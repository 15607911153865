/*eslint-disable*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.infinitusai = (function() {
    
        /**
         * Namespace infinitusai.
         * @exports infinitusai
         * @namespace
         */
        var infinitusai = {};
    
        infinitusai.be = (function() {
    
            /**
             * Namespace be.
             * @memberof infinitusai
             * @namespace
             */
            var be = {};
    
            be.ACLDoc = (function() {
    
                /**
                 * Properties of a ACLDoc.
                 * @memberof infinitusai.be
                 * @interface IACLDoc
                 * @property {Array.<string>|null} [allowedTaskFinalizers] ACLDoc allowedTaskFinalizers
                 */
    
                /**
                 * Constructs a new ACLDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACLDoc.
                 * @implements IACLDoc
                 * @constructor
                 * @param {infinitusai.be.IACLDoc=} [properties] Properties to set
                 */
                function ACLDoc(properties) {
                    this.allowedTaskFinalizers = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACLDoc allowedTaskFinalizers.
                 * @member {Array.<string>} allowedTaskFinalizers
                 * @memberof infinitusai.be.ACLDoc
                 * @instance
                 */
                ACLDoc.prototype.allowedTaskFinalizers = $util.emptyArray;
    
                /**
                 * Creates a new ACLDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {infinitusai.be.IACLDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.ACLDoc} ACLDoc instance
                 */
                ACLDoc.create = function create(properties) {
                    return new ACLDoc(properties);
                };
    
                /**
                 * Encodes the specified ACLDoc message. Does not implicitly {@link infinitusai.be.ACLDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {infinitusai.be.IACLDoc} message ACLDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACLDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.allowedTaskFinalizers != null && message.allowedTaskFinalizers.length)
                        for (var i = 0; i < message.allowedTaskFinalizers.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.allowedTaskFinalizers[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACLDoc message, length delimited. Does not implicitly {@link infinitusai.be.ACLDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {infinitusai.be.IACLDoc} message ACLDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACLDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACLDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACLDoc} ACLDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACLDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACLDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.allowedTaskFinalizers && message.allowedTaskFinalizers.length))
                                    message.allowedTaskFinalizers = [];
                                message.allowedTaskFinalizers.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACLDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACLDoc} ACLDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACLDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACLDoc message.
                 * @function verify
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACLDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.allowedTaskFinalizers != null && message.hasOwnProperty("allowedTaskFinalizers")) {
                        if (!Array.isArray(message.allowedTaskFinalizers))
                            return "allowedTaskFinalizers: array expected";
                        for (var i = 0; i < message.allowedTaskFinalizers.length; ++i)
                            if (!$util.isString(message.allowedTaskFinalizers[i]))
                                return "allowedTaskFinalizers: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ACLDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACLDoc} ACLDoc
                 */
                ACLDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACLDoc)
                        return object;
                    var message = new $root.infinitusai.be.ACLDoc();
                    if (object.allowedTaskFinalizers) {
                        if (!Array.isArray(object.allowedTaskFinalizers))
                            throw TypeError(".infinitusai.be.ACLDoc.allowedTaskFinalizers: array expected");
                        message.allowedTaskFinalizers = [];
                        for (var i = 0; i < object.allowedTaskFinalizers.length; ++i)
                            message.allowedTaskFinalizers[i] = String(object.allowedTaskFinalizers[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACLDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {infinitusai.be.ACLDoc} message ACLDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACLDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.allowedTaskFinalizers = [];
                    if (message.allowedTaskFinalizers && message.allowedTaskFinalizers.length) {
                        object.allowedTaskFinalizers = [];
                        for (var j = 0; j < message.allowedTaskFinalizers.length; ++j)
                            object.allowedTaskFinalizers[j] = message.allowedTaskFinalizers[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ACLDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACLDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACLDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACLDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACLDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACLDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACLDoc";
                };
    
                return ACLDoc;
            })();
    
            be.TTSSetting = (function() {
    
                /**
                 * Properties of a TTSSetting.
                 * @memberof infinitusai.be
                 * @interface ITTSSetting
                 * @property {number|null} [ttsSpeed] TTSSetting ttsSpeed
                 * @property {number|null} [ttsPitch] TTSSetting ttsPitch
                 * @property {infinitusai.be.TTSSetting.Voice|null} [ttsVoice] TTSSetting ttsVoice
                 * @property {boolean|null} [muteEva] TTSSetting muteEva
                 * @property {number|null} [sampleRate] TTSSetting sampleRate
                 * @property {string|null} [ttsVoiceName] TTSSetting ttsVoiceName
                 */
    
                /**
                 * Constructs a new TTSSetting.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TTSSetting.
                 * @implements ITTSSetting
                 * @constructor
                 * @param {infinitusai.be.ITTSSetting=} [properties] Properties to set
                 */
                function TTSSetting(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TTSSetting ttsSpeed.
                 * @member {number} ttsSpeed
                 * @memberof infinitusai.be.TTSSetting
                 * @instance
                 */
                TTSSetting.prototype.ttsSpeed = 0;
    
                /**
                 * TTSSetting ttsPitch.
                 * @member {number} ttsPitch
                 * @memberof infinitusai.be.TTSSetting
                 * @instance
                 */
                TTSSetting.prototype.ttsPitch = 0;
    
                /**
                 * TTSSetting ttsVoice.
                 * @member {infinitusai.be.TTSSetting.Voice} ttsVoice
                 * @memberof infinitusai.be.TTSSetting
                 * @instance
                 */
                TTSSetting.prototype.ttsVoice = 0;
    
                /**
                 * TTSSetting muteEva.
                 * @member {boolean} muteEva
                 * @memberof infinitusai.be.TTSSetting
                 * @instance
                 */
                TTSSetting.prototype.muteEva = false;
    
                /**
                 * TTSSetting sampleRate.
                 * @member {number} sampleRate
                 * @memberof infinitusai.be.TTSSetting
                 * @instance
                 */
                TTSSetting.prototype.sampleRate = 0;
    
                /**
                 * TTSSetting ttsVoiceName.
                 * @member {string} ttsVoiceName
                 * @memberof infinitusai.be.TTSSetting
                 * @instance
                 */
                TTSSetting.prototype.ttsVoiceName = "";
    
                /**
                 * Creates a new TTSSetting instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {infinitusai.be.ITTSSetting=} [properties] Properties to set
                 * @returns {infinitusai.be.TTSSetting} TTSSetting instance
                 */
                TTSSetting.create = function create(properties) {
                    return new TTSSetting(properties);
                };
    
                /**
                 * Encodes the specified TTSSetting message. Does not implicitly {@link infinitusai.be.TTSSetting.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {infinitusai.be.ITTSSetting} message TTSSetting message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TTSSetting.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ttsSpeed != null && Object.hasOwnProperty.call(message, "ttsSpeed"))
                        writer.uint32(/* id 1, wireType 1 =*/9).double(message.ttsSpeed);
                    if (message.ttsPitch != null && Object.hasOwnProperty.call(message, "ttsPitch"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.ttsPitch);
                    if (message.ttsVoice != null && Object.hasOwnProperty.call(message, "ttsVoice"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ttsVoice);
                    if (message.muteEva != null && Object.hasOwnProperty.call(message, "muteEva"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.muteEva);
                    if (message.sampleRate != null && Object.hasOwnProperty.call(message, "sampleRate"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sampleRate);
                    if (message.ttsVoiceName != null && Object.hasOwnProperty.call(message, "ttsVoiceName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.ttsVoiceName);
                    return writer;
                };
    
                /**
                 * Encodes the specified TTSSetting message, length delimited. Does not implicitly {@link infinitusai.be.TTSSetting.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {infinitusai.be.ITTSSetting} message TTSSetting message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TTSSetting.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TTSSetting message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TTSSetting} TTSSetting
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TTSSetting.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TTSSetting();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ttsSpeed = reader.double();
                                break;
                            }
                        case 2: {
                                message.ttsPitch = reader.double();
                                break;
                            }
                        case 3: {
                                message.ttsVoice = reader.int32();
                                break;
                            }
                        case 4: {
                                message.muteEva = reader.bool();
                                break;
                            }
                        case 5: {
                                message.sampleRate = reader.int32();
                                break;
                            }
                        case 6: {
                                message.ttsVoiceName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TTSSetting message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TTSSetting} TTSSetting
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TTSSetting.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TTSSetting message.
                 * @function verify
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TTSSetting.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ttsSpeed != null && message.hasOwnProperty("ttsSpeed"))
                        if (typeof message.ttsSpeed !== "number")
                            return "ttsSpeed: number expected";
                    if (message.ttsPitch != null && message.hasOwnProperty("ttsPitch"))
                        if (typeof message.ttsPitch !== "number")
                            return "ttsPitch: number expected";
                    if (message.ttsVoice != null && message.hasOwnProperty("ttsVoice"))
                        switch (message.ttsVoice) {
                        default:
                            return "ttsVoice: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 29:
                        case 30:
                        case 31:
                        case 32:
                        case 33:
                        case 34:
                            break;
                        }
                    if (message.muteEva != null && message.hasOwnProperty("muteEva"))
                        if (typeof message.muteEva !== "boolean")
                            return "muteEva: boolean expected";
                    if (message.sampleRate != null && message.hasOwnProperty("sampleRate"))
                        if (!$util.isInteger(message.sampleRate))
                            return "sampleRate: integer expected";
                    if (message.ttsVoiceName != null && message.hasOwnProperty("ttsVoiceName"))
                        if (!$util.isString(message.ttsVoiceName))
                            return "ttsVoiceName: string expected";
                    return null;
                };
    
                /**
                 * Creates a TTSSetting message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TTSSetting} TTSSetting
                 */
                TTSSetting.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TTSSetting)
                        return object;
                    var message = new $root.infinitusai.be.TTSSetting();
                    if (object.ttsSpeed != null)
                        message.ttsSpeed = Number(object.ttsSpeed);
                    if (object.ttsPitch != null)
                        message.ttsPitch = Number(object.ttsPitch);
                    switch (object.ttsVoice) {
                    default:
                        if (typeof object.ttsVoice === "number") {
                            message.ttsVoice = object.ttsVoice;
                            break;
                        }
                        break;
                    case "en_US_Wavenet_A":
                    case 0:
                        message.ttsVoice = 0;
                        break;
                    case "en_US_Wavenet_B":
                    case 1:
                        message.ttsVoice = 1;
                        break;
                    case "en_US_Wavenet_C":
                    case 2:
                        message.ttsVoice = 2;
                        break;
                    case "en_US_Wavenet_D":
                    case 3:
                        message.ttsVoice = 3;
                        break;
                    case "en_US_Wavenet_E":
                    case 4:
                        message.ttsVoice = 4;
                        break;
                    case "en_US_Wavenet_F":
                    case 5:
                        message.ttsVoice = 5;
                        break;
                    case "en_US_Neural2_F":
                    case 6:
                        message.ttsVoice = 6;
                        break;
                    case "en_US_Neural2_G":
                    case 7:
                        message.ttsVoice = 7;
                        break;
                    case "en_US_Neural2_H":
                    case 8:
                        message.ttsVoice = 8;
                        break;
                    case "en_US_Studio_M":
                    case 9:
                        message.ttsVoice = 9;
                        break;
                    case "en_US_Studio_O":
                    case 10:
                        message.ttsVoice = 10;
                        break;
                    case "elevenLabs_Rahul":
                    case 11:
                        message.ttsVoice = 11;
                        break;
                    case "elevenLabs_Jack":
                    case 12:
                        message.ttsVoice = 12;
                        break;
                    case "elevenLabs_Ira":
                    case 13:
                        message.ttsVoice = 13;
                        break;
                    case "elevenLabs_Rebbecca":
                    case 14:
                        message.ttsVoice = 14;
                        break;
                    case "elevenLabs_Sneha":
                    case 15:
                        message.ttsVoice = 15;
                        break;
                    case "elevenLabs_Tom":
                    case 16:
                        message.ttsVoice = 16;
                        break;
                    case "elevenLabs_Alfred":
                    case 17:
                        message.ttsVoice = 17;
                        break;
                    case "elevenLabs_Adam":
                    case 18:
                        message.ttsVoice = 18;
                        break;
                    case "elevenLabs_Sir_Paddington":
                    case 19:
                        message.ttsVoice = 19;
                        break;
                    case "elevenLabs_Emma":
                    case 20:
                        message.ttsVoice = 20;
                        break;
                    case "elevenLabs_Meera":
                    case 21:
                        message.ttsVoice = 21;
                        break;
                    case "elevenLabs_Shauna":
                    case 22:
                        message.ttsVoice = 22;
                        break;
                    case "elevenLabs_Shyam":
                    case 23:
                        message.ttsVoice = 23;
                        break;
                    case "elevenLabs_Tamika":
                    case 24:
                        message.ttsVoice = 24;
                        break;
                    case "elevenLabs_Bria":
                    case 25:
                        message.ttsVoice = 25;
                        break;
                    case "elevenLabs_Won":
                    case 26:
                        message.ttsVoice = 26;
                        break;
                    case "elevenLabs_AJ":
                    case 27:
                        message.ttsVoice = 27;
                        break;
                    case "elevenLabs_DK":
                    case 28:
                        message.ttsVoice = 28;
                        break;
                    case "elevenLabs_JB":
                    case 29:
                        message.ttsVoice = 29;
                        break;
                    case "elevenLabs_RP":
                    case 30:
                        message.ttsVoice = 30;
                        break;
                    case "elevenLabs_SR":
                    case 31:
                        message.ttsVoice = 31;
                        break;
                    case "elevenLabs_WW":
                    case 32:
                        message.ttsVoice = 32;
                        break;
                    case "elevenLabs_RR":
                    case 33:
                        message.ttsVoice = 33;
                        break;
                    case "elevenLabs_JH":
                    case 34:
                        message.ttsVoice = 34;
                        break;
                    }
                    if (object.muteEva != null)
                        message.muteEva = Boolean(object.muteEva);
                    if (object.sampleRate != null)
                        message.sampleRate = object.sampleRate | 0;
                    if (object.ttsVoiceName != null)
                        message.ttsVoiceName = String(object.ttsVoiceName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TTSSetting message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {infinitusai.be.TTSSetting} message TTSSetting
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TTSSetting.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.ttsSpeed = 0;
                        object.ttsPitch = 0;
                        object.ttsVoice = options.enums === String ? "en_US_Wavenet_A" : 0;
                        object.muteEva = false;
                        object.sampleRate = 0;
                        object.ttsVoiceName = "";
                    }
                    if (message.ttsSpeed != null && message.hasOwnProperty("ttsSpeed"))
                        object.ttsSpeed = options.json && !isFinite(message.ttsSpeed) ? String(message.ttsSpeed) : message.ttsSpeed;
                    if (message.ttsPitch != null && message.hasOwnProperty("ttsPitch"))
                        object.ttsPitch = options.json && !isFinite(message.ttsPitch) ? String(message.ttsPitch) : message.ttsPitch;
                    if (message.ttsVoice != null && message.hasOwnProperty("ttsVoice"))
                        object.ttsVoice = options.enums === String ? $root.infinitusai.be.TTSSetting.Voice[message.ttsVoice] === undefined ? message.ttsVoice : $root.infinitusai.be.TTSSetting.Voice[message.ttsVoice] : message.ttsVoice;
                    if (message.muteEva != null && message.hasOwnProperty("muteEva"))
                        object.muteEva = message.muteEva;
                    if (message.sampleRate != null && message.hasOwnProperty("sampleRate"))
                        object.sampleRate = message.sampleRate;
                    if (message.ttsVoiceName != null && message.hasOwnProperty("ttsVoiceName"))
                        object.ttsVoiceName = message.ttsVoiceName;
                    return object;
                };
    
                /**
                 * Converts this TTSSetting to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TTSSetting
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TTSSetting.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TTSSetting
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TTSSetting
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TTSSetting.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TTSSetting";
                };
    
                /**
                 * Voice enum.
                 * @name infinitusai.be.TTSSetting.Voice
                 * @enum {number}
                 * @property {number} en_US_Wavenet_A=0 en_US_Wavenet_A value
                 * @property {number} en_US_Wavenet_B=1 en_US_Wavenet_B value
                 * @property {number} en_US_Wavenet_C=2 en_US_Wavenet_C value
                 * @property {number} en_US_Wavenet_D=3 en_US_Wavenet_D value
                 * @property {number} en_US_Wavenet_E=4 en_US_Wavenet_E value
                 * @property {number} en_US_Wavenet_F=5 en_US_Wavenet_F value
                 * @property {number} en_US_Neural2_F=6 en_US_Neural2_F value
                 * @property {number} en_US_Neural2_G=7 en_US_Neural2_G value
                 * @property {number} en_US_Neural2_H=8 en_US_Neural2_H value
                 * @property {number} en_US_Studio_M=9 en_US_Studio_M value
                 * @property {number} en_US_Studio_O=10 en_US_Studio_O value
                 * @property {number} elevenLabs_Rahul=11 elevenLabs_Rahul value
                 * @property {number} elevenLabs_Jack=12 elevenLabs_Jack value
                 * @property {number} elevenLabs_Ira=13 elevenLabs_Ira value
                 * @property {number} elevenLabs_Rebbecca=14 elevenLabs_Rebbecca value
                 * @property {number} elevenLabs_Sneha=15 elevenLabs_Sneha value
                 * @property {number} elevenLabs_Tom=16 elevenLabs_Tom value
                 * @property {number} elevenLabs_Alfred=17 elevenLabs_Alfred value
                 * @property {number} elevenLabs_Adam=18 elevenLabs_Adam value
                 * @property {number} elevenLabs_Sir_Paddington=19 elevenLabs_Sir_Paddington value
                 * @property {number} elevenLabs_Emma=20 elevenLabs_Emma value
                 * @property {number} elevenLabs_Meera=21 elevenLabs_Meera value
                 * @property {number} elevenLabs_Shauna=22 elevenLabs_Shauna value
                 * @property {number} elevenLabs_Shyam=23 elevenLabs_Shyam value
                 * @property {number} elevenLabs_Tamika=24 elevenLabs_Tamika value
                 * @property {number} elevenLabs_Bria=25 elevenLabs_Bria value
                 * @property {number} elevenLabs_Won=26 elevenLabs_Won value
                 * @property {number} elevenLabs_AJ=27 elevenLabs_AJ value
                 * @property {number} elevenLabs_DK=28 elevenLabs_DK value
                 * @property {number} elevenLabs_JB=29 elevenLabs_JB value
                 * @property {number} elevenLabs_RP=30 elevenLabs_RP value
                 * @property {number} elevenLabs_SR=31 elevenLabs_SR value
                 * @property {number} elevenLabs_WW=32 elevenLabs_WW value
                 * @property {number} elevenLabs_RR=33 elevenLabs_RR value
                 * @property {number} elevenLabs_JH=34 elevenLabs_JH value
                 */
                TTSSetting.Voice = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "en_US_Wavenet_A"] = 0;
                    values[valuesById[1] = "en_US_Wavenet_B"] = 1;
                    values[valuesById[2] = "en_US_Wavenet_C"] = 2;
                    values[valuesById[3] = "en_US_Wavenet_D"] = 3;
                    values[valuesById[4] = "en_US_Wavenet_E"] = 4;
                    values[valuesById[5] = "en_US_Wavenet_F"] = 5;
                    values[valuesById[6] = "en_US_Neural2_F"] = 6;
                    values[valuesById[7] = "en_US_Neural2_G"] = 7;
                    values[valuesById[8] = "en_US_Neural2_H"] = 8;
                    values[valuesById[9] = "en_US_Studio_M"] = 9;
                    values[valuesById[10] = "en_US_Studio_O"] = 10;
                    values[valuesById[11] = "elevenLabs_Rahul"] = 11;
                    values[valuesById[12] = "elevenLabs_Jack"] = 12;
                    values[valuesById[13] = "elevenLabs_Ira"] = 13;
                    values[valuesById[14] = "elevenLabs_Rebbecca"] = 14;
                    values[valuesById[15] = "elevenLabs_Sneha"] = 15;
                    values[valuesById[16] = "elevenLabs_Tom"] = 16;
                    values[valuesById[17] = "elevenLabs_Alfred"] = 17;
                    values[valuesById[18] = "elevenLabs_Adam"] = 18;
                    values[valuesById[19] = "elevenLabs_Sir_Paddington"] = 19;
                    values[valuesById[20] = "elevenLabs_Emma"] = 20;
                    values[valuesById[21] = "elevenLabs_Meera"] = 21;
                    values[valuesById[22] = "elevenLabs_Shauna"] = 22;
                    values[valuesById[23] = "elevenLabs_Shyam"] = 23;
                    values[valuesById[24] = "elevenLabs_Tamika"] = 24;
                    values[valuesById[25] = "elevenLabs_Bria"] = 25;
                    values[valuesById[26] = "elevenLabs_Won"] = 26;
                    values[valuesById[27] = "elevenLabs_AJ"] = 27;
                    values[valuesById[28] = "elevenLabs_DK"] = 28;
                    values[valuesById[29] = "elevenLabs_JB"] = 29;
                    values[valuesById[30] = "elevenLabs_RP"] = 30;
                    values[valuesById[31] = "elevenLabs_SR"] = 31;
                    values[valuesById[32] = "elevenLabs_WW"] = 32;
                    values[valuesById[33] = "elevenLabs_RR"] = 33;
                    values[valuesById[34] = "elevenLabs_JH"] = 34;
                    return values;
                })();
    
                return TTSSetting;
            })();
    
            be.SpeechAction = (function() {
    
                /**
                 * Properties of a SpeechAction.
                 * @memberof infinitusai.be
                 * @interface ISpeechAction
                 * @property {string|null} [speechUtterance] SpeechAction speechUtterance
                 * @property {string|null} [fallbackSpeechUtterance] SpeechAction fallbackSpeechUtterance
                 * @property {infinitusai.be.ITTSSetting|null} [ttsSetting] SpeechAction ttsSetting
                 */
    
                /**
                 * Constructs a new SpeechAction.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SpeechAction.
                 * @implements ISpeechAction
                 * @constructor
                 * @param {infinitusai.be.ISpeechAction=} [properties] Properties to set
                 */
                function SpeechAction(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SpeechAction speechUtterance.
                 * @member {string} speechUtterance
                 * @memberof infinitusai.be.SpeechAction
                 * @instance
                 */
                SpeechAction.prototype.speechUtterance = "";
    
                /**
                 * SpeechAction fallbackSpeechUtterance.
                 * @member {string} fallbackSpeechUtterance
                 * @memberof infinitusai.be.SpeechAction
                 * @instance
                 */
                SpeechAction.prototype.fallbackSpeechUtterance = "";
    
                /**
                 * SpeechAction ttsSetting.
                 * @member {infinitusai.be.ITTSSetting|null|undefined} ttsSetting
                 * @memberof infinitusai.be.SpeechAction
                 * @instance
                 */
                SpeechAction.prototype.ttsSetting = null;
    
                /**
                 * Creates a new SpeechAction instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {infinitusai.be.ISpeechAction=} [properties] Properties to set
                 * @returns {infinitusai.be.SpeechAction} SpeechAction instance
                 */
                SpeechAction.create = function create(properties) {
                    return new SpeechAction(properties);
                };
    
                /**
                 * Encodes the specified SpeechAction message. Does not implicitly {@link infinitusai.be.SpeechAction.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {infinitusai.be.ISpeechAction} message SpeechAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpeechAction.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.speechUtterance != null && Object.hasOwnProperty.call(message, "speechUtterance"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.speechUtterance);
                    if (message.ttsSetting != null && Object.hasOwnProperty.call(message, "ttsSetting"))
                        $root.infinitusai.be.TTSSetting.encode(message.ttsSetting, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.fallbackSpeechUtterance != null && Object.hasOwnProperty.call(message, "fallbackSpeechUtterance"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.fallbackSpeechUtterance);
                    return writer;
                };
    
                /**
                 * Encodes the specified SpeechAction message, length delimited. Does not implicitly {@link infinitusai.be.SpeechAction.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {infinitusai.be.ISpeechAction} message SpeechAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpeechAction.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SpeechAction message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SpeechAction} SpeechAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpeechAction.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SpeechAction();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.speechUtterance = reader.string();
                                break;
                            }
                        case 3: {
                                message.fallbackSpeechUtterance = reader.string();
                                break;
                            }
                        case 2: {
                                message.ttsSetting = $root.infinitusai.be.TTSSetting.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SpeechAction message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SpeechAction} SpeechAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpeechAction.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SpeechAction message.
                 * @function verify
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpeechAction.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.speechUtterance != null && message.hasOwnProperty("speechUtterance"))
                        if (!$util.isString(message.speechUtterance))
                            return "speechUtterance: string expected";
                    if (message.fallbackSpeechUtterance != null && message.hasOwnProperty("fallbackSpeechUtterance"))
                        if (!$util.isString(message.fallbackSpeechUtterance))
                            return "fallbackSpeechUtterance: string expected";
                    if (message.ttsSetting != null && message.hasOwnProperty("ttsSetting")) {
                        var error = $root.infinitusai.be.TTSSetting.verify(message.ttsSetting);
                        if (error)
                            return "ttsSetting." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a SpeechAction message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SpeechAction} SpeechAction
                 */
                SpeechAction.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SpeechAction)
                        return object;
                    var message = new $root.infinitusai.be.SpeechAction();
                    if (object.speechUtterance != null)
                        message.speechUtterance = String(object.speechUtterance);
                    if (object.fallbackSpeechUtterance != null)
                        message.fallbackSpeechUtterance = String(object.fallbackSpeechUtterance);
                    if (object.ttsSetting != null) {
                        if (typeof object.ttsSetting !== "object")
                            throw TypeError(".infinitusai.be.SpeechAction.ttsSetting: object expected");
                        message.ttsSetting = $root.infinitusai.be.TTSSetting.fromObject(object.ttsSetting);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SpeechAction message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {infinitusai.be.SpeechAction} message SpeechAction
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpeechAction.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.speechUtterance = "";
                        object.ttsSetting = null;
                        object.fallbackSpeechUtterance = "";
                    }
                    if (message.speechUtterance != null && message.hasOwnProperty("speechUtterance"))
                        object.speechUtterance = message.speechUtterance;
                    if (message.ttsSetting != null && message.hasOwnProperty("ttsSetting"))
                        object.ttsSetting = $root.infinitusai.be.TTSSetting.toObject(message.ttsSetting, options);
                    if (message.fallbackSpeechUtterance != null && message.hasOwnProperty("fallbackSpeechUtterance"))
                        object.fallbackSpeechUtterance = message.fallbackSpeechUtterance;
                    return object;
                };
    
                /**
                 * Converts this SpeechAction to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SpeechAction
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpeechAction.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SpeechAction
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SpeechAction
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpeechAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SpeechAction";
                };
    
                return SpeechAction;
            })();
    
            be.DtmfAction = (function() {
    
                /**
                 * Properties of a DtmfAction.
                 * @memberof infinitusai.be
                 * @interface IDtmfAction
                 * @property {string|null} [dtmfDigits] DtmfAction dtmfDigits
                 */
    
                /**
                 * Constructs a new DtmfAction.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DtmfAction.
                 * @implements IDtmfAction
                 * @constructor
                 * @param {infinitusai.be.IDtmfAction=} [properties] Properties to set
                 */
                function DtmfAction(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DtmfAction dtmfDigits.
                 * @member {string} dtmfDigits
                 * @memberof infinitusai.be.DtmfAction
                 * @instance
                 */
                DtmfAction.prototype.dtmfDigits = "";
    
                /**
                 * Creates a new DtmfAction instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {infinitusai.be.IDtmfAction=} [properties] Properties to set
                 * @returns {infinitusai.be.DtmfAction} DtmfAction instance
                 */
                DtmfAction.create = function create(properties) {
                    return new DtmfAction(properties);
                };
    
                /**
                 * Encodes the specified DtmfAction message. Does not implicitly {@link infinitusai.be.DtmfAction.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {infinitusai.be.IDtmfAction} message DtmfAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DtmfAction.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.dtmfDigits != null && Object.hasOwnProperty.call(message, "dtmfDigits"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.dtmfDigits);
                    return writer;
                };
    
                /**
                 * Encodes the specified DtmfAction message, length delimited. Does not implicitly {@link infinitusai.be.DtmfAction.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {infinitusai.be.IDtmfAction} message DtmfAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DtmfAction.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DtmfAction message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DtmfAction} DtmfAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DtmfAction.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DtmfAction();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.dtmfDigits = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DtmfAction message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DtmfAction} DtmfAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DtmfAction.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DtmfAction message.
                 * @function verify
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DtmfAction.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits"))
                        if (!$util.isString(message.dtmfDigits))
                            return "dtmfDigits: string expected";
                    return null;
                };
    
                /**
                 * Creates a DtmfAction message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DtmfAction} DtmfAction
                 */
                DtmfAction.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DtmfAction)
                        return object;
                    var message = new $root.infinitusai.be.DtmfAction();
                    if (object.dtmfDigits != null)
                        message.dtmfDigits = String(object.dtmfDigits);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DtmfAction message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {infinitusai.be.DtmfAction} message DtmfAction
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DtmfAction.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.dtmfDigits = "";
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits"))
                        object.dtmfDigits = message.dtmfDigits;
                    return object;
                };
    
                /**
                 * Converts this DtmfAction to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DtmfAction
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DtmfAction.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DtmfAction
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DtmfAction
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DtmfAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DtmfAction";
                };
    
                return DtmfAction;
            })();
    
            be.RecordedAudioAction = (function() {
    
                /**
                 * Properties of a RecordedAudioAction.
                 * @memberof infinitusai.be
                 * @interface IRecordedAudioAction
                 * @property {string|null} [greetingName] RecordedAudioAction greetingName
                 * @property {string|null} [recordedText] RecordedAudioAction recordedText
                 * @property {string|null} [gcsPath] RecordedAudioAction gcsPath
                 */
    
                /**
                 * Constructs a new RecordedAudioAction.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RecordedAudioAction.
                 * @implements IRecordedAudioAction
                 * @constructor
                 * @param {infinitusai.be.IRecordedAudioAction=} [properties] Properties to set
                 */
                function RecordedAudioAction(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RecordedAudioAction greetingName.
                 * @member {string} greetingName
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @instance
                 */
                RecordedAudioAction.prototype.greetingName = "";
    
                /**
                 * RecordedAudioAction recordedText.
                 * @member {string} recordedText
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @instance
                 */
                RecordedAudioAction.prototype.recordedText = "";
    
                /**
                 * RecordedAudioAction gcsPath.
                 * @member {string} gcsPath
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @instance
                 */
                RecordedAudioAction.prototype.gcsPath = "";
    
                /**
                 * Creates a new RecordedAudioAction instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {infinitusai.be.IRecordedAudioAction=} [properties] Properties to set
                 * @returns {infinitusai.be.RecordedAudioAction} RecordedAudioAction instance
                 */
                RecordedAudioAction.create = function create(properties) {
                    return new RecordedAudioAction(properties);
                };
    
                /**
                 * Encodes the specified RecordedAudioAction message. Does not implicitly {@link infinitusai.be.RecordedAudioAction.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {infinitusai.be.IRecordedAudioAction} message RecordedAudioAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordedAudioAction.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.greetingName != null && Object.hasOwnProperty.call(message, "greetingName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.greetingName);
                    if (message.recordedText != null && Object.hasOwnProperty.call(message, "recordedText"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.recordedText);
                    if (message.gcsPath != null && Object.hasOwnProperty.call(message, "gcsPath"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.gcsPath);
                    return writer;
                };
    
                /**
                 * Encodes the specified RecordedAudioAction message, length delimited. Does not implicitly {@link infinitusai.be.RecordedAudioAction.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {infinitusai.be.IRecordedAudioAction} message RecordedAudioAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordedAudioAction.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RecordedAudioAction message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RecordedAudioAction} RecordedAudioAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordedAudioAction.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RecordedAudioAction();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.greetingName = reader.string();
                                break;
                            }
                        case 2: {
                                message.recordedText = reader.string();
                                break;
                            }
                        case 3: {
                                message.gcsPath = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RecordedAudioAction message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RecordedAudioAction} RecordedAudioAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordedAudioAction.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RecordedAudioAction message.
                 * @function verify
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RecordedAudioAction.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.greetingName != null && message.hasOwnProperty("greetingName"))
                        if (!$util.isString(message.greetingName))
                            return "greetingName: string expected";
                    if (message.recordedText != null && message.hasOwnProperty("recordedText"))
                        if (!$util.isString(message.recordedText))
                            return "recordedText: string expected";
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        if (!$util.isString(message.gcsPath))
                            return "gcsPath: string expected";
                    return null;
                };
    
                /**
                 * Creates a RecordedAudioAction message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RecordedAudioAction} RecordedAudioAction
                 */
                RecordedAudioAction.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RecordedAudioAction)
                        return object;
                    var message = new $root.infinitusai.be.RecordedAudioAction();
                    if (object.greetingName != null)
                        message.greetingName = String(object.greetingName);
                    if (object.recordedText != null)
                        message.recordedText = String(object.recordedText);
                    if (object.gcsPath != null)
                        message.gcsPath = String(object.gcsPath);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RecordedAudioAction message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {infinitusai.be.RecordedAudioAction} message RecordedAudioAction
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordedAudioAction.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.greetingName = "";
                        object.recordedText = "";
                        object.gcsPath = "";
                    }
                    if (message.greetingName != null && message.hasOwnProperty("greetingName"))
                        object.greetingName = message.greetingName;
                    if (message.recordedText != null && message.hasOwnProperty("recordedText"))
                        object.recordedText = message.recordedText;
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        object.gcsPath = message.gcsPath;
                    return object;
                };
    
                /**
                 * Converts this RecordedAudioAction to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordedAudioAction.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RecordedAudioAction
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RecordedAudioAction
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordedAudioAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RecordedAudioAction";
                };
    
                return RecordedAudioAction;
            })();
    
            /**
             * ActionSource enum.
             * @name infinitusai.be.ActionSource
             * @enum {number}
             * @property {number} ACTION_SOURCE_UNKNOWN=0 ACTION_SOURCE_UNKNOWN value
             * @property {number} ACTION_SOURCE_KEYBOARD=1 ACTION_SOURCE_KEYBOARD value
             * @property {number} ACTION_SOURCE_TOP_SUGGESTION=2 ACTION_SOURCE_TOP_SUGGESTION value
             * @property {number} ACTION_SOURCE_SUGGESTION=3 ACTION_SOURCE_SUGGESTION value
             * @property {number} ACTION_SOURCE_AUTO_RESPOND=4 ACTION_SOURCE_AUTO_RESPOND value
             * @property {number} ACTION_SOURCE_FOLLOWUP_SUGGESTION=5 ACTION_SOURCE_FOLLOWUP_SUGGESTION value
             * @property {number} ACTION_SOURCE_RECORDED_AUDIO=6 ACTION_SOURCE_RECORDED_AUDIO value
             */
            be.ActionSource = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ACTION_SOURCE_UNKNOWN"] = 0;
                values[valuesById[1] = "ACTION_SOURCE_KEYBOARD"] = 1;
                values[valuesById[2] = "ACTION_SOURCE_TOP_SUGGESTION"] = 2;
                values[valuesById[3] = "ACTION_SOURCE_SUGGESTION"] = 3;
                values[valuesById[4] = "ACTION_SOURCE_AUTO_RESPOND"] = 4;
                values[valuesById[5] = "ACTION_SOURCE_FOLLOWUP_SUGGESTION"] = 5;
                values[valuesById[6] = "ACTION_SOURCE_RECORDED_AUDIO"] = 6;
                return values;
            })();
    
            be.FeExecuteActionRequest = (function() {
    
                /**
                 * Properties of a FeExecuteActionRequest.
                 * @memberof infinitusai.be
                 * @interface IFeExecuteActionRequest
                 * @property {string|null} [taskUuid] FeExecuteActionRequest taskUuid
                 * @property {string|null} [callUuid] FeExecuteActionRequest callUuid
                 * @property {infinitusai.be.IAction|null} [action] FeExecuteActionRequest action
                 */
    
                /**
                 * Constructs a new FeExecuteActionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FeExecuteActionRequest.
                 * @implements IFeExecuteActionRequest
                 * @constructor
                 * @param {infinitusai.be.IFeExecuteActionRequest=} [properties] Properties to set
                 */
                function FeExecuteActionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FeExecuteActionRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @instance
                 */
                FeExecuteActionRequest.prototype.taskUuid = "";
    
                /**
                 * FeExecuteActionRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @instance
                 */
                FeExecuteActionRequest.prototype.callUuid = "";
    
                /**
                 * FeExecuteActionRequest action.
                 * @member {infinitusai.be.IAction|null|undefined} action
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @instance
                 */
                FeExecuteActionRequest.prototype.action = null;
    
                /**
                 * Creates a new FeExecuteActionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.IFeExecuteActionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.FeExecuteActionRequest} FeExecuteActionRequest instance
                 */
                FeExecuteActionRequest.create = function create(properties) {
                    return new FeExecuteActionRequest(properties);
                };
    
                /**
                 * Encodes the specified FeExecuteActionRequest message. Does not implicitly {@link infinitusai.be.FeExecuteActionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.IFeExecuteActionRequest} message FeExecuteActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FeExecuteActionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                        $root.infinitusai.be.Action.encode(message.action, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FeExecuteActionRequest message, length delimited. Does not implicitly {@link infinitusai.be.FeExecuteActionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.IFeExecuteActionRequest} message FeExecuteActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FeExecuteActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FeExecuteActionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FeExecuteActionRequest} FeExecuteActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FeExecuteActionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FeExecuteActionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.action = $root.infinitusai.be.Action.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FeExecuteActionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FeExecuteActionRequest} FeExecuteActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FeExecuteActionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FeExecuteActionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FeExecuteActionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.action != null && message.hasOwnProperty("action")) {
                        var error = $root.infinitusai.be.Action.verify(message.action);
                        if (error)
                            return "action." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FeExecuteActionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FeExecuteActionRequest} FeExecuteActionRequest
                 */
                FeExecuteActionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FeExecuteActionRequest)
                        return object;
                    var message = new $root.infinitusai.be.FeExecuteActionRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.action != null) {
                        if (typeof object.action !== "object")
                            throw TypeError(".infinitusai.be.FeExecuteActionRequest.action: object expected");
                        message.action = $root.infinitusai.be.Action.fromObject(object.action);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FeExecuteActionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.FeExecuteActionRequest} message FeExecuteActionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FeExecuteActionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.action = null;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.action != null && message.hasOwnProperty("action"))
                        object.action = $root.infinitusai.be.Action.toObject(message.action, options);
                    return object;
                };
    
                /**
                 * Converts this FeExecuteActionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FeExecuteActionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FeExecuteActionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FeExecuteActionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FeExecuteActionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FeExecuteActionRequest";
                };
    
                return FeExecuteActionRequest;
            })();
    
            be.FeExecuteActionResponse = (function() {
    
                /**
                 * Properties of a FeExecuteActionResponse.
                 * @memberof infinitusai.be
                 * @interface IFeExecuteActionResponse
                 */
    
                /**
                 * Constructs a new FeExecuteActionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FeExecuteActionResponse.
                 * @implements IFeExecuteActionResponse
                 * @constructor
                 * @param {infinitusai.be.IFeExecuteActionResponse=} [properties] Properties to set
                 */
                function FeExecuteActionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new FeExecuteActionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.IFeExecuteActionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.FeExecuteActionResponse} FeExecuteActionResponse instance
                 */
                FeExecuteActionResponse.create = function create(properties) {
                    return new FeExecuteActionResponse(properties);
                };
    
                /**
                 * Encodes the specified FeExecuteActionResponse message. Does not implicitly {@link infinitusai.be.FeExecuteActionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.IFeExecuteActionResponse} message FeExecuteActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FeExecuteActionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified FeExecuteActionResponse message, length delimited. Does not implicitly {@link infinitusai.be.FeExecuteActionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.IFeExecuteActionResponse} message FeExecuteActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FeExecuteActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FeExecuteActionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FeExecuteActionResponse} FeExecuteActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FeExecuteActionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FeExecuteActionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FeExecuteActionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FeExecuteActionResponse} FeExecuteActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FeExecuteActionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FeExecuteActionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FeExecuteActionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a FeExecuteActionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FeExecuteActionResponse} FeExecuteActionResponse
                 */
                FeExecuteActionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FeExecuteActionResponse)
                        return object;
                    return new $root.infinitusai.be.FeExecuteActionResponse();
                };
    
                /**
                 * Creates a plain object from a FeExecuteActionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.FeExecuteActionResponse} message FeExecuteActionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FeExecuteActionResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this FeExecuteActionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FeExecuteActionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FeExecuteActionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FeExecuteActionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FeExecuteActionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FeExecuteActionResponse";
                };
    
                return FeExecuteActionResponse;
            })();
    
            /**
             * NlpExecutionFollowup enum.
             * @name infinitusai.be.NlpExecutionFollowup
             * @enum {number}
             * @property {number} NONE=0 NONE value
             * @property {number} HANGUP=1 HANGUP value
             * @property {number} HANGUP_AND_REQUEUE=2 HANGUP_AND_REQUEUE value
             * @property {number} HANGUP_AND_LEAVE_DISCONNECTED=3 HANGUP_AND_LEAVE_DISCONNECTED value
             */
            be.NlpExecutionFollowup = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NONE"] = 0;
                values[valuesById[1] = "HANGUP"] = 1;
                values[valuesById[2] = "HANGUP_AND_REQUEUE"] = 2;
                values[valuesById[3] = "HANGUP_AND_LEAVE_DISCONNECTED"] = 3;
                return values;
            })();
    
            be.RequeueReason = (function() {
    
                /**
                 * Properties of a RequeueReason.
                 * @memberof infinitusai.be
                 * @interface IRequeueReason
                 * @property {infinitusai.be.RequeueReason.Type|null} [type] RequeueReason type
                 */
    
                /**
                 * Constructs a new RequeueReason.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RequeueReason.
                 * @implements IRequeueReason
                 * @constructor
                 * @param {infinitusai.be.IRequeueReason=} [properties] Properties to set
                 */
                function RequeueReason(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RequeueReason type.
                 * @member {infinitusai.be.RequeueReason.Type} type
                 * @memberof infinitusai.be.RequeueReason
                 * @instance
                 */
                RequeueReason.prototype.type = 0;
    
                /**
                 * Creates a new RequeueReason instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {infinitusai.be.IRequeueReason=} [properties] Properties to set
                 * @returns {infinitusai.be.RequeueReason} RequeueReason instance
                 */
                RequeueReason.create = function create(properties) {
                    return new RequeueReason(properties);
                };
    
                /**
                 * Encodes the specified RequeueReason message. Does not implicitly {@link infinitusai.be.RequeueReason.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {infinitusai.be.IRequeueReason} message RequeueReason message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeueReason.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    return writer;
                };
    
                /**
                 * Encodes the specified RequeueReason message, length delimited. Does not implicitly {@link infinitusai.be.RequeueReason.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {infinitusai.be.IRequeueReason} message RequeueReason message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeueReason.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequeueReason message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RequeueReason} RequeueReason
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeueReason.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RequeueReason();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequeueReason message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RequeueReason} RequeueReason
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeueReason.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequeueReason message.
                 * @function verify
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequeueReason.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 29:
                        case 21:
                        case 30:
                        case 22:
                        case 23:
                        case 24:
                        case 32:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 31:
                        case 33:
                        case 34:
                        case 42:
                        case 43:
                        case 45:
                        case 46:
                        case 47:
                        case 52:
                        case 53:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 99:
                        case 35:
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 48:
                        case 49:
                        case 50:
                        case 51:
                        case 55:
                        case 56:
                        case 57:
                        case 58:
                        case 59:
                        case 44:
                        case 60:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a RequeueReason message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RequeueReason} RequeueReason
                 */
                RequeueReason.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RequeueReason)
                        return object;
                    var message = new $root.infinitusai.be.RequeueReason();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "REASON_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 1:
                        message.type = 1;
                        break;
                    case "REASON_AGENT_DID_NOT_PROVIDE_INFO":
                    case 2:
                        message.type = 2;
                        break;
                    case "REASON_AGENT_PROVIDED_UNRELIABLE_DATA":
                    case 3:
                        message.type = 3;
                        break;
                    case "REASON_AGENT_REQUESTED_UNAVAILABLE_INFO":
                    case 4:
                        message.type = 4;
                        break;
                    case "REASON_AGENT_TOO_SLOW":
                    case 5:
                        message.type = 5;
                        break;
                    case "REASON_OPERATOR_ERROR":
                    case 6:
                        message.type = 6;
                        break;
                    case "REASON_SYSTEM_ISSUE":
                    case 7:
                        message.type = 7;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER":
                    case 8:
                        message.type = 8;
                        break;
                    case "REASON_GATHERED_PIN_FOR_MEDICAL":
                    case 9:
                        message.type = 9;
                        break;
                    case "REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT":
                    case 10:
                        message.type = 10;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_BV":
                    case 11:
                        message.type = 11;
                        break;
                    case "REASON_PAYER_SYSTEM_ISSUE":
                    case 12:
                        message.type = 12;
                        break;
                    case "REASON_PAYER_UNREACHABLE":
                    case 29:
                        message.type = 29;
                        break;
                    case "REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY":
                    case 21:
                        message.type = 21;
                        break;
                    case "REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA":
                    case 30:
                        message.type = 30;
                        break;
                    case "REASON_MISSING_INPUT_INFORMATION":
                    case 22:
                        message.type = 22;
                        break;
                    case "REASON_IVR_NAVIGATION_FAILURE":
                    case 23:
                        message.type = 23;
                        break;
                    case "REASON_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 24:
                        message.type = 24;
                        break;
                    case "REASON_AGENT_AUTOMATION_HANGUP":
                    case 32:
                        message.type = 32;
                        break;
                    case "REASON_PIE_ESCALATION_NEEDED":
                    case 25:
                        message.type = 25;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER_PLAN":
                    case 26:
                        message.type = 26;
                        break;
                    case "REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED":
                    case 27:
                        message.type = 27;
                        break;
                    case "REASON_INCORRECT_INPUT_INFORMATION":
                    case 28:
                        message.type = 28;
                        break;
                    case "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD":
                    case 31:
                        message.type = 31;
                        break;
                    case "REASON_INCORRECT_PROMPT_SELECTED":
                    case 33:
                        message.type = 33;
                        break;
                    case "REASON_QUALITY_CONCERNS":
                    case 34:
                        message.type = 34;
                        break;
                    case "REASON_PROVIDER_IS_INACTIVE":
                    case 42:
                        message.type = 42;
                        break;
                    case "REASON_NO_OPERATOR_AVAILABLE":
                    case 43:
                        message.type = 43;
                        break;
                    case "REASON_NO_BENEFITS_UNDER_THIS_PLAN":
                    case 45:
                        message.type = 45;
                        break;
                    case "REASON_UNABLE_TO_REVERIFY_BENEFITS":
                    case 46:
                        message.type = 46;
                        break;
                    case "REASON_LONG_HOLD_TIME":
                    case 47:
                        message.type = 47;
                        break;
                    case "REASON_HOLD_NAVIGATION_FAILURE":
                    case 52:
                        message.type = 52;
                        break;
                    case "REASON_IVR_TIMEOUT":
                    case 53:
                        message.type = 53;
                        break;
                    case "REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK":
                    case 13:
                        message.type = 13;
                        break;
                    case "REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS":
                    case 14:
                        message.type = 14;
                        break;
                    case "REASON_INCOMPLETE_ANSWER_TO_QUESTIONS":
                    case 15:
                        message.type = 15;
                        break;
                    case "REASON_INVALID_ANSWERS_TO_QUESTIONS":
                    case 16:
                        message.type = 16;
                        break;
                    case "REASON_UNANSWERED_QUESTIONS_REMAINING":
                    case 17:
                        message.type = 17;
                        break;
                    case "REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED":
                    case 18:
                        message.type = 18;
                        break;
                    case "REASON_BOTH_IN_AND_OON_NOT_COLLECTED":
                    case 19:
                        message.type = 19;
                        break;
                    case "REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS":
                    case 20:
                        message.type = 20;
                        break;
                    case "REASON_OTHER":
                    case 99:
                        message.type = 99;
                        break;
                    case "REASON_NLP_PLAN_TERMINATED":
                    case 35:
                        message.type = 35;
                        break;
                    case "REASON_NLP_PAYER_UNREACHABLE":
                    case 36:
                        message.type = 36;
                        break;
                    case "REASON_NLP_ERROR":
                    case 37:
                        message.type = 37;
                        break;
                    case "REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 38:
                        message.type = 38;
                        break;
                    case "REASON_NLP_READY_FOR_REVIEW":
                    case 39:
                        message.type = 39;
                        break;
                    case "REASON_NLP_PAYER_SYSTEM_ISSUE":
                    case 40:
                        message.type = 40;
                        break;
                    case "REASON_NLP_CALL_NEW_NUMBER":
                    case 41:
                        message.type = 41;
                        break;
                    case "REASON_NLP_BAD_DATA":
                    case 48:
                        message.type = 48;
                        break;
                    case "REASON_NLP_AGENT_CANT_FIND_MEMBER":
                    case 49:
                        message.type = 49;
                        break;
                    case "REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 50:
                        message.type = 50;
                        break;
                    case "REASON_NLP_INPUT_NOT_RECOGNIZED":
                    case 51:
                        message.type = 51;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK":
                    case 55:
                        message.type = 55;
                        break;
                    case "REASON_NEED_TO_CALL_TPA":
                    case 56:
                        message.type = 56;
                        break;
                    case "REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT":
                    case 57:
                        message.type = 57;
                        break;
                    case "REASON_IVR_WRONG_DEPARTMENT":
                    case 58:
                        message.type = 58;
                        break;
                    case "REASON_AGENT_ISSUE":
                    case 59:
                        message.type = 59;
                        break;
                    case "REASON_NONE":
                    case 44:
                        message.type = 44;
                        break;
                    case "REASON_CHAINED_TASK":
                    case 60:
                        message.type = 60;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RequeueReason message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {infinitusai.be.RequeueReason} message RequeueReason
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequeueReason.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.type = options.enums === String ? "REASON_UNKNOWN" : 0;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.RequeueReason.Type[message.type] === undefined ? message.type : $root.infinitusai.be.RequeueReason.Type[message.type] : message.type;
                    return object;
                };
    
                /**
                 * Converts this RequeueReason to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RequeueReason
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequeueReason.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequeueReason
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RequeueReason
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequeueReason.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RequeueReason";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.RequeueReason.Type
                 * @enum {number}
                 * @property {number} REASON_UNKNOWN=0 REASON_UNKNOWN value
                 * @property {number} REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA=1 REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA value
                 * @property {number} REASON_AGENT_DID_NOT_PROVIDE_INFO=2 REASON_AGENT_DID_NOT_PROVIDE_INFO value
                 * @property {number} REASON_AGENT_PROVIDED_UNRELIABLE_DATA=3 REASON_AGENT_PROVIDED_UNRELIABLE_DATA value
                 * @property {number} REASON_AGENT_REQUESTED_UNAVAILABLE_INFO=4 REASON_AGENT_REQUESTED_UNAVAILABLE_INFO value
                 * @property {number} REASON_AGENT_TOO_SLOW=5 REASON_AGENT_TOO_SLOW value
                 * @property {number} REASON_OPERATOR_ERROR=6 REASON_OPERATOR_ERROR value
                 * @property {number} REASON_SYSTEM_ISSUE=7 REASON_SYSTEM_ISSUE value
                 * @property {number} REASON_AGENT_CANT_FIND_MEMBER=8 REASON_AGENT_CANT_FIND_MEMBER value
                 * @property {number} REASON_GATHERED_PIN_FOR_MEDICAL=9 REASON_GATHERED_PIN_FOR_MEDICAL value
                 * @property {number} REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT=10 REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT value
                 * @property {number} REASON_PROVIDED_CORRECT_NUMBER_FOR_BV=11 REASON_PROVIDED_CORRECT_NUMBER_FOR_BV value
                 * @property {number} REASON_PAYER_SYSTEM_ISSUE=12 REASON_PAYER_SYSTEM_ISSUE value
                 * @property {number} REASON_PAYER_UNREACHABLE=29 REASON_PAYER_UNREACHABLE value
                 * @property {number} REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY=21 REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY value
                 * @property {number} REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA=30 REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA value
                 * @property {number} REASON_MISSING_INPUT_INFORMATION=22 REASON_MISSING_INPUT_INFORMATION value
                 * @property {number} REASON_IVR_NAVIGATION_FAILURE=23 REASON_IVR_NAVIGATION_FAILURE value
                 * @property {number} REASON_UNEXPLAINED_OUTBOUND_HANGUP=24 REASON_UNEXPLAINED_OUTBOUND_HANGUP value
                 * @property {number} REASON_AGENT_AUTOMATION_HANGUP=32 REASON_AGENT_AUTOMATION_HANGUP value
                 * @property {number} REASON_PIE_ESCALATION_NEEDED=25 REASON_PIE_ESCALATION_NEEDED value
                 * @property {number} REASON_AGENT_CANT_FIND_MEMBER_PLAN=26 REASON_AGENT_CANT_FIND_MEMBER_PLAN value
                 * @property {number} REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED=27 REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED value
                 * @property {number} REASON_INCORRECT_INPUT_INFORMATION=28 REASON_INCORRECT_INPUT_INFORMATION value
                 * @property {number} REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD=31 REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD value
                 * @property {number} REASON_INCORRECT_PROMPT_SELECTED=33 REASON_INCORRECT_PROMPT_SELECTED value
                 * @property {number} REASON_QUALITY_CONCERNS=34 REASON_QUALITY_CONCERNS value
                 * @property {number} REASON_PROVIDER_IS_INACTIVE=42 REASON_PROVIDER_IS_INACTIVE value
                 * @property {number} REASON_NO_OPERATOR_AVAILABLE=43 REASON_NO_OPERATOR_AVAILABLE value
                 * @property {number} REASON_NO_BENEFITS_UNDER_THIS_PLAN=45 REASON_NO_BENEFITS_UNDER_THIS_PLAN value
                 * @property {number} REASON_UNABLE_TO_REVERIFY_BENEFITS=46 REASON_UNABLE_TO_REVERIFY_BENEFITS value
                 * @property {number} REASON_LONG_HOLD_TIME=47 REASON_LONG_HOLD_TIME value
                 * @property {number} REASON_HOLD_NAVIGATION_FAILURE=52 REASON_HOLD_NAVIGATION_FAILURE value
                 * @property {number} REASON_IVR_TIMEOUT=53 REASON_IVR_TIMEOUT value
                 * @property {number} REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK=13 REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK value
                 * @property {number} REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS=14 REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS value
                 * @property {number} REASON_INCOMPLETE_ANSWER_TO_QUESTIONS=15 REASON_INCOMPLETE_ANSWER_TO_QUESTIONS value
                 * @property {number} REASON_INVALID_ANSWERS_TO_QUESTIONS=16 REASON_INVALID_ANSWERS_TO_QUESTIONS value
                 * @property {number} REASON_UNANSWERED_QUESTIONS_REMAINING=17 REASON_UNANSWERED_QUESTIONS_REMAINING value
                 * @property {number} REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED=18 REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED value
                 * @property {number} REASON_BOTH_IN_AND_OON_NOT_COLLECTED=19 REASON_BOTH_IN_AND_OON_NOT_COLLECTED value
                 * @property {number} REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS=20 REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS value
                 * @property {number} REASON_OTHER=99 REASON_OTHER value
                 * @property {number} REASON_NLP_PLAN_TERMINATED=35 REASON_NLP_PLAN_TERMINATED value
                 * @property {number} REASON_NLP_PAYER_UNREACHABLE=36 REASON_NLP_PAYER_UNREACHABLE value
                 * @property {number} REASON_NLP_ERROR=37 REASON_NLP_ERROR value
                 * @property {number} REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA=38 REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA value
                 * @property {number} REASON_NLP_READY_FOR_REVIEW=39 REASON_NLP_READY_FOR_REVIEW value
                 * @property {number} REASON_NLP_PAYER_SYSTEM_ISSUE=40 REASON_NLP_PAYER_SYSTEM_ISSUE value
                 * @property {number} REASON_NLP_CALL_NEW_NUMBER=41 REASON_NLP_CALL_NEW_NUMBER value
                 * @property {number} REASON_NLP_BAD_DATA=48 REASON_NLP_BAD_DATA value
                 * @property {number} REASON_NLP_AGENT_CANT_FIND_MEMBER=49 REASON_NLP_AGENT_CANT_FIND_MEMBER value
                 * @property {number} REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP=50 REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP value
                 * @property {number} REASON_NLP_INPUT_NOT_RECOGNIZED=51 REASON_NLP_INPUT_NOT_RECOGNIZED value
                 * @property {number} REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK=55 REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK value
                 * @property {number} REASON_NEED_TO_CALL_TPA=56 REASON_NEED_TO_CALL_TPA value
                 * @property {number} REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT=57 REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT value
                 * @property {number} REASON_IVR_WRONG_DEPARTMENT=58 REASON_IVR_WRONG_DEPARTMENT value
                 * @property {number} REASON_AGENT_ISSUE=59 REASON_AGENT_ISSUE value
                 * @property {number} REASON_NONE=44 REASON_NONE value
                 * @property {number} REASON_CHAINED_TASK=60 REASON_CHAINED_TASK value
                 */
                RequeueReason.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REASON_UNKNOWN"] = 0;
                    values[valuesById[1] = "REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA"] = 1;
                    values[valuesById[2] = "REASON_AGENT_DID_NOT_PROVIDE_INFO"] = 2;
                    values[valuesById[3] = "REASON_AGENT_PROVIDED_UNRELIABLE_DATA"] = 3;
                    values[valuesById[4] = "REASON_AGENT_REQUESTED_UNAVAILABLE_INFO"] = 4;
                    values[valuesById[5] = "REASON_AGENT_TOO_SLOW"] = 5;
                    values[valuesById[6] = "REASON_OPERATOR_ERROR"] = 6;
                    values[valuesById[7] = "REASON_SYSTEM_ISSUE"] = 7;
                    values[valuesById[8] = "REASON_AGENT_CANT_FIND_MEMBER"] = 8;
                    values[valuesById[9] = "REASON_GATHERED_PIN_FOR_MEDICAL"] = 9;
                    values[valuesById[10] = "REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT"] = 10;
                    values[valuesById[11] = "REASON_PROVIDED_CORRECT_NUMBER_FOR_BV"] = 11;
                    values[valuesById[12] = "REASON_PAYER_SYSTEM_ISSUE"] = 12;
                    values[valuesById[29] = "REASON_PAYER_UNREACHABLE"] = 29;
                    values[valuesById[21] = "REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY"] = 21;
                    values[valuesById[30] = "REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA"] = 30;
                    values[valuesById[22] = "REASON_MISSING_INPUT_INFORMATION"] = 22;
                    values[valuesById[23] = "REASON_IVR_NAVIGATION_FAILURE"] = 23;
                    values[valuesById[24] = "REASON_UNEXPLAINED_OUTBOUND_HANGUP"] = 24;
                    values[valuesById[32] = "REASON_AGENT_AUTOMATION_HANGUP"] = 32;
                    values[valuesById[25] = "REASON_PIE_ESCALATION_NEEDED"] = 25;
                    values[valuesById[26] = "REASON_AGENT_CANT_FIND_MEMBER_PLAN"] = 26;
                    values[valuesById[27] = "REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED"] = 27;
                    values[valuesById[28] = "REASON_INCORRECT_INPUT_INFORMATION"] = 28;
                    values[valuesById[31] = "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD"] = 31;
                    values[valuesById[33] = "REASON_INCORRECT_PROMPT_SELECTED"] = 33;
                    values[valuesById[34] = "REASON_QUALITY_CONCERNS"] = 34;
                    values[valuesById[42] = "REASON_PROVIDER_IS_INACTIVE"] = 42;
                    values[valuesById[43] = "REASON_NO_OPERATOR_AVAILABLE"] = 43;
                    values[valuesById[45] = "REASON_NO_BENEFITS_UNDER_THIS_PLAN"] = 45;
                    values[valuesById[46] = "REASON_UNABLE_TO_REVERIFY_BENEFITS"] = 46;
                    values[valuesById[47] = "REASON_LONG_HOLD_TIME"] = 47;
                    values[valuesById[52] = "REASON_HOLD_NAVIGATION_FAILURE"] = 52;
                    values[valuesById[53] = "REASON_IVR_TIMEOUT"] = 53;
                    values[valuesById[13] = "REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK"] = 13;
                    values[valuesById[14] = "REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS"] = 14;
                    values[valuesById[15] = "REASON_INCOMPLETE_ANSWER_TO_QUESTIONS"] = 15;
                    values[valuesById[16] = "REASON_INVALID_ANSWERS_TO_QUESTIONS"] = 16;
                    values[valuesById[17] = "REASON_UNANSWERED_QUESTIONS_REMAINING"] = 17;
                    values[valuesById[18] = "REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED"] = 18;
                    values[valuesById[19] = "REASON_BOTH_IN_AND_OON_NOT_COLLECTED"] = 19;
                    values[valuesById[20] = "REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS"] = 20;
                    values[valuesById[99] = "REASON_OTHER"] = 99;
                    values[valuesById[35] = "REASON_NLP_PLAN_TERMINATED"] = 35;
                    values[valuesById[36] = "REASON_NLP_PAYER_UNREACHABLE"] = 36;
                    values[valuesById[37] = "REASON_NLP_ERROR"] = 37;
                    values[valuesById[38] = "REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA"] = 38;
                    values[valuesById[39] = "REASON_NLP_READY_FOR_REVIEW"] = 39;
                    values[valuesById[40] = "REASON_NLP_PAYER_SYSTEM_ISSUE"] = 40;
                    values[valuesById[41] = "REASON_NLP_CALL_NEW_NUMBER"] = 41;
                    values[valuesById[48] = "REASON_NLP_BAD_DATA"] = 48;
                    values[valuesById[49] = "REASON_NLP_AGENT_CANT_FIND_MEMBER"] = 49;
                    values[valuesById[50] = "REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP"] = 50;
                    values[valuesById[51] = "REASON_NLP_INPUT_NOT_RECOGNIZED"] = 51;
                    values[valuesById[55] = "REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK"] = 55;
                    values[valuesById[56] = "REASON_NEED_TO_CALL_TPA"] = 56;
                    values[valuesById[57] = "REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT"] = 57;
                    values[valuesById[58] = "REASON_IVR_WRONG_DEPARTMENT"] = 58;
                    values[valuesById[59] = "REASON_AGENT_ISSUE"] = 59;
                    values[valuesById[44] = "REASON_NONE"] = 44;
                    values[valuesById[60] = "REASON_CHAINED_TASK"] = 60;
                    return values;
                })();
    
                return RequeueReason;
            })();
    
            be.Action = (function() {
    
                /**
                 * Properties of an Action.
                 * @memberof infinitusai.be
                 * @interface IAction
                 * @property {infinitusai.be.ActionSource|null} [actionSource] Action actionSource
                 * @property {infinitusai.be.ISpeechAction|null} [speechAction] Action speechAction
                 * @property {infinitusai.be.IDtmfAction|null} [dtmfAction] Action dtmfAction
                 * @property {infinitusai.be.IRecordedAudioAction|null} [recordedAudioAction] Action recordedAudioAction
                 * @property {string|null} [actionName] Action actionName
                 * @property {boolean|null} [stopRecordingAction] Action stopRecordingAction
                 * @property {string|null} [suggestionId] Action suggestionId
                 * @property {infinitusai.be.SuggestionCategory|null} [suggestionCategory] Action suggestionCategory
                 * @property {boolean|null} [isCallSpecific] Action isCallSpecific
                 * @property {infinitusai.be.NlpExecutionFollowup|null} [followup] Action followup
                 * @property {string|null} [taskNoteUpdate] Action taskNoteUpdate
                 * @property {infinitusai.be.RequeueReason.Type|null} [hangupReason] Action hangupReason
                 * @property {string|null} [requeueNumber] Action requeueNumber
                 * @property {string|null} [requestId] Action requestId
                 * @property {string|null} [nlpEnvValue] Action nlpEnvValue
                 */
    
                /**
                 * Constructs a new Action.
                 * @memberof infinitusai.be
                 * @classdesc Represents an Action.
                 * @implements IAction
                 * @constructor
                 * @param {infinitusai.be.IAction=} [properties] Properties to set
                 */
                function Action(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Action actionSource.
                 * @member {infinitusai.be.ActionSource} actionSource
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.actionSource = 0;
    
                /**
                 * Action speechAction.
                 * @member {infinitusai.be.ISpeechAction|null|undefined} speechAction
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.speechAction = null;
    
                /**
                 * Action dtmfAction.
                 * @member {infinitusai.be.IDtmfAction|null|undefined} dtmfAction
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.dtmfAction = null;
    
                /**
                 * Action recordedAudioAction.
                 * @member {infinitusai.be.IRecordedAudioAction|null|undefined} recordedAudioAction
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.recordedAudioAction = null;
    
                /**
                 * Action actionName.
                 * @member {string} actionName
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.actionName = "";
    
                /**
                 * Action stopRecordingAction.
                 * @member {boolean} stopRecordingAction
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.stopRecordingAction = false;
    
                /**
                 * Action suggestionId.
                 * @member {string} suggestionId
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.suggestionId = "";
    
                /**
                 * Action suggestionCategory.
                 * @member {infinitusai.be.SuggestionCategory} suggestionCategory
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.suggestionCategory = 0;
    
                /**
                 * Action isCallSpecific.
                 * @member {boolean} isCallSpecific
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.isCallSpecific = false;
    
                /**
                 * Action followup.
                 * @member {infinitusai.be.NlpExecutionFollowup} followup
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.followup = 0;
    
                /**
                 * Action taskNoteUpdate.
                 * @member {string} taskNoteUpdate
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.taskNoteUpdate = "";
    
                /**
                 * Action hangupReason.
                 * @member {infinitusai.be.RequeueReason.Type} hangupReason
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.hangupReason = 0;
    
                /**
                 * Action requeueNumber.
                 * @member {string} requeueNumber
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.requeueNumber = "";
    
                /**
                 * Action requestId.
                 * @member {string} requestId
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.requestId = "";
    
                /**
                 * Action nlpEnvValue.
                 * @member {string} nlpEnvValue
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Action.prototype.nlpEnvValue = "";
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * Action action.
                 * @member {"speechAction"|"dtmfAction"|"recordedAudioAction"|undefined} action
                 * @memberof infinitusai.be.Action
                 * @instance
                 */
                Object.defineProperty(Action.prototype, "action", {
                    get: $util.oneOfGetter($oneOfFields = ["speechAction", "dtmfAction", "recordedAudioAction"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new Action instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {infinitusai.be.IAction=} [properties] Properties to set
                 * @returns {infinitusai.be.Action} Action instance
                 */
                Action.create = function create(properties) {
                    return new Action(properties);
                };
    
                /**
                 * Encodes the specified Action message. Does not implicitly {@link infinitusai.be.Action.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {infinitusai.be.IAction} message Action message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Action.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.actionSource != null && Object.hasOwnProperty.call(message, "actionSource"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.actionSource);
                    if (message.speechAction != null && Object.hasOwnProperty.call(message, "speechAction"))
                        $root.infinitusai.be.SpeechAction.encode(message.speechAction, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.dtmfAction != null && Object.hasOwnProperty.call(message, "dtmfAction"))
                        $root.infinitusai.be.DtmfAction.encode(message.dtmfAction, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.actionName);
                    if (message.stopRecordingAction != null && Object.hasOwnProperty.call(message, "stopRecordingAction"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.stopRecordingAction);
                    if (message.suggestionId != null && Object.hasOwnProperty.call(message, "suggestionId"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.suggestionId);
                    if (message.suggestionCategory != null && Object.hasOwnProperty.call(message, "suggestionCategory"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.suggestionCategory);
                    if (message.isCallSpecific != null && Object.hasOwnProperty.call(message, "isCallSpecific"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isCallSpecific);
                    if (message.followup != null && Object.hasOwnProperty.call(message, "followup"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.followup);
                    if (message.taskNoteUpdate != null && Object.hasOwnProperty.call(message, "taskNoteUpdate"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.taskNoteUpdate);
                    if (message.recordedAudioAction != null && Object.hasOwnProperty.call(message, "recordedAudioAction"))
                        $root.infinitusai.be.RecordedAudioAction.encode(message.recordedAudioAction, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.hangupReason != null && Object.hasOwnProperty.call(message, "hangupReason"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.hangupReason);
                    if (message.requeueNumber != null && Object.hasOwnProperty.call(message, "requeueNumber"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.requeueNumber);
                    if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.requestId);
                    if (message.nlpEnvValue != null && Object.hasOwnProperty.call(message, "nlpEnvValue"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.nlpEnvValue);
                    return writer;
                };
    
                /**
                 * Encodes the specified Action message, length delimited. Does not implicitly {@link infinitusai.be.Action.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {infinitusai.be.IAction} message Action message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Action.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Action message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Action} Action
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Action.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Action();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.actionSource = reader.int32();
                                break;
                            }
                        case 2: {
                                message.speechAction = $root.infinitusai.be.SpeechAction.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.dtmfAction = $root.infinitusai.be.DtmfAction.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.recordedAudioAction = $root.infinitusai.be.RecordedAudioAction.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.actionName = reader.string();
                                break;
                            }
                        case 5: {
                                message.stopRecordingAction = reader.bool();
                                break;
                            }
                        case 6: {
                                message.suggestionId = reader.string();
                                break;
                            }
                        case 7: {
                                message.suggestionCategory = reader.int32();
                                break;
                            }
                        case 8: {
                                message.isCallSpecific = reader.bool();
                                break;
                            }
                        case 9: {
                                message.followup = reader.int32();
                                break;
                            }
                        case 10: {
                                message.taskNoteUpdate = reader.string();
                                break;
                            }
                        case 12: {
                                message.hangupReason = reader.int32();
                                break;
                            }
                        case 13: {
                                message.requeueNumber = reader.string();
                                break;
                            }
                        case 14: {
                                message.requestId = reader.string();
                                break;
                            }
                        case 15: {
                                message.nlpEnvValue = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Action message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Action} Action
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Action.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Action message.
                 * @function verify
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Action.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.actionSource != null && message.hasOwnProperty("actionSource"))
                        switch (message.actionSource) {
                        default:
                            return "actionSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.speechAction != null && message.hasOwnProperty("speechAction")) {
                        properties.action = 1;
                        {
                            var error = $root.infinitusai.be.SpeechAction.verify(message.speechAction);
                            if (error)
                                return "speechAction." + error;
                        }
                    }
                    if (message.dtmfAction != null && message.hasOwnProperty("dtmfAction")) {
                        if (properties.action === 1)
                            return "action: multiple values";
                        properties.action = 1;
                        {
                            var error = $root.infinitusai.be.DtmfAction.verify(message.dtmfAction);
                            if (error)
                                return "dtmfAction." + error;
                        }
                    }
                    if (message.recordedAudioAction != null && message.hasOwnProperty("recordedAudioAction")) {
                        if (properties.action === 1)
                            return "action: multiple values";
                        properties.action = 1;
                        {
                            var error = $root.infinitusai.be.RecordedAudioAction.verify(message.recordedAudioAction);
                            if (error)
                                return "recordedAudioAction." + error;
                        }
                    }
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        if (!$util.isString(message.actionName))
                            return "actionName: string expected";
                    if (message.stopRecordingAction != null && message.hasOwnProperty("stopRecordingAction"))
                        if (typeof message.stopRecordingAction !== "boolean")
                            return "stopRecordingAction: boolean expected";
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        if (!$util.isString(message.suggestionId))
                            return "suggestionId: string expected";
                    if (message.suggestionCategory != null && message.hasOwnProperty("suggestionCategory"))
                        switch (message.suggestionCategory) {
                        default:
                            return "suggestionCategory: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.isCallSpecific != null && message.hasOwnProperty("isCallSpecific"))
                        if (typeof message.isCallSpecific !== "boolean")
                            return "isCallSpecific: boolean expected";
                    if (message.followup != null && message.hasOwnProperty("followup"))
                        switch (message.followup) {
                        default:
                            return "followup: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.taskNoteUpdate != null && message.hasOwnProperty("taskNoteUpdate"))
                        if (!$util.isString(message.taskNoteUpdate))
                            return "taskNoteUpdate: string expected";
                    if (message.hangupReason != null && message.hasOwnProperty("hangupReason"))
                        switch (message.hangupReason) {
                        default:
                            return "hangupReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 29:
                        case 21:
                        case 30:
                        case 22:
                        case 23:
                        case 24:
                        case 32:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 31:
                        case 33:
                        case 34:
                        case 42:
                        case 43:
                        case 45:
                        case 46:
                        case 47:
                        case 52:
                        case 53:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 99:
                        case 35:
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 48:
                        case 49:
                        case 50:
                        case 51:
                        case 55:
                        case 56:
                        case 57:
                        case 58:
                        case 59:
                        case 44:
                        case 60:
                            break;
                        }
                    if (message.requeueNumber != null && message.hasOwnProperty("requeueNumber"))
                        if (!$util.isString(message.requeueNumber))
                            return "requeueNumber: string expected";
                    if (message.requestId != null && message.hasOwnProperty("requestId"))
                        if (!$util.isString(message.requestId))
                            return "requestId: string expected";
                    if (message.nlpEnvValue != null && message.hasOwnProperty("nlpEnvValue"))
                        if (!$util.isString(message.nlpEnvValue))
                            return "nlpEnvValue: string expected";
                    return null;
                };
    
                /**
                 * Creates an Action message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Action} Action
                 */
                Action.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Action)
                        return object;
                    var message = new $root.infinitusai.be.Action();
                    switch (object.actionSource) {
                    default:
                        if (typeof object.actionSource === "number") {
                            message.actionSource = object.actionSource;
                            break;
                        }
                        break;
                    case "ACTION_SOURCE_UNKNOWN":
                    case 0:
                        message.actionSource = 0;
                        break;
                    case "ACTION_SOURCE_KEYBOARD":
                    case 1:
                        message.actionSource = 1;
                        break;
                    case "ACTION_SOURCE_TOP_SUGGESTION":
                    case 2:
                        message.actionSource = 2;
                        break;
                    case "ACTION_SOURCE_SUGGESTION":
                    case 3:
                        message.actionSource = 3;
                        break;
                    case "ACTION_SOURCE_AUTO_RESPOND":
                    case 4:
                        message.actionSource = 4;
                        break;
                    case "ACTION_SOURCE_FOLLOWUP_SUGGESTION":
                    case 5:
                        message.actionSource = 5;
                        break;
                    case "ACTION_SOURCE_RECORDED_AUDIO":
                    case 6:
                        message.actionSource = 6;
                        break;
                    }
                    if (object.speechAction != null) {
                        if (typeof object.speechAction !== "object")
                            throw TypeError(".infinitusai.be.Action.speechAction: object expected");
                        message.speechAction = $root.infinitusai.be.SpeechAction.fromObject(object.speechAction);
                    }
                    if (object.dtmfAction != null) {
                        if (typeof object.dtmfAction !== "object")
                            throw TypeError(".infinitusai.be.Action.dtmfAction: object expected");
                        message.dtmfAction = $root.infinitusai.be.DtmfAction.fromObject(object.dtmfAction);
                    }
                    if (object.recordedAudioAction != null) {
                        if (typeof object.recordedAudioAction !== "object")
                            throw TypeError(".infinitusai.be.Action.recordedAudioAction: object expected");
                        message.recordedAudioAction = $root.infinitusai.be.RecordedAudioAction.fromObject(object.recordedAudioAction);
                    }
                    if (object.actionName != null)
                        message.actionName = String(object.actionName);
                    if (object.stopRecordingAction != null)
                        message.stopRecordingAction = Boolean(object.stopRecordingAction);
                    if (object.suggestionId != null)
                        message.suggestionId = String(object.suggestionId);
                    switch (object.suggestionCategory) {
                    default:
                        if (typeof object.suggestionCategory === "number") {
                            message.suggestionCategory = object.suggestionCategory;
                            break;
                        }
                        break;
                    case "SUGGESTION_CATEGORY_UNKNOWN":
                    case 0:
                        message.suggestionCategory = 0;
                        break;
                    case "SUGGESTION_CATEGORY_CHITCHAT":
                    case 1:
                        message.suggestionCategory = 1;
                        break;
                    case "SUGGESTION_CATEGORY_IVR":
                    case 2:
                        message.suggestionCategory = 2;
                        break;
                    case "SUGGESTION_CATEGORY_ANSWERS":
                    case 3:
                        message.suggestionCategory = 3;
                        break;
                    case "SUGGESTION_CATEGORY_QUESTIONS":
                    case 4:
                        message.suggestionCategory = 4;
                        break;
                    case "SUGGESTION_CATEGORY_PUSHBACK":
                    case 5:
                        message.suggestionCategory = 5;
                        break;
                    }
                    if (object.isCallSpecific != null)
                        message.isCallSpecific = Boolean(object.isCallSpecific);
                    switch (object.followup) {
                    default:
                        if (typeof object.followup === "number") {
                            message.followup = object.followup;
                            break;
                        }
                        break;
                    case "NONE":
                    case 0:
                        message.followup = 0;
                        break;
                    case "HANGUP":
                    case 1:
                        message.followup = 1;
                        break;
                    case "HANGUP_AND_REQUEUE":
                    case 2:
                        message.followup = 2;
                        break;
                    case "HANGUP_AND_LEAVE_DISCONNECTED":
                    case 3:
                        message.followup = 3;
                        break;
                    }
                    if (object.taskNoteUpdate != null)
                        message.taskNoteUpdate = String(object.taskNoteUpdate);
                    switch (object.hangupReason) {
                    default:
                        if (typeof object.hangupReason === "number") {
                            message.hangupReason = object.hangupReason;
                            break;
                        }
                        break;
                    case "REASON_UNKNOWN":
                    case 0:
                        message.hangupReason = 0;
                        break;
                    case "REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 1:
                        message.hangupReason = 1;
                        break;
                    case "REASON_AGENT_DID_NOT_PROVIDE_INFO":
                    case 2:
                        message.hangupReason = 2;
                        break;
                    case "REASON_AGENT_PROVIDED_UNRELIABLE_DATA":
                    case 3:
                        message.hangupReason = 3;
                        break;
                    case "REASON_AGENT_REQUESTED_UNAVAILABLE_INFO":
                    case 4:
                        message.hangupReason = 4;
                        break;
                    case "REASON_AGENT_TOO_SLOW":
                    case 5:
                        message.hangupReason = 5;
                        break;
                    case "REASON_OPERATOR_ERROR":
                    case 6:
                        message.hangupReason = 6;
                        break;
                    case "REASON_SYSTEM_ISSUE":
                    case 7:
                        message.hangupReason = 7;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER":
                    case 8:
                        message.hangupReason = 8;
                        break;
                    case "REASON_GATHERED_PIN_FOR_MEDICAL":
                    case 9:
                        message.hangupReason = 9;
                        break;
                    case "REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT":
                    case 10:
                        message.hangupReason = 10;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_BV":
                    case 11:
                        message.hangupReason = 11;
                        break;
                    case "REASON_PAYER_SYSTEM_ISSUE":
                    case 12:
                        message.hangupReason = 12;
                        break;
                    case "REASON_PAYER_UNREACHABLE":
                    case 29:
                        message.hangupReason = 29;
                        break;
                    case "REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY":
                    case 21:
                        message.hangupReason = 21;
                        break;
                    case "REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA":
                    case 30:
                        message.hangupReason = 30;
                        break;
                    case "REASON_MISSING_INPUT_INFORMATION":
                    case 22:
                        message.hangupReason = 22;
                        break;
                    case "REASON_IVR_NAVIGATION_FAILURE":
                    case 23:
                        message.hangupReason = 23;
                        break;
                    case "REASON_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 24:
                        message.hangupReason = 24;
                        break;
                    case "REASON_AGENT_AUTOMATION_HANGUP":
                    case 32:
                        message.hangupReason = 32;
                        break;
                    case "REASON_PIE_ESCALATION_NEEDED":
                    case 25:
                        message.hangupReason = 25;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER_PLAN":
                    case 26:
                        message.hangupReason = 26;
                        break;
                    case "REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED":
                    case 27:
                        message.hangupReason = 27;
                        break;
                    case "REASON_INCORRECT_INPUT_INFORMATION":
                    case 28:
                        message.hangupReason = 28;
                        break;
                    case "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD":
                    case 31:
                        message.hangupReason = 31;
                        break;
                    case "REASON_INCORRECT_PROMPT_SELECTED":
                    case 33:
                        message.hangupReason = 33;
                        break;
                    case "REASON_QUALITY_CONCERNS":
                    case 34:
                        message.hangupReason = 34;
                        break;
                    case "REASON_PROVIDER_IS_INACTIVE":
                    case 42:
                        message.hangupReason = 42;
                        break;
                    case "REASON_NO_OPERATOR_AVAILABLE":
                    case 43:
                        message.hangupReason = 43;
                        break;
                    case "REASON_NO_BENEFITS_UNDER_THIS_PLAN":
                    case 45:
                        message.hangupReason = 45;
                        break;
                    case "REASON_UNABLE_TO_REVERIFY_BENEFITS":
                    case 46:
                        message.hangupReason = 46;
                        break;
                    case "REASON_LONG_HOLD_TIME":
                    case 47:
                        message.hangupReason = 47;
                        break;
                    case "REASON_HOLD_NAVIGATION_FAILURE":
                    case 52:
                        message.hangupReason = 52;
                        break;
                    case "REASON_IVR_TIMEOUT":
                    case 53:
                        message.hangupReason = 53;
                        break;
                    case "REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK":
                    case 13:
                        message.hangupReason = 13;
                        break;
                    case "REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS":
                    case 14:
                        message.hangupReason = 14;
                        break;
                    case "REASON_INCOMPLETE_ANSWER_TO_QUESTIONS":
                    case 15:
                        message.hangupReason = 15;
                        break;
                    case "REASON_INVALID_ANSWERS_TO_QUESTIONS":
                    case 16:
                        message.hangupReason = 16;
                        break;
                    case "REASON_UNANSWERED_QUESTIONS_REMAINING":
                    case 17:
                        message.hangupReason = 17;
                        break;
                    case "REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED":
                    case 18:
                        message.hangupReason = 18;
                        break;
                    case "REASON_BOTH_IN_AND_OON_NOT_COLLECTED":
                    case 19:
                        message.hangupReason = 19;
                        break;
                    case "REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS":
                    case 20:
                        message.hangupReason = 20;
                        break;
                    case "REASON_OTHER":
                    case 99:
                        message.hangupReason = 99;
                        break;
                    case "REASON_NLP_PLAN_TERMINATED":
                    case 35:
                        message.hangupReason = 35;
                        break;
                    case "REASON_NLP_PAYER_UNREACHABLE":
                    case 36:
                        message.hangupReason = 36;
                        break;
                    case "REASON_NLP_ERROR":
                    case 37:
                        message.hangupReason = 37;
                        break;
                    case "REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 38:
                        message.hangupReason = 38;
                        break;
                    case "REASON_NLP_READY_FOR_REVIEW":
                    case 39:
                        message.hangupReason = 39;
                        break;
                    case "REASON_NLP_PAYER_SYSTEM_ISSUE":
                    case 40:
                        message.hangupReason = 40;
                        break;
                    case "REASON_NLP_CALL_NEW_NUMBER":
                    case 41:
                        message.hangupReason = 41;
                        break;
                    case "REASON_NLP_BAD_DATA":
                    case 48:
                        message.hangupReason = 48;
                        break;
                    case "REASON_NLP_AGENT_CANT_FIND_MEMBER":
                    case 49:
                        message.hangupReason = 49;
                        break;
                    case "REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 50:
                        message.hangupReason = 50;
                        break;
                    case "REASON_NLP_INPUT_NOT_RECOGNIZED":
                    case 51:
                        message.hangupReason = 51;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK":
                    case 55:
                        message.hangupReason = 55;
                        break;
                    case "REASON_NEED_TO_CALL_TPA":
                    case 56:
                        message.hangupReason = 56;
                        break;
                    case "REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT":
                    case 57:
                        message.hangupReason = 57;
                        break;
                    case "REASON_IVR_WRONG_DEPARTMENT":
                    case 58:
                        message.hangupReason = 58;
                        break;
                    case "REASON_AGENT_ISSUE":
                    case 59:
                        message.hangupReason = 59;
                        break;
                    case "REASON_NONE":
                    case 44:
                        message.hangupReason = 44;
                        break;
                    case "REASON_CHAINED_TASK":
                    case 60:
                        message.hangupReason = 60;
                        break;
                    }
                    if (object.requeueNumber != null)
                        message.requeueNumber = String(object.requeueNumber);
                    if (object.requestId != null)
                        message.requestId = String(object.requestId);
                    if (object.nlpEnvValue != null)
                        message.nlpEnvValue = String(object.nlpEnvValue);
                    return message;
                };
    
                /**
                 * Creates a plain object from an Action message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {infinitusai.be.Action} message Action
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Action.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.actionSource = options.enums === String ? "ACTION_SOURCE_UNKNOWN" : 0;
                        object.actionName = "";
                        object.stopRecordingAction = false;
                        object.suggestionId = "";
                        object.suggestionCategory = options.enums === String ? "SUGGESTION_CATEGORY_UNKNOWN" : 0;
                        object.isCallSpecific = false;
                        object.followup = options.enums === String ? "NONE" : 0;
                        object.taskNoteUpdate = "";
                        object.hangupReason = options.enums === String ? "REASON_UNKNOWN" : 0;
                        object.requeueNumber = "";
                        object.requestId = "";
                        object.nlpEnvValue = "";
                    }
                    if (message.actionSource != null && message.hasOwnProperty("actionSource"))
                        object.actionSource = options.enums === String ? $root.infinitusai.be.ActionSource[message.actionSource] === undefined ? message.actionSource : $root.infinitusai.be.ActionSource[message.actionSource] : message.actionSource;
                    if (message.speechAction != null && message.hasOwnProperty("speechAction")) {
                        object.speechAction = $root.infinitusai.be.SpeechAction.toObject(message.speechAction, options);
                        if (options.oneofs)
                            object.action = "speechAction";
                    }
                    if (message.dtmfAction != null && message.hasOwnProperty("dtmfAction")) {
                        object.dtmfAction = $root.infinitusai.be.DtmfAction.toObject(message.dtmfAction, options);
                        if (options.oneofs)
                            object.action = "dtmfAction";
                    }
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        object.actionName = message.actionName;
                    if (message.stopRecordingAction != null && message.hasOwnProperty("stopRecordingAction"))
                        object.stopRecordingAction = message.stopRecordingAction;
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        object.suggestionId = message.suggestionId;
                    if (message.suggestionCategory != null && message.hasOwnProperty("suggestionCategory"))
                        object.suggestionCategory = options.enums === String ? $root.infinitusai.be.SuggestionCategory[message.suggestionCategory] === undefined ? message.suggestionCategory : $root.infinitusai.be.SuggestionCategory[message.suggestionCategory] : message.suggestionCategory;
                    if (message.isCallSpecific != null && message.hasOwnProperty("isCallSpecific"))
                        object.isCallSpecific = message.isCallSpecific;
                    if (message.followup != null && message.hasOwnProperty("followup"))
                        object.followup = options.enums === String ? $root.infinitusai.be.NlpExecutionFollowup[message.followup] === undefined ? message.followup : $root.infinitusai.be.NlpExecutionFollowup[message.followup] : message.followup;
                    if (message.taskNoteUpdate != null && message.hasOwnProperty("taskNoteUpdate"))
                        object.taskNoteUpdate = message.taskNoteUpdate;
                    if (message.recordedAudioAction != null && message.hasOwnProperty("recordedAudioAction")) {
                        object.recordedAudioAction = $root.infinitusai.be.RecordedAudioAction.toObject(message.recordedAudioAction, options);
                        if (options.oneofs)
                            object.action = "recordedAudioAction";
                    }
                    if (message.hangupReason != null && message.hasOwnProperty("hangupReason"))
                        object.hangupReason = options.enums === String ? $root.infinitusai.be.RequeueReason.Type[message.hangupReason] === undefined ? message.hangupReason : $root.infinitusai.be.RequeueReason.Type[message.hangupReason] : message.hangupReason;
                    if (message.requeueNumber != null && message.hasOwnProperty("requeueNumber"))
                        object.requeueNumber = message.requeueNumber;
                    if (message.requestId != null && message.hasOwnProperty("requestId"))
                        object.requestId = message.requestId;
                    if (message.nlpEnvValue != null && message.hasOwnProperty("nlpEnvValue"))
                        object.nlpEnvValue = message.nlpEnvValue;
                    return object;
                };
    
                /**
                 * Converts this Action to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Action
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Action.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Action
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Action
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Action.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Action";
                };
    
                return Action;
            })();
    
            /**
             * SuggestionCategory enum.
             * @name infinitusai.be.SuggestionCategory
             * @enum {number}
             * @property {number} SUGGESTION_CATEGORY_UNKNOWN=0 SUGGESTION_CATEGORY_UNKNOWN value
             * @property {number} SUGGESTION_CATEGORY_CHITCHAT=1 SUGGESTION_CATEGORY_CHITCHAT value
             * @property {number} SUGGESTION_CATEGORY_IVR=2 SUGGESTION_CATEGORY_IVR value
             * @property {number} SUGGESTION_CATEGORY_ANSWERS=3 SUGGESTION_CATEGORY_ANSWERS value
             * @property {number} SUGGESTION_CATEGORY_QUESTIONS=4 SUGGESTION_CATEGORY_QUESTIONS value
             * @property {number} SUGGESTION_CATEGORY_PUSHBACK=5 SUGGESTION_CATEGORY_PUSHBACK value
             */
            be.SuggestionCategory = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUGGESTION_CATEGORY_UNKNOWN"] = 0;
                values[valuesById[1] = "SUGGESTION_CATEGORY_CHITCHAT"] = 1;
                values[valuesById[2] = "SUGGESTION_CATEGORY_IVR"] = 2;
                values[valuesById[3] = "SUGGESTION_CATEGORY_ANSWERS"] = 3;
                values[valuesById[4] = "SUGGESTION_CATEGORY_QUESTIONS"] = 4;
                values[valuesById[5] = "SUGGESTION_CATEGORY_PUSHBACK"] = 5;
                return values;
            })();
    
            /**
             * SuggestionSubcategory enum.
             * @name infinitusai.be.SuggestionSubcategory
             * @enum {number}
             * @property {number} SUGGESTION_SUBCATEGORY_UNKNOWN=0 SUGGESTION_SUBCATEGORY_UNKNOWN value
             * @property {number} SUGGESTION_SUBCATEGORY_PLAN_INFO=1 SUGGESTION_SUBCATEGORY_PLAN_INFO value
             * @property {number} SUGGESTION_SUBCATEGORY_OTHER_INSURANCE=3 SUGGESTION_SUBCATEGORY_OTHER_INSURANCE value
             * @property {number} SUGGESTION_SUBCATEGORY_PLAN_DESIGN=4 SUGGESTION_SUBCATEGORY_PLAN_DESIGN value
             * @property {number} SUGGESTION_SUBCATEGORY_COST_SHARE=5 SUGGESTION_SUBCATEGORY_COST_SHARE value
             * @property {number} SUGGESTION_SUBCATEGORY_ACCESS_INFORMATION=6 SUGGESTION_SUBCATEGORY_ACCESS_INFORMATION value
             */
            be.SuggestionSubcategory = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUGGESTION_SUBCATEGORY_UNKNOWN"] = 0;
                values[valuesById[1] = "SUGGESTION_SUBCATEGORY_PLAN_INFO"] = 1;
                values[valuesById[3] = "SUGGESTION_SUBCATEGORY_OTHER_INSURANCE"] = 3;
                values[valuesById[4] = "SUGGESTION_SUBCATEGORY_PLAN_DESIGN"] = 4;
                values[valuesById[5] = "SUGGESTION_SUBCATEGORY_COST_SHARE"] = 5;
                values[valuesById[6] = "SUGGESTION_SUBCATEGORY_ACCESS_INFORMATION"] = 6;
                return values;
            })();
    
            be.FollowupSuggestion = (function() {
    
                /**
                 * Properties of a FollowupSuggestion.
                 * @memberof infinitusai.be
                 * @interface IFollowupSuggestion
                 * @property {string|null} [intentName] FollowupSuggestion intentName
                 * @property {string|null} [textUtterance] FollowupSuggestion textUtterance
                 * @property {string|null} [dtmfDigits] FollowupSuggestion dtmfDigits
                 * @property {infinitusai.be.SuggestionCategory|null} [category] FollowupSuggestion category
                 * @property {string|null} [suggestionId] FollowupSuggestion suggestionId
                 * @property {boolean|null} [isCallSpecific] FollowupSuggestion isCallSpecific
                 */
    
                /**
                 * Constructs a new FollowupSuggestion.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FollowupSuggestion.
                 * @implements IFollowupSuggestion
                 * @constructor
                 * @param {infinitusai.be.IFollowupSuggestion=} [properties] Properties to set
                 */
                function FollowupSuggestion(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FollowupSuggestion intentName.
                 * @member {string} intentName
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @instance
                 */
                FollowupSuggestion.prototype.intentName = "";
    
                /**
                 * FollowupSuggestion textUtterance.
                 * @member {string} textUtterance
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @instance
                 */
                FollowupSuggestion.prototype.textUtterance = "";
    
                /**
                 * FollowupSuggestion dtmfDigits.
                 * @member {string} dtmfDigits
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @instance
                 */
                FollowupSuggestion.prototype.dtmfDigits = "";
    
                /**
                 * FollowupSuggestion category.
                 * @member {infinitusai.be.SuggestionCategory} category
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @instance
                 */
                FollowupSuggestion.prototype.category = 0;
    
                /**
                 * FollowupSuggestion suggestionId.
                 * @member {string} suggestionId
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @instance
                 */
                FollowupSuggestion.prototype.suggestionId = "";
    
                /**
                 * FollowupSuggestion isCallSpecific.
                 * @member {boolean} isCallSpecific
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @instance
                 */
                FollowupSuggestion.prototype.isCallSpecific = false;
    
                /**
                 * Creates a new FollowupSuggestion instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {infinitusai.be.IFollowupSuggestion=} [properties] Properties to set
                 * @returns {infinitusai.be.FollowupSuggestion} FollowupSuggestion instance
                 */
                FollowupSuggestion.create = function create(properties) {
                    return new FollowupSuggestion(properties);
                };
    
                /**
                 * Encodes the specified FollowupSuggestion message. Does not implicitly {@link infinitusai.be.FollowupSuggestion.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {infinitusai.be.IFollowupSuggestion} message FollowupSuggestion message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FollowupSuggestion.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.intentName != null && Object.hasOwnProperty.call(message, "intentName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.intentName);
                    if (message.textUtterance != null && Object.hasOwnProperty.call(message, "textUtterance"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.textUtterance);
                    if (message.dtmfDigits != null && Object.hasOwnProperty.call(message, "dtmfDigits"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dtmfDigits);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.category);
                    if (message.suggestionId != null && Object.hasOwnProperty.call(message, "suggestionId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.suggestionId);
                    if (message.isCallSpecific != null && Object.hasOwnProperty.call(message, "isCallSpecific"))
                        writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isCallSpecific);
                    return writer;
                };
    
                /**
                 * Encodes the specified FollowupSuggestion message, length delimited. Does not implicitly {@link infinitusai.be.FollowupSuggestion.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {infinitusai.be.IFollowupSuggestion} message FollowupSuggestion message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FollowupSuggestion.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FollowupSuggestion message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FollowupSuggestion} FollowupSuggestion
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FollowupSuggestion.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FollowupSuggestion();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.intentName = reader.string();
                                break;
                            }
                        case 2: {
                                message.textUtterance = reader.string();
                                break;
                            }
                        case 3: {
                                message.dtmfDigits = reader.string();
                                break;
                            }
                        case 4: {
                                message.category = reader.int32();
                                break;
                            }
                        case 5: {
                                message.suggestionId = reader.string();
                                break;
                            }
                        case 11: {
                                message.isCallSpecific = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FollowupSuggestion message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FollowupSuggestion} FollowupSuggestion
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FollowupSuggestion.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FollowupSuggestion message.
                 * @function verify
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FollowupSuggestion.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.intentName != null && message.hasOwnProperty("intentName"))
                        if (!$util.isString(message.intentName))
                            return "intentName: string expected";
                    if (message.textUtterance != null && message.hasOwnProperty("textUtterance"))
                        if (!$util.isString(message.textUtterance))
                            return "textUtterance: string expected";
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits"))
                        if (!$util.isString(message.dtmfDigits))
                            return "dtmfDigits: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        switch (message.category) {
                        default:
                            return "category: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        if (!$util.isString(message.suggestionId))
                            return "suggestionId: string expected";
                    if (message.isCallSpecific != null && message.hasOwnProperty("isCallSpecific"))
                        if (typeof message.isCallSpecific !== "boolean")
                            return "isCallSpecific: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a FollowupSuggestion message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FollowupSuggestion} FollowupSuggestion
                 */
                FollowupSuggestion.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FollowupSuggestion)
                        return object;
                    var message = new $root.infinitusai.be.FollowupSuggestion();
                    if (object.intentName != null)
                        message.intentName = String(object.intentName);
                    if (object.textUtterance != null)
                        message.textUtterance = String(object.textUtterance);
                    if (object.dtmfDigits != null)
                        message.dtmfDigits = String(object.dtmfDigits);
                    switch (object.category) {
                    default:
                        if (typeof object.category === "number") {
                            message.category = object.category;
                            break;
                        }
                        break;
                    case "SUGGESTION_CATEGORY_UNKNOWN":
                    case 0:
                        message.category = 0;
                        break;
                    case "SUGGESTION_CATEGORY_CHITCHAT":
                    case 1:
                        message.category = 1;
                        break;
                    case "SUGGESTION_CATEGORY_IVR":
                    case 2:
                        message.category = 2;
                        break;
                    case "SUGGESTION_CATEGORY_ANSWERS":
                    case 3:
                        message.category = 3;
                        break;
                    case "SUGGESTION_CATEGORY_QUESTIONS":
                    case 4:
                        message.category = 4;
                        break;
                    case "SUGGESTION_CATEGORY_PUSHBACK":
                    case 5:
                        message.category = 5;
                        break;
                    }
                    if (object.suggestionId != null)
                        message.suggestionId = String(object.suggestionId);
                    if (object.isCallSpecific != null)
                        message.isCallSpecific = Boolean(object.isCallSpecific);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FollowupSuggestion message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {infinitusai.be.FollowupSuggestion} message FollowupSuggestion
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FollowupSuggestion.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.intentName = "";
                        object.textUtterance = "";
                        object.dtmfDigits = "";
                        object.category = options.enums === String ? "SUGGESTION_CATEGORY_UNKNOWN" : 0;
                        object.suggestionId = "";
                        object.isCallSpecific = false;
                    }
                    if (message.intentName != null && message.hasOwnProperty("intentName"))
                        object.intentName = message.intentName;
                    if (message.textUtterance != null && message.hasOwnProperty("textUtterance"))
                        object.textUtterance = message.textUtterance;
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits"))
                        object.dtmfDigits = message.dtmfDigits;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = options.enums === String ? $root.infinitusai.be.SuggestionCategory[message.category] === undefined ? message.category : $root.infinitusai.be.SuggestionCategory[message.category] : message.category;
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        object.suggestionId = message.suggestionId;
                    if (message.isCallSpecific != null && message.hasOwnProperty("isCallSpecific"))
                        object.isCallSpecific = message.isCallSpecific;
                    return object;
                };
    
                /**
                 * Converts this FollowupSuggestion to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FollowupSuggestion.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FollowupSuggestion
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FollowupSuggestion
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FollowupSuggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FollowupSuggestion";
                };
    
                return FollowupSuggestion;
            })();
    
            be.Suggestion = (function() {
    
                /**
                 * Properties of a Suggestion.
                 * @memberof infinitusai.be
                 * @interface ISuggestion
                 * @property {string|null} [intentName] Suggestion intentName
                 * @property {string|null} [textUtterance] Suggestion textUtterance
                 * @property {string|null} [dtmfDigits] Suggestion dtmfDigits
                 * @property {infinitusai.be.SuggestionCategory|null} [category] Suggestion category
                 * @property {Array.<string>|null} [protoPath] Suggestion protoPath
                 * @property {boolean|null} [popularSuggestion] Suggestion popularSuggestion
                 * @property {boolean|null} [stopRecordingSuggestion] Suggestion stopRecordingSuggestion
                 * @property {Array.<infinitusai.be.IFollowupSuggestion>|null} [followupSuggestions] Suggestion followupSuggestions
                 * @property {string|null} [suggestionId] Suggestion suggestionId
                 * @property {boolean|null} [isCallSpecific] Suggestion isCallSpecific
                 * @property {infinitusai.be.SuggestionSubcategory|null} [subcategory] Suggestion subcategory
                 * @property {boolean|null} [shouldCapturePayerIntelligenceState] Suggestion shouldCapturePayerIntelligenceState
                 */
    
                /**
                 * Constructs a new Suggestion.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Suggestion.
                 * @implements ISuggestion
                 * @constructor
                 * @param {infinitusai.be.ISuggestion=} [properties] Properties to set
                 */
                function Suggestion(properties) {
                    this.protoPath = [];
                    this.followupSuggestions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Suggestion intentName.
                 * @member {string} intentName
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.intentName = "";
    
                /**
                 * Suggestion textUtterance.
                 * @member {string} textUtterance
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.textUtterance = "";
    
                /**
                 * Suggestion dtmfDigits.
                 * @member {string} dtmfDigits
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.dtmfDigits = "";
    
                /**
                 * Suggestion category.
                 * @member {infinitusai.be.SuggestionCategory} category
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.category = 0;
    
                /**
                 * Suggestion protoPath.
                 * @member {Array.<string>} protoPath
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.protoPath = $util.emptyArray;
    
                /**
                 * Suggestion popularSuggestion.
                 * @member {boolean} popularSuggestion
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.popularSuggestion = false;
    
                /**
                 * Suggestion stopRecordingSuggestion.
                 * @member {boolean} stopRecordingSuggestion
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.stopRecordingSuggestion = false;
    
                /**
                 * Suggestion followupSuggestions.
                 * @member {Array.<infinitusai.be.IFollowupSuggestion>} followupSuggestions
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.followupSuggestions = $util.emptyArray;
    
                /**
                 * Suggestion suggestionId.
                 * @member {string} suggestionId
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.suggestionId = "";
    
                /**
                 * Suggestion isCallSpecific.
                 * @member {boolean} isCallSpecific
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.isCallSpecific = false;
    
                /**
                 * Suggestion subcategory.
                 * @member {infinitusai.be.SuggestionSubcategory} subcategory
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.subcategory = 0;
    
                /**
                 * Suggestion shouldCapturePayerIntelligenceState.
                 * @member {boolean} shouldCapturePayerIntelligenceState
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 */
                Suggestion.prototype.shouldCapturePayerIntelligenceState = false;
    
                /**
                 * Creates a new Suggestion instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {infinitusai.be.ISuggestion=} [properties] Properties to set
                 * @returns {infinitusai.be.Suggestion} Suggestion instance
                 */
                Suggestion.create = function create(properties) {
                    return new Suggestion(properties);
                };
    
                /**
                 * Encodes the specified Suggestion message. Does not implicitly {@link infinitusai.be.Suggestion.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {infinitusai.be.ISuggestion} message Suggestion message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Suggestion.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.intentName != null && Object.hasOwnProperty.call(message, "intentName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.intentName);
                    if (message.textUtterance != null && Object.hasOwnProperty.call(message, "textUtterance"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.textUtterance);
                    if (message.dtmfDigits != null && Object.hasOwnProperty.call(message, "dtmfDigits"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dtmfDigits);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.category);
                    if (message.protoPath != null && message.protoPath.length)
                        for (var i = 0; i < message.protoPath.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.protoPath[i]);
                    if (message.popularSuggestion != null && Object.hasOwnProperty.call(message, "popularSuggestion"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.popularSuggestion);
                    if (message.stopRecordingSuggestion != null && Object.hasOwnProperty.call(message, "stopRecordingSuggestion"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.stopRecordingSuggestion);
                    if (message.followupSuggestions != null && message.followupSuggestions.length)
                        for (var i = 0; i < message.followupSuggestions.length; ++i)
                            $root.infinitusai.be.FollowupSuggestion.encode(message.followupSuggestions[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.suggestionId != null && Object.hasOwnProperty.call(message, "suggestionId"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.suggestionId);
                    if (message.isCallSpecific != null && Object.hasOwnProperty.call(message, "isCallSpecific"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isCallSpecific);
                    if (message.subcategory != null && Object.hasOwnProperty.call(message, "subcategory"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.subcategory);
                    if (message.shouldCapturePayerIntelligenceState != null && Object.hasOwnProperty.call(message, "shouldCapturePayerIntelligenceState"))
                        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.shouldCapturePayerIntelligenceState);
                    return writer;
                };
    
                /**
                 * Encodes the specified Suggestion message, length delimited. Does not implicitly {@link infinitusai.be.Suggestion.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {infinitusai.be.ISuggestion} message Suggestion message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Suggestion.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Suggestion message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Suggestion} Suggestion
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Suggestion.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Suggestion();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.intentName = reader.string();
                                break;
                            }
                        case 2: {
                                message.textUtterance = reader.string();
                                break;
                            }
                        case 3: {
                                message.dtmfDigits = reader.string();
                                break;
                            }
                        case 4: {
                                message.category = reader.int32();
                                break;
                            }
                        case 5: {
                                if (!(message.protoPath && message.protoPath.length))
                                    message.protoPath = [];
                                message.protoPath.push(reader.string());
                                break;
                            }
                        case 6: {
                                message.popularSuggestion = reader.bool();
                                break;
                            }
                        case 7: {
                                message.stopRecordingSuggestion = reader.bool();
                                break;
                            }
                        case 8: {
                                if (!(message.followupSuggestions && message.followupSuggestions.length))
                                    message.followupSuggestions = [];
                                message.followupSuggestions.push($root.infinitusai.be.FollowupSuggestion.decode(reader, reader.uint32()));
                                break;
                            }
                        case 9: {
                                message.suggestionId = reader.string();
                                break;
                            }
                        case 10: {
                                message.isCallSpecific = reader.bool();
                                break;
                            }
                        case 11: {
                                message.subcategory = reader.int32();
                                break;
                            }
                        case 12: {
                                message.shouldCapturePayerIntelligenceState = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Suggestion message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Suggestion} Suggestion
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Suggestion.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Suggestion message.
                 * @function verify
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Suggestion.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.intentName != null && message.hasOwnProperty("intentName"))
                        if (!$util.isString(message.intentName))
                            return "intentName: string expected";
                    if (message.textUtterance != null && message.hasOwnProperty("textUtterance"))
                        if (!$util.isString(message.textUtterance))
                            return "textUtterance: string expected";
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits"))
                        if (!$util.isString(message.dtmfDigits))
                            return "dtmfDigits: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        switch (message.category) {
                        default:
                            return "category: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.protoPath != null && message.hasOwnProperty("protoPath")) {
                        if (!Array.isArray(message.protoPath))
                            return "protoPath: array expected";
                        for (var i = 0; i < message.protoPath.length; ++i)
                            if (!$util.isString(message.protoPath[i]))
                                return "protoPath: string[] expected";
                    }
                    if (message.popularSuggestion != null && message.hasOwnProperty("popularSuggestion"))
                        if (typeof message.popularSuggestion !== "boolean")
                            return "popularSuggestion: boolean expected";
                    if (message.stopRecordingSuggestion != null && message.hasOwnProperty("stopRecordingSuggestion"))
                        if (typeof message.stopRecordingSuggestion !== "boolean")
                            return "stopRecordingSuggestion: boolean expected";
                    if (message.followupSuggestions != null && message.hasOwnProperty("followupSuggestions")) {
                        if (!Array.isArray(message.followupSuggestions))
                            return "followupSuggestions: array expected";
                        for (var i = 0; i < message.followupSuggestions.length; ++i) {
                            var error = $root.infinitusai.be.FollowupSuggestion.verify(message.followupSuggestions[i]);
                            if (error)
                                return "followupSuggestions." + error;
                        }
                    }
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        if (!$util.isString(message.suggestionId))
                            return "suggestionId: string expected";
                    if (message.isCallSpecific != null && message.hasOwnProperty("isCallSpecific"))
                        if (typeof message.isCallSpecific !== "boolean")
                            return "isCallSpecific: boolean expected";
                    if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                        switch (message.subcategory) {
                        default:
                            return "subcategory: enum value expected";
                        case 0:
                        case 1:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.shouldCapturePayerIntelligenceState != null && message.hasOwnProperty("shouldCapturePayerIntelligenceState"))
                        if (typeof message.shouldCapturePayerIntelligenceState !== "boolean")
                            return "shouldCapturePayerIntelligenceState: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a Suggestion message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Suggestion} Suggestion
                 */
                Suggestion.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Suggestion)
                        return object;
                    var message = new $root.infinitusai.be.Suggestion();
                    if (object.intentName != null)
                        message.intentName = String(object.intentName);
                    if (object.textUtterance != null)
                        message.textUtterance = String(object.textUtterance);
                    if (object.dtmfDigits != null)
                        message.dtmfDigits = String(object.dtmfDigits);
                    switch (object.category) {
                    default:
                        if (typeof object.category === "number") {
                            message.category = object.category;
                            break;
                        }
                        break;
                    case "SUGGESTION_CATEGORY_UNKNOWN":
                    case 0:
                        message.category = 0;
                        break;
                    case "SUGGESTION_CATEGORY_CHITCHAT":
                    case 1:
                        message.category = 1;
                        break;
                    case "SUGGESTION_CATEGORY_IVR":
                    case 2:
                        message.category = 2;
                        break;
                    case "SUGGESTION_CATEGORY_ANSWERS":
                    case 3:
                        message.category = 3;
                        break;
                    case "SUGGESTION_CATEGORY_QUESTIONS":
                    case 4:
                        message.category = 4;
                        break;
                    case "SUGGESTION_CATEGORY_PUSHBACK":
                    case 5:
                        message.category = 5;
                        break;
                    }
                    if (object.protoPath) {
                        if (!Array.isArray(object.protoPath))
                            throw TypeError(".infinitusai.be.Suggestion.protoPath: array expected");
                        message.protoPath = [];
                        for (var i = 0; i < object.protoPath.length; ++i)
                            message.protoPath[i] = String(object.protoPath[i]);
                    }
                    if (object.popularSuggestion != null)
                        message.popularSuggestion = Boolean(object.popularSuggestion);
                    if (object.stopRecordingSuggestion != null)
                        message.stopRecordingSuggestion = Boolean(object.stopRecordingSuggestion);
                    if (object.followupSuggestions) {
                        if (!Array.isArray(object.followupSuggestions))
                            throw TypeError(".infinitusai.be.Suggestion.followupSuggestions: array expected");
                        message.followupSuggestions = [];
                        for (var i = 0; i < object.followupSuggestions.length; ++i) {
                            if (typeof object.followupSuggestions[i] !== "object")
                                throw TypeError(".infinitusai.be.Suggestion.followupSuggestions: object expected");
                            message.followupSuggestions[i] = $root.infinitusai.be.FollowupSuggestion.fromObject(object.followupSuggestions[i]);
                        }
                    }
                    if (object.suggestionId != null)
                        message.suggestionId = String(object.suggestionId);
                    if (object.isCallSpecific != null)
                        message.isCallSpecific = Boolean(object.isCallSpecific);
                    switch (object.subcategory) {
                    default:
                        if (typeof object.subcategory === "number") {
                            message.subcategory = object.subcategory;
                            break;
                        }
                        break;
                    case "SUGGESTION_SUBCATEGORY_UNKNOWN":
                    case 0:
                        message.subcategory = 0;
                        break;
                    case "SUGGESTION_SUBCATEGORY_PLAN_INFO":
                    case 1:
                        message.subcategory = 1;
                        break;
                    case "SUGGESTION_SUBCATEGORY_OTHER_INSURANCE":
                    case 3:
                        message.subcategory = 3;
                        break;
                    case "SUGGESTION_SUBCATEGORY_PLAN_DESIGN":
                    case 4:
                        message.subcategory = 4;
                        break;
                    case "SUGGESTION_SUBCATEGORY_COST_SHARE":
                    case 5:
                        message.subcategory = 5;
                        break;
                    case "SUGGESTION_SUBCATEGORY_ACCESS_INFORMATION":
                    case 6:
                        message.subcategory = 6;
                        break;
                    }
                    if (object.shouldCapturePayerIntelligenceState != null)
                        message.shouldCapturePayerIntelligenceState = Boolean(object.shouldCapturePayerIntelligenceState);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Suggestion message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {infinitusai.be.Suggestion} message Suggestion
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Suggestion.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.protoPath = [];
                        object.followupSuggestions = [];
                    }
                    if (options.defaults) {
                        object.intentName = "";
                        object.textUtterance = "";
                        object.dtmfDigits = "";
                        object.category = options.enums === String ? "SUGGESTION_CATEGORY_UNKNOWN" : 0;
                        object.popularSuggestion = false;
                        object.stopRecordingSuggestion = false;
                        object.suggestionId = "";
                        object.isCallSpecific = false;
                        object.subcategory = options.enums === String ? "SUGGESTION_SUBCATEGORY_UNKNOWN" : 0;
                        object.shouldCapturePayerIntelligenceState = false;
                    }
                    if (message.intentName != null && message.hasOwnProperty("intentName"))
                        object.intentName = message.intentName;
                    if (message.textUtterance != null && message.hasOwnProperty("textUtterance"))
                        object.textUtterance = message.textUtterance;
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits"))
                        object.dtmfDigits = message.dtmfDigits;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = options.enums === String ? $root.infinitusai.be.SuggestionCategory[message.category] === undefined ? message.category : $root.infinitusai.be.SuggestionCategory[message.category] : message.category;
                    if (message.protoPath && message.protoPath.length) {
                        object.protoPath = [];
                        for (var j = 0; j < message.protoPath.length; ++j)
                            object.protoPath[j] = message.protoPath[j];
                    }
                    if (message.popularSuggestion != null && message.hasOwnProperty("popularSuggestion"))
                        object.popularSuggestion = message.popularSuggestion;
                    if (message.stopRecordingSuggestion != null && message.hasOwnProperty("stopRecordingSuggestion"))
                        object.stopRecordingSuggestion = message.stopRecordingSuggestion;
                    if (message.followupSuggestions && message.followupSuggestions.length) {
                        object.followupSuggestions = [];
                        for (var j = 0; j < message.followupSuggestions.length; ++j)
                            object.followupSuggestions[j] = $root.infinitusai.be.FollowupSuggestion.toObject(message.followupSuggestions[j], options);
                    }
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        object.suggestionId = message.suggestionId;
                    if (message.isCallSpecific != null && message.hasOwnProperty("isCallSpecific"))
                        object.isCallSpecific = message.isCallSpecific;
                    if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                        object.subcategory = options.enums === String ? $root.infinitusai.be.SuggestionSubcategory[message.subcategory] === undefined ? message.subcategory : $root.infinitusai.be.SuggestionSubcategory[message.subcategory] : message.subcategory;
                    if (message.shouldCapturePayerIntelligenceState != null && message.hasOwnProperty("shouldCapturePayerIntelligenceState"))
                        object.shouldCapturePayerIntelligenceState = message.shouldCapturePayerIntelligenceState;
                    return object;
                };
    
                /**
                 * Converts this Suggestion to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Suggestion
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Suggestion.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Suggestion
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Suggestion
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Suggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Suggestion";
                };
    
                return Suggestion;
            })();
    
            be.Suggestions = (function() {
    
                /**
                 * Properties of a Suggestions.
                 * @memberof infinitusai.be
                 * @interface ISuggestions
                 * @property {Array.<infinitusai.be.ISuggestion>|null} [suggestions] Suggestions suggestions
                 */
    
                /**
                 * Constructs a new Suggestions.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Suggestions.
                 * @implements ISuggestions
                 * @constructor
                 * @param {infinitusai.be.ISuggestions=} [properties] Properties to set
                 */
                function Suggestions(properties) {
                    this.suggestions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Suggestions suggestions.
                 * @member {Array.<infinitusai.be.ISuggestion>} suggestions
                 * @memberof infinitusai.be.Suggestions
                 * @instance
                 */
                Suggestions.prototype.suggestions = $util.emptyArray;
    
                /**
                 * Creates a new Suggestions instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {infinitusai.be.ISuggestions=} [properties] Properties to set
                 * @returns {infinitusai.be.Suggestions} Suggestions instance
                 */
                Suggestions.create = function create(properties) {
                    return new Suggestions(properties);
                };
    
                /**
                 * Encodes the specified Suggestions message. Does not implicitly {@link infinitusai.be.Suggestions.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {infinitusai.be.ISuggestions} message Suggestions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Suggestions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.suggestions != null && message.suggestions.length)
                        for (var i = 0; i < message.suggestions.length; ++i)
                            $root.infinitusai.be.Suggestion.encode(message.suggestions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Suggestions message, length delimited. Does not implicitly {@link infinitusai.be.Suggestions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {infinitusai.be.ISuggestions} message Suggestions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Suggestions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Suggestions message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Suggestions} Suggestions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Suggestions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Suggestions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.suggestions && message.suggestions.length))
                                    message.suggestions = [];
                                message.suggestions.push($root.infinitusai.be.Suggestion.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Suggestions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Suggestions} Suggestions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Suggestions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Suggestions message.
                 * @function verify
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Suggestions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.suggestions != null && message.hasOwnProperty("suggestions")) {
                        if (!Array.isArray(message.suggestions))
                            return "suggestions: array expected";
                        for (var i = 0; i < message.suggestions.length; ++i) {
                            var error = $root.infinitusai.be.Suggestion.verify(message.suggestions[i]);
                            if (error)
                                return "suggestions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Suggestions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Suggestions} Suggestions
                 */
                Suggestions.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Suggestions)
                        return object;
                    var message = new $root.infinitusai.be.Suggestions();
                    if (object.suggestions) {
                        if (!Array.isArray(object.suggestions))
                            throw TypeError(".infinitusai.be.Suggestions.suggestions: array expected");
                        message.suggestions = [];
                        for (var i = 0; i < object.suggestions.length; ++i) {
                            if (typeof object.suggestions[i] !== "object")
                                throw TypeError(".infinitusai.be.Suggestions.suggestions: object expected");
                            message.suggestions[i] = $root.infinitusai.be.Suggestion.fromObject(object.suggestions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Suggestions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {infinitusai.be.Suggestions} message Suggestions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Suggestions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.suggestions = [];
                    if (message.suggestions && message.suggestions.length) {
                        object.suggestions = [];
                        for (var j = 0; j < message.suggestions.length; ++j)
                            object.suggestions[j] = $root.infinitusai.be.Suggestion.toObject(message.suggestions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Suggestions to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Suggestions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Suggestions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Suggestions
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Suggestions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Suggestions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Suggestions";
                };
    
                return Suggestions;
            })();
    
            be.PartialSTT = (function() {
    
                /**
                 * Properties of a PartialSTT.
                 * @memberof infinitusai.be
                 * @interface IPartialSTT
                 * @property {string|null} [partial] PartialSTT partial
                 */
    
                /**
                 * Constructs a new PartialSTT.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PartialSTT.
                 * @implements IPartialSTT
                 * @constructor
                 * @param {infinitusai.be.IPartialSTT=} [properties] Properties to set
                 */
                function PartialSTT(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PartialSTT partial.
                 * @member {string} partial
                 * @memberof infinitusai.be.PartialSTT
                 * @instance
                 */
                PartialSTT.prototype.partial = "";
    
                /**
                 * Creates a new PartialSTT instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {infinitusai.be.IPartialSTT=} [properties] Properties to set
                 * @returns {infinitusai.be.PartialSTT} PartialSTT instance
                 */
                PartialSTT.create = function create(properties) {
                    return new PartialSTT(properties);
                };
    
                /**
                 * Encodes the specified PartialSTT message. Does not implicitly {@link infinitusai.be.PartialSTT.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {infinitusai.be.IPartialSTT} message PartialSTT message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PartialSTT.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.partial != null && Object.hasOwnProperty.call(message, "partial"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.partial);
                    return writer;
                };
    
                /**
                 * Encodes the specified PartialSTT message, length delimited. Does not implicitly {@link infinitusai.be.PartialSTT.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {infinitusai.be.IPartialSTT} message PartialSTT message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PartialSTT.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PartialSTT message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PartialSTT} PartialSTT
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PartialSTT.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PartialSTT();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.partial = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PartialSTT message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PartialSTT} PartialSTT
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PartialSTT.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PartialSTT message.
                 * @function verify
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PartialSTT.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.partial != null && message.hasOwnProperty("partial"))
                        if (!$util.isString(message.partial))
                            return "partial: string expected";
                    return null;
                };
    
                /**
                 * Creates a PartialSTT message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PartialSTT} PartialSTT
                 */
                PartialSTT.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PartialSTT)
                        return object;
                    var message = new $root.infinitusai.be.PartialSTT();
                    if (object.partial != null)
                        message.partial = String(object.partial);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PartialSTT message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {infinitusai.be.PartialSTT} message PartialSTT
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PartialSTT.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.partial = "";
                    if (message.partial != null && message.hasOwnProperty("partial"))
                        object.partial = message.partial;
                    return object;
                };
    
                /**
                 * Converts this PartialSTT to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PartialSTT
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PartialSTT.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PartialSTT
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PartialSTT
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PartialSTT.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PartialSTT";
                };
    
                return PartialSTT;
            })();
    
            /**
             * ErrorLevel enum.
             * @name infinitusai.be.ErrorLevel
             * @enum {number}
             * @property {number} ERROR_LEVEL_UNKNOWN=0 ERROR_LEVEL_UNKNOWN value
             * @property {number} ERROR_LEVEL_ERROR=1 ERROR_LEVEL_ERROR value
             * @property {number} ERROR_LEVEL_FATAL=2 ERROR_LEVEL_FATAL value
             */
            be.ErrorLevel = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ERROR_LEVEL_UNKNOWN"] = 0;
                values[valuesById[1] = "ERROR_LEVEL_ERROR"] = 1;
                values[valuesById[2] = "ERROR_LEVEL_FATAL"] = 2;
                return values;
            })();
    
            be.ChatLogRecord = (function() {
    
                /**
                 * Properties of a ChatLogRecord.
                 * @memberof infinitusai.be
                 * @interface IChatLogRecord
                 * @property {string|null} [intent] ChatLogRecord intent
                 * @property {string|null} [text] ChatLogRecord text
                 * @property {infinitusai.be.ActionSource|null} [actionSource] ChatLogRecord actionSource
                 * @property {boolean|null} [isResponse] ChatLogRecord isResponse
                 * @property {number|Long|null} [timestampMillis] ChatLogRecord timestampMillis
                 * @property {boolean|null} [isPartialText] ChatLogRecord isPartialText
                 * @property {infinitusai.be.IUserInfo|null} [userInfo] ChatLogRecord userInfo
                 * @property {string|null} [suggestionId] ChatLogRecord suggestionId
                 * @property {boolean|null} [isError] ChatLogRecord isError
                 * @property {infinitusai.be.ErrorLevel|null} [errorLevel] ChatLogRecord errorLevel
                 * @property {number|null} [sttConfidence] ChatLogRecord sttConfidence
                 * @property {number|Long|null} [timestampEndMillis] ChatLogRecord timestampEndMillis
                 * @property {boolean|null} [evaMuted] ChatLogRecord evaMuted
                 * @property {string|null} [sourceTaskUuid] ChatLogRecord sourceTaskUuid
                 * @property {string|null} [ttsRefId] ChatLogRecord ttsRefId
                 */
    
                /**
                 * Constructs a new ChatLogRecord.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChatLogRecord.
                 * @implements IChatLogRecord
                 * @constructor
                 * @param {infinitusai.be.IChatLogRecord=} [properties] Properties to set
                 */
                function ChatLogRecord(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChatLogRecord intent.
                 * @member {string} intent
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.intent = "";
    
                /**
                 * ChatLogRecord text.
                 * @member {string} text
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.text = "";
    
                /**
                 * ChatLogRecord actionSource.
                 * @member {infinitusai.be.ActionSource} actionSource
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.actionSource = 0;
    
                /**
                 * ChatLogRecord isResponse.
                 * @member {boolean} isResponse
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.isResponse = false;
    
                /**
                 * ChatLogRecord timestampMillis.
                 * @member {number|Long} timestampMillis
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.timestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ChatLogRecord isPartialText.
                 * @member {boolean} isPartialText
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.isPartialText = false;
    
                /**
                 * ChatLogRecord userInfo.
                 * @member {infinitusai.be.IUserInfo|null|undefined} userInfo
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.userInfo = null;
    
                /**
                 * ChatLogRecord suggestionId.
                 * @member {string} suggestionId
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.suggestionId = "";
    
                /**
                 * ChatLogRecord isError.
                 * @member {boolean} isError
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.isError = false;
    
                /**
                 * ChatLogRecord errorLevel.
                 * @member {infinitusai.be.ErrorLevel} errorLevel
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.errorLevel = 0;
    
                /**
                 * ChatLogRecord sttConfidence.
                 * @member {number} sttConfidence
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.sttConfidence = 0;
    
                /**
                 * ChatLogRecord timestampEndMillis.
                 * @member {number|Long} timestampEndMillis
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.timestampEndMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ChatLogRecord evaMuted.
                 * @member {boolean} evaMuted
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.evaMuted = false;
    
                /**
                 * ChatLogRecord sourceTaskUuid.
                 * @member {string} sourceTaskUuid
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.sourceTaskUuid = "";
    
                /**
                 * ChatLogRecord ttsRefId.
                 * @member {string} ttsRefId
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 */
                ChatLogRecord.prototype.ttsRefId = "";
    
                /**
                 * Creates a new ChatLogRecord instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {infinitusai.be.IChatLogRecord=} [properties] Properties to set
                 * @returns {infinitusai.be.ChatLogRecord} ChatLogRecord instance
                 */
                ChatLogRecord.create = function create(properties) {
                    return new ChatLogRecord(properties);
                };
    
                /**
                 * Encodes the specified ChatLogRecord message. Does not implicitly {@link infinitusai.be.ChatLogRecord.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {infinitusai.be.IChatLogRecord} message ChatLogRecord message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatLogRecord.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.intent != null && Object.hasOwnProperty.call(message, "intent"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.intent);
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
                    if (message.actionSource != null && Object.hasOwnProperty.call(message, "actionSource"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.actionSource);
                    if (message.isResponse != null && Object.hasOwnProperty.call(message, "isResponse"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isResponse);
                    if (message.timestampMillis != null && Object.hasOwnProperty.call(message, "timestampMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.timestampMillis);
                    if (message.isPartialText != null && Object.hasOwnProperty.call(message, "isPartialText"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isPartialText);
                    if (message.userInfo != null && Object.hasOwnProperty.call(message, "userInfo"))
                        $root.infinitusai.be.UserInfo.encode(message.userInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.suggestionId != null && Object.hasOwnProperty.call(message, "suggestionId"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.suggestionId);
                    if (message.isError != null && Object.hasOwnProperty.call(message, "isError"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isError);
                    if (message.errorLevel != null && Object.hasOwnProperty.call(message, "errorLevel"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.errorLevel);
                    if (message.sttConfidence != null && Object.hasOwnProperty.call(message, "sttConfidence"))
                        writer.uint32(/* id 11, wireType 1 =*/89).double(message.sttConfidence);
                    if (message.timestampEndMillis != null && Object.hasOwnProperty.call(message, "timestampEndMillis"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int64(message.timestampEndMillis);
                    if (message.evaMuted != null && Object.hasOwnProperty.call(message, "evaMuted"))
                        writer.uint32(/* id 13, wireType 0 =*/104).bool(message.evaMuted);
                    if (message.sourceTaskUuid != null && Object.hasOwnProperty.call(message, "sourceTaskUuid"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.sourceTaskUuid);
                    if (message.ttsRefId != null && Object.hasOwnProperty.call(message, "ttsRefId"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.ttsRefId);
                    return writer;
                };
    
                /**
                 * Encodes the specified ChatLogRecord message, length delimited. Does not implicitly {@link infinitusai.be.ChatLogRecord.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {infinitusai.be.IChatLogRecord} message ChatLogRecord message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatLogRecord.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChatLogRecord message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChatLogRecord} ChatLogRecord
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatLogRecord.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChatLogRecord();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.intent = reader.string();
                                break;
                            }
                        case 2: {
                                message.text = reader.string();
                                break;
                            }
                        case 3: {
                                message.actionSource = reader.int32();
                                break;
                            }
                        case 4: {
                                message.isResponse = reader.bool();
                                break;
                            }
                        case 5: {
                                message.timestampMillis = reader.int64();
                                break;
                            }
                        case 6: {
                                message.isPartialText = reader.bool();
                                break;
                            }
                        case 7: {
                                message.userInfo = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.suggestionId = reader.string();
                                break;
                            }
                        case 9: {
                                message.isError = reader.bool();
                                break;
                            }
                        case 10: {
                                message.errorLevel = reader.int32();
                                break;
                            }
                        case 11: {
                                message.sttConfidence = reader.double();
                                break;
                            }
                        case 12: {
                                message.timestampEndMillis = reader.int64();
                                break;
                            }
                        case 13: {
                                message.evaMuted = reader.bool();
                                break;
                            }
                        case 14: {
                                message.sourceTaskUuid = reader.string();
                                break;
                            }
                        case 15: {
                                message.ttsRefId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChatLogRecord message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChatLogRecord} ChatLogRecord
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatLogRecord.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChatLogRecord message.
                 * @function verify
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChatLogRecord.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.intent != null && message.hasOwnProperty("intent"))
                        if (!$util.isString(message.intent))
                            return "intent: string expected";
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    if (message.actionSource != null && message.hasOwnProperty("actionSource"))
                        switch (message.actionSource) {
                        default:
                            return "actionSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.isResponse != null && message.hasOwnProperty("isResponse"))
                        if (typeof message.isResponse !== "boolean")
                            return "isResponse: boolean expected";
                    if (message.timestampMillis != null && message.hasOwnProperty("timestampMillis"))
                        if (!$util.isInteger(message.timestampMillis) && !(message.timestampMillis && $util.isInteger(message.timestampMillis.low) && $util.isInteger(message.timestampMillis.high)))
                            return "timestampMillis: integer|Long expected";
                    if (message.isPartialText != null && message.hasOwnProperty("isPartialText"))
                        if (typeof message.isPartialText !== "boolean")
                            return "isPartialText: boolean expected";
                    if (message.userInfo != null && message.hasOwnProperty("userInfo")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.userInfo);
                        if (error)
                            return "userInfo." + error;
                    }
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        if (!$util.isString(message.suggestionId))
                            return "suggestionId: string expected";
                    if (message.isError != null && message.hasOwnProperty("isError"))
                        if (typeof message.isError !== "boolean")
                            return "isError: boolean expected";
                    if (message.errorLevel != null && message.hasOwnProperty("errorLevel"))
                        switch (message.errorLevel) {
                        default:
                            return "errorLevel: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.sttConfidence != null && message.hasOwnProperty("sttConfidence"))
                        if (typeof message.sttConfidence !== "number")
                            return "sttConfidence: number expected";
                    if (message.timestampEndMillis != null && message.hasOwnProperty("timestampEndMillis"))
                        if (!$util.isInteger(message.timestampEndMillis) && !(message.timestampEndMillis && $util.isInteger(message.timestampEndMillis.low) && $util.isInteger(message.timestampEndMillis.high)))
                            return "timestampEndMillis: integer|Long expected";
                    if (message.evaMuted != null && message.hasOwnProperty("evaMuted"))
                        if (typeof message.evaMuted !== "boolean")
                            return "evaMuted: boolean expected";
                    if (message.sourceTaskUuid != null && message.hasOwnProperty("sourceTaskUuid"))
                        if (!$util.isString(message.sourceTaskUuid))
                            return "sourceTaskUuid: string expected";
                    if (message.ttsRefId != null && message.hasOwnProperty("ttsRefId"))
                        if (!$util.isString(message.ttsRefId))
                            return "ttsRefId: string expected";
                    return null;
                };
    
                /**
                 * Creates a ChatLogRecord message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChatLogRecord} ChatLogRecord
                 */
                ChatLogRecord.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChatLogRecord)
                        return object;
                    var message = new $root.infinitusai.be.ChatLogRecord();
                    if (object.intent != null)
                        message.intent = String(object.intent);
                    if (object.text != null)
                        message.text = String(object.text);
                    switch (object.actionSource) {
                    default:
                        if (typeof object.actionSource === "number") {
                            message.actionSource = object.actionSource;
                            break;
                        }
                        break;
                    case "ACTION_SOURCE_UNKNOWN":
                    case 0:
                        message.actionSource = 0;
                        break;
                    case "ACTION_SOURCE_KEYBOARD":
                    case 1:
                        message.actionSource = 1;
                        break;
                    case "ACTION_SOURCE_TOP_SUGGESTION":
                    case 2:
                        message.actionSource = 2;
                        break;
                    case "ACTION_SOURCE_SUGGESTION":
                    case 3:
                        message.actionSource = 3;
                        break;
                    case "ACTION_SOURCE_AUTO_RESPOND":
                    case 4:
                        message.actionSource = 4;
                        break;
                    case "ACTION_SOURCE_FOLLOWUP_SUGGESTION":
                    case 5:
                        message.actionSource = 5;
                        break;
                    case "ACTION_SOURCE_RECORDED_AUDIO":
                    case 6:
                        message.actionSource = 6;
                        break;
                    }
                    if (object.isResponse != null)
                        message.isResponse = Boolean(object.isResponse);
                    if (object.timestampMillis != null)
                        if ($util.Long)
                            (message.timestampMillis = $util.Long.fromValue(object.timestampMillis)).unsigned = false;
                        else if (typeof object.timestampMillis === "string")
                            message.timestampMillis = parseInt(object.timestampMillis, 10);
                        else if (typeof object.timestampMillis === "number")
                            message.timestampMillis = object.timestampMillis;
                        else if (typeof object.timestampMillis === "object")
                            message.timestampMillis = new $util.LongBits(object.timestampMillis.low >>> 0, object.timestampMillis.high >>> 0).toNumber();
                    if (object.isPartialText != null)
                        message.isPartialText = Boolean(object.isPartialText);
                    if (object.userInfo != null) {
                        if (typeof object.userInfo !== "object")
                            throw TypeError(".infinitusai.be.ChatLogRecord.userInfo: object expected");
                        message.userInfo = $root.infinitusai.be.UserInfo.fromObject(object.userInfo);
                    }
                    if (object.suggestionId != null)
                        message.suggestionId = String(object.suggestionId);
                    if (object.isError != null)
                        message.isError = Boolean(object.isError);
                    switch (object.errorLevel) {
                    default:
                        if (typeof object.errorLevel === "number") {
                            message.errorLevel = object.errorLevel;
                            break;
                        }
                        break;
                    case "ERROR_LEVEL_UNKNOWN":
                    case 0:
                        message.errorLevel = 0;
                        break;
                    case "ERROR_LEVEL_ERROR":
                    case 1:
                        message.errorLevel = 1;
                        break;
                    case "ERROR_LEVEL_FATAL":
                    case 2:
                        message.errorLevel = 2;
                        break;
                    }
                    if (object.sttConfidence != null)
                        message.sttConfidence = Number(object.sttConfidence);
                    if (object.timestampEndMillis != null)
                        if ($util.Long)
                            (message.timestampEndMillis = $util.Long.fromValue(object.timestampEndMillis)).unsigned = false;
                        else if (typeof object.timestampEndMillis === "string")
                            message.timestampEndMillis = parseInt(object.timestampEndMillis, 10);
                        else if (typeof object.timestampEndMillis === "number")
                            message.timestampEndMillis = object.timestampEndMillis;
                        else if (typeof object.timestampEndMillis === "object")
                            message.timestampEndMillis = new $util.LongBits(object.timestampEndMillis.low >>> 0, object.timestampEndMillis.high >>> 0).toNumber();
                    if (object.evaMuted != null)
                        message.evaMuted = Boolean(object.evaMuted);
                    if (object.sourceTaskUuid != null)
                        message.sourceTaskUuid = String(object.sourceTaskUuid);
                    if (object.ttsRefId != null)
                        message.ttsRefId = String(object.ttsRefId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChatLogRecord message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {infinitusai.be.ChatLogRecord} message ChatLogRecord
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChatLogRecord.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.intent = "";
                        object.text = "";
                        object.actionSource = options.enums === String ? "ACTION_SOURCE_UNKNOWN" : 0;
                        object.isResponse = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestampMillis = options.longs === String ? "0" : 0;
                        object.isPartialText = false;
                        object.userInfo = null;
                        object.suggestionId = "";
                        object.isError = false;
                        object.errorLevel = options.enums === String ? "ERROR_LEVEL_UNKNOWN" : 0;
                        object.sttConfidence = 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timestampEndMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestampEndMillis = options.longs === String ? "0" : 0;
                        object.evaMuted = false;
                        object.sourceTaskUuid = "";
                        object.ttsRefId = "";
                    }
                    if (message.intent != null && message.hasOwnProperty("intent"))
                        object.intent = message.intent;
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    if (message.actionSource != null && message.hasOwnProperty("actionSource"))
                        object.actionSource = options.enums === String ? $root.infinitusai.be.ActionSource[message.actionSource] === undefined ? message.actionSource : $root.infinitusai.be.ActionSource[message.actionSource] : message.actionSource;
                    if (message.isResponse != null && message.hasOwnProperty("isResponse"))
                        object.isResponse = message.isResponse;
                    if (message.timestampMillis != null && message.hasOwnProperty("timestampMillis"))
                        if (typeof message.timestampMillis === "number")
                            object.timestampMillis = options.longs === String ? String(message.timestampMillis) : message.timestampMillis;
                        else
                            object.timestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.timestampMillis) : options.longs === Number ? new $util.LongBits(message.timestampMillis.low >>> 0, message.timestampMillis.high >>> 0).toNumber() : message.timestampMillis;
                    if (message.isPartialText != null && message.hasOwnProperty("isPartialText"))
                        object.isPartialText = message.isPartialText;
                    if (message.userInfo != null && message.hasOwnProperty("userInfo"))
                        object.userInfo = $root.infinitusai.be.UserInfo.toObject(message.userInfo, options);
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        object.suggestionId = message.suggestionId;
                    if (message.isError != null && message.hasOwnProperty("isError"))
                        object.isError = message.isError;
                    if (message.errorLevel != null && message.hasOwnProperty("errorLevel"))
                        object.errorLevel = options.enums === String ? $root.infinitusai.be.ErrorLevel[message.errorLevel] === undefined ? message.errorLevel : $root.infinitusai.be.ErrorLevel[message.errorLevel] : message.errorLevel;
                    if (message.sttConfidence != null && message.hasOwnProperty("sttConfidence"))
                        object.sttConfidence = options.json && !isFinite(message.sttConfidence) ? String(message.sttConfidence) : message.sttConfidence;
                    if (message.timestampEndMillis != null && message.hasOwnProperty("timestampEndMillis"))
                        if (typeof message.timestampEndMillis === "number")
                            object.timestampEndMillis = options.longs === String ? String(message.timestampEndMillis) : message.timestampEndMillis;
                        else
                            object.timestampEndMillis = options.longs === String ? $util.Long.prototype.toString.call(message.timestampEndMillis) : options.longs === Number ? new $util.LongBits(message.timestampEndMillis.low >>> 0, message.timestampEndMillis.high >>> 0).toNumber() : message.timestampEndMillis;
                    if (message.evaMuted != null && message.hasOwnProperty("evaMuted"))
                        object.evaMuted = message.evaMuted;
                    if (message.sourceTaskUuid != null && message.hasOwnProperty("sourceTaskUuid"))
                        object.sourceTaskUuid = message.sourceTaskUuid;
                    if (message.ttsRefId != null && message.hasOwnProperty("ttsRefId"))
                        object.ttsRefId = message.ttsRefId;
                    return object;
                };
    
                /**
                 * Converts this ChatLogRecord to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChatLogRecord
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChatLogRecord.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChatLogRecord
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChatLogRecord
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChatLogRecord.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChatLogRecord";
                };
    
                return ChatLogRecord;
            })();
    
            be.ChatLogRecords = (function() {
    
                /**
                 * Properties of a ChatLogRecords.
                 * @memberof infinitusai.be
                 * @interface IChatLogRecords
                 * @property {Array.<infinitusai.be.IChatLogRecord>|null} [records] ChatLogRecords records
                 * @property {Array.<string>|null} [recordIds] ChatLogRecords recordIds
                 */
    
                /**
                 * Constructs a new ChatLogRecords.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChatLogRecords.
                 * @implements IChatLogRecords
                 * @constructor
                 * @param {infinitusai.be.IChatLogRecords=} [properties] Properties to set
                 */
                function ChatLogRecords(properties) {
                    this.records = [];
                    this.recordIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChatLogRecords records.
                 * @member {Array.<infinitusai.be.IChatLogRecord>} records
                 * @memberof infinitusai.be.ChatLogRecords
                 * @instance
                 */
                ChatLogRecords.prototype.records = $util.emptyArray;
    
                /**
                 * ChatLogRecords recordIds.
                 * @member {Array.<string>} recordIds
                 * @memberof infinitusai.be.ChatLogRecords
                 * @instance
                 */
                ChatLogRecords.prototype.recordIds = $util.emptyArray;
    
                /**
                 * Creates a new ChatLogRecords instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {infinitusai.be.IChatLogRecords=} [properties] Properties to set
                 * @returns {infinitusai.be.ChatLogRecords} ChatLogRecords instance
                 */
                ChatLogRecords.create = function create(properties) {
                    return new ChatLogRecords(properties);
                };
    
                /**
                 * Encodes the specified ChatLogRecords message. Does not implicitly {@link infinitusai.be.ChatLogRecords.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {infinitusai.be.IChatLogRecords} message ChatLogRecords message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatLogRecords.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.records != null && message.records.length)
                        for (var i = 0; i < message.records.length; ++i)
                            $root.infinitusai.be.ChatLogRecord.encode(message.records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.recordIds != null && message.recordIds.length)
                        for (var i = 0; i < message.recordIds.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.recordIds[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified ChatLogRecords message, length delimited. Does not implicitly {@link infinitusai.be.ChatLogRecords.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {infinitusai.be.IChatLogRecords} message ChatLogRecords message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatLogRecords.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChatLogRecords message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChatLogRecords} ChatLogRecords
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatLogRecords.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChatLogRecords();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.records && message.records.length))
                                    message.records = [];
                                message.records.push($root.infinitusai.be.ChatLogRecord.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.recordIds && message.recordIds.length))
                                    message.recordIds = [];
                                message.recordIds.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChatLogRecords message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChatLogRecords} ChatLogRecords
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatLogRecords.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChatLogRecords message.
                 * @function verify
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChatLogRecords.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.records != null && message.hasOwnProperty("records")) {
                        if (!Array.isArray(message.records))
                            return "records: array expected";
                        for (var i = 0; i < message.records.length; ++i) {
                            var error = $root.infinitusai.be.ChatLogRecord.verify(message.records[i]);
                            if (error)
                                return "records." + error;
                        }
                    }
                    if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                        if (!Array.isArray(message.recordIds))
                            return "recordIds: array expected";
                        for (var i = 0; i < message.recordIds.length; ++i)
                            if (!$util.isString(message.recordIds[i]))
                                return "recordIds: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ChatLogRecords message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChatLogRecords} ChatLogRecords
                 */
                ChatLogRecords.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChatLogRecords)
                        return object;
                    var message = new $root.infinitusai.be.ChatLogRecords();
                    if (object.records) {
                        if (!Array.isArray(object.records))
                            throw TypeError(".infinitusai.be.ChatLogRecords.records: array expected");
                        message.records = [];
                        for (var i = 0; i < object.records.length; ++i) {
                            if (typeof object.records[i] !== "object")
                                throw TypeError(".infinitusai.be.ChatLogRecords.records: object expected");
                            message.records[i] = $root.infinitusai.be.ChatLogRecord.fromObject(object.records[i]);
                        }
                    }
                    if (object.recordIds) {
                        if (!Array.isArray(object.recordIds))
                            throw TypeError(".infinitusai.be.ChatLogRecords.recordIds: array expected");
                        message.recordIds = [];
                        for (var i = 0; i < object.recordIds.length; ++i)
                            message.recordIds[i] = String(object.recordIds[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChatLogRecords message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {infinitusai.be.ChatLogRecords} message ChatLogRecords
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChatLogRecords.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.records = [];
                        object.recordIds = [];
                    }
                    if (message.records && message.records.length) {
                        object.records = [];
                        for (var j = 0; j < message.records.length; ++j)
                            object.records[j] = $root.infinitusai.be.ChatLogRecord.toObject(message.records[j], options);
                    }
                    if (message.recordIds && message.recordIds.length) {
                        object.recordIds = [];
                        for (var j = 0; j < message.recordIds.length; ++j)
                            object.recordIds[j] = message.recordIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ChatLogRecords to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChatLogRecords
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChatLogRecords.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChatLogRecords
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChatLogRecords
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChatLogRecords.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChatLogRecords";
                };
    
                return ChatLogRecords;
            })();
    
            be.SharedAudioRequest = (function() {
    
                /**
                 * Properties of a SharedAudioRequest.
                 * @memberof infinitusai.be
                 * @interface ISharedAudioRequest
                 * @property {string|null} [utterance] SharedAudioRequest utterance
                 * @property {number|Long|null} [sampleRate] SharedAudioRequest sampleRate
                 * @property {infinitusai.be.TTSSetting.Voice|null} [ttsVoice] SharedAudioRequest ttsVoice
                 * @property {number|null} [ttsSpeed] SharedAudioRequest ttsSpeed
                 * @property {number|null} [ttsPitch] SharedAudioRequest ttsPitch
                 */
    
                /**
                 * Constructs a new SharedAudioRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SharedAudioRequest.
                 * @implements ISharedAudioRequest
                 * @constructor
                 * @param {infinitusai.be.ISharedAudioRequest=} [properties] Properties to set
                 */
                function SharedAudioRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SharedAudioRequest utterance.
                 * @member {string} utterance
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @instance
                 */
                SharedAudioRequest.prototype.utterance = "";
    
                /**
                 * SharedAudioRequest sampleRate.
                 * @member {number|Long} sampleRate
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @instance
                 */
                SharedAudioRequest.prototype.sampleRate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * SharedAudioRequest ttsVoice.
                 * @member {infinitusai.be.TTSSetting.Voice} ttsVoice
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @instance
                 */
                SharedAudioRequest.prototype.ttsVoice = 0;
    
                /**
                 * SharedAudioRequest ttsSpeed.
                 * @member {number} ttsSpeed
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @instance
                 */
                SharedAudioRequest.prototype.ttsSpeed = 0;
    
                /**
                 * SharedAudioRequest ttsPitch.
                 * @member {number} ttsPitch
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @instance
                 */
                SharedAudioRequest.prototype.ttsPitch = 0;
    
                /**
                 * Creates a new SharedAudioRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {infinitusai.be.ISharedAudioRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SharedAudioRequest} SharedAudioRequest instance
                 */
                SharedAudioRequest.create = function create(properties) {
                    return new SharedAudioRequest(properties);
                };
    
                /**
                 * Encodes the specified SharedAudioRequest message. Does not implicitly {@link infinitusai.be.SharedAudioRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {infinitusai.be.ISharedAudioRequest} message SharedAudioRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SharedAudioRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.utterance != null && Object.hasOwnProperty.call(message, "utterance"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.utterance);
                    if (message.sampleRate != null && Object.hasOwnProperty.call(message, "sampleRate"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sampleRate);
                    if (message.ttsVoice != null && Object.hasOwnProperty.call(message, "ttsVoice"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ttsVoice);
                    if (message.ttsSpeed != null && Object.hasOwnProperty.call(message, "ttsSpeed"))
                        writer.uint32(/* id 4, wireType 1 =*/33).double(message.ttsSpeed);
                    if (message.ttsPitch != null && Object.hasOwnProperty.call(message, "ttsPitch"))
                        writer.uint32(/* id 5, wireType 1 =*/41).double(message.ttsPitch);
                    return writer;
                };
    
                /**
                 * Encodes the specified SharedAudioRequest message, length delimited. Does not implicitly {@link infinitusai.be.SharedAudioRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {infinitusai.be.ISharedAudioRequest} message SharedAudioRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SharedAudioRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SharedAudioRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SharedAudioRequest} SharedAudioRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SharedAudioRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SharedAudioRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.utterance = reader.string();
                                break;
                            }
                        case 2: {
                                message.sampleRate = reader.int64();
                                break;
                            }
                        case 3: {
                                message.ttsVoice = reader.int32();
                                break;
                            }
                        case 4: {
                                message.ttsSpeed = reader.double();
                                break;
                            }
                        case 5: {
                                message.ttsPitch = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SharedAudioRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SharedAudioRequest} SharedAudioRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SharedAudioRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SharedAudioRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedAudioRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        if (!$util.isString(message.utterance))
                            return "utterance: string expected";
                    if (message.sampleRate != null && message.hasOwnProperty("sampleRate"))
                        if (!$util.isInteger(message.sampleRate) && !(message.sampleRate && $util.isInteger(message.sampleRate.low) && $util.isInteger(message.sampleRate.high)))
                            return "sampleRate: integer|Long expected";
                    if (message.ttsVoice != null && message.hasOwnProperty("ttsVoice"))
                        switch (message.ttsVoice) {
                        default:
                            return "ttsVoice: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 29:
                        case 30:
                        case 31:
                        case 32:
                        case 33:
                        case 34:
                            break;
                        }
                    if (message.ttsSpeed != null && message.hasOwnProperty("ttsSpeed"))
                        if (typeof message.ttsSpeed !== "number")
                            return "ttsSpeed: number expected";
                    if (message.ttsPitch != null && message.hasOwnProperty("ttsPitch"))
                        if (typeof message.ttsPitch !== "number")
                            return "ttsPitch: number expected";
                    return null;
                };
    
                /**
                 * Creates a SharedAudioRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SharedAudioRequest} SharedAudioRequest
                 */
                SharedAudioRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SharedAudioRequest)
                        return object;
                    var message = new $root.infinitusai.be.SharedAudioRequest();
                    if (object.utterance != null)
                        message.utterance = String(object.utterance);
                    if (object.sampleRate != null)
                        if ($util.Long)
                            (message.sampleRate = $util.Long.fromValue(object.sampleRate)).unsigned = false;
                        else if (typeof object.sampleRate === "string")
                            message.sampleRate = parseInt(object.sampleRate, 10);
                        else if (typeof object.sampleRate === "number")
                            message.sampleRate = object.sampleRate;
                        else if (typeof object.sampleRate === "object")
                            message.sampleRate = new $util.LongBits(object.sampleRate.low >>> 0, object.sampleRate.high >>> 0).toNumber();
                    switch (object.ttsVoice) {
                    default:
                        if (typeof object.ttsVoice === "number") {
                            message.ttsVoice = object.ttsVoice;
                            break;
                        }
                        break;
                    case "en_US_Wavenet_A":
                    case 0:
                        message.ttsVoice = 0;
                        break;
                    case "en_US_Wavenet_B":
                    case 1:
                        message.ttsVoice = 1;
                        break;
                    case "en_US_Wavenet_C":
                    case 2:
                        message.ttsVoice = 2;
                        break;
                    case "en_US_Wavenet_D":
                    case 3:
                        message.ttsVoice = 3;
                        break;
                    case "en_US_Wavenet_E":
                    case 4:
                        message.ttsVoice = 4;
                        break;
                    case "en_US_Wavenet_F":
                    case 5:
                        message.ttsVoice = 5;
                        break;
                    case "en_US_Neural2_F":
                    case 6:
                        message.ttsVoice = 6;
                        break;
                    case "en_US_Neural2_G":
                    case 7:
                        message.ttsVoice = 7;
                        break;
                    case "en_US_Neural2_H":
                    case 8:
                        message.ttsVoice = 8;
                        break;
                    case "en_US_Studio_M":
                    case 9:
                        message.ttsVoice = 9;
                        break;
                    case "en_US_Studio_O":
                    case 10:
                        message.ttsVoice = 10;
                        break;
                    case "elevenLabs_Rahul":
                    case 11:
                        message.ttsVoice = 11;
                        break;
                    case "elevenLabs_Jack":
                    case 12:
                        message.ttsVoice = 12;
                        break;
                    case "elevenLabs_Ira":
                    case 13:
                        message.ttsVoice = 13;
                        break;
                    case "elevenLabs_Rebbecca":
                    case 14:
                        message.ttsVoice = 14;
                        break;
                    case "elevenLabs_Sneha":
                    case 15:
                        message.ttsVoice = 15;
                        break;
                    case "elevenLabs_Tom":
                    case 16:
                        message.ttsVoice = 16;
                        break;
                    case "elevenLabs_Alfred":
                    case 17:
                        message.ttsVoice = 17;
                        break;
                    case "elevenLabs_Adam":
                    case 18:
                        message.ttsVoice = 18;
                        break;
                    case "elevenLabs_Sir_Paddington":
                    case 19:
                        message.ttsVoice = 19;
                        break;
                    case "elevenLabs_Emma":
                    case 20:
                        message.ttsVoice = 20;
                        break;
                    case "elevenLabs_Meera":
                    case 21:
                        message.ttsVoice = 21;
                        break;
                    case "elevenLabs_Shauna":
                    case 22:
                        message.ttsVoice = 22;
                        break;
                    case "elevenLabs_Shyam":
                    case 23:
                        message.ttsVoice = 23;
                        break;
                    case "elevenLabs_Tamika":
                    case 24:
                        message.ttsVoice = 24;
                        break;
                    case "elevenLabs_Bria":
                    case 25:
                        message.ttsVoice = 25;
                        break;
                    case "elevenLabs_Won":
                    case 26:
                        message.ttsVoice = 26;
                        break;
                    case "elevenLabs_AJ":
                    case 27:
                        message.ttsVoice = 27;
                        break;
                    case "elevenLabs_DK":
                    case 28:
                        message.ttsVoice = 28;
                        break;
                    case "elevenLabs_JB":
                    case 29:
                        message.ttsVoice = 29;
                        break;
                    case "elevenLabs_RP":
                    case 30:
                        message.ttsVoice = 30;
                        break;
                    case "elevenLabs_SR":
                    case 31:
                        message.ttsVoice = 31;
                        break;
                    case "elevenLabs_WW":
                    case 32:
                        message.ttsVoice = 32;
                        break;
                    case "elevenLabs_RR":
                    case 33:
                        message.ttsVoice = 33;
                        break;
                    case "elevenLabs_JH":
                    case 34:
                        message.ttsVoice = 34;
                        break;
                    }
                    if (object.ttsSpeed != null)
                        message.ttsSpeed = Number(object.ttsSpeed);
                    if (object.ttsPitch != null)
                        message.ttsPitch = Number(object.ttsPitch);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SharedAudioRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {infinitusai.be.SharedAudioRequest} message SharedAudioRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedAudioRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.utterance = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.sampleRate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sampleRate = options.longs === String ? "0" : 0;
                        object.ttsVoice = options.enums === String ? "en_US_Wavenet_A" : 0;
                        object.ttsSpeed = 0;
                        object.ttsPitch = 0;
                    }
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        object.utterance = message.utterance;
                    if (message.sampleRate != null && message.hasOwnProperty("sampleRate"))
                        if (typeof message.sampleRate === "number")
                            object.sampleRate = options.longs === String ? String(message.sampleRate) : message.sampleRate;
                        else
                            object.sampleRate = options.longs === String ? $util.Long.prototype.toString.call(message.sampleRate) : options.longs === Number ? new $util.LongBits(message.sampleRate.low >>> 0, message.sampleRate.high >>> 0).toNumber() : message.sampleRate;
                    if (message.ttsVoice != null && message.hasOwnProperty("ttsVoice"))
                        object.ttsVoice = options.enums === String ? $root.infinitusai.be.TTSSetting.Voice[message.ttsVoice] === undefined ? message.ttsVoice : $root.infinitusai.be.TTSSetting.Voice[message.ttsVoice] : message.ttsVoice;
                    if (message.ttsSpeed != null && message.hasOwnProperty("ttsSpeed"))
                        object.ttsSpeed = options.json && !isFinite(message.ttsSpeed) ? String(message.ttsSpeed) : message.ttsSpeed;
                    if (message.ttsPitch != null && message.hasOwnProperty("ttsPitch"))
                        object.ttsPitch = options.json && !isFinite(message.ttsPitch) ? String(message.ttsPitch) : message.ttsPitch;
                    return object;
                };
    
                /**
                 * Converts this SharedAudioRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedAudioRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SharedAudioRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SharedAudioRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SharedAudioRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SharedAudioRequest";
                };
    
                return SharedAudioRequest;
            })();
    
            be.NullableString = (function() {
    
                /**
                 * Properties of a NullableString.
                 * @memberof infinitusai.be
                 * @interface INullableString
                 * @property {string|null} [value] NullableString value
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] NullableString metadata
                 */
    
                /**
                 * Constructs a new NullableString.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NullableString.
                 * @implements INullableString
                 * @constructor
                 * @param {infinitusai.be.INullableString=} [properties] Properties to set
                 */
                function NullableString(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NullableString value.
                 * @member {string} value
                 * @memberof infinitusai.be.NullableString
                 * @instance
                 */
                NullableString.prototype.value = "";
    
                /**
                 * NullableString metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.NullableString
                 * @instance
                 */
                NullableString.prototype.metadata = null;
    
                /**
                 * Creates a new NullableString instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {infinitusai.be.INullableString=} [properties] Properties to set
                 * @returns {infinitusai.be.NullableString} NullableString instance
                 */
                NullableString.create = function create(properties) {
                    return new NullableString(properties);
                };
    
                /**
                 * Encodes the specified NullableString message. Does not implicitly {@link infinitusai.be.NullableString.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {infinitusai.be.INullableString} message NullableString message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NullableString.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified NullableString message, length delimited. Does not implicitly {@link infinitusai.be.NullableString.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {infinitusai.be.INullableString} message NullableString message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NullableString.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NullableString message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NullableString} NullableString
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NullableString.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NullableString();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.string();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NullableString message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NullableString} NullableString
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NullableString.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NullableString message.
                 * @function verify
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NullableString.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a NullableString message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NullableString} NullableString
                 */
                NullableString.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NullableString)
                        return object;
                    var message = new $root.infinitusai.be.NullableString();
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.NullableString.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a NullableString message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {infinitusai.be.NullableString} message NullableString
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NullableString.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.value = "";
                        object.metadata = null;
                    }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this NullableString to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NullableString
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NullableString.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NullableString
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NullableString
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NullableString.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NullableString";
                };
    
                return NullableString;
            })();
    
            be.NullableBool = (function() {
    
                /**
                 * Properties of a NullableBool.
                 * @memberof infinitusai.be
                 * @interface INullableBool
                 * @property {boolean|null} [value] NullableBool value
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] NullableBool metadata
                 */
    
                /**
                 * Constructs a new NullableBool.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NullableBool.
                 * @implements INullableBool
                 * @constructor
                 * @param {infinitusai.be.INullableBool=} [properties] Properties to set
                 */
                function NullableBool(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NullableBool value.
                 * @member {boolean} value
                 * @memberof infinitusai.be.NullableBool
                 * @instance
                 */
                NullableBool.prototype.value = false;
    
                /**
                 * NullableBool metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.NullableBool
                 * @instance
                 */
                NullableBool.prototype.metadata = null;
    
                /**
                 * Creates a new NullableBool instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {infinitusai.be.INullableBool=} [properties] Properties to set
                 * @returns {infinitusai.be.NullableBool} NullableBool instance
                 */
                NullableBool.create = function create(properties) {
                    return new NullableBool(properties);
                };
    
                /**
                 * Encodes the specified NullableBool message. Does not implicitly {@link infinitusai.be.NullableBool.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {infinitusai.be.INullableBool} message NullableBool message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NullableBool.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified NullableBool message, length delimited. Does not implicitly {@link infinitusai.be.NullableBool.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {infinitusai.be.INullableBool} message NullableBool message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NullableBool.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NullableBool message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NullableBool} NullableBool
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NullableBool.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NullableBool();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.bool();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NullableBool message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NullableBool} NullableBool
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NullableBool.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NullableBool message.
                 * @function verify
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NullableBool.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "boolean")
                            return "value: boolean expected";
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a NullableBool message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NullableBool} NullableBool
                 */
                NullableBool.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NullableBool)
                        return object;
                    var message = new $root.infinitusai.be.NullableBool();
                    if (object.value != null)
                        message.value = Boolean(object.value);
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.NullableBool.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a NullableBool message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {infinitusai.be.NullableBool} message NullableBool
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NullableBool.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.value = false;
                        object.metadata = null;
                    }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this NullableBool to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NullableBool
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NullableBool.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NullableBool
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NullableBool
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NullableBool.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NullableBool";
                };
    
                return NullableBool;
            })();
    
            be.NullableInt32 = (function() {
    
                /**
                 * Properties of a NullableInt32.
                 * @memberof infinitusai.be
                 * @interface INullableInt32
                 * @property {number|null} [value] NullableInt32 value
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] NullableInt32 metadata
                 */
    
                /**
                 * Constructs a new NullableInt32.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NullableInt32.
                 * @implements INullableInt32
                 * @constructor
                 * @param {infinitusai.be.INullableInt32=} [properties] Properties to set
                 */
                function NullableInt32(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NullableInt32 value.
                 * @member {number} value
                 * @memberof infinitusai.be.NullableInt32
                 * @instance
                 */
                NullableInt32.prototype.value = 0;
    
                /**
                 * NullableInt32 metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.NullableInt32
                 * @instance
                 */
                NullableInt32.prototype.metadata = null;
    
                /**
                 * Creates a new NullableInt32 instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {infinitusai.be.INullableInt32=} [properties] Properties to set
                 * @returns {infinitusai.be.NullableInt32} NullableInt32 instance
                 */
                NullableInt32.create = function create(properties) {
                    return new NullableInt32(properties);
                };
    
                /**
                 * Encodes the specified NullableInt32 message. Does not implicitly {@link infinitusai.be.NullableInt32.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {infinitusai.be.INullableInt32} message NullableInt32 message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NullableInt32.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified NullableInt32 message, length delimited. Does not implicitly {@link infinitusai.be.NullableInt32.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {infinitusai.be.INullableInt32} message NullableInt32 message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NullableInt32.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NullableInt32 message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NullableInt32} NullableInt32
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NullableInt32.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NullableInt32();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NullableInt32 message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NullableInt32} NullableInt32
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NullableInt32.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NullableInt32 message.
                 * @function verify
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NullableInt32.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value))
                            return "value: integer expected";
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a NullableInt32 message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NullableInt32} NullableInt32
                 */
                NullableInt32.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NullableInt32)
                        return object;
                    var message = new $root.infinitusai.be.NullableInt32();
                    if (object.value != null)
                        message.value = object.value | 0;
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.NullableInt32.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a NullableInt32 message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {infinitusai.be.NullableInt32} message NullableInt32
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NullableInt32.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.value = 0;
                        object.metadata = null;
                    }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this NullableInt32 to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NullableInt32
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NullableInt32.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NullableInt32
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NullableInt32
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NullableInt32.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NullableInt32";
                };
    
                return NullableInt32;
            })();
    
            be.Date = (function() {
    
                /**
                 * Properties of a Date.
                 * @memberof infinitusai.be
                 * @interface IDate
                 * @property {number|null} [day] Date day
                 * @property {number|null} [month] Date month
                 * @property {number|null} [year] Date year
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] Date metadata
                 */
    
                /**
                 * Constructs a new Date.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Date.
                 * @implements IDate
                 * @constructor
                 * @param {infinitusai.be.IDate=} [properties] Properties to set
                 */
                function Date(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Date day.
                 * @member {number} day
                 * @memberof infinitusai.be.Date
                 * @instance
                 */
                Date.prototype.day = 0;
    
                /**
                 * Date month.
                 * @member {number} month
                 * @memberof infinitusai.be.Date
                 * @instance
                 */
                Date.prototype.month = 0;
    
                /**
                 * Date year.
                 * @member {number} year
                 * @memberof infinitusai.be.Date
                 * @instance
                 */
                Date.prototype.year = 0;
    
                /**
                 * Date metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.Date
                 * @instance
                 */
                Date.prototype.metadata = null;
    
                /**
                 * Creates a new Date instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {infinitusai.be.IDate=} [properties] Properties to set
                 * @returns {infinitusai.be.Date} Date instance
                 */
                Date.create = function create(properties) {
                    return new Date(properties);
                };
    
                /**
                 * Encodes the specified Date message. Does not implicitly {@link infinitusai.be.Date.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {infinitusai.be.IDate} message Date message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Date.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.day);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.year);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Date message, length delimited. Does not implicitly {@link infinitusai.be.Date.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {infinitusai.be.IDate} message Date message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Date.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Date message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Date} Date
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Date.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Date();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.day = reader.int32();
                                break;
                            }
                        case 2: {
                                message.month = reader.int32();
                                break;
                            }
                        case 3: {
                                message.year = reader.int32();
                                break;
                            }
                        case 4: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Date message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Date} Date
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Date.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Date message.
                 * @function verify
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Date.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.day != null && message.hasOwnProperty("day"))
                        if (!$util.isInteger(message.day))
                            return "day: integer expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isInteger(message.month))
                            return "month: integer expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a Date message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Date} Date
                 */
                Date.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Date)
                        return object;
                    var message = new $root.infinitusai.be.Date();
                    if (object.day != null)
                        message.day = object.day | 0;
                    if (object.month != null)
                        message.month = object.month | 0;
                    if (object.year != null)
                        message.year = object.year | 0;
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.Date.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Date message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {infinitusai.be.Date} message Date
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Date.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.day = 0;
                        object.month = 0;
                        object.year = 0;
                        object.metadata = null;
                    }
                    if (message.day != null && message.hasOwnProperty("day"))
                        object.day = message.day;
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this Date to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Date
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Date.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Date
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Date
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Date.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Date";
                };
    
                return Date;
            })();
    
            be.UserInfo = (function() {
    
                /**
                 * Properties of a UserInfo.
                 * @memberof infinitusai.be
                 * @interface IUserInfo
                 * @property {string|null} [userId] UserInfo userId
                 * @property {string|null} [userEmail] UserInfo userEmail
                 */
    
                /**
                 * Constructs a new UserInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a UserInfo.
                 * @implements IUserInfo
                 * @constructor
                 * @param {infinitusai.be.IUserInfo=} [properties] Properties to set
                 */
                function UserInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UserInfo userId.
                 * @member {string} userId
                 * @memberof infinitusai.be.UserInfo
                 * @instance
                 */
                UserInfo.prototype.userId = "";
    
                /**
                 * UserInfo userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.UserInfo
                 * @instance
                 */
                UserInfo.prototype.userEmail = "";
    
                /**
                 * Creates a new UserInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {infinitusai.be.IUserInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.UserInfo} UserInfo instance
                 */
                UserInfo.create = function create(properties) {
                    return new UserInfo(properties);
                };
    
                /**
                 * Encodes the specified UserInfo message. Does not implicitly {@link infinitusai.be.UserInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {infinitusai.be.IUserInfo} message UserInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified UserInfo message, length delimited. Does not implicitly {@link infinitusai.be.UserInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {infinitusai.be.IUserInfo} message UserInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UserInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UserInfo} UserInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UserInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.userEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UserInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UserInfo} UserInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UserInfo message.
                 * @function verify
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a UserInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UserInfo} UserInfo
                 */
                UserInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UserInfo)
                        return object;
                    var message = new $root.infinitusai.be.UserInfo();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a UserInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {infinitusai.be.UserInfo} message UserInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userId = "";
                        object.userEmail = "";
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    return object;
                };
    
                /**
                 * Converts this UserInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UserInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UserInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UserInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UserInfo";
                };
    
                return UserInfo;
            })();
    
            /**
             * QueueingAlgorithm enum.
             * @name infinitusai.be.QueueingAlgorithm
             * @enum {number}
             * @property {number} QUEUEING_MANUAL=0 QUEUEING_MANUAL value
             * @property {number} QUEUEING_DEFAULT=1 QUEUEING_DEFAULT value
             * @property {number} QUEUEING_SHAQ=2 QUEUEING_SHAQ value
             */
            be.QueueingAlgorithm = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "QUEUEING_MANUAL"] = 0;
                values[valuesById[1] = "QUEUEING_DEFAULT"] = 1;
                values[valuesById[2] = "QUEUEING_SHAQ"] = 2;
                return values;
            })();
    
            be.CallOutputsDataValidationError = (function() {
    
                /**
                 * Properties of a CallOutputsDataValidationError.
                 * @memberof infinitusai.be
                 * @interface ICallOutputsDataValidationError
                 * @property {infinitusai.be.CallOutputsDataValidationError.Severity|null} [severity] CallOutputsDataValidationError severity
                 * @property {Array.<string>|null} [dataProtoPaths] CallOutputsDataValidationError dataProtoPaths
                 * @property {string|null} [notes] CallOutputsDataValidationError notes
                 * @property {string|null} [outputSuggestionJson] CallOutputsDataValidationError outputSuggestionJson
                 */
    
                /**
                 * Constructs a new CallOutputsDataValidationError.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallOutputsDataValidationError.
                 * @implements ICallOutputsDataValidationError
                 * @constructor
                 * @param {infinitusai.be.ICallOutputsDataValidationError=} [properties] Properties to set
                 */
                function CallOutputsDataValidationError(properties) {
                    this.dataProtoPaths = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallOutputsDataValidationError severity.
                 * @member {infinitusai.be.CallOutputsDataValidationError.Severity} severity
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @instance
                 */
                CallOutputsDataValidationError.prototype.severity = 0;
    
                /**
                 * CallOutputsDataValidationError dataProtoPaths.
                 * @member {Array.<string>} dataProtoPaths
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @instance
                 */
                CallOutputsDataValidationError.prototype.dataProtoPaths = $util.emptyArray;
    
                /**
                 * CallOutputsDataValidationError notes.
                 * @member {string} notes
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @instance
                 */
                CallOutputsDataValidationError.prototype.notes = "";
    
                /**
                 * CallOutputsDataValidationError outputSuggestionJson.
                 * @member {string} outputSuggestionJson
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @instance
                 */
                CallOutputsDataValidationError.prototype.outputSuggestionJson = "";
    
                /**
                 * Creates a new CallOutputsDataValidationError instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {infinitusai.be.ICallOutputsDataValidationError=} [properties] Properties to set
                 * @returns {infinitusai.be.CallOutputsDataValidationError} CallOutputsDataValidationError instance
                 */
                CallOutputsDataValidationError.create = function create(properties) {
                    return new CallOutputsDataValidationError(properties);
                };
    
                /**
                 * Encodes the specified CallOutputsDataValidationError message. Does not implicitly {@link infinitusai.be.CallOutputsDataValidationError.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {infinitusai.be.ICallOutputsDataValidationError} message CallOutputsDataValidationError message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallOutputsDataValidationError.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.severity != null && Object.hasOwnProperty.call(message, "severity"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.severity);
                    if (message.dataProtoPaths != null && message.dataProtoPaths.length)
                        for (var i = 0; i < message.dataProtoPaths.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dataProtoPaths[i]);
                    if (message.notes != null && Object.hasOwnProperty.call(message, "notes"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.notes);
                    if (message.outputSuggestionJson != null && Object.hasOwnProperty.call(message, "outputSuggestionJson"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.outputSuggestionJson);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallOutputsDataValidationError message, length delimited. Does not implicitly {@link infinitusai.be.CallOutputsDataValidationError.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {infinitusai.be.ICallOutputsDataValidationError} message CallOutputsDataValidationError message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallOutputsDataValidationError.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallOutputsDataValidationError message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallOutputsDataValidationError} CallOutputsDataValidationError
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallOutputsDataValidationError.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallOutputsDataValidationError();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.severity = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.dataProtoPaths && message.dataProtoPaths.length))
                                    message.dataProtoPaths = [];
                                message.dataProtoPaths.push(reader.string());
                                break;
                            }
                        case 3: {
                                message.notes = reader.string();
                                break;
                            }
                        case 4: {
                                message.outputSuggestionJson = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallOutputsDataValidationError message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallOutputsDataValidationError} CallOutputsDataValidationError
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallOutputsDataValidationError.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallOutputsDataValidationError message.
                 * @function verify
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallOutputsDataValidationError.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.severity != null && message.hasOwnProperty("severity"))
                        switch (message.severity) {
                        default:
                            return "severity: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.dataProtoPaths != null && message.hasOwnProperty("dataProtoPaths")) {
                        if (!Array.isArray(message.dataProtoPaths))
                            return "dataProtoPaths: array expected";
                        for (var i = 0; i < message.dataProtoPaths.length; ++i)
                            if (!$util.isString(message.dataProtoPaths[i]))
                                return "dataProtoPaths: string[] expected";
                    }
                    if (message.notes != null && message.hasOwnProperty("notes"))
                        if (!$util.isString(message.notes))
                            return "notes: string expected";
                    if (message.outputSuggestionJson != null && message.hasOwnProperty("outputSuggestionJson"))
                        if (!$util.isString(message.outputSuggestionJson))
                            return "outputSuggestionJson: string expected";
                    return null;
                };
    
                /**
                 * Creates a CallOutputsDataValidationError message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallOutputsDataValidationError} CallOutputsDataValidationError
                 */
                CallOutputsDataValidationError.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallOutputsDataValidationError)
                        return object;
                    var message = new $root.infinitusai.be.CallOutputsDataValidationError();
                    switch (object.severity) {
                    default:
                        if (typeof object.severity === "number") {
                            message.severity = object.severity;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.severity = 0;
                        break;
                    case "WARNING":
                    case 1:
                        message.severity = 1;
                        break;
                    case "ERROR":
                    case 2:
                        message.severity = 2;
                        break;
                    case "SUGGESTION":
                    case 3:
                        message.severity = 3;
                        break;
                    }
                    if (object.dataProtoPaths) {
                        if (!Array.isArray(object.dataProtoPaths))
                            throw TypeError(".infinitusai.be.CallOutputsDataValidationError.dataProtoPaths: array expected");
                        message.dataProtoPaths = [];
                        for (var i = 0; i < object.dataProtoPaths.length; ++i)
                            message.dataProtoPaths[i] = String(object.dataProtoPaths[i]);
                    }
                    if (object.notes != null)
                        message.notes = String(object.notes);
                    if (object.outputSuggestionJson != null)
                        message.outputSuggestionJson = String(object.outputSuggestionJson);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallOutputsDataValidationError message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {infinitusai.be.CallOutputsDataValidationError} message CallOutputsDataValidationError
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallOutputsDataValidationError.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.dataProtoPaths = [];
                    if (options.defaults) {
                        object.severity = options.enums === String ? "UNKNOWN" : 0;
                        object.notes = "";
                        object.outputSuggestionJson = "";
                    }
                    if (message.severity != null && message.hasOwnProperty("severity"))
                        object.severity = options.enums === String ? $root.infinitusai.be.CallOutputsDataValidationError.Severity[message.severity] === undefined ? message.severity : $root.infinitusai.be.CallOutputsDataValidationError.Severity[message.severity] : message.severity;
                    if (message.dataProtoPaths && message.dataProtoPaths.length) {
                        object.dataProtoPaths = [];
                        for (var j = 0; j < message.dataProtoPaths.length; ++j)
                            object.dataProtoPaths[j] = message.dataProtoPaths[j];
                    }
                    if (message.notes != null && message.hasOwnProperty("notes"))
                        object.notes = message.notes;
                    if (message.outputSuggestionJson != null && message.hasOwnProperty("outputSuggestionJson"))
                        object.outputSuggestionJson = message.outputSuggestionJson;
                    return object;
                };
    
                /**
                 * Converts this CallOutputsDataValidationError to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallOutputsDataValidationError.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallOutputsDataValidationError
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallOutputsDataValidationError
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallOutputsDataValidationError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallOutputsDataValidationError";
                };
    
                /**
                 * Severity enum.
                 * @name infinitusai.be.CallOutputsDataValidationError.Severity
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} WARNING=1 WARNING value
                 * @property {number} ERROR=2 ERROR value
                 * @property {number} SUGGESTION=3 SUGGESTION value
                 */
                CallOutputsDataValidationError.Severity = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "WARNING"] = 1;
                    values[valuesById[2] = "ERROR"] = 2;
                    values[valuesById[3] = "SUGGESTION"] = 3;
                    return values;
                })();
    
                return CallOutputsDataValidationError;
            })();
    
            be.Money = (function() {
    
                /**
                 * Properties of a Money.
                 * @memberof infinitusai.be
                 * @interface IMoney
                 * @property {string|null} [currencyCode] Money currencyCode
                 * @property {number|Long|null} [units] Money units
                 * @property {number|null} [nanos] Money nanos
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] Money metadata
                 */
    
                /**
                 * Constructs a new Money.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Money.
                 * @implements IMoney
                 * @constructor
                 * @param {infinitusai.be.IMoney=} [properties] Properties to set
                 */
                function Money(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Money currencyCode.
                 * @member {string} currencyCode
                 * @memberof infinitusai.be.Money
                 * @instance
                 */
                Money.prototype.currencyCode = "";
    
                /**
                 * Money units.
                 * @member {number|Long} units
                 * @memberof infinitusai.be.Money
                 * @instance
                 */
                Money.prototype.units = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Money nanos.
                 * @member {number} nanos
                 * @memberof infinitusai.be.Money
                 * @instance
                 */
                Money.prototype.nanos = 0;
    
                /**
                 * Money metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.Money
                 * @instance
                 */
                Money.prototype.metadata = null;
    
                /**
                 * Creates a new Money instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {infinitusai.be.IMoney=} [properties] Properties to set
                 * @returns {infinitusai.be.Money} Money instance
                 */
                Money.create = function create(properties) {
                    return new Money(properties);
                };
    
                /**
                 * Encodes the specified Money message. Does not implicitly {@link infinitusai.be.Money.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {infinitusai.be.IMoney} message Money message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Money.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.currencyCode);
                    if (message.units != null && Object.hasOwnProperty.call(message, "units"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.units);
                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.nanos);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Money message, length delimited. Does not implicitly {@link infinitusai.be.Money.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {infinitusai.be.IMoney} message Money message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Money.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Money message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Money} Money
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Money.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Money();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.units = reader.int64();
                                break;
                            }
                        case 3: {
                                message.nanos = reader.int32();
                                break;
                            }
                        case 4: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Money message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Money} Money
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Money.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Money message.
                 * @function verify
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Money.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    if (message.units != null && message.hasOwnProperty("units"))
                        if (!$util.isInteger(message.units) && !(message.units && $util.isInteger(message.units.low) && $util.isInteger(message.units.high)))
                            return "units: integer|Long expected";
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        if (!$util.isInteger(message.nanos))
                            return "nanos: integer expected";
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a Money message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Money} Money
                 */
                Money.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Money)
                        return object;
                    var message = new $root.infinitusai.be.Money();
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    if (object.units != null)
                        if ($util.Long)
                            (message.units = $util.Long.fromValue(object.units)).unsigned = false;
                        else if (typeof object.units === "string")
                            message.units = parseInt(object.units, 10);
                        else if (typeof object.units === "number")
                            message.units = object.units;
                        else if (typeof object.units === "object")
                            message.units = new $util.LongBits(object.units.low >>> 0, object.units.high >>> 0).toNumber();
                    if (object.nanos != null)
                        message.nanos = object.nanos | 0;
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.Money.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Money message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {infinitusai.be.Money} message Money
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Money.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.currencyCode = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.units = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.units = options.longs === String ? "0" : 0;
                        object.nanos = 0;
                        object.metadata = null;
                    }
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    if (message.units != null && message.hasOwnProperty("units"))
                        if (typeof message.units === "number")
                            object.units = options.longs === String ? String(message.units) : message.units;
                        else
                            object.units = options.longs === String ? $util.Long.prototype.toString.call(message.units) : options.longs === Number ? new $util.LongBits(message.units.low >>> 0, message.units.high >>> 0).toNumber() : message.units;
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        object.nanos = message.nanos;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this Money to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Money
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Money.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Money
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Money
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Money.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Money";
                };
    
                return Money;
            })();
    
            be.StreetAddress = (function() {
    
                /**
                 * Properties of a StreetAddress.
                 * @memberof infinitusai.be
                 * @interface IStreetAddress
                 * @property {infinitusai.be.INullableString|null} [streetAddress] StreetAddress streetAddress
                 * @property {infinitusai.be.INullableString|null} [streetAddressLine2] StreetAddress streetAddressLine2
                 * @property {infinitusai.be.INullableString|null} [city] StreetAddress city
                 * @property {infinitusai.be.INullableString|null} [state] StreetAddress state
                 * @property {infinitusai.be.INullableString|null} [zip] StreetAddress zip
                 */
    
                /**
                 * Constructs a new StreetAddress.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StreetAddress.
                 * @implements IStreetAddress
                 * @constructor
                 * @param {infinitusai.be.IStreetAddress=} [properties] Properties to set
                 */
                function StreetAddress(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StreetAddress streetAddress.
                 * @member {infinitusai.be.INullableString|null|undefined} streetAddress
                 * @memberof infinitusai.be.StreetAddress
                 * @instance
                 */
                StreetAddress.prototype.streetAddress = null;
    
                /**
                 * StreetAddress streetAddressLine2.
                 * @member {infinitusai.be.INullableString|null|undefined} streetAddressLine2
                 * @memberof infinitusai.be.StreetAddress
                 * @instance
                 */
                StreetAddress.prototype.streetAddressLine2 = null;
    
                /**
                 * StreetAddress city.
                 * @member {infinitusai.be.INullableString|null|undefined} city
                 * @memberof infinitusai.be.StreetAddress
                 * @instance
                 */
                StreetAddress.prototype.city = null;
    
                /**
                 * StreetAddress state.
                 * @member {infinitusai.be.INullableString|null|undefined} state
                 * @memberof infinitusai.be.StreetAddress
                 * @instance
                 */
                StreetAddress.prototype.state = null;
    
                /**
                 * StreetAddress zip.
                 * @member {infinitusai.be.INullableString|null|undefined} zip
                 * @memberof infinitusai.be.StreetAddress
                 * @instance
                 */
                StreetAddress.prototype.zip = null;
    
                /**
                 * Creates a new StreetAddress instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {infinitusai.be.IStreetAddress=} [properties] Properties to set
                 * @returns {infinitusai.be.StreetAddress} StreetAddress instance
                 */
                StreetAddress.create = function create(properties) {
                    return new StreetAddress(properties);
                };
    
                /**
                 * Encodes the specified StreetAddress message. Does not implicitly {@link infinitusai.be.StreetAddress.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {infinitusai.be.IStreetAddress} message StreetAddress message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StreetAddress.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.streetAddress != null && Object.hasOwnProperty.call(message, "streetAddress"))
                        $root.infinitusai.be.NullableString.encode(message.streetAddress, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.streetAddressLine2 != null && Object.hasOwnProperty.call(message, "streetAddressLine2"))
                        $root.infinitusai.be.NullableString.encode(message.streetAddressLine2, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                        $root.infinitusai.be.NullableString.encode(message.city, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        $root.infinitusai.be.NullableString.encode(message.state, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                        $root.infinitusai.be.NullableString.encode(message.zip, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StreetAddress message, length delimited. Does not implicitly {@link infinitusai.be.StreetAddress.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {infinitusai.be.IStreetAddress} message StreetAddress message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StreetAddress.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StreetAddress message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StreetAddress} StreetAddress
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StreetAddress.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StreetAddress();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.streetAddress = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.streetAddressLine2 = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.city = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.state = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.zip = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StreetAddress message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StreetAddress} StreetAddress
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StreetAddress.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StreetAddress message.
                 * @function verify
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StreetAddress.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.streetAddress != null && message.hasOwnProperty("streetAddress")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.streetAddress);
                        if (error)
                            return "streetAddress." + error;
                    }
                    if (message.streetAddressLine2 != null && message.hasOwnProperty("streetAddressLine2")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.streetAddressLine2);
                        if (error)
                            return "streetAddressLine2." + error;
                    }
                    if (message.city != null && message.hasOwnProperty("city")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.city);
                        if (error)
                            return "city." + error;
                    }
                    if (message.state != null && message.hasOwnProperty("state")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.state);
                        if (error)
                            return "state." + error;
                    }
                    if (message.zip != null && message.hasOwnProperty("zip")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.zip);
                        if (error)
                            return "zip." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a StreetAddress message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StreetAddress} StreetAddress
                 */
                StreetAddress.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StreetAddress)
                        return object;
                    var message = new $root.infinitusai.be.StreetAddress();
                    if (object.streetAddress != null) {
                        if (typeof object.streetAddress !== "object")
                            throw TypeError(".infinitusai.be.StreetAddress.streetAddress: object expected");
                        message.streetAddress = $root.infinitusai.be.NullableString.fromObject(object.streetAddress);
                    }
                    if (object.streetAddressLine2 != null) {
                        if (typeof object.streetAddressLine2 !== "object")
                            throw TypeError(".infinitusai.be.StreetAddress.streetAddressLine2: object expected");
                        message.streetAddressLine2 = $root.infinitusai.be.NullableString.fromObject(object.streetAddressLine2);
                    }
                    if (object.city != null) {
                        if (typeof object.city !== "object")
                            throw TypeError(".infinitusai.be.StreetAddress.city: object expected");
                        message.city = $root.infinitusai.be.NullableString.fromObject(object.city);
                    }
                    if (object.state != null) {
                        if (typeof object.state !== "object")
                            throw TypeError(".infinitusai.be.StreetAddress.state: object expected");
                        message.state = $root.infinitusai.be.NullableString.fromObject(object.state);
                    }
                    if (object.zip != null) {
                        if (typeof object.zip !== "object")
                            throw TypeError(".infinitusai.be.StreetAddress.zip: object expected");
                        message.zip = $root.infinitusai.be.NullableString.fromObject(object.zip);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StreetAddress message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {infinitusai.be.StreetAddress} message StreetAddress
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StreetAddress.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.streetAddress = null;
                        object.streetAddressLine2 = null;
                        object.city = null;
                        object.state = null;
                        object.zip = null;
                    }
                    if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
                        object.streetAddress = $root.infinitusai.be.NullableString.toObject(message.streetAddress, options);
                    if (message.streetAddressLine2 != null && message.hasOwnProperty("streetAddressLine2"))
                        object.streetAddressLine2 = $root.infinitusai.be.NullableString.toObject(message.streetAddressLine2, options);
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = $root.infinitusai.be.NullableString.toObject(message.city, options);
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = $root.infinitusai.be.NullableString.toObject(message.state, options);
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        object.zip = $root.infinitusai.be.NullableString.toObject(message.zip, options);
                    return object;
                };
    
                /**
                 * Converts this StreetAddress to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StreetAddress
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StreetAddress.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StreetAddress
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StreetAddress
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StreetAddress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StreetAddress";
                };
    
                return StreetAddress;
            })();
    
            be.FieldMetadata = (function() {
    
                /**
                 * Properties of a FieldMetadata.
                 * @memberof infinitusai.be
                 * @interface IFieldMetadata
                 * @property {infinitusai.be.FieldMetadata.Source|null} [source] FieldMetadata source
                 * @property {string|null} [sourceRecordId] FieldMetadata sourceRecordId
                 * @property {number|Long|null} [startTimeMillis] FieldMetadata startTimeMillis
                 * @property {number|Long|null} [endTimeMillis] FieldMetadata endTimeMillis
                 */
    
                /**
                 * Constructs a new FieldMetadata.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FieldMetadata.
                 * @implements IFieldMetadata
                 * @constructor
                 * @param {infinitusai.be.IFieldMetadata=} [properties] Properties to set
                 */
                function FieldMetadata(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldMetadata source.
                 * @member {infinitusai.be.FieldMetadata.Source} source
                 * @memberof infinitusai.be.FieldMetadata
                 * @instance
                 */
                FieldMetadata.prototype.source = 0;
    
                /**
                 * FieldMetadata sourceRecordId.
                 * @member {string} sourceRecordId
                 * @memberof infinitusai.be.FieldMetadata
                 * @instance
                 */
                FieldMetadata.prototype.sourceRecordId = "";
    
                /**
                 * FieldMetadata startTimeMillis.
                 * @member {number|Long} startTimeMillis
                 * @memberof infinitusai.be.FieldMetadata
                 * @instance
                 */
                FieldMetadata.prototype.startTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * FieldMetadata endTimeMillis.
                 * @member {number|Long} endTimeMillis
                 * @memberof infinitusai.be.FieldMetadata
                 * @instance
                 */
                FieldMetadata.prototype.endTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new FieldMetadata instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {infinitusai.be.IFieldMetadata=} [properties] Properties to set
                 * @returns {infinitusai.be.FieldMetadata} FieldMetadata instance
                 */
                FieldMetadata.create = function create(properties) {
                    return new FieldMetadata(properties);
                };
    
                /**
                 * Encodes the specified FieldMetadata message. Does not implicitly {@link infinitusai.be.FieldMetadata.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {infinitusai.be.IFieldMetadata} message FieldMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldMetadata.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.source);
                    if (message.sourceRecordId != null && Object.hasOwnProperty.call(message, "sourceRecordId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceRecordId);
                    if (message.startTimeMillis != null && Object.hasOwnProperty.call(message, "startTimeMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.startTimeMillis);
                    if (message.endTimeMillis != null && Object.hasOwnProperty.call(message, "endTimeMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.endTimeMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldMetadata message, length delimited. Does not implicitly {@link infinitusai.be.FieldMetadata.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {infinitusai.be.IFieldMetadata} message FieldMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldMetadata message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FieldMetadata} FieldMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldMetadata.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FieldMetadata();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.source = reader.int32();
                                break;
                            }
                        case 2: {
                                message.sourceRecordId = reader.string();
                                break;
                            }
                        case 3: {
                                message.startTimeMillis = reader.int64();
                                break;
                            }
                        case 4: {
                                message.endTimeMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldMetadata message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FieldMetadata} FieldMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldMetadata.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldMetadata message.
                 * @function verify
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldMetadata.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.source != null && message.hasOwnProperty("source"))
                        switch (message.source) {
                        default:
                            return "source: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                            break;
                        }
                    if (message.sourceRecordId != null && message.hasOwnProperty("sourceRecordId"))
                        if (!$util.isString(message.sourceRecordId))
                            return "sourceRecordId: string expected";
                    if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                        if (!$util.isInteger(message.startTimeMillis) && !(message.startTimeMillis && $util.isInteger(message.startTimeMillis.low) && $util.isInteger(message.startTimeMillis.high)))
                            return "startTimeMillis: integer|Long expected";
                    if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                        if (!$util.isInteger(message.endTimeMillis) && !(message.endTimeMillis && $util.isInteger(message.endTimeMillis.low) && $util.isInteger(message.endTimeMillis.high)))
                            return "endTimeMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a FieldMetadata message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FieldMetadata} FieldMetadata
                 */
                FieldMetadata.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FieldMetadata)
                        return object;
                    var message = new $root.infinitusai.be.FieldMetadata();
                    switch (object.source) {
                    default:
                        if (typeof object.source === "number") {
                            message.source = object.source;
                            break;
                        }
                        break;
                    case "SOURCE_UNKNOWN":
                    case 0:
                        message.source = 0;
                        break;
                    case "SOURCE_OPERATOR":
                    case 1:
                        message.source = 1;
                        break;
                    case "SOURCE_270_271":
                    case 2:
                        message.source = 2;
                        break;
                    case "SOURCE_PREVIOUS_CALL":
                    case 3:
                        message.source = 3;
                        break;
                    case "SOURCE_IVR_AUTO_EXTRACT_CONFIDENT":
                    case 4:
                        message.source = 4;
                        break;
                    case "SOURCE_IVR_AUTO_EXTRACT_UNCONFIDENT":
                    case 5:
                        message.source = 5;
                        break;
                    case "SOURCE_AGENT_AUTO_EXTRACT_CONFIDENT":
                    case 6:
                        message.source = 6;
                        break;
                    case "SOURCE_AGENT_AUTO_EXTRACT_UNCONFIDENT":
                    case 7:
                        message.source = 7;
                        break;
                    case "SOURCE_PIE_OVERRIDE":
                    case 8:
                        message.source = 8;
                        break;
                    case "SOURCE_REVIEWER":
                    case 9:
                        message.source = 9;
                        break;
                    case "SOURCE_PREVIOUS_BV":
                    case 10:
                        message.source = 10;
                        break;
                    case "SOURCE_PAYER_API":
                    case 11:
                        message.source = 11;
                        break;
                    case "SOURCE_STAT_PIE":
                    case 12:
                        message.source = 12;
                        break;
                    case "SOURCE_AUTO_FORMATTER":
                    case 13:
                        message.source = 13;
                        break;
                    case "SOURCE_PROCESS_CALL":
                    case 14:
                        message.source = 14;
                        break;
                    case "SOURCE_GENERIC_EBV":
                    case 15:
                        message.source = 15;
                        break;
                    case "SOURCE_MOCK_EBV":
                    case 16:
                        message.source = 16;
                        break;
                    case "SOURCE_INPUTS":
                    case 17:
                        message.source = 17;
                        break;
                    case "SOURCE_CUSTOMER_PIE":
                    case 18:
                        message.source = 18;
                        break;
                    case "SOURCE_KG_POWERED_PIE":
                    case 19:
                        message.source = 19;
                        break;
                    case "SOURCE_MEDICARE":
                    case 20:
                        message.source = 20;
                        break;
                    case "SOURCE_AVAILITY":
                    case 21:
                        message.source = 21;
                        break;
                    case "SOURCE_CMS":
                    case 22:
                        message.source = 22;
                        break;
                    case "SOURCE_BCBS":
                    case 23:
                        message.source = 23;
                        break;
                    case "SOURCE_ARRIVE_HEALTH":
                    case 24:
                        message.source = 24;
                        break;
                    case "SOURCE_KG_INTERNAL":
                    case 25:
                        message.source = 25;
                        break;
                    }
                    if (object.sourceRecordId != null)
                        message.sourceRecordId = String(object.sourceRecordId);
                    if (object.startTimeMillis != null)
                        if ($util.Long)
                            (message.startTimeMillis = $util.Long.fromValue(object.startTimeMillis)).unsigned = false;
                        else if (typeof object.startTimeMillis === "string")
                            message.startTimeMillis = parseInt(object.startTimeMillis, 10);
                        else if (typeof object.startTimeMillis === "number")
                            message.startTimeMillis = object.startTimeMillis;
                        else if (typeof object.startTimeMillis === "object")
                            message.startTimeMillis = new $util.LongBits(object.startTimeMillis.low >>> 0, object.startTimeMillis.high >>> 0).toNumber();
                    if (object.endTimeMillis != null)
                        if ($util.Long)
                            (message.endTimeMillis = $util.Long.fromValue(object.endTimeMillis)).unsigned = false;
                        else if (typeof object.endTimeMillis === "string")
                            message.endTimeMillis = parseInt(object.endTimeMillis, 10);
                        else if (typeof object.endTimeMillis === "number")
                            message.endTimeMillis = object.endTimeMillis;
                        else if (typeof object.endTimeMillis === "object")
                            message.endTimeMillis = new $util.LongBits(object.endTimeMillis.low >>> 0, object.endTimeMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldMetadata message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {infinitusai.be.FieldMetadata} message FieldMetadata
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldMetadata.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.source = options.enums === String ? "SOURCE_UNKNOWN" : 0;
                        object.sourceRecordId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.source != null && message.hasOwnProperty("source"))
                        object.source = options.enums === String ? $root.infinitusai.be.FieldMetadata.Source[message.source] === undefined ? message.source : $root.infinitusai.be.FieldMetadata.Source[message.source] : message.source;
                    if (message.sourceRecordId != null && message.hasOwnProperty("sourceRecordId"))
                        object.sourceRecordId = message.sourceRecordId;
                    if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                        if (typeof message.startTimeMillis === "number")
                            object.startTimeMillis = options.longs === String ? String(message.startTimeMillis) : message.startTimeMillis;
                        else
                            object.startTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMillis) : options.longs === Number ? new $util.LongBits(message.startTimeMillis.low >>> 0, message.startTimeMillis.high >>> 0).toNumber() : message.startTimeMillis;
                    if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                        if (typeof message.endTimeMillis === "number")
                            object.endTimeMillis = options.longs === String ? String(message.endTimeMillis) : message.endTimeMillis;
                        else
                            object.endTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMillis) : options.longs === Number ? new $util.LongBits(message.endTimeMillis.low >>> 0, message.endTimeMillis.high >>> 0).toNumber() : message.endTimeMillis;
                    return object;
                };
    
                /**
                 * Converts this FieldMetadata to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FieldMetadata
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldMetadata.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldMetadata
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FieldMetadata
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FieldMetadata";
                };
    
                /**
                 * Source enum.
                 * @name infinitusai.be.FieldMetadata.Source
                 * @enum {number}
                 * @property {number} SOURCE_UNKNOWN=0 SOURCE_UNKNOWN value
                 * @property {number} SOURCE_OPERATOR=1 SOURCE_OPERATOR value
                 * @property {number} SOURCE_270_271=2 SOURCE_270_271 value
                 * @property {number} SOURCE_PREVIOUS_CALL=3 SOURCE_PREVIOUS_CALL value
                 * @property {number} SOURCE_IVR_AUTO_EXTRACT_CONFIDENT=4 SOURCE_IVR_AUTO_EXTRACT_CONFIDENT value
                 * @property {number} SOURCE_IVR_AUTO_EXTRACT_UNCONFIDENT=5 SOURCE_IVR_AUTO_EXTRACT_UNCONFIDENT value
                 * @property {number} SOURCE_AGENT_AUTO_EXTRACT_CONFIDENT=6 SOURCE_AGENT_AUTO_EXTRACT_CONFIDENT value
                 * @property {number} SOURCE_AGENT_AUTO_EXTRACT_UNCONFIDENT=7 SOURCE_AGENT_AUTO_EXTRACT_UNCONFIDENT value
                 * @property {number} SOURCE_PIE_OVERRIDE=8 SOURCE_PIE_OVERRIDE value
                 * @property {number} SOURCE_REVIEWER=9 SOURCE_REVIEWER value
                 * @property {number} SOURCE_PREVIOUS_BV=10 SOURCE_PREVIOUS_BV value
                 * @property {number} SOURCE_PAYER_API=11 SOURCE_PAYER_API value
                 * @property {number} SOURCE_STAT_PIE=12 SOURCE_STAT_PIE value
                 * @property {number} SOURCE_AUTO_FORMATTER=13 SOURCE_AUTO_FORMATTER value
                 * @property {number} SOURCE_PROCESS_CALL=14 SOURCE_PROCESS_CALL value
                 * @property {number} SOURCE_GENERIC_EBV=15 SOURCE_GENERIC_EBV value
                 * @property {number} SOURCE_MOCK_EBV=16 SOURCE_MOCK_EBV value
                 * @property {number} SOURCE_INPUTS=17 SOURCE_INPUTS value
                 * @property {number} SOURCE_CUSTOMER_PIE=18 SOURCE_CUSTOMER_PIE value
                 * @property {number} SOURCE_KG_POWERED_PIE=19 SOURCE_KG_POWERED_PIE value
                 * @property {number} SOURCE_MEDICARE=20 SOURCE_MEDICARE value
                 * @property {number} SOURCE_AVAILITY=21 SOURCE_AVAILITY value
                 * @property {number} SOURCE_CMS=22 SOURCE_CMS value
                 * @property {number} SOURCE_BCBS=23 SOURCE_BCBS value
                 * @property {number} SOURCE_ARRIVE_HEALTH=24 SOURCE_ARRIVE_HEALTH value
                 * @property {number} SOURCE_KG_INTERNAL=25 SOURCE_KG_INTERNAL value
                 */
                FieldMetadata.Source = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SOURCE_UNKNOWN"] = 0;
                    values[valuesById[1] = "SOURCE_OPERATOR"] = 1;
                    values[valuesById[2] = "SOURCE_270_271"] = 2;
                    values[valuesById[3] = "SOURCE_PREVIOUS_CALL"] = 3;
                    values[valuesById[4] = "SOURCE_IVR_AUTO_EXTRACT_CONFIDENT"] = 4;
                    values[valuesById[5] = "SOURCE_IVR_AUTO_EXTRACT_UNCONFIDENT"] = 5;
                    values[valuesById[6] = "SOURCE_AGENT_AUTO_EXTRACT_CONFIDENT"] = 6;
                    values[valuesById[7] = "SOURCE_AGENT_AUTO_EXTRACT_UNCONFIDENT"] = 7;
                    values[valuesById[8] = "SOURCE_PIE_OVERRIDE"] = 8;
                    values[valuesById[9] = "SOURCE_REVIEWER"] = 9;
                    values[valuesById[10] = "SOURCE_PREVIOUS_BV"] = 10;
                    values[valuesById[11] = "SOURCE_PAYER_API"] = 11;
                    values[valuesById[12] = "SOURCE_STAT_PIE"] = 12;
                    values[valuesById[13] = "SOURCE_AUTO_FORMATTER"] = 13;
                    values[valuesById[14] = "SOURCE_PROCESS_CALL"] = 14;
                    values[valuesById[15] = "SOURCE_GENERIC_EBV"] = 15;
                    values[valuesById[16] = "SOURCE_MOCK_EBV"] = 16;
                    values[valuesById[17] = "SOURCE_INPUTS"] = 17;
                    values[valuesById[18] = "SOURCE_CUSTOMER_PIE"] = 18;
                    values[valuesById[19] = "SOURCE_KG_POWERED_PIE"] = 19;
                    values[valuesById[20] = "SOURCE_MEDICARE"] = 20;
                    values[valuesById[21] = "SOURCE_AVAILITY"] = 21;
                    values[valuesById[22] = "SOURCE_CMS"] = 22;
                    values[valuesById[23] = "SOURCE_BCBS"] = 23;
                    values[valuesById[24] = "SOURCE_ARRIVE_HEALTH"] = 24;
                    values[valuesById[25] = "SOURCE_KG_INTERNAL"] = 25;
                    return values;
                })();
    
                return FieldMetadata;
            })();
    
            be.FieldOverrideEvent = (function() {
    
                /**
                 * Properties of a FieldOverrideEvent.
                 * @memberof infinitusai.be
                 * @interface IFieldOverrideEvent
                 * @property {string|null} [fieldPath] FieldOverrideEvent fieldPath
                 * @property {infinitusai.be.FieldMetadata.Source|null} [source] FieldOverrideEvent source
                 * @property {string|null} [sourceRecordId] FieldOverrideEvent sourceRecordId
                 * @property {infinitusai.be.FieldMetadata.Source|null} [overrideSource] FieldOverrideEvent overrideSource
                 * @property {string|null} [overrideSourceRecordId] FieldOverrideEvent overrideSourceRecordId
                 * @property {string|null} [oldValue] FieldOverrideEvent oldValue
                 * @property {string|null} [newValue] FieldOverrideEvent newValue
                 */
    
                /**
                 * Constructs a new FieldOverrideEvent.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FieldOverrideEvent.
                 * @implements IFieldOverrideEvent
                 * @constructor
                 * @param {infinitusai.be.IFieldOverrideEvent=} [properties] Properties to set
                 */
                function FieldOverrideEvent(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldOverrideEvent fieldPath.
                 * @member {string} fieldPath
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @instance
                 */
                FieldOverrideEvent.prototype.fieldPath = "";
    
                /**
                 * FieldOverrideEvent source.
                 * @member {infinitusai.be.FieldMetadata.Source} source
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @instance
                 */
                FieldOverrideEvent.prototype.source = 0;
    
                /**
                 * FieldOverrideEvent sourceRecordId.
                 * @member {string} sourceRecordId
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @instance
                 */
                FieldOverrideEvent.prototype.sourceRecordId = "";
    
                /**
                 * FieldOverrideEvent overrideSource.
                 * @member {infinitusai.be.FieldMetadata.Source} overrideSource
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @instance
                 */
                FieldOverrideEvent.prototype.overrideSource = 0;
    
                /**
                 * FieldOverrideEvent overrideSourceRecordId.
                 * @member {string} overrideSourceRecordId
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @instance
                 */
                FieldOverrideEvent.prototype.overrideSourceRecordId = "";
    
                /**
                 * FieldOverrideEvent oldValue.
                 * @member {string} oldValue
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @instance
                 */
                FieldOverrideEvent.prototype.oldValue = "";
    
                /**
                 * FieldOverrideEvent newValue.
                 * @member {string} newValue
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @instance
                 */
                FieldOverrideEvent.prototype.newValue = "";
    
                /**
                 * Creates a new FieldOverrideEvent instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {infinitusai.be.IFieldOverrideEvent=} [properties] Properties to set
                 * @returns {infinitusai.be.FieldOverrideEvent} FieldOverrideEvent instance
                 */
                FieldOverrideEvent.create = function create(properties) {
                    return new FieldOverrideEvent(properties);
                };
    
                /**
                 * Encodes the specified FieldOverrideEvent message. Does not implicitly {@link infinitusai.be.FieldOverrideEvent.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {infinitusai.be.IFieldOverrideEvent} message FieldOverrideEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOverrideEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fieldPath != null && Object.hasOwnProperty.call(message, "fieldPath"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.fieldPath);
                    if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.source);
                    if (message.sourceRecordId != null && Object.hasOwnProperty.call(message, "sourceRecordId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.sourceRecordId);
                    if (message.overrideSource != null && Object.hasOwnProperty.call(message, "overrideSource"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.overrideSource);
                    if (message.overrideSourceRecordId != null && Object.hasOwnProperty.call(message, "overrideSourceRecordId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.overrideSourceRecordId);
                    if (message.oldValue != null && Object.hasOwnProperty.call(message, "oldValue"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.oldValue);
                    if (message.newValue != null && Object.hasOwnProperty.call(message, "newValue"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.newValue);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldOverrideEvent message, length delimited. Does not implicitly {@link infinitusai.be.FieldOverrideEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {infinitusai.be.IFieldOverrideEvent} message FieldOverrideEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOverrideEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldOverrideEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FieldOverrideEvent} FieldOverrideEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOverrideEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FieldOverrideEvent();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3: {
                                message.fieldPath = reader.string();
                                break;
                            }
                        case 4: {
                                message.source = reader.int32();
                                break;
                            }
                        case 5: {
                                message.sourceRecordId = reader.string();
                                break;
                            }
                        case 6: {
                                message.overrideSource = reader.int32();
                                break;
                            }
                        case 7: {
                                message.overrideSourceRecordId = reader.string();
                                break;
                            }
                        case 8: {
                                message.oldValue = reader.string();
                                break;
                            }
                        case 9: {
                                message.newValue = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldOverrideEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FieldOverrideEvent} FieldOverrideEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOverrideEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldOverrideEvent message.
                 * @function verify
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldOverrideEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                        if (!$util.isString(message.fieldPath))
                            return "fieldPath: string expected";
                    if (message.source != null && message.hasOwnProperty("source"))
                        switch (message.source) {
                        default:
                            return "source: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                            break;
                        }
                    if (message.sourceRecordId != null && message.hasOwnProperty("sourceRecordId"))
                        if (!$util.isString(message.sourceRecordId))
                            return "sourceRecordId: string expected";
                    if (message.overrideSource != null && message.hasOwnProperty("overrideSource"))
                        switch (message.overrideSource) {
                        default:
                            return "overrideSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                            break;
                        }
                    if (message.overrideSourceRecordId != null && message.hasOwnProperty("overrideSourceRecordId"))
                        if (!$util.isString(message.overrideSourceRecordId))
                            return "overrideSourceRecordId: string expected";
                    if (message.oldValue != null && message.hasOwnProperty("oldValue"))
                        if (!$util.isString(message.oldValue))
                            return "oldValue: string expected";
                    if (message.newValue != null && message.hasOwnProperty("newValue"))
                        if (!$util.isString(message.newValue))
                            return "newValue: string expected";
                    return null;
                };
    
                /**
                 * Creates a FieldOverrideEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FieldOverrideEvent} FieldOverrideEvent
                 */
                FieldOverrideEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FieldOverrideEvent)
                        return object;
                    var message = new $root.infinitusai.be.FieldOverrideEvent();
                    if (object.fieldPath != null)
                        message.fieldPath = String(object.fieldPath);
                    switch (object.source) {
                    default:
                        if (typeof object.source === "number") {
                            message.source = object.source;
                            break;
                        }
                        break;
                    case "SOURCE_UNKNOWN":
                    case 0:
                        message.source = 0;
                        break;
                    case "SOURCE_OPERATOR":
                    case 1:
                        message.source = 1;
                        break;
                    case "SOURCE_270_271":
                    case 2:
                        message.source = 2;
                        break;
                    case "SOURCE_PREVIOUS_CALL":
                    case 3:
                        message.source = 3;
                        break;
                    case "SOURCE_IVR_AUTO_EXTRACT_CONFIDENT":
                    case 4:
                        message.source = 4;
                        break;
                    case "SOURCE_IVR_AUTO_EXTRACT_UNCONFIDENT":
                    case 5:
                        message.source = 5;
                        break;
                    case "SOURCE_AGENT_AUTO_EXTRACT_CONFIDENT":
                    case 6:
                        message.source = 6;
                        break;
                    case "SOURCE_AGENT_AUTO_EXTRACT_UNCONFIDENT":
                    case 7:
                        message.source = 7;
                        break;
                    case "SOURCE_PIE_OVERRIDE":
                    case 8:
                        message.source = 8;
                        break;
                    case "SOURCE_REVIEWER":
                    case 9:
                        message.source = 9;
                        break;
                    case "SOURCE_PREVIOUS_BV":
                    case 10:
                        message.source = 10;
                        break;
                    case "SOURCE_PAYER_API":
                    case 11:
                        message.source = 11;
                        break;
                    case "SOURCE_STAT_PIE":
                    case 12:
                        message.source = 12;
                        break;
                    case "SOURCE_AUTO_FORMATTER":
                    case 13:
                        message.source = 13;
                        break;
                    case "SOURCE_PROCESS_CALL":
                    case 14:
                        message.source = 14;
                        break;
                    case "SOURCE_GENERIC_EBV":
                    case 15:
                        message.source = 15;
                        break;
                    case "SOURCE_MOCK_EBV":
                    case 16:
                        message.source = 16;
                        break;
                    case "SOURCE_INPUTS":
                    case 17:
                        message.source = 17;
                        break;
                    case "SOURCE_CUSTOMER_PIE":
                    case 18:
                        message.source = 18;
                        break;
                    case "SOURCE_KG_POWERED_PIE":
                    case 19:
                        message.source = 19;
                        break;
                    case "SOURCE_MEDICARE":
                    case 20:
                        message.source = 20;
                        break;
                    case "SOURCE_AVAILITY":
                    case 21:
                        message.source = 21;
                        break;
                    case "SOURCE_CMS":
                    case 22:
                        message.source = 22;
                        break;
                    case "SOURCE_BCBS":
                    case 23:
                        message.source = 23;
                        break;
                    case "SOURCE_ARRIVE_HEALTH":
                    case 24:
                        message.source = 24;
                        break;
                    case "SOURCE_KG_INTERNAL":
                    case 25:
                        message.source = 25;
                        break;
                    }
                    if (object.sourceRecordId != null)
                        message.sourceRecordId = String(object.sourceRecordId);
                    switch (object.overrideSource) {
                    default:
                        if (typeof object.overrideSource === "number") {
                            message.overrideSource = object.overrideSource;
                            break;
                        }
                        break;
                    case "SOURCE_UNKNOWN":
                    case 0:
                        message.overrideSource = 0;
                        break;
                    case "SOURCE_OPERATOR":
                    case 1:
                        message.overrideSource = 1;
                        break;
                    case "SOURCE_270_271":
                    case 2:
                        message.overrideSource = 2;
                        break;
                    case "SOURCE_PREVIOUS_CALL":
                    case 3:
                        message.overrideSource = 3;
                        break;
                    case "SOURCE_IVR_AUTO_EXTRACT_CONFIDENT":
                    case 4:
                        message.overrideSource = 4;
                        break;
                    case "SOURCE_IVR_AUTO_EXTRACT_UNCONFIDENT":
                    case 5:
                        message.overrideSource = 5;
                        break;
                    case "SOURCE_AGENT_AUTO_EXTRACT_CONFIDENT":
                    case 6:
                        message.overrideSource = 6;
                        break;
                    case "SOURCE_AGENT_AUTO_EXTRACT_UNCONFIDENT":
                    case 7:
                        message.overrideSource = 7;
                        break;
                    case "SOURCE_PIE_OVERRIDE":
                    case 8:
                        message.overrideSource = 8;
                        break;
                    case "SOURCE_REVIEWER":
                    case 9:
                        message.overrideSource = 9;
                        break;
                    case "SOURCE_PREVIOUS_BV":
                    case 10:
                        message.overrideSource = 10;
                        break;
                    case "SOURCE_PAYER_API":
                    case 11:
                        message.overrideSource = 11;
                        break;
                    case "SOURCE_STAT_PIE":
                    case 12:
                        message.overrideSource = 12;
                        break;
                    case "SOURCE_AUTO_FORMATTER":
                    case 13:
                        message.overrideSource = 13;
                        break;
                    case "SOURCE_PROCESS_CALL":
                    case 14:
                        message.overrideSource = 14;
                        break;
                    case "SOURCE_GENERIC_EBV":
                    case 15:
                        message.overrideSource = 15;
                        break;
                    case "SOURCE_MOCK_EBV":
                    case 16:
                        message.overrideSource = 16;
                        break;
                    case "SOURCE_INPUTS":
                    case 17:
                        message.overrideSource = 17;
                        break;
                    case "SOURCE_CUSTOMER_PIE":
                    case 18:
                        message.overrideSource = 18;
                        break;
                    case "SOURCE_KG_POWERED_PIE":
                    case 19:
                        message.overrideSource = 19;
                        break;
                    case "SOURCE_MEDICARE":
                    case 20:
                        message.overrideSource = 20;
                        break;
                    case "SOURCE_AVAILITY":
                    case 21:
                        message.overrideSource = 21;
                        break;
                    case "SOURCE_CMS":
                    case 22:
                        message.overrideSource = 22;
                        break;
                    case "SOURCE_BCBS":
                    case 23:
                        message.overrideSource = 23;
                        break;
                    case "SOURCE_ARRIVE_HEALTH":
                    case 24:
                        message.overrideSource = 24;
                        break;
                    case "SOURCE_KG_INTERNAL":
                    case 25:
                        message.overrideSource = 25;
                        break;
                    }
                    if (object.overrideSourceRecordId != null)
                        message.overrideSourceRecordId = String(object.overrideSourceRecordId);
                    if (object.oldValue != null)
                        message.oldValue = String(object.oldValue);
                    if (object.newValue != null)
                        message.newValue = String(object.newValue);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldOverrideEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {infinitusai.be.FieldOverrideEvent} message FieldOverrideEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldOverrideEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.fieldPath = "";
                        object.source = options.enums === String ? "SOURCE_UNKNOWN" : 0;
                        object.sourceRecordId = "";
                        object.overrideSource = options.enums === String ? "SOURCE_UNKNOWN" : 0;
                        object.overrideSourceRecordId = "";
                        object.oldValue = "";
                        object.newValue = "";
                    }
                    if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                        object.fieldPath = message.fieldPath;
                    if (message.source != null && message.hasOwnProperty("source"))
                        object.source = options.enums === String ? $root.infinitusai.be.FieldMetadata.Source[message.source] === undefined ? message.source : $root.infinitusai.be.FieldMetadata.Source[message.source] : message.source;
                    if (message.sourceRecordId != null && message.hasOwnProperty("sourceRecordId"))
                        object.sourceRecordId = message.sourceRecordId;
                    if (message.overrideSource != null && message.hasOwnProperty("overrideSource"))
                        object.overrideSource = options.enums === String ? $root.infinitusai.be.FieldMetadata.Source[message.overrideSource] === undefined ? message.overrideSource : $root.infinitusai.be.FieldMetadata.Source[message.overrideSource] : message.overrideSource;
                    if (message.overrideSourceRecordId != null && message.hasOwnProperty("overrideSourceRecordId"))
                        object.overrideSourceRecordId = message.overrideSourceRecordId;
                    if (message.oldValue != null && message.hasOwnProperty("oldValue"))
                        object.oldValue = message.oldValue;
                    if (message.newValue != null && message.hasOwnProperty("newValue"))
                        object.newValue = message.newValue;
                    return object;
                };
    
                /**
                 * Converts this FieldOverrideEvent to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldOverrideEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldOverrideEvent
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FieldOverrideEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldOverrideEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FieldOverrideEvent";
                };
    
                return FieldOverrideEvent;
            })();
    
            be.LogFieldOverrideEventsRequest = (function() {
    
                /**
                 * Properties of a LogFieldOverrideEventsRequest.
                 * @memberof infinitusai.be
                 * @interface ILogFieldOverrideEventsRequest
                 * @property {string|null} [taskUuid] LogFieldOverrideEventsRequest taskUuid
                 * @property {string|null} [callUuid] LogFieldOverrideEventsRequest callUuid
                 * @property {Array.<infinitusai.be.IFieldOverrideEvent>|null} [fieldOverrideEvents] LogFieldOverrideEventsRequest fieldOverrideEvents
                 */
    
                /**
                 * Constructs a new LogFieldOverrideEventsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LogFieldOverrideEventsRequest.
                 * @implements ILogFieldOverrideEventsRequest
                 * @constructor
                 * @param {infinitusai.be.ILogFieldOverrideEventsRequest=} [properties] Properties to set
                 */
                function LogFieldOverrideEventsRequest(properties) {
                    this.fieldOverrideEvents = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LogFieldOverrideEventsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @instance
                 */
                LogFieldOverrideEventsRequest.prototype.taskUuid = "";
    
                /**
                 * LogFieldOverrideEventsRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @instance
                 */
                LogFieldOverrideEventsRequest.prototype.callUuid = "";
    
                /**
                 * LogFieldOverrideEventsRequest fieldOverrideEvents.
                 * @member {Array.<infinitusai.be.IFieldOverrideEvent>} fieldOverrideEvents
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @instance
                 */
                LogFieldOverrideEventsRequest.prototype.fieldOverrideEvents = $util.emptyArray;
    
                /**
                 * Creates a new LogFieldOverrideEventsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {infinitusai.be.ILogFieldOverrideEventsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.LogFieldOverrideEventsRequest} LogFieldOverrideEventsRequest instance
                 */
                LogFieldOverrideEventsRequest.create = function create(properties) {
                    return new LogFieldOverrideEventsRequest(properties);
                };
    
                /**
                 * Encodes the specified LogFieldOverrideEventsRequest message. Does not implicitly {@link infinitusai.be.LogFieldOverrideEventsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {infinitusai.be.ILogFieldOverrideEventsRequest} message LogFieldOverrideEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogFieldOverrideEventsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.fieldOverrideEvents != null && message.fieldOverrideEvents.length)
                        for (var i = 0; i < message.fieldOverrideEvents.length; ++i)
                            $root.infinitusai.be.FieldOverrideEvent.encode(message.fieldOverrideEvents[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified LogFieldOverrideEventsRequest message, length delimited. Does not implicitly {@link infinitusai.be.LogFieldOverrideEventsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {infinitusai.be.ILogFieldOverrideEventsRequest} message LogFieldOverrideEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogFieldOverrideEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LogFieldOverrideEventsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LogFieldOverrideEventsRequest} LogFieldOverrideEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogFieldOverrideEventsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LogFieldOverrideEventsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fieldOverrideEvents && message.fieldOverrideEvents.length))
                                    message.fieldOverrideEvents = [];
                                message.fieldOverrideEvents.push($root.infinitusai.be.FieldOverrideEvent.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LogFieldOverrideEventsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LogFieldOverrideEventsRequest} LogFieldOverrideEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogFieldOverrideEventsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LogFieldOverrideEventsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogFieldOverrideEventsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.fieldOverrideEvents != null && message.hasOwnProperty("fieldOverrideEvents")) {
                        if (!Array.isArray(message.fieldOverrideEvents))
                            return "fieldOverrideEvents: array expected";
                        for (var i = 0; i < message.fieldOverrideEvents.length; ++i) {
                            var error = $root.infinitusai.be.FieldOverrideEvent.verify(message.fieldOverrideEvents[i]);
                            if (error)
                                return "fieldOverrideEvents." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a LogFieldOverrideEventsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LogFieldOverrideEventsRequest} LogFieldOverrideEventsRequest
                 */
                LogFieldOverrideEventsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LogFieldOverrideEventsRequest)
                        return object;
                    var message = new $root.infinitusai.be.LogFieldOverrideEventsRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.fieldOverrideEvents) {
                        if (!Array.isArray(object.fieldOverrideEvents))
                            throw TypeError(".infinitusai.be.LogFieldOverrideEventsRequest.fieldOverrideEvents: array expected");
                        message.fieldOverrideEvents = [];
                        for (var i = 0; i < object.fieldOverrideEvents.length; ++i) {
                            if (typeof object.fieldOverrideEvents[i] !== "object")
                                throw TypeError(".infinitusai.be.LogFieldOverrideEventsRequest.fieldOverrideEvents: object expected");
                            message.fieldOverrideEvents[i] = $root.infinitusai.be.FieldOverrideEvent.fromObject(object.fieldOverrideEvents[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a LogFieldOverrideEventsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {infinitusai.be.LogFieldOverrideEventsRequest} message LogFieldOverrideEventsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogFieldOverrideEventsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldOverrideEvents = [];
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.fieldOverrideEvents && message.fieldOverrideEvents.length) {
                        object.fieldOverrideEvents = [];
                        for (var j = 0; j < message.fieldOverrideEvents.length; ++j)
                            object.fieldOverrideEvents[j] = $root.infinitusai.be.FieldOverrideEvent.toObject(message.fieldOverrideEvents[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this LogFieldOverrideEventsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogFieldOverrideEventsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LogFieldOverrideEventsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LogFieldOverrideEventsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LogFieldOverrideEventsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LogFieldOverrideEventsRequest";
                };
    
                return LogFieldOverrideEventsRequest;
            })();
    
            be.LogFieldOverrideEventsResponse = (function() {
    
                /**
                 * Properties of a LogFieldOverrideEventsResponse.
                 * @memberof infinitusai.be
                 * @interface ILogFieldOverrideEventsResponse
                 */
    
                /**
                 * Constructs a new LogFieldOverrideEventsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LogFieldOverrideEventsResponse.
                 * @implements ILogFieldOverrideEventsResponse
                 * @constructor
                 * @param {infinitusai.be.ILogFieldOverrideEventsResponse=} [properties] Properties to set
                 */
                function LogFieldOverrideEventsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new LogFieldOverrideEventsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {infinitusai.be.ILogFieldOverrideEventsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.LogFieldOverrideEventsResponse} LogFieldOverrideEventsResponse instance
                 */
                LogFieldOverrideEventsResponse.create = function create(properties) {
                    return new LogFieldOverrideEventsResponse(properties);
                };
    
                /**
                 * Encodes the specified LogFieldOverrideEventsResponse message. Does not implicitly {@link infinitusai.be.LogFieldOverrideEventsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {infinitusai.be.ILogFieldOverrideEventsResponse} message LogFieldOverrideEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogFieldOverrideEventsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified LogFieldOverrideEventsResponse message, length delimited. Does not implicitly {@link infinitusai.be.LogFieldOverrideEventsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {infinitusai.be.ILogFieldOverrideEventsResponse} message LogFieldOverrideEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogFieldOverrideEventsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LogFieldOverrideEventsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LogFieldOverrideEventsResponse} LogFieldOverrideEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogFieldOverrideEventsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LogFieldOverrideEventsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LogFieldOverrideEventsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LogFieldOverrideEventsResponse} LogFieldOverrideEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogFieldOverrideEventsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LogFieldOverrideEventsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogFieldOverrideEventsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a LogFieldOverrideEventsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LogFieldOverrideEventsResponse} LogFieldOverrideEventsResponse
                 */
                LogFieldOverrideEventsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LogFieldOverrideEventsResponse)
                        return object;
                    return new $root.infinitusai.be.LogFieldOverrideEventsResponse();
                };
    
                /**
                 * Creates a plain object from a LogFieldOverrideEventsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {infinitusai.be.LogFieldOverrideEventsResponse} message LogFieldOverrideEventsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogFieldOverrideEventsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this LogFieldOverrideEventsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogFieldOverrideEventsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LogFieldOverrideEventsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LogFieldOverrideEventsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LogFieldOverrideEventsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LogFieldOverrideEventsResponse";
                };
    
                return LogFieldOverrideEventsResponse;
            })();
    
            be.AnnualReenrollmentDoc = (function() {
    
                /**
                 * Properties of an AnnualReenrollmentDoc.
                 * @memberof infinitusai.be
                 * @interface IAnnualReenrollmentDoc
                 * @property {Array.<string>|null} [customerIds] AnnualReenrollmentDoc customerIds
                 * @property {infinitusai.be.IDate|null} [blizzardEndDate] AnnualReenrollmentDoc blizzardEndDate
                 */
    
                /**
                 * Constructs a new AnnualReenrollmentDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AnnualReenrollmentDoc.
                 * @implements IAnnualReenrollmentDoc
                 * @constructor
                 * @param {infinitusai.be.IAnnualReenrollmentDoc=} [properties] Properties to set
                 */
                function AnnualReenrollmentDoc(properties) {
                    this.customerIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AnnualReenrollmentDoc customerIds.
                 * @member {Array.<string>} customerIds
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @instance
                 */
                AnnualReenrollmentDoc.prototype.customerIds = $util.emptyArray;
    
                /**
                 * AnnualReenrollmentDoc blizzardEndDate.
                 * @member {infinitusai.be.IDate|null|undefined} blizzardEndDate
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @instance
                 */
                AnnualReenrollmentDoc.prototype.blizzardEndDate = null;
    
                /**
                 * Creates a new AnnualReenrollmentDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {infinitusai.be.IAnnualReenrollmentDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.AnnualReenrollmentDoc} AnnualReenrollmentDoc instance
                 */
                AnnualReenrollmentDoc.create = function create(properties) {
                    return new AnnualReenrollmentDoc(properties);
                };
    
                /**
                 * Encodes the specified AnnualReenrollmentDoc message. Does not implicitly {@link infinitusai.be.AnnualReenrollmentDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {infinitusai.be.IAnnualReenrollmentDoc} message AnnualReenrollmentDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AnnualReenrollmentDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.customerIds != null && message.customerIds.length)
                        for (var i = 0; i < message.customerIds.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerIds[i]);
                    if (message.blizzardEndDate != null && Object.hasOwnProperty.call(message, "blizzardEndDate"))
                        $root.infinitusai.be.Date.encode(message.blizzardEndDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AnnualReenrollmentDoc message, length delimited. Does not implicitly {@link infinitusai.be.AnnualReenrollmentDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {infinitusai.be.IAnnualReenrollmentDoc} message AnnualReenrollmentDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AnnualReenrollmentDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AnnualReenrollmentDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AnnualReenrollmentDoc} AnnualReenrollmentDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AnnualReenrollmentDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AnnualReenrollmentDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.customerIds && message.customerIds.length))
                                    message.customerIds = [];
                                message.customerIds.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.blizzardEndDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AnnualReenrollmentDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AnnualReenrollmentDoc} AnnualReenrollmentDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AnnualReenrollmentDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AnnualReenrollmentDoc message.
                 * @function verify
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AnnualReenrollmentDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.customerIds != null && message.hasOwnProperty("customerIds")) {
                        if (!Array.isArray(message.customerIds))
                            return "customerIds: array expected";
                        for (var i = 0; i < message.customerIds.length; ++i)
                            if (!$util.isString(message.customerIds[i]))
                                return "customerIds: string[] expected";
                    }
                    if (message.blizzardEndDate != null && message.hasOwnProperty("blizzardEndDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.blizzardEndDate);
                        if (error)
                            return "blizzardEndDate." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an AnnualReenrollmentDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AnnualReenrollmentDoc} AnnualReenrollmentDoc
                 */
                AnnualReenrollmentDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AnnualReenrollmentDoc)
                        return object;
                    var message = new $root.infinitusai.be.AnnualReenrollmentDoc();
                    if (object.customerIds) {
                        if (!Array.isArray(object.customerIds))
                            throw TypeError(".infinitusai.be.AnnualReenrollmentDoc.customerIds: array expected");
                        message.customerIds = [];
                        for (var i = 0; i < object.customerIds.length; ++i)
                            message.customerIds[i] = String(object.customerIds[i]);
                    }
                    if (object.blizzardEndDate != null) {
                        if (typeof object.blizzardEndDate !== "object")
                            throw TypeError(".infinitusai.be.AnnualReenrollmentDoc.blizzardEndDate: object expected");
                        message.blizzardEndDate = $root.infinitusai.be.Date.fromObject(object.blizzardEndDate);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AnnualReenrollmentDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {infinitusai.be.AnnualReenrollmentDoc} message AnnualReenrollmentDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AnnualReenrollmentDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.customerIds = [];
                    if (options.defaults)
                        object.blizzardEndDate = null;
                    if (message.customerIds && message.customerIds.length) {
                        object.customerIds = [];
                        for (var j = 0; j < message.customerIds.length; ++j)
                            object.customerIds[j] = message.customerIds[j];
                    }
                    if (message.blizzardEndDate != null && message.hasOwnProperty("blizzardEndDate"))
                        object.blizzardEndDate = $root.infinitusai.be.Date.toObject(message.blizzardEndDate, options);
                    return object;
                };
    
                /**
                 * Converts this AnnualReenrollmentDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AnnualReenrollmentDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AnnualReenrollmentDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AnnualReenrollmentDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AnnualReenrollmentDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AnnualReenrollmentDoc";
                };
    
                return AnnualReenrollmentDoc;
            })();
    
            /**
             * ApiKeyState enum.
             * @name infinitusai.be.ApiKeyState
             * @enum {number}
             * @property {number} API_KEY_STATE_UNKNOWN=0 API_KEY_STATE_UNKNOWN value
             * @property {number} API_KEY_STATE_ACTIVE=1 API_KEY_STATE_ACTIVE value
             * @property {number} API_KEY_STATE_INACTIVE=2 API_KEY_STATE_INACTIVE value
             */
            be.ApiKeyState = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "API_KEY_STATE_UNKNOWN"] = 0;
                values[valuesById[1] = "API_KEY_STATE_ACTIVE"] = 1;
                values[valuesById[2] = "API_KEY_STATE_INACTIVE"] = 2;
                return values;
            })();
    
            be.ApiKeyDoc = (function() {
    
                /**
                 * Properties of an ApiKeyDoc.
                 * @memberof infinitusai.be
                 * @interface IApiKeyDoc
                 * @property {string|null} [keyId] ApiKeyDoc keyId
                 * @property {string|null} [keyName] ApiKeyDoc keyName
                 * @property {number|Long|null} [generatedAtMillis] ApiKeyDoc generatedAtMillis
                 * @property {infinitusai.be.ApiKeyState|null} [keyState] ApiKeyDoc keyState
                 * @property {number|Long|null} [modifiedAtMillis] ApiKeyDoc modifiedAtMillis
                 */
    
                /**
                 * Constructs a new ApiKeyDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ApiKeyDoc.
                 * @implements IApiKeyDoc
                 * @constructor
                 * @param {infinitusai.be.IApiKeyDoc=} [properties] Properties to set
                 */
                function ApiKeyDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ApiKeyDoc keyId.
                 * @member {string} keyId
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @instance
                 */
                ApiKeyDoc.prototype.keyId = "";
    
                /**
                 * ApiKeyDoc keyName.
                 * @member {string} keyName
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @instance
                 */
                ApiKeyDoc.prototype.keyName = "";
    
                /**
                 * ApiKeyDoc generatedAtMillis.
                 * @member {number|Long} generatedAtMillis
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @instance
                 */
                ApiKeyDoc.prototype.generatedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ApiKeyDoc keyState.
                 * @member {infinitusai.be.ApiKeyState} keyState
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @instance
                 */
                ApiKeyDoc.prototype.keyState = 0;
    
                /**
                 * ApiKeyDoc modifiedAtMillis.
                 * @member {number|Long} modifiedAtMillis
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @instance
                 */
                ApiKeyDoc.prototype.modifiedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new ApiKeyDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {infinitusai.be.IApiKeyDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.ApiKeyDoc} ApiKeyDoc instance
                 */
                ApiKeyDoc.create = function create(properties) {
                    return new ApiKeyDoc(properties);
                };
    
                /**
                 * Encodes the specified ApiKeyDoc message. Does not implicitly {@link infinitusai.be.ApiKeyDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {infinitusai.be.IApiKeyDoc} message ApiKeyDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ApiKeyDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.keyId != null && Object.hasOwnProperty.call(message, "keyId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.keyId);
                    if (message.keyName != null && Object.hasOwnProperty.call(message, "keyName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.keyName);
                    if (message.generatedAtMillis != null && Object.hasOwnProperty.call(message, "generatedAtMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.generatedAtMillis);
                    if (message.keyState != null && Object.hasOwnProperty.call(message, "keyState"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.keyState);
                    if (message.modifiedAtMillis != null && Object.hasOwnProperty.call(message, "modifiedAtMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.modifiedAtMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified ApiKeyDoc message, length delimited. Does not implicitly {@link infinitusai.be.ApiKeyDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {infinitusai.be.IApiKeyDoc} message ApiKeyDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ApiKeyDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ApiKeyDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ApiKeyDoc} ApiKeyDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ApiKeyDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ApiKeyDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.keyId = reader.string();
                                break;
                            }
                        case 2: {
                                message.keyName = reader.string();
                                break;
                            }
                        case 3: {
                                message.generatedAtMillis = reader.int64();
                                break;
                            }
                        case 4: {
                                message.keyState = reader.int32();
                                break;
                            }
                        case 5: {
                                message.modifiedAtMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ApiKeyDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ApiKeyDoc} ApiKeyDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ApiKeyDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ApiKeyDoc message.
                 * @function verify
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApiKeyDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keyId != null && message.hasOwnProperty("keyId"))
                        if (!$util.isString(message.keyId))
                            return "keyId: string expected";
                    if (message.keyName != null && message.hasOwnProperty("keyName"))
                        if (!$util.isString(message.keyName))
                            return "keyName: string expected";
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (!$util.isInteger(message.generatedAtMillis) && !(message.generatedAtMillis && $util.isInteger(message.generatedAtMillis.low) && $util.isInteger(message.generatedAtMillis.high)))
                            return "generatedAtMillis: integer|Long expected";
                    if (message.keyState != null && message.hasOwnProperty("keyState"))
                        switch (message.keyState) {
                        default:
                            return "keyState: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.modifiedAtMillis != null && message.hasOwnProperty("modifiedAtMillis"))
                        if (!$util.isInteger(message.modifiedAtMillis) && !(message.modifiedAtMillis && $util.isInteger(message.modifiedAtMillis.low) && $util.isInteger(message.modifiedAtMillis.high)))
                            return "modifiedAtMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an ApiKeyDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ApiKeyDoc} ApiKeyDoc
                 */
                ApiKeyDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ApiKeyDoc)
                        return object;
                    var message = new $root.infinitusai.be.ApiKeyDoc();
                    if (object.keyId != null)
                        message.keyId = String(object.keyId);
                    if (object.keyName != null)
                        message.keyName = String(object.keyName);
                    if (object.generatedAtMillis != null)
                        if ($util.Long)
                            (message.generatedAtMillis = $util.Long.fromValue(object.generatedAtMillis)).unsigned = false;
                        else if (typeof object.generatedAtMillis === "string")
                            message.generatedAtMillis = parseInt(object.generatedAtMillis, 10);
                        else if (typeof object.generatedAtMillis === "number")
                            message.generatedAtMillis = object.generatedAtMillis;
                        else if (typeof object.generatedAtMillis === "object")
                            message.generatedAtMillis = new $util.LongBits(object.generatedAtMillis.low >>> 0, object.generatedAtMillis.high >>> 0).toNumber();
                    switch (object.keyState) {
                    default:
                        if (typeof object.keyState === "number") {
                            message.keyState = object.keyState;
                            break;
                        }
                        break;
                    case "API_KEY_STATE_UNKNOWN":
                    case 0:
                        message.keyState = 0;
                        break;
                    case "API_KEY_STATE_ACTIVE":
                    case 1:
                        message.keyState = 1;
                        break;
                    case "API_KEY_STATE_INACTIVE":
                    case 2:
                        message.keyState = 2;
                        break;
                    }
                    if (object.modifiedAtMillis != null)
                        if ($util.Long)
                            (message.modifiedAtMillis = $util.Long.fromValue(object.modifiedAtMillis)).unsigned = false;
                        else if (typeof object.modifiedAtMillis === "string")
                            message.modifiedAtMillis = parseInt(object.modifiedAtMillis, 10);
                        else if (typeof object.modifiedAtMillis === "number")
                            message.modifiedAtMillis = object.modifiedAtMillis;
                        else if (typeof object.modifiedAtMillis === "object")
                            message.modifiedAtMillis = new $util.LongBits(object.modifiedAtMillis.low >>> 0, object.modifiedAtMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an ApiKeyDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {infinitusai.be.ApiKeyDoc} message ApiKeyDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApiKeyDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.keyId = "";
                        object.keyName = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedAtMillis = options.longs === String ? "0" : 0;
                        object.keyState = options.enums === String ? "API_KEY_STATE_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.modifiedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.modifiedAtMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.keyId != null && message.hasOwnProperty("keyId"))
                        object.keyId = message.keyId;
                    if (message.keyName != null && message.hasOwnProperty("keyName"))
                        object.keyName = message.keyName;
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (typeof message.generatedAtMillis === "number")
                            object.generatedAtMillis = options.longs === String ? String(message.generatedAtMillis) : message.generatedAtMillis;
                        else
                            object.generatedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.generatedAtMillis) : options.longs === Number ? new $util.LongBits(message.generatedAtMillis.low >>> 0, message.generatedAtMillis.high >>> 0).toNumber() : message.generatedAtMillis;
                    if (message.keyState != null && message.hasOwnProperty("keyState"))
                        object.keyState = options.enums === String ? $root.infinitusai.be.ApiKeyState[message.keyState] === undefined ? message.keyState : $root.infinitusai.be.ApiKeyState[message.keyState] : message.keyState;
                    if (message.modifiedAtMillis != null && message.hasOwnProperty("modifiedAtMillis"))
                        if (typeof message.modifiedAtMillis === "number")
                            object.modifiedAtMillis = options.longs === String ? String(message.modifiedAtMillis) : message.modifiedAtMillis;
                        else
                            object.modifiedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.modifiedAtMillis) : options.longs === Number ? new $util.LongBits(message.modifiedAtMillis.low >>> 0, message.modifiedAtMillis.high >>> 0).toNumber() : message.modifiedAtMillis;
                    return object;
                };
    
                /**
                 * Converts this ApiKeyDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApiKeyDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ApiKeyDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ApiKeyDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ApiKeyDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ApiKeyDoc";
                };
    
                return ApiKeyDoc;
            })();
    
            be.GetAPIKeysRequest = (function() {
    
                /**
                 * Properties of a GetAPIKeysRequest.
                 * @memberof infinitusai.be
                 * @interface IGetAPIKeysRequest
                 */
    
                /**
                 * Constructs a new GetAPIKeysRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAPIKeysRequest.
                 * @implements IGetAPIKeysRequest
                 * @constructor
                 * @param {infinitusai.be.IGetAPIKeysRequest=} [properties] Properties to set
                 */
                function GetAPIKeysRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetAPIKeysRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {infinitusai.be.IGetAPIKeysRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAPIKeysRequest} GetAPIKeysRequest instance
                 */
                GetAPIKeysRequest.create = function create(properties) {
                    return new GetAPIKeysRequest(properties);
                };
    
                /**
                 * Encodes the specified GetAPIKeysRequest message. Does not implicitly {@link infinitusai.be.GetAPIKeysRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {infinitusai.be.IGetAPIKeysRequest} message GetAPIKeysRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAPIKeysRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAPIKeysRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetAPIKeysRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {infinitusai.be.IGetAPIKeysRequest} message GetAPIKeysRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAPIKeysRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAPIKeysRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAPIKeysRequest} GetAPIKeysRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAPIKeysRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAPIKeysRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAPIKeysRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAPIKeysRequest} GetAPIKeysRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAPIKeysRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAPIKeysRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAPIKeysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetAPIKeysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAPIKeysRequest} GetAPIKeysRequest
                 */
                GetAPIKeysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAPIKeysRequest)
                        return object;
                    return new $root.infinitusai.be.GetAPIKeysRequest();
                };
    
                /**
                 * Creates a plain object from a GetAPIKeysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {infinitusai.be.GetAPIKeysRequest} message GetAPIKeysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAPIKeysRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetAPIKeysRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAPIKeysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAPIKeysRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAPIKeysRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAPIKeysRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAPIKeysRequest";
                };
    
                return GetAPIKeysRequest;
            })();
    
            be.GetAPIKeysResponse = (function() {
    
                /**
                 * Properties of a GetAPIKeysResponse.
                 * @memberof infinitusai.be
                 * @interface IGetAPIKeysResponse
                 * @property {Array.<infinitusai.be.IApiKeyDoc>|null} [keys] GetAPIKeysResponse keys
                 */
    
                /**
                 * Constructs a new GetAPIKeysResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAPIKeysResponse.
                 * @implements IGetAPIKeysResponse
                 * @constructor
                 * @param {infinitusai.be.IGetAPIKeysResponse=} [properties] Properties to set
                 */
                function GetAPIKeysResponse(properties) {
                    this.keys = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAPIKeysResponse keys.
                 * @member {Array.<infinitusai.be.IApiKeyDoc>} keys
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @instance
                 */
                GetAPIKeysResponse.prototype.keys = $util.emptyArray;
    
                /**
                 * Creates a new GetAPIKeysResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {infinitusai.be.IGetAPIKeysResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAPIKeysResponse} GetAPIKeysResponse instance
                 */
                GetAPIKeysResponse.create = function create(properties) {
                    return new GetAPIKeysResponse(properties);
                };
    
                /**
                 * Encodes the specified GetAPIKeysResponse message. Does not implicitly {@link infinitusai.be.GetAPIKeysResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {infinitusai.be.IGetAPIKeysResponse} message GetAPIKeysResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAPIKeysResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.keys != null && message.keys.length)
                        for (var i = 0; i < message.keys.length; ++i)
                            $root.infinitusai.be.ApiKeyDoc.encode(message.keys[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAPIKeysResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetAPIKeysResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {infinitusai.be.IGetAPIKeysResponse} message GetAPIKeysResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAPIKeysResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAPIKeysResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAPIKeysResponse} GetAPIKeysResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAPIKeysResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAPIKeysResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.keys && message.keys.length))
                                    message.keys = [];
                                message.keys.push($root.infinitusai.be.ApiKeyDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAPIKeysResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAPIKeysResponse} GetAPIKeysResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAPIKeysResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAPIKeysResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAPIKeysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keys != null && message.hasOwnProperty("keys")) {
                        if (!Array.isArray(message.keys))
                            return "keys: array expected";
                        for (var i = 0; i < message.keys.length; ++i) {
                            var error = $root.infinitusai.be.ApiKeyDoc.verify(message.keys[i]);
                            if (error)
                                return "keys." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetAPIKeysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAPIKeysResponse} GetAPIKeysResponse
                 */
                GetAPIKeysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAPIKeysResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetAPIKeysResponse();
                    if (object.keys) {
                        if (!Array.isArray(object.keys))
                            throw TypeError(".infinitusai.be.GetAPIKeysResponse.keys: array expected");
                        message.keys = [];
                        for (var i = 0; i < object.keys.length; ++i) {
                            if (typeof object.keys[i] !== "object")
                                throw TypeError(".infinitusai.be.GetAPIKeysResponse.keys: object expected");
                            message.keys[i] = $root.infinitusai.be.ApiKeyDoc.fromObject(object.keys[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAPIKeysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {infinitusai.be.GetAPIKeysResponse} message GetAPIKeysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAPIKeysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.keys = [];
                    if (message.keys && message.keys.length) {
                        object.keys = [];
                        for (var j = 0; j < message.keys.length; ++j)
                            object.keys[j] = $root.infinitusai.be.ApiKeyDoc.toObject(message.keys[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetAPIKeysResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAPIKeysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAPIKeysResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAPIKeysResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAPIKeysResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAPIKeysResponse";
                };
    
                return GetAPIKeysResponse;
            })();
    
            be.GenerateApiKeyRequest = (function() {
    
                /**
                 * Properties of a GenerateApiKeyRequest.
                 * @memberof infinitusai.be
                 * @interface IGenerateApiKeyRequest
                 * @property {string|null} [keyName] GenerateApiKeyRequest keyName
                 */
    
                /**
                 * Constructs a new GenerateApiKeyRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateApiKeyRequest.
                 * @implements IGenerateApiKeyRequest
                 * @constructor
                 * @param {infinitusai.be.IGenerateApiKeyRequest=} [properties] Properties to set
                 */
                function GenerateApiKeyRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateApiKeyRequest keyName.
                 * @member {string} keyName
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @instance
                 */
                GenerateApiKeyRequest.prototype.keyName = "";
    
                /**
                 * Creates a new GenerateApiKeyRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {infinitusai.be.IGenerateApiKeyRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateApiKeyRequest} GenerateApiKeyRequest instance
                 */
                GenerateApiKeyRequest.create = function create(properties) {
                    return new GenerateApiKeyRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateApiKeyRequest message. Does not implicitly {@link infinitusai.be.GenerateApiKeyRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {infinitusai.be.IGenerateApiKeyRequest} message GenerateApiKeyRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateApiKeyRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.keyName != null && Object.hasOwnProperty.call(message, "keyName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.keyName);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateApiKeyRequest message, length delimited. Does not implicitly {@link infinitusai.be.GenerateApiKeyRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {infinitusai.be.IGenerateApiKeyRequest} message GenerateApiKeyRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateApiKeyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateApiKeyRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateApiKeyRequest} GenerateApiKeyRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateApiKeyRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateApiKeyRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.keyName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateApiKeyRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateApiKeyRequest} GenerateApiKeyRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateApiKeyRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateApiKeyRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateApiKeyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keyName != null && message.hasOwnProperty("keyName"))
                        if (!$util.isString(message.keyName))
                            return "keyName: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateApiKeyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateApiKeyRequest} GenerateApiKeyRequest
                 */
                GenerateApiKeyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateApiKeyRequest)
                        return object;
                    var message = new $root.infinitusai.be.GenerateApiKeyRequest();
                    if (object.keyName != null)
                        message.keyName = String(object.keyName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateApiKeyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {infinitusai.be.GenerateApiKeyRequest} message GenerateApiKeyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateApiKeyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.keyName = "";
                    if (message.keyName != null && message.hasOwnProperty("keyName"))
                        object.keyName = message.keyName;
                    return object;
                };
    
                /**
                 * Converts this GenerateApiKeyRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateApiKeyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateApiKeyRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateApiKeyRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateApiKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateApiKeyRequest";
                };
    
                return GenerateApiKeyRequest;
            })();
    
            be.GenerateApiKeyResponse = (function() {
    
                /**
                 * Properties of a GenerateApiKeyResponse.
                 * @memberof infinitusai.be
                 * @interface IGenerateApiKeyResponse
                 * @property {infinitusai.be.IApiKeyDoc|null} [metadata] GenerateApiKeyResponse metadata
                 * @property {string|null} [apiKey] GenerateApiKeyResponse apiKey
                 */
    
                /**
                 * Constructs a new GenerateApiKeyResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateApiKeyResponse.
                 * @implements IGenerateApiKeyResponse
                 * @constructor
                 * @param {infinitusai.be.IGenerateApiKeyResponse=} [properties] Properties to set
                 */
                function GenerateApiKeyResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateApiKeyResponse metadata.
                 * @member {infinitusai.be.IApiKeyDoc|null|undefined} metadata
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @instance
                 */
                GenerateApiKeyResponse.prototype.metadata = null;
    
                /**
                 * GenerateApiKeyResponse apiKey.
                 * @member {string} apiKey
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @instance
                 */
                GenerateApiKeyResponse.prototype.apiKey = "";
    
                /**
                 * Creates a new GenerateApiKeyResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {infinitusai.be.IGenerateApiKeyResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateApiKeyResponse} GenerateApiKeyResponse instance
                 */
                GenerateApiKeyResponse.create = function create(properties) {
                    return new GenerateApiKeyResponse(properties);
                };
    
                /**
                 * Encodes the specified GenerateApiKeyResponse message. Does not implicitly {@link infinitusai.be.GenerateApiKeyResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {infinitusai.be.IGenerateApiKeyResponse} message GenerateApiKeyResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateApiKeyResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.ApiKeyDoc.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.apiKey != null && Object.hasOwnProperty.call(message, "apiKey"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.apiKey);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateApiKeyResponse message, length delimited. Does not implicitly {@link infinitusai.be.GenerateApiKeyResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {infinitusai.be.IGenerateApiKeyResponse} message GenerateApiKeyResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateApiKeyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateApiKeyResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateApiKeyResponse} GenerateApiKeyResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateApiKeyResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateApiKeyResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.metadata = $root.infinitusai.be.ApiKeyDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.apiKey = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateApiKeyResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateApiKeyResponse} GenerateApiKeyResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateApiKeyResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateApiKeyResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateApiKeyResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.ApiKeyDoc.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    if (message.apiKey != null && message.hasOwnProperty("apiKey"))
                        if (!$util.isString(message.apiKey))
                            return "apiKey: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateApiKeyResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateApiKeyResponse} GenerateApiKeyResponse
                 */
                GenerateApiKeyResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateApiKeyResponse)
                        return object;
                    var message = new $root.infinitusai.be.GenerateApiKeyResponse();
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.GenerateApiKeyResponse.metadata: object expected");
                        message.metadata = $root.infinitusai.be.ApiKeyDoc.fromObject(object.metadata);
                    }
                    if (object.apiKey != null)
                        message.apiKey = String(object.apiKey);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateApiKeyResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {infinitusai.be.GenerateApiKeyResponse} message GenerateApiKeyResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateApiKeyResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.metadata = null;
                        object.apiKey = "";
                    }
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.ApiKeyDoc.toObject(message.metadata, options);
                    if (message.apiKey != null && message.hasOwnProperty("apiKey"))
                        object.apiKey = message.apiKey;
                    return object;
                };
    
                /**
                 * Converts this GenerateApiKeyResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateApiKeyResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateApiKeyResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateApiKeyResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateApiKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateApiKeyResponse";
                };
    
                return GenerateApiKeyResponse;
            })();
    
            be.DisableApiKeyRequest = (function() {
    
                /**
                 * Properties of a DisableApiKeyRequest.
                 * @memberof infinitusai.be
                 * @interface IDisableApiKeyRequest
                 * @property {string|null} [keyId] DisableApiKeyRequest keyId
                 */
    
                /**
                 * Constructs a new DisableApiKeyRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DisableApiKeyRequest.
                 * @implements IDisableApiKeyRequest
                 * @constructor
                 * @param {infinitusai.be.IDisableApiKeyRequest=} [properties] Properties to set
                 */
                function DisableApiKeyRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DisableApiKeyRequest keyId.
                 * @member {string} keyId
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @instance
                 */
                DisableApiKeyRequest.prototype.keyId = "";
    
                /**
                 * Creates a new DisableApiKeyRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {infinitusai.be.IDisableApiKeyRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DisableApiKeyRequest} DisableApiKeyRequest instance
                 */
                DisableApiKeyRequest.create = function create(properties) {
                    return new DisableApiKeyRequest(properties);
                };
    
                /**
                 * Encodes the specified DisableApiKeyRequest message. Does not implicitly {@link infinitusai.be.DisableApiKeyRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {infinitusai.be.IDisableApiKeyRequest} message DisableApiKeyRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisableApiKeyRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.keyId != null && Object.hasOwnProperty.call(message, "keyId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.keyId);
                    return writer;
                };
    
                /**
                 * Encodes the specified DisableApiKeyRequest message, length delimited. Does not implicitly {@link infinitusai.be.DisableApiKeyRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {infinitusai.be.IDisableApiKeyRequest} message DisableApiKeyRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisableApiKeyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DisableApiKeyRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DisableApiKeyRequest} DisableApiKeyRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisableApiKeyRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DisableApiKeyRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.keyId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DisableApiKeyRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DisableApiKeyRequest} DisableApiKeyRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisableApiKeyRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DisableApiKeyRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableApiKeyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keyId != null && message.hasOwnProperty("keyId"))
                        if (!$util.isString(message.keyId))
                            return "keyId: string expected";
                    return null;
                };
    
                /**
                 * Creates a DisableApiKeyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DisableApiKeyRequest} DisableApiKeyRequest
                 */
                DisableApiKeyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DisableApiKeyRequest)
                        return object;
                    var message = new $root.infinitusai.be.DisableApiKeyRequest();
                    if (object.keyId != null)
                        message.keyId = String(object.keyId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DisableApiKeyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {infinitusai.be.DisableApiKeyRequest} message DisableApiKeyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableApiKeyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.keyId = "";
                    if (message.keyId != null && message.hasOwnProperty("keyId"))
                        object.keyId = message.keyId;
                    return object;
                };
    
                /**
                 * Converts this DisableApiKeyRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableApiKeyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DisableApiKeyRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DisableApiKeyRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DisableApiKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DisableApiKeyRequest";
                };
    
                return DisableApiKeyRequest;
            })();
    
            be.DisableApiKeyResponse = (function() {
    
                /**
                 * Properties of a DisableApiKeyResponse.
                 * @memberof infinitusai.be
                 * @interface IDisableApiKeyResponse
                 */
    
                /**
                 * Constructs a new DisableApiKeyResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DisableApiKeyResponse.
                 * @implements IDisableApiKeyResponse
                 * @constructor
                 * @param {infinitusai.be.IDisableApiKeyResponse=} [properties] Properties to set
                 */
                function DisableApiKeyResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DisableApiKeyResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {infinitusai.be.IDisableApiKeyResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DisableApiKeyResponse} DisableApiKeyResponse instance
                 */
                DisableApiKeyResponse.create = function create(properties) {
                    return new DisableApiKeyResponse(properties);
                };
    
                /**
                 * Encodes the specified DisableApiKeyResponse message. Does not implicitly {@link infinitusai.be.DisableApiKeyResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {infinitusai.be.IDisableApiKeyResponse} message DisableApiKeyResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisableApiKeyResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DisableApiKeyResponse message, length delimited. Does not implicitly {@link infinitusai.be.DisableApiKeyResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {infinitusai.be.IDisableApiKeyResponse} message DisableApiKeyResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisableApiKeyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DisableApiKeyResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DisableApiKeyResponse} DisableApiKeyResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisableApiKeyResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DisableApiKeyResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DisableApiKeyResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DisableApiKeyResponse} DisableApiKeyResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisableApiKeyResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DisableApiKeyResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableApiKeyResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DisableApiKeyResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DisableApiKeyResponse} DisableApiKeyResponse
                 */
                DisableApiKeyResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DisableApiKeyResponse)
                        return object;
                    return new $root.infinitusai.be.DisableApiKeyResponse();
                };
    
                /**
                 * Creates a plain object from a DisableApiKeyResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {infinitusai.be.DisableApiKeyResponse} message DisableApiKeyResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableApiKeyResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DisableApiKeyResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableApiKeyResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DisableApiKeyResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DisableApiKeyResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DisableApiKeyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DisableApiKeyResponse";
                };
    
                return DisableApiKeyResponse;
            })();
    
            be.ACCreateConversationRequest = (function() {
    
                /**
                 * Properties of a ACCreateConversationRequest.
                 * @memberof infinitusai.be
                 * @interface IACCreateConversationRequest
                 * @property {string|null} [conversation] ACCreateConversationRequest conversation
                 * @property {string|null} [bot] ACCreateConversationRequest bot
                 * @property {Array.<string>|null} [capabilities] ACCreateConversationRequest capabilities
                 */
    
                /**
                 * Constructs a new ACCreateConversationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACCreateConversationRequest.
                 * @implements IACCreateConversationRequest
                 * @constructor
                 * @param {infinitusai.be.IACCreateConversationRequest=} [properties] Properties to set
                 */
                function ACCreateConversationRequest(properties) {
                    this.capabilities = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACCreateConversationRequest conversation.
                 * @member {string} conversation
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @instance
                 */
                ACCreateConversationRequest.prototype.conversation = "";
    
                /**
                 * ACCreateConversationRequest bot.
                 * @member {string} bot
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @instance
                 */
                ACCreateConversationRequest.prototype.bot = "";
    
                /**
                 * ACCreateConversationRequest capabilities.
                 * @member {Array.<string>} capabilities
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @instance
                 */
                ACCreateConversationRequest.prototype.capabilities = $util.emptyArray;
    
                /**
                 * Creates a new ACCreateConversationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {infinitusai.be.IACCreateConversationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ACCreateConversationRequest} ACCreateConversationRequest instance
                 */
                ACCreateConversationRequest.create = function create(properties) {
                    return new ACCreateConversationRequest(properties);
                };
    
                /**
                 * Encodes the specified ACCreateConversationRequest message. Does not implicitly {@link infinitusai.be.ACCreateConversationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {infinitusai.be.IACCreateConversationRequest} message ACCreateConversationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACCreateConversationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversation != null && Object.hasOwnProperty.call(message, "conversation"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversation);
                    if (message.bot != null && Object.hasOwnProperty.call(message, "bot"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.bot);
                    if (message.capabilities != null && message.capabilities.length)
                        for (var i = 0; i < message.capabilities.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.capabilities[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACCreateConversationRequest message, length delimited. Does not implicitly {@link infinitusai.be.ACCreateConversationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {infinitusai.be.IACCreateConversationRequest} message ACCreateConversationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACCreateConversationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACCreateConversationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACCreateConversationRequest} ACCreateConversationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACCreateConversationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACCreateConversationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversation = reader.string();
                                break;
                            }
                        case 2: {
                                message.bot = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.capabilities && message.capabilities.length))
                                    message.capabilities = [];
                                message.capabilities.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACCreateConversationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACCreateConversationRequest} ACCreateConversationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACCreateConversationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACCreateConversationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACCreateConversationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversation != null && message.hasOwnProperty("conversation"))
                        if (!$util.isString(message.conversation))
                            return "conversation: string expected";
                    if (message.bot != null && message.hasOwnProperty("bot"))
                        if (!$util.isString(message.bot))
                            return "bot: string expected";
                    if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                        if (!Array.isArray(message.capabilities))
                            return "capabilities: array expected";
                        for (var i = 0; i < message.capabilities.length; ++i)
                            if (!$util.isString(message.capabilities[i]))
                                return "capabilities: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ACCreateConversationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACCreateConversationRequest} ACCreateConversationRequest
                 */
                ACCreateConversationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACCreateConversationRequest)
                        return object;
                    var message = new $root.infinitusai.be.ACCreateConversationRequest();
                    if (object.conversation != null)
                        message.conversation = String(object.conversation);
                    if (object.bot != null)
                        message.bot = String(object.bot);
                    if (object.capabilities) {
                        if (!Array.isArray(object.capabilities))
                            throw TypeError(".infinitusai.be.ACCreateConversationRequest.capabilities: array expected");
                        message.capabilities = [];
                        for (var i = 0; i < object.capabilities.length; ++i)
                            message.capabilities[i] = String(object.capabilities[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACCreateConversationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {infinitusai.be.ACCreateConversationRequest} message ACCreateConversationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACCreateConversationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.capabilities = [];
                    if (options.defaults) {
                        object.conversation = "";
                        object.bot = "";
                    }
                    if (message.conversation != null && message.hasOwnProperty("conversation"))
                        object.conversation = message.conversation;
                    if (message.bot != null && message.hasOwnProperty("bot"))
                        object.bot = message.bot;
                    if (message.capabilities && message.capabilities.length) {
                        object.capabilities = [];
                        for (var j = 0; j < message.capabilities.length; ++j)
                            object.capabilities[j] = message.capabilities[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ACCreateConversationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACCreateConversationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACCreateConversationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACCreateConversationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACCreateConversationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACCreateConversationRequest";
                };
    
                return ACCreateConversationRequest;
            })();
    
            be.ACCreateConversationResponse = (function() {
    
                /**
                 * Properties of a ACCreateConversationResponse.
                 * @memberof infinitusai.be
                 * @interface IACCreateConversationResponse
                 * @property {string|null} [activitiesURL] ACCreateConversationResponse activitiesURL
                 * @property {string|null} [refreshURL] ACCreateConversationResponse refreshURL
                 * @property {string|null} [disconnectURL] ACCreateConversationResponse disconnectURL
                 * @property {string|null} [websocketURL] ACCreateConversationResponse websocketURL
                 * @property {number|null} [expiresSeconds] ACCreateConversationResponse expiresSeconds
                 */
    
                /**
                 * Constructs a new ACCreateConversationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACCreateConversationResponse.
                 * @implements IACCreateConversationResponse
                 * @constructor
                 * @param {infinitusai.be.IACCreateConversationResponse=} [properties] Properties to set
                 */
                function ACCreateConversationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACCreateConversationResponse activitiesURL.
                 * @member {string} activitiesURL
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @instance
                 */
                ACCreateConversationResponse.prototype.activitiesURL = "";
    
                /**
                 * ACCreateConversationResponse refreshURL.
                 * @member {string} refreshURL
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @instance
                 */
                ACCreateConversationResponse.prototype.refreshURL = "";
    
                /**
                 * ACCreateConversationResponse disconnectURL.
                 * @member {string} disconnectURL
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @instance
                 */
                ACCreateConversationResponse.prototype.disconnectURL = "";
    
                /**
                 * ACCreateConversationResponse websocketURL.
                 * @member {string} websocketURL
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @instance
                 */
                ACCreateConversationResponse.prototype.websocketURL = "";
    
                /**
                 * ACCreateConversationResponse expiresSeconds.
                 * @member {number} expiresSeconds
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @instance
                 */
                ACCreateConversationResponse.prototype.expiresSeconds = 0;
    
                /**
                 * Creates a new ACCreateConversationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {infinitusai.be.IACCreateConversationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ACCreateConversationResponse} ACCreateConversationResponse instance
                 */
                ACCreateConversationResponse.create = function create(properties) {
                    return new ACCreateConversationResponse(properties);
                };
    
                /**
                 * Encodes the specified ACCreateConversationResponse message. Does not implicitly {@link infinitusai.be.ACCreateConversationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {infinitusai.be.IACCreateConversationResponse} message ACCreateConversationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACCreateConversationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.activitiesURL != null && Object.hasOwnProperty.call(message, "activitiesURL"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.activitiesURL);
                    if (message.refreshURL != null && Object.hasOwnProperty.call(message, "refreshURL"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.refreshURL);
                    if (message.disconnectURL != null && Object.hasOwnProperty.call(message, "disconnectURL"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.disconnectURL);
                    if (message.websocketURL != null && Object.hasOwnProperty.call(message, "websocketURL"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.websocketURL);
                    if (message.expiresSeconds != null && Object.hasOwnProperty.call(message, "expiresSeconds"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.expiresSeconds);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACCreateConversationResponse message, length delimited. Does not implicitly {@link infinitusai.be.ACCreateConversationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {infinitusai.be.IACCreateConversationResponse} message ACCreateConversationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACCreateConversationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACCreateConversationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACCreateConversationResponse} ACCreateConversationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACCreateConversationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACCreateConversationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.activitiesURL = reader.string();
                                break;
                            }
                        case 2: {
                                message.refreshURL = reader.string();
                                break;
                            }
                        case 3: {
                                message.disconnectURL = reader.string();
                                break;
                            }
                        case 4: {
                                message.websocketURL = reader.string();
                                break;
                            }
                        case 5: {
                                message.expiresSeconds = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACCreateConversationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACCreateConversationResponse} ACCreateConversationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACCreateConversationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACCreateConversationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACCreateConversationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.activitiesURL != null && message.hasOwnProperty("activitiesURL"))
                        if (!$util.isString(message.activitiesURL))
                            return "activitiesURL: string expected";
                    if (message.refreshURL != null && message.hasOwnProperty("refreshURL"))
                        if (!$util.isString(message.refreshURL))
                            return "refreshURL: string expected";
                    if (message.disconnectURL != null && message.hasOwnProperty("disconnectURL"))
                        if (!$util.isString(message.disconnectURL))
                            return "disconnectURL: string expected";
                    if (message.websocketURL != null && message.hasOwnProperty("websocketURL"))
                        if (!$util.isString(message.websocketURL))
                            return "websocketURL: string expected";
                    if (message.expiresSeconds != null && message.hasOwnProperty("expiresSeconds"))
                        if (!$util.isInteger(message.expiresSeconds))
                            return "expiresSeconds: integer expected";
                    return null;
                };
    
                /**
                 * Creates a ACCreateConversationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACCreateConversationResponse} ACCreateConversationResponse
                 */
                ACCreateConversationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACCreateConversationResponse)
                        return object;
                    var message = new $root.infinitusai.be.ACCreateConversationResponse();
                    if (object.activitiesURL != null)
                        message.activitiesURL = String(object.activitiesURL);
                    if (object.refreshURL != null)
                        message.refreshURL = String(object.refreshURL);
                    if (object.disconnectURL != null)
                        message.disconnectURL = String(object.disconnectURL);
                    if (object.websocketURL != null)
                        message.websocketURL = String(object.websocketURL);
                    if (object.expiresSeconds != null)
                        message.expiresSeconds = object.expiresSeconds | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACCreateConversationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {infinitusai.be.ACCreateConversationResponse} message ACCreateConversationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACCreateConversationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.activitiesURL = "";
                        object.refreshURL = "";
                        object.disconnectURL = "";
                        object.websocketURL = "";
                        object.expiresSeconds = 0;
                    }
                    if (message.activitiesURL != null && message.hasOwnProperty("activitiesURL"))
                        object.activitiesURL = message.activitiesURL;
                    if (message.refreshURL != null && message.hasOwnProperty("refreshURL"))
                        object.refreshURL = message.refreshURL;
                    if (message.disconnectURL != null && message.hasOwnProperty("disconnectURL"))
                        object.disconnectURL = message.disconnectURL;
                    if (message.websocketURL != null && message.hasOwnProperty("websocketURL"))
                        object.websocketURL = message.websocketURL;
                    if (message.expiresSeconds != null && message.hasOwnProperty("expiresSeconds"))
                        object.expiresSeconds = message.expiresSeconds;
                    return object;
                };
    
                /**
                 * Converts this ACCreateConversationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACCreateConversationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACCreateConversationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACCreateConversationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACCreateConversationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACCreateConversationResponse";
                };
    
                return ACCreateConversationResponse;
            })();
    
            be.ACConversationRefreshRequest = (function() {
    
                /**
                 * Properties of a ACConversationRefreshRequest.
                 * @memberof infinitusai.be
                 * @interface IACConversationRefreshRequest
                 * @property {string|null} [conversation] ACConversationRefreshRequest conversation
                 */
    
                /**
                 * Constructs a new ACConversationRefreshRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACConversationRefreshRequest.
                 * @implements IACConversationRefreshRequest
                 * @constructor
                 * @param {infinitusai.be.IACConversationRefreshRequest=} [properties] Properties to set
                 */
                function ACConversationRefreshRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACConversationRefreshRequest conversation.
                 * @member {string} conversation
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @instance
                 */
                ACConversationRefreshRequest.prototype.conversation = "";
    
                /**
                 * Creates a new ACConversationRefreshRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {infinitusai.be.IACConversationRefreshRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ACConversationRefreshRequest} ACConversationRefreshRequest instance
                 */
                ACConversationRefreshRequest.create = function create(properties) {
                    return new ACConversationRefreshRequest(properties);
                };
    
                /**
                 * Encodes the specified ACConversationRefreshRequest message. Does not implicitly {@link infinitusai.be.ACConversationRefreshRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {infinitusai.be.IACConversationRefreshRequest} message ACConversationRefreshRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationRefreshRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversation != null && Object.hasOwnProperty.call(message, "conversation"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversation);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACConversationRefreshRequest message, length delimited. Does not implicitly {@link infinitusai.be.ACConversationRefreshRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {infinitusai.be.IACConversationRefreshRequest} message ACConversationRefreshRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationRefreshRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACConversationRefreshRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACConversationRefreshRequest} ACConversationRefreshRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationRefreshRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACConversationRefreshRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversation = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACConversationRefreshRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACConversationRefreshRequest} ACConversationRefreshRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationRefreshRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACConversationRefreshRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACConversationRefreshRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversation != null && message.hasOwnProperty("conversation"))
                        if (!$util.isString(message.conversation))
                            return "conversation: string expected";
                    return null;
                };
    
                /**
                 * Creates a ACConversationRefreshRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACConversationRefreshRequest} ACConversationRefreshRequest
                 */
                ACConversationRefreshRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACConversationRefreshRequest)
                        return object;
                    var message = new $root.infinitusai.be.ACConversationRefreshRequest();
                    if (object.conversation != null)
                        message.conversation = String(object.conversation);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACConversationRefreshRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {infinitusai.be.ACConversationRefreshRequest} message ACConversationRefreshRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACConversationRefreshRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.conversation = "";
                    if (message.conversation != null && message.hasOwnProperty("conversation"))
                        object.conversation = message.conversation;
                    return object;
                };
    
                /**
                 * Converts this ACConversationRefreshRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACConversationRefreshRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACConversationRefreshRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACConversationRefreshRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACConversationRefreshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACConversationRefreshRequest";
                };
    
                return ACConversationRefreshRequest;
            })();
    
            be.ACConversationRefreshResponse = (function() {
    
                /**
                 * Properties of a ACConversationRefreshResponse.
                 * @memberof infinitusai.be
                 * @interface IACConversationRefreshResponse
                 * @property {number|null} [expiresSeconds] ACConversationRefreshResponse expiresSeconds
                 */
    
                /**
                 * Constructs a new ACConversationRefreshResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACConversationRefreshResponse.
                 * @implements IACConversationRefreshResponse
                 * @constructor
                 * @param {infinitusai.be.IACConversationRefreshResponse=} [properties] Properties to set
                 */
                function ACConversationRefreshResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACConversationRefreshResponse expiresSeconds.
                 * @member {number} expiresSeconds
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @instance
                 */
                ACConversationRefreshResponse.prototype.expiresSeconds = 0;
    
                /**
                 * Creates a new ACConversationRefreshResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {infinitusai.be.IACConversationRefreshResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ACConversationRefreshResponse} ACConversationRefreshResponse instance
                 */
                ACConversationRefreshResponse.create = function create(properties) {
                    return new ACConversationRefreshResponse(properties);
                };
    
                /**
                 * Encodes the specified ACConversationRefreshResponse message. Does not implicitly {@link infinitusai.be.ACConversationRefreshResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {infinitusai.be.IACConversationRefreshResponse} message ACConversationRefreshResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationRefreshResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expiresSeconds != null && Object.hasOwnProperty.call(message, "expiresSeconds"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.expiresSeconds);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACConversationRefreshResponse message, length delimited. Does not implicitly {@link infinitusai.be.ACConversationRefreshResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {infinitusai.be.IACConversationRefreshResponse} message ACConversationRefreshResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationRefreshResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACConversationRefreshResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACConversationRefreshResponse} ACConversationRefreshResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationRefreshResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACConversationRefreshResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.expiresSeconds = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACConversationRefreshResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACConversationRefreshResponse} ACConversationRefreshResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationRefreshResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACConversationRefreshResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACConversationRefreshResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expiresSeconds != null && message.hasOwnProperty("expiresSeconds"))
                        if (!$util.isInteger(message.expiresSeconds))
                            return "expiresSeconds: integer expected";
                    return null;
                };
    
                /**
                 * Creates a ACConversationRefreshResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACConversationRefreshResponse} ACConversationRefreshResponse
                 */
                ACConversationRefreshResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACConversationRefreshResponse)
                        return object;
                    var message = new $root.infinitusai.be.ACConversationRefreshResponse();
                    if (object.expiresSeconds != null)
                        message.expiresSeconds = object.expiresSeconds | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACConversationRefreshResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {infinitusai.be.ACConversationRefreshResponse} message ACConversationRefreshResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACConversationRefreshResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.expiresSeconds = 0;
                    if (message.expiresSeconds != null && message.hasOwnProperty("expiresSeconds"))
                        object.expiresSeconds = message.expiresSeconds;
                    return object;
                };
    
                /**
                 * Converts this ACConversationRefreshResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACConversationRefreshResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACConversationRefreshResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACConversationRefreshResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACConversationRefreshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACConversationRefreshResponse";
                };
    
                return ACConversationRefreshResponse;
            })();
    
            be.ACConversationDisconnectRequest = (function() {
    
                /**
                 * Properties of a ACConversationDisconnectRequest.
                 * @memberof infinitusai.be
                 * @interface IACConversationDisconnectRequest
                 * @property {string|null} [conversation] ACConversationDisconnectRequest conversation
                 * @property {string|null} [reason] ACConversationDisconnectRequest reason
                 */
    
                /**
                 * Constructs a new ACConversationDisconnectRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACConversationDisconnectRequest.
                 * @implements IACConversationDisconnectRequest
                 * @constructor
                 * @param {infinitusai.be.IACConversationDisconnectRequest=} [properties] Properties to set
                 */
                function ACConversationDisconnectRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACConversationDisconnectRequest conversation.
                 * @member {string} conversation
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @instance
                 */
                ACConversationDisconnectRequest.prototype.conversation = "";
    
                /**
                 * ACConversationDisconnectRequest reason.
                 * @member {string} reason
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @instance
                 */
                ACConversationDisconnectRequest.prototype.reason = "";
    
                /**
                 * Creates a new ACConversationDisconnectRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {infinitusai.be.IACConversationDisconnectRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ACConversationDisconnectRequest} ACConversationDisconnectRequest instance
                 */
                ACConversationDisconnectRequest.create = function create(properties) {
                    return new ACConversationDisconnectRequest(properties);
                };
    
                /**
                 * Encodes the specified ACConversationDisconnectRequest message. Does not implicitly {@link infinitusai.be.ACConversationDisconnectRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {infinitusai.be.IACConversationDisconnectRequest} message ACConversationDisconnectRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationDisconnectRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversation != null && Object.hasOwnProperty.call(message, "conversation"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversation);
                    if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACConversationDisconnectRequest message, length delimited. Does not implicitly {@link infinitusai.be.ACConversationDisconnectRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {infinitusai.be.IACConversationDisconnectRequest} message ACConversationDisconnectRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationDisconnectRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACConversationDisconnectRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACConversationDisconnectRequest} ACConversationDisconnectRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationDisconnectRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACConversationDisconnectRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversation = reader.string();
                                break;
                            }
                        case 2: {
                                message.reason = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACConversationDisconnectRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACConversationDisconnectRequest} ACConversationDisconnectRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationDisconnectRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACConversationDisconnectRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACConversationDisconnectRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversation != null && message.hasOwnProperty("conversation"))
                        if (!$util.isString(message.conversation))
                            return "conversation: string expected";
                    if (message.reason != null && message.hasOwnProperty("reason"))
                        if (!$util.isString(message.reason))
                            return "reason: string expected";
                    return null;
                };
    
                /**
                 * Creates a ACConversationDisconnectRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACConversationDisconnectRequest} ACConversationDisconnectRequest
                 */
                ACConversationDisconnectRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACConversationDisconnectRequest)
                        return object;
                    var message = new $root.infinitusai.be.ACConversationDisconnectRequest();
                    if (object.conversation != null)
                        message.conversation = String(object.conversation);
                    if (object.reason != null)
                        message.reason = String(object.reason);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACConversationDisconnectRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {infinitusai.be.ACConversationDisconnectRequest} message ACConversationDisconnectRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACConversationDisconnectRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.conversation = "";
                        object.reason = "";
                    }
                    if (message.conversation != null && message.hasOwnProperty("conversation"))
                        object.conversation = message.conversation;
                    if (message.reason != null && message.hasOwnProperty("reason"))
                        object.reason = message.reason;
                    return object;
                };
    
                /**
                 * Converts this ACConversationDisconnectRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACConversationDisconnectRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACConversationDisconnectRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACConversationDisconnectRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACConversationDisconnectRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACConversationDisconnectRequest";
                };
    
                return ACConversationDisconnectRequest;
            })();
    
            be.ACConversationDisconnectResponse = (function() {
    
                /**
                 * Properties of a ACConversationDisconnectResponse.
                 * @memberof infinitusai.be
                 * @interface IACConversationDisconnectResponse
                 */
    
                /**
                 * Constructs a new ACConversationDisconnectResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACConversationDisconnectResponse.
                 * @implements IACConversationDisconnectResponse
                 * @constructor
                 * @param {infinitusai.be.IACConversationDisconnectResponse=} [properties] Properties to set
                 */
                function ACConversationDisconnectResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ACConversationDisconnectResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {infinitusai.be.IACConversationDisconnectResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ACConversationDisconnectResponse} ACConversationDisconnectResponse instance
                 */
                ACConversationDisconnectResponse.create = function create(properties) {
                    return new ACConversationDisconnectResponse(properties);
                };
    
                /**
                 * Encodes the specified ACConversationDisconnectResponse message. Does not implicitly {@link infinitusai.be.ACConversationDisconnectResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {infinitusai.be.IACConversationDisconnectResponse} message ACConversationDisconnectResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationDisconnectResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ACConversationDisconnectResponse message, length delimited. Does not implicitly {@link infinitusai.be.ACConversationDisconnectResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {infinitusai.be.IACConversationDisconnectResponse} message ACConversationDisconnectResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationDisconnectResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACConversationDisconnectResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACConversationDisconnectResponse} ACConversationDisconnectResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationDisconnectResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACConversationDisconnectResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACConversationDisconnectResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACConversationDisconnectResponse} ACConversationDisconnectResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationDisconnectResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACConversationDisconnectResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACConversationDisconnectResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ACConversationDisconnectResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACConversationDisconnectResponse} ACConversationDisconnectResponse
                 */
                ACConversationDisconnectResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACConversationDisconnectResponse)
                        return object;
                    return new $root.infinitusai.be.ACConversationDisconnectResponse();
                };
    
                /**
                 * Creates a plain object from a ACConversationDisconnectResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {infinitusai.be.ACConversationDisconnectResponse} message ACConversationDisconnectResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACConversationDisconnectResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ACConversationDisconnectResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACConversationDisconnectResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACConversationDisconnectResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACConversationDisconnectResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACConversationDisconnectResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACConversationDisconnectResponse";
                };
    
                return ACConversationDisconnectResponse;
            })();
    
            be.ACConversationActivity = (function() {
    
                /**
                 * Properties of a ACConversationActivity.
                 * @memberof infinitusai.be
                 * @interface IACConversationActivity
                 * @property {string|null} [id] ACConversationActivity id
                 * @property {string|null} [timestamp] ACConversationActivity timestamp
                 * @property {string|null} [type] ACConversationActivity type
                 * @property {string|null} [name] ACConversationActivity name
                 * @property {string|null} [text] ACConversationActivity text
                 * @property {string|null} [value] ACConversationActivity value
                 * @property {infinitusai.be.IACSessionParams|null} [sessionParams] ACConversationActivity sessionParams
                 * @property {infinitusai.be.IACActivityParams|null} [activityParams] ACConversationActivity activityParams
                 */
    
                /**
                 * Constructs a new ACConversationActivity.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACConversationActivity.
                 * @implements IACConversationActivity
                 * @constructor
                 * @param {infinitusai.be.IACConversationActivity=} [properties] Properties to set
                 */
                function ACConversationActivity(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACConversationActivity id.
                 * @member {string} id
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 */
                ACConversationActivity.prototype.id = "";
    
                /**
                 * ACConversationActivity timestamp.
                 * @member {string} timestamp
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 */
                ACConversationActivity.prototype.timestamp = "";
    
                /**
                 * ACConversationActivity type.
                 * @member {string} type
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 */
                ACConversationActivity.prototype.type = "";
    
                /**
                 * ACConversationActivity name.
                 * @member {string} name
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 */
                ACConversationActivity.prototype.name = "";
    
                /**
                 * ACConversationActivity text.
                 * @member {string} text
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 */
                ACConversationActivity.prototype.text = "";
    
                /**
                 * ACConversationActivity value.
                 * @member {string} value
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 */
                ACConversationActivity.prototype.value = "";
    
                /**
                 * ACConversationActivity sessionParams.
                 * @member {infinitusai.be.IACSessionParams|null|undefined} sessionParams
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 */
                ACConversationActivity.prototype.sessionParams = null;
    
                /**
                 * ACConversationActivity activityParams.
                 * @member {infinitusai.be.IACActivityParams|null|undefined} activityParams
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 */
                ACConversationActivity.prototype.activityParams = null;
    
                /**
                 * Creates a new ACConversationActivity instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {infinitusai.be.IACConversationActivity=} [properties] Properties to set
                 * @returns {infinitusai.be.ACConversationActivity} ACConversationActivity instance
                 */
                ACConversationActivity.create = function create(properties) {
                    return new ACConversationActivity(properties);
                };
    
                /**
                 * Encodes the specified ACConversationActivity message. Does not implicitly {@link infinitusai.be.ACConversationActivity.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {infinitusai.be.IACConversationActivity} message ACConversationActivity message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationActivity.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.timestamp);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.text);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.value);
                    if (message.sessionParams != null && Object.hasOwnProperty.call(message, "sessionParams"))
                        $root.infinitusai.be.ACSessionParams.encode(message.sessionParams, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.activityParams != null && Object.hasOwnProperty.call(message, "activityParams"))
                        $root.infinitusai.be.ACActivityParams.encode(message.activityParams, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ACConversationActivity message, length delimited. Does not implicitly {@link infinitusai.be.ACConversationActivity.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {infinitusai.be.IACConversationActivity} message ACConversationActivity message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationActivity.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACConversationActivity message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACConversationActivity} ACConversationActivity
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationActivity.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACConversationActivity();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.timestamp = reader.string();
                                break;
                            }
                        case 3: {
                                message.type = reader.string();
                                break;
                            }
                        case 4: {
                                message.name = reader.string();
                                break;
                            }
                        case 5: {
                                message.text = reader.string();
                                break;
                            }
                        case 6: {
                                message.value = reader.string();
                                break;
                            }
                        case 7: {
                                message.sessionParams = $root.infinitusai.be.ACSessionParams.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.activityParams = $root.infinitusai.be.ACActivityParams.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACConversationActivity message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACConversationActivity} ACConversationActivity
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationActivity.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACConversationActivity message.
                 * @function verify
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACConversationActivity.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                        if (!$util.isString(message.timestamp))
                            return "timestamp: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.sessionParams != null && message.hasOwnProperty("sessionParams")) {
                        var error = $root.infinitusai.be.ACSessionParams.verify(message.sessionParams);
                        if (error)
                            return "sessionParams." + error;
                    }
                    if (message.activityParams != null && message.hasOwnProperty("activityParams")) {
                        var error = $root.infinitusai.be.ACActivityParams.verify(message.activityParams);
                        if (error)
                            return "activityParams." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ACConversationActivity message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACConversationActivity} ACConversationActivity
                 */
                ACConversationActivity.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACConversationActivity)
                        return object;
                    var message = new $root.infinitusai.be.ACConversationActivity();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.timestamp != null)
                        message.timestamp = String(object.timestamp);
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.text != null)
                        message.text = String(object.text);
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.sessionParams != null) {
                        if (typeof object.sessionParams !== "object")
                            throw TypeError(".infinitusai.be.ACConversationActivity.sessionParams: object expected");
                        message.sessionParams = $root.infinitusai.be.ACSessionParams.fromObject(object.sessionParams);
                    }
                    if (object.activityParams != null) {
                        if (typeof object.activityParams !== "object")
                            throw TypeError(".infinitusai.be.ACConversationActivity.activityParams: object expected");
                        message.activityParams = $root.infinitusai.be.ACActivityParams.fromObject(object.activityParams);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACConversationActivity message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {infinitusai.be.ACConversationActivity} message ACConversationActivity
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACConversationActivity.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.timestamp = "";
                        object.type = "";
                        object.name = "";
                        object.text = "";
                        object.value = "";
                        object.sessionParams = null;
                        object.activityParams = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                        object.timestamp = message.timestamp;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.sessionParams != null && message.hasOwnProperty("sessionParams"))
                        object.sessionParams = $root.infinitusai.be.ACSessionParams.toObject(message.sessionParams, options);
                    if (message.activityParams != null && message.hasOwnProperty("activityParams"))
                        object.activityParams = $root.infinitusai.be.ACActivityParams.toObject(message.activityParams, options);
                    return object;
                };
    
                /**
                 * Converts this ACConversationActivity to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACConversationActivity
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACConversationActivity.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACConversationActivity
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACConversationActivity
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACConversationActivity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACConversationActivity";
                };
    
                return ACConversationActivity;
            })();
    
            be.ACActivityParams = (function() {
    
                /**
                 * Properties of a ACActivityParams.
                 * @memberof infinitusai.be
                 * @interface IACActivityParams
                 * @property {string|null} [transferTarget] ACActivityParams transferTarget
                 */
    
                /**
                 * Constructs a new ACActivityParams.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACActivityParams.
                 * @implements IACActivityParams
                 * @constructor
                 * @param {infinitusai.be.IACActivityParams=} [properties] Properties to set
                 */
                function ACActivityParams(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACActivityParams transferTarget.
                 * @member {string} transferTarget
                 * @memberof infinitusai.be.ACActivityParams
                 * @instance
                 */
                ACActivityParams.prototype.transferTarget = "";
    
                /**
                 * Creates a new ACActivityParams instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {infinitusai.be.IACActivityParams=} [properties] Properties to set
                 * @returns {infinitusai.be.ACActivityParams} ACActivityParams instance
                 */
                ACActivityParams.create = function create(properties) {
                    return new ACActivityParams(properties);
                };
    
                /**
                 * Encodes the specified ACActivityParams message. Does not implicitly {@link infinitusai.be.ACActivityParams.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {infinitusai.be.IACActivityParams} message ACActivityParams message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACActivityParams.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.transferTarget != null && Object.hasOwnProperty.call(message, "transferTarget"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.transferTarget);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACActivityParams message, length delimited. Does not implicitly {@link infinitusai.be.ACActivityParams.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {infinitusai.be.IACActivityParams} message ACActivityParams message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACActivityParams.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACActivityParams message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACActivityParams} ACActivityParams
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACActivityParams.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACActivityParams();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.transferTarget = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACActivityParams message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACActivityParams} ACActivityParams
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACActivityParams.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACActivityParams message.
                 * @function verify
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACActivityParams.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transferTarget != null && message.hasOwnProperty("transferTarget"))
                        if (!$util.isString(message.transferTarget))
                            return "transferTarget: string expected";
                    return null;
                };
    
                /**
                 * Creates a ACActivityParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACActivityParams} ACActivityParams
                 */
                ACActivityParams.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACActivityParams)
                        return object;
                    var message = new $root.infinitusai.be.ACActivityParams();
                    if (object.transferTarget != null)
                        message.transferTarget = String(object.transferTarget);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACActivityParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {infinitusai.be.ACActivityParams} message ACActivityParams
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACActivityParams.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.transferTarget = "";
                    if (message.transferTarget != null && message.hasOwnProperty("transferTarget"))
                        object.transferTarget = message.transferTarget;
                    return object;
                };
    
                /**
                 * Converts this ACActivityParams to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACActivityParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACActivityParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACActivityParams
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACActivityParams
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACActivityParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACActivityParams";
                };
    
                return ACActivityParams;
            })();
    
            be.ACSessionParams = (function() {
    
                /**
                 * Properties of a ACSessionParams.
                 * @memberof infinitusai.be
                 * @interface IACSessionParams
                 * @property {boolean|null} [dtmfCollect] ACSessionParams dtmfCollect
                 * @property {string|null} [dtmfCollectSubmitDigit] ACSessionParams dtmfCollectSubmitDigit
                 * @property {number|null} [dtmfCollectInterDigitTimeoutMS] ACSessionParams dtmfCollectInterDigitTimeoutMS
                 * @property {number|null} [dtmfCollectMaxDigits] ACSessionParams dtmfCollectMaxDigits
                 * @property {boolean|null} [bargeInOnDTMF] ACSessionParams bargeInOnDTMF
                 * @property {boolean|null} [bargeIn] ACSessionParams bargeIn
                 * @property {number|null} [bargeInMinWordCount] ACSessionParams bargeInMinWordCount
                 */
    
                /**
                 * Constructs a new ACSessionParams.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACSessionParams.
                 * @implements IACSessionParams
                 * @constructor
                 * @param {infinitusai.be.IACSessionParams=} [properties] Properties to set
                 */
                function ACSessionParams(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACSessionParams dtmfCollect.
                 * @member {boolean} dtmfCollect
                 * @memberof infinitusai.be.ACSessionParams
                 * @instance
                 */
                ACSessionParams.prototype.dtmfCollect = false;
    
                /**
                 * ACSessionParams dtmfCollectSubmitDigit.
                 * @member {string} dtmfCollectSubmitDigit
                 * @memberof infinitusai.be.ACSessionParams
                 * @instance
                 */
                ACSessionParams.prototype.dtmfCollectSubmitDigit = "";
    
                /**
                 * ACSessionParams dtmfCollectInterDigitTimeoutMS.
                 * @member {number} dtmfCollectInterDigitTimeoutMS
                 * @memberof infinitusai.be.ACSessionParams
                 * @instance
                 */
                ACSessionParams.prototype.dtmfCollectInterDigitTimeoutMS = 0;
    
                /**
                 * ACSessionParams dtmfCollectMaxDigits.
                 * @member {number} dtmfCollectMaxDigits
                 * @memberof infinitusai.be.ACSessionParams
                 * @instance
                 */
                ACSessionParams.prototype.dtmfCollectMaxDigits = 0;
    
                /**
                 * ACSessionParams bargeInOnDTMF.
                 * @member {boolean} bargeInOnDTMF
                 * @memberof infinitusai.be.ACSessionParams
                 * @instance
                 */
                ACSessionParams.prototype.bargeInOnDTMF = false;
    
                /**
                 * ACSessionParams bargeIn.
                 * @member {boolean} bargeIn
                 * @memberof infinitusai.be.ACSessionParams
                 * @instance
                 */
                ACSessionParams.prototype.bargeIn = false;
    
                /**
                 * ACSessionParams bargeInMinWordCount.
                 * @member {number} bargeInMinWordCount
                 * @memberof infinitusai.be.ACSessionParams
                 * @instance
                 */
                ACSessionParams.prototype.bargeInMinWordCount = 0;
    
                /**
                 * Creates a new ACSessionParams instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {infinitusai.be.IACSessionParams=} [properties] Properties to set
                 * @returns {infinitusai.be.ACSessionParams} ACSessionParams instance
                 */
                ACSessionParams.create = function create(properties) {
                    return new ACSessionParams(properties);
                };
    
                /**
                 * Encodes the specified ACSessionParams message. Does not implicitly {@link infinitusai.be.ACSessionParams.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {infinitusai.be.IACSessionParams} message ACSessionParams message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACSessionParams.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.dtmfCollect != null && Object.hasOwnProperty.call(message, "dtmfCollect"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.dtmfCollect);
                    if (message.dtmfCollectSubmitDigit != null && Object.hasOwnProperty.call(message, "dtmfCollectSubmitDigit"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dtmfCollectSubmitDigit);
                    if (message.dtmfCollectInterDigitTimeoutMS != null && Object.hasOwnProperty.call(message, "dtmfCollectInterDigitTimeoutMS"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.dtmfCollectInterDigitTimeoutMS);
                    if (message.dtmfCollectMaxDigits != null && Object.hasOwnProperty.call(message, "dtmfCollectMaxDigits"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dtmfCollectMaxDigits);
                    if (message.bargeInOnDTMF != null && Object.hasOwnProperty.call(message, "bargeInOnDTMF"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.bargeInOnDTMF);
                    if (message.bargeIn != null && Object.hasOwnProperty.call(message, "bargeIn"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.bargeIn);
                    if (message.bargeInMinWordCount != null && Object.hasOwnProperty.call(message, "bargeInMinWordCount"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.bargeInMinWordCount);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACSessionParams message, length delimited. Does not implicitly {@link infinitusai.be.ACSessionParams.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {infinitusai.be.IACSessionParams} message ACSessionParams message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACSessionParams.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACSessionParams message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACSessionParams} ACSessionParams
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACSessionParams.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACSessionParams();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.dtmfCollect = reader.bool();
                                break;
                            }
                        case 2: {
                                message.dtmfCollectSubmitDigit = reader.string();
                                break;
                            }
                        case 3: {
                                message.dtmfCollectInterDigitTimeoutMS = reader.int32();
                                break;
                            }
                        case 4: {
                                message.dtmfCollectMaxDigits = reader.int32();
                                break;
                            }
                        case 5: {
                                message.bargeInOnDTMF = reader.bool();
                                break;
                            }
                        case 6: {
                                message.bargeIn = reader.bool();
                                break;
                            }
                        case 7: {
                                message.bargeInMinWordCount = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACSessionParams message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACSessionParams} ACSessionParams
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACSessionParams.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACSessionParams message.
                 * @function verify
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACSessionParams.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.dtmfCollect != null && message.hasOwnProperty("dtmfCollect"))
                        if (typeof message.dtmfCollect !== "boolean")
                            return "dtmfCollect: boolean expected";
                    if (message.dtmfCollectSubmitDigit != null && message.hasOwnProperty("dtmfCollectSubmitDigit"))
                        if (!$util.isString(message.dtmfCollectSubmitDigit))
                            return "dtmfCollectSubmitDigit: string expected";
                    if (message.dtmfCollectInterDigitTimeoutMS != null && message.hasOwnProperty("dtmfCollectInterDigitTimeoutMS"))
                        if (!$util.isInteger(message.dtmfCollectInterDigitTimeoutMS))
                            return "dtmfCollectInterDigitTimeoutMS: integer expected";
                    if (message.dtmfCollectMaxDigits != null && message.hasOwnProperty("dtmfCollectMaxDigits"))
                        if (!$util.isInteger(message.dtmfCollectMaxDigits))
                            return "dtmfCollectMaxDigits: integer expected";
                    if (message.bargeInOnDTMF != null && message.hasOwnProperty("bargeInOnDTMF"))
                        if (typeof message.bargeInOnDTMF !== "boolean")
                            return "bargeInOnDTMF: boolean expected";
                    if (message.bargeIn != null && message.hasOwnProperty("bargeIn"))
                        if (typeof message.bargeIn !== "boolean")
                            return "bargeIn: boolean expected";
                    if (message.bargeInMinWordCount != null && message.hasOwnProperty("bargeInMinWordCount"))
                        if (!$util.isInteger(message.bargeInMinWordCount))
                            return "bargeInMinWordCount: integer expected";
                    return null;
                };
    
                /**
                 * Creates a ACSessionParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACSessionParams} ACSessionParams
                 */
                ACSessionParams.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACSessionParams)
                        return object;
                    var message = new $root.infinitusai.be.ACSessionParams();
                    if (object.dtmfCollect != null)
                        message.dtmfCollect = Boolean(object.dtmfCollect);
                    if (object.dtmfCollectSubmitDigit != null)
                        message.dtmfCollectSubmitDigit = String(object.dtmfCollectSubmitDigit);
                    if (object.dtmfCollectInterDigitTimeoutMS != null)
                        message.dtmfCollectInterDigitTimeoutMS = object.dtmfCollectInterDigitTimeoutMS | 0;
                    if (object.dtmfCollectMaxDigits != null)
                        message.dtmfCollectMaxDigits = object.dtmfCollectMaxDigits | 0;
                    if (object.bargeInOnDTMF != null)
                        message.bargeInOnDTMF = Boolean(object.bargeInOnDTMF);
                    if (object.bargeIn != null)
                        message.bargeIn = Boolean(object.bargeIn);
                    if (object.bargeInMinWordCount != null)
                        message.bargeInMinWordCount = object.bargeInMinWordCount | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACSessionParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {infinitusai.be.ACSessionParams} message ACSessionParams
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACSessionParams.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.dtmfCollect = false;
                        object.dtmfCollectSubmitDigit = "";
                        object.dtmfCollectInterDigitTimeoutMS = 0;
                        object.dtmfCollectMaxDigits = 0;
                        object.bargeInOnDTMF = false;
                        object.bargeIn = false;
                        object.bargeInMinWordCount = 0;
                    }
                    if (message.dtmfCollect != null && message.hasOwnProperty("dtmfCollect"))
                        object.dtmfCollect = message.dtmfCollect;
                    if (message.dtmfCollectSubmitDigit != null && message.hasOwnProperty("dtmfCollectSubmitDigit"))
                        object.dtmfCollectSubmitDigit = message.dtmfCollectSubmitDigit;
                    if (message.dtmfCollectInterDigitTimeoutMS != null && message.hasOwnProperty("dtmfCollectInterDigitTimeoutMS"))
                        object.dtmfCollectInterDigitTimeoutMS = message.dtmfCollectInterDigitTimeoutMS;
                    if (message.dtmfCollectMaxDigits != null && message.hasOwnProperty("dtmfCollectMaxDigits"))
                        object.dtmfCollectMaxDigits = message.dtmfCollectMaxDigits;
                    if (message.bargeInOnDTMF != null && message.hasOwnProperty("bargeInOnDTMF"))
                        object.bargeInOnDTMF = message.bargeInOnDTMF;
                    if (message.bargeIn != null && message.hasOwnProperty("bargeIn"))
                        object.bargeIn = message.bargeIn;
                    if (message.bargeInMinWordCount != null && message.hasOwnProperty("bargeInMinWordCount"))
                        object.bargeInMinWordCount = message.bargeInMinWordCount;
                    return object;
                };
    
                /**
                 * Converts this ACSessionParams to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACSessionParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACSessionParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACSessionParams
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACSessionParams
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACSessionParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACSessionParams";
                };
    
                return ACSessionParams;
            })();
    
            be.ACConversationActivityRequest = (function() {
    
                /**
                 * Properties of a ACConversationActivityRequest.
                 * @memberof infinitusai.be
                 * @interface IACConversationActivityRequest
                 * @property {string|null} [conversation] ACConversationActivityRequest conversation
                 * @property {Array.<infinitusai.be.IACConversationActivity>|null} [activities] ACConversationActivityRequest activities
                 */
    
                /**
                 * Constructs a new ACConversationActivityRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACConversationActivityRequest.
                 * @implements IACConversationActivityRequest
                 * @constructor
                 * @param {infinitusai.be.IACConversationActivityRequest=} [properties] Properties to set
                 */
                function ACConversationActivityRequest(properties) {
                    this.activities = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACConversationActivityRequest conversation.
                 * @member {string} conversation
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @instance
                 */
                ACConversationActivityRequest.prototype.conversation = "";
    
                /**
                 * ACConversationActivityRequest activities.
                 * @member {Array.<infinitusai.be.IACConversationActivity>} activities
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @instance
                 */
                ACConversationActivityRequest.prototype.activities = $util.emptyArray;
    
                /**
                 * Creates a new ACConversationActivityRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {infinitusai.be.IACConversationActivityRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ACConversationActivityRequest} ACConversationActivityRequest instance
                 */
                ACConversationActivityRequest.create = function create(properties) {
                    return new ACConversationActivityRequest(properties);
                };
    
                /**
                 * Encodes the specified ACConversationActivityRequest message. Does not implicitly {@link infinitusai.be.ACConversationActivityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {infinitusai.be.IACConversationActivityRequest} message ACConversationActivityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationActivityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversation != null && Object.hasOwnProperty.call(message, "conversation"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversation);
                    if (message.activities != null && message.activities.length)
                        for (var i = 0; i < message.activities.length; ++i)
                            $root.infinitusai.be.ACConversationActivity.encode(message.activities[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ACConversationActivityRequest message, length delimited. Does not implicitly {@link infinitusai.be.ACConversationActivityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {infinitusai.be.IACConversationActivityRequest} message ACConversationActivityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationActivityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACConversationActivityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACConversationActivityRequest} ACConversationActivityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationActivityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACConversationActivityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversation = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.activities && message.activities.length))
                                    message.activities = [];
                                message.activities.push($root.infinitusai.be.ACConversationActivity.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACConversationActivityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACConversationActivityRequest} ACConversationActivityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationActivityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACConversationActivityRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACConversationActivityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversation != null && message.hasOwnProperty("conversation"))
                        if (!$util.isString(message.conversation))
                            return "conversation: string expected";
                    if (message.activities != null && message.hasOwnProperty("activities")) {
                        if (!Array.isArray(message.activities))
                            return "activities: array expected";
                        for (var i = 0; i < message.activities.length; ++i) {
                            var error = $root.infinitusai.be.ACConversationActivity.verify(message.activities[i]);
                            if (error)
                                return "activities." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ACConversationActivityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACConversationActivityRequest} ACConversationActivityRequest
                 */
                ACConversationActivityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACConversationActivityRequest)
                        return object;
                    var message = new $root.infinitusai.be.ACConversationActivityRequest();
                    if (object.conversation != null)
                        message.conversation = String(object.conversation);
                    if (object.activities) {
                        if (!Array.isArray(object.activities))
                            throw TypeError(".infinitusai.be.ACConversationActivityRequest.activities: array expected");
                        message.activities = [];
                        for (var i = 0; i < object.activities.length; ++i) {
                            if (typeof object.activities[i] !== "object")
                                throw TypeError(".infinitusai.be.ACConversationActivityRequest.activities: object expected");
                            message.activities[i] = $root.infinitusai.be.ACConversationActivity.fromObject(object.activities[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACConversationActivityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {infinitusai.be.ACConversationActivityRequest} message ACConversationActivityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACConversationActivityRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.activities = [];
                    if (options.defaults)
                        object.conversation = "";
                    if (message.conversation != null && message.hasOwnProperty("conversation"))
                        object.conversation = message.conversation;
                    if (message.activities && message.activities.length) {
                        object.activities = [];
                        for (var j = 0; j < message.activities.length; ++j)
                            object.activities[j] = $root.infinitusai.be.ACConversationActivity.toObject(message.activities[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ACConversationActivityRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACConversationActivityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACConversationActivityRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACConversationActivityRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACConversationActivityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACConversationActivityRequest";
                };
    
                return ACConversationActivityRequest;
            })();
    
            be.ACConversationActivityResponse = (function() {
    
                /**
                 * Properties of a ACConversationActivityResponse.
                 * @memberof infinitusai.be
                 * @interface IACConversationActivityResponse
                 * @property {Array.<infinitusai.be.IACConversationActivity>|null} [activities] ACConversationActivityResponse activities
                 */
    
                /**
                 * Constructs a new ACConversationActivityResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACConversationActivityResponse.
                 * @implements IACConversationActivityResponse
                 * @constructor
                 * @param {infinitusai.be.IACConversationActivityResponse=} [properties] Properties to set
                 */
                function ACConversationActivityResponse(properties) {
                    this.activities = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACConversationActivityResponse activities.
                 * @member {Array.<infinitusai.be.IACConversationActivity>} activities
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @instance
                 */
                ACConversationActivityResponse.prototype.activities = $util.emptyArray;
    
                /**
                 * Creates a new ACConversationActivityResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {infinitusai.be.IACConversationActivityResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ACConversationActivityResponse} ACConversationActivityResponse instance
                 */
                ACConversationActivityResponse.create = function create(properties) {
                    return new ACConversationActivityResponse(properties);
                };
    
                /**
                 * Encodes the specified ACConversationActivityResponse message. Does not implicitly {@link infinitusai.be.ACConversationActivityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {infinitusai.be.IACConversationActivityResponse} message ACConversationActivityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationActivityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.activities != null && message.activities.length)
                        for (var i = 0; i < message.activities.length; ++i)
                            $root.infinitusai.be.ACConversationActivity.encode(message.activities[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ACConversationActivityResponse message, length delimited. Does not implicitly {@link infinitusai.be.ACConversationActivityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {infinitusai.be.IACConversationActivityResponse} message ACConversationActivityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACConversationActivityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACConversationActivityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACConversationActivityResponse} ACConversationActivityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationActivityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACConversationActivityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.activities && message.activities.length))
                                    message.activities = [];
                                message.activities.push($root.infinitusai.be.ACConversationActivity.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACConversationActivityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACConversationActivityResponse} ACConversationActivityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACConversationActivityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACConversationActivityResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACConversationActivityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.activities != null && message.hasOwnProperty("activities")) {
                        if (!Array.isArray(message.activities))
                            return "activities: array expected";
                        for (var i = 0; i < message.activities.length; ++i) {
                            var error = $root.infinitusai.be.ACConversationActivity.verify(message.activities[i]);
                            if (error)
                                return "activities." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ACConversationActivityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACConversationActivityResponse} ACConversationActivityResponse
                 */
                ACConversationActivityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACConversationActivityResponse)
                        return object;
                    var message = new $root.infinitusai.be.ACConversationActivityResponse();
                    if (object.activities) {
                        if (!Array.isArray(object.activities))
                            throw TypeError(".infinitusai.be.ACConversationActivityResponse.activities: array expected");
                        message.activities = [];
                        for (var i = 0; i < object.activities.length; ++i) {
                            if (typeof object.activities[i] !== "object")
                                throw TypeError(".infinitusai.be.ACConversationActivityResponse.activities: object expected");
                            message.activities[i] = $root.infinitusai.be.ACConversationActivity.fromObject(object.activities[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACConversationActivityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {infinitusai.be.ACConversationActivityResponse} message ACConversationActivityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACConversationActivityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.activities = [];
                    if (message.activities && message.activities.length) {
                        object.activities = [];
                        for (var j = 0; j < message.activities.length; ++j)
                            object.activities[j] = $root.infinitusai.be.ACConversationActivity.toObject(message.activities[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ACConversationActivityResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACConversationActivityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACConversationActivityResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACConversationActivityResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACConversationActivityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACConversationActivityResponse";
                };
    
                return ACConversationActivityResponse;
            })();
    
            be.ACHealthResponse = (function() {
    
                /**
                 * Properties of a ACHealthResponse.
                 * @memberof infinitusai.be
                 * @interface IACHealthResponse
                 * @property {string|null} [type] ACHealthResponse type
                 * @property {boolean|null} [success] ACHealthResponse success
                 */
    
                /**
                 * Constructs a new ACHealthResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ACHealthResponse.
                 * @implements IACHealthResponse
                 * @constructor
                 * @param {infinitusai.be.IACHealthResponse=} [properties] Properties to set
                 */
                function ACHealthResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ACHealthResponse type.
                 * @member {string} type
                 * @memberof infinitusai.be.ACHealthResponse
                 * @instance
                 */
                ACHealthResponse.prototype.type = "";
    
                /**
                 * ACHealthResponse success.
                 * @member {boolean} success
                 * @memberof infinitusai.be.ACHealthResponse
                 * @instance
                 */
                ACHealthResponse.prototype.success = false;
    
                /**
                 * Creates a new ACHealthResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {infinitusai.be.IACHealthResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ACHealthResponse} ACHealthResponse instance
                 */
                ACHealthResponse.create = function create(properties) {
                    return new ACHealthResponse(properties);
                };
    
                /**
                 * Encodes the specified ACHealthResponse message. Does not implicitly {@link infinitusai.be.ACHealthResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {infinitusai.be.IACHealthResponse} message ACHealthResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACHealthResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                    if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.success);
                    return writer;
                };
    
                /**
                 * Encodes the specified ACHealthResponse message, length delimited. Does not implicitly {@link infinitusai.be.ACHealthResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {infinitusai.be.IACHealthResponse} message ACHealthResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ACHealthResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ACHealthResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ACHealthResponse} ACHealthResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACHealthResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ACHealthResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.string();
                                break;
                            }
                        case 2: {
                                message.success = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ACHealthResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ACHealthResponse} ACHealthResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ACHealthResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ACHealthResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ACHealthResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.success != null && message.hasOwnProperty("success"))
                        if (typeof message.success !== "boolean")
                            return "success: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a ACHealthResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ACHealthResponse} ACHealthResponse
                 */
                ACHealthResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ACHealthResponse)
                        return object;
                    var message = new $root.infinitusai.be.ACHealthResponse();
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.success != null)
                        message.success = Boolean(object.success);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ACHealthResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {infinitusai.be.ACHealthResponse} message ACHealthResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ACHealthResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = "";
                        object.success = false;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.success != null && message.hasOwnProperty("success"))
                        object.success = message.success;
                    return object;
                };
    
                /**
                 * Converts this ACHealthResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ACHealthResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ACHealthResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ACHealthResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ACHealthResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ACHealthResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ACHealthResponse";
                };
    
                return ACHealthResponse;
            })();
    
            be.AudioProcessingService = (function() {
    
                /**
                 * Constructs a new AudioProcessingService service.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AudioProcessingService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AudioProcessingService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (AudioProcessingService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AudioProcessingService;
    
                /**
                 * Creates new AudioProcessingService service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.AudioProcessingService
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {AudioProcessingService} RPC service. Useful where requests and/or responses are streamed.
                 */
                AudioProcessingService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.AudioProcessingService#callAudioChunk}.
                 * @memberof infinitusai.be.AudioProcessingService
                 * @typedef CallAudioChunkCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.CallAudioChunkResponse} [response] CallAudioChunkResponse
                 */
    
                /**
                 * Calls CallAudioChunk.
                 * @function callAudioChunk
                 * @memberof infinitusai.be.AudioProcessingService
                 * @instance
                 * @param {infinitusai.be.ICallAudioChunkRequest} request CallAudioChunkRequest message or plain object
                 * @param {infinitusai.be.AudioProcessingService.CallAudioChunkCallback} callback Node-style callback called with the error, if any, and CallAudioChunkResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AudioProcessingService.prototype.callAudioChunk = function callAudioChunk(request, callback) {
                    return this.rpcCall(callAudioChunk, $root.infinitusai.be.CallAudioChunkRequest, $root.infinitusai.be.CallAudioChunkResponse, request, callback);
                }, "name", { value: "CallAudioChunk" });
    
                /**
                 * Calls CallAudioChunk.
                 * @function callAudioChunk
                 * @memberof infinitusai.be.AudioProcessingService
                 * @instance
                 * @param {infinitusai.be.ICallAudioChunkRequest} request CallAudioChunkRequest message or plain object
                 * @returns {Promise<infinitusai.be.CallAudioChunkResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.AudioProcessingService#getInferences}.
                 * @memberof infinitusai.be.AudioProcessingService
                 * @typedef GetInferencesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetInferencesResponse} [response] GetInferencesResponse
                 */
    
                /**
                 * Calls GetInferences.
                 * @function getInferences
                 * @memberof infinitusai.be.AudioProcessingService
                 * @instance
                 * @param {infinitusai.be.IGetInferencesRequest} request GetInferencesRequest message or plain object
                 * @param {infinitusai.be.AudioProcessingService.GetInferencesCallback} callback Node-style callback called with the error, if any, and GetInferencesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AudioProcessingService.prototype.getInferences = function getInferences(request, callback) {
                    return this.rpcCall(getInferences, $root.infinitusai.be.GetInferencesRequest, $root.infinitusai.be.GetInferencesResponse, request, callback);
                }, "name", { value: "GetInferences" });
    
                /**
                 * Calls GetInferences.
                 * @function getInferences
                 * @memberof infinitusai.be.AudioProcessingService
                 * @instance
                 * @param {infinitusai.be.IGetInferencesRequest} request GetInferencesRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetInferencesResponse>} Promise
                 * @variation 2
                 */
    
                return AudioProcessingService;
            })();
    
            be.CallAudioChunkRequest = (function() {
    
                /**
                 * Properties of a CallAudioChunkRequest.
                 * @memberof infinitusai.be
                 * @interface ICallAudioChunkRequest
                 * @property {string|null} [callUuid] CallAudioChunkRequest callUuid
                 * @property {Uint8Array|null} [audioChunk] CallAudioChunkRequest audioChunk
                 * @property {number|Long|null} [endMillis] CallAudioChunkRequest endMillis
                 * @property {number|Long|null} [audioEndByteTimestamp] CallAudioChunkRequest audioEndByteTimestamp
                 */
    
                /**
                 * Constructs a new CallAudioChunkRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallAudioChunkRequest.
                 * @implements ICallAudioChunkRequest
                 * @constructor
                 * @param {infinitusai.be.ICallAudioChunkRequest=} [properties] Properties to set
                 */
                function CallAudioChunkRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallAudioChunkRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @instance
                 */
                CallAudioChunkRequest.prototype.callUuid = "";
    
                /**
                 * CallAudioChunkRequest audioChunk.
                 * @member {Uint8Array} audioChunk
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @instance
                 */
                CallAudioChunkRequest.prototype.audioChunk = $util.newBuffer([]);
    
                /**
                 * CallAudioChunkRequest endMillis.
                 * @member {number|Long} endMillis
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @instance
                 */
                CallAudioChunkRequest.prototype.endMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallAudioChunkRequest audioEndByteTimestamp.
                 * @member {number|Long} audioEndByteTimestamp
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @instance
                 */
                CallAudioChunkRequest.prototype.audioEndByteTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new CallAudioChunkRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {infinitusai.be.ICallAudioChunkRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CallAudioChunkRequest} CallAudioChunkRequest instance
                 */
                CallAudioChunkRequest.create = function create(properties) {
                    return new CallAudioChunkRequest(properties);
                };
    
                /**
                 * Encodes the specified CallAudioChunkRequest message. Does not implicitly {@link infinitusai.be.CallAudioChunkRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {infinitusai.be.ICallAudioChunkRequest} message CallAudioChunkRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallAudioChunkRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.audioChunk != null && Object.hasOwnProperty.call(message, "audioChunk"))
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.audioChunk);
                    if (message.endMillis != null && Object.hasOwnProperty.call(message, "endMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endMillis);
                    if (message.audioEndByteTimestamp != null && Object.hasOwnProperty.call(message, "audioEndByteTimestamp"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.audioEndByteTimestamp);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallAudioChunkRequest message, length delimited. Does not implicitly {@link infinitusai.be.CallAudioChunkRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {infinitusai.be.ICallAudioChunkRequest} message CallAudioChunkRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallAudioChunkRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallAudioChunkRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallAudioChunkRequest} CallAudioChunkRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallAudioChunkRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallAudioChunkRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.audioChunk = reader.bytes();
                                break;
                            }
                        case 3: {
                                message.endMillis = reader.int64();
                                break;
                            }
                        case 4: {
                                message.audioEndByteTimestamp = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallAudioChunkRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallAudioChunkRequest} CallAudioChunkRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallAudioChunkRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallAudioChunkRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallAudioChunkRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.audioChunk != null && message.hasOwnProperty("audioChunk"))
                        if (!(message.audioChunk && typeof message.audioChunk.length === "number" || $util.isString(message.audioChunk)))
                            return "audioChunk: buffer expected";
                    if (message.endMillis != null && message.hasOwnProperty("endMillis"))
                        if (!$util.isInteger(message.endMillis) && !(message.endMillis && $util.isInteger(message.endMillis.low) && $util.isInteger(message.endMillis.high)))
                            return "endMillis: integer|Long expected";
                    if (message.audioEndByteTimestamp != null && message.hasOwnProperty("audioEndByteTimestamp"))
                        if (!$util.isInteger(message.audioEndByteTimestamp) && !(message.audioEndByteTimestamp && $util.isInteger(message.audioEndByteTimestamp.low) && $util.isInteger(message.audioEndByteTimestamp.high)))
                            return "audioEndByteTimestamp: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a CallAudioChunkRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallAudioChunkRequest} CallAudioChunkRequest
                 */
                CallAudioChunkRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallAudioChunkRequest)
                        return object;
                    var message = new $root.infinitusai.be.CallAudioChunkRequest();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.audioChunk != null)
                        if (typeof object.audioChunk === "string")
                            $util.base64.decode(object.audioChunk, message.audioChunk = $util.newBuffer($util.base64.length(object.audioChunk)), 0);
                        else if (object.audioChunk.length >= 0)
                            message.audioChunk = object.audioChunk;
                    if (object.endMillis != null)
                        if ($util.Long)
                            (message.endMillis = $util.Long.fromValue(object.endMillis)).unsigned = false;
                        else if (typeof object.endMillis === "string")
                            message.endMillis = parseInt(object.endMillis, 10);
                        else if (typeof object.endMillis === "number")
                            message.endMillis = object.endMillis;
                        else if (typeof object.endMillis === "object")
                            message.endMillis = new $util.LongBits(object.endMillis.low >>> 0, object.endMillis.high >>> 0).toNumber();
                    if (object.audioEndByteTimestamp != null)
                        if ($util.Long)
                            (message.audioEndByteTimestamp = $util.Long.fromValue(object.audioEndByteTimestamp)).unsigned = false;
                        else if (typeof object.audioEndByteTimestamp === "string")
                            message.audioEndByteTimestamp = parseInt(object.audioEndByteTimestamp, 10);
                        else if (typeof object.audioEndByteTimestamp === "number")
                            message.audioEndByteTimestamp = object.audioEndByteTimestamp;
                        else if (typeof object.audioEndByteTimestamp === "object")
                            message.audioEndByteTimestamp = new $util.LongBits(object.audioEndByteTimestamp.low >>> 0, object.audioEndByteTimestamp.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallAudioChunkRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {infinitusai.be.CallAudioChunkRequest} message CallAudioChunkRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallAudioChunkRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callUuid = "";
                        if (options.bytes === String)
                            object.audioChunk = "";
                        else {
                            object.audioChunk = [];
                            if (options.bytes !== Array)
                                object.audioChunk = $util.newBuffer(object.audioChunk);
                        }
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.audioEndByteTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.audioEndByteTimestamp = options.longs === String ? "0" : 0;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.audioChunk != null && message.hasOwnProperty("audioChunk"))
                        object.audioChunk = options.bytes === String ? $util.base64.encode(message.audioChunk, 0, message.audioChunk.length) : options.bytes === Array ? Array.prototype.slice.call(message.audioChunk) : message.audioChunk;
                    if (message.endMillis != null && message.hasOwnProperty("endMillis"))
                        if (typeof message.endMillis === "number")
                            object.endMillis = options.longs === String ? String(message.endMillis) : message.endMillis;
                        else
                            object.endMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endMillis) : options.longs === Number ? new $util.LongBits(message.endMillis.low >>> 0, message.endMillis.high >>> 0).toNumber() : message.endMillis;
                    if (message.audioEndByteTimestamp != null && message.hasOwnProperty("audioEndByteTimestamp"))
                        if (typeof message.audioEndByteTimestamp === "number")
                            object.audioEndByteTimestamp = options.longs === String ? String(message.audioEndByteTimestamp) : message.audioEndByteTimestamp;
                        else
                            object.audioEndByteTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.audioEndByteTimestamp) : options.longs === Number ? new $util.LongBits(message.audioEndByteTimestamp.low >>> 0, message.audioEndByteTimestamp.high >>> 0).toNumber() : message.audioEndByteTimestamp;
                    return object;
                };
    
                /**
                 * Converts this CallAudioChunkRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallAudioChunkRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallAudioChunkRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallAudioChunkRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallAudioChunkRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallAudioChunkRequest";
                };
    
                return CallAudioChunkRequest;
            })();
    
            be.CallAudioChunkResponse = (function() {
    
                /**
                 * Properties of a CallAudioChunkResponse.
                 * @memberof infinitusai.be
                 * @interface ICallAudioChunkResponse
                 */
    
                /**
                 * Constructs a new CallAudioChunkResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallAudioChunkResponse.
                 * @implements ICallAudioChunkResponse
                 * @constructor
                 * @param {infinitusai.be.ICallAudioChunkResponse=} [properties] Properties to set
                 */
                function CallAudioChunkResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CallAudioChunkResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {infinitusai.be.ICallAudioChunkResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CallAudioChunkResponse} CallAudioChunkResponse instance
                 */
                CallAudioChunkResponse.create = function create(properties) {
                    return new CallAudioChunkResponse(properties);
                };
    
                /**
                 * Encodes the specified CallAudioChunkResponse message. Does not implicitly {@link infinitusai.be.CallAudioChunkResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {infinitusai.be.ICallAudioChunkResponse} message CallAudioChunkResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallAudioChunkResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallAudioChunkResponse message, length delimited. Does not implicitly {@link infinitusai.be.CallAudioChunkResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {infinitusai.be.ICallAudioChunkResponse} message CallAudioChunkResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallAudioChunkResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallAudioChunkResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallAudioChunkResponse} CallAudioChunkResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallAudioChunkResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallAudioChunkResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallAudioChunkResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallAudioChunkResponse} CallAudioChunkResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallAudioChunkResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallAudioChunkResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallAudioChunkResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CallAudioChunkResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallAudioChunkResponse} CallAudioChunkResponse
                 */
                CallAudioChunkResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallAudioChunkResponse)
                        return object;
                    return new $root.infinitusai.be.CallAudioChunkResponse();
                };
    
                /**
                 * Creates a plain object from a CallAudioChunkResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {infinitusai.be.CallAudioChunkResponse} message CallAudioChunkResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallAudioChunkResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CallAudioChunkResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallAudioChunkResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallAudioChunkResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallAudioChunkResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallAudioChunkResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallAudioChunkResponse";
                };
    
                return CallAudioChunkResponse;
            })();
    
            /**
             * ModelType enum.
             * @name infinitusai.be.ModelType
             * @enum {number}
             * @property {number} UNKNOWN_MODEL_TYPE=0 UNKNOWN_MODEL_TYPE value
             * @property {number} PHARMACY_HOLD_AUDIO_CLASSIFIER=1 PHARMACY_HOLD_AUDIO_CLASSIFIER value
             * @property {number} PHARMACY_HOLD_WHISPER_AUDIO_CLASSIFIER=2 PHARMACY_HOLD_WHISPER_AUDIO_CLASSIFIER value
             */
            be.ModelType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_MODEL_TYPE"] = 0;
                values[valuesById[1] = "PHARMACY_HOLD_AUDIO_CLASSIFIER"] = 1;
                values[valuesById[2] = "PHARMACY_HOLD_WHISPER_AUDIO_CLASSIFIER"] = 2;
                return values;
            })();
    
            /**
             * ModelInferenceResult enum.
             * @name infinitusai.be.ModelInferenceResult
             * @enum {number}
             * @property {number} UNKNOWN_MODEL_INFERENCE_RESULT=0 UNKNOWN_MODEL_INFERENCE_RESULT value
             * @property {number} AGENT_PICK_UP=1 AGENT_PICK_UP value
             * @property {number} AUTOMATED_SYSTEM=2 AUTOMATED_SYSTEM value
             * @property {number} MUSIC=3 MUSIC value
             * @property {number} SILENCE=4 SILENCE value
             * @property {number} DIAL_TONE=5 DIAL_TONE value
             */
            be.ModelInferenceResult = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_MODEL_INFERENCE_RESULT"] = 0;
                values[valuesById[1] = "AGENT_PICK_UP"] = 1;
                values[valuesById[2] = "AUTOMATED_SYSTEM"] = 2;
                values[valuesById[3] = "MUSIC"] = 3;
                values[valuesById[4] = "SILENCE"] = 4;
                values[valuesById[5] = "DIAL_TONE"] = 5;
                return values;
            })();
    
            be.InferenceConfidence = (function() {
    
                /**
                 * Properties of an InferenceConfidence.
                 * @memberof infinitusai.be
                 * @interface IInferenceConfidence
                 * @property {infinitusai.be.ModelInferenceResult|null} [modelInferenceResult] InferenceConfidence modelInferenceResult
                 * @property {number|null} [confidence] InferenceConfidence confidence
                 */
    
                /**
                 * Constructs a new InferenceConfidence.
                 * @memberof infinitusai.be
                 * @classdesc Represents an InferenceConfidence.
                 * @implements IInferenceConfidence
                 * @constructor
                 * @param {infinitusai.be.IInferenceConfidence=} [properties] Properties to set
                 */
                function InferenceConfidence(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * InferenceConfidence modelInferenceResult.
                 * @member {infinitusai.be.ModelInferenceResult} modelInferenceResult
                 * @memberof infinitusai.be.InferenceConfidence
                 * @instance
                 */
                InferenceConfidence.prototype.modelInferenceResult = 0;
    
                /**
                 * InferenceConfidence confidence.
                 * @member {number} confidence
                 * @memberof infinitusai.be.InferenceConfidence
                 * @instance
                 */
                InferenceConfidence.prototype.confidence = 0;
    
                /**
                 * Creates a new InferenceConfidence instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {infinitusai.be.IInferenceConfidence=} [properties] Properties to set
                 * @returns {infinitusai.be.InferenceConfidence} InferenceConfidence instance
                 */
                InferenceConfidence.create = function create(properties) {
                    return new InferenceConfidence(properties);
                };
    
                /**
                 * Encodes the specified InferenceConfidence message. Does not implicitly {@link infinitusai.be.InferenceConfidence.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {infinitusai.be.IInferenceConfidence} message InferenceConfidence message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InferenceConfidence.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.modelInferenceResult != null && Object.hasOwnProperty.call(message, "modelInferenceResult"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.modelInferenceResult);
                    if (message.confidence != null && Object.hasOwnProperty.call(message, "confidence"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.confidence);
                    return writer;
                };
    
                /**
                 * Encodes the specified InferenceConfidence message, length delimited. Does not implicitly {@link infinitusai.be.InferenceConfidence.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {infinitusai.be.IInferenceConfidence} message InferenceConfidence message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InferenceConfidence.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an InferenceConfidence message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.InferenceConfidence} InferenceConfidence
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InferenceConfidence.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.InferenceConfidence();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.modelInferenceResult = reader.int32();
                                break;
                            }
                        case 2: {
                                message.confidence = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an InferenceConfidence message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.InferenceConfidence} InferenceConfidence
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InferenceConfidence.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an InferenceConfidence message.
                 * @function verify
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InferenceConfidence.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.modelInferenceResult != null && message.hasOwnProperty("modelInferenceResult"))
                        switch (message.modelInferenceResult) {
                        default:
                            return "modelInferenceResult: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        if (typeof message.confidence !== "number")
                            return "confidence: number expected";
                    return null;
                };
    
                /**
                 * Creates an InferenceConfidence message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.InferenceConfidence} InferenceConfidence
                 */
                InferenceConfidence.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.InferenceConfidence)
                        return object;
                    var message = new $root.infinitusai.be.InferenceConfidence();
                    switch (object.modelInferenceResult) {
                    default:
                        if (typeof object.modelInferenceResult === "number") {
                            message.modelInferenceResult = object.modelInferenceResult;
                            break;
                        }
                        break;
                    case "UNKNOWN_MODEL_INFERENCE_RESULT":
                    case 0:
                        message.modelInferenceResult = 0;
                        break;
                    case "AGENT_PICK_UP":
                    case 1:
                        message.modelInferenceResult = 1;
                        break;
                    case "AUTOMATED_SYSTEM":
                    case 2:
                        message.modelInferenceResult = 2;
                        break;
                    case "MUSIC":
                    case 3:
                        message.modelInferenceResult = 3;
                        break;
                    case "SILENCE":
                    case 4:
                        message.modelInferenceResult = 4;
                        break;
                    case "DIAL_TONE":
                    case 5:
                        message.modelInferenceResult = 5;
                        break;
                    }
                    if (object.confidence != null)
                        message.confidence = Number(object.confidence);
                    return message;
                };
    
                /**
                 * Creates a plain object from an InferenceConfidence message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {infinitusai.be.InferenceConfidence} message InferenceConfidence
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InferenceConfidence.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.modelInferenceResult = options.enums === String ? "UNKNOWN_MODEL_INFERENCE_RESULT" : 0;
                        object.confidence = 0;
                    }
                    if (message.modelInferenceResult != null && message.hasOwnProperty("modelInferenceResult"))
                        object.modelInferenceResult = options.enums === String ? $root.infinitusai.be.ModelInferenceResult[message.modelInferenceResult] === undefined ? message.modelInferenceResult : $root.infinitusai.be.ModelInferenceResult[message.modelInferenceResult] : message.modelInferenceResult;
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        object.confidence = options.json && !isFinite(message.confidence) ? String(message.confidence) : message.confidence;
                    return object;
                };
    
                /**
                 * Converts this InferenceConfidence to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.InferenceConfidence
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InferenceConfidence.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for InferenceConfidence
                 * @function getTypeUrl
                 * @memberof infinitusai.be.InferenceConfidence
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InferenceConfidence.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.InferenceConfidence";
                };
    
                return InferenceConfidence;
            })();
    
            be.InferenceIteration = (function() {
    
                /**
                 * Properties of an InferenceIteration.
                 * @memberof infinitusai.be
                 * @interface IInferenceIteration
                 * @property {Array.<infinitusai.be.IInferenceConfidence>|null} [inferenceConfidences] InferenceIteration inferenceConfidences
                 * @property {number|Long|null} [startTimestamp] InferenceIteration startTimestamp
                 * @property {number|Long|null} [endTimestamp] InferenceIteration endTimestamp
                 */
    
                /**
                 * Constructs a new InferenceIteration.
                 * @memberof infinitusai.be
                 * @classdesc Represents an InferenceIteration.
                 * @implements IInferenceIteration
                 * @constructor
                 * @param {infinitusai.be.IInferenceIteration=} [properties] Properties to set
                 */
                function InferenceIteration(properties) {
                    this.inferenceConfidences = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * InferenceIteration inferenceConfidences.
                 * @member {Array.<infinitusai.be.IInferenceConfidence>} inferenceConfidences
                 * @memberof infinitusai.be.InferenceIteration
                 * @instance
                 */
                InferenceIteration.prototype.inferenceConfidences = $util.emptyArray;
    
                /**
                 * InferenceIteration startTimestamp.
                 * @member {number|Long} startTimestamp
                 * @memberof infinitusai.be.InferenceIteration
                 * @instance
                 */
                InferenceIteration.prototype.startTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * InferenceIteration endTimestamp.
                 * @member {number|Long} endTimestamp
                 * @memberof infinitusai.be.InferenceIteration
                 * @instance
                 */
                InferenceIteration.prototype.endTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new InferenceIteration instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {infinitusai.be.IInferenceIteration=} [properties] Properties to set
                 * @returns {infinitusai.be.InferenceIteration} InferenceIteration instance
                 */
                InferenceIteration.create = function create(properties) {
                    return new InferenceIteration(properties);
                };
    
                /**
                 * Encodes the specified InferenceIteration message. Does not implicitly {@link infinitusai.be.InferenceIteration.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {infinitusai.be.IInferenceIteration} message InferenceIteration message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InferenceIteration.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inferenceConfidences != null && message.inferenceConfidences.length)
                        for (var i = 0; i < message.inferenceConfidences.length; ++i)
                            $root.infinitusai.be.InferenceConfidence.encode(message.inferenceConfidences[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.startTimestamp != null && Object.hasOwnProperty.call(message, "startTimestamp"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimestamp);
                    if (message.endTimestamp != null && Object.hasOwnProperty.call(message, "endTimestamp"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimestamp);
                    return writer;
                };
    
                /**
                 * Encodes the specified InferenceIteration message, length delimited. Does not implicitly {@link infinitusai.be.InferenceIteration.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {infinitusai.be.IInferenceIteration} message InferenceIteration message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InferenceIteration.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an InferenceIteration message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.InferenceIteration} InferenceIteration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InferenceIteration.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.InferenceIteration();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.inferenceConfidences && message.inferenceConfidences.length))
                                    message.inferenceConfidences = [];
                                message.inferenceConfidences.push($root.infinitusai.be.InferenceConfidence.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.startTimestamp = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimestamp = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an InferenceIteration message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.InferenceIteration} InferenceIteration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InferenceIteration.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an InferenceIteration message.
                 * @function verify
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InferenceIteration.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inferenceConfidences != null && message.hasOwnProperty("inferenceConfidences")) {
                        if (!Array.isArray(message.inferenceConfidences))
                            return "inferenceConfidences: array expected";
                        for (var i = 0; i < message.inferenceConfidences.length; ++i) {
                            var error = $root.infinitusai.be.InferenceConfidence.verify(message.inferenceConfidences[i]);
                            if (error)
                                return "inferenceConfidences." + error;
                        }
                    }
                    if (message.startTimestamp != null && message.hasOwnProperty("startTimestamp"))
                        if (!$util.isInteger(message.startTimestamp) && !(message.startTimestamp && $util.isInteger(message.startTimestamp.low) && $util.isInteger(message.startTimestamp.high)))
                            return "startTimestamp: integer|Long expected";
                    if (message.endTimestamp != null && message.hasOwnProperty("endTimestamp"))
                        if (!$util.isInteger(message.endTimestamp) && !(message.endTimestamp && $util.isInteger(message.endTimestamp.low) && $util.isInteger(message.endTimestamp.high)))
                            return "endTimestamp: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an InferenceIteration message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.InferenceIteration} InferenceIteration
                 */
                InferenceIteration.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.InferenceIteration)
                        return object;
                    var message = new $root.infinitusai.be.InferenceIteration();
                    if (object.inferenceConfidences) {
                        if (!Array.isArray(object.inferenceConfidences))
                            throw TypeError(".infinitusai.be.InferenceIteration.inferenceConfidences: array expected");
                        message.inferenceConfidences = [];
                        for (var i = 0; i < object.inferenceConfidences.length; ++i) {
                            if (typeof object.inferenceConfidences[i] !== "object")
                                throw TypeError(".infinitusai.be.InferenceIteration.inferenceConfidences: object expected");
                            message.inferenceConfidences[i] = $root.infinitusai.be.InferenceConfidence.fromObject(object.inferenceConfidences[i]);
                        }
                    }
                    if (object.startTimestamp != null)
                        if ($util.Long)
                            (message.startTimestamp = $util.Long.fromValue(object.startTimestamp)).unsigned = false;
                        else if (typeof object.startTimestamp === "string")
                            message.startTimestamp = parseInt(object.startTimestamp, 10);
                        else if (typeof object.startTimestamp === "number")
                            message.startTimestamp = object.startTimestamp;
                        else if (typeof object.startTimestamp === "object")
                            message.startTimestamp = new $util.LongBits(object.startTimestamp.low >>> 0, object.startTimestamp.high >>> 0).toNumber();
                    if (object.endTimestamp != null)
                        if ($util.Long)
                            (message.endTimestamp = $util.Long.fromValue(object.endTimestamp)).unsigned = false;
                        else if (typeof object.endTimestamp === "string")
                            message.endTimestamp = parseInt(object.endTimestamp, 10);
                        else if (typeof object.endTimestamp === "number")
                            message.endTimestamp = object.endTimestamp;
                        else if (typeof object.endTimestamp === "object")
                            message.endTimestamp = new $util.LongBits(object.endTimestamp.low >>> 0, object.endTimestamp.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an InferenceIteration message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {infinitusai.be.InferenceIteration} message InferenceIteration
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InferenceIteration.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.inferenceConfidences = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimestamp = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimestamp = options.longs === String ? "0" : 0;
                    }
                    if (message.inferenceConfidences && message.inferenceConfidences.length) {
                        object.inferenceConfidences = [];
                        for (var j = 0; j < message.inferenceConfidences.length; ++j)
                            object.inferenceConfidences[j] = $root.infinitusai.be.InferenceConfidence.toObject(message.inferenceConfidences[j], options);
                    }
                    if (message.startTimestamp != null && message.hasOwnProperty("startTimestamp"))
                        if (typeof message.startTimestamp === "number")
                            object.startTimestamp = options.longs === String ? String(message.startTimestamp) : message.startTimestamp;
                        else
                            object.startTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.startTimestamp) : options.longs === Number ? new $util.LongBits(message.startTimestamp.low >>> 0, message.startTimestamp.high >>> 0).toNumber() : message.startTimestamp;
                    if (message.endTimestamp != null && message.hasOwnProperty("endTimestamp"))
                        if (typeof message.endTimestamp === "number")
                            object.endTimestamp = options.longs === String ? String(message.endTimestamp) : message.endTimestamp;
                        else
                            object.endTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.endTimestamp) : options.longs === Number ? new $util.LongBits(message.endTimestamp.low >>> 0, message.endTimestamp.high >>> 0).toNumber() : message.endTimestamp;
                    return object;
                };
    
                /**
                 * Converts this InferenceIteration to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.InferenceIteration
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InferenceIteration.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for InferenceIteration
                 * @function getTypeUrl
                 * @memberof infinitusai.be.InferenceIteration
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InferenceIteration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.InferenceIteration";
                };
    
                return InferenceIteration;
            })();
    
            be.ModelPastInferences = (function() {
    
                /**
                 * Properties of a ModelPastInferences.
                 * @memberof infinitusai.be
                 * @interface IModelPastInferences
                 * @property {infinitusai.be.ModelType|null} [modelType] ModelPastInferences modelType
                 * @property {Array.<infinitusai.be.IInferenceIteration>|null} [pastInferences] ModelPastInferences pastInferences
                 */
    
                /**
                 * Constructs a new ModelPastInferences.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ModelPastInferences.
                 * @implements IModelPastInferences
                 * @constructor
                 * @param {infinitusai.be.IModelPastInferences=} [properties] Properties to set
                 */
                function ModelPastInferences(properties) {
                    this.pastInferences = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ModelPastInferences modelType.
                 * @member {infinitusai.be.ModelType} modelType
                 * @memberof infinitusai.be.ModelPastInferences
                 * @instance
                 */
                ModelPastInferences.prototype.modelType = 0;
    
                /**
                 * ModelPastInferences pastInferences.
                 * @member {Array.<infinitusai.be.IInferenceIteration>} pastInferences
                 * @memberof infinitusai.be.ModelPastInferences
                 * @instance
                 */
                ModelPastInferences.prototype.pastInferences = $util.emptyArray;
    
                /**
                 * Creates a new ModelPastInferences instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {infinitusai.be.IModelPastInferences=} [properties] Properties to set
                 * @returns {infinitusai.be.ModelPastInferences} ModelPastInferences instance
                 */
                ModelPastInferences.create = function create(properties) {
                    return new ModelPastInferences(properties);
                };
    
                /**
                 * Encodes the specified ModelPastInferences message. Does not implicitly {@link infinitusai.be.ModelPastInferences.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {infinitusai.be.IModelPastInferences} message ModelPastInferences message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ModelPastInferences.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.modelType != null && Object.hasOwnProperty.call(message, "modelType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.modelType);
                    if (message.pastInferences != null && message.pastInferences.length)
                        for (var i = 0; i < message.pastInferences.length; ++i)
                            $root.infinitusai.be.InferenceIteration.encode(message.pastInferences[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ModelPastInferences message, length delimited. Does not implicitly {@link infinitusai.be.ModelPastInferences.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {infinitusai.be.IModelPastInferences} message ModelPastInferences message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ModelPastInferences.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ModelPastInferences message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ModelPastInferences} ModelPastInferences
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ModelPastInferences.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ModelPastInferences();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.modelType = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.pastInferences && message.pastInferences.length))
                                    message.pastInferences = [];
                                message.pastInferences.push($root.infinitusai.be.InferenceIteration.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ModelPastInferences message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ModelPastInferences} ModelPastInferences
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ModelPastInferences.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ModelPastInferences message.
                 * @function verify
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ModelPastInferences.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.modelType != null && message.hasOwnProperty("modelType"))
                        switch (message.modelType) {
                        default:
                            return "modelType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.pastInferences != null && message.hasOwnProperty("pastInferences")) {
                        if (!Array.isArray(message.pastInferences))
                            return "pastInferences: array expected";
                        for (var i = 0; i < message.pastInferences.length; ++i) {
                            var error = $root.infinitusai.be.InferenceIteration.verify(message.pastInferences[i]);
                            if (error)
                                return "pastInferences." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ModelPastInferences message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ModelPastInferences} ModelPastInferences
                 */
                ModelPastInferences.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ModelPastInferences)
                        return object;
                    var message = new $root.infinitusai.be.ModelPastInferences();
                    switch (object.modelType) {
                    default:
                        if (typeof object.modelType === "number") {
                            message.modelType = object.modelType;
                            break;
                        }
                        break;
                    case "UNKNOWN_MODEL_TYPE":
                    case 0:
                        message.modelType = 0;
                        break;
                    case "PHARMACY_HOLD_AUDIO_CLASSIFIER":
                    case 1:
                        message.modelType = 1;
                        break;
                    case "PHARMACY_HOLD_WHISPER_AUDIO_CLASSIFIER":
                    case 2:
                        message.modelType = 2;
                        break;
                    }
                    if (object.pastInferences) {
                        if (!Array.isArray(object.pastInferences))
                            throw TypeError(".infinitusai.be.ModelPastInferences.pastInferences: array expected");
                        message.pastInferences = [];
                        for (var i = 0; i < object.pastInferences.length; ++i) {
                            if (typeof object.pastInferences[i] !== "object")
                                throw TypeError(".infinitusai.be.ModelPastInferences.pastInferences: object expected");
                            message.pastInferences[i] = $root.infinitusai.be.InferenceIteration.fromObject(object.pastInferences[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ModelPastInferences message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {infinitusai.be.ModelPastInferences} message ModelPastInferences
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ModelPastInferences.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.pastInferences = [];
                    if (options.defaults)
                        object.modelType = options.enums === String ? "UNKNOWN_MODEL_TYPE" : 0;
                    if (message.modelType != null && message.hasOwnProperty("modelType"))
                        object.modelType = options.enums === String ? $root.infinitusai.be.ModelType[message.modelType] === undefined ? message.modelType : $root.infinitusai.be.ModelType[message.modelType] : message.modelType;
                    if (message.pastInferences && message.pastInferences.length) {
                        object.pastInferences = [];
                        for (var j = 0; j < message.pastInferences.length; ++j)
                            object.pastInferences[j] = $root.infinitusai.be.InferenceIteration.toObject(message.pastInferences[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ModelPastInferences to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ModelPastInferences
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ModelPastInferences.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ModelPastInferences
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ModelPastInferences
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ModelPastInferences.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ModelPastInferences";
                };
    
                return ModelPastInferences;
            })();
    
            be.GetInferencesRequest = (function() {
    
                /**
                 * Properties of a GetInferencesRequest.
                 * @memberof infinitusai.be
                 * @interface IGetInferencesRequest
                 * @property {string|null} [callUuid] GetInferencesRequest callUuid
                 */
    
                /**
                 * Constructs a new GetInferencesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetInferencesRequest.
                 * @implements IGetInferencesRequest
                 * @constructor
                 * @param {infinitusai.be.IGetInferencesRequest=} [properties] Properties to set
                 */
                function GetInferencesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetInferencesRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @instance
                 */
                GetInferencesRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new GetInferencesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {infinitusai.be.IGetInferencesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetInferencesRequest} GetInferencesRequest instance
                 */
                GetInferencesRequest.create = function create(properties) {
                    return new GetInferencesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetInferencesRequest message. Does not implicitly {@link infinitusai.be.GetInferencesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {infinitusai.be.IGetInferencesRequest} message GetInferencesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInferencesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetInferencesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetInferencesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {infinitusai.be.IGetInferencesRequest} message GetInferencesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInferencesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetInferencesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetInferencesRequest} GetInferencesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInferencesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetInferencesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetInferencesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetInferencesRequest} GetInferencesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInferencesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetInferencesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInferencesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetInferencesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetInferencesRequest} GetInferencesRequest
                 */
                GetInferencesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetInferencesRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetInferencesRequest();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetInferencesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {infinitusai.be.GetInferencesRequest} message GetInferencesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInferencesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.callUuid = "";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this GetInferencesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInferencesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetInferencesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetInferencesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInferencesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetInferencesRequest";
                };
    
                return GetInferencesRequest;
            })();
    
            be.GetInferencesResponse = (function() {
    
                /**
                 * Properties of a GetInferencesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetInferencesResponse
                 * @property {Array.<infinitusai.be.IModelPastInferences>|null} [modelPastInferences] GetInferencesResponse modelPastInferences
                 */
    
                /**
                 * Constructs a new GetInferencesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetInferencesResponse.
                 * @implements IGetInferencesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetInferencesResponse=} [properties] Properties to set
                 */
                function GetInferencesResponse(properties) {
                    this.modelPastInferences = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetInferencesResponse modelPastInferences.
                 * @member {Array.<infinitusai.be.IModelPastInferences>} modelPastInferences
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @instance
                 */
                GetInferencesResponse.prototype.modelPastInferences = $util.emptyArray;
    
                /**
                 * Creates a new GetInferencesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {infinitusai.be.IGetInferencesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetInferencesResponse} GetInferencesResponse instance
                 */
                GetInferencesResponse.create = function create(properties) {
                    return new GetInferencesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetInferencesResponse message. Does not implicitly {@link infinitusai.be.GetInferencesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {infinitusai.be.IGetInferencesResponse} message GetInferencesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInferencesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.modelPastInferences != null && message.modelPastInferences.length)
                        for (var i = 0; i < message.modelPastInferences.length; ++i)
                            $root.infinitusai.be.ModelPastInferences.encode(message.modelPastInferences[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetInferencesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetInferencesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {infinitusai.be.IGetInferencesResponse} message GetInferencesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInferencesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetInferencesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetInferencesResponse} GetInferencesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInferencesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetInferencesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.modelPastInferences && message.modelPastInferences.length))
                                    message.modelPastInferences = [];
                                message.modelPastInferences.push($root.infinitusai.be.ModelPastInferences.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetInferencesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetInferencesResponse} GetInferencesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInferencesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetInferencesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInferencesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.modelPastInferences != null && message.hasOwnProperty("modelPastInferences")) {
                        if (!Array.isArray(message.modelPastInferences))
                            return "modelPastInferences: array expected";
                        for (var i = 0; i < message.modelPastInferences.length; ++i) {
                            var error = $root.infinitusai.be.ModelPastInferences.verify(message.modelPastInferences[i]);
                            if (error)
                                return "modelPastInferences." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetInferencesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetInferencesResponse} GetInferencesResponse
                 */
                GetInferencesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetInferencesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetInferencesResponse();
                    if (object.modelPastInferences) {
                        if (!Array.isArray(object.modelPastInferences))
                            throw TypeError(".infinitusai.be.GetInferencesResponse.modelPastInferences: array expected");
                        message.modelPastInferences = [];
                        for (var i = 0; i < object.modelPastInferences.length; ++i) {
                            if (typeof object.modelPastInferences[i] !== "object")
                                throw TypeError(".infinitusai.be.GetInferencesResponse.modelPastInferences: object expected");
                            message.modelPastInferences[i] = $root.infinitusai.be.ModelPastInferences.fromObject(object.modelPastInferences[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetInferencesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {infinitusai.be.GetInferencesResponse} message GetInferencesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInferencesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.modelPastInferences = [];
                    if (message.modelPastInferences && message.modelPastInferences.length) {
                        object.modelPastInferences = [];
                        for (var j = 0; j < message.modelPastInferences.length; ++j)
                            object.modelPastInferences[j] = $root.infinitusai.be.ModelPastInferences.toObject(message.modelPastInferences[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetInferencesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInferencesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetInferencesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetInferencesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInferencesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetInferencesResponse";
                };
    
                return GetInferencesResponse;
            })();
    
            be.GeneratedAudio = (function() {
    
                /**
                 * Properties of a GeneratedAudio.
                 * @memberof infinitusai.be
                 * @interface IGeneratedAudio
                 * @property {number|Long|null} [generatedAtMillis] GeneratedAudio generatedAtMillis
                 * @property {string|null} [ssml] GeneratedAudio ssml
                 * @property {Uint8Array|null} [audio] GeneratedAudio audio
                 */
    
                /**
                 * Constructs a new GeneratedAudio.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GeneratedAudio.
                 * @implements IGeneratedAudio
                 * @constructor
                 * @param {infinitusai.be.IGeneratedAudio=} [properties] Properties to set
                 */
                function GeneratedAudio(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GeneratedAudio generatedAtMillis.
                 * @member {number|Long} generatedAtMillis
                 * @memberof infinitusai.be.GeneratedAudio
                 * @instance
                 */
                GeneratedAudio.prototype.generatedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GeneratedAudio ssml.
                 * @member {string} ssml
                 * @memberof infinitusai.be.GeneratedAudio
                 * @instance
                 */
                GeneratedAudio.prototype.ssml = "";
    
                /**
                 * GeneratedAudio audio.
                 * @member {Uint8Array} audio
                 * @memberof infinitusai.be.GeneratedAudio
                 * @instance
                 */
                GeneratedAudio.prototype.audio = $util.newBuffer([]);
    
                /**
                 * Creates a new GeneratedAudio instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {infinitusai.be.IGeneratedAudio=} [properties] Properties to set
                 * @returns {infinitusai.be.GeneratedAudio} GeneratedAudio instance
                 */
                GeneratedAudio.create = function create(properties) {
                    return new GeneratedAudio(properties);
                };
    
                /**
                 * Encodes the specified GeneratedAudio message. Does not implicitly {@link infinitusai.be.GeneratedAudio.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {infinitusai.be.IGeneratedAudio} message GeneratedAudio message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedAudio.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.generatedAtMillis != null && Object.hasOwnProperty.call(message, "generatedAtMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.generatedAtMillis);
                    if (message.ssml != null && Object.hasOwnProperty.call(message, "ssml"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.ssml);
                    if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                        writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.audio);
                    return writer;
                };
    
                /**
                 * Encodes the specified GeneratedAudio message, length delimited. Does not implicitly {@link infinitusai.be.GeneratedAudio.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {infinitusai.be.IGeneratedAudio} message GeneratedAudio message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedAudio.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GeneratedAudio message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GeneratedAudio} GeneratedAudio
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedAudio.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GeneratedAudio();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.generatedAtMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                message.ssml = reader.string();
                                break;
                            }
                        case 3: {
                                message.audio = reader.bytes();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GeneratedAudio message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GeneratedAudio} GeneratedAudio
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedAudio.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GeneratedAudio message.
                 * @function verify
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GeneratedAudio.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (!$util.isInteger(message.generatedAtMillis) && !(message.generatedAtMillis && $util.isInteger(message.generatedAtMillis.low) && $util.isInteger(message.generatedAtMillis.high)))
                            return "generatedAtMillis: integer|Long expected";
                    if (message.ssml != null && message.hasOwnProperty("ssml"))
                        if (!$util.isString(message.ssml))
                            return "ssml: string expected";
                    if (message.audio != null && message.hasOwnProperty("audio"))
                        if (!(message.audio && typeof message.audio.length === "number" || $util.isString(message.audio)))
                            return "audio: buffer expected";
                    return null;
                };
    
                /**
                 * Creates a GeneratedAudio message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GeneratedAudio} GeneratedAudio
                 */
                GeneratedAudio.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GeneratedAudio)
                        return object;
                    var message = new $root.infinitusai.be.GeneratedAudio();
                    if (object.generatedAtMillis != null)
                        if ($util.Long)
                            (message.generatedAtMillis = $util.Long.fromValue(object.generatedAtMillis)).unsigned = false;
                        else if (typeof object.generatedAtMillis === "string")
                            message.generatedAtMillis = parseInt(object.generatedAtMillis, 10);
                        else if (typeof object.generatedAtMillis === "number")
                            message.generatedAtMillis = object.generatedAtMillis;
                        else if (typeof object.generatedAtMillis === "object")
                            message.generatedAtMillis = new $util.LongBits(object.generatedAtMillis.low >>> 0, object.generatedAtMillis.high >>> 0).toNumber();
                    if (object.ssml != null)
                        message.ssml = String(object.ssml);
                    if (object.audio != null)
                        if (typeof object.audio === "string")
                            $util.base64.decode(object.audio, message.audio = $util.newBuffer($util.base64.length(object.audio)), 0);
                        else if (object.audio.length >= 0)
                            message.audio = object.audio;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GeneratedAudio message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {infinitusai.be.GeneratedAudio} message GeneratedAudio
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GeneratedAudio.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedAtMillis = options.longs === String ? "0" : 0;
                        object.ssml = "";
                        if (options.bytes === String)
                            object.audio = "";
                        else {
                            object.audio = [];
                            if (options.bytes !== Array)
                                object.audio = $util.newBuffer(object.audio);
                        }
                    }
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (typeof message.generatedAtMillis === "number")
                            object.generatedAtMillis = options.longs === String ? String(message.generatedAtMillis) : message.generatedAtMillis;
                        else
                            object.generatedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.generatedAtMillis) : options.longs === Number ? new $util.LongBits(message.generatedAtMillis.low >>> 0, message.generatedAtMillis.high >>> 0).toNumber() : message.generatedAtMillis;
                    if (message.ssml != null && message.hasOwnProperty("ssml"))
                        object.ssml = message.ssml;
                    if (message.audio != null && message.hasOwnProperty("audio"))
                        object.audio = options.bytes === String ? $util.base64.encode(message.audio, 0, message.audio.length) : options.bytes === Array ? Array.prototype.slice.call(message.audio) : message.audio;
                    return object;
                };
    
                /**
                 * Converts this GeneratedAudio to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GeneratedAudio
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GeneratedAudio.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GeneratedAudio
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GeneratedAudio
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GeneratedAudio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GeneratedAudio";
                };
    
                return GeneratedAudio;
            })();
    
            /**
             * AuditLogEventType enum.
             * @name infinitusai.be.AuditLogEventType
             * @enum {number}
             * @property {number} AUDIT_LOG_EVENT_UNKNOWN=0 AUDIT_LOG_EVENT_UNKNOWN value
             * @property {number} AUDIT_LOG_EVENT_REVEAL_TASK_INPUTS_PRIVATE_DATA=1 AUDIT_LOG_EVENT_REVEAL_TASK_INPUTS_PRIVATE_DATA value
             * @property {number} AUDIT_LOG_EVENT_REVEAL_CALL_PAGE_PRIVATE_DATA=2 AUDIT_LOG_EVENT_REVEAL_CALL_PAGE_PRIVATE_DATA value
             */
            be.AuditLogEventType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AUDIT_LOG_EVENT_UNKNOWN"] = 0;
                values[valuesById[1] = "AUDIT_LOG_EVENT_REVEAL_TASK_INPUTS_PRIVATE_DATA"] = 1;
                values[valuesById[2] = "AUDIT_LOG_EVENT_REVEAL_CALL_PAGE_PRIVATE_DATA"] = 2;
                return values;
            })();
    
            be.AuditLogRequest = (function() {
    
                /**
                 * Properties of an AuditLogRequest.
                 * @memberof infinitusai.be
                 * @interface IAuditLogRequest
                 * @property {string|null} [taskUuid] AuditLogRequest taskUuid
                 * @property {string|null} [callUuid] AuditLogRequest callUuid
                 * @property {infinitusai.be.AuditLogEventType|null} [eventType] AuditLogRequest eventType
                 */
    
                /**
                 * Constructs a new AuditLogRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AuditLogRequest.
                 * @implements IAuditLogRequest
                 * @constructor
                 * @param {infinitusai.be.IAuditLogRequest=} [properties] Properties to set
                 */
                function AuditLogRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AuditLogRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.AuditLogRequest
                 * @instance
                 */
                AuditLogRequest.prototype.taskUuid = "";
    
                /**
                 * AuditLogRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.AuditLogRequest
                 * @instance
                 */
                AuditLogRequest.prototype.callUuid = "";
    
                /**
                 * AuditLogRequest eventType.
                 * @member {infinitusai.be.AuditLogEventType} eventType
                 * @memberof infinitusai.be.AuditLogRequest
                 * @instance
                 */
                AuditLogRequest.prototype.eventType = 0;
    
                /**
                 * Creates a new AuditLogRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {infinitusai.be.IAuditLogRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.AuditLogRequest} AuditLogRequest instance
                 */
                AuditLogRequest.create = function create(properties) {
                    return new AuditLogRequest(properties);
                };
    
                /**
                 * Encodes the specified AuditLogRequest message. Does not implicitly {@link infinitusai.be.AuditLogRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {infinitusai.be.IAuditLogRequest} message AuditLogRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuditLogRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.eventType);
                    return writer;
                };
    
                /**
                 * Encodes the specified AuditLogRequest message, length delimited. Does not implicitly {@link infinitusai.be.AuditLogRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {infinitusai.be.IAuditLogRequest} message AuditLogRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuditLogRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AuditLogRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AuditLogRequest} AuditLogRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuditLogRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AuditLogRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.eventType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AuditLogRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AuditLogRequest} AuditLogRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuditLogRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AuditLogRequest message.
                 * @function verify
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AuditLogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.eventType != null && message.hasOwnProperty("eventType"))
                        switch (message.eventType) {
                        default:
                            return "eventType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an AuditLogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AuditLogRequest} AuditLogRequest
                 */
                AuditLogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AuditLogRequest)
                        return object;
                    var message = new $root.infinitusai.be.AuditLogRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    switch (object.eventType) {
                    default:
                        if (typeof object.eventType === "number") {
                            message.eventType = object.eventType;
                            break;
                        }
                        break;
                    case "AUDIT_LOG_EVENT_UNKNOWN":
                    case 0:
                        message.eventType = 0;
                        break;
                    case "AUDIT_LOG_EVENT_REVEAL_TASK_INPUTS_PRIVATE_DATA":
                    case 1:
                        message.eventType = 1;
                        break;
                    case "AUDIT_LOG_EVENT_REVEAL_CALL_PAGE_PRIVATE_DATA":
                    case 2:
                        message.eventType = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AuditLogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {infinitusai.be.AuditLogRequest} message AuditLogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AuditLogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.eventType = options.enums === String ? "AUDIT_LOG_EVENT_UNKNOWN" : 0;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.eventType != null && message.hasOwnProperty("eventType"))
                        object.eventType = options.enums === String ? $root.infinitusai.be.AuditLogEventType[message.eventType] === undefined ? message.eventType : $root.infinitusai.be.AuditLogEventType[message.eventType] : message.eventType;
                    return object;
                };
    
                /**
                 * Converts this AuditLogRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AuditLogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AuditLogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AuditLogRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AuditLogRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AuditLogRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AuditLogRequest";
                };
    
                return AuditLogRequest;
            })();
    
            be.AuditLogResponse = (function() {
    
                /**
                 * Properties of an AuditLogResponse.
                 * @memberof infinitusai.be
                 * @interface IAuditLogResponse
                 */
    
                /**
                 * Constructs a new AuditLogResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AuditLogResponse.
                 * @implements IAuditLogResponse
                 * @constructor
                 * @param {infinitusai.be.IAuditLogResponse=} [properties] Properties to set
                 */
                function AuditLogResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new AuditLogResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {infinitusai.be.IAuditLogResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.AuditLogResponse} AuditLogResponse instance
                 */
                AuditLogResponse.create = function create(properties) {
                    return new AuditLogResponse(properties);
                };
    
                /**
                 * Encodes the specified AuditLogResponse message. Does not implicitly {@link infinitusai.be.AuditLogResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {infinitusai.be.IAuditLogResponse} message AuditLogResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuditLogResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified AuditLogResponse message, length delimited. Does not implicitly {@link infinitusai.be.AuditLogResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {infinitusai.be.IAuditLogResponse} message AuditLogResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuditLogResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AuditLogResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AuditLogResponse} AuditLogResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuditLogResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AuditLogResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AuditLogResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AuditLogResponse} AuditLogResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuditLogResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AuditLogResponse message.
                 * @function verify
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AuditLogResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an AuditLogResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AuditLogResponse} AuditLogResponse
                 */
                AuditLogResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AuditLogResponse)
                        return object;
                    return new $root.infinitusai.be.AuditLogResponse();
                };
    
                /**
                 * Creates a plain object from an AuditLogResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {infinitusai.be.AuditLogResponse} message AuditLogResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AuditLogResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this AuditLogResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AuditLogResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AuditLogResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AuditLogResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AuditLogResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AuditLogResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AuditLogResponse";
                };
    
                return AuditLogResponse;
            })();
    
            be.GetAuditLogsRequest = (function() {
    
                /**
                 * Properties of a GetAuditLogsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetAuditLogsRequest
                 * @property {string|null} [auxiliaryId] GetAuditLogsRequest auxiliaryId
                 * @property {string|null} [eventType] GetAuditLogsRequest eventType
                 * @property {number|null} [page] GetAuditLogsRequest page
                 * @property {number|null} [limit] GetAuditLogsRequest limit
                 */
    
                /**
                 * Constructs a new GetAuditLogsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAuditLogsRequest.
                 * @implements IGetAuditLogsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetAuditLogsRequest=} [properties] Properties to set
                 */
                function GetAuditLogsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAuditLogsRequest auxiliaryId.
                 * @member {string} auxiliaryId
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @instance
                 */
                GetAuditLogsRequest.prototype.auxiliaryId = "";
    
                /**
                 * GetAuditLogsRequest eventType.
                 * @member {string} eventType
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @instance
                 */
                GetAuditLogsRequest.prototype.eventType = "";
    
                /**
                 * GetAuditLogsRequest page.
                 * @member {number} page
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @instance
                 */
                GetAuditLogsRequest.prototype.page = 0;
    
                /**
                 * GetAuditLogsRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @instance
                 */
                GetAuditLogsRequest.prototype.limit = 0;
    
                /**
                 * Creates a new GetAuditLogsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {infinitusai.be.IGetAuditLogsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAuditLogsRequest} GetAuditLogsRequest instance
                 */
                GetAuditLogsRequest.create = function create(properties) {
                    return new GetAuditLogsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetAuditLogsRequest message. Does not implicitly {@link infinitusai.be.GetAuditLogsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {infinitusai.be.IGetAuditLogsRequest} message GetAuditLogsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAuditLogsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.auxiliaryId != null && Object.hasOwnProperty.call(message, "auxiliaryId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.auxiliaryId);
                    if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.eventType);
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.page);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAuditLogsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetAuditLogsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {infinitusai.be.IGetAuditLogsRequest} message GetAuditLogsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAuditLogsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAuditLogsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAuditLogsRequest} GetAuditLogsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAuditLogsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAuditLogsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.auxiliaryId = reader.string();
                                break;
                            }
                        case 2: {
                                message.eventType = reader.string();
                                break;
                            }
                        case 3: {
                                message.page = reader.int32();
                                break;
                            }
                        case 4: {
                                message.limit = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAuditLogsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAuditLogsRequest} GetAuditLogsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAuditLogsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAuditLogsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAuditLogsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.auxiliaryId != null && message.hasOwnProperty("auxiliaryId"))
                        if (!$util.isString(message.auxiliaryId))
                            return "auxiliaryId: string expected";
                    if (message.eventType != null && message.hasOwnProperty("eventType"))
                        if (!$util.isString(message.eventType))
                            return "eventType: string expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    return null;
                };
    
                /**
                 * Creates a GetAuditLogsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAuditLogsRequest} GetAuditLogsRequest
                 */
                GetAuditLogsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAuditLogsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetAuditLogsRequest();
                    if (object.auxiliaryId != null)
                        message.auxiliaryId = String(object.auxiliaryId);
                    if (object.eventType != null)
                        message.eventType = String(object.eventType);
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAuditLogsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {infinitusai.be.GetAuditLogsRequest} message GetAuditLogsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAuditLogsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.auxiliaryId = "";
                        object.eventType = "";
                        object.page = 0;
                        object.limit = 0;
                    }
                    if (message.auxiliaryId != null && message.hasOwnProperty("auxiliaryId"))
                        object.auxiliaryId = message.auxiliaryId;
                    if (message.eventType != null && message.hasOwnProperty("eventType"))
                        object.eventType = message.eventType;
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    return object;
                };
    
                /**
                 * Converts this GetAuditLogsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAuditLogsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAuditLogsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAuditLogsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAuditLogsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAuditLogsRequest";
                };
    
                return GetAuditLogsRequest;
            })();
    
            be.AuditLog = (function() {
    
                /**
                 * Properties of an AuditLog.
                 * @memberof infinitusai.be
                 * @interface IAuditLog
                 * @property {string|null} [uuid] AuditLog uuid
                 * @property {string|null} [eventType] AuditLog eventType
                 * @property {string|null} [userId] AuditLog userId
                 * @property {string|null} [userComment] AuditLog userComment
                 * @property {string|null} [auxiliaryId] AuditLog auxiliaryId
                 * @property {string|null} [previousValueBlob] AuditLog previousValueBlob
                 * @property {string|null} [newValueBlob] AuditLog newValueBlob
                 * @property {number|Long|null} [createTimeMillis] AuditLog createTimeMillis
                 * @property {string|null} [userEmail] AuditLog userEmail
                 */
    
                /**
                 * Constructs a new AuditLog.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AuditLog.
                 * @implements IAuditLog
                 * @constructor
                 * @param {infinitusai.be.IAuditLog=} [properties] Properties to set
                 */
                function AuditLog(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AuditLog uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.uuid = "";
    
                /**
                 * AuditLog eventType.
                 * @member {string} eventType
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.eventType = "";
    
                /**
                 * AuditLog userId.
                 * @member {string} userId
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.userId = "";
    
                /**
                 * AuditLog userComment.
                 * @member {string} userComment
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.userComment = "";
    
                /**
                 * AuditLog auxiliaryId.
                 * @member {string} auxiliaryId
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.auxiliaryId = "";
    
                /**
                 * AuditLog previousValueBlob.
                 * @member {string} previousValueBlob
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.previousValueBlob = "";
    
                /**
                 * AuditLog newValueBlob.
                 * @member {string} newValueBlob
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.newValueBlob = "";
    
                /**
                 * AuditLog createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * AuditLog userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 */
                AuditLog.prototype.userEmail = "";
    
                /**
                 * Creates a new AuditLog instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {infinitusai.be.IAuditLog=} [properties] Properties to set
                 * @returns {infinitusai.be.AuditLog} AuditLog instance
                 */
                AuditLog.create = function create(properties) {
                    return new AuditLog(properties);
                };
    
                /**
                 * Encodes the specified AuditLog message. Does not implicitly {@link infinitusai.be.AuditLog.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {infinitusai.be.IAuditLog} message AuditLog message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuditLog.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.eventType);
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
                    if (message.userComment != null && Object.hasOwnProperty.call(message, "userComment"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userComment);
                    if (message.auxiliaryId != null && Object.hasOwnProperty.call(message, "auxiliaryId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.auxiliaryId);
                    if (message.previousValueBlob != null && Object.hasOwnProperty.call(message, "previousValueBlob"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.previousValueBlob);
                    if (message.newValueBlob != null && Object.hasOwnProperty.call(message, "newValueBlob"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.newValueBlob);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createTimeMillis);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.userEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified AuditLog message, length delimited. Does not implicitly {@link infinitusai.be.AuditLog.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {infinitusai.be.IAuditLog} message AuditLog message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuditLog.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AuditLog message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AuditLog} AuditLog
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuditLog.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AuditLog();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.eventType = reader.string();
                                break;
                            }
                        case 3: {
                                message.userId = reader.string();
                                break;
                            }
                        case 4: {
                                message.userComment = reader.string();
                                break;
                            }
                        case 5: {
                                message.auxiliaryId = reader.string();
                                break;
                            }
                        case 6: {
                                message.previousValueBlob = reader.string();
                                break;
                            }
                        case 7: {
                                message.newValueBlob = reader.string();
                                break;
                            }
                        case 8: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 9: {
                                message.userEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AuditLog message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AuditLog} AuditLog
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuditLog.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AuditLog message.
                 * @function verify
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AuditLog.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.eventType != null && message.hasOwnProperty("eventType"))
                        if (!$util.isString(message.eventType))
                            return "eventType: string expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.userComment != null && message.hasOwnProperty("userComment"))
                        if (!$util.isString(message.userComment))
                            return "userComment: string expected";
                    if (message.auxiliaryId != null && message.hasOwnProperty("auxiliaryId"))
                        if (!$util.isString(message.auxiliaryId))
                            return "auxiliaryId: string expected";
                    if (message.previousValueBlob != null && message.hasOwnProperty("previousValueBlob"))
                        if (!$util.isString(message.previousValueBlob))
                            return "previousValueBlob: string expected";
                    if (message.newValueBlob != null && message.hasOwnProperty("newValueBlob"))
                        if (!$util.isString(message.newValueBlob))
                            return "newValueBlob: string expected";
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates an AuditLog message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AuditLog} AuditLog
                 */
                AuditLog.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AuditLog)
                        return object;
                    var message = new $root.infinitusai.be.AuditLog();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.eventType != null)
                        message.eventType = String(object.eventType);
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.userComment != null)
                        message.userComment = String(object.userComment);
                    if (object.auxiliaryId != null)
                        message.auxiliaryId = String(object.auxiliaryId);
                    if (object.previousValueBlob != null)
                        message.previousValueBlob = String(object.previousValueBlob);
                    if (object.newValueBlob != null)
                        message.newValueBlob = String(object.newValueBlob);
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AuditLog message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {infinitusai.be.AuditLog} message AuditLog
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AuditLog.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uuid = "";
                        object.eventType = "";
                        object.userId = "";
                        object.userComment = "";
                        object.auxiliaryId = "";
                        object.previousValueBlob = "";
                        object.newValueBlob = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        object.userEmail = "";
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.eventType != null && message.hasOwnProperty("eventType"))
                        object.eventType = message.eventType;
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.userComment != null && message.hasOwnProperty("userComment"))
                        object.userComment = message.userComment;
                    if (message.auxiliaryId != null && message.hasOwnProperty("auxiliaryId"))
                        object.auxiliaryId = message.auxiliaryId;
                    if (message.previousValueBlob != null && message.hasOwnProperty("previousValueBlob"))
                        object.previousValueBlob = message.previousValueBlob;
                    if (message.newValueBlob != null && message.hasOwnProperty("newValueBlob"))
                        object.newValueBlob = message.newValueBlob;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    return object;
                };
    
                /**
                 * Converts this AuditLog to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AuditLog
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AuditLog.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AuditLog
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AuditLog
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AuditLog.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AuditLog";
                };
    
                return AuditLog;
            })();
    
            be.GetAuditLogsResponse = (function() {
    
                /**
                 * Properties of a GetAuditLogsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetAuditLogsResponse
                 * @property {Array.<infinitusai.be.IAuditLog>|null} [auditLogs] GetAuditLogsResponse auditLogs
                 * @property {number|Long|null} [total] GetAuditLogsResponse total
                 */
    
                /**
                 * Constructs a new GetAuditLogsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAuditLogsResponse.
                 * @implements IGetAuditLogsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetAuditLogsResponse=} [properties] Properties to set
                 */
                function GetAuditLogsResponse(properties) {
                    this.auditLogs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAuditLogsResponse auditLogs.
                 * @member {Array.<infinitusai.be.IAuditLog>} auditLogs
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @instance
                 */
                GetAuditLogsResponse.prototype.auditLogs = $util.emptyArray;
    
                /**
                 * GetAuditLogsResponse total.
                 * @member {number|Long} total
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @instance
                 */
                GetAuditLogsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new GetAuditLogsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {infinitusai.be.IGetAuditLogsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAuditLogsResponse} GetAuditLogsResponse instance
                 */
                GetAuditLogsResponse.create = function create(properties) {
                    return new GetAuditLogsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetAuditLogsResponse message. Does not implicitly {@link infinitusai.be.GetAuditLogsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {infinitusai.be.IGetAuditLogsResponse} message GetAuditLogsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAuditLogsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.auditLogs != null && message.auditLogs.length)
                        for (var i = 0; i < message.auditLogs.length; ++i)
                            $root.infinitusai.be.AuditLog.encode(message.auditLogs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.total);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAuditLogsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetAuditLogsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {infinitusai.be.IGetAuditLogsResponse} message GetAuditLogsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAuditLogsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAuditLogsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAuditLogsResponse} GetAuditLogsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAuditLogsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAuditLogsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.auditLogs && message.auditLogs.length))
                                    message.auditLogs = [];
                                message.auditLogs.push($root.infinitusai.be.AuditLog.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.total = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAuditLogsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAuditLogsResponse} GetAuditLogsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAuditLogsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAuditLogsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAuditLogsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.auditLogs != null && message.hasOwnProperty("auditLogs")) {
                        if (!Array.isArray(message.auditLogs))
                            return "auditLogs: array expected";
                        for (var i = 0; i < message.auditLogs.length; ++i) {
                            var error = $root.infinitusai.be.AuditLog.verify(message.auditLogs[i]);
                            if (error)
                                return "auditLogs." + error;
                        }
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a GetAuditLogsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAuditLogsResponse} GetAuditLogsResponse
                 */
                GetAuditLogsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAuditLogsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetAuditLogsResponse();
                    if (object.auditLogs) {
                        if (!Array.isArray(object.auditLogs))
                            throw TypeError(".infinitusai.be.GetAuditLogsResponse.auditLogs: array expected");
                        message.auditLogs = [];
                        for (var i = 0; i < object.auditLogs.length; ++i) {
                            if (typeof object.auditLogs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetAuditLogsResponse.auditLogs: object expected");
                            message.auditLogs[i] = $root.infinitusai.be.AuditLog.fromObject(object.auditLogs[i]);
                        }
                    }
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAuditLogsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {infinitusai.be.GetAuditLogsResponse} message GetAuditLogsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAuditLogsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.auditLogs = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.auditLogs && message.auditLogs.length) {
                        object.auditLogs = [];
                        for (var j = 0; j < message.auditLogs.length; ++j)
                            object.auditLogs[j] = $root.infinitusai.be.AuditLog.toObject(message.auditLogs[j], options);
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    return object;
                };
    
                /**
                 * Converts this GetAuditLogsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAuditLogsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAuditLogsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAuditLogsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAuditLogsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAuditLogsResponse";
                };
    
                return GetAuditLogsResponse;
            })();
    
            /**
             * AutoKey enum.
             * @name infinitusai.be.AutoKey
             * @enum {number}
             * @property {number} AUTO_KEY_UNKNOWN=0 AUTO_KEY_UNKNOWN value
             * @property {number} AUTO_KEY_PAYER_NAME=1 AUTO_KEY_PAYER_NAME value
             * @property {number} AUTO_KEY_SPECIALTY_PHARMACY=2 AUTO_KEY_SPECIALTY_PHARMACY value
             * @property {number} AUTO_KEY_PLAN_NAME=4 AUTO_KEY_PLAN_NAME value
             * @property {number} AUTO_KEY_TASK_NOTES=5 AUTO_KEY_TASK_NOTES value
             * @property {number} AUTO_KEY_CALL_FEEDBACK=7 AUTO_KEY_CALL_FEEDBACK value
             * @property {number} AUTO_KEY_PA_ENTITY=9 AUTO_KEY_PA_ENTITY value
             * @property {number} AUTO_KEY_PA_CONTACT=10 AUTO_KEY_PA_CONTACT value
             * @property {number} AUTO_KEY_PA_PHONE=13 AUTO_KEY_PA_PHONE value
             * @property {number} AUTO_KEY_PA_WEB=14 AUTO_KEY_PA_WEB value
             * @property {number} AUTO_KEY_POTENTIAL_ADVERSE_EVENT_NOTES=15 AUTO_KEY_POTENTIAL_ADVERSE_EVENT_NOTES value
             * @property {number} AUTO_KEY_OTHER_INSURANCE_NAME=11 AUTO_KEY_OTHER_INSURANCE_NAME value
             * @property {number} AUTO_KEY_STEP_THERAPY_TREATMENT=12 AUTO_KEY_STEP_THERAPY_TREATMENT value
             * @property {number} AUTO_KEY_ACCEPTED_WORDS=16 AUTO_KEY_ACCEPTED_WORDS value
             * @property {number} AUTO_KEY_GROUP_NAME=17 AUTO_KEY_GROUP_NAME value
             * @property {number} AUTO_KEY_PA_TURNAROUND_TIME=18 AUTO_KEY_PA_TURNAROUND_TIME value
             * @property {number} AUTO_KEY_AGENT_NAME=19 AUTO_KEY_AGENT_NAME value
             * @property {number} AUTO_KEY_GROUP_NUMBER=20 AUTO_KEY_GROUP_NUMBER value
             * @property {number} AUTO_KEY_PROCESSOR_CONTROL_NUMBER=21 AUTO_KEY_PROCESSOR_CONTROL_NUMBER value
             * @property {number} AUTO_KEY_BANK_IDENTIFICATION_NUMBER=22 AUTO_KEY_BANK_IDENTIFICATION_NUMBER value
             * @property {number} AUTO_KEY_CREATE_MESSAGE=3 AUTO_KEY_CREATE_MESSAGE value
             * @property {number} AUTO_KEY_PA_NOTES=6 AUTO_KEY_PA_NOTES value
             * @property {number} AUTO_KEY_OTHER_INSURANCE_NOTES=8 AUTO_KEY_OTHER_INSURANCE_NOTES value
             */
            be.AutoKey = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AUTO_KEY_UNKNOWN"] = 0;
                values[valuesById[1] = "AUTO_KEY_PAYER_NAME"] = 1;
                values[valuesById[2] = "AUTO_KEY_SPECIALTY_PHARMACY"] = 2;
                values[valuesById[4] = "AUTO_KEY_PLAN_NAME"] = 4;
                values[valuesById[5] = "AUTO_KEY_TASK_NOTES"] = 5;
                values[valuesById[7] = "AUTO_KEY_CALL_FEEDBACK"] = 7;
                values[valuesById[9] = "AUTO_KEY_PA_ENTITY"] = 9;
                values[valuesById[10] = "AUTO_KEY_PA_CONTACT"] = 10;
                values[valuesById[13] = "AUTO_KEY_PA_PHONE"] = 13;
                values[valuesById[14] = "AUTO_KEY_PA_WEB"] = 14;
                values[valuesById[15] = "AUTO_KEY_POTENTIAL_ADVERSE_EVENT_NOTES"] = 15;
                values[valuesById[11] = "AUTO_KEY_OTHER_INSURANCE_NAME"] = 11;
                values[valuesById[12] = "AUTO_KEY_STEP_THERAPY_TREATMENT"] = 12;
                values[valuesById[16] = "AUTO_KEY_ACCEPTED_WORDS"] = 16;
                values[valuesById[17] = "AUTO_KEY_GROUP_NAME"] = 17;
                values[valuesById[18] = "AUTO_KEY_PA_TURNAROUND_TIME"] = 18;
                values[valuesById[19] = "AUTO_KEY_AGENT_NAME"] = 19;
                values[valuesById[20] = "AUTO_KEY_GROUP_NUMBER"] = 20;
                values[valuesById[21] = "AUTO_KEY_PROCESSOR_CONTROL_NUMBER"] = 21;
                values[valuesById[22] = "AUTO_KEY_BANK_IDENTIFICATION_NUMBER"] = 22;
                values[valuesById[3] = "AUTO_KEY_CREATE_MESSAGE"] = 3;
                values[valuesById[6] = "AUTO_KEY_PA_NOTES"] = 6;
                values[valuesById[8] = "AUTO_KEY_OTHER_INSURANCE_NOTES"] = 8;
                return values;
            })();
    
            be.AutoValue = (function() {
    
                /**
                 * Properties of an AutoValue.
                 * @memberof infinitusai.be
                 * @interface IAutoValue
                 * @property {Array.<string>|null} [values] AutoValue values
                 */
    
                /**
                 * Constructs a new AutoValue.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AutoValue.
                 * @implements IAutoValue
                 * @constructor
                 * @param {infinitusai.be.IAutoValue=} [properties] Properties to set
                 */
                function AutoValue(properties) {
                    this.values = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AutoValue values.
                 * @member {Array.<string>} values
                 * @memberof infinitusai.be.AutoValue
                 * @instance
                 */
                AutoValue.prototype.values = $util.emptyArray;
    
                /**
                 * Creates a new AutoValue instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {infinitusai.be.IAutoValue=} [properties] Properties to set
                 * @returns {infinitusai.be.AutoValue} AutoValue instance
                 */
                AutoValue.create = function create(properties) {
                    return new AutoValue(properties);
                };
    
                /**
                 * Encodes the specified AutoValue message. Does not implicitly {@link infinitusai.be.AutoValue.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {infinitusai.be.IAutoValue} message AutoValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.values != null && message.values.length)
                        for (var i = 0; i < message.values.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.values[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified AutoValue message, length delimited. Does not implicitly {@link infinitusai.be.AutoValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {infinitusai.be.IAutoValue} message AutoValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AutoValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AutoValue} AutoValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AutoValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AutoValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AutoValue} AutoValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AutoValue message.
                 * @function verify
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AutoValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.values != null && message.hasOwnProperty("values")) {
                        if (!Array.isArray(message.values))
                            return "values: array expected";
                        for (var i = 0; i < message.values.length; ++i)
                            if (!$util.isString(message.values[i]))
                                return "values: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an AutoValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AutoValue} AutoValue
                 */
                AutoValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AutoValue)
                        return object;
                    var message = new $root.infinitusai.be.AutoValue();
                    if (object.values) {
                        if (!Array.isArray(object.values))
                            throw TypeError(".infinitusai.be.AutoValue.values: array expected");
                        message.values = [];
                        for (var i = 0; i < object.values.length; ++i)
                            message.values[i] = String(object.values[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AutoValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {infinitusai.be.AutoValue} message AutoValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AutoValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.values = [];
                    if (message.values && message.values.length) {
                        object.values = [];
                        for (var j = 0; j < message.values.length; ++j)
                            object.values[j] = message.values[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this AutoValue to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AutoValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AutoValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AutoValue
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AutoValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AutoValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AutoValue";
                };
    
                return AutoValue;
            })();
    
            be.GenerateBillingReportRequest = (function() {
    
                /**
                 * Properties of a GenerateBillingReportRequest.
                 * @memberof infinitusai.be
                 * @interface IGenerateBillingReportRequest
                 * @property {number|Long|null} [reviewedMillisStart] GenerateBillingReportRequest reviewedMillisStart
                 * @property {number|Long|null} [reviewedMillisEnd] GenerateBillingReportRequest reviewedMillisEnd
                 * @property {string|null} [customerName] GenerateBillingReportRequest customerName
                 */
    
                /**
                 * Constructs a new GenerateBillingReportRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateBillingReportRequest.
                 * @implements IGenerateBillingReportRequest
                 * @constructor
                 * @param {infinitusai.be.IGenerateBillingReportRequest=} [properties] Properties to set
                 */
                function GenerateBillingReportRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateBillingReportRequest reviewedMillisStart.
                 * @member {number|Long} reviewedMillisStart
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @instance
                 */
                GenerateBillingReportRequest.prototype.reviewedMillisStart = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateBillingReportRequest reviewedMillisEnd.
                 * @member {number|Long} reviewedMillisEnd
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @instance
                 */
                GenerateBillingReportRequest.prototype.reviewedMillisEnd = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateBillingReportRequest customerName.
                 * @member {string} customerName
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @instance
                 */
                GenerateBillingReportRequest.prototype.customerName = "";
    
                /**
                 * Creates a new GenerateBillingReportRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {infinitusai.be.IGenerateBillingReportRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateBillingReportRequest} GenerateBillingReportRequest instance
                 */
                GenerateBillingReportRequest.create = function create(properties) {
                    return new GenerateBillingReportRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateBillingReportRequest message. Does not implicitly {@link infinitusai.be.GenerateBillingReportRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {infinitusai.be.IGenerateBillingReportRequest} message GenerateBillingReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateBillingReportRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reviewedMillisStart != null && Object.hasOwnProperty.call(message, "reviewedMillisStart"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.reviewedMillisStart);
                    if (message.reviewedMillisEnd != null && Object.hasOwnProperty.call(message, "reviewedMillisEnd"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.reviewedMillisEnd);
                    if (message.customerName != null && Object.hasOwnProperty.call(message, "customerName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.customerName);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateBillingReportRequest message, length delimited. Does not implicitly {@link infinitusai.be.GenerateBillingReportRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {infinitusai.be.IGenerateBillingReportRequest} message GenerateBillingReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateBillingReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateBillingReportRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateBillingReportRequest} GenerateBillingReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateBillingReportRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateBillingReportRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.reviewedMillisStart = reader.int64();
                                break;
                            }
                        case 2: {
                                message.reviewedMillisEnd = reader.int64();
                                break;
                            }
                        case 3: {
                                message.customerName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateBillingReportRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateBillingReportRequest} GenerateBillingReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateBillingReportRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateBillingReportRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateBillingReportRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reviewedMillisStart != null && message.hasOwnProperty("reviewedMillisStart"))
                        if (!$util.isInteger(message.reviewedMillisStart) && !(message.reviewedMillisStart && $util.isInteger(message.reviewedMillisStart.low) && $util.isInteger(message.reviewedMillisStart.high)))
                            return "reviewedMillisStart: integer|Long expected";
                    if (message.reviewedMillisEnd != null && message.hasOwnProperty("reviewedMillisEnd"))
                        if (!$util.isInteger(message.reviewedMillisEnd) && !(message.reviewedMillisEnd && $util.isInteger(message.reviewedMillisEnd.low) && $util.isInteger(message.reviewedMillisEnd.high)))
                            return "reviewedMillisEnd: integer|Long expected";
                    if (message.customerName != null && message.hasOwnProperty("customerName"))
                        if (!$util.isString(message.customerName))
                            return "customerName: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateBillingReportRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateBillingReportRequest} GenerateBillingReportRequest
                 */
                GenerateBillingReportRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateBillingReportRequest)
                        return object;
                    var message = new $root.infinitusai.be.GenerateBillingReportRequest();
                    if (object.reviewedMillisStart != null)
                        if ($util.Long)
                            (message.reviewedMillisStart = $util.Long.fromValue(object.reviewedMillisStart)).unsigned = false;
                        else if (typeof object.reviewedMillisStart === "string")
                            message.reviewedMillisStart = parseInt(object.reviewedMillisStart, 10);
                        else if (typeof object.reviewedMillisStart === "number")
                            message.reviewedMillisStart = object.reviewedMillisStart;
                        else if (typeof object.reviewedMillisStart === "object")
                            message.reviewedMillisStart = new $util.LongBits(object.reviewedMillisStart.low >>> 0, object.reviewedMillisStart.high >>> 0).toNumber();
                    if (object.reviewedMillisEnd != null)
                        if ($util.Long)
                            (message.reviewedMillisEnd = $util.Long.fromValue(object.reviewedMillisEnd)).unsigned = false;
                        else if (typeof object.reviewedMillisEnd === "string")
                            message.reviewedMillisEnd = parseInt(object.reviewedMillisEnd, 10);
                        else if (typeof object.reviewedMillisEnd === "number")
                            message.reviewedMillisEnd = object.reviewedMillisEnd;
                        else if (typeof object.reviewedMillisEnd === "object")
                            message.reviewedMillisEnd = new $util.LongBits(object.reviewedMillisEnd.low >>> 0, object.reviewedMillisEnd.high >>> 0).toNumber();
                    if (object.customerName != null)
                        message.customerName = String(object.customerName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateBillingReportRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {infinitusai.be.GenerateBillingReportRequest} message GenerateBillingReportRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateBillingReportRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedMillisStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedMillisStart = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedMillisEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedMillisEnd = options.longs === String ? "0" : 0;
                        object.customerName = "";
                    }
                    if (message.reviewedMillisStart != null && message.hasOwnProperty("reviewedMillisStart"))
                        if (typeof message.reviewedMillisStart === "number")
                            object.reviewedMillisStart = options.longs === String ? String(message.reviewedMillisStart) : message.reviewedMillisStart;
                        else
                            object.reviewedMillisStart = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedMillisStart) : options.longs === Number ? new $util.LongBits(message.reviewedMillisStart.low >>> 0, message.reviewedMillisStart.high >>> 0).toNumber() : message.reviewedMillisStart;
                    if (message.reviewedMillisEnd != null && message.hasOwnProperty("reviewedMillisEnd"))
                        if (typeof message.reviewedMillisEnd === "number")
                            object.reviewedMillisEnd = options.longs === String ? String(message.reviewedMillisEnd) : message.reviewedMillisEnd;
                        else
                            object.reviewedMillisEnd = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedMillisEnd) : options.longs === Number ? new $util.LongBits(message.reviewedMillisEnd.low >>> 0, message.reviewedMillisEnd.high >>> 0).toNumber() : message.reviewedMillisEnd;
                    if (message.customerName != null && message.hasOwnProperty("customerName"))
                        object.customerName = message.customerName;
                    return object;
                };
    
                /**
                 * Converts this GenerateBillingReportRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateBillingReportRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateBillingReportRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateBillingReportRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateBillingReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateBillingReportRequest";
                };
    
                return GenerateBillingReportRequest;
            })();
    
            be.GenerateBillingReportResponse = (function() {
    
                /**
                 * Properties of a GenerateBillingReportResponse.
                 * @memberof infinitusai.be
                 * @interface IGenerateBillingReportResponse
                 * @property {string|null} [reportUrl] GenerateBillingReportResponse reportUrl
                 */
    
                /**
                 * Constructs a new GenerateBillingReportResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateBillingReportResponse.
                 * @implements IGenerateBillingReportResponse
                 * @constructor
                 * @param {infinitusai.be.IGenerateBillingReportResponse=} [properties] Properties to set
                 */
                function GenerateBillingReportResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateBillingReportResponse reportUrl.
                 * @member {string} reportUrl
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @instance
                 */
                GenerateBillingReportResponse.prototype.reportUrl = "";
    
                /**
                 * Creates a new GenerateBillingReportResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {infinitusai.be.IGenerateBillingReportResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateBillingReportResponse} GenerateBillingReportResponse instance
                 */
                GenerateBillingReportResponse.create = function create(properties) {
                    return new GenerateBillingReportResponse(properties);
                };
    
                /**
                 * Encodes the specified GenerateBillingReportResponse message. Does not implicitly {@link infinitusai.be.GenerateBillingReportResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {infinitusai.be.IGenerateBillingReportResponse} message GenerateBillingReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateBillingReportResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reportUrl != null && Object.hasOwnProperty.call(message, "reportUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.reportUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateBillingReportResponse message, length delimited. Does not implicitly {@link infinitusai.be.GenerateBillingReportResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {infinitusai.be.IGenerateBillingReportResponse} message GenerateBillingReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateBillingReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateBillingReportResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateBillingReportResponse} GenerateBillingReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateBillingReportResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateBillingReportResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.reportUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateBillingReportResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateBillingReportResponse} GenerateBillingReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateBillingReportResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateBillingReportResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateBillingReportResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reportUrl != null && message.hasOwnProperty("reportUrl"))
                        if (!$util.isString(message.reportUrl))
                            return "reportUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateBillingReportResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateBillingReportResponse} GenerateBillingReportResponse
                 */
                GenerateBillingReportResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateBillingReportResponse)
                        return object;
                    var message = new $root.infinitusai.be.GenerateBillingReportResponse();
                    if (object.reportUrl != null)
                        message.reportUrl = String(object.reportUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateBillingReportResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {infinitusai.be.GenerateBillingReportResponse} message GenerateBillingReportResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateBillingReportResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.reportUrl = "";
                    if (message.reportUrl != null && message.hasOwnProperty("reportUrl"))
                        object.reportUrl = message.reportUrl;
                    return object;
                };
    
                /**
                 * Converts this GenerateBillingReportResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateBillingReportResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateBillingReportResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateBillingReportResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateBillingReportResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateBillingReportResponse";
                };
    
                return GenerateBillingReportResponse;
            })();
    
            be.UploadBillingReportRequest = (function() {
    
                /**
                 * Properties of an UploadBillingReportRequest.
                 * @memberof infinitusai.be
                 * @interface IUploadBillingReportRequest
                 * @property {string|null} [month] UploadBillingReportRequest month
                 * @property {string|null} [year] UploadBillingReportRequest year
                 */
    
                /**
                 * Constructs a new UploadBillingReportRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UploadBillingReportRequest.
                 * @implements IUploadBillingReportRequest
                 * @constructor
                 * @param {infinitusai.be.IUploadBillingReportRequest=} [properties] Properties to set
                 */
                function UploadBillingReportRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UploadBillingReportRequest month.
                 * @member {string} month
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @instance
                 */
                UploadBillingReportRequest.prototype.month = "";
    
                /**
                 * UploadBillingReportRequest year.
                 * @member {string} year
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @instance
                 */
                UploadBillingReportRequest.prototype.year = "";
    
                /**
                 * Creates a new UploadBillingReportRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {infinitusai.be.IUploadBillingReportRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UploadBillingReportRequest} UploadBillingReportRequest instance
                 */
                UploadBillingReportRequest.create = function create(properties) {
                    return new UploadBillingReportRequest(properties);
                };
    
                /**
                 * Encodes the specified UploadBillingReportRequest message. Does not implicitly {@link infinitusai.be.UploadBillingReportRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {infinitusai.be.IUploadBillingReportRequest} message UploadBillingReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadBillingReportRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.month);
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.year);
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadBillingReportRequest message, length delimited. Does not implicitly {@link infinitusai.be.UploadBillingReportRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {infinitusai.be.IUploadBillingReportRequest} message UploadBillingReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadBillingReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadBillingReportRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UploadBillingReportRequest} UploadBillingReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadBillingReportRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UploadBillingReportRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.month = reader.string();
                                break;
                            }
                        case 2: {
                                message.year = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadBillingReportRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UploadBillingReportRequest} UploadBillingReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadBillingReportRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadBillingReportRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadBillingReportRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isString(message.month))
                            return "month: string expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isString(message.year))
                            return "year: string expected";
                    return null;
                };
    
                /**
                 * Creates an UploadBillingReportRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UploadBillingReportRequest} UploadBillingReportRequest
                 */
                UploadBillingReportRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UploadBillingReportRequest)
                        return object;
                    var message = new $root.infinitusai.be.UploadBillingReportRequest();
                    if (object.month != null)
                        message.month = String(object.month);
                    if (object.year != null)
                        message.year = String(object.year);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UploadBillingReportRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {infinitusai.be.UploadBillingReportRequest} message UploadBillingReportRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadBillingReportRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.month = "";
                        object.year = "";
                    }
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    return object;
                };
    
                /**
                 * Converts this UploadBillingReportRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadBillingReportRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadBillingReportRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UploadBillingReportRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadBillingReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UploadBillingReportRequest";
                };
    
                return UploadBillingReportRequest;
            })();
    
            be.UploadBillingReportResponse = (function() {
    
                /**
                 * Properties of an UploadBillingReportResponse.
                 * @memberof infinitusai.be
                 * @interface IUploadBillingReportResponse
                 * @property {string|null} [presignedUploadUrl] UploadBillingReportResponse presignedUploadUrl
                 */
    
                /**
                 * Constructs a new UploadBillingReportResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UploadBillingReportResponse.
                 * @implements IUploadBillingReportResponse
                 * @constructor
                 * @param {infinitusai.be.IUploadBillingReportResponse=} [properties] Properties to set
                 */
                function UploadBillingReportResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UploadBillingReportResponse presignedUploadUrl.
                 * @member {string} presignedUploadUrl
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @instance
                 */
                UploadBillingReportResponse.prototype.presignedUploadUrl = "";
    
                /**
                 * Creates a new UploadBillingReportResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {infinitusai.be.IUploadBillingReportResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UploadBillingReportResponse} UploadBillingReportResponse instance
                 */
                UploadBillingReportResponse.create = function create(properties) {
                    return new UploadBillingReportResponse(properties);
                };
    
                /**
                 * Encodes the specified UploadBillingReportResponse message. Does not implicitly {@link infinitusai.be.UploadBillingReportResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {infinitusai.be.IUploadBillingReportResponse} message UploadBillingReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadBillingReportResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.presignedUploadUrl != null && Object.hasOwnProperty.call(message, "presignedUploadUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.presignedUploadUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadBillingReportResponse message, length delimited. Does not implicitly {@link infinitusai.be.UploadBillingReportResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {infinitusai.be.IUploadBillingReportResponse} message UploadBillingReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadBillingReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadBillingReportResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UploadBillingReportResponse} UploadBillingReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadBillingReportResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UploadBillingReportResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.presignedUploadUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadBillingReportResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UploadBillingReportResponse} UploadBillingReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadBillingReportResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadBillingReportResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadBillingReportResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.presignedUploadUrl != null && message.hasOwnProperty("presignedUploadUrl"))
                        if (!$util.isString(message.presignedUploadUrl))
                            return "presignedUploadUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates an UploadBillingReportResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UploadBillingReportResponse} UploadBillingReportResponse
                 */
                UploadBillingReportResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UploadBillingReportResponse)
                        return object;
                    var message = new $root.infinitusai.be.UploadBillingReportResponse();
                    if (object.presignedUploadUrl != null)
                        message.presignedUploadUrl = String(object.presignedUploadUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UploadBillingReportResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {infinitusai.be.UploadBillingReportResponse} message UploadBillingReportResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadBillingReportResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.presignedUploadUrl = "";
                    if (message.presignedUploadUrl != null && message.hasOwnProperty("presignedUploadUrl"))
                        object.presignedUploadUrl = message.presignedUploadUrl;
                    return object;
                };
    
                /**
                 * Converts this UploadBillingReportResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadBillingReportResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadBillingReportResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UploadBillingReportResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadBillingReportResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UploadBillingReportResponse";
                };
    
                return UploadBillingReportResponse;
            })();
    
            be.GeneratedBillingReport = (function() {
    
                /**
                 * Properties of a GeneratedBillingReport.
                 * @memberof infinitusai.be
                 * @interface IGeneratedBillingReport
                 * @property {number|Long|null} [generatedAtMillis] GeneratedBillingReport generatedAtMillis
                 * @property {string|null} [reportUuid] GeneratedBillingReport reportUuid
                 * @property {number|Long|null} [reviewedMillisStart] GeneratedBillingReport reviewedMillisStart
                 * @property {number|Long|null} [reviewedMillisEnd] GeneratedBillingReport reviewedMillisEnd
                 * @property {string|null} [errorMessage] GeneratedBillingReport errorMessage
                 * @property {string|null} [gcsPath] GeneratedBillingReport gcsPath
                 */
    
                /**
                 * Constructs a new GeneratedBillingReport.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GeneratedBillingReport.
                 * @implements IGeneratedBillingReport
                 * @constructor
                 * @param {infinitusai.be.IGeneratedBillingReport=} [properties] Properties to set
                 */
                function GeneratedBillingReport(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GeneratedBillingReport generatedAtMillis.
                 * @member {number|Long} generatedAtMillis
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @instance
                 */
                GeneratedBillingReport.prototype.generatedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GeneratedBillingReport reportUuid.
                 * @member {string} reportUuid
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @instance
                 */
                GeneratedBillingReport.prototype.reportUuid = "";
    
                /**
                 * GeneratedBillingReport reviewedMillisStart.
                 * @member {number|Long} reviewedMillisStart
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @instance
                 */
                GeneratedBillingReport.prototype.reviewedMillisStart = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GeneratedBillingReport reviewedMillisEnd.
                 * @member {number|Long} reviewedMillisEnd
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @instance
                 */
                GeneratedBillingReport.prototype.reviewedMillisEnd = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GeneratedBillingReport errorMessage.
                 * @member {string} errorMessage
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @instance
                 */
                GeneratedBillingReport.prototype.errorMessage = "";
    
                /**
                 * GeneratedBillingReport gcsPath.
                 * @member {string} gcsPath
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @instance
                 */
                GeneratedBillingReport.prototype.gcsPath = "";
    
                /**
                 * Creates a new GeneratedBillingReport instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {infinitusai.be.IGeneratedBillingReport=} [properties] Properties to set
                 * @returns {infinitusai.be.GeneratedBillingReport} GeneratedBillingReport instance
                 */
                GeneratedBillingReport.create = function create(properties) {
                    return new GeneratedBillingReport(properties);
                };
    
                /**
                 * Encodes the specified GeneratedBillingReport message. Does not implicitly {@link infinitusai.be.GeneratedBillingReport.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {infinitusai.be.IGeneratedBillingReport} message GeneratedBillingReport message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedBillingReport.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.generatedAtMillis != null && Object.hasOwnProperty.call(message, "generatedAtMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.generatedAtMillis);
                    if (message.reportUuid != null && Object.hasOwnProperty.call(message, "reportUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.reportUuid);
                    if (message.reviewedMillisStart != null && Object.hasOwnProperty.call(message, "reviewedMillisStart"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.reviewedMillisStart);
                    if (message.reviewedMillisEnd != null && Object.hasOwnProperty.call(message, "reviewedMillisEnd"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.reviewedMillisEnd);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
                    if (message.gcsPath != null && Object.hasOwnProperty.call(message, "gcsPath"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.gcsPath);
                    return writer;
                };
    
                /**
                 * Encodes the specified GeneratedBillingReport message, length delimited. Does not implicitly {@link infinitusai.be.GeneratedBillingReport.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {infinitusai.be.IGeneratedBillingReport} message GeneratedBillingReport message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedBillingReport.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GeneratedBillingReport message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GeneratedBillingReport} GeneratedBillingReport
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedBillingReport.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GeneratedBillingReport();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.generatedAtMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                message.reportUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.reviewedMillisStart = reader.int64();
                                break;
                            }
                        case 4: {
                                message.reviewedMillisEnd = reader.int64();
                                break;
                            }
                        case 5: {
                                message.errorMessage = reader.string();
                                break;
                            }
                        case 6: {
                                message.gcsPath = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GeneratedBillingReport message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GeneratedBillingReport} GeneratedBillingReport
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedBillingReport.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GeneratedBillingReport message.
                 * @function verify
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GeneratedBillingReport.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (!$util.isInteger(message.generatedAtMillis) && !(message.generatedAtMillis && $util.isInteger(message.generatedAtMillis.low) && $util.isInteger(message.generatedAtMillis.high)))
                            return "generatedAtMillis: integer|Long expected";
                    if (message.reportUuid != null && message.hasOwnProperty("reportUuid"))
                        if (!$util.isString(message.reportUuid))
                            return "reportUuid: string expected";
                    if (message.reviewedMillisStart != null && message.hasOwnProperty("reviewedMillisStart"))
                        if (!$util.isInteger(message.reviewedMillisStart) && !(message.reviewedMillisStart && $util.isInteger(message.reviewedMillisStart.low) && $util.isInteger(message.reviewedMillisStart.high)))
                            return "reviewedMillisStart: integer|Long expected";
                    if (message.reviewedMillisEnd != null && message.hasOwnProperty("reviewedMillisEnd"))
                        if (!$util.isInteger(message.reviewedMillisEnd) && !(message.reviewedMillisEnd && $util.isInteger(message.reviewedMillisEnd.low) && $util.isInteger(message.reviewedMillisEnd.high)))
                            return "reviewedMillisEnd: integer|Long expected";
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        if (!$util.isString(message.gcsPath))
                            return "gcsPath: string expected";
                    return null;
                };
    
                /**
                 * Creates a GeneratedBillingReport message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GeneratedBillingReport} GeneratedBillingReport
                 */
                GeneratedBillingReport.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GeneratedBillingReport)
                        return object;
                    var message = new $root.infinitusai.be.GeneratedBillingReport();
                    if (object.generatedAtMillis != null)
                        if ($util.Long)
                            (message.generatedAtMillis = $util.Long.fromValue(object.generatedAtMillis)).unsigned = false;
                        else if (typeof object.generatedAtMillis === "string")
                            message.generatedAtMillis = parseInt(object.generatedAtMillis, 10);
                        else if (typeof object.generatedAtMillis === "number")
                            message.generatedAtMillis = object.generatedAtMillis;
                        else if (typeof object.generatedAtMillis === "object")
                            message.generatedAtMillis = new $util.LongBits(object.generatedAtMillis.low >>> 0, object.generatedAtMillis.high >>> 0).toNumber();
                    if (object.reportUuid != null)
                        message.reportUuid = String(object.reportUuid);
                    if (object.reviewedMillisStart != null)
                        if ($util.Long)
                            (message.reviewedMillisStart = $util.Long.fromValue(object.reviewedMillisStart)).unsigned = false;
                        else if (typeof object.reviewedMillisStart === "string")
                            message.reviewedMillisStart = parseInt(object.reviewedMillisStart, 10);
                        else if (typeof object.reviewedMillisStart === "number")
                            message.reviewedMillisStart = object.reviewedMillisStart;
                        else if (typeof object.reviewedMillisStart === "object")
                            message.reviewedMillisStart = new $util.LongBits(object.reviewedMillisStart.low >>> 0, object.reviewedMillisStart.high >>> 0).toNumber();
                    if (object.reviewedMillisEnd != null)
                        if ($util.Long)
                            (message.reviewedMillisEnd = $util.Long.fromValue(object.reviewedMillisEnd)).unsigned = false;
                        else if (typeof object.reviewedMillisEnd === "string")
                            message.reviewedMillisEnd = parseInt(object.reviewedMillisEnd, 10);
                        else if (typeof object.reviewedMillisEnd === "number")
                            message.reviewedMillisEnd = object.reviewedMillisEnd;
                        else if (typeof object.reviewedMillisEnd === "object")
                            message.reviewedMillisEnd = new $util.LongBits(object.reviewedMillisEnd.low >>> 0, object.reviewedMillisEnd.high >>> 0).toNumber();
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    if (object.gcsPath != null)
                        message.gcsPath = String(object.gcsPath);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GeneratedBillingReport message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {infinitusai.be.GeneratedBillingReport} message GeneratedBillingReport
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GeneratedBillingReport.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedAtMillis = options.longs === String ? "0" : 0;
                        object.reportUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedMillisStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedMillisStart = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedMillisEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedMillisEnd = options.longs === String ? "0" : 0;
                        object.errorMessage = "";
                        object.gcsPath = "";
                    }
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (typeof message.generatedAtMillis === "number")
                            object.generatedAtMillis = options.longs === String ? String(message.generatedAtMillis) : message.generatedAtMillis;
                        else
                            object.generatedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.generatedAtMillis) : options.longs === Number ? new $util.LongBits(message.generatedAtMillis.low >>> 0, message.generatedAtMillis.high >>> 0).toNumber() : message.generatedAtMillis;
                    if (message.reportUuid != null && message.hasOwnProperty("reportUuid"))
                        object.reportUuid = message.reportUuid;
                    if (message.reviewedMillisStart != null && message.hasOwnProperty("reviewedMillisStart"))
                        if (typeof message.reviewedMillisStart === "number")
                            object.reviewedMillisStart = options.longs === String ? String(message.reviewedMillisStart) : message.reviewedMillisStart;
                        else
                            object.reviewedMillisStart = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedMillisStart) : options.longs === Number ? new $util.LongBits(message.reviewedMillisStart.low >>> 0, message.reviewedMillisStart.high >>> 0).toNumber() : message.reviewedMillisStart;
                    if (message.reviewedMillisEnd != null && message.hasOwnProperty("reviewedMillisEnd"))
                        if (typeof message.reviewedMillisEnd === "number")
                            object.reviewedMillisEnd = options.longs === String ? String(message.reviewedMillisEnd) : message.reviewedMillisEnd;
                        else
                            object.reviewedMillisEnd = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedMillisEnd) : options.longs === Number ? new $util.LongBits(message.reviewedMillisEnd.low >>> 0, message.reviewedMillisEnd.high >>> 0).toNumber() : message.reviewedMillisEnd;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        object.gcsPath = message.gcsPath;
                    return object;
                };
    
                /**
                 * Converts this GeneratedBillingReport to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GeneratedBillingReport.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GeneratedBillingReport
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GeneratedBillingReport
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GeneratedBillingReport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GeneratedBillingReport";
                };
    
                return GeneratedBillingReport;
            })();
    
            be.GetBillingReportsRequest = (function() {
    
                /**
                 * Properties of a GetBillingReportsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetBillingReportsRequest
                 */
    
                /**
                 * Constructs a new GetBillingReportsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBillingReportsRequest.
                 * @implements IGetBillingReportsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetBillingReportsRequest=} [properties] Properties to set
                 */
                function GetBillingReportsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetBillingReportsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingReportsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBillingReportsRequest} GetBillingReportsRequest instance
                 */
                GetBillingReportsRequest.create = function create(properties) {
                    return new GetBillingReportsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetBillingReportsRequest message. Does not implicitly {@link infinitusai.be.GetBillingReportsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingReportsRequest} message GetBillingReportsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBillingReportsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetBillingReportsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingReportsRequest} message GetBillingReportsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBillingReportsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBillingReportsRequest} GetBillingReportsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBillingReportsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBillingReportsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBillingReportsRequest} GetBillingReportsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBillingReportsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingReportsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetBillingReportsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBillingReportsRequest} GetBillingReportsRequest
                 */
                GetBillingReportsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBillingReportsRequest)
                        return object;
                    return new $root.infinitusai.be.GetBillingReportsRequest();
                };
    
                /**
                 * Creates a plain object from a GetBillingReportsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {infinitusai.be.GetBillingReportsRequest} message GetBillingReportsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingReportsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetBillingReportsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingReportsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBillingReportsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBillingReportsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBillingReportsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBillingReportsRequest";
                };
    
                return GetBillingReportsRequest;
            })();
    
            be.GetBillingReportsResponse = (function() {
    
                /**
                 * Properties of a GetBillingReportsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetBillingReportsResponse
                 * @property {Array.<infinitusai.be.IGeneratedBillingReport>|null} [reports] GetBillingReportsResponse reports
                 */
    
                /**
                 * Constructs a new GetBillingReportsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBillingReportsResponse.
                 * @implements IGetBillingReportsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetBillingReportsResponse=} [properties] Properties to set
                 */
                function GetBillingReportsResponse(properties) {
                    this.reports = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBillingReportsResponse reports.
                 * @member {Array.<infinitusai.be.IGeneratedBillingReport>} reports
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @instance
                 */
                GetBillingReportsResponse.prototype.reports = $util.emptyArray;
    
                /**
                 * Creates a new GetBillingReportsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingReportsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBillingReportsResponse} GetBillingReportsResponse instance
                 */
                GetBillingReportsResponse.create = function create(properties) {
                    return new GetBillingReportsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetBillingReportsResponse message. Does not implicitly {@link infinitusai.be.GetBillingReportsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingReportsResponse} message GetBillingReportsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reports != null && message.reports.length)
                        for (var i = 0; i < message.reports.length; ++i)
                            $root.infinitusai.be.GeneratedBillingReport.encode(message.reports[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBillingReportsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetBillingReportsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingReportsResponse} message GetBillingReportsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBillingReportsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBillingReportsResponse} GetBillingReportsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBillingReportsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.reports && message.reports.length))
                                    message.reports = [];
                                message.reports.push($root.infinitusai.be.GeneratedBillingReport.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBillingReportsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBillingReportsResponse} GetBillingReportsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBillingReportsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingReportsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reports != null && message.hasOwnProperty("reports")) {
                        if (!Array.isArray(message.reports))
                            return "reports: array expected";
                        for (var i = 0; i < message.reports.length; ++i) {
                            var error = $root.infinitusai.be.GeneratedBillingReport.verify(message.reports[i]);
                            if (error)
                                return "reports." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetBillingReportsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBillingReportsResponse} GetBillingReportsResponse
                 */
                GetBillingReportsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBillingReportsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetBillingReportsResponse();
                    if (object.reports) {
                        if (!Array.isArray(object.reports))
                            throw TypeError(".infinitusai.be.GetBillingReportsResponse.reports: array expected");
                        message.reports = [];
                        for (var i = 0; i < object.reports.length; ++i) {
                            if (typeof object.reports[i] !== "object")
                                throw TypeError(".infinitusai.be.GetBillingReportsResponse.reports: object expected");
                            message.reports[i] = $root.infinitusai.be.GeneratedBillingReport.fromObject(object.reports[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBillingReportsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {infinitusai.be.GetBillingReportsResponse} message GetBillingReportsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingReportsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.reports = [];
                    if (message.reports && message.reports.length) {
                        object.reports = [];
                        for (var j = 0; j < message.reports.length; ++j)
                            object.reports[j] = $root.infinitusai.be.GeneratedBillingReport.toObject(message.reports[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetBillingReportsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingReportsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBillingReportsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBillingReportsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBillingReportsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBillingReportsResponse";
                };
    
                return GetBillingReportsResponse;
            })();
    
            be.GetBillingReportURLRequest = (function() {
    
                /**
                 * Properties of a GetBillingReportURLRequest.
                 * @memberof infinitusai.be
                 * @interface IGetBillingReportURLRequest
                 * @property {string|null} [reportUuid] GetBillingReportURLRequest reportUuid
                 */
    
                /**
                 * Constructs a new GetBillingReportURLRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBillingReportURLRequest.
                 * @implements IGetBillingReportURLRequest
                 * @constructor
                 * @param {infinitusai.be.IGetBillingReportURLRequest=} [properties] Properties to set
                 */
                function GetBillingReportURLRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBillingReportURLRequest reportUuid.
                 * @member {string} reportUuid
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @instance
                 */
                GetBillingReportURLRequest.prototype.reportUuid = "";
    
                /**
                 * Creates a new GetBillingReportURLRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingReportURLRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBillingReportURLRequest} GetBillingReportURLRequest instance
                 */
                GetBillingReportURLRequest.create = function create(properties) {
                    return new GetBillingReportURLRequest(properties);
                };
    
                /**
                 * Encodes the specified GetBillingReportURLRequest message. Does not implicitly {@link infinitusai.be.GetBillingReportURLRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingReportURLRequest} message GetBillingReportURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportURLRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reportUuid != null && Object.hasOwnProperty.call(message, "reportUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.reportUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBillingReportURLRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetBillingReportURLRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingReportURLRequest} message GetBillingReportURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportURLRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBillingReportURLRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBillingReportURLRequest} GetBillingReportURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportURLRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBillingReportURLRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.reportUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBillingReportURLRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBillingReportURLRequest} GetBillingReportURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportURLRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBillingReportURLRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingReportURLRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reportUuid != null && message.hasOwnProperty("reportUuid"))
                        if (!$util.isString(message.reportUuid))
                            return "reportUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetBillingReportURLRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBillingReportURLRequest} GetBillingReportURLRequest
                 */
                GetBillingReportURLRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBillingReportURLRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetBillingReportURLRequest();
                    if (object.reportUuid != null)
                        message.reportUuid = String(object.reportUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBillingReportURLRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {infinitusai.be.GetBillingReportURLRequest} message GetBillingReportURLRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingReportURLRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.reportUuid = "";
                    if (message.reportUuid != null && message.hasOwnProperty("reportUuid"))
                        object.reportUuid = message.reportUuid;
                    return object;
                };
    
                /**
                 * Converts this GetBillingReportURLRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingReportURLRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBillingReportURLRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBillingReportURLRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBillingReportURLRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBillingReportURLRequest";
                };
    
                return GetBillingReportURLRequest;
            })();
    
            be.GetBillingReportURLResponse = (function() {
    
                /**
                 * Properties of a GetBillingReportURLResponse.
                 * @memberof infinitusai.be
                 * @interface IGetBillingReportURLResponse
                 * @property {string|null} [downloadUrl] GetBillingReportURLResponse downloadUrl
                 */
    
                /**
                 * Constructs a new GetBillingReportURLResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBillingReportURLResponse.
                 * @implements IGetBillingReportURLResponse
                 * @constructor
                 * @param {infinitusai.be.IGetBillingReportURLResponse=} [properties] Properties to set
                 */
                function GetBillingReportURLResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBillingReportURLResponse downloadUrl.
                 * @member {string} downloadUrl
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @instance
                 */
                GetBillingReportURLResponse.prototype.downloadUrl = "";
    
                /**
                 * Creates a new GetBillingReportURLResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingReportURLResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBillingReportURLResponse} GetBillingReportURLResponse instance
                 */
                GetBillingReportURLResponse.create = function create(properties) {
                    return new GetBillingReportURLResponse(properties);
                };
    
                /**
                 * Encodes the specified GetBillingReportURLResponse message. Does not implicitly {@link infinitusai.be.GetBillingReportURLResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingReportURLResponse} message GetBillingReportURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportURLResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.downloadUrl != null && Object.hasOwnProperty.call(message, "downloadUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.downloadUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBillingReportURLResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetBillingReportURLResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingReportURLResponse} message GetBillingReportURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportURLResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBillingReportURLResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBillingReportURLResponse} GetBillingReportURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportURLResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBillingReportURLResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.downloadUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBillingReportURLResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBillingReportURLResponse} GetBillingReportURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportURLResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBillingReportURLResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingReportURLResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.downloadUrl != null && message.hasOwnProperty("downloadUrl"))
                        if (!$util.isString(message.downloadUrl))
                            return "downloadUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetBillingReportURLResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBillingReportURLResponse} GetBillingReportURLResponse
                 */
                GetBillingReportURLResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBillingReportURLResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetBillingReportURLResponse();
                    if (object.downloadUrl != null)
                        message.downloadUrl = String(object.downloadUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBillingReportURLResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {infinitusai.be.GetBillingReportURLResponse} message GetBillingReportURLResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingReportURLResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.downloadUrl = "";
                    if (message.downloadUrl != null && message.hasOwnProperty("downloadUrl"))
                        object.downloadUrl = message.downloadUrl;
                    return object;
                };
    
                /**
                 * Converts this GetBillingReportURLResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingReportURLResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBillingReportURLResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBillingReportURLResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBillingReportURLResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBillingReportURLResponse";
                };
    
                return GetBillingReportURLResponse;
            })();
    
            be.GetBcbsEligibilityInfoRequest = (function() {
    
                /**
                 * Properties of a GetBcbsEligibilityInfoRequest.
                 * @memberof infinitusai.be
                 * @interface IGetBcbsEligibilityInfoRequest
                 * @property {infinitusai.be.IPolicyInfo|null} [policyInfo] GetBcbsEligibilityInfoRequest policyInfo
                 * @property {infinitusai.be.IPracticeInfo|null} [practiceInfo] GetBcbsEligibilityInfoRequest practiceInfo
                 * @property {infinitusai.be.IProviderInfo|null} [providerInfo] GetBcbsEligibilityInfoRequest providerInfo
                 */
    
                /**
                 * Constructs a new GetBcbsEligibilityInfoRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBcbsEligibilityInfoRequest.
                 * @implements IGetBcbsEligibilityInfoRequest
                 * @constructor
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoRequest=} [properties] Properties to set
                 */
                function GetBcbsEligibilityInfoRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBcbsEligibilityInfoRequest policyInfo.
                 * @member {infinitusai.be.IPolicyInfo|null|undefined} policyInfo
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @instance
                 */
                GetBcbsEligibilityInfoRequest.prototype.policyInfo = null;
    
                /**
                 * GetBcbsEligibilityInfoRequest practiceInfo.
                 * @member {infinitusai.be.IPracticeInfo|null|undefined} practiceInfo
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @instance
                 */
                GetBcbsEligibilityInfoRequest.prototype.practiceInfo = null;
    
                /**
                 * GetBcbsEligibilityInfoRequest providerInfo.
                 * @member {infinitusai.be.IProviderInfo|null|undefined} providerInfo
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @instance
                 */
                GetBcbsEligibilityInfoRequest.prototype.providerInfo = null;
    
                /**
                 * Creates a new GetBcbsEligibilityInfoRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBcbsEligibilityInfoRequest} GetBcbsEligibilityInfoRequest instance
                 */
                GetBcbsEligibilityInfoRequest.create = function create(properties) {
                    return new GetBcbsEligibilityInfoRequest(properties);
                };
    
                /**
                 * Encodes the specified GetBcbsEligibilityInfoRequest message. Does not implicitly {@link infinitusai.be.GetBcbsEligibilityInfoRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoRequest} message GetBcbsEligibilityInfoRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBcbsEligibilityInfoRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.policyInfo != null && Object.hasOwnProperty.call(message, "policyInfo"))
                        $root.infinitusai.be.PolicyInfo.encode(message.policyInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.practiceInfo != null && Object.hasOwnProperty.call(message, "practiceInfo"))
                        $root.infinitusai.be.PracticeInfo.encode(message.practiceInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.providerInfo != null && Object.hasOwnProperty.call(message, "providerInfo"))
                        $root.infinitusai.be.ProviderInfo.encode(message.providerInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBcbsEligibilityInfoRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetBcbsEligibilityInfoRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoRequest} message GetBcbsEligibilityInfoRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBcbsEligibilityInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBcbsEligibilityInfoRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBcbsEligibilityInfoRequest} GetBcbsEligibilityInfoRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBcbsEligibilityInfoRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBcbsEligibilityInfoRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.policyInfo = $root.infinitusai.be.PolicyInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.practiceInfo = $root.infinitusai.be.PracticeInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.providerInfo = $root.infinitusai.be.ProviderInfo.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBcbsEligibilityInfoRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBcbsEligibilityInfoRequest} GetBcbsEligibilityInfoRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBcbsEligibilityInfoRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBcbsEligibilityInfoRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBcbsEligibilityInfoRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.policyInfo != null && message.hasOwnProperty("policyInfo")) {
                        var error = $root.infinitusai.be.PolicyInfo.verify(message.policyInfo);
                        if (error)
                            return "policyInfo." + error;
                    }
                    if (message.practiceInfo != null && message.hasOwnProperty("practiceInfo")) {
                        var error = $root.infinitusai.be.PracticeInfo.verify(message.practiceInfo);
                        if (error)
                            return "practiceInfo." + error;
                    }
                    if (message.providerInfo != null && message.hasOwnProperty("providerInfo")) {
                        var error = $root.infinitusai.be.ProviderInfo.verify(message.providerInfo);
                        if (error)
                            return "providerInfo." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetBcbsEligibilityInfoRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBcbsEligibilityInfoRequest} GetBcbsEligibilityInfoRequest
                 */
                GetBcbsEligibilityInfoRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBcbsEligibilityInfoRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetBcbsEligibilityInfoRequest();
                    if (object.policyInfo != null) {
                        if (typeof object.policyInfo !== "object")
                            throw TypeError(".infinitusai.be.GetBcbsEligibilityInfoRequest.policyInfo: object expected");
                        message.policyInfo = $root.infinitusai.be.PolicyInfo.fromObject(object.policyInfo);
                    }
                    if (object.practiceInfo != null) {
                        if (typeof object.practiceInfo !== "object")
                            throw TypeError(".infinitusai.be.GetBcbsEligibilityInfoRequest.practiceInfo: object expected");
                        message.practiceInfo = $root.infinitusai.be.PracticeInfo.fromObject(object.practiceInfo);
                    }
                    if (object.providerInfo != null) {
                        if (typeof object.providerInfo !== "object")
                            throw TypeError(".infinitusai.be.GetBcbsEligibilityInfoRequest.providerInfo: object expected");
                        message.providerInfo = $root.infinitusai.be.ProviderInfo.fromObject(object.providerInfo);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBcbsEligibilityInfoRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {infinitusai.be.GetBcbsEligibilityInfoRequest} message GetBcbsEligibilityInfoRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBcbsEligibilityInfoRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.policyInfo = null;
                        object.practiceInfo = null;
                        object.providerInfo = null;
                    }
                    if (message.policyInfo != null && message.hasOwnProperty("policyInfo"))
                        object.policyInfo = $root.infinitusai.be.PolicyInfo.toObject(message.policyInfo, options);
                    if (message.practiceInfo != null && message.hasOwnProperty("practiceInfo"))
                        object.practiceInfo = $root.infinitusai.be.PracticeInfo.toObject(message.practiceInfo, options);
                    if (message.providerInfo != null && message.hasOwnProperty("providerInfo"))
                        object.providerInfo = $root.infinitusai.be.ProviderInfo.toObject(message.providerInfo, options);
                    return object;
                };
    
                /**
                 * Converts this GetBcbsEligibilityInfoRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBcbsEligibilityInfoRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBcbsEligibilityInfoRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBcbsEligibilityInfoRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBcbsEligibilityInfoRequest";
                };
    
                return GetBcbsEligibilityInfoRequest;
            })();
    
            be.GetBcbsEligibilityInfoResponse = (function() {
    
                /**
                 * Properties of a GetBcbsEligibilityInfoResponse.
                 * @memberof infinitusai.be
                 * @interface IGetBcbsEligibilityInfoResponse
                 * @property {infinitusai.be.GetBcbsEligibilityInfoResponse.NetworkStatus|null} [networkStatus] GetBcbsEligibilityInfoResponse networkStatus
                 */
    
                /**
                 * Constructs a new GetBcbsEligibilityInfoResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBcbsEligibilityInfoResponse.
                 * @implements IGetBcbsEligibilityInfoResponse
                 * @constructor
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoResponse=} [properties] Properties to set
                 */
                function GetBcbsEligibilityInfoResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBcbsEligibilityInfoResponse networkStatus.
                 * @member {infinitusai.be.GetBcbsEligibilityInfoResponse.NetworkStatus} networkStatus
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @instance
                 */
                GetBcbsEligibilityInfoResponse.prototype.networkStatus = 0;
    
                /**
                 * Creates a new GetBcbsEligibilityInfoResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBcbsEligibilityInfoResponse} GetBcbsEligibilityInfoResponse instance
                 */
                GetBcbsEligibilityInfoResponse.create = function create(properties) {
                    return new GetBcbsEligibilityInfoResponse(properties);
                };
    
                /**
                 * Encodes the specified GetBcbsEligibilityInfoResponse message. Does not implicitly {@link infinitusai.be.GetBcbsEligibilityInfoResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoResponse} message GetBcbsEligibilityInfoResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBcbsEligibilityInfoResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.networkStatus);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBcbsEligibilityInfoResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetBcbsEligibilityInfoResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoResponse} message GetBcbsEligibilityInfoResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBcbsEligibilityInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBcbsEligibilityInfoResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBcbsEligibilityInfoResponse} GetBcbsEligibilityInfoResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBcbsEligibilityInfoResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBcbsEligibilityInfoResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.networkStatus = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBcbsEligibilityInfoResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBcbsEligibilityInfoResponse} GetBcbsEligibilityInfoResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBcbsEligibilityInfoResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBcbsEligibilityInfoResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBcbsEligibilityInfoResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                        switch (message.networkStatus) {
                        default:
                            return "networkStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GetBcbsEligibilityInfoResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBcbsEligibilityInfoResponse} GetBcbsEligibilityInfoResponse
                 */
                GetBcbsEligibilityInfoResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBcbsEligibilityInfoResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetBcbsEligibilityInfoResponse();
                    switch (object.networkStatus) {
                    default:
                        if (typeof object.networkStatus === "number") {
                            message.networkStatus = object.networkStatus;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.networkStatus = 0;
                        break;
                    case "IN_NETWORK":
                    case 1:
                        message.networkStatus = 1;
                        break;
                    case "IN_NETWORK_DIFFERENT_ADDRESS":
                    case 2:
                        message.networkStatus = 2;
                        break;
                    case "OUT_OF_NETWORK":
                    case 3:
                        message.networkStatus = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBcbsEligibilityInfoResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {infinitusai.be.GetBcbsEligibilityInfoResponse} message GetBcbsEligibilityInfoResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBcbsEligibilityInfoResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.networkStatus = options.enums === String ? "UNKNOWN" : 0;
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                        object.networkStatus = options.enums === String ? $root.infinitusai.be.GetBcbsEligibilityInfoResponse.NetworkStatus[message.networkStatus] === undefined ? message.networkStatus : $root.infinitusai.be.GetBcbsEligibilityInfoResponse.NetworkStatus[message.networkStatus] : message.networkStatus;
                    return object;
                };
    
                /**
                 * Converts this GetBcbsEligibilityInfoResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBcbsEligibilityInfoResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBcbsEligibilityInfoResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBcbsEligibilityInfoResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBcbsEligibilityInfoResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBcbsEligibilityInfoResponse";
                };
    
                /**
                 * NetworkStatus enum.
                 * @name infinitusai.be.GetBcbsEligibilityInfoResponse.NetworkStatus
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} IN_NETWORK=1 IN_NETWORK value
                 * @property {number} IN_NETWORK_DIFFERENT_ADDRESS=2 IN_NETWORK_DIFFERENT_ADDRESS value
                 * @property {number} OUT_OF_NETWORK=3 OUT_OF_NETWORK value
                 */
                GetBcbsEligibilityInfoResponse.NetworkStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "IN_NETWORK"] = 1;
                    values[valuesById[2] = "IN_NETWORK_DIFFERENT_ADDRESS"] = 2;
                    values[valuesById[3] = "OUT_OF_NETWORK"] = 3;
                    return values;
                })();
    
                return GetBcbsEligibilityInfoResponse;
            })();
    
            be.Birthday = (function() {
    
                /**
                 * Properties of a Birthday.
                 * @memberof infinitusai.be
                 * @interface IBirthday
                 * @property {number|null} [day] Birthday day
                 * @property {number|null} [month] Birthday month
                 * @property {number|null} [year] Birthday year
                 */
    
                /**
                 * Constructs a new Birthday.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Birthday.
                 * @implements IBirthday
                 * @constructor
                 * @param {infinitusai.be.IBirthday=} [properties] Properties to set
                 */
                function Birthday(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Birthday day.
                 * @member {number} day
                 * @memberof infinitusai.be.Birthday
                 * @instance
                 */
                Birthday.prototype.day = 0;
    
                /**
                 * Birthday month.
                 * @member {number} month
                 * @memberof infinitusai.be.Birthday
                 * @instance
                 */
                Birthday.prototype.month = 0;
    
                /**
                 * Birthday year.
                 * @member {number} year
                 * @memberof infinitusai.be.Birthday
                 * @instance
                 */
                Birthday.prototype.year = 0;
    
                /**
                 * Creates a new Birthday instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {infinitusai.be.IBirthday=} [properties] Properties to set
                 * @returns {infinitusai.be.Birthday} Birthday instance
                 */
                Birthday.create = function create(properties) {
                    return new Birthday(properties);
                };
    
                /**
                 * Encodes the specified Birthday message. Does not implicitly {@link infinitusai.be.Birthday.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {infinitusai.be.IBirthday} message Birthday message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Birthday.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.day);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.year);
                    return writer;
                };
    
                /**
                 * Encodes the specified Birthday message, length delimited. Does not implicitly {@link infinitusai.be.Birthday.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {infinitusai.be.IBirthday} message Birthday message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Birthday.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Birthday message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Birthday} Birthday
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Birthday.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Birthday();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.day = reader.int32();
                                break;
                            }
                        case 2: {
                                message.month = reader.int32();
                                break;
                            }
                        case 3: {
                                message.year = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Birthday message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Birthday} Birthday
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Birthday.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Birthday message.
                 * @function verify
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Birthday.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.day != null && message.hasOwnProperty("day"))
                        if (!$util.isInteger(message.day))
                            return "day: integer expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isInteger(message.month))
                            return "month: integer expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    return null;
                };
    
                /**
                 * Creates a Birthday message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Birthday} Birthday
                 */
                Birthday.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Birthday)
                        return object;
                    var message = new $root.infinitusai.be.Birthday();
                    if (object.day != null)
                        message.day = object.day | 0;
                    if (object.month != null)
                        message.month = object.month | 0;
                    if (object.year != null)
                        message.year = object.year | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a Birthday message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {infinitusai.be.Birthday} message Birthday
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Birthday.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.day = 0;
                        object.month = 0;
                        object.year = 0;
                    }
                    if (message.day != null && message.hasOwnProperty("day"))
                        object.day = message.day;
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    return object;
                };
    
                /**
                 * Converts this Birthday to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Birthday
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Birthday.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Birthday
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Birthday
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Birthday.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Birthday";
                };
    
                return Birthday;
            })();
    
            be.FacilityType = (function() {
    
                /**
                 * Properties of a FacilityType.
                 * @memberof infinitusai.be
                 * @interface IFacilityType
                 * @property {infinitusai.be.FacilityType.Type|null} [type] FacilityType type
                 */
    
                /**
                 * Constructs a new FacilityType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FacilityType.
                 * @implements IFacilityType
                 * @constructor
                 * @param {infinitusai.be.IFacilityType=} [properties] Properties to set
                 */
                function FacilityType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FacilityType type.
                 * @member {infinitusai.be.FacilityType.Type} type
                 * @memberof infinitusai.be.FacilityType
                 * @instance
                 */
                FacilityType.prototype.type = 0;
    
                /**
                 * Creates a new FacilityType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {infinitusai.be.IFacilityType=} [properties] Properties to set
                 * @returns {infinitusai.be.FacilityType} FacilityType instance
                 */
                FacilityType.create = function create(properties) {
                    return new FacilityType(properties);
                };
    
                /**
                 * Encodes the specified FacilityType message. Does not implicitly {@link infinitusai.be.FacilityType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {infinitusai.be.IFacilityType} message FacilityType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FacilityType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    return writer;
                };
    
                /**
                 * Encodes the specified FacilityType message, length delimited. Does not implicitly {@link infinitusai.be.FacilityType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {infinitusai.be.IFacilityType} message FacilityType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FacilityType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FacilityType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FacilityType} FacilityType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FacilityType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FacilityType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FacilityType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FacilityType} FacilityType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FacilityType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FacilityType message.
                 * @function verify
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FacilityType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a FacilityType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FacilityType} FacilityType
                 */
                FacilityType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FacilityType)
                        return object;
                    var message = new $root.infinitusai.be.FacilityType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "HOSPITAL_OUTPATIENT":
                    case 1:
                        message.type = 1;
                        break;
                    case "PHARMACY":
                    case 2:
                        message.type = 2;
                        break;
                    case "SPECIALIST_OFFICE":
                    case 3:
                        message.type = 3;
                        break;
                    case "SHIP_TO_HOME":
                    case 4:
                        message.type = 4;
                        break;
                    case "INFUSION_CENTER":
                    case 5:
                        message.type = 5;
                        break;
                    case "AMBULATORY_SURGICAL_CENTER":
                    case 6:
                        message.type = 6;
                        break;
                    case "HOME_INFUSION":
                    case 7:
                        message.type = 7;
                        break;
                    case "HOSPITAL_OUTPATIENT_OFF_CAMPUS":
                    case 8:
                        message.type = 8;
                        break;
                    case "HOSPITAL_INPATIENT":
                    case 9:
                        message.type = 9;
                        break;
                    case "SNF":
                    case 10:
                        message.type = 10;
                        break;
                    case "HOSPICE":
                    case 11:
                        message.type = 11;
                        break;
                    case "IHC":
                    case 12:
                        message.type = 12;
                        break;
                    case "FED_QUALIFIED_HC":
                    case 13:
                        message.type = 13;
                        break;
                    case "INPATIENT_REHAB":
                    case 14:
                        message.type = 14;
                        break;
                    case "OUTPATIENT_REHAB":
                    case 15:
                        message.type = 15;
                        break;
                    case "PUBLIC_HEALTH_CLINIC":
                    case 16:
                        message.type = 16;
                        break;
                    case "RURAL_HEALTH_CLINIC":
                    case 17:
                        message.type = 17;
                        break;
                    case "DIAGNOSTIC_TEST_LAB":
                    case 18:
                        message.type = 18;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FacilityType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {infinitusai.be.FacilityType} message FacilityType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FacilityType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.FacilityType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.FacilityType.Type[message.type] : message.type;
                    return object;
                };
    
                /**
                 * Converts this FacilityType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FacilityType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FacilityType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FacilityType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FacilityType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FacilityType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FacilityType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.FacilityType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} HOSPITAL_OUTPATIENT=1 HOSPITAL_OUTPATIENT value
                 * @property {number} PHARMACY=2 PHARMACY value
                 * @property {number} SPECIALIST_OFFICE=3 SPECIALIST_OFFICE value
                 * @property {number} SHIP_TO_HOME=4 SHIP_TO_HOME value
                 * @property {number} INFUSION_CENTER=5 INFUSION_CENTER value
                 * @property {number} AMBULATORY_SURGICAL_CENTER=6 AMBULATORY_SURGICAL_CENTER value
                 * @property {number} HOME_INFUSION=7 HOME_INFUSION value
                 * @property {number} HOSPITAL_OUTPATIENT_OFF_CAMPUS=8 HOSPITAL_OUTPATIENT_OFF_CAMPUS value
                 * @property {number} HOSPITAL_INPATIENT=9 HOSPITAL_INPATIENT value
                 * @property {number} SNF=10 SNF value
                 * @property {number} HOSPICE=11 HOSPICE value
                 * @property {number} IHC=12 IHC value
                 * @property {number} FED_QUALIFIED_HC=13 FED_QUALIFIED_HC value
                 * @property {number} INPATIENT_REHAB=14 INPATIENT_REHAB value
                 * @property {number} OUTPATIENT_REHAB=15 OUTPATIENT_REHAB value
                 * @property {number} PUBLIC_HEALTH_CLINIC=16 PUBLIC_HEALTH_CLINIC value
                 * @property {number} RURAL_HEALTH_CLINIC=17 RURAL_HEALTH_CLINIC value
                 * @property {number} DIAGNOSTIC_TEST_LAB=18 DIAGNOSTIC_TEST_LAB value
                 */
                FacilityType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "HOSPITAL_OUTPATIENT"] = 1;
                    values[valuesById[2] = "PHARMACY"] = 2;
                    values[valuesById[3] = "SPECIALIST_OFFICE"] = 3;
                    values[valuesById[4] = "SHIP_TO_HOME"] = 4;
                    values[valuesById[5] = "INFUSION_CENTER"] = 5;
                    values[valuesById[6] = "AMBULATORY_SURGICAL_CENTER"] = 6;
                    values[valuesById[7] = "HOME_INFUSION"] = 7;
                    values[valuesById[8] = "HOSPITAL_OUTPATIENT_OFF_CAMPUS"] = 8;
                    values[valuesById[9] = "HOSPITAL_INPATIENT"] = 9;
                    values[valuesById[10] = "SNF"] = 10;
                    values[valuesById[11] = "HOSPICE"] = 11;
                    values[valuesById[12] = "IHC"] = 12;
                    values[valuesById[13] = "FED_QUALIFIED_HC"] = 13;
                    values[valuesById[14] = "INPATIENT_REHAB"] = 14;
                    values[valuesById[15] = "OUTPATIENT_REHAB"] = 15;
                    values[valuesById[16] = "PUBLIC_HEALTH_CLINIC"] = 16;
                    values[valuesById[17] = "RURAL_HEALTH_CLINIC"] = 17;
                    values[valuesById[18] = "DIAGNOSTIC_TEST_LAB"] = 18;
                    return values;
                })();
    
                return FacilityType;
            })();
    
            be.CallerInfo = (function() {
    
                /**
                 * Properties of a CallerInfo.
                 * @memberof infinitusai.be
                 * @interface ICallerInfo
                 * @property {string|null} [name] CallerInfo name
                 * @property {string|null} [entityName] CallerInfo entityName
                 * @property {string|null} [callbackPhoneNumber] CallerInfo callbackPhoneNumber
                 * @property {string|null} [introSentence] CallerInfo introSentence
                 */
    
                /**
                 * Constructs a new CallerInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallerInfo.
                 * @implements ICallerInfo
                 * @constructor
                 * @param {infinitusai.be.ICallerInfo=} [properties] Properties to set
                 */
                function CallerInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallerInfo name.
                 * @member {string} name
                 * @memberof infinitusai.be.CallerInfo
                 * @instance
                 */
                CallerInfo.prototype.name = "";
    
                /**
                 * CallerInfo entityName.
                 * @member {string} entityName
                 * @memberof infinitusai.be.CallerInfo
                 * @instance
                 */
                CallerInfo.prototype.entityName = "";
    
                /**
                 * CallerInfo callbackPhoneNumber.
                 * @member {string} callbackPhoneNumber
                 * @memberof infinitusai.be.CallerInfo
                 * @instance
                 */
                CallerInfo.prototype.callbackPhoneNumber = "";
    
                /**
                 * CallerInfo introSentence.
                 * @member {string} introSentence
                 * @memberof infinitusai.be.CallerInfo
                 * @instance
                 */
                CallerInfo.prototype.introSentence = "";
    
                /**
                 * Creates a new CallerInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {infinitusai.be.ICallerInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.CallerInfo} CallerInfo instance
                 */
                CallerInfo.create = function create(properties) {
                    return new CallerInfo(properties);
                };
    
                /**
                 * Encodes the specified CallerInfo message. Does not implicitly {@link infinitusai.be.CallerInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {infinitusai.be.ICallerInfo} message CallerInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallerInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.entityName != null && Object.hasOwnProperty.call(message, "entityName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.entityName);
                    if (message.callbackPhoneNumber != null && Object.hasOwnProperty.call(message, "callbackPhoneNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callbackPhoneNumber);
                    if (message.introSentence != null && Object.hasOwnProperty.call(message, "introSentence"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.introSentence);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallerInfo message, length delimited. Does not implicitly {@link infinitusai.be.CallerInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {infinitusai.be.ICallerInfo} message CallerInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallerInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallerInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallerInfo} CallerInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallerInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallerInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.entityName = reader.string();
                                break;
                            }
                        case 3: {
                                message.callbackPhoneNumber = reader.string();
                                break;
                            }
                        case 4: {
                                message.introSentence = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallerInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallerInfo} CallerInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallerInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallerInfo message.
                 * @function verify
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallerInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.entityName != null && message.hasOwnProperty("entityName"))
                        if (!$util.isString(message.entityName))
                            return "entityName: string expected";
                    if (message.callbackPhoneNumber != null && message.hasOwnProperty("callbackPhoneNumber"))
                        if (!$util.isString(message.callbackPhoneNumber))
                            return "callbackPhoneNumber: string expected";
                    if (message.introSentence != null && message.hasOwnProperty("introSentence"))
                        if (!$util.isString(message.introSentence))
                            return "introSentence: string expected";
                    return null;
                };
    
                /**
                 * Creates a CallerInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallerInfo} CallerInfo
                 */
                CallerInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallerInfo)
                        return object;
                    var message = new $root.infinitusai.be.CallerInfo();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.entityName != null)
                        message.entityName = String(object.entityName);
                    if (object.callbackPhoneNumber != null)
                        message.callbackPhoneNumber = String(object.callbackPhoneNumber);
                    if (object.introSentence != null)
                        message.introSentence = String(object.introSentence);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallerInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {infinitusai.be.CallerInfo} message CallerInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallerInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.entityName = "";
                        object.callbackPhoneNumber = "";
                        object.introSentence = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.entityName != null && message.hasOwnProperty("entityName"))
                        object.entityName = message.entityName;
                    if (message.callbackPhoneNumber != null && message.hasOwnProperty("callbackPhoneNumber"))
                        object.callbackPhoneNumber = message.callbackPhoneNumber;
                    if (message.introSentence != null && message.hasOwnProperty("introSentence"))
                        object.introSentence = message.introSentence;
                    return object;
                };
    
                /**
                 * Converts this CallerInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallerInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallerInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallerInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallerInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallerInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallerInfo";
                };
    
                return CallerInfo;
            })();
    
            be.PatientInfo = (function() {
    
                /**
                 * Properties of a PatientInfo.
                 * @memberof infinitusai.be
                 * @interface IPatientInfo
                 * @property {string|null} [firstName] PatientInfo firstName
                 * @property {string|null} [lastName] PatientInfo lastName
                 * @property {infinitusai.be.IBirthday|null} [birthday] PatientInfo birthday
                 * @property {infinitusai.be.IAddressInfo|null} [addressInfo] PatientInfo addressInfo
                 * @property {string|null} [phoneNumber] PatientInfo phoneNumber
                 */
    
                /**
                 * Constructs a new PatientInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PatientInfo.
                 * @implements IPatientInfo
                 * @constructor
                 * @param {infinitusai.be.IPatientInfo=} [properties] Properties to set
                 */
                function PatientInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PatientInfo firstName.
                 * @member {string} firstName
                 * @memberof infinitusai.be.PatientInfo
                 * @instance
                 */
                PatientInfo.prototype.firstName = "";
    
                /**
                 * PatientInfo lastName.
                 * @member {string} lastName
                 * @memberof infinitusai.be.PatientInfo
                 * @instance
                 */
                PatientInfo.prototype.lastName = "";
    
                /**
                 * PatientInfo birthday.
                 * @member {infinitusai.be.IBirthday|null|undefined} birthday
                 * @memberof infinitusai.be.PatientInfo
                 * @instance
                 */
                PatientInfo.prototype.birthday = null;
    
                /**
                 * PatientInfo addressInfo.
                 * @member {infinitusai.be.IAddressInfo|null|undefined} addressInfo
                 * @memberof infinitusai.be.PatientInfo
                 * @instance
                 */
                PatientInfo.prototype.addressInfo = null;
    
                /**
                 * PatientInfo phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.PatientInfo
                 * @instance
                 */
                PatientInfo.prototype.phoneNumber = "";
    
                /**
                 * Creates a new PatientInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {infinitusai.be.IPatientInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.PatientInfo} PatientInfo instance
                 */
                PatientInfo.create = function create(properties) {
                    return new PatientInfo(properties);
                };
    
                /**
                 * Encodes the specified PatientInfo message. Does not implicitly {@link infinitusai.be.PatientInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {infinitusai.be.IPatientInfo} message PatientInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PatientInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                        $root.infinitusai.be.Birthday.encode(message.birthday, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.addressInfo != null && Object.hasOwnProperty.call(message, "addressInfo"))
                        $root.infinitusai.be.AddressInfo.encode(message.addressInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.phoneNumber);
                    return writer;
                };
    
                /**
                 * Encodes the specified PatientInfo message, length delimited. Does not implicitly {@link infinitusai.be.PatientInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {infinitusai.be.IPatientInfo} message PatientInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PatientInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PatientInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PatientInfo} PatientInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PatientInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PatientInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 2: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 3: {
                                message.birthday = $root.infinitusai.be.Birthday.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.addressInfo = $root.infinitusai.be.AddressInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PatientInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PatientInfo} PatientInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PatientInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PatientInfo message.
                 * @function verify
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PatientInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.birthday != null && message.hasOwnProperty("birthday")) {
                        var error = $root.infinitusai.be.Birthday.verify(message.birthday);
                        if (error)
                            return "birthday." + error;
                    }
                    if (message.addressInfo != null && message.hasOwnProperty("addressInfo")) {
                        var error = $root.infinitusai.be.AddressInfo.verify(message.addressInfo);
                        if (error)
                            return "addressInfo." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    return null;
                };
    
                /**
                 * Creates a PatientInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PatientInfo} PatientInfo
                 */
                PatientInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PatientInfo)
                        return object;
                    var message = new $root.infinitusai.be.PatientInfo();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.birthday != null) {
                        if (typeof object.birthday !== "object")
                            throw TypeError(".infinitusai.be.PatientInfo.birthday: object expected");
                        message.birthday = $root.infinitusai.be.Birthday.fromObject(object.birthday);
                    }
                    if (object.addressInfo != null) {
                        if (typeof object.addressInfo !== "object")
                            throw TypeError(".infinitusai.be.PatientInfo.addressInfo: object expected");
                        message.addressInfo = $root.infinitusai.be.AddressInfo.fromObject(object.addressInfo);
                    }
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PatientInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {infinitusai.be.PatientInfo} message PatientInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PatientInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                        object.birthday = null;
                        object.addressInfo = null;
                        object.phoneNumber = "";
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.birthday != null && message.hasOwnProperty("birthday"))
                        object.birthday = $root.infinitusai.be.Birthday.toObject(message.birthday, options);
                    if (message.addressInfo != null && message.hasOwnProperty("addressInfo"))
                        object.addressInfo = $root.infinitusai.be.AddressInfo.toObject(message.addressInfo, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    return object;
                };
    
                /**
                 * Converts this PatientInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PatientInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PatientInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PatientInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PatientInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PatientInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PatientInfo";
                };
    
                return PatientInfo;
            })();
    
            be.CptInfo = (function() {
    
                /**
                 * Properties of a CptInfo.
                 * @memberof infinitusai.be
                 * @interface ICptInfo
                 * @property {string|null} [cptCode] CptInfo cptCode
                 * @property {string|null} [cptDescription] CptInfo cptDescription
                 */
    
                /**
                 * Constructs a new CptInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CptInfo.
                 * @implements ICptInfo
                 * @constructor
                 * @param {infinitusai.be.ICptInfo=} [properties] Properties to set
                 */
                function CptInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CptInfo cptCode.
                 * @member {string} cptCode
                 * @memberof infinitusai.be.CptInfo
                 * @instance
                 */
                CptInfo.prototype.cptCode = "";
    
                /**
                 * CptInfo cptDescription.
                 * @member {string} cptDescription
                 * @memberof infinitusai.be.CptInfo
                 * @instance
                 */
                CptInfo.prototype.cptDescription = "";
    
                /**
                 * Creates a new CptInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {infinitusai.be.ICptInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.CptInfo} CptInfo instance
                 */
                CptInfo.create = function create(properties) {
                    return new CptInfo(properties);
                };
    
                /**
                 * Encodes the specified CptInfo message. Does not implicitly {@link infinitusai.be.CptInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {infinitusai.be.ICptInfo} message CptInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CptInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cptCode != null && Object.hasOwnProperty.call(message, "cptCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cptCode);
                    if (message.cptDescription != null && Object.hasOwnProperty.call(message, "cptDescription"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cptDescription);
                    return writer;
                };
    
                /**
                 * Encodes the specified CptInfo message, length delimited. Does not implicitly {@link infinitusai.be.CptInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {infinitusai.be.ICptInfo} message CptInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CptInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CptInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CptInfo} CptInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CptInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CptInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.cptCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.cptDescription = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CptInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CptInfo} CptInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CptInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CptInfo message.
                 * @function verify
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CptInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cptCode != null && message.hasOwnProperty("cptCode"))
                        if (!$util.isString(message.cptCode))
                            return "cptCode: string expected";
                    if (message.cptDescription != null && message.hasOwnProperty("cptDescription"))
                        if (!$util.isString(message.cptDescription))
                            return "cptDescription: string expected";
                    return null;
                };
    
                /**
                 * Creates a CptInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CptInfo} CptInfo
                 */
                CptInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CptInfo)
                        return object;
                    var message = new $root.infinitusai.be.CptInfo();
                    if (object.cptCode != null)
                        message.cptCode = String(object.cptCode);
                    if (object.cptDescription != null)
                        message.cptDescription = String(object.cptDescription);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CptInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {infinitusai.be.CptInfo} message CptInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CptInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cptCode = "";
                        object.cptDescription = "";
                    }
                    if (message.cptCode != null && message.hasOwnProperty("cptCode"))
                        object.cptCode = message.cptCode;
                    if (message.cptDescription != null && message.hasOwnProperty("cptDescription"))
                        object.cptDescription = message.cptDescription;
                    return object;
                };
    
                /**
                 * Converts this CptInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CptInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CptInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CptInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CptInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CptInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CptInfo";
                };
    
                return CptInfo;
            })();
    
            be.ProductInfo = (function() {
    
                /**
                 * Properties of a ProductInfo.
                 * @memberof infinitusai.be
                 * @interface IProductInfo
                 * @property {string|null} [productName] ProductInfo productName
                 * @property {string|null} [productCode] ProductInfo productCode
                 * @property {string|null} [productDescription] ProductInfo productDescription
                 * @property {string|null} [productPronunciation] ProductInfo productPronunciation
                 * @property {string|null} [productSpeciality] ProductInfo productSpeciality
                 * @property {string|null} [productGenericName] ProductInfo productGenericName
                 * @property {infinitusai.be.ProductInfo.ProductType|null} [productType] ProductInfo productType
                 * @property {string|null} [productSpecialty] ProductInfo productSpecialty
                 * @property {Array.<string>|null} [productCodes] ProductInfo productCodes
                 * @property {string|null} [productAdditionalDescription] ProductInfo productAdditionalDescription
                 * @property {boolean|null} [productOffLabel] ProductInfo productOffLabel
                 * @property {infinitusai.be.ProductInfo.DaysSupply|null} [productDaysSupply] ProductInfo productDaysSupply
                 * @property {string|null} [productNumberOfUnits] ProductInfo productNumberOfUnits
                 * @property {string|null} [productParentCode] ProductInfo productParentCode
                 */
    
                /**
                 * Constructs a new ProductInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProductInfo.
                 * @implements IProductInfo
                 * @constructor
                 * @param {infinitusai.be.IProductInfo=} [properties] Properties to set
                 */
                function ProductInfo(properties) {
                    this.productCodes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProductInfo productName.
                 * @member {string} productName
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productName = "";
    
                /**
                 * ProductInfo productCode.
                 * @member {string} productCode
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productCode = "";
    
                /**
                 * ProductInfo productDescription.
                 * @member {string} productDescription
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productDescription = "";
    
                /**
                 * ProductInfo productPronunciation.
                 * @member {string} productPronunciation
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productPronunciation = "";
    
                /**
                 * ProductInfo productSpeciality.
                 * @member {string} productSpeciality
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productSpeciality = "";
    
                /**
                 * ProductInfo productGenericName.
                 * @member {string} productGenericName
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productGenericName = "";
    
                /**
                 * ProductInfo productType.
                 * @member {infinitusai.be.ProductInfo.ProductType} productType
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productType = 0;
    
                /**
                 * ProductInfo productSpecialty.
                 * @member {string} productSpecialty
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productSpecialty = "";
    
                /**
                 * ProductInfo productCodes.
                 * @member {Array.<string>} productCodes
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productCodes = $util.emptyArray;
    
                /**
                 * ProductInfo productAdditionalDescription.
                 * @member {string} productAdditionalDescription
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productAdditionalDescription = "";
    
                /**
                 * ProductInfo productOffLabel.
                 * @member {boolean} productOffLabel
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productOffLabel = false;
    
                /**
                 * ProductInfo productDaysSupply.
                 * @member {infinitusai.be.ProductInfo.DaysSupply} productDaysSupply
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productDaysSupply = 0;
    
                /**
                 * ProductInfo productNumberOfUnits.
                 * @member {string} productNumberOfUnits
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productNumberOfUnits = "";
    
                /**
                 * ProductInfo productParentCode.
                 * @member {string} productParentCode
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 */
                ProductInfo.prototype.productParentCode = "";
    
                /**
                 * Creates a new ProductInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {infinitusai.be.IProductInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.ProductInfo} ProductInfo instance
                 */
                ProductInfo.create = function create(properties) {
                    return new ProductInfo(properties);
                };
    
                /**
                 * Encodes the specified ProductInfo message. Does not implicitly {@link infinitusai.be.ProductInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {infinitusai.be.IProductInfo} message ProductInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProductInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.productName != null && Object.hasOwnProperty.call(message, "productName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.productName);
                    if (message.productCode != null && Object.hasOwnProperty.call(message, "productCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.productCode);
                    if (message.productDescription != null && Object.hasOwnProperty.call(message, "productDescription"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.productDescription);
                    if (message.productPronunciation != null && Object.hasOwnProperty.call(message, "productPronunciation"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.productPronunciation);
                    if (message.productSpeciality != null && Object.hasOwnProperty.call(message, "productSpeciality"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.productSpeciality);
                    if (message.productGenericName != null && Object.hasOwnProperty.call(message, "productGenericName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.productGenericName);
                    if (message.productType != null && Object.hasOwnProperty.call(message, "productType"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.productType);
                    if (message.productSpecialty != null && Object.hasOwnProperty.call(message, "productSpecialty"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.productSpecialty);
                    if (message.productCodes != null && message.productCodes.length)
                        for (var i = 0; i < message.productCodes.length; ++i)
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.productCodes[i]);
                    if (message.productAdditionalDescription != null && Object.hasOwnProperty.call(message, "productAdditionalDescription"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.productAdditionalDescription);
                    if (message.productOffLabel != null && Object.hasOwnProperty.call(message, "productOffLabel"))
                        writer.uint32(/* id 11, wireType 0 =*/88).bool(message.productOffLabel);
                    if (message.productDaysSupply != null && Object.hasOwnProperty.call(message, "productDaysSupply"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.productDaysSupply);
                    if (message.productNumberOfUnits != null && Object.hasOwnProperty.call(message, "productNumberOfUnits"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.productNumberOfUnits);
                    if (message.productParentCode != null && Object.hasOwnProperty.call(message, "productParentCode"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.productParentCode);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProductInfo message, length delimited. Does not implicitly {@link infinitusai.be.ProductInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {infinitusai.be.IProductInfo} message ProductInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProductInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProductInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProductInfo} ProductInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProductInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProductInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.productName = reader.string();
                                break;
                            }
                        case 2: {
                                message.productCode = reader.string();
                                break;
                            }
                        case 3: {
                                message.productDescription = reader.string();
                                break;
                            }
                        case 4: {
                                message.productPronunciation = reader.string();
                                break;
                            }
                        case 5: {
                                message.productSpeciality = reader.string();
                                break;
                            }
                        case 6: {
                                message.productGenericName = reader.string();
                                break;
                            }
                        case 7: {
                                message.productType = reader.int32();
                                break;
                            }
                        case 8: {
                                message.productSpecialty = reader.string();
                                break;
                            }
                        case 9: {
                                if (!(message.productCodes && message.productCodes.length))
                                    message.productCodes = [];
                                message.productCodes.push(reader.string());
                                break;
                            }
                        case 10: {
                                message.productAdditionalDescription = reader.string();
                                break;
                            }
                        case 11: {
                                message.productOffLabel = reader.bool();
                                break;
                            }
                        case 12: {
                                message.productDaysSupply = reader.int32();
                                break;
                            }
                        case 13: {
                                message.productNumberOfUnits = reader.string();
                                break;
                            }
                        case 14: {
                                message.productParentCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProductInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProductInfo} ProductInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProductInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProductInfo message.
                 * @function verify
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProductInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.productName != null && message.hasOwnProperty("productName"))
                        if (!$util.isString(message.productName))
                            return "productName: string expected";
                    if (message.productCode != null && message.hasOwnProperty("productCode"))
                        if (!$util.isString(message.productCode))
                            return "productCode: string expected";
                    if (message.productDescription != null && message.hasOwnProperty("productDescription"))
                        if (!$util.isString(message.productDescription))
                            return "productDescription: string expected";
                    if (message.productPronunciation != null && message.hasOwnProperty("productPronunciation"))
                        if (!$util.isString(message.productPronunciation))
                            return "productPronunciation: string expected";
                    if (message.productSpeciality != null && message.hasOwnProperty("productSpeciality"))
                        if (!$util.isString(message.productSpeciality))
                            return "productSpeciality: string expected";
                    if (message.productGenericName != null && message.hasOwnProperty("productGenericName"))
                        if (!$util.isString(message.productGenericName))
                            return "productGenericName: string expected";
                    if (message.productType != null && message.hasOwnProperty("productType"))
                        switch (message.productType) {
                        default:
                            return "productType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.productSpecialty != null && message.hasOwnProperty("productSpecialty"))
                        if (!$util.isString(message.productSpecialty))
                            return "productSpecialty: string expected";
                    if (message.productCodes != null && message.hasOwnProperty("productCodes")) {
                        if (!Array.isArray(message.productCodes))
                            return "productCodes: array expected";
                        for (var i = 0; i < message.productCodes.length; ++i)
                            if (!$util.isString(message.productCodes[i]))
                                return "productCodes: string[] expected";
                    }
                    if (message.productAdditionalDescription != null && message.hasOwnProperty("productAdditionalDescription"))
                        if (!$util.isString(message.productAdditionalDescription))
                            return "productAdditionalDescription: string expected";
                    if (message.productOffLabel != null && message.hasOwnProperty("productOffLabel"))
                        if (typeof message.productOffLabel !== "boolean")
                            return "productOffLabel: boolean expected";
                    if (message.productDaysSupply != null && message.hasOwnProperty("productDaysSupply"))
                        switch (message.productDaysSupply) {
                        default:
                            return "productDaysSupply: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.productNumberOfUnits != null && message.hasOwnProperty("productNumberOfUnits"))
                        if (!$util.isString(message.productNumberOfUnits))
                            return "productNumberOfUnits: string expected";
                    if (message.productParentCode != null && message.hasOwnProperty("productParentCode"))
                        if (!$util.isString(message.productParentCode))
                            return "productParentCode: string expected";
                    return null;
                };
    
                /**
                 * Creates a ProductInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProductInfo} ProductInfo
                 */
                ProductInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProductInfo)
                        return object;
                    var message = new $root.infinitusai.be.ProductInfo();
                    if (object.productName != null)
                        message.productName = String(object.productName);
                    if (object.productCode != null)
                        message.productCode = String(object.productCode);
                    if (object.productDescription != null)
                        message.productDescription = String(object.productDescription);
                    if (object.productPronunciation != null)
                        message.productPronunciation = String(object.productPronunciation);
                    if (object.productSpeciality != null)
                        message.productSpeciality = String(object.productSpeciality);
                    if (object.productGenericName != null)
                        message.productGenericName = String(object.productGenericName);
                    switch (object.productType) {
                    default:
                        if (typeof object.productType === "number") {
                            message.productType = object.productType;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.productType = 0;
                        break;
                    case "PART_B_DRUG":
                    case 1:
                        message.productType = 1;
                        break;
                    case "DURABLE_MEDICAL_EQUIPMENT":
                    case 2:
                        message.productType = 2;
                        break;
                    case "PART_D_DRUG":
                    case 3:
                        message.productType = 3;
                        break;
                    }
                    if (object.productSpecialty != null)
                        message.productSpecialty = String(object.productSpecialty);
                    if (object.productCodes) {
                        if (!Array.isArray(object.productCodes))
                            throw TypeError(".infinitusai.be.ProductInfo.productCodes: array expected");
                        message.productCodes = [];
                        for (var i = 0; i < object.productCodes.length; ++i)
                            message.productCodes[i] = String(object.productCodes[i]);
                    }
                    if (object.productAdditionalDescription != null)
                        message.productAdditionalDescription = String(object.productAdditionalDescription);
                    if (object.productOffLabel != null)
                        message.productOffLabel = Boolean(object.productOffLabel);
                    switch (object.productDaysSupply) {
                    default:
                        if (typeof object.productDaysSupply === "number") {
                            message.productDaysSupply = object.productDaysSupply;
                            break;
                        }
                        break;
                    case "DAYS_SUPPLY_UNKNOWN":
                    case 0:
                        message.productDaysSupply = 0;
                        break;
                    case "DAYS_SUPPLY_30_DAYS":
                    case 1:
                        message.productDaysSupply = 1;
                        break;
                    case "DAYS_SUPPLY_90_DAYS":
                    case 2:
                        message.productDaysSupply = 2;
                        break;
                    case "DAYS_SUPPLY_60_DAYS":
                    case 3:
                        message.productDaysSupply = 3;
                        break;
                    }
                    if (object.productNumberOfUnits != null)
                        message.productNumberOfUnits = String(object.productNumberOfUnits);
                    if (object.productParentCode != null)
                        message.productParentCode = String(object.productParentCode);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProductInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {infinitusai.be.ProductInfo} message ProductInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProductInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.productCodes = [];
                    if (options.defaults) {
                        object.productName = "";
                        object.productCode = "";
                        object.productDescription = "";
                        object.productPronunciation = "";
                        object.productSpeciality = "";
                        object.productGenericName = "";
                        object.productType = options.enums === String ? "UNKNOWN" : 0;
                        object.productSpecialty = "";
                        object.productAdditionalDescription = "";
                        object.productOffLabel = false;
                        object.productDaysSupply = options.enums === String ? "DAYS_SUPPLY_UNKNOWN" : 0;
                        object.productNumberOfUnits = "";
                        object.productParentCode = "";
                    }
                    if (message.productName != null && message.hasOwnProperty("productName"))
                        object.productName = message.productName;
                    if (message.productCode != null && message.hasOwnProperty("productCode"))
                        object.productCode = message.productCode;
                    if (message.productDescription != null && message.hasOwnProperty("productDescription"))
                        object.productDescription = message.productDescription;
                    if (message.productPronunciation != null && message.hasOwnProperty("productPronunciation"))
                        object.productPronunciation = message.productPronunciation;
                    if (message.productSpeciality != null && message.hasOwnProperty("productSpeciality"))
                        object.productSpeciality = message.productSpeciality;
                    if (message.productGenericName != null && message.hasOwnProperty("productGenericName"))
                        object.productGenericName = message.productGenericName;
                    if (message.productType != null && message.hasOwnProperty("productType"))
                        object.productType = options.enums === String ? $root.infinitusai.be.ProductInfo.ProductType[message.productType] === undefined ? message.productType : $root.infinitusai.be.ProductInfo.ProductType[message.productType] : message.productType;
                    if (message.productSpecialty != null && message.hasOwnProperty("productSpecialty"))
                        object.productSpecialty = message.productSpecialty;
                    if (message.productCodes && message.productCodes.length) {
                        object.productCodes = [];
                        for (var j = 0; j < message.productCodes.length; ++j)
                            object.productCodes[j] = message.productCodes[j];
                    }
                    if (message.productAdditionalDescription != null && message.hasOwnProperty("productAdditionalDescription"))
                        object.productAdditionalDescription = message.productAdditionalDescription;
                    if (message.productOffLabel != null && message.hasOwnProperty("productOffLabel"))
                        object.productOffLabel = message.productOffLabel;
                    if (message.productDaysSupply != null && message.hasOwnProperty("productDaysSupply"))
                        object.productDaysSupply = options.enums === String ? $root.infinitusai.be.ProductInfo.DaysSupply[message.productDaysSupply] === undefined ? message.productDaysSupply : $root.infinitusai.be.ProductInfo.DaysSupply[message.productDaysSupply] : message.productDaysSupply;
                    if (message.productNumberOfUnits != null && message.hasOwnProperty("productNumberOfUnits"))
                        object.productNumberOfUnits = message.productNumberOfUnits;
                    if (message.productParentCode != null && message.hasOwnProperty("productParentCode"))
                        object.productParentCode = message.productParentCode;
                    return object;
                };
    
                /**
                 * Converts this ProductInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProductInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProductInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProductInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProductInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProductInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProductInfo";
                };
    
                /**
                 * ProductType enum.
                 * @name infinitusai.be.ProductInfo.ProductType
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} PART_B_DRUG=1 PART_B_DRUG value
                 * @property {number} DURABLE_MEDICAL_EQUIPMENT=2 DURABLE_MEDICAL_EQUIPMENT value
                 * @property {number} PART_D_DRUG=3 PART_D_DRUG value
                 */
                ProductInfo.ProductType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "PART_B_DRUG"] = 1;
                    values[valuesById[2] = "DURABLE_MEDICAL_EQUIPMENT"] = 2;
                    values[valuesById[3] = "PART_D_DRUG"] = 3;
                    return values;
                })();
    
                /**
                 * DaysSupply enum.
                 * @name infinitusai.be.ProductInfo.DaysSupply
                 * @enum {number}
                 * @property {number} DAYS_SUPPLY_UNKNOWN=0 DAYS_SUPPLY_UNKNOWN value
                 * @property {number} DAYS_SUPPLY_30_DAYS=1 DAYS_SUPPLY_30_DAYS value
                 * @property {number} DAYS_SUPPLY_90_DAYS=2 DAYS_SUPPLY_90_DAYS value
                 * @property {number} DAYS_SUPPLY_60_DAYS=3 DAYS_SUPPLY_60_DAYS value
                 */
                ProductInfo.DaysSupply = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DAYS_SUPPLY_UNKNOWN"] = 0;
                    values[valuesById[1] = "DAYS_SUPPLY_30_DAYS"] = 1;
                    values[valuesById[2] = "DAYS_SUPPLY_90_DAYS"] = 2;
                    values[valuesById[3] = "DAYS_SUPPLY_60_DAYS"] = 3;
                    return values;
                })();
    
                return ProductInfo;
            })();
    
            be.GetProductCodesRequest = (function() {
    
                /**
                 * Properties of a GetProductCodesRequest.
                 * @memberof infinitusai.be
                 * @interface IGetProductCodesRequest
                 */
    
                /**
                 * Constructs a new GetProductCodesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetProductCodesRequest.
                 * @implements IGetProductCodesRequest
                 * @constructor
                 * @param {infinitusai.be.IGetProductCodesRequest=} [properties] Properties to set
                 */
                function GetProductCodesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetProductCodesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {infinitusai.be.IGetProductCodesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetProductCodesRequest} GetProductCodesRequest instance
                 */
                GetProductCodesRequest.create = function create(properties) {
                    return new GetProductCodesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetProductCodesRequest message. Does not implicitly {@link infinitusai.be.GetProductCodesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {infinitusai.be.IGetProductCodesRequest} message GetProductCodesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetProductCodesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetProductCodesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetProductCodesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {infinitusai.be.IGetProductCodesRequest} message GetProductCodesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetProductCodesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetProductCodesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetProductCodesRequest} GetProductCodesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetProductCodesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetProductCodesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetProductCodesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetProductCodesRequest} GetProductCodesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetProductCodesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetProductCodesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetProductCodesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetProductCodesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetProductCodesRequest} GetProductCodesRequest
                 */
                GetProductCodesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetProductCodesRequest)
                        return object;
                    return new $root.infinitusai.be.GetProductCodesRequest();
                };
    
                /**
                 * Creates a plain object from a GetProductCodesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {infinitusai.be.GetProductCodesRequest} message GetProductCodesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetProductCodesRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetProductCodesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetProductCodesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetProductCodesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetProductCodesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetProductCodesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetProductCodesRequest";
                };
    
                return GetProductCodesRequest;
            })();
    
            be.GetProductCodesResponse = (function() {
    
                /**
                 * Properties of a GetProductCodesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetProductCodesResponse
                 * @property {Object.<string,infinitusai.be.GetProductCodesResponse.IProductData>|null} [codes] GetProductCodesResponse codes
                 */
    
                /**
                 * Constructs a new GetProductCodesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetProductCodesResponse.
                 * @implements IGetProductCodesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetProductCodesResponse=} [properties] Properties to set
                 */
                function GetProductCodesResponse(properties) {
                    this.codes = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetProductCodesResponse codes.
                 * @member {Object.<string,infinitusai.be.GetProductCodesResponse.IProductData>} codes
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @instance
                 */
                GetProductCodesResponse.prototype.codes = $util.emptyObject;
    
                /**
                 * Creates a new GetProductCodesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {infinitusai.be.IGetProductCodesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetProductCodesResponse} GetProductCodesResponse instance
                 */
                GetProductCodesResponse.create = function create(properties) {
                    return new GetProductCodesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetProductCodesResponse message. Does not implicitly {@link infinitusai.be.GetProductCodesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {infinitusai.be.IGetProductCodesResponse} message GetProductCodesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetProductCodesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.codes != null && Object.hasOwnProperty.call(message, "codes"))
                        for (var keys = Object.keys(message.codes), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.GetProductCodesResponse.ProductData.encode(message.codes[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetProductCodesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetProductCodesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {infinitusai.be.IGetProductCodesResponse} message GetProductCodesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetProductCodesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetProductCodesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetProductCodesResponse} GetProductCodesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetProductCodesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetProductCodesResponse(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.codes === $util.emptyObject)
                                    message.codes = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.GetProductCodesResponse.ProductData.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.codes[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetProductCodesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetProductCodesResponse} GetProductCodesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetProductCodesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetProductCodesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetProductCodesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.codes != null && message.hasOwnProperty("codes")) {
                        if (!$util.isObject(message.codes))
                            return "codes: object expected";
                        var key = Object.keys(message.codes);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.GetProductCodesResponse.ProductData.verify(message.codes[key[i]]);
                            if (error)
                                return "codes." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetProductCodesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetProductCodesResponse} GetProductCodesResponse
                 */
                GetProductCodesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetProductCodesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetProductCodesResponse();
                    if (object.codes) {
                        if (typeof object.codes !== "object")
                            throw TypeError(".infinitusai.be.GetProductCodesResponse.codes: object expected");
                        message.codes = {};
                        for (var keys = Object.keys(object.codes), i = 0; i < keys.length; ++i) {
                            if (typeof object.codes[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.GetProductCodesResponse.codes: object expected");
                            message.codes[keys[i]] = $root.infinitusai.be.GetProductCodesResponse.ProductData.fromObject(object.codes[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetProductCodesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {infinitusai.be.GetProductCodesResponse} message GetProductCodesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetProductCodesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.codes = {};
                    var keys2;
                    if (message.codes && (keys2 = Object.keys(message.codes)).length) {
                        object.codes = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.codes[keys2[j]] = $root.infinitusai.be.GetProductCodesResponse.ProductData.toObject(message.codes[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetProductCodesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetProductCodesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetProductCodesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetProductCodesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetProductCodesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetProductCodesResponse";
                };
    
                GetProductCodesResponse.ProductData = (function() {
    
                    /**
                     * Properties of a ProductData.
                     * @memberof infinitusai.be.GetProductCodesResponse
                     * @interface IProductData
                     * @property {Array.<string>|null} [names] ProductData names
                     */
    
                    /**
                     * Constructs a new ProductData.
                     * @memberof infinitusai.be.GetProductCodesResponse
                     * @classdesc Represents a ProductData.
                     * @implements IProductData
                     * @constructor
                     * @param {infinitusai.be.GetProductCodesResponse.IProductData=} [properties] Properties to set
                     */
                    function ProductData(properties) {
                        this.names = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ProductData names.
                     * @member {Array.<string>} names
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @instance
                     */
                    ProductData.prototype.names = $util.emptyArray;
    
                    /**
                     * Creates a new ProductData instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {infinitusai.be.GetProductCodesResponse.IProductData=} [properties] Properties to set
                     * @returns {infinitusai.be.GetProductCodesResponse.ProductData} ProductData instance
                     */
                    ProductData.create = function create(properties) {
                        return new ProductData(properties);
                    };
    
                    /**
                     * Encodes the specified ProductData message. Does not implicitly {@link infinitusai.be.GetProductCodesResponse.ProductData.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {infinitusai.be.GetProductCodesResponse.IProductData} message ProductData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.names != null && message.names.length)
                            for (var i = 0; i < message.names.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.names[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ProductData message, length delimited. Does not implicitly {@link infinitusai.be.GetProductCodesResponse.ProductData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {infinitusai.be.GetProductCodesResponse.IProductData} message ProductData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ProductData message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.GetProductCodesResponse.ProductData} ProductData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetProductCodesResponse.ProductData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.names && message.names.length))
                                        message.names = [];
                                    message.names.push(reader.string());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ProductData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.GetProductCodesResponse.ProductData} ProductData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ProductData message.
                     * @function verify
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.names != null && message.hasOwnProperty("names")) {
                            if (!Array.isArray(message.names))
                                return "names: array expected";
                            for (var i = 0; i < message.names.length; ++i)
                                if (!$util.isString(message.names[i]))
                                    return "names: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ProductData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.GetProductCodesResponse.ProductData} ProductData
                     */
                    ProductData.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.GetProductCodesResponse.ProductData)
                            return object;
                        var message = new $root.infinitusai.be.GetProductCodesResponse.ProductData();
                        if (object.names) {
                            if (!Array.isArray(object.names))
                                throw TypeError(".infinitusai.be.GetProductCodesResponse.ProductData.names: array expected");
                            message.names = [];
                            for (var i = 0; i < object.names.length; ++i)
                                message.names[i] = String(object.names[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ProductData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {infinitusai.be.GetProductCodesResponse.ProductData} message ProductData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.names = [];
                        if (message.names && message.names.length) {
                            object.names = [];
                            for (var j = 0; j < message.names.length; ++j)
                                object.names[j] = message.names[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ProductData to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for ProductData
                     * @function getTypeUrl
                     * @memberof infinitusai.be.GetProductCodesResponse.ProductData
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ProductData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.GetProductCodesResponse.ProductData";
                    };
    
                    return ProductData;
                })();
    
                return GetProductCodesResponse;
            })();
    
            be.PracticeInfo = (function() {
    
                /**
                 * Properties of a PracticeInfo.
                 * @memberof infinitusai.be
                 * @interface IPracticeInfo
                 * @property {string|null} [taxId] PracticeInfo taxId
                 * @property {string|null} [name] PracticeInfo name
                 * @property {string|null} [npi] PracticeInfo npi
                 * @property {infinitusai.be.IAddressInfo|null} [addressInfo] PracticeInfo addressInfo
                 * @property {Array.<string>|null} [alternateNames] PracticeInfo alternateNames
                 */
    
                /**
                 * Constructs a new PracticeInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PracticeInfo.
                 * @implements IPracticeInfo
                 * @constructor
                 * @param {infinitusai.be.IPracticeInfo=} [properties] Properties to set
                 */
                function PracticeInfo(properties) {
                    this.alternateNames = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PracticeInfo taxId.
                 * @member {string} taxId
                 * @memberof infinitusai.be.PracticeInfo
                 * @instance
                 */
                PracticeInfo.prototype.taxId = "";
    
                /**
                 * PracticeInfo name.
                 * @member {string} name
                 * @memberof infinitusai.be.PracticeInfo
                 * @instance
                 */
                PracticeInfo.prototype.name = "";
    
                /**
                 * PracticeInfo npi.
                 * @member {string} npi
                 * @memberof infinitusai.be.PracticeInfo
                 * @instance
                 */
                PracticeInfo.prototype.npi = "";
    
                /**
                 * PracticeInfo addressInfo.
                 * @member {infinitusai.be.IAddressInfo|null|undefined} addressInfo
                 * @memberof infinitusai.be.PracticeInfo
                 * @instance
                 */
                PracticeInfo.prototype.addressInfo = null;
    
                /**
                 * PracticeInfo alternateNames.
                 * @member {Array.<string>} alternateNames
                 * @memberof infinitusai.be.PracticeInfo
                 * @instance
                 */
                PracticeInfo.prototype.alternateNames = $util.emptyArray;
    
                /**
                 * Creates a new PracticeInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {infinitusai.be.IPracticeInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.PracticeInfo} PracticeInfo instance
                 */
                PracticeInfo.create = function create(properties) {
                    return new PracticeInfo(properties);
                };
    
                /**
                 * Encodes the specified PracticeInfo message. Does not implicitly {@link infinitusai.be.PracticeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {infinitusai.be.IPracticeInfo} message PracticeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taxId);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.npi != null && Object.hasOwnProperty.call(message, "npi"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.npi);
                    if (message.addressInfo != null && Object.hasOwnProperty.call(message, "addressInfo"))
                        $root.infinitusai.be.AddressInfo.encode(message.addressInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.alternateNames != null && message.alternateNames.length)
                        for (var i = 0; i < message.alternateNames.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.alternateNames[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified PracticeInfo message, length delimited. Does not implicitly {@link infinitusai.be.PracticeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {infinitusai.be.IPracticeInfo} message PracticeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PracticeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PracticeInfo} PracticeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PracticeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taxId = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.npi = reader.string();
                                break;
                            }
                        case 4: {
                                message.addressInfo = $root.infinitusai.be.AddressInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                if (!(message.alternateNames && message.alternateNames.length))
                                    message.alternateNames = [];
                                message.alternateNames.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PracticeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PracticeInfo} PracticeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PracticeInfo message.
                 * @function verify
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PracticeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taxId != null && message.hasOwnProperty("taxId"))
                        if (!$util.isString(message.taxId))
                            return "taxId: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.npi != null && message.hasOwnProperty("npi"))
                        if (!$util.isString(message.npi))
                            return "npi: string expected";
                    if (message.addressInfo != null && message.hasOwnProperty("addressInfo")) {
                        var error = $root.infinitusai.be.AddressInfo.verify(message.addressInfo);
                        if (error)
                            return "addressInfo." + error;
                    }
                    if (message.alternateNames != null && message.hasOwnProperty("alternateNames")) {
                        if (!Array.isArray(message.alternateNames))
                            return "alternateNames: array expected";
                        for (var i = 0; i < message.alternateNames.length; ++i)
                            if (!$util.isString(message.alternateNames[i]))
                                return "alternateNames: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a PracticeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PracticeInfo} PracticeInfo
                 */
                PracticeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PracticeInfo)
                        return object;
                    var message = new $root.infinitusai.be.PracticeInfo();
                    if (object.taxId != null)
                        message.taxId = String(object.taxId);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.npi != null)
                        message.npi = String(object.npi);
                    if (object.addressInfo != null) {
                        if (typeof object.addressInfo !== "object")
                            throw TypeError(".infinitusai.be.PracticeInfo.addressInfo: object expected");
                        message.addressInfo = $root.infinitusai.be.AddressInfo.fromObject(object.addressInfo);
                    }
                    if (object.alternateNames) {
                        if (!Array.isArray(object.alternateNames))
                            throw TypeError(".infinitusai.be.PracticeInfo.alternateNames: array expected");
                        message.alternateNames = [];
                        for (var i = 0; i < object.alternateNames.length; ++i)
                            message.alternateNames[i] = String(object.alternateNames[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PracticeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {infinitusai.be.PracticeInfo} message PracticeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PracticeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.alternateNames = [];
                    if (options.defaults) {
                        object.taxId = "";
                        object.name = "";
                        object.npi = "";
                        object.addressInfo = null;
                    }
                    if (message.taxId != null && message.hasOwnProperty("taxId"))
                        object.taxId = message.taxId;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.npi != null && message.hasOwnProperty("npi"))
                        object.npi = message.npi;
                    if (message.addressInfo != null && message.hasOwnProperty("addressInfo"))
                        object.addressInfo = $root.infinitusai.be.AddressInfo.toObject(message.addressInfo, options);
                    if (message.alternateNames && message.alternateNames.length) {
                        object.alternateNames = [];
                        for (var j = 0; j < message.alternateNames.length; ++j)
                            object.alternateNames[j] = message.alternateNames[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this PracticeInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PracticeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PracticeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PracticeInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PracticeInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PracticeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PracticeInfo";
                };
    
                return PracticeInfo;
            })();
    
            be.ProviderInfo = (function() {
    
                /**
                 * Properties of a ProviderInfo.
                 * @memberof infinitusai.be
                 * @interface IProviderInfo
                 * @property {string|null} [name] ProviderInfo name
                 * @property {string|null} [npi] ProviderInfo npi
                 * @property {string|null} [address] ProviderInfo address
                 * @property {infinitusai.be.IAddressInfo|null} [addressInfo] ProviderInfo addressInfo
                 * @property {string|null} [taxId] ProviderInfo taxId
                 * @property {string|null} [firstName] ProviderInfo firstName
                 * @property {string|null} [lastName] ProviderInfo lastName
                 * @property {Array.<string>|null} [preferredSpecialtyPharmacies] ProviderInfo preferredSpecialtyPharmacies
                 * @property {infinitusai.be.ProviderInfo.NetworkStatus|null} [networkStatus] ProviderInfo networkStatus
                 * @property {string|null} [additionalNotes] ProviderInfo additionalNotes
                 * @property {string|null} [ptan] ProviderInfo ptan
                 */
    
                /**
                 * Constructs a new ProviderInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProviderInfo.
                 * @implements IProviderInfo
                 * @constructor
                 * @param {infinitusai.be.IProviderInfo=} [properties] Properties to set
                 */
                function ProviderInfo(properties) {
                    this.preferredSpecialtyPharmacies = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProviderInfo name.
                 * @member {string} name
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.name = "";
    
                /**
                 * ProviderInfo npi.
                 * @member {string} npi
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.npi = "";
    
                /**
                 * ProviderInfo address.
                 * @member {string} address
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.address = "";
    
                /**
                 * ProviderInfo addressInfo.
                 * @member {infinitusai.be.IAddressInfo|null|undefined} addressInfo
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.addressInfo = null;
    
                /**
                 * ProviderInfo taxId.
                 * @member {string} taxId
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.taxId = "";
    
                /**
                 * ProviderInfo firstName.
                 * @member {string} firstName
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.firstName = "";
    
                /**
                 * ProviderInfo lastName.
                 * @member {string} lastName
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.lastName = "";
    
                /**
                 * ProviderInfo preferredSpecialtyPharmacies.
                 * @member {Array.<string>} preferredSpecialtyPharmacies
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.preferredSpecialtyPharmacies = $util.emptyArray;
    
                /**
                 * ProviderInfo networkStatus.
                 * @member {infinitusai.be.ProviderInfo.NetworkStatus} networkStatus
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.networkStatus = 0;
    
                /**
                 * ProviderInfo additionalNotes.
                 * @member {string} additionalNotes
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.additionalNotes = "";
    
                /**
                 * ProviderInfo ptan.
                 * @member {string} ptan
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 */
                ProviderInfo.prototype.ptan = "";
    
                /**
                 * Creates a new ProviderInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {infinitusai.be.IProviderInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.ProviderInfo} ProviderInfo instance
                 */
                ProviderInfo.create = function create(properties) {
                    return new ProviderInfo(properties);
                };
    
                /**
                 * Encodes the specified ProviderInfo message. Does not implicitly {@link infinitusai.be.ProviderInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {infinitusai.be.IProviderInfo} message ProviderInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.npi != null && Object.hasOwnProperty.call(message, "npi"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.npi);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                    if (message.addressInfo != null && Object.hasOwnProperty.call(message, "addressInfo"))
                        $root.infinitusai.be.AddressInfo.encode(message.addressInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.taxId);
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.lastName);
                    if (message.preferredSpecialtyPharmacies != null && message.preferredSpecialtyPharmacies.length)
                        for (var i = 0; i < message.preferredSpecialtyPharmacies.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.preferredSpecialtyPharmacies[i]);
                    if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.networkStatus);
                    if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.additionalNotes);
                    if (message.ptan != null && Object.hasOwnProperty.call(message, "ptan"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.ptan);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProviderInfo message, length delimited. Does not implicitly {@link infinitusai.be.ProviderInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {infinitusai.be.IProviderInfo} message ProviderInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProviderInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProviderInfo} ProviderInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProviderInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.npi = reader.string();
                                break;
                            }
                        case 3: {
                                message.address = reader.string();
                                break;
                            }
                        case 4: {
                                message.addressInfo = $root.infinitusai.be.AddressInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.taxId = reader.string();
                                break;
                            }
                        case 6: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 7: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 8: {
                                if (!(message.preferredSpecialtyPharmacies && message.preferredSpecialtyPharmacies.length))
                                    message.preferredSpecialtyPharmacies = [];
                                message.preferredSpecialtyPharmacies.push(reader.string());
                                break;
                            }
                        case 9: {
                                message.networkStatus = reader.int32();
                                break;
                            }
                        case 10: {
                                message.additionalNotes = reader.string();
                                break;
                            }
                        case 11: {
                                message.ptan = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProviderInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProviderInfo} ProviderInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProviderInfo message.
                 * @function verify
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.npi != null && message.hasOwnProperty("npi"))
                        if (!$util.isString(message.npi))
                            return "npi: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.addressInfo != null && message.hasOwnProperty("addressInfo")) {
                        var error = $root.infinitusai.be.AddressInfo.verify(message.addressInfo);
                        if (error)
                            return "addressInfo." + error;
                    }
                    if (message.taxId != null && message.hasOwnProperty("taxId"))
                        if (!$util.isString(message.taxId))
                            return "taxId: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.preferredSpecialtyPharmacies != null && message.hasOwnProperty("preferredSpecialtyPharmacies")) {
                        if (!Array.isArray(message.preferredSpecialtyPharmacies))
                            return "preferredSpecialtyPharmacies: array expected";
                        for (var i = 0; i < message.preferredSpecialtyPharmacies.length; ++i)
                            if (!$util.isString(message.preferredSpecialtyPharmacies[i]))
                                return "preferredSpecialtyPharmacies: string[] expected";
                    }
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                        switch (message.networkStatus) {
                        default:
                            return "networkStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                        if (!$util.isString(message.additionalNotes))
                            return "additionalNotes: string expected";
                    if (message.ptan != null && message.hasOwnProperty("ptan"))
                        if (!$util.isString(message.ptan))
                            return "ptan: string expected";
                    return null;
                };
    
                /**
                 * Creates a ProviderInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProviderInfo} ProviderInfo
                 */
                ProviderInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProviderInfo)
                        return object;
                    var message = new $root.infinitusai.be.ProviderInfo();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.npi != null)
                        message.npi = String(object.npi);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.addressInfo != null) {
                        if (typeof object.addressInfo !== "object")
                            throw TypeError(".infinitusai.be.ProviderInfo.addressInfo: object expected");
                        message.addressInfo = $root.infinitusai.be.AddressInfo.fromObject(object.addressInfo);
                    }
                    if (object.taxId != null)
                        message.taxId = String(object.taxId);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.preferredSpecialtyPharmacies) {
                        if (!Array.isArray(object.preferredSpecialtyPharmacies))
                            throw TypeError(".infinitusai.be.ProviderInfo.preferredSpecialtyPharmacies: array expected");
                        message.preferredSpecialtyPharmacies = [];
                        for (var i = 0; i < object.preferredSpecialtyPharmacies.length; ++i)
                            message.preferredSpecialtyPharmacies[i] = String(object.preferredSpecialtyPharmacies[i]);
                    }
                    switch (object.networkStatus) {
                    default:
                        if (typeof object.networkStatus === "number") {
                            message.networkStatus = object.networkStatus;
                            break;
                        }
                        break;
                    case "NETWORK_STATUS_UNKNOWN":
                    case 0:
                        message.networkStatus = 0;
                        break;
                    case "NETWORK_STATUS_IN_NETWORK":
                    case 1:
                        message.networkStatus = 1;
                        break;
                    case "NETWORK_STATUS_OUT_OF_NETWORK":
                    case 2:
                        message.networkStatus = 2;
                        break;
                    }
                    if (object.additionalNotes != null)
                        message.additionalNotes = String(object.additionalNotes);
                    if (object.ptan != null)
                        message.ptan = String(object.ptan);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProviderInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {infinitusai.be.ProviderInfo} message ProviderInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.preferredSpecialtyPharmacies = [];
                    if (options.defaults) {
                        object.name = "";
                        object.npi = "";
                        object.address = "";
                        object.addressInfo = null;
                        object.taxId = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.networkStatus = options.enums === String ? "NETWORK_STATUS_UNKNOWN" : 0;
                        object.additionalNotes = "";
                        object.ptan = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.npi != null && message.hasOwnProperty("npi"))
                        object.npi = message.npi;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.addressInfo != null && message.hasOwnProperty("addressInfo"))
                        object.addressInfo = $root.infinitusai.be.AddressInfo.toObject(message.addressInfo, options);
                    if (message.taxId != null && message.hasOwnProperty("taxId"))
                        object.taxId = message.taxId;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.preferredSpecialtyPharmacies && message.preferredSpecialtyPharmacies.length) {
                        object.preferredSpecialtyPharmacies = [];
                        for (var j = 0; j < message.preferredSpecialtyPharmacies.length; ++j)
                            object.preferredSpecialtyPharmacies[j] = message.preferredSpecialtyPharmacies[j];
                    }
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                        object.networkStatus = options.enums === String ? $root.infinitusai.be.ProviderInfo.NetworkStatus[message.networkStatus] === undefined ? message.networkStatus : $root.infinitusai.be.ProviderInfo.NetworkStatus[message.networkStatus] : message.networkStatus;
                    if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                        object.additionalNotes = message.additionalNotes;
                    if (message.ptan != null && message.hasOwnProperty("ptan"))
                        object.ptan = message.ptan;
                    return object;
                };
    
                /**
                 * Converts this ProviderInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProviderInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProviderInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProviderInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProviderInfo";
                };
    
                /**
                 * NetworkStatus enum.
                 * @name infinitusai.be.ProviderInfo.NetworkStatus
                 * @enum {number}
                 * @property {number} NETWORK_STATUS_UNKNOWN=0 NETWORK_STATUS_UNKNOWN value
                 * @property {number} NETWORK_STATUS_IN_NETWORK=1 NETWORK_STATUS_IN_NETWORK value
                 * @property {number} NETWORK_STATUS_OUT_OF_NETWORK=2 NETWORK_STATUS_OUT_OF_NETWORK value
                 */
                ProviderInfo.NetworkStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NETWORK_STATUS_UNKNOWN"] = 0;
                    values[valuesById[1] = "NETWORK_STATUS_IN_NETWORK"] = 1;
                    values[valuesById[2] = "NETWORK_STATUS_OUT_OF_NETWORK"] = 2;
                    return values;
                })();
    
                return ProviderInfo;
            })();
    
            be.AddressInfo = (function() {
    
                /**
                 * Properties of an AddressInfo.
                 * @memberof infinitusai.be
                 * @interface IAddressInfo
                 * @property {string|null} [state] AddressInfo state
                 * @property {string|null} [city] AddressInfo city
                 * @property {string|null} [street] AddressInfo street
                 * @property {string|null} [zip] AddressInfo zip
                 */
    
                /**
                 * Constructs a new AddressInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AddressInfo.
                 * @implements IAddressInfo
                 * @constructor
                 * @param {infinitusai.be.IAddressInfo=} [properties] Properties to set
                 */
                function AddressInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AddressInfo state.
                 * @member {string} state
                 * @memberof infinitusai.be.AddressInfo
                 * @instance
                 */
                AddressInfo.prototype.state = "";
    
                /**
                 * AddressInfo city.
                 * @member {string} city
                 * @memberof infinitusai.be.AddressInfo
                 * @instance
                 */
                AddressInfo.prototype.city = "";
    
                /**
                 * AddressInfo street.
                 * @member {string} street
                 * @memberof infinitusai.be.AddressInfo
                 * @instance
                 */
                AddressInfo.prototype.street = "";
    
                /**
                 * AddressInfo zip.
                 * @member {string} zip
                 * @memberof infinitusai.be.AddressInfo
                 * @instance
                 */
                AddressInfo.prototype.zip = "";
    
                /**
                 * Creates a new AddressInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {infinitusai.be.IAddressInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.AddressInfo} AddressInfo instance
                 */
                AddressInfo.create = function create(properties) {
                    return new AddressInfo(properties);
                };
    
                /**
                 * Encodes the specified AddressInfo message. Does not implicitly {@link infinitusai.be.AddressInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {infinitusai.be.IAddressInfo} message AddressInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.state);
                    if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.city);
                    if (message.street != null && Object.hasOwnProperty.call(message, "street"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.street);
                    if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.zip);
                    return writer;
                };
    
                /**
                 * Encodes the specified AddressInfo message, length delimited. Does not implicitly {@link infinitusai.be.AddressInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {infinitusai.be.IAddressInfo} message AddressInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddressInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AddressInfo} AddressInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AddressInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.state = reader.string();
                                break;
                            }
                        case 2: {
                                message.city = reader.string();
                                break;
                            }
                        case 3: {
                                message.street = reader.string();
                                break;
                            }
                        case 4: {
                                message.zip = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddressInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AddressInfo} AddressInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddressInfo message.
                 * @function verify
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddressInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        if (!$util.isString(message.state))
                            return "state: string expected";
                    if (message.city != null && message.hasOwnProperty("city"))
                        if (!$util.isString(message.city))
                            return "city: string expected";
                    if (message.street != null && message.hasOwnProperty("street"))
                        if (!$util.isString(message.street))
                            return "street: string expected";
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        if (!$util.isString(message.zip))
                            return "zip: string expected";
                    return null;
                };
    
                /**
                 * Creates an AddressInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AddressInfo} AddressInfo
                 */
                AddressInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AddressInfo)
                        return object;
                    var message = new $root.infinitusai.be.AddressInfo();
                    if (object.state != null)
                        message.state = String(object.state);
                    if (object.city != null)
                        message.city = String(object.city);
                    if (object.street != null)
                        message.street = String(object.street);
                    if (object.zip != null)
                        message.zip = String(object.zip);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AddressInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {infinitusai.be.AddressInfo} message AddressInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddressInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.state = "";
                        object.city = "";
                        object.street = "";
                        object.zip = "";
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = message.state;
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = message.city;
                    if (message.street != null && message.hasOwnProperty("street"))
                        object.street = message.street;
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        object.zip = message.zip;
                    return object;
                };
    
                /**
                 * Converts this AddressInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AddressInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddressInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddressInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AddressInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddressInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AddressInfo";
                };
    
                return AddressInfo;
            })();
    
            be.PolicyInfo = (function() {
    
                /**
                 * Properties of a PolicyInfo.
                 * @memberof infinitusai.be
                 * @interface IPolicyInfo
                 * @property {string|null} [memberId] PolicyInfo memberId
                 * @property {infinitusai.be.INullableString|null} [planName] PolicyInfo planName
                 * @property {infinitusai.be.INullableString|null} [groupNumber] PolicyInfo groupNumber
                 * @property {infinitusai.be.PolicyInfo.OtherInsuranceStatus|null} [otherInsuranceStatus] PolicyInfo otherInsuranceStatus
                 * @property {infinitusai.be.INullableString|null} [groupName] PolicyInfo groupName
                 */
    
                /**
                 * Constructs a new PolicyInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PolicyInfo.
                 * @implements IPolicyInfo
                 * @constructor
                 * @param {infinitusai.be.IPolicyInfo=} [properties] Properties to set
                 */
                function PolicyInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PolicyInfo memberId.
                 * @member {string} memberId
                 * @memberof infinitusai.be.PolicyInfo
                 * @instance
                 */
                PolicyInfo.prototype.memberId = "";
    
                /**
                 * PolicyInfo planName.
                 * @member {infinitusai.be.INullableString|null|undefined} planName
                 * @memberof infinitusai.be.PolicyInfo
                 * @instance
                 */
                PolicyInfo.prototype.planName = null;
    
                /**
                 * PolicyInfo groupNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} groupNumber
                 * @memberof infinitusai.be.PolicyInfo
                 * @instance
                 */
                PolicyInfo.prototype.groupNumber = null;
    
                /**
                 * PolicyInfo otherInsuranceStatus.
                 * @member {infinitusai.be.PolicyInfo.OtherInsuranceStatus} otherInsuranceStatus
                 * @memberof infinitusai.be.PolicyInfo
                 * @instance
                 */
                PolicyInfo.prototype.otherInsuranceStatus = 0;
    
                /**
                 * PolicyInfo groupName.
                 * @member {infinitusai.be.INullableString|null|undefined} groupName
                 * @memberof infinitusai.be.PolicyInfo
                 * @instance
                 */
                PolicyInfo.prototype.groupName = null;
    
                /**
                 * Creates a new PolicyInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {infinitusai.be.IPolicyInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.PolicyInfo} PolicyInfo instance
                 */
                PolicyInfo.create = function create(properties) {
                    return new PolicyInfo(properties);
                };
    
                /**
                 * Encodes the specified PolicyInfo message. Does not implicitly {@link infinitusai.be.PolicyInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {infinitusai.be.IPolicyInfo} message PolicyInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PolicyInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.memberId);
                    if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                        $root.infinitusai.be.NullableString.encode(message.planName, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber"))
                        $root.infinitusai.be.NullableString.encode(message.groupNumber, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.otherInsuranceStatus != null && Object.hasOwnProperty.call(message, "otherInsuranceStatus"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.otherInsuranceStatus);
                    if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                        $root.infinitusai.be.NullableString.encode(message.groupName, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PolicyInfo message, length delimited. Does not implicitly {@link infinitusai.be.PolicyInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {infinitusai.be.IPolicyInfo} message PolicyInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PolicyInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PolicyInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PolicyInfo} PolicyInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PolicyInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PolicyInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.memberId = reader.string();
                                break;
                            }
                        case 2: {
                                message.planName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.groupNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.otherInsuranceStatus = reader.int32();
                                break;
                            }
                        case 5: {
                                message.groupName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PolicyInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PolicyInfo} PolicyInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PolicyInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PolicyInfo message.
                 * @function verify
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PolicyInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.memberId != null && message.hasOwnProperty("memberId"))
                        if (!$util.isString(message.memberId))
                            return "memberId: string expected";
                    if (message.planName != null && message.hasOwnProperty("planName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.planName);
                        if (error)
                            return "planName." + error;
                    }
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.groupNumber);
                        if (error)
                            return "groupNumber." + error;
                    }
                    if (message.otherInsuranceStatus != null && message.hasOwnProperty("otherInsuranceStatus"))
                        switch (message.otherInsuranceStatus) {
                        default:
                            return "otherInsuranceStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.groupName != null && message.hasOwnProperty("groupName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.groupName);
                        if (error)
                            return "groupName." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PolicyInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PolicyInfo} PolicyInfo
                 */
                PolicyInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PolicyInfo)
                        return object;
                    var message = new $root.infinitusai.be.PolicyInfo();
                    if (object.memberId != null)
                        message.memberId = String(object.memberId);
                    if (object.planName != null) {
                        if (typeof object.planName !== "object")
                            throw TypeError(".infinitusai.be.PolicyInfo.planName: object expected");
                        message.planName = $root.infinitusai.be.NullableString.fromObject(object.planName);
                    }
                    if (object.groupNumber != null) {
                        if (typeof object.groupNumber !== "object")
                            throw TypeError(".infinitusai.be.PolicyInfo.groupNumber: object expected");
                        message.groupNumber = $root.infinitusai.be.NullableString.fromObject(object.groupNumber);
                    }
                    switch (object.otherInsuranceStatus) {
                    default:
                        if (typeof object.otherInsuranceStatus === "number") {
                            message.otherInsuranceStatus = object.otherInsuranceStatus;
                            break;
                        }
                        break;
                    case "OTHER_INSURANCE_STATUS_UNKNOWN":
                    case 0:
                        message.otherInsuranceStatus = 0;
                        break;
                    case "OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B":
                    case 1:
                        message.otherInsuranceStatus = 1;
                        break;
                    case "OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY":
                    case 2:
                        message.otherInsuranceStatus = 2;
                        break;
                    case "OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY":
                    case 3:
                        message.otherInsuranceStatus = 3;
                        break;
                    case "OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY":
                    case 4:
                        message.otherInsuranceStatus = 4;
                        break;
                    }
                    if (object.groupName != null) {
                        if (typeof object.groupName !== "object")
                            throw TypeError(".infinitusai.be.PolicyInfo.groupName: object expected");
                        message.groupName = $root.infinitusai.be.NullableString.fromObject(object.groupName);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PolicyInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {infinitusai.be.PolicyInfo} message PolicyInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PolicyInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.memberId = "";
                        object.planName = null;
                        object.groupNumber = null;
                        object.otherInsuranceStatus = options.enums === String ? "OTHER_INSURANCE_STATUS_UNKNOWN" : 0;
                        object.groupName = null;
                    }
                    if (message.memberId != null && message.hasOwnProperty("memberId"))
                        object.memberId = message.memberId;
                    if (message.planName != null && message.hasOwnProperty("planName"))
                        object.planName = $root.infinitusai.be.NullableString.toObject(message.planName, options);
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                        object.groupNumber = $root.infinitusai.be.NullableString.toObject(message.groupNumber, options);
                    if (message.otherInsuranceStatus != null && message.hasOwnProperty("otherInsuranceStatus"))
                        object.otherInsuranceStatus = options.enums === String ? $root.infinitusai.be.PolicyInfo.OtherInsuranceStatus[message.otherInsuranceStatus] === undefined ? message.otherInsuranceStatus : $root.infinitusai.be.PolicyInfo.OtherInsuranceStatus[message.otherInsuranceStatus] : message.otherInsuranceStatus;
                    if (message.groupName != null && message.hasOwnProperty("groupName"))
                        object.groupName = $root.infinitusai.be.NullableString.toObject(message.groupName, options);
                    return object;
                };
    
                /**
                 * Converts this PolicyInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PolicyInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PolicyInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PolicyInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PolicyInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PolicyInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PolicyInfo";
                };
    
                /**
                 * OtherInsuranceStatus enum.
                 * @name infinitusai.be.PolicyInfo.OtherInsuranceStatus
                 * @enum {number}
                 * @property {number} OTHER_INSURANCE_STATUS_UNKNOWN=0 OTHER_INSURANCE_STATUS_UNKNOWN value
                 * @property {number} OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B=1 OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B value
                 * @property {number} OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY=2 OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY value
                 * @property {number} OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY=3 OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY value
                 * @property {number} OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY=4 OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY value
                 */
                PolicyInfo.OtherInsuranceStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "OTHER_INSURANCE_STATUS_UNKNOWN"] = 0;
                    values[valuesById[1] = "OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B"] = 1;
                    values[valuesById[2] = "OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY"] = 2;
                    values[valuesById[3] = "OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY"] = 3;
                    values[valuesById[4] = "OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY"] = 4;
                    return values;
                })();
    
                return PolicyInfo;
            })();
    
            be.PayerInfo = (function() {
    
                /**
                 * Properties of a PayerInfo.
                 * @memberof infinitusai.be
                 * @interface IPayerInfo
                 * @property {string|null} [name] PayerInfo name
                 * @property {string|null} [infinitusId] PayerInfo infinitusId
                 * @property {string|null} [providedPhoneNumber] PayerInfo providedPhoneNumber
                 * @property {string|null} [resolvedPhoneNumber] PayerInfo resolvedPhoneNumber
                 * @property {string|null} [phoneNumber] PayerInfo phoneNumber
                 * @property {infinitusai.be.Association|null} [association] PayerInfo association
                 * @property {string|null} [resolvedChcTradingPartnerServiceId] PayerInfo resolvedChcTradingPartnerServiceId
                 * @property {infinitusai.be.IPBMPayerInfo|null} [pbmPayerInfo] PayerInfo pbmPayerInfo
                 * @property {infinitusai.be.IBVPayerInfo|null} [bvPayerInfo] PayerInfo bvPayerInfo
                 * @property {string|null} [customerPayerName] PayerInfo customerPayerName
                 * @property {string|null} [customerInfinitusId] PayerInfo customerInfinitusId
                 */
    
                /**
                 * Constructs a new PayerInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerInfo.
                 * @implements IPayerInfo
                 * @constructor
                 * @param {infinitusai.be.IPayerInfo=} [properties] Properties to set
                 */
                function PayerInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerInfo name.
                 * @member {string} name
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.name = "";
    
                /**
                 * PayerInfo infinitusId.
                 * @member {string} infinitusId
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.infinitusId = "";
    
                /**
                 * PayerInfo providedPhoneNumber.
                 * @member {string} providedPhoneNumber
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.providedPhoneNumber = "";
    
                /**
                 * PayerInfo resolvedPhoneNumber.
                 * @member {string} resolvedPhoneNumber
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.resolvedPhoneNumber = "";
    
                /**
                 * PayerInfo phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.phoneNumber = "";
    
                /**
                 * PayerInfo association.
                 * @member {infinitusai.be.Association} association
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.association = 0;
    
                /**
                 * PayerInfo resolvedChcTradingPartnerServiceId.
                 * @member {string} resolvedChcTradingPartnerServiceId
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.resolvedChcTradingPartnerServiceId = "";
    
                /**
                 * PayerInfo pbmPayerInfo.
                 * @member {infinitusai.be.IPBMPayerInfo|null|undefined} pbmPayerInfo
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.pbmPayerInfo = null;
    
                /**
                 * PayerInfo bvPayerInfo.
                 * @member {infinitusai.be.IBVPayerInfo|null|undefined} bvPayerInfo
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.bvPayerInfo = null;
    
                /**
                 * PayerInfo customerPayerName.
                 * @member {string} customerPayerName
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.customerPayerName = "";
    
                /**
                 * PayerInfo customerInfinitusId.
                 * @member {string} customerInfinitusId
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 */
                PayerInfo.prototype.customerInfinitusId = "";
    
                /**
                 * Creates a new PayerInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {infinitusai.be.IPayerInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerInfo} PayerInfo instance
                 */
                PayerInfo.create = function create(properties) {
                    return new PayerInfo(properties);
                };
    
                /**
                 * Encodes the specified PayerInfo message. Does not implicitly {@link infinitusai.be.PayerInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {infinitusai.be.IPayerInfo} message PayerInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.infinitusId != null && Object.hasOwnProperty.call(message, "infinitusId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.infinitusId);
                    if (message.providedPhoneNumber != null && Object.hasOwnProperty.call(message, "providedPhoneNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.providedPhoneNumber);
                    if (message.resolvedPhoneNumber != null && Object.hasOwnProperty.call(message, "resolvedPhoneNumber"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.resolvedPhoneNumber);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.phoneNumber);
                    if (message.association != null && Object.hasOwnProperty.call(message, "association"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.association);
                    if (message.resolvedChcTradingPartnerServiceId != null && Object.hasOwnProperty.call(message, "resolvedChcTradingPartnerServiceId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.resolvedChcTradingPartnerServiceId);
                    if (message.pbmPayerInfo != null && Object.hasOwnProperty.call(message, "pbmPayerInfo"))
                        $root.infinitusai.be.PBMPayerInfo.encode(message.pbmPayerInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.bvPayerInfo != null && Object.hasOwnProperty.call(message, "bvPayerInfo"))
                        $root.infinitusai.be.BVPayerInfo.encode(message.bvPayerInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.customerPayerName != null && Object.hasOwnProperty.call(message, "customerPayerName"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.customerPayerName);
                    if (message.customerInfinitusId != null && Object.hasOwnProperty.call(message, "customerInfinitusId"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.customerInfinitusId);
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerInfo message, length delimited. Does not implicitly {@link infinitusai.be.PayerInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {infinitusai.be.IPayerInfo} message PayerInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerInfo} PayerInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.infinitusId = reader.string();
                                break;
                            }
                        case 3: {
                                message.providedPhoneNumber = reader.string();
                                break;
                            }
                        case 4: {
                                message.resolvedPhoneNumber = reader.string();
                                break;
                            }
                        case 5: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 6: {
                                message.association = reader.int32();
                                break;
                            }
                        case 7: {
                                message.resolvedChcTradingPartnerServiceId = reader.string();
                                break;
                            }
                        case 8: {
                                message.pbmPayerInfo = $root.infinitusai.be.PBMPayerInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.bvPayerInfo = $root.infinitusai.be.BVPayerInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.customerPayerName = reader.string();
                                break;
                            }
                        case 11: {
                                message.customerInfinitusId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerInfo} PayerInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerInfo message.
                 * @function verify
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.infinitusId != null && message.hasOwnProperty("infinitusId"))
                        if (!$util.isString(message.infinitusId))
                            return "infinitusId: string expected";
                    if (message.providedPhoneNumber != null && message.hasOwnProperty("providedPhoneNumber"))
                        if (!$util.isString(message.providedPhoneNumber))
                            return "providedPhoneNumber: string expected";
                    if (message.resolvedPhoneNumber != null && message.hasOwnProperty("resolvedPhoneNumber"))
                        if (!$util.isString(message.resolvedPhoneNumber))
                            return "resolvedPhoneNumber: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.association != null && message.hasOwnProperty("association"))
                        switch (message.association) {
                        default:
                            return "association: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.resolvedChcTradingPartnerServiceId != null && message.hasOwnProperty("resolvedChcTradingPartnerServiceId"))
                        if (!$util.isString(message.resolvedChcTradingPartnerServiceId))
                            return "resolvedChcTradingPartnerServiceId: string expected";
                    if (message.pbmPayerInfo != null && message.hasOwnProperty("pbmPayerInfo")) {
                        var error = $root.infinitusai.be.PBMPayerInfo.verify(message.pbmPayerInfo);
                        if (error)
                            return "pbmPayerInfo." + error;
                    }
                    if (message.bvPayerInfo != null && message.hasOwnProperty("bvPayerInfo")) {
                        var error = $root.infinitusai.be.BVPayerInfo.verify(message.bvPayerInfo);
                        if (error)
                            return "bvPayerInfo." + error;
                    }
                    if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                        if (!$util.isString(message.customerPayerName))
                            return "customerPayerName: string expected";
                    if (message.customerInfinitusId != null && message.hasOwnProperty("customerInfinitusId"))
                        if (!$util.isString(message.customerInfinitusId))
                            return "customerInfinitusId: string expected";
                    return null;
                };
    
                /**
                 * Creates a PayerInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerInfo} PayerInfo
                 */
                PayerInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerInfo)
                        return object;
                    var message = new $root.infinitusai.be.PayerInfo();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.infinitusId != null)
                        message.infinitusId = String(object.infinitusId);
                    if (object.providedPhoneNumber != null)
                        message.providedPhoneNumber = String(object.providedPhoneNumber);
                    if (object.resolvedPhoneNumber != null)
                        message.resolvedPhoneNumber = String(object.resolvedPhoneNumber);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    switch (object.association) {
                    default:
                        if (typeof object.association === "number") {
                            message.association = object.association;
                            break;
                        }
                        break;
                    case "ASSOCIATION_UNKNOWN":
                    case 0:
                        message.association = 0;
                        break;
                    case "ASSOCIATION_BCBS":
                    case 1:
                        message.association = 1;
                        break;
                    case "ASSOCIATION_BCBS_FEDERAL":
                    case 2:
                        message.association = 2;
                        break;
                    }
                    if (object.resolvedChcTradingPartnerServiceId != null)
                        message.resolvedChcTradingPartnerServiceId = String(object.resolvedChcTradingPartnerServiceId);
                    if (object.pbmPayerInfo != null) {
                        if (typeof object.pbmPayerInfo !== "object")
                            throw TypeError(".infinitusai.be.PayerInfo.pbmPayerInfo: object expected");
                        message.pbmPayerInfo = $root.infinitusai.be.PBMPayerInfo.fromObject(object.pbmPayerInfo);
                    }
                    if (object.bvPayerInfo != null) {
                        if (typeof object.bvPayerInfo !== "object")
                            throw TypeError(".infinitusai.be.PayerInfo.bvPayerInfo: object expected");
                        message.bvPayerInfo = $root.infinitusai.be.BVPayerInfo.fromObject(object.bvPayerInfo);
                    }
                    if (object.customerPayerName != null)
                        message.customerPayerName = String(object.customerPayerName);
                    if (object.customerInfinitusId != null)
                        message.customerInfinitusId = String(object.customerInfinitusId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {infinitusai.be.PayerInfo} message PayerInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.infinitusId = "";
                        object.providedPhoneNumber = "";
                        object.resolvedPhoneNumber = "";
                        object.phoneNumber = "";
                        object.association = options.enums === String ? "ASSOCIATION_UNKNOWN" : 0;
                        object.resolvedChcTradingPartnerServiceId = "";
                        object.pbmPayerInfo = null;
                        object.bvPayerInfo = null;
                        object.customerPayerName = "";
                        object.customerInfinitusId = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.infinitusId != null && message.hasOwnProperty("infinitusId"))
                        object.infinitusId = message.infinitusId;
                    if (message.providedPhoneNumber != null && message.hasOwnProperty("providedPhoneNumber"))
                        object.providedPhoneNumber = message.providedPhoneNumber;
                    if (message.resolvedPhoneNumber != null && message.hasOwnProperty("resolvedPhoneNumber"))
                        object.resolvedPhoneNumber = message.resolvedPhoneNumber;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.association != null && message.hasOwnProperty("association"))
                        object.association = options.enums === String ? $root.infinitusai.be.Association[message.association] === undefined ? message.association : $root.infinitusai.be.Association[message.association] : message.association;
                    if (message.resolvedChcTradingPartnerServiceId != null && message.hasOwnProperty("resolvedChcTradingPartnerServiceId"))
                        object.resolvedChcTradingPartnerServiceId = message.resolvedChcTradingPartnerServiceId;
                    if (message.pbmPayerInfo != null && message.hasOwnProperty("pbmPayerInfo"))
                        object.pbmPayerInfo = $root.infinitusai.be.PBMPayerInfo.toObject(message.pbmPayerInfo, options);
                    if (message.bvPayerInfo != null && message.hasOwnProperty("bvPayerInfo"))
                        object.bvPayerInfo = $root.infinitusai.be.BVPayerInfo.toObject(message.bvPayerInfo, options);
                    if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                        object.customerPayerName = message.customerPayerName;
                    if (message.customerInfinitusId != null && message.hasOwnProperty("customerInfinitusId"))
                        object.customerInfinitusId = message.customerInfinitusId;
                    return object;
                };
    
                /**
                 * Converts this PayerInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerInfo";
                };
    
                return PayerInfo;
            })();
    
            be.TreatmentInfo = (function() {
    
                /**
                 * Properties of a TreatmentInfo.
                 * @memberof infinitusai.be
                 * @interface ITreatmentInfo
                 * @property {string|null} [treatmentCode] TreatmentInfo treatmentCode
                 * @property {string|null} [secondaryTreatmentCode] TreatmentInfo secondaryTreatmentCode
                 * @property {string|null} [tertiaryTreatmentCode] TreatmentInfo tertiaryTreatmentCode
                 * @property {infinitusai.be.IFacilityType|null} [facilityType] TreatmentInfo facilityType
                 * @property {infinitusai.be.IDate|null} [anticipatedDateOfTreatment] TreatmentInfo anticipatedDateOfTreatment
                 * @property {boolean|null} [skipSpecialtyPharmacyInfo] TreatmentInfo skipSpecialtyPharmacyInfo
                 * @property {string|null} [facilityTypeAdditionalNotes] TreatmentInfo facilityTypeAdditionalNotes
                 */
    
                /**
                 * Constructs a new TreatmentInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TreatmentInfo.
                 * @implements ITreatmentInfo
                 * @constructor
                 * @param {infinitusai.be.ITreatmentInfo=} [properties] Properties to set
                 */
                function TreatmentInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TreatmentInfo treatmentCode.
                 * @member {string} treatmentCode
                 * @memberof infinitusai.be.TreatmentInfo
                 * @instance
                 */
                TreatmentInfo.prototype.treatmentCode = "";
    
                /**
                 * TreatmentInfo secondaryTreatmentCode.
                 * @member {string} secondaryTreatmentCode
                 * @memberof infinitusai.be.TreatmentInfo
                 * @instance
                 */
                TreatmentInfo.prototype.secondaryTreatmentCode = "";
    
                /**
                 * TreatmentInfo tertiaryTreatmentCode.
                 * @member {string} tertiaryTreatmentCode
                 * @memberof infinitusai.be.TreatmentInfo
                 * @instance
                 */
                TreatmentInfo.prototype.tertiaryTreatmentCode = "";
    
                /**
                 * TreatmentInfo facilityType.
                 * @member {infinitusai.be.IFacilityType|null|undefined} facilityType
                 * @memberof infinitusai.be.TreatmentInfo
                 * @instance
                 */
                TreatmentInfo.prototype.facilityType = null;
    
                /**
                 * TreatmentInfo anticipatedDateOfTreatment.
                 * @member {infinitusai.be.IDate|null|undefined} anticipatedDateOfTreatment
                 * @memberof infinitusai.be.TreatmentInfo
                 * @instance
                 */
                TreatmentInfo.prototype.anticipatedDateOfTreatment = null;
    
                /**
                 * TreatmentInfo skipSpecialtyPharmacyInfo.
                 * @member {boolean} skipSpecialtyPharmacyInfo
                 * @memberof infinitusai.be.TreatmentInfo
                 * @instance
                 */
                TreatmentInfo.prototype.skipSpecialtyPharmacyInfo = false;
    
                /**
                 * TreatmentInfo facilityTypeAdditionalNotes.
                 * @member {string} facilityTypeAdditionalNotes
                 * @memberof infinitusai.be.TreatmentInfo
                 * @instance
                 */
                TreatmentInfo.prototype.facilityTypeAdditionalNotes = "";
    
                /**
                 * Creates a new TreatmentInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {infinitusai.be.ITreatmentInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.TreatmentInfo} TreatmentInfo instance
                 */
                TreatmentInfo.create = function create(properties) {
                    return new TreatmentInfo(properties);
                };
    
                /**
                 * Encodes the specified TreatmentInfo message. Does not implicitly {@link infinitusai.be.TreatmentInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {infinitusai.be.ITreatmentInfo} message TreatmentInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TreatmentInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.treatmentCode != null && Object.hasOwnProperty.call(message, "treatmentCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.treatmentCode);
                    if (message.secondaryTreatmentCode != null && Object.hasOwnProperty.call(message, "secondaryTreatmentCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.secondaryTreatmentCode);
                    if (message.facilityType != null && Object.hasOwnProperty.call(message, "facilityType"))
                        $root.infinitusai.be.FacilityType.encode(message.facilityType, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.tertiaryTreatmentCode != null && Object.hasOwnProperty.call(message, "tertiaryTreatmentCode"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.tertiaryTreatmentCode);
                    if (message.anticipatedDateOfTreatment != null && Object.hasOwnProperty.call(message, "anticipatedDateOfTreatment"))
                        $root.infinitusai.be.Date.encode(message.anticipatedDateOfTreatment, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.skipSpecialtyPharmacyInfo != null && Object.hasOwnProperty.call(message, "skipSpecialtyPharmacyInfo"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.skipSpecialtyPharmacyInfo);
                    if (message.facilityTypeAdditionalNotes != null && Object.hasOwnProperty.call(message, "facilityTypeAdditionalNotes"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.facilityTypeAdditionalNotes);
                    return writer;
                };
    
                /**
                 * Encodes the specified TreatmentInfo message, length delimited. Does not implicitly {@link infinitusai.be.TreatmentInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {infinitusai.be.ITreatmentInfo} message TreatmentInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TreatmentInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TreatmentInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TreatmentInfo} TreatmentInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TreatmentInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TreatmentInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.treatmentCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.secondaryTreatmentCode = reader.string();
                                break;
                            }
                        case 4: {
                                message.tertiaryTreatmentCode = reader.string();
                                break;
                            }
                        case 3: {
                                message.facilityType = $root.infinitusai.be.FacilityType.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.anticipatedDateOfTreatment = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.skipSpecialtyPharmacyInfo = reader.bool();
                                break;
                            }
                        case 7: {
                                message.facilityTypeAdditionalNotes = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TreatmentInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TreatmentInfo} TreatmentInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TreatmentInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TreatmentInfo message.
                 * @function verify
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TreatmentInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.treatmentCode != null && message.hasOwnProperty("treatmentCode"))
                        if (!$util.isString(message.treatmentCode))
                            return "treatmentCode: string expected";
                    if (message.secondaryTreatmentCode != null && message.hasOwnProperty("secondaryTreatmentCode"))
                        if (!$util.isString(message.secondaryTreatmentCode))
                            return "secondaryTreatmentCode: string expected";
                    if (message.tertiaryTreatmentCode != null && message.hasOwnProperty("tertiaryTreatmentCode"))
                        if (!$util.isString(message.tertiaryTreatmentCode))
                            return "tertiaryTreatmentCode: string expected";
                    if (message.facilityType != null && message.hasOwnProperty("facilityType")) {
                        var error = $root.infinitusai.be.FacilityType.verify(message.facilityType);
                        if (error)
                            return "facilityType." + error;
                    }
                    if (message.anticipatedDateOfTreatment != null && message.hasOwnProperty("anticipatedDateOfTreatment")) {
                        var error = $root.infinitusai.be.Date.verify(message.anticipatedDateOfTreatment);
                        if (error)
                            return "anticipatedDateOfTreatment." + error;
                    }
                    if (message.skipSpecialtyPharmacyInfo != null && message.hasOwnProperty("skipSpecialtyPharmacyInfo"))
                        if (typeof message.skipSpecialtyPharmacyInfo !== "boolean")
                            return "skipSpecialtyPharmacyInfo: boolean expected";
                    if (message.facilityTypeAdditionalNotes != null && message.hasOwnProperty("facilityTypeAdditionalNotes"))
                        if (!$util.isString(message.facilityTypeAdditionalNotes))
                            return "facilityTypeAdditionalNotes: string expected";
                    return null;
                };
    
                /**
                 * Creates a TreatmentInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TreatmentInfo} TreatmentInfo
                 */
                TreatmentInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TreatmentInfo)
                        return object;
                    var message = new $root.infinitusai.be.TreatmentInfo();
                    if (object.treatmentCode != null)
                        message.treatmentCode = String(object.treatmentCode);
                    if (object.secondaryTreatmentCode != null)
                        message.secondaryTreatmentCode = String(object.secondaryTreatmentCode);
                    if (object.tertiaryTreatmentCode != null)
                        message.tertiaryTreatmentCode = String(object.tertiaryTreatmentCode);
                    if (object.facilityType != null) {
                        if (typeof object.facilityType !== "object")
                            throw TypeError(".infinitusai.be.TreatmentInfo.facilityType: object expected");
                        message.facilityType = $root.infinitusai.be.FacilityType.fromObject(object.facilityType);
                    }
                    if (object.anticipatedDateOfTreatment != null) {
                        if (typeof object.anticipatedDateOfTreatment !== "object")
                            throw TypeError(".infinitusai.be.TreatmentInfo.anticipatedDateOfTreatment: object expected");
                        message.anticipatedDateOfTreatment = $root.infinitusai.be.Date.fromObject(object.anticipatedDateOfTreatment);
                    }
                    if (object.skipSpecialtyPharmacyInfo != null)
                        message.skipSpecialtyPharmacyInfo = Boolean(object.skipSpecialtyPharmacyInfo);
                    if (object.facilityTypeAdditionalNotes != null)
                        message.facilityTypeAdditionalNotes = String(object.facilityTypeAdditionalNotes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TreatmentInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {infinitusai.be.TreatmentInfo} message TreatmentInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TreatmentInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.treatmentCode = "";
                        object.secondaryTreatmentCode = "";
                        object.facilityType = null;
                        object.tertiaryTreatmentCode = "";
                        object.anticipatedDateOfTreatment = null;
                        object.skipSpecialtyPharmacyInfo = false;
                        object.facilityTypeAdditionalNotes = "";
                    }
                    if (message.treatmentCode != null && message.hasOwnProperty("treatmentCode"))
                        object.treatmentCode = message.treatmentCode;
                    if (message.secondaryTreatmentCode != null && message.hasOwnProperty("secondaryTreatmentCode"))
                        object.secondaryTreatmentCode = message.secondaryTreatmentCode;
                    if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                        object.facilityType = $root.infinitusai.be.FacilityType.toObject(message.facilityType, options);
                    if (message.tertiaryTreatmentCode != null && message.hasOwnProperty("tertiaryTreatmentCode"))
                        object.tertiaryTreatmentCode = message.tertiaryTreatmentCode;
                    if (message.anticipatedDateOfTreatment != null && message.hasOwnProperty("anticipatedDateOfTreatment"))
                        object.anticipatedDateOfTreatment = $root.infinitusai.be.Date.toObject(message.anticipatedDateOfTreatment, options);
                    if (message.skipSpecialtyPharmacyInfo != null && message.hasOwnProperty("skipSpecialtyPharmacyInfo"))
                        object.skipSpecialtyPharmacyInfo = message.skipSpecialtyPharmacyInfo;
                    if (message.facilityTypeAdditionalNotes != null && message.hasOwnProperty("facilityTypeAdditionalNotes"))
                        object.facilityTypeAdditionalNotes = message.facilityTypeAdditionalNotes;
                    return object;
                };
    
                /**
                 * Converts this TreatmentInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TreatmentInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TreatmentInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TreatmentInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TreatmentInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TreatmentInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TreatmentInfo";
                };
    
                return TreatmentInfo;
            })();
    
            be.BVCallInputs = (function() {
    
                /**
                 * Properties of a BVCallInputs.
                 * @memberof infinitusai.be
                 * @interface IBVCallInputs
                 * @property {infinitusai.be.ICallerInfo|null} [callerInfo] BVCallInputs callerInfo
                 * @property {infinitusai.be.IPatientInfo|null} [patientInfo] BVCallInputs patientInfo
                 * @property {Array.<infinitusai.be.IProductInfo>|null} [productInfos] BVCallInputs productInfos
                 * @property {string|null} [adminCode] BVCallInputs adminCode
                 * @property {infinitusai.be.IPracticeInfo|null} [practiceInfo] BVCallInputs practiceInfo
                 * @property {infinitusai.be.IProviderInfo|null} [providerInfo] BVCallInputs providerInfo
                 * @property {infinitusai.be.IPolicyInfo|null} [policyInfo] BVCallInputs policyInfo
                 * @property {infinitusai.be.IPayerInfo|null} [payerInfo] BVCallInputs payerInfo
                 * @property {infinitusai.be.ITreatmentInfo|null} [treatmentInfo] BVCallInputs treatmentInfo
                 * @property {Array.<string>|null} [adminCodes] BVCallInputs adminCodes
                 * @property {boolean|null} [annualReenrollment] BVCallInputs annualReenrollment
                 * @property {Array.<infinitusapi.IINFBVTaskInputSiteOfCare>|null} [sitesOfCare] BVCallInputs sitesOfCare
                 * @property {string|null} [additionalNotes] BVCallInputs additionalNotes
                 * @property {Array.<infinitusai.be.ICptInfo>|null} [cptInfos] BVCallInputs cptInfos
                 * @property {infinitusai.be.IPolicyHolderInfo|null} [policyHolderInfo] BVCallInputs policyHolderInfo
                 */
    
                /**
                 * Constructs a new BVCallInputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BVCallInputs.
                 * @implements IBVCallInputs
                 * @constructor
                 * @param {infinitusai.be.IBVCallInputs=} [properties] Properties to set
                 */
                function BVCallInputs(properties) {
                    this.productInfos = [];
                    this.adminCodes = [];
                    this.sitesOfCare = [];
                    this.cptInfos = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BVCallInputs callerInfo.
                 * @member {infinitusai.be.ICallerInfo|null|undefined} callerInfo
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.callerInfo = null;
    
                /**
                 * BVCallInputs patientInfo.
                 * @member {infinitusai.be.IPatientInfo|null|undefined} patientInfo
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.patientInfo = null;
    
                /**
                 * BVCallInputs productInfos.
                 * @member {Array.<infinitusai.be.IProductInfo>} productInfos
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.productInfos = $util.emptyArray;
    
                /**
                 * BVCallInputs adminCode.
                 * @member {string} adminCode
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.adminCode = "";
    
                /**
                 * BVCallInputs practiceInfo.
                 * @member {infinitusai.be.IPracticeInfo|null|undefined} practiceInfo
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.practiceInfo = null;
    
                /**
                 * BVCallInputs providerInfo.
                 * @member {infinitusai.be.IProviderInfo|null|undefined} providerInfo
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.providerInfo = null;
    
                /**
                 * BVCallInputs policyInfo.
                 * @member {infinitusai.be.IPolicyInfo|null|undefined} policyInfo
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.policyInfo = null;
    
                /**
                 * BVCallInputs payerInfo.
                 * @member {infinitusai.be.IPayerInfo|null|undefined} payerInfo
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.payerInfo = null;
    
                /**
                 * BVCallInputs treatmentInfo.
                 * @member {infinitusai.be.ITreatmentInfo|null|undefined} treatmentInfo
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.treatmentInfo = null;
    
                /**
                 * BVCallInputs adminCodes.
                 * @member {Array.<string>} adminCodes
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.adminCodes = $util.emptyArray;
    
                /**
                 * BVCallInputs annualReenrollment.
                 * @member {boolean} annualReenrollment
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.annualReenrollment = false;
    
                /**
                 * BVCallInputs sitesOfCare.
                 * @member {Array.<infinitusapi.IINFBVTaskInputSiteOfCare>} sitesOfCare
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.sitesOfCare = $util.emptyArray;
    
                /**
                 * BVCallInputs additionalNotes.
                 * @member {string} additionalNotes
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.additionalNotes = "";
    
                /**
                 * BVCallInputs cptInfos.
                 * @member {Array.<infinitusai.be.ICptInfo>} cptInfos
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.cptInfos = $util.emptyArray;
    
                /**
                 * BVCallInputs policyHolderInfo.
                 * @member {infinitusai.be.IPolicyHolderInfo|null|undefined} policyHolderInfo
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 */
                BVCallInputs.prototype.policyHolderInfo = null;
    
                /**
                 * Creates a new BVCallInputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {infinitusai.be.IBVCallInputs=} [properties] Properties to set
                 * @returns {infinitusai.be.BVCallInputs} BVCallInputs instance
                 */
                BVCallInputs.create = function create(properties) {
                    return new BVCallInputs(properties);
                };
    
                /**
                 * Encodes the specified BVCallInputs message. Does not implicitly {@link infinitusai.be.BVCallInputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {infinitusai.be.IBVCallInputs} message BVCallInputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BVCallInputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callerInfo != null && Object.hasOwnProperty.call(message, "callerInfo"))
                        $root.infinitusai.be.CallerInfo.encode(message.callerInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.patientInfo != null && Object.hasOwnProperty.call(message, "patientInfo"))
                        $root.infinitusai.be.PatientInfo.encode(message.patientInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.practiceInfo != null && Object.hasOwnProperty.call(message, "practiceInfo"))
                        $root.infinitusai.be.PracticeInfo.encode(message.practiceInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.providerInfo != null && Object.hasOwnProperty.call(message, "providerInfo"))
                        $root.infinitusai.be.ProviderInfo.encode(message.providerInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.policyInfo != null && Object.hasOwnProperty.call(message, "policyInfo"))
                        $root.infinitusai.be.PolicyInfo.encode(message.policyInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.payerInfo != null && Object.hasOwnProperty.call(message, "payerInfo"))
                        $root.infinitusai.be.PayerInfo.encode(message.payerInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.treatmentInfo != null && Object.hasOwnProperty.call(message, "treatmentInfo"))
                        $root.infinitusai.be.TreatmentInfo.encode(message.treatmentInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.productInfos != null && message.productInfos.length)
                        for (var i = 0; i < message.productInfos.length; ++i)
                            $root.infinitusai.be.ProductInfo.encode(message.productInfos[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.adminCode != null && Object.hasOwnProperty.call(message, "adminCode"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.adminCode);
                    if (message.adminCodes != null && message.adminCodes.length)
                        for (var i = 0; i < message.adminCodes.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.adminCodes[i]);
                    if (message.annualReenrollment != null && Object.hasOwnProperty.call(message, "annualReenrollment"))
                        writer.uint32(/* id 13, wireType 0 =*/104).bool(message.annualReenrollment);
                    if (message.sitesOfCare != null && message.sitesOfCare.length)
                        for (var i = 0; i < message.sitesOfCare.length; ++i)
                            $root.infinitusapi.INFBVTaskInputSiteOfCare.encode(message.sitesOfCare[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.additionalNotes);
                    if (message.cptInfos != null && message.cptInfos.length)
                        for (var i = 0; i < message.cptInfos.length; ++i)
                            $root.infinitusai.be.CptInfo.encode(message.cptInfos[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.policyHolderInfo != null && Object.hasOwnProperty.call(message, "policyHolderInfo"))
                        $root.infinitusai.be.PolicyHolderInfo.encode(message.policyHolderInfo, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified BVCallInputs message, length delimited. Does not implicitly {@link infinitusai.be.BVCallInputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {infinitusai.be.IBVCallInputs} message BVCallInputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BVCallInputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BVCallInputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BVCallInputs} BVCallInputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BVCallInputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BVCallInputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callerInfo = $root.infinitusai.be.CallerInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.patientInfo = $root.infinitusai.be.PatientInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                if (!(message.productInfos && message.productInfos.length))
                                    message.productInfos = [];
                                message.productInfos.push($root.infinitusai.be.ProductInfo.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                message.adminCode = reader.string();
                                break;
                            }
                        case 4: {
                                message.practiceInfo = $root.infinitusai.be.PracticeInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.providerInfo = $root.infinitusai.be.ProviderInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.policyInfo = $root.infinitusai.be.PolicyInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.payerInfo = $root.infinitusai.be.PayerInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.treatmentInfo = $root.infinitusai.be.TreatmentInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                if (!(message.adminCodes && message.adminCodes.length))
                                    message.adminCodes = [];
                                message.adminCodes.push(reader.string());
                                break;
                            }
                        case 13: {
                                message.annualReenrollment = reader.bool();
                                break;
                            }
                        case 14: {
                                if (!(message.sitesOfCare && message.sitesOfCare.length))
                                    message.sitesOfCare = [];
                                message.sitesOfCare.push($root.infinitusapi.INFBVTaskInputSiteOfCare.decode(reader, reader.uint32()));
                                break;
                            }
                        case 15: {
                                message.additionalNotes = reader.string();
                                break;
                            }
                        case 16: {
                                if (!(message.cptInfos && message.cptInfos.length))
                                    message.cptInfos = [];
                                message.cptInfos.push($root.infinitusai.be.CptInfo.decode(reader, reader.uint32()));
                                break;
                            }
                        case 17: {
                                message.policyHolderInfo = $root.infinitusai.be.PolicyHolderInfo.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BVCallInputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BVCallInputs} BVCallInputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BVCallInputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BVCallInputs message.
                 * @function verify
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BVCallInputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callerInfo != null && message.hasOwnProperty("callerInfo")) {
                        var error = $root.infinitusai.be.CallerInfo.verify(message.callerInfo);
                        if (error)
                            return "callerInfo." + error;
                    }
                    if (message.patientInfo != null && message.hasOwnProperty("patientInfo")) {
                        var error = $root.infinitusai.be.PatientInfo.verify(message.patientInfo);
                        if (error)
                            return "patientInfo." + error;
                    }
                    if (message.productInfos != null && message.hasOwnProperty("productInfos")) {
                        if (!Array.isArray(message.productInfos))
                            return "productInfos: array expected";
                        for (var i = 0; i < message.productInfos.length; ++i) {
                            var error = $root.infinitusai.be.ProductInfo.verify(message.productInfos[i]);
                            if (error)
                                return "productInfos." + error;
                        }
                    }
                    if (message.adminCode != null && message.hasOwnProperty("adminCode"))
                        if (!$util.isString(message.adminCode))
                            return "adminCode: string expected";
                    if (message.practiceInfo != null && message.hasOwnProperty("practiceInfo")) {
                        var error = $root.infinitusai.be.PracticeInfo.verify(message.practiceInfo);
                        if (error)
                            return "practiceInfo." + error;
                    }
                    if (message.providerInfo != null && message.hasOwnProperty("providerInfo")) {
                        var error = $root.infinitusai.be.ProviderInfo.verify(message.providerInfo);
                        if (error)
                            return "providerInfo." + error;
                    }
                    if (message.policyInfo != null && message.hasOwnProperty("policyInfo")) {
                        var error = $root.infinitusai.be.PolicyInfo.verify(message.policyInfo);
                        if (error)
                            return "policyInfo." + error;
                    }
                    if (message.payerInfo != null && message.hasOwnProperty("payerInfo")) {
                        var error = $root.infinitusai.be.PayerInfo.verify(message.payerInfo);
                        if (error)
                            return "payerInfo." + error;
                    }
                    if (message.treatmentInfo != null && message.hasOwnProperty("treatmentInfo")) {
                        var error = $root.infinitusai.be.TreatmentInfo.verify(message.treatmentInfo);
                        if (error)
                            return "treatmentInfo." + error;
                    }
                    if (message.adminCodes != null && message.hasOwnProperty("adminCodes")) {
                        if (!Array.isArray(message.adminCodes))
                            return "adminCodes: array expected";
                        for (var i = 0; i < message.adminCodes.length; ++i)
                            if (!$util.isString(message.adminCodes[i]))
                                return "adminCodes: string[] expected";
                    }
                    if (message.annualReenrollment != null && message.hasOwnProperty("annualReenrollment"))
                        if (typeof message.annualReenrollment !== "boolean")
                            return "annualReenrollment: boolean expected";
                    if (message.sitesOfCare != null && message.hasOwnProperty("sitesOfCare")) {
                        if (!Array.isArray(message.sitesOfCare))
                            return "sitesOfCare: array expected";
                        for (var i = 0; i < message.sitesOfCare.length; ++i) {
                            var error = $root.infinitusapi.INFBVTaskInputSiteOfCare.verify(message.sitesOfCare[i]);
                            if (error)
                                return "sitesOfCare." + error;
                        }
                    }
                    if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                        if (!$util.isString(message.additionalNotes))
                            return "additionalNotes: string expected";
                    if (message.cptInfos != null && message.hasOwnProperty("cptInfos")) {
                        if (!Array.isArray(message.cptInfos))
                            return "cptInfos: array expected";
                        for (var i = 0; i < message.cptInfos.length; ++i) {
                            var error = $root.infinitusai.be.CptInfo.verify(message.cptInfos[i]);
                            if (error)
                                return "cptInfos." + error;
                        }
                    }
                    if (message.policyHolderInfo != null && message.hasOwnProperty("policyHolderInfo")) {
                        var error = $root.infinitusai.be.PolicyHolderInfo.verify(message.policyHolderInfo);
                        if (error)
                            return "policyHolderInfo." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a BVCallInputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BVCallInputs} BVCallInputs
                 */
                BVCallInputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BVCallInputs)
                        return object;
                    var message = new $root.infinitusai.be.BVCallInputs();
                    if (object.callerInfo != null) {
                        if (typeof object.callerInfo !== "object")
                            throw TypeError(".infinitusai.be.BVCallInputs.callerInfo: object expected");
                        message.callerInfo = $root.infinitusai.be.CallerInfo.fromObject(object.callerInfo);
                    }
                    if (object.patientInfo != null) {
                        if (typeof object.patientInfo !== "object")
                            throw TypeError(".infinitusai.be.BVCallInputs.patientInfo: object expected");
                        message.patientInfo = $root.infinitusai.be.PatientInfo.fromObject(object.patientInfo);
                    }
                    if (object.productInfos) {
                        if (!Array.isArray(object.productInfos))
                            throw TypeError(".infinitusai.be.BVCallInputs.productInfos: array expected");
                        message.productInfos = [];
                        for (var i = 0; i < object.productInfos.length; ++i) {
                            if (typeof object.productInfos[i] !== "object")
                                throw TypeError(".infinitusai.be.BVCallInputs.productInfos: object expected");
                            message.productInfos[i] = $root.infinitusai.be.ProductInfo.fromObject(object.productInfos[i]);
                        }
                    }
                    if (object.adminCode != null)
                        message.adminCode = String(object.adminCode);
                    if (object.practiceInfo != null) {
                        if (typeof object.practiceInfo !== "object")
                            throw TypeError(".infinitusai.be.BVCallInputs.practiceInfo: object expected");
                        message.practiceInfo = $root.infinitusai.be.PracticeInfo.fromObject(object.practiceInfo);
                    }
                    if (object.providerInfo != null) {
                        if (typeof object.providerInfo !== "object")
                            throw TypeError(".infinitusai.be.BVCallInputs.providerInfo: object expected");
                        message.providerInfo = $root.infinitusai.be.ProviderInfo.fromObject(object.providerInfo);
                    }
                    if (object.policyInfo != null) {
                        if (typeof object.policyInfo !== "object")
                            throw TypeError(".infinitusai.be.BVCallInputs.policyInfo: object expected");
                        message.policyInfo = $root.infinitusai.be.PolicyInfo.fromObject(object.policyInfo);
                    }
                    if (object.payerInfo != null) {
                        if (typeof object.payerInfo !== "object")
                            throw TypeError(".infinitusai.be.BVCallInputs.payerInfo: object expected");
                        message.payerInfo = $root.infinitusai.be.PayerInfo.fromObject(object.payerInfo);
                    }
                    if (object.treatmentInfo != null) {
                        if (typeof object.treatmentInfo !== "object")
                            throw TypeError(".infinitusai.be.BVCallInputs.treatmentInfo: object expected");
                        message.treatmentInfo = $root.infinitusai.be.TreatmentInfo.fromObject(object.treatmentInfo);
                    }
                    if (object.adminCodes) {
                        if (!Array.isArray(object.adminCodes))
                            throw TypeError(".infinitusai.be.BVCallInputs.adminCodes: array expected");
                        message.adminCodes = [];
                        for (var i = 0; i < object.adminCodes.length; ++i)
                            message.adminCodes[i] = String(object.adminCodes[i]);
                    }
                    if (object.annualReenrollment != null)
                        message.annualReenrollment = Boolean(object.annualReenrollment);
                    if (object.sitesOfCare) {
                        if (!Array.isArray(object.sitesOfCare))
                            throw TypeError(".infinitusai.be.BVCallInputs.sitesOfCare: array expected");
                        message.sitesOfCare = [];
                        for (var i = 0; i < object.sitesOfCare.length; ++i) {
                            if (typeof object.sitesOfCare[i] !== "object")
                                throw TypeError(".infinitusai.be.BVCallInputs.sitesOfCare: object expected");
                            message.sitesOfCare[i] = $root.infinitusapi.INFBVTaskInputSiteOfCare.fromObject(object.sitesOfCare[i]);
                        }
                    }
                    if (object.additionalNotes != null)
                        message.additionalNotes = String(object.additionalNotes);
                    if (object.cptInfos) {
                        if (!Array.isArray(object.cptInfos))
                            throw TypeError(".infinitusai.be.BVCallInputs.cptInfos: array expected");
                        message.cptInfos = [];
                        for (var i = 0; i < object.cptInfos.length; ++i) {
                            if (typeof object.cptInfos[i] !== "object")
                                throw TypeError(".infinitusai.be.BVCallInputs.cptInfos: object expected");
                            message.cptInfos[i] = $root.infinitusai.be.CptInfo.fromObject(object.cptInfos[i]);
                        }
                    }
                    if (object.policyHolderInfo != null) {
                        if (typeof object.policyHolderInfo !== "object")
                            throw TypeError(".infinitusai.be.BVCallInputs.policyHolderInfo: object expected");
                        message.policyHolderInfo = $root.infinitusai.be.PolicyHolderInfo.fromObject(object.policyHolderInfo);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a BVCallInputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {infinitusai.be.BVCallInputs} message BVCallInputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BVCallInputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.productInfos = [];
                        object.adminCodes = [];
                        object.sitesOfCare = [];
                        object.cptInfos = [];
                    }
                    if (options.defaults) {
                        object.callerInfo = null;
                        object.patientInfo = null;
                        object.practiceInfo = null;
                        object.providerInfo = null;
                        object.policyInfo = null;
                        object.payerInfo = null;
                        object.treatmentInfo = null;
                        object.adminCode = "";
                        object.annualReenrollment = false;
                        object.additionalNotes = "";
                        object.policyHolderInfo = null;
                    }
                    if (message.callerInfo != null && message.hasOwnProperty("callerInfo"))
                        object.callerInfo = $root.infinitusai.be.CallerInfo.toObject(message.callerInfo, options);
                    if (message.patientInfo != null && message.hasOwnProperty("patientInfo"))
                        object.patientInfo = $root.infinitusai.be.PatientInfo.toObject(message.patientInfo, options);
                    if (message.practiceInfo != null && message.hasOwnProperty("practiceInfo"))
                        object.practiceInfo = $root.infinitusai.be.PracticeInfo.toObject(message.practiceInfo, options);
                    if (message.providerInfo != null && message.hasOwnProperty("providerInfo"))
                        object.providerInfo = $root.infinitusai.be.ProviderInfo.toObject(message.providerInfo, options);
                    if (message.policyInfo != null && message.hasOwnProperty("policyInfo"))
                        object.policyInfo = $root.infinitusai.be.PolicyInfo.toObject(message.policyInfo, options);
                    if (message.payerInfo != null && message.hasOwnProperty("payerInfo"))
                        object.payerInfo = $root.infinitusai.be.PayerInfo.toObject(message.payerInfo, options);
                    if (message.treatmentInfo != null && message.hasOwnProperty("treatmentInfo"))
                        object.treatmentInfo = $root.infinitusai.be.TreatmentInfo.toObject(message.treatmentInfo, options);
                    if (message.productInfos && message.productInfos.length) {
                        object.productInfos = [];
                        for (var j = 0; j < message.productInfos.length; ++j)
                            object.productInfos[j] = $root.infinitusai.be.ProductInfo.toObject(message.productInfos[j], options);
                    }
                    if (message.adminCode != null && message.hasOwnProperty("adminCode"))
                        object.adminCode = message.adminCode;
                    if (message.adminCodes && message.adminCodes.length) {
                        object.adminCodes = [];
                        for (var j = 0; j < message.adminCodes.length; ++j)
                            object.adminCodes[j] = message.adminCodes[j];
                    }
                    if (message.annualReenrollment != null && message.hasOwnProperty("annualReenrollment"))
                        object.annualReenrollment = message.annualReenrollment;
                    if (message.sitesOfCare && message.sitesOfCare.length) {
                        object.sitesOfCare = [];
                        for (var j = 0; j < message.sitesOfCare.length; ++j)
                            object.sitesOfCare[j] = $root.infinitusapi.INFBVTaskInputSiteOfCare.toObject(message.sitesOfCare[j], options);
                    }
                    if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                        object.additionalNotes = message.additionalNotes;
                    if (message.cptInfos && message.cptInfos.length) {
                        object.cptInfos = [];
                        for (var j = 0; j < message.cptInfos.length; ++j)
                            object.cptInfos[j] = $root.infinitusai.be.CptInfo.toObject(message.cptInfos[j], options);
                    }
                    if (message.policyHolderInfo != null && message.hasOwnProperty("policyHolderInfo"))
                        object.policyHolderInfo = $root.infinitusai.be.PolicyHolderInfo.toObject(message.policyHolderInfo, options);
                    return object;
                };
    
                /**
                 * Converts this BVCallInputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BVCallInputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BVCallInputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BVCallInputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BVCallInputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BVCallInputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BVCallInputs";
                };
    
                return BVCallInputs;
            })();
    
            be.PolicyHolderInfo = (function() {
    
                /**
                 * Properties of a PolicyHolderInfo.
                 * @memberof infinitusai.be
                 * @interface IPolicyHolderInfo
                 * @property {string|null} [firstName] PolicyHolderInfo firstName
                 * @property {string|null} [lastName] PolicyHolderInfo lastName
                 * @property {infinitusai.be.IBirthday|null} [dateOfBirth] PolicyHolderInfo dateOfBirth
                 */
    
                /**
                 * Constructs a new PolicyHolderInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PolicyHolderInfo.
                 * @implements IPolicyHolderInfo
                 * @constructor
                 * @param {infinitusai.be.IPolicyHolderInfo=} [properties] Properties to set
                 */
                function PolicyHolderInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PolicyHolderInfo firstName.
                 * @member {string} firstName
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @instance
                 */
                PolicyHolderInfo.prototype.firstName = "";
    
                /**
                 * PolicyHolderInfo lastName.
                 * @member {string} lastName
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @instance
                 */
                PolicyHolderInfo.prototype.lastName = "";
    
                /**
                 * PolicyHolderInfo dateOfBirth.
                 * @member {infinitusai.be.IBirthday|null|undefined} dateOfBirth
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @instance
                 */
                PolicyHolderInfo.prototype.dateOfBirth = null;
    
                /**
                 * Creates a new PolicyHolderInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {infinitusai.be.IPolicyHolderInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.PolicyHolderInfo} PolicyHolderInfo instance
                 */
                PolicyHolderInfo.create = function create(properties) {
                    return new PolicyHolderInfo(properties);
                };
    
                /**
                 * Encodes the specified PolicyHolderInfo message. Does not implicitly {@link infinitusai.be.PolicyHolderInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {infinitusai.be.IPolicyHolderInfo} message PolicyHolderInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PolicyHolderInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth"))
                        $root.infinitusai.be.Birthday.encode(message.dateOfBirth, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PolicyHolderInfo message, length delimited. Does not implicitly {@link infinitusai.be.PolicyHolderInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {infinitusai.be.IPolicyHolderInfo} message PolicyHolderInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PolicyHolderInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PolicyHolderInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PolicyHolderInfo} PolicyHolderInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PolicyHolderInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PolicyHolderInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 2: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 3: {
                                message.dateOfBirth = $root.infinitusai.be.Birthday.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PolicyHolderInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PolicyHolderInfo} PolicyHolderInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PolicyHolderInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PolicyHolderInfo message.
                 * @function verify
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PolicyHolderInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
                        var error = $root.infinitusai.be.Birthday.verify(message.dateOfBirth);
                        if (error)
                            return "dateOfBirth." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PolicyHolderInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PolicyHolderInfo} PolicyHolderInfo
                 */
                PolicyHolderInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PolicyHolderInfo)
                        return object;
                    var message = new $root.infinitusai.be.PolicyHolderInfo();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.dateOfBirth != null) {
                        if (typeof object.dateOfBirth !== "object")
                            throw TypeError(".infinitusai.be.PolicyHolderInfo.dateOfBirth: object expected");
                        message.dateOfBirth = $root.infinitusai.be.Birthday.fromObject(object.dateOfBirth);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PolicyHolderInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {infinitusai.be.PolicyHolderInfo} message PolicyHolderInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PolicyHolderInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                        object.dateOfBirth = null;
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                        object.dateOfBirth = $root.infinitusai.be.Birthday.toObject(message.dateOfBirth, options);
                    return object;
                };
    
                /**
                 * Converts this PolicyHolderInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PolicyHolderInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PolicyHolderInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PolicyHolderInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PolicyHolderInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PolicyHolderInfo";
                };
    
                return PolicyHolderInfo;
            })();
    
            be.BVCallInputsList = (function() {
    
                /**
                 * Properties of a BVCallInputsList.
                 * @memberof infinitusai.be
                 * @interface IBVCallInputsList
                 * @property {Array.<infinitusai.be.IBVCallInputs>|null} [bvCallInputs] BVCallInputsList bvCallInputs
                 */
    
                /**
                 * Constructs a new BVCallInputsList.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BVCallInputsList.
                 * @implements IBVCallInputsList
                 * @constructor
                 * @param {infinitusai.be.IBVCallInputsList=} [properties] Properties to set
                 */
                function BVCallInputsList(properties) {
                    this.bvCallInputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BVCallInputsList bvCallInputs.
                 * @member {Array.<infinitusai.be.IBVCallInputs>} bvCallInputs
                 * @memberof infinitusai.be.BVCallInputsList
                 * @instance
                 */
                BVCallInputsList.prototype.bvCallInputs = $util.emptyArray;
    
                /**
                 * Creates a new BVCallInputsList instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {infinitusai.be.IBVCallInputsList=} [properties] Properties to set
                 * @returns {infinitusai.be.BVCallInputsList} BVCallInputsList instance
                 */
                BVCallInputsList.create = function create(properties) {
                    return new BVCallInputsList(properties);
                };
    
                /**
                 * Encodes the specified BVCallInputsList message. Does not implicitly {@link infinitusai.be.BVCallInputsList.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {infinitusai.be.IBVCallInputsList} message BVCallInputsList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BVCallInputsList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bvCallInputs != null && message.bvCallInputs.length)
                        for (var i = 0; i < message.bvCallInputs.length; ++i)
                            $root.infinitusai.be.BVCallInputs.encode(message.bvCallInputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified BVCallInputsList message, length delimited. Does not implicitly {@link infinitusai.be.BVCallInputsList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {infinitusai.be.IBVCallInputsList} message BVCallInputsList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BVCallInputsList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BVCallInputsList message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BVCallInputsList} BVCallInputsList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BVCallInputsList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BVCallInputsList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.bvCallInputs && message.bvCallInputs.length))
                                    message.bvCallInputs = [];
                                message.bvCallInputs.push($root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BVCallInputsList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BVCallInputsList} BVCallInputsList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BVCallInputsList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BVCallInputsList message.
                 * @function verify
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BVCallInputsList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bvCallInputs != null && message.hasOwnProperty("bvCallInputs")) {
                        if (!Array.isArray(message.bvCallInputs))
                            return "bvCallInputs: array expected";
                        for (var i = 0; i < message.bvCallInputs.length; ++i) {
                            var error = $root.infinitusai.be.BVCallInputs.verify(message.bvCallInputs[i]);
                            if (error)
                                return "bvCallInputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a BVCallInputsList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BVCallInputsList} BVCallInputsList
                 */
                BVCallInputsList.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BVCallInputsList)
                        return object;
                    var message = new $root.infinitusai.be.BVCallInputsList();
                    if (object.bvCallInputs) {
                        if (!Array.isArray(object.bvCallInputs))
                            throw TypeError(".infinitusai.be.BVCallInputsList.bvCallInputs: array expected");
                        message.bvCallInputs = [];
                        for (var i = 0; i < object.bvCallInputs.length; ++i) {
                            if (typeof object.bvCallInputs[i] !== "object")
                                throw TypeError(".infinitusai.be.BVCallInputsList.bvCallInputs: object expected");
                            message.bvCallInputs[i] = $root.infinitusai.be.BVCallInputs.fromObject(object.bvCallInputs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a BVCallInputsList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {infinitusai.be.BVCallInputsList} message BVCallInputsList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BVCallInputsList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.bvCallInputs = [];
                    if (message.bvCallInputs && message.bvCallInputs.length) {
                        object.bvCallInputs = [];
                        for (var j = 0; j < message.bvCallInputs.length; ++j)
                            object.bvCallInputs[j] = $root.infinitusai.be.BVCallInputs.toObject(message.bvCallInputs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this BVCallInputsList to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BVCallInputsList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BVCallInputsList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BVCallInputsList
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BVCallInputsList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BVCallInputsList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BVCallInputsList";
                };
    
                return BVCallInputsList;
            })();
    
            be.SmallBVCallInputs = (function() {
    
                /**
                 * Properties of a SmallBVCallInputs.
                 * @memberof infinitusai.be
                 * @interface ISmallBVCallInputs
                 * @property {infinitusai.be.IPayerInfo|null} [payerInfo] SmallBVCallInputs payerInfo
                 * @property {Array.<infinitusai.be.IProductInfo>|null} [productInfos] SmallBVCallInputs productInfos
                 * @property {infinitusai.be.IProviderInfo|null} [providerInfo] SmallBVCallInputs providerInfo
                 * @property {infinitusai.be.IPatientInfo|null} [patientInfo] SmallBVCallInputs patientInfo
                 * @property {infinitusai.be.ITreatmentInfo|null} [treatmentInfo] SmallBVCallInputs treatmentInfo
                 * @property {infinitusai.be.IPolicyInfo|null} [policyInfo] SmallBVCallInputs policyInfo
                 */
    
                /**
                 * Constructs a new SmallBVCallInputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SmallBVCallInputs.
                 * @implements ISmallBVCallInputs
                 * @constructor
                 * @param {infinitusai.be.ISmallBVCallInputs=} [properties] Properties to set
                 */
                function SmallBVCallInputs(properties) {
                    this.productInfos = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SmallBVCallInputs payerInfo.
                 * @member {infinitusai.be.IPayerInfo|null|undefined} payerInfo
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @instance
                 */
                SmallBVCallInputs.prototype.payerInfo = null;
    
                /**
                 * SmallBVCallInputs productInfos.
                 * @member {Array.<infinitusai.be.IProductInfo>} productInfos
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @instance
                 */
                SmallBVCallInputs.prototype.productInfos = $util.emptyArray;
    
                /**
                 * SmallBVCallInputs providerInfo.
                 * @member {infinitusai.be.IProviderInfo|null|undefined} providerInfo
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @instance
                 */
                SmallBVCallInputs.prototype.providerInfo = null;
    
                /**
                 * SmallBVCallInputs patientInfo.
                 * @member {infinitusai.be.IPatientInfo|null|undefined} patientInfo
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @instance
                 */
                SmallBVCallInputs.prototype.patientInfo = null;
    
                /**
                 * SmallBVCallInputs treatmentInfo.
                 * @member {infinitusai.be.ITreatmentInfo|null|undefined} treatmentInfo
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @instance
                 */
                SmallBVCallInputs.prototype.treatmentInfo = null;
    
                /**
                 * SmallBVCallInputs policyInfo.
                 * @member {infinitusai.be.IPolicyInfo|null|undefined} policyInfo
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @instance
                 */
                SmallBVCallInputs.prototype.policyInfo = null;
    
                /**
                 * Creates a new SmallBVCallInputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {infinitusai.be.ISmallBVCallInputs=} [properties] Properties to set
                 * @returns {infinitusai.be.SmallBVCallInputs} SmallBVCallInputs instance
                 */
                SmallBVCallInputs.create = function create(properties) {
                    return new SmallBVCallInputs(properties);
                };
    
                /**
                 * Encodes the specified SmallBVCallInputs message. Does not implicitly {@link infinitusai.be.SmallBVCallInputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {infinitusai.be.ISmallBVCallInputs} message SmallBVCallInputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmallBVCallInputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payerInfo != null && Object.hasOwnProperty.call(message, "payerInfo"))
                        $root.infinitusai.be.PayerInfo.encode(message.payerInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.productInfos != null && message.productInfos.length)
                        for (var i = 0; i < message.productInfos.length; ++i)
                            $root.infinitusai.be.ProductInfo.encode(message.productInfos[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.providerInfo != null && Object.hasOwnProperty.call(message, "providerInfo"))
                        $root.infinitusai.be.ProviderInfo.encode(message.providerInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.patientInfo != null && Object.hasOwnProperty.call(message, "patientInfo"))
                        $root.infinitusai.be.PatientInfo.encode(message.patientInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.treatmentInfo != null && Object.hasOwnProperty.call(message, "treatmentInfo"))
                        $root.infinitusai.be.TreatmentInfo.encode(message.treatmentInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.policyInfo != null && Object.hasOwnProperty.call(message, "policyInfo"))
                        $root.infinitusai.be.PolicyInfo.encode(message.policyInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SmallBVCallInputs message, length delimited. Does not implicitly {@link infinitusai.be.SmallBVCallInputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {infinitusai.be.ISmallBVCallInputs} message SmallBVCallInputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmallBVCallInputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SmallBVCallInputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SmallBVCallInputs} SmallBVCallInputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmallBVCallInputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SmallBVCallInputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.payerInfo = $root.infinitusai.be.PayerInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.productInfos && message.productInfos.length))
                                    message.productInfos = [];
                                message.productInfos.push($root.infinitusai.be.ProductInfo.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.providerInfo = $root.infinitusai.be.ProviderInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.patientInfo = $root.infinitusai.be.PatientInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.treatmentInfo = $root.infinitusai.be.TreatmentInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.policyInfo = $root.infinitusai.be.PolicyInfo.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SmallBVCallInputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SmallBVCallInputs} SmallBVCallInputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmallBVCallInputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SmallBVCallInputs message.
                 * @function verify
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SmallBVCallInputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payerInfo != null && message.hasOwnProperty("payerInfo")) {
                        var error = $root.infinitusai.be.PayerInfo.verify(message.payerInfo);
                        if (error)
                            return "payerInfo." + error;
                    }
                    if (message.productInfos != null && message.hasOwnProperty("productInfos")) {
                        if (!Array.isArray(message.productInfos))
                            return "productInfos: array expected";
                        for (var i = 0; i < message.productInfos.length; ++i) {
                            var error = $root.infinitusai.be.ProductInfo.verify(message.productInfos[i]);
                            if (error)
                                return "productInfos." + error;
                        }
                    }
                    if (message.providerInfo != null && message.hasOwnProperty("providerInfo")) {
                        var error = $root.infinitusai.be.ProviderInfo.verify(message.providerInfo);
                        if (error)
                            return "providerInfo." + error;
                    }
                    if (message.patientInfo != null && message.hasOwnProperty("patientInfo")) {
                        var error = $root.infinitusai.be.PatientInfo.verify(message.patientInfo);
                        if (error)
                            return "patientInfo." + error;
                    }
                    if (message.treatmentInfo != null && message.hasOwnProperty("treatmentInfo")) {
                        var error = $root.infinitusai.be.TreatmentInfo.verify(message.treatmentInfo);
                        if (error)
                            return "treatmentInfo." + error;
                    }
                    if (message.policyInfo != null && message.hasOwnProperty("policyInfo")) {
                        var error = $root.infinitusai.be.PolicyInfo.verify(message.policyInfo);
                        if (error)
                            return "policyInfo." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a SmallBVCallInputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SmallBVCallInputs} SmallBVCallInputs
                 */
                SmallBVCallInputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SmallBVCallInputs)
                        return object;
                    var message = new $root.infinitusai.be.SmallBVCallInputs();
                    if (object.payerInfo != null) {
                        if (typeof object.payerInfo !== "object")
                            throw TypeError(".infinitusai.be.SmallBVCallInputs.payerInfo: object expected");
                        message.payerInfo = $root.infinitusai.be.PayerInfo.fromObject(object.payerInfo);
                    }
                    if (object.productInfos) {
                        if (!Array.isArray(object.productInfos))
                            throw TypeError(".infinitusai.be.SmallBVCallInputs.productInfos: array expected");
                        message.productInfos = [];
                        for (var i = 0; i < object.productInfos.length; ++i) {
                            if (typeof object.productInfos[i] !== "object")
                                throw TypeError(".infinitusai.be.SmallBVCallInputs.productInfos: object expected");
                            message.productInfos[i] = $root.infinitusai.be.ProductInfo.fromObject(object.productInfos[i]);
                        }
                    }
                    if (object.providerInfo != null) {
                        if (typeof object.providerInfo !== "object")
                            throw TypeError(".infinitusai.be.SmallBVCallInputs.providerInfo: object expected");
                        message.providerInfo = $root.infinitusai.be.ProviderInfo.fromObject(object.providerInfo);
                    }
                    if (object.patientInfo != null) {
                        if (typeof object.patientInfo !== "object")
                            throw TypeError(".infinitusai.be.SmallBVCallInputs.patientInfo: object expected");
                        message.patientInfo = $root.infinitusai.be.PatientInfo.fromObject(object.patientInfo);
                    }
                    if (object.treatmentInfo != null) {
                        if (typeof object.treatmentInfo !== "object")
                            throw TypeError(".infinitusai.be.SmallBVCallInputs.treatmentInfo: object expected");
                        message.treatmentInfo = $root.infinitusai.be.TreatmentInfo.fromObject(object.treatmentInfo);
                    }
                    if (object.policyInfo != null) {
                        if (typeof object.policyInfo !== "object")
                            throw TypeError(".infinitusai.be.SmallBVCallInputs.policyInfo: object expected");
                        message.policyInfo = $root.infinitusai.be.PolicyInfo.fromObject(object.policyInfo);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SmallBVCallInputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {infinitusai.be.SmallBVCallInputs} message SmallBVCallInputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SmallBVCallInputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.productInfos = [];
                    if (options.defaults) {
                        object.payerInfo = null;
                        object.providerInfo = null;
                        object.patientInfo = null;
                        object.treatmentInfo = null;
                        object.policyInfo = null;
                    }
                    if (message.payerInfo != null && message.hasOwnProperty("payerInfo"))
                        object.payerInfo = $root.infinitusai.be.PayerInfo.toObject(message.payerInfo, options);
                    if (message.productInfos && message.productInfos.length) {
                        object.productInfos = [];
                        for (var j = 0; j < message.productInfos.length; ++j)
                            object.productInfos[j] = $root.infinitusai.be.ProductInfo.toObject(message.productInfos[j], options);
                    }
                    if (message.providerInfo != null && message.hasOwnProperty("providerInfo"))
                        object.providerInfo = $root.infinitusai.be.ProviderInfo.toObject(message.providerInfo, options);
                    if (message.patientInfo != null && message.hasOwnProperty("patientInfo"))
                        object.patientInfo = $root.infinitusai.be.PatientInfo.toObject(message.patientInfo, options);
                    if (message.treatmentInfo != null && message.hasOwnProperty("treatmentInfo"))
                        object.treatmentInfo = $root.infinitusai.be.TreatmentInfo.toObject(message.treatmentInfo, options);
                    if (message.policyInfo != null && message.hasOwnProperty("policyInfo"))
                        object.policyInfo = $root.infinitusai.be.PolicyInfo.toObject(message.policyInfo, options);
                    return object;
                };
    
                /**
                 * Converts this SmallBVCallInputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SmallBVCallInputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SmallBVCallInputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SmallBVCallInputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SmallBVCallInputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SmallBVCallInputs";
                };
    
                return SmallBVCallInputs;
            })();
    
            be.PBMPayerInfo = (function() {
    
                /**
                 * Properties of a PBMPayerInfo.
                 * @memberof infinitusai.be
                 * @interface IPBMPayerInfo
                 * @property {string|null} [infinitusId] PBMPayerInfo infinitusId
                 * @property {string|null} [customerPayerName] PBMPayerInfo customerPayerName
                 * @property {string|null} [customerPayerPhoneNumber] PBMPayerInfo customerPayerPhoneNumber
                 * @property {string|null} [subscriberId] PBMPayerInfo subscriberId
                 * @property {string|null} [customerId] PBMPayerInfo customerId
                 */
    
                /**
                 * Constructs a new PBMPayerInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PBMPayerInfo.
                 * @implements IPBMPayerInfo
                 * @constructor
                 * @param {infinitusai.be.IPBMPayerInfo=} [properties] Properties to set
                 */
                function PBMPayerInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PBMPayerInfo infinitusId.
                 * @member {string} infinitusId
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @instance
                 */
                PBMPayerInfo.prototype.infinitusId = "";
    
                /**
                 * PBMPayerInfo customerPayerName.
                 * @member {string} customerPayerName
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @instance
                 */
                PBMPayerInfo.prototype.customerPayerName = "";
    
                /**
                 * PBMPayerInfo customerPayerPhoneNumber.
                 * @member {string} customerPayerPhoneNumber
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @instance
                 */
                PBMPayerInfo.prototype.customerPayerPhoneNumber = "";
    
                /**
                 * PBMPayerInfo subscriberId.
                 * @member {string} subscriberId
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @instance
                 */
                PBMPayerInfo.prototype.subscriberId = "";
    
                /**
                 * PBMPayerInfo customerId.
                 * @member {string} customerId
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @instance
                 */
                PBMPayerInfo.prototype.customerId = "";
    
                /**
                 * Creates a new PBMPayerInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {infinitusai.be.IPBMPayerInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.PBMPayerInfo} PBMPayerInfo instance
                 */
                PBMPayerInfo.create = function create(properties) {
                    return new PBMPayerInfo(properties);
                };
    
                /**
                 * Encodes the specified PBMPayerInfo message. Does not implicitly {@link infinitusai.be.PBMPayerInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {infinitusai.be.IPBMPayerInfo} message PBMPayerInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMPayerInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.infinitusId != null && Object.hasOwnProperty.call(message, "infinitusId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.infinitusId);
                    if (message.customerPayerName != null && Object.hasOwnProperty.call(message, "customerPayerName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerPayerName);
                    if (message.customerPayerPhoneNumber != null && Object.hasOwnProperty.call(message, "customerPayerPhoneNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.customerPayerPhoneNumber);
                    if (message.subscriberId != null && Object.hasOwnProperty.call(message, "subscriberId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.subscriberId);
                    if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.customerId);
                    return writer;
                };
    
                /**
                 * Encodes the specified PBMPayerInfo message, length delimited. Does not implicitly {@link infinitusai.be.PBMPayerInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {infinitusai.be.IPBMPayerInfo} message PBMPayerInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMPayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PBMPayerInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PBMPayerInfo} PBMPayerInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMPayerInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PBMPayerInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.infinitusId = reader.string();
                                break;
                            }
                        case 2: {
                                message.customerPayerName = reader.string();
                                break;
                            }
                        case 3: {
                                message.customerPayerPhoneNumber = reader.string();
                                break;
                            }
                        case 4: {
                                message.subscriberId = reader.string();
                                break;
                            }
                        case 5: {
                                message.customerId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PBMPayerInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PBMPayerInfo} PBMPayerInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMPayerInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PBMPayerInfo message.
                 * @function verify
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PBMPayerInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.infinitusId != null && message.hasOwnProperty("infinitusId"))
                        if (!$util.isString(message.infinitusId))
                            return "infinitusId: string expected";
                    if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                        if (!$util.isString(message.customerPayerName))
                            return "customerPayerName: string expected";
                    if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                        if (!$util.isString(message.customerPayerPhoneNumber))
                            return "customerPayerPhoneNumber: string expected";
                    if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                        if (!$util.isString(message.subscriberId))
                            return "subscriberId: string expected";
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        if (!$util.isString(message.customerId))
                            return "customerId: string expected";
                    return null;
                };
    
                /**
                 * Creates a PBMPayerInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PBMPayerInfo} PBMPayerInfo
                 */
                PBMPayerInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PBMPayerInfo)
                        return object;
                    var message = new $root.infinitusai.be.PBMPayerInfo();
                    if (object.infinitusId != null)
                        message.infinitusId = String(object.infinitusId);
                    if (object.customerPayerName != null)
                        message.customerPayerName = String(object.customerPayerName);
                    if (object.customerPayerPhoneNumber != null)
                        message.customerPayerPhoneNumber = String(object.customerPayerPhoneNumber);
                    if (object.subscriberId != null)
                        message.subscriberId = String(object.subscriberId);
                    if (object.customerId != null)
                        message.customerId = String(object.customerId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PBMPayerInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {infinitusai.be.PBMPayerInfo} message PBMPayerInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PBMPayerInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.infinitusId = "";
                        object.customerPayerName = "";
                        object.customerPayerPhoneNumber = "";
                        object.subscriberId = "";
                        object.customerId = "";
                    }
                    if (message.infinitusId != null && message.hasOwnProperty("infinitusId"))
                        object.infinitusId = message.infinitusId;
                    if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                        object.customerPayerName = message.customerPayerName;
                    if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                        object.customerPayerPhoneNumber = message.customerPayerPhoneNumber;
                    if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                        object.subscriberId = message.subscriberId;
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        object.customerId = message.customerId;
                    return object;
                };
    
                /**
                 * Converts this PBMPayerInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PBMPayerInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PBMPayerInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PBMPayerInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PBMPayerInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PBMPayerInfo";
                };
    
                return PBMPayerInfo;
            })();
    
            be.BVPayerInfo = (function() {
    
                /**
                 * Properties of a BVPayerInfo.
                 * @memberof infinitusai.be
                 * @interface IBVPayerInfo
                 * @property {string|null} [infinitusId] BVPayerInfo infinitusId
                 * @property {string|null} [customerPayerName] BVPayerInfo customerPayerName
                 * @property {string|null} [customerPayerPhoneNumber] BVPayerInfo customerPayerPhoneNumber
                 * @property {string|null} [subscriberId] BVPayerInfo subscriberId
                 */
    
                /**
                 * Constructs a new BVPayerInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BVPayerInfo.
                 * @implements IBVPayerInfo
                 * @constructor
                 * @param {infinitusai.be.IBVPayerInfo=} [properties] Properties to set
                 */
                function BVPayerInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BVPayerInfo infinitusId.
                 * @member {string} infinitusId
                 * @memberof infinitusai.be.BVPayerInfo
                 * @instance
                 */
                BVPayerInfo.prototype.infinitusId = "";
    
                /**
                 * BVPayerInfo customerPayerName.
                 * @member {string} customerPayerName
                 * @memberof infinitusai.be.BVPayerInfo
                 * @instance
                 */
                BVPayerInfo.prototype.customerPayerName = "";
    
                /**
                 * BVPayerInfo customerPayerPhoneNumber.
                 * @member {string} customerPayerPhoneNumber
                 * @memberof infinitusai.be.BVPayerInfo
                 * @instance
                 */
                BVPayerInfo.prototype.customerPayerPhoneNumber = "";
    
                /**
                 * BVPayerInfo subscriberId.
                 * @member {string} subscriberId
                 * @memberof infinitusai.be.BVPayerInfo
                 * @instance
                 */
                BVPayerInfo.prototype.subscriberId = "";
    
                /**
                 * Creates a new BVPayerInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {infinitusai.be.IBVPayerInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.BVPayerInfo} BVPayerInfo instance
                 */
                BVPayerInfo.create = function create(properties) {
                    return new BVPayerInfo(properties);
                };
    
                /**
                 * Encodes the specified BVPayerInfo message. Does not implicitly {@link infinitusai.be.BVPayerInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {infinitusai.be.IBVPayerInfo} message BVPayerInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BVPayerInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.infinitusId != null && Object.hasOwnProperty.call(message, "infinitusId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.infinitusId);
                    if (message.customerPayerName != null && Object.hasOwnProperty.call(message, "customerPayerName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerPayerName);
                    if (message.customerPayerPhoneNumber != null && Object.hasOwnProperty.call(message, "customerPayerPhoneNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.customerPayerPhoneNumber);
                    if (message.subscriberId != null && Object.hasOwnProperty.call(message, "subscriberId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.subscriberId);
                    return writer;
                };
    
                /**
                 * Encodes the specified BVPayerInfo message, length delimited. Does not implicitly {@link infinitusai.be.BVPayerInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {infinitusai.be.IBVPayerInfo} message BVPayerInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BVPayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BVPayerInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BVPayerInfo} BVPayerInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BVPayerInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BVPayerInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.infinitusId = reader.string();
                                break;
                            }
                        case 2: {
                                message.customerPayerName = reader.string();
                                break;
                            }
                        case 3: {
                                message.customerPayerPhoneNumber = reader.string();
                                break;
                            }
                        case 4: {
                                message.subscriberId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BVPayerInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BVPayerInfo} BVPayerInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BVPayerInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BVPayerInfo message.
                 * @function verify
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BVPayerInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.infinitusId != null && message.hasOwnProperty("infinitusId"))
                        if (!$util.isString(message.infinitusId))
                            return "infinitusId: string expected";
                    if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                        if (!$util.isString(message.customerPayerName))
                            return "customerPayerName: string expected";
                    if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                        if (!$util.isString(message.customerPayerPhoneNumber))
                            return "customerPayerPhoneNumber: string expected";
                    if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                        if (!$util.isString(message.subscriberId))
                            return "subscriberId: string expected";
                    return null;
                };
    
                /**
                 * Creates a BVPayerInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BVPayerInfo} BVPayerInfo
                 */
                BVPayerInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BVPayerInfo)
                        return object;
                    var message = new $root.infinitusai.be.BVPayerInfo();
                    if (object.infinitusId != null)
                        message.infinitusId = String(object.infinitusId);
                    if (object.customerPayerName != null)
                        message.customerPayerName = String(object.customerPayerName);
                    if (object.customerPayerPhoneNumber != null)
                        message.customerPayerPhoneNumber = String(object.customerPayerPhoneNumber);
                    if (object.subscriberId != null)
                        message.subscriberId = String(object.subscriberId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a BVPayerInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {infinitusai.be.BVPayerInfo} message BVPayerInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BVPayerInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.infinitusId = "";
                        object.customerPayerName = "";
                        object.customerPayerPhoneNumber = "";
                        object.subscriberId = "";
                    }
                    if (message.infinitusId != null && message.hasOwnProperty("infinitusId"))
                        object.infinitusId = message.infinitusId;
                    if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                        object.customerPayerName = message.customerPayerName;
                    if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                        object.customerPayerPhoneNumber = message.customerPayerPhoneNumber;
                    if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                        object.subscriberId = message.subscriberId;
                    return object;
                };
    
                /**
                 * Converts this BVPayerInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BVPayerInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BVPayerInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BVPayerInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BVPayerInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BVPayerInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BVPayerInfo";
                };
    
                return BVPayerInfo;
            })();
    
            /**
             * Association enum.
             * @name infinitusai.be.Association
             * @enum {number}
             * @property {number} ASSOCIATION_UNKNOWN=0 ASSOCIATION_UNKNOWN value
             * @property {number} ASSOCIATION_BCBS=1 ASSOCIATION_BCBS value
             * @property {number} ASSOCIATION_BCBS_FEDERAL=2 ASSOCIATION_BCBS_FEDERAL value
             */
            be.Association = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ASSOCIATION_UNKNOWN"] = 0;
                values[valuesById[1] = "ASSOCIATION_BCBS"] = 1;
                values[valuesById[2] = "ASSOCIATION_BCBS_FEDERAL"] = 2;
                return values;
            })();
    
            /**
             * NexmoCallState enum.
             * @name infinitusai.be.NexmoCallState
             * @enum {number}
             * @property {number} NEXMO_STATE_UNKNOWN=0 NEXMO_STATE_UNKNOWN value
             * @property {number} NEXMO_STATE_STARTED=1 NEXMO_STATE_STARTED value
             * @property {number} NEXMO_STATE_RINGING=2 NEXMO_STATE_RINGING value
             * @property {number} NEXMO_STATE_ANSWERED=3 NEXMO_STATE_ANSWERED value
             * @property {number} NEXMO_STATE_COMPLETED=4 NEXMO_STATE_COMPLETED value
             * @property {number} NEXMO_STATE_FAILED=5 NEXMO_STATE_FAILED value
             * @property {number} NEXMO_STATE_TIMEOUT=6 NEXMO_STATE_TIMEOUT value
             * @property {number} NEXMO_STATE_UNANSWERED=7 NEXMO_STATE_UNANSWERED value
             * @property {number} NEXMO_STATE_REJECTED=8 NEXMO_STATE_REJECTED value
             * @property {number} NEXMO_STATE_BUSY=9 NEXMO_STATE_BUSY value
             * @property {number} NEXMO_STATE_MACHINE=10 NEXMO_STATE_MACHINE value
             * @property {number} NEXMO_STATE_CANCELLED=11 NEXMO_STATE_CANCELLED value
             */
            be.NexmoCallState = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NEXMO_STATE_UNKNOWN"] = 0;
                values[valuesById[1] = "NEXMO_STATE_STARTED"] = 1;
                values[valuesById[2] = "NEXMO_STATE_RINGING"] = 2;
                values[valuesById[3] = "NEXMO_STATE_ANSWERED"] = 3;
                values[valuesById[4] = "NEXMO_STATE_COMPLETED"] = 4;
                values[valuesById[5] = "NEXMO_STATE_FAILED"] = 5;
                values[valuesById[6] = "NEXMO_STATE_TIMEOUT"] = 6;
                values[valuesById[7] = "NEXMO_STATE_UNANSWERED"] = 7;
                values[valuesById[8] = "NEXMO_STATE_REJECTED"] = 8;
                values[valuesById[9] = "NEXMO_STATE_BUSY"] = 9;
                values[valuesById[10] = "NEXMO_STATE_MACHINE"] = 10;
                values[valuesById[11] = "NEXMO_STATE_CANCELLED"] = 11;
                return values;
            })();
    
            /**
             * ProcessCallOutcome enum.
             * @name infinitusai.be.ProcessCallOutcome
             * @enum {number}
             * @property {number} PROCESS_CALL_OUTCOME_UNKNOWN=0 PROCESS_CALL_OUTCOME_UNKNOWN value
             * @property {number} PROCESS_CALL_OUTCOME_NOT_YET_PROCESSED=1 PROCESS_CALL_OUTCOME_NOT_YET_PROCESSED value
             * @property {number} PROCESS_CALL_OUTCOME_PROCESSING_INITIATED=2 PROCESS_CALL_OUTCOME_PROCESSING_INITIATED value
             * @property {number} PROCESS_CALL_OUTCOME_CALL_COMPLETED=3 PROCESS_CALL_OUTCOME_CALL_COMPLETED value
             * @property {number} PROCESS_CALL_OUTCOME_NEEDS_ANOTHER_CALL=4 PROCESS_CALL_OUTCOME_NEEDS_ANOTHER_CALL value
             */
            be.ProcessCallOutcome = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PROCESS_CALL_OUTCOME_UNKNOWN"] = 0;
                values[valuesById[1] = "PROCESS_CALL_OUTCOME_NOT_YET_PROCESSED"] = 1;
                values[valuesById[2] = "PROCESS_CALL_OUTCOME_PROCESSING_INITIATED"] = 2;
                values[valuesById[3] = "PROCESS_CALL_OUTCOME_CALL_COMPLETED"] = 3;
                values[valuesById[4] = "PROCESS_CALL_OUTCOME_NEEDS_ANOTHER_CALL"] = 4;
                return values;
            })();
    
            be.LoopError = (function() {
    
                /**
                 * Properties of a LoopError.
                 * @memberof infinitusai.be
                 * @interface ILoopError
                 * @property {infinitusai.be.LoopError.CallOutcomeType|null} [callOutcome] LoopError callOutcome
                 * @property {string|null} ["class"] LoopError class
                 * @property {string|null} [nested] LoopError nested
                 * @property {string|null} [loc] LoopError loc
                 * @property {string|null} [taskNotes] LoopError taskNotes
                 * @property {infinitusai.be.ITaskFailReason|null} [taskFailReason] LoopError taskFailReason
                 */
    
                /**
                 * Constructs a new LoopError.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LoopError.
                 * @implements ILoopError
                 * @constructor
                 * @param {infinitusai.be.ILoopError=} [properties] Properties to set
                 */
                function LoopError(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LoopError callOutcome.
                 * @member {infinitusai.be.LoopError.CallOutcomeType} callOutcome
                 * @memberof infinitusai.be.LoopError
                 * @instance
                 */
                LoopError.prototype.callOutcome = 0;
    
                /**
                 * LoopError class.
                 * @member {string} class
                 * @memberof infinitusai.be.LoopError
                 * @instance
                 */
                LoopError.prototype["class"] = "";
    
                /**
                 * LoopError nested.
                 * @member {string} nested
                 * @memberof infinitusai.be.LoopError
                 * @instance
                 */
                LoopError.prototype.nested = "";
    
                /**
                 * LoopError loc.
                 * @member {string} loc
                 * @memberof infinitusai.be.LoopError
                 * @instance
                 */
                LoopError.prototype.loc = "";
    
                /**
                 * LoopError taskNotes.
                 * @member {string} taskNotes
                 * @memberof infinitusai.be.LoopError
                 * @instance
                 */
                LoopError.prototype.taskNotes = "";
    
                /**
                 * LoopError taskFailReason.
                 * @member {infinitusai.be.ITaskFailReason|null|undefined} taskFailReason
                 * @memberof infinitusai.be.LoopError
                 * @instance
                 */
                LoopError.prototype.taskFailReason = null;
    
                /**
                 * Creates a new LoopError instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {infinitusai.be.ILoopError=} [properties] Properties to set
                 * @returns {infinitusai.be.LoopError} LoopError instance
                 */
                LoopError.create = function create(properties) {
                    return new LoopError(properties);
                };
    
                /**
                 * Encodes the specified LoopError message. Does not implicitly {@link infinitusai.be.LoopError.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {infinitusai.be.ILoopError} message LoopError message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoopError.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callOutcome != null && Object.hasOwnProperty.call(message, "callOutcome"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.callOutcome);
                    if (message["class"] != null && Object.hasOwnProperty.call(message, "class"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message["class"]);
                    if (message.nested != null && Object.hasOwnProperty.call(message, "nested"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.nested);
                    if (message.loc != null && Object.hasOwnProperty.call(message, "loc"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.loc);
                    if (message.taskNotes != null && Object.hasOwnProperty.call(message, "taskNotes"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.taskNotes);
                    if (message.taskFailReason != null && Object.hasOwnProperty.call(message, "taskFailReason"))
                        $root.infinitusai.be.TaskFailReason.encode(message.taskFailReason, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified LoopError message, length delimited. Does not implicitly {@link infinitusai.be.LoopError.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {infinitusai.be.ILoopError} message LoopError message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoopError.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LoopError message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LoopError} LoopError
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoopError.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LoopError();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callOutcome = reader.int32();
                                break;
                            }
                        case 2: {
                                message["class"] = reader.string();
                                break;
                            }
                        case 3: {
                                message.nested = reader.string();
                                break;
                            }
                        case 4: {
                                message.loc = reader.string();
                                break;
                            }
                        case 5: {
                                message.taskNotes = reader.string();
                                break;
                            }
                        case 6: {
                                message.taskFailReason = $root.infinitusai.be.TaskFailReason.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LoopError message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LoopError} LoopError
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoopError.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LoopError message.
                 * @function verify
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LoopError.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callOutcome != null && message.hasOwnProperty("callOutcome"))
                        switch (message.callOutcome) {
                        default:
                            return "callOutcome: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message["class"] != null && message.hasOwnProperty("class"))
                        if (!$util.isString(message["class"]))
                            return "class: string expected";
                    if (message.nested != null && message.hasOwnProperty("nested"))
                        if (!$util.isString(message.nested))
                            return "nested: string expected";
                    if (message.loc != null && message.hasOwnProperty("loc"))
                        if (!$util.isString(message.loc))
                            return "loc: string expected";
                    if (message.taskNotes != null && message.hasOwnProperty("taskNotes"))
                        if (!$util.isString(message.taskNotes))
                            return "taskNotes: string expected";
                    if (message.taskFailReason != null && message.hasOwnProperty("taskFailReason")) {
                        var error = $root.infinitusai.be.TaskFailReason.verify(message.taskFailReason);
                        if (error)
                            return "taskFailReason." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a LoopError message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LoopError} LoopError
                 */
                LoopError.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LoopError)
                        return object;
                    var message = new $root.infinitusai.be.LoopError();
                    switch (object.callOutcome) {
                    default:
                        if (typeof object.callOutcome === "number") {
                            message.callOutcome = object.callOutcome;
                            break;
                        }
                        break;
                    case "CALL_OUTCOME_TYPE_UNKNOWN":
                    case 0:
                        message.callOutcome = 0;
                        break;
                    case "CALL_OUTCOME_TYPE_LEAVE_DISCONNECTED":
                    case 1:
                        message.callOutcome = 1;
                        break;
                    case "CALL_OUTCOME_TYPE_REQUEUE_TASK":
                    case 2:
                        message.callOutcome = 2;
                        break;
                    case "CALL_OUTCOME_TYPE_MARK_TASK_READY_FOR_REVIEW":
                    case 3:
                        message.callOutcome = 3;
                        break;
                    case "CALL_OUTCOME_TYPE_SUCCESS":
                    case 4:
                        message.callOutcome = 4;
                        break;
                    }
                    if (object["class"] != null)
                        message["class"] = String(object["class"]);
                    if (object.nested != null)
                        message.nested = String(object.nested);
                    if (object.loc != null)
                        message.loc = String(object.loc);
                    if (object.taskNotes != null)
                        message.taskNotes = String(object.taskNotes);
                    if (object.taskFailReason != null) {
                        if (typeof object.taskFailReason !== "object")
                            throw TypeError(".infinitusai.be.LoopError.taskFailReason: object expected");
                        message.taskFailReason = $root.infinitusai.be.TaskFailReason.fromObject(object.taskFailReason);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a LoopError message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {infinitusai.be.LoopError} message LoopError
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LoopError.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callOutcome = options.enums === String ? "CALL_OUTCOME_TYPE_UNKNOWN" : 0;
                        object["class"] = "";
                        object.nested = "";
                        object.loc = "";
                        object.taskNotes = "";
                        object.taskFailReason = null;
                    }
                    if (message.callOutcome != null && message.hasOwnProperty("callOutcome"))
                        object.callOutcome = options.enums === String ? $root.infinitusai.be.LoopError.CallOutcomeType[message.callOutcome] === undefined ? message.callOutcome : $root.infinitusai.be.LoopError.CallOutcomeType[message.callOutcome] : message.callOutcome;
                    if (message["class"] != null && message.hasOwnProperty("class"))
                        object["class"] = message["class"];
                    if (message.nested != null && message.hasOwnProperty("nested"))
                        object.nested = message.nested;
                    if (message.loc != null && message.hasOwnProperty("loc"))
                        object.loc = message.loc;
                    if (message.taskNotes != null && message.hasOwnProperty("taskNotes"))
                        object.taskNotes = message.taskNotes;
                    if (message.taskFailReason != null && message.hasOwnProperty("taskFailReason"))
                        object.taskFailReason = $root.infinitusai.be.TaskFailReason.toObject(message.taskFailReason, options);
                    return object;
                };
    
                /**
                 * Converts this LoopError to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LoopError
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LoopError.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LoopError
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LoopError
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LoopError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LoopError";
                };
    
                /**
                 * CallOutcomeType enum.
                 * @name infinitusai.be.LoopError.CallOutcomeType
                 * @enum {number}
                 * @property {number} CALL_OUTCOME_TYPE_UNKNOWN=0 CALL_OUTCOME_TYPE_UNKNOWN value
                 * @property {number} CALL_OUTCOME_TYPE_LEAVE_DISCONNECTED=1 CALL_OUTCOME_TYPE_LEAVE_DISCONNECTED value
                 * @property {number} CALL_OUTCOME_TYPE_REQUEUE_TASK=2 CALL_OUTCOME_TYPE_REQUEUE_TASK value
                 * @property {number} CALL_OUTCOME_TYPE_MARK_TASK_READY_FOR_REVIEW=3 CALL_OUTCOME_TYPE_MARK_TASK_READY_FOR_REVIEW value
                 * @property {number} CALL_OUTCOME_TYPE_SUCCESS=4 CALL_OUTCOME_TYPE_SUCCESS value
                 */
                LoopError.CallOutcomeType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CALL_OUTCOME_TYPE_UNKNOWN"] = 0;
                    values[valuesById[1] = "CALL_OUTCOME_TYPE_LEAVE_DISCONNECTED"] = 1;
                    values[valuesById[2] = "CALL_OUTCOME_TYPE_REQUEUE_TASK"] = 2;
                    values[valuesById[3] = "CALL_OUTCOME_TYPE_MARK_TASK_READY_FOR_REVIEW"] = 3;
                    values[valuesById[4] = "CALL_OUTCOME_TYPE_SUCCESS"] = 4;
                    return values;
                })();
    
                return LoopError;
            })();
    
            be.RequeuedBy = (function() {
    
                /**
                 * Properties of a RequeuedBy.
                 * @memberof infinitusai.be
                 * @interface IRequeuedBy
                 * @property {string|null} [userEmail] RequeuedBy userEmail
                 * @property {infinitusai.be.OrgRole|null} [orgRole] RequeuedBy orgRole
                 */
    
                /**
                 * Constructs a new RequeuedBy.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RequeuedBy.
                 * @implements IRequeuedBy
                 * @constructor
                 * @param {infinitusai.be.IRequeuedBy=} [properties] Properties to set
                 */
                function RequeuedBy(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RequeuedBy userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.RequeuedBy
                 * @instance
                 */
                RequeuedBy.prototype.userEmail = "";
    
                /**
                 * RequeuedBy orgRole.
                 * @member {infinitusai.be.OrgRole} orgRole
                 * @memberof infinitusai.be.RequeuedBy
                 * @instance
                 */
                RequeuedBy.prototype.orgRole = 0;
    
                /**
                 * Creates a new RequeuedBy instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {infinitusai.be.IRequeuedBy=} [properties] Properties to set
                 * @returns {infinitusai.be.RequeuedBy} RequeuedBy instance
                 */
                RequeuedBy.create = function create(properties) {
                    return new RequeuedBy(properties);
                };
    
                /**
                 * Encodes the specified RequeuedBy message. Does not implicitly {@link infinitusai.be.RequeuedBy.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {infinitusai.be.IRequeuedBy} message RequeuedBy message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeuedBy.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userEmail);
                    if (message.orgRole != null && Object.hasOwnProperty.call(message, "orgRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orgRole);
                    return writer;
                };
    
                /**
                 * Encodes the specified RequeuedBy message, length delimited. Does not implicitly {@link infinitusai.be.RequeuedBy.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {infinitusai.be.IRequeuedBy} message RequeuedBy message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeuedBy.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequeuedBy message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RequeuedBy} RequeuedBy
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeuedBy.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RequeuedBy();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgRole = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequeuedBy message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RequeuedBy} RequeuedBy
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeuedBy.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequeuedBy message.
                 * @function verify
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequeuedBy.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        switch (message.orgRole) {
                        default:
                            return "orgRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a RequeuedBy message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RequeuedBy} RequeuedBy
                 */
                RequeuedBy.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RequeuedBy)
                        return object;
                    var message = new $root.infinitusai.be.RequeuedBy();
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    switch (object.orgRole) {
                    default:
                        if (typeof object.orgRole === "number") {
                            message.orgRole = object.orgRole;
                            break;
                        }
                        break;
                    case "ORG_ROLE_UNKNOWN":
                    case 0:
                        message.orgRole = 0;
                        break;
                    case "ORG_ROLE_OWNER":
                    case 1:
                        message.orgRole = 1;
                        break;
                    case "ORG_ROLE_MEMBER":
                    case 2:
                        message.orgRole = 2;
                        break;
                    case "ORG_ROLE_SUPERVISOR":
                    case 3:
                        message.orgRole = 3;
                        break;
                    case "ORG_ROLE_OPERATOR":
                    case 4:
                        message.orgRole = 4;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RequeuedBy message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {infinitusai.be.RequeuedBy} message RequeuedBy
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequeuedBy.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userEmail = "";
                        object.orgRole = options.enums === String ? "ORG_ROLE_UNKNOWN" : 0;
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        object.orgRole = options.enums === String ? $root.infinitusai.be.OrgRole[message.orgRole] === undefined ? message.orgRole : $root.infinitusai.be.OrgRole[message.orgRole] : message.orgRole;
                    return object;
                };
    
                /**
                 * Converts this RequeuedBy to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RequeuedBy
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequeuedBy.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequeuedBy
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RequeuedBy
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequeuedBy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RequeuedBy";
                };
    
                return RequeuedBy;
            })();
    
            be.CallMetadata = (function() {
    
                /**
                 * Properties of a CallMetadata.
                 * @memberof infinitusai.be
                 * @interface ICallMetadata
                 * @property {string|null} [agentName] CallMetadata agentName
                 * @property {string|null} [referenceNumber] CallMetadata referenceNumber
                 */
    
                /**
                 * Constructs a new CallMetadata.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallMetadata.
                 * @implements ICallMetadata
                 * @constructor
                 * @param {infinitusai.be.ICallMetadata=} [properties] Properties to set
                 */
                function CallMetadata(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallMetadata agentName.
                 * @member {string} agentName
                 * @memberof infinitusai.be.CallMetadata
                 * @instance
                 */
                CallMetadata.prototype.agentName = "";
    
                /**
                 * CallMetadata referenceNumber.
                 * @member {string} referenceNumber
                 * @memberof infinitusai.be.CallMetadata
                 * @instance
                 */
                CallMetadata.prototype.referenceNumber = "";
    
                /**
                 * Creates a new CallMetadata instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {infinitusai.be.ICallMetadata=} [properties] Properties to set
                 * @returns {infinitusai.be.CallMetadata} CallMetadata instance
                 */
                CallMetadata.create = function create(properties) {
                    return new CallMetadata(properties);
                };
    
                /**
                 * Encodes the specified CallMetadata message. Does not implicitly {@link infinitusai.be.CallMetadata.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {infinitusai.be.ICallMetadata} message CallMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallMetadata.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.agentName != null && Object.hasOwnProperty.call(message, "agentName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.agentName);
                    if (message.referenceNumber != null && Object.hasOwnProperty.call(message, "referenceNumber"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.referenceNumber);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallMetadata message, length delimited. Does not implicitly {@link infinitusai.be.CallMetadata.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {infinitusai.be.ICallMetadata} message CallMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallMetadata message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallMetadata} CallMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallMetadata.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallMetadata();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.agentName = reader.string();
                                break;
                            }
                        case 2: {
                                message.referenceNumber = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallMetadata message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallMetadata} CallMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallMetadata.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallMetadata message.
                 * @function verify
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallMetadata.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.agentName != null && message.hasOwnProperty("agentName"))
                        if (!$util.isString(message.agentName))
                            return "agentName: string expected";
                    if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                        if (!$util.isString(message.referenceNumber))
                            return "referenceNumber: string expected";
                    return null;
                };
    
                /**
                 * Creates a CallMetadata message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallMetadata} CallMetadata
                 */
                CallMetadata.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallMetadata)
                        return object;
                    var message = new $root.infinitusai.be.CallMetadata();
                    if (object.agentName != null)
                        message.agentName = String(object.agentName);
                    if (object.referenceNumber != null)
                        message.referenceNumber = String(object.referenceNumber);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallMetadata message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {infinitusai.be.CallMetadata} message CallMetadata
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallMetadata.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.agentName = "";
                        object.referenceNumber = "";
                    }
                    if (message.agentName != null && message.hasOwnProperty("agentName"))
                        object.agentName = message.agentName;
                    if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                        object.referenceNumber = message.referenceNumber;
                    return object;
                };
    
                /**
                 * Converts this CallMetadata to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallMetadata
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallMetadata.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallMetadata
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallMetadata
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallMetadata";
                };
    
                return CallMetadata;
            })();
    
            be.IvrFailure = (function() {
    
                /**
                 * Properties of an IvrFailure.
                 * @memberof infinitusai.be
                 * @interface IIvrFailure
                 * @property {infinitusai.be.IvrFailure.Reason|null} [failureReason] IvrFailure failureReason
                 * @property {string|null} [value] IvrFailure value
                 * @property {infinitusai.be.IvrFailure.ValueType|null} [valueType] IvrFailure valueType
                 */
    
                /**
                 * Constructs a new IvrFailure.
                 * @memberof infinitusai.be
                 * @classdesc Represents an IvrFailure.
                 * @implements IIvrFailure
                 * @constructor
                 * @param {infinitusai.be.IIvrFailure=} [properties] Properties to set
                 */
                function IvrFailure(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IvrFailure failureReason.
                 * @member {infinitusai.be.IvrFailure.Reason} failureReason
                 * @memberof infinitusai.be.IvrFailure
                 * @instance
                 */
                IvrFailure.prototype.failureReason = 0;
    
                /**
                 * IvrFailure value.
                 * @member {string} value
                 * @memberof infinitusai.be.IvrFailure
                 * @instance
                 */
                IvrFailure.prototype.value = "";
    
                /**
                 * IvrFailure valueType.
                 * @member {infinitusai.be.IvrFailure.ValueType} valueType
                 * @memberof infinitusai.be.IvrFailure
                 * @instance
                 */
                IvrFailure.prototype.valueType = 0;
    
                /**
                 * Creates a new IvrFailure instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {infinitusai.be.IIvrFailure=} [properties] Properties to set
                 * @returns {infinitusai.be.IvrFailure} IvrFailure instance
                 */
                IvrFailure.create = function create(properties) {
                    return new IvrFailure(properties);
                };
    
                /**
                 * Encodes the specified IvrFailure message. Does not implicitly {@link infinitusai.be.IvrFailure.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {infinitusai.be.IIvrFailure} message IvrFailure message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IvrFailure.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.failureReason);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                    if (message.valueType != null && Object.hasOwnProperty.call(message, "valueType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.valueType);
                    return writer;
                };
    
                /**
                 * Encodes the specified IvrFailure message, length delimited. Does not implicitly {@link infinitusai.be.IvrFailure.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {infinitusai.be.IIvrFailure} message IvrFailure message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IvrFailure.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IvrFailure message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IvrFailure} IvrFailure
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IvrFailure.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IvrFailure();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.failureReason = reader.int32();
                                break;
                            }
                        case 2: {
                                message.value = reader.string();
                                break;
                            }
                        case 3: {
                                message.valueType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IvrFailure message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IvrFailure} IvrFailure
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IvrFailure.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IvrFailure message.
                 * @function verify
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IvrFailure.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                        switch (message.failureReason) {
                        default:
                            return "failureReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.valueType != null && message.hasOwnProperty("valueType"))
                        switch (message.valueType) {
                        default:
                            return "valueType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an IvrFailure message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IvrFailure} IvrFailure
                 */
                IvrFailure.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IvrFailure)
                        return object;
                    var message = new $root.infinitusai.be.IvrFailure();
                    switch (object.failureReason) {
                    default:
                        if (typeof object.failureReason === "number") {
                            message.failureReason = object.failureReason;
                            break;
                        }
                        break;
                    case "IVR_FAILURE_REASON_UNKNOWN":
                    case 0:
                        message.failureReason = 0;
                        break;
                    case "IVR_FAILURE_REASON_PATIENT_DOB":
                    case 1:
                        message.failureReason = 1;
                        break;
                    case "IVR_FAILURE_REASON_PATIENT_ID":
                    case 2:
                        message.failureReason = 2;
                        break;
                    case "IVR_FAILURE_REASON_PROVIDER_NPI":
                    case 3:
                        message.failureReason = 3;
                        break;
                    case "IVR_FAILURE_REASON_PROVIDER_TAX_ID":
                    case 4:
                        message.failureReason = 4;
                        break;
                    case "IVR_FAILURE_REASON_IVR_CLOSED":
                    case 5:
                        message.failureReason = 5;
                        break;
                    case "IVR_FAILURE_REASON_PLAN_TERMINATED":
                    case 6:
                        message.failureReason = 6;
                        break;
                    }
                    if (object.value != null)
                        message.value = String(object.value);
                    switch (object.valueType) {
                    default:
                        if (typeof object.valueType === "number") {
                            message.valueType = object.valueType;
                            break;
                        }
                        break;
                    case "VALUE_TYPE_UNSPECIFIED":
                    case 0:
                        message.valueType = 0;
                        break;
                    case "VALUE_TYPE_STRING":
                    case 1:
                        message.valueType = 1;
                        break;
                    case "VALUE_TYPE_DATE":
                    case 2:
                        message.valueType = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an IvrFailure message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {infinitusai.be.IvrFailure} message IvrFailure
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IvrFailure.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.failureReason = options.enums === String ? "IVR_FAILURE_REASON_UNKNOWN" : 0;
                        object.value = "";
                        object.valueType = options.enums === String ? "VALUE_TYPE_UNSPECIFIED" : 0;
                    }
                    if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                        object.failureReason = options.enums === String ? $root.infinitusai.be.IvrFailure.Reason[message.failureReason] === undefined ? message.failureReason : $root.infinitusai.be.IvrFailure.Reason[message.failureReason] : message.failureReason;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.valueType != null && message.hasOwnProperty("valueType"))
                        object.valueType = options.enums === String ? $root.infinitusai.be.IvrFailure.ValueType[message.valueType] === undefined ? message.valueType : $root.infinitusai.be.IvrFailure.ValueType[message.valueType] : message.valueType;
                    return object;
                };
    
                /**
                 * Converts this IvrFailure to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IvrFailure
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IvrFailure.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IvrFailure
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IvrFailure
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IvrFailure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IvrFailure";
                };
    
                /**
                 * Reason enum.
                 * @name infinitusai.be.IvrFailure.Reason
                 * @enum {number}
                 * @property {number} IVR_FAILURE_REASON_UNKNOWN=0 IVR_FAILURE_REASON_UNKNOWN value
                 * @property {number} IVR_FAILURE_REASON_PATIENT_DOB=1 IVR_FAILURE_REASON_PATIENT_DOB value
                 * @property {number} IVR_FAILURE_REASON_PATIENT_ID=2 IVR_FAILURE_REASON_PATIENT_ID value
                 * @property {number} IVR_FAILURE_REASON_PROVIDER_NPI=3 IVR_FAILURE_REASON_PROVIDER_NPI value
                 * @property {number} IVR_FAILURE_REASON_PROVIDER_TAX_ID=4 IVR_FAILURE_REASON_PROVIDER_TAX_ID value
                 * @property {number} IVR_FAILURE_REASON_IVR_CLOSED=5 IVR_FAILURE_REASON_IVR_CLOSED value
                 * @property {number} IVR_FAILURE_REASON_PLAN_TERMINATED=6 IVR_FAILURE_REASON_PLAN_TERMINATED value
                 */
                IvrFailure.Reason = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "IVR_FAILURE_REASON_UNKNOWN"] = 0;
                    values[valuesById[1] = "IVR_FAILURE_REASON_PATIENT_DOB"] = 1;
                    values[valuesById[2] = "IVR_FAILURE_REASON_PATIENT_ID"] = 2;
                    values[valuesById[3] = "IVR_FAILURE_REASON_PROVIDER_NPI"] = 3;
                    values[valuesById[4] = "IVR_FAILURE_REASON_PROVIDER_TAX_ID"] = 4;
                    values[valuesById[5] = "IVR_FAILURE_REASON_IVR_CLOSED"] = 5;
                    values[valuesById[6] = "IVR_FAILURE_REASON_PLAN_TERMINATED"] = 6;
                    return values;
                })();
    
                /**
                 * ValueType enum.
                 * @name infinitusai.be.IvrFailure.ValueType
                 * @enum {number}
                 * @property {number} VALUE_TYPE_UNSPECIFIED=0 VALUE_TYPE_UNSPECIFIED value
                 * @property {number} VALUE_TYPE_STRING=1 VALUE_TYPE_STRING value
                 * @property {number} VALUE_TYPE_DATE=2 VALUE_TYPE_DATE value
                 */
                IvrFailure.ValueType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "VALUE_TYPE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "VALUE_TYPE_STRING"] = 1;
                    values[valuesById[2] = "VALUE_TYPE_DATE"] = 2;
                    return values;
                })();
    
                return IvrFailure;
            })();
    
            be.CallDoc = (function() {
    
                /**
                 * Properties of a CallDoc.
                 * @memberof infinitusai.be
                 * @interface ICallDoc
                 * @property {string|null} [callUuid] CallDoc callUuid
                 * @property {string|null} [taskUuid] CallDoc taskUuid
                 * @property {string|null} [orgUuid] CallDoc orgUuid
                 * @property {string|null} [nexmoConvUuid] CallDoc nexmoConvUuid
                 * @property {string|null} [nexmoOutboundLegUuid] CallDoc nexmoOutboundLegUuid
                 * @property {infinitusai.be.IUserInfo|null} [callCreator] CallDoc callCreator
                 * @property {infinitusai.be.QueueingAlgorithm|null} [queueingAlgorithm] CallDoc queueingAlgorithm
                 * @property {number|Long|null} [createTimeMillis] CallDoc createTimeMillis
                 * @property {number|Long|null} [audioStartMillis] CallDoc audioStartMillis
                 * @property {infinitusai.be.IBVCallInputs|null} [bvInputs] CallDoc bvInputs
                 * @property {boolean|null} [callStatusStarted] CallDoc callStatusStarted
                 * @property {infinitusai.be.NexmoCallState|null} [nexmoWsCallState] CallDoc nexmoWsCallState
                 * @property {infinitusai.be.NexmoCallState|null} [nexmoOutboundCallState] CallDoc nexmoOutboundCallState
                 * @property {boolean|null} [startHeadless] CallDoc startHeadless
                 * @property {number|Long|null} [audioEndMillis] CallDoc audioEndMillis
                 * @property {boolean|null} [recordingReady] CallDoc recordingReady
                 * @property {infinitusai.be.IUserInfo|null} [readyForReviewUser] CallDoc readyForReviewUser
                 * @property {infinitusai.be.IUserInfo|null} [processCallUser] CallDoc processCallUser
                 * @property {infinitusai.be.ProcessCallOutcome|null} [processCallOutcome] CallDoc processCallOutcome
                 * @property {number|Long|null} [readyForReviewMillis] CallDoc readyForReviewMillis
                 * @property {number|Long|null} [processCallMillis] CallDoc processCallMillis
                 * @property {string|null} [audioFilePath] CallDoc audioFilePath
                 * @property {boolean|null} [keepAutorespondInPersonAuthenticate] CallDoc keepAutorespondInPersonAuthenticate
                 * @property {boolean|null} [useRecordedGreeting] CallDoc useRecordedGreeting
                 * @property {infinitusai.be.IRequeueReason|null} [requeueReasonChosen] CallDoc requeueReasonChosen
                 * @property {string|null} [requeueNotes] CallDoc requeueNotes
                 * @property {infinitusai.be.IRequeuedBy|null} [requeuedBy] CallDoc requeuedBy
                 * @property {Array.<infinitusai.be.IPayerIntelligenceAction>|null} [payerIntelligenceActions] CallDoc payerIntelligenceActions
                 * @property {boolean|null} [isValuable] CallDoc isValuable
                 * @property {boolean|null} [callEscalated] CallDoc callEscalated
                 * @property {string|null} [nexmoApiUrl] CallDoc nexmoApiUrl
                 * @property {string|null} [phoneNumber] CallDoc phoneNumber
                 * @property {boolean|null} [multitaskEnabled] CallDoc multitaskEnabled
                 * @property {boolean|null} [operatorFeedbackGiven] CallDoc operatorFeedbackGiven
                 * @property {string|null} [queueId] CallDoc queueId
                 * @property {infinitusai.be.IIvrFailure|null} [ivrFailure] CallDoc ivrFailure
                 * @property {boolean|null} [usingElevenLabs] CallDoc usingElevenLabs
                 * @property {infinitusai.be.ITTSSetting|null} [ttsSetting] CallDoc ttsSetting
                 * @property {infinitusai.be.IHumanIntroConfig|null} [humanIntroConfig] CallDoc humanIntroConfig
                 * @property {boolean|null} [autorespondDidNavigateIvr] CallDoc autorespondDidNavigateIvr
                 * @property {infinitusai.be.ILoopError|null} [loopError] CallDoc loopError
                 * @property {boolean|null} [wasHungupByOperator] CallDoc wasHungupByOperator
                 * @property {infinitusai.be.ICallMetadata|null} [callMetadata] CallDoc callMetadata
                 * @property {boolean|null} [humanInvolved] CallDoc humanInvolved
                 * @property {infinitusai.be.IFastTrackProcessCallDetails|null} [fasttrackProcessCallDetails] CallDoc fasttrackProcessCallDetails
                 * @property {boolean|null} [isUserInitiated] CallDoc isUserInitiated
                 * @property {infinitusai.be.IUserInfo|null} [callAssignee] CallDoc callAssignee
                 * @property {infinitusai.be.IFastTrackCallDocAdditionalData|null} [fasttrackAdditionalData] CallDoc fasttrackAdditionalData
                 */
    
                /**
                 * Constructs a new CallDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallDoc.
                 * @implements ICallDoc
                 * @constructor
                 * @param {infinitusai.be.ICallDoc=} [properties] Properties to set
                 */
                function CallDoc(properties) {
                    this.payerIntelligenceActions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallDoc callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.callUuid = "";
    
                /**
                 * CallDoc taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.taskUuid = "";
    
                /**
                 * CallDoc orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.orgUuid = "";
    
                /**
                 * CallDoc nexmoConvUuid.
                 * @member {string} nexmoConvUuid
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.nexmoConvUuid = "";
    
                /**
                 * CallDoc nexmoOutboundLegUuid.
                 * @member {string} nexmoOutboundLegUuid
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.nexmoOutboundLegUuid = "";
    
                /**
                 * CallDoc callCreator.
                 * @member {infinitusai.be.IUserInfo|null|undefined} callCreator
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.callCreator = null;
    
                /**
                 * CallDoc queueingAlgorithm.
                 * @member {infinitusai.be.QueueingAlgorithm} queueingAlgorithm
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.queueingAlgorithm = 0;
    
                /**
                 * CallDoc createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallDoc audioStartMillis.
                 * @member {number|Long} audioStartMillis
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.audioStartMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallDoc bvInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} bvInputs
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.bvInputs = null;
    
                /**
                 * CallDoc callStatusStarted.
                 * @member {boolean} callStatusStarted
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.callStatusStarted = false;
    
                /**
                 * CallDoc nexmoWsCallState.
                 * @member {infinitusai.be.NexmoCallState} nexmoWsCallState
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.nexmoWsCallState = 0;
    
                /**
                 * CallDoc nexmoOutboundCallState.
                 * @member {infinitusai.be.NexmoCallState} nexmoOutboundCallState
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.nexmoOutboundCallState = 0;
    
                /**
                 * CallDoc startHeadless.
                 * @member {boolean} startHeadless
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.startHeadless = false;
    
                /**
                 * CallDoc audioEndMillis.
                 * @member {number|Long} audioEndMillis
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.audioEndMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallDoc recordingReady.
                 * @member {boolean} recordingReady
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.recordingReady = false;
    
                /**
                 * CallDoc readyForReviewUser.
                 * @member {infinitusai.be.IUserInfo|null|undefined} readyForReviewUser
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.readyForReviewUser = null;
    
                /**
                 * CallDoc processCallUser.
                 * @member {infinitusai.be.IUserInfo|null|undefined} processCallUser
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.processCallUser = null;
    
                /**
                 * CallDoc processCallOutcome.
                 * @member {infinitusai.be.ProcessCallOutcome} processCallOutcome
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.processCallOutcome = 0;
    
                /**
                 * CallDoc readyForReviewMillis.
                 * @member {number|Long} readyForReviewMillis
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.readyForReviewMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallDoc processCallMillis.
                 * @member {number|Long} processCallMillis
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.processCallMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallDoc audioFilePath.
                 * @member {string} audioFilePath
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.audioFilePath = "";
    
                /**
                 * CallDoc keepAutorespondInPersonAuthenticate.
                 * @member {boolean} keepAutorespondInPersonAuthenticate
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.keepAutorespondInPersonAuthenticate = false;
    
                /**
                 * CallDoc useRecordedGreeting.
                 * @member {boolean} useRecordedGreeting
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.useRecordedGreeting = false;
    
                /**
                 * CallDoc requeueReasonChosen.
                 * @member {infinitusai.be.IRequeueReason|null|undefined} requeueReasonChosen
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.requeueReasonChosen = null;
    
                /**
                 * CallDoc requeueNotes.
                 * @member {string} requeueNotes
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.requeueNotes = "";
    
                /**
                 * CallDoc requeuedBy.
                 * @member {infinitusai.be.IRequeuedBy|null|undefined} requeuedBy
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.requeuedBy = null;
    
                /**
                 * CallDoc payerIntelligenceActions.
                 * @member {Array.<infinitusai.be.IPayerIntelligenceAction>} payerIntelligenceActions
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.payerIntelligenceActions = $util.emptyArray;
    
                /**
                 * CallDoc isValuable.
                 * @member {boolean} isValuable
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.isValuable = false;
    
                /**
                 * CallDoc callEscalated.
                 * @member {boolean} callEscalated
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.callEscalated = false;
    
                /**
                 * CallDoc nexmoApiUrl.
                 * @member {string} nexmoApiUrl
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.nexmoApiUrl = "";
    
                /**
                 * CallDoc phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.phoneNumber = "";
    
                /**
                 * CallDoc multitaskEnabled.
                 * @member {boolean} multitaskEnabled
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.multitaskEnabled = false;
    
                /**
                 * CallDoc operatorFeedbackGiven.
                 * @member {boolean} operatorFeedbackGiven
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.operatorFeedbackGiven = false;
    
                /**
                 * CallDoc queueId.
                 * @member {string} queueId
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.queueId = "";
    
                /**
                 * CallDoc ivrFailure.
                 * @member {infinitusai.be.IIvrFailure|null|undefined} ivrFailure
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.ivrFailure = null;
    
                /**
                 * CallDoc usingElevenLabs.
                 * @member {boolean} usingElevenLabs
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.usingElevenLabs = false;
    
                /**
                 * CallDoc ttsSetting.
                 * @member {infinitusai.be.ITTSSetting|null|undefined} ttsSetting
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.ttsSetting = null;
    
                /**
                 * CallDoc humanIntroConfig.
                 * @member {infinitusai.be.IHumanIntroConfig|null|undefined} humanIntroConfig
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.humanIntroConfig = null;
    
                /**
                 * CallDoc autorespondDidNavigateIvr.
                 * @member {boolean} autorespondDidNavigateIvr
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.autorespondDidNavigateIvr = false;
    
                /**
                 * CallDoc loopError.
                 * @member {infinitusai.be.ILoopError|null|undefined} loopError
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.loopError = null;
    
                /**
                 * CallDoc wasHungupByOperator.
                 * @member {boolean} wasHungupByOperator
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.wasHungupByOperator = false;
    
                /**
                 * CallDoc callMetadata.
                 * @member {infinitusai.be.ICallMetadata|null|undefined} callMetadata
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.callMetadata = null;
    
                /**
                 * CallDoc humanInvolved.
                 * @member {boolean} humanInvolved
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.humanInvolved = false;
    
                /**
                 * CallDoc fasttrackProcessCallDetails.
                 * @member {infinitusai.be.IFastTrackProcessCallDetails|null|undefined} fasttrackProcessCallDetails
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.fasttrackProcessCallDetails = null;
    
                /**
                 * CallDoc isUserInitiated.
                 * @member {boolean} isUserInitiated
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.isUserInitiated = false;
    
                /**
                 * CallDoc callAssignee.
                 * @member {infinitusai.be.IUserInfo|null|undefined} callAssignee
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.callAssignee = null;
    
                /**
                 * CallDoc fasttrackAdditionalData.
                 * @member {infinitusai.be.IFastTrackCallDocAdditionalData|null|undefined} fasttrackAdditionalData
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                CallDoc.prototype.fasttrackAdditionalData = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * CallDoc inputs.
                 * @member {"bvInputs"|undefined} inputs
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 */
                Object.defineProperty(CallDoc.prototype, "inputs", {
                    get: $util.oneOfGetter($oneOfFields = ["bvInputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new CallDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {infinitusai.be.ICallDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.CallDoc} CallDoc instance
                 */
                CallDoc.create = function create(properties) {
                    return new CallDoc(properties);
                };
    
                /**
                 * Encodes the specified CallDoc message. Does not implicitly {@link infinitusai.be.CallDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {infinitusai.be.ICallDoc} message CallDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.nexmoConvUuid != null && Object.hasOwnProperty.call(message, "nexmoConvUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.nexmoConvUuid);
                    if (message.nexmoOutboundLegUuid != null && Object.hasOwnProperty.call(message, "nexmoOutboundLegUuid"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.nexmoOutboundLegUuid);
                    if (message.callCreator != null && Object.hasOwnProperty.call(message, "callCreator"))
                        $root.infinitusai.be.UserInfo.encode(message.callCreator, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createTimeMillis);
                    if (message.audioStartMillis != null && Object.hasOwnProperty.call(message, "audioStartMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.audioStartMillis);
                    if (message.bvInputs != null && Object.hasOwnProperty.call(message, "bvInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.bvInputs, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.callStatusStarted != null && Object.hasOwnProperty.call(message, "callStatusStarted"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.callStatusStarted);
                    if (message.nexmoWsCallState != null && Object.hasOwnProperty.call(message, "nexmoWsCallState"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.nexmoWsCallState);
                    if (message.nexmoOutboundCallState != null && Object.hasOwnProperty.call(message, "nexmoOutboundCallState"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.nexmoOutboundCallState);
                    if (message.startHeadless != null && Object.hasOwnProperty.call(message, "startHeadless"))
                        writer.uint32(/* id 13, wireType 0 =*/104).bool(message.startHeadless);
                    if (message.audioEndMillis != null && Object.hasOwnProperty.call(message, "audioEndMillis"))
                        writer.uint32(/* id 14, wireType 0 =*/112).int64(message.audioEndMillis);
                    if (message.recordingReady != null && Object.hasOwnProperty.call(message, "recordingReady"))
                        writer.uint32(/* id 15, wireType 0 =*/120).bool(message.recordingReady);
                    if (message.readyForReviewUser != null && Object.hasOwnProperty.call(message, "readyForReviewUser"))
                        $root.infinitusai.be.UserInfo.encode(message.readyForReviewUser, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.processCallMillis != null && Object.hasOwnProperty.call(message, "processCallMillis"))
                        writer.uint32(/* id 17, wireType 0 =*/136).int64(message.processCallMillis);
                    if (message.audioFilePath != null && Object.hasOwnProperty.call(message, "audioFilePath"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.audioFilePath);
                    if (message.keepAutorespondInPersonAuthenticate != null && Object.hasOwnProperty.call(message, "keepAutorespondInPersonAuthenticate"))
                        writer.uint32(/* id 19, wireType 0 =*/152).bool(message.keepAutorespondInPersonAuthenticate);
                    if (message.requeueReasonChosen != null && Object.hasOwnProperty.call(message, "requeueReasonChosen"))
                        $root.infinitusai.be.RequeueReason.encode(message.requeueReasonChosen, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                    if (message.requeueNotes != null && Object.hasOwnProperty.call(message, "requeueNotes"))
                        writer.uint32(/* id 21, wireType 2 =*/170).string(message.requeueNotes);
                    if (message.payerIntelligenceActions != null && message.payerIntelligenceActions.length)
                        for (var i = 0; i < message.payerIntelligenceActions.length; ++i)
                            $root.infinitusai.be.PayerIntelligenceAction.encode(message.payerIntelligenceActions[i], writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                    if (message.isValuable != null && Object.hasOwnProperty.call(message, "isValuable"))
                        writer.uint32(/* id 23, wireType 0 =*/184).bool(message.isValuable);
                    if (message.queueingAlgorithm != null && Object.hasOwnProperty.call(message, "queueingAlgorithm"))
                        writer.uint32(/* id 24, wireType 0 =*/192).int32(message.queueingAlgorithm);
                    if (message.useRecordedGreeting != null && Object.hasOwnProperty.call(message, "useRecordedGreeting"))
                        writer.uint32(/* id 25, wireType 0 =*/200).bool(message.useRecordedGreeting);
                    if (message.processCallUser != null && Object.hasOwnProperty.call(message, "processCallUser"))
                        $root.infinitusai.be.UserInfo.encode(message.processCallUser, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                    if (message.readyForReviewMillis != null && Object.hasOwnProperty.call(message, "readyForReviewMillis"))
                        writer.uint32(/* id 27, wireType 0 =*/216).int64(message.readyForReviewMillis);
                    if (message.callEscalated != null && Object.hasOwnProperty.call(message, "callEscalated"))
                        writer.uint32(/* id 28, wireType 0 =*/224).bool(message.callEscalated);
                    if (message.nexmoApiUrl != null && Object.hasOwnProperty.call(message, "nexmoApiUrl"))
                        writer.uint32(/* id 29, wireType 2 =*/234).string(message.nexmoApiUrl);
                    if (message.processCallOutcome != null && Object.hasOwnProperty.call(message, "processCallOutcome"))
                        writer.uint32(/* id 30, wireType 0 =*/240).int32(message.processCallOutcome);
                    if (message.requeuedBy != null && Object.hasOwnProperty.call(message, "requeuedBy"))
                        $root.infinitusai.be.RequeuedBy.encode(message.requeuedBy, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 32, wireType 2 =*/258).string(message.phoneNumber);
                    if (message.multitaskEnabled != null && Object.hasOwnProperty.call(message, "multitaskEnabled"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.multitaskEnabled);
                    if (message.operatorFeedbackGiven != null && Object.hasOwnProperty.call(message, "operatorFeedbackGiven"))
                        writer.uint32(/* id 35, wireType 0 =*/280).bool(message.operatorFeedbackGiven);
                    if (message.queueId != null && Object.hasOwnProperty.call(message, "queueId"))
                        writer.uint32(/* id 36, wireType 2 =*/290).string(message.queueId);
                    if (message.ivrFailure != null && Object.hasOwnProperty.call(message, "ivrFailure"))
                        $root.infinitusai.be.IvrFailure.encode(message.ivrFailure, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                    if (message.usingElevenLabs != null && Object.hasOwnProperty.call(message, "usingElevenLabs"))
                        writer.uint32(/* id 38, wireType 0 =*/304).bool(message.usingElevenLabs);
                    if (message.ttsSetting != null && Object.hasOwnProperty.call(message, "ttsSetting"))
                        $root.infinitusai.be.TTSSetting.encode(message.ttsSetting, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                    if (message.humanIntroConfig != null && Object.hasOwnProperty.call(message, "humanIntroConfig"))
                        $root.infinitusai.be.HumanIntroConfig.encode(message.humanIntroConfig, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                    if (message.autorespondDidNavigateIvr != null && Object.hasOwnProperty.call(message, "autorespondDidNavigateIvr"))
                        writer.uint32(/* id 41, wireType 0 =*/328).bool(message.autorespondDidNavigateIvr);
                    if (message.loopError != null && Object.hasOwnProperty.call(message, "loopError"))
                        $root.infinitusai.be.LoopError.encode(message.loopError, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                    if (message.wasHungupByOperator != null && Object.hasOwnProperty.call(message, "wasHungupByOperator"))
                        writer.uint32(/* id 43, wireType 0 =*/344).bool(message.wasHungupByOperator);
                    if (message.callMetadata != null && Object.hasOwnProperty.call(message, "callMetadata"))
                        $root.infinitusai.be.CallMetadata.encode(message.callMetadata, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                    if (message.humanInvolved != null && Object.hasOwnProperty.call(message, "humanInvolved"))
                        writer.uint32(/* id 45, wireType 0 =*/360).bool(message.humanInvolved);
                    if (message.fasttrackProcessCallDetails != null && Object.hasOwnProperty.call(message, "fasttrackProcessCallDetails"))
                        $root.infinitusai.be.FastTrackProcessCallDetails.encode(message.fasttrackProcessCallDetails, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                    if (message.isUserInitiated != null && Object.hasOwnProperty.call(message, "isUserInitiated"))
                        writer.uint32(/* id 47, wireType 0 =*/376).bool(message.isUserInitiated);
                    if (message.callAssignee != null && Object.hasOwnProperty.call(message, "callAssignee"))
                        $root.infinitusai.be.UserInfo.encode(message.callAssignee, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
                    if (message.fasttrackAdditionalData != null && Object.hasOwnProperty.call(message, "fasttrackAdditionalData"))
                        $root.infinitusai.be.FastTrackCallDocAdditionalData.encode(message.fasttrackAdditionalData, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallDoc message, length delimited. Does not implicitly {@link infinitusai.be.CallDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {infinitusai.be.ICallDoc} message CallDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallDoc} CallDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.nexmoConvUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.nexmoOutboundLegUuid = reader.string();
                                break;
                            }
                        case 6: {
                                message.callCreator = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 24: {
                                message.queueingAlgorithm = reader.int32();
                                break;
                            }
                        case 7: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 8: {
                                message.audioStartMillis = reader.int64();
                                break;
                            }
                        case 9: {
                                message.bvInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.callStatusStarted = reader.bool();
                                break;
                            }
                        case 11: {
                                message.nexmoWsCallState = reader.int32();
                                break;
                            }
                        case 12: {
                                message.nexmoOutboundCallState = reader.int32();
                                break;
                            }
                        case 13: {
                                message.startHeadless = reader.bool();
                                break;
                            }
                        case 14: {
                                message.audioEndMillis = reader.int64();
                                break;
                            }
                        case 15: {
                                message.recordingReady = reader.bool();
                                break;
                            }
                        case 16: {
                                message.readyForReviewUser = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 26: {
                                message.processCallUser = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 30: {
                                message.processCallOutcome = reader.int32();
                                break;
                            }
                        case 27: {
                                message.readyForReviewMillis = reader.int64();
                                break;
                            }
                        case 17: {
                                message.processCallMillis = reader.int64();
                                break;
                            }
                        case 18: {
                                message.audioFilePath = reader.string();
                                break;
                            }
                        case 19: {
                                message.keepAutorespondInPersonAuthenticate = reader.bool();
                                break;
                            }
                        case 25: {
                                message.useRecordedGreeting = reader.bool();
                                break;
                            }
                        case 20: {
                                message.requeueReasonChosen = $root.infinitusai.be.RequeueReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 21: {
                                message.requeueNotes = reader.string();
                                break;
                            }
                        case 31: {
                                message.requeuedBy = $root.infinitusai.be.RequeuedBy.decode(reader, reader.uint32());
                                break;
                            }
                        case 22: {
                                if (!(message.payerIntelligenceActions && message.payerIntelligenceActions.length))
                                    message.payerIntelligenceActions = [];
                                message.payerIntelligenceActions.push($root.infinitusai.be.PayerIntelligenceAction.decode(reader, reader.uint32()));
                                break;
                            }
                        case 23: {
                                message.isValuable = reader.bool();
                                break;
                            }
                        case 28: {
                                message.callEscalated = reader.bool();
                                break;
                            }
                        case 29: {
                                message.nexmoApiUrl = reader.string();
                                break;
                            }
                        case 32: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 33: {
                                message.multitaskEnabled = reader.bool();
                                break;
                            }
                        case 35: {
                                message.operatorFeedbackGiven = reader.bool();
                                break;
                            }
                        case 36: {
                                message.queueId = reader.string();
                                break;
                            }
                        case 37: {
                                message.ivrFailure = $root.infinitusai.be.IvrFailure.decode(reader, reader.uint32());
                                break;
                            }
                        case 38: {
                                message.usingElevenLabs = reader.bool();
                                break;
                            }
                        case 39: {
                                message.ttsSetting = $root.infinitusai.be.TTSSetting.decode(reader, reader.uint32());
                                break;
                            }
                        case 40: {
                                message.humanIntroConfig = $root.infinitusai.be.HumanIntroConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 41: {
                                message.autorespondDidNavigateIvr = reader.bool();
                                break;
                            }
                        case 42: {
                                message.loopError = $root.infinitusai.be.LoopError.decode(reader, reader.uint32());
                                break;
                            }
                        case 43: {
                                message.wasHungupByOperator = reader.bool();
                                break;
                            }
                        case 44: {
                                message.callMetadata = $root.infinitusai.be.CallMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        case 45: {
                                message.humanInvolved = reader.bool();
                                break;
                            }
                        case 46: {
                                message.fasttrackProcessCallDetails = $root.infinitusai.be.FastTrackProcessCallDetails.decode(reader, reader.uint32());
                                break;
                            }
                        case 47: {
                                message.isUserInitiated = reader.bool();
                                break;
                            }
                        case 48: {
                                message.callAssignee = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 49: {
                                message.fasttrackAdditionalData = $root.infinitusai.be.FastTrackCallDocAdditionalData.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallDoc} CallDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallDoc message.
                 * @function verify
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.nexmoConvUuid != null && message.hasOwnProperty("nexmoConvUuid"))
                        if (!$util.isString(message.nexmoConvUuid))
                            return "nexmoConvUuid: string expected";
                    if (message.nexmoOutboundLegUuid != null && message.hasOwnProperty("nexmoOutboundLegUuid"))
                        if (!$util.isString(message.nexmoOutboundLegUuid))
                            return "nexmoOutboundLegUuid: string expected";
                    if (message.callCreator != null && message.hasOwnProperty("callCreator")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.callCreator);
                        if (error)
                            return "callCreator." + error;
                    }
                    if (message.queueingAlgorithm != null && message.hasOwnProperty("queueingAlgorithm"))
                        switch (message.queueingAlgorithm) {
                        default:
                            return "queueingAlgorithm: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.audioStartMillis != null && message.hasOwnProperty("audioStartMillis"))
                        if (!$util.isInteger(message.audioStartMillis) && !(message.audioStartMillis && $util.isInteger(message.audioStartMillis.low) && $util.isInteger(message.audioStartMillis.high)))
                            return "audioStartMillis: integer|Long expected";
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        properties.inputs = 1;
                        {
                            var error = $root.infinitusai.be.BVCallInputs.verify(message.bvInputs);
                            if (error)
                                return "bvInputs." + error;
                        }
                    }
                    if (message.callStatusStarted != null && message.hasOwnProperty("callStatusStarted"))
                        if (typeof message.callStatusStarted !== "boolean")
                            return "callStatusStarted: boolean expected";
                    if (message.nexmoWsCallState != null && message.hasOwnProperty("nexmoWsCallState"))
                        switch (message.nexmoWsCallState) {
                        default:
                            return "nexmoWsCallState: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                            break;
                        }
                    if (message.nexmoOutboundCallState != null && message.hasOwnProperty("nexmoOutboundCallState"))
                        switch (message.nexmoOutboundCallState) {
                        default:
                            return "nexmoOutboundCallState: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                            break;
                        }
                    if (message.startHeadless != null && message.hasOwnProperty("startHeadless"))
                        if (typeof message.startHeadless !== "boolean")
                            return "startHeadless: boolean expected";
                    if (message.audioEndMillis != null && message.hasOwnProperty("audioEndMillis"))
                        if (!$util.isInteger(message.audioEndMillis) && !(message.audioEndMillis && $util.isInteger(message.audioEndMillis.low) && $util.isInteger(message.audioEndMillis.high)))
                            return "audioEndMillis: integer|Long expected";
                    if (message.recordingReady != null && message.hasOwnProperty("recordingReady"))
                        if (typeof message.recordingReady !== "boolean")
                            return "recordingReady: boolean expected";
                    if (message.readyForReviewUser != null && message.hasOwnProperty("readyForReviewUser")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.readyForReviewUser);
                        if (error)
                            return "readyForReviewUser." + error;
                    }
                    if (message.processCallUser != null && message.hasOwnProperty("processCallUser")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.processCallUser);
                        if (error)
                            return "processCallUser." + error;
                    }
                    if (message.processCallOutcome != null && message.hasOwnProperty("processCallOutcome"))
                        switch (message.processCallOutcome) {
                        default:
                            return "processCallOutcome: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.readyForReviewMillis != null && message.hasOwnProperty("readyForReviewMillis"))
                        if (!$util.isInteger(message.readyForReviewMillis) && !(message.readyForReviewMillis && $util.isInteger(message.readyForReviewMillis.low) && $util.isInteger(message.readyForReviewMillis.high)))
                            return "readyForReviewMillis: integer|Long expected";
                    if (message.processCallMillis != null && message.hasOwnProperty("processCallMillis"))
                        if (!$util.isInteger(message.processCallMillis) && !(message.processCallMillis && $util.isInteger(message.processCallMillis.low) && $util.isInteger(message.processCallMillis.high)))
                            return "processCallMillis: integer|Long expected";
                    if (message.audioFilePath != null && message.hasOwnProperty("audioFilePath"))
                        if (!$util.isString(message.audioFilePath))
                            return "audioFilePath: string expected";
                    if (message.keepAutorespondInPersonAuthenticate != null && message.hasOwnProperty("keepAutorespondInPersonAuthenticate"))
                        if (typeof message.keepAutorespondInPersonAuthenticate !== "boolean")
                            return "keepAutorespondInPersonAuthenticate: boolean expected";
                    if (message.useRecordedGreeting != null && message.hasOwnProperty("useRecordedGreeting"))
                        if (typeof message.useRecordedGreeting !== "boolean")
                            return "useRecordedGreeting: boolean expected";
                    if (message.requeueReasonChosen != null && message.hasOwnProperty("requeueReasonChosen")) {
                        var error = $root.infinitusai.be.RequeueReason.verify(message.requeueReasonChosen);
                        if (error)
                            return "requeueReasonChosen." + error;
                    }
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        if (!$util.isString(message.requeueNotes))
                            return "requeueNotes: string expected";
                    if (message.requeuedBy != null && message.hasOwnProperty("requeuedBy")) {
                        var error = $root.infinitusai.be.RequeuedBy.verify(message.requeuedBy);
                        if (error)
                            return "requeuedBy." + error;
                    }
                    if (message.payerIntelligenceActions != null && message.hasOwnProperty("payerIntelligenceActions")) {
                        if (!Array.isArray(message.payerIntelligenceActions))
                            return "payerIntelligenceActions: array expected";
                        for (var i = 0; i < message.payerIntelligenceActions.length; ++i) {
                            var error = $root.infinitusai.be.PayerIntelligenceAction.verify(message.payerIntelligenceActions[i]);
                            if (error)
                                return "payerIntelligenceActions." + error;
                        }
                    }
                    if (message.isValuable != null && message.hasOwnProperty("isValuable"))
                        if (typeof message.isValuable !== "boolean")
                            return "isValuable: boolean expected";
                    if (message.callEscalated != null && message.hasOwnProperty("callEscalated"))
                        if (typeof message.callEscalated !== "boolean")
                            return "callEscalated: boolean expected";
                    if (message.nexmoApiUrl != null && message.hasOwnProperty("nexmoApiUrl"))
                        if (!$util.isString(message.nexmoApiUrl))
                            return "nexmoApiUrl: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.multitaskEnabled != null && message.hasOwnProperty("multitaskEnabled"))
                        if (typeof message.multitaskEnabled !== "boolean")
                            return "multitaskEnabled: boolean expected";
                    if (message.operatorFeedbackGiven != null && message.hasOwnProperty("operatorFeedbackGiven"))
                        if (typeof message.operatorFeedbackGiven !== "boolean")
                            return "operatorFeedbackGiven: boolean expected";
                    if (message.queueId != null && message.hasOwnProperty("queueId"))
                        if (!$util.isString(message.queueId))
                            return "queueId: string expected";
                    if (message.ivrFailure != null && message.hasOwnProperty("ivrFailure")) {
                        var error = $root.infinitusai.be.IvrFailure.verify(message.ivrFailure);
                        if (error)
                            return "ivrFailure." + error;
                    }
                    if (message.usingElevenLabs != null && message.hasOwnProperty("usingElevenLabs"))
                        if (typeof message.usingElevenLabs !== "boolean")
                            return "usingElevenLabs: boolean expected";
                    if (message.ttsSetting != null && message.hasOwnProperty("ttsSetting")) {
                        var error = $root.infinitusai.be.TTSSetting.verify(message.ttsSetting);
                        if (error)
                            return "ttsSetting." + error;
                    }
                    if (message.humanIntroConfig != null && message.hasOwnProperty("humanIntroConfig")) {
                        var error = $root.infinitusai.be.HumanIntroConfig.verify(message.humanIntroConfig);
                        if (error)
                            return "humanIntroConfig." + error;
                    }
                    if (message.autorespondDidNavigateIvr != null && message.hasOwnProperty("autorespondDidNavigateIvr"))
                        if (typeof message.autorespondDidNavigateIvr !== "boolean")
                            return "autorespondDidNavigateIvr: boolean expected";
                    if (message.loopError != null && message.hasOwnProperty("loopError")) {
                        var error = $root.infinitusai.be.LoopError.verify(message.loopError);
                        if (error)
                            return "loopError." + error;
                    }
                    if (message.wasHungupByOperator != null && message.hasOwnProperty("wasHungupByOperator"))
                        if (typeof message.wasHungupByOperator !== "boolean")
                            return "wasHungupByOperator: boolean expected";
                    if (message.callMetadata != null && message.hasOwnProperty("callMetadata")) {
                        var error = $root.infinitusai.be.CallMetadata.verify(message.callMetadata);
                        if (error)
                            return "callMetadata." + error;
                    }
                    if (message.humanInvolved != null && message.hasOwnProperty("humanInvolved"))
                        if (typeof message.humanInvolved !== "boolean")
                            return "humanInvolved: boolean expected";
                    if (message.fasttrackProcessCallDetails != null && message.hasOwnProperty("fasttrackProcessCallDetails")) {
                        var error = $root.infinitusai.be.FastTrackProcessCallDetails.verify(message.fasttrackProcessCallDetails);
                        if (error)
                            return "fasttrackProcessCallDetails." + error;
                    }
                    if (message.isUserInitiated != null && message.hasOwnProperty("isUserInitiated"))
                        if (typeof message.isUserInitiated !== "boolean")
                            return "isUserInitiated: boolean expected";
                    if (message.callAssignee != null && message.hasOwnProperty("callAssignee")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.callAssignee);
                        if (error)
                            return "callAssignee." + error;
                    }
                    if (message.fasttrackAdditionalData != null && message.hasOwnProperty("fasttrackAdditionalData")) {
                        var error = $root.infinitusai.be.FastTrackCallDocAdditionalData.verify(message.fasttrackAdditionalData);
                        if (error)
                            return "fasttrackAdditionalData." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CallDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallDoc} CallDoc
                 */
                CallDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallDoc)
                        return object;
                    var message = new $root.infinitusai.be.CallDoc();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.nexmoConvUuid != null)
                        message.nexmoConvUuid = String(object.nexmoConvUuid);
                    if (object.nexmoOutboundLegUuid != null)
                        message.nexmoOutboundLegUuid = String(object.nexmoOutboundLegUuid);
                    if (object.callCreator != null) {
                        if (typeof object.callCreator !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.callCreator: object expected");
                        message.callCreator = $root.infinitusai.be.UserInfo.fromObject(object.callCreator);
                    }
                    switch (object.queueingAlgorithm) {
                    default:
                        if (typeof object.queueingAlgorithm === "number") {
                            message.queueingAlgorithm = object.queueingAlgorithm;
                            break;
                        }
                        break;
                    case "QUEUEING_MANUAL":
                    case 0:
                        message.queueingAlgorithm = 0;
                        break;
                    case "QUEUEING_DEFAULT":
                    case 1:
                        message.queueingAlgorithm = 1;
                        break;
                    case "QUEUEING_SHAQ":
                    case 2:
                        message.queueingAlgorithm = 2;
                        break;
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.audioStartMillis != null)
                        if ($util.Long)
                            (message.audioStartMillis = $util.Long.fromValue(object.audioStartMillis)).unsigned = false;
                        else if (typeof object.audioStartMillis === "string")
                            message.audioStartMillis = parseInt(object.audioStartMillis, 10);
                        else if (typeof object.audioStartMillis === "number")
                            message.audioStartMillis = object.audioStartMillis;
                        else if (typeof object.audioStartMillis === "object")
                            message.audioStartMillis = new $util.LongBits(object.audioStartMillis.low >>> 0, object.audioStartMillis.high >>> 0).toNumber();
                    if (object.bvInputs != null) {
                        if (typeof object.bvInputs !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.bvInputs: object expected");
                        message.bvInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.bvInputs);
                    }
                    if (object.callStatusStarted != null)
                        message.callStatusStarted = Boolean(object.callStatusStarted);
                    switch (object.nexmoWsCallState) {
                    default:
                        if (typeof object.nexmoWsCallState === "number") {
                            message.nexmoWsCallState = object.nexmoWsCallState;
                            break;
                        }
                        break;
                    case "NEXMO_STATE_UNKNOWN":
                    case 0:
                        message.nexmoWsCallState = 0;
                        break;
                    case "NEXMO_STATE_STARTED":
                    case 1:
                        message.nexmoWsCallState = 1;
                        break;
                    case "NEXMO_STATE_RINGING":
                    case 2:
                        message.nexmoWsCallState = 2;
                        break;
                    case "NEXMO_STATE_ANSWERED":
                    case 3:
                        message.nexmoWsCallState = 3;
                        break;
                    case "NEXMO_STATE_COMPLETED":
                    case 4:
                        message.nexmoWsCallState = 4;
                        break;
                    case "NEXMO_STATE_FAILED":
                    case 5:
                        message.nexmoWsCallState = 5;
                        break;
                    case "NEXMO_STATE_TIMEOUT":
                    case 6:
                        message.nexmoWsCallState = 6;
                        break;
                    case "NEXMO_STATE_UNANSWERED":
                    case 7:
                        message.nexmoWsCallState = 7;
                        break;
                    case "NEXMO_STATE_REJECTED":
                    case 8:
                        message.nexmoWsCallState = 8;
                        break;
                    case "NEXMO_STATE_BUSY":
                    case 9:
                        message.nexmoWsCallState = 9;
                        break;
                    case "NEXMO_STATE_MACHINE":
                    case 10:
                        message.nexmoWsCallState = 10;
                        break;
                    case "NEXMO_STATE_CANCELLED":
                    case 11:
                        message.nexmoWsCallState = 11;
                        break;
                    }
                    switch (object.nexmoOutboundCallState) {
                    default:
                        if (typeof object.nexmoOutboundCallState === "number") {
                            message.nexmoOutboundCallState = object.nexmoOutboundCallState;
                            break;
                        }
                        break;
                    case "NEXMO_STATE_UNKNOWN":
                    case 0:
                        message.nexmoOutboundCallState = 0;
                        break;
                    case "NEXMO_STATE_STARTED":
                    case 1:
                        message.nexmoOutboundCallState = 1;
                        break;
                    case "NEXMO_STATE_RINGING":
                    case 2:
                        message.nexmoOutboundCallState = 2;
                        break;
                    case "NEXMO_STATE_ANSWERED":
                    case 3:
                        message.nexmoOutboundCallState = 3;
                        break;
                    case "NEXMO_STATE_COMPLETED":
                    case 4:
                        message.nexmoOutboundCallState = 4;
                        break;
                    case "NEXMO_STATE_FAILED":
                    case 5:
                        message.nexmoOutboundCallState = 5;
                        break;
                    case "NEXMO_STATE_TIMEOUT":
                    case 6:
                        message.nexmoOutboundCallState = 6;
                        break;
                    case "NEXMO_STATE_UNANSWERED":
                    case 7:
                        message.nexmoOutboundCallState = 7;
                        break;
                    case "NEXMO_STATE_REJECTED":
                    case 8:
                        message.nexmoOutboundCallState = 8;
                        break;
                    case "NEXMO_STATE_BUSY":
                    case 9:
                        message.nexmoOutboundCallState = 9;
                        break;
                    case "NEXMO_STATE_MACHINE":
                    case 10:
                        message.nexmoOutboundCallState = 10;
                        break;
                    case "NEXMO_STATE_CANCELLED":
                    case 11:
                        message.nexmoOutboundCallState = 11;
                        break;
                    }
                    if (object.startHeadless != null)
                        message.startHeadless = Boolean(object.startHeadless);
                    if (object.audioEndMillis != null)
                        if ($util.Long)
                            (message.audioEndMillis = $util.Long.fromValue(object.audioEndMillis)).unsigned = false;
                        else if (typeof object.audioEndMillis === "string")
                            message.audioEndMillis = parseInt(object.audioEndMillis, 10);
                        else if (typeof object.audioEndMillis === "number")
                            message.audioEndMillis = object.audioEndMillis;
                        else if (typeof object.audioEndMillis === "object")
                            message.audioEndMillis = new $util.LongBits(object.audioEndMillis.low >>> 0, object.audioEndMillis.high >>> 0).toNumber();
                    if (object.recordingReady != null)
                        message.recordingReady = Boolean(object.recordingReady);
                    if (object.readyForReviewUser != null) {
                        if (typeof object.readyForReviewUser !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.readyForReviewUser: object expected");
                        message.readyForReviewUser = $root.infinitusai.be.UserInfo.fromObject(object.readyForReviewUser);
                    }
                    if (object.processCallUser != null) {
                        if (typeof object.processCallUser !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.processCallUser: object expected");
                        message.processCallUser = $root.infinitusai.be.UserInfo.fromObject(object.processCallUser);
                    }
                    switch (object.processCallOutcome) {
                    default:
                        if (typeof object.processCallOutcome === "number") {
                            message.processCallOutcome = object.processCallOutcome;
                            break;
                        }
                        break;
                    case "PROCESS_CALL_OUTCOME_UNKNOWN":
                    case 0:
                        message.processCallOutcome = 0;
                        break;
                    case "PROCESS_CALL_OUTCOME_NOT_YET_PROCESSED":
                    case 1:
                        message.processCallOutcome = 1;
                        break;
                    case "PROCESS_CALL_OUTCOME_PROCESSING_INITIATED":
                    case 2:
                        message.processCallOutcome = 2;
                        break;
                    case "PROCESS_CALL_OUTCOME_CALL_COMPLETED":
                    case 3:
                        message.processCallOutcome = 3;
                        break;
                    case "PROCESS_CALL_OUTCOME_NEEDS_ANOTHER_CALL":
                    case 4:
                        message.processCallOutcome = 4;
                        break;
                    }
                    if (object.readyForReviewMillis != null)
                        if ($util.Long)
                            (message.readyForReviewMillis = $util.Long.fromValue(object.readyForReviewMillis)).unsigned = false;
                        else if (typeof object.readyForReviewMillis === "string")
                            message.readyForReviewMillis = parseInt(object.readyForReviewMillis, 10);
                        else if (typeof object.readyForReviewMillis === "number")
                            message.readyForReviewMillis = object.readyForReviewMillis;
                        else if (typeof object.readyForReviewMillis === "object")
                            message.readyForReviewMillis = new $util.LongBits(object.readyForReviewMillis.low >>> 0, object.readyForReviewMillis.high >>> 0).toNumber();
                    if (object.processCallMillis != null)
                        if ($util.Long)
                            (message.processCallMillis = $util.Long.fromValue(object.processCallMillis)).unsigned = false;
                        else if (typeof object.processCallMillis === "string")
                            message.processCallMillis = parseInt(object.processCallMillis, 10);
                        else if (typeof object.processCallMillis === "number")
                            message.processCallMillis = object.processCallMillis;
                        else if (typeof object.processCallMillis === "object")
                            message.processCallMillis = new $util.LongBits(object.processCallMillis.low >>> 0, object.processCallMillis.high >>> 0).toNumber();
                    if (object.audioFilePath != null)
                        message.audioFilePath = String(object.audioFilePath);
                    if (object.keepAutorespondInPersonAuthenticate != null)
                        message.keepAutorespondInPersonAuthenticate = Boolean(object.keepAutorespondInPersonAuthenticate);
                    if (object.useRecordedGreeting != null)
                        message.useRecordedGreeting = Boolean(object.useRecordedGreeting);
                    if (object.requeueReasonChosen != null) {
                        if (typeof object.requeueReasonChosen !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.requeueReasonChosen: object expected");
                        message.requeueReasonChosen = $root.infinitusai.be.RequeueReason.fromObject(object.requeueReasonChosen);
                    }
                    if (object.requeueNotes != null)
                        message.requeueNotes = String(object.requeueNotes);
                    if (object.requeuedBy != null) {
                        if (typeof object.requeuedBy !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.requeuedBy: object expected");
                        message.requeuedBy = $root.infinitusai.be.RequeuedBy.fromObject(object.requeuedBy);
                    }
                    if (object.payerIntelligenceActions) {
                        if (!Array.isArray(object.payerIntelligenceActions))
                            throw TypeError(".infinitusai.be.CallDoc.payerIntelligenceActions: array expected");
                        message.payerIntelligenceActions = [];
                        for (var i = 0; i < object.payerIntelligenceActions.length; ++i) {
                            if (typeof object.payerIntelligenceActions[i] !== "object")
                                throw TypeError(".infinitusai.be.CallDoc.payerIntelligenceActions: object expected");
                            message.payerIntelligenceActions[i] = $root.infinitusai.be.PayerIntelligenceAction.fromObject(object.payerIntelligenceActions[i]);
                        }
                    }
                    if (object.isValuable != null)
                        message.isValuable = Boolean(object.isValuable);
                    if (object.callEscalated != null)
                        message.callEscalated = Boolean(object.callEscalated);
                    if (object.nexmoApiUrl != null)
                        message.nexmoApiUrl = String(object.nexmoApiUrl);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.multitaskEnabled != null)
                        message.multitaskEnabled = Boolean(object.multitaskEnabled);
                    if (object.operatorFeedbackGiven != null)
                        message.operatorFeedbackGiven = Boolean(object.operatorFeedbackGiven);
                    if (object.queueId != null)
                        message.queueId = String(object.queueId);
                    if (object.ivrFailure != null) {
                        if (typeof object.ivrFailure !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.ivrFailure: object expected");
                        message.ivrFailure = $root.infinitusai.be.IvrFailure.fromObject(object.ivrFailure);
                    }
                    if (object.usingElevenLabs != null)
                        message.usingElevenLabs = Boolean(object.usingElevenLabs);
                    if (object.ttsSetting != null) {
                        if (typeof object.ttsSetting !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.ttsSetting: object expected");
                        message.ttsSetting = $root.infinitusai.be.TTSSetting.fromObject(object.ttsSetting);
                    }
                    if (object.humanIntroConfig != null) {
                        if (typeof object.humanIntroConfig !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.humanIntroConfig: object expected");
                        message.humanIntroConfig = $root.infinitusai.be.HumanIntroConfig.fromObject(object.humanIntroConfig);
                    }
                    if (object.autorespondDidNavigateIvr != null)
                        message.autorespondDidNavigateIvr = Boolean(object.autorespondDidNavigateIvr);
                    if (object.loopError != null) {
                        if (typeof object.loopError !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.loopError: object expected");
                        message.loopError = $root.infinitusai.be.LoopError.fromObject(object.loopError);
                    }
                    if (object.wasHungupByOperator != null)
                        message.wasHungupByOperator = Boolean(object.wasHungupByOperator);
                    if (object.callMetadata != null) {
                        if (typeof object.callMetadata !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.callMetadata: object expected");
                        message.callMetadata = $root.infinitusai.be.CallMetadata.fromObject(object.callMetadata);
                    }
                    if (object.humanInvolved != null)
                        message.humanInvolved = Boolean(object.humanInvolved);
                    if (object.fasttrackProcessCallDetails != null) {
                        if (typeof object.fasttrackProcessCallDetails !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.fasttrackProcessCallDetails: object expected");
                        message.fasttrackProcessCallDetails = $root.infinitusai.be.FastTrackProcessCallDetails.fromObject(object.fasttrackProcessCallDetails);
                    }
                    if (object.isUserInitiated != null)
                        message.isUserInitiated = Boolean(object.isUserInitiated);
                    if (object.callAssignee != null) {
                        if (typeof object.callAssignee !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.callAssignee: object expected");
                        message.callAssignee = $root.infinitusai.be.UserInfo.fromObject(object.callAssignee);
                    }
                    if (object.fasttrackAdditionalData != null) {
                        if (typeof object.fasttrackAdditionalData !== "object")
                            throw TypeError(".infinitusai.be.CallDoc.fasttrackAdditionalData: object expected");
                        message.fasttrackAdditionalData = $root.infinitusai.be.FastTrackCallDocAdditionalData.fromObject(object.fasttrackAdditionalData);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {infinitusai.be.CallDoc} message CallDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.payerIntelligenceActions = [];
                    if (options.defaults) {
                        object.callUuid = "";
                        object.taskUuid = "";
                        object.orgUuid = "";
                        object.nexmoConvUuid = "";
                        object.nexmoOutboundLegUuid = "";
                        object.callCreator = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.audioStartMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.audioStartMillis = options.longs === String ? "0" : 0;
                        object.callStatusStarted = false;
                        object.nexmoWsCallState = options.enums === String ? "NEXMO_STATE_UNKNOWN" : 0;
                        object.nexmoOutboundCallState = options.enums === String ? "NEXMO_STATE_UNKNOWN" : 0;
                        object.startHeadless = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.audioEndMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.audioEndMillis = options.longs === String ? "0" : 0;
                        object.recordingReady = false;
                        object.readyForReviewUser = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.processCallMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.processCallMillis = options.longs === String ? "0" : 0;
                        object.audioFilePath = "";
                        object.keepAutorespondInPersonAuthenticate = false;
                        object.requeueReasonChosen = null;
                        object.requeueNotes = "";
                        object.isValuable = false;
                        object.queueingAlgorithm = options.enums === String ? "QUEUEING_MANUAL" : 0;
                        object.useRecordedGreeting = false;
                        object.processCallUser = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.readyForReviewMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.readyForReviewMillis = options.longs === String ? "0" : 0;
                        object.callEscalated = false;
                        object.nexmoApiUrl = "";
                        object.processCallOutcome = options.enums === String ? "PROCESS_CALL_OUTCOME_UNKNOWN" : 0;
                        object.requeuedBy = null;
                        object.phoneNumber = "";
                        object.multitaskEnabled = false;
                        object.operatorFeedbackGiven = false;
                        object.queueId = "";
                        object.ivrFailure = null;
                        object.usingElevenLabs = false;
                        object.ttsSetting = null;
                        object.humanIntroConfig = null;
                        object.autorespondDidNavigateIvr = false;
                        object.loopError = null;
                        object.wasHungupByOperator = false;
                        object.callMetadata = null;
                        object.humanInvolved = false;
                        object.fasttrackProcessCallDetails = null;
                        object.isUserInitiated = false;
                        object.callAssignee = null;
                        object.fasttrackAdditionalData = null;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.nexmoConvUuid != null && message.hasOwnProperty("nexmoConvUuid"))
                        object.nexmoConvUuid = message.nexmoConvUuid;
                    if (message.nexmoOutboundLegUuid != null && message.hasOwnProperty("nexmoOutboundLegUuid"))
                        object.nexmoOutboundLegUuid = message.nexmoOutboundLegUuid;
                    if (message.callCreator != null && message.hasOwnProperty("callCreator"))
                        object.callCreator = $root.infinitusai.be.UserInfo.toObject(message.callCreator, options);
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.audioStartMillis != null && message.hasOwnProperty("audioStartMillis"))
                        if (typeof message.audioStartMillis === "number")
                            object.audioStartMillis = options.longs === String ? String(message.audioStartMillis) : message.audioStartMillis;
                        else
                            object.audioStartMillis = options.longs === String ? $util.Long.prototype.toString.call(message.audioStartMillis) : options.longs === Number ? new $util.LongBits(message.audioStartMillis.low >>> 0, message.audioStartMillis.high >>> 0).toNumber() : message.audioStartMillis;
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        object.bvInputs = $root.infinitusai.be.BVCallInputs.toObject(message.bvInputs, options);
                        if (options.oneofs)
                            object.inputs = "bvInputs";
                    }
                    if (message.callStatusStarted != null && message.hasOwnProperty("callStatusStarted"))
                        object.callStatusStarted = message.callStatusStarted;
                    if (message.nexmoWsCallState != null && message.hasOwnProperty("nexmoWsCallState"))
                        object.nexmoWsCallState = options.enums === String ? $root.infinitusai.be.NexmoCallState[message.nexmoWsCallState] === undefined ? message.nexmoWsCallState : $root.infinitusai.be.NexmoCallState[message.nexmoWsCallState] : message.nexmoWsCallState;
                    if (message.nexmoOutboundCallState != null && message.hasOwnProperty("nexmoOutboundCallState"))
                        object.nexmoOutboundCallState = options.enums === String ? $root.infinitusai.be.NexmoCallState[message.nexmoOutboundCallState] === undefined ? message.nexmoOutboundCallState : $root.infinitusai.be.NexmoCallState[message.nexmoOutboundCallState] : message.nexmoOutboundCallState;
                    if (message.startHeadless != null && message.hasOwnProperty("startHeadless"))
                        object.startHeadless = message.startHeadless;
                    if (message.audioEndMillis != null && message.hasOwnProperty("audioEndMillis"))
                        if (typeof message.audioEndMillis === "number")
                            object.audioEndMillis = options.longs === String ? String(message.audioEndMillis) : message.audioEndMillis;
                        else
                            object.audioEndMillis = options.longs === String ? $util.Long.prototype.toString.call(message.audioEndMillis) : options.longs === Number ? new $util.LongBits(message.audioEndMillis.low >>> 0, message.audioEndMillis.high >>> 0).toNumber() : message.audioEndMillis;
                    if (message.recordingReady != null && message.hasOwnProperty("recordingReady"))
                        object.recordingReady = message.recordingReady;
                    if (message.readyForReviewUser != null && message.hasOwnProperty("readyForReviewUser"))
                        object.readyForReviewUser = $root.infinitusai.be.UserInfo.toObject(message.readyForReviewUser, options);
                    if (message.processCallMillis != null && message.hasOwnProperty("processCallMillis"))
                        if (typeof message.processCallMillis === "number")
                            object.processCallMillis = options.longs === String ? String(message.processCallMillis) : message.processCallMillis;
                        else
                            object.processCallMillis = options.longs === String ? $util.Long.prototype.toString.call(message.processCallMillis) : options.longs === Number ? new $util.LongBits(message.processCallMillis.low >>> 0, message.processCallMillis.high >>> 0).toNumber() : message.processCallMillis;
                    if (message.audioFilePath != null && message.hasOwnProperty("audioFilePath"))
                        object.audioFilePath = message.audioFilePath;
                    if (message.keepAutorespondInPersonAuthenticate != null && message.hasOwnProperty("keepAutorespondInPersonAuthenticate"))
                        object.keepAutorespondInPersonAuthenticate = message.keepAutorespondInPersonAuthenticate;
                    if (message.requeueReasonChosen != null && message.hasOwnProperty("requeueReasonChosen"))
                        object.requeueReasonChosen = $root.infinitusai.be.RequeueReason.toObject(message.requeueReasonChosen, options);
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        object.requeueNotes = message.requeueNotes;
                    if (message.payerIntelligenceActions && message.payerIntelligenceActions.length) {
                        object.payerIntelligenceActions = [];
                        for (var j = 0; j < message.payerIntelligenceActions.length; ++j)
                            object.payerIntelligenceActions[j] = $root.infinitusai.be.PayerIntelligenceAction.toObject(message.payerIntelligenceActions[j], options);
                    }
                    if (message.isValuable != null && message.hasOwnProperty("isValuable"))
                        object.isValuable = message.isValuable;
                    if (message.queueingAlgorithm != null && message.hasOwnProperty("queueingAlgorithm"))
                        object.queueingAlgorithm = options.enums === String ? $root.infinitusai.be.QueueingAlgorithm[message.queueingAlgorithm] === undefined ? message.queueingAlgorithm : $root.infinitusai.be.QueueingAlgorithm[message.queueingAlgorithm] : message.queueingAlgorithm;
                    if (message.useRecordedGreeting != null && message.hasOwnProperty("useRecordedGreeting"))
                        object.useRecordedGreeting = message.useRecordedGreeting;
                    if (message.processCallUser != null && message.hasOwnProperty("processCallUser"))
                        object.processCallUser = $root.infinitusai.be.UserInfo.toObject(message.processCallUser, options);
                    if (message.readyForReviewMillis != null && message.hasOwnProperty("readyForReviewMillis"))
                        if (typeof message.readyForReviewMillis === "number")
                            object.readyForReviewMillis = options.longs === String ? String(message.readyForReviewMillis) : message.readyForReviewMillis;
                        else
                            object.readyForReviewMillis = options.longs === String ? $util.Long.prototype.toString.call(message.readyForReviewMillis) : options.longs === Number ? new $util.LongBits(message.readyForReviewMillis.low >>> 0, message.readyForReviewMillis.high >>> 0).toNumber() : message.readyForReviewMillis;
                    if (message.callEscalated != null && message.hasOwnProperty("callEscalated"))
                        object.callEscalated = message.callEscalated;
                    if (message.nexmoApiUrl != null && message.hasOwnProperty("nexmoApiUrl"))
                        object.nexmoApiUrl = message.nexmoApiUrl;
                    if (message.processCallOutcome != null && message.hasOwnProperty("processCallOutcome"))
                        object.processCallOutcome = options.enums === String ? $root.infinitusai.be.ProcessCallOutcome[message.processCallOutcome] === undefined ? message.processCallOutcome : $root.infinitusai.be.ProcessCallOutcome[message.processCallOutcome] : message.processCallOutcome;
                    if (message.requeuedBy != null && message.hasOwnProperty("requeuedBy"))
                        object.requeuedBy = $root.infinitusai.be.RequeuedBy.toObject(message.requeuedBy, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.multitaskEnabled != null && message.hasOwnProperty("multitaskEnabled"))
                        object.multitaskEnabled = message.multitaskEnabled;
                    if (message.operatorFeedbackGiven != null && message.hasOwnProperty("operatorFeedbackGiven"))
                        object.operatorFeedbackGiven = message.operatorFeedbackGiven;
                    if (message.queueId != null && message.hasOwnProperty("queueId"))
                        object.queueId = message.queueId;
                    if (message.ivrFailure != null && message.hasOwnProperty("ivrFailure"))
                        object.ivrFailure = $root.infinitusai.be.IvrFailure.toObject(message.ivrFailure, options);
                    if (message.usingElevenLabs != null && message.hasOwnProperty("usingElevenLabs"))
                        object.usingElevenLabs = message.usingElevenLabs;
                    if (message.ttsSetting != null && message.hasOwnProperty("ttsSetting"))
                        object.ttsSetting = $root.infinitusai.be.TTSSetting.toObject(message.ttsSetting, options);
                    if (message.humanIntroConfig != null && message.hasOwnProperty("humanIntroConfig"))
                        object.humanIntroConfig = $root.infinitusai.be.HumanIntroConfig.toObject(message.humanIntroConfig, options);
                    if (message.autorespondDidNavigateIvr != null && message.hasOwnProperty("autorespondDidNavigateIvr"))
                        object.autorespondDidNavigateIvr = message.autorespondDidNavigateIvr;
                    if (message.loopError != null && message.hasOwnProperty("loopError"))
                        object.loopError = $root.infinitusai.be.LoopError.toObject(message.loopError, options);
                    if (message.wasHungupByOperator != null && message.hasOwnProperty("wasHungupByOperator"))
                        object.wasHungupByOperator = message.wasHungupByOperator;
                    if (message.callMetadata != null && message.hasOwnProperty("callMetadata"))
                        object.callMetadata = $root.infinitusai.be.CallMetadata.toObject(message.callMetadata, options);
                    if (message.humanInvolved != null && message.hasOwnProperty("humanInvolved"))
                        object.humanInvolved = message.humanInvolved;
                    if (message.fasttrackProcessCallDetails != null && message.hasOwnProperty("fasttrackProcessCallDetails"))
                        object.fasttrackProcessCallDetails = $root.infinitusai.be.FastTrackProcessCallDetails.toObject(message.fasttrackProcessCallDetails, options);
                    if (message.isUserInitiated != null && message.hasOwnProperty("isUserInitiated"))
                        object.isUserInitiated = message.isUserInitiated;
                    if (message.callAssignee != null && message.hasOwnProperty("callAssignee"))
                        object.callAssignee = $root.infinitusai.be.UserInfo.toObject(message.callAssignee, options);
                    if (message.fasttrackAdditionalData != null && message.hasOwnProperty("fasttrackAdditionalData"))
                        object.fasttrackAdditionalData = $root.infinitusai.be.FastTrackCallDocAdditionalData.toObject(message.fasttrackAdditionalData, options);
                    return object;
                };
    
                /**
                 * Converts this CallDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallDoc";
                };
    
                return CallDoc;
            })();
    
            be.ProgramDoc = (function() {
    
                /**
                 * Properties of a ProgramDoc.
                 * @memberof infinitusai.be
                 * @interface IProgramDoc
                 * @property {string|null} [name] ProgramDoc name
                 * @property {string|null} [displayName] ProgramDoc displayName
                 * @property {boolean|null} [shouldFailOverdueTasks] ProgramDoc shouldFailOverdueTasks
                 * @property {boolean|null} [shouldSkipOverdueTasks] ProgramDoc shouldSkipOverdueTasks
                 * @property {boolean|null} [shouldSkipFutureDueTasks] ProgramDoc shouldSkipFutureDueTasks
                 * @property {number|null} [valuableCallThresholdMinutes] ProgramDoc valuableCallThresholdMinutes
                 * @property {number|null} [valuableCallThresholdHeadlessHangups] ProgramDoc valuableCallThresholdHeadlessHangups
                 * @property {number|null} [maxHeadlessAutoRequeues] ProgramDoc maxHeadlessAutoRequeues
                 * @property {number|null} [taskDeduplicationWindowBusinessDays] ProgramDoc taskDeduplicationWindowBusinessDays
                 * @property {number|null} [taskTurnaroundTimeHours] ProgramDoc taskTurnaroundTimeHours
                 * @property {number|null} [taskTurnaroundTimeDays] ProgramDoc taskTurnaroundTimeDays
                 * @property {boolean|null} [queueEnabled] ProgramDoc queueEnabled
                 * @property {boolean|null} [enableAnnualReenrollmentDueDates] ProgramDoc enableAnnualReenrollmentDueDates
                 * @property {number|null} [numBusinessDaysBufferBeforeAdot] ProgramDoc numBusinessDaysBufferBeforeAdot
                 * @property {infinitusai.be.IChainingMetadata|null} [chainingMetadata] ProgramDoc chainingMetadata
                 */
    
                /**
                 * Constructs a new ProgramDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProgramDoc.
                 * @implements IProgramDoc
                 * @constructor
                 * @param {infinitusai.be.IProgramDoc=} [properties] Properties to set
                 */
                function ProgramDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProgramDoc name.
                 * @member {string} name
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.name = "";
    
                /**
                 * ProgramDoc displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.displayName = "";
    
                /**
                 * ProgramDoc shouldFailOverdueTasks.
                 * @member {boolean} shouldFailOverdueTasks
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.shouldFailOverdueTasks = false;
    
                /**
                 * ProgramDoc shouldSkipOverdueTasks.
                 * @member {boolean} shouldSkipOverdueTasks
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.shouldSkipOverdueTasks = false;
    
                /**
                 * ProgramDoc shouldSkipFutureDueTasks.
                 * @member {boolean} shouldSkipFutureDueTasks
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.shouldSkipFutureDueTasks = false;
    
                /**
                 * ProgramDoc valuableCallThresholdMinutes.
                 * @member {number} valuableCallThresholdMinutes
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.valuableCallThresholdMinutes = 0;
    
                /**
                 * ProgramDoc valuableCallThresholdHeadlessHangups.
                 * @member {number} valuableCallThresholdHeadlessHangups
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.valuableCallThresholdHeadlessHangups = 0;
    
                /**
                 * ProgramDoc maxHeadlessAutoRequeues.
                 * @member {number} maxHeadlessAutoRequeues
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.maxHeadlessAutoRequeues = 0;
    
                /**
                 * ProgramDoc taskDeduplicationWindowBusinessDays.
                 * @member {number} taskDeduplicationWindowBusinessDays
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.taskDeduplicationWindowBusinessDays = 0;
    
                /**
                 * ProgramDoc taskTurnaroundTimeHours.
                 * @member {number} taskTurnaroundTimeHours
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.taskTurnaroundTimeHours = 0;
    
                /**
                 * ProgramDoc taskTurnaroundTimeDays.
                 * @member {number} taskTurnaroundTimeDays
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.taskTurnaroundTimeDays = 0;
    
                /**
                 * ProgramDoc queueEnabled.
                 * @member {boolean} queueEnabled
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.queueEnabled = false;
    
                /**
                 * ProgramDoc enableAnnualReenrollmentDueDates.
                 * @member {boolean} enableAnnualReenrollmentDueDates
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.enableAnnualReenrollmentDueDates = false;
    
                /**
                 * ProgramDoc numBusinessDaysBufferBeforeAdot.
                 * @member {number} numBusinessDaysBufferBeforeAdot
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.numBusinessDaysBufferBeforeAdot = 0;
    
                /**
                 * ProgramDoc chainingMetadata.
                 * @member {infinitusai.be.IChainingMetadata|null|undefined} chainingMetadata
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.chainingMetadata = null;
    
                /**
                 * Creates a new ProgramDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {infinitusai.be.IProgramDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.ProgramDoc} ProgramDoc instance
                 */
                ProgramDoc.create = function create(properties) {
                    return new ProgramDoc(properties);
                };
    
                /**
                 * Encodes the specified ProgramDoc message. Does not implicitly {@link infinitusai.be.ProgramDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {infinitusai.be.IProgramDoc} message ProgramDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProgramDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayName);
                    if (message.shouldFailOverdueTasks != null && Object.hasOwnProperty.call(message, "shouldFailOverdueTasks"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.shouldFailOverdueTasks);
                    if (message.shouldSkipOverdueTasks != null && Object.hasOwnProperty.call(message, "shouldSkipOverdueTasks"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.shouldSkipOverdueTasks);
                    if (message.valuableCallThresholdMinutes != null && Object.hasOwnProperty.call(message, "valuableCallThresholdMinutes"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.valuableCallThresholdMinutes);
                    if (message.maxHeadlessAutoRequeues != null && Object.hasOwnProperty.call(message, "maxHeadlessAutoRequeues"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxHeadlessAutoRequeues);
                    if (message.shouldSkipFutureDueTasks != null && Object.hasOwnProperty.call(message, "shouldSkipFutureDueTasks"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.shouldSkipFutureDueTasks);
                    if (message.taskDeduplicationWindowBusinessDays != null && Object.hasOwnProperty.call(message, "taskDeduplicationWindowBusinessDays"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.taskDeduplicationWindowBusinessDays);
                    if (message.valuableCallThresholdHeadlessHangups != null && Object.hasOwnProperty.call(message, "valuableCallThresholdHeadlessHangups"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.valuableCallThresholdHeadlessHangups);
                    if (message.taskTurnaroundTimeHours != null && Object.hasOwnProperty.call(message, "taskTurnaroundTimeHours"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.taskTurnaroundTimeHours);
                    if (message.taskTurnaroundTimeDays != null && Object.hasOwnProperty.call(message, "taskTurnaroundTimeDays"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.taskTurnaroundTimeDays);
                    if (message.queueEnabled != null && Object.hasOwnProperty.call(message, "queueEnabled"))
                        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.queueEnabled);
                    if (message.enableAnnualReenrollmentDueDates != null && Object.hasOwnProperty.call(message, "enableAnnualReenrollmentDueDates"))
                        writer.uint32(/* id 13, wireType 0 =*/104).bool(message.enableAnnualReenrollmentDueDates);
                    if (message.numBusinessDaysBufferBeforeAdot != null && Object.hasOwnProperty.call(message, "numBusinessDaysBufferBeforeAdot"))
                        writer.uint32(/* id 14, wireType 0 =*/112).int32(message.numBusinessDaysBufferBeforeAdot);
                    if (message.chainingMetadata != null && Object.hasOwnProperty.call(message, "chainingMetadata"))
                        $root.infinitusai.be.ChainingMetadata.encode(message.chainingMetadata, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ProgramDoc message, length delimited. Does not implicitly {@link infinitusai.be.ProgramDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {infinitusai.be.IProgramDoc} message ProgramDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProgramDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProgramDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProgramDoc} ProgramDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProgramDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProgramDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 3: {
                                message.shouldFailOverdueTasks = reader.bool();
                                break;
                            }
                        case 4: {
                                message.shouldSkipOverdueTasks = reader.bool();
                                break;
                            }
                        case 7: {
                                message.shouldSkipFutureDueTasks = reader.bool();
                                break;
                            }
                        case 5: {
                                message.valuableCallThresholdMinutes = reader.int32();
                                break;
                            }
                        case 9: {
                                message.valuableCallThresholdHeadlessHangups = reader.int32();
                                break;
                            }
                        case 6: {
                                message.maxHeadlessAutoRequeues = reader.int32();
                                break;
                            }
                        case 8: {
                                message.taskDeduplicationWindowBusinessDays = reader.int32();
                                break;
                            }
                        case 10: {
                                message.taskTurnaroundTimeHours = reader.int32();
                                break;
                            }
                        case 11: {
                                message.taskTurnaroundTimeDays = reader.int32();
                                break;
                            }
                        case 12: {
                                message.queueEnabled = reader.bool();
                                break;
                            }
                        case 13: {
                                message.enableAnnualReenrollmentDueDates = reader.bool();
                                break;
                            }
                        case 14: {
                                message.numBusinessDaysBufferBeforeAdot = reader.int32();
                                break;
                            }
                        case 15: {
                                message.chainingMetadata = $root.infinitusai.be.ChainingMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProgramDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProgramDoc} ProgramDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProgramDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProgramDoc message.
                 * @function verify
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProgramDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.shouldFailOverdueTasks != null && message.hasOwnProperty("shouldFailOverdueTasks"))
                        if (typeof message.shouldFailOverdueTasks !== "boolean")
                            return "shouldFailOverdueTasks: boolean expected";
                    if (message.shouldSkipOverdueTasks != null && message.hasOwnProperty("shouldSkipOverdueTasks"))
                        if (typeof message.shouldSkipOverdueTasks !== "boolean")
                            return "shouldSkipOverdueTasks: boolean expected";
                    if (message.shouldSkipFutureDueTasks != null && message.hasOwnProperty("shouldSkipFutureDueTasks"))
                        if (typeof message.shouldSkipFutureDueTasks !== "boolean")
                            return "shouldSkipFutureDueTasks: boolean expected";
                    if (message.valuableCallThresholdMinutes != null && message.hasOwnProperty("valuableCallThresholdMinutes"))
                        if (!$util.isInteger(message.valuableCallThresholdMinutes))
                            return "valuableCallThresholdMinutes: integer expected";
                    if (message.valuableCallThresholdHeadlessHangups != null && message.hasOwnProperty("valuableCallThresholdHeadlessHangups"))
                        if (!$util.isInteger(message.valuableCallThresholdHeadlessHangups))
                            return "valuableCallThresholdHeadlessHangups: integer expected";
                    if (message.maxHeadlessAutoRequeues != null && message.hasOwnProperty("maxHeadlessAutoRequeues"))
                        if (!$util.isInteger(message.maxHeadlessAutoRequeues))
                            return "maxHeadlessAutoRequeues: integer expected";
                    if (message.taskDeduplicationWindowBusinessDays != null && message.hasOwnProperty("taskDeduplicationWindowBusinessDays"))
                        if (!$util.isInteger(message.taskDeduplicationWindowBusinessDays))
                            return "taskDeduplicationWindowBusinessDays: integer expected";
                    if (message.taskTurnaroundTimeHours != null && message.hasOwnProperty("taskTurnaroundTimeHours"))
                        if (!$util.isInteger(message.taskTurnaroundTimeHours))
                            return "taskTurnaroundTimeHours: integer expected";
                    if (message.taskTurnaroundTimeDays != null && message.hasOwnProperty("taskTurnaroundTimeDays"))
                        if (!$util.isInteger(message.taskTurnaroundTimeDays))
                            return "taskTurnaroundTimeDays: integer expected";
                    if (message.queueEnabled != null && message.hasOwnProperty("queueEnabled"))
                        if (typeof message.queueEnabled !== "boolean")
                            return "queueEnabled: boolean expected";
                    if (message.enableAnnualReenrollmentDueDates != null && message.hasOwnProperty("enableAnnualReenrollmentDueDates"))
                        if (typeof message.enableAnnualReenrollmentDueDates !== "boolean")
                            return "enableAnnualReenrollmentDueDates: boolean expected";
                    if (message.numBusinessDaysBufferBeforeAdot != null && message.hasOwnProperty("numBusinessDaysBufferBeforeAdot"))
                        if (!$util.isInteger(message.numBusinessDaysBufferBeforeAdot))
                            return "numBusinessDaysBufferBeforeAdot: integer expected";
                    if (message.chainingMetadata != null && message.hasOwnProperty("chainingMetadata")) {
                        var error = $root.infinitusai.be.ChainingMetadata.verify(message.chainingMetadata);
                        if (error)
                            return "chainingMetadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ProgramDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProgramDoc} ProgramDoc
                 */
                ProgramDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProgramDoc)
                        return object;
                    var message = new $root.infinitusai.be.ProgramDoc();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.shouldFailOverdueTasks != null)
                        message.shouldFailOverdueTasks = Boolean(object.shouldFailOverdueTasks);
                    if (object.shouldSkipOverdueTasks != null)
                        message.shouldSkipOverdueTasks = Boolean(object.shouldSkipOverdueTasks);
                    if (object.shouldSkipFutureDueTasks != null)
                        message.shouldSkipFutureDueTasks = Boolean(object.shouldSkipFutureDueTasks);
                    if (object.valuableCallThresholdMinutes != null)
                        message.valuableCallThresholdMinutes = object.valuableCallThresholdMinutes | 0;
                    if (object.valuableCallThresholdHeadlessHangups != null)
                        message.valuableCallThresholdHeadlessHangups = object.valuableCallThresholdHeadlessHangups | 0;
                    if (object.maxHeadlessAutoRequeues != null)
                        message.maxHeadlessAutoRequeues = object.maxHeadlessAutoRequeues | 0;
                    if (object.taskDeduplicationWindowBusinessDays != null)
                        message.taskDeduplicationWindowBusinessDays = object.taskDeduplicationWindowBusinessDays | 0;
                    if (object.taskTurnaroundTimeHours != null)
                        message.taskTurnaroundTimeHours = object.taskTurnaroundTimeHours | 0;
                    if (object.taskTurnaroundTimeDays != null)
                        message.taskTurnaroundTimeDays = object.taskTurnaroundTimeDays | 0;
                    if (object.queueEnabled != null)
                        message.queueEnabled = Boolean(object.queueEnabled);
                    if (object.enableAnnualReenrollmentDueDates != null)
                        message.enableAnnualReenrollmentDueDates = Boolean(object.enableAnnualReenrollmentDueDates);
                    if (object.numBusinessDaysBufferBeforeAdot != null)
                        message.numBusinessDaysBufferBeforeAdot = object.numBusinessDaysBufferBeforeAdot | 0;
                    if (object.chainingMetadata != null) {
                        if (typeof object.chainingMetadata !== "object")
                            throw TypeError(".infinitusai.be.ProgramDoc.chainingMetadata: object expected");
                        message.chainingMetadata = $root.infinitusai.be.ChainingMetadata.fromObject(object.chainingMetadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProgramDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {infinitusai.be.ProgramDoc} message ProgramDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProgramDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.displayName = "";
                        object.shouldFailOverdueTasks = false;
                        object.shouldSkipOverdueTasks = false;
                        object.valuableCallThresholdMinutes = 0;
                        object.maxHeadlessAutoRequeues = 0;
                        object.shouldSkipFutureDueTasks = false;
                        object.taskDeduplicationWindowBusinessDays = 0;
                        object.valuableCallThresholdHeadlessHangups = 0;
                        object.taskTurnaroundTimeHours = 0;
                        object.taskTurnaroundTimeDays = 0;
                        object.queueEnabled = false;
                        object.enableAnnualReenrollmentDueDates = false;
                        object.numBusinessDaysBufferBeforeAdot = 0;
                        object.chainingMetadata = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.shouldFailOverdueTasks != null && message.hasOwnProperty("shouldFailOverdueTasks"))
                        object.shouldFailOverdueTasks = message.shouldFailOverdueTasks;
                    if (message.shouldSkipOverdueTasks != null && message.hasOwnProperty("shouldSkipOverdueTasks"))
                        object.shouldSkipOverdueTasks = message.shouldSkipOverdueTasks;
                    if (message.valuableCallThresholdMinutes != null && message.hasOwnProperty("valuableCallThresholdMinutes"))
                        object.valuableCallThresholdMinutes = message.valuableCallThresholdMinutes;
                    if (message.maxHeadlessAutoRequeues != null && message.hasOwnProperty("maxHeadlessAutoRequeues"))
                        object.maxHeadlessAutoRequeues = message.maxHeadlessAutoRequeues;
                    if (message.shouldSkipFutureDueTasks != null && message.hasOwnProperty("shouldSkipFutureDueTasks"))
                        object.shouldSkipFutureDueTasks = message.shouldSkipFutureDueTasks;
                    if (message.taskDeduplicationWindowBusinessDays != null && message.hasOwnProperty("taskDeduplicationWindowBusinessDays"))
                        object.taskDeduplicationWindowBusinessDays = message.taskDeduplicationWindowBusinessDays;
                    if (message.valuableCallThresholdHeadlessHangups != null && message.hasOwnProperty("valuableCallThresholdHeadlessHangups"))
                        object.valuableCallThresholdHeadlessHangups = message.valuableCallThresholdHeadlessHangups;
                    if (message.taskTurnaroundTimeHours != null && message.hasOwnProperty("taskTurnaroundTimeHours"))
                        object.taskTurnaroundTimeHours = message.taskTurnaroundTimeHours;
                    if (message.taskTurnaroundTimeDays != null && message.hasOwnProperty("taskTurnaroundTimeDays"))
                        object.taskTurnaroundTimeDays = message.taskTurnaroundTimeDays;
                    if (message.queueEnabled != null && message.hasOwnProperty("queueEnabled"))
                        object.queueEnabled = message.queueEnabled;
                    if (message.enableAnnualReenrollmentDueDates != null && message.hasOwnProperty("enableAnnualReenrollmentDueDates"))
                        object.enableAnnualReenrollmentDueDates = message.enableAnnualReenrollmentDueDates;
                    if (message.numBusinessDaysBufferBeforeAdot != null && message.hasOwnProperty("numBusinessDaysBufferBeforeAdot"))
                        object.numBusinessDaysBufferBeforeAdot = message.numBusinessDaysBufferBeforeAdot;
                    if (message.chainingMetadata != null && message.hasOwnProperty("chainingMetadata"))
                        object.chainingMetadata = $root.infinitusai.be.ChainingMetadata.toObject(message.chainingMetadata, options);
                    return object;
                };
    
                /**
                 * Converts this ProgramDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProgramDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProgramDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProgramDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProgramDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProgramDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProgramDoc";
                };
    
                return ProgramDoc;
            })();
    
            be.ChainingMetadata = (function() {
    
                /**
                 * Properties of a ChainingMetadata.
                 * @memberof infinitusai.be
                 * @interface IChainingMetadata
                 * @property {number|null} [frequencyOfChaining] ChainingMetadata frequencyOfChaining
                 * @property {number|null} [maxLimitBusinessDays] ChainingMetadata maxLimitBusinessDays
                 */
    
                /**
                 * Constructs a new ChainingMetadata.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChainingMetadata.
                 * @implements IChainingMetadata
                 * @constructor
                 * @param {infinitusai.be.IChainingMetadata=} [properties] Properties to set
                 */
                function ChainingMetadata(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChainingMetadata frequencyOfChaining.
                 * @member {number} frequencyOfChaining
                 * @memberof infinitusai.be.ChainingMetadata
                 * @instance
                 */
                ChainingMetadata.prototype.frequencyOfChaining = 0;
    
                /**
                 * ChainingMetadata maxLimitBusinessDays.
                 * @member {number} maxLimitBusinessDays
                 * @memberof infinitusai.be.ChainingMetadata
                 * @instance
                 */
                ChainingMetadata.prototype.maxLimitBusinessDays = 0;
    
                /**
                 * Creates a new ChainingMetadata instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {infinitusai.be.IChainingMetadata=} [properties] Properties to set
                 * @returns {infinitusai.be.ChainingMetadata} ChainingMetadata instance
                 */
                ChainingMetadata.create = function create(properties) {
                    return new ChainingMetadata(properties);
                };
    
                /**
                 * Encodes the specified ChainingMetadata message. Does not implicitly {@link infinitusai.be.ChainingMetadata.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {infinitusai.be.IChainingMetadata} message ChainingMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChainingMetadata.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.frequencyOfChaining != null && Object.hasOwnProperty.call(message, "frequencyOfChaining"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.frequencyOfChaining);
                    if (message.maxLimitBusinessDays != null && Object.hasOwnProperty.call(message, "maxLimitBusinessDays"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxLimitBusinessDays);
                    return writer;
                };
    
                /**
                 * Encodes the specified ChainingMetadata message, length delimited. Does not implicitly {@link infinitusai.be.ChainingMetadata.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {infinitusai.be.IChainingMetadata} message ChainingMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChainingMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChainingMetadata message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChainingMetadata} ChainingMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChainingMetadata.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChainingMetadata();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.frequencyOfChaining = reader.int32();
                                break;
                            }
                        case 2: {
                                message.maxLimitBusinessDays = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChainingMetadata message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChainingMetadata} ChainingMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChainingMetadata.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChainingMetadata message.
                 * @function verify
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChainingMetadata.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.frequencyOfChaining != null && message.hasOwnProperty("frequencyOfChaining"))
                        if (!$util.isInteger(message.frequencyOfChaining))
                            return "frequencyOfChaining: integer expected";
                    if (message.maxLimitBusinessDays != null && message.hasOwnProperty("maxLimitBusinessDays"))
                        if (!$util.isInteger(message.maxLimitBusinessDays))
                            return "maxLimitBusinessDays: integer expected";
                    return null;
                };
    
                /**
                 * Creates a ChainingMetadata message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChainingMetadata} ChainingMetadata
                 */
                ChainingMetadata.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChainingMetadata)
                        return object;
                    var message = new $root.infinitusai.be.ChainingMetadata();
                    if (object.frequencyOfChaining != null)
                        message.frequencyOfChaining = object.frequencyOfChaining | 0;
                    if (object.maxLimitBusinessDays != null)
                        message.maxLimitBusinessDays = object.maxLimitBusinessDays | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChainingMetadata message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {infinitusai.be.ChainingMetadata} message ChainingMetadata
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChainingMetadata.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.frequencyOfChaining = 0;
                        object.maxLimitBusinessDays = 0;
                    }
                    if (message.frequencyOfChaining != null && message.hasOwnProperty("frequencyOfChaining"))
                        object.frequencyOfChaining = message.frequencyOfChaining;
                    if (message.maxLimitBusinessDays != null && message.hasOwnProperty("maxLimitBusinessDays"))
                        object.maxLimitBusinessDays = message.maxLimitBusinessDays;
                    return object;
                };
    
                /**
                 * Converts this ChainingMetadata to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChainingMetadata
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChainingMetadata.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChainingMetadata
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChainingMetadata
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChainingMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChainingMetadata";
                };
    
                return ChainingMetadata;
            })();
    
            /**
             * OrgRole enum.
             * @name infinitusai.be.OrgRole
             * @enum {number}
             * @property {number} ORG_ROLE_UNKNOWN=0 ORG_ROLE_UNKNOWN value
             * @property {number} ORG_ROLE_OWNER=1 ORG_ROLE_OWNER value
             * @property {number} ORG_ROLE_MEMBER=2 ORG_ROLE_MEMBER value
             * @property {number} ORG_ROLE_SUPERVISOR=3 ORG_ROLE_SUPERVISOR value
             * @property {number} ORG_ROLE_OPERATOR=4 ORG_ROLE_OPERATOR value
             */
            be.OrgRole = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ORG_ROLE_UNKNOWN"] = 0;
                values[valuesById[1] = "ORG_ROLE_OWNER"] = 1;
                values[valuesById[2] = "ORG_ROLE_MEMBER"] = 2;
                values[valuesById[3] = "ORG_ROLE_SUPERVISOR"] = 3;
                values[valuesById[4] = "ORG_ROLE_OPERATOR"] = 4;
                return values;
            })();
    
            be.OrgUser = (function() {
    
                /**
                 * Properties of an OrgUser.
                 * @memberof infinitusai.be
                 * @interface IOrgUser
                 * @property {string|null} [email] OrgUser email
                 * @property {infinitusai.be.OrgRole|null} [role] OrgUser role
                 * @property {string|null} [imageUrl] OrgUser imageUrl
                 * @property {string|null} [displayName] OrgUser displayName
                 * @property {number|Long|null} [lastActiveMillis] OrgUser lastActiveMillis
                 * @property {string|null} [onboardingStatus] OrgUser onboardingStatus
                 */
    
                /**
                 * Constructs a new OrgUser.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrgUser.
                 * @implements IOrgUser
                 * @constructor
                 * @param {infinitusai.be.IOrgUser=} [properties] Properties to set
                 */
                function OrgUser(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgUser email.
                 * @member {string} email
                 * @memberof infinitusai.be.OrgUser
                 * @instance
                 */
                OrgUser.prototype.email = "";
    
                /**
                 * OrgUser role.
                 * @member {infinitusai.be.OrgRole} role
                 * @memberof infinitusai.be.OrgUser
                 * @instance
                 */
                OrgUser.prototype.role = 0;
    
                /**
                 * OrgUser imageUrl.
                 * @member {string} imageUrl
                 * @memberof infinitusai.be.OrgUser
                 * @instance
                 */
                OrgUser.prototype.imageUrl = "";
    
                /**
                 * OrgUser displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.be.OrgUser
                 * @instance
                 */
                OrgUser.prototype.displayName = "";
    
                /**
                 * OrgUser lastActiveMillis.
                 * @member {number|Long} lastActiveMillis
                 * @memberof infinitusai.be.OrgUser
                 * @instance
                 */
                OrgUser.prototype.lastActiveMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * OrgUser onboardingStatus.
                 * @member {string} onboardingStatus
                 * @memberof infinitusai.be.OrgUser
                 * @instance
                 */
                OrgUser.prototype.onboardingStatus = "";
    
                /**
                 * Creates a new OrgUser instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {infinitusai.be.IOrgUser=} [properties] Properties to set
                 * @returns {infinitusai.be.OrgUser} OrgUser instance
                 */
                OrgUser.create = function create(properties) {
                    return new OrgUser(properties);
                };
    
                /**
                 * Encodes the specified OrgUser message. Does not implicitly {@link infinitusai.be.OrgUser.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {infinitusai.be.IOrgUser} message OrgUser message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgUser.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.role);
                    if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.imageUrl);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.displayName);
                    if (message.lastActiveMillis != null && Object.hasOwnProperty.call(message, "lastActiveMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.lastActiveMillis);
                    if (message.onboardingStatus != null && Object.hasOwnProperty.call(message, "onboardingStatus"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.onboardingStatus);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgUser message, length delimited. Does not implicitly {@link infinitusai.be.OrgUser.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {infinitusai.be.IOrgUser} message OrgUser message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgUser.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgUser message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrgUser} OrgUser
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgUser.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrgUser();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        case 2: {
                                message.role = reader.int32();
                                break;
                            }
                        case 3: {
                                message.imageUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 5: {
                                message.lastActiveMillis = reader.int64();
                                break;
                            }
                        case 6: {
                                message.onboardingStatus = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgUser message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrgUser} OrgUser
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgUser.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgUser message.
                 * @function verify
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgUser.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.role != null && message.hasOwnProperty("role"))
                        switch (message.role) {
                        default:
                            return "role: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        if (!$util.isString(message.imageUrl))
                            return "imageUrl: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.lastActiveMillis != null && message.hasOwnProperty("lastActiveMillis"))
                        if (!$util.isInteger(message.lastActiveMillis) && !(message.lastActiveMillis && $util.isInteger(message.lastActiveMillis.low) && $util.isInteger(message.lastActiveMillis.high)))
                            return "lastActiveMillis: integer|Long expected";
                    if (message.onboardingStatus != null && message.hasOwnProperty("onboardingStatus"))
                        if (!$util.isString(message.onboardingStatus))
                            return "onboardingStatus: string expected";
                    return null;
                };
    
                /**
                 * Creates an OrgUser message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrgUser} OrgUser
                 */
                OrgUser.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrgUser)
                        return object;
                    var message = new $root.infinitusai.be.OrgUser();
                    if (object.email != null)
                        message.email = String(object.email);
                    switch (object.role) {
                    default:
                        if (typeof object.role === "number") {
                            message.role = object.role;
                            break;
                        }
                        break;
                    case "ORG_ROLE_UNKNOWN":
                    case 0:
                        message.role = 0;
                        break;
                    case "ORG_ROLE_OWNER":
                    case 1:
                        message.role = 1;
                        break;
                    case "ORG_ROLE_MEMBER":
                    case 2:
                        message.role = 2;
                        break;
                    case "ORG_ROLE_SUPERVISOR":
                    case 3:
                        message.role = 3;
                        break;
                    case "ORG_ROLE_OPERATOR":
                    case 4:
                        message.role = 4;
                        break;
                    }
                    if (object.imageUrl != null)
                        message.imageUrl = String(object.imageUrl);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.lastActiveMillis != null)
                        if ($util.Long)
                            (message.lastActiveMillis = $util.Long.fromValue(object.lastActiveMillis)).unsigned = false;
                        else if (typeof object.lastActiveMillis === "string")
                            message.lastActiveMillis = parseInt(object.lastActiveMillis, 10);
                        else if (typeof object.lastActiveMillis === "number")
                            message.lastActiveMillis = object.lastActiveMillis;
                        else if (typeof object.lastActiveMillis === "object")
                            message.lastActiveMillis = new $util.LongBits(object.lastActiveMillis.low >>> 0, object.lastActiveMillis.high >>> 0).toNumber();
                    if (object.onboardingStatus != null)
                        message.onboardingStatus = String(object.onboardingStatus);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgUser message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {infinitusai.be.OrgUser} message OrgUser
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgUser.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.email = "";
                        object.role = options.enums === String ? "ORG_ROLE_UNKNOWN" : 0;
                        object.imageUrl = "";
                        object.displayName = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastActiveMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastActiveMillis = options.longs === String ? "0" : 0;
                        object.onboardingStatus = "";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.role != null && message.hasOwnProperty("role"))
                        object.role = options.enums === String ? $root.infinitusai.be.OrgRole[message.role] === undefined ? message.role : $root.infinitusai.be.OrgRole[message.role] : message.role;
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        object.imageUrl = message.imageUrl;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.lastActiveMillis != null && message.hasOwnProperty("lastActiveMillis"))
                        if (typeof message.lastActiveMillis === "number")
                            object.lastActiveMillis = options.longs === String ? String(message.lastActiveMillis) : message.lastActiveMillis;
                        else
                            object.lastActiveMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastActiveMillis) : options.longs === Number ? new $util.LongBits(message.lastActiveMillis.low >>> 0, message.lastActiveMillis.high >>> 0).toNumber() : message.lastActiveMillis;
                    if (message.onboardingStatus != null && message.hasOwnProperty("onboardingStatus"))
                        object.onboardingStatus = message.onboardingStatus;
                    return object;
                };
    
                /**
                 * Converts this OrgUser to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrgUser
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgUser.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgUser
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrgUser
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrgUser";
                };
    
                return OrgUser;
            })();
    
            be.OrgMembership = (function() {
    
                /**
                 * Properties of an OrgMembership.
                 * @memberof infinitusai.be
                 * @interface IOrgMembership
                 * @property {string|null} [orgUuid] OrgMembership orgUuid
                 * @property {infinitusai.be.OrgRole|null} [orgRole] OrgMembership orgRole
                 * @property {string|null} [orgName] OrgMembership orgName
                 * @property {string|null} [orgDisplayName] OrgMembership orgDisplayName
                 * @property {string|null} [orgImageUrl] OrgMembership orgImageUrl
                 * @property {Array.<infinitusai.orgs.CustomerPortalFeature>|null} [orgFeatures] OrgMembership orgFeatures
                 */
    
                /**
                 * Constructs a new OrgMembership.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrgMembership.
                 * @implements IOrgMembership
                 * @constructor
                 * @param {infinitusai.be.IOrgMembership=} [properties] Properties to set
                 */
                function OrgMembership(properties) {
                    this.orgFeatures = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgMembership orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.OrgMembership
                 * @instance
                 */
                OrgMembership.prototype.orgUuid = "";
    
                /**
                 * OrgMembership orgRole.
                 * @member {infinitusai.be.OrgRole} orgRole
                 * @memberof infinitusai.be.OrgMembership
                 * @instance
                 */
                OrgMembership.prototype.orgRole = 0;
    
                /**
                 * OrgMembership orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.OrgMembership
                 * @instance
                 */
                OrgMembership.prototype.orgName = "";
    
                /**
                 * OrgMembership orgDisplayName.
                 * @member {string} orgDisplayName
                 * @memberof infinitusai.be.OrgMembership
                 * @instance
                 */
                OrgMembership.prototype.orgDisplayName = "";
    
                /**
                 * OrgMembership orgImageUrl.
                 * @member {string} orgImageUrl
                 * @memberof infinitusai.be.OrgMembership
                 * @instance
                 */
                OrgMembership.prototype.orgImageUrl = "";
    
                /**
                 * OrgMembership orgFeatures.
                 * @member {Array.<infinitusai.orgs.CustomerPortalFeature>} orgFeatures
                 * @memberof infinitusai.be.OrgMembership
                 * @instance
                 */
                OrgMembership.prototype.orgFeatures = $util.emptyArray;
    
                /**
                 * Creates a new OrgMembership instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {infinitusai.be.IOrgMembership=} [properties] Properties to set
                 * @returns {infinitusai.be.OrgMembership} OrgMembership instance
                 */
                OrgMembership.create = function create(properties) {
                    return new OrgMembership(properties);
                };
    
                /**
                 * Encodes the specified OrgMembership message. Does not implicitly {@link infinitusai.be.OrgMembership.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {infinitusai.be.IOrgMembership} message OrgMembership message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgMembership.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.orgRole != null && Object.hasOwnProperty.call(message, "orgRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orgRole);
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgName);
                    if (message.orgDisplayName != null && Object.hasOwnProperty.call(message, "orgDisplayName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orgDisplayName);
                    if (message.orgImageUrl != null && Object.hasOwnProperty.call(message, "orgImageUrl"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.orgImageUrl);
                    if (message.orgFeatures != null && message.orgFeatures.length) {
                        writer.uint32(/* id 6, wireType 2 =*/50).fork();
                        for (var i = 0; i < message.orgFeatures.length; ++i)
                            writer.int32(message.orgFeatures[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgMembership message, length delimited. Does not implicitly {@link infinitusai.be.OrgMembership.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {infinitusai.be.IOrgMembership} message OrgMembership message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgMembership.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgMembership message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrgMembership} OrgMembership
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgMembership.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrgMembership();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgRole = reader.int32();
                                break;
                            }
                        case 3: {
                                message.orgName = reader.string();
                                break;
                            }
                        case 4: {
                                message.orgDisplayName = reader.string();
                                break;
                            }
                        case 5: {
                                message.orgImageUrl = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.orgFeatures && message.orgFeatures.length))
                                    message.orgFeatures = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.orgFeatures.push(reader.int32());
                                } else
                                    message.orgFeatures.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgMembership message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrgMembership} OrgMembership
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgMembership.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgMembership message.
                 * @function verify
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgMembership.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        switch (message.orgRole) {
                        default:
                            return "orgRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        if (!$util.isString(message.orgDisplayName))
                            return "orgDisplayName: string expected";
                    if (message.orgImageUrl != null && message.hasOwnProperty("orgImageUrl"))
                        if (!$util.isString(message.orgImageUrl))
                            return "orgImageUrl: string expected";
                    if (message.orgFeatures != null && message.hasOwnProperty("orgFeatures")) {
                        if (!Array.isArray(message.orgFeatures))
                            return "orgFeatures: array expected";
                        for (var i = 0; i < message.orgFeatures.length; ++i)
                            switch (message.orgFeatures[i]) {
                            default:
                                return "orgFeatures: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 30:
                            case 31:
                            case 32:
                            case 33:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates an OrgMembership message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrgMembership} OrgMembership
                 */
                OrgMembership.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrgMembership)
                        return object;
                    var message = new $root.infinitusai.be.OrgMembership();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    switch (object.orgRole) {
                    default:
                        if (typeof object.orgRole === "number") {
                            message.orgRole = object.orgRole;
                            break;
                        }
                        break;
                    case "ORG_ROLE_UNKNOWN":
                    case 0:
                        message.orgRole = 0;
                        break;
                    case "ORG_ROLE_OWNER":
                    case 1:
                        message.orgRole = 1;
                        break;
                    case "ORG_ROLE_MEMBER":
                    case 2:
                        message.orgRole = 2;
                        break;
                    case "ORG_ROLE_SUPERVISOR":
                    case 3:
                        message.orgRole = 3;
                        break;
                    case "ORG_ROLE_OPERATOR":
                    case 4:
                        message.orgRole = 4;
                        break;
                    }
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    if (object.orgDisplayName != null)
                        message.orgDisplayName = String(object.orgDisplayName);
                    if (object.orgImageUrl != null)
                        message.orgImageUrl = String(object.orgImageUrl);
                    if (object.orgFeatures) {
                        if (!Array.isArray(object.orgFeatures))
                            throw TypeError(".infinitusai.be.OrgMembership.orgFeatures: array expected");
                        message.orgFeatures = [];
                        for (var i = 0; i < object.orgFeatures.length; ++i)
                            switch (object.orgFeatures[i]) {
                            default:
                                if (typeof object.orgFeatures[i] === "number") {
                                    message.orgFeatures[i] = object.orgFeatures[i];
                                    break;
                                }
                            case "CUSTOMER_PORTAL_FEATURE_UNKNOWN":
                            case 0:
                                message.orgFeatures[i] = 0;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_PAYER_INTELLIGENCE":
                            case 1:
                                message.orgFeatures[i] = 1;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_REPORTS":
                            case 2:
                                message.orgFeatures[i] = 2;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CREATE_TASK":
                            case 3:
                                message.orgFeatures[i] = 3;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BILLING":
                            case 4:
                                message.orgFeatures[i] = 4;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_EXPOSE_PHI":
                            case 5:
                                message.orgFeatures[i] = 5;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_ALL_TASKS_REQUIRE_REVIEW":
                            case 6:
                                message.orgFeatures[i] = 6;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_UPDATE_TASK_INPUTS":
                            case 7:
                                message.orgFeatures[i] = 7;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IMPORT_TASKS_CSV":
                            case 8:
                                message.orgFeatures[i] = 8;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BILLING_NOTES":
                            case 9:
                                message.orgFeatures[i] = 9;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IVR_AND_HOLD":
                            case 10:
                                message.orgFeatures[i] = 10;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IMPORT_RULES":
                            case 11:
                                message.orgFeatures[i] = 11;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TASK_TABLE_TASK_TYPES":
                            case 12:
                                message.orgFeatures[i] = 12;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TASK_TABLE_PRODUCTS":
                            case 13:
                                message.orgFeatures[i] = 13;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_MARK_TASK_COMPLETE":
                            case 14:
                                message.orgFeatures[i] = 14;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_RBAC":
                            case 15:
                                message.orgFeatures[i] = 15;
                                break;
                            case "CUSTOMER_PORTAL_UI_UPDATES":
                            case 16:
                                message.orgFeatures[i] = 16;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_V2":
                            case 17:
                                message.orgFeatures[i] = 17;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_HUMAN_READABLE_CSV_REPORT":
                            case 18:
                                message.orgFeatures[i] = 18;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SELF_SERVE_TESTING":
                            case 19:
                                message.orgFeatures[i] = 19;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CSWII":
                            case 20:
                                message.orgFeatures[i] = 20;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SHOW_TIME_SAVINGS":
                            case 21:
                                message.orgFeatures[i] = 21;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_ACTIVE_CALLS":
                            case 22:
                                message.orgFeatures[i] = 22;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_NEW_VOICE_ARCHITECTURE":
                            case 23:
                                message.orgFeatures[i] = 23;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_DISABLE_FT_HOLD_TIMES":
                            case 24:
                                message.orgFeatures[i] = 24;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CALL_SUMMARIZATION":
                            case 25:
                                message.orgFeatures[i] = 25;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SHOW_HUMAN_INVOLVEMENT_FLAG":
                            case 26:
                                message.orgFeatures[i] = 26;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_MULTI_PLAYER_MODE_INTERNAL":
                            case 27:
                                message.orgFeatures[i] = 27;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_SINGLE_PLAYER_MODE_INTERNAL":
                            case 28:
                                message.orgFeatures[i] = 28;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CSV_REPORT_EXCLUDE_UNUSED_API_OUTPUTS":
                            case 29:
                                message.orgFeatures[i] = 29;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_DEMO":
                            case 30:
                                message.orgFeatures[i] = 30;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TEAM_MANAGEMENT":
                            case 31:
                                message.orgFeatures[i] = 31;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FT_TRANSCRIPT":
                            case 32:
                                message.orgFeatures[i] = 32;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BUNDLE_CUSTOMER_TASKS":
                            case 33:
                                message.orgFeatures[i] = 33;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgMembership message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {infinitusai.be.OrgMembership} message OrgMembership
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgMembership.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgFeatures = [];
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.orgRole = options.enums === String ? "ORG_ROLE_UNKNOWN" : 0;
                        object.orgName = "";
                        object.orgDisplayName = "";
                        object.orgImageUrl = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        object.orgRole = options.enums === String ? $root.infinitusai.be.OrgRole[message.orgRole] === undefined ? message.orgRole : $root.infinitusai.be.OrgRole[message.orgRole] : message.orgRole;
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        object.orgDisplayName = message.orgDisplayName;
                    if (message.orgImageUrl != null && message.hasOwnProperty("orgImageUrl"))
                        object.orgImageUrl = message.orgImageUrl;
                    if (message.orgFeatures && message.orgFeatures.length) {
                        object.orgFeatures = [];
                        for (var j = 0; j < message.orgFeatures.length; ++j)
                            object.orgFeatures[j] = options.enums === String ? $root.infinitusai.orgs.CustomerPortalFeature[message.orgFeatures[j]] === undefined ? message.orgFeatures[j] : $root.infinitusai.orgs.CustomerPortalFeature[message.orgFeatures[j]] : message.orgFeatures[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this OrgMembership to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrgMembership
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgMembership.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgMembership
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrgMembership
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgMembership.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrgMembership";
                };
    
                return OrgMembership;
            })();
    
            /**
             * ApiFeature enum.
             * @name infinitusai.be.ApiFeature
             * @enum {number}
             * @property {number} API_FEATURE_UNKNOWN=0 API_FEATURE_UNKNOWN value
             * @property {number} API_FEATURE_TASK_RECORDINGS_API=1 API_FEATURE_TASK_RECORDINGS_API value
             * @property {number} API_FEATURE_CANCEL_TASKS_API=2 API_FEATURE_CANCEL_TASKS_API value
             * @property {number} API_FEATURE_ENABLE_VONAGE_SDK=3 API_FEATURE_ENABLE_VONAGE_SDK value
             */
            be.ApiFeature = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "API_FEATURE_UNKNOWN"] = 0;
                values[valuesById[1] = "API_FEATURE_TASK_RECORDINGS_API"] = 1;
                values[valuesById[2] = "API_FEATURE_CANCEL_TASKS_API"] = 2;
                values[valuesById[3] = "API_FEATURE_ENABLE_VONAGE_SDK"] = 3;
                return values;
            })();
    
            be.OrgBillingReport = (function() {
    
                /**
                 * Properties of an OrgBillingReport.
                 * @memberof infinitusai.be
                 * @interface IOrgBillingReport
                 * @property {number|Long|null} [createdOnMillis] OrgBillingReport createdOnMillis
                 * @property {string|null} [gcsFilePath] OrgBillingReport gcsFilePath
                 * @property {string|null} [month] OrgBillingReport month
                 * @property {string|null} [year] OrgBillingReport year
                 */
    
                /**
                 * Constructs a new OrgBillingReport.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrgBillingReport.
                 * @implements IOrgBillingReport
                 * @constructor
                 * @param {infinitusai.be.IOrgBillingReport=} [properties] Properties to set
                 */
                function OrgBillingReport(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgBillingReport createdOnMillis.
                 * @member {number|Long} createdOnMillis
                 * @memberof infinitusai.be.OrgBillingReport
                 * @instance
                 */
                OrgBillingReport.prototype.createdOnMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * OrgBillingReport gcsFilePath.
                 * @member {string} gcsFilePath
                 * @memberof infinitusai.be.OrgBillingReport
                 * @instance
                 */
                OrgBillingReport.prototype.gcsFilePath = "";
    
                /**
                 * OrgBillingReport month.
                 * @member {string} month
                 * @memberof infinitusai.be.OrgBillingReport
                 * @instance
                 */
                OrgBillingReport.prototype.month = "";
    
                /**
                 * OrgBillingReport year.
                 * @member {string} year
                 * @memberof infinitusai.be.OrgBillingReport
                 * @instance
                 */
                OrgBillingReport.prototype.year = "";
    
                /**
                 * Creates a new OrgBillingReport instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {infinitusai.be.IOrgBillingReport=} [properties] Properties to set
                 * @returns {infinitusai.be.OrgBillingReport} OrgBillingReport instance
                 */
                OrgBillingReport.create = function create(properties) {
                    return new OrgBillingReport(properties);
                };
    
                /**
                 * Encodes the specified OrgBillingReport message. Does not implicitly {@link infinitusai.be.OrgBillingReport.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {infinitusai.be.IOrgBillingReport} message OrgBillingReport message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgBillingReport.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.createdOnMillis != null && Object.hasOwnProperty.call(message, "createdOnMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.createdOnMillis);
                    if (message.gcsFilePath != null && Object.hasOwnProperty.call(message, "gcsFilePath"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.gcsFilePath);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.month);
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.year);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgBillingReport message, length delimited. Does not implicitly {@link infinitusai.be.OrgBillingReport.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {infinitusai.be.IOrgBillingReport} message OrgBillingReport message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgBillingReport.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgBillingReport message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrgBillingReport} OrgBillingReport
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgBillingReport.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrgBillingReport();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.createdOnMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                message.gcsFilePath = reader.string();
                                break;
                            }
                        case 3: {
                                message.month = reader.string();
                                break;
                            }
                        case 4: {
                                message.year = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgBillingReport message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrgBillingReport} OrgBillingReport
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgBillingReport.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgBillingReport message.
                 * @function verify
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgBillingReport.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.createdOnMillis != null && message.hasOwnProperty("createdOnMillis"))
                        if (!$util.isInteger(message.createdOnMillis) && !(message.createdOnMillis && $util.isInteger(message.createdOnMillis.low) && $util.isInteger(message.createdOnMillis.high)))
                            return "createdOnMillis: integer|Long expected";
                    if (message.gcsFilePath != null && message.hasOwnProperty("gcsFilePath"))
                        if (!$util.isString(message.gcsFilePath))
                            return "gcsFilePath: string expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isString(message.month))
                            return "month: string expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isString(message.year))
                            return "year: string expected";
                    return null;
                };
    
                /**
                 * Creates an OrgBillingReport message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrgBillingReport} OrgBillingReport
                 */
                OrgBillingReport.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrgBillingReport)
                        return object;
                    var message = new $root.infinitusai.be.OrgBillingReport();
                    if (object.createdOnMillis != null)
                        if ($util.Long)
                            (message.createdOnMillis = $util.Long.fromValue(object.createdOnMillis)).unsigned = false;
                        else if (typeof object.createdOnMillis === "string")
                            message.createdOnMillis = parseInt(object.createdOnMillis, 10);
                        else if (typeof object.createdOnMillis === "number")
                            message.createdOnMillis = object.createdOnMillis;
                        else if (typeof object.createdOnMillis === "object")
                            message.createdOnMillis = new $util.LongBits(object.createdOnMillis.low >>> 0, object.createdOnMillis.high >>> 0).toNumber();
                    if (object.gcsFilePath != null)
                        message.gcsFilePath = String(object.gcsFilePath);
                    if (object.month != null)
                        message.month = String(object.month);
                    if (object.year != null)
                        message.year = String(object.year);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgBillingReport message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {infinitusai.be.OrgBillingReport} message OrgBillingReport
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgBillingReport.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdOnMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdOnMillis = options.longs === String ? "0" : 0;
                        object.gcsFilePath = "";
                        object.month = "";
                        object.year = "";
                    }
                    if (message.createdOnMillis != null && message.hasOwnProperty("createdOnMillis"))
                        if (typeof message.createdOnMillis === "number")
                            object.createdOnMillis = options.longs === String ? String(message.createdOnMillis) : message.createdOnMillis;
                        else
                            object.createdOnMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdOnMillis) : options.longs === Number ? new $util.LongBits(message.createdOnMillis.low >>> 0, message.createdOnMillis.high >>> 0).toNumber() : message.createdOnMillis;
                    if (message.gcsFilePath != null && message.hasOwnProperty("gcsFilePath"))
                        object.gcsFilePath = message.gcsFilePath;
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    return object;
                };
    
                /**
                 * Converts this OrgBillingReport to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrgBillingReport
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgBillingReport.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgBillingReport
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrgBillingReport
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgBillingReport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrgBillingReport";
                };
    
                return OrgBillingReport;
            })();
    
            be.PortalIssue = (function() {
    
                /**
                 * Properties of a PortalIssue.
                 * @memberof infinitusai.be
                 * @interface IPortalIssue
                 * @property {number|Long|null} [createdOnMillis] PortalIssue createdOnMillis
                 * @property {string|null} [gcsFilePath] PortalIssue gcsFilePath
                 * @property {boolean|null} [onCall] PortalIssue onCall
                 * @property {string|null} [description] PortalIssue description
                 * @property {string|null} [pageUrl] PortalIssue pageUrl
                 * @property {boolean|null} [callPositive] PortalIssue callPositive
                 * @property {string|null} [id] PortalIssue id
                 * @property {Array.<string>|null} [downloadUrls] PortalIssue downloadUrls
                 * @property {infinitusai.be.IssueStatus|null} [status] PortalIssue status
                 * @property {string|null} [logs] PortalIssue logs
                 * @property {string|null} [notes] PortalIssue notes
                 * @property {string|null} [category] PortalIssue category
                 * @property {Array.<string>|null} [upvoters] PortalIssue upvoters
                 * @property {string|null} [submittedByEmail] PortalIssue submittedByEmail
                 */
    
                /**
                 * Constructs a new PortalIssue.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PortalIssue.
                 * @implements IPortalIssue
                 * @constructor
                 * @param {infinitusai.be.IPortalIssue=} [properties] Properties to set
                 */
                function PortalIssue(properties) {
                    this.downloadUrls = [];
                    this.upvoters = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PortalIssue createdOnMillis.
                 * @member {number|Long} createdOnMillis
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.createdOnMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * PortalIssue gcsFilePath.
                 * @member {string} gcsFilePath
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.gcsFilePath = "";
    
                /**
                 * PortalIssue onCall.
                 * @member {boolean} onCall
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.onCall = false;
    
                /**
                 * PortalIssue description.
                 * @member {string} description
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.description = "";
    
                /**
                 * PortalIssue pageUrl.
                 * @member {string} pageUrl
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.pageUrl = "";
    
                /**
                 * PortalIssue callPositive.
                 * @member {boolean} callPositive
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.callPositive = false;
    
                /**
                 * PortalIssue id.
                 * @member {string} id
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.id = "";
    
                /**
                 * PortalIssue downloadUrls.
                 * @member {Array.<string>} downloadUrls
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.downloadUrls = $util.emptyArray;
    
                /**
                 * PortalIssue status.
                 * @member {infinitusai.be.IssueStatus} status
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.status = 0;
    
                /**
                 * PortalIssue logs.
                 * @member {string} logs
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.logs = "";
    
                /**
                 * PortalIssue notes.
                 * @member {string} notes
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.notes = "";
    
                /**
                 * PortalIssue category.
                 * @member {string} category
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.category = "";
    
                /**
                 * PortalIssue upvoters.
                 * @member {Array.<string>} upvoters
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.upvoters = $util.emptyArray;
    
                /**
                 * PortalIssue submittedByEmail.
                 * @member {string} submittedByEmail
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 */
                PortalIssue.prototype.submittedByEmail = "";
    
                /**
                 * Creates a new PortalIssue instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {infinitusai.be.IPortalIssue=} [properties] Properties to set
                 * @returns {infinitusai.be.PortalIssue} PortalIssue instance
                 */
                PortalIssue.create = function create(properties) {
                    return new PortalIssue(properties);
                };
    
                /**
                 * Encodes the specified PortalIssue message. Does not implicitly {@link infinitusai.be.PortalIssue.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {infinitusai.be.IPortalIssue} message PortalIssue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalIssue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.createdOnMillis != null && Object.hasOwnProperty.call(message, "createdOnMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.createdOnMillis);
                    if (message.gcsFilePath != null && Object.hasOwnProperty.call(message, "gcsFilePath"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.gcsFilePath);
                    if (message.onCall != null && Object.hasOwnProperty.call(message, "onCall"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.onCall);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                    if (message.pageUrl != null && Object.hasOwnProperty.call(message, "pageUrl"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.pageUrl);
                    if (message.callPositive != null && Object.hasOwnProperty.call(message, "callPositive"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.callPositive);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.id);
                    if (message.downloadUrls != null && message.downloadUrls.length)
                        for (var i = 0; i < message.downloadUrls.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.downloadUrls[i]);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.status);
                    if (message.logs != null && Object.hasOwnProperty.call(message, "logs"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.logs);
                    if (message.notes != null && Object.hasOwnProperty.call(message, "notes"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.notes);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.category);
                    if (message.upvoters != null && message.upvoters.length)
                        for (var i = 0; i < message.upvoters.length; ++i)
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.upvoters[i]);
                    if (message.submittedByEmail != null && Object.hasOwnProperty.call(message, "submittedByEmail"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.submittedByEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified PortalIssue message, length delimited. Does not implicitly {@link infinitusai.be.PortalIssue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {infinitusai.be.IPortalIssue} message PortalIssue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalIssue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PortalIssue message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PortalIssue} PortalIssue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalIssue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PortalIssue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.createdOnMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                message.gcsFilePath = reader.string();
                                break;
                            }
                        case 3: {
                                message.onCall = reader.bool();
                                break;
                            }
                        case 4: {
                                message.description = reader.string();
                                break;
                            }
                        case 5: {
                                message.pageUrl = reader.string();
                                break;
                            }
                        case 6: {
                                message.callPositive = reader.bool();
                                break;
                            }
                        case 7: {
                                message.id = reader.string();
                                break;
                            }
                        case 8: {
                                if (!(message.downloadUrls && message.downloadUrls.length))
                                    message.downloadUrls = [];
                                message.downloadUrls.push(reader.string());
                                break;
                            }
                        case 9: {
                                message.status = reader.int32();
                                break;
                            }
                        case 10: {
                                message.logs = reader.string();
                                break;
                            }
                        case 11: {
                                message.notes = reader.string();
                                break;
                            }
                        case 12: {
                                message.category = reader.string();
                                break;
                            }
                        case 13: {
                                if (!(message.upvoters && message.upvoters.length))
                                    message.upvoters = [];
                                message.upvoters.push(reader.string());
                                break;
                            }
                        case 14: {
                                message.submittedByEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PortalIssue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PortalIssue} PortalIssue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalIssue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PortalIssue message.
                 * @function verify
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PortalIssue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.createdOnMillis != null && message.hasOwnProperty("createdOnMillis"))
                        if (!$util.isInteger(message.createdOnMillis) && !(message.createdOnMillis && $util.isInteger(message.createdOnMillis.low) && $util.isInteger(message.createdOnMillis.high)))
                            return "createdOnMillis: integer|Long expected";
                    if (message.gcsFilePath != null && message.hasOwnProperty("gcsFilePath"))
                        if (!$util.isString(message.gcsFilePath))
                            return "gcsFilePath: string expected";
                    if (message.onCall != null && message.hasOwnProperty("onCall"))
                        if (typeof message.onCall !== "boolean")
                            return "onCall: boolean expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.pageUrl != null && message.hasOwnProperty("pageUrl"))
                        if (!$util.isString(message.pageUrl))
                            return "pageUrl: string expected";
                    if (message.callPositive != null && message.hasOwnProperty("callPositive"))
                        if (typeof message.callPositive !== "boolean")
                            return "callPositive: boolean expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.downloadUrls != null && message.hasOwnProperty("downloadUrls")) {
                        if (!Array.isArray(message.downloadUrls))
                            return "downloadUrls: array expected";
                        for (var i = 0; i < message.downloadUrls.length; ++i)
                            if (!$util.isString(message.downloadUrls[i]))
                                return "downloadUrls: string[] expected";
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.logs != null && message.hasOwnProperty("logs"))
                        if (!$util.isString(message.logs))
                            return "logs: string expected";
                    if (message.notes != null && message.hasOwnProperty("notes"))
                        if (!$util.isString(message.notes))
                            return "notes: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.upvoters != null && message.hasOwnProperty("upvoters")) {
                        if (!Array.isArray(message.upvoters))
                            return "upvoters: array expected";
                        for (var i = 0; i < message.upvoters.length; ++i)
                            if (!$util.isString(message.upvoters[i]))
                                return "upvoters: string[] expected";
                    }
                    if (message.submittedByEmail != null && message.hasOwnProperty("submittedByEmail"))
                        if (!$util.isString(message.submittedByEmail))
                            return "submittedByEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a PortalIssue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PortalIssue} PortalIssue
                 */
                PortalIssue.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PortalIssue)
                        return object;
                    var message = new $root.infinitusai.be.PortalIssue();
                    if (object.createdOnMillis != null)
                        if ($util.Long)
                            (message.createdOnMillis = $util.Long.fromValue(object.createdOnMillis)).unsigned = false;
                        else if (typeof object.createdOnMillis === "string")
                            message.createdOnMillis = parseInt(object.createdOnMillis, 10);
                        else if (typeof object.createdOnMillis === "number")
                            message.createdOnMillis = object.createdOnMillis;
                        else if (typeof object.createdOnMillis === "object")
                            message.createdOnMillis = new $util.LongBits(object.createdOnMillis.low >>> 0, object.createdOnMillis.high >>> 0).toNumber();
                    if (object.gcsFilePath != null)
                        message.gcsFilePath = String(object.gcsFilePath);
                    if (object.onCall != null)
                        message.onCall = Boolean(object.onCall);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.pageUrl != null)
                        message.pageUrl = String(object.pageUrl);
                    if (object.callPositive != null)
                        message.callPositive = Boolean(object.callPositive);
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.downloadUrls) {
                        if (!Array.isArray(object.downloadUrls))
                            throw TypeError(".infinitusai.be.PortalIssue.downloadUrls: array expected");
                        message.downloadUrls = [];
                        for (var i = 0; i < object.downloadUrls.length; ++i)
                            message.downloadUrls[i] = String(object.downloadUrls[i]);
                    }
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "ISSUE_STATUS_PENDING":
                    case 0:
                        message.status = 0;
                        break;
                    case "ISSUE_STATUS_IN_PROGRESS":
                    case 1:
                        message.status = 1;
                        break;
                    case "ISSUE_STATUS_RESOLVED":
                    case 2:
                        message.status = 2;
                        break;
                    case "ISSUE_STATUS_UNKNOWN":
                    case 3:
                        message.status = 3;
                        break;
                    case "ISSUE_STATUS_NEW":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    if (object.logs != null)
                        message.logs = String(object.logs);
                    if (object.notes != null)
                        message.notes = String(object.notes);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.upvoters) {
                        if (!Array.isArray(object.upvoters))
                            throw TypeError(".infinitusai.be.PortalIssue.upvoters: array expected");
                        message.upvoters = [];
                        for (var i = 0; i < object.upvoters.length; ++i)
                            message.upvoters[i] = String(object.upvoters[i]);
                    }
                    if (object.submittedByEmail != null)
                        message.submittedByEmail = String(object.submittedByEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PortalIssue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {infinitusai.be.PortalIssue} message PortalIssue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PortalIssue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.downloadUrls = [];
                        object.upvoters = [];
                    }
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdOnMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdOnMillis = options.longs === String ? "0" : 0;
                        object.gcsFilePath = "";
                        object.onCall = false;
                        object.description = "";
                        object.pageUrl = "";
                        object.callPositive = false;
                        object.id = "";
                        object.status = options.enums === String ? "ISSUE_STATUS_PENDING" : 0;
                        object.logs = "";
                        object.notes = "";
                        object.category = "";
                        object.submittedByEmail = "";
                    }
                    if (message.createdOnMillis != null && message.hasOwnProperty("createdOnMillis"))
                        if (typeof message.createdOnMillis === "number")
                            object.createdOnMillis = options.longs === String ? String(message.createdOnMillis) : message.createdOnMillis;
                        else
                            object.createdOnMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdOnMillis) : options.longs === Number ? new $util.LongBits(message.createdOnMillis.low >>> 0, message.createdOnMillis.high >>> 0).toNumber() : message.createdOnMillis;
                    if (message.gcsFilePath != null && message.hasOwnProperty("gcsFilePath"))
                        object.gcsFilePath = message.gcsFilePath;
                    if (message.onCall != null && message.hasOwnProperty("onCall"))
                        object.onCall = message.onCall;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.pageUrl != null && message.hasOwnProperty("pageUrl"))
                        object.pageUrl = message.pageUrl;
                    if (message.callPositive != null && message.hasOwnProperty("callPositive"))
                        object.callPositive = message.callPositive;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.downloadUrls && message.downloadUrls.length) {
                        object.downloadUrls = [];
                        for (var j = 0; j < message.downloadUrls.length; ++j)
                            object.downloadUrls[j] = message.downloadUrls[j];
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.IssueStatus[message.status] === undefined ? message.status : $root.infinitusai.be.IssueStatus[message.status] : message.status;
                    if (message.logs != null && message.hasOwnProperty("logs"))
                        object.logs = message.logs;
                    if (message.notes != null && message.hasOwnProperty("notes"))
                        object.notes = message.notes;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.upvoters && message.upvoters.length) {
                        object.upvoters = [];
                        for (var j = 0; j < message.upvoters.length; ++j)
                            object.upvoters[j] = message.upvoters[j];
                    }
                    if (message.submittedByEmail != null && message.hasOwnProperty("submittedByEmail"))
                        object.submittedByEmail = message.submittedByEmail;
                    return object;
                };
    
                /**
                 * Converts this PortalIssue to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PortalIssue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PortalIssue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PortalIssue
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PortalIssue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PortalIssue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PortalIssue";
                };
    
                return PortalIssue;
            })();
    
            be.OrgLevelEbvConfig = (function() {
    
                /**
                 * Properties of an OrgLevelEbvConfig.
                 * @memberof infinitusai.be
                 * @interface IOrgLevelEbvConfig
                 * @property {boolean|null} [enabled] OrgLevelEbvConfig enabled
                 * @property {boolean|null} [enabledDynamicStatspie] OrgLevelEbvConfig enabledDynamicStatspie
                 * @property {number|null} [holdbackProbability] OrgLevelEbvConfig holdbackProbability
                 * @property {Array.<string>|null} [disabledFields] OrgLevelEbvConfig disabledFields
                 * @property {number|null} [autoReviewHoldoutProbability] OrgLevelEbvConfig autoReviewHoldoutProbability
                 * @property {boolean|null} [enableLocalProductCoverage] OrgLevelEbvConfig enableLocalProductCoverage
                 * @property {boolean|null} [enableOnlyNoHoldback] OrgLevelEbvConfig enableOnlyNoHoldback
                 * @property {boolean|null} [enableProductCoverage] OrgLevelEbvConfig enableProductCoverage
                 * @property {boolean|null} [enablePracticeParticipation] OrgLevelEbvConfig enablePracticeParticipation
                 */
    
                /**
                 * Constructs a new OrgLevelEbvConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrgLevelEbvConfig.
                 * @implements IOrgLevelEbvConfig
                 * @constructor
                 * @param {infinitusai.be.IOrgLevelEbvConfig=} [properties] Properties to set
                 */
                function OrgLevelEbvConfig(properties) {
                    this.disabledFields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgLevelEbvConfig enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.enabled = false;
    
                /**
                 * OrgLevelEbvConfig enabledDynamicStatspie.
                 * @member {boolean} enabledDynamicStatspie
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.enabledDynamicStatspie = false;
    
                /**
                 * OrgLevelEbvConfig holdbackProbability.
                 * @member {number} holdbackProbability
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.holdbackProbability = 0;
    
                /**
                 * OrgLevelEbvConfig disabledFields.
                 * @member {Array.<string>} disabledFields
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.disabledFields = $util.emptyArray;
    
                /**
                 * OrgLevelEbvConfig autoReviewHoldoutProbability.
                 * @member {number} autoReviewHoldoutProbability
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.autoReviewHoldoutProbability = 0;
    
                /**
                 * OrgLevelEbvConfig enableLocalProductCoverage.
                 * @member {boolean} enableLocalProductCoverage
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.enableLocalProductCoverage = false;
    
                /**
                 * OrgLevelEbvConfig enableOnlyNoHoldback.
                 * @member {boolean} enableOnlyNoHoldback
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.enableOnlyNoHoldback = false;
    
                /**
                 * OrgLevelEbvConfig enableProductCoverage.
                 * @member {boolean} enableProductCoverage
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.enableProductCoverage = false;
    
                /**
                 * OrgLevelEbvConfig enablePracticeParticipation.
                 * @member {boolean} enablePracticeParticipation
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 */
                OrgLevelEbvConfig.prototype.enablePracticeParticipation = false;
    
                /**
                 * Creates a new OrgLevelEbvConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {infinitusai.be.IOrgLevelEbvConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.OrgLevelEbvConfig} OrgLevelEbvConfig instance
                 */
                OrgLevelEbvConfig.create = function create(properties) {
                    return new OrgLevelEbvConfig(properties);
                };
    
                /**
                 * Encodes the specified OrgLevelEbvConfig message. Does not implicitly {@link infinitusai.be.OrgLevelEbvConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {infinitusai.be.IOrgLevelEbvConfig} message OrgLevelEbvConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgLevelEbvConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
                    if (message.enabledDynamicStatspie != null && Object.hasOwnProperty.call(message, "enabledDynamicStatspie"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.enabledDynamicStatspie);
                    if (message.holdbackProbability != null && Object.hasOwnProperty.call(message, "holdbackProbability"))
                        writer.uint32(/* id 3, wireType 5 =*/29).float(message.holdbackProbability);
                    if (message.disabledFields != null && message.disabledFields.length)
                        for (var i = 0; i < message.disabledFields.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.disabledFields[i]);
                    if (message.autoReviewHoldoutProbability != null && Object.hasOwnProperty.call(message, "autoReviewHoldoutProbability"))
                        writer.uint32(/* id 5, wireType 5 =*/45).float(message.autoReviewHoldoutProbability);
                    if (message.enableLocalProductCoverage != null && Object.hasOwnProperty.call(message, "enableLocalProductCoverage"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.enableLocalProductCoverage);
                    if (message.enableOnlyNoHoldback != null && Object.hasOwnProperty.call(message, "enableOnlyNoHoldback"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.enableOnlyNoHoldback);
                    if (message.enableProductCoverage != null && Object.hasOwnProperty.call(message, "enableProductCoverage"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.enableProductCoverage);
                    if (message.enablePracticeParticipation != null && Object.hasOwnProperty.call(message, "enablePracticeParticipation"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.enablePracticeParticipation);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgLevelEbvConfig message, length delimited. Does not implicitly {@link infinitusai.be.OrgLevelEbvConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {infinitusai.be.IOrgLevelEbvConfig} message OrgLevelEbvConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgLevelEbvConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgLevelEbvConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrgLevelEbvConfig} OrgLevelEbvConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgLevelEbvConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrgLevelEbvConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 2: {
                                message.enabledDynamicStatspie = reader.bool();
                                break;
                            }
                        case 3: {
                                message.holdbackProbability = reader.float();
                                break;
                            }
                        case 4: {
                                if (!(message.disabledFields && message.disabledFields.length))
                                    message.disabledFields = [];
                                message.disabledFields.push(reader.string());
                                break;
                            }
                        case 5: {
                                message.autoReviewHoldoutProbability = reader.float();
                                break;
                            }
                        case 6: {
                                message.enableLocalProductCoverage = reader.bool();
                                break;
                            }
                        case 7: {
                                message.enableOnlyNoHoldback = reader.bool();
                                break;
                            }
                        case 8: {
                                message.enableProductCoverage = reader.bool();
                                break;
                            }
                        case 9: {
                                message.enablePracticeParticipation = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgLevelEbvConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrgLevelEbvConfig} OrgLevelEbvConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgLevelEbvConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgLevelEbvConfig message.
                 * @function verify
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgLevelEbvConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.enabledDynamicStatspie != null && message.hasOwnProperty("enabledDynamicStatspie"))
                        if (typeof message.enabledDynamicStatspie !== "boolean")
                            return "enabledDynamicStatspie: boolean expected";
                    if (message.holdbackProbability != null && message.hasOwnProperty("holdbackProbability"))
                        if (typeof message.holdbackProbability !== "number")
                            return "holdbackProbability: number expected";
                    if (message.disabledFields != null && message.hasOwnProperty("disabledFields")) {
                        if (!Array.isArray(message.disabledFields))
                            return "disabledFields: array expected";
                        for (var i = 0; i < message.disabledFields.length; ++i)
                            if (!$util.isString(message.disabledFields[i]))
                                return "disabledFields: string[] expected";
                    }
                    if (message.autoReviewHoldoutProbability != null && message.hasOwnProperty("autoReviewHoldoutProbability"))
                        if (typeof message.autoReviewHoldoutProbability !== "number")
                            return "autoReviewHoldoutProbability: number expected";
                    if (message.enableLocalProductCoverage != null && message.hasOwnProperty("enableLocalProductCoverage"))
                        if (typeof message.enableLocalProductCoverage !== "boolean")
                            return "enableLocalProductCoverage: boolean expected";
                    if (message.enableOnlyNoHoldback != null && message.hasOwnProperty("enableOnlyNoHoldback"))
                        if (typeof message.enableOnlyNoHoldback !== "boolean")
                            return "enableOnlyNoHoldback: boolean expected";
                    if (message.enableProductCoverage != null && message.hasOwnProperty("enableProductCoverage"))
                        if (typeof message.enableProductCoverage !== "boolean")
                            return "enableProductCoverage: boolean expected";
                    if (message.enablePracticeParticipation != null && message.hasOwnProperty("enablePracticeParticipation"))
                        if (typeof message.enablePracticeParticipation !== "boolean")
                            return "enablePracticeParticipation: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an OrgLevelEbvConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrgLevelEbvConfig} OrgLevelEbvConfig
                 */
                OrgLevelEbvConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrgLevelEbvConfig)
                        return object;
                    var message = new $root.infinitusai.be.OrgLevelEbvConfig();
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.enabledDynamicStatspie != null)
                        message.enabledDynamicStatspie = Boolean(object.enabledDynamicStatspie);
                    if (object.holdbackProbability != null)
                        message.holdbackProbability = Number(object.holdbackProbability);
                    if (object.disabledFields) {
                        if (!Array.isArray(object.disabledFields))
                            throw TypeError(".infinitusai.be.OrgLevelEbvConfig.disabledFields: array expected");
                        message.disabledFields = [];
                        for (var i = 0; i < object.disabledFields.length; ++i)
                            message.disabledFields[i] = String(object.disabledFields[i]);
                    }
                    if (object.autoReviewHoldoutProbability != null)
                        message.autoReviewHoldoutProbability = Number(object.autoReviewHoldoutProbability);
                    if (object.enableLocalProductCoverage != null)
                        message.enableLocalProductCoverage = Boolean(object.enableLocalProductCoverage);
                    if (object.enableOnlyNoHoldback != null)
                        message.enableOnlyNoHoldback = Boolean(object.enableOnlyNoHoldback);
                    if (object.enableProductCoverage != null)
                        message.enableProductCoverage = Boolean(object.enableProductCoverage);
                    if (object.enablePracticeParticipation != null)
                        message.enablePracticeParticipation = Boolean(object.enablePracticeParticipation);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgLevelEbvConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {infinitusai.be.OrgLevelEbvConfig} message OrgLevelEbvConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgLevelEbvConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.disabledFields = [];
                    if (options.defaults) {
                        object.enabled = false;
                        object.enabledDynamicStatspie = false;
                        object.holdbackProbability = 0;
                        object.autoReviewHoldoutProbability = 0;
                        object.enableLocalProductCoverage = false;
                        object.enableOnlyNoHoldback = false;
                        object.enableProductCoverage = false;
                        object.enablePracticeParticipation = false;
                    }
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.enabledDynamicStatspie != null && message.hasOwnProperty("enabledDynamicStatspie"))
                        object.enabledDynamicStatspie = message.enabledDynamicStatspie;
                    if (message.holdbackProbability != null && message.hasOwnProperty("holdbackProbability"))
                        object.holdbackProbability = options.json && !isFinite(message.holdbackProbability) ? String(message.holdbackProbability) : message.holdbackProbability;
                    if (message.disabledFields && message.disabledFields.length) {
                        object.disabledFields = [];
                        for (var j = 0; j < message.disabledFields.length; ++j)
                            object.disabledFields[j] = message.disabledFields[j];
                    }
                    if (message.autoReviewHoldoutProbability != null && message.hasOwnProperty("autoReviewHoldoutProbability"))
                        object.autoReviewHoldoutProbability = options.json && !isFinite(message.autoReviewHoldoutProbability) ? String(message.autoReviewHoldoutProbability) : message.autoReviewHoldoutProbability;
                    if (message.enableLocalProductCoverage != null && message.hasOwnProperty("enableLocalProductCoverage"))
                        object.enableLocalProductCoverage = message.enableLocalProductCoverage;
                    if (message.enableOnlyNoHoldback != null && message.hasOwnProperty("enableOnlyNoHoldback"))
                        object.enableOnlyNoHoldback = message.enableOnlyNoHoldback;
                    if (message.enableProductCoverage != null && message.hasOwnProperty("enableProductCoverage"))
                        object.enableProductCoverage = message.enableProductCoverage;
                    if (message.enablePracticeParticipation != null && message.hasOwnProperty("enablePracticeParticipation"))
                        object.enablePracticeParticipation = message.enablePracticeParticipation;
                    return object;
                };
    
                /**
                 * Converts this OrgLevelEbvConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgLevelEbvConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgLevelEbvConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrgLevelEbvConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgLevelEbvConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrgLevelEbvConfig";
                };
    
                return OrgLevelEbvConfig;
            })();
    
            be.OrgEbvConfig = (function() {
    
                /**
                 * Properties of an OrgEbvConfig.
                 * @memberof infinitusai.be
                 * @interface IOrgEbvConfig
                 * @property {boolean|null} [enabledDynamicStatspie] OrgEbvConfig enabledDynamicStatspie
                 * @property {boolean|null} [enableMock] OrgEbvConfig enableMock
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [chcConfig] OrgEbvConfig chcConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [statPieConfig] OrgEbvConfig statPieConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [payerApiConfig] OrgEbvConfig payerApiConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [pverifyApiConfig] OrgEbvConfig pverifyApiConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [pbmPayerApiConfig] OrgEbvConfig pbmPayerApiConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [availityConfig] OrgEbvConfig availityConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [cmsConfig] OrgEbvConfig cmsConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [bcbsConfig] OrgEbvConfig bcbsConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [arriveHealthConfig] OrgEbvConfig arriveHealthConfig
                 * @property {infinitusai.be.IOrgLevelEbvConfig|null} [kgInternalConfig] OrgEbvConfig kgInternalConfig
                 */
    
                /**
                 * Constructs a new OrgEbvConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrgEbvConfig.
                 * @implements IOrgEbvConfig
                 * @constructor
                 * @param {infinitusai.be.IOrgEbvConfig=} [properties] Properties to set
                 */
                function OrgEbvConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgEbvConfig enabledDynamicStatspie.
                 * @member {boolean} enabledDynamicStatspie
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.enabledDynamicStatspie = false;
    
                /**
                 * OrgEbvConfig enableMock.
                 * @member {boolean} enableMock
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.enableMock = false;
    
                /**
                 * OrgEbvConfig chcConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} chcConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.chcConfig = null;
    
                /**
                 * OrgEbvConfig statPieConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} statPieConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.statPieConfig = null;
    
                /**
                 * OrgEbvConfig payerApiConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} payerApiConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.payerApiConfig = null;
    
                /**
                 * OrgEbvConfig pverifyApiConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} pverifyApiConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.pverifyApiConfig = null;
    
                /**
                 * OrgEbvConfig pbmPayerApiConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} pbmPayerApiConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.pbmPayerApiConfig = null;
    
                /**
                 * OrgEbvConfig availityConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} availityConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.availityConfig = null;
    
                /**
                 * OrgEbvConfig cmsConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} cmsConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.cmsConfig = null;
    
                /**
                 * OrgEbvConfig bcbsConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} bcbsConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.bcbsConfig = null;
    
                /**
                 * OrgEbvConfig arriveHealthConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} arriveHealthConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.arriveHealthConfig = null;
    
                /**
                 * OrgEbvConfig kgInternalConfig.
                 * @member {infinitusai.be.IOrgLevelEbvConfig|null|undefined} kgInternalConfig
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 */
                OrgEbvConfig.prototype.kgInternalConfig = null;
    
                /**
                 * Creates a new OrgEbvConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {infinitusai.be.IOrgEbvConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.OrgEbvConfig} OrgEbvConfig instance
                 */
                OrgEbvConfig.create = function create(properties) {
                    return new OrgEbvConfig(properties);
                };
    
                /**
                 * Encodes the specified OrgEbvConfig message. Does not implicitly {@link infinitusai.be.OrgEbvConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {infinitusai.be.IOrgEbvConfig} message OrgEbvConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgEbvConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.enableMock != null && Object.hasOwnProperty.call(message, "enableMock"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.enableMock);
                    if (message.enabledDynamicStatspie != null && Object.hasOwnProperty.call(message, "enabledDynamicStatspie"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.enabledDynamicStatspie);
                    if (message.chcConfig != null && Object.hasOwnProperty.call(message, "chcConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.chcConfig, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.statPieConfig != null && Object.hasOwnProperty.call(message, "statPieConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.statPieConfig, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.payerApiConfig != null && Object.hasOwnProperty.call(message, "payerApiConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.payerApiConfig, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.pverifyApiConfig != null && Object.hasOwnProperty.call(message, "pverifyApiConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.pverifyApiConfig, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.pbmPayerApiConfig != null && Object.hasOwnProperty.call(message, "pbmPayerApiConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.pbmPayerApiConfig, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.availityConfig != null && Object.hasOwnProperty.call(message, "availityConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.availityConfig, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.cmsConfig != null && Object.hasOwnProperty.call(message, "cmsConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.cmsConfig, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.bcbsConfig != null && Object.hasOwnProperty.call(message, "bcbsConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.bcbsConfig, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.arriveHealthConfig != null && Object.hasOwnProperty.call(message, "arriveHealthConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.arriveHealthConfig, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.kgInternalConfig != null && Object.hasOwnProperty.call(message, "kgInternalConfig"))
                        $root.infinitusai.be.OrgLevelEbvConfig.encode(message.kgInternalConfig, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgEbvConfig message, length delimited. Does not implicitly {@link infinitusai.be.OrgEbvConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {infinitusai.be.IOrgEbvConfig} message OrgEbvConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgEbvConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgEbvConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrgEbvConfig} OrgEbvConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgEbvConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrgEbvConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 6: {
                                message.enabledDynamicStatspie = reader.bool();
                                break;
                            }
                        case 5: {
                                message.enableMock = reader.bool();
                                break;
                            }
                        case 7: {
                                message.chcConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.statPieConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.payerApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.pverifyApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.pbmPayerApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.availityConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.cmsConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.bcbsConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.arriveHealthConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.kgInternalConfig = $root.infinitusai.be.OrgLevelEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgEbvConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrgEbvConfig} OrgEbvConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgEbvConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgEbvConfig message.
                 * @function verify
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgEbvConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.enabledDynamicStatspie != null && message.hasOwnProperty("enabledDynamicStatspie"))
                        if (typeof message.enabledDynamicStatspie !== "boolean")
                            return "enabledDynamicStatspie: boolean expected";
                    if (message.enableMock != null && message.hasOwnProperty("enableMock"))
                        if (typeof message.enableMock !== "boolean")
                            return "enableMock: boolean expected";
                    if (message.chcConfig != null && message.hasOwnProperty("chcConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.chcConfig);
                        if (error)
                            return "chcConfig." + error;
                    }
                    if (message.statPieConfig != null && message.hasOwnProperty("statPieConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.statPieConfig);
                        if (error)
                            return "statPieConfig." + error;
                    }
                    if (message.payerApiConfig != null && message.hasOwnProperty("payerApiConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.payerApiConfig);
                        if (error)
                            return "payerApiConfig." + error;
                    }
                    if (message.pverifyApiConfig != null && message.hasOwnProperty("pverifyApiConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.pverifyApiConfig);
                        if (error)
                            return "pverifyApiConfig." + error;
                    }
                    if (message.pbmPayerApiConfig != null && message.hasOwnProperty("pbmPayerApiConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.pbmPayerApiConfig);
                        if (error)
                            return "pbmPayerApiConfig." + error;
                    }
                    if (message.availityConfig != null && message.hasOwnProperty("availityConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.availityConfig);
                        if (error)
                            return "availityConfig." + error;
                    }
                    if (message.cmsConfig != null && message.hasOwnProperty("cmsConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.cmsConfig);
                        if (error)
                            return "cmsConfig." + error;
                    }
                    if (message.bcbsConfig != null && message.hasOwnProperty("bcbsConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.bcbsConfig);
                        if (error)
                            return "bcbsConfig." + error;
                    }
                    if (message.arriveHealthConfig != null && message.hasOwnProperty("arriveHealthConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.arriveHealthConfig);
                        if (error)
                            return "arriveHealthConfig." + error;
                    }
                    if (message.kgInternalConfig != null && message.hasOwnProperty("kgInternalConfig")) {
                        var error = $root.infinitusai.be.OrgLevelEbvConfig.verify(message.kgInternalConfig);
                        if (error)
                            return "kgInternalConfig." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an OrgEbvConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrgEbvConfig} OrgEbvConfig
                 */
                OrgEbvConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrgEbvConfig)
                        return object;
                    var message = new $root.infinitusai.be.OrgEbvConfig();
                    if (object.enabledDynamicStatspie != null)
                        message.enabledDynamicStatspie = Boolean(object.enabledDynamicStatspie);
                    if (object.enableMock != null)
                        message.enableMock = Boolean(object.enableMock);
                    if (object.chcConfig != null) {
                        if (typeof object.chcConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.chcConfig: object expected");
                        message.chcConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.chcConfig);
                    }
                    if (object.statPieConfig != null) {
                        if (typeof object.statPieConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.statPieConfig: object expected");
                        message.statPieConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.statPieConfig);
                    }
                    if (object.payerApiConfig != null) {
                        if (typeof object.payerApiConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.payerApiConfig: object expected");
                        message.payerApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.payerApiConfig);
                    }
                    if (object.pverifyApiConfig != null) {
                        if (typeof object.pverifyApiConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.pverifyApiConfig: object expected");
                        message.pverifyApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.pverifyApiConfig);
                    }
                    if (object.pbmPayerApiConfig != null) {
                        if (typeof object.pbmPayerApiConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.pbmPayerApiConfig: object expected");
                        message.pbmPayerApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.pbmPayerApiConfig);
                    }
                    if (object.availityConfig != null) {
                        if (typeof object.availityConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.availityConfig: object expected");
                        message.availityConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.availityConfig);
                    }
                    if (object.cmsConfig != null) {
                        if (typeof object.cmsConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.cmsConfig: object expected");
                        message.cmsConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.cmsConfig);
                    }
                    if (object.bcbsConfig != null) {
                        if (typeof object.bcbsConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.bcbsConfig: object expected");
                        message.bcbsConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.bcbsConfig);
                    }
                    if (object.arriveHealthConfig != null) {
                        if (typeof object.arriveHealthConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.arriveHealthConfig: object expected");
                        message.arriveHealthConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.arriveHealthConfig);
                    }
                    if (object.kgInternalConfig != null) {
                        if (typeof object.kgInternalConfig !== "object")
                            throw TypeError(".infinitusai.be.OrgEbvConfig.kgInternalConfig: object expected");
                        message.kgInternalConfig = $root.infinitusai.be.OrgLevelEbvConfig.fromObject(object.kgInternalConfig);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgEbvConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {infinitusai.be.OrgEbvConfig} message OrgEbvConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgEbvConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.enableMock = false;
                        object.enabledDynamicStatspie = false;
                        object.chcConfig = null;
                        object.statPieConfig = null;
                        object.payerApiConfig = null;
                        object.pverifyApiConfig = null;
                        object.pbmPayerApiConfig = null;
                        object.availityConfig = null;
                        object.cmsConfig = null;
                        object.bcbsConfig = null;
                        object.arriveHealthConfig = null;
                        object.kgInternalConfig = null;
                    }
                    if (message.enableMock != null && message.hasOwnProperty("enableMock"))
                        object.enableMock = message.enableMock;
                    if (message.enabledDynamicStatspie != null && message.hasOwnProperty("enabledDynamicStatspie"))
                        object.enabledDynamicStatspie = message.enabledDynamicStatspie;
                    if (message.chcConfig != null && message.hasOwnProperty("chcConfig"))
                        object.chcConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.chcConfig, options);
                    if (message.statPieConfig != null && message.hasOwnProperty("statPieConfig"))
                        object.statPieConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.statPieConfig, options);
                    if (message.payerApiConfig != null && message.hasOwnProperty("payerApiConfig"))
                        object.payerApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.payerApiConfig, options);
                    if (message.pverifyApiConfig != null && message.hasOwnProperty("pverifyApiConfig"))
                        object.pverifyApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.pverifyApiConfig, options);
                    if (message.pbmPayerApiConfig != null && message.hasOwnProperty("pbmPayerApiConfig"))
                        object.pbmPayerApiConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.pbmPayerApiConfig, options);
                    if (message.availityConfig != null && message.hasOwnProperty("availityConfig"))
                        object.availityConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.availityConfig, options);
                    if (message.cmsConfig != null && message.hasOwnProperty("cmsConfig"))
                        object.cmsConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.cmsConfig, options);
                    if (message.bcbsConfig != null && message.hasOwnProperty("bcbsConfig"))
                        object.bcbsConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.bcbsConfig, options);
                    if (message.arriveHealthConfig != null && message.hasOwnProperty("arriveHealthConfig"))
                        object.arriveHealthConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.arriveHealthConfig, options);
                    if (message.kgInternalConfig != null && message.hasOwnProperty("kgInternalConfig"))
                        object.kgInternalConfig = $root.infinitusai.be.OrgLevelEbvConfig.toObject(message.kgInternalConfig, options);
                    return object;
                };
    
                /**
                 * Converts this OrgEbvConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgEbvConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgEbvConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrgEbvConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgEbvConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrgEbvConfig";
                };
    
                return OrgEbvConfig;
            })();
    
            /**
             * SpeechToTextProvider enum.
             * @name infinitusai.be.SpeechToTextProvider
             * @enum {number}
             * @property {number} STT_PROVIDER_UNKNOWN=0 STT_PROVIDER_UNKNOWN value
             * @property {number} STT_PROVIDER_GOOGLE_CLOUD=1 STT_PROVIDER_GOOGLE_CLOUD value
             * @property {number} STT_PROVIDER_DEEPGRAM=2 STT_PROVIDER_DEEPGRAM value
             */
            be.SpeechToTextProvider = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STT_PROVIDER_UNKNOWN"] = 0;
                values[valuesById[1] = "STT_PROVIDER_GOOGLE_CLOUD"] = 1;
                values[valuesById[2] = "STT_PROVIDER_DEEPGRAM"] = 2;
                return values;
            })();
    
            /**
             * StartRoutingOperator enum.
             * @name infinitusai.be.StartRoutingOperator
             * @enum {number}
             * @property {number} ON_PERSON_AUTH=0 ON_PERSON_AUTH value
             * @property {number} ON_PERSON_GET_INFO=1 ON_PERSON_GET_INFO value
             * @property {number} ON_NLP_SERVER=2 ON_NLP_SERVER value
             */
            be.StartRoutingOperator = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ON_PERSON_AUTH"] = 0;
                values[valuesById[1] = "ON_PERSON_GET_INFO"] = 1;
                values[valuesById[2] = "ON_NLP_SERVER"] = 2;
                return values;
            })();
    
            be.StartDynamicRoutingAtABTestConfig = (function() {
    
                /**
                 * Properties of a StartDynamicRoutingAtABTestConfig.
                 * @memberof infinitusai.be
                 * @interface IStartDynamicRoutingAtABTestConfig
                 * @property {number|Long|null} [startPercentage] StartDynamicRoutingAtABTestConfig startPercentage
                 * @property {number|Long|null} [endPercentage] StartDynamicRoutingAtABTestConfig endPercentage
                 * @property {string|null} [startDate] StartDynamicRoutingAtABTestConfig startDate
                 * @property {string|null} [endDate] StartDynamicRoutingAtABTestConfig endDate
                 * @property {string|null} [description] StartDynamicRoutingAtABTestConfig description
                 */
    
                /**
                 * Constructs a new StartDynamicRoutingAtABTestConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StartDynamicRoutingAtABTestConfig.
                 * @implements IStartDynamicRoutingAtABTestConfig
                 * @constructor
                 * @param {infinitusai.be.IStartDynamicRoutingAtABTestConfig=} [properties] Properties to set
                 */
                function StartDynamicRoutingAtABTestConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StartDynamicRoutingAtABTestConfig startPercentage.
                 * @member {number|Long} startPercentage
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @instance
                 */
                StartDynamicRoutingAtABTestConfig.prototype.startPercentage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StartDynamicRoutingAtABTestConfig endPercentage.
                 * @member {number|Long} endPercentage
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @instance
                 */
                StartDynamicRoutingAtABTestConfig.prototype.endPercentage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StartDynamicRoutingAtABTestConfig startDate.
                 * @member {string} startDate
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @instance
                 */
                StartDynamicRoutingAtABTestConfig.prototype.startDate = "";
    
                /**
                 * StartDynamicRoutingAtABTestConfig endDate.
                 * @member {string} endDate
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @instance
                 */
                StartDynamicRoutingAtABTestConfig.prototype.endDate = "";
    
                /**
                 * StartDynamicRoutingAtABTestConfig description.
                 * @member {string} description
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @instance
                 */
                StartDynamicRoutingAtABTestConfig.prototype.description = "";
    
                /**
                 * Creates a new StartDynamicRoutingAtABTestConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {infinitusai.be.IStartDynamicRoutingAtABTestConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.StartDynamicRoutingAtABTestConfig} StartDynamicRoutingAtABTestConfig instance
                 */
                StartDynamicRoutingAtABTestConfig.create = function create(properties) {
                    return new StartDynamicRoutingAtABTestConfig(properties);
                };
    
                /**
                 * Encodes the specified StartDynamicRoutingAtABTestConfig message. Does not implicitly {@link infinitusai.be.StartDynamicRoutingAtABTestConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {infinitusai.be.IStartDynamicRoutingAtABTestConfig} message StartDynamicRoutingAtABTestConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartDynamicRoutingAtABTestConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.startPercentage != null && Object.hasOwnProperty.call(message, "startPercentage"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.startPercentage);
                    if (message.endPercentage != null && Object.hasOwnProperty.call(message, "endPercentage"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.endPercentage);
                    if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.startDate);
                    if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.endDate);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                    return writer;
                };
    
                /**
                 * Encodes the specified StartDynamicRoutingAtABTestConfig message, length delimited. Does not implicitly {@link infinitusai.be.StartDynamicRoutingAtABTestConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {infinitusai.be.IStartDynamicRoutingAtABTestConfig} message StartDynamicRoutingAtABTestConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartDynamicRoutingAtABTestConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StartDynamicRoutingAtABTestConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StartDynamicRoutingAtABTestConfig} StartDynamicRoutingAtABTestConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartDynamicRoutingAtABTestConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StartDynamicRoutingAtABTestConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.startPercentage = reader.int64();
                                break;
                            }
                        case 2: {
                                message.endPercentage = reader.int64();
                                break;
                            }
                        case 3: {
                                message.startDate = reader.string();
                                break;
                            }
                        case 4: {
                                message.endDate = reader.string();
                                break;
                            }
                        case 5: {
                                message.description = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StartDynamicRoutingAtABTestConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StartDynamicRoutingAtABTestConfig} StartDynamicRoutingAtABTestConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartDynamicRoutingAtABTestConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StartDynamicRoutingAtABTestConfig message.
                 * @function verify
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartDynamicRoutingAtABTestConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.startPercentage != null && message.hasOwnProperty("startPercentage"))
                        if (!$util.isInteger(message.startPercentage) && !(message.startPercentage && $util.isInteger(message.startPercentage.low) && $util.isInteger(message.startPercentage.high)))
                            return "startPercentage: integer|Long expected";
                    if (message.endPercentage != null && message.hasOwnProperty("endPercentage"))
                        if (!$util.isInteger(message.endPercentage) && !(message.endPercentage && $util.isInteger(message.endPercentage.low) && $util.isInteger(message.endPercentage.high)))
                            return "endPercentage: integer|Long expected";
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        if (!$util.isString(message.startDate))
                            return "startDate: string expected";
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        if (!$util.isString(message.endDate))
                            return "endDate: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    return null;
                };
    
                /**
                 * Creates a StartDynamicRoutingAtABTestConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StartDynamicRoutingAtABTestConfig} StartDynamicRoutingAtABTestConfig
                 */
                StartDynamicRoutingAtABTestConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StartDynamicRoutingAtABTestConfig)
                        return object;
                    var message = new $root.infinitusai.be.StartDynamicRoutingAtABTestConfig();
                    if (object.startPercentage != null)
                        if ($util.Long)
                            (message.startPercentage = $util.Long.fromValue(object.startPercentage)).unsigned = false;
                        else if (typeof object.startPercentage === "string")
                            message.startPercentage = parseInt(object.startPercentage, 10);
                        else if (typeof object.startPercentage === "number")
                            message.startPercentage = object.startPercentage;
                        else if (typeof object.startPercentage === "object")
                            message.startPercentage = new $util.LongBits(object.startPercentage.low >>> 0, object.startPercentage.high >>> 0).toNumber();
                    if (object.endPercentage != null)
                        if ($util.Long)
                            (message.endPercentage = $util.Long.fromValue(object.endPercentage)).unsigned = false;
                        else if (typeof object.endPercentage === "string")
                            message.endPercentage = parseInt(object.endPercentage, 10);
                        else if (typeof object.endPercentage === "number")
                            message.endPercentage = object.endPercentage;
                        else if (typeof object.endPercentage === "object")
                            message.endPercentage = new $util.LongBits(object.endPercentage.low >>> 0, object.endPercentage.high >>> 0).toNumber();
                    if (object.startDate != null)
                        message.startDate = String(object.startDate);
                    if (object.endDate != null)
                        message.endDate = String(object.endDate);
                    if (object.description != null)
                        message.description = String(object.description);
                    return message;
                };
    
                /**
                 * Creates a plain object from a StartDynamicRoutingAtABTestConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {infinitusai.be.StartDynamicRoutingAtABTestConfig} message StartDynamicRoutingAtABTestConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartDynamicRoutingAtABTestConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startPercentage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startPercentage = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endPercentage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endPercentage = options.longs === String ? "0" : 0;
                        object.startDate = "";
                        object.endDate = "";
                        object.description = "";
                    }
                    if (message.startPercentage != null && message.hasOwnProperty("startPercentage"))
                        if (typeof message.startPercentage === "number")
                            object.startPercentage = options.longs === String ? String(message.startPercentage) : message.startPercentage;
                        else
                            object.startPercentage = options.longs === String ? $util.Long.prototype.toString.call(message.startPercentage) : options.longs === Number ? new $util.LongBits(message.startPercentage.low >>> 0, message.startPercentage.high >>> 0).toNumber() : message.startPercentage;
                    if (message.endPercentage != null && message.hasOwnProperty("endPercentage"))
                        if (typeof message.endPercentage === "number")
                            object.endPercentage = options.longs === String ? String(message.endPercentage) : message.endPercentage;
                        else
                            object.endPercentage = options.longs === String ? $util.Long.prototype.toString.call(message.endPercentage) : options.longs === Number ? new $util.LongBits(message.endPercentage.low >>> 0, message.endPercentage.high >>> 0).toNumber() : message.endPercentage;
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        object.startDate = message.startDate;
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        object.endDate = message.endDate;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    return object;
                };
    
                /**
                 * Converts this StartDynamicRoutingAtABTestConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartDynamicRoutingAtABTestConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StartDynamicRoutingAtABTestConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StartDynamicRoutingAtABTestConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StartDynamicRoutingAtABTestConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StartDynamicRoutingAtABTestConfig";
                };
    
                return StartDynamicRoutingAtABTestConfig;
            })();
    
            be.EhrIntegrationConfig = (function() {
    
                /**
                 * Properties of an EhrIntegrationConfig.
                 * @memberof infinitusai.be
                 * @interface IEhrIntegrationConfig
                 * @property {string|null} [iss] EhrIntegrationConfig iss
                 * @property {boolean|null} [isIntegrationEnabled] EhrIntegrationConfig isIntegrationEnabled
                 */
    
                /**
                 * Constructs a new EhrIntegrationConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EhrIntegrationConfig.
                 * @implements IEhrIntegrationConfig
                 * @constructor
                 * @param {infinitusai.be.IEhrIntegrationConfig=} [properties] Properties to set
                 */
                function EhrIntegrationConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EhrIntegrationConfig iss.
                 * @member {string} iss
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @instance
                 */
                EhrIntegrationConfig.prototype.iss = "";
    
                /**
                 * EhrIntegrationConfig isIntegrationEnabled.
                 * @member {boolean} isIntegrationEnabled
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @instance
                 */
                EhrIntegrationConfig.prototype.isIntegrationEnabled = false;
    
                /**
                 * Creates a new EhrIntegrationConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {infinitusai.be.IEhrIntegrationConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.EhrIntegrationConfig} EhrIntegrationConfig instance
                 */
                EhrIntegrationConfig.create = function create(properties) {
                    return new EhrIntegrationConfig(properties);
                };
    
                /**
                 * Encodes the specified EhrIntegrationConfig message. Does not implicitly {@link infinitusai.be.EhrIntegrationConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {infinitusai.be.IEhrIntegrationConfig} message EhrIntegrationConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EhrIntegrationConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.iss != null && Object.hasOwnProperty.call(message, "iss"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.iss);
                    if (message.isIntegrationEnabled != null && Object.hasOwnProperty.call(message, "isIntegrationEnabled"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isIntegrationEnabled);
                    return writer;
                };
    
                /**
                 * Encodes the specified EhrIntegrationConfig message, length delimited. Does not implicitly {@link infinitusai.be.EhrIntegrationConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {infinitusai.be.IEhrIntegrationConfig} message EhrIntegrationConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EhrIntegrationConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EhrIntegrationConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EhrIntegrationConfig} EhrIntegrationConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EhrIntegrationConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EhrIntegrationConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.iss = reader.string();
                                break;
                            }
                        case 2: {
                                message.isIntegrationEnabled = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EhrIntegrationConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EhrIntegrationConfig} EhrIntegrationConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EhrIntegrationConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EhrIntegrationConfig message.
                 * @function verify
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EhrIntegrationConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        if (!$util.isString(message.iss))
                            return "iss: string expected";
                    if (message.isIntegrationEnabled != null && message.hasOwnProperty("isIntegrationEnabled"))
                        if (typeof message.isIntegrationEnabled !== "boolean")
                            return "isIntegrationEnabled: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an EhrIntegrationConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EhrIntegrationConfig} EhrIntegrationConfig
                 */
                EhrIntegrationConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EhrIntegrationConfig)
                        return object;
                    var message = new $root.infinitusai.be.EhrIntegrationConfig();
                    if (object.iss != null)
                        message.iss = String(object.iss);
                    if (object.isIntegrationEnabled != null)
                        message.isIntegrationEnabled = Boolean(object.isIntegrationEnabled);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EhrIntegrationConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {infinitusai.be.EhrIntegrationConfig} message EhrIntegrationConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EhrIntegrationConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.iss = "";
                        object.isIntegrationEnabled = false;
                    }
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        object.iss = message.iss;
                    if (message.isIntegrationEnabled != null && message.hasOwnProperty("isIntegrationEnabled"))
                        object.isIntegrationEnabled = message.isIntegrationEnabled;
                    return object;
                };
    
                /**
                 * Converts this EhrIntegrationConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EhrIntegrationConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EhrIntegrationConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EhrIntegrationConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EhrIntegrationConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EhrIntegrationConfig";
                };
    
                return EhrIntegrationConfig;
            })();
    
            be.OrganizationDoc = (function() {
    
                /**
                 * Properties of an OrganizationDoc.
                 * @memberof infinitusai.be
                 * @interface IOrganizationDoc
                 * @property {string|null} [name] OrganizationDoc name
                 * @property {string|null} [imageUrl] OrganizationDoc imageUrl
                 * @property {string|null} [domainName] OrganizationDoc domainName
                 * @property {string|null} [displayName] OrganizationDoc displayName
                 * @property {Array.<infinitusai.be.AuthProvider>|null} [authProviders] OrganizationDoc authProviders
                 * @property {Object.<string,infinitusai.be.IProgramDoc>|null} [programs] OrganizationDoc programs
                 * @property {Object.<string,infinitusai.be.IOrgUser>|null} [users] OrganizationDoc users
                 * @property {boolean|null} [demo] OrganizationDoc demo
                 * @property {string|null} [supportEmail] OrganizationDoc supportEmail
                 * @property {infinitusai.orgs.IOrgCustomization|null} [custom] OrganizationDoc custom
                 * @property {string|null} [callbackNumber] OrganizationDoc callbackNumber
                 * @property {number|null} [maxTasksReadyForCall] OrganizationDoc maxTasksReadyForCall
                 * @property {Array.<string>|null} [emailDomains] OrganizationDoc emailDomains
                 * @property {infinitusai.be.INullableInt32|null} [availableSeats] OrganizationDoc availableSeats
                 * @property {string|null} [defaultProgramId] OrganizationDoc defaultProgramId
                 * @property {number|null} [numBusinessDaysBufferBeforeAdot] OrganizationDoc numBusinessDaysBufferBeforeAdot
                 * @property {Array.<infinitusai.orgs.CustomerPortalFeature>|null} [enabledCustomerPortalFeatures] OrganizationDoc enabledCustomerPortalFeatures
                 * @property {infinitusai.be.SpeechToTextProvider|null} [sttProvider] OrganizationDoc sttProvider
                 * @property {boolean|null} [disableCapacityLimits] OrganizationDoc disableCapacityLimits
                 * @property {Object.<string,number>|null} [dailyCapacityByProgram] OrganizationDoc dailyCapacityByProgram
                 * @property {boolean|null} [disableAnnualReenrollmentDueDates] OrganizationDoc disableAnnualReenrollmentDueDates
                 * @property {string|null} [outboundCallerIdNumber] OrganizationDoc outboundCallerIdNumber
                 * @property {infinitusai.be.IBVCallInputs|null} [defaultBvInputs] OrganizationDoc defaultBvInputs
                 * @property {Array.<string>|null} [restrictedToPayerUuids] OrganizationDoc restrictedToPayerUuids
                 * @property {Array.<infinitusai.orgs.OperatorPortalFeature>|null} [enabledOperatorPortalFeatures] OrganizationDoc enabledOperatorPortalFeatures
                 * @property {Array.<string>|null} [whitelistedIpAddresses] OrganizationDoc whitelistedIpAddresses
                 * @property {infinitusai.be.INullableInt32|null} [daysTasksAvailableForAccess] OrganizationDoc daysTasksAvailableForAccess
                 * @property {number|null} [taskTurnaroundTimeHours] OrganizationDoc taskTurnaroundTimeHours
                 * @property {infinitusai.be.QueueingAlgorithm|null} [queueingAlgorithm] OrganizationDoc queueingAlgorithm
                 * @property {number|null} [taskTurnaroundTimeDays] OrganizationDoc taskTurnaroundTimeDays
                 * @property {Array.<infinitusai.be.IRecordedGreetingTemplate>|null} [recordedGreetingTemplates] OrganizationDoc recordedGreetingTemplates
                 * @property {boolean|null} [usePracticeForHopd] OrganizationDoc usePracticeForHopd
                 * @property {boolean|null} [usePracticeForSpecialistOffice] OrganizationDoc usePracticeForSpecialistOffice
                 * @property {Object.<string,number>|null} [autopilotSecondsByTaskType] OrganizationDoc autopilotSecondsByTaskType
                 * @property {boolean|null} [enableTesting] OrganizationDoc enableTesting
                 * @property {string|null} [chatRoom] OrganizationDoc chatRoom
                 * @property {boolean|null} [disableRecording] OrganizationDoc disableRecording
                 * @property {number|null} [numDaysToRetain] OrganizationDoc numDaysToRetain
                 * @property {infinitusai.be.IOrgEbvConfig|null} [ebvConfig] OrganizationDoc ebvConfig
                 * @property {string|null} [customerUuid] OrganizationDoc customerUuid
                 * @property {boolean|null} [isInternal] OrganizationDoc isInternal
                 * @property {boolean|null} [isLive] OrganizationDoc isLive
                 * @property {boolean|null} [humanVoicesAllowed] OrganizationDoc humanVoicesAllowed
                 * @property {boolean|null} [enableProductCodeMapping] OrganizationDoc enableProductCodeMapping
                 * @property {infinitusai.be.StartRoutingOperator|null} [startRoutingOperator] OrganizationDoc startRoutingOperator
                 * @property {number|null} [maxAttemptsPerTask] OrganizationDoc maxAttemptsPerTask
                 * @property {number|null} [watchdogIntervalSeconds] OrganizationDoc watchdogIntervalSeconds
                 * @property {Array.<infinitusai.be.ApiFeature>|null} [enabledApiFeatures] OrganizationDoc enabledApiFeatures
                 * @property {string|null} [startDynamicRoutingAtDefault] OrganizationDoc startDynamicRoutingAtDefault
                 * @property {Object.<string,infinitusai.be.IStartDynamicRoutingAtABTestConfig>|null} [startDynamicRoutingAt_ABTestConfig] OrganizationDoc startDynamicRoutingAt_ABTestConfig
                 * @property {infinitusai.be.IEhrIntegrationConfig|null} [ehrIntegrationConfig] OrganizationDoc ehrIntegrationConfig
                 * @property {infinitusai.be.IElevenLabsConfigForOrg|null} [elevenLabsConfig] OrganizationDoc elevenLabsConfig
                 * @property {string|null} [voipUrl] OrganizationDoc voipUrl
                 * @property {boolean|null} [disableFetchRecording] OrganizationDoc disableFetchRecording
                 * @property {infinitusai.be.IAudioOptions|null} [audioOptions] OrganizationDoc audioOptions
                 * @property {infinitusai.be.IAutodialerElevenLabsConfigForOrg|null} [autodialerElevenLabsConfig] OrganizationDoc autodialerElevenLabsConfig
                 * @property {number|null} [maxConcurrentCallPerUser] OrganizationDoc maxConcurrentCallPerUser
                 * @property {infinitusai.be.IDate|null} [blizzardEndDate] OrganizationDoc blizzardEndDate
                 * @property {boolean|null} [postProcessingDisabled] OrganizationDoc postProcessingDisabled
                 * @property {boolean|null} [defaultToPracticeInfo] OrganizationDoc defaultToPracticeInfo
                 * @property {boolean|null} [enableIpWhitelistValidation] OrganizationDoc enableIpWhitelistValidation
                 * @property {string|null} [timeSavingsStartDate] OrganizationDoc timeSavingsStartDate
                 * @property {boolean|null} [enableAcousticAutomation] OrganizationDoc enableAcousticAutomation
                 * @property {boolean|null} [excludePhiFromWebhook] OrganizationDoc excludePhiFromWebhook
                 */
    
                /**
                 * Constructs a new OrganizationDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrganizationDoc.
                 * @implements IOrganizationDoc
                 * @constructor
                 * @param {infinitusai.be.IOrganizationDoc=} [properties] Properties to set
                 */
                function OrganizationDoc(properties) {
                    this.authProviders = [];
                    this.programs = {};
                    this.users = {};
                    this.emailDomains = [];
                    this.enabledCustomerPortalFeatures = [];
                    this.dailyCapacityByProgram = {};
                    this.restrictedToPayerUuids = [];
                    this.enabledOperatorPortalFeatures = [];
                    this.whitelistedIpAddresses = [];
                    this.recordedGreetingTemplates = [];
                    this.autopilotSecondsByTaskType = {};
                    this.enabledApiFeatures = [];
                    this.startDynamicRoutingAt_ABTestConfig = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrganizationDoc name.
                 * @member {string} name
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.name = "";
    
                /**
                 * OrganizationDoc imageUrl.
                 * @member {string} imageUrl
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.imageUrl = "";
    
                /**
                 * OrganizationDoc domainName.
                 * @member {string} domainName
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.domainName = "";
    
                /**
                 * OrganizationDoc displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.displayName = "";
    
                /**
                 * OrganizationDoc authProviders.
                 * @member {Array.<infinitusai.be.AuthProvider>} authProviders
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.authProviders = $util.emptyArray;
    
                /**
                 * OrganizationDoc programs.
                 * @member {Object.<string,infinitusai.be.IProgramDoc>} programs
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.programs = $util.emptyObject;
    
                /**
                 * OrganizationDoc users.
                 * @member {Object.<string,infinitusai.be.IOrgUser>} users
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.users = $util.emptyObject;
    
                /**
                 * OrganizationDoc demo.
                 * @member {boolean} demo
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.demo = false;
    
                /**
                 * OrganizationDoc supportEmail.
                 * @member {string} supportEmail
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.supportEmail = "";
    
                /**
                 * OrganizationDoc custom.
                 * @member {infinitusai.orgs.IOrgCustomization|null|undefined} custom
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.custom = null;
    
                /**
                 * OrganizationDoc callbackNumber.
                 * @member {string} callbackNumber
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.callbackNumber = "";
    
                /**
                 * OrganizationDoc maxTasksReadyForCall.
                 * @member {number} maxTasksReadyForCall
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.maxTasksReadyForCall = 0;
    
                /**
                 * OrganizationDoc emailDomains.
                 * @member {Array.<string>} emailDomains
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.emailDomains = $util.emptyArray;
    
                /**
                 * OrganizationDoc availableSeats.
                 * @member {infinitusai.be.INullableInt32|null|undefined} availableSeats
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.availableSeats = null;
    
                /**
                 * OrganizationDoc defaultProgramId.
                 * @member {string} defaultProgramId
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.defaultProgramId = "";
    
                /**
                 * OrganizationDoc numBusinessDaysBufferBeforeAdot.
                 * @member {number} numBusinessDaysBufferBeforeAdot
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.numBusinessDaysBufferBeforeAdot = 0;
    
                /**
                 * OrganizationDoc enabledCustomerPortalFeatures.
                 * @member {Array.<infinitusai.orgs.CustomerPortalFeature>} enabledCustomerPortalFeatures
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.enabledCustomerPortalFeatures = $util.emptyArray;
    
                /**
                 * OrganizationDoc sttProvider.
                 * @member {infinitusai.be.SpeechToTextProvider} sttProvider
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.sttProvider = 0;
    
                /**
                 * OrganizationDoc disableCapacityLimits.
                 * @member {boolean} disableCapacityLimits
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.disableCapacityLimits = false;
    
                /**
                 * OrganizationDoc dailyCapacityByProgram.
                 * @member {Object.<string,number>} dailyCapacityByProgram
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.dailyCapacityByProgram = $util.emptyObject;
    
                /**
                 * OrganizationDoc disableAnnualReenrollmentDueDates.
                 * @member {boolean} disableAnnualReenrollmentDueDates
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.disableAnnualReenrollmentDueDates = false;
    
                /**
                 * OrganizationDoc outboundCallerIdNumber.
                 * @member {string} outboundCallerIdNumber
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.outboundCallerIdNumber = "";
    
                /**
                 * OrganizationDoc defaultBvInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} defaultBvInputs
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.defaultBvInputs = null;
    
                /**
                 * OrganizationDoc restrictedToPayerUuids.
                 * @member {Array.<string>} restrictedToPayerUuids
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.restrictedToPayerUuids = $util.emptyArray;
    
                /**
                 * OrganizationDoc enabledOperatorPortalFeatures.
                 * @member {Array.<infinitusai.orgs.OperatorPortalFeature>} enabledOperatorPortalFeatures
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.enabledOperatorPortalFeatures = $util.emptyArray;
    
                /**
                 * OrganizationDoc whitelistedIpAddresses.
                 * @member {Array.<string>} whitelistedIpAddresses
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.whitelistedIpAddresses = $util.emptyArray;
    
                /**
                 * OrganizationDoc daysTasksAvailableForAccess.
                 * @member {infinitusai.be.INullableInt32|null|undefined} daysTasksAvailableForAccess
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.daysTasksAvailableForAccess = null;
    
                /**
                 * OrganizationDoc taskTurnaroundTimeHours.
                 * @member {number} taskTurnaroundTimeHours
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.taskTurnaroundTimeHours = 0;
    
                /**
                 * OrganizationDoc queueingAlgorithm.
                 * @member {infinitusai.be.QueueingAlgorithm} queueingAlgorithm
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.queueingAlgorithm = 0;
    
                /**
                 * OrganizationDoc taskTurnaroundTimeDays.
                 * @member {number} taskTurnaroundTimeDays
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.taskTurnaroundTimeDays = 0;
    
                /**
                 * OrganizationDoc recordedGreetingTemplates.
                 * @member {Array.<infinitusai.be.IRecordedGreetingTemplate>} recordedGreetingTemplates
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.recordedGreetingTemplates = $util.emptyArray;
    
                /**
                 * OrganizationDoc usePracticeForHopd.
                 * @member {boolean} usePracticeForHopd
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.usePracticeForHopd = false;
    
                /**
                 * OrganizationDoc usePracticeForSpecialistOffice.
                 * @member {boolean} usePracticeForSpecialistOffice
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.usePracticeForSpecialistOffice = false;
    
                /**
                 * OrganizationDoc autopilotSecondsByTaskType.
                 * @member {Object.<string,number>} autopilotSecondsByTaskType
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.autopilotSecondsByTaskType = $util.emptyObject;
    
                /**
                 * OrganizationDoc enableTesting.
                 * @member {boolean} enableTesting
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.enableTesting = false;
    
                /**
                 * OrganizationDoc chatRoom.
                 * @member {string} chatRoom
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.chatRoom = "";
    
                /**
                 * OrganizationDoc disableRecording.
                 * @member {boolean} disableRecording
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.disableRecording = false;
    
                /**
                 * OrganizationDoc numDaysToRetain.
                 * @member {number} numDaysToRetain
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.numDaysToRetain = 0;
    
                /**
                 * OrganizationDoc ebvConfig.
                 * @member {infinitusai.be.IOrgEbvConfig|null|undefined} ebvConfig
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.ebvConfig = null;
    
                /**
                 * OrganizationDoc customerUuid.
                 * @member {string} customerUuid
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.customerUuid = "";
    
                /**
                 * OrganizationDoc isInternal.
                 * @member {boolean} isInternal
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.isInternal = false;
    
                /**
                 * OrganizationDoc isLive.
                 * @member {boolean} isLive
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.isLive = false;
    
                /**
                 * OrganizationDoc humanVoicesAllowed.
                 * @member {boolean} humanVoicesAllowed
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.humanVoicesAllowed = false;
    
                /**
                 * OrganizationDoc enableProductCodeMapping.
                 * @member {boolean} enableProductCodeMapping
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.enableProductCodeMapping = false;
    
                /**
                 * OrganizationDoc startRoutingOperator.
                 * @member {infinitusai.be.StartRoutingOperator} startRoutingOperator
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.startRoutingOperator = 0;
    
                /**
                 * OrganizationDoc maxAttemptsPerTask.
                 * @member {number} maxAttemptsPerTask
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.maxAttemptsPerTask = 0;
    
                /**
                 * OrganizationDoc watchdogIntervalSeconds.
                 * @member {number} watchdogIntervalSeconds
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.watchdogIntervalSeconds = 0;
    
                /**
                 * OrganizationDoc enabledApiFeatures.
                 * @member {Array.<infinitusai.be.ApiFeature>} enabledApiFeatures
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.enabledApiFeatures = $util.emptyArray;
    
                /**
                 * OrganizationDoc startDynamicRoutingAtDefault.
                 * @member {string} startDynamicRoutingAtDefault
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.startDynamicRoutingAtDefault = "";
    
                /**
                 * OrganizationDoc startDynamicRoutingAt_ABTestConfig.
                 * @member {Object.<string,infinitusai.be.IStartDynamicRoutingAtABTestConfig>} startDynamicRoutingAt_ABTestConfig
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.startDynamicRoutingAt_ABTestConfig = $util.emptyObject;
    
                /**
                 * OrganizationDoc ehrIntegrationConfig.
                 * @member {infinitusai.be.IEhrIntegrationConfig|null|undefined} ehrIntegrationConfig
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.ehrIntegrationConfig = null;
    
                /**
                 * OrganizationDoc elevenLabsConfig.
                 * @member {infinitusai.be.IElevenLabsConfigForOrg|null|undefined} elevenLabsConfig
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.elevenLabsConfig = null;
    
                /**
                 * OrganizationDoc voipUrl.
                 * @member {string} voipUrl
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.voipUrl = "";
    
                /**
                 * OrganizationDoc disableFetchRecording.
                 * @member {boolean} disableFetchRecording
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.disableFetchRecording = false;
    
                /**
                 * OrganizationDoc audioOptions.
                 * @member {infinitusai.be.IAudioOptions|null|undefined} audioOptions
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.audioOptions = null;
    
                /**
                 * OrganizationDoc autodialerElevenLabsConfig.
                 * @member {infinitusai.be.IAutodialerElevenLabsConfigForOrg|null|undefined} autodialerElevenLabsConfig
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.autodialerElevenLabsConfig = null;
    
                /**
                 * OrganizationDoc maxConcurrentCallPerUser.
                 * @member {number} maxConcurrentCallPerUser
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.maxConcurrentCallPerUser = 0;
    
                /**
                 * OrganizationDoc blizzardEndDate.
                 * @member {infinitusai.be.IDate|null|undefined} blizzardEndDate
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.blizzardEndDate = null;
    
                /**
                 * OrganizationDoc postProcessingDisabled.
                 * @member {boolean} postProcessingDisabled
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.postProcessingDisabled = false;
    
                /**
                 * OrganizationDoc defaultToPracticeInfo.
                 * @member {boolean} defaultToPracticeInfo
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.defaultToPracticeInfo = false;
    
                /**
                 * OrganizationDoc enableIpWhitelistValidation.
                 * @member {boolean} enableIpWhitelistValidation
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.enableIpWhitelistValidation = false;
    
                /**
                 * OrganizationDoc timeSavingsStartDate.
                 * @member {string} timeSavingsStartDate
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.timeSavingsStartDate = "";
    
                /**
                 * OrganizationDoc enableAcousticAutomation.
                 * @member {boolean} enableAcousticAutomation
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.enableAcousticAutomation = false;
    
                /**
                 * OrganizationDoc excludePhiFromWebhook.
                 * @member {boolean} excludePhiFromWebhook
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 */
                OrganizationDoc.prototype.excludePhiFromWebhook = false;
    
                /**
                 * Creates a new OrganizationDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {infinitusai.be.IOrganizationDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.OrganizationDoc} OrganizationDoc instance
                 */
                OrganizationDoc.create = function create(properties) {
                    return new OrganizationDoc(properties);
                };
    
                /**
                 * Encodes the specified OrganizationDoc message. Does not implicitly {@link infinitusai.be.OrganizationDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {infinitusai.be.IOrganizationDoc} message OrganizationDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrganizationDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageUrl);
                    if (message.domainName != null && Object.hasOwnProperty.call(message, "domainName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.domainName);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.displayName);
                    if (message.authProviders != null && message.authProviders.length) {
                        writer.uint32(/* id 5, wireType 2 =*/42).fork();
                        for (var i = 0; i < message.authProviders.length; ++i)
                            writer.int32(message.authProviders[i]);
                        writer.ldelim();
                    }
                    if (message.programs != null && Object.hasOwnProperty.call(message, "programs"))
                        for (var keys = Object.keys(message.programs), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.ProgramDoc.encode(message.programs[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.users != null && Object.hasOwnProperty.call(message, "users"))
                        for (var keys = Object.keys(message.users), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.OrgUser.encode(message.users[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.demo != null && Object.hasOwnProperty.call(message, "demo"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.demo);
                    if (message.supportEmail != null && Object.hasOwnProperty.call(message, "supportEmail"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.supportEmail);
                    if (message.custom != null && Object.hasOwnProperty.call(message, "custom"))
                        $root.infinitusai.orgs.OrgCustomization.encode(message.custom, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.emailDomains != null && message.emailDomains.length)
                        for (var i = 0; i < message.emailDomains.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.emailDomains[i]);
                    if (message.availableSeats != null && Object.hasOwnProperty.call(message, "availableSeats"))
                        $root.infinitusai.be.NullableInt32.encode(message.availableSeats, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.defaultProgramId != null && Object.hasOwnProperty.call(message, "defaultProgramId"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.defaultProgramId);
                    if (message.callbackNumber != null && Object.hasOwnProperty.call(message, "callbackNumber"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.callbackNumber);
                    if (message.maxTasksReadyForCall != null && Object.hasOwnProperty.call(message, "maxTasksReadyForCall"))
                        writer.uint32(/* id 15, wireType 0 =*/120).int32(message.maxTasksReadyForCall);
                    if (message.numBusinessDaysBufferBeforeAdot != null && Object.hasOwnProperty.call(message, "numBusinessDaysBufferBeforeAdot"))
                        writer.uint32(/* id 16, wireType 0 =*/128).int32(message.numBusinessDaysBufferBeforeAdot);
                    if (message.enabledCustomerPortalFeatures != null && message.enabledCustomerPortalFeatures.length) {
                        writer.uint32(/* id 17, wireType 2 =*/138).fork();
                        for (var i = 0; i < message.enabledCustomerPortalFeatures.length; ++i)
                            writer.int32(message.enabledCustomerPortalFeatures[i]);
                        writer.ldelim();
                    }
                    if (message.disableCapacityLimits != null && Object.hasOwnProperty.call(message, "disableCapacityLimits"))
                        writer.uint32(/* id 19, wireType 0 =*/152).bool(message.disableCapacityLimits);
                    if (message.dailyCapacityByProgram != null && Object.hasOwnProperty.call(message, "dailyCapacityByProgram"))
                        for (var keys = Object.keys(message.dailyCapacityByProgram), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 20, wireType 2 =*/162).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.dailyCapacityByProgram[keys[i]]).ldelim();
                    if (message.disableAnnualReenrollmentDueDates != null && Object.hasOwnProperty.call(message, "disableAnnualReenrollmentDueDates"))
                        writer.uint32(/* id 21, wireType 0 =*/168).bool(message.disableAnnualReenrollmentDueDates);
                    if (message.outboundCallerIdNumber != null && Object.hasOwnProperty.call(message, "outboundCallerIdNumber"))
                        writer.uint32(/* id 22, wireType 2 =*/178).string(message.outboundCallerIdNumber);
                    if (message.defaultBvInputs != null && Object.hasOwnProperty.call(message, "defaultBvInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.defaultBvInputs, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                    if (message.restrictedToPayerUuids != null && message.restrictedToPayerUuids.length)
                        for (var i = 0; i < message.restrictedToPayerUuids.length; ++i)
                            writer.uint32(/* id 24, wireType 2 =*/194).string(message.restrictedToPayerUuids[i]);
                    if (message.sttProvider != null && Object.hasOwnProperty.call(message, "sttProvider"))
                        writer.uint32(/* id 25, wireType 0 =*/200).int32(message.sttProvider);
                    if (message.enabledOperatorPortalFeatures != null && message.enabledOperatorPortalFeatures.length) {
                        writer.uint32(/* id 26, wireType 2 =*/210).fork();
                        for (var i = 0; i < message.enabledOperatorPortalFeatures.length; ++i)
                            writer.int32(message.enabledOperatorPortalFeatures[i]);
                        writer.ldelim();
                    }
                    if (message.whitelistedIpAddresses != null && message.whitelistedIpAddresses.length)
                        for (var i = 0; i < message.whitelistedIpAddresses.length; ++i)
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.whitelistedIpAddresses[i]);
                    if (message.daysTasksAvailableForAccess != null && Object.hasOwnProperty.call(message, "daysTasksAvailableForAccess"))
                        $root.infinitusai.be.NullableInt32.encode(message.daysTasksAvailableForAccess, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                    if (message.taskTurnaroundTimeHours != null && Object.hasOwnProperty.call(message, "taskTurnaroundTimeHours"))
                        writer.uint32(/* id 29, wireType 0 =*/232).int32(message.taskTurnaroundTimeHours);
                    if (message.queueingAlgorithm != null && Object.hasOwnProperty.call(message, "queueingAlgorithm"))
                        writer.uint32(/* id 30, wireType 0 =*/240).int32(message.queueingAlgorithm);
                    if (message.taskTurnaroundTimeDays != null && Object.hasOwnProperty.call(message, "taskTurnaroundTimeDays"))
                        writer.uint32(/* id 31, wireType 0 =*/248).int32(message.taskTurnaroundTimeDays);
                    if (message.recordedGreetingTemplates != null && message.recordedGreetingTemplates.length)
                        for (var i = 0; i < message.recordedGreetingTemplates.length; ++i)
                            $root.infinitusai.be.RecordedGreetingTemplate.encode(message.recordedGreetingTemplates[i], writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                    if (message.usePracticeForHopd != null && Object.hasOwnProperty.call(message, "usePracticeForHopd"))
                        writer.uint32(/* id 35, wireType 0 =*/280).bool(message.usePracticeForHopd);
                    if (message.usePracticeForSpecialistOffice != null && Object.hasOwnProperty.call(message, "usePracticeForSpecialistOffice"))
                        writer.uint32(/* id 36, wireType 0 =*/288).bool(message.usePracticeForSpecialistOffice);
                    if (message.autopilotSecondsByTaskType != null && Object.hasOwnProperty.call(message, "autopilotSecondsByTaskType"))
                        for (var keys = Object.keys(message.autopilotSecondsByTaskType), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 37, wireType 2 =*/298).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.autopilotSecondsByTaskType[keys[i]]).ldelim();
                    if (message.enableTesting != null && Object.hasOwnProperty.call(message, "enableTesting"))
                        writer.uint32(/* id 38, wireType 0 =*/304).bool(message.enableTesting);
                    if (message.chatRoom != null && Object.hasOwnProperty.call(message, "chatRoom"))
                        writer.uint32(/* id 39, wireType 2 =*/314).string(message.chatRoom);
                    if (message.disableRecording != null && Object.hasOwnProperty.call(message, "disableRecording"))
                        writer.uint32(/* id 40, wireType 0 =*/320).bool(message.disableRecording);
                    if (message.numDaysToRetain != null && Object.hasOwnProperty.call(message, "numDaysToRetain"))
                        writer.uint32(/* id 41, wireType 0 =*/328).int32(message.numDaysToRetain);
                    if (message.ebvConfig != null && Object.hasOwnProperty.call(message, "ebvConfig"))
                        $root.infinitusai.be.OrgEbvConfig.encode(message.ebvConfig, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                    if (message.customerUuid != null && Object.hasOwnProperty.call(message, "customerUuid"))
                        writer.uint32(/* id 43, wireType 2 =*/346).string(message.customerUuid);
                    if (message.isInternal != null && Object.hasOwnProperty.call(message, "isInternal"))
                        writer.uint32(/* id 44, wireType 0 =*/352).bool(message.isInternal);
                    if (message.isLive != null && Object.hasOwnProperty.call(message, "isLive"))
                        writer.uint32(/* id 45, wireType 0 =*/360).bool(message.isLive);
                    if (message.humanVoicesAllowed != null && Object.hasOwnProperty.call(message, "humanVoicesAllowed"))
                        writer.uint32(/* id 46, wireType 0 =*/368).bool(message.humanVoicesAllowed);
                    if (message.enableProductCodeMapping != null && Object.hasOwnProperty.call(message, "enableProductCodeMapping"))
                        writer.uint32(/* id 48, wireType 0 =*/384).bool(message.enableProductCodeMapping);
                    if (message.startRoutingOperator != null && Object.hasOwnProperty.call(message, "startRoutingOperator"))
                        writer.uint32(/* id 49, wireType 0 =*/392).int32(message.startRoutingOperator);
                    if (message.maxAttemptsPerTask != null && Object.hasOwnProperty.call(message, "maxAttemptsPerTask"))
                        writer.uint32(/* id 50, wireType 0 =*/400).int32(message.maxAttemptsPerTask);
                    if (message.watchdogIntervalSeconds != null && Object.hasOwnProperty.call(message, "watchdogIntervalSeconds"))
                        writer.uint32(/* id 51, wireType 0 =*/408).int32(message.watchdogIntervalSeconds);
                    if (message.enabledApiFeatures != null && message.enabledApiFeatures.length) {
                        writer.uint32(/* id 52, wireType 2 =*/418).fork();
                        for (var i = 0; i < message.enabledApiFeatures.length; ++i)
                            writer.int32(message.enabledApiFeatures[i]);
                        writer.ldelim();
                    }
                    if (message.startDynamicRoutingAtDefault != null && Object.hasOwnProperty.call(message, "startDynamicRoutingAtDefault"))
                        writer.uint32(/* id 53, wireType 2 =*/426).string(message.startDynamicRoutingAtDefault);
                    if (message.startDynamicRoutingAt_ABTestConfig != null && Object.hasOwnProperty.call(message, "startDynamicRoutingAt_ABTestConfig"))
                        for (var keys = Object.keys(message.startDynamicRoutingAt_ABTestConfig), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 54, wireType 2 =*/434).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.encode(message.startDynamicRoutingAt_ABTestConfig[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.ehrIntegrationConfig != null && Object.hasOwnProperty.call(message, "ehrIntegrationConfig"))
                        $root.infinitusai.be.EhrIntegrationConfig.encode(message.ehrIntegrationConfig, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
                    if (message.elevenLabsConfig != null && Object.hasOwnProperty.call(message, "elevenLabsConfig"))
                        $root.infinitusai.be.ElevenLabsConfigForOrg.encode(message.elevenLabsConfig, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
                    if (message.voipUrl != null && Object.hasOwnProperty.call(message, "voipUrl"))
                        writer.uint32(/* id 57, wireType 2 =*/458).string(message.voipUrl);
                    if (message.disableFetchRecording != null && Object.hasOwnProperty.call(message, "disableFetchRecording"))
                        writer.uint32(/* id 58, wireType 0 =*/464).bool(message.disableFetchRecording);
                    if (message.audioOptions != null && Object.hasOwnProperty.call(message, "audioOptions"))
                        $root.infinitusai.be.AudioOptions.encode(message.audioOptions, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
                    if (message.autodialerElevenLabsConfig != null && Object.hasOwnProperty.call(message, "autodialerElevenLabsConfig"))
                        $root.infinitusai.be.AutodialerElevenLabsConfigForOrg.encode(message.autodialerElevenLabsConfig, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
                    if (message.maxConcurrentCallPerUser != null && Object.hasOwnProperty.call(message, "maxConcurrentCallPerUser"))
                        writer.uint32(/* id 61, wireType 0 =*/488).int32(message.maxConcurrentCallPerUser);
                    if (message.blizzardEndDate != null && Object.hasOwnProperty.call(message, "blizzardEndDate"))
                        $root.infinitusai.be.Date.encode(message.blizzardEndDate, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
                    if (message.postProcessingDisabled != null && Object.hasOwnProperty.call(message, "postProcessingDisabled"))
                        writer.uint32(/* id 63, wireType 0 =*/504).bool(message.postProcessingDisabled);
                    if (message.defaultToPracticeInfo != null && Object.hasOwnProperty.call(message, "defaultToPracticeInfo"))
                        writer.uint32(/* id 64, wireType 0 =*/512).bool(message.defaultToPracticeInfo);
                    if (message.enableIpWhitelistValidation != null && Object.hasOwnProperty.call(message, "enableIpWhitelistValidation"))
                        writer.uint32(/* id 65, wireType 0 =*/520).bool(message.enableIpWhitelistValidation);
                    if (message.timeSavingsStartDate != null && Object.hasOwnProperty.call(message, "timeSavingsStartDate"))
                        writer.uint32(/* id 66, wireType 2 =*/530).string(message.timeSavingsStartDate);
                    if (message.enableAcousticAutomation != null && Object.hasOwnProperty.call(message, "enableAcousticAutomation"))
                        writer.uint32(/* id 67, wireType 0 =*/536).bool(message.enableAcousticAutomation);
                    if (message.excludePhiFromWebhook != null && Object.hasOwnProperty.call(message, "excludePhiFromWebhook"))
                        writer.uint32(/* id 68, wireType 0 =*/544).bool(message.excludePhiFromWebhook);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrganizationDoc message, length delimited. Does not implicitly {@link infinitusai.be.OrganizationDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {infinitusai.be.IOrganizationDoc} message OrganizationDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrganizationDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrganizationDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrganizationDoc} OrganizationDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrganizationDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrganizationDoc(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.imageUrl = reader.string();
                                break;
                            }
                        case 3: {
                                message.domainName = reader.string();
                                break;
                            }
                        case 4: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.authProviders && message.authProviders.length))
                                    message.authProviders = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.authProviders.push(reader.int32());
                                } else
                                    message.authProviders.push(reader.int32());
                                break;
                            }
                        case 6: {
                                if (message.programs === $util.emptyObject)
                                    message.programs = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ProgramDoc.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.programs[key] = value;
                                break;
                            }
                        case 7: {
                                if (message.users === $util.emptyObject)
                                    message.users = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.OrgUser.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.users[key] = value;
                                break;
                            }
                        case 8: {
                                message.demo = reader.bool();
                                break;
                            }
                        case 9: {
                                message.supportEmail = reader.string();
                                break;
                            }
                        case 10: {
                                message.custom = $root.infinitusai.orgs.OrgCustomization.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.callbackNumber = reader.string();
                                break;
                            }
                        case 15: {
                                message.maxTasksReadyForCall = reader.int32();
                                break;
                            }
                        case 11: {
                                if (!(message.emailDomains && message.emailDomains.length))
                                    message.emailDomains = [];
                                message.emailDomains.push(reader.string());
                                break;
                            }
                        case 12: {
                                message.availableSeats = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.defaultProgramId = reader.string();
                                break;
                            }
                        case 16: {
                                message.numBusinessDaysBufferBeforeAdot = reader.int32();
                                break;
                            }
                        case 17: {
                                if (!(message.enabledCustomerPortalFeatures && message.enabledCustomerPortalFeatures.length))
                                    message.enabledCustomerPortalFeatures = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.enabledCustomerPortalFeatures.push(reader.int32());
                                } else
                                    message.enabledCustomerPortalFeatures.push(reader.int32());
                                break;
                            }
                        case 25: {
                                message.sttProvider = reader.int32();
                                break;
                            }
                        case 19: {
                                message.disableCapacityLimits = reader.bool();
                                break;
                            }
                        case 20: {
                                if (message.dailyCapacityByProgram === $util.emptyObject)
                                    message.dailyCapacityByProgram = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.int32();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.dailyCapacityByProgram[key] = value;
                                break;
                            }
                        case 21: {
                                message.disableAnnualReenrollmentDueDates = reader.bool();
                                break;
                            }
                        case 22: {
                                message.outboundCallerIdNumber = reader.string();
                                break;
                            }
                        case 23: {
                                message.defaultBvInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 24: {
                                if (!(message.restrictedToPayerUuids && message.restrictedToPayerUuids.length))
                                    message.restrictedToPayerUuids = [];
                                message.restrictedToPayerUuids.push(reader.string());
                                break;
                            }
                        case 26: {
                                if (!(message.enabledOperatorPortalFeatures && message.enabledOperatorPortalFeatures.length))
                                    message.enabledOperatorPortalFeatures = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.enabledOperatorPortalFeatures.push(reader.int32());
                                } else
                                    message.enabledOperatorPortalFeatures.push(reader.int32());
                                break;
                            }
                        case 27: {
                                if (!(message.whitelistedIpAddresses && message.whitelistedIpAddresses.length))
                                    message.whitelistedIpAddresses = [];
                                message.whitelistedIpAddresses.push(reader.string());
                                break;
                            }
                        case 28: {
                                message.daysTasksAvailableForAccess = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        case 29: {
                                message.taskTurnaroundTimeHours = reader.int32();
                                break;
                            }
                        case 30: {
                                message.queueingAlgorithm = reader.int32();
                                break;
                            }
                        case 31: {
                                message.taskTurnaroundTimeDays = reader.int32();
                                break;
                            }
                        case 33: {
                                if (!(message.recordedGreetingTemplates && message.recordedGreetingTemplates.length))
                                    message.recordedGreetingTemplates = [];
                                message.recordedGreetingTemplates.push($root.infinitusai.be.RecordedGreetingTemplate.decode(reader, reader.uint32()));
                                break;
                            }
                        case 35: {
                                message.usePracticeForHopd = reader.bool();
                                break;
                            }
                        case 36: {
                                message.usePracticeForSpecialistOffice = reader.bool();
                                break;
                            }
                        case 37: {
                                if (message.autopilotSecondsByTaskType === $util.emptyObject)
                                    message.autopilotSecondsByTaskType = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.int32();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.autopilotSecondsByTaskType[key] = value;
                                break;
                            }
                        case 38: {
                                message.enableTesting = reader.bool();
                                break;
                            }
                        case 39: {
                                message.chatRoom = reader.string();
                                break;
                            }
                        case 40: {
                                message.disableRecording = reader.bool();
                                break;
                            }
                        case 41: {
                                message.numDaysToRetain = reader.int32();
                                break;
                            }
                        case 42: {
                                message.ebvConfig = $root.infinitusai.be.OrgEbvConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 43: {
                                message.customerUuid = reader.string();
                                break;
                            }
                        case 44: {
                                message.isInternal = reader.bool();
                                break;
                            }
                        case 45: {
                                message.isLive = reader.bool();
                                break;
                            }
                        case 46: {
                                message.humanVoicesAllowed = reader.bool();
                                break;
                            }
                        case 48: {
                                message.enableProductCodeMapping = reader.bool();
                                break;
                            }
                        case 49: {
                                message.startRoutingOperator = reader.int32();
                                break;
                            }
                        case 50: {
                                message.maxAttemptsPerTask = reader.int32();
                                break;
                            }
                        case 51: {
                                message.watchdogIntervalSeconds = reader.int32();
                                break;
                            }
                        case 52: {
                                if (!(message.enabledApiFeatures && message.enabledApiFeatures.length))
                                    message.enabledApiFeatures = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.enabledApiFeatures.push(reader.int32());
                                } else
                                    message.enabledApiFeatures.push(reader.int32());
                                break;
                            }
                        case 53: {
                                message.startDynamicRoutingAtDefault = reader.string();
                                break;
                            }
                        case 54: {
                                if (message.startDynamicRoutingAt_ABTestConfig === $util.emptyObject)
                                    message.startDynamicRoutingAt_ABTestConfig = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.startDynamicRoutingAt_ABTestConfig[key] = value;
                                break;
                            }
                        case 55: {
                                message.ehrIntegrationConfig = $root.infinitusai.be.EhrIntegrationConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 56: {
                                message.elevenLabsConfig = $root.infinitusai.be.ElevenLabsConfigForOrg.decode(reader, reader.uint32());
                                break;
                            }
                        case 57: {
                                message.voipUrl = reader.string();
                                break;
                            }
                        case 58: {
                                message.disableFetchRecording = reader.bool();
                                break;
                            }
                        case 59: {
                                message.audioOptions = $root.infinitusai.be.AudioOptions.decode(reader, reader.uint32());
                                break;
                            }
                        case 60: {
                                message.autodialerElevenLabsConfig = $root.infinitusai.be.AutodialerElevenLabsConfigForOrg.decode(reader, reader.uint32());
                                break;
                            }
                        case 61: {
                                message.maxConcurrentCallPerUser = reader.int32();
                                break;
                            }
                        case 62: {
                                message.blizzardEndDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 63: {
                                message.postProcessingDisabled = reader.bool();
                                break;
                            }
                        case 64: {
                                message.defaultToPracticeInfo = reader.bool();
                                break;
                            }
                        case 65: {
                                message.enableIpWhitelistValidation = reader.bool();
                                break;
                            }
                        case 66: {
                                message.timeSavingsStartDate = reader.string();
                                break;
                            }
                        case 67: {
                                message.enableAcousticAutomation = reader.bool();
                                break;
                            }
                        case 68: {
                                message.excludePhiFromWebhook = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrganizationDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrganizationDoc} OrganizationDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrganizationDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrganizationDoc message.
                 * @function verify
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrganizationDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        if (!$util.isString(message.imageUrl))
                            return "imageUrl: string expected";
                    if (message.domainName != null && message.hasOwnProperty("domainName"))
                        if (!$util.isString(message.domainName))
                            return "domainName: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.authProviders != null && message.hasOwnProperty("authProviders")) {
                        if (!Array.isArray(message.authProviders))
                            return "authProviders: array expected";
                        for (var i = 0; i < message.authProviders.length; ++i)
                            switch (message.authProviders[i]) {
                            default:
                                return "authProviders: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                    }
                    if (message.programs != null && message.hasOwnProperty("programs")) {
                        if (!$util.isObject(message.programs))
                            return "programs: object expected";
                        var key = Object.keys(message.programs);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.ProgramDoc.verify(message.programs[key[i]]);
                            if (error)
                                return "programs." + error;
                        }
                    }
                    if (message.users != null && message.hasOwnProperty("users")) {
                        if (!$util.isObject(message.users))
                            return "users: object expected";
                        var key = Object.keys(message.users);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.OrgUser.verify(message.users[key[i]]);
                            if (error)
                                return "users." + error;
                        }
                    }
                    if (message.demo != null && message.hasOwnProperty("demo"))
                        if (typeof message.demo !== "boolean")
                            return "demo: boolean expected";
                    if (message.supportEmail != null && message.hasOwnProperty("supportEmail"))
                        if (!$util.isString(message.supportEmail))
                            return "supportEmail: string expected";
                    if (message.custom != null && message.hasOwnProperty("custom")) {
                        var error = $root.infinitusai.orgs.OrgCustomization.verify(message.custom);
                        if (error)
                            return "custom." + error;
                    }
                    if (message.callbackNumber != null && message.hasOwnProperty("callbackNumber"))
                        if (!$util.isString(message.callbackNumber))
                            return "callbackNumber: string expected";
                    if (message.maxTasksReadyForCall != null && message.hasOwnProperty("maxTasksReadyForCall"))
                        if (!$util.isInteger(message.maxTasksReadyForCall))
                            return "maxTasksReadyForCall: integer expected";
                    if (message.emailDomains != null && message.hasOwnProperty("emailDomains")) {
                        if (!Array.isArray(message.emailDomains))
                            return "emailDomains: array expected";
                        for (var i = 0; i < message.emailDomains.length; ++i)
                            if (!$util.isString(message.emailDomains[i]))
                                return "emailDomains: string[] expected";
                    }
                    if (message.availableSeats != null && message.hasOwnProperty("availableSeats")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.availableSeats);
                        if (error)
                            return "availableSeats." + error;
                    }
                    if (message.defaultProgramId != null && message.hasOwnProperty("defaultProgramId"))
                        if (!$util.isString(message.defaultProgramId))
                            return "defaultProgramId: string expected";
                    if (message.numBusinessDaysBufferBeforeAdot != null && message.hasOwnProperty("numBusinessDaysBufferBeforeAdot"))
                        if (!$util.isInteger(message.numBusinessDaysBufferBeforeAdot))
                            return "numBusinessDaysBufferBeforeAdot: integer expected";
                    if (message.enabledCustomerPortalFeatures != null && message.hasOwnProperty("enabledCustomerPortalFeatures")) {
                        if (!Array.isArray(message.enabledCustomerPortalFeatures))
                            return "enabledCustomerPortalFeatures: array expected";
                        for (var i = 0; i < message.enabledCustomerPortalFeatures.length; ++i)
                            switch (message.enabledCustomerPortalFeatures[i]) {
                            default:
                                return "enabledCustomerPortalFeatures: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 30:
                            case 31:
                            case 32:
                            case 33:
                                break;
                            }
                    }
                    if (message.sttProvider != null && message.hasOwnProperty("sttProvider"))
                        switch (message.sttProvider) {
                        default:
                            return "sttProvider: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.disableCapacityLimits != null && message.hasOwnProperty("disableCapacityLimits"))
                        if (typeof message.disableCapacityLimits !== "boolean")
                            return "disableCapacityLimits: boolean expected";
                    if (message.dailyCapacityByProgram != null && message.hasOwnProperty("dailyCapacityByProgram")) {
                        if (!$util.isObject(message.dailyCapacityByProgram))
                            return "dailyCapacityByProgram: object expected";
                        var key = Object.keys(message.dailyCapacityByProgram);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isInteger(message.dailyCapacityByProgram[key[i]]))
                                return "dailyCapacityByProgram: integer{k:string} expected";
                    }
                    if (message.disableAnnualReenrollmentDueDates != null && message.hasOwnProperty("disableAnnualReenrollmentDueDates"))
                        if (typeof message.disableAnnualReenrollmentDueDates !== "boolean")
                            return "disableAnnualReenrollmentDueDates: boolean expected";
                    if (message.outboundCallerIdNumber != null && message.hasOwnProperty("outboundCallerIdNumber"))
                        if (!$util.isString(message.outboundCallerIdNumber))
                            return "outboundCallerIdNumber: string expected";
                    if (message.defaultBvInputs != null && message.hasOwnProperty("defaultBvInputs")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.defaultBvInputs);
                        if (error)
                            return "defaultBvInputs." + error;
                    }
                    if (message.restrictedToPayerUuids != null && message.hasOwnProperty("restrictedToPayerUuids")) {
                        if (!Array.isArray(message.restrictedToPayerUuids))
                            return "restrictedToPayerUuids: array expected";
                        for (var i = 0; i < message.restrictedToPayerUuids.length; ++i)
                            if (!$util.isString(message.restrictedToPayerUuids[i]))
                                return "restrictedToPayerUuids: string[] expected";
                    }
                    if (message.enabledOperatorPortalFeatures != null && message.hasOwnProperty("enabledOperatorPortalFeatures")) {
                        if (!Array.isArray(message.enabledOperatorPortalFeatures))
                            return "enabledOperatorPortalFeatures: array expected";
                        for (var i = 0; i < message.enabledOperatorPortalFeatures.length; ++i)
                            switch (message.enabledOperatorPortalFeatures[i]) {
                            default:
                                return "enabledOperatorPortalFeatures: enum value[] expected";
                            case 0:
                            case 5:
                            case 6:
                            case 7:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 30:
                            case 31:
                            case 32:
                            case 33:
                            case 34:
                            case 35:
                            case 36:
                            case 37:
                            case 38:
                                break;
                            }
                    }
                    if (message.whitelistedIpAddresses != null && message.hasOwnProperty("whitelistedIpAddresses")) {
                        if (!Array.isArray(message.whitelistedIpAddresses))
                            return "whitelistedIpAddresses: array expected";
                        for (var i = 0; i < message.whitelistedIpAddresses.length; ++i)
                            if (!$util.isString(message.whitelistedIpAddresses[i]))
                                return "whitelistedIpAddresses: string[] expected";
                    }
                    if (message.daysTasksAvailableForAccess != null && message.hasOwnProperty("daysTasksAvailableForAccess")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.daysTasksAvailableForAccess);
                        if (error)
                            return "daysTasksAvailableForAccess." + error;
                    }
                    if (message.taskTurnaroundTimeHours != null && message.hasOwnProperty("taskTurnaroundTimeHours"))
                        if (!$util.isInteger(message.taskTurnaroundTimeHours))
                            return "taskTurnaroundTimeHours: integer expected";
                    if (message.queueingAlgorithm != null && message.hasOwnProperty("queueingAlgorithm"))
                        switch (message.queueingAlgorithm) {
                        default:
                            return "queueingAlgorithm: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.taskTurnaroundTimeDays != null && message.hasOwnProperty("taskTurnaroundTimeDays"))
                        if (!$util.isInteger(message.taskTurnaroundTimeDays))
                            return "taskTurnaroundTimeDays: integer expected";
                    if (message.recordedGreetingTemplates != null && message.hasOwnProperty("recordedGreetingTemplates")) {
                        if (!Array.isArray(message.recordedGreetingTemplates))
                            return "recordedGreetingTemplates: array expected";
                        for (var i = 0; i < message.recordedGreetingTemplates.length; ++i) {
                            var error = $root.infinitusai.be.RecordedGreetingTemplate.verify(message.recordedGreetingTemplates[i]);
                            if (error)
                                return "recordedGreetingTemplates." + error;
                        }
                    }
                    if (message.usePracticeForHopd != null && message.hasOwnProperty("usePracticeForHopd"))
                        if (typeof message.usePracticeForHopd !== "boolean")
                            return "usePracticeForHopd: boolean expected";
                    if (message.usePracticeForSpecialistOffice != null && message.hasOwnProperty("usePracticeForSpecialistOffice"))
                        if (typeof message.usePracticeForSpecialistOffice !== "boolean")
                            return "usePracticeForSpecialistOffice: boolean expected";
                    if (message.autopilotSecondsByTaskType != null && message.hasOwnProperty("autopilotSecondsByTaskType")) {
                        if (!$util.isObject(message.autopilotSecondsByTaskType))
                            return "autopilotSecondsByTaskType: object expected";
                        var key = Object.keys(message.autopilotSecondsByTaskType);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isInteger(message.autopilotSecondsByTaskType[key[i]]))
                                return "autopilotSecondsByTaskType: integer{k:string} expected";
                    }
                    if (message.enableTesting != null && message.hasOwnProperty("enableTesting"))
                        if (typeof message.enableTesting !== "boolean")
                            return "enableTesting: boolean expected";
                    if (message.chatRoom != null && message.hasOwnProperty("chatRoom"))
                        if (!$util.isString(message.chatRoom))
                            return "chatRoom: string expected";
                    if (message.disableRecording != null && message.hasOwnProperty("disableRecording"))
                        if (typeof message.disableRecording !== "boolean")
                            return "disableRecording: boolean expected";
                    if (message.numDaysToRetain != null && message.hasOwnProperty("numDaysToRetain"))
                        if (!$util.isInteger(message.numDaysToRetain))
                            return "numDaysToRetain: integer expected";
                    if (message.ebvConfig != null && message.hasOwnProperty("ebvConfig")) {
                        var error = $root.infinitusai.be.OrgEbvConfig.verify(message.ebvConfig);
                        if (error)
                            return "ebvConfig." + error;
                    }
                    if (message.customerUuid != null && message.hasOwnProperty("customerUuid"))
                        if (!$util.isString(message.customerUuid))
                            return "customerUuid: string expected";
                    if (message.isInternal != null && message.hasOwnProperty("isInternal"))
                        if (typeof message.isInternal !== "boolean")
                            return "isInternal: boolean expected";
                    if (message.isLive != null && message.hasOwnProperty("isLive"))
                        if (typeof message.isLive !== "boolean")
                            return "isLive: boolean expected";
                    if (message.humanVoicesAllowed != null && message.hasOwnProperty("humanVoicesAllowed"))
                        if (typeof message.humanVoicesAllowed !== "boolean")
                            return "humanVoicesAllowed: boolean expected";
                    if (message.enableProductCodeMapping != null && message.hasOwnProperty("enableProductCodeMapping"))
                        if (typeof message.enableProductCodeMapping !== "boolean")
                            return "enableProductCodeMapping: boolean expected";
                    if (message.startRoutingOperator != null && message.hasOwnProperty("startRoutingOperator"))
                        switch (message.startRoutingOperator) {
                        default:
                            return "startRoutingOperator: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.maxAttemptsPerTask != null && message.hasOwnProperty("maxAttemptsPerTask"))
                        if (!$util.isInteger(message.maxAttemptsPerTask))
                            return "maxAttemptsPerTask: integer expected";
                    if (message.watchdogIntervalSeconds != null && message.hasOwnProperty("watchdogIntervalSeconds"))
                        if (!$util.isInteger(message.watchdogIntervalSeconds))
                            return "watchdogIntervalSeconds: integer expected";
                    if (message.enabledApiFeatures != null && message.hasOwnProperty("enabledApiFeatures")) {
                        if (!Array.isArray(message.enabledApiFeatures))
                            return "enabledApiFeatures: array expected";
                        for (var i = 0; i < message.enabledApiFeatures.length; ++i)
                            switch (message.enabledApiFeatures[i]) {
                            default:
                                return "enabledApiFeatures: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.startDynamicRoutingAtDefault != null && message.hasOwnProperty("startDynamicRoutingAtDefault"))
                        if (!$util.isString(message.startDynamicRoutingAtDefault))
                            return "startDynamicRoutingAtDefault: string expected";
                    if (message.startDynamicRoutingAt_ABTestConfig != null && message.hasOwnProperty("startDynamicRoutingAt_ABTestConfig")) {
                        if (!$util.isObject(message.startDynamicRoutingAt_ABTestConfig))
                            return "startDynamicRoutingAt_ABTestConfig: object expected";
                        var key = Object.keys(message.startDynamicRoutingAt_ABTestConfig);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.verify(message.startDynamicRoutingAt_ABTestConfig[key[i]]);
                            if (error)
                                return "startDynamicRoutingAt_ABTestConfig." + error;
                        }
                    }
                    if (message.ehrIntegrationConfig != null && message.hasOwnProperty("ehrIntegrationConfig")) {
                        var error = $root.infinitusai.be.EhrIntegrationConfig.verify(message.ehrIntegrationConfig);
                        if (error)
                            return "ehrIntegrationConfig." + error;
                    }
                    if (message.elevenLabsConfig != null && message.hasOwnProperty("elevenLabsConfig")) {
                        var error = $root.infinitusai.be.ElevenLabsConfigForOrg.verify(message.elevenLabsConfig);
                        if (error)
                            return "elevenLabsConfig." + error;
                    }
                    if (message.voipUrl != null && message.hasOwnProperty("voipUrl"))
                        if (!$util.isString(message.voipUrl))
                            return "voipUrl: string expected";
                    if (message.disableFetchRecording != null && message.hasOwnProperty("disableFetchRecording"))
                        if (typeof message.disableFetchRecording !== "boolean")
                            return "disableFetchRecording: boolean expected";
                    if (message.audioOptions != null && message.hasOwnProperty("audioOptions")) {
                        var error = $root.infinitusai.be.AudioOptions.verify(message.audioOptions);
                        if (error)
                            return "audioOptions." + error;
                    }
                    if (message.autodialerElevenLabsConfig != null && message.hasOwnProperty("autodialerElevenLabsConfig")) {
                        var error = $root.infinitusai.be.AutodialerElevenLabsConfigForOrg.verify(message.autodialerElevenLabsConfig);
                        if (error)
                            return "autodialerElevenLabsConfig." + error;
                    }
                    if (message.maxConcurrentCallPerUser != null && message.hasOwnProperty("maxConcurrentCallPerUser"))
                        if (!$util.isInteger(message.maxConcurrentCallPerUser))
                            return "maxConcurrentCallPerUser: integer expected";
                    if (message.blizzardEndDate != null && message.hasOwnProperty("blizzardEndDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.blizzardEndDate);
                        if (error)
                            return "blizzardEndDate." + error;
                    }
                    if (message.postProcessingDisabled != null && message.hasOwnProperty("postProcessingDisabled"))
                        if (typeof message.postProcessingDisabled !== "boolean")
                            return "postProcessingDisabled: boolean expected";
                    if (message.defaultToPracticeInfo != null && message.hasOwnProperty("defaultToPracticeInfo"))
                        if (typeof message.defaultToPracticeInfo !== "boolean")
                            return "defaultToPracticeInfo: boolean expected";
                    if (message.enableIpWhitelistValidation != null && message.hasOwnProperty("enableIpWhitelistValidation"))
                        if (typeof message.enableIpWhitelistValidation !== "boolean")
                            return "enableIpWhitelistValidation: boolean expected";
                    if (message.timeSavingsStartDate != null && message.hasOwnProperty("timeSavingsStartDate"))
                        if (!$util.isString(message.timeSavingsStartDate))
                            return "timeSavingsStartDate: string expected";
                    if (message.enableAcousticAutomation != null && message.hasOwnProperty("enableAcousticAutomation"))
                        if (typeof message.enableAcousticAutomation !== "boolean")
                            return "enableAcousticAutomation: boolean expected";
                    if (message.excludePhiFromWebhook != null && message.hasOwnProperty("excludePhiFromWebhook"))
                        if (typeof message.excludePhiFromWebhook !== "boolean")
                            return "excludePhiFromWebhook: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an OrganizationDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrganizationDoc} OrganizationDoc
                 */
                OrganizationDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrganizationDoc)
                        return object;
                    var message = new $root.infinitusai.be.OrganizationDoc();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.imageUrl != null)
                        message.imageUrl = String(object.imageUrl);
                    if (object.domainName != null)
                        message.domainName = String(object.domainName);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.authProviders) {
                        if (!Array.isArray(object.authProviders))
                            throw TypeError(".infinitusai.be.OrganizationDoc.authProviders: array expected");
                        message.authProviders = [];
                        for (var i = 0; i < object.authProviders.length; ++i)
                            switch (object.authProviders[i]) {
                            default:
                                if (typeof object.authProviders[i] === "number") {
                                    message.authProviders[i] = object.authProviders[i];
                                    break;
                                }
                            case "AUTH_PROVIDER_UNKNOWN":
                            case 0:
                                message.authProviders[i] = 0;
                                break;
                            case "AUTH_PROVIDER_GOOGLE":
                            case 1:
                                message.authProviders[i] = 1;
                                break;
                            case "AUTH_PROVIDER_MICROSOFT":
                            case 2:
                                message.authProviders[i] = 2;
                                break;
                            }
                    }
                    if (object.programs) {
                        if (typeof object.programs !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.programs: object expected");
                        message.programs = {};
                        for (var keys = Object.keys(object.programs), i = 0; i < keys.length; ++i) {
                            if (typeof object.programs[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.OrganizationDoc.programs: object expected");
                            message.programs[keys[i]] = $root.infinitusai.be.ProgramDoc.fromObject(object.programs[keys[i]]);
                        }
                    }
                    if (object.users) {
                        if (typeof object.users !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.users: object expected");
                        message.users = {};
                        for (var keys = Object.keys(object.users), i = 0; i < keys.length; ++i) {
                            if (typeof object.users[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.OrganizationDoc.users: object expected");
                            message.users[keys[i]] = $root.infinitusai.be.OrgUser.fromObject(object.users[keys[i]]);
                        }
                    }
                    if (object.demo != null)
                        message.demo = Boolean(object.demo);
                    if (object.supportEmail != null)
                        message.supportEmail = String(object.supportEmail);
                    if (object.custom != null) {
                        if (typeof object.custom !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.custom: object expected");
                        message.custom = $root.infinitusai.orgs.OrgCustomization.fromObject(object.custom);
                    }
                    if (object.callbackNumber != null)
                        message.callbackNumber = String(object.callbackNumber);
                    if (object.maxTasksReadyForCall != null)
                        message.maxTasksReadyForCall = object.maxTasksReadyForCall | 0;
                    if (object.emailDomains) {
                        if (!Array.isArray(object.emailDomains))
                            throw TypeError(".infinitusai.be.OrganizationDoc.emailDomains: array expected");
                        message.emailDomains = [];
                        for (var i = 0; i < object.emailDomains.length; ++i)
                            message.emailDomains[i] = String(object.emailDomains[i]);
                    }
                    if (object.availableSeats != null) {
                        if (typeof object.availableSeats !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.availableSeats: object expected");
                        message.availableSeats = $root.infinitusai.be.NullableInt32.fromObject(object.availableSeats);
                    }
                    if (object.defaultProgramId != null)
                        message.defaultProgramId = String(object.defaultProgramId);
                    if (object.numBusinessDaysBufferBeforeAdot != null)
                        message.numBusinessDaysBufferBeforeAdot = object.numBusinessDaysBufferBeforeAdot | 0;
                    if (object.enabledCustomerPortalFeatures) {
                        if (!Array.isArray(object.enabledCustomerPortalFeatures))
                            throw TypeError(".infinitusai.be.OrganizationDoc.enabledCustomerPortalFeatures: array expected");
                        message.enabledCustomerPortalFeatures = [];
                        for (var i = 0; i < object.enabledCustomerPortalFeatures.length; ++i)
                            switch (object.enabledCustomerPortalFeatures[i]) {
                            default:
                                if (typeof object.enabledCustomerPortalFeatures[i] === "number") {
                                    message.enabledCustomerPortalFeatures[i] = object.enabledCustomerPortalFeatures[i];
                                    break;
                                }
                            case "CUSTOMER_PORTAL_FEATURE_UNKNOWN":
                            case 0:
                                message.enabledCustomerPortalFeatures[i] = 0;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_PAYER_INTELLIGENCE":
                            case 1:
                                message.enabledCustomerPortalFeatures[i] = 1;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_REPORTS":
                            case 2:
                                message.enabledCustomerPortalFeatures[i] = 2;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CREATE_TASK":
                            case 3:
                                message.enabledCustomerPortalFeatures[i] = 3;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BILLING":
                            case 4:
                                message.enabledCustomerPortalFeatures[i] = 4;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_EXPOSE_PHI":
                            case 5:
                                message.enabledCustomerPortalFeatures[i] = 5;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_ALL_TASKS_REQUIRE_REVIEW":
                            case 6:
                                message.enabledCustomerPortalFeatures[i] = 6;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_UPDATE_TASK_INPUTS":
                            case 7:
                                message.enabledCustomerPortalFeatures[i] = 7;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IMPORT_TASKS_CSV":
                            case 8:
                                message.enabledCustomerPortalFeatures[i] = 8;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BILLING_NOTES":
                            case 9:
                                message.enabledCustomerPortalFeatures[i] = 9;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IVR_AND_HOLD":
                            case 10:
                                message.enabledCustomerPortalFeatures[i] = 10;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IMPORT_RULES":
                            case 11:
                                message.enabledCustomerPortalFeatures[i] = 11;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TASK_TABLE_TASK_TYPES":
                            case 12:
                                message.enabledCustomerPortalFeatures[i] = 12;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TASK_TABLE_PRODUCTS":
                            case 13:
                                message.enabledCustomerPortalFeatures[i] = 13;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_MARK_TASK_COMPLETE":
                            case 14:
                                message.enabledCustomerPortalFeatures[i] = 14;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_RBAC":
                            case 15:
                                message.enabledCustomerPortalFeatures[i] = 15;
                                break;
                            case "CUSTOMER_PORTAL_UI_UPDATES":
                            case 16:
                                message.enabledCustomerPortalFeatures[i] = 16;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_V2":
                            case 17:
                                message.enabledCustomerPortalFeatures[i] = 17;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_HUMAN_READABLE_CSV_REPORT":
                            case 18:
                                message.enabledCustomerPortalFeatures[i] = 18;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SELF_SERVE_TESTING":
                            case 19:
                                message.enabledCustomerPortalFeatures[i] = 19;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CSWII":
                            case 20:
                                message.enabledCustomerPortalFeatures[i] = 20;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SHOW_TIME_SAVINGS":
                            case 21:
                                message.enabledCustomerPortalFeatures[i] = 21;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_ACTIVE_CALLS":
                            case 22:
                                message.enabledCustomerPortalFeatures[i] = 22;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_NEW_VOICE_ARCHITECTURE":
                            case 23:
                                message.enabledCustomerPortalFeatures[i] = 23;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_DISABLE_FT_HOLD_TIMES":
                            case 24:
                                message.enabledCustomerPortalFeatures[i] = 24;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CALL_SUMMARIZATION":
                            case 25:
                                message.enabledCustomerPortalFeatures[i] = 25;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SHOW_HUMAN_INVOLVEMENT_FLAG":
                            case 26:
                                message.enabledCustomerPortalFeatures[i] = 26;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_MULTI_PLAYER_MODE_INTERNAL":
                            case 27:
                                message.enabledCustomerPortalFeatures[i] = 27;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_SINGLE_PLAYER_MODE_INTERNAL":
                            case 28:
                                message.enabledCustomerPortalFeatures[i] = 28;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CSV_REPORT_EXCLUDE_UNUSED_API_OUTPUTS":
                            case 29:
                                message.enabledCustomerPortalFeatures[i] = 29;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_DEMO":
                            case 30:
                                message.enabledCustomerPortalFeatures[i] = 30;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TEAM_MANAGEMENT":
                            case 31:
                                message.enabledCustomerPortalFeatures[i] = 31;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FT_TRANSCRIPT":
                            case 32:
                                message.enabledCustomerPortalFeatures[i] = 32;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BUNDLE_CUSTOMER_TASKS":
                            case 33:
                                message.enabledCustomerPortalFeatures[i] = 33;
                                break;
                            }
                    }
                    switch (object.sttProvider) {
                    default:
                        if (typeof object.sttProvider === "number") {
                            message.sttProvider = object.sttProvider;
                            break;
                        }
                        break;
                    case "STT_PROVIDER_UNKNOWN":
                    case 0:
                        message.sttProvider = 0;
                        break;
                    case "STT_PROVIDER_GOOGLE_CLOUD":
                    case 1:
                        message.sttProvider = 1;
                        break;
                    case "STT_PROVIDER_DEEPGRAM":
                    case 2:
                        message.sttProvider = 2;
                        break;
                    }
                    if (object.disableCapacityLimits != null)
                        message.disableCapacityLimits = Boolean(object.disableCapacityLimits);
                    if (object.dailyCapacityByProgram) {
                        if (typeof object.dailyCapacityByProgram !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.dailyCapacityByProgram: object expected");
                        message.dailyCapacityByProgram = {};
                        for (var keys = Object.keys(object.dailyCapacityByProgram), i = 0; i < keys.length; ++i)
                            message.dailyCapacityByProgram[keys[i]] = object.dailyCapacityByProgram[keys[i]] | 0;
                    }
                    if (object.disableAnnualReenrollmentDueDates != null)
                        message.disableAnnualReenrollmentDueDates = Boolean(object.disableAnnualReenrollmentDueDates);
                    if (object.outboundCallerIdNumber != null)
                        message.outboundCallerIdNumber = String(object.outboundCallerIdNumber);
                    if (object.defaultBvInputs != null) {
                        if (typeof object.defaultBvInputs !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.defaultBvInputs: object expected");
                        message.defaultBvInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.defaultBvInputs);
                    }
                    if (object.restrictedToPayerUuids) {
                        if (!Array.isArray(object.restrictedToPayerUuids))
                            throw TypeError(".infinitusai.be.OrganizationDoc.restrictedToPayerUuids: array expected");
                        message.restrictedToPayerUuids = [];
                        for (var i = 0; i < object.restrictedToPayerUuids.length; ++i)
                            message.restrictedToPayerUuids[i] = String(object.restrictedToPayerUuids[i]);
                    }
                    if (object.enabledOperatorPortalFeatures) {
                        if (!Array.isArray(object.enabledOperatorPortalFeatures))
                            throw TypeError(".infinitusai.be.OrganizationDoc.enabledOperatorPortalFeatures: array expected");
                        message.enabledOperatorPortalFeatures = [];
                        for (var i = 0; i < object.enabledOperatorPortalFeatures.length; ++i)
                            switch (object.enabledOperatorPortalFeatures[i]) {
                            default:
                                if (typeof object.enabledOperatorPortalFeatures[i] === "number") {
                                    message.enabledOperatorPortalFeatures[i] = object.enabledOperatorPortalFeatures[i];
                                    break;
                                }
                            case "OPERATOR_PORTAL_FEATURE_UNKNOWN":
                            case 0:
                                message.enabledOperatorPortalFeatures[i] = 0;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_PROCESS_TASK":
                            case 5:
                                message.enabledOperatorPortalFeatures[i] = 5;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SKIP_REVIEW":
                            case 6:
                                message.enabledOperatorPortalFeatures[i] = 6;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SKIP_REVIEW_IGNORE_OPERATOR_CRITERIA":
                            case 7:
                                message.enabledOperatorPortalFeatures[i] = 7;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_INPUTS":
                            case 9:
                                message.enabledOperatorPortalFeatures[i] = 9;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_MULTITASK_ENABLED":
                            case 10:
                                message.enabledOperatorPortalFeatures[i] = 10;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SUPPRESS_BEG_NOTIFICATIONS":
                            case 11:
                                message.enabledOperatorPortalFeatures[i] = 11;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ADD_INPUTS_TO_OUTPUT_CSV":
                            case 12:
                                message.enabledOperatorPortalFeatures[i] = 12;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_OUTPUTS":
                            case 13:
                                message.enabledOperatorPortalFeatures[i] = 13;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_RBAC":
                            case 14:
                                message.enabledOperatorPortalFeatures[i] = 14;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_AUTO_POPULATE_FROM_STAT_PIE":
                            case 15:
                                message.enabledOperatorPortalFeatures[i] = 15;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_PARTIAL_REVIEW":
                            case 16:
                                message.enabledOperatorPortalFeatures[i] = 16;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SOCKET_IO":
                            case 17:
                                message.enabledOperatorPortalFeatures[i] = 17;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_PRIORITIZE_BLIZZARD_OVER_PROD_TASKS":
                            case 18:
                                message.enabledOperatorPortalFeatures[i] = 18;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_UNIFIED_QUEUE":
                            case 19:
                                message.enabledOperatorPortalFeatures[i] = 19;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ML_IVR_TESTING":
                            case 20:
                                message.enabledOperatorPortalFeatures[i] = 20;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_INLINE_CALL_OUTPUT_VALIDATION":
                            case 21:
                                message.enabledOperatorPortalFeatures[i] = 21;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ALWAYS_AUTODIAL_FIRST_CALL":
                            case 22:
                                message.enabledOperatorPortalFeatures[i] = 22;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ALLOW_NEW_CALL_WITHOUT_PROCESSING":
                            case 23:
                                message.enabledOperatorPortalFeatures[i] = 23;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ENFORCE_HUMAN_CALL_REASON":
                            case 24:
                                message.enabledOperatorPortalFeatures[i] = 24;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_USE_CONVO_BUILDER_2":
                            case 25:
                                message.enabledOperatorPortalFeatures[i] = 25;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_USE_NEW_PROCESS_CALL_FLOW":
                            case 26:
                                message.enabledOperatorPortalFeatures[i] = 26;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_EARLY_FAIL_TPA_TASKS":
                            case 27:
                                message.enabledOperatorPortalFeatures[i] = 27;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_PLAN_NAME_XP":
                            case 28:
                                message.enabledOperatorPortalFeatures[i] = 28;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_GENERIC_EBV":
                            case 29:
                                message.enabledOperatorPortalFeatures[i] = 29;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_MULTIBENEFITS_ENABLED":
                            case 30:
                                message.enabledOperatorPortalFeatures[i] = 30;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_REPORT_CONTROL_CALL_REASON":
                            case 31:
                                message.enabledOperatorPortalFeatures[i] = 31;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_AUTO_REDIRECT_IN_HOLD":
                            case 32:
                                message.enabledOperatorPortalFeatures[i] = 32;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_LOG_TRIGGERED_CUSTOMER_PIE":
                            case 33:
                                message.enabledOperatorPortalFeatures[i] = 33;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ROUTE_OPS_FROM_AUTODIALER":
                            case 34:
                                message.enabledOperatorPortalFeatures[i] = 34;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_HIDE_ROUTE_OPERATOR_BUTTON":
                            case 35:
                                message.enabledOperatorPortalFeatures[i] = 35;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_AUTO_REVIEW_ENABLED":
                            case 36:
                                message.enabledOperatorPortalFeatures[i] = 36;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_HIDE_FREE_TEXT_INPUT":
                            case 37:
                                message.enabledOperatorPortalFeatures[i] = 37;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SHOW_TASKS_FOR_NON_ADMINS":
                            case 38:
                                message.enabledOperatorPortalFeatures[i] = 38;
                                break;
                            }
                    }
                    if (object.whitelistedIpAddresses) {
                        if (!Array.isArray(object.whitelistedIpAddresses))
                            throw TypeError(".infinitusai.be.OrganizationDoc.whitelistedIpAddresses: array expected");
                        message.whitelistedIpAddresses = [];
                        for (var i = 0; i < object.whitelistedIpAddresses.length; ++i)
                            message.whitelistedIpAddresses[i] = String(object.whitelistedIpAddresses[i]);
                    }
                    if (object.daysTasksAvailableForAccess != null) {
                        if (typeof object.daysTasksAvailableForAccess !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.daysTasksAvailableForAccess: object expected");
                        message.daysTasksAvailableForAccess = $root.infinitusai.be.NullableInt32.fromObject(object.daysTasksAvailableForAccess);
                    }
                    if (object.taskTurnaroundTimeHours != null)
                        message.taskTurnaroundTimeHours = object.taskTurnaroundTimeHours | 0;
                    switch (object.queueingAlgorithm) {
                    default:
                        if (typeof object.queueingAlgorithm === "number") {
                            message.queueingAlgorithm = object.queueingAlgorithm;
                            break;
                        }
                        break;
                    case "QUEUEING_MANUAL":
                    case 0:
                        message.queueingAlgorithm = 0;
                        break;
                    case "QUEUEING_DEFAULT":
                    case 1:
                        message.queueingAlgorithm = 1;
                        break;
                    case "QUEUEING_SHAQ":
                    case 2:
                        message.queueingAlgorithm = 2;
                        break;
                    }
                    if (object.taskTurnaroundTimeDays != null)
                        message.taskTurnaroundTimeDays = object.taskTurnaroundTimeDays | 0;
                    if (object.recordedGreetingTemplates) {
                        if (!Array.isArray(object.recordedGreetingTemplates))
                            throw TypeError(".infinitusai.be.OrganizationDoc.recordedGreetingTemplates: array expected");
                        message.recordedGreetingTemplates = [];
                        for (var i = 0; i < object.recordedGreetingTemplates.length; ++i) {
                            if (typeof object.recordedGreetingTemplates[i] !== "object")
                                throw TypeError(".infinitusai.be.OrganizationDoc.recordedGreetingTemplates: object expected");
                            message.recordedGreetingTemplates[i] = $root.infinitusai.be.RecordedGreetingTemplate.fromObject(object.recordedGreetingTemplates[i]);
                        }
                    }
                    if (object.usePracticeForHopd != null)
                        message.usePracticeForHopd = Boolean(object.usePracticeForHopd);
                    if (object.usePracticeForSpecialistOffice != null)
                        message.usePracticeForSpecialistOffice = Boolean(object.usePracticeForSpecialistOffice);
                    if (object.autopilotSecondsByTaskType) {
                        if (typeof object.autopilotSecondsByTaskType !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.autopilotSecondsByTaskType: object expected");
                        message.autopilotSecondsByTaskType = {};
                        for (var keys = Object.keys(object.autopilotSecondsByTaskType), i = 0; i < keys.length; ++i)
                            message.autopilotSecondsByTaskType[keys[i]] = object.autopilotSecondsByTaskType[keys[i]] | 0;
                    }
                    if (object.enableTesting != null)
                        message.enableTesting = Boolean(object.enableTesting);
                    if (object.chatRoom != null)
                        message.chatRoom = String(object.chatRoom);
                    if (object.disableRecording != null)
                        message.disableRecording = Boolean(object.disableRecording);
                    if (object.numDaysToRetain != null)
                        message.numDaysToRetain = object.numDaysToRetain | 0;
                    if (object.ebvConfig != null) {
                        if (typeof object.ebvConfig !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.ebvConfig: object expected");
                        message.ebvConfig = $root.infinitusai.be.OrgEbvConfig.fromObject(object.ebvConfig);
                    }
                    if (object.customerUuid != null)
                        message.customerUuid = String(object.customerUuid);
                    if (object.isInternal != null)
                        message.isInternal = Boolean(object.isInternal);
                    if (object.isLive != null)
                        message.isLive = Boolean(object.isLive);
                    if (object.humanVoicesAllowed != null)
                        message.humanVoicesAllowed = Boolean(object.humanVoicesAllowed);
                    if (object.enableProductCodeMapping != null)
                        message.enableProductCodeMapping = Boolean(object.enableProductCodeMapping);
                    switch (object.startRoutingOperator) {
                    default:
                        if (typeof object.startRoutingOperator === "number") {
                            message.startRoutingOperator = object.startRoutingOperator;
                            break;
                        }
                        break;
                    case "ON_PERSON_AUTH":
                    case 0:
                        message.startRoutingOperator = 0;
                        break;
                    case "ON_PERSON_GET_INFO":
                    case 1:
                        message.startRoutingOperator = 1;
                        break;
                    case "ON_NLP_SERVER":
                    case 2:
                        message.startRoutingOperator = 2;
                        break;
                    }
                    if (object.maxAttemptsPerTask != null)
                        message.maxAttemptsPerTask = object.maxAttemptsPerTask | 0;
                    if (object.watchdogIntervalSeconds != null)
                        message.watchdogIntervalSeconds = object.watchdogIntervalSeconds | 0;
                    if (object.enabledApiFeatures) {
                        if (!Array.isArray(object.enabledApiFeatures))
                            throw TypeError(".infinitusai.be.OrganizationDoc.enabledApiFeatures: array expected");
                        message.enabledApiFeatures = [];
                        for (var i = 0; i < object.enabledApiFeatures.length; ++i)
                            switch (object.enabledApiFeatures[i]) {
                            default:
                                if (typeof object.enabledApiFeatures[i] === "number") {
                                    message.enabledApiFeatures[i] = object.enabledApiFeatures[i];
                                    break;
                                }
                            case "API_FEATURE_UNKNOWN":
                            case 0:
                                message.enabledApiFeatures[i] = 0;
                                break;
                            case "API_FEATURE_TASK_RECORDINGS_API":
                            case 1:
                                message.enabledApiFeatures[i] = 1;
                                break;
                            case "API_FEATURE_CANCEL_TASKS_API":
                            case 2:
                                message.enabledApiFeatures[i] = 2;
                                break;
                            case "API_FEATURE_ENABLE_VONAGE_SDK":
                            case 3:
                                message.enabledApiFeatures[i] = 3;
                                break;
                            }
                    }
                    if (object.startDynamicRoutingAtDefault != null)
                        message.startDynamicRoutingAtDefault = String(object.startDynamicRoutingAtDefault);
                    if (object.startDynamicRoutingAt_ABTestConfig) {
                        if (typeof object.startDynamicRoutingAt_ABTestConfig !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.startDynamicRoutingAt_ABTestConfig: object expected");
                        message.startDynamicRoutingAt_ABTestConfig = {};
                        for (var keys = Object.keys(object.startDynamicRoutingAt_ABTestConfig), i = 0; i < keys.length; ++i) {
                            if (typeof object.startDynamicRoutingAt_ABTestConfig[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.OrganizationDoc.startDynamicRoutingAt_ABTestConfig: object expected");
                            message.startDynamicRoutingAt_ABTestConfig[keys[i]] = $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.fromObject(object.startDynamicRoutingAt_ABTestConfig[keys[i]]);
                        }
                    }
                    if (object.ehrIntegrationConfig != null) {
                        if (typeof object.ehrIntegrationConfig !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.ehrIntegrationConfig: object expected");
                        message.ehrIntegrationConfig = $root.infinitusai.be.EhrIntegrationConfig.fromObject(object.ehrIntegrationConfig);
                    }
                    if (object.elevenLabsConfig != null) {
                        if (typeof object.elevenLabsConfig !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.elevenLabsConfig: object expected");
                        message.elevenLabsConfig = $root.infinitusai.be.ElevenLabsConfigForOrg.fromObject(object.elevenLabsConfig);
                    }
                    if (object.voipUrl != null)
                        message.voipUrl = String(object.voipUrl);
                    if (object.disableFetchRecording != null)
                        message.disableFetchRecording = Boolean(object.disableFetchRecording);
                    if (object.audioOptions != null) {
                        if (typeof object.audioOptions !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.audioOptions: object expected");
                        message.audioOptions = $root.infinitusai.be.AudioOptions.fromObject(object.audioOptions);
                    }
                    if (object.autodialerElevenLabsConfig != null) {
                        if (typeof object.autodialerElevenLabsConfig !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.autodialerElevenLabsConfig: object expected");
                        message.autodialerElevenLabsConfig = $root.infinitusai.be.AutodialerElevenLabsConfigForOrg.fromObject(object.autodialerElevenLabsConfig);
                    }
                    if (object.maxConcurrentCallPerUser != null)
                        message.maxConcurrentCallPerUser = object.maxConcurrentCallPerUser | 0;
                    if (object.blizzardEndDate != null) {
                        if (typeof object.blizzardEndDate !== "object")
                            throw TypeError(".infinitusai.be.OrganizationDoc.blizzardEndDate: object expected");
                        message.blizzardEndDate = $root.infinitusai.be.Date.fromObject(object.blizzardEndDate);
                    }
                    if (object.postProcessingDisabled != null)
                        message.postProcessingDisabled = Boolean(object.postProcessingDisabled);
                    if (object.defaultToPracticeInfo != null)
                        message.defaultToPracticeInfo = Boolean(object.defaultToPracticeInfo);
                    if (object.enableIpWhitelistValidation != null)
                        message.enableIpWhitelistValidation = Boolean(object.enableIpWhitelistValidation);
                    if (object.timeSavingsStartDate != null)
                        message.timeSavingsStartDate = String(object.timeSavingsStartDate);
                    if (object.enableAcousticAutomation != null)
                        message.enableAcousticAutomation = Boolean(object.enableAcousticAutomation);
                    if (object.excludePhiFromWebhook != null)
                        message.excludePhiFromWebhook = Boolean(object.excludePhiFromWebhook);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrganizationDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {infinitusai.be.OrganizationDoc} message OrganizationDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrganizationDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.authProviders = [];
                        object.emailDomains = [];
                        object.enabledCustomerPortalFeatures = [];
                        object.restrictedToPayerUuids = [];
                        object.enabledOperatorPortalFeatures = [];
                        object.whitelistedIpAddresses = [];
                        object.recordedGreetingTemplates = [];
                        object.enabledApiFeatures = [];
                    }
                    if (options.objects || options.defaults) {
                        object.programs = {};
                        object.users = {};
                        object.dailyCapacityByProgram = {};
                        object.autopilotSecondsByTaskType = {};
                        object.startDynamicRoutingAt_ABTestConfig = {};
                    }
                    if (options.defaults) {
                        object.name = "";
                        object.imageUrl = "";
                        object.domainName = "";
                        object.displayName = "";
                        object.demo = false;
                        object.supportEmail = "";
                        object.custom = null;
                        object.availableSeats = null;
                        object.defaultProgramId = "";
                        object.callbackNumber = "";
                        object.maxTasksReadyForCall = 0;
                        object.numBusinessDaysBufferBeforeAdot = 0;
                        object.disableCapacityLimits = false;
                        object.disableAnnualReenrollmentDueDates = false;
                        object.outboundCallerIdNumber = "";
                        object.defaultBvInputs = null;
                        object.sttProvider = options.enums === String ? "STT_PROVIDER_UNKNOWN" : 0;
                        object.daysTasksAvailableForAccess = null;
                        object.taskTurnaroundTimeHours = 0;
                        object.queueingAlgorithm = options.enums === String ? "QUEUEING_MANUAL" : 0;
                        object.taskTurnaroundTimeDays = 0;
                        object.usePracticeForHopd = false;
                        object.usePracticeForSpecialistOffice = false;
                        object.enableTesting = false;
                        object.chatRoom = "";
                        object.disableRecording = false;
                        object.numDaysToRetain = 0;
                        object.ebvConfig = null;
                        object.customerUuid = "";
                        object.isInternal = false;
                        object.isLive = false;
                        object.humanVoicesAllowed = false;
                        object.enableProductCodeMapping = false;
                        object.startRoutingOperator = options.enums === String ? "ON_PERSON_AUTH" : 0;
                        object.maxAttemptsPerTask = 0;
                        object.watchdogIntervalSeconds = 0;
                        object.startDynamicRoutingAtDefault = "";
                        object.ehrIntegrationConfig = null;
                        object.elevenLabsConfig = null;
                        object.voipUrl = "";
                        object.disableFetchRecording = false;
                        object.audioOptions = null;
                        object.autodialerElevenLabsConfig = null;
                        object.maxConcurrentCallPerUser = 0;
                        object.blizzardEndDate = null;
                        object.postProcessingDisabled = false;
                        object.defaultToPracticeInfo = false;
                        object.enableIpWhitelistValidation = false;
                        object.timeSavingsStartDate = "";
                        object.enableAcousticAutomation = false;
                        object.excludePhiFromWebhook = false;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        object.imageUrl = message.imageUrl;
                    if (message.domainName != null && message.hasOwnProperty("domainName"))
                        object.domainName = message.domainName;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.authProviders && message.authProviders.length) {
                        object.authProviders = [];
                        for (var j = 0; j < message.authProviders.length; ++j)
                            object.authProviders[j] = options.enums === String ? $root.infinitusai.be.AuthProvider[message.authProviders[j]] === undefined ? message.authProviders[j] : $root.infinitusai.be.AuthProvider[message.authProviders[j]] : message.authProviders[j];
                    }
                    var keys2;
                    if (message.programs && (keys2 = Object.keys(message.programs)).length) {
                        object.programs = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.programs[keys2[j]] = $root.infinitusai.be.ProgramDoc.toObject(message.programs[keys2[j]], options);
                    }
                    if (message.users && (keys2 = Object.keys(message.users)).length) {
                        object.users = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.users[keys2[j]] = $root.infinitusai.be.OrgUser.toObject(message.users[keys2[j]], options);
                    }
                    if (message.demo != null && message.hasOwnProperty("demo"))
                        object.demo = message.demo;
                    if (message.supportEmail != null && message.hasOwnProperty("supportEmail"))
                        object.supportEmail = message.supportEmail;
                    if (message.custom != null && message.hasOwnProperty("custom"))
                        object.custom = $root.infinitusai.orgs.OrgCustomization.toObject(message.custom, options);
                    if (message.emailDomains && message.emailDomains.length) {
                        object.emailDomains = [];
                        for (var j = 0; j < message.emailDomains.length; ++j)
                            object.emailDomains[j] = message.emailDomains[j];
                    }
                    if (message.availableSeats != null && message.hasOwnProperty("availableSeats"))
                        object.availableSeats = $root.infinitusai.be.NullableInt32.toObject(message.availableSeats, options);
                    if (message.defaultProgramId != null && message.hasOwnProperty("defaultProgramId"))
                        object.defaultProgramId = message.defaultProgramId;
                    if (message.callbackNumber != null && message.hasOwnProperty("callbackNumber"))
                        object.callbackNumber = message.callbackNumber;
                    if (message.maxTasksReadyForCall != null && message.hasOwnProperty("maxTasksReadyForCall"))
                        object.maxTasksReadyForCall = message.maxTasksReadyForCall;
                    if (message.numBusinessDaysBufferBeforeAdot != null && message.hasOwnProperty("numBusinessDaysBufferBeforeAdot"))
                        object.numBusinessDaysBufferBeforeAdot = message.numBusinessDaysBufferBeforeAdot;
                    if (message.enabledCustomerPortalFeatures && message.enabledCustomerPortalFeatures.length) {
                        object.enabledCustomerPortalFeatures = [];
                        for (var j = 0; j < message.enabledCustomerPortalFeatures.length; ++j)
                            object.enabledCustomerPortalFeatures[j] = options.enums === String ? $root.infinitusai.orgs.CustomerPortalFeature[message.enabledCustomerPortalFeatures[j]] === undefined ? message.enabledCustomerPortalFeatures[j] : $root.infinitusai.orgs.CustomerPortalFeature[message.enabledCustomerPortalFeatures[j]] : message.enabledCustomerPortalFeatures[j];
                    }
                    if (message.disableCapacityLimits != null && message.hasOwnProperty("disableCapacityLimits"))
                        object.disableCapacityLimits = message.disableCapacityLimits;
                    if (message.dailyCapacityByProgram && (keys2 = Object.keys(message.dailyCapacityByProgram)).length) {
                        object.dailyCapacityByProgram = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.dailyCapacityByProgram[keys2[j]] = message.dailyCapacityByProgram[keys2[j]];
                    }
                    if (message.disableAnnualReenrollmentDueDates != null && message.hasOwnProperty("disableAnnualReenrollmentDueDates"))
                        object.disableAnnualReenrollmentDueDates = message.disableAnnualReenrollmentDueDates;
                    if (message.outboundCallerIdNumber != null && message.hasOwnProperty("outboundCallerIdNumber"))
                        object.outboundCallerIdNumber = message.outboundCallerIdNumber;
                    if (message.defaultBvInputs != null && message.hasOwnProperty("defaultBvInputs"))
                        object.defaultBvInputs = $root.infinitusai.be.BVCallInputs.toObject(message.defaultBvInputs, options);
                    if (message.restrictedToPayerUuids && message.restrictedToPayerUuids.length) {
                        object.restrictedToPayerUuids = [];
                        for (var j = 0; j < message.restrictedToPayerUuids.length; ++j)
                            object.restrictedToPayerUuids[j] = message.restrictedToPayerUuids[j];
                    }
                    if (message.sttProvider != null && message.hasOwnProperty("sttProvider"))
                        object.sttProvider = options.enums === String ? $root.infinitusai.be.SpeechToTextProvider[message.sttProvider] === undefined ? message.sttProvider : $root.infinitusai.be.SpeechToTextProvider[message.sttProvider] : message.sttProvider;
                    if (message.enabledOperatorPortalFeatures && message.enabledOperatorPortalFeatures.length) {
                        object.enabledOperatorPortalFeatures = [];
                        for (var j = 0; j < message.enabledOperatorPortalFeatures.length; ++j)
                            object.enabledOperatorPortalFeatures[j] = options.enums === String ? $root.infinitusai.orgs.OperatorPortalFeature[message.enabledOperatorPortalFeatures[j]] === undefined ? message.enabledOperatorPortalFeatures[j] : $root.infinitusai.orgs.OperatorPortalFeature[message.enabledOperatorPortalFeatures[j]] : message.enabledOperatorPortalFeatures[j];
                    }
                    if (message.whitelistedIpAddresses && message.whitelistedIpAddresses.length) {
                        object.whitelistedIpAddresses = [];
                        for (var j = 0; j < message.whitelistedIpAddresses.length; ++j)
                            object.whitelistedIpAddresses[j] = message.whitelistedIpAddresses[j];
                    }
                    if (message.daysTasksAvailableForAccess != null && message.hasOwnProperty("daysTasksAvailableForAccess"))
                        object.daysTasksAvailableForAccess = $root.infinitusai.be.NullableInt32.toObject(message.daysTasksAvailableForAccess, options);
                    if (message.taskTurnaroundTimeHours != null && message.hasOwnProperty("taskTurnaroundTimeHours"))
                        object.taskTurnaroundTimeHours = message.taskTurnaroundTimeHours;
                    if (message.queueingAlgorithm != null && message.hasOwnProperty("queueingAlgorithm"))
                        object.queueingAlgorithm = options.enums === String ? $root.infinitusai.be.QueueingAlgorithm[message.queueingAlgorithm] === undefined ? message.queueingAlgorithm : $root.infinitusai.be.QueueingAlgorithm[message.queueingAlgorithm] : message.queueingAlgorithm;
                    if (message.taskTurnaroundTimeDays != null && message.hasOwnProperty("taskTurnaroundTimeDays"))
                        object.taskTurnaroundTimeDays = message.taskTurnaroundTimeDays;
                    if (message.recordedGreetingTemplates && message.recordedGreetingTemplates.length) {
                        object.recordedGreetingTemplates = [];
                        for (var j = 0; j < message.recordedGreetingTemplates.length; ++j)
                            object.recordedGreetingTemplates[j] = $root.infinitusai.be.RecordedGreetingTemplate.toObject(message.recordedGreetingTemplates[j], options);
                    }
                    if (message.usePracticeForHopd != null && message.hasOwnProperty("usePracticeForHopd"))
                        object.usePracticeForHopd = message.usePracticeForHopd;
                    if (message.usePracticeForSpecialistOffice != null && message.hasOwnProperty("usePracticeForSpecialistOffice"))
                        object.usePracticeForSpecialistOffice = message.usePracticeForSpecialistOffice;
                    if (message.autopilotSecondsByTaskType && (keys2 = Object.keys(message.autopilotSecondsByTaskType)).length) {
                        object.autopilotSecondsByTaskType = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.autopilotSecondsByTaskType[keys2[j]] = message.autopilotSecondsByTaskType[keys2[j]];
                    }
                    if (message.enableTesting != null && message.hasOwnProperty("enableTesting"))
                        object.enableTesting = message.enableTesting;
                    if (message.chatRoom != null && message.hasOwnProperty("chatRoom"))
                        object.chatRoom = message.chatRoom;
                    if (message.disableRecording != null && message.hasOwnProperty("disableRecording"))
                        object.disableRecording = message.disableRecording;
                    if (message.numDaysToRetain != null && message.hasOwnProperty("numDaysToRetain"))
                        object.numDaysToRetain = message.numDaysToRetain;
                    if (message.ebvConfig != null && message.hasOwnProperty("ebvConfig"))
                        object.ebvConfig = $root.infinitusai.be.OrgEbvConfig.toObject(message.ebvConfig, options);
                    if (message.customerUuid != null && message.hasOwnProperty("customerUuid"))
                        object.customerUuid = message.customerUuid;
                    if (message.isInternal != null && message.hasOwnProperty("isInternal"))
                        object.isInternal = message.isInternal;
                    if (message.isLive != null && message.hasOwnProperty("isLive"))
                        object.isLive = message.isLive;
                    if (message.humanVoicesAllowed != null && message.hasOwnProperty("humanVoicesAllowed"))
                        object.humanVoicesAllowed = message.humanVoicesAllowed;
                    if (message.enableProductCodeMapping != null && message.hasOwnProperty("enableProductCodeMapping"))
                        object.enableProductCodeMapping = message.enableProductCodeMapping;
                    if (message.startRoutingOperator != null && message.hasOwnProperty("startRoutingOperator"))
                        object.startRoutingOperator = options.enums === String ? $root.infinitusai.be.StartRoutingOperator[message.startRoutingOperator] === undefined ? message.startRoutingOperator : $root.infinitusai.be.StartRoutingOperator[message.startRoutingOperator] : message.startRoutingOperator;
                    if (message.maxAttemptsPerTask != null && message.hasOwnProperty("maxAttemptsPerTask"))
                        object.maxAttemptsPerTask = message.maxAttemptsPerTask;
                    if (message.watchdogIntervalSeconds != null && message.hasOwnProperty("watchdogIntervalSeconds"))
                        object.watchdogIntervalSeconds = message.watchdogIntervalSeconds;
                    if (message.enabledApiFeatures && message.enabledApiFeatures.length) {
                        object.enabledApiFeatures = [];
                        for (var j = 0; j < message.enabledApiFeatures.length; ++j)
                            object.enabledApiFeatures[j] = options.enums === String ? $root.infinitusai.be.ApiFeature[message.enabledApiFeatures[j]] === undefined ? message.enabledApiFeatures[j] : $root.infinitusai.be.ApiFeature[message.enabledApiFeatures[j]] : message.enabledApiFeatures[j];
                    }
                    if (message.startDynamicRoutingAtDefault != null && message.hasOwnProperty("startDynamicRoutingAtDefault"))
                        object.startDynamicRoutingAtDefault = message.startDynamicRoutingAtDefault;
                    if (message.startDynamicRoutingAt_ABTestConfig && (keys2 = Object.keys(message.startDynamicRoutingAt_ABTestConfig)).length) {
                        object.startDynamicRoutingAt_ABTestConfig = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.startDynamicRoutingAt_ABTestConfig[keys2[j]] = $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.toObject(message.startDynamicRoutingAt_ABTestConfig[keys2[j]], options);
                    }
                    if (message.ehrIntegrationConfig != null && message.hasOwnProperty("ehrIntegrationConfig"))
                        object.ehrIntegrationConfig = $root.infinitusai.be.EhrIntegrationConfig.toObject(message.ehrIntegrationConfig, options);
                    if (message.elevenLabsConfig != null && message.hasOwnProperty("elevenLabsConfig"))
                        object.elevenLabsConfig = $root.infinitusai.be.ElevenLabsConfigForOrg.toObject(message.elevenLabsConfig, options);
                    if (message.voipUrl != null && message.hasOwnProperty("voipUrl"))
                        object.voipUrl = message.voipUrl;
                    if (message.disableFetchRecording != null && message.hasOwnProperty("disableFetchRecording"))
                        object.disableFetchRecording = message.disableFetchRecording;
                    if (message.audioOptions != null && message.hasOwnProperty("audioOptions"))
                        object.audioOptions = $root.infinitusai.be.AudioOptions.toObject(message.audioOptions, options);
                    if (message.autodialerElevenLabsConfig != null && message.hasOwnProperty("autodialerElevenLabsConfig"))
                        object.autodialerElevenLabsConfig = $root.infinitusai.be.AutodialerElevenLabsConfigForOrg.toObject(message.autodialerElevenLabsConfig, options);
                    if (message.maxConcurrentCallPerUser != null && message.hasOwnProperty("maxConcurrentCallPerUser"))
                        object.maxConcurrentCallPerUser = message.maxConcurrentCallPerUser;
                    if (message.blizzardEndDate != null && message.hasOwnProperty("blizzardEndDate"))
                        object.blizzardEndDate = $root.infinitusai.be.Date.toObject(message.blizzardEndDate, options);
                    if (message.postProcessingDisabled != null && message.hasOwnProperty("postProcessingDisabled"))
                        object.postProcessingDisabled = message.postProcessingDisabled;
                    if (message.defaultToPracticeInfo != null && message.hasOwnProperty("defaultToPracticeInfo"))
                        object.defaultToPracticeInfo = message.defaultToPracticeInfo;
                    if (message.enableIpWhitelistValidation != null && message.hasOwnProperty("enableIpWhitelistValidation"))
                        object.enableIpWhitelistValidation = message.enableIpWhitelistValidation;
                    if (message.timeSavingsStartDate != null && message.hasOwnProperty("timeSavingsStartDate"))
                        object.timeSavingsStartDate = message.timeSavingsStartDate;
                    if (message.enableAcousticAutomation != null && message.hasOwnProperty("enableAcousticAutomation"))
                        object.enableAcousticAutomation = message.enableAcousticAutomation;
                    if (message.excludePhiFromWebhook != null && message.hasOwnProperty("excludePhiFromWebhook"))
                        object.excludePhiFromWebhook = message.excludePhiFromWebhook;
                    return object;
                };
    
                /**
                 * Converts this OrganizationDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrganizationDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrganizationDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrganizationDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrganizationDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrganizationDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrganizationDoc";
                };
    
                return OrganizationDoc;
            })();
    
            be.AutodialerElevenLabsConfigForOrg = (function() {
    
                /**
                 * Properties of an AutodialerElevenLabsConfigForOrg.
                 * @memberof infinitusai.be
                 * @interface IAutodialerElevenLabsConfigForOrg
                 * @property {infinitusai.be.IElevenLabsConfigForOrg|null} [evaToRealisticOverride] AutodialerElevenLabsConfigForOrg evaToRealisticOverride
                 * @property {infinitusai.be.IElevenLabsConfigForOrg|null} [humanToRealisticOverride] AutodialerElevenLabsConfigForOrg humanToRealisticOverride
                 */
    
                /**
                 * Constructs a new AutodialerElevenLabsConfigForOrg.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AutodialerElevenLabsConfigForOrg.
                 * @implements IAutodialerElevenLabsConfigForOrg
                 * @constructor
                 * @param {infinitusai.be.IAutodialerElevenLabsConfigForOrg=} [properties] Properties to set
                 */
                function AutodialerElevenLabsConfigForOrg(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AutodialerElevenLabsConfigForOrg evaToRealisticOverride.
                 * @member {infinitusai.be.IElevenLabsConfigForOrg|null|undefined} evaToRealisticOverride
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @instance
                 */
                AutodialerElevenLabsConfigForOrg.prototype.evaToRealisticOverride = null;
    
                /**
                 * AutodialerElevenLabsConfigForOrg humanToRealisticOverride.
                 * @member {infinitusai.be.IElevenLabsConfigForOrg|null|undefined} humanToRealisticOverride
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @instance
                 */
                AutodialerElevenLabsConfigForOrg.prototype.humanToRealisticOverride = null;
    
                /**
                 * Creates a new AutodialerElevenLabsConfigForOrg instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {infinitusai.be.IAutodialerElevenLabsConfigForOrg=} [properties] Properties to set
                 * @returns {infinitusai.be.AutodialerElevenLabsConfigForOrg} AutodialerElevenLabsConfigForOrg instance
                 */
                AutodialerElevenLabsConfigForOrg.create = function create(properties) {
                    return new AutodialerElevenLabsConfigForOrg(properties);
                };
    
                /**
                 * Encodes the specified AutodialerElevenLabsConfigForOrg message. Does not implicitly {@link infinitusai.be.AutodialerElevenLabsConfigForOrg.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {infinitusai.be.IAutodialerElevenLabsConfigForOrg} message AutodialerElevenLabsConfigForOrg message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutodialerElevenLabsConfigForOrg.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.evaToRealisticOverride != null && Object.hasOwnProperty.call(message, "evaToRealisticOverride"))
                        $root.infinitusai.be.ElevenLabsConfigForOrg.encode(message.evaToRealisticOverride, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.humanToRealisticOverride != null && Object.hasOwnProperty.call(message, "humanToRealisticOverride"))
                        $root.infinitusai.be.ElevenLabsConfigForOrg.encode(message.humanToRealisticOverride, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AutodialerElevenLabsConfigForOrg message, length delimited. Does not implicitly {@link infinitusai.be.AutodialerElevenLabsConfigForOrg.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {infinitusai.be.IAutodialerElevenLabsConfigForOrg} message AutodialerElevenLabsConfigForOrg message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutodialerElevenLabsConfigForOrg.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AutodialerElevenLabsConfigForOrg message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AutodialerElevenLabsConfigForOrg} AutodialerElevenLabsConfigForOrg
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutodialerElevenLabsConfigForOrg.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AutodialerElevenLabsConfigForOrg();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.evaToRealisticOverride = $root.infinitusai.be.ElevenLabsConfigForOrg.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.humanToRealisticOverride = $root.infinitusai.be.ElevenLabsConfigForOrg.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AutodialerElevenLabsConfigForOrg message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AutodialerElevenLabsConfigForOrg} AutodialerElevenLabsConfigForOrg
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutodialerElevenLabsConfigForOrg.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AutodialerElevenLabsConfigForOrg message.
                 * @function verify
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AutodialerElevenLabsConfigForOrg.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.evaToRealisticOverride != null && message.hasOwnProperty("evaToRealisticOverride")) {
                        var error = $root.infinitusai.be.ElevenLabsConfigForOrg.verify(message.evaToRealisticOverride);
                        if (error)
                            return "evaToRealisticOverride." + error;
                    }
                    if (message.humanToRealisticOverride != null && message.hasOwnProperty("humanToRealisticOverride")) {
                        var error = $root.infinitusai.be.ElevenLabsConfigForOrg.verify(message.humanToRealisticOverride);
                        if (error)
                            return "humanToRealisticOverride." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an AutodialerElevenLabsConfigForOrg message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AutodialerElevenLabsConfigForOrg} AutodialerElevenLabsConfigForOrg
                 */
                AutodialerElevenLabsConfigForOrg.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AutodialerElevenLabsConfigForOrg)
                        return object;
                    var message = new $root.infinitusai.be.AutodialerElevenLabsConfigForOrg();
                    if (object.evaToRealisticOverride != null) {
                        if (typeof object.evaToRealisticOverride !== "object")
                            throw TypeError(".infinitusai.be.AutodialerElevenLabsConfigForOrg.evaToRealisticOverride: object expected");
                        message.evaToRealisticOverride = $root.infinitusai.be.ElevenLabsConfigForOrg.fromObject(object.evaToRealisticOverride);
                    }
                    if (object.humanToRealisticOverride != null) {
                        if (typeof object.humanToRealisticOverride !== "object")
                            throw TypeError(".infinitusai.be.AutodialerElevenLabsConfigForOrg.humanToRealisticOverride: object expected");
                        message.humanToRealisticOverride = $root.infinitusai.be.ElevenLabsConfigForOrg.fromObject(object.humanToRealisticOverride);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AutodialerElevenLabsConfigForOrg message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {infinitusai.be.AutodialerElevenLabsConfigForOrg} message AutodialerElevenLabsConfigForOrg
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AutodialerElevenLabsConfigForOrg.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.evaToRealisticOverride = null;
                        object.humanToRealisticOverride = null;
                    }
                    if (message.evaToRealisticOverride != null && message.hasOwnProperty("evaToRealisticOverride"))
                        object.evaToRealisticOverride = $root.infinitusai.be.ElevenLabsConfigForOrg.toObject(message.evaToRealisticOverride, options);
                    if (message.humanToRealisticOverride != null && message.hasOwnProperty("humanToRealisticOverride"))
                        object.humanToRealisticOverride = $root.infinitusai.be.ElevenLabsConfigForOrg.toObject(message.humanToRealisticOverride, options);
                    return object;
                };
    
                /**
                 * Converts this AutodialerElevenLabsConfigForOrg to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AutodialerElevenLabsConfigForOrg.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AutodialerElevenLabsConfigForOrg
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AutodialerElevenLabsConfigForOrg
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AutodialerElevenLabsConfigForOrg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AutodialerElevenLabsConfigForOrg";
                };
    
                return AutodialerElevenLabsConfigForOrg;
            })();
    
            be.AudioOptions = (function() {
    
                /**
                 * Properties of an AudioOptions.
                 * @memberof infinitusai.be
                 * @interface IAudioOptions
                 * @property {infinitusai.be.AudioOptions.IPregenerateOptions|null} [pregenerateOptions] AudioOptions pregenerateOptions
                 */
    
                /**
                 * Constructs a new AudioOptions.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AudioOptions.
                 * @implements IAudioOptions
                 * @constructor
                 * @param {infinitusai.be.IAudioOptions=} [properties] Properties to set
                 */
                function AudioOptions(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AudioOptions pregenerateOptions.
                 * @member {infinitusai.be.AudioOptions.IPregenerateOptions|null|undefined} pregenerateOptions
                 * @memberof infinitusai.be.AudioOptions
                 * @instance
                 */
                AudioOptions.prototype.pregenerateOptions = null;
    
                /**
                 * Creates a new AudioOptions instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {infinitusai.be.IAudioOptions=} [properties] Properties to set
                 * @returns {infinitusai.be.AudioOptions} AudioOptions instance
                 */
                AudioOptions.create = function create(properties) {
                    return new AudioOptions(properties);
                };
    
                /**
                 * Encodes the specified AudioOptions message. Does not implicitly {@link infinitusai.be.AudioOptions.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {infinitusai.be.IAudioOptions} message AudioOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AudioOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pregenerateOptions != null && Object.hasOwnProperty.call(message, "pregenerateOptions"))
                        $root.infinitusai.be.AudioOptions.PregenerateOptions.encode(message.pregenerateOptions, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AudioOptions message, length delimited. Does not implicitly {@link infinitusai.be.AudioOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {infinitusai.be.IAudioOptions} message AudioOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AudioOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AudioOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AudioOptions} AudioOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AudioOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AudioOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pregenerateOptions = $root.infinitusai.be.AudioOptions.PregenerateOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AudioOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AudioOptions} AudioOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AudioOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AudioOptions message.
                 * @function verify
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AudioOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pregenerateOptions != null && message.hasOwnProperty("pregenerateOptions")) {
                        var error = $root.infinitusai.be.AudioOptions.PregenerateOptions.verify(message.pregenerateOptions);
                        if (error)
                            return "pregenerateOptions." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an AudioOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AudioOptions} AudioOptions
                 */
                AudioOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AudioOptions)
                        return object;
                    var message = new $root.infinitusai.be.AudioOptions();
                    if (object.pregenerateOptions != null) {
                        if (typeof object.pregenerateOptions !== "object")
                            throw TypeError(".infinitusai.be.AudioOptions.pregenerateOptions: object expected");
                        message.pregenerateOptions = $root.infinitusai.be.AudioOptions.PregenerateOptions.fromObject(object.pregenerateOptions);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AudioOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {infinitusai.be.AudioOptions} message AudioOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AudioOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.pregenerateOptions = null;
                    if (message.pregenerateOptions != null && message.hasOwnProperty("pregenerateOptions"))
                        object.pregenerateOptions = $root.infinitusai.be.AudioOptions.PregenerateOptions.toObject(message.pregenerateOptions, options);
                    return object;
                };
    
                /**
                 * Converts this AudioOptions to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AudioOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AudioOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AudioOptions
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AudioOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AudioOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AudioOptions";
                };
    
                AudioOptions.PregenerateOptions = (function() {
    
                    /**
                     * Properties of a PregenerateOptions.
                     * @memberof infinitusai.be.AudioOptions
                     * @interface IPregenerateOptions
                     * @property {boolean|null} [isActive] PregenerateOptions isActive
                     * @property {number|null} [workersPerCall] PregenerateOptions workersPerCall
                     * @property {number|null} [sleepWindowMilliseconds] PregenerateOptions sleepWindowMilliseconds
                     */
    
                    /**
                     * Constructs a new PregenerateOptions.
                     * @memberof infinitusai.be.AudioOptions
                     * @classdesc Represents a PregenerateOptions.
                     * @implements IPregenerateOptions
                     * @constructor
                     * @param {infinitusai.be.AudioOptions.IPregenerateOptions=} [properties] Properties to set
                     */
                    function PregenerateOptions(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PregenerateOptions isActive.
                     * @member {boolean} isActive
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @instance
                     */
                    PregenerateOptions.prototype.isActive = false;
    
                    /**
                     * PregenerateOptions workersPerCall.
                     * @member {number} workersPerCall
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @instance
                     */
                    PregenerateOptions.prototype.workersPerCall = 0;
    
                    /**
                     * PregenerateOptions sleepWindowMilliseconds.
                     * @member {number} sleepWindowMilliseconds
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @instance
                     */
                    PregenerateOptions.prototype.sleepWindowMilliseconds = 0;
    
                    /**
                     * Creates a new PregenerateOptions instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {infinitusai.be.AudioOptions.IPregenerateOptions=} [properties] Properties to set
                     * @returns {infinitusai.be.AudioOptions.PregenerateOptions} PregenerateOptions instance
                     */
                    PregenerateOptions.create = function create(properties) {
                        return new PregenerateOptions(properties);
                    };
    
                    /**
                     * Encodes the specified PregenerateOptions message. Does not implicitly {@link infinitusai.be.AudioOptions.PregenerateOptions.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {infinitusai.be.AudioOptions.IPregenerateOptions} message PregenerateOptions message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PregenerateOptions.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isActive);
                        if (message.workersPerCall != null && Object.hasOwnProperty.call(message, "workersPerCall"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.workersPerCall);
                        if (message.sleepWindowMilliseconds != null && Object.hasOwnProperty.call(message, "sleepWindowMilliseconds"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sleepWindowMilliseconds);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PregenerateOptions message, length delimited. Does not implicitly {@link infinitusai.be.AudioOptions.PregenerateOptions.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {infinitusai.be.AudioOptions.IPregenerateOptions} message PregenerateOptions message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PregenerateOptions.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PregenerateOptions message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.AudioOptions.PregenerateOptions} PregenerateOptions
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PregenerateOptions.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AudioOptions.PregenerateOptions();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.isActive = reader.bool();
                                    break;
                                }
                            case 2: {
                                    message.workersPerCall = reader.int32();
                                    break;
                                }
                            case 3: {
                                    message.sleepWindowMilliseconds = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PregenerateOptions message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.AudioOptions.PregenerateOptions} PregenerateOptions
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PregenerateOptions.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PregenerateOptions message.
                     * @function verify
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PregenerateOptions.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isActive != null && message.hasOwnProperty("isActive"))
                            if (typeof message.isActive !== "boolean")
                                return "isActive: boolean expected";
                        if (message.workersPerCall != null && message.hasOwnProperty("workersPerCall"))
                            if (!$util.isInteger(message.workersPerCall))
                                return "workersPerCall: integer expected";
                        if (message.sleepWindowMilliseconds != null && message.hasOwnProperty("sleepWindowMilliseconds"))
                            if (!$util.isInteger(message.sleepWindowMilliseconds))
                                return "sleepWindowMilliseconds: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a PregenerateOptions message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.AudioOptions.PregenerateOptions} PregenerateOptions
                     */
                    PregenerateOptions.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.AudioOptions.PregenerateOptions)
                            return object;
                        var message = new $root.infinitusai.be.AudioOptions.PregenerateOptions();
                        if (object.isActive != null)
                            message.isActive = Boolean(object.isActive);
                        if (object.workersPerCall != null)
                            message.workersPerCall = object.workersPerCall | 0;
                        if (object.sleepWindowMilliseconds != null)
                            message.sleepWindowMilliseconds = object.sleepWindowMilliseconds | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PregenerateOptions message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {infinitusai.be.AudioOptions.PregenerateOptions} message PregenerateOptions
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PregenerateOptions.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.isActive = false;
                            object.workersPerCall = 0;
                            object.sleepWindowMilliseconds = 0;
                        }
                        if (message.isActive != null && message.hasOwnProperty("isActive"))
                            object.isActive = message.isActive;
                        if (message.workersPerCall != null && message.hasOwnProperty("workersPerCall"))
                            object.workersPerCall = message.workersPerCall;
                        if (message.sleepWindowMilliseconds != null && message.hasOwnProperty("sleepWindowMilliseconds"))
                            object.sleepWindowMilliseconds = message.sleepWindowMilliseconds;
                        return object;
                    };
    
                    /**
                     * Converts this PregenerateOptions to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PregenerateOptions.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for PregenerateOptions
                     * @function getTypeUrl
                     * @memberof infinitusai.be.AudioOptions.PregenerateOptions
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    PregenerateOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.AudioOptions.PregenerateOptions";
                    };
    
                    return PregenerateOptions;
                })();
    
                return AudioOptions;
            })();
    
            be.ElevenLabsConfigForOrg = (function() {
    
                /**
                 * Properties of an ElevenLabsConfigForOrg.
                 * @memberof infinitusai.be
                 * @interface IElevenLabsConfigForOrg
                 * @property {boolean|null} [isActive] ElevenLabsConfigForOrg isActive
                 * @property {Array.<string>|null} [payerIds] ElevenLabsConfigForOrg payerIds
                 * @property {boolean|null} [enableForAllPayers] ElevenLabsConfigForOrg enableForAllPayers
                 * @property {infinitusai.be.IVoiceData|null} [voiceData] ElevenLabsConfigForOrg voiceData
                 */
    
                /**
                 * Constructs a new ElevenLabsConfigForOrg.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ElevenLabsConfigForOrg.
                 * @implements IElevenLabsConfigForOrg
                 * @constructor
                 * @param {infinitusai.be.IElevenLabsConfigForOrg=} [properties] Properties to set
                 */
                function ElevenLabsConfigForOrg(properties) {
                    this.payerIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ElevenLabsConfigForOrg isActive.
                 * @member {boolean} isActive
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @instance
                 */
                ElevenLabsConfigForOrg.prototype.isActive = false;
    
                /**
                 * ElevenLabsConfigForOrg payerIds.
                 * @member {Array.<string>} payerIds
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @instance
                 */
                ElevenLabsConfigForOrg.prototype.payerIds = $util.emptyArray;
    
                /**
                 * ElevenLabsConfigForOrg enableForAllPayers.
                 * @member {boolean} enableForAllPayers
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @instance
                 */
                ElevenLabsConfigForOrg.prototype.enableForAllPayers = false;
    
                /**
                 * ElevenLabsConfigForOrg voiceData.
                 * @member {infinitusai.be.IVoiceData|null|undefined} voiceData
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @instance
                 */
                ElevenLabsConfigForOrg.prototype.voiceData = null;
    
                /**
                 * Creates a new ElevenLabsConfigForOrg instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForOrg=} [properties] Properties to set
                 * @returns {infinitusai.be.ElevenLabsConfigForOrg} ElevenLabsConfigForOrg instance
                 */
                ElevenLabsConfigForOrg.create = function create(properties) {
                    return new ElevenLabsConfigForOrg(properties);
                };
    
                /**
                 * Encodes the specified ElevenLabsConfigForOrg message. Does not implicitly {@link infinitusai.be.ElevenLabsConfigForOrg.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForOrg} message ElevenLabsConfigForOrg message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElevenLabsConfigForOrg.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isActive);
                    if (message.payerIds != null && message.payerIds.length)
                        for (var i = 0; i < message.payerIds.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.payerIds[i]);
                    if (message.enableForAllPayers != null && Object.hasOwnProperty.call(message, "enableForAllPayers"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.enableForAllPayers);
                    if (message.voiceData != null && Object.hasOwnProperty.call(message, "voiceData"))
                        $root.infinitusai.be.VoiceData.encode(message.voiceData, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ElevenLabsConfigForOrg message, length delimited. Does not implicitly {@link infinitusai.be.ElevenLabsConfigForOrg.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForOrg} message ElevenLabsConfigForOrg message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElevenLabsConfigForOrg.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ElevenLabsConfigForOrg message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ElevenLabsConfigForOrg} ElevenLabsConfigForOrg
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElevenLabsConfigForOrg.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ElevenLabsConfigForOrg();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isActive = reader.bool();
                                break;
                            }
                        case 2: {
                                if (!(message.payerIds && message.payerIds.length))
                                    message.payerIds = [];
                                message.payerIds.push(reader.string());
                                break;
                            }
                        case 3: {
                                message.enableForAllPayers = reader.bool();
                                break;
                            }
                        case 4: {
                                message.voiceData = $root.infinitusai.be.VoiceData.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ElevenLabsConfigForOrg message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ElevenLabsConfigForOrg} ElevenLabsConfigForOrg
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElevenLabsConfigForOrg.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ElevenLabsConfigForOrg message.
                 * @function verify
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ElevenLabsConfigForOrg.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isActive != null && message.hasOwnProperty("isActive"))
                        if (typeof message.isActive !== "boolean")
                            return "isActive: boolean expected";
                    if (message.payerIds != null && message.hasOwnProperty("payerIds")) {
                        if (!Array.isArray(message.payerIds))
                            return "payerIds: array expected";
                        for (var i = 0; i < message.payerIds.length; ++i)
                            if (!$util.isString(message.payerIds[i]))
                                return "payerIds: string[] expected";
                    }
                    if (message.enableForAllPayers != null && message.hasOwnProperty("enableForAllPayers"))
                        if (typeof message.enableForAllPayers !== "boolean")
                            return "enableForAllPayers: boolean expected";
                    if (message.voiceData != null && message.hasOwnProperty("voiceData")) {
                        var error = $root.infinitusai.be.VoiceData.verify(message.voiceData);
                        if (error)
                            return "voiceData." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an ElevenLabsConfigForOrg message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ElevenLabsConfigForOrg} ElevenLabsConfigForOrg
                 */
                ElevenLabsConfigForOrg.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ElevenLabsConfigForOrg)
                        return object;
                    var message = new $root.infinitusai.be.ElevenLabsConfigForOrg();
                    if (object.isActive != null)
                        message.isActive = Boolean(object.isActive);
                    if (object.payerIds) {
                        if (!Array.isArray(object.payerIds))
                            throw TypeError(".infinitusai.be.ElevenLabsConfigForOrg.payerIds: array expected");
                        message.payerIds = [];
                        for (var i = 0; i < object.payerIds.length; ++i)
                            message.payerIds[i] = String(object.payerIds[i]);
                    }
                    if (object.enableForAllPayers != null)
                        message.enableForAllPayers = Boolean(object.enableForAllPayers);
                    if (object.voiceData != null) {
                        if (typeof object.voiceData !== "object")
                            throw TypeError(".infinitusai.be.ElevenLabsConfigForOrg.voiceData: object expected");
                        message.voiceData = $root.infinitusai.be.VoiceData.fromObject(object.voiceData);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ElevenLabsConfigForOrg message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {infinitusai.be.ElevenLabsConfigForOrg} message ElevenLabsConfigForOrg
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ElevenLabsConfigForOrg.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.payerIds = [];
                    if (options.defaults) {
                        object.isActive = false;
                        object.enableForAllPayers = false;
                        object.voiceData = null;
                    }
                    if (message.isActive != null && message.hasOwnProperty("isActive"))
                        object.isActive = message.isActive;
                    if (message.payerIds && message.payerIds.length) {
                        object.payerIds = [];
                        for (var j = 0; j < message.payerIds.length; ++j)
                            object.payerIds[j] = message.payerIds[j];
                    }
                    if (message.enableForAllPayers != null && message.hasOwnProperty("enableForAllPayers"))
                        object.enableForAllPayers = message.enableForAllPayers;
                    if (message.voiceData != null && message.hasOwnProperty("voiceData"))
                        object.voiceData = $root.infinitusai.be.VoiceData.toObject(message.voiceData, options);
                    return object;
                };
    
                /**
                 * Converts this ElevenLabsConfigForOrg to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ElevenLabsConfigForOrg.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ElevenLabsConfigForOrg
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ElevenLabsConfigForOrg
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ElevenLabsConfigForOrg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ElevenLabsConfigForOrg";
                };
    
                return ElevenLabsConfigForOrg;
            })();
    
            be.GetBillingMetricsRequest = (function() {
    
                /**
                 * Properties of a GetBillingMetricsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetBillingMetricsRequest
                 * @property {string|null} [completedGteMillis] GetBillingMetricsRequest completedGteMillis
                 * @property {string|null} [completedLteMillis] GetBillingMetricsRequest completedLteMillis
                 */
    
                /**
                 * Constructs a new GetBillingMetricsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBillingMetricsRequest.
                 * @implements IGetBillingMetricsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetBillingMetricsRequest=} [properties] Properties to set
                 */
                function GetBillingMetricsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBillingMetricsRequest completedGteMillis.
                 * @member {string} completedGteMillis
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @instance
                 */
                GetBillingMetricsRequest.prototype.completedGteMillis = "";
    
                /**
                 * GetBillingMetricsRequest completedLteMillis.
                 * @member {string} completedLteMillis
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @instance
                 */
                GetBillingMetricsRequest.prototype.completedLteMillis = "";
    
                /**
                 * Creates a new GetBillingMetricsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingMetricsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBillingMetricsRequest} GetBillingMetricsRequest instance
                 */
                GetBillingMetricsRequest.create = function create(properties) {
                    return new GetBillingMetricsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetBillingMetricsRequest message. Does not implicitly {@link infinitusai.be.GetBillingMetricsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingMetricsRequest} message GetBillingMetricsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingMetricsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.completedGteMillis != null && Object.hasOwnProperty.call(message, "completedGteMillis"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.completedGteMillis);
                    if (message.completedLteMillis != null && Object.hasOwnProperty.call(message, "completedLteMillis"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.completedLteMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBillingMetricsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetBillingMetricsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {infinitusai.be.IGetBillingMetricsRequest} message GetBillingMetricsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingMetricsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBillingMetricsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBillingMetricsRequest} GetBillingMetricsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingMetricsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBillingMetricsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.completedGteMillis = reader.string();
                                break;
                            }
                        case 2: {
                                message.completedLteMillis = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBillingMetricsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBillingMetricsRequest} GetBillingMetricsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingMetricsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBillingMetricsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingMetricsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.completedGteMillis != null && message.hasOwnProperty("completedGteMillis"))
                        if (!$util.isString(message.completedGteMillis))
                            return "completedGteMillis: string expected";
                    if (message.completedLteMillis != null && message.hasOwnProperty("completedLteMillis"))
                        if (!$util.isString(message.completedLteMillis))
                            return "completedLteMillis: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetBillingMetricsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBillingMetricsRequest} GetBillingMetricsRequest
                 */
                GetBillingMetricsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBillingMetricsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetBillingMetricsRequest();
                    if (object.completedGteMillis != null)
                        message.completedGteMillis = String(object.completedGteMillis);
                    if (object.completedLteMillis != null)
                        message.completedLteMillis = String(object.completedLteMillis);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBillingMetricsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {infinitusai.be.GetBillingMetricsRequest} message GetBillingMetricsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingMetricsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.completedGteMillis = "";
                        object.completedLteMillis = "";
                    }
                    if (message.completedGteMillis != null && message.hasOwnProperty("completedGteMillis"))
                        object.completedGteMillis = message.completedGteMillis;
                    if (message.completedLteMillis != null && message.hasOwnProperty("completedLteMillis"))
                        object.completedLteMillis = message.completedLteMillis;
                    return object;
                };
    
                /**
                 * Converts this GetBillingMetricsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingMetricsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBillingMetricsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBillingMetricsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBillingMetricsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBillingMetricsRequest";
                };
    
                return GetBillingMetricsRequest;
            })();
    
            be.GetBillingMetricsResponse = (function() {
    
                /**
                 * Properties of a GetBillingMetricsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetBillingMetricsResponse
                 * @property {number|null} [tasksCompletedCount] GetBillingMetricsResponse tasksCompletedCount
                 * @property {number|null} [tasksFailedBadDataCount] GetBillingMetricsResponse tasksFailedBadDataCount
                 * @property {number|null} [tasksFailedOtherCount] GetBillingMetricsResponse tasksFailedOtherCount
                 */
    
                /**
                 * Constructs a new GetBillingMetricsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBillingMetricsResponse.
                 * @implements IGetBillingMetricsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetBillingMetricsResponse=} [properties] Properties to set
                 */
                function GetBillingMetricsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBillingMetricsResponse tasksCompletedCount.
                 * @member {number} tasksCompletedCount
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @instance
                 */
                GetBillingMetricsResponse.prototype.tasksCompletedCount = 0;
    
                /**
                 * GetBillingMetricsResponse tasksFailedBadDataCount.
                 * @member {number} tasksFailedBadDataCount
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @instance
                 */
                GetBillingMetricsResponse.prototype.tasksFailedBadDataCount = 0;
    
                /**
                 * GetBillingMetricsResponse tasksFailedOtherCount.
                 * @member {number} tasksFailedOtherCount
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @instance
                 */
                GetBillingMetricsResponse.prototype.tasksFailedOtherCount = 0;
    
                /**
                 * Creates a new GetBillingMetricsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingMetricsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBillingMetricsResponse} GetBillingMetricsResponse instance
                 */
                GetBillingMetricsResponse.create = function create(properties) {
                    return new GetBillingMetricsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetBillingMetricsResponse message. Does not implicitly {@link infinitusai.be.GetBillingMetricsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingMetricsResponse} message GetBillingMetricsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingMetricsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tasksCompletedCount != null && Object.hasOwnProperty.call(message, "tasksCompletedCount"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tasksCompletedCount);
                    if (message.tasksFailedBadDataCount != null && Object.hasOwnProperty.call(message, "tasksFailedBadDataCount"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.tasksFailedBadDataCount);
                    if (message.tasksFailedOtherCount != null && Object.hasOwnProperty.call(message, "tasksFailedOtherCount"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.tasksFailedOtherCount);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBillingMetricsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetBillingMetricsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {infinitusai.be.IGetBillingMetricsResponse} message GetBillingMetricsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingMetricsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBillingMetricsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBillingMetricsResponse} GetBillingMetricsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingMetricsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBillingMetricsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.tasksCompletedCount = reader.int32();
                                break;
                            }
                        case 2: {
                                message.tasksFailedBadDataCount = reader.int32();
                                break;
                            }
                        case 3: {
                                message.tasksFailedOtherCount = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBillingMetricsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBillingMetricsResponse} GetBillingMetricsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingMetricsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBillingMetricsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingMetricsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tasksCompletedCount != null && message.hasOwnProperty("tasksCompletedCount"))
                        if (!$util.isInteger(message.tasksCompletedCount))
                            return "tasksCompletedCount: integer expected";
                    if (message.tasksFailedBadDataCount != null && message.hasOwnProperty("tasksFailedBadDataCount"))
                        if (!$util.isInteger(message.tasksFailedBadDataCount))
                            return "tasksFailedBadDataCount: integer expected";
                    if (message.tasksFailedOtherCount != null && message.hasOwnProperty("tasksFailedOtherCount"))
                        if (!$util.isInteger(message.tasksFailedOtherCount))
                            return "tasksFailedOtherCount: integer expected";
                    return null;
                };
    
                /**
                 * Creates a GetBillingMetricsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBillingMetricsResponse} GetBillingMetricsResponse
                 */
                GetBillingMetricsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBillingMetricsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetBillingMetricsResponse();
                    if (object.tasksCompletedCount != null)
                        message.tasksCompletedCount = object.tasksCompletedCount | 0;
                    if (object.tasksFailedBadDataCount != null)
                        message.tasksFailedBadDataCount = object.tasksFailedBadDataCount | 0;
                    if (object.tasksFailedOtherCount != null)
                        message.tasksFailedOtherCount = object.tasksFailedOtherCount | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBillingMetricsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {infinitusai.be.GetBillingMetricsResponse} message GetBillingMetricsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingMetricsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.tasksCompletedCount = 0;
                        object.tasksFailedBadDataCount = 0;
                        object.tasksFailedOtherCount = 0;
                    }
                    if (message.tasksCompletedCount != null && message.hasOwnProperty("tasksCompletedCount"))
                        object.tasksCompletedCount = message.tasksCompletedCount;
                    if (message.tasksFailedBadDataCount != null && message.hasOwnProperty("tasksFailedBadDataCount"))
                        object.tasksFailedBadDataCount = message.tasksFailedBadDataCount;
                    if (message.tasksFailedOtherCount != null && message.hasOwnProperty("tasksFailedOtherCount"))
                        object.tasksFailedOtherCount = message.tasksFailedOtherCount;
                    return object;
                };
    
                /**
                 * Converts this GetBillingMetricsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingMetricsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBillingMetricsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBillingMetricsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBillingMetricsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBillingMetricsResponse";
                };
    
                return GetBillingMetricsResponse;
            })();
    
            be.GetPublicOrganizationRequest = (function() {
    
                /**
                 * Properties of a GetPublicOrganizationRequest.
                 * @memberof infinitusai.be
                 * @interface IGetPublicOrganizationRequest
                 * @property {string|null} [orgUuid] GetPublicOrganizationRequest orgUuid
                 * @property {string|null} [orgName] GetPublicOrganizationRequest orgName
                 * @property {string|null} [orgDomainName] GetPublicOrganizationRequest orgDomainName
                 */
    
                /**
                 * Constructs a new GetPublicOrganizationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPublicOrganizationRequest.
                 * @implements IGetPublicOrganizationRequest
                 * @constructor
                 * @param {infinitusai.be.IGetPublicOrganizationRequest=} [properties] Properties to set
                 */
                function GetPublicOrganizationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPublicOrganizationRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @instance
                 */
                GetPublicOrganizationRequest.prototype.orgUuid = "";
    
                /**
                 * GetPublicOrganizationRequest orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @instance
                 */
                GetPublicOrganizationRequest.prototype.orgName = "";
    
                /**
                 * GetPublicOrganizationRequest orgDomainName.
                 * @member {string} orgDomainName
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @instance
                 */
                GetPublicOrganizationRequest.prototype.orgDomainName = "";
    
                /**
                 * Creates a new GetPublicOrganizationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {infinitusai.be.IGetPublicOrganizationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPublicOrganizationRequest} GetPublicOrganizationRequest instance
                 */
                GetPublicOrganizationRequest.create = function create(properties) {
                    return new GetPublicOrganizationRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPublicOrganizationRequest message. Does not implicitly {@link infinitusai.be.GetPublicOrganizationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {infinitusai.be.IGetPublicOrganizationRequest} message GetPublicOrganizationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPublicOrganizationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgName);
                    if (message.orgDomainName != null && Object.hasOwnProperty.call(message, "orgDomainName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgDomainName);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPublicOrganizationRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetPublicOrganizationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {infinitusai.be.IGetPublicOrganizationRequest} message GetPublicOrganizationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPublicOrganizationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPublicOrganizationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPublicOrganizationRequest} GetPublicOrganizationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPublicOrganizationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPublicOrganizationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgName = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgDomainName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPublicOrganizationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPublicOrganizationRequest} GetPublicOrganizationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPublicOrganizationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPublicOrganizationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPublicOrganizationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    if (message.orgDomainName != null && message.hasOwnProperty("orgDomainName"))
                        if (!$util.isString(message.orgDomainName))
                            return "orgDomainName: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetPublicOrganizationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPublicOrganizationRequest} GetPublicOrganizationRequest
                 */
                GetPublicOrganizationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPublicOrganizationRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetPublicOrganizationRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    if (object.orgDomainName != null)
                        message.orgDomainName = String(object.orgDomainName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPublicOrganizationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {infinitusai.be.GetPublicOrganizationRequest} message GetPublicOrganizationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPublicOrganizationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.orgName = "";
                        object.orgDomainName = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    if (message.orgDomainName != null && message.hasOwnProperty("orgDomainName"))
                        object.orgDomainName = message.orgDomainName;
                    return object;
                };
    
                /**
                 * Converts this GetPublicOrganizationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPublicOrganizationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPublicOrganizationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPublicOrganizationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPublicOrganizationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPublicOrganizationRequest";
                };
    
                return GetPublicOrganizationRequest;
            })();
    
            be.GetPublicOrganizationResponse = (function() {
    
                /**
                 * Properties of a GetPublicOrganizationResponse.
                 * @memberof infinitusai.be
                 * @interface IGetPublicOrganizationResponse
                 * @property {string|null} [orgUuid] GetPublicOrganizationResponse orgUuid
                 * @property {string|null} [orgName] GetPublicOrganizationResponse orgName
                 * @property {string|null} [orgImageUrl] GetPublicOrganizationResponse orgImageUrl
                 * @property {string|null} [orgDomainName] GetPublicOrganizationResponse orgDomainName
                 * @property {string|null} [orgDisplayName] GetPublicOrganizationResponse orgDisplayName
                 * @property {Array.<infinitusai.be.AuthProvider>|null} [orgAuthProviders] GetPublicOrganizationResponse orgAuthProviders
                 * @property {Array.<string>|null} [orgEmailDomains] GetPublicOrganizationResponse orgEmailDomains
                 */
    
                /**
                 * Constructs a new GetPublicOrganizationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPublicOrganizationResponse.
                 * @implements IGetPublicOrganizationResponse
                 * @constructor
                 * @param {infinitusai.be.IGetPublicOrganizationResponse=} [properties] Properties to set
                 */
                function GetPublicOrganizationResponse(properties) {
                    this.orgAuthProviders = [];
                    this.orgEmailDomains = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPublicOrganizationResponse orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @instance
                 */
                GetPublicOrganizationResponse.prototype.orgUuid = "";
    
                /**
                 * GetPublicOrganizationResponse orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @instance
                 */
                GetPublicOrganizationResponse.prototype.orgName = "";
    
                /**
                 * GetPublicOrganizationResponse orgImageUrl.
                 * @member {string} orgImageUrl
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @instance
                 */
                GetPublicOrganizationResponse.prototype.orgImageUrl = "";
    
                /**
                 * GetPublicOrganizationResponse orgDomainName.
                 * @member {string} orgDomainName
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @instance
                 */
                GetPublicOrganizationResponse.prototype.orgDomainName = "";
    
                /**
                 * GetPublicOrganizationResponse orgDisplayName.
                 * @member {string} orgDisplayName
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @instance
                 */
                GetPublicOrganizationResponse.prototype.orgDisplayName = "";
    
                /**
                 * GetPublicOrganizationResponse orgAuthProviders.
                 * @member {Array.<infinitusai.be.AuthProvider>} orgAuthProviders
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @instance
                 */
                GetPublicOrganizationResponse.prototype.orgAuthProviders = $util.emptyArray;
    
                /**
                 * GetPublicOrganizationResponse orgEmailDomains.
                 * @member {Array.<string>} orgEmailDomains
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @instance
                 */
                GetPublicOrganizationResponse.prototype.orgEmailDomains = $util.emptyArray;
    
                /**
                 * Creates a new GetPublicOrganizationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {infinitusai.be.IGetPublicOrganizationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPublicOrganizationResponse} GetPublicOrganizationResponse instance
                 */
                GetPublicOrganizationResponse.create = function create(properties) {
                    return new GetPublicOrganizationResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPublicOrganizationResponse message. Does not implicitly {@link infinitusai.be.GetPublicOrganizationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {infinitusai.be.IGetPublicOrganizationResponse} message GetPublicOrganizationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPublicOrganizationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgName);
                    if (message.orgImageUrl != null && Object.hasOwnProperty.call(message, "orgImageUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgImageUrl);
                    if (message.orgDomainName != null && Object.hasOwnProperty.call(message, "orgDomainName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orgDomainName);
                    if (message.orgDisplayName != null && Object.hasOwnProperty.call(message, "orgDisplayName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.orgDisplayName);
                    if (message.orgAuthProviders != null && message.orgAuthProviders.length) {
                        writer.uint32(/* id 6, wireType 2 =*/50).fork();
                        for (var i = 0; i < message.orgAuthProviders.length; ++i)
                            writer.int32(message.orgAuthProviders[i]);
                        writer.ldelim();
                    }
                    if (message.orgEmailDomains != null && message.orgEmailDomains.length)
                        for (var i = 0; i < message.orgEmailDomains.length; ++i)
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.orgEmailDomains[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPublicOrganizationResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetPublicOrganizationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {infinitusai.be.IGetPublicOrganizationResponse} message GetPublicOrganizationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPublicOrganizationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPublicOrganizationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPublicOrganizationResponse} GetPublicOrganizationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPublicOrganizationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPublicOrganizationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgName = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgImageUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.orgDomainName = reader.string();
                                break;
                            }
                        case 5: {
                                message.orgDisplayName = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.orgAuthProviders && message.orgAuthProviders.length))
                                    message.orgAuthProviders = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.orgAuthProviders.push(reader.int32());
                                } else
                                    message.orgAuthProviders.push(reader.int32());
                                break;
                            }
                        case 7: {
                                if (!(message.orgEmailDomains && message.orgEmailDomains.length))
                                    message.orgEmailDomains = [];
                                message.orgEmailDomains.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPublicOrganizationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPublicOrganizationResponse} GetPublicOrganizationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPublicOrganizationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPublicOrganizationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPublicOrganizationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    if (message.orgImageUrl != null && message.hasOwnProperty("orgImageUrl"))
                        if (!$util.isString(message.orgImageUrl))
                            return "orgImageUrl: string expected";
                    if (message.orgDomainName != null && message.hasOwnProperty("orgDomainName"))
                        if (!$util.isString(message.orgDomainName))
                            return "orgDomainName: string expected";
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        if (!$util.isString(message.orgDisplayName))
                            return "orgDisplayName: string expected";
                    if (message.orgAuthProviders != null && message.hasOwnProperty("orgAuthProviders")) {
                        if (!Array.isArray(message.orgAuthProviders))
                            return "orgAuthProviders: array expected";
                        for (var i = 0; i < message.orgAuthProviders.length; ++i)
                            switch (message.orgAuthProviders[i]) {
                            default:
                                return "orgAuthProviders: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                    }
                    if (message.orgEmailDomains != null && message.hasOwnProperty("orgEmailDomains")) {
                        if (!Array.isArray(message.orgEmailDomains))
                            return "orgEmailDomains: array expected";
                        for (var i = 0; i < message.orgEmailDomains.length; ++i)
                            if (!$util.isString(message.orgEmailDomains[i]))
                                return "orgEmailDomains: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPublicOrganizationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPublicOrganizationResponse} GetPublicOrganizationResponse
                 */
                GetPublicOrganizationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPublicOrganizationResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetPublicOrganizationResponse();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    if (object.orgImageUrl != null)
                        message.orgImageUrl = String(object.orgImageUrl);
                    if (object.orgDomainName != null)
                        message.orgDomainName = String(object.orgDomainName);
                    if (object.orgDisplayName != null)
                        message.orgDisplayName = String(object.orgDisplayName);
                    if (object.orgAuthProviders) {
                        if (!Array.isArray(object.orgAuthProviders))
                            throw TypeError(".infinitusai.be.GetPublicOrganizationResponse.orgAuthProviders: array expected");
                        message.orgAuthProviders = [];
                        for (var i = 0; i < object.orgAuthProviders.length; ++i)
                            switch (object.orgAuthProviders[i]) {
                            default:
                                if (typeof object.orgAuthProviders[i] === "number") {
                                    message.orgAuthProviders[i] = object.orgAuthProviders[i];
                                    break;
                                }
                            case "AUTH_PROVIDER_UNKNOWN":
                            case 0:
                                message.orgAuthProviders[i] = 0;
                                break;
                            case "AUTH_PROVIDER_GOOGLE":
                            case 1:
                                message.orgAuthProviders[i] = 1;
                                break;
                            case "AUTH_PROVIDER_MICROSOFT":
                            case 2:
                                message.orgAuthProviders[i] = 2;
                                break;
                            }
                    }
                    if (object.orgEmailDomains) {
                        if (!Array.isArray(object.orgEmailDomains))
                            throw TypeError(".infinitusai.be.GetPublicOrganizationResponse.orgEmailDomains: array expected");
                        message.orgEmailDomains = [];
                        for (var i = 0; i < object.orgEmailDomains.length; ++i)
                            message.orgEmailDomains[i] = String(object.orgEmailDomains[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPublicOrganizationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {infinitusai.be.GetPublicOrganizationResponse} message GetPublicOrganizationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPublicOrganizationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.orgAuthProviders = [];
                        object.orgEmailDomains = [];
                    }
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.orgName = "";
                        object.orgImageUrl = "";
                        object.orgDomainName = "";
                        object.orgDisplayName = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    if (message.orgImageUrl != null && message.hasOwnProperty("orgImageUrl"))
                        object.orgImageUrl = message.orgImageUrl;
                    if (message.orgDomainName != null && message.hasOwnProperty("orgDomainName"))
                        object.orgDomainName = message.orgDomainName;
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        object.orgDisplayName = message.orgDisplayName;
                    if (message.orgAuthProviders && message.orgAuthProviders.length) {
                        object.orgAuthProviders = [];
                        for (var j = 0; j < message.orgAuthProviders.length; ++j)
                            object.orgAuthProviders[j] = options.enums === String ? $root.infinitusai.be.AuthProvider[message.orgAuthProviders[j]] === undefined ? message.orgAuthProviders[j] : $root.infinitusai.be.AuthProvider[message.orgAuthProviders[j]] : message.orgAuthProviders[j];
                    }
                    if (message.orgEmailDomains && message.orgEmailDomains.length) {
                        object.orgEmailDomains = [];
                        for (var j = 0; j < message.orgEmailDomains.length; ++j)
                            object.orgEmailDomains[j] = message.orgEmailDomains[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPublicOrganizationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPublicOrganizationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPublicOrganizationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPublicOrganizationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPublicOrganizationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPublicOrganizationResponse";
                };
    
                return GetPublicOrganizationResponse;
            })();
    
            be.GetPrivateOrganizationRequest = (function() {
    
                /**
                 * Properties of a GetPrivateOrganizationRequest.
                 * @memberof infinitusai.be
                 * @interface IGetPrivateOrganizationRequest
                 */
    
                /**
                 * Constructs a new GetPrivateOrganizationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPrivateOrganizationRequest.
                 * @implements IGetPrivateOrganizationRequest
                 * @constructor
                 * @param {infinitusai.be.IGetPrivateOrganizationRequest=} [properties] Properties to set
                 */
                function GetPrivateOrganizationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetPrivateOrganizationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {infinitusai.be.IGetPrivateOrganizationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPrivateOrganizationRequest} GetPrivateOrganizationRequest instance
                 */
                GetPrivateOrganizationRequest.create = function create(properties) {
                    return new GetPrivateOrganizationRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPrivateOrganizationRequest message. Does not implicitly {@link infinitusai.be.GetPrivateOrganizationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {infinitusai.be.IGetPrivateOrganizationRequest} message GetPrivateOrganizationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPrivateOrganizationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPrivateOrganizationRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetPrivateOrganizationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {infinitusai.be.IGetPrivateOrganizationRequest} message GetPrivateOrganizationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPrivateOrganizationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPrivateOrganizationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPrivateOrganizationRequest} GetPrivateOrganizationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPrivateOrganizationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPrivateOrganizationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPrivateOrganizationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPrivateOrganizationRequest} GetPrivateOrganizationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPrivateOrganizationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPrivateOrganizationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPrivateOrganizationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetPrivateOrganizationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPrivateOrganizationRequest} GetPrivateOrganizationRequest
                 */
                GetPrivateOrganizationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPrivateOrganizationRequest)
                        return object;
                    return new $root.infinitusai.be.GetPrivateOrganizationRequest();
                };
    
                /**
                 * Creates a plain object from a GetPrivateOrganizationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {infinitusai.be.GetPrivateOrganizationRequest} message GetPrivateOrganizationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPrivateOrganizationRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetPrivateOrganizationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPrivateOrganizationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPrivateOrganizationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPrivateOrganizationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPrivateOrganizationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPrivateOrganizationRequest";
                };
    
                return GetPrivateOrganizationRequest;
            })();
    
            be.GetPrivateOrganizationResponse = (function() {
    
                /**
                 * Properties of a GetPrivateOrganizationResponse.
                 * @memberof infinitusai.be
                 * @interface IGetPrivateOrganizationResponse
                 * @property {infinitusai.be.IOrganizationDoc|null} [org] GetPrivateOrganizationResponse org
                 */
    
                /**
                 * Constructs a new GetPrivateOrganizationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPrivateOrganizationResponse.
                 * @implements IGetPrivateOrganizationResponse
                 * @constructor
                 * @param {infinitusai.be.IGetPrivateOrganizationResponse=} [properties] Properties to set
                 */
                function GetPrivateOrganizationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPrivateOrganizationResponse org.
                 * @member {infinitusai.be.IOrganizationDoc|null|undefined} org
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @instance
                 */
                GetPrivateOrganizationResponse.prototype.org = null;
    
                /**
                 * Creates a new GetPrivateOrganizationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {infinitusai.be.IGetPrivateOrganizationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPrivateOrganizationResponse} GetPrivateOrganizationResponse instance
                 */
                GetPrivateOrganizationResponse.create = function create(properties) {
                    return new GetPrivateOrganizationResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPrivateOrganizationResponse message. Does not implicitly {@link infinitusai.be.GetPrivateOrganizationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {infinitusai.be.IGetPrivateOrganizationResponse} message GetPrivateOrganizationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPrivateOrganizationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.org != null && Object.hasOwnProperty.call(message, "org"))
                        $root.infinitusai.be.OrganizationDoc.encode(message.org, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPrivateOrganizationResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetPrivateOrganizationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {infinitusai.be.IGetPrivateOrganizationResponse} message GetPrivateOrganizationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPrivateOrganizationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPrivateOrganizationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPrivateOrganizationResponse} GetPrivateOrganizationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPrivateOrganizationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPrivateOrganizationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.org = $root.infinitusai.be.OrganizationDoc.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPrivateOrganizationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPrivateOrganizationResponse} GetPrivateOrganizationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPrivateOrganizationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPrivateOrganizationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPrivateOrganizationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.org != null && message.hasOwnProperty("org")) {
                        var error = $root.infinitusai.be.OrganizationDoc.verify(message.org);
                        if (error)
                            return "org." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPrivateOrganizationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPrivateOrganizationResponse} GetPrivateOrganizationResponse
                 */
                GetPrivateOrganizationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPrivateOrganizationResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetPrivateOrganizationResponse();
                    if (object.org != null) {
                        if (typeof object.org !== "object")
                            throw TypeError(".infinitusai.be.GetPrivateOrganizationResponse.org: object expected");
                        message.org = $root.infinitusai.be.OrganizationDoc.fromObject(object.org);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPrivateOrganizationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {infinitusai.be.GetPrivateOrganizationResponse} message GetPrivateOrganizationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPrivateOrganizationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.org = null;
                    if (message.org != null && message.hasOwnProperty("org"))
                        object.org = $root.infinitusai.be.OrganizationDoc.toObject(message.org, options);
                    return object;
                };
    
                /**
                 * Converts this GetPrivateOrganizationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPrivateOrganizationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPrivateOrganizationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPrivateOrganizationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPrivateOrganizationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPrivateOrganizationResponse";
                };
    
                return GetPrivateOrganizationResponse;
            })();
    
            be.UpdateDefaultBvInputsRequest = (function() {
    
                /**
                 * Properties of an UpdateDefaultBvInputsRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateDefaultBvInputsRequest
                 * @property {infinitusai.be.IBVCallInputs|null} [bvInputs] UpdateDefaultBvInputsRequest bvInputs
                 */
    
                /**
                 * Constructs a new UpdateDefaultBvInputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateDefaultBvInputsRequest.
                 * @implements IUpdateDefaultBvInputsRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateDefaultBvInputsRequest=} [properties] Properties to set
                 */
                function UpdateDefaultBvInputsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateDefaultBvInputsRequest bvInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} bvInputs
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @instance
                 */
                UpdateDefaultBvInputsRequest.prototype.bvInputs = null;
    
                /**
                 * Creates a new UpdateDefaultBvInputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {infinitusai.be.IUpdateDefaultBvInputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateDefaultBvInputsRequest} UpdateDefaultBvInputsRequest instance
                 */
                UpdateDefaultBvInputsRequest.create = function create(properties) {
                    return new UpdateDefaultBvInputsRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateDefaultBvInputsRequest message. Does not implicitly {@link infinitusai.be.UpdateDefaultBvInputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {infinitusai.be.IUpdateDefaultBvInputsRequest} message UpdateDefaultBvInputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDefaultBvInputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bvInputs != null && Object.hasOwnProperty.call(message, "bvInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.bvInputs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateDefaultBvInputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateDefaultBvInputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {infinitusai.be.IUpdateDefaultBvInputsRequest} message UpdateDefaultBvInputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDefaultBvInputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateDefaultBvInputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateDefaultBvInputsRequest} UpdateDefaultBvInputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDefaultBvInputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateDefaultBvInputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.bvInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateDefaultBvInputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateDefaultBvInputsRequest} UpdateDefaultBvInputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDefaultBvInputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateDefaultBvInputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateDefaultBvInputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.bvInputs);
                        if (error)
                            return "bvInputs." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateDefaultBvInputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateDefaultBvInputsRequest} UpdateDefaultBvInputsRequest
                 */
                UpdateDefaultBvInputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateDefaultBvInputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateDefaultBvInputsRequest();
                    if (object.bvInputs != null) {
                        if (typeof object.bvInputs !== "object")
                            throw TypeError(".infinitusai.be.UpdateDefaultBvInputsRequest.bvInputs: object expected");
                        message.bvInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.bvInputs);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateDefaultBvInputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {infinitusai.be.UpdateDefaultBvInputsRequest} message UpdateDefaultBvInputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateDefaultBvInputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.bvInputs = null;
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs"))
                        object.bvInputs = $root.infinitusai.be.BVCallInputs.toObject(message.bvInputs, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateDefaultBvInputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateDefaultBvInputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateDefaultBvInputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateDefaultBvInputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateDefaultBvInputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateDefaultBvInputsRequest";
                };
    
                return UpdateDefaultBvInputsRequest;
            })();
    
            be.UpdateDefaultBvInputsResponse = (function() {
    
                /**
                 * Properties of an UpdateDefaultBvInputsResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateDefaultBvInputsResponse
                 */
    
                /**
                 * Constructs a new UpdateDefaultBvInputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateDefaultBvInputsResponse.
                 * @implements IUpdateDefaultBvInputsResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateDefaultBvInputsResponse=} [properties] Properties to set
                 */
                function UpdateDefaultBvInputsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpdateDefaultBvInputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {infinitusai.be.IUpdateDefaultBvInputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateDefaultBvInputsResponse} UpdateDefaultBvInputsResponse instance
                 */
                UpdateDefaultBvInputsResponse.create = function create(properties) {
                    return new UpdateDefaultBvInputsResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateDefaultBvInputsResponse message. Does not implicitly {@link infinitusai.be.UpdateDefaultBvInputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {infinitusai.be.IUpdateDefaultBvInputsResponse} message UpdateDefaultBvInputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDefaultBvInputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateDefaultBvInputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateDefaultBvInputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {infinitusai.be.IUpdateDefaultBvInputsResponse} message UpdateDefaultBvInputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDefaultBvInputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateDefaultBvInputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateDefaultBvInputsResponse} UpdateDefaultBvInputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDefaultBvInputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateDefaultBvInputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateDefaultBvInputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateDefaultBvInputsResponse} UpdateDefaultBvInputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDefaultBvInputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateDefaultBvInputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateDefaultBvInputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateDefaultBvInputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateDefaultBvInputsResponse} UpdateDefaultBvInputsResponse
                 */
                UpdateDefaultBvInputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateDefaultBvInputsResponse)
                        return object;
                    return new $root.infinitusai.be.UpdateDefaultBvInputsResponse();
                };
    
                /**
                 * Creates a plain object from an UpdateDefaultBvInputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {infinitusai.be.UpdateDefaultBvInputsResponse} message UpdateDefaultBvInputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateDefaultBvInputsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpdateDefaultBvInputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateDefaultBvInputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateDefaultBvInputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateDefaultBvInputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateDefaultBvInputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateDefaultBvInputsResponse";
                };
    
                return UpdateDefaultBvInputsResponse;
            })();
    
            be.GetOrgUsersRequest = (function() {
    
                /**
                 * Properties of a GetOrgUsersRequest.
                 * @memberof infinitusai.be
                 * @interface IGetOrgUsersRequest
                 */
    
                /**
                 * Constructs a new GetOrgUsersRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOrgUsersRequest.
                 * @implements IGetOrgUsersRequest
                 * @constructor
                 * @param {infinitusai.be.IGetOrgUsersRequest=} [properties] Properties to set
                 */
                function GetOrgUsersRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetOrgUsersRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgUsersRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOrgUsersRequest} GetOrgUsersRequest instance
                 */
                GetOrgUsersRequest.create = function create(properties) {
                    return new GetOrgUsersRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgUsersRequest message. Does not implicitly {@link infinitusai.be.GetOrgUsersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgUsersRequest} message GetOrgUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgUsersRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgUsersRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetOrgUsersRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgUsersRequest} message GetOrgUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgUsersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgUsersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOrgUsersRequest} GetOrgUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgUsersRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOrgUsersRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgUsersRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOrgUsersRequest} GetOrgUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgUsersRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgUsersRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgUsersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetOrgUsersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOrgUsersRequest} GetOrgUsersRequest
                 */
                GetOrgUsersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOrgUsersRequest)
                        return object;
                    return new $root.infinitusai.be.GetOrgUsersRequest();
                };
    
                /**
                 * Creates a plain object from a GetOrgUsersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {infinitusai.be.GetOrgUsersRequest} message GetOrgUsersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgUsersRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetOrgUsersRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgUsersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgUsersRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOrgUsersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOrgUsersRequest";
                };
    
                return GetOrgUsersRequest;
            })();
    
            be.GetOrgUsersResponse = (function() {
    
                /**
                 * Properties of a GetOrgUsersResponse.
                 * @memberof infinitusai.be
                 * @interface IGetOrgUsersResponse
                 * @property {Array.<infinitusai.be.GetOrgUsersResponse.IOrgUserData>|null} [users] GetOrgUsersResponse users
                 */
    
                /**
                 * Constructs a new GetOrgUsersResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOrgUsersResponse.
                 * @implements IGetOrgUsersResponse
                 * @constructor
                 * @param {infinitusai.be.IGetOrgUsersResponse=} [properties] Properties to set
                 */
                function GetOrgUsersResponse(properties) {
                    this.users = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgUsersResponse users.
                 * @member {Array.<infinitusai.be.GetOrgUsersResponse.IOrgUserData>} users
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @instance
                 */
                GetOrgUsersResponse.prototype.users = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgUsersResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgUsersResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOrgUsersResponse} GetOrgUsersResponse instance
                 */
                GetOrgUsersResponse.create = function create(properties) {
                    return new GetOrgUsersResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgUsersResponse message. Does not implicitly {@link infinitusai.be.GetOrgUsersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgUsersResponse} message GetOrgUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgUsersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.users != null && message.users.length)
                        for (var i = 0; i < message.users.length; ++i)
                            $root.infinitusai.be.GetOrgUsersResponse.OrgUserData.encode(message.users[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgUsersResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetOrgUsersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgUsersResponse} message GetOrgUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgUsersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgUsersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOrgUsersResponse} GetOrgUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgUsersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOrgUsersResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.users && message.users.length))
                                    message.users = [];
                                message.users.push($root.infinitusai.be.GetOrgUsersResponse.OrgUserData.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgUsersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOrgUsersResponse} GetOrgUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgUsersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgUsersResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgUsersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.users != null && message.hasOwnProperty("users")) {
                        if (!Array.isArray(message.users))
                            return "users: array expected";
                        for (var i = 0; i < message.users.length; ++i) {
                            var error = $root.infinitusai.be.GetOrgUsersResponse.OrgUserData.verify(message.users[i]);
                            if (error)
                                return "users." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgUsersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOrgUsersResponse} GetOrgUsersResponse
                 */
                GetOrgUsersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOrgUsersResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetOrgUsersResponse();
                    if (object.users) {
                        if (!Array.isArray(object.users))
                            throw TypeError(".infinitusai.be.GetOrgUsersResponse.users: array expected");
                        message.users = [];
                        for (var i = 0; i < object.users.length; ++i) {
                            if (typeof object.users[i] !== "object")
                                throw TypeError(".infinitusai.be.GetOrgUsersResponse.users: object expected");
                            message.users[i] = $root.infinitusai.be.GetOrgUsersResponse.OrgUserData.fromObject(object.users[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgUsersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {infinitusai.be.GetOrgUsersResponse} message GetOrgUsersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgUsersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.users = [];
                    if (message.users && message.users.length) {
                        object.users = [];
                        for (var j = 0; j < message.users.length; ++j)
                            object.users[j] = $root.infinitusai.be.GetOrgUsersResponse.OrgUserData.toObject(message.users[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgUsersResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgUsersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgUsersResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOrgUsersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOrgUsersResponse";
                };
    
                GetOrgUsersResponse.OrgUserData = (function() {
    
                    /**
                     * Properties of an OrgUserData.
                     * @memberof infinitusai.be.GetOrgUsersResponse
                     * @interface IOrgUserData
                     * @property {string|null} [userUid] OrgUserData userUid
                     * @property {infinitusai.be.IOrgUser|null} [userData] OrgUserData userData
                     */
    
                    /**
                     * Constructs a new OrgUserData.
                     * @memberof infinitusai.be.GetOrgUsersResponse
                     * @classdesc Represents an OrgUserData.
                     * @implements IOrgUserData
                     * @constructor
                     * @param {infinitusai.be.GetOrgUsersResponse.IOrgUserData=} [properties] Properties to set
                     */
                    function OrgUserData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * OrgUserData userUid.
                     * @member {string} userUid
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @instance
                     */
                    OrgUserData.prototype.userUid = "";
    
                    /**
                     * OrgUserData userData.
                     * @member {infinitusai.be.IOrgUser|null|undefined} userData
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @instance
                     */
                    OrgUserData.prototype.userData = null;
    
                    /**
                     * Creates a new OrgUserData instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {infinitusai.be.GetOrgUsersResponse.IOrgUserData=} [properties] Properties to set
                     * @returns {infinitusai.be.GetOrgUsersResponse.OrgUserData} OrgUserData instance
                     */
                    OrgUserData.create = function create(properties) {
                        return new OrgUserData(properties);
                    };
    
                    /**
                     * Encodes the specified OrgUserData message. Does not implicitly {@link infinitusai.be.GetOrgUsersResponse.OrgUserData.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {infinitusai.be.GetOrgUsersResponse.IOrgUserData} message OrgUserData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrgUserData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUid);
                        if (message.userData != null && Object.hasOwnProperty.call(message, "userData"))
                            $root.infinitusai.be.OrgUser.encode(message.userData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified OrgUserData message, length delimited. Does not implicitly {@link infinitusai.be.GetOrgUsersResponse.OrgUserData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {infinitusai.be.GetOrgUsersResponse.IOrgUserData} message OrgUserData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrgUserData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an OrgUserData message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.GetOrgUsersResponse.OrgUserData} OrgUserData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrgUserData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOrgUsersResponse.OrgUserData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.userUid = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.userData = $root.infinitusai.be.OrgUser.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an OrgUserData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.GetOrgUsersResponse.OrgUserData} OrgUserData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrgUserData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an OrgUserData message.
                     * @function verify
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrgUserData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userUid != null && message.hasOwnProperty("userUid"))
                            if (!$util.isString(message.userUid))
                                return "userUid: string expected";
                        if (message.userData != null && message.hasOwnProperty("userData")) {
                            var error = $root.infinitusai.be.OrgUser.verify(message.userData);
                            if (error)
                                return "userData." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an OrgUserData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.GetOrgUsersResponse.OrgUserData} OrgUserData
                     */
                    OrgUserData.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.GetOrgUsersResponse.OrgUserData)
                            return object;
                        var message = new $root.infinitusai.be.GetOrgUsersResponse.OrgUserData();
                        if (object.userUid != null)
                            message.userUid = String(object.userUid);
                        if (object.userData != null) {
                            if (typeof object.userData !== "object")
                                throw TypeError(".infinitusai.be.GetOrgUsersResponse.OrgUserData.userData: object expected");
                            message.userData = $root.infinitusai.be.OrgUser.fromObject(object.userData);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an OrgUserData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {infinitusai.be.GetOrgUsersResponse.OrgUserData} message OrgUserData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrgUserData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userUid = "";
                            object.userData = null;
                        }
                        if (message.userUid != null && message.hasOwnProperty("userUid"))
                            object.userUid = message.userUid;
                        if (message.userData != null && message.hasOwnProperty("userData"))
                            object.userData = $root.infinitusai.be.OrgUser.toObject(message.userData, options);
                        return object;
                    };
    
                    /**
                     * Converts this OrgUserData to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrgUserData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for OrgUserData
                     * @function getTypeUrl
                     * @memberof infinitusai.be.GetOrgUsersResponse.OrgUserData
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    OrgUserData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.GetOrgUsersResponse.OrgUserData";
                    };
    
                    return OrgUserData;
                })();
    
                return GetOrgUsersResponse;
            })();
    
            /**
             * AuthProvider enum.
             * @name infinitusai.be.AuthProvider
             * @enum {number}
             * @property {number} AUTH_PROVIDER_UNKNOWN=0 AUTH_PROVIDER_UNKNOWN value
             * @property {number} AUTH_PROVIDER_GOOGLE=1 AUTH_PROVIDER_GOOGLE value
             * @property {number} AUTH_PROVIDER_MICROSOFT=2 AUTH_PROVIDER_MICROSOFT value
             */
            be.AuthProvider = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AUTH_PROVIDER_UNKNOWN"] = 0;
                values[valuesById[1] = "AUTH_PROVIDER_GOOGLE"] = 1;
                values[valuesById[2] = "AUTH_PROVIDER_MICROSOFT"] = 2;
                return values;
            })();
    
            /**
             * UserRole enum.
             * @name infinitusai.be.UserRole
             * @enum {number}
             * @property {number} USER_ROLE_UNKNOWN=0 USER_ROLE_UNKNOWN value
             * @property {number} USER_ROLE_ADMIN=1 USER_ROLE_ADMIN value
             * @property {number} USER_ROLE_OPERATOR=2 USER_ROLE_OPERATOR value
             * @property {number} USER_ROLE_CUSTOMER=3 USER_ROLE_CUSTOMER value
             * @property {number} USER_ROLE_USER=4 USER_ROLE_USER value
             */
            be.UserRole = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "USER_ROLE_UNKNOWN"] = 0;
                values[valuesById[1] = "USER_ROLE_ADMIN"] = 1;
                values[valuesById[2] = "USER_ROLE_OPERATOR"] = 2;
                values[valuesById[3] = "USER_ROLE_CUSTOMER"] = 3;
                values[valuesById[4] = "USER_ROLE_USER"] = 4;
                return values;
            })();
    
            /**
             * UserStatus enum.
             * @name infinitusai.be.UserStatus
             * @enum {number}
             * @property {number} USER_STATUS_UNKNOWN=0 USER_STATUS_UNKNOWN value
             * @property {number} USER_STATUS_ACTIVE=1 USER_STATUS_ACTIVE value
             * @property {number} USER_STATUS_INACTIVE=2 USER_STATUS_INACTIVE value
             * @property {number} USER_STATUS_DEACTIVATED=3 USER_STATUS_DEACTIVATED value
             * @property {number} USER_STATUS_PENDING=4 USER_STATUS_PENDING value
             */
            be.UserStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "USER_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "USER_STATUS_ACTIVE"] = 1;
                values[valuesById[2] = "USER_STATUS_INACTIVE"] = 2;
                values[valuesById[3] = "USER_STATUS_DEACTIVATED"] = 3;
                values[valuesById[4] = "USER_STATUS_PENDING"] = 4;
                return values;
            })();
    
            /**
             * OperatorOfflineReason enum.
             * @name infinitusai.be.OperatorOfflineReason
             * @enum {number}
             * @property {number} OPERATOR_OFFLINE_REASON_UNKNOWN=0 OPERATOR_OFFLINE_REASON_UNKNOWN value
             * @property {number} OPERATOR_OFFLINE_REASON_MEETING=1 OPERATOR_OFFLINE_REASON_MEETING value
             * @property {number} OPERATOR_OFFLINE_REASON_MANAGER_MEETING=2 OPERATOR_OFFLINE_REASON_MANAGER_MEETING value
             * @property {number} OPERATOR_OFFLINE_REASON_PROJECT=3 OPERATOR_OFFLINE_REASON_PROJECT value
             * @property {number} OPERATOR_OFFLINE_REASON_LUNCH=4 OPERATOR_OFFLINE_REASON_LUNCH value
             * @property {number} OPERATOR_OFFLINE_REASON_BREAK=5 OPERATOR_OFFLINE_REASON_BREAK value
             * @property {number} OPERATOR_OFFLINE_REASON_DONE_TODAY=6 OPERATOR_OFFLINE_REASON_DONE_TODAY value
             * @property {number} OPERATOR_OFFLINE_REASON_OTHER=7 OPERATOR_OFFLINE_REASON_OTHER value
             * @property {number} OPERATOR_OFFLINE_REASON_NULL=8 OPERATOR_OFFLINE_REASON_NULL value
             * @property {number} OPERATOR_OFFLINE_REASON_INACTIVE=9 OPERATOR_OFFLINE_REASON_INACTIVE value
             * @property {number} OPERATOR_OFFLINE_REASON_TRAINING=10 OPERATOR_OFFLINE_REASON_TRAINING value
             */
            be.OperatorOfflineReason = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OPERATOR_OFFLINE_REASON_UNKNOWN"] = 0;
                values[valuesById[1] = "OPERATOR_OFFLINE_REASON_MEETING"] = 1;
                values[valuesById[2] = "OPERATOR_OFFLINE_REASON_MANAGER_MEETING"] = 2;
                values[valuesById[3] = "OPERATOR_OFFLINE_REASON_PROJECT"] = 3;
                values[valuesById[4] = "OPERATOR_OFFLINE_REASON_LUNCH"] = 4;
                values[valuesById[5] = "OPERATOR_OFFLINE_REASON_BREAK"] = 5;
                values[valuesById[6] = "OPERATOR_OFFLINE_REASON_DONE_TODAY"] = 6;
                values[valuesById[7] = "OPERATOR_OFFLINE_REASON_OTHER"] = 7;
                values[valuesById[8] = "OPERATOR_OFFLINE_REASON_NULL"] = 8;
                values[valuesById[9] = "OPERATOR_OFFLINE_REASON_INACTIVE"] = 9;
                values[valuesById[10] = "OPERATOR_OFFLINE_REASON_TRAINING"] = 10;
                return values;
            })();
    
            /**
             * OperatorOnlineStatus enum.
             * @name infinitusai.be.OperatorOnlineStatus
             * @enum {number}
             * @property {number} OPERATOR_ONLINE_STATUS_UNKNOWN=0 OPERATOR_ONLINE_STATUS_UNKNOWN value
             * @property {number} OPERATOR_ONLINE_STATUS_OFFLINE=1 OPERATOR_ONLINE_STATUS_OFFLINE value
             * @property {number} OPERATOR_ONLINE_STATUS_ONLINE=2 OPERATOR_ONLINE_STATUS_ONLINE value
             */
            be.OperatorOnlineStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OPERATOR_ONLINE_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "OPERATOR_ONLINE_STATUS_OFFLINE"] = 1;
                values[valuesById[2] = "OPERATOR_ONLINE_STATUS_ONLINE"] = 2;
                return values;
            })();
    
            be.UserDoc = (function() {
    
                /**
                 * Properties of a UserDoc.
                 * @memberof infinitusai.be
                 * @interface IUserDoc
                 * @property {string|null} [email] UserDoc email
                 * @property {infinitusai.be.UserRole|null} [role] UserDoc role
                 * @property {string|null} [imageUrl] UserDoc imageUrl
                 * @property {string|null} [displayName] UserDoc displayName
                 * @property {infinitusai.be.UserStatus|null} [status] UserDoc status
                 * @property {number|Long|null} [createdAtMillis] UserDoc createdAtMillis
                 * @property {number|Long|null} [lastActiveMillis] UserDoc lastActiveMillis
                 * @property {boolean|null} [isEngineer] UserDoc isEngineer
                 * @property {number|Long|null} [lastStatusUpdateTimestampMillis] UserDoc lastStatusUpdateTimestampMillis
                 * @property {string|null} [statusLastUpdatedFromUrl] UserDoc statusLastUpdatedFromUrl
                 * @property {infinitusai.be.OperatorOnlineStatus|null} [operatorOnlineStatus] UserDoc operatorOnlineStatus
                 * @property {infinitusai.be.OperatorOfflineReason|null} [operatorOfflineReason] UserDoc operatorOfflineReason
                 */
    
                /**
                 * Constructs a new UserDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a UserDoc.
                 * @implements IUserDoc
                 * @constructor
                 * @param {infinitusai.be.IUserDoc=} [properties] Properties to set
                 */
                function UserDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UserDoc email.
                 * @member {string} email
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.email = "";
    
                /**
                 * UserDoc role.
                 * @member {infinitusai.be.UserRole} role
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.role = 0;
    
                /**
                 * UserDoc imageUrl.
                 * @member {string} imageUrl
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.imageUrl = "";
    
                /**
                 * UserDoc displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.displayName = "";
    
                /**
                 * UserDoc status.
                 * @member {infinitusai.be.UserStatus} status
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.status = 0;
    
                /**
                 * UserDoc createdAtMillis.
                 * @member {number|Long} createdAtMillis
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.createdAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UserDoc lastActiveMillis.
                 * @member {number|Long} lastActiveMillis
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.lastActiveMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UserDoc isEngineer.
                 * @member {boolean} isEngineer
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.isEngineer = false;
    
                /**
                 * UserDoc lastStatusUpdateTimestampMillis.
                 * @member {number|Long} lastStatusUpdateTimestampMillis
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.lastStatusUpdateTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UserDoc statusLastUpdatedFromUrl.
                 * @member {string} statusLastUpdatedFromUrl
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.statusLastUpdatedFromUrl = "";
    
                /**
                 * UserDoc operatorOnlineStatus.
                 * @member {infinitusai.be.OperatorOnlineStatus} operatorOnlineStatus
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.operatorOnlineStatus = 0;
    
                /**
                 * UserDoc operatorOfflineReason.
                 * @member {infinitusai.be.OperatorOfflineReason} operatorOfflineReason
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 */
                UserDoc.prototype.operatorOfflineReason = 0;
    
                /**
                 * Creates a new UserDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {infinitusai.be.IUserDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.UserDoc} UserDoc instance
                 */
                UserDoc.create = function create(properties) {
                    return new UserDoc(properties);
                };
    
                /**
                 * Encodes the specified UserDoc message. Does not implicitly {@link infinitusai.be.UserDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {infinitusai.be.IUserDoc} message UserDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.role);
                    if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.imageUrl);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.displayName);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
                    if (message.createdAtMillis != null && Object.hasOwnProperty.call(message, "createdAtMillis"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createdAtMillis);
                    if (message.lastActiveMillis != null && Object.hasOwnProperty.call(message, "lastActiveMillis"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.lastActiveMillis);
                    if (message.isEngineer != null && Object.hasOwnProperty.call(message, "isEngineer"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isEngineer);
                    if (message.lastStatusUpdateTimestampMillis != null && Object.hasOwnProperty.call(message, "lastStatusUpdateTimestampMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.lastStatusUpdateTimestampMillis);
                    if (message.statusLastUpdatedFromUrl != null && Object.hasOwnProperty.call(message, "statusLastUpdatedFromUrl"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.statusLastUpdatedFromUrl);
                    if (message.operatorOnlineStatus != null && Object.hasOwnProperty.call(message, "operatorOnlineStatus"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.operatorOnlineStatus);
                    if (message.operatorOfflineReason != null && Object.hasOwnProperty.call(message, "operatorOfflineReason"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.operatorOfflineReason);
                    return writer;
                };
    
                /**
                 * Encodes the specified UserDoc message, length delimited. Does not implicitly {@link infinitusai.be.UserDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {infinitusai.be.IUserDoc} message UserDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UserDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UserDoc} UserDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UserDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        case 2: {
                                message.role = reader.int32();
                                break;
                            }
                        case 3: {
                                message.imageUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 5: {
                                message.status = reader.int32();
                                break;
                            }
                        case 6: {
                                message.createdAtMillis = reader.int64();
                                break;
                            }
                        case 7: {
                                message.lastActiveMillis = reader.int64();
                                break;
                            }
                        case 8: {
                                message.isEngineer = reader.bool();
                                break;
                            }
                        case 9: {
                                message.lastStatusUpdateTimestampMillis = reader.int64();
                                break;
                            }
                        case 10: {
                                message.statusLastUpdatedFromUrl = reader.string();
                                break;
                            }
                        case 11: {
                                message.operatorOnlineStatus = reader.int32();
                                break;
                            }
                        case 12: {
                                message.operatorOfflineReason = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UserDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UserDoc} UserDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UserDoc message.
                 * @function verify
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.role != null && message.hasOwnProperty("role"))
                        switch (message.role) {
                        default:
                            return "role: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        if (!$util.isString(message.imageUrl))
                            return "imageUrl: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (!$util.isInteger(message.createdAtMillis) && !(message.createdAtMillis && $util.isInteger(message.createdAtMillis.low) && $util.isInteger(message.createdAtMillis.high)))
                            return "createdAtMillis: integer|Long expected";
                    if (message.lastActiveMillis != null && message.hasOwnProperty("lastActiveMillis"))
                        if (!$util.isInteger(message.lastActiveMillis) && !(message.lastActiveMillis && $util.isInteger(message.lastActiveMillis.low) && $util.isInteger(message.lastActiveMillis.high)))
                            return "lastActiveMillis: integer|Long expected";
                    if (message.isEngineer != null && message.hasOwnProperty("isEngineer"))
                        if (typeof message.isEngineer !== "boolean")
                            return "isEngineer: boolean expected";
                    if (message.lastStatusUpdateTimestampMillis != null && message.hasOwnProperty("lastStatusUpdateTimestampMillis"))
                        if (!$util.isInteger(message.lastStatusUpdateTimestampMillis) && !(message.lastStatusUpdateTimestampMillis && $util.isInteger(message.lastStatusUpdateTimestampMillis.low) && $util.isInteger(message.lastStatusUpdateTimestampMillis.high)))
                            return "lastStatusUpdateTimestampMillis: integer|Long expected";
                    if (message.statusLastUpdatedFromUrl != null && message.hasOwnProperty("statusLastUpdatedFromUrl"))
                        if (!$util.isString(message.statusLastUpdatedFromUrl))
                            return "statusLastUpdatedFromUrl: string expected";
                    if (message.operatorOnlineStatus != null && message.hasOwnProperty("operatorOnlineStatus"))
                        switch (message.operatorOnlineStatus) {
                        default:
                            return "operatorOnlineStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.operatorOfflineReason != null && message.hasOwnProperty("operatorOfflineReason"))
                        switch (message.operatorOfflineReason) {
                        default:
                            return "operatorOfflineReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a UserDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UserDoc} UserDoc
                 */
                UserDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UserDoc)
                        return object;
                    var message = new $root.infinitusai.be.UserDoc();
                    if (object.email != null)
                        message.email = String(object.email);
                    switch (object.role) {
                    default:
                        if (typeof object.role === "number") {
                            message.role = object.role;
                            break;
                        }
                        break;
                    case "USER_ROLE_UNKNOWN":
                    case 0:
                        message.role = 0;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 1:
                        message.role = 1;
                        break;
                    case "USER_ROLE_OPERATOR":
                    case 2:
                        message.role = 2;
                        break;
                    case "USER_ROLE_CUSTOMER":
                    case 3:
                        message.role = 3;
                        break;
                    case "USER_ROLE_USER":
                    case 4:
                        message.role = 4;
                        break;
                    }
                    if (object.imageUrl != null)
                        message.imageUrl = String(object.imageUrl);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "USER_STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "USER_STATUS_ACTIVE":
                    case 1:
                        message.status = 1;
                        break;
                    case "USER_STATUS_INACTIVE":
                    case 2:
                        message.status = 2;
                        break;
                    case "USER_STATUS_DEACTIVATED":
                    case 3:
                        message.status = 3;
                        break;
                    case "USER_STATUS_PENDING":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    if (object.createdAtMillis != null)
                        if ($util.Long)
                            (message.createdAtMillis = $util.Long.fromValue(object.createdAtMillis)).unsigned = false;
                        else if (typeof object.createdAtMillis === "string")
                            message.createdAtMillis = parseInt(object.createdAtMillis, 10);
                        else if (typeof object.createdAtMillis === "number")
                            message.createdAtMillis = object.createdAtMillis;
                        else if (typeof object.createdAtMillis === "object")
                            message.createdAtMillis = new $util.LongBits(object.createdAtMillis.low >>> 0, object.createdAtMillis.high >>> 0).toNumber();
                    if (object.lastActiveMillis != null)
                        if ($util.Long)
                            (message.lastActiveMillis = $util.Long.fromValue(object.lastActiveMillis)).unsigned = false;
                        else if (typeof object.lastActiveMillis === "string")
                            message.lastActiveMillis = parseInt(object.lastActiveMillis, 10);
                        else if (typeof object.lastActiveMillis === "number")
                            message.lastActiveMillis = object.lastActiveMillis;
                        else if (typeof object.lastActiveMillis === "object")
                            message.lastActiveMillis = new $util.LongBits(object.lastActiveMillis.low >>> 0, object.lastActiveMillis.high >>> 0).toNumber();
                    if (object.isEngineer != null)
                        message.isEngineer = Boolean(object.isEngineer);
                    if (object.lastStatusUpdateTimestampMillis != null)
                        if ($util.Long)
                            (message.lastStatusUpdateTimestampMillis = $util.Long.fromValue(object.lastStatusUpdateTimestampMillis)).unsigned = false;
                        else if (typeof object.lastStatusUpdateTimestampMillis === "string")
                            message.lastStatusUpdateTimestampMillis = parseInt(object.lastStatusUpdateTimestampMillis, 10);
                        else if (typeof object.lastStatusUpdateTimestampMillis === "number")
                            message.lastStatusUpdateTimestampMillis = object.lastStatusUpdateTimestampMillis;
                        else if (typeof object.lastStatusUpdateTimestampMillis === "object")
                            message.lastStatusUpdateTimestampMillis = new $util.LongBits(object.lastStatusUpdateTimestampMillis.low >>> 0, object.lastStatusUpdateTimestampMillis.high >>> 0).toNumber();
                    if (object.statusLastUpdatedFromUrl != null)
                        message.statusLastUpdatedFromUrl = String(object.statusLastUpdatedFromUrl);
                    switch (object.operatorOnlineStatus) {
                    default:
                        if (typeof object.operatorOnlineStatus === "number") {
                            message.operatorOnlineStatus = object.operatorOnlineStatus;
                            break;
                        }
                        break;
                    case "OPERATOR_ONLINE_STATUS_UNKNOWN":
                    case 0:
                        message.operatorOnlineStatus = 0;
                        break;
                    case "OPERATOR_ONLINE_STATUS_OFFLINE":
                    case 1:
                        message.operatorOnlineStatus = 1;
                        break;
                    case "OPERATOR_ONLINE_STATUS_ONLINE":
                    case 2:
                        message.operatorOnlineStatus = 2;
                        break;
                    }
                    switch (object.operatorOfflineReason) {
                    default:
                        if (typeof object.operatorOfflineReason === "number") {
                            message.operatorOfflineReason = object.operatorOfflineReason;
                            break;
                        }
                        break;
                    case "OPERATOR_OFFLINE_REASON_UNKNOWN":
                    case 0:
                        message.operatorOfflineReason = 0;
                        break;
                    case "OPERATOR_OFFLINE_REASON_MEETING":
                    case 1:
                        message.operatorOfflineReason = 1;
                        break;
                    case "OPERATOR_OFFLINE_REASON_MANAGER_MEETING":
                    case 2:
                        message.operatorOfflineReason = 2;
                        break;
                    case "OPERATOR_OFFLINE_REASON_PROJECT":
                    case 3:
                        message.operatorOfflineReason = 3;
                        break;
                    case "OPERATOR_OFFLINE_REASON_LUNCH":
                    case 4:
                        message.operatorOfflineReason = 4;
                        break;
                    case "OPERATOR_OFFLINE_REASON_BREAK":
                    case 5:
                        message.operatorOfflineReason = 5;
                        break;
                    case "OPERATOR_OFFLINE_REASON_DONE_TODAY":
                    case 6:
                        message.operatorOfflineReason = 6;
                        break;
                    case "OPERATOR_OFFLINE_REASON_OTHER":
                    case 7:
                        message.operatorOfflineReason = 7;
                        break;
                    case "OPERATOR_OFFLINE_REASON_NULL":
                    case 8:
                        message.operatorOfflineReason = 8;
                        break;
                    case "OPERATOR_OFFLINE_REASON_INACTIVE":
                    case 9:
                        message.operatorOfflineReason = 9;
                        break;
                    case "OPERATOR_OFFLINE_REASON_TRAINING":
                    case 10:
                        message.operatorOfflineReason = 10;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a UserDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {infinitusai.be.UserDoc} message UserDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.email = "";
                        object.role = options.enums === String ? "USER_ROLE_UNKNOWN" : 0;
                        object.imageUrl = "";
                        object.displayName = "";
                        object.status = options.enums === String ? "USER_STATUS_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAtMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastActiveMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastActiveMillis = options.longs === String ? "0" : 0;
                        object.isEngineer = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? "0" : 0;
                        object.statusLastUpdatedFromUrl = "";
                        object.operatorOnlineStatus = options.enums === String ? "OPERATOR_ONLINE_STATUS_UNKNOWN" : 0;
                        object.operatorOfflineReason = options.enums === String ? "OPERATOR_OFFLINE_REASON_UNKNOWN" : 0;
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.role != null && message.hasOwnProperty("role"))
                        object.role = options.enums === String ? $root.infinitusai.be.UserRole[message.role] === undefined ? message.role : $root.infinitusai.be.UserRole[message.role] : message.role;
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        object.imageUrl = message.imageUrl;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.UserStatus[message.status] === undefined ? message.status : $root.infinitusai.be.UserStatus[message.status] : message.status;
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (typeof message.createdAtMillis === "number")
                            object.createdAtMillis = options.longs === String ? String(message.createdAtMillis) : message.createdAtMillis;
                        else
                            object.createdAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtMillis) : options.longs === Number ? new $util.LongBits(message.createdAtMillis.low >>> 0, message.createdAtMillis.high >>> 0).toNumber() : message.createdAtMillis;
                    if (message.lastActiveMillis != null && message.hasOwnProperty("lastActiveMillis"))
                        if (typeof message.lastActiveMillis === "number")
                            object.lastActiveMillis = options.longs === String ? String(message.lastActiveMillis) : message.lastActiveMillis;
                        else
                            object.lastActiveMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastActiveMillis) : options.longs === Number ? new $util.LongBits(message.lastActiveMillis.low >>> 0, message.lastActiveMillis.high >>> 0).toNumber() : message.lastActiveMillis;
                    if (message.isEngineer != null && message.hasOwnProperty("isEngineer"))
                        object.isEngineer = message.isEngineer;
                    if (message.lastStatusUpdateTimestampMillis != null && message.hasOwnProperty("lastStatusUpdateTimestampMillis"))
                        if (typeof message.lastStatusUpdateTimestampMillis === "number")
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? String(message.lastStatusUpdateTimestampMillis) : message.lastStatusUpdateTimestampMillis;
                        else
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastStatusUpdateTimestampMillis) : options.longs === Number ? new $util.LongBits(message.lastStatusUpdateTimestampMillis.low >>> 0, message.lastStatusUpdateTimestampMillis.high >>> 0).toNumber() : message.lastStatusUpdateTimestampMillis;
                    if (message.statusLastUpdatedFromUrl != null && message.hasOwnProperty("statusLastUpdatedFromUrl"))
                        object.statusLastUpdatedFromUrl = message.statusLastUpdatedFromUrl;
                    if (message.operatorOnlineStatus != null && message.hasOwnProperty("operatorOnlineStatus"))
                        object.operatorOnlineStatus = options.enums === String ? $root.infinitusai.be.OperatorOnlineStatus[message.operatorOnlineStatus] === undefined ? message.operatorOnlineStatus : $root.infinitusai.be.OperatorOnlineStatus[message.operatorOnlineStatus] : message.operatorOnlineStatus;
                    if (message.operatorOfflineReason != null && message.hasOwnProperty("operatorOfflineReason"))
                        object.operatorOfflineReason = options.enums === String ? $root.infinitusai.be.OperatorOfflineReason[message.operatorOfflineReason] === undefined ? message.operatorOfflineReason : $root.infinitusai.be.OperatorOfflineReason[message.operatorOfflineReason] : message.operatorOfflineReason;
                    return object;
                };
    
                /**
                 * Converts this UserDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UserDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UserDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UserDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UserDoc";
                };
    
                return UserDoc;
            })();
    
            be.RequestUserAccessRequest = (function() {
    
                /**
                 * Properties of a RequestUserAccessRequest.
                 * @memberof infinitusai.be
                 * @interface IRequestUserAccessRequest
                 */
    
                /**
                 * Constructs a new RequestUserAccessRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RequestUserAccessRequest.
                 * @implements IRequestUserAccessRequest
                 * @constructor
                 * @param {infinitusai.be.IRequestUserAccessRequest=} [properties] Properties to set
                 */
                function RequestUserAccessRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RequestUserAccessRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {infinitusai.be.IRequestUserAccessRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RequestUserAccessRequest} RequestUserAccessRequest instance
                 */
                RequestUserAccessRequest.create = function create(properties) {
                    return new RequestUserAccessRequest(properties);
                };
    
                /**
                 * Encodes the specified RequestUserAccessRequest message. Does not implicitly {@link infinitusai.be.RequestUserAccessRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {infinitusai.be.IRequestUserAccessRequest} message RequestUserAccessRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestUserAccessRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RequestUserAccessRequest message, length delimited. Does not implicitly {@link infinitusai.be.RequestUserAccessRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {infinitusai.be.IRequestUserAccessRequest} message RequestUserAccessRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestUserAccessRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequestUserAccessRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RequestUserAccessRequest} RequestUserAccessRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestUserAccessRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RequestUserAccessRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequestUserAccessRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RequestUserAccessRequest} RequestUserAccessRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestUserAccessRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequestUserAccessRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestUserAccessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RequestUserAccessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RequestUserAccessRequest} RequestUserAccessRequest
                 */
                RequestUserAccessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RequestUserAccessRequest)
                        return object;
                    return new $root.infinitusai.be.RequestUserAccessRequest();
                };
    
                /**
                 * Creates a plain object from a RequestUserAccessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {infinitusai.be.RequestUserAccessRequest} message RequestUserAccessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestUserAccessRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RequestUserAccessRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestUserAccessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequestUserAccessRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RequestUserAccessRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequestUserAccessRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RequestUserAccessRequest";
                };
    
                return RequestUserAccessRequest;
            })();
    
            be.RequestUserAccessResponse = (function() {
    
                /**
                 * Properties of a RequestUserAccessResponse.
                 * @memberof infinitusai.be
                 * @interface IRequestUserAccessResponse
                 */
    
                /**
                 * Constructs a new RequestUserAccessResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RequestUserAccessResponse.
                 * @implements IRequestUserAccessResponse
                 * @constructor
                 * @param {infinitusai.be.IRequestUserAccessResponse=} [properties] Properties to set
                 */
                function RequestUserAccessResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RequestUserAccessResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {infinitusai.be.IRequestUserAccessResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RequestUserAccessResponse} RequestUserAccessResponse instance
                 */
                RequestUserAccessResponse.create = function create(properties) {
                    return new RequestUserAccessResponse(properties);
                };
    
                /**
                 * Encodes the specified RequestUserAccessResponse message. Does not implicitly {@link infinitusai.be.RequestUserAccessResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {infinitusai.be.IRequestUserAccessResponse} message RequestUserAccessResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestUserAccessResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RequestUserAccessResponse message, length delimited. Does not implicitly {@link infinitusai.be.RequestUserAccessResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {infinitusai.be.IRequestUserAccessResponse} message RequestUserAccessResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestUserAccessResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequestUserAccessResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RequestUserAccessResponse} RequestUserAccessResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestUserAccessResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RequestUserAccessResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequestUserAccessResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RequestUserAccessResponse} RequestUserAccessResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestUserAccessResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequestUserAccessResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestUserAccessResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RequestUserAccessResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RequestUserAccessResponse} RequestUserAccessResponse
                 */
                RequestUserAccessResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RequestUserAccessResponse)
                        return object;
                    return new $root.infinitusai.be.RequestUserAccessResponse();
                };
    
                /**
                 * Creates a plain object from a RequestUserAccessResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {infinitusai.be.RequestUserAccessResponse} message RequestUserAccessResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestUserAccessResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RequestUserAccessResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestUserAccessResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequestUserAccessResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RequestUserAccessResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequestUserAccessResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RequestUserAccessResponse";
                };
    
                return RequestUserAccessResponse;
            })();
    
            be.UpdateUserLastActiveRequest = (function() {
    
                /**
                 * Properties of an UpdateUserLastActiveRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateUserLastActiveRequest
                 * @property {string|null} [url] UpdateUserLastActiveRequest url
                 * @property {string|null} [fasttrackRole] UpdateUserLastActiveRequest fasttrackRole
                 */
    
                /**
                 * Constructs a new UpdateUserLastActiveRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateUserLastActiveRequest.
                 * @implements IUpdateUserLastActiveRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateUserLastActiveRequest=} [properties] Properties to set
                 */
                function UpdateUserLastActiveRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateUserLastActiveRequest url.
                 * @member {string} url
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @instance
                 */
                UpdateUserLastActiveRequest.prototype.url = "";
    
                /**
                 * UpdateUserLastActiveRequest fasttrackRole.
                 * @member {string} fasttrackRole
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @instance
                 */
                UpdateUserLastActiveRequest.prototype.fasttrackRole = "";
    
                /**
                 * Creates a new UpdateUserLastActiveRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {infinitusai.be.IUpdateUserLastActiveRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateUserLastActiveRequest} UpdateUserLastActiveRequest instance
                 */
                UpdateUserLastActiveRequest.create = function create(properties) {
                    return new UpdateUserLastActiveRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateUserLastActiveRequest message. Does not implicitly {@link infinitusai.be.UpdateUserLastActiveRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {infinitusai.be.IUpdateUserLastActiveRequest} message UpdateUserLastActiveRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateUserLastActiveRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                    if (message.fasttrackRole != null && Object.hasOwnProperty.call(message, "fasttrackRole"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fasttrackRole);
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateUserLastActiveRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateUserLastActiveRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {infinitusai.be.IUpdateUserLastActiveRequest} message UpdateUserLastActiveRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateUserLastActiveRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateUserLastActiveRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateUserLastActiveRequest} UpdateUserLastActiveRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateUserLastActiveRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateUserLastActiveRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.url = reader.string();
                                break;
                            }
                        case 2: {
                                message.fasttrackRole = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateUserLastActiveRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateUserLastActiveRequest} UpdateUserLastActiveRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateUserLastActiveRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateUserLastActiveRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserLastActiveRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fasttrackRole != null && message.hasOwnProperty("fasttrackRole"))
                        if (!$util.isString(message.fasttrackRole))
                            return "fasttrackRole: string expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateUserLastActiveRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateUserLastActiveRequest} UpdateUserLastActiveRequest
                 */
                UpdateUserLastActiveRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateUserLastActiveRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateUserLastActiveRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fasttrackRole != null)
                        message.fasttrackRole = String(object.fasttrackRole);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateUserLastActiveRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {infinitusai.be.UpdateUserLastActiveRequest} message UpdateUserLastActiveRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserLastActiveRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fasttrackRole = "";
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fasttrackRole != null && message.hasOwnProperty("fasttrackRole"))
                        object.fasttrackRole = message.fasttrackRole;
                    return object;
                };
    
                /**
                 * Converts this UpdateUserLastActiveRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserLastActiveRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateUserLastActiveRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateUserLastActiveRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateUserLastActiveRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateUserLastActiveRequest";
                };
    
                return UpdateUserLastActiveRequest;
            })();
    
            be.UpdateUserLastActiveResponse = (function() {
    
                /**
                 * Properties of an UpdateUserLastActiveResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateUserLastActiveResponse
                 */
    
                /**
                 * Constructs a new UpdateUserLastActiveResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateUserLastActiveResponse.
                 * @implements IUpdateUserLastActiveResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateUserLastActiveResponse=} [properties] Properties to set
                 */
                function UpdateUserLastActiveResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpdateUserLastActiveResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {infinitusai.be.IUpdateUserLastActiveResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateUserLastActiveResponse} UpdateUserLastActiveResponse instance
                 */
                UpdateUserLastActiveResponse.create = function create(properties) {
                    return new UpdateUserLastActiveResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateUserLastActiveResponse message. Does not implicitly {@link infinitusai.be.UpdateUserLastActiveResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {infinitusai.be.IUpdateUserLastActiveResponse} message UpdateUserLastActiveResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateUserLastActiveResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateUserLastActiveResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateUserLastActiveResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {infinitusai.be.IUpdateUserLastActiveResponse} message UpdateUserLastActiveResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateUserLastActiveResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateUserLastActiveResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateUserLastActiveResponse} UpdateUserLastActiveResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateUserLastActiveResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateUserLastActiveResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateUserLastActiveResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateUserLastActiveResponse} UpdateUserLastActiveResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateUserLastActiveResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateUserLastActiveResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserLastActiveResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateUserLastActiveResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateUserLastActiveResponse} UpdateUserLastActiveResponse
                 */
                UpdateUserLastActiveResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateUserLastActiveResponse)
                        return object;
                    return new $root.infinitusai.be.UpdateUserLastActiveResponse();
                };
    
                /**
                 * Creates a plain object from an UpdateUserLastActiveResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {infinitusai.be.UpdateUserLastActiveResponse} message UpdateUserLastActiveResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserLastActiveResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpdateUserLastActiveResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserLastActiveResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateUserLastActiveResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateUserLastActiveResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateUserLastActiveResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateUserLastActiveResponse";
                };
    
                return UpdateUserLastActiveResponse;
            })();
    
            /**
             * RecordedGreetingApprovalStatus enum.
             * @name infinitusai.be.RecordedGreetingApprovalStatus
             * @enum {number}
             * @property {number} GREETING_PENDING=0 GREETING_PENDING value
             * @property {number} GREETING_APPROVED=1 GREETING_APPROVED value
             * @property {number} GREETING_REJECTED=2 GREETING_REJECTED value
             */
            be.RecordedGreetingApprovalStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "GREETING_PENDING"] = 0;
                values[valuesById[1] = "GREETING_APPROVED"] = 1;
                values[valuesById[2] = "GREETING_REJECTED"] = 2;
                return values;
            })();
    
            be.RecordedGreeting = (function() {
    
                /**
                 * Properties of a RecordedGreeting.
                 * @memberof infinitusai.be
                 * @interface IRecordedGreeting
                 * @property {string|null} [orgUuid] RecordedGreeting orgUuid
                 * @property {string|null} [userEmail] RecordedGreeting userEmail
                 * @property {string|null} [uuid] RecordedGreeting uuid
                 * @property {number|Long|null} [createTimeMillis] RecordedGreeting createTimeMillis
                 * @property {string|null} [greetingName] RecordedGreeting greetingName
                 * @property {string|null} [recordedText] RecordedGreeting recordedText
                 * @property {string|null} [gcsPath] RecordedGreeting gcsPath
                 * @property {string|null} [gcsSignedUrl] RecordedGreeting gcsSignedUrl
                 * @property {infinitusai.be.RecordedGreetingApprovalStatus|null} [approvalStatus] RecordedGreeting approvalStatus
                 * @property {string|null} [approvedBy] RecordedGreeting approvedBy
                 */
    
                /**
                 * Constructs a new RecordedGreeting.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RecordedGreeting.
                 * @implements IRecordedGreeting
                 * @constructor
                 * @param {infinitusai.be.IRecordedGreeting=} [properties] Properties to set
                 */
                function RecordedGreeting(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RecordedGreeting orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.orgUuid = "";
    
                /**
                 * RecordedGreeting userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.userEmail = "";
    
                /**
                 * RecordedGreeting uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.uuid = "";
    
                /**
                 * RecordedGreeting createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * RecordedGreeting greetingName.
                 * @member {string} greetingName
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.greetingName = "";
    
                /**
                 * RecordedGreeting recordedText.
                 * @member {string} recordedText
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.recordedText = "";
    
                /**
                 * RecordedGreeting gcsPath.
                 * @member {string} gcsPath
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.gcsPath = "";
    
                /**
                 * RecordedGreeting gcsSignedUrl.
                 * @member {string} gcsSignedUrl
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.gcsSignedUrl = "";
    
                /**
                 * RecordedGreeting approvalStatus.
                 * @member {infinitusai.be.RecordedGreetingApprovalStatus} approvalStatus
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.approvalStatus = 0;
    
                /**
                 * RecordedGreeting approvedBy.
                 * @member {string} approvedBy
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 */
                RecordedGreeting.prototype.approvedBy = "";
    
                /**
                 * Creates a new RecordedGreeting instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {infinitusai.be.IRecordedGreeting=} [properties] Properties to set
                 * @returns {infinitusai.be.RecordedGreeting} RecordedGreeting instance
                 */
                RecordedGreeting.create = function create(properties) {
                    return new RecordedGreeting(properties);
                };
    
                /**
                 * Encodes the specified RecordedGreeting message. Does not implicitly {@link infinitusai.be.RecordedGreeting.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {infinitusai.be.IRecordedGreeting} message RecordedGreeting message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordedGreeting.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userEmail);
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.uuid);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createTimeMillis);
                    if (message.greetingName != null && Object.hasOwnProperty.call(message, "greetingName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.greetingName);
                    if (message.recordedText != null && Object.hasOwnProperty.call(message, "recordedText"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.recordedText);
                    if (message.gcsPath != null && Object.hasOwnProperty.call(message, "gcsPath"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.gcsPath);
                    if (message.gcsSignedUrl != null && Object.hasOwnProperty.call(message, "gcsSignedUrl"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.gcsSignedUrl);
                    if (message.approvalStatus != null && Object.hasOwnProperty.call(message, "approvalStatus"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.approvalStatus);
                    if (message.approvedBy != null && Object.hasOwnProperty.call(message, "approvedBy"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.approvedBy);
                    return writer;
                };
    
                /**
                 * Encodes the specified RecordedGreeting message, length delimited. Does not implicitly {@link infinitusai.be.RecordedGreeting.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {infinitusai.be.IRecordedGreeting} message RecordedGreeting message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordedGreeting.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RecordedGreeting message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RecordedGreeting} RecordedGreeting
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordedGreeting.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RecordedGreeting();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 3: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 5: {
                                message.greetingName = reader.string();
                                break;
                            }
                        case 6: {
                                message.recordedText = reader.string();
                                break;
                            }
                        case 7: {
                                message.gcsPath = reader.string();
                                break;
                            }
                        case 8: {
                                message.gcsSignedUrl = reader.string();
                                break;
                            }
                        case 9: {
                                message.approvalStatus = reader.int32();
                                break;
                            }
                        case 10: {
                                message.approvedBy = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RecordedGreeting message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RecordedGreeting} RecordedGreeting
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordedGreeting.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RecordedGreeting message.
                 * @function verify
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RecordedGreeting.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.greetingName != null && message.hasOwnProperty("greetingName"))
                        if (!$util.isString(message.greetingName))
                            return "greetingName: string expected";
                    if (message.recordedText != null && message.hasOwnProperty("recordedText"))
                        if (!$util.isString(message.recordedText))
                            return "recordedText: string expected";
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        if (!$util.isString(message.gcsPath))
                            return "gcsPath: string expected";
                    if (message.gcsSignedUrl != null && message.hasOwnProperty("gcsSignedUrl"))
                        if (!$util.isString(message.gcsSignedUrl))
                            return "gcsSignedUrl: string expected";
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus"))
                        switch (message.approvalStatus) {
                        default:
                            return "approvalStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.approvedBy != null && message.hasOwnProperty("approvedBy"))
                        if (!$util.isString(message.approvedBy))
                            return "approvedBy: string expected";
                    return null;
                };
    
                /**
                 * Creates a RecordedGreeting message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RecordedGreeting} RecordedGreeting
                 */
                RecordedGreeting.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RecordedGreeting)
                        return object;
                    var message = new $root.infinitusai.be.RecordedGreeting();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.greetingName != null)
                        message.greetingName = String(object.greetingName);
                    if (object.recordedText != null)
                        message.recordedText = String(object.recordedText);
                    if (object.gcsPath != null)
                        message.gcsPath = String(object.gcsPath);
                    if (object.gcsSignedUrl != null)
                        message.gcsSignedUrl = String(object.gcsSignedUrl);
                    switch (object.approvalStatus) {
                    default:
                        if (typeof object.approvalStatus === "number") {
                            message.approvalStatus = object.approvalStatus;
                            break;
                        }
                        break;
                    case "GREETING_PENDING":
                    case 0:
                        message.approvalStatus = 0;
                        break;
                    case "GREETING_APPROVED":
                    case 1:
                        message.approvalStatus = 1;
                        break;
                    case "GREETING_REJECTED":
                    case 2:
                        message.approvalStatus = 2;
                        break;
                    }
                    if (object.approvedBy != null)
                        message.approvedBy = String(object.approvedBy);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RecordedGreeting message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {infinitusai.be.RecordedGreeting} message RecordedGreeting
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordedGreeting.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.userEmail = "";
                        object.uuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        object.greetingName = "";
                        object.recordedText = "";
                        object.gcsPath = "";
                        object.gcsSignedUrl = "";
                        object.approvalStatus = options.enums === String ? "GREETING_PENDING" : 0;
                        object.approvedBy = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.greetingName != null && message.hasOwnProperty("greetingName"))
                        object.greetingName = message.greetingName;
                    if (message.recordedText != null && message.hasOwnProperty("recordedText"))
                        object.recordedText = message.recordedText;
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        object.gcsPath = message.gcsPath;
                    if (message.gcsSignedUrl != null && message.hasOwnProperty("gcsSignedUrl"))
                        object.gcsSignedUrl = message.gcsSignedUrl;
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus"))
                        object.approvalStatus = options.enums === String ? $root.infinitusai.be.RecordedGreetingApprovalStatus[message.approvalStatus] === undefined ? message.approvalStatus : $root.infinitusai.be.RecordedGreetingApprovalStatus[message.approvalStatus] : message.approvalStatus;
                    if (message.approvedBy != null && message.hasOwnProperty("approvedBy"))
                        object.approvedBy = message.approvedBy;
                    return object;
                };
    
                /**
                 * Converts this RecordedGreeting to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RecordedGreeting
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordedGreeting.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RecordedGreeting
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RecordedGreeting
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordedGreeting.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RecordedGreeting";
                };
    
                return RecordedGreeting;
            })();
    
            be.RecordedGreetingTemplate = (function() {
    
                /**
                 * Properties of a RecordedGreetingTemplate.
                 * @memberof infinitusai.be
                 * @interface IRecordedGreetingTemplate
                 * @property {string|null} [greetingName] RecordedGreetingTemplate greetingName
                 * @property {string|null} [recordedText] RecordedGreetingTemplate recordedText
                 */
    
                /**
                 * Constructs a new RecordedGreetingTemplate.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RecordedGreetingTemplate.
                 * @implements IRecordedGreetingTemplate
                 * @constructor
                 * @param {infinitusai.be.IRecordedGreetingTemplate=} [properties] Properties to set
                 */
                function RecordedGreetingTemplate(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RecordedGreetingTemplate greetingName.
                 * @member {string} greetingName
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @instance
                 */
                RecordedGreetingTemplate.prototype.greetingName = "";
    
                /**
                 * RecordedGreetingTemplate recordedText.
                 * @member {string} recordedText
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @instance
                 */
                RecordedGreetingTemplate.prototype.recordedText = "";
    
                /**
                 * Creates a new RecordedGreetingTemplate instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {infinitusai.be.IRecordedGreetingTemplate=} [properties] Properties to set
                 * @returns {infinitusai.be.RecordedGreetingTemplate} RecordedGreetingTemplate instance
                 */
                RecordedGreetingTemplate.create = function create(properties) {
                    return new RecordedGreetingTemplate(properties);
                };
    
                /**
                 * Encodes the specified RecordedGreetingTemplate message. Does not implicitly {@link infinitusai.be.RecordedGreetingTemplate.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {infinitusai.be.IRecordedGreetingTemplate} message RecordedGreetingTemplate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordedGreetingTemplate.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.greetingName != null && Object.hasOwnProperty.call(message, "greetingName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.greetingName);
                    if (message.recordedText != null && Object.hasOwnProperty.call(message, "recordedText"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.recordedText);
                    return writer;
                };
    
                /**
                 * Encodes the specified RecordedGreetingTemplate message, length delimited. Does not implicitly {@link infinitusai.be.RecordedGreetingTemplate.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {infinitusai.be.IRecordedGreetingTemplate} message RecordedGreetingTemplate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordedGreetingTemplate.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RecordedGreetingTemplate message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RecordedGreetingTemplate} RecordedGreetingTemplate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordedGreetingTemplate.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RecordedGreetingTemplate();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 5: {
                                message.greetingName = reader.string();
                                break;
                            }
                        case 6: {
                                message.recordedText = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RecordedGreetingTemplate message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RecordedGreetingTemplate} RecordedGreetingTemplate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordedGreetingTemplate.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RecordedGreetingTemplate message.
                 * @function verify
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RecordedGreetingTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.greetingName != null && message.hasOwnProperty("greetingName"))
                        if (!$util.isString(message.greetingName))
                            return "greetingName: string expected";
                    if (message.recordedText != null && message.hasOwnProperty("recordedText"))
                        if (!$util.isString(message.recordedText))
                            return "recordedText: string expected";
                    return null;
                };
    
                /**
                 * Creates a RecordedGreetingTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RecordedGreetingTemplate} RecordedGreetingTemplate
                 */
                RecordedGreetingTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RecordedGreetingTemplate)
                        return object;
                    var message = new $root.infinitusai.be.RecordedGreetingTemplate();
                    if (object.greetingName != null)
                        message.greetingName = String(object.greetingName);
                    if (object.recordedText != null)
                        message.recordedText = String(object.recordedText);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RecordedGreetingTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {infinitusai.be.RecordedGreetingTemplate} message RecordedGreetingTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordedGreetingTemplate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.greetingName = "";
                        object.recordedText = "";
                    }
                    if (message.greetingName != null && message.hasOwnProperty("greetingName"))
                        object.greetingName = message.greetingName;
                    if (message.recordedText != null && message.hasOwnProperty("recordedText"))
                        object.recordedText = message.recordedText;
                    return object;
                };
    
                /**
                 * Converts this RecordedGreetingTemplate to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordedGreetingTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RecordedGreetingTemplate
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RecordedGreetingTemplate
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordedGreetingTemplate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RecordedGreetingTemplate";
                };
    
                return RecordedGreetingTemplate;
            })();
    
            be.ListGreetingsRequest = (function() {
    
                /**
                 * Properties of a ListGreetingsRequest.
                 * @memberof infinitusai.be
                 * @interface IListGreetingsRequest
                 */
    
                /**
                 * Constructs a new ListGreetingsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ListGreetingsRequest.
                 * @implements IListGreetingsRequest
                 * @constructor
                 * @param {infinitusai.be.IListGreetingsRequest=} [properties] Properties to set
                 */
                function ListGreetingsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ListGreetingsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {infinitusai.be.IListGreetingsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ListGreetingsRequest} ListGreetingsRequest instance
                 */
                ListGreetingsRequest.create = function create(properties) {
                    return new ListGreetingsRequest(properties);
                };
    
                /**
                 * Encodes the specified ListGreetingsRequest message. Does not implicitly {@link infinitusai.be.ListGreetingsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {infinitusai.be.IListGreetingsRequest} message ListGreetingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListGreetingsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ListGreetingsRequest message, length delimited. Does not implicitly {@link infinitusai.be.ListGreetingsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {infinitusai.be.IListGreetingsRequest} message ListGreetingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListGreetingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ListGreetingsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ListGreetingsRequest} ListGreetingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListGreetingsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ListGreetingsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ListGreetingsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ListGreetingsRequest} ListGreetingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListGreetingsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ListGreetingsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListGreetingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ListGreetingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ListGreetingsRequest} ListGreetingsRequest
                 */
                ListGreetingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ListGreetingsRequest)
                        return object;
                    return new $root.infinitusai.be.ListGreetingsRequest();
                };
    
                /**
                 * Creates a plain object from a ListGreetingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {infinitusai.be.ListGreetingsRequest} message ListGreetingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListGreetingsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ListGreetingsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListGreetingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ListGreetingsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ListGreetingsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListGreetingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ListGreetingsRequest";
                };
    
                return ListGreetingsRequest;
            })();
    
            be.ListGreetingsResponse = (function() {
    
                /**
                 * Properties of a ListGreetingsResponse.
                 * @memberof infinitusai.be
                 * @interface IListGreetingsResponse
                 * @property {Array.<infinitusai.be.IRecordedGreeting>|null} [greetings] ListGreetingsResponse greetings
                 */
    
                /**
                 * Constructs a new ListGreetingsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ListGreetingsResponse.
                 * @implements IListGreetingsResponse
                 * @constructor
                 * @param {infinitusai.be.IListGreetingsResponse=} [properties] Properties to set
                 */
                function ListGreetingsResponse(properties) {
                    this.greetings = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ListGreetingsResponse greetings.
                 * @member {Array.<infinitusai.be.IRecordedGreeting>} greetings
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @instance
                 */
                ListGreetingsResponse.prototype.greetings = $util.emptyArray;
    
                /**
                 * Creates a new ListGreetingsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {infinitusai.be.IListGreetingsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ListGreetingsResponse} ListGreetingsResponse instance
                 */
                ListGreetingsResponse.create = function create(properties) {
                    return new ListGreetingsResponse(properties);
                };
    
                /**
                 * Encodes the specified ListGreetingsResponse message. Does not implicitly {@link infinitusai.be.ListGreetingsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {infinitusai.be.IListGreetingsResponse} message ListGreetingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListGreetingsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.greetings != null && message.greetings.length)
                        for (var i = 0; i < message.greetings.length; ++i)
                            $root.infinitusai.be.RecordedGreeting.encode(message.greetings[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ListGreetingsResponse message, length delimited. Does not implicitly {@link infinitusai.be.ListGreetingsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {infinitusai.be.IListGreetingsResponse} message ListGreetingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListGreetingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ListGreetingsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ListGreetingsResponse} ListGreetingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListGreetingsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ListGreetingsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.greetings && message.greetings.length))
                                    message.greetings = [];
                                message.greetings.push($root.infinitusai.be.RecordedGreeting.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ListGreetingsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ListGreetingsResponse} ListGreetingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListGreetingsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ListGreetingsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListGreetingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.greetings != null && message.hasOwnProperty("greetings")) {
                        if (!Array.isArray(message.greetings))
                            return "greetings: array expected";
                        for (var i = 0; i < message.greetings.length; ++i) {
                            var error = $root.infinitusai.be.RecordedGreeting.verify(message.greetings[i]);
                            if (error)
                                return "greetings." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ListGreetingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ListGreetingsResponse} ListGreetingsResponse
                 */
                ListGreetingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ListGreetingsResponse)
                        return object;
                    var message = new $root.infinitusai.be.ListGreetingsResponse();
                    if (object.greetings) {
                        if (!Array.isArray(object.greetings))
                            throw TypeError(".infinitusai.be.ListGreetingsResponse.greetings: array expected");
                        message.greetings = [];
                        for (var i = 0; i < object.greetings.length; ++i) {
                            if (typeof object.greetings[i] !== "object")
                                throw TypeError(".infinitusai.be.ListGreetingsResponse.greetings: object expected");
                            message.greetings[i] = $root.infinitusai.be.RecordedGreeting.fromObject(object.greetings[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ListGreetingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {infinitusai.be.ListGreetingsResponse} message ListGreetingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListGreetingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.greetings = [];
                    if (message.greetings && message.greetings.length) {
                        object.greetings = [];
                        for (var j = 0; j < message.greetings.length; ++j)
                            object.greetings[j] = $root.infinitusai.be.RecordedGreeting.toObject(message.greetings[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ListGreetingsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListGreetingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ListGreetingsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ListGreetingsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListGreetingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ListGreetingsResponse";
                };
    
                return ListGreetingsResponse;
            })();
    
            be.ListGreetingTemplatesRequest = (function() {
    
                /**
                 * Properties of a ListGreetingTemplatesRequest.
                 * @memberof infinitusai.be
                 * @interface IListGreetingTemplatesRequest
                 */
    
                /**
                 * Constructs a new ListGreetingTemplatesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ListGreetingTemplatesRequest.
                 * @implements IListGreetingTemplatesRequest
                 * @constructor
                 * @param {infinitusai.be.IListGreetingTemplatesRequest=} [properties] Properties to set
                 */
                function ListGreetingTemplatesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ListGreetingTemplatesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {infinitusai.be.IListGreetingTemplatesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ListGreetingTemplatesRequest} ListGreetingTemplatesRequest instance
                 */
                ListGreetingTemplatesRequest.create = function create(properties) {
                    return new ListGreetingTemplatesRequest(properties);
                };
    
                /**
                 * Encodes the specified ListGreetingTemplatesRequest message. Does not implicitly {@link infinitusai.be.ListGreetingTemplatesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {infinitusai.be.IListGreetingTemplatesRequest} message ListGreetingTemplatesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListGreetingTemplatesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ListGreetingTemplatesRequest message, length delimited. Does not implicitly {@link infinitusai.be.ListGreetingTemplatesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {infinitusai.be.IListGreetingTemplatesRequest} message ListGreetingTemplatesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListGreetingTemplatesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ListGreetingTemplatesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ListGreetingTemplatesRequest} ListGreetingTemplatesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListGreetingTemplatesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ListGreetingTemplatesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ListGreetingTemplatesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ListGreetingTemplatesRequest} ListGreetingTemplatesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListGreetingTemplatesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ListGreetingTemplatesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListGreetingTemplatesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ListGreetingTemplatesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ListGreetingTemplatesRequest} ListGreetingTemplatesRequest
                 */
                ListGreetingTemplatesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ListGreetingTemplatesRequest)
                        return object;
                    return new $root.infinitusai.be.ListGreetingTemplatesRequest();
                };
    
                /**
                 * Creates a plain object from a ListGreetingTemplatesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {infinitusai.be.ListGreetingTemplatesRequest} message ListGreetingTemplatesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListGreetingTemplatesRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ListGreetingTemplatesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListGreetingTemplatesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ListGreetingTemplatesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ListGreetingTemplatesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListGreetingTemplatesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ListGreetingTemplatesRequest";
                };
    
                return ListGreetingTemplatesRequest;
            })();
    
            be.ListGreetingTemplatesResponse = (function() {
    
                /**
                 * Properties of a ListGreetingTemplatesResponse.
                 * @memberof infinitusai.be
                 * @interface IListGreetingTemplatesResponse
                 * @property {Array.<infinitusai.be.IRecordedGreetingTemplate>|null} [greetings] ListGreetingTemplatesResponse greetings
                 */
    
                /**
                 * Constructs a new ListGreetingTemplatesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ListGreetingTemplatesResponse.
                 * @implements IListGreetingTemplatesResponse
                 * @constructor
                 * @param {infinitusai.be.IListGreetingTemplatesResponse=} [properties] Properties to set
                 */
                function ListGreetingTemplatesResponse(properties) {
                    this.greetings = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ListGreetingTemplatesResponse greetings.
                 * @member {Array.<infinitusai.be.IRecordedGreetingTemplate>} greetings
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @instance
                 */
                ListGreetingTemplatesResponse.prototype.greetings = $util.emptyArray;
    
                /**
                 * Creates a new ListGreetingTemplatesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {infinitusai.be.IListGreetingTemplatesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ListGreetingTemplatesResponse} ListGreetingTemplatesResponse instance
                 */
                ListGreetingTemplatesResponse.create = function create(properties) {
                    return new ListGreetingTemplatesResponse(properties);
                };
    
                /**
                 * Encodes the specified ListGreetingTemplatesResponse message. Does not implicitly {@link infinitusai.be.ListGreetingTemplatesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {infinitusai.be.IListGreetingTemplatesResponse} message ListGreetingTemplatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListGreetingTemplatesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.greetings != null && message.greetings.length)
                        for (var i = 0; i < message.greetings.length; ++i)
                            $root.infinitusai.be.RecordedGreetingTemplate.encode(message.greetings[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ListGreetingTemplatesResponse message, length delimited. Does not implicitly {@link infinitusai.be.ListGreetingTemplatesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {infinitusai.be.IListGreetingTemplatesResponse} message ListGreetingTemplatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListGreetingTemplatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ListGreetingTemplatesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ListGreetingTemplatesResponse} ListGreetingTemplatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListGreetingTemplatesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ListGreetingTemplatesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.greetings && message.greetings.length))
                                    message.greetings = [];
                                message.greetings.push($root.infinitusai.be.RecordedGreetingTemplate.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ListGreetingTemplatesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ListGreetingTemplatesResponse} ListGreetingTemplatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListGreetingTemplatesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ListGreetingTemplatesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListGreetingTemplatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.greetings != null && message.hasOwnProperty("greetings")) {
                        if (!Array.isArray(message.greetings))
                            return "greetings: array expected";
                        for (var i = 0; i < message.greetings.length; ++i) {
                            var error = $root.infinitusai.be.RecordedGreetingTemplate.verify(message.greetings[i]);
                            if (error)
                                return "greetings." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ListGreetingTemplatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ListGreetingTemplatesResponse} ListGreetingTemplatesResponse
                 */
                ListGreetingTemplatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ListGreetingTemplatesResponse)
                        return object;
                    var message = new $root.infinitusai.be.ListGreetingTemplatesResponse();
                    if (object.greetings) {
                        if (!Array.isArray(object.greetings))
                            throw TypeError(".infinitusai.be.ListGreetingTemplatesResponse.greetings: array expected");
                        message.greetings = [];
                        for (var i = 0; i < object.greetings.length; ++i) {
                            if (typeof object.greetings[i] !== "object")
                                throw TypeError(".infinitusai.be.ListGreetingTemplatesResponse.greetings: object expected");
                            message.greetings[i] = $root.infinitusai.be.RecordedGreetingTemplate.fromObject(object.greetings[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ListGreetingTemplatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {infinitusai.be.ListGreetingTemplatesResponse} message ListGreetingTemplatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListGreetingTemplatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.greetings = [];
                    if (message.greetings && message.greetings.length) {
                        object.greetings = [];
                        for (var j = 0; j < message.greetings.length; ++j)
                            object.greetings[j] = $root.infinitusai.be.RecordedGreetingTemplate.toObject(message.greetings[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ListGreetingTemplatesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListGreetingTemplatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ListGreetingTemplatesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ListGreetingTemplatesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListGreetingTemplatesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ListGreetingTemplatesResponse";
                };
    
                return ListGreetingTemplatesResponse;
            })();
    
            be.PortalGetCallInputsRequest = (function() {
    
                /**
                 * Properties of a PortalGetCallInputsRequest.
                 * @memberof infinitusai.be
                 * @interface IPortalGetCallInputsRequest
                 * @property {boolean|null} [exposePhi] PortalGetCallInputsRequest exposePhi
                 * @property {string|null} [orgUuid] PortalGetCallInputsRequest orgUuid
                 * @property {string|null} [taskUuid] PortalGetCallInputsRequest taskUuid
                 * @property {string|null} [callUuid] PortalGetCallInputsRequest callUuid
                 */
    
                /**
                 * Constructs a new PortalGetCallInputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PortalGetCallInputsRequest.
                 * @implements IPortalGetCallInputsRequest
                 * @constructor
                 * @param {infinitusai.be.IPortalGetCallInputsRequest=} [properties] Properties to set
                 */
                function PortalGetCallInputsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PortalGetCallInputsRequest exposePhi.
                 * @member {boolean} exposePhi
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @instance
                 */
                PortalGetCallInputsRequest.prototype.exposePhi = false;
    
                /**
                 * PortalGetCallInputsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @instance
                 */
                PortalGetCallInputsRequest.prototype.orgUuid = "";
    
                /**
                 * PortalGetCallInputsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @instance
                 */
                PortalGetCallInputsRequest.prototype.taskUuid = "";
    
                /**
                 * PortalGetCallInputsRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @instance
                 */
                PortalGetCallInputsRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new PortalGetCallInputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {infinitusai.be.IPortalGetCallInputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.PortalGetCallInputsRequest} PortalGetCallInputsRequest instance
                 */
                PortalGetCallInputsRequest.create = function create(properties) {
                    return new PortalGetCallInputsRequest(properties);
                };
    
                /**
                 * Encodes the specified PortalGetCallInputsRequest message. Does not implicitly {@link infinitusai.be.PortalGetCallInputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {infinitusai.be.IPortalGetCallInputsRequest} message PortalGetCallInputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalGetCallInputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.exposePhi != null && Object.hasOwnProperty.call(message, "exposePhi"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.exposePhi);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified PortalGetCallInputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.PortalGetCallInputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {infinitusai.be.IPortalGetCallInputsRequest} message PortalGetCallInputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalGetCallInputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PortalGetCallInputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PortalGetCallInputsRequest} PortalGetCallInputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalGetCallInputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PortalGetCallInputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.exposePhi = reader.bool();
                                break;
                            }
                        case 2: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PortalGetCallInputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PortalGetCallInputsRequest} PortalGetCallInputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalGetCallInputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PortalGetCallInputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PortalGetCallInputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.exposePhi != null && message.hasOwnProperty("exposePhi"))
                        if (typeof message.exposePhi !== "boolean")
                            return "exposePhi: boolean expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a PortalGetCallInputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PortalGetCallInputsRequest} PortalGetCallInputsRequest
                 */
                PortalGetCallInputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PortalGetCallInputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.PortalGetCallInputsRequest();
                    if (object.exposePhi != null)
                        message.exposePhi = Boolean(object.exposePhi);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PortalGetCallInputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {infinitusai.be.PortalGetCallInputsRequest} message PortalGetCallInputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PortalGetCallInputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.exposePhi = false;
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.exposePhi != null && message.hasOwnProperty("exposePhi"))
                        object.exposePhi = message.exposePhi;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this PortalGetCallInputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PortalGetCallInputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PortalGetCallInputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PortalGetCallInputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PortalGetCallInputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PortalGetCallInputsRequest";
                };
    
                return PortalGetCallInputsRequest;
            })();
    
            be.PortalGetCallInputsResponse = (function() {
    
                /**
                 * Properties of a PortalGetCallInputsResponse.
                 * @memberof infinitusai.be
                 * @interface IPortalGetCallInputsResponse
                 * @property {infinitusai.be.IBVCallInputs|null} [callInputs] PortalGetCallInputsResponse callInputs
                 * @property {string|null} [customerAssignedId] PortalGetCallInputsResponse customerAssignedId
                 * @property {string|null} [programName] PortalGetCallInputsResponse programName
                 */
    
                /**
                 * Constructs a new PortalGetCallInputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PortalGetCallInputsResponse.
                 * @implements IPortalGetCallInputsResponse
                 * @constructor
                 * @param {infinitusai.be.IPortalGetCallInputsResponse=} [properties] Properties to set
                 */
                function PortalGetCallInputsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PortalGetCallInputsResponse callInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} callInputs
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @instance
                 */
                PortalGetCallInputsResponse.prototype.callInputs = null;
    
                /**
                 * PortalGetCallInputsResponse customerAssignedId.
                 * @member {string} customerAssignedId
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @instance
                 */
                PortalGetCallInputsResponse.prototype.customerAssignedId = "";
    
                /**
                 * PortalGetCallInputsResponse programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @instance
                 */
                PortalGetCallInputsResponse.prototype.programName = "";
    
                /**
                 * Creates a new PortalGetCallInputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {infinitusai.be.IPortalGetCallInputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.PortalGetCallInputsResponse} PortalGetCallInputsResponse instance
                 */
                PortalGetCallInputsResponse.create = function create(properties) {
                    return new PortalGetCallInputsResponse(properties);
                };
    
                /**
                 * Encodes the specified PortalGetCallInputsResponse message. Does not implicitly {@link infinitusai.be.PortalGetCallInputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {infinitusai.be.IPortalGetCallInputsResponse} message PortalGetCallInputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalGetCallInputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callInputs != null && Object.hasOwnProperty.call(message, "callInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.callInputs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.customerAssignedId != null && Object.hasOwnProperty.call(message, "customerAssignedId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerAssignedId);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.programName);
                    return writer;
                };
    
                /**
                 * Encodes the specified PortalGetCallInputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.PortalGetCallInputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {infinitusai.be.IPortalGetCallInputsResponse} message PortalGetCallInputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalGetCallInputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PortalGetCallInputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PortalGetCallInputsResponse} PortalGetCallInputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalGetCallInputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PortalGetCallInputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.customerAssignedId = reader.string();
                                break;
                            }
                        case 3: {
                                message.programName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PortalGetCallInputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PortalGetCallInputsResponse} PortalGetCallInputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalGetCallInputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PortalGetCallInputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PortalGetCallInputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callInputs != null && message.hasOwnProperty("callInputs")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.callInputs);
                        if (error)
                            return "callInputs." + error;
                    }
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        if (!$util.isString(message.customerAssignedId))
                            return "customerAssignedId: string expected";
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    return null;
                };
    
                /**
                 * Creates a PortalGetCallInputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PortalGetCallInputsResponse} PortalGetCallInputsResponse
                 */
                PortalGetCallInputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PortalGetCallInputsResponse)
                        return object;
                    var message = new $root.infinitusai.be.PortalGetCallInputsResponse();
                    if (object.callInputs != null) {
                        if (typeof object.callInputs !== "object")
                            throw TypeError(".infinitusai.be.PortalGetCallInputsResponse.callInputs: object expected");
                        message.callInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.callInputs);
                    }
                    if (object.customerAssignedId != null)
                        message.customerAssignedId = String(object.customerAssignedId);
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PortalGetCallInputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {infinitusai.be.PortalGetCallInputsResponse} message PortalGetCallInputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PortalGetCallInputsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callInputs = null;
                        object.customerAssignedId = "";
                        object.programName = "";
                    }
                    if (message.callInputs != null && message.hasOwnProperty("callInputs"))
                        object.callInputs = $root.infinitusai.be.BVCallInputs.toObject(message.callInputs, options);
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        object.customerAssignedId = message.customerAssignedId;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    return object;
                };
    
                /**
                 * Converts this PortalGetCallInputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PortalGetCallInputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PortalGetCallInputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PortalGetCallInputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PortalGetCallInputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PortalGetCallInputsResponse";
                };
    
                return PortalGetCallInputsResponse;
            })();
    
            be.PortalReportIssueRequest = (function() {
    
                /**
                 * Properties of a PortalReportIssueRequest.
                 * @memberof infinitusai.be
                 * @interface IPortalReportIssueRequest
                 * @property {boolean|null} [onCall] PortalReportIssueRequest onCall
                 * @property {boolean|null} [callPositive] PortalReportIssueRequest callPositive
                 * @property {string|null} [pageUrl] PortalReportIssueRequest pageUrl
                 * @property {string|null} [description] PortalReportIssueRequest description
                 */
    
                /**
                 * Constructs a new PortalReportIssueRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PortalReportIssueRequest.
                 * @implements IPortalReportIssueRequest
                 * @constructor
                 * @param {infinitusai.be.IPortalReportIssueRequest=} [properties] Properties to set
                 */
                function PortalReportIssueRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PortalReportIssueRequest onCall.
                 * @member {boolean} onCall
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @instance
                 */
                PortalReportIssueRequest.prototype.onCall = false;
    
                /**
                 * PortalReportIssueRequest callPositive.
                 * @member {boolean} callPositive
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @instance
                 */
                PortalReportIssueRequest.prototype.callPositive = false;
    
                /**
                 * PortalReportIssueRequest pageUrl.
                 * @member {string} pageUrl
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @instance
                 */
                PortalReportIssueRequest.prototype.pageUrl = "";
    
                /**
                 * PortalReportIssueRequest description.
                 * @member {string} description
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @instance
                 */
                PortalReportIssueRequest.prototype.description = "";
    
                /**
                 * Creates a new PortalReportIssueRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {infinitusai.be.IPortalReportIssueRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.PortalReportIssueRequest} PortalReportIssueRequest instance
                 */
                PortalReportIssueRequest.create = function create(properties) {
                    return new PortalReportIssueRequest(properties);
                };
    
                /**
                 * Encodes the specified PortalReportIssueRequest message. Does not implicitly {@link infinitusai.be.PortalReportIssueRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {infinitusai.be.IPortalReportIssueRequest} message PortalReportIssueRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalReportIssueRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.onCall != null && Object.hasOwnProperty.call(message, "onCall"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.onCall);
                    if (message.callPositive != null && Object.hasOwnProperty.call(message, "callPositive"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.callPositive);
                    if (message.pageUrl != null && Object.hasOwnProperty.call(message, "pageUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.pageUrl);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                    return writer;
                };
    
                /**
                 * Encodes the specified PortalReportIssueRequest message, length delimited. Does not implicitly {@link infinitusai.be.PortalReportIssueRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {infinitusai.be.IPortalReportIssueRequest} message PortalReportIssueRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalReportIssueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PortalReportIssueRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PortalReportIssueRequest} PortalReportIssueRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalReportIssueRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PortalReportIssueRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.onCall = reader.bool();
                                break;
                            }
                        case 2: {
                                message.callPositive = reader.bool();
                                break;
                            }
                        case 3: {
                                message.pageUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.description = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PortalReportIssueRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PortalReportIssueRequest} PortalReportIssueRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalReportIssueRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PortalReportIssueRequest message.
                 * @function verify
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PortalReportIssueRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.onCall != null && message.hasOwnProperty("onCall"))
                        if (typeof message.onCall !== "boolean")
                            return "onCall: boolean expected";
                    if (message.callPositive != null && message.hasOwnProperty("callPositive"))
                        if (typeof message.callPositive !== "boolean")
                            return "callPositive: boolean expected";
                    if (message.pageUrl != null && message.hasOwnProperty("pageUrl"))
                        if (!$util.isString(message.pageUrl))
                            return "pageUrl: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    return null;
                };
    
                /**
                 * Creates a PortalReportIssueRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PortalReportIssueRequest} PortalReportIssueRequest
                 */
                PortalReportIssueRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PortalReportIssueRequest)
                        return object;
                    var message = new $root.infinitusai.be.PortalReportIssueRequest();
                    if (object.onCall != null)
                        message.onCall = Boolean(object.onCall);
                    if (object.callPositive != null)
                        message.callPositive = Boolean(object.callPositive);
                    if (object.pageUrl != null)
                        message.pageUrl = String(object.pageUrl);
                    if (object.description != null)
                        message.description = String(object.description);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PortalReportIssueRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {infinitusai.be.PortalReportIssueRequest} message PortalReportIssueRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PortalReportIssueRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.onCall = false;
                        object.callPositive = false;
                        object.pageUrl = "";
                        object.description = "";
                    }
                    if (message.onCall != null && message.hasOwnProperty("onCall"))
                        object.onCall = message.onCall;
                    if (message.callPositive != null && message.hasOwnProperty("callPositive"))
                        object.callPositive = message.callPositive;
                    if (message.pageUrl != null && message.hasOwnProperty("pageUrl"))
                        object.pageUrl = message.pageUrl;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    return object;
                };
    
                /**
                 * Converts this PortalReportIssueRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PortalReportIssueRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PortalReportIssueRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PortalReportIssueRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PortalReportIssueRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PortalReportIssueRequest";
                };
    
                return PortalReportIssueRequest;
            })();
    
            be.PortalReportIssueResponse = (function() {
    
                /**
                 * Properties of a PortalReportIssueResponse.
                 * @memberof infinitusai.be
                 * @interface IPortalReportIssueResponse
                 * @property {string|null} [gcsFilePath] PortalReportIssueResponse gcsFilePath
                 * @property {string|null} [issueUuid] PortalReportIssueResponse issueUuid
                 */
    
                /**
                 * Constructs a new PortalReportIssueResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PortalReportIssueResponse.
                 * @implements IPortalReportIssueResponse
                 * @constructor
                 * @param {infinitusai.be.IPortalReportIssueResponse=} [properties] Properties to set
                 */
                function PortalReportIssueResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PortalReportIssueResponse gcsFilePath.
                 * @member {string} gcsFilePath
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @instance
                 */
                PortalReportIssueResponse.prototype.gcsFilePath = "";
    
                /**
                 * PortalReportIssueResponse issueUuid.
                 * @member {string} issueUuid
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @instance
                 */
                PortalReportIssueResponse.prototype.issueUuid = "";
    
                /**
                 * Creates a new PortalReportIssueResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {infinitusai.be.IPortalReportIssueResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.PortalReportIssueResponse} PortalReportIssueResponse instance
                 */
                PortalReportIssueResponse.create = function create(properties) {
                    return new PortalReportIssueResponse(properties);
                };
    
                /**
                 * Encodes the specified PortalReportIssueResponse message. Does not implicitly {@link infinitusai.be.PortalReportIssueResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {infinitusai.be.IPortalReportIssueResponse} message PortalReportIssueResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalReportIssueResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.gcsFilePath != null && Object.hasOwnProperty.call(message, "gcsFilePath"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.gcsFilePath);
                    if (message.issueUuid != null && Object.hasOwnProperty.call(message, "issueUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.issueUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified PortalReportIssueResponse message, length delimited. Does not implicitly {@link infinitusai.be.PortalReportIssueResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {infinitusai.be.IPortalReportIssueResponse} message PortalReportIssueResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PortalReportIssueResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PortalReportIssueResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PortalReportIssueResponse} PortalReportIssueResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalReportIssueResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PortalReportIssueResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.gcsFilePath = reader.string();
                                break;
                            }
                        case 2: {
                                message.issueUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PortalReportIssueResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PortalReportIssueResponse} PortalReportIssueResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PortalReportIssueResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PortalReportIssueResponse message.
                 * @function verify
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PortalReportIssueResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.gcsFilePath != null && message.hasOwnProperty("gcsFilePath"))
                        if (!$util.isString(message.gcsFilePath))
                            return "gcsFilePath: string expected";
                    if (message.issueUuid != null && message.hasOwnProperty("issueUuid"))
                        if (!$util.isString(message.issueUuid))
                            return "issueUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a PortalReportIssueResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PortalReportIssueResponse} PortalReportIssueResponse
                 */
                PortalReportIssueResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PortalReportIssueResponse)
                        return object;
                    var message = new $root.infinitusai.be.PortalReportIssueResponse();
                    if (object.gcsFilePath != null)
                        message.gcsFilePath = String(object.gcsFilePath);
                    if (object.issueUuid != null)
                        message.issueUuid = String(object.issueUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PortalReportIssueResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {infinitusai.be.PortalReportIssueResponse} message PortalReportIssueResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PortalReportIssueResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.gcsFilePath = "";
                        object.issueUuid = "";
                    }
                    if (message.gcsFilePath != null && message.hasOwnProperty("gcsFilePath"))
                        object.gcsFilePath = message.gcsFilePath;
                    if (message.issueUuid != null && message.hasOwnProperty("issueUuid"))
                        object.issueUuid = message.issueUuid;
                    return object;
                };
    
                /**
                 * Converts this PortalReportIssueResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PortalReportIssueResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PortalReportIssueResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PortalReportIssueResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PortalReportIssueResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PortalReportIssueResponse";
                };
    
                return PortalReportIssueResponse;
            })();
    
            be.ChatFeedbackResponse = (function() {
    
                /**
                 * Properties of a ChatFeedbackResponse.
                 * @memberof infinitusai.be
                 * @interface IChatFeedbackResponse
                 * @property {string|null} [id] ChatFeedbackResponse id
                 */
    
                /**
                 * Constructs a new ChatFeedbackResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChatFeedbackResponse.
                 * @implements IChatFeedbackResponse
                 * @constructor
                 * @param {infinitusai.be.IChatFeedbackResponse=} [properties] Properties to set
                 */
                function ChatFeedbackResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChatFeedbackResponse id.
                 * @member {string} id
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @instance
                 */
                ChatFeedbackResponse.prototype.id = "";
    
                /**
                 * Creates a new ChatFeedbackResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {infinitusai.be.IChatFeedbackResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ChatFeedbackResponse} ChatFeedbackResponse instance
                 */
                ChatFeedbackResponse.create = function create(properties) {
                    return new ChatFeedbackResponse(properties);
                };
    
                /**
                 * Encodes the specified ChatFeedbackResponse message. Does not implicitly {@link infinitusai.be.ChatFeedbackResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {infinitusai.be.IChatFeedbackResponse} message ChatFeedbackResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatFeedbackResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    return writer;
                };
    
                /**
                 * Encodes the specified ChatFeedbackResponse message, length delimited. Does not implicitly {@link infinitusai.be.ChatFeedbackResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {infinitusai.be.IChatFeedbackResponse} message ChatFeedbackResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatFeedbackResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChatFeedbackResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChatFeedbackResponse} ChatFeedbackResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatFeedbackResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChatFeedbackResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChatFeedbackResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChatFeedbackResponse} ChatFeedbackResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatFeedbackResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChatFeedbackResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChatFeedbackResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    return null;
                };
    
                /**
                 * Creates a ChatFeedbackResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChatFeedbackResponse} ChatFeedbackResponse
                 */
                ChatFeedbackResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChatFeedbackResponse)
                        return object;
                    var message = new $root.infinitusai.be.ChatFeedbackResponse();
                    if (object.id != null)
                        message.id = String(object.id);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChatFeedbackResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {infinitusai.be.ChatFeedbackResponse} message ChatFeedbackResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChatFeedbackResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.id = "";
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };
    
                /**
                 * Converts this ChatFeedbackResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChatFeedbackResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChatFeedbackResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChatFeedbackResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChatFeedbackResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChatFeedbackResponse";
                };
    
                return ChatFeedbackResponse;
            })();
    
            /**
             * IssueStatus enum.
             * @name infinitusai.be.IssueStatus
             * @enum {number}
             * @property {number} ISSUE_STATUS_PENDING=0 ISSUE_STATUS_PENDING value
             * @property {number} ISSUE_STATUS_IN_PROGRESS=1 ISSUE_STATUS_IN_PROGRESS value
             * @property {number} ISSUE_STATUS_RESOLVED=2 ISSUE_STATUS_RESOLVED value
             * @property {number} ISSUE_STATUS_UNKNOWN=3 ISSUE_STATUS_UNKNOWN value
             * @property {number} ISSUE_STATUS_NEW=4 ISSUE_STATUS_NEW value
             */
            be.IssueStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ISSUE_STATUS_PENDING"] = 0;
                values[valuesById[1] = "ISSUE_STATUS_IN_PROGRESS"] = 1;
                values[valuesById[2] = "ISSUE_STATUS_RESOLVED"] = 2;
                values[valuesById[3] = "ISSUE_STATUS_UNKNOWN"] = 3;
                values[valuesById[4] = "ISSUE_STATUS_NEW"] = 4;
                return values;
            })();
    
            be.ElevenLabsConfigForATaskRequest = (function() {
    
                /**
                 * Properties of an ElevenLabsConfigForATaskRequest.
                 * @memberof infinitusai.be
                 * @interface IElevenLabsConfigForATaskRequest
                 * @property {string|null} [orgUUID] ElevenLabsConfigForATaskRequest orgUUID
                 * @property {string|null} [taskUUID] ElevenLabsConfigForATaskRequest taskUUID
                 * @property {string|null} [payerUUID] ElevenLabsConfigForATaskRequest payerUUID
                 */
    
                /**
                 * Constructs a new ElevenLabsConfigForATaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ElevenLabsConfigForATaskRequest.
                 * @implements IElevenLabsConfigForATaskRequest
                 * @constructor
                 * @param {infinitusai.be.IElevenLabsConfigForATaskRequest=} [properties] Properties to set
                 */
                function ElevenLabsConfigForATaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ElevenLabsConfigForATaskRequest orgUUID.
                 * @member {string} orgUUID
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @instance
                 */
                ElevenLabsConfigForATaskRequest.prototype.orgUUID = "";
    
                /**
                 * ElevenLabsConfigForATaskRequest taskUUID.
                 * @member {string} taskUUID
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @instance
                 */
                ElevenLabsConfigForATaskRequest.prototype.taskUUID = "";
    
                /**
                 * ElevenLabsConfigForATaskRequest payerUUID.
                 * @member {string} payerUUID
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @instance
                 */
                ElevenLabsConfigForATaskRequest.prototype.payerUUID = "";
    
                /**
                 * Creates a new ElevenLabsConfigForATaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForATaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ElevenLabsConfigForATaskRequest} ElevenLabsConfigForATaskRequest instance
                 */
                ElevenLabsConfigForATaskRequest.create = function create(properties) {
                    return new ElevenLabsConfigForATaskRequest(properties);
                };
    
                /**
                 * Encodes the specified ElevenLabsConfigForATaskRequest message. Does not implicitly {@link infinitusai.be.ElevenLabsConfigForATaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForATaskRequest} message ElevenLabsConfigForATaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElevenLabsConfigForATaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUUID != null && Object.hasOwnProperty.call(message, "orgUUID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUUID);
                    if (message.taskUUID != null && Object.hasOwnProperty.call(message, "taskUUID"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUUID);
                    if (message.payerUUID != null && Object.hasOwnProperty.call(message, "payerUUID"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.payerUUID);
                    return writer;
                };
    
                /**
                 * Encodes the specified ElevenLabsConfigForATaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.ElevenLabsConfigForATaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForATaskRequest} message ElevenLabsConfigForATaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElevenLabsConfigForATaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ElevenLabsConfigForATaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ElevenLabsConfigForATaskRequest} ElevenLabsConfigForATaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElevenLabsConfigForATaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ElevenLabsConfigForATaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUUID = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUUID = reader.string();
                                break;
                            }
                        case 3: {
                                message.payerUUID = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ElevenLabsConfigForATaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ElevenLabsConfigForATaskRequest} ElevenLabsConfigForATaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElevenLabsConfigForATaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ElevenLabsConfigForATaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ElevenLabsConfigForATaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUUID != null && message.hasOwnProperty("orgUUID"))
                        if (!$util.isString(message.orgUUID))
                            return "orgUUID: string expected";
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        if (!$util.isString(message.taskUUID))
                            return "taskUUID: string expected";
                    if (message.payerUUID != null && message.hasOwnProperty("payerUUID"))
                        if (!$util.isString(message.payerUUID))
                            return "payerUUID: string expected";
                    return null;
                };
    
                /**
                 * Creates an ElevenLabsConfigForATaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ElevenLabsConfigForATaskRequest} ElevenLabsConfigForATaskRequest
                 */
                ElevenLabsConfigForATaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ElevenLabsConfigForATaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.ElevenLabsConfigForATaskRequest();
                    if (object.orgUUID != null)
                        message.orgUUID = String(object.orgUUID);
                    if (object.taskUUID != null)
                        message.taskUUID = String(object.taskUUID);
                    if (object.payerUUID != null)
                        message.payerUUID = String(object.payerUUID);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ElevenLabsConfigForATaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {infinitusai.be.ElevenLabsConfigForATaskRequest} message ElevenLabsConfigForATaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ElevenLabsConfigForATaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUUID = "";
                        object.taskUUID = "";
                        object.payerUUID = "";
                    }
                    if (message.orgUUID != null && message.hasOwnProperty("orgUUID"))
                        object.orgUUID = message.orgUUID;
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        object.taskUUID = message.taskUUID;
                    if (message.payerUUID != null && message.hasOwnProperty("payerUUID"))
                        object.payerUUID = message.payerUUID;
                    return object;
                };
    
                /**
                 * Converts this ElevenLabsConfigForATaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ElevenLabsConfigForATaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ElevenLabsConfigForATaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ElevenLabsConfigForATaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ElevenLabsConfigForATaskRequest";
                };
    
                return ElevenLabsConfigForATaskRequest;
            })();
    
            be.ElevenLabsConfigForATaskResponse = (function() {
    
                /**
                 * Properties of an ElevenLabsConfigForATaskResponse.
                 * @memberof infinitusai.be
                 * @interface IElevenLabsConfigForATaskResponse
                 * @property {string|null} [orgUUID] ElevenLabsConfigForATaskResponse orgUUID
                 * @property {string|null} [taskUUID] ElevenLabsConfigForATaskResponse taskUUID
                 * @property {string|null} [payerUUID] ElevenLabsConfigForATaskResponse payerUUID
                 * @property {boolean|null} [isEligible] ElevenLabsConfigForATaskResponse isEligible
                 * @property {infinitusai.be.IVoiceData|null} [voiceData] ElevenLabsConfigForATaskResponse voiceData
                 */
    
                /**
                 * Constructs a new ElevenLabsConfigForATaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ElevenLabsConfigForATaskResponse.
                 * @implements IElevenLabsConfigForATaskResponse
                 * @constructor
                 * @param {infinitusai.be.IElevenLabsConfigForATaskResponse=} [properties] Properties to set
                 */
                function ElevenLabsConfigForATaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ElevenLabsConfigForATaskResponse orgUUID.
                 * @member {string} orgUUID
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @instance
                 */
                ElevenLabsConfigForATaskResponse.prototype.orgUUID = "";
    
                /**
                 * ElevenLabsConfigForATaskResponse taskUUID.
                 * @member {string} taskUUID
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @instance
                 */
                ElevenLabsConfigForATaskResponse.prototype.taskUUID = "";
    
                /**
                 * ElevenLabsConfigForATaskResponse payerUUID.
                 * @member {string} payerUUID
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @instance
                 */
                ElevenLabsConfigForATaskResponse.prototype.payerUUID = "";
    
                /**
                 * ElevenLabsConfigForATaskResponse isEligible.
                 * @member {boolean} isEligible
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @instance
                 */
                ElevenLabsConfigForATaskResponse.prototype.isEligible = false;
    
                /**
                 * ElevenLabsConfigForATaskResponse voiceData.
                 * @member {infinitusai.be.IVoiceData|null|undefined} voiceData
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @instance
                 */
                ElevenLabsConfigForATaskResponse.prototype.voiceData = null;
    
                /**
                 * Creates a new ElevenLabsConfigForATaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForATaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ElevenLabsConfigForATaskResponse} ElevenLabsConfigForATaskResponse instance
                 */
                ElevenLabsConfigForATaskResponse.create = function create(properties) {
                    return new ElevenLabsConfigForATaskResponse(properties);
                };
    
                /**
                 * Encodes the specified ElevenLabsConfigForATaskResponse message. Does not implicitly {@link infinitusai.be.ElevenLabsConfigForATaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForATaskResponse} message ElevenLabsConfigForATaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElevenLabsConfigForATaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUUID != null && Object.hasOwnProperty.call(message, "orgUUID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUUID);
                    if (message.taskUUID != null && Object.hasOwnProperty.call(message, "taskUUID"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUUID);
                    if (message.payerUUID != null && Object.hasOwnProperty.call(message, "payerUUID"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.payerUUID);
                    if (message.isEligible != null && Object.hasOwnProperty.call(message, "isEligible"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isEligible);
                    if (message.voiceData != null && Object.hasOwnProperty.call(message, "voiceData"))
                        $root.infinitusai.be.VoiceData.encode(message.voiceData, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ElevenLabsConfigForATaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.ElevenLabsConfigForATaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfigForATaskResponse} message ElevenLabsConfigForATaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElevenLabsConfigForATaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ElevenLabsConfigForATaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ElevenLabsConfigForATaskResponse} ElevenLabsConfigForATaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElevenLabsConfigForATaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ElevenLabsConfigForATaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUUID = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUUID = reader.string();
                                break;
                            }
                        case 3: {
                                message.payerUUID = reader.string();
                                break;
                            }
                        case 4: {
                                message.isEligible = reader.bool();
                                break;
                            }
                        case 5: {
                                message.voiceData = $root.infinitusai.be.VoiceData.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ElevenLabsConfigForATaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ElevenLabsConfigForATaskResponse} ElevenLabsConfigForATaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElevenLabsConfigForATaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ElevenLabsConfigForATaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ElevenLabsConfigForATaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUUID != null && message.hasOwnProperty("orgUUID"))
                        if (!$util.isString(message.orgUUID))
                            return "orgUUID: string expected";
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        if (!$util.isString(message.taskUUID))
                            return "taskUUID: string expected";
                    if (message.payerUUID != null && message.hasOwnProperty("payerUUID"))
                        if (!$util.isString(message.payerUUID))
                            return "payerUUID: string expected";
                    if (message.isEligible != null && message.hasOwnProperty("isEligible"))
                        if (typeof message.isEligible !== "boolean")
                            return "isEligible: boolean expected";
                    if (message.voiceData != null && message.hasOwnProperty("voiceData")) {
                        var error = $root.infinitusai.be.VoiceData.verify(message.voiceData);
                        if (error)
                            return "voiceData." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an ElevenLabsConfigForATaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ElevenLabsConfigForATaskResponse} ElevenLabsConfigForATaskResponse
                 */
                ElevenLabsConfigForATaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ElevenLabsConfigForATaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.ElevenLabsConfigForATaskResponse();
                    if (object.orgUUID != null)
                        message.orgUUID = String(object.orgUUID);
                    if (object.taskUUID != null)
                        message.taskUUID = String(object.taskUUID);
                    if (object.payerUUID != null)
                        message.payerUUID = String(object.payerUUID);
                    if (object.isEligible != null)
                        message.isEligible = Boolean(object.isEligible);
                    if (object.voiceData != null) {
                        if (typeof object.voiceData !== "object")
                            throw TypeError(".infinitusai.be.ElevenLabsConfigForATaskResponse.voiceData: object expected");
                        message.voiceData = $root.infinitusai.be.VoiceData.fromObject(object.voiceData);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ElevenLabsConfigForATaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {infinitusai.be.ElevenLabsConfigForATaskResponse} message ElevenLabsConfigForATaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ElevenLabsConfigForATaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUUID = "";
                        object.taskUUID = "";
                        object.payerUUID = "";
                        object.isEligible = false;
                        object.voiceData = null;
                    }
                    if (message.orgUUID != null && message.hasOwnProperty("orgUUID"))
                        object.orgUUID = message.orgUUID;
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        object.taskUUID = message.taskUUID;
                    if (message.payerUUID != null && message.hasOwnProperty("payerUUID"))
                        object.payerUUID = message.payerUUID;
                    if (message.isEligible != null && message.hasOwnProperty("isEligible"))
                        object.isEligible = message.isEligible;
                    if (message.voiceData != null && message.hasOwnProperty("voiceData"))
                        object.voiceData = $root.infinitusai.be.VoiceData.toObject(message.voiceData, options);
                    return object;
                };
    
                /**
                 * Converts this ElevenLabsConfigForATaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ElevenLabsConfigForATaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ElevenLabsConfigForATaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ElevenLabsConfigForATaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ElevenLabsConfigForATaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ElevenLabsConfigForATaskResponse";
                };
    
                return ElevenLabsConfigForATaskResponse;
            })();
    
            be.VoiceData = (function() {
    
                /**
                 * Properties of a VoiceData.
                 * @memberof infinitusai.be
                 * @interface IVoiceData
                 * @property {boolean|null} [useThisResult] VoiceData useThisResult
                 * @property {infinitusai.be.TTSSetting.Voice|null} [voiceToUse] VoiceData voiceToUse
                 * @property {Object.<string,number>|null} [voiceDistribution] VoiceData voiceDistribution
                 */
    
                /**
                 * Constructs a new VoiceData.
                 * @memberof infinitusai.be
                 * @classdesc Represents a VoiceData.
                 * @implements IVoiceData
                 * @constructor
                 * @param {infinitusai.be.IVoiceData=} [properties] Properties to set
                 */
                function VoiceData(properties) {
                    this.voiceDistribution = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * VoiceData useThisResult.
                 * @member {boolean} useThisResult
                 * @memberof infinitusai.be.VoiceData
                 * @instance
                 */
                VoiceData.prototype.useThisResult = false;
    
                /**
                 * VoiceData voiceToUse.
                 * @member {infinitusai.be.TTSSetting.Voice} voiceToUse
                 * @memberof infinitusai.be.VoiceData
                 * @instance
                 */
                VoiceData.prototype.voiceToUse = 0;
    
                /**
                 * VoiceData voiceDistribution.
                 * @member {Object.<string,number>} voiceDistribution
                 * @memberof infinitusai.be.VoiceData
                 * @instance
                 */
                VoiceData.prototype.voiceDistribution = $util.emptyObject;
    
                /**
                 * Creates a new VoiceData instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {infinitusai.be.IVoiceData=} [properties] Properties to set
                 * @returns {infinitusai.be.VoiceData} VoiceData instance
                 */
                VoiceData.create = function create(properties) {
                    return new VoiceData(properties);
                };
    
                /**
                 * Encodes the specified VoiceData message. Does not implicitly {@link infinitusai.be.VoiceData.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {infinitusai.be.IVoiceData} message VoiceData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VoiceData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.useThisResult != null && Object.hasOwnProperty.call(message, "useThisResult"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.useThisResult);
                    if (message.voiceToUse != null && Object.hasOwnProperty.call(message, "voiceToUse"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.voiceToUse);
                    if (message.voiceDistribution != null && Object.hasOwnProperty.call(message, "voiceDistribution"))
                        for (var keys = Object.keys(message.voiceDistribution), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.voiceDistribution[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified VoiceData message, length delimited. Does not implicitly {@link infinitusai.be.VoiceData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {infinitusai.be.IVoiceData} message VoiceData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VoiceData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a VoiceData message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.VoiceData} VoiceData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VoiceData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.VoiceData(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.useThisResult = reader.bool();
                                break;
                            }
                        case 2: {
                                message.voiceToUse = reader.int32();
                                break;
                            }
                        case 3: {
                                if (message.voiceDistribution === $util.emptyObject)
                                    message.voiceDistribution = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.int32();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.voiceDistribution[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a VoiceData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.VoiceData} VoiceData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VoiceData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a VoiceData message.
                 * @function verify
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VoiceData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.useThisResult != null && message.hasOwnProperty("useThisResult"))
                        if (typeof message.useThisResult !== "boolean")
                            return "useThisResult: boolean expected";
                    if (message.voiceToUse != null && message.hasOwnProperty("voiceToUse"))
                        switch (message.voiceToUse) {
                        default:
                            return "voiceToUse: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 29:
                        case 30:
                        case 31:
                        case 32:
                        case 33:
                        case 34:
                            break;
                        }
                    if (message.voiceDistribution != null && message.hasOwnProperty("voiceDistribution")) {
                        if (!$util.isObject(message.voiceDistribution))
                            return "voiceDistribution: object expected";
                        var key = Object.keys(message.voiceDistribution);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isInteger(message.voiceDistribution[key[i]]))
                                return "voiceDistribution: integer{k:string} expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a VoiceData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.VoiceData} VoiceData
                 */
                VoiceData.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.VoiceData)
                        return object;
                    var message = new $root.infinitusai.be.VoiceData();
                    if (object.useThisResult != null)
                        message.useThisResult = Boolean(object.useThisResult);
                    switch (object.voiceToUse) {
                    default:
                        if (typeof object.voiceToUse === "number") {
                            message.voiceToUse = object.voiceToUse;
                            break;
                        }
                        break;
                    case "en_US_Wavenet_A":
                    case 0:
                        message.voiceToUse = 0;
                        break;
                    case "en_US_Wavenet_B":
                    case 1:
                        message.voiceToUse = 1;
                        break;
                    case "en_US_Wavenet_C":
                    case 2:
                        message.voiceToUse = 2;
                        break;
                    case "en_US_Wavenet_D":
                    case 3:
                        message.voiceToUse = 3;
                        break;
                    case "en_US_Wavenet_E":
                    case 4:
                        message.voiceToUse = 4;
                        break;
                    case "en_US_Wavenet_F":
                    case 5:
                        message.voiceToUse = 5;
                        break;
                    case "en_US_Neural2_F":
                    case 6:
                        message.voiceToUse = 6;
                        break;
                    case "en_US_Neural2_G":
                    case 7:
                        message.voiceToUse = 7;
                        break;
                    case "en_US_Neural2_H":
                    case 8:
                        message.voiceToUse = 8;
                        break;
                    case "en_US_Studio_M":
                    case 9:
                        message.voiceToUse = 9;
                        break;
                    case "en_US_Studio_O":
                    case 10:
                        message.voiceToUse = 10;
                        break;
                    case "elevenLabs_Rahul":
                    case 11:
                        message.voiceToUse = 11;
                        break;
                    case "elevenLabs_Jack":
                    case 12:
                        message.voiceToUse = 12;
                        break;
                    case "elevenLabs_Ira":
                    case 13:
                        message.voiceToUse = 13;
                        break;
                    case "elevenLabs_Rebbecca":
                    case 14:
                        message.voiceToUse = 14;
                        break;
                    case "elevenLabs_Sneha":
                    case 15:
                        message.voiceToUse = 15;
                        break;
                    case "elevenLabs_Tom":
                    case 16:
                        message.voiceToUse = 16;
                        break;
                    case "elevenLabs_Alfred":
                    case 17:
                        message.voiceToUse = 17;
                        break;
                    case "elevenLabs_Adam":
                    case 18:
                        message.voiceToUse = 18;
                        break;
                    case "elevenLabs_Sir_Paddington":
                    case 19:
                        message.voiceToUse = 19;
                        break;
                    case "elevenLabs_Emma":
                    case 20:
                        message.voiceToUse = 20;
                        break;
                    case "elevenLabs_Meera":
                    case 21:
                        message.voiceToUse = 21;
                        break;
                    case "elevenLabs_Shauna":
                    case 22:
                        message.voiceToUse = 22;
                        break;
                    case "elevenLabs_Shyam":
                    case 23:
                        message.voiceToUse = 23;
                        break;
                    case "elevenLabs_Tamika":
                    case 24:
                        message.voiceToUse = 24;
                        break;
                    case "elevenLabs_Bria":
                    case 25:
                        message.voiceToUse = 25;
                        break;
                    case "elevenLabs_Won":
                    case 26:
                        message.voiceToUse = 26;
                        break;
                    case "elevenLabs_AJ":
                    case 27:
                        message.voiceToUse = 27;
                        break;
                    case "elevenLabs_DK":
                    case 28:
                        message.voiceToUse = 28;
                        break;
                    case "elevenLabs_JB":
                    case 29:
                        message.voiceToUse = 29;
                        break;
                    case "elevenLabs_RP":
                    case 30:
                        message.voiceToUse = 30;
                        break;
                    case "elevenLabs_SR":
                    case 31:
                        message.voiceToUse = 31;
                        break;
                    case "elevenLabs_WW":
                    case 32:
                        message.voiceToUse = 32;
                        break;
                    case "elevenLabs_RR":
                    case 33:
                        message.voiceToUse = 33;
                        break;
                    case "elevenLabs_JH":
                    case 34:
                        message.voiceToUse = 34;
                        break;
                    }
                    if (object.voiceDistribution) {
                        if (typeof object.voiceDistribution !== "object")
                            throw TypeError(".infinitusai.be.VoiceData.voiceDistribution: object expected");
                        message.voiceDistribution = {};
                        for (var keys = Object.keys(object.voiceDistribution), i = 0; i < keys.length; ++i)
                            message.voiceDistribution[keys[i]] = object.voiceDistribution[keys[i]] | 0;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a VoiceData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {infinitusai.be.VoiceData} message VoiceData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VoiceData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.voiceDistribution = {};
                    if (options.defaults) {
                        object.useThisResult = false;
                        object.voiceToUse = options.enums === String ? "en_US_Wavenet_A" : 0;
                    }
                    if (message.useThisResult != null && message.hasOwnProperty("useThisResult"))
                        object.useThisResult = message.useThisResult;
                    if (message.voiceToUse != null && message.hasOwnProperty("voiceToUse"))
                        object.voiceToUse = options.enums === String ? $root.infinitusai.be.TTSSetting.Voice[message.voiceToUse] === undefined ? message.voiceToUse : $root.infinitusai.be.TTSSetting.Voice[message.voiceToUse] : message.voiceToUse;
                    var keys2;
                    if (message.voiceDistribution && (keys2 = Object.keys(message.voiceDistribution)).length) {
                        object.voiceDistribution = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.voiceDistribution[keys2[j]] = message.voiceDistribution[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this VoiceData to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.VoiceData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VoiceData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for VoiceData
                 * @function getTypeUrl
                 * @memberof infinitusai.be.VoiceData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VoiceData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.VoiceData";
                };
    
                return VoiceData;
            })();
    
            /**
             * FieldConditionSourceType enum.
             * @name infinitusai.be.FieldConditionSourceType
             * @enum {number}
             * @property {number} FIELD_CONDITION_SOURCE_TYPE_UNKNOWN=0 FIELD_CONDITION_SOURCE_TYPE_UNKNOWN value
             * @property {number} FIELD_CONDITION_SOURCE_TYPE_INPUT=1 FIELD_CONDITION_SOURCE_TYPE_INPUT value
             * @property {number} FIELD_CONDITION_SOURCE_TYPE_OUTPUT=2 FIELD_CONDITION_SOURCE_TYPE_OUTPUT value
             * @property {number} FIELD_CONDITION_SOURCE_TYPE_OPTIONAL_OUTPUT=3 FIELD_CONDITION_SOURCE_TYPE_OPTIONAL_OUTPUT value
             */
            be.FieldConditionSourceType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "FIELD_CONDITION_SOURCE_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "FIELD_CONDITION_SOURCE_TYPE_INPUT"] = 1;
                values[valuesById[2] = "FIELD_CONDITION_SOURCE_TYPE_OUTPUT"] = 2;
                values[valuesById[3] = "FIELD_CONDITION_SOURCE_TYPE_OPTIONAL_OUTPUT"] = 3;
                return values;
            })();
    
            /**
             * FieldConditionComparator enum.
             * @name infinitusai.be.FieldConditionComparator
             * @enum {number}
             * @property {number} FIELD_CONDITION_COMPARATOR_UNKNOWN=0 FIELD_CONDITION_COMPARATOR_UNKNOWN value
             * @property {number} FIELD_CONDITION_COMPARATOR_EQUAL=1 FIELD_CONDITION_COMPARATOR_EQUAL value
             * @property {number} FIELD_CONDITION_COMPARATOR_NOT_EQUAL=2 FIELD_CONDITION_COMPARATOR_NOT_EQUAL value
             * @property {number} FIELD_CONDITION_COMPARATOR_IN=3 FIELD_CONDITION_COMPARATOR_IN value
             * @property {number} FIELD_CONDITION_COMPARATOR_NOT_IN=4 FIELD_CONDITION_COMPARATOR_NOT_IN value
             * @property {number} FIELD_CONDITION_COMPARATOR_HAS_PREFIX=5 FIELD_CONDITION_COMPARATOR_HAS_PREFIX value
             * @property {number} FIELD_CONDITION_COMPARATOR_HAS_SUBSTRING=6 FIELD_CONDITION_COMPARATOR_HAS_SUBSTRING value
             */
            be.FieldConditionComparator = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "FIELD_CONDITION_COMPARATOR_UNKNOWN"] = 0;
                values[valuesById[1] = "FIELD_CONDITION_COMPARATOR_EQUAL"] = 1;
                values[valuesById[2] = "FIELD_CONDITION_COMPARATOR_NOT_EQUAL"] = 2;
                values[valuesById[3] = "FIELD_CONDITION_COMPARATOR_IN"] = 3;
                values[valuesById[4] = "FIELD_CONDITION_COMPARATOR_NOT_IN"] = 4;
                values[valuesById[5] = "FIELD_CONDITION_COMPARATOR_HAS_PREFIX"] = 5;
                values[valuesById[6] = "FIELD_CONDITION_COMPARATOR_HAS_SUBSTRING"] = 6;
                return values;
            })();
    
            be.FieldCondition = (function() {
    
                /**
                 * Properties of a FieldCondition.
                 * @memberof infinitusai.be
                 * @interface IFieldCondition
                 * @property {infinitusai.be.FieldConditionSourceType|null} [sourceType] FieldCondition sourceType
                 * @property {string|null} [fieldPath] FieldCondition fieldPath
                 * @property {string|null} [fieldValueJson] FieldCondition fieldValueJson
                 * @property {infinitusai.be.FieldConditionComparator|null} [comparator] FieldCondition comparator
                 */
    
                /**
                 * Constructs a new FieldCondition.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FieldCondition.
                 * @implements IFieldCondition
                 * @constructor
                 * @param {infinitusai.be.IFieldCondition=} [properties] Properties to set
                 */
                function FieldCondition(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldCondition sourceType.
                 * @member {infinitusai.be.FieldConditionSourceType} sourceType
                 * @memberof infinitusai.be.FieldCondition
                 * @instance
                 */
                FieldCondition.prototype.sourceType = 0;
    
                /**
                 * FieldCondition fieldPath.
                 * @member {string} fieldPath
                 * @memberof infinitusai.be.FieldCondition
                 * @instance
                 */
                FieldCondition.prototype.fieldPath = "";
    
                /**
                 * FieldCondition fieldValueJson.
                 * @member {string} fieldValueJson
                 * @memberof infinitusai.be.FieldCondition
                 * @instance
                 */
                FieldCondition.prototype.fieldValueJson = "";
    
                /**
                 * FieldCondition comparator.
                 * @member {infinitusai.be.FieldConditionComparator} comparator
                 * @memberof infinitusai.be.FieldCondition
                 * @instance
                 */
                FieldCondition.prototype.comparator = 0;
    
                /**
                 * Creates a new FieldCondition instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {infinitusai.be.IFieldCondition=} [properties] Properties to set
                 * @returns {infinitusai.be.FieldCondition} FieldCondition instance
                 */
                FieldCondition.create = function create(properties) {
                    return new FieldCondition(properties);
                };
    
                /**
                 * Encodes the specified FieldCondition message. Does not implicitly {@link infinitusai.be.FieldCondition.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {infinitusai.be.IFieldCondition} message FieldCondition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldCondition.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sourceType != null && Object.hasOwnProperty.call(message, "sourceType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.sourceType);
                    if (message.fieldPath != null && Object.hasOwnProperty.call(message, "fieldPath"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fieldPath);
                    if (message.fieldValueJson != null && Object.hasOwnProperty.call(message, "fieldValueJson"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.fieldValueJson);
                    if (message.comparator != null && Object.hasOwnProperty.call(message, "comparator"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.comparator);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldCondition message, length delimited. Does not implicitly {@link infinitusai.be.FieldCondition.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {infinitusai.be.IFieldCondition} message FieldCondition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldCondition.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldCondition message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FieldCondition} FieldCondition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldCondition.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FieldCondition();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sourceType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.fieldPath = reader.string();
                                break;
                            }
                        case 3: {
                                message.fieldValueJson = reader.string();
                                break;
                            }
                        case 4: {
                                message.comparator = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldCondition message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FieldCondition} FieldCondition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldCondition.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldCondition message.
                 * @function verify
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldCondition.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sourceType != null && message.hasOwnProperty("sourceType"))
                        switch (message.sourceType) {
                        default:
                            return "sourceType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                        if (!$util.isString(message.fieldPath))
                            return "fieldPath: string expected";
                    if (message.fieldValueJson != null && message.hasOwnProperty("fieldValueJson"))
                        if (!$util.isString(message.fieldValueJson))
                            return "fieldValueJson: string expected";
                    if (message.comparator != null && message.hasOwnProperty("comparator"))
                        switch (message.comparator) {
                        default:
                            return "comparator: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a FieldCondition message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FieldCondition} FieldCondition
                 */
                FieldCondition.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FieldCondition)
                        return object;
                    var message = new $root.infinitusai.be.FieldCondition();
                    switch (object.sourceType) {
                    default:
                        if (typeof object.sourceType === "number") {
                            message.sourceType = object.sourceType;
                            break;
                        }
                        break;
                    case "FIELD_CONDITION_SOURCE_TYPE_UNKNOWN":
                    case 0:
                        message.sourceType = 0;
                        break;
                    case "FIELD_CONDITION_SOURCE_TYPE_INPUT":
                    case 1:
                        message.sourceType = 1;
                        break;
                    case "FIELD_CONDITION_SOURCE_TYPE_OUTPUT":
                    case 2:
                        message.sourceType = 2;
                        break;
                    case "FIELD_CONDITION_SOURCE_TYPE_OPTIONAL_OUTPUT":
                    case 3:
                        message.sourceType = 3;
                        break;
                    }
                    if (object.fieldPath != null)
                        message.fieldPath = String(object.fieldPath);
                    if (object.fieldValueJson != null)
                        message.fieldValueJson = String(object.fieldValueJson);
                    switch (object.comparator) {
                    default:
                        if (typeof object.comparator === "number") {
                            message.comparator = object.comparator;
                            break;
                        }
                        break;
                    case "FIELD_CONDITION_COMPARATOR_UNKNOWN":
                    case 0:
                        message.comparator = 0;
                        break;
                    case "FIELD_CONDITION_COMPARATOR_EQUAL":
                    case 1:
                        message.comparator = 1;
                        break;
                    case "FIELD_CONDITION_COMPARATOR_NOT_EQUAL":
                    case 2:
                        message.comparator = 2;
                        break;
                    case "FIELD_CONDITION_COMPARATOR_IN":
                    case 3:
                        message.comparator = 3;
                        break;
                    case "FIELD_CONDITION_COMPARATOR_NOT_IN":
                    case 4:
                        message.comparator = 4;
                        break;
                    case "FIELD_CONDITION_COMPARATOR_HAS_PREFIX":
                    case 5:
                        message.comparator = 5;
                        break;
                    case "FIELD_CONDITION_COMPARATOR_HAS_SUBSTRING":
                    case 6:
                        message.comparator = 6;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldCondition message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {infinitusai.be.FieldCondition} message FieldCondition
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldCondition.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sourceType = options.enums === String ? "FIELD_CONDITION_SOURCE_TYPE_UNKNOWN" : 0;
                        object.fieldPath = "";
                        object.fieldValueJson = "";
                        object.comparator = options.enums === String ? "FIELD_CONDITION_COMPARATOR_UNKNOWN" : 0;
                    }
                    if (message.sourceType != null && message.hasOwnProperty("sourceType"))
                        object.sourceType = options.enums === String ? $root.infinitusai.be.FieldConditionSourceType[message.sourceType] === undefined ? message.sourceType : $root.infinitusai.be.FieldConditionSourceType[message.sourceType] : message.sourceType;
                    if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                        object.fieldPath = message.fieldPath;
                    if (message.fieldValueJson != null && message.hasOwnProperty("fieldValueJson"))
                        object.fieldValueJson = message.fieldValueJson;
                    if (message.comparator != null && message.hasOwnProperty("comparator"))
                        object.comparator = options.enums === String ? $root.infinitusai.be.FieldConditionComparator[message.comparator] === undefined ? message.comparator : $root.infinitusai.be.FieldConditionComparator[message.comparator] : message.comparator;
                    return object;
                };
    
                /**
                 * Converts this FieldCondition to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FieldCondition
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldCondition.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldCondition
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FieldCondition
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldCondition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FieldCondition";
                };
    
                return FieldCondition;
            })();
    
            be.Condition = (function() {
    
                /**
                 * Properties of a Condition.
                 * @memberof infinitusai.be
                 * @interface ICondition
                 * @property {string|null} [conditionId] Condition conditionId
                 * @property {string|null} [conditionDescription] Condition conditionDescription
                 * @property {Array.<infinitusai.be.IFieldCondition>|null} [fieldConditions] Condition fieldConditions
                 * @property {boolean|null} [negate] Condition negate
                 */
    
                /**
                 * Constructs a new Condition.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Condition.
                 * @implements ICondition
                 * @constructor
                 * @param {infinitusai.be.ICondition=} [properties] Properties to set
                 */
                function Condition(properties) {
                    this.fieldConditions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Condition conditionId.
                 * @member {string} conditionId
                 * @memberof infinitusai.be.Condition
                 * @instance
                 */
                Condition.prototype.conditionId = "";
    
                /**
                 * Condition conditionDescription.
                 * @member {string} conditionDescription
                 * @memberof infinitusai.be.Condition
                 * @instance
                 */
                Condition.prototype.conditionDescription = "";
    
                /**
                 * Condition fieldConditions.
                 * @member {Array.<infinitusai.be.IFieldCondition>} fieldConditions
                 * @memberof infinitusai.be.Condition
                 * @instance
                 */
                Condition.prototype.fieldConditions = $util.emptyArray;
    
                /**
                 * Condition negate.
                 * @member {boolean} negate
                 * @memberof infinitusai.be.Condition
                 * @instance
                 */
                Condition.prototype.negate = false;
    
                /**
                 * Creates a new Condition instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {infinitusai.be.ICondition=} [properties] Properties to set
                 * @returns {infinitusai.be.Condition} Condition instance
                 */
                Condition.create = function create(properties) {
                    return new Condition(properties);
                };
    
                /**
                 * Encodes the specified Condition message. Does not implicitly {@link infinitusai.be.Condition.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {infinitusai.be.ICondition} message Condition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Condition.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conditionId != null && Object.hasOwnProperty.call(message, "conditionId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conditionId);
                    if (message.conditionDescription != null && Object.hasOwnProperty.call(message, "conditionDescription"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.conditionDescription);
                    if (message.fieldConditions != null && message.fieldConditions.length)
                        for (var i = 0; i < message.fieldConditions.length; ++i)
                            $root.infinitusai.be.FieldCondition.encode(message.fieldConditions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.negate != null && Object.hasOwnProperty.call(message, "negate"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.negate);
                    return writer;
                };
    
                /**
                 * Encodes the specified Condition message, length delimited. Does not implicitly {@link infinitusai.be.Condition.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {infinitusai.be.ICondition} message Condition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Condition.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Condition message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Condition} Condition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Condition.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Condition();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conditionId = reader.string();
                                break;
                            }
                        case 2: {
                                message.conditionDescription = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fieldConditions && message.fieldConditions.length))
                                    message.fieldConditions = [];
                                message.fieldConditions.push($root.infinitusai.be.FieldCondition.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.negate = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Condition message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Condition} Condition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Condition.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Condition message.
                 * @function verify
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Condition.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conditionId != null && message.hasOwnProperty("conditionId"))
                        if (!$util.isString(message.conditionId))
                            return "conditionId: string expected";
                    if (message.conditionDescription != null && message.hasOwnProperty("conditionDescription"))
                        if (!$util.isString(message.conditionDescription))
                            return "conditionDescription: string expected";
                    if (message.fieldConditions != null && message.hasOwnProperty("fieldConditions")) {
                        if (!Array.isArray(message.fieldConditions))
                            return "fieldConditions: array expected";
                        for (var i = 0; i < message.fieldConditions.length; ++i) {
                            var error = $root.infinitusai.be.FieldCondition.verify(message.fieldConditions[i]);
                            if (error)
                                return "fieldConditions." + error;
                        }
                    }
                    if (message.negate != null && message.hasOwnProperty("negate"))
                        if (typeof message.negate !== "boolean")
                            return "negate: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a Condition message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Condition} Condition
                 */
                Condition.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Condition)
                        return object;
                    var message = new $root.infinitusai.be.Condition();
                    if (object.conditionId != null)
                        message.conditionId = String(object.conditionId);
                    if (object.conditionDescription != null)
                        message.conditionDescription = String(object.conditionDescription);
                    if (object.fieldConditions) {
                        if (!Array.isArray(object.fieldConditions))
                            throw TypeError(".infinitusai.be.Condition.fieldConditions: array expected");
                        message.fieldConditions = [];
                        for (var i = 0; i < object.fieldConditions.length; ++i) {
                            if (typeof object.fieldConditions[i] !== "object")
                                throw TypeError(".infinitusai.be.Condition.fieldConditions: object expected");
                            message.fieldConditions[i] = $root.infinitusai.be.FieldCondition.fromObject(object.fieldConditions[i]);
                        }
                    }
                    if (object.negate != null)
                        message.negate = Boolean(object.negate);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Condition message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {infinitusai.be.Condition} message Condition
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Condition.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldConditions = [];
                    if (options.defaults) {
                        object.conditionId = "";
                        object.conditionDescription = "";
                        object.negate = false;
                    }
                    if (message.conditionId != null && message.hasOwnProperty("conditionId"))
                        object.conditionId = message.conditionId;
                    if (message.conditionDescription != null && message.hasOwnProperty("conditionDescription"))
                        object.conditionDescription = message.conditionDescription;
                    if (message.fieldConditions && message.fieldConditions.length) {
                        object.fieldConditions = [];
                        for (var j = 0; j < message.fieldConditions.length; ++j)
                            object.fieldConditions[j] = $root.infinitusai.be.FieldCondition.toObject(message.fieldConditions[j], options);
                    }
                    if (message.negate != null && message.hasOwnProperty("negate"))
                        object.negate = message.negate;
                    return object;
                };
    
                /**
                 * Converts this Condition to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Condition
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Condition.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Condition
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Condition
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Condition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Condition";
                };
    
                return Condition;
            })();
    
            be.FieldEffect = (function() {
    
                /**
                 * Properties of a FieldEffect.
                 * @memberof infinitusai.be
                 * @interface IFieldEffect
                 * @property {string|null} [fieldPath] FieldEffect fieldPath
                 * @property {string|null} [fieldValueJson] FieldEffect fieldValueJson
                 */
    
                /**
                 * Constructs a new FieldEffect.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FieldEffect.
                 * @implements IFieldEffect
                 * @constructor
                 * @param {infinitusai.be.IFieldEffect=} [properties] Properties to set
                 */
                function FieldEffect(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldEffect fieldPath.
                 * @member {string} fieldPath
                 * @memberof infinitusai.be.FieldEffect
                 * @instance
                 */
                FieldEffect.prototype.fieldPath = "";
    
                /**
                 * FieldEffect fieldValueJson.
                 * @member {string} fieldValueJson
                 * @memberof infinitusai.be.FieldEffect
                 * @instance
                 */
                FieldEffect.prototype.fieldValueJson = "";
    
                /**
                 * Creates a new FieldEffect instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {infinitusai.be.IFieldEffect=} [properties] Properties to set
                 * @returns {infinitusai.be.FieldEffect} FieldEffect instance
                 */
                FieldEffect.create = function create(properties) {
                    return new FieldEffect(properties);
                };
    
                /**
                 * Encodes the specified FieldEffect message. Does not implicitly {@link infinitusai.be.FieldEffect.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {infinitusai.be.IFieldEffect} message FieldEffect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldEffect.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fieldPath != null && Object.hasOwnProperty.call(message, "fieldPath"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.fieldPath);
                    if (message.fieldValueJson != null && Object.hasOwnProperty.call(message, "fieldValueJson"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fieldValueJson);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldEffect message, length delimited. Does not implicitly {@link infinitusai.be.FieldEffect.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {infinitusai.be.IFieldEffect} message FieldEffect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldEffect.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldEffect message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FieldEffect} FieldEffect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldEffect.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FieldEffect();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.fieldPath = reader.string();
                                break;
                            }
                        case 2: {
                                message.fieldValueJson = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldEffect message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FieldEffect} FieldEffect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldEffect.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldEffect message.
                 * @function verify
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldEffect.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                        if (!$util.isString(message.fieldPath))
                            return "fieldPath: string expected";
                    if (message.fieldValueJson != null && message.hasOwnProperty("fieldValueJson"))
                        if (!$util.isString(message.fieldValueJson))
                            return "fieldValueJson: string expected";
                    return null;
                };
    
                /**
                 * Creates a FieldEffect message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FieldEffect} FieldEffect
                 */
                FieldEffect.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FieldEffect)
                        return object;
                    var message = new $root.infinitusai.be.FieldEffect();
                    if (object.fieldPath != null)
                        message.fieldPath = String(object.fieldPath);
                    if (object.fieldValueJson != null)
                        message.fieldValueJson = String(object.fieldValueJson);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldEffect message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {infinitusai.be.FieldEffect} message FieldEffect
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldEffect.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.fieldPath = "";
                        object.fieldValueJson = "";
                    }
                    if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                        object.fieldPath = message.fieldPath;
                    if (message.fieldValueJson != null && message.hasOwnProperty("fieldValueJson"))
                        object.fieldValueJson = message.fieldValueJson;
                    return object;
                };
    
                /**
                 * Converts this FieldEffect to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FieldEffect
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldEffect.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldEffect
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FieldEffect
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldEffect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FieldEffect";
                };
    
                return FieldEffect;
            })();
    
            be.Effect = (function() {
    
                /**
                 * Properties of an Effect.
                 * @memberof infinitusai.be
                 * @interface IEffect
                 * @property {string|null} [effectId] Effect effectId
                 * @property {string|null} [effectDescription] Effect effectDescription
                 * @property {Array.<infinitusai.be.IFieldEffect>|null} [fieldEffects] Effect fieldEffects
                 * @property {string|null} [taskNoteDescription] Effect taskNoteDescription
                 */
    
                /**
                 * Constructs a new Effect.
                 * @memberof infinitusai.be
                 * @classdesc Represents an Effect.
                 * @implements IEffect
                 * @constructor
                 * @param {infinitusai.be.IEffect=} [properties] Properties to set
                 */
                function Effect(properties) {
                    this.fieldEffects = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Effect effectId.
                 * @member {string} effectId
                 * @memberof infinitusai.be.Effect
                 * @instance
                 */
                Effect.prototype.effectId = "";
    
                /**
                 * Effect effectDescription.
                 * @member {string} effectDescription
                 * @memberof infinitusai.be.Effect
                 * @instance
                 */
                Effect.prototype.effectDescription = "";
    
                /**
                 * Effect fieldEffects.
                 * @member {Array.<infinitusai.be.IFieldEffect>} fieldEffects
                 * @memberof infinitusai.be.Effect
                 * @instance
                 */
                Effect.prototype.fieldEffects = $util.emptyArray;
    
                /**
                 * Effect taskNoteDescription.
                 * @member {string} taskNoteDescription
                 * @memberof infinitusai.be.Effect
                 * @instance
                 */
                Effect.prototype.taskNoteDescription = "";
    
                /**
                 * Creates a new Effect instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {infinitusai.be.IEffect=} [properties] Properties to set
                 * @returns {infinitusai.be.Effect} Effect instance
                 */
                Effect.create = function create(properties) {
                    return new Effect(properties);
                };
    
                /**
                 * Encodes the specified Effect message. Does not implicitly {@link infinitusai.be.Effect.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {infinitusai.be.IEffect} message Effect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Effect.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.effectId != null && Object.hasOwnProperty.call(message, "effectId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.effectId);
                    if (message.effectDescription != null && Object.hasOwnProperty.call(message, "effectDescription"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.effectDescription);
                    if (message.fieldEffects != null && message.fieldEffects.length)
                        for (var i = 0; i < message.fieldEffects.length; ++i)
                            $root.infinitusai.be.FieldEffect.encode(message.fieldEffects[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.taskNoteDescription != null && Object.hasOwnProperty.call(message, "taskNoteDescription"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskNoteDescription);
                    return writer;
                };
    
                /**
                 * Encodes the specified Effect message, length delimited. Does not implicitly {@link infinitusai.be.Effect.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {infinitusai.be.IEffect} message Effect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Effect.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Effect message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Effect} Effect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Effect.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Effect();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.effectId = reader.string();
                                break;
                            }
                        case 2: {
                                message.effectDescription = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fieldEffects && message.fieldEffects.length))
                                    message.fieldEffects = [];
                                message.fieldEffects.push($root.infinitusai.be.FieldEffect.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.taskNoteDescription = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Effect message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Effect} Effect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Effect.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Effect message.
                 * @function verify
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Effect.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.effectId != null && message.hasOwnProperty("effectId"))
                        if (!$util.isString(message.effectId))
                            return "effectId: string expected";
                    if (message.effectDescription != null && message.hasOwnProperty("effectDescription"))
                        if (!$util.isString(message.effectDescription))
                            return "effectDescription: string expected";
                    if (message.fieldEffects != null && message.hasOwnProperty("fieldEffects")) {
                        if (!Array.isArray(message.fieldEffects))
                            return "fieldEffects: array expected";
                        for (var i = 0; i < message.fieldEffects.length; ++i) {
                            var error = $root.infinitusai.be.FieldEffect.verify(message.fieldEffects[i]);
                            if (error)
                                return "fieldEffects." + error;
                        }
                    }
                    if (message.taskNoteDescription != null && message.hasOwnProperty("taskNoteDescription"))
                        if (!$util.isString(message.taskNoteDescription))
                            return "taskNoteDescription: string expected";
                    return null;
                };
    
                /**
                 * Creates an Effect message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Effect} Effect
                 */
                Effect.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Effect)
                        return object;
                    var message = new $root.infinitusai.be.Effect();
                    if (object.effectId != null)
                        message.effectId = String(object.effectId);
                    if (object.effectDescription != null)
                        message.effectDescription = String(object.effectDescription);
                    if (object.fieldEffects) {
                        if (!Array.isArray(object.fieldEffects))
                            throw TypeError(".infinitusai.be.Effect.fieldEffects: array expected");
                        message.fieldEffects = [];
                        for (var i = 0; i < object.fieldEffects.length; ++i) {
                            if (typeof object.fieldEffects[i] !== "object")
                                throw TypeError(".infinitusai.be.Effect.fieldEffects: object expected");
                            message.fieldEffects[i] = $root.infinitusai.be.FieldEffect.fromObject(object.fieldEffects[i]);
                        }
                    }
                    if (object.taskNoteDescription != null)
                        message.taskNoteDescription = String(object.taskNoteDescription);
                    return message;
                };
    
                /**
                 * Creates a plain object from an Effect message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {infinitusai.be.Effect} message Effect
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Effect.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldEffects = [];
                    if (options.defaults) {
                        object.effectId = "";
                        object.effectDescription = "";
                        object.taskNoteDescription = "";
                    }
                    if (message.effectId != null && message.hasOwnProperty("effectId"))
                        object.effectId = message.effectId;
                    if (message.effectDescription != null && message.hasOwnProperty("effectDescription"))
                        object.effectDescription = message.effectDescription;
                    if (message.fieldEffects && message.fieldEffects.length) {
                        object.fieldEffects = [];
                        for (var j = 0; j < message.fieldEffects.length; ++j)
                            object.fieldEffects[j] = $root.infinitusai.be.FieldEffect.toObject(message.fieldEffects[j], options);
                    }
                    if (message.taskNoteDescription != null && message.hasOwnProperty("taskNoteDescription"))
                        object.taskNoteDescription = message.taskNoteDescription;
                    return object;
                };
    
                /**
                 * Converts this Effect to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Effect
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Effect.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Effect
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Effect
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Effect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Effect";
                };
    
                return Effect;
            })();
    
            /**
             * RuleType enum.
             * @name infinitusai.be.RuleType
             * @enum {number}
             * @property {number} RULE_TYPE_UNKNOWN=0 RULE_TYPE_UNKNOWN value
             * @property {number} RULE_TYPE_OVERRIDE=1 RULE_TYPE_OVERRIDE value
             * @property {number} RULE_TYPE_ENFORCE=2 RULE_TYPE_ENFORCE value
             * @property {number} RULE_TYPE_ESCALATE=3 RULE_TYPE_ESCALATE value
             */
            be.RuleType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "RULE_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "RULE_TYPE_OVERRIDE"] = 1;
                values[valuesById[2] = "RULE_TYPE_ENFORCE"] = 2;
                values[valuesById[3] = "RULE_TYPE_ESCALATE"] = 3;
                return values;
            })();
    
            be.Rule = (function() {
    
                /**
                 * Properties of a Rule.
                 * @memberof infinitusai.be
                 * @interface IRule
                 * @property {Array.<infinitusai.be.ICondition>|null} [conditions] Rule conditions
                 * @property {Array.<infinitusai.be.IEffect>|null} [effects] Rule effects
                 * @property {infinitusai.be.RuleType|null} [ruleType] Rule ruleType
                 * @property {string|null} [customerId] Rule customerId
                 * @property {string|null} [ruleId] Rule ruleId
                 * @property {string|null} [category] Rule category
                 * @property {number|null} [weight] Rule weight
                 */
    
                /**
                 * Constructs a new Rule.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Rule.
                 * @implements IRule
                 * @constructor
                 * @param {infinitusai.be.IRule=} [properties] Properties to set
                 */
                function Rule(properties) {
                    this.conditions = [];
                    this.effects = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Rule conditions.
                 * @member {Array.<infinitusai.be.ICondition>} conditions
                 * @memberof infinitusai.be.Rule
                 * @instance
                 */
                Rule.prototype.conditions = $util.emptyArray;
    
                /**
                 * Rule effects.
                 * @member {Array.<infinitusai.be.IEffect>} effects
                 * @memberof infinitusai.be.Rule
                 * @instance
                 */
                Rule.prototype.effects = $util.emptyArray;
    
                /**
                 * Rule ruleType.
                 * @member {infinitusai.be.RuleType} ruleType
                 * @memberof infinitusai.be.Rule
                 * @instance
                 */
                Rule.prototype.ruleType = 0;
    
                /**
                 * Rule customerId.
                 * @member {string} customerId
                 * @memberof infinitusai.be.Rule
                 * @instance
                 */
                Rule.prototype.customerId = "";
    
                /**
                 * Rule ruleId.
                 * @member {string} ruleId
                 * @memberof infinitusai.be.Rule
                 * @instance
                 */
                Rule.prototype.ruleId = "";
    
                /**
                 * Rule category.
                 * @member {string} category
                 * @memberof infinitusai.be.Rule
                 * @instance
                 */
                Rule.prototype.category = "";
    
                /**
                 * Rule weight.
                 * @member {number} weight
                 * @memberof infinitusai.be.Rule
                 * @instance
                 */
                Rule.prototype.weight = 0;
    
                /**
                 * Creates a new Rule instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {infinitusai.be.IRule=} [properties] Properties to set
                 * @returns {infinitusai.be.Rule} Rule instance
                 */
                Rule.create = function create(properties) {
                    return new Rule(properties);
                };
    
                /**
                 * Encodes the specified Rule message. Does not implicitly {@link infinitusai.be.Rule.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {infinitusai.be.IRule} message Rule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Rule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conditions != null && message.conditions.length)
                        for (var i = 0; i < message.conditions.length; ++i)
                            $root.infinitusai.be.Condition.encode(message.conditions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.effects != null && message.effects.length)
                        for (var i = 0; i < message.effects.length; ++i)
                            $root.infinitusai.be.Effect.encode(message.effects[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.ruleType != null && Object.hasOwnProperty.call(message, "ruleType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ruleType);
                    if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.customerId);
                    if (message.ruleId != null && Object.hasOwnProperty.call(message, "ruleId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.ruleId);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.category);
                    if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.weight);
                    return writer;
                };
    
                /**
                 * Encodes the specified Rule message, length delimited. Does not implicitly {@link infinitusai.be.Rule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {infinitusai.be.IRule} message Rule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Rule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Rule message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Rule} Rule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Rule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Rule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.conditions && message.conditions.length))
                                    message.conditions = [];
                                message.conditions.push($root.infinitusai.be.Condition.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.effects && message.effects.length))
                                    message.effects = [];
                                message.effects.push($root.infinitusai.be.Effect.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.ruleType = reader.int32();
                                break;
                            }
                        case 4: {
                                message.customerId = reader.string();
                                break;
                            }
                        case 5: {
                                message.ruleId = reader.string();
                                break;
                            }
                        case 6: {
                                message.category = reader.string();
                                break;
                            }
                        case 7: {
                                message.weight = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Rule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Rule} Rule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Rule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Rule message.
                 * @function verify
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Rule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conditions != null && message.hasOwnProperty("conditions")) {
                        if (!Array.isArray(message.conditions))
                            return "conditions: array expected";
                        for (var i = 0; i < message.conditions.length; ++i) {
                            var error = $root.infinitusai.be.Condition.verify(message.conditions[i]);
                            if (error)
                                return "conditions." + error;
                        }
                    }
                    if (message.effects != null && message.hasOwnProperty("effects")) {
                        if (!Array.isArray(message.effects))
                            return "effects: array expected";
                        for (var i = 0; i < message.effects.length; ++i) {
                            var error = $root.infinitusai.be.Effect.verify(message.effects[i]);
                            if (error)
                                return "effects." + error;
                        }
                    }
                    if (message.ruleType != null && message.hasOwnProperty("ruleType"))
                        switch (message.ruleType) {
                        default:
                            return "ruleType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        if (!$util.isString(message.customerId))
                            return "customerId: string expected";
                    if (message.ruleId != null && message.hasOwnProperty("ruleId"))
                        if (!$util.isString(message.ruleId))
                            return "ruleId: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.weight != null && message.hasOwnProperty("weight"))
                        if (!$util.isInteger(message.weight))
                            return "weight: integer expected";
                    return null;
                };
    
                /**
                 * Creates a Rule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Rule} Rule
                 */
                Rule.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Rule)
                        return object;
                    var message = new $root.infinitusai.be.Rule();
                    if (object.conditions) {
                        if (!Array.isArray(object.conditions))
                            throw TypeError(".infinitusai.be.Rule.conditions: array expected");
                        message.conditions = [];
                        for (var i = 0; i < object.conditions.length; ++i) {
                            if (typeof object.conditions[i] !== "object")
                                throw TypeError(".infinitusai.be.Rule.conditions: object expected");
                            message.conditions[i] = $root.infinitusai.be.Condition.fromObject(object.conditions[i]);
                        }
                    }
                    if (object.effects) {
                        if (!Array.isArray(object.effects))
                            throw TypeError(".infinitusai.be.Rule.effects: array expected");
                        message.effects = [];
                        for (var i = 0; i < object.effects.length; ++i) {
                            if (typeof object.effects[i] !== "object")
                                throw TypeError(".infinitusai.be.Rule.effects: object expected");
                            message.effects[i] = $root.infinitusai.be.Effect.fromObject(object.effects[i]);
                        }
                    }
                    switch (object.ruleType) {
                    default:
                        if (typeof object.ruleType === "number") {
                            message.ruleType = object.ruleType;
                            break;
                        }
                        break;
                    case "RULE_TYPE_UNKNOWN":
                    case 0:
                        message.ruleType = 0;
                        break;
                    case "RULE_TYPE_OVERRIDE":
                    case 1:
                        message.ruleType = 1;
                        break;
                    case "RULE_TYPE_ENFORCE":
                    case 2:
                        message.ruleType = 2;
                        break;
                    case "RULE_TYPE_ESCALATE":
                    case 3:
                        message.ruleType = 3;
                        break;
                    }
                    if (object.customerId != null)
                        message.customerId = String(object.customerId);
                    if (object.ruleId != null)
                        message.ruleId = String(object.ruleId);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.weight != null)
                        message.weight = object.weight | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a Rule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {infinitusai.be.Rule} message Rule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Rule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.conditions = [];
                        object.effects = [];
                    }
                    if (options.defaults) {
                        object.ruleType = options.enums === String ? "RULE_TYPE_UNKNOWN" : 0;
                        object.customerId = "";
                        object.ruleId = "";
                        object.category = "";
                        object.weight = 0;
                    }
                    if (message.conditions && message.conditions.length) {
                        object.conditions = [];
                        for (var j = 0; j < message.conditions.length; ++j)
                            object.conditions[j] = $root.infinitusai.be.Condition.toObject(message.conditions[j], options);
                    }
                    if (message.effects && message.effects.length) {
                        object.effects = [];
                        for (var j = 0; j < message.effects.length; ++j)
                            object.effects[j] = $root.infinitusai.be.Effect.toObject(message.effects[j], options);
                    }
                    if (message.ruleType != null && message.hasOwnProperty("ruleType"))
                        object.ruleType = options.enums === String ? $root.infinitusai.be.RuleType[message.ruleType] === undefined ? message.ruleType : $root.infinitusai.be.RuleType[message.ruleType] : message.ruleType;
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        object.customerId = message.customerId;
                    if (message.ruleId != null && message.hasOwnProperty("ruleId"))
                        object.ruleId = message.ruleId;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.weight != null && message.hasOwnProperty("weight"))
                        object.weight = message.weight;
                    return object;
                };
    
                /**
                 * Converts this Rule to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Rule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Rule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Rule
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Rule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Rule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Rule";
                };
    
                return Rule;
            })();
    
            be.EvaluatedRule = (function() {
    
                /**
                 * Properties of an EvaluatedRule.
                 * @memberof infinitusai.be
                 * @interface IEvaluatedRule
                 * @property {infinitusai.be.IRule|null} [rule] EvaluatedRule rule
                 * @property {Array.<string>|null} [unanswered] EvaluatedRule unanswered
                 * @property {Array.<string>|null} [conflicts] EvaluatedRule conflicts
                 * @property {Array.<string>|null} [satisfied] EvaluatedRule satisfied
                 */
    
                /**
                 * Constructs a new EvaluatedRule.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EvaluatedRule.
                 * @implements IEvaluatedRule
                 * @constructor
                 * @param {infinitusai.be.IEvaluatedRule=} [properties] Properties to set
                 */
                function EvaluatedRule(properties) {
                    this.unanswered = [];
                    this.conflicts = [];
                    this.satisfied = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EvaluatedRule rule.
                 * @member {infinitusai.be.IRule|null|undefined} rule
                 * @memberof infinitusai.be.EvaluatedRule
                 * @instance
                 */
                EvaluatedRule.prototype.rule = null;
    
                /**
                 * EvaluatedRule unanswered.
                 * @member {Array.<string>} unanswered
                 * @memberof infinitusai.be.EvaluatedRule
                 * @instance
                 */
                EvaluatedRule.prototype.unanswered = $util.emptyArray;
    
                /**
                 * EvaluatedRule conflicts.
                 * @member {Array.<string>} conflicts
                 * @memberof infinitusai.be.EvaluatedRule
                 * @instance
                 */
                EvaluatedRule.prototype.conflicts = $util.emptyArray;
    
                /**
                 * EvaluatedRule satisfied.
                 * @member {Array.<string>} satisfied
                 * @memberof infinitusai.be.EvaluatedRule
                 * @instance
                 */
                EvaluatedRule.prototype.satisfied = $util.emptyArray;
    
                /**
                 * Creates a new EvaluatedRule instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {infinitusai.be.IEvaluatedRule=} [properties] Properties to set
                 * @returns {infinitusai.be.EvaluatedRule} EvaluatedRule instance
                 */
                EvaluatedRule.create = function create(properties) {
                    return new EvaluatedRule(properties);
                };
    
                /**
                 * Encodes the specified EvaluatedRule message. Does not implicitly {@link infinitusai.be.EvaluatedRule.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {infinitusai.be.IEvaluatedRule} message EvaluatedRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluatedRule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.rule != null && Object.hasOwnProperty.call(message, "rule"))
                        $root.infinitusai.be.Rule.encode(message.rule, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.unanswered != null && message.unanswered.length)
                        for (var i = 0; i < message.unanswered.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.unanswered[i]);
                    if (message.conflicts != null && message.conflicts.length)
                        for (var i = 0; i < message.conflicts.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.conflicts[i]);
                    if (message.satisfied != null && message.satisfied.length)
                        for (var i = 0; i < message.satisfied.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.satisfied[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified EvaluatedRule message, length delimited. Does not implicitly {@link infinitusai.be.EvaluatedRule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {infinitusai.be.IEvaluatedRule} message EvaluatedRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluatedRule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EvaluatedRule message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EvaluatedRule} EvaluatedRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluatedRule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EvaluatedRule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.rule = $root.infinitusai.be.Rule.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.unanswered && message.unanswered.length))
                                    message.unanswered = [];
                                message.unanswered.push(reader.string());
                                break;
                            }
                        case 3: {
                                if (!(message.conflicts && message.conflicts.length))
                                    message.conflicts = [];
                                message.conflicts.push(reader.string());
                                break;
                            }
                        case 4: {
                                if (!(message.satisfied && message.satisfied.length))
                                    message.satisfied = [];
                                message.satisfied.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EvaluatedRule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EvaluatedRule} EvaluatedRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluatedRule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EvaluatedRule message.
                 * @function verify
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EvaluatedRule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.rule != null && message.hasOwnProperty("rule")) {
                        var error = $root.infinitusai.be.Rule.verify(message.rule);
                        if (error)
                            return "rule." + error;
                    }
                    if (message.unanswered != null && message.hasOwnProperty("unanswered")) {
                        if (!Array.isArray(message.unanswered))
                            return "unanswered: array expected";
                        for (var i = 0; i < message.unanswered.length; ++i)
                            if (!$util.isString(message.unanswered[i]))
                                return "unanswered: string[] expected";
                    }
                    if (message.conflicts != null && message.hasOwnProperty("conflicts")) {
                        if (!Array.isArray(message.conflicts))
                            return "conflicts: array expected";
                        for (var i = 0; i < message.conflicts.length; ++i)
                            if (!$util.isString(message.conflicts[i]))
                                return "conflicts: string[] expected";
                    }
                    if (message.satisfied != null && message.hasOwnProperty("satisfied")) {
                        if (!Array.isArray(message.satisfied))
                            return "satisfied: array expected";
                        for (var i = 0; i < message.satisfied.length; ++i)
                            if (!$util.isString(message.satisfied[i]))
                                return "satisfied: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an EvaluatedRule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EvaluatedRule} EvaluatedRule
                 */
                EvaluatedRule.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EvaluatedRule)
                        return object;
                    var message = new $root.infinitusai.be.EvaluatedRule();
                    if (object.rule != null) {
                        if (typeof object.rule !== "object")
                            throw TypeError(".infinitusai.be.EvaluatedRule.rule: object expected");
                        message.rule = $root.infinitusai.be.Rule.fromObject(object.rule);
                    }
                    if (object.unanswered) {
                        if (!Array.isArray(object.unanswered))
                            throw TypeError(".infinitusai.be.EvaluatedRule.unanswered: array expected");
                        message.unanswered = [];
                        for (var i = 0; i < object.unanswered.length; ++i)
                            message.unanswered[i] = String(object.unanswered[i]);
                    }
                    if (object.conflicts) {
                        if (!Array.isArray(object.conflicts))
                            throw TypeError(".infinitusai.be.EvaluatedRule.conflicts: array expected");
                        message.conflicts = [];
                        for (var i = 0; i < object.conflicts.length; ++i)
                            message.conflicts[i] = String(object.conflicts[i]);
                    }
                    if (object.satisfied) {
                        if (!Array.isArray(object.satisfied))
                            throw TypeError(".infinitusai.be.EvaluatedRule.satisfied: array expected");
                        message.satisfied = [];
                        for (var i = 0; i < object.satisfied.length; ++i)
                            message.satisfied[i] = String(object.satisfied[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EvaluatedRule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {infinitusai.be.EvaluatedRule} message EvaluatedRule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EvaluatedRule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.unanswered = [];
                        object.conflicts = [];
                        object.satisfied = [];
                    }
                    if (options.defaults)
                        object.rule = null;
                    if (message.rule != null && message.hasOwnProperty("rule"))
                        object.rule = $root.infinitusai.be.Rule.toObject(message.rule, options);
                    if (message.unanswered && message.unanswered.length) {
                        object.unanswered = [];
                        for (var j = 0; j < message.unanswered.length; ++j)
                            object.unanswered[j] = message.unanswered[j];
                    }
                    if (message.conflicts && message.conflicts.length) {
                        object.conflicts = [];
                        for (var j = 0; j < message.conflicts.length; ++j)
                            object.conflicts[j] = message.conflicts[j];
                    }
                    if (message.satisfied && message.satisfied.length) {
                        object.satisfied = [];
                        for (var j = 0; j < message.satisfied.length; ++j)
                            object.satisfied[j] = message.satisfied[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this EvaluatedRule to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EvaluatedRule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EvaluatedRule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EvaluatedRule
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EvaluatedRule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EvaluatedRule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EvaluatedRule";
                };
    
                return EvaluatedRule;
            })();
    
            be.PayerIntelligenceState = (function() {
    
                /**
                 * Properties of a PayerIntelligenceState.
                 * @memberof infinitusai.be
                 * @interface IPayerIntelligenceState
                 * @property {Array.<infinitusai.be.IEvaluatedRule>|null} [evaluatedRules] PayerIntelligenceState evaluatedRules
                 */
    
                /**
                 * Constructs a new PayerIntelligenceState.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerIntelligenceState.
                 * @implements IPayerIntelligenceState
                 * @constructor
                 * @param {infinitusai.be.IPayerIntelligenceState=} [properties] Properties to set
                 */
                function PayerIntelligenceState(properties) {
                    this.evaluatedRules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerIntelligenceState evaluatedRules.
                 * @member {Array.<infinitusai.be.IEvaluatedRule>} evaluatedRules
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @instance
                 */
                PayerIntelligenceState.prototype.evaluatedRules = $util.emptyArray;
    
                /**
                 * Creates a new PayerIntelligenceState instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceState=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerIntelligenceState} PayerIntelligenceState instance
                 */
                PayerIntelligenceState.create = function create(properties) {
                    return new PayerIntelligenceState(properties);
                };
    
                /**
                 * Encodes the specified PayerIntelligenceState message. Does not implicitly {@link infinitusai.be.PayerIntelligenceState.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceState} message PayerIntelligenceState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerIntelligenceState.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.evaluatedRules != null && message.evaluatedRules.length)
                        for (var i = 0; i < message.evaluatedRules.length; ++i)
                            $root.infinitusai.be.EvaluatedRule.encode(message.evaluatedRules[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerIntelligenceState message, length delimited. Does not implicitly {@link infinitusai.be.PayerIntelligenceState.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceState} message PayerIntelligenceState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerIntelligenceState.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerIntelligenceState message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerIntelligenceState} PayerIntelligenceState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerIntelligenceState.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerIntelligenceState();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.evaluatedRules && message.evaluatedRules.length))
                                    message.evaluatedRules = [];
                                message.evaluatedRules.push($root.infinitusai.be.EvaluatedRule.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerIntelligenceState message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerIntelligenceState} PayerIntelligenceState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerIntelligenceState.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerIntelligenceState message.
                 * @function verify
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerIntelligenceState.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.evaluatedRules != null && message.hasOwnProperty("evaluatedRules")) {
                        if (!Array.isArray(message.evaluatedRules))
                            return "evaluatedRules: array expected";
                        for (var i = 0; i < message.evaluatedRules.length; ++i) {
                            var error = $root.infinitusai.be.EvaluatedRule.verify(message.evaluatedRules[i]);
                            if (error)
                                return "evaluatedRules." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a PayerIntelligenceState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerIntelligenceState} PayerIntelligenceState
                 */
                PayerIntelligenceState.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerIntelligenceState)
                        return object;
                    var message = new $root.infinitusai.be.PayerIntelligenceState();
                    if (object.evaluatedRules) {
                        if (!Array.isArray(object.evaluatedRules))
                            throw TypeError(".infinitusai.be.PayerIntelligenceState.evaluatedRules: array expected");
                        message.evaluatedRules = [];
                        for (var i = 0; i < object.evaluatedRules.length; ++i) {
                            if (typeof object.evaluatedRules[i] !== "object")
                                throw TypeError(".infinitusai.be.PayerIntelligenceState.evaluatedRules: object expected");
                            message.evaluatedRules[i] = $root.infinitusai.be.EvaluatedRule.fromObject(object.evaluatedRules[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerIntelligenceState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {infinitusai.be.PayerIntelligenceState} message PayerIntelligenceState
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerIntelligenceState.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.evaluatedRules = [];
                    if (message.evaluatedRules && message.evaluatedRules.length) {
                        object.evaluatedRules = [];
                        for (var j = 0; j < message.evaluatedRules.length; ++j)
                            object.evaluatedRules[j] = $root.infinitusai.be.EvaluatedRule.toObject(message.evaluatedRules[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this PayerIntelligenceState to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerIntelligenceState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerIntelligenceState
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerIntelligenceState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerIntelligenceState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerIntelligenceState";
                };
    
                return PayerIntelligenceState;
            })();
    
            be.RuleDoc = (function() {
    
                /**
                 * Properties of a RuleDoc.
                 * @memberof infinitusai.be
                 * @interface IRuleDoc
                 * @property {Array.<string>|null} [conditionIds] RuleDoc conditionIds
                 * @property {Array.<string>|null} [effectIds] RuleDoc effectIds
                 * @property {infinitusai.be.RuleType|null} [ruleType] RuleDoc ruleType
                 * @property {string|null} [customerId] RuleDoc customerId
                 * @property {string|null} [category] RuleDoc category
                 * @property {number|null} [weight] RuleDoc weight
                 */
    
                /**
                 * Constructs a new RuleDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RuleDoc.
                 * @implements IRuleDoc
                 * @constructor
                 * @param {infinitusai.be.IRuleDoc=} [properties] Properties to set
                 */
                function RuleDoc(properties) {
                    this.conditionIds = [];
                    this.effectIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RuleDoc conditionIds.
                 * @member {Array.<string>} conditionIds
                 * @memberof infinitusai.be.RuleDoc
                 * @instance
                 */
                RuleDoc.prototype.conditionIds = $util.emptyArray;
    
                /**
                 * RuleDoc effectIds.
                 * @member {Array.<string>} effectIds
                 * @memberof infinitusai.be.RuleDoc
                 * @instance
                 */
                RuleDoc.prototype.effectIds = $util.emptyArray;
    
                /**
                 * RuleDoc ruleType.
                 * @member {infinitusai.be.RuleType} ruleType
                 * @memberof infinitusai.be.RuleDoc
                 * @instance
                 */
                RuleDoc.prototype.ruleType = 0;
    
                /**
                 * RuleDoc customerId.
                 * @member {string} customerId
                 * @memberof infinitusai.be.RuleDoc
                 * @instance
                 */
                RuleDoc.prototype.customerId = "";
    
                /**
                 * RuleDoc category.
                 * @member {string} category
                 * @memberof infinitusai.be.RuleDoc
                 * @instance
                 */
                RuleDoc.prototype.category = "";
    
                /**
                 * RuleDoc weight.
                 * @member {number} weight
                 * @memberof infinitusai.be.RuleDoc
                 * @instance
                 */
                RuleDoc.prototype.weight = 0;
    
                /**
                 * Creates a new RuleDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {infinitusai.be.IRuleDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.RuleDoc} RuleDoc instance
                 */
                RuleDoc.create = function create(properties) {
                    return new RuleDoc(properties);
                };
    
                /**
                 * Encodes the specified RuleDoc message. Does not implicitly {@link infinitusai.be.RuleDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {infinitusai.be.IRuleDoc} message RuleDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RuleDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conditionIds != null && message.conditionIds.length)
                        for (var i = 0; i < message.conditionIds.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.conditionIds[i]);
                    if (message.effectIds != null && message.effectIds.length)
                        for (var i = 0; i < message.effectIds.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.effectIds[i]);
                    if (message.ruleType != null && Object.hasOwnProperty.call(message, "ruleType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ruleType);
                    if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.customerId);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.category);
                    if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.weight);
                    return writer;
                };
    
                /**
                 * Encodes the specified RuleDoc message, length delimited. Does not implicitly {@link infinitusai.be.RuleDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {infinitusai.be.IRuleDoc} message RuleDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RuleDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RuleDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RuleDoc} RuleDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RuleDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RuleDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.conditionIds && message.conditionIds.length))
                                    message.conditionIds = [];
                                message.conditionIds.push(reader.string());
                                break;
                            }
                        case 2: {
                                if (!(message.effectIds && message.effectIds.length))
                                    message.effectIds = [];
                                message.effectIds.push(reader.string());
                                break;
                            }
                        case 3: {
                                message.ruleType = reader.int32();
                                break;
                            }
                        case 4: {
                                message.customerId = reader.string();
                                break;
                            }
                        case 5: {
                                message.category = reader.string();
                                break;
                            }
                        case 6: {
                                message.weight = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RuleDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RuleDoc} RuleDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RuleDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RuleDoc message.
                 * @function verify
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RuleDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conditionIds != null && message.hasOwnProperty("conditionIds")) {
                        if (!Array.isArray(message.conditionIds))
                            return "conditionIds: array expected";
                        for (var i = 0; i < message.conditionIds.length; ++i)
                            if (!$util.isString(message.conditionIds[i]))
                                return "conditionIds: string[] expected";
                    }
                    if (message.effectIds != null && message.hasOwnProperty("effectIds")) {
                        if (!Array.isArray(message.effectIds))
                            return "effectIds: array expected";
                        for (var i = 0; i < message.effectIds.length; ++i)
                            if (!$util.isString(message.effectIds[i]))
                                return "effectIds: string[] expected";
                    }
                    if (message.ruleType != null && message.hasOwnProperty("ruleType"))
                        switch (message.ruleType) {
                        default:
                            return "ruleType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        if (!$util.isString(message.customerId))
                            return "customerId: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.weight != null && message.hasOwnProperty("weight"))
                        if (!$util.isInteger(message.weight))
                            return "weight: integer expected";
                    return null;
                };
    
                /**
                 * Creates a RuleDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RuleDoc} RuleDoc
                 */
                RuleDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RuleDoc)
                        return object;
                    var message = new $root.infinitusai.be.RuleDoc();
                    if (object.conditionIds) {
                        if (!Array.isArray(object.conditionIds))
                            throw TypeError(".infinitusai.be.RuleDoc.conditionIds: array expected");
                        message.conditionIds = [];
                        for (var i = 0; i < object.conditionIds.length; ++i)
                            message.conditionIds[i] = String(object.conditionIds[i]);
                    }
                    if (object.effectIds) {
                        if (!Array.isArray(object.effectIds))
                            throw TypeError(".infinitusai.be.RuleDoc.effectIds: array expected");
                        message.effectIds = [];
                        for (var i = 0; i < object.effectIds.length; ++i)
                            message.effectIds[i] = String(object.effectIds[i]);
                    }
                    switch (object.ruleType) {
                    default:
                        if (typeof object.ruleType === "number") {
                            message.ruleType = object.ruleType;
                            break;
                        }
                        break;
                    case "RULE_TYPE_UNKNOWN":
                    case 0:
                        message.ruleType = 0;
                        break;
                    case "RULE_TYPE_OVERRIDE":
                    case 1:
                        message.ruleType = 1;
                        break;
                    case "RULE_TYPE_ENFORCE":
                    case 2:
                        message.ruleType = 2;
                        break;
                    case "RULE_TYPE_ESCALATE":
                    case 3:
                        message.ruleType = 3;
                        break;
                    }
                    if (object.customerId != null)
                        message.customerId = String(object.customerId);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.weight != null)
                        message.weight = object.weight | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a RuleDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {infinitusai.be.RuleDoc} message RuleDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RuleDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.conditionIds = [];
                        object.effectIds = [];
                    }
                    if (options.defaults) {
                        object.ruleType = options.enums === String ? "RULE_TYPE_UNKNOWN" : 0;
                        object.customerId = "";
                        object.category = "";
                        object.weight = 0;
                    }
                    if (message.conditionIds && message.conditionIds.length) {
                        object.conditionIds = [];
                        for (var j = 0; j < message.conditionIds.length; ++j)
                            object.conditionIds[j] = message.conditionIds[j];
                    }
                    if (message.effectIds && message.effectIds.length) {
                        object.effectIds = [];
                        for (var j = 0; j < message.effectIds.length; ++j)
                            object.effectIds[j] = message.effectIds[j];
                    }
                    if (message.ruleType != null && message.hasOwnProperty("ruleType"))
                        object.ruleType = options.enums === String ? $root.infinitusai.be.RuleType[message.ruleType] === undefined ? message.ruleType : $root.infinitusai.be.RuleType[message.ruleType] : message.ruleType;
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        object.customerId = message.customerId;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.weight != null && message.hasOwnProperty("weight"))
                        object.weight = message.weight;
                    return object;
                };
    
                /**
                 * Converts this RuleDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RuleDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RuleDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RuleDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RuleDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RuleDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RuleDoc";
                };
    
                return RuleDoc;
            })();
    
            be.GetRulesRequest = (function() {
    
                /**
                 * Properties of a GetRulesRequest.
                 * @memberof infinitusai.be
                 * @interface IGetRulesRequest
                 * @property {string|null} [taskUuid] GetRulesRequest taskUuid
                 * @property {string|null} [callUuid] GetRulesRequest callUuid
                 */
    
                /**
                 * Constructs a new GetRulesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetRulesRequest.
                 * @implements IGetRulesRequest
                 * @constructor
                 * @param {infinitusai.be.IGetRulesRequest=} [properties] Properties to set
                 */
                function GetRulesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRulesRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetRulesRequest
                 * @instance
                 */
                GetRulesRequest.prototype.taskUuid = "";
    
                /**
                 * GetRulesRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetRulesRequest
                 * @instance
                 */
                GetRulesRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new GetRulesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {infinitusai.be.IGetRulesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetRulesRequest} GetRulesRequest instance
                 */
                GetRulesRequest.create = function create(properties) {
                    return new GetRulesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetRulesRequest message. Does not implicitly {@link infinitusai.be.GetRulesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {infinitusai.be.IGetRulesRequest} message GetRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRulesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRulesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetRulesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {infinitusai.be.IGetRulesRequest} message GetRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRulesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRulesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetRulesRequest} GetRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRulesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetRulesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRulesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetRulesRequest} GetRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRulesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRulesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRulesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetRulesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetRulesRequest} GetRulesRequest
                 */
                GetRulesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetRulesRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetRulesRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRulesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {infinitusai.be.GetRulesRequest} message GetRulesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRulesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this GetRulesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetRulesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRulesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRulesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetRulesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRulesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetRulesRequest";
                };
    
                return GetRulesRequest;
            })();
    
            be.GetRulesResponse = (function() {
    
                /**
                 * Properties of a GetRulesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetRulesResponse
                 * @property {Array.<infinitusai.be.IRule>|null} [rules] GetRulesResponse rules
                 */
    
                /**
                 * Constructs a new GetRulesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetRulesResponse.
                 * @implements IGetRulesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetRulesResponse=} [properties] Properties to set
                 */
                function GetRulesResponse(properties) {
                    this.rules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRulesResponse rules.
                 * @member {Array.<infinitusai.be.IRule>} rules
                 * @memberof infinitusai.be.GetRulesResponse
                 * @instance
                 */
                GetRulesResponse.prototype.rules = $util.emptyArray;
    
                /**
                 * Creates a new GetRulesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetRulesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetRulesResponse} GetRulesResponse instance
                 */
                GetRulesResponse.create = function create(properties) {
                    return new GetRulesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetRulesResponse message. Does not implicitly {@link infinitusai.be.GetRulesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetRulesResponse} message GetRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRulesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.rules != null && message.rules.length)
                        for (var i = 0; i < message.rules.length; ++i)
                            $root.infinitusai.be.Rule.encode(message.rules[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRulesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetRulesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetRulesResponse} message GetRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRulesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRulesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetRulesResponse} GetRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRulesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetRulesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.rules && message.rules.length))
                                    message.rules = [];
                                message.rules.push($root.infinitusai.be.Rule.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRulesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetRulesResponse} GetRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRulesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRulesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRulesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.rules != null && message.hasOwnProperty("rules")) {
                        if (!Array.isArray(message.rules))
                            return "rules: array expected";
                        for (var i = 0; i < message.rules.length; ++i) {
                            var error = $root.infinitusai.be.Rule.verify(message.rules[i]);
                            if (error)
                                return "rules." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetRulesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetRulesResponse} GetRulesResponse
                 */
                GetRulesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetRulesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetRulesResponse();
                    if (object.rules) {
                        if (!Array.isArray(object.rules))
                            throw TypeError(".infinitusai.be.GetRulesResponse.rules: array expected");
                        message.rules = [];
                        for (var i = 0; i < object.rules.length; ++i) {
                            if (typeof object.rules[i] !== "object")
                                throw TypeError(".infinitusai.be.GetRulesResponse.rules: object expected");
                            message.rules[i] = $root.infinitusai.be.Rule.fromObject(object.rules[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRulesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {infinitusai.be.GetRulesResponse} message GetRulesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRulesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.rules = [];
                    if (message.rules && message.rules.length) {
                        object.rules = [];
                        for (var j = 0; j < message.rules.length; ++j)
                            object.rules[j] = $root.infinitusai.be.Rule.toObject(message.rules[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetRulesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetRulesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRulesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRulesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetRulesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRulesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetRulesResponse";
                };
    
                return GetRulesResponse;
            })();
    
            be.PayerIntelligenceConflicts = (function() {
    
                /**
                 * Properties of a PayerIntelligenceConflicts.
                 * @memberof infinitusai.be
                 * @interface IPayerIntelligenceConflicts
                 * @property {Object.<string,infinitusai.be.IPayerIntelligenceConflictFields>|null} [conflictsByRule] PayerIntelligenceConflicts conflictsByRule
                 */
    
                /**
                 * Constructs a new PayerIntelligenceConflicts.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerIntelligenceConflicts.
                 * @implements IPayerIntelligenceConflicts
                 * @constructor
                 * @param {infinitusai.be.IPayerIntelligenceConflicts=} [properties] Properties to set
                 */
                function PayerIntelligenceConflicts(properties) {
                    this.conflictsByRule = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerIntelligenceConflicts conflictsByRule.
                 * @member {Object.<string,infinitusai.be.IPayerIntelligenceConflictFields>} conflictsByRule
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @instance
                 */
                PayerIntelligenceConflicts.prototype.conflictsByRule = $util.emptyObject;
    
                /**
                 * Creates a new PayerIntelligenceConflicts instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceConflicts=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerIntelligenceConflicts} PayerIntelligenceConflicts instance
                 */
                PayerIntelligenceConflicts.create = function create(properties) {
                    return new PayerIntelligenceConflicts(properties);
                };
    
                /**
                 * Encodes the specified PayerIntelligenceConflicts message. Does not implicitly {@link infinitusai.be.PayerIntelligenceConflicts.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceConflicts} message PayerIntelligenceConflicts message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerIntelligenceConflicts.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conflictsByRule != null && Object.hasOwnProperty.call(message, "conflictsByRule"))
                        for (var keys = Object.keys(message.conflictsByRule), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.PayerIntelligenceConflictFields.encode(message.conflictsByRule[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerIntelligenceConflicts message, length delimited. Does not implicitly {@link infinitusai.be.PayerIntelligenceConflicts.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceConflicts} message PayerIntelligenceConflicts message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerIntelligenceConflicts.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerIntelligenceConflicts message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerIntelligenceConflicts} PayerIntelligenceConflicts
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerIntelligenceConflicts.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerIntelligenceConflicts(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.conflictsByRule === $util.emptyObject)
                                    message.conflictsByRule = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.PayerIntelligenceConflictFields.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.conflictsByRule[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerIntelligenceConflicts message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerIntelligenceConflicts} PayerIntelligenceConflicts
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerIntelligenceConflicts.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerIntelligenceConflicts message.
                 * @function verify
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerIntelligenceConflicts.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conflictsByRule != null && message.hasOwnProperty("conflictsByRule")) {
                        if (!$util.isObject(message.conflictsByRule))
                            return "conflictsByRule: object expected";
                        var key = Object.keys(message.conflictsByRule);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.PayerIntelligenceConflictFields.verify(message.conflictsByRule[key[i]]);
                            if (error)
                                return "conflictsByRule." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a PayerIntelligenceConflicts message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerIntelligenceConflicts} PayerIntelligenceConflicts
                 */
                PayerIntelligenceConflicts.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerIntelligenceConflicts)
                        return object;
                    var message = new $root.infinitusai.be.PayerIntelligenceConflicts();
                    if (object.conflictsByRule) {
                        if (typeof object.conflictsByRule !== "object")
                            throw TypeError(".infinitusai.be.PayerIntelligenceConflicts.conflictsByRule: object expected");
                        message.conflictsByRule = {};
                        for (var keys = Object.keys(object.conflictsByRule), i = 0; i < keys.length; ++i) {
                            if (typeof object.conflictsByRule[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.PayerIntelligenceConflicts.conflictsByRule: object expected");
                            message.conflictsByRule[keys[i]] = $root.infinitusai.be.PayerIntelligenceConflictFields.fromObject(object.conflictsByRule[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerIntelligenceConflicts message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {infinitusai.be.PayerIntelligenceConflicts} message PayerIntelligenceConflicts
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerIntelligenceConflicts.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.conflictsByRule = {};
                    var keys2;
                    if (message.conflictsByRule && (keys2 = Object.keys(message.conflictsByRule)).length) {
                        object.conflictsByRule = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.conflictsByRule[keys2[j]] = $root.infinitusai.be.PayerIntelligenceConflictFields.toObject(message.conflictsByRule[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this PayerIntelligenceConflicts to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerIntelligenceConflicts.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerIntelligenceConflicts
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerIntelligenceConflicts
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerIntelligenceConflicts.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerIntelligenceConflicts";
                };
    
                return PayerIntelligenceConflicts;
            })();
    
            be.PayerIntelligenceConflictFields = (function() {
    
                /**
                 * Properties of a PayerIntelligenceConflictFields.
                 * @memberof infinitusai.be
                 * @interface IPayerIntelligenceConflictFields
                 * @property {Array.<string>|null} [fields] PayerIntelligenceConflictFields fields
                 */
    
                /**
                 * Constructs a new PayerIntelligenceConflictFields.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerIntelligenceConflictFields.
                 * @implements IPayerIntelligenceConflictFields
                 * @constructor
                 * @param {infinitusai.be.IPayerIntelligenceConflictFields=} [properties] Properties to set
                 */
                function PayerIntelligenceConflictFields(properties) {
                    this.fields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerIntelligenceConflictFields fields.
                 * @member {Array.<string>} fields
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @instance
                 */
                PayerIntelligenceConflictFields.prototype.fields = $util.emptyArray;
    
                /**
                 * Creates a new PayerIntelligenceConflictFields instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceConflictFields=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerIntelligenceConflictFields} PayerIntelligenceConflictFields instance
                 */
                PayerIntelligenceConflictFields.create = function create(properties) {
                    return new PayerIntelligenceConflictFields(properties);
                };
    
                /**
                 * Encodes the specified PayerIntelligenceConflictFields message. Does not implicitly {@link infinitusai.be.PayerIntelligenceConflictFields.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceConflictFields} message PayerIntelligenceConflictFields message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerIntelligenceConflictFields.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fields != null && message.fields.length)
                        for (var i = 0; i < message.fields.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fields[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerIntelligenceConflictFields message, length delimited. Does not implicitly {@link infinitusai.be.PayerIntelligenceConflictFields.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceConflictFields} message PayerIntelligenceConflictFields message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerIntelligenceConflictFields.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerIntelligenceConflictFields message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerIntelligenceConflictFields} PayerIntelligenceConflictFields
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerIntelligenceConflictFields.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerIntelligenceConflictFields();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.fields && message.fields.length))
                                    message.fields = [];
                                message.fields.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerIntelligenceConflictFields message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerIntelligenceConflictFields} PayerIntelligenceConflictFields
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerIntelligenceConflictFields.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerIntelligenceConflictFields message.
                 * @function verify
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerIntelligenceConflictFields.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fields != null && message.hasOwnProperty("fields")) {
                        if (!Array.isArray(message.fields))
                            return "fields: array expected";
                        for (var i = 0; i < message.fields.length; ++i)
                            if (!$util.isString(message.fields[i]))
                                return "fields: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a PayerIntelligenceConflictFields message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerIntelligenceConflictFields} PayerIntelligenceConflictFields
                 */
                PayerIntelligenceConflictFields.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerIntelligenceConflictFields)
                        return object;
                    var message = new $root.infinitusai.be.PayerIntelligenceConflictFields();
                    if (object.fields) {
                        if (!Array.isArray(object.fields))
                            throw TypeError(".infinitusai.be.PayerIntelligenceConflictFields.fields: array expected");
                        message.fields = [];
                        for (var i = 0; i < object.fields.length; ++i)
                            message.fields[i] = String(object.fields[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerIntelligenceConflictFields message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {infinitusai.be.PayerIntelligenceConflictFields} message PayerIntelligenceConflictFields
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerIntelligenceConflictFields.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fields = [];
                    if (message.fields && message.fields.length) {
                        object.fields = [];
                        for (var j = 0; j < message.fields.length; ++j)
                            object.fields[j] = message.fields[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this PayerIntelligenceConflictFields to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerIntelligenceConflictFields.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerIntelligenceConflictFields
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerIntelligenceConflictFields
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerIntelligenceConflictFields.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerIntelligenceConflictFields";
                };
    
                return PayerIntelligenceConflictFields;
            })();
    
            be.PayerIntelligenceAction = (function() {
    
                /**
                 * Properties of a PayerIntelligenceAction.
                 * @memberof infinitusai.be
                 * @interface IPayerIntelligenceAction
                 * @property {infinitusai.be.IPayerIntelligenceConflicts|null} [state] PayerIntelligenceAction state
                 * @property {string|null} [utterance] PayerIntelligenceAction utterance
                 */
    
                /**
                 * Constructs a new PayerIntelligenceAction.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerIntelligenceAction.
                 * @implements IPayerIntelligenceAction
                 * @constructor
                 * @param {infinitusai.be.IPayerIntelligenceAction=} [properties] Properties to set
                 */
                function PayerIntelligenceAction(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerIntelligenceAction state.
                 * @member {infinitusai.be.IPayerIntelligenceConflicts|null|undefined} state
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @instance
                 */
                PayerIntelligenceAction.prototype.state = null;
    
                /**
                 * PayerIntelligenceAction utterance.
                 * @member {string} utterance
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @instance
                 */
                PayerIntelligenceAction.prototype.utterance = "";
    
                /**
                 * Creates a new PayerIntelligenceAction instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceAction=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerIntelligenceAction} PayerIntelligenceAction instance
                 */
                PayerIntelligenceAction.create = function create(properties) {
                    return new PayerIntelligenceAction(properties);
                };
    
                /**
                 * Encodes the specified PayerIntelligenceAction message. Does not implicitly {@link infinitusai.be.PayerIntelligenceAction.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceAction} message PayerIntelligenceAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerIntelligenceAction.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        $root.infinitusai.be.PayerIntelligenceConflicts.encode(message.state, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.utterance != null && Object.hasOwnProperty.call(message, "utterance"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.utterance);
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerIntelligenceAction message, length delimited. Does not implicitly {@link infinitusai.be.PayerIntelligenceAction.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {infinitusai.be.IPayerIntelligenceAction} message PayerIntelligenceAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerIntelligenceAction.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerIntelligenceAction message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerIntelligenceAction} PayerIntelligenceAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerIntelligenceAction.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerIntelligenceAction();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.state = $root.infinitusai.be.PayerIntelligenceConflicts.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.utterance = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerIntelligenceAction message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerIntelligenceAction} PayerIntelligenceAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerIntelligenceAction.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerIntelligenceAction message.
                 * @function verify
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerIntelligenceAction.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.state != null && message.hasOwnProperty("state")) {
                        var error = $root.infinitusai.be.PayerIntelligenceConflicts.verify(message.state);
                        if (error)
                            return "state." + error;
                    }
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        if (!$util.isString(message.utterance))
                            return "utterance: string expected";
                    return null;
                };
    
                /**
                 * Creates a PayerIntelligenceAction message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerIntelligenceAction} PayerIntelligenceAction
                 */
                PayerIntelligenceAction.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerIntelligenceAction)
                        return object;
                    var message = new $root.infinitusai.be.PayerIntelligenceAction();
                    if (object.state != null) {
                        if (typeof object.state !== "object")
                            throw TypeError(".infinitusai.be.PayerIntelligenceAction.state: object expected");
                        message.state = $root.infinitusai.be.PayerIntelligenceConflicts.fromObject(object.state);
                    }
                    if (object.utterance != null)
                        message.utterance = String(object.utterance);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerIntelligenceAction message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {infinitusai.be.PayerIntelligenceAction} message PayerIntelligenceAction
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerIntelligenceAction.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.state = null;
                        object.utterance = "";
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = $root.infinitusai.be.PayerIntelligenceConflicts.toObject(message.state, options);
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        object.utterance = message.utterance;
                    return object;
                };
    
                /**
                 * Converts this PayerIntelligenceAction to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerIntelligenceAction.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerIntelligenceAction
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerIntelligenceAction
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerIntelligenceAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerIntelligenceAction";
                };
    
                return PayerIntelligenceAction;
            })();
    
            be.RecordPayerIntelligenceActionRequest = (function() {
    
                /**
                 * Properties of a RecordPayerIntelligenceActionRequest.
                 * @memberof infinitusai.be
                 * @interface IRecordPayerIntelligenceActionRequest
                 * @property {string|null} [taskUUID] RecordPayerIntelligenceActionRequest taskUUID
                 * @property {string|null} [callUUID] RecordPayerIntelligenceActionRequest callUUID
                 * @property {infinitusai.be.IPayerIntelligenceConflicts|null} [conflicts] RecordPayerIntelligenceActionRequest conflicts
                 * @property {string|null} [utterance] RecordPayerIntelligenceActionRequest utterance
                 */
    
                /**
                 * Constructs a new RecordPayerIntelligenceActionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RecordPayerIntelligenceActionRequest.
                 * @implements IRecordPayerIntelligenceActionRequest
                 * @constructor
                 * @param {infinitusai.be.IRecordPayerIntelligenceActionRequest=} [properties] Properties to set
                 */
                function RecordPayerIntelligenceActionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RecordPayerIntelligenceActionRequest taskUUID.
                 * @member {string} taskUUID
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @instance
                 */
                RecordPayerIntelligenceActionRequest.prototype.taskUUID = "";
    
                /**
                 * RecordPayerIntelligenceActionRequest callUUID.
                 * @member {string} callUUID
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @instance
                 */
                RecordPayerIntelligenceActionRequest.prototype.callUUID = "";
    
                /**
                 * RecordPayerIntelligenceActionRequest conflicts.
                 * @member {infinitusai.be.IPayerIntelligenceConflicts|null|undefined} conflicts
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @instance
                 */
                RecordPayerIntelligenceActionRequest.prototype.conflicts = null;
    
                /**
                 * RecordPayerIntelligenceActionRequest utterance.
                 * @member {string} utterance
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @instance
                 */
                RecordPayerIntelligenceActionRequest.prototype.utterance = "";
    
                /**
                 * Creates a new RecordPayerIntelligenceActionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceActionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RecordPayerIntelligenceActionRequest} RecordPayerIntelligenceActionRequest instance
                 */
                RecordPayerIntelligenceActionRequest.create = function create(properties) {
                    return new RecordPayerIntelligenceActionRequest(properties);
                };
    
                /**
                 * Encodes the specified RecordPayerIntelligenceActionRequest message. Does not implicitly {@link infinitusai.be.RecordPayerIntelligenceActionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceActionRequest} message RecordPayerIntelligenceActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordPayerIntelligenceActionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUUID != null && Object.hasOwnProperty.call(message, "taskUUID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUUID);
                    if (message.callUUID != null && Object.hasOwnProperty.call(message, "callUUID"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUUID);
                    if (message.conflicts != null && Object.hasOwnProperty.call(message, "conflicts"))
                        $root.infinitusai.be.PayerIntelligenceConflicts.encode(message.conflicts, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.utterance != null && Object.hasOwnProperty.call(message, "utterance"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.utterance);
                    return writer;
                };
    
                /**
                 * Encodes the specified RecordPayerIntelligenceActionRequest message, length delimited. Does not implicitly {@link infinitusai.be.RecordPayerIntelligenceActionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceActionRequest} message RecordPayerIntelligenceActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordPayerIntelligenceActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RecordPayerIntelligenceActionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RecordPayerIntelligenceActionRequest} RecordPayerIntelligenceActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordPayerIntelligenceActionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RecordPayerIntelligenceActionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUUID = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUUID = reader.string();
                                break;
                            }
                        case 3: {
                                message.conflicts = $root.infinitusai.be.PayerIntelligenceConflicts.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.utterance = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RecordPayerIntelligenceActionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RecordPayerIntelligenceActionRequest} RecordPayerIntelligenceActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordPayerIntelligenceActionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RecordPayerIntelligenceActionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RecordPayerIntelligenceActionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        if (!$util.isString(message.taskUUID))
                            return "taskUUID: string expected";
                    if (message.callUUID != null && message.hasOwnProperty("callUUID"))
                        if (!$util.isString(message.callUUID))
                            return "callUUID: string expected";
                    if (message.conflicts != null && message.hasOwnProperty("conflicts")) {
                        var error = $root.infinitusai.be.PayerIntelligenceConflicts.verify(message.conflicts);
                        if (error)
                            return "conflicts." + error;
                    }
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        if (!$util.isString(message.utterance))
                            return "utterance: string expected";
                    return null;
                };
    
                /**
                 * Creates a RecordPayerIntelligenceActionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RecordPayerIntelligenceActionRequest} RecordPayerIntelligenceActionRequest
                 */
                RecordPayerIntelligenceActionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RecordPayerIntelligenceActionRequest)
                        return object;
                    var message = new $root.infinitusai.be.RecordPayerIntelligenceActionRequest();
                    if (object.taskUUID != null)
                        message.taskUUID = String(object.taskUUID);
                    if (object.callUUID != null)
                        message.callUUID = String(object.callUUID);
                    if (object.conflicts != null) {
                        if (typeof object.conflicts !== "object")
                            throw TypeError(".infinitusai.be.RecordPayerIntelligenceActionRequest.conflicts: object expected");
                        message.conflicts = $root.infinitusai.be.PayerIntelligenceConflicts.fromObject(object.conflicts);
                    }
                    if (object.utterance != null)
                        message.utterance = String(object.utterance);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RecordPayerIntelligenceActionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {infinitusai.be.RecordPayerIntelligenceActionRequest} message RecordPayerIntelligenceActionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordPayerIntelligenceActionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUUID = "";
                        object.callUUID = "";
                        object.conflicts = null;
                        object.utterance = "";
                    }
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        object.taskUUID = message.taskUUID;
                    if (message.callUUID != null && message.hasOwnProperty("callUUID"))
                        object.callUUID = message.callUUID;
                    if (message.conflicts != null && message.hasOwnProperty("conflicts"))
                        object.conflicts = $root.infinitusai.be.PayerIntelligenceConflicts.toObject(message.conflicts, options);
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        object.utterance = message.utterance;
                    return object;
                };
    
                /**
                 * Converts this RecordPayerIntelligenceActionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordPayerIntelligenceActionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RecordPayerIntelligenceActionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordPayerIntelligenceActionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RecordPayerIntelligenceActionRequest";
                };
    
                return RecordPayerIntelligenceActionRequest;
            })();
    
            be.RecordPayerIntelligenceActionResponse = (function() {
    
                /**
                 * Properties of a RecordPayerIntelligenceActionResponse.
                 * @memberof infinitusai.be
                 * @interface IRecordPayerIntelligenceActionResponse
                 */
    
                /**
                 * Constructs a new RecordPayerIntelligenceActionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RecordPayerIntelligenceActionResponse.
                 * @implements IRecordPayerIntelligenceActionResponse
                 * @constructor
                 * @param {infinitusai.be.IRecordPayerIntelligenceActionResponse=} [properties] Properties to set
                 */
                function RecordPayerIntelligenceActionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RecordPayerIntelligenceActionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceActionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RecordPayerIntelligenceActionResponse} RecordPayerIntelligenceActionResponse instance
                 */
                RecordPayerIntelligenceActionResponse.create = function create(properties) {
                    return new RecordPayerIntelligenceActionResponse(properties);
                };
    
                /**
                 * Encodes the specified RecordPayerIntelligenceActionResponse message. Does not implicitly {@link infinitusai.be.RecordPayerIntelligenceActionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceActionResponse} message RecordPayerIntelligenceActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordPayerIntelligenceActionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RecordPayerIntelligenceActionResponse message, length delimited. Does not implicitly {@link infinitusai.be.RecordPayerIntelligenceActionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceActionResponse} message RecordPayerIntelligenceActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordPayerIntelligenceActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RecordPayerIntelligenceActionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RecordPayerIntelligenceActionResponse} RecordPayerIntelligenceActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordPayerIntelligenceActionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RecordPayerIntelligenceActionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RecordPayerIntelligenceActionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RecordPayerIntelligenceActionResponse} RecordPayerIntelligenceActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordPayerIntelligenceActionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RecordPayerIntelligenceActionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RecordPayerIntelligenceActionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RecordPayerIntelligenceActionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RecordPayerIntelligenceActionResponse} RecordPayerIntelligenceActionResponse
                 */
                RecordPayerIntelligenceActionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RecordPayerIntelligenceActionResponse)
                        return object;
                    return new $root.infinitusai.be.RecordPayerIntelligenceActionResponse();
                };
    
                /**
                 * Creates a plain object from a RecordPayerIntelligenceActionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {infinitusai.be.RecordPayerIntelligenceActionResponse} message RecordPayerIntelligenceActionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordPayerIntelligenceActionResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RecordPayerIntelligenceActionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordPayerIntelligenceActionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RecordPayerIntelligenceActionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RecordPayerIntelligenceActionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordPayerIntelligenceActionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RecordPayerIntelligenceActionResponse";
                };
    
                return RecordPayerIntelligenceActionResponse;
            })();
    
            be.UploadPayerIntelRulesResponse = (function() {
    
                /**
                 * Properties of an UploadPayerIntelRulesResponse.
                 * @memberof infinitusai.be
                 * @interface IUploadPayerIntelRulesResponse
                 * @property {string|null} [uploadID] UploadPayerIntelRulesResponse uploadID
                 */
    
                /**
                 * Constructs a new UploadPayerIntelRulesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UploadPayerIntelRulesResponse.
                 * @implements IUploadPayerIntelRulesResponse
                 * @constructor
                 * @param {infinitusai.be.IUploadPayerIntelRulesResponse=} [properties] Properties to set
                 */
                function UploadPayerIntelRulesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UploadPayerIntelRulesResponse uploadID.
                 * @member {string} uploadID
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @instance
                 */
                UploadPayerIntelRulesResponse.prototype.uploadID = "";
    
                /**
                 * Creates a new UploadPayerIntelRulesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.be.IUploadPayerIntelRulesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UploadPayerIntelRulesResponse} UploadPayerIntelRulesResponse instance
                 */
                UploadPayerIntelRulesResponse.create = function create(properties) {
                    return new UploadPayerIntelRulesResponse(properties);
                };
    
                /**
                 * Encodes the specified UploadPayerIntelRulesResponse message. Does not implicitly {@link infinitusai.be.UploadPayerIntelRulesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.be.IUploadPayerIntelRulesResponse} message UploadPayerIntelRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadPayerIntelRulesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uploadID != null && Object.hasOwnProperty.call(message, "uploadID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uploadID);
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadPayerIntelRulesResponse message, length delimited. Does not implicitly {@link infinitusai.be.UploadPayerIntelRulesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.be.IUploadPayerIntelRulesResponse} message UploadPayerIntelRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadPayerIntelRulesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadPayerIntelRulesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UploadPayerIntelRulesResponse} UploadPayerIntelRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadPayerIntelRulesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UploadPayerIntelRulesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uploadID = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadPayerIntelRulesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UploadPayerIntelRulesResponse} UploadPayerIntelRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadPayerIntelRulesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadPayerIntelRulesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadPayerIntelRulesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uploadID != null && message.hasOwnProperty("uploadID"))
                        if (!$util.isString(message.uploadID))
                            return "uploadID: string expected";
                    return null;
                };
    
                /**
                 * Creates an UploadPayerIntelRulesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UploadPayerIntelRulesResponse} UploadPayerIntelRulesResponse
                 */
                UploadPayerIntelRulesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UploadPayerIntelRulesResponse)
                        return object;
                    var message = new $root.infinitusai.be.UploadPayerIntelRulesResponse();
                    if (object.uploadID != null)
                        message.uploadID = String(object.uploadID);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UploadPayerIntelRulesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.be.UploadPayerIntelRulesResponse} message UploadPayerIntelRulesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadPayerIntelRulesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uploadID = "";
                    if (message.uploadID != null && message.hasOwnProperty("uploadID"))
                        object.uploadID = message.uploadID;
                    return object;
                };
    
                /**
                 * Converts this UploadPayerIntelRulesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadPayerIntelRulesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadPayerIntelRulesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UploadPayerIntelRulesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadPayerIntelRulesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UploadPayerIntelRulesResponse";
                };
    
                return UploadPayerIntelRulesResponse;
            })();
    
            be.HumanIntroConfig = (function() {
    
                /**
                 * Properties of a HumanIntroConfig.
                 * @memberof infinitusai.be
                 * @interface IHumanIntroConfig
                 * @property {boolean|null} [useRecordedGreeting] HumanIntroConfig useRecordedGreeting
                 * @property {string|null} [humanVoiceCallStartNotes] HumanIntroConfig humanVoiceCallStartNotes
                 * @property {infinitusai.be.HumanIntroConfig.HumanIntroReason|null} [humanIntroReason] HumanIntroConfig humanIntroReason
                 */
    
                /**
                 * Constructs a new HumanIntroConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a HumanIntroConfig.
                 * @implements IHumanIntroConfig
                 * @constructor
                 * @param {infinitusai.be.IHumanIntroConfig=} [properties] Properties to set
                 */
                function HumanIntroConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * HumanIntroConfig useRecordedGreeting.
                 * @member {boolean} useRecordedGreeting
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @instance
                 */
                HumanIntroConfig.prototype.useRecordedGreeting = false;
    
                /**
                 * HumanIntroConfig humanVoiceCallStartNotes.
                 * @member {string} humanVoiceCallStartNotes
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @instance
                 */
                HumanIntroConfig.prototype.humanVoiceCallStartNotes = "";
    
                /**
                 * HumanIntroConfig humanIntroReason.
                 * @member {infinitusai.be.HumanIntroConfig.HumanIntroReason} humanIntroReason
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @instance
                 */
                HumanIntroConfig.prototype.humanIntroReason = 0;
    
                /**
                 * Creates a new HumanIntroConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {infinitusai.be.IHumanIntroConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.HumanIntroConfig} HumanIntroConfig instance
                 */
                HumanIntroConfig.create = function create(properties) {
                    return new HumanIntroConfig(properties);
                };
    
                /**
                 * Encodes the specified HumanIntroConfig message. Does not implicitly {@link infinitusai.be.HumanIntroConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {infinitusai.be.IHumanIntroConfig} message HumanIntroConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HumanIntroConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.useRecordedGreeting != null && Object.hasOwnProperty.call(message, "useRecordedGreeting"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.useRecordedGreeting);
                    if (message.humanVoiceCallStartNotes != null && Object.hasOwnProperty.call(message, "humanVoiceCallStartNotes"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.humanVoiceCallStartNotes);
                    if (message.humanIntroReason != null && Object.hasOwnProperty.call(message, "humanIntroReason"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.humanIntroReason);
                    return writer;
                };
    
                /**
                 * Encodes the specified HumanIntroConfig message, length delimited. Does not implicitly {@link infinitusai.be.HumanIntroConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {infinitusai.be.IHumanIntroConfig} message HumanIntroConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HumanIntroConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a HumanIntroConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.HumanIntroConfig} HumanIntroConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HumanIntroConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.HumanIntroConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.useRecordedGreeting = reader.bool();
                                break;
                            }
                        case 2: {
                                message.humanVoiceCallStartNotes = reader.string();
                                break;
                            }
                        case 3: {
                                message.humanIntroReason = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a HumanIntroConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.HumanIntroConfig} HumanIntroConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HumanIntroConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a HumanIntroConfig message.
                 * @function verify
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HumanIntroConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.useRecordedGreeting != null && message.hasOwnProperty("useRecordedGreeting"))
                        if (typeof message.useRecordedGreeting !== "boolean")
                            return "useRecordedGreeting: boolean expected";
                    if (message.humanVoiceCallStartNotes != null && message.hasOwnProperty("humanVoiceCallStartNotes"))
                        if (!$util.isString(message.humanVoiceCallStartNotes))
                            return "humanVoiceCallStartNotes: string expected";
                    if (message.humanIntroReason != null && message.hasOwnProperty("humanIntroReason"))
                        switch (message.humanIntroReason) {
                        default:
                            return "humanIntroReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a HumanIntroConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.HumanIntroConfig} HumanIntroConfig
                 */
                HumanIntroConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.HumanIntroConfig)
                        return object;
                    var message = new $root.infinitusai.be.HumanIntroConfig();
                    if (object.useRecordedGreeting != null)
                        message.useRecordedGreeting = Boolean(object.useRecordedGreeting);
                    if (object.humanVoiceCallStartNotes != null)
                        message.humanVoiceCallStartNotes = String(object.humanVoiceCallStartNotes);
                    switch (object.humanIntroReason) {
                    default:
                        if (typeof object.humanIntroReason === "number") {
                            message.humanIntroReason = object.humanIntroReason;
                            break;
                        }
                        break;
                    case "HUMAN_INTRO_REASON_UNKNOWN":
                    case 0:
                        message.humanIntroReason = 0;
                        break;
                    case "HUMAN_INTRO_REASON_FASTTRACK":
                    case 1:
                        message.humanIntroReason = 1;
                        break;
                    case "HUMAN_INTRO_REASON_AUTO_DIALER":
                    case 2:
                        message.humanIntroReason = 2;
                        break;
                    case "HUMAN_INTRO_REASON_VOICE_PAYER_REFUSAL":
                    case 3:
                        message.humanIntroReason = 3;
                        break;
                    case "HUMAN_INTRO_REASON_OFFICIAL_PAYER_SOP":
                    case 4:
                        message.humanIntroReason = 4;
                        break;
                    case "HUMAN_INTRO_REASON_ESCALATION_TO_SUPERVISOR":
                    case 5:
                        message.humanIntroReason = 5;
                        break;
                    case "HUMAN_INTRO_REASON_ESCALATION_TO_PA_DEPARTMENT":
                    case 6:
                        message.humanIntroReason = 6;
                        break;
                    case "HUMAN_INTRO_REASON_ESCALATION_TO_TPA":
                    case 7:
                        message.humanIntroReason = 7;
                        break;
                    case "HUMAN_INTRO_REASON_ESCALATION_OTHER":
                    case 8:
                        message.humanIntroReason = 8;
                        break;
                    case "HUMAN_INTRO_REASON_NON_ESCALATION_OTHER":
                    case 9:
                        message.humanIntroReason = 9;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a HumanIntroConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {infinitusai.be.HumanIntroConfig} message HumanIntroConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HumanIntroConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.useRecordedGreeting = false;
                        object.humanVoiceCallStartNotes = "";
                        object.humanIntroReason = options.enums === String ? "HUMAN_INTRO_REASON_UNKNOWN" : 0;
                    }
                    if (message.useRecordedGreeting != null && message.hasOwnProperty("useRecordedGreeting"))
                        object.useRecordedGreeting = message.useRecordedGreeting;
                    if (message.humanVoiceCallStartNotes != null && message.hasOwnProperty("humanVoiceCallStartNotes"))
                        object.humanVoiceCallStartNotes = message.humanVoiceCallStartNotes;
                    if (message.humanIntroReason != null && message.hasOwnProperty("humanIntroReason"))
                        object.humanIntroReason = options.enums === String ? $root.infinitusai.be.HumanIntroConfig.HumanIntroReason[message.humanIntroReason] === undefined ? message.humanIntroReason : $root.infinitusai.be.HumanIntroConfig.HumanIntroReason[message.humanIntroReason] : message.humanIntroReason;
                    return object;
                };
    
                /**
                 * Converts this HumanIntroConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HumanIntroConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for HumanIntroConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.HumanIntroConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                HumanIntroConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.HumanIntroConfig";
                };
    
                /**
                 * HumanIntroReason enum.
                 * @name infinitusai.be.HumanIntroConfig.HumanIntroReason
                 * @enum {number}
                 * @property {number} HUMAN_INTRO_REASON_UNKNOWN=0 HUMAN_INTRO_REASON_UNKNOWN value
                 * @property {number} HUMAN_INTRO_REASON_FASTTRACK=1 HUMAN_INTRO_REASON_FASTTRACK value
                 * @property {number} HUMAN_INTRO_REASON_AUTO_DIALER=2 HUMAN_INTRO_REASON_AUTO_DIALER value
                 * @property {number} HUMAN_INTRO_REASON_VOICE_PAYER_REFUSAL=3 HUMAN_INTRO_REASON_VOICE_PAYER_REFUSAL value
                 * @property {number} HUMAN_INTRO_REASON_OFFICIAL_PAYER_SOP=4 HUMAN_INTRO_REASON_OFFICIAL_PAYER_SOP value
                 * @property {number} HUMAN_INTRO_REASON_ESCALATION_TO_SUPERVISOR=5 HUMAN_INTRO_REASON_ESCALATION_TO_SUPERVISOR value
                 * @property {number} HUMAN_INTRO_REASON_ESCALATION_TO_PA_DEPARTMENT=6 HUMAN_INTRO_REASON_ESCALATION_TO_PA_DEPARTMENT value
                 * @property {number} HUMAN_INTRO_REASON_ESCALATION_TO_TPA=7 HUMAN_INTRO_REASON_ESCALATION_TO_TPA value
                 * @property {number} HUMAN_INTRO_REASON_ESCALATION_OTHER=8 HUMAN_INTRO_REASON_ESCALATION_OTHER value
                 * @property {number} HUMAN_INTRO_REASON_NON_ESCALATION_OTHER=9 HUMAN_INTRO_REASON_NON_ESCALATION_OTHER value
                 */
                HumanIntroConfig.HumanIntroReason = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "HUMAN_INTRO_REASON_UNKNOWN"] = 0;
                    values[valuesById[1] = "HUMAN_INTRO_REASON_FASTTRACK"] = 1;
                    values[valuesById[2] = "HUMAN_INTRO_REASON_AUTO_DIALER"] = 2;
                    values[valuesById[3] = "HUMAN_INTRO_REASON_VOICE_PAYER_REFUSAL"] = 3;
                    values[valuesById[4] = "HUMAN_INTRO_REASON_OFFICIAL_PAYER_SOP"] = 4;
                    values[valuesById[5] = "HUMAN_INTRO_REASON_ESCALATION_TO_SUPERVISOR"] = 5;
                    values[valuesById[6] = "HUMAN_INTRO_REASON_ESCALATION_TO_PA_DEPARTMENT"] = 6;
                    values[valuesById[7] = "HUMAN_INTRO_REASON_ESCALATION_TO_TPA"] = 7;
                    values[valuesById[8] = "HUMAN_INTRO_REASON_ESCALATION_OTHER"] = 8;
                    values[valuesById[9] = "HUMAN_INTRO_REASON_NON_ESCALATION_OTHER"] = 9;
                    return values;
                })();
    
                return HumanIntroConfig;
            })();
    
            be.TaskFailReason = (function() {
    
                /**
                 * Properties of a TaskFailReason.
                 * @memberof infinitusai.be
                 * @interface ITaskFailReason
                 * @property {infinitusai.be.TaskFailReason.Type|null} [type] TaskFailReason type
                 */
    
                /**
                 * Constructs a new TaskFailReason.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TaskFailReason.
                 * @implements ITaskFailReason
                 * @constructor
                 * @param {infinitusai.be.ITaskFailReason=} [properties] Properties to set
                 */
                function TaskFailReason(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskFailReason type.
                 * @member {infinitusai.be.TaskFailReason.Type} type
                 * @memberof infinitusai.be.TaskFailReason
                 * @instance
                 */
                TaskFailReason.prototype.type = 0;
    
                /**
                 * Creates a new TaskFailReason instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {infinitusai.be.ITaskFailReason=} [properties] Properties to set
                 * @returns {infinitusai.be.TaskFailReason} TaskFailReason instance
                 */
                TaskFailReason.create = function create(properties) {
                    return new TaskFailReason(properties);
                };
    
                /**
                 * Encodes the specified TaskFailReason message. Does not implicitly {@link infinitusai.be.TaskFailReason.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {infinitusai.be.ITaskFailReason} message TaskFailReason message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskFailReason.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskFailReason message, length delimited. Does not implicitly {@link infinitusai.be.TaskFailReason.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {infinitusai.be.ITaskFailReason} message TaskFailReason message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskFailReason.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskFailReason message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TaskFailReason} TaskFailReason
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskFailReason.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskFailReason();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskFailReason message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TaskFailReason} TaskFailReason
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskFailReason.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskFailReason message.
                 * @function verify
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskFailReason.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 99:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a TaskFailReason message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TaskFailReason} TaskFailReason
                 */
                TaskFailReason.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TaskFailReason)
                        return object;
                    var message = new $root.infinitusai.be.TaskFailReason();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "REASON_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "REASON_BAD_DATA":
                    case 1:
                        message.type = 1;
                        break;
                    case "REASON_MISSING_DATA":
                    case 2:
                        message.type = 2;
                        break;
                    case "REASON_INVALID_PLAN":
                    case 3:
                        message.type = 3;
                        break;
                    case "REASON_PAYER_UNREACHABLE":
                    case 4:
                        message.type = 4;
                        break;
                    case "REASON_PATIENT_DECEASED":
                    case 5:
                        message.type = 5;
                        break;
                    case "REASON_PLAN_TERMINATED":
                    case 6:
                        message.type = 6;
                        break;
                    case "REASON_CUSTOMER_CANCELED":
                    case 7:
                        message.type = 7;
                        break;
                    case "REASON_PAYER_REFUSES_THIRD_PARTY":
                    case 8:
                        message.type = 8;
                        break;
                    case "REASON_PAYER_REFUSES_DA":
                    case 9:
                        message.type = 9;
                        break;
                    case "REASON_PAST_DUE":
                    case 10:
                        message.type = 10;
                        break;
                    case "REASON_TASK_EXPIRED":
                    case 11:
                        message.type = 11;
                        break;
                    case "REASON_IVR_NAVIGATION_FAILURE":
                    case 12:
                        message.type = 12;
                        break;
                    case "REASON_PRESCRIBER_UNREACHABLE":
                    case 13:
                        message.type = 13;
                        break;
                    case "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD":
                    case 14:
                        message.type = 14;
                        break;
                    case "REASON_AGENT_AUTOMATION_HANGUP":
                    case 15:
                        message.type = 15;
                        break;
                    case "FASTTRACK_REASON_REDIAL":
                    case 16:
                        message.type = 16;
                        break;
                    case "REASON_NO_OPERATOR_AVAILABLE":
                    case 17:
                        message.type = 17;
                        break;
                    case "REASON_HOLD_NAVIGATION_FAILURE":
                    case 18:
                        message.type = 18;
                        break;
                    case "REASON_OTHER":
                    case 99:
                        message.type = 99;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskFailReason message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {infinitusai.be.TaskFailReason} message TaskFailReason
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskFailReason.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.type = options.enums === String ? "REASON_UNKNOWN" : 0;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.TaskFailReason.Type[message.type] === undefined ? message.type : $root.infinitusai.be.TaskFailReason.Type[message.type] : message.type;
                    return object;
                };
    
                /**
                 * Converts this TaskFailReason to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TaskFailReason
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskFailReason.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskFailReason
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TaskFailReason
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskFailReason.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TaskFailReason";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.TaskFailReason.Type
                 * @enum {number}
                 * @property {number} REASON_UNKNOWN=0 REASON_UNKNOWN value
                 * @property {number} REASON_BAD_DATA=1 REASON_BAD_DATA value
                 * @property {number} REASON_MISSING_DATA=2 REASON_MISSING_DATA value
                 * @property {number} REASON_INVALID_PLAN=3 REASON_INVALID_PLAN value
                 * @property {number} REASON_PAYER_UNREACHABLE=4 REASON_PAYER_UNREACHABLE value
                 * @property {number} REASON_PATIENT_DECEASED=5 REASON_PATIENT_DECEASED value
                 * @property {number} REASON_PLAN_TERMINATED=6 REASON_PLAN_TERMINATED value
                 * @property {number} REASON_CUSTOMER_CANCELED=7 REASON_CUSTOMER_CANCELED value
                 * @property {number} REASON_PAYER_REFUSES_THIRD_PARTY=8 REASON_PAYER_REFUSES_THIRD_PARTY value
                 * @property {number} REASON_PAYER_REFUSES_DA=9 REASON_PAYER_REFUSES_DA value
                 * @property {number} REASON_PAST_DUE=10 REASON_PAST_DUE value
                 * @property {number} REASON_TASK_EXPIRED=11 REASON_TASK_EXPIRED value
                 * @property {number} REASON_IVR_NAVIGATION_FAILURE=12 REASON_IVR_NAVIGATION_FAILURE value
                 * @property {number} REASON_PRESCRIBER_UNREACHABLE=13 REASON_PRESCRIBER_UNREACHABLE value
                 * @property {number} REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD=14 REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD value
                 * @property {number} REASON_AGENT_AUTOMATION_HANGUP=15 REASON_AGENT_AUTOMATION_HANGUP value
                 * @property {number} FASTTRACK_REASON_REDIAL=16 FASTTRACK_REASON_REDIAL value
                 * @property {number} REASON_NO_OPERATOR_AVAILABLE=17 REASON_NO_OPERATOR_AVAILABLE value
                 * @property {number} REASON_HOLD_NAVIGATION_FAILURE=18 REASON_HOLD_NAVIGATION_FAILURE value
                 * @property {number} REASON_OTHER=99 REASON_OTHER value
                 */
                TaskFailReason.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REASON_UNKNOWN"] = 0;
                    values[valuesById[1] = "REASON_BAD_DATA"] = 1;
                    values[valuesById[2] = "REASON_MISSING_DATA"] = 2;
                    values[valuesById[3] = "REASON_INVALID_PLAN"] = 3;
                    values[valuesById[4] = "REASON_PAYER_UNREACHABLE"] = 4;
                    values[valuesById[5] = "REASON_PATIENT_DECEASED"] = 5;
                    values[valuesById[6] = "REASON_PLAN_TERMINATED"] = 6;
                    values[valuesById[7] = "REASON_CUSTOMER_CANCELED"] = 7;
                    values[valuesById[8] = "REASON_PAYER_REFUSES_THIRD_PARTY"] = 8;
                    values[valuesById[9] = "REASON_PAYER_REFUSES_DA"] = 9;
                    values[valuesById[10] = "REASON_PAST_DUE"] = 10;
                    values[valuesById[11] = "REASON_TASK_EXPIRED"] = 11;
                    values[valuesById[12] = "REASON_IVR_NAVIGATION_FAILURE"] = 12;
                    values[valuesById[13] = "REASON_PRESCRIBER_UNREACHABLE"] = 13;
                    values[valuesById[14] = "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD"] = 14;
                    values[valuesById[15] = "REASON_AGENT_AUTOMATION_HANGUP"] = 15;
                    values[valuesById[16] = "FASTTRACK_REASON_REDIAL"] = 16;
                    values[valuesById[17] = "REASON_NO_OPERATOR_AVAILABLE"] = 17;
                    values[valuesById[18] = "REASON_HOLD_NAVIGATION_FAILURE"] = 18;
                    values[valuesById[99] = "REASON_OTHER"] = 99;
                    return values;
                })();
    
                return TaskFailReason;
            })();
    
            be.CreateFastTrackTaskAndCallRequest = (function() {
    
                /**
                 * Properties of a CreateFastTrackTaskAndCallRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateFastTrackTaskAndCallRequest
                 * @property {string|null} [payorId] CreateFastTrackTaskAndCallRequest payorId
                 * @property {infinitusai.be.TaskType|null} [taskType] CreateFastTrackTaskAndCallRequest taskType
                 * @property {string|null} [memberId] CreateFastTrackTaskAndCallRequest memberId
                 * @property {string|null} [phoneNumber] CreateFastTrackTaskAndCallRequest phoneNumber
                 * @property {string|null} [providerNpi] CreateFastTrackTaskAndCallRequest providerNpi
                 * @property {infinitusai.be.IDate|null} [patientDob] CreateFastTrackTaskAndCallRequest patientDob
                 * @property {string|null} [relatedLink] CreateFastTrackTaskAndCallRequest relatedLink
                 * @property {string|null} [providerTaxId] CreateFastTrackTaskAndCallRequest providerTaxId
                 * @property {string|null} [providerZipCode] CreateFastTrackTaskAndCallRequest providerZipCode
                 * @property {string|null} [patientZipCode] CreateFastTrackTaskAndCallRequest patientZipCode
                 * @property {infinitusai.be.FacilityType.Type|null} [facilityType] CreateFastTrackTaskAndCallRequest facilityType
                 * @property {string|null} [groupNumber] CreateFastTrackTaskAndCallRequest groupNumber
                 * @property {string|null} [patientName] CreateFastTrackTaskAndCallRequest patientName
                 * @property {string|null} [providerName] CreateFastTrackTaskAndCallRequest providerName
                 * @property {infinitusai.be.ProviderInfo.NetworkStatus|null} [networkStatus] CreateFastTrackTaskAndCallRequest networkStatus
                 * @property {infinitusai.be.IDate|null} [dateOfService] CreateFastTrackTaskAndCallRequest dateOfService
                 * @property {boolean|null} [shouldPlayRecordedGreeting] CreateFastTrackTaskAndCallRequest shouldPlayRecordedGreeting
                 * @property {infinitusai.be.TaskSource|null} [taskSource] CreateFastTrackTaskAndCallRequest taskSource
                 * @property {string|null} [callbackNumber] CreateFastTrackTaskAndCallRequest callbackNumber
                 * @property {string|null} [payorName] CreateFastTrackTaskAndCallRequest payorName
                 * @property {infinitusai.be.IFastTrackClaimsInputs|null} [claimsInputs] CreateFastTrackTaskAndCallRequest claimsInputs
                 */
    
                /**
                 * Constructs a new CreateFastTrackTaskAndCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateFastTrackTaskAndCallRequest.
                 * @implements ICreateFastTrackTaskAndCallRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateFastTrackTaskAndCallRequest=} [properties] Properties to set
                 */
                function CreateFastTrackTaskAndCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateFastTrackTaskAndCallRequest payorId.
                 * @member {string} payorId
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.payorId = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.taskType = 0;
    
                /**
                 * CreateFastTrackTaskAndCallRequest memberId.
                 * @member {string} memberId
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.memberId = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.phoneNumber = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest providerNpi.
                 * @member {string} providerNpi
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.providerNpi = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest patientDob.
                 * @member {infinitusai.be.IDate|null|undefined} patientDob
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.patientDob = null;
    
                /**
                 * CreateFastTrackTaskAndCallRequest relatedLink.
                 * @member {string} relatedLink
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.relatedLink = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest providerTaxId.
                 * @member {string} providerTaxId
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.providerTaxId = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest providerZipCode.
                 * @member {string} providerZipCode
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.providerZipCode = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest patientZipCode.
                 * @member {string} patientZipCode
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.patientZipCode = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest facilityType.
                 * @member {infinitusai.be.FacilityType.Type} facilityType
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.facilityType = 0;
    
                /**
                 * CreateFastTrackTaskAndCallRequest groupNumber.
                 * @member {string} groupNumber
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.groupNumber = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest patientName.
                 * @member {string} patientName
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.patientName = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest providerName.
                 * @member {string} providerName
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.providerName = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest networkStatus.
                 * @member {infinitusai.be.ProviderInfo.NetworkStatus} networkStatus
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.networkStatus = 0;
    
                /**
                 * CreateFastTrackTaskAndCallRequest dateOfService.
                 * @member {infinitusai.be.IDate|null|undefined} dateOfService
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.dateOfService = null;
    
                /**
                 * CreateFastTrackTaskAndCallRequest shouldPlayRecordedGreeting.
                 * @member {boolean} shouldPlayRecordedGreeting
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.shouldPlayRecordedGreeting = false;
    
                /**
                 * CreateFastTrackTaskAndCallRequest taskSource.
                 * @member {infinitusai.be.TaskSource} taskSource
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.taskSource = 0;
    
                /**
                 * CreateFastTrackTaskAndCallRequest callbackNumber.
                 * @member {string} callbackNumber
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.callbackNumber = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest payorName.
                 * @member {string} payorName
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.payorName = "";
    
                /**
                 * CreateFastTrackTaskAndCallRequest claimsInputs.
                 * @member {infinitusai.be.IFastTrackClaimsInputs|null|undefined} claimsInputs
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 */
                CreateFastTrackTaskAndCallRequest.prototype.claimsInputs = null;
    
                /**
                 * Creates a new CreateFastTrackTaskAndCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackTaskAndCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateFastTrackTaskAndCallRequest} CreateFastTrackTaskAndCallRequest instance
                 */
                CreateFastTrackTaskAndCallRequest.create = function create(properties) {
                    return new CreateFastTrackTaskAndCallRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateFastTrackTaskAndCallRequest message. Does not implicitly {@link infinitusai.be.CreateFastTrackTaskAndCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackTaskAndCallRequest} message CreateFastTrackTaskAndCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFastTrackTaskAndCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payorId != null && Object.hasOwnProperty.call(message, "payorId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.payorId);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.memberId);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phoneNumber);
                    if (message.providerNpi != null && Object.hasOwnProperty.call(message, "providerNpi"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerNpi);
                    if (message.patientDob != null && Object.hasOwnProperty.call(message, "patientDob"))
                        $root.infinitusai.be.Date.encode(message.patientDob, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.relatedLink != null && Object.hasOwnProperty.call(message, "relatedLink"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.relatedLink);
                    if (message.providerTaxId != null && Object.hasOwnProperty.call(message, "providerTaxId"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.providerTaxId);
                    if (message.providerZipCode != null && Object.hasOwnProperty.call(message, "providerZipCode"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.providerZipCode);
                    if (message.patientZipCode != null && Object.hasOwnProperty.call(message, "patientZipCode"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.patientZipCode);
                    if (message.facilityType != null && Object.hasOwnProperty.call(message, "facilityType"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.facilityType);
                    if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.groupNumber);
                    if (message.patientName != null && Object.hasOwnProperty.call(message, "patientName"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.patientName);
                    if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.providerName);
                    if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                        writer.uint32(/* id 15, wireType 0 =*/120).int32(message.networkStatus);
                    if (message.dateOfService != null && Object.hasOwnProperty.call(message, "dateOfService"))
                        $root.infinitusai.be.Date.encode(message.dateOfService, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.shouldPlayRecordedGreeting != null && Object.hasOwnProperty.call(message, "shouldPlayRecordedGreeting"))
                        writer.uint32(/* id 17, wireType 0 =*/136).bool(message.shouldPlayRecordedGreeting);
                    if (message.taskSource != null && Object.hasOwnProperty.call(message, "taskSource"))
                        writer.uint32(/* id 18, wireType 0 =*/144).int32(message.taskSource);
                    if (message.callbackNumber != null && Object.hasOwnProperty.call(message, "callbackNumber"))
                        writer.uint32(/* id 19, wireType 2 =*/154).string(message.callbackNumber);
                    if (message.payorName != null && Object.hasOwnProperty.call(message, "payorName"))
                        writer.uint32(/* id 20, wireType 2 =*/162).string(message.payorName);
                    if (message.claimsInputs != null && Object.hasOwnProperty.call(message, "claimsInputs"))
                        $root.infinitusai.be.FastTrackClaimsInputs.encode(message.claimsInputs, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateFastTrackTaskAndCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateFastTrackTaskAndCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackTaskAndCallRequest} message CreateFastTrackTaskAndCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFastTrackTaskAndCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateFastTrackTaskAndCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateFastTrackTaskAndCallRequest} CreateFastTrackTaskAndCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFastTrackTaskAndCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateFastTrackTaskAndCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.payorId = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.memberId = reader.string();
                                break;
                            }
                        case 4: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 5: {
                                message.providerNpi = reader.string();
                                break;
                            }
                        case 6: {
                                message.patientDob = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.relatedLink = reader.string();
                                break;
                            }
                        case 8: {
                                message.providerTaxId = reader.string();
                                break;
                            }
                        case 9: {
                                message.providerZipCode = reader.string();
                                break;
                            }
                        case 10: {
                                message.patientZipCode = reader.string();
                                break;
                            }
                        case 11: {
                                message.facilityType = reader.int32();
                                break;
                            }
                        case 12: {
                                message.groupNumber = reader.string();
                                break;
                            }
                        case 13: {
                                message.patientName = reader.string();
                                break;
                            }
                        case 14: {
                                message.providerName = reader.string();
                                break;
                            }
                        case 15: {
                                message.networkStatus = reader.int32();
                                break;
                            }
                        case 16: {
                                message.dateOfService = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.shouldPlayRecordedGreeting = reader.bool();
                                break;
                            }
                        case 18: {
                                message.taskSource = reader.int32();
                                break;
                            }
                        case 19: {
                                message.callbackNumber = reader.string();
                                break;
                            }
                        case 20: {
                                message.payorName = reader.string();
                                break;
                            }
                        case 21: {
                                message.claimsInputs = $root.infinitusai.be.FastTrackClaimsInputs.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateFastTrackTaskAndCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateFastTrackTaskAndCallRequest} CreateFastTrackTaskAndCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFastTrackTaskAndCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateFastTrackTaskAndCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateFastTrackTaskAndCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payorId != null && message.hasOwnProperty("payorId"))
                        if (!$util.isString(message.payorId))
                            return "payorId: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.memberId != null && message.hasOwnProperty("memberId"))
                        if (!$util.isString(message.memberId))
                            return "memberId: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.providerNpi != null && message.hasOwnProperty("providerNpi"))
                        if (!$util.isString(message.providerNpi))
                            return "providerNpi: string expected";
                    if (message.patientDob != null && message.hasOwnProperty("patientDob")) {
                        var error = $root.infinitusai.be.Date.verify(message.patientDob);
                        if (error)
                            return "patientDob." + error;
                    }
                    if (message.relatedLink != null && message.hasOwnProperty("relatedLink"))
                        if (!$util.isString(message.relatedLink))
                            return "relatedLink: string expected";
                    if (message.providerTaxId != null && message.hasOwnProperty("providerTaxId"))
                        if (!$util.isString(message.providerTaxId))
                            return "providerTaxId: string expected";
                    if (message.providerZipCode != null && message.hasOwnProperty("providerZipCode"))
                        if (!$util.isString(message.providerZipCode))
                            return "providerZipCode: string expected";
                    if (message.patientZipCode != null && message.hasOwnProperty("patientZipCode"))
                        if (!$util.isString(message.patientZipCode))
                            return "patientZipCode: string expected";
                    if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                        switch (message.facilityType) {
                        default:
                            return "facilityType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                        if (!$util.isString(message.groupNumber))
                            return "groupNumber: string expected";
                    if (message.patientName != null && message.hasOwnProperty("patientName"))
                        if (!$util.isString(message.patientName))
                            return "patientName: string expected";
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        if (!$util.isString(message.providerName))
                            return "providerName: string expected";
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                        switch (message.networkStatus) {
                        default:
                            return "networkStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.dateOfService != null && message.hasOwnProperty("dateOfService")) {
                        var error = $root.infinitusai.be.Date.verify(message.dateOfService);
                        if (error)
                            return "dateOfService." + error;
                    }
                    if (message.shouldPlayRecordedGreeting != null && message.hasOwnProperty("shouldPlayRecordedGreeting"))
                        if (typeof message.shouldPlayRecordedGreeting !== "boolean")
                            return "shouldPlayRecordedGreeting: boolean expected";
                    if (message.taskSource != null && message.hasOwnProperty("taskSource"))
                        switch (message.taskSource) {
                        default:
                            return "taskSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                    if (message.callbackNumber != null && message.hasOwnProperty("callbackNumber"))
                        if (!$util.isString(message.callbackNumber))
                            return "callbackNumber: string expected";
                    if (message.payorName != null && message.hasOwnProperty("payorName"))
                        if (!$util.isString(message.payorName))
                            return "payorName: string expected";
                    if (message.claimsInputs != null && message.hasOwnProperty("claimsInputs")) {
                        var error = $root.infinitusai.be.FastTrackClaimsInputs.verify(message.claimsInputs);
                        if (error)
                            return "claimsInputs." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateFastTrackTaskAndCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateFastTrackTaskAndCallRequest} CreateFastTrackTaskAndCallRequest
                 */
                CreateFastTrackTaskAndCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateFastTrackTaskAndCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateFastTrackTaskAndCallRequest();
                    if (object.payorId != null)
                        message.payorId = String(object.payorId);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.memberId != null)
                        message.memberId = String(object.memberId);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.providerNpi != null)
                        message.providerNpi = String(object.providerNpi);
                    if (object.patientDob != null) {
                        if (typeof object.patientDob !== "object")
                            throw TypeError(".infinitusai.be.CreateFastTrackTaskAndCallRequest.patientDob: object expected");
                        message.patientDob = $root.infinitusai.be.Date.fromObject(object.patientDob);
                    }
                    if (object.relatedLink != null)
                        message.relatedLink = String(object.relatedLink);
                    if (object.providerTaxId != null)
                        message.providerTaxId = String(object.providerTaxId);
                    if (object.providerZipCode != null)
                        message.providerZipCode = String(object.providerZipCode);
                    if (object.patientZipCode != null)
                        message.patientZipCode = String(object.patientZipCode);
                    switch (object.facilityType) {
                    default:
                        if (typeof object.facilityType === "number") {
                            message.facilityType = object.facilityType;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.facilityType = 0;
                        break;
                    case "HOSPITAL_OUTPATIENT":
                    case 1:
                        message.facilityType = 1;
                        break;
                    case "PHARMACY":
                    case 2:
                        message.facilityType = 2;
                        break;
                    case "SPECIALIST_OFFICE":
                    case 3:
                        message.facilityType = 3;
                        break;
                    case "SHIP_TO_HOME":
                    case 4:
                        message.facilityType = 4;
                        break;
                    case "INFUSION_CENTER":
                    case 5:
                        message.facilityType = 5;
                        break;
                    case "AMBULATORY_SURGICAL_CENTER":
                    case 6:
                        message.facilityType = 6;
                        break;
                    case "HOME_INFUSION":
                    case 7:
                        message.facilityType = 7;
                        break;
                    case "HOSPITAL_OUTPATIENT_OFF_CAMPUS":
                    case 8:
                        message.facilityType = 8;
                        break;
                    case "HOSPITAL_INPATIENT":
                    case 9:
                        message.facilityType = 9;
                        break;
                    case "SNF":
                    case 10:
                        message.facilityType = 10;
                        break;
                    case "HOSPICE":
                    case 11:
                        message.facilityType = 11;
                        break;
                    case "IHC":
                    case 12:
                        message.facilityType = 12;
                        break;
                    case "FED_QUALIFIED_HC":
                    case 13:
                        message.facilityType = 13;
                        break;
                    case "INPATIENT_REHAB":
                    case 14:
                        message.facilityType = 14;
                        break;
                    case "OUTPATIENT_REHAB":
                    case 15:
                        message.facilityType = 15;
                        break;
                    case "PUBLIC_HEALTH_CLINIC":
                    case 16:
                        message.facilityType = 16;
                        break;
                    case "RURAL_HEALTH_CLINIC":
                    case 17:
                        message.facilityType = 17;
                        break;
                    case "DIAGNOSTIC_TEST_LAB":
                    case 18:
                        message.facilityType = 18;
                        break;
                    }
                    if (object.groupNumber != null)
                        message.groupNumber = String(object.groupNumber);
                    if (object.patientName != null)
                        message.patientName = String(object.patientName);
                    if (object.providerName != null)
                        message.providerName = String(object.providerName);
                    switch (object.networkStatus) {
                    default:
                        if (typeof object.networkStatus === "number") {
                            message.networkStatus = object.networkStatus;
                            break;
                        }
                        break;
                    case "NETWORK_STATUS_UNKNOWN":
                    case 0:
                        message.networkStatus = 0;
                        break;
                    case "NETWORK_STATUS_IN_NETWORK":
                    case 1:
                        message.networkStatus = 1;
                        break;
                    case "NETWORK_STATUS_OUT_OF_NETWORK":
                    case 2:
                        message.networkStatus = 2;
                        break;
                    }
                    if (object.dateOfService != null) {
                        if (typeof object.dateOfService !== "object")
                            throw TypeError(".infinitusai.be.CreateFastTrackTaskAndCallRequest.dateOfService: object expected");
                        message.dateOfService = $root.infinitusai.be.Date.fromObject(object.dateOfService);
                    }
                    if (object.shouldPlayRecordedGreeting != null)
                        message.shouldPlayRecordedGreeting = Boolean(object.shouldPlayRecordedGreeting);
                    switch (object.taskSource) {
                    default:
                        if (typeof object.taskSource === "number") {
                            message.taskSource = object.taskSource;
                            break;
                        }
                        break;
                    case "TASK_SOURCE_UNKNOWN":
                    case 0:
                        message.taskSource = 0;
                        break;
                    case "TASK_SOURCE_ETL_SCRIPT":
                    case 1:
                        message.taskSource = 1;
                        break;
                    case "TASK_SOURCE_FE":
                    case 2:
                        message.taskSource = 2;
                        break;
                    case "TASK_SOURCE_CUSTOMER_PORTAL":
                    case 3:
                        message.taskSource = 3;
                        break;
                    case "TASK_SOURCE_CUSTOMER_API":
                    case 4:
                        message.taskSource = 4;
                        break;
                    case "TASK_SOURCE_SETUP":
                    case 5:
                        message.taskSource = 5;
                        break;
                    case "TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD":
                    case 6:
                        message.taskSource = 6;
                        break;
                    case "TASK_SOURCE_FASTTRACK":
                    case 7:
                        message.taskSource = 7;
                        break;
                    case "TASK_SOURCE_FASTTRACK_SF":
                    case 8:
                        message.taskSource = 8;
                        break;
                    case "TASK_SOURCE_FASTTRACK_CSV_UPLOAD":
                    case 9:
                        message.taskSource = 9;
                        break;
                    }
                    if (object.callbackNumber != null)
                        message.callbackNumber = String(object.callbackNumber);
                    if (object.payorName != null)
                        message.payorName = String(object.payorName);
                    if (object.claimsInputs != null) {
                        if (typeof object.claimsInputs !== "object")
                            throw TypeError(".infinitusai.be.CreateFastTrackTaskAndCallRequest.claimsInputs: object expected");
                        message.claimsInputs = $root.infinitusai.be.FastTrackClaimsInputs.fromObject(object.claimsInputs);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateFastTrackTaskAndCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {infinitusai.be.CreateFastTrackTaskAndCallRequest} message CreateFastTrackTaskAndCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateFastTrackTaskAndCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.payorId = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.memberId = "";
                        object.phoneNumber = "";
                        object.providerNpi = "";
                        object.patientDob = null;
                        object.relatedLink = "";
                        object.providerTaxId = "";
                        object.providerZipCode = "";
                        object.patientZipCode = "";
                        object.facilityType = options.enums === String ? "UNKNOWN" : 0;
                        object.groupNumber = "";
                        object.patientName = "";
                        object.providerName = "";
                        object.networkStatus = options.enums === String ? "NETWORK_STATUS_UNKNOWN" : 0;
                        object.dateOfService = null;
                        object.shouldPlayRecordedGreeting = false;
                        object.taskSource = options.enums === String ? "TASK_SOURCE_UNKNOWN" : 0;
                        object.callbackNumber = "";
                        object.payorName = "";
                        object.claimsInputs = null;
                    }
                    if (message.payorId != null && message.hasOwnProperty("payorId"))
                        object.payorId = message.payorId;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.memberId != null && message.hasOwnProperty("memberId"))
                        object.memberId = message.memberId;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.providerNpi != null && message.hasOwnProperty("providerNpi"))
                        object.providerNpi = message.providerNpi;
                    if (message.patientDob != null && message.hasOwnProperty("patientDob"))
                        object.patientDob = $root.infinitusai.be.Date.toObject(message.patientDob, options);
                    if (message.relatedLink != null && message.hasOwnProperty("relatedLink"))
                        object.relatedLink = message.relatedLink;
                    if (message.providerTaxId != null && message.hasOwnProperty("providerTaxId"))
                        object.providerTaxId = message.providerTaxId;
                    if (message.providerZipCode != null && message.hasOwnProperty("providerZipCode"))
                        object.providerZipCode = message.providerZipCode;
                    if (message.patientZipCode != null && message.hasOwnProperty("patientZipCode"))
                        object.patientZipCode = message.patientZipCode;
                    if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                        object.facilityType = options.enums === String ? $root.infinitusai.be.FacilityType.Type[message.facilityType] === undefined ? message.facilityType : $root.infinitusai.be.FacilityType.Type[message.facilityType] : message.facilityType;
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                        object.groupNumber = message.groupNumber;
                    if (message.patientName != null && message.hasOwnProperty("patientName"))
                        object.patientName = message.patientName;
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        object.providerName = message.providerName;
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                        object.networkStatus = options.enums === String ? $root.infinitusai.be.ProviderInfo.NetworkStatus[message.networkStatus] === undefined ? message.networkStatus : $root.infinitusai.be.ProviderInfo.NetworkStatus[message.networkStatus] : message.networkStatus;
                    if (message.dateOfService != null && message.hasOwnProperty("dateOfService"))
                        object.dateOfService = $root.infinitusai.be.Date.toObject(message.dateOfService, options);
                    if (message.shouldPlayRecordedGreeting != null && message.hasOwnProperty("shouldPlayRecordedGreeting"))
                        object.shouldPlayRecordedGreeting = message.shouldPlayRecordedGreeting;
                    if (message.taskSource != null && message.hasOwnProperty("taskSource"))
                        object.taskSource = options.enums === String ? $root.infinitusai.be.TaskSource[message.taskSource] === undefined ? message.taskSource : $root.infinitusai.be.TaskSource[message.taskSource] : message.taskSource;
                    if (message.callbackNumber != null && message.hasOwnProperty("callbackNumber"))
                        object.callbackNumber = message.callbackNumber;
                    if (message.payorName != null && message.hasOwnProperty("payorName"))
                        object.payorName = message.payorName;
                    if (message.claimsInputs != null && message.hasOwnProperty("claimsInputs"))
                        object.claimsInputs = $root.infinitusai.be.FastTrackClaimsInputs.toObject(message.claimsInputs, options);
                    return object;
                };
    
                /**
                 * Converts this CreateFastTrackTaskAndCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateFastTrackTaskAndCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateFastTrackTaskAndCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateFastTrackTaskAndCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateFastTrackTaskAndCallRequest";
                };
    
                return CreateFastTrackTaskAndCallRequest;
            })();
    
            be.FastTrackClaimsInputs = (function() {
    
                /**
                 * Properties of a FastTrackClaimsInputs.
                 * @memberof infinitusai.be
                 * @interface IFastTrackClaimsInputs
                 * @property {string|null} [claimNumber] FastTrackClaimsInputs claimNumber
                 * @property {string|null} [claimType] FastTrackClaimsInputs claimType
                 * @property {number|null} [claimBilledAmount] FastTrackClaimsInputs claimBilledAmount
                 * @property {string|null} [claimBillType] FastTrackClaimsInputs claimBillType
                 * @property {string|null} [previousClaimStatus] FastTrackClaimsInputs previousClaimStatus
                 * @property {infinitusai.be.IDate|null} [claimSubmissionDate] FastTrackClaimsInputs claimSubmissionDate
                 * @property {string|null} [claimSubmissionMethod] FastTrackClaimsInputs claimSubmissionMethod
                 * @property {infinitusai.be.IDate|null} [claimDenialDate] FastTrackClaimsInputs claimDenialDate
                 * @property {string|null} [claimDenialReason] FastTrackClaimsInputs claimDenialReason
                 * @property {boolean|null} [eobReceivedForClaim] FastTrackClaimsInputs eobReceivedForClaim
                 * @property {infinitusai.be.IDate|null} [dischargeDate] FastTrackClaimsInputs dischargeDate
                 * @property {infinitusai.be.IDate|null} [admitDate] FastTrackClaimsInputs admitDate
                 * @property {infinitusai.be.IDate|null} [appealSubmissionDate] FastTrackClaimsInputs appealSubmissionDate
                 * @property {string|null} [appealsSubmittedSoFar] FastTrackClaimsInputs appealsSubmittedSoFar
                 * @property {string|null} [memberFirstName] FastTrackClaimsInputs memberFirstName
                 * @property {string|null} [memberLastName] FastTrackClaimsInputs memberLastName
                 * @property {string|null} [providerStreetAddress] FastTrackClaimsInputs providerStreetAddress
                 * @property {string|null} [providerCity] FastTrackClaimsInputs providerCity
                 * @property {string|null} [providerState] FastTrackClaimsInputs providerState
                 * @property {string|null} [facilityName] FastTrackClaimsInputs facilityName
                 * @property {string|null} [facilityNpi] FastTrackClaimsInputs facilityNpi
                 * @property {string|null} [facilityTaxId] FastTrackClaimsInputs facilityTaxId
                 * @property {string|null} [facilityStreetAddress] FastTrackClaimsInputs facilityStreetAddress
                 * @property {string|null} [facilityCity] FastTrackClaimsInputs facilityCity
                 * @property {string|null} [facilityState] FastTrackClaimsInputs facilityState
                 * @property {string|null} [facilityZipCode] FastTrackClaimsInputs facilityZipCode
                 * @property {string|null} [claimNotificationEmail] FastTrackClaimsInputs claimNotificationEmail
                 * @property {string|null} [claimNotificationFaxNumber] FastTrackClaimsInputs claimNotificationFaxNumber
                 * @property {string|null} [claimNotificationStreetAddress] FastTrackClaimsInputs claimNotificationStreetAddress
                 * @property {string|null} [claimNotificationCity] FastTrackClaimsInputs claimNotificationCity
                 * @property {string|null} [claimNotificationState] FastTrackClaimsInputs claimNotificationState
                 * @property {string|null} [claimNotificationZipCode] FastTrackClaimsInputs claimNotificationZipCode
                 * @property {string|null} [claimPaymentStreetAddress] FastTrackClaimsInputs claimPaymentStreetAddress
                 * @property {string|null} [claimPaymentCity] FastTrackClaimsInputs claimPaymentCity
                 * @property {string|null} [claimPaymentState] FastTrackClaimsInputs claimPaymentState
                 * @property {string|null} [claimPaymentZipCode] FastTrackClaimsInputs claimPaymentZipCode
                 * @property {string|null} [eftAccountNumber] FastTrackClaimsInputs eftAccountNumber
                 * @property {string|null} [eftAccountType] FastTrackClaimsInputs eftAccountType
                 * @property {string|null} [eftBankName] FastTrackClaimsInputs eftBankName
                 * @property {string|null} [eftRoutingNumber] FastTrackClaimsInputs eftRoutingNumber
                 * @property {string|null} [preferredMethodOfPayment] FastTrackClaimsInputs preferredMethodOfPayment
                 * @property {string|null} [adminCode_1] FastTrackClaimsInputs adminCode_1
                 * @property {string|null} [diagnosisCode_1] FastTrackClaimsInputs diagnosisCode_1
                 * @property {string|null} [drgCode_1] FastTrackClaimsInputs drgCode_1
                 * @property {string|null} [productCode_1] FastTrackClaimsInputs productCode_1
                 * @property {string|null} [memberPhoneNumber] FastTrackClaimsInputs memberPhoneNumber
                 */
    
                /**
                 * Constructs a new FastTrackClaimsInputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FastTrackClaimsInputs.
                 * @implements IFastTrackClaimsInputs
                 * @constructor
                 * @param {infinitusai.be.IFastTrackClaimsInputs=} [properties] Properties to set
                 */
                function FastTrackClaimsInputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FastTrackClaimsInputs claimNumber.
                 * @member {string} claimNumber
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimNumber = "";
    
                /**
                 * FastTrackClaimsInputs claimType.
                 * @member {string} claimType
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimType = "";
    
                /**
                 * FastTrackClaimsInputs claimBilledAmount.
                 * @member {number} claimBilledAmount
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimBilledAmount = 0;
    
                /**
                 * FastTrackClaimsInputs claimBillType.
                 * @member {string} claimBillType
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimBillType = "";
    
                /**
                 * FastTrackClaimsInputs previousClaimStatus.
                 * @member {string} previousClaimStatus
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.previousClaimStatus = "";
    
                /**
                 * FastTrackClaimsInputs claimSubmissionDate.
                 * @member {infinitusai.be.IDate|null|undefined} claimSubmissionDate
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimSubmissionDate = null;
    
                /**
                 * FastTrackClaimsInputs claimSubmissionMethod.
                 * @member {string} claimSubmissionMethod
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimSubmissionMethod = "";
    
                /**
                 * FastTrackClaimsInputs claimDenialDate.
                 * @member {infinitusai.be.IDate|null|undefined} claimDenialDate
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimDenialDate = null;
    
                /**
                 * FastTrackClaimsInputs claimDenialReason.
                 * @member {string} claimDenialReason
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimDenialReason = "";
    
                /**
                 * FastTrackClaimsInputs eobReceivedForClaim.
                 * @member {boolean} eobReceivedForClaim
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.eobReceivedForClaim = false;
    
                /**
                 * FastTrackClaimsInputs dischargeDate.
                 * @member {infinitusai.be.IDate|null|undefined} dischargeDate
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.dischargeDate = null;
    
                /**
                 * FastTrackClaimsInputs admitDate.
                 * @member {infinitusai.be.IDate|null|undefined} admitDate
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.admitDate = null;
    
                /**
                 * FastTrackClaimsInputs appealSubmissionDate.
                 * @member {infinitusai.be.IDate|null|undefined} appealSubmissionDate
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.appealSubmissionDate = null;
    
                /**
                 * FastTrackClaimsInputs appealsSubmittedSoFar.
                 * @member {string} appealsSubmittedSoFar
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.appealsSubmittedSoFar = "";
    
                /**
                 * FastTrackClaimsInputs memberFirstName.
                 * @member {string} memberFirstName
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.memberFirstName = "";
    
                /**
                 * FastTrackClaimsInputs memberLastName.
                 * @member {string} memberLastName
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.memberLastName = "";
    
                /**
                 * FastTrackClaimsInputs providerStreetAddress.
                 * @member {string} providerStreetAddress
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.providerStreetAddress = "";
    
                /**
                 * FastTrackClaimsInputs providerCity.
                 * @member {string} providerCity
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.providerCity = "";
    
                /**
                 * FastTrackClaimsInputs providerState.
                 * @member {string} providerState
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.providerState = "";
    
                /**
                 * FastTrackClaimsInputs facilityName.
                 * @member {string} facilityName
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.facilityName = "";
    
                /**
                 * FastTrackClaimsInputs facilityNpi.
                 * @member {string} facilityNpi
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.facilityNpi = "";
    
                /**
                 * FastTrackClaimsInputs facilityTaxId.
                 * @member {string} facilityTaxId
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.facilityTaxId = "";
    
                /**
                 * FastTrackClaimsInputs facilityStreetAddress.
                 * @member {string} facilityStreetAddress
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.facilityStreetAddress = "";
    
                /**
                 * FastTrackClaimsInputs facilityCity.
                 * @member {string} facilityCity
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.facilityCity = "";
    
                /**
                 * FastTrackClaimsInputs facilityState.
                 * @member {string} facilityState
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.facilityState = "";
    
                /**
                 * FastTrackClaimsInputs facilityZipCode.
                 * @member {string} facilityZipCode
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.facilityZipCode = "";
    
                /**
                 * FastTrackClaimsInputs claimNotificationEmail.
                 * @member {string} claimNotificationEmail
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimNotificationEmail = "";
    
                /**
                 * FastTrackClaimsInputs claimNotificationFaxNumber.
                 * @member {string} claimNotificationFaxNumber
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimNotificationFaxNumber = "";
    
                /**
                 * FastTrackClaimsInputs claimNotificationStreetAddress.
                 * @member {string} claimNotificationStreetAddress
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimNotificationStreetAddress = "";
    
                /**
                 * FastTrackClaimsInputs claimNotificationCity.
                 * @member {string} claimNotificationCity
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimNotificationCity = "";
    
                /**
                 * FastTrackClaimsInputs claimNotificationState.
                 * @member {string} claimNotificationState
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimNotificationState = "";
    
                /**
                 * FastTrackClaimsInputs claimNotificationZipCode.
                 * @member {string} claimNotificationZipCode
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimNotificationZipCode = "";
    
                /**
                 * FastTrackClaimsInputs claimPaymentStreetAddress.
                 * @member {string} claimPaymentStreetAddress
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimPaymentStreetAddress = "";
    
                /**
                 * FastTrackClaimsInputs claimPaymentCity.
                 * @member {string} claimPaymentCity
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimPaymentCity = "";
    
                /**
                 * FastTrackClaimsInputs claimPaymentState.
                 * @member {string} claimPaymentState
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimPaymentState = "";
    
                /**
                 * FastTrackClaimsInputs claimPaymentZipCode.
                 * @member {string} claimPaymentZipCode
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.claimPaymentZipCode = "";
    
                /**
                 * FastTrackClaimsInputs eftAccountNumber.
                 * @member {string} eftAccountNumber
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.eftAccountNumber = "";
    
                /**
                 * FastTrackClaimsInputs eftAccountType.
                 * @member {string} eftAccountType
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.eftAccountType = "";
    
                /**
                 * FastTrackClaimsInputs eftBankName.
                 * @member {string} eftBankName
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.eftBankName = "";
    
                /**
                 * FastTrackClaimsInputs eftRoutingNumber.
                 * @member {string} eftRoutingNumber
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.eftRoutingNumber = "";
    
                /**
                 * FastTrackClaimsInputs preferredMethodOfPayment.
                 * @member {string} preferredMethodOfPayment
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.preferredMethodOfPayment = "";
    
                /**
                 * FastTrackClaimsInputs adminCode_1.
                 * @member {string} adminCode_1
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.adminCode_1 = "";
    
                /**
                 * FastTrackClaimsInputs diagnosisCode_1.
                 * @member {string} diagnosisCode_1
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.diagnosisCode_1 = "";
    
                /**
                 * FastTrackClaimsInputs drgCode_1.
                 * @member {string} drgCode_1
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.drgCode_1 = "";
    
                /**
                 * FastTrackClaimsInputs productCode_1.
                 * @member {string} productCode_1
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.productCode_1 = "";
    
                /**
                 * FastTrackClaimsInputs memberPhoneNumber.
                 * @member {string} memberPhoneNumber
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 */
                FastTrackClaimsInputs.prototype.memberPhoneNumber = "";
    
                /**
                 * Creates a new FastTrackClaimsInputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {infinitusai.be.IFastTrackClaimsInputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FastTrackClaimsInputs} FastTrackClaimsInputs instance
                 */
                FastTrackClaimsInputs.create = function create(properties) {
                    return new FastTrackClaimsInputs(properties);
                };
    
                /**
                 * Encodes the specified FastTrackClaimsInputs message. Does not implicitly {@link infinitusai.be.FastTrackClaimsInputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {infinitusai.be.IFastTrackClaimsInputs} message FastTrackClaimsInputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackClaimsInputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.claimNumber != null && Object.hasOwnProperty.call(message, "claimNumber"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.claimNumber);
                    if (message.claimType != null && Object.hasOwnProperty.call(message, "claimType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.claimType);
                    if (message.claimBilledAmount != null && Object.hasOwnProperty.call(message, "claimBilledAmount"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.claimBilledAmount);
                    if (message.claimBillType != null && Object.hasOwnProperty.call(message, "claimBillType"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.claimBillType);
                    if (message.previousClaimStatus != null && Object.hasOwnProperty.call(message, "previousClaimStatus"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.previousClaimStatus);
                    if (message.claimSubmissionDate != null && Object.hasOwnProperty.call(message, "claimSubmissionDate"))
                        $root.infinitusai.be.Date.encode(message.claimSubmissionDate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.claimSubmissionMethod != null && Object.hasOwnProperty.call(message, "claimSubmissionMethod"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.claimSubmissionMethod);
                    if (message.claimDenialDate != null && Object.hasOwnProperty.call(message, "claimDenialDate"))
                        $root.infinitusai.be.Date.encode(message.claimDenialDate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.claimDenialReason != null && Object.hasOwnProperty.call(message, "claimDenialReason"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.claimDenialReason);
                    if (message.eobReceivedForClaim != null && Object.hasOwnProperty.call(message, "eobReceivedForClaim"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.eobReceivedForClaim);
                    if (message.dischargeDate != null && Object.hasOwnProperty.call(message, "dischargeDate"))
                        $root.infinitusai.be.Date.encode(message.dischargeDate, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.admitDate != null && Object.hasOwnProperty.call(message, "admitDate"))
                        $root.infinitusai.be.Date.encode(message.admitDate, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.appealSubmissionDate != null && Object.hasOwnProperty.call(message, "appealSubmissionDate"))
                        $root.infinitusai.be.Date.encode(message.appealSubmissionDate, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.appealsSubmittedSoFar != null && Object.hasOwnProperty.call(message, "appealsSubmittedSoFar"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.appealsSubmittedSoFar);
                    if (message.memberFirstName != null && Object.hasOwnProperty.call(message, "memberFirstName"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.memberFirstName);
                    if (message.memberLastName != null && Object.hasOwnProperty.call(message, "memberLastName"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.memberLastName);
                    if (message.providerStreetAddress != null && Object.hasOwnProperty.call(message, "providerStreetAddress"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.providerStreetAddress);
                    if (message.providerCity != null && Object.hasOwnProperty.call(message, "providerCity"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.providerCity);
                    if (message.providerState != null && Object.hasOwnProperty.call(message, "providerState"))
                        writer.uint32(/* id 19, wireType 2 =*/154).string(message.providerState);
                    if (message.facilityName != null && Object.hasOwnProperty.call(message, "facilityName"))
                        writer.uint32(/* id 20, wireType 2 =*/162).string(message.facilityName);
                    if (message.facilityNpi != null && Object.hasOwnProperty.call(message, "facilityNpi"))
                        writer.uint32(/* id 21, wireType 2 =*/170).string(message.facilityNpi);
                    if (message.facilityTaxId != null && Object.hasOwnProperty.call(message, "facilityTaxId"))
                        writer.uint32(/* id 22, wireType 2 =*/178).string(message.facilityTaxId);
                    if (message.facilityStreetAddress != null && Object.hasOwnProperty.call(message, "facilityStreetAddress"))
                        writer.uint32(/* id 23, wireType 2 =*/186).string(message.facilityStreetAddress);
                    if (message.facilityCity != null && Object.hasOwnProperty.call(message, "facilityCity"))
                        writer.uint32(/* id 24, wireType 2 =*/194).string(message.facilityCity);
                    if (message.facilityState != null && Object.hasOwnProperty.call(message, "facilityState"))
                        writer.uint32(/* id 25, wireType 2 =*/202).string(message.facilityState);
                    if (message.facilityZipCode != null && Object.hasOwnProperty.call(message, "facilityZipCode"))
                        writer.uint32(/* id 26, wireType 2 =*/210).string(message.facilityZipCode);
                    if (message.claimNotificationEmail != null && Object.hasOwnProperty.call(message, "claimNotificationEmail"))
                        writer.uint32(/* id 27, wireType 2 =*/218).string(message.claimNotificationEmail);
                    if (message.claimNotificationFaxNumber != null && Object.hasOwnProperty.call(message, "claimNotificationFaxNumber"))
                        writer.uint32(/* id 28, wireType 2 =*/226).string(message.claimNotificationFaxNumber);
                    if (message.claimNotificationStreetAddress != null && Object.hasOwnProperty.call(message, "claimNotificationStreetAddress"))
                        writer.uint32(/* id 29, wireType 2 =*/234).string(message.claimNotificationStreetAddress);
                    if (message.claimNotificationCity != null && Object.hasOwnProperty.call(message, "claimNotificationCity"))
                        writer.uint32(/* id 30, wireType 2 =*/242).string(message.claimNotificationCity);
                    if (message.claimNotificationState != null && Object.hasOwnProperty.call(message, "claimNotificationState"))
                        writer.uint32(/* id 31, wireType 2 =*/250).string(message.claimNotificationState);
                    if (message.claimNotificationZipCode != null && Object.hasOwnProperty.call(message, "claimNotificationZipCode"))
                        writer.uint32(/* id 32, wireType 2 =*/258).string(message.claimNotificationZipCode);
                    if (message.claimPaymentStreetAddress != null && Object.hasOwnProperty.call(message, "claimPaymentStreetAddress"))
                        writer.uint32(/* id 33, wireType 2 =*/266).string(message.claimPaymentStreetAddress);
                    if (message.claimPaymentCity != null && Object.hasOwnProperty.call(message, "claimPaymentCity"))
                        writer.uint32(/* id 34, wireType 2 =*/274).string(message.claimPaymentCity);
                    if (message.claimPaymentState != null && Object.hasOwnProperty.call(message, "claimPaymentState"))
                        writer.uint32(/* id 35, wireType 2 =*/282).string(message.claimPaymentState);
                    if (message.claimPaymentZipCode != null && Object.hasOwnProperty.call(message, "claimPaymentZipCode"))
                        writer.uint32(/* id 36, wireType 2 =*/290).string(message.claimPaymentZipCode);
                    if (message.eftAccountNumber != null && Object.hasOwnProperty.call(message, "eftAccountNumber"))
                        writer.uint32(/* id 37, wireType 2 =*/298).string(message.eftAccountNumber);
                    if (message.eftAccountType != null && Object.hasOwnProperty.call(message, "eftAccountType"))
                        writer.uint32(/* id 38, wireType 2 =*/306).string(message.eftAccountType);
                    if (message.eftBankName != null && Object.hasOwnProperty.call(message, "eftBankName"))
                        writer.uint32(/* id 39, wireType 2 =*/314).string(message.eftBankName);
                    if (message.eftRoutingNumber != null && Object.hasOwnProperty.call(message, "eftRoutingNumber"))
                        writer.uint32(/* id 40, wireType 2 =*/322).string(message.eftRoutingNumber);
                    if (message.preferredMethodOfPayment != null && Object.hasOwnProperty.call(message, "preferredMethodOfPayment"))
                        writer.uint32(/* id 41, wireType 2 =*/330).string(message.preferredMethodOfPayment);
                    if (message.adminCode_1 != null && Object.hasOwnProperty.call(message, "adminCode_1"))
                        writer.uint32(/* id 42, wireType 2 =*/338).string(message.adminCode_1);
                    if (message.diagnosisCode_1 != null && Object.hasOwnProperty.call(message, "diagnosisCode_1"))
                        writer.uint32(/* id 43, wireType 2 =*/346).string(message.diagnosisCode_1);
                    if (message.drgCode_1 != null && Object.hasOwnProperty.call(message, "drgCode_1"))
                        writer.uint32(/* id 44, wireType 2 =*/354).string(message.drgCode_1);
                    if (message.productCode_1 != null && Object.hasOwnProperty.call(message, "productCode_1"))
                        writer.uint32(/* id 45, wireType 2 =*/362).string(message.productCode_1);
                    if (message.memberPhoneNumber != null && Object.hasOwnProperty.call(message, "memberPhoneNumber"))
                        writer.uint32(/* id 46, wireType 2 =*/370).string(message.memberPhoneNumber);
                    return writer;
                };
    
                /**
                 * Encodes the specified FastTrackClaimsInputs message, length delimited. Does not implicitly {@link infinitusai.be.FastTrackClaimsInputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {infinitusai.be.IFastTrackClaimsInputs} message FastTrackClaimsInputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackClaimsInputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FastTrackClaimsInputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FastTrackClaimsInputs} FastTrackClaimsInputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackClaimsInputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FastTrackClaimsInputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.claimNumber = reader.string();
                                break;
                            }
                        case 2: {
                                message.claimType = reader.string();
                                break;
                            }
                        case 3: {
                                message.claimBilledAmount = reader.double();
                                break;
                            }
                        case 4: {
                                message.claimBillType = reader.string();
                                break;
                            }
                        case 5: {
                                message.previousClaimStatus = reader.string();
                                break;
                            }
                        case 6: {
                                message.claimSubmissionDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.claimSubmissionMethod = reader.string();
                                break;
                            }
                        case 8: {
                                message.claimDenialDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.claimDenialReason = reader.string();
                                break;
                            }
                        case 10: {
                                message.eobReceivedForClaim = reader.bool();
                                break;
                            }
                        case 11: {
                                message.dischargeDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.admitDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.appealSubmissionDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.appealsSubmittedSoFar = reader.string();
                                break;
                            }
                        case 15: {
                                message.memberFirstName = reader.string();
                                break;
                            }
                        case 16: {
                                message.memberLastName = reader.string();
                                break;
                            }
                        case 17: {
                                message.providerStreetAddress = reader.string();
                                break;
                            }
                        case 18: {
                                message.providerCity = reader.string();
                                break;
                            }
                        case 19: {
                                message.providerState = reader.string();
                                break;
                            }
                        case 20: {
                                message.facilityName = reader.string();
                                break;
                            }
                        case 21: {
                                message.facilityNpi = reader.string();
                                break;
                            }
                        case 22: {
                                message.facilityTaxId = reader.string();
                                break;
                            }
                        case 23: {
                                message.facilityStreetAddress = reader.string();
                                break;
                            }
                        case 24: {
                                message.facilityCity = reader.string();
                                break;
                            }
                        case 25: {
                                message.facilityState = reader.string();
                                break;
                            }
                        case 26: {
                                message.facilityZipCode = reader.string();
                                break;
                            }
                        case 27: {
                                message.claimNotificationEmail = reader.string();
                                break;
                            }
                        case 28: {
                                message.claimNotificationFaxNumber = reader.string();
                                break;
                            }
                        case 29: {
                                message.claimNotificationStreetAddress = reader.string();
                                break;
                            }
                        case 30: {
                                message.claimNotificationCity = reader.string();
                                break;
                            }
                        case 31: {
                                message.claimNotificationState = reader.string();
                                break;
                            }
                        case 32: {
                                message.claimNotificationZipCode = reader.string();
                                break;
                            }
                        case 33: {
                                message.claimPaymentStreetAddress = reader.string();
                                break;
                            }
                        case 34: {
                                message.claimPaymentCity = reader.string();
                                break;
                            }
                        case 35: {
                                message.claimPaymentState = reader.string();
                                break;
                            }
                        case 36: {
                                message.claimPaymentZipCode = reader.string();
                                break;
                            }
                        case 37: {
                                message.eftAccountNumber = reader.string();
                                break;
                            }
                        case 38: {
                                message.eftAccountType = reader.string();
                                break;
                            }
                        case 39: {
                                message.eftBankName = reader.string();
                                break;
                            }
                        case 40: {
                                message.eftRoutingNumber = reader.string();
                                break;
                            }
                        case 41: {
                                message.preferredMethodOfPayment = reader.string();
                                break;
                            }
                        case 42: {
                                message.adminCode_1 = reader.string();
                                break;
                            }
                        case 43: {
                                message.diagnosisCode_1 = reader.string();
                                break;
                            }
                        case 44: {
                                message.drgCode_1 = reader.string();
                                break;
                            }
                        case 45: {
                                message.productCode_1 = reader.string();
                                break;
                            }
                        case 46: {
                                message.memberPhoneNumber = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FastTrackClaimsInputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FastTrackClaimsInputs} FastTrackClaimsInputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackClaimsInputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FastTrackClaimsInputs message.
                 * @function verify
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastTrackClaimsInputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.claimNumber != null && message.hasOwnProperty("claimNumber"))
                        if (!$util.isString(message.claimNumber))
                            return "claimNumber: string expected";
                    if (message.claimType != null && message.hasOwnProperty("claimType"))
                        if (!$util.isString(message.claimType))
                            return "claimType: string expected";
                    if (message.claimBilledAmount != null && message.hasOwnProperty("claimBilledAmount"))
                        if (typeof message.claimBilledAmount !== "number")
                            return "claimBilledAmount: number expected";
                    if (message.claimBillType != null && message.hasOwnProperty("claimBillType"))
                        if (!$util.isString(message.claimBillType))
                            return "claimBillType: string expected";
                    if (message.previousClaimStatus != null && message.hasOwnProperty("previousClaimStatus"))
                        if (!$util.isString(message.previousClaimStatus))
                            return "previousClaimStatus: string expected";
                    if (message.claimSubmissionDate != null && message.hasOwnProperty("claimSubmissionDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.claimSubmissionDate);
                        if (error)
                            return "claimSubmissionDate." + error;
                    }
                    if (message.claimSubmissionMethod != null && message.hasOwnProperty("claimSubmissionMethod"))
                        if (!$util.isString(message.claimSubmissionMethod))
                            return "claimSubmissionMethod: string expected";
                    if (message.claimDenialDate != null && message.hasOwnProperty("claimDenialDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.claimDenialDate);
                        if (error)
                            return "claimDenialDate." + error;
                    }
                    if (message.claimDenialReason != null && message.hasOwnProperty("claimDenialReason"))
                        if (!$util.isString(message.claimDenialReason))
                            return "claimDenialReason: string expected";
                    if (message.eobReceivedForClaim != null && message.hasOwnProperty("eobReceivedForClaim"))
                        if (typeof message.eobReceivedForClaim !== "boolean")
                            return "eobReceivedForClaim: boolean expected";
                    if (message.dischargeDate != null && message.hasOwnProperty("dischargeDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.dischargeDate);
                        if (error)
                            return "dischargeDate." + error;
                    }
                    if (message.admitDate != null && message.hasOwnProperty("admitDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.admitDate);
                        if (error)
                            return "admitDate." + error;
                    }
                    if (message.appealSubmissionDate != null && message.hasOwnProperty("appealSubmissionDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.appealSubmissionDate);
                        if (error)
                            return "appealSubmissionDate." + error;
                    }
                    if (message.appealsSubmittedSoFar != null && message.hasOwnProperty("appealsSubmittedSoFar"))
                        if (!$util.isString(message.appealsSubmittedSoFar))
                            return "appealsSubmittedSoFar: string expected";
                    if (message.memberFirstName != null && message.hasOwnProperty("memberFirstName"))
                        if (!$util.isString(message.memberFirstName))
                            return "memberFirstName: string expected";
                    if (message.memberLastName != null && message.hasOwnProperty("memberLastName"))
                        if (!$util.isString(message.memberLastName))
                            return "memberLastName: string expected";
                    if (message.providerStreetAddress != null && message.hasOwnProperty("providerStreetAddress"))
                        if (!$util.isString(message.providerStreetAddress))
                            return "providerStreetAddress: string expected";
                    if (message.providerCity != null && message.hasOwnProperty("providerCity"))
                        if (!$util.isString(message.providerCity))
                            return "providerCity: string expected";
                    if (message.providerState != null && message.hasOwnProperty("providerState"))
                        if (!$util.isString(message.providerState))
                            return "providerState: string expected";
                    if (message.facilityName != null && message.hasOwnProperty("facilityName"))
                        if (!$util.isString(message.facilityName))
                            return "facilityName: string expected";
                    if (message.facilityNpi != null && message.hasOwnProperty("facilityNpi"))
                        if (!$util.isString(message.facilityNpi))
                            return "facilityNpi: string expected";
                    if (message.facilityTaxId != null && message.hasOwnProperty("facilityTaxId"))
                        if (!$util.isString(message.facilityTaxId))
                            return "facilityTaxId: string expected";
                    if (message.facilityStreetAddress != null && message.hasOwnProperty("facilityStreetAddress"))
                        if (!$util.isString(message.facilityStreetAddress))
                            return "facilityStreetAddress: string expected";
                    if (message.facilityCity != null && message.hasOwnProperty("facilityCity"))
                        if (!$util.isString(message.facilityCity))
                            return "facilityCity: string expected";
                    if (message.facilityState != null && message.hasOwnProperty("facilityState"))
                        if (!$util.isString(message.facilityState))
                            return "facilityState: string expected";
                    if (message.facilityZipCode != null && message.hasOwnProperty("facilityZipCode"))
                        if (!$util.isString(message.facilityZipCode))
                            return "facilityZipCode: string expected";
                    if (message.claimNotificationEmail != null && message.hasOwnProperty("claimNotificationEmail"))
                        if (!$util.isString(message.claimNotificationEmail))
                            return "claimNotificationEmail: string expected";
                    if (message.claimNotificationFaxNumber != null && message.hasOwnProperty("claimNotificationFaxNumber"))
                        if (!$util.isString(message.claimNotificationFaxNumber))
                            return "claimNotificationFaxNumber: string expected";
                    if (message.claimNotificationStreetAddress != null && message.hasOwnProperty("claimNotificationStreetAddress"))
                        if (!$util.isString(message.claimNotificationStreetAddress))
                            return "claimNotificationStreetAddress: string expected";
                    if (message.claimNotificationCity != null && message.hasOwnProperty("claimNotificationCity"))
                        if (!$util.isString(message.claimNotificationCity))
                            return "claimNotificationCity: string expected";
                    if (message.claimNotificationState != null && message.hasOwnProperty("claimNotificationState"))
                        if (!$util.isString(message.claimNotificationState))
                            return "claimNotificationState: string expected";
                    if (message.claimNotificationZipCode != null && message.hasOwnProperty("claimNotificationZipCode"))
                        if (!$util.isString(message.claimNotificationZipCode))
                            return "claimNotificationZipCode: string expected";
                    if (message.claimPaymentStreetAddress != null && message.hasOwnProperty("claimPaymentStreetAddress"))
                        if (!$util.isString(message.claimPaymentStreetAddress))
                            return "claimPaymentStreetAddress: string expected";
                    if (message.claimPaymentCity != null && message.hasOwnProperty("claimPaymentCity"))
                        if (!$util.isString(message.claimPaymentCity))
                            return "claimPaymentCity: string expected";
                    if (message.claimPaymentState != null && message.hasOwnProperty("claimPaymentState"))
                        if (!$util.isString(message.claimPaymentState))
                            return "claimPaymentState: string expected";
                    if (message.claimPaymentZipCode != null && message.hasOwnProperty("claimPaymentZipCode"))
                        if (!$util.isString(message.claimPaymentZipCode))
                            return "claimPaymentZipCode: string expected";
                    if (message.eftAccountNumber != null && message.hasOwnProperty("eftAccountNumber"))
                        if (!$util.isString(message.eftAccountNumber))
                            return "eftAccountNumber: string expected";
                    if (message.eftAccountType != null && message.hasOwnProperty("eftAccountType"))
                        if (!$util.isString(message.eftAccountType))
                            return "eftAccountType: string expected";
                    if (message.eftBankName != null && message.hasOwnProperty("eftBankName"))
                        if (!$util.isString(message.eftBankName))
                            return "eftBankName: string expected";
                    if (message.eftRoutingNumber != null && message.hasOwnProperty("eftRoutingNumber"))
                        if (!$util.isString(message.eftRoutingNumber))
                            return "eftRoutingNumber: string expected";
                    if (message.preferredMethodOfPayment != null && message.hasOwnProperty("preferredMethodOfPayment"))
                        if (!$util.isString(message.preferredMethodOfPayment))
                            return "preferredMethodOfPayment: string expected";
                    if (message.adminCode_1 != null && message.hasOwnProperty("adminCode_1"))
                        if (!$util.isString(message.adminCode_1))
                            return "adminCode_1: string expected";
                    if (message.diagnosisCode_1 != null && message.hasOwnProperty("diagnosisCode_1"))
                        if (!$util.isString(message.diagnosisCode_1))
                            return "diagnosisCode_1: string expected";
                    if (message.drgCode_1 != null && message.hasOwnProperty("drgCode_1"))
                        if (!$util.isString(message.drgCode_1))
                            return "drgCode_1: string expected";
                    if (message.productCode_1 != null && message.hasOwnProperty("productCode_1"))
                        if (!$util.isString(message.productCode_1))
                            return "productCode_1: string expected";
                    if (message.memberPhoneNumber != null && message.hasOwnProperty("memberPhoneNumber"))
                        if (!$util.isString(message.memberPhoneNumber))
                            return "memberPhoneNumber: string expected";
                    return null;
                };
    
                /**
                 * Creates a FastTrackClaimsInputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FastTrackClaimsInputs} FastTrackClaimsInputs
                 */
                FastTrackClaimsInputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FastTrackClaimsInputs)
                        return object;
                    var message = new $root.infinitusai.be.FastTrackClaimsInputs();
                    if (object.claimNumber != null)
                        message.claimNumber = String(object.claimNumber);
                    if (object.claimType != null)
                        message.claimType = String(object.claimType);
                    if (object.claimBilledAmount != null)
                        message.claimBilledAmount = Number(object.claimBilledAmount);
                    if (object.claimBillType != null)
                        message.claimBillType = String(object.claimBillType);
                    if (object.previousClaimStatus != null)
                        message.previousClaimStatus = String(object.previousClaimStatus);
                    if (object.claimSubmissionDate != null) {
                        if (typeof object.claimSubmissionDate !== "object")
                            throw TypeError(".infinitusai.be.FastTrackClaimsInputs.claimSubmissionDate: object expected");
                        message.claimSubmissionDate = $root.infinitusai.be.Date.fromObject(object.claimSubmissionDate);
                    }
                    if (object.claimSubmissionMethod != null)
                        message.claimSubmissionMethod = String(object.claimSubmissionMethod);
                    if (object.claimDenialDate != null) {
                        if (typeof object.claimDenialDate !== "object")
                            throw TypeError(".infinitusai.be.FastTrackClaimsInputs.claimDenialDate: object expected");
                        message.claimDenialDate = $root.infinitusai.be.Date.fromObject(object.claimDenialDate);
                    }
                    if (object.claimDenialReason != null)
                        message.claimDenialReason = String(object.claimDenialReason);
                    if (object.eobReceivedForClaim != null)
                        message.eobReceivedForClaim = Boolean(object.eobReceivedForClaim);
                    if (object.dischargeDate != null) {
                        if (typeof object.dischargeDate !== "object")
                            throw TypeError(".infinitusai.be.FastTrackClaimsInputs.dischargeDate: object expected");
                        message.dischargeDate = $root.infinitusai.be.Date.fromObject(object.dischargeDate);
                    }
                    if (object.admitDate != null) {
                        if (typeof object.admitDate !== "object")
                            throw TypeError(".infinitusai.be.FastTrackClaimsInputs.admitDate: object expected");
                        message.admitDate = $root.infinitusai.be.Date.fromObject(object.admitDate);
                    }
                    if (object.appealSubmissionDate != null) {
                        if (typeof object.appealSubmissionDate !== "object")
                            throw TypeError(".infinitusai.be.FastTrackClaimsInputs.appealSubmissionDate: object expected");
                        message.appealSubmissionDate = $root.infinitusai.be.Date.fromObject(object.appealSubmissionDate);
                    }
                    if (object.appealsSubmittedSoFar != null)
                        message.appealsSubmittedSoFar = String(object.appealsSubmittedSoFar);
                    if (object.memberFirstName != null)
                        message.memberFirstName = String(object.memberFirstName);
                    if (object.memberLastName != null)
                        message.memberLastName = String(object.memberLastName);
                    if (object.providerStreetAddress != null)
                        message.providerStreetAddress = String(object.providerStreetAddress);
                    if (object.providerCity != null)
                        message.providerCity = String(object.providerCity);
                    if (object.providerState != null)
                        message.providerState = String(object.providerState);
                    if (object.facilityName != null)
                        message.facilityName = String(object.facilityName);
                    if (object.facilityNpi != null)
                        message.facilityNpi = String(object.facilityNpi);
                    if (object.facilityTaxId != null)
                        message.facilityTaxId = String(object.facilityTaxId);
                    if (object.facilityStreetAddress != null)
                        message.facilityStreetAddress = String(object.facilityStreetAddress);
                    if (object.facilityCity != null)
                        message.facilityCity = String(object.facilityCity);
                    if (object.facilityState != null)
                        message.facilityState = String(object.facilityState);
                    if (object.facilityZipCode != null)
                        message.facilityZipCode = String(object.facilityZipCode);
                    if (object.claimNotificationEmail != null)
                        message.claimNotificationEmail = String(object.claimNotificationEmail);
                    if (object.claimNotificationFaxNumber != null)
                        message.claimNotificationFaxNumber = String(object.claimNotificationFaxNumber);
                    if (object.claimNotificationStreetAddress != null)
                        message.claimNotificationStreetAddress = String(object.claimNotificationStreetAddress);
                    if (object.claimNotificationCity != null)
                        message.claimNotificationCity = String(object.claimNotificationCity);
                    if (object.claimNotificationState != null)
                        message.claimNotificationState = String(object.claimNotificationState);
                    if (object.claimNotificationZipCode != null)
                        message.claimNotificationZipCode = String(object.claimNotificationZipCode);
                    if (object.claimPaymentStreetAddress != null)
                        message.claimPaymentStreetAddress = String(object.claimPaymentStreetAddress);
                    if (object.claimPaymentCity != null)
                        message.claimPaymentCity = String(object.claimPaymentCity);
                    if (object.claimPaymentState != null)
                        message.claimPaymentState = String(object.claimPaymentState);
                    if (object.claimPaymentZipCode != null)
                        message.claimPaymentZipCode = String(object.claimPaymentZipCode);
                    if (object.eftAccountNumber != null)
                        message.eftAccountNumber = String(object.eftAccountNumber);
                    if (object.eftAccountType != null)
                        message.eftAccountType = String(object.eftAccountType);
                    if (object.eftBankName != null)
                        message.eftBankName = String(object.eftBankName);
                    if (object.eftRoutingNumber != null)
                        message.eftRoutingNumber = String(object.eftRoutingNumber);
                    if (object.preferredMethodOfPayment != null)
                        message.preferredMethodOfPayment = String(object.preferredMethodOfPayment);
                    if (object.adminCode_1 != null)
                        message.adminCode_1 = String(object.adminCode_1);
                    if (object.diagnosisCode_1 != null)
                        message.diagnosisCode_1 = String(object.diagnosisCode_1);
                    if (object.drgCode_1 != null)
                        message.drgCode_1 = String(object.drgCode_1);
                    if (object.productCode_1 != null)
                        message.productCode_1 = String(object.productCode_1);
                    if (object.memberPhoneNumber != null)
                        message.memberPhoneNumber = String(object.memberPhoneNumber);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FastTrackClaimsInputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {infinitusai.be.FastTrackClaimsInputs} message FastTrackClaimsInputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastTrackClaimsInputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.claimNumber = "";
                        object.claimType = "";
                        object.claimBilledAmount = 0;
                        object.claimBillType = "";
                        object.previousClaimStatus = "";
                        object.claimSubmissionDate = null;
                        object.claimSubmissionMethod = "";
                        object.claimDenialDate = null;
                        object.claimDenialReason = "";
                        object.eobReceivedForClaim = false;
                        object.dischargeDate = null;
                        object.admitDate = null;
                        object.appealSubmissionDate = null;
                        object.appealsSubmittedSoFar = "";
                        object.memberFirstName = "";
                        object.memberLastName = "";
                        object.providerStreetAddress = "";
                        object.providerCity = "";
                        object.providerState = "";
                        object.facilityName = "";
                        object.facilityNpi = "";
                        object.facilityTaxId = "";
                        object.facilityStreetAddress = "";
                        object.facilityCity = "";
                        object.facilityState = "";
                        object.facilityZipCode = "";
                        object.claimNotificationEmail = "";
                        object.claimNotificationFaxNumber = "";
                        object.claimNotificationStreetAddress = "";
                        object.claimNotificationCity = "";
                        object.claimNotificationState = "";
                        object.claimNotificationZipCode = "";
                        object.claimPaymentStreetAddress = "";
                        object.claimPaymentCity = "";
                        object.claimPaymentState = "";
                        object.claimPaymentZipCode = "";
                        object.eftAccountNumber = "";
                        object.eftAccountType = "";
                        object.eftBankName = "";
                        object.eftRoutingNumber = "";
                        object.preferredMethodOfPayment = "";
                        object.adminCode_1 = "";
                        object.diagnosisCode_1 = "";
                        object.drgCode_1 = "";
                        object.productCode_1 = "";
                        object.memberPhoneNumber = "";
                    }
                    if (message.claimNumber != null && message.hasOwnProperty("claimNumber"))
                        object.claimNumber = message.claimNumber;
                    if (message.claimType != null && message.hasOwnProperty("claimType"))
                        object.claimType = message.claimType;
                    if (message.claimBilledAmount != null && message.hasOwnProperty("claimBilledAmount"))
                        object.claimBilledAmount = options.json && !isFinite(message.claimBilledAmount) ? String(message.claimBilledAmount) : message.claimBilledAmount;
                    if (message.claimBillType != null && message.hasOwnProperty("claimBillType"))
                        object.claimBillType = message.claimBillType;
                    if (message.previousClaimStatus != null && message.hasOwnProperty("previousClaimStatus"))
                        object.previousClaimStatus = message.previousClaimStatus;
                    if (message.claimSubmissionDate != null && message.hasOwnProperty("claimSubmissionDate"))
                        object.claimSubmissionDate = $root.infinitusai.be.Date.toObject(message.claimSubmissionDate, options);
                    if (message.claimSubmissionMethod != null && message.hasOwnProperty("claimSubmissionMethod"))
                        object.claimSubmissionMethod = message.claimSubmissionMethod;
                    if (message.claimDenialDate != null && message.hasOwnProperty("claimDenialDate"))
                        object.claimDenialDate = $root.infinitusai.be.Date.toObject(message.claimDenialDate, options);
                    if (message.claimDenialReason != null && message.hasOwnProperty("claimDenialReason"))
                        object.claimDenialReason = message.claimDenialReason;
                    if (message.eobReceivedForClaim != null && message.hasOwnProperty("eobReceivedForClaim"))
                        object.eobReceivedForClaim = message.eobReceivedForClaim;
                    if (message.dischargeDate != null && message.hasOwnProperty("dischargeDate"))
                        object.dischargeDate = $root.infinitusai.be.Date.toObject(message.dischargeDate, options);
                    if (message.admitDate != null && message.hasOwnProperty("admitDate"))
                        object.admitDate = $root.infinitusai.be.Date.toObject(message.admitDate, options);
                    if (message.appealSubmissionDate != null && message.hasOwnProperty("appealSubmissionDate"))
                        object.appealSubmissionDate = $root.infinitusai.be.Date.toObject(message.appealSubmissionDate, options);
                    if (message.appealsSubmittedSoFar != null && message.hasOwnProperty("appealsSubmittedSoFar"))
                        object.appealsSubmittedSoFar = message.appealsSubmittedSoFar;
                    if (message.memberFirstName != null && message.hasOwnProperty("memberFirstName"))
                        object.memberFirstName = message.memberFirstName;
                    if (message.memberLastName != null && message.hasOwnProperty("memberLastName"))
                        object.memberLastName = message.memberLastName;
                    if (message.providerStreetAddress != null && message.hasOwnProperty("providerStreetAddress"))
                        object.providerStreetAddress = message.providerStreetAddress;
                    if (message.providerCity != null && message.hasOwnProperty("providerCity"))
                        object.providerCity = message.providerCity;
                    if (message.providerState != null && message.hasOwnProperty("providerState"))
                        object.providerState = message.providerState;
                    if (message.facilityName != null && message.hasOwnProperty("facilityName"))
                        object.facilityName = message.facilityName;
                    if (message.facilityNpi != null && message.hasOwnProperty("facilityNpi"))
                        object.facilityNpi = message.facilityNpi;
                    if (message.facilityTaxId != null && message.hasOwnProperty("facilityTaxId"))
                        object.facilityTaxId = message.facilityTaxId;
                    if (message.facilityStreetAddress != null && message.hasOwnProperty("facilityStreetAddress"))
                        object.facilityStreetAddress = message.facilityStreetAddress;
                    if (message.facilityCity != null && message.hasOwnProperty("facilityCity"))
                        object.facilityCity = message.facilityCity;
                    if (message.facilityState != null && message.hasOwnProperty("facilityState"))
                        object.facilityState = message.facilityState;
                    if (message.facilityZipCode != null && message.hasOwnProperty("facilityZipCode"))
                        object.facilityZipCode = message.facilityZipCode;
                    if (message.claimNotificationEmail != null && message.hasOwnProperty("claimNotificationEmail"))
                        object.claimNotificationEmail = message.claimNotificationEmail;
                    if (message.claimNotificationFaxNumber != null && message.hasOwnProperty("claimNotificationFaxNumber"))
                        object.claimNotificationFaxNumber = message.claimNotificationFaxNumber;
                    if (message.claimNotificationStreetAddress != null && message.hasOwnProperty("claimNotificationStreetAddress"))
                        object.claimNotificationStreetAddress = message.claimNotificationStreetAddress;
                    if (message.claimNotificationCity != null && message.hasOwnProperty("claimNotificationCity"))
                        object.claimNotificationCity = message.claimNotificationCity;
                    if (message.claimNotificationState != null && message.hasOwnProperty("claimNotificationState"))
                        object.claimNotificationState = message.claimNotificationState;
                    if (message.claimNotificationZipCode != null && message.hasOwnProperty("claimNotificationZipCode"))
                        object.claimNotificationZipCode = message.claimNotificationZipCode;
                    if (message.claimPaymentStreetAddress != null && message.hasOwnProperty("claimPaymentStreetAddress"))
                        object.claimPaymentStreetAddress = message.claimPaymentStreetAddress;
                    if (message.claimPaymentCity != null && message.hasOwnProperty("claimPaymentCity"))
                        object.claimPaymentCity = message.claimPaymentCity;
                    if (message.claimPaymentState != null && message.hasOwnProperty("claimPaymentState"))
                        object.claimPaymentState = message.claimPaymentState;
                    if (message.claimPaymentZipCode != null && message.hasOwnProperty("claimPaymentZipCode"))
                        object.claimPaymentZipCode = message.claimPaymentZipCode;
                    if (message.eftAccountNumber != null && message.hasOwnProperty("eftAccountNumber"))
                        object.eftAccountNumber = message.eftAccountNumber;
                    if (message.eftAccountType != null && message.hasOwnProperty("eftAccountType"))
                        object.eftAccountType = message.eftAccountType;
                    if (message.eftBankName != null && message.hasOwnProperty("eftBankName"))
                        object.eftBankName = message.eftBankName;
                    if (message.eftRoutingNumber != null && message.hasOwnProperty("eftRoutingNumber"))
                        object.eftRoutingNumber = message.eftRoutingNumber;
                    if (message.preferredMethodOfPayment != null && message.hasOwnProperty("preferredMethodOfPayment"))
                        object.preferredMethodOfPayment = message.preferredMethodOfPayment;
                    if (message.adminCode_1 != null && message.hasOwnProperty("adminCode_1"))
                        object.adminCode_1 = message.adminCode_1;
                    if (message.diagnosisCode_1 != null && message.hasOwnProperty("diagnosisCode_1"))
                        object.diagnosisCode_1 = message.diagnosisCode_1;
                    if (message.drgCode_1 != null && message.hasOwnProperty("drgCode_1"))
                        object.drgCode_1 = message.drgCode_1;
                    if (message.productCode_1 != null && message.hasOwnProperty("productCode_1"))
                        object.productCode_1 = message.productCode_1;
                    if (message.memberPhoneNumber != null && message.hasOwnProperty("memberPhoneNumber"))
                        object.memberPhoneNumber = message.memberPhoneNumber;
                    return object;
                };
    
                /**
                 * Converts this FastTrackClaimsInputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastTrackClaimsInputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FastTrackClaimsInputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FastTrackClaimsInputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FastTrackClaimsInputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FastTrackClaimsInputs";
                };
    
                return FastTrackClaimsInputs;
            })();
    
            be.UpdateFastTrackTaskRequest = (function() {
    
                /**
                 * Properties of an UpdateFastTrackTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateFastTrackTaskRequest
                 * @property {string|null} [taskUuid] UpdateFastTrackTaskRequest taskUuid
                 * @property {string|null} [phoneNumber] UpdateFastTrackTaskRequest phoneNumber
                 * @property {string|null} [memberId] UpdateFastTrackTaskRequest memberId
                 * @property {string|null} [providerNpi] UpdateFastTrackTaskRequest providerNpi
                 * @property {infinitusai.be.IDate|null} [patientDob] UpdateFastTrackTaskRequest patientDob
                 * @property {string|null} [customerAssignedId] UpdateFastTrackTaskRequest customerAssignedId
                 * @property {string|null} [providerTaxId] UpdateFastTrackTaskRequest providerTaxId
                 * @property {string|null} [providerZipCode] UpdateFastTrackTaskRequest providerZipCode
                 * @property {string|null} [patientZipCode] UpdateFastTrackTaskRequest patientZipCode
                 * @property {string|null} [groupNumber] UpdateFastTrackTaskRequest groupNumber
                 * @property {infinitusai.be.ProviderInfo.NetworkStatus|null} [networkStatus] UpdateFastTrackTaskRequest networkStatus
                 * @property {infinitusai.be.IDate|null} [dateOfService] UpdateFastTrackTaskRequest dateOfService
                 * @property {string|null} [callbackNumber] UpdateFastTrackTaskRequest callbackNumber
                 * @property {string|null} [claimNumber] UpdateFastTrackTaskRequest claimNumber
                 * @property {number|null} [claimAmount] UpdateFastTrackTaskRequest claimAmount
                 * @property {string|null} [taskNote] UpdateFastTrackTaskRequest taskNote
                 * @property {string|null} [providerName] UpdateFastTrackTaskRequest providerName
                 * @property {string|null} [patientName] UpdateFastTrackTaskRequest patientName
                 * @property {infinitusai.be.FacilityType.Type|null} [facilityType] UpdateFastTrackTaskRequest facilityType
                 * @property {string|null} [relatedLink] UpdateFastTrackTaskRequest relatedLink
                 */
    
                /**
                 * Constructs a new UpdateFastTrackTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateFastTrackTaskRequest.
                 * @implements IUpdateFastTrackTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateFastTrackTaskRequest=} [properties] Properties to set
                 */
                function UpdateFastTrackTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateFastTrackTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.taskUuid = "";
    
                /**
                 * UpdateFastTrackTaskRequest phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.phoneNumber = "";
    
                /**
                 * UpdateFastTrackTaskRequest memberId.
                 * @member {string} memberId
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.memberId = "";
    
                /**
                 * UpdateFastTrackTaskRequest providerNpi.
                 * @member {string} providerNpi
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.providerNpi = "";
    
                /**
                 * UpdateFastTrackTaskRequest patientDob.
                 * @member {infinitusai.be.IDate|null|undefined} patientDob
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.patientDob = null;
    
                /**
                 * UpdateFastTrackTaskRequest customerAssignedId.
                 * @member {string} customerAssignedId
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.customerAssignedId = "";
    
                /**
                 * UpdateFastTrackTaskRequest providerTaxId.
                 * @member {string} providerTaxId
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.providerTaxId = "";
    
                /**
                 * UpdateFastTrackTaskRequest providerZipCode.
                 * @member {string} providerZipCode
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.providerZipCode = "";
    
                /**
                 * UpdateFastTrackTaskRequest patientZipCode.
                 * @member {string} patientZipCode
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.patientZipCode = "";
    
                /**
                 * UpdateFastTrackTaskRequest groupNumber.
                 * @member {string} groupNumber
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.groupNumber = "";
    
                /**
                 * UpdateFastTrackTaskRequest networkStatus.
                 * @member {infinitusai.be.ProviderInfo.NetworkStatus|null|undefined} networkStatus
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.networkStatus = null;
    
                /**
                 * UpdateFastTrackTaskRequest dateOfService.
                 * @member {infinitusai.be.IDate|null|undefined} dateOfService
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.dateOfService = null;
    
                /**
                 * UpdateFastTrackTaskRequest callbackNumber.
                 * @member {string} callbackNumber
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.callbackNumber = "";
    
                /**
                 * UpdateFastTrackTaskRequest claimNumber.
                 * @member {string} claimNumber
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.claimNumber = "";
    
                /**
                 * UpdateFastTrackTaskRequest claimAmount.
                 * @member {number} claimAmount
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.claimAmount = 0;
    
                /**
                 * UpdateFastTrackTaskRequest taskNote.
                 * @member {string} taskNote
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.taskNote = "";
    
                /**
                 * UpdateFastTrackTaskRequest providerName.
                 * @member {string} providerName
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.providerName = "";
    
                /**
                 * UpdateFastTrackTaskRequest patientName.
                 * @member {string} patientName
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.patientName = "";
    
                /**
                 * UpdateFastTrackTaskRequest facilityType.
                 * @member {infinitusai.be.FacilityType.Type|null|undefined} facilityType
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.facilityType = null;
    
                /**
                 * UpdateFastTrackTaskRequest relatedLink.
                 * @member {string} relatedLink
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                UpdateFastTrackTaskRequest.prototype.relatedLink = "";
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * UpdateFastTrackTaskRequest _networkStatus.
                 * @member {"networkStatus"|undefined} _networkStatus
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                Object.defineProperty(UpdateFastTrackTaskRequest.prototype, "_networkStatus", {
                    get: $util.oneOfGetter($oneOfFields = ["networkStatus"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * UpdateFastTrackTaskRequest _facilityType.
                 * @member {"facilityType"|undefined} _facilityType
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 */
                Object.defineProperty(UpdateFastTrackTaskRequest.prototype, "_facilityType", {
                    get: $util.oneOfGetter($oneOfFields = ["facilityType"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new UpdateFastTrackTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {infinitusai.be.IUpdateFastTrackTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateFastTrackTaskRequest} UpdateFastTrackTaskRequest instance
                 */
                UpdateFastTrackTaskRequest.create = function create(properties) {
                    return new UpdateFastTrackTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateFastTrackTaskRequest message. Does not implicitly {@link infinitusai.be.UpdateFastTrackTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {infinitusai.be.IUpdateFastTrackTaskRequest} message UpdateFastTrackTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateFastTrackTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.memberId);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.phoneNumber);
                    if (message.providerNpi != null && Object.hasOwnProperty.call(message, "providerNpi"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.providerNpi);
                    if (message.patientDob != null && Object.hasOwnProperty.call(message, "patientDob"))
                        $root.infinitusai.be.Date.encode(message.patientDob, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.customerAssignedId != null && Object.hasOwnProperty.call(message, "customerAssignedId"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.customerAssignedId);
                    if (message.providerTaxId != null && Object.hasOwnProperty.call(message, "providerTaxId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.providerTaxId);
                    if (message.providerZipCode != null && Object.hasOwnProperty.call(message, "providerZipCode"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.providerZipCode);
                    if (message.patientZipCode != null && Object.hasOwnProperty.call(message, "patientZipCode"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.patientZipCode);
                    if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.groupNumber);
                    if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.networkStatus);
                    if (message.dateOfService != null && Object.hasOwnProperty.call(message, "dateOfService"))
                        $root.infinitusai.be.Date.encode(message.dateOfService, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.callbackNumber != null && Object.hasOwnProperty.call(message, "callbackNumber"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.callbackNumber);
                    if (message.claimNumber != null && Object.hasOwnProperty.call(message, "claimNumber"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.claimNumber);
                    if (message.claimAmount != null && Object.hasOwnProperty.call(message, "claimAmount"))
                        writer.uint32(/* id 15, wireType 1 =*/121).double(message.claimAmount);
                    if (message.taskNote != null && Object.hasOwnProperty.call(message, "taskNote"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.taskNote);
                    if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.providerName);
                    if (message.patientName != null && Object.hasOwnProperty.call(message, "patientName"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.patientName);
                    if (message.facilityType != null && Object.hasOwnProperty.call(message, "facilityType"))
                        writer.uint32(/* id 19, wireType 0 =*/152).int32(message.facilityType);
                    if (message.relatedLink != null && Object.hasOwnProperty.call(message, "relatedLink"))
                        writer.uint32(/* id 20, wireType 2 =*/162).string(message.relatedLink);
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateFastTrackTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateFastTrackTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {infinitusai.be.IUpdateFastTrackTaskRequest} message UpdateFastTrackTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateFastTrackTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateFastTrackTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateFastTrackTaskRequest} UpdateFastTrackTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateFastTrackTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateFastTrackTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 2: {
                                message.memberId = reader.string();
                                break;
                            }
                        case 4: {
                                message.providerNpi = reader.string();
                                break;
                            }
                        case 5: {
                                message.patientDob = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.customerAssignedId = reader.string();
                                break;
                            }
                        case 7: {
                                message.providerTaxId = reader.string();
                                break;
                            }
                        case 8: {
                                message.providerZipCode = reader.string();
                                break;
                            }
                        case 9: {
                                message.patientZipCode = reader.string();
                                break;
                            }
                        case 10: {
                                message.groupNumber = reader.string();
                                break;
                            }
                        case 11: {
                                message.networkStatus = reader.int32();
                                break;
                            }
                        case 12: {
                                message.dateOfService = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.callbackNumber = reader.string();
                                break;
                            }
                        case 14: {
                                message.claimNumber = reader.string();
                                break;
                            }
                        case 15: {
                                message.claimAmount = reader.double();
                                break;
                            }
                        case 16: {
                                message.taskNote = reader.string();
                                break;
                            }
                        case 17: {
                                message.providerName = reader.string();
                                break;
                            }
                        case 18: {
                                message.patientName = reader.string();
                                break;
                            }
                        case 19: {
                                message.facilityType = reader.int32();
                                break;
                            }
                        case 20: {
                                message.relatedLink = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateFastTrackTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateFastTrackTaskRequest} UpdateFastTrackTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateFastTrackTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateFastTrackTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateFastTrackTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.memberId != null && message.hasOwnProperty("memberId"))
                        if (!$util.isString(message.memberId))
                            return "memberId: string expected";
                    if (message.providerNpi != null && message.hasOwnProperty("providerNpi"))
                        if (!$util.isString(message.providerNpi))
                            return "providerNpi: string expected";
                    if (message.patientDob != null && message.hasOwnProperty("patientDob")) {
                        var error = $root.infinitusai.be.Date.verify(message.patientDob);
                        if (error)
                            return "patientDob." + error;
                    }
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        if (!$util.isString(message.customerAssignedId))
                            return "customerAssignedId: string expected";
                    if (message.providerTaxId != null && message.hasOwnProperty("providerTaxId"))
                        if (!$util.isString(message.providerTaxId))
                            return "providerTaxId: string expected";
                    if (message.providerZipCode != null && message.hasOwnProperty("providerZipCode"))
                        if (!$util.isString(message.providerZipCode))
                            return "providerZipCode: string expected";
                    if (message.patientZipCode != null && message.hasOwnProperty("patientZipCode"))
                        if (!$util.isString(message.patientZipCode))
                            return "patientZipCode: string expected";
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                        if (!$util.isString(message.groupNumber))
                            return "groupNumber: string expected";
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus")) {
                        properties._networkStatus = 1;
                        switch (message.networkStatus) {
                        default:
                            return "networkStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    }
                    if (message.dateOfService != null && message.hasOwnProperty("dateOfService")) {
                        var error = $root.infinitusai.be.Date.verify(message.dateOfService);
                        if (error)
                            return "dateOfService." + error;
                    }
                    if (message.callbackNumber != null && message.hasOwnProperty("callbackNumber"))
                        if (!$util.isString(message.callbackNumber))
                            return "callbackNumber: string expected";
                    if (message.claimNumber != null && message.hasOwnProperty("claimNumber"))
                        if (!$util.isString(message.claimNumber))
                            return "claimNumber: string expected";
                    if (message.claimAmount != null && message.hasOwnProperty("claimAmount"))
                        if (typeof message.claimAmount !== "number")
                            return "claimAmount: number expected";
                    if (message.taskNote != null && message.hasOwnProperty("taskNote"))
                        if (!$util.isString(message.taskNote))
                            return "taskNote: string expected";
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        if (!$util.isString(message.providerName))
                            return "providerName: string expected";
                    if (message.patientName != null && message.hasOwnProperty("patientName"))
                        if (!$util.isString(message.patientName))
                            return "patientName: string expected";
                    if (message.facilityType != null && message.hasOwnProperty("facilityType")) {
                        properties._facilityType = 1;
                        switch (message.facilityType) {
                        default:
                            return "facilityType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    }
                    if (message.relatedLink != null && message.hasOwnProperty("relatedLink"))
                        if (!$util.isString(message.relatedLink))
                            return "relatedLink: string expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateFastTrackTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateFastTrackTaskRequest} UpdateFastTrackTaskRequest
                 */
                UpdateFastTrackTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateFastTrackTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateFastTrackTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.memberId != null)
                        message.memberId = String(object.memberId);
                    if (object.providerNpi != null)
                        message.providerNpi = String(object.providerNpi);
                    if (object.patientDob != null) {
                        if (typeof object.patientDob !== "object")
                            throw TypeError(".infinitusai.be.UpdateFastTrackTaskRequest.patientDob: object expected");
                        message.patientDob = $root.infinitusai.be.Date.fromObject(object.patientDob);
                    }
                    if (object.customerAssignedId != null)
                        message.customerAssignedId = String(object.customerAssignedId);
                    if (object.providerTaxId != null)
                        message.providerTaxId = String(object.providerTaxId);
                    if (object.providerZipCode != null)
                        message.providerZipCode = String(object.providerZipCode);
                    if (object.patientZipCode != null)
                        message.patientZipCode = String(object.patientZipCode);
                    if (object.groupNumber != null)
                        message.groupNumber = String(object.groupNumber);
                    switch (object.networkStatus) {
                    default:
                        if (typeof object.networkStatus === "number") {
                            message.networkStatus = object.networkStatus;
                            break;
                        }
                        break;
                    case "NETWORK_STATUS_UNKNOWN":
                    case 0:
                        message.networkStatus = 0;
                        break;
                    case "NETWORK_STATUS_IN_NETWORK":
                    case 1:
                        message.networkStatus = 1;
                        break;
                    case "NETWORK_STATUS_OUT_OF_NETWORK":
                    case 2:
                        message.networkStatus = 2;
                        break;
                    }
                    if (object.dateOfService != null) {
                        if (typeof object.dateOfService !== "object")
                            throw TypeError(".infinitusai.be.UpdateFastTrackTaskRequest.dateOfService: object expected");
                        message.dateOfService = $root.infinitusai.be.Date.fromObject(object.dateOfService);
                    }
                    if (object.callbackNumber != null)
                        message.callbackNumber = String(object.callbackNumber);
                    if (object.claimNumber != null)
                        message.claimNumber = String(object.claimNumber);
                    if (object.claimAmount != null)
                        message.claimAmount = Number(object.claimAmount);
                    if (object.taskNote != null)
                        message.taskNote = String(object.taskNote);
                    if (object.providerName != null)
                        message.providerName = String(object.providerName);
                    if (object.patientName != null)
                        message.patientName = String(object.patientName);
                    switch (object.facilityType) {
                    default:
                        if (typeof object.facilityType === "number") {
                            message.facilityType = object.facilityType;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.facilityType = 0;
                        break;
                    case "HOSPITAL_OUTPATIENT":
                    case 1:
                        message.facilityType = 1;
                        break;
                    case "PHARMACY":
                    case 2:
                        message.facilityType = 2;
                        break;
                    case "SPECIALIST_OFFICE":
                    case 3:
                        message.facilityType = 3;
                        break;
                    case "SHIP_TO_HOME":
                    case 4:
                        message.facilityType = 4;
                        break;
                    case "INFUSION_CENTER":
                    case 5:
                        message.facilityType = 5;
                        break;
                    case "AMBULATORY_SURGICAL_CENTER":
                    case 6:
                        message.facilityType = 6;
                        break;
                    case "HOME_INFUSION":
                    case 7:
                        message.facilityType = 7;
                        break;
                    case "HOSPITAL_OUTPATIENT_OFF_CAMPUS":
                    case 8:
                        message.facilityType = 8;
                        break;
                    case "HOSPITAL_INPATIENT":
                    case 9:
                        message.facilityType = 9;
                        break;
                    case "SNF":
                    case 10:
                        message.facilityType = 10;
                        break;
                    case "HOSPICE":
                    case 11:
                        message.facilityType = 11;
                        break;
                    case "IHC":
                    case 12:
                        message.facilityType = 12;
                        break;
                    case "FED_QUALIFIED_HC":
                    case 13:
                        message.facilityType = 13;
                        break;
                    case "INPATIENT_REHAB":
                    case 14:
                        message.facilityType = 14;
                        break;
                    case "OUTPATIENT_REHAB":
                    case 15:
                        message.facilityType = 15;
                        break;
                    case "PUBLIC_HEALTH_CLINIC":
                    case 16:
                        message.facilityType = 16;
                        break;
                    case "RURAL_HEALTH_CLINIC":
                    case 17:
                        message.facilityType = 17;
                        break;
                    case "DIAGNOSTIC_TEST_LAB":
                    case 18:
                        message.facilityType = 18;
                        break;
                    }
                    if (object.relatedLink != null)
                        message.relatedLink = String(object.relatedLink);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateFastTrackTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {infinitusai.be.UpdateFastTrackTaskRequest} message UpdateFastTrackTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateFastTrackTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.memberId = "";
                        object.phoneNumber = "";
                        object.providerNpi = "";
                        object.patientDob = null;
                        object.customerAssignedId = "";
                        object.providerTaxId = "";
                        object.providerZipCode = "";
                        object.patientZipCode = "";
                        object.groupNumber = "";
                        object.dateOfService = null;
                        object.callbackNumber = "";
                        object.claimNumber = "";
                        object.claimAmount = 0;
                        object.taskNote = "";
                        object.providerName = "";
                        object.patientName = "";
                        object.relatedLink = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.memberId != null && message.hasOwnProperty("memberId"))
                        object.memberId = message.memberId;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.providerNpi != null && message.hasOwnProperty("providerNpi"))
                        object.providerNpi = message.providerNpi;
                    if (message.patientDob != null && message.hasOwnProperty("patientDob"))
                        object.patientDob = $root.infinitusai.be.Date.toObject(message.patientDob, options);
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        object.customerAssignedId = message.customerAssignedId;
                    if (message.providerTaxId != null && message.hasOwnProperty("providerTaxId"))
                        object.providerTaxId = message.providerTaxId;
                    if (message.providerZipCode != null && message.hasOwnProperty("providerZipCode"))
                        object.providerZipCode = message.providerZipCode;
                    if (message.patientZipCode != null && message.hasOwnProperty("patientZipCode"))
                        object.patientZipCode = message.patientZipCode;
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                        object.groupNumber = message.groupNumber;
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus")) {
                        object.networkStatus = options.enums === String ? $root.infinitusai.be.ProviderInfo.NetworkStatus[message.networkStatus] === undefined ? message.networkStatus : $root.infinitusai.be.ProviderInfo.NetworkStatus[message.networkStatus] : message.networkStatus;
                        if (options.oneofs)
                            object._networkStatus = "networkStatus";
                    }
                    if (message.dateOfService != null && message.hasOwnProperty("dateOfService"))
                        object.dateOfService = $root.infinitusai.be.Date.toObject(message.dateOfService, options);
                    if (message.callbackNumber != null && message.hasOwnProperty("callbackNumber"))
                        object.callbackNumber = message.callbackNumber;
                    if (message.claimNumber != null && message.hasOwnProperty("claimNumber"))
                        object.claimNumber = message.claimNumber;
                    if (message.claimAmount != null && message.hasOwnProperty("claimAmount"))
                        object.claimAmount = options.json && !isFinite(message.claimAmount) ? String(message.claimAmount) : message.claimAmount;
                    if (message.taskNote != null && message.hasOwnProperty("taskNote"))
                        object.taskNote = message.taskNote;
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        object.providerName = message.providerName;
                    if (message.patientName != null && message.hasOwnProperty("patientName"))
                        object.patientName = message.patientName;
                    if (message.facilityType != null && message.hasOwnProperty("facilityType")) {
                        object.facilityType = options.enums === String ? $root.infinitusai.be.FacilityType.Type[message.facilityType] === undefined ? message.facilityType : $root.infinitusai.be.FacilityType.Type[message.facilityType] : message.facilityType;
                        if (options.oneofs)
                            object._facilityType = "facilityType";
                    }
                    if (message.relatedLink != null && message.hasOwnProperty("relatedLink"))
                        object.relatedLink = message.relatedLink;
                    return object;
                };
    
                /**
                 * Converts this UpdateFastTrackTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateFastTrackTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateFastTrackTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateFastTrackTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateFastTrackTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateFastTrackTaskRequest";
                };
    
                return UpdateFastTrackTaskRequest;
            })();
    
            be.UpdateFastTrackTaskResponse = (function() {
    
                /**
                 * Properties of an UpdateFastTrackTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateFastTrackTaskResponse
                 * @property {string|null} [taskUuid] UpdateFastTrackTaskResponse taskUuid
                 */
    
                /**
                 * Constructs a new UpdateFastTrackTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateFastTrackTaskResponse.
                 * @implements IUpdateFastTrackTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateFastTrackTaskResponse=} [properties] Properties to set
                 */
                function UpdateFastTrackTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateFastTrackTaskResponse taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @instance
                 */
                UpdateFastTrackTaskResponse.prototype.taskUuid = "";
    
                /**
                 * Creates a new UpdateFastTrackTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {infinitusai.be.IUpdateFastTrackTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateFastTrackTaskResponse} UpdateFastTrackTaskResponse instance
                 */
                UpdateFastTrackTaskResponse.create = function create(properties) {
                    return new UpdateFastTrackTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateFastTrackTaskResponse message. Does not implicitly {@link infinitusai.be.UpdateFastTrackTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {infinitusai.be.IUpdateFastTrackTaskResponse} message UpdateFastTrackTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateFastTrackTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateFastTrackTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateFastTrackTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {infinitusai.be.IUpdateFastTrackTaskResponse} message UpdateFastTrackTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateFastTrackTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateFastTrackTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateFastTrackTaskResponse} UpdateFastTrackTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateFastTrackTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateFastTrackTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateFastTrackTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateFastTrackTaskResponse} UpdateFastTrackTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateFastTrackTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateFastTrackTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateFastTrackTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateFastTrackTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateFastTrackTaskResponse} UpdateFastTrackTaskResponse
                 */
                UpdateFastTrackTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateFastTrackTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.UpdateFastTrackTaskResponse();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateFastTrackTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {infinitusai.be.UpdateFastTrackTaskResponse} message UpdateFastTrackTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateFastTrackTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this UpdateFastTrackTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateFastTrackTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateFastTrackTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateFastTrackTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateFastTrackTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateFastTrackTaskResponse";
                };
    
                return UpdateFastTrackTaskResponse;
            })();
    
            be.CreateFastTrackTaskAndCallResponse = (function() {
    
                /**
                 * Properties of a CreateFastTrackTaskAndCallResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateFastTrackTaskAndCallResponse
                 * @property {string|null} [taskUuid] CreateFastTrackTaskAndCallResponse taskUuid
                 * @property {string|null} [callUuid] CreateFastTrackTaskAndCallResponse callUuid
                 */
    
                /**
                 * Constructs a new CreateFastTrackTaskAndCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateFastTrackTaskAndCallResponse.
                 * @implements ICreateFastTrackTaskAndCallResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateFastTrackTaskAndCallResponse=} [properties] Properties to set
                 */
                function CreateFastTrackTaskAndCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateFastTrackTaskAndCallResponse taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @instance
                 */
                CreateFastTrackTaskAndCallResponse.prototype.taskUuid = "";
    
                /**
                 * CreateFastTrackTaskAndCallResponse callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @instance
                 */
                CreateFastTrackTaskAndCallResponse.prototype.callUuid = "";
    
                /**
                 * Creates a new CreateFastTrackTaskAndCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackTaskAndCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateFastTrackTaskAndCallResponse} CreateFastTrackTaskAndCallResponse instance
                 */
                CreateFastTrackTaskAndCallResponse.create = function create(properties) {
                    return new CreateFastTrackTaskAndCallResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateFastTrackTaskAndCallResponse message. Does not implicitly {@link infinitusai.be.CreateFastTrackTaskAndCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackTaskAndCallResponse} message CreateFastTrackTaskAndCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFastTrackTaskAndCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateFastTrackTaskAndCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateFastTrackTaskAndCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackTaskAndCallResponse} message CreateFastTrackTaskAndCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFastTrackTaskAndCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateFastTrackTaskAndCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateFastTrackTaskAndCallResponse} CreateFastTrackTaskAndCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFastTrackTaskAndCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateFastTrackTaskAndCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateFastTrackTaskAndCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateFastTrackTaskAndCallResponse} CreateFastTrackTaskAndCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFastTrackTaskAndCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateFastTrackTaskAndCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateFastTrackTaskAndCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateFastTrackTaskAndCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateFastTrackTaskAndCallResponse} CreateFastTrackTaskAndCallResponse
                 */
                CreateFastTrackTaskAndCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateFastTrackTaskAndCallResponse)
                        return object;
                    var message = new $root.infinitusai.be.CreateFastTrackTaskAndCallResponse();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateFastTrackTaskAndCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {infinitusai.be.CreateFastTrackTaskAndCallResponse} message CreateFastTrackTaskAndCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateFastTrackTaskAndCallResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this CreateFastTrackTaskAndCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateFastTrackTaskAndCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateFastTrackTaskAndCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateFastTrackTaskAndCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateFastTrackTaskAndCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateFastTrackTaskAndCallResponse";
                };
    
                return CreateFastTrackTaskAndCallResponse;
            })();
    
            be.CreateFastTrackCallFromTaskRequest = (function() {
    
                /**
                 * Properties of a CreateFastTrackCallFromTaskRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateFastTrackCallFromTaskRequest
                 * @property {string|null} [taskUuid] CreateFastTrackCallFromTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new CreateFastTrackCallFromTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateFastTrackCallFromTaskRequest.
                 * @implements ICreateFastTrackCallFromTaskRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateFastTrackCallFromTaskRequest=} [properties] Properties to set
                 */
                function CreateFastTrackCallFromTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateFastTrackCallFromTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @instance
                 */
                CreateFastTrackCallFromTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new CreateFastTrackCallFromTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackCallFromTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateFastTrackCallFromTaskRequest} CreateFastTrackCallFromTaskRequest instance
                 */
                CreateFastTrackCallFromTaskRequest.create = function create(properties) {
                    return new CreateFastTrackCallFromTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateFastTrackCallFromTaskRequest message. Does not implicitly {@link infinitusai.be.CreateFastTrackCallFromTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackCallFromTaskRequest} message CreateFastTrackCallFromTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFastTrackCallFromTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateFastTrackCallFromTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateFastTrackCallFromTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackCallFromTaskRequest} message CreateFastTrackCallFromTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFastTrackCallFromTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateFastTrackCallFromTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateFastTrackCallFromTaskRequest} CreateFastTrackCallFromTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFastTrackCallFromTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateFastTrackCallFromTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateFastTrackCallFromTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateFastTrackCallFromTaskRequest} CreateFastTrackCallFromTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFastTrackCallFromTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateFastTrackCallFromTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateFastTrackCallFromTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateFastTrackCallFromTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateFastTrackCallFromTaskRequest} CreateFastTrackCallFromTaskRequest
                 */
                CreateFastTrackCallFromTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateFastTrackCallFromTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateFastTrackCallFromTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateFastTrackCallFromTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {infinitusai.be.CreateFastTrackCallFromTaskRequest} message CreateFastTrackCallFromTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateFastTrackCallFromTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this CreateFastTrackCallFromTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateFastTrackCallFromTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateFastTrackCallFromTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateFastTrackCallFromTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateFastTrackCallFromTaskRequest";
                };
    
                return CreateFastTrackCallFromTaskRequest;
            })();
    
            be.CreateFastTrackCallFromTaskResponse = (function() {
    
                /**
                 * Properties of a CreateFastTrackCallFromTaskResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateFastTrackCallFromTaskResponse
                 * @property {string|null} [callUuid] CreateFastTrackCallFromTaskResponse callUuid
                 */
    
                /**
                 * Constructs a new CreateFastTrackCallFromTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateFastTrackCallFromTaskResponse.
                 * @implements ICreateFastTrackCallFromTaskResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateFastTrackCallFromTaskResponse=} [properties] Properties to set
                 */
                function CreateFastTrackCallFromTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateFastTrackCallFromTaskResponse callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @instance
                 */
                CreateFastTrackCallFromTaskResponse.prototype.callUuid = "";
    
                /**
                 * Creates a new CreateFastTrackCallFromTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackCallFromTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateFastTrackCallFromTaskResponse} CreateFastTrackCallFromTaskResponse instance
                 */
                CreateFastTrackCallFromTaskResponse.create = function create(properties) {
                    return new CreateFastTrackCallFromTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateFastTrackCallFromTaskResponse message. Does not implicitly {@link infinitusai.be.CreateFastTrackCallFromTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackCallFromTaskResponse} message CreateFastTrackCallFromTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFastTrackCallFromTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateFastTrackCallFromTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateFastTrackCallFromTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateFastTrackCallFromTaskResponse} message CreateFastTrackCallFromTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFastTrackCallFromTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateFastTrackCallFromTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateFastTrackCallFromTaskResponse} CreateFastTrackCallFromTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFastTrackCallFromTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateFastTrackCallFromTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateFastTrackCallFromTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateFastTrackCallFromTaskResponse} CreateFastTrackCallFromTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFastTrackCallFromTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateFastTrackCallFromTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateFastTrackCallFromTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateFastTrackCallFromTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateFastTrackCallFromTaskResponse} CreateFastTrackCallFromTaskResponse
                 */
                CreateFastTrackCallFromTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateFastTrackCallFromTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.CreateFastTrackCallFromTaskResponse();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateFastTrackCallFromTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {infinitusai.be.CreateFastTrackCallFromTaskResponse} message CreateFastTrackCallFromTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateFastTrackCallFromTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.callUuid = "";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this CreateFastTrackCallFromTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateFastTrackCallFromTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateFastTrackCallFromTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateFastTrackCallFromTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateFastTrackCallFromTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateFastTrackCallFromTaskResponse";
                };
    
                return CreateFastTrackCallFromTaskResponse;
            })();
    
            be.GetEstimatedHoldTimeRequest = (function() {
    
                /**
                 * Properties of a GetEstimatedHoldTimeRequest.
                 * @memberof infinitusai.be
                 * @interface IGetEstimatedHoldTimeRequest
                 * @property {string|null} [payorId] GetEstimatedHoldTimeRequest payorId
                 * @property {string|null} [phoneNumber] GetEstimatedHoldTimeRequest phoneNumber
                 * @property {string|null} [taskUuid] GetEstimatedHoldTimeRequest taskUuid
                 * @property {string|null} [callUuid] GetEstimatedHoldTimeRequest callUuid
                 * @property {infinitusai.be.TaskType|null} [taskType] GetEstimatedHoldTimeRequest taskType
                 */
    
                /**
                 * Constructs a new GetEstimatedHoldTimeRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetEstimatedHoldTimeRequest.
                 * @implements IGetEstimatedHoldTimeRequest
                 * @constructor
                 * @param {infinitusai.be.IGetEstimatedHoldTimeRequest=} [properties] Properties to set
                 */
                function GetEstimatedHoldTimeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetEstimatedHoldTimeRequest payorId.
                 * @member {string} payorId
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @instance
                 */
                GetEstimatedHoldTimeRequest.prototype.payorId = "";
    
                /**
                 * GetEstimatedHoldTimeRequest phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @instance
                 */
                GetEstimatedHoldTimeRequest.prototype.phoneNumber = "";
    
                /**
                 * GetEstimatedHoldTimeRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @instance
                 */
                GetEstimatedHoldTimeRequest.prototype.taskUuid = "";
    
                /**
                 * GetEstimatedHoldTimeRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @instance
                 */
                GetEstimatedHoldTimeRequest.prototype.callUuid = "";
    
                /**
                 * GetEstimatedHoldTimeRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @instance
                 */
                GetEstimatedHoldTimeRequest.prototype.taskType = 0;
    
                /**
                 * Creates a new GetEstimatedHoldTimeRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {infinitusai.be.IGetEstimatedHoldTimeRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetEstimatedHoldTimeRequest} GetEstimatedHoldTimeRequest instance
                 */
                GetEstimatedHoldTimeRequest.create = function create(properties) {
                    return new GetEstimatedHoldTimeRequest(properties);
                };
    
                /**
                 * Encodes the specified GetEstimatedHoldTimeRequest message. Does not implicitly {@link infinitusai.be.GetEstimatedHoldTimeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {infinitusai.be.IGetEstimatedHoldTimeRequest} message GetEstimatedHoldTimeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEstimatedHoldTimeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payorId != null && Object.hasOwnProperty.call(message, "payorId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.payorId);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.phoneNumber);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.callUuid);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetEstimatedHoldTimeRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetEstimatedHoldTimeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {infinitusai.be.IGetEstimatedHoldTimeRequest} message GetEstimatedHoldTimeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEstimatedHoldTimeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetEstimatedHoldTimeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetEstimatedHoldTimeRequest} GetEstimatedHoldTimeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEstimatedHoldTimeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetEstimatedHoldTimeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.payorId = reader.string();
                                break;
                            }
                        case 2: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.taskType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetEstimatedHoldTimeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetEstimatedHoldTimeRequest} GetEstimatedHoldTimeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEstimatedHoldTimeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetEstimatedHoldTimeRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetEstimatedHoldTimeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payorId != null && message.hasOwnProperty("payorId"))
                        if (!$util.isString(message.payorId))
                            return "payorId: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GetEstimatedHoldTimeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetEstimatedHoldTimeRequest} GetEstimatedHoldTimeRequest
                 */
                GetEstimatedHoldTimeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetEstimatedHoldTimeRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetEstimatedHoldTimeRequest();
                    if (object.payorId != null)
                        message.payorId = String(object.payorId);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetEstimatedHoldTimeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {infinitusai.be.GetEstimatedHoldTimeRequest} message GetEstimatedHoldTimeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetEstimatedHoldTimeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.payorId = "";
                        object.phoneNumber = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                    }
                    if (message.payorId != null && message.hasOwnProperty("payorId"))
                        object.payorId = message.payorId;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    return object;
                };
    
                /**
                 * Converts this GetEstimatedHoldTimeRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetEstimatedHoldTimeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetEstimatedHoldTimeRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetEstimatedHoldTimeRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetEstimatedHoldTimeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetEstimatedHoldTimeRequest";
                };
    
                return GetEstimatedHoldTimeRequest;
            })();
    
            be.JoinOrDepartCallRequest = (function() {
    
                /**
                 * Properties of a JoinOrDepartCallRequest.
                 * @memberof infinitusai.be
                 * @interface IJoinOrDepartCallRequest
                 * @property {string|null} [callUuid] JoinOrDepartCallRequest callUuid
                 * @property {string|null} [taskUuid] JoinOrDepartCallRequest taskUuid
                 * @property {infinitusai.be.CallJoinOrDepart|null} [addOrRemoveUser] JoinOrDepartCallRequest addOrRemoveUser
                 */
    
                /**
                 * Constructs a new JoinOrDepartCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a JoinOrDepartCallRequest.
                 * @implements IJoinOrDepartCallRequest
                 * @constructor
                 * @param {infinitusai.be.IJoinOrDepartCallRequest=} [properties] Properties to set
                 */
                function JoinOrDepartCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * JoinOrDepartCallRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @instance
                 */
                JoinOrDepartCallRequest.prototype.callUuid = "";
    
                /**
                 * JoinOrDepartCallRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @instance
                 */
                JoinOrDepartCallRequest.prototype.taskUuid = "";
    
                /**
                 * JoinOrDepartCallRequest addOrRemoveUser.
                 * @member {infinitusai.be.CallJoinOrDepart} addOrRemoveUser
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @instance
                 */
                JoinOrDepartCallRequest.prototype.addOrRemoveUser = 0;
    
                /**
                 * Creates a new JoinOrDepartCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {infinitusai.be.IJoinOrDepartCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.JoinOrDepartCallRequest} JoinOrDepartCallRequest instance
                 */
                JoinOrDepartCallRequest.create = function create(properties) {
                    return new JoinOrDepartCallRequest(properties);
                };
    
                /**
                 * Encodes the specified JoinOrDepartCallRequest message. Does not implicitly {@link infinitusai.be.JoinOrDepartCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {infinitusai.be.IJoinOrDepartCallRequest} message JoinOrDepartCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JoinOrDepartCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.addOrRemoveUser != null && Object.hasOwnProperty.call(message, "addOrRemoveUser"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.addOrRemoveUser);
                    return writer;
                };
    
                /**
                 * Encodes the specified JoinOrDepartCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.JoinOrDepartCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {infinitusai.be.IJoinOrDepartCallRequest} message JoinOrDepartCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JoinOrDepartCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a JoinOrDepartCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.JoinOrDepartCallRequest} JoinOrDepartCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JoinOrDepartCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.JoinOrDepartCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.addOrRemoveUser = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a JoinOrDepartCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.JoinOrDepartCallRequest} JoinOrDepartCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JoinOrDepartCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a JoinOrDepartCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                JoinOrDepartCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.addOrRemoveUser != null && message.hasOwnProperty("addOrRemoveUser"))
                        switch (message.addOrRemoveUser) {
                        default:
                            return "addOrRemoveUser: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a JoinOrDepartCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.JoinOrDepartCallRequest} JoinOrDepartCallRequest
                 */
                JoinOrDepartCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.JoinOrDepartCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.JoinOrDepartCallRequest();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    switch (object.addOrRemoveUser) {
                    default:
                        if (typeof object.addOrRemoveUser === "number") {
                            message.addOrRemoveUser = object.addOrRemoveUser;
                            break;
                        }
                        break;
                    case "ADD_USER_TO_CALL":
                    case 0:
                        message.addOrRemoveUser = 0;
                        break;
                    case "REMOVE_USER_FROM_CALL":
                    case 1:
                        message.addOrRemoveUser = 1;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a JoinOrDepartCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {infinitusai.be.JoinOrDepartCallRequest} message JoinOrDepartCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                JoinOrDepartCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callUuid = "";
                        object.taskUuid = "";
                        object.addOrRemoveUser = options.enums === String ? "ADD_USER_TO_CALL" : 0;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.addOrRemoveUser != null && message.hasOwnProperty("addOrRemoveUser"))
                        object.addOrRemoveUser = options.enums === String ? $root.infinitusai.be.CallJoinOrDepart[message.addOrRemoveUser] === undefined ? message.addOrRemoveUser : $root.infinitusai.be.CallJoinOrDepart[message.addOrRemoveUser] : message.addOrRemoveUser;
                    return object;
                };
    
                /**
                 * Converts this JoinOrDepartCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                JoinOrDepartCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for JoinOrDepartCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.JoinOrDepartCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                JoinOrDepartCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.JoinOrDepartCallRequest";
                };
    
                return JoinOrDepartCallRequest;
            })();
    
            /**
             * CallJoinOrDepart enum.
             * @name infinitusai.be.CallJoinOrDepart
             * @enum {number}
             * @property {number} ADD_USER_TO_CALL=0 ADD_USER_TO_CALL value
             * @property {number} REMOVE_USER_FROM_CALL=1 REMOVE_USER_FROM_CALL value
             */
            be.CallJoinOrDepart = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ADD_USER_TO_CALL"] = 0;
                values[valuesById[1] = "REMOVE_USER_FROM_CALL"] = 1;
                return values;
            })();
    
            be.JoinOrDepartCallResponse = (function() {
    
                /**
                 * Properties of a JoinOrDepartCallResponse.
                 * @memberof infinitusai.be
                 * @interface IJoinOrDepartCallResponse
                 * @property {string|null} [emailAddedToCall] JoinOrDepartCallResponse emailAddedToCall
                 */
    
                /**
                 * Constructs a new JoinOrDepartCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a JoinOrDepartCallResponse.
                 * @implements IJoinOrDepartCallResponse
                 * @constructor
                 * @param {infinitusai.be.IJoinOrDepartCallResponse=} [properties] Properties to set
                 */
                function JoinOrDepartCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * JoinOrDepartCallResponse emailAddedToCall.
                 * @member {string} emailAddedToCall
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @instance
                 */
                JoinOrDepartCallResponse.prototype.emailAddedToCall = "";
    
                /**
                 * Creates a new JoinOrDepartCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {infinitusai.be.IJoinOrDepartCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.JoinOrDepartCallResponse} JoinOrDepartCallResponse instance
                 */
                JoinOrDepartCallResponse.create = function create(properties) {
                    return new JoinOrDepartCallResponse(properties);
                };
    
                /**
                 * Encodes the specified JoinOrDepartCallResponse message. Does not implicitly {@link infinitusai.be.JoinOrDepartCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {infinitusai.be.IJoinOrDepartCallResponse} message JoinOrDepartCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JoinOrDepartCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.emailAddedToCall != null && Object.hasOwnProperty.call(message, "emailAddedToCall"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailAddedToCall);
                    return writer;
                };
    
                /**
                 * Encodes the specified JoinOrDepartCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.JoinOrDepartCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {infinitusai.be.IJoinOrDepartCallResponse} message JoinOrDepartCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JoinOrDepartCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a JoinOrDepartCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.JoinOrDepartCallResponse} JoinOrDepartCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JoinOrDepartCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.JoinOrDepartCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.emailAddedToCall = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a JoinOrDepartCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.JoinOrDepartCallResponse} JoinOrDepartCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JoinOrDepartCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a JoinOrDepartCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                JoinOrDepartCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.emailAddedToCall != null && message.hasOwnProperty("emailAddedToCall"))
                        if (!$util.isString(message.emailAddedToCall))
                            return "emailAddedToCall: string expected";
                    return null;
                };
    
                /**
                 * Creates a JoinOrDepartCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.JoinOrDepartCallResponse} JoinOrDepartCallResponse
                 */
                JoinOrDepartCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.JoinOrDepartCallResponse)
                        return object;
                    var message = new $root.infinitusai.be.JoinOrDepartCallResponse();
                    if (object.emailAddedToCall != null)
                        message.emailAddedToCall = String(object.emailAddedToCall);
                    return message;
                };
    
                /**
                 * Creates a plain object from a JoinOrDepartCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {infinitusai.be.JoinOrDepartCallResponse} message JoinOrDepartCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                JoinOrDepartCallResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.emailAddedToCall = "";
                    if (message.emailAddedToCall != null && message.hasOwnProperty("emailAddedToCall"))
                        object.emailAddedToCall = message.emailAddedToCall;
                    return object;
                };
    
                /**
                 * Converts this JoinOrDepartCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                JoinOrDepartCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for JoinOrDepartCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.JoinOrDepartCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                JoinOrDepartCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.JoinOrDepartCallResponse";
                };
    
                return JoinOrDepartCallResponse;
            })();
    
            be.AutoSelectNextTaskRequest = (function() {
    
                /**
                 * Properties of an AutoSelectNextTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IAutoSelectNextTaskRequest
                 * @property {boolean|null} [peekingNextTask] AutoSelectNextTaskRequest peekingNextTask
                 */
    
                /**
                 * Constructs a new AutoSelectNextTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AutoSelectNextTaskRequest.
                 * @implements IAutoSelectNextTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IAutoSelectNextTaskRequest=} [properties] Properties to set
                 */
                function AutoSelectNextTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AutoSelectNextTaskRequest peekingNextTask.
                 * @member {boolean} peekingNextTask
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @instance
                 */
                AutoSelectNextTaskRequest.prototype.peekingNextTask = false;
    
                /**
                 * Creates a new AutoSelectNextTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {infinitusai.be.IAutoSelectNextTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.AutoSelectNextTaskRequest} AutoSelectNextTaskRequest instance
                 */
                AutoSelectNextTaskRequest.create = function create(properties) {
                    return new AutoSelectNextTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified AutoSelectNextTaskRequest message. Does not implicitly {@link infinitusai.be.AutoSelectNextTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {infinitusai.be.IAutoSelectNextTaskRequest} message AutoSelectNextTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoSelectNextTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.peekingNextTask != null && Object.hasOwnProperty.call(message, "peekingNextTask"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.peekingNextTask);
                    return writer;
                };
    
                /**
                 * Encodes the specified AutoSelectNextTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.AutoSelectNextTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {infinitusai.be.IAutoSelectNextTaskRequest} message AutoSelectNextTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoSelectNextTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AutoSelectNextTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AutoSelectNextTaskRequest} AutoSelectNextTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoSelectNextTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AutoSelectNextTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.peekingNextTask = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AutoSelectNextTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AutoSelectNextTaskRequest} AutoSelectNextTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoSelectNextTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AutoSelectNextTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AutoSelectNextTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.peekingNextTask != null && message.hasOwnProperty("peekingNextTask"))
                        if (typeof message.peekingNextTask !== "boolean")
                            return "peekingNextTask: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an AutoSelectNextTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AutoSelectNextTaskRequest} AutoSelectNextTaskRequest
                 */
                AutoSelectNextTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AutoSelectNextTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.AutoSelectNextTaskRequest();
                    if (object.peekingNextTask != null)
                        message.peekingNextTask = Boolean(object.peekingNextTask);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AutoSelectNextTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {infinitusai.be.AutoSelectNextTaskRequest} message AutoSelectNextTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AutoSelectNextTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.peekingNextTask = false;
                    if (message.peekingNextTask != null && message.hasOwnProperty("peekingNextTask"))
                        object.peekingNextTask = message.peekingNextTask;
                    return object;
                };
    
                /**
                 * Converts this AutoSelectNextTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AutoSelectNextTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AutoSelectNextTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AutoSelectNextTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AutoSelectNextTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AutoSelectNextTaskRequest";
                };
    
                return AutoSelectNextTaskRequest;
            })();
    
            be.AutoSelectNextTaskResponse = (function() {
    
                /**
                 * Properties of an AutoSelectNextTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IAutoSelectNextTaskResponse
                 * @property {boolean|null} [isTaskAvailable] AutoSelectNextTaskResponse isTaskAvailable
                 * @property {string|null} [taskUuid] AutoSelectNextTaskResponse taskUuid
                 * @property {string|null} [callUuid] AutoSelectNextTaskResponse callUuid
                 */
    
                /**
                 * Constructs a new AutoSelectNextTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AutoSelectNextTaskResponse.
                 * @implements IAutoSelectNextTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IAutoSelectNextTaskResponse=} [properties] Properties to set
                 */
                function AutoSelectNextTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AutoSelectNextTaskResponse isTaskAvailable.
                 * @member {boolean} isTaskAvailable
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @instance
                 */
                AutoSelectNextTaskResponse.prototype.isTaskAvailable = false;
    
                /**
                 * AutoSelectNextTaskResponse taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @instance
                 */
                AutoSelectNextTaskResponse.prototype.taskUuid = "";
    
                /**
                 * AutoSelectNextTaskResponse callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @instance
                 */
                AutoSelectNextTaskResponse.prototype.callUuid = "";
    
                /**
                 * Creates a new AutoSelectNextTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {infinitusai.be.IAutoSelectNextTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.AutoSelectNextTaskResponse} AutoSelectNextTaskResponse instance
                 */
                AutoSelectNextTaskResponse.create = function create(properties) {
                    return new AutoSelectNextTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified AutoSelectNextTaskResponse message. Does not implicitly {@link infinitusai.be.AutoSelectNextTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {infinitusai.be.IAutoSelectNextTaskResponse} message AutoSelectNextTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoSelectNextTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isTaskAvailable != null && Object.hasOwnProperty.call(message, "isTaskAvailable"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isTaskAvailable);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified AutoSelectNextTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.AutoSelectNextTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {infinitusai.be.IAutoSelectNextTaskResponse} message AutoSelectNextTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoSelectNextTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AutoSelectNextTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AutoSelectNextTaskResponse} AutoSelectNextTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoSelectNextTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AutoSelectNextTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isTaskAvailable = reader.bool();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AutoSelectNextTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AutoSelectNextTaskResponse} AutoSelectNextTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoSelectNextTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AutoSelectNextTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AutoSelectNextTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isTaskAvailable != null && message.hasOwnProperty("isTaskAvailable"))
                        if (typeof message.isTaskAvailable !== "boolean")
                            return "isTaskAvailable: boolean expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an AutoSelectNextTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AutoSelectNextTaskResponse} AutoSelectNextTaskResponse
                 */
                AutoSelectNextTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AutoSelectNextTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.AutoSelectNextTaskResponse();
                    if (object.isTaskAvailable != null)
                        message.isTaskAvailable = Boolean(object.isTaskAvailable);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AutoSelectNextTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {infinitusai.be.AutoSelectNextTaskResponse} message AutoSelectNextTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AutoSelectNextTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isTaskAvailable = false;
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.isTaskAvailable != null && message.hasOwnProperty("isTaskAvailable"))
                        object.isTaskAvailable = message.isTaskAvailable;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this AutoSelectNextTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AutoSelectNextTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AutoSelectNextTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AutoSelectNextTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AutoSelectNextTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AutoSelectNextTaskResponse";
                };
    
                return AutoSelectNextTaskResponse;
            })();
    
            be.GetEstimatedHoldTimeResponse = (function() {
    
                /**
                 * Properties of a GetEstimatedHoldTimeResponse.
                 * @memberof infinitusai.be
                 * @interface IGetEstimatedHoldTimeResponse
                 * @property {boolean|null} [isResultAvailable] GetEstimatedHoldTimeResponse isResultAvailable
                 * @property {number|null} [estimatedHoldTimeMinutes] GetEstimatedHoldTimeResponse estimatedHoldTimeMinutes
                 * @property {number|null} [lowerBoundDeltaMinutes] GetEstimatedHoldTimeResponse lowerBoundDeltaMinutes
                 * @property {number|null} [upperBoundDeltaMinutes] GetEstimatedHoldTimeResponse upperBoundDeltaMinutes
                 */
    
                /**
                 * Constructs a new GetEstimatedHoldTimeResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetEstimatedHoldTimeResponse.
                 * @implements IGetEstimatedHoldTimeResponse
                 * @constructor
                 * @param {infinitusai.be.IGetEstimatedHoldTimeResponse=} [properties] Properties to set
                 */
                function GetEstimatedHoldTimeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetEstimatedHoldTimeResponse isResultAvailable.
                 * @member {boolean} isResultAvailable
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @instance
                 */
                GetEstimatedHoldTimeResponse.prototype.isResultAvailable = false;
    
                /**
                 * GetEstimatedHoldTimeResponse estimatedHoldTimeMinutes.
                 * @member {number} estimatedHoldTimeMinutes
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @instance
                 */
                GetEstimatedHoldTimeResponse.prototype.estimatedHoldTimeMinutes = 0;
    
                /**
                 * GetEstimatedHoldTimeResponse lowerBoundDeltaMinutes.
                 * @member {number} lowerBoundDeltaMinutes
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @instance
                 */
                GetEstimatedHoldTimeResponse.prototype.lowerBoundDeltaMinutes = 0;
    
                /**
                 * GetEstimatedHoldTimeResponse upperBoundDeltaMinutes.
                 * @member {number} upperBoundDeltaMinutes
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @instance
                 */
                GetEstimatedHoldTimeResponse.prototype.upperBoundDeltaMinutes = 0;
    
                /**
                 * Creates a new GetEstimatedHoldTimeResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {infinitusai.be.IGetEstimatedHoldTimeResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetEstimatedHoldTimeResponse} GetEstimatedHoldTimeResponse instance
                 */
                GetEstimatedHoldTimeResponse.create = function create(properties) {
                    return new GetEstimatedHoldTimeResponse(properties);
                };
    
                /**
                 * Encodes the specified GetEstimatedHoldTimeResponse message. Does not implicitly {@link infinitusai.be.GetEstimatedHoldTimeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {infinitusai.be.IGetEstimatedHoldTimeResponse} message GetEstimatedHoldTimeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEstimatedHoldTimeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isResultAvailable != null && Object.hasOwnProperty.call(message, "isResultAvailable"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isResultAvailable);
                    if (message.estimatedHoldTimeMinutes != null && Object.hasOwnProperty.call(message, "estimatedHoldTimeMinutes"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.estimatedHoldTimeMinutes);
                    if (message.lowerBoundDeltaMinutes != null && Object.hasOwnProperty.call(message, "lowerBoundDeltaMinutes"))
                        writer.uint32(/* id 3, wireType 5 =*/29).float(message.lowerBoundDeltaMinutes);
                    if (message.upperBoundDeltaMinutes != null && Object.hasOwnProperty.call(message, "upperBoundDeltaMinutes"))
                        writer.uint32(/* id 4, wireType 5 =*/37).float(message.upperBoundDeltaMinutes);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetEstimatedHoldTimeResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetEstimatedHoldTimeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {infinitusai.be.IGetEstimatedHoldTimeResponse} message GetEstimatedHoldTimeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEstimatedHoldTimeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetEstimatedHoldTimeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetEstimatedHoldTimeResponse} GetEstimatedHoldTimeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEstimatedHoldTimeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetEstimatedHoldTimeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isResultAvailable = reader.bool();
                                break;
                            }
                        case 2: {
                                message.estimatedHoldTimeMinutes = reader.float();
                                break;
                            }
                        case 3: {
                                message.lowerBoundDeltaMinutes = reader.float();
                                break;
                            }
                        case 4: {
                                message.upperBoundDeltaMinutes = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetEstimatedHoldTimeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetEstimatedHoldTimeResponse} GetEstimatedHoldTimeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEstimatedHoldTimeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetEstimatedHoldTimeResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetEstimatedHoldTimeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isResultAvailable != null && message.hasOwnProperty("isResultAvailable"))
                        if (typeof message.isResultAvailable !== "boolean")
                            return "isResultAvailable: boolean expected";
                    if (message.estimatedHoldTimeMinutes != null && message.hasOwnProperty("estimatedHoldTimeMinutes"))
                        if (typeof message.estimatedHoldTimeMinutes !== "number")
                            return "estimatedHoldTimeMinutes: number expected";
                    if (message.lowerBoundDeltaMinutes != null && message.hasOwnProperty("lowerBoundDeltaMinutes"))
                        if (typeof message.lowerBoundDeltaMinutes !== "number")
                            return "lowerBoundDeltaMinutes: number expected";
                    if (message.upperBoundDeltaMinutes != null && message.hasOwnProperty("upperBoundDeltaMinutes"))
                        if (typeof message.upperBoundDeltaMinutes !== "number")
                            return "upperBoundDeltaMinutes: number expected";
                    return null;
                };
    
                /**
                 * Creates a GetEstimatedHoldTimeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetEstimatedHoldTimeResponse} GetEstimatedHoldTimeResponse
                 */
                GetEstimatedHoldTimeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetEstimatedHoldTimeResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetEstimatedHoldTimeResponse();
                    if (object.isResultAvailable != null)
                        message.isResultAvailable = Boolean(object.isResultAvailable);
                    if (object.estimatedHoldTimeMinutes != null)
                        message.estimatedHoldTimeMinutes = Number(object.estimatedHoldTimeMinutes);
                    if (object.lowerBoundDeltaMinutes != null)
                        message.lowerBoundDeltaMinutes = Number(object.lowerBoundDeltaMinutes);
                    if (object.upperBoundDeltaMinutes != null)
                        message.upperBoundDeltaMinutes = Number(object.upperBoundDeltaMinutes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetEstimatedHoldTimeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {infinitusai.be.GetEstimatedHoldTimeResponse} message GetEstimatedHoldTimeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetEstimatedHoldTimeResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isResultAvailable = false;
                        object.estimatedHoldTimeMinutes = 0;
                        object.lowerBoundDeltaMinutes = 0;
                        object.upperBoundDeltaMinutes = 0;
                    }
                    if (message.isResultAvailable != null && message.hasOwnProperty("isResultAvailable"))
                        object.isResultAvailable = message.isResultAvailable;
                    if (message.estimatedHoldTimeMinutes != null && message.hasOwnProperty("estimatedHoldTimeMinutes"))
                        object.estimatedHoldTimeMinutes = options.json && !isFinite(message.estimatedHoldTimeMinutes) ? String(message.estimatedHoldTimeMinutes) : message.estimatedHoldTimeMinutes;
                    if (message.lowerBoundDeltaMinutes != null && message.hasOwnProperty("lowerBoundDeltaMinutes"))
                        object.lowerBoundDeltaMinutes = options.json && !isFinite(message.lowerBoundDeltaMinutes) ? String(message.lowerBoundDeltaMinutes) : message.lowerBoundDeltaMinutes;
                    if (message.upperBoundDeltaMinutes != null && message.hasOwnProperty("upperBoundDeltaMinutes"))
                        object.upperBoundDeltaMinutes = options.json && !isFinite(message.upperBoundDeltaMinutes) ? String(message.upperBoundDeltaMinutes) : message.upperBoundDeltaMinutes;
                    return object;
                };
    
                /**
                 * Converts this GetEstimatedHoldTimeResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetEstimatedHoldTimeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetEstimatedHoldTimeResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetEstimatedHoldTimeResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetEstimatedHoldTimeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetEstimatedHoldTimeResponse";
                };
    
                return GetEstimatedHoldTimeResponse;
            })();
    
            /**
             * FastTrackCallOutcome enum.
             * @name infinitusai.be.FastTrackCallOutcome
             * @enum {number}
             * @property {number} FAST_TRACK_CALL_OUTCOME_UNKNOWN=0 FAST_TRACK_CALL_OUTCOME_UNKNOWN value
             * @property {number} FAST_TRACK_CALL_OUTCOME_SUCCESS=1 FAST_TRACK_CALL_OUTCOME_SUCCESS value
             * @property {number} FAST_TRACK_CALL_OUTCOME_FAILURE=2 FAST_TRACK_CALL_OUTCOME_FAILURE value
             * @property {number} FAST_TRACK_CALL_OUTCOME_FAILURE_AND_REDIAL=3 FAST_TRACK_CALL_OUTCOME_FAILURE_AND_REDIAL value
             * @property {number} FAST_TRACK_CALL_OUTCOME_FAILURE_AND_SEND_TO_QUEUE=4 FAST_TRACK_CALL_OUTCOME_FAILURE_AND_SEND_TO_QUEUE value
             * @property {number} FAST_TRACK_CALL_OUTCOME_NOT_PROCESSED_YET=5 FAST_TRACK_CALL_OUTCOME_NOT_PROCESSED_YET value
             */
            be.FastTrackCallOutcome = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "FAST_TRACK_CALL_OUTCOME_UNKNOWN"] = 0;
                values[valuesById[1] = "FAST_TRACK_CALL_OUTCOME_SUCCESS"] = 1;
                values[valuesById[2] = "FAST_TRACK_CALL_OUTCOME_FAILURE"] = 2;
                values[valuesById[3] = "FAST_TRACK_CALL_OUTCOME_FAILURE_AND_REDIAL"] = 3;
                values[valuesById[4] = "FAST_TRACK_CALL_OUTCOME_FAILURE_AND_SEND_TO_QUEUE"] = 4;
                values[valuesById[5] = "FAST_TRACK_CALL_OUTCOME_NOT_PROCESSED_YET"] = 5;
                return values;
            })();
    
            be.FastTrackProcessCallDetails = (function() {
    
                /**
                 * Properties of a FastTrackProcessCallDetails.
                 * @memberof infinitusai.be
                 * @interface IFastTrackProcessCallDetails
                 * @property {string|null} [failureMajorReason] FastTrackProcessCallDetails failureMajorReason
                 * @property {infinitusai.be.RequeueReason.Type|null} [failureDetailedReason] FastTrackProcessCallDetails failureDetailedReason
                 * @property {string|null} [failureDetailsNotes] FastTrackProcessCallDetails failureDetailsNotes
                 * @property {infinitusai.be.IFastTrackSuccessfulTaskNotes|null} [successfulTaskNotes] FastTrackProcessCallDetails successfulTaskNotes
                 */
    
                /**
                 * Constructs a new FastTrackProcessCallDetails.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FastTrackProcessCallDetails.
                 * @implements IFastTrackProcessCallDetails
                 * @constructor
                 * @param {infinitusai.be.IFastTrackProcessCallDetails=} [properties] Properties to set
                 */
                function FastTrackProcessCallDetails(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FastTrackProcessCallDetails failureMajorReason.
                 * @member {string} failureMajorReason
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @instance
                 */
                FastTrackProcessCallDetails.prototype.failureMajorReason = "";
    
                /**
                 * FastTrackProcessCallDetails failureDetailedReason.
                 * @member {infinitusai.be.RequeueReason.Type} failureDetailedReason
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @instance
                 */
                FastTrackProcessCallDetails.prototype.failureDetailedReason = 0;
    
                /**
                 * FastTrackProcessCallDetails failureDetailsNotes.
                 * @member {string} failureDetailsNotes
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @instance
                 */
                FastTrackProcessCallDetails.prototype.failureDetailsNotes = "";
    
                /**
                 * FastTrackProcessCallDetails successfulTaskNotes.
                 * @member {infinitusai.be.IFastTrackSuccessfulTaskNotes|null|undefined} successfulTaskNotes
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @instance
                 */
                FastTrackProcessCallDetails.prototype.successfulTaskNotes = null;
    
                /**
                 * Creates a new FastTrackProcessCallDetails instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {infinitusai.be.IFastTrackProcessCallDetails=} [properties] Properties to set
                 * @returns {infinitusai.be.FastTrackProcessCallDetails} FastTrackProcessCallDetails instance
                 */
                FastTrackProcessCallDetails.create = function create(properties) {
                    return new FastTrackProcessCallDetails(properties);
                };
    
                /**
                 * Encodes the specified FastTrackProcessCallDetails message. Does not implicitly {@link infinitusai.be.FastTrackProcessCallDetails.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {infinitusai.be.IFastTrackProcessCallDetails} message FastTrackProcessCallDetails message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackProcessCallDetails.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.failureMajorReason != null && Object.hasOwnProperty.call(message, "failureMajorReason"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.failureMajorReason);
                    if (message.failureDetailedReason != null && Object.hasOwnProperty.call(message, "failureDetailedReason"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.failureDetailedReason);
                    if (message.failureDetailsNotes != null && Object.hasOwnProperty.call(message, "failureDetailsNotes"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.failureDetailsNotes);
                    if (message.successfulTaskNotes != null && Object.hasOwnProperty.call(message, "successfulTaskNotes"))
                        $root.infinitusai.be.FastTrackSuccessfulTaskNotes.encode(message.successfulTaskNotes, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FastTrackProcessCallDetails message, length delimited. Does not implicitly {@link infinitusai.be.FastTrackProcessCallDetails.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {infinitusai.be.IFastTrackProcessCallDetails} message FastTrackProcessCallDetails message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackProcessCallDetails.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FastTrackProcessCallDetails message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FastTrackProcessCallDetails} FastTrackProcessCallDetails
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackProcessCallDetails.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FastTrackProcessCallDetails();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.failureMajorReason = reader.string();
                                break;
                            }
                        case 2: {
                                message.failureDetailedReason = reader.int32();
                                break;
                            }
                        case 3: {
                                message.failureDetailsNotes = reader.string();
                                break;
                            }
                        case 4: {
                                message.successfulTaskNotes = $root.infinitusai.be.FastTrackSuccessfulTaskNotes.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FastTrackProcessCallDetails message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FastTrackProcessCallDetails} FastTrackProcessCallDetails
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackProcessCallDetails.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FastTrackProcessCallDetails message.
                 * @function verify
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastTrackProcessCallDetails.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.failureMajorReason != null && message.hasOwnProperty("failureMajorReason"))
                        if (!$util.isString(message.failureMajorReason))
                            return "failureMajorReason: string expected";
                    if (message.failureDetailedReason != null && message.hasOwnProperty("failureDetailedReason"))
                        switch (message.failureDetailedReason) {
                        default:
                            return "failureDetailedReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 29:
                        case 21:
                        case 30:
                        case 22:
                        case 23:
                        case 24:
                        case 32:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 31:
                        case 33:
                        case 34:
                        case 42:
                        case 43:
                        case 45:
                        case 46:
                        case 47:
                        case 52:
                        case 53:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 99:
                        case 35:
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 48:
                        case 49:
                        case 50:
                        case 51:
                        case 55:
                        case 56:
                        case 57:
                        case 58:
                        case 59:
                        case 44:
                        case 60:
                            break;
                        }
                    if (message.failureDetailsNotes != null && message.hasOwnProperty("failureDetailsNotes"))
                        if (!$util.isString(message.failureDetailsNotes))
                            return "failureDetailsNotes: string expected";
                    if (message.successfulTaskNotes != null && message.hasOwnProperty("successfulTaskNotes")) {
                        var error = $root.infinitusai.be.FastTrackSuccessfulTaskNotes.verify(message.successfulTaskNotes);
                        if (error)
                            return "successfulTaskNotes." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FastTrackProcessCallDetails message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FastTrackProcessCallDetails} FastTrackProcessCallDetails
                 */
                FastTrackProcessCallDetails.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FastTrackProcessCallDetails)
                        return object;
                    var message = new $root.infinitusai.be.FastTrackProcessCallDetails();
                    if (object.failureMajorReason != null)
                        message.failureMajorReason = String(object.failureMajorReason);
                    switch (object.failureDetailedReason) {
                    default:
                        if (typeof object.failureDetailedReason === "number") {
                            message.failureDetailedReason = object.failureDetailedReason;
                            break;
                        }
                        break;
                    case "REASON_UNKNOWN":
                    case 0:
                        message.failureDetailedReason = 0;
                        break;
                    case "REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 1:
                        message.failureDetailedReason = 1;
                        break;
                    case "REASON_AGENT_DID_NOT_PROVIDE_INFO":
                    case 2:
                        message.failureDetailedReason = 2;
                        break;
                    case "REASON_AGENT_PROVIDED_UNRELIABLE_DATA":
                    case 3:
                        message.failureDetailedReason = 3;
                        break;
                    case "REASON_AGENT_REQUESTED_UNAVAILABLE_INFO":
                    case 4:
                        message.failureDetailedReason = 4;
                        break;
                    case "REASON_AGENT_TOO_SLOW":
                    case 5:
                        message.failureDetailedReason = 5;
                        break;
                    case "REASON_OPERATOR_ERROR":
                    case 6:
                        message.failureDetailedReason = 6;
                        break;
                    case "REASON_SYSTEM_ISSUE":
                    case 7:
                        message.failureDetailedReason = 7;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER":
                    case 8:
                        message.failureDetailedReason = 8;
                        break;
                    case "REASON_GATHERED_PIN_FOR_MEDICAL":
                    case 9:
                        message.failureDetailedReason = 9;
                        break;
                    case "REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT":
                    case 10:
                        message.failureDetailedReason = 10;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_BV":
                    case 11:
                        message.failureDetailedReason = 11;
                        break;
                    case "REASON_PAYER_SYSTEM_ISSUE":
                    case 12:
                        message.failureDetailedReason = 12;
                        break;
                    case "REASON_PAYER_UNREACHABLE":
                    case 29:
                        message.failureDetailedReason = 29;
                        break;
                    case "REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY":
                    case 21:
                        message.failureDetailedReason = 21;
                        break;
                    case "REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA":
                    case 30:
                        message.failureDetailedReason = 30;
                        break;
                    case "REASON_MISSING_INPUT_INFORMATION":
                    case 22:
                        message.failureDetailedReason = 22;
                        break;
                    case "REASON_IVR_NAVIGATION_FAILURE":
                    case 23:
                        message.failureDetailedReason = 23;
                        break;
                    case "REASON_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 24:
                        message.failureDetailedReason = 24;
                        break;
                    case "REASON_AGENT_AUTOMATION_HANGUP":
                    case 32:
                        message.failureDetailedReason = 32;
                        break;
                    case "REASON_PIE_ESCALATION_NEEDED":
                    case 25:
                        message.failureDetailedReason = 25;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER_PLAN":
                    case 26:
                        message.failureDetailedReason = 26;
                        break;
                    case "REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED":
                    case 27:
                        message.failureDetailedReason = 27;
                        break;
                    case "REASON_INCORRECT_INPUT_INFORMATION":
                    case 28:
                        message.failureDetailedReason = 28;
                        break;
                    case "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD":
                    case 31:
                        message.failureDetailedReason = 31;
                        break;
                    case "REASON_INCORRECT_PROMPT_SELECTED":
                    case 33:
                        message.failureDetailedReason = 33;
                        break;
                    case "REASON_QUALITY_CONCERNS":
                    case 34:
                        message.failureDetailedReason = 34;
                        break;
                    case "REASON_PROVIDER_IS_INACTIVE":
                    case 42:
                        message.failureDetailedReason = 42;
                        break;
                    case "REASON_NO_OPERATOR_AVAILABLE":
                    case 43:
                        message.failureDetailedReason = 43;
                        break;
                    case "REASON_NO_BENEFITS_UNDER_THIS_PLAN":
                    case 45:
                        message.failureDetailedReason = 45;
                        break;
                    case "REASON_UNABLE_TO_REVERIFY_BENEFITS":
                    case 46:
                        message.failureDetailedReason = 46;
                        break;
                    case "REASON_LONG_HOLD_TIME":
                    case 47:
                        message.failureDetailedReason = 47;
                        break;
                    case "REASON_HOLD_NAVIGATION_FAILURE":
                    case 52:
                        message.failureDetailedReason = 52;
                        break;
                    case "REASON_IVR_TIMEOUT":
                    case 53:
                        message.failureDetailedReason = 53;
                        break;
                    case "REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK":
                    case 13:
                        message.failureDetailedReason = 13;
                        break;
                    case "REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS":
                    case 14:
                        message.failureDetailedReason = 14;
                        break;
                    case "REASON_INCOMPLETE_ANSWER_TO_QUESTIONS":
                    case 15:
                        message.failureDetailedReason = 15;
                        break;
                    case "REASON_INVALID_ANSWERS_TO_QUESTIONS":
                    case 16:
                        message.failureDetailedReason = 16;
                        break;
                    case "REASON_UNANSWERED_QUESTIONS_REMAINING":
                    case 17:
                        message.failureDetailedReason = 17;
                        break;
                    case "REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED":
                    case 18:
                        message.failureDetailedReason = 18;
                        break;
                    case "REASON_BOTH_IN_AND_OON_NOT_COLLECTED":
                    case 19:
                        message.failureDetailedReason = 19;
                        break;
                    case "REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS":
                    case 20:
                        message.failureDetailedReason = 20;
                        break;
                    case "REASON_OTHER":
                    case 99:
                        message.failureDetailedReason = 99;
                        break;
                    case "REASON_NLP_PLAN_TERMINATED":
                    case 35:
                        message.failureDetailedReason = 35;
                        break;
                    case "REASON_NLP_PAYER_UNREACHABLE":
                    case 36:
                        message.failureDetailedReason = 36;
                        break;
                    case "REASON_NLP_ERROR":
                    case 37:
                        message.failureDetailedReason = 37;
                        break;
                    case "REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 38:
                        message.failureDetailedReason = 38;
                        break;
                    case "REASON_NLP_READY_FOR_REVIEW":
                    case 39:
                        message.failureDetailedReason = 39;
                        break;
                    case "REASON_NLP_PAYER_SYSTEM_ISSUE":
                    case 40:
                        message.failureDetailedReason = 40;
                        break;
                    case "REASON_NLP_CALL_NEW_NUMBER":
                    case 41:
                        message.failureDetailedReason = 41;
                        break;
                    case "REASON_NLP_BAD_DATA":
                    case 48:
                        message.failureDetailedReason = 48;
                        break;
                    case "REASON_NLP_AGENT_CANT_FIND_MEMBER":
                    case 49:
                        message.failureDetailedReason = 49;
                        break;
                    case "REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 50:
                        message.failureDetailedReason = 50;
                        break;
                    case "REASON_NLP_INPUT_NOT_RECOGNIZED":
                    case 51:
                        message.failureDetailedReason = 51;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK":
                    case 55:
                        message.failureDetailedReason = 55;
                        break;
                    case "REASON_NEED_TO_CALL_TPA":
                    case 56:
                        message.failureDetailedReason = 56;
                        break;
                    case "REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT":
                    case 57:
                        message.failureDetailedReason = 57;
                        break;
                    case "REASON_IVR_WRONG_DEPARTMENT":
                    case 58:
                        message.failureDetailedReason = 58;
                        break;
                    case "REASON_AGENT_ISSUE":
                    case 59:
                        message.failureDetailedReason = 59;
                        break;
                    case "REASON_NONE":
                    case 44:
                        message.failureDetailedReason = 44;
                        break;
                    case "REASON_CHAINED_TASK":
                    case 60:
                        message.failureDetailedReason = 60;
                        break;
                    }
                    if (object.failureDetailsNotes != null)
                        message.failureDetailsNotes = String(object.failureDetailsNotes);
                    if (object.successfulTaskNotes != null) {
                        if (typeof object.successfulTaskNotes !== "object")
                            throw TypeError(".infinitusai.be.FastTrackProcessCallDetails.successfulTaskNotes: object expected");
                        message.successfulTaskNotes = $root.infinitusai.be.FastTrackSuccessfulTaskNotes.fromObject(object.successfulTaskNotes);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FastTrackProcessCallDetails message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {infinitusai.be.FastTrackProcessCallDetails} message FastTrackProcessCallDetails
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastTrackProcessCallDetails.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.failureMajorReason = "";
                        object.failureDetailedReason = options.enums === String ? "REASON_UNKNOWN" : 0;
                        object.failureDetailsNotes = "";
                        object.successfulTaskNotes = null;
                    }
                    if (message.failureMajorReason != null && message.hasOwnProperty("failureMajorReason"))
                        object.failureMajorReason = message.failureMajorReason;
                    if (message.failureDetailedReason != null && message.hasOwnProperty("failureDetailedReason"))
                        object.failureDetailedReason = options.enums === String ? $root.infinitusai.be.RequeueReason.Type[message.failureDetailedReason] === undefined ? message.failureDetailedReason : $root.infinitusai.be.RequeueReason.Type[message.failureDetailedReason] : message.failureDetailedReason;
                    if (message.failureDetailsNotes != null && message.hasOwnProperty("failureDetailsNotes"))
                        object.failureDetailsNotes = message.failureDetailsNotes;
                    if (message.successfulTaskNotes != null && message.hasOwnProperty("successfulTaskNotes"))
                        object.successfulTaskNotes = $root.infinitusai.be.FastTrackSuccessfulTaskNotes.toObject(message.successfulTaskNotes, options);
                    return object;
                };
    
                /**
                 * Converts this FastTrackProcessCallDetails to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastTrackProcessCallDetails.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FastTrackProcessCallDetails
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FastTrackProcessCallDetails
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FastTrackProcessCallDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FastTrackProcessCallDetails";
                };
    
                return FastTrackProcessCallDetails;
            })();
    
            be.ProcessFastTrackCallRequest = (function() {
    
                /**
                 * Properties of a ProcessFastTrackCallRequest.
                 * @memberof infinitusai.be
                 * @interface IProcessFastTrackCallRequest
                 * @property {string|null} [callUuid] ProcessFastTrackCallRequest callUuid
                 * @property {string|null} [taskUuid] ProcessFastTrackCallRequest taskUuid
                 * @property {infinitusai.be.FastTrackCallOutcome|null} [outcome] ProcessFastTrackCallRequest outcome
                 * @property {infinitusai.be.IFastTrackProcessCallDetails|null} [details] ProcessFastTrackCallRequest details
                 */
    
                /**
                 * Constructs a new ProcessFastTrackCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProcessFastTrackCallRequest.
                 * @implements IProcessFastTrackCallRequest
                 * @constructor
                 * @param {infinitusai.be.IProcessFastTrackCallRequest=} [properties] Properties to set
                 */
                function ProcessFastTrackCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProcessFastTrackCallRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @instance
                 */
                ProcessFastTrackCallRequest.prototype.callUuid = "";
    
                /**
                 * ProcessFastTrackCallRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @instance
                 */
                ProcessFastTrackCallRequest.prototype.taskUuid = "";
    
                /**
                 * ProcessFastTrackCallRequest outcome.
                 * @member {infinitusai.be.FastTrackCallOutcome} outcome
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @instance
                 */
                ProcessFastTrackCallRequest.prototype.outcome = 0;
    
                /**
                 * ProcessFastTrackCallRequest details.
                 * @member {infinitusai.be.IFastTrackProcessCallDetails|null|undefined} details
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @instance
                 */
                ProcessFastTrackCallRequest.prototype.details = null;
    
                /**
                 * Creates a new ProcessFastTrackCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {infinitusai.be.IProcessFastTrackCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ProcessFastTrackCallRequest} ProcessFastTrackCallRequest instance
                 */
                ProcessFastTrackCallRequest.create = function create(properties) {
                    return new ProcessFastTrackCallRequest(properties);
                };
    
                /**
                 * Encodes the specified ProcessFastTrackCallRequest message. Does not implicitly {@link infinitusai.be.ProcessFastTrackCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {infinitusai.be.IProcessFastTrackCallRequest} message ProcessFastTrackCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessFastTrackCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.outcome != null && Object.hasOwnProperty.call(message, "outcome"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.outcome);
                    if (message.details != null && Object.hasOwnProperty.call(message, "details"))
                        $root.infinitusai.be.FastTrackProcessCallDetails.encode(message.details, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ProcessFastTrackCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.ProcessFastTrackCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {infinitusai.be.IProcessFastTrackCallRequest} message ProcessFastTrackCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessFastTrackCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProcessFastTrackCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProcessFastTrackCallRequest} ProcessFastTrackCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessFastTrackCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProcessFastTrackCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.outcome = reader.int32();
                                break;
                            }
                        case 4: {
                                message.details = $root.infinitusai.be.FastTrackProcessCallDetails.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProcessFastTrackCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProcessFastTrackCallRequest} ProcessFastTrackCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessFastTrackCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProcessFastTrackCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProcessFastTrackCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.outcome != null && message.hasOwnProperty("outcome"))
                        switch (message.outcome) {
                        default:
                            return "outcome: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.details != null && message.hasOwnProperty("details")) {
                        var error = $root.infinitusai.be.FastTrackProcessCallDetails.verify(message.details);
                        if (error)
                            return "details." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ProcessFastTrackCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProcessFastTrackCallRequest} ProcessFastTrackCallRequest
                 */
                ProcessFastTrackCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProcessFastTrackCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.ProcessFastTrackCallRequest();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    switch (object.outcome) {
                    default:
                        if (typeof object.outcome === "number") {
                            message.outcome = object.outcome;
                            break;
                        }
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_UNKNOWN":
                    case 0:
                        message.outcome = 0;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_SUCCESS":
                    case 1:
                        message.outcome = 1;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_FAILURE":
                    case 2:
                        message.outcome = 2;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_FAILURE_AND_REDIAL":
                    case 3:
                        message.outcome = 3;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_FAILURE_AND_SEND_TO_QUEUE":
                    case 4:
                        message.outcome = 4;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_NOT_PROCESSED_YET":
                    case 5:
                        message.outcome = 5;
                        break;
                    }
                    if (object.details != null) {
                        if (typeof object.details !== "object")
                            throw TypeError(".infinitusai.be.ProcessFastTrackCallRequest.details: object expected");
                        message.details = $root.infinitusai.be.FastTrackProcessCallDetails.fromObject(object.details);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProcessFastTrackCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {infinitusai.be.ProcessFastTrackCallRequest} message ProcessFastTrackCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProcessFastTrackCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callUuid = "";
                        object.taskUuid = "";
                        object.outcome = options.enums === String ? "FAST_TRACK_CALL_OUTCOME_UNKNOWN" : 0;
                        object.details = null;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.outcome != null && message.hasOwnProperty("outcome"))
                        object.outcome = options.enums === String ? $root.infinitusai.be.FastTrackCallOutcome[message.outcome] === undefined ? message.outcome : $root.infinitusai.be.FastTrackCallOutcome[message.outcome] : message.outcome;
                    if (message.details != null && message.hasOwnProperty("details"))
                        object.details = $root.infinitusai.be.FastTrackProcessCallDetails.toObject(message.details, options);
                    return object;
                };
    
                /**
                 * Converts this ProcessFastTrackCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProcessFastTrackCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProcessFastTrackCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProcessFastTrackCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProcessFastTrackCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProcessFastTrackCallRequest";
                };
    
                return ProcessFastTrackCallRequest;
            })();
    
            be.ProcessFastTrackCallResponse = (function() {
    
                /**
                 * Properties of a ProcessFastTrackCallResponse.
                 * @memberof infinitusai.be
                 * @interface IProcessFastTrackCallResponse
                 */
    
                /**
                 * Constructs a new ProcessFastTrackCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProcessFastTrackCallResponse.
                 * @implements IProcessFastTrackCallResponse
                 * @constructor
                 * @param {infinitusai.be.IProcessFastTrackCallResponse=} [properties] Properties to set
                 */
                function ProcessFastTrackCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ProcessFastTrackCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {infinitusai.be.IProcessFastTrackCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ProcessFastTrackCallResponse} ProcessFastTrackCallResponse instance
                 */
                ProcessFastTrackCallResponse.create = function create(properties) {
                    return new ProcessFastTrackCallResponse(properties);
                };
    
                /**
                 * Encodes the specified ProcessFastTrackCallResponse message. Does not implicitly {@link infinitusai.be.ProcessFastTrackCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {infinitusai.be.IProcessFastTrackCallResponse} message ProcessFastTrackCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessFastTrackCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ProcessFastTrackCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.ProcessFastTrackCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {infinitusai.be.IProcessFastTrackCallResponse} message ProcessFastTrackCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessFastTrackCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProcessFastTrackCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProcessFastTrackCallResponse} ProcessFastTrackCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessFastTrackCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProcessFastTrackCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProcessFastTrackCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProcessFastTrackCallResponse} ProcessFastTrackCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessFastTrackCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProcessFastTrackCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProcessFastTrackCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ProcessFastTrackCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProcessFastTrackCallResponse} ProcessFastTrackCallResponse
                 */
                ProcessFastTrackCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProcessFastTrackCallResponse)
                        return object;
                    return new $root.infinitusai.be.ProcessFastTrackCallResponse();
                };
    
                /**
                 * Creates a plain object from a ProcessFastTrackCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {infinitusai.be.ProcessFastTrackCallResponse} message ProcessFastTrackCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProcessFastTrackCallResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ProcessFastTrackCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProcessFastTrackCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProcessFastTrackCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProcessFastTrackCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProcessFastTrackCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProcessFastTrackCallResponse";
                };
    
                return ProcessFastTrackCallResponse;
            })();
    
            be.SoftphoneActivityMetricsRequest = (function() {
    
                /**
                 * Properties of a SoftphoneActivityMetricsRequest.
                 * @memberof infinitusai.be
                 * @interface ISoftphoneActivityMetricsRequest
                 * @property {string|null} [startTimeIsoTimestamp] SoftphoneActivityMetricsRequest startTimeIsoTimestamp
                 * @property {string|null} [endTimeIsoTimestamp] SoftphoneActivityMetricsRequest endTimeIsoTimestamp
                 */
    
                /**
                 * Constructs a new SoftphoneActivityMetricsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SoftphoneActivityMetricsRequest.
                 * @implements ISoftphoneActivityMetricsRequest
                 * @constructor
                 * @param {infinitusai.be.ISoftphoneActivityMetricsRequest=} [properties] Properties to set
                 */
                function SoftphoneActivityMetricsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SoftphoneActivityMetricsRequest startTimeIsoTimestamp.
                 * @member {string} startTimeIsoTimestamp
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @instance
                 */
                SoftphoneActivityMetricsRequest.prototype.startTimeIsoTimestamp = "";
    
                /**
                 * SoftphoneActivityMetricsRequest endTimeIsoTimestamp.
                 * @member {string} endTimeIsoTimestamp
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @instance
                 */
                SoftphoneActivityMetricsRequest.prototype.endTimeIsoTimestamp = "";
    
                /**
                 * Creates a new SoftphoneActivityMetricsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {infinitusai.be.ISoftphoneActivityMetricsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SoftphoneActivityMetricsRequest} SoftphoneActivityMetricsRequest instance
                 */
                SoftphoneActivityMetricsRequest.create = function create(properties) {
                    return new SoftphoneActivityMetricsRequest(properties);
                };
    
                /**
                 * Encodes the specified SoftphoneActivityMetricsRequest message. Does not implicitly {@link infinitusai.be.SoftphoneActivityMetricsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {infinitusai.be.ISoftphoneActivityMetricsRequest} message SoftphoneActivityMetricsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneActivityMetricsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.startTimeIsoTimestamp != null && Object.hasOwnProperty.call(message, "startTimeIsoTimestamp"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.startTimeIsoTimestamp);
                    if (message.endTimeIsoTimestamp != null && Object.hasOwnProperty.call(message, "endTimeIsoTimestamp"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.endTimeIsoTimestamp);
                    return writer;
                };
    
                /**
                 * Encodes the specified SoftphoneActivityMetricsRequest message, length delimited. Does not implicitly {@link infinitusai.be.SoftphoneActivityMetricsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {infinitusai.be.ISoftphoneActivityMetricsRequest} message SoftphoneActivityMetricsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneActivityMetricsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SoftphoneActivityMetricsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SoftphoneActivityMetricsRequest} SoftphoneActivityMetricsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneActivityMetricsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SoftphoneActivityMetricsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.startTimeIsoTimestamp = reader.string();
                                break;
                            }
                        case 2: {
                                message.endTimeIsoTimestamp = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SoftphoneActivityMetricsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SoftphoneActivityMetricsRequest} SoftphoneActivityMetricsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneActivityMetricsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SoftphoneActivityMetricsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SoftphoneActivityMetricsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.startTimeIsoTimestamp != null && message.hasOwnProperty("startTimeIsoTimestamp"))
                        if (!$util.isString(message.startTimeIsoTimestamp))
                            return "startTimeIsoTimestamp: string expected";
                    if (message.endTimeIsoTimestamp != null && message.hasOwnProperty("endTimeIsoTimestamp"))
                        if (!$util.isString(message.endTimeIsoTimestamp))
                            return "endTimeIsoTimestamp: string expected";
                    return null;
                };
    
                /**
                 * Creates a SoftphoneActivityMetricsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SoftphoneActivityMetricsRequest} SoftphoneActivityMetricsRequest
                 */
                SoftphoneActivityMetricsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SoftphoneActivityMetricsRequest)
                        return object;
                    var message = new $root.infinitusai.be.SoftphoneActivityMetricsRequest();
                    if (object.startTimeIsoTimestamp != null)
                        message.startTimeIsoTimestamp = String(object.startTimeIsoTimestamp);
                    if (object.endTimeIsoTimestamp != null)
                        message.endTimeIsoTimestamp = String(object.endTimeIsoTimestamp);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SoftphoneActivityMetricsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {infinitusai.be.SoftphoneActivityMetricsRequest} message SoftphoneActivityMetricsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SoftphoneActivityMetricsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.startTimeIsoTimestamp = "";
                        object.endTimeIsoTimestamp = "";
                    }
                    if (message.startTimeIsoTimestamp != null && message.hasOwnProperty("startTimeIsoTimestamp"))
                        object.startTimeIsoTimestamp = message.startTimeIsoTimestamp;
                    if (message.endTimeIsoTimestamp != null && message.hasOwnProperty("endTimeIsoTimestamp"))
                        object.endTimeIsoTimestamp = message.endTimeIsoTimestamp;
                    return object;
                };
    
                /**
                 * Converts this SoftphoneActivityMetricsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SoftphoneActivityMetricsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SoftphoneActivityMetricsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SoftphoneActivityMetricsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SoftphoneActivityMetricsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SoftphoneActivityMetricsRequest";
                };
    
                return SoftphoneActivityMetricsRequest;
            })();
    
            be.SoftphoneActivityMetricsResponse = (function() {
    
                /**
                 * Properties of a SoftphoneActivityMetricsResponse.
                 * @memberof infinitusai.be
                 * @interface ISoftphoneActivityMetricsResponse
                 * @property {number|null} [callsCompleted] SoftphoneActivityMetricsResponse callsCompleted
                 * @property {number|null} [minutesSaved] SoftphoneActivityMetricsResponse minutesSaved
                 * @property {number|null} [patientsHelped] SoftphoneActivityMetricsResponse patientsHelped
                 */
    
                /**
                 * Constructs a new SoftphoneActivityMetricsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SoftphoneActivityMetricsResponse.
                 * @implements ISoftphoneActivityMetricsResponse
                 * @constructor
                 * @param {infinitusai.be.ISoftphoneActivityMetricsResponse=} [properties] Properties to set
                 */
                function SoftphoneActivityMetricsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SoftphoneActivityMetricsResponse callsCompleted.
                 * @member {number} callsCompleted
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @instance
                 */
                SoftphoneActivityMetricsResponse.prototype.callsCompleted = 0;
    
                /**
                 * SoftphoneActivityMetricsResponse minutesSaved.
                 * @member {number} minutesSaved
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @instance
                 */
                SoftphoneActivityMetricsResponse.prototype.minutesSaved = 0;
    
                /**
                 * SoftphoneActivityMetricsResponse patientsHelped.
                 * @member {number} patientsHelped
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @instance
                 */
                SoftphoneActivityMetricsResponse.prototype.patientsHelped = 0;
    
                /**
                 * Creates a new SoftphoneActivityMetricsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {infinitusai.be.ISoftphoneActivityMetricsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.SoftphoneActivityMetricsResponse} SoftphoneActivityMetricsResponse instance
                 */
                SoftphoneActivityMetricsResponse.create = function create(properties) {
                    return new SoftphoneActivityMetricsResponse(properties);
                };
    
                /**
                 * Encodes the specified SoftphoneActivityMetricsResponse message. Does not implicitly {@link infinitusai.be.SoftphoneActivityMetricsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {infinitusai.be.ISoftphoneActivityMetricsResponse} message SoftphoneActivityMetricsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneActivityMetricsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callsCompleted != null && Object.hasOwnProperty.call(message, "callsCompleted"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.callsCompleted);
                    if (message.minutesSaved != null && Object.hasOwnProperty.call(message, "minutesSaved"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.minutesSaved);
                    if (message.patientsHelped != null && Object.hasOwnProperty.call(message, "patientsHelped"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.patientsHelped);
                    return writer;
                };
    
                /**
                 * Encodes the specified SoftphoneActivityMetricsResponse message, length delimited. Does not implicitly {@link infinitusai.be.SoftphoneActivityMetricsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {infinitusai.be.ISoftphoneActivityMetricsResponse} message SoftphoneActivityMetricsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneActivityMetricsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SoftphoneActivityMetricsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SoftphoneActivityMetricsResponse} SoftphoneActivityMetricsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneActivityMetricsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SoftphoneActivityMetricsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callsCompleted = reader.int32();
                                break;
                            }
                        case 2: {
                                message.minutesSaved = reader.float();
                                break;
                            }
                        case 3: {
                                message.patientsHelped = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SoftphoneActivityMetricsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SoftphoneActivityMetricsResponse} SoftphoneActivityMetricsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneActivityMetricsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SoftphoneActivityMetricsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SoftphoneActivityMetricsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callsCompleted != null && message.hasOwnProperty("callsCompleted"))
                        if (!$util.isInteger(message.callsCompleted))
                            return "callsCompleted: integer expected";
                    if (message.minutesSaved != null && message.hasOwnProperty("minutesSaved"))
                        if (typeof message.minutesSaved !== "number")
                            return "minutesSaved: number expected";
                    if (message.patientsHelped != null && message.hasOwnProperty("patientsHelped"))
                        if (!$util.isInteger(message.patientsHelped))
                            return "patientsHelped: integer expected";
                    return null;
                };
    
                /**
                 * Creates a SoftphoneActivityMetricsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SoftphoneActivityMetricsResponse} SoftphoneActivityMetricsResponse
                 */
                SoftphoneActivityMetricsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SoftphoneActivityMetricsResponse)
                        return object;
                    var message = new $root.infinitusai.be.SoftphoneActivityMetricsResponse();
                    if (object.callsCompleted != null)
                        message.callsCompleted = object.callsCompleted | 0;
                    if (object.minutesSaved != null)
                        message.minutesSaved = Number(object.minutesSaved);
                    if (object.patientsHelped != null)
                        message.patientsHelped = object.patientsHelped | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a SoftphoneActivityMetricsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {infinitusai.be.SoftphoneActivityMetricsResponse} message SoftphoneActivityMetricsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SoftphoneActivityMetricsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callsCompleted = 0;
                        object.minutesSaved = 0;
                        object.patientsHelped = 0;
                    }
                    if (message.callsCompleted != null && message.hasOwnProperty("callsCompleted"))
                        object.callsCompleted = message.callsCompleted;
                    if (message.minutesSaved != null && message.hasOwnProperty("minutesSaved"))
                        object.minutesSaved = options.json && !isFinite(message.minutesSaved) ? String(message.minutesSaved) : message.minutesSaved;
                    if (message.patientsHelped != null && message.hasOwnProperty("patientsHelped"))
                        object.patientsHelped = message.patientsHelped;
                    return object;
                };
    
                /**
                 * Converts this SoftphoneActivityMetricsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SoftphoneActivityMetricsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SoftphoneActivityMetricsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SoftphoneActivityMetricsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SoftphoneActivityMetricsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SoftphoneActivityMetricsResponse";
                };
    
                return SoftphoneActivityMetricsResponse;
            })();
    
            be.UpdateMuteStatusRequest = (function() {
    
                /**
                 * Properties of an UpdateMuteStatusRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateMuteStatusRequest
                 * @property {string|null} [callUuid] UpdateMuteStatusRequest callUuid
                 * @property {string|null} [taskUuid] UpdateMuteStatusRequest taskUuid
                 * @property {boolean|null} [isMuted] UpdateMuteStatusRequest isMuted
                 * @property {boolean|null} [isDeparting] UpdateMuteStatusRequest isDeparting
                 */
    
                /**
                 * Constructs a new UpdateMuteStatusRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateMuteStatusRequest.
                 * @implements IUpdateMuteStatusRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateMuteStatusRequest=} [properties] Properties to set
                 */
                function UpdateMuteStatusRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateMuteStatusRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @instance
                 */
                UpdateMuteStatusRequest.prototype.callUuid = "";
    
                /**
                 * UpdateMuteStatusRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @instance
                 */
                UpdateMuteStatusRequest.prototype.taskUuid = "";
    
                /**
                 * UpdateMuteStatusRequest isMuted.
                 * @member {boolean} isMuted
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @instance
                 */
                UpdateMuteStatusRequest.prototype.isMuted = false;
    
                /**
                 * UpdateMuteStatusRequest isDeparting.
                 * @member {boolean} isDeparting
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @instance
                 */
                UpdateMuteStatusRequest.prototype.isDeparting = false;
    
                /**
                 * Creates a new UpdateMuteStatusRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {infinitusai.be.IUpdateMuteStatusRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateMuteStatusRequest} UpdateMuteStatusRequest instance
                 */
                UpdateMuteStatusRequest.create = function create(properties) {
                    return new UpdateMuteStatusRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateMuteStatusRequest message. Does not implicitly {@link infinitusai.be.UpdateMuteStatusRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {infinitusai.be.IUpdateMuteStatusRequest} message UpdateMuteStatusRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateMuteStatusRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.isMuted != null && Object.hasOwnProperty.call(message, "isMuted"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isMuted);
                    if (message.isDeparting != null && Object.hasOwnProperty.call(message, "isDeparting"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeparting);
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateMuteStatusRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateMuteStatusRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {infinitusai.be.IUpdateMuteStatusRequest} message UpdateMuteStatusRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateMuteStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateMuteStatusRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateMuteStatusRequest} UpdateMuteStatusRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateMuteStatusRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateMuteStatusRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.isMuted = reader.bool();
                                break;
                            }
                        case 4: {
                                message.isDeparting = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateMuteStatusRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateMuteStatusRequest} UpdateMuteStatusRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateMuteStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateMuteStatusRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateMuteStatusRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.isMuted != null && message.hasOwnProperty("isMuted"))
                        if (typeof message.isMuted !== "boolean")
                            return "isMuted: boolean expected";
                    if (message.isDeparting != null && message.hasOwnProperty("isDeparting"))
                        if (typeof message.isDeparting !== "boolean")
                            return "isDeparting: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateMuteStatusRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateMuteStatusRequest} UpdateMuteStatusRequest
                 */
                UpdateMuteStatusRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateMuteStatusRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateMuteStatusRequest();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.isMuted != null)
                        message.isMuted = Boolean(object.isMuted);
                    if (object.isDeparting != null)
                        message.isDeparting = Boolean(object.isDeparting);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateMuteStatusRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {infinitusai.be.UpdateMuteStatusRequest} message UpdateMuteStatusRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateMuteStatusRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callUuid = "";
                        object.taskUuid = "";
                        object.isMuted = false;
                        object.isDeparting = false;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.isMuted != null && message.hasOwnProperty("isMuted"))
                        object.isMuted = message.isMuted;
                    if (message.isDeparting != null && message.hasOwnProperty("isDeparting"))
                        object.isDeparting = message.isDeparting;
                    return object;
                };
    
                /**
                 * Converts this UpdateMuteStatusRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateMuteStatusRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateMuteStatusRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateMuteStatusRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateMuteStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateMuteStatusRequest";
                };
    
                return UpdateMuteStatusRequest;
            })();
    
            be.UpdateMuteStatusResponse = (function() {
    
                /**
                 * Properties of an UpdateMuteStatusResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateMuteStatusResponse
                 */
    
                /**
                 * Constructs a new UpdateMuteStatusResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateMuteStatusResponse.
                 * @implements IUpdateMuteStatusResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateMuteStatusResponse=} [properties] Properties to set
                 */
                function UpdateMuteStatusResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpdateMuteStatusResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {infinitusai.be.IUpdateMuteStatusResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateMuteStatusResponse} UpdateMuteStatusResponse instance
                 */
                UpdateMuteStatusResponse.create = function create(properties) {
                    return new UpdateMuteStatusResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateMuteStatusResponse message. Does not implicitly {@link infinitusai.be.UpdateMuteStatusResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {infinitusai.be.IUpdateMuteStatusResponse} message UpdateMuteStatusResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateMuteStatusResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateMuteStatusResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateMuteStatusResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {infinitusai.be.IUpdateMuteStatusResponse} message UpdateMuteStatusResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateMuteStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateMuteStatusResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateMuteStatusResponse} UpdateMuteStatusResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateMuteStatusResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateMuteStatusResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateMuteStatusResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateMuteStatusResponse} UpdateMuteStatusResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateMuteStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateMuteStatusResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateMuteStatusResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateMuteStatusResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateMuteStatusResponse} UpdateMuteStatusResponse
                 */
                UpdateMuteStatusResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateMuteStatusResponse)
                        return object;
                    return new $root.infinitusai.be.UpdateMuteStatusResponse();
                };
    
                /**
                 * Creates a plain object from an UpdateMuteStatusResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {infinitusai.be.UpdateMuteStatusResponse} message UpdateMuteStatusResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateMuteStatusResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpdateMuteStatusResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateMuteStatusResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateMuteStatusResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateMuteStatusResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateMuteStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateMuteStatusResponse";
                };
    
                return UpdateMuteStatusResponse;
            })();
    
            be.ClaimCallRequest = (function() {
    
                /**
                 * Properties of a ClaimCallRequest.
                 * @memberof infinitusai.be
                 * @interface IClaimCallRequest
                 * @property {string|null} [callUuid] ClaimCallRequest callUuid
                 * @property {string|null} [taskUuid] ClaimCallRequest taskUuid
                 */
    
                /**
                 * Constructs a new ClaimCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ClaimCallRequest.
                 * @implements IClaimCallRequest
                 * @constructor
                 * @param {infinitusai.be.IClaimCallRequest=} [properties] Properties to set
                 */
                function ClaimCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ClaimCallRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @instance
                 */
                ClaimCallRequest.prototype.callUuid = "";
    
                /**
                 * ClaimCallRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @instance
                 */
                ClaimCallRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new ClaimCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {infinitusai.be.IClaimCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ClaimCallRequest} ClaimCallRequest instance
                 */
                ClaimCallRequest.create = function create(properties) {
                    return new ClaimCallRequest(properties);
                };
    
                /**
                 * Encodes the specified ClaimCallRequest message. Does not implicitly {@link infinitusai.be.ClaimCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {infinitusai.be.IClaimCallRequest} message ClaimCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified ClaimCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.ClaimCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {infinitusai.be.IClaimCallRequest} message ClaimCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ClaimCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ClaimCallRequest} ClaimCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ClaimCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ClaimCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ClaimCallRequest} ClaimCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ClaimCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClaimCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a ClaimCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ClaimCallRequest} ClaimCallRequest
                 */
                ClaimCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ClaimCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.ClaimCallRequest();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ClaimCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {infinitusai.be.ClaimCallRequest} message ClaimCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClaimCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callUuid = "";
                        object.taskUuid = "";
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this ClaimCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClaimCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ClaimCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ClaimCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClaimCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ClaimCallRequest";
                };
    
                return ClaimCallRequest;
            })();
    
            be.ClaimCallResponse = (function() {
    
                /**
                 * Properties of a ClaimCallResponse.
                 * @memberof infinitusai.be
                 * @interface IClaimCallResponse
                 */
    
                /**
                 * Constructs a new ClaimCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ClaimCallResponse.
                 * @implements IClaimCallResponse
                 * @constructor
                 * @param {infinitusai.be.IClaimCallResponse=} [properties] Properties to set
                 */
                function ClaimCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ClaimCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {infinitusai.be.IClaimCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ClaimCallResponse} ClaimCallResponse instance
                 */
                ClaimCallResponse.create = function create(properties) {
                    return new ClaimCallResponse(properties);
                };
    
                /**
                 * Encodes the specified ClaimCallResponse message. Does not implicitly {@link infinitusai.be.ClaimCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {infinitusai.be.IClaimCallResponse} message ClaimCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ClaimCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.ClaimCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {infinitusai.be.IClaimCallResponse} message ClaimCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ClaimCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ClaimCallResponse} ClaimCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ClaimCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ClaimCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ClaimCallResponse} ClaimCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ClaimCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClaimCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ClaimCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ClaimCallResponse} ClaimCallResponse
                 */
                ClaimCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ClaimCallResponse)
                        return object;
                    return new $root.infinitusai.be.ClaimCallResponse();
                };
    
                /**
                 * Creates a plain object from a ClaimCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {infinitusai.be.ClaimCallResponse} message ClaimCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClaimCallResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ClaimCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClaimCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ClaimCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ClaimCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClaimCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ClaimCallResponse";
                };
    
                return ClaimCallResponse;
            })();
    
            be.FastTrackCallDoc = (function() {
    
                /**
                 * Properties of a FastTrackCallDoc.
                 * @memberof infinitusai.be
                 * @interface IFastTrackCallDoc
                 * @property {infinitusai.be.FastTrackCallDoc.CallPhasePosition|null} [callPhasePosition] FastTrackCallDoc callPhasePosition
                 */
    
                /**
                 * Constructs a new FastTrackCallDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FastTrackCallDoc.
                 * @implements IFastTrackCallDoc
                 * @constructor
                 * @param {infinitusai.be.IFastTrackCallDoc=} [properties] Properties to set
                 */
                function FastTrackCallDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FastTrackCallDoc callPhasePosition.
                 * @member {infinitusai.be.FastTrackCallDoc.CallPhasePosition} callPhasePosition
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @instance
                 */
                FastTrackCallDoc.prototype.callPhasePosition = 0;
    
                /**
                 * Creates a new FastTrackCallDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCallDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.FastTrackCallDoc} FastTrackCallDoc instance
                 */
                FastTrackCallDoc.create = function create(properties) {
                    return new FastTrackCallDoc(properties);
                };
    
                /**
                 * Encodes the specified FastTrackCallDoc message. Does not implicitly {@link infinitusai.be.FastTrackCallDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCallDoc} message FastTrackCallDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackCallDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callPhasePosition != null && Object.hasOwnProperty.call(message, "callPhasePosition"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.callPhasePosition);
                    return writer;
                };
    
                /**
                 * Encodes the specified FastTrackCallDoc message, length delimited. Does not implicitly {@link infinitusai.be.FastTrackCallDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCallDoc} message FastTrackCallDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackCallDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FastTrackCallDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FastTrackCallDoc} FastTrackCallDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackCallDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FastTrackCallDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callPhasePosition = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FastTrackCallDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FastTrackCallDoc} FastTrackCallDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackCallDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FastTrackCallDoc message.
                 * @function verify
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastTrackCallDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callPhasePosition != null && message.hasOwnProperty("callPhasePosition"))
                        switch (message.callPhasePosition) {
                        default:
                            return "callPhasePosition: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a FastTrackCallDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FastTrackCallDoc} FastTrackCallDoc
                 */
                FastTrackCallDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FastTrackCallDoc)
                        return object;
                    var message = new $root.infinitusai.be.FastTrackCallDoc();
                    switch (object.callPhasePosition) {
                    default:
                        if (typeof object.callPhasePosition === "number") {
                            message.callPhasePosition = object.callPhasePosition;
                            break;
                        }
                        break;
                    case "CALL_PHASE_POSITION_UNKNOWN":
                    case 0:
                        message.callPhasePosition = 0;
                        break;
                    case "CALL_PHASE_POSITION_IVR":
                    case 1:
                        message.callPhasePosition = 1;
                        break;
                    case "CALL_PHASE_POSITION_ON_HOLD":
                    case 2:
                        message.callPhasePosition = 2;
                        break;
                    case "CALL_PHASE_POSITION_AGENT_READY":
                    case 3:
                        message.callPhasePosition = 3;
                        break;
                    case "CALL_PHASE_POSITION_ON_CALL":
                    case 4:
                        message.callPhasePosition = 4;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FastTrackCallDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {infinitusai.be.FastTrackCallDoc} message FastTrackCallDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastTrackCallDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.callPhasePosition = options.enums === String ? "CALL_PHASE_POSITION_UNKNOWN" : 0;
                    if (message.callPhasePosition != null && message.hasOwnProperty("callPhasePosition"))
                        object.callPhasePosition = options.enums === String ? $root.infinitusai.be.FastTrackCallDoc.CallPhasePosition[message.callPhasePosition] === undefined ? message.callPhasePosition : $root.infinitusai.be.FastTrackCallDoc.CallPhasePosition[message.callPhasePosition] : message.callPhasePosition;
                    return object;
                };
    
                /**
                 * Converts this FastTrackCallDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastTrackCallDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FastTrackCallDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FastTrackCallDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FastTrackCallDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FastTrackCallDoc";
                };
    
                /**
                 * CallPhasePosition enum.
                 * @name infinitusai.be.FastTrackCallDoc.CallPhasePosition
                 * @enum {number}
                 * @property {number} CALL_PHASE_POSITION_UNKNOWN=0 CALL_PHASE_POSITION_UNKNOWN value
                 * @property {number} CALL_PHASE_POSITION_IVR=1 CALL_PHASE_POSITION_IVR value
                 * @property {number} CALL_PHASE_POSITION_ON_HOLD=2 CALL_PHASE_POSITION_ON_HOLD value
                 * @property {number} CALL_PHASE_POSITION_AGENT_READY=3 CALL_PHASE_POSITION_AGENT_READY value
                 * @property {number} CALL_PHASE_POSITION_ON_CALL=4 CALL_PHASE_POSITION_ON_CALL value
                 */
                FastTrackCallDoc.CallPhasePosition = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CALL_PHASE_POSITION_UNKNOWN"] = 0;
                    values[valuesById[1] = "CALL_PHASE_POSITION_IVR"] = 1;
                    values[valuesById[2] = "CALL_PHASE_POSITION_ON_HOLD"] = 2;
                    values[valuesById[3] = "CALL_PHASE_POSITION_AGENT_READY"] = 3;
                    values[valuesById[4] = "CALL_PHASE_POSITION_ON_CALL"] = 4;
                    return values;
                })();
    
                return FastTrackCallDoc;
            })();
    
            be.GetCallsHistoryRequest = (function() {
    
                /**
                 * Properties of a GetCallsHistoryRequest.
                 * @memberof infinitusai.be
                 * @interface IGetCallsHistoryRequest
                 * @property {number|null} [limit] GetCallsHistoryRequest limit
                 * @property {number|null} [offset] GetCallsHistoryRequest offset
                 * @property {Array.<infinitusai.be.FastTrackCallOutcome>|null} [callOutcomes] GetCallsHistoryRequest callOutcomes
                 */
    
                /**
                 * Constructs a new GetCallsHistoryRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCallsHistoryRequest.
                 * @implements IGetCallsHistoryRequest
                 * @constructor
                 * @param {infinitusai.be.IGetCallsHistoryRequest=} [properties] Properties to set
                 */
                function GetCallsHistoryRequest(properties) {
                    this.callOutcomes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCallsHistoryRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @instance
                 */
                GetCallsHistoryRequest.prototype.limit = 0;
    
                /**
                 * GetCallsHistoryRequest offset.
                 * @member {number} offset
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @instance
                 */
                GetCallsHistoryRequest.prototype.offset = 0;
    
                /**
                 * GetCallsHistoryRequest callOutcomes.
                 * @member {Array.<infinitusai.be.FastTrackCallOutcome>} callOutcomes
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @instance
                 */
                GetCallsHistoryRequest.prototype.callOutcomes = $util.emptyArray;
    
                /**
                 * Creates a new GetCallsHistoryRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {infinitusai.be.IGetCallsHistoryRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCallsHistoryRequest} GetCallsHistoryRequest instance
                 */
                GetCallsHistoryRequest.create = function create(properties) {
                    return new GetCallsHistoryRequest(properties);
                };
    
                /**
                 * Encodes the specified GetCallsHistoryRequest message. Does not implicitly {@link infinitusai.be.GetCallsHistoryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {infinitusai.be.IGetCallsHistoryRequest} message GetCallsHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallsHistoryRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.offset);
                    if (message.callOutcomes != null && message.callOutcomes.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.callOutcomes.length; ++i)
                            writer.int32(message.callOutcomes[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCallsHistoryRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetCallsHistoryRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {infinitusai.be.IGetCallsHistoryRequest} message GetCallsHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallsHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCallsHistoryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCallsHistoryRequest} GetCallsHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallsHistoryRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCallsHistoryRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.limit = reader.int32();
                                break;
                            }
                        case 2: {
                                message.offset = reader.int32();
                                break;
                            }
                        case 3: {
                                if (!(message.callOutcomes && message.callOutcomes.length))
                                    message.callOutcomes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.callOutcomes.push(reader.int32());
                                } else
                                    message.callOutcomes.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCallsHistoryRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCallsHistoryRequest} GetCallsHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallsHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCallsHistoryRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCallsHistoryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.callOutcomes != null && message.hasOwnProperty("callOutcomes")) {
                        if (!Array.isArray(message.callOutcomes))
                            return "callOutcomes: array expected";
                        for (var i = 0; i < message.callOutcomes.length; ++i)
                            switch (message.callOutcomes[i]) {
                            default:
                                return "callOutcomes: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetCallsHistoryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCallsHistoryRequest} GetCallsHistoryRequest
                 */
                GetCallsHistoryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCallsHistoryRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetCallsHistoryRequest();
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    if (object.offset != null)
                        message.offset = object.offset | 0;
                    if (object.callOutcomes) {
                        if (!Array.isArray(object.callOutcomes))
                            throw TypeError(".infinitusai.be.GetCallsHistoryRequest.callOutcomes: array expected");
                        message.callOutcomes = [];
                        for (var i = 0; i < object.callOutcomes.length; ++i)
                            switch (object.callOutcomes[i]) {
                            default:
                                if (typeof object.callOutcomes[i] === "number") {
                                    message.callOutcomes[i] = object.callOutcomes[i];
                                    break;
                                }
                            case "FAST_TRACK_CALL_OUTCOME_UNKNOWN":
                            case 0:
                                message.callOutcomes[i] = 0;
                                break;
                            case "FAST_TRACK_CALL_OUTCOME_SUCCESS":
                            case 1:
                                message.callOutcomes[i] = 1;
                                break;
                            case "FAST_TRACK_CALL_OUTCOME_FAILURE":
                            case 2:
                                message.callOutcomes[i] = 2;
                                break;
                            case "FAST_TRACK_CALL_OUTCOME_FAILURE_AND_REDIAL":
                            case 3:
                                message.callOutcomes[i] = 3;
                                break;
                            case "FAST_TRACK_CALL_OUTCOME_FAILURE_AND_SEND_TO_QUEUE":
                            case 4:
                                message.callOutcomes[i] = 4;
                                break;
                            case "FAST_TRACK_CALL_OUTCOME_NOT_PROCESSED_YET":
                            case 5:
                                message.callOutcomes[i] = 5;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCallsHistoryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {infinitusai.be.GetCallsHistoryRequest} message GetCallsHistoryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCallsHistoryRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.callOutcomes = [];
                    if (options.defaults) {
                        object.limit = 0;
                        object.offset = 0;
                    }
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.callOutcomes && message.callOutcomes.length) {
                        object.callOutcomes = [];
                        for (var j = 0; j < message.callOutcomes.length; ++j)
                            object.callOutcomes[j] = options.enums === String ? $root.infinitusai.be.FastTrackCallOutcome[message.callOutcomes[j]] === undefined ? message.callOutcomes[j] : $root.infinitusai.be.FastTrackCallOutcome[message.callOutcomes[j]] : message.callOutcomes[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetCallsHistoryRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCallsHistoryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCallsHistoryRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCallsHistoryRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCallsHistoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCallsHistoryRequest";
                };
    
                return GetCallsHistoryRequest;
            })();
    
            be.FastTrackCallInfoDoc = (function() {
    
                /**
                 * Properties of a FastTrackCallInfoDoc.
                 * @memberof infinitusai.be
                 * @interface IFastTrackCallInfoDoc
                 * @property {string|null} [callUuid] FastTrackCallInfoDoc callUuid
                 * @property {string|null} [taskUuid] FastTrackCallInfoDoc taskUuid
                 * @property {string|null} [orgUuid] FastTrackCallInfoDoc orgUuid
                 * @property {infinitusai.be.IUserInfo|null} [callCreator] FastTrackCallInfoDoc callCreator
                 * @property {number|Long|null} [createTimeMillis] FastTrackCallInfoDoc createTimeMillis
                 * @property {number|Long|null} [audioStartMillis] FastTrackCallInfoDoc audioStartMillis
                 * @property {number|Long|null} [audioEndMillis] FastTrackCallInfoDoc audioEndMillis
                 * @property {string|null} [audioFilePath] FastTrackCallInfoDoc audioFilePath
                 * @property {string|null} [phoneNumber] FastTrackCallInfoDoc phoneNumber
                 * @property {infinitusai.be.IUserInfo|null} [callAssignee] FastTrackCallInfoDoc callAssignee
                 * @property {infinitusai.be.IFastTrackCallDocAdditionalData|null} [additionalData] FastTrackCallInfoDoc additionalData
                 */
    
                /**
                 * Constructs a new FastTrackCallInfoDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FastTrackCallInfoDoc.
                 * @implements IFastTrackCallInfoDoc
                 * @constructor
                 * @param {infinitusai.be.IFastTrackCallInfoDoc=} [properties] Properties to set
                 */
                function FastTrackCallInfoDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FastTrackCallInfoDoc callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.callUuid = "";
    
                /**
                 * FastTrackCallInfoDoc taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.taskUuid = "";
    
                /**
                 * FastTrackCallInfoDoc orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.orgUuid = "";
    
                /**
                 * FastTrackCallInfoDoc callCreator.
                 * @member {infinitusai.be.IUserInfo|null|undefined} callCreator
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.callCreator = null;
    
                /**
                 * FastTrackCallInfoDoc createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * FastTrackCallInfoDoc audioStartMillis.
                 * @member {number|Long} audioStartMillis
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.audioStartMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * FastTrackCallInfoDoc audioEndMillis.
                 * @member {number|Long} audioEndMillis
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.audioEndMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * FastTrackCallInfoDoc audioFilePath.
                 * @member {string} audioFilePath
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.audioFilePath = "";
    
                /**
                 * FastTrackCallInfoDoc phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.phoneNumber = "";
    
                /**
                 * FastTrackCallInfoDoc callAssignee.
                 * @member {infinitusai.be.IUserInfo|null|undefined} callAssignee
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.callAssignee = null;
    
                /**
                 * FastTrackCallInfoDoc additionalData.
                 * @member {infinitusai.be.IFastTrackCallDocAdditionalData|null|undefined} additionalData
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 */
                FastTrackCallInfoDoc.prototype.additionalData = null;
    
                /**
                 * Creates a new FastTrackCallInfoDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCallInfoDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.FastTrackCallInfoDoc} FastTrackCallInfoDoc instance
                 */
                FastTrackCallInfoDoc.create = function create(properties) {
                    return new FastTrackCallInfoDoc(properties);
                };
    
                /**
                 * Encodes the specified FastTrackCallInfoDoc message. Does not implicitly {@link infinitusai.be.FastTrackCallInfoDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCallInfoDoc} message FastTrackCallInfoDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackCallInfoDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.callCreator != null && Object.hasOwnProperty.call(message, "callCreator"))
                        $root.infinitusai.be.UserInfo.encode(message.callCreator, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createTimeMillis);
                    if (message.audioStartMillis != null && Object.hasOwnProperty.call(message, "audioStartMillis"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.audioStartMillis);
                    if (message.audioEndMillis != null && Object.hasOwnProperty.call(message, "audioEndMillis"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.audioEndMillis);
                    if (message.audioFilePath != null && Object.hasOwnProperty.call(message, "audioFilePath"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.audioFilePath);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.phoneNumber);
                    if (message.callAssignee != null && Object.hasOwnProperty.call(message, "callAssignee"))
                        $root.infinitusai.be.UserInfo.encode(message.callAssignee, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.additionalData != null && Object.hasOwnProperty.call(message, "additionalData"))
                        $root.infinitusai.be.FastTrackCallDocAdditionalData.encode(message.additionalData, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FastTrackCallInfoDoc message, length delimited. Does not implicitly {@link infinitusai.be.FastTrackCallInfoDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCallInfoDoc} message FastTrackCallInfoDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackCallInfoDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FastTrackCallInfoDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FastTrackCallInfoDoc} FastTrackCallInfoDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackCallInfoDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FastTrackCallInfoDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.callCreator = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 6: {
                                message.audioStartMillis = reader.int64();
                                break;
                            }
                        case 7: {
                                message.audioEndMillis = reader.int64();
                                break;
                            }
                        case 8: {
                                message.audioFilePath = reader.string();
                                break;
                            }
                        case 9: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 10: {
                                message.callAssignee = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.additionalData = $root.infinitusai.be.FastTrackCallDocAdditionalData.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FastTrackCallInfoDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FastTrackCallInfoDoc} FastTrackCallInfoDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackCallInfoDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FastTrackCallInfoDoc message.
                 * @function verify
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastTrackCallInfoDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.callCreator != null && message.hasOwnProperty("callCreator")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.callCreator);
                        if (error)
                            return "callCreator." + error;
                    }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.audioStartMillis != null && message.hasOwnProperty("audioStartMillis"))
                        if (!$util.isInteger(message.audioStartMillis) && !(message.audioStartMillis && $util.isInteger(message.audioStartMillis.low) && $util.isInteger(message.audioStartMillis.high)))
                            return "audioStartMillis: integer|Long expected";
                    if (message.audioEndMillis != null && message.hasOwnProperty("audioEndMillis"))
                        if (!$util.isInteger(message.audioEndMillis) && !(message.audioEndMillis && $util.isInteger(message.audioEndMillis.low) && $util.isInteger(message.audioEndMillis.high)))
                            return "audioEndMillis: integer|Long expected";
                    if (message.audioFilePath != null && message.hasOwnProperty("audioFilePath"))
                        if (!$util.isString(message.audioFilePath))
                            return "audioFilePath: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.callAssignee != null && message.hasOwnProperty("callAssignee")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.callAssignee);
                        if (error)
                            return "callAssignee." + error;
                    }
                    if (message.additionalData != null && message.hasOwnProperty("additionalData")) {
                        var error = $root.infinitusai.be.FastTrackCallDocAdditionalData.verify(message.additionalData);
                        if (error)
                            return "additionalData." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FastTrackCallInfoDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FastTrackCallInfoDoc} FastTrackCallInfoDoc
                 */
                FastTrackCallInfoDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FastTrackCallInfoDoc)
                        return object;
                    var message = new $root.infinitusai.be.FastTrackCallInfoDoc();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.callCreator != null) {
                        if (typeof object.callCreator !== "object")
                            throw TypeError(".infinitusai.be.FastTrackCallInfoDoc.callCreator: object expected");
                        message.callCreator = $root.infinitusai.be.UserInfo.fromObject(object.callCreator);
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.audioStartMillis != null)
                        if ($util.Long)
                            (message.audioStartMillis = $util.Long.fromValue(object.audioStartMillis)).unsigned = false;
                        else if (typeof object.audioStartMillis === "string")
                            message.audioStartMillis = parseInt(object.audioStartMillis, 10);
                        else if (typeof object.audioStartMillis === "number")
                            message.audioStartMillis = object.audioStartMillis;
                        else if (typeof object.audioStartMillis === "object")
                            message.audioStartMillis = new $util.LongBits(object.audioStartMillis.low >>> 0, object.audioStartMillis.high >>> 0).toNumber();
                    if (object.audioEndMillis != null)
                        if ($util.Long)
                            (message.audioEndMillis = $util.Long.fromValue(object.audioEndMillis)).unsigned = false;
                        else if (typeof object.audioEndMillis === "string")
                            message.audioEndMillis = parseInt(object.audioEndMillis, 10);
                        else if (typeof object.audioEndMillis === "number")
                            message.audioEndMillis = object.audioEndMillis;
                        else if (typeof object.audioEndMillis === "object")
                            message.audioEndMillis = new $util.LongBits(object.audioEndMillis.low >>> 0, object.audioEndMillis.high >>> 0).toNumber();
                    if (object.audioFilePath != null)
                        message.audioFilePath = String(object.audioFilePath);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.callAssignee != null) {
                        if (typeof object.callAssignee !== "object")
                            throw TypeError(".infinitusai.be.FastTrackCallInfoDoc.callAssignee: object expected");
                        message.callAssignee = $root.infinitusai.be.UserInfo.fromObject(object.callAssignee);
                    }
                    if (object.additionalData != null) {
                        if (typeof object.additionalData !== "object")
                            throw TypeError(".infinitusai.be.FastTrackCallInfoDoc.additionalData: object expected");
                        message.additionalData = $root.infinitusai.be.FastTrackCallDocAdditionalData.fromObject(object.additionalData);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FastTrackCallInfoDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {infinitusai.be.FastTrackCallInfoDoc} message FastTrackCallInfoDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastTrackCallInfoDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callUuid = "";
                        object.taskUuid = "";
                        object.orgUuid = "";
                        object.callCreator = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.audioStartMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.audioStartMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.audioEndMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.audioEndMillis = options.longs === String ? "0" : 0;
                        object.audioFilePath = "";
                        object.phoneNumber = "";
                        object.callAssignee = null;
                        object.additionalData = null;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.callCreator != null && message.hasOwnProperty("callCreator"))
                        object.callCreator = $root.infinitusai.be.UserInfo.toObject(message.callCreator, options);
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.audioStartMillis != null && message.hasOwnProperty("audioStartMillis"))
                        if (typeof message.audioStartMillis === "number")
                            object.audioStartMillis = options.longs === String ? String(message.audioStartMillis) : message.audioStartMillis;
                        else
                            object.audioStartMillis = options.longs === String ? $util.Long.prototype.toString.call(message.audioStartMillis) : options.longs === Number ? new $util.LongBits(message.audioStartMillis.low >>> 0, message.audioStartMillis.high >>> 0).toNumber() : message.audioStartMillis;
                    if (message.audioEndMillis != null && message.hasOwnProperty("audioEndMillis"))
                        if (typeof message.audioEndMillis === "number")
                            object.audioEndMillis = options.longs === String ? String(message.audioEndMillis) : message.audioEndMillis;
                        else
                            object.audioEndMillis = options.longs === String ? $util.Long.prototype.toString.call(message.audioEndMillis) : options.longs === Number ? new $util.LongBits(message.audioEndMillis.low >>> 0, message.audioEndMillis.high >>> 0).toNumber() : message.audioEndMillis;
                    if (message.audioFilePath != null && message.hasOwnProperty("audioFilePath"))
                        object.audioFilePath = message.audioFilePath;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.callAssignee != null && message.hasOwnProperty("callAssignee"))
                        object.callAssignee = $root.infinitusai.be.UserInfo.toObject(message.callAssignee, options);
                    if (message.additionalData != null && message.hasOwnProperty("additionalData"))
                        object.additionalData = $root.infinitusai.be.FastTrackCallDocAdditionalData.toObject(message.additionalData, options);
                    return object;
                };
    
                /**
                 * Converts this FastTrackCallInfoDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastTrackCallInfoDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FastTrackCallInfoDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FastTrackCallInfoDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FastTrackCallInfoDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FastTrackCallInfoDoc";
                };
    
                return FastTrackCallInfoDoc;
            })();
    
            be.AdditionalTaskInfo = (function() {
    
                /**
                 * Properties of an AdditionalTaskInfo.
                 * @memberof infinitusai.be
                 * @interface IAdditionalTaskInfo
                 * @property {string|null} [externalTaskNotes] AdditionalTaskInfo externalTaskNotes
                 */
    
                /**
                 * Constructs a new AdditionalTaskInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AdditionalTaskInfo.
                 * @implements IAdditionalTaskInfo
                 * @constructor
                 * @param {infinitusai.be.IAdditionalTaskInfo=} [properties] Properties to set
                 */
                function AdditionalTaskInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AdditionalTaskInfo externalTaskNotes.
                 * @member {string} externalTaskNotes
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @instance
                 */
                AdditionalTaskInfo.prototype.externalTaskNotes = "";
    
                /**
                 * Creates a new AdditionalTaskInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {infinitusai.be.IAdditionalTaskInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.AdditionalTaskInfo} AdditionalTaskInfo instance
                 */
                AdditionalTaskInfo.create = function create(properties) {
                    return new AdditionalTaskInfo(properties);
                };
    
                /**
                 * Encodes the specified AdditionalTaskInfo message. Does not implicitly {@link infinitusai.be.AdditionalTaskInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {infinitusai.be.IAdditionalTaskInfo} message AdditionalTaskInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdditionalTaskInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.externalTaskNotes != null && Object.hasOwnProperty.call(message, "externalTaskNotes"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalTaskNotes);
                    return writer;
                };
    
                /**
                 * Encodes the specified AdditionalTaskInfo message, length delimited. Does not implicitly {@link infinitusai.be.AdditionalTaskInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {infinitusai.be.IAdditionalTaskInfo} message AdditionalTaskInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdditionalTaskInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AdditionalTaskInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AdditionalTaskInfo} AdditionalTaskInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdditionalTaskInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AdditionalTaskInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.externalTaskNotes = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AdditionalTaskInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AdditionalTaskInfo} AdditionalTaskInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdditionalTaskInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AdditionalTaskInfo message.
                 * @function verify
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AdditionalTaskInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.externalTaskNotes != null && message.hasOwnProperty("externalTaskNotes"))
                        if (!$util.isString(message.externalTaskNotes))
                            return "externalTaskNotes: string expected";
                    return null;
                };
    
                /**
                 * Creates an AdditionalTaskInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AdditionalTaskInfo} AdditionalTaskInfo
                 */
                AdditionalTaskInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AdditionalTaskInfo)
                        return object;
                    var message = new $root.infinitusai.be.AdditionalTaskInfo();
                    if (object.externalTaskNotes != null)
                        message.externalTaskNotes = String(object.externalTaskNotes);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AdditionalTaskInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {infinitusai.be.AdditionalTaskInfo} message AdditionalTaskInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AdditionalTaskInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.externalTaskNotes = "";
                    if (message.externalTaskNotes != null && message.hasOwnProperty("externalTaskNotes"))
                        object.externalTaskNotes = message.externalTaskNotes;
                    return object;
                };
    
                /**
                 * Converts this AdditionalTaskInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AdditionalTaskInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AdditionalTaskInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AdditionalTaskInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AdditionalTaskInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AdditionalTaskInfo";
                };
    
                return AdditionalTaskInfo;
            })();
    
            be.FastTrackCustomerCallDoc = (function() {
    
                /**
                 * Properties of a FastTrackCustomerCallDoc.
                 * @memberof infinitusai.be
                 * @interface IFastTrackCustomerCallDoc
                 * @property {string|null} [taskUuid] FastTrackCustomerCallDoc taskUuid
                 * @property {infinitusai.be.ICustomerTaskDoc|null} [customerTaskDoc] FastTrackCustomerCallDoc customerTaskDoc
                 * @property {infinitusai.be.IFastTrackCallInfoDoc|null} [fastTrackCallDoc] FastTrackCustomerCallDoc fastTrackCallDoc
                 * @property {infinitusai.be.IAdditionalTaskInfo|null} [additionalTaskInfo] FastTrackCustomerCallDoc additionalTaskInfo
                 */
    
                /**
                 * Constructs a new FastTrackCustomerCallDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FastTrackCustomerCallDoc.
                 * @implements IFastTrackCustomerCallDoc
                 * @constructor
                 * @param {infinitusai.be.IFastTrackCustomerCallDoc=} [properties] Properties to set
                 */
                function FastTrackCustomerCallDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FastTrackCustomerCallDoc taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @instance
                 */
                FastTrackCustomerCallDoc.prototype.taskUuid = "";
    
                /**
                 * FastTrackCustomerCallDoc customerTaskDoc.
                 * @member {infinitusai.be.ICustomerTaskDoc|null|undefined} customerTaskDoc
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @instance
                 */
                FastTrackCustomerCallDoc.prototype.customerTaskDoc = null;
    
                /**
                 * FastTrackCustomerCallDoc fastTrackCallDoc.
                 * @member {infinitusai.be.IFastTrackCallInfoDoc|null|undefined} fastTrackCallDoc
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @instance
                 */
                FastTrackCustomerCallDoc.prototype.fastTrackCallDoc = null;
    
                /**
                 * FastTrackCustomerCallDoc additionalTaskInfo.
                 * @member {infinitusai.be.IAdditionalTaskInfo|null|undefined} additionalTaskInfo
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @instance
                 */
                FastTrackCustomerCallDoc.prototype.additionalTaskInfo = null;
    
                /**
                 * Creates a new FastTrackCustomerCallDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCustomerCallDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.FastTrackCustomerCallDoc} FastTrackCustomerCallDoc instance
                 */
                FastTrackCustomerCallDoc.create = function create(properties) {
                    return new FastTrackCustomerCallDoc(properties);
                };
    
                /**
                 * Encodes the specified FastTrackCustomerCallDoc message. Does not implicitly {@link infinitusai.be.FastTrackCustomerCallDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCustomerCallDoc} message FastTrackCustomerCallDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackCustomerCallDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.customerTaskDoc != null && Object.hasOwnProperty.call(message, "customerTaskDoc"))
                        $root.infinitusai.be.CustomerTaskDoc.encode(message.customerTaskDoc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.fastTrackCallDoc != null && Object.hasOwnProperty.call(message, "fastTrackCallDoc"))
                        $root.infinitusai.be.FastTrackCallInfoDoc.encode(message.fastTrackCallDoc, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.additionalTaskInfo != null && Object.hasOwnProperty.call(message, "additionalTaskInfo"))
                        $root.infinitusai.be.AdditionalTaskInfo.encode(message.additionalTaskInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FastTrackCustomerCallDoc message, length delimited. Does not implicitly {@link infinitusai.be.FastTrackCustomerCallDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {infinitusai.be.IFastTrackCustomerCallDoc} message FastTrackCustomerCallDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackCustomerCallDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FastTrackCustomerCallDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FastTrackCustomerCallDoc} FastTrackCustomerCallDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackCustomerCallDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FastTrackCustomerCallDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.customerTaskDoc = $root.infinitusai.be.CustomerTaskDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.fastTrackCallDoc = $root.infinitusai.be.FastTrackCallInfoDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.additionalTaskInfo = $root.infinitusai.be.AdditionalTaskInfo.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FastTrackCustomerCallDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FastTrackCustomerCallDoc} FastTrackCustomerCallDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackCustomerCallDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FastTrackCustomerCallDoc message.
                 * @function verify
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastTrackCustomerCallDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.customerTaskDoc != null && message.hasOwnProperty("customerTaskDoc")) {
                        var error = $root.infinitusai.be.CustomerTaskDoc.verify(message.customerTaskDoc);
                        if (error)
                            return "customerTaskDoc." + error;
                    }
                    if (message.fastTrackCallDoc != null && message.hasOwnProperty("fastTrackCallDoc")) {
                        var error = $root.infinitusai.be.FastTrackCallInfoDoc.verify(message.fastTrackCallDoc);
                        if (error)
                            return "fastTrackCallDoc." + error;
                    }
                    if (message.additionalTaskInfo != null && message.hasOwnProperty("additionalTaskInfo")) {
                        var error = $root.infinitusai.be.AdditionalTaskInfo.verify(message.additionalTaskInfo);
                        if (error)
                            return "additionalTaskInfo." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FastTrackCustomerCallDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FastTrackCustomerCallDoc} FastTrackCustomerCallDoc
                 */
                FastTrackCustomerCallDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FastTrackCustomerCallDoc)
                        return object;
                    var message = new $root.infinitusai.be.FastTrackCustomerCallDoc();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.customerTaskDoc != null) {
                        if (typeof object.customerTaskDoc !== "object")
                            throw TypeError(".infinitusai.be.FastTrackCustomerCallDoc.customerTaskDoc: object expected");
                        message.customerTaskDoc = $root.infinitusai.be.CustomerTaskDoc.fromObject(object.customerTaskDoc);
                    }
                    if (object.fastTrackCallDoc != null) {
                        if (typeof object.fastTrackCallDoc !== "object")
                            throw TypeError(".infinitusai.be.FastTrackCustomerCallDoc.fastTrackCallDoc: object expected");
                        message.fastTrackCallDoc = $root.infinitusai.be.FastTrackCallInfoDoc.fromObject(object.fastTrackCallDoc);
                    }
                    if (object.additionalTaskInfo != null) {
                        if (typeof object.additionalTaskInfo !== "object")
                            throw TypeError(".infinitusai.be.FastTrackCustomerCallDoc.additionalTaskInfo: object expected");
                        message.additionalTaskInfo = $root.infinitusai.be.AdditionalTaskInfo.fromObject(object.additionalTaskInfo);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FastTrackCustomerCallDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {infinitusai.be.FastTrackCustomerCallDoc} message FastTrackCustomerCallDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastTrackCustomerCallDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.customerTaskDoc = null;
                        object.fastTrackCallDoc = null;
                        object.additionalTaskInfo = null;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.customerTaskDoc != null && message.hasOwnProperty("customerTaskDoc"))
                        object.customerTaskDoc = $root.infinitusai.be.CustomerTaskDoc.toObject(message.customerTaskDoc, options);
                    if (message.fastTrackCallDoc != null && message.hasOwnProperty("fastTrackCallDoc"))
                        object.fastTrackCallDoc = $root.infinitusai.be.FastTrackCallInfoDoc.toObject(message.fastTrackCallDoc, options);
                    if (message.additionalTaskInfo != null && message.hasOwnProperty("additionalTaskInfo"))
                        object.additionalTaskInfo = $root.infinitusai.be.AdditionalTaskInfo.toObject(message.additionalTaskInfo, options);
                    return object;
                };
    
                /**
                 * Converts this FastTrackCustomerCallDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastTrackCustomerCallDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FastTrackCustomerCallDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FastTrackCustomerCallDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FastTrackCustomerCallDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FastTrackCustomerCallDoc";
                };
    
                return FastTrackCustomerCallDoc;
            })();
    
            be.GetCallsHistoryResponse = (function() {
    
                /**
                 * Properties of a GetCallsHistoryResponse.
                 * @memberof infinitusai.be
                 * @interface IGetCallsHistoryResponse
                 * @property {Array.<infinitusai.be.IFastTrackCustomerCallDoc>|null} [calls] GetCallsHistoryResponse calls
                 */
    
                /**
                 * Constructs a new GetCallsHistoryResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCallsHistoryResponse.
                 * @implements IGetCallsHistoryResponse
                 * @constructor
                 * @param {infinitusai.be.IGetCallsHistoryResponse=} [properties] Properties to set
                 */
                function GetCallsHistoryResponse(properties) {
                    this.calls = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCallsHistoryResponse calls.
                 * @member {Array.<infinitusai.be.IFastTrackCustomerCallDoc>} calls
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @instance
                 */
                GetCallsHistoryResponse.prototype.calls = $util.emptyArray;
    
                /**
                 * Creates a new GetCallsHistoryResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {infinitusai.be.IGetCallsHistoryResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCallsHistoryResponse} GetCallsHistoryResponse instance
                 */
                GetCallsHistoryResponse.create = function create(properties) {
                    return new GetCallsHistoryResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCallsHistoryResponse message. Does not implicitly {@link infinitusai.be.GetCallsHistoryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {infinitusai.be.IGetCallsHistoryResponse} message GetCallsHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallsHistoryResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.calls != null && message.calls.length)
                        for (var i = 0; i < message.calls.length; ++i)
                            $root.infinitusai.be.FastTrackCustomerCallDoc.encode(message.calls[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCallsHistoryResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetCallsHistoryResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {infinitusai.be.IGetCallsHistoryResponse} message GetCallsHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallsHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCallsHistoryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCallsHistoryResponse} GetCallsHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallsHistoryResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCallsHistoryResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.calls && message.calls.length))
                                    message.calls = [];
                                message.calls.push($root.infinitusai.be.FastTrackCustomerCallDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCallsHistoryResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCallsHistoryResponse} GetCallsHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallsHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCallsHistoryResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCallsHistoryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.calls != null && message.hasOwnProperty("calls")) {
                        if (!Array.isArray(message.calls))
                            return "calls: array expected";
                        for (var i = 0; i < message.calls.length; ++i) {
                            var error = $root.infinitusai.be.FastTrackCustomerCallDoc.verify(message.calls[i]);
                            if (error)
                                return "calls." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetCallsHistoryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCallsHistoryResponse} GetCallsHistoryResponse
                 */
                GetCallsHistoryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCallsHistoryResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetCallsHistoryResponse();
                    if (object.calls) {
                        if (!Array.isArray(object.calls))
                            throw TypeError(".infinitusai.be.GetCallsHistoryResponse.calls: array expected");
                        message.calls = [];
                        for (var i = 0; i < object.calls.length; ++i) {
                            if (typeof object.calls[i] !== "object")
                                throw TypeError(".infinitusai.be.GetCallsHistoryResponse.calls: object expected");
                            message.calls[i] = $root.infinitusai.be.FastTrackCustomerCallDoc.fromObject(object.calls[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCallsHistoryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {infinitusai.be.GetCallsHistoryResponse} message GetCallsHistoryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCallsHistoryResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.calls = [];
                    if (message.calls && message.calls.length) {
                        object.calls = [];
                        for (var j = 0; j < message.calls.length; ++j)
                            object.calls[j] = $root.infinitusai.be.FastTrackCustomerCallDoc.toObject(message.calls[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetCallsHistoryResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCallsHistoryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCallsHistoryResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCallsHistoryResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCallsHistoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCallsHistoryResponse";
                };
    
                return GetCallsHistoryResponse;
            })();
    
            be.FastTrackCallDocAdditionalData = (function() {
    
                /**
                 * Properties of a FastTrackCallDocAdditionalData.
                 * @memberof infinitusai.be
                 * @interface IFastTrackCallDocAdditionalData
                 * @property {infinitusai.be.IProcessCallAdditionalData|null} [processCallAdditionalData] FastTrackCallDocAdditionalData processCallAdditionalData
                 */
    
                /**
                 * Constructs a new FastTrackCallDocAdditionalData.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FastTrackCallDocAdditionalData.
                 * @implements IFastTrackCallDocAdditionalData
                 * @constructor
                 * @param {infinitusai.be.IFastTrackCallDocAdditionalData=} [properties] Properties to set
                 */
                function FastTrackCallDocAdditionalData(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FastTrackCallDocAdditionalData processCallAdditionalData.
                 * @member {infinitusai.be.IProcessCallAdditionalData|null|undefined} processCallAdditionalData
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @instance
                 */
                FastTrackCallDocAdditionalData.prototype.processCallAdditionalData = null;
    
                /**
                 * Creates a new FastTrackCallDocAdditionalData instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {infinitusai.be.IFastTrackCallDocAdditionalData=} [properties] Properties to set
                 * @returns {infinitusai.be.FastTrackCallDocAdditionalData} FastTrackCallDocAdditionalData instance
                 */
                FastTrackCallDocAdditionalData.create = function create(properties) {
                    return new FastTrackCallDocAdditionalData(properties);
                };
    
                /**
                 * Encodes the specified FastTrackCallDocAdditionalData message. Does not implicitly {@link infinitusai.be.FastTrackCallDocAdditionalData.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {infinitusai.be.IFastTrackCallDocAdditionalData} message FastTrackCallDocAdditionalData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackCallDocAdditionalData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.processCallAdditionalData != null && Object.hasOwnProperty.call(message, "processCallAdditionalData"))
                        $root.infinitusai.be.ProcessCallAdditionalData.encode(message.processCallAdditionalData, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FastTrackCallDocAdditionalData message, length delimited. Does not implicitly {@link infinitusai.be.FastTrackCallDocAdditionalData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {infinitusai.be.IFastTrackCallDocAdditionalData} message FastTrackCallDocAdditionalData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackCallDocAdditionalData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FastTrackCallDocAdditionalData message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FastTrackCallDocAdditionalData} FastTrackCallDocAdditionalData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackCallDocAdditionalData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FastTrackCallDocAdditionalData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.processCallAdditionalData = $root.infinitusai.be.ProcessCallAdditionalData.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FastTrackCallDocAdditionalData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FastTrackCallDocAdditionalData} FastTrackCallDocAdditionalData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackCallDocAdditionalData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FastTrackCallDocAdditionalData message.
                 * @function verify
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastTrackCallDocAdditionalData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.processCallAdditionalData != null && message.hasOwnProperty("processCallAdditionalData")) {
                        var error = $root.infinitusai.be.ProcessCallAdditionalData.verify(message.processCallAdditionalData);
                        if (error)
                            return "processCallAdditionalData." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FastTrackCallDocAdditionalData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FastTrackCallDocAdditionalData} FastTrackCallDocAdditionalData
                 */
                FastTrackCallDocAdditionalData.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FastTrackCallDocAdditionalData)
                        return object;
                    var message = new $root.infinitusai.be.FastTrackCallDocAdditionalData();
                    if (object.processCallAdditionalData != null) {
                        if (typeof object.processCallAdditionalData !== "object")
                            throw TypeError(".infinitusai.be.FastTrackCallDocAdditionalData.processCallAdditionalData: object expected");
                        message.processCallAdditionalData = $root.infinitusai.be.ProcessCallAdditionalData.fromObject(object.processCallAdditionalData);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FastTrackCallDocAdditionalData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {infinitusai.be.FastTrackCallDocAdditionalData} message FastTrackCallDocAdditionalData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastTrackCallDocAdditionalData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.processCallAdditionalData = null;
                    if (message.processCallAdditionalData != null && message.hasOwnProperty("processCallAdditionalData"))
                        object.processCallAdditionalData = $root.infinitusai.be.ProcessCallAdditionalData.toObject(message.processCallAdditionalData, options);
                    return object;
                };
    
                /**
                 * Converts this FastTrackCallDocAdditionalData to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastTrackCallDocAdditionalData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FastTrackCallDocAdditionalData
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FastTrackCallDocAdditionalData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FastTrackCallDocAdditionalData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FastTrackCallDocAdditionalData";
                };
    
                return FastTrackCallDocAdditionalData;
            })();
    
            be.ProcessCallAdditionalData = (function() {
    
                /**
                 * Properties of a ProcessCallAdditionalData.
                 * @memberof infinitusai.be
                 * @interface IProcessCallAdditionalData
                 * @property {infinitusai.be.IFastTrackProcessCallDetails|null} [processCallDetails] ProcessCallAdditionalData processCallDetails
                 * @property {infinitusai.be.FastTrackCallOutcome|null} [outcome] ProcessCallAdditionalData outcome
                 */
    
                /**
                 * Constructs a new ProcessCallAdditionalData.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProcessCallAdditionalData.
                 * @implements IProcessCallAdditionalData
                 * @constructor
                 * @param {infinitusai.be.IProcessCallAdditionalData=} [properties] Properties to set
                 */
                function ProcessCallAdditionalData(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProcessCallAdditionalData processCallDetails.
                 * @member {infinitusai.be.IFastTrackProcessCallDetails|null|undefined} processCallDetails
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @instance
                 */
                ProcessCallAdditionalData.prototype.processCallDetails = null;
    
                /**
                 * ProcessCallAdditionalData outcome.
                 * @member {infinitusai.be.FastTrackCallOutcome} outcome
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @instance
                 */
                ProcessCallAdditionalData.prototype.outcome = 0;
    
                /**
                 * Creates a new ProcessCallAdditionalData instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {infinitusai.be.IProcessCallAdditionalData=} [properties] Properties to set
                 * @returns {infinitusai.be.ProcessCallAdditionalData} ProcessCallAdditionalData instance
                 */
                ProcessCallAdditionalData.create = function create(properties) {
                    return new ProcessCallAdditionalData(properties);
                };
    
                /**
                 * Encodes the specified ProcessCallAdditionalData message. Does not implicitly {@link infinitusai.be.ProcessCallAdditionalData.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {infinitusai.be.IProcessCallAdditionalData} message ProcessCallAdditionalData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessCallAdditionalData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.processCallDetails != null && Object.hasOwnProperty.call(message, "processCallDetails"))
                        $root.infinitusai.be.FastTrackProcessCallDetails.encode(message.processCallDetails, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.outcome != null && Object.hasOwnProperty.call(message, "outcome"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.outcome);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProcessCallAdditionalData message, length delimited. Does not implicitly {@link infinitusai.be.ProcessCallAdditionalData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {infinitusai.be.IProcessCallAdditionalData} message ProcessCallAdditionalData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessCallAdditionalData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProcessCallAdditionalData message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProcessCallAdditionalData} ProcessCallAdditionalData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessCallAdditionalData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProcessCallAdditionalData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.processCallDetails = $root.infinitusai.be.FastTrackProcessCallDetails.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.outcome = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProcessCallAdditionalData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProcessCallAdditionalData} ProcessCallAdditionalData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessCallAdditionalData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProcessCallAdditionalData message.
                 * @function verify
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProcessCallAdditionalData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.processCallDetails != null && message.hasOwnProperty("processCallDetails")) {
                        var error = $root.infinitusai.be.FastTrackProcessCallDetails.verify(message.processCallDetails);
                        if (error)
                            return "processCallDetails." + error;
                    }
                    if (message.outcome != null && message.hasOwnProperty("outcome"))
                        switch (message.outcome) {
                        default:
                            return "outcome: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a ProcessCallAdditionalData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProcessCallAdditionalData} ProcessCallAdditionalData
                 */
                ProcessCallAdditionalData.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProcessCallAdditionalData)
                        return object;
                    var message = new $root.infinitusai.be.ProcessCallAdditionalData();
                    if (object.processCallDetails != null) {
                        if (typeof object.processCallDetails !== "object")
                            throw TypeError(".infinitusai.be.ProcessCallAdditionalData.processCallDetails: object expected");
                        message.processCallDetails = $root.infinitusai.be.FastTrackProcessCallDetails.fromObject(object.processCallDetails);
                    }
                    switch (object.outcome) {
                    default:
                        if (typeof object.outcome === "number") {
                            message.outcome = object.outcome;
                            break;
                        }
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_UNKNOWN":
                    case 0:
                        message.outcome = 0;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_SUCCESS":
                    case 1:
                        message.outcome = 1;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_FAILURE":
                    case 2:
                        message.outcome = 2;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_FAILURE_AND_REDIAL":
                    case 3:
                        message.outcome = 3;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_FAILURE_AND_SEND_TO_QUEUE":
                    case 4:
                        message.outcome = 4;
                        break;
                    case "FAST_TRACK_CALL_OUTCOME_NOT_PROCESSED_YET":
                    case 5:
                        message.outcome = 5;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProcessCallAdditionalData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {infinitusai.be.ProcessCallAdditionalData} message ProcessCallAdditionalData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProcessCallAdditionalData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.processCallDetails = null;
                        object.outcome = options.enums === String ? "FAST_TRACK_CALL_OUTCOME_UNKNOWN" : 0;
                    }
                    if (message.processCallDetails != null && message.hasOwnProperty("processCallDetails"))
                        object.processCallDetails = $root.infinitusai.be.FastTrackProcessCallDetails.toObject(message.processCallDetails, options);
                    if (message.outcome != null && message.hasOwnProperty("outcome"))
                        object.outcome = options.enums === String ? $root.infinitusai.be.FastTrackCallOutcome[message.outcome] === undefined ? message.outcome : $root.infinitusai.be.FastTrackCallOutcome[message.outcome] : message.outcome;
                    return object;
                };
    
                /**
                 * Converts this ProcessCallAdditionalData to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProcessCallAdditionalData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProcessCallAdditionalData
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProcessCallAdditionalData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProcessCallAdditionalData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProcessCallAdditionalData";
                };
    
                return ProcessCallAdditionalData;
            })();
    
            be.GetFastTrackTaskRequest = (function() {
    
                /**
                 * Properties of a GetFastTrackTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IGetFastTrackTaskRequest
                 * @property {string|null} [taskUuid] GetFastTrackTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new GetFastTrackTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetFastTrackTaskRequest.
                 * @implements IGetFastTrackTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IGetFastTrackTaskRequest=} [properties] Properties to set
                 */
                function GetFastTrackTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetFastTrackTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @instance
                 */
                GetFastTrackTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new GetFastTrackTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetFastTrackTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetFastTrackTaskRequest} GetFastTrackTaskRequest instance
                 */
                GetFastTrackTaskRequest.create = function create(properties) {
                    return new GetFastTrackTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified GetFastTrackTaskRequest message. Does not implicitly {@link infinitusai.be.GetFastTrackTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetFastTrackTaskRequest} message GetFastTrackTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFastTrackTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetFastTrackTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetFastTrackTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetFastTrackTaskRequest} message GetFastTrackTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFastTrackTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetFastTrackTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetFastTrackTaskRequest} GetFastTrackTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFastTrackTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetFastTrackTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetFastTrackTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetFastTrackTaskRequest} GetFastTrackTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFastTrackTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetFastTrackTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetFastTrackTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetFastTrackTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetFastTrackTaskRequest} GetFastTrackTaskRequest
                 */
                GetFastTrackTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetFastTrackTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetFastTrackTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetFastTrackTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {infinitusai.be.GetFastTrackTaskRequest} message GetFastTrackTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetFastTrackTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this GetFastTrackTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetFastTrackTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetFastTrackTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetFastTrackTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetFastTrackTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetFastTrackTaskRequest";
                };
    
                return GetFastTrackTaskRequest;
            })();
    
            be.GetFastTrackTaskResponse = (function() {
    
                /**
                 * Properties of a GetFastTrackTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IGetFastTrackTaskResponse
                 * @property {infinitusai.be.ITaskDoc|null} [taskDoc] GetFastTrackTaskResponse taskDoc
                 * @property {infinitusai.be.ICustomerTaskDoc|null} [customerTask] GetFastTrackTaskResponse customerTask
                 * @property {Array.<infinitusapi.IINFTaskInput>|null} [taskInputs] GetFastTrackTaskResponse taskInputs
                 * @property {Array.<infinitusai.be.IFastTrackCallInfoDoc>|null} [calls] GetFastTrackTaskResponse calls
                 */
    
                /**
                 * Constructs a new GetFastTrackTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetFastTrackTaskResponse.
                 * @implements IGetFastTrackTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IGetFastTrackTaskResponse=} [properties] Properties to set
                 */
                function GetFastTrackTaskResponse(properties) {
                    this.taskInputs = [];
                    this.calls = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetFastTrackTaskResponse taskDoc.
                 * @member {infinitusai.be.ITaskDoc|null|undefined} taskDoc
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @instance
                 */
                GetFastTrackTaskResponse.prototype.taskDoc = null;
    
                /**
                 * GetFastTrackTaskResponse customerTask.
                 * @member {infinitusai.be.ICustomerTaskDoc|null|undefined} customerTask
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @instance
                 */
                GetFastTrackTaskResponse.prototype.customerTask = null;
    
                /**
                 * GetFastTrackTaskResponse taskInputs.
                 * @member {Array.<infinitusapi.IINFTaskInput>} taskInputs
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @instance
                 */
                GetFastTrackTaskResponse.prototype.taskInputs = $util.emptyArray;
    
                /**
                 * GetFastTrackTaskResponse calls.
                 * @member {Array.<infinitusai.be.IFastTrackCallInfoDoc>} calls
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @instance
                 */
                GetFastTrackTaskResponse.prototype.calls = $util.emptyArray;
    
                /**
                 * Creates a new GetFastTrackTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetFastTrackTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetFastTrackTaskResponse} GetFastTrackTaskResponse instance
                 */
                GetFastTrackTaskResponse.create = function create(properties) {
                    return new GetFastTrackTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified GetFastTrackTaskResponse message. Does not implicitly {@link infinitusai.be.GetFastTrackTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetFastTrackTaskResponse} message GetFastTrackTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFastTrackTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskDoc != null && Object.hasOwnProperty.call(message, "taskDoc"))
                        $root.infinitusai.be.TaskDoc.encode(message.taskDoc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.customerTask != null && Object.hasOwnProperty.call(message, "customerTask"))
                        $root.infinitusai.be.CustomerTaskDoc.encode(message.customerTask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.taskInputs != null && message.taskInputs.length)
                        for (var i = 0; i < message.taskInputs.length; ++i)
                            $root.infinitusapi.INFTaskInput.encode(message.taskInputs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.calls != null && message.calls.length)
                        for (var i = 0; i < message.calls.length; ++i)
                            $root.infinitusai.be.FastTrackCallInfoDoc.encode(message.calls[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetFastTrackTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetFastTrackTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetFastTrackTaskResponse} message GetFastTrackTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFastTrackTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetFastTrackTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetFastTrackTaskResponse} GetFastTrackTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFastTrackTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetFastTrackTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskDoc = $root.infinitusai.be.TaskDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.customerTask = $root.infinitusai.be.CustomerTaskDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.taskInputs && message.taskInputs.length))
                                    message.taskInputs = [];
                                message.taskInputs.push($root.infinitusapi.INFTaskInput.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.calls && message.calls.length))
                                    message.calls = [];
                                message.calls.push($root.infinitusai.be.FastTrackCallInfoDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetFastTrackTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetFastTrackTaskResponse} GetFastTrackTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFastTrackTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetFastTrackTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetFastTrackTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskDoc != null && message.hasOwnProperty("taskDoc")) {
                        var error = $root.infinitusai.be.TaskDoc.verify(message.taskDoc);
                        if (error)
                            return "taskDoc." + error;
                    }
                    if (message.customerTask != null && message.hasOwnProperty("customerTask")) {
                        var error = $root.infinitusai.be.CustomerTaskDoc.verify(message.customerTask);
                        if (error)
                            return "customerTask." + error;
                    }
                    if (message.taskInputs != null && message.hasOwnProperty("taskInputs")) {
                        if (!Array.isArray(message.taskInputs))
                            return "taskInputs: array expected";
                        for (var i = 0; i < message.taskInputs.length; ++i) {
                            var error = $root.infinitusapi.INFTaskInput.verify(message.taskInputs[i]);
                            if (error)
                                return "taskInputs." + error;
                        }
                    }
                    if (message.calls != null && message.hasOwnProperty("calls")) {
                        if (!Array.isArray(message.calls))
                            return "calls: array expected";
                        for (var i = 0; i < message.calls.length; ++i) {
                            var error = $root.infinitusai.be.FastTrackCallInfoDoc.verify(message.calls[i]);
                            if (error)
                                return "calls." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetFastTrackTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetFastTrackTaskResponse} GetFastTrackTaskResponse
                 */
                GetFastTrackTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetFastTrackTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetFastTrackTaskResponse();
                    if (object.taskDoc != null) {
                        if (typeof object.taskDoc !== "object")
                            throw TypeError(".infinitusai.be.GetFastTrackTaskResponse.taskDoc: object expected");
                        message.taskDoc = $root.infinitusai.be.TaskDoc.fromObject(object.taskDoc);
                    }
                    if (object.customerTask != null) {
                        if (typeof object.customerTask !== "object")
                            throw TypeError(".infinitusai.be.GetFastTrackTaskResponse.customerTask: object expected");
                        message.customerTask = $root.infinitusai.be.CustomerTaskDoc.fromObject(object.customerTask);
                    }
                    if (object.taskInputs) {
                        if (!Array.isArray(object.taskInputs))
                            throw TypeError(".infinitusai.be.GetFastTrackTaskResponse.taskInputs: array expected");
                        message.taskInputs = [];
                        for (var i = 0; i < object.taskInputs.length; ++i) {
                            if (typeof object.taskInputs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetFastTrackTaskResponse.taskInputs: object expected");
                            message.taskInputs[i] = $root.infinitusapi.INFTaskInput.fromObject(object.taskInputs[i]);
                        }
                    }
                    if (object.calls) {
                        if (!Array.isArray(object.calls))
                            throw TypeError(".infinitusai.be.GetFastTrackTaskResponse.calls: array expected");
                        message.calls = [];
                        for (var i = 0; i < object.calls.length; ++i) {
                            if (typeof object.calls[i] !== "object")
                                throw TypeError(".infinitusai.be.GetFastTrackTaskResponse.calls: object expected");
                            message.calls[i] = $root.infinitusai.be.FastTrackCallInfoDoc.fromObject(object.calls[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetFastTrackTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {infinitusai.be.GetFastTrackTaskResponse} message GetFastTrackTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetFastTrackTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.taskInputs = [];
                        object.calls = [];
                    }
                    if (options.defaults) {
                        object.taskDoc = null;
                        object.customerTask = null;
                    }
                    if (message.taskDoc != null && message.hasOwnProperty("taskDoc"))
                        object.taskDoc = $root.infinitusai.be.TaskDoc.toObject(message.taskDoc, options);
                    if (message.customerTask != null && message.hasOwnProperty("customerTask"))
                        object.customerTask = $root.infinitusai.be.CustomerTaskDoc.toObject(message.customerTask, options);
                    if (message.taskInputs && message.taskInputs.length) {
                        object.taskInputs = [];
                        for (var j = 0; j < message.taskInputs.length; ++j)
                            object.taskInputs[j] = $root.infinitusapi.INFTaskInput.toObject(message.taskInputs[j], options);
                    }
                    if (message.calls && message.calls.length) {
                        object.calls = [];
                        for (var j = 0; j < message.calls.length; ++j)
                            object.calls[j] = $root.infinitusai.be.FastTrackCallInfoDoc.toObject(message.calls[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetFastTrackTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetFastTrackTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetFastTrackTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetFastTrackTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetFastTrackTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetFastTrackTaskResponse";
                };
    
                return GetFastTrackTaskResponse;
            })();
    
            be.GetCallRecordingURLRequest = (function() {
    
                /**
                 * Properties of a GetCallRecordingURLRequest.
                 * @memberof infinitusai.be
                 * @interface IGetCallRecordingURLRequest
                 * @property {string|null} [taskUuid] GetCallRecordingURLRequest taskUuid
                 * @property {string|null} [callUuid] GetCallRecordingURLRequest callUuid
                 */
    
                /**
                 * Constructs a new GetCallRecordingURLRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCallRecordingURLRequest.
                 * @implements IGetCallRecordingURLRequest
                 * @constructor
                 * @param {infinitusai.be.IGetCallRecordingURLRequest=} [properties] Properties to set
                 */
                function GetCallRecordingURLRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCallRecordingURLRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @instance
                 */
                GetCallRecordingURLRequest.prototype.taskUuid = "";
    
                /**
                 * GetCallRecordingURLRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @instance
                 */
                GetCallRecordingURLRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new GetCallRecordingURLRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {infinitusai.be.IGetCallRecordingURLRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCallRecordingURLRequest} GetCallRecordingURLRequest instance
                 */
                GetCallRecordingURLRequest.create = function create(properties) {
                    return new GetCallRecordingURLRequest(properties);
                };
    
                /**
                 * Encodes the specified GetCallRecordingURLRequest message. Does not implicitly {@link infinitusai.be.GetCallRecordingURLRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {infinitusai.be.IGetCallRecordingURLRequest} message GetCallRecordingURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallRecordingURLRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCallRecordingURLRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetCallRecordingURLRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {infinitusai.be.IGetCallRecordingURLRequest} message GetCallRecordingURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallRecordingURLRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCallRecordingURLRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCallRecordingURLRequest} GetCallRecordingURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallRecordingURLRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCallRecordingURLRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCallRecordingURLRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCallRecordingURLRequest} GetCallRecordingURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallRecordingURLRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCallRecordingURLRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCallRecordingURLRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetCallRecordingURLRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCallRecordingURLRequest} GetCallRecordingURLRequest
                 */
                GetCallRecordingURLRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCallRecordingURLRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetCallRecordingURLRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCallRecordingURLRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {infinitusai.be.GetCallRecordingURLRequest} message GetCallRecordingURLRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCallRecordingURLRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this GetCallRecordingURLRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCallRecordingURLRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCallRecordingURLRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCallRecordingURLRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCallRecordingURLRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCallRecordingURLRequest";
                };
    
                return GetCallRecordingURLRequest;
            })();
    
            be.GetCallRecordingURLResponse = (function() {
    
                /**
                 * Properties of a GetCallRecordingURLResponse.
                 * @memberof infinitusai.be
                 * @interface IGetCallRecordingURLResponse
                 * @property {string|null} [recordingUrl] GetCallRecordingURLResponse recordingUrl
                 * @property {string|null} [taskUuid] GetCallRecordingURLResponse taskUuid
                 * @property {string|null} [callUuid] GetCallRecordingURLResponse callUuid
                 */
    
                /**
                 * Constructs a new GetCallRecordingURLResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCallRecordingURLResponse.
                 * @implements IGetCallRecordingURLResponse
                 * @constructor
                 * @param {infinitusai.be.IGetCallRecordingURLResponse=} [properties] Properties to set
                 */
                function GetCallRecordingURLResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCallRecordingURLResponse recordingUrl.
                 * @member {string} recordingUrl
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @instance
                 */
                GetCallRecordingURLResponse.prototype.recordingUrl = "";
    
                /**
                 * GetCallRecordingURLResponse taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @instance
                 */
                GetCallRecordingURLResponse.prototype.taskUuid = "";
    
                /**
                 * GetCallRecordingURLResponse callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @instance
                 */
                GetCallRecordingURLResponse.prototype.callUuid = "";
    
                /**
                 * Creates a new GetCallRecordingURLResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {infinitusai.be.IGetCallRecordingURLResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCallRecordingURLResponse} GetCallRecordingURLResponse instance
                 */
                GetCallRecordingURLResponse.create = function create(properties) {
                    return new GetCallRecordingURLResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCallRecordingURLResponse message. Does not implicitly {@link infinitusai.be.GetCallRecordingURLResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {infinitusai.be.IGetCallRecordingURLResponse} message GetCallRecordingURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallRecordingURLResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.recordingUrl != null && Object.hasOwnProperty.call(message, "recordingUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.recordingUrl);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCallRecordingURLResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetCallRecordingURLResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {infinitusai.be.IGetCallRecordingURLResponse} message GetCallRecordingURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallRecordingURLResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCallRecordingURLResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCallRecordingURLResponse} GetCallRecordingURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallRecordingURLResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCallRecordingURLResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.recordingUrl = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCallRecordingURLResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCallRecordingURLResponse} GetCallRecordingURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallRecordingURLResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCallRecordingURLResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCallRecordingURLResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordingUrl != null && message.hasOwnProperty("recordingUrl"))
                        if (!$util.isString(message.recordingUrl))
                            return "recordingUrl: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetCallRecordingURLResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCallRecordingURLResponse} GetCallRecordingURLResponse
                 */
                GetCallRecordingURLResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCallRecordingURLResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetCallRecordingURLResponse();
                    if (object.recordingUrl != null)
                        message.recordingUrl = String(object.recordingUrl);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCallRecordingURLResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {infinitusai.be.GetCallRecordingURLResponse} message GetCallRecordingURLResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCallRecordingURLResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.recordingUrl = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.recordingUrl != null && message.hasOwnProperty("recordingUrl"))
                        object.recordingUrl = message.recordingUrl;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this GetCallRecordingURLResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCallRecordingURLResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCallRecordingURLResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCallRecordingURLResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCallRecordingURLResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCallRecordingURLResponse";
                };
    
                return GetCallRecordingURLResponse;
            })();
    
            be.CallOutputs = (function() {
    
                /**
                 * Properties of a CallOutputs.
                 * @memberof infinitusai.be
                 * @interface ICallOutputs
                 * @property {infinitusai.be.IMedSupBVCallOutputs|null} [medSupBvOutputs] CallOutputs medSupBvOutputs
                 * @property {infinitusai.be.IFullBVCallOutputs|null} [fullBvOutputs] CallOutputs fullBvOutputs
                 * @property {infinitusai.be.IPBMBVCallOutputs|null} [pbmBvOutputs] CallOutputs pbmBvOutputs
                 * @property {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null} [covidVaccineAvailabilityCallOutputs] CallOutputs covidVaccineAvailabilityCallOutputs
                 */
    
                /**
                 * Constructs a new CallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallOutputs.
                 * @implements ICallOutputs
                 * @constructor
                 * @param {infinitusai.be.ICallOutputs=} [properties] Properties to set
                 */
                function CallOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallOutputs medSupBvOutputs.
                 * @member {infinitusai.be.IMedSupBVCallOutputs|null|undefined} medSupBvOutputs
                 * @memberof infinitusai.be.CallOutputs
                 * @instance
                 */
                CallOutputs.prototype.medSupBvOutputs = null;
    
                /**
                 * CallOutputs fullBvOutputs.
                 * @member {infinitusai.be.IFullBVCallOutputs|null|undefined} fullBvOutputs
                 * @memberof infinitusai.be.CallOutputs
                 * @instance
                 */
                CallOutputs.prototype.fullBvOutputs = null;
    
                /**
                 * CallOutputs pbmBvOutputs.
                 * @member {infinitusai.be.IPBMBVCallOutputs|null|undefined} pbmBvOutputs
                 * @memberof infinitusai.be.CallOutputs
                 * @instance
                 */
                CallOutputs.prototype.pbmBvOutputs = null;
    
                /**
                 * CallOutputs covidVaccineAvailabilityCallOutputs.
                 * @member {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null|undefined} covidVaccineAvailabilityCallOutputs
                 * @memberof infinitusai.be.CallOutputs
                 * @instance
                 */
                CallOutputs.prototype.covidVaccineAvailabilityCallOutputs = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * CallOutputs outputs.
                 * @member {"medSupBvOutputs"|"fullBvOutputs"|"pbmBvOutputs"|"covidVaccineAvailabilityCallOutputs"|undefined} outputs
                 * @memberof infinitusai.be.CallOutputs
                 * @instance
                 */
                Object.defineProperty(CallOutputs.prototype, "outputs", {
                    get: $util.oneOfGetter($oneOfFields = ["medSupBvOutputs", "fullBvOutputs", "pbmBvOutputs", "covidVaccineAvailabilityCallOutputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new CallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {infinitusai.be.ICallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.CallOutputs} CallOutputs instance
                 */
                CallOutputs.create = function create(properties) {
                    return new CallOutputs(properties);
                };
    
                /**
                 * Encodes the specified CallOutputs message. Does not implicitly {@link infinitusai.be.CallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {infinitusai.be.ICallOutputs} message CallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.medSupBvOutputs != null && Object.hasOwnProperty.call(message, "medSupBvOutputs"))
                        $root.infinitusai.be.MedSupBVCallOutputs.encode(message.medSupBvOutputs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fullBvOutputs != null && Object.hasOwnProperty.call(message, "fullBvOutputs"))
                        $root.infinitusai.be.FullBVCallOutputs.encode(message.fullBvOutputs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.pbmBvOutputs != null && Object.hasOwnProperty.call(message, "pbmBvOutputs"))
                        $root.infinitusai.be.PBMBVCallOutputs.encode(message.pbmBvOutputs, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.covidVaccineAvailabilityCallOutputs != null && Object.hasOwnProperty.call(message, "covidVaccineAvailabilityCallOutputs"))
                        $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.encode(message.covidVaccineAvailabilityCallOutputs, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.CallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {infinitusai.be.ICallOutputs} message CallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallOutputs} CallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.medSupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallOutputs} CallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.medSupBvOutputs != null && message.hasOwnProperty("medSupBvOutputs")) {
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.MedSupBVCallOutputs.verify(message.medSupBvOutputs);
                            if (error)
                                return "medSupBvOutputs." + error;
                        }
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.FullBVCallOutputs.verify(message.fullBvOutputs);
                            if (error)
                                return "fullBvOutputs." + error;
                        }
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.PBMBVCallOutputs.verify(message.pbmBvOutputs);
                            if (error)
                                return "pbmBvOutputs." + error;
                        }
                    }
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.verify(message.covidVaccineAvailabilityCallOutputs);
                            if (error)
                                return "covidVaccineAvailabilityCallOutputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a CallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallOutputs} CallOutputs
                 */
                CallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.CallOutputs();
                    if (object.medSupBvOutputs != null) {
                        if (typeof object.medSupBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputs.medSupBvOutputs: object expected");
                        message.medSupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.fromObject(object.medSupBvOutputs);
                    }
                    if (object.fullBvOutputs != null) {
                        if (typeof object.fullBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputs.fullBvOutputs: object expected");
                        message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.fromObject(object.fullBvOutputs);
                    }
                    if (object.pbmBvOutputs != null) {
                        if (typeof object.pbmBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputs.pbmBvOutputs: object expected");
                        message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.fromObject(object.pbmBvOutputs);
                    }
                    if (object.covidVaccineAvailabilityCallOutputs != null) {
                        if (typeof object.covidVaccineAvailabilityCallOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputs.covidVaccineAvailabilityCallOutputs: object expected");
                        message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.fromObject(object.covidVaccineAvailabilityCallOutputs);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {infinitusai.be.CallOutputs} message CallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.medSupBvOutputs != null && message.hasOwnProperty("medSupBvOutputs")) {
                        object.medSupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.toObject(message.medSupBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "medSupBvOutputs";
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        object.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.toObject(message.fullBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "fullBvOutputs";
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        object.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.toObject(message.pbmBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "pbmBvOutputs";
                    }
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        object.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.toObject(message.covidVaccineAvailabilityCallOutputs, options);
                        if (options.oneofs)
                            object.outputs = "covidVaccineAvailabilityCallOutputs";
                    }
                    return object;
                };
    
                /**
                 * Converts this CallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallOutputs";
                };
    
                return CallOutputs;
            })();
    
            /**
             * TaskState enum.
             * @name infinitusai.be.TaskState
             * @enum {number}
             * @property {number} TASK_STATE_UNKNOWN=0 TASK_STATE_UNKNOWN value
             * @property {number} TASK_STATE_CREATED=1 TASK_STATE_CREATED value
             * @property {number} TASK_STATE_CALL_CONNECTED=2 TASK_STATE_CALL_CONNECTED value
             * @property {number} TASK_STATE_CALL_DISCONNECTED=3 TASK_STATE_CALL_DISCONNECTED value
             * @property {number} TASK_STATE_FAILED=4 TASK_STATE_FAILED value
             * @property {number} TASK_STATE_REVIEWED=5 TASK_STATE_REVIEWED value
             * @property {number} TASK_STATE_READY_FOR_REVIEW=6 TASK_STATE_READY_FOR_REVIEW value
             * @property {number} TASK_STATE_IN_REVIEW=7 TASK_STATE_IN_REVIEW value
             * @property {number} TASK_STATE_IN_PROGRESS=8 TASK_STATE_IN_PROGRESS value
             * @property {number} TASK_STATE_PARTIAL_SUCCESS=9 TASK_STATE_PARTIAL_SUCCESS value
             * @property {number} TASK_STATE_READY_FOR_SELF_REVIEW=10 TASK_STATE_READY_FOR_SELF_REVIEW value
             * @property {number} TASK_STATE_AUTO_CLOSED=11 TASK_STATE_AUTO_CLOSED value
             * @property {number} TASK_STATE_PENDING_AUTO_REQUEUE=12 TASK_STATE_PENDING_AUTO_REQUEUE value
             * @property {number} TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL=13 TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL value
             */
            be.TaskState = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TASK_STATE_UNKNOWN"] = 0;
                values[valuesById[1] = "TASK_STATE_CREATED"] = 1;
                values[valuesById[2] = "TASK_STATE_CALL_CONNECTED"] = 2;
                values[valuesById[3] = "TASK_STATE_CALL_DISCONNECTED"] = 3;
                values[valuesById[4] = "TASK_STATE_FAILED"] = 4;
                values[valuesById[5] = "TASK_STATE_REVIEWED"] = 5;
                values[valuesById[6] = "TASK_STATE_READY_FOR_REVIEW"] = 6;
                values[valuesById[7] = "TASK_STATE_IN_REVIEW"] = 7;
                values[valuesById[8] = "TASK_STATE_IN_PROGRESS"] = 8;
                values[valuesById[9] = "TASK_STATE_PARTIAL_SUCCESS"] = 9;
                values[valuesById[10] = "TASK_STATE_READY_FOR_SELF_REVIEW"] = 10;
                values[valuesById[11] = "TASK_STATE_AUTO_CLOSED"] = 11;
                values[valuesById[12] = "TASK_STATE_PENDING_AUTO_REQUEUE"] = 12;
                values[valuesById[13] = "TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL"] = 13;
                return values;
            })();
    
            /**
             * TaskSource enum.
             * @name infinitusai.be.TaskSource
             * @enum {number}
             * @property {number} TASK_SOURCE_UNKNOWN=0 TASK_SOURCE_UNKNOWN value
             * @property {number} TASK_SOURCE_ETL_SCRIPT=1 TASK_SOURCE_ETL_SCRIPT value
             * @property {number} TASK_SOURCE_FE=2 TASK_SOURCE_FE value
             * @property {number} TASK_SOURCE_CUSTOMER_PORTAL=3 TASK_SOURCE_CUSTOMER_PORTAL value
             * @property {number} TASK_SOURCE_CUSTOMER_API=4 TASK_SOURCE_CUSTOMER_API value
             * @property {number} TASK_SOURCE_SETUP=5 TASK_SOURCE_SETUP value
             * @property {number} TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD=6 TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD value
             * @property {number} TASK_SOURCE_FASTTRACK=7 TASK_SOURCE_FASTTRACK value
             * @property {number} TASK_SOURCE_FASTTRACK_SF=8 TASK_SOURCE_FASTTRACK_SF value
             * @property {number} TASK_SOURCE_FASTTRACK_CSV_UPLOAD=9 TASK_SOURCE_FASTTRACK_CSV_UPLOAD value
             */
            be.TaskSource = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TASK_SOURCE_UNKNOWN"] = 0;
                values[valuesById[1] = "TASK_SOURCE_ETL_SCRIPT"] = 1;
                values[valuesById[2] = "TASK_SOURCE_FE"] = 2;
                values[valuesById[3] = "TASK_SOURCE_CUSTOMER_PORTAL"] = 3;
                values[valuesById[4] = "TASK_SOURCE_CUSTOMER_API"] = 4;
                values[valuesById[5] = "TASK_SOURCE_SETUP"] = 5;
                values[valuesById[6] = "TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD"] = 6;
                values[valuesById[7] = "TASK_SOURCE_FASTTRACK"] = 7;
                values[valuesById[8] = "TASK_SOURCE_FASTTRACK_SF"] = 8;
                values[valuesById[9] = "TASK_SOURCE_FASTTRACK_CSV_UPLOAD"] = 9;
                return values;
            })();
    
            /**
             * TaskType enum.
             * @name infinitusai.be.TaskType
             * @enum {number}
             * @property {number} TASK_TYPE_UNKNOWN=0 TASK_TYPE_UNKNOWN value
             * @property {number} TASK_TYPE_MED_SUPP_BV=1 TASK_TYPE_MED_SUPP_BV value
             * @property {number} TASK_TYPE_FULL_BI=2 TASK_TYPE_FULL_BI value
             * @property {number} TASK_TYPE_PBM_BV=3 TASK_TYPE_PBM_BV value
             * @property {number} TASK_TYPE_COVID_VACCINATIONS=4 TASK_TYPE_COVID_VACCINATIONS value
             * @property {number} TASK_TYPE_PRESCRIPTION_TRANSFER=5 TASK_TYPE_PRESCRIPTION_TRANSFER value
             * @property {number} TASK_TYPE_INBOUND=6 TASK_TYPE_INBOUND value
             * @property {number} TASK_TYPE_PRESCRIPTION_SAVINGS=7 TASK_TYPE_PRESCRIPTION_SAVINGS value
             * @property {number} TASK_TYPE_CLAIMS_FOLLOWUP=8 TASK_TYPE_CLAIMS_FOLLOWUP value
             * @property {number} TASK_TYPE_PRIOR_AUTH_FOLLOWUP=9 TASK_TYPE_PRIOR_AUTH_FOLLOWUP value
             * @property {number} TASK_TYPE_DENTAL_BV=10 TASK_TYPE_DENTAL_BV value
             * @property {number} TASK_TYPE_SHIPMENT_FOLLOWUP=11 TASK_TYPE_SHIPMENT_FOLLOWUP value
             * @property {number} TASK_TYPE_PATIENT_OUTREACH=12 TASK_TYPE_PATIENT_OUTREACH value
             * @property {number} TASK_TYPE_PROVIDER_OUTREACH=13 TASK_TYPE_PROVIDER_OUTREACH value
             * @property {number} TASK_TYPE_FASTTRACK_MM=14 TASK_TYPE_FASTTRACK_MM value
             * @property {number} TASK_TYPE_FASTTRACK_PBM=15 TASK_TYPE_FASTTRACK_PBM value
             * @property {number} TASK_TYPE_PHARMACY_STOCK_CHECK=16 TASK_TYPE_PHARMACY_STOCK_CHECK value
             * @property {number} TASK_TYPE_BASIC_BV=17 TASK_TYPE_BASIC_BV value
             * @property {number} TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP=18 TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP value
             * @property {number} TASK_TYPE_FASTTRACK_CLAIMS_DENIAL=19 TASK_TYPE_FASTTRACK_CLAIMS_DENIAL value
             * @property {number} TASK_TYPE_FASTTRACK_CLAIMS_APPEAL=20 TASK_TYPE_FASTTRACK_CLAIMS_APPEAL value
             * @property {number} TASK_TYPE_PBM_DISCOVERY=21 TASK_TYPE_PBM_DISCOVERY value
             */
            be.TaskType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TASK_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "TASK_TYPE_MED_SUPP_BV"] = 1;
                values[valuesById[2] = "TASK_TYPE_FULL_BI"] = 2;
                values[valuesById[3] = "TASK_TYPE_PBM_BV"] = 3;
                values[valuesById[4] = "TASK_TYPE_COVID_VACCINATIONS"] = 4;
                values[valuesById[5] = "TASK_TYPE_PRESCRIPTION_TRANSFER"] = 5;
                values[valuesById[6] = "TASK_TYPE_INBOUND"] = 6;
                values[valuesById[7] = "TASK_TYPE_PRESCRIPTION_SAVINGS"] = 7;
                values[valuesById[8] = "TASK_TYPE_CLAIMS_FOLLOWUP"] = 8;
                values[valuesById[9] = "TASK_TYPE_PRIOR_AUTH_FOLLOWUP"] = 9;
                values[valuesById[10] = "TASK_TYPE_DENTAL_BV"] = 10;
                values[valuesById[11] = "TASK_TYPE_SHIPMENT_FOLLOWUP"] = 11;
                values[valuesById[12] = "TASK_TYPE_PATIENT_OUTREACH"] = 12;
                values[valuesById[13] = "TASK_TYPE_PROVIDER_OUTREACH"] = 13;
                values[valuesById[14] = "TASK_TYPE_FASTTRACK_MM"] = 14;
                values[valuesById[15] = "TASK_TYPE_FASTTRACK_PBM"] = 15;
                values[valuesById[16] = "TASK_TYPE_PHARMACY_STOCK_CHECK"] = 16;
                values[valuesById[17] = "TASK_TYPE_BASIC_BV"] = 17;
                values[valuesById[18] = "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP"] = 18;
                values[valuesById[19] = "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL"] = 19;
                values[valuesById[20] = "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL"] = 20;
                values[valuesById[21] = "TASK_TYPE_PBM_DISCOVERY"] = 21;
                return values;
            })();
    
            /**
             * TaskAnnotation enum.
             * @name infinitusai.be.TaskAnnotation
             * @enum {number}
             * @property {number} TASK_ANNOTATION_UNKNOWN=0 TASK_ANNOTATION_UNKNOWN value
             * @property {number} TASK_ANNOTATION_DUPLICATE_TASK=1 TASK_ANNOTATION_DUPLICATE_TASK value
             */
            be.TaskAnnotation = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TASK_ANNOTATION_UNKNOWN"] = 0;
                values[valuesById[1] = "TASK_ANNOTATION_DUPLICATE_TASK"] = 1;
                return values;
            })();
    
            be.CustomerReview = (function() {
    
                /**
                 * Properties of a CustomerReview.
                 * @memberof infinitusai.be
                 * @interface ICustomerReview
                 * @property {string|null} [reviewedByUuid] CustomerReview reviewedByUuid
                 * @property {string|null} [reviewedByName] CustomerReview reviewedByName
                 * @property {number|Long|null} [reviewedOnMillis] CustomerReview reviewedOnMillis
                 */
    
                /**
                 * Constructs a new CustomerReview.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CustomerReview.
                 * @implements ICustomerReview
                 * @constructor
                 * @param {infinitusai.be.ICustomerReview=} [properties] Properties to set
                 */
                function CustomerReview(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CustomerReview reviewedByUuid.
                 * @member {string} reviewedByUuid
                 * @memberof infinitusai.be.CustomerReview
                 * @instance
                 */
                CustomerReview.prototype.reviewedByUuid = "";
    
                /**
                 * CustomerReview reviewedByName.
                 * @member {string} reviewedByName
                 * @memberof infinitusai.be.CustomerReview
                 * @instance
                 */
                CustomerReview.prototype.reviewedByName = "";
    
                /**
                 * CustomerReview reviewedOnMillis.
                 * @member {number|Long} reviewedOnMillis
                 * @memberof infinitusai.be.CustomerReview
                 * @instance
                 */
                CustomerReview.prototype.reviewedOnMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new CustomerReview instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {infinitusai.be.ICustomerReview=} [properties] Properties to set
                 * @returns {infinitusai.be.CustomerReview} CustomerReview instance
                 */
                CustomerReview.create = function create(properties) {
                    return new CustomerReview(properties);
                };
    
                /**
                 * Encodes the specified CustomerReview message. Does not implicitly {@link infinitusai.be.CustomerReview.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {infinitusai.be.ICustomerReview} message CustomerReview message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomerReview.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reviewedByUuid != null && Object.hasOwnProperty.call(message, "reviewedByUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.reviewedByUuid);
                    if (message.reviewedByName != null && Object.hasOwnProperty.call(message, "reviewedByName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.reviewedByName);
                    if (message.reviewedOnMillis != null && Object.hasOwnProperty.call(message, "reviewedOnMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.reviewedOnMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified CustomerReview message, length delimited. Does not implicitly {@link infinitusai.be.CustomerReview.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {infinitusai.be.ICustomerReview} message CustomerReview message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomerReview.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CustomerReview message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CustomerReview} CustomerReview
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomerReview.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CustomerReview();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.reviewedByUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.reviewedByName = reader.string();
                                break;
                            }
                        case 4: {
                                message.reviewedOnMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CustomerReview message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CustomerReview} CustomerReview
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomerReview.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CustomerReview message.
                 * @function verify
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CustomerReview.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reviewedByUuid != null && message.hasOwnProperty("reviewedByUuid"))
                        if (!$util.isString(message.reviewedByUuid))
                            return "reviewedByUuid: string expected";
                    if (message.reviewedByName != null && message.hasOwnProperty("reviewedByName"))
                        if (!$util.isString(message.reviewedByName))
                            return "reviewedByName: string expected";
                    if (message.reviewedOnMillis != null && message.hasOwnProperty("reviewedOnMillis"))
                        if (!$util.isInteger(message.reviewedOnMillis) && !(message.reviewedOnMillis && $util.isInteger(message.reviewedOnMillis.low) && $util.isInteger(message.reviewedOnMillis.high)))
                            return "reviewedOnMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a CustomerReview message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CustomerReview} CustomerReview
                 */
                CustomerReview.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CustomerReview)
                        return object;
                    var message = new $root.infinitusai.be.CustomerReview();
                    if (object.reviewedByUuid != null)
                        message.reviewedByUuid = String(object.reviewedByUuid);
                    if (object.reviewedByName != null)
                        message.reviewedByName = String(object.reviewedByName);
                    if (object.reviewedOnMillis != null)
                        if ($util.Long)
                            (message.reviewedOnMillis = $util.Long.fromValue(object.reviewedOnMillis)).unsigned = false;
                        else if (typeof object.reviewedOnMillis === "string")
                            message.reviewedOnMillis = parseInt(object.reviewedOnMillis, 10);
                        else if (typeof object.reviewedOnMillis === "number")
                            message.reviewedOnMillis = object.reviewedOnMillis;
                        else if (typeof object.reviewedOnMillis === "object")
                            message.reviewedOnMillis = new $util.LongBits(object.reviewedOnMillis.low >>> 0, object.reviewedOnMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a CustomerReview message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {infinitusai.be.CustomerReview} message CustomerReview
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CustomerReview.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.reviewedByUuid = "";
                        object.reviewedByName = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedOnMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedOnMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.reviewedByUuid != null && message.hasOwnProperty("reviewedByUuid"))
                        object.reviewedByUuid = message.reviewedByUuid;
                    if (message.reviewedByName != null && message.hasOwnProperty("reviewedByName"))
                        object.reviewedByName = message.reviewedByName;
                    if (message.reviewedOnMillis != null && message.hasOwnProperty("reviewedOnMillis"))
                        if (typeof message.reviewedOnMillis === "number")
                            object.reviewedOnMillis = options.longs === String ? String(message.reviewedOnMillis) : message.reviewedOnMillis;
                        else
                            object.reviewedOnMillis = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedOnMillis) : options.longs === Number ? new $util.LongBits(message.reviewedOnMillis.low >>> 0, message.reviewedOnMillis.high >>> 0).toNumber() : message.reviewedOnMillis;
                    return object;
                };
    
                /**
                 * Converts this CustomerReview to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CustomerReview
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CustomerReview.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CustomerReview
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CustomerReview
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CustomerReview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CustomerReview";
                };
    
                return CustomerReview;
            })();
    
            be.CreateTaskRequest = (function() {
    
                /**
                 * Properties of a CreateTaskRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateTaskRequest
                 * @property {infinitusai.be.TaskSource|null} [taskSource] CreateTaskRequest taskSource
                 * @property {infinitusai.be.TaskType|null} [taskType] CreateTaskRequest taskType
                 * @property {infinitusai.be.IBVCallInputs|null} [bvInputs] CreateTaskRequest bvInputs
                 * @property {infinitusapi.IINFPriorAuthTaskInput|null} [priorAuthTaskInput] CreateTaskRequest priorAuthTaskInput
                 * @property {string|null} [customerAssignedId] CreateTaskRequest customerAssignedId
                 * @property {string|null} [programName] CreateTaskRequest programName
                 * @property {boolean|null} [isOperatorTrainerTask] CreateTaskRequest isOperatorTrainerTask
                 * @property {string|null} [operatorTrainerScriptId] CreateTaskRequest operatorTrainerScriptId
                 * @property {string|null} [customerSegment] CreateTaskRequest customerSegment
                 */
    
                /**
                 * Constructs a new CreateTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateTaskRequest.
                 * @implements ICreateTaskRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateTaskRequest=} [properties] Properties to set
                 */
                function CreateTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateTaskRequest taskSource.
                 * @member {infinitusai.be.TaskSource} taskSource
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.taskSource = 0;
    
                /**
                 * CreateTaskRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.taskType = 0;
    
                /**
                 * CreateTaskRequest bvInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} bvInputs
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.bvInputs = null;
    
                /**
                 * CreateTaskRequest priorAuthTaskInput.
                 * @member {infinitusapi.IINFPriorAuthTaskInput|null|undefined} priorAuthTaskInput
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.priorAuthTaskInput = null;
    
                /**
                 * CreateTaskRequest customerAssignedId.
                 * @member {string} customerAssignedId
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.customerAssignedId = "";
    
                /**
                 * CreateTaskRequest programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.programName = "";
    
                /**
                 * CreateTaskRequest isOperatorTrainerTask.
                 * @member {boolean} isOperatorTrainerTask
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.isOperatorTrainerTask = false;
    
                /**
                 * CreateTaskRequest operatorTrainerScriptId.
                 * @member {string} operatorTrainerScriptId
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.operatorTrainerScriptId = "";
    
                /**
                 * CreateTaskRequest customerSegment.
                 * @member {string} customerSegment
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                CreateTaskRequest.prototype.customerSegment = "";
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * CreateTaskRequest inputs.
                 * @member {"bvInputs"|"priorAuthTaskInput"|undefined} inputs
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 */
                Object.defineProperty(CreateTaskRequest.prototype, "inputs", {
                    get: $util.oneOfGetter($oneOfFields = ["bvInputs", "priorAuthTaskInput"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new CreateTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateTaskRequest} CreateTaskRequest instance
                 */
                CreateTaskRequest.create = function create(properties) {
                    return new CreateTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateTaskRequest message. Does not implicitly {@link infinitusai.be.CreateTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateTaskRequest} message CreateTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskSource != null && Object.hasOwnProperty.call(message, "taskSource"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.taskSource);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    if (message.bvInputs != null && Object.hasOwnProperty.call(message, "bvInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.bvInputs, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.customerAssignedId != null && Object.hasOwnProperty.call(message, "customerAssignedId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.customerAssignedId);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.programName);
                    if (message.isOperatorTrainerTask != null && Object.hasOwnProperty.call(message, "isOperatorTrainerTask"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isOperatorTrainerTask);
                    if (message.operatorTrainerScriptId != null && Object.hasOwnProperty.call(message, "operatorTrainerScriptId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.operatorTrainerScriptId);
                    if (message.customerSegment != null && Object.hasOwnProperty.call(message, "customerSegment"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.customerSegment);
                    if (message.priorAuthTaskInput != null && Object.hasOwnProperty.call(message, "priorAuthTaskInput"))
                        $root.infinitusapi.INFPriorAuthTaskInput.encode(message.priorAuthTaskInput, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateTaskRequest} message CreateTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateTaskRequest} CreateTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskSource = reader.int32();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.bvInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.customerAssignedId = reader.string();
                                break;
                            }
                        case 5: {
                                message.programName = reader.string();
                                break;
                            }
                        case 6: {
                                message.isOperatorTrainerTask = reader.bool();
                                break;
                            }
                        case 7: {
                                message.operatorTrainerScriptId = reader.string();
                                break;
                            }
                        case 8: {
                                message.customerSegment = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateTaskRequest} CreateTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.taskSource != null && message.hasOwnProperty("taskSource"))
                        switch (message.taskSource) {
                        default:
                            return "taskSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        properties.inputs = 1;
                        {
                            var error = $root.infinitusai.be.BVCallInputs.verify(message.bvInputs);
                            if (error)
                                return "bvInputs." + error;
                        }
                    }
                    if (message.priorAuthTaskInput != null && message.hasOwnProperty("priorAuthTaskInput")) {
                        if (properties.inputs === 1)
                            return "inputs: multiple values";
                        properties.inputs = 1;
                        {
                            var error = $root.infinitusapi.INFPriorAuthTaskInput.verify(message.priorAuthTaskInput);
                            if (error)
                                return "priorAuthTaskInput." + error;
                        }
                    }
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        if (!$util.isString(message.customerAssignedId))
                            return "customerAssignedId: string expected";
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    if (message.isOperatorTrainerTask != null && message.hasOwnProperty("isOperatorTrainerTask"))
                        if (typeof message.isOperatorTrainerTask !== "boolean")
                            return "isOperatorTrainerTask: boolean expected";
                    if (message.operatorTrainerScriptId != null && message.hasOwnProperty("operatorTrainerScriptId"))
                        if (!$util.isString(message.operatorTrainerScriptId))
                            return "operatorTrainerScriptId: string expected";
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        if (!$util.isString(message.customerSegment))
                            return "customerSegment: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateTaskRequest} CreateTaskRequest
                 */
                CreateTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateTaskRequest();
                    switch (object.taskSource) {
                    default:
                        if (typeof object.taskSource === "number") {
                            message.taskSource = object.taskSource;
                            break;
                        }
                        break;
                    case "TASK_SOURCE_UNKNOWN":
                    case 0:
                        message.taskSource = 0;
                        break;
                    case "TASK_SOURCE_ETL_SCRIPT":
                    case 1:
                        message.taskSource = 1;
                        break;
                    case "TASK_SOURCE_FE":
                    case 2:
                        message.taskSource = 2;
                        break;
                    case "TASK_SOURCE_CUSTOMER_PORTAL":
                    case 3:
                        message.taskSource = 3;
                        break;
                    case "TASK_SOURCE_CUSTOMER_API":
                    case 4:
                        message.taskSource = 4;
                        break;
                    case "TASK_SOURCE_SETUP":
                    case 5:
                        message.taskSource = 5;
                        break;
                    case "TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD":
                    case 6:
                        message.taskSource = 6;
                        break;
                    case "TASK_SOURCE_FASTTRACK":
                    case 7:
                        message.taskSource = 7;
                        break;
                    case "TASK_SOURCE_FASTTRACK_SF":
                    case 8:
                        message.taskSource = 8;
                        break;
                    case "TASK_SOURCE_FASTTRACK_CSV_UPLOAD":
                    case 9:
                        message.taskSource = 9;
                        break;
                    }
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.bvInputs != null) {
                        if (typeof object.bvInputs !== "object")
                            throw TypeError(".infinitusai.be.CreateTaskRequest.bvInputs: object expected");
                        message.bvInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.bvInputs);
                    }
                    if (object.priorAuthTaskInput != null) {
                        if (typeof object.priorAuthTaskInput !== "object")
                            throw TypeError(".infinitusai.be.CreateTaskRequest.priorAuthTaskInput: object expected");
                        message.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.fromObject(object.priorAuthTaskInput);
                    }
                    if (object.customerAssignedId != null)
                        message.customerAssignedId = String(object.customerAssignedId);
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    if (object.isOperatorTrainerTask != null)
                        message.isOperatorTrainerTask = Boolean(object.isOperatorTrainerTask);
                    if (object.operatorTrainerScriptId != null)
                        message.operatorTrainerScriptId = String(object.operatorTrainerScriptId);
                    if (object.customerSegment != null)
                        message.customerSegment = String(object.customerSegment);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {infinitusai.be.CreateTaskRequest} message CreateTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskSource = options.enums === String ? "TASK_SOURCE_UNKNOWN" : 0;
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.customerAssignedId = "";
                        object.programName = "";
                        object.isOperatorTrainerTask = false;
                        object.operatorTrainerScriptId = "";
                        object.customerSegment = "";
                    }
                    if (message.taskSource != null && message.hasOwnProperty("taskSource"))
                        object.taskSource = options.enums === String ? $root.infinitusai.be.TaskSource[message.taskSource] === undefined ? message.taskSource : $root.infinitusai.be.TaskSource[message.taskSource] : message.taskSource;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        object.bvInputs = $root.infinitusai.be.BVCallInputs.toObject(message.bvInputs, options);
                        if (options.oneofs)
                            object.inputs = "bvInputs";
                    }
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        object.customerAssignedId = message.customerAssignedId;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    if (message.isOperatorTrainerTask != null && message.hasOwnProperty("isOperatorTrainerTask"))
                        object.isOperatorTrainerTask = message.isOperatorTrainerTask;
                    if (message.operatorTrainerScriptId != null && message.hasOwnProperty("operatorTrainerScriptId"))
                        object.operatorTrainerScriptId = message.operatorTrainerScriptId;
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        object.customerSegment = message.customerSegment;
                    if (message.priorAuthTaskInput != null && message.hasOwnProperty("priorAuthTaskInput")) {
                        object.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.toObject(message.priorAuthTaskInput, options);
                        if (options.oneofs)
                            object.inputs = "priorAuthTaskInput";
                    }
                    return object;
                };
    
                /**
                 * Converts this CreateTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateTaskRequest";
                };
    
                return CreateTaskRequest;
            })();
    
            be.CreateTaskResponse = (function() {
    
                /**
                 * Properties of a CreateTaskResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateTaskResponse
                 * @property {string|null} [taskUuid] CreateTaskResponse taskUuid
                 */
    
                /**
                 * Constructs a new CreateTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateTaskResponse.
                 * @implements ICreateTaskResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateTaskResponse=} [properties] Properties to set
                 */
                function CreateTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateTaskResponse taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @instance
                 */
                CreateTaskResponse.prototype.taskUuid = "";
    
                /**
                 * Creates a new CreateTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateTaskResponse} CreateTaskResponse instance
                 */
                CreateTaskResponse.create = function create(properties) {
                    return new CreateTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateTaskResponse message. Does not implicitly {@link infinitusai.be.CreateTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateTaskResponse} message CreateTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateTaskResponse} message CreateTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateTaskResponse} CreateTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateTaskResponse} CreateTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateTaskResponse} CreateTaskResponse
                 */
                CreateTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.CreateTaskResponse();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {infinitusai.be.CreateTaskResponse} message CreateTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this CreateTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateTaskResponse";
                };
    
                return CreateTaskResponse;
            })();
    
            be.CreateLoadTestTaskRequest = (function() {
    
                /**
                 * Properties of a CreateLoadTestTaskRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateLoadTestTaskRequest
                 * @property {number|null} [count] CreateLoadTestTaskRequest count
                 * @property {string|null} [programName] CreateLoadTestTaskRequest programName
                 * @property {string|null} [taskUuid] CreateLoadTestTaskRequest taskUuid
                 * @property {string|null} [callUuid] CreateLoadTestTaskRequest callUuid
                 */
    
                /**
                 * Constructs a new CreateLoadTestTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateLoadTestTaskRequest.
                 * @implements ICreateLoadTestTaskRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateLoadTestTaskRequest=} [properties] Properties to set
                 */
                function CreateLoadTestTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateLoadTestTaskRequest count.
                 * @member {number} count
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @instance
                 */
                CreateLoadTestTaskRequest.prototype.count = 0;
    
                /**
                 * CreateLoadTestTaskRequest programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @instance
                 */
                CreateLoadTestTaskRequest.prototype.programName = "";
    
                /**
                 * CreateLoadTestTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @instance
                 */
                CreateLoadTestTaskRequest.prototype.taskUuid = "";
    
                /**
                 * CreateLoadTestTaskRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @instance
                 */
                CreateLoadTestTaskRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new CreateLoadTestTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateLoadTestTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateLoadTestTaskRequest} CreateLoadTestTaskRequest instance
                 */
                CreateLoadTestTaskRequest.create = function create(properties) {
                    return new CreateLoadTestTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateLoadTestTaskRequest message. Does not implicitly {@link infinitusai.be.CreateLoadTestTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateLoadTestTaskRequest} message CreateLoadTestTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateLoadTestTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.count);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.programName);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateLoadTestTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateLoadTestTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateLoadTestTaskRequest} message CreateLoadTestTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateLoadTestTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateLoadTestTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateLoadTestTaskRequest} CreateLoadTestTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateLoadTestTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateLoadTestTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.count = reader.int32();
                                break;
                            }
                        case 2: {
                                message.programName = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateLoadTestTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateLoadTestTaskRequest} CreateLoadTestTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateLoadTestTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateLoadTestTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateLoadTestTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.count != null && message.hasOwnProperty("count"))
                        if (!$util.isInteger(message.count))
                            return "count: integer expected";
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateLoadTestTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateLoadTestTaskRequest} CreateLoadTestTaskRequest
                 */
                CreateLoadTestTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateLoadTestTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateLoadTestTaskRequest();
                    if (object.count != null)
                        message.count = object.count | 0;
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateLoadTestTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {infinitusai.be.CreateLoadTestTaskRequest} message CreateLoadTestTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateLoadTestTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.count = 0;
                        object.programName = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.count != null && message.hasOwnProperty("count"))
                        object.count = message.count;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this CreateLoadTestTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateLoadTestTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateLoadTestTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateLoadTestTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateLoadTestTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateLoadTestTaskRequest";
                };
    
                return CreateLoadTestTaskRequest;
            })();
    
            be.CreateLoadTestTaskResponse = (function() {
    
                /**
                 * Properties of a CreateLoadTestTaskResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateLoadTestTaskResponse
                 * @property {Array.<string>|null} [taskUuids] CreateLoadTestTaskResponse taskUuids
                 */
    
                /**
                 * Constructs a new CreateLoadTestTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateLoadTestTaskResponse.
                 * @implements ICreateLoadTestTaskResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateLoadTestTaskResponse=} [properties] Properties to set
                 */
                function CreateLoadTestTaskResponse(properties) {
                    this.taskUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateLoadTestTaskResponse taskUuids.
                 * @member {Array.<string>} taskUuids
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @instance
                 */
                CreateLoadTestTaskResponse.prototype.taskUuids = $util.emptyArray;
    
                /**
                 * Creates a new CreateLoadTestTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateLoadTestTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateLoadTestTaskResponse} CreateLoadTestTaskResponse instance
                 */
                CreateLoadTestTaskResponse.create = function create(properties) {
                    return new CreateLoadTestTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateLoadTestTaskResponse message. Does not implicitly {@link infinitusai.be.CreateLoadTestTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateLoadTestTaskResponse} message CreateLoadTestTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateLoadTestTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuids != null && message.taskUuids.length)
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateLoadTestTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateLoadTestTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateLoadTestTaskResponse} message CreateLoadTestTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateLoadTestTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateLoadTestTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateLoadTestTaskResponse} CreateLoadTestTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateLoadTestTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateLoadTestTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.taskUuids && message.taskUuids.length))
                                    message.taskUuids = [];
                                message.taskUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateLoadTestTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateLoadTestTaskResponse} CreateLoadTestTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateLoadTestTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateLoadTestTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateLoadTestTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuids != null && message.hasOwnProperty("taskUuids")) {
                        if (!Array.isArray(message.taskUuids))
                            return "taskUuids: array expected";
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            if (!$util.isString(message.taskUuids[i]))
                                return "taskUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateLoadTestTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateLoadTestTaskResponse} CreateLoadTestTaskResponse
                 */
                CreateLoadTestTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateLoadTestTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.CreateLoadTestTaskResponse();
                    if (object.taskUuids) {
                        if (!Array.isArray(object.taskUuids))
                            throw TypeError(".infinitusai.be.CreateLoadTestTaskResponse.taskUuids: array expected");
                        message.taskUuids = [];
                        for (var i = 0; i < object.taskUuids.length; ++i)
                            message.taskUuids[i] = String(object.taskUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateLoadTestTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {infinitusai.be.CreateLoadTestTaskResponse} message CreateLoadTestTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateLoadTestTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.taskUuids = [];
                    if (message.taskUuids && message.taskUuids.length) {
                        object.taskUuids = [];
                        for (var j = 0; j < message.taskUuids.length; ++j)
                            object.taskUuids[j] = message.taskUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this CreateLoadTestTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateLoadTestTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateLoadTestTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateLoadTestTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateLoadTestTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateLoadTestTaskResponse";
                };
    
                return CreateLoadTestTaskResponse;
            })();
    
            /**
             * Curveball enum.
             * @name infinitusai.be.Curveball
             * @enum {number}
             * @property {number} CURVEBALL_UNKNOWN=0 CURVEBALL_UNKNOWN value
             * @property {number} CURVEBALL_REQUEST_FOR_HUMAN=1 CURVEBALL_REQUEST_FOR_HUMAN value
             * @property {number} CURVEBALL_REQUEST_FOR_LIVE_AGENT=2 CURVEBALL_REQUEST_FOR_LIVE_AGENT value
             * @property {number} CURVEBALL_HIPAA_CONCERNS_PUSHBACK=3 CURVEBALL_HIPAA_CONCERNS_PUSHBACK value
             * @property {number} CURVEBALL_AGENT_PLAYS_WITH_EVA=4 CURVEBALL_AGENT_PLAYS_WITH_EVA value
             * @property {number} CURVEBALL_AGENT_RELENTLESSLY_PLAYS_WITH_EVA=5 CURVEBALL_AGENT_RELENTLESSLY_PLAYS_WITH_EVA value
             * @property {number} CURVEBALL_AGENT_SHARES_TOO_MUCH_INFO=6 CURVEBALL_AGENT_SHARES_TOO_MUCH_INFO value
             * @property {number} CURVEBALL_FRUSTRATED_AGENT_SHARES_TOO_MUCH_INFO=7 CURVEBALL_FRUSTRATED_AGENT_SHARES_TOO_MUCH_INFO value
             * @property {number} CURVEBALL_STEP_THERAPY_CONFUSION=8 CURVEBALL_STEP_THERAPY_CONFUSION value
             * @property {number} CURVEBALL_SPECIALTY_PHARMACY_CONFUSION=9 CURVEBALL_SPECIALTY_PHARMACY_CONFUSION value
             * @property {number} CURVEBALL_PREDETERMINATION_RECOMMENDED_NOT_REQUIRED=10 CURVEBALL_PREDETERMINATION_RECOMMENDED_NOT_REQUIRED value
             * @property {number} CURVEBALL_PREDETERMINATION_RECOMMENDED=11 CURVEBALL_PREDETERMINATION_RECOMMENDED value
             * @property {number} CURVEBALL_PREDETERMINATION_OPTIONAL=12 CURVEBALL_PREDETERMINATION_OPTIONAL value
             * @property {number} CURVEBALL_VAGUE_LIFETIME_MAX_NO=13 CURVEBALL_VAGUE_LIFETIME_MAX_NO value
             * @property {number} CURVEBALL_VAGUE_LIFETIME_MAX_YES=14 CURVEBALL_VAGUE_LIFETIME_MAX_YES value
             * @property {number} CURVEBALL_VAGUE_LIFETIME_MAX_MEDICARE=15 CURVEBALL_VAGUE_LIFETIME_MAX_MEDICARE value
             * @property {number} CURVEBALL_VAGUE_LIFETIME_MAX_LARGE_VALUE=16 CURVEBALL_VAGUE_LIFETIME_MAX_LARGE_VALUE value
             * @property {number} CURVEBALL_VAGUE_COPAY_COINSURANCE_YES=17 CURVEBALL_VAGUE_COPAY_COINSURANCE_YES value
             * @property {number} CURVEBALL_VAGUE_COPAY_COINSURANCE_NO=18 CURVEBALL_VAGUE_COPAY_COINSURANCE_NO value
             * @property {number} CURVEBALL_OOP_ALREADY_MET=19 CURVEBALL_OOP_ALREADY_MET value
             * @property {number} CURVEBALL_COB_NOT_UP_TO_DATE_NO_SECONDARY=20 CURVEBALL_COB_NOT_UP_TO_DATE_NO_SECONDARY value
             * @property {number} CURVEBALL_COB_NOT_UP_TO_DATE_WITH_SECONDARY=21 CURVEBALL_COB_NOT_UP_TO_DATE_WITH_SECONDARY value
             */
            be.Curveball = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CURVEBALL_UNKNOWN"] = 0;
                values[valuesById[1] = "CURVEBALL_REQUEST_FOR_HUMAN"] = 1;
                values[valuesById[2] = "CURVEBALL_REQUEST_FOR_LIVE_AGENT"] = 2;
                values[valuesById[3] = "CURVEBALL_HIPAA_CONCERNS_PUSHBACK"] = 3;
                values[valuesById[4] = "CURVEBALL_AGENT_PLAYS_WITH_EVA"] = 4;
                values[valuesById[5] = "CURVEBALL_AGENT_RELENTLESSLY_PLAYS_WITH_EVA"] = 5;
                values[valuesById[6] = "CURVEBALL_AGENT_SHARES_TOO_MUCH_INFO"] = 6;
                values[valuesById[7] = "CURVEBALL_FRUSTRATED_AGENT_SHARES_TOO_MUCH_INFO"] = 7;
                values[valuesById[8] = "CURVEBALL_STEP_THERAPY_CONFUSION"] = 8;
                values[valuesById[9] = "CURVEBALL_SPECIALTY_PHARMACY_CONFUSION"] = 9;
                values[valuesById[10] = "CURVEBALL_PREDETERMINATION_RECOMMENDED_NOT_REQUIRED"] = 10;
                values[valuesById[11] = "CURVEBALL_PREDETERMINATION_RECOMMENDED"] = 11;
                values[valuesById[12] = "CURVEBALL_PREDETERMINATION_OPTIONAL"] = 12;
                values[valuesById[13] = "CURVEBALL_VAGUE_LIFETIME_MAX_NO"] = 13;
                values[valuesById[14] = "CURVEBALL_VAGUE_LIFETIME_MAX_YES"] = 14;
                values[valuesById[15] = "CURVEBALL_VAGUE_LIFETIME_MAX_MEDICARE"] = 15;
                values[valuesById[16] = "CURVEBALL_VAGUE_LIFETIME_MAX_LARGE_VALUE"] = 16;
                values[valuesById[17] = "CURVEBALL_VAGUE_COPAY_COINSURANCE_YES"] = 17;
                values[valuesById[18] = "CURVEBALL_VAGUE_COPAY_COINSURANCE_NO"] = 18;
                values[valuesById[19] = "CURVEBALL_OOP_ALREADY_MET"] = 19;
                values[valuesById[20] = "CURVEBALL_COB_NOT_UP_TO_DATE_NO_SECONDARY"] = 20;
                values[valuesById[21] = "CURVEBALL_COB_NOT_UP_TO_DATE_WITH_SECONDARY"] = 21;
                return values;
            })();
    
            /**
             * EbvType enum.
             * @name infinitusai.be.EbvType
             * @enum {number}
             * @property {number} EBV_TYPE_UNKNOWN=0 EBV_TYPE_UNKNOWN value
             * @property {number} EBV_TYPE_CHC=1 EBV_TYPE_CHC value
             * @property {number} EBV_TYPE_PAYER_API=2 EBV_TYPE_PAYER_API value
             * @property {number} EBV_TYPE_STAT_PIE=3 EBV_TYPE_STAT_PIE value
             * @property {number} EBV_TYPE_MOCK=4 EBV_TYPE_MOCK value
             * @property {number} EBV_TYPE_MEDICARE=5 EBV_TYPE_MEDICARE value
             * @property {number} EBV_TYPE_AVAILITY=6 EBV_TYPE_AVAILITY value
             * @property {number} EBV_TYPE_CMS=7 EBV_TYPE_CMS value
             * @property {number} EBV_TYPE_BCBS=8 EBV_TYPE_BCBS value
             * @property {number} EBV_TYPE_ARRIVE_HEALTH=9 EBV_TYPE_ARRIVE_HEALTH value
             * @property {number} EBV_TYPE_KG_INTERNAL=10 EBV_TYPE_KG_INTERNAL value
             */
            be.EbvType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "EBV_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "EBV_TYPE_CHC"] = 1;
                values[valuesById[2] = "EBV_TYPE_PAYER_API"] = 2;
                values[valuesById[3] = "EBV_TYPE_STAT_PIE"] = 3;
                values[valuesById[4] = "EBV_TYPE_MOCK"] = 4;
                values[valuesById[5] = "EBV_TYPE_MEDICARE"] = 5;
                values[valuesById[6] = "EBV_TYPE_AVAILITY"] = 6;
                values[valuesById[7] = "EBV_TYPE_CMS"] = 7;
                values[valuesById[8] = "EBV_TYPE_BCBS"] = 8;
                values[valuesById[9] = "EBV_TYPE_ARRIVE_HEALTH"] = 9;
                values[valuesById[10] = "EBV_TYPE_KG_INTERNAL"] = 10;
                return values;
            })();
    
            be.EbvOutputs = (function() {
    
                /**
                 * Properties of an EbvOutputs.
                 * @memberof infinitusai.be
                 * @interface IEbvOutputs
                 * @property {infinitusai.be.EbvType|null} [type] EbvOutputs type
                 * @property {infinitusai.be.ICallOutputs|null} [outputs] EbvOutputs outputs
                 * @property {infinitusai.be.ICallOutputs|null} [outOfNetworkOutputs] EbvOutputs outOfNetworkOutputs
                 * @property {number|Long|null} [createdAtMillis] EbvOutputs createdAtMillis
                 * @property {boolean|null} [success] EbvOutputs success
                 * @property {Array.<string>|null} [errorMessages] EbvOutputs errorMessages
                 * @property {string|null} [transactionId] EbvOutputs transactionId
                 * @property {Array.<string>|null} [rawDataFilePaths] EbvOutputs rawDataFilePaths
                 */
    
                /**
                 * Constructs a new EbvOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EbvOutputs.
                 * @implements IEbvOutputs
                 * @constructor
                 * @param {infinitusai.be.IEbvOutputs=} [properties] Properties to set
                 */
                function EbvOutputs(properties) {
                    this.errorMessages = [];
                    this.rawDataFilePaths = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EbvOutputs type.
                 * @member {infinitusai.be.EbvType} type
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 */
                EbvOutputs.prototype.type = 0;
    
                /**
                 * EbvOutputs outputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} outputs
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 */
                EbvOutputs.prototype.outputs = null;
    
                /**
                 * EbvOutputs outOfNetworkOutputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} outOfNetworkOutputs
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 */
                EbvOutputs.prototype.outOfNetworkOutputs = null;
    
                /**
                 * EbvOutputs createdAtMillis.
                 * @member {number|Long} createdAtMillis
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 */
                EbvOutputs.prototype.createdAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * EbvOutputs success.
                 * @member {boolean} success
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 */
                EbvOutputs.prototype.success = false;
    
                /**
                 * EbvOutputs errorMessages.
                 * @member {Array.<string>} errorMessages
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 */
                EbvOutputs.prototype.errorMessages = $util.emptyArray;
    
                /**
                 * EbvOutputs transactionId.
                 * @member {string} transactionId
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 */
                EbvOutputs.prototype.transactionId = "";
    
                /**
                 * EbvOutputs rawDataFilePaths.
                 * @member {Array.<string>} rawDataFilePaths
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 */
                EbvOutputs.prototype.rawDataFilePaths = $util.emptyArray;
    
                /**
                 * Creates a new EbvOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {infinitusai.be.IEbvOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.EbvOutputs} EbvOutputs instance
                 */
                EbvOutputs.create = function create(properties) {
                    return new EbvOutputs(properties);
                };
    
                /**
                 * Encodes the specified EbvOutputs message. Does not implicitly {@link infinitusai.be.EbvOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {infinitusai.be.IEbvOutputs} message EbvOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EbvOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.outputs != null && Object.hasOwnProperty.call(message, "outputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.outputs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.outOfNetworkOutputs != null && Object.hasOwnProperty.call(message, "outOfNetworkOutputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.outOfNetworkOutputs, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.createdAtMillis != null && Object.hasOwnProperty.call(message, "createdAtMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createdAtMillis);
                    if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.success);
                    if (message.errorMessages != null && message.errorMessages.length)
                        for (var i = 0; i < message.errorMessages.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.errorMessages[i]);
                    if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.transactionId);
                    if (message.rawDataFilePaths != null && message.rawDataFilePaths.length)
                        for (var i = 0; i < message.rawDataFilePaths.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.rawDataFilePaths[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified EbvOutputs message, length delimited. Does not implicitly {@link infinitusai.be.EbvOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {infinitusai.be.IEbvOutputs} message EbvOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EbvOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EbvOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EbvOutputs} EbvOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EbvOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EbvOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.outputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.createdAtMillis = reader.int64();
                                break;
                            }
                        case 5: {
                                message.success = reader.bool();
                                break;
                            }
                        case 6: {
                                if (!(message.errorMessages && message.errorMessages.length))
                                    message.errorMessages = [];
                                message.errorMessages.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.transactionId = reader.string();
                                break;
                            }
                        case 8: {
                                if (!(message.rawDataFilePaths && message.rawDataFilePaths.length))
                                    message.rawDataFilePaths = [];
                                message.rawDataFilePaths.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EbvOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EbvOutputs} EbvOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EbvOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EbvOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EbvOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            break;
                        }
                    if (message.outputs != null && message.hasOwnProperty("outputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.outputs);
                        if (error)
                            return "outputs." + error;
                    }
                    if (message.outOfNetworkOutputs != null && message.hasOwnProperty("outOfNetworkOutputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.outOfNetworkOutputs);
                        if (error)
                            return "outOfNetworkOutputs." + error;
                    }
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (!$util.isInteger(message.createdAtMillis) && !(message.createdAtMillis && $util.isInteger(message.createdAtMillis.low) && $util.isInteger(message.createdAtMillis.high)))
                            return "createdAtMillis: integer|Long expected";
                    if (message.success != null && message.hasOwnProperty("success"))
                        if (typeof message.success !== "boolean")
                            return "success: boolean expected";
                    if (message.errorMessages != null && message.hasOwnProperty("errorMessages")) {
                        if (!Array.isArray(message.errorMessages))
                            return "errorMessages: array expected";
                        for (var i = 0; i < message.errorMessages.length; ++i)
                            if (!$util.isString(message.errorMessages[i]))
                                return "errorMessages: string[] expected";
                    }
                    if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                        if (!$util.isString(message.transactionId))
                            return "transactionId: string expected";
                    if (message.rawDataFilePaths != null && message.hasOwnProperty("rawDataFilePaths")) {
                        if (!Array.isArray(message.rawDataFilePaths))
                            return "rawDataFilePaths: array expected";
                        for (var i = 0; i < message.rawDataFilePaths.length; ++i)
                            if (!$util.isString(message.rawDataFilePaths[i]))
                                return "rawDataFilePaths: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an EbvOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EbvOutputs} EbvOutputs
                 */
                EbvOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EbvOutputs)
                        return object;
                    var message = new $root.infinitusai.be.EbvOutputs();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "EBV_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "EBV_TYPE_CHC":
                    case 1:
                        message.type = 1;
                        break;
                    case "EBV_TYPE_PAYER_API":
                    case 2:
                        message.type = 2;
                        break;
                    case "EBV_TYPE_STAT_PIE":
                    case 3:
                        message.type = 3;
                        break;
                    case "EBV_TYPE_MOCK":
                    case 4:
                        message.type = 4;
                        break;
                    case "EBV_TYPE_MEDICARE":
                    case 5:
                        message.type = 5;
                        break;
                    case "EBV_TYPE_AVAILITY":
                    case 6:
                        message.type = 6;
                        break;
                    case "EBV_TYPE_CMS":
                    case 7:
                        message.type = 7;
                        break;
                    case "EBV_TYPE_BCBS":
                    case 8:
                        message.type = 8;
                        break;
                    case "EBV_TYPE_ARRIVE_HEALTH":
                    case 9:
                        message.type = 9;
                        break;
                    case "EBV_TYPE_KG_INTERNAL":
                    case 10:
                        message.type = 10;
                        break;
                    }
                    if (object.outputs != null) {
                        if (typeof object.outputs !== "object")
                            throw TypeError(".infinitusai.be.EbvOutputs.outputs: object expected");
                        message.outputs = $root.infinitusai.be.CallOutputs.fromObject(object.outputs);
                    }
                    if (object.outOfNetworkOutputs != null) {
                        if (typeof object.outOfNetworkOutputs !== "object")
                            throw TypeError(".infinitusai.be.EbvOutputs.outOfNetworkOutputs: object expected");
                        message.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.fromObject(object.outOfNetworkOutputs);
                    }
                    if (object.createdAtMillis != null)
                        if ($util.Long)
                            (message.createdAtMillis = $util.Long.fromValue(object.createdAtMillis)).unsigned = false;
                        else if (typeof object.createdAtMillis === "string")
                            message.createdAtMillis = parseInt(object.createdAtMillis, 10);
                        else if (typeof object.createdAtMillis === "number")
                            message.createdAtMillis = object.createdAtMillis;
                        else if (typeof object.createdAtMillis === "object")
                            message.createdAtMillis = new $util.LongBits(object.createdAtMillis.low >>> 0, object.createdAtMillis.high >>> 0).toNumber();
                    if (object.success != null)
                        message.success = Boolean(object.success);
                    if (object.errorMessages) {
                        if (!Array.isArray(object.errorMessages))
                            throw TypeError(".infinitusai.be.EbvOutputs.errorMessages: array expected");
                        message.errorMessages = [];
                        for (var i = 0; i < object.errorMessages.length; ++i)
                            message.errorMessages[i] = String(object.errorMessages[i]);
                    }
                    if (object.transactionId != null)
                        message.transactionId = String(object.transactionId);
                    if (object.rawDataFilePaths) {
                        if (!Array.isArray(object.rawDataFilePaths))
                            throw TypeError(".infinitusai.be.EbvOutputs.rawDataFilePaths: array expected");
                        message.rawDataFilePaths = [];
                        for (var i = 0; i < object.rawDataFilePaths.length; ++i)
                            message.rawDataFilePaths[i] = String(object.rawDataFilePaths[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EbvOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {infinitusai.be.EbvOutputs} message EbvOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EbvOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.errorMessages = [];
                        object.rawDataFilePaths = [];
                    }
                    if (options.defaults) {
                        object.type = options.enums === String ? "EBV_TYPE_UNKNOWN" : 0;
                        object.outputs = null;
                        object.outOfNetworkOutputs = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAtMillis = options.longs === String ? "0" : 0;
                        object.success = false;
                        object.transactionId = "";
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.EbvType[message.type] === undefined ? message.type : $root.infinitusai.be.EbvType[message.type] : message.type;
                    if (message.outputs != null && message.hasOwnProperty("outputs"))
                        object.outputs = $root.infinitusai.be.CallOutputs.toObject(message.outputs, options);
                    if (message.outOfNetworkOutputs != null && message.hasOwnProperty("outOfNetworkOutputs"))
                        object.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.toObject(message.outOfNetworkOutputs, options);
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (typeof message.createdAtMillis === "number")
                            object.createdAtMillis = options.longs === String ? String(message.createdAtMillis) : message.createdAtMillis;
                        else
                            object.createdAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtMillis) : options.longs === Number ? new $util.LongBits(message.createdAtMillis.low >>> 0, message.createdAtMillis.high >>> 0).toNumber() : message.createdAtMillis;
                    if (message.success != null && message.hasOwnProperty("success"))
                        object.success = message.success;
                    if (message.errorMessages && message.errorMessages.length) {
                        object.errorMessages = [];
                        for (var j = 0; j < message.errorMessages.length; ++j)
                            object.errorMessages[j] = message.errorMessages[j];
                    }
                    if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                        object.transactionId = message.transactionId;
                    if (message.rawDataFilePaths && message.rawDataFilePaths.length) {
                        object.rawDataFilePaths = [];
                        for (var j = 0; j < message.rawDataFilePaths.length; ++j)
                            object.rawDataFilePaths[j] = message.rawDataFilePaths[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this EbvOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EbvOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EbvOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EbvOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EbvOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EbvOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EbvOutputs";
                };
    
                return EbvOutputs;
            })();
    
            be.EbvMetric = (function() {
    
                /**
                 * Properties of an EbvMetric.
                 * @memberof infinitusai.be
                 * @interface IEbvMetric
                 * @property {string|null} [fieldName] EbvMetric fieldName
                 * @property {boolean|null} [foundFromApi] EbvMetric foundFromApi
                 * @property {boolean|null} [isCorrect] EbvMetric isCorrect
                 * @property {boolean|null} [autoPopulated] EbvMetric autoPopulated
                 * @property {boolean|null} [hasActual] EbvMetric hasActual
                 */
    
                /**
                 * Constructs a new EbvMetric.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EbvMetric.
                 * @implements IEbvMetric
                 * @constructor
                 * @param {infinitusai.be.IEbvMetric=} [properties] Properties to set
                 */
                function EbvMetric(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EbvMetric fieldName.
                 * @member {string} fieldName
                 * @memberof infinitusai.be.EbvMetric
                 * @instance
                 */
                EbvMetric.prototype.fieldName = "";
    
                /**
                 * EbvMetric foundFromApi.
                 * @member {boolean} foundFromApi
                 * @memberof infinitusai.be.EbvMetric
                 * @instance
                 */
                EbvMetric.prototype.foundFromApi = false;
    
                /**
                 * EbvMetric isCorrect.
                 * @member {boolean} isCorrect
                 * @memberof infinitusai.be.EbvMetric
                 * @instance
                 */
                EbvMetric.prototype.isCorrect = false;
    
                /**
                 * EbvMetric autoPopulated.
                 * @member {boolean} autoPopulated
                 * @memberof infinitusai.be.EbvMetric
                 * @instance
                 */
                EbvMetric.prototype.autoPopulated = false;
    
                /**
                 * EbvMetric hasActual.
                 * @member {boolean} hasActual
                 * @memberof infinitusai.be.EbvMetric
                 * @instance
                 */
                EbvMetric.prototype.hasActual = false;
    
                /**
                 * Creates a new EbvMetric instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {infinitusai.be.IEbvMetric=} [properties] Properties to set
                 * @returns {infinitusai.be.EbvMetric} EbvMetric instance
                 */
                EbvMetric.create = function create(properties) {
                    return new EbvMetric(properties);
                };
    
                /**
                 * Encodes the specified EbvMetric message. Does not implicitly {@link infinitusai.be.EbvMetric.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {infinitusai.be.IEbvMetric} message EbvMetric message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EbvMetric.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fieldName != null && Object.hasOwnProperty.call(message, "fieldName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.fieldName);
                    if (message.foundFromApi != null && Object.hasOwnProperty.call(message, "foundFromApi"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.foundFromApi);
                    if (message.isCorrect != null && Object.hasOwnProperty.call(message, "isCorrect"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isCorrect);
                    if (message.autoPopulated != null && Object.hasOwnProperty.call(message, "autoPopulated"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.autoPopulated);
                    if (message.hasActual != null && Object.hasOwnProperty.call(message, "hasActual"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.hasActual);
                    return writer;
                };
    
                /**
                 * Encodes the specified EbvMetric message, length delimited. Does not implicitly {@link infinitusai.be.EbvMetric.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {infinitusai.be.IEbvMetric} message EbvMetric message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EbvMetric.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EbvMetric message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EbvMetric} EbvMetric
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EbvMetric.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EbvMetric();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.fieldName = reader.string();
                                break;
                            }
                        case 2: {
                                message.foundFromApi = reader.bool();
                                break;
                            }
                        case 3: {
                                message.isCorrect = reader.bool();
                                break;
                            }
                        case 4: {
                                message.autoPopulated = reader.bool();
                                break;
                            }
                        case 5: {
                                message.hasActual = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EbvMetric message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EbvMetric} EbvMetric
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EbvMetric.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EbvMetric message.
                 * @function verify
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EbvMetric.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fieldName != null && message.hasOwnProperty("fieldName"))
                        if (!$util.isString(message.fieldName))
                            return "fieldName: string expected";
                    if (message.foundFromApi != null && message.hasOwnProperty("foundFromApi"))
                        if (typeof message.foundFromApi !== "boolean")
                            return "foundFromApi: boolean expected";
                    if (message.isCorrect != null && message.hasOwnProperty("isCorrect"))
                        if (typeof message.isCorrect !== "boolean")
                            return "isCorrect: boolean expected";
                    if (message.autoPopulated != null && message.hasOwnProperty("autoPopulated"))
                        if (typeof message.autoPopulated !== "boolean")
                            return "autoPopulated: boolean expected";
                    if (message.hasActual != null && message.hasOwnProperty("hasActual"))
                        if (typeof message.hasActual !== "boolean")
                            return "hasActual: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an EbvMetric message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EbvMetric} EbvMetric
                 */
                EbvMetric.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EbvMetric)
                        return object;
                    var message = new $root.infinitusai.be.EbvMetric();
                    if (object.fieldName != null)
                        message.fieldName = String(object.fieldName);
                    if (object.foundFromApi != null)
                        message.foundFromApi = Boolean(object.foundFromApi);
                    if (object.isCorrect != null)
                        message.isCorrect = Boolean(object.isCorrect);
                    if (object.autoPopulated != null)
                        message.autoPopulated = Boolean(object.autoPopulated);
                    if (object.hasActual != null)
                        message.hasActual = Boolean(object.hasActual);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EbvMetric message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {infinitusai.be.EbvMetric} message EbvMetric
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EbvMetric.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.fieldName = "";
                        object.foundFromApi = false;
                        object.isCorrect = false;
                        object.autoPopulated = false;
                        object.hasActual = false;
                    }
                    if (message.fieldName != null && message.hasOwnProperty("fieldName"))
                        object.fieldName = message.fieldName;
                    if (message.foundFromApi != null && message.hasOwnProperty("foundFromApi"))
                        object.foundFromApi = message.foundFromApi;
                    if (message.isCorrect != null && message.hasOwnProperty("isCorrect"))
                        object.isCorrect = message.isCorrect;
                    if (message.autoPopulated != null && message.hasOwnProperty("autoPopulated"))
                        object.autoPopulated = message.autoPopulated;
                    if (message.hasActual != null && message.hasOwnProperty("hasActual"))
                        object.hasActual = message.hasActual;
                    return object;
                };
    
                /**
                 * Converts this EbvMetric to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EbvMetric
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EbvMetric.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EbvMetric
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EbvMetric
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EbvMetric.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EbvMetric";
                };
    
                return EbvMetric;
            })();
    
            /**
             * TaskStateTransition enum.
             * @name infinitusai.be.TaskStateTransition
             * @enum {number}
             * @property {number} TASK_STATE_TRANSITION_UNKNOWN=0 TASK_STATE_TRANSITION_UNKNOWN value
             * @property {number} TASK_STATE_TRANSITION_FAIL_TASK=1 TASK_STATE_TRANSITION_FAIL_TASK value
             * @property {number} TASK_STATE_TRANSITION_LEAVE_TASK_DISCONNECTED=2 TASK_STATE_TRANSITION_LEAVE_TASK_DISCONNECTED value
             * @property {number} TASK_STATE_TRANSITION_REQUEUE_TASK=3 TASK_STATE_TRANSITION_REQUEUE_TASK value
             * @property {number} TASK_STATE_TRANSITION_SEND_TO_REVIEW=4 TASK_STATE_TRANSITION_SEND_TO_REVIEW value
             * @property {number} TASK_STATE_TRANSITION_AUTO_REQUEUE_TASK=5 TASK_STATE_TRANSITION_AUTO_REQUEUE_TASK value
             */
            be.TaskStateTransition = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TASK_STATE_TRANSITION_UNKNOWN"] = 0;
                values[valuesById[1] = "TASK_STATE_TRANSITION_FAIL_TASK"] = 1;
                values[valuesById[2] = "TASK_STATE_TRANSITION_LEAVE_TASK_DISCONNECTED"] = 2;
                values[valuesById[3] = "TASK_STATE_TRANSITION_REQUEUE_TASK"] = 3;
                values[valuesById[4] = "TASK_STATE_TRANSITION_SEND_TO_REVIEW"] = 4;
                values[valuesById[5] = "TASK_STATE_TRANSITION_AUTO_REQUEUE_TASK"] = 5;
                return values;
            })();
    
            be.TaskDoc = (function() {
    
                /**
                 * Properties of a TaskDoc.
                 * @memberof infinitusai.be
                 * @interface ITaskDoc
                 * @property {string|null} [orgUuid] TaskDoc orgUuid
                 * @property {string|null} [programName] TaskDoc programName
                 * @property {Array.<string>|null} [callUuids] TaskDoc callUuids
                 * @property {infinitusai.be.TaskType|null} [taskType] TaskDoc taskType
                 * @property {infinitusai.be.TaskState|null} [state] TaskDoc state
                 * @property {infinitusai.be.IBVCallInputs|null} [bvInputs] TaskDoc bvInputs
                 * @property {infinitusai.be.TaskSource|null} [taskSource] TaskDoc taskSource
                 * @property {infinitusai.be.IUserInfo|null} [taskCreator] TaskDoc taskCreator
                 * @property {number|Long|null} [taskCreationMillis] TaskDoc taskCreationMillis
                 * @property {infinitusai.be.IMedSupBVCallOutputs|null} [medsupBvOutputs] TaskDoc medsupBvOutputs
                 * @property {infinitusai.be.IFullBVCallOutputs|null} [fullBvOutputs] TaskDoc fullBvOutputs
                 * @property {infinitusai.be.IPBMBVCallOutputs|null} [pbmBvOutputs] TaskDoc pbmBvOutputs
                 * @property {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null} [covidVaccineAvailabilityCallOutputs] TaskDoc covidVaccineAvailabilityCallOutputs
                 * @property {infinitusai.be.IUserInfo|null} [taskReviewer] TaskDoc taskReviewer
                 * @property {number|Long|null} [taskReviewMillis] TaskDoc taskReviewMillis
                 * @property {string|null} [customerAssignedId] TaskDoc customerAssignedId
                 * @property {string|null} [taskNotes] TaskDoc taskNotes
                 * @property {infinitusai.be.ITaskFailReason|null} [failureReasonChosen] TaskDoc failureReasonChosen
                 * @property {string|null} [authoritativeCallUuid] TaskDoc authoritativeCallUuid
                 * @property {infinitusai.be.IUserInfo|null} [readyForReviewUser] TaskDoc readyForReviewUser
                 * @property {number|Long|null} [readyForReviewMillis] TaskDoc readyForReviewMillis
                 * @property {number|Long|null} [taskUpdatedMillis] TaskDoc taskUpdatedMillis
                 * @property {infinitusai.be.IUserInfo|null} [reviewClaimUser] TaskDoc reviewClaimUser
                 * @property {number|Long|null} [reviewClaimMillis] TaskDoc reviewClaimMillis
                 * @property {string|null} [outputSummary] TaskDoc outputSummary
                 * @property {infinitusai.be.ICustomerReview|null} [customerReview] TaskDoc customerReview
                 * @property {infinitusai.be.INullableBool|null} [customerReviewExists] TaskDoc customerReviewExists
                 * @property {boolean|null} [operatorTrainerMode] TaskDoc operatorTrainerMode
                 * @property {infinitusai.be.IChangeHealthcareOutputs|null} [chcOutputs] TaskDoc chcOutputs
                 * @property {number|Long|null} [chcOutputsCreatedAtMillis] TaskDoc chcOutputsCreatedAtMillis
                 * @property {string|null} [chcOutputsRawDataFilePath] TaskDoc chcOutputsRawDataFilePath
                 * @property {boolean|null} [chcOutputsAvailable] TaskDoc chcOutputsAvailable
                 * @property {Array.<infinitusai.be.Curveball>|null} [curveballs] TaskDoc curveballs
                 * @property {infinitusai.be.ITaskWebhook|null} [webhook] TaskDoc webhook
                 * @property {infinitusai.be.INullableBool|null} [hasPotentialAdverseEvent] TaskDoc hasPotentialAdverseEvent
                 * @property {string|null} [potentialAdverseEventNotes] TaskDoc potentialAdverseEventNotes
                 * @property {infinitusai.be.ICallOutputs|null} [outOfNetworkOutputs] TaskDoc outOfNetworkOutputs
                 * @property {string|null} [customerUrl] TaskDoc customerUrl
                 * @property {infinitusai.be.IPayerIntelligenceState|null} [payerIntelligenceStateAtEscalation] TaskDoc payerIntelligenceStateAtEscalation
                 * @property {Array.<string>|null} [additionalRecordingUuids] TaskDoc additionalRecordingUuids
                 * @property {infinitusai.be.ICallOutputs|null} [previousBvOutputs] TaskDoc previousBvOutputs
                 * @property {Array.<infinitusai.be.TaskAnnotation>|null} [annotations] TaskDoc annotations
                 * @property {Array.<infinitusai.be.TaskDoc.IHeadlessHangup>|null} [headlessHangups] TaskDoc headlessHangups
                 * @property {string|null} [customerTaskUuid] TaskDoc customerTaskUuid
                 * @property {Object.<string,number>|null} [requeueReasonCounters] TaskDoc requeueReasonCounters
                 * @property {string|null} [taskNotesInternal] TaskDoc taskNotesInternal
                 * @property {boolean|null} [loadTestMode] TaskDoc loadTestMode
                 * @property {infinitusai.be.TaskStateTransition|null} [taskStateTransition] TaskDoc taskStateTransition
                 * @property {number|Long|null} [taskDueTimestampMillis] TaskDoc taskDueTimestampMillis
                 * @property {boolean|null} [annualReenrollment] TaskDoc annualReenrollment
                 * @property {boolean|null} [needsHumanIntro] TaskDoc needsHumanIntro
                 * @property {string|null} [operatorTrainerScriptId] TaskDoc operatorTrainerScriptId
                 * @property {boolean|null} [isInMultitaskCall] TaskDoc isInMultitaskCall
                 * @property {string|null} [customerSegment] TaskDoc customerSegment
                 * @property {boolean|null} [isMultiBenefitTask] TaskDoc isMultiBenefitTask
                 * @property {string|null} [multiBenefitTaskId] TaskDoc multiBenefitTaskId
                 * @property {string|null} [baseCallUuidForNextCall] TaskDoc baseCallUuidForNextCall
                 * @property {Array.<infinitusai.be.IAutoRequeueConfig>|null} [autoRequeueConfigs] TaskDoc autoRequeueConfigs
                 * @property {boolean|null} [overridingHumanCallsToRealisticCall] TaskDoc overridingHumanCallsToRealisticCall
                 * @property {boolean|null} [needsManualIvr] TaskDoc needsManualIvr
                 * @property {infinitusai.be.INullableBool|null} [isFtiTask] TaskDoc isFtiTask
                 * @property {number|Long|null} [nextVendorApiCallTimestampMillis] TaskDoc nextVendorApiCallTimestampMillis
                 * @property {number|null} [pverifyApiCallCount] TaskDoc pverifyApiCallCount
                 * @property {string|null} [previousBvDate] TaskDoc previousBvDate
                 * @property {string|null} [previousPbmDate] TaskDoc previousPbmDate
                 * @property {infinitusai.be.IFastTrackSuccessfulTaskNotes|null} [fastTrackSuccessfulTaskNotes] TaskDoc fastTrackSuccessfulTaskNotes
                 * @property {boolean|null} [routeUserOnCallStart] TaskDoc routeUserOnCallStart
                 * @property {infinitusai.be.IUserInfo|null} [lastTaskAssignee] TaskDoc lastTaskAssignee
                 * @property {boolean|null} [fullyDigital] TaskDoc fullyDigital
                 */
    
                /**
                 * Constructs a new TaskDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TaskDoc.
                 * @implements ITaskDoc
                 * @constructor
                 * @param {infinitusai.be.ITaskDoc=} [properties] Properties to set
                 */
                function TaskDoc(properties) {
                    this.callUuids = [];
                    this.curveballs = [];
                    this.additionalRecordingUuids = [];
                    this.annotations = [];
                    this.headlessHangups = [];
                    this.requeueReasonCounters = {};
                    this.autoRequeueConfigs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskDoc orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.orgUuid = "";
    
                /**
                 * TaskDoc programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.programName = "";
    
                /**
                 * TaskDoc callUuids.
                 * @member {Array.<string>} callUuids
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.callUuids = $util.emptyArray;
    
                /**
                 * TaskDoc taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskType = 0;
    
                /**
                 * TaskDoc state.
                 * @member {infinitusai.be.TaskState} state
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.state = 0;
    
                /**
                 * TaskDoc bvInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} bvInputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.bvInputs = null;
    
                /**
                 * TaskDoc taskSource.
                 * @member {infinitusai.be.TaskSource} taskSource
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskSource = 0;
    
                /**
                 * TaskDoc taskCreator.
                 * @member {infinitusai.be.IUserInfo|null|undefined} taskCreator
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskCreator = null;
    
                /**
                 * TaskDoc taskCreationMillis.
                 * @member {number|Long} taskCreationMillis
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskCreationMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDoc medsupBvOutputs.
                 * @member {infinitusai.be.IMedSupBVCallOutputs|null|undefined} medsupBvOutputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.medsupBvOutputs = null;
    
                /**
                 * TaskDoc fullBvOutputs.
                 * @member {infinitusai.be.IFullBVCallOutputs|null|undefined} fullBvOutputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.fullBvOutputs = null;
    
                /**
                 * TaskDoc pbmBvOutputs.
                 * @member {infinitusai.be.IPBMBVCallOutputs|null|undefined} pbmBvOutputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.pbmBvOutputs = null;
    
                /**
                 * TaskDoc covidVaccineAvailabilityCallOutputs.
                 * @member {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null|undefined} covidVaccineAvailabilityCallOutputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.covidVaccineAvailabilityCallOutputs = null;
    
                /**
                 * TaskDoc taskReviewer.
                 * @member {infinitusai.be.IUserInfo|null|undefined} taskReviewer
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskReviewer = null;
    
                /**
                 * TaskDoc taskReviewMillis.
                 * @member {number|Long} taskReviewMillis
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskReviewMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDoc customerAssignedId.
                 * @member {string} customerAssignedId
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.customerAssignedId = "";
    
                /**
                 * TaskDoc taskNotes.
                 * @member {string} taskNotes
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskNotes = "";
    
                /**
                 * TaskDoc failureReasonChosen.
                 * @member {infinitusai.be.ITaskFailReason|null|undefined} failureReasonChosen
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.failureReasonChosen = null;
    
                /**
                 * TaskDoc authoritativeCallUuid.
                 * @member {string} authoritativeCallUuid
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.authoritativeCallUuid = "";
    
                /**
                 * TaskDoc readyForReviewUser.
                 * @member {infinitusai.be.IUserInfo|null|undefined} readyForReviewUser
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.readyForReviewUser = null;
    
                /**
                 * TaskDoc readyForReviewMillis.
                 * @member {number|Long} readyForReviewMillis
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.readyForReviewMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDoc taskUpdatedMillis.
                 * @member {number|Long} taskUpdatedMillis
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskUpdatedMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDoc reviewClaimUser.
                 * @member {infinitusai.be.IUserInfo|null|undefined} reviewClaimUser
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.reviewClaimUser = null;
    
                /**
                 * TaskDoc reviewClaimMillis.
                 * @member {number|Long} reviewClaimMillis
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.reviewClaimMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDoc outputSummary.
                 * @member {string} outputSummary
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.outputSummary = "";
    
                /**
                 * TaskDoc customerReview.
                 * @member {infinitusai.be.ICustomerReview|null|undefined} customerReview
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.customerReview = null;
    
                /**
                 * TaskDoc customerReviewExists.
                 * @member {infinitusai.be.INullableBool|null|undefined} customerReviewExists
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.customerReviewExists = null;
    
                /**
                 * TaskDoc operatorTrainerMode.
                 * @member {boolean} operatorTrainerMode
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.operatorTrainerMode = false;
    
                /**
                 * TaskDoc chcOutputs.
                 * @member {infinitusai.be.IChangeHealthcareOutputs|null|undefined} chcOutputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.chcOutputs = null;
    
                /**
                 * TaskDoc chcOutputsCreatedAtMillis.
                 * @member {number|Long} chcOutputsCreatedAtMillis
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.chcOutputsCreatedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDoc chcOutputsRawDataFilePath.
                 * @member {string} chcOutputsRawDataFilePath
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.chcOutputsRawDataFilePath = "";
    
                /**
                 * TaskDoc chcOutputsAvailable.
                 * @member {boolean} chcOutputsAvailable
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.chcOutputsAvailable = false;
    
                /**
                 * TaskDoc curveballs.
                 * @member {Array.<infinitusai.be.Curveball>} curveballs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.curveballs = $util.emptyArray;
    
                /**
                 * TaskDoc webhook.
                 * @member {infinitusai.be.ITaskWebhook|null|undefined} webhook
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.webhook = null;
    
                /**
                 * TaskDoc hasPotentialAdverseEvent.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasPotentialAdverseEvent
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.hasPotentialAdverseEvent = null;
    
                /**
                 * TaskDoc potentialAdverseEventNotes.
                 * @member {string} potentialAdverseEventNotes
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.potentialAdverseEventNotes = "";
    
                /**
                 * TaskDoc outOfNetworkOutputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} outOfNetworkOutputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.outOfNetworkOutputs = null;
    
                /**
                 * TaskDoc customerUrl.
                 * @member {string} customerUrl
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.customerUrl = "";
    
                /**
                 * TaskDoc payerIntelligenceStateAtEscalation.
                 * @member {infinitusai.be.IPayerIntelligenceState|null|undefined} payerIntelligenceStateAtEscalation
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.payerIntelligenceStateAtEscalation = null;
    
                /**
                 * TaskDoc additionalRecordingUuids.
                 * @member {Array.<string>} additionalRecordingUuids
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.additionalRecordingUuids = $util.emptyArray;
    
                /**
                 * TaskDoc previousBvOutputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} previousBvOutputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.previousBvOutputs = null;
    
                /**
                 * TaskDoc annotations.
                 * @member {Array.<infinitusai.be.TaskAnnotation>} annotations
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.annotations = $util.emptyArray;
    
                /**
                 * TaskDoc headlessHangups.
                 * @member {Array.<infinitusai.be.TaskDoc.IHeadlessHangup>} headlessHangups
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.headlessHangups = $util.emptyArray;
    
                /**
                 * TaskDoc customerTaskUuid.
                 * @member {string} customerTaskUuid
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.customerTaskUuid = "";
    
                /**
                 * TaskDoc requeueReasonCounters.
                 * @member {Object.<string,number>} requeueReasonCounters
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.requeueReasonCounters = $util.emptyObject;
    
                /**
                 * TaskDoc taskNotesInternal.
                 * @member {string} taskNotesInternal
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskNotesInternal = "";
    
                /**
                 * TaskDoc loadTestMode.
                 * @member {boolean} loadTestMode
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.loadTestMode = false;
    
                /**
                 * TaskDoc taskStateTransition.
                 * @member {infinitusai.be.TaskStateTransition} taskStateTransition
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskStateTransition = 0;
    
                /**
                 * TaskDoc taskDueTimestampMillis.
                 * @member {number|Long} taskDueTimestampMillis
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.taskDueTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDoc annualReenrollment.
                 * @member {boolean} annualReenrollment
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.annualReenrollment = false;
    
                /**
                 * TaskDoc needsHumanIntro.
                 * @member {boolean} needsHumanIntro
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.needsHumanIntro = false;
    
                /**
                 * TaskDoc operatorTrainerScriptId.
                 * @member {string} operatorTrainerScriptId
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.operatorTrainerScriptId = "";
    
                /**
                 * TaskDoc isInMultitaskCall.
                 * @member {boolean} isInMultitaskCall
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.isInMultitaskCall = false;
    
                /**
                 * TaskDoc customerSegment.
                 * @member {string} customerSegment
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.customerSegment = "";
    
                /**
                 * TaskDoc isMultiBenefitTask.
                 * @member {boolean} isMultiBenefitTask
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.isMultiBenefitTask = false;
    
                /**
                 * TaskDoc multiBenefitTaskId.
                 * @member {string} multiBenefitTaskId
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.multiBenefitTaskId = "";
    
                /**
                 * TaskDoc baseCallUuidForNextCall.
                 * @member {string} baseCallUuidForNextCall
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.baseCallUuidForNextCall = "";
    
                /**
                 * TaskDoc autoRequeueConfigs.
                 * @member {Array.<infinitusai.be.IAutoRequeueConfig>} autoRequeueConfigs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.autoRequeueConfigs = $util.emptyArray;
    
                /**
                 * TaskDoc overridingHumanCallsToRealisticCall.
                 * @member {boolean} overridingHumanCallsToRealisticCall
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.overridingHumanCallsToRealisticCall = false;
    
                /**
                 * TaskDoc needsManualIvr.
                 * @member {boolean} needsManualIvr
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.needsManualIvr = false;
    
                /**
                 * TaskDoc isFtiTask.
                 * @member {infinitusai.be.INullableBool|null|undefined} isFtiTask
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.isFtiTask = null;
    
                /**
                 * TaskDoc nextVendorApiCallTimestampMillis.
                 * @member {number|Long} nextVendorApiCallTimestampMillis
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.nextVendorApiCallTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDoc pverifyApiCallCount.
                 * @member {number} pverifyApiCallCount
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.pverifyApiCallCount = 0;
    
                /**
                 * TaskDoc previousBvDate.
                 * @member {string} previousBvDate
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.previousBvDate = "";
    
                /**
                 * TaskDoc previousPbmDate.
                 * @member {string} previousPbmDate
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.previousPbmDate = "";
    
                /**
                 * TaskDoc fastTrackSuccessfulTaskNotes.
                 * @member {infinitusai.be.IFastTrackSuccessfulTaskNotes|null|undefined} fastTrackSuccessfulTaskNotes
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.fastTrackSuccessfulTaskNotes = null;
    
                /**
                 * TaskDoc routeUserOnCallStart.
                 * @member {boolean} routeUserOnCallStart
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.routeUserOnCallStart = false;
    
                /**
                 * TaskDoc lastTaskAssignee.
                 * @member {infinitusai.be.IUserInfo|null|undefined} lastTaskAssignee
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.lastTaskAssignee = null;
    
                /**
                 * TaskDoc fullyDigital.
                 * @member {boolean} fullyDigital
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                TaskDoc.prototype.fullyDigital = false;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * TaskDoc inputs.
                 * @member {"bvInputs"|undefined} inputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                Object.defineProperty(TaskDoc.prototype, "inputs", {
                    get: $util.oneOfGetter($oneOfFields = ["bvInputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * TaskDoc outputs.
                 * @member {"medsupBvOutputs"|"fullBvOutputs"|"pbmBvOutputs"|"covidVaccineAvailabilityCallOutputs"|undefined} outputs
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 */
                Object.defineProperty(TaskDoc.prototype, "outputs", {
                    get: $util.oneOfGetter($oneOfFields = ["medsupBvOutputs", "fullBvOutputs", "pbmBvOutputs", "covidVaccineAvailabilityCallOutputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new TaskDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {infinitusai.be.ITaskDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.TaskDoc} TaskDoc instance
                 */
                TaskDoc.create = function create(properties) {
                    return new TaskDoc(properties);
                };
    
                /**
                 * Encodes the specified TaskDoc message. Does not implicitly {@link infinitusai.be.TaskDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {infinitusai.be.ITaskDoc} message TaskDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.programName);
                    if (message.callUuids != null && message.callUuids.length)
                        for (var i = 0; i < message.callUuids.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuids[i]);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.taskType);
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.state);
                    if (message.bvInputs != null && Object.hasOwnProperty.call(message, "bvInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.bvInputs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.taskSource != null && Object.hasOwnProperty.call(message, "taskSource"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.taskSource);
                    if (message.taskCreator != null && Object.hasOwnProperty.call(message, "taskCreator"))
                        $root.infinitusai.be.UserInfo.encode(message.taskCreator, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.taskCreationMillis != null && Object.hasOwnProperty.call(message, "taskCreationMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.taskCreationMillis);
                    if (message.medsupBvOutputs != null && Object.hasOwnProperty.call(message, "medsupBvOutputs"))
                        $root.infinitusai.be.MedSupBVCallOutputs.encode(message.medsupBvOutputs, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.fullBvOutputs != null && Object.hasOwnProperty.call(message, "fullBvOutputs"))
                        $root.infinitusai.be.FullBVCallOutputs.encode(message.fullBvOutputs, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.taskReviewer != null && Object.hasOwnProperty.call(message, "taskReviewer"))
                        $root.infinitusai.be.UserInfo.encode(message.taskReviewer, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.taskReviewMillis != null && Object.hasOwnProperty.call(message, "taskReviewMillis"))
                        writer.uint32(/* id 13, wireType 0 =*/104).int64(message.taskReviewMillis);
                    if (message.customerAssignedId != null && Object.hasOwnProperty.call(message, "customerAssignedId"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.customerAssignedId);
                    if (message.taskNotes != null && Object.hasOwnProperty.call(message, "taskNotes"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.taskNotes);
                    if (message.failureReasonChosen != null && Object.hasOwnProperty.call(message, "failureReasonChosen"))
                        $root.infinitusai.be.TaskFailReason.encode(message.failureReasonChosen, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.authoritativeCallUuid != null && Object.hasOwnProperty.call(message, "authoritativeCallUuid"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.authoritativeCallUuid);
                    if (message.readyForReviewUser != null && Object.hasOwnProperty.call(message, "readyForReviewUser"))
                        $root.infinitusai.be.UserInfo.encode(message.readyForReviewUser, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.readyForReviewMillis != null && Object.hasOwnProperty.call(message, "readyForReviewMillis"))
                        writer.uint32(/* id 19, wireType 0 =*/152).int64(message.readyForReviewMillis);
                    if (message.taskUpdatedMillis != null && Object.hasOwnProperty.call(message, "taskUpdatedMillis"))
                        writer.uint32(/* id 20, wireType 0 =*/160).int64(message.taskUpdatedMillis);
                    if (message.reviewClaimUser != null && Object.hasOwnProperty.call(message, "reviewClaimUser"))
                        $root.infinitusai.be.UserInfo.encode(message.reviewClaimUser, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    if (message.reviewClaimMillis != null && Object.hasOwnProperty.call(message, "reviewClaimMillis"))
                        writer.uint32(/* id 22, wireType 0 =*/176).int64(message.reviewClaimMillis);
                    if (message.outputSummary != null && Object.hasOwnProperty.call(message, "outputSummary"))
                        writer.uint32(/* id 23, wireType 2 =*/186).string(message.outputSummary);
                    if (message.customerReview != null && Object.hasOwnProperty.call(message, "customerReview"))
                        $root.infinitusai.be.CustomerReview.encode(message.customerReview, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                    if (message.operatorTrainerMode != null && Object.hasOwnProperty.call(message, "operatorTrainerMode"))
                        writer.uint32(/* id 25, wireType 0 =*/200).bool(message.operatorTrainerMode);
                    if (message.chcOutputs != null && Object.hasOwnProperty.call(message, "chcOutputs"))
                        $root.infinitusai.be.ChangeHealthcareOutputs.encode(message.chcOutputs, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                    if (message.chcOutputsCreatedAtMillis != null && Object.hasOwnProperty.call(message, "chcOutputsCreatedAtMillis"))
                        writer.uint32(/* id 27, wireType 0 =*/216).int64(message.chcOutputsCreatedAtMillis);
                    if (message.chcOutputsRawDataFilePath != null && Object.hasOwnProperty.call(message, "chcOutputsRawDataFilePath"))
                        writer.uint32(/* id 28, wireType 2 =*/226).string(message.chcOutputsRawDataFilePath);
                    if (message.chcOutputsAvailable != null && Object.hasOwnProperty.call(message, "chcOutputsAvailable"))
                        writer.uint32(/* id 29, wireType 0 =*/232).bool(message.chcOutputsAvailable);
                    if (message.pbmBvOutputs != null && Object.hasOwnProperty.call(message, "pbmBvOutputs"))
                        $root.infinitusai.be.PBMBVCallOutputs.encode(message.pbmBvOutputs, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                    if (message.curveballs != null && message.curveballs.length) {
                        writer.uint32(/* id 31, wireType 2 =*/250).fork();
                        for (var i = 0; i < message.curveballs.length; ++i)
                            writer.int32(message.curveballs[i]);
                        writer.ldelim();
                    }
                    if (message.webhook != null && Object.hasOwnProperty.call(message, "webhook"))
                        $root.infinitusai.be.TaskWebhook.encode(message.webhook, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                    if (message.hasPotentialAdverseEvent != null && Object.hasOwnProperty.call(message, "hasPotentialAdverseEvent"))
                        $root.infinitusai.be.NullableBool.encode(message.hasPotentialAdverseEvent, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                    if (message.potentialAdverseEventNotes != null && Object.hasOwnProperty.call(message, "potentialAdverseEventNotes"))
                        writer.uint32(/* id 34, wireType 2 =*/274).string(message.potentialAdverseEventNotes);
                    if (message.outOfNetworkOutputs != null && Object.hasOwnProperty.call(message, "outOfNetworkOutputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.outOfNetworkOutputs, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                    if (message.customerUrl != null && Object.hasOwnProperty.call(message, "customerUrl"))
                        writer.uint32(/* id 36, wireType 2 =*/290).string(message.customerUrl);
                    if (message.payerIntelligenceStateAtEscalation != null && Object.hasOwnProperty.call(message, "payerIntelligenceStateAtEscalation"))
                        $root.infinitusai.be.PayerIntelligenceState.encode(message.payerIntelligenceStateAtEscalation, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                    if (message.additionalRecordingUuids != null && message.additionalRecordingUuids.length)
                        for (var i = 0; i < message.additionalRecordingUuids.length; ++i)
                            writer.uint32(/* id 38, wireType 2 =*/306).string(message.additionalRecordingUuids[i]);
                    if (message.previousBvOutputs != null && Object.hasOwnProperty.call(message, "previousBvOutputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.previousBvOutputs, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                    if (message.covidVaccineAvailabilityCallOutputs != null && Object.hasOwnProperty.call(message, "covidVaccineAvailabilityCallOutputs"))
                        $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.encode(message.covidVaccineAvailabilityCallOutputs, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                    if (message.annotations != null && message.annotations.length) {
                        writer.uint32(/* id 41, wireType 2 =*/330).fork();
                        for (var i = 0; i < message.annotations.length; ++i)
                            writer.int32(message.annotations[i]);
                        writer.ldelim();
                    }
                    if (message.headlessHangups != null && message.headlessHangups.length)
                        for (var i = 0; i < message.headlessHangups.length; ++i)
                            $root.infinitusai.be.TaskDoc.HeadlessHangup.encode(message.headlessHangups[i], writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                    if (message.customerReviewExists != null && Object.hasOwnProperty.call(message, "customerReviewExists"))
                        $root.infinitusai.be.NullableBool.encode(message.customerReviewExists, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                    if (message.customerTaskUuid != null && Object.hasOwnProperty.call(message, "customerTaskUuid"))
                        writer.uint32(/* id 44, wireType 2 =*/354).string(message.customerTaskUuid);
                    if (message.requeueReasonCounters != null && Object.hasOwnProperty.call(message, "requeueReasonCounters"))
                        for (var keys = Object.keys(message.requeueReasonCounters), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 46, wireType 2 =*/370).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.requeueReasonCounters[keys[i]]).ldelim();
                    if (message.taskNotesInternal != null && Object.hasOwnProperty.call(message, "taskNotesInternal"))
                        writer.uint32(/* id 47, wireType 2 =*/378).string(message.taskNotesInternal);
                    if (message.loadTestMode != null && Object.hasOwnProperty.call(message, "loadTestMode"))
                        writer.uint32(/* id 48, wireType 0 =*/384).bool(message.loadTestMode);
                    if (message.taskStateTransition != null && Object.hasOwnProperty.call(message, "taskStateTransition"))
                        writer.uint32(/* id 49, wireType 0 =*/392).int32(message.taskStateTransition);
                    if (message.taskDueTimestampMillis != null && Object.hasOwnProperty.call(message, "taskDueTimestampMillis"))
                        writer.uint32(/* id 50, wireType 0 =*/400).int64(message.taskDueTimestampMillis);
                    if (message.annualReenrollment != null && Object.hasOwnProperty.call(message, "annualReenrollment"))
                        writer.uint32(/* id 51, wireType 0 =*/408).bool(message.annualReenrollment);
                    if (message.needsHumanIntro != null && Object.hasOwnProperty.call(message, "needsHumanIntro"))
                        writer.uint32(/* id 52, wireType 0 =*/416).bool(message.needsHumanIntro);
                    if (message.operatorTrainerScriptId != null && Object.hasOwnProperty.call(message, "operatorTrainerScriptId"))
                        writer.uint32(/* id 53, wireType 2 =*/426).string(message.operatorTrainerScriptId);
                    if (message.isInMultitaskCall != null && Object.hasOwnProperty.call(message, "isInMultitaskCall"))
                        writer.uint32(/* id 54, wireType 0 =*/432).bool(message.isInMultitaskCall);
                    if (message.customerSegment != null && Object.hasOwnProperty.call(message, "customerSegment"))
                        writer.uint32(/* id 55, wireType 2 =*/442).string(message.customerSegment);
                    if (message.isMultiBenefitTask != null && Object.hasOwnProperty.call(message, "isMultiBenefitTask"))
                        writer.uint32(/* id 56, wireType 0 =*/448).bool(message.isMultiBenefitTask);
                    if (message.multiBenefitTaskId != null && Object.hasOwnProperty.call(message, "multiBenefitTaskId"))
                        writer.uint32(/* id 57, wireType 2 =*/458).string(message.multiBenefitTaskId);
                    if (message.baseCallUuidForNextCall != null && Object.hasOwnProperty.call(message, "baseCallUuidForNextCall"))
                        writer.uint32(/* id 58, wireType 2 =*/466).string(message.baseCallUuidForNextCall);
                    if (message.autoRequeueConfigs != null && message.autoRequeueConfigs.length)
                        for (var i = 0; i < message.autoRequeueConfigs.length; ++i)
                            $root.infinitusai.be.AutoRequeueConfig.encode(message.autoRequeueConfigs[i], writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
                    if (message.overridingHumanCallsToRealisticCall != null && Object.hasOwnProperty.call(message, "overridingHumanCallsToRealisticCall"))
                        writer.uint32(/* id 60, wireType 0 =*/480).bool(message.overridingHumanCallsToRealisticCall);
                    if (message.needsManualIvr != null && Object.hasOwnProperty.call(message, "needsManualIvr"))
                        writer.uint32(/* id 61, wireType 0 =*/488).bool(message.needsManualIvr);
                    if (message.isFtiTask != null && Object.hasOwnProperty.call(message, "isFtiTask"))
                        $root.infinitusai.be.NullableBool.encode(message.isFtiTask, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
                    if (message.nextVendorApiCallTimestampMillis != null && Object.hasOwnProperty.call(message, "nextVendorApiCallTimestampMillis"))
                        writer.uint32(/* id 63, wireType 0 =*/504).int64(message.nextVendorApiCallTimestampMillis);
                    if (message.pverifyApiCallCount != null && Object.hasOwnProperty.call(message, "pverifyApiCallCount"))
                        writer.uint32(/* id 64, wireType 0 =*/512).int32(message.pverifyApiCallCount);
                    if (message.previousBvDate != null && Object.hasOwnProperty.call(message, "previousBvDate"))
                        writer.uint32(/* id 65, wireType 2 =*/522).string(message.previousBvDate);
                    if (message.previousPbmDate != null && Object.hasOwnProperty.call(message, "previousPbmDate"))
                        writer.uint32(/* id 66, wireType 2 =*/530).string(message.previousPbmDate);
                    if (message.fastTrackSuccessfulTaskNotes != null && Object.hasOwnProperty.call(message, "fastTrackSuccessfulTaskNotes"))
                        $root.infinitusai.be.FastTrackSuccessfulTaskNotes.encode(message.fastTrackSuccessfulTaskNotes, writer.uint32(/* id 67, wireType 2 =*/538).fork()).ldelim();
                    if (message.routeUserOnCallStart != null && Object.hasOwnProperty.call(message, "routeUserOnCallStart"))
                        writer.uint32(/* id 68, wireType 0 =*/544).bool(message.routeUserOnCallStart);
                    if (message.lastTaskAssignee != null && Object.hasOwnProperty.call(message, "lastTaskAssignee"))
                        $root.infinitusai.be.UserInfo.encode(message.lastTaskAssignee, writer.uint32(/* id 69, wireType 2 =*/554).fork()).ldelim();
                    if (message.fullyDigital != null && Object.hasOwnProperty.call(message, "fullyDigital"))
                        writer.uint32(/* id 70, wireType 0 =*/560).bool(message.fullyDigital);
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskDoc message, length delimited. Does not implicitly {@link infinitusai.be.TaskDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {infinitusai.be.ITaskDoc} message TaskDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TaskDoc} TaskDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskDoc(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.programName = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.callUuids && message.callUuids.length))
                                    message.callUuids = [];
                                message.callUuids.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 5: {
                                message.state = reader.int32();
                                break;
                            }
                        case 6: {
                                message.bvInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.taskSource = reader.int32();
                                break;
                            }
                        case 8: {
                                message.taskCreator = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.taskCreationMillis = reader.int64();
                                break;
                            }
                        case 10: {
                                message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 30: {
                                message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 40: {
                                message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.taskReviewer = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.taskReviewMillis = reader.int64();
                                break;
                            }
                        case 14: {
                                message.customerAssignedId = reader.string();
                                break;
                            }
                        case 15: {
                                message.taskNotes = reader.string();
                                break;
                            }
                        case 16: {
                                message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.authoritativeCallUuid = reader.string();
                                break;
                            }
                        case 18: {
                                message.readyForReviewUser = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.readyForReviewMillis = reader.int64();
                                break;
                            }
                        case 20: {
                                message.taskUpdatedMillis = reader.int64();
                                break;
                            }
                        case 21: {
                                message.reviewClaimUser = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 22: {
                                message.reviewClaimMillis = reader.int64();
                                break;
                            }
                        case 23: {
                                message.outputSummary = reader.string();
                                break;
                            }
                        case 24: {
                                message.customerReview = $root.infinitusai.be.CustomerReview.decode(reader, reader.uint32());
                                break;
                            }
                        case 43: {
                                message.customerReviewExists = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 25: {
                                message.operatorTrainerMode = reader.bool();
                                break;
                            }
                        case 26: {
                                message.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 27: {
                                message.chcOutputsCreatedAtMillis = reader.int64();
                                break;
                            }
                        case 28: {
                                message.chcOutputsRawDataFilePath = reader.string();
                                break;
                            }
                        case 29: {
                                message.chcOutputsAvailable = reader.bool();
                                break;
                            }
                        case 31: {
                                if (!(message.curveballs && message.curveballs.length))
                                    message.curveballs = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.curveballs.push(reader.int32());
                                } else
                                    message.curveballs.push(reader.int32());
                                break;
                            }
                        case 32: {
                                message.webhook = $root.infinitusai.be.TaskWebhook.decode(reader, reader.uint32());
                                break;
                            }
                        case 33: {
                                message.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 34: {
                                message.potentialAdverseEventNotes = reader.string();
                                break;
                            }
                        case 35: {
                                message.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 36: {
                                message.customerUrl = reader.string();
                                break;
                            }
                        case 37: {
                                message.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.decode(reader, reader.uint32());
                                break;
                            }
                        case 38: {
                                if (!(message.additionalRecordingUuids && message.additionalRecordingUuids.length))
                                    message.additionalRecordingUuids = [];
                                message.additionalRecordingUuids.push(reader.string());
                                break;
                            }
                        case 39: {
                                message.previousBvOutputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 41: {
                                if (!(message.annotations && message.annotations.length))
                                    message.annotations = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.annotations.push(reader.int32());
                                } else
                                    message.annotations.push(reader.int32());
                                break;
                            }
                        case 42: {
                                if (!(message.headlessHangups && message.headlessHangups.length))
                                    message.headlessHangups = [];
                                message.headlessHangups.push($root.infinitusai.be.TaskDoc.HeadlessHangup.decode(reader, reader.uint32()));
                                break;
                            }
                        case 44: {
                                message.customerTaskUuid = reader.string();
                                break;
                            }
                        case 46: {
                                if (message.requeueReasonCounters === $util.emptyObject)
                                    message.requeueReasonCounters = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.int32();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.requeueReasonCounters[key] = value;
                                break;
                            }
                        case 47: {
                                message.taskNotesInternal = reader.string();
                                break;
                            }
                        case 48: {
                                message.loadTestMode = reader.bool();
                                break;
                            }
                        case 49: {
                                message.taskStateTransition = reader.int32();
                                break;
                            }
                        case 50: {
                                message.taskDueTimestampMillis = reader.int64();
                                break;
                            }
                        case 51: {
                                message.annualReenrollment = reader.bool();
                                break;
                            }
                        case 52: {
                                message.needsHumanIntro = reader.bool();
                                break;
                            }
                        case 53: {
                                message.operatorTrainerScriptId = reader.string();
                                break;
                            }
                        case 54: {
                                message.isInMultitaskCall = reader.bool();
                                break;
                            }
                        case 55: {
                                message.customerSegment = reader.string();
                                break;
                            }
                        case 56: {
                                message.isMultiBenefitTask = reader.bool();
                                break;
                            }
                        case 57: {
                                message.multiBenefitTaskId = reader.string();
                                break;
                            }
                        case 58: {
                                message.baseCallUuidForNextCall = reader.string();
                                break;
                            }
                        case 59: {
                                if (!(message.autoRequeueConfigs && message.autoRequeueConfigs.length))
                                    message.autoRequeueConfigs = [];
                                message.autoRequeueConfigs.push($root.infinitusai.be.AutoRequeueConfig.decode(reader, reader.uint32()));
                                break;
                            }
                        case 60: {
                                message.overridingHumanCallsToRealisticCall = reader.bool();
                                break;
                            }
                        case 61: {
                                message.needsManualIvr = reader.bool();
                                break;
                            }
                        case 62: {
                                message.isFtiTask = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 63: {
                                message.nextVendorApiCallTimestampMillis = reader.int64();
                                break;
                            }
                        case 64: {
                                message.pverifyApiCallCount = reader.int32();
                                break;
                            }
                        case 65: {
                                message.previousBvDate = reader.string();
                                break;
                            }
                        case 66: {
                                message.previousPbmDate = reader.string();
                                break;
                            }
                        case 67: {
                                message.fastTrackSuccessfulTaskNotes = $root.infinitusai.be.FastTrackSuccessfulTaskNotes.decode(reader, reader.uint32());
                                break;
                            }
                        case 68: {
                                message.routeUserOnCallStart = reader.bool();
                                break;
                            }
                        case 69: {
                                message.lastTaskAssignee = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 70: {
                                message.fullyDigital = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TaskDoc} TaskDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskDoc message.
                 * @function verify
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    if (message.callUuids != null && message.hasOwnProperty("callUuids")) {
                        if (!Array.isArray(message.callUuids))
                            return "callUuids: array expected";
                        for (var i = 0; i < message.callUuids.length; ++i)
                            if (!$util.isString(message.callUuids[i]))
                                return "callUuids: string[] expected";
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                            break;
                        }
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        properties.inputs = 1;
                        {
                            var error = $root.infinitusai.be.BVCallInputs.verify(message.bvInputs);
                            if (error)
                                return "bvInputs." + error;
                        }
                    }
                    if (message.taskSource != null && message.hasOwnProperty("taskSource"))
                        switch (message.taskSource) {
                        default:
                            return "taskSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                    if (message.taskCreator != null && message.hasOwnProperty("taskCreator")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.taskCreator);
                        if (error)
                            return "taskCreator." + error;
                    }
                    if (message.taskCreationMillis != null && message.hasOwnProperty("taskCreationMillis"))
                        if (!$util.isInteger(message.taskCreationMillis) && !(message.taskCreationMillis && $util.isInteger(message.taskCreationMillis.low) && $util.isInteger(message.taskCreationMillis.high)))
                            return "taskCreationMillis: integer|Long expected";
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.MedSupBVCallOutputs.verify(message.medsupBvOutputs);
                            if (error)
                                return "medsupBvOutputs." + error;
                        }
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.FullBVCallOutputs.verify(message.fullBvOutputs);
                            if (error)
                                return "fullBvOutputs." + error;
                        }
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.PBMBVCallOutputs.verify(message.pbmBvOutputs);
                            if (error)
                                return "pbmBvOutputs." + error;
                        }
                    }
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.verify(message.covidVaccineAvailabilityCallOutputs);
                            if (error)
                                return "covidVaccineAvailabilityCallOutputs." + error;
                        }
                    }
                    if (message.taskReviewer != null && message.hasOwnProperty("taskReviewer")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.taskReviewer);
                        if (error)
                            return "taskReviewer." + error;
                    }
                    if (message.taskReviewMillis != null && message.hasOwnProperty("taskReviewMillis"))
                        if (!$util.isInteger(message.taskReviewMillis) && !(message.taskReviewMillis && $util.isInteger(message.taskReviewMillis.low) && $util.isInteger(message.taskReviewMillis.high)))
                            return "taskReviewMillis: integer|Long expected";
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        if (!$util.isString(message.customerAssignedId))
                            return "customerAssignedId: string expected";
                    if (message.taskNotes != null && message.hasOwnProperty("taskNotes"))
                        if (!$util.isString(message.taskNotes))
                            return "taskNotes: string expected";
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen")) {
                        var error = $root.infinitusai.be.TaskFailReason.verify(message.failureReasonChosen);
                        if (error)
                            return "failureReasonChosen." + error;
                    }
                    if (message.authoritativeCallUuid != null && message.hasOwnProperty("authoritativeCallUuid"))
                        if (!$util.isString(message.authoritativeCallUuid))
                            return "authoritativeCallUuid: string expected";
                    if (message.readyForReviewUser != null && message.hasOwnProperty("readyForReviewUser")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.readyForReviewUser);
                        if (error)
                            return "readyForReviewUser." + error;
                    }
                    if (message.readyForReviewMillis != null && message.hasOwnProperty("readyForReviewMillis"))
                        if (!$util.isInteger(message.readyForReviewMillis) && !(message.readyForReviewMillis && $util.isInteger(message.readyForReviewMillis.low) && $util.isInteger(message.readyForReviewMillis.high)))
                            return "readyForReviewMillis: integer|Long expected";
                    if (message.taskUpdatedMillis != null && message.hasOwnProperty("taskUpdatedMillis"))
                        if (!$util.isInteger(message.taskUpdatedMillis) && !(message.taskUpdatedMillis && $util.isInteger(message.taskUpdatedMillis.low) && $util.isInteger(message.taskUpdatedMillis.high)))
                            return "taskUpdatedMillis: integer|Long expected";
                    if (message.reviewClaimUser != null && message.hasOwnProperty("reviewClaimUser")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.reviewClaimUser);
                        if (error)
                            return "reviewClaimUser." + error;
                    }
                    if (message.reviewClaimMillis != null && message.hasOwnProperty("reviewClaimMillis"))
                        if (!$util.isInteger(message.reviewClaimMillis) && !(message.reviewClaimMillis && $util.isInteger(message.reviewClaimMillis.low) && $util.isInteger(message.reviewClaimMillis.high)))
                            return "reviewClaimMillis: integer|Long expected";
                    if (message.outputSummary != null && message.hasOwnProperty("outputSummary"))
                        if (!$util.isString(message.outputSummary))
                            return "outputSummary: string expected";
                    if (message.customerReview != null && message.hasOwnProperty("customerReview")) {
                        var error = $root.infinitusai.be.CustomerReview.verify(message.customerReview);
                        if (error)
                            return "customerReview." + error;
                    }
                    if (message.customerReviewExists != null && message.hasOwnProperty("customerReviewExists")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.customerReviewExists);
                        if (error)
                            return "customerReviewExists." + error;
                    }
                    if (message.operatorTrainerMode != null && message.hasOwnProperty("operatorTrainerMode"))
                        if (typeof message.operatorTrainerMode !== "boolean")
                            return "operatorTrainerMode: boolean expected";
                    if (message.chcOutputs != null && message.hasOwnProperty("chcOutputs")) {
                        var error = $root.infinitusai.be.ChangeHealthcareOutputs.verify(message.chcOutputs);
                        if (error)
                            return "chcOutputs." + error;
                    }
                    if (message.chcOutputsCreatedAtMillis != null && message.hasOwnProperty("chcOutputsCreatedAtMillis"))
                        if (!$util.isInteger(message.chcOutputsCreatedAtMillis) && !(message.chcOutputsCreatedAtMillis && $util.isInteger(message.chcOutputsCreatedAtMillis.low) && $util.isInteger(message.chcOutputsCreatedAtMillis.high)))
                            return "chcOutputsCreatedAtMillis: integer|Long expected";
                    if (message.chcOutputsRawDataFilePath != null && message.hasOwnProperty("chcOutputsRawDataFilePath"))
                        if (!$util.isString(message.chcOutputsRawDataFilePath))
                            return "chcOutputsRawDataFilePath: string expected";
                    if (message.chcOutputsAvailable != null && message.hasOwnProperty("chcOutputsAvailable"))
                        if (typeof message.chcOutputsAvailable !== "boolean")
                            return "chcOutputsAvailable: boolean expected";
                    if (message.curveballs != null && message.hasOwnProperty("curveballs")) {
                        if (!Array.isArray(message.curveballs))
                            return "curveballs: array expected";
                        for (var i = 0; i < message.curveballs.length; ++i)
                            switch (message.curveballs[i]) {
                            default:
                                return "curveballs: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                                break;
                            }
                    }
                    if (message.webhook != null && message.hasOwnProperty("webhook")) {
                        var error = $root.infinitusai.be.TaskWebhook.verify(message.webhook);
                        if (error)
                            return "webhook." + error;
                    }
                    if (message.hasPotentialAdverseEvent != null && message.hasOwnProperty("hasPotentialAdverseEvent")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasPotentialAdverseEvent);
                        if (error)
                            return "hasPotentialAdverseEvent." + error;
                    }
                    if (message.potentialAdverseEventNotes != null && message.hasOwnProperty("potentialAdverseEventNotes"))
                        if (!$util.isString(message.potentialAdverseEventNotes))
                            return "potentialAdverseEventNotes: string expected";
                    if (message.outOfNetworkOutputs != null && message.hasOwnProperty("outOfNetworkOutputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.outOfNetworkOutputs);
                        if (error)
                            return "outOfNetworkOutputs." + error;
                    }
                    if (message.customerUrl != null && message.hasOwnProperty("customerUrl"))
                        if (!$util.isString(message.customerUrl))
                            return "customerUrl: string expected";
                    if (message.payerIntelligenceStateAtEscalation != null && message.hasOwnProperty("payerIntelligenceStateAtEscalation")) {
                        var error = $root.infinitusai.be.PayerIntelligenceState.verify(message.payerIntelligenceStateAtEscalation);
                        if (error)
                            return "payerIntelligenceStateAtEscalation." + error;
                    }
                    if (message.additionalRecordingUuids != null && message.hasOwnProperty("additionalRecordingUuids")) {
                        if (!Array.isArray(message.additionalRecordingUuids))
                            return "additionalRecordingUuids: array expected";
                        for (var i = 0; i < message.additionalRecordingUuids.length; ++i)
                            if (!$util.isString(message.additionalRecordingUuids[i]))
                                return "additionalRecordingUuids: string[] expected";
                    }
                    if (message.previousBvOutputs != null && message.hasOwnProperty("previousBvOutputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.previousBvOutputs);
                        if (error)
                            return "previousBvOutputs." + error;
                    }
                    if (message.annotations != null && message.hasOwnProperty("annotations")) {
                        if (!Array.isArray(message.annotations))
                            return "annotations: array expected";
                        for (var i = 0; i < message.annotations.length; ++i)
                            switch (message.annotations[i]) {
                            default:
                                return "annotations: enum value[] expected";
                            case 0:
                            case 1:
                                break;
                            }
                    }
                    if (message.headlessHangups != null && message.hasOwnProperty("headlessHangups")) {
                        if (!Array.isArray(message.headlessHangups))
                            return "headlessHangups: array expected";
                        for (var i = 0; i < message.headlessHangups.length; ++i) {
                            var error = $root.infinitusai.be.TaskDoc.HeadlessHangup.verify(message.headlessHangups[i]);
                            if (error)
                                return "headlessHangups." + error;
                        }
                    }
                    if (message.customerTaskUuid != null && message.hasOwnProperty("customerTaskUuid"))
                        if (!$util.isString(message.customerTaskUuid))
                            return "customerTaskUuid: string expected";
                    if (message.requeueReasonCounters != null && message.hasOwnProperty("requeueReasonCounters")) {
                        if (!$util.isObject(message.requeueReasonCounters))
                            return "requeueReasonCounters: object expected";
                        var key = Object.keys(message.requeueReasonCounters);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isInteger(message.requeueReasonCounters[key[i]]))
                                return "requeueReasonCounters: integer{k:string} expected";
                    }
                    if (message.taskNotesInternal != null && message.hasOwnProperty("taskNotesInternal"))
                        if (!$util.isString(message.taskNotesInternal))
                            return "taskNotesInternal: string expected";
                    if (message.loadTestMode != null && message.hasOwnProperty("loadTestMode"))
                        if (typeof message.loadTestMode !== "boolean")
                            return "loadTestMode: boolean expected";
                    if (message.taskStateTransition != null && message.hasOwnProperty("taskStateTransition"))
                        switch (message.taskStateTransition) {
                        default:
                            return "taskStateTransition: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.taskDueTimestampMillis != null && message.hasOwnProperty("taskDueTimestampMillis"))
                        if (!$util.isInteger(message.taskDueTimestampMillis) && !(message.taskDueTimestampMillis && $util.isInteger(message.taskDueTimestampMillis.low) && $util.isInteger(message.taskDueTimestampMillis.high)))
                            return "taskDueTimestampMillis: integer|Long expected";
                    if (message.annualReenrollment != null && message.hasOwnProperty("annualReenrollment"))
                        if (typeof message.annualReenrollment !== "boolean")
                            return "annualReenrollment: boolean expected";
                    if (message.needsHumanIntro != null && message.hasOwnProperty("needsHumanIntro"))
                        if (typeof message.needsHumanIntro !== "boolean")
                            return "needsHumanIntro: boolean expected";
                    if (message.operatorTrainerScriptId != null && message.hasOwnProperty("operatorTrainerScriptId"))
                        if (!$util.isString(message.operatorTrainerScriptId))
                            return "operatorTrainerScriptId: string expected";
                    if (message.isInMultitaskCall != null && message.hasOwnProperty("isInMultitaskCall"))
                        if (typeof message.isInMultitaskCall !== "boolean")
                            return "isInMultitaskCall: boolean expected";
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        if (!$util.isString(message.customerSegment))
                            return "customerSegment: string expected";
                    if (message.isMultiBenefitTask != null && message.hasOwnProperty("isMultiBenefitTask"))
                        if (typeof message.isMultiBenefitTask !== "boolean")
                            return "isMultiBenefitTask: boolean expected";
                    if (message.multiBenefitTaskId != null && message.hasOwnProperty("multiBenefitTaskId"))
                        if (!$util.isString(message.multiBenefitTaskId))
                            return "multiBenefitTaskId: string expected";
                    if (message.baseCallUuidForNextCall != null && message.hasOwnProperty("baseCallUuidForNextCall"))
                        if (!$util.isString(message.baseCallUuidForNextCall))
                            return "baseCallUuidForNextCall: string expected";
                    if (message.autoRequeueConfigs != null && message.hasOwnProperty("autoRequeueConfigs")) {
                        if (!Array.isArray(message.autoRequeueConfigs))
                            return "autoRequeueConfigs: array expected";
                        for (var i = 0; i < message.autoRequeueConfigs.length; ++i) {
                            var error = $root.infinitusai.be.AutoRequeueConfig.verify(message.autoRequeueConfigs[i]);
                            if (error)
                                return "autoRequeueConfigs." + error;
                        }
                    }
                    if (message.overridingHumanCallsToRealisticCall != null && message.hasOwnProperty("overridingHumanCallsToRealisticCall"))
                        if (typeof message.overridingHumanCallsToRealisticCall !== "boolean")
                            return "overridingHumanCallsToRealisticCall: boolean expected";
                    if (message.needsManualIvr != null && message.hasOwnProperty("needsManualIvr"))
                        if (typeof message.needsManualIvr !== "boolean")
                            return "needsManualIvr: boolean expected";
                    if (message.isFtiTask != null && message.hasOwnProperty("isFtiTask")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isFtiTask);
                        if (error)
                            return "isFtiTask." + error;
                    }
                    if (message.nextVendorApiCallTimestampMillis != null && message.hasOwnProperty("nextVendorApiCallTimestampMillis"))
                        if (!$util.isInteger(message.nextVendorApiCallTimestampMillis) && !(message.nextVendorApiCallTimestampMillis && $util.isInteger(message.nextVendorApiCallTimestampMillis.low) && $util.isInteger(message.nextVendorApiCallTimestampMillis.high)))
                            return "nextVendorApiCallTimestampMillis: integer|Long expected";
                    if (message.pverifyApiCallCount != null && message.hasOwnProperty("pverifyApiCallCount"))
                        if (!$util.isInteger(message.pverifyApiCallCount))
                            return "pverifyApiCallCount: integer expected";
                    if (message.previousBvDate != null && message.hasOwnProperty("previousBvDate"))
                        if (!$util.isString(message.previousBvDate))
                            return "previousBvDate: string expected";
                    if (message.previousPbmDate != null && message.hasOwnProperty("previousPbmDate"))
                        if (!$util.isString(message.previousPbmDate))
                            return "previousPbmDate: string expected";
                    if (message.fastTrackSuccessfulTaskNotes != null && message.hasOwnProperty("fastTrackSuccessfulTaskNotes")) {
                        var error = $root.infinitusai.be.FastTrackSuccessfulTaskNotes.verify(message.fastTrackSuccessfulTaskNotes);
                        if (error)
                            return "fastTrackSuccessfulTaskNotes." + error;
                    }
                    if (message.routeUserOnCallStart != null && message.hasOwnProperty("routeUserOnCallStart"))
                        if (typeof message.routeUserOnCallStart !== "boolean")
                            return "routeUserOnCallStart: boolean expected";
                    if (message.lastTaskAssignee != null && message.hasOwnProperty("lastTaskAssignee")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.lastTaskAssignee);
                        if (error)
                            return "lastTaskAssignee." + error;
                    }
                    if (message.fullyDigital != null && message.hasOwnProperty("fullyDigital"))
                        if (typeof message.fullyDigital !== "boolean")
                            return "fullyDigital: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a TaskDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TaskDoc} TaskDoc
                 */
                TaskDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TaskDoc)
                        return object;
                    var message = new $root.infinitusai.be.TaskDoc();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    if (object.callUuids) {
                        if (!Array.isArray(object.callUuids))
                            throw TypeError(".infinitusai.be.TaskDoc.callUuids: array expected");
                        message.callUuids = [];
                        for (var i = 0; i < object.callUuids.length; ++i)
                            message.callUuids[i] = String(object.callUuids[i]);
                    }
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "TASK_STATE_UNKNOWN":
                    case 0:
                        message.state = 0;
                        break;
                    case "TASK_STATE_CREATED":
                    case 1:
                        message.state = 1;
                        break;
                    case "TASK_STATE_CALL_CONNECTED":
                    case 2:
                        message.state = 2;
                        break;
                    case "TASK_STATE_CALL_DISCONNECTED":
                    case 3:
                        message.state = 3;
                        break;
                    case "TASK_STATE_FAILED":
                    case 4:
                        message.state = 4;
                        break;
                    case "TASK_STATE_REVIEWED":
                    case 5:
                        message.state = 5;
                        break;
                    case "TASK_STATE_READY_FOR_REVIEW":
                    case 6:
                        message.state = 6;
                        break;
                    case "TASK_STATE_IN_REVIEW":
                    case 7:
                        message.state = 7;
                        break;
                    case "TASK_STATE_IN_PROGRESS":
                    case 8:
                        message.state = 8;
                        break;
                    case "TASK_STATE_PARTIAL_SUCCESS":
                    case 9:
                        message.state = 9;
                        break;
                    case "TASK_STATE_READY_FOR_SELF_REVIEW":
                    case 10:
                        message.state = 10;
                        break;
                    case "TASK_STATE_AUTO_CLOSED":
                    case 11:
                        message.state = 11;
                        break;
                    case "TASK_STATE_PENDING_AUTO_REQUEUE":
                    case 12:
                        message.state = 12;
                        break;
                    case "TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL":
                    case 13:
                        message.state = 13;
                        break;
                    }
                    if (object.bvInputs != null) {
                        if (typeof object.bvInputs !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.bvInputs: object expected");
                        message.bvInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.bvInputs);
                    }
                    switch (object.taskSource) {
                    default:
                        if (typeof object.taskSource === "number") {
                            message.taskSource = object.taskSource;
                            break;
                        }
                        break;
                    case "TASK_SOURCE_UNKNOWN":
                    case 0:
                        message.taskSource = 0;
                        break;
                    case "TASK_SOURCE_ETL_SCRIPT":
                    case 1:
                        message.taskSource = 1;
                        break;
                    case "TASK_SOURCE_FE":
                    case 2:
                        message.taskSource = 2;
                        break;
                    case "TASK_SOURCE_CUSTOMER_PORTAL":
                    case 3:
                        message.taskSource = 3;
                        break;
                    case "TASK_SOURCE_CUSTOMER_API":
                    case 4:
                        message.taskSource = 4;
                        break;
                    case "TASK_SOURCE_SETUP":
                    case 5:
                        message.taskSource = 5;
                        break;
                    case "TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD":
                    case 6:
                        message.taskSource = 6;
                        break;
                    case "TASK_SOURCE_FASTTRACK":
                    case 7:
                        message.taskSource = 7;
                        break;
                    case "TASK_SOURCE_FASTTRACK_SF":
                    case 8:
                        message.taskSource = 8;
                        break;
                    case "TASK_SOURCE_FASTTRACK_CSV_UPLOAD":
                    case 9:
                        message.taskSource = 9;
                        break;
                    }
                    if (object.taskCreator != null) {
                        if (typeof object.taskCreator !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.taskCreator: object expected");
                        message.taskCreator = $root.infinitusai.be.UserInfo.fromObject(object.taskCreator);
                    }
                    if (object.taskCreationMillis != null)
                        if ($util.Long)
                            (message.taskCreationMillis = $util.Long.fromValue(object.taskCreationMillis)).unsigned = false;
                        else if (typeof object.taskCreationMillis === "string")
                            message.taskCreationMillis = parseInt(object.taskCreationMillis, 10);
                        else if (typeof object.taskCreationMillis === "number")
                            message.taskCreationMillis = object.taskCreationMillis;
                        else if (typeof object.taskCreationMillis === "object")
                            message.taskCreationMillis = new $util.LongBits(object.taskCreationMillis.low >>> 0, object.taskCreationMillis.high >>> 0).toNumber();
                    if (object.medsupBvOutputs != null) {
                        if (typeof object.medsupBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.medsupBvOutputs: object expected");
                        message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.fromObject(object.medsupBvOutputs);
                    }
                    if (object.fullBvOutputs != null) {
                        if (typeof object.fullBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.fullBvOutputs: object expected");
                        message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.fromObject(object.fullBvOutputs);
                    }
                    if (object.pbmBvOutputs != null) {
                        if (typeof object.pbmBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.pbmBvOutputs: object expected");
                        message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.fromObject(object.pbmBvOutputs);
                    }
                    if (object.covidVaccineAvailabilityCallOutputs != null) {
                        if (typeof object.covidVaccineAvailabilityCallOutputs !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.covidVaccineAvailabilityCallOutputs: object expected");
                        message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.fromObject(object.covidVaccineAvailabilityCallOutputs);
                    }
                    if (object.taskReviewer != null) {
                        if (typeof object.taskReviewer !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.taskReviewer: object expected");
                        message.taskReviewer = $root.infinitusai.be.UserInfo.fromObject(object.taskReviewer);
                    }
                    if (object.taskReviewMillis != null)
                        if ($util.Long)
                            (message.taskReviewMillis = $util.Long.fromValue(object.taskReviewMillis)).unsigned = false;
                        else if (typeof object.taskReviewMillis === "string")
                            message.taskReviewMillis = parseInt(object.taskReviewMillis, 10);
                        else if (typeof object.taskReviewMillis === "number")
                            message.taskReviewMillis = object.taskReviewMillis;
                        else if (typeof object.taskReviewMillis === "object")
                            message.taskReviewMillis = new $util.LongBits(object.taskReviewMillis.low >>> 0, object.taskReviewMillis.high >>> 0).toNumber();
                    if (object.customerAssignedId != null)
                        message.customerAssignedId = String(object.customerAssignedId);
                    if (object.taskNotes != null)
                        message.taskNotes = String(object.taskNotes);
                    if (object.failureReasonChosen != null) {
                        if (typeof object.failureReasonChosen !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.failureReasonChosen: object expected");
                        message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.fromObject(object.failureReasonChosen);
                    }
                    if (object.authoritativeCallUuid != null)
                        message.authoritativeCallUuid = String(object.authoritativeCallUuid);
                    if (object.readyForReviewUser != null) {
                        if (typeof object.readyForReviewUser !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.readyForReviewUser: object expected");
                        message.readyForReviewUser = $root.infinitusai.be.UserInfo.fromObject(object.readyForReviewUser);
                    }
                    if (object.readyForReviewMillis != null)
                        if ($util.Long)
                            (message.readyForReviewMillis = $util.Long.fromValue(object.readyForReviewMillis)).unsigned = false;
                        else if (typeof object.readyForReviewMillis === "string")
                            message.readyForReviewMillis = parseInt(object.readyForReviewMillis, 10);
                        else if (typeof object.readyForReviewMillis === "number")
                            message.readyForReviewMillis = object.readyForReviewMillis;
                        else if (typeof object.readyForReviewMillis === "object")
                            message.readyForReviewMillis = new $util.LongBits(object.readyForReviewMillis.low >>> 0, object.readyForReviewMillis.high >>> 0).toNumber();
                    if (object.taskUpdatedMillis != null)
                        if ($util.Long)
                            (message.taskUpdatedMillis = $util.Long.fromValue(object.taskUpdatedMillis)).unsigned = false;
                        else if (typeof object.taskUpdatedMillis === "string")
                            message.taskUpdatedMillis = parseInt(object.taskUpdatedMillis, 10);
                        else if (typeof object.taskUpdatedMillis === "number")
                            message.taskUpdatedMillis = object.taskUpdatedMillis;
                        else if (typeof object.taskUpdatedMillis === "object")
                            message.taskUpdatedMillis = new $util.LongBits(object.taskUpdatedMillis.low >>> 0, object.taskUpdatedMillis.high >>> 0).toNumber();
                    if (object.reviewClaimUser != null) {
                        if (typeof object.reviewClaimUser !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.reviewClaimUser: object expected");
                        message.reviewClaimUser = $root.infinitusai.be.UserInfo.fromObject(object.reviewClaimUser);
                    }
                    if (object.reviewClaimMillis != null)
                        if ($util.Long)
                            (message.reviewClaimMillis = $util.Long.fromValue(object.reviewClaimMillis)).unsigned = false;
                        else if (typeof object.reviewClaimMillis === "string")
                            message.reviewClaimMillis = parseInt(object.reviewClaimMillis, 10);
                        else if (typeof object.reviewClaimMillis === "number")
                            message.reviewClaimMillis = object.reviewClaimMillis;
                        else if (typeof object.reviewClaimMillis === "object")
                            message.reviewClaimMillis = new $util.LongBits(object.reviewClaimMillis.low >>> 0, object.reviewClaimMillis.high >>> 0).toNumber();
                    if (object.outputSummary != null)
                        message.outputSummary = String(object.outputSummary);
                    if (object.customerReview != null) {
                        if (typeof object.customerReview !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.customerReview: object expected");
                        message.customerReview = $root.infinitusai.be.CustomerReview.fromObject(object.customerReview);
                    }
                    if (object.customerReviewExists != null) {
                        if (typeof object.customerReviewExists !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.customerReviewExists: object expected");
                        message.customerReviewExists = $root.infinitusai.be.NullableBool.fromObject(object.customerReviewExists);
                    }
                    if (object.operatorTrainerMode != null)
                        message.operatorTrainerMode = Boolean(object.operatorTrainerMode);
                    if (object.chcOutputs != null) {
                        if (typeof object.chcOutputs !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.chcOutputs: object expected");
                        message.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.fromObject(object.chcOutputs);
                    }
                    if (object.chcOutputsCreatedAtMillis != null)
                        if ($util.Long)
                            (message.chcOutputsCreatedAtMillis = $util.Long.fromValue(object.chcOutputsCreatedAtMillis)).unsigned = false;
                        else if (typeof object.chcOutputsCreatedAtMillis === "string")
                            message.chcOutputsCreatedAtMillis = parseInt(object.chcOutputsCreatedAtMillis, 10);
                        else if (typeof object.chcOutputsCreatedAtMillis === "number")
                            message.chcOutputsCreatedAtMillis = object.chcOutputsCreatedAtMillis;
                        else if (typeof object.chcOutputsCreatedAtMillis === "object")
                            message.chcOutputsCreatedAtMillis = new $util.LongBits(object.chcOutputsCreatedAtMillis.low >>> 0, object.chcOutputsCreatedAtMillis.high >>> 0).toNumber();
                    if (object.chcOutputsRawDataFilePath != null)
                        message.chcOutputsRawDataFilePath = String(object.chcOutputsRawDataFilePath);
                    if (object.chcOutputsAvailable != null)
                        message.chcOutputsAvailable = Boolean(object.chcOutputsAvailable);
                    if (object.curveballs) {
                        if (!Array.isArray(object.curveballs))
                            throw TypeError(".infinitusai.be.TaskDoc.curveballs: array expected");
                        message.curveballs = [];
                        for (var i = 0; i < object.curveballs.length; ++i)
                            switch (object.curveballs[i]) {
                            default:
                                if (typeof object.curveballs[i] === "number") {
                                    message.curveballs[i] = object.curveballs[i];
                                    break;
                                }
                            case "CURVEBALL_UNKNOWN":
                            case 0:
                                message.curveballs[i] = 0;
                                break;
                            case "CURVEBALL_REQUEST_FOR_HUMAN":
                            case 1:
                                message.curveballs[i] = 1;
                                break;
                            case "CURVEBALL_REQUEST_FOR_LIVE_AGENT":
                            case 2:
                                message.curveballs[i] = 2;
                                break;
                            case "CURVEBALL_HIPAA_CONCERNS_PUSHBACK":
                            case 3:
                                message.curveballs[i] = 3;
                                break;
                            case "CURVEBALL_AGENT_PLAYS_WITH_EVA":
                            case 4:
                                message.curveballs[i] = 4;
                                break;
                            case "CURVEBALL_AGENT_RELENTLESSLY_PLAYS_WITH_EVA":
                            case 5:
                                message.curveballs[i] = 5;
                                break;
                            case "CURVEBALL_AGENT_SHARES_TOO_MUCH_INFO":
                            case 6:
                                message.curveballs[i] = 6;
                                break;
                            case "CURVEBALL_FRUSTRATED_AGENT_SHARES_TOO_MUCH_INFO":
                            case 7:
                                message.curveballs[i] = 7;
                                break;
                            case "CURVEBALL_STEP_THERAPY_CONFUSION":
                            case 8:
                                message.curveballs[i] = 8;
                                break;
                            case "CURVEBALL_SPECIALTY_PHARMACY_CONFUSION":
                            case 9:
                                message.curveballs[i] = 9;
                                break;
                            case "CURVEBALL_PREDETERMINATION_RECOMMENDED_NOT_REQUIRED":
                            case 10:
                                message.curveballs[i] = 10;
                                break;
                            case "CURVEBALL_PREDETERMINATION_RECOMMENDED":
                            case 11:
                                message.curveballs[i] = 11;
                                break;
                            case "CURVEBALL_PREDETERMINATION_OPTIONAL":
                            case 12:
                                message.curveballs[i] = 12;
                                break;
                            case "CURVEBALL_VAGUE_LIFETIME_MAX_NO":
                            case 13:
                                message.curveballs[i] = 13;
                                break;
                            case "CURVEBALL_VAGUE_LIFETIME_MAX_YES":
                            case 14:
                                message.curveballs[i] = 14;
                                break;
                            case "CURVEBALL_VAGUE_LIFETIME_MAX_MEDICARE":
                            case 15:
                                message.curveballs[i] = 15;
                                break;
                            case "CURVEBALL_VAGUE_LIFETIME_MAX_LARGE_VALUE":
                            case 16:
                                message.curveballs[i] = 16;
                                break;
                            case "CURVEBALL_VAGUE_COPAY_COINSURANCE_YES":
                            case 17:
                                message.curveballs[i] = 17;
                                break;
                            case "CURVEBALL_VAGUE_COPAY_COINSURANCE_NO":
                            case 18:
                                message.curveballs[i] = 18;
                                break;
                            case "CURVEBALL_OOP_ALREADY_MET":
                            case 19:
                                message.curveballs[i] = 19;
                                break;
                            case "CURVEBALL_COB_NOT_UP_TO_DATE_NO_SECONDARY":
                            case 20:
                                message.curveballs[i] = 20;
                                break;
                            case "CURVEBALL_COB_NOT_UP_TO_DATE_WITH_SECONDARY":
                            case 21:
                                message.curveballs[i] = 21;
                                break;
                            }
                    }
                    if (object.webhook != null) {
                        if (typeof object.webhook !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.webhook: object expected");
                        message.webhook = $root.infinitusai.be.TaskWebhook.fromObject(object.webhook);
                    }
                    if (object.hasPotentialAdverseEvent != null) {
                        if (typeof object.hasPotentialAdverseEvent !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.hasPotentialAdverseEvent: object expected");
                        message.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.fromObject(object.hasPotentialAdverseEvent);
                    }
                    if (object.potentialAdverseEventNotes != null)
                        message.potentialAdverseEventNotes = String(object.potentialAdverseEventNotes);
                    if (object.outOfNetworkOutputs != null) {
                        if (typeof object.outOfNetworkOutputs !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.outOfNetworkOutputs: object expected");
                        message.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.fromObject(object.outOfNetworkOutputs);
                    }
                    if (object.customerUrl != null)
                        message.customerUrl = String(object.customerUrl);
                    if (object.payerIntelligenceStateAtEscalation != null) {
                        if (typeof object.payerIntelligenceStateAtEscalation !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.payerIntelligenceStateAtEscalation: object expected");
                        message.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.fromObject(object.payerIntelligenceStateAtEscalation);
                    }
                    if (object.additionalRecordingUuids) {
                        if (!Array.isArray(object.additionalRecordingUuids))
                            throw TypeError(".infinitusai.be.TaskDoc.additionalRecordingUuids: array expected");
                        message.additionalRecordingUuids = [];
                        for (var i = 0; i < object.additionalRecordingUuids.length; ++i)
                            message.additionalRecordingUuids[i] = String(object.additionalRecordingUuids[i]);
                    }
                    if (object.previousBvOutputs != null) {
                        if (typeof object.previousBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.previousBvOutputs: object expected");
                        message.previousBvOutputs = $root.infinitusai.be.CallOutputs.fromObject(object.previousBvOutputs);
                    }
                    if (object.annotations) {
                        if (!Array.isArray(object.annotations))
                            throw TypeError(".infinitusai.be.TaskDoc.annotations: array expected");
                        message.annotations = [];
                        for (var i = 0; i < object.annotations.length; ++i)
                            switch (object.annotations[i]) {
                            default:
                                if (typeof object.annotations[i] === "number") {
                                    message.annotations[i] = object.annotations[i];
                                    break;
                                }
                            case "TASK_ANNOTATION_UNKNOWN":
                            case 0:
                                message.annotations[i] = 0;
                                break;
                            case "TASK_ANNOTATION_DUPLICATE_TASK":
                            case 1:
                                message.annotations[i] = 1;
                                break;
                            }
                    }
                    if (object.headlessHangups) {
                        if (!Array.isArray(object.headlessHangups))
                            throw TypeError(".infinitusai.be.TaskDoc.headlessHangups: array expected");
                        message.headlessHangups = [];
                        for (var i = 0; i < object.headlessHangups.length; ++i) {
                            if (typeof object.headlessHangups[i] !== "object")
                                throw TypeError(".infinitusai.be.TaskDoc.headlessHangups: object expected");
                            message.headlessHangups[i] = $root.infinitusai.be.TaskDoc.HeadlessHangup.fromObject(object.headlessHangups[i]);
                        }
                    }
                    if (object.customerTaskUuid != null)
                        message.customerTaskUuid = String(object.customerTaskUuid);
                    if (object.requeueReasonCounters) {
                        if (typeof object.requeueReasonCounters !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.requeueReasonCounters: object expected");
                        message.requeueReasonCounters = {};
                        for (var keys = Object.keys(object.requeueReasonCounters), i = 0; i < keys.length; ++i)
                            message.requeueReasonCounters[keys[i]] = object.requeueReasonCounters[keys[i]] | 0;
                    }
                    if (object.taskNotesInternal != null)
                        message.taskNotesInternal = String(object.taskNotesInternal);
                    if (object.loadTestMode != null)
                        message.loadTestMode = Boolean(object.loadTestMode);
                    switch (object.taskStateTransition) {
                    default:
                        if (typeof object.taskStateTransition === "number") {
                            message.taskStateTransition = object.taskStateTransition;
                            break;
                        }
                        break;
                    case "TASK_STATE_TRANSITION_UNKNOWN":
                    case 0:
                        message.taskStateTransition = 0;
                        break;
                    case "TASK_STATE_TRANSITION_FAIL_TASK":
                    case 1:
                        message.taskStateTransition = 1;
                        break;
                    case "TASK_STATE_TRANSITION_LEAVE_TASK_DISCONNECTED":
                    case 2:
                        message.taskStateTransition = 2;
                        break;
                    case "TASK_STATE_TRANSITION_REQUEUE_TASK":
                    case 3:
                        message.taskStateTransition = 3;
                        break;
                    case "TASK_STATE_TRANSITION_SEND_TO_REVIEW":
                    case 4:
                        message.taskStateTransition = 4;
                        break;
                    case "TASK_STATE_TRANSITION_AUTO_REQUEUE_TASK":
                    case 5:
                        message.taskStateTransition = 5;
                        break;
                    }
                    if (object.taskDueTimestampMillis != null)
                        if ($util.Long)
                            (message.taskDueTimestampMillis = $util.Long.fromValue(object.taskDueTimestampMillis)).unsigned = false;
                        else if (typeof object.taskDueTimestampMillis === "string")
                            message.taskDueTimestampMillis = parseInt(object.taskDueTimestampMillis, 10);
                        else if (typeof object.taskDueTimestampMillis === "number")
                            message.taskDueTimestampMillis = object.taskDueTimestampMillis;
                        else if (typeof object.taskDueTimestampMillis === "object")
                            message.taskDueTimestampMillis = new $util.LongBits(object.taskDueTimestampMillis.low >>> 0, object.taskDueTimestampMillis.high >>> 0).toNumber();
                    if (object.annualReenrollment != null)
                        message.annualReenrollment = Boolean(object.annualReenrollment);
                    if (object.needsHumanIntro != null)
                        message.needsHumanIntro = Boolean(object.needsHumanIntro);
                    if (object.operatorTrainerScriptId != null)
                        message.operatorTrainerScriptId = String(object.operatorTrainerScriptId);
                    if (object.isInMultitaskCall != null)
                        message.isInMultitaskCall = Boolean(object.isInMultitaskCall);
                    if (object.customerSegment != null)
                        message.customerSegment = String(object.customerSegment);
                    if (object.isMultiBenefitTask != null)
                        message.isMultiBenefitTask = Boolean(object.isMultiBenefitTask);
                    if (object.multiBenefitTaskId != null)
                        message.multiBenefitTaskId = String(object.multiBenefitTaskId);
                    if (object.baseCallUuidForNextCall != null)
                        message.baseCallUuidForNextCall = String(object.baseCallUuidForNextCall);
                    if (object.autoRequeueConfigs) {
                        if (!Array.isArray(object.autoRequeueConfigs))
                            throw TypeError(".infinitusai.be.TaskDoc.autoRequeueConfigs: array expected");
                        message.autoRequeueConfigs = [];
                        for (var i = 0; i < object.autoRequeueConfigs.length; ++i) {
                            if (typeof object.autoRequeueConfigs[i] !== "object")
                                throw TypeError(".infinitusai.be.TaskDoc.autoRequeueConfigs: object expected");
                            message.autoRequeueConfigs[i] = $root.infinitusai.be.AutoRequeueConfig.fromObject(object.autoRequeueConfigs[i]);
                        }
                    }
                    if (object.overridingHumanCallsToRealisticCall != null)
                        message.overridingHumanCallsToRealisticCall = Boolean(object.overridingHumanCallsToRealisticCall);
                    if (object.needsManualIvr != null)
                        message.needsManualIvr = Boolean(object.needsManualIvr);
                    if (object.isFtiTask != null) {
                        if (typeof object.isFtiTask !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.isFtiTask: object expected");
                        message.isFtiTask = $root.infinitusai.be.NullableBool.fromObject(object.isFtiTask);
                    }
                    if (object.nextVendorApiCallTimestampMillis != null)
                        if ($util.Long)
                            (message.nextVendorApiCallTimestampMillis = $util.Long.fromValue(object.nextVendorApiCallTimestampMillis)).unsigned = false;
                        else if (typeof object.nextVendorApiCallTimestampMillis === "string")
                            message.nextVendorApiCallTimestampMillis = parseInt(object.nextVendorApiCallTimestampMillis, 10);
                        else if (typeof object.nextVendorApiCallTimestampMillis === "number")
                            message.nextVendorApiCallTimestampMillis = object.nextVendorApiCallTimestampMillis;
                        else if (typeof object.nextVendorApiCallTimestampMillis === "object")
                            message.nextVendorApiCallTimestampMillis = new $util.LongBits(object.nextVendorApiCallTimestampMillis.low >>> 0, object.nextVendorApiCallTimestampMillis.high >>> 0).toNumber();
                    if (object.pverifyApiCallCount != null)
                        message.pverifyApiCallCount = object.pverifyApiCallCount | 0;
                    if (object.previousBvDate != null)
                        message.previousBvDate = String(object.previousBvDate);
                    if (object.previousPbmDate != null)
                        message.previousPbmDate = String(object.previousPbmDate);
                    if (object.fastTrackSuccessfulTaskNotes != null) {
                        if (typeof object.fastTrackSuccessfulTaskNotes !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.fastTrackSuccessfulTaskNotes: object expected");
                        message.fastTrackSuccessfulTaskNotes = $root.infinitusai.be.FastTrackSuccessfulTaskNotes.fromObject(object.fastTrackSuccessfulTaskNotes);
                    }
                    if (object.routeUserOnCallStart != null)
                        message.routeUserOnCallStart = Boolean(object.routeUserOnCallStart);
                    if (object.lastTaskAssignee != null) {
                        if (typeof object.lastTaskAssignee !== "object")
                            throw TypeError(".infinitusai.be.TaskDoc.lastTaskAssignee: object expected");
                        message.lastTaskAssignee = $root.infinitusai.be.UserInfo.fromObject(object.lastTaskAssignee);
                    }
                    if (object.fullyDigital != null)
                        message.fullyDigital = Boolean(object.fullyDigital);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {infinitusai.be.TaskDoc} message TaskDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.callUuids = [];
                        object.curveballs = [];
                        object.additionalRecordingUuids = [];
                        object.annotations = [];
                        object.headlessHangups = [];
                        object.autoRequeueConfigs = [];
                    }
                    if (options.objects || options.defaults)
                        object.requeueReasonCounters = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.programName = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.state = options.enums === String ? "TASK_STATE_UNKNOWN" : 0;
                        object.taskSource = options.enums === String ? "TASK_SOURCE_UNKNOWN" : 0;
                        object.taskCreator = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.taskCreationMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.taskCreationMillis = options.longs === String ? "0" : 0;
                        object.taskReviewer = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.taskReviewMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.taskReviewMillis = options.longs === String ? "0" : 0;
                        object.customerAssignedId = "";
                        object.taskNotes = "";
                        object.failureReasonChosen = null;
                        object.authoritativeCallUuid = "";
                        object.readyForReviewUser = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.readyForReviewMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.readyForReviewMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.taskUpdatedMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.taskUpdatedMillis = options.longs === String ? "0" : 0;
                        object.reviewClaimUser = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewClaimMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewClaimMillis = options.longs === String ? "0" : 0;
                        object.outputSummary = "";
                        object.customerReview = null;
                        object.operatorTrainerMode = false;
                        object.chcOutputs = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.chcOutputsCreatedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.chcOutputsCreatedAtMillis = options.longs === String ? "0" : 0;
                        object.chcOutputsRawDataFilePath = "";
                        object.chcOutputsAvailable = false;
                        object.webhook = null;
                        object.hasPotentialAdverseEvent = null;
                        object.potentialAdverseEventNotes = "";
                        object.outOfNetworkOutputs = null;
                        object.customerUrl = "";
                        object.payerIntelligenceStateAtEscalation = null;
                        object.previousBvOutputs = null;
                        object.customerReviewExists = null;
                        object.customerTaskUuid = "";
                        object.taskNotesInternal = "";
                        object.loadTestMode = false;
                        object.taskStateTransition = options.enums === String ? "TASK_STATE_TRANSITION_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.taskDueTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.taskDueTimestampMillis = options.longs === String ? "0" : 0;
                        object.annualReenrollment = false;
                        object.needsHumanIntro = false;
                        object.operatorTrainerScriptId = "";
                        object.isInMultitaskCall = false;
                        object.customerSegment = "";
                        object.isMultiBenefitTask = false;
                        object.multiBenefitTaskId = "";
                        object.baseCallUuidForNextCall = "";
                        object.overridingHumanCallsToRealisticCall = false;
                        object.needsManualIvr = false;
                        object.isFtiTask = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.nextVendorApiCallTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.nextVendorApiCallTimestampMillis = options.longs === String ? "0" : 0;
                        object.pverifyApiCallCount = 0;
                        object.previousBvDate = "";
                        object.previousPbmDate = "";
                        object.fastTrackSuccessfulTaskNotes = null;
                        object.routeUserOnCallStart = false;
                        object.lastTaskAssignee = null;
                        object.fullyDigital = false;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    if (message.callUuids && message.callUuids.length) {
                        object.callUuids = [];
                        for (var j = 0; j < message.callUuids.length; ++j)
                            object.callUuids[j] = message.callUuids[j];
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.infinitusai.be.TaskState[message.state] === undefined ? message.state : $root.infinitusai.be.TaskState[message.state] : message.state;
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        object.bvInputs = $root.infinitusai.be.BVCallInputs.toObject(message.bvInputs, options);
                        if (options.oneofs)
                            object.inputs = "bvInputs";
                    }
                    if (message.taskSource != null && message.hasOwnProperty("taskSource"))
                        object.taskSource = options.enums === String ? $root.infinitusai.be.TaskSource[message.taskSource] === undefined ? message.taskSource : $root.infinitusai.be.TaskSource[message.taskSource] : message.taskSource;
                    if (message.taskCreator != null && message.hasOwnProperty("taskCreator"))
                        object.taskCreator = $root.infinitusai.be.UserInfo.toObject(message.taskCreator, options);
                    if (message.taskCreationMillis != null && message.hasOwnProperty("taskCreationMillis"))
                        if (typeof message.taskCreationMillis === "number")
                            object.taskCreationMillis = options.longs === String ? String(message.taskCreationMillis) : message.taskCreationMillis;
                        else
                            object.taskCreationMillis = options.longs === String ? $util.Long.prototype.toString.call(message.taskCreationMillis) : options.longs === Number ? new $util.LongBits(message.taskCreationMillis.low >>> 0, message.taskCreationMillis.high >>> 0).toNumber() : message.taskCreationMillis;
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        object.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.toObject(message.medsupBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "medsupBvOutputs";
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        object.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.toObject(message.fullBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "fullBvOutputs";
                    }
                    if (message.taskReviewer != null && message.hasOwnProperty("taskReviewer"))
                        object.taskReviewer = $root.infinitusai.be.UserInfo.toObject(message.taskReviewer, options);
                    if (message.taskReviewMillis != null && message.hasOwnProperty("taskReviewMillis"))
                        if (typeof message.taskReviewMillis === "number")
                            object.taskReviewMillis = options.longs === String ? String(message.taskReviewMillis) : message.taskReviewMillis;
                        else
                            object.taskReviewMillis = options.longs === String ? $util.Long.prototype.toString.call(message.taskReviewMillis) : options.longs === Number ? new $util.LongBits(message.taskReviewMillis.low >>> 0, message.taskReviewMillis.high >>> 0).toNumber() : message.taskReviewMillis;
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        object.customerAssignedId = message.customerAssignedId;
                    if (message.taskNotes != null && message.hasOwnProperty("taskNotes"))
                        object.taskNotes = message.taskNotes;
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen"))
                        object.failureReasonChosen = $root.infinitusai.be.TaskFailReason.toObject(message.failureReasonChosen, options);
                    if (message.authoritativeCallUuid != null && message.hasOwnProperty("authoritativeCallUuid"))
                        object.authoritativeCallUuid = message.authoritativeCallUuid;
                    if (message.readyForReviewUser != null && message.hasOwnProperty("readyForReviewUser"))
                        object.readyForReviewUser = $root.infinitusai.be.UserInfo.toObject(message.readyForReviewUser, options);
                    if (message.readyForReviewMillis != null && message.hasOwnProperty("readyForReviewMillis"))
                        if (typeof message.readyForReviewMillis === "number")
                            object.readyForReviewMillis = options.longs === String ? String(message.readyForReviewMillis) : message.readyForReviewMillis;
                        else
                            object.readyForReviewMillis = options.longs === String ? $util.Long.prototype.toString.call(message.readyForReviewMillis) : options.longs === Number ? new $util.LongBits(message.readyForReviewMillis.low >>> 0, message.readyForReviewMillis.high >>> 0).toNumber() : message.readyForReviewMillis;
                    if (message.taskUpdatedMillis != null && message.hasOwnProperty("taskUpdatedMillis"))
                        if (typeof message.taskUpdatedMillis === "number")
                            object.taskUpdatedMillis = options.longs === String ? String(message.taskUpdatedMillis) : message.taskUpdatedMillis;
                        else
                            object.taskUpdatedMillis = options.longs === String ? $util.Long.prototype.toString.call(message.taskUpdatedMillis) : options.longs === Number ? new $util.LongBits(message.taskUpdatedMillis.low >>> 0, message.taskUpdatedMillis.high >>> 0).toNumber() : message.taskUpdatedMillis;
                    if (message.reviewClaimUser != null && message.hasOwnProperty("reviewClaimUser"))
                        object.reviewClaimUser = $root.infinitusai.be.UserInfo.toObject(message.reviewClaimUser, options);
                    if (message.reviewClaimMillis != null && message.hasOwnProperty("reviewClaimMillis"))
                        if (typeof message.reviewClaimMillis === "number")
                            object.reviewClaimMillis = options.longs === String ? String(message.reviewClaimMillis) : message.reviewClaimMillis;
                        else
                            object.reviewClaimMillis = options.longs === String ? $util.Long.prototype.toString.call(message.reviewClaimMillis) : options.longs === Number ? new $util.LongBits(message.reviewClaimMillis.low >>> 0, message.reviewClaimMillis.high >>> 0).toNumber() : message.reviewClaimMillis;
                    if (message.outputSummary != null && message.hasOwnProperty("outputSummary"))
                        object.outputSummary = message.outputSummary;
                    if (message.customerReview != null && message.hasOwnProperty("customerReview"))
                        object.customerReview = $root.infinitusai.be.CustomerReview.toObject(message.customerReview, options);
                    if (message.operatorTrainerMode != null && message.hasOwnProperty("operatorTrainerMode"))
                        object.operatorTrainerMode = message.operatorTrainerMode;
                    if (message.chcOutputs != null && message.hasOwnProperty("chcOutputs"))
                        object.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.toObject(message.chcOutputs, options);
                    if (message.chcOutputsCreatedAtMillis != null && message.hasOwnProperty("chcOutputsCreatedAtMillis"))
                        if (typeof message.chcOutputsCreatedAtMillis === "number")
                            object.chcOutputsCreatedAtMillis = options.longs === String ? String(message.chcOutputsCreatedAtMillis) : message.chcOutputsCreatedAtMillis;
                        else
                            object.chcOutputsCreatedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.chcOutputsCreatedAtMillis) : options.longs === Number ? new $util.LongBits(message.chcOutputsCreatedAtMillis.low >>> 0, message.chcOutputsCreatedAtMillis.high >>> 0).toNumber() : message.chcOutputsCreatedAtMillis;
                    if (message.chcOutputsRawDataFilePath != null && message.hasOwnProperty("chcOutputsRawDataFilePath"))
                        object.chcOutputsRawDataFilePath = message.chcOutputsRawDataFilePath;
                    if (message.chcOutputsAvailable != null && message.hasOwnProperty("chcOutputsAvailable"))
                        object.chcOutputsAvailable = message.chcOutputsAvailable;
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        object.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.toObject(message.pbmBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "pbmBvOutputs";
                    }
                    if (message.curveballs && message.curveballs.length) {
                        object.curveballs = [];
                        for (var j = 0; j < message.curveballs.length; ++j)
                            object.curveballs[j] = options.enums === String ? $root.infinitusai.be.Curveball[message.curveballs[j]] === undefined ? message.curveballs[j] : $root.infinitusai.be.Curveball[message.curveballs[j]] : message.curveballs[j];
                    }
                    if (message.webhook != null && message.hasOwnProperty("webhook"))
                        object.webhook = $root.infinitusai.be.TaskWebhook.toObject(message.webhook, options);
                    if (message.hasPotentialAdverseEvent != null && message.hasOwnProperty("hasPotentialAdverseEvent"))
                        object.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.toObject(message.hasPotentialAdverseEvent, options);
                    if (message.potentialAdverseEventNotes != null && message.hasOwnProperty("potentialAdverseEventNotes"))
                        object.potentialAdverseEventNotes = message.potentialAdverseEventNotes;
                    if (message.outOfNetworkOutputs != null && message.hasOwnProperty("outOfNetworkOutputs"))
                        object.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.toObject(message.outOfNetworkOutputs, options);
                    if (message.customerUrl != null && message.hasOwnProperty("customerUrl"))
                        object.customerUrl = message.customerUrl;
                    if (message.payerIntelligenceStateAtEscalation != null && message.hasOwnProperty("payerIntelligenceStateAtEscalation"))
                        object.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.toObject(message.payerIntelligenceStateAtEscalation, options);
                    if (message.additionalRecordingUuids && message.additionalRecordingUuids.length) {
                        object.additionalRecordingUuids = [];
                        for (var j = 0; j < message.additionalRecordingUuids.length; ++j)
                            object.additionalRecordingUuids[j] = message.additionalRecordingUuids[j];
                    }
                    if (message.previousBvOutputs != null && message.hasOwnProperty("previousBvOutputs"))
                        object.previousBvOutputs = $root.infinitusai.be.CallOutputs.toObject(message.previousBvOutputs, options);
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        object.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.toObject(message.covidVaccineAvailabilityCallOutputs, options);
                        if (options.oneofs)
                            object.outputs = "covidVaccineAvailabilityCallOutputs";
                    }
                    if (message.annotations && message.annotations.length) {
                        object.annotations = [];
                        for (var j = 0; j < message.annotations.length; ++j)
                            object.annotations[j] = options.enums === String ? $root.infinitusai.be.TaskAnnotation[message.annotations[j]] === undefined ? message.annotations[j] : $root.infinitusai.be.TaskAnnotation[message.annotations[j]] : message.annotations[j];
                    }
                    if (message.headlessHangups && message.headlessHangups.length) {
                        object.headlessHangups = [];
                        for (var j = 0; j < message.headlessHangups.length; ++j)
                            object.headlessHangups[j] = $root.infinitusai.be.TaskDoc.HeadlessHangup.toObject(message.headlessHangups[j], options);
                    }
                    if (message.customerReviewExists != null && message.hasOwnProperty("customerReviewExists"))
                        object.customerReviewExists = $root.infinitusai.be.NullableBool.toObject(message.customerReviewExists, options);
                    if (message.customerTaskUuid != null && message.hasOwnProperty("customerTaskUuid"))
                        object.customerTaskUuid = message.customerTaskUuid;
                    var keys2;
                    if (message.requeueReasonCounters && (keys2 = Object.keys(message.requeueReasonCounters)).length) {
                        object.requeueReasonCounters = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.requeueReasonCounters[keys2[j]] = message.requeueReasonCounters[keys2[j]];
                    }
                    if (message.taskNotesInternal != null && message.hasOwnProperty("taskNotesInternal"))
                        object.taskNotesInternal = message.taskNotesInternal;
                    if (message.loadTestMode != null && message.hasOwnProperty("loadTestMode"))
                        object.loadTestMode = message.loadTestMode;
                    if (message.taskStateTransition != null && message.hasOwnProperty("taskStateTransition"))
                        object.taskStateTransition = options.enums === String ? $root.infinitusai.be.TaskStateTransition[message.taskStateTransition] === undefined ? message.taskStateTransition : $root.infinitusai.be.TaskStateTransition[message.taskStateTransition] : message.taskStateTransition;
                    if (message.taskDueTimestampMillis != null && message.hasOwnProperty("taskDueTimestampMillis"))
                        if (typeof message.taskDueTimestampMillis === "number")
                            object.taskDueTimestampMillis = options.longs === String ? String(message.taskDueTimestampMillis) : message.taskDueTimestampMillis;
                        else
                            object.taskDueTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.taskDueTimestampMillis) : options.longs === Number ? new $util.LongBits(message.taskDueTimestampMillis.low >>> 0, message.taskDueTimestampMillis.high >>> 0).toNumber() : message.taskDueTimestampMillis;
                    if (message.annualReenrollment != null && message.hasOwnProperty("annualReenrollment"))
                        object.annualReenrollment = message.annualReenrollment;
                    if (message.needsHumanIntro != null && message.hasOwnProperty("needsHumanIntro"))
                        object.needsHumanIntro = message.needsHumanIntro;
                    if (message.operatorTrainerScriptId != null && message.hasOwnProperty("operatorTrainerScriptId"))
                        object.operatorTrainerScriptId = message.operatorTrainerScriptId;
                    if (message.isInMultitaskCall != null && message.hasOwnProperty("isInMultitaskCall"))
                        object.isInMultitaskCall = message.isInMultitaskCall;
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        object.customerSegment = message.customerSegment;
                    if (message.isMultiBenefitTask != null && message.hasOwnProperty("isMultiBenefitTask"))
                        object.isMultiBenefitTask = message.isMultiBenefitTask;
                    if (message.multiBenefitTaskId != null && message.hasOwnProperty("multiBenefitTaskId"))
                        object.multiBenefitTaskId = message.multiBenefitTaskId;
                    if (message.baseCallUuidForNextCall != null && message.hasOwnProperty("baseCallUuidForNextCall"))
                        object.baseCallUuidForNextCall = message.baseCallUuidForNextCall;
                    if (message.autoRequeueConfigs && message.autoRequeueConfigs.length) {
                        object.autoRequeueConfigs = [];
                        for (var j = 0; j < message.autoRequeueConfigs.length; ++j)
                            object.autoRequeueConfigs[j] = $root.infinitusai.be.AutoRequeueConfig.toObject(message.autoRequeueConfigs[j], options);
                    }
                    if (message.overridingHumanCallsToRealisticCall != null && message.hasOwnProperty("overridingHumanCallsToRealisticCall"))
                        object.overridingHumanCallsToRealisticCall = message.overridingHumanCallsToRealisticCall;
                    if (message.needsManualIvr != null && message.hasOwnProperty("needsManualIvr"))
                        object.needsManualIvr = message.needsManualIvr;
                    if (message.isFtiTask != null && message.hasOwnProperty("isFtiTask"))
                        object.isFtiTask = $root.infinitusai.be.NullableBool.toObject(message.isFtiTask, options);
                    if (message.nextVendorApiCallTimestampMillis != null && message.hasOwnProperty("nextVendorApiCallTimestampMillis"))
                        if (typeof message.nextVendorApiCallTimestampMillis === "number")
                            object.nextVendorApiCallTimestampMillis = options.longs === String ? String(message.nextVendorApiCallTimestampMillis) : message.nextVendorApiCallTimestampMillis;
                        else
                            object.nextVendorApiCallTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.nextVendorApiCallTimestampMillis) : options.longs === Number ? new $util.LongBits(message.nextVendorApiCallTimestampMillis.low >>> 0, message.nextVendorApiCallTimestampMillis.high >>> 0).toNumber() : message.nextVendorApiCallTimestampMillis;
                    if (message.pverifyApiCallCount != null && message.hasOwnProperty("pverifyApiCallCount"))
                        object.pverifyApiCallCount = message.pverifyApiCallCount;
                    if (message.previousBvDate != null && message.hasOwnProperty("previousBvDate"))
                        object.previousBvDate = message.previousBvDate;
                    if (message.previousPbmDate != null && message.hasOwnProperty("previousPbmDate"))
                        object.previousPbmDate = message.previousPbmDate;
                    if (message.fastTrackSuccessfulTaskNotes != null && message.hasOwnProperty("fastTrackSuccessfulTaskNotes"))
                        object.fastTrackSuccessfulTaskNotes = $root.infinitusai.be.FastTrackSuccessfulTaskNotes.toObject(message.fastTrackSuccessfulTaskNotes, options);
                    if (message.routeUserOnCallStart != null && message.hasOwnProperty("routeUserOnCallStart"))
                        object.routeUserOnCallStart = message.routeUserOnCallStart;
                    if (message.lastTaskAssignee != null && message.hasOwnProperty("lastTaskAssignee"))
                        object.lastTaskAssignee = $root.infinitusai.be.UserInfo.toObject(message.lastTaskAssignee, options);
                    if (message.fullyDigital != null && message.hasOwnProperty("fullyDigital"))
                        object.fullyDigital = message.fullyDigital;
                    return object;
                };
    
                /**
                 * Converts this TaskDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TaskDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TaskDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TaskDoc";
                };
    
                TaskDoc.HeadlessHangup = (function() {
    
                    /**
                     * Properties of a HeadlessHangup.
                     * @memberof infinitusai.be.TaskDoc
                     * @interface IHeadlessHangup
                     * @property {string|null} [callUuid] HeadlessHangup callUuid
                     * @property {number|Long|null} [hangupTimeMillis] HeadlessHangup hangupTimeMillis
                     */
    
                    /**
                     * Constructs a new HeadlessHangup.
                     * @memberof infinitusai.be.TaskDoc
                     * @classdesc Represents a HeadlessHangup.
                     * @implements IHeadlessHangup
                     * @constructor
                     * @param {infinitusai.be.TaskDoc.IHeadlessHangup=} [properties] Properties to set
                     */
                    function HeadlessHangup(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * HeadlessHangup callUuid.
                     * @member {string} callUuid
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @instance
                     */
                    HeadlessHangup.prototype.callUuid = "";
    
                    /**
                     * HeadlessHangup hangupTimeMillis.
                     * @member {number|Long} hangupTimeMillis
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @instance
                     */
                    HeadlessHangup.prototype.hangupTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new HeadlessHangup instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {infinitusai.be.TaskDoc.IHeadlessHangup=} [properties] Properties to set
                     * @returns {infinitusai.be.TaskDoc.HeadlessHangup} HeadlessHangup instance
                     */
                    HeadlessHangup.create = function create(properties) {
                        return new HeadlessHangup(properties);
                    };
    
                    /**
                     * Encodes the specified HeadlessHangup message. Does not implicitly {@link infinitusai.be.TaskDoc.HeadlessHangup.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {infinitusai.be.TaskDoc.IHeadlessHangup} message HeadlessHangup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HeadlessHangup.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                        if (message.hangupTimeMillis != null && Object.hasOwnProperty.call(message, "hangupTimeMillis"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.hangupTimeMillis);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified HeadlessHangup message, length delimited. Does not implicitly {@link infinitusai.be.TaskDoc.HeadlessHangup.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {infinitusai.be.TaskDoc.IHeadlessHangup} message HeadlessHangup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HeadlessHangup.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a HeadlessHangup message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.TaskDoc.HeadlessHangup} HeadlessHangup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HeadlessHangup.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskDoc.HeadlessHangup();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.callUuid = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.hangupTimeMillis = reader.int64();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a HeadlessHangup message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.TaskDoc.HeadlessHangup} HeadlessHangup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HeadlessHangup.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a HeadlessHangup message.
                     * @function verify
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    HeadlessHangup.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                            if (!$util.isString(message.callUuid))
                                return "callUuid: string expected";
                        if (message.hangupTimeMillis != null && message.hasOwnProperty("hangupTimeMillis"))
                            if (!$util.isInteger(message.hangupTimeMillis) && !(message.hangupTimeMillis && $util.isInteger(message.hangupTimeMillis.low) && $util.isInteger(message.hangupTimeMillis.high)))
                                return "hangupTimeMillis: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a HeadlessHangup message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.TaskDoc.HeadlessHangup} HeadlessHangup
                     */
                    HeadlessHangup.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.TaskDoc.HeadlessHangup)
                            return object;
                        var message = new $root.infinitusai.be.TaskDoc.HeadlessHangup();
                        if (object.callUuid != null)
                            message.callUuid = String(object.callUuid);
                        if (object.hangupTimeMillis != null)
                            if ($util.Long)
                                (message.hangupTimeMillis = $util.Long.fromValue(object.hangupTimeMillis)).unsigned = false;
                            else if (typeof object.hangupTimeMillis === "string")
                                message.hangupTimeMillis = parseInt(object.hangupTimeMillis, 10);
                            else if (typeof object.hangupTimeMillis === "number")
                                message.hangupTimeMillis = object.hangupTimeMillis;
                            else if (typeof object.hangupTimeMillis === "object")
                                message.hangupTimeMillis = new $util.LongBits(object.hangupTimeMillis.low >>> 0, object.hangupTimeMillis.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a HeadlessHangup message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {infinitusai.be.TaskDoc.HeadlessHangup} message HeadlessHangup
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    HeadlessHangup.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.callUuid = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.hangupTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.hangupTimeMillis = options.longs === String ? "0" : 0;
                        }
                        if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                            object.callUuid = message.callUuid;
                        if (message.hangupTimeMillis != null && message.hasOwnProperty("hangupTimeMillis"))
                            if (typeof message.hangupTimeMillis === "number")
                                object.hangupTimeMillis = options.longs === String ? String(message.hangupTimeMillis) : message.hangupTimeMillis;
                            else
                                object.hangupTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.hangupTimeMillis) : options.longs === Number ? new $util.LongBits(message.hangupTimeMillis.low >>> 0, message.hangupTimeMillis.high >>> 0).toNumber() : message.hangupTimeMillis;
                        return object;
                    };
    
                    /**
                     * Converts this HeadlessHangup to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    HeadlessHangup.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for HeadlessHangup
                     * @function getTypeUrl
                     * @memberof infinitusai.be.TaskDoc.HeadlessHangup
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    HeadlessHangup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.TaskDoc.HeadlessHangup";
                    };
    
                    return HeadlessHangup;
                })();
    
                return TaskDoc;
            })();
    
            be.CustomerTaskDoc = (function() {
    
                /**
                 * Properties of a CustomerTaskDoc.
                 * @memberof infinitusai.be
                 * @interface ICustomerTaskDoc
                 * @property {string|null} [taskUuid] CustomerTaskDoc taskUuid
                 * @property {string|null} [orgUuid] CustomerTaskDoc orgUuid
                 * @property {string|null} [programName] CustomerTaskDoc programName
                 * @property {infinitusai.be.TaskType|null} [taskType] CustomerTaskDoc taskType
                 * @property {infinitusai.be.TaskState|null} [state] CustomerTaskDoc state
                 * @property {number|Long|null} [taskCreationMillis] CustomerTaskDoc taskCreationMillis
                 * @property {number|Long|null} [taskReviewMillis] CustomerTaskDoc taskReviewMillis
                 * @property {string|null} [customerAssignedId] CustomerTaskDoc customerAssignedId
                 * @property {number|Long|null} [taskUpdatedMillis] CustomerTaskDoc taskUpdatedMillis
                 * @property {infinitusai.be.ISmallBVCallInputs|null} [bvInputs] CustomerTaskDoc bvInputs
                 * @property {infinitusai.be.ICustomerReview|null} [customerReview] CustomerTaskDoc customerReview
                 * @property {infinitusai.be.INullableBool|null} [customerReviewExists] CustomerTaskDoc customerReviewExists
                 * @property {infinitusai.be.IUserInfo|null} [taskCreator] CustomerTaskDoc taskCreator
                 * @property {infinitusai.be.INullableBool|null} [hasPotentialAdverseEvent] CustomerTaskDoc hasPotentialAdverseEvent
                 * @property {string|null} [customerUrl] CustomerTaskDoc customerUrl
                 * @property {infinitusai.be.ITaskFailReason|null} [failureReasonChosen] CustomerTaskDoc failureReasonChosen
                 * @property {infinitusai.be.TaskSource|null} [taskSource] CustomerTaskDoc taskSource
                 * @property {infinitusai.be.ITaskWebhook|null} [webhook] CustomerTaskDoc webhook
                 * @property {Array.<string>|null} [operatorTaskUuids] CustomerTaskDoc operatorTaskUuids
                 * @property {boolean|null} [chainPbm] CustomerTaskDoc chainPbm
                 * @property {string|null} [calleeName] CustomerTaskDoc calleeName
                 * @property {boolean|null} [chainBv] CustomerTaskDoc chainBv
                 * @property {string|null} [customerSegment] CustomerTaskDoc customerSegment
                 * @property {infinitusapi.IEhrData|null} [ehrData] CustomerTaskDoc ehrData
                 * @property {number|null} [timeSavingsMinutes] CustomerTaskDoc timeSavingsMinutes
                 * @property {infinitusai.be.INullableBool|null} [humanInvolved] CustomerTaskDoc humanInvolved
                 * @property {string|null} [bundleTaskId] CustomerTaskDoc bundleTaskId
                 * @property {infinitusai.be.INullableBool|null} [webhookDelivered] CustomerTaskDoc webhookDelivered
                 * @property {boolean|null} [chainingRequired] CustomerTaskDoc chainingRequired
                 */
    
                /**
                 * Constructs a new CustomerTaskDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CustomerTaskDoc.
                 * @implements ICustomerTaskDoc
                 * @constructor
                 * @param {infinitusai.be.ICustomerTaskDoc=} [properties] Properties to set
                 */
                function CustomerTaskDoc(properties) {
                    this.operatorTaskUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CustomerTaskDoc taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.taskUuid = "";
    
                /**
                 * CustomerTaskDoc orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.orgUuid = "";
    
                /**
                 * CustomerTaskDoc programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.programName = "";
    
                /**
                 * CustomerTaskDoc taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.taskType = 0;
    
                /**
                 * CustomerTaskDoc state.
                 * @member {infinitusai.be.TaskState} state
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.state = 0;
    
                /**
                 * CustomerTaskDoc taskCreationMillis.
                 * @member {number|Long} taskCreationMillis
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.taskCreationMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CustomerTaskDoc taskReviewMillis.
                 * @member {number|Long} taskReviewMillis
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.taskReviewMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CustomerTaskDoc customerAssignedId.
                 * @member {string} customerAssignedId
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.customerAssignedId = "";
    
                /**
                 * CustomerTaskDoc taskUpdatedMillis.
                 * @member {number|Long} taskUpdatedMillis
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.taskUpdatedMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CustomerTaskDoc bvInputs.
                 * @member {infinitusai.be.ISmallBVCallInputs|null|undefined} bvInputs
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.bvInputs = null;
    
                /**
                 * CustomerTaskDoc customerReview.
                 * @member {infinitusai.be.ICustomerReview|null|undefined} customerReview
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.customerReview = null;
    
                /**
                 * CustomerTaskDoc customerReviewExists.
                 * @member {infinitusai.be.INullableBool|null|undefined} customerReviewExists
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.customerReviewExists = null;
    
                /**
                 * CustomerTaskDoc taskCreator.
                 * @member {infinitusai.be.IUserInfo|null|undefined} taskCreator
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.taskCreator = null;
    
                /**
                 * CustomerTaskDoc hasPotentialAdverseEvent.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasPotentialAdverseEvent
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.hasPotentialAdverseEvent = null;
    
                /**
                 * CustomerTaskDoc customerUrl.
                 * @member {string} customerUrl
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.customerUrl = "";
    
                /**
                 * CustomerTaskDoc failureReasonChosen.
                 * @member {infinitusai.be.ITaskFailReason|null|undefined} failureReasonChosen
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.failureReasonChosen = null;
    
                /**
                 * CustomerTaskDoc taskSource.
                 * @member {infinitusai.be.TaskSource} taskSource
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.taskSource = 0;
    
                /**
                 * CustomerTaskDoc webhook.
                 * @member {infinitusai.be.ITaskWebhook|null|undefined} webhook
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.webhook = null;
    
                /**
                 * CustomerTaskDoc operatorTaskUuids.
                 * @member {Array.<string>} operatorTaskUuids
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.operatorTaskUuids = $util.emptyArray;
    
                /**
                 * CustomerTaskDoc chainPbm.
                 * @member {boolean} chainPbm
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.chainPbm = false;
    
                /**
                 * CustomerTaskDoc calleeName.
                 * @member {string} calleeName
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.calleeName = "";
    
                /**
                 * CustomerTaskDoc chainBv.
                 * @member {boolean} chainBv
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.chainBv = false;
    
                /**
                 * CustomerTaskDoc customerSegment.
                 * @member {string} customerSegment
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.customerSegment = "";
    
                /**
                 * CustomerTaskDoc ehrData.
                 * @member {infinitusapi.IEhrData|null|undefined} ehrData
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.ehrData = null;
    
                /**
                 * CustomerTaskDoc timeSavingsMinutes.
                 * @member {number} timeSavingsMinutes
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.timeSavingsMinutes = 0;
    
                /**
                 * CustomerTaskDoc humanInvolved.
                 * @member {infinitusai.be.INullableBool|null|undefined} humanInvolved
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.humanInvolved = null;
    
                /**
                 * CustomerTaskDoc bundleTaskId.
                 * @member {string} bundleTaskId
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.bundleTaskId = "";
    
                /**
                 * CustomerTaskDoc webhookDelivered.
                 * @member {infinitusai.be.INullableBool|null|undefined} webhookDelivered
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.webhookDelivered = null;
    
                /**
                 * CustomerTaskDoc chainingRequired.
                 * @member {boolean} chainingRequired
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                CustomerTaskDoc.prototype.chainingRequired = false;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * CustomerTaskDoc inputs.
                 * @member {"bvInputs"|undefined} inputs
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 */
                Object.defineProperty(CustomerTaskDoc.prototype, "inputs", {
                    get: $util.oneOfGetter($oneOfFields = ["bvInputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new CustomerTaskDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {infinitusai.be.ICustomerTaskDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.CustomerTaskDoc} CustomerTaskDoc instance
                 */
                CustomerTaskDoc.create = function create(properties) {
                    return new CustomerTaskDoc(properties);
                };
    
                /**
                 * Encodes the specified CustomerTaskDoc message. Does not implicitly {@link infinitusai.be.CustomerTaskDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {infinitusai.be.ICustomerTaskDoc} message CustomerTaskDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomerTaskDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuid);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.programName);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.taskType);
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.state);
                    if (message.taskCreationMillis != null && Object.hasOwnProperty.call(message, "taskCreationMillis"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.taskCreationMillis);
                    if (message.taskReviewMillis != null && Object.hasOwnProperty.call(message, "taskReviewMillis"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.taskReviewMillis);
                    if (message.customerAssignedId != null && Object.hasOwnProperty.call(message, "customerAssignedId"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.customerAssignedId);
                    if (message.taskUpdatedMillis != null && Object.hasOwnProperty.call(message, "taskUpdatedMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.taskUpdatedMillis);
                    if (message.bvInputs != null && Object.hasOwnProperty.call(message, "bvInputs"))
                        $root.infinitusai.be.SmallBVCallInputs.encode(message.bvInputs, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.customerReview != null && Object.hasOwnProperty.call(message, "customerReview"))
                        $root.infinitusai.be.CustomerReview.encode(message.customerReview, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.taskCreator != null && Object.hasOwnProperty.call(message, "taskCreator"))
                        $root.infinitusai.be.UserInfo.encode(message.taskCreator, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.hasPotentialAdverseEvent != null && Object.hasOwnProperty.call(message, "hasPotentialAdverseEvent"))
                        $root.infinitusai.be.NullableBool.encode(message.hasPotentialAdverseEvent, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.customerUrl != null && Object.hasOwnProperty.call(message, "customerUrl"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.customerUrl);
                    if (message.failureReasonChosen != null && Object.hasOwnProperty.call(message, "failureReasonChosen"))
                        $root.infinitusai.be.TaskFailReason.encode(message.failureReasonChosen, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.customerReviewExists != null && Object.hasOwnProperty.call(message, "customerReviewExists"))
                        $root.infinitusai.be.NullableBool.encode(message.customerReviewExists, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.taskSource != null && Object.hasOwnProperty.call(message, "taskSource"))
                        writer.uint32(/* id 17, wireType 0 =*/136).int32(message.taskSource);
                    if (message.webhook != null && Object.hasOwnProperty.call(message, "webhook"))
                        $root.infinitusai.be.TaskWebhook.encode(message.webhook, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.operatorTaskUuids != null && message.operatorTaskUuids.length)
                        for (var i = 0; i < message.operatorTaskUuids.length; ++i)
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.operatorTaskUuids[i]);
                    if (message.chainPbm != null && Object.hasOwnProperty.call(message, "chainPbm"))
                        writer.uint32(/* id 20, wireType 0 =*/160).bool(message.chainPbm);
                    if (message.calleeName != null && Object.hasOwnProperty.call(message, "calleeName"))
                        writer.uint32(/* id 21, wireType 2 =*/170).string(message.calleeName);
                    if (message.chainBv != null && Object.hasOwnProperty.call(message, "chainBv"))
                        writer.uint32(/* id 22, wireType 0 =*/176).bool(message.chainBv);
                    if (message.customerSegment != null && Object.hasOwnProperty.call(message, "customerSegment"))
                        writer.uint32(/* id 23, wireType 2 =*/186).string(message.customerSegment);
                    if (message.ehrData != null && Object.hasOwnProperty.call(message, "ehrData"))
                        $root.infinitusapi.EhrData.encode(message.ehrData, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                    if (message.timeSavingsMinutes != null && Object.hasOwnProperty.call(message, "timeSavingsMinutes"))
                        writer.uint32(/* id 25, wireType 5 =*/205).float(message.timeSavingsMinutes);
                    if (message.humanInvolved != null && Object.hasOwnProperty.call(message, "humanInvolved"))
                        $root.infinitusai.be.NullableBool.encode(message.humanInvolved, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                    if (message.bundleTaskId != null && Object.hasOwnProperty.call(message, "bundleTaskId"))
                        writer.uint32(/* id 27, wireType 2 =*/218).string(message.bundleTaskId);
                    if (message.webhookDelivered != null && Object.hasOwnProperty.call(message, "webhookDelivered"))
                        $root.infinitusai.be.NullableBool.encode(message.webhookDelivered, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                    if (message.chainingRequired != null && Object.hasOwnProperty.call(message, "chainingRequired"))
                        writer.uint32(/* id 29, wireType 0 =*/232).bool(message.chainingRequired);
                    return writer;
                };
    
                /**
                 * Encodes the specified CustomerTaskDoc message, length delimited. Does not implicitly {@link infinitusai.be.CustomerTaskDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {infinitusai.be.ICustomerTaskDoc} message CustomerTaskDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomerTaskDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CustomerTaskDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CustomerTaskDoc} CustomerTaskDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomerTaskDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CustomerTaskDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.programName = reader.string();
                                break;
                            }
                        case 4: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 5: {
                                message.state = reader.int32();
                                break;
                            }
                        case 6: {
                                message.taskCreationMillis = reader.int64();
                                break;
                            }
                        case 7: {
                                message.taskReviewMillis = reader.int64();
                                break;
                            }
                        case 8: {
                                message.customerAssignedId = reader.string();
                                break;
                            }
                        case 9: {
                                message.taskUpdatedMillis = reader.int64();
                                break;
                            }
                        case 10: {
                                message.bvInputs = $root.infinitusai.be.SmallBVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.customerReview = $root.infinitusai.be.CustomerReview.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.customerReviewExists = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.taskCreator = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.customerUrl = reader.string();
                                break;
                            }
                        case 15: {
                                message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.taskSource = reader.int32();
                                break;
                            }
                        case 18: {
                                message.webhook = $root.infinitusai.be.TaskWebhook.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                if (!(message.operatorTaskUuids && message.operatorTaskUuids.length))
                                    message.operatorTaskUuids = [];
                                message.operatorTaskUuids.push(reader.string());
                                break;
                            }
                        case 20: {
                                message.chainPbm = reader.bool();
                                break;
                            }
                        case 21: {
                                message.calleeName = reader.string();
                                break;
                            }
                        case 22: {
                                message.chainBv = reader.bool();
                                break;
                            }
                        case 23: {
                                message.customerSegment = reader.string();
                                break;
                            }
                        case 24: {
                                message.ehrData = $root.infinitusapi.EhrData.decode(reader, reader.uint32());
                                break;
                            }
                        case 25: {
                                message.timeSavingsMinutes = reader.float();
                                break;
                            }
                        case 26: {
                                message.humanInvolved = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 27: {
                                message.bundleTaskId = reader.string();
                                break;
                            }
                        case 28: {
                                message.webhookDelivered = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 29: {
                                message.chainingRequired = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CustomerTaskDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CustomerTaskDoc} CustomerTaskDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomerTaskDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CustomerTaskDoc message.
                 * @function verify
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CustomerTaskDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                            break;
                        }
                    if (message.taskCreationMillis != null && message.hasOwnProperty("taskCreationMillis"))
                        if (!$util.isInteger(message.taskCreationMillis) && !(message.taskCreationMillis && $util.isInteger(message.taskCreationMillis.low) && $util.isInteger(message.taskCreationMillis.high)))
                            return "taskCreationMillis: integer|Long expected";
                    if (message.taskReviewMillis != null && message.hasOwnProperty("taskReviewMillis"))
                        if (!$util.isInteger(message.taskReviewMillis) && !(message.taskReviewMillis && $util.isInteger(message.taskReviewMillis.low) && $util.isInteger(message.taskReviewMillis.high)))
                            return "taskReviewMillis: integer|Long expected";
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        if (!$util.isString(message.customerAssignedId))
                            return "customerAssignedId: string expected";
                    if (message.taskUpdatedMillis != null && message.hasOwnProperty("taskUpdatedMillis"))
                        if (!$util.isInteger(message.taskUpdatedMillis) && !(message.taskUpdatedMillis && $util.isInteger(message.taskUpdatedMillis.low) && $util.isInteger(message.taskUpdatedMillis.high)))
                            return "taskUpdatedMillis: integer|Long expected";
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        properties.inputs = 1;
                        {
                            var error = $root.infinitusai.be.SmallBVCallInputs.verify(message.bvInputs);
                            if (error)
                                return "bvInputs." + error;
                        }
                    }
                    if (message.customerReview != null && message.hasOwnProperty("customerReview")) {
                        var error = $root.infinitusai.be.CustomerReview.verify(message.customerReview);
                        if (error)
                            return "customerReview." + error;
                    }
                    if (message.customerReviewExists != null && message.hasOwnProperty("customerReviewExists")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.customerReviewExists);
                        if (error)
                            return "customerReviewExists." + error;
                    }
                    if (message.taskCreator != null && message.hasOwnProperty("taskCreator")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.taskCreator);
                        if (error)
                            return "taskCreator." + error;
                    }
                    if (message.hasPotentialAdverseEvent != null && message.hasOwnProperty("hasPotentialAdverseEvent")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasPotentialAdverseEvent);
                        if (error)
                            return "hasPotentialAdverseEvent." + error;
                    }
                    if (message.customerUrl != null && message.hasOwnProperty("customerUrl"))
                        if (!$util.isString(message.customerUrl))
                            return "customerUrl: string expected";
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen")) {
                        var error = $root.infinitusai.be.TaskFailReason.verify(message.failureReasonChosen);
                        if (error)
                            return "failureReasonChosen." + error;
                    }
                    if (message.taskSource != null && message.hasOwnProperty("taskSource"))
                        switch (message.taskSource) {
                        default:
                            return "taskSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                    if (message.webhook != null && message.hasOwnProperty("webhook")) {
                        var error = $root.infinitusai.be.TaskWebhook.verify(message.webhook);
                        if (error)
                            return "webhook." + error;
                    }
                    if (message.operatorTaskUuids != null && message.hasOwnProperty("operatorTaskUuids")) {
                        if (!Array.isArray(message.operatorTaskUuids))
                            return "operatorTaskUuids: array expected";
                        for (var i = 0; i < message.operatorTaskUuids.length; ++i)
                            if (!$util.isString(message.operatorTaskUuids[i]))
                                return "operatorTaskUuids: string[] expected";
                    }
                    if (message.chainPbm != null && message.hasOwnProperty("chainPbm"))
                        if (typeof message.chainPbm !== "boolean")
                            return "chainPbm: boolean expected";
                    if (message.calleeName != null && message.hasOwnProperty("calleeName"))
                        if (!$util.isString(message.calleeName))
                            return "calleeName: string expected";
                    if (message.chainBv != null && message.hasOwnProperty("chainBv"))
                        if (typeof message.chainBv !== "boolean")
                            return "chainBv: boolean expected";
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        if (!$util.isString(message.customerSegment))
                            return "customerSegment: string expected";
                    if (message.ehrData != null && message.hasOwnProperty("ehrData")) {
                        var error = $root.infinitusapi.EhrData.verify(message.ehrData);
                        if (error)
                            return "ehrData." + error;
                    }
                    if (message.timeSavingsMinutes != null && message.hasOwnProperty("timeSavingsMinutes"))
                        if (typeof message.timeSavingsMinutes !== "number")
                            return "timeSavingsMinutes: number expected";
                    if (message.humanInvolved != null && message.hasOwnProperty("humanInvolved")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.humanInvolved);
                        if (error)
                            return "humanInvolved." + error;
                    }
                    if (message.bundleTaskId != null && message.hasOwnProperty("bundleTaskId"))
                        if (!$util.isString(message.bundleTaskId))
                            return "bundleTaskId: string expected";
                    if (message.webhookDelivered != null && message.hasOwnProperty("webhookDelivered")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.webhookDelivered);
                        if (error)
                            return "webhookDelivered." + error;
                    }
                    if (message.chainingRequired != null && message.hasOwnProperty("chainingRequired"))
                        if (typeof message.chainingRequired !== "boolean")
                            return "chainingRequired: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a CustomerTaskDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CustomerTaskDoc} CustomerTaskDoc
                 */
                CustomerTaskDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CustomerTaskDoc)
                        return object;
                    var message = new $root.infinitusai.be.CustomerTaskDoc();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "TASK_STATE_UNKNOWN":
                    case 0:
                        message.state = 0;
                        break;
                    case "TASK_STATE_CREATED":
                    case 1:
                        message.state = 1;
                        break;
                    case "TASK_STATE_CALL_CONNECTED":
                    case 2:
                        message.state = 2;
                        break;
                    case "TASK_STATE_CALL_DISCONNECTED":
                    case 3:
                        message.state = 3;
                        break;
                    case "TASK_STATE_FAILED":
                    case 4:
                        message.state = 4;
                        break;
                    case "TASK_STATE_REVIEWED":
                    case 5:
                        message.state = 5;
                        break;
                    case "TASK_STATE_READY_FOR_REVIEW":
                    case 6:
                        message.state = 6;
                        break;
                    case "TASK_STATE_IN_REVIEW":
                    case 7:
                        message.state = 7;
                        break;
                    case "TASK_STATE_IN_PROGRESS":
                    case 8:
                        message.state = 8;
                        break;
                    case "TASK_STATE_PARTIAL_SUCCESS":
                    case 9:
                        message.state = 9;
                        break;
                    case "TASK_STATE_READY_FOR_SELF_REVIEW":
                    case 10:
                        message.state = 10;
                        break;
                    case "TASK_STATE_AUTO_CLOSED":
                    case 11:
                        message.state = 11;
                        break;
                    case "TASK_STATE_PENDING_AUTO_REQUEUE":
                    case 12:
                        message.state = 12;
                        break;
                    case "TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL":
                    case 13:
                        message.state = 13;
                        break;
                    }
                    if (object.taskCreationMillis != null)
                        if ($util.Long)
                            (message.taskCreationMillis = $util.Long.fromValue(object.taskCreationMillis)).unsigned = false;
                        else if (typeof object.taskCreationMillis === "string")
                            message.taskCreationMillis = parseInt(object.taskCreationMillis, 10);
                        else if (typeof object.taskCreationMillis === "number")
                            message.taskCreationMillis = object.taskCreationMillis;
                        else if (typeof object.taskCreationMillis === "object")
                            message.taskCreationMillis = new $util.LongBits(object.taskCreationMillis.low >>> 0, object.taskCreationMillis.high >>> 0).toNumber();
                    if (object.taskReviewMillis != null)
                        if ($util.Long)
                            (message.taskReviewMillis = $util.Long.fromValue(object.taskReviewMillis)).unsigned = false;
                        else if (typeof object.taskReviewMillis === "string")
                            message.taskReviewMillis = parseInt(object.taskReviewMillis, 10);
                        else if (typeof object.taskReviewMillis === "number")
                            message.taskReviewMillis = object.taskReviewMillis;
                        else if (typeof object.taskReviewMillis === "object")
                            message.taskReviewMillis = new $util.LongBits(object.taskReviewMillis.low >>> 0, object.taskReviewMillis.high >>> 0).toNumber();
                    if (object.customerAssignedId != null)
                        message.customerAssignedId = String(object.customerAssignedId);
                    if (object.taskUpdatedMillis != null)
                        if ($util.Long)
                            (message.taskUpdatedMillis = $util.Long.fromValue(object.taskUpdatedMillis)).unsigned = false;
                        else if (typeof object.taskUpdatedMillis === "string")
                            message.taskUpdatedMillis = parseInt(object.taskUpdatedMillis, 10);
                        else if (typeof object.taskUpdatedMillis === "number")
                            message.taskUpdatedMillis = object.taskUpdatedMillis;
                        else if (typeof object.taskUpdatedMillis === "object")
                            message.taskUpdatedMillis = new $util.LongBits(object.taskUpdatedMillis.low >>> 0, object.taskUpdatedMillis.high >>> 0).toNumber();
                    if (object.bvInputs != null) {
                        if (typeof object.bvInputs !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.bvInputs: object expected");
                        message.bvInputs = $root.infinitusai.be.SmallBVCallInputs.fromObject(object.bvInputs);
                    }
                    if (object.customerReview != null) {
                        if (typeof object.customerReview !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.customerReview: object expected");
                        message.customerReview = $root.infinitusai.be.CustomerReview.fromObject(object.customerReview);
                    }
                    if (object.customerReviewExists != null) {
                        if (typeof object.customerReviewExists !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.customerReviewExists: object expected");
                        message.customerReviewExists = $root.infinitusai.be.NullableBool.fromObject(object.customerReviewExists);
                    }
                    if (object.taskCreator != null) {
                        if (typeof object.taskCreator !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.taskCreator: object expected");
                        message.taskCreator = $root.infinitusai.be.UserInfo.fromObject(object.taskCreator);
                    }
                    if (object.hasPotentialAdverseEvent != null) {
                        if (typeof object.hasPotentialAdverseEvent !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.hasPotentialAdverseEvent: object expected");
                        message.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.fromObject(object.hasPotentialAdverseEvent);
                    }
                    if (object.customerUrl != null)
                        message.customerUrl = String(object.customerUrl);
                    if (object.failureReasonChosen != null) {
                        if (typeof object.failureReasonChosen !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.failureReasonChosen: object expected");
                        message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.fromObject(object.failureReasonChosen);
                    }
                    switch (object.taskSource) {
                    default:
                        if (typeof object.taskSource === "number") {
                            message.taskSource = object.taskSource;
                            break;
                        }
                        break;
                    case "TASK_SOURCE_UNKNOWN":
                    case 0:
                        message.taskSource = 0;
                        break;
                    case "TASK_SOURCE_ETL_SCRIPT":
                    case 1:
                        message.taskSource = 1;
                        break;
                    case "TASK_SOURCE_FE":
                    case 2:
                        message.taskSource = 2;
                        break;
                    case "TASK_SOURCE_CUSTOMER_PORTAL":
                    case 3:
                        message.taskSource = 3;
                        break;
                    case "TASK_SOURCE_CUSTOMER_API":
                    case 4:
                        message.taskSource = 4;
                        break;
                    case "TASK_SOURCE_SETUP":
                    case 5:
                        message.taskSource = 5;
                        break;
                    case "TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD":
                    case 6:
                        message.taskSource = 6;
                        break;
                    case "TASK_SOURCE_FASTTRACK":
                    case 7:
                        message.taskSource = 7;
                        break;
                    case "TASK_SOURCE_FASTTRACK_SF":
                    case 8:
                        message.taskSource = 8;
                        break;
                    case "TASK_SOURCE_FASTTRACK_CSV_UPLOAD":
                    case 9:
                        message.taskSource = 9;
                        break;
                    }
                    if (object.webhook != null) {
                        if (typeof object.webhook !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.webhook: object expected");
                        message.webhook = $root.infinitusai.be.TaskWebhook.fromObject(object.webhook);
                    }
                    if (object.operatorTaskUuids) {
                        if (!Array.isArray(object.operatorTaskUuids))
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.operatorTaskUuids: array expected");
                        message.operatorTaskUuids = [];
                        for (var i = 0; i < object.operatorTaskUuids.length; ++i)
                            message.operatorTaskUuids[i] = String(object.operatorTaskUuids[i]);
                    }
                    if (object.chainPbm != null)
                        message.chainPbm = Boolean(object.chainPbm);
                    if (object.calleeName != null)
                        message.calleeName = String(object.calleeName);
                    if (object.chainBv != null)
                        message.chainBv = Boolean(object.chainBv);
                    if (object.customerSegment != null)
                        message.customerSegment = String(object.customerSegment);
                    if (object.ehrData != null) {
                        if (typeof object.ehrData !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.ehrData: object expected");
                        message.ehrData = $root.infinitusapi.EhrData.fromObject(object.ehrData);
                    }
                    if (object.timeSavingsMinutes != null)
                        message.timeSavingsMinutes = Number(object.timeSavingsMinutes);
                    if (object.humanInvolved != null) {
                        if (typeof object.humanInvolved !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.humanInvolved: object expected");
                        message.humanInvolved = $root.infinitusai.be.NullableBool.fromObject(object.humanInvolved);
                    }
                    if (object.bundleTaskId != null)
                        message.bundleTaskId = String(object.bundleTaskId);
                    if (object.webhookDelivered != null) {
                        if (typeof object.webhookDelivered !== "object")
                            throw TypeError(".infinitusai.be.CustomerTaskDoc.webhookDelivered: object expected");
                        message.webhookDelivered = $root.infinitusai.be.NullableBool.fromObject(object.webhookDelivered);
                    }
                    if (object.chainingRequired != null)
                        message.chainingRequired = Boolean(object.chainingRequired);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CustomerTaskDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {infinitusai.be.CustomerTaskDoc} message CustomerTaskDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CustomerTaskDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorTaskUuids = [];
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.orgUuid = "";
                        object.programName = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.state = options.enums === String ? "TASK_STATE_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.taskCreationMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.taskCreationMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.taskReviewMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.taskReviewMillis = options.longs === String ? "0" : 0;
                        object.customerAssignedId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.taskUpdatedMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.taskUpdatedMillis = options.longs === String ? "0" : 0;
                        object.customerReview = null;
                        object.taskCreator = null;
                        object.hasPotentialAdverseEvent = null;
                        object.customerUrl = "";
                        object.failureReasonChosen = null;
                        object.customerReviewExists = null;
                        object.taskSource = options.enums === String ? "TASK_SOURCE_UNKNOWN" : 0;
                        object.webhook = null;
                        object.chainPbm = false;
                        object.calleeName = "";
                        object.chainBv = false;
                        object.customerSegment = "";
                        object.ehrData = null;
                        object.timeSavingsMinutes = 0;
                        object.humanInvolved = null;
                        object.bundleTaskId = "";
                        object.webhookDelivered = null;
                        object.chainingRequired = false;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.infinitusai.be.TaskState[message.state] === undefined ? message.state : $root.infinitusai.be.TaskState[message.state] : message.state;
                    if (message.taskCreationMillis != null && message.hasOwnProperty("taskCreationMillis"))
                        if (typeof message.taskCreationMillis === "number")
                            object.taskCreationMillis = options.longs === String ? String(message.taskCreationMillis) : message.taskCreationMillis;
                        else
                            object.taskCreationMillis = options.longs === String ? $util.Long.prototype.toString.call(message.taskCreationMillis) : options.longs === Number ? new $util.LongBits(message.taskCreationMillis.low >>> 0, message.taskCreationMillis.high >>> 0).toNumber() : message.taskCreationMillis;
                    if (message.taskReviewMillis != null && message.hasOwnProperty("taskReviewMillis"))
                        if (typeof message.taskReviewMillis === "number")
                            object.taskReviewMillis = options.longs === String ? String(message.taskReviewMillis) : message.taskReviewMillis;
                        else
                            object.taskReviewMillis = options.longs === String ? $util.Long.prototype.toString.call(message.taskReviewMillis) : options.longs === Number ? new $util.LongBits(message.taskReviewMillis.low >>> 0, message.taskReviewMillis.high >>> 0).toNumber() : message.taskReviewMillis;
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        object.customerAssignedId = message.customerAssignedId;
                    if (message.taskUpdatedMillis != null && message.hasOwnProperty("taskUpdatedMillis"))
                        if (typeof message.taskUpdatedMillis === "number")
                            object.taskUpdatedMillis = options.longs === String ? String(message.taskUpdatedMillis) : message.taskUpdatedMillis;
                        else
                            object.taskUpdatedMillis = options.longs === String ? $util.Long.prototype.toString.call(message.taskUpdatedMillis) : options.longs === Number ? new $util.LongBits(message.taskUpdatedMillis.low >>> 0, message.taskUpdatedMillis.high >>> 0).toNumber() : message.taskUpdatedMillis;
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        object.bvInputs = $root.infinitusai.be.SmallBVCallInputs.toObject(message.bvInputs, options);
                        if (options.oneofs)
                            object.inputs = "bvInputs";
                    }
                    if (message.customerReview != null && message.hasOwnProperty("customerReview"))
                        object.customerReview = $root.infinitusai.be.CustomerReview.toObject(message.customerReview, options);
                    if (message.taskCreator != null && message.hasOwnProperty("taskCreator"))
                        object.taskCreator = $root.infinitusai.be.UserInfo.toObject(message.taskCreator, options);
                    if (message.hasPotentialAdverseEvent != null && message.hasOwnProperty("hasPotentialAdverseEvent"))
                        object.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.toObject(message.hasPotentialAdverseEvent, options);
                    if (message.customerUrl != null && message.hasOwnProperty("customerUrl"))
                        object.customerUrl = message.customerUrl;
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen"))
                        object.failureReasonChosen = $root.infinitusai.be.TaskFailReason.toObject(message.failureReasonChosen, options);
                    if (message.customerReviewExists != null && message.hasOwnProperty("customerReviewExists"))
                        object.customerReviewExists = $root.infinitusai.be.NullableBool.toObject(message.customerReviewExists, options);
                    if (message.taskSource != null && message.hasOwnProperty("taskSource"))
                        object.taskSource = options.enums === String ? $root.infinitusai.be.TaskSource[message.taskSource] === undefined ? message.taskSource : $root.infinitusai.be.TaskSource[message.taskSource] : message.taskSource;
                    if (message.webhook != null && message.hasOwnProperty("webhook"))
                        object.webhook = $root.infinitusai.be.TaskWebhook.toObject(message.webhook, options);
                    if (message.operatorTaskUuids && message.operatorTaskUuids.length) {
                        object.operatorTaskUuids = [];
                        for (var j = 0; j < message.operatorTaskUuids.length; ++j)
                            object.operatorTaskUuids[j] = message.operatorTaskUuids[j];
                    }
                    if (message.chainPbm != null && message.hasOwnProperty("chainPbm"))
                        object.chainPbm = message.chainPbm;
                    if (message.calleeName != null && message.hasOwnProperty("calleeName"))
                        object.calleeName = message.calleeName;
                    if (message.chainBv != null && message.hasOwnProperty("chainBv"))
                        object.chainBv = message.chainBv;
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        object.customerSegment = message.customerSegment;
                    if (message.ehrData != null && message.hasOwnProperty("ehrData"))
                        object.ehrData = $root.infinitusapi.EhrData.toObject(message.ehrData, options);
                    if (message.timeSavingsMinutes != null && message.hasOwnProperty("timeSavingsMinutes"))
                        object.timeSavingsMinutes = options.json && !isFinite(message.timeSavingsMinutes) ? String(message.timeSavingsMinutes) : message.timeSavingsMinutes;
                    if (message.humanInvolved != null && message.hasOwnProperty("humanInvolved"))
                        object.humanInvolved = $root.infinitusai.be.NullableBool.toObject(message.humanInvolved, options);
                    if (message.bundleTaskId != null && message.hasOwnProperty("bundleTaskId"))
                        object.bundleTaskId = message.bundleTaskId;
                    if (message.webhookDelivered != null && message.hasOwnProperty("webhookDelivered"))
                        object.webhookDelivered = $root.infinitusai.be.NullableBool.toObject(message.webhookDelivered, options);
                    if (message.chainingRequired != null && message.hasOwnProperty("chainingRequired"))
                        object.chainingRequired = message.chainingRequired;
                    return object;
                };
    
                /**
                 * Converts this CustomerTaskDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CustomerTaskDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CustomerTaskDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CustomerTaskDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CustomerTaskDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CustomerTaskDoc";
                };
    
                return CustomerTaskDoc;
            })();
    
            be.TaskDocChange = (function() {
    
                /**
                 * Properties of a TaskDocChange.
                 * @memberof infinitusai.be
                 * @interface ITaskDocChange
                 * @property {string|null} [changeUuid] TaskDocChange changeUuid
                 * @property {number|Long|null} [unixNanos] TaskDocChange unixNanos
                 * @property {Array.<string>|null} [changedFields] TaskDocChange changedFields
                 * @property {string|null} [orgUuid] TaskDocChange orgUuid
                 * @property {string|null} [taskUuid] TaskDocChange taskUuid
                 * @property {infinitusai.be.ITaskDoc|null} [change] TaskDocChange change
                 */
    
                /**
                 * Constructs a new TaskDocChange.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TaskDocChange.
                 * @implements ITaskDocChange
                 * @constructor
                 * @param {infinitusai.be.ITaskDocChange=} [properties] Properties to set
                 */
                function TaskDocChange(properties) {
                    this.changedFields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskDocChange changeUuid.
                 * @member {string} changeUuid
                 * @memberof infinitusai.be.TaskDocChange
                 * @instance
                 */
                TaskDocChange.prototype.changeUuid = "";
    
                /**
                 * TaskDocChange unixNanos.
                 * @member {number|Long} unixNanos
                 * @memberof infinitusai.be.TaskDocChange
                 * @instance
                 */
                TaskDocChange.prototype.unixNanos = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskDocChange changedFields.
                 * @member {Array.<string>} changedFields
                 * @memberof infinitusai.be.TaskDocChange
                 * @instance
                 */
                TaskDocChange.prototype.changedFields = $util.emptyArray;
    
                /**
                 * TaskDocChange orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.TaskDocChange
                 * @instance
                 */
                TaskDocChange.prototype.orgUuid = "";
    
                /**
                 * TaskDocChange taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.TaskDocChange
                 * @instance
                 */
                TaskDocChange.prototype.taskUuid = "";
    
                /**
                 * TaskDocChange change.
                 * @member {infinitusai.be.ITaskDoc|null|undefined} change
                 * @memberof infinitusai.be.TaskDocChange
                 * @instance
                 */
                TaskDocChange.prototype.change = null;
    
                /**
                 * Creates a new TaskDocChange instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {infinitusai.be.ITaskDocChange=} [properties] Properties to set
                 * @returns {infinitusai.be.TaskDocChange} TaskDocChange instance
                 */
                TaskDocChange.create = function create(properties) {
                    return new TaskDocChange(properties);
                };
    
                /**
                 * Encodes the specified TaskDocChange message. Does not implicitly {@link infinitusai.be.TaskDocChange.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {infinitusai.be.ITaskDocChange} message TaskDocChange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskDocChange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.changeUuid != null && Object.hasOwnProperty.call(message, "changeUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.changeUuid);
                    if (message.unixNanos != null && Object.hasOwnProperty.call(message, "unixNanos"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.unixNanos);
                    if (message.changedFields != null && message.changedFields.length)
                        for (var i = 0; i < message.changedFields.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.changedFields[i]);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.taskUuid);
                    if (message.change != null && Object.hasOwnProperty.call(message, "change"))
                        $root.infinitusai.be.TaskDoc.encode(message.change, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskDocChange message, length delimited. Does not implicitly {@link infinitusai.be.TaskDocChange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {infinitusai.be.ITaskDocChange} message TaskDocChange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskDocChange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskDocChange message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TaskDocChange} TaskDocChange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskDocChange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskDocChange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.changeUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.unixNanos = reader.int64();
                                break;
                            }
                        case 3: {
                                if (!(message.changedFields && message.changedFields.length))
                                    message.changedFields = [];
                                message.changedFields.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 6: {
                                message.change = $root.infinitusai.be.TaskDoc.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskDocChange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TaskDocChange} TaskDocChange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskDocChange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskDocChange message.
                 * @function verify
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskDocChange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.changeUuid != null && message.hasOwnProperty("changeUuid"))
                        if (!$util.isString(message.changeUuid))
                            return "changeUuid: string expected";
                    if (message.unixNanos != null && message.hasOwnProperty("unixNanos"))
                        if (!$util.isInteger(message.unixNanos) && !(message.unixNanos && $util.isInteger(message.unixNanos.low) && $util.isInteger(message.unixNanos.high)))
                            return "unixNanos: integer|Long expected";
                    if (message.changedFields != null && message.hasOwnProperty("changedFields")) {
                        if (!Array.isArray(message.changedFields))
                            return "changedFields: array expected";
                        for (var i = 0; i < message.changedFields.length; ++i)
                            if (!$util.isString(message.changedFields[i]))
                                return "changedFields: string[] expected";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.change != null && message.hasOwnProperty("change")) {
                        var error = $root.infinitusai.be.TaskDoc.verify(message.change);
                        if (error)
                            return "change." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a TaskDocChange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TaskDocChange} TaskDocChange
                 */
                TaskDocChange.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TaskDocChange)
                        return object;
                    var message = new $root.infinitusai.be.TaskDocChange();
                    if (object.changeUuid != null)
                        message.changeUuid = String(object.changeUuid);
                    if (object.unixNanos != null)
                        if ($util.Long)
                            (message.unixNanos = $util.Long.fromValue(object.unixNanos)).unsigned = false;
                        else if (typeof object.unixNanos === "string")
                            message.unixNanos = parseInt(object.unixNanos, 10);
                        else if (typeof object.unixNanos === "number")
                            message.unixNanos = object.unixNanos;
                        else if (typeof object.unixNanos === "object")
                            message.unixNanos = new $util.LongBits(object.unixNanos.low >>> 0, object.unixNanos.high >>> 0).toNumber();
                    if (object.changedFields) {
                        if (!Array.isArray(object.changedFields))
                            throw TypeError(".infinitusai.be.TaskDocChange.changedFields: array expected");
                        message.changedFields = [];
                        for (var i = 0; i < object.changedFields.length; ++i)
                            message.changedFields[i] = String(object.changedFields[i]);
                    }
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.change != null) {
                        if (typeof object.change !== "object")
                            throw TypeError(".infinitusai.be.TaskDocChange.change: object expected");
                        message.change = $root.infinitusai.be.TaskDoc.fromObject(object.change);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskDocChange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {infinitusai.be.TaskDocChange} message TaskDocChange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskDocChange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.changedFields = [];
                    if (options.defaults) {
                        object.changeUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.unixNanos = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.unixNanos = options.longs === String ? "0" : 0;
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.change = null;
                    }
                    if (message.changeUuid != null && message.hasOwnProperty("changeUuid"))
                        object.changeUuid = message.changeUuid;
                    if (message.unixNanos != null && message.hasOwnProperty("unixNanos"))
                        if (typeof message.unixNanos === "number")
                            object.unixNanos = options.longs === String ? String(message.unixNanos) : message.unixNanos;
                        else
                            object.unixNanos = options.longs === String ? $util.Long.prototype.toString.call(message.unixNanos) : options.longs === Number ? new $util.LongBits(message.unixNanos.low >>> 0, message.unixNanos.high >>> 0).toNumber() : message.unixNanos;
                    if (message.changedFields && message.changedFields.length) {
                        object.changedFields = [];
                        for (var j = 0; j < message.changedFields.length; ++j)
                            object.changedFields[j] = message.changedFields[j];
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.change != null && message.hasOwnProperty("change"))
                        object.change = $root.infinitusai.be.TaskDoc.toObject(message.change, options);
                    return object;
                };
    
                /**
                 * Converts this TaskDocChange to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TaskDocChange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskDocChange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskDocChange
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TaskDocChange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskDocChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TaskDocChange";
                };
    
                return TaskDocChange;
            })();
    
            be.FetchPatientTaskInfoFromEhrRequest = (function() {
    
                /**
                 * Properties of a FetchPatientTaskInfoFromEhrRequest.
                 * @memberof infinitusai.be
                 * @interface IFetchPatientTaskInfoFromEhrRequest
                 * @property {string|null} [patientId] FetchPatientTaskInfoFromEhrRequest patientId
                 */
    
                /**
                 * Constructs a new FetchPatientTaskInfoFromEhrRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FetchPatientTaskInfoFromEhrRequest.
                 * @implements IFetchPatientTaskInfoFromEhrRequest
                 * @constructor
                 * @param {infinitusai.be.IFetchPatientTaskInfoFromEhrRequest=} [properties] Properties to set
                 */
                function FetchPatientTaskInfoFromEhrRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FetchPatientTaskInfoFromEhrRequest patientId.
                 * @member {string} patientId
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @instance
                 */
                FetchPatientTaskInfoFromEhrRequest.prototype.patientId = "";
    
                /**
                 * Creates a new FetchPatientTaskInfoFromEhrRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {infinitusai.be.IFetchPatientTaskInfoFromEhrRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.FetchPatientTaskInfoFromEhrRequest} FetchPatientTaskInfoFromEhrRequest instance
                 */
                FetchPatientTaskInfoFromEhrRequest.create = function create(properties) {
                    return new FetchPatientTaskInfoFromEhrRequest(properties);
                };
    
                /**
                 * Encodes the specified FetchPatientTaskInfoFromEhrRequest message. Does not implicitly {@link infinitusai.be.FetchPatientTaskInfoFromEhrRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {infinitusai.be.IFetchPatientTaskInfoFromEhrRequest} message FetchPatientTaskInfoFromEhrRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FetchPatientTaskInfoFromEhrRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.patientId != null && Object.hasOwnProperty.call(message, "patientId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.patientId);
                    return writer;
                };
    
                /**
                 * Encodes the specified FetchPatientTaskInfoFromEhrRequest message, length delimited. Does not implicitly {@link infinitusai.be.FetchPatientTaskInfoFromEhrRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {infinitusai.be.IFetchPatientTaskInfoFromEhrRequest} message FetchPatientTaskInfoFromEhrRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FetchPatientTaskInfoFromEhrRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FetchPatientTaskInfoFromEhrRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FetchPatientTaskInfoFromEhrRequest} FetchPatientTaskInfoFromEhrRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FetchPatientTaskInfoFromEhrRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FetchPatientTaskInfoFromEhrRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.patientId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FetchPatientTaskInfoFromEhrRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FetchPatientTaskInfoFromEhrRequest} FetchPatientTaskInfoFromEhrRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FetchPatientTaskInfoFromEhrRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FetchPatientTaskInfoFromEhrRequest message.
                 * @function verify
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FetchPatientTaskInfoFromEhrRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.patientId != null && message.hasOwnProperty("patientId"))
                        if (!$util.isString(message.patientId))
                            return "patientId: string expected";
                    return null;
                };
    
                /**
                 * Creates a FetchPatientTaskInfoFromEhrRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FetchPatientTaskInfoFromEhrRequest} FetchPatientTaskInfoFromEhrRequest
                 */
                FetchPatientTaskInfoFromEhrRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FetchPatientTaskInfoFromEhrRequest)
                        return object;
                    var message = new $root.infinitusai.be.FetchPatientTaskInfoFromEhrRequest();
                    if (object.patientId != null)
                        message.patientId = String(object.patientId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FetchPatientTaskInfoFromEhrRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {infinitusai.be.FetchPatientTaskInfoFromEhrRequest} message FetchPatientTaskInfoFromEhrRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FetchPatientTaskInfoFromEhrRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.patientId = "";
                    if (message.patientId != null && message.hasOwnProperty("patientId"))
                        object.patientId = message.patientId;
                    return object;
                };
    
                /**
                 * Converts this FetchPatientTaskInfoFromEhrRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FetchPatientTaskInfoFromEhrRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FetchPatientTaskInfoFromEhrRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FetchPatientTaskInfoFromEhrRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FetchPatientTaskInfoFromEhrRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FetchPatientTaskInfoFromEhrRequest";
                };
    
                return FetchPatientTaskInfoFromEhrRequest;
            })();
    
            be.AbeTranscriptLog = (function() {
    
                /**
                 * Properties of an AbeTranscriptLog.
                 * @memberof infinitusai.be
                 * @interface IAbeTranscriptLog
                 * @property {string|null} [speaker] AbeTranscriptLog speaker
                 * @property {string|null} [utterance] AbeTranscriptLog utterance
                 * @property {string|null} [actionName] AbeTranscriptLog actionName
                 * @property {string|null} [operatorInput] AbeTranscriptLog operatorInput
                 */
    
                /**
                 * Constructs a new AbeTranscriptLog.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AbeTranscriptLog.
                 * @implements IAbeTranscriptLog
                 * @constructor
                 * @param {infinitusai.be.IAbeTranscriptLog=} [properties] Properties to set
                 */
                function AbeTranscriptLog(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AbeTranscriptLog speaker.
                 * @member {string} speaker
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @instance
                 */
                AbeTranscriptLog.prototype.speaker = "";
    
                /**
                 * AbeTranscriptLog utterance.
                 * @member {string} utterance
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @instance
                 */
                AbeTranscriptLog.prototype.utterance = "";
    
                /**
                 * AbeTranscriptLog actionName.
                 * @member {string} actionName
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @instance
                 */
                AbeTranscriptLog.prototype.actionName = "";
    
                /**
                 * AbeTranscriptLog operatorInput.
                 * @member {string} operatorInput
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @instance
                 */
                AbeTranscriptLog.prototype.operatorInput = "";
    
                /**
                 * Creates a new AbeTranscriptLog instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {infinitusai.be.IAbeTranscriptLog=} [properties] Properties to set
                 * @returns {infinitusai.be.AbeTranscriptLog} AbeTranscriptLog instance
                 */
                AbeTranscriptLog.create = function create(properties) {
                    return new AbeTranscriptLog(properties);
                };
    
                /**
                 * Encodes the specified AbeTranscriptLog message. Does not implicitly {@link infinitusai.be.AbeTranscriptLog.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {infinitusai.be.IAbeTranscriptLog} message AbeTranscriptLog message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbeTranscriptLog.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.speaker != null && Object.hasOwnProperty.call(message, "speaker"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.speaker);
                    if (message.utterance != null && Object.hasOwnProperty.call(message, "utterance"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.utterance);
                    if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.actionName);
                    if (message.operatorInput != null && Object.hasOwnProperty.call(message, "operatorInput"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.operatorInput);
                    return writer;
                };
    
                /**
                 * Encodes the specified AbeTranscriptLog message, length delimited. Does not implicitly {@link infinitusai.be.AbeTranscriptLog.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {infinitusai.be.IAbeTranscriptLog} message AbeTranscriptLog message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbeTranscriptLog.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AbeTranscriptLog message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AbeTranscriptLog} AbeTranscriptLog
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbeTranscriptLog.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AbeTranscriptLog();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.speaker = reader.string();
                                break;
                            }
                        case 2: {
                                message.utterance = reader.string();
                                break;
                            }
                        case 3: {
                                message.actionName = reader.string();
                                break;
                            }
                        case 4: {
                                message.operatorInput = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AbeTranscriptLog message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AbeTranscriptLog} AbeTranscriptLog
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbeTranscriptLog.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AbeTranscriptLog message.
                 * @function verify
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbeTranscriptLog.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.speaker != null && message.hasOwnProperty("speaker"))
                        if (!$util.isString(message.speaker))
                            return "speaker: string expected";
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        if (!$util.isString(message.utterance))
                            return "utterance: string expected";
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        if (!$util.isString(message.actionName))
                            return "actionName: string expected";
                    if (message.operatorInput != null && message.hasOwnProperty("operatorInput"))
                        if (!$util.isString(message.operatorInput))
                            return "operatorInput: string expected";
                    return null;
                };
    
                /**
                 * Creates an AbeTranscriptLog message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AbeTranscriptLog} AbeTranscriptLog
                 */
                AbeTranscriptLog.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AbeTranscriptLog)
                        return object;
                    var message = new $root.infinitusai.be.AbeTranscriptLog();
                    if (object.speaker != null)
                        message.speaker = String(object.speaker);
                    if (object.utterance != null)
                        message.utterance = String(object.utterance);
                    if (object.actionName != null)
                        message.actionName = String(object.actionName);
                    if (object.operatorInput != null)
                        message.operatorInput = String(object.operatorInput);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AbeTranscriptLog message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {infinitusai.be.AbeTranscriptLog} message AbeTranscriptLog
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbeTranscriptLog.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.speaker = "";
                        object.utterance = "";
                        object.actionName = "";
                        object.operatorInput = "";
                    }
                    if (message.speaker != null && message.hasOwnProperty("speaker"))
                        object.speaker = message.speaker;
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        object.utterance = message.utterance;
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        object.actionName = message.actionName;
                    if (message.operatorInput != null && message.hasOwnProperty("operatorInput"))
                        object.operatorInput = message.operatorInput;
                    return object;
                };
    
                /**
                 * Converts this AbeTranscriptLog to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbeTranscriptLog.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AbeTranscriptLog
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AbeTranscriptLog
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbeTranscriptLog.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AbeTranscriptLog";
                };
    
                return AbeTranscriptLog;
            })();
    
            be.CreateCallFromTaskRequest = (function() {
    
                /**
                 * Properties of a CreateCallFromTaskRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateCallFromTaskRequest
                 * @property {string|null} [taskUuid] CreateCallFromTaskRequest taskUuid
                 * @property {boolean|null} [autoRespondIvr] CreateCallFromTaskRequest autoRespondIvr
                 * @property {boolean|null} [startHeadless] CreateCallFromTaskRequest startHeadless
                 * @property {boolean|null} [useRecordedGreeting] CreateCallFromTaskRequest useRecordedGreeting
                 * @property {boolean|null} [immediatelyRouteOperator] CreateCallFromTaskRequest immediatelyRouteOperator
                 * @property {boolean|null} [forFastTrack] CreateCallFromTaskRequest forFastTrack
                 * @property {boolean|null} [useElevenLabs] CreateCallFromTaskRequest useElevenLabs
                 * @property {infinitusai.be.ITTSSetting|null} [ttsSettings] CreateCallFromTaskRequest ttsSettings
                 * @property {infinitusai.be.IHumanIntroConfig|null} [humanIntroConfig] CreateCallFromTaskRequest humanIntroConfig
                 */
    
                /**
                 * Constructs a new CreateCallFromTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateCallFromTaskRequest.
                 * @implements ICreateCallFromTaskRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateCallFromTaskRequest=} [properties] Properties to set
                 */
                function CreateCallFromTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateCallFromTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.taskUuid = "";
    
                /**
                 * CreateCallFromTaskRequest autoRespondIvr.
                 * @member {boolean} autoRespondIvr
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.autoRespondIvr = false;
    
                /**
                 * CreateCallFromTaskRequest startHeadless.
                 * @member {boolean} startHeadless
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.startHeadless = false;
    
                /**
                 * CreateCallFromTaskRequest useRecordedGreeting.
                 * @member {boolean} useRecordedGreeting
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.useRecordedGreeting = false;
    
                /**
                 * CreateCallFromTaskRequest immediatelyRouteOperator.
                 * @member {boolean} immediatelyRouteOperator
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.immediatelyRouteOperator = false;
    
                /**
                 * CreateCallFromTaskRequest forFastTrack.
                 * @member {boolean} forFastTrack
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.forFastTrack = false;
    
                /**
                 * CreateCallFromTaskRequest useElevenLabs.
                 * @member {boolean} useElevenLabs
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.useElevenLabs = false;
    
                /**
                 * CreateCallFromTaskRequest ttsSettings.
                 * @member {infinitusai.be.ITTSSetting|null|undefined} ttsSettings
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.ttsSettings = null;
    
                /**
                 * CreateCallFromTaskRequest humanIntroConfig.
                 * @member {infinitusai.be.IHumanIntroConfig|null|undefined} humanIntroConfig
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 */
                CreateCallFromTaskRequest.prototype.humanIntroConfig = null;
    
                /**
                 * Creates a new CreateCallFromTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateCallFromTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateCallFromTaskRequest} CreateCallFromTaskRequest instance
                 */
                CreateCallFromTaskRequest.create = function create(properties) {
                    return new CreateCallFromTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateCallFromTaskRequest message. Does not implicitly {@link infinitusai.be.CreateCallFromTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateCallFromTaskRequest} message CreateCallFromTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCallFromTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.autoRespondIvr != null && Object.hasOwnProperty.call(message, "autoRespondIvr"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.autoRespondIvr);
                    if (message.startHeadless != null && Object.hasOwnProperty.call(message, "startHeadless"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.startHeadless);
                    if (message.useRecordedGreeting != null && Object.hasOwnProperty.call(message, "useRecordedGreeting"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.useRecordedGreeting);
                    if (message.immediatelyRouteOperator != null && Object.hasOwnProperty.call(message, "immediatelyRouteOperator"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.immediatelyRouteOperator);
                    if (message.forFastTrack != null && Object.hasOwnProperty.call(message, "forFastTrack"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.forFastTrack);
                    if (message.useElevenLabs != null && Object.hasOwnProperty.call(message, "useElevenLabs"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.useElevenLabs);
                    if (message.ttsSettings != null && Object.hasOwnProperty.call(message, "ttsSettings"))
                        $root.infinitusai.be.TTSSetting.encode(message.ttsSettings, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.humanIntroConfig != null && Object.hasOwnProperty.call(message, "humanIntroConfig"))
                        $root.infinitusai.be.HumanIntroConfig.encode(message.humanIntroConfig, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateCallFromTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateCallFromTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {infinitusai.be.ICreateCallFromTaskRequest} message CreateCallFromTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCallFromTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateCallFromTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateCallFromTaskRequest} CreateCallFromTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCallFromTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateCallFromTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.autoRespondIvr = reader.bool();
                                break;
                            }
                        case 3: {
                                message.startHeadless = reader.bool();
                                break;
                            }
                        case 5: {
                                message.useRecordedGreeting = reader.bool();
                                break;
                            }
                        case 6: {
                                message.immediatelyRouteOperator = reader.bool();
                                break;
                            }
                        case 7: {
                                message.forFastTrack = reader.bool();
                                break;
                            }
                        case 8: {
                                message.useElevenLabs = reader.bool();
                                break;
                            }
                        case 9: {
                                message.ttsSettings = $root.infinitusai.be.TTSSetting.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.humanIntroConfig = $root.infinitusai.be.HumanIntroConfig.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateCallFromTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateCallFromTaskRequest} CreateCallFromTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCallFromTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateCallFromTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCallFromTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.autoRespondIvr != null && message.hasOwnProperty("autoRespondIvr"))
                        if (typeof message.autoRespondIvr !== "boolean")
                            return "autoRespondIvr: boolean expected";
                    if (message.startHeadless != null && message.hasOwnProperty("startHeadless"))
                        if (typeof message.startHeadless !== "boolean")
                            return "startHeadless: boolean expected";
                    if (message.useRecordedGreeting != null && message.hasOwnProperty("useRecordedGreeting"))
                        if (typeof message.useRecordedGreeting !== "boolean")
                            return "useRecordedGreeting: boolean expected";
                    if (message.immediatelyRouteOperator != null && message.hasOwnProperty("immediatelyRouteOperator"))
                        if (typeof message.immediatelyRouteOperator !== "boolean")
                            return "immediatelyRouteOperator: boolean expected";
                    if (message.forFastTrack != null && message.hasOwnProperty("forFastTrack"))
                        if (typeof message.forFastTrack !== "boolean")
                            return "forFastTrack: boolean expected";
                    if (message.useElevenLabs != null && message.hasOwnProperty("useElevenLabs"))
                        if (typeof message.useElevenLabs !== "boolean")
                            return "useElevenLabs: boolean expected";
                    if (message.ttsSettings != null && message.hasOwnProperty("ttsSettings")) {
                        var error = $root.infinitusai.be.TTSSetting.verify(message.ttsSettings);
                        if (error)
                            return "ttsSettings." + error;
                    }
                    if (message.humanIntroConfig != null && message.hasOwnProperty("humanIntroConfig")) {
                        var error = $root.infinitusai.be.HumanIntroConfig.verify(message.humanIntroConfig);
                        if (error)
                            return "humanIntroConfig." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateCallFromTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateCallFromTaskRequest} CreateCallFromTaskRequest
                 */
                CreateCallFromTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateCallFromTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateCallFromTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.autoRespondIvr != null)
                        message.autoRespondIvr = Boolean(object.autoRespondIvr);
                    if (object.startHeadless != null)
                        message.startHeadless = Boolean(object.startHeadless);
                    if (object.useRecordedGreeting != null)
                        message.useRecordedGreeting = Boolean(object.useRecordedGreeting);
                    if (object.immediatelyRouteOperator != null)
                        message.immediatelyRouteOperator = Boolean(object.immediatelyRouteOperator);
                    if (object.forFastTrack != null)
                        message.forFastTrack = Boolean(object.forFastTrack);
                    if (object.useElevenLabs != null)
                        message.useElevenLabs = Boolean(object.useElevenLabs);
                    if (object.ttsSettings != null) {
                        if (typeof object.ttsSettings !== "object")
                            throw TypeError(".infinitusai.be.CreateCallFromTaskRequest.ttsSettings: object expected");
                        message.ttsSettings = $root.infinitusai.be.TTSSetting.fromObject(object.ttsSettings);
                    }
                    if (object.humanIntroConfig != null) {
                        if (typeof object.humanIntroConfig !== "object")
                            throw TypeError(".infinitusai.be.CreateCallFromTaskRequest.humanIntroConfig: object expected");
                        message.humanIntroConfig = $root.infinitusai.be.HumanIntroConfig.fromObject(object.humanIntroConfig);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateCallFromTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {infinitusai.be.CreateCallFromTaskRequest} message CreateCallFromTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCallFromTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.autoRespondIvr = false;
                        object.startHeadless = false;
                        object.useRecordedGreeting = false;
                        object.immediatelyRouteOperator = false;
                        object.forFastTrack = false;
                        object.useElevenLabs = false;
                        object.ttsSettings = null;
                        object.humanIntroConfig = null;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.autoRespondIvr != null && message.hasOwnProperty("autoRespondIvr"))
                        object.autoRespondIvr = message.autoRespondIvr;
                    if (message.startHeadless != null && message.hasOwnProperty("startHeadless"))
                        object.startHeadless = message.startHeadless;
                    if (message.useRecordedGreeting != null && message.hasOwnProperty("useRecordedGreeting"))
                        object.useRecordedGreeting = message.useRecordedGreeting;
                    if (message.immediatelyRouteOperator != null && message.hasOwnProperty("immediatelyRouteOperator"))
                        object.immediatelyRouteOperator = message.immediatelyRouteOperator;
                    if (message.forFastTrack != null && message.hasOwnProperty("forFastTrack"))
                        object.forFastTrack = message.forFastTrack;
                    if (message.useElevenLabs != null && message.hasOwnProperty("useElevenLabs"))
                        object.useElevenLabs = message.useElevenLabs;
                    if (message.ttsSettings != null && message.hasOwnProperty("ttsSettings"))
                        object.ttsSettings = $root.infinitusai.be.TTSSetting.toObject(message.ttsSettings, options);
                    if (message.humanIntroConfig != null && message.hasOwnProperty("humanIntroConfig"))
                        object.humanIntroConfig = $root.infinitusai.be.HumanIntroConfig.toObject(message.humanIntroConfig, options);
                    return object;
                };
    
                /**
                 * Converts this CreateCallFromTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCallFromTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateCallFromTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateCallFromTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateCallFromTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateCallFromTaskRequest";
                };
    
                return CreateCallFromTaskRequest;
            })();
    
            be.CreateCallFromTaskResponse = (function() {
    
                /**
                 * Properties of a CreateCallFromTaskResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateCallFromTaskResponse
                 * @property {string|null} [callUuid] CreateCallFromTaskResponse callUuid
                 */
    
                /**
                 * Constructs a new CreateCallFromTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateCallFromTaskResponse.
                 * @implements ICreateCallFromTaskResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateCallFromTaskResponse=} [properties] Properties to set
                 */
                function CreateCallFromTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateCallFromTaskResponse callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @instance
                 */
                CreateCallFromTaskResponse.prototype.callUuid = "";
    
                /**
                 * Creates a new CreateCallFromTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateCallFromTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateCallFromTaskResponse} CreateCallFromTaskResponse instance
                 */
                CreateCallFromTaskResponse.create = function create(properties) {
                    return new CreateCallFromTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateCallFromTaskResponse message. Does not implicitly {@link infinitusai.be.CreateCallFromTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateCallFromTaskResponse} message CreateCallFromTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCallFromTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateCallFromTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateCallFromTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {infinitusai.be.ICreateCallFromTaskResponse} message CreateCallFromTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCallFromTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateCallFromTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateCallFromTaskResponse} CreateCallFromTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCallFromTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateCallFromTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateCallFromTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateCallFromTaskResponse} CreateCallFromTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCallFromTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateCallFromTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCallFromTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateCallFromTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateCallFromTaskResponse} CreateCallFromTaskResponse
                 */
                CreateCallFromTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateCallFromTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.CreateCallFromTaskResponse();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateCallFromTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {infinitusai.be.CreateCallFromTaskResponse} message CreateCallFromTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCallFromTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.callUuid = "";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this CreateCallFromTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCallFromTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateCallFromTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateCallFromTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateCallFromTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateCallFromTaskResponse";
                };
    
                return CreateCallFromTaskResponse;
            })();
    
            be.DialMeRequest = (function() {
    
                /**
                 * Properties of a DialMeRequest.
                 * @memberof infinitusai.be
                 * @interface IDialMeRequest
                 * @property {string|null} [taskUuid] DialMeRequest taskUuid
                 * @property {string|null} [callUuid] DialMeRequest callUuid
                 */
    
                /**
                 * Constructs a new DialMeRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DialMeRequest.
                 * @implements IDialMeRequest
                 * @constructor
                 * @param {infinitusai.be.IDialMeRequest=} [properties] Properties to set
                 */
                function DialMeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DialMeRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.DialMeRequest
                 * @instance
                 */
                DialMeRequest.prototype.taskUuid = "";
    
                /**
                 * DialMeRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.DialMeRequest
                 * @instance
                 */
                DialMeRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new DialMeRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {infinitusai.be.IDialMeRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DialMeRequest} DialMeRequest instance
                 */
                DialMeRequest.create = function create(properties) {
                    return new DialMeRequest(properties);
                };
    
                /**
                 * Encodes the specified DialMeRequest message. Does not implicitly {@link infinitusai.be.DialMeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {infinitusai.be.IDialMeRequest} message DialMeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DialMeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DialMeRequest message, length delimited. Does not implicitly {@link infinitusai.be.DialMeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {infinitusai.be.IDialMeRequest} message DialMeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DialMeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DialMeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DialMeRequest} DialMeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DialMeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DialMeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DialMeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DialMeRequest} DialMeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DialMeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DialMeRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DialMeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DialMeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DialMeRequest} DialMeRequest
                 */
                DialMeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DialMeRequest)
                        return object;
                    var message = new $root.infinitusai.be.DialMeRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DialMeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {infinitusai.be.DialMeRequest} message DialMeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DialMeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this DialMeRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DialMeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DialMeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DialMeRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DialMeRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DialMeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DialMeRequest";
                };
    
                return DialMeRequest;
            })();
    
            be.DialMeResponse = (function() {
    
                /**
                 * Properties of a DialMeResponse.
                 * @memberof infinitusai.be
                 * @interface IDialMeResponse
                 */
    
                /**
                 * Constructs a new DialMeResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DialMeResponse.
                 * @implements IDialMeResponse
                 * @constructor
                 * @param {infinitusai.be.IDialMeResponse=} [properties] Properties to set
                 */
                function DialMeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DialMeResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {infinitusai.be.IDialMeResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DialMeResponse} DialMeResponse instance
                 */
                DialMeResponse.create = function create(properties) {
                    return new DialMeResponse(properties);
                };
    
                /**
                 * Encodes the specified DialMeResponse message. Does not implicitly {@link infinitusai.be.DialMeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {infinitusai.be.IDialMeResponse} message DialMeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DialMeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DialMeResponse message, length delimited. Does not implicitly {@link infinitusai.be.DialMeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {infinitusai.be.IDialMeResponse} message DialMeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DialMeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DialMeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DialMeResponse} DialMeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DialMeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DialMeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DialMeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DialMeResponse} DialMeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DialMeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DialMeResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DialMeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DialMeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DialMeResponse} DialMeResponse
                 */
                DialMeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DialMeResponse)
                        return object;
                    return new $root.infinitusai.be.DialMeResponse();
                };
    
                /**
                 * Creates a plain object from a DialMeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {infinitusai.be.DialMeResponse} message DialMeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DialMeResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DialMeResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DialMeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DialMeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DialMeResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DialMeResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DialMeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DialMeResponse";
                };
    
                return DialMeResponse;
            })();
    
            be.ReportHangupRequest = (function() {
    
                /**
                 * Properties of a ReportHangupRequest.
                 * @memberof infinitusai.be
                 * @interface IReportHangupRequest
                 * @property {string|null} [taskUuid] ReportHangupRequest taskUuid
                 * @property {string|null} [callUuid] ReportHangupRequest callUuid
                 */
    
                /**
                 * Constructs a new ReportHangupRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReportHangupRequest.
                 * @implements IReportHangupRequest
                 * @constructor
                 * @param {infinitusai.be.IReportHangupRequest=} [properties] Properties to set
                 */
                function ReportHangupRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ReportHangupRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @instance
                 */
                ReportHangupRequest.prototype.taskUuid = "";
    
                /**
                 * ReportHangupRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @instance
                 */
                ReportHangupRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new ReportHangupRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {infinitusai.be.IReportHangupRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ReportHangupRequest} ReportHangupRequest instance
                 */
                ReportHangupRequest.create = function create(properties) {
                    return new ReportHangupRequest(properties);
                };
    
                /**
                 * Encodes the specified ReportHangupRequest message. Does not implicitly {@link infinitusai.be.ReportHangupRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {infinitusai.be.IReportHangupRequest} message ReportHangupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportHangupRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified ReportHangupRequest message, length delimited. Does not implicitly {@link infinitusai.be.ReportHangupRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {infinitusai.be.IReportHangupRequest} message ReportHangupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportHangupRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReportHangupRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReportHangupRequest} ReportHangupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportHangupRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReportHangupRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReportHangupRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReportHangupRequest} ReportHangupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportHangupRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReportHangupRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportHangupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a ReportHangupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReportHangupRequest} ReportHangupRequest
                 */
                ReportHangupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReportHangupRequest)
                        return object;
                    var message = new $root.infinitusai.be.ReportHangupRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ReportHangupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {infinitusai.be.ReportHangupRequest} message ReportHangupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportHangupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this ReportHangupRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportHangupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReportHangupRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReportHangupRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReportHangupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReportHangupRequest";
                };
    
                return ReportHangupRequest;
            })();
    
            be.ReportHangupResponse = (function() {
    
                /**
                 * Properties of a ReportHangupResponse.
                 * @memberof infinitusai.be
                 * @interface IReportHangupResponse
                 */
    
                /**
                 * Constructs a new ReportHangupResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReportHangupResponse.
                 * @implements IReportHangupResponse
                 * @constructor
                 * @param {infinitusai.be.IReportHangupResponse=} [properties] Properties to set
                 */
                function ReportHangupResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ReportHangupResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {infinitusai.be.IReportHangupResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ReportHangupResponse} ReportHangupResponse instance
                 */
                ReportHangupResponse.create = function create(properties) {
                    return new ReportHangupResponse(properties);
                };
    
                /**
                 * Encodes the specified ReportHangupResponse message. Does not implicitly {@link infinitusai.be.ReportHangupResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {infinitusai.be.IReportHangupResponse} message ReportHangupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportHangupResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ReportHangupResponse message, length delimited. Does not implicitly {@link infinitusai.be.ReportHangupResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {infinitusai.be.IReportHangupResponse} message ReportHangupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportHangupResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReportHangupResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReportHangupResponse} ReportHangupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportHangupResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReportHangupResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReportHangupResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReportHangupResponse} ReportHangupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportHangupResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReportHangupResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportHangupResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ReportHangupResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReportHangupResponse} ReportHangupResponse
                 */
                ReportHangupResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReportHangupResponse)
                        return object;
                    return new $root.infinitusai.be.ReportHangupResponse();
                };
    
                /**
                 * Creates a plain object from a ReportHangupResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {infinitusai.be.ReportHangupResponse} message ReportHangupResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportHangupResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ReportHangupResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportHangupResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReportHangupResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReportHangupResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReportHangupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReportHangupResponse";
                };
    
                return ReportHangupResponse;
            })();
    
            be.MarkReadyForReviewRequest = (function() {
    
                /**
                 * Properties of a MarkReadyForReviewRequest.
                 * @memberof infinitusai.be
                 * @interface IMarkReadyForReviewRequest
                 * @property {string|null} [taskUuid] MarkReadyForReviewRequest taskUuid
                 * @property {string|null} [callUuid] MarkReadyForReviewRequest callUuid
                 * @property {Array.<string>|null} [additionalRecordingUuids] MarkReadyForReviewRequest additionalRecordingUuids
                 * @property {boolean|null} [selectiveReviewCallCriteriaMet] MarkReadyForReviewRequest selectiveReviewCallCriteriaMet
                 */
    
                /**
                 * Constructs a new MarkReadyForReviewRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MarkReadyForReviewRequest.
                 * @implements IMarkReadyForReviewRequest
                 * @constructor
                 * @param {infinitusai.be.IMarkReadyForReviewRequest=} [properties] Properties to set
                 */
                function MarkReadyForReviewRequest(properties) {
                    this.additionalRecordingUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MarkReadyForReviewRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @instance
                 */
                MarkReadyForReviewRequest.prototype.taskUuid = "";
    
                /**
                 * MarkReadyForReviewRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @instance
                 */
                MarkReadyForReviewRequest.prototype.callUuid = "";
    
                /**
                 * MarkReadyForReviewRequest additionalRecordingUuids.
                 * @member {Array.<string>} additionalRecordingUuids
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @instance
                 */
                MarkReadyForReviewRequest.prototype.additionalRecordingUuids = $util.emptyArray;
    
                /**
                 * MarkReadyForReviewRequest selectiveReviewCallCriteriaMet.
                 * @member {boolean} selectiveReviewCallCriteriaMet
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @instance
                 */
                MarkReadyForReviewRequest.prototype.selectiveReviewCallCriteriaMet = false;
    
                /**
                 * Creates a new MarkReadyForReviewRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {infinitusai.be.IMarkReadyForReviewRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.MarkReadyForReviewRequest} MarkReadyForReviewRequest instance
                 */
                MarkReadyForReviewRequest.create = function create(properties) {
                    return new MarkReadyForReviewRequest(properties);
                };
    
                /**
                 * Encodes the specified MarkReadyForReviewRequest message. Does not implicitly {@link infinitusai.be.MarkReadyForReviewRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {infinitusai.be.IMarkReadyForReviewRequest} message MarkReadyForReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkReadyForReviewRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.additionalRecordingUuids != null && message.additionalRecordingUuids.length)
                        for (var i = 0; i < message.additionalRecordingUuids.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.additionalRecordingUuids[i]);
                    if (message.selectiveReviewCallCriteriaMet != null && Object.hasOwnProperty.call(message, "selectiveReviewCallCriteriaMet"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.selectiveReviewCallCriteriaMet);
                    return writer;
                };
    
                /**
                 * Encodes the specified MarkReadyForReviewRequest message, length delimited. Does not implicitly {@link infinitusai.be.MarkReadyForReviewRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {infinitusai.be.IMarkReadyForReviewRequest} message MarkReadyForReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkReadyForReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MarkReadyForReviewRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MarkReadyForReviewRequest} MarkReadyForReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkReadyForReviewRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MarkReadyForReviewRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.additionalRecordingUuids && message.additionalRecordingUuids.length))
                                    message.additionalRecordingUuids = [];
                                message.additionalRecordingUuids.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.selectiveReviewCallCriteriaMet = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MarkReadyForReviewRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MarkReadyForReviewRequest} MarkReadyForReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkReadyForReviewRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MarkReadyForReviewRequest message.
                 * @function verify
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MarkReadyForReviewRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.additionalRecordingUuids != null && message.hasOwnProperty("additionalRecordingUuids")) {
                        if (!Array.isArray(message.additionalRecordingUuids))
                            return "additionalRecordingUuids: array expected";
                        for (var i = 0; i < message.additionalRecordingUuids.length; ++i)
                            if (!$util.isString(message.additionalRecordingUuids[i]))
                                return "additionalRecordingUuids: string[] expected";
                    }
                    if (message.selectiveReviewCallCriteriaMet != null && message.hasOwnProperty("selectiveReviewCallCriteriaMet"))
                        if (typeof message.selectiveReviewCallCriteriaMet !== "boolean")
                            return "selectiveReviewCallCriteriaMet: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a MarkReadyForReviewRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MarkReadyForReviewRequest} MarkReadyForReviewRequest
                 */
                MarkReadyForReviewRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MarkReadyForReviewRequest)
                        return object;
                    var message = new $root.infinitusai.be.MarkReadyForReviewRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.additionalRecordingUuids) {
                        if (!Array.isArray(object.additionalRecordingUuids))
                            throw TypeError(".infinitusai.be.MarkReadyForReviewRequest.additionalRecordingUuids: array expected");
                        message.additionalRecordingUuids = [];
                        for (var i = 0; i < object.additionalRecordingUuids.length; ++i)
                            message.additionalRecordingUuids[i] = String(object.additionalRecordingUuids[i]);
                    }
                    if (object.selectiveReviewCallCriteriaMet != null)
                        message.selectiveReviewCallCriteriaMet = Boolean(object.selectiveReviewCallCriteriaMet);
                    return message;
                };
    
                /**
                 * Creates a plain object from a MarkReadyForReviewRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {infinitusai.be.MarkReadyForReviewRequest} message MarkReadyForReviewRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MarkReadyForReviewRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.additionalRecordingUuids = [];
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.selectiveReviewCallCriteriaMet = false;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.additionalRecordingUuids && message.additionalRecordingUuids.length) {
                        object.additionalRecordingUuids = [];
                        for (var j = 0; j < message.additionalRecordingUuids.length; ++j)
                            object.additionalRecordingUuids[j] = message.additionalRecordingUuids[j];
                    }
                    if (message.selectiveReviewCallCriteriaMet != null && message.hasOwnProperty("selectiveReviewCallCriteriaMet"))
                        object.selectiveReviewCallCriteriaMet = message.selectiveReviewCallCriteriaMet;
                    return object;
                };
    
                /**
                 * Converts this MarkReadyForReviewRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MarkReadyForReviewRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MarkReadyForReviewRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MarkReadyForReviewRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MarkReadyForReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MarkReadyForReviewRequest";
                };
    
                return MarkReadyForReviewRequest;
            })();
    
            be.MarkReadyForReviewResponse = (function() {
    
                /**
                 * Properties of a MarkReadyForReviewResponse.
                 * @memberof infinitusai.be
                 * @interface IMarkReadyForReviewResponse
                 */
    
                /**
                 * Constructs a new MarkReadyForReviewResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MarkReadyForReviewResponse.
                 * @implements IMarkReadyForReviewResponse
                 * @constructor
                 * @param {infinitusai.be.IMarkReadyForReviewResponse=} [properties] Properties to set
                 */
                function MarkReadyForReviewResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new MarkReadyForReviewResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {infinitusai.be.IMarkReadyForReviewResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.MarkReadyForReviewResponse} MarkReadyForReviewResponse instance
                 */
                MarkReadyForReviewResponse.create = function create(properties) {
                    return new MarkReadyForReviewResponse(properties);
                };
    
                /**
                 * Encodes the specified MarkReadyForReviewResponse message. Does not implicitly {@link infinitusai.be.MarkReadyForReviewResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {infinitusai.be.IMarkReadyForReviewResponse} message MarkReadyForReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkReadyForReviewResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified MarkReadyForReviewResponse message, length delimited. Does not implicitly {@link infinitusai.be.MarkReadyForReviewResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {infinitusai.be.IMarkReadyForReviewResponse} message MarkReadyForReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkReadyForReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MarkReadyForReviewResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MarkReadyForReviewResponse} MarkReadyForReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkReadyForReviewResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MarkReadyForReviewResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MarkReadyForReviewResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MarkReadyForReviewResponse} MarkReadyForReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkReadyForReviewResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MarkReadyForReviewResponse message.
                 * @function verify
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MarkReadyForReviewResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a MarkReadyForReviewResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MarkReadyForReviewResponse} MarkReadyForReviewResponse
                 */
                MarkReadyForReviewResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MarkReadyForReviewResponse)
                        return object;
                    return new $root.infinitusai.be.MarkReadyForReviewResponse();
                };
    
                /**
                 * Creates a plain object from a MarkReadyForReviewResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {infinitusai.be.MarkReadyForReviewResponse} message MarkReadyForReviewResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MarkReadyForReviewResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this MarkReadyForReviewResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MarkReadyForReviewResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MarkReadyForReviewResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MarkReadyForReviewResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MarkReadyForReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MarkReadyForReviewResponse";
                };
    
                return MarkReadyForReviewResponse;
            })();
    
            be.SaveTaskNotesRequest = (function() {
    
                /**
                 * Properties of a SaveTaskNotesRequest.
                 * @memberof infinitusai.be
                 * @interface ISaveTaskNotesRequest
                 * @property {string|null} [taskUuid] SaveTaskNotesRequest taskUuid
                 * @property {string|null} [taskNotes] SaveTaskNotesRequest taskNotes
                 */
    
                /**
                 * Constructs a new SaveTaskNotesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveTaskNotesRequest.
                 * @implements ISaveTaskNotesRequest
                 * @constructor
                 * @param {infinitusai.be.ISaveTaskNotesRequest=} [properties] Properties to set
                 */
                function SaveTaskNotesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SaveTaskNotesRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @instance
                 */
                SaveTaskNotesRequest.prototype.taskUuid = "";
    
                /**
                 * SaveTaskNotesRequest taskNotes.
                 * @member {string} taskNotes
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @instance
                 */
                SaveTaskNotesRequest.prototype.taskNotes = "";
    
                /**
                 * Creates a new SaveTaskNotesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {infinitusai.be.ISaveTaskNotesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveTaskNotesRequest} SaveTaskNotesRequest instance
                 */
                SaveTaskNotesRequest.create = function create(properties) {
                    return new SaveTaskNotesRequest(properties);
                };
    
                /**
                 * Encodes the specified SaveTaskNotesRequest message. Does not implicitly {@link infinitusai.be.SaveTaskNotesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {infinitusai.be.ISaveTaskNotesRequest} message SaveTaskNotesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveTaskNotesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.taskNotes != null && Object.hasOwnProperty.call(message, "taskNotes"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskNotes);
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveTaskNotesRequest message, length delimited. Does not implicitly {@link infinitusai.be.SaveTaskNotesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {infinitusai.be.ISaveTaskNotesRequest} message SaveTaskNotesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveTaskNotesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveTaskNotesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveTaskNotesRequest} SaveTaskNotesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveTaskNotesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveTaskNotesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskNotes = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveTaskNotesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveTaskNotesRequest} SaveTaskNotesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveTaskNotesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveTaskNotesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveTaskNotesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.taskNotes != null && message.hasOwnProperty("taskNotes"))
                        if (!$util.isString(message.taskNotes))
                            return "taskNotes: string expected";
                    return null;
                };
    
                /**
                 * Creates a SaveTaskNotesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveTaskNotesRequest} SaveTaskNotesRequest
                 */
                SaveTaskNotesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveTaskNotesRequest)
                        return object;
                    var message = new $root.infinitusai.be.SaveTaskNotesRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.taskNotes != null)
                        message.taskNotes = String(object.taskNotes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SaveTaskNotesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {infinitusai.be.SaveTaskNotesRequest} message SaveTaskNotesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveTaskNotesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.taskNotes = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.taskNotes != null && message.hasOwnProperty("taskNotes"))
                        object.taskNotes = message.taskNotes;
                    return object;
                };
    
                /**
                 * Converts this SaveTaskNotesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveTaskNotesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveTaskNotesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveTaskNotesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveTaskNotesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveTaskNotesRequest";
                };
    
                return SaveTaskNotesRequest;
            })();
    
            be.SaveTaskNotesResponse = (function() {
    
                /**
                 * Properties of a SaveTaskNotesResponse.
                 * @memberof infinitusai.be
                 * @interface ISaveTaskNotesResponse
                 */
    
                /**
                 * Constructs a new SaveTaskNotesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveTaskNotesResponse.
                 * @implements ISaveTaskNotesResponse
                 * @constructor
                 * @param {infinitusai.be.ISaveTaskNotesResponse=} [properties] Properties to set
                 */
                function SaveTaskNotesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new SaveTaskNotesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {infinitusai.be.ISaveTaskNotesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveTaskNotesResponse} SaveTaskNotesResponse instance
                 */
                SaveTaskNotesResponse.create = function create(properties) {
                    return new SaveTaskNotesResponse(properties);
                };
    
                /**
                 * Encodes the specified SaveTaskNotesResponse message. Does not implicitly {@link infinitusai.be.SaveTaskNotesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {infinitusai.be.ISaveTaskNotesResponse} message SaveTaskNotesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveTaskNotesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveTaskNotesResponse message, length delimited. Does not implicitly {@link infinitusai.be.SaveTaskNotesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {infinitusai.be.ISaveTaskNotesResponse} message SaveTaskNotesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveTaskNotesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveTaskNotesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveTaskNotesResponse} SaveTaskNotesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveTaskNotesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveTaskNotesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveTaskNotesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveTaskNotesResponse} SaveTaskNotesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveTaskNotesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveTaskNotesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveTaskNotesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a SaveTaskNotesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveTaskNotesResponse} SaveTaskNotesResponse
                 */
                SaveTaskNotesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveTaskNotesResponse)
                        return object;
                    return new $root.infinitusai.be.SaveTaskNotesResponse();
                };
    
                /**
                 * Creates a plain object from a SaveTaskNotesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {infinitusai.be.SaveTaskNotesResponse} message SaveTaskNotesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveTaskNotesResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this SaveTaskNotesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveTaskNotesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveTaskNotesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveTaskNotesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveTaskNotesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveTaskNotesResponse";
                };
    
                return SaveTaskNotesResponse;
            })();
    
            be.SavePotentialAdverseEventRequest = (function() {
    
                /**
                 * Properties of a SavePotentialAdverseEventRequest.
                 * @memberof infinitusai.be
                 * @interface ISavePotentialAdverseEventRequest
                 * @property {string|null} [taskUuid] SavePotentialAdverseEventRequest taskUuid
                 * @property {string|null} [callUuid] SavePotentialAdverseEventRequest callUuid
                 * @property {infinitusai.be.INullableBool|null} [hasPotentialAdverseEvent] SavePotentialAdverseEventRequest hasPotentialAdverseEvent
                 * @property {string|null} [potentialAdverseEventNotes] SavePotentialAdverseEventRequest potentialAdverseEventNotes
                 */
    
                /**
                 * Constructs a new SavePotentialAdverseEventRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SavePotentialAdverseEventRequest.
                 * @implements ISavePotentialAdverseEventRequest
                 * @constructor
                 * @param {infinitusai.be.ISavePotentialAdverseEventRequest=} [properties] Properties to set
                 */
                function SavePotentialAdverseEventRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SavePotentialAdverseEventRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @instance
                 */
                SavePotentialAdverseEventRequest.prototype.taskUuid = "";
    
                /**
                 * SavePotentialAdverseEventRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @instance
                 */
                SavePotentialAdverseEventRequest.prototype.callUuid = "";
    
                /**
                 * SavePotentialAdverseEventRequest hasPotentialAdverseEvent.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasPotentialAdverseEvent
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @instance
                 */
                SavePotentialAdverseEventRequest.prototype.hasPotentialAdverseEvent = null;
    
                /**
                 * SavePotentialAdverseEventRequest potentialAdverseEventNotes.
                 * @member {string} potentialAdverseEventNotes
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @instance
                 */
                SavePotentialAdverseEventRequest.prototype.potentialAdverseEventNotes = "";
    
                /**
                 * Creates a new SavePotentialAdverseEventRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {infinitusai.be.ISavePotentialAdverseEventRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SavePotentialAdverseEventRequest} SavePotentialAdverseEventRequest instance
                 */
                SavePotentialAdverseEventRequest.create = function create(properties) {
                    return new SavePotentialAdverseEventRequest(properties);
                };
    
                /**
                 * Encodes the specified SavePotentialAdverseEventRequest message. Does not implicitly {@link infinitusai.be.SavePotentialAdverseEventRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {infinitusai.be.ISavePotentialAdverseEventRequest} message SavePotentialAdverseEventRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SavePotentialAdverseEventRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.hasPotentialAdverseEvent != null && Object.hasOwnProperty.call(message, "hasPotentialAdverseEvent"))
                        $root.infinitusai.be.NullableBool.encode(message.hasPotentialAdverseEvent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.potentialAdverseEventNotes != null && Object.hasOwnProperty.call(message, "potentialAdverseEventNotes"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.potentialAdverseEventNotes);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified SavePotentialAdverseEventRequest message, length delimited. Does not implicitly {@link infinitusai.be.SavePotentialAdverseEventRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {infinitusai.be.ISavePotentialAdverseEventRequest} message SavePotentialAdverseEventRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SavePotentialAdverseEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SavePotentialAdverseEventRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SavePotentialAdverseEventRequest} SavePotentialAdverseEventRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SavePotentialAdverseEventRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SavePotentialAdverseEventRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.potentialAdverseEventNotes = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SavePotentialAdverseEventRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SavePotentialAdverseEventRequest} SavePotentialAdverseEventRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SavePotentialAdverseEventRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SavePotentialAdverseEventRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SavePotentialAdverseEventRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.hasPotentialAdverseEvent != null && message.hasOwnProperty("hasPotentialAdverseEvent")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasPotentialAdverseEvent);
                        if (error)
                            return "hasPotentialAdverseEvent." + error;
                    }
                    if (message.potentialAdverseEventNotes != null && message.hasOwnProperty("potentialAdverseEventNotes"))
                        if (!$util.isString(message.potentialAdverseEventNotes))
                            return "potentialAdverseEventNotes: string expected";
                    return null;
                };
    
                /**
                 * Creates a SavePotentialAdverseEventRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SavePotentialAdverseEventRequest} SavePotentialAdverseEventRequest
                 */
                SavePotentialAdverseEventRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SavePotentialAdverseEventRequest)
                        return object;
                    var message = new $root.infinitusai.be.SavePotentialAdverseEventRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.hasPotentialAdverseEvent != null) {
                        if (typeof object.hasPotentialAdverseEvent !== "object")
                            throw TypeError(".infinitusai.be.SavePotentialAdverseEventRequest.hasPotentialAdverseEvent: object expected");
                        message.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.fromObject(object.hasPotentialAdverseEvent);
                    }
                    if (object.potentialAdverseEventNotes != null)
                        message.potentialAdverseEventNotes = String(object.potentialAdverseEventNotes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SavePotentialAdverseEventRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {infinitusai.be.SavePotentialAdverseEventRequest} message SavePotentialAdverseEventRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SavePotentialAdverseEventRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.hasPotentialAdverseEvent = null;
                        object.potentialAdverseEventNotes = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.hasPotentialAdverseEvent != null && message.hasOwnProperty("hasPotentialAdverseEvent"))
                        object.hasPotentialAdverseEvent = $root.infinitusai.be.NullableBool.toObject(message.hasPotentialAdverseEvent, options);
                    if (message.potentialAdverseEventNotes != null && message.hasOwnProperty("potentialAdverseEventNotes"))
                        object.potentialAdverseEventNotes = message.potentialAdverseEventNotes;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this SavePotentialAdverseEventRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SavePotentialAdverseEventRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SavePotentialAdverseEventRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SavePotentialAdverseEventRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SavePotentialAdverseEventRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SavePotentialAdverseEventRequest";
                };
    
                return SavePotentialAdverseEventRequest;
            })();
    
            be.SavePotentialAdverseEventResponse = (function() {
    
                /**
                 * Properties of a SavePotentialAdverseEventResponse.
                 * @memberof infinitusai.be
                 * @interface ISavePotentialAdverseEventResponse
                 */
    
                /**
                 * Constructs a new SavePotentialAdverseEventResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SavePotentialAdverseEventResponse.
                 * @implements ISavePotentialAdverseEventResponse
                 * @constructor
                 * @param {infinitusai.be.ISavePotentialAdverseEventResponse=} [properties] Properties to set
                 */
                function SavePotentialAdverseEventResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new SavePotentialAdverseEventResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {infinitusai.be.ISavePotentialAdverseEventResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.SavePotentialAdverseEventResponse} SavePotentialAdverseEventResponse instance
                 */
                SavePotentialAdverseEventResponse.create = function create(properties) {
                    return new SavePotentialAdverseEventResponse(properties);
                };
    
                /**
                 * Encodes the specified SavePotentialAdverseEventResponse message. Does not implicitly {@link infinitusai.be.SavePotentialAdverseEventResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {infinitusai.be.ISavePotentialAdverseEventResponse} message SavePotentialAdverseEventResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SavePotentialAdverseEventResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified SavePotentialAdverseEventResponse message, length delimited. Does not implicitly {@link infinitusai.be.SavePotentialAdverseEventResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {infinitusai.be.ISavePotentialAdverseEventResponse} message SavePotentialAdverseEventResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SavePotentialAdverseEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SavePotentialAdverseEventResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SavePotentialAdverseEventResponse} SavePotentialAdverseEventResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SavePotentialAdverseEventResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SavePotentialAdverseEventResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SavePotentialAdverseEventResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SavePotentialAdverseEventResponse} SavePotentialAdverseEventResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SavePotentialAdverseEventResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SavePotentialAdverseEventResponse message.
                 * @function verify
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SavePotentialAdverseEventResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a SavePotentialAdverseEventResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SavePotentialAdverseEventResponse} SavePotentialAdverseEventResponse
                 */
                SavePotentialAdverseEventResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SavePotentialAdverseEventResponse)
                        return object;
                    return new $root.infinitusai.be.SavePotentialAdverseEventResponse();
                };
    
                /**
                 * Creates a plain object from a SavePotentialAdverseEventResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {infinitusai.be.SavePotentialAdverseEventResponse} message SavePotentialAdverseEventResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SavePotentialAdverseEventResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this SavePotentialAdverseEventResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SavePotentialAdverseEventResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SavePotentialAdverseEventResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SavePotentialAdverseEventResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SavePotentialAdverseEventResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SavePotentialAdverseEventResponse";
                };
    
                return SavePotentialAdverseEventResponse;
            })();
    
            be.CompleteReviewRequest = (function() {
    
                /**
                 * Properties of a CompleteReviewRequest.
                 * @memberof infinitusai.be
                 * @interface ICompleteReviewRequest
                 * @property {string|null} [taskUuid] CompleteReviewRequest taskUuid
                 * @property {Array.<string>|null} [stitchAdditionalCallUuids] CompleteReviewRequest stitchAdditionalCallUuids
                 * @property {string|null} [orgUuid] CompleteReviewRequest orgUuid
                 * @property {string|null} [userEmail] CompleteReviewRequest userEmail
                 */
    
                /**
                 * Constructs a new CompleteReviewRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CompleteReviewRequest.
                 * @implements ICompleteReviewRequest
                 * @constructor
                 * @param {infinitusai.be.ICompleteReviewRequest=} [properties] Properties to set
                 */
                function CompleteReviewRequest(properties) {
                    this.stitchAdditionalCallUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CompleteReviewRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @instance
                 */
                CompleteReviewRequest.prototype.taskUuid = "";
    
                /**
                 * CompleteReviewRequest stitchAdditionalCallUuids.
                 * @member {Array.<string>} stitchAdditionalCallUuids
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @instance
                 */
                CompleteReviewRequest.prototype.stitchAdditionalCallUuids = $util.emptyArray;
    
                /**
                 * CompleteReviewRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @instance
                 */
                CompleteReviewRequest.prototype.orgUuid = "";
    
                /**
                 * CompleteReviewRequest userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @instance
                 */
                CompleteReviewRequest.prototype.userEmail = "";
    
                /**
                 * Creates a new CompleteReviewRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {infinitusai.be.ICompleteReviewRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CompleteReviewRequest} CompleteReviewRequest instance
                 */
                CompleteReviewRequest.create = function create(properties) {
                    return new CompleteReviewRequest(properties);
                };
    
                /**
                 * Encodes the specified CompleteReviewRequest message. Does not implicitly {@link infinitusai.be.CompleteReviewRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {infinitusai.be.ICompleteReviewRequest} message CompleteReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.stitchAdditionalCallUuids != null && message.stitchAdditionalCallUuids.length)
                        for (var i = 0; i < message.stitchAdditionalCallUuids.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.stitchAdditionalCallUuids[i]);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified CompleteReviewRequest message, length delimited. Does not implicitly {@link infinitusai.be.CompleteReviewRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {infinitusai.be.ICompleteReviewRequest} message CompleteReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CompleteReviewRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CompleteReviewRequest} CompleteReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CompleteReviewRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.stitchAdditionalCallUuids && message.stitchAdditionalCallUuids.length))
                                    message.stitchAdditionalCallUuids = [];
                                message.stitchAdditionalCallUuids.push(reader.string());
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.userEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CompleteReviewRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CompleteReviewRequest} CompleteReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CompleteReviewRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteReviewRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.stitchAdditionalCallUuids != null && message.hasOwnProperty("stitchAdditionalCallUuids")) {
                        if (!Array.isArray(message.stitchAdditionalCallUuids))
                            return "stitchAdditionalCallUuids: array expected";
                        for (var i = 0; i < message.stitchAdditionalCallUuids.length; ++i)
                            if (!$util.isString(message.stitchAdditionalCallUuids[i]))
                                return "stitchAdditionalCallUuids: string[] expected";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a CompleteReviewRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CompleteReviewRequest} CompleteReviewRequest
                 */
                CompleteReviewRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CompleteReviewRequest)
                        return object;
                    var message = new $root.infinitusai.be.CompleteReviewRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.stitchAdditionalCallUuids) {
                        if (!Array.isArray(object.stitchAdditionalCallUuids))
                            throw TypeError(".infinitusai.be.CompleteReviewRequest.stitchAdditionalCallUuids: array expected");
                        message.stitchAdditionalCallUuids = [];
                        for (var i = 0; i < object.stitchAdditionalCallUuids.length; ++i)
                            message.stitchAdditionalCallUuids[i] = String(object.stitchAdditionalCallUuids[i]);
                    }
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CompleteReviewRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {infinitusai.be.CompleteReviewRequest} message CompleteReviewRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteReviewRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.stitchAdditionalCallUuids = [];
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.orgUuid = "";
                        object.userEmail = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.stitchAdditionalCallUuids && message.stitchAdditionalCallUuids.length) {
                        object.stitchAdditionalCallUuids = [];
                        for (var j = 0; j < message.stitchAdditionalCallUuids.length; ++j)
                            object.stitchAdditionalCallUuids[j] = message.stitchAdditionalCallUuids[j];
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    return object;
                };
    
                /**
                 * Converts this CompleteReviewRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteReviewRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CompleteReviewRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CompleteReviewRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CompleteReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CompleteReviewRequest";
                };
    
                return CompleteReviewRequest;
            })();
    
            be.CompleteReviewResponse = (function() {
    
                /**
                 * Properties of a CompleteReviewResponse.
                 * @memberof infinitusai.be
                 * @interface ICompleteReviewResponse
                 */
    
                /**
                 * Constructs a new CompleteReviewResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CompleteReviewResponse.
                 * @implements ICompleteReviewResponse
                 * @constructor
                 * @param {infinitusai.be.ICompleteReviewResponse=} [properties] Properties to set
                 */
                function CompleteReviewResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CompleteReviewResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {infinitusai.be.ICompleteReviewResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CompleteReviewResponse} CompleteReviewResponse instance
                 */
                CompleteReviewResponse.create = function create(properties) {
                    return new CompleteReviewResponse(properties);
                };
    
                /**
                 * Encodes the specified CompleteReviewResponse message. Does not implicitly {@link infinitusai.be.CompleteReviewResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {infinitusai.be.ICompleteReviewResponse} message CompleteReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CompleteReviewResponse message, length delimited. Does not implicitly {@link infinitusai.be.CompleteReviewResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {infinitusai.be.ICompleteReviewResponse} message CompleteReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CompleteReviewResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CompleteReviewResponse} CompleteReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CompleteReviewResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CompleteReviewResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CompleteReviewResponse} CompleteReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CompleteReviewResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteReviewResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CompleteReviewResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CompleteReviewResponse} CompleteReviewResponse
                 */
                CompleteReviewResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CompleteReviewResponse)
                        return object;
                    return new $root.infinitusai.be.CompleteReviewResponse();
                };
    
                /**
                 * Creates a plain object from a CompleteReviewResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {infinitusai.be.CompleteReviewResponse} message CompleteReviewResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteReviewResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CompleteReviewResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteReviewResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CompleteReviewResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CompleteReviewResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CompleteReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CompleteReviewResponse";
                };
    
                return CompleteReviewResponse;
            })();
    
            be.SaveTaskOutputsRequest = (function() {
    
                /**
                 * Properties of a SaveTaskOutputsRequest.
                 * @memberof infinitusai.be
                 * @interface ISaveTaskOutputsRequest
                 * @property {string|null} [taskUuid] SaveTaskOutputsRequest taskUuid
                 * @property {infinitusai.be.IMedSupBVCallOutputs|null} [medsupBvOutputs] SaveTaskOutputsRequest medsupBvOutputs
                 * @property {infinitusai.be.IFullBVCallOutputs|null} [fullBvOutputs] SaveTaskOutputsRequest fullBvOutputs
                 * @property {infinitusai.be.IPBMBVCallOutputs|null} [pbmBvOutputs] SaveTaskOutputsRequest pbmBvOutputs
                 * @property {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null} [covidVaccineAvailabilityCallOutputs] SaveTaskOutputsRequest covidVaccineAvailabilityCallOutputs
                 * @property {boolean|null} [isFinal] SaveTaskOutputsRequest isFinal
                 * @property {string|null} [taskNotes] SaveTaskOutputsRequest taskNotes
                 * @property {boolean|null} [isReadyForReview] SaveTaskOutputsRequest isReadyForReview
                 * @property {string|null} [taskNotesInternal] SaveTaskOutputsRequest taskNotesInternal
                 */
    
                /**
                 * Constructs a new SaveTaskOutputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveTaskOutputsRequest.
                 * @implements ISaveTaskOutputsRequest
                 * @constructor
                 * @param {infinitusai.be.ISaveTaskOutputsRequest=} [properties] Properties to set
                 */
                function SaveTaskOutputsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SaveTaskOutputsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.taskUuid = "";
    
                /**
                 * SaveTaskOutputsRequest medsupBvOutputs.
                 * @member {infinitusai.be.IMedSupBVCallOutputs|null|undefined} medsupBvOutputs
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.medsupBvOutputs = null;
    
                /**
                 * SaveTaskOutputsRequest fullBvOutputs.
                 * @member {infinitusai.be.IFullBVCallOutputs|null|undefined} fullBvOutputs
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.fullBvOutputs = null;
    
                /**
                 * SaveTaskOutputsRequest pbmBvOutputs.
                 * @member {infinitusai.be.IPBMBVCallOutputs|null|undefined} pbmBvOutputs
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.pbmBvOutputs = null;
    
                /**
                 * SaveTaskOutputsRequest covidVaccineAvailabilityCallOutputs.
                 * @member {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null|undefined} covidVaccineAvailabilityCallOutputs
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.covidVaccineAvailabilityCallOutputs = null;
    
                /**
                 * SaveTaskOutputsRequest isFinal.
                 * @member {boolean} isFinal
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.isFinal = false;
    
                /**
                 * SaveTaskOutputsRequest taskNotes.
                 * @member {string} taskNotes
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.taskNotes = "";
    
                /**
                 * SaveTaskOutputsRequest isReadyForReview.
                 * @member {boolean} isReadyForReview
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.isReadyForReview = false;
    
                /**
                 * SaveTaskOutputsRequest taskNotesInternal.
                 * @member {string} taskNotesInternal
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                SaveTaskOutputsRequest.prototype.taskNotesInternal = "";
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * SaveTaskOutputsRequest outputs.
                 * @member {"medsupBvOutputs"|"fullBvOutputs"|"pbmBvOutputs"|"covidVaccineAvailabilityCallOutputs"|undefined} outputs
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 */
                Object.defineProperty(SaveTaskOutputsRequest.prototype, "outputs", {
                    get: $util.oneOfGetter($oneOfFields = ["medsupBvOutputs", "fullBvOutputs", "pbmBvOutputs", "covidVaccineAvailabilityCallOutputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new SaveTaskOutputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {infinitusai.be.ISaveTaskOutputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveTaskOutputsRequest} SaveTaskOutputsRequest instance
                 */
                SaveTaskOutputsRequest.create = function create(properties) {
                    return new SaveTaskOutputsRequest(properties);
                };
    
                /**
                 * Encodes the specified SaveTaskOutputsRequest message. Does not implicitly {@link infinitusai.be.SaveTaskOutputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {infinitusai.be.ISaveTaskOutputsRequest} message SaveTaskOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveTaskOutputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.medsupBvOutputs != null && Object.hasOwnProperty.call(message, "medsupBvOutputs"))
                        $root.infinitusai.be.MedSupBVCallOutputs.encode(message.medsupBvOutputs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.isFinal != null && Object.hasOwnProperty.call(message, "isFinal"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isFinal);
                    if (message.taskNotes != null && Object.hasOwnProperty.call(message, "taskNotes"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskNotes);
                    if (message.isReadyForReview != null && Object.hasOwnProperty.call(message, "isReadyForReview"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isReadyForReview);
                    if (message.fullBvOutputs != null && Object.hasOwnProperty.call(message, "fullBvOutputs"))
                        $root.infinitusai.be.FullBVCallOutputs.encode(message.fullBvOutputs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.pbmBvOutputs != null && Object.hasOwnProperty.call(message, "pbmBvOutputs"))
                        $root.infinitusai.be.PBMBVCallOutputs.encode(message.pbmBvOutputs, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.covidVaccineAvailabilityCallOutputs != null && Object.hasOwnProperty.call(message, "covidVaccineAvailabilityCallOutputs"))
                        $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.encode(message.covidVaccineAvailabilityCallOutputs, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.taskNotesInternal != null && Object.hasOwnProperty.call(message, "taskNotesInternal"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.taskNotesInternal);
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveTaskOutputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.SaveTaskOutputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {infinitusai.be.ISaveTaskOutputsRequest} message SaveTaskOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveTaskOutputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveTaskOutputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveTaskOutputsRequest} SaveTaskOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveTaskOutputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveTaskOutputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.isFinal = reader.bool();
                                break;
                            }
                        case 4: {
                                message.taskNotes = reader.string();
                                break;
                            }
                        case 5: {
                                message.isReadyForReview = reader.bool();
                                break;
                            }
                        case 9: {
                                message.taskNotesInternal = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveTaskOutputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveTaskOutputsRequest} SaveTaskOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveTaskOutputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveTaskOutputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveTaskOutputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.MedSupBVCallOutputs.verify(message.medsupBvOutputs);
                            if (error)
                                return "medsupBvOutputs." + error;
                        }
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.FullBVCallOutputs.verify(message.fullBvOutputs);
                            if (error)
                                return "fullBvOutputs." + error;
                        }
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.PBMBVCallOutputs.verify(message.pbmBvOutputs);
                            if (error)
                                return "pbmBvOutputs." + error;
                        }
                    }
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.verify(message.covidVaccineAvailabilityCallOutputs);
                            if (error)
                                return "covidVaccineAvailabilityCallOutputs." + error;
                        }
                    }
                    if (message.isFinal != null && message.hasOwnProperty("isFinal"))
                        if (typeof message.isFinal !== "boolean")
                            return "isFinal: boolean expected";
                    if (message.taskNotes != null && message.hasOwnProperty("taskNotes"))
                        if (!$util.isString(message.taskNotes))
                            return "taskNotes: string expected";
                    if (message.isReadyForReview != null && message.hasOwnProperty("isReadyForReview"))
                        if (typeof message.isReadyForReview !== "boolean")
                            return "isReadyForReview: boolean expected";
                    if (message.taskNotesInternal != null && message.hasOwnProperty("taskNotesInternal"))
                        if (!$util.isString(message.taskNotesInternal))
                            return "taskNotesInternal: string expected";
                    return null;
                };
    
                /**
                 * Creates a SaveTaskOutputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveTaskOutputsRequest} SaveTaskOutputsRequest
                 */
                SaveTaskOutputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveTaskOutputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.SaveTaskOutputsRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.medsupBvOutputs != null) {
                        if (typeof object.medsupBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveTaskOutputsRequest.medsupBvOutputs: object expected");
                        message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.fromObject(object.medsupBvOutputs);
                    }
                    if (object.fullBvOutputs != null) {
                        if (typeof object.fullBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveTaskOutputsRequest.fullBvOutputs: object expected");
                        message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.fromObject(object.fullBvOutputs);
                    }
                    if (object.pbmBvOutputs != null) {
                        if (typeof object.pbmBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveTaskOutputsRequest.pbmBvOutputs: object expected");
                        message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.fromObject(object.pbmBvOutputs);
                    }
                    if (object.covidVaccineAvailabilityCallOutputs != null) {
                        if (typeof object.covidVaccineAvailabilityCallOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveTaskOutputsRequest.covidVaccineAvailabilityCallOutputs: object expected");
                        message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.fromObject(object.covidVaccineAvailabilityCallOutputs);
                    }
                    if (object.isFinal != null)
                        message.isFinal = Boolean(object.isFinal);
                    if (object.taskNotes != null)
                        message.taskNotes = String(object.taskNotes);
                    if (object.isReadyForReview != null)
                        message.isReadyForReview = Boolean(object.isReadyForReview);
                    if (object.taskNotesInternal != null)
                        message.taskNotesInternal = String(object.taskNotesInternal);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SaveTaskOutputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {infinitusai.be.SaveTaskOutputsRequest} message SaveTaskOutputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveTaskOutputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.isFinal = false;
                        object.taskNotes = "";
                        object.isReadyForReview = false;
                        object.taskNotesInternal = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        object.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.toObject(message.medsupBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "medsupBvOutputs";
                    }
                    if (message.isFinal != null && message.hasOwnProperty("isFinal"))
                        object.isFinal = message.isFinal;
                    if (message.taskNotes != null && message.hasOwnProperty("taskNotes"))
                        object.taskNotes = message.taskNotes;
                    if (message.isReadyForReview != null && message.hasOwnProperty("isReadyForReview"))
                        object.isReadyForReview = message.isReadyForReview;
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        object.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.toObject(message.fullBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "fullBvOutputs";
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        object.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.toObject(message.pbmBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "pbmBvOutputs";
                    }
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        object.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.toObject(message.covidVaccineAvailabilityCallOutputs, options);
                        if (options.oneofs)
                            object.outputs = "covidVaccineAvailabilityCallOutputs";
                    }
                    if (message.taskNotesInternal != null && message.hasOwnProperty("taskNotesInternal"))
                        object.taskNotesInternal = message.taskNotesInternal;
                    return object;
                };
    
                /**
                 * Converts this SaveTaskOutputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveTaskOutputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveTaskOutputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveTaskOutputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveTaskOutputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveTaskOutputsRequest";
                };
    
                return SaveTaskOutputsRequest;
            })();
    
            be.SaveTaskOutputsResponse = (function() {
    
                /**
                 * Properties of a SaveTaskOutputsResponse.
                 * @memberof infinitusai.be
                 * @interface ISaveTaskOutputsResponse
                 */
    
                /**
                 * Constructs a new SaveTaskOutputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveTaskOutputsResponse.
                 * @implements ISaveTaskOutputsResponse
                 * @constructor
                 * @param {infinitusai.be.ISaveTaskOutputsResponse=} [properties] Properties to set
                 */
                function SaveTaskOutputsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new SaveTaskOutputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {infinitusai.be.ISaveTaskOutputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveTaskOutputsResponse} SaveTaskOutputsResponse instance
                 */
                SaveTaskOutputsResponse.create = function create(properties) {
                    return new SaveTaskOutputsResponse(properties);
                };
    
                /**
                 * Encodes the specified SaveTaskOutputsResponse message. Does not implicitly {@link infinitusai.be.SaveTaskOutputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {infinitusai.be.ISaveTaskOutputsResponse} message SaveTaskOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveTaskOutputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveTaskOutputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.SaveTaskOutputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {infinitusai.be.ISaveTaskOutputsResponse} message SaveTaskOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveTaskOutputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveTaskOutputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveTaskOutputsResponse} SaveTaskOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveTaskOutputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveTaskOutputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveTaskOutputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveTaskOutputsResponse} SaveTaskOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveTaskOutputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveTaskOutputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveTaskOutputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a SaveTaskOutputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveTaskOutputsResponse} SaveTaskOutputsResponse
                 */
                SaveTaskOutputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveTaskOutputsResponse)
                        return object;
                    return new $root.infinitusai.be.SaveTaskOutputsResponse();
                };
    
                /**
                 * Creates a plain object from a SaveTaskOutputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {infinitusai.be.SaveTaskOutputsResponse} message SaveTaskOutputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveTaskOutputsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this SaveTaskOutputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveTaskOutputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveTaskOutputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveTaskOutputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveTaskOutputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveTaskOutputsResponse";
                };
    
                return SaveTaskOutputsResponse;
            })();
    
            be.FailTaskRequest = (function() {
    
                /**
                 * Properties of a FailTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IFailTaskRequest
                 * @property {string|null} [taskUuid] FailTaskRequest taskUuid
                 * @property {infinitusai.be.ITaskFailReason|null} [failReason] FailTaskRequest failReason
                 * @property {string|null} [callUuid] FailTaskRequest callUuid
                 */
    
                /**
                 * Constructs a new FailTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FailTaskRequest.
                 * @implements IFailTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IFailTaskRequest=} [properties] Properties to set
                 */
                function FailTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FailTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.FailTaskRequest
                 * @instance
                 */
                FailTaskRequest.prototype.taskUuid = "";
    
                /**
                 * FailTaskRequest failReason.
                 * @member {infinitusai.be.ITaskFailReason|null|undefined} failReason
                 * @memberof infinitusai.be.FailTaskRequest
                 * @instance
                 */
                FailTaskRequest.prototype.failReason = null;
    
                /**
                 * FailTaskRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.FailTaskRequest
                 * @instance
                 */
                FailTaskRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new FailTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {infinitusai.be.IFailTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.FailTaskRequest} FailTaskRequest instance
                 */
                FailTaskRequest.create = function create(properties) {
                    return new FailTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified FailTaskRequest message. Does not implicitly {@link infinitusai.be.FailTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {infinitusai.be.IFailTaskRequest} message FailTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FailTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.failReason != null && Object.hasOwnProperty.call(message, "failReason"))
                        $root.infinitusai.be.TaskFailReason.encode(message.failReason, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified FailTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.FailTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {infinitusai.be.IFailTaskRequest} message FailTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FailTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FailTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FailTaskRequest} FailTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FailTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FailTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.failReason = $root.infinitusai.be.TaskFailReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FailTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FailTaskRequest} FailTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FailTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FailTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FailTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.failReason != null && message.hasOwnProperty("failReason")) {
                        var error = $root.infinitusai.be.TaskFailReason.verify(message.failReason);
                        if (error)
                            return "failReason." + error;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a FailTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FailTaskRequest} FailTaskRequest
                 */
                FailTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FailTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.FailTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.failReason != null) {
                        if (typeof object.failReason !== "object")
                            throw TypeError(".infinitusai.be.FailTaskRequest.failReason: object expected");
                        message.failReason = $root.infinitusai.be.TaskFailReason.fromObject(object.failReason);
                    }
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FailTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {infinitusai.be.FailTaskRequest} message FailTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FailTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.failReason = null;
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.failReason != null && message.hasOwnProperty("failReason"))
                        object.failReason = $root.infinitusai.be.TaskFailReason.toObject(message.failReason, options);
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this FailTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FailTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FailTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FailTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FailTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FailTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FailTaskRequest";
                };
    
                return FailTaskRequest;
            })();
    
            be.FailTaskResponse = (function() {
    
                /**
                 * Properties of a FailTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IFailTaskResponse
                 */
    
                /**
                 * Constructs a new FailTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FailTaskResponse.
                 * @implements IFailTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IFailTaskResponse=} [properties] Properties to set
                 */
                function FailTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new FailTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {infinitusai.be.IFailTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.FailTaskResponse} FailTaskResponse instance
                 */
                FailTaskResponse.create = function create(properties) {
                    return new FailTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified FailTaskResponse message. Does not implicitly {@link infinitusai.be.FailTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {infinitusai.be.IFailTaskResponse} message FailTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FailTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified FailTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.FailTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {infinitusai.be.IFailTaskResponse} message FailTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FailTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FailTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FailTaskResponse} FailTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FailTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FailTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FailTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FailTaskResponse} FailTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FailTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FailTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FailTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a FailTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FailTaskResponse} FailTaskResponse
                 */
                FailTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FailTaskResponse)
                        return object;
                    return new $root.infinitusai.be.FailTaskResponse();
                };
    
                /**
                 * Creates a plain object from a FailTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {infinitusai.be.FailTaskResponse} message FailTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FailTaskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this FailTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FailTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FailTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FailTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FailTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FailTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FailTaskResponse";
                };
    
                return FailTaskResponse;
            })();
    
            be.CancelTaskRequest = (function() {
    
                /**
                 * Properties of a CancelTaskRequest.
                 * @memberof infinitusai.be
                 * @interface ICancelTaskRequest
                 * @property {string|null} [taskUuid] CancelTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new CancelTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CancelTaskRequest.
                 * @implements ICancelTaskRequest
                 * @constructor
                 * @param {infinitusai.be.ICancelTaskRequest=} [properties] Properties to set
                 */
                function CancelTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CancelTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @instance
                 */
                CancelTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new CancelTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {infinitusai.be.ICancelTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CancelTaskRequest} CancelTaskRequest instance
                 */
                CancelTaskRequest.create = function create(properties) {
                    return new CancelTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified CancelTaskRequest message. Does not implicitly {@link infinitusai.be.CancelTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {infinitusai.be.ICancelTaskRequest} message CancelTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CancelTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.CancelTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {infinitusai.be.ICancelTaskRequest} message CancelTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CancelTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CancelTaskRequest} CancelTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CancelTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CancelTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CancelTaskRequest} CancelTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CancelTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CancelTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CancelTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CancelTaskRequest} CancelTaskRequest
                 */
                CancelTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CancelTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.CancelTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CancelTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {infinitusai.be.CancelTaskRequest} message CancelTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CancelTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this CancelTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CancelTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CancelTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CancelTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CancelTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CancelTaskRequest";
                };
    
                return CancelTaskRequest;
            })();
    
            be.CancelTaskResponse = (function() {
    
                /**
                 * Properties of a CancelTaskResponse.
                 * @memberof infinitusai.be
                 * @interface ICancelTaskResponse
                 */
    
                /**
                 * Constructs a new CancelTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CancelTaskResponse.
                 * @implements ICancelTaskResponse
                 * @constructor
                 * @param {infinitusai.be.ICancelTaskResponse=} [properties] Properties to set
                 */
                function CancelTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CancelTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {infinitusai.be.ICancelTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CancelTaskResponse} CancelTaskResponse instance
                 */
                CancelTaskResponse.create = function create(properties) {
                    return new CancelTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified CancelTaskResponse message. Does not implicitly {@link infinitusai.be.CancelTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {infinitusai.be.ICancelTaskResponse} message CancelTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CancelTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.CancelTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {infinitusai.be.ICancelTaskResponse} message CancelTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CancelTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CancelTaskResponse} CancelTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CancelTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CancelTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CancelTaskResponse} CancelTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CancelTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CancelTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CancelTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CancelTaskResponse} CancelTaskResponse
                 */
                CancelTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CancelTaskResponse)
                        return object;
                    return new $root.infinitusai.be.CancelTaskResponse();
                };
    
                /**
                 * Creates a plain object from a CancelTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {infinitusai.be.CancelTaskResponse} message CancelTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CancelTaskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CancelTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CancelTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CancelTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CancelTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CancelTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CancelTaskResponse";
                };
    
                return CancelTaskResponse;
            })();
    
            be.EditTaskTreatmentDateRequest = (function() {
    
                /**
                 * Properties of an EditTaskTreatmentDateRequest.
                 * @memberof infinitusai.be
                 * @interface IEditTaskTreatmentDateRequest
                 * @property {string|null} [taskUuid] EditTaskTreatmentDateRequest taskUuid
                 * @property {string|null} [programName] EditTaskTreatmentDateRequest programName
                 * @property {infinitusai.be.IDate|null} [newAnticipatedDateOfTreatment] EditTaskTreatmentDateRequest newAnticipatedDateOfTreatment
                 */
    
                /**
                 * Constructs a new EditTaskTreatmentDateRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EditTaskTreatmentDateRequest.
                 * @implements IEditTaskTreatmentDateRequest
                 * @constructor
                 * @param {infinitusai.be.IEditTaskTreatmentDateRequest=} [properties] Properties to set
                 */
                function EditTaskTreatmentDateRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EditTaskTreatmentDateRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @instance
                 */
                EditTaskTreatmentDateRequest.prototype.taskUuid = "";
    
                /**
                 * EditTaskTreatmentDateRequest programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @instance
                 */
                EditTaskTreatmentDateRequest.prototype.programName = "";
    
                /**
                 * EditTaskTreatmentDateRequest newAnticipatedDateOfTreatment.
                 * @member {infinitusai.be.IDate|null|undefined} newAnticipatedDateOfTreatment
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @instance
                 */
                EditTaskTreatmentDateRequest.prototype.newAnticipatedDateOfTreatment = null;
    
                /**
                 * Creates a new EditTaskTreatmentDateRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {infinitusai.be.IEditTaskTreatmentDateRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.EditTaskTreatmentDateRequest} EditTaskTreatmentDateRequest instance
                 */
                EditTaskTreatmentDateRequest.create = function create(properties) {
                    return new EditTaskTreatmentDateRequest(properties);
                };
    
                /**
                 * Encodes the specified EditTaskTreatmentDateRequest message. Does not implicitly {@link infinitusai.be.EditTaskTreatmentDateRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {infinitusai.be.IEditTaskTreatmentDateRequest} message EditTaskTreatmentDateRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EditTaskTreatmentDateRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.programName);
                    if (message.newAnticipatedDateOfTreatment != null && Object.hasOwnProperty.call(message, "newAnticipatedDateOfTreatment"))
                        $root.infinitusai.be.Date.encode(message.newAnticipatedDateOfTreatment, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EditTaskTreatmentDateRequest message, length delimited. Does not implicitly {@link infinitusai.be.EditTaskTreatmentDateRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {infinitusai.be.IEditTaskTreatmentDateRequest} message EditTaskTreatmentDateRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EditTaskTreatmentDateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EditTaskTreatmentDateRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EditTaskTreatmentDateRequest} EditTaskTreatmentDateRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EditTaskTreatmentDateRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EditTaskTreatmentDateRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.programName = reader.string();
                                break;
                            }
                        case 3: {
                                message.newAnticipatedDateOfTreatment = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EditTaskTreatmentDateRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EditTaskTreatmentDateRequest} EditTaskTreatmentDateRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EditTaskTreatmentDateRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EditTaskTreatmentDateRequest message.
                 * @function verify
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EditTaskTreatmentDateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    if (message.newAnticipatedDateOfTreatment != null && message.hasOwnProperty("newAnticipatedDateOfTreatment")) {
                        var error = $root.infinitusai.be.Date.verify(message.newAnticipatedDateOfTreatment);
                        if (error)
                            return "newAnticipatedDateOfTreatment." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an EditTaskTreatmentDateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EditTaskTreatmentDateRequest} EditTaskTreatmentDateRequest
                 */
                EditTaskTreatmentDateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EditTaskTreatmentDateRequest)
                        return object;
                    var message = new $root.infinitusai.be.EditTaskTreatmentDateRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    if (object.newAnticipatedDateOfTreatment != null) {
                        if (typeof object.newAnticipatedDateOfTreatment !== "object")
                            throw TypeError(".infinitusai.be.EditTaskTreatmentDateRequest.newAnticipatedDateOfTreatment: object expected");
                        message.newAnticipatedDateOfTreatment = $root.infinitusai.be.Date.fromObject(object.newAnticipatedDateOfTreatment);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EditTaskTreatmentDateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {infinitusai.be.EditTaskTreatmentDateRequest} message EditTaskTreatmentDateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EditTaskTreatmentDateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.programName = "";
                        object.newAnticipatedDateOfTreatment = null;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    if (message.newAnticipatedDateOfTreatment != null && message.hasOwnProperty("newAnticipatedDateOfTreatment"))
                        object.newAnticipatedDateOfTreatment = $root.infinitusai.be.Date.toObject(message.newAnticipatedDateOfTreatment, options);
                    return object;
                };
    
                /**
                 * Converts this EditTaskTreatmentDateRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EditTaskTreatmentDateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EditTaskTreatmentDateRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EditTaskTreatmentDateRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EditTaskTreatmentDateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EditTaskTreatmentDateRequest";
                };
    
                return EditTaskTreatmentDateRequest;
            })();
    
            be.EditTaskTreatmentDateResponse = (function() {
    
                /**
                 * Properties of an EditTaskTreatmentDateResponse.
                 * @memberof infinitusai.be
                 * @interface IEditTaskTreatmentDateResponse
                 */
    
                /**
                 * Constructs a new EditTaskTreatmentDateResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EditTaskTreatmentDateResponse.
                 * @implements IEditTaskTreatmentDateResponse
                 * @constructor
                 * @param {infinitusai.be.IEditTaskTreatmentDateResponse=} [properties] Properties to set
                 */
                function EditTaskTreatmentDateResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new EditTaskTreatmentDateResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {infinitusai.be.IEditTaskTreatmentDateResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.EditTaskTreatmentDateResponse} EditTaskTreatmentDateResponse instance
                 */
                EditTaskTreatmentDateResponse.create = function create(properties) {
                    return new EditTaskTreatmentDateResponse(properties);
                };
    
                /**
                 * Encodes the specified EditTaskTreatmentDateResponse message. Does not implicitly {@link infinitusai.be.EditTaskTreatmentDateResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {infinitusai.be.IEditTaskTreatmentDateResponse} message EditTaskTreatmentDateResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EditTaskTreatmentDateResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified EditTaskTreatmentDateResponse message, length delimited. Does not implicitly {@link infinitusai.be.EditTaskTreatmentDateResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {infinitusai.be.IEditTaskTreatmentDateResponse} message EditTaskTreatmentDateResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EditTaskTreatmentDateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EditTaskTreatmentDateResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EditTaskTreatmentDateResponse} EditTaskTreatmentDateResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EditTaskTreatmentDateResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EditTaskTreatmentDateResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EditTaskTreatmentDateResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EditTaskTreatmentDateResponse} EditTaskTreatmentDateResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EditTaskTreatmentDateResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EditTaskTreatmentDateResponse message.
                 * @function verify
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EditTaskTreatmentDateResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an EditTaskTreatmentDateResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EditTaskTreatmentDateResponse} EditTaskTreatmentDateResponse
                 */
                EditTaskTreatmentDateResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EditTaskTreatmentDateResponse)
                        return object;
                    return new $root.infinitusai.be.EditTaskTreatmentDateResponse();
                };
    
                /**
                 * Creates a plain object from an EditTaskTreatmentDateResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {infinitusai.be.EditTaskTreatmentDateResponse} message EditTaskTreatmentDateResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EditTaskTreatmentDateResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this EditTaskTreatmentDateResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EditTaskTreatmentDateResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EditTaskTreatmentDateResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EditTaskTreatmentDateResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EditTaskTreatmentDateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EditTaskTreatmentDateResponse";
                };
    
                return EditTaskTreatmentDateResponse;
            })();
    
            be.RequeueTaskRequest = (function() {
    
                /**
                 * Properties of a RequeueTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IRequeueTaskRequest
                 * @property {string|null} [taskUuid] RequeueTaskRequest taskUuid
                 * @property {string|null} [callUuid] RequeueTaskRequest callUuid
                 * @property {infinitusai.be.IRequeueReason|null} [requeueReasonChosen] RequeueTaskRequest requeueReasonChosen
                 * @property {string|null} [requeueNotes] RequeueTaskRequest requeueNotes
                 * @property {string|null} [newPhoneNumber] RequeueTaskRequest newPhoneNumber
                 */
    
                /**
                 * Constructs a new RequeueTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RequeueTaskRequest.
                 * @implements IRequeueTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IRequeueTaskRequest=} [properties] Properties to set
                 */
                function RequeueTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RequeueTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @instance
                 */
                RequeueTaskRequest.prototype.taskUuid = "";
    
                /**
                 * RequeueTaskRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @instance
                 */
                RequeueTaskRequest.prototype.callUuid = "";
    
                /**
                 * RequeueTaskRequest requeueReasonChosen.
                 * @member {infinitusai.be.IRequeueReason|null|undefined} requeueReasonChosen
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @instance
                 */
                RequeueTaskRequest.prototype.requeueReasonChosen = null;
    
                /**
                 * RequeueTaskRequest requeueNotes.
                 * @member {string} requeueNotes
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @instance
                 */
                RequeueTaskRequest.prototype.requeueNotes = "";
    
                /**
                 * RequeueTaskRequest newPhoneNumber.
                 * @member {string} newPhoneNumber
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @instance
                 */
                RequeueTaskRequest.prototype.newPhoneNumber = "";
    
                /**
                 * Creates a new RequeueTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {infinitusai.be.IRequeueTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RequeueTaskRequest} RequeueTaskRequest instance
                 */
                RequeueTaskRequest.create = function create(properties) {
                    return new RequeueTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified RequeueTaskRequest message. Does not implicitly {@link infinitusai.be.RequeueTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {infinitusai.be.IRequeueTaskRequest} message RequeueTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeueTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.requeueReasonChosen != null && Object.hasOwnProperty.call(message, "requeueReasonChosen"))
                        $root.infinitusai.be.RequeueReason.encode(message.requeueReasonChosen, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.requeueNotes != null && Object.hasOwnProperty.call(message, "requeueNotes"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.requeueNotes);
                    if (message.newPhoneNumber != null && Object.hasOwnProperty.call(message, "newPhoneNumber"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.newPhoneNumber);
                    return writer;
                };
    
                /**
                 * Encodes the specified RequeueTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.RequeueTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {infinitusai.be.IRequeueTaskRequest} message RequeueTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeueTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequeueTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RequeueTaskRequest} RequeueTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeueTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RequeueTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.requeueReasonChosen = $root.infinitusai.be.RequeueReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.requeueNotes = reader.string();
                                break;
                            }
                        case 5: {
                                message.newPhoneNumber = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequeueTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RequeueTaskRequest} RequeueTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeueTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequeueTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequeueTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.requeueReasonChosen != null && message.hasOwnProperty("requeueReasonChosen")) {
                        var error = $root.infinitusai.be.RequeueReason.verify(message.requeueReasonChosen);
                        if (error)
                            return "requeueReasonChosen." + error;
                    }
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        if (!$util.isString(message.requeueNotes))
                            return "requeueNotes: string expected";
                    if (message.newPhoneNumber != null && message.hasOwnProperty("newPhoneNumber"))
                        if (!$util.isString(message.newPhoneNumber))
                            return "newPhoneNumber: string expected";
                    return null;
                };
    
                /**
                 * Creates a RequeueTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RequeueTaskRequest} RequeueTaskRequest
                 */
                RequeueTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RequeueTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.RequeueTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.requeueReasonChosen != null) {
                        if (typeof object.requeueReasonChosen !== "object")
                            throw TypeError(".infinitusai.be.RequeueTaskRequest.requeueReasonChosen: object expected");
                        message.requeueReasonChosen = $root.infinitusai.be.RequeueReason.fromObject(object.requeueReasonChosen);
                    }
                    if (object.requeueNotes != null)
                        message.requeueNotes = String(object.requeueNotes);
                    if (object.newPhoneNumber != null)
                        message.newPhoneNumber = String(object.newPhoneNumber);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RequeueTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {infinitusai.be.RequeueTaskRequest} message RequeueTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequeueTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.requeueReasonChosen = null;
                        object.requeueNotes = "";
                        object.newPhoneNumber = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.requeueReasonChosen != null && message.hasOwnProperty("requeueReasonChosen"))
                        object.requeueReasonChosen = $root.infinitusai.be.RequeueReason.toObject(message.requeueReasonChosen, options);
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        object.requeueNotes = message.requeueNotes;
                    if (message.newPhoneNumber != null && message.hasOwnProperty("newPhoneNumber"))
                        object.newPhoneNumber = message.newPhoneNumber;
                    return object;
                };
    
                /**
                 * Converts this RequeueTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequeueTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequeueTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RequeueTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequeueTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RequeueTaskRequest";
                };
    
                return RequeueTaskRequest;
            })();
    
            be.RequeueTaskResponse = (function() {
    
                /**
                 * Properties of a RequeueTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IRequeueTaskResponse
                 */
    
                /**
                 * Constructs a new RequeueTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RequeueTaskResponse.
                 * @implements IRequeueTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IRequeueTaskResponse=} [properties] Properties to set
                 */
                function RequeueTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RequeueTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {infinitusai.be.IRequeueTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RequeueTaskResponse} RequeueTaskResponse instance
                 */
                RequeueTaskResponse.create = function create(properties) {
                    return new RequeueTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified RequeueTaskResponse message. Does not implicitly {@link infinitusai.be.RequeueTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {infinitusai.be.IRequeueTaskResponse} message RequeueTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeueTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RequeueTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.RequeueTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {infinitusai.be.IRequeueTaskResponse} message RequeueTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeueTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequeueTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RequeueTaskResponse} RequeueTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeueTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RequeueTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequeueTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RequeueTaskResponse} RequeueTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeueTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequeueTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequeueTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RequeueTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RequeueTaskResponse} RequeueTaskResponse
                 */
                RequeueTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RequeueTaskResponse)
                        return object;
                    return new $root.infinitusai.be.RequeueTaskResponse();
                };
    
                /**
                 * Creates a plain object from a RequeueTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {infinitusai.be.RequeueTaskResponse} message RequeueTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequeueTaskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RequeueTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequeueTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequeueTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RequeueTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequeueTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RequeueTaskResponse";
                };
    
                return RequeueTaskResponse;
            })();
    
            be.UpdateTaskFailureReasonRequest = (function() {
    
                /**
                 * Properties of an UpdateTaskFailureReasonRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateTaskFailureReasonRequest
                 * @property {string|null} [taskUuid] UpdateTaskFailureReasonRequest taskUuid
                 * @property {string|null} [callUuid] UpdateTaskFailureReasonRequest callUuid
                 * @property {infinitusai.be.ITaskFailReason|null} [failureReasonChosen] UpdateTaskFailureReasonRequest failureReasonChosen
                 */
    
                /**
                 * Constructs a new UpdateTaskFailureReasonRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateTaskFailureReasonRequest.
                 * @implements IUpdateTaskFailureReasonRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateTaskFailureReasonRequest=} [properties] Properties to set
                 */
                function UpdateTaskFailureReasonRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateTaskFailureReasonRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @instance
                 */
                UpdateTaskFailureReasonRequest.prototype.taskUuid = "";
    
                /**
                 * UpdateTaskFailureReasonRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @instance
                 */
                UpdateTaskFailureReasonRequest.prototype.callUuid = "";
    
                /**
                 * UpdateTaskFailureReasonRequest failureReasonChosen.
                 * @member {infinitusai.be.ITaskFailReason|null|undefined} failureReasonChosen
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @instance
                 */
                UpdateTaskFailureReasonRequest.prototype.failureReasonChosen = null;
    
                /**
                 * Creates a new UpdateTaskFailureReasonRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {infinitusai.be.IUpdateTaskFailureReasonRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateTaskFailureReasonRequest} UpdateTaskFailureReasonRequest instance
                 */
                UpdateTaskFailureReasonRequest.create = function create(properties) {
                    return new UpdateTaskFailureReasonRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateTaskFailureReasonRequest message. Does not implicitly {@link infinitusai.be.UpdateTaskFailureReasonRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {infinitusai.be.IUpdateTaskFailureReasonRequest} message UpdateTaskFailureReasonRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateTaskFailureReasonRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.failureReasonChosen != null && Object.hasOwnProperty.call(message, "failureReasonChosen"))
                        $root.infinitusai.be.TaskFailReason.encode(message.failureReasonChosen, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateTaskFailureReasonRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateTaskFailureReasonRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {infinitusai.be.IUpdateTaskFailureReasonRequest} message UpdateTaskFailureReasonRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateTaskFailureReasonRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateTaskFailureReasonRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateTaskFailureReasonRequest} UpdateTaskFailureReasonRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateTaskFailureReasonRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateTaskFailureReasonRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateTaskFailureReasonRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateTaskFailureReasonRequest} UpdateTaskFailureReasonRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateTaskFailureReasonRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateTaskFailureReasonRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateTaskFailureReasonRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen")) {
                        var error = $root.infinitusai.be.TaskFailReason.verify(message.failureReasonChosen);
                        if (error)
                            return "failureReasonChosen." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateTaskFailureReasonRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateTaskFailureReasonRequest} UpdateTaskFailureReasonRequest
                 */
                UpdateTaskFailureReasonRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateTaskFailureReasonRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateTaskFailureReasonRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.failureReasonChosen != null) {
                        if (typeof object.failureReasonChosen !== "object")
                            throw TypeError(".infinitusai.be.UpdateTaskFailureReasonRequest.failureReasonChosen: object expected");
                        message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.fromObject(object.failureReasonChosen);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateTaskFailureReasonRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {infinitusai.be.UpdateTaskFailureReasonRequest} message UpdateTaskFailureReasonRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateTaskFailureReasonRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.failureReasonChosen = null;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen"))
                        object.failureReasonChosen = $root.infinitusai.be.TaskFailReason.toObject(message.failureReasonChosen, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateTaskFailureReasonRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateTaskFailureReasonRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateTaskFailureReasonRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateTaskFailureReasonRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateTaskFailureReasonRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateTaskFailureReasonRequest";
                };
    
                return UpdateTaskFailureReasonRequest;
            })();
    
            be.UpdateTaskFailureReasonResponse = (function() {
    
                /**
                 * Properties of an UpdateTaskFailureReasonResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateTaskFailureReasonResponse
                 */
    
                /**
                 * Constructs a new UpdateTaskFailureReasonResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateTaskFailureReasonResponse.
                 * @implements IUpdateTaskFailureReasonResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateTaskFailureReasonResponse=} [properties] Properties to set
                 */
                function UpdateTaskFailureReasonResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpdateTaskFailureReasonResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {infinitusai.be.IUpdateTaskFailureReasonResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateTaskFailureReasonResponse} UpdateTaskFailureReasonResponse instance
                 */
                UpdateTaskFailureReasonResponse.create = function create(properties) {
                    return new UpdateTaskFailureReasonResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateTaskFailureReasonResponse message. Does not implicitly {@link infinitusai.be.UpdateTaskFailureReasonResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {infinitusai.be.IUpdateTaskFailureReasonResponse} message UpdateTaskFailureReasonResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateTaskFailureReasonResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateTaskFailureReasonResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateTaskFailureReasonResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {infinitusai.be.IUpdateTaskFailureReasonResponse} message UpdateTaskFailureReasonResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateTaskFailureReasonResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateTaskFailureReasonResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateTaskFailureReasonResponse} UpdateTaskFailureReasonResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateTaskFailureReasonResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateTaskFailureReasonResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateTaskFailureReasonResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateTaskFailureReasonResponse} UpdateTaskFailureReasonResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateTaskFailureReasonResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateTaskFailureReasonResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateTaskFailureReasonResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateTaskFailureReasonResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateTaskFailureReasonResponse} UpdateTaskFailureReasonResponse
                 */
                UpdateTaskFailureReasonResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateTaskFailureReasonResponse)
                        return object;
                    return new $root.infinitusai.be.UpdateTaskFailureReasonResponse();
                };
    
                /**
                 * Creates a plain object from an UpdateTaskFailureReasonResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {infinitusai.be.UpdateTaskFailureReasonResponse} message UpdateTaskFailureReasonResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateTaskFailureReasonResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpdateTaskFailureReasonResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateTaskFailureReasonResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateTaskFailureReasonResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateTaskFailureReasonResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateTaskFailureReasonResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateTaskFailureReasonResponse";
                };
    
                return UpdateTaskFailureReasonResponse;
            })();
    
            be.SaveCallOutputsRequest = (function() {
    
                /**
                 * Properties of a SaveCallOutputsRequest.
                 * @memberof infinitusai.be
                 * @interface ISaveCallOutputsRequest
                 * @property {string|null} [taskUuid] SaveCallOutputsRequest taskUuid
                 * @property {string|null} [callUuid] SaveCallOutputsRequest callUuid
                 * @property {infinitusai.be.IMedSupBVCallOutputs|null} [medsupBvOutputs] SaveCallOutputsRequest medsupBvOutputs
                 * @property {infinitusai.be.IFullBVCallOutputs|null} [fullBvOutputs] SaveCallOutputsRequest fullBvOutputs
                 * @property {infinitusai.be.IPBMBVCallOutputs|null} [pbmBvOutputs] SaveCallOutputsRequest pbmBvOutputs
                 * @property {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null} [covidVaccineAvailabilityCallOutputs] SaveCallOutputsRequest covidVaccineAvailabilityCallOutputs
                 * @property {infinitusai.be.ITaskFailReason|null} [failureReasonChosen] SaveCallOutputsRequest failureReasonChosen
                 * @property {infinitusai.be.ICallOutputs|null} [outOfNetworkOutputs] SaveCallOutputsRequest outOfNetworkOutputs
                 * @property {Array.<infinitusai.be.IRule>|null} [applicableRules] SaveCallOutputsRequest applicableRules
                 * @property {boolean|null} [rulesLoaded] SaveCallOutputsRequest rulesLoaded
                 */
    
                /**
                 * Constructs a new SaveCallOutputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveCallOutputsRequest.
                 * @implements ISaveCallOutputsRequest
                 * @constructor
                 * @param {infinitusai.be.ISaveCallOutputsRequest=} [properties] Properties to set
                 */
                function SaveCallOutputsRequest(properties) {
                    this.applicableRules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SaveCallOutputsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.taskUuid = "";
    
                /**
                 * SaveCallOutputsRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.callUuid = "";
    
                /**
                 * SaveCallOutputsRequest medsupBvOutputs.
                 * @member {infinitusai.be.IMedSupBVCallOutputs|null|undefined} medsupBvOutputs
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.medsupBvOutputs = null;
    
                /**
                 * SaveCallOutputsRequest fullBvOutputs.
                 * @member {infinitusai.be.IFullBVCallOutputs|null|undefined} fullBvOutputs
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.fullBvOutputs = null;
    
                /**
                 * SaveCallOutputsRequest pbmBvOutputs.
                 * @member {infinitusai.be.IPBMBVCallOutputs|null|undefined} pbmBvOutputs
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.pbmBvOutputs = null;
    
                /**
                 * SaveCallOutputsRequest covidVaccineAvailabilityCallOutputs.
                 * @member {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null|undefined} covidVaccineAvailabilityCallOutputs
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.covidVaccineAvailabilityCallOutputs = null;
    
                /**
                 * SaveCallOutputsRequest failureReasonChosen.
                 * @member {infinitusai.be.ITaskFailReason|null|undefined} failureReasonChosen
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.failureReasonChosen = null;
    
                /**
                 * SaveCallOutputsRequest outOfNetworkOutputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} outOfNetworkOutputs
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.outOfNetworkOutputs = null;
    
                /**
                 * SaveCallOutputsRequest applicableRules.
                 * @member {Array.<infinitusai.be.IRule>} applicableRules
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.applicableRules = $util.emptyArray;
    
                /**
                 * SaveCallOutputsRequest rulesLoaded.
                 * @member {boolean} rulesLoaded
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                SaveCallOutputsRequest.prototype.rulesLoaded = false;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * SaveCallOutputsRequest outputs.
                 * @member {"medsupBvOutputs"|"fullBvOutputs"|"pbmBvOutputs"|"covidVaccineAvailabilityCallOutputs"|undefined} outputs
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 */
                Object.defineProperty(SaveCallOutputsRequest.prototype, "outputs", {
                    get: $util.oneOfGetter($oneOfFields = ["medsupBvOutputs", "fullBvOutputs", "pbmBvOutputs", "covidVaccineAvailabilityCallOutputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new SaveCallOutputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveCallOutputsRequest} SaveCallOutputsRequest instance
                 */
                SaveCallOutputsRequest.create = function create(properties) {
                    return new SaveCallOutputsRequest(properties);
                };
    
                /**
                 * Encodes the specified SaveCallOutputsRequest message. Does not implicitly {@link infinitusai.be.SaveCallOutputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsRequest} message SaveCallOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveCallOutputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.medsupBvOutputs != null && Object.hasOwnProperty.call(message, "medsupBvOutputs"))
                        $root.infinitusai.be.MedSupBVCallOutputs.encode(message.medsupBvOutputs, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.fullBvOutputs != null && Object.hasOwnProperty.call(message, "fullBvOutputs"))
                        $root.infinitusai.be.FullBVCallOutputs.encode(message.fullBvOutputs, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.failureReasonChosen != null && Object.hasOwnProperty.call(message, "failureReasonChosen"))
                        $root.infinitusai.be.TaskFailReason.encode(message.failureReasonChosen, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.pbmBvOutputs != null && Object.hasOwnProperty.call(message, "pbmBvOutputs"))
                        $root.infinitusai.be.PBMBVCallOutputs.encode(message.pbmBvOutputs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.outOfNetworkOutputs != null && Object.hasOwnProperty.call(message, "outOfNetworkOutputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.outOfNetworkOutputs, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.applicableRules != null && message.applicableRules.length)
                        for (var i = 0; i < message.applicableRules.length; ++i)
                            $root.infinitusai.be.Rule.encode(message.applicableRules[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.covidVaccineAvailabilityCallOutputs != null && Object.hasOwnProperty.call(message, "covidVaccineAvailabilityCallOutputs"))
                        $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.encode(message.covidVaccineAvailabilityCallOutputs, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.rulesLoaded != null && Object.hasOwnProperty.call(message, "rulesLoaded"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.rulesLoaded);
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveCallOutputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.SaveCallOutputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsRequest} message SaveCallOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveCallOutputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveCallOutputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveCallOutputsRequest} SaveCallOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveCallOutputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveCallOutputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                if (!(message.applicableRules && message.applicableRules.length))
                                    message.applicableRules = [];
                                message.applicableRules.push($root.infinitusai.be.Rule.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                message.rulesLoaded = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveCallOutputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveCallOutputsRequest} SaveCallOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveCallOutputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveCallOutputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveCallOutputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.MedSupBVCallOutputs.verify(message.medsupBvOutputs);
                            if (error)
                                return "medsupBvOutputs." + error;
                        }
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.FullBVCallOutputs.verify(message.fullBvOutputs);
                            if (error)
                                return "fullBvOutputs." + error;
                        }
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.PBMBVCallOutputs.verify(message.pbmBvOutputs);
                            if (error)
                                return "pbmBvOutputs." + error;
                        }
                    }
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.verify(message.covidVaccineAvailabilityCallOutputs);
                            if (error)
                                return "covidVaccineAvailabilityCallOutputs." + error;
                        }
                    }
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen")) {
                        var error = $root.infinitusai.be.TaskFailReason.verify(message.failureReasonChosen);
                        if (error)
                            return "failureReasonChosen." + error;
                    }
                    if (message.outOfNetworkOutputs != null && message.hasOwnProperty("outOfNetworkOutputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.outOfNetworkOutputs);
                        if (error)
                            return "outOfNetworkOutputs." + error;
                    }
                    if (message.applicableRules != null && message.hasOwnProperty("applicableRules")) {
                        if (!Array.isArray(message.applicableRules))
                            return "applicableRules: array expected";
                        for (var i = 0; i < message.applicableRules.length; ++i) {
                            var error = $root.infinitusai.be.Rule.verify(message.applicableRules[i]);
                            if (error)
                                return "applicableRules." + error;
                        }
                    }
                    if (message.rulesLoaded != null && message.hasOwnProperty("rulesLoaded"))
                        if (typeof message.rulesLoaded !== "boolean")
                            return "rulesLoaded: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a SaveCallOutputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveCallOutputsRequest} SaveCallOutputsRequest
                 */
                SaveCallOutputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveCallOutputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.SaveCallOutputsRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.medsupBvOutputs != null) {
                        if (typeof object.medsupBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequest.medsupBvOutputs: object expected");
                        message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.fromObject(object.medsupBvOutputs);
                    }
                    if (object.fullBvOutputs != null) {
                        if (typeof object.fullBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequest.fullBvOutputs: object expected");
                        message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.fromObject(object.fullBvOutputs);
                    }
                    if (object.pbmBvOutputs != null) {
                        if (typeof object.pbmBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequest.pbmBvOutputs: object expected");
                        message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.fromObject(object.pbmBvOutputs);
                    }
                    if (object.covidVaccineAvailabilityCallOutputs != null) {
                        if (typeof object.covidVaccineAvailabilityCallOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequest.covidVaccineAvailabilityCallOutputs: object expected");
                        message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.fromObject(object.covidVaccineAvailabilityCallOutputs);
                    }
                    if (object.failureReasonChosen != null) {
                        if (typeof object.failureReasonChosen !== "object")
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequest.failureReasonChosen: object expected");
                        message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.fromObject(object.failureReasonChosen);
                    }
                    if (object.outOfNetworkOutputs != null) {
                        if (typeof object.outOfNetworkOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequest.outOfNetworkOutputs: object expected");
                        message.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.fromObject(object.outOfNetworkOutputs);
                    }
                    if (object.applicableRules) {
                        if (!Array.isArray(object.applicableRules))
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequest.applicableRules: array expected");
                        message.applicableRules = [];
                        for (var i = 0; i < object.applicableRules.length; ++i) {
                            if (typeof object.applicableRules[i] !== "object")
                                throw TypeError(".infinitusai.be.SaveCallOutputsRequest.applicableRules: object expected");
                            message.applicableRules[i] = $root.infinitusai.be.Rule.fromObject(object.applicableRules[i]);
                        }
                    }
                    if (object.rulesLoaded != null)
                        message.rulesLoaded = Boolean(object.rulesLoaded);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SaveCallOutputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.SaveCallOutputsRequest} message SaveCallOutputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveCallOutputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.applicableRules = [];
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.failureReasonChosen = null;
                        object.outOfNetworkOutputs = null;
                        object.rulesLoaded = false;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        object.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.toObject(message.medsupBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "medsupBvOutputs";
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        object.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.toObject(message.fullBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "fullBvOutputs";
                    }
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen"))
                        object.failureReasonChosen = $root.infinitusai.be.TaskFailReason.toObject(message.failureReasonChosen, options);
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        object.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.toObject(message.pbmBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "pbmBvOutputs";
                    }
                    if (message.outOfNetworkOutputs != null && message.hasOwnProperty("outOfNetworkOutputs"))
                        object.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.toObject(message.outOfNetworkOutputs, options);
                    if (message.applicableRules && message.applicableRules.length) {
                        object.applicableRules = [];
                        for (var j = 0; j < message.applicableRules.length; ++j)
                            object.applicableRules[j] = $root.infinitusai.be.Rule.toObject(message.applicableRules[j], options);
                    }
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        object.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.toObject(message.covidVaccineAvailabilityCallOutputs, options);
                        if (options.oneofs)
                            object.outputs = "covidVaccineAvailabilityCallOutputs";
                    }
                    if (message.rulesLoaded != null && message.hasOwnProperty("rulesLoaded"))
                        object.rulesLoaded = message.rulesLoaded;
                    return object;
                };
    
                /**
                 * Converts this SaveCallOutputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveCallOutputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveCallOutputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveCallOutputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveCallOutputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveCallOutputsRequest";
                };
    
                return SaveCallOutputsRequest;
            })();
    
            be.SaveCallOutputsRequestV2 = (function() {
    
                /**
                 * Properties of a SaveCallOutputsRequestV2.
                 * @memberof infinitusai.be
                 * @interface ISaveCallOutputsRequestV2
                 * @property {string|null} [taskUuid] SaveCallOutputsRequestV2 taskUuid
                 * @property {string|null} [callUuid] SaveCallOutputsRequestV2 callUuid
                 * @property {infinitusai.be.ICallOutputsDoc|null} [callOutputs] SaveCallOutputsRequestV2 callOutputs
                 * @property {google.protobuf.IFieldMask|null} [callOutputsFieldmask] SaveCallOutputsRequestV2 callOutputsFieldmask
                 * @property {Array.<infinitusai.be.IRule>|null} [applicableRules] SaveCallOutputsRequestV2 applicableRules
                 * @property {boolean|null} [rulesLoaded] SaveCallOutputsRequestV2 rulesLoaded
                 */
    
                /**
                 * Constructs a new SaveCallOutputsRequestV2.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveCallOutputsRequestV2.
                 * @implements ISaveCallOutputsRequestV2
                 * @constructor
                 * @param {infinitusai.be.ISaveCallOutputsRequestV2=} [properties] Properties to set
                 */
                function SaveCallOutputsRequestV2(properties) {
                    this.applicableRules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SaveCallOutputsRequestV2 taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @instance
                 */
                SaveCallOutputsRequestV2.prototype.taskUuid = "";
    
                /**
                 * SaveCallOutputsRequestV2 callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @instance
                 */
                SaveCallOutputsRequestV2.prototype.callUuid = "";
    
                /**
                 * SaveCallOutputsRequestV2 callOutputs.
                 * @member {infinitusai.be.ICallOutputsDoc|null|undefined} callOutputs
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @instance
                 */
                SaveCallOutputsRequestV2.prototype.callOutputs = null;
    
                /**
                 * SaveCallOutputsRequestV2 callOutputsFieldmask.
                 * @member {google.protobuf.IFieldMask|null|undefined} callOutputsFieldmask
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @instance
                 */
                SaveCallOutputsRequestV2.prototype.callOutputsFieldmask = null;
    
                /**
                 * SaveCallOutputsRequestV2 applicableRules.
                 * @member {Array.<infinitusai.be.IRule>} applicableRules
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @instance
                 */
                SaveCallOutputsRequestV2.prototype.applicableRules = $util.emptyArray;
    
                /**
                 * SaveCallOutputsRequestV2 rulesLoaded.
                 * @member {boolean} rulesLoaded
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @instance
                 */
                SaveCallOutputsRequestV2.prototype.rulesLoaded = false;
    
                /**
                 * Creates a new SaveCallOutputsRequestV2 instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsRequestV2=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveCallOutputsRequestV2} SaveCallOutputsRequestV2 instance
                 */
                SaveCallOutputsRequestV2.create = function create(properties) {
                    return new SaveCallOutputsRequestV2(properties);
                };
    
                /**
                 * Encodes the specified SaveCallOutputsRequestV2 message. Does not implicitly {@link infinitusai.be.SaveCallOutputsRequestV2.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsRequestV2} message SaveCallOutputsRequestV2 message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveCallOutputsRequestV2.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.callOutputs != null && Object.hasOwnProperty.call(message, "callOutputs"))
                        $root.infinitusai.be.CallOutputsDoc.encode(message.callOutputs, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.callOutputsFieldmask != null && Object.hasOwnProperty.call(message, "callOutputsFieldmask"))
                        $root.google.protobuf.FieldMask.encode(message.callOutputsFieldmask, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.applicableRules != null && message.applicableRules.length)
                        for (var i = 0; i < message.applicableRules.length; ++i)
                            $root.infinitusai.be.Rule.encode(message.applicableRules[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.rulesLoaded != null && Object.hasOwnProperty.call(message, "rulesLoaded"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.rulesLoaded);
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveCallOutputsRequestV2 message, length delimited. Does not implicitly {@link infinitusai.be.SaveCallOutputsRequestV2.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsRequestV2} message SaveCallOutputsRequestV2 message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveCallOutputsRequestV2.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveCallOutputsRequestV2 message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveCallOutputsRequestV2} SaveCallOutputsRequestV2
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveCallOutputsRequestV2.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveCallOutputsRequestV2();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callOutputs = $root.infinitusai.be.CallOutputsDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.callOutputsFieldmask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                if (!(message.applicableRules && message.applicableRules.length))
                                    message.applicableRules = [];
                                message.applicableRules.push($root.infinitusai.be.Rule.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                message.rulesLoaded = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveCallOutputsRequestV2 message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveCallOutputsRequestV2} SaveCallOutputsRequestV2
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveCallOutputsRequestV2.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveCallOutputsRequestV2 message.
                 * @function verify
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveCallOutputsRequestV2.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.callOutputs != null && message.hasOwnProperty("callOutputs")) {
                        var error = $root.infinitusai.be.CallOutputsDoc.verify(message.callOutputs);
                        if (error)
                            return "callOutputs." + error;
                    }
                    if (message.callOutputsFieldmask != null && message.hasOwnProperty("callOutputsFieldmask")) {
                        var error = $root.google.protobuf.FieldMask.verify(message.callOutputsFieldmask);
                        if (error)
                            return "callOutputsFieldmask." + error;
                    }
                    if (message.applicableRules != null && message.hasOwnProperty("applicableRules")) {
                        if (!Array.isArray(message.applicableRules))
                            return "applicableRules: array expected";
                        for (var i = 0; i < message.applicableRules.length; ++i) {
                            var error = $root.infinitusai.be.Rule.verify(message.applicableRules[i]);
                            if (error)
                                return "applicableRules." + error;
                        }
                    }
                    if (message.rulesLoaded != null && message.hasOwnProperty("rulesLoaded"))
                        if (typeof message.rulesLoaded !== "boolean")
                            return "rulesLoaded: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a SaveCallOutputsRequestV2 message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveCallOutputsRequestV2} SaveCallOutputsRequestV2
                 */
                SaveCallOutputsRequestV2.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveCallOutputsRequestV2)
                        return object;
                    var message = new $root.infinitusai.be.SaveCallOutputsRequestV2();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.callOutputs != null) {
                        if (typeof object.callOutputs !== "object")
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequestV2.callOutputs: object expected");
                        message.callOutputs = $root.infinitusai.be.CallOutputsDoc.fromObject(object.callOutputs);
                    }
                    if (object.callOutputsFieldmask != null) {
                        if (typeof object.callOutputsFieldmask !== "object")
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequestV2.callOutputsFieldmask: object expected");
                        message.callOutputsFieldmask = $root.google.protobuf.FieldMask.fromObject(object.callOutputsFieldmask);
                    }
                    if (object.applicableRules) {
                        if (!Array.isArray(object.applicableRules))
                            throw TypeError(".infinitusai.be.SaveCallOutputsRequestV2.applicableRules: array expected");
                        message.applicableRules = [];
                        for (var i = 0; i < object.applicableRules.length; ++i) {
                            if (typeof object.applicableRules[i] !== "object")
                                throw TypeError(".infinitusai.be.SaveCallOutputsRequestV2.applicableRules: object expected");
                            message.applicableRules[i] = $root.infinitusai.be.Rule.fromObject(object.applicableRules[i]);
                        }
                    }
                    if (object.rulesLoaded != null)
                        message.rulesLoaded = Boolean(object.rulesLoaded);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SaveCallOutputsRequestV2 message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {infinitusai.be.SaveCallOutputsRequestV2} message SaveCallOutputsRequestV2
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveCallOutputsRequestV2.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.applicableRules = [];
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.callOutputs = null;
                        object.callOutputsFieldmask = null;
                        object.rulesLoaded = false;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.callOutputs != null && message.hasOwnProperty("callOutputs"))
                        object.callOutputs = $root.infinitusai.be.CallOutputsDoc.toObject(message.callOutputs, options);
                    if (message.callOutputsFieldmask != null && message.hasOwnProperty("callOutputsFieldmask"))
                        object.callOutputsFieldmask = $root.google.protobuf.FieldMask.toObject(message.callOutputsFieldmask, options);
                    if (message.applicableRules && message.applicableRules.length) {
                        object.applicableRules = [];
                        for (var j = 0; j < message.applicableRules.length; ++j)
                            object.applicableRules[j] = $root.infinitusai.be.Rule.toObject(message.applicableRules[j], options);
                    }
                    if (message.rulesLoaded != null && message.hasOwnProperty("rulesLoaded"))
                        object.rulesLoaded = message.rulesLoaded;
                    return object;
                };
    
                /**
                 * Converts this SaveCallOutputsRequestV2 to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveCallOutputsRequestV2.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveCallOutputsRequestV2
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveCallOutputsRequestV2
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveCallOutputsRequestV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveCallOutputsRequestV2";
                };
    
                return SaveCallOutputsRequestV2;
            })();
    
            be.SaveCallOutputsResponse = (function() {
    
                /**
                 * Properties of a SaveCallOutputsResponse.
                 * @memberof infinitusai.be
                 * @interface ISaveCallOutputsResponse
                 */
    
                /**
                 * Constructs a new SaveCallOutputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveCallOutputsResponse.
                 * @implements ISaveCallOutputsResponse
                 * @constructor
                 * @param {infinitusai.be.ISaveCallOutputsResponse=} [properties] Properties to set
                 */
                function SaveCallOutputsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new SaveCallOutputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveCallOutputsResponse} SaveCallOutputsResponse instance
                 */
                SaveCallOutputsResponse.create = function create(properties) {
                    return new SaveCallOutputsResponse(properties);
                };
    
                /**
                 * Encodes the specified SaveCallOutputsResponse message. Does not implicitly {@link infinitusai.be.SaveCallOutputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsResponse} message SaveCallOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveCallOutputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveCallOutputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.SaveCallOutputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.ISaveCallOutputsResponse} message SaveCallOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveCallOutputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveCallOutputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveCallOutputsResponse} SaveCallOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveCallOutputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveCallOutputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveCallOutputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveCallOutputsResponse} SaveCallOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveCallOutputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveCallOutputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveCallOutputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a SaveCallOutputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveCallOutputsResponse} SaveCallOutputsResponse
                 */
                SaveCallOutputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveCallOutputsResponse)
                        return object;
                    return new $root.infinitusai.be.SaveCallOutputsResponse();
                };
    
                /**
                 * Creates a plain object from a SaveCallOutputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.SaveCallOutputsResponse} message SaveCallOutputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveCallOutputsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this SaveCallOutputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveCallOutputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveCallOutputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveCallOutputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveCallOutputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveCallOutputsResponse";
                };
    
                return SaveCallOutputsResponse;
            })();
    
            be.RecordPayerIntelligenceEscalationRequest = (function() {
    
                /**
                 * Properties of a RecordPayerIntelligenceEscalationRequest.
                 * @memberof infinitusai.be
                 * @interface IRecordPayerIntelligenceEscalationRequest
                 * @property {string|null} [taskUuid] RecordPayerIntelligenceEscalationRequest taskUuid
                 * @property {string|null} [callUuid] RecordPayerIntelligenceEscalationRequest callUuid
                 * @property {infinitusai.be.IPayerIntelligenceState|null} [payerIntelligenceStateAtEscalation] RecordPayerIntelligenceEscalationRequest payerIntelligenceStateAtEscalation
                 */
    
                /**
                 * Constructs a new RecordPayerIntelligenceEscalationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RecordPayerIntelligenceEscalationRequest.
                 * @implements IRecordPayerIntelligenceEscalationRequest
                 * @constructor
                 * @param {infinitusai.be.IRecordPayerIntelligenceEscalationRequest=} [properties] Properties to set
                 */
                function RecordPayerIntelligenceEscalationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RecordPayerIntelligenceEscalationRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @instance
                 */
                RecordPayerIntelligenceEscalationRequest.prototype.taskUuid = "";
    
                /**
                 * RecordPayerIntelligenceEscalationRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @instance
                 */
                RecordPayerIntelligenceEscalationRequest.prototype.callUuid = "";
    
                /**
                 * RecordPayerIntelligenceEscalationRequest payerIntelligenceStateAtEscalation.
                 * @member {infinitusai.be.IPayerIntelligenceState|null|undefined} payerIntelligenceStateAtEscalation
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @instance
                 */
                RecordPayerIntelligenceEscalationRequest.prototype.payerIntelligenceStateAtEscalation = null;
    
                /**
                 * Creates a new RecordPayerIntelligenceEscalationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceEscalationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RecordPayerIntelligenceEscalationRequest} RecordPayerIntelligenceEscalationRequest instance
                 */
                RecordPayerIntelligenceEscalationRequest.create = function create(properties) {
                    return new RecordPayerIntelligenceEscalationRequest(properties);
                };
    
                /**
                 * Encodes the specified RecordPayerIntelligenceEscalationRequest message. Does not implicitly {@link infinitusai.be.RecordPayerIntelligenceEscalationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceEscalationRequest} message RecordPayerIntelligenceEscalationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordPayerIntelligenceEscalationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.payerIntelligenceStateAtEscalation != null && Object.hasOwnProperty.call(message, "payerIntelligenceStateAtEscalation"))
                        $root.infinitusai.be.PayerIntelligenceState.encode(message.payerIntelligenceStateAtEscalation, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified RecordPayerIntelligenceEscalationRequest message, length delimited. Does not implicitly {@link infinitusai.be.RecordPayerIntelligenceEscalationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceEscalationRequest} message RecordPayerIntelligenceEscalationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordPayerIntelligenceEscalationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RecordPayerIntelligenceEscalationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RecordPayerIntelligenceEscalationRequest} RecordPayerIntelligenceEscalationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordPayerIntelligenceEscalationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RecordPayerIntelligenceEscalationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RecordPayerIntelligenceEscalationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RecordPayerIntelligenceEscalationRequest} RecordPayerIntelligenceEscalationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordPayerIntelligenceEscalationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RecordPayerIntelligenceEscalationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RecordPayerIntelligenceEscalationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.payerIntelligenceStateAtEscalation != null && message.hasOwnProperty("payerIntelligenceStateAtEscalation")) {
                        var error = $root.infinitusai.be.PayerIntelligenceState.verify(message.payerIntelligenceStateAtEscalation);
                        if (error)
                            return "payerIntelligenceStateAtEscalation." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a RecordPayerIntelligenceEscalationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RecordPayerIntelligenceEscalationRequest} RecordPayerIntelligenceEscalationRequest
                 */
                RecordPayerIntelligenceEscalationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RecordPayerIntelligenceEscalationRequest)
                        return object;
                    var message = new $root.infinitusai.be.RecordPayerIntelligenceEscalationRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.payerIntelligenceStateAtEscalation != null) {
                        if (typeof object.payerIntelligenceStateAtEscalation !== "object")
                            throw TypeError(".infinitusai.be.RecordPayerIntelligenceEscalationRequest.payerIntelligenceStateAtEscalation: object expected");
                        message.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.fromObject(object.payerIntelligenceStateAtEscalation);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RecordPayerIntelligenceEscalationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {infinitusai.be.RecordPayerIntelligenceEscalationRequest} message RecordPayerIntelligenceEscalationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordPayerIntelligenceEscalationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.payerIntelligenceStateAtEscalation = null;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.payerIntelligenceStateAtEscalation != null && message.hasOwnProperty("payerIntelligenceStateAtEscalation"))
                        object.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.toObject(message.payerIntelligenceStateAtEscalation, options);
                    return object;
                };
    
                /**
                 * Converts this RecordPayerIntelligenceEscalationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordPayerIntelligenceEscalationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RecordPayerIntelligenceEscalationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordPayerIntelligenceEscalationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RecordPayerIntelligenceEscalationRequest";
                };
    
                return RecordPayerIntelligenceEscalationRequest;
            })();
    
            be.RecordPayerIntelligenceEscalationResponse = (function() {
    
                /**
                 * Properties of a RecordPayerIntelligenceEscalationResponse.
                 * @memberof infinitusai.be
                 * @interface IRecordPayerIntelligenceEscalationResponse
                 */
    
                /**
                 * Constructs a new RecordPayerIntelligenceEscalationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RecordPayerIntelligenceEscalationResponse.
                 * @implements IRecordPayerIntelligenceEscalationResponse
                 * @constructor
                 * @param {infinitusai.be.IRecordPayerIntelligenceEscalationResponse=} [properties] Properties to set
                 */
                function RecordPayerIntelligenceEscalationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RecordPayerIntelligenceEscalationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceEscalationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RecordPayerIntelligenceEscalationResponse} RecordPayerIntelligenceEscalationResponse instance
                 */
                RecordPayerIntelligenceEscalationResponse.create = function create(properties) {
                    return new RecordPayerIntelligenceEscalationResponse(properties);
                };
    
                /**
                 * Encodes the specified RecordPayerIntelligenceEscalationResponse message. Does not implicitly {@link infinitusai.be.RecordPayerIntelligenceEscalationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceEscalationResponse} message RecordPayerIntelligenceEscalationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordPayerIntelligenceEscalationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RecordPayerIntelligenceEscalationResponse message, length delimited. Does not implicitly {@link infinitusai.be.RecordPayerIntelligenceEscalationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {infinitusai.be.IRecordPayerIntelligenceEscalationResponse} message RecordPayerIntelligenceEscalationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecordPayerIntelligenceEscalationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RecordPayerIntelligenceEscalationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RecordPayerIntelligenceEscalationResponse} RecordPayerIntelligenceEscalationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordPayerIntelligenceEscalationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RecordPayerIntelligenceEscalationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RecordPayerIntelligenceEscalationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RecordPayerIntelligenceEscalationResponse} RecordPayerIntelligenceEscalationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecordPayerIntelligenceEscalationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RecordPayerIntelligenceEscalationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RecordPayerIntelligenceEscalationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RecordPayerIntelligenceEscalationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RecordPayerIntelligenceEscalationResponse} RecordPayerIntelligenceEscalationResponse
                 */
                RecordPayerIntelligenceEscalationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RecordPayerIntelligenceEscalationResponse)
                        return object;
                    return new $root.infinitusai.be.RecordPayerIntelligenceEscalationResponse();
                };
    
                /**
                 * Creates a plain object from a RecordPayerIntelligenceEscalationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {infinitusai.be.RecordPayerIntelligenceEscalationResponse} message RecordPayerIntelligenceEscalationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecordPayerIntelligenceEscalationResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RecordPayerIntelligenceEscalationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecordPayerIntelligenceEscalationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RecordPayerIntelligenceEscalationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RecordPayerIntelligenceEscalationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecordPayerIntelligenceEscalationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RecordPayerIntelligenceEscalationResponse";
                };
    
                return RecordPayerIntelligenceEscalationResponse;
            })();
    
            be.CallOutputsDoc = (function() {
    
                /**
                 * Properties of a CallOutputsDoc.
                 * @memberof infinitusai.be
                 * @interface ICallOutputsDoc
                 * @property {infinitusai.be.IMedSupBVCallOutputs|null} [medsupBvOutputs] CallOutputsDoc medsupBvOutputs
                 * @property {infinitusai.be.IFullBVCallOutputs|null} [fullBvOutputs] CallOutputsDoc fullBvOutputs
                 * @property {infinitusai.be.IPBMBVCallOutputs|null} [pbmBvOutputs] CallOutputsDoc pbmBvOutputs
                 * @property {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null} [covidVaccineAvailabilityCallOutputs] CallOutputsDoc covidVaccineAvailabilityCallOutputs
                 * @property {infinitusai.be.ICallOutputs|null} [outOfNetworkOutputs] CallOutputsDoc outOfNetworkOutputs
                 * @property {infinitusai.be.ITaskFailReason|null} [failureReasonChosen] CallOutputsDoc failureReasonChosen
                 * @property {infinitusai.be.IUserInfo|null} [lastEditor] CallOutputsDoc lastEditor
                 * @property {number|Long|null} [lastEditMillis] CallOutputsDoc lastEditMillis
                 * @property {infinitusai.be.IPayerIntelligenceState|null} [payerIntelligenceStateAtEscalation] CallOutputsDoc payerIntelligenceStateAtEscalation
                 */
    
                /**
                 * Constructs a new CallOutputsDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallOutputsDoc.
                 * @implements ICallOutputsDoc
                 * @constructor
                 * @param {infinitusai.be.ICallOutputsDoc=} [properties] Properties to set
                 */
                function CallOutputsDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallOutputsDoc medsupBvOutputs.
                 * @member {infinitusai.be.IMedSupBVCallOutputs|null|undefined} medsupBvOutputs
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.medsupBvOutputs = null;
    
                /**
                 * CallOutputsDoc fullBvOutputs.
                 * @member {infinitusai.be.IFullBVCallOutputs|null|undefined} fullBvOutputs
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.fullBvOutputs = null;
    
                /**
                 * CallOutputsDoc pbmBvOutputs.
                 * @member {infinitusai.be.IPBMBVCallOutputs|null|undefined} pbmBvOutputs
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.pbmBvOutputs = null;
    
                /**
                 * CallOutputsDoc covidVaccineAvailabilityCallOutputs.
                 * @member {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null|undefined} covidVaccineAvailabilityCallOutputs
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.covidVaccineAvailabilityCallOutputs = null;
    
                /**
                 * CallOutputsDoc outOfNetworkOutputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} outOfNetworkOutputs
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.outOfNetworkOutputs = null;
    
                /**
                 * CallOutputsDoc failureReasonChosen.
                 * @member {infinitusai.be.ITaskFailReason|null|undefined} failureReasonChosen
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.failureReasonChosen = null;
    
                /**
                 * CallOutputsDoc lastEditor.
                 * @member {infinitusai.be.IUserInfo|null|undefined} lastEditor
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.lastEditor = null;
    
                /**
                 * CallOutputsDoc lastEditMillis.
                 * @member {number|Long} lastEditMillis
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.lastEditMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallOutputsDoc payerIntelligenceStateAtEscalation.
                 * @member {infinitusai.be.IPayerIntelligenceState|null|undefined} payerIntelligenceStateAtEscalation
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                CallOutputsDoc.prototype.payerIntelligenceStateAtEscalation = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * CallOutputsDoc outputs.
                 * @member {"medsupBvOutputs"|"fullBvOutputs"|"pbmBvOutputs"|"covidVaccineAvailabilityCallOutputs"|undefined} outputs
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 */
                Object.defineProperty(CallOutputsDoc.prototype, "outputs", {
                    get: $util.oneOfGetter($oneOfFields = ["medsupBvOutputs", "fullBvOutputs", "pbmBvOutputs", "covidVaccineAvailabilityCallOutputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new CallOutputsDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {infinitusai.be.ICallOutputsDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.CallOutputsDoc} CallOutputsDoc instance
                 */
                CallOutputsDoc.create = function create(properties) {
                    return new CallOutputsDoc(properties);
                };
    
                /**
                 * Encodes the specified CallOutputsDoc message. Does not implicitly {@link infinitusai.be.CallOutputsDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {infinitusai.be.ICallOutputsDoc} message CallOutputsDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallOutputsDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.medsupBvOutputs != null && Object.hasOwnProperty.call(message, "medsupBvOutputs"))
                        $root.infinitusai.be.MedSupBVCallOutputs.encode(message.medsupBvOutputs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fullBvOutputs != null && Object.hasOwnProperty.call(message, "fullBvOutputs"))
                        $root.infinitusai.be.FullBVCallOutputs.encode(message.fullBvOutputs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.failureReasonChosen != null && Object.hasOwnProperty.call(message, "failureReasonChosen"))
                        $root.infinitusai.be.TaskFailReason.encode(message.failureReasonChosen, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.lastEditor != null && Object.hasOwnProperty.call(message, "lastEditor"))
                        $root.infinitusai.be.UserInfo.encode(message.lastEditor, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.lastEditMillis != null && Object.hasOwnProperty.call(message, "lastEditMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.lastEditMillis);
                    if (message.pbmBvOutputs != null && Object.hasOwnProperty.call(message, "pbmBvOutputs"))
                        $root.infinitusai.be.PBMBVCallOutputs.encode(message.pbmBvOutputs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.outOfNetworkOutputs != null && Object.hasOwnProperty.call(message, "outOfNetworkOutputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.outOfNetworkOutputs, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.payerIntelligenceStateAtEscalation != null && Object.hasOwnProperty.call(message, "payerIntelligenceStateAtEscalation"))
                        $root.infinitusai.be.PayerIntelligenceState.encode(message.payerIntelligenceStateAtEscalation, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.covidVaccineAvailabilityCallOutputs != null && Object.hasOwnProperty.call(message, "covidVaccineAvailabilityCallOutputs"))
                        $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.encode(message.covidVaccineAvailabilityCallOutputs, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallOutputsDoc message, length delimited. Does not implicitly {@link infinitusai.be.CallOutputsDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {infinitusai.be.ICallOutputsDoc} message CallOutputsDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallOutputsDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallOutputsDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallOutputsDoc} CallOutputsDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallOutputsDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallOutputsDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.lastEditor = $root.infinitusai.be.UserInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.lastEditMillis = reader.int64();
                                break;
                            }
                        case 8: {
                                message.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallOutputsDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallOutputsDoc} CallOutputsDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallOutputsDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallOutputsDoc message.
                 * @function verify
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallOutputsDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.MedSupBVCallOutputs.verify(message.medsupBvOutputs);
                            if (error)
                                return "medsupBvOutputs." + error;
                        }
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.FullBVCallOutputs.verify(message.fullBvOutputs);
                            if (error)
                                return "fullBvOutputs." + error;
                        }
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.PBMBVCallOutputs.verify(message.pbmBvOutputs);
                            if (error)
                                return "pbmBvOutputs." + error;
                        }
                    }
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.verify(message.covidVaccineAvailabilityCallOutputs);
                            if (error)
                                return "covidVaccineAvailabilityCallOutputs." + error;
                        }
                    }
                    if (message.outOfNetworkOutputs != null && message.hasOwnProperty("outOfNetworkOutputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.outOfNetworkOutputs);
                        if (error)
                            return "outOfNetworkOutputs." + error;
                    }
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen")) {
                        var error = $root.infinitusai.be.TaskFailReason.verify(message.failureReasonChosen);
                        if (error)
                            return "failureReasonChosen." + error;
                    }
                    if (message.lastEditor != null && message.hasOwnProperty("lastEditor")) {
                        var error = $root.infinitusai.be.UserInfo.verify(message.lastEditor);
                        if (error)
                            return "lastEditor." + error;
                    }
                    if (message.lastEditMillis != null && message.hasOwnProperty("lastEditMillis"))
                        if (!$util.isInteger(message.lastEditMillis) && !(message.lastEditMillis && $util.isInteger(message.lastEditMillis.low) && $util.isInteger(message.lastEditMillis.high)))
                            return "lastEditMillis: integer|Long expected";
                    if (message.payerIntelligenceStateAtEscalation != null && message.hasOwnProperty("payerIntelligenceStateAtEscalation")) {
                        var error = $root.infinitusai.be.PayerIntelligenceState.verify(message.payerIntelligenceStateAtEscalation);
                        if (error)
                            return "payerIntelligenceStateAtEscalation." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CallOutputsDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallOutputsDoc} CallOutputsDoc
                 */
                CallOutputsDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallOutputsDoc)
                        return object;
                    var message = new $root.infinitusai.be.CallOutputsDoc();
                    if (object.medsupBvOutputs != null) {
                        if (typeof object.medsupBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputsDoc.medsupBvOutputs: object expected");
                        message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.fromObject(object.medsupBvOutputs);
                    }
                    if (object.fullBvOutputs != null) {
                        if (typeof object.fullBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputsDoc.fullBvOutputs: object expected");
                        message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.fromObject(object.fullBvOutputs);
                    }
                    if (object.pbmBvOutputs != null) {
                        if (typeof object.pbmBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputsDoc.pbmBvOutputs: object expected");
                        message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.fromObject(object.pbmBvOutputs);
                    }
                    if (object.covidVaccineAvailabilityCallOutputs != null) {
                        if (typeof object.covidVaccineAvailabilityCallOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputsDoc.covidVaccineAvailabilityCallOutputs: object expected");
                        message.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.fromObject(object.covidVaccineAvailabilityCallOutputs);
                    }
                    if (object.outOfNetworkOutputs != null) {
                        if (typeof object.outOfNetworkOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallOutputsDoc.outOfNetworkOutputs: object expected");
                        message.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.fromObject(object.outOfNetworkOutputs);
                    }
                    if (object.failureReasonChosen != null) {
                        if (typeof object.failureReasonChosen !== "object")
                            throw TypeError(".infinitusai.be.CallOutputsDoc.failureReasonChosen: object expected");
                        message.failureReasonChosen = $root.infinitusai.be.TaskFailReason.fromObject(object.failureReasonChosen);
                    }
                    if (object.lastEditor != null) {
                        if (typeof object.lastEditor !== "object")
                            throw TypeError(".infinitusai.be.CallOutputsDoc.lastEditor: object expected");
                        message.lastEditor = $root.infinitusai.be.UserInfo.fromObject(object.lastEditor);
                    }
                    if (object.lastEditMillis != null)
                        if ($util.Long)
                            (message.lastEditMillis = $util.Long.fromValue(object.lastEditMillis)).unsigned = false;
                        else if (typeof object.lastEditMillis === "string")
                            message.lastEditMillis = parseInt(object.lastEditMillis, 10);
                        else if (typeof object.lastEditMillis === "number")
                            message.lastEditMillis = object.lastEditMillis;
                        else if (typeof object.lastEditMillis === "object")
                            message.lastEditMillis = new $util.LongBits(object.lastEditMillis.low >>> 0, object.lastEditMillis.high >>> 0).toNumber();
                    if (object.payerIntelligenceStateAtEscalation != null) {
                        if (typeof object.payerIntelligenceStateAtEscalation !== "object")
                            throw TypeError(".infinitusai.be.CallOutputsDoc.payerIntelligenceStateAtEscalation: object expected");
                        message.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.fromObject(object.payerIntelligenceStateAtEscalation);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallOutputsDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {infinitusai.be.CallOutputsDoc} message CallOutputsDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallOutputsDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.failureReasonChosen = null;
                        object.lastEditor = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastEditMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastEditMillis = options.longs === String ? "0" : 0;
                        object.outOfNetworkOutputs = null;
                        object.payerIntelligenceStateAtEscalation = null;
                    }
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        object.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.toObject(message.medsupBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "medsupBvOutputs";
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        object.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.toObject(message.fullBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "fullBvOutputs";
                    }
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen"))
                        object.failureReasonChosen = $root.infinitusai.be.TaskFailReason.toObject(message.failureReasonChosen, options);
                    if (message.lastEditor != null && message.hasOwnProperty("lastEditor"))
                        object.lastEditor = $root.infinitusai.be.UserInfo.toObject(message.lastEditor, options);
                    if (message.lastEditMillis != null && message.hasOwnProperty("lastEditMillis"))
                        if (typeof message.lastEditMillis === "number")
                            object.lastEditMillis = options.longs === String ? String(message.lastEditMillis) : message.lastEditMillis;
                        else
                            object.lastEditMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastEditMillis) : options.longs === Number ? new $util.LongBits(message.lastEditMillis.low >>> 0, message.lastEditMillis.high >>> 0).toNumber() : message.lastEditMillis;
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        object.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.toObject(message.pbmBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "pbmBvOutputs";
                    }
                    if (message.outOfNetworkOutputs != null && message.hasOwnProperty("outOfNetworkOutputs"))
                        object.outOfNetworkOutputs = $root.infinitusai.be.CallOutputs.toObject(message.outOfNetworkOutputs, options);
                    if (message.payerIntelligenceStateAtEscalation != null && message.hasOwnProperty("payerIntelligenceStateAtEscalation"))
                        object.payerIntelligenceStateAtEscalation = $root.infinitusai.be.PayerIntelligenceState.toObject(message.payerIntelligenceStateAtEscalation, options);
                    if (message.covidVaccineAvailabilityCallOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityCallOutputs")) {
                        object.covidVaccineAvailabilityCallOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.toObject(message.covidVaccineAvailabilityCallOutputs, options);
                        if (options.oneofs)
                            object.outputs = "covidVaccineAvailabilityCallOutputs";
                    }
                    return object;
                };
    
                /**
                 * Converts this CallOutputsDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallOutputsDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallOutputsDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallOutputsDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallOutputsDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallOutputsDoc";
                };
    
                return CallOutputsDoc;
            })();
    
            be.CallChcOutputsDoc = (function() {
    
                /**
                 * Properties of a CallChcOutputsDoc.
                 * @memberof infinitusai.be
                 * @interface ICallChcOutputsDoc
                 * @property {string|null} [rawDataFilePath] CallChcOutputsDoc rawDataFilePath
                 * @property {number|Long|null} [createdAtMillis] CallChcOutputsDoc createdAtMillis
                 * @property {infinitusai.be.IChangeHealthcareOutputs|null} [chcOutputs] CallChcOutputsDoc chcOutputs
                 */
    
                /**
                 * Constructs a new CallChcOutputsDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallChcOutputsDoc.
                 * @implements ICallChcOutputsDoc
                 * @constructor
                 * @param {infinitusai.be.ICallChcOutputsDoc=} [properties] Properties to set
                 */
                function CallChcOutputsDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallChcOutputsDoc rawDataFilePath.
                 * @member {string} rawDataFilePath
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @instance
                 */
                CallChcOutputsDoc.prototype.rawDataFilePath = "";
    
                /**
                 * CallChcOutputsDoc createdAtMillis.
                 * @member {number|Long} createdAtMillis
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @instance
                 */
                CallChcOutputsDoc.prototype.createdAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CallChcOutputsDoc chcOutputs.
                 * @member {infinitusai.be.IChangeHealthcareOutputs|null|undefined} chcOutputs
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @instance
                 */
                CallChcOutputsDoc.prototype.chcOutputs = null;
    
                /**
                 * Creates a new CallChcOutputsDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {infinitusai.be.ICallChcOutputsDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.CallChcOutputsDoc} CallChcOutputsDoc instance
                 */
                CallChcOutputsDoc.create = function create(properties) {
                    return new CallChcOutputsDoc(properties);
                };
    
                /**
                 * Encodes the specified CallChcOutputsDoc message. Does not implicitly {@link infinitusai.be.CallChcOutputsDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {infinitusai.be.ICallChcOutputsDoc} message CallChcOutputsDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallChcOutputsDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.rawDataFilePath != null && Object.hasOwnProperty.call(message, "rawDataFilePath"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.rawDataFilePath);
                    if (message.createdAtMillis != null && Object.hasOwnProperty.call(message, "createdAtMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.createdAtMillis);
                    if (message.chcOutputs != null && Object.hasOwnProperty.call(message, "chcOutputs"))
                        $root.infinitusai.be.ChangeHealthcareOutputs.encode(message.chcOutputs, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallChcOutputsDoc message, length delimited. Does not implicitly {@link infinitusai.be.CallChcOutputsDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {infinitusai.be.ICallChcOutputsDoc} message CallChcOutputsDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallChcOutputsDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallChcOutputsDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallChcOutputsDoc} CallChcOutputsDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallChcOutputsDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallChcOutputsDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.rawDataFilePath = reader.string();
                                break;
                            }
                        case 2: {
                                message.createdAtMillis = reader.int64();
                                break;
                            }
                        case 26: {
                                message.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallChcOutputsDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallChcOutputsDoc} CallChcOutputsDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallChcOutputsDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallChcOutputsDoc message.
                 * @function verify
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallChcOutputsDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.rawDataFilePath != null && message.hasOwnProperty("rawDataFilePath"))
                        if (!$util.isString(message.rawDataFilePath))
                            return "rawDataFilePath: string expected";
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (!$util.isInteger(message.createdAtMillis) && !(message.createdAtMillis && $util.isInteger(message.createdAtMillis.low) && $util.isInteger(message.createdAtMillis.high)))
                            return "createdAtMillis: integer|Long expected";
                    if (message.chcOutputs != null && message.hasOwnProperty("chcOutputs")) {
                        var error = $root.infinitusai.be.ChangeHealthcareOutputs.verify(message.chcOutputs);
                        if (error)
                            return "chcOutputs." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CallChcOutputsDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallChcOutputsDoc} CallChcOutputsDoc
                 */
                CallChcOutputsDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallChcOutputsDoc)
                        return object;
                    var message = new $root.infinitusai.be.CallChcOutputsDoc();
                    if (object.rawDataFilePath != null)
                        message.rawDataFilePath = String(object.rawDataFilePath);
                    if (object.createdAtMillis != null)
                        if ($util.Long)
                            (message.createdAtMillis = $util.Long.fromValue(object.createdAtMillis)).unsigned = false;
                        else if (typeof object.createdAtMillis === "string")
                            message.createdAtMillis = parseInt(object.createdAtMillis, 10);
                        else if (typeof object.createdAtMillis === "number")
                            message.createdAtMillis = object.createdAtMillis;
                        else if (typeof object.createdAtMillis === "object")
                            message.createdAtMillis = new $util.LongBits(object.createdAtMillis.low >>> 0, object.createdAtMillis.high >>> 0).toNumber();
                    if (object.chcOutputs != null) {
                        if (typeof object.chcOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallChcOutputsDoc.chcOutputs: object expected");
                        message.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.fromObject(object.chcOutputs);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallChcOutputsDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {infinitusai.be.CallChcOutputsDoc} message CallChcOutputsDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallChcOutputsDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.rawDataFilePath = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAtMillis = options.longs === String ? "0" : 0;
                        object.chcOutputs = null;
                    }
                    if (message.rawDataFilePath != null && message.hasOwnProperty("rawDataFilePath"))
                        object.rawDataFilePath = message.rawDataFilePath;
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (typeof message.createdAtMillis === "number")
                            object.createdAtMillis = options.longs === String ? String(message.createdAtMillis) : message.createdAtMillis;
                        else
                            object.createdAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtMillis) : options.longs === Number ? new $util.LongBits(message.createdAtMillis.low >>> 0, message.createdAtMillis.high >>> 0).toNumber() : message.createdAtMillis;
                    if (message.chcOutputs != null && message.hasOwnProperty("chcOutputs"))
                        object.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.toObject(message.chcOutputs, options);
                    return object;
                };
    
                /**
                 * Converts this CallChcOutputsDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallChcOutputsDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallChcOutputsDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallChcOutputsDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallChcOutputsDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallChcOutputsDoc";
                };
    
                return CallChcOutputsDoc;
            })();
    
            be.ClaimReviewRequest = (function() {
    
                /**
                 * Properties of a ClaimReviewRequest.
                 * @memberof infinitusai.be
                 * @interface IClaimReviewRequest
                 * @property {string|null} [taskUuid] ClaimReviewRequest taskUuid
                 * @property {string|null} [callUuid] ClaimReviewRequest callUuid
                 * @property {string|null} [orgUuid] ClaimReviewRequest orgUuid
                 * @property {string|null} [userEmail] ClaimReviewRequest userEmail
                 */
    
                /**
                 * Constructs a new ClaimReviewRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ClaimReviewRequest.
                 * @implements IClaimReviewRequest
                 * @constructor
                 * @param {infinitusai.be.IClaimReviewRequest=} [properties] Properties to set
                 */
                function ClaimReviewRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ClaimReviewRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @instance
                 */
                ClaimReviewRequest.prototype.taskUuid = "";
    
                /**
                 * ClaimReviewRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @instance
                 */
                ClaimReviewRequest.prototype.callUuid = "";
    
                /**
                 * ClaimReviewRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @instance
                 */
                ClaimReviewRequest.prototype.orgUuid = "";
    
                /**
                 * ClaimReviewRequest userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @instance
                 */
                ClaimReviewRequest.prototype.userEmail = "";
    
                /**
                 * Creates a new ClaimReviewRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {infinitusai.be.IClaimReviewRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ClaimReviewRequest} ClaimReviewRequest instance
                 */
                ClaimReviewRequest.create = function create(properties) {
                    return new ClaimReviewRequest(properties);
                };
    
                /**
                 * Encodes the specified ClaimReviewRequest message. Does not implicitly {@link infinitusai.be.ClaimReviewRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {infinitusai.be.IClaimReviewRequest} message ClaimReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimReviewRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified ClaimReviewRequest message, length delimited. Does not implicitly {@link infinitusai.be.ClaimReviewRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {infinitusai.be.IClaimReviewRequest} message ClaimReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ClaimReviewRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ClaimReviewRequest} ClaimReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimReviewRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ClaimReviewRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.userEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ClaimReviewRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ClaimReviewRequest} ClaimReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimReviewRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ClaimReviewRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClaimReviewRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a ClaimReviewRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ClaimReviewRequest} ClaimReviewRequest
                 */
                ClaimReviewRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ClaimReviewRequest)
                        return object;
                    var message = new $root.infinitusai.be.ClaimReviewRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ClaimReviewRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {infinitusai.be.ClaimReviewRequest} message ClaimReviewRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClaimReviewRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.orgUuid = "";
                        object.userEmail = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    return object;
                };
    
                /**
                 * Converts this ClaimReviewRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClaimReviewRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ClaimReviewRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ClaimReviewRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClaimReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ClaimReviewRequest";
                };
    
                return ClaimReviewRequest;
            })();
    
            be.ClaimReviewResponse = (function() {
    
                /**
                 * Properties of a ClaimReviewResponse.
                 * @memberof infinitusai.be
                 * @interface IClaimReviewResponse
                 */
    
                /**
                 * Constructs a new ClaimReviewResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ClaimReviewResponse.
                 * @implements IClaimReviewResponse
                 * @constructor
                 * @param {infinitusai.be.IClaimReviewResponse=} [properties] Properties to set
                 */
                function ClaimReviewResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ClaimReviewResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {infinitusai.be.IClaimReviewResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ClaimReviewResponse} ClaimReviewResponse instance
                 */
                ClaimReviewResponse.create = function create(properties) {
                    return new ClaimReviewResponse(properties);
                };
    
                /**
                 * Encodes the specified ClaimReviewResponse message. Does not implicitly {@link infinitusai.be.ClaimReviewResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {infinitusai.be.IClaimReviewResponse} message ClaimReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimReviewResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ClaimReviewResponse message, length delimited. Does not implicitly {@link infinitusai.be.ClaimReviewResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {infinitusai.be.IClaimReviewResponse} message ClaimReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ClaimReviewResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ClaimReviewResponse} ClaimReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimReviewResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ClaimReviewResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ClaimReviewResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ClaimReviewResponse} ClaimReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimReviewResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ClaimReviewResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClaimReviewResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ClaimReviewResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ClaimReviewResponse} ClaimReviewResponse
                 */
                ClaimReviewResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ClaimReviewResponse)
                        return object;
                    return new $root.infinitusai.be.ClaimReviewResponse();
                };
    
                /**
                 * Creates a plain object from a ClaimReviewResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {infinitusai.be.ClaimReviewResponse} message ClaimReviewResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClaimReviewResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ClaimReviewResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClaimReviewResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ClaimReviewResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ClaimReviewResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClaimReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ClaimReviewResponse";
                };
    
                return ClaimReviewResponse;
            })();
    
            be.RelinquishReviewRequest = (function() {
    
                /**
                 * Properties of a RelinquishReviewRequest.
                 * @memberof infinitusai.be
                 * @interface IRelinquishReviewRequest
                 * @property {string|null} [taskUuid] RelinquishReviewRequest taskUuid
                 * @property {string|null} [callUuid] RelinquishReviewRequest callUuid
                 * @property {string|null} [orgUuid] RelinquishReviewRequest orgUuid
                 * @property {string|null} [userEmail] RelinquishReviewRequest userEmail
                 */
    
                /**
                 * Constructs a new RelinquishReviewRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RelinquishReviewRequest.
                 * @implements IRelinquishReviewRequest
                 * @constructor
                 * @param {infinitusai.be.IRelinquishReviewRequest=} [properties] Properties to set
                 */
                function RelinquishReviewRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RelinquishReviewRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @instance
                 */
                RelinquishReviewRequest.prototype.taskUuid = "";
    
                /**
                 * RelinquishReviewRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @instance
                 */
                RelinquishReviewRequest.prototype.callUuid = "";
    
                /**
                 * RelinquishReviewRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @instance
                 */
                RelinquishReviewRequest.prototype.orgUuid = "";
    
                /**
                 * RelinquishReviewRequest userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @instance
                 */
                RelinquishReviewRequest.prototype.userEmail = "";
    
                /**
                 * Creates a new RelinquishReviewRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {infinitusai.be.IRelinquishReviewRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RelinquishReviewRequest} RelinquishReviewRequest instance
                 */
                RelinquishReviewRequest.create = function create(properties) {
                    return new RelinquishReviewRequest(properties);
                };
    
                /**
                 * Encodes the specified RelinquishReviewRequest message. Does not implicitly {@link infinitusai.be.RelinquishReviewRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {infinitusai.be.IRelinquishReviewRequest} message RelinquishReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RelinquishReviewRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified RelinquishReviewRequest message, length delimited. Does not implicitly {@link infinitusai.be.RelinquishReviewRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {infinitusai.be.IRelinquishReviewRequest} message RelinquishReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RelinquishReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RelinquishReviewRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RelinquishReviewRequest} RelinquishReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RelinquishReviewRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RelinquishReviewRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.userEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RelinquishReviewRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RelinquishReviewRequest} RelinquishReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RelinquishReviewRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RelinquishReviewRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RelinquishReviewRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a RelinquishReviewRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RelinquishReviewRequest} RelinquishReviewRequest
                 */
                RelinquishReviewRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RelinquishReviewRequest)
                        return object;
                    var message = new $root.infinitusai.be.RelinquishReviewRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RelinquishReviewRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {infinitusai.be.RelinquishReviewRequest} message RelinquishReviewRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RelinquishReviewRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.orgUuid = "";
                        object.userEmail = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    return object;
                };
    
                /**
                 * Converts this RelinquishReviewRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RelinquishReviewRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RelinquishReviewRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RelinquishReviewRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RelinquishReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RelinquishReviewRequest";
                };
    
                return RelinquishReviewRequest;
            })();
    
            be.RelinquishReviewResponse = (function() {
    
                /**
                 * Properties of a RelinquishReviewResponse.
                 * @memberof infinitusai.be
                 * @interface IRelinquishReviewResponse
                 */
    
                /**
                 * Constructs a new RelinquishReviewResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RelinquishReviewResponse.
                 * @implements IRelinquishReviewResponse
                 * @constructor
                 * @param {infinitusai.be.IRelinquishReviewResponse=} [properties] Properties to set
                 */
                function RelinquishReviewResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RelinquishReviewResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {infinitusai.be.IRelinquishReviewResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RelinquishReviewResponse} RelinquishReviewResponse instance
                 */
                RelinquishReviewResponse.create = function create(properties) {
                    return new RelinquishReviewResponse(properties);
                };
    
                /**
                 * Encodes the specified RelinquishReviewResponse message. Does not implicitly {@link infinitusai.be.RelinquishReviewResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {infinitusai.be.IRelinquishReviewResponse} message RelinquishReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RelinquishReviewResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RelinquishReviewResponse message, length delimited. Does not implicitly {@link infinitusai.be.RelinquishReviewResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {infinitusai.be.IRelinquishReviewResponse} message RelinquishReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RelinquishReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RelinquishReviewResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RelinquishReviewResponse} RelinquishReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RelinquishReviewResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RelinquishReviewResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RelinquishReviewResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RelinquishReviewResponse} RelinquishReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RelinquishReviewResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RelinquishReviewResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RelinquishReviewResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RelinquishReviewResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RelinquishReviewResponse} RelinquishReviewResponse
                 */
                RelinquishReviewResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RelinquishReviewResponse)
                        return object;
                    return new $root.infinitusai.be.RelinquishReviewResponse();
                };
    
                /**
                 * Creates a plain object from a RelinquishReviewResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {infinitusai.be.RelinquishReviewResponse} message RelinquishReviewResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RelinquishReviewResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RelinquishReviewResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RelinquishReviewResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RelinquishReviewResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RelinquishReviewResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RelinquishReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RelinquishReviewResponse";
                };
    
                return RelinquishReviewResponse;
            })();
    
            be.UnReviewTaskRequest = (function() {
    
                /**
                 * Properties of an UnReviewTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IUnReviewTaskRequest
                 * @property {string|null} [taskUuid] UnReviewTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new UnReviewTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UnReviewTaskRequest.
                 * @implements IUnReviewTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IUnReviewTaskRequest=} [properties] Properties to set
                 */
                function UnReviewTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UnReviewTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @instance
                 */
                UnReviewTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new UnReviewTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {infinitusai.be.IUnReviewTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UnReviewTaskRequest} UnReviewTaskRequest instance
                 */
                UnReviewTaskRequest.create = function create(properties) {
                    return new UnReviewTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified UnReviewTaskRequest message. Does not implicitly {@link infinitusai.be.UnReviewTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {infinitusai.be.IUnReviewTaskRequest} message UnReviewTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnReviewTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified UnReviewTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.UnReviewTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {infinitusai.be.IUnReviewTaskRequest} message UnReviewTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnReviewTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UnReviewTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UnReviewTaskRequest} UnReviewTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnReviewTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UnReviewTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UnReviewTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UnReviewTaskRequest} UnReviewTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnReviewTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UnReviewTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnReviewTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an UnReviewTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UnReviewTaskRequest} UnReviewTaskRequest
                 */
                UnReviewTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UnReviewTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.UnReviewTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UnReviewTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {infinitusai.be.UnReviewTaskRequest} message UnReviewTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnReviewTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this UnReviewTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnReviewTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UnReviewTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UnReviewTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UnReviewTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UnReviewTaskRequest";
                };
    
                return UnReviewTaskRequest;
            })();
    
            be.UnReviewTaskResponse = (function() {
    
                /**
                 * Properties of an UnReviewTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IUnReviewTaskResponse
                 */
    
                /**
                 * Constructs a new UnReviewTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UnReviewTaskResponse.
                 * @implements IUnReviewTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IUnReviewTaskResponse=} [properties] Properties to set
                 */
                function UnReviewTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UnReviewTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {infinitusai.be.IUnReviewTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UnReviewTaskResponse} UnReviewTaskResponse instance
                 */
                UnReviewTaskResponse.create = function create(properties) {
                    return new UnReviewTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified UnReviewTaskResponse message. Does not implicitly {@link infinitusai.be.UnReviewTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {infinitusai.be.IUnReviewTaskResponse} message UnReviewTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnReviewTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UnReviewTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.UnReviewTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {infinitusai.be.IUnReviewTaskResponse} message UnReviewTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnReviewTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UnReviewTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UnReviewTaskResponse} UnReviewTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnReviewTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UnReviewTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UnReviewTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UnReviewTaskResponse} UnReviewTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnReviewTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UnReviewTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnReviewTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UnReviewTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UnReviewTaskResponse} UnReviewTaskResponse
                 */
                UnReviewTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UnReviewTaskResponse)
                        return object;
                    return new $root.infinitusai.be.UnReviewTaskResponse();
                };
    
                /**
                 * Creates a plain object from an UnReviewTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {infinitusai.be.UnReviewTaskResponse} message UnReviewTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnReviewTaskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UnReviewTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnReviewTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UnReviewTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UnReviewTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UnReviewTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UnReviewTaskResponse";
                };
    
                return UnReviewTaskResponse;
            })();
    
            be.HangupRequest = (function() {
    
                /**
                 * Properties of a HangupRequest.
                 * @memberof infinitusai.be
                 * @interface IHangupRequest
                 * @property {string|null} [taskUuid] HangupRequest taskUuid
                 * @property {string|null} [callUuid] HangupRequest callUuid
                 */
    
                /**
                 * Constructs a new HangupRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a HangupRequest.
                 * @implements IHangupRequest
                 * @constructor
                 * @param {infinitusai.be.IHangupRequest=} [properties] Properties to set
                 */
                function HangupRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * HangupRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.HangupRequest
                 * @instance
                 */
                HangupRequest.prototype.taskUuid = "";
    
                /**
                 * HangupRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.HangupRequest
                 * @instance
                 */
                HangupRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new HangupRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {infinitusai.be.IHangupRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.HangupRequest} HangupRequest instance
                 */
                HangupRequest.create = function create(properties) {
                    return new HangupRequest(properties);
                };
    
                /**
                 * Encodes the specified HangupRequest message. Does not implicitly {@link infinitusai.be.HangupRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {infinitusai.be.IHangupRequest} message HangupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HangupRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified HangupRequest message, length delimited. Does not implicitly {@link infinitusai.be.HangupRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {infinitusai.be.IHangupRequest} message HangupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HangupRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a HangupRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.HangupRequest} HangupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HangupRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.HangupRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a HangupRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.HangupRequest} HangupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HangupRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a HangupRequest message.
                 * @function verify
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HangupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a HangupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.HangupRequest} HangupRequest
                 */
                HangupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.HangupRequest)
                        return object;
                    var message = new $root.infinitusai.be.HangupRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a HangupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {infinitusai.be.HangupRequest} message HangupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HangupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this HangupRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.HangupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HangupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for HangupRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.HangupRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                HangupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.HangupRequest";
                };
    
                return HangupRequest;
            })();
    
            be.HangupResponse = (function() {
    
                /**
                 * Properties of a HangupResponse.
                 * @memberof infinitusai.be
                 * @interface IHangupResponse
                 */
    
                /**
                 * Constructs a new HangupResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a HangupResponse.
                 * @implements IHangupResponse
                 * @constructor
                 * @param {infinitusai.be.IHangupResponse=} [properties] Properties to set
                 */
                function HangupResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new HangupResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {infinitusai.be.IHangupResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.HangupResponse} HangupResponse instance
                 */
                HangupResponse.create = function create(properties) {
                    return new HangupResponse(properties);
                };
    
                /**
                 * Encodes the specified HangupResponse message. Does not implicitly {@link infinitusai.be.HangupResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {infinitusai.be.IHangupResponse} message HangupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HangupResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified HangupResponse message, length delimited. Does not implicitly {@link infinitusai.be.HangupResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {infinitusai.be.IHangupResponse} message HangupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HangupResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a HangupResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.HangupResponse} HangupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HangupResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.HangupResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a HangupResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.HangupResponse} HangupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HangupResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a HangupResponse message.
                 * @function verify
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HangupResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a HangupResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.HangupResponse} HangupResponse
                 */
                HangupResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.HangupResponse)
                        return object;
                    return new $root.infinitusai.be.HangupResponse();
                };
    
                /**
                 * Creates a plain object from a HangupResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {infinitusai.be.HangupResponse} message HangupResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HangupResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this HangupResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.HangupResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HangupResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for HangupResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.HangupResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                HangupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.HangupResponse";
                };
    
                return HangupResponse;
            })();
    
            be.IsCallCompletedRequest = (function() {
    
                /**
                 * Properties of an IsCallCompletedRequest.
                 * @memberof infinitusai.be
                 * @interface IIsCallCompletedRequest
                 * @property {string|null} [taskUuid] IsCallCompletedRequest taskUuid
                 * @property {string|null} [callUuid] IsCallCompletedRequest callUuid
                 */
    
                /**
                 * Constructs a new IsCallCompletedRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an IsCallCompletedRequest.
                 * @implements IIsCallCompletedRequest
                 * @constructor
                 * @param {infinitusai.be.IIsCallCompletedRequest=} [properties] Properties to set
                 */
                function IsCallCompletedRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IsCallCompletedRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @instance
                 */
                IsCallCompletedRequest.prototype.taskUuid = "";
    
                /**
                 * IsCallCompletedRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @instance
                 */
                IsCallCompletedRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new IsCallCompletedRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {infinitusai.be.IIsCallCompletedRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.IsCallCompletedRequest} IsCallCompletedRequest instance
                 */
                IsCallCompletedRequest.create = function create(properties) {
                    return new IsCallCompletedRequest(properties);
                };
    
                /**
                 * Encodes the specified IsCallCompletedRequest message. Does not implicitly {@link infinitusai.be.IsCallCompletedRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {infinitusai.be.IIsCallCompletedRequest} message IsCallCompletedRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IsCallCompletedRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified IsCallCompletedRequest message, length delimited. Does not implicitly {@link infinitusai.be.IsCallCompletedRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {infinitusai.be.IIsCallCompletedRequest} message IsCallCompletedRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IsCallCompletedRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IsCallCompletedRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IsCallCompletedRequest} IsCallCompletedRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IsCallCompletedRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IsCallCompletedRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IsCallCompletedRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IsCallCompletedRequest} IsCallCompletedRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IsCallCompletedRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IsCallCompletedRequest message.
                 * @function verify
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IsCallCompletedRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an IsCallCompletedRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IsCallCompletedRequest} IsCallCompletedRequest
                 */
                IsCallCompletedRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IsCallCompletedRequest)
                        return object;
                    var message = new $root.infinitusai.be.IsCallCompletedRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an IsCallCompletedRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {infinitusai.be.IsCallCompletedRequest} message IsCallCompletedRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IsCallCompletedRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this IsCallCompletedRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IsCallCompletedRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IsCallCompletedRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IsCallCompletedRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IsCallCompletedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IsCallCompletedRequest";
                };
    
                return IsCallCompletedRequest;
            })();
    
            be.IsCallCompletedResponse = (function() {
    
                /**
                 * Properties of an IsCallCompletedResponse.
                 * @memberof infinitusai.be
                 * @interface IIsCallCompletedResponse
                 * @property {boolean|null} [callCompleted] IsCallCompletedResponse callCompleted
                 */
    
                /**
                 * Constructs a new IsCallCompletedResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an IsCallCompletedResponse.
                 * @implements IIsCallCompletedResponse
                 * @constructor
                 * @param {infinitusai.be.IIsCallCompletedResponse=} [properties] Properties to set
                 */
                function IsCallCompletedResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IsCallCompletedResponse callCompleted.
                 * @member {boolean} callCompleted
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @instance
                 */
                IsCallCompletedResponse.prototype.callCompleted = false;
    
                /**
                 * Creates a new IsCallCompletedResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {infinitusai.be.IIsCallCompletedResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.IsCallCompletedResponse} IsCallCompletedResponse instance
                 */
                IsCallCompletedResponse.create = function create(properties) {
                    return new IsCallCompletedResponse(properties);
                };
    
                /**
                 * Encodes the specified IsCallCompletedResponse message. Does not implicitly {@link infinitusai.be.IsCallCompletedResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {infinitusai.be.IIsCallCompletedResponse} message IsCallCompletedResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IsCallCompletedResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callCompleted != null && Object.hasOwnProperty.call(message, "callCompleted"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.callCompleted);
                    return writer;
                };
    
                /**
                 * Encodes the specified IsCallCompletedResponse message, length delimited. Does not implicitly {@link infinitusai.be.IsCallCompletedResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {infinitusai.be.IIsCallCompletedResponse} message IsCallCompletedResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IsCallCompletedResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IsCallCompletedResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IsCallCompletedResponse} IsCallCompletedResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IsCallCompletedResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IsCallCompletedResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callCompleted = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IsCallCompletedResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IsCallCompletedResponse} IsCallCompletedResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IsCallCompletedResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IsCallCompletedResponse message.
                 * @function verify
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IsCallCompletedResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callCompleted != null && message.hasOwnProperty("callCompleted"))
                        if (typeof message.callCompleted !== "boolean")
                            return "callCompleted: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an IsCallCompletedResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IsCallCompletedResponse} IsCallCompletedResponse
                 */
                IsCallCompletedResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IsCallCompletedResponse)
                        return object;
                    var message = new $root.infinitusai.be.IsCallCompletedResponse();
                    if (object.callCompleted != null)
                        message.callCompleted = Boolean(object.callCompleted);
                    return message;
                };
    
                /**
                 * Creates a plain object from an IsCallCompletedResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {infinitusai.be.IsCallCompletedResponse} message IsCallCompletedResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IsCallCompletedResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.callCompleted = false;
                    if (message.callCompleted != null && message.hasOwnProperty("callCompleted"))
                        object.callCompleted = message.callCompleted;
                    return object;
                };
    
                /**
                 * Converts this IsCallCompletedResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IsCallCompletedResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IsCallCompletedResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IsCallCompletedResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IsCallCompletedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IsCallCompletedResponse";
                };
    
                return IsCallCompletedResponse;
            })();
    
            be.ProcessTaskRequest = (function() {
    
                /**
                 * Properties of a ProcessTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IProcessTaskRequest
                 * @property {string|null} [taskUuid] ProcessTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new ProcessTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProcessTaskRequest.
                 * @implements IProcessTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IProcessTaskRequest=} [properties] Properties to set
                 */
                function ProcessTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProcessTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @instance
                 */
                ProcessTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new ProcessTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {infinitusai.be.IProcessTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ProcessTaskRequest} ProcessTaskRequest instance
                 */
                ProcessTaskRequest.create = function create(properties) {
                    return new ProcessTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified ProcessTaskRequest message. Does not implicitly {@link infinitusai.be.ProcessTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {infinitusai.be.IProcessTaskRequest} message ProcessTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProcessTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.ProcessTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {infinitusai.be.IProcessTaskRequest} message ProcessTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProcessTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProcessTaskRequest} ProcessTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProcessTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProcessTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProcessTaskRequest} ProcessTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProcessTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProcessTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a ProcessTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProcessTaskRequest} ProcessTaskRequest
                 */
                ProcessTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProcessTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.ProcessTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProcessTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {infinitusai.be.ProcessTaskRequest} message ProcessTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProcessTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this ProcessTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProcessTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProcessTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProcessTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProcessTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProcessTaskRequest";
                };
    
                return ProcessTaskRequest;
            })();
    
            be.ProcessTaskResponse = (function() {
    
                /**
                 * Properties of a ProcessTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IProcessTaskResponse
                 */
    
                /**
                 * Constructs a new ProcessTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProcessTaskResponse.
                 * @implements IProcessTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IProcessTaskResponse=} [properties] Properties to set
                 */
                function ProcessTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ProcessTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {infinitusai.be.IProcessTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ProcessTaskResponse} ProcessTaskResponse instance
                 */
                ProcessTaskResponse.create = function create(properties) {
                    return new ProcessTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified ProcessTaskResponse message. Does not implicitly {@link infinitusai.be.ProcessTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {infinitusai.be.IProcessTaskResponse} message ProcessTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ProcessTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.ProcessTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {infinitusai.be.IProcessTaskResponse} message ProcessTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcessTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProcessTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProcessTaskResponse} ProcessTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProcessTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProcessTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProcessTaskResponse} ProcessTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcessTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProcessTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProcessTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ProcessTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProcessTaskResponse} ProcessTaskResponse
                 */
                ProcessTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProcessTaskResponse)
                        return object;
                    return new $root.infinitusai.be.ProcessTaskResponse();
                };
    
                /**
                 * Creates a plain object from a ProcessTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {infinitusai.be.ProcessTaskResponse} message ProcessTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProcessTaskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ProcessTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProcessTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProcessTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProcessTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProcessTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProcessTaskResponse";
                };
    
                return ProcessTaskResponse;
            })();
    
            be.GetTaskRequest = (function() {
    
                /**
                 * Properties of a GetTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IGetTaskRequest
                 * @property {string|null} [taskUuid] GetTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new GetTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTaskRequest.
                 * @implements IGetTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IGetTaskRequest=} [properties] Properties to set
                 */
                function GetTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetTaskRequest
                 * @instance
                 */
                GetTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new GetTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTaskRequest} GetTaskRequest instance
                 */
                GetTaskRequest.create = function create(properties) {
                    return new GetTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTaskRequest message. Does not implicitly {@link infinitusai.be.GetTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskRequest} message GetTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskRequest} message GetTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTaskRequest} GetTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTaskRequest} GetTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTaskRequest} GetTaskRequest
                 */
                GetTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {infinitusai.be.GetTaskRequest} message GetTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this GetTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTaskRequest";
                };
    
                return GetTaskRequest;
            })();
    
            be.GetTaskResponse = (function() {
    
                /**
                 * Properties of a GetTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IGetTaskResponse
                 * @property {infinitusai.be.ITaskDoc|null} [task] GetTaskResponse task
                 * @property {infinitusai.be.ICustomerTaskDoc|null} [customerTask] GetTaskResponse customerTask
                 * @property {Array.<infinitusai.be.ITaskDoc>|null} [tasks] GetTaskResponse tasks
                 * @property {Array.<infinitusai.be.GetTaskResponse.IPreviousOutputs>|null} [previousOutputs] GetTaskResponse previousOutputs
                 * @property {Array.<infinitusapi.IINFTaskInput>|null} [taskInputs] GetTaskResponse taskInputs
                 */
    
                /**
                 * Constructs a new GetTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTaskResponse.
                 * @implements IGetTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IGetTaskResponse=} [properties] Properties to set
                 */
                function GetTaskResponse(properties) {
                    this.tasks = [];
                    this.previousOutputs = [];
                    this.taskInputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTaskResponse task.
                 * @member {infinitusai.be.ITaskDoc|null|undefined} task
                 * @memberof infinitusai.be.GetTaskResponse
                 * @instance
                 */
                GetTaskResponse.prototype.task = null;
    
                /**
                 * GetTaskResponse customerTask.
                 * @member {infinitusai.be.ICustomerTaskDoc|null|undefined} customerTask
                 * @memberof infinitusai.be.GetTaskResponse
                 * @instance
                 */
                GetTaskResponse.prototype.customerTask = null;
    
                /**
                 * GetTaskResponse tasks.
                 * @member {Array.<infinitusai.be.ITaskDoc>} tasks
                 * @memberof infinitusai.be.GetTaskResponse
                 * @instance
                 */
                GetTaskResponse.prototype.tasks = $util.emptyArray;
    
                /**
                 * GetTaskResponse previousOutputs.
                 * @member {Array.<infinitusai.be.GetTaskResponse.IPreviousOutputs>} previousOutputs
                 * @memberof infinitusai.be.GetTaskResponse
                 * @instance
                 */
                GetTaskResponse.prototype.previousOutputs = $util.emptyArray;
    
                /**
                 * GetTaskResponse taskInputs.
                 * @member {Array.<infinitusapi.IINFTaskInput>} taskInputs
                 * @memberof infinitusai.be.GetTaskResponse
                 * @instance
                 */
                GetTaskResponse.prototype.taskInputs = $util.emptyArray;
    
                /**
                 * Creates a new GetTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTaskResponse} GetTaskResponse instance
                 */
                GetTaskResponse.create = function create(properties) {
                    return new GetTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified GetTaskResponse message. Does not implicitly {@link infinitusai.be.GetTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskResponse} message GetTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                        $root.infinitusai.be.TaskDoc.encode(message.task, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.customerTask != null && Object.hasOwnProperty.call(message, "customerTask"))
                        $root.infinitusai.be.CustomerTaskDoc.encode(message.customerTask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.tasks != null && message.tasks.length)
                        for (var i = 0; i < message.tasks.length; ++i)
                            $root.infinitusai.be.TaskDoc.encode(message.tasks[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.previousOutputs != null && message.previousOutputs.length)
                        for (var i = 0; i < message.previousOutputs.length; ++i)
                            $root.infinitusai.be.GetTaskResponse.PreviousOutputs.encode(message.previousOutputs[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.taskInputs != null && message.taskInputs.length)
                        for (var i = 0; i < message.taskInputs.length; ++i)
                            $root.infinitusapi.INFTaskInput.encode(message.taskInputs[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskResponse} message GetTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTaskResponse} GetTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.task = $root.infinitusai.be.TaskDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.customerTask = $root.infinitusai.be.CustomerTaskDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.infinitusai.be.TaskDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.previousOutputs && message.previousOutputs.length))
                                    message.previousOutputs = [];
                                message.previousOutputs.push($root.infinitusai.be.GetTaskResponse.PreviousOutputs.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(message.taskInputs && message.taskInputs.length))
                                    message.taskInputs = [];
                                message.taskInputs.push($root.infinitusapi.INFTaskInput.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTaskResponse} GetTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.task != null && message.hasOwnProperty("task")) {
                        var error = $root.infinitusai.be.TaskDoc.verify(message.task);
                        if (error)
                            return "task." + error;
                    }
                    if (message.customerTask != null && message.hasOwnProperty("customerTask")) {
                        var error = $root.infinitusai.be.CustomerTaskDoc.verify(message.customerTask);
                        if (error)
                            return "customerTask." + error;
                    }
                    if (message.tasks != null && message.hasOwnProperty("tasks")) {
                        if (!Array.isArray(message.tasks))
                            return "tasks: array expected";
                        for (var i = 0; i < message.tasks.length; ++i) {
                            var error = $root.infinitusai.be.TaskDoc.verify(message.tasks[i]);
                            if (error)
                                return "tasks." + error;
                        }
                    }
                    if (message.previousOutputs != null && message.hasOwnProperty("previousOutputs")) {
                        if (!Array.isArray(message.previousOutputs))
                            return "previousOutputs: array expected";
                        for (var i = 0; i < message.previousOutputs.length; ++i) {
                            var error = $root.infinitusai.be.GetTaskResponse.PreviousOutputs.verify(message.previousOutputs[i]);
                            if (error)
                                return "previousOutputs." + error;
                        }
                    }
                    if (message.taskInputs != null && message.hasOwnProperty("taskInputs")) {
                        if (!Array.isArray(message.taskInputs))
                            return "taskInputs: array expected";
                        for (var i = 0; i < message.taskInputs.length; ++i) {
                            var error = $root.infinitusapi.INFTaskInput.verify(message.taskInputs[i]);
                            if (error)
                                return "taskInputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTaskResponse} GetTaskResponse
                 */
                GetTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetTaskResponse();
                    if (object.task != null) {
                        if (typeof object.task !== "object")
                            throw TypeError(".infinitusai.be.GetTaskResponse.task: object expected");
                        message.task = $root.infinitusai.be.TaskDoc.fromObject(object.task);
                    }
                    if (object.customerTask != null) {
                        if (typeof object.customerTask !== "object")
                            throw TypeError(".infinitusai.be.GetTaskResponse.customerTask: object expected");
                        message.customerTask = $root.infinitusai.be.CustomerTaskDoc.fromObject(object.customerTask);
                    }
                    if (object.tasks) {
                        if (!Array.isArray(object.tasks))
                            throw TypeError(".infinitusai.be.GetTaskResponse.tasks: array expected");
                        message.tasks = [];
                        for (var i = 0; i < object.tasks.length; ++i) {
                            if (typeof object.tasks[i] !== "object")
                                throw TypeError(".infinitusai.be.GetTaskResponse.tasks: object expected");
                            message.tasks[i] = $root.infinitusai.be.TaskDoc.fromObject(object.tasks[i]);
                        }
                    }
                    if (object.previousOutputs) {
                        if (!Array.isArray(object.previousOutputs))
                            throw TypeError(".infinitusai.be.GetTaskResponse.previousOutputs: array expected");
                        message.previousOutputs = [];
                        for (var i = 0; i < object.previousOutputs.length; ++i) {
                            if (typeof object.previousOutputs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetTaskResponse.previousOutputs: object expected");
                            message.previousOutputs[i] = $root.infinitusai.be.GetTaskResponse.PreviousOutputs.fromObject(object.previousOutputs[i]);
                        }
                    }
                    if (object.taskInputs) {
                        if (!Array.isArray(object.taskInputs))
                            throw TypeError(".infinitusai.be.GetTaskResponse.taskInputs: array expected");
                        message.taskInputs = [];
                        for (var i = 0; i < object.taskInputs.length; ++i) {
                            if (typeof object.taskInputs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetTaskResponse.taskInputs: object expected");
                            message.taskInputs[i] = $root.infinitusapi.INFTaskInput.fromObject(object.taskInputs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {infinitusai.be.GetTaskResponse} message GetTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.tasks = [];
                        object.previousOutputs = [];
                        object.taskInputs = [];
                    }
                    if (options.defaults) {
                        object.task = null;
                        object.customerTask = null;
                    }
                    if (message.task != null && message.hasOwnProperty("task"))
                        object.task = $root.infinitusai.be.TaskDoc.toObject(message.task, options);
                    if (message.customerTask != null && message.hasOwnProperty("customerTask"))
                        object.customerTask = $root.infinitusai.be.CustomerTaskDoc.toObject(message.customerTask, options);
                    if (message.tasks && message.tasks.length) {
                        object.tasks = [];
                        for (var j = 0; j < message.tasks.length; ++j)
                            object.tasks[j] = $root.infinitusai.be.TaskDoc.toObject(message.tasks[j], options);
                    }
                    if (message.previousOutputs && message.previousOutputs.length) {
                        object.previousOutputs = [];
                        for (var j = 0; j < message.previousOutputs.length; ++j)
                            object.previousOutputs[j] = $root.infinitusai.be.GetTaskResponse.PreviousOutputs.toObject(message.previousOutputs[j], options);
                    }
                    if (message.taskInputs && message.taskInputs.length) {
                        object.taskInputs = [];
                        for (var j = 0; j < message.taskInputs.length; ++j)
                            object.taskInputs[j] = $root.infinitusapi.INFTaskInput.toObject(message.taskInputs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTaskResponse";
                };
    
                GetTaskResponse.PreviousOutputs = (function() {
    
                    /**
                     * Properties of a PreviousOutputs.
                     * @memberof infinitusai.be.GetTaskResponse
                     * @interface IPreviousOutputs
                     * @property {string|null} [taskId] PreviousOutputs taskId
                     * @property {infinitusapi.INFTaskType|null} [taskType] PreviousOutputs taskType
                     * @property {infinitusapi.IINFBVTaskOutput|null} [bvTaskOutput] PreviousOutputs bvTaskOutput
                     * @property {infinitusapi.IINFPBMTaskOutput|null} [pbmTaskOutput] PreviousOutputs pbmTaskOutput
                     */
    
                    /**
                     * Constructs a new PreviousOutputs.
                     * @memberof infinitusai.be.GetTaskResponse
                     * @classdesc Represents a PreviousOutputs.
                     * @implements IPreviousOutputs
                     * @constructor
                     * @param {infinitusai.be.GetTaskResponse.IPreviousOutputs=} [properties] Properties to set
                     */
                    function PreviousOutputs(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PreviousOutputs taskId.
                     * @member {string} taskId
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @instance
                     */
                    PreviousOutputs.prototype.taskId = "";
    
                    /**
                     * PreviousOutputs taskType.
                     * @member {infinitusapi.INFTaskType} taskType
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @instance
                     */
                    PreviousOutputs.prototype.taskType = 0;
    
                    /**
                     * PreviousOutputs bvTaskOutput.
                     * @member {infinitusapi.IINFBVTaskOutput|null|undefined} bvTaskOutput
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @instance
                     */
                    PreviousOutputs.prototype.bvTaskOutput = null;
    
                    /**
                     * PreviousOutputs pbmTaskOutput.
                     * @member {infinitusapi.IINFPBMTaskOutput|null|undefined} pbmTaskOutput
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @instance
                     */
                    PreviousOutputs.prototype.pbmTaskOutput = null;
    
                    /**
                     * Creates a new PreviousOutputs instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {infinitusai.be.GetTaskResponse.IPreviousOutputs=} [properties] Properties to set
                     * @returns {infinitusai.be.GetTaskResponse.PreviousOutputs} PreviousOutputs instance
                     */
                    PreviousOutputs.create = function create(properties) {
                        return new PreviousOutputs(properties);
                    };
    
                    /**
                     * Encodes the specified PreviousOutputs message. Does not implicitly {@link infinitusai.be.GetTaskResponse.PreviousOutputs.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {infinitusai.be.GetTaskResponse.IPreviousOutputs} message PreviousOutputs message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreviousOutputs.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskId);
                        if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                        if (message.bvTaskOutput != null && Object.hasOwnProperty.call(message, "bvTaskOutput"))
                            $root.infinitusapi.INFBVTaskOutput.encode(message.bvTaskOutput, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.pbmTaskOutput != null && Object.hasOwnProperty.call(message, "pbmTaskOutput"))
                            $root.infinitusapi.INFPBMTaskOutput.encode(message.pbmTaskOutput, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PreviousOutputs message, length delimited. Does not implicitly {@link infinitusai.be.GetTaskResponse.PreviousOutputs.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {infinitusai.be.GetTaskResponse.IPreviousOutputs} message PreviousOutputs message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreviousOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PreviousOutputs message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.GetTaskResponse.PreviousOutputs} PreviousOutputs
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreviousOutputs.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTaskResponse.PreviousOutputs();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.taskId = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.taskType = reader.int32();
                                    break;
                                }
                            case 3: {
                                    message.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.decode(reader, reader.uint32());
                                    break;
                                }
                            case 4: {
                                    message.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PreviousOutputs message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.GetTaskResponse.PreviousOutputs} PreviousOutputs
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreviousOutputs.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PreviousOutputs message.
                     * @function verify
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PreviousOutputs.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            if (!$util.isString(message.taskId))
                                return "taskId: string expected";
                        if (message.taskType != null && message.hasOwnProperty("taskType"))
                            switch (message.taskType) {
                            default:
                                return "taskType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                                break;
                            }
                        if (message.bvTaskOutput != null && message.hasOwnProperty("bvTaskOutput")) {
                            var error = $root.infinitusapi.INFBVTaskOutput.verify(message.bvTaskOutput);
                            if (error)
                                return "bvTaskOutput." + error;
                        }
                        if (message.pbmTaskOutput != null && message.hasOwnProperty("pbmTaskOutput")) {
                            var error = $root.infinitusapi.INFPBMTaskOutput.verify(message.pbmTaskOutput);
                            if (error)
                                return "pbmTaskOutput." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PreviousOutputs message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.GetTaskResponse.PreviousOutputs} PreviousOutputs
                     */
                    PreviousOutputs.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.GetTaskResponse.PreviousOutputs)
                            return object;
                        var message = new $root.infinitusai.be.GetTaskResponse.PreviousOutputs();
                        if (object.taskId != null)
                            message.taskId = String(object.taskId);
                        switch (object.taskType) {
                        default:
                            if (typeof object.taskType === "number") {
                                message.taskType = object.taskType;
                                break;
                            }
                            break;
                        case "INF_TASK_TYPE_UNKNOWN":
                        case 0:
                            message.taskType = 0;
                            break;
                        case "INF_TASK_TYPE_BENEFITS_VERIFICATION":
                        case 1:
                            message.taskType = 1;
                            break;
                        case "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER":
                        case 2:
                            message.taskType = 2;
                            break;
                        case "INF_TASK_TYPE_PRESCRIPTION_TRANSFER":
                        case 3:
                            message.taskType = 3;
                            break;
                        case "INF_TASK_TYPE_PRESCRIPTION_SAVINGS":
                        case 4:
                            message.taskType = 4;
                            break;
                        case "INF_TASK_TYPE_CLAIMS_FOLLOWUP":
                        case 5:
                            message.taskType = 5;
                            break;
                        case "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                        case 6:
                            message.taskType = 6;
                            break;
                        case "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION":
                        case 7:
                            message.taskType = 7;
                            break;
                        case "INF_TASK_TYPE_SHIPMENT_FOLLOWUP":
                        case 8:
                            message.taskType = 8;
                            break;
                        case "INF_TASK_TYPE_PATIENT_OUTREACH":
                        case 9:
                            message.taskType = 9;
                            break;
                        case "INF_TASK_TYPE_PROVIDER_OUTREACH":
                        case 10:
                            message.taskType = 10;
                            break;
                        case "INF_TASK_TYPE_FASTTRACK_MM":
                        case 11:
                            message.taskType = 11;
                            break;
                        case "INF_TASK_TYPE_FASTTRACK_PBM":
                        case 12:
                            message.taskType = 12;
                            break;
                        case "INF_TASK_TYPE_PHARMACY_STOCK_CHECK":
                        case 13:
                            message.taskType = 13;
                            break;
                        case "INF_TASK_TYPE_BASIC_BV":
                        case 14:
                            message.taskType = 14;
                            break;
                        case "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                        case 15:
                            message.taskType = 15;
                            break;
                        case "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                        case 16:
                            message.taskType = 16;
                            break;
                        case "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                        case 17:
                            message.taskType = 17;
                            break;
                        case "INF_TASK_TYPE_PBM_DISCOVERY":
                        case 18:
                            message.taskType = 18;
                            break;
                        }
                        if (object.bvTaskOutput != null) {
                            if (typeof object.bvTaskOutput !== "object")
                                throw TypeError(".infinitusai.be.GetTaskResponse.PreviousOutputs.bvTaskOutput: object expected");
                            message.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.fromObject(object.bvTaskOutput);
                        }
                        if (object.pbmTaskOutput != null) {
                            if (typeof object.pbmTaskOutput !== "object")
                                throw TypeError(".infinitusai.be.GetTaskResponse.PreviousOutputs.pbmTaskOutput: object expected");
                            message.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.fromObject(object.pbmTaskOutput);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PreviousOutputs message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {infinitusai.be.GetTaskResponse.PreviousOutputs} message PreviousOutputs
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PreviousOutputs.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.taskId = "";
                            object.taskType = options.enums === String ? "INF_TASK_TYPE_UNKNOWN" : 0;
                            object.bvTaskOutput = null;
                            object.pbmTaskOutput = null;
                        }
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            object.taskId = message.taskId;
                        if (message.taskType != null && message.hasOwnProperty("taskType"))
                            object.taskType = options.enums === String ? $root.infinitusapi.INFTaskType[message.taskType] === undefined ? message.taskType : $root.infinitusapi.INFTaskType[message.taskType] : message.taskType;
                        if (message.bvTaskOutput != null && message.hasOwnProperty("bvTaskOutput"))
                            object.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.toObject(message.bvTaskOutput, options);
                        if (message.pbmTaskOutput != null && message.hasOwnProperty("pbmTaskOutput"))
                            object.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.toObject(message.pbmTaskOutput, options);
                        return object;
                    };
    
                    /**
                     * Converts this PreviousOutputs to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PreviousOutputs.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for PreviousOutputs
                     * @function getTypeUrl
                     * @memberof infinitusai.be.GetTaskResponse.PreviousOutputs
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    PreviousOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.GetTaskResponse.PreviousOutputs";
                    };
    
                    return PreviousOutputs;
                })();
    
                return GetTaskResponse;
            })();
    
            be.GetTaskResultsRequest = (function() {
    
                /**
                 * Properties of a GetTaskResultsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetTaskResultsRequest
                 * @property {Array.<string>|null} [taskUuids] GetTaskResultsRequest taskUuids
                 */
    
                /**
                 * Constructs a new GetTaskResultsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTaskResultsRequest.
                 * @implements IGetTaskResultsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetTaskResultsRequest=} [properties] Properties to set
                 */
                function GetTaskResultsRequest(properties) {
                    this.taskUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTaskResultsRequest taskUuids.
                 * @member {Array.<string>} taskUuids
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @instance
                 */
                GetTaskResultsRequest.prototype.taskUuids = $util.emptyArray;
    
                /**
                 * Creates a new GetTaskResultsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskResultsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTaskResultsRequest} GetTaskResultsRequest instance
                 */
                GetTaskResultsRequest.create = function create(properties) {
                    return new GetTaskResultsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTaskResultsRequest message. Does not implicitly {@link infinitusai.be.GetTaskResultsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskResultsRequest} message GetTaskResultsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskResultsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuids != null && message.taskUuids.length)
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTaskResultsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetTaskResultsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskResultsRequest} message GetTaskResultsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskResultsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTaskResultsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTaskResultsRequest} GetTaskResultsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskResultsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTaskResultsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.taskUuids && message.taskUuids.length))
                                    message.taskUuids = [];
                                message.taskUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTaskResultsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTaskResultsRequest} GetTaskResultsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskResultsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTaskResultsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTaskResultsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuids != null && message.hasOwnProperty("taskUuids")) {
                        if (!Array.isArray(message.taskUuids))
                            return "taskUuids: array expected";
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            if (!$util.isString(message.taskUuids[i]))
                                return "taskUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetTaskResultsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTaskResultsRequest} GetTaskResultsRequest
                 */
                GetTaskResultsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTaskResultsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetTaskResultsRequest();
                    if (object.taskUuids) {
                        if (!Array.isArray(object.taskUuids))
                            throw TypeError(".infinitusai.be.GetTaskResultsRequest.taskUuids: array expected");
                        message.taskUuids = [];
                        for (var i = 0; i < object.taskUuids.length; ++i)
                            message.taskUuids[i] = String(object.taskUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTaskResultsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {infinitusai.be.GetTaskResultsRequest} message GetTaskResultsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTaskResultsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.taskUuids = [];
                    if (message.taskUuids && message.taskUuids.length) {
                        object.taskUuids = [];
                        for (var j = 0; j < message.taskUuids.length; ++j)
                            object.taskUuids[j] = message.taskUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetTaskResultsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTaskResultsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTaskResultsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTaskResultsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTaskResultsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTaskResultsRequest";
                };
    
                return GetTaskResultsRequest;
            })();
    
            be.TriggerTaskReviewedWebhookRequest = (function() {
    
                /**
                 * Properties of a TriggerTaskReviewedWebhookRequest.
                 * @memberof infinitusai.be
                 * @interface ITriggerTaskReviewedWebhookRequest
                 * @property {string|null} [taskUuid] TriggerTaskReviewedWebhookRequest taskUuid
                 */
    
                /**
                 * Constructs a new TriggerTaskReviewedWebhookRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TriggerTaskReviewedWebhookRequest.
                 * @implements ITriggerTaskReviewedWebhookRequest
                 * @constructor
                 * @param {infinitusai.be.ITriggerTaskReviewedWebhookRequest=} [properties] Properties to set
                 */
                function TriggerTaskReviewedWebhookRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TriggerTaskReviewedWebhookRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @instance
                 */
                TriggerTaskReviewedWebhookRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new TriggerTaskReviewedWebhookRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {infinitusai.be.ITriggerTaskReviewedWebhookRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.TriggerTaskReviewedWebhookRequest} TriggerTaskReviewedWebhookRequest instance
                 */
                TriggerTaskReviewedWebhookRequest.create = function create(properties) {
                    return new TriggerTaskReviewedWebhookRequest(properties);
                };
    
                /**
                 * Encodes the specified TriggerTaskReviewedWebhookRequest message. Does not implicitly {@link infinitusai.be.TriggerTaskReviewedWebhookRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {infinitusai.be.ITriggerTaskReviewedWebhookRequest} message TriggerTaskReviewedWebhookRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TriggerTaskReviewedWebhookRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified TriggerTaskReviewedWebhookRequest message, length delimited. Does not implicitly {@link infinitusai.be.TriggerTaskReviewedWebhookRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {infinitusai.be.ITriggerTaskReviewedWebhookRequest} message TriggerTaskReviewedWebhookRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TriggerTaskReviewedWebhookRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TriggerTaskReviewedWebhookRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TriggerTaskReviewedWebhookRequest} TriggerTaskReviewedWebhookRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TriggerTaskReviewedWebhookRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TriggerTaskReviewedWebhookRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TriggerTaskReviewedWebhookRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TriggerTaskReviewedWebhookRequest} TriggerTaskReviewedWebhookRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TriggerTaskReviewedWebhookRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TriggerTaskReviewedWebhookRequest message.
                 * @function verify
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TriggerTaskReviewedWebhookRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a TriggerTaskReviewedWebhookRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TriggerTaskReviewedWebhookRequest} TriggerTaskReviewedWebhookRequest
                 */
                TriggerTaskReviewedWebhookRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TriggerTaskReviewedWebhookRequest)
                        return object;
                    var message = new $root.infinitusai.be.TriggerTaskReviewedWebhookRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TriggerTaskReviewedWebhookRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {infinitusai.be.TriggerTaskReviewedWebhookRequest} message TriggerTaskReviewedWebhookRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TriggerTaskReviewedWebhookRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this TriggerTaskReviewedWebhookRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TriggerTaskReviewedWebhookRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TriggerTaskReviewedWebhookRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TriggerTaskReviewedWebhookRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TriggerTaskReviewedWebhookRequest";
                };
    
                return TriggerTaskReviewedWebhookRequest;
            })();
    
            be.TriggerTaskReviewedWebhookResponse = (function() {
    
                /**
                 * Properties of a TriggerTaskReviewedWebhookResponse.
                 * @memberof infinitusai.be
                 * @interface ITriggerTaskReviewedWebhookResponse
                 * @property {infinitusai.be.IWebhookDeliveryAttempt|null} [deliveryAttempt] TriggerTaskReviewedWebhookResponse deliveryAttempt
                 */
    
                /**
                 * Constructs a new TriggerTaskReviewedWebhookResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TriggerTaskReviewedWebhookResponse.
                 * @implements ITriggerTaskReviewedWebhookResponse
                 * @constructor
                 * @param {infinitusai.be.ITriggerTaskReviewedWebhookResponse=} [properties] Properties to set
                 */
                function TriggerTaskReviewedWebhookResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TriggerTaskReviewedWebhookResponse deliveryAttempt.
                 * @member {infinitusai.be.IWebhookDeliveryAttempt|null|undefined} deliveryAttempt
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @instance
                 */
                TriggerTaskReviewedWebhookResponse.prototype.deliveryAttempt = null;
    
                /**
                 * Creates a new TriggerTaskReviewedWebhookResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {infinitusai.be.ITriggerTaskReviewedWebhookResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.TriggerTaskReviewedWebhookResponse} TriggerTaskReviewedWebhookResponse instance
                 */
                TriggerTaskReviewedWebhookResponse.create = function create(properties) {
                    return new TriggerTaskReviewedWebhookResponse(properties);
                };
    
                /**
                 * Encodes the specified TriggerTaskReviewedWebhookResponse message. Does not implicitly {@link infinitusai.be.TriggerTaskReviewedWebhookResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {infinitusai.be.ITriggerTaskReviewedWebhookResponse} message TriggerTaskReviewedWebhookResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TriggerTaskReviewedWebhookResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deliveryAttempt != null && Object.hasOwnProperty.call(message, "deliveryAttempt"))
                        $root.infinitusai.be.WebhookDeliveryAttempt.encode(message.deliveryAttempt, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified TriggerTaskReviewedWebhookResponse message, length delimited. Does not implicitly {@link infinitusai.be.TriggerTaskReviewedWebhookResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {infinitusai.be.ITriggerTaskReviewedWebhookResponse} message TriggerTaskReviewedWebhookResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TriggerTaskReviewedWebhookResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TriggerTaskReviewedWebhookResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TriggerTaskReviewedWebhookResponse} TriggerTaskReviewedWebhookResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TriggerTaskReviewedWebhookResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TriggerTaskReviewedWebhookResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deliveryAttempt = $root.infinitusai.be.WebhookDeliveryAttempt.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TriggerTaskReviewedWebhookResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TriggerTaskReviewedWebhookResponse} TriggerTaskReviewedWebhookResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TriggerTaskReviewedWebhookResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TriggerTaskReviewedWebhookResponse message.
                 * @function verify
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TriggerTaskReviewedWebhookResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deliveryAttempt != null && message.hasOwnProperty("deliveryAttempt")) {
                        var error = $root.infinitusai.be.WebhookDeliveryAttempt.verify(message.deliveryAttempt);
                        if (error)
                            return "deliveryAttempt." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a TriggerTaskReviewedWebhookResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TriggerTaskReviewedWebhookResponse} TriggerTaskReviewedWebhookResponse
                 */
                TriggerTaskReviewedWebhookResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TriggerTaskReviewedWebhookResponse)
                        return object;
                    var message = new $root.infinitusai.be.TriggerTaskReviewedWebhookResponse();
                    if (object.deliveryAttempt != null) {
                        if (typeof object.deliveryAttempt !== "object")
                            throw TypeError(".infinitusai.be.TriggerTaskReviewedWebhookResponse.deliveryAttempt: object expected");
                        message.deliveryAttempt = $root.infinitusai.be.WebhookDeliveryAttempt.fromObject(object.deliveryAttempt);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a TriggerTaskReviewedWebhookResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {infinitusai.be.TriggerTaskReviewedWebhookResponse} message TriggerTaskReviewedWebhookResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TriggerTaskReviewedWebhookResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.deliveryAttempt = null;
                    if (message.deliveryAttempt != null && message.hasOwnProperty("deliveryAttempt"))
                        object.deliveryAttempt = $root.infinitusai.be.WebhookDeliveryAttempt.toObject(message.deliveryAttempt, options);
                    return object;
                };
    
                /**
                 * Converts this TriggerTaskReviewedWebhookResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TriggerTaskReviewedWebhookResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TriggerTaskReviewedWebhookResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TriggerTaskReviewedWebhookResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TriggerTaskReviewedWebhookResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TriggerTaskReviewedWebhookResponse";
                };
    
                return TriggerTaskReviewedWebhookResponse;
            })();
    
            be.CPGetRecordingURLRequest = (function() {
    
                /**
                 * Properties of a CPGetRecordingURLRequest.
                 * @memberof infinitusai.be
                 * @interface ICPGetRecordingURLRequest
                 * @property {string|null} [taskUuid] CPGetRecordingURLRequest taskUuid
                 */
    
                /**
                 * Constructs a new CPGetRecordingURLRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CPGetRecordingURLRequest.
                 * @implements ICPGetRecordingURLRequest
                 * @constructor
                 * @param {infinitusai.be.ICPGetRecordingURLRequest=} [properties] Properties to set
                 */
                function CPGetRecordingURLRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CPGetRecordingURLRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @instance
                 */
                CPGetRecordingURLRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new CPGetRecordingURLRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {infinitusai.be.ICPGetRecordingURLRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CPGetRecordingURLRequest} CPGetRecordingURLRequest instance
                 */
                CPGetRecordingURLRequest.create = function create(properties) {
                    return new CPGetRecordingURLRequest(properties);
                };
    
                /**
                 * Encodes the specified CPGetRecordingURLRequest message. Does not implicitly {@link infinitusai.be.CPGetRecordingURLRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {infinitusai.be.ICPGetRecordingURLRequest} message CPGetRecordingURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CPGetRecordingURLRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CPGetRecordingURLRequest message, length delimited. Does not implicitly {@link infinitusai.be.CPGetRecordingURLRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {infinitusai.be.ICPGetRecordingURLRequest} message CPGetRecordingURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CPGetRecordingURLRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CPGetRecordingURLRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CPGetRecordingURLRequest} CPGetRecordingURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CPGetRecordingURLRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CPGetRecordingURLRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CPGetRecordingURLRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CPGetRecordingURLRequest} CPGetRecordingURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CPGetRecordingURLRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CPGetRecordingURLRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CPGetRecordingURLRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CPGetRecordingURLRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CPGetRecordingURLRequest} CPGetRecordingURLRequest
                 */
                CPGetRecordingURLRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CPGetRecordingURLRequest)
                        return object;
                    var message = new $root.infinitusai.be.CPGetRecordingURLRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CPGetRecordingURLRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {infinitusai.be.CPGetRecordingURLRequest} message CPGetRecordingURLRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CPGetRecordingURLRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this CPGetRecordingURLRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CPGetRecordingURLRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CPGetRecordingURLRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CPGetRecordingURLRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CPGetRecordingURLRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CPGetRecordingURLRequest";
                };
    
                return CPGetRecordingURLRequest;
            })();
    
            be.CPGetRecordingURLResponse = (function() {
    
                /**
                 * Properties of a CPGetRecordingURLResponse.
                 * @memberof infinitusai.be
                 * @interface ICPGetRecordingURLResponse
                 * @property {string|null} [url] CPGetRecordingURLResponse url
                 * @property {Array.<string>|null} [additionalRecordingUrls] CPGetRecordingURLResponse additionalRecordingUrls
                 * @property {Array.<infinitusai.be.CPGetRecordingURLResponse.ITaskRecordings>|null} [taskRecordings] CPGetRecordingURLResponse taskRecordings
                 */
    
                /**
                 * Constructs a new CPGetRecordingURLResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CPGetRecordingURLResponse.
                 * @implements ICPGetRecordingURLResponse
                 * @constructor
                 * @param {infinitusai.be.ICPGetRecordingURLResponse=} [properties] Properties to set
                 */
                function CPGetRecordingURLResponse(properties) {
                    this.additionalRecordingUrls = [];
                    this.taskRecordings = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CPGetRecordingURLResponse url.
                 * @member {string} url
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @instance
                 */
                CPGetRecordingURLResponse.prototype.url = "";
    
                /**
                 * CPGetRecordingURLResponse additionalRecordingUrls.
                 * @member {Array.<string>} additionalRecordingUrls
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @instance
                 */
                CPGetRecordingURLResponse.prototype.additionalRecordingUrls = $util.emptyArray;
    
                /**
                 * CPGetRecordingURLResponse taskRecordings.
                 * @member {Array.<infinitusai.be.CPGetRecordingURLResponse.ITaskRecordings>} taskRecordings
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @instance
                 */
                CPGetRecordingURLResponse.prototype.taskRecordings = $util.emptyArray;
    
                /**
                 * Creates a new CPGetRecordingURLResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {infinitusai.be.ICPGetRecordingURLResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CPGetRecordingURLResponse} CPGetRecordingURLResponse instance
                 */
                CPGetRecordingURLResponse.create = function create(properties) {
                    return new CPGetRecordingURLResponse(properties);
                };
    
                /**
                 * Encodes the specified CPGetRecordingURLResponse message. Does not implicitly {@link infinitusai.be.CPGetRecordingURLResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {infinitusai.be.ICPGetRecordingURLResponse} message CPGetRecordingURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CPGetRecordingURLResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                    if (message.additionalRecordingUrls != null && message.additionalRecordingUrls.length)
                        for (var i = 0; i < message.additionalRecordingUrls.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.additionalRecordingUrls[i]);
                    if (message.taskRecordings != null && message.taskRecordings.length)
                        for (var i = 0; i < message.taskRecordings.length; ++i)
                            $root.infinitusai.be.CPGetRecordingURLResponse.TaskRecordings.encode(message.taskRecordings[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CPGetRecordingURLResponse message, length delimited. Does not implicitly {@link infinitusai.be.CPGetRecordingURLResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {infinitusai.be.ICPGetRecordingURLResponse} message CPGetRecordingURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CPGetRecordingURLResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CPGetRecordingURLResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CPGetRecordingURLResponse} CPGetRecordingURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CPGetRecordingURLResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CPGetRecordingURLResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.url = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.additionalRecordingUrls && message.additionalRecordingUrls.length))
                                    message.additionalRecordingUrls = [];
                                message.additionalRecordingUrls.push(reader.string());
                                break;
                            }
                        case 3: {
                                if (!(message.taskRecordings && message.taskRecordings.length))
                                    message.taskRecordings = [];
                                message.taskRecordings.push($root.infinitusai.be.CPGetRecordingURLResponse.TaskRecordings.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CPGetRecordingURLResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CPGetRecordingURLResponse} CPGetRecordingURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CPGetRecordingURLResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CPGetRecordingURLResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CPGetRecordingURLResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.additionalRecordingUrls != null && message.hasOwnProperty("additionalRecordingUrls")) {
                        if (!Array.isArray(message.additionalRecordingUrls))
                            return "additionalRecordingUrls: array expected";
                        for (var i = 0; i < message.additionalRecordingUrls.length; ++i)
                            if (!$util.isString(message.additionalRecordingUrls[i]))
                                return "additionalRecordingUrls: string[] expected";
                    }
                    if (message.taskRecordings != null && message.hasOwnProperty("taskRecordings")) {
                        if (!Array.isArray(message.taskRecordings))
                            return "taskRecordings: array expected";
                        for (var i = 0; i < message.taskRecordings.length; ++i) {
                            var error = $root.infinitusai.be.CPGetRecordingURLResponse.TaskRecordings.verify(message.taskRecordings[i]);
                            if (error)
                                return "taskRecordings." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a CPGetRecordingURLResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CPGetRecordingURLResponse} CPGetRecordingURLResponse
                 */
                CPGetRecordingURLResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CPGetRecordingURLResponse)
                        return object;
                    var message = new $root.infinitusai.be.CPGetRecordingURLResponse();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.additionalRecordingUrls) {
                        if (!Array.isArray(object.additionalRecordingUrls))
                            throw TypeError(".infinitusai.be.CPGetRecordingURLResponse.additionalRecordingUrls: array expected");
                        message.additionalRecordingUrls = [];
                        for (var i = 0; i < object.additionalRecordingUrls.length; ++i)
                            message.additionalRecordingUrls[i] = String(object.additionalRecordingUrls[i]);
                    }
                    if (object.taskRecordings) {
                        if (!Array.isArray(object.taskRecordings))
                            throw TypeError(".infinitusai.be.CPGetRecordingURLResponse.taskRecordings: array expected");
                        message.taskRecordings = [];
                        for (var i = 0; i < object.taskRecordings.length; ++i) {
                            if (typeof object.taskRecordings[i] !== "object")
                                throw TypeError(".infinitusai.be.CPGetRecordingURLResponse.taskRecordings: object expected");
                            message.taskRecordings[i] = $root.infinitusai.be.CPGetRecordingURLResponse.TaskRecordings.fromObject(object.taskRecordings[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CPGetRecordingURLResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {infinitusai.be.CPGetRecordingURLResponse} message CPGetRecordingURLResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CPGetRecordingURLResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.additionalRecordingUrls = [];
                        object.taskRecordings = [];
                    }
                    if (options.defaults)
                        object.url = "";
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.additionalRecordingUrls && message.additionalRecordingUrls.length) {
                        object.additionalRecordingUrls = [];
                        for (var j = 0; j < message.additionalRecordingUrls.length; ++j)
                            object.additionalRecordingUrls[j] = message.additionalRecordingUrls[j];
                    }
                    if (message.taskRecordings && message.taskRecordings.length) {
                        object.taskRecordings = [];
                        for (var j = 0; j < message.taskRecordings.length; ++j)
                            object.taskRecordings[j] = $root.infinitusai.be.CPGetRecordingURLResponse.TaskRecordings.toObject(message.taskRecordings[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this CPGetRecordingURLResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CPGetRecordingURLResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CPGetRecordingURLResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CPGetRecordingURLResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CPGetRecordingURLResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CPGetRecordingURLResponse";
                };
    
                CPGetRecordingURLResponse.TaskRecordings = (function() {
    
                    /**
                     * Properties of a TaskRecordings.
                     * @memberof infinitusai.be.CPGetRecordingURLResponse
                     * @interface ITaskRecordings
                     * @property {infinitusai.be.TaskType|null} [taskType] TaskRecordings taskType
                     * @property {Array.<string>|null} [urls] TaskRecordings urls
                     */
    
                    /**
                     * Constructs a new TaskRecordings.
                     * @memberof infinitusai.be.CPGetRecordingURLResponse
                     * @classdesc Represents a TaskRecordings.
                     * @implements ITaskRecordings
                     * @constructor
                     * @param {infinitusai.be.CPGetRecordingURLResponse.ITaskRecordings=} [properties] Properties to set
                     */
                    function TaskRecordings(properties) {
                        this.urls = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TaskRecordings taskType.
                     * @member {infinitusai.be.TaskType} taskType
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @instance
                     */
                    TaskRecordings.prototype.taskType = 0;
    
                    /**
                     * TaskRecordings urls.
                     * @member {Array.<string>} urls
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @instance
                     */
                    TaskRecordings.prototype.urls = $util.emptyArray;
    
                    /**
                     * Creates a new TaskRecordings instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {infinitusai.be.CPGetRecordingURLResponse.ITaskRecordings=} [properties] Properties to set
                     * @returns {infinitusai.be.CPGetRecordingURLResponse.TaskRecordings} TaskRecordings instance
                     */
                    TaskRecordings.create = function create(properties) {
                        return new TaskRecordings(properties);
                    };
    
                    /**
                     * Encodes the specified TaskRecordings message. Does not implicitly {@link infinitusai.be.CPGetRecordingURLResponse.TaskRecordings.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {infinitusai.be.CPGetRecordingURLResponse.ITaskRecordings} message TaskRecordings message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskRecordings.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.taskType);
                        if (message.urls != null && message.urls.length)
                            for (var i = 0; i < message.urls.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.urls[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TaskRecordings message, length delimited. Does not implicitly {@link infinitusai.be.CPGetRecordingURLResponse.TaskRecordings.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {infinitusai.be.CPGetRecordingURLResponse.ITaskRecordings} message TaskRecordings message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskRecordings.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TaskRecordings message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CPGetRecordingURLResponse.TaskRecordings} TaskRecordings
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskRecordings.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CPGetRecordingURLResponse.TaskRecordings();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.taskType = reader.int32();
                                    break;
                                }
                            case 2: {
                                    if (!(message.urls && message.urls.length))
                                        message.urls = [];
                                    message.urls.push(reader.string());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TaskRecordings message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CPGetRecordingURLResponse.TaskRecordings} TaskRecordings
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskRecordings.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TaskRecordings message.
                     * @function verify
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskRecordings.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskType != null && message.hasOwnProperty("taskType"))
                            switch (message.taskType) {
                            default:
                                return "taskType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                                break;
                            }
                        if (message.urls != null && message.hasOwnProperty("urls")) {
                            if (!Array.isArray(message.urls))
                                return "urls: array expected";
                            for (var i = 0; i < message.urls.length; ++i)
                                if (!$util.isString(message.urls[i]))
                                    return "urls: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TaskRecordings message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CPGetRecordingURLResponse.TaskRecordings} TaskRecordings
                     */
                    TaskRecordings.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CPGetRecordingURLResponse.TaskRecordings)
                            return object;
                        var message = new $root.infinitusai.be.CPGetRecordingURLResponse.TaskRecordings();
                        switch (object.taskType) {
                        default:
                            if (typeof object.taskType === "number") {
                                message.taskType = object.taskType;
                                break;
                            }
                            break;
                        case "TASK_TYPE_UNKNOWN":
                        case 0:
                            message.taskType = 0;
                            break;
                        case "TASK_TYPE_MED_SUPP_BV":
                        case 1:
                            message.taskType = 1;
                            break;
                        case "TASK_TYPE_FULL_BI":
                        case 2:
                            message.taskType = 2;
                            break;
                        case "TASK_TYPE_PBM_BV":
                        case 3:
                            message.taskType = 3;
                            break;
                        case "TASK_TYPE_COVID_VACCINATIONS":
                        case 4:
                            message.taskType = 4;
                            break;
                        case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                        case 5:
                            message.taskType = 5;
                            break;
                        case "TASK_TYPE_INBOUND":
                        case 6:
                            message.taskType = 6;
                            break;
                        case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                        case 7:
                            message.taskType = 7;
                            break;
                        case "TASK_TYPE_CLAIMS_FOLLOWUP":
                        case 8:
                            message.taskType = 8;
                            break;
                        case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                        case 9:
                            message.taskType = 9;
                            break;
                        case "TASK_TYPE_DENTAL_BV":
                        case 10:
                            message.taskType = 10;
                            break;
                        case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                        case 11:
                            message.taskType = 11;
                            break;
                        case "TASK_TYPE_PATIENT_OUTREACH":
                        case 12:
                            message.taskType = 12;
                            break;
                        case "TASK_TYPE_PROVIDER_OUTREACH":
                        case 13:
                            message.taskType = 13;
                            break;
                        case "TASK_TYPE_FASTTRACK_MM":
                        case 14:
                            message.taskType = 14;
                            break;
                        case "TASK_TYPE_FASTTRACK_PBM":
                        case 15:
                            message.taskType = 15;
                            break;
                        case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                        case 16:
                            message.taskType = 16;
                            break;
                        case "TASK_TYPE_BASIC_BV":
                        case 17:
                            message.taskType = 17;
                            break;
                        case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                        case 18:
                            message.taskType = 18;
                            break;
                        case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                        case 19:
                            message.taskType = 19;
                            break;
                        case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                        case 20:
                            message.taskType = 20;
                            break;
                        case "TASK_TYPE_PBM_DISCOVERY":
                        case 21:
                            message.taskType = 21;
                            break;
                        }
                        if (object.urls) {
                            if (!Array.isArray(object.urls))
                                throw TypeError(".infinitusai.be.CPGetRecordingURLResponse.TaskRecordings.urls: array expected");
                            message.urls = [];
                            for (var i = 0; i < object.urls.length; ++i)
                                message.urls[i] = String(object.urls[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TaskRecordings message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {infinitusai.be.CPGetRecordingURLResponse.TaskRecordings} message TaskRecordings
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskRecordings.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.urls = [];
                        if (options.defaults)
                            object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        if (message.taskType != null && message.hasOwnProperty("taskType"))
                            object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                        if (message.urls && message.urls.length) {
                            object.urls = [];
                            for (var j = 0; j < message.urls.length; ++j)
                                object.urls[j] = message.urls[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this TaskRecordings to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskRecordings.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for TaskRecordings
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CPGetRecordingURLResponse.TaskRecordings
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TaskRecordings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CPGetRecordingURLResponse.TaskRecordings";
                    };
    
                    return TaskRecordings;
                })();
    
                return CPGetRecordingURLResponse;
            })();
    
            be.FlexibleTaskInputFilter = (function() {
    
                /**
                 * Properties of a FlexibleTaskInputFilter.
                 * @memberof infinitusai.be
                 * @interface IFlexibleTaskInputFilter
                 * @property {string|null} [name] FlexibleTaskInputFilter name
                 * @property {string|null} [value] FlexibleTaskInputFilter value
                 */
    
                /**
                 * Constructs a new FlexibleTaskInputFilter.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FlexibleTaskInputFilter.
                 * @implements IFlexibleTaskInputFilter
                 * @constructor
                 * @param {infinitusai.be.IFlexibleTaskInputFilter=} [properties] Properties to set
                 */
                function FlexibleTaskInputFilter(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FlexibleTaskInputFilter name.
                 * @member {string} name
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @instance
                 */
                FlexibleTaskInputFilter.prototype.name = "";
    
                /**
                 * FlexibleTaskInputFilter value.
                 * @member {string} value
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @instance
                 */
                FlexibleTaskInputFilter.prototype.value = "";
    
                /**
                 * Creates a new FlexibleTaskInputFilter instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {infinitusai.be.IFlexibleTaskInputFilter=} [properties] Properties to set
                 * @returns {infinitusai.be.FlexibleTaskInputFilter} FlexibleTaskInputFilter instance
                 */
                FlexibleTaskInputFilter.create = function create(properties) {
                    return new FlexibleTaskInputFilter(properties);
                };
    
                /**
                 * Encodes the specified FlexibleTaskInputFilter message. Does not implicitly {@link infinitusai.be.FlexibleTaskInputFilter.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {infinitusai.be.IFlexibleTaskInputFilter} message FlexibleTaskInputFilter message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FlexibleTaskInputFilter.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified FlexibleTaskInputFilter message, length delimited. Does not implicitly {@link infinitusai.be.FlexibleTaskInputFilter.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {infinitusai.be.IFlexibleTaskInputFilter} message FlexibleTaskInputFilter message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FlexibleTaskInputFilter.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FlexibleTaskInputFilter message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FlexibleTaskInputFilter} FlexibleTaskInputFilter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FlexibleTaskInputFilter.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FlexibleTaskInputFilter();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.value = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FlexibleTaskInputFilter message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FlexibleTaskInputFilter} FlexibleTaskInputFilter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FlexibleTaskInputFilter.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FlexibleTaskInputFilter message.
                 * @function verify
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FlexibleTaskInputFilter.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    return null;
                };
    
                /**
                 * Creates a FlexibleTaskInputFilter message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FlexibleTaskInputFilter} FlexibleTaskInputFilter
                 */
                FlexibleTaskInputFilter.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FlexibleTaskInputFilter)
                        return object;
                    var message = new $root.infinitusai.be.FlexibleTaskInputFilter();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.value != null)
                        message.value = String(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FlexibleTaskInputFilter message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {infinitusai.be.FlexibleTaskInputFilter} message FlexibleTaskInputFilter
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FlexibleTaskInputFilter.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.value = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this FlexibleTaskInputFilter to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FlexibleTaskInputFilter.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FlexibleTaskInputFilter
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FlexibleTaskInputFilter
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FlexibleTaskInputFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FlexibleTaskInputFilter";
                };
    
                return FlexibleTaskInputFilter;
            })();
    
            be.GetTasksRequest = (function() {
    
                /**
                 * Properties of a GetTasksRequest.
                 * @memberof infinitusai.be
                 * @interface IGetTasksRequest
                 * @property {Array.<string>|null} [taskStates] GetTasksRequest taskStates
                 * @property {string|null} [taskCreatedMillisGte] GetTasksRequest taskCreatedMillisGte
                 * @property {string|null} [taskCreatedMillisLte] GetTasksRequest taskCreatedMillisLte
                 * @property {string|null} [taskUpdatedMillisGte] GetTasksRequest taskUpdatedMillisGte
                 * @property {string|null} [taskUpdatedMillisLte] GetTasksRequest taskUpdatedMillisLte
                 * @property {string|null} [taskReviewedMillisGte] GetTasksRequest taskReviewedMillisGte
                 * @property {string|null} [taskReviewedMillisLte] GetTasksRequest taskReviewedMillisLte
                 * @property {string|null} [customerAssignedId] GetTasksRequest customerAssignedId
                 * @property {string|null} [calleeName] GetTasksRequest calleeName
                 * @property {string|null} [sortAsc] GetTasksRequest sortAsc
                 * @property {string|null} [sortDesc] GetTasksRequest sortDesc
                 * @property {number|null} [limit] GetTasksRequest limit
                 * @property {string|null} [failureReasonChosen] GetTasksRequest failureReasonChosen
                 * @property {string|null} [processed] GetTasksRequest processed
                 * @property {string|null} [flaggedForReview] GetTasksRequest flaggedForReview
                 * @property {string|null} [programName] GetTasksRequest programName
                 * @property {string|null} [taskCreator] GetTasksRequest taskCreator
                 * @property {Array.<infinitusai.be.IFlexibleTaskInputFilter>|null} [taskInputFilters] GetTasksRequest taskInputFilters
                 * @property {number|null} [page] GetTasksRequest page
                 * @property {string|null} [hasHumanInvolvement] GetTasksRequest hasHumanInvolvement
                 * @property {string|null} [webhookDelivered] GetTasksRequest webhookDelivered
                 * @property {string|null} [webhookDeliveryStatus] GetTasksRequest webhookDeliveryStatus
                 */
    
                /**
                 * Constructs a new GetTasksRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTasksRequest.
                 * @implements IGetTasksRequest
                 * @constructor
                 * @param {infinitusai.be.IGetTasksRequest=} [properties] Properties to set
                 */
                function GetTasksRequest(properties) {
                    this.taskStates = [];
                    this.taskInputFilters = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTasksRequest taskStates.
                 * @member {Array.<string>} taskStates
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskStates = $util.emptyArray;
    
                /**
                 * GetTasksRequest taskCreatedMillisGte.
                 * @member {string} taskCreatedMillisGte
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskCreatedMillisGte = "";
    
                /**
                 * GetTasksRequest taskCreatedMillisLte.
                 * @member {string} taskCreatedMillisLte
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskCreatedMillisLte = "";
    
                /**
                 * GetTasksRequest taskUpdatedMillisGte.
                 * @member {string} taskUpdatedMillisGte
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskUpdatedMillisGte = "";
    
                /**
                 * GetTasksRequest taskUpdatedMillisLte.
                 * @member {string} taskUpdatedMillisLte
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskUpdatedMillisLte = "";
    
                /**
                 * GetTasksRequest taskReviewedMillisGte.
                 * @member {string} taskReviewedMillisGte
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskReviewedMillisGte = "";
    
                /**
                 * GetTasksRequest taskReviewedMillisLte.
                 * @member {string} taskReviewedMillisLte
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskReviewedMillisLte = "";
    
                /**
                 * GetTasksRequest customerAssignedId.
                 * @member {string} customerAssignedId
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.customerAssignedId = "";
    
                /**
                 * GetTasksRequest calleeName.
                 * @member {string} calleeName
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.calleeName = "";
    
                /**
                 * GetTasksRequest sortAsc.
                 * @member {string} sortAsc
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.sortAsc = "";
    
                /**
                 * GetTasksRequest sortDesc.
                 * @member {string} sortDesc
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.sortDesc = "";
    
                /**
                 * GetTasksRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.limit = 0;
    
                /**
                 * GetTasksRequest failureReasonChosen.
                 * @member {string} failureReasonChosen
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.failureReasonChosen = "";
    
                /**
                 * GetTasksRequest processed.
                 * @member {string} processed
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.processed = "";
    
                /**
                 * GetTasksRequest flaggedForReview.
                 * @member {string} flaggedForReview
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.flaggedForReview = "";
    
                /**
                 * GetTasksRequest programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.programName = "";
    
                /**
                 * GetTasksRequest taskCreator.
                 * @member {string} taskCreator
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskCreator = "";
    
                /**
                 * GetTasksRequest taskInputFilters.
                 * @member {Array.<infinitusai.be.IFlexibleTaskInputFilter>} taskInputFilters
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.taskInputFilters = $util.emptyArray;
    
                /**
                 * GetTasksRequest page.
                 * @member {number} page
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.page = 0;
    
                /**
                 * GetTasksRequest hasHumanInvolvement.
                 * @member {string} hasHumanInvolvement
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.hasHumanInvolvement = "";
    
                /**
                 * GetTasksRequest webhookDelivered.
                 * @member {string} webhookDelivered
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.webhookDelivered = "";
    
                /**
                 * GetTasksRequest webhookDeliveryStatus.
                 * @member {string} webhookDeliveryStatus
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 */
                GetTasksRequest.prototype.webhookDeliveryStatus = "";
    
                /**
                 * Creates a new GetTasksRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTasksRequest} GetTasksRequest instance
                 */
                GetTasksRequest.create = function create(properties) {
                    return new GetTasksRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTasksRequest message. Does not implicitly {@link infinitusai.be.GetTasksRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksRequest} message GetTasksRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskStates != null && message.taskStates.length)
                        for (var i = 0; i < message.taskStates.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskStates[i]);
                    if (message.taskCreatedMillisGte != null && Object.hasOwnProperty.call(message, "taskCreatedMillisGte"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskCreatedMillisGte);
                    if (message.taskCreatedMillisLte != null && Object.hasOwnProperty.call(message, "taskCreatedMillisLte"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskCreatedMillisLte);
                    if (message.taskUpdatedMillisGte != null && Object.hasOwnProperty.call(message, "taskUpdatedMillisGte"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskUpdatedMillisGte);
                    if (message.taskUpdatedMillisLte != null && Object.hasOwnProperty.call(message, "taskUpdatedMillisLte"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.taskUpdatedMillisLte);
                    if (message.taskReviewedMillisGte != null && Object.hasOwnProperty.call(message, "taskReviewedMillisGte"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.taskReviewedMillisGte);
                    if (message.taskReviewedMillisLte != null && Object.hasOwnProperty.call(message, "taskReviewedMillisLte"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.taskReviewedMillisLte);
                    if (message.customerAssignedId != null && Object.hasOwnProperty.call(message, "customerAssignedId"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.customerAssignedId);
                    if (message.calleeName != null && Object.hasOwnProperty.call(message, "calleeName"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.calleeName);
                    if (message.sortAsc != null && Object.hasOwnProperty.call(message, "sortAsc"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.sortAsc);
                    if (message.sortDesc != null && Object.hasOwnProperty.call(message, "sortDesc"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.sortDesc);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.limit);
                    if (message.failureReasonChosen != null && Object.hasOwnProperty.call(message, "failureReasonChosen"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.failureReasonChosen);
                    if (message.flaggedForReview != null && Object.hasOwnProperty.call(message, "flaggedForReview"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.flaggedForReview);
                    if (message.processed != null && Object.hasOwnProperty.call(message, "processed"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.processed);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.programName);
                    if (message.taskCreator != null && Object.hasOwnProperty.call(message, "taskCreator"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.taskCreator);
                    if (message.taskInputFilters != null && message.taskInputFilters.length)
                        for (var i = 0; i < message.taskInputFilters.length; ++i)
                            $root.infinitusai.be.FlexibleTaskInputFilter.encode(message.taskInputFilters[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 19, wireType 0 =*/152).int32(message.page);
                    if (message.hasHumanInvolvement != null && Object.hasOwnProperty.call(message, "hasHumanInvolvement"))
                        writer.uint32(/* id 20, wireType 2 =*/162).string(message.hasHumanInvolvement);
                    if (message.webhookDelivered != null && Object.hasOwnProperty.call(message, "webhookDelivered"))
                        writer.uint32(/* id 21, wireType 2 =*/170).string(message.webhookDelivered);
                    if (message.webhookDeliveryStatus != null && Object.hasOwnProperty.call(message, "webhookDeliveryStatus"))
                        writer.uint32(/* id 22, wireType 2 =*/178).string(message.webhookDeliveryStatus);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTasksRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetTasksRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksRequest} message GetTasksRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTasksRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTasksRequest} GetTasksRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTasksRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.taskStates && message.taskStates.length))
                                    message.taskStates = [];
                                message.taskStates.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.taskCreatedMillisGte = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskCreatedMillisLte = reader.string();
                                break;
                            }
                        case 4: {
                                message.taskUpdatedMillisGte = reader.string();
                                break;
                            }
                        case 5: {
                                message.taskUpdatedMillisLte = reader.string();
                                break;
                            }
                        case 6: {
                                message.taskReviewedMillisGte = reader.string();
                                break;
                            }
                        case 7: {
                                message.taskReviewedMillisLte = reader.string();
                                break;
                            }
                        case 8: {
                                message.customerAssignedId = reader.string();
                                break;
                            }
                        case 9: {
                                message.calleeName = reader.string();
                                break;
                            }
                        case 10: {
                                message.sortAsc = reader.string();
                                break;
                            }
                        case 11: {
                                message.sortDesc = reader.string();
                                break;
                            }
                        case 12: {
                                message.limit = reader.int32();
                                break;
                            }
                        case 13: {
                                message.failureReasonChosen = reader.string();
                                break;
                            }
                        case 15: {
                                message.processed = reader.string();
                                break;
                            }
                        case 14: {
                                message.flaggedForReview = reader.string();
                                break;
                            }
                        case 16: {
                                message.programName = reader.string();
                                break;
                            }
                        case 17: {
                                message.taskCreator = reader.string();
                                break;
                            }
                        case 18: {
                                if (!(message.taskInputFilters && message.taskInputFilters.length))
                                    message.taskInputFilters = [];
                                message.taskInputFilters.push($root.infinitusai.be.FlexibleTaskInputFilter.decode(reader, reader.uint32()));
                                break;
                            }
                        case 19: {
                                message.page = reader.int32();
                                break;
                            }
                        case 20: {
                                message.hasHumanInvolvement = reader.string();
                                break;
                            }
                        case 21: {
                                message.webhookDelivered = reader.string();
                                break;
                            }
                        case 22: {
                                message.webhookDeliveryStatus = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTasksRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTasksRequest} GetTasksRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTasksRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTasksRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskStates != null && message.hasOwnProperty("taskStates")) {
                        if (!Array.isArray(message.taskStates))
                            return "taskStates: array expected";
                        for (var i = 0; i < message.taskStates.length; ++i)
                            if (!$util.isString(message.taskStates[i]))
                                return "taskStates: string[] expected";
                    }
                    if (message.taskCreatedMillisGte != null && message.hasOwnProperty("taskCreatedMillisGte"))
                        if (!$util.isString(message.taskCreatedMillisGte))
                            return "taskCreatedMillisGte: string expected";
                    if (message.taskCreatedMillisLte != null && message.hasOwnProperty("taskCreatedMillisLte"))
                        if (!$util.isString(message.taskCreatedMillisLte))
                            return "taskCreatedMillisLte: string expected";
                    if (message.taskUpdatedMillisGte != null && message.hasOwnProperty("taskUpdatedMillisGte"))
                        if (!$util.isString(message.taskUpdatedMillisGte))
                            return "taskUpdatedMillisGte: string expected";
                    if (message.taskUpdatedMillisLte != null && message.hasOwnProperty("taskUpdatedMillisLte"))
                        if (!$util.isString(message.taskUpdatedMillisLte))
                            return "taskUpdatedMillisLte: string expected";
                    if (message.taskReviewedMillisGte != null && message.hasOwnProperty("taskReviewedMillisGte"))
                        if (!$util.isString(message.taskReviewedMillisGte))
                            return "taskReviewedMillisGte: string expected";
                    if (message.taskReviewedMillisLte != null && message.hasOwnProperty("taskReviewedMillisLte"))
                        if (!$util.isString(message.taskReviewedMillisLte))
                            return "taskReviewedMillisLte: string expected";
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        if (!$util.isString(message.customerAssignedId))
                            return "customerAssignedId: string expected";
                    if (message.calleeName != null && message.hasOwnProperty("calleeName"))
                        if (!$util.isString(message.calleeName))
                            return "calleeName: string expected";
                    if (message.sortAsc != null && message.hasOwnProperty("sortAsc"))
                        if (!$util.isString(message.sortAsc))
                            return "sortAsc: string expected";
                    if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                        if (!$util.isString(message.sortDesc))
                            return "sortDesc: string expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen"))
                        if (!$util.isString(message.failureReasonChosen))
                            return "failureReasonChosen: string expected";
                    if (message.processed != null && message.hasOwnProperty("processed"))
                        if (!$util.isString(message.processed))
                            return "processed: string expected";
                    if (message.flaggedForReview != null && message.hasOwnProperty("flaggedForReview"))
                        if (!$util.isString(message.flaggedForReview))
                            return "flaggedForReview: string expected";
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    if (message.taskCreator != null && message.hasOwnProperty("taskCreator"))
                        if (!$util.isString(message.taskCreator))
                            return "taskCreator: string expected";
                    if (message.taskInputFilters != null && message.hasOwnProperty("taskInputFilters")) {
                        if (!Array.isArray(message.taskInputFilters))
                            return "taskInputFilters: array expected";
                        for (var i = 0; i < message.taskInputFilters.length; ++i) {
                            var error = $root.infinitusai.be.FlexibleTaskInputFilter.verify(message.taskInputFilters[i]);
                            if (error)
                                return "taskInputFilters." + error;
                        }
                    }
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.hasHumanInvolvement != null && message.hasOwnProperty("hasHumanInvolvement"))
                        if (!$util.isString(message.hasHumanInvolvement))
                            return "hasHumanInvolvement: string expected";
                    if (message.webhookDelivered != null && message.hasOwnProperty("webhookDelivered"))
                        if (!$util.isString(message.webhookDelivered))
                            return "webhookDelivered: string expected";
                    if (message.webhookDeliveryStatus != null && message.hasOwnProperty("webhookDeliveryStatus"))
                        if (!$util.isString(message.webhookDeliveryStatus))
                            return "webhookDeliveryStatus: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetTasksRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTasksRequest} GetTasksRequest
                 */
                GetTasksRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTasksRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetTasksRequest();
                    if (object.taskStates) {
                        if (!Array.isArray(object.taskStates))
                            throw TypeError(".infinitusai.be.GetTasksRequest.taskStates: array expected");
                        message.taskStates = [];
                        for (var i = 0; i < object.taskStates.length; ++i)
                            message.taskStates[i] = String(object.taskStates[i]);
                    }
                    if (object.taskCreatedMillisGte != null)
                        message.taskCreatedMillisGte = String(object.taskCreatedMillisGte);
                    if (object.taskCreatedMillisLte != null)
                        message.taskCreatedMillisLte = String(object.taskCreatedMillisLte);
                    if (object.taskUpdatedMillisGte != null)
                        message.taskUpdatedMillisGte = String(object.taskUpdatedMillisGte);
                    if (object.taskUpdatedMillisLte != null)
                        message.taskUpdatedMillisLte = String(object.taskUpdatedMillisLte);
                    if (object.taskReviewedMillisGte != null)
                        message.taskReviewedMillisGte = String(object.taskReviewedMillisGte);
                    if (object.taskReviewedMillisLte != null)
                        message.taskReviewedMillisLte = String(object.taskReviewedMillisLte);
                    if (object.customerAssignedId != null)
                        message.customerAssignedId = String(object.customerAssignedId);
                    if (object.calleeName != null)
                        message.calleeName = String(object.calleeName);
                    if (object.sortAsc != null)
                        message.sortAsc = String(object.sortAsc);
                    if (object.sortDesc != null)
                        message.sortDesc = String(object.sortDesc);
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    if (object.failureReasonChosen != null)
                        message.failureReasonChosen = String(object.failureReasonChosen);
                    if (object.processed != null)
                        message.processed = String(object.processed);
                    if (object.flaggedForReview != null)
                        message.flaggedForReview = String(object.flaggedForReview);
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    if (object.taskCreator != null)
                        message.taskCreator = String(object.taskCreator);
                    if (object.taskInputFilters) {
                        if (!Array.isArray(object.taskInputFilters))
                            throw TypeError(".infinitusai.be.GetTasksRequest.taskInputFilters: array expected");
                        message.taskInputFilters = [];
                        for (var i = 0; i < object.taskInputFilters.length; ++i) {
                            if (typeof object.taskInputFilters[i] !== "object")
                                throw TypeError(".infinitusai.be.GetTasksRequest.taskInputFilters: object expected");
                            message.taskInputFilters[i] = $root.infinitusai.be.FlexibleTaskInputFilter.fromObject(object.taskInputFilters[i]);
                        }
                    }
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.hasHumanInvolvement != null)
                        message.hasHumanInvolvement = String(object.hasHumanInvolvement);
                    if (object.webhookDelivered != null)
                        message.webhookDelivered = String(object.webhookDelivered);
                    if (object.webhookDeliveryStatus != null)
                        message.webhookDeliveryStatus = String(object.webhookDeliveryStatus);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTasksRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {infinitusai.be.GetTasksRequest} message GetTasksRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTasksRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.taskStates = [];
                        object.taskInputFilters = [];
                    }
                    if (options.defaults) {
                        object.taskCreatedMillisGte = "";
                        object.taskCreatedMillisLte = "";
                        object.taskUpdatedMillisGte = "";
                        object.taskUpdatedMillisLte = "";
                        object.taskReviewedMillisGte = "";
                        object.taskReviewedMillisLte = "";
                        object.customerAssignedId = "";
                        object.calleeName = "";
                        object.sortAsc = "";
                        object.sortDesc = "";
                        object.limit = 0;
                        object.failureReasonChosen = "";
                        object.flaggedForReview = "";
                        object.processed = "";
                        object.programName = "";
                        object.taskCreator = "";
                        object.page = 0;
                        object.hasHumanInvolvement = "";
                        object.webhookDelivered = "";
                        object.webhookDeliveryStatus = "";
                    }
                    if (message.taskStates && message.taskStates.length) {
                        object.taskStates = [];
                        for (var j = 0; j < message.taskStates.length; ++j)
                            object.taskStates[j] = message.taskStates[j];
                    }
                    if (message.taskCreatedMillisGte != null && message.hasOwnProperty("taskCreatedMillisGte"))
                        object.taskCreatedMillisGte = message.taskCreatedMillisGte;
                    if (message.taskCreatedMillisLte != null && message.hasOwnProperty("taskCreatedMillisLte"))
                        object.taskCreatedMillisLte = message.taskCreatedMillisLte;
                    if (message.taskUpdatedMillisGte != null && message.hasOwnProperty("taskUpdatedMillisGte"))
                        object.taskUpdatedMillisGte = message.taskUpdatedMillisGte;
                    if (message.taskUpdatedMillisLte != null && message.hasOwnProperty("taskUpdatedMillisLte"))
                        object.taskUpdatedMillisLte = message.taskUpdatedMillisLte;
                    if (message.taskReviewedMillisGte != null && message.hasOwnProperty("taskReviewedMillisGte"))
                        object.taskReviewedMillisGte = message.taskReviewedMillisGte;
                    if (message.taskReviewedMillisLte != null && message.hasOwnProperty("taskReviewedMillisLte"))
                        object.taskReviewedMillisLte = message.taskReviewedMillisLte;
                    if (message.customerAssignedId != null && message.hasOwnProperty("customerAssignedId"))
                        object.customerAssignedId = message.customerAssignedId;
                    if (message.calleeName != null && message.hasOwnProperty("calleeName"))
                        object.calleeName = message.calleeName;
                    if (message.sortAsc != null && message.hasOwnProperty("sortAsc"))
                        object.sortAsc = message.sortAsc;
                    if (message.sortDesc != null && message.hasOwnProperty("sortDesc"))
                        object.sortDesc = message.sortDesc;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.failureReasonChosen != null && message.hasOwnProperty("failureReasonChosen"))
                        object.failureReasonChosen = message.failureReasonChosen;
                    if (message.flaggedForReview != null && message.hasOwnProperty("flaggedForReview"))
                        object.flaggedForReview = message.flaggedForReview;
                    if (message.processed != null && message.hasOwnProperty("processed"))
                        object.processed = message.processed;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    if (message.taskCreator != null && message.hasOwnProperty("taskCreator"))
                        object.taskCreator = message.taskCreator;
                    if (message.taskInputFilters && message.taskInputFilters.length) {
                        object.taskInputFilters = [];
                        for (var j = 0; j < message.taskInputFilters.length; ++j)
                            object.taskInputFilters[j] = $root.infinitusai.be.FlexibleTaskInputFilter.toObject(message.taskInputFilters[j], options);
                    }
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.hasHumanInvolvement != null && message.hasOwnProperty("hasHumanInvolvement"))
                        object.hasHumanInvolvement = message.hasHumanInvolvement;
                    if (message.webhookDelivered != null && message.hasOwnProperty("webhookDelivered"))
                        object.webhookDelivered = message.webhookDelivered;
                    if (message.webhookDeliveryStatus != null && message.hasOwnProperty("webhookDeliveryStatus"))
                        object.webhookDeliveryStatus = message.webhookDeliveryStatus;
                    return object;
                };
    
                /**
                 * Converts this GetTasksRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTasksRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTasksRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTasksRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTasksRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTasksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTasksRequest";
                };
    
                return GetTasksRequest;
            })();
    
            be.GetTasksForEhrRequest = (function() {
    
                /**
                 * Properties of a GetTasksForEhrRequest.
                 * @memberof infinitusai.be
                 * @interface IGetTasksForEhrRequest
                 * @property {string|null} [patientId] GetTasksForEhrRequest patientId
                 * @property {number|null} [limit] GetTasksForEhrRequest limit
                 */
    
                /**
                 * Constructs a new GetTasksForEhrRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTasksForEhrRequest.
                 * @implements IGetTasksForEhrRequest
                 * @constructor
                 * @param {infinitusai.be.IGetTasksForEhrRequest=} [properties] Properties to set
                 */
                function GetTasksForEhrRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTasksForEhrRequest patientId.
                 * @member {string} patientId
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @instance
                 */
                GetTasksForEhrRequest.prototype.patientId = "";
    
                /**
                 * GetTasksForEhrRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @instance
                 */
                GetTasksForEhrRequest.prototype.limit = 0;
    
                /**
                 * Creates a new GetTasksForEhrRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksForEhrRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTasksForEhrRequest} GetTasksForEhrRequest instance
                 */
                GetTasksForEhrRequest.create = function create(properties) {
                    return new GetTasksForEhrRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTasksForEhrRequest message. Does not implicitly {@link infinitusai.be.GetTasksForEhrRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksForEhrRequest} message GetTasksForEhrRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksForEhrRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.patientId != null && Object.hasOwnProperty.call(message, "patientId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.patientId);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTasksForEhrRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetTasksForEhrRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksForEhrRequest} message GetTasksForEhrRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksForEhrRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTasksForEhrRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTasksForEhrRequest} GetTasksForEhrRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksForEhrRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTasksForEhrRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.patientId = reader.string();
                                break;
                            }
                        case 2: {
                                message.limit = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTasksForEhrRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTasksForEhrRequest} GetTasksForEhrRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksForEhrRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTasksForEhrRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTasksForEhrRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.patientId != null && message.hasOwnProperty("patientId"))
                        if (!$util.isString(message.patientId))
                            return "patientId: string expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    return null;
                };
    
                /**
                 * Creates a GetTasksForEhrRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTasksForEhrRequest} GetTasksForEhrRequest
                 */
                GetTasksForEhrRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTasksForEhrRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetTasksForEhrRequest();
                    if (object.patientId != null)
                        message.patientId = String(object.patientId);
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTasksForEhrRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {infinitusai.be.GetTasksForEhrRequest} message GetTasksForEhrRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTasksForEhrRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.patientId = "";
                        object.limit = 0;
                    }
                    if (message.patientId != null && message.hasOwnProperty("patientId"))
                        object.patientId = message.patientId;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    return object;
                };
    
                /**
                 * Converts this GetTasksForEhrRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTasksForEhrRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTasksForEhrRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTasksForEhrRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTasksForEhrRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTasksForEhrRequest";
                };
    
                return GetTasksForEhrRequest;
            })();
    
            be.GetTasksResponse = (function() {
    
                /**
                 * Properties of a GetTasksResponse.
                 * @memberof infinitusai.be
                 * @interface IGetTasksResponse
                 * @property {Array.<infinitusai.be.ICustomerTaskDoc>|null} [tasks] GetTasksResponse tasks
                 * @property {Array.<infinitusai.be.GetTasksResponse.ITaskInputListElement>|null} [taskInputs] GetTasksResponse taskInputs
                 */
    
                /**
                 * Constructs a new GetTasksResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTasksResponse.
                 * @implements IGetTasksResponse
                 * @constructor
                 * @param {infinitusai.be.IGetTasksResponse=} [properties] Properties to set
                 */
                function GetTasksResponse(properties) {
                    this.tasks = [];
                    this.taskInputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTasksResponse tasks.
                 * @member {Array.<infinitusai.be.ICustomerTaskDoc>} tasks
                 * @memberof infinitusai.be.GetTasksResponse
                 * @instance
                 */
                GetTasksResponse.prototype.tasks = $util.emptyArray;
    
                /**
                 * GetTasksResponse taskInputs.
                 * @member {Array.<infinitusai.be.GetTasksResponse.ITaskInputListElement>} taskInputs
                 * @memberof infinitusai.be.GetTasksResponse
                 * @instance
                 */
                GetTasksResponse.prototype.taskInputs = $util.emptyArray;
    
                /**
                 * Creates a new GetTasksResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetTasksResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTasksResponse} GetTasksResponse instance
                 */
                GetTasksResponse.create = function create(properties) {
                    return new GetTasksResponse(properties);
                };
    
                /**
                 * Encodes the specified GetTasksResponse message. Does not implicitly {@link infinitusai.be.GetTasksResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetTasksResponse} message GetTasksResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tasks != null && message.tasks.length)
                        for (var i = 0; i < message.tasks.length; ++i)
                            $root.infinitusai.be.CustomerTaskDoc.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.taskInputs != null && message.taskInputs.length)
                        for (var i = 0; i < message.taskInputs.length; ++i)
                            $root.infinitusai.be.GetTasksResponse.TaskInputListElement.encode(message.taskInputs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTasksResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetTasksResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetTasksResponse} message GetTasksResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTasksResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTasksResponse} GetTasksResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTasksResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.infinitusai.be.CustomerTaskDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.taskInputs && message.taskInputs.length))
                                    message.taskInputs = [];
                                message.taskInputs.push($root.infinitusai.be.GetTasksResponse.TaskInputListElement.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTasksResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTasksResponse} GetTasksResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTasksResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTasksResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tasks != null && message.hasOwnProperty("tasks")) {
                        if (!Array.isArray(message.tasks))
                            return "tasks: array expected";
                        for (var i = 0; i < message.tasks.length; ++i) {
                            var error = $root.infinitusai.be.CustomerTaskDoc.verify(message.tasks[i]);
                            if (error)
                                return "tasks." + error;
                        }
                    }
                    if (message.taskInputs != null && message.hasOwnProperty("taskInputs")) {
                        if (!Array.isArray(message.taskInputs))
                            return "taskInputs: array expected";
                        for (var i = 0; i < message.taskInputs.length; ++i) {
                            var error = $root.infinitusai.be.GetTasksResponse.TaskInputListElement.verify(message.taskInputs[i]);
                            if (error)
                                return "taskInputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetTasksResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTasksResponse} GetTasksResponse
                 */
                GetTasksResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTasksResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetTasksResponse();
                    if (object.tasks) {
                        if (!Array.isArray(object.tasks))
                            throw TypeError(".infinitusai.be.GetTasksResponse.tasks: array expected");
                        message.tasks = [];
                        for (var i = 0; i < object.tasks.length; ++i) {
                            if (typeof object.tasks[i] !== "object")
                                throw TypeError(".infinitusai.be.GetTasksResponse.tasks: object expected");
                            message.tasks[i] = $root.infinitusai.be.CustomerTaskDoc.fromObject(object.tasks[i]);
                        }
                    }
                    if (object.taskInputs) {
                        if (!Array.isArray(object.taskInputs))
                            throw TypeError(".infinitusai.be.GetTasksResponse.taskInputs: array expected");
                        message.taskInputs = [];
                        for (var i = 0; i < object.taskInputs.length; ++i) {
                            if (typeof object.taskInputs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetTasksResponse.taskInputs: object expected");
                            message.taskInputs[i] = $root.infinitusai.be.GetTasksResponse.TaskInputListElement.fromObject(object.taskInputs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTasksResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {infinitusai.be.GetTasksResponse} message GetTasksResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTasksResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.tasks = [];
                        object.taskInputs = [];
                    }
                    if (message.tasks && message.tasks.length) {
                        object.tasks = [];
                        for (var j = 0; j < message.tasks.length; ++j)
                            object.tasks[j] = $root.infinitusai.be.CustomerTaskDoc.toObject(message.tasks[j], options);
                    }
                    if (message.taskInputs && message.taskInputs.length) {
                        object.taskInputs = [];
                        for (var j = 0; j < message.taskInputs.length; ++j)
                            object.taskInputs[j] = $root.infinitusai.be.GetTasksResponse.TaskInputListElement.toObject(message.taskInputs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetTasksResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTasksResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTasksResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTasksResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTasksResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTasksResponse";
                };
    
                GetTasksResponse.TaskInputListElement = (function() {
    
                    /**
                     * Properties of a TaskInputListElement.
                     * @memberof infinitusai.be.GetTasksResponse
                     * @interface ITaskInputListElement
                     * @property {infinitusapi.IINFTaskInput|null} [input] TaskInputListElement input
                     * @property {string|null} [taskUuid] TaskInputListElement taskUuid
                     */
    
                    /**
                     * Constructs a new TaskInputListElement.
                     * @memberof infinitusai.be.GetTasksResponse
                     * @classdesc Represents a TaskInputListElement.
                     * @implements ITaskInputListElement
                     * @constructor
                     * @param {infinitusai.be.GetTasksResponse.ITaskInputListElement=} [properties] Properties to set
                     */
                    function TaskInputListElement(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TaskInputListElement input.
                     * @member {infinitusapi.IINFTaskInput|null|undefined} input
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @instance
                     */
                    TaskInputListElement.prototype.input = null;
    
                    /**
                     * TaskInputListElement taskUuid.
                     * @member {string} taskUuid
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @instance
                     */
                    TaskInputListElement.prototype.taskUuid = "";
    
                    /**
                     * Creates a new TaskInputListElement instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {infinitusai.be.GetTasksResponse.ITaskInputListElement=} [properties] Properties to set
                     * @returns {infinitusai.be.GetTasksResponse.TaskInputListElement} TaskInputListElement instance
                     */
                    TaskInputListElement.create = function create(properties) {
                        return new TaskInputListElement(properties);
                    };
    
                    /**
                     * Encodes the specified TaskInputListElement message. Does not implicitly {@link infinitusai.be.GetTasksResponse.TaskInputListElement.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {infinitusai.be.GetTasksResponse.ITaskInputListElement} message TaskInputListElement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskInputListElement.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                            $root.infinitusapi.INFTaskInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TaskInputListElement message, length delimited. Does not implicitly {@link infinitusai.be.GetTasksResponse.TaskInputListElement.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {infinitusai.be.GetTasksResponse.ITaskInputListElement} message TaskInputListElement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskInputListElement.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TaskInputListElement message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.GetTasksResponse.TaskInputListElement} TaskInputListElement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskInputListElement.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTasksResponse.TaskInputListElement();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.input = $root.infinitusapi.INFTaskInput.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    message.taskUuid = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TaskInputListElement message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.GetTasksResponse.TaskInputListElement} TaskInputListElement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskInputListElement.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TaskInputListElement message.
                     * @function verify
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskInputListElement.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.input != null && message.hasOwnProperty("input")) {
                            var error = $root.infinitusapi.INFTaskInput.verify(message.input);
                            if (error)
                                return "input." + error;
                        }
                        if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                            if (!$util.isString(message.taskUuid))
                                return "taskUuid: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a TaskInputListElement message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.GetTasksResponse.TaskInputListElement} TaskInputListElement
                     */
                    TaskInputListElement.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.GetTasksResponse.TaskInputListElement)
                            return object;
                        var message = new $root.infinitusai.be.GetTasksResponse.TaskInputListElement();
                        if (object.input != null) {
                            if (typeof object.input !== "object")
                                throw TypeError(".infinitusai.be.GetTasksResponse.TaskInputListElement.input: object expected");
                            message.input = $root.infinitusapi.INFTaskInput.fromObject(object.input);
                        }
                        if (object.taskUuid != null)
                            message.taskUuid = String(object.taskUuid);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TaskInputListElement message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {infinitusai.be.GetTasksResponse.TaskInputListElement} message TaskInputListElement
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskInputListElement.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.input = null;
                            object.taskUuid = "";
                        }
                        if (message.input != null && message.hasOwnProperty("input"))
                            object.input = $root.infinitusapi.INFTaskInput.toObject(message.input, options);
                        if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                            object.taskUuid = message.taskUuid;
                        return object;
                    };
    
                    /**
                     * Converts this TaskInputListElement to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskInputListElement.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for TaskInputListElement
                     * @function getTypeUrl
                     * @memberof infinitusai.be.GetTasksResponse.TaskInputListElement
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TaskInputListElement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.GetTasksResponse.TaskInputListElement";
                    };
    
                    return TaskInputListElement;
                })();
    
                return GetTasksResponse;
            })();
    
            be.GetTaskCountsRequest = (function() {
    
                /**
                 * Properties of a GetTaskCountsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetTaskCountsRequest
                 */
    
                /**
                 * Constructs a new GetTaskCountsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTaskCountsRequest.
                 * @implements IGetTaskCountsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetTaskCountsRequest=} [properties] Properties to set
                 */
                function GetTaskCountsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetTaskCountsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskCountsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTaskCountsRequest} GetTaskCountsRequest instance
                 */
                GetTaskCountsRequest.create = function create(properties) {
                    return new GetTaskCountsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTaskCountsRequest message. Does not implicitly {@link infinitusai.be.GetTaskCountsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskCountsRequest} message GetTaskCountsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskCountsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTaskCountsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetTaskCountsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskCountsRequest} message GetTaskCountsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskCountsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTaskCountsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTaskCountsRequest} GetTaskCountsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskCountsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTaskCountsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTaskCountsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTaskCountsRequest} GetTaskCountsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskCountsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTaskCountsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTaskCountsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetTaskCountsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTaskCountsRequest} GetTaskCountsRequest
                 */
                GetTaskCountsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTaskCountsRequest)
                        return object;
                    return new $root.infinitusai.be.GetTaskCountsRequest();
                };
    
                /**
                 * Creates a plain object from a GetTaskCountsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {infinitusai.be.GetTaskCountsRequest} message GetTaskCountsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTaskCountsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetTaskCountsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTaskCountsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTaskCountsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTaskCountsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTaskCountsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTaskCountsRequest";
                };
    
                return GetTaskCountsRequest;
            })();
    
            be.GetTaskCountsResponse = (function() {
    
                /**
                 * Properties of a GetTaskCountsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetTaskCountsResponse
                 * @property {number|null} [tasksInQueueCount] GetTaskCountsResponse tasksInQueueCount
                 * @property {number|null} [tasksInProgressCount] GetTaskCountsResponse tasksInProgressCount
                 * @property {number|null} [tasksCompletedTodayCount] GetTaskCountsResponse tasksCompletedTodayCount
                 */
    
                /**
                 * Constructs a new GetTaskCountsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTaskCountsResponse.
                 * @implements IGetTaskCountsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetTaskCountsResponse=} [properties] Properties to set
                 */
                function GetTaskCountsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTaskCountsResponse tasksInQueueCount.
                 * @member {number} tasksInQueueCount
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @instance
                 */
                GetTaskCountsResponse.prototype.tasksInQueueCount = 0;
    
                /**
                 * GetTaskCountsResponse tasksInProgressCount.
                 * @member {number} tasksInProgressCount
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @instance
                 */
                GetTaskCountsResponse.prototype.tasksInProgressCount = 0;
    
                /**
                 * GetTaskCountsResponse tasksCompletedTodayCount.
                 * @member {number} tasksCompletedTodayCount
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @instance
                 */
                GetTaskCountsResponse.prototype.tasksCompletedTodayCount = 0;
    
                /**
                 * Creates a new GetTaskCountsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskCountsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTaskCountsResponse} GetTaskCountsResponse instance
                 */
                GetTaskCountsResponse.create = function create(properties) {
                    return new GetTaskCountsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetTaskCountsResponse message. Does not implicitly {@link infinitusai.be.GetTaskCountsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskCountsResponse} message GetTaskCountsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskCountsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tasksInQueueCount != null && Object.hasOwnProperty.call(message, "tasksInQueueCount"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tasksInQueueCount);
                    if (message.tasksInProgressCount != null && Object.hasOwnProperty.call(message, "tasksInProgressCount"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.tasksInProgressCount);
                    if (message.tasksCompletedTodayCount != null && Object.hasOwnProperty.call(message, "tasksCompletedTodayCount"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.tasksCompletedTodayCount);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTaskCountsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetTaskCountsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskCountsResponse} message GetTaskCountsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskCountsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTaskCountsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTaskCountsResponse} GetTaskCountsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskCountsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTaskCountsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.tasksInQueueCount = reader.int32();
                                break;
                            }
                        case 2: {
                                message.tasksInProgressCount = reader.int32();
                                break;
                            }
                        case 3: {
                                message.tasksCompletedTodayCount = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTaskCountsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTaskCountsResponse} GetTaskCountsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskCountsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTaskCountsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTaskCountsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tasksInQueueCount != null && message.hasOwnProperty("tasksInQueueCount"))
                        if (!$util.isInteger(message.tasksInQueueCount))
                            return "tasksInQueueCount: integer expected";
                    if (message.tasksInProgressCount != null && message.hasOwnProperty("tasksInProgressCount"))
                        if (!$util.isInteger(message.tasksInProgressCount))
                            return "tasksInProgressCount: integer expected";
                    if (message.tasksCompletedTodayCount != null && message.hasOwnProperty("tasksCompletedTodayCount"))
                        if (!$util.isInteger(message.tasksCompletedTodayCount))
                            return "tasksCompletedTodayCount: integer expected";
                    return null;
                };
    
                /**
                 * Creates a GetTaskCountsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTaskCountsResponse} GetTaskCountsResponse
                 */
                GetTaskCountsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTaskCountsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetTaskCountsResponse();
                    if (object.tasksInQueueCount != null)
                        message.tasksInQueueCount = object.tasksInQueueCount | 0;
                    if (object.tasksInProgressCount != null)
                        message.tasksInProgressCount = object.tasksInProgressCount | 0;
                    if (object.tasksCompletedTodayCount != null)
                        message.tasksCompletedTodayCount = object.tasksCompletedTodayCount | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTaskCountsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {infinitusai.be.GetTaskCountsResponse} message GetTaskCountsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTaskCountsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.tasksInQueueCount = 0;
                        object.tasksInProgressCount = 0;
                        object.tasksCompletedTodayCount = 0;
                    }
                    if (message.tasksInQueueCount != null && message.hasOwnProperty("tasksInQueueCount"))
                        object.tasksInQueueCount = message.tasksInQueueCount;
                    if (message.tasksInProgressCount != null && message.hasOwnProperty("tasksInProgressCount"))
                        object.tasksInProgressCount = message.tasksInProgressCount;
                    if (message.tasksCompletedTodayCount != null && message.hasOwnProperty("tasksCompletedTodayCount"))
                        object.tasksCompletedTodayCount = message.tasksCompletedTodayCount;
                    return object;
                };
    
                /**
                 * Converts this GetTaskCountsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTaskCountsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTaskCountsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTaskCountsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTaskCountsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTaskCountsResponse";
                };
    
                return GetTaskCountsResponse;
            })();
    
            be.GetTasksToReviewRequest = (function() {
    
                /**
                 * Properties of a GetTasksToReviewRequest.
                 * @memberof infinitusai.be
                 * @interface IGetTasksToReviewRequest
                 */
    
                /**
                 * Constructs a new GetTasksToReviewRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTasksToReviewRequest.
                 * @implements IGetTasksToReviewRequest
                 * @constructor
                 * @param {infinitusai.be.IGetTasksToReviewRequest=} [properties] Properties to set
                 */
                function GetTasksToReviewRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetTasksToReviewRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksToReviewRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTasksToReviewRequest} GetTasksToReviewRequest instance
                 */
                GetTasksToReviewRequest.create = function create(properties) {
                    return new GetTasksToReviewRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTasksToReviewRequest message. Does not implicitly {@link infinitusai.be.GetTasksToReviewRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksToReviewRequest} message GetTasksToReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksToReviewRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTasksToReviewRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetTasksToReviewRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {infinitusai.be.IGetTasksToReviewRequest} message GetTasksToReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksToReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTasksToReviewRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTasksToReviewRequest} GetTasksToReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksToReviewRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTasksToReviewRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTasksToReviewRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTasksToReviewRequest} GetTasksToReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksToReviewRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTasksToReviewRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTasksToReviewRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetTasksToReviewRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTasksToReviewRequest} GetTasksToReviewRequest
                 */
                GetTasksToReviewRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTasksToReviewRequest)
                        return object;
                    return new $root.infinitusai.be.GetTasksToReviewRequest();
                };
    
                /**
                 * Creates a plain object from a GetTasksToReviewRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {infinitusai.be.GetTasksToReviewRequest} message GetTasksToReviewRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTasksToReviewRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetTasksToReviewRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTasksToReviewRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTasksToReviewRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTasksToReviewRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTasksToReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTasksToReviewRequest";
                };
    
                return GetTasksToReviewRequest;
            })();
    
            be.GetTasksToReviewResponse = (function() {
    
                /**
                 * Properties of a GetTasksToReviewResponse.
                 * @memberof infinitusai.be
                 * @interface IGetTasksToReviewResponse
                 * @property {Array.<infinitusai.be.ICustomerTaskDoc>|null} [tasks] GetTasksToReviewResponse tasks
                 */
    
                /**
                 * Constructs a new GetTasksToReviewResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTasksToReviewResponse.
                 * @implements IGetTasksToReviewResponse
                 * @constructor
                 * @param {infinitusai.be.IGetTasksToReviewResponse=} [properties] Properties to set
                 */
                function GetTasksToReviewResponse(properties) {
                    this.tasks = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTasksToReviewResponse tasks.
                 * @member {Array.<infinitusai.be.ICustomerTaskDoc>} tasks
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @instance
                 */
                GetTasksToReviewResponse.prototype.tasks = $util.emptyArray;
    
                /**
                 * Creates a new GetTasksToReviewResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {infinitusai.be.IGetTasksToReviewResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTasksToReviewResponse} GetTasksToReviewResponse instance
                 */
                GetTasksToReviewResponse.create = function create(properties) {
                    return new GetTasksToReviewResponse(properties);
                };
    
                /**
                 * Encodes the specified GetTasksToReviewResponse message. Does not implicitly {@link infinitusai.be.GetTasksToReviewResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {infinitusai.be.IGetTasksToReviewResponse} message GetTasksToReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksToReviewResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tasks != null && message.tasks.length)
                        for (var i = 0; i < message.tasks.length; ++i)
                            $root.infinitusai.be.CustomerTaskDoc.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTasksToReviewResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetTasksToReviewResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {infinitusai.be.IGetTasksToReviewResponse} message GetTasksToReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTasksToReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTasksToReviewResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTasksToReviewResponse} GetTasksToReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksToReviewResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTasksToReviewResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.infinitusai.be.CustomerTaskDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTasksToReviewResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTasksToReviewResponse} GetTasksToReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTasksToReviewResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTasksToReviewResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTasksToReviewResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tasks != null && message.hasOwnProperty("tasks")) {
                        if (!Array.isArray(message.tasks))
                            return "tasks: array expected";
                        for (var i = 0; i < message.tasks.length; ++i) {
                            var error = $root.infinitusai.be.CustomerTaskDoc.verify(message.tasks[i]);
                            if (error)
                                return "tasks." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetTasksToReviewResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTasksToReviewResponse} GetTasksToReviewResponse
                 */
                GetTasksToReviewResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTasksToReviewResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetTasksToReviewResponse();
                    if (object.tasks) {
                        if (!Array.isArray(object.tasks))
                            throw TypeError(".infinitusai.be.GetTasksToReviewResponse.tasks: array expected");
                        message.tasks = [];
                        for (var i = 0; i < object.tasks.length; ++i) {
                            if (typeof object.tasks[i] !== "object")
                                throw TypeError(".infinitusai.be.GetTasksToReviewResponse.tasks: object expected");
                            message.tasks[i] = $root.infinitusai.be.CustomerTaskDoc.fromObject(object.tasks[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTasksToReviewResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {infinitusai.be.GetTasksToReviewResponse} message GetTasksToReviewResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTasksToReviewResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.tasks = [];
                    if (message.tasks && message.tasks.length) {
                        object.tasks = [];
                        for (var j = 0; j < message.tasks.length; ++j)
                            object.tasks[j] = $root.infinitusai.be.CustomerTaskDoc.toObject(message.tasks[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetTasksToReviewResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTasksToReviewResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTasksToReviewResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTasksToReviewResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTasksToReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTasksToReviewResponse";
                };
    
                return GetTasksToReviewResponse;
            })();
    
            be.DuplicateTaskRequest = (function() {
    
                /**
                 * Properties of a DuplicateTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IDuplicateTaskRequest
                 * @property {string|null} [taskUuid] DuplicateTaskRequest taskUuid
                 * @property {number|null} [numCopies] DuplicateTaskRequest numCopies
                 */
    
                /**
                 * Constructs a new DuplicateTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DuplicateTaskRequest.
                 * @implements IDuplicateTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IDuplicateTaskRequest=} [properties] Properties to set
                 */
                function DuplicateTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DuplicateTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @instance
                 */
                DuplicateTaskRequest.prototype.taskUuid = "";
    
                /**
                 * DuplicateTaskRequest numCopies.
                 * @member {number} numCopies
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @instance
                 */
                DuplicateTaskRequest.prototype.numCopies = 0;
    
                /**
                 * Creates a new DuplicateTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {infinitusai.be.IDuplicateTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DuplicateTaskRequest} DuplicateTaskRequest instance
                 */
                DuplicateTaskRequest.create = function create(properties) {
                    return new DuplicateTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified DuplicateTaskRequest message. Does not implicitly {@link infinitusai.be.DuplicateTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {infinitusai.be.IDuplicateTaskRequest} message DuplicateTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DuplicateTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.numCopies != null && Object.hasOwnProperty.call(message, "numCopies"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.numCopies);
                    return writer;
                };
    
                /**
                 * Encodes the specified DuplicateTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.DuplicateTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {infinitusai.be.IDuplicateTaskRequest} message DuplicateTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DuplicateTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DuplicateTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DuplicateTaskRequest} DuplicateTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DuplicateTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DuplicateTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.numCopies = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DuplicateTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DuplicateTaskRequest} DuplicateTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DuplicateTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DuplicateTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DuplicateTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.numCopies != null && message.hasOwnProperty("numCopies"))
                        if (!$util.isInteger(message.numCopies))
                            return "numCopies: integer expected";
                    return null;
                };
    
                /**
                 * Creates a DuplicateTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DuplicateTaskRequest} DuplicateTaskRequest
                 */
                DuplicateTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DuplicateTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.DuplicateTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.numCopies != null)
                        message.numCopies = object.numCopies | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a DuplicateTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {infinitusai.be.DuplicateTaskRequest} message DuplicateTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DuplicateTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.numCopies = 0;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.numCopies != null && message.hasOwnProperty("numCopies"))
                        object.numCopies = message.numCopies;
                    return object;
                };
    
                /**
                 * Converts this DuplicateTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DuplicateTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DuplicateTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DuplicateTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DuplicateTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DuplicateTaskRequest";
                };
    
                return DuplicateTaskRequest;
            })();
    
            be.DuplicateTaskResponse = (function() {
    
                /**
                 * Properties of a DuplicateTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IDuplicateTaskResponse
                 * @property {Array.<string>|null} [taskUuids] DuplicateTaskResponse taskUuids
                 */
    
                /**
                 * Constructs a new DuplicateTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DuplicateTaskResponse.
                 * @implements IDuplicateTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IDuplicateTaskResponse=} [properties] Properties to set
                 */
                function DuplicateTaskResponse(properties) {
                    this.taskUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DuplicateTaskResponse taskUuids.
                 * @member {Array.<string>} taskUuids
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @instance
                 */
                DuplicateTaskResponse.prototype.taskUuids = $util.emptyArray;
    
                /**
                 * Creates a new DuplicateTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {infinitusai.be.IDuplicateTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DuplicateTaskResponse} DuplicateTaskResponse instance
                 */
                DuplicateTaskResponse.create = function create(properties) {
                    return new DuplicateTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified DuplicateTaskResponse message. Does not implicitly {@link infinitusai.be.DuplicateTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {infinitusai.be.IDuplicateTaskResponse} message DuplicateTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DuplicateTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuids != null && message.taskUuids.length)
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified DuplicateTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.DuplicateTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {infinitusai.be.IDuplicateTaskResponse} message DuplicateTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DuplicateTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DuplicateTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DuplicateTaskResponse} DuplicateTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DuplicateTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DuplicateTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.taskUuids && message.taskUuids.length))
                                    message.taskUuids = [];
                                message.taskUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DuplicateTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DuplicateTaskResponse} DuplicateTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DuplicateTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DuplicateTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DuplicateTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuids != null && message.hasOwnProperty("taskUuids")) {
                        if (!Array.isArray(message.taskUuids))
                            return "taskUuids: array expected";
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            if (!$util.isString(message.taskUuids[i]))
                                return "taskUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a DuplicateTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DuplicateTaskResponse} DuplicateTaskResponse
                 */
                DuplicateTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DuplicateTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.DuplicateTaskResponse();
                    if (object.taskUuids) {
                        if (!Array.isArray(object.taskUuids))
                            throw TypeError(".infinitusai.be.DuplicateTaskResponse.taskUuids: array expected");
                        message.taskUuids = [];
                        for (var i = 0; i < object.taskUuids.length; ++i)
                            message.taskUuids[i] = String(object.taskUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DuplicateTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {infinitusai.be.DuplicateTaskResponse} message DuplicateTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DuplicateTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.taskUuids = [];
                    if (message.taskUuids && message.taskUuids.length) {
                        object.taskUuids = [];
                        for (var j = 0; j < message.taskUuids.length; ++j)
                            object.taskUuids[j] = message.taskUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this DuplicateTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DuplicateTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DuplicateTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DuplicateTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DuplicateTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DuplicateTaskResponse";
                };
    
                return DuplicateTaskResponse;
            })();
    
            be.CompleteTaskRequest = (function() {
    
                /**
                 * Properties of a CompleteTaskRequest.
                 * @memberof infinitusai.be
                 * @interface ICompleteTaskRequest
                 * @property {string|null} [taskUuid] CompleteTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new CompleteTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CompleteTaskRequest.
                 * @implements ICompleteTaskRequest
                 * @constructor
                 * @param {infinitusai.be.ICompleteTaskRequest=} [properties] Properties to set
                 */
                function CompleteTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CompleteTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @instance
                 */
                CompleteTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new CompleteTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {infinitusai.be.ICompleteTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CompleteTaskRequest} CompleteTaskRequest instance
                 */
                CompleteTaskRequest.create = function create(properties) {
                    return new CompleteTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified CompleteTaskRequest message. Does not implicitly {@link infinitusai.be.CompleteTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {infinitusai.be.ICompleteTaskRequest} message CompleteTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CompleteTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.CompleteTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {infinitusai.be.ICompleteTaskRequest} message CompleteTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CompleteTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CompleteTaskRequest} CompleteTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CompleteTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CompleteTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CompleteTaskRequest} CompleteTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CompleteTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CompleteTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CompleteTaskRequest} CompleteTaskRequest
                 */
                CompleteTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CompleteTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.CompleteTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CompleteTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {infinitusai.be.CompleteTaskRequest} message CompleteTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this CompleteTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CompleteTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CompleteTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CompleteTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CompleteTaskRequest";
                };
    
                return CompleteTaskRequest;
            })();
    
            be.CompleteTaskResponse = (function() {
    
                /**
                 * Properties of a CompleteTaskResponse.
                 * @memberof infinitusai.be
                 * @interface ICompleteTaskResponse
                 */
    
                /**
                 * Constructs a new CompleteTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CompleteTaskResponse.
                 * @implements ICompleteTaskResponse
                 * @constructor
                 * @param {infinitusai.be.ICompleteTaskResponse=} [properties] Properties to set
                 */
                function CompleteTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CompleteTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {infinitusai.be.ICompleteTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CompleteTaskResponse} CompleteTaskResponse instance
                 */
                CompleteTaskResponse.create = function create(properties) {
                    return new CompleteTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified CompleteTaskResponse message. Does not implicitly {@link infinitusai.be.CompleteTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {infinitusai.be.ICompleteTaskResponse} message CompleteTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CompleteTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.CompleteTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {infinitusai.be.ICompleteTaskResponse} message CompleteTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CompleteTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CompleteTaskResponse} CompleteTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CompleteTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CompleteTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CompleteTaskResponse} CompleteTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CompleteTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CompleteTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CompleteTaskResponse} CompleteTaskResponse
                 */
                CompleteTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CompleteTaskResponse)
                        return object;
                    return new $root.infinitusai.be.CompleteTaskResponse();
                };
    
                /**
                 * Creates a plain object from a CompleteTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {infinitusai.be.CompleteTaskResponse} message CompleteTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTaskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CompleteTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CompleteTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CompleteTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CompleteTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CompleteTaskResponse";
                };
    
                return CompleteTaskResponse;
            })();
    
            be.ConvertToOperatorTrainerTaskRequest = (function() {
    
                /**
                 * Properties of a ConvertToOperatorTrainerTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IConvertToOperatorTrainerTaskRequest
                 * @property {string|null} [taskUuid] ConvertToOperatorTrainerTaskRequest taskUuid
                 * @property {string|null} [operatorTrainerScriptId] ConvertToOperatorTrainerTaskRequest operatorTrainerScriptId
                 */
    
                /**
                 * Constructs a new ConvertToOperatorTrainerTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ConvertToOperatorTrainerTaskRequest.
                 * @implements IConvertToOperatorTrainerTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IConvertToOperatorTrainerTaskRequest=} [properties] Properties to set
                 */
                function ConvertToOperatorTrainerTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ConvertToOperatorTrainerTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @instance
                 */
                ConvertToOperatorTrainerTaskRequest.prototype.taskUuid = "";
    
                /**
                 * ConvertToOperatorTrainerTaskRequest operatorTrainerScriptId.
                 * @member {string} operatorTrainerScriptId
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @instance
                 */
                ConvertToOperatorTrainerTaskRequest.prototype.operatorTrainerScriptId = "";
    
                /**
                 * Creates a new ConvertToOperatorTrainerTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {infinitusai.be.IConvertToOperatorTrainerTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ConvertToOperatorTrainerTaskRequest} ConvertToOperatorTrainerTaskRequest instance
                 */
                ConvertToOperatorTrainerTaskRequest.create = function create(properties) {
                    return new ConvertToOperatorTrainerTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified ConvertToOperatorTrainerTaskRequest message. Does not implicitly {@link infinitusai.be.ConvertToOperatorTrainerTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {infinitusai.be.IConvertToOperatorTrainerTaskRequest} message ConvertToOperatorTrainerTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConvertToOperatorTrainerTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.operatorTrainerScriptId != null && Object.hasOwnProperty.call(message, "operatorTrainerScriptId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.operatorTrainerScriptId);
                    return writer;
                };
    
                /**
                 * Encodes the specified ConvertToOperatorTrainerTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.ConvertToOperatorTrainerTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {infinitusai.be.IConvertToOperatorTrainerTaskRequest} message ConvertToOperatorTrainerTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConvertToOperatorTrainerTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ConvertToOperatorTrainerTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ConvertToOperatorTrainerTaskRequest} ConvertToOperatorTrainerTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConvertToOperatorTrainerTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ConvertToOperatorTrainerTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.operatorTrainerScriptId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ConvertToOperatorTrainerTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ConvertToOperatorTrainerTaskRequest} ConvertToOperatorTrainerTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConvertToOperatorTrainerTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ConvertToOperatorTrainerTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConvertToOperatorTrainerTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.operatorTrainerScriptId != null && message.hasOwnProperty("operatorTrainerScriptId"))
                        if (!$util.isString(message.operatorTrainerScriptId))
                            return "operatorTrainerScriptId: string expected";
                    return null;
                };
    
                /**
                 * Creates a ConvertToOperatorTrainerTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ConvertToOperatorTrainerTaskRequest} ConvertToOperatorTrainerTaskRequest
                 */
                ConvertToOperatorTrainerTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ConvertToOperatorTrainerTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.ConvertToOperatorTrainerTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.operatorTrainerScriptId != null)
                        message.operatorTrainerScriptId = String(object.operatorTrainerScriptId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ConvertToOperatorTrainerTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {infinitusai.be.ConvertToOperatorTrainerTaskRequest} message ConvertToOperatorTrainerTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConvertToOperatorTrainerTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.operatorTrainerScriptId = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.operatorTrainerScriptId != null && message.hasOwnProperty("operatorTrainerScriptId"))
                        object.operatorTrainerScriptId = message.operatorTrainerScriptId;
                    return object;
                };
    
                /**
                 * Converts this ConvertToOperatorTrainerTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConvertToOperatorTrainerTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ConvertToOperatorTrainerTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ConvertToOperatorTrainerTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ConvertToOperatorTrainerTaskRequest";
                };
    
                return ConvertToOperatorTrainerTaskRequest;
            })();
    
            be.ConvertToOperatorTrainerTaskResponse = (function() {
    
                /**
                 * Properties of a ConvertToOperatorTrainerTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IConvertToOperatorTrainerTaskResponse
                 */
    
                /**
                 * Constructs a new ConvertToOperatorTrainerTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ConvertToOperatorTrainerTaskResponse.
                 * @implements IConvertToOperatorTrainerTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IConvertToOperatorTrainerTaskResponse=} [properties] Properties to set
                 */
                function ConvertToOperatorTrainerTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ConvertToOperatorTrainerTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {infinitusai.be.IConvertToOperatorTrainerTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ConvertToOperatorTrainerTaskResponse} ConvertToOperatorTrainerTaskResponse instance
                 */
                ConvertToOperatorTrainerTaskResponse.create = function create(properties) {
                    return new ConvertToOperatorTrainerTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified ConvertToOperatorTrainerTaskResponse message. Does not implicitly {@link infinitusai.be.ConvertToOperatorTrainerTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {infinitusai.be.IConvertToOperatorTrainerTaskResponse} message ConvertToOperatorTrainerTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConvertToOperatorTrainerTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ConvertToOperatorTrainerTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.ConvertToOperatorTrainerTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {infinitusai.be.IConvertToOperatorTrainerTaskResponse} message ConvertToOperatorTrainerTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConvertToOperatorTrainerTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ConvertToOperatorTrainerTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ConvertToOperatorTrainerTaskResponse} ConvertToOperatorTrainerTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConvertToOperatorTrainerTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ConvertToOperatorTrainerTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ConvertToOperatorTrainerTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ConvertToOperatorTrainerTaskResponse} ConvertToOperatorTrainerTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConvertToOperatorTrainerTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ConvertToOperatorTrainerTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConvertToOperatorTrainerTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ConvertToOperatorTrainerTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ConvertToOperatorTrainerTaskResponse} ConvertToOperatorTrainerTaskResponse
                 */
                ConvertToOperatorTrainerTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ConvertToOperatorTrainerTaskResponse)
                        return object;
                    return new $root.infinitusai.be.ConvertToOperatorTrainerTaskResponse();
                };
    
                /**
                 * Creates a plain object from a ConvertToOperatorTrainerTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {infinitusai.be.ConvertToOperatorTrainerTaskResponse} message ConvertToOperatorTrainerTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConvertToOperatorTrainerTaskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ConvertToOperatorTrainerTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConvertToOperatorTrainerTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ConvertToOperatorTrainerTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ConvertToOperatorTrainerTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ConvertToOperatorTrainerTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ConvertToOperatorTrainerTaskResponse";
                };
    
                return ConvertToOperatorTrainerTaskResponse;
            })();
    
            be.GetOperatorTrainerScriptIDRequest = (function() {
    
                /**
                 * Properties of a GetOperatorTrainerScriptIDRequest.
                 * @memberof infinitusai.be
                 * @interface IGetOperatorTrainerScriptIDRequest
                 * @property {infinitusai.be.TaskType|null} [taskType] GetOperatorTrainerScriptIDRequest taskType
                 */
    
                /**
                 * Constructs a new GetOperatorTrainerScriptIDRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOperatorTrainerScriptIDRequest.
                 * @implements IGetOperatorTrainerScriptIDRequest
                 * @constructor
                 * @param {infinitusai.be.IGetOperatorTrainerScriptIDRequest=} [properties] Properties to set
                 */
                function GetOperatorTrainerScriptIDRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOperatorTrainerScriptIDRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @instance
                 */
                GetOperatorTrainerScriptIDRequest.prototype.taskType = 0;
    
                /**
                 * Creates a new GetOperatorTrainerScriptIDRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptIDRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOperatorTrainerScriptIDRequest} GetOperatorTrainerScriptIDRequest instance
                 */
                GetOperatorTrainerScriptIDRequest.create = function create(properties) {
                    return new GetOperatorTrainerScriptIDRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOperatorTrainerScriptIDRequest message. Does not implicitly {@link infinitusai.be.GetOperatorTrainerScriptIDRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptIDRequest} message GetOperatorTrainerScriptIDRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorTrainerScriptIDRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOperatorTrainerScriptIDRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetOperatorTrainerScriptIDRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptIDRequest} message GetOperatorTrainerScriptIDRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorTrainerScriptIDRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOperatorTrainerScriptIDRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOperatorTrainerScriptIDRequest} GetOperatorTrainerScriptIDRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorTrainerScriptIDRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOperatorTrainerScriptIDRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOperatorTrainerScriptIDRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOperatorTrainerScriptIDRequest} GetOperatorTrainerScriptIDRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorTrainerScriptIDRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOperatorTrainerScriptIDRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOperatorTrainerScriptIDRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GetOperatorTrainerScriptIDRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOperatorTrainerScriptIDRequest} GetOperatorTrainerScriptIDRequest
                 */
                GetOperatorTrainerScriptIDRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOperatorTrainerScriptIDRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetOperatorTrainerScriptIDRequest();
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOperatorTrainerScriptIDRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {infinitusai.be.GetOperatorTrainerScriptIDRequest} message GetOperatorTrainerScriptIDRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOperatorTrainerScriptIDRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    return object;
                };
    
                /**
                 * Converts this GetOperatorTrainerScriptIDRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOperatorTrainerScriptIDRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOperatorTrainerScriptIDRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOperatorTrainerScriptIDRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOperatorTrainerScriptIDRequest";
                };
    
                return GetOperatorTrainerScriptIDRequest;
            })();
    
            be.GetOperatorTrainerScriptIDResponse = (function() {
    
                /**
                 * Properties of a GetOperatorTrainerScriptIDResponse.
                 * @memberof infinitusai.be
                 * @interface IGetOperatorTrainerScriptIDResponse
                 * @property {Array.<string>|null} [scriptID] GetOperatorTrainerScriptIDResponse scriptID
                 */
    
                /**
                 * Constructs a new GetOperatorTrainerScriptIDResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOperatorTrainerScriptIDResponse.
                 * @implements IGetOperatorTrainerScriptIDResponse
                 * @constructor
                 * @param {infinitusai.be.IGetOperatorTrainerScriptIDResponse=} [properties] Properties to set
                 */
                function GetOperatorTrainerScriptIDResponse(properties) {
                    this.scriptID = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOperatorTrainerScriptIDResponse scriptID.
                 * @member {Array.<string>} scriptID
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @instance
                 */
                GetOperatorTrainerScriptIDResponse.prototype.scriptID = $util.emptyArray;
    
                /**
                 * Creates a new GetOperatorTrainerScriptIDResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptIDResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOperatorTrainerScriptIDResponse} GetOperatorTrainerScriptIDResponse instance
                 */
                GetOperatorTrainerScriptIDResponse.create = function create(properties) {
                    return new GetOperatorTrainerScriptIDResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOperatorTrainerScriptIDResponse message. Does not implicitly {@link infinitusai.be.GetOperatorTrainerScriptIDResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptIDResponse} message GetOperatorTrainerScriptIDResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorTrainerScriptIDResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scriptID != null && message.scriptID.length)
                        for (var i = 0; i < message.scriptID.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.scriptID[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOperatorTrainerScriptIDResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetOperatorTrainerScriptIDResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptIDResponse} message GetOperatorTrainerScriptIDResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorTrainerScriptIDResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOperatorTrainerScriptIDResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOperatorTrainerScriptIDResponse} GetOperatorTrainerScriptIDResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorTrainerScriptIDResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOperatorTrainerScriptIDResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.scriptID && message.scriptID.length))
                                    message.scriptID = [];
                                message.scriptID.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOperatorTrainerScriptIDResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOperatorTrainerScriptIDResponse} GetOperatorTrainerScriptIDResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorTrainerScriptIDResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOperatorTrainerScriptIDResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOperatorTrainerScriptIDResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scriptID != null && message.hasOwnProperty("scriptID")) {
                        if (!Array.isArray(message.scriptID))
                            return "scriptID: array expected";
                        for (var i = 0; i < message.scriptID.length; ++i)
                            if (!$util.isString(message.scriptID[i]))
                                return "scriptID: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOperatorTrainerScriptIDResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOperatorTrainerScriptIDResponse} GetOperatorTrainerScriptIDResponse
                 */
                GetOperatorTrainerScriptIDResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOperatorTrainerScriptIDResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetOperatorTrainerScriptIDResponse();
                    if (object.scriptID) {
                        if (!Array.isArray(object.scriptID))
                            throw TypeError(".infinitusai.be.GetOperatorTrainerScriptIDResponse.scriptID: array expected");
                        message.scriptID = [];
                        for (var i = 0; i < object.scriptID.length; ++i)
                            message.scriptID[i] = String(object.scriptID[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOperatorTrainerScriptIDResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {infinitusai.be.GetOperatorTrainerScriptIDResponse} message GetOperatorTrainerScriptIDResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOperatorTrainerScriptIDResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.scriptID = [];
                    if (message.scriptID && message.scriptID.length) {
                        object.scriptID = [];
                        for (var j = 0; j < message.scriptID.length; ++j)
                            object.scriptID[j] = message.scriptID[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOperatorTrainerScriptIDResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOperatorTrainerScriptIDResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOperatorTrainerScriptIDResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOperatorTrainerScriptIDResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOperatorTrainerScriptIDResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOperatorTrainerScriptIDResponse";
                };
    
                return GetOperatorTrainerScriptIDResponse;
            })();
    
            be.UploadCsvOperatorTrainerScriptRequest = (function() {
    
                /**
                 * Properties of an UploadCsvOperatorTrainerScriptRequest.
                 * @memberof infinitusai.be
                 * @interface IUploadCsvOperatorTrainerScriptRequest
                 * @property {string|null} [scriptId] UploadCsvOperatorTrainerScriptRequest scriptId
                 * @property {infinitusai.be.TaskType|null} [taskType] UploadCsvOperatorTrainerScriptRequest taskType
                 * @property {string|null} [content] UploadCsvOperatorTrainerScriptRequest content
                 * @property {string|null} [orgUuid] UploadCsvOperatorTrainerScriptRequest orgUuid
                 */
    
                /**
                 * Constructs a new UploadCsvOperatorTrainerScriptRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UploadCsvOperatorTrainerScriptRequest.
                 * @implements IUploadCsvOperatorTrainerScriptRequest
                 * @constructor
                 * @param {infinitusai.be.IUploadCsvOperatorTrainerScriptRequest=} [properties] Properties to set
                 */
                function UploadCsvOperatorTrainerScriptRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UploadCsvOperatorTrainerScriptRequest scriptId.
                 * @member {string} scriptId
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @instance
                 */
                UploadCsvOperatorTrainerScriptRequest.prototype.scriptId = "";
    
                /**
                 * UploadCsvOperatorTrainerScriptRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @instance
                 */
                UploadCsvOperatorTrainerScriptRequest.prototype.taskType = 0;
    
                /**
                 * UploadCsvOperatorTrainerScriptRequest content.
                 * @member {string} content
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @instance
                 */
                UploadCsvOperatorTrainerScriptRequest.prototype.content = "";
    
                /**
                 * UploadCsvOperatorTrainerScriptRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @instance
                 */
                UploadCsvOperatorTrainerScriptRequest.prototype.orgUuid = "";
    
                /**
                 * Creates a new UploadCsvOperatorTrainerScriptRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IUploadCsvOperatorTrainerScriptRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UploadCsvOperatorTrainerScriptRequest} UploadCsvOperatorTrainerScriptRequest instance
                 */
                UploadCsvOperatorTrainerScriptRequest.create = function create(properties) {
                    return new UploadCsvOperatorTrainerScriptRequest(properties);
                };
    
                /**
                 * Encodes the specified UploadCsvOperatorTrainerScriptRequest message. Does not implicitly {@link infinitusai.be.UploadCsvOperatorTrainerScriptRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IUploadCsvOperatorTrainerScriptRequest} message UploadCsvOperatorTrainerScriptRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCsvOperatorTrainerScriptRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scriptId != null && Object.hasOwnProperty.call(message, "scriptId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scriptId);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.content);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadCsvOperatorTrainerScriptRequest message, length delimited. Does not implicitly {@link infinitusai.be.UploadCsvOperatorTrainerScriptRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IUploadCsvOperatorTrainerScriptRequest} message UploadCsvOperatorTrainerScriptRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCsvOperatorTrainerScriptRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadCsvOperatorTrainerScriptRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UploadCsvOperatorTrainerScriptRequest} UploadCsvOperatorTrainerScriptRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCsvOperatorTrainerScriptRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UploadCsvOperatorTrainerScriptRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.scriptId = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.content = reader.string();
                                break;
                            }
                        case 4: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadCsvOperatorTrainerScriptRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UploadCsvOperatorTrainerScriptRequest} UploadCsvOperatorTrainerScriptRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCsvOperatorTrainerScriptRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadCsvOperatorTrainerScriptRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadCsvOperatorTrainerScriptRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scriptId != null && message.hasOwnProperty("scriptId"))
                        if (!$util.isString(message.scriptId))
                            return "scriptId: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an UploadCsvOperatorTrainerScriptRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UploadCsvOperatorTrainerScriptRequest} UploadCsvOperatorTrainerScriptRequest
                 */
                UploadCsvOperatorTrainerScriptRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UploadCsvOperatorTrainerScriptRequest)
                        return object;
                    var message = new $root.infinitusai.be.UploadCsvOperatorTrainerScriptRequest();
                    if (object.scriptId != null)
                        message.scriptId = String(object.scriptId);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.content != null)
                        message.content = String(object.content);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UploadCsvOperatorTrainerScriptRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.UploadCsvOperatorTrainerScriptRequest} message UploadCsvOperatorTrainerScriptRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadCsvOperatorTrainerScriptRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.scriptId = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.content = "";
                        object.orgUuid = "";
                    }
                    if (message.scriptId != null && message.hasOwnProperty("scriptId"))
                        object.scriptId = message.scriptId;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this UploadCsvOperatorTrainerScriptRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadCsvOperatorTrainerScriptRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadCsvOperatorTrainerScriptRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadCsvOperatorTrainerScriptRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UploadCsvOperatorTrainerScriptRequest";
                };
    
                return UploadCsvOperatorTrainerScriptRequest;
            })();
    
            be.UploadCsvOperatorTrainerScriptResponse = (function() {
    
                /**
                 * Properties of an UploadCsvOperatorTrainerScriptResponse.
                 * @memberof infinitusai.be
                 * @interface IUploadCsvOperatorTrainerScriptResponse
                 */
    
                /**
                 * Constructs a new UploadCsvOperatorTrainerScriptResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UploadCsvOperatorTrainerScriptResponse.
                 * @implements IUploadCsvOperatorTrainerScriptResponse
                 * @constructor
                 * @param {infinitusai.be.IUploadCsvOperatorTrainerScriptResponse=} [properties] Properties to set
                 */
                function UploadCsvOperatorTrainerScriptResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UploadCsvOperatorTrainerScriptResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IUploadCsvOperatorTrainerScriptResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UploadCsvOperatorTrainerScriptResponse} UploadCsvOperatorTrainerScriptResponse instance
                 */
                UploadCsvOperatorTrainerScriptResponse.create = function create(properties) {
                    return new UploadCsvOperatorTrainerScriptResponse(properties);
                };
    
                /**
                 * Encodes the specified UploadCsvOperatorTrainerScriptResponse message. Does not implicitly {@link infinitusai.be.UploadCsvOperatorTrainerScriptResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IUploadCsvOperatorTrainerScriptResponse} message UploadCsvOperatorTrainerScriptResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCsvOperatorTrainerScriptResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadCsvOperatorTrainerScriptResponse message, length delimited. Does not implicitly {@link infinitusai.be.UploadCsvOperatorTrainerScriptResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IUploadCsvOperatorTrainerScriptResponse} message UploadCsvOperatorTrainerScriptResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCsvOperatorTrainerScriptResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadCsvOperatorTrainerScriptResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UploadCsvOperatorTrainerScriptResponse} UploadCsvOperatorTrainerScriptResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCsvOperatorTrainerScriptResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UploadCsvOperatorTrainerScriptResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadCsvOperatorTrainerScriptResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UploadCsvOperatorTrainerScriptResponse} UploadCsvOperatorTrainerScriptResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCsvOperatorTrainerScriptResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadCsvOperatorTrainerScriptResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadCsvOperatorTrainerScriptResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UploadCsvOperatorTrainerScriptResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UploadCsvOperatorTrainerScriptResponse} UploadCsvOperatorTrainerScriptResponse
                 */
                UploadCsvOperatorTrainerScriptResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UploadCsvOperatorTrainerScriptResponse)
                        return object;
                    return new $root.infinitusai.be.UploadCsvOperatorTrainerScriptResponse();
                };
    
                /**
                 * Creates a plain object from an UploadCsvOperatorTrainerScriptResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.UploadCsvOperatorTrainerScriptResponse} message UploadCsvOperatorTrainerScriptResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadCsvOperatorTrainerScriptResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UploadCsvOperatorTrainerScriptResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadCsvOperatorTrainerScriptResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadCsvOperatorTrainerScriptResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UploadCsvOperatorTrainerScriptResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadCsvOperatorTrainerScriptResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UploadCsvOperatorTrainerScriptResponse";
                };
    
                return UploadCsvOperatorTrainerScriptResponse;
            })();
    
            be.DeleteOperatorTrainerScriptRequest = (function() {
    
                /**
                 * Properties of a DeleteOperatorTrainerScriptRequest.
                 * @memberof infinitusai.be
                 * @interface IDeleteOperatorTrainerScriptRequest
                 * @property {string|null} [scriptId] DeleteOperatorTrainerScriptRequest scriptId
                 * @property {string|null} [orgUuid] DeleteOperatorTrainerScriptRequest orgUuid
                 */
    
                /**
                 * Constructs a new DeleteOperatorTrainerScriptRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteOperatorTrainerScriptRequest.
                 * @implements IDeleteOperatorTrainerScriptRequest
                 * @constructor
                 * @param {infinitusai.be.IDeleteOperatorTrainerScriptRequest=} [properties] Properties to set
                 */
                function DeleteOperatorTrainerScriptRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteOperatorTrainerScriptRequest scriptId.
                 * @member {string} scriptId
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @instance
                 */
                DeleteOperatorTrainerScriptRequest.prototype.scriptId = "";
    
                /**
                 * DeleteOperatorTrainerScriptRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @instance
                 */
                DeleteOperatorTrainerScriptRequest.prototype.orgUuid = "";
    
                /**
                 * Creates a new DeleteOperatorTrainerScriptRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorTrainerScriptRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteOperatorTrainerScriptRequest} DeleteOperatorTrainerScriptRequest instance
                 */
                DeleteOperatorTrainerScriptRequest.create = function create(properties) {
                    return new DeleteOperatorTrainerScriptRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteOperatorTrainerScriptRequest message. Does not implicitly {@link infinitusai.be.DeleteOperatorTrainerScriptRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorTrainerScriptRequest} message DeleteOperatorTrainerScriptRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteOperatorTrainerScriptRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scriptId != null && Object.hasOwnProperty.call(message, "scriptId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scriptId);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteOperatorTrainerScriptRequest message, length delimited. Does not implicitly {@link infinitusai.be.DeleteOperatorTrainerScriptRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorTrainerScriptRequest} message DeleteOperatorTrainerScriptRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteOperatorTrainerScriptRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteOperatorTrainerScriptRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteOperatorTrainerScriptRequest} DeleteOperatorTrainerScriptRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteOperatorTrainerScriptRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteOperatorTrainerScriptRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.scriptId = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteOperatorTrainerScriptRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteOperatorTrainerScriptRequest} DeleteOperatorTrainerScriptRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteOperatorTrainerScriptRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteOperatorTrainerScriptRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteOperatorTrainerScriptRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scriptId != null && message.hasOwnProperty("scriptId"))
                        if (!$util.isString(message.scriptId))
                            return "scriptId: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteOperatorTrainerScriptRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteOperatorTrainerScriptRequest} DeleteOperatorTrainerScriptRequest
                 */
                DeleteOperatorTrainerScriptRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteOperatorTrainerScriptRequest)
                        return object;
                    var message = new $root.infinitusai.be.DeleteOperatorTrainerScriptRequest();
                    if (object.scriptId != null)
                        message.scriptId = String(object.scriptId);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteOperatorTrainerScriptRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.DeleteOperatorTrainerScriptRequest} message DeleteOperatorTrainerScriptRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteOperatorTrainerScriptRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.scriptId = "";
                        object.orgUuid = "";
                    }
                    if (message.scriptId != null && message.hasOwnProperty("scriptId"))
                        object.scriptId = message.scriptId;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this DeleteOperatorTrainerScriptRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteOperatorTrainerScriptRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteOperatorTrainerScriptRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteOperatorTrainerScriptRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteOperatorTrainerScriptRequest";
                };
    
                return DeleteOperatorTrainerScriptRequest;
            })();
    
            be.DeleteOperatorTrainerScriptResponse = (function() {
    
                /**
                 * Properties of a DeleteOperatorTrainerScriptResponse.
                 * @memberof infinitusai.be
                 * @interface IDeleteOperatorTrainerScriptResponse
                 */
    
                /**
                 * Constructs a new DeleteOperatorTrainerScriptResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteOperatorTrainerScriptResponse.
                 * @implements IDeleteOperatorTrainerScriptResponse
                 * @constructor
                 * @param {infinitusai.be.IDeleteOperatorTrainerScriptResponse=} [properties] Properties to set
                 */
                function DeleteOperatorTrainerScriptResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteOperatorTrainerScriptResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorTrainerScriptResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteOperatorTrainerScriptResponse} DeleteOperatorTrainerScriptResponse instance
                 */
                DeleteOperatorTrainerScriptResponse.create = function create(properties) {
                    return new DeleteOperatorTrainerScriptResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteOperatorTrainerScriptResponse message. Does not implicitly {@link infinitusai.be.DeleteOperatorTrainerScriptResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorTrainerScriptResponse} message DeleteOperatorTrainerScriptResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteOperatorTrainerScriptResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteOperatorTrainerScriptResponse message, length delimited. Does not implicitly {@link infinitusai.be.DeleteOperatorTrainerScriptResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorTrainerScriptResponse} message DeleteOperatorTrainerScriptResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteOperatorTrainerScriptResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteOperatorTrainerScriptResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteOperatorTrainerScriptResponse} DeleteOperatorTrainerScriptResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteOperatorTrainerScriptResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteOperatorTrainerScriptResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteOperatorTrainerScriptResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteOperatorTrainerScriptResponse} DeleteOperatorTrainerScriptResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteOperatorTrainerScriptResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteOperatorTrainerScriptResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteOperatorTrainerScriptResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteOperatorTrainerScriptResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteOperatorTrainerScriptResponse} DeleteOperatorTrainerScriptResponse
                 */
                DeleteOperatorTrainerScriptResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteOperatorTrainerScriptResponse)
                        return object;
                    return new $root.infinitusai.be.DeleteOperatorTrainerScriptResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteOperatorTrainerScriptResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.DeleteOperatorTrainerScriptResponse} message DeleteOperatorTrainerScriptResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteOperatorTrainerScriptResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteOperatorTrainerScriptResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteOperatorTrainerScriptResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteOperatorTrainerScriptResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteOperatorTrainerScriptResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteOperatorTrainerScriptResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteOperatorTrainerScriptResponse";
                };
    
                return DeleteOperatorTrainerScriptResponse;
            })();
    
            be.UpdateOperatorTrainerCallOutputBagsRequest = (function() {
    
                /**
                 * Properties of an UpdateOperatorTrainerCallOutputBagsRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateOperatorTrainerCallOutputBagsRequest
                 * @property {string|null} [scriptId] UpdateOperatorTrainerCallOutputBagsRequest scriptId
                 * @property {string|null} [link] UpdateOperatorTrainerCallOutputBagsRequest link
                 * @property {string|null} [orgUuid] UpdateOperatorTrainerCallOutputBagsRequest orgUuid
                 */
    
                /**
                 * Constructs a new UpdateOperatorTrainerCallOutputBagsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateOperatorTrainerCallOutputBagsRequest.
                 * @implements IUpdateOperatorTrainerCallOutputBagsRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateOperatorTrainerCallOutputBagsRequest=} [properties] Properties to set
                 */
                function UpdateOperatorTrainerCallOutputBagsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateOperatorTrainerCallOutputBagsRequest scriptId.
                 * @member {string} scriptId
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @instance
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.prototype.scriptId = "";
    
                /**
                 * UpdateOperatorTrainerCallOutputBagsRequest link.
                 * @member {string} link
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @instance
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.prototype.link = "";
    
                /**
                 * UpdateOperatorTrainerCallOutputBagsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @instance
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.prototype.orgUuid = "";
    
                /**
                 * Creates a new UpdateOperatorTrainerCallOutputBagsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {infinitusai.be.IUpdateOperatorTrainerCallOutputBagsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest} UpdateOperatorTrainerCallOutputBagsRequest instance
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.create = function create(properties) {
                    return new UpdateOperatorTrainerCallOutputBagsRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateOperatorTrainerCallOutputBagsRequest message. Does not implicitly {@link infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {infinitusai.be.IUpdateOperatorTrainerCallOutputBagsRequest} message UpdateOperatorTrainerCallOutputBagsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scriptId != null && Object.hasOwnProperty.call(message, "scriptId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scriptId);
                    if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.link);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateOperatorTrainerCallOutputBagsRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {infinitusai.be.IUpdateOperatorTrainerCallOutputBagsRequest} message UpdateOperatorTrainerCallOutputBagsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateOperatorTrainerCallOutputBagsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest} UpdateOperatorTrainerCallOutputBagsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.scriptId = reader.string();
                                break;
                            }
                        case 2: {
                                message.link = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateOperatorTrainerCallOutputBagsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest} UpdateOperatorTrainerCallOutputBagsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateOperatorTrainerCallOutputBagsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scriptId != null && message.hasOwnProperty("scriptId"))
                        if (!$util.isString(message.scriptId))
                            return "scriptId: string expected";
                    if (message.link != null && message.hasOwnProperty("link"))
                        if (!$util.isString(message.link))
                            return "link: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateOperatorTrainerCallOutputBagsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest} UpdateOperatorTrainerCallOutputBagsRequest
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest();
                    if (object.scriptId != null)
                        message.scriptId = String(object.scriptId);
                    if (object.link != null)
                        message.link = String(object.link);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateOperatorTrainerCallOutputBagsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest} message UpdateOperatorTrainerCallOutputBagsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.scriptId = "";
                        object.link = "";
                        object.orgUuid = "";
                    }
                    if (message.scriptId != null && message.hasOwnProperty("scriptId"))
                        object.scriptId = message.scriptId;
                    if (message.link != null && message.hasOwnProperty("link"))
                        object.link = message.link;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this UpdateOperatorTrainerCallOutputBagsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateOperatorTrainerCallOutputBagsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateOperatorTrainerCallOutputBagsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest";
                };
    
                return UpdateOperatorTrainerCallOutputBagsRequest;
            })();
    
            be.UpdateOperatorTrainerCallOutputBagsResponse = (function() {
    
                /**
                 * Properties of an UpdateOperatorTrainerCallOutputBagsResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateOperatorTrainerCallOutputBagsResponse
                 */
    
                /**
                 * Constructs a new UpdateOperatorTrainerCallOutputBagsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateOperatorTrainerCallOutputBagsResponse.
                 * @implements IUpdateOperatorTrainerCallOutputBagsResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateOperatorTrainerCallOutputBagsResponse=} [properties] Properties to set
                 */
                function UpdateOperatorTrainerCallOutputBagsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpdateOperatorTrainerCallOutputBagsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {infinitusai.be.IUpdateOperatorTrainerCallOutputBagsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse} UpdateOperatorTrainerCallOutputBagsResponse instance
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.create = function create(properties) {
                    return new UpdateOperatorTrainerCallOutputBagsResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateOperatorTrainerCallOutputBagsResponse message. Does not implicitly {@link infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {infinitusai.be.IUpdateOperatorTrainerCallOutputBagsResponse} message UpdateOperatorTrainerCallOutputBagsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateOperatorTrainerCallOutputBagsResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {infinitusai.be.IUpdateOperatorTrainerCallOutputBagsResponse} message UpdateOperatorTrainerCallOutputBagsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateOperatorTrainerCallOutputBagsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse} UpdateOperatorTrainerCallOutputBagsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateOperatorTrainerCallOutputBagsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse} UpdateOperatorTrainerCallOutputBagsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateOperatorTrainerCallOutputBagsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateOperatorTrainerCallOutputBagsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse} UpdateOperatorTrainerCallOutputBagsResponse
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse)
                        return object;
                    return new $root.infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse();
                };
    
                /**
                 * Creates a plain object from an UpdateOperatorTrainerCallOutputBagsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse} message UpdateOperatorTrainerCallOutputBagsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpdateOperatorTrainerCallOutputBagsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateOperatorTrainerCallOutputBagsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateOperatorTrainerCallOutputBagsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse";
                };
    
                return UpdateOperatorTrainerCallOutputBagsResponse;
            })();
    
            be.GetOperatorTrainerScriptRequest = (function() {
    
                /**
                 * Properties of a GetOperatorTrainerScriptRequest.
                 * @memberof infinitusai.be
                 * @interface IGetOperatorTrainerScriptRequest
                 * @property {string|null} [scriptId] GetOperatorTrainerScriptRequest scriptId
                 * @property {string|null} [orgUuid] GetOperatorTrainerScriptRequest orgUuid
                 */
    
                /**
                 * Constructs a new GetOperatorTrainerScriptRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOperatorTrainerScriptRequest.
                 * @implements IGetOperatorTrainerScriptRequest
                 * @constructor
                 * @param {infinitusai.be.IGetOperatorTrainerScriptRequest=} [properties] Properties to set
                 */
                function GetOperatorTrainerScriptRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOperatorTrainerScriptRequest scriptId.
                 * @member {string} scriptId
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @instance
                 */
                GetOperatorTrainerScriptRequest.prototype.scriptId = "";
    
                /**
                 * GetOperatorTrainerScriptRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @instance
                 */
                GetOperatorTrainerScriptRequest.prototype.orgUuid = "";
    
                /**
                 * Creates a new GetOperatorTrainerScriptRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOperatorTrainerScriptRequest} GetOperatorTrainerScriptRequest instance
                 */
                GetOperatorTrainerScriptRequest.create = function create(properties) {
                    return new GetOperatorTrainerScriptRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOperatorTrainerScriptRequest message. Does not implicitly {@link infinitusai.be.GetOperatorTrainerScriptRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptRequest} message GetOperatorTrainerScriptRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorTrainerScriptRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scriptId != null && Object.hasOwnProperty.call(message, "scriptId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scriptId);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOperatorTrainerScriptRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetOperatorTrainerScriptRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptRequest} message GetOperatorTrainerScriptRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorTrainerScriptRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOperatorTrainerScriptRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOperatorTrainerScriptRequest} GetOperatorTrainerScriptRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorTrainerScriptRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOperatorTrainerScriptRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.scriptId = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOperatorTrainerScriptRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOperatorTrainerScriptRequest} GetOperatorTrainerScriptRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorTrainerScriptRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOperatorTrainerScriptRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOperatorTrainerScriptRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scriptId != null && message.hasOwnProperty("scriptId"))
                        if (!$util.isString(message.scriptId))
                            return "scriptId: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetOperatorTrainerScriptRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOperatorTrainerScriptRequest} GetOperatorTrainerScriptRequest
                 */
                GetOperatorTrainerScriptRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOperatorTrainerScriptRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetOperatorTrainerScriptRequest();
                    if (object.scriptId != null)
                        message.scriptId = String(object.scriptId);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOperatorTrainerScriptRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {infinitusai.be.GetOperatorTrainerScriptRequest} message GetOperatorTrainerScriptRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOperatorTrainerScriptRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.scriptId = "";
                        object.orgUuid = "";
                    }
                    if (message.scriptId != null && message.hasOwnProperty("scriptId"))
                        object.scriptId = message.scriptId;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this GetOperatorTrainerScriptRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOperatorTrainerScriptRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOperatorTrainerScriptRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOperatorTrainerScriptRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOperatorTrainerScriptRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOperatorTrainerScriptRequest";
                };
    
                return GetOperatorTrainerScriptRequest;
            })();
    
            be.GetOperatorTrainerScriptResponse = (function() {
    
                /**
                 * Properties of a GetOperatorTrainerScriptResponse.
                 * @memberof infinitusai.be
                 * @interface IGetOperatorTrainerScriptResponse
                 * @property {Array.<infinitusai.be.IAbeTranscriptLog>|null} [abeTranscriptLog] GetOperatorTrainerScriptResponse abeTranscriptLog
                 */
    
                /**
                 * Constructs a new GetOperatorTrainerScriptResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOperatorTrainerScriptResponse.
                 * @implements IGetOperatorTrainerScriptResponse
                 * @constructor
                 * @param {infinitusai.be.IGetOperatorTrainerScriptResponse=} [properties] Properties to set
                 */
                function GetOperatorTrainerScriptResponse(properties) {
                    this.abeTranscriptLog = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOperatorTrainerScriptResponse abeTranscriptLog.
                 * @member {Array.<infinitusai.be.IAbeTranscriptLog>} abeTranscriptLog
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @instance
                 */
                GetOperatorTrainerScriptResponse.prototype.abeTranscriptLog = $util.emptyArray;
    
                /**
                 * Creates a new GetOperatorTrainerScriptResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOperatorTrainerScriptResponse} GetOperatorTrainerScriptResponse instance
                 */
                GetOperatorTrainerScriptResponse.create = function create(properties) {
                    return new GetOperatorTrainerScriptResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOperatorTrainerScriptResponse message. Does not implicitly {@link infinitusai.be.GetOperatorTrainerScriptResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptResponse} message GetOperatorTrainerScriptResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorTrainerScriptResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.abeTranscriptLog != null && message.abeTranscriptLog.length)
                        for (var i = 0; i < message.abeTranscriptLog.length; ++i)
                            $root.infinitusai.be.AbeTranscriptLog.encode(message.abeTranscriptLog[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOperatorTrainerScriptResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetOperatorTrainerScriptResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorTrainerScriptResponse} message GetOperatorTrainerScriptResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorTrainerScriptResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOperatorTrainerScriptResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOperatorTrainerScriptResponse} GetOperatorTrainerScriptResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorTrainerScriptResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOperatorTrainerScriptResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.abeTranscriptLog && message.abeTranscriptLog.length))
                                    message.abeTranscriptLog = [];
                                message.abeTranscriptLog.push($root.infinitusai.be.AbeTranscriptLog.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOperatorTrainerScriptResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOperatorTrainerScriptResponse} GetOperatorTrainerScriptResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorTrainerScriptResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOperatorTrainerScriptResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOperatorTrainerScriptResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.abeTranscriptLog != null && message.hasOwnProperty("abeTranscriptLog")) {
                        if (!Array.isArray(message.abeTranscriptLog))
                            return "abeTranscriptLog: array expected";
                        for (var i = 0; i < message.abeTranscriptLog.length; ++i) {
                            var error = $root.infinitusai.be.AbeTranscriptLog.verify(message.abeTranscriptLog[i]);
                            if (error)
                                return "abeTranscriptLog." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOperatorTrainerScriptResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOperatorTrainerScriptResponse} GetOperatorTrainerScriptResponse
                 */
                GetOperatorTrainerScriptResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOperatorTrainerScriptResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetOperatorTrainerScriptResponse();
                    if (object.abeTranscriptLog) {
                        if (!Array.isArray(object.abeTranscriptLog))
                            throw TypeError(".infinitusai.be.GetOperatorTrainerScriptResponse.abeTranscriptLog: array expected");
                        message.abeTranscriptLog = [];
                        for (var i = 0; i < object.abeTranscriptLog.length; ++i) {
                            if (typeof object.abeTranscriptLog[i] !== "object")
                                throw TypeError(".infinitusai.be.GetOperatorTrainerScriptResponse.abeTranscriptLog: object expected");
                            message.abeTranscriptLog[i] = $root.infinitusai.be.AbeTranscriptLog.fromObject(object.abeTranscriptLog[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOperatorTrainerScriptResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {infinitusai.be.GetOperatorTrainerScriptResponse} message GetOperatorTrainerScriptResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOperatorTrainerScriptResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.abeTranscriptLog = [];
                    if (message.abeTranscriptLog && message.abeTranscriptLog.length) {
                        object.abeTranscriptLog = [];
                        for (var j = 0; j < message.abeTranscriptLog.length; ++j)
                            object.abeTranscriptLog[j] = $root.infinitusai.be.AbeTranscriptLog.toObject(message.abeTranscriptLog[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOperatorTrainerScriptResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOperatorTrainerScriptResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOperatorTrainerScriptResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOperatorTrainerScriptResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOperatorTrainerScriptResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOperatorTrainerScriptResponse";
                };
    
                return GetOperatorTrainerScriptResponse;
            })();
    
            be.GetMultiBenefitTasksRequest = (function() {
    
                /**
                 * Properties of a GetMultiBenefitTasksRequest.
                 * @memberof infinitusai.be
                 * @interface IGetMultiBenefitTasksRequest
                 * @property {string|null} [taskUuid] GetMultiBenefitTasksRequest taskUuid
                 */
    
                /**
                 * Constructs a new GetMultiBenefitTasksRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetMultiBenefitTasksRequest.
                 * @implements IGetMultiBenefitTasksRequest
                 * @constructor
                 * @param {infinitusai.be.IGetMultiBenefitTasksRequest=} [properties] Properties to set
                 */
                function GetMultiBenefitTasksRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMultiBenefitTasksRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @instance
                 */
                GetMultiBenefitTasksRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new GetMultiBenefitTasksRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetMultiBenefitTasksRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetMultiBenefitTasksRequest} GetMultiBenefitTasksRequest instance
                 */
                GetMultiBenefitTasksRequest.create = function create(properties) {
                    return new GetMultiBenefitTasksRequest(properties);
                };
    
                /**
                 * Encodes the specified GetMultiBenefitTasksRequest message. Does not implicitly {@link infinitusai.be.GetMultiBenefitTasksRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetMultiBenefitTasksRequest} message GetMultiBenefitTasksRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMultiBenefitTasksRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMultiBenefitTasksRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetMultiBenefitTasksRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetMultiBenefitTasksRequest} message GetMultiBenefitTasksRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMultiBenefitTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMultiBenefitTasksRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetMultiBenefitTasksRequest} GetMultiBenefitTasksRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMultiBenefitTasksRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetMultiBenefitTasksRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMultiBenefitTasksRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetMultiBenefitTasksRequest} GetMultiBenefitTasksRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMultiBenefitTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMultiBenefitTasksRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMultiBenefitTasksRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetMultiBenefitTasksRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetMultiBenefitTasksRequest} GetMultiBenefitTasksRequest
                 */
                GetMultiBenefitTasksRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetMultiBenefitTasksRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetMultiBenefitTasksRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMultiBenefitTasksRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {infinitusai.be.GetMultiBenefitTasksRequest} message GetMultiBenefitTasksRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMultiBenefitTasksRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this GetMultiBenefitTasksRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMultiBenefitTasksRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMultiBenefitTasksRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetMultiBenefitTasksRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMultiBenefitTasksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetMultiBenefitTasksRequest";
                };
    
                return GetMultiBenefitTasksRequest;
            })();
    
            be.MultiBenefitTasksRequest = (function() {
    
                /**
                 * Properties of a MultiBenefitTasksRequest.
                 * @memberof infinitusai.be
                 * @interface IMultiBenefitTasksRequest
                 * @property {string|null} [taskUuid] MultiBenefitTasksRequest taskUuid
                 */
    
                /**
                 * Constructs a new MultiBenefitTasksRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MultiBenefitTasksRequest.
                 * @implements IMultiBenefitTasksRequest
                 * @constructor
                 * @param {infinitusai.be.IMultiBenefitTasksRequest=} [properties] Properties to set
                 */
                function MultiBenefitTasksRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MultiBenefitTasksRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @instance
                 */
                MultiBenefitTasksRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new MultiBenefitTasksRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {infinitusai.be.IMultiBenefitTasksRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.MultiBenefitTasksRequest} MultiBenefitTasksRequest instance
                 */
                MultiBenefitTasksRequest.create = function create(properties) {
                    return new MultiBenefitTasksRequest(properties);
                };
    
                /**
                 * Encodes the specified MultiBenefitTasksRequest message. Does not implicitly {@link infinitusai.be.MultiBenefitTasksRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {infinitusai.be.IMultiBenefitTasksRequest} message MultiBenefitTasksRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiBenefitTasksRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified MultiBenefitTasksRequest message, length delimited. Does not implicitly {@link infinitusai.be.MultiBenefitTasksRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {infinitusai.be.IMultiBenefitTasksRequest} message MultiBenefitTasksRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiBenefitTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MultiBenefitTasksRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MultiBenefitTasksRequest} MultiBenefitTasksRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiBenefitTasksRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MultiBenefitTasksRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MultiBenefitTasksRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MultiBenefitTasksRequest} MultiBenefitTasksRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiBenefitTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MultiBenefitTasksRequest message.
                 * @function verify
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MultiBenefitTasksRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a MultiBenefitTasksRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MultiBenefitTasksRequest} MultiBenefitTasksRequest
                 */
                MultiBenefitTasksRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MultiBenefitTasksRequest)
                        return object;
                    var message = new $root.infinitusai.be.MultiBenefitTasksRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a MultiBenefitTasksRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {infinitusai.be.MultiBenefitTasksRequest} message MultiBenefitTasksRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MultiBenefitTasksRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this MultiBenefitTasksRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MultiBenefitTasksRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MultiBenefitTasksRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MultiBenefitTasksRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MultiBenefitTasksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MultiBenefitTasksRequest";
                };
    
                return MultiBenefitTasksRequest;
            })();
    
            be.MultiBenefitTasksResponse = (function() {
    
                /**
                 * Properties of a MultiBenefitTasksResponse.
                 * @memberof infinitusai.be
                 * @interface IMultiBenefitTasksResponse
                 */
    
                /**
                 * Constructs a new MultiBenefitTasksResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MultiBenefitTasksResponse.
                 * @implements IMultiBenefitTasksResponse
                 * @constructor
                 * @param {infinitusai.be.IMultiBenefitTasksResponse=} [properties] Properties to set
                 */
                function MultiBenefitTasksResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new MultiBenefitTasksResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {infinitusai.be.IMultiBenefitTasksResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.MultiBenefitTasksResponse} MultiBenefitTasksResponse instance
                 */
                MultiBenefitTasksResponse.create = function create(properties) {
                    return new MultiBenefitTasksResponse(properties);
                };
    
                /**
                 * Encodes the specified MultiBenefitTasksResponse message. Does not implicitly {@link infinitusai.be.MultiBenefitTasksResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {infinitusai.be.IMultiBenefitTasksResponse} message MultiBenefitTasksResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiBenefitTasksResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified MultiBenefitTasksResponse message, length delimited. Does not implicitly {@link infinitusai.be.MultiBenefitTasksResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {infinitusai.be.IMultiBenefitTasksResponse} message MultiBenefitTasksResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiBenefitTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MultiBenefitTasksResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MultiBenefitTasksResponse} MultiBenefitTasksResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiBenefitTasksResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MultiBenefitTasksResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MultiBenefitTasksResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MultiBenefitTasksResponse} MultiBenefitTasksResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiBenefitTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MultiBenefitTasksResponse message.
                 * @function verify
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MultiBenefitTasksResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a MultiBenefitTasksResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MultiBenefitTasksResponse} MultiBenefitTasksResponse
                 */
                MultiBenefitTasksResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MultiBenefitTasksResponse)
                        return object;
                    return new $root.infinitusai.be.MultiBenefitTasksResponse();
                };
    
                /**
                 * Creates a plain object from a MultiBenefitTasksResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {infinitusai.be.MultiBenefitTasksResponse} message MultiBenefitTasksResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MultiBenefitTasksResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this MultiBenefitTasksResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MultiBenefitTasksResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MultiBenefitTasksResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MultiBenefitTasksResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MultiBenefitTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MultiBenefitTasksResponse";
                };
    
                return MultiBenefitTasksResponse;
            })();
    
            be.GetMultiBenefitTasksResponse = (function() {
    
                /**
                 * Properties of a GetMultiBenefitTasksResponse.
                 * @memberof infinitusai.be
                 * @interface IGetMultiBenefitTasksResponse
                 * @property {Array.<string>|null} [taskUuids] GetMultiBenefitTasksResponse taskUuids
                 * @property {Array.<infinitusai.be.ITaskDoc>|null} [tasks] GetMultiBenefitTasksResponse tasks
                 */
    
                /**
                 * Constructs a new GetMultiBenefitTasksResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetMultiBenefitTasksResponse.
                 * @implements IGetMultiBenefitTasksResponse
                 * @constructor
                 * @param {infinitusai.be.IGetMultiBenefitTasksResponse=} [properties] Properties to set
                 */
                function GetMultiBenefitTasksResponse(properties) {
                    this.taskUuids = [];
                    this.tasks = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMultiBenefitTasksResponse taskUuids.
                 * @member {Array.<string>} taskUuids
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @instance
                 */
                GetMultiBenefitTasksResponse.prototype.taskUuids = $util.emptyArray;
    
                /**
                 * GetMultiBenefitTasksResponse tasks.
                 * @member {Array.<infinitusai.be.ITaskDoc>} tasks
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @instance
                 */
                GetMultiBenefitTasksResponse.prototype.tasks = $util.emptyArray;
    
                /**
                 * Creates a new GetMultiBenefitTasksResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetMultiBenefitTasksResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetMultiBenefitTasksResponse} GetMultiBenefitTasksResponse instance
                 */
                GetMultiBenefitTasksResponse.create = function create(properties) {
                    return new GetMultiBenefitTasksResponse(properties);
                };
    
                /**
                 * Encodes the specified GetMultiBenefitTasksResponse message. Does not implicitly {@link infinitusai.be.GetMultiBenefitTasksResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetMultiBenefitTasksResponse} message GetMultiBenefitTasksResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMultiBenefitTasksResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuids != null && message.taskUuids.length)
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuids[i]);
                    if (message.tasks != null && message.tasks.length)
                        for (var i = 0; i < message.tasks.length; ++i)
                            $root.infinitusai.be.TaskDoc.encode(message.tasks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMultiBenefitTasksResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetMultiBenefitTasksResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetMultiBenefitTasksResponse} message GetMultiBenefitTasksResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMultiBenefitTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMultiBenefitTasksResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetMultiBenefitTasksResponse} GetMultiBenefitTasksResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMultiBenefitTasksResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetMultiBenefitTasksResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.taskUuids && message.taskUuids.length))
                                    message.taskUuids = [];
                                message.taskUuids.push(reader.string());
                                break;
                            }
                        case 2: {
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.infinitusai.be.TaskDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMultiBenefitTasksResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetMultiBenefitTasksResponse} GetMultiBenefitTasksResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMultiBenefitTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMultiBenefitTasksResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMultiBenefitTasksResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuids != null && message.hasOwnProperty("taskUuids")) {
                        if (!Array.isArray(message.taskUuids))
                            return "taskUuids: array expected";
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            if (!$util.isString(message.taskUuids[i]))
                                return "taskUuids: string[] expected";
                    }
                    if (message.tasks != null && message.hasOwnProperty("tasks")) {
                        if (!Array.isArray(message.tasks))
                            return "tasks: array expected";
                        for (var i = 0; i < message.tasks.length; ++i) {
                            var error = $root.infinitusai.be.TaskDoc.verify(message.tasks[i]);
                            if (error)
                                return "tasks." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetMultiBenefitTasksResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetMultiBenefitTasksResponse} GetMultiBenefitTasksResponse
                 */
                GetMultiBenefitTasksResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetMultiBenefitTasksResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetMultiBenefitTasksResponse();
                    if (object.taskUuids) {
                        if (!Array.isArray(object.taskUuids))
                            throw TypeError(".infinitusai.be.GetMultiBenefitTasksResponse.taskUuids: array expected");
                        message.taskUuids = [];
                        for (var i = 0; i < object.taskUuids.length; ++i)
                            message.taskUuids[i] = String(object.taskUuids[i]);
                    }
                    if (object.tasks) {
                        if (!Array.isArray(object.tasks))
                            throw TypeError(".infinitusai.be.GetMultiBenefitTasksResponse.tasks: array expected");
                        message.tasks = [];
                        for (var i = 0; i < object.tasks.length; ++i) {
                            if (typeof object.tasks[i] !== "object")
                                throw TypeError(".infinitusai.be.GetMultiBenefitTasksResponse.tasks: object expected");
                            message.tasks[i] = $root.infinitusai.be.TaskDoc.fromObject(object.tasks[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMultiBenefitTasksResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {infinitusai.be.GetMultiBenefitTasksResponse} message GetMultiBenefitTasksResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMultiBenefitTasksResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.taskUuids = [];
                        object.tasks = [];
                    }
                    if (message.taskUuids && message.taskUuids.length) {
                        object.taskUuids = [];
                        for (var j = 0; j < message.taskUuids.length; ++j)
                            object.taskUuids[j] = message.taskUuids[j];
                    }
                    if (message.tasks && message.tasks.length) {
                        object.tasks = [];
                        for (var j = 0; j < message.tasks.length; ++j)
                            object.tasks[j] = $root.infinitusai.be.TaskDoc.toObject(message.tasks[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetMultiBenefitTasksResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMultiBenefitTasksResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMultiBenefitTasksResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetMultiBenefitTasksResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMultiBenefitTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetMultiBenefitTasksResponse";
                };
    
                return GetMultiBenefitTasksResponse;
            })();
    
            be.GetCustomerTimeSavingsRequest = (function() {
    
                /**
                 * Properties of a GetCustomerTimeSavingsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetCustomerTimeSavingsRequest
                 * @property {string|null} [duration] GetCustomerTimeSavingsRequest duration
                 * @property {infinitusai.be.TaskType|null} [taskType] GetCustomerTimeSavingsRequest taskType
                 * @property {string|null} [programName] GetCustomerTimeSavingsRequest programName
                 * @property {string|null} [timeSavingsStartDate] GetCustomerTimeSavingsRequest timeSavingsStartDate
                 * @property {string|null} [timeSavingsEndDate] GetCustomerTimeSavingsRequest timeSavingsEndDate
                 */
    
                /**
                 * Constructs a new GetCustomerTimeSavingsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCustomerTimeSavingsRequest.
                 * @implements IGetCustomerTimeSavingsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetCustomerTimeSavingsRequest=} [properties] Properties to set
                 */
                function GetCustomerTimeSavingsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCustomerTimeSavingsRequest duration.
                 * @member {string} duration
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @instance
                 */
                GetCustomerTimeSavingsRequest.prototype.duration = "";
    
                /**
                 * GetCustomerTimeSavingsRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @instance
                 */
                GetCustomerTimeSavingsRequest.prototype.taskType = 0;
    
                /**
                 * GetCustomerTimeSavingsRequest programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @instance
                 */
                GetCustomerTimeSavingsRequest.prototype.programName = "";
    
                /**
                 * GetCustomerTimeSavingsRequest timeSavingsStartDate.
                 * @member {string} timeSavingsStartDate
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @instance
                 */
                GetCustomerTimeSavingsRequest.prototype.timeSavingsStartDate = "";
    
                /**
                 * GetCustomerTimeSavingsRequest timeSavingsEndDate.
                 * @member {string} timeSavingsEndDate
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @instance
                 */
                GetCustomerTimeSavingsRequest.prototype.timeSavingsEndDate = "";
    
                /**
                 * Creates a new GetCustomerTimeSavingsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {infinitusai.be.IGetCustomerTimeSavingsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCustomerTimeSavingsRequest} GetCustomerTimeSavingsRequest instance
                 */
                GetCustomerTimeSavingsRequest.create = function create(properties) {
                    return new GetCustomerTimeSavingsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetCustomerTimeSavingsRequest message. Does not implicitly {@link infinitusai.be.GetCustomerTimeSavingsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {infinitusai.be.IGetCustomerTimeSavingsRequest} message GetCustomerTimeSavingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCustomerTimeSavingsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.duration);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.programName);
                    if (message.timeSavingsStartDate != null && Object.hasOwnProperty.call(message, "timeSavingsStartDate"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.timeSavingsStartDate);
                    if (message.timeSavingsEndDate != null && Object.hasOwnProperty.call(message, "timeSavingsEndDate"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.timeSavingsEndDate);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCustomerTimeSavingsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetCustomerTimeSavingsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {infinitusai.be.IGetCustomerTimeSavingsRequest} message GetCustomerTimeSavingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCustomerTimeSavingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCustomerTimeSavingsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCustomerTimeSavingsRequest} GetCustomerTimeSavingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCustomerTimeSavingsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCustomerTimeSavingsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.duration = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.programName = reader.string();
                                break;
                            }
                        case 4: {
                                message.timeSavingsStartDate = reader.string();
                                break;
                            }
                        case 5: {
                                message.timeSavingsEndDate = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCustomerTimeSavingsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCustomerTimeSavingsRequest} GetCustomerTimeSavingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCustomerTimeSavingsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCustomerTimeSavingsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCustomerTimeSavingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.duration != null && message.hasOwnProperty("duration"))
                        if (!$util.isString(message.duration))
                            return "duration: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    if (message.timeSavingsStartDate != null && message.hasOwnProperty("timeSavingsStartDate"))
                        if (!$util.isString(message.timeSavingsStartDate))
                            return "timeSavingsStartDate: string expected";
                    if (message.timeSavingsEndDate != null && message.hasOwnProperty("timeSavingsEndDate"))
                        if (!$util.isString(message.timeSavingsEndDate))
                            return "timeSavingsEndDate: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetCustomerTimeSavingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCustomerTimeSavingsRequest} GetCustomerTimeSavingsRequest
                 */
                GetCustomerTimeSavingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCustomerTimeSavingsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetCustomerTimeSavingsRequest();
                    if (object.duration != null)
                        message.duration = String(object.duration);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    if (object.timeSavingsStartDate != null)
                        message.timeSavingsStartDate = String(object.timeSavingsStartDate);
                    if (object.timeSavingsEndDate != null)
                        message.timeSavingsEndDate = String(object.timeSavingsEndDate);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCustomerTimeSavingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {infinitusai.be.GetCustomerTimeSavingsRequest} message GetCustomerTimeSavingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCustomerTimeSavingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.duration = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.programName = "";
                        object.timeSavingsStartDate = "";
                        object.timeSavingsEndDate = "";
                    }
                    if (message.duration != null && message.hasOwnProperty("duration"))
                        object.duration = message.duration;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    if (message.timeSavingsStartDate != null && message.hasOwnProperty("timeSavingsStartDate"))
                        object.timeSavingsStartDate = message.timeSavingsStartDate;
                    if (message.timeSavingsEndDate != null && message.hasOwnProperty("timeSavingsEndDate"))
                        object.timeSavingsEndDate = message.timeSavingsEndDate;
                    return object;
                };
    
                /**
                 * Converts this GetCustomerTimeSavingsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCustomerTimeSavingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCustomerTimeSavingsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCustomerTimeSavingsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCustomerTimeSavingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCustomerTimeSavingsRequest";
                };
    
                return GetCustomerTimeSavingsRequest;
            })();
    
            be.GetCustomerTimeSavingsResponse = (function() {
    
                /**
                 * Properties of a GetCustomerTimeSavingsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetCustomerTimeSavingsResponse
                 * @property {Object.<string,number>|null} [avgTimeSavingsByRange] GetCustomerTimeSavingsResponse avgTimeSavingsByRange
                 * @property {number|null} [avgTimeSavingsForRange] GetCustomerTimeSavingsResponse avgTimeSavingsForRange
                 */
    
                /**
                 * Constructs a new GetCustomerTimeSavingsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCustomerTimeSavingsResponse.
                 * @implements IGetCustomerTimeSavingsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetCustomerTimeSavingsResponse=} [properties] Properties to set
                 */
                function GetCustomerTimeSavingsResponse(properties) {
                    this.avgTimeSavingsByRange = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCustomerTimeSavingsResponse avgTimeSavingsByRange.
                 * @member {Object.<string,number>} avgTimeSavingsByRange
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @instance
                 */
                GetCustomerTimeSavingsResponse.prototype.avgTimeSavingsByRange = $util.emptyObject;
    
                /**
                 * GetCustomerTimeSavingsResponse avgTimeSavingsForRange.
                 * @member {number} avgTimeSavingsForRange
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @instance
                 */
                GetCustomerTimeSavingsResponse.prototype.avgTimeSavingsForRange = 0;
    
                /**
                 * Creates a new GetCustomerTimeSavingsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {infinitusai.be.IGetCustomerTimeSavingsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCustomerTimeSavingsResponse} GetCustomerTimeSavingsResponse instance
                 */
                GetCustomerTimeSavingsResponse.create = function create(properties) {
                    return new GetCustomerTimeSavingsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCustomerTimeSavingsResponse message. Does not implicitly {@link infinitusai.be.GetCustomerTimeSavingsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {infinitusai.be.IGetCustomerTimeSavingsResponse} message GetCustomerTimeSavingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCustomerTimeSavingsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.avgTimeSavingsByRange != null && Object.hasOwnProperty.call(message, "avgTimeSavingsByRange"))
                        for (var keys = Object.keys(message.avgTimeSavingsByRange), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 5 =*/21).float(message.avgTimeSavingsByRange[keys[i]]).ldelim();
                    if (message.avgTimeSavingsForRange != null && Object.hasOwnProperty.call(message, "avgTimeSavingsForRange"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.avgTimeSavingsForRange);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCustomerTimeSavingsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetCustomerTimeSavingsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {infinitusai.be.IGetCustomerTimeSavingsResponse} message GetCustomerTimeSavingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCustomerTimeSavingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCustomerTimeSavingsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCustomerTimeSavingsResponse} GetCustomerTimeSavingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCustomerTimeSavingsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCustomerTimeSavingsResponse(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.avgTimeSavingsByRange === $util.emptyObject)
                                    message.avgTimeSavingsByRange = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.float();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.avgTimeSavingsByRange[key] = value;
                                break;
                            }
                        case 2: {
                                message.avgTimeSavingsForRange = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCustomerTimeSavingsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCustomerTimeSavingsResponse} GetCustomerTimeSavingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCustomerTimeSavingsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCustomerTimeSavingsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCustomerTimeSavingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.avgTimeSavingsByRange != null && message.hasOwnProperty("avgTimeSavingsByRange")) {
                        if (!$util.isObject(message.avgTimeSavingsByRange))
                            return "avgTimeSavingsByRange: object expected";
                        var key = Object.keys(message.avgTimeSavingsByRange);
                        for (var i = 0; i < key.length; ++i)
                            if (typeof message.avgTimeSavingsByRange[key[i]] !== "number")
                                return "avgTimeSavingsByRange: number{k:string} expected";
                    }
                    if (message.avgTimeSavingsForRange != null && message.hasOwnProperty("avgTimeSavingsForRange"))
                        if (typeof message.avgTimeSavingsForRange !== "number")
                            return "avgTimeSavingsForRange: number expected";
                    return null;
                };
    
                /**
                 * Creates a GetCustomerTimeSavingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCustomerTimeSavingsResponse} GetCustomerTimeSavingsResponse
                 */
                GetCustomerTimeSavingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCustomerTimeSavingsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetCustomerTimeSavingsResponse();
                    if (object.avgTimeSavingsByRange) {
                        if (typeof object.avgTimeSavingsByRange !== "object")
                            throw TypeError(".infinitusai.be.GetCustomerTimeSavingsResponse.avgTimeSavingsByRange: object expected");
                        message.avgTimeSavingsByRange = {};
                        for (var keys = Object.keys(object.avgTimeSavingsByRange), i = 0; i < keys.length; ++i)
                            message.avgTimeSavingsByRange[keys[i]] = Number(object.avgTimeSavingsByRange[keys[i]]);
                    }
                    if (object.avgTimeSavingsForRange != null)
                        message.avgTimeSavingsForRange = Number(object.avgTimeSavingsForRange);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCustomerTimeSavingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {infinitusai.be.GetCustomerTimeSavingsResponse} message GetCustomerTimeSavingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCustomerTimeSavingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.avgTimeSavingsByRange = {};
                    if (options.defaults)
                        object.avgTimeSavingsForRange = 0;
                    var keys2;
                    if (message.avgTimeSavingsByRange && (keys2 = Object.keys(message.avgTimeSavingsByRange)).length) {
                        object.avgTimeSavingsByRange = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.avgTimeSavingsByRange[keys2[j]] = options.json && !isFinite(message.avgTimeSavingsByRange[keys2[j]]) ? String(message.avgTimeSavingsByRange[keys2[j]]) : message.avgTimeSavingsByRange[keys2[j]];
                    }
                    if (message.avgTimeSavingsForRange != null && message.hasOwnProperty("avgTimeSavingsForRange"))
                        object.avgTimeSavingsForRange = options.json && !isFinite(message.avgTimeSavingsForRange) ? String(message.avgTimeSavingsForRange) : message.avgTimeSavingsForRange;
                    return object;
                };
    
                /**
                 * Converts this GetCustomerTimeSavingsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCustomerTimeSavingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCustomerTimeSavingsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCustomerTimeSavingsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCustomerTimeSavingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCustomerTimeSavingsResponse";
                };
    
                return GetCustomerTimeSavingsResponse;
            })();
    
            /**
             * AutoRequeueConfigStatus enum.
             * @name infinitusai.be.AutoRequeueConfigStatus
             * @enum {number}
             * @property {number} AUTO_REQUEUE_CONFIG_STATUS_UNKNOWN=0 AUTO_REQUEUE_CONFIG_STATUS_UNKNOWN value
             * @property {number} AUTO_REQUEUE_CONFIG_STATUS_PENDING=1 AUTO_REQUEUE_CONFIG_STATUS_PENDING value
             * @property {number} AUTO_REQUEUE_CONFIG_STATUS_COMPLETED=2 AUTO_REQUEUE_CONFIG_STATUS_COMPLETED value
             * @property {number} AUTO_REQUEUE_CONFIG_STATUS_FAILED=3 AUTO_REQUEUE_CONFIG_STATUS_FAILED value
             */
            be.AutoRequeueConfigStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AUTO_REQUEUE_CONFIG_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "AUTO_REQUEUE_CONFIG_STATUS_PENDING"] = 1;
                values[valuesById[2] = "AUTO_REQUEUE_CONFIG_STATUS_COMPLETED"] = 2;
                values[valuesById[3] = "AUTO_REQUEUE_CONFIG_STATUS_FAILED"] = 3;
                return values;
            })();
    
            be.AutoRequeueConfig = (function() {
    
                /**
                 * Properties of an AutoRequeueConfig.
                 * @memberof infinitusai.be
                 * @interface IAutoRequeueConfig
                 * @property {number|Long|null} [requeueTime] AutoRequeueConfig requeueTime
                 * @property {string|null} [payerId] AutoRequeueConfig payerId
                 * @property {infinitusai.be.AutoRequeueConfigStatus|null} [status] AutoRequeueConfig status
                 * @property {string|null} [callUuid] AutoRequeueConfig callUuid
                 * @property {infinitusai.be.IRequeueReason|null} [requeueReason] AutoRequeueConfig requeueReason
                 * @property {number|Long|null} [updatedMillis] AutoRequeueConfig updatedMillis
                 */
    
                /**
                 * Constructs a new AutoRequeueConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AutoRequeueConfig.
                 * @implements IAutoRequeueConfig
                 * @constructor
                 * @param {infinitusai.be.IAutoRequeueConfig=} [properties] Properties to set
                 */
                function AutoRequeueConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AutoRequeueConfig requeueTime.
                 * @member {number|Long} requeueTime
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @instance
                 */
                AutoRequeueConfig.prototype.requeueTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * AutoRequeueConfig payerId.
                 * @member {string} payerId
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @instance
                 */
                AutoRequeueConfig.prototype.payerId = "";
    
                /**
                 * AutoRequeueConfig status.
                 * @member {infinitusai.be.AutoRequeueConfigStatus} status
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @instance
                 */
                AutoRequeueConfig.prototype.status = 0;
    
                /**
                 * AutoRequeueConfig callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @instance
                 */
                AutoRequeueConfig.prototype.callUuid = "";
    
                /**
                 * AutoRequeueConfig requeueReason.
                 * @member {infinitusai.be.IRequeueReason|null|undefined} requeueReason
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @instance
                 */
                AutoRequeueConfig.prototype.requeueReason = null;
    
                /**
                 * AutoRequeueConfig updatedMillis.
                 * @member {number|Long} updatedMillis
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @instance
                 */
                AutoRequeueConfig.prototype.updatedMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new AutoRequeueConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {infinitusai.be.IAutoRequeueConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.AutoRequeueConfig} AutoRequeueConfig instance
                 */
                AutoRequeueConfig.create = function create(properties) {
                    return new AutoRequeueConfig(properties);
                };
    
                /**
                 * Encodes the specified AutoRequeueConfig message. Does not implicitly {@link infinitusai.be.AutoRequeueConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {infinitusai.be.IAutoRequeueConfig} message AutoRequeueConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoRequeueConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.requeueTime != null && Object.hasOwnProperty.call(message, "requeueTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.requeueTime);
                    if (message.payerId != null && Object.hasOwnProperty.call(message, "payerId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.payerId);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.callUuid);
                    if (message.requeueReason != null && Object.hasOwnProperty.call(message, "requeueReason"))
                        $root.infinitusai.be.RequeueReason.encode(message.requeueReason, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.updatedMillis != null && Object.hasOwnProperty.call(message, "updatedMillis"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.updatedMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified AutoRequeueConfig message, length delimited. Does not implicitly {@link infinitusai.be.AutoRequeueConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {infinitusai.be.IAutoRequeueConfig} message AutoRequeueConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoRequeueConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AutoRequeueConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AutoRequeueConfig} AutoRequeueConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoRequeueConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AutoRequeueConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.requeueTime = reader.int64();
                                break;
                            }
                        case 2: {
                                message.payerId = reader.string();
                                break;
                            }
                        case 3: {
                                message.status = reader.int32();
                                break;
                            }
                        case 4: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.requeueReason = $root.infinitusai.be.RequeueReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.updatedMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AutoRequeueConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AutoRequeueConfig} AutoRequeueConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoRequeueConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AutoRequeueConfig message.
                 * @function verify
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AutoRequeueConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.requeueTime != null && message.hasOwnProperty("requeueTime"))
                        if (!$util.isInteger(message.requeueTime) && !(message.requeueTime && $util.isInteger(message.requeueTime.low) && $util.isInteger(message.requeueTime.high)))
                            return "requeueTime: integer|Long expected";
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        if (!$util.isString(message.payerId))
                            return "payerId: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.requeueReason != null && message.hasOwnProperty("requeueReason")) {
                        var error = $root.infinitusai.be.RequeueReason.verify(message.requeueReason);
                        if (error)
                            return "requeueReason." + error;
                    }
                    if (message.updatedMillis != null && message.hasOwnProperty("updatedMillis"))
                        if (!$util.isInteger(message.updatedMillis) && !(message.updatedMillis && $util.isInteger(message.updatedMillis.low) && $util.isInteger(message.updatedMillis.high)))
                            return "updatedMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an AutoRequeueConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AutoRequeueConfig} AutoRequeueConfig
                 */
                AutoRequeueConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AutoRequeueConfig)
                        return object;
                    var message = new $root.infinitusai.be.AutoRequeueConfig();
                    if (object.requeueTime != null)
                        if ($util.Long)
                            (message.requeueTime = $util.Long.fromValue(object.requeueTime)).unsigned = false;
                        else if (typeof object.requeueTime === "string")
                            message.requeueTime = parseInt(object.requeueTime, 10);
                        else if (typeof object.requeueTime === "number")
                            message.requeueTime = object.requeueTime;
                        else if (typeof object.requeueTime === "object")
                            message.requeueTime = new $util.LongBits(object.requeueTime.low >>> 0, object.requeueTime.high >>> 0).toNumber();
                    if (object.payerId != null)
                        message.payerId = String(object.payerId);
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "AUTO_REQUEUE_CONFIG_STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "AUTO_REQUEUE_CONFIG_STATUS_PENDING":
                    case 1:
                        message.status = 1;
                        break;
                    case "AUTO_REQUEUE_CONFIG_STATUS_COMPLETED":
                    case 2:
                        message.status = 2;
                        break;
                    case "AUTO_REQUEUE_CONFIG_STATUS_FAILED":
                    case 3:
                        message.status = 3;
                        break;
                    }
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.requeueReason != null) {
                        if (typeof object.requeueReason !== "object")
                            throw TypeError(".infinitusai.be.AutoRequeueConfig.requeueReason: object expected");
                        message.requeueReason = $root.infinitusai.be.RequeueReason.fromObject(object.requeueReason);
                    }
                    if (object.updatedMillis != null)
                        if ($util.Long)
                            (message.updatedMillis = $util.Long.fromValue(object.updatedMillis)).unsigned = false;
                        else if (typeof object.updatedMillis === "string")
                            message.updatedMillis = parseInt(object.updatedMillis, 10);
                        else if (typeof object.updatedMillis === "number")
                            message.updatedMillis = object.updatedMillis;
                        else if (typeof object.updatedMillis === "object")
                            message.updatedMillis = new $util.LongBits(object.updatedMillis.low >>> 0, object.updatedMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an AutoRequeueConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {infinitusai.be.AutoRequeueConfig} message AutoRequeueConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AutoRequeueConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.requeueTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.requeueTime = options.longs === String ? "0" : 0;
                        object.payerId = "";
                        object.status = options.enums === String ? "AUTO_REQUEUE_CONFIG_STATUS_UNKNOWN" : 0;
                        object.callUuid = "";
                        object.requeueReason = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updatedMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.requeueTime != null && message.hasOwnProperty("requeueTime"))
                        if (typeof message.requeueTime === "number")
                            object.requeueTime = options.longs === String ? String(message.requeueTime) : message.requeueTime;
                        else
                            object.requeueTime = options.longs === String ? $util.Long.prototype.toString.call(message.requeueTime) : options.longs === Number ? new $util.LongBits(message.requeueTime.low >>> 0, message.requeueTime.high >>> 0).toNumber() : message.requeueTime;
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        object.payerId = message.payerId;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.AutoRequeueConfigStatus[message.status] === undefined ? message.status : $root.infinitusai.be.AutoRequeueConfigStatus[message.status] : message.status;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.requeueReason != null && message.hasOwnProperty("requeueReason"))
                        object.requeueReason = $root.infinitusai.be.RequeueReason.toObject(message.requeueReason, options);
                    if (message.updatedMillis != null && message.hasOwnProperty("updatedMillis"))
                        if (typeof message.updatedMillis === "number")
                            object.updatedMillis = options.longs === String ? String(message.updatedMillis) : message.updatedMillis;
                        else
                            object.updatedMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updatedMillis) : options.longs === Number ? new $util.LongBits(message.updatedMillis.low >>> 0, message.updatedMillis.high >>> 0).toNumber() : message.updatedMillis;
                    return object;
                };
    
                /**
                 * Converts this AutoRequeueConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AutoRequeueConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AutoRequeueConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AutoRequeueConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AutoRequeueConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AutoRequeueConfig";
                };
    
                return AutoRequeueConfig;
            })();
    
            be.GetTaskSummaryRequest = (function() {
    
                /**
                 * Properties of a GetTaskSummaryRequest.
                 * @memberof infinitusai.be
                 * @interface IGetTaskSummaryRequest
                 * @property {string|null} [duration] GetTaskSummaryRequest duration
                 */
    
                /**
                 * Constructs a new GetTaskSummaryRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTaskSummaryRequest.
                 * @implements IGetTaskSummaryRequest
                 * @constructor
                 * @param {infinitusai.be.IGetTaskSummaryRequest=} [properties] Properties to set
                 */
                function GetTaskSummaryRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTaskSummaryRequest duration.
                 * @member {string} duration
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @instance
                 */
                GetTaskSummaryRequest.prototype.duration = "";
    
                /**
                 * Creates a new GetTaskSummaryRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskSummaryRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTaskSummaryRequest} GetTaskSummaryRequest instance
                 */
                GetTaskSummaryRequest.create = function create(properties) {
                    return new GetTaskSummaryRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTaskSummaryRequest message. Does not implicitly {@link infinitusai.be.GetTaskSummaryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskSummaryRequest} message GetTaskSummaryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskSummaryRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.duration);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTaskSummaryRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetTaskSummaryRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {infinitusai.be.IGetTaskSummaryRequest} message GetTaskSummaryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskSummaryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTaskSummaryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTaskSummaryRequest} GetTaskSummaryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskSummaryRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTaskSummaryRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.duration = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTaskSummaryRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTaskSummaryRequest} GetTaskSummaryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskSummaryRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTaskSummaryRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTaskSummaryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.duration != null && message.hasOwnProperty("duration"))
                        if (!$util.isString(message.duration))
                            return "duration: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetTaskSummaryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTaskSummaryRequest} GetTaskSummaryRequest
                 */
                GetTaskSummaryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTaskSummaryRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetTaskSummaryRequest();
                    if (object.duration != null)
                        message.duration = String(object.duration);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTaskSummaryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {infinitusai.be.GetTaskSummaryRequest} message GetTaskSummaryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTaskSummaryRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.duration = "";
                    if (message.duration != null && message.hasOwnProperty("duration"))
                        object.duration = message.duration;
                    return object;
                };
    
                /**
                 * Converts this GetTaskSummaryRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTaskSummaryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTaskSummaryRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTaskSummaryRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTaskSummaryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTaskSummaryRequest";
                };
    
                return GetTaskSummaryRequest;
            })();
    
            be.GetTaskSummaryResponse = (function() {
    
                /**
                 * Properties of a GetTaskSummaryResponse.
                 * @memberof infinitusai.be
                 * @interface IGetTaskSummaryResponse
                 * @property {number|null} [tasksSuccessful] GetTaskSummaryResponse tasksSuccessful
                 * @property {number|null} [tasksPartiallySuccessul] GetTaskSummaryResponse tasksPartiallySuccessul
                 * @property {number|null} [tasksUnsuccessful] GetTaskSummaryResponse tasksUnsuccessful
                 * @property {number|null} [tasksBadData] GetTaskSummaryResponse tasksBadData
                 * @property {number|null} [tasksDataMissing] GetTaskSummaryResponse tasksDataMissing
                 * @property {number|null} [tasksThirdPartyReasons] GetTaskSummaryResponse tasksThirdPartyReasons
                 * @property {number|null} [tasksCancelled] GetTaskSummaryResponse tasksCancelled
                 * @property {number|null} [tasksOther] GetTaskSummaryResponse tasksOther
                 * @property {number|null} [tasksTotal] GetTaskSummaryResponse tasksTotal
                 */
    
                /**
                 * Constructs a new GetTaskSummaryResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetTaskSummaryResponse.
                 * @implements IGetTaskSummaryResponse
                 * @constructor
                 * @param {infinitusai.be.IGetTaskSummaryResponse=} [properties] Properties to set
                 */
                function GetTaskSummaryResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTaskSummaryResponse tasksSuccessful.
                 * @member {number} tasksSuccessful
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksSuccessful = 0;
    
                /**
                 * GetTaskSummaryResponse tasksPartiallySuccessul.
                 * @member {number} tasksPartiallySuccessul
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksPartiallySuccessul = 0;
    
                /**
                 * GetTaskSummaryResponse tasksUnsuccessful.
                 * @member {number} tasksUnsuccessful
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksUnsuccessful = 0;
    
                /**
                 * GetTaskSummaryResponse tasksBadData.
                 * @member {number} tasksBadData
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksBadData = 0;
    
                /**
                 * GetTaskSummaryResponse tasksDataMissing.
                 * @member {number} tasksDataMissing
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksDataMissing = 0;
    
                /**
                 * GetTaskSummaryResponse tasksThirdPartyReasons.
                 * @member {number} tasksThirdPartyReasons
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksThirdPartyReasons = 0;
    
                /**
                 * GetTaskSummaryResponse tasksCancelled.
                 * @member {number} tasksCancelled
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksCancelled = 0;
    
                /**
                 * GetTaskSummaryResponse tasksOther.
                 * @member {number} tasksOther
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksOther = 0;
    
                /**
                 * GetTaskSummaryResponse tasksTotal.
                 * @member {number} tasksTotal
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 */
                GetTaskSummaryResponse.prototype.tasksTotal = 0;
    
                /**
                 * Creates a new GetTaskSummaryResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskSummaryResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetTaskSummaryResponse} GetTaskSummaryResponse instance
                 */
                GetTaskSummaryResponse.create = function create(properties) {
                    return new GetTaskSummaryResponse(properties);
                };
    
                /**
                 * Encodes the specified GetTaskSummaryResponse message. Does not implicitly {@link infinitusai.be.GetTaskSummaryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskSummaryResponse} message GetTaskSummaryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskSummaryResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tasksSuccessful != null && Object.hasOwnProperty.call(message, "tasksSuccessful"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tasksSuccessful);
                    if (message.tasksPartiallySuccessul != null && Object.hasOwnProperty.call(message, "tasksPartiallySuccessul"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.tasksPartiallySuccessul);
                    if (message.tasksUnsuccessful != null && Object.hasOwnProperty.call(message, "tasksUnsuccessful"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.tasksUnsuccessful);
                    if (message.tasksBadData != null && Object.hasOwnProperty.call(message, "tasksBadData"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.tasksBadData);
                    if (message.tasksDataMissing != null && Object.hasOwnProperty.call(message, "tasksDataMissing"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.tasksDataMissing);
                    if (message.tasksThirdPartyReasons != null && Object.hasOwnProperty.call(message, "tasksThirdPartyReasons"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.tasksThirdPartyReasons);
                    if (message.tasksCancelled != null && Object.hasOwnProperty.call(message, "tasksCancelled"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.tasksCancelled);
                    if (message.tasksOther != null && Object.hasOwnProperty.call(message, "tasksOther"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.tasksOther);
                    if (message.tasksTotal != null && Object.hasOwnProperty.call(message, "tasksTotal"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.tasksTotal);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTaskSummaryResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetTaskSummaryResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {infinitusai.be.IGetTaskSummaryResponse} message GetTaskSummaryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTaskSummaryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTaskSummaryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetTaskSummaryResponse} GetTaskSummaryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskSummaryResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetTaskSummaryResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.tasksSuccessful = reader.int32();
                                break;
                            }
                        case 2: {
                                message.tasksPartiallySuccessul = reader.int32();
                                break;
                            }
                        case 3: {
                                message.tasksUnsuccessful = reader.int32();
                                break;
                            }
                        case 4: {
                                message.tasksBadData = reader.int32();
                                break;
                            }
                        case 5: {
                                message.tasksDataMissing = reader.int32();
                                break;
                            }
                        case 6: {
                                message.tasksThirdPartyReasons = reader.int32();
                                break;
                            }
                        case 7: {
                                message.tasksCancelled = reader.int32();
                                break;
                            }
                        case 8: {
                                message.tasksOther = reader.int32();
                                break;
                            }
                        case 9: {
                                message.tasksTotal = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTaskSummaryResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetTaskSummaryResponse} GetTaskSummaryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTaskSummaryResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTaskSummaryResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTaskSummaryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tasksSuccessful != null && message.hasOwnProperty("tasksSuccessful"))
                        if (!$util.isInteger(message.tasksSuccessful))
                            return "tasksSuccessful: integer expected";
                    if (message.tasksPartiallySuccessul != null && message.hasOwnProperty("tasksPartiallySuccessul"))
                        if (!$util.isInteger(message.tasksPartiallySuccessul))
                            return "tasksPartiallySuccessul: integer expected";
                    if (message.tasksUnsuccessful != null && message.hasOwnProperty("tasksUnsuccessful"))
                        if (!$util.isInteger(message.tasksUnsuccessful))
                            return "tasksUnsuccessful: integer expected";
                    if (message.tasksBadData != null && message.hasOwnProperty("tasksBadData"))
                        if (!$util.isInteger(message.tasksBadData))
                            return "tasksBadData: integer expected";
                    if (message.tasksDataMissing != null && message.hasOwnProperty("tasksDataMissing"))
                        if (!$util.isInteger(message.tasksDataMissing))
                            return "tasksDataMissing: integer expected";
                    if (message.tasksThirdPartyReasons != null && message.hasOwnProperty("tasksThirdPartyReasons"))
                        if (!$util.isInteger(message.tasksThirdPartyReasons))
                            return "tasksThirdPartyReasons: integer expected";
                    if (message.tasksCancelled != null && message.hasOwnProperty("tasksCancelled"))
                        if (!$util.isInteger(message.tasksCancelled))
                            return "tasksCancelled: integer expected";
                    if (message.tasksOther != null && message.hasOwnProperty("tasksOther"))
                        if (!$util.isInteger(message.tasksOther))
                            return "tasksOther: integer expected";
                    if (message.tasksTotal != null && message.hasOwnProperty("tasksTotal"))
                        if (!$util.isInteger(message.tasksTotal))
                            return "tasksTotal: integer expected";
                    return null;
                };
    
                /**
                 * Creates a GetTaskSummaryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetTaskSummaryResponse} GetTaskSummaryResponse
                 */
                GetTaskSummaryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetTaskSummaryResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetTaskSummaryResponse();
                    if (object.tasksSuccessful != null)
                        message.tasksSuccessful = object.tasksSuccessful | 0;
                    if (object.tasksPartiallySuccessul != null)
                        message.tasksPartiallySuccessul = object.tasksPartiallySuccessul | 0;
                    if (object.tasksUnsuccessful != null)
                        message.tasksUnsuccessful = object.tasksUnsuccessful | 0;
                    if (object.tasksBadData != null)
                        message.tasksBadData = object.tasksBadData | 0;
                    if (object.tasksDataMissing != null)
                        message.tasksDataMissing = object.tasksDataMissing | 0;
                    if (object.tasksThirdPartyReasons != null)
                        message.tasksThirdPartyReasons = object.tasksThirdPartyReasons | 0;
                    if (object.tasksCancelled != null)
                        message.tasksCancelled = object.tasksCancelled | 0;
                    if (object.tasksOther != null)
                        message.tasksOther = object.tasksOther | 0;
                    if (object.tasksTotal != null)
                        message.tasksTotal = object.tasksTotal | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTaskSummaryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {infinitusai.be.GetTaskSummaryResponse} message GetTaskSummaryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTaskSummaryResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.tasksSuccessful = 0;
                        object.tasksPartiallySuccessul = 0;
                        object.tasksUnsuccessful = 0;
                        object.tasksBadData = 0;
                        object.tasksDataMissing = 0;
                        object.tasksThirdPartyReasons = 0;
                        object.tasksCancelled = 0;
                        object.tasksOther = 0;
                        object.tasksTotal = 0;
                    }
                    if (message.tasksSuccessful != null && message.hasOwnProperty("tasksSuccessful"))
                        object.tasksSuccessful = message.tasksSuccessful;
                    if (message.tasksPartiallySuccessul != null && message.hasOwnProperty("tasksPartiallySuccessul"))
                        object.tasksPartiallySuccessul = message.tasksPartiallySuccessul;
                    if (message.tasksUnsuccessful != null && message.hasOwnProperty("tasksUnsuccessful"))
                        object.tasksUnsuccessful = message.tasksUnsuccessful;
                    if (message.tasksBadData != null && message.hasOwnProperty("tasksBadData"))
                        object.tasksBadData = message.tasksBadData;
                    if (message.tasksDataMissing != null && message.hasOwnProperty("tasksDataMissing"))
                        object.tasksDataMissing = message.tasksDataMissing;
                    if (message.tasksThirdPartyReasons != null && message.hasOwnProperty("tasksThirdPartyReasons"))
                        object.tasksThirdPartyReasons = message.tasksThirdPartyReasons;
                    if (message.tasksCancelled != null && message.hasOwnProperty("tasksCancelled"))
                        object.tasksCancelled = message.tasksCancelled;
                    if (message.tasksOther != null && message.hasOwnProperty("tasksOther"))
                        object.tasksOther = message.tasksOther;
                    if (message.tasksTotal != null && message.hasOwnProperty("tasksTotal"))
                        object.tasksTotal = message.tasksTotal;
                    return object;
                };
    
                /**
                 * Converts this GetTaskSummaryResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTaskSummaryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTaskSummaryResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetTaskSummaryResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTaskSummaryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetTaskSummaryResponse";
                };
    
                return GetTaskSummaryResponse;
            })();
    
            be.FastTrackSuccessfulTaskNotes = (function() {
    
                /**
                 * Properties of a FastTrackSuccessfulTaskNotes.
                 * @memberof infinitusai.be
                 * @interface IFastTrackSuccessfulTaskNotes
                 * @property {string|null} [callOutcome] FastTrackSuccessfulTaskNotes callOutcome
                 * @property {infinitusai.be.IDate|null} [followUpDate] FastTrackSuccessfulTaskNotes followUpDate
                 * @property {string|null} [finalizedNotes] FastTrackSuccessfulTaskNotes finalizedNotes
                 */
    
                /**
                 * Constructs a new FastTrackSuccessfulTaskNotes.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FastTrackSuccessfulTaskNotes.
                 * @implements IFastTrackSuccessfulTaskNotes
                 * @constructor
                 * @param {infinitusai.be.IFastTrackSuccessfulTaskNotes=} [properties] Properties to set
                 */
                function FastTrackSuccessfulTaskNotes(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FastTrackSuccessfulTaskNotes callOutcome.
                 * @member {string} callOutcome
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @instance
                 */
                FastTrackSuccessfulTaskNotes.prototype.callOutcome = "";
    
                /**
                 * FastTrackSuccessfulTaskNotes followUpDate.
                 * @member {infinitusai.be.IDate|null|undefined} followUpDate
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @instance
                 */
                FastTrackSuccessfulTaskNotes.prototype.followUpDate = null;
    
                /**
                 * FastTrackSuccessfulTaskNotes finalizedNotes.
                 * @member {string} finalizedNotes
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @instance
                 */
                FastTrackSuccessfulTaskNotes.prototype.finalizedNotes = "";
    
                /**
                 * Creates a new FastTrackSuccessfulTaskNotes instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {infinitusai.be.IFastTrackSuccessfulTaskNotes=} [properties] Properties to set
                 * @returns {infinitusai.be.FastTrackSuccessfulTaskNotes} FastTrackSuccessfulTaskNotes instance
                 */
                FastTrackSuccessfulTaskNotes.create = function create(properties) {
                    return new FastTrackSuccessfulTaskNotes(properties);
                };
    
                /**
                 * Encodes the specified FastTrackSuccessfulTaskNotes message. Does not implicitly {@link infinitusai.be.FastTrackSuccessfulTaskNotes.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {infinitusai.be.IFastTrackSuccessfulTaskNotes} message FastTrackSuccessfulTaskNotes message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackSuccessfulTaskNotes.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callOutcome != null && Object.hasOwnProperty.call(message, "callOutcome"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callOutcome);
                    if (message.followUpDate != null && Object.hasOwnProperty.call(message, "followUpDate"))
                        $root.infinitusai.be.Date.encode(message.followUpDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.finalizedNotes != null && Object.hasOwnProperty.call(message, "finalizedNotes"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.finalizedNotes);
                    return writer;
                };
    
                /**
                 * Encodes the specified FastTrackSuccessfulTaskNotes message, length delimited. Does not implicitly {@link infinitusai.be.FastTrackSuccessfulTaskNotes.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {infinitusai.be.IFastTrackSuccessfulTaskNotes} message FastTrackSuccessfulTaskNotes message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackSuccessfulTaskNotes.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FastTrackSuccessfulTaskNotes message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FastTrackSuccessfulTaskNotes} FastTrackSuccessfulTaskNotes
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackSuccessfulTaskNotes.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FastTrackSuccessfulTaskNotes();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callOutcome = reader.string();
                                break;
                            }
                        case 2: {
                                message.followUpDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.finalizedNotes = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FastTrackSuccessfulTaskNotes message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FastTrackSuccessfulTaskNotes} FastTrackSuccessfulTaskNotes
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackSuccessfulTaskNotes.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FastTrackSuccessfulTaskNotes message.
                 * @function verify
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastTrackSuccessfulTaskNotes.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callOutcome != null && message.hasOwnProperty("callOutcome"))
                        if (!$util.isString(message.callOutcome))
                            return "callOutcome: string expected";
                    if (message.followUpDate != null && message.hasOwnProperty("followUpDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.followUpDate);
                        if (error)
                            return "followUpDate." + error;
                    }
                    if (message.finalizedNotes != null && message.hasOwnProperty("finalizedNotes"))
                        if (!$util.isString(message.finalizedNotes))
                            return "finalizedNotes: string expected";
                    return null;
                };
    
                /**
                 * Creates a FastTrackSuccessfulTaskNotes message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FastTrackSuccessfulTaskNotes} FastTrackSuccessfulTaskNotes
                 */
                FastTrackSuccessfulTaskNotes.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FastTrackSuccessfulTaskNotes)
                        return object;
                    var message = new $root.infinitusai.be.FastTrackSuccessfulTaskNotes();
                    if (object.callOutcome != null)
                        message.callOutcome = String(object.callOutcome);
                    if (object.followUpDate != null) {
                        if (typeof object.followUpDate !== "object")
                            throw TypeError(".infinitusai.be.FastTrackSuccessfulTaskNotes.followUpDate: object expected");
                        message.followUpDate = $root.infinitusai.be.Date.fromObject(object.followUpDate);
                    }
                    if (object.finalizedNotes != null)
                        message.finalizedNotes = String(object.finalizedNotes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FastTrackSuccessfulTaskNotes message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {infinitusai.be.FastTrackSuccessfulTaskNotes} message FastTrackSuccessfulTaskNotes
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastTrackSuccessfulTaskNotes.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callOutcome = "";
                        object.followUpDate = null;
                        object.finalizedNotes = "";
                    }
                    if (message.callOutcome != null && message.hasOwnProperty("callOutcome"))
                        object.callOutcome = message.callOutcome;
                    if (message.followUpDate != null && message.hasOwnProperty("followUpDate"))
                        object.followUpDate = $root.infinitusai.be.Date.toObject(message.followUpDate, options);
                    if (message.finalizedNotes != null && message.hasOwnProperty("finalizedNotes"))
                        object.finalizedNotes = message.finalizedNotes;
                    return object;
                };
    
                /**
                 * Converts this FastTrackSuccessfulTaskNotes to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastTrackSuccessfulTaskNotes.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FastTrackSuccessfulTaskNotes
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FastTrackSuccessfulTaskNotes
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FastTrackSuccessfulTaskNotes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FastTrackSuccessfulTaskNotes";
                };
    
                return FastTrackSuccessfulTaskNotes;
            })();
    
            be.GetBundledCustomerTasksRequest = (function() {
    
                /**
                 * Properties of a GetBundledCustomerTasksRequest.
                 * @memberof infinitusai.be
                 * @interface IGetBundledCustomerTasksRequest
                 * @property {string|null} [taskUuid] GetBundledCustomerTasksRequest taskUuid
                 */
    
                /**
                 * Constructs a new GetBundledCustomerTasksRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBundledCustomerTasksRequest.
                 * @implements IGetBundledCustomerTasksRequest
                 * @constructor
                 * @param {infinitusai.be.IGetBundledCustomerTasksRequest=} [properties] Properties to set
                 */
                function GetBundledCustomerTasksRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBundledCustomerTasksRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @instance
                 */
                GetBundledCustomerTasksRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new GetBundledCustomerTasksRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetBundledCustomerTasksRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBundledCustomerTasksRequest} GetBundledCustomerTasksRequest instance
                 */
                GetBundledCustomerTasksRequest.create = function create(properties) {
                    return new GetBundledCustomerTasksRequest(properties);
                };
    
                /**
                 * Encodes the specified GetBundledCustomerTasksRequest message. Does not implicitly {@link infinitusai.be.GetBundledCustomerTasksRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetBundledCustomerTasksRequest} message GetBundledCustomerTasksRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBundledCustomerTasksRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBundledCustomerTasksRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetBundledCustomerTasksRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {infinitusai.be.IGetBundledCustomerTasksRequest} message GetBundledCustomerTasksRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBundledCustomerTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBundledCustomerTasksRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBundledCustomerTasksRequest} GetBundledCustomerTasksRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBundledCustomerTasksRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBundledCustomerTasksRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBundledCustomerTasksRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBundledCustomerTasksRequest} GetBundledCustomerTasksRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBundledCustomerTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBundledCustomerTasksRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBundledCustomerTasksRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetBundledCustomerTasksRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBundledCustomerTasksRequest} GetBundledCustomerTasksRequest
                 */
                GetBundledCustomerTasksRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBundledCustomerTasksRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetBundledCustomerTasksRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBundledCustomerTasksRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {infinitusai.be.GetBundledCustomerTasksRequest} message GetBundledCustomerTasksRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBundledCustomerTasksRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this GetBundledCustomerTasksRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBundledCustomerTasksRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBundledCustomerTasksRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBundledCustomerTasksRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBundledCustomerTasksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBundledCustomerTasksRequest";
                };
    
                return GetBundledCustomerTasksRequest;
            })();
    
            be.GetBundledCustomerTasksResponse = (function() {
    
                /**
                 * Properties of a GetBundledCustomerTasksResponse.
                 * @memberof infinitusai.be
                 * @interface IGetBundledCustomerTasksResponse
                 * @property {Array.<infinitusai.be.ICustomerTaskDoc>|null} [tasks] GetBundledCustomerTasksResponse tasks
                 */
    
                /**
                 * Constructs a new GetBundledCustomerTasksResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetBundledCustomerTasksResponse.
                 * @implements IGetBundledCustomerTasksResponse
                 * @constructor
                 * @param {infinitusai.be.IGetBundledCustomerTasksResponse=} [properties] Properties to set
                 */
                function GetBundledCustomerTasksResponse(properties) {
                    this.tasks = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBundledCustomerTasksResponse tasks.
                 * @member {Array.<infinitusai.be.ICustomerTaskDoc>} tasks
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @instance
                 */
                GetBundledCustomerTasksResponse.prototype.tasks = $util.emptyArray;
    
                /**
                 * Creates a new GetBundledCustomerTasksResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetBundledCustomerTasksResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetBundledCustomerTasksResponse} GetBundledCustomerTasksResponse instance
                 */
                GetBundledCustomerTasksResponse.create = function create(properties) {
                    return new GetBundledCustomerTasksResponse(properties);
                };
    
                /**
                 * Encodes the specified GetBundledCustomerTasksResponse message. Does not implicitly {@link infinitusai.be.GetBundledCustomerTasksResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetBundledCustomerTasksResponse} message GetBundledCustomerTasksResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBundledCustomerTasksResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tasks != null && message.tasks.length)
                        for (var i = 0; i < message.tasks.length; ++i)
                            $root.infinitusai.be.CustomerTaskDoc.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBundledCustomerTasksResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetBundledCustomerTasksResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {infinitusai.be.IGetBundledCustomerTasksResponse} message GetBundledCustomerTasksResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBundledCustomerTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBundledCustomerTasksResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetBundledCustomerTasksResponse} GetBundledCustomerTasksResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBundledCustomerTasksResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetBundledCustomerTasksResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.infinitusai.be.CustomerTaskDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBundledCustomerTasksResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetBundledCustomerTasksResponse} GetBundledCustomerTasksResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBundledCustomerTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBundledCustomerTasksResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBundledCustomerTasksResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tasks != null && message.hasOwnProperty("tasks")) {
                        if (!Array.isArray(message.tasks))
                            return "tasks: array expected";
                        for (var i = 0; i < message.tasks.length; ++i) {
                            var error = $root.infinitusai.be.CustomerTaskDoc.verify(message.tasks[i]);
                            if (error)
                                return "tasks." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetBundledCustomerTasksResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetBundledCustomerTasksResponse} GetBundledCustomerTasksResponse
                 */
                GetBundledCustomerTasksResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetBundledCustomerTasksResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetBundledCustomerTasksResponse();
                    if (object.tasks) {
                        if (!Array.isArray(object.tasks))
                            throw TypeError(".infinitusai.be.GetBundledCustomerTasksResponse.tasks: array expected");
                        message.tasks = [];
                        for (var i = 0; i < object.tasks.length; ++i) {
                            if (typeof object.tasks[i] !== "object")
                                throw TypeError(".infinitusai.be.GetBundledCustomerTasksResponse.tasks: object expected");
                            message.tasks[i] = $root.infinitusai.be.CustomerTaskDoc.fromObject(object.tasks[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBundledCustomerTasksResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {infinitusai.be.GetBundledCustomerTasksResponse} message GetBundledCustomerTasksResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBundledCustomerTasksResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.tasks = [];
                    if (message.tasks && message.tasks.length) {
                        object.tasks = [];
                        for (var j = 0; j < message.tasks.length; ++j)
                            object.tasks[j] = $root.infinitusai.be.CustomerTaskDoc.toObject(message.tasks[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetBundledCustomerTasksResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBundledCustomerTasksResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBundledCustomerTasksResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetBundledCustomerTasksResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBundledCustomerTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetBundledCustomerTasksResponse";
                };
    
                return GetBundledCustomerTasksResponse;
            })();
    
            be.StandardPlanLetter = (function() {
    
                /**
                 * Properties of a StandardPlanLetter.
                 * @memberof infinitusai.be
                 * @interface IStandardPlanLetter
                 * @property {infinitusai.be.StandardPlanLetter.Type|null} [type] StandardPlanLetter type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] StandardPlanLetter metadata
                 */
    
                /**
                 * Constructs a new StandardPlanLetter.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardPlanLetter.
                 * @implements IStandardPlanLetter
                 * @constructor
                 * @param {infinitusai.be.IStandardPlanLetter=} [properties] Properties to set
                 */
                function StandardPlanLetter(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardPlanLetter type.
                 * @member {infinitusai.be.StandardPlanLetter.Type} type
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @instance
                 */
                StandardPlanLetter.prototype.type = 0;
    
                /**
                 * StandardPlanLetter metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @instance
                 */
                StandardPlanLetter.prototype.metadata = null;
    
                /**
                 * Creates a new StandardPlanLetter instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {infinitusai.be.IStandardPlanLetter=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardPlanLetter} StandardPlanLetter instance
                 */
                StandardPlanLetter.create = function create(properties) {
                    return new StandardPlanLetter(properties);
                };
    
                /**
                 * Encodes the specified StandardPlanLetter message. Does not implicitly {@link infinitusai.be.StandardPlanLetter.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {infinitusai.be.IStandardPlanLetter} message StandardPlanLetter message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardPlanLetter.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardPlanLetter message, length delimited. Does not implicitly {@link infinitusai.be.StandardPlanLetter.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {infinitusai.be.IStandardPlanLetter} message StandardPlanLetter message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardPlanLetter.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardPlanLetter message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardPlanLetter} StandardPlanLetter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardPlanLetter.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardPlanLetter();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardPlanLetter message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardPlanLetter} StandardPlanLetter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardPlanLetter.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardPlanLetter message.
                 * @function verify
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardPlanLetter.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardPlanLetter message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardPlanLetter} StandardPlanLetter
                 */
                StandardPlanLetter.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardPlanLetter)
                        return object;
                    var message = new $root.infinitusai.be.StandardPlanLetter();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "A":
                    case 1:
                        message.type = 1;
                        break;
                    case "B":
                    case 2:
                        message.type = 2;
                        break;
                    case "C":
                    case 3:
                        message.type = 3;
                        break;
                    case "D":
                    case 4:
                        message.type = 4;
                        break;
                    case "E":
                    case 5:
                        message.type = 5;
                        break;
                    case "F":
                    case 6:
                        message.type = 6;
                        break;
                    case "G":
                    case 7:
                        message.type = 7;
                        break;
                    case "H":
                    case 8:
                        message.type = 8;
                        break;
                    case "I":
                    case 9:
                        message.type = 9;
                        break;
                    case "J":
                    case 10:
                        message.type = 10;
                        break;
                    case "K":
                    case 11:
                        message.type = 11;
                        break;
                    case "L":
                    case 12:
                        message.type = 12;
                        break;
                    case "M":
                    case 13:
                        message.type = 13;
                        break;
                    case "N":
                    case 14:
                        message.type = 14;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.StandardPlanLetter.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardPlanLetter message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {infinitusai.be.StandardPlanLetter} message StandardPlanLetter
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardPlanLetter.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.StandardPlanLetter.Type[message.type] === undefined ? message.type : $root.infinitusai.be.StandardPlanLetter.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this StandardPlanLetter to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardPlanLetter.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardPlanLetter
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardPlanLetter
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardPlanLetter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardPlanLetter";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.StandardPlanLetter.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} A=1 A value
                 * @property {number} B=2 B value
                 * @property {number} C=3 C value
                 * @property {number} D=4 D value
                 * @property {number} E=5 E value
                 * @property {number} F=6 F value
                 * @property {number} G=7 G value
                 * @property {number} H=8 H value
                 * @property {number} I=9 I value
                 * @property {number} J=10 J value
                 * @property {number} K=11 K value
                 * @property {number} L=12 L value
                 * @property {number} M=13 M value
                 * @property {number} N=14 N value
                 */
                StandardPlanLetter.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "A"] = 1;
                    values[valuesById[2] = "B"] = 2;
                    values[valuesById[3] = "C"] = 3;
                    values[valuesById[4] = "D"] = 4;
                    values[valuesById[5] = "E"] = 5;
                    values[valuesById[6] = "F"] = 6;
                    values[valuesById[7] = "G"] = 7;
                    values[valuesById[8] = "H"] = 8;
                    values[valuesById[9] = "I"] = 9;
                    values[valuesById[10] = "J"] = 10;
                    values[valuesById[11] = "K"] = 11;
                    values[valuesById[12] = "L"] = 12;
                    values[valuesById[13] = "M"] = 13;
                    values[valuesById[14] = "N"] = 14;
                    return values;
                })();
    
                return StandardPlanLetter;
            })();
    
            be.MedSupBVCallOutputs = (function() {
    
                /**
                 * Properties of a MedSupBVCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IMedSupBVCallOutputs
                 * @property {infinitusai.be.IStandardPlanLetter|null} [knownLetter] MedSupBVCallOutputs knownLetter
                 * @property {infinitusai.be.INullableString|null} [nonStandardLetter] MedSupBVCallOutputs nonStandardLetter
                 * @property {infinitusai.be.INullableBool|null} [highDeductible] MedSupBVCallOutputs highDeductible
                 * @property {infinitusai.be.IMoney|null} [deductibleMet] MedSupBVCallOutputs deductibleMet
                 * @property {infinitusai.be.IMoney|null} [outOfPocketMet] MedSupBVCallOutputs outOfPocketMet
                 * @property {infinitusai.be.IMoney|null} [copay] MedSupBVCallOutputs copay
                 * @property {infinitusai.be.IMoney|null} [deductibleTotal] MedSupBVCallOutputs deductibleTotal
                 * @property {infinitusai.be.IMoney|null} [maxOutOfPocket] MedSupBVCallOutputs maxOutOfPocket
                 * @property {infinitusai.be.INullableString|null} [callReferenceNumber] MedSupBVCallOutputs callReferenceNumber
                 * @property {infinitusai.be.INullableInt32|null} [coinsurancePercentage] MedSupBVCallOutputs coinsurancePercentage
                 */
    
                /**
                 * Constructs a new MedSupBVCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MedSupBVCallOutputs.
                 * @implements IMedSupBVCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IMedSupBVCallOutputs=} [properties] Properties to set
                 */
                function MedSupBVCallOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MedSupBVCallOutputs knownLetter.
                 * @member {infinitusai.be.IStandardPlanLetter|null|undefined} knownLetter
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.knownLetter = null;
    
                /**
                 * MedSupBVCallOutputs nonStandardLetter.
                 * @member {infinitusai.be.INullableString|null|undefined} nonStandardLetter
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.nonStandardLetter = null;
    
                /**
                 * MedSupBVCallOutputs highDeductible.
                 * @member {infinitusai.be.INullableBool|null|undefined} highDeductible
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.highDeductible = null;
    
                /**
                 * MedSupBVCallOutputs deductibleMet.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleMet
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.deductibleMet = null;
    
                /**
                 * MedSupBVCallOutputs outOfPocketMet.
                 * @member {infinitusai.be.IMoney|null|undefined} outOfPocketMet
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.outOfPocketMet = null;
    
                /**
                 * MedSupBVCallOutputs copay.
                 * @member {infinitusai.be.IMoney|null|undefined} copay
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.copay = null;
    
                /**
                 * MedSupBVCallOutputs deductibleTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleTotal
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.deductibleTotal = null;
    
                /**
                 * MedSupBVCallOutputs maxOutOfPocket.
                 * @member {infinitusai.be.IMoney|null|undefined} maxOutOfPocket
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.maxOutOfPocket = null;
    
                /**
                 * MedSupBVCallOutputs callReferenceNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} callReferenceNumber
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.callReferenceNumber = null;
    
                /**
                 * MedSupBVCallOutputs coinsurancePercentage.
                 * @member {infinitusai.be.INullableInt32|null|undefined} coinsurancePercentage
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 */
                MedSupBVCallOutputs.prototype.coinsurancePercentage = null;
    
                /**
                 * Creates a new MedSupBVCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IMedSupBVCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.MedSupBVCallOutputs} MedSupBVCallOutputs instance
                 */
                MedSupBVCallOutputs.create = function create(properties) {
                    return new MedSupBVCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified MedSupBVCallOutputs message. Does not implicitly {@link infinitusai.be.MedSupBVCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IMedSupBVCallOutputs} message MedSupBVCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MedSupBVCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.knownLetter != null && Object.hasOwnProperty.call(message, "knownLetter"))
                        $root.infinitusai.be.StandardPlanLetter.encode(message.knownLetter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.nonStandardLetter != null && Object.hasOwnProperty.call(message, "nonStandardLetter"))
                        $root.infinitusai.be.NullableString.encode(message.nonStandardLetter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.highDeductible != null && Object.hasOwnProperty.call(message, "highDeductible"))
                        $root.infinitusai.be.NullableBool.encode(message.highDeductible, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.deductibleMet != null && Object.hasOwnProperty.call(message, "deductibleMet"))
                        $root.infinitusai.be.Money.encode(message.deductibleMet, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.outOfPocketMet != null && Object.hasOwnProperty.call(message, "outOfPocketMet"))
                        $root.infinitusai.be.Money.encode(message.outOfPocketMet, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.copay != null && Object.hasOwnProperty.call(message, "copay"))
                        $root.infinitusai.be.Money.encode(message.copay, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.deductibleTotal != null && Object.hasOwnProperty.call(message, "deductibleTotal"))
                        $root.infinitusai.be.Money.encode(message.deductibleTotal, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.maxOutOfPocket != null && Object.hasOwnProperty.call(message, "maxOutOfPocket"))
                        $root.infinitusai.be.Money.encode(message.maxOutOfPocket, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.callReferenceNumber != null && Object.hasOwnProperty.call(message, "callReferenceNumber"))
                        $root.infinitusai.be.NullableString.encode(message.callReferenceNumber, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.coinsurancePercentage != null && Object.hasOwnProperty.call(message, "coinsurancePercentage"))
                        $root.infinitusai.be.NullableInt32.encode(message.coinsurancePercentage, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MedSupBVCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.MedSupBVCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IMedSupBVCallOutputs} message MedSupBVCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MedSupBVCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MedSupBVCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MedSupBVCallOutputs} MedSupBVCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MedSupBVCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MedSupBVCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.knownLetter = $root.infinitusai.be.StandardPlanLetter.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.nonStandardLetter = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.highDeductible = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.deductibleMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.outOfPocketMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.copay = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.deductibleTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.maxOutOfPocket = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.callReferenceNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.coinsurancePercentage = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MedSupBVCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MedSupBVCallOutputs} MedSupBVCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MedSupBVCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MedSupBVCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MedSupBVCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.knownLetter != null && message.hasOwnProperty("knownLetter")) {
                        var error = $root.infinitusai.be.StandardPlanLetter.verify(message.knownLetter);
                        if (error)
                            return "knownLetter." + error;
                    }
                    if (message.nonStandardLetter != null && message.hasOwnProperty("nonStandardLetter")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.nonStandardLetter);
                        if (error)
                            return "nonStandardLetter." + error;
                    }
                    if (message.highDeductible != null && message.hasOwnProperty("highDeductible")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.highDeductible);
                        if (error)
                            return "highDeductible." + error;
                    }
                    if (message.deductibleMet != null && message.hasOwnProperty("deductibleMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleMet);
                        if (error)
                            return "deductibleMet." + error;
                    }
                    if (message.outOfPocketMet != null && message.hasOwnProperty("outOfPocketMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.outOfPocketMet);
                        if (error)
                            return "outOfPocketMet." + error;
                    }
                    if (message.copay != null && message.hasOwnProperty("copay")) {
                        var error = $root.infinitusai.be.Money.verify(message.copay);
                        if (error)
                            return "copay." + error;
                    }
                    if (message.deductibleTotal != null && message.hasOwnProperty("deductibleTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleTotal);
                        if (error)
                            return "deductibleTotal." + error;
                    }
                    if (message.maxOutOfPocket != null && message.hasOwnProperty("maxOutOfPocket")) {
                        var error = $root.infinitusai.be.Money.verify(message.maxOutOfPocket);
                        if (error)
                            return "maxOutOfPocket." + error;
                    }
                    if (message.callReferenceNumber != null && message.hasOwnProperty("callReferenceNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.callReferenceNumber);
                        if (error)
                            return "callReferenceNumber." + error;
                    }
                    if (message.coinsurancePercentage != null && message.hasOwnProperty("coinsurancePercentage")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.coinsurancePercentage);
                        if (error)
                            return "coinsurancePercentage." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a MedSupBVCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MedSupBVCallOutputs} MedSupBVCallOutputs
                 */
                MedSupBVCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MedSupBVCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.MedSupBVCallOutputs();
                    if (object.knownLetter != null) {
                        if (typeof object.knownLetter !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.knownLetter: object expected");
                        message.knownLetter = $root.infinitusai.be.StandardPlanLetter.fromObject(object.knownLetter);
                    }
                    if (object.nonStandardLetter != null) {
                        if (typeof object.nonStandardLetter !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.nonStandardLetter: object expected");
                        message.nonStandardLetter = $root.infinitusai.be.NullableString.fromObject(object.nonStandardLetter);
                    }
                    if (object.highDeductible != null) {
                        if (typeof object.highDeductible !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.highDeductible: object expected");
                        message.highDeductible = $root.infinitusai.be.NullableBool.fromObject(object.highDeductible);
                    }
                    if (object.deductibleMet != null) {
                        if (typeof object.deductibleMet !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.deductibleMet: object expected");
                        message.deductibleMet = $root.infinitusai.be.Money.fromObject(object.deductibleMet);
                    }
                    if (object.outOfPocketMet != null) {
                        if (typeof object.outOfPocketMet !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.outOfPocketMet: object expected");
                        message.outOfPocketMet = $root.infinitusai.be.Money.fromObject(object.outOfPocketMet);
                    }
                    if (object.copay != null) {
                        if (typeof object.copay !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.copay: object expected");
                        message.copay = $root.infinitusai.be.Money.fromObject(object.copay);
                    }
                    if (object.deductibleTotal != null) {
                        if (typeof object.deductibleTotal !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.deductibleTotal: object expected");
                        message.deductibleTotal = $root.infinitusai.be.Money.fromObject(object.deductibleTotal);
                    }
                    if (object.maxOutOfPocket != null) {
                        if (typeof object.maxOutOfPocket !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.maxOutOfPocket: object expected");
                        message.maxOutOfPocket = $root.infinitusai.be.Money.fromObject(object.maxOutOfPocket);
                    }
                    if (object.callReferenceNumber != null) {
                        if (typeof object.callReferenceNumber !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.callReferenceNumber: object expected");
                        message.callReferenceNumber = $root.infinitusai.be.NullableString.fromObject(object.callReferenceNumber);
                    }
                    if (object.coinsurancePercentage != null) {
                        if (typeof object.coinsurancePercentage !== "object")
                            throw TypeError(".infinitusai.be.MedSupBVCallOutputs.coinsurancePercentage: object expected");
                        message.coinsurancePercentage = $root.infinitusai.be.NullableInt32.fromObject(object.coinsurancePercentage);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MedSupBVCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {infinitusai.be.MedSupBVCallOutputs} message MedSupBVCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MedSupBVCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.knownLetter = null;
                        object.nonStandardLetter = null;
                        object.highDeductible = null;
                        object.deductibleMet = null;
                        object.outOfPocketMet = null;
                        object.copay = null;
                        object.deductibleTotal = null;
                        object.maxOutOfPocket = null;
                        object.callReferenceNumber = null;
                        object.coinsurancePercentage = null;
                    }
                    if (message.knownLetter != null && message.hasOwnProperty("knownLetter"))
                        object.knownLetter = $root.infinitusai.be.StandardPlanLetter.toObject(message.knownLetter, options);
                    if (message.nonStandardLetter != null && message.hasOwnProperty("nonStandardLetter"))
                        object.nonStandardLetter = $root.infinitusai.be.NullableString.toObject(message.nonStandardLetter, options);
                    if (message.highDeductible != null && message.hasOwnProperty("highDeductible"))
                        object.highDeductible = $root.infinitusai.be.NullableBool.toObject(message.highDeductible, options);
                    if (message.deductibleMet != null && message.hasOwnProperty("deductibleMet"))
                        object.deductibleMet = $root.infinitusai.be.Money.toObject(message.deductibleMet, options);
                    if (message.outOfPocketMet != null && message.hasOwnProperty("outOfPocketMet"))
                        object.outOfPocketMet = $root.infinitusai.be.Money.toObject(message.outOfPocketMet, options);
                    if (message.copay != null && message.hasOwnProperty("copay"))
                        object.copay = $root.infinitusai.be.Money.toObject(message.copay, options);
                    if (message.deductibleTotal != null && message.hasOwnProperty("deductibleTotal"))
                        object.deductibleTotal = $root.infinitusai.be.Money.toObject(message.deductibleTotal, options);
                    if (message.maxOutOfPocket != null && message.hasOwnProperty("maxOutOfPocket"))
                        object.maxOutOfPocket = $root.infinitusai.be.Money.toObject(message.maxOutOfPocket, options);
                    if (message.callReferenceNumber != null && message.hasOwnProperty("callReferenceNumber"))
                        object.callReferenceNumber = $root.infinitusai.be.NullableString.toObject(message.callReferenceNumber, options);
                    if (message.coinsurancePercentage != null && message.hasOwnProperty("coinsurancePercentage"))
                        object.coinsurancePercentage = $root.infinitusai.be.NullableInt32.toObject(message.coinsurancePercentage, options);
                    return object;
                };
    
                /**
                 * Converts this MedSupBVCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MedSupBVCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MedSupBVCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MedSupBVCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MedSupBVCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MedSupBVCallOutputs";
                };
    
                return MedSupBVCallOutputs;
            })();
    
            be.ChangeHealthcareBenefits = (function() {
    
                /**
                 * Properties of a ChangeHealthcareBenefits.
                 * @memberof infinitusai.be
                 * @interface IChangeHealthcareBenefits
                 * @property {infinitusai.be.INullableBool|null} [hasDeductible] ChangeHealthcareBenefits hasDeductible
                 * @property {infinitusai.be.IMoney|null} [deductibleTotal] ChangeHealthcareBenefits deductibleTotal
                 * @property {infinitusai.be.IMoney|null} [deductibleRemaining] ChangeHealthcareBenefits deductibleRemaining
                 * @property {infinitusai.be.IMoney|null} [deductibleMet] ChangeHealthcareBenefits deductibleMet
                 * @property {infinitusai.be.INullableBool|null} [hasFamilyDeductible] ChangeHealthcareBenefits hasFamilyDeductible
                 * @property {infinitusai.be.IMoney|null} [familyDeductibleTotal] ChangeHealthcareBenefits familyDeductibleTotal
                 * @property {infinitusai.be.IMoney|null} [familyDeductibleRemaining] ChangeHealthcareBenefits familyDeductibleRemaining
                 * @property {infinitusai.be.IMoney|null} [familyDeductibleMet] ChangeHealthcareBenefits familyDeductibleMet
                 * @property {infinitusai.be.INullableBool|null} [hasOop] ChangeHealthcareBenefits hasOop
                 * @property {infinitusai.be.IMoney|null} [oopTotal] ChangeHealthcareBenefits oopTotal
                 * @property {infinitusai.be.IMoney|null} [oopRemaining] ChangeHealthcareBenefits oopRemaining
                 * @property {infinitusai.be.IMoney|null} [oopMet] ChangeHealthcareBenefits oopMet
                 * @property {infinitusai.be.INullableBool|null} [hasFamilyOop] ChangeHealthcareBenefits hasFamilyOop
                 * @property {infinitusai.be.IMoney|null} [familyOopTotal] ChangeHealthcareBenefits familyOopTotal
                 * @property {infinitusai.be.IMoney|null} [familyOopRemaining] ChangeHealthcareBenefits familyOopRemaining
                 * @property {infinitusai.be.IMoney|null} [familyOopMet] ChangeHealthcareBenefits familyOopMet
                 * @property {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null} [specialistVisitBenefits] ChangeHealthcareBenefits specialistVisitBenefits
                 * @property {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null} [hospitalOutpatientVisitBenefits] ChangeHealthcareBenefits hospitalOutpatientVisitBenefits
                 */
    
                /**
                 * Constructs a new ChangeHealthcareBenefits.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChangeHealthcareBenefits.
                 * @implements IChangeHealthcareBenefits
                 * @constructor
                 * @param {infinitusai.be.IChangeHealthcareBenefits=} [properties] Properties to set
                 */
                function ChangeHealthcareBenefits(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChangeHealthcareBenefits hasDeductible.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasDeductible
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.hasDeductible = null;
    
                /**
                 * ChangeHealthcareBenefits deductibleTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleTotal
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.deductibleTotal = null;
    
                /**
                 * ChangeHealthcareBenefits deductibleRemaining.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleRemaining
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.deductibleRemaining = null;
    
                /**
                 * ChangeHealthcareBenefits deductibleMet.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleMet
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.deductibleMet = null;
    
                /**
                 * ChangeHealthcareBenefits hasFamilyDeductible.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasFamilyDeductible
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.hasFamilyDeductible = null;
    
                /**
                 * ChangeHealthcareBenefits familyDeductibleTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} familyDeductibleTotal
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.familyDeductibleTotal = null;
    
                /**
                 * ChangeHealthcareBenefits familyDeductibleRemaining.
                 * @member {infinitusai.be.IMoney|null|undefined} familyDeductibleRemaining
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.familyDeductibleRemaining = null;
    
                /**
                 * ChangeHealthcareBenefits familyDeductibleMet.
                 * @member {infinitusai.be.IMoney|null|undefined} familyDeductibleMet
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.familyDeductibleMet = null;
    
                /**
                 * ChangeHealthcareBenefits hasOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasOop
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.hasOop = null;
    
                /**
                 * ChangeHealthcareBenefits oopTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} oopTotal
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.oopTotal = null;
    
                /**
                 * ChangeHealthcareBenefits oopRemaining.
                 * @member {infinitusai.be.IMoney|null|undefined} oopRemaining
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.oopRemaining = null;
    
                /**
                 * ChangeHealthcareBenefits oopMet.
                 * @member {infinitusai.be.IMoney|null|undefined} oopMet
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.oopMet = null;
    
                /**
                 * ChangeHealthcareBenefits hasFamilyOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasFamilyOop
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.hasFamilyOop = null;
    
                /**
                 * ChangeHealthcareBenefits familyOopTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} familyOopTotal
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.familyOopTotal = null;
    
                /**
                 * ChangeHealthcareBenefits familyOopRemaining.
                 * @member {infinitusai.be.IMoney|null|undefined} familyOopRemaining
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.familyOopRemaining = null;
    
                /**
                 * ChangeHealthcareBenefits familyOopMet.
                 * @member {infinitusai.be.IMoney|null|undefined} familyOopMet
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.familyOopMet = null;
    
                /**
                 * ChangeHealthcareBenefits specialistVisitBenefits.
                 * @member {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null|undefined} specialistVisitBenefits
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.specialistVisitBenefits = null;
    
                /**
                 * ChangeHealthcareBenefits hospitalOutpatientVisitBenefits.
                 * @member {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null|undefined} hospitalOutpatientVisitBenefits
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 */
                ChangeHealthcareBenefits.prototype.hospitalOutpatientVisitBenefits = null;
    
                /**
                 * Creates a new ChangeHealthcareBenefits instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareBenefits=} [properties] Properties to set
                 * @returns {infinitusai.be.ChangeHealthcareBenefits} ChangeHealthcareBenefits instance
                 */
                ChangeHealthcareBenefits.create = function create(properties) {
                    return new ChangeHealthcareBenefits(properties);
                };
    
                /**
                 * Encodes the specified ChangeHealthcareBenefits message. Does not implicitly {@link infinitusai.be.ChangeHealthcareBenefits.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareBenefits} message ChangeHealthcareBenefits message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChangeHealthcareBenefits.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hasDeductible != null && Object.hasOwnProperty.call(message, "hasDeductible"))
                        $root.infinitusai.be.NullableBool.encode(message.hasDeductible, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.deductibleTotal != null && Object.hasOwnProperty.call(message, "deductibleTotal"))
                        $root.infinitusai.be.Money.encode(message.deductibleTotal, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.deductibleRemaining != null && Object.hasOwnProperty.call(message, "deductibleRemaining"))
                        $root.infinitusai.be.Money.encode(message.deductibleRemaining, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.hasFamilyDeductible != null && Object.hasOwnProperty.call(message, "hasFamilyDeductible"))
                        $root.infinitusai.be.NullableBool.encode(message.hasFamilyDeductible, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.familyDeductibleTotal != null && Object.hasOwnProperty.call(message, "familyDeductibleTotal"))
                        $root.infinitusai.be.Money.encode(message.familyDeductibleTotal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.familyDeductibleRemaining != null && Object.hasOwnProperty.call(message, "familyDeductibleRemaining"))
                        $root.infinitusai.be.Money.encode(message.familyDeductibleRemaining, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.hasOop != null && Object.hasOwnProperty.call(message, "hasOop"))
                        $root.infinitusai.be.NullableBool.encode(message.hasOop, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.oopTotal != null && Object.hasOwnProperty.call(message, "oopTotal"))
                        $root.infinitusai.be.Money.encode(message.oopTotal, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.oopRemaining != null && Object.hasOwnProperty.call(message, "oopRemaining"))
                        $root.infinitusai.be.Money.encode(message.oopRemaining, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.hasFamilyOop != null && Object.hasOwnProperty.call(message, "hasFamilyOop"))
                        $root.infinitusai.be.NullableBool.encode(message.hasFamilyOop, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.familyOopTotal != null && Object.hasOwnProperty.call(message, "familyOopTotal"))
                        $root.infinitusai.be.Money.encode(message.familyOopTotal, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.familyOopRemaining != null && Object.hasOwnProperty.call(message, "familyOopRemaining"))
                        $root.infinitusai.be.Money.encode(message.familyOopRemaining, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.specialistVisitBenefits != null && Object.hasOwnProperty.call(message, "specialistVisitBenefits"))
                        $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.encode(message.specialistVisitBenefits, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.hospitalOutpatientVisitBenefits != null && Object.hasOwnProperty.call(message, "hospitalOutpatientVisitBenefits"))
                        $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.encode(message.hospitalOutpatientVisitBenefits, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.deductibleMet != null && Object.hasOwnProperty.call(message, "deductibleMet"))
                        $root.infinitusai.be.Money.encode(message.deductibleMet, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.familyDeductibleMet != null && Object.hasOwnProperty.call(message, "familyDeductibleMet"))
                        $root.infinitusai.be.Money.encode(message.familyDeductibleMet, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.oopMet != null && Object.hasOwnProperty.call(message, "oopMet"))
                        $root.infinitusai.be.Money.encode(message.oopMet, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.familyOopMet != null && Object.hasOwnProperty.call(message, "familyOopMet"))
                        $root.infinitusai.be.Money.encode(message.familyOopMet, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ChangeHealthcareBenefits message, length delimited. Does not implicitly {@link infinitusai.be.ChangeHealthcareBenefits.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareBenefits} message ChangeHealthcareBenefits message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChangeHealthcareBenefits.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChangeHealthcareBenefits message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChangeHealthcareBenefits} ChangeHealthcareBenefits
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChangeHealthcareBenefits.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChangeHealthcareBenefits();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hasDeductible = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.deductibleTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.deductibleRemaining = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.deductibleMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.hasFamilyDeductible = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.familyDeductibleTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.familyDeductibleRemaining = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.familyDeductibleMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.hasOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.oopTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.oopRemaining = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.oopMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.hasFamilyOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.familyOopTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.familyOopRemaining = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                message.familyOopMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.specialistVisitBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.hospitalOutpatientVisitBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChangeHealthcareBenefits message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChangeHealthcareBenefits} ChangeHealthcareBenefits
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChangeHealthcareBenefits.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChangeHealthcareBenefits message.
                 * @function verify
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChangeHealthcareBenefits.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hasDeductible != null && message.hasOwnProperty("hasDeductible")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasDeductible);
                        if (error)
                            return "hasDeductible." + error;
                    }
                    if (message.deductibleTotal != null && message.hasOwnProperty("deductibleTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleTotal);
                        if (error)
                            return "deductibleTotal." + error;
                    }
                    if (message.deductibleRemaining != null && message.hasOwnProperty("deductibleRemaining")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleRemaining);
                        if (error)
                            return "deductibleRemaining." + error;
                    }
                    if (message.deductibleMet != null && message.hasOwnProperty("deductibleMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleMet);
                        if (error)
                            return "deductibleMet." + error;
                    }
                    if (message.hasFamilyDeductible != null && message.hasOwnProperty("hasFamilyDeductible")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasFamilyDeductible);
                        if (error)
                            return "hasFamilyDeductible." + error;
                    }
                    if (message.familyDeductibleTotal != null && message.hasOwnProperty("familyDeductibleTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyDeductibleTotal);
                        if (error)
                            return "familyDeductibleTotal." + error;
                    }
                    if (message.familyDeductibleRemaining != null && message.hasOwnProperty("familyDeductibleRemaining")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyDeductibleRemaining);
                        if (error)
                            return "familyDeductibleRemaining." + error;
                    }
                    if (message.familyDeductibleMet != null && message.hasOwnProperty("familyDeductibleMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyDeductibleMet);
                        if (error)
                            return "familyDeductibleMet." + error;
                    }
                    if (message.hasOop != null && message.hasOwnProperty("hasOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasOop);
                        if (error)
                            return "hasOop." + error;
                    }
                    if (message.oopTotal != null && message.hasOwnProperty("oopTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.oopTotal);
                        if (error)
                            return "oopTotal." + error;
                    }
                    if (message.oopRemaining != null && message.hasOwnProperty("oopRemaining")) {
                        var error = $root.infinitusai.be.Money.verify(message.oopRemaining);
                        if (error)
                            return "oopRemaining." + error;
                    }
                    if (message.oopMet != null && message.hasOwnProperty("oopMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.oopMet);
                        if (error)
                            return "oopMet." + error;
                    }
                    if (message.hasFamilyOop != null && message.hasOwnProperty("hasFamilyOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasFamilyOop);
                        if (error)
                            return "hasFamilyOop." + error;
                    }
                    if (message.familyOopTotal != null && message.hasOwnProperty("familyOopTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyOopTotal);
                        if (error)
                            return "familyOopTotal." + error;
                    }
                    if (message.familyOopRemaining != null && message.hasOwnProperty("familyOopRemaining")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyOopRemaining);
                        if (error)
                            return "familyOopRemaining." + error;
                    }
                    if (message.familyOopMet != null && message.hasOwnProperty("familyOopMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyOopMet);
                        if (error)
                            return "familyOopMet." + error;
                    }
                    if (message.specialistVisitBenefits != null && message.hasOwnProperty("specialistVisitBenefits")) {
                        var error = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.verify(message.specialistVisitBenefits);
                        if (error)
                            return "specialistVisitBenefits." + error;
                    }
                    if (message.hospitalOutpatientVisitBenefits != null && message.hasOwnProperty("hospitalOutpatientVisitBenefits")) {
                        var error = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.verify(message.hospitalOutpatientVisitBenefits);
                        if (error)
                            return "hospitalOutpatientVisitBenefits." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ChangeHealthcareBenefits message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChangeHealthcareBenefits} ChangeHealthcareBenefits
                 */
                ChangeHealthcareBenefits.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChangeHealthcareBenefits)
                        return object;
                    var message = new $root.infinitusai.be.ChangeHealthcareBenefits();
                    if (object.hasDeductible != null) {
                        if (typeof object.hasDeductible !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.hasDeductible: object expected");
                        message.hasDeductible = $root.infinitusai.be.NullableBool.fromObject(object.hasDeductible);
                    }
                    if (object.deductibleTotal != null) {
                        if (typeof object.deductibleTotal !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.deductibleTotal: object expected");
                        message.deductibleTotal = $root.infinitusai.be.Money.fromObject(object.deductibleTotal);
                    }
                    if (object.deductibleRemaining != null) {
                        if (typeof object.deductibleRemaining !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.deductibleRemaining: object expected");
                        message.deductibleRemaining = $root.infinitusai.be.Money.fromObject(object.deductibleRemaining);
                    }
                    if (object.deductibleMet != null) {
                        if (typeof object.deductibleMet !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.deductibleMet: object expected");
                        message.deductibleMet = $root.infinitusai.be.Money.fromObject(object.deductibleMet);
                    }
                    if (object.hasFamilyDeductible != null) {
                        if (typeof object.hasFamilyDeductible !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.hasFamilyDeductible: object expected");
                        message.hasFamilyDeductible = $root.infinitusai.be.NullableBool.fromObject(object.hasFamilyDeductible);
                    }
                    if (object.familyDeductibleTotal != null) {
                        if (typeof object.familyDeductibleTotal !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.familyDeductibleTotal: object expected");
                        message.familyDeductibleTotal = $root.infinitusai.be.Money.fromObject(object.familyDeductibleTotal);
                    }
                    if (object.familyDeductibleRemaining != null) {
                        if (typeof object.familyDeductibleRemaining !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.familyDeductibleRemaining: object expected");
                        message.familyDeductibleRemaining = $root.infinitusai.be.Money.fromObject(object.familyDeductibleRemaining);
                    }
                    if (object.familyDeductibleMet != null) {
                        if (typeof object.familyDeductibleMet !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.familyDeductibleMet: object expected");
                        message.familyDeductibleMet = $root.infinitusai.be.Money.fromObject(object.familyDeductibleMet);
                    }
                    if (object.hasOop != null) {
                        if (typeof object.hasOop !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.hasOop: object expected");
                        message.hasOop = $root.infinitusai.be.NullableBool.fromObject(object.hasOop);
                    }
                    if (object.oopTotal != null) {
                        if (typeof object.oopTotal !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.oopTotal: object expected");
                        message.oopTotal = $root.infinitusai.be.Money.fromObject(object.oopTotal);
                    }
                    if (object.oopRemaining != null) {
                        if (typeof object.oopRemaining !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.oopRemaining: object expected");
                        message.oopRemaining = $root.infinitusai.be.Money.fromObject(object.oopRemaining);
                    }
                    if (object.oopMet != null) {
                        if (typeof object.oopMet !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.oopMet: object expected");
                        message.oopMet = $root.infinitusai.be.Money.fromObject(object.oopMet);
                    }
                    if (object.hasFamilyOop != null) {
                        if (typeof object.hasFamilyOop !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.hasFamilyOop: object expected");
                        message.hasFamilyOop = $root.infinitusai.be.NullableBool.fromObject(object.hasFamilyOop);
                    }
                    if (object.familyOopTotal != null) {
                        if (typeof object.familyOopTotal !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.familyOopTotal: object expected");
                        message.familyOopTotal = $root.infinitusai.be.Money.fromObject(object.familyOopTotal);
                    }
                    if (object.familyOopRemaining != null) {
                        if (typeof object.familyOopRemaining !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.familyOopRemaining: object expected");
                        message.familyOopRemaining = $root.infinitusai.be.Money.fromObject(object.familyOopRemaining);
                    }
                    if (object.familyOopMet != null) {
                        if (typeof object.familyOopMet !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.familyOopMet: object expected");
                        message.familyOopMet = $root.infinitusai.be.Money.fromObject(object.familyOopMet);
                    }
                    if (object.specialistVisitBenefits != null) {
                        if (typeof object.specialistVisitBenefits !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.specialistVisitBenefits: object expected");
                        message.specialistVisitBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.fromObject(object.specialistVisitBenefits);
                    }
                    if (object.hospitalOutpatientVisitBenefits != null) {
                        if (typeof object.hospitalOutpatientVisitBenefits !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareBenefits.hospitalOutpatientVisitBenefits: object expected");
                        message.hospitalOutpatientVisitBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.fromObject(object.hospitalOutpatientVisitBenefits);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChangeHealthcareBenefits message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {infinitusai.be.ChangeHealthcareBenefits} message ChangeHealthcareBenefits
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChangeHealthcareBenefits.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hasDeductible = null;
                        object.deductibleTotal = null;
                        object.deductibleRemaining = null;
                        object.hasFamilyDeductible = null;
                        object.familyDeductibleTotal = null;
                        object.familyDeductibleRemaining = null;
                        object.hasOop = null;
                        object.oopTotal = null;
                        object.oopRemaining = null;
                        object.hasFamilyOop = null;
                        object.familyOopTotal = null;
                        object.familyOopRemaining = null;
                        object.specialistVisitBenefits = null;
                        object.hospitalOutpatientVisitBenefits = null;
                        object.deductibleMet = null;
                        object.familyDeductibleMet = null;
                        object.oopMet = null;
                        object.familyOopMet = null;
                    }
                    if (message.hasDeductible != null && message.hasOwnProperty("hasDeductible"))
                        object.hasDeductible = $root.infinitusai.be.NullableBool.toObject(message.hasDeductible, options);
                    if (message.deductibleTotal != null && message.hasOwnProperty("deductibleTotal"))
                        object.deductibleTotal = $root.infinitusai.be.Money.toObject(message.deductibleTotal, options);
                    if (message.deductibleRemaining != null && message.hasOwnProperty("deductibleRemaining"))
                        object.deductibleRemaining = $root.infinitusai.be.Money.toObject(message.deductibleRemaining, options);
                    if (message.hasFamilyDeductible != null && message.hasOwnProperty("hasFamilyDeductible"))
                        object.hasFamilyDeductible = $root.infinitusai.be.NullableBool.toObject(message.hasFamilyDeductible, options);
                    if (message.familyDeductibleTotal != null && message.hasOwnProperty("familyDeductibleTotal"))
                        object.familyDeductibleTotal = $root.infinitusai.be.Money.toObject(message.familyDeductibleTotal, options);
                    if (message.familyDeductibleRemaining != null && message.hasOwnProperty("familyDeductibleRemaining"))
                        object.familyDeductibleRemaining = $root.infinitusai.be.Money.toObject(message.familyDeductibleRemaining, options);
                    if (message.hasOop != null && message.hasOwnProperty("hasOop"))
                        object.hasOop = $root.infinitusai.be.NullableBool.toObject(message.hasOop, options);
                    if (message.oopTotal != null && message.hasOwnProperty("oopTotal"))
                        object.oopTotal = $root.infinitusai.be.Money.toObject(message.oopTotal, options);
                    if (message.oopRemaining != null && message.hasOwnProperty("oopRemaining"))
                        object.oopRemaining = $root.infinitusai.be.Money.toObject(message.oopRemaining, options);
                    if (message.hasFamilyOop != null && message.hasOwnProperty("hasFamilyOop"))
                        object.hasFamilyOop = $root.infinitusai.be.NullableBool.toObject(message.hasFamilyOop, options);
                    if (message.familyOopTotal != null && message.hasOwnProperty("familyOopTotal"))
                        object.familyOopTotal = $root.infinitusai.be.Money.toObject(message.familyOopTotal, options);
                    if (message.familyOopRemaining != null && message.hasOwnProperty("familyOopRemaining"))
                        object.familyOopRemaining = $root.infinitusai.be.Money.toObject(message.familyOopRemaining, options);
                    if (message.specialistVisitBenefits != null && message.hasOwnProperty("specialistVisitBenefits"))
                        object.specialistVisitBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.toObject(message.specialistVisitBenefits, options);
                    if (message.hospitalOutpatientVisitBenefits != null && message.hasOwnProperty("hospitalOutpatientVisitBenefits"))
                        object.hospitalOutpatientVisitBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.toObject(message.hospitalOutpatientVisitBenefits, options);
                    if (message.deductibleMet != null && message.hasOwnProperty("deductibleMet"))
                        object.deductibleMet = $root.infinitusai.be.Money.toObject(message.deductibleMet, options);
                    if (message.familyDeductibleMet != null && message.hasOwnProperty("familyDeductibleMet"))
                        object.familyDeductibleMet = $root.infinitusai.be.Money.toObject(message.familyDeductibleMet, options);
                    if (message.oopMet != null && message.hasOwnProperty("oopMet"))
                        object.oopMet = $root.infinitusai.be.Money.toObject(message.oopMet, options);
                    if (message.familyOopMet != null && message.hasOwnProperty("familyOopMet"))
                        object.familyOopMet = $root.infinitusai.be.Money.toObject(message.familyOopMet, options);
                    return object;
                };
    
                /**
                 * Converts this ChangeHealthcareBenefits to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChangeHealthcareBenefits.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChangeHealthcareBenefits
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChangeHealthcareBenefits
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChangeHealthcareBenefits.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChangeHealthcareBenefits";
                };
    
                return ChangeHealthcareBenefits;
            })();
    
            be.ChangeHealthcareOutputs = (function() {
    
                /**
                 * Properties of a ChangeHealthcareOutputs.
                 * @memberof infinitusai.be
                 * @interface IChangeHealthcareOutputs
                 * @property {string|null} [controlNumber] ChangeHealthcareOutputs controlNumber
                 * @property {boolean|null} [hasErrors] ChangeHealthcareOutputs hasErrors
                 * @property {boolean|null} [retryAllowed] ChangeHealthcareOutputs retryAllowed
                 * @property {string|null} [payerId] ChangeHealthcareOutputs payerId
                 * @property {infinitusai.be.INullableBool|null} [planActive] ChangeHealthcareOutputs planActive
                 * @property {infinitusai.be.IPlanType|null} [planType] ChangeHealthcareOutputs planType
                 * @property {infinitusai.be.IPolicyType|null} [policyType] ChangeHealthcareOutputs policyType
                 * @property {infinitusai.be.INullableString|null} [planName] ChangeHealthcareOutputs planName
                 * @property {infinitusai.be.INullableString|null} [groupNumber] ChangeHealthcareOutputs groupNumber
                 * @property {infinitusai.be.IDate|null} [effectiveDate] ChangeHealthcareOutputs effectiveDate
                 * @property {infinitusai.be.IDate|null} [termDate] ChangeHealthcareOutputs termDate
                 * @property {string|null} [memberId] ChangeHealthcareOutputs memberId
                 * @property {infinitusai.be.IChangeHealthcareBenefits|null} [inNetworkBenefits] ChangeHealthcareOutputs inNetworkBenefits
                 * @property {infinitusai.be.IChangeHealthcareBenefits|null} [outNetworkBenefits] ChangeHealthcareOutputs outNetworkBenefits
                 * @property {infinitusai.be.IChangeHealthcareBenefits|null} [defaultBenefits] ChangeHealthcareOutputs defaultBenefits
                 * @property {infinitusai.be.INetworkStatusType|null} [networkStatus] ChangeHealthcareOutputs networkStatus
                 * @property {infinitusai.be.INullableBool|null} [lifetimeMaximumExists] ChangeHealthcareOutputs lifetimeMaximumExists
                 * @property {infinitusai.be.IMoney|null} [lifetimeMaximum] ChangeHealthcareOutputs lifetimeMaximum
                 * @property {infinitusai.be.IMoney|null} [lifetimeMaximumRemaining] ChangeHealthcareOutputs lifetimeMaximumRemaining
                 * @property {infinitusai.be.IMoney|null} [lifetimeMaximumMet] ChangeHealthcareOutputs lifetimeMaximumMet
                 * @property {string|null} [payerName] ChangeHealthcareOutputs payerName
                 */
    
                /**
                 * Constructs a new ChangeHealthcareOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChangeHealthcareOutputs.
                 * @implements IChangeHealthcareOutputs
                 * @constructor
                 * @param {infinitusai.be.IChangeHealthcareOutputs=} [properties] Properties to set
                 */
                function ChangeHealthcareOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChangeHealthcareOutputs controlNumber.
                 * @member {string} controlNumber
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.controlNumber = "";
    
                /**
                 * ChangeHealthcareOutputs hasErrors.
                 * @member {boolean} hasErrors
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.hasErrors = false;
    
                /**
                 * ChangeHealthcareOutputs retryAllowed.
                 * @member {boolean} retryAllowed
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.retryAllowed = false;
    
                /**
                 * ChangeHealthcareOutputs payerId.
                 * @member {string} payerId
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.payerId = "";
    
                /**
                 * ChangeHealthcareOutputs planActive.
                 * @member {infinitusai.be.INullableBool|null|undefined} planActive
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.planActive = null;
    
                /**
                 * ChangeHealthcareOutputs planType.
                 * @member {infinitusai.be.IPlanType|null|undefined} planType
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.planType = null;
    
                /**
                 * ChangeHealthcareOutputs policyType.
                 * @member {infinitusai.be.IPolicyType|null|undefined} policyType
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.policyType = null;
    
                /**
                 * ChangeHealthcareOutputs planName.
                 * @member {infinitusai.be.INullableString|null|undefined} planName
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.planName = null;
    
                /**
                 * ChangeHealthcareOutputs groupNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} groupNumber
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.groupNumber = null;
    
                /**
                 * ChangeHealthcareOutputs effectiveDate.
                 * @member {infinitusai.be.IDate|null|undefined} effectiveDate
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.effectiveDate = null;
    
                /**
                 * ChangeHealthcareOutputs termDate.
                 * @member {infinitusai.be.IDate|null|undefined} termDate
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.termDate = null;
    
                /**
                 * ChangeHealthcareOutputs memberId.
                 * @member {string} memberId
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.memberId = "";
    
                /**
                 * ChangeHealthcareOutputs inNetworkBenefits.
                 * @member {infinitusai.be.IChangeHealthcareBenefits|null|undefined} inNetworkBenefits
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.inNetworkBenefits = null;
    
                /**
                 * ChangeHealthcareOutputs outNetworkBenefits.
                 * @member {infinitusai.be.IChangeHealthcareBenefits|null|undefined} outNetworkBenefits
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.outNetworkBenefits = null;
    
                /**
                 * ChangeHealthcareOutputs defaultBenefits.
                 * @member {infinitusai.be.IChangeHealthcareBenefits|null|undefined} defaultBenefits
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.defaultBenefits = null;
    
                /**
                 * ChangeHealthcareOutputs networkStatus.
                 * @member {infinitusai.be.INetworkStatusType|null|undefined} networkStatus
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.networkStatus = null;
    
                /**
                 * ChangeHealthcareOutputs lifetimeMaximumExists.
                 * @member {infinitusai.be.INullableBool|null|undefined} lifetimeMaximumExists
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.lifetimeMaximumExists = null;
    
                /**
                 * ChangeHealthcareOutputs lifetimeMaximum.
                 * @member {infinitusai.be.IMoney|null|undefined} lifetimeMaximum
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.lifetimeMaximum = null;
    
                /**
                 * ChangeHealthcareOutputs lifetimeMaximumRemaining.
                 * @member {infinitusai.be.IMoney|null|undefined} lifetimeMaximumRemaining
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.lifetimeMaximumRemaining = null;
    
                /**
                 * ChangeHealthcareOutputs lifetimeMaximumMet.
                 * @member {infinitusai.be.IMoney|null|undefined} lifetimeMaximumMet
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.lifetimeMaximumMet = null;
    
                /**
                 * ChangeHealthcareOutputs payerName.
                 * @member {string} payerName
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 */
                ChangeHealthcareOutputs.prototype.payerName = "";
    
                /**
                 * Creates a new ChangeHealthcareOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.ChangeHealthcareOutputs} ChangeHealthcareOutputs instance
                 */
                ChangeHealthcareOutputs.create = function create(properties) {
                    return new ChangeHealthcareOutputs(properties);
                };
    
                /**
                 * Encodes the specified ChangeHealthcareOutputs message. Does not implicitly {@link infinitusai.be.ChangeHealthcareOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareOutputs} message ChangeHealthcareOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChangeHealthcareOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payerId != null && Object.hasOwnProperty.call(message, "payerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.payerId);
                    if (message.planActive != null && Object.hasOwnProperty.call(message, "planActive"))
                        $root.infinitusai.be.NullableBool.encode(message.planActive, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.planType != null && Object.hasOwnProperty.call(message, "planType"))
                        $root.infinitusai.be.PlanType.encode(message.planType, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                        $root.infinitusai.be.NullableString.encode(message.planName, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber"))
                        $root.infinitusai.be.NullableString.encode(message.groupNumber, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.effectiveDate != null && Object.hasOwnProperty.call(message, "effectiveDate"))
                        $root.infinitusai.be.Date.encode(message.effectiveDate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.termDate != null && Object.hasOwnProperty.call(message, "termDate"))
                        $root.infinitusai.be.Date.encode(message.termDate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.inNetworkBenefits != null && Object.hasOwnProperty.call(message, "inNetworkBenefits"))
                        $root.infinitusai.be.ChangeHealthcareBenefits.encode(message.inNetworkBenefits, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.outNetworkBenefits != null && Object.hasOwnProperty.call(message, "outNetworkBenefits"))
                        $root.infinitusai.be.ChangeHealthcareBenefits.encode(message.outNetworkBenefits, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.defaultBenefits != null && Object.hasOwnProperty.call(message, "defaultBenefits"))
                        $root.infinitusai.be.ChangeHealthcareBenefits.encode(message.defaultBenefits, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                        $root.infinitusai.be.NetworkStatusType.encode(message.networkStatus, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.controlNumber != null && Object.hasOwnProperty.call(message, "controlNumber"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.controlNumber);
                    if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.memberId);
                    if (message.hasErrors != null && Object.hasOwnProperty.call(message, "hasErrors"))
                        writer.uint32(/* id 14, wireType 0 =*/112).bool(message.hasErrors);
                    if (message.retryAllowed != null && Object.hasOwnProperty.call(message, "retryAllowed"))
                        writer.uint32(/* id 15, wireType 0 =*/120).bool(message.retryAllowed);
                    if (message.lifetimeMaximumExists != null && Object.hasOwnProperty.call(message, "lifetimeMaximumExists"))
                        $root.infinitusai.be.NullableBool.encode(message.lifetimeMaximumExists, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.lifetimeMaximum != null && Object.hasOwnProperty.call(message, "lifetimeMaximum"))
                        $root.infinitusai.be.Money.encode(message.lifetimeMaximum, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.lifetimeMaximumRemaining != null && Object.hasOwnProperty.call(message, "lifetimeMaximumRemaining"))
                        $root.infinitusai.be.Money.encode(message.lifetimeMaximumRemaining, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.lifetimeMaximumMet != null && Object.hasOwnProperty.call(message, "lifetimeMaximumMet"))
                        $root.infinitusai.be.Money.encode(message.lifetimeMaximumMet, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                    if (message.policyType != null && Object.hasOwnProperty.call(message, "policyType"))
                        $root.infinitusai.be.PolicyType.encode(message.policyType, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                    if (message.payerName != null && Object.hasOwnProperty.call(message, "payerName"))
                        writer.uint32(/* id 21, wireType 2 =*/170).string(message.payerName);
                    return writer;
                };
    
                /**
                 * Encodes the specified ChangeHealthcareOutputs message, length delimited. Does not implicitly {@link infinitusai.be.ChangeHealthcareOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareOutputs} message ChangeHealthcareOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChangeHealthcareOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChangeHealthcareOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChangeHealthcareOutputs} ChangeHealthcareOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChangeHealthcareOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChangeHealthcareOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 12: {
                                message.controlNumber = reader.string();
                                break;
                            }
                        case 14: {
                                message.hasErrors = reader.bool();
                                break;
                            }
                        case 15: {
                                message.retryAllowed = reader.bool();
                                break;
                            }
                        case 1: {
                                message.payerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.planActive = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.planType = $root.infinitusai.be.PlanType.decode(reader, reader.uint32());
                                break;
                            }
                        case 20: {
                                message.policyType = $root.infinitusai.be.PolicyType.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.planName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.groupNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.effectiveDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.termDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.memberId = reader.string();
                                break;
                            }
                        case 8: {
                                message.inNetworkBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.outNetworkBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.defaultBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.networkStatus = $root.infinitusai.be.NetworkStatusType.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.lifetimeMaximumExists = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.lifetimeMaximum = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                message.lifetimeMaximumRemaining = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.lifetimeMaximumMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 21: {
                                message.payerName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChangeHealthcareOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChangeHealthcareOutputs} ChangeHealthcareOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChangeHealthcareOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChangeHealthcareOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChangeHealthcareOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.controlNumber != null && message.hasOwnProperty("controlNumber"))
                        if (!$util.isString(message.controlNumber))
                            return "controlNumber: string expected";
                    if (message.hasErrors != null && message.hasOwnProperty("hasErrors"))
                        if (typeof message.hasErrors !== "boolean")
                            return "hasErrors: boolean expected";
                    if (message.retryAllowed != null && message.hasOwnProperty("retryAllowed"))
                        if (typeof message.retryAllowed !== "boolean")
                            return "retryAllowed: boolean expected";
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        if (!$util.isString(message.payerId))
                            return "payerId: string expected";
                    if (message.planActive != null && message.hasOwnProperty("planActive")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.planActive);
                        if (error)
                            return "planActive." + error;
                    }
                    if (message.planType != null && message.hasOwnProperty("planType")) {
                        var error = $root.infinitusai.be.PlanType.verify(message.planType);
                        if (error)
                            return "planType." + error;
                    }
                    if (message.policyType != null && message.hasOwnProperty("policyType")) {
                        var error = $root.infinitusai.be.PolicyType.verify(message.policyType);
                        if (error)
                            return "policyType." + error;
                    }
                    if (message.planName != null && message.hasOwnProperty("planName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.planName);
                        if (error)
                            return "planName." + error;
                    }
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.groupNumber);
                        if (error)
                            return "groupNumber." + error;
                    }
                    if (message.effectiveDate != null && message.hasOwnProperty("effectiveDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.effectiveDate);
                        if (error)
                            return "effectiveDate." + error;
                    }
                    if (message.termDate != null && message.hasOwnProperty("termDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.termDate);
                        if (error)
                            return "termDate." + error;
                    }
                    if (message.memberId != null && message.hasOwnProperty("memberId"))
                        if (!$util.isString(message.memberId))
                            return "memberId: string expected";
                    if (message.inNetworkBenefits != null && message.hasOwnProperty("inNetworkBenefits")) {
                        var error = $root.infinitusai.be.ChangeHealthcareBenefits.verify(message.inNetworkBenefits);
                        if (error)
                            return "inNetworkBenefits." + error;
                    }
                    if (message.outNetworkBenefits != null && message.hasOwnProperty("outNetworkBenefits")) {
                        var error = $root.infinitusai.be.ChangeHealthcareBenefits.verify(message.outNetworkBenefits);
                        if (error)
                            return "outNetworkBenefits." + error;
                    }
                    if (message.defaultBenefits != null && message.hasOwnProperty("defaultBenefits")) {
                        var error = $root.infinitusai.be.ChangeHealthcareBenefits.verify(message.defaultBenefits);
                        if (error)
                            return "defaultBenefits." + error;
                    }
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus")) {
                        var error = $root.infinitusai.be.NetworkStatusType.verify(message.networkStatus);
                        if (error)
                            return "networkStatus." + error;
                    }
                    if (message.lifetimeMaximumExists != null && message.hasOwnProperty("lifetimeMaximumExists")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.lifetimeMaximumExists);
                        if (error)
                            return "lifetimeMaximumExists." + error;
                    }
                    if (message.lifetimeMaximum != null && message.hasOwnProperty("lifetimeMaximum")) {
                        var error = $root.infinitusai.be.Money.verify(message.lifetimeMaximum);
                        if (error)
                            return "lifetimeMaximum." + error;
                    }
                    if (message.lifetimeMaximumRemaining != null && message.hasOwnProperty("lifetimeMaximumRemaining")) {
                        var error = $root.infinitusai.be.Money.verify(message.lifetimeMaximumRemaining);
                        if (error)
                            return "lifetimeMaximumRemaining." + error;
                    }
                    if (message.lifetimeMaximumMet != null && message.hasOwnProperty("lifetimeMaximumMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.lifetimeMaximumMet);
                        if (error)
                            return "lifetimeMaximumMet." + error;
                    }
                    if (message.payerName != null && message.hasOwnProperty("payerName"))
                        if (!$util.isString(message.payerName))
                            return "payerName: string expected";
                    return null;
                };
    
                /**
                 * Creates a ChangeHealthcareOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChangeHealthcareOutputs} ChangeHealthcareOutputs
                 */
                ChangeHealthcareOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChangeHealthcareOutputs)
                        return object;
                    var message = new $root.infinitusai.be.ChangeHealthcareOutputs();
                    if (object.controlNumber != null)
                        message.controlNumber = String(object.controlNumber);
                    if (object.hasErrors != null)
                        message.hasErrors = Boolean(object.hasErrors);
                    if (object.retryAllowed != null)
                        message.retryAllowed = Boolean(object.retryAllowed);
                    if (object.payerId != null)
                        message.payerId = String(object.payerId);
                    if (object.planActive != null) {
                        if (typeof object.planActive !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.planActive: object expected");
                        message.planActive = $root.infinitusai.be.NullableBool.fromObject(object.planActive);
                    }
                    if (object.planType != null) {
                        if (typeof object.planType !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.planType: object expected");
                        message.planType = $root.infinitusai.be.PlanType.fromObject(object.planType);
                    }
                    if (object.policyType != null) {
                        if (typeof object.policyType !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.policyType: object expected");
                        message.policyType = $root.infinitusai.be.PolicyType.fromObject(object.policyType);
                    }
                    if (object.planName != null) {
                        if (typeof object.planName !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.planName: object expected");
                        message.planName = $root.infinitusai.be.NullableString.fromObject(object.planName);
                    }
                    if (object.groupNumber != null) {
                        if (typeof object.groupNumber !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.groupNumber: object expected");
                        message.groupNumber = $root.infinitusai.be.NullableString.fromObject(object.groupNumber);
                    }
                    if (object.effectiveDate != null) {
                        if (typeof object.effectiveDate !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.effectiveDate: object expected");
                        message.effectiveDate = $root.infinitusai.be.Date.fromObject(object.effectiveDate);
                    }
                    if (object.termDate != null) {
                        if (typeof object.termDate !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.termDate: object expected");
                        message.termDate = $root.infinitusai.be.Date.fromObject(object.termDate);
                    }
                    if (object.memberId != null)
                        message.memberId = String(object.memberId);
                    if (object.inNetworkBenefits != null) {
                        if (typeof object.inNetworkBenefits !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.inNetworkBenefits: object expected");
                        message.inNetworkBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.fromObject(object.inNetworkBenefits);
                    }
                    if (object.outNetworkBenefits != null) {
                        if (typeof object.outNetworkBenefits !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.outNetworkBenefits: object expected");
                        message.outNetworkBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.fromObject(object.outNetworkBenefits);
                    }
                    if (object.defaultBenefits != null) {
                        if (typeof object.defaultBenefits !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.defaultBenefits: object expected");
                        message.defaultBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.fromObject(object.defaultBenefits);
                    }
                    if (object.networkStatus != null) {
                        if (typeof object.networkStatus !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.networkStatus: object expected");
                        message.networkStatus = $root.infinitusai.be.NetworkStatusType.fromObject(object.networkStatus);
                    }
                    if (object.lifetimeMaximumExists != null) {
                        if (typeof object.lifetimeMaximumExists !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.lifetimeMaximumExists: object expected");
                        message.lifetimeMaximumExists = $root.infinitusai.be.NullableBool.fromObject(object.lifetimeMaximumExists);
                    }
                    if (object.lifetimeMaximum != null) {
                        if (typeof object.lifetimeMaximum !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.lifetimeMaximum: object expected");
                        message.lifetimeMaximum = $root.infinitusai.be.Money.fromObject(object.lifetimeMaximum);
                    }
                    if (object.lifetimeMaximumRemaining != null) {
                        if (typeof object.lifetimeMaximumRemaining !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.lifetimeMaximumRemaining: object expected");
                        message.lifetimeMaximumRemaining = $root.infinitusai.be.Money.fromObject(object.lifetimeMaximumRemaining);
                    }
                    if (object.lifetimeMaximumMet != null) {
                        if (typeof object.lifetimeMaximumMet !== "object")
                            throw TypeError(".infinitusai.be.ChangeHealthcareOutputs.lifetimeMaximumMet: object expected");
                        message.lifetimeMaximumMet = $root.infinitusai.be.Money.fromObject(object.lifetimeMaximumMet);
                    }
                    if (object.payerName != null)
                        message.payerName = String(object.payerName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChangeHealthcareOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {infinitusai.be.ChangeHealthcareOutputs} message ChangeHealthcareOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChangeHealthcareOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.payerId = "";
                        object.planActive = null;
                        object.planType = null;
                        object.planName = null;
                        object.groupNumber = null;
                        object.effectiveDate = null;
                        object.termDate = null;
                        object.inNetworkBenefits = null;
                        object.outNetworkBenefits = null;
                        object.defaultBenefits = null;
                        object.networkStatus = null;
                        object.controlNumber = "";
                        object.memberId = "";
                        object.hasErrors = false;
                        object.retryAllowed = false;
                        object.lifetimeMaximumExists = null;
                        object.lifetimeMaximum = null;
                        object.lifetimeMaximumRemaining = null;
                        object.lifetimeMaximumMet = null;
                        object.policyType = null;
                        object.payerName = "";
                    }
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        object.payerId = message.payerId;
                    if (message.planActive != null && message.hasOwnProperty("planActive"))
                        object.planActive = $root.infinitusai.be.NullableBool.toObject(message.planActive, options);
                    if (message.planType != null && message.hasOwnProperty("planType"))
                        object.planType = $root.infinitusai.be.PlanType.toObject(message.planType, options);
                    if (message.planName != null && message.hasOwnProperty("planName"))
                        object.planName = $root.infinitusai.be.NullableString.toObject(message.planName, options);
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                        object.groupNumber = $root.infinitusai.be.NullableString.toObject(message.groupNumber, options);
                    if (message.effectiveDate != null && message.hasOwnProperty("effectiveDate"))
                        object.effectiveDate = $root.infinitusai.be.Date.toObject(message.effectiveDate, options);
                    if (message.termDate != null && message.hasOwnProperty("termDate"))
                        object.termDate = $root.infinitusai.be.Date.toObject(message.termDate, options);
                    if (message.inNetworkBenefits != null && message.hasOwnProperty("inNetworkBenefits"))
                        object.inNetworkBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.toObject(message.inNetworkBenefits, options);
                    if (message.outNetworkBenefits != null && message.hasOwnProperty("outNetworkBenefits"))
                        object.outNetworkBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.toObject(message.outNetworkBenefits, options);
                    if (message.defaultBenefits != null && message.hasOwnProperty("defaultBenefits"))
                        object.defaultBenefits = $root.infinitusai.be.ChangeHealthcareBenefits.toObject(message.defaultBenefits, options);
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                        object.networkStatus = $root.infinitusai.be.NetworkStatusType.toObject(message.networkStatus, options);
                    if (message.controlNumber != null && message.hasOwnProperty("controlNumber"))
                        object.controlNumber = message.controlNumber;
                    if (message.memberId != null && message.hasOwnProperty("memberId"))
                        object.memberId = message.memberId;
                    if (message.hasErrors != null && message.hasOwnProperty("hasErrors"))
                        object.hasErrors = message.hasErrors;
                    if (message.retryAllowed != null && message.hasOwnProperty("retryAllowed"))
                        object.retryAllowed = message.retryAllowed;
                    if (message.lifetimeMaximumExists != null && message.hasOwnProperty("lifetimeMaximumExists"))
                        object.lifetimeMaximumExists = $root.infinitusai.be.NullableBool.toObject(message.lifetimeMaximumExists, options);
                    if (message.lifetimeMaximum != null && message.hasOwnProperty("lifetimeMaximum"))
                        object.lifetimeMaximum = $root.infinitusai.be.Money.toObject(message.lifetimeMaximum, options);
                    if (message.lifetimeMaximumRemaining != null && message.hasOwnProperty("lifetimeMaximumRemaining"))
                        object.lifetimeMaximumRemaining = $root.infinitusai.be.Money.toObject(message.lifetimeMaximumRemaining, options);
                    if (message.lifetimeMaximumMet != null && message.hasOwnProperty("lifetimeMaximumMet"))
                        object.lifetimeMaximumMet = $root.infinitusai.be.Money.toObject(message.lifetimeMaximumMet, options);
                    if (message.policyType != null && message.hasOwnProperty("policyType"))
                        object.policyType = $root.infinitusai.be.PolicyType.toObject(message.policyType, options);
                    if (message.payerName != null && message.hasOwnProperty("payerName"))
                        object.payerName = message.payerName;
                    return object;
                };
    
                /**
                 * Converts this ChangeHealthcareOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChangeHealthcareOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChangeHealthcareOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChangeHealthcareOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChangeHealthcareOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChangeHealthcareOutputs";
                };
    
                return ChangeHealthcareOutputs;
            })();
    
            be.ChangeHealthcareMetric = (function() {
    
                /**
                 * Properties of a ChangeHealthcareMetric.
                 * @memberof infinitusai.be
                 * @interface IChangeHealthcareMetric
                 * @property {string|null} [payerId] ChangeHealthcareMetric payerId
                 * @property {string|null} [fieldName] ChangeHealthcareMetric fieldName
                 * @property {boolean|null} [foundFromApi] ChangeHealthcareMetric foundFromApi
                 * @property {boolean|null} [isCorrect] ChangeHealthcareMetric isCorrect
                 */
    
                /**
                 * Constructs a new ChangeHealthcareMetric.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChangeHealthcareMetric.
                 * @implements IChangeHealthcareMetric
                 * @constructor
                 * @param {infinitusai.be.IChangeHealthcareMetric=} [properties] Properties to set
                 */
                function ChangeHealthcareMetric(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChangeHealthcareMetric payerId.
                 * @member {string} payerId
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @instance
                 */
                ChangeHealthcareMetric.prototype.payerId = "";
    
                /**
                 * ChangeHealthcareMetric fieldName.
                 * @member {string} fieldName
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @instance
                 */
                ChangeHealthcareMetric.prototype.fieldName = "";
    
                /**
                 * ChangeHealthcareMetric foundFromApi.
                 * @member {boolean} foundFromApi
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @instance
                 */
                ChangeHealthcareMetric.prototype.foundFromApi = false;
    
                /**
                 * ChangeHealthcareMetric isCorrect.
                 * @member {boolean} isCorrect
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @instance
                 */
                ChangeHealthcareMetric.prototype.isCorrect = false;
    
                /**
                 * Creates a new ChangeHealthcareMetric instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareMetric=} [properties] Properties to set
                 * @returns {infinitusai.be.ChangeHealthcareMetric} ChangeHealthcareMetric instance
                 */
                ChangeHealthcareMetric.create = function create(properties) {
                    return new ChangeHealthcareMetric(properties);
                };
    
                /**
                 * Encodes the specified ChangeHealthcareMetric message. Does not implicitly {@link infinitusai.be.ChangeHealthcareMetric.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareMetric} message ChangeHealthcareMetric message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChangeHealthcareMetric.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payerId != null && Object.hasOwnProperty.call(message, "payerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.payerId);
                    if (message.fieldName != null && Object.hasOwnProperty.call(message, "fieldName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fieldName);
                    if (message.foundFromApi != null && Object.hasOwnProperty.call(message, "foundFromApi"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.foundFromApi);
                    if (message.isCorrect != null && Object.hasOwnProperty.call(message, "isCorrect"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isCorrect);
                    return writer;
                };
    
                /**
                 * Encodes the specified ChangeHealthcareMetric message, length delimited. Does not implicitly {@link infinitusai.be.ChangeHealthcareMetric.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {infinitusai.be.IChangeHealthcareMetric} message ChangeHealthcareMetric message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChangeHealthcareMetric.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChangeHealthcareMetric message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChangeHealthcareMetric} ChangeHealthcareMetric
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChangeHealthcareMetric.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChangeHealthcareMetric();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.payerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.fieldName = reader.string();
                                break;
                            }
                        case 3: {
                                message.foundFromApi = reader.bool();
                                break;
                            }
                        case 4: {
                                message.isCorrect = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChangeHealthcareMetric message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChangeHealthcareMetric} ChangeHealthcareMetric
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChangeHealthcareMetric.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChangeHealthcareMetric message.
                 * @function verify
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChangeHealthcareMetric.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        if (!$util.isString(message.payerId))
                            return "payerId: string expected";
                    if (message.fieldName != null && message.hasOwnProperty("fieldName"))
                        if (!$util.isString(message.fieldName))
                            return "fieldName: string expected";
                    if (message.foundFromApi != null && message.hasOwnProperty("foundFromApi"))
                        if (typeof message.foundFromApi !== "boolean")
                            return "foundFromApi: boolean expected";
                    if (message.isCorrect != null && message.hasOwnProperty("isCorrect"))
                        if (typeof message.isCorrect !== "boolean")
                            return "isCorrect: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a ChangeHealthcareMetric message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChangeHealthcareMetric} ChangeHealthcareMetric
                 */
                ChangeHealthcareMetric.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChangeHealthcareMetric)
                        return object;
                    var message = new $root.infinitusai.be.ChangeHealthcareMetric();
                    if (object.payerId != null)
                        message.payerId = String(object.payerId);
                    if (object.fieldName != null)
                        message.fieldName = String(object.fieldName);
                    if (object.foundFromApi != null)
                        message.foundFromApi = Boolean(object.foundFromApi);
                    if (object.isCorrect != null)
                        message.isCorrect = Boolean(object.isCorrect);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChangeHealthcareMetric message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {infinitusai.be.ChangeHealthcareMetric} message ChangeHealthcareMetric
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChangeHealthcareMetric.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.payerId = "";
                        object.fieldName = "";
                        object.foundFromApi = false;
                        object.isCorrect = false;
                    }
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        object.payerId = message.payerId;
                    if (message.fieldName != null && message.hasOwnProperty("fieldName"))
                        object.fieldName = message.fieldName;
                    if (message.foundFromApi != null && message.hasOwnProperty("foundFromApi"))
                        object.foundFromApi = message.foundFromApi;
                    if (message.isCorrect != null && message.hasOwnProperty("isCorrect"))
                        object.isCorrect = message.isCorrect;
                    return object;
                };
    
                /**
                 * Converts this ChangeHealthcareMetric to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChangeHealthcareMetric.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChangeHealthcareMetric
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChangeHealthcareMetric
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChangeHealthcareMetric.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChangeHealthcareMetric";
                };
    
                return ChangeHealthcareMetric;
            })();
    
            be.SpecialtyPharmacy = (function() {
    
                /**
                 * Properties of a SpecialtyPharmacy.
                 * @memberof infinitusai.be
                 * @interface ISpecialtyPharmacy
                 * @property {string|null} [name] SpecialtyPharmacy name
                 * @property {string|null} [phoneNumber] SpecialtyPharmacy phoneNumber
                 */
    
                /**
                 * Constructs a new SpecialtyPharmacy.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SpecialtyPharmacy.
                 * @implements ISpecialtyPharmacy
                 * @constructor
                 * @param {infinitusai.be.ISpecialtyPharmacy=} [properties] Properties to set
                 */
                function SpecialtyPharmacy(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SpecialtyPharmacy name.
                 * @member {string} name
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @instance
                 */
                SpecialtyPharmacy.prototype.name = "";
    
                /**
                 * SpecialtyPharmacy phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @instance
                 */
                SpecialtyPharmacy.prototype.phoneNumber = "";
    
                /**
                 * Creates a new SpecialtyPharmacy instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {infinitusai.be.ISpecialtyPharmacy=} [properties] Properties to set
                 * @returns {infinitusai.be.SpecialtyPharmacy} SpecialtyPharmacy instance
                 */
                SpecialtyPharmacy.create = function create(properties) {
                    return new SpecialtyPharmacy(properties);
                };
    
                /**
                 * Encodes the specified SpecialtyPharmacy message. Does not implicitly {@link infinitusai.be.SpecialtyPharmacy.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {infinitusai.be.ISpecialtyPharmacy} message SpecialtyPharmacy message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyPharmacy.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.phoneNumber);
                    return writer;
                };
    
                /**
                 * Encodes the specified SpecialtyPharmacy message, length delimited. Does not implicitly {@link infinitusai.be.SpecialtyPharmacy.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {infinitusai.be.ISpecialtyPharmacy} message SpecialtyPharmacy message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyPharmacy.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SpecialtyPharmacy message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SpecialtyPharmacy} SpecialtyPharmacy
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyPharmacy.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SpecialtyPharmacy();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SpecialtyPharmacy message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SpecialtyPharmacy} SpecialtyPharmacy
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyPharmacy.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SpecialtyPharmacy message.
                 * @function verify
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpecialtyPharmacy.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    return null;
                };
    
                /**
                 * Creates a SpecialtyPharmacy message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SpecialtyPharmacy} SpecialtyPharmacy
                 */
                SpecialtyPharmacy.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SpecialtyPharmacy)
                        return object;
                    var message = new $root.infinitusai.be.SpecialtyPharmacy();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SpecialtyPharmacy message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {infinitusai.be.SpecialtyPharmacy} message SpecialtyPharmacy
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpecialtyPharmacy.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.phoneNumber = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    return object;
                };
    
                /**
                 * Converts this SpecialtyPharmacy to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpecialtyPharmacy.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SpecialtyPharmacy
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SpecialtyPharmacy
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpecialtyPharmacy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SpecialtyPharmacy";
                };
    
                return SpecialtyPharmacy;
            })();
    
            be.PolicyType = (function() {
    
                /**
                 * Properties of a PolicyType.
                 * @memberof infinitusai.be
                 * @interface IPolicyType
                 * @property {infinitusai.be.PolicyType.Type|null} [type] PolicyType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] PolicyType metadata
                 */
    
                /**
                 * Constructs a new PolicyType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PolicyType.
                 * @implements IPolicyType
                 * @constructor
                 * @param {infinitusai.be.IPolicyType=} [properties] Properties to set
                 */
                function PolicyType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PolicyType type.
                 * @member {infinitusai.be.PolicyType.Type} type
                 * @memberof infinitusai.be.PolicyType
                 * @instance
                 */
                PolicyType.prototype.type = 0;
    
                /**
                 * PolicyType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.PolicyType
                 * @instance
                 */
                PolicyType.prototype.metadata = null;
    
                /**
                 * Creates a new PolicyType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {infinitusai.be.IPolicyType=} [properties] Properties to set
                 * @returns {infinitusai.be.PolicyType} PolicyType instance
                 */
                PolicyType.create = function create(properties) {
                    return new PolicyType(properties);
                };
    
                /**
                 * Encodes the specified PolicyType message. Does not implicitly {@link infinitusai.be.PolicyType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {infinitusai.be.IPolicyType} message PolicyType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PolicyType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PolicyType message, length delimited. Does not implicitly {@link infinitusai.be.PolicyType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {infinitusai.be.IPolicyType} message PolicyType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PolicyType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PolicyType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PolicyType} PolicyType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PolicyType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PolicyType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PolicyType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PolicyType} PolicyType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PolicyType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PolicyType message.
                 * @function verify
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PolicyType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PolicyType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PolicyType} PolicyType
                 */
                PolicyType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PolicyType)
                        return object;
                    var message = new $root.infinitusai.be.PolicyType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "OTHER":
                    case 1:
                        message.type = 1;
                        break;
                    case "COMMERCIAL":
                    case 2:
                        message.type = 2;
                        break;
                    case "GOVERNMENT_MEDICARE":
                    case 3:
                        message.type = 3;
                        break;
                    case "GOVERNMENT_MEDICAID":
                    case 4:
                        message.type = 4;
                        break;
                    case "GOVERNMENT_TRICARE":
                    case 5:
                        message.type = 5;
                        break;
                    case "FEDERAL_EMPLOYEE_PROGRAM":
                    case 6:
                        message.type = 6;
                        break;
                    case "MANAGED_MEDICAID":
                    case 7:
                        message.type = 7;
                        break;
                    case "MEDICARE_ADVANTAGE":
                    case 8:
                        message.type = 8;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.PolicyType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PolicyType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {infinitusai.be.PolicyType} message PolicyType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PolicyType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.PolicyType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.PolicyType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this PolicyType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PolicyType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PolicyType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PolicyType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PolicyType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PolicyType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PolicyType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.PolicyType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} OTHER=1 OTHER value
                 * @property {number} COMMERCIAL=2 COMMERCIAL value
                 * @property {number} GOVERNMENT_MEDICARE=3 GOVERNMENT_MEDICARE value
                 * @property {number} GOVERNMENT_MEDICAID=4 GOVERNMENT_MEDICAID value
                 * @property {number} GOVERNMENT_TRICARE=5 GOVERNMENT_TRICARE value
                 * @property {number} FEDERAL_EMPLOYEE_PROGRAM=6 FEDERAL_EMPLOYEE_PROGRAM value
                 * @property {number} MANAGED_MEDICAID=7 MANAGED_MEDICAID value
                 * @property {number} MEDICARE_ADVANTAGE=8 MEDICARE_ADVANTAGE value
                 */
                PolicyType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "OTHER"] = 1;
                    values[valuesById[2] = "COMMERCIAL"] = 2;
                    values[valuesById[3] = "GOVERNMENT_MEDICARE"] = 3;
                    values[valuesById[4] = "GOVERNMENT_MEDICAID"] = 4;
                    values[valuesById[5] = "GOVERNMENT_TRICARE"] = 5;
                    values[valuesById[6] = "FEDERAL_EMPLOYEE_PROGRAM"] = 6;
                    values[valuesById[7] = "MANAGED_MEDICAID"] = 7;
                    values[valuesById[8] = "MEDICARE_ADVANTAGE"] = 8;
                    return values;
                })();
    
                return PolicyType;
            })();
    
            be.PlanType = (function() {
    
                /**
                 * Properties of a PlanType.
                 * @memberof infinitusai.be
                 * @interface IPlanType
                 * @property {infinitusai.be.PlanType.Type|null} [type] PlanType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] PlanType metadata
                 */
    
                /**
                 * Constructs a new PlanType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PlanType.
                 * @implements IPlanType
                 * @constructor
                 * @param {infinitusai.be.IPlanType=} [properties] Properties to set
                 */
                function PlanType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PlanType type.
                 * @member {infinitusai.be.PlanType.Type} type
                 * @memberof infinitusai.be.PlanType
                 * @instance
                 */
                PlanType.prototype.type = 0;
    
                /**
                 * PlanType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.PlanType
                 * @instance
                 */
                PlanType.prototype.metadata = null;
    
                /**
                 * Creates a new PlanType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {infinitusai.be.IPlanType=} [properties] Properties to set
                 * @returns {infinitusai.be.PlanType} PlanType instance
                 */
                PlanType.create = function create(properties) {
                    return new PlanType(properties);
                };
    
                /**
                 * Encodes the specified PlanType message. Does not implicitly {@link infinitusai.be.PlanType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {infinitusai.be.IPlanType} message PlanType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PlanType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PlanType message, length delimited. Does not implicitly {@link infinitusai.be.PlanType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {infinitusai.be.IPlanType} message PlanType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PlanType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PlanType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PlanType} PlanType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PlanType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PlanType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PlanType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PlanType} PlanType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PlanType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PlanType message.
                 * @function verify
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PlanType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PlanType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PlanType} PlanType
                 */
                PlanType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PlanType)
                        return object;
                    var message = new $root.infinitusai.be.PlanType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "PPO":
                    case 1:
                        message.type = 1;
                        break;
                    case "HMO":
                    case 2:
                        message.type = 2;
                        break;
                    case "POS":
                    case 3:
                        message.type = 3;
                        break;
                    case "EPO":
                    case 4:
                        message.type = 4;
                        break;
                    case "INDEMNITY":
                    case 5:
                        message.type = 5;
                        break;
                    case "TRICARE":
                    case 6:
                        message.type = 6;
                        break;
                    case "MEDICAID":
                    case 7:
                        message.type = 7;
                        break;
                    case "OAP":
                    case 8:
                        message.type = 8;
                        break;
                    case "MEDICARE_SUPPLEMENT":
                    case 9:
                        message.type = 9;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.PlanType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PlanType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {infinitusai.be.PlanType} message PlanType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PlanType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.PlanType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.PlanType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this PlanType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PlanType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PlanType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PlanType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PlanType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PlanType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PlanType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.PlanType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} PPO=1 PPO value
                 * @property {number} HMO=2 HMO value
                 * @property {number} POS=3 POS value
                 * @property {number} EPO=4 EPO value
                 * @property {number} INDEMNITY=5 INDEMNITY value
                 * @property {number} TRICARE=6 TRICARE value
                 * @property {number} MEDICAID=7 MEDICAID value
                 * @property {number} OAP=8 OAP value
                 * @property {number} MEDICARE_SUPPLEMENT=9 MEDICARE_SUPPLEMENT value
                 */
                PlanType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "PPO"] = 1;
                    values[valuesById[2] = "HMO"] = 2;
                    values[valuesById[3] = "POS"] = 3;
                    values[valuesById[4] = "EPO"] = 4;
                    values[valuesById[5] = "INDEMNITY"] = 5;
                    values[valuesById[6] = "TRICARE"] = 6;
                    values[valuesById[7] = "MEDICAID"] = 7;
                    values[valuesById[8] = "OAP"] = 8;
                    values[valuesById[9] = "MEDICARE_SUPPLEMENT"] = 9;
                    return values;
                })();
    
                return PlanType;
            })();
    
            be.BuyAndBillType = (function() {
    
                /**
                 * Properties of a BuyAndBillType.
                 * @memberof infinitusai.be
                 * @interface IBuyAndBillType
                 * @property {infinitusai.be.BuyAndBillType.Type|null} [type] BuyAndBillType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] BuyAndBillType metadata
                 */
    
                /**
                 * Constructs a new BuyAndBillType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BuyAndBillType.
                 * @implements IBuyAndBillType
                 * @constructor
                 * @param {infinitusai.be.IBuyAndBillType=} [properties] Properties to set
                 */
                function BuyAndBillType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BuyAndBillType type.
                 * @member {infinitusai.be.BuyAndBillType.Type} type
                 * @memberof infinitusai.be.BuyAndBillType
                 * @instance
                 */
                BuyAndBillType.prototype.type = 0;
    
                /**
                 * BuyAndBillType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.BuyAndBillType
                 * @instance
                 */
                BuyAndBillType.prototype.metadata = null;
    
                /**
                 * Creates a new BuyAndBillType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {infinitusai.be.IBuyAndBillType=} [properties] Properties to set
                 * @returns {infinitusai.be.BuyAndBillType} BuyAndBillType instance
                 */
                BuyAndBillType.create = function create(properties) {
                    return new BuyAndBillType(properties);
                };
    
                /**
                 * Encodes the specified BuyAndBillType message. Does not implicitly {@link infinitusai.be.BuyAndBillType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {infinitusai.be.IBuyAndBillType} message BuyAndBillType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BuyAndBillType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified BuyAndBillType message, length delimited. Does not implicitly {@link infinitusai.be.BuyAndBillType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {infinitusai.be.IBuyAndBillType} message BuyAndBillType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BuyAndBillType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BuyAndBillType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BuyAndBillType} BuyAndBillType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BuyAndBillType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BuyAndBillType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BuyAndBillType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BuyAndBillType} BuyAndBillType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BuyAndBillType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BuyAndBillType message.
                 * @function verify
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BuyAndBillType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a BuyAndBillType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BuyAndBillType} BuyAndBillType
                 */
                BuyAndBillType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BuyAndBillType)
                        return object;
                    var message = new $root.infinitusai.be.BuyAndBillType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "ALLOWED":
                    case 1:
                        message.type = 1;
                        break;
                    case "NOT_ALLOWED":
                    case 2:
                        message.type = 2;
                        break;
                    case "REQUIRED":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.BuyAndBillType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a BuyAndBillType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {infinitusai.be.BuyAndBillType} message BuyAndBillType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BuyAndBillType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.BuyAndBillType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.BuyAndBillType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this BuyAndBillType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BuyAndBillType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BuyAndBillType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BuyAndBillType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BuyAndBillType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BuyAndBillType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BuyAndBillType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.BuyAndBillType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} ALLOWED=1 ALLOWED value
                 * @property {number} NOT_ALLOWED=2 NOT_ALLOWED value
                 * @property {number} REQUIRED=3 REQUIRED value
                 */
                BuyAndBillType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "ALLOWED"] = 1;
                    values[valuesById[2] = "NOT_ALLOWED"] = 2;
                    values[valuesById[3] = "REQUIRED"] = 3;
                    return values;
                })();
    
                return BuyAndBillType;
            })();
    
            be.PriorAuthAndPredeterminationMethod = (function() {
    
                /**
                 * Properties of a PriorAuthAndPredeterminationMethod.
                 * @memberof infinitusai.be
                 * @interface IPriorAuthAndPredeterminationMethod
                 * @property {infinitusai.be.PriorAuthAndPredeterminationMethod.Type|null} [type] PriorAuthAndPredeterminationMethod type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] PriorAuthAndPredeterminationMethod metadata
                 */
    
                /**
                 * Constructs a new PriorAuthAndPredeterminationMethod.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PriorAuthAndPredeterminationMethod.
                 * @implements IPriorAuthAndPredeterminationMethod
                 * @constructor
                 * @param {infinitusai.be.IPriorAuthAndPredeterminationMethod=} [properties] Properties to set
                 */
                function PriorAuthAndPredeterminationMethod(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PriorAuthAndPredeterminationMethod type.
                 * @member {infinitusai.be.PriorAuthAndPredeterminationMethod.Type} type
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @instance
                 */
                PriorAuthAndPredeterminationMethod.prototype.type = 0;
    
                /**
                 * PriorAuthAndPredeterminationMethod metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @instance
                 */
                PriorAuthAndPredeterminationMethod.prototype.metadata = null;
    
                /**
                 * Creates a new PriorAuthAndPredeterminationMethod instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {infinitusai.be.IPriorAuthAndPredeterminationMethod=} [properties] Properties to set
                 * @returns {infinitusai.be.PriorAuthAndPredeterminationMethod} PriorAuthAndPredeterminationMethod instance
                 */
                PriorAuthAndPredeterminationMethod.create = function create(properties) {
                    return new PriorAuthAndPredeterminationMethod(properties);
                };
    
                /**
                 * Encodes the specified PriorAuthAndPredeterminationMethod message. Does not implicitly {@link infinitusai.be.PriorAuthAndPredeterminationMethod.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {infinitusai.be.IPriorAuthAndPredeterminationMethod} message PriorAuthAndPredeterminationMethod message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriorAuthAndPredeterminationMethod.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PriorAuthAndPredeterminationMethod message, length delimited. Does not implicitly {@link infinitusai.be.PriorAuthAndPredeterminationMethod.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {infinitusai.be.IPriorAuthAndPredeterminationMethod} message PriorAuthAndPredeterminationMethod message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriorAuthAndPredeterminationMethod.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PriorAuthAndPredeterminationMethod message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PriorAuthAndPredeterminationMethod} PriorAuthAndPredeterminationMethod
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriorAuthAndPredeterminationMethod.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PriorAuthAndPredeterminationMethod();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PriorAuthAndPredeterminationMethod message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PriorAuthAndPredeterminationMethod} PriorAuthAndPredeterminationMethod
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriorAuthAndPredeterminationMethod.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PriorAuthAndPredeterminationMethod message.
                 * @function verify
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PriorAuthAndPredeterminationMethod.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PriorAuthAndPredeterminationMethod message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PriorAuthAndPredeterminationMethod} PriorAuthAndPredeterminationMethod
                 */
                PriorAuthAndPredeterminationMethod.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PriorAuthAndPredeterminationMethod)
                        return object;
                    var message = new $root.infinitusai.be.PriorAuthAndPredeterminationMethod();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "CALL":
                    case 1:
                        message.type = 1;
                        break;
                    case "FAX":
                    case 2:
                        message.type = 2;
                        break;
                    case "WEBSITE":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.PriorAuthAndPredeterminationMethod.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PriorAuthAndPredeterminationMethod message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {infinitusai.be.PriorAuthAndPredeterminationMethod} message PriorAuthAndPredeterminationMethod
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriorAuthAndPredeterminationMethod.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.PriorAuthAndPredeterminationMethod.Type[message.type] === undefined ? message.type : $root.infinitusai.be.PriorAuthAndPredeterminationMethod.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this PriorAuthAndPredeterminationMethod to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriorAuthAndPredeterminationMethod.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PriorAuthAndPredeterminationMethod
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PriorAuthAndPredeterminationMethod
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PriorAuthAndPredeterminationMethod.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PriorAuthAndPredeterminationMethod";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.PriorAuthAndPredeterminationMethod.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} CALL=1 CALL value
                 * @property {number} FAX=2 FAX value
                 * @property {number} WEBSITE=3 WEBSITE value
                 */
                PriorAuthAndPredeterminationMethod.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "CALL"] = 1;
                    values[valuesById[2] = "FAX"] = 2;
                    values[valuesById[3] = "WEBSITE"] = 3;
                    return values;
                })();
    
                return PriorAuthAndPredeterminationMethod;
            })();
    
            be.StepTherapyMethod = (function() {
    
                /**
                 * Properties of a StepTherapyMethod.
                 * @memberof infinitusai.be
                 * @interface IStepTherapyMethod
                 * @property {infinitusai.be.StepTherapyMethod.Type|null} [type] StepTherapyMethod type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] StepTherapyMethod metadata
                 */
    
                /**
                 * Constructs a new StepTherapyMethod.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StepTherapyMethod.
                 * @implements IStepTherapyMethod
                 * @constructor
                 * @param {infinitusai.be.IStepTherapyMethod=} [properties] Properties to set
                 */
                function StepTherapyMethod(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StepTherapyMethod type.
                 * @member {infinitusai.be.StepTherapyMethod.Type} type
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @instance
                 */
                StepTherapyMethod.prototype.type = 0;
    
                /**
                 * StepTherapyMethod metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @instance
                 */
                StepTherapyMethod.prototype.metadata = null;
    
                /**
                 * Creates a new StepTherapyMethod instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {infinitusai.be.IStepTherapyMethod=} [properties] Properties to set
                 * @returns {infinitusai.be.StepTherapyMethod} StepTherapyMethod instance
                 */
                StepTherapyMethod.create = function create(properties) {
                    return new StepTherapyMethod(properties);
                };
    
                /**
                 * Encodes the specified StepTherapyMethod message. Does not implicitly {@link infinitusai.be.StepTherapyMethod.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {infinitusai.be.IStepTherapyMethod} message StepTherapyMethod message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StepTherapyMethod.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StepTherapyMethod message, length delimited. Does not implicitly {@link infinitusai.be.StepTherapyMethod.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {infinitusai.be.IStepTherapyMethod} message StepTherapyMethod message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StepTherapyMethod.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StepTherapyMethod message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StepTherapyMethod} StepTherapyMethod
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StepTherapyMethod.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StepTherapyMethod();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StepTherapyMethod message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StepTherapyMethod} StepTherapyMethod
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StepTherapyMethod.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StepTherapyMethod message.
                 * @function verify
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StepTherapyMethod.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a StepTherapyMethod message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StepTherapyMethod} StepTherapyMethod
                 */
                StepTherapyMethod.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StepTherapyMethod)
                        return object;
                    var message = new $root.infinitusai.be.StepTherapyMethod();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "PREFERRED_TREATMENT":
                    case 1:
                        message.type = 1;
                        break;
                    case "SUBMIT_CLINICAL_NOTES":
                    case 2:
                        message.type = 2;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.StepTherapyMethod.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StepTherapyMethod message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {infinitusai.be.StepTherapyMethod} message StepTherapyMethod
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StepTherapyMethod.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.StepTherapyMethod.Type[message.type] === undefined ? message.type : $root.infinitusai.be.StepTherapyMethod.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this StepTherapyMethod to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StepTherapyMethod.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StepTherapyMethod
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StepTherapyMethod
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StepTherapyMethod.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StepTherapyMethod";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.StepTherapyMethod.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} PREFERRED_TREATMENT=1 PREFERRED_TREATMENT value
                 * @property {number} SUBMIT_CLINICAL_NOTES=2 SUBMIT_CLINICAL_NOTES value
                 */
                StepTherapyMethod.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "PREFERRED_TREATMENT"] = 1;
                    values[valuesById[2] = "SUBMIT_CLINICAL_NOTES"] = 2;
                    return values;
                })();
    
                return StepTherapyMethod;
            })();
    
            be.PlanRenewType = (function() {
    
                /**
                 * Properties of a PlanRenewType.
                 * @memberof infinitusai.be
                 * @interface IPlanRenewType
                 * @property {infinitusai.be.PlanRenewType.Type|null} [type] PlanRenewType type
                 */
    
                /**
                 * Constructs a new PlanRenewType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PlanRenewType.
                 * @implements IPlanRenewType
                 * @constructor
                 * @param {infinitusai.be.IPlanRenewType=} [properties] Properties to set
                 */
                function PlanRenewType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PlanRenewType type.
                 * @member {infinitusai.be.PlanRenewType.Type} type
                 * @memberof infinitusai.be.PlanRenewType
                 * @instance
                 */
                PlanRenewType.prototype.type = 0;
    
                /**
                 * Creates a new PlanRenewType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {infinitusai.be.IPlanRenewType=} [properties] Properties to set
                 * @returns {infinitusai.be.PlanRenewType} PlanRenewType instance
                 */
                PlanRenewType.create = function create(properties) {
                    return new PlanRenewType(properties);
                };
    
                /**
                 * Encodes the specified PlanRenewType message. Does not implicitly {@link infinitusai.be.PlanRenewType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {infinitusai.be.IPlanRenewType} message PlanRenewType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PlanRenewType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    return writer;
                };
    
                /**
                 * Encodes the specified PlanRenewType message, length delimited. Does not implicitly {@link infinitusai.be.PlanRenewType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {infinitusai.be.IPlanRenewType} message PlanRenewType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PlanRenewType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PlanRenewType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PlanRenewType} PlanRenewType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PlanRenewType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PlanRenewType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PlanRenewType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PlanRenewType} PlanRenewType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PlanRenewType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PlanRenewType message.
                 * @function verify
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PlanRenewType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a PlanRenewType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PlanRenewType} PlanRenewType
                 */
                PlanRenewType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PlanRenewType)
                        return object;
                    var message = new $root.infinitusai.be.PlanRenewType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "CALENDAR_YEAR":
                    case 1:
                        message.type = 1;
                        break;
                    case "FISCAL_YEAR":
                    case 2:
                        message.type = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PlanRenewType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {infinitusai.be.PlanRenewType} message PlanRenewType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PlanRenewType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.PlanRenewType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.PlanRenewType.Type[message.type] : message.type;
                    return object;
                };
    
                /**
                 * Converts this PlanRenewType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PlanRenewType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PlanRenewType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PlanRenewType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PlanRenewType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PlanRenewType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PlanRenewType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.PlanRenewType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} CALENDAR_YEAR=1 CALENDAR_YEAR value
                 * @property {number} FISCAL_YEAR=2 FISCAL_YEAR value
                 */
                PlanRenewType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "CALENDAR_YEAR"] = 1;
                    values[valuesById[2] = "FISCAL_YEAR"] = 2;
                    return values;
                })();
    
                return PlanRenewType;
            })();
    
            be.BenefitPlanRenewalType = (function() {
    
                /**
                 * Properties of a BenefitPlanRenewalType.
                 * @memberof infinitusai.be
                 * @interface IBenefitPlanRenewalType
                 * @property {infinitusai.be.BenefitPlanRenewalType.Type|null} [type] BenefitPlanRenewalType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] BenefitPlanRenewalType metadata
                 */
    
                /**
                 * Constructs a new BenefitPlanRenewalType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BenefitPlanRenewalType.
                 * @implements IBenefitPlanRenewalType
                 * @constructor
                 * @param {infinitusai.be.IBenefitPlanRenewalType=} [properties] Properties to set
                 */
                function BenefitPlanRenewalType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BenefitPlanRenewalType type.
                 * @member {infinitusai.be.BenefitPlanRenewalType.Type} type
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @instance
                 */
                BenefitPlanRenewalType.prototype.type = 0;
    
                /**
                 * BenefitPlanRenewalType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @instance
                 */
                BenefitPlanRenewalType.prototype.metadata = null;
    
                /**
                 * Creates a new BenefitPlanRenewalType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {infinitusai.be.IBenefitPlanRenewalType=} [properties] Properties to set
                 * @returns {infinitusai.be.BenefitPlanRenewalType} BenefitPlanRenewalType instance
                 */
                BenefitPlanRenewalType.create = function create(properties) {
                    return new BenefitPlanRenewalType(properties);
                };
    
                /**
                 * Encodes the specified BenefitPlanRenewalType message. Does not implicitly {@link infinitusai.be.BenefitPlanRenewalType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {infinitusai.be.IBenefitPlanRenewalType} message BenefitPlanRenewalType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BenefitPlanRenewalType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified BenefitPlanRenewalType message, length delimited. Does not implicitly {@link infinitusai.be.BenefitPlanRenewalType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {infinitusai.be.IBenefitPlanRenewalType} message BenefitPlanRenewalType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BenefitPlanRenewalType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BenefitPlanRenewalType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BenefitPlanRenewalType} BenefitPlanRenewalType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BenefitPlanRenewalType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BenefitPlanRenewalType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BenefitPlanRenewalType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BenefitPlanRenewalType} BenefitPlanRenewalType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BenefitPlanRenewalType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BenefitPlanRenewalType message.
                 * @function verify
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BenefitPlanRenewalType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a BenefitPlanRenewalType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BenefitPlanRenewalType} BenefitPlanRenewalType
                 */
                BenefitPlanRenewalType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BenefitPlanRenewalType)
                        return object;
                    var message = new $root.infinitusai.be.BenefitPlanRenewalType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "CALENDAR_YEARLY":
                    case 1:
                        message.type = 1;
                        break;
                    case "CALENDAR_QUARTERLY":
                    case 2:
                        message.type = 2;
                        break;
                    case "CALENDAR_MONTHLY":
                    case 3:
                        message.type = 3;
                        break;
                    case "BENEFIT_YEARLY":
                    case 4:
                        message.type = 4;
                        break;
                    case "BENEFIT_QUARTERLY":
                    case 5:
                        message.type = 5;
                        break;
                    case "BENEFIT_MONTHLY":
                    case 6:
                        message.type = 6;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.BenefitPlanRenewalType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a BenefitPlanRenewalType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {infinitusai.be.BenefitPlanRenewalType} message BenefitPlanRenewalType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BenefitPlanRenewalType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.BenefitPlanRenewalType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.BenefitPlanRenewalType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this BenefitPlanRenewalType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BenefitPlanRenewalType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BenefitPlanRenewalType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BenefitPlanRenewalType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BenefitPlanRenewalType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BenefitPlanRenewalType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.BenefitPlanRenewalType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} CALENDAR_YEARLY=1 CALENDAR_YEARLY value
                 * @property {number} CALENDAR_QUARTERLY=2 CALENDAR_QUARTERLY value
                 * @property {number} CALENDAR_MONTHLY=3 CALENDAR_MONTHLY value
                 * @property {number} BENEFIT_YEARLY=4 BENEFIT_YEARLY value
                 * @property {number} BENEFIT_QUARTERLY=5 BENEFIT_QUARTERLY value
                 * @property {number} BENEFIT_MONTHLY=6 BENEFIT_MONTHLY value
                 */
                BenefitPlanRenewalType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "CALENDAR_YEARLY"] = 1;
                    values[valuesById[2] = "CALENDAR_QUARTERLY"] = 2;
                    values[valuesById[3] = "CALENDAR_MONTHLY"] = 3;
                    values[valuesById[4] = "BENEFIT_YEARLY"] = 4;
                    values[valuesById[5] = "BENEFIT_QUARTERLY"] = 5;
                    values[valuesById[6] = "BENEFIT_MONTHLY"] = 6;
                    return values;
                })();
    
                return BenefitPlanRenewalType;
            })();
    
            be.MonthType = (function() {
    
                /**
                 * Properties of a MonthType.
                 * @memberof infinitusai.be
                 * @interface IMonthType
                 * @property {infinitusai.be.MonthType.Type|null} [type] MonthType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] MonthType metadata
                 */
    
                /**
                 * Constructs a new MonthType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MonthType.
                 * @implements IMonthType
                 * @constructor
                 * @param {infinitusai.be.IMonthType=} [properties] Properties to set
                 */
                function MonthType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MonthType type.
                 * @member {infinitusai.be.MonthType.Type} type
                 * @memberof infinitusai.be.MonthType
                 * @instance
                 */
                MonthType.prototype.type = 0;
    
                /**
                 * MonthType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.MonthType
                 * @instance
                 */
                MonthType.prototype.metadata = null;
    
                /**
                 * Creates a new MonthType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {infinitusai.be.IMonthType=} [properties] Properties to set
                 * @returns {infinitusai.be.MonthType} MonthType instance
                 */
                MonthType.create = function create(properties) {
                    return new MonthType(properties);
                };
    
                /**
                 * Encodes the specified MonthType message. Does not implicitly {@link infinitusai.be.MonthType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {infinitusai.be.IMonthType} message MonthType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MonthType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MonthType message, length delimited. Does not implicitly {@link infinitusai.be.MonthType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {infinitusai.be.IMonthType} message MonthType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MonthType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MonthType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MonthType} MonthType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MonthType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MonthType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MonthType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MonthType} MonthType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MonthType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MonthType message.
                 * @function verify
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MonthType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a MonthType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MonthType} MonthType
                 */
                MonthType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MonthType)
                        return object;
                    var message = new $root.infinitusai.be.MonthType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "JAN":
                    case 1:
                        message.type = 1;
                        break;
                    case "FEB":
                    case 2:
                        message.type = 2;
                        break;
                    case "MAR":
                    case 3:
                        message.type = 3;
                        break;
                    case "APR":
                    case 4:
                        message.type = 4;
                        break;
                    case "MAY":
                    case 5:
                        message.type = 5;
                        break;
                    case "JUN":
                    case 6:
                        message.type = 6;
                        break;
                    case "JUL":
                    case 7:
                        message.type = 7;
                        break;
                    case "AUG":
                    case 8:
                        message.type = 8;
                        break;
                    case "SEP":
                    case 9:
                        message.type = 9;
                        break;
                    case "OCT":
                    case 10:
                        message.type = 10;
                        break;
                    case "NOV":
                    case 11:
                        message.type = 11;
                        break;
                    case "DEC":
                    case 12:
                        message.type = 12;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.MonthType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MonthType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {infinitusai.be.MonthType} message MonthType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MonthType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.MonthType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.MonthType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this MonthType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MonthType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MonthType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MonthType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MonthType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MonthType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MonthType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.MonthType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} JAN=1 JAN value
                 * @property {number} FEB=2 FEB value
                 * @property {number} MAR=3 MAR value
                 * @property {number} APR=4 APR value
                 * @property {number} MAY=5 MAY value
                 * @property {number} JUN=6 JUN value
                 * @property {number} JUL=7 JUL value
                 * @property {number} AUG=8 AUG value
                 * @property {number} SEP=9 SEP value
                 * @property {number} OCT=10 OCT value
                 * @property {number} NOV=11 NOV value
                 * @property {number} DEC=12 DEC value
                 */
                MonthType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "JAN"] = 1;
                    values[valuesById[2] = "FEB"] = 2;
                    values[valuesById[3] = "MAR"] = 3;
                    values[valuesById[4] = "APR"] = 4;
                    values[valuesById[5] = "MAY"] = 5;
                    values[valuesById[6] = "JUN"] = 6;
                    values[valuesById[7] = "JUL"] = 7;
                    values[valuesById[8] = "AUG"] = 8;
                    values[valuesById[9] = "SEP"] = 9;
                    values[valuesById[10] = "OCT"] = 10;
                    values[valuesById[11] = "NOV"] = 11;
                    values[valuesById[12] = "DEC"] = 12;
                    return values;
                })();
    
                return MonthType;
            })();
    
            be.AdminCostType = (function() {
    
                /**
                 * Properties of an AdminCostType.
                 * @memberof infinitusai.be
                 * @interface IAdminCostType
                 * @property {infinitusai.be.AdminCostType.Type|null} [type] AdminCostType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] AdminCostType metadata
                 */
    
                /**
                 * Constructs a new AdminCostType.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AdminCostType.
                 * @implements IAdminCostType
                 * @constructor
                 * @param {infinitusai.be.IAdminCostType=} [properties] Properties to set
                 */
                function AdminCostType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AdminCostType type.
                 * @member {infinitusai.be.AdminCostType.Type} type
                 * @memberof infinitusai.be.AdminCostType
                 * @instance
                 */
                AdminCostType.prototype.type = 0;
    
                /**
                 * AdminCostType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.AdminCostType
                 * @instance
                 */
                AdminCostType.prototype.metadata = null;
    
                /**
                 * Creates a new AdminCostType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {infinitusai.be.IAdminCostType=} [properties] Properties to set
                 * @returns {infinitusai.be.AdminCostType} AdminCostType instance
                 */
                AdminCostType.create = function create(properties) {
                    return new AdminCostType(properties);
                };
    
                /**
                 * Encodes the specified AdminCostType message. Does not implicitly {@link infinitusai.be.AdminCostType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {infinitusai.be.IAdminCostType} message AdminCostType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdminCostType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AdminCostType message, length delimited. Does not implicitly {@link infinitusai.be.AdminCostType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {infinitusai.be.IAdminCostType} message AdminCostType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdminCostType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AdminCostType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AdminCostType} AdminCostType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdminCostType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AdminCostType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AdminCostType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AdminCostType} AdminCostType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdminCostType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AdminCostType message.
                 * @function verify
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AdminCostType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an AdminCostType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AdminCostType} AdminCostType
                 */
                AdminCostType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AdminCostType)
                        return object;
                    var message = new $root.infinitusai.be.AdminCostType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "WITH_JCODE":
                    case 1:
                        message.type = 1;
                        break;
                    case "WITH_SPECIALIST":
                    case 2:
                        message.type = 2;
                        break;
                    case "SEPARATE":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.AdminCostType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AdminCostType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {infinitusai.be.AdminCostType} message AdminCostType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AdminCostType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.AdminCostType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.AdminCostType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this AdminCostType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AdminCostType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AdminCostType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AdminCostType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AdminCostType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AdminCostType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AdminCostType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.AdminCostType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} WITH_JCODE=1 WITH_JCODE value
                 * @property {number} WITH_SPECIALIST=2 WITH_SPECIALIST value
                 * @property {number} SEPARATE=3 SEPARATE value
                 */
                AdminCostType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "WITH_JCODE"] = 1;
                    values[valuesById[2] = "WITH_SPECIALIST"] = 2;
                    values[valuesById[3] = "SEPARATE"] = 3;
                    return values;
                })();
    
                return AdminCostType;
            })();
    
            be.MultiselectCodesType = (function() {
    
                /**
                 * Properties of a MultiselectCodesType.
                 * @memberof infinitusai.be
                 * @interface IMultiselectCodesType
                 * @property {Array.<infinitusai.be.MultiselectCodesType.Type>|null} [types] MultiselectCodesType types
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] MultiselectCodesType metadata
                 */
    
                /**
                 * Constructs a new MultiselectCodesType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MultiselectCodesType.
                 * @implements IMultiselectCodesType
                 * @constructor
                 * @param {infinitusai.be.IMultiselectCodesType=} [properties] Properties to set
                 */
                function MultiselectCodesType(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MultiselectCodesType types.
                 * @member {Array.<infinitusai.be.MultiselectCodesType.Type>} types
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @instance
                 */
                MultiselectCodesType.prototype.types = $util.emptyArray;
    
                /**
                 * MultiselectCodesType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @instance
                 */
                MultiselectCodesType.prototype.metadata = null;
    
                /**
                 * Creates a new MultiselectCodesType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {infinitusai.be.IMultiselectCodesType=} [properties] Properties to set
                 * @returns {infinitusai.be.MultiselectCodesType} MultiselectCodesType instance
                 */
                MultiselectCodesType.create = function create(properties) {
                    return new MultiselectCodesType(properties);
                };
    
                /**
                 * Encodes the specified MultiselectCodesType message. Does not implicitly {@link infinitusai.be.MultiselectCodesType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {infinitusai.be.IMultiselectCodesType} message MultiselectCodesType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiselectCodesType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MultiselectCodesType message, length delimited. Does not implicitly {@link infinitusai.be.MultiselectCodesType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {infinitusai.be.IMultiselectCodesType} message MultiselectCodesType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiselectCodesType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MultiselectCodesType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MultiselectCodesType} MultiselectCodesType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiselectCodesType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MultiselectCodesType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MultiselectCodesType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MultiselectCodesType} MultiselectCodesType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiselectCodesType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MultiselectCodesType message.
                 * @function verify
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MultiselectCodesType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                    }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a MultiselectCodesType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MultiselectCodesType} MultiselectCodesType
                 */
                MultiselectCodesType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MultiselectCodesType)
                        return object;
                    var message = new $root.infinitusai.be.MultiselectCodesType();
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.be.MultiselectCodesType.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "J_CODE":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "ADMIN_CODE":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "DIAGNOSIS_CODE_PRIMARY":
                            case 3:
                                message.types[i] = 3;
                                break;
                            case "DIAGNOSIS_CODE_ALTERNATE":
                            case 4:
                                message.types[i] = 4;
                                break;
                            }
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.MultiselectCodesType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MultiselectCodesType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {infinitusai.be.MultiselectCodesType} message MultiselectCodesType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MultiselectCodesType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (options.defaults)
                        object.metadata = null;
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.be.MultiselectCodesType.Type[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.be.MultiselectCodesType.Type[message.types[j]] : message.types[j];
                    }
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this MultiselectCodesType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MultiselectCodesType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MultiselectCodesType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MultiselectCodesType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MultiselectCodesType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MultiselectCodesType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.MultiselectCodesType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} J_CODE=1 J_CODE value
                 * @property {number} ADMIN_CODE=2 ADMIN_CODE value
                 * @property {number} DIAGNOSIS_CODE_PRIMARY=3 DIAGNOSIS_CODE_PRIMARY value
                 * @property {number} DIAGNOSIS_CODE_ALTERNATE=4 DIAGNOSIS_CODE_ALTERNATE value
                 */
                MultiselectCodesType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "J_CODE"] = 1;
                    values[valuesById[2] = "ADMIN_CODE"] = 2;
                    values[valuesById[3] = "DIAGNOSIS_CODE_PRIMARY"] = 3;
                    values[valuesById[4] = "DIAGNOSIS_CODE_ALTERNATE"] = 4;
                    return values;
                })();
    
                return MultiselectCodesType;
            })();
    
            be.NetworkStatusType = (function() {
    
                /**
                 * Properties of a NetworkStatusType.
                 * @memberof infinitusai.be
                 * @interface INetworkStatusType
                 * @property {infinitusai.be.NetworkStatusType.Type|null} [type] NetworkStatusType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] NetworkStatusType metadata
                 */
    
                /**
                 * Constructs a new NetworkStatusType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NetworkStatusType.
                 * @implements INetworkStatusType
                 * @constructor
                 * @param {infinitusai.be.INetworkStatusType=} [properties] Properties to set
                 */
                function NetworkStatusType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NetworkStatusType type.
                 * @member {infinitusai.be.NetworkStatusType.Type} type
                 * @memberof infinitusai.be.NetworkStatusType
                 * @instance
                 */
                NetworkStatusType.prototype.type = 0;
    
                /**
                 * NetworkStatusType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.NetworkStatusType
                 * @instance
                 */
                NetworkStatusType.prototype.metadata = null;
    
                /**
                 * Creates a new NetworkStatusType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {infinitusai.be.INetworkStatusType=} [properties] Properties to set
                 * @returns {infinitusai.be.NetworkStatusType} NetworkStatusType instance
                 */
                NetworkStatusType.create = function create(properties) {
                    return new NetworkStatusType(properties);
                };
    
                /**
                 * Encodes the specified NetworkStatusType message. Does not implicitly {@link infinitusai.be.NetworkStatusType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {infinitusai.be.INetworkStatusType} message NetworkStatusType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NetworkStatusType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified NetworkStatusType message, length delimited. Does not implicitly {@link infinitusai.be.NetworkStatusType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {infinitusai.be.INetworkStatusType} message NetworkStatusType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NetworkStatusType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NetworkStatusType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NetworkStatusType} NetworkStatusType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NetworkStatusType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NetworkStatusType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NetworkStatusType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NetworkStatusType} NetworkStatusType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NetworkStatusType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NetworkStatusType message.
                 * @function verify
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NetworkStatusType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a NetworkStatusType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NetworkStatusType} NetworkStatusType
                 */
                NetworkStatusType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NetworkStatusType)
                        return object;
                    var message = new $root.infinitusai.be.NetworkStatusType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "IN_NETWORK":
                    case 1:
                        message.type = 1;
                        break;
                    case "OUT_OF_NETWORK":
                    case 2:
                        message.type = 2;
                        break;
                    case "UNDETERMINED_COLLECTING_IN":
                    case 3:
                        message.type = 3;
                        break;
                    case "UNDETERMINED_COLLECTING_BOTH":
                    case 4:
                        message.type = 4;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.NetworkStatusType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a NetworkStatusType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {infinitusai.be.NetworkStatusType} message NetworkStatusType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NetworkStatusType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.NetworkStatusType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.NetworkStatusType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this NetworkStatusType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NetworkStatusType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NetworkStatusType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NetworkStatusType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NetworkStatusType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NetworkStatusType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NetworkStatusType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.NetworkStatusType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} IN_NETWORK=1 IN_NETWORK value
                 * @property {number} OUT_OF_NETWORK=2 OUT_OF_NETWORK value
                 * @property {number} UNDETERMINED_COLLECTING_IN=3 UNDETERMINED_COLLECTING_IN value
                 * @property {number} UNDETERMINED_COLLECTING_BOTH=4 UNDETERMINED_COLLECTING_BOTH value
                 */
                NetworkStatusType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "IN_NETWORK"] = 1;
                    values[valuesById[2] = "OUT_OF_NETWORK"] = 2;
                    values[valuesById[3] = "UNDETERMINED_COLLECTING_IN"] = 3;
                    values[valuesById[4] = "UNDETERMINED_COLLECTING_BOTH"] = 4;
                    return values;
                })();
    
                return NetworkStatusType;
            })();
    
            be.ProviderNetworkStatusOnlyType = (function() {
    
                /**
                 * Properties of a ProviderNetworkStatusOnlyType.
                 * @memberof infinitusai.be
                 * @interface IProviderNetworkStatusOnlyType
                 * @property {infinitusai.be.ProviderNetworkStatusOnlyType.Type|null} [type] ProviderNetworkStatusOnlyType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] ProviderNetworkStatusOnlyType metadata
                 */
    
                /**
                 * Constructs a new ProviderNetworkStatusOnlyType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProviderNetworkStatusOnlyType.
                 * @implements IProviderNetworkStatusOnlyType
                 * @constructor
                 * @param {infinitusai.be.IProviderNetworkStatusOnlyType=} [properties] Properties to set
                 */
                function ProviderNetworkStatusOnlyType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProviderNetworkStatusOnlyType type.
                 * @member {infinitusai.be.ProviderNetworkStatusOnlyType.Type} type
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @instance
                 */
                ProviderNetworkStatusOnlyType.prototype.type = 0;
    
                /**
                 * ProviderNetworkStatusOnlyType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @instance
                 */
                ProviderNetworkStatusOnlyType.prototype.metadata = null;
    
                /**
                 * Creates a new ProviderNetworkStatusOnlyType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {infinitusai.be.IProviderNetworkStatusOnlyType=} [properties] Properties to set
                 * @returns {infinitusai.be.ProviderNetworkStatusOnlyType} ProviderNetworkStatusOnlyType instance
                 */
                ProviderNetworkStatusOnlyType.create = function create(properties) {
                    return new ProviderNetworkStatusOnlyType(properties);
                };
    
                /**
                 * Encodes the specified ProviderNetworkStatusOnlyType message. Does not implicitly {@link infinitusai.be.ProviderNetworkStatusOnlyType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {infinitusai.be.IProviderNetworkStatusOnlyType} message ProviderNetworkStatusOnlyType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderNetworkStatusOnlyType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ProviderNetworkStatusOnlyType message, length delimited. Does not implicitly {@link infinitusai.be.ProviderNetworkStatusOnlyType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {infinitusai.be.IProviderNetworkStatusOnlyType} message ProviderNetworkStatusOnlyType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderNetworkStatusOnlyType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProviderNetworkStatusOnlyType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProviderNetworkStatusOnlyType} ProviderNetworkStatusOnlyType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderNetworkStatusOnlyType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProviderNetworkStatusOnlyType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProviderNetworkStatusOnlyType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProviderNetworkStatusOnlyType} ProviderNetworkStatusOnlyType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderNetworkStatusOnlyType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProviderNetworkStatusOnlyType message.
                 * @function verify
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderNetworkStatusOnlyType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ProviderNetworkStatusOnlyType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProviderNetworkStatusOnlyType} ProviderNetworkStatusOnlyType
                 */
                ProviderNetworkStatusOnlyType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProviderNetworkStatusOnlyType)
                        return object;
                    var message = new $root.infinitusai.be.ProviderNetworkStatusOnlyType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "IN_NETWORK":
                    case 1:
                        message.type = 1;
                        break;
                    case "IN_NETWORK_DIFFERENT_ADDRESS":
                    case 2:
                        message.type = 2;
                        break;
                    case "OUT_OF_NETWORK":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.ProviderNetworkStatusOnlyType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProviderNetworkStatusOnlyType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {infinitusai.be.ProviderNetworkStatusOnlyType} message ProviderNetworkStatusOnlyType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderNetworkStatusOnlyType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.ProviderNetworkStatusOnlyType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.ProviderNetworkStatusOnlyType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this ProviderNetworkStatusOnlyType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderNetworkStatusOnlyType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProviderNetworkStatusOnlyType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProviderNetworkStatusOnlyType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderNetworkStatusOnlyType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProviderNetworkStatusOnlyType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.ProviderNetworkStatusOnlyType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} IN_NETWORK=1 IN_NETWORK value
                 * @property {number} IN_NETWORK_DIFFERENT_ADDRESS=2 IN_NETWORK_DIFFERENT_ADDRESS value
                 * @property {number} OUT_OF_NETWORK=3 OUT_OF_NETWORK value
                 */
                ProviderNetworkStatusOnlyType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "IN_NETWORK"] = 1;
                    values[valuesById[2] = "IN_NETWORK_DIFFERENT_ADDRESS"] = 2;
                    values[valuesById[3] = "OUT_OF_NETWORK"] = 3;
                    return values;
                })();
    
                return ProviderNetworkStatusOnlyType;
            })();
    
            be.SpecialtyPharmacyType = (function() {
    
                /**
                 * Properties of a SpecialtyPharmacyType.
                 * @memberof infinitusai.be
                 * @interface ISpecialtyPharmacyType
                 * @property {infinitusai.be.SpecialtyPharmacyType.Type|null} [type] SpecialtyPharmacyType type
                 */
    
                /**
                 * Constructs a new SpecialtyPharmacyType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SpecialtyPharmacyType.
                 * @implements ISpecialtyPharmacyType
                 * @constructor
                 * @param {infinitusai.be.ISpecialtyPharmacyType=} [properties] Properties to set
                 */
                function SpecialtyPharmacyType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SpecialtyPharmacyType type.
                 * @member {infinitusai.be.SpecialtyPharmacyType.Type} type
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @instance
                 */
                SpecialtyPharmacyType.prototype.type = 0;
    
                /**
                 * Creates a new SpecialtyPharmacyType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {infinitusai.be.ISpecialtyPharmacyType=} [properties] Properties to set
                 * @returns {infinitusai.be.SpecialtyPharmacyType} SpecialtyPharmacyType instance
                 */
                SpecialtyPharmacyType.create = function create(properties) {
                    return new SpecialtyPharmacyType(properties);
                };
    
                /**
                 * Encodes the specified SpecialtyPharmacyType message. Does not implicitly {@link infinitusai.be.SpecialtyPharmacyType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {infinitusai.be.ISpecialtyPharmacyType} message SpecialtyPharmacyType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyPharmacyType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    return writer;
                };
    
                /**
                 * Encodes the specified SpecialtyPharmacyType message, length delimited. Does not implicitly {@link infinitusai.be.SpecialtyPharmacyType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {infinitusai.be.ISpecialtyPharmacyType} message SpecialtyPharmacyType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyPharmacyType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SpecialtyPharmacyType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SpecialtyPharmacyType} SpecialtyPharmacyType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyPharmacyType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SpecialtyPharmacyType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SpecialtyPharmacyType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SpecialtyPharmacyType} SpecialtyPharmacyType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyPharmacyType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SpecialtyPharmacyType message.
                 * @function verify
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpecialtyPharmacyType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a SpecialtyPharmacyType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SpecialtyPharmacyType} SpecialtyPharmacyType
                 */
                SpecialtyPharmacyType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SpecialtyPharmacyType)
                        return object;
                    var message = new $root.infinitusai.be.SpecialtyPharmacyType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "NOT_AVAILABLE":
                    case 1:
                        message.type = 1;
                        break;
                    case "AVAILABLE":
                    case 2:
                        message.type = 2;
                        break;
                    case "REQUIRED":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SpecialtyPharmacyType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {infinitusai.be.SpecialtyPharmacyType} message SpecialtyPharmacyType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpecialtyPharmacyType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.SpecialtyPharmacyType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.SpecialtyPharmacyType.Type[message.type] : message.type;
                    return object;
                };
    
                /**
                 * Converts this SpecialtyPharmacyType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpecialtyPharmacyType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SpecialtyPharmacyType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SpecialtyPharmacyType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpecialtyPharmacyType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SpecialtyPharmacyType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.SpecialtyPharmacyType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} NOT_AVAILABLE=1 NOT_AVAILABLE value
                 * @property {number} AVAILABLE=2 AVAILABLE value
                 * @property {number} REQUIRED=3 REQUIRED value
                 */
                SpecialtyPharmacyType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "NOT_AVAILABLE"] = 1;
                    values[valuesById[2] = "AVAILABLE"] = 2;
                    values[valuesById[3] = "REQUIRED"] = 3;
                    return values;
                })();
    
                return SpecialtyPharmacyType;
            })();
    
            be.AdverseEventType = (function() {
    
                /**
                 * Properties of an AdverseEventType.
                 * @memberof infinitusai.be
                 * @interface IAdverseEventType
                 * @property {infinitusai.be.AdverseEventType.Type|null} [type] AdverseEventType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] AdverseEventType metadata
                 */
    
                /**
                 * Constructs a new AdverseEventType.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AdverseEventType.
                 * @implements IAdverseEventType
                 * @constructor
                 * @param {infinitusai.be.IAdverseEventType=} [properties] Properties to set
                 */
                function AdverseEventType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AdverseEventType type.
                 * @member {infinitusai.be.AdverseEventType.Type} type
                 * @memberof infinitusai.be.AdverseEventType
                 * @instance
                 */
                AdverseEventType.prototype.type = 0;
    
                /**
                 * AdverseEventType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.AdverseEventType
                 * @instance
                 */
                AdverseEventType.prototype.metadata = null;
    
                /**
                 * Creates a new AdverseEventType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {infinitusai.be.IAdverseEventType=} [properties] Properties to set
                 * @returns {infinitusai.be.AdverseEventType} AdverseEventType instance
                 */
                AdverseEventType.create = function create(properties) {
                    return new AdverseEventType(properties);
                };
    
                /**
                 * Encodes the specified AdverseEventType message. Does not implicitly {@link infinitusai.be.AdverseEventType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {infinitusai.be.IAdverseEventType} message AdverseEventType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdverseEventType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AdverseEventType message, length delimited. Does not implicitly {@link infinitusai.be.AdverseEventType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {infinitusai.be.IAdverseEventType} message AdverseEventType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdverseEventType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AdverseEventType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AdverseEventType} AdverseEventType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdverseEventType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AdverseEventType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AdverseEventType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AdverseEventType} AdverseEventType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdverseEventType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AdverseEventType message.
                 * @function verify
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AdverseEventType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an AdverseEventType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AdverseEventType} AdverseEventType
                 */
                AdverseEventType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AdverseEventType)
                        return object;
                    var message = new $root.infinitusai.be.AdverseEventType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "PATIENT_DECEASED":
                    case 1:
                        message.type = 1;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.AdverseEventType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AdverseEventType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {infinitusai.be.AdverseEventType} message AdverseEventType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AdverseEventType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.AdverseEventType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.AdverseEventType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this AdverseEventType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AdverseEventType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AdverseEventType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AdverseEventType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AdverseEventType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AdverseEventType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AdverseEventType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.AdverseEventType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} PATIENT_DECEASED=1 PATIENT_DECEASED value
                 */
                AdverseEventType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "PATIENT_DECEASED"] = 1;
                    return values;
                })();
    
                return AdverseEventType;
            })();
    
            be.PatientResponsibility = (function() {
    
                /**
                 * Properties of a PatientResponsibility.
                 * @memberof infinitusai.be
                 * @interface IPatientResponsibility
                 * @property {infinitusai.be.IMoney|null} [copay] PatientResponsibility copay
                 * @property {infinitusai.be.INullableInt32|null} [coinsurancePercent] PatientResponsibility coinsurancePercent
                 */
    
                /**
                 * Constructs a new PatientResponsibility.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PatientResponsibility.
                 * @implements IPatientResponsibility
                 * @constructor
                 * @param {infinitusai.be.IPatientResponsibility=} [properties] Properties to set
                 */
                function PatientResponsibility(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PatientResponsibility copay.
                 * @member {infinitusai.be.IMoney|null|undefined} copay
                 * @memberof infinitusai.be.PatientResponsibility
                 * @instance
                 */
                PatientResponsibility.prototype.copay = null;
    
                /**
                 * PatientResponsibility coinsurancePercent.
                 * @member {infinitusai.be.INullableInt32|null|undefined} coinsurancePercent
                 * @memberof infinitusai.be.PatientResponsibility
                 * @instance
                 */
                PatientResponsibility.prototype.coinsurancePercent = null;
    
                /**
                 * Creates a new PatientResponsibility instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {infinitusai.be.IPatientResponsibility=} [properties] Properties to set
                 * @returns {infinitusai.be.PatientResponsibility} PatientResponsibility instance
                 */
                PatientResponsibility.create = function create(properties) {
                    return new PatientResponsibility(properties);
                };
    
                /**
                 * Encodes the specified PatientResponsibility message. Does not implicitly {@link infinitusai.be.PatientResponsibility.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {infinitusai.be.IPatientResponsibility} message PatientResponsibility message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PatientResponsibility.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.copay != null && Object.hasOwnProperty.call(message, "copay"))
                        $root.infinitusai.be.Money.encode(message.copay, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.coinsurancePercent != null && Object.hasOwnProperty.call(message, "coinsurancePercent"))
                        $root.infinitusai.be.NullableInt32.encode(message.coinsurancePercent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PatientResponsibility message, length delimited. Does not implicitly {@link infinitusai.be.PatientResponsibility.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {infinitusai.be.IPatientResponsibility} message PatientResponsibility message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PatientResponsibility.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PatientResponsibility message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PatientResponsibility} PatientResponsibility
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PatientResponsibility.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PatientResponsibility();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.copay = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.coinsurancePercent = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PatientResponsibility message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PatientResponsibility} PatientResponsibility
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PatientResponsibility.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PatientResponsibility message.
                 * @function verify
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PatientResponsibility.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.copay != null && message.hasOwnProperty("copay")) {
                        var error = $root.infinitusai.be.Money.verify(message.copay);
                        if (error)
                            return "copay." + error;
                    }
                    if (message.coinsurancePercent != null && message.hasOwnProperty("coinsurancePercent")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.coinsurancePercent);
                        if (error)
                            return "coinsurancePercent." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PatientResponsibility message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PatientResponsibility} PatientResponsibility
                 */
                PatientResponsibility.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PatientResponsibility)
                        return object;
                    var message = new $root.infinitusai.be.PatientResponsibility();
                    if (object.copay != null) {
                        if (typeof object.copay !== "object")
                            throw TypeError(".infinitusai.be.PatientResponsibility.copay: object expected");
                        message.copay = $root.infinitusai.be.Money.fromObject(object.copay);
                    }
                    if (object.coinsurancePercent != null) {
                        if (typeof object.coinsurancePercent !== "object")
                            throw TypeError(".infinitusai.be.PatientResponsibility.coinsurancePercent: object expected");
                        message.coinsurancePercent = $root.infinitusai.be.NullableInt32.fromObject(object.coinsurancePercent);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PatientResponsibility message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {infinitusai.be.PatientResponsibility} message PatientResponsibility
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PatientResponsibility.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.copay = null;
                        object.coinsurancePercent = null;
                    }
                    if (message.copay != null && message.hasOwnProperty("copay"))
                        object.copay = $root.infinitusai.be.Money.toObject(message.copay, options);
                    if (message.coinsurancePercent != null && message.hasOwnProperty("coinsurancePercent"))
                        object.coinsurancePercent = $root.infinitusai.be.NullableInt32.toObject(message.coinsurancePercent, options);
                    return object;
                };
    
                /**
                 * Converts this PatientResponsibility to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PatientResponsibility
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PatientResponsibility.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PatientResponsibility
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PatientResponsibility
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PatientResponsibility.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PatientResponsibility";
                };
    
                return PatientResponsibility;
            })();
    
            be.CurrentPlanPriority = (function() {
    
                /**
                 * Properties of a CurrentPlanPriority.
                 * @memberof infinitusai.be
                 * @interface ICurrentPlanPriority
                 * @property {infinitusai.be.CurrentPlanPriority.Type|null} [type] CurrentPlanPriority type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] CurrentPlanPriority metadata
                 */
    
                /**
                 * Constructs a new CurrentPlanPriority.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CurrentPlanPriority.
                 * @implements ICurrentPlanPriority
                 * @constructor
                 * @param {infinitusai.be.ICurrentPlanPriority=} [properties] Properties to set
                 */
                function CurrentPlanPriority(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CurrentPlanPriority type.
                 * @member {infinitusai.be.CurrentPlanPriority.Type} type
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @instance
                 */
                CurrentPlanPriority.prototype.type = 0;
    
                /**
                 * CurrentPlanPriority metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @instance
                 */
                CurrentPlanPriority.prototype.metadata = null;
    
                /**
                 * Creates a new CurrentPlanPriority instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {infinitusai.be.ICurrentPlanPriority=} [properties] Properties to set
                 * @returns {infinitusai.be.CurrentPlanPriority} CurrentPlanPriority instance
                 */
                CurrentPlanPriority.create = function create(properties) {
                    return new CurrentPlanPriority(properties);
                };
    
                /**
                 * Encodes the specified CurrentPlanPriority message. Does not implicitly {@link infinitusai.be.CurrentPlanPriority.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {infinitusai.be.ICurrentPlanPriority} message CurrentPlanPriority message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CurrentPlanPriority.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CurrentPlanPriority message, length delimited. Does not implicitly {@link infinitusai.be.CurrentPlanPriority.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {infinitusai.be.ICurrentPlanPriority} message CurrentPlanPriority message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CurrentPlanPriority.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CurrentPlanPriority message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CurrentPlanPriority} CurrentPlanPriority
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CurrentPlanPriority.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CurrentPlanPriority();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CurrentPlanPriority message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CurrentPlanPriority} CurrentPlanPriority
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CurrentPlanPriority.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CurrentPlanPriority message.
                 * @function verify
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CurrentPlanPriority.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CurrentPlanPriority message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CurrentPlanPriority} CurrentPlanPriority
                 */
                CurrentPlanPriority.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CurrentPlanPriority)
                        return object;
                    var message = new $root.infinitusai.be.CurrentPlanPriority();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "PRIMARY":
                    case 1:
                        message.type = 1;
                        break;
                    case "SECONDARY":
                    case 2:
                        message.type = 2;
                        break;
                    case "TERTIARY":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.CurrentPlanPriority.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CurrentPlanPriority message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {infinitusai.be.CurrentPlanPriority} message CurrentPlanPriority
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CurrentPlanPriority.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.CurrentPlanPriority.Type[message.type] === undefined ? message.type : $root.infinitusai.be.CurrentPlanPriority.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this CurrentPlanPriority to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CurrentPlanPriority.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CurrentPlanPriority
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CurrentPlanPriority
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CurrentPlanPriority.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CurrentPlanPriority";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.CurrentPlanPriority.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} PRIMARY=1 PRIMARY value
                 * @property {number} SECONDARY=2 SECONDARY value
                 * @property {number} TERTIARY=3 TERTIARY value
                 */
                CurrentPlanPriority.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "PRIMARY"] = 1;
                    values[valuesById[2] = "SECONDARY"] = 2;
                    values[valuesById[3] = "TERTIARY"] = 3;
                    return values;
                })();
    
                return CurrentPlanPriority;
            })();
    
            be.BenefitCoordination = (function() {
    
                /**
                 * Properties of a BenefitCoordination.
                 * @memberof infinitusai.be
                 * @interface IBenefitCoordination
                 * @property {infinitusai.be.BenefitCoordination.Type|null} [type] BenefitCoordination type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] BenefitCoordination metadata
                 */
    
                /**
                 * Constructs a new BenefitCoordination.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BenefitCoordination.
                 * @implements IBenefitCoordination
                 * @constructor
                 * @param {infinitusai.be.IBenefitCoordination=} [properties] Properties to set
                 */
                function BenefitCoordination(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BenefitCoordination type.
                 * @member {infinitusai.be.BenefitCoordination.Type} type
                 * @memberof infinitusai.be.BenefitCoordination
                 * @instance
                 */
                BenefitCoordination.prototype.type = 0;
    
                /**
                 * BenefitCoordination metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.BenefitCoordination
                 * @instance
                 */
                BenefitCoordination.prototype.metadata = null;
    
                /**
                 * Creates a new BenefitCoordination instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {infinitusai.be.IBenefitCoordination=} [properties] Properties to set
                 * @returns {infinitusai.be.BenefitCoordination} BenefitCoordination instance
                 */
                BenefitCoordination.create = function create(properties) {
                    return new BenefitCoordination(properties);
                };
    
                /**
                 * Encodes the specified BenefitCoordination message. Does not implicitly {@link infinitusai.be.BenefitCoordination.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {infinitusai.be.IBenefitCoordination} message BenefitCoordination message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BenefitCoordination.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified BenefitCoordination message, length delimited. Does not implicitly {@link infinitusai.be.BenefitCoordination.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {infinitusai.be.IBenefitCoordination} message BenefitCoordination message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BenefitCoordination.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BenefitCoordination message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BenefitCoordination} BenefitCoordination
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BenefitCoordination.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BenefitCoordination();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BenefitCoordination message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BenefitCoordination} BenefitCoordination
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BenefitCoordination.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BenefitCoordination message.
                 * @function verify
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BenefitCoordination.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a BenefitCoordination message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BenefitCoordination} BenefitCoordination
                 */
                BenefitCoordination.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BenefitCoordination)
                        return object;
                    var message = new $root.infinitusai.be.BenefitCoordination();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "SUPPLEMENT":
                    case 1:
                        message.type = 1;
                        break;
                    case "STANDARD":
                    case 2:
                        message.type = 2;
                        break;
                    case "NON_DUPLICATION":
                    case 3:
                        message.type = 3;
                        break;
                    case "COME_OUT_WHOLE":
                    case 4:
                        message.type = 4;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.BenefitCoordination.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a BenefitCoordination message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {infinitusai.be.BenefitCoordination} message BenefitCoordination
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BenefitCoordination.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.BenefitCoordination.Type[message.type] === undefined ? message.type : $root.infinitusai.be.BenefitCoordination.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this BenefitCoordination to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BenefitCoordination
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BenefitCoordination.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BenefitCoordination
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BenefitCoordination
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BenefitCoordination.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BenefitCoordination";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.BenefitCoordination.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} SUPPLEMENT=1 SUPPLEMENT value
                 * @property {number} STANDARD=2 STANDARD value
                 * @property {number} NON_DUPLICATION=3 NON_DUPLICATION value
                 * @property {number} COME_OUT_WHOLE=4 COME_OUT_WHOLE value
                 */
                BenefitCoordination.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "SUPPLEMENT"] = 1;
                    values[valuesById[2] = "STANDARD"] = 2;
                    values[valuesById[3] = "NON_DUPLICATION"] = 3;
                    values[valuesById[4] = "COME_OUT_WHOLE"] = 4;
                    return values;
                })();
    
                return BenefitCoordination;
            })();
    
            be.MultiSelectPriorAuthRequirementType = (function() {
    
                /**
                 * Properties of a MultiSelectPriorAuthRequirementType.
                 * @memberof infinitusai.be
                 * @interface IMultiSelectPriorAuthRequirementType
                 * @property {Array.<infinitusai.be.MultiSelectPriorAuthRequirementType.Type>|null} [types] MultiSelectPriorAuthRequirementType types
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] MultiSelectPriorAuthRequirementType metadata
                 */
    
                /**
                 * Constructs a new MultiSelectPriorAuthRequirementType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MultiSelectPriorAuthRequirementType.
                 * @implements IMultiSelectPriorAuthRequirementType
                 * @constructor
                 * @param {infinitusai.be.IMultiSelectPriorAuthRequirementType=} [properties] Properties to set
                 */
                function MultiSelectPriorAuthRequirementType(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MultiSelectPriorAuthRequirementType types.
                 * @member {Array.<infinitusai.be.MultiSelectPriorAuthRequirementType.Type>} types
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @instance
                 */
                MultiSelectPriorAuthRequirementType.prototype.types = $util.emptyArray;
    
                /**
                 * MultiSelectPriorAuthRequirementType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @instance
                 */
                MultiSelectPriorAuthRequirementType.prototype.metadata = null;
    
                /**
                 * Creates a new MultiSelectPriorAuthRequirementType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {infinitusai.be.IMultiSelectPriorAuthRequirementType=} [properties] Properties to set
                 * @returns {infinitusai.be.MultiSelectPriorAuthRequirementType} MultiSelectPriorAuthRequirementType instance
                 */
                MultiSelectPriorAuthRequirementType.create = function create(properties) {
                    return new MultiSelectPriorAuthRequirementType(properties);
                };
    
                /**
                 * Encodes the specified MultiSelectPriorAuthRequirementType message. Does not implicitly {@link infinitusai.be.MultiSelectPriorAuthRequirementType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {infinitusai.be.IMultiSelectPriorAuthRequirementType} message MultiSelectPriorAuthRequirementType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiSelectPriorAuthRequirementType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MultiSelectPriorAuthRequirementType message, length delimited. Does not implicitly {@link infinitusai.be.MultiSelectPriorAuthRequirementType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {infinitusai.be.IMultiSelectPriorAuthRequirementType} message MultiSelectPriorAuthRequirementType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiSelectPriorAuthRequirementType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MultiSelectPriorAuthRequirementType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MultiSelectPriorAuthRequirementType} MultiSelectPriorAuthRequirementType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiSelectPriorAuthRequirementType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MultiSelectPriorAuthRequirementType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MultiSelectPriorAuthRequirementType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MultiSelectPriorAuthRequirementType} MultiSelectPriorAuthRequirementType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiSelectPriorAuthRequirementType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MultiSelectPriorAuthRequirementType message.
                 * @function verify
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MultiSelectPriorAuthRequirementType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 4:
                                break;
                            }
                    }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a MultiSelectPriorAuthRequirementType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MultiSelectPriorAuthRequirementType} MultiSelectPriorAuthRequirementType
                 */
                MultiSelectPriorAuthRequirementType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MultiSelectPriorAuthRequirementType)
                        return object;
                    var message = new $root.infinitusai.be.MultiSelectPriorAuthRequirementType();
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.be.MultiSelectPriorAuthRequirementType.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "PA_FORM":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "LETTER_OF_NECESSITY":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "MEDICAL_DOCS":
                            case 4:
                                message.types[i] = 4;
                                break;
                            }
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.MultiSelectPriorAuthRequirementType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MultiSelectPriorAuthRequirementType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {infinitusai.be.MultiSelectPriorAuthRequirementType} message MultiSelectPriorAuthRequirementType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MultiSelectPriorAuthRequirementType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (options.defaults)
                        object.metadata = null;
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.be.MultiSelectPriorAuthRequirementType.Type[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.be.MultiSelectPriorAuthRequirementType.Type[message.types[j]] : message.types[j];
                    }
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this MultiSelectPriorAuthRequirementType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MultiSelectPriorAuthRequirementType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MultiSelectPriorAuthRequirementType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MultiSelectPriorAuthRequirementType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MultiSelectPriorAuthRequirementType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MultiSelectPriorAuthRequirementType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.MultiSelectPriorAuthRequirementType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} PA_FORM=1 PA_FORM value
                 * @property {number} LETTER_OF_NECESSITY=2 LETTER_OF_NECESSITY value
                 * @property {number} MEDICAL_DOCS=4 MEDICAL_DOCS value
                 */
                MultiSelectPriorAuthRequirementType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "PA_FORM"] = 1;
                    values[valuesById[2] = "LETTER_OF_NECESSITY"] = 2;
                    values[valuesById[4] = "MEDICAL_DOCS"] = 4;
                    return values;
                })();
    
                return MultiSelectPriorAuthRequirementType;
            })();
    
            be.ReasonForNonCoverageType = (function() {
    
                /**
                 * Properties of a ReasonForNonCoverageType.
                 * @memberof infinitusai.be
                 * @interface IReasonForNonCoverageType
                 * @property {infinitusai.be.ReasonForNonCoverageType.Type|null} [type] ReasonForNonCoverageType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] ReasonForNonCoverageType metadata
                 */
    
                /**
                 * Constructs a new ReasonForNonCoverageType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReasonForNonCoverageType.
                 * @implements IReasonForNonCoverageType
                 * @constructor
                 * @param {infinitusai.be.IReasonForNonCoverageType=} [properties] Properties to set
                 */
                function ReasonForNonCoverageType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ReasonForNonCoverageType type.
                 * @member {infinitusai.be.ReasonForNonCoverageType.Type} type
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @instance
                 */
                ReasonForNonCoverageType.prototype.type = 0;
    
                /**
                 * ReasonForNonCoverageType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @instance
                 */
                ReasonForNonCoverageType.prototype.metadata = null;
    
                /**
                 * Creates a new ReasonForNonCoverageType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {infinitusai.be.IReasonForNonCoverageType=} [properties] Properties to set
                 * @returns {infinitusai.be.ReasonForNonCoverageType} ReasonForNonCoverageType instance
                 */
                ReasonForNonCoverageType.create = function create(properties) {
                    return new ReasonForNonCoverageType(properties);
                };
    
                /**
                 * Encodes the specified ReasonForNonCoverageType message. Does not implicitly {@link infinitusai.be.ReasonForNonCoverageType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {infinitusai.be.IReasonForNonCoverageType} message ReasonForNonCoverageType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReasonForNonCoverageType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ReasonForNonCoverageType message, length delimited. Does not implicitly {@link infinitusai.be.ReasonForNonCoverageType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {infinitusai.be.IReasonForNonCoverageType} message ReasonForNonCoverageType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReasonForNonCoverageType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReasonForNonCoverageType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReasonForNonCoverageType} ReasonForNonCoverageType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReasonForNonCoverageType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReasonForNonCoverageType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReasonForNonCoverageType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReasonForNonCoverageType} ReasonForNonCoverageType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReasonForNonCoverageType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReasonForNonCoverageType message.
                 * @function verify
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReasonForNonCoverageType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ReasonForNonCoverageType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReasonForNonCoverageType} ReasonForNonCoverageType
                 */
                ReasonForNonCoverageType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReasonForNonCoverageType)
                        return object;
                    var message = new $root.infinitusai.be.ReasonForNonCoverageType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM":
                    case 1:
                        message.type = 1;
                        break;
                    case "NO_INFUSION_OR_NO_INJECTABLE_BENEFITS":
                    case 2:
                        message.type = 2;
                        break;
                    case "NOT_ENROLLED_IN_PART_B":
                    case 3:
                        message.type = 3;
                        break;
                    case "CAP_MET":
                    case 4:
                        message.type = 4;
                        break;
                    case "EXCLUSION_DIAGNOSIS":
                    case 5:
                        message.type = 5;
                        break;
                    case "EXCLUSION_DRUG":
                    case 6:
                        message.type = 6;
                        break;
                    case "COVERED_UNDER_MEDICAL_BENEFIT":
                    case 7:
                        message.type = 7;
                        break;
                    case "FEDERAL_LEGEND_DRUG":
                    case 8:
                        message.type = 8;
                        break;
                    case "NEED_APEAL":
                    case 9:
                        message.type = 9;
                        break;
                    case "NON_FORMULARY":
                    case 10:
                        message.type = 10;
                        break;
                    case "PLAN_EXCLUSION":
                    case 11:
                        message.type = 11;
                        break;
                    case "PLAN_IS_SECONDARY":
                    case 12:
                        message.type = 12;
                        break;
                    case "PRIOR_AUTHORIZATION_REQUIRED":
                    case 13:
                        message.type = 13;
                        break;
                    case "STEP_THERAPY_REQUIRED":
                    case 14:
                        message.type = 14;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.ReasonForNonCoverageType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ReasonForNonCoverageType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {infinitusai.be.ReasonForNonCoverageType} message ReasonForNonCoverageType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReasonForNonCoverageType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.ReasonForNonCoverageType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.ReasonForNonCoverageType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this ReasonForNonCoverageType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReasonForNonCoverageType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReasonForNonCoverageType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReasonForNonCoverageType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReasonForNonCoverageType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReasonForNonCoverageType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.ReasonForNonCoverageType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM=1 NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM value
                 * @property {number} NO_INFUSION_OR_NO_INJECTABLE_BENEFITS=2 NO_INFUSION_OR_NO_INJECTABLE_BENEFITS value
                 * @property {number} NOT_ENROLLED_IN_PART_B=3 NOT_ENROLLED_IN_PART_B value
                 * @property {number} CAP_MET=4 CAP_MET value
                 * @property {number} EXCLUSION_DIAGNOSIS=5 EXCLUSION_DIAGNOSIS value
                 * @property {number} EXCLUSION_DRUG=6 EXCLUSION_DRUG value
                 * @property {number} COVERED_UNDER_MEDICAL_BENEFIT=7 COVERED_UNDER_MEDICAL_BENEFIT value
                 * @property {number} FEDERAL_LEGEND_DRUG=8 FEDERAL_LEGEND_DRUG value
                 * @property {number} NEED_APEAL=9 NEED_APEAL value
                 * @property {number} NON_FORMULARY=10 NON_FORMULARY value
                 * @property {number} PLAN_EXCLUSION=11 PLAN_EXCLUSION value
                 * @property {number} PLAN_IS_SECONDARY=12 PLAN_IS_SECONDARY value
                 * @property {number} PRIOR_AUTHORIZATION_REQUIRED=13 PRIOR_AUTHORIZATION_REQUIRED value
                 * @property {number} STEP_THERAPY_REQUIRED=14 STEP_THERAPY_REQUIRED value
                 */
                ReasonForNonCoverageType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM"] = 1;
                    values[valuesById[2] = "NO_INFUSION_OR_NO_INJECTABLE_BENEFITS"] = 2;
                    values[valuesById[3] = "NOT_ENROLLED_IN_PART_B"] = 3;
                    values[valuesById[4] = "CAP_MET"] = 4;
                    values[valuesById[5] = "EXCLUSION_DIAGNOSIS"] = 5;
                    values[valuesById[6] = "EXCLUSION_DRUG"] = 6;
                    values[valuesById[7] = "COVERED_UNDER_MEDICAL_BENEFIT"] = 7;
                    values[valuesById[8] = "FEDERAL_LEGEND_DRUG"] = 8;
                    values[valuesById[9] = "NEED_APEAL"] = 9;
                    values[valuesById[10] = "NON_FORMULARY"] = 10;
                    values[valuesById[11] = "PLAN_EXCLUSION"] = 11;
                    values[valuesById[12] = "PLAN_IS_SECONDARY"] = 12;
                    values[valuesById[13] = "PRIOR_AUTHORIZATION_REQUIRED"] = 13;
                    values[valuesById[14] = "STEP_THERAPY_REQUIRED"] = 14;
                    return values;
                })();
    
                return ReasonForNonCoverageType;
            })();
    
            be.PredeterminationRequirementType = (function() {
    
                /**
                 * Properties of a PredeterminationRequirementType.
                 * @memberof infinitusai.be
                 * @interface IPredeterminationRequirementType
                 * @property {infinitusai.be.PredeterminationRequirementType.Type|null} [type] PredeterminationRequirementType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] PredeterminationRequirementType metadata
                 */
    
                /**
                 * Constructs a new PredeterminationRequirementType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PredeterminationRequirementType.
                 * @implements IPredeterminationRequirementType
                 * @constructor
                 * @param {infinitusai.be.IPredeterminationRequirementType=} [properties] Properties to set
                 */
                function PredeterminationRequirementType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PredeterminationRequirementType type.
                 * @member {infinitusai.be.PredeterminationRequirementType.Type} type
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @instance
                 */
                PredeterminationRequirementType.prototype.type = 0;
    
                /**
                 * PredeterminationRequirementType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @instance
                 */
                PredeterminationRequirementType.prototype.metadata = null;
    
                /**
                 * Creates a new PredeterminationRequirementType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {infinitusai.be.IPredeterminationRequirementType=} [properties] Properties to set
                 * @returns {infinitusai.be.PredeterminationRequirementType} PredeterminationRequirementType instance
                 */
                PredeterminationRequirementType.create = function create(properties) {
                    return new PredeterminationRequirementType(properties);
                };
    
                /**
                 * Encodes the specified PredeterminationRequirementType message. Does not implicitly {@link infinitusai.be.PredeterminationRequirementType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {infinitusai.be.IPredeterminationRequirementType} message PredeterminationRequirementType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PredeterminationRequirementType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PredeterminationRequirementType message, length delimited. Does not implicitly {@link infinitusai.be.PredeterminationRequirementType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {infinitusai.be.IPredeterminationRequirementType} message PredeterminationRequirementType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PredeterminationRequirementType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PredeterminationRequirementType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PredeterminationRequirementType} PredeterminationRequirementType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PredeterminationRequirementType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PredeterminationRequirementType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PredeterminationRequirementType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PredeterminationRequirementType} PredeterminationRequirementType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PredeterminationRequirementType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PredeterminationRequirementType message.
                 * @function verify
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PredeterminationRequirementType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PredeterminationRequirementType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PredeterminationRequirementType} PredeterminationRequirementType
                 */
                PredeterminationRequirementType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PredeterminationRequirementType)
                        return object;
                    var message = new $root.infinitusai.be.PredeterminationRequirementType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "REQUIRED":
                    case 1:
                        message.type = 1;
                        break;
                    case "HIGHLY_RECOMMENDED":
                    case 2:
                        message.type = 2;
                        break;
                    case "AVAILABLE":
                    case 3:
                        message.type = 3;
                        break;
                    case "NOT_AVAILABLE":
                    case 4:
                        message.type = 4;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.PredeterminationRequirementType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PredeterminationRequirementType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {infinitusai.be.PredeterminationRequirementType} message PredeterminationRequirementType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PredeterminationRequirementType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.PredeterminationRequirementType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.PredeterminationRequirementType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this PredeterminationRequirementType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PredeterminationRequirementType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PredeterminationRequirementType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PredeterminationRequirementType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PredeterminationRequirementType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PredeterminationRequirementType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.PredeterminationRequirementType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} REQUIRED=1 REQUIRED value
                 * @property {number} HIGHLY_RECOMMENDED=2 HIGHLY_RECOMMENDED value
                 * @property {number} AVAILABLE=3 AVAILABLE value
                 * @property {number} NOT_AVAILABLE=4 NOT_AVAILABLE value
                 */
                PredeterminationRequirementType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "REQUIRED"] = 1;
                    values[valuesById[2] = "HIGHLY_RECOMMENDED"] = 2;
                    values[valuesById[3] = "AVAILABLE"] = 3;
                    values[valuesById[4] = "NOT_AVAILABLE"] = 4;
                    return values;
                })();
    
                return PredeterminationRequirementType;
            })();
    
            be.ReferralRequirementsType = (function() {
    
                /**
                 * Properties of a ReferralRequirementsType.
                 * @memberof infinitusai.be
                 * @interface IReferralRequirementsType
                 * @property {infinitusai.be.ReferralRequirementsType.Type|null} [type] ReferralRequirementsType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] ReferralRequirementsType metadata
                 */
    
                /**
                 * Constructs a new ReferralRequirementsType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReferralRequirementsType.
                 * @implements IReferralRequirementsType
                 * @constructor
                 * @param {infinitusai.be.IReferralRequirementsType=} [properties] Properties to set
                 */
                function ReferralRequirementsType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ReferralRequirementsType type.
                 * @member {infinitusai.be.ReferralRequirementsType.Type} type
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @instance
                 */
                ReferralRequirementsType.prototype.type = 0;
    
                /**
                 * ReferralRequirementsType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @instance
                 */
                ReferralRequirementsType.prototype.metadata = null;
    
                /**
                 * Creates a new ReferralRequirementsType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {infinitusai.be.IReferralRequirementsType=} [properties] Properties to set
                 * @returns {infinitusai.be.ReferralRequirementsType} ReferralRequirementsType instance
                 */
                ReferralRequirementsType.create = function create(properties) {
                    return new ReferralRequirementsType(properties);
                };
    
                /**
                 * Encodes the specified ReferralRequirementsType message. Does not implicitly {@link infinitusai.be.ReferralRequirementsType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {infinitusai.be.IReferralRequirementsType} message ReferralRequirementsType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReferralRequirementsType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ReferralRequirementsType message, length delimited. Does not implicitly {@link infinitusai.be.ReferralRequirementsType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {infinitusai.be.IReferralRequirementsType} message ReferralRequirementsType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReferralRequirementsType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReferralRequirementsType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReferralRequirementsType} ReferralRequirementsType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReferralRequirementsType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReferralRequirementsType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReferralRequirementsType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReferralRequirementsType} ReferralRequirementsType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReferralRequirementsType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReferralRequirementsType message.
                 * @function verify
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReferralRequirementsType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ReferralRequirementsType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReferralRequirementsType} ReferralRequirementsType
                 */
                ReferralRequirementsType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReferralRequirementsType)
                        return object;
                    var message = new $root.infinitusai.be.ReferralRequirementsType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "NOT_REQUIRED":
                    case 1:
                        message.type = 1;
                        break;
                    case "REQUIRED":
                    case 2:
                        message.type = 2;
                        break;
                    case "REQUIRED_BUT_WAIVED":
                    case 3:
                        message.type = 3;
                        break;
                    case "DOCTOR_TO_DOCTOR":
                    case 4:
                        message.type = 4;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.ReferralRequirementsType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ReferralRequirementsType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {infinitusai.be.ReferralRequirementsType} message ReferralRequirementsType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReferralRequirementsType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.ReferralRequirementsType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.ReferralRequirementsType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this ReferralRequirementsType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReferralRequirementsType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReferralRequirementsType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReferralRequirementsType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReferralRequirementsType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReferralRequirementsType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.ReferralRequirementsType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} NOT_REQUIRED=1 NOT_REQUIRED value
                 * @property {number} REQUIRED=2 REQUIRED value
                 * @property {number} REQUIRED_BUT_WAIVED=3 REQUIRED_BUT_WAIVED value
                 * @property {number} DOCTOR_TO_DOCTOR=4 DOCTOR_TO_DOCTOR value
                 */
                ReferralRequirementsType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "NOT_REQUIRED"] = 1;
                    values[valuesById[2] = "REQUIRED"] = 2;
                    values[valuesById[3] = "REQUIRED_BUT_WAIVED"] = 3;
                    values[valuesById[4] = "DOCTOR_TO_DOCTOR"] = 4;
                    return values;
                })();
    
                return ReferralRequirementsType;
            })();
    
            be.PriorAuthStatusType = (function() {
    
                /**
                 * Properties of a PriorAuthStatusType.
                 * @memberof infinitusai.be
                 * @interface IPriorAuthStatusType
                 * @property {infinitusai.be.PriorAuthStatusType.Type|null} [type] PriorAuthStatusType type
                 * @property {infinitusai.be.IFieldMetadata|null} [metadata] PriorAuthStatusType metadata
                 */
    
                /**
                 * Constructs a new PriorAuthStatusType.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PriorAuthStatusType.
                 * @implements IPriorAuthStatusType
                 * @constructor
                 * @param {infinitusai.be.IPriorAuthStatusType=} [properties] Properties to set
                 */
                function PriorAuthStatusType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PriorAuthStatusType type.
                 * @member {infinitusai.be.PriorAuthStatusType.Type} type
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @instance
                 */
                PriorAuthStatusType.prototype.type = 0;
    
                /**
                 * PriorAuthStatusType metadata.
                 * @member {infinitusai.be.IFieldMetadata|null|undefined} metadata
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @instance
                 */
                PriorAuthStatusType.prototype.metadata = null;
    
                /**
                 * Creates a new PriorAuthStatusType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {infinitusai.be.IPriorAuthStatusType=} [properties] Properties to set
                 * @returns {infinitusai.be.PriorAuthStatusType} PriorAuthStatusType instance
                 */
                PriorAuthStatusType.create = function create(properties) {
                    return new PriorAuthStatusType(properties);
                };
    
                /**
                 * Encodes the specified PriorAuthStatusType message. Does not implicitly {@link infinitusai.be.PriorAuthStatusType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {infinitusai.be.IPriorAuthStatusType} message PriorAuthStatusType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriorAuthStatusType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.infinitusai.be.FieldMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PriorAuthStatusType message, length delimited. Does not implicitly {@link infinitusai.be.PriorAuthStatusType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {infinitusai.be.IPriorAuthStatusType} message PriorAuthStatusType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriorAuthStatusType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PriorAuthStatusType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PriorAuthStatusType} PriorAuthStatusType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriorAuthStatusType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PriorAuthStatusType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.int32();
                                break;
                            }
                        case 2: {
                                message.metadata = $root.infinitusai.be.FieldMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PriorAuthStatusType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PriorAuthStatusType} PriorAuthStatusType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriorAuthStatusType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PriorAuthStatusType message.
                 * @function verify
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PriorAuthStatusType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.infinitusai.be.FieldMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PriorAuthStatusType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PriorAuthStatusType} PriorAuthStatusType
                 */
                PriorAuthStatusType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PriorAuthStatusType)
                        return object;
                    var message = new $root.infinitusai.be.PriorAuthStatusType();
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "NOT_ON_FILE":
                    case 1:
                        message.type = 1;
                        break;
                    case "APPROVED":
                    case 2:
                        message.type = 2;
                        break;
                    case "APPROVED_DIFFERENT_DOCTOR":
                    case 3:
                        message.type = 3;
                        break;
                    case "APPROVED_DIFFERENT_DIAGNOSIS":
                    case 4:
                        message.type = 4;
                        break;
                    case "DENIED":
                    case 5:
                        message.type = 5;
                        break;
                    case "EXPIRED":
                    case 6:
                        message.type = 6;
                        break;
                    case "FUTURE":
                    case 7:
                        message.type = 7;
                        break;
                    case "PENDING":
                    case 8:
                        message.type = 8;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.PriorAuthStatusType.metadata: object expected");
                        message.metadata = $root.infinitusai.be.FieldMetadata.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PriorAuthStatusType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {infinitusai.be.PriorAuthStatusType} message PriorAuthStatusType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriorAuthStatusType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "UNKNOWN" : 0;
                        object.metadata = null;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.PriorAuthStatusType.Type[message.type] === undefined ? message.type : $root.infinitusai.be.PriorAuthStatusType.Type[message.type] : message.type;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.infinitusai.be.FieldMetadata.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this PriorAuthStatusType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriorAuthStatusType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PriorAuthStatusType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PriorAuthStatusType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PriorAuthStatusType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PriorAuthStatusType";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.PriorAuthStatusType.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} NOT_ON_FILE=1 NOT_ON_FILE value
                 * @property {number} APPROVED=2 APPROVED value
                 * @property {number} APPROVED_DIFFERENT_DOCTOR=3 APPROVED_DIFFERENT_DOCTOR value
                 * @property {number} APPROVED_DIFFERENT_DIAGNOSIS=4 APPROVED_DIFFERENT_DIAGNOSIS value
                 * @property {number} DENIED=5 DENIED value
                 * @property {number} EXPIRED=6 EXPIRED value
                 * @property {number} FUTURE=7 FUTURE value
                 * @property {number} PENDING=8 PENDING value
                 */
                PriorAuthStatusType.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "NOT_ON_FILE"] = 1;
                    values[valuesById[2] = "APPROVED"] = 2;
                    values[valuesById[3] = "APPROVED_DIFFERENT_DOCTOR"] = 3;
                    values[valuesById[4] = "APPROVED_DIFFERENT_DIAGNOSIS"] = 4;
                    values[valuesById[5] = "DENIED"] = 5;
                    values[valuesById[6] = "EXPIRED"] = 6;
                    values[valuesById[7] = "FUTURE"] = 7;
                    values[valuesById[8] = "PENDING"] = 8;
                    return values;
                })();
    
                return PriorAuthStatusType;
            })();
    
            be.PriorAuthMethod = (function() {
    
                /**
                 * Properties of a PriorAuthMethod.
                 * @memberof infinitusai.be
                 * @interface IPriorAuthMethod
                 * @property {infinitusai.be.INullableString|null} [call] PriorAuthMethod call
                 * @property {infinitusai.be.INullableString|null} [fax] PriorAuthMethod fax
                 * @property {infinitusai.be.INullableString|null} [website] PriorAuthMethod website
                 */
    
                /**
                 * Constructs a new PriorAuthMethod.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PriorAuthMethod.
                 * @implements IPriorAuthMethod
                 * @constructor
                 * @param {infinitusai.be.IPriorAuthMethod=} [properties] Properties to set
                 */
                function PriorAuthMethod(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PriorAuthMethod call.
                 * @member {infinitusai.be.INullableString|null|undefined} call
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @instance
                 */
                PriorAuthMethod.prototype.call = null;
    
                /**
                 * PriorAuthMethod fax.
                 * @member {infinitusai.be.INullableString|null|undefined} fax
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @instance
                 */
                PriorAuthMethod.prototype.fax = null;
    
                /**
                 * PriorAuthMethod website.
                 * @member {infinitusai.be.INullableString|null|undefined} website
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @instance
                 */
                PriorAuthMethod.prototype.website = null;
    
                /**
                 * Creates a new PriorAuthMethod instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {infinitusai.be.IPriorAuthMethod=} [properties] Properties to set
                 * @returns {infinitusai.be.PriorAuthMethod} PriorAuthMethod instance
                 */
                PriorAuthMethod.create = function create(properties) {
                    return new PriorAuthMethod(properties);
                };
    
                /**
                 * Encodes the specified PriorAuthMethod message. Does not implicitly {@link infinitusai.be.PriorAuthMethod.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {infinitusai.be.IPriorAuthMethod} message PriorAuthMethod message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriorAuthMethod.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.call != null && Object.hasOwnProperty.call(message, "call"))
                        $root.infinitusai.be.NullableString.encode(message.call, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fax != null && Object.hasOwnProperty.call(message, "fax"))
                        $root.infinitusai.be.NullableString.encode(message.fax, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.website != null && Object.hasOwnProperty.call(message, "website"))
                        $root.infinitusai.be.NullableString.encode(message.website, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PriorAuthMethod message, length delimited. Does not implicitly {@link infinitusai.be.PriorAuthMethod.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {infinitusai.be.IPriorAuthMethod} message PriorAuthMethod message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriorAuthMethod.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PriorAuthMethod message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PriorAuthMethod} PriorAuthMethod
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriorAuthMethod.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PriorAuthMethod();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.call = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.fax = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.website = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PriorAuthMethod message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PriorAuthMethod} PriorAuthMethod
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriorAuthMethod.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PriorAuthMethod message.
                 * @function verify
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PriorAuthMethod.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.call != null && message.hasOwnProperty("call")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.call);
                        if (error)
                            return "call." + error;
                    }
                    if (message.fax != null && message.hasOwnProperty("fax")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.fax);
                        if (error)
                            return "fax." + error;
                    }
                    if (message.website != null && message.hasOwnProperty("website")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.website);
                        if (error)
                            return "website." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PriorAuthMethod message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PriorAuthMethod} PriorAuthMethod
                 */
                PriorAuthMethod.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PriorAuthMethod)
                        return object;
                    var message = new $root.infinitusai.be.PriorAuthMethod();
                    if (object.call != null) {
                        if (typeof object.call !== "object")
                            throw TypeError(".infinitusai.be.PriorAuthMethod.call: object expected");
                        message.call = $root.infinitusai.be.NullableString.fromObject(object.call);
                    }
                    if (object.fax != null) {
                        if (typeof object.fax !== "object")
                            throw TypeError(".infinitusai.be.PriorAuthMethod.fax: object expected");
                        message.fax = $root.infinitusai.be.NullableString.fromObject(object.fax);
                    }
                    if (object.website != null) {
                        if (typeof object.website !== "object")
                            throw TypeError(".infinitusai.be.PriorAuthMethod.website: object expected");
                        message.website = $root.infinitusai.be.NullableString.fromObject(object.website);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PriorAuthMethod message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {infinitusai.be.PriorAuthMethod} message PriorAuthMethod
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriorAuthMethod.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.call = null;
                        object.fax = null;
                        object.website = null;
                    }
                    if (message.call != null && message.hasOwnProperty("call"))
                        object.call = $root.infinitusai.be.NullableString.toObject(message.call, options);
                    if (message.fax != null && message.hasOwnProperty("fax"))
                        object.fax = $root.infinitusai.be.NullableString.toObject(message.fax, options);
                    if (message.website != null && message.hasOwnProperty("website"))
                        object.website = $root.infinitusai.be.NullableString.toObject(message.website, options);
                    return object;
                };
    
                /**
                 * Converts this PriorAuthMethod to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriorAuthMethod.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PriorAuthMethod
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PriorAuthMethod
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PriorAuthMethod.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PriorAuthMethod";
                };
    
                return PriorAuthMethod;
            })();
    
            be.FullBVGeneralCallOutputs = (function() {
    
                /**
                 * Properties of a FullBVGeneralCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IFullBVGeneralCallOutputs
                 * @property {infinitusai.be.INullableString|null} [agentName] FullBVGeneralCallOutputs agentName
                 * @property {infinitusai.be.INullableString|null} [referenceNumber] FullBVGeneralCallOutputs referenceNumber
                 * @property {infinitusai.be.INullableBool|null} [hasOtherInsuranceOnFile] FullBVGeneralCallOutputs hasOtherInsuranceOnFile
                 * @property {infinitusai.be.INullableString|null} [otherInsuranceName] FullBVGeneralCallOutputs otherInsuranceName
                 * @property {infinitusai.be.ICurrentPlanPriority|null} [currentPlanPriority] FullBVGeneralCallOutputs currentPlanPriority
                 * @property {infinitusai.be.IBenefitCoordination|null} [benefitCoordination] FullBVGeneralCallOutputs benefitCoordination
                 * @property {infinitusai.be.INullableBool|null} [willPlanCoverAsPrimary] FullBVGeneralCallOutputs willPlanCoverAsPrimary
                 * @property {infinitusai.be.INullableBool|null} [partBDeductibleCovered] FullBVGeneralCallOutputs partBDeductibleCovered
                 * @property {infinitusai.be.INullableString|null} [otherInsuranceNotes] FullBVGeneralCallOutputs otherInsuranceNotes
                 * @property {infinitusai.be.IPolicyType|null} [policyType] FullBVGeneralCallOutputs policyType
                 * @property {infinitusai.be.IPlanType|null} [planType] FullBVGeneralCallOutputs planType
                 * @property {infinitusai.be.INullableBool|null} [followsMedicareGuidelines] FullBVGeneralCallOutputs followsMedicareGuidelines
                 * @property {infinitusai.be.INullableString|null} [planName] FullBVGeneralCallOutputs planName
                 * @property {infinitusai.be.IDate|null} [effectiveDate] FullBVGeneralCallOutputs effectiveDate
                 * @property {infinitusai.be.INullableBool|null} [newPlanAvailable] FullBVGeneralCallOutputs newPlanAvailable
                 * @property {infinitusai.be.INullableString|null} [newPlanSubscriberId] FullBVGeneralCallOutputs newPlanSubscriberId
                 * @property {infinitusai.be.IDate|null} [newPlanEffectiveDate] FullBVGeneralCallOutputs newPlanEffectiveDate
                 * @property {infinitusai.be.INullableBool|null} [otherInsuranceExists] FullBVGeneralCallOutputs otherInsuranceExists
                 * @property {infinitusai.be.INullableBool|null} [planTerminated] FullBVGeneralCallOutputs planTerminated
                 * @property {infinitusai.be.IDate|null} [termDate] FullBVGeneralCallOutputs termDate
                 * @property {infinitusai.be.INullableBool|null} [isAdverseEvent] FullBVGeneralCallOutputs isAdverseEvent
                 * @property {infinitusai.be.IAdverseEventType|null} [adverseEventType] FullBVGeneralCallOutputs adverseEventType
                 * @property {infinitusai.be.IBenefitPlanRenewalType|null} [benefitPlanRenewalType] FullBVGeneralCallOutputs benefitPlanRenewalType
                 * @property {infinitusai.be.IMonthType|null} [fiscalRenewMonth] FullBVGeneralCallOutputs fiscalRenewMonth
                 * @property {infinitusai.be.INullableString|null} [groupNumber] FullBVGeneralCallOutputs groupNumber
                 * @property {infinitusai.be.INullableString|null} [groupName] FullBVGeneralCallOutputs groupName
                 * @property {infinitusai.be.INullableBool|null} [isCobraPlan] FullBVGeneralCallOutputs isCobraPlan
                 * @property {infinitusai.be.IDate|null} [cobraPaidThroughDate] FullBVGeneralCallOutputs cobraPaidThroughDate
                 * @property {infinitusai.be.INullableBool|null} [cobraHasGracePeriod] FullBVGeneralCallOutputs cobraHasGracePeriod
                 * @property {infinitusai.be.INullableString|null} [cobraGracePeriod] FullBVGeneralCallOutputs cobraGracePeriod
                 * @property {infinitusai.be.INullableBool|null} [isHealthExchangePlan] FullBVGeneralCallOutputs isHealthExchangePlan
                 * @property {infinitusai.be.INullableBool|null} [patientReceivesSubsidies] FullBVGeneralCallOutputs patientReceivesSubsidies
                 * @property {infinitusai.be.IDate|null} [healthExchangePaidThroughDate] FullBVGeneralCallOutputs healthExchangePaidThroughDate
                 * @property {infinitusai.be.INullableBool|null} [healthExchangeHasGracePeriod] FullBVGeneralCallOutputs healthExchangeHasGracePeriod
                 * @property {infinitusai.be.INullableString|null} [healthExchangeGracePeriod] FullBVGeneralCallOutputs healthExchangeGracePeriod
                 * @property {infinitusai.be.INetworkStatusType|null} [networkStatus] FullBVGeneralCallOutputs networkStatus
                 * @property {infinitusai.be.INullableString|null} [inNetworkConsiderationNotes] FullBVGeneralCallOutputs inNetworkConsiderationNotes
                 * @property {infinitusai.be.IProviderNetworkStatusOnlyType|null} [providerNetworkStatusOnly] FullBVGeneralCallOutputs providerNetworkStatusOnly
                 * @property {infinitusai.be.INullableBool|null} [lifetimeMaximumExists] FullBVGeneralCallOutputs lifetimeMaximumExists
                 * @property {infinitusai.be.IMoney|null} [lifetimeMaximum] FullBVGeneralCallOutputs lifetimeMaximum
                 * @property {infinitusai.be.INullableBool|null} [annualCapExists] FullBVGeneralCallOutputs annualCapExists
                 * @property {infinitusai.be.IMoney|null} [annualCap] FullBVGeneralCallOutputs annualCap
                 * @property {infinitusai.be.INullableBool|null} [codesValidAndBillable] FullBVGeneralCallOutputs codesValidAndBillable
                 * @property {infinitusai.be.IMultiselectCodesType|null} [uncoveredCodes] FullBVGeneralCallOutputs uncoveredCodes
                 * @property {Array.<string>|null} [uncoveredCodesStr] FullBVGeneralCallOutputs uncoveredCodesStr
                 * @property {infinitusai.be.INullableBool|null} [isSelfFunded] FullBVGeneralCallOutputs isSelfFunded
                 * @property {infinitusai.be.IMoney|null} [lifetimeMaximumMet] FullBVGeneralCallOutputs lifetimeMaximumMet
                 * @property {infinitusai.be.IMoney|null} [annualCapMet] FullBVGeneralCallOutputs annualCapMet
                 * @property {infinitusai.be.IReasonForNonCoverageType|null} [reasonForNonCoverageType] FullBVGeneralCallOutputs reasonForNonCoverageType
                 * @property {infinitusai.be.INullableBool|null} [hasDeductible] FullBVGeneralCallOutputs hasDeductible
                 * @property {infinitusai.be.IMoney|null} [deductibleTotal] FullBVGeneralCallOutputs deductibleTotal
                 * @property {infinitusai.be.IMoney|null} [deductibleMet] FullBVGeneralCallOutputs deductibleMet
                 * @property {infinitusai.be.INullableBool|null} [hasSpendDown] FullBVGeneralCallOutputs hasSpendDown
                 * @property {infinitusai.be.IMoney|null} [spendDownTotal] FullBVGeneralCallOutputs spendDownTotal
                 * @property {infinitusai.be.IMoney|null} [spendDownMet] FullBVGeneralCallOutputs spendDownMet
                 * @property {infinitusai.be.INullableBool|null} [isDeductibleIncludedInOop] FullBVGeneralCallOutputs isDeductibleIncludedInOop
                 * @property {infinitusai.be.INullableBool|null} [hasOop] FullBVGeneralCallOutputs hasOop
                 * @property {infinitusai.be.IMoney|null} [oopTotal] FullBVGeneralCallOutputs oopTotal
                 * @property {infinitusai.be.IMoney|null} [oopMet] FullBVGeneralCallOutputs oopMet
                 * @property {infinitusai.be.INullableBool|null} [hasFamilyDeductible] FullBVGeneralCallOutputs hasFamilyDeductible
                 * @property {infinitusai.be.IMoney|null} [familyDeductibleTotal] FullBVGeneralCallOutputs familyDeductibleTotal
                 * @property {infinitusai.be.IMoney|null} [familyDeductibleMet] FullBVGeneralCallOutputs familyDeductibleMet
                 * @property {infinitusai.be.INullableBool|null} [hasFamilyOop] FullBVGeneralCallOutputs hasFamilyOop
                 * @property {infinitusai.be.IMoney|null} [familyOopTotal] FullBVGeneralCallOutputs familyOopTotal
                 * @property {infinitusai.be.IMoney|null} [familyOopMet] FullBVGeneralCallOutputs familyOopMet
                 * @property {infinitusai.be.INullableBool|null} [doesCopayApplyToOop] FullBVGeneralCallOutputs doesCopayApplyToOop
                 * @property {infinitusai.be.INullableBool|null} [copayWaivedAfterOop] FullBVGeneralCallOutputs copayWaivedAfterOop
                 * @property {infinitusai.be.INullableBool|null} [responsibleForMultipleCopays] FullBVGeneralCallOutputs responsibleForMultipleCopays
                 * @property {infinitusai.be.INullableBool|null} [hasStandardPlanLetter] FullBVGeneralCallOutputs hasStandardPlanLetter
                 * @property {infinitusai.be.IStandardPlanLetter|null} [standardPlanLetter] FullBVGeneralCallOutputs standardPlanLetter
                 * @property {infinitusai.be.INullableBool|null} [differentBenefitsForOutpatient] FullBVGeneralCallOutputs differentBenefitsForOutpatient
                 * @property {infinitusai.be.INullableBool|null} [pbmExists] FullBVGeneralCallOutputs pbmExists
                 * @property {infinitusai.be.INullableString|null} [pbmName] FullBVGeneralCallOutputs pbmName
                 * @property {infinitusai.be.INullableString|null} [pbmPhone] FullBVGeneralCallOutputs pbmPhone
                 * @property {infinitusai.be.INullableBool|null} [isAccumulatorPlan] FullBVGeneralCallOutputs isAccumulatorPlan
                 * @property {infinitusai.be.INullableBool|null} [isMaximizerPlan] FullBVGeneralCallOutputs isMaximizerPlan
                 */
    
                /**
                 * Constructs a new FullBVGeneralCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FullBVGeneralCallOutputs.
                 * @implements IFullBVGeneralCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IFullBVGeneralCallOutputs=} [properties] Properties to set
                 */
                function FullBVGeneralCallOutputs(properties) {
                    this.uncoveredCodesStr = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FullBVGeneralCallOutputs agentName.
                 * @member {infinitusai.be.INullableString|null|undefined} agentName
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.agentName = null;
    
                /**
                 * FullBVGeneralCallOutputs referenceNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} referenceNumber
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.referenceNumber = null;
    
                /**
                 * FullBVGeneralCallOutputs hasOtherInsuranceOnFile.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasOtherInsuranceOnFile
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.hasOtherInsuranceOnFile = null;
    
                /**
                 * FullBVGeneralCallOutputs otherInsuranceName.
                 * @member {infinitusai.be.INullableString|null|undefined} otherInsuranceName
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.otherInsuranceName = null;
    
                /**
                 * FullBVGeneralCallOutputs currentPlanPriority.
                 * @member {infinitusai.be.ICurrentPlanPriority|null|undefined} currentPlanPriority
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.currentPlanPriority = null;
    
                /**
                 * FullBVGeneralCallOutputs benefitCoordination.
                 * @member {infinitusai.be.IBenefitCoordination|null|undefined} benefitCoordination
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.benefitCoordination = null;
    
                /**
                 * FullBVGeneralCallOutputs willPlanCoverAsPrimary.
                 * @member {infinitusai.be.INullableBool|null|undefined} willPlanCoverAsPrimary
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.willPlanCoverAsPrimary = null;
    
                /**
                 * FullBVGeneralCallOutputs partBDeductibleCovered.
                 * @member {infinitusai.be.INullableBool|null|undefined} partBDeductibleCovered
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.partBDeductibleCovered = null;
    
                /**
                 * FullBVGeneralCallOutputs otherInsuranceNotes.
                 * @member {infinitusai.be.INullableString|null|undefined} otherInsuranceNotes
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.otherInsuranceNotes = null;
    
                /**
                 * FullBVGeneralCallOutputs policyType.
                 * @member {infinitusai.be.IPolicyType|null|undefined} policyType
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.policyType = null;
    
                /**
                 * FullBVGeneralCallOutputs planType.
                 * @member {infinitusai.be.IPlanType|null|undefined} planType
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.planType = null;
    
                /**
                 * FullBVGeneralCallOutputs followsMedicareGuidelines.
                 * @member {infinitusai.be.INullableBool|null|undefined} followsMedicareGuidelines
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.followsMedicareGuidelines = null;
    
                /**
                 * FullBVGeneralCallOutputs planName.
                 * @member {infinitusai.be.INullableString|null|undefined} planName
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.planName = null;
    
                /**
                 * FullBVGeneralCallOutputs effectiveDate.
                 * @member {infinitusai.be.IDate|null|undefined} effectiveDate
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.effectiveDate = null;
    
                /**
                 * FullBVGeneralCallOutputs newPlanAvailable.
                 * @member {infinitusai.be.INullableBool|null|undefined} newPlanAvailable
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.newPlanAvailable = null;
    
                /**
                 * FullBVGeneralCallOutputs newPlanSubscriberId.
                 * @member {infinitusai.be.INullableString|null|undefined} newPlanSubscriberId
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.newPlanSubscriberId = null;
    
                /**
                 * FullBVGeneralCallOutputs newPlanEffectiveDate.
                 * @member {infinitusai.be.IDate|null|undefined} newPlanEffectiveDate
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.newPlanEffectiveDate = null;
    
                /**
                 * FullBVGeneralCallOutputs otherInsuranceExists.
                 * @member {infinitusai.be.INullableBool|null|undefined} otherInsuranceExists
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.otherInsuranceExists = null;
    
                /**
                 * FullBVGeneralCallOutputs planTerminated.
                 * @member {infinitusai.be.INullableBool|null|undefined} planTerminated
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.planTerminated = null;
    
                /**
                 * FullBVGeneralCallOutputs termDate.
                 * @member {infinitusai.be.IDate|null|undefined} termDate
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.termDate = null;
    
                /**
                 * FullBVGeneralCallOutputs isAdverseEvent.
                 * @member {infinitusai.be.INullableBool|null|undefined} isAdverseEvent
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.isAdverseEvent = null;
    
                /**
                 * FullBVGeneralCallOutputs adverseEventType.
                 * @member {infinitusai.be.IAdverseEventType|null|undefined} adverseEventType
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.adverseEventType = null;
    
                /**
                 * FullBVGeneralCallOutputs benefitPlanRenewalType.
                 * @member {infinitusai.be.IBenefitPlanRenewalType|null|undefined} benefitPlanRenewalType
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.benefitPlanRenewalType = null;
    
                /**
                 * FullBVGeneralCallOutputs fiscalRenewMonth.
                 * @member {infinitusai.be.IMonthType|null|undefined} fiscalRenewMonth
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.fiscalRenewMonth = null;
    
                /**
                 * FullBVGeneralCallOutputs groupNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} groupNumber
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.groupNumber = null;
    
                /**
                 * FullBVGeneralCallOutputs groupName.
                 * @member {infinitusai.be.INullableString|null|undefined} groupName
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.groupName = null;
    
                /**
                 * FullBVGeneralCallOutputs isCobraPlan.
                 * @member {infinitusai.be.INullableBool|null|undefined} isCobraPlan
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.isCobraPlan = null;
    
                /**
                 * FullBVGeneralCallOutputs cobraPaidThroughDate.
                 * @member {infinitusai.be.IDate|null|undefined} cobraPaidThroughDate
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.cobraPaidThroughDate = null;
    
                /**
                 * FullBVGeneralCallOutputs cobraHasGracePeriod.
                 * @member {infinitusai.be.INullableBool|null|undefined} cobraHasGracePeriod
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.cobraHasGracePeriod = null;
    
                /**
                 * FullBVGeneralCallOutputs cobraGracePeriod.
                 * @member {infinitusai.be.INullableString|null|undefined} cobraGracePeriod
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.cobraGracePeriod = null;
    
                /**
                 * FullBVGeneralCallOutputs isHealthExchangePlan.
                 * @member {infinitusai.be.INullableBool|null|undefined} isHealthExchangePlan
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.isHealthExchangePlan = null;
    
                /**
                 * FullBVGeneralCallOutputs patientReceivesSubsidies.
                 * @member {infinitusai.be.INullableBool|null|undefined} patientReceivesSubsidies
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.patientReceivesSubsidies = null;
    
                /**
                 * FullBVGeneralCallOutputs healthExchangePaidThroughDate.
                 * @member {infinitusai.be.IDate|null|undefined} healthExchangePaidThroughDate
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.healthExchangePaidThroughDate = null;
    
                /**
                 * FullBVGeneralCallOutputs healthExchangeHasGracePeriod.
                 * @member {infinitusai.be.INullableBool|null|undefined} healthExchangeHasGracePeriod
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.healthExchangeHasGracePeriod = null;
    
                /**
                 * FullBVGeneralCallOutputs healthExchangeGracePeriod.
                 * @member {infinitusai.be.INullableString|null|undefined} healthExchangeGracePeriod
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.healthExchangeGracePeriod = null;
    
                /**
                 * FullBVGeneralCallOutputs networkStatus.
                 * @member {infinitusai.be.INetworkStatusType|null|undefined} networkStatus
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.networkStatus = null;
    
                /**
                 * FullBVGeneralCallOutputs inNetworkConsiderationNotes.
                 * @member {infinitusai.be.INullableString|null|undefined} inNetworkConsiderationNotes
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.inNetworkConsiderationNotes = null;
    
                /**
                 * FullBVGeneralCallOutputs providerNetworkStatusOnly.
                 * @member {infinitusai.be.IProviderNetworkStatusOnlyType|null|undefined} providerNetworkStatusOnly
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.providerNetworkStatusOnly = null;
    
                /**
                 * FullBVGeneralCallOutputs lifetimeMaximumExists.
                 * @member {infinitusai.be.INullableBool|null|undefined} lifetimeMaximumExists
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.lifetimeMaximumExists = null;
    
                /**
                 * FullBVGeneralCallOutputs lifetimeMaximum.
                 * @member {infinitusai.be.IMoney|null|undefined} lifetimeMaximum
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.lifetimeMaximum = null;
    
                /**
                 * FullBVGeneralCallOutputs annualCapExists.
                 * @member {infinitusai.be.INullableBool|null|undefined} annualCapExists
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.annualCapExists = null;
    
                /**
                 * FullBVGeneralCallOutputs annualCap.
                 * @member {infinitusai.be.IMoney|null|undefined} annualCap
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.annualCap = null;
    
                /**
                 * FullBVGeneralCallOutputs codesValidAndBillable.
                 * @member {infinitusai.be.INullableBool|null|undefined} codesValidAndBillable
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.codesValidAndBillable = null;
    
                /**
                 * FullBVGeneralCallOutputs uncoveredCodes.
                 * @member {infinitusai.be.IMultiselectCodesType|null|undefined} uncoveredCodes
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.uncoveredCodes = null;
    
                /**
                 * FullBVGeneralCallOutputs uncoveredCodesStr.
                 * @member {Array.<string>} uncoveredCodesStr
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.uncoveredCodesStr = $util.emptyArray;
    
                /**
                 * FullBVGeneralCallOutputs isSelfFunded.
                 * @member {infinitusai.be.INullableBool|null|undefined} isSelfFunded
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.isSelfFunded = null;
    
                /**
                 * FullBVGeneralCallOutputs lifetimeMaximumMet.
                 * @member {infinitusai.be.IMoney|null|undefined} lifetimeMaximumMet
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.lifetimeMaximumMet = null;
    
                /**
                 * FullBVGeneralCallOutputs annualCapMet.
                 * @member {infinitusai.be.IMoney|null|undefined} annualCapMet
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.annualCapMet = null;
    
                /**
                 * FullBVGeneralCallOutputs reasonForNonCoverageType.
                 * @member {infinitusai.be.IReasonForNonCoverageType|null|undefined} reasonForNonCoverageType
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.reasonForNonCoverageType = null;
    
                /**
                 * FullBVGeneralCallOutputs hasDeductible.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasDeductible
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.hasDeductible = null;
    
                /**
                 * FullBVGeneralCallOutputs deductibleTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleTotal
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.deductibleTotal = null;
    
                /**
                 * FullBVGeneralCallOutputs deductibleMet.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleMet
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.deductibleMet = null;
    
                /**
                 * FullBVGeneralCallOutputs hasSpendDown.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasSpendDown
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.hasSpendDown = null;
    
                /**
                 * FullBVGeneralCallOutputs spendDownTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} spendDownTotal
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.spendDownTotal = null;
    
                /**
                 * FullBVGeneralCallOutputs spendDownMet.
                 * @member {infinitusai.be.IMoney|null|undefined} spendDownMet
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.spendDownMet = null;
    
                /**
                 * FullBVGeneralCallOutputs isDeductibleIncludedInOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} isDeductibleIncludedInOop
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.isDeductibleIncludedInOop = null;
    
                /**
                 * FullBVGeneralCallOutputs hasOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasOop
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.hasOop = null;
    
                /**
                 * FullBVGeneralCallOutputs oopTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} oopTotal
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.oopTotal = null;
    
                /**
                 * FullBVGeneralCallOutputs oopMet.
                 * @member {infinitusai.be.IMoney|null|undefined} oopMet
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.oopMet = null;
    
                /**
                 * FullBVGeneralCallOutputs hasFamilyDeductible.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasFamilyDeductible
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.hasFamilyDeductible = null;
    
                /**
                 * FullBVGeneralCallOutputs familyDeductibleTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} familyDeductibleTotal
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.familyDeductibleTotal = null;
    
                /**
                 * FullBVGeneralCallOutputs familyDeductibleMet.
                 * @member {infinitusai.be.IMoney|null|undefined} familyDeductibleMet
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.familyDeductibleMet = null;
    
                /**
                 * FullBVGeneralCallOutputs hasFamilyOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasFamilyOop
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.hasFamilyOop = null;
    
                /**
                 * FullBVGeneralCallOutputs familyOopTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} familyOopTotal
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.familyOopTotal = null;
    
                /**
                 * FullBVGeneralCallOutputs familyOopMet.
                 * @member {infinitusai.be.IMoney|null|undefined} familyOopMet
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.familyOopMet = null;
    
                /**
                 * FullBVGeneralCallOutputs doesCopayApplyToOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} doesCopayApplyToOop
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.doesCopayApplyToOop = null;
    
                /**
                 * FullBVGeneralCallOutputs copayWaivedAfterOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} copayWaivedAfterOop
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.copayWaivedAfterOop = null;
    
                /**
                 * FullBVGeneralCallOutputs responsibleForMultipleCopays.
                 * @member {infinitusai.be.INullableBool|null|undefined} responsibleForMultipleCopays
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.responsibleForMultipleCopays = null;
    
                /**
                 * FullBVGeneralCallOutputs hasStandardPlanLetter.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasStandardPlanLetter
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.hasStandardPlanLetter = null;
    
                /**
                 * FullBVGeneralCallOutputs standardPlanLetter.
                 * @member {infinitusai.be.IStandardPlanLetter|null|undefined} standardPlanLetter
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.standardPlanLetter = null;
    
                /**
                 * FullBVGeneralCallOutputs differentBenefitsForOutpatient.
                 * @member {infinitusai.be.INullableBool|null|undefined} differentBenefitsForOutpatient
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.differentBenefitsForOutpatient = null;
    
                /**
                 * FullBVGeneralCallOutputs pbmExists.
                 * @member {infinitusai.be.INullableBool|null|undefined} pbmExists
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.pbmExists = null;
    
                /**
                 * FullBVGeneralCallOutputs pbmName.
                 * @member {infinitusai.be.INullableString|null|undefined} pbmName
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.pbmName = null;
    
                /**
                 * FullBVGeneralCallOutputs pbmPhone.
                 * @member {infinitusai.be.INullableString|null|undefined} pbmPhone
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.pbmPhone = null;
    
                /**
                 * FullBVGeneralCallOutputs isAccumulatorPlan.
                 * @member {infinitusai.be.INullableBool|null|undefined} isAccumulatorPlan
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.isAccumulatorPlan = null;
    
                /**
                 * FullBVGeneralCallOutputs isMaximizerPlan.
                 * @member {infinitusai.be.INullableBool|null|undefined} isMaximizerPlan
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 */
                FullBVGeneralCallOutputs.prototype.isMaximizerPlan = null;
    
                /**
                 * Creates a new FullBVGeneralCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVGeneralCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FullBVGeneralCallOutputs} FullBVGeneralCallOutputs instance
                 */
                FullBVGeneralCallOutputs.create = function create(properties) {
                    return new FullBVGeneralCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified FullBVGeneralCallOutputs message. Does not implicitly {@link infinitusai.be.FullBVGeneralCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVGeneralCallOutputs} message FullBVGeneralCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVGeneralCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.agentName != null && Object.hasOwnProperty.call(message, "agentName"))
                        $root.infinitusai.be.NullableString.encode(message.agentName, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.referenceNumber != null && Object.hasOwnProperty.call(message, "referenceNumber"))
                        $root.infinitusai.be.NullableString.encode(message.referenceNumber, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.hasOtherInsuranceOnFile != null && Object.hasOwnProperty.call(message, "hasOtherInsuranceOnFile"))
                        $root.infinitusai.be.NullableBool.encode(message.hasOtherInsuranceOnFile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.policyType != null && Object.hasOwnProperty.call(message, "policyType"))
                        $root.infinitusai.be.PolicyType.encode(message.policyType, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.planType != null && Object.hasOwnProperty.call(message, "planType"))
                        $root.infinitusai.be.PlanType.encode(message.planType, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.followsMedicareGuidelines != null && Object.hasOwnProperty.call(message, "followsMedicareGuidelines"))
                        $root.infinitusai.be.NullableBool.encode(message.followsMedicareGuidelines, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                        $root.infinitusai.be.NullableString.encode(message.planName, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.effectiveDate != null && Object.hasOwnProperty.call(message, "effectiveDate"))
                        $root.infinitusai.be.Date.encode(message.effectiveDate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.termDate != null && Object.hasOwnProperty.call(message, "termDate"))
                        $root.infinitusai.be.Date.encode(message.termDate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.fiscalRenewMonth != null && Object.hasOwnProperty.call(message, "fiscalRenewMonth"))
                        $root.infinitusai.be.MonthType.encode(message.fiscalRenewMonth, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber"))
                        $root.infinitusai.be.NullableString.encode(message.groupNumber, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                        $root.infinitusai.be.NetworkStatusType.encode(message.networkStatus, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.lifetimeMaximumExists != null && Object.hasOwnProperty.call(message, "lifetimeMaximumExists"))
                        $root.infinitusai.be.NullableBool.encode(message.lifetimeMaximumExists, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.lifetimeMaximum != null && Object.hasOwnProperty.call(message, "lifetimeMaximum"))
                        $root.infinitusai.be.Money.encode(message.lifetimeMaximum, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.annualCapExists != null && Object.hasOwnProperty.call(message, "annualCapExists"))
                        $root.infinitusai.be.NullableBool.encode(message.annualCapExists, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.annualCap != null && Object.hasOwnProperty.call(message, "annualCap"))
                        $root.infinitusai.be.Money.encode(message.annualCap, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.codesValidAndBillable != null && Object.hasOwnProperty.call(message, "codesValidAndBillable"))
                        $root.infinitusai.be.NullableBool.encode(message.codesValidAndBillable, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                    if (message.isSelfFunded != null && Object.hasOwnProperty.call(message, "isSelfFunded"))
                        $root.infinitusai.be.NullableBool.encode(message.isSelfFunded, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                    if (message.lifetimeMaximumMet != null && Object.hasOwnProperty.call(message, "lifetimeMaximumMet"))
                        $root.infinitusai.be.Money.encode(message.lifetimeMaximumMet, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    if (message.annualCapMet != null && Object.hasOwnProperty.call(message, "annualCapMet"))
                        $root.infinitusai.be.Money.encode(message.annualCapMet, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                    if (message.hasDeductible != null && Object.hasOwnProperty.call(message, "hasDeductible"))
                        $root.infinitusai.be.NullableBool.encode(message.hasDeductible, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                    if (message.deductibleTotal != null && Object.hasOwnProperty.call(message, "deductibleTotal"))
                        $root.infinitusai.be.Money.encode(message.deductibleTotal, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                    if (message.deductibleMet != null && Object.hasOwnProperty.call(message, "deductibleMet"))
                        $root.infinitusai.be.Money.encode(message.deductibleMet, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                    if (message.isDeductibleIncludedInOop != null && Object.hasOwnProperty.call(message, "isDeductibleIncludedInOop"))
                        $root.infinitusai.be.NullableBool.encode(message.isDeductibleIncludedInOop, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                    if (message.hasOop != null && Object.hasOwnProperty.call(message, "hasOop"))
                        $root.infinitusai.be.NullableBool.encode(message.hasOop, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                    if (message.oopTotal != null && Object.hasOwnProperty.call(message, "oopTotal"))
                        $root.infinitusai.be.Money.encode(message.oopTotal, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                    if (message.oopMet != null && Object.hasOwnProperty.call(message, "oopMet"))
                        $root.infinitusai.be.Money.encode(message.oopMet, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                    if (message.hasFamilyDeductible != null && Object.hasOwnProperty.call(message, "hasFamilyDeductible"))
                        $root.infinitusai.be.NullableBool.encode(message.hasFamilyDeductible, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                    if (message.familyDeductibleTotal != null && Object.hasOwnProperty.call(message, "familyDeductibleTotal"))
                        $root.infinitusai.be.Money.encode(message.familyDeductibleTotal, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                    if (message.familyDeductibleMet != null && Object.hasOwnProperty.call(message, "familyDeductibleMet"))
                        $root.infinitusai.be.Money.encode(message.familyDeductibleMet, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                    if (message.hasFamilyOop != null && Object.hasOwnProperty.call(message, "hasFamilyOop"))
                        $root.infinitusai.be.NullableBool.encode(message.hasFamilyOop, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                    if (message.familyOopTotal != null && Object.hasOwnProperty.call(message, "familyOopTotal"))
                        $root.infinitusai.be.Money.encode(message.familyOopTotal, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                    if (message.familyOopMet != null && Object.hasOwnProperty.call(message, "familyOopMet"))
                        $root.infinitusai.be.Money.encode(message.familyOopMet, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                    if (message.otherInsuranceNotes != null && Object.hasOwnProperty.call(message, "otherInsuranceNotes"))
                        $root.infinitusai.be.NullableString.encode(message.otherInsuranceNotes, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                    if (message.doesCopayApplyToOop != null && Object.hasOwnProperty.call(message, "doesCopayApplyToOop"))
                        $root.infinitusai.be.NullableBool.encode(message.doesCopayApplyToOop, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                    if (message.copayWaivedAfterOop != null && Object.hasOwnProperty.call(message, "copayWaivedAfterOop"))
                        $root.infinitusai.be.NullableBool.encode(message.copayWaivedAfterOop, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                    if (message.uncoveredCodes != null && Object.hasOwnProperty.call(message, "uncoveredCodes"))
                        $root.infinitusai.be.MultiselectCodesType.encode(message.uncoveredCodes, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                    if (message.inNetworkConsiderationNotes != null && Object.hasOwnProperty.call(message, "inNetworkConsiderationNotes"))
                        $root.infinitusai.be.NullableString.encode(message.inNetworkConsiderationNotes, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                    if (message.responsibleForMultipleCopays != null && Object.hasOwnProperty.call(message, "responsibleForMultipleCopays"))
                        $root.infinitusai.be.NullableBool.encode(message.responsibleForMultipleCopays, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                    if (message.otherInsuranceName != null && Object.hasOwnProperty.call(message, "otherInsuranceName"))
                        $root.infinitusai.be.NullableString.encode(message.otherInsuranceName, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                    if (message.currentPlanPriority != null && Object.hasOwnProperty.call(message, "currentPlanPriority"))
                        $root.infinitusai.be.CurrentPlanPriority.encode(message.currentPlanPriority, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                    if (message.benefitCoordination != null && Object.hasOwnProperty.call(message, "benefitCoordination"))
                        $root.infinitusai.be.BenefitCoordination.encode(message.benefitCoordination, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                    if (message.willPlanCoverAsPrimary != null && Object.hasOwnProperty.call(message, "willPlanCoverAsPrimary"))
                        $root.infinitusai.be.NullableBool.encode(message.willPlanCoverAsPrimary, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
                    if (message.partBDeductibleCovered != null && Object.hasOwnProperty.call(message, "partBDeductibleCovered"))
                        $root.infinitusai.be.NullableBool.encode(message.partBDeductibleCovered, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                    if (message.planTerminated != null && Object.hasOwnProperty.call(message, "planTerminated"))
                        $root.infinitusai.be.NullableBool.encode(message.planTerminated, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                    if (message.uncoveredCodesStr != null && message.uncoveredCodesStr.length)
                        for (var i = 0; i < message.uncoveredCodesStr.length; ++i)
                            writer.uint32(/* id 48, wireType 2 =*/386).string(message.uncoveredCodesStr[i]);
                    if (message.isAdverseEvent != null && Object.hasOwnProperty.call(message, "isAdverseEvent"))
                        $root.infinitusai.be.NullableBool.encode(message.isAdverseEvent, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                    if (message.adverseEventType != null && Object.hasOwnProperty.call(message, "adverseEventType"))
                        $root.infinitusai.be.AdverseEventType.encode(message.adverseEventType, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                    if (message.hasSpendDown != null && Object.hasOwnProperty.call(message, "hasSpendDown"))
                        $root.infinitusai.be.NullableBool.encode(message.hasSpendDown, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                    if (message.spendDownTotal != null && Object.hasOwnProperty.call(message, "spendDownTotal"))
                        $root.infinitusai.be.Money.encode(message.spendDownTotal, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
                    if (message.spendDownMet != null && Object.hasOwnProperty.call(message, "spendDownMet"))
                        $root.infinitusai.be.Money.encode(message.spendDownMet, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
                    if (message.reasonForNonCoverageType != null && Object.hasOwnProperty.call(message, "reasonForNonCoverageType"))
                        $root.infinitusai.be.ReasonForNonCoverageType.encode(message.reasonForNonCoverageType, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
                    if (message.hasStandardPlanLetter != null && Object.hasOwnProperty.call(message, "hasStandardPlanLetter"))
                        $root.infinitusai.be.NullableBool.encode(message.hasStandardPlanLetter, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
                    if (message.standardPlanLetter != null && Object.hasOwnProperty.call(message, "standardPlanLetter"))
                        $root.infinitusai.be.StandardPlanLetter.encode(message.standardPlanLetter, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
                    if (message.benefitPlanRenewalType != null && Object.hasOwnProperty.call(message, "benefitPlanRenewalType"))
                        $root.infinitusai.be.BenefitPlanRenewalType.encode(message.benefitPlanRenewalType, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
                    if (message.differentBenefitsForOutpatient != null && Object.hasOwnProperty.call(message, "differentBenefitsForOutpatient"))
                        $root.infinitusai.be.NullableBool.encode(message.differentBenefitsForOutpatient, writer.uint32(/* id 58, wireType 2 =*/466).fork()).ldelim();
                    if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                        $root.infinitusai.be.NullableString.encode(message.groupName, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
                    if (message.isCobraPlan != null && Object.hasOwnProperty.call(message, "isCobraPlan"))
                        $root.infinitusai.be.NullableBool.encode(message.isCobraPlan, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
                    if (message.cobraPaidThroughDate != null && Object.hasOwnProperty.call(message, "cobraPaidThroughDate"))
                        $root.infinitusai.be.Date.encode(message.cobraPaidThroughDate, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
                    if (message.cobraHasGracePeriod != null && Object.hasOwnProperty.call(message, "cobraHasGracePeriod"))
                        $root.infinitusai.be.NullableBool.encode(message.cobraHasGracePeriod, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
                    if (message.cobraGracePeriod != null && Object.hasOwnProperty.call(message, "cobraGracePeriod"))
                        $root.infinitusai.be.NullableString.encode(message.cobraGracePeriod, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
                    if (message.isHealthExchangePlan != null && Object.hasOwnProperty.call(message, "isHealthExchangePlan"))
                        $root.infinitusai.be.NullableBool.encode(message.isHealthExchangePlan, writer.uint32(/* id 64, wireType 2 =*/514).fork()).ldelim();
                    if (message.patientReceivesSubsidies != null && Object.hasOwnProperty.call(message, "patientReceivesSubsidies"))
                        $root.infinitusai.be.NullableBool.encode(message.patientReceivesSubsidies, writer.uint32(/* id 65, wireType 2 =*/522).fork()).ldelim();
                    if (message.healthExchangePaidThroughDate != null && Object.hasOwnProperty.call(message, "healthExchangePaidThroughDate"))
                        $root.infinitusai.be.Date.encode(message.healthExchangePaidThroughDate, writer.uint32(/* id 66, wireType 2 =*/530).fork()).ldelim();
                    if (message.healthExchangeHasGracePeriod != null && Object.hasOwnProperty.call(message, "healthExchangeHasGracePeriod"))
                        $root.infinitusai.be.NullableBool.encode(message.healthExchangeHasGracePeriod, writer.uint32(/* id 67, wireType 2 =*/538).fork()).ldelim();
                    if (message.healthExchangeGracePeriod != null && Object.hasOwnProperty.call(message, "healthExchangeGracePeriod"))
                        $root.infinitusai.be.NullableString.encode(message.healthExchangeGracePeriod, writer.uint32(/* id 68, wireType 2 =*/546).fork()).ldelim();
                    if (message.providerNetworkStatusOnly != null && Object.hasOwnProperty.call(message, "providerNetworkStatusOnly"))
                        $root.infinitusai.be.ProviderNetworkStatusOnlyType.encode(message.providerNetworkStatusOnly, writer.uint32(/* id 69, wireType 2 =*/554).fork()).ldelim();
                    if (message.pbmExists != null && Object.hasOwnProperty.call(message, "pbmExists"))
                        $root.infinitusai.be.NullableBool.encode(message.pbmExists, writer.uint32(/* id 70, wireType 2 =*/562).fork()).ldelim();
                    if (message.pbmName != null && Object.hasOwnProperty.call(message, "pbmName"))
                        $root.infinitusai.be.NullableString.encode(message.pbmName, writer.uint32(/* id 71, wireType 2 =*/570).fork()).ldelim();
                    if (message.pbmPhone != null && Object.hasOwnProperty.call(message, "pbmPhone"))
                        $root.infinitusai.be.NullableString.encode(message.pbmPhone, writer.uint32(/* id 72, wireType 2 =*/578).fork()).ldelim();
                    if (message.newPlanAvailable != null && Object.hasOwnProperty.call(message, "newPlanAvailable"))
                        $root.infinitusai.be.NullableBool.encode(message.newPlanAvailable, writer.uint32(/* id 73, wireType 2 =*/586).fork()).ldelim();
                    if (message.newPlanSubscriberId != null && Object.hasOwnProperty.call(message, "newPlanSubscriberId"))
                        $root.infinitusai.be.NullableString.encode(message.newPlanSubscriberId, writer.uint32(/* id 74, wireType 2 =*/594).fork()).ldelim();
                    if (message.newPlanEffectiveDate != null && Object.hasOwnProperty.call(message, "newPlanEffectiveDate"))
                        $root.infinitusai.be.Date.encode(message.newPlanEffectiveDate, writer.uint32(/* id 75, wireType 2 =*/602).fork()).ldelim();
                    if (message.isAccumulatorPlan != null && Object.hasOwnProperty.call(message, "isAccumulatorPlan"))
                        $root.infinitusai.be.NullableBool.encode(message.isAccumulatorPlan, writer.uint32(/* id 76, wireType 2 =*/610).fork()).ldelim();
                    if (message.isMaximizerPlan != null && Object.hasOwnProperty.call(message, "isMaximizerPlan"))
                        $root.infinitusai.be.NullableBool.encode(message.isMaximizerPlan, writer.uint32(/* id 77, wireType 2 =*/618).fork()).ldelim();
                    if (message.otherInsuranceExists != null && Object.hasOwnProperty.call(message, "otherInsuranceExists"))
                        $root.infinitusai.be.NullableBool.encode(message.otherInsuranceExists, writer.uint32(/* id 78, wireType 2 =*/626).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FullBVGeneralCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.FullBVGeneralCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVGeneralCallOutputs} message FullBVGeneralCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVGeneralCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FullBVGeneralCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FullBVGeneralCallOutputs} FullBVGeneralCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVGeneralCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FullBVGeneralCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.agentName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.referenceNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.hasOtherInsuranceOnFile = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 42: {
                                message.otherInsuranceName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 43: {
                                message.currentPlanPriority = $root.infinitusai.be.CurrentPlanPriority.decode(reader, reader.uint32());
                                break;
                            }
                        case 44: {
                                message.benefitCoordination = $root.infinitusai.be.BenefitCoordination.decode(reader, reader.uint32());
                                break;
                            }
                        case 45: {
                                message.willPlanCoverAsPrimary = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 46: {
                                message.partBDeductibleCovered = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 36: {
                                message.otherInsuranceNotes = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.policyType = $root.infinitusai.be.PolicyType.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.planType = $root.infinitusai.be.PlanType.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.followsMedicareGuidelines = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.planName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.effectiveDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 73: {
                                message.newPlanAvailable = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 74: {
                                message.newPlanSubscriberId = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 75: {
                                message.newPlanEffectiveDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 78: {
                                message.otherInsuranceExists = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 47: {
                                message.planTerminated = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.termDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 49: {
                                message.isAdverseEvent = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 50: {
                                message.adverseEventType = $root.infinitusai.be.AdverseEventType.decode(reader, reader.uint32());
                                break;
                            }
                        case 57: {
                                message.benefitPlanRenewalType = $root.infinitusai.be.BenefitPlanRenewalType.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.fiscalRenewMonth = $root.infinitusai.be.MonthType.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.groupNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 59: {
                                message.groupName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 60: {
                                message.isCobraPlan = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 61: {
                                message.cobraPaidThroughDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 62: {
                                message.cobraHasGracePeriod = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 63: {
                                message.cobraGracePeriod = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 64: {
                                message.isHealthExchangePlan = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 65: {
                                message.patientReceivesSubsidies = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 66: {
                                message.healthExchangePaidThroughDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 67: {
                                message.healthExchangeHasGracePeriod = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 68: {
                                message.healthExchangeGracePeriod = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.networkStatus = $root.infinitusai.be.NetworkStatusType.decode(reader, reader.uint32());
                                break;
                            }
                        case 40: {
                                message.inNetworkConsiderationNotes = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 69: {
                                message.providerNetworkStatusOnly = $root.infinitusai.be.ProviderNetworkStatusOnlyType.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.lifetimeMaximumExists = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.lifetimeMaximum = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.annualCapExists = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                message.annualCap = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.codesValidAndBillable = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 39: {
                                message.uncoveredCodes = $root.infinitusai.be.MultiselectCodesType.decode(reader, reader.uint32());
                                break;
                            }
                        case 48: {
                                if (!(message.uncoveredCodesStr && message.uncoveredCodesStr.length))
                                    message.uncoveredCodesStr = [];
                                message.uncoveredCodesStr.push(reader.string());
                                break;
                            }
                        case 20: {
                                message.isSelfFunded = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 21: {
                                message.lifetimeMaximumMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 22: {
                                message.annualCapMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 54: {
                                message.reasonForNonCoverageType = $root.infinitusai.be.ReasonForNonCoverageType.decode(reader, reader.uint32());
                                break;
                            }
                        case 23: {
                                message.hasDeductible = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 24: {
                                message.deductibleTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 25: {
                                message.deductibleMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 51: {
                                message.hasSpendDown = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 52: {
                                message.spendDownTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 53: {
                                message.spendDownMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 26: {
                                message.isDeductibleIncludedInOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 27: {
                                message.hasOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 28: {
                                message.oopTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 29: {
                                message.oopMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 30: {
                                message.hasFamilyDeductible = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 31: {
                                message.familyDeductibleTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 32: {
                                message.familyDeductibleMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 33: {
                                message.hasFamilyOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 34: {
                                message.familyOopTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 35: {
                                message.familyOopMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 37: {
                                message.doesCopayApplyToOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 38: {
                                message.copayWaivedAfterOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 41: {
                                message.responsibleForMultipleCopays = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 55: {
                                message.hasStandardPlanLetter = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 56: {
                                message.standardPlanLetter = $root.infinitusai.be.StandardPlanLetter.decode(reader, reader.uint32());
                                break;
                            }
                        case 58: {
                                message.differentBenefitsForOutpatient = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 70: {
                                message.pbmExists = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 71: {
                                message.pbmName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 72: {
                                message.pbmPhone = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 76: {
                                message.isAccumulatorPlan = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 77: {
                                message.isMaximizerPlan = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FullBVGeneralCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FullBVGeneralCallOutputs} FullBVGeneralCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVGeneralCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FullBVGeneralCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FullBVGeneralCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.agentName != null && message.hasOwnProperty("agentName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.agentName);
                        if (error)
                            return "agentName." + error;
                    }
                    if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.referenceNumber);
                        if (error)
                            return "referenceNumber." + error;
                    }
                    if (message.hasOtherInsuranceOnFile != null && message.hasOwnProperty("hasOtherInsuranceOnFile")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasOtherInsuranceOnFile);
                        if (error)
                            return "hasOtherInsuranceOnFile." + error;
                    }
                    if (message.otherInsuranceName != null && message.hasOwnProperty("otherInsuranceName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.otherInsuranceName);
                        if (error)
                            return "otherInsuranceName." + error;
                    }
                    if (message.currentPlanPriority != null && message.hasOwnProperty("currentPlanPriority")) {
                        var error = $root.infinitusai.be.CurrentPlanPriority.verify(message.currentPlanPriority);
                        if (error)
                            return "currentPlanPriority." + error;
                    }
                    if (message.benefitCoordination != null && message.hasOwnProperty("benefitCoordination")) {
                        var error = $root.infinitusai.be.BenefitCoordination.verify(message.benefitCoordination);
                        if (error)
                            return "benefitCoordination." + error;
                    }
                    if (message.willPlanCoverAsPrimary != null && message.hasOwnProperty("willPlanCoverAsPrimary")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.willPlanCoverAsPrimary);
                        if (error)
                            return "willPlanCoverAsPrimary." + error;
                    }
                    if (message.partBDeductibleCovered != null && message.hasOwnProperty("partBDeductibleCovered")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.partBDeductibleCovered);
                        if (error)
                            return "partBDeductibleCovered." + error;
                    }
                    if (message.otherInsuranceNotes != null && message.hasOwnProperty("otherInsuranceNotes")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.otherInsuranceNotes);
                        if (error)
                            return "otherInsuranceNotes." + error;
                    }
                    if (message.policyType != null && message.hasOwnProperty("policyType")) {
                        var error = $root.infinitusai.be.PolicyType.verify(message.policyType);
                        if (error)
                            return "policyType." + error;
                    }
                    if (message.planType != null && message.hasOwnProperty("planType")) {
                        var error = $root.infinitusai.be.PlanType.verify(message.planType);
                        if (error)
                            return "planType." + error;
                    }
                    if (message.followsMedicareGuidelines != null && message.hasOwnProperty("followsMedicareGuidelines")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.followsMedicareGuidelines);
                        if (error)
                            return "followsMedicareGuidelines." + error;
                    }
                    if (message.planName != null && message.hasOwnProperty("planName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.planName);
                        if (error)
                            return "planName." + error;
                    }
                    if (message.effectiveDate != null && message.hasOwnProperty("effectiveDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.effectiveDate);
                        if (error)
                            return "effectiveDate." + error;
                    }
                    if (message.newPlanAvailable != null && message.hasOwnProperty("newPlanAvailable")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.newPlanAvailable);
                        if (error)
                            return "newPlanAvailable." + error;
                    }
                    if (message.newPlanSubscriberId != null && message.hasOwnProperty("newPlanSubscriberId")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.newPlanSubscriberId);
                        if (error)
                            return "newPlanSubscriberId." + error;
                    }
                    if (message.newPlanEffectiveDate != null && message.hasOwnProperty("newPlanEffectiveDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.newPlanEffectiveDate);
                        if (error)
                            return "newPlanEffectiveDate." + error;
                    }
                    if (message.otherInsuranceExists != null && message.hasOwnProperty("otherInsuranceExists")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.otherInsuranceExists);
                        if (error)
                            return "otherInsuranceExists." + error;
                    }
                    if (message.planTerminated != null && message.hasOwnProperty("planTerminated")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.planTerminated);
                        if (error)
                            return "planTerminated." + error;
                    }
                    if (message.termDate != null && message.hasOwnProperty("termDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.termDate);
                        if (error)
                            return "termDate." + error;
                    }
                    if (message.isAdverseEvent != null && message.hasOwnProperty("isAdverseEvent")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isAdverseEvent);
                        if (error)
                            return "isAdverseEvent." + error;
                    }
                    if (message.adverseEventType != null && message.hasOwnProperty("adverseEventType")) {
                        var error = $root.infinitusai.be.AdverseEventType.verify(message.adverseEventType);
                        if (error)
                            return "adverseEventType." + error;
                    }
                    if (message.benefitPlanRenewalType != null && message.hasOwnProperty("benefitPlanRenewalType")) {
                        var error = $root.infinitusai.be.BenefitPlanRenewalType.verify(message.benefitPlanRenewalType);
                        if (error)
                            return "benefitPlanRenewalType." + error;
                    }
                    if (message.fiscalRenewMonth != null && message.hasOwnProperty("fiscalRenewMonth")) {
                        var error = $root.infinitusai.be.MonthType.verify(message.fiscalRenewMonth);
                        if (error)
                            return "fiscalRenewMonth." + error;
                    }
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.groupNumber);
                        if (error)
                            return "groupNumber." + error;
                    }
                    if (message.groupName != null && message.hasOwnProperty("groupName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.groupName);
                        if (error)
                            return "groupName." + error;
                    }
                    if (message.isCobraPlan != null && message.hasOwnProperty("isCobraPlan")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isCobraPlan);
                        if (error)
                            return "isCobraPlan." + error;
                    }
                    if (message.cobraPaidThroughDate != null && message.hasOwnProperty("cobraPaidThroughDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.cobraPaidThroughDate);
                        if (error)
                            return "cobraPaidThroughDate." + error;
                    }
                    if (message.cobraHasGracePeriod != null && message.hasOwnProperty("cobraHasGracePeriod")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.cobraHasGracePeriod);
                        if (error)
                            return "cobraHasGracePeriod." + error;
                    }
                    if (message.cobraGracePeriod != null && message.hasOwnProperty("cobraGracePeriod")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.cobraGracePeriod);
                        if (error)
                            return "cobraGracePeriod." + error;
                    }
                    if (message.isHealthExchangePlan != null && message.hasOwnProperty("isHealthExchangePlan")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isHealthExchangePlan);
                        if (error)
                            return "isHealthExchangePlan." + error;
                    }
                    if (message.patientReceivesSubsidies != null && message.hasOwnProperty("patientReceivesSubsidies")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.patientReceivesSubsidies);
                        if (error)
                            return "patientReceivesSubsidies." + error;
                    }
                    if (message.healthExchangePaidThroughDate != null && message.hasOwnProperty("healthExchangePaidThroughDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.healthExchangePaidThroughDate);
                        if (error)
                            return "healthExchangePaidThroughDate." + error;
                    }
                    if (message.healthExchangeHasGracePeriod != null && message.hasOwnProperty("healthExchangeHasGracePeriod")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.healthExchangeHasGracePeriod);
                        if (error)
                            return "healthExchangeHasGracePeriod." + error;
                    }
                    if (message.healthExchangeGracePeriod != null && message.hasOwnProperty("healthExchangeGracePeriod")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.healthExchangeGracePeriod);
                        if (error)
                            return "healthExchangeGracePeriod." + error;
                    }
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus")) {
                        var error = $root.infinitusai.be.NetworkStatusType.verify(message.networkStatus);
                        if (error)
                            return "networkStatus." + error;
                    }
                    if (message.inNetworkConsiderationNotes != null && message.hasOwnProperty("inNetworkConsiderationNotes")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.inNetworkConsiderationNotes);
                        if (error)
                            return "inNetworkConsiderationNotes." + error;
                    }
                    if (message.providerNetworkStatusOnly != null && message.hasOwnProperty("providerNetworkStatusOnly")) {
                        var error = $root.infinitusai.be.ProviderNetworkStatusOnlyType.verify(message.providerNetworkStatusOnly);
                        if (error)
                            return "providerNetworkStatusOnly." + error;
                    }
                    if (message.lifetimeMaximumExists != null && message.hasOwnProperty("lifetimeMaximumExists")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.lifetimeMaximumExists);
                        if (error)
                            return "lifetimeMaximumExists." + error;
                    }
                    if (message.lifetimeMaximum != null && message.hasOwnProperty("lifetimeMaximum")) {
                        var error = $root.infinitusai.be.Money.verify(message.lifetimeMaximum);
                        if (error)
                            return "lifetimeMaximum." + error;
                    }
                    if (message.annualCapExists != null && message.hasOwnProperty("annualCapExists")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.annualCapExists);
                        if (error)
                            return "annualCapExists." + error;
                    }
                    if (message.annualCap != null && message.hasOwnProperty("annualCap")) {
                        var error = $root.infinitusai.be.Money.verify(message.annualCap);
                        if (error)
                            return "annualCap." + error;
                    }
                    if (message.codesValidAndBillable != null && message.hasOwnProperty("codesValidAndBillable")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.codesValidAndBillable);
                        if (error)
                            return "codesValidAndBillable." + error;
                    }
                    if (message.uncoveredCodes != null && message.hasOwnProperty("uncoveredCodes")) {
                        var error = $root.infinitusai.be.MultiselectCodesType.verify(message.uncoveredCodes);
                        if (error)
                            return "uncoveredCodes." + error;
                    }
                    if (message.uncoveredCodesStr != null && message.hasOwnProperty("uncoveredCodesStr")) {
                        if (!Array.isArray(message.uncoveredCodesStr))
                            return "uncoveredCodesStr: array expected";
                        for (var i = 0; i < message.uncoveredCodesStr.length; ++i)
                            if (!$util.isString(message.uncoveredCodesStr[i]))
                                return "uncoveredCodesStr: string[] expected";
                    }
                    if (message.isSelfFunded != null && message.hasOwnProperty("isSelfFunded")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isSelfFunded);
                        if (error)
                            return "isSelfFunded." + error;
                    }
                    if (message.lifetimeMaximumMet != null && message.hasOwnProperty("lifetimeMaximumMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.lifetimeMaximumMet);
                        if (error)
                            return "lifetimeMaximumMet." + error;
                    }
                    if (message.annualCapMet != null && message.hasOwnProperty("annualCapMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.annualCapMet);
                        if (error)
                            return "annualCapMet." + error;
                    }
                    if (message.reasonForNonCoverageType != null && message.hasOwnProperty("reasonForNonCoverageType")) {
                        var error = $root.infinitusai.be.ReasonForNonCoverageType.verify(message.reasonForNonCoverageType);
                        if (error)
                            return "reasonForNonCoverageType." + error;
                    }
                    if (message.hasDeductible != null && message.hasOwnProperty("hasDeductible")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasDeductible);
                        if (error)
                            return "hasDeductible." + error;
                    }
                    if (message.deductibleTotal != null && message.hasOwnProperty("deductibleTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleTotal);
                        if (error)
                            return "deductibleTotal." + error;
                    }
                    if (message.deductibleMet != null && message.hasOwnProperty("deductibleMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleMet);
                        if (error)
                            return "deductibleMet." + error;
                    }
                    if (message.hasSpendDown != null && message.hasOwnProperty("hasSpendDown")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasSpendDown);
                        if (error)
                            return "hasSpendDown." + error;
                    }
                    if (message.spendDownTotal != null && message.hasOwnProperty("spendDownTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.spendDownTotal);
                        if (error)
                            return "spendDownTotal." + error;
                    }
                    if (message.spendDownMet != null && message.hasOwnProperty("spendDownMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.spendDownMet);
                        if (error)
                            return "spendDownMet." + error;
                    }
                    if (message.isDeductibleIncludedInOop != null && message.hasOwnProperty("isDeductibleIncludedInOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isDeductibleIncludedInOop);
                        if (error)
                            return "isDeductibleIncludedInOop." + error;
                    }
                    if (message.hasOop != null && message.hasOwnProperty("hasOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasOop);
                        if (error)
                            return "hasOop." + error;
                    }
                    if (message.oopTotal != null && message.hasOwnProperty("oopTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.oopTotal);
                        if (error)
                            return "oopTotal." + error;
                    }
                    if (message.oopMet != null && message.hasOwnProperty("oopMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.oopMet);
                        if (error)
                            return "oopMet." + error;
                    }
                    if (message.hasFamilyDeductible != null && message.hasOwnProperty("hasFamilyDeductible")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasFamilyDeductible);
                        if (error)
                            return "hasFamilyDeductible." + error;
                    }
                    if (message.familyDeductibleTotal != null && message.hasOwnProperty("familyDeductibleTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyDeductibleTotal);
                        if (error)
                            return "familyDeductibleTotal." + error;
                    }
                    if (message.familyDeductibleMet != null && message.hasOwnProperty("familyDeductibleMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyDeductibleMet);
                        if (error)
                            return "familyDeductibleMet." + error;
                    }
                    if (message.hasFamilyOop != null && message.hasOwnProperty("hasFamilyOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasFamilyOop);
                        if (error)
                            return "hasFamilyOop." + error;
                    }
                    if (message.familyOopTotal != null && message.hasOwnProperty("familyOopTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyOopTotal);
                        if (error)
                            return "familyOopTotal." + error;
                    }
                    if (message.familyOopMet != null && message.hasOwnProperty("familyOopMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyOopMet);
                        if (error)
                            return "familyOopMet." + error;
                    }
                    if (message.doesCopayApplyToOop != null && message.hasOwnProperty("doesCopayApplyToOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.doesCopayApplyToOop);
                        if (error)
                            return "doesCopayApplyToOop." + error;
                    }
                    if (message.copayWaivedAfterOop != null && message.hasOwnProperty("copayWaivedAfterOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.copayWaivedAfterOop);
                        if (error)
                            return "copayWaivedAfterOop." + error;
                    }
                    if (message.responsibleForMultipleCopays != null && message.hasOwnProperty("responsibleForMultipleCopays")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.responsibleForMultipleCopays);
                        if (error)
                            return "responsibleForMultipleCopays." + error;
                    }
                    if (message.hasStandardPlanLetter != null && message.hasOwnProperty("hasStandardPlanLetter")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasStandardPlanLetter);
                        if (error)
                            return "hasStandardPlanLetter." + error;
                    }
                    if (message.standardPlanLetter != null && message.hasOwnProperty("standardPlanLetter")) {
                        var error = $root.infinitusai.be.StandardPlanLetter.verify(message.standardPlanLetter);
                        if (error)
                            return "standardPlanLetter." + error;
                    }
                    if (message.differentBenefitsForOutpatient != null && message.hasOwnProperty("differentBenefitsForOutpatient")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.differentBenefitsForOutpatient);
                        if (error)
                            return "differentBenefitsForOutpatient." + error;
                    }
                    if (message.pbmExists != null && message.hasOwnProperty("pbmExists")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.pbmExists);
                        if (error)
                            return "pbmExists." + error;
                    }
                    if (message.pbmName != null && message.hasOwnProperty("pbmName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.pbmName);
                        if (error)
                            return "pbmName." + error;
                    }
                    if (message.pbmPhone != null && message.hasOwnProperty("pbmPhone")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.pbmPhone);
                        if (error)
                            return "pbmPhone." + error;
                    }
                    if (message.isAccumulatorPlan != null && message.hasOwnProperty("isAccumulatorPlan")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isAccumulatorPlan);
                        if (error)
                            return "isAccumulatorPlan." + error;
                    }
                    if (message.isMaximizerPlan != null && message.hasOwnProperty("isMaximizerPlan")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isMaximizerPlan);
                        if (error)
                            return "isMaximizerPlan." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FullBVGeneralCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FullBVGeneralCallOutputs} FullBVGeneralCallOutputs
                 */
                FullBVGeneralCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FullBVGeneralCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.FullBVGeneralCallOutputs();
                    if (object.agentName != null) {
                        if (typeof object.agentName !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.agentName: object expected");
                        message.agentName = $root.infinitusai.be.NullableString.fromObject(object.agentName);
                    }
                    if (object.referenceNumber != null) {
                        if (typeof object.referenceNumber !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.referenceNumber: object expected");
                        message.referenceNumber = $root.infinitusai.be.NullableString.fromObject(object.referenceNumber);
                    }
                    if (object.hasOtherInsuranceOnFile != null) {
                        if (typeof object.hasOtherInsuranceOnFile !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.hasOtherInsuranceOnFile: object expected");
                        message.hasOtherInsuranceOnFile = $root.infinitusai.be.NullableBool.fromObject(object.hasOtherInsuranceOnFile);
                    }
                    if (object.otherInsuranceName != null) {
                        if (typeof object.otherInsuranceName !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.otherInsuranceName: object expected");
                        message.otherInsuranceName = $root.infinitusai.be.NullableString.fromObject(object.otherInsuranceName);
                    }
                    if (object.currentPlanPriority != null) {
                        if (typeof object.currentPlanPriority !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.currentPlanPriority: object expected");
                        message.currentPlanPriority = $root.infinitusai.be.CurrentPlanPriority.fromObject(object.currentPlanPriority);
                    }
                    if (object.benefitCoordination != null) {
                        if (typeof object.benefitCoordination !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.benefitCoordination: object expected");
                        message.benefitCoordination = $root.infinitusai.be.BenefitCoordination.fromObject(object.benefitCoordination);
                    }
                    if (object.willPlanCoverAsPrimary != null) {
                        if (typeof object.willPlanCoverAsPrimary !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.willPlanCoverAsPrimary: object expected");
                        message.willPlanCoverAsPrimary = $root.infinitusai.be.NullableBool.fromObject(object.willPlanCoverAsPrimary);
                    }
                    if (object.partBDeductibleCovered != null) {
                        if (typeof object.partBDeductibleCovered !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.partBDeductibleCovered: object expected");
                        message.partBDeductibleCovered = $root.infinitusai.be.NullableBool.fromObject(object.partBDeductibleCovered);
                    }
                    if (object.otherInsuranceNotes != null) {
                        if (typeof object.otherInsuranceNotes !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.otherInsuranceNotes: object expected");
                        message.otherInsuranceNotes = $root.infinitusai.be.NullableString.fromObject(object.otherInsuranceNotes);
                    }
                    if (object.policyType != null) {
                        if (typeof object.policyType !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.policyType: object expected");
                        message.policyType = $root.infinitusai.be.PolicyType.fromObject(object.policyType);
                    }
                    if (object.planType != null) {
                        if (typeof object.planType !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.planType: object expected");
                        message.planType = $root.infinitusai.be.PlanType.fromObject(object.planType);
                    }
                    if (object.followsMedicareGuidelines != null) {
                        if (typeof object.followsMedicareGuidelines !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.followsMedicareGuidelines: object expected");
                        message.followsMedicareGuidelines = $root.infinitusai.be.NullableBool.fromObject(object.followsMedicareGuidelines);
                    }
                    if (object.planName != null) {
                        if (typeof object.planName !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.planName: object expected");
                        message.planName = $root.infinitusai.be.NullableString.fromObject(object.planName);
                    }
                    if (object.effectiveDate != null) {
                        if (typeof object.effectiveDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.effectiveDate: object expected");
                        message.effectiveDate = $root.infinitusai.be.Date.fromObject(object.effectiveDate);
                    }
                    if (object.newPlanAvailable != null) {
                        if (typeof object.newPlanAvailable !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.newPlanAvailable: object expected");
                        message.newPlanAvailable = $root.infinitusai.be.NullableBool.fromObject(object.newPlanAvailable);
                    }
                    if (object.newPlanSubscriberId != null) {
                        if (typeof object.newPlanSubscriberId !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.newPlanSubscriberId: object expected");
                        message.newPlanSubscriberId = $root.infinitusai.be.NullableString.fromObject(object.newPlanSubscriberId);
                    }
                    if (object.newPlanEffectiveDate != null) {
                        if (typeof object.newPlanEffectiveDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.newPlanEffectiveDate: object expected");
                        message.newPlanEffectiveDate = $root.infinitusai.be.Date.fromObject(object.newPlanEffectiveDate);
                    }
                    if (object.otherInsuranceExists != null) {
                        if (typeof object.otherInsuranceExists !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.otherInsuranceExists: object expected");
                        message.otherInsuranceExists = $root.infinitusai.be.NullableBool.fromObject(object.otherInsuranceExists);
                    }
                    if (object.planTerminated != null) {
                        if (typeof object.planTerminated !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.planTerminated: object expected");
                        message.planTerminated = $root.infinitusai.be.NullableBool.fromObject(object.planTerminated);
                    }
                    if (object.termDate != null) {
                        if (typeof object.termDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.termDate: object expected");
                        message.termDate = $root.infinitusai.be.Date.fromObject(object.termDate);
                    }
                    if (object.isAdverseEvent != null) {
                        if (typeof object.isAdverseEvent !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.isAdverseEvent: object expected");
                        message.isAdverseEvent = $root.infinitusai.be.NullableBool.fromObject(object.isAdverseEvent);
                    }
                    if (object.adverseEventType != null) {
                        if (typeof object.adverseEventType !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.adverseEventType: object expected");
                        message.adverseEventType = $root.infinitusai.be.AdverseEventType.fromObject(object.adverseEventType);
                    }
                    if (object.benefitPlanRenewalType != null) {
                        if (typeof object.benefitPlanRenewalType !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.benefitPlanRenewalType: object expected");
                        message.benefitPlanRenewalType = $root.infinitusai.be.BenefitPlanRenewalType.fromObject(object.benefitPlanRenewalType);
                    }
                    if (object.fiscalRenewMonth != null) {
                        if (typeof object.fiscalRenewMonth !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.fiscalRenewMonth: object expected");
                        message.fiscalRenewMonth = $root.infinitusai.be.MonthType.fromObject(object.fiscalRenewMonth);
                    }
                    if (object.groupNumber != null) {
                        if (typeof object.groupNumber !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.groupNumber: object expected");
                        message.groupNumber = $root.infinitusai.be.NullableString.fromObject(object.groupNumber);
                    }
                    if (object.groupName != null) {
                        if (typeof object.groupName !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.groupName: object expected");
                        message.groupName = $root.infinitusai.be.NullableString.fromObject(object.groupName);
                    }
                    if (object.isCobraPlan != null) {
                        if (typeof object.isCobraPlan !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.isCobraPlan: object expected");
                        message.isCobraPlan = $root.infinitusai.be.NullableBool.fromObject(object.isCobraPlan);
                    }
                    if (object.cobraPaidThroughDate != null) {
                        if (typeof object.cobraPaidThroughDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.cobraPaidThroughDate: object expected");
                        message.cobraPaidThroughDate = $root.infinitusai.be.Date.fromObject(object.cobraPaidThroughDate);
                    }
                    if (object.cobraHasGracePeriod != null) {
                        if (typeof object.cobraHasGracePeriod !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.cobraHasGracePeriod: object expected");
                        message.cobraHasGracePeriod = $root.infinitusai.be.NullableBool.fromObject(object.cobraHasGracePeriod);
                    }
                    if (object.cobraGracePeriod != null) {
                        if (typeof object.cobraGracePeriod !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.cobraGracePeriod: object expected");
                        message.cobraGracePeriod = $root.infinitusai.be.NullableString.fromObject(object.cobraGracePeriod);
                    }
                    if (object.isHealthExchangePlan != null) {
                        if (typeof object.isHealthExchangePlan !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.isHealthExchangePlan: object expected");
                        message.isHealthExchangePlan = $root.infinitusai.be.NullableBool.fromObject(object.isHealthExchangePlan);
                    }
                    if (object.patientReceivesSubsidies != null) {
                        if (typeof object.patientReceivesSubsidies !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.patientReceivesSubsidies: object expected");
                        message.patientReceivesSubsidies = $root.infinitusai.be.NullableBool.fromObject(object.patientReceivesSubsidies);
                    }
                    if (object.healthExchangePaidThroughDate != null) {
                        if (typeof object.healthExchangePaidThroughDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.healthExchangePaidThroughDate: object expected");
                        message.healthExchangePaidThroughDate = $root.infinitusai.be.Date.fromObject(object.healthExchangePaidThroughDate);
                    }
                    if (object.healthExchangeHasGracePeriod != null) {
                        if (typeof object.healthExchangeHasGracePeriod !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.healthExchangeHasGracePeriod: object expected");
                        message.healthExchangeHasGracePeriod = $root.infinitusai.be.NullableBool.fromObject(object.healthExchangeHasGracePeriod);
                    }
                    if (object.healthExchangeGracePeriod != null) {
                        if (typeof object.healthExchangeGracePeriod !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.healthExchangeGracePeriod: object expected");
                        message.healthExchangeGracePeriod = $root.infinitusai.be.NullableString.fromObject(object.healthExchangeGracePeriod);
                    }
                    if (object.networkStatus != null) {
                        if (typeof object.networkStatus !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.networkStatus: object expected");
                        message.networkStatus = $root.infinitusai.be.NetworkStatusType.fromObject(object.networkStatus);
                    }
                    if (object.inNetworkConsiderationNotes != null) {
                        if (typeof object.inNetworkConsiderationNotes !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.inNetworkConsiderationNotes: object expected");
                        message.inNetworkConsiderationNotes = $root.infinitusai.be.NullableString.fromObject(object.inNetworkConsiderationNotes);
                    }
                    if (object.providerNetworkStatusOnly != null) {
                        if (typeof object.providerNetworkStatusOnly !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.providerNetworkStatusOnly: object expected");
                        message.providerNetworkStatusOnly = $root.infinitusai.be.ProviderNetworkStatusOnlyType.fromObject(object.providerNetworkStatusOnly);
                    }
                    if (object.lifetimeMaximumExists != null) {
                        if (typeof object.lifetimeMaximumExists !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.lifetimeMaximumExists: object expected");
                        message.lifetimeMaximumExists = $root.infinitusai.be.NullableBool.fromObject(object.lifetimeMaximumExists);
                    }
                    if (object.lifetimeMaximum != null) {
                        if (typeof object.lifetimeMaximum !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.lifetimeMaximum: object expected");
                        message.lifetimeMaximum = $root.infinitusai.be.Money.fromObject(object.lifetimeMaximum);
                    }
                    if (object.annualCapExists != null) {
                        if (typeof object.annualCapExists !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.annualCapExists: object expected");
                        message.annualCapExists = $root.infinitusai.be.NullableBool.fromObject(object.annualCapExists);
                    }
                    if (object.annualCap != null) {
                        if (typeof object.annualCap !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.annualCap: object expected");
                        message.annualCap = $root.infinitusai.be.Money.fromObject(object.annualCap);
                    }
                    if (object.codesValidAndBillable != null) {
                        if (typeof object.codesValidAndBillable !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.codesValidAndBillable: object expected");
                        message.codesValidAndBillable = $root.infinitusai.be.NullableBool.fromObject(object.codesValidAndBillable);
                    }
                    if (object.uncoveredCodes != null) {
                        if (typeof object.uncoveredCodes !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.uncoveredCodes: object expected");
                        message.uncoveredCodes = $root.infinitusai.be.MultiselectCodesType.fromObject(object.uncoveredCodes);
                    }
                    if (object.uncoveredCodesStr) {
                        if (!Array.isArray(object.uncoveredCodesStr))
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.uncoveredCodesStr: array expected");
                        message.uncoveredCodesStr = [];
                        for (var i = 0; i < object.uncoveredCodesStr.length; ++i)
                            message.uncoveredCodesStr[i] = String(object.uncoveredCodesStr[i]);
                    }
                    if (object.isSelfFunded != null) {
                        if (typeof object.isSelfFunded !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.isSelfFunded: object expected");
                        message.isSelfFunded = $root.infinitusai.be.NullableBool.fromObject(object.isSelfFunded);
                    }
                    if (object.lifetimeMaximumMet != null) {
                        if (typeof object.lifetimeMaximumMet !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.lifetimeMaximumMet: object expected");
                        message.lifetimeMaximumMet = $root.infinitusai.be.Money.fromObject(object.lifetimeMaximumMet);
                    }
                    if (object.annualCapMet != null) {
                        if (typeof object.annualCapMet !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.annualCapMet: object expected");
                        message.annualCapMet = $root.infinitusai.be.Money.fromObject(object.annualCapMet);
                    }
                    if (object.reasonForNonCoverageType != null) {
                        if (typeof object.reasonForNonCoverageType !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.reasonForNonCoverageType: object expected");
                        message.reasonForNonCoverageType = $root.infinitusai.be.ReasonForNonCoverageType.fromObject(object.reasonForNonCoverageType);
                    }
                    if (object.hasDeductible != null) {
                        if (typeof object.hasDeductible !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.hasDeductible: object expected");
                        message.hasDeductible = $root.infinitusai.be.NullableBool.fromObject(object.hasDeductible);
                    }
                    if (object.deductibleTotal != null) {
                        if (typeof object.deductibleTotal !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.deductibleTotal: object expected");
                        message.deductibleTotal = $root.infinitusai.be.Money.fromObject(object.deductibleTotal);
                    }
                    if (object.deductibleMet != null) {
                        if (typeof object.deductibleMet !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.deductibleMet: object expected");
                        message.deductibleMet = $root.infinitusai.be.Money.fromObject(object.deductibleMet);
                    }
                    if (object.hasSpendDown != null) {
                        if (typeof object.hasSpendDown !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.hasSpendDown: object expected");
                        message.hasSpendDown = $root.infinitusai.be.NullableBool.fromObject(object.hasSpendDown);
                    }
                    if (object.spendDownTotal != null) {
                        if (typeof object.spendDownTotal !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.spendDownTotal: object expected");
                        message.spendDownTotal = $root.infinitusai.be.Money.fromObject(object.spendDownTotal);
                    }
                    if (object.spendDownMet != null) {
                        if (typeof object.spendDownMet !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.spendDownMet: object expected");
                        message.spendDownMet = $root.infinitusai.be.Money.fromObject(object.spendDownMet);
                    }
                    if (object.isDeductibleIncludedInOop != null) {
                        if (typeof object.isDeductibleIncludedInOop !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.isDeductibleIncludedInOop: object expected");
                        message.isDeductibleIncludedInOop = $root.infinitusai.be.NullableBool.fromObject(object.isDeductibleIncludedInOop);
                    }
                    if (object.hasOop != null) {
                        if (typeof object.hasOop !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.hasOop: object expected");
                        message.hasOop = $root.infinitusai.be.NullableBool.fromObject(object.hasOop);
                    }
                    if (object.oopTotal != null) {
                        if (typeof object.oopTotal !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.oopTotal: object expected");
                        message.oopTotal = $root.infinitusai.be.Money.fromObject(object.oopTotal);
                    }
                    if (object.oopMet != null) {
                        if (typeof object.oopMet !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.oopMet: object expected");
                        message.oopMet = $root.infinitusai.be.Money.fromObject(object.oopMet);
                    }
                    if (object.hasFamilyDeductible != null) {
                        if (typeof object.hasFamilyDeductible !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.hasFamilyDeductible: object expected");
                        message.hasFamilyDeductible = $root.infinitusai.be.NullableBool.fromObject(object.hasFamilyDeductible);
                    }
                    if (object.familyDeductibleTotal != null) {
                        if (typeof object.familyDeductibleTotal !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.familyDeductibleTotal: object expected");
                        message.familyDeductibleTotal = $root.infinitusai.be.Money.fromObject(object.familyDeductibleTotal);
                    }
                    if (object.familyDeductibleMet != null) {
                        if (typeof object.familyDeductibleMet !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.familyDeductibleMet: object expected");
                        message.familyDeductibleMet = $root.infinitusai.be.Money.fromObject(object.familyDeductibleMet);
                    }
                    if (object.hasFamilyOop != null) {
                        if (typeof object.hasFamilyOop !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.hasFamilyOop: object expected");
                        message.hasFamilyOop = $root.infinitusai.be.NullableBool.fromObject(object.hasFamilyOop);
                    }
                    if (object.familyOopTotal != null) {
                        if (typeof object.familyOopTotal !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.familyOopTotal: object expected");
                        message.familyOopTotal = $root.infinitusai.be.Money.fromObject(object.familyOopTotal);
                    }
                    if (object.familyOopMet != null) {
                        if (typeof object.familyOopMet !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.familyOopMet: object expected");
                        message.familyOopMet = $root.infinitusai.be.Money.fromObject(object.familyOopMet);
                    }
                    if (object.doesCopayApplyToOop != null) {
                        if (typeof object.doesCopayApplyToOop !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.doesCopayApplyToOop: object expected");
                        message.doesCopayApplyToOop = $root.infinitusai.be.NullableBool.fromObject(object.doesCopayApplyToOop);
                    }
                    if (object.copayWaivedAfterOop != null) {
                        if (typeof object.copayWaivedAfterOop !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.copayWaivedAfterOop: object expected");
                        message.copayWaivedAfterOop = $root.infinitusai.be.NullableBool.fromObject(object.copayWaivedAfterOop);
                    }
                    if (object.responsibleForMultipleCopays != null) {
                        if (typeof object.responsibleForMultipleCopays !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.responsibleForMultipleCopays: object expected");
                        message.responsibleForMultipleCopays = $root.infinitusai.be.NullableBool.fromObject(object.responsibleForMultipleCopays);
                    }
                    if (object.hasStandardPlanLetter != null) {
                        if (typeof object.hasStandardPlanLetter !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.hasStandardPlanLetter: object expected");
                        message.hasStandardPlanLetter = $root.infinitusai.be.NullableBool.fromObject(object.hasStandardPlanLetter);
                    }
                    if (object.standardPlanLetter != null) {
                        if (typeof object.standardPlanLetter !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.standardPlanLetter: object expected");
                        message.standardPlanLetter = $root.infinitusai.be.StandardPlanLetter.fromObject(object.standardPlanLetter);
                    }
                    if (object.differentBenefitsForOutpatient != null) {
                        if (typeof object.differentBenefitsForOutpatient !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.differentBenefitsForOutpatient: object expected");
                        message.differentBenefitsForOutpatient = $root.infinitusai.be.NullableBool.fromObject(object.differentBenefitsForOutpatient);
                    }
                    if (object.pbmExists != null) {
                        if (typeof object.pbmExists !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.pbmExists: object expected");
                        message.pbmExists = $root.infinitusai.be.NullableBool.fromObject(object.pbmExists);
                    }
                    if (object.pbmName != null) {
                        if (typeof object.pbmName !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.pbmName: object expected");
                        message.pbmName = $root.infinitusai.be.NullableString.fromObject(object.pbmName);
                    }
                    if (object.pbmPhone != null) {
                        if (typeof object.pbmPhone !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.pbmPhone: object expected");
                        message.pbmPhone = $root.infinitusai.be.NullableString.fromObject(object.pbmPhone);
                    }
                    if (object.isAccumulatorPlan != null) {
                        if (typeof object.isAccumulatorPlan !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.isAccumulatorPlan: object expected");
                        message.isAccumulatorPlan = $root.infinitusai.be.NullableBool.fromObject(object.isAccumulatorPlan);
                    }
                    if (object.isMaximizerPlan != null) {
                        if (typeof object.isMaximizerPlan !== "object")
                            throw TypeError(".infinitusai.be.FullBVGeneralCallOutputs.isMaximizerPlan: object expected");
                        message.isMaximizerPlan = $root.infinitusai.be.NullableBool.fromObject(object.isMaximizerPlan);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FullBVGeneralCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {infinitusai.be.FullBVGeneralCallOutputs} message FullBVGeneralCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FullBVGeneralCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uncoveredCodesStr = [];
                    if (options.defaults) {
                        object.agentName = null;
                        object.referenceNumber = null;
                        object.hasOtherInsuranceOnFile = null;
                        object.policyType = null;
                        object.planType = null;
                        object.followsMedicareGuidelines = null;
                        object.planName = null;
                        object.effectiveDate = null;
                        object.termDate = null;
                        object.fiscalRenewMonth = null;
                        object.groupNumber = null;
                        object.networkStatus = null;
                        object.lifetimeMaximumExists = null;
                        object.lifetimeMaximum = null;
                        object.annualCapExists = null;
                        object.annualCap = null;
                        object.codesValidAndBillable = null;
                        object.isSelfFunded = null;
                        object.lifetimeMaximumMet = null;
                        object.annualCapMet = null;
                        object.hasDeductible = null;
                        object.deductibleTotal = null;
                        object.deductibleMet = null;
                        object.isDeductibleIncludedInOop = null;
                        object.hasOop = null;
                        object.oopTotal = null;
                        object.oopMet = null;
                        object.hasFamilyDeductible = null;
                        object.familyDeductibleTotal = null;
                        object.familyDeductibleMet = null;
                        object.hasFamilyOop = null;
                        object.familyOopTotal = null;
                        object.familyOopMet = null;
                        object.otherInsuranceNotes = null;
                        object.doesCopayApplyToOop = null;
                        object.copayWaivedAfterOop = null;
                        object.uncoveredCodes = null;
                        object.inNetworkConsiderationNotes = null;
                        object.responsibleForMultipleCopays = null;
                        object.otherInsuranceName = null;
                        object.currentPlanPriority = null;
                        object.benefitCoordination = null;
                        object.willPlanCoverAsPrimary = null;
                        object.partBDeductibleCovered = null;
                        object.planTerminated = null;
                        object.isAdverseEvent = null;
                        object.adverseEventType = null;
                        object.hasSpendDown = null;
                        object.spendDownTotal = null;
                        object.spendDownMet = null;
                        object.reasonForNonCoverageType = null;
                        object.hasStandardPlanLetter = null;
                        object.standardPlanLetter = null;
                        object.benefitPlanRenewalType = null;
                        object.differentBenefitsForOutpatient = null;
                        object.groupName = null;
                        object.isCobraPlan = null;
                        object.cobraPaidThroughDate = null;
                        object.cobraHasGracePeriod = null;
                        object.cobraGracePeriod = null;
                        object.isHealthExchangePlan = null;
                        object.patientReceivesSubsidies = null;
                        object.healthExchangePaidThroughDate = null;
                        object.healthExchangeHasGracePeriod = null;
                        object.healthExchangeGracePeriod = null;
                        object.providerNetworkStatusOnly = null;
                        object.pbmExists = null;
                        object.pbmName = null;
                        object.pbmPhone = null;
                        object.newPlanAvailable = null;
                        object.newPlanSubscriberId = null;
                        object.newPlanEffectiveDate = null;
                        object.isAccumulatorPlan = null;
                        object.isMaximizerPlan = null;
                        object.otherInsuranceExists = null;
                    }
                    if (message.agentName != null && message.hasOwnProperty("agentName"))
                        object.agentName = $root.infinitusai.be.NullableString.toObject(message.agentName, options);
                    if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                        object.referenceNumber = $root.infinitusai.be.NullableString.toObject(message.referenceNumber, options);
                    if (message.hasOtherInsuranceOnFile != null && message.hasOwnProperty("hasOtherInsuranceOnFile"))
                        object.hasOtherInsuranceOnFile = $root.infinitusai.be.NullableBool.toObject(message.hasOtherInsuranceOnFile, options);
                    if (message.policyType != null && message.hasOwnProperty("policyType"))
                        object.policyType = $root.infinitusai.be.PolicyType.toObject(message.policyType, options);
                    if (message.planType != null && message.hasOwnProperty("planType"))
                        object.planType = $root.infinitusai.be.PlanType.toObject(message.planType, options);
                    if (message.followsMedicareGuidelines != null && message.hasOwnProperty("followsMedicareGuidelines"))
                        object.followsMedicareGuidelines = $root.infinitusai.be.NullableBool.toObject(message.followsMedicareGuidelines, options);
                    if (message.planName != null && message.hasOwnProperty("planName"))
                        object.planName = $root.infinitusai.be.NullableString.toObject(message.planName, options);
                    if (message.effectiveDate != null && message.hasOwnProperty("effectiveDate"))
                        object.effectiveDate = $root.infinitusai.be.Date.toObject(message.effectiveDate, options);
                    if (message.termDate != null && message.hasOwnProperty("termDate"))
                        object.termDate = $root.infinitusai.be.Date.toObject(message.termDate, options);
                    if (message.fiscalRenewMonth != null && message.hasOwnProperty("fiscalRenewMonth"))
                        object.fiscalRenewMonth = $root.infinitusai.be.MonthType.toObject(message.fiscalRenewMonth, options);
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                        object.groupNumber = $root.infinitusai.be.NullableString.toObject(message.groupNumber, options);
                    if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                        object.networkStatus = $root.infinitusai.be.NetworkStatusType.toObject(message.networkStatus, options);
                    if (message.lifetimeMaximumExists != null && message.hasOwnProperty("lifetimeMaximumExists"))
                        object.lifetimeMaximumExists = $root.infinitusai.be.NullableBool.toObject(message.lifetimeMaximumExists, options);
                    if (message.lifetimeMaximum != null && message.hasOwnProperty("lifetimeMaximum"))
                        object.lifetimeMaximum = $root.infinitusai.be.Money.toObject(message.lifetimeMaximum, options);
                    if (message.annualCapExists != null && message.hasOwnProperty("annualCapExists"))
                        object.annualCapExists = $root.infinitusai.be.NullableBool.toObject(message.annualCapExists, options);
                    if (message.annualCap != null && message.hasOwnProperty("annualCap"))
                        object.annualCap = $root.infinitusai.be.Money.toObject(message.annualCap, options);
                    if (message.codesValidAndBillable != null && message.hasOwnProperty("codesValidAndBillable"))
                        object.codesValidAndBillable = $root.infinitusai.be.NullableBool.toObject(message.codesValidAndBillable, options);
                    if (message.isSelfFunded != null && message.hasOwnProperty("isSelfFunded"))
                        object.isSelfFunded = $root.infinitusai.be.NullableBool.toObject(message.isSelfFunded, options);
                    if (message.lifetimeMaximumMet != null && message.hasOwnProperty("lifetimeMaximumMet"))
                        object.lifetimeMaximumMet = $root.infinitusai.be.Money.toObject(message.lifetimeMaximumMet, options);
                    if (message.annualCapMet != null && message.hasOwnProperty("annualCapMet"))
                        object.annualCapMet = $root.infinitusai.be.Money.toObject(message.annualCapMet, options);
                    if (message.hasDeductible != null && message.hasOwnProperty("hasDeductible"))
                        object.hasDeductible = $root.infinitusai.be.NullableBool.toObject(message.hasDeductible, options);
                    if (message.deductibleTotal != null && message.hasOwnProperty("deductibleTotal"))
                        object.deductibleTotal = $root.infinitusai.be.Money.toObject(message.deductibleTotal, options);
                    if (message.deductibleMet != null && message.hasOwnProperty("deductibleMet"))
                        object.deductibleMet = $root.infinitusai.be.Money.toObject(message.deductibleMet, options);
                    if (message.isDeductibleIncludedInOop != null && message.hasOwnProperty("isDeductibleIncludedInOop"))
                        object.isDeductibleIncludedInOop = $root.infinitusai.be.NullableBool.toObject(message.isDeductibleIncludedInOop, options);
                    if (message.hasOop != null && message.hasOwnProperty("hasOop"))
                        object.hasOop = $root.infinitusai.be.NullableBool.toObject(message.hasOop, options);
                    if (message.oopTotal != null && message.hasOwnProperty("oopTotal"))
                        object.oopTotal = $root.infinitusai.be.Money.toObject(message.oopTotal, options);
                    if (message.oopMet != null && message.hasOwnProperty("oopMet"))
                        object.oopMet = $root.infinitusai.be.Money.toObject(message.oopMet, options);
                    if (message.hasFamilyDeductible != null && message.hasOwnProperty("hasFamilyDeductible"))
                        object.hasFamilyDeductible = $root.infinitusai.be.NullableBool.toObject(message.hasFamilyDeductible, options);
                    if (message.familyDeductibleTotal != null && message.hasOwnProperty("familyDeductibleTotal"))
                        object.familyDeductibleTotal = $root.infinitusai.be.Money.toObject(message.familyDeductibleTotal, options);
                    if (message.familyDeductibleMet != null && message.hasOwnProperty("familyDeductibleMet"))
                        object.familyDeductibleMet = $root.infinitusai.be.Money.toObject(message.familyDeductibleMet, options);
                    if (message.hasFamilyOop != null && message.hasOwnProperty("hasFamilyOop"))
                        object.hasFamilyOop = $root.infinitusai.be.NullableBool.toObject(message.hasFamilyOop, options);
                    if (message.familyOopTotal != null && message.hasOwnProperty("familyOopTotal"))
                        object.familyOopTotal = $root.infinitusai.be.Money.toObject(message.familyOopTotal, options);
                    if (message.familyOopMet != null && message.hasOwnProperty("familyOopMet"))
                        object.familyOopMet = $root.infinitusai.be.Money.toObject(message.familyOopMet, options);
                    if (message.otherInsuranceNotes != null && message.hasOwnProperty("otherInsuranceNotes"))
                        object.otherInsuranceNotes = $root.infinitusai.be.NullableString.toObject(message.otherInsuranceNotes, options);
                    if (message.doesCopayApplyToOop != null && message.hasOwnProperty("doesCopayApplyToOop"))
                        object.doesCopayApplyToOop = $root.infinitusai.be.NullableBool.toObject(message.doesCopayApplyToOop, options);
                    if (message.copayWaivedAfterOop != null && message.hasOwnProperty("copayWaivedAfterOop"))
                        object.copayWaivedAfterOop = $root.infinitusai.be.NullableBool.toObject(message.copayWaivedAfterOop, options);
                    if (message.uncoveredCodes != null && message.hasOwnProperty("uncoveredCodes"))
                        object.uncoveredCodes = $root.infinitusai.be.MultiselectCodesType.toObject(message.uncoveredCodes, options);
                    if (message.inNetworkConsiderationNotes != null && message.hasOwnProperty("inNetworkConsiderationNotes"))
                        object.inNetworkConsiderationNotes = $root.infinitusai.be.NullableString.toObject(message.inNetworkConsiderationNotes, options);
                    if (message.responsibleForMultipleCopays != null && message.hasOwnProperty("responsibleForMultipleCopays"))
                        object.responsibleForMultipleCopays = $root.infinitusai.be.NullableBool.toObject(message.responsibleForMultipleCopays, options);
                    if (message.otherInsuranceName != null && message.hasOwnProperty("otherInsuranceName"))
                        object.otherInsuranceName = $root.infinitusai.be.NullableString.toObject(message.otherInsuranceName, options);
                    if (message.currentPlanPriority != null && message.hasOwnProperty("currentPlanPriority"))
                        object.currentPlanPriority = $root.infinitusai.be.CurrentPlanPriority.toObject(message.currentPlanPriority, options);
                    if (message.benefitCoordination != null && message.hasOwnProperty("benefitCoordination"))
                        object.benefitCoordination = $root.infinitusai.be.BenefitCoordination.toObject(message.benefitCoordination, options);
                    if (message.willPlanCoverAsPrimary != null && message.hasOwnProperty("willPlanCoverAsPrimary"))
                        object.willPlanCoverAsPrimary = $root.infinitusai.be.NullableBool.toObject(message.willPlanCoverAsPrimary, options);
                    if (message.partBDeductibleCovered != null && message.hasOwnProperty("partBDeductibleCovered"))
                        object.partBDeductibleCovered = $root.infinitusai.be.NullableBool.toObject(message.partBDeductibleCovered, options);
                    if (message.planTerminated != null && message.hasOwnProperty("planTerminated"))
                        object.planTerminated = $root.infinitusai.be.NullableBool.toObject(message.planTerminated, options);
                    if (message.uncoveredCodesStr && message.uncoveredCodesStr.length) {
                        object.uncoveredCodesStr = [];
                        for (var j = 0; j < message.uncoveredCodesStr.length; ++j)
                            object.uncoveredCodesStr[j] = message.uncoveredCodesStr[j];
                    }
                    if (message.isAdverseEvent != null && message.hasOwnProperty("isAdverseEvent"))
                        object.isAdverseEvent = $root.infinitusai.be.NullableBool.toObject(message.isAdverseEvent, options);
                    if (message.adverseEventType != null && message.hasOwnProperty("adverseEventType"))
                        object.adverseEventType = $root.infinitusai.be.AdverseEventType.toObject(message.adverseEventType, options);
                    if (message.hasSpendDown != null && message.hasOwnProperty("hasSpendDown"))
                        object.hasSpendDown = $root.infinitusai.be.NullableBool.toObject(message.hasSpendDown, options);
                    if (message.spendDownTotal != null && message.hasOwnProperty("spendDownTotal"))
                        object.spendDownTotal = $root.infinitusai.be.Money.toObject(message.spendDownTotal, options);
                    if (message.spendDownMet != null && message.hasOwnProperty("spendDownMet"))
                        object.spendDownMet = $root.infinitusai.be.Money.toObject(message.spendDownMet, options);
                    if (message.reasonForNonCoverageType != null && message.hasOwnProperty("reasonForNonCoverageType"))
                        object.reasonForNonCoverageType = $root.infinitusai.be.ReasonForNonCoverageType.toObject(message.reasonForNonCoverageType, options);
                    if (message.hasStandardPlanLetter != null && message.hasOwnProperty("hasStandardPlanLetter"))
                        object.hasStandardPlanLetter = $root.infinitusai.be.NullableBool.toObject(message.hasStandardPlanLetter, options);
                    if (message.standardPlanLetter != null && message.hasOwnProperty("standardPlanLetter"))
                        object.standardPlanLetter = $root.infinitusai.be.StandardPlanLetter.toObject(message.standardPlanLetter, options);
                    if (message.benefitPlanRenewalType != null && message.hasOwnProperty("benefitPlanRenewalType"))
                        object.benefitPlanRenewalType = $root.infinitusai.be.BenefitPlanRenewalType.toObject(message.benefitPlanRenewalType, options);
                    if (message.differentBenefitsForOutpatient != null && message.hasOwnProperty("differentBenefitsForOutpatient"))
                        object.differentBenefitsForOutpatient = $root.infinitusai.be.NullableBool.toObject(message.differentBenefitsForOutpatient, options);
                    if (message.groupName != null && message.hasOwnProperty("groupName"))
                        object.groupName = $root.infinitusai.be.NullableString.toObject(message.groupName, options);
                    if (message.isCobraPlan != null && message.hasOwnProperty("isCobraPlan"))
                        object.isCobraPlan = $root.infinitusai.be.NullableBool.toObject(message.isCobraPlan, options);
                    if (message.cobraPaidThroughDate != null && message.hasOwnProperty("cobraPaidThroughDate"))
                        object.cobraPaidThroughDate = $root.infinitusai.be.Date.toObject(message.cobraPaidThroughDate, options);
                    if (message.cobraHasGracePeriod != null && message.hasOwnProperty("cobraHasGracePeriod"))
                        object.cobraHasGracePeriod = $root.infinitusai.be.NullableBool.toObject(message.cobraHasGracePeriod, options);
                    if (message.cobraGracePeriod != null && message.hasOwnProperty("cobraGracePeriod"))
                        object.cobraGracePeriod = $root.infinitusai.be.NullableString.toObject(message.cobraGracePeriod, options);
                    if (message.isHealthExchangePlan != null && message.hasOwnProperty("isHealthExchangePlan"))
                        object.isHealthExchangePlan = $root.infinitusai.be.NullableBool.toObject(message.isHealthExchangePlan, options);
                    if (message.patientReceivesSubsidies != null && message.hasOwnProperty("patientReceivesSubsidies"))
                        object.patientReceivesSubsidies = $root.infinitusai.be.NullableBool.toObject(message.patientReceivesSubsidies, options);
                    if (message.healthExchangePaidThroughDate != null && message.hasOwnProperty("healthExchangePaidThroughDate"))
                        object.healthExchangePaidThroughDate = $root.infinitusai.be.Date.toObject(message.healthExchangePaidThroughDate, options);
                    if (message.healthExchangeHasGracePeriod != null && message.hasOwnProperty("healthExchangeHasGracePeriod"))
                        object.healthExchangeHasGracePeriod = $root.infinitusai.be.NullableBool.toObject(message.healthExchangeHasGracePeriod, options);
                    if (message.healthExchangeGracePeriod != null && message.hasOwnProperty("healthExchangeGracePeriod"))
                        object.healthExchangeGracePeriod = $root.infinitusai.be.NullableString.toObject(message.healthExchangeGracePeriod, options);
                    if (message.providerNetworkStatusOnly != null && message.hasOwnProperty("providerNetworkStatusOnly"))
                        object.providerNetworkStatusOnly = $root.infinitusai.be.ProviderNetworkStatusOnlyType.toObject(message.providerNetworkStatusOnly, options);
                    if (message.pbmExists != null && message.hasOwnProperty("pbmExists"))
                        object.pbmExists = $root.infinitusai.be.NullableBool.toObject(message.pbmExists, options);
                    if (message.pbmName != null && message.hasOwnProperty("pbmName"))
                        object.pbmName = $root.infinitusai.be.NullableString.toObject(message.pbmName, options);
                    if (message.pbmPhone != null && message.hasOwnProperty("pbmPhone"))
                        object.pbmPhone = $root.infinitusai.be.NullableString.toObject(message.pbmPhone, options);
                    if (message.newPlanAvailable != null && message.hasOwnProperty("newPlanAvailable"))
                        object.newPlanAvailable = $root.infinitusai.be.NullableBool.toObject(message.newPlanAvailable, options);
                    if (message.newPlanSubscriberId != null && message.hasOwnProperty("newPlanSubscriberId"))
                        object.newPlanSubscriberId = $root.infinitusai.be.NullableString.toObject(message.newPlanSubscriberId, options);
                    if (message.newPlanEffectiveDate != null && message.hasOwnProperty("newPlanEffectiveDate"))
                        object.newPlanEffectiveDate = $root.infinitusai.be.Date.toObject(message.newPlanEffectiveDate, options);
                    if (message.isAccumulatorPlan != null && message.hasOwnProperty("isAccumulatorPlan"))
                        object.isAccumulatorPlan = $root.infinitusai.be.NullableBool.toObject(message.isAccumulatorPlan, options);
                    if (message.isMaximizerPlan != null && message.hasOwnProperty("isMaximizerPlan"))
                        object.isMaximizerPlan = $root.infinitusai.be.NullableBool.toObject(message.isMaximizerPlan, options);
                    if (message.otherInsuranceExists != null && message.hasOwnProperty("otherInsuranceExists"))
                        object.otherInsuranceExists = $root.infinitusai.be.NullableBool.toObject(message.otherInsuranceExists, options);
                    return object;
                };
    
                /**
                 * Converts this FullBVGeneralCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FullBVGeneralCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FullBVGeneralCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FullBVGeneralCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FullBVGeneralCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FullBVGeneralCallOutputs";
                };
    
                return FullBVGeneralCallOutputs;
            })();
    
            be.FullBVCodeBenefitsCallOutputs = (function() {
    
                /**
                 * Properties of a FullBVCodeBenefitsCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IFullBVCodeBenefitsCallOutputs
                 * @property {infinitusai.be.INullableBool|null} [hasCoinsurance] FullBVCodeBenefitsCallOutputs hasCoinsurance
                 * @property {infinitusai.be.INullableInt32|null} [coinsurancePercent] FullBVCodeBenefitsCallOutputs coinsurancePercent
                 * @property {infinitusai.be.INullableBool|null} [hasCopay] FullBVCodeBenefitsCallOutputs hasCopay
                 * @property {infinitusai.be.IMoney|null} [copay] FullBVCodeBenefitsCallOutputs copay
                 */
    
                /**
                 * Constructs a new FullBVCodeBenefitsCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FullBVCodeBenefitsCallOutputs.
                 * @implements IFullBVCodeBenefitsCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IFullBVCodeBenefitsCallOutputs=} [properties] Properties to set
                 */
                function FullBVCodeBenefitsCallOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FullBVCodeBenefitsCallOutputs hasCoinsurance.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasCoinsurance
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @instance
                 */
                FullBVCodeBenefitsCallOutputs.prototype.hasCoinsurance = null;
    
                /**
                 * FullBVCodeBenefitsCallOutputs coinsurancePercent.
                 * @member {infinitusai.be.INullableInt32|null|undefined} coinsurancePercent
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @instance
                 */
                FullBVCodeBenefitsCallOutputs.prototype.coinsurancePercent = null;
    
                /**
                 * FullBVCodeBenefitsCallOutputs hasCopay.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasCopay
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @instance
                 */
                FullBVCodeBenefitsCallOutputs.prototype.hasCopay = null;
    
                /**
                 * FullBVCodeBenefitsCallOutputs copay.
                 * @member {infinitusai.be.IMoney|null|undefined} copay
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @instance
                 */
                FullBVCodeBenefitsCallOutputs.prototype.copay = null;
    
                /**
                 * Creates a new FullBVCodeBenefitsCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVCodeBenefitsCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FullBVCodeBenefitsCallOutputs} FullBVCodeBenefitsCallOutputs instance
                 */
                FullBVCodeBenefitsCallOutputs.create = function create(properties) {
                    return new FullBVCodeBenefitsCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified FullBVCodeBenefitsCallOutputs message. Does not implicitly {@link infinitusai.be.FullBVCodeBenefitsCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVCodeBenefitsCallOutputs} message FullBVCodeBenefitsCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVCodeBenefitsCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hasCoinsurance != null && Object.hasOwnProperty.call(message, "hasCoinsurance"))
                        $root.infinitusai.be.NullableBool.encode(message.hasCoinsurance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.coinsurancePercent != null && Object.hasOwnProperty.call(message, "coinsurancePercent"))
                        $root.infinitusai.be.NullableInt32.encode(message.coinsurancePercent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.hasCopay != null && Object.hasOwnProperty.call(message, "hasCopay"))
                        $root.infinitusai.be.NullableBool.encode(message.hasCopay, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.copay != null && Object.hasOwnProperty.call(message, "copay"))
                        $root.infinitusai.be.Money.encode(message.copay, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FullBVCodeBenefitsCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.FullBVCodeBenefitsCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVCodeBenefitsCallOutputs} message FullBVCodeBenefitsCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVCodeBenefitsCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FullBVCodeBenefitsCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FullBVCodeBenefitsCallOutputs} FullBVCodeBenefitsCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVCodeBenefitsCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FullBVCodeBenefitsCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hasCoinsurance = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.coinsurancePercent = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.hasCopay = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.copay = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FullBVCodeBenefitsCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FullBVCodeBenefitsCallOutputs} FullBVCodeBenefitsCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVCodeBenefitsCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FullBVCodeBenefitsCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FullBVCodeBenefitsCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hasCoinsurance != null && message.hasOwnProperty("hasCoinsurance")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasCoinsurance);
                        if (error)
                            return "hasCoinsurance." + error;
                    }
                    if (message.coinsurancePercent != null && message.hasOwnProperty("coinsurancePercent")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.coinsurancePercent);
                        if (error)
                            return "coinsurancePercent." + error;
                    }
                    if (message.hasCopay != null && message.hasOwnProperty("hasCopay")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasCopay);
                        if (error)
                            return "hasCopay." + error;
                    }
                    if (message.copay != null && message.hasOwnProperty("copay")) {
                        var error = $root.infinitusai.be.Money.verify(message.copay);
                        if (error)
                            return "copay." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FullBVCodeBenefitsCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FullBVCodeBenefitsCallOutputs} FullBVCodeBenefitsCallOutputs
                 */
                FullBVCodeBenefitsCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FullBVCodeBenefitsCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.FullBVCodeBenefitsCallOutputs();
                    if (object.hasCoinsurance != null) {
                        if (typeof object.hasCoinsurance !== "object")
                            throw TypeError(".infinitusai.be.FullBVCodeBenefitsCallOutputs.hasCoinsurance: object expected");
                        message.hasCoinsurance = $root.infinitusai.be.NullableBool.fromObject(object.hasCoinsurance);
                    }
                    if (object.coinsurancePercent != null) {
                        if (typeof object.coinsurancePercent !== "object")
                            throw TypeError(".infinitusai.be.FullBVCodeBenefitsCallOutputs.coinsurancePercent: object expected");
                        message.coinsurancePercent = $root.infinitusai.be.NullableInt32.fromObject(object.coinsurancePercent);
                    }
                    if (object.hasCopay != null) {
                        if (typeof object.hasCopay !== "object")
                            throw TypeError(".infinitusai.be.FullBVCodeBenefitsCallOutputs.hasCopay: object expected");
                        message.hasCopay = $root.infinitusai.be.NullableBool.fromObject(object.hasCopay);
                    }
                    if (object.copay != null) {
                        if (typeof object.copay !== "object")
                            throw TypeError(".infinitusai.be.FullBVCodeBenefitsCallOutputs.copay: object expected");
                        message.copay = $root.infinitusai.be.Money.fromObject(object.copay);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FullBVCodeBenefitsCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {infinitusai.be.FullBVCodeBenefitsCallOutputs} message FullBVCodeBenefitsCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FullBVCodeBenefitsCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hasCoinsurance = null;
                        object.coinsurancePercent = null;
                        object.hasCopay = null;
                        object.copay = null;
                    }
                    if (message.hasCoinsurance != null && message.hasOwnProperty("hasCoinsurance"))
                        object.hasCoinsurance = $root.infinitusai.be.NullableBool.toObject(message.hasCoinsurance, options);
                    if (message.coinsurancePercent != null && message.hasOwnProperty("coinsurancePercent"))
                        object.coinsurancePercent = $root.infinitusai.be.NullableInt32.toObject(message.coinsurancePercent, options);
                    if (message.hasCopay != null && message.hasOwnProperty("hasCopay"))
                        object.hasCopay = $root.infinitusai.be.NullableBool.toObject(message.hasCopay, options);
                    if (message.copay != null && message.hasOwnProperty("copay"))
                        object.copay = $root.infinitusai.be.Money.toObject(message.copay, options);
                    return object;
                };
    
                /**
                 * Converts this FullBVCodeBenefitsCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FullBVCodeBenefitsCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FullBVCodeBenefitsCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FullBVCodeBenefitsCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FullBVCodeBenefitsCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FullBVCodeBenefitsCallOutputs";
                };
    
                return FullBVCodeBenefitsCallOutputs;
            })();
    
            be.FullBVJCodeCallOutputs = (function() {
    
                /**
                 * Properties of a FullBVJCodeCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IFullBVJCodeCallOutputs
                 * @property {infinitusai.be.INullableInt32|null} [medicareReplacementPartBCoveragePercent] FullBVJCodeCallOutputs medicareReplacementPartBCoveragePercent
                 * @property {infinitusai.be.IBuyAndBillType|null} [buyAndBillStatus] FullBVJCodeCallOutputs buyAndBillStatus
                 * @property {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null} [codeBenefits] FullBVJCodeCallOutputs codeBenefits
                 * @property {infinitusai.be.INullableBool|null} [deductibleApplies] FullBVJCodeCallOutputs deductibleApplies
                 * @property {infinitusai.be.INullableBool|null} [oopApplies] FullBVJCodeCallOutputs oopApplies
                 */
    
                /**
                 * Constructs a new FullBVJCodeCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FullBVJCodeCallOutputs.
                 * @implements IFullBVJCodeCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IFullBVJCodeCallOutputs=} [properties] Properties to set
                 */
                function FullBVJCodeCallOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FullBVJCodeCallOutputs medicareReplacementPartBCoveragePercent.
                 * @member {infinitusai.be.INullableInt32|null|undefined} medicareReplacementPartBCoveragePercent
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @instance
                 */
                FullBVJCodeCallOutputs.prototype.medicareReplacementPartBCoveragePercent = null;
    
                /**
                 * FullBVJCodeCallOutputs buyAndBillStatus.
                 * @member {infinitusai.be.IBuyAndBillType|null|undefined} buyAndBillStatus
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @instance
                 */
                FullBVJCodeCallOutputs.prototype.buyAndBillStatus = null;
    
                /**
                 * FullBVJCodeCallOutputs codeBenefits.
                 * @member {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null|undefined} codeBenefits
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @instance
                 */
                FullBVJCodeCallOutputs.prototype.codeBenefits = null;
    
                /**
                 * FullBVJCodeCallOutputs deductibleApplies.
                 * @member {infinitusai.be.INullableBool|null|undefined} deductibleApplies
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @instance
                 */
                FullBVJCodeCallOutputs.prototype.deductibleApplies = null;
    
                /**
                 * FullBVJCodeCallOutputs oopApplies.
                 * @member {infinitusai.be.INullableBool|null|undefined} oopApplies
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @instance
                 */
                FullBVJCodeCallOutputs.prototype.oopApplies = null;
    
                /**
                 * Creates a new FullBVJCodeCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVJCodeCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FullBVJCodeCallOutputs} FullBVJCodeCallOutputs instance
                 */
                FullBVJCodeCallOutputs.create = function create(properties) {
                    return new FullBVJCodeCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified FullBVJCodeCallOutputs message. Does not implicitly {@link infinitusai.be.FullBVJCodeCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVJCodeCallOutputs} message FullBVJCodeCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVJCodeCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.medicareReplacementPartBCoveragePercent != null && Object.hasOwnProperty.call(message, "medicareReplacementPartBCoveragePercent"))
                        $root.infinitusai.be.NullableInt32.encode(message.medicareReplacementPartBCoveragePercent, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.buyAndBillStatus != null && Object.hasOwnProperty.call(message, "buyAndBillStatus"))
                        $root.infinitusai.be.BuyAndBillType.encode(message.buyAndBillStatus, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.codeBenefits != null && Object.hasOwnProperty.call(message, "codeBenefits"))
                        $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.encode(message.codeBenefits, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.deductibleApplies != null && Object.hasOwnProperty.call(message, "deductibleApplies"))
                        $root.infinitusai.be.NullableBool.encode(message.deductibleApplies, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.oopApplies != null && Object.hasOwnProperty.call(message, "oopApplies"))
                        $root.infinitusai.be.NullableBool.encode(message.oopApplies, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FullBVJCodeCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.FullBVJCodeCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVJCodeCallOutputs} message FullBVJCodeCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVJCodeCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FullBVJCodeCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FullBVJCodeCallOutputs} FullBVJCodeCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVJCodeCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FullBVJCodeCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.medicareReplacementPartBCoveragePercent = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.buyAndBillStatus = $root.infinitusai.be.BuyAndBillType.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.deductibleApplies = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.oopApplies = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FullBVJCodeCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FullBVJCodeCallOutputs} FullBVJCodeCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVJCodeCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FullBVJCodeCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FullBVJCodeCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.medicareReplacementPartBCoveragePercent != null && message.hasOwnProperty("medicareReplacementPartBCoveragePercent")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.medicareReplacementPartBCoveragePercent);
                        if (error)
                            return "medicareReplacementPartBCoveragePercent." + error;
                    }
                    if (message.buyAndBillStatus != null && message.hasOwnProperty("buyAndBillStatus")) {
                        var error = $root.infinitusai.be.BuyAndBillType.verify(message.buyAndBillStatus);
                        if (error)
                            return "buyAndBillStatus." + error;
                    }
                    if (message.codeBenefits != null && message.hasOwnProperty("codeBenefits")) {
                        var error = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.verify(message.codeBenefits);
                        if (error)
                            return "codeBenefits." + error;
                    }
                    if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.deductibleApplies);
                        if (error)
                            return "deductibleApplies." + error;
                    }
                    if (message.oopApplies != null && message.hasOwnProperty("oopApplies")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.oopApplies);
                        if (error)
                            return "oopApplies." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FullBVJCodeCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FullBVJCodeCallOutputs} FullBVJCodeCallOutputs
                 */
                FullBVJCodeCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FullBVJCodeCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.FullBVJCodeCallOutputs();
                    if (object.medicareReplacementPartBCoveragePercent != null) {
                        if (typeof object.medicareReplacementPartBCoveragePercent !== "object")
                            throw TypeError(".infinitusai.be.FullBVJCodeCallOutputs.medicareReplacementPartBCoveragePercent: object expected");
                        message.medicareReplacementPartBCoveragePercent = $root.infinitusai.be.NullableInt32.fromObject(object.medicareReplacementPartBCoveragePercent);
                    }
                    if (object.buyAndBillStatus != null) {
                        if (typeof object.buyAndBillStatus !== "object")
                            throw TypeError(".infinitusai.be.FullBVJCodeCallOutputs.buyAndBillStatus: object expected");
                        message.buyAndBillStatus = $root.infinitusai.be.BuyAndBillType.fromObject(object.buyAndBillStatus);
                    }
                    if (object.codeBenefits != null) {
                        if (typeof object.codeBenefits !== "object")
                            throw TypeError(".infinitusai.be.FullBVJCodeCallOutputs.codeBenefits: object expected");
                        message.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.fromObject(object.codeBenefits);
                    }
                    if (object.deductibleApplies != null) {
                        if (typeof object.deductibleApplies !== "object")
                            throw TypeError(".infinitusai.be.FullBVJCodeCallOutputs.deductibleApplies: object expected");
                        message.deductibleApplies = $root.infinitusai.be.NullableBool.fromObject(object.deductibleApplies);
                    }
                    if (object.oopApplies != null) {
                        if (typeof object.oopApplies !== "object")
                            throw TypeError(".infinitusai.be.FullBVJCodeCallOutputs.oopApplies: object expected");
                        message.oopApplies = $root.infinitusai.be.NullableBool.fromObject(object.oopApplies);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FullBVJCodeCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {infinitusai.be.FullBVJCodeCallOutputs} message FullBVJCodeCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FullBVJCodeCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.medicareReplacementPartBCoveragePercent = null;
                        object.buyAndBillStatus = null;
                        object.codeBenefits = null;
                        object.deductibleApplies = null;
                        object.oopApplies = null;
                    }
                    if (message.medicareReplacementPartBCoveragePercent != null && message.hasOwnProperty("medicareReplacementPartBCoveragePercent"))
                        object.medicareReplacementPartBCoveragePercent = $root.infinitusai.be.NullableInt32.toObject(message.medicareReplacementPartBCoveragePercent, options);
                    if (message.buyAndBillStatus != null && message.hasOwnProperty("buyAndBillStatus"))
                        object.buyAndBillStatus = $root.infinitusai.be.BuyAndBillType.toObject(message.buyAndBillStatus, options);
                    if (message.codeBenefits != null && message.hasOwnProperty("codeBenefits"))
                        object.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.toObject(message.codeBenefits, options);
                    if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies"))
                        object.deductibleApplies = $root.infinitusai.be.NullableBool.toObject(message.deductibleApplies, options);
                    if (message.oopApplies != null && message.hasOwnProperty("oopApplies"))
                        object.oopApplies = $root.infinitusai.be.NullableBool.toObject(message.oopApplies, options);
                    return object;
                };
    
                /**
                 * Converts this FullBVJCodeCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FullBVJCodeCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FullBVJCodeCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FullBVJCodeCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FullBVJCodeCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FullBVJCodeCallOutputs";
                };
    
                return FullBVJCodeCallOutputs;
            })();
    
            be.FullBVAdminCodeCallOutputs = (function() {
    
                /**
                 * Properties of a FullBVAdminCodeCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IFullBVAdminCodeCallOutputs
                 * @property {infinitusai.be.IAdminCostType|null} [adminCostType] FullBVAdminCodeCallOutputs adminCostType
                 * @property {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null} [codeBenefits] FullBVAdminCodeCallOutputs codeBenefits
                 * @property {infinitusai.be.INullableBool|null} [deductibleApplies] FullBVAdminCodeCallOutputs deductibleApplies
                 * @property {infinitusai.be.INullableBool|null} [oopApplies] FullBVAdminCodeCallOutputs oopApplies
                 * @property {infinitusai.be.INullableBool|null} [sameBenefitsApplyForAllCodes] FullBVAdminCodeCallOutputs sameBenefitsApplyForAllCodes
                 */
    
                /**
                 * Constructs a new FullBVAdminCodeCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FullBVAdminCodeCallOutputs.
                 * @implements IFullBVAdminCodeCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IFullBVAdminCodeCallOutputs=} [properties] Properties to set
                 */
                function FullBVAdminCodeCallOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FullBVAdminCodeCallOutputs adminCostType.
                 * @member {infinitusai.be.IAdminCostType|null|undefined} adminCostType
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @instance
                 */
                FullBVAdminCodeCallOutputs.prototype.adminCostType = null;
    
                /**
                 * FullBVAdminCodeCallOutputs codeBenefits.
                 * @member {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null|undefined} codeBenefits
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @instance
                 */
                FullBVAdminCodeCallOutputs.prototype.codeBenefits = null;
    
                /**
                 * FullBVAdminCodeCallOutputs deductibleApplies.
                 * @member {infinitusai.be.INullableBool|null|undefined} deductibleApplies
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @instance
                 */
                FullBVAdminCodeCallOutputs.prototype.deductibleApplies = null;
    
                /**
                 * FullBVAdminCodeCallOutputs oopApplies.
                 * @member {infinitusai.be.INullableBool|null|undefined} oopApplies
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @instance
                 */
                FullBVAdminCodeCallOutputs.prototype.oopApplies = null;
    
                /**
                 * FullBVAdminCodeCallOutputs sameBenefitsApplyForAllCodes.
                 * @member {infinitusai.be.INullableBool|null|undefined} sameBenefitsApplyForAllCodes
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @instance
                 */
                FullBVAdminCodeCallOutputs.prototype.sameBenefitsApplyForAllCodes = null;
    
                /**
                 * Creates a new FullBVAdminCodeCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVAdminCodeCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FullBVAdminCodeCallOutputs} FullBVAdminCodeCallOutputs instance
                 */
                FullBVAdminCodeCallOutputs.create = function create(properties) {
                    return new FullBVAdminCodeCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified FullBVAdminCodeCallOutputs message. Does not implicitly {@link infinitusai.be.FullBVAdminCodeCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVAdminCodeCallOutputs} message FullBVAdminCodeCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVAdminCodeCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.adminCostType != null && Object.hasOwnProperty.call(message, "adminCostType"))
                        $root.infinitusai.be.AdminCostType.encode(message.adminCostType, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.codeBenefits != null && Object.hasOwnProperty.call(message, "codeBenefits"))
                        $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.encode(message.codeBenefits, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.deductibleApplies != null && Object.hasOwnProperty.call(message, "deductibleApplies"))
                        $root.infinitusai.be.NullableBool.encode(message.deductibleApplies, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.oopApplies != null && Object.hasOwnProperty.call(message, "oopApplies"))
                        $root.infinitusai.be.NullableBool.encode(message.oopApplies, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.sameBenefitsApplyForAllCodes != null && Object.hasOwnProperty.call(message, "sameBenefitsApplyForAllCodes"))
                        $root.infinitusai.be.NullableBool.encode(message.sameBenefitsApplyForAllCodes, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FullBVAdminCodeCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.FullBVAdminCodeCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVAdminCodeCallOutputs} message FullBVAdminCodeCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVAdminCodeCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FullBVAdminCodeCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FullBVAdminCodeCallOutputs} FullBVAdminCodeCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVAdminCodeCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FullBVAdminCodeCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.adminCostType = $root.infinitusai.be.AdminCostType.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.deductibleApplies = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.oopApplies = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.sameBenefitsApplyForAllCodes = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FullBVAdminCodeCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FullBVAdminCodeCallOutputs} FullBVAdminCodeCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVAdminCodeCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FullBVAdminCodeCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FullBVAdminCodeCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.adminCostType != null && message.hasOwnProperty("adminCostType")) {
                        var error = $root.infinitusai.be.AdminCostType.verify(message.adminCostType);
                        if (error)
                            return "adminCostType." + error;
                    }
                    if (message.codeBenefits != null && message.hasOwnProperty("codeBenefits")) {
                        var error = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.verify(message.codeBenefits);
                        if (error)
                            return "codeBenefits." + error;
                    }
                    if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.deductibleApplies);
                        if (error)
                            return "deductibleApplies." + error;
                    }
                    if (message.oopApplies != null && message.hasOwnProperty("oopApplies")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.oopApplies);
                        if (error)
                            return "oopApplies." + error;
                    }
                    if (message.sameBenefitsApplyForAllCodes != null && message.hasOwnProperty("sameBenefitsApplyForAllCodes")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.sameBenefitsApplyForAllCodes);
                        if (error)
                            return "sameBenefitsApplyForAllCodes." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FullBVAdminCodeCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FullBVAdminCodeCallOutputs} FullBVAdminCodeCallOutputs
                 */
                FullBVAdminCodeCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FullBVAdminCodeCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.FullBVAdminCodeCallOutputs();
                    if (object.adminCostType != null) {
                        if (typeof object.adminCostType !== "object")
                            throw TypeError(".infinitusai.be.FullBVAdminCodeCallOutputs.adminCostType: object expected");
                        message.adminCostType = $root.infinitusai.be.AdminCostType.fromObject(object.adminCostType);
                    }
                    if (object.codeBenefits != null) {
                        if (typeof object.codeBenefits !== "object")
                            throw TypeError(".infinitusai.be.FullBVAdminCodeCallOutputs.codeBenefits: object expected");
                        message.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.fromObject(object.codeBenefits);
                    }
                    if (object.deductibleApplies != null) {
                        if (typeof object.deductibleApplies !== "object")
                            throw TypeError(".infinitusai.be.FullBVAdminCodeCallOutputs.deductibleApplies: object expected");
                        message.deductibleApplies = $root.infinitusai.be.NullableBool.fromObject(object.deductibleApplies);
                    }
                    if (object.oopApplies != null) {
                        if (typeof object.oopApplies !== "object")
                            throw TypeError(".infinitusai.be.FullBVAdminCodeCallOutputs.oopApplies: object expected");
                        message.oopApplies = $root.infinitusai.be.NullableBool.fromObject(object.oopApplies);
                    }
                    if (object.sameBenefitsApplyForAllCodes != null) {
                        if (typeof object.sameBenefitsApplyForAllCodes !== "object")
                            throw TypeError(".infinitusai.be.FullBVAdminCodeCallOutputs.sameBenefitsApplyForAllCodes: object expected");
                        message.sameBenefitsApplyForAllCodes = $root.infinitusai.be.NullableBool.fromObject(object.sameBenefitsApplyForAllCodes);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FullBVAdminCodeCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {infinitusai.be.FullBVAdminCodeCallOutputs} message FullBVAdminCodeCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FullBVAdminCodeCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.adminCostType = null;
                        object.codeBenefits = null;
                        object.deductibleApplies = null;
                        object.oopApplies = null;
                        object.sameBenefitsApplyForAllCodes = null;
                    }
                    if (message.adminCostType != null && message.hasOwnProperty("adminCostType"))
                        object.adminCostType = $root.infinitusai.be.AdminCostType.toObject(message.adminCostType, options);
                    if (message.codeBenefits != null && message.hasOwnProperty("codeBenefits"))
                        object.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.toObject(message.codeBenefits, options);
                    if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies"))
                        object.deductibleApplies = $root.infinitusai.be.NullableBool.toObject(message.deductibleApplies, options);
                    if (message.oopApplies != null && message.hasOwnProperty("oopApplies"))
                        object.oopApplies = $root.infinitusai.be.NullableBool.toObject(message.oopApplies, options);
                    if (message.sameBenefitsApplyForAllCodes != null && message.hasOwnProperty("sameBenefitsApplyForAllCodes"))
                        object.sameBenefitsApplyForAllCodes = $root.infinitusai.be.NullableBool.toObject(message.sameBenefitsApplyForAllCodes, options);
                    return object;
                };
    
                /**
                 * Converts this FullBVAdminCodeCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FullBVAdminCodeCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FullBVAdminCodeCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FullBVAdminCodeCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FullBVAdminCodeCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FullBVAdminCodeCallOutputs";
                };
    
                return FullBVAdminCodeCallOutputs;
            })();
    
            be.FullBVSpecialOfficeCallOutputs = (function() {
    
                /**
                 * Properties of a FullBVSpecialOfficeCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IFullBVSpecialOfficeCallOutputs
                 * @property {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null} [codeBenefits] FullBVSpecialOfficeCallOutputs codeBenefits
                 * @property {infinitusai.be.INullableBool|null} [billedWhenNoOfficeVisit] FullBVSpecialOfficeCallOutputs billedWhenNoOfficeVisit
                 * @property {infinitusai.be.INullableBool|null} [deductibleApplies] FullBVSpecialOfficeCallOutputs deductibleApplies
                 */
    
                /**
                 * Constructs a new FullBVSpecialOfficeCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FullBVSpecialOfficeCallOutputs.
                 * @implements IFullBVSpecialOfficeCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IFullBVSpecialOfficeCallOutputs=} [properties] Properties to set
                 */
                function FullBVSpecialOfficeCallOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FullBVSpecialOfficeCallOutputs codeBenefits.
                 * @member {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null|undefined} codeBenefits
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @instance
                 */
                FullBVSpecialOfficeCallOutputs.prototype.codeBenefits = null;
    
                /**
                 * FullBVSpecialOfficeCallOutputs billedWhenNoOfficeVisit.
                 * @member {infinitusai.be.INullableBool|null|undefined} billedWhenNoOfficeVisit
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @instance
                 */
                FullBVSpecialOfficeCallOutputs.prototype.billedWhenNoOfficeVisit = null;
    
                /**
                 * FullBVSpecialOfficeCallOutputs deductibleApplies.
                 * @member {infinitusai.be.INullableBool|null|undefined} deductibleApplies
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @instance
                 */
                FullBVSpecialOfficeCallOutputs.prototype.deductibleApplies = null;
    
                /**
                 * Creates a new FullBVSpecialOfficeCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVSpecialOfficeCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FullBVSpecialOfficeCallOutputs} FullBVSpecialOfficeCallOutputs instance
                 */
                FullBVSpecialOfficeCallOutputs.create = function create(properties) {
                    return new FullBVSpecialOfficeCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified FullBVSpecialOfficeCallOutputs message. Does not implicitly {@link infinitusai.be.FullBVSpecialOfficeCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVSpecialOfficeCallOutputs} message FullBVSpecialOfficeCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVSpecialOfficeCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.codeBenefits != null && Object.hasOwnProperty.call(message, "codeBenefits"))
                        $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.encode(message.codeBenefits, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.billedWhenNoOfficeVisit != null && Object.hasOwnProperty.call(message, "billedWhenNoOfficeVisit"))
                        $root.infinitusai.be.NullableBool.encode(message.billedWhenNoOfficeVisit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.deductibleApplies != null && Object.hasOwnProperty.call(message, "deductibleApplies"))
                        $root.infinitusai.be.NullableBool.encode(message.deductibleApplies, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FullBVSpecialOfficeCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.FullBVSpecialOfficeCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVSpecialOfficeCallOutputs} message FullBVSpecialOfficeCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVSpecialOfficeCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FullBVSpecialOfficeCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FullBVSpecialOfficeCallOutputs} FullBVSpecialOfficeCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVSpecialOfficeCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FullBVSpecialOfficeCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.billedWhenNoOfficeVisit = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.deductibleApplies = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FullBVSpecialOfficeCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FullBVSpecialOfficeCallOutputs} FullBVSpecialOfficeCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVSpecialOfficeCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FullBVSpecialOfficeCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FullBVSpecialOfficeCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.codeBenefits != null && message.hasOwnProperty("codeBenefits")) {
                        var error = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.verify(message.codeBenefits);
                        if (error)
                            return "codeBenefits." + error;
                    }
                    if (message.billedWhenNoOfficeVisit != null && message.hasOwnProperty("billedWhenNoOfficeVisit")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.billedWhenNoOfficeVisit);
                        if (error)
                            return "billedWhenNoOfficeVisit." + error;
                    }
                    if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.deductibleApplies);
                        if (error)
                            return "deductibleApplies." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FullBVSpecialOfficeCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FullBVSpecialOfficeCallOutputs} FullBVSpecialOfficeCallOutputs
                 */
                FullBVSpecialOfficeCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FullBVSpecialOfficeCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.FullBVSpecialOfficeCallOutputs();
                    if (object.codeBenefits != null) {
                        if (typeof object.codeBenefits !== "object")
                            throw TypeError(".infinitusai.be.FullBVSpecialOfficeCallOutputs.codeBenefits: object expected");
                        message.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.fromObject(object.codeBenefits);
                    }
                    if (object.billedWhenNoOfficeVisit != null) {
                        if (typeof object.billedWhenNoOfficeVisit !== "object")
                            throw TypeError(".infinitusai.be.FullBVSpecialOfficeCallOutputs.billedWhenNoOfficeVisit: object expected");
                        message.billedWhenNoOfficeVisit = $root.infinitusai.be.NullableBool.fromObject(object.billedWhenNoOfficeVisit);
                    }
                    if (object.deductibleApplies != null) {
                        if (typeof object.deductibleApplies !== "object")
                            throw TypeError(".infinitusai.be.FullBVSpecialOfficeCallOutputs.deductibleApplies: object expected");
                        message.deductibleApplies = $root.infinitusai.be.NullableBool.fromObject(object.deductibleApplies);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FullBVSpecialOfficeCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {infinitusai.be.FullBVSpecialOfficeCallOutputs} message FullBVSpecialOfficeCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FullBVSpecialOfficeCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.codeBenefits = null;
                        object.billedWhenNoOfficeVisit = null;
                        object.deductibleApplies = null;
                    }
                    if (message.codeBenefits != null && message.hasOwnProperty("codeBenefits"))
                        object.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.toObject(message.codeBenefits, options);
                    if (message.billedWhenNoOfficeVisit != null && message.hasOwnProperty("billedWhenNoOfficeVisit"))
                        object.billedWhenNoOfficeVisit = $root.infinitusai.be.NullableBool.toObject(message.billedWhenNoOfficeVisit, options);
                    if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies"))
                        object.deductibleApplies = $root.infinitusai.be.NullableBool.toObject(message.deductibleApplies, options);
                    return object;
                };
    
                /**
                 * Converts this FullBVSpecialOfficeCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FullBVSpecialOfficeCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FullBVSpecialOfficeCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FullBVSpecialOfficeCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FullBVSpecialOfficeCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FullBVSpecialOfficeCallOutputs";
                };
    
                return FullBVSpecialOfficeCallOutputs;
            })();
    
            be.FullBVPreferredSpecialtyPharmacyOutputs = (function() {
    
                /**
                 * Properties of a FullBVPreferredSpecialtyPharmacyOutputs.
                 * @memberof infinitusai.be
                 * @interface IFullBVPreferredSpecialtyPharmacyOutputs
                 * @property {infinitusai.be.INullableBool|null} [available] FullBVPreferredSpecialtyPharmacyOutputs available
                 */
    
                /**
                 * Constructs a new FullBVPreferredSpecialtyPharmacyOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FullBVPreferredSpecialtyPharmacyOutputs.
                 * @implements IFullBVPreferredSpecialtyPharmacyOutputs
                 * @constructor
                 * @param {infinitusai.be.IFullBVPreferredSpecialtyPharmacyOutputs=} [properties] Properties to set
                 */
                function FullBVPreferredSpecialtyPharmacyOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FullBVPreferredSpecialtyPharmacyOutputs available.
                 * @member {infinitusai.be.INullableBool|null|undefined} available
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @instance
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.prototype.available = null;
    
                /**
                 * Creates a new FullBVPreferredSpecialtyPharmacyOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVPreferredSpecialtyPharmacyOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs} FullBVPreferredSpecialtyPharmacyOutputs instance
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.create = function create(properties) {
                    return new FullBVPreferredSpecialtyPharmacyOutputs(properties);
                };
    
                /**
                 * Encodes the specified FullBVPreferredSpecialtyPharmacyOutputs message. Does not implicitly {@link infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVPreferredSpecialtyPharmacyOutputs} message FullBVPreferredSpecialtyPharmacyOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.available != null && Object.hasOwnProperty.call(message, "available"))
                        $root.infinitusai.be.NullableBool.encode(message.available, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FullBVPreferredSpecialtyPharmacyOutputs message, length delimited. Does not implicitly {@link infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVPreferredSpecialtyPharmacyOutputs} message FullBVPreferredSpecialtyPharmacyOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FullBVPreferredSpecialtyPharmacyOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs} FullBVPreferredSpecialtyPharmacyOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.available = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FullBVPreferredSpecialtyPharmacyOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs} FullBVPreferredSpecialtyPharmacyOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FullBVPreferredSpecialtyPharmacyOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.available != null && message.hasOwnProperty("available")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.available);
                        if (error)
                            return "available." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FullBVPreferredSpecialtyPharmacyOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs} FullBVPreferredSpecialtyPharmacyOutputs
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs)
                        return object;
                    var message = new $root.infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs();
                    if (object.available != null) {
                        if (typeof object.available !== "object")
                            throw TypeError(".infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs.available: object expected");
                        message.available = $root.infinitusai.be.NullableBool.fromObject(object.available);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FullBVPreferredSpecialtyPharmacyOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs} message FullBVPreferredSpecialtyPharmacyOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.available = null;
                    if (message.available != null && message.hasOwnProperty("available"))
                        object.available = $root.infinitusai.be.NullableBool.toObject(message.available, options);
                    return object;
                };
    
                /**
                 * Converts this FullBVPreferredSpecialtyPharmacyOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FullBVPreferredSpecialtyPharmacyOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FullBVPreferredSpecialtyPharmacyOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs";
                };
    
                return FullBVPreferredSpecialtyPharmacyOutputs;
            })();
    
            be.FullBVReferralCallOutputs = (function() {
    
                /**
                 * Properties of a FullBVReferralCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IFullBVReferralCallOutputs
                 * @property {infinitusai.be.INullableBool|null} [isReferralRequired] FullBVReferralCallOutputs isReferralRequired
                 * @property {infinitusai.be.IReferralRequirementsType|null} [referralRequirements] FullBVReferralCallOutputs referralRequirements
                 * @property {infinitusai.be.INullableBool|null} [hasReferralOnFile] FullBVReferralCallOutputs hasReferralOnFile
                 * @property {infinitusai.be.IDate|null} [referralEffectiveDate] FullBVReferralCallOutputs referralEffectiveDate
                 * @property {infinitusai.be.IDate|null} [referralRecertDate] FullBVReferralCallOutputs referralRecertDate
                 * @property {infinitusai.be.INullableString|null} [referralNumber] FullBVReferralCallOutputs referralNumber
                 * @property {infinitusai.be.INullableInt32|null} [referralNumVisitsApproved] FullBVReferralCallOutputs referralNumVisitsApproved
                 * @property {infinitusai.be.INullableInt32|null} [referralNumVisitsUsed] FullBVReferralCallOutputs referralNumVisitsUsed
                 * @property {infinitusai.be.INullableString|null} [referringPcpName] FullBVReferralCallOutputs referringPcpName
                 * @property {infinitusai.be.INullableString|null} [referringPcpPhone] FullBVReferralCallOutputs referringPcpPhone
                 * @property {infinitusai.be.INullableBool|null} [isPriorAuthRequired] FullBVReferralCallOutputs isPriorAuthRequired
                 * @property {infinitusai.be.INullableBool|null} [isPriorAuthOnFile] FullBVReferralCallOutputs isPriorAuthOnFile
                 * @property {infinitusai.be.IPriorAuthStatusType|null} [priorAuthStatus] FullBVReferralCallOutputs priorAuthStatus
                 * @property {infinitusai.be.IDate|null} [priorAuthApprovalStartDate] FullBVReferralCallOutputs priorAuthApprovalStartDate
                 * @property {infinitusai.be.IDate|null} [priorAuthApprovalEndDate] FullBVReferralCallOutputs priorAuthApprovalEndDate
                 * @property {infinitusai.be.INullableString|null} [priorAuthApprovalNumber] FullBVReferralCallOutputs priorAuthApprovalNumber
                 * @property {infinitusai.be.INullableString|null} [priorAuthApprovedQuantity] FullBVReferralCallOutputs priorAuthApprovedQuantity
                 * @property {infinitusai.be.INullableString|null} [priorAuthTurnaroundNotes] FullBVReferralCallOutputs priorAuthTurnaroundNotes
                 * @property {infinitusai.be.INullableString|null} [priorAuthDenialReason] FullBVReferralCallOutputs priorAuthDenialReason
                 * @property {infinitusai.be.IDate|null} [priorAuthDenialDate] FullBVReferralCallOutputs priorAuthDenialDate
                 * @property {infinitusai.be.IDate|null} [priorAuthSubmissionDate] FullBVReferralCallOutputs priorAuthSubmissionDate
                 * @property {infinitusai.be.INullableBool|null} [medicalPolicyAvailableOnWebsite] FullBVReferralCallOutputs medicalPolicyAvailableOnWebsite
                 * @property {infinitusai.be.INullableString|null} [medicalPolicyNumber] FullBVReferralCallOutputs medicalPolicyNumber
                 * @property {infinitusai.be.INullableBool|null} [isMedicalGroupAtRisk] FullBVReferralCallOutputs isMedicalGroupAtRisk
                 * @property {infinitusai.be.INullableString|null} [medicalGroupName] FullBVReferralCallOutputs medicalGroupName
                 * @property {infinitusai.be.INullableString|null} [medicalGroupPhoneNumber] FullBVReferralCallOutputs medicalGroupPhoneNumber
                 * @property {Array.<string>|null} [priorAuthRequiredCodes] FullBVReferralCallOutputs priorAuthRequiredCodes
                 * @property {infinitusai.be.IPredeterminationRequirementType|null} [predeterminationRequirement] FullBVReferralCallOutputs predeterminationRequirement
                 * @property {infinitusai.be.INullableBool|null} [isPredeterminationOnFile] FullBVReferralCallOutputs isPredeterminationOnFile
                 * @property {infinitusai.be.INullableString|null} [predeterminationApprovalNumber] FullBVReferralCallOutputs predeterminationApprovalNumber
                 * @property {infinitusai.be.INullableString|null} [predeterminationTurnaroundNotes] FullBVReferralCallOutputs predeterminationTurnaroundNotes
                 * @property {infinitusai.be.INullableBool|null} [isStepTherapyRequired] FullBVReferralCallOutputs isStepTherapyRequired
                 * @property {infinitusai.be.IStepTherapyMethod|null} [stepTherapyMethod] FullBVReferralCallOutputs stepTherapyMethod
                 * @property {infinitusai.be.INullableString|null} [stepTherapyTreatment] FullBVReferralCallOutputs stepTherapyTreatment
                 * @property {infinitusai.be.INullableBool|null} [specialtyPharmacyAvailable] FullBVReferralCallOutputs specialtyPharmacyAvailable
                 * @property {infinitusai.be.INullableString|null} [specialtyPharmacy1Name] FullBVReferralCallOutputs specialtyPharmacy1Name
                 * @property {infinitusai.be.INullableString|null} [specialtyPharmacy1Phone] FullBVReferralCallOutputs specialtyPharmacy1Phone
                 * @property {infinitusai.be.INullableString|null} [specialtyPharmacy1Fax] FullBVReferralCallOutputs specialtyPharmacy1Fax
                 * @property {infinitusai.be.INullableBool|null} [hasSpecialtyPharmacyExclusions] FullBVReferralCallOutputs hasSpecialtyPharmacyExclusions
                 * @property {infinitusai.be.INullableString|null} [specialtyPharmacyExclusions] FullBVReferralCallOutputs specialtyPharmacyExclusions
                 * @property {Array.<infinitusai.be.IFullBVPreferredSpecialtyPharmacyOutputs>|null} [preferredSpecialtyPharmacyOutputs] FullBVReferralCallOutputs preferredSpecialtyPharmacyOutputs
                 * @property {infinitusai.be.INullableString|null} [priorAuthMethodEntity] FullBVReferralCallOutputs priorAuthMethodEntity
                 * @property {infinitusai.be.IPriorAuthMethod|null} [priorAuthMethodsV2] FullBVReferralCallOutputs priorAuthMethodsV2
                 * @property {infinitusai.be.IMultiSelectPriorAuthRequirementType|null} [priorAuthRequirements] FullBVReferralCallOutputs priorAuthRequirements
                 * @property {infinitusai.be.IPriorAuthAndPredeterminationMethod|null} [predeterminationMethodType] FullBVReferralCallOutputs predeterminationMethodType
                 * @property {infinitusai.be.INullableString|null} [predeterminationMethodEntity] FullBVReferralCallOutputs predeterminationMethodEntity
                 * @property {infinitusai.be.INullableString|null} [predeterminationMethodContact] FullBVReferralCallOutputs predeterminationMethodContact
                 * @property {infinitusai.be.IDate|null} [predeterminationStartDate] FullBVReferralCallOutputs predeterminationStartDate
                 * @property {infinitusai.be.IDate|null} [predeterminationEndDate] FullBVReferralCallOutputs predeterminationEndDate
                 * @property {Object.<string,string>|null} [priorAuthMethods] FullBVReferralCallOutputs priorAuthMethods
                 * @property {infinitusai.be.INullableString|null} [priorAuthMethodNotes] FullBVReferralCallOutputs priorAuthMethodNotes
                 * @property {infinitusai.be.INullableString|null} [predeterminationMethodNotes] FullBVReferralCallOutputs predeterminationMethodNotes
                 * @property {infinitusai.be.IPriorAuthAndPredeterminationMethod|null} [priorAuthMethodType] FullBVReferralCallOutputs priorAuthMethodType
                 * @property {infinitusai.be.INullableString|null} [priorAuthMethodContact] FullBVReferralCallOutputs priorAuthMethodContact
                 * @property {infinitusai.be.IMultiselectCodesType|null} [priorAuthCodes] FullBVReferralCallOutputs priorAuthCodes
                 * @property {infinitusai.be.INullableString|null} [stepTherapyMethodNotes] FullBVReferralCallOutputs stepTherapyMethodNotes
                 * @property {infinitusai.be.INullableBool|null} [isPredeterminationRequired] FullBVReferralCallOutputs isPredeterminationRequired
                 * @property {infinitusai.be.INullableBool|null} [isPredeterminationAvailable] FullBVReferralCallOutputs isPredeterminationAvailable
                 * @property {infinitusai.be.INullableBool|null} [buyAndBillAvailable] FullBVReferralCallOutputs buyAndBillAvailable
                 * @property {infinitusai.be.IStreetAddress|null} [claimsAddress] FullBVReferralCallOutputs claimsAddress
                 */
    
                /**
                 * Constructs a new FullBVReferralCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FullBVReferralCallOutputs.
                 * @implements IFullBVReferralCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IFullBVReferralCallOutputs=} [properties] Properties to set
                 */
                function FullBVReferralCallOutputs(properties) {
                    this.priorAuthRequiredCodes = [];
                    this.preferredSpecialtyPharmacyOutputs = [];
                    this.priorAuthMethods = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FullBVReferralCallOutputs isReferralRequired.
                 * @member {infinitusai.be.INullableBool|null|undefined} isReferralRequired
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.isReferralRequired = null;
    
                /**
                 * FullBVReferralCallOutputs referralRequirements.
                 * @member {infinitusai.be.IReferralRequirementsType|null|undefined} referralRequirements
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.referralRequirements = null;
    
                /**
                 * FullBVReferralCallOutputs hasReferralOnFile.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasReferralOnFile
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.hasReferralOnFile = null;
    
                /**
                 * FullBVReferralCallOutputs referralEffectiveDate.
                 * @member {infinitusai.be.IDate|null|undefined} referralEffectiveDate
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.referralEffectiveDate = null;
    
                /**
                 * FullBVReferralCallOutputs referralRecertDate.
                 * @member {infinitusai.be.IDate|null|undefined} referralRecertDate
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.referralRecertDate = null;
    
                /**
                 * FullBVReferralCallOutputs referralNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} referralNumber
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.referralNumber = null;
    
                /**
                 * FullBVReferralCallOutputs referralNumVisitsApproved.
                 * @member {infinitusai.be.INullableInt32|null|undefined} referralNumVisitsApproved
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.referralNumVisitsApproved = null;
    
                /**
                 * FullBVReferralCallOutputs referralNumVisitsUsed.
                 * @member {infinitusai.be.INullableInt32|null|undefined} referralNumVisitsUsed
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.referralNumVisitsUsed = null;
    
                /**
                 * FullBVReferralCallOutputs referringPcpName.
                 * @member {infinitusai.be.INullableString|null|undefined} referringPcpName
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.referringPcpName = null;
    
                /**
                 * FullBVReferralCallOutputs referringPcpPhone.
                 * @member {infinitusai.be.INullableString|null|undefined} referringPcpPhone
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.referringPcpPhone = null;
    
                /**
                 * FullBVReferralCallOutputs isPriorAuthRequired.
                 * @member {infinitusai.be.INullableBool|null|undefined} isPriorAuthRequired
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.isPriorAuthRequired = null;
    
                /**
                 * FullBVReferralCallOutputs isPriorAuthOnFile.
                 * @member {infinitusai.be.INullableBool|null|undefined} isPriorAuthOnFile
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.isPriorAuthOnFile = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthStatus.
                 * @member {infinitusai.be.IPriorAuthStatusType|null|undefined} priorAuthStatus
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthStatus = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthApprovalStartDate.
                 * @member {infinitusai.be.IDate|null|undefined} priorAuthApprovalStartDate
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthApprovalStartDate = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthApprovalEndDate.
                 * @member {infinitusai.be.IDate|null|undefined} priorAuthApprovalEndDate
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthApprovalEndDate = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthApprovalNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthApprovalNumber
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthApprovalNumber = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthApprovedQuantity.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthApprovedQuantity
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthApprovedQuantity = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthTurnaroundNotes.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthTurnaroundNotes
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthTurnaroundNotes = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthDenialReason.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthDenialReason
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthDenialReason = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthDenialDate.
                 * @member {infinitusai.be.IDate|null|undefined} priorAuthDenialDate
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthDenialDate = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthSubmissionDate.
                 * @member {infinitusai.be.IDate|null|undefined} priorAuthSubmissionDate
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthSubmissionDate = null;
    
                /**
                 * FullBVReferralCallOutputs medicalPolicyAvailableOnWebsite.
                 * @member {infinitusai.be.INullableBool|null|undefined} medicalPolicyAvailableOnWebsite
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.medicalPolicyAvailableOnWebsite = null;
    
                /**
                 * FullBVReferralCallOutputs medicalPolicyNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} medicalPolicyNumber
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.medicalPolicyNumber = null;
    
                /**
                 * FullBVReferralCallOutputs isMedicalGroupAtRisk.
                 * @member {infinitusai.be.INullableBool|null|undefined} isMedicalGroupAtRisk
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.isMedicalGroupAtRisk = null;
    
                /**
                 * FullBVReferralCallOutputs medicalGroupName.
                 * @member {infinitusai.be.INullableString|null|undefined} medicalGroupName
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.medicalGroupName = null;
    
                /**
                 * FullBVReferralCallOutputs medicalGroupPhoneNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} medicalGroupPhoneNumber
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.medicalGroupPhoneNumber = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthRequiredCodes.
                 * @member {Array.<string>} priorAuthRequiredCodes
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthRequiredCodes = $util.emptyArray;
    
                /**
                 * FullBVReferralCallOutputs predeterminationRequirement.
                 * @member {infinitusai.be.IPredeterminationRequirementType|null|undefined} predeterminationRequirement
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationRequirement = null;
    
                /**
                 * FullBVReferralCallOutputs isPredeterminationOnFile.
                 * @member {infinitusai.be.INullableBool|null|undefined} isPredeterminationOnFile
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.isPredeterminationOnFile = null;
    
                /**
                 * FullBVReferralCallOutputs predeterminationApprovalNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} predeterminationApprovalNumber
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationApprovalNumber = null;
    
                /**
                 * FullBVReferralCallOutputs predeterminationTurnaroundNotes.
                 * @member {infinitusai.be.INullableString|null|undefined} predeterminationTurnaroundNotes
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationTurnaroundNotes = null;
    
                /**
                 * FullBVReferralCallOutputs isStepTherapyRequired.
                 * @member {infinitusai.be.INullableBool|null|undefined} isStepTherapyRequired
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.isStepTherapyRequired = null;
    
                /**
                 * FullBVReferralCallOutputs stepTherapyMethod.
                 * @member {infinitusai.be.IStepTherapyMethod|null|undefined} stepTherapyMethod
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.stepTherapyMethod = null;
    
                /**
                 * FullBVReferralCallOutputs stepTherapyTreatment.
                 * @member {infinitusai.be.INullableString|null|undefined} stepTherapyTreatment
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.stepTherapyTreatment = null;
    
                /**
                 * FullBVReferralCallOutputs specialtyPharmacyAvailable.
                 * @member {infinitusai.be.INullableBool|null|undefined} specialtyPharmacyAvailable
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.specialtyPharmacyAvailable = null;
    
                /**
                 * FullBVReferralCallOutputs specialtyPharmacy1Name.
                 * @member {infinitusai.be.INullableString|null|undefined} specialtyPharmacy1Name
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.specialtyPharmacy1Name = null;
    
                /**
                 * FullBVReferralCallOutputs specialtyPharmacy1Phone.
                 * @member {infinitusai.be.INullableString|null|undefined} specialtyPharmacy1Phone
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.specialtyPharmacy1Phone = null;
    
                /**
                 * FullBVReferralCallOutputs specialtyPharmacy1Fax.
                 * @member {infinitusai.be.INullableString|null|undefined} specialtyPharmacy1Fax
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.specialtyPharmacy1Fax = null;
    
                /**
                 * FullBVReferralCallOutputs hasSpecialtyPharmacyExclusions.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasSpecialtyPharmacyExclusions
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.hasSpecialtyPharmacyExclusions = null;
    
                /**
                 * FullBVReferralCallOutputs specialtyPharmacyExclusions.
                 * @member {infinitusai.be.INullableString|null|undefined} specialtyPharmacyExclusions
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.specialtyPharmacyExclusions = null;
    
                /**
                 * FullBVReferralCallOutputs preferredSpecialtyPharmacyOutputs.
                 * @member {Array.<infinitusai.be.IFullBVPreferredSpecialtyPharmacyOutputs>} preferredSpecialtyPharmacyOutputs
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.preferredSpecialtyPharmacyOutputs = $util.emptyArray;
    
                /**
                 * FullBVReferralCallOutputs priorAuthMethodEntity.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthMethodEntity
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthMethodEntity = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthMethodsV2.
                 * @member {infinitusai.be.IPriorAuthMethod|null|undefined} priorAuthMethodsV2
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthMethodsV2 = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthRequirements.
                 * @member {infinitusai.be.IMultiSelectPriorAuthRequirementType|null|undefined} priorAuthRequirements
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthRequirements = null;
    
                /**
                 * FullBVReferralCallOutputs predeterminationMethodType.
                 * @member {infinitusai.be.IPriorAuthAndPredeterminationMethod|null|undefined} predeterminationMethodType
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationMethodType = null;
    
                /**
                 * FullBVReferralCallOutputs predeterminationMethodEntity.
                 * @member {infinitusai.be.INullableString|null|undefined} predeterminationMethodEntity
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationMethodEntity = null;
    
                /**
                 * FullBVReferralCallOutputs predeterminationMethodContact.
                 * @member {infinitusai.be.INullableString|null|undefined} predeterminationMethodContact
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationMethodContact = null;
    
                /**
                 * FullBVReferralCallOutputs predeterminationStartDate.
                 * @member {infinitusai.be.IDate|null|undefined} predeterminationStartDate
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationStartDate = null;
    
                /**
                 * FullBVReferralCallOutputs predeterminationEndDate.
                 * @member {infinitusai.be.IDate|null|undefined} predeterminationEndDate
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationEndDate = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthMethods.
                 * @member {Object.<string,string>} priorAuthMethods
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthMethods = $util.emptyObject;
    
                /**
                 * FullBVReferralCallOutputs priorAuthMethodNotes.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthMethodNotes
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthMethodNotes = null;
    
                /**
                 * FullBVReferralCallOutputs predeterminationMethodNotes.
                 * @member {infinitusai.be.INullableString|null|undefined} predeterminationMethodNotes
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.predeterminationMethodNotes = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthMethodType.
                 * @member {infinitusai.be.IPriorAuthAndPredeterminationMethod|null|undefined} priorAuthMethodType
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthMethodType = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthMethodContact.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthMethodContact
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthMethodContact = null;
    
                /**
                 * FullBVReferralCallOutputs priorAuthCodes.
                 * @member {infinitusai.be.IMultiselectCodesType|null|undefined} priorAuthCodes
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.priorAuthCodes = null;
    
                /**
                 * FullBVReferralCallOutputs stepTherapyMethodNotes.
                 * @member {infinitusai.be.INullableString|null|undefined} stepTherapyMethodNotes
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.stepTherapyMethodNotes = null;
    
                /**
                 * FullBVReferralCallOutputs isPredeterminationRequired.
                 * @member {infinitusai.be.INullableBool|null|undefined} isPredeterminationRequired
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.isPredeterminationRequired = null;
    
                /**
                 * FullBVReferralCallOutputs isPredeterminationAvailable.
                 * @member {infinitusai.be.INullableBool|null|undefined} isPredeterminationAvailable
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.isPredeterminationAvailable = null;
    
                /**
                 * FullBVReferralCallOutputs buyAndBillAvailable.
                 * @member {infinitusai.be.INullableBool|null|undefined} buyAndBillAvailable
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.buyAndBillAvailable = null;
    
                /**
                 * FullBVReferralCallOutputs claimsAddress.
                 * @member {infinitusai.be.IStreetAddress|null|undefined} claimsAddress
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 */
                FullBVReferralCallOutputs.prototype.claimsAddress = null;
    
                /**
                 * Creates a new FullBVReferralCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVReferralCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FullBVReferralCallOutputs} FullBVReferralCallOutputs instance
                 */
                FullBVReferralCallOutputs.create = function create(properties) {
                    return new FullBVReferralCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified FullBVReferralCallOutputs message. Does not implicitly {@link infinitusai.be.FullBVReferralCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVReferralCallOutputs} message FullBVReferralCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVReferralCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isReferralRequired != null && Object.hasOwnProperty.call(message, "isReferralRequired"))
                        $root.infinitusai.be.NullableBool.encode(message.isReferralRequired, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.hasReferralOnFile != null && Object.hasOwnProperty.call(message, "hasReferralOnFile"))
                        $root.infinitusai.be.NullableBool.encode(message.hasReferralOnFile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.referralNumber != null && Object.hasOwnProperty.call(message, "referralNumber"))
                        $root.infinitusai.be.NullableString.encode(message.referralNumber, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.isPriorAuthRequired != null && Object.hasOwnProperty.call(message, "isPriorAuthRequired"))
                        $root.infinitusai.be.NullableBool.encode(message.isPriorAuthRequired, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.isPriorAuthOnFile != null && Object.hasOwnProperty.call(message, "isPriorAuthOnFile"))
                        $root.infinitusai.be.NullableBool.encode(message.isPriorAuthOnFile, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.priorAuthMethodNotes != null && Object.hasOwnProperty.call(message, "priorAuthMethodNotes"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthMethodNotes, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.priorAuthApprovalStartDate != null && Object.hasOwnProperty.call(message, "priorAuthApprovalStartDate"))
                        $root.infinitusai.be.Date.encode(message.priorAuthApprovalStartDate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.priorAuthApprovalEndDate != null && Object.hasOwnProperty.call(message, "priorAuthApprovalEndDate"))
                        $root.infinitusai.be.Date.encode(message.priorAuthApprovalEndDate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.priorAuthApprovalNumber != null && Object.hasOwnProperty.call(message, "priorAuthApprovalNumber"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthApprovalNumber, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.priorAuthTurnaroundNotes != null && Object.hasOwnProperty.call(message, "priorAuthTurnaroundNotes"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthTurnaroundNotes, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.isPredeterminationRequired != null && Object.hasOwnProperty.call(message, "isPredeterminationRequired"))
                        $root.infinitusai.be.NullableBool.encode(message.isPredeterminationRequired, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.isPredeterminationOnFile != null && Object.hasOwnProperty.call(message, "isPredeterminationOnFile"))
                        $root.infinitusai.be.NullableBool.encode(message.isPredeterminationOnFile, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.predeterminationApprovalNumber != null && Object.hasOwnProperty.call(message, "predeterminationApprovalNumber"))
                        $root.infinitusai.be.NullableString.encode(message.predeterminationApprovalNumber, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.predeterminationMethodNotes != null && Object.hasOwnProperty.call(message, "predeterminationMethodNotes"))
                        $root.infinitusai.be.NullableString.encode(message.predeterminationMethodNotes, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.isStepTherapyRequired != null && Object.hasOwnProperty.call(message, "isStepTherapyRequired"))
                        $root.infinitusai.be.NullableBool.encode(message.isStepTherapyRequired, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.stepTherapyMethodNotes != null && Object.hasOwnProperty.call(message, "stepTherapyMethodNotes"))
                        $root.infinitusai.be.NullableString.encode(message.stepTherapyMethodNotes, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.specialtyPharmacy1Name != null && Object.hasOwnProperty.call(message, "specialtyPharmacy1Name"))
                        $root.infinitusai.be.NullableString.encode(message.specialtyPharmacy1Name, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.specialtyPharmacy1Phone != null && Object.hasOwnProperty.call(message, "specialtyPharmacy1Phone"))
                        $root.infinitusai.be.NullableString.encode(message.specialtyPharmacy1Phone, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.priorAuthCodes != null && Object.hasOwnProperty.call(message, "priorAuthCodes"))
                        $root.infinitusai.be.MultiselectCodesType.encode(message.priorAuthCodes, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                    if (message.referringPcpName != null && Object.hasOwnProperty.call(message, "referringPcpName"))
                        $root.infinitusai.be.NullableString.encode(message.referringPcpName, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                    if (message.referringPcpPhone != null && Object.hasOwnProperty.call(message, "referringPcpPhone"))
                        $root.infinitusai.be.NullableString.encode(message.referringPcpPhone, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    if (message.specialtyPharmacyAvailable != null && Object.hasOwnProperty.call(message, "specialtyPharmacyAvailable"))
                        $root.infinitusai.be.NullableBool.encode(message.specialtyPharmacyAvailable, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                    if (message.isPredeterminationAvailable != null && Object.hasOwnProperty.call(message, "isPredeterminationAvailable"))
                        $root.infinitusai.be.NullableBool.encode(message.isPredeterminationAvailable, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                    if (message.priorAuthMethodType != null && Object.hasOwnProperty.call(message, "priorAuthMethodType"))
                        $root.infinitusai.be.PriorAuthAndPredeterminationMethod.encode(message.priorAuthMethodType, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                    if (message.priorAuthMethodEntity != null && Object.hasOwnProperty.call(message, "priorAuthMethodEntity"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthMethodEntity, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                    if (message.priorAuthMethodContact != null && Object.hasOwnProperty.call(message, "priorAuthMethodContact"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthMethodContact, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                    if (message.predeterminationMethodType != null && Object.hasOwnProperty.call(message, "predeterminationMethodType"))
                        $root.infinitusai.be.PriorAuthAndPredeterminationMethod.encode(message.predeterminationMethodType, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                    if (message.predeterminationMethodEntity != null && Object.hasOwnProperty.call(message, "predeterminationMethodEntity"))
                        $root.infinitusai.be.NullableString.encode(message.predeterminationMethodEntity, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                    if (message.predeterminationMethodContact != null && Object.hasOwnProperty.call(message, "predeterminationMethodContact"))
                        $root.infinitusai.be.NullableString.encode(message.predeterminationMethodContact, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                    if (message.priorAuthRequiredCodes != null && message.priorAuthRequiredCodes.length)
                        for (var i = 0; i < message.priorAuthRequiredCodes.length; ++i)
                            writer.uint32(/* id 31, wireType 2 =*/250).string(message.priorAuthRequiredCodes[i]);
                    if (message.stepTherapyMethod != null && Object.hasOwnProperty.call(message, "stepTherapyMethod"))
                        $root.infinitusai.be.StepTherapyMethod.encode(message.stepTherapyMethod, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                    if (message.stepTherapyTreatment != null && Object.hasOwnProperty.call(message, "stepTherapyTreatment"))
                        $root.infinitusai.be.NullableString.encode(message.stepTherapyTreatment, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                    if (message.buyAndBillAvailable != null && Object.hasOwnProperty.call(message, "buyAndBillAvailable"))
                        $root.infinitusai.be.NullableBool.encode(message.buyAndBillAvailable, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                    if (message.priorAuthMethods != null && Object.hasOwnProperty.call(message, "priorAuthMethods"))
                        for (var keys = Object.keys(message.priorAuthMethods), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 35, wireType 2 =*/282).fork().uint32(/* id 1, wireType 0 =*/8).int32(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.priorAuthMethods[keys[i]]).ldelim();
                    if (message.priorAuthRequirements != null && Object.hasOwnProperty.call(message, "priorAuthRequirements"))
                        $root.infinitusai.be.MultiSelectPriorAuthRequirementType.encode(message.priorAuthRequirements, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                    if (message.predeterminationTurnaroundNotes != null && Object.hasOwnProperty.call(message, "predeterminationTurnaroundNotes"))
                        $root.infinitusai.be.NullableString.encode(message.predeterminationTurnaroundNotes, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                    if (message.referralEffectiveDate != null && Object.hasOwnProperty.call(message, "referralEffectiveDate"))
                        $root.infinitusai.be.Date.encode(message.referralEffectiveDate, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                    if (message.referralRecertDate != null && Object.hasOwnProperty.call(message, "referralRecertDate"))
                        $root.infinitusai.be.Date.encode(message.referralRecertDate, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                    if (message.isMedicalGroupAtRisk != null && Object.hasOwnProperty.call(message, "isMedicalGroupAtRisk"))
                        $root.infinitusai.be.NullableBool.encode(message.isMedicalGroupAtRisk, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                    if (message.priorAuthMethodsV2 != null && Object.hasOwnProperty.call(message, "priorAuthMethodsV2"))
                        $root.infinitusai.be.PriorAuthMethod.encode(message.priorAuthMethodsV2, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                    if (message.hasSpecialtyPharmacyExclusions != null && Object.hasOwnProperty.call(message, "hasSpecialtyPharmacyExclusions"))
                        $root.infinitusai.be.NullableBool.encode(message.hasSpecialtyPharmacyExclusions, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                    if (message.specialtyPharmacyExclusions != null && Object.hasOwnProperty.call(message, "specialtyPharmacyExclusions"))
                        $root.infinitusai.be.NullableString.encode(message.specialtyPharmacyExclusions, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                    if (message.claimsAddress != null && Object.hasOwnProperty.call(message, "claimsAddress"))
                        $root.infinitusai.be.StreetAddress.encode(message.claimsAddress, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                    if (message.medicalGroupName != null && Object.hasOwnProperty.call(message, "medicalGroupName"))
                        $root.infinitusai.be.NullableString.encode(message.medicalGroupName, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
                    if (message.medicalGroupPhoneNumber != null && Object.hasOwnProperty.call(message, "medicalGroupPhoneNumber"))
                        $root.infinitusai.be.NullableString.encode(message.medicalGroupPhoneNumber, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                    if (message.medicalPolicyAvailableOnWebsite != null && Object.hasOwnProperty.call(message, "medicalPolicyAvailableOnWebsite"))
                        $root.infinitusai.be.NullableBool.encode(message.medicalPolicyAvailableOnWebsite, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                    if (message.priorAuthApprovedQuantity != null && Object.hasOwnProperty.call(message, "priorAuthApprovedQuantity"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthApprovedQuantity, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
                    if (message.medicalPolicyNumber != null && Object.hasOwnProperty.call(message, "medicalPolicyNumber"))
                        $root.infinitusai.be.NullableString.encode(message.medicalPolicyNumber, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                    if (message.predeterminationRequirement != null && Object.hasOwnProperty.call(message, "predeterminationRequirement"))
                        $root.infinitusai.be.PredeterminationRequirementType.encode(message.predeterminationRequirement, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                    if (message.referralRequirements != null && Object.hasOwnProperty.call(message, "referralRequirements"))
                        $root.infinitusai.be.ReferralRequirementsType.encode(message.referralRequirements, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                    if (message.preferredSpecialtyPharmacyOutputs != null && message.preferredSpecialtyPharmacyOutputs.length)
                        for (var i = 0; i < message.preferredSpecialtyPharmacyOutputs.length; ++i)
                            $root.infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs.encode(message.preferredSpecialtyPharmacyOutputs[i], writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
                    if (message.referralNumVisitsApproved != null && Object.hasOwnProperty.call(message, "referralNumVisitsApproved"))
                        $root.infinitusai.be.NullableInt32.encode(message.referralNumVisitsApproved, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
                    if (message.referralNumVisitsUsed != null && Object.hasOwnProperty.call(message, "referralNumVisitsUsed"))
                        $root.infinitusai.be.NullableInt32.encode(message.referralNumVisitsUsed, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
                    if (message.priorAuthStatus != null && Object.hasOwnProperty.call(message, "priorAuthStatus"))
                        $root.infinitusai.be.PriorAuthStatusType.encode(message.priorAuthStatus, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
                    if (message.priorAuthDenialReason != null && Object.hasOwnProperty.call(message, "priorAuthDenialReason"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthDenialReason, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
                    if (message.priorAuthDenialDate != null && Object.hasOwnProperty.call(message, "priorAuthDenialDate"))
                        $root.infinitusai.be.Date.encode(message.priorAuthDenialDate, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
                    if (message.priorAuthSubmissionDate != null && Object.hasOwnProperty.call(message, "priorAuthSubmissionDate"))
                        $root.infinitusai.be.Date.encode(message.priorAuthSubmissionDate, writer.uint32(/* id 58, wireType 2 =*/466).fork()).ldelim();
                    if (message.predeterminationStartDate != null && Object.hasOwnProperty.call(message, "predeterminationStartDate"))
                        $root.infinitusai.be.Date.encode(message.predeterminationStartDate, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
                    if (message.predeterminationEndDate != null && Object.hasOwnProperty.call(message, "predeterminationEndDate"))
                        $root.infinitusai.be.Date.encode(message.predeterminationEndDate, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
                    if (message.specialtyPharmacy1Fax != null && Object.hasOwnProperty.call(message, "specialtyPharmacy1Fax"))
                        $root.infinitusai.be.NullableString.encode(message.specialtyPharmacy1Fax, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FullBVReferralCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.FullBVReferralCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVReferralCallOutputs} message FullBVReferralCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVReferralCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FullBVReferralCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FullBVReferralCallOutputs} FullBVReferralCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVReferralCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FullBVReferralCallOutputs(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isReferralRequired = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 51: {
                                message.referralRequirements = $root.infinitusai.be.ReferralRequirementsType.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.hasReferralOnFile = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 38: {
                                message.referralEffectiveDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 39: {
                                message.referralRecertDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.referralNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 53: {
                                message.referralNumVisitsApproved = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        case 54: {
                                message.referralNumVisitsUsed = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        case 20: {
                                message.referringPcpName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 21: {
                                message.referringPcpPhone = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.isPriorAuthRequired = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.isPriorAuthOnFile = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 55: {
                                message.priorAuthStatus = $root.infinitusai.be.PriorAuthStatusType.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.priorAuthApprovalStartDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.priorAuthApprovalEndDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.priorAuthApprovalNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 48: {
                                message.priorAuthApprovedQuantity = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.priorAuthTurnaroundNotes = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 56: {
                                message.priorAuthDenialReason = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 57: {
                                message.priorAuthDenialDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 58: {
                                message.priorAuthSubmissionDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 47: {
                                message.medicalPolicyAvailableOnWebsite = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 49: {
                                message.medicalPolicyNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 40: {
                                message.isMedicalGroupAtRisk = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 45: {
                                message.medicalGroupName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 46: {
                                message.medicalGroupPhoneNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 31: {
                                if (!(message.priorAuthRequiredCodes && message.priorAuthRequiredCodes.length))
                                    message.priorAuthRequiredCodes = [];
                                message.priorAuthRequiredCodes.push(reader.string());
                                break;
                            }
                        case 50: {
                                message.predeterminationRequirement = $root.infinitusai.be.PredeterminationRequirementType.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.isPredeterminationOnFile = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.predeterminationApprovalNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 37: {
                                message.predeterminationTurnaroundNotes = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.isStepTherapyRequired = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 32: {
                                message.stepTherapyMethod = $root.infinitusai.be.StepTherapyMethod.decode(reader, reader.uint32());
                                break;
                            }
                        case 33: {
                                message.stepTherapyTreatment = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 22: {
                                message.specialtyPharmacyAvailable = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.specialtyPharmacy1Name = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                message.specialtyPharmacy1Phone = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 61: {
                                message.specialtyPharmacy1Fax = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 42: {
                                message.hasSpecialtyPharmacyExclusions = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 43: {
                                message.specialtyPharmacyExclusions = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 52: {
                                if (!(message.preferredSpecialtyPharmacyOutputs && message.preferredSpecialtyPharmacyOutputs.length))
                                    message.preferredSpecialtyPharmacyOutputs = [];
                                message.preferredSpecialtyPharmacyOutputs.push($root.infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs.decode(reader, reader.uint32()));
                                break;
                            }
                        case 26: {
                                message.priorAuthMethodEntity = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 41: {
                                message.priorAuthMethodsV2 = $root.infinitusai.be.PriorAuthMethod.decode(reader, reader.uint32());
                                break;
                            }
                        case 36: {
                                message.priorAuthRequirements = $root.infinitusai.be.MultiSelectPriorAuthRequirementType.decode(reader, reader.uint32());
                                break;
                            }
                        case 28: {
                                message.predeterminationMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.decode(reader, reader.uint32());
                                break;
                            }
                        case 29: {
                                message.predeterminationMethodEntity = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 30: {
                                message.predeterminationMethodContact = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 59: {
                                message.predeterminationStartDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 60: {
                                message.predeterminationEndDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 35: {
                                if (message.priorAuthMethods === $util.emptyObject)
                                    message.priorAuthMethods = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int32();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.priorAuthMethods[key] = value;
                                break;
                            }
                        case 6: {
                                message.priorAuthMethodNotes = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.predeterminationMethodNotes = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 25: {
                                message.priorAuthMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.decode(reader, reader.uint32());
                                break;
                            }
                        case 27: {
                                message.priorAuthMethodContact = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.priorAuthCodes = $root.infinitusai.be.MultiselectCodesType.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.stepTherapyMethodNotes = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.isPredeterminationRequired = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 24: {
                                message.isPredeterminationAvailable = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 34: {
                                message.buyAndBillAvailable = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 44: {
                                message.claimsAddress = $root.infinitusai.be.StreetAddress.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FullBVReferralCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FullBVReferralCallOutputs} FullBVReferralCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVReferralCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FullBVReferralCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FullBVReferralCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isReferralRequired != null && message.hasOwnProperty("isReferralRequired")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isReferralRequired);
                        if (error)
                            return "isReferralRequired." + error;
                    }
                    if (message.referralRequirements != null && message.hasOwnProperty("referralRequirements")) {
                        var error = $root.infinitusai.be.ReferralRequirementsType.verify(message.referralRequirements);
                        if (error)
                            return "referralRequirements." + error;
                    }
                    if (message.hasReferralOnFile != null && message.hasOwnProperty("hasReferralOnFile")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasReferralOnFile);
                        if (error)
                            return "hasReferralOnFile." + error;
                    }
                    if (message.referralEffectiveDate != null && message.hasOwnProperty("referralEffectiveDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.referralEffectiveDate);
                        if (error)
                            return "referralEffectiveDate." + error;
                    }
                    if (message.referralRecertDate != null && message.hasOwnProperty("referralRecertDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.referralRecertDate);
                        if (error)
                            return "referralRecertDate." + error;
                    }
                    if (message.referralNumber != null && message.hasOwnProperty("referralNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.referralNumber);
                        if (error)
                            return "referralNumber." + error;
                    }
                    if (message.referralNumVisitsApproved != null && message.hasOwnProperty("referralNumVisitsApproved")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.referralNumVisitsApproved);
                        if (error)
                            return "referralNumVisitsApproved." + error;
                    }
                    if (message.referralNumVisitsUsed != null && message.hasOwnProperty("referralNumVisitsUsed")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.referralNumVisitsUsed);
                        if (error)
                            return "referralNumVisitsUsed." + error;
                    }
                    if (message.referringPcpName != null && message.hasOwnProperty("referringPcpName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.referringPcpName);
                        if (error)
                            return "referringPcpName." + error;
                    }
                    if (message.referringPcpPhone != null && message.hasOwnProperty("referringPcpPhone")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.referringPcpPhone);
                        if (error)
                            return "referringPcpPhone." + error;
                    }
                    if (message.isPriorAuthRequired != null && message.hasOwnProperty("isPriorAuthRequired")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isPriorAuthRequired);
                        if (error)
                            return "isPriorAuthRequired." + error;
                    }
                    if (message.isPriorAuthOnFile != null && message.hasOwnProperty("isPriorAuthOnFile")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isPriorAuthOnFile);
                        if (error)
                            return "isPriorAuthOnFile." + error;
                    }
                    if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus")) {
                        var error = $root.infinitusai.be.PriorAuthStatusType.verify(message.priorAuthStatus);
                        if (error)
                            return "priorAuthStatus." + error;
                    }
                    if (message.priorAuthApprovalStartDate != null && message.hasOwnProperty("priorAuthApprovalStartDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.priorAuthApprovalStartDate);
                        if (error)
                            return "priorAuthApprovalStartDate." + error;
                    }
                    if (message.priorAuthApprovalEndDate != null && message.hasOwnProperty("priorAuthApprovalEndDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.priorAuthApprovalEndDate);
                        if (error)
                            return "priorAuthApprovalEndDate." + error;
                    }
                    if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthApprovalNumber);
                        if (error)
                            return "priorAuthApprovalNumber." + error;
                    }
                    if (message.priorAuthApprovedQuantity != null && message.hasOwnProperty("priorAuthApprovedQuantity")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthApprovedQuantity);
                        if (error)
                            return "priorAuthApprovedQuantity." + error;
                    }
                    if (message.priorAuthTurnaroundNotes != null && message.hasOwnProperty("priorAuthTurnaroundNotes")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthTurnaroundNotes);
                        if (error)
                            return "priorAuthTurnaroundNotes." + error;
                    }
                    if (message.priorAuthDenialReason != null && message.hasOwnProperty("priorAuthDenialReason")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthDenialReason);
                        if (error)
                            return "priorAuthDenialReason." + error;
                    }
                    if (message.priorAuthDenialDate != null && message.hasOwnProperty("priorAuthDenialDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.priorAuthDenialDate);
                        if (error)
                            return "priorAuthDenialDate." + error;
                    }
                    if (message.priorAuthSubmissionDate != null && message.hasOwnProperty("priorAuthSubmissionDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.priorAuthSubmissionDate);
                        if (error)
                            return "priorAuthSubmissionDate." + error;
                    }
                    if (message.medicalPolicyAvailableOnWebsite != null && message.hasOwnProperty("medicalPolicyAvailableOnWebsite")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.medicalPolicyAvailableOnWebsite);
                        if (error)
                            return "medicalPolicyAvailableOnWebsite." + error;
                    }
                    if (message.medicalPolicyNumber != null && message.hasOwnProperty("medicalPolicyNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.medicalPolicyNumber);
                        if (error)
                            return "medicalPolicyNumber." + error;
                    }
                    if (message.isMedicalGroupAtRisk != null && message.hasOwnProperty("isMedicalGroupAtRisk")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isMedicalGroupAtRisk);
                        if (error)
                            return "isMedicalGroupAtRisk." + error;
                    }
                    if (message.medicalGroupName != null && message.hasOwnProperty("medicalGroupName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.medicalGroupName);
                        if (error)
                            return "medicalGroupName." + error;
                    }
                    if (message.medicalGroupPhoneNumber != null && message.hasOwnProperty("medicalGroupPhoneNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.medicalGroupPhoneNumber);
                        if (error)
                            return "medicalGroupPhoneNumber." + error;
                    }
                    if (message.priorAuthRequiredCodes != null && message.hasOwnProperty("priorAuthRequiredCodes")) {
                        if (!Array.isArray(message.priorAuthRequiredCodes))
                            return "priorAuthRequiredCodes: array expected";
                        for (var i = 0; i < message.priorAuthRequiredCodes.length; ++i)
                            if (!$util.isString(message.priorAuthRequiredCodes[i]))
                                return "priorAuthRequiredCodes: string[] expected";
                    }
                    if (message.predeterminationRequirement != null && message.hasOwnProperty("predeterminationRequirement")) {
                        var error = $root.infinitusai.be.PredeterminationRequirementType.verify(message.predeterminationRequirement);
                        if (error)
                            return "predeterminationRequirement." + error;
                    }
                    if (message.isPredeterminationOnFile != null && message.hasOwnProperty("isPredeterminationOnFile")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isPredeterminationOnFile);
                        if (error)
                            return "isPredeterminationOnFile." + error;
                    }
                    if (message.predeterminationApprovalNumber != null && message.hasOwnProperty("predeterminationApprovalNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.predeterminationApprovalNumber);
                        if (error)
                            return "predeterminationApprovalNumber." + error;
                    }
                    if (message.predeterminationTurnaroundNotes != null && message.hasOwnProperty("predeterminationTurnaroundNotes")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.predeterminationTurnaroundNotes);
                        if (error)
                            return "predeterminationTurnaroundNotes." + error;
                    }
                    if (message.isStepTherapyRequired != null && message.hasOwnProperty("isStepTherapyRequired")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isStepTherapyRequired);
                        if (error)
                            return "isStepTherapyRequired." + error;
                    }
                    if (message.stepTherapyMethod != null && message.hasOwnProperty("stepTherapyMethod")) {
                        var error = $root.infinitusai.be.StepTherapyMethod.verify(message.stepTherapyMethod);
                        if (error)
                            return "stepTherapyMethod." + error;
                    }
                    if (message.stepTherapyTreatment != null && message.hasOwnProperty("stepTherapyTreatment")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.stepTherapyTreatment);
                        if (error)
                            return "stepTherapyTreatment." + error;
                    }
                    if (message.specialtyPharmacyAvailable != null && message.hasOwnProperty("specialtyPharmacyAvailable")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.specialtyPharmacyAvailable);
                        if (error)
                            return "specialtyPharmacyAvailable." + error;
                    }
                    if (message.specialtyPharmacy1Name != null && message.hasOwnProperty("specialtyPharmacy1Name")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.specialtyPharmacy1Name);
                        if (error)
                            return "specialtyPharmacy1Name." + error;
                    }
                    if (message.specialtyPharmacy1Phone != null && message.hasOwnProperty("specialtyPharmacy1Phone")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.specialtyPharmacy1Phone);
                        if (error)
                            return "specialtyPharmacy1Phone." + error;
                    }
                    if (message.specialtyPharmacy1Fax != null && message.hasOwnProperty("specialtyPharmacy1Fax")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.specialtyPharmacy1Fax);
                        if (error)
                            return "specialtyPharmacy1Fax." + error;
                    }
                    if (message.hasSpecialtyPharmacyExclusions != null && message.hasOwnProperty("hasSpecialtyPharmacyExclusions")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasSpecialtyPharmacyExclusions);
                        if (error)
                            return "hasSpecialtyPharmacyExclusions." + error;
                    }
                    if (message.specialtyPharmacyExclusions != null && message.hasOwnProperty("specialtyPharmacyExclusions")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.specialtyPharmacyExclusions);
                        if (error)
                            return "specialtyPharmacyExclusions." + error;
                    }
                    if (message.preferredSpecialtyPharmacyOutputs != null && message.hasOwnProperty("preferredSpecialtyPharmacyOutputs")) {
                        if (!Array.isArray(message.preferredSpecialtyPharmacyOutputs))
                            return "preferredSpecialtyPharmacyOutputs: array expected";
                        for (var i = 0; i < message.preferredSpecialtyPharmacyOutputs.length; ++i) {
                            var error = $root.infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs.verify(message.preferredSpecialtyPharmacyOutputs[i]);
                            if (error)
                                return "preferredSpecialtyPharmacyOutputs." + error;
                        }
                    }
                    if (message.priorAuthMethodEntity != null && message.hasOwnProperty("priorAuthMethodEntity")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthMethodEntity);
                        if (error)
                            return "priorAuthMethodEntity." + error;
                    }
                    if (message.priorAuthMethodsV2 != null && message.hasOwnProperty("priorAuthMethodsV2")) {
                        var error = $root.infinitusai.be.PriorAuthMethod.verify(message.priorAuthMethodsV2);
                        if (error)
                            return "priorAuthMethodsV2." + error;
                    }
                    if (message.priorAuthRequirements != null && message.hasOwnProperty("priorAuthRequirements")) {
                        var error = $root.infinitusai.be.MultiSelectPriorAuthRequirementType.verify(message.priorAuthRequirements);
                        if (error)
                            return "priorAuthRequirements." + error;
                    }
                    if (message.predeterminationMethodType != null && message.hasOwnProperty("predeterminationMethodType")) {
                        var error = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.verify(message.predeterminationMethodType);
                        if (error)
                            return "predeterminationMethodType." + error;
                    }
                    if (message.predeterminationMethodEntity != null && message.hasOwnProperty("predeterminationMethodEntity")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.predeterminationMethodEntity);
                        if (error)
                            return "predeterminationMethodEntity." + error;
                    }
                    if (message.predeterminationMethodContact != null && message.hasOwnProperty("predeterminationMethodContact")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.predeterminationMethodContact);
                        if (error)
                            return "predeterminationMethodContact." + error;
                    }
                    if (message.predeterminationStartDate != null && message.hasOwnProperty("predeterminationStartDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.predeterminationStartDate);
                        if (error)
                            return "predeterminationStartDate." + error;
                    }
                    if (message.predeterminationEndDate != null && message.hasOwnProperty("predeterminationEndDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.predeterminationEndDate);
                        if (error)
                            return "predeterminationEndDate." + error;
                    }
                    if (message.priorAuthMethods != null && message.hasOwnProperty("priorAuthMethods")) {
                        if (!$util.isObject(message.priorAuthMethods))
                            return "priorAuthMethods: object expected";
                        var key = Object.keys(message.priorAuthMethods);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key32Re.test(key[i]))
                                return "priorAuthMethods: integer key{k:int32} expected";
                            if (!$util.isString(message.priorAuthMethods[key[i]]))
                                return "priorAuthMethods: string{k:int32} expected";
                        }
                    }
                    if (message.priorAuthMethodNotes != null && message.hasOwnProperty("priorAuthMethodNotes")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthMethodNotes);
                        if (error)
                            return "priorAuthMethodNotes." + error;
                    }
                    if (message.predeterminationMethodNotes != null && message.hasOwnProperty("predeterminationMethodNotes")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.predeterminationMethodNotes);
                        if (error)
                            return "predeterminationMethodNotes." + error;
                    }
                    if (message.priorAuthMethodType != null && message.hasOwnProperty("priorAuthMethodType")) {
                        var error = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.verify(message.priorAuthMethodType);
                        if (error)
                            return "priorAuthMethodType." + error;
                    }
                    if (message.priorAuthMethodContact != null && message.hasOwnProperty("priorAuthMethodContact")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthMethodContact);
                        if (error)
                            return "priorAuthMethodContact." + error;
                    }
                    if (message.priorAuthCodes != null && message.hasOwnProperty("priorAuthCodes")) {
                        var error = $root.infinitusai.be.MultiselectCodesType.verify(message.priorAuthCodes);
                        if (error)
                            return "priorAuthCodes." + error;
                    }
                    if (message.stepTherapyMethodNotes != null && message.hasOwnProperty("stepTherapyMethodNotes")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.stepTherapyMethodNotes);
                        if (error)
                            return "stepTherapyMethodNotes." + error;
                    }
                    if (message.isPredeterminationRequired != null && message.hasOwnProperty("isPredeterminationRequired")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isPredeterminationRequired);
                        if (error)
                            return "isPredeterminationRequired." + error;
                    }
                    if (message.isPredeterminationAvailable != null && message.hasOwnProperty("isPredeterminationAvailable")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isPredeterminationAvailable);
                        if (error)
                            return "isPredeterminationAvailable." + error;
                    }
                    if (message.buyAndBillAvailable != null && message.hasOwnProperty("buyAndBillAvailable")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.buyAndBillAvailable);
                        if (error)
                            return "buyAndBillAvailable." + error;
                    }
                    if (message.claimsAddress != null && message.hasOwnProperty("claimsAddress")) {
                        var error = $root.infinitusai.be.StreetAddress.verify(message.claimsAddress);
                        if (error)
                            return "claimsAddress." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FullBVReferralCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FullBVReferralCallOutputs} FullBVReferralCallOutputs
                 */
                FullBVReferralCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FullBVReferralCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.FullBVReferralCallOutputs();
                    if (object.isReferralRequired != null) {
                        if (typeof object.isReferralRequired !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.isReferralRequired: object expected");
                        message.isReferralRequired = $root.infinitusai.be.NullableBool.fromObject(object.isReferralRequired);
                    }
                    if (object.referralRequirements != null) {
                        if (typeof object.referralRequirements !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.referralRequirements: object expected");
                        message.referralRequirements = $root.infinitusai.be.ReferralRequirementsType.fromObject(object.referralRequirements);
                    }
                    if (object.hasReferralOnFile != null) {
                        if (typeof object.hasReferralOnFile !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.hasReferralOnFile: object expected");
                        message.hasReferralOnFile = $root.infinitusai.be.NullableBool.fromObject(object.hasReferralOnFile);
                    }
                    if (object.referralEffectiveDate != null) {
                        if (typeof object.referralEffectiveDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.referralEffectiveDate: object expected");
                        message.referralEffectiveDate = $root.infinitusai.be.Date.fromObject(object.referralEffectiveDate);
                    }
                    if (object.referralRecertDate != null) {
                        if (typeof object.referralRecertDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.referralRecertDate: object expected");
                        message.referralRecertDate = $root.infinitusai.be.Date.fromObject(object.referralRecertDate);
                    }
                    if (object.referralNumber != null) {
                        if (typeof object.referralNumber !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.referralNumber: object expected");
                        message.referralNumber = $root.infinitusai.be.NullableString.fromObject(object.referralNumber);
                    }
                    if (object.referralNumVisitsApproved != null) {
                        if (typeof object.referralNumVisitsApproved !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.referralNumVisitsApproved: object expected");
                        message.referralNumVisitsApproved = $root.infinitusai.be.NullableInt32.fromObject(object.referralNumVisitsApproved);
                    }
                    if (object.referralNumVisitsUsed != null) {
                        if (typeof object.referralNumVisitsUsed !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.referralNumVisitsUsed: object expected");
                        message.referralNumVisitsUsed = $root.infinitusai.be.NullableInt32.fromObject(object.referralNumVisitsUsed);
                    }
                    if (object.referringPcpName != null) {
                        if (typeof object.referringPcpName !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.referringPcpName: object expected");
                        message.referringPcpName = $root.infinitusai.be.NullableString.fromObject(object.referringPcpName);
                    }
                    if (object.referringPcpPhone != null) {
                        if (typeof object.referringPcpPhone !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.referringPcpPhone: object expected");
                        message.referringPcpPhone = $root.infinitusai.be.NullableString.fromObject(object.referringPcpPhone);
                    }
                    if (object.isPriorAuthRequired != null) {
                        if (typeof object.isPriorAuthRequired !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.isPriorAuthRequired: object expected");
                        message.isPriorAuthRequired = $root.infinitusai.be.NullableBool.fromObject(object.isPriorAuthRequired);
                    }
                    if (object.isPriorAuthOnFile != null) {
                        if (typeof object.isPriorAuthOnFile !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.isPriorAuthOnFile: object expected");
                        message.isPriorAuthOnFile = $root.infinitusai.be.NullableBool.fromObject(object.isPriorAuthOnFile);
                    }
                    if (object.priorAuthStatus != null) {
                        if (typeof object.priorAuthStatus !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthStatus: object expected");
                        message.priorAuthStatus = $root.infinitusai.be.PriorAuthStatusType.fromObject(object.priorAuthStatus);
                    }
                    if (object.priorAuthApprovalStartDate != null) {
                        if (typeof object.priorAuthApprovalStartDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthApprovalStartDate: object expected");
                        message.priorAuthApprovalStartDate = $root.infinitusai.be.Date.fromObject(object.priorAuthApprovalStartDate);
                    }
                    if (object.priorAuthApprovalEndDate != null) {
                        if (typeof object.priorAuthApprovalEndDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthApprovalEndDate: object expected");
                        message.priorAuthApprovalEndDate = $root.infinitusai.be.Date.fromObject(object.priorAuthApprovalEndDate);
                    }
                    if (object.priorAuthApprovalNumber != null) {
                        if (typeof object.priorAuthApprovalNumber !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthApprovalNumber: object expected");
                        message.priorAuthApprovalNumber = $root.infinitusai.be.NullableString.fromObject(object.priorAuthApprovalNumber);
                    }
                    if (object.priorAuthApprovedQuantity != null) {
                        if (typeof object.priorAuthApprovedQuantity !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthApprovedQuantity: object expected");
                        message.priorAuthApprovedQuantity = $root.infinitusai.be.NullableString.fromObject(object.priorAuthApprovedQuantity);
                    }
                    if (object.priorAuthTurnaroundNotes != null) {
                        if (typeof object.priorAuthTurnaroundNotes !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthTurnaroundNotes: object expected");
                        message.priorAuthTurnaroundNotes = $root.infinitusai.be.NullableString.fromObject(object.priorAuthTurnaroundNotes);
                    }
                    if (object.priorAuthDenialReason != null) {
                        if (typeof object.priorAuthDenialReason !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthDenialReason: object expected");
                        message.priorAuthDenialReason = $root.infinitusai.be.NullableString.fromObject(object.priorAuthDenialReason);
                    }
                    if (object.priorAuthDenialDate != null) {
                        if (typeof object.priorAuthDenialDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthDenialDate: object expected");
                        message.priorAuthDenialDate = $root.infinitusai.be.Date.fromObject(object.priorAuthDenialDate);
                    }
                    if (object.priorAuthSubmissionDate != null) {
                        if (typeof object.priorAuthSubmissionDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthSubmissionDate: object expected");
                        message.priorAuthSubmissionDate = $root.infinitusai.be.Date.fromObject(object.priorAuthSubmissionDate);
                    }
                    if (object.medicalPolicyAvailableOnWebsite != null) {
                        if (typeof object.medicalPolicyAvailableOnWebsite !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.medicalPolicyAvailableOnWebsite: object expected");
                        message.medicalPolicyAvailableOnWebsite = $root.infinitusai.be.NullableBool.fromObject(object.medicalPolicyAvailableOnWebsite);
                    }
                    if (object.medicalPolicyNumber != null) {
                        if (typeof object.medicalPolicyNumber !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.medicalPolicyNumber: object expected");
                        message.medicalPolicyNumber = $root.infinitusai.be.NullableString.fromObject(object.medicalPolicyNumber);
                    }
                    if (object.isMedicalGroupAtRisk != null) {
                        if (typeof object.isMedicalGroupAtRisk !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.isMedicalGroupAtRisk: object expected");
                        message.isMedicalGroupAtRisk = $root.infinitusai.be.NullableBool.fromObject(object.isMedicalGroupAtRisk);
                    }
                    if (object.medicalGroupName != null) {
                        if (typeof object.medicalGroupName !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.medicalGroupName: object expected");
                        message.medicalGroupName = $root.infinitusai.be.NullableString.fromObject(object.medicalGroupName);
                    }
                    if (object.medicalGroupPhoneNumber != null) {
                        if (typeof object.medicalGroupPhoneNumber !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.medicalGroupPhoneNumber: object expected");
                        message.medicalGroupPhoneNumber = $root.infinitusai.be.NullableString.fromObject(object.medicalGroupPhoneNumber);
                    }
                    if (object.priorAuthRequiredCodes) {
                        if (!Array.isArray(object.priorAuthRequiredCodes))
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthRequiredCodes: array expected");
                        message.priorAuthRequiredCodes = [];
                        for (var i = 0; i < object.priorAuthRequiredCodes.length; ++i)
                            message.priorAuthRequiredCodes[i] = String(object.priorAuthRequiredCodes[i]);
                    }
                    if (object.predeterminationRequirement != null) {
                        if (typeof object.predeterminationRequirement !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationRequirement: object expected");
                        message.predeterminationRequirement = $root.infinitusai.be.PredeterminationRequirementType.fromObject(object.predeterminationRequirement);
                    }
                    if (object.isPredeterminationOnFile != null) {
                        if (typeof object.isPredeterminationOnFile !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.isPredeterminationOnFile: object expected");
                        message.isPredeterminationOnFile = $root.infinitusai.be.NullableBool.fromObject(object.isPredeterminationOnFile);
                    }
                    if (object.predeterminationApprovalNumber != null) {
                        if (typeof object.predeterminationApprovalNumber !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationApprovalNumber: object expected");
                        message.predeterminationApprovalNumber = $root.infinitusai.be.NullableString.fromObject(object.predeterminationApprovalNumber);
                    }
                    if (object.predeterminationTurnaroundNotes != null) {
                        if (typeof object.predeterminationTurnaroundNotes !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationTurnaroundNotes: object expected");
                        message.predeterminationTurnaroundNotes = $root.infinitusai.be.NullableString.fromObject(object.predeterminationTurnaroundNotes);
                    }
                    if (object.isStepTherapyRequired != null) {
                        if (typeof object.isStepTherapyRequired !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.isStepTherapyRequired: object expected");
                        message.isStepTherapyRequired = $root.infinitusai.be.NullableBool.fromObject(object.isStepTherapyRequired);
                    }
                    if (object.stepTherapyMethod != null) {
                        if (typeof object.stepTherapyMethod !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.stepTherapyMethod: object expected");
                        message.stepTherapyMethod = $root.infinitusai.be.StepTherapyMethod.fromObject(object.stepTherapyMethod);
                    }
                    if (object.stepTherapyTreatment != null) {
                        if (typeof object.stepTherapyTreatment !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.stepTherapyTreatment: object expected");
                        message.stepTherapyTreatment = $root.infinitusai.be.NullableString.fromObject(object.stepTherapyTreatment);
                    }
                    if (object.specialtyPharmacyAvailable != null) {
                        if (typeof object.specialtyPharmacyAvailable !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.specialtyPharmacyAvailable: object expected");
                        message.specialtyPharmacyAvailable = $root.infinitusai.be.NullableBool.fromObject(object.specialtyPharmacyAvailable);
                    }
                    if (object.specialtyPharmacy1Name != null) {
                        if (typeof object.specialtyPharmacy1Name !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.specialtyPharmacy1Name: object expected");
                        message.specialtyPharmacy1Name = $root.infinitusai.be.NullableString.fromObject(object.specialtyPharmacy1Name);
                    }
                    if (object.specialtyPharmacy1Phone != null) {
                        if (typeof object.specialtyPharmacy1Phone !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.specialtyPharmacy1Phone: object expected");
                        message.specialtyPharmacy1Phone = $root.infinitusai.be.NullableString.fromObject(object.specialtyPharmacy1Phone);
                    }
                    if (object.specialtyPharmacy1Fax != null) {
                        if (typeof object.specialtyPharmacy1Fax !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.specialtyPharmacy1Fax: object expected");
                        message.specialtyPharmacy1Fax = $root.infinitusai.be.NullableString.fromObject(object.specialtyPharmacy1Fax);
                    }
                    if (object.hasSpecialtyPharmacyExclusions != null) {
                        if (typeof object.hasSpecialtyPharmacyExclusions !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.hasSpecialtyPharmacyExclusions: object expected");
                        message.hasSpecialtyPharmacyExclusions = $root.infinitusai.be.NullableBool.fromObject(object.hasSpecialtyPharmacyExclusions);
                    }
                    if (object.specialtyPharmacyExclusions != null) {
                        if (typeof object.specialtyPharmacyExclusions !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.specialtyPharmacyExclusions: object expected");
                        message.specialtyPharmacyExclusions = $root.infinitusai.be.NullableString.fromObject(object.specialtyPharmacyExclusions);
                    }
                    if (object.preferredSpecialtyPharmacyOutputs) {
                        if (!Array.isArray(object.preferredSpecialtyPharmacyOutputs))
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.preferredSpecialtyPharmacyOutputs: array expected");
                        message.preferredSpecialtyPharmacyOutputs = [];
                        for (var i = 0; i < object.preferredSpecialtyPharmacyOutputs.length; ++i) {
                            if (typeof object.preferredSpecialtyPharmacyOutputs[i] !== "object")
                                throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.preferredSpecialtyPharmacyOutputs: object expected");
                            message.preferredSpecialtyPharmacyOutputs[i] = $root.infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs.fromObject(object.preferredSpecialtyPharmacyOutputs[i]);
                        }
                    }
                    if (object.priorAuthMethodEntity != null) {
                        if (typeof object.priorAuthMethodEntity !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthMethodEntity: object expected");
                        message.priorAuthMethodEntity = $root.infinitusai.be.NullableString.fromObject(object.priorAuthMethodEntity);
                    }
                    if (object.priorAuthMethodsV2 != null) {
                        if (typeof object.priorAuthMethodsV2 !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthMethodsV2: object expected");
                        message.priorAuthMethodsV2 = $root.infinitusai.be.PriorAuthMethod.fromObject(object.priorAuthMethodsV2);
                    }
                    if (object.priorAuthRequirements != null) {
                        if (typeof object.priorAuthRequirements !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthRequirements: object expected");
                        message.priorAuthRequirements = $root.infinitusai.be.MultiSelectPriorAuthRequirementType.fromObject(object.priorAuthRequirements);
                    }
                    if (object.predeterminationMethodType != null) {
                        if (typeof object.predeterminationMethodType !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationMethodType: object expected");
                        message.predeterminationMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.fromObject(object.predeterminationMethodType);
                    }
                    if (object.predeterminationMethodEntity != null) {
                        if (typeof object.predeterminationMethodEntity !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationMethodEntity: object expected");
                        message.predeterminationMethodEntity = $root.infinitusai.be.NullableString.fromObject(object.predeterminationMethodEntity);
                    }
                    if (object.predeterminationMethodContact != null) {
                        if (typeof object.predeterminationMethodContact !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationMethodContact: object expected");
                        message.predeterminationMethodContact = $root.infinitusai.be.NullableString.fromObject(object.predeterminationMethodContact);
                    }
                    if (object.predeterminationStartDate != null) {
                        if (typeof object.predeterminationStartDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationStartDate: object expected");
                        message.predeterminationStartDate = $root.infinitusai.be.Date.fromObject(object.predeterminationStartDate);
                    }
                    if (object.predeterminationEndDate != null) {
                        if (typeof object.predeterminationEndDate !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationEndDate: object expected");
                        message.predeterminationEndDate = $root.infinitusai.be.Date.fromObject(object.predeterminationEndDate);
                    }
                    if (object.priorAuthMethods) {
                        if (typeof object.priorAuthMethods !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthMethods: object expected");
                        message.priorAuthMethods = {};
                        for (var keys = Object.keys(object.priorAuthMethods), i = 0; i < keys.length; ++i)
                            message.priorAuthMethods[keys[i]] = String(object.priorAuthMethods[keys[i]]);
                    }
                    if (object.priorAuthMethodNotes != null) {
                        if (typeof object.priorAuthMethodNotes !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthMethodNotes: object expected");
                        message.priorAuthMethodNotes = $root.infinitusai.be.NullableString.fromObject(object.priorAuthMethodNotes);
                    }
                    if (object.predeterminationMethodNotes != null) {
                        if (typeof object.predeterminationMethodNotes !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.predeterminationMethodNotes: object expected");
                        message.predeterminationMethodNotes = $root.infinitusai.be.NullableString.fromObject(object.predeterminationMethodNotes);
                    }
                    if (object.priorAuthMethodType != null) {
                        if (typeof object.priorAuthMethodType !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthMethodType: object expected");
                        message.priorAuthMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.fromObject(object.priorAuthMethodType);
                    }
                    if (object.priorAuthMethodContact != null) {
                        if (typeof object.priorAuthMethodContact !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthMethodContact: object expected");
                        message.priorAuthMethodContact = $root.infinitusai.be.NullableString.fromObject(object.priorAuthMethodContact);
                    }
                    if (object.priorAuthCodes != null) {
                        if (typeof object.priorAuthCodes !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.priorAuthCodes: object expected");
                        message.priorAuthCodes = $root.infinitusai.be.MultiselectCodesType.fromObject(object.priorAuthCodes);
                    }
                    if (object.stepTherapyMethodNotes != null) {
                        if (typeof object.stepTherapyMethodNotes !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.stepTherapyMethodNotes: object expected");
                        message.stepTherapyMethodNotes = $root.infinitusai.be.NullableString.fromObject(object.stepTherapyMethodNotes);
                    }
                    if (object.isPredeterminationRequired != null) {
                        if (typeof object.isPredeterminationRequired !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.isPredeterminationRequired: object expected");
                        message.isPredeterminationRequired = $root.infinitusai.be.NullableBool.fromObject(object.isPredeterminationRequired);
                    }
                    if (object.isPredeterminationAvailable != null) {
                        if (typeof object.isPredeterminationAvailable !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.isPredeterminationAvailable: object expected");
                        message.isPredeterminationAvailable = $root.infinitusai.be.NullableBool.fromObject(object.isPredeterminationAvailable);
                    }
                    if (object.buyAndBillAvailable != null) {
                        if (typeof object.buyAndBillAvailable !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.buyAndBillAvailable: object expected");
                        message.buyAndBillAvailable = $root.infinitusai.be.NullableBool.fromObject(object.buyAndBillAvailable);
                    }
                    if (object.claimsAddress != null) {
                        if (typeof object.claimsAddress !== "object")
                            throw TypeError(".infinitusai.be.FullBVReferralCallOutputs.claimsAddress: object expected");
                        message.claimsAddress = $root.infinitusai.be.StreetAddress.fromObject(object.claimsAddress);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FullBVReferralCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {infinitusai.be.FullBVReferralCallOutputs} message FullBVReferralCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FullBVReferralCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.priorAuthRequiredCodes = [];
                        object.preferredSpecialtyPharmacyOutputs = [];
                    }
                    if (options.objects || options.defaults)
                        object.priorAuthMethods = {};
                    if (options.defaults) {
                        object.isReferralRequired = null;
                        object.hasReferralOnFile = null;
                        object.referralNumber = null;
                        object.isPriorAuthRequired = null;
                        object.isPriorAuthOnFile = null;
                        object.priorAuthMethodNotes = null;
                        object.priorAuthApprovalStartDate = null;
                        object.priorAuthApprovalEndDate = null;
                        object.priorAuthApprovalNumber = null;
                        object.priorAuthTurnaroundNotes = null;
                        object.isPredeterminationRequired = null;
                        object.isPredeterminationOnFile = null;
                        object.predeterminationApprovalNumber = null;
                        object.predeterminationMethodNotes = null;
                        object.isStepTherapyRequired = null;
                        object.stepTherapyMethodNotes = null;
                        object.specialtyPharmacy1Name = null;
                        object.specialtyPharmacy1Phone = null;
                        object.priorAuthCodes = null;
                        object.referringPcpName = null;
                        object.referringPcpPhone = null;
                        object.specialtyPharmacyAvailable = null;
                        object.isPredeterminationAvailable = null;
                        object.priorAuthMethodType = null;
                        object.priorAuthMethodEntity = null;
                        object.priorAuthMethodContact = null;
                        object.predeterminationMethodType = null;
                        object.predeterminationMethodEntity = null;
                        object.predeterminationMethodContact = null;
                        object.stepTherapyMethod = null;
                        object.stepTherapyTreatment = null;
                        object.buyAndBillAvailable = null;
                        object.priorAuthRequirements = null;
                        object.predeterminationTurnaroundNotes = null;
                        object.referralEffectiveDate = null;
                        object.referralRecertDate = null;
                        object.isMedicalGroupAtRisk = null;
                        object.priorAuthMethodsV2 = null;
                        object.hasSpecialtyPharmacyExclusions = null;
                        object.specialtyPharmacyExclusions = null;
                        object.claimsAddress = null;
                        object.medicalGroupName = null;
                        object.medicalGroupPhoneNumber = null;
                        object.medicalPolicyAvailableOnWebsite = null;
                        object.priorAuthApprovedQuantity = null;
                        object.medicalPolicyNumber = null;
                        object.predeterminationRequirement = null;
                        object.referralRequirements = null;
                        object.referralNumVisitsApproved = null;
                        object.referralNumVisitsUsed = null;
                        object.priorAuthStatus = null;
                        object.priorAuthDenialReason = null;
                        object.priorAuthDenialDate = null;
                        object.priorAuthSubmissionDate = null;
                        object.predeterminationStartDate = null;
                        object.predeterminationEndDate = null;
                        object.specialtyPharmacy1Fax = null;
                    }
                    if (message.isReferralRequired != null && message.hasOwnProperty("isReferralRequired"))
                        object.isReferralRequired = $root.infinitusai.be.NullableBool.toObject(message.isReferralRequired, options);
                    if (message.hasReferralOnFile != null && message.hasOwnProperty("hasReferralOnFile"))
                        object.hasReferralOnFile = $root.infinitusai.be.NullableBool.toObject(message.hasReferralOnFile, options);
                    if (message.referralNumber != null && message.hasOwnProperty("referralNumber"))
                        object.referralNumber = $root.infinitusai.be.NullableString.toObject(message.referralNumber, options);
                    if (message.isPriorAuthRequired != null && message.hasOwnProperty("isPriorAuthRequired"))
                        object.isPriorAuthRequired = $root.infinitusai.be.NullableBool.toObject(message.isPriorAuthRequired, options);
                    if (message.isPriorAuthOnFile != null && message.hasOwnProperty("isPriorAuthOnFile"))
                        object.isPriorAuthOnFile = $root.infinitusai.be.NullableBool.toObject(message.isPriorAuthOnFile, options);
                    if (message.priorAuthMethodNotes != null && message.hasOwnProperty("priorAuthMethodNotes"))
                        object.priorAuthMethodNotes = $root.infinitusai.be.NullableString.toObject(message.priorAuthMethodNotes, options);
                    if (message.priorAuthApprovalStartDate != null && message.hasOwnProperty("priorAuthApprovalStartDate"))
                        object.priorAuthApprovalStartDate = $root.infinitusai.be.Date.toObject(message.priorAuthApprovalStartDate, options);
                    if (message.priorAuthApprovalEndDate != null && message.hasOwnProperty("priorAuthApprovalEndDate"))
                        object.priorAuthApprovalEndDate = $root.infinitusai.be.Date.toObject(message.priorAuthApprovalEndDate, options);
                    if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber"))
                        object.priorAuthApprovalNumber = $root.infinitusai.be.NullableString.toObject(message.priorAuthApprovalNumber, options);
                    if (message.priorAuthTurnaroundNotes != null && message.hasOwnProperty("priorAuthTurnaroundNotes"))
                        object.priorAuthTurnaroundNotes = $root.infinitusai.be.NullableString.toObject(message.priorAuthTurnaroundNotes, options);
                    if (message.isPredeterminationRequired != null && message.hasOwnProperty("isPredeterminationRequired"))
                        object.isPredeterminationRequired = $root.infinitusai.be.NullableBool.toObject(message.isPredeterminationRequired, options);
                    if (message.isPredeterminationOnFile != null && message.hasOwnProperty("isPredeterminationOnFile"))
                        object.isPredeterminationOnFile = $root.infinitusai.be.NullableBool.toObject(message.isPredeterminationOnFile, options);
                    if (message.predeterminationApprovalNumber != null && message.hasOwnProperty("predeterminationApprovalNumber"))
                        object.predeterminationApprovalNumber = $root.infinitusai.be.NullableString.toObject(message.predeterminationApprovalNumber, options);
                    if (message.predeterminationMethodNotes != null && message.hasOwnProperty("predeterminationMethodNotes"))
                        object.predeterminationMethodNotes = $root.infinitusai.be.NullableString.toObject(message.predeterminationMethodNotes, options);
                    if (message.isStepTherapyRequired != null && message.hasOwnProperty("isStepTherapyRequired"))
                        object.isStepTherapyRequired = $root.infinitusai.be.NullableBool.toObject(message.isStepTherapyRequired, options);
                    if (message.stepTherapyMethodNotes != null && message.hasOwnProperty("stepTherapyMethodNotes"))
                        object.stepTherapyMethodNotes = $root.infinitusai.be.NullableString.toObject(message.stepTherapyMethodNotes, options);
                    if (message.specialtyPharmacy1Name != null && message.hasOwnProperty("specialtyPharmacy1Name"))
                        object.specialtyPharmacy1Name = $root.infinitusai.be.NullableString.toObject(message.specialtyPharmacy1Name, options);
                    if (message.specialtyPharmacy1Phone != null && message.hasOwnProperty("specialtyPharmacy1Phone"))
                        object.specialtyPharmacy1Phone = $root.infinitusai.be.NullableString.toObject(message.specialtyPharmacy1Phone, options);
                    if (message.priorAuthCodes != null && message.hasOwnProperty("priorAuthCodes"))
                        object.priorAuthCodes = $root.infinitusai.be.MultiselectCodesType.toObject(message.priorAuthCodes, options);
                    if (message.referringPcpName != null && message.hasOwnProperty("referringPcpName"))
                        object.referringPcpName = $root.infinitusai.be.NullableString.toObject(message.referringPcpName, options);
                    if (message.referringPcpPhone != null && message.hasOwnProperty("referringPcpPhone"))
                        object.referringPcpPhone = $root.infinitusai.be.NullableString.toObject(message.referringPcpPhone, options);
                    if (message.specialtyPharmacyAvailable != null && message.hasOwnProperty("specialtyPharmacyAvailable"))
                        object.specialtyPharmacyAvailable = $root.infinitusai.be.NullableBool.toObject(message.specialtyPharmacyAvailable, options);
                    if (message.isPredeterminationAvailable != null && message.hasOwnProperty("isPredeterminationAvailable"))
                        object.isPredeterminationAvailable = $root.infinitusai.be.NullableBool.toObject(message.isPredeterminationAvailable, options);
                    if (message.priorAuthMethodType != null && message.hasOwnProperty("priorAuthMethodType"))
                        object.priorAuthMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.toObject(message.priorAuthMethodType, options);
                    if (message.priorAuthMethodEntity != null && message.hasOwnProperty("priorAuthMethodEntity"))
                        object.priorAuthMethodEntity = $root.infinitusai.be.NullableString.toObject(message.priorAuthMethodEntity, options);
                    if (message.priorAuthMethodContact != null && message.hasOwnProperty("priorAuthMethodContact"))
                        object.priorAuthMethodContact = $root.infinitusai.be.NullableString.toObject(message.priorAuthMethodContact, options);
                    if (message.predeterminationMethodType != null && message.hasOwnProperty("predeterminationMethodType"))
                        object.predeterminationMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.toObject(message.predeterminationMethodType, options);
                    if (message.predeterminationMethodEntity != null && message.hasOwnProperty("predeterminationMethodEntity"))
                        object.predeterminationMethodEntity = $root.infinitusai.be.NullableString.toObject(message.predeterminationMethodEntity, options);
                    if (message.predeterminationMethodContact != null && message.hasOwnProperty("predeterminationMethodContact"))
                        object.predeterminationMethodContact = $root.infinitusai.be.NullableString.toObject(message.predeterminationMethodContact, options);
                    if (message.priorAuthRequiredCodes && message.priorAuthRequiredCodes.length) {
                        object.priorAuthRequiredCodes = [];
                        for (var j = 0; j < message.priorAuthRequiredCodes.length; ++j)
                            object.priorAuthRequiredCodes[j] = message.priorAuthRequiredCodes[j];
                    }
                    if (message.stepTherapyMethod != null && message.hasOwnProperty("stepTherapyMethod"))
                        object.stepTherapyMethod = $root.infinitusai.be.StepTherapyMethod.toObject(message.stepTherapyMethod, options);
                    if (message.stepTherapyTreatment != null && message.hasOwnProperty("stepTherapyTreatment"))
                        object.stepTherapyTreatment = $root.infinitusai.be.NullableString.toObject(message.stepTherapyTreatment, options);
                    if (message.buyAndBillAvailable != null && message.hasOwnProperty("buyAndBillAvailable"))
                        object.buyAndBillAvailable = $root.infinitusai.be.NullableBool.toObject(message.buyAndBillAvailable, options);
                    var keys2;
                    if (message.priorAuthMethods && (keys2 = Object.keys(message.priorAuthMethods)).length) {
                        object.priorAuthMethods = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.priorAuthMethods[keys2[j]] = message.priorAuthMethods[keys2[j]];
                    }
                    if (message.priorAuthRequirements != null && message.hasOwnProperty("priorAuthRequirements"))
                        object.priorAuthRequirements = $root.infinitusai.be.MultiSelectPriorAuthRequirementType.toObject(message.priorAuthRequirements, options);
                    if (message.predeterminationTurnaroundNotes != null && message.hasOwnProperty("predeterminationTurnaroundNotes"))
                        object.predeterminationTurnaroundNotes = $root.infinitusai.be.NullableString.toObject(message.predeterminationTurnaroundNotes, options);
                    if (message.referralEffectiveDate != null && message.hasOwnProperty("referralEffectiveDate"))
                        object.referralEffectiveDate = $root.infinitusai.be.Date.toObject(message.referralEffectiveDate, options);
                    if (message.referralRecertDate != null && message.hasOwnProperty("referralRecertDate"))
                        object.referralRecertDate = $root.infinitusai.be.Date.toObject(message.referralRecertDate, options);
                    if (message.isMedicalGroupAtRisk != null && message.hasOwnProperty("isMedicalGroupAtRisk"))
                        object.isMedicalGroupAtRisk = $root.infinitusai.be.NullableBool.toObject(message.isMedicalGroupAtRisk, options);
                    if (message.priorAuthMethodsV2 != null && message.hasOwnProperty("priorAuthMethodsV2"))
                        object.priorAuthMethodsV2 = $root.infinitusai.be.PriorAuthMethod.toObject(message.priorAuthMethodsV2, options);
                    if (message.hasSpecialtyPharmacyExclusions != null && message.hasOwnProperty("hasSpecialtyPharmacyExclusions"))
                        object.hasSpecialtyPharmacyExclusions = $root.infinitusai.be.NullableBool.toObject(message.hasSpecialtyPharmacyExclusions, options);
                    if (message.specialtyPharmacyExclusions != null && message.hasOwnProperty("specialtyPharmacyExclusions"))
                        object.specialtyPharmacyExclusions = $root.infinitusai.be.NullableString.toObject(message.specialtyPharmacyExclusions, options);
                    if (message.claimsAddress != null && message.hasOwnProperty("claimsAddress"))
                        object.claimsAddress = $root.infinitusai.be.StreetAddress.toObject(message.claimsAddress, options);
                    if (message.medicalGroupName != null && message.hasOwnProperty("medicalGroupName"))
                        object.medicalGroupName = $root.infinitusai.be.NullableString.toObject(message.medicalGroupName, options);
                    if (message.medicalGroupPhoneNumber != null && message.hasOwnProperty("medicalGroupPhoneNumber"))
                        object.medicalGroupPhoneNumber = $root.infinitusai.be.NullableString.toObject(message.medicalGroupPhoneNumber, options);
                    if (message.medicalPolicyAvailableOnWebsite != null && message.hasOwnProperty("medicalPolicyAvailableOnWebsite"))
                        object.medicalPolicyAvailableOnWebsite = $root.infinitusai.be.NullableBool.toObject(message.medicalPolicyAvailableOnWebsite, options);
                    if (message.priorAuthApprovedQuantity != null && message.hasOwnProperty("priorAuthApprovedQuantity"))
                        object.priorAuthApprovedQuantity = $root.infinitusai.be.NullableString.toObject(message.priorAuthApprovedQuantity, options);
                    if (message.medicalPolicyNumber != null && message.hasOwnProperty("medicalPolicyNumber"))
                        object.medicalPolicyNumber = $root.infinitusai.be.NullableString.toObject(message.medicalPolicyNumber, options);
                    if (message.predeterminationRequirement != null && message.hasOwnProperty("predeterminationRequirement"))
                        object.predeterminationRequirement = $root.infinitusai.be.PredeterminationRequirementType.toObject(message.predeterminationRequirement, options);
                    if (message.referralRequirements != null && message.hasOwnProperty("referralRequirements"))
                        object.referralRequirements = $root.infinitusai.be.ReferralRequirementsType.toObject(message.referralRequirements, options);
                    if (message.preferredSpecialtyPharmacyOutputs && message.preferredSpecialtyPharmacyOutputs.length) {
                        object.preferredSpecialtyPharmacyOutputs = [];
                        for (var j = 0; j < message.preferredSpecialtyPharmacyOutputs.length; ++j)
                            object.preferredSpecialtyPharmacyOutputs[j] = $root.infinitusai.be.FullBVPreferredSpecialtyPharmacyOutputs.toObject(message.preferredSpecialtyPharmacyOutputs[j], options);
                    }
                    if (message.referralNumVisitsApproved != null && message.hasOwnProperty("referralNumVisitsApproved"))
                        object.referralNumVisitsApproved = $root.infinitusai.be.NullableInt32.toObject(message.referralNumVisitsApproved, options);
                    if (message.referralNumVisitsUsed != null && message.hasOwnProperty("referralNumVisitsUsed"))
                        object.referralNumVisitsUsed = $root.infinitusai.be.NullableInt32.toObject(message.referralNumVisitsUsed, options);
                    if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                        object.priorAuthStatus = $root.infinitusai.be.PriorAuthStatusType.toObject(message.priorAuthStatus, options);
                    if (message.priorAuthDenialReason != null && message.hasOwnProperty("priorAuthDenialReason"))
                        object.priorAuthDenialReason = $root.infinitusai.be.NullableString.toObject(message.priorAuthDenialReason, options);
                    if (message.priorAuthDenialDate != null && message.hasOwnProperty("priorAuthDenialDate"))
                        object.priorAuthDenialDate = $root.infinitusai.be.Date.toObject(message.priorAuthDenialDate, options);
                    if (message.priorAuthSubmissionDate != null && message.hasOwnProperty("priorAuthSubmissionDate"))
                        object.priorAuthSubmissionDate = $root.infinitusai.be.Date.toObject(message.priorAuthSubmissionDate, options);
                    if (message.predeterminationStartDate != null && message.hasOwnProperty("predeterminationStartDate"))
                        object.predeterminationStartDate = $root.infinitusai.be.Date.toObject(message.predeterminationStartDate, options);
                    if (message.predeterminationEndDate != null && message.hasOwnProperty("predeterminationEndDate"))
                        object.predeterminationEndDate = $root.infinitusai.be.Date.toObject(message.predeterminationEndDate, options);
                    if (message.specialtyPharmacy1Fax != null && message.hasOwnProperty("specialtyPharmacy1Fax"))
                        object.specialtyPharmacy1Fax = $root.infinitusai.be.NullableString.toObject(message.specialtyPharmacy1Fax, options);
                    return object;
                };
    
                /**
                 * Converts this FullBVReferralCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FullBVReferralCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FullBVReferralCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FullBVReferralCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FullBVReferralCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FullBVReferralCallOutputs";
                };
    
                return FullBVReferralCallOutputs;
            })();
    
            be.FullBVCallOutputs = (function() {
    
                /**
                 * Properties of a FullBVCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IFullBVCallOutputs
                 * @property {infinitusai.be.IFullBVGeneralCallOutputs|null} [general] FullBVCallOutputs general
                 * @property {infinitusai.be.IFullBVJCodeCallOutputs|null} [jCode] FullBVCallOutputs jCode
                 * @property {infinitusai.be.IFullBVAdminCodeCallOutputs|null} [adminCode] FullBVCallOutputs adminCode
                 * @property {infinitusai.be.IFullBVSpecialOfficeCallOutputs|null} [office] FullBVCallOutputs office
                 * @property {infinitusai.be.IFullBVReferralCallOutputs|null} [auth] FullBVCallOutputs auth
                 * @property {Array.<infinitusai.be.IFullBVJCodeCallOutputs>|null} [productCodes] FullBVCallOutputs productCodes
                 * @property {Array.<string>|null} [agentCouldNotAnswerFields] FullBVCallOutputs agentCouldNotAnswerFields
                 */
    
                /**
                 * Constructs a new FullBVCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FullBVCallOutputs.
                 * @implements IFullBVCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IFullBVCallOutputs=} [properties] Properties to set
                 */
                function FullBVCallOutputs(properties) {
                    this.productCodes = [];
                    this.agentCouldNotAnswerFields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FullBVCallOutputs general.
                 * @member {infinitusai.be.IFullBVGeneralCallOutputs|null|undefined} general
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @instance
                 */
                FullBVCallOutputs.prototype.general = null;
    
                /**
                 * FullBVCallOutputs jCode.
                 * @member {infinitusai.be.IFullBVJCodeCallOutputs|null|undefined} jCode
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @instance
                 */
                FullBVCallOutputs.prototype.jCode = null;
    
                /**
                 * FullBVCallOutputs adminCode.
                 * @member {infinitusai.be.IFullBVAdminCodeCallOutputs|null|undefined} adminCode
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @instance
                 */
                FullBVCallOutputs.prototype.adminCode = null;
    
                /**
                 * FullBVCallOutputs office.
                 * @member {infinitusai.be.IFullBVSpecialOfficeCallOutputs|null|undefined} office
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @instance
                 */
                FullBVCallOutputs.prototype.office = null;
    
                /**
                 * FullBVCallOutputs auth.
                 * @member {infinitusai.be.IFullBVReferralCallOutputs|null|undefined} auth
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @instance
                 */
                FullBVCallOutputs.prototype.auth = null;
    
                /**
                 * FullBVCallOutputs productCodes.
                 * @member {Array.<infinitusai.be.IFullBVJCodeCallOutputs>} productCodes
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @instance
                 */
                FullBVCallOutputs.prototype.productCodes = $util.emptyArray;
    
                /**
                 * FullBVCallOutputs agentCouldNotAnswerFields.
                 * @member {Array.<string>} agentCouldNotAnswerFields
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @instance
                 */
                FullBVCallOutputs.prototype.agentCouldNotAnswerFields = $util.emptyArray;
    
                /**
                 * Creates a new FullBVCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.FullBVCallOutputs} FullBVCallOutputs instance
                 */
                FullBVCallOutputs.create = function create(properties) {
                    return new FullBVCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified FullBVCallOutputs message. Does not implicitly {@link infinitusai.be.FullBVCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVCallOutputs} message FullBVCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.general != null && Object.hasOwnProperty.call(message, "general"))
                        $root.infinitusai.be.FullBVGeneralCallOutputs.encode(message.general, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.jCode != null && Object.hasOwnProperty.call(message, "jCode"))
                        $root.infinitusai.be.FullBVJCodeCallOutputs.encode(message.jCode, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.adminCode != null && Object.hasOwnProperty.call(message, "adminCode"))
                        $root.infinitusai.be.FullBVAdminCodeCallOutputs.encode(message.adminCode, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.office != null && Object.hasOwnProperty.call(message, "office"))
                        $root.infinitusai.be.FullBVSpecialOfficeCallOutputs.encode(message.office, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                        $root.infinitusai.be.FullBVReferralCallOutputs.encode(message.auth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.productCodes != null && message.productCodes.length)
                        for (var i = 0; i < message.productCodes.length; ++i)
                            $root.infinitusai.be.FullBVJCodeCallOutputs.encode(message.productCodes[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.agentCouldNotAnswerFields != null && message.agentCouldNotAnswerFields.length)
                        for (var i = 0; i < message.agentCouldNotAnswerFields.length; ++i)
                            writer.uint32(/* id 99, wireType 2 =*/794).string(message.agentCouldNotAnswerFields[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified FullBVCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.FullBVCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IFullBVCallOutputs} message FullBVCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FullBVCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FullBVCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FullBVCallOutputs} FullBVCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FullBVCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.general = $root.infinitusai.be.FullBVGeneralCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.jCode = $root.infinitusai.be.FullBVJCodeCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.adminCode = $root.infinitusai.be.FullBVAdminCodeCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.office = $root.infinitusai.be.FullBVSpecialOfficeCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.auth = $root.infinitusai.be.FullBVReferralCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                if (!(message.productCodes && message.productCodes.length))
                                    message.productCodes = [];
                                message.productCodes.push($root.infinitusai.be.FullBVJCodeCallOutputs.decode(reader, reader.uint32()));
                                break;
                            }
                        case 99: {
                                if (!(message.agentCouldNotAnswerFields && message.agentCouldNotAnswerFields.length))
                                    message.agentCouldNotAnswerFields = [];
                                message.agentCouldNotAnswerFields.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FullBVCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FullBVCallOutputs} FullBVCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FullBVCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FullBVCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FullBVCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.general != null && message.hasOwnProperty("general")) {
                        var error = $root.infinitusai.be.FullBVGeneralCallOutputs.verify(message.general);
                        if (error)
                            return "general." + error;
                    }
                    if (message.jCode != null && message.hasOwnProperty("jCode")) {
                        var error = $root.infinitusai.be.FullBVJCodeCallOutputs.verify(message.jCode);
                        if (error)
                            return "jCode." + error;
                    }
                    if (message.adminCode != null && message.hasOwnProperty("adminCode")) {
                        var error = $root.infinitusai.be.FullBVAdminCodeCallOutputs.verify(message.adminCode);
                        if (error)
                            return "adminCode." + error;
                    }
                    if (message.office != null && message.hasOwnProperty("office")) {
                        var error = $root.infinitusai.be.FullBVSpecialOfficeCallOutputs.verify(message.office);
                        if (error)
                            return "office." + error;
                    }
                    if (message.auth != null && message.hasOwnProperty("auth")) {
                        var error = $root.infinitusai.be.FullBVReferralCallOutputs.verify(message.auth);
                        if (error)
                            return "auth." + error;
                    }
                    if (message.productCodes != null && message.hasOwnProperty("productCodes")) {
                        if (!Array.isArray(message.productCodes))
                            return "productCodes: array expected";
                        for (var i = 0; i < message.productCodes.length; ++i) {
                            var error = $root.infinitusai.be.FullBVJCodeCallOutputs.verify(message.productCodes[i]);
                            if (error)
                                return "productCodes." + error;
                        }
                    }
                    if (message.agentCouldNotAnswerFields != null && message.hasOwnProperty("agentCouldNotAnswerFields")) {
                        if (!Array.isArray(message.agentCouldNotAnswerFields))
                            return "agentCouldNotAnswerFields: array expected";
                        for (var i = 0; i < message.agentCouldNotAnswerFields.length; ++i)
                            if (!$util.isString(message.agentCouldNotAnswerFields[i]))
                                return "agentCouldNotAnswerFields: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a FullBVCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FullBVCallOutputs} FullBVCallOutputs
                 */
                FullBVCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FullBVCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.FullBVCallOutputs();
                    if (object.general != null) {
                        if (typeof object.general !== "object")
                            throw TypeError(".infinitusai.be.FullBVCallOutputs.general: object expected");
                        message.general = $root.infinitusai.be.FullBVGeneralCallOutputs.fromObject(object.general);
                    }
                    if (object.jCode != null) {
                        if (typeof object.jCode !== "object")
                            throw TypeError(".infinitusai.be.FullBVCallOutputs.jCode: object expected");
                        message.jCode = $root.infinitusai.be.FullBVJCodeCallOutputs.fromObject(object.jCode);
                    }
                    if (object.adminCode != null) {
                        if (typeof object.adminCode !== "object")
                            throw TypeError(".infinitusai.be.FullBVCallOutputs.adminCode: object expected");
                        message.adminCode = $root.infinitusai.be.FullBVAdminCodeCallOutputs.fromObject(object.adminCode);
                    }
                    if (object.office != null) {
                        if (typeof object.office !== "object")
                            throw TypeError(".infinitusai.be.FullBVCallOutputs.office: object expected");
                        message.office = $root.infinitusai.be.FullBVSpecialOfficeCallOutputs.fromObject(object.office);
                    }
                    if (object.auth != null) {
                        if (typeof object.auth !== "object")
                            throw TypeError(".infinitusai.be.FullBVCallOutputs.auth: object expected");
                        message.auth = $root.infinitusai.be.FullBVReferralCallOutputs.fromObject(object.auth);
                    }
                    if (object.productCodes) {
                        if (!Array.isArray(object.productCodes))
                            throw TypeError(".infinitusai.be.FullBVCallOutputs.productCodes: array expected");
                        message.productCodes = [];
                        for (var i = 0; i < object.productCodes.length; ++i) {
                            if (typeof object.productCodes[i] !== "object")
                                throw TypeError(".infinitusai.be.FullBVCallOutputs.productCodes: object expected");
                            message.productCodes[i] = $root.infinitusai.be.FullBVJCodeCallOutputs.fromObject(object.productCodes[i]);
                        }
                    }
                    if (object.agentCouldNotAnswerFields) {
                        if (!Array.isArray(object.agentCouldNotAnswerFields))
                            throw TypeError(".infinitusai.be.FullBVCallOutputs.agentCouldNotAnswerFields: array expected");
                        message.agentCouldNotAnswerFields = [];
                        for (var i = 0; i < object.agentCouldNotAnswerFields.length; ++i)
                            message.agentCouldNotAnswerFields[i] = String(object.agentCouldNotAnswerFields[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FullBVCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {infinitusai.be.FullBVCallOutputs} message FullBVCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FullBVCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.productCodes = [];
                        object.agentCouldNotAnswerFields = [];
                    }
                    if (options.defaults) {
                        object.general = null;
                        object.jCode = null;
                        object.adminCode = null;
                        object.office = null;
                        object.auth = null;
                    }
                    if (message.general != null && message.hasOwnProperty("general"))
                        object.general = $root.infinitusai.be.FullBVGeneralCallOutputs.toObject(message.general, options);
                    if (message.jCode != null && message.hasOwnProperty("jCode"))
                        object.jCode = $root.infinitusai.be.FullBVJCodeCallOutputs.toObject(message.jCode, options);
                    if (message.adminCode != null && message.hasOwnProperty("adminCode"))
                        object.adminCode = $root.infinitusai.be.FullBVAdminCodeCallOutputs.toObject(message.adminCode, options);
                    if (message.office != null && message.hasOwnProperty("office"))
                        object.office = $root.infinitusai.be.FullBVSpecialOfficeCallOutputs.toObject(message.office, options);
                    if (message.auth != null && message.hasOwnProperty("auth"))
                        object.auth = $root.infinitusai.be.FullBVReferralCallOutputs.toObject(message.auth, options);
                    if (message.productCodes && message.productCodes.length) {
                        object.productCodes = [];
                        for (var j = 0; j < message.productCodes.length; ++j)
                            object.productCodes[j] = $root.infinitusai.be.FullBVJCodeCallOutputs.toObject(message.productCodes[j], options);
                    }
                    if (message.agentCouldNotAnswerFields && message.agentCouldNotAnswerFields.length) {
                        object.agentCouldNotAnswerFields = [];
                        for (var j = 0; j < message.agentCouldNotAnswerFields.length; ++j)
                            object.agentCouldNotAnswerFields[j] = message.agentCouldNotAnswerFields[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this FullBVCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FullBVCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FullBVCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FullBVCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FullBVCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FullBVCallOutputs";
                };
    
                return FullBVCallOutputs;
            })();
    
            be.PBMBVGeneralCallOutputs = (function() {
    
                /**
                 * Properties of a PBMBVGeneralCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IPBMBVGeneralCallOutputs
                 * @property {infinitusai.be.INullableString|null} [agentName] PBMBVGeneralCallOutputs agentName
                 * @property {infinitusai.be.INullableString|null} [referenceNumber] PBMBVGeneralCallOutputs referenceNumber
                 * @property {infinitusai.be.INullableBool|null} [hasOtherInsuranceOnFile] PBMBVGeneralCallOutputs hasOtherInsuranceOnFile
                 * @property {infinitusai.be.INullableString|null} [otherInsuranceName] PBMBVGeneralCallOutputs otherInsuranceName
                 * @property {infinitusai.be.ICurrentPlanPriority|null} [currentPlanPriority] PBMBVGeneralCallOutputs currentPlanPriority
                 * @property {infinitusai.be.IPolicyType|null} [policyType] PBMBVGeneralCallOutputs policyType
                 * @property {infinitusai.be.IPlanType|null} [planType] PBMBVGeneralCallOutputs planType
                 * @property {infinitusai.be.INullableString|null} [planName] PBMBVGeneralCallOutputs planName
                 * @property {infinitusai.be.INullableString|null} [groupNumber] PBMBVGeneralCallOutputs groupNumber
                 * @property {infinitusai.be.IDate|null} [effectiveDate] PBMBVGeneralCallOutputs effectiveDate
                 * @property {infinitusai.be.INullableBool|null} [planTerminated] PBMBVGeneralCallOutputs planTerminated
                 * @property {infinitusai.be.IDate|null} [termDate] PBMBVGeneralCallOutputs termDate
                 * @property {infinitusai.be.INullableBool|null} [isAdverseEvent] PBMBVGeneralCallOutputs isAdverseEvent
                 * @property {infinitusai.be.IAdverseEventType|null} [adverseEventType] PBMBVGeneralCallOutputs adverseEventType
                 * @property {infinitusai.be.IBenefitPlanRenewalType|null} [benefitPlanRenewalType] PBMBVGeneralCallOutputs benefitPlanRenewalType
                 * @property {infinitusai.be.IMonthType|null} [fiscalRenewMonth] PBMBVGeneralCallOutputs fiscalRenewMonth
                 * @property {infinitusai.be.INullableString|null} [pbmPolicyNumber] PBMBVGeneralCallOutputs pbmPolicyNumber
                 * @property {infinitusai.be.INullableString|null} [pcn] PBMBVGeneralCallOutputs pcn
                 * @property {infinitusai.be.INullableString|null} [bin] PBMBVGeneralCallOutputs bin
                 * @property {infinitusai.be.INullableBool|null} [isSelfFunded] PBMBVGeneralCallOutputs isSelfFunded
                 * @property {infinitusai.be.INullableBool|null} [annualCapExists] PBMBVGeneralCallOutputs annualCapExists
                 * @property {infinitusai.be.IMoney|null} [annualCap] PBMBVGeneralCallOutputs annualCap
                 * @property {infinitusai.be.IMoney|null} [annualCapMet] PBMBVGeneralCallOutputs annualCapMet
                 * @property {infinitusai.be.INullableBool|null} [hasDeductible] PBMBVGeneralCallOutputs hasDeductible
                 * @property {infinitusai.be.IMoney|null} [deductibleTotal] PBMBVGeneralCallOutputs deductibleTotal
                 * @property {infinitusai.be.IMoney|null} [deductibleMet] PBMBVGeneralCallOutputs deductibleMet
                 * @property {infinitusai.be.INullableBool|null} [hasFamilyDeductible] PBMBVGeneralCallOutputs hasFamilyDeductible
                 * @property {infinitusai.be.IMoney|null} [familyDeductibleTotal] PBMBVGeneralCallOutputs familyDeductibleTotal
                 * @property {infinitusai.be.IMoney|null} [familyDeductibleMet] PBMBVGeneralCallOutputs familyDeductibleMet
                 * @property {infinitusai.be.INullableBool|null} [hasOop] PBMBVGeneralCallOutputs hasOop
                 * @property {infinitusai.be.IMoney|null} [oopTotal] PBMBVGeneralCallOutputs oopTotal
                 * @property {infinitusai.be.IMoney|null} [oopMet] PBMBVGeneralCallOutputs oopMet
                 * @property {infinitusai.be.INullableBool|null} [hasFamilyOop] PBMBVGeneralCallOutputs hasFamilyOop
                 * @property {infinitusai.be.IMoney|null} [familyOopTotal] PBMBVGeneralCallOutputs familyOopTotal
                 * @property {infinitusai.be.IMoney|null} [familyOopMet] PBMBVGeneralCallOutputs familyOopMet
                 * @property {infinitusai.be.INullableBool|null} [doesCopayApplyToOop] PBMBVGeneralCallOutputs doesCopayApplyToOop
                 * @property {infinitusai.be.INullableBool|null} [codesValidAndBillable] PBMBVGeneralCallOutputs codesValidAndBillable
                 * @property {Array.<string>|null} [uncoveredCodesStr] PBMBVGeneralCallOutputs uncoveredCodesStr
                 * @property {infinitusai.be.INullableString|null} [reasonForNonCoverage] PBMBVGeneralCallOutputs reasonForNonCoverage
                 * @property {infinitusai.be.IReasonForNonCoverageType|null} [reasonForNonCoverageType] PBMBVGeneralCallOutputs reasonForNonCoverageType
                 */
    
                /**
                 * Constructs a new PBMBVGeneralCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PBMBVGeneralCallOutputs.
                 * @implements IPBMBVGeneralCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IPBMBVGeneralCallOutputs=} [properties] Properties to set
                 */
                function PBMBVGeneralCallOutputs(properties) {
                    this.uncoveredCodesStr = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PBMBVGeneralCallOutputs agentName.
                 * @member {infinitusai.be.INullableString|null|undefined} agentName
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.agentName = null;
    
                /**
                 * PBMBVGeneralCallOutputs referenceNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} referenceNumber
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.referenceNumber = null;
    
                /**
                 * PBMBVGeneralCallOutputs hasOtherInsuranceOnFile.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasOtherInsuranceOnFile
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.hasOtherInsuranceOnFile = null;
    
                /**
                 * PBMBVGeneralCallOutputs otherInsuranceName.
                 * @member {infinitusai.be.INullableString|null|undefined} otherInsuranceName
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.otherInsuranceName = null;
    
                /**
                 * PBMBVGeneralCallOutputs currentPlanPriority.
                 * @member {infinitusai.be.ICurrentPlanPriority|null|undefined} currentPlanPriority
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.currentPlanPriority = null;
    
                /**
                 * PBMBVGeneralCallOutputs policyType.
                 * @member {infinitusai.be.IPolicyType|null|undefined} policyType
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.policyType = null;
    
                /**
                 * PBMBVGeneralCallOutputs planType.
                 * @member {infinitusai.be.IPlanType|null|undefined} planType
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.planType = null;
    
                /**
                 * PBMBVGeneralCallOutputs planName.
                 * @member {infinitusai.be.INullableString|null|undefined} planName
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.planName = null;
    
                /**
                 * PBMBVGeneralCallOutputs groupNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} groupNumber
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.groupNumber = null;
    
                /**
                 * PBMBVGeneralCallOutputs effectiveDate.
                 * @member {infinitusai.be.IDate|null|undefined} effectiveDate
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.effectiveDate = null;
    
                /**
                 * PBMBVGeneralCallOutputs planTerminated.
                 * @member {infinitusai.be.INullableBool|null|undefined} planTerminated
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.planTerminated = null;
    
                /**
                 * PBMBVGeneralCallOutputs termDate.
                 * @member {infinitusai.be.IDate|null|undefined} termDate
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.termDate = null;
    
                /**
                 * PBMBVGeneralCallOutputs isAdverseEvent.
                 * @member {infinitusai.be.INullableBool|null|undefined} isAdverseEvent
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.isAdverseEvent = null;
    
                /**
                 * PBMBVGeneralCallOutputs adverseEventType.
                 * @member {infinitusai.be.IAdverseEventType|null|undefined} adverseEventType
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.adverseEventType = null;
    
                /**
                 * PBMBVGeneralCallOutputs benefitPlanRenewalType.
                 * @member {infinitusai.be.IBenefitPlanRenewalType|null|undefined} benefitPlanRenewalType
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.benefitPlanRenewalType = null;
    
                /**
                 * PBMBVGeneralCallOutputs fiscalRenewMonth.
                 * @member {infinitusai.be.IMonthType|null|undefined} fiscalRenewMonth
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.fiscalRenewMonth = null;
    
                /**
                 * PBMBVGeneralCallOutputs pbmPolicyNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} pbmPolicyNumber
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.pbmPolicyNumber = null;
    
                /**
                 * PBMBVGeneralCallOutputs pcn.
                 * @member {infinitusai.be.INullableString|null|undefined} pcn
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.pcn = null;
    
                /**
                 * PBMBVGeneralCallOutputs bin.
                 * @member {infinitusai.be.INullableString|null|undefined} bin
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.bin = null;
    
                /**
                 * PBMBVGeneralCallOutputs isSelfFunded.
                 * @member {infinitusai.be.INullableBool|null|undefined} isSelfFunded
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.isSelfFunded = null;
    
                /**
                 * PBMBVGeneralCallOutputs annualCapExists.
                 * @member {infinitusai.be.INullableBool|null|undefined} annualCapExists
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.annualCapExists = null;
    
                /**
                 * PBMBVGeneralCallOutputs annualCap.
                 * @member {infinitusai.be.IMoney|null|undefined} annualCap
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.annualCap = null;
    
                /**
                 * PBMBVGeneralCallOutputs annualCapMet.
                 * @member {infinitusai.be.IMoney|null|undefined} annualCapMet
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.annualCapMet = null;
    
                /**
                 * PBMBVGeneralCallOutputs hasDeductible.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasDeductible
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.hasDeductible = null;
    
                /**
                 * PBMBVGeneralCallOutputs deductibleTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleTotal
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.deductibleTotal = null;
    
                /**
                 * PBMBVGeneralCallOutputs deductibleMet.
                 * @member {infinitusai.be.IMoney|null|undefined} deductibleMet
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.deductibleMet = null;
    
                /**
                 * PBMBVGeneralCallOutputs hasFamilyDeductible.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasFamilyDeductible
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.hasFamilyDeductible = null;
    
                /**
                 * PBMBVGeneralCallOutputs familyDeductibleTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} familyDeductibleTotal
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.familyDeductibleTotal = null;
    
                /**
                 * PBMBVGeneralCallOutputs familyDeductibleMet.
                 * @member {infinitusai.be.IMoney|null|undefined} familyDeductibleMet
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.familyDeductibleMet = null;
    
                /**
                 * PBMBVGeneralCallOutputs hasOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasOop
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.hasOop = null;
    
                /**
                 * PBMBVGeneralCallOutputs oopTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} oopTotal
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.oopTotal = null;
    
                /**
                 * PBMBVGeneralCallOutputs oopMet.
                 * @member {infinitusai.be.IMoney|null|undefined} oopMet
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.oopMet = null;
    
                /**
                 * PBMBVGeneralCallOutputs hasFamilyOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} hasFamilyOop
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.hasFamilyOop = null;
    
                /**
                 * PBMBVGeneralCallOutputs familyOopTotal.
                 * @member {infinitusai.be.IMoney|null|undefined} familyOopTotal
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.familyOopTotal = null;
    
                /**
                 * PBMBVGeneralCallOutputs familyOopMet.
                 * @member {infinitusai.be.IMoney|null|undefined} familyOopMet
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.familyOopMet = null;
    
                /**
                 * PBMBVGeneralCallOutputs doesCopayApplyToOop.
                 * @member {infinitusai.be.INullableBool|null|undefined} doesCopayApplyToOop
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.doesCopayApplyToOop = null;
    
                /**
                 * PBMBVGeneralCallOutputs codesValidAndBillable.
                 * @member {infinitusai.be.INullableBool|null|undefined} codesValidAndBillable
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.codesValidAndBillable = null;
    
                /**
                 * PBMBVGeneralCallOutputs uncoveredCodesStr.
                 * @member {Array.<string>} uncoveredCodesStr
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.uncoveredCodesStr = $util.emptyArray;
    
                /**
                 * PBMBVGeneralCallOutputs reasonForNonCoverage.
                 * @member {infinitusai.be.INullableString|null|undefined} reasonForNonCoverage
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.reasonForNonCoverage = null;
    
                /**
                 * PBMBVGeneralCallOutputs reasonForNonCoverageType.
                 * @member {infinitusai.be.IReasonForNonCoverageType|null|undefined} reasonForNonCoverageType
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 */
                PBMBVGeneralCallOutputs.prototype.reasonForNonCoverageType = null;
    
                /**
                 * Creates a new PBMBVGeneralCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVGeneralCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.PBMBVGeneralCallOutputs} PBMBVGeneralCallOutputs instance
                 */
                PBMBVGeneralCallOutputs.create = function create(properties) {
                    return new PBMBVGeneralCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified PBMBVGeneralCallOutputs message. Does not implicitly {@link infinitusai.be.PBMBVGeneralCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVGeneralCallOutputs} message PBMBVGeneralCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMBVGeneralCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.agentName != null && Object.hasOwnProperty.call(message, "agentName"))
                        $root.infinitusai.be.NullableString.encode(message.agentName, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.referenceNumber != null && Object.hasOwnProperty.call(message, "referenceNumber"))
                        $root.infinitusai.be.NullableString.encode(message.referenceNumber, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.hasOtherInsuranceOnFile != null && Object.hasOwnProperty.call(message, "hasOtherInsuranceOnFile"))
                        $root.infinitusai.be.NullableBool.encode(message.hasOtherInsuranceOnFile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.otherInsuranceName != null && Object.hasOwnProperty.call(message, "otherInsuranceName"))
                        $root.infinitusai.be.NullableString.encode(message.otherInsuranceName, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.currentPlanPriority != null && Object.hasOwnProperty.call(message, "currentPlanPriority"))
                        $root.infinitusai.be.CurrentPlanPriority.encode(message.currentPlanPriority, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.policyType != null && Object.hasOwnProperty.call(message, "policyType"))
                        $root.infinitusai.be.PolicyType.encode(message.policyType, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.planType != null && Object.hasOwnProperty.call(message, "planType"))
                        $root.infinitusai.be.PlanType.encode(message.planType, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                        $root.infinitusai.be.NullableString.encode(message.planName, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber"))
                        $root.infinitusai.be.NullableString.encode(message.groupNumber, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.effectiveDate != null && Object.hasOwnProperty.call(message, "effectiveDate"))
                        $root.infinitusai.be.Date.encode(message.effectiveDate, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.planTerminated != null && Object.hasOwnProperty.call(message, "planTerminated"))
                        $root.infinitusai.be.NullableBool.encode(message.planTerminated, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.termDate != null && Object.hasOwnProperty.call(message, "termDate"))
                        $root.infinitusai.be.Date.encode(message.termDate, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.isAdverseEvent != null && Object.hasOwnProperty.call(message, "isAdverseEvent"))
                        $root.infinitusai.be.NullableBool.encode(message.isAdverseEvent, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.adverseEventType != null && Object.hasOwnProperty.call(message, "adverseEventType"))
                        $root.infinitusai.be.AdverseEventType.encode(message.adverseEventType, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.fiscalRenewMonth != null && Object.hasOwnProperty.call(message, "fiscalRenewMonth"))
                        $root.infinitusai.be.MonthType.encode(message.fiscalRenewMonth, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.pbmPolicyNumber != null && Object.hasOwnProperty.call(message, "pbmPolicyNumber"))
                        $root.infinitusai.be.NullableString.encode(message.pbmPolicyNumber, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.pcn != null && Object.hasOwnProperty.call(message, "pcn"))
                        $root.infinitusai.be.NullableString.encode(message.pcn, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.bin != null && Object.hasOwnProperty.call(message, "bin"))
                        $root.infinitusai.be.NullableString.encode(message.bin, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.isSelfFunded != null && Object.hasOwnProperty.call(message, "isSelfFunded"))
                        $root.infinitusai.be.NullableBool.encode(message.isSelfFunded, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                    if (message.annualCapExists != null && Object.hasOwnProperty.call(message, "annualCapExists"))
                        $root.infinitusai.be.NullableBool.encode(message.annualCapExists, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                    if (message.annualCap != null && Object.hasOwnProperty.call(message, "annualCap"))
                        $root.infinitusai.be.Money.encode(message.annualCap, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    if (message.annualCapMet != null && Object.hasOwnProperty.call(message, "annualCapMet"))
                        $root.infinitusai.be.Money.encode(message.annualCapMet, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                    if (message.hasDeductible != null && Object.hasOwnProperty.call(message, "hasDeductible"))
                        $root.infinitusai.be.NullableBool.encode(message.hasDeductible, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                    if (message.deductibleTotal != null && Object.hasOwnProperty.call(message, "deductibleTotal"))
                        $root.infinitusai.be.Money.encode(message.deductibleTotal, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                    if (message.deductibleMet != null && Object.hasOwnProperty.call(message, "deductibleMet"))
                        $root.infinitusai.be.Money.encode(message.deductibleMet, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                    if (message.hasFamilyDeductible != null && Object.hasOwnProperty.call(message, "hasFamilyDeductible"))
                        $root.infinitusai.be.NullableBool.encode(message.hasFamilyDeductible, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                    if (message.familyDeductibleTotal != null && Object.hasOwnProperty.call(message, "familyDeductibleTotal"))
                        $root.infinitusai.be.Money.encode(message.familyDeductibleTotal, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                    if (message.familyDeductibleMet != null && Object.hasOwnProperty.call(message, "familyDeductibleMet"))
                        $root.infinitusai.be.Money.encode(message.familyDeductibleMet, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                    if (message.hasOop != null && Object.hasOwnProperty.call(message, "hasOop"))
                        $root.infinitusai.be.NullableBool.encode(message.hasOop, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                    if (message.oopTotal != null && Object.hasOwnProperty.call(message, "oopTotal"))
                        $root.infinitusai.be.Money.encode(message.oopTotal, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                    if (message.oopMet != null && Object.hasOwnProperty.call(message, "oopMet"))
                        $root.infinitusai.be.Money.encode(message.oopMet, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                    if (message.hasFamilyOop != null && Object.hasOwnProperty.call(message, "hasFamilyOop"))
                        $root.infinitusai.be.NullableBool.encode(message.hasFamilyOop, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                    if (message.familyOopTotal != null && Object.hasOwnProperty.call(message, "familyOopTotal"))
                        $root.infinitusai.be.Money.encode(message.familyOopTotal, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                    if (message.familyOopMet != null && Object.hasOwnProperty.call(message, "familyOopMet"))
                        $root.infinitusai.be.Money.encode(message.familyOopMet, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                    if (message.doesCopayApplyToOop != null && Object.hasOwnProperty.call(message, "doesCopayApplyToOop"))
                        $root.infinitusai.be.NullableBool.encode(message.doesCopayApplyToOop, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                    if (message.codesValidAndBillable != null && Object.hasOwnProperty.call(message, "codesValidAndBillable"))
                        $root.infinitusai.be.NullableBool.encode(message.codesValidAndBillable, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                    if (message.uncoveredCodesStr != null && message.uncoveredCodesStr.length)
                        for (var i = 0; i < message.uncoveredCodesStr.length; ++i)
                            writer.uint32(/* id 37, wireType 2 =*/298).string(message.uncoveredCodesStr[i]);
                    if (message.reasonForNonCoverage != null && Object.hasOwnProperty.call(message, "reasonForNonCoverage"))
                        $root.infinitusai.be.NullableString.encode(message.reasonForNonCoverage, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                    if (message.reasonForNonCoverageType != null && Object.hasOwnProperty.call(message, "reasonForNonCoverageType"))
                        $root.infinitusai.be.ReasonForNonCoverageType.encode(message.reasonForNonCoverageType, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                    if (message.benefitPlanRenewalType != null && Object.hasOwnProperty.call(message, "benefitPlanRenewalType"))
                        $root.infinitusai.be.BenefitPlanRenewalType.encode(message.benefitPlanRenewalType, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PBMBVGeneralCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.PBMBVGeneralCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVGeneralCallOutputs} message PBMBVGeneralCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMBVGeneralCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PBMBVGeneralCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PBMBVGeneralCallOutputs} PBMBVGeneralCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMBVGeneralCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PBMBVGeneralCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.agentName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.referenceNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.hasOtherInsuranceOnFile = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.otherInsuranceName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.currentPlanPriority = $root.infinitusai.be.CurrentPlanPriority.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.policyType = $root.infinitusai.be.PolicyType.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.planType = $root.infinitusai.be.PlanType.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.planName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.groupNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.effectiveDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.planTerminated = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.termDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.isAdverseEvent = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.adverseEventType = $root.infinitusai.be.AdverseEventType.decode(reader, reader.uint32());
                                break;
                            }
                        case 40: {
                                message.benefitPlanRenewalType = $root.infinitusai.be.BenefitPlanRenewalType.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.fiscalRenewMonth = $root.infinitusai.be.MonthType.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.pbmPolicyNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.pcn = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                message.bin = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.isSelfFunded = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 20: {
                                message.annualCapExists = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 21: {
                                message.annualCap = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 22: {
                                message.annualCapMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 23: {
                                message.hasDeductible = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 24: {
                                message.deductibleTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 25: {
                                message.deductibleMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 26: {
                                message.hasFamilyDeductible = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 27: {
                                message.familyDeductibleTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 28: {
                                message.familyDeductibleMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 29: {
                                message.hasOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 30: {
                                message.oopTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 31: {
                                message.oopMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 32: {
                                message.hasFamilyOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 33: {
                                message.familyOopTotal = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 34: {
                                message.familyOopMet = $root.infinitusai.be.Money.decode(reader, reader.uint32());
                                break;
                            }
                        case 35: {
                                message.doesCopayApplyToOop = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 36: {
                                message.codesValidAndBillable = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 37: {
                                if (!(message.uncoveredCodesStr && message.uncoveredCodesStr.length))
                                    message.uncoveredCodesStr = [];
                                message.uncoveredCodesStr.push(reader.string());
                                break;
                            }
                        case 38: {
                                message.reasonForNonCoverage = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 39: {
                                message.reasonForNonCoverageType = $root.infinitusai.be.ReasonForNonCoverageType.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PBMBVGeneralCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PBMBVGeneralCallOutputs} PBMBVGeneralCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMBVGeneralCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PBMBVGeneralCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PBMBVGeneralCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.agentName != null && message.hasOwnProperty("agentName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.agentName);
                        if (error)
                            return "agentName." + error;
                    }
                    if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.referenceNumber);
                        if (error)
                            return "referenceNumber." + error;
                    }
                    if (message.hasOtherInsuranceOnFile != null && message.hasOwnProperty("hasOtherInsuranceOnFile")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasOtherInsuranceOnFile);
                        if (error)
                            return "hasOtherInsuranceOnFile." + error;
                    }
                    if (message.otherInsuranceName != null && message.hasOwnProperty("otherInsuranceName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.otherInsuranceName);
                        if (error)
                            return "otherInsuranceName." + error;
                    }
                    if (message.currentPlanPriority != null && message.hasOwnProperty("currentPlanPriority")) {
                        var error = $root.infinitusai.be.CurrentPlanPriority.verify(message.currentPlanPriority);
                        if (error)
                            return "currentPlanPriority." + error;
                    }
                    if (message.policyType != null && message.hasOwnProperty("policyType")) {
                        var error = $root.infinitusai.be.PolicyType.verify(message.policyType);
                        if (error)
                            return "policyType." + error;
                    }
                    if (message.planType != null && message.hasOwnProperty("planType")) {
                        var error = $root.infinitusai.be.PlanType.verify(message.planType);
                        if (error)
                            return "planType." + error;
                    }
                    if (message.planName != null && message.hasOwnProperty("planName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.planName);
                        if (error)
                            return "planName." + error;
                    }
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.groupNumber);
                        if (error)
                            return "groupNumber." + error;
                    }
                    if (message.effectiveDate != null && message.hasOwnProperty("effectiveDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.effectiveDate);
                        if (error)
                            return "effectiveDate." + error;
                    }
                    if (message.planTerminated != null && message.hasOwnProperty("planTerminated")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.planTerminated);
                        if (error)
                            return "planTerminated." + error;
                    }
                    if (message.termDate != null && message.hasOwnProperty("termDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.termDate);
                        if (error)
                            return "termDate." + error;
                    }
                    if (message.isAdverseEvent != null && message.hasOwnProperty("isAdverseEvent")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isAdverseEvent);
                        if (error)
                            return "isAdverseEvent." + error;
                    }
                    if (message.adverseEventType != null && message.hasOwnProperty("adverseEventType")) {
                        var error = $root.infinitusai.be.AdverseEventType.verify(message.adverseEventType);
                        if (error)
                            return "adverseEventType." + error;
                    }
                    if (message.benefitPlanRenewalType != null && message.hasOwnProperty("benefitPlanRenewalType")) {
                        var error = $root.infinitusai.be.BenefitPlanRenewalType.verify(message.benefitPlanRenewalType);
                        if (error)
                            return "benefitPlanRenewalType." + error;
                    }
                    if (message.fiscalRenewMonth != null && message.hasOwnProperty("fiscalRenewMonth")) {
                        var error = $root.infinitusai.be.MonthType.verify(message.fiscalRenewMonth);
                        if (error)
                            return "fiscalRenewMonth." + error;
                    }
                    if (message.pbmPolicyNumber != null && message.hasOwnProperty("pbmPolicyNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.pbmPolicyNumber);
                        if (error)
                            return "pbmPolicyNumber." + error;
                    }
                    if (message.pcn != null && message.hasOwnProperty("pcn")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.pcn);
                        if (error)
                            return "pcn." + error;
                    }
                    if (message.bin != null && message.hasOwnProperty("bin")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.bin);
                        if (error)
                            return "bin." + error;
                    }
                    if (message.isSelfFunded != null && message.hasOwnProperty("isSelfFunded")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isSelfFunded);
                        if (error)
                            return "isSelfFunded." + error;
                    }
                    if (message.annualCapExists != null && message.hasOwnProperty("annualCapExists")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.annualCapExists);
                        if (error)
                            return "annualCapExists." + error;
                    }
                    if (message.annualCap != null && message.hasOwnProperty("annualCap")) {
                        var error = $root.infinitusai.be.Money.verify(message.annualCap);
                        if (error)
                            return "annualCap." + error;
                    }
                    if (message.annualCapMet != null && message.hasOwnProperty("annualCapMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.annualCapMet);
                        if (error)
                            return "annualCapMet." + error;
                    }
                    if (message.hasDeductible != null && message.hasOwnProperty("hasDeductible")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasDeductible);
                        if (error)
                            return "hasDeductible." + error;
                    }
                    if (message.deductibleTotal != null && message.hasOwnProperty("deductibleTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleTotal);
                        if (error)
                            return "deductibleTotal." + error;
                    }
                    if (message.deductibleMet != null && message.hasOwnProperty("deductibleMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.deductibleMet);
                        if (error)
                            return "deductibleMet." + error;
                    }
                    if (message.hasFamilyDeductible != null && message.hasOwnProperty("hasFamilyDeductible")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasFamilyDeductible);
                        if (error)
                            return "hasFamilyDeductible." + error;
                    }
                    if (message.familyDeductibleTotal != null && message.hasOwnProperty("familyDeductibleTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyDeductibleTotal);
                        if (error)
                            return "familyDeductibleTotal." + error;
                    }
                    if (message.familyDeductibleMet != null && message.hasOwnProperty("familyDeductibleMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyDeductibleMet);
                        if (error)
                            return "familyDeductibleMet." + error;
                    }
                    if (message.hasOop != null && message.hasOwnProperty("hasOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasOop);
                        if (error)
                            return "hasOop." + error;
                    }
                    if (message.oopTotal != null && message.hasOwnProperty("oopTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.oopTotal);
                        if (error)
                            return "oopTotal." + error;
                    }
                    if (message.oopMet != null && message.hasOwnProperty("oopMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.oopMet);
                        if (error)
                            return "oopMet." + error;
                    }
                    if (message.hasFamilyOop != null && message.hasOwnProperty("hasFamilyOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.hasFamilyOop);
                        if (error)
                            return "hasFamilyOop." + error;
                    }
                    if (message.familyOopTotal != null && message.hasOwnProperty("familyOopTotal")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyOopTotal);
                        if (error)
                            return "familyOopTotal." + error;
                    }
                    if (message.familyOopMet != null && message.hasOwnProperty("familyOopMet")) {
                        var error = $root.infinitusai.be.Money.verify(message.familyOopMet);
                        if (error)
                            return "familyOopMet." + error;
                    }
                    if (message.doesCopayApplyToOop != null && message.hasOwnProperty("doesCopayApplyToOop")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.doesCopayApplyToOop);
                        if (error)
                            return "doesCopayApplyToOop." + error;
                    }
                    if (message.codesValidAndBillable != null && message.hasOwnProperty("codesValidAndBillable")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.codesValidAndBillable);
                        if (error)
                            return "codesValidAndBillable." + error;
                    }
                    if (message.uncoveredCodesStr != null && message.hasOwnProperty("uncoveredCodesStr")) {
                        if (!Array.isArray(message.uncoveredCodesStr))
                            return "uncoveredCodesStr: array expected";
                        for (var i = 0; i < message.uncoveredCodesStr.length; ++i)
                            if (!$util.isString(message.uncoveredCodesStr[i]))
                                return "uncoveredCodesStr: string[] expected";
                    }
                    if (message.reasonForNonCoverage != null && message.hasOwnProperty("reasonForNonCoverage")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.reasonForNonCoverage);
                        if (error)
                            return "reasonForNonCoverage." + error;
                    }
                    if (message.reasonForNonCoverageType != null && message.hasOwnProperty("reasonForNonCoverageType")) {
                        var error = $root.infinitusai.be.ReasonForNonCoverageType.verify(message.reasonForNonCoverageType);
                        if (error)
                            return "reasonForNonCoverageType." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PBMBVGeneralCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PBMBVGeneralCallOutputs} PBMBVGeneralCallOutputs
                 */
                PBMBVGeneralCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PBMBVGeneralCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.PBMBVGeneralCallOutputs();
                    if (object.agentName != null) {
                        if (typeof object.agentName !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.agentName: object expected");
                        message.agentName = $root.infinitusai.be.NullableString.fromObject(object.agentName);
                    }
                    if (object.referenceNumber != null) {
                        if (typeof object.referenceNumber !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.referenceNumber: object expected");
                        message.referenceNumber = $root.infinitusai.be.NullableString.fromObject(object.referenceNumber);
                    }
                    if (object.hasOtherInsuranceOnFile != null) {
                        if (typeof object.hasOtherInsuranceOnFile !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.hasOtherInsuranceOnFile: object expected");
                        message.hasOtherInsuranceOnFile = $root.infinitusai.be.NullableBool.fromObject(object.hasOtherInsuranceOnFile);
                    }
                    if (object.otherInsuranceName != null) {
                        if (typeof object.otherInsuranceName !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.otherInsuranceName: object expected");
                        message.otherInsuranceName = $root.infinitusai.be.NullableString.fromObject(object.otherInsuranceName);
                    }
                    if (object.currentPlanPriority != null) {
                        if (typeof object.currentPlanPriority !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.currentPlanPriority: object expected");
                        message.currentPlanPriority = $root.infinitusai.be.CurrentPlanPriority.fromObject(object.currentPlanPriority);
                    }
                    if (object.policyType != null) {
                        if (typeof object.policyType !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.policyType: object expected");
                        message.policyType = $root.infinitusai.be.PolicyType.fromObject(object.policyType);
                    }
                    if (object.planType != null) {
                        if (typeof object.planType !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.planType: object expected");
                        message.planType = $root.infinitusai.be.PlanType.fromObject(object.planType);
                    }
                    if (object.planName != null) {
                        if (typeof object.planName !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.planName: object expected");
                        message.planName = $root.infinitusai.be.NullableString.fromObject(object.planName);
                    }
                    if (object.groupNumber != null) {
                        if (typeof object.groupNumber !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.groupNumber: object expected");
                        message.groupNumber = $root.infinitusai.be.NullableString.fromObject(object.groupNumber);
                    }
                    if (object.effectiveDate != null) {
                        if (typeof object.effectiveDate !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.effectiveDate: object expected");
                        message.effectiveDate = $root.infinitusai.be.Date.fromObject(object.effectiveDate);
                    }
                    if (object.planTerminated != null) {
                        if (typeof object.planTerminated !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.planTerminated: object expected");
                        message.planTerminated = $root.infinitusai.be.NullableBool.fromObject(object.planTerminated);
                    }
                    if (object.termDate != null) {
                        if (typeof object.termDate !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.termDate: object expected");
                        message.termDate = $root.infinitusai.be.Date.fromObject(object.termDate);
                    }
                    if (object.isAdverseEvent != null) {
                        if (typeof object.isAdverseEvent !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.isAdverseEvent: object expected");
                        message.isAdverseEvent = $root.infinitusai.be.NullableBool.fromObject(object.isAdverseEvent);
                    }
                    if (object.adverseEventType != null) {
                        if (typeof object.adverseEventType !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.adverseEventType: object expected");
                        message.adverseEventType = $root.infinitusai.be.AdverseEventType.fromObject(object.adverseEventType);
                    }
                    if (object.benefitPlanRenewalType != null) {
                        if (typeof object.benefitPlanRenewalType !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.benefitPlanRenewalType: object expected");
                        message.benefitPlanRenewalType = $root.infinitusai.be.BenefitPlanRenewalType.fromObject(object.benefitPlanRenewalType);
                    }
                    if (object.fiscalRenewMonth != null) {
                        if (typeof object.fiscalRenewMonth !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.fiscalRenewMonth: object expected");
                        message.fiscalRenewMonth = $root.infinitusai.be.MonthType.fromObject(object.fiscalRenewMonth);
                    }
                    if (object.pbmPolicyNumber != null) {
                        if (typeof object.pbmPolicyNumber !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.pbmPolicyNumber: object expected");
                        message.pbmPolicyNumber = $root.infinitusai.be.NullableString.fromObject(object.pbmPolicyNumber);
                    }
                    if (object.pcn != null) {
                        if (typeof object.pcn !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.pcn: object expected");
                        message.pcn = $root.infinitusai.be.NullableString.fromObject(object.pcn);
                    }
                    if (object.bin != null) {
                        if (typeof object.bin !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.bin: object expected");
                        message.bin = $root.infinitusai.be.NullableString.fromObject(object.bin);
                    }
                    if (object.isSelfFunded != null) {
                        if (typeof object.isSelfFunded !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.isSelfFunded: object expected");
                        message.isSelfFunded = $root.infinitusai.be.NullableBool.fromObject(object.isSelfFunded);
                    }
                    if (object.annualCapExists != null) {
                        if (typeof object.annualCapExists !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.annualCapExists: object expected");
                        message.annualCapExists = $root.infinitusai.be.NullableBool.fromObject(object.annualCapExists);
                    }
                    if (object.annualCap != null) {
                        if (typeof object.annualCap !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.annualCap: object expected");
                        message.annualCap = $root.infinitusai.be.Money.fromObject(object.annualCap);
                    }
                    if (object.annualCapMet != null) {
                        if (typeof object.annualCapMet !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.annualCapMet: object expected");
                        message.annualCapMet = $root.infinitusai.be.Money.fromObject(object.annualCapMet);
                    }
                    if (object.hasDeductible != null) {
                        if (typeof object.hasDeductible !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.hasDeductible: object expected");
                        message.hasDeductible = $root.infinitusai.be.NullableBool.fromObject(object.hasDeductible);
                    }
                    if (object.deductibleTotal != null) {
                        if (typeof object.deductibleTotal !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.deductibleTotal: object expected");
                        message.deductibleTotal = $root.infinitusai.be.Money.fromObject(object.deductibleTotal);
                    }
                    if (object.deductibleMet != null) {
                        if (typeof object.deductibleMet !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.deductibleMet: object expected");
                        message.deductibleMet = $root.infinitusai.be.Money.fromObject(object.deductibleMet);
                    }
                    if (object.hasFamilyDeductible != null) {
                        if (typeof object.hasFamilyDeductible !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.hasFamilyDeductible: object expected");
                        message.hasFamilyDeductible = $root.infinitusai.be.NullableBool.fromObject(object.hasFamilyDeductible);
                    }
                    if (object.familyDeductibleTotal != null) {
                        if (typeof object.familyDeductibleTotal !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.familyDeductibleTotal: object expected");
                        message.familyDeductibleTotal = $root.infinitusai.be.Money.fromObject(object.familyDeductibleTotal);
                    }
                    if (object.familyDeductibleMet != null) {
                        if (typeof object.familyDeductibleMet !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.familyDeductibleMet: object expected");
                        message.familyDeductibleMet = $root.infinitusai.be.Money.fromObject(object.familyDeductibleMet);
                    }
                    if (object.hasOop != null) {
                        if (typeof object.hasOop !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.hasOop: object expected");
                        message.hasOop = $root.infinitusai.be.NullableBool.fromObject(object.hasOop);
                    }
                    if (object.oopTotal != null) {
                        if (typeof object.oopTotal !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.oopTotal: object expected");
                        message.oopTotal = $root.infinitusai.be.Money.fromObject(object.oopTotal);
                    }
                    if (object.oopMet != null) {
                        if (typeof object.oopMet !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.oopMet: object expected");
                        message.oopMet = $root.infinitusai.be.Money.fromObject(object.oopMet);
                    }
                    if (object.hasFamilyOop != null) {
                        if (typeof object.hasFamilyOop !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.hasFamilyOop: object expected");
                        message.hasFamilyOop = $root.infinitusai.be.NullableBool.fromObject(object.hasFamilyOop);
                    }
                    if (object.familyOopTotal != null) {
                        if (typeof object.familyOopTotal !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.familyOopTotal: object expected");
                        message.familyOopTotal = $root.infinitusai.be.Money.fromObject(object.familyOopTotal);
                    }
                    if (object.familyOopMet != null) {
                        if (typeof object.familyOopMet !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.familyOopMet: object expected");
                        message.familyOopMet = $root.infinitusai.be.Money.fromObject(object.familyOopMet);
                    }
                    if (object.doesCopayApplyToOop != null) {
                        if (typeof object.doesCopayApplyToOop !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.doesCopayApplyToOop: object expected");
                        message.doesCopayApplyToOop = $root.infinitusai.be.NullableBool.fromObject(object.doesCopayApplyToOop);
                    }
                    if (object.codesValidAndBillable != null) {
                        if (typeof object.codesValidAndBillable !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.codesValidAndBillable: object expected");
                        message.codesValidAndBillable = $root.infinitusai.be.NullableBool.fromObject(object.codesValidAndBillable);
                    }
                    if (object.uncoveredCodesStr) {
                        if (!Array.isArray(object.uncoveredCodesStr))
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.uncoveredCodesStr: array expected");
                        message.uncoveredCodesStr = [];
                        for (var i = 0; i < object.uncoveredCodesStr.length; ++i)
                            message.uncoveredCodesStr[i] = String(object.uncoveredCodesStr[i]);
                    }
                    if (object.reasonForNonCoverage != null) {
                        if (typeof object.reasonForNonCoverage !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.reasonForNonCoverage: object expected");
                        message.reasonForNonCoverage = $root.infinitusai.be.NullableString.fromObject(object.reasonForNonCoverage);
                    }
                    if (object.reasonForNonCoverageType != null) {
                        if (typeof object.reasonForNonCoverageType !== "object")
                            throw TypeError(".infinitusai.be.PBMBVGeneralCallOutputs.reasonForNonCoverageType: object expected");
                        message.reasonForNonCoverageType = $root.infinitusai.be.ReasonForNonCoverageType.fromObject(object.reasonForNonCoverageType);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PBMBVGeneralCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {infinitusai.be.PBMBVGeneralCallOutputs} message PBMBVGeneralCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PBMBVGeneralCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uncoveredCodesStr = [];
                    if (options.defaults) {
                        object.agentName = null;
                        object.referenceNumber = null;
                        object.hasOtherInsuranceOnFile = null;
                        object.otherInsuranceName = null;
                        object.currentPlanPriority = null;
                        object.policyType = null;
                        object.planType = null;
                        object.planName = null;
                        object.groupNumber = null;
                        object.effectiveDate = null;
                        object.planTerminated = null;
                        object.termDate = null;
                        object.isAdverseEvent = null;
                        object.adverseEventType = null;
                        object.fiscalRenewMonth = null;
                        object.pbmPolicyNumber = null;
                        object.pcn = null;
                        object.bin = null;
                        object.isSelfFunded = null;
                        object.annualCapExists = null;
                        object.annualCap = null;
                        object.annualCapMet = null;
                        object.hasDeductible = null;
                        object.deductibleTotal = null;
                        object.deductibleMet = null;
                        object.hasFamilyDeductible = null;
                        object.familyDeductibleTotal = null;
                        object.familyDeductibleMet = null;
                        object.hasOop = null;
                        object.oopTotal = null;
                        object.oopMet = null;
                        object.hasFamilyOop = null;
                        object.familyOopTotal = null;
                        object.familyOopMet = null;
                        object.doesCopayApplyToOop = null;
                        object.codesValidAndBillable = null;
                        object.reasonForNonCoverage = null;
                        object.reasonForNonCoverageType = null;
                        object.benefitPlanRenewalType = null;
                    }
                    if (message.agentName != null && message.hasOwnProperty("agentName"))
                        object.agentName = $root.infinitusai.be.NullableString.toObject(message.agentName, options);
                    if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                        object.referenceNumber = $root.infinitusai.be.NullableString.toObject(message.referenceNumber, options);
                    if (message.hasOtherInsuranceOnFile != null && message.hasOwnProperty("hasOtherInsuranceOnFile"))
                        object.hasOtherInsuranceOnFile = $root.infinitusai.be.NullableBool.toObject(message.hasOtherInsuranceOnFile, options);
                    if (message.otherInsuranceName != null && message.hasOwnProperty("otherInsuranceName"))
                        object.otherInsuranceName = $root.infinitusai.be.NullableString.toObject(message.otherInsuranceName, options);
                    if (message.currentPlanPriority != null && message.hasOwnProperty("currentPlanPriority"))
                        object.currentPlanPriority = $root.infinitusai.be.CurrentPlanPriority.toObject(message.currentPlanPriority, options);
                    if (message.policyType != null && message.hasOwnProperty("policyType"))
                        object.policyType = $root.infinitusai.be.PolicyType.toObject(message.policyType, options);
                    if (message.planType != null && message.hasOwnProperty("planType"))
                        object.planType = $root.infinitusai.be.PlanType.toObject(message.planType, options);
                    if (message.planName != null && message.hasOwnProperty("planName"))
                        object.planName = $root.infinitusai.be.NullableString.toObject(message.planName, options);
                    if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                        object.groupNumber = $root.infinitusai.be.NullableString.toObject(message.groupNumber, options);
                    if (message.effectiveDate != null && message.hasOwnProperty("effectiveDate"))
                        object.effectiveDate = $root.infinitusai.be.Date.toObject(message.effectiveDate, options);
                    if (message.planTerminated != null && message.hasOwnProperty("planTerminated"))
                        object.planTerminated = $root.infinitusai.be.NullableBool.toObject(message.planTerminated, options);
                    if (message.termDate != null && message.hasOwnProperty("termDate"))
                        object.termDate = $root.infinitusai.be.Date.toObject(message.termDate, options);
                    if (message.isAdverseEvent != null && message.hasOwnProperty("isAdverseEvent"))
                        object.isAdverseEvent = $root.infinitusai.be.NullableBool.toObject(message.isAdverseEvent, options);
                    if (message.adverseEventType != null && message.hasOwnProperty("adverseEventType"))
                        object.adverseEventType = $root.infinitusai.be.AdverseEventType.toObject(message.adverseEventType, options);
                    if (message.fiscalRenewMonth != null && message.hasOwnProperty("fiscalRenewMonth"))
                        object.fiscalRenewMonth = $root.infinitusai.be.MonthType.toObject(message.fiscalRenewMonth, options);
                    if (message.pbmPolicyNumber != null && message.hasOwnProperty("pbmPolicyNumber"))
                        object.pbmPolicyNumber = $root.infinitusai.be.NullableString.toObject(message.pbmPolicyNumber, options);
                    if (message.pcn != null && message.hasOwnProperty("pcn"))
                        object.pcn = $root.infinitusai.be.NullableString.toObject(message.pcn, options);
                    if (message.bin != null && message.hasOwnProperty("bin"))
                        object.bin = $root.infinitusai.be.NullableString.toObject(message.bin, options);
                    if (message.isSelfFunded != null && message.hasOwnProperty("isSelfFunded"))
                        object.isSelfFunded = $root.infinitusai.be.NullableBool.toObject(message.isSelfFunded, options);
                    if (message.annualCapExists != null && message.hasOwnProperty("annualCapExists"))
                        object.annualCapExists = $root.infinitusai.be.NullableBool.toObject(message.annualCapExists, options);
                    if (message.annualCap != null && message.hasOwnProperty("annualCap"))
                        object.annualCap = $root.infinitusai.be.Money.toObject(message.annualCap, options);
                    if (message.annualCapMet != null && message.hasOwnProperty("annualCapMet"))
                        object.annualCapMet = $root.infinitusai.be.Money.toObject(message.annualCapMet, options);
                    if (message.hasDeductible != null && message.hasOwnProperty("hasDeductible"))
                        object.hasDeductible = $root.infinitusai.be.NullableBool.toObject(message.hasDeductible, options);
                    if (message.deductibleTotal != null && message.hasOwnProperty("deductibleTotal"))
                        object.deductibleTotal = $root.infinitusai.be.Money.toObject(message.deductibleTotal, options);
                    if (message.deductibleMet != null && message.hasOwnProperty("deductibleMet"))
                        object.deductibleMet = $root.infinitusai.be.Money.toObject(message.deductibleMet, options);
                    if (message.hasFamilyDeductible != null && message.hasOwnProperty("hasFamilyDeductible"))
                        object.hasFamilyDeductible = $root.infinitusai.be.NullableBool.toObject(message.hasFamilyDeductible, options);
                    if (message.familyDeductibleTotal != null && message.hasOwnProperty("familyDeductibleTotal"))
                        object.familyDeductibleTotal = $root.infinitusai.be.Money.toObject(message.familyDeductibleTotal, options);
                    if (message.familyDeductibleMet != null && message.hasOwnProperty("familyDeductibleMet"))
                        object.familyDeductibleMet = $root.infinitusai.be.Money.toObject(message.familyDeductibleMet, options);
                    if (message.hasOop != null && message.hasOwnProperty("hasOop"))
                        object.hasOop = $root.infinitusai.be.NullableBool.toObject(message.hasOop, options);
                    if (message.oopTotal != null && message.hasOwnProperty("oopTotal"))
                        object.oopTotal = $root.infinitusai.be.Money.toObject(message.oopTotal, options);
                    if (message.oopMet != null && message.hasOwnProperty("oopMet"))
                        object.oopMet = $root.infinitusai.be.Money.toObject(message.oopMet, options);
                    if (message.hasFamilyOop != null && message.hasOwnProperty("hasFamilyOop"))
                        object.hasFamilyOop = $root.infinitusai.be.NullableBool.toObject(message.hasFamilyOop, options);
                    if (message.familyOopTotal != null && message.hasOwnProperty("familyOopTotal"))
                        object.familyOopTotal = $root.infinitusai.be.Money.toObject(message.familyOopTotal, options);
                    if (message.familyOopMet != null && message.hasOwnProperty("familyOopMet"))
                        object.familyOopMet = $root.infinitusai.be.Money.toObject(message.familyOopMet, options);
                    if (message.doesCopayApplyToOop != null && message.hasOwnProperty("doesCopayApplyToOop"))
                        object.doesCopayApplyToOop = $root.infinitusai.be.NullableBool.toObject(message.doesCopayApplyToOop, options);
                    if (message.codesValidAndBillable != null && message.hasOwnProperty("codesValidAndBillable"))
                        object.codesValidAndBillable = $root.infinitusai.be.NullableBool.toObject(message.codesValidAndBillable, options);
                    if (message.uncoveredCodesStr && message.uncoveredCodesStr.length) {
                        object.uncoveredCodesStr = [];
                        for (var j = 0; j < message.uncoveredCodesStr.length; ++j)
                            object.uncoveredCodesStr[j] = message.uncoveredCodesStr[j];
                    }
                    if (message.reasonForNonCoverage != null && message.hasOwnProperty("reasonForNonCoverage"))
                        object.reasonForNonCoverage = $root.infinitusai.be.NullableString.toObject(message.reasonForNonCoverage, options);
                    if (message.reasonForNonCoverageType != null && message.hasOwnProperty("reasonForNonCoverageType"))
                        object.reasonForNonCoverageType = $root.infinitusai.be.ReasonForNonCoverageType.toObject(message.reasonForNonCoverageType, options);
                    if (message.benefitPlanRenewalType != null && message.hasOwnProperty("benefitPlanRenewalType"))
                        object.benefitPlanRenewalType = $root.infinitusai.be.BenefitPlanRenewalType.toObject(message.benefitPlanRenewalType, options);
                    return object;
                };
    
                /**
                 * Converts this PBMBVGeneralCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PBMBVGeneralCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PBMBVGeneralCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PBMBVGeneralCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PBMBVGeneralCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PBMBVGeneralCallOutputs";
                };
    
                return PBMBVGeneralCallOutputs;
            })();
    
            be.PBMBVProductCallOutputs = (function() {
    
                /**
                 * Properties of a PBMBVProductCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IPBMBVProductCallOutputs
                 * @property {infinitusai.be.INullableBool|null} [isProductPreferred] PBMBVProductCallOutputs isProductPreferred
                 * @property {infinitusai.be.INullableInt32|null} [productTierLevel] PBMBVProductCallOutputs productTierLevel
                 * @property {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null} [codeBenefits] PBMBVProductCallOutputs codeBenefits
                 * @property {infinitusai.be.INullableBool|null} [deductibleApplies] PBMBVProductCallOutputs deductibleApplies
                 */
    
                /**
                 * Constructs a new PBMBVProductCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PBMBVProductCallOutputs.
                 * @implements IPBMBVProductCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IPBMBVProductCallOutputs=} [properties] Properties to set
                 */
                function PBMBVProductCallOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PBMBVProductCallOutputs isProductPreferred.
                 * @member {infinitusai.be.INullableBool|null|undefined} isProductPreferred
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @instance
                 */
                PBMBVProductCallOutputs.prototype.isProductPreferred = null;
    
                /**
                 * PBMBVProductCallOutputs productTierLevel.
                 * @member {infinitusai.be.INullableInt32|null|undefined} productTierLevel
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @instance
                 */
                PBMBVProductCallOutputs.prototype.productTierLevel = null;
    
                /**
                 * PBMBVProductCallOutputs codeBenefits.
                 * @member {infinitusai.be.IFullBVCodeBenefitsCallOutputs|null|undefined} codeBenefits
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @instance
                 */
                PBMBVProductCallOutputs.prototype.codeBenefits = null;
    
                /**
                 * PBMBVProductCallOutputs deductibleApplies.
                 * @member {infinitusai.be.INullableBool|null|undefined} deductibleApplies
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @instance
                 */
                PBMBVProductCallOutputs.prototype.deductibleApplies = null;
    
                /**
                 * Creates a new PBMBVProductCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVProductCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.PBMBVProductCallOutputs} PBMBVProductCallOutputs instance
                 */
                PBMBVProductCallOutputs.create = function create(properties) {
                    return new PBMBVProductCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified PBMBVProductCallOutputs message. Does not implicitly {@link infinitusai.be.PBMBVProductCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVProductCallOutputs} message PBMBVProductCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMBVProductCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isProductPreferred != null && Object.hasOwnProperty.call(message, "isProductPreferred"))
                        $root.infinitusai.be.NullableBool.encode(message.isProductPreferred, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.productTierLevel != null && Object.hasOwnProperty.call(message, "productTierLevel"))
                        $root.infinitusai.be.NullableInt32.encode(message.productTierLevel, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.codeBenefits != null && Object.hasOwnProperty.call(message, "codeBenefits"))
                        $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.encode(message.codeBenefits, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.deductibleApplies != null && Object.hasOwnProperty.call(message, "deductibleApplies"))
                        $root.infinitusai.be.NullableBool.encode(message.deductibleApplies, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PBMBVProductCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.PBMBVProductCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVProductCallOutputs} message PBMBVProductCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMBVProductCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PBMBVProductCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PBMBVProductCallOutputs} PBMBVProductCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMBVProductCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PBMBVProductCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isProductPreferred = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.productTierLevel = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.deductibleApplies = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PBMBVProductCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PBMBVProductCallOutputs} PBMBVProductCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMBVProductCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PBMBVProductCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PBMBVProductCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isProductPreferred != null && message.hasOwnProperty("isProductPreferred")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isProductPreferred);
                        if (error)
                            return "isProductPreferred." + error;
                    }
                    if (message.productTierLevel != null && message.hasOwnProperty("productTierLevel")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.productTierLevel);
                        if (error)
                            return "productTierLevel." + error;
                    }
                    if (message.codeBenefits != null && message.hasOwnProperty("codeBenefits")) {
                        var error = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.verify(message.codeBenefits);
                        if (error)
                            return "codeBenefits." + error;
                    }
                    if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.deductibleApplies);
                        if (error)
                            return "deductibleApplies." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PBMBVProductCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PBMBVProductCallOutputs} PBMBVProductCallOutputs
                 */
                PBMBVProductCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PBMBVProductCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.PBMBVProductCallOutputs();
                    if (object.isProductPreferred != null) {
                        if (typeof object.isProductPreferred !== "object")
                            throw TypeError(".infinitusai.be.PBMBVProductCallOutputs.isProductPreferred: object expected");
                        message.isProductPreferred = $root.infinitusai.be.NullableBool.fromObject(object.isProductPreferred);
                    }
                    if (object.productTierLevel != null) {
                        if (typeof object.productTierLevel !== "object")
                            throw TypeError(".infinitusai.be.PBMBVProductCallOutputs.productTierLevel: object expected");
                        message.productTierLevel = $root.infinitusai.be.NullableInt32.fromObject(object.productTierLevel);
                    }
                    if (object.codeBenefits != null) {
                        if (typeof object.codeBenefits !== "object")
                            throw TypeError(".infinitusai.be.PBMBVProductCallOutputs.codeBenefits: object expected");
                        message.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.fromObject(object.codeBenefits);
                    }
                    if (object.deductibleApplies != null) {
                        if (typeof object.deductibleApplies !== "object")
                            throw TypeError(".infinitusai.be.PBMBVProductCallOutputs.deductibleApplies: object expected");
                        message.deductibleApplies = $root.infinitusai.be.NullableBool.fromObject(object.deductibleApplies);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PBMBVProductCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {infinitusai.be.PBMBVProductCallOutputs} message PBMBVProductCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PBMBVProductCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isProductPreferred = null;
                        object.productTierLevel = null;
                        object.codeBenefits = null;
                        object.deductibleApplies = null;
                    }
                    if (message.isProductPreferred != null && message.hasOwnProperty("isProductPreferred"))
                        object.isProductPreferred = $root.infinitusai.be.NullableBool.toObject(message.isProductPreferred, options);
                    if (message.productTierLevel != null && message.hasOwnProperty("productTierLevel"))
                        object.productTierLevel = $root.infinitusai.be.NullableInt32.toObject(message.productTierLevel, options);
                    if (message.codeBenefits != null && message.hasOwnProperty("codeBenefits"))
                        object.codeBenefits = $root.infinitusai.be.FullBVCodeBenefitsCallOutputs.toObject(message.codeBenefits, options);
                    if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies"))
                        object.deductibleApplies = $root.infinitusai.be.NullableBool.toObject(message.deductibleApplies, options);
                    return object;
                };
    
                /**
                 * Converts this PBMBVProductCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PBMBVProductCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PBMBVProductCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PBMBVProductCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PBMBVProductCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PBMBVProductCallOutputs";
                };
    
                return PBMBVProductCallOutputs;
            })();
    
            be.PBMBVReferralCallOutputs = (function() {
    
                /**
                 * Properties of a PBMBVReferralCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IPBMBVReferralCallOutputs
                 * @property {infinitusai.be.INullableBool|null} [specialtyPharmacyAvailable] PBMBVReferralCallOutputs specialtyPharmacyAvailable
                 * @property {infinitusai.be.INullableString|null} [specialtyPharmacy1Name] PBMBVReferralCallOutputs specialtyPharmacy1Name
                 * @property {infinitusai.be.INullableString|null} [specialtyPharmacy1Phone] PBMBVReferralCallOutputs specialtyPharmacy1Phone
                 * @property {infinitusai.be.INullableString|null} [specialtyPharmacy1Fax] PBMBVReferralCallOutputs specialtyPharmacy1Fax
                 * @property {infinitusai.be.INullableBool|null} [isPriorAuthRequired] PBMBVReferralCallOutputs isPriorAuthRequired
                 * @property {infinitusai.be.INullableBool|null} [isPriorAuthOnFile] PBMBVReferralCallOutputs isPriorAuthOnFile
                 * @property {infinitusai.be.IPriorAuthStatusType|null} [priorAuthStatus] PBMBVReferralCallOutputs priorAuthStatus
                 * @property {infinitusai.be.IDate|null} [priorAuthApprovalStartDate] PBMBVReferralCallOutputs priorAuthApprovalStartDate
                 * @property {infinitusai.be.IDate|null} [priorAuthApprovalEndDate] PBMBVReferralCallOutputs priorAuthApprovalEndDate
                 * @property {infinitusai.be.INullableString|null} [priorAuthApprovalNumber] PBMBVReferralCallOutputs priorAuthApprovalNumber
                 * @property {infinitusai.be.INullableString|null} [priorAuthTurnaroundNotes] PBMBVReferralCallOutputs priorAuthTurnaroundNotes
                 * @property {infinitusai.be.INullableString|null} [priorAuthDenialReason] PBMBVReferralCallOutputs priorAuthDenialReason
                 * @property {infinitusai.be.IDate|null} [priorAuthDenialDate] PBMBVReferralCallOutputs priorAuthDenialDate
                 * @property {infinitusai.be.IDate|null} [priorAuthSubmissionDate] PBMBVReferralCallOutputs priorAuthSubmissionDate
                 * @property {infinitusai.be.INullableString|null} [priorAuthMethodEntity] PBMBVReferralCallOutputs priorAuthMethodEntity
                 * @property {infinitusai.be.IPriorAuthAndPredeterminationMethod|null} [priorAuthPhysicianNotificationMethod] PBMBVReferralCallOutputs priorAuthPhysicianNotificationMethod
                 * @property {Object.<string,string>|null} [priorAuthMethods] PBMBVReferralCallOutputs priorAuthMethods
                 * @property {infinitusai.be.IPriorAuthMethod|null} [priorAuthMethodsV2] PBMBVReferralCallOutputs priorAuthMethodsV2
                 * @property {infinitusai.be.IMultiSelectPriorAuthRequirementType|null} [priorAuthRequirements] PBMBVReferralCallOutputs priorAuthRequirements
                 * @property {infinitusai.be.INullableBool|null} [isStepTherapyRequired] PBMBVReferralCallOutputs isStepTherapyRequired
                 * @property {infinitusai.be.IStepTherapyMethod|null} [stepTherapyMethod] PBMBVReferralCallOutputs stepTherapyMethod
                 * @property {infinitusai.be.INullableString|null} [stepTherapyTreatment] PBMBVReferralCallOutputs stepTherapyTreatment
                 * @property {infinitusai.be.IPriorAuthAndPredeterminationMethod|null} [priorAuthMethodType] PBMBVReferralCallOutputs priorAuthMethodType
                 * @property {infinitusai.be.INullableString|null} [priorAuthMethodContact] PBMBVReferralCallOutputs priorAuthMethodContact
                 */
    
                /**
                 * Constructs a new PBMBVReferralCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PBMBVReferralCallOutputs.
                 * @implements IPBMBVReferralCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IPBMBVReferralCallOutputs=} [properties] Properties to set
                 */
                function PBMBVReferralCallOutputs(properties) {
                    this.priorAuthMethods = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PBMBVReferralCallOutputs specialtyPharmacyAvailable.
                 * @member {infinitusai.be.INullableBool|null|undefined} specialtyPharmacyAvailable
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.specialtyPharmacyAvailable = null;
    
                /**
                 * PBMBVReferralCallOutputs specialtyPharmacy1Name.
                 * @member {infinitusai.be.INullableString|null|undefined} specialtyPharmacy1Name
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.specialtyPharmacy1Name = null;
    
                /**
                 * PBMBVReferralCallOutputs specialtyPharmacy1Phone.
                 * @member {infinitusai.be.INullableString|null|undefined} specialtyPharmacy1Phone
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.specialtyPharmacy1Phone = null;
    
                /**
                 * PBMBVReferralCallOutputs specialtyPharmacy1Fax.
                 * @member {infinitusai.be.INullableString|null|undefined} specialtyPharmacy1Fax
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.specialtyPharmacy1Fax = null;
    
                /**
                 * PBMBVReferralCallOutputs isPriorAuthRequired.
                 * @member {infinitusai.be.INullableBool|null|undefined} isPriorAuthRequired
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.isPriorAuthRequired = null;
    
                /**
                 * PBMBVReferralCallOutputs isPriorAuthOnFile.
                 * @member {infinitusai.be.INullableBool|null|undefined} isPriorAuthOnFile
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.isPriorAuthOnFile = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthStatus.
                 * @member {infinitusai.be.IPriorAuthStatusType|null|undefined} priorAuthStatus
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthStatus = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthApprovalStartDate.
                 * @member {infinitusai.be.IDate|null|undefined} priorAuthApprovalStartDate
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthApprovalStartDate = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthApprovalEndDate.
                 * @member {infinitusai.be.IDate|null|undefined} priorAuthApprovalEndDate
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthApprovalEndDate = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthApprovalNumber.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthApprovalNumber
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthApprovalNumber = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthTurnaroundNotes.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthTurnaroundNotes
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthTurnaroundNotes = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthDenialReason.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthDenialReason
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthDenialReason = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthDenialDate.
                 * @member {infinitusai.be.IDate|null|undefined} priorAuthDenialDate
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthDenialDate = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthSubmissionDate.
                 * @member {infinitusai.be.IDate|null|undefined} priorAuthSubmissionDate
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthSubmissionDate = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthMethodEntity.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthMethodEntity
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthMethodEntity = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthPhysicianNotificationMethod.
                 * @member {infinitusai.be.IPriorAuthAndPredeterminationMethod|null|undefined} priorAuthPhysicianNotificationMethod
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthPhysicianNotificationMethod = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthMethods.
                 * @member {Object.<string,string>} priorAuthMethods
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthMethods = $util.emptyObject;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthMethodsV2.
                 * @member {infinitusai.be.IPriorAuthMethod|null|undefined} priorAuthMethodsV2
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthMethodsV2 = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthRequirements.
                 * @member {infinitusai.be.IMultiSelectPriorAuthRequirementType|null|undefined} priorAuthRequirements
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthRequirements = null;
    
                /**
                 * PBMBVReferralCallOutputs isStepTherapyRequired.
                 * @member {infinitusai.be.INullableBool|null|undefined} isStepTherapyRequired
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.isStepTherapyRequired = null;
    
                /**
                 * PBMBVReferralCallOutputs stepTherapyMethod.
                 * @member {infinitusai.be.IStepTherapyMethod|null|undefined} stepTherapyMethod
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.stepTherapyMethod = null;
    
                /**
                 * PBMBVReferralCallOutputs stepTherapyTreatment.
                 * @member {infinitusai.be.INullableString|null|undefined} stepTherapyTreatment
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.stepTherapyTreatment = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthMethodType.
                 * @member {infinitusai.be.IPriorAuthAndPredeterminationMethod|null|undefined} priorAuthMethodType
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthMethodType = null;
    
                /**
                 * PBMBVReferralCallOutputs priorAuthMethodContact.
                 * @member {infinitusai.be.INullableString|null|undefined} priorAuthMethodContact
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 */
                PBMBVReferralCallOutputs.prototype.priorAuthMethodContact = null;
    
                /**
                 * Creates a new PBMBVReferralCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVReferralCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.PBMBVReferralCallOutputs} PBMBVReferralCallOutputs instance
                 */
                PBMBVReferralCallOutputs.create = function create(properties) {
                    return new PBMBVReferralCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified PBMBVReferralCallOutputs message. Does not implicitly {@link infinitusai.be.PBMBVReferralCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVReferralCallOutputs} message PBMBVReferralCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMBVReferralCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.specialtyPharmacyAvailable != null && Object.hasOwnProperty.call(message, "specialtyPharmacyAvailable"))
                        $root.infinitusai.be.NullableBool.encode(message.specialtyPharmacyAvailable, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.specialtyPharmacy1Name != null && Object.hasOwnProperty.call(message, "specialtyPharmacy1Name"))
                        $root.infinitusai.be.NullableString.encode(message.specialtyPharmacy1Name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.specialtyPharmacy1Phone != null && Object.hasOwnProperty.call(message, "specialtyPharmacy1Phone"))
                        $root.infinitusai.be.NullableString.encode(message.specialtyPharmacy1Phone, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.specialtyPharmacy1Fax != null && Object.hasOwnProperty.call(message, "specialtyPharmacy1Fax"))
                        $root.infinitusai.be.NullableString.encode(message.specialtyPharmacy1Fax, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.isPriorAuthRequired != null && Object.hasOwnProperty.call(message, "isPriorAuthRequired"))
                        $root.infinitusai.be.NullableBool.encode(message.isPriorAuthRequired, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.isPriorAuthOnFile != null && Object.hasOwnProperty.call(message, "isPriorAuthOnFile"))
                        $root.infinitusai.be.NullableBool.encode(message.isPriorAuthOnFile, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.priorAuthApprovalStartDate != null && Object.hasOwnProperty.call(message, "priorAuthApprovalStartDate"))
                        $root.infinitusai.be.Date.encode(message.priorAuthApprovalStartDate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.priorAuthApprovalEndDate != null && Object.hasOwnProperty.call(message, "priorAuthApprovalEndDate"))
                        $root.infinitusai.be.Date.encode(message.priorAuthApprovalEndDate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.priorAuthApprovalNumber != null && Object.hasOwnProperty.call(message, "priorAuthApprovalNumber"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthApprovalNumber, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.priorAuthTurnaroundNotes != null && Object.hasOwnProperty.call(message, "priorAuthTurnaroundNotes"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthTurnaroundNotes, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.priorAuthMethodType != null && Object.hasOwnProperty.call(message, "priorAuthMethodType"))
                        $root.infinitusai.be.PriorAuthAndPredeterminationMethod.encode(message.priorAuthMethodType, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.priorAuthMethodEntity != null && Object.hasOwnProperty.call(message, "priorAuthMethodEntity"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthMethodEntity, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.priorAuthMethodContact != null && Object.hasOwnProperty.call(message, "priorAuthMethodContact"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthMethodContact, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.priorAuthPhysicianNotificationMethod != null && Object.hasOwnProperty.call(message, "priorAuthPhysicianNotificationMethod"))
                        $root.infinitusai.be.PriorAuthAndPredeterminationMethod.encode(message.priorAuthPhysicianNotificationMethod, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.isStepTherapyRequired != null && Object.hasOwnProperty.call(message, "isStepTherapyRequired"))
                        $root.infinitusai.be.NullableBool.encode(message.isStepTherapyRequired, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.stepTherapyMethod != null && Object.hasOwnProperty.call(message, "stepTherapyMethod"))
                        $root.infinitusai.be.StepTherapyMethod.encode(message.stepTherapyMethod, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.stepTherapyTreatment != null && Object.hasOwnProperty.call(message, "stepTherapyTreatment"))
                        $root.infinitusai.be.NullableString.encode(message.stepTherapyTreatment, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.priorAuthMethods != null && Object.hasOwnProperty.call(message, "priorAuthMethods"))
                        for (var keys = Object.keys(message.priorAuthMethods), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 18, wireType 2 =*/146).fork().uint32(/* id 1, wireType 0 =*/8).int32(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.priorAuthMethods[keys[i]]).ldelim();
                    if (message.priorAuthRequirements != null && Object.hasOwnProperty.call(message, "priorAuthRequirements"))
                        $root.infinitusai.be.MultiSelectPriorAuthRequirementType.encode(message.priorAuthRequirements, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                    if (message.priorAuthMethodsV2 != null && Object.hasOwnProperty.call(message, "priorAuthMethodsV2"))
                        $root.infinitusai.be.PriorAuthMethod.encode(message.priorAuthMethodsV2, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                    if (message.priorAuthStatus != null && Object.hasOwnProperty.call(message, "priorAuthStatus"))
                        $root.infinitusai.be.PriorAuthStatusType.encode(message.priorAuthStatus, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    if (message.priorAuthDenialReason != null && Object.hasOwnProperty.call(message, "priorAuthDenialReason"))
                        $root.infinitusai.be.NullableString.encode(message.priorAuthDenialReason, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                    if (message.priorAuthDenialDate != null && Object.hasOwnProperty.call(message, "priorAuthDenialDate"))
                        $root.infinitusai.be.Date.encode(message.priorAuthDenialDate, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                    if (message.priorAuthSubmissionDate != null && Object.hasOwnProperty.call(message, "priorAuthSubmissionDate"))
                        $root.infinitusai.be.Date.encode(message.priorAuthSubmissionDate, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PBMBVReferralCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.PBMBVReferralCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVReferralCallOutputs} message PBMBVReferralCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMBVReferralCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PBMBVReferralCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PBMBVReferralCallOutputs} PBMBVReferralCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMBVReferralCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PBMBVReferralCallOutputs(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.specialtyPharmacyAvailable = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.specialtyPharmacy1Name = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.specialtyPharmacy1Phone = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.specialtyPharmacy1Fax = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.isPriorAuthRequired = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.isPriorAuthOnFile = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 21: {
                                message.priorAuthStatus = $root.infinitusai.be.PriorAuthStatusType.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.priorAuthApprovalStartDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.priorAuthApprovalEndDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.priorAuthApprovalNumber = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.priorAuthTurnaroundNotes = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 22: {
                                message.priorAuthDenialReason = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 23: {
                                message.priorAuthDenialDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 24: {
                                message.priorAuthSubmissionDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.priorAuthMethodEntity = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.priorAuthPhysicianNotificationMethod = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                if (message.priorAuthMethods === $util.emptyObject)
                                    message.priorAuthMethods = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int32();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.priorAuthMethods[key] = value;
                                break;
                            }
                        case 20: {
                                message.priorAuthMethodsV2 = $root.infinitusai.be.PriorAuthMethod.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.priorAuthRequirements = $root.infinitusai.be.MultiSelectPriorAuthRequirementType.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.isStepTherapyRequired = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.stepTherapyMethod = $root.infinitusai.be.StepTherapyMethod.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.stepTherapyTreatment = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.priorAuthMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.priorAuthMethodContact = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PBMBVReferralCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PBMBVReferralCallOutputs} PBMBVReferralCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMBVReferralCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PBMBVReferralCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PBMBVReferralCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.specialtyPharmacyAvailable != null && message.hasOwnProperty("specialtyPharmacyAvailable")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.specialtyPharmacyAvailable);
                        if (error)
                            return "specialtyPharmacyAvailable." + error;
                    }
                    if (message.specialtyPharmacy1Name != null && message.hasOwnProperty("specialtyPharmacy1Name")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.specialtyPharmacy1Name);
                        if (error)
                            return "specialtyPharmacy1Name." + error;
                    }
                    if (message.specialtyPharmacy1Phone != null && message.hasOwnProperty("specialtyPharmacy1Phone")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.specialtyPharmacy1Phone);
                        if (error)
                            return "specialtyPharmacy1Phone." + error;
                    }
                    if (message.specialtyPharmacy1Fax != null && message.hasOwnProperty("specialtyPharmacy1Fax")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.specialtyPharmacy1Fax);
                        if (error)
                            return "specialtyPharmacy1Fax." + error;
                    }
                    if (message.isPriorAuthRequired != null && message.hasOwnProperty("isPriorAuthRequired")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isPriorAuthRequired);
                        if (error)
                            return "isPriorAuthRequired." + error;
                    }
                    if (message.isPriorAuthOnFile != null && message.hasOwnProperty("isPriorAuthOnFile")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isPriorAuthOnFile);
                        if (error)
                            return "isPriorAuthOnFile." + error;
                    }
                    if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus")) {
                        var error = $root.infinitusai.be.PriorAuthStatusType.verify(message.priorAuthStatus);
                        if (error)
                            return "priorAuthStatus." + error;
                    }
                    if (message.priorAuthApprovalStartDate != null && message.hasOwnProperty("priorAuthApprovalStartDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.priorAuthApprovalStartDate);
                        if (error)
                            return "priorAuthApprovalStartDate." + error;
                    }
                    if (message.priorAuthApprovalEndDate != null && message.hasOwnProperty("priorAuthApprovalEndDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.priorAuthApprovalEndDate);
                        if (error)
                            return "priorAuthApprovalEndDate." + error;
                    }
                    if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthApprovalNumber);
                        if (error)
                            return "priorAuthApprovalNumber." + error;
                    }
                    if (message.priorAuthTurnaroundNotes != null && message.hasOwnProperty("priorAuthTurnaroundNotes")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthTurnaroundNotes);
                        if (error)
                            return "priorAuthTurnaroundNotes." + error;
                    }
                    if (message.priorAuthDenialReason != null && message.hasOwnProperty("priorAuthDenialReason")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthDenialReason);
                        if (error)
                            return "priorAuthDenialReason." + error;
                    }
                    if (message.priorAuthDenialDate != null && message.hasOwnProperty("priorAuthDenialDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.priorAuthDenialDate);
                        if (error)
                            return "priorAuthDenialDate." + error;
                    }
                    if (message.priorAuthSubmissionDate != null && message.hasOwnProperty("priorAuthSubmissionDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.priorAuthSubmissionDate);
                        if (error)
                            return "priorAuthSubmissionDate." + error;
                    }
                    if (message.priorAuthMethodEntity != null && message.hasOwnProperty("priorAuthMethodEntity")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthMethodEntity);
                        if (error)
                            return "priorAuthMethodEntity." + error;
                    }
                    if (message.priorAuthPhysicianNotificationMethod != null && message.hasOwnProperty("priorAuthPhysicianNotificationMethod")) {
                        var error = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.verify(message.priorAuthPhysicianNotificationMethod);
                        if (error)
                            return "priorAuthPhysicianNotificationMethod." + error;
                    }
                    if (message.priorAuthMethods != null && message.hasOwnProperty("priorAuthMethods")) {
                        if (!$util.isObject(message.priorAuthMethods))
                            return "priorAuthMethods: object expected";
                        var key = Object.keys(message.priorAuthMethods);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key32Re.test(key[i]))
                                return "priorAuthMethods: integer key{k:int32} expected";
                            if (!$util.isString(message.priorAuthMethods[key[i]]))
                                return "priorAuthMethods: string{k:int32} expected";
                        }
                    }
                    if (message.priorAuthMethodsV2 != null && message.hasOwnProperty("priorAuthMethodsV2")) {
                        var error = $root.infinitusai.be.PriorAuthMethod.verify(message.priorAuthMethodsV2);
                        if (error)
                            return "priorAuthMethodsV2." + error;
                    }
                    if (message.priorAuthRequirements != null && message.hasOwnProperty("priorAuthRequirements")) {
                        var error = $root.infinitusai.be.MultiSelectPriorAuthRequirementType.verify(message.priorAuthRequirements);
                        if (error)
                            return "priorAuthRequirements." + error;
                    }
                    if (message.isStepTherapyRequired != null && message.hasOwnProperty("isStepTherapyRequired")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isStepTherapyRequired);
                        if (error)
                            return "isStepTherapyRequired." + error;
                    }
                    if (message.stepTherapyMethod != null && message.hasOwnProperty("stepTherapyMethod")) {
                        var error = $root.infinitusai.be.StepTherapyMethod.verify(message.stepTherapyMethod);
                        if (error)
                            return "stepTherapyMethod." + error;
                    }
                    if (message.stepTherapyTreatment != null && message.hasOwnProperty("stepTherapyTreatment")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.stepTherapyTreatment);
                        if (error)
                            return "stepTherapyTreatment." + error;
                    }
                    if (message.priorAuthMethodType != null && message.hasOwnProperty("priorAuthMethodType")) {
                        var error = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.verify(message.priorAuthMethodType);
                        if (error)
                            return "priorAuthMethodType." + error;
                    }
                    if (message.priorAuthMethodContact != null && message.hasOwnProperty("priorAuthMethodContact")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.priorAuthMethodContact);
                        if (error)
                            return "priorAuthMethodContact." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PBMBVReferralCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PBMBVReferralCallOutputs} PBMBVReferralCallOutputs
                 */
                PBMBVReferralCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PBMBVReferralCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.PBMBVReferralCallOutputs();
                    if (object.specialtyPharmacyAvailable != null) {
                        if (typeof object.specialtyPharmacyAvailable !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.specialtyPharmacyAvailable: object expected");
                        message.specialtyPharmacyAvailable = $root.infinitusai.be.NullableBool.fromObject(object.specialtyPharmacyAvailable);
                    }
                    if (object.specialtyPharmacy1Name != null) {
                        if (typeof object.specialtyPharmacy1Name !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.specialtyPharmacy1Name: object expected");
                        message.specialtyPharmacy1Name = $root.infinitusai.be.NullableString.fromObject(object.specialtyPharmacy1Name);
                    }
                    if (object.specialtyPharmacy1Phone != null) {
                        if (typeof object.specialtyPharmacy1Phone !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.specialtyPharmacy1Phone: object expected");
                        message.specialtyPharmacy1Phone = $root.infinitusai.be.NullableString.fromObject(object.specialtyPharmacy1Phone);
                    }
                    if (object.specialtyPharmacy1Fax != null) {
                        if (typeof object.specialtyPharmacy1Fax !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.specialtyPharmacy1Fax: object expected");
                        message.specialtyPharmacy1Fax = $root.infinitusai.be.NullableString.fromObject(object.specialtyPharmacy1Fax);
                    }
                    if (object.isPriorAuthRequired != null) {
                        if (typeof object.isPriorAuthRequired !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.isPriorAuthRequired: object expected");
                        message.isPriorAuthRequired = $root.infinitusai.be.NullableBool.fromObject(object.isPriorAuthRequired);
                    }
                    if (object.isPriorAuthOnFile != null) {
                        if (typeof object.isPriorAuthOnFile !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.isPriorAuthOnFile: object expected");
                        message.isPriorAuthOnFile = $root.infinitusai.be.NullableBool.fromObject(object.isPriorAuthOnFile);
                    }
                    if (object.priorAuthStatus != null) {
                        if (typeof object.priorAuthStatus !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthStatus: object expected");
                        message.priorAuthStatus = $root.infinitusai.be.PriorAuthStatusType.fromObject(object.priorAuthStatus);
                    }
                    if (object.priorAuthApprovalStartDate != null) {
                        if (typeof object.priorAuthApprovalStartDate !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthApprovalStartDate: object expected");
                        message.priorAuthApprovalStartDate = $root.infinitusai.be.Date.fromObject(object.priorAuthApprovalStartDate);
                    }
                    if (object.priorAuthApprovalEndDate != null) {
                        if (typeof object.priorAuthApprovalEndDate !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthApprovalEndDate: object expected");
                        message.priorAuthApprovalEndDate = $root.infinitusai.be.Date.fromObject(object.priorAuthApprovalEndDate);
                    }
                    if (object.priorAuthApprovalNumber != null) {
                        if (typeof object.priorAuthApprovalNumber !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthApprovalNumber: object expected");
                        message.priorAuthApprovalNumber = $root.infinitusai.be.NullableString.fromObject(object.priorAuthApprovalNumber);
                    }
                    if (object.priorAuthTurnaroundNotes != null) {
                        if (typeof object.priorAuthTurnaroundNotes !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthTurnaroundNotes: object expected");
                        message.priorAuthTurnaroundNotes = $root.infinitusai.be.NullableString.fromObject(object.priorAuthTurnaroundNotes);
                    }
                    if (object.priorAuthDenialReason != null) {
                        if (typeof object.priorAuthDenialReason !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthDenialReason: object expected");
                        message.priorAuthDenialReason = $root.infinitusai.be.NullableString.fromObject(object.priorAuthDenialReason);
                    }
                    if (object.priorAuthDenialDate != null) {
                        if (typeof object.priorAuthDenialDate !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthDenialDate: object expected");
                        message.priorAuthDenialDate = $root.infinitusai.be.Date.fromObject(object.priorAuthDenialDate);
                    }
                    if (object.priorAuthSubmissionDate != null) {
                        if (typeof object.priorAuthSubmissionDate !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthSubmissionDate: object expected");
                        message.priorAuthSubmissionDate = $root.infinitusai.be.Date.fromObject(object.priorAuthSubmissionDate);
                    }
                    if (object.priorAuthMethodEntity != null) {
                        if (typeof object.priorAuthMethodEntity !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthMethodEntity: object expected");
                        message.priorAuthMethodEntity = $root.infinitusai.be.NullableString.fromObject(object.priorAuthMethodEntity);
                    }
                    if (object.priorAuthPhysicianNotificationMethod != null) {
                        if (typeof object.priorAuthPhysicianNotificationMethod !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthPhysicianNotificationMethod: object expected");
                        message.priorAuthPhysicianNotificationMethod = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.fromObject(object.priorAuthPhysicianNotificationMethod);
                    }
                    if (object.priorAuthMethods) {
                        if (typeof object.priorAuthMethods !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthMethods: object expected");
                        message.priorAuthMethods = {};
                        for (var keys = Object.keys(object.priorAuthMethods), i = 0; i < keys.length; ++i)
                            message.priorAuthMethods[keys[i]] = String(object.priorAuthMethods[keys[i]]);
                    }
                    if (object.priorAuthMethodsV2 != null) {
                        if (typeof object.priorAuthMethodsV2 !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthMethodsV2: object expected");
                        message.priorAuthMethodsV2 = $root.infinitusai.be.PriorAuthMethod.fromObject(object.priorAuthMethodsV2);
                    }
                    if (object.priorAuthRequirements != null) {
                        if (typeof object.priorAuthRequirements !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthRequirements: object expected");
                        message.priorAuthRequirements = $root.infinitusai.be.MultiSelectPriorAuthRequirementType.fromObject(object.priorAuthRequirements);
                    }
                    if (object.isStepTherapyRequired != null) {
                        if (typeof object.isStepTherapyRequired !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.isStepTherapyRequired: object expected");
                        message.isStepTherapyRequired = $root.infinitusai.be.NullableBool.fromObject(object.isStepTherapyRequired);
                    }
                    if (object.stepTherapyMethod != null) {
                        if (typeof object.stepTherapyMethod !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.stepTherapyMethod: object expected");
                        message.stepTherapyMethod = $root.infinitusai.be.StepTherapyMethod.fromObject(object.stepTherapyMethod);
                    }
                    if (object.stepTherapyTreatment != null) {
                        if (typeof object.stepTherapyTreatment !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.stepTherapyTreatment: object expected");
                        message.stepTherapyTreatment = $root.infinitusai.be.NullableString.fromObject(object.stepTherapyTreatment);
                    }
                    if (object.priorAuthMethodType != null) {
                        if (typeof object.priorAuthMethodType !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthMethodType: object expected");
                        message.priorAuthMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.fromObject(object.priorAuthMethodType);
                    }
                    if (object.priorAuthMethodContact != null) {
                        if (typeof object.priorAuthMethodContact !== "object")
                            throw TypeError(".infinitusai.be.PBMBVReferralCallOutputs.priorAuthMethodContact: object expected");
                        message.priorAuthMethodContact = $root.infinitusai.be.NullableString.fromObject(object.priorAuthMethodContact);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PBMBVReferralCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {infinitusai.be.PBMBVReferralCallOutputs} message PBMBVReferralCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PBMBVReferralCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.priorAuthMethods = {};
                    if (options.defaults) {
                        object.specialtyPharmacyAvailable = null;
                        object.specialtyPharmacy1Name = null;
                        object.specialtyPharmacy1Phone = null;
                        object.specialtyPharmacy1Fax = null;
                        object.isPriorAuthRequired = null;
                        object.isPriorAuthOnFile = null;
                        object.priorAuthApprovalStartDate = null;
                        object.priorAuthApprovalEndDate = null;
                        object.priorAuthApprovalNumber = null;
                        object.priorAuthTurnaroundNotes = null;
                        object.priorAuthMethodType = null;
                        object.priorAuthMethodEntity = null;
                        object.priorAuthMethodContact = null;
                        object.priorAuthPhysicianNotificationMethod = null;
                        object.isStepTherapyRequired = null;
                        object.stepTherapyMethod = null;
                        object.stepTherapyTreatment = null;
                        object.priorAuthRequirements = null;
                        object.priorAuthMethodsV2 = null;
                        object.priorAuthStatus = null;
                        object.priorAuthDenialReason = null;
                        object.priorAuthDenialDate = null;
                        object.priorAuthSubmissionDate = null;
                    }
                    if (message.specialtyPharmacyAvailable != null && message.hasOwnProperty("specialtyPharmacyAvailable"))
                        object.specialtyPharmacyAvailable = $root.infinitusai.be.NullableBool.toObject(message.specialtyPharmacyAvailable, options);
                    if (message.specialtyPharmacy1Name != null && message.hasOwnProperty("specialtyPharmacy1Name"))
                        object.specialtyPharmacy1Name = $root.infinitusai.be.NullableString.toObject(message.specialtyPharmacy1Name, options);
                    if (message.specialtyPharmacy1Phone != null && message.hasOwnProperty("specialtyPharmacy1Phone"))
                        object.specialtyPharmacy1Phone = $root.infinitusai.be.NullableString.toObject(message.specialtyPharmacy1Phone, options);
                    if (message.specialtyPharmacy1Fax != null && message.hasOwnProperty("specialtyPharmacy1Fax"))
                        object.specialtyPharmacy1Fax = $root.infinitusai.be.NullableString.toObject(message.specialtyPharmacy1Fax, options);
                    if (message.isPriorAuthRequired != null && message.hasOwnProperty("isPriorAuthRequired"))
                        object.isPriorAuthRequired = $root.infinitusai.be.NullableBool.toObject(message.isPriorAuthRequired, options);
                    if (message.isPriorAuthOnFile != null && message.hasOwnProperty("isPriorAuthOnFile"))
                        object.isPriorAuthOnFile = $root.infinitusai.be.NullableBool.toObject(message.isPriorAuthOnFile, options);
                    if (message.priorAuthApprovalStartDate != null && message.hasOwnProperty("priorAuthApprovalStartDate"))
                        object.priorAuthApprovalStartDate = $root.infinitusai.be.Date.toObject(message.priorAuthApprovalStartDate, options);
                    if (message.priorAuthApprovalEndDate != null && message.hasOwnProperty("priorAuthApprovalEndDate"))
                        object.priorAuthApprovalEndDate = $root.infinitusai.be.Date.toObject(message.priorAuthApprovalEndDate, options);
                    if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber"))
                        object.priorAuthApprovalNumber = $root.infinitusai.be.NullableString.toObject(message.priorAuthApprovalNumber, options);
                    if (message.priorAuthTurnaroundNotes != null && message.hasOwnProperty("priorAuthTurnaroundNotes"))
                        object.priorAuthTurnaroundNotes = $root.infinitusai.be.NullableString.toObject(message.priorAuthTurnaroundNotes, options);
                    if (message.priorAuthMethodType != null && message.hasOwnProperty("priorAuthMethodType"))
                        object.priorAuthMethodType = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.toObject(message.priorAuthMethodType, options);
                    if (message.priorAuthMethodEntity != null && message.hasOwnProperty("priorAuthMethodEntity"))
                        object.priorAuthMethodEntity = $root.infinitusai.be.NullableString.toObject(message.priorAuthMethodEntity, options);
                    if (message.priorAuthMethodContact != null && message.hasOwnProperty("priorAuthMethodContact"))
                        object.priorAuthMethodContact = $root.infinitusai.be.NullableString.toObject(message.priorAuthMethodContact, options);
                    if (message.priorAuthPhysicianNotificationMethod != null && message.hasOwnProperty("priorAuthPhysicianNotificationMethod"))
                        object.priorAuthPhysicianNotificationMethod = $root.infinitusai.be.PriorAuthAndPredeterminationMethod.toObject(message.priorAuthPhysicianNotificationMethod, options);
                    if (message.isStepTherapyRequired != null && message.hasOwnProperty("isStepTherapyRequired"))
                        object.isStepTherapyRequired = $root.infinitusai.be.NullableBool.toObject(message.isStepTherapyRequired, options);
                    if (message.stepTherapyMethod != null && message.hasOwnProperty("stepTherapyMethod"))
                        object.stepTherapyMethod = $root.infinitusai.be.StepTherapyMethod.toObject(message.stepTherapyMethod, options);
                    if (message.stepTherapyTreatment != null && message.hasOwnProperty("stepTherapyTreatment"))
                        object.stepTherapyTreatment = $root.infinitusai.be.NullableString.toObject(message.stepTherapyTreatment, options);
                    var keys2;
                    if (message.priorAuthMethods && (keys2 = Object.keys(message.priorAuthMethods)).length) {
                        object.priorAuthMethods = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.priorAuthMethods[keys2[j]] = message.priorAuthMethods[keys2[j]];
                    }
                    if (message.priorAuthRequirements != null && message.hasOwnProperty("priorAuthRequirements"))
                        object.priorAuthRequirements = $root.infinitusai.be.MultiSelectPriorAuthRequirementType.toObject(message.priorAuthRequirements, options);
                    if (message.priorAuthMethodsV2 != null && message.hasOwnProperty("priorAuthMethodsV2"))
                        object.priorAuthMethodsV2 = $root.infinitusai.be.PriorAuthMethod.toObject(message.priorAuthMethodsV2, options);
                    if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                        object.priorAuthStatus = $root.infinitusai.be.PriorAuthStatusType.toObject(message.priorAuthStatus, options);
                    if (message.priorAuthDenialReason != null && message.hasOwnProperty("priorAuthDenialReason"))
                        object.priorAuthDenialReason = $root.infinitusai.be.NullableString.toObject(message.priorAuthDenialReason, options);
                    if (message.priorAuthDenialDate != null && message.hasOwnProperty("priorAuthDenialDate"))
                        object.priorAuthDenialDate = $root.infinitusai.be.Date.toObject(message.priorAuthDenialDate, options);
                    if (message.priorAuthSubmissionDate != null && message.hasOwnProperty("priorAuthSubmissionDate"))
                        object.priorAuthSubmissionDate = $root.infinitusai.be.Date.toObject(message.priorAuthSubmissionDate, options);
                    return object;
                };
    
                /**
                 * Converts this PBMBVReferralCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PBMBVReferralCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PBMBVReferralCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PBMBVReferralCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PBMBVReferralCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PBMBVReferralCallOutputs";
                };
    
                return PBMBVReferralCallOutputs;
            })();
    
            be.PBMBVCallOutputs = (function() {
    
                /**
                 * Properties of a PBMBVCallOutputs.
                 * @memberof infinitusai.be
                 * @interface IPBMBVCallOutputs
                 * @property {infinitusai.be.IPBMBVGeneralCallOutputs|null} [general] PBMBVCallOutputs general
                 * @property {Array.<infinitusai.be.IPBMBVProductCallOutputs>|null} [productCodes] PBMBVCallOutputs productCodes
                 * @property {infinitusai.be.IPBMBVReferralCallOutputs|null} [auth] PBMBVCallOutputs auth
                 * @property {Array.<string>|null} [agentCouldNotAnswerFields] PBMBVCallOutputs agentCouldNotAnswerFields
                 */
    
                /**
                 * Constructs a new PBMBVCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PBMBVCallOutputs.
                 * @implements IPBMBVCallOutputs
                 * @constructor
                 * @param {infinitusai.be.IPBMBVCallOutputs=} [properties] Properties to set
                 */
                function PBMBVCallOutputs(properties) {
                    this.productCodes = [];
                    this.agentCouldNotAnswerFields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PBMBVCallOutputs general.
                 * @member {infinitusai.be.IPBMBVGeneralCallOutputs|null|undefined} general
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @instance
                 */
                PBMBVCallOutputs.prototype.general = null;
    
                /**
                 * PBMBVCallOutputs productCodes.
                 * @member {Array.<infinitusai.be.IPBMBVProductCallOutputs>} productCodes
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @instance
                 */
                PBMBVCallOutputs.prototype.productCodes = $util.emptyArray;
    
                /**
                 * PBMBVCallOutputs auth.
                 * @member {infinitusai.be.IPBMBVReferralCallOutputs|null|undefined} auth
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @instance
                 */
                PBMBVCallOutputs.prototype.auth = null;
    
                /**
                 * PBMBVCallOutputs agentCouldNotAnswerFields.
                 * @member {Array.<string>} agentCouldNotAnswerFields
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @instance
                 */
                PBMBVCallOutputs.prototype.agentCouldNotAnswerFields = $util.emptyArray;
    
                /**
                 * Creates a new PBMBVCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.PBMBVCallOutputs} PBMBVCallOutputs instance
                 */
                PBMBVCallOutputs.create = function create(properties) {
                    return new PBMBVCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified PBMBVCallOutputs message. Does not implicitly {@link infinitusai.be.PBMBVCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVCallOutputs} message PBMBVCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMBVCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.general != null && Object.hasOwnProperty.call(message, "general"))
                        $root.infinitusai.be.PBMBVGeneralCallOutputs.encode(message.general, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.productCodes != null && message.productCodes.length)
                        for (var i = 0; i < message.productCodes.length; ++i)
                            $root.infinitusai.be.PBMBVProductCallOutputs.encode(message.productCodes[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                        $root.infinitusai.be.PBMBVReferralCallOutputs.encode(message.auth, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.agentCouldNotAnswerFields != null && message.agentCouldNotAnswerFields.length)
                        for (var i = 0; i < message.agentCouldNotAnswerFields.length; ++i)
                            writer.uint32(/* id 99, wireType 2 =*/794).string(message.agentCouldNotAnswerFields[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified PBMBVCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.PBMBVCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {infinitusai.be.IPBMBVCallOutputs} message PBMBVCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PBMBVCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PBMBVCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PBMBVCallOutputs} PBMBVCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMBVCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PBMBVCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.general = $root.infinitusai.be.PBMBVGeneralCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.productCodes && message.productCodes.length))
                                    message.productCodes = [];
                                message.productCodes.push($root.infinitusai.be.PBMBVProductCallOutputs.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.auth = $root.infinitusai.be.PBMBVReferralCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 99: {
                                if (!(message.agentCouldNotAnswerFields && message.agentCouldNotAnswerFields.length))
                                    message.agentCouldNotAnswerFields = [];
                                message.agentCouldNotAnswerFields.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PBMBVCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PBMBVCallOutputs} PBMBVCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PBMBVCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PBMBVCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PBMBVCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.general != null && message.hasOwnProperty("general")) {
                        var error = $root.infinitusai.be.PBMBVGeneralCallOutputs.verify(message.general);
                        if (error)
                            return "general." + error;
                    }
                    if (message.productCodes != null && message.hasOwnProperty("productCodes")) {
                        if (!Array.isArray(message.productCodes))
                            return "productCodes: array expected";
                        for (var i = 0; i < message.productCodes.length; ++i) {
                            var error = $root.infinitusai.be.PBMBVProductCallOutputs.verify(message.productCodes[i]);
                            if (error)
                                return "productCodes." + error;
                        }
                    }
                    if (message.auth != null && message.hasOwnProperty("auth")) {
                        var error = $root.infinitusai.be.PBMBVReferralCallOutputs.verify(message.auth);
                        if (error)
                            return "auth." + error;
                    }
                    if (message.agentCouldNotAnswerFields != null && message.hasOwnProperty("agentCouldNotAnswerFields")) {
                        if (!Array.isArray(message.agentCouldNotAnswerFields))
                            return "agentCouldNotAnswerFields: array expected";
                        for (var i = 0; i < message.agentCouldNotAnswerFields.length; ++i)
                            if (!$util.isString(message.agentCouldNotAnswerFields[i]))
                                return "agentCouldNotAnswerFields: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a PBMBVCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PBMBVCallOutputs} PBMBVCallOutputs
                 */
                PBMBVCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PBMBVCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.PBMBVCallOutputs();
                    if (object.general != null) {
                        if (typeof object.general !== "object")
                            throw TypeError(".infinitusai.be.PBMBVCallOutputs.general: object expected");
                        message.general = $root.infinitusai.be.PBMBVGeneralCallOutputs.fromObject(object.general);
                    }
                    if (object.productCodes) {
                        if (!Array.isArray(object.productCodes))
                            throw TypeError(".infinitusai.be.PBMBVCallOutputs.productCodes: array expected");
                        message.productCodes = [];
                        for (var i = 0; i < object.productCodes.length; ++i) {
                            if (typeof object.productCodes[i] !== "object")
                                throw TypeError(".infinitusai.be.PBMBVCallOutputs.productCodes: object expected");
                            message.productCodes[i] = $root.infinitusai.be.PBMBVProductCallOutputs.fromObject(object.productCodes[i]);
                        }
                    }
                    if (object.auth != null) {
                        if (typeof object.auth !== "object")
                            throw TypeError(".infinitusai.be.PBMBVCallOutputs.auth: object expected");
                        message.auth = $root.infinitusai.be.PBMBVReferralCallOutputs.fromObject(object.auth);
                    }
                    if (object.agentCouldNotAnswerFields) {
                        if (!Array.isArray(object.agentCouldNotAnswerFields))
                            throw TypeError(".infinitusai.be.PBMBVCallOutputs.agentCouldNotAnswerFields: array expected");
                        message.agentCouldNotAnswerFields = [];
                        for (var i = 0; i < object.agentCouldNotAnswerFields.length; ++i)
                            message.agentCouldNotAnswerFields[i] = String(object.agentCouldNotAnswerFields[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PBMBVCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {infinitusai.be.PBMBVCallOutputs} message PBMBVCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PBMBVCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.productCodes = [];
                        object.agentCouldNotAnswerFields = [];
                    }
                    if (options.defaults) {
                        object.general = null;
                        object.auth = null;
                    }
                    if (message.general != null && message.hasOwnProperty("general"))
                        object.general = $root.infinitusai.be.PBMBVGeneralCallOutputs.toObject(message.general, options);
                    if (message.productCodes && message.productCodes.length) {
                        object.productCodes = [];
                        for (var j = 0; j < message.productCodes.length; ++j)
                            object.productCodes[j] = $root.infinitusai.be.PBMBVProductCallOutputs.toObject(message.productCodes[j], options);
                    }
                    if (message.auth != null && message.hasOwnProperty("auth"))
                        object.auth = $root.infinitusai.be.PBMBVReferralCallOutputs.toObject(message.auth, options);
                    if (message.agentCouldNotAnswerFields && message.agentCouldNotAnswerFields.length) {
                        object.agentCouldNotAnswerFields = [];
                        for (var j = 0; j < message.agentCouldNotAnswerFields.length; ++j)
                            object.agentCouldNotAnswerFields[j] = message.agentCouldNotAnswerFields[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this PBMBVCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PBMBVCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PBMBVCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PBMBVCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PBMBVCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PBMBVCallOutputs";
                };
    
                return PBMBVCallOutputs;
            })();
    
            /**
             * WebhookDeliveryAttemptStatus enum.
             * @name infinitusai.be.WebhookDeliveryAttemptStatus
             * @enum {number}
             * @property {number} WEBHOOK_DELIVERY_ATTEMPT_STATUS_UNKNOWN=0 WEBHOOK_DELIVERY_ATTEMPT_STATUS_UNKNOWN value
             * @property {number} WEBHOOK_DELIVERY_ATTEMPT_STATUS_SUCCESS=1 WEBHOOK_DELIVERY_ATTEMPT_STATUS_SUCCESS value
             * @property {number} WEBHOOK_DELIVERY_ATTEMPT_STATUS_FAILED=2 WEBHOOK_DELIVERY_ATTEMPT_STATUS_FAILED value
             */
            be.WebhookDeliveryAttemptStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WEBHOOK_DELIVERY_ATTEMPT_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "WEBHOOK_DELIVERY_ATTEMPT_STATUS_SUCCESS"] = 1;
                values[valuesById[2] = "WEBHOOK_DELIVERY_ATTEMPT_STATUS_FAILED"] = 2;
                return values;
            })();
    
            /**
             * WebhookDeliveryStatus enum.
             * @name infinitusai.be.WebhookDeliveryStatus
             * @enum {number}
             * @property {number} WEBHOOK_DELIVERY_STATUS_UNKNOWN=0 WEBHOOK_DELIVERY_STATUS_UNKNOWN value
             * @property {number} WEBHOOK_DELIVERY_STATUS_SUCCESS=1 WEBHOOK_DELIVERY_STATUS_SUCCESS value
             * @property {number} WEBHOOK_DELIVERY_STATUS_PENDING_RETRY=2 WEBHOOK_DELIVERY_STATUS_PENDING_RETRY value
             * @property {number} WEBHOOK_DELIVERY_STATUS_FAILED=3 WEBHOOK_DELIVERY_STATUS_FAILED value
             */
            be.WebhookDeliveryStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WEBHOOK_DELIVERY_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "WEBHOOK_DELIVERY_STATUS_SUCCESS"] = 1;
                values[valuesById[2] = "WEBHOOK_DELIVERY_STATUS_PENDING_RETRY"] = 2;
                values[valuesById[3] = "WEBHOOK_DELIVERY_STATUS_FAILED"] = 3;
                return values;
            })();
    
            be.WebhookDeliveryAttempt = (function() {
    
                /**
                 * Properties of a WebhookDeliveryAttempt.
                 * @memberof infinitusai.be
                 * @interface IWebhookDeliveryAttempt
                 * @property {infinitusai.be.WebhookDeliveryAttemptStatus|null} [status] WebhookDeliveryAttempt status
                 * @property {number|Long|null} [deliveryAttemptedAtMillis] WebhookDeliveryAttempt deliveryAttemptedAtMillis
                 * @property {number|Long|null} [endpointResponseTimeMillis] WebhookDeliveryAttempt endpointResponseTimeMillis
                 * @property {string|null} [errorMessage] WebhookDeliveryAttempt errorMessage
                 */
    
                /**
                 * Constructs a new WebhookDeliveryAttempt.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebhookDeliveryAttempt.
                 * @implements IWebhookDeliveryAttempt
                 * @constructor
                 * @param {infinitusai.be.IWebhookDeliveryAttempt=} [properties] Properties to set
                 */
                function WebhookDeliveryAttempt(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebhookDeliveryAttempt status.
                 * @member {infinitusai.be.WebhookDeliveryAttemptStatus} status
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @instance
                 */
                WebhookDeliveryAttempt.prototype.status = 0;
    
                /**
                 * WebhookDeliveryAttempt deliveryAttemptedAtMillis.
                 * @member {number|Long} deliveryAttemptedAtMillis
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @instance
                 */
                WebhookDeliveryAttempt.prototype.deliveryAttemptedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * WebhookDeliveryAttempt endpointResponseTimeMillis.
                 * @member {number|Long} endpointResponseTimeMillis
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @instance
                 */
                WebhookDeliveryAttempt.prototype.endpointResponseTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * WebhookDeliveryAttempt errorMessage.
                 * @member {string} errorMessage
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @instance
                 */
                WebhookDeliveryAttempt.prototype.errorMessage = "";
    
                /**
                 * Creates a new WebhookDeliveryAttempt instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {infinitusai.be.IWebhookDeliveryAttempt=} [properties] Properties to set
                 * @returns {infinitusai.be.WebhookDeliveryAttempt} WebhookDeliveryAttempt instance
                 */
                WebhookDeliveryAttempt.create = function create(properties) {
                    return new WebhookDeliveryAttempt(properties);
                };
    
                /**
                 * Encodes the specified WebhookDeliveryAttempt message. Does not implicitly {@link infinitusai.be.WebhookDeliveryAttempt.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {infinitusai.be.IWebhookDeliveryAttempt} message WebhookDeliveryAttempt message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookDeliveryAttempt.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.deliveryAttemptedAtMillis != null && Object.hasOwnProperty.call(message, "deliveryAttemptedAtMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.deliveryAttemptedAtMillis);
                    if (message.endpointResponseTimeMillis != null && Object.hasOwnProperty.call(message, "endpointResponseTimeMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endpointResponseTimeMillis);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.errorMessage);
                    return writer;
                };
    
                /**
                 * Encodes the specified WebhookDeliveryAttempt message, length delimited. Does not implicitly {@link infinitusai.be.WebhookDeliveryAttempt.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {infinitusai.be.IWebhookDeliveryAttempt} message WebhookDeliveryAttempt message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookDeliveryAttempt.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebhookDeliveryAttempt message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebhookDeliveryAttempt} WebhookDeliveryAttempt
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookDeliveryAttempt.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebhookDeliveryAttempt();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                message.deliveryAttemptedAtMillis = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endpointResponseTimeMillis = reader.int64();
                                break;
                            }
                        case 4: {
                                message.errorMessage = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebhookDeliveryAttempt message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebhookDeliveryAttempt} WebhookDeliveryAttempt
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookDeliveryAttempt.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebhookDeliveryAttempt message.
                 * @function verify
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebhookDeliveryAttempt.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.deliveryAttemptedAtMillis != null && message.hasOwnProperty("deliveryAttemptedAtMillis"))
                        if (!$util.isInteger(message.deliveryAttemptedAtMillis) && !(message.deliveryAttemptedAtMillis && $util.isInteger(message.deliveryAttemptedAtMillis.low) && $util.isInteger(message.deliveryAttemptedAtMillis.high)))
                            return "deliveryAttemptedAtMillis: integer|Long expected";
                    if (message.endpointResponseTimeMillis != null && message.hasOwnProperty("endpointResponseTimeMillis"))
                        if (!$util.isInteger(message.endpointResponseTimeMillis) && !(message.endpointResponseTimeMillis && $util.isInteger(message.endpointResponseTimeMillis.low) && $util.isInteger(message.endpointResponseTimeMillis.high)))
                            return "endpointResponseTimeMillis: integer|Long expected";
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    return null;
                };
    
                /**
                 * Creates a WebhookDeliveryAttempt message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebhookDeliveryAttempt} WebhookDeliveryAttempt
                 */
                WebhookDeliveryAttempt.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebhookDeliveryAttempt)
                        return object;
                    var message = new $root.infinitusai.be.WebhookDeliveryAttempt();
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "WEBHOOK_DELIVERY_ATTEMPT_STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "WEBHOOK_DELIVERY_ATTEMPT_STATUS_SUCCESS":
                    case 1:
                        message.status = 1;
                        break;
                    case "WEBHOOK_DELIVERY_ATTEMPT_STATUS_FAILED":
                    case 2:
                        message.status = 2;
                        break;
                    }
                    if (object.deliveryAttemptedAtMillis != null)
                        if ($util.Long)
                            (message.deliveryAttemptedAtMillis = $util.Long.fromValue(object.deliveryAttemptedAtMillis)).unsigned = false;
                        else if (typeof object.deliveryAttemptedAtMillis === "string")
                            message.deliveryAttemptedAtMillis = parseInt(object.deliveryAttemptedAtMillis, 10);
                        else if (typeof object.deliveryAttemptedAtMillis === "number")
                            message.deliveryAttemptedAtMillis = object.deliveryAttemptedAtMillis;
                        else if (typeof object.deliveryAttemptedAtMillis === "object")
                            message.deliveryAttemptedAtMillis = new $util.LongBits(object.deliveryAttemptedAtMillis.low >>> 0, object.deliveryAttemptedAtMillis.high >>> 0).toNumber();
                    if (object.endpointResponseTimeMillis != null)
                        if ($util.Long)
                            (message.endpointResponseTimeMillis = $util.Long.fromValue(object.endpointResponseTimeMillis)).unsigned = false;
                        else if (typeof object.endpointResponseTimeMillis === "string")
                            message.endpointResponseTimeMillis = parseInt(object.endpointResponseTimeMillis, 10);
                        else if (typeof object.endpointResponseTimeMillis === "number")
                            message.endpointResponseTimeMillis = object.endpointResponseTimeMillis;
                        else if (typeof object.endpointResponseTimeMillis === "object")
                            message.endpointResponseTimeMillis = new $util.LongBits(object.endpointResponseTimeMillis.low >>> 0, object.endpointResponseTimeMillis.high >>> 0).toNumber();
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebhookDeliveryAttempt message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {infinitusai.be.WebhookDeliveryAttempt} message WebhookDeliveryAttempt
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebhookDeliveryAttempt.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.status = options.enums === String ? "WEBHOOK_DELIVERY_ATTEMPT_STATUS_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.deliveryAttemptedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deliveryAttemptedAtMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endpointResponseTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endpointResponseTimeMillis = options.longs === String ? "0" : 0;
                        object.errorMessage = "";
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.WebhookDeliveryAttemptStatus[message.status] === undefined ? message.status : $root.infinitusai.be.WebhookDeliveryAttemptStatus[message.status] : message.status;
                    if (message.deliveryAttemptedAtMillis != null && message.hasOwnProperty("deliveryAttemptedAtMillis"))
                        if (typeof message.deliveryAttemptedAtMillis === "number")
                            object.deliveryAttemptedAtMillis = options.longs === String ? String(message.deliveryAttemptedAtMillis) : message.deliveryAttemptedAtMillis;
                        else
                            object.deliveryAttemptedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.deliveryAttemptedAtMillis) : options.longs === Number ? new $util.LongBits(message.deliveryAttemptedAtMillis.low >>> 0, message.deliveryAttemptedAtMillis.high >>> 0).toNumber() : message.deliveryAttemptedAtMillis;
                    if (message.endpointResponseTimeMillis != null && message.hasOwnProperty("endpointResponseTimeMillis"))
                        if (typeof message.endpointResponseTimeMillis === "number")
                            object.endpointResponseTimeMillis = options.longs === String ? String(message.endpointResponseTimeMillis) : message.endpointResponseTimeMillis;
                        else
                            object.endpointResponseTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endpointResponseTimeMillis) : options.longs === Number ? new $util.LongBits(message.endpointResponseTimeMillis.low >>> 0, message.endpointResponseTimeMillis.high >>> 0).toNumber() : message.endpointResponseTimeMillis;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    return object;
                };
    
                /**
                 * Converts this WebhookDeliveryAttempt to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebhookDeliveryAttempt.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebhookDeliveryAttempt
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebhookDeliveryAttempt
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebhookDeliveryAttempt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebhookDeliveryAttempt";
                };
    
                return WebhookDeliveryAttempt;
            })();
    
            be.TaskWebhook = (function() {
    
                /**
                 * Properties of a TaskWebhook.
                 * @memberof infinitusai.be
                 * @interface ITaskWebhook
                 * @property {string|null} [url] TaskWebhook url
                 * @property {infinitusai.be.IWebhookAuth|null} [auth] TaskWebhook auth
                 * @property {Array.<infinitusai.be.IWebhookDeliveryAttempt>|null} [deliveryAttempts] TaskWebhook deliveryAttempts
                 * @property {infinitusai.be.WebhookDeliveryStatus|null} [status] TaskWebhook status
                 */
    
                /**
                 * Constructs a new TaskWebhook.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TaskWebhook.
                 * @implements ITaskWebhook
                 * @constructor
                 * @param {infinitusai.be.ITaskWebhook=} [properties] Properties to set
                 */
                function TaskWebhook(properties) {
                    this.deliveryAttempts = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskWebhook url.
                 * @member {string} url
                 * @memberof infinitusai.be.TaskWebhook
                 * @instance
                 */
                TaskWebhook.prototype.url = "";
    
                /**
                 * TaskWebhook auth.
                 * @member {infinitusai.be.IWebhookAuth|null|undefined} auth
                 * @memberof infinitusai.be.TaskWebhook
                 * @instance
                 */
                TaskWebhook.prototype.auth = null;
    
                /**
                 * TaskWebhook deliveryAttempts.
                 * @member {Array.<infinitusai.be.IWebhookDeliveryAttempt>} deliveryAttempts
                 * @memberof infinitusai.be.TaskWebhook
                 * @instance
                 */
                TaskWebhook.prototype.deliveryAttempts = $util.emptyArray;
    
                /**
                 * TaskWebhook status.
                 * @member {infinitusai.be.WebhookDeliveryStatus} status
                 * @memberof infinitusai.be.TaskWebhook
                 * @instance
                 */
                TaskWebhook.prototype.status = 0;
    
                /**
                 * Creates a new TaskWebhook instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {infinitusai.be.ITaskWebhook=} [properties] Properties to set
                 * @returns {infinitusai.be.TaskWebhook} TaskWebhook instance
                 */
                TaskWebhook.create = function create(properties) {
                    return new TaskWebhook(properties);
                };
    
                /**
                 * Encodes the specified TaskWebhook message. Does not implicitly {@link infinitusai.be.TaskWebhook.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {infinitusai.be.ITaskWebhook} message TaskWebhook message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskWebhook.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                    if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                        $root.infinitusai.be.WebhookAuth.encode(message.auth, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.deliveryAttempts != null && message.deliveryAttempts.length)
                        for (var i = 0; i < message.deliveryAttempts.length; ++i)
                            $root.infinitusai.be.WebhookDeliveryAttempt.encode(message.deliveryAttempts[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskWebhook message, length delimited. Does not implicitly {@link infinitusai.be.TaskWebhook.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {infinitusai.be.ITaskWebhook} message TaskWebhook message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskWebhook.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskWebhook message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TaskWebhook} TaskWebhook
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskWebhook.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskWebhook();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.url = reader.string();
                                break;
                            }
                        case 2: {
                                message.auth = $root.infinitusai.be.WebhookAuth.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.deliveryAttempts && message.deliveryAttempts.length))
                                    message.deliveryAttempts = [];
                                message.deliveryAttempts.push($root.infinitusai.be.WebhookDeliveryAttempt.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.status = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskWebhook message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TaskWebhook} TaskWebhook
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskWebhook.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskWebhook message.
                 * @function verify
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskWebhook.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.auth != null && message.hasOwnProperty("auth")) {
                        var error = $root.infinitusai.be.WebhookAuth.verify(message.auth);
                        if (error)
                            return "auth." + error;
                    }
                    if (message.deliveryAttempts != null && message.hasOwnProperty("deliveryAttempts")) {
                        if (!Array.isArray(message.deliveryAttempts))
                            return "deliveryAttempts: array expected";
                        for (var i = 0; i < message.deliveryAttempts.length; ++i) {
                            var error = $root.infinitusai.be.WebhookDeliveryAttempt.verify(message.deliveryAttempts[i]);
                            if (error)
                                return "deliveryAttempts." + error;
                        }
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a TaskWebhook message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TaskWebhook} TaskWebhook
                 */
                TaskWebhook.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TaskWebhook)
                        return object;
                    var message = new $root.infinitusai.be.TaskWebhook();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.auth != null) {
                        if (typeof object.auth !== "object")
                            throw TypeError(".infinitusai.be.TaskWebhook.auth: object expected");
                        message.auth = $root.infinitusai.be.WebhookAuth.fromObject(object.auth);
                    }
                    if (object.deliveryAttempts) {
                        if (!Array.isArray(object.deliveryAttempts))
                            throw TypeError(".infinitusai.be.TaskWebhook.deliveryAttempts: array expected");
                        message.deliveryAttempts = [];
                        for (var i = 0; i < object.deliveryAttempts.length; ++i) {
                            if (typeof object.deliveryAttempts[i] !== "object")
                                throw TypeError(".infinitusai.be.TaskWebhook.deliveryAttempts: object expected");
                            message.deliveryAttempts[i] = $root.infinitusai.be.WebhookDeliveryAttempt.fromObject(object.deliveryAttempts[i]);
                        }
                    }
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "WEBHOOK_DELIVERY_STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "WEBHOOK_DELIVERY_STATUS_SUCCESS":
                    case 1:
                        message.status = 1;
                        break;
                    case "WEBHOOK_DELIVERY_STATUS_PENDING_RETRY":
                    case 2:
                        message.status = 2;
                        break;
                    case "WEBHOOK_DELIVERY_STATUS_FAILED":
                    case 3:
                        message.status = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskWebhook message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {infinitusai.be.TaskWebhook} message TaskWebhook
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskWebhook.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.deliveryAttempts = [];
                    if (options.defaults) {
                        object.url = "";
                        object.auth = null;
                        object.status = options.enums === String ? "WEBHOOK_DELIVERY_STATUS_UNKNOWN" : 0;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.auth != null && message.hasOwnProperty("auth"))
                        object.auth = $root.infinitusai.be.WebhookAuth.toObject(message.auth, options);
                    if (message.deliveryAttempts && message.deliveryAttempts.length) {
                        object.deliveryAttempts = [];
                        for (var j = 0; j < message.deliveryAttempts.length; ++j)
                            object.deliveryAttempts[j] = $root.infinitusai.be.WebhookDeliveryAttempt.toObject(message.deliveryAttempts[j], options);
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.WebhookDeliveryStatus[message.status] === undefined ? message.status : $root.infinitusai.be.WebhookDeliveryStatus[message.status] : message.status;
                    return object;
                };
    
                /**
                 * Converts this TaskWebhook to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TaskWebhook
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskWebhook.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskWebhook
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TaskWebhook
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskWebhook.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TaskWebhook";
                };
    
                return TaskWebhook;
            })();
    
            be.WebhookAuth = (function() {
    
                /**
                 * Properties of a WebhookAuth.
                 * @memberof infinitusai.be
                 * @interface IWebhookAuth
                 * @property {string|null} [username] WebhookAuth username
                 * @property {string|null} [password] WebhookAuth password
                 */
    
                /**
                 * Constructs a new WebhookAuth.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebhookAuth.
                 * @implements IWebhookAuth
                 * @constructor
                 * @param {infinitusai.be.IWebhookAuth=} [properties] Properties to set
                 */
                function WebhookAuth(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebhookAuth username.
                 * @member {string} username
                 * @memberof infinitusai.be.WebhookAuth
                 * @instance
                 */
                WebhookAuth.prototype.username = "";
    
                /**
                 * WebhookAuth password.
                 * @member {string} password
                 * @memberof infinitusai.be.WebhookAuth
                 * @instance
                 */
                WebhookAuth.prototype.password = "";
    
                /**
                 * Creates a new WebhookAuth instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {infinitusai.be.IWebhookAuth=} [properties] Properties to set
                 * @returns {infinitusai.be.WebhookAuth} WebhookAuth instance
                 */
                WebhookAuth.create = function create(properties) {
                    return new WebhookAuth(properties);
                };
    
                /**
                 * Encodes the specified WebhookAuth message. Does not implicitly {@link infinitusai.be.WebhookAuth.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {infinitusai.be.IWebhookAuth} message WebhookAuth message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookAuth.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
                    if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                    return writer;
                };
    
                /**
                 * Encodes the specified WebhookAuth message, length delimited. Does not implicitly {@link infinitusai.be.WebhookAuth.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {infinitusai.be.IWebhookAuth} message WebhookAuth message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookAuth.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebhookAuth message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebhookAuth} WebhookAuth
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookAuth.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebhookAuth();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.username = reader.string();
                                break;
                            }
                        case 2: {
                                message.password = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebhookAuth message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebhookAuth} WebhookAuth
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookAuth.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebhookAuth message.
                 * @function verify
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebhookAuth.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.username != null && message.hasOwnProperty("username"))
                        if (!$util.isString(message.username))
                            return "username: string expected";
                    if (message.password != null && message.hasOwnProperty("password"))
                        if (!$util.isString(message.password))
                            return "password: string expected";
                    return null;
                };
    
                /**
                 * Creates a WebhookAuth message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebhookAuth} WebhookAuth
                 */
                WebhookAuth.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebhookAuth)
                        return object;
                    var message = new $root.infinitusai.be.WebhookAuth();
                    if (object.username != null)
                        message.username = String(object.username);
                    if (object.password != null)
                        message.password = String(object.password);
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebhookAuth message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {infinitusai.be.WebhookAuth} message WebhookAuth
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebhookAuth.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.username = "";
                        object.password = "";
                    }
                    if (message.username != null && message.hasOwnProperty("username"))
                        object.username = message.username;
                    if (message.password != null && message.hasOwnProperty("password"))
                        object.password = message.password;
                    return object;
                };
    
                /**
                 * Converts this WebhookAuth to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebhookAuth
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebhookAuth.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebhookAuth
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebhookAuth
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebhookAuth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebhookAuth";
                };
    
                return WebhookAuth;
            })();
    
            be.WebhookCustomHeader = (function() {
    
                /**
                 * Properties of a WebhookCustomHeader.
                 * @memberof infinitusai.be
                 * @interface IWebhookCustomHeader
                 * @property {string|null} [key] WebhookCustomHeader key
                 * @property {string|null} [value] WebhookCustomHeader value
                 */
    
                /**
                 * Constructs a new WebhookCustomHeader.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebhookCustomHeader.
                 * @implements IWebhookCustomHeader
                 * @constructor
                 * @param {infinitusai.be.IWebhookCustomHeader=} [properties] Properties to set
                 */
                function WebhookCustomHeader(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebhookCustomHeader key.
                 * @member {string} key
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @instance
                 */
                WebhookCustomHeader.prototype.key = "";
    
                /**
                 * WebhookCustomHeader value.
                 * @member {string} value
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @instance
                 */
                WebhookCustomHeader.prototype.value = "";
    
                /**
                 * Creates a new WebhookCustomHeader instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {infinitusai.be.IWebhookCustomHeader=} [properties] Properties to set
                 * @returns {infinitusai.be.WebhookCustomHeader} WebhookCustomHeader instance
                 */
                WebhookCustomHeader.create = function create(properties) {
                    return new WebhookCustomHeader(properties);
                };
    
                /**
                 * Encodes the specified WebhookCustomHeader message. Does not implicitly {@link infinitusai.be.WebhookCustomHeader.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {infinitusai.be.IWebhookCustomHeader} message WebhookCustomHeader message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookCustomHeader.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified WebhookCustomHeader message, length delimited. Does not implicitly {@link infinitusai.be.WebhookCustomHeader.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {infinitusai.be.IWebhookCustomHeader} message WebhookCustomHeader message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookCustomHeader.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebhookCustomHeader message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebhookCustomHeader} WebhookCustomHeader
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookCustomHeader.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebhookCustomHeader();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.key = reader.string();
                                break;
                            }
                        case 2: {
                                message.value = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebhookCustomHeader message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebhookCustomHeader} WebhookCustomHeader
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookCustomHeader.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebhookCustomHeader message.
                 * @function verify
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebhookCustomHeader.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    return null;
                };
    
                /**
                 * Creates a WebhookCustomHeader message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebhookCustomHeader} WebhookCustomHeader
                 */
                WebhookCustomHeader.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebhookCustomHeader)
                        return object;
                    var message = new $root.infinitusai.be.WebhookCustomHeader();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.value != null)
                        message.value = String(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebhookCustomHeader message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {infinitusai.be.WebhookCustomHeader} message WebhookCustomHeader
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebhookCustomHeader.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.key = "";
                        object.value = "";
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this WebhookCustomHeader to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebhookCustomHeader.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebhookCustomHeader
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebhookCustomHeader
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebhookCustomHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebhookCustomHeader";
                };
    
                return WebhookCustomHeader;
            })();
    
            be.CreateWebhookConfigRequest = (function() {
    
                /**
                 * Properties of a CreateWebhookConfigRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateWebhookConfigRequest
                 * @property {string|null} [url] CreateWebhookConfigRequest url
                 * @property {infinitusai.be.IWebhookAuth|null} [auth] CreateWebhookConfigRequest auth
                 * @property {Array.<infinitusai.be.IWebhookCustomHeader>|null} [headers] CreateWebhookConfigRequest headers
                 */
    
                /**
                 * Constructs a new CreateWebhookConfigRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateWebhookConfigRequest.
                 * @implements ICreateWebhookConfigRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateWebhookConfigRequest=} [properties] Properties to set
                 */
                function CreateWebhookConfigRequest(properties) {
                    this.headers = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateWebhookConfigRequest url.
                 * @member {string} url
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @instance
                 */
                CreateWebhookConfigRequest.prototype.url = "";
    
                /**
                 * CreateWebhookConfigRequest auth.
                 * @member {infinitusai.be.IWebhookAuth|null|undefined} auth
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @instance
                 */
                CreateWebhookConfigRequest.prototype.auth = null;
    
                /**
                 * CreateWebhookConfigRequest headers.
                 * @member {Array.<infinitusai.be.IWebhookCustomHeader>} headers
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @instance
                 */
                CreateWebhookConfigRequest.prototype.headers = $util.emptyArray;
    
                /**
                 * Creates a new CreateWebhookConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {infinitusai.be.ICreateWebhookConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateWebhookConfigRequest} CreateWebhookConfigRequest instance
                 */
                CreateWebhookConfigRequest.create = function create(properties) {
                    return new CreateWebhookConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateWebhookConfigRequest message. Does not implicitly {@link infinitusai.be.CreateWebhookConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {infinitusai.be.ICreateWebhookConfigRequest} message CreateWebhookConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateWebhookConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                    if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                        $root.infinitusai.be.WebhookAuth.encode(message.auth, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.headers != null && message.headers.length)
                        for (var i = 0; i < message.headers.length; ++i)
                            $root.infinitusai.be.WebhookCustomHeader.encode(message.headers[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateWebhookConfigRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateWebhookConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {infinitusai.be.ICreateWebhookConfigRequest} message CreateWebhookConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateWebhookConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateWebhookConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateWebhookConfigRequest} CreateWebhookConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateWebhookConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateWebhookConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.url = reader.string();
                                break;
                            }
                        case 2: {
                                message.auth = $root.infinitusai.be.WebhookAuth.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.headers && message.headers.length))
                                    message.headers = [];
                                message.headers.push($root.infinitusai.be.WebhookCustomHeader.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateWebhookConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateWebhookConfigRequest} CreateWebhookConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateWebhookConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateWebhookConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateWebhookConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.auth != null && message.hasOwnProperty("auth")) {
                        var error = $root.infinitusai.be.WebhookAuth.verify(message.auth);
                        if (error)
                            return "auth." + error;
                    }
                    if (message.headers != null && message.hasOwnProperty("headers")) {
                        if (!Array.isArray(message.headers))
                            return "headers: array expected";
                        for (var i = 0; i < message.headers.length; ++i) {
                            var error = $root.infinitusai.be.WebhookCustomHeader.verify(message.headers[i]);
                            if (error)
                                return "headers." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateWebhookConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateWebhookConfigRequest} CreateWebhookConfigRequest
                 */
                CreateWebhookConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateWebhookConfigRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateWebhookConfigRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.auth != null) {
                        if (typeof object.auth !== "object")
                            throw TypeError(".infinitusai.be.CreateWebhookConfigRequest.auth: object expected");
                        message.auth = $root.infinitusai.be.WebhookAuth.fromObject(object.auth);
                    }
                    if (object.headers) {
                        if (!Array.isArray(object.headers))
                            throw TypeError(".infinitusai.be.CreateWebhookConfigRequest.headers: array expected");
                        message.headers = [];
                        for (var i = 0; i < object.headers.length; ++i) {
                            if (typeof object.headers[i] !== "object")
                                throw TypeError(".infinitusai.be.CreateWebhookConfigRequest.headers: object expected");
                            message.headers[i] = $root.infinitusai.be.WebhookCustomHeader.fromObject(object.headers[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateWebhookConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {infinitusai.be.CreateWebhookConfigRequest} message CreateWebhookConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateWebhookConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.headers = [];
                    if (options.defaults) {
                        object.url = "";
                        object.auth = null;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.auth != null && message.hasOwnProperty("auth"))
                        object.auth = $root.infinitusai.be.WebhookAuth.toObject(message.auth, options);
                    if (message.headers && message.headers.length) {
                        object.headers = [];
                        for (var j = 0; j < message.headers.length; ++j)
                            object.headers[j] = $root.infinitusai.be.WebhookCustomHeader.toObject(message.headers[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this CreateWebhookConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateWebhookConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateWebhookConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateWebhookConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateWebhookConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateWebhookConfigRequest";
                };
    
                return CreateWebhookConfigRequest;
            })();
    
            be.CreateWebhookConfigResponse = (function() {
    
                /**
                 * Properties of a CreateWebhookConfigResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateWebhookConfigResponse
                 * @property {string|null} [secretId] CreateWebhookConfigResponse secretId
                 * @property {string|null} [url] CreateWebhookConfigResponse url
                 * @property {number|Long|null} [createdAtMillis] CreateWebhookConfigResponse createdAtMillis
                 */
    
                /**
                 * Constructs a new CreateWebhookConfigResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateWebhookConfigResponse.
                 * @implements ICreateWebhookConfigResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateWebhookConfigResponse=} [properties] Properties to set
                 */
                function CreateWebhookConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateWebhookConfigResponse secretId.
                 * @member {string} secretId
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @instance
                 */
                CreateWebhookConfigResponse.prototype.secretId = "";
    
                /**
                 * CreateWebhookConfigResponse url.
                 * @member {string} url
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @instance
                 */
                CreateWebhookConfigResponse.prototype.url = "";
    
                /**
                 * CreateWebhookConfigResponse createdAtMillis.
                 * @member {number|Long} createdAtMillis
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @instance
                 */
                CreateWebhookConfigResponse.prototype.createdAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new CreateWebhookConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.ICreateWebhookConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateWebhookConfigResponse} CreateWebhookConfigResponse instance
                 */
                CreateWebhookConfigResponse.create = function create(properties) {
                    return new CreateWebhookConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateWebhookConfigResponse message. Does not implicitly {@link infinitusai.be.CreateWebhookConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.ICreateWebhookConfigResponse} message CreateWebhookConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateWebhookConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.secretId != null && Object.hasOwnProperty.call(message, "secretId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretId);
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
                    if (message.createdAtMillis != null && Object.hasOwnProperty.call(message, "createdAtMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.createdAtMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateWebhookConfigResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateWebhookConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.ICreateWebhookConfigResponse} message CreateWebhookConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateWebhookConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateWebhookConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateWebhookConfigResponse} CreateWebhookConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateWebhookConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateWebhookConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.secretId = reader.string();
                                break;
                            }
                        case 2: {
                                message.url = reader.string();
                                break;
                            }
                        case 3: {
                                message.createdAtMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateWebhookConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateWebhookConfigResponse} CreateWebhookConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateWebhookConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateWebhookConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateWebhookConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.secretId != null && message.hasOwnProperty("secretId"))
                        if (!$util.isString(message.secretId))
                            return "secretId: string expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (!$util.isInteger(message.createdAtMillis) && !(message.createdAtMillis && $util.isInteger(message.createdAtMillis.low) && $util.isInteger(message.createdAtMillis.high)))
                            return "createdAtMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a CreateWebhookConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateWebhookConfigResponse} CreateWebhookConfigResponse
                 */
                CreateWebhookConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateWebhookConfigResponse)
                        return object;
                    var message = new $root.infinitusai.be.CreateWebhookConfigResponse();
                    if (object.secretId != null)
                        message.secretId = String(object.secretId);
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.createdAtMillis != null)
                        if ($util.Long)
                            (message.createdAtMillis = $util.Long.fromValue(object.createdAtMillis)).unsigned = false;
                        else if (typeof object.createdAtMillis === "string")
                            message.createdAtMillis = parseInt(object.createdAtMillis, 10);
                        else if (typeof object.createdAtMillis === "number")
                            message.createdAtMillis = object.createdAtMillis;
                        else if (typeof object.createdAtMillis === "object")
                            message.createdAtMillis = new $util.LongBits(object.createdAtMillis.low >>> 0, object.createdAtMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateWebhookConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.CreateWebhookConfigResponse} message CreateWebhookConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateWebhookConfigResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.secretId = "";
                        object.url = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAtMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.secretId != null && message.hasOwnProperty("secretId"))
                        object.secretId = message.secretId;
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (typeof message.createdAtMillis === "number")
                            object.createdAtMillis = options.longs === String ? String(message.createdAtMillis) : message.createdAtMillis;
                        else
                            object.createdAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtMillis) : options.longs === Number ? new $util.LongBits(message.createdAtMillis.low >>> 0, message.createdAtMillis.high >>> 0).toNumber() : message.createdAtMillis;
                    return object;
                };
    
                /**
                 * Converts this CreateWebhookConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateWebhookConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateWebhookConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateWebhookConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateWebhookConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateWebhookConfigResponse";
                };
    
                return CreateWebhookConfigResponse;
            })();
    
            be.DeleteWebhookConfigRequest = (function() {
    
                /**
                 * Properties of a DeleteWebhookConfigRequest.
                 * @memberof infinitusai.be
                 * @interface IDeleteWebhookConfigRequest
                 * @property {string|null} [secretId] DeleteWebhookConfigRequest secretId
                 */
    
                /**
                 * Constructs a new DeleteWebhookConfigRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteWebhookConfigRequest.
                 * @implements IDeleteWebhookConfigRequest
                 * @constructor
                 * @param {infinitusai.be.IDeleteWebhookConfigRequest=} [properties] Properties to set
                 */
                function DeleteWebhookConfigRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteWebhookConfigRequest secretId.
                 * @member {string} secretId
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @instance
                 */
                DeleteWebhookConfigRequest.prototype.secretId = "";
    
                /**
                 * Creates a new DeleteWebhookConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {infinitusai.be.IDeleteWebhookConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteWebhookConfigRequest} DeleteWebhookConfigRequest instance
                 */
                DeleteWebhookConfigRequest.create = function create(properties) {
                    return new DeleteWebhookConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteWebhookConfigRequest message. Does not implicitly {@link infinitusai.be.DeleteWebhookConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {infinitusai.be.IDeleteWebhookConfigRequest} message DeleteWebhookConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteWebhookConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.secretId != null && Object.hasOwnProperty.call(message, "secretId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretId);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteWebhookConfigRequest message, length delimited. Does not implicitly {@link infinitusai.be.DeleteWebhookConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {infinitusai.be.IDeleteWebhookConfigRequest} message DeleteWebhookConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteWebhookConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteWebhookConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteWebhookConfigRequest} DeleteWebhookConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteWebhookConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteWebhookConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.secretId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteWebhookConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteWebhookConfigRequest} DeleteWebhookConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteWebhookConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteWebhookConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteWebhookConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.secretId != null && message.hasOwnProperty("secretId"))
                        if (!$util.isString(message.secretId))
                            return "secretId: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteWebhookConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteWebhookConfigRequest} DeleteWebhookConfigRequest
                 */
                DeleteWebhookConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteWebhookConfigRequest)
                        return object;
                    var message = new $root.infinitusai.be.DeleteWebhookConfigRequest();
                    if (object.secretId != null)
                        message.secretId = String(object.secretId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteWebhookConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {infinitusai.be.DeleteWebhookConfigRequest} message DeleteWebhookConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteWebhookConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.secretId = "";
                    if (message.secretId != null && message.hasOwnProperty("secretId"))
                        object.secretId = message.secretId;
                    return object;
                };
    
                /**
                 * Converts this DeleteWebhookConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteWebhookConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteWebhookConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteWebhookConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteWebhookConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteWebhookConfigRequest";
                };
    
                return DeleteWebhookConfigRequest;
            })();
    
            be.DeleteWebhookConfigResponse = (function() {
    
                /**
                 * Properties of a DeleteWebhookConfigResponse.
                 * @memberof infinitusai.be
                 * @interface IDeleteWebhookConfigResponse
                 */
    
                /**
                 * Constructs a new DeleteWebhookConfigResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteWebhookConfigResponse.
                 * @implements IDeleteWebhookConfigResponse
                 * @constructor
                 * @param {infinitusai.be.IDeleteWebhookConfigResponse=} [properties] Properties to set
                 */
                function DeleteWebhookConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteWebhookConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.IDeleteWebhookConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteWebhookConfigResponse} DeleteWebhookConfigResponse instance
                 */
                DeleteWebhookConfigResponse.create = function create(properties) {
                    return new DeleteWebhookConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteWebhookConfigResponse message. Does not implicitly {@link infinitusai.be.DeleteWebhookConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.IDeleteWebhookConfigResponse} message DeleteWebhookConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteWebhookConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteWebhookConfigResponse message, length delimited. Does not implicitly {@link infinitusai.be.DeleteWebhookConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.IDeleteWebhookConfigResponse} message DeleteWebhookConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteWebhookConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteWebhookConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteWebhookConfigResponse} DeleteWebhookConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteWebhookConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteWebhookConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteWebhookConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteWebhookConfigResponse} DeleteWebhookConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteWebhookConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteWebhookConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteWebhookConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteWebhookConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteWebhookConfigResponse} DeleteWebhookConfigResponse
                 */
                DeleteWebhookConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteWebhookConfigResponse)
                        return object;
                    return new $root.infinitusai.be.DeleteWebhookConfigResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteWebhookConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.DeleteWebhookConfigResponse} message DeleteWebhookConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteWebhookConfigResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteWebhookConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteWebhookConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteWebhookConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteWebhookConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteWebhookConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteWebhookConfigResponse";
                };
    
                return DeleteWebhookConfigResponse;
            })();
    
            be.WebhookConfigDoc = (function() {
    
                /**
                 * Properties of a WebhookConfigDoc.
                 * @memberof infinitusai.be
                 * @interface IWebhookConfigDoc
                 * @property {string|null} [secretId] WebhookConfigDoc secretId
                 * @property {string|null} [url] WebhookConfigDoc url
                 * @property {number|Long|null} [createdAtMillis] WebhookConfigDoc createdAtMillis
                 */
    
                /**
                 * Constructs a new WebhookConfigDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebhookConfigDoc.
                 * @implements IWebhookConfigDoc
                 * @constructor
                 * @param {infinitusai.be.IWebhookConfigDoc=} [properties] Properties to set
                 */
                function WebhookConfigDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebhookConfigDoc secretId.
                 * @member {string} secretId
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @instance
                 */
                WebhookConfigDoc.prototype.secretId = "";
    
                /**
                 * WebhookConfigDoc url.
                 * @member {string} url
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @instance
                 */
                WebhookConfigDoc.prototype.url = "";
    
                /**
                 * WebhookConfigDoc createdAtMillis.
                 * @member {number|Long} createdAtMillis
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @instance
                 */
                WebhookConfigDoc.prototype.createdAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new WebhookConfigDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {infinitusai.be.IWebhookConfigDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.WebhookConfigDoc} WebhookConfigDoc instance
                 */
                WebhookConfigDoc.create = function create(properties) {
                    return new WebhookConfigDoc(properties);
                };
    
                /**
                 * Encodes the specified WebhookConfigDoc message. Does not implicitly {@link infinitusai.be.WebhookConfigDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {infinitusai.be.IWebhookConfigDoc} message WebhookConfigDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookConfigDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.secretId != null && Object.hasOwnProperty.call(message, "secretId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretId);
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
                    if (message.createdAtMillis != null && Object.hasOwnProperty.call(message, "createdAtMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.createdAtMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified WebhookConfigDoc message, length delimited. Does not implicitly {@link infinitusai.be.WebhookConfigDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {infinitusai.be.IWebhookConfigDoc} message WebhookConfigDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookConfigDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebhookConfigDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebhookConfigDoc} WebhookConfigDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookConfigDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebhookConfigDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.secretId = reader.string();
                                break;
                            }
                        case 2: {
                                message.url = reader.string();
                                break;
                            }
                        case 3: {
                                message.createdAtMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebhookConfigDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebhookConfigDoc} WebhookConfigDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookConfigDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebhookConfigDoc message.
                 * @function verify
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebhookConfigDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.secretId != null && message.hasOwnProperty("secretId"))
                        if (!$util.isString(message.secretId))
                            return "secretId: string expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (!$util.isInteger(message.createdAtMillis) && !(message.createdAtMillis && $util.isInteger(message.createdAtMillis.low) && $util.isInteger(message.createdAtMillis.high)))
                            return "createdAtMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a WebhookConfigDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebhookConfigDoc} WebhookConfigDoc
                 */
                WebhookConfigDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebhookConfigDoc)
                        return object;
                    var message = new $root.infinitusai.be.WebhookConfigDoc();
                    if (object.secretId != null)
                        message.secretId = String(object.secretId);
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.createdAtMillis != null)
                        if ($util.Long)
                            (message.createdAtMillis = $util.Long.fromValue(object.createdAtMillis)).unsigned = false;
                        else if (typeof object.createdAtMillis === "string")
                            message.createdAtMillis = parseInt(object.createdAtMillis, 10);
                        else if (typeof object.createdAtMillis === "number")
                            message.createdAtMillis = object.createdAtMillis;
                        else if (typeof object.createdAtMillis === "object")
                            message.createdAtMillis = new $util.LongBits(object.createdAtMillis.low >>> 0, object.createdAtMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebhookConfigDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {infinitusai.be.WebhookConfigDoc} message WebhookConfigDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebhookConfigDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.secretId = "";
                        object.url = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAtMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.secretId != null && message.hasOwnProperty("secretId"))
                        object.secretId = message.secretId;
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (typeof message.createdAtMillis === "number")
                            object.createdAtMillis = options.longs === String ? String(message.createdAtMillis) : message.createdAtMillis;
                        else
                            object.createdAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtMillis) : options.longs === Number ? new $util.LongBits(message.createdAtMillis.low >>> 0, message.createdAtMillis.high >>> 0).toNumber() : message.createdAtMillis;
                    return object;
                };
    
                /**
                 * Converts this WebhookConfigDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebhookConfigDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebhookConfigDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebhookConfigDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebhookConfigDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebhookConfigDoc";
                };
    
                return WebhookConfigDoc;
            })();
    
            be.GetWebhookConfigsRequest = (function() {
    
                /**
                 * Properties of a GetWebhookConfigsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetWebhookConfigsRequest
                 */
    
                /**
                 * Constructs a new GetWebhookConfigsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetWebhookConfigsRequest.
                 * @implements IGetWebhookConfigsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetWebhookConfigsRequest=} [properties] Properties to set
                 */
                function GetWebhookConfigsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetWebhookConfigsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {infinitusai.be.IGetWebhookConfigsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetWebhookConfigsRequest} GetWebhookConfigsRequest instance
                 */
                GetWebhookConfigsRequest.create = function create(properties) {
                    return new GetWebhookConfigsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetWebhookConfigsRequest message. Does not implicitly {@link infinitusai.be.GetWebhookConfigsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {infinitusai.be.IGetWebhookConfigsRequest} message GetWebhookConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWebhookConfigsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetWebhookConfigsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetWebhookConfigsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {infinitusai.be.IGetWebhookConfigsRequest} message GetWebhookConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWebhookConfigsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetWebhookConfigsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetWebhookConfigsRequest} GetWebhookConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWebhookConfigsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetWebhookConfigsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetWebhookConfigsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetWebhookConfigsRequest} GetWebhookConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWebhookConfigsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetWebhookConfigsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetWebhookConfigsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetWebhookConfigsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetWebhookConfigsRequest} GetWebhookConfigsRequest
                 */
                GetWebhookConfigsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetWebhookConfigsRequest)
                        return object;
                    return new $root.infinitusai.be.GetWebhookConfigsRequest();
                };
    
                /**
                 * Creates a plain object from a GetWebhookConfigsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {infinitusai.be.GetWebhookConfigsRequest} message GetWebhookConfigsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWebhookConfigsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetWebhookConfigsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWebhookConfigsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetWebhookConfigsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetWebhookConfigsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetWebhookConfigsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetWebhookConfigsRequest";
                };
    
                return GetWebhookConfigsRequest;
            })();
    
            be.WebhookConfigResponse = (function() {
    
                /**
                 * Properties of a WebhookConfigResponse.
                 * @memberof infinitusai.be
                 * @interface IWebhookConfigResponse
                 * @property {string|null} [secretId] WebhookConfigResponse secretId
                 * @property {string|null} [url] WebhookConfigResponse url
                 * @property {number|Long|null} [createdAtMillis] WebhookConfigResponse createdAtMillis
                 */
    
                /**
                 * Constructs a new WebhookConfigResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebhookConfigResponse.
                 * @implements IWebhookConfigResponse
                 * @constructor
                 * @param {infinitusai.be.IWebhookConfigResponse=} [properties] Properties to set
                 */
                function WebhookConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebhookConfigResponse secretId.
                 * @member {string} secretId
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @instance
                 */
                WebhookConfigResponse.prototype.secretId = "";
    
                /**
                 * WebhookConfigResponse url.
                 * @member {string} url
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @instance
                 */
                WebhookConfigResponse.prototype.url = "";
    
                /**
                 * WebhookConfigResponse createdAtMillis.
                 * @member {number|Long} createdAtMillis
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @instance
                 */
                WebhookConfigResponse.prototype.createdAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new WebhookConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.IWebhookConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.WebhookConfigResponse} WebhookConfigResponse instance
                 */
                WebhookConfigResponse.create = function create(properties) {
                    return new WebhookConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified WebhookConfigResponse message. Does not implicitly {@link infinitusai.be.WebhookConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.IWebhookConfigResponse} message WebhookConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.secretId != null && Object.hasOwnProperty.call(message, "secretId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretId);
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
                    if (message.createdAtMillis != null && Object.hasOwnProperty.call(message, "createdAtMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.createdAtMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified WebhookConfigResponse message, length delimited. Does not implicitly {@link infinitusai.be.WebhookConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.IWebhookConfigResponse} message WebhookConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebhookConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebhookConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebhookConfigResponse} WebhookConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebhookConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.secretId = reader.string();
                                break;
                            }
                        case 2: {
                                message.url = reader.string();
                                break;
                            }
                        case 3: {
                                message.createdAtMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebhookConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebhookConfigResponse} WebhookConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebhookConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebhookConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebhookConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.secretId != null && message.hasOwnProperty("secretId"))
                        if (!$util.isString(message.secretId))
                            return "secretId: string expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (!$util.isInteger(message.createdAtMillis) && !(message.createdAtMillis && $util.isInteger(message.createdAtMillis.low) && $util.isInteger(message.createdAtMillis.high)))
                            return "createdAtMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a WebhookConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebhookConfigResponse} WebhookConfigResponse
                 */
                WebhookConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebhookConfigResponse)
                        return object;
                    var message = new $root.infinitusai.be.WebhookConfigResponse();
                    if (object.secretId != null)
                        message.secretId = String(object.secretId);
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.createdAtMillis != null)
                        if ($util.Long)
                            (message.createdAtMillis = $util.Long.fromValue(object.createdAtMillis)).unsigned = false;
                        else if (typeof object.createdAtMillis === "string")
                            message.createdAtMillis = parseInt(object.createdAtMillis, 10);
                        else if (typeof object.createdAtMillis === "number")
                            message.createdAtMillis = object.createdAtMillis;
                        else if (typeof object.createdAtMillis === "object")
                            message.createdAtMillis = new $util.LongBits(object.createdAtMillis.low >>> 0, object.createdAtMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebhookConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {infinitusai.be.WebhookConfigResponse} message WebhookConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebhookConfigResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.secretId = "";
                        object.url = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAtMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.secretId != null && message.hasOwnProperty("secretId"))
                        object.secretId = message.secretId;
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.createdAtMillis != null && message.hasOwnProperty("createdAtMillis"))
                        if (typeof message.createdAtMillis === "number")
                            object.createdAtMillis = options.longs === String ? String(message.createdAtMillis) : message.createdAtMillis;
                        else
                            object.createdAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtMillis) : options.longs === Number ? new $util.LongBits(message.createdAtMillis.low >>> 0, message.createdAtMillis.high >>> 0).toNumber() : message.createdAtMillis;
                    return object;
                };
    
                /**
                 * Converts this WebhookConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebhookConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebhookConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebhookConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebhookConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebhookConfigResponse";
                };
    
                return WebhookConfigResponse;
            })();
    
            be.GetWebhookConfigsResponse = (function() {
    
                /**
                 * Properties of a GetWebhookConfigsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetWebhookConfigsResponse
                 * @property {Array.<infinitusai.be.IWebhookConfigResponse>|null} [responses] GetWebhookConfigsResponse responses
                 */
    
                /**
                 * Constructs a new GetWebhookConfigsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetWebhookConfigsResponse.
                 * @implements IGetWebhookConfigsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetWebhookConfigsResponse=} [properties] Properties to set
                 */
                function GetWebhookConfigsResponse(properties) {
                    this.responses = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetWebhookConfigsResponse responses.
                 * @member {Array.<infinitusai.be.IWebhookConfigResponse>} responses
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @instance
                 */
                GetWebhookConfigsResponse.prototype.responses = $util.emptyArray;
    
                /**
                 * Creates a new GetWebhookConfigsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {infinitusai.be.IGetWebhookConfigsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetWebhookConfigsResponse} GetWebhookConfigsResponse instance
                 */
                GetWebhookConfigsResponse.create = function create(properties) {
                    return new GetWebhookConfigsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetWebhookConfigsResponse message. Does not implicitly {@link infinitusai.be.GetWebhookConfigsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {infinitusai.be.IGetWebhookConfigsResponse} message GetWebhookConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWebhookConfigsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.responses != null && message.responses.length)
                        for (var i = 0; i < message.responses.length; ++i)
                            $root.infinitusai.be.WebhookConfigResponse.encode(message.responses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetWebhookConfigsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetWebhookConfigsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {infinitusai.be.IGetWebhookConfigsResponse} message GetWebhookConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetWebhookConfigsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetWebhookConfigsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetWebhookConfigsResponse} GetWebhookConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWebhookConfigsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetWebhookConfigsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.responses && message.responses.length))
                                    message.responses = [];
                                message.responses.push($root.infinitusai.be.WebhookConfigResponse.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetWebhookConfigsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetWebhookConfigsResponse} GetWebhookConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetWebhookConfigsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetWebhookConfigsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetWebhookConfigsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.responses != null && message.hasOwnProperty("responses")) {
                        if (!Array.isArray(message.responses))
                            return "responses: array expected";
                        for (var i = 0; i < message.responses.length; ++i) {
                            var error = $root.infinitusai.be.WebhookConfigResponse.verify(message.responses[i]);
                            if (error)
                                return "responses." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetWebhookConfigsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetWebhookConfigsResponse} GetWebhookConfigsResponse
                 */
                GetWebhookConfigsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetWebhookConfigsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetWebhookConfigsResponse();
                    if (object.responses) {
                        if (!Array.isArray(object.responses))
                            throw TypeError(".infinitusai.be.GetWebhookConfigsResponse.responses: array expected");
                        message.responses = [];
                        for (var i = 0; i < object.responses.length; ++i) {
                            if (typeof object.responses[i] !== "object")
                                throw TypeError(".infinitusai.be.GetWebhookConfigsResponse.responses: object expected");
                            message.responses[i] = $root.infinitusai.be.WebhookConfigResponse.fromObject(object.responses[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetWebhookConfigsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {infinitusai.be.GetWebhookConfigsResponse} message GetWebhookConfigsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWebhookConfigsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.responses = [];
                    if (message.responses && message.responses.length) {
                        object.responses = [];
                        for (var j = 0; j < message.responses.length; ++j)
                            object.responses[j] = $root.infinitusai.be.WebhookConfigResponse.toObject(message.responses[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetWebhookConfigsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWebhookConfigsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetWebhookConfigsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetWebhookConfigsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetWebhookConfigsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetWebhookConfigsResponse";
                };
    
                return GetWebhookConfigsResponse;
            })();
    
            be.ResendWebhookNotificationsRequest = (function() {
    
                /**
                 * Properties of a ResendWebhookNotificationsRequest.
                 * @memberof infinitusai.be
                 * @interface IResendWebhookNotificationsRequest
                 * @property {Array.<string>|null} [taskIds] ResendWebhookNotificationsRequest taskIds
                 */
    
                /**
                 * Constructs a new ResendWebhookNotificationsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ResendWebhookNotificationsRequest.
                 * @implements IResendWebhookNotificationsRequest
                 * @constructor
                 * @param {infinitusai.be.IResendWebhookNotificationsRequest=} [properties] Properties to set
                 */
                function ResendWebhookNotificationsRequest(properties) {
                    this.taskIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ResendWebhookNotificationsRequest taskIds.
                 * @member {Array.<string>} taskIds
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @instance
                 */
                ResendWebhookNotificationsRequest.prototype.taskIds = $util.emptyArray;
    
                /**
                 * Creates a new ResendWebhookNotificationsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {infinitusai.be.IResendWebhookNotificationsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ResendWebhookNotificationsRequest} ResendWebhookNotificationsRequest instance
                 */
                ResendWebhookNotificationsRequest.create = function create(properties) {
                    return new ResendWebhookNotificationsRequest(properties);
                };
    
                /**
                 * Encodes the specified ResendWebhookNotificationsRequest message. Does not implicitly {@link infinitusai.be.ResendWebhookNotificationsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {infinitusai.be.IResendWebhookNotificationsRequest} message ResendWebhookNotificationsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendWebhookNotificationsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskIds != null && message.taskIds.length)
                        for (var i = 0; i < message.taskIds.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskIds[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified ResendWebhookNotificationsRequest message, length delimited. Does not implicitly {@link infinitusai.be.ResendWebhookNotificationsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {infinitusai.be.IResendWebhookNotificationsRequest} message ResendWebhookNotificationsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendWebhookNotificationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ResendWebhookNotificationsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ResendWebhookNotificationsRequest} ResendWebhookNotificationsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendWebhookNotificationsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ResendWebhookNotificationsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.taskIds && message.taskIds.length))
                                    message.taskIds = [];
                                message.taskIds.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ResendWebhookNotificationsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ResendWebhookNotificationsRequest} ResendWebhookNotificationsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendWebhookNotificationsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ResendWebhookNotificationsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResendWebhookNotificationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskIds != null && message.hasOwnProperty("taskIds")) {
                        if (!Array.isArray(message.taskIds))
                            return "taskIds: array expected";
                        for (var i = 0; i < message.taskIds.length; ++i)
                            if (!$util.isString(message.taskIds[i]))
                                return "taskIds: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ResendWebhookNotificationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ResendWebhookNotificationsRequest} ResendWebhookNotificationsRequest
                 */
                ResendWebhookNotificationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ResendWebhookNotificationsRequest)
                        return object;
                    var message = new $root.infinitusai.be.ResendWebhookNotificationsRequest();
                    if (object.taskIds) {
                        if (!Array.isArray(object.taskIds))
                            throw TypeError(".infinitusai.be.ResendWebhookNotificationsRequest.taskIds: array expected");
                        message.taskIds = [];
                        for (var i = 0; i < object.taskIds.length; ++i)
                            message.taskIds[i] = String(object.taskIds[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ResendWebhookNotificationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {infinitusai.be.ResendWebhookNotificationsRequest} message ResendWebhookNotificationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResendWebhookNotificationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.taskIds = [];
                    if (message.taskIds && message.taskIds.length) {
                        object.taskIds = [];
                        for (var j = 0; j < message.taskIds.length; ++j)
                            object.taskIds[j] = message.taskIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ResendWebhookNotificationsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResendWebhookNotificationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ResendWebhookNotificationsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ResendWebhookNotificationsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ResendWebhookNotificationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ResendWebhookNotificationsRequest";
                };
    
                return ResendWebhookNotificationsRequest;
            })();
    
            /**
             * ResendWebhookStatus enum.
             * @name infinitusai.be.ResendWebhookStatus
             * @enum {number}
             * @property {number} RESEND_WEBHOOK_STATUS_UNKNOWN=0 RESEND_WEBHOOK_STATUS_UNKNOWN value
             * @property {number} RESEND_WEBHOOK_STATUS_SUCCESS=1 RESEND_WEBHOOK_STATUS_SUCCESS value
             * @property {number} RESEND_WEBHOOK_STATUS_NON_SUCCESS_RESPONSE=2 RESEND_WEBHOOK_STATUS_NON_SUCCESS_RESPONSE value
             * @property {number} RESEND_WEBHOOK_STATUS_INTERNAL_SERVER_ERROR=3 RESEND_WEBHOOK_STATUS_INTERNAL_SERVER_ERROR value
             * @property {number} RESEND_WEBHOOK_STATUS_SKIP_DUE_TO_INCOMPLETE_BUNDLE_TASK=4 RESEND_WEBHOOK_STATUS_SKIP_DUE_TO_INCOMPLETE_BUNDLE_TASK value
             */
            be.ResendWebhookStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "RESEND_WEBHOOK_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "RESEND_WEBHOOK_STATUS_SUCCESS"] = 1;
                values[valuesById[2] = "RESEND_WEBHOOK_STATUS_NON_SUCCESS_RESPONSE"] = 2;
                values[valuesById[3] = "RESEND_WEBHOOK_STATUS_INTERNAL_SERVER_ERROR"] = 3;
                values[valuesById[4] = "RESEND_WEBHOOK_STATUS_SKIP_DUE_TO_INCOMPLETE_BUNDLE_TASK"] = 4;
                return values;
            })();
    
            be.ResendWebhookResponse = (function() {
    
                /**
                 * Properties of a ResendWebhookResponse.
                 * @memberof infinitusai.be
                 * @interface IResendWebhookResponse
                 * @property {string|null} [taskId] ResendWebhookResponse taskId
                 * @property {infinitusai.be.ResendWebhookStatus|null} [status] ResendWebhookResponse status
                 */
    
                /**
                 * Constructs a new ResendWebhookResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ResendWebhookResponse.
                 * @implements IResendWebhookResponse
                 * @constructor
                 * @param {infinitusai.be.IResendWebhookResponse=} [properties] Properties to set
                 */
                function ResendWebhookResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ResendWebhookResponse taskId.
                 * @member {string} taskId
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @instance
                 */
                ResendWebhookResponse.prototype.taskId = "";
    
                /**
                 * ResendWebhookResponse status.
                 * @member {infinitusai.be.ResendWebhookStatus} status
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @instance
                 */
                ResendWebhookResponse.prototype.status = 0;
    
                /**
                 * Creates a new ResendWebhookResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {infinitusai.be.IResendWebhookResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ResendWebhookResponse} ResendWebhookResponse instance
                 */
                ResendWebhookResponse.create = function create(properties) {
                    return new ResendWebhookResponse(properties);
                };
    
                /**
                 * Encodes the specified ResendWebhookResponse message. Does not implicitly {@link infinitusai.be.ResendWebhookResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {infinitusai.be.IResendWebhookResponse} message ResendWebhookResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendWebhookResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskId);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                    return writer;
                };
    
                /**
                 * Encodes the specified ResendWebhookResponse message, length delimited. Does not implicitly {@link infinitusai.be.ResendWebhookResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {infinitusai.be.IResendWebhookResponse} message ResendWebhookResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendWebhookResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ResendWebhookResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ResendWebhookResponse} ResendWebhookResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendWebhookResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ResendWebhookResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskId = reader.string();
                                break;
                            }
                        case 2: {
                                message.status = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ResendWebhookResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ResendWebhookResponse} ResendWebhookResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendWebhookResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ResendWebhookResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResendWebhookResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        if (!$util.isString(message.taskId))
                            return "taskId: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a ResendWebhookResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ResendWebhookResponse} ResendWebhookResponse
                 */
                ResendWebhookResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ResendWebhookResponse)
                        return object;
                    var message = new $root.infinitusai.be.ResendWebhookResponse();
                    if (object.taskId != null)
                        message.taskId = String(object.taskId);
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "RESEND_WEBHOOK_STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "RESEND_WEBHOOK_STATUS_SUCCESS":
                    case 1:
                        message.status = 1;
                        break;
                    case "RESEND_WEBHOOK_STATUS_NON_SUCCESS_RESPONSE":
                    case 2:
                        message.status = 2;
                        break;
                    case "RESEND_WEBHOOK_STATUS_INTERNAL_SERVER_ERROR":
                    case 3:
                        message.status = 3;
                        break;
                    case "RESEND_WEBHOOK_STATUS_SKIP_DUE_TO_INCOMPLETE_BUNDLE_TASK":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ResendWebhookResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {infinitusai.be.ResendWebhookResponse} message ResendWebhookResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResendWebhookResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskId = "";
                        object.status = options.enums === String ? "RESEND_WEBHOOK_STATUS_UNKNOWN" : 0;
                    }
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        object.taskId = message.taskId;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.ResendWebhookStatus[message.status] === undefined ? message.status : $root.infinitusai.be.ResendWebhookStatus[message.status] : message.status;
                    return object;
                };
    
                /**
                 * Converts this ResendWebhookResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResendWebhookResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ResendWebhookResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ResendWebhookResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ResendWebhookResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ResendWebhookResponse";
                };
    
                return ResendWebhookResponse;
            })();
    
            be.ResendWebhookNotificationsResponse = (function() {
    
                /**
                 * Properties of a ResendWebhookNotificationsResponse.
                 * @memberof infinitusai.be
                 * @interface IResendWebhookNotificationsResponse
                 * @property {Array.<infinitusai.be.IResendWebhookResponse>|null} [responses] ResendWebhookNotificationsResponse responses
                 */
    
                /**
                 * Constructs a new ResendWebhookNotificationsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ResendWebhookNotificationsResponse.
                 * @implements IResendWebhookNotificationsResponse
                 * @constructor
                 * @param {infinitusai.be.IResendWebhookNotificationsResponse=} [properties] Properties to set
                 */
                function ResendWebhookNotificationsResponse(properties) {
                    this.responses = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ResendWebhookNotificationsResponse responses.
                 * @member {Array.<infinitusai.be.IResendWebhookResponse>} responses
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @instance
                 */
                ResendWebhookNotificationsResponse.prototype.responses = $util.emptyArray;
    
                /**
                 * Creates a new ResendWebhookNotificationsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {infinitusai.be.IResendWebhookNotificationsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ResendWebhookNotificationsResponse} ResendWebhookNotificationsResponse instance
                 */
                ResendWebhookNotificationsResponse.create = function create(properties) {
                    return new ResendWebhookNotificationsResponse(properties);
                };
    
                /**
                 * Encodes the specified ResendWebhookNotificationsResponse message. Does not implicitly {@link infinitusai.be.ResendWebhookNotificationsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {infinitusai.be.IResendWebhookNotificationsResponse} message ResendWebhookNotificationsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendWebhookNotificationsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.responses != null && message.responses.length)
                        for (var i = 0; i < message.responses.length; ++i)
                            $root.infinitusai.be.ResendWebhookResponse.encode(message.responses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ResendWebhookNotificationsResponse message, length delimited. Does not implicitly {@link infinitusai.be.ResendWebhookNotificationsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {infinitusai.be.IResendWebhookNotificationsResponse} message ResendWebhookNotificationsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendWebhookNotificationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ResendWebhookNotificationsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ResendWebhookNotificationsResponse} ResendWebhookNotificationsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendWebhookNotificationsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ResendWebhookNotificationsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.responses && message.responses.length))
                                    message.responses = [];
                                message.responses.push($root.infinitusai.be.ResendWebhookResponse.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ResendWebhookNotificationsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ResendWebhookNotificationsResponse} ResendWebhookNotificationsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendWebhookNotificationsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ResendWebhookNotificationsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResendWebhookNotificationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.responses != null && message.hasOwnProperty("responses")) {
                        if (!Array.isArray(message.responses))
                            return "responses: array expected";
                        for (var i = 0; i < message.responses.length; ++i) {
                            var error = $root.infinitusai.be.ResendWebhookResponse.verify(message.responses[i]);
                            if (error)
                                return "responses." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ResendWebhookNotificationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ResendWebhookNotificationsResponse} ResendWebhookNotificationsResponse
                 */
                ResendWebhookNotificationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ResendWebhookNotificationsResponse)
                        return object;
                    var message = new $root.infinitusai.be.ResendWebhookNotificationsResponse();
                    if (object.responses) {
                        if (!Array.isArray(object.responses))
                            throw TypeError(".infinitusai.be.ResendWebhookNotificationsResponse.responses: array expected");
                        message.responses = [];
                        for (var i = 0; i < object.responses.length; ++i) {
                            if (typeof object.responses[i] !== "object")
                                throw TypeError(".infinitusai.be.ResendWebhookNotificationsResponse.responses: object expected");
                            message.responses[i] = $root.infinitusai.be.ResendWebhookResponse.fromObject(object.responses[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ResendWebhookNotificationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {infinitusai.be.ResendWebhookNotificationsResponse} message ResendWebhookNotificationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResendWebhookNotificationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.responses = [];
                    if (message.responses && message.responses.length) {
                        object.responses = [];
                        for (var j = 0; j < message.responses.length; ++j)
                            object.responses[j] = $root.infinitusai.be.ResendWebhookResponse.toObject(message.responses[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ResendWebhookNotificationsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResendWebhookNotificationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ResendWebhookNotificationsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ResendWebhookNotificationsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ResendWebhookNotificationsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ResendWebhookNotificationsResponse";
                };
    
                return ResendWebhookNotificationsResponse;
            })();
    
            be.CovidVaccineAvailabilityCallOutputs = (function() {
    
                /**
                 * Properties of a CovidVaccineAvailabilityCallOutputs.
                 * @memberof infinitusai.be
                 * @interface ICovidVaccineAvailabilityCallOutputs
                 * @property {infinitusai.be.INullableBool|null} [isVaccineAvailable] CovidVaccineAvailabilityCallOutputs isVaccineAvailable
                 * @property {infinitusai.be.INullableBool|null} [allowWalkIn] CovidVaccineAvailabilityCallOutputs allowWalkIn
                 * @property {infinitusai.be.INullableString|null} [appointmentMethod] CovidVaccineAvailabilityCallOutputs appointmentMethod
                 * @property {infinitusai.be.INullableBool|null} [willRespondToEva] CovidVaccineAvailabilityCallOutputs willRespondToEva
                 * @property {infinitusai.be.INullableBool|null} [availableForSixtyFivePlus] CovidVaccineAvailabilityCallOutputs availableForSixtyFivePlus
                 * @property {infinitusai.be.INullableBool|null} [availableForSeventyFivePlus] CovidVaccineAvailabilityCallOutputs availableForSeventyFivePlus
                 * @property {infinitusai.be.INullableBool|null} [limitedToCountyResidents] CovidVaccineAvailabilityCallOutputs limitedToCountyResidents
                 */
    
                /**
                 * Constructs a new CovidVaccineAvailabilityCallOutputs.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CovidVaccineAvailabilityCallOutputs.
                 * @implements ICovidVaccineAvailabilityCallOutputs
                 * @constructor
                 * @param {infinitusai.be.ICovidVaccineAvailabilityCallOutputs=} [properties] Properties to set
                 */
                function CovidVaccineAvailabilityCallOutputs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CovidVaccineAvailabilityCallOutputs isVaccineAvailable.
                 * @member {infinitusai.be.INullableBool|null|undefined} isVaccineAvailable
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @instance
                 */
                CovidVaccineAvailabilityCallOutputs.prototype.isVaccineAvailable = null;
    
                /**
                 * CovidVaccineAvailabilityCallOutputs allowWalkIn.
                 * @member {infinitusai.be.INullableBool|null|undefined} allowWalkIn
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @instance
                 */
                CovidVaccineAvailabilityCallOutputs.prototype.allowWalkIn = null;
    
                /**
                 * CovidVaccineAvailabilityCallOutputs appointmentMethod.
                 * @member {infinitusai.be.INullableString|null|undefined} appointmentMethod
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @instance
                 */
                CovidVaccineAvailabilityCallOutputs.prototype.appointmentMethod = null;
    
                /**
                 * CovidVaccineAvailabilityCallOutputs willRespondToEva.
                 * @member {infinitusai.be.INullableBool|null|undefined} willRespondToEva
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @instance
                 */
                CovidVaccineAvailabilityCallOutputs.prototype.willRespondToEva = null;
    
                /**
                 * CovidVaccineAvailabilityCallOutputs availableForSixtyFivePlus.
                 * @member {infinitusai.be.INullableBool|null|undefined} availableForSixtyFivePlus
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @instance
                 */
                CovidVaccineAvailabilityCallOutputs.prototype.availableForSixtyFivePlus = null;
    
                /**
                 * CovidVaccineAvailabilityCallOutputs availableForSeventyFivePlus.
                 * @member {infinitusai.be.INullableBool|null|undefined} availableForSeventyFivePlus
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @instance
                 */
                CovidVaccineAvailabilityCallOutputs.prototype.availableForSeventyFivePlus = null;
    
                /**
                 * CovidVaccineAvailabilityCallOutputs limitedToCountyResidents.
                 * @member {infinitusai.be.INullableBool|null|undefined} limitedToCountyResidents
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @instance
                 */
                CovidVaccineAvailabilityCallOutputs.prototype.limitedToCountyResidents = null;
    
                /**
                 * Creates a new CovidVaccineAvailabilityCallOutputs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {infinitusai.be.ICovidVaccineAvailabilityCallOutputs=} [properties] Properties to set
                 * @returns {infinitusai.be.CovidVaccineAvailabilityCallOutputs} CovidVaccineAvailabilityCallOutputs instance
                 */
                CovidVaccineAvailabilityCallOutputs.create = function create(properties) {
                    return new CovidVaccineAvailabilityCallOutputs(properties);
                };
    
                /**
                 * Encodes the specified CovidVaccineAvailabilityCallOutputs message. Does not implicitly {@link infinitusai.be.CovidVaccineAvailabilityCallOutputs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {infinitusai.be.ICovidVaccineAvailabilityCallOutputs} message CovidVaccineAvailabilityCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CovidVaccineAvailabilityCallOutputs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isVaccineAvailable != null && Object.hasOwnProperty.call(message, "isVaccineAvailable"))
                        $root.infinitusai.be.NullableBool.encode(message.isVaccineAvailable, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.allowWalkIn != null && Object.hasOwnProperty.call(message, "allowWalkIn"))
                        $root.infinitusai.be.NullableBool.encode(message.allowWalkIn, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.appointmentMethod != null && Object.hasOwnProperty.call(message, "appointmentMethod"))
                        $root.infinitusai.be.NullableString.encode(message.appointmentMethod, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.willRespondToEva != null && Object.hasOwnProperty.call(message, "willRespondToEva"))
                        $root.infinitusai.be.NullableBool.encode(message.willRespondToEva, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.availableForSixtyFivePlus != null && Object.hasOwnProperty.call(message, "availableForSixtyFivePlus"))
                        $root.infinitusai.be.NullableBool.encode(message.availableForSixtyFivePlus, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.availableForSeventyFivePlus != null && Object.hasOwnProperty.call(message, "availableForSeventyFivePlus"))
                        $root.infinitusai.be.NullableBool.encode(message.availableForSeventyFivePlus, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.limitedToCountyResidents != null && Object.hasOwnProperty.call(message, "limitedToCountyResidents"))
                        $root.infinitusai.be.NullableBool.encode(message.limitedToCountyResidents, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CovidVaccineAvailabilityCallOutputs message, length delimited. Does not implicitly {@link infinitusai.be.CovidVaccineAvailabilityCallOutputs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {infinitusai.be.ICovidVaccineAvailabilityCallOutputs} message CovidVaccineAvailabilityCallOutputs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CovidVaccineAvailabilityCallOutputs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CovidVaccineAvailabilityCallOutputs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CovidVaccineAvailabilityCallOutputs} CovidVaccineAvailabilityCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CovidVaccineAvailabilityCallOutputs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isVaccineAvailable = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.allowWalkIn = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.appointmentMethod = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.willRespondToEva = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.availableForSixtyFivePlus = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.availableForSeventyFivePlus = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.limitedToCountyResidents = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CovidVaccineAvailabilityCallOutputs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CovidVaccineAvailabilityCallOutputs} CovidVaccineAvailabilityCallOutputs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CovidVaccineAvailabilityCallOutputs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CovidVaccineAvailabilityCallOutputs message.
                 * @function verify
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CovidVaccineAvailabilityCallOutputs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isVaccineAvailable != null && message.hasOwnProperty("isVaccineAvailable")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.isVaccineAvailable);
                        if (error)
                            return "isVaccineAvailable." + error;
                    }
                    if (message.allowWalkIn != null && message.hasOwnProperty("allowWalkIn")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.allowWalkIn);
                        if (error)
                            return "allowWalkIn." + error;
                    }
                    if (message.appointmentMethod != null && message.hasOwnProperty("appointmentMethod")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.appointmentMethod);
                        if (error)
                            return "appointmentMethod." + error;
                    }
                    if (message.willRespondToEva != null && message.hasOwnProperty("willRespondToEva")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.willRespondToEva);
                        if (error)
                            return "willRespondToEva." + error;
                    }
                    if (message.availableForSixtyFivePlus != null && message.hasOwnProperty("availableForSixtyFivePlus")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.availableForSixtyFivePlus);
                        if (error)
                            return "availableForSixtyFivePlus." + error;
                    }
                    if (message.availableForSeventyFivePlus != null && message.hasOwnProperty("availableForSeventyFivePlus")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.availableForSeventyFivePlus);
                        if (error)
                            return "availableForSeventyFivePlus." + error;
                    }
                    if (message.limitedToCountyResidents != null && message.hasOwnProperty("limitedToCountyResidents")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.limitedToCountyResidents);
                        if (error)
                            return "limitedToCountyResidents." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CovidVaccineAvailabilityCallOutputs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CovidVaccineAvailabilityCallOutputs} CovidVaccineAvailabilityCallOutputs
                 */
                CovidVaccineAvailabilityCallOutputs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs)
                        return object;
                    var message = new $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs();
                    if (object.isVaccineAvailable != null) {
                        if (typeof object.isVaccineAvailable !== "object")
                            throw TypeError(".infinitusai.be.CovidVaccineAvailabilityCallOutputs.isVaccineAvailable: object expected");
                        message.isVaccineAvailable = $root.infinitusai.be.NullableBool.fromObject(object.isVaccineAvailable);
                    }
                    if (object.allowWalkIn != null) {
                        if (typeof object.allowWalkIn !== "object")
                            throw TypeError(".infinitusai.be.CovidVaccineAvailabilityCallOutputs.allowWalkIn: object expected");
                        message.allowWalkIn = $root.infinitusai.be.NullableBool.fromObject(object.allowWalkIn);
                    }
                    if (object.appointmentMethod != null) {
                        if (typeof object.appointmentMethod !== "object")
                            throw TypeError(".infinitusai.be.CovidVaccineAvailabilityCallOutputs.appointmentMethod: object expected");
                        message.appointmentMethod = $root.infinitusai.be.NullableString.fromObject(object.appointmentMethod);
                    }
                    if (object.willRespondToEva != null) {
                        if (typeof object.willRespondToEva !== "object")
                            throw TypeError(".infinitusai.be.CovidVaccineAvailabilityCallOutputs.willRespondToEva: object expected");
                        message.willRespondToEva = $root.infinitusai.be.NullableBool.fromObject(object.willRespondToEva);
                    }
                    if (object.availableForSixtyFivePlus != null) {
                        if (typeof object.availableForSixtyFivePlus !== "object")
                            throw TypeError(".infinitusai.be.CovidVaccineAvailabilityCallOutputs.availableForSixtyFivePlus: object expected");
                        message.availableForSixtyFivePlus = $root.infinitusai.be.NullableBool.fromObject(object.availableForSixtyFivePlus);
                    }
                    if (object.availableForSeventyFivePlus != null) {
                        if (typeof object.availableForSeventyFivePlus !== "object")
                            throw TypeError(".infinitusai.be.CovidVaccineAvailabilityCallOutputs.availableForSeventyFivePlus: object expected");
                        message.availableForSeventyFivePlus = $root.infinitusai.be.NullableBool.fromObject(object.availableForSeventyFivePlus);
                    }
                    if (object.limitedToCountyResidents != null) {
                        if (typeof object.limitedToCountyResidents !== "object")
                            throw TypeError(".infinitusai.be.CovidVaccineAvailabilityCallOutputs.limitedToCountyResidents: object expected");
                        message.limitedToCountyResidents = $root.infinitusai.be.NullableBool.fromObject(object.limitedToCountyResidents);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CovidVaccineAvailabilityCallOutputs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {infinitusai.be.CovidVaccineAvailabilityCallOutputs} message CovidVaccineAvailabilityCallOutputs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CovidVaccineAvailabilityCallOutputs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isVaccineAvailable = null;
                        object.allowWalkIn = null;
                        object.appointmentMethod = null;
                        object.willRespondToEva = null;
                        object.availableForSixtyFivePlus = null;
                        object.availableForSeventyFivePlus = null;
                        object.limitedToCountyResidents = null;
                    }
                    if (message.isVaccineAvailable != null && message.hasOwnProperty("isVaccineAvailable"))
                        object.isVaccineAvailable = $root.infinitusai.be.NullableBool.toObject(message.isVaccineAvailable, options);
                    if (message.allowWalkIn != null && message.hasOwnProperty("allowWalkIn"))
                        object.allowWalkIn = $root.infinitusai.be.NullableBool.toObject(message.allowWalkIn, options);
                    if (message.appointmentMethod != null && message.hasOwnProperty("appointmentMethod"))
                        object.appointmentMethod = $root.infinitusai.be.NullableString.toObject(message.appointmentMethod, options);
                    if (message.willRespondToEva != null && message.hasOwnProperty("willRespondToEva"))
                        object.willRespondToEva = $root.infinitusai.be.NullableBool.toObject(message.willRespondToEva, options);
                    if (message.availableForSixtyFivePlus != null && message.hasOwnProperty("availableForSixtyFivePlus"))
                        object.availableForSixtyFivePlus = $root.infinitusai.be.NullableBool.toObject(message.availableForSixtyFivePlus, options);
                    if (message.availableForSeventyFivePlus != null && message.hasOwnProperty("availableForSeventyFivePlus"))
                        object.availableForSeventyFivePlus = $root.infinitusai.be.NullableBool.toObject(message.availableForSeventyFivePlus, options);
                    if (message.limitedToCountyResidents != null && message.hasOwnProperty("limitedToCountyResidents"))
                        object.limitedToCountyResidents = $root.infinitusai.be.NullableBool.toObject(message.limitedToCountyResidents, options);
                    return object;
                };
    
                /**
                 * Converts this CovidVaccineAvailabilityCallOutputs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CovidVaccineAvailabilityCallOutputs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CovidVaccineAvailabilityCallOutputs
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CovidVaccineAvailabilityCallOutputs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CovidVaccineAvailabilityCallOutputs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CovidVaccineAvailabilityCallOutputs";
                };
    
                return CovidVaccineAvailabilityCallOutputs;
            })();
    
            be.OperatorState = (function() {
    
                /**
                 * Properties of an OperatorState.
                 * @memberof infinitusai.be
                 * @interface IOperatorState
                 * @property {number|Long|null} [lastSeenMillis] OperatorState lastSeenMillis
                 * @property {infinitusai.be.CallRole|null} [callRole] OperatorState callRole
                 */
    
                /**
                 * Constructs a new OperatorState.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorState.
                 * @implements IOperatorState
                 * @constructor
                 * @param {infinitusai.be.IOperatorState=} [properties] Properties to set
                 */
                function OperatorState(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorState lastSeenMillis.
                 * @member {number|Long} lastSeenMillis
                 * @memberof infinitusai.be.OperatorState
                 * @instance
                 */
                OperatorState.prototype.lastSeenMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * OperatorState callRole.
                 * @member {infinitusai.be.CallRole} callRole
                 * @memberof infinitusai.be.OperatorState
                 * @instance
                 */
                OperatorState.prototype.callRole = 0;
    
                /**
                 * Creates a new OperatorState instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {infinitusai.be.IOperatorState=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorState} OperatorState instance
                 */
                OperatorState.create = function create(properties) {
                    return new OperatorState(properties);
                };
    
                /**
                 * Encodes the specified OperatorState message. Does not implicitly {@link infinitusai.be.OperatorState.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {infinitusai.be.IOperatorState} message OperatorState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorState.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.lastSeenMillis != null && Object.hasOwnProperty.call(message, "lastSeenMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.lastSeenMillis);
                    if (message.callRole != null && Object.hasOwnProperty.call(message, "callRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.callRole);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorState message, length delimited. Does not implicitly {@link infinitusai.be.OperatorState.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {infinitusai.be.IOperatorState} message OperatorState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorState.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorState message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorState} OperatorState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorState.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorState();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.lastSeenMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                message.callRole = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorState message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorState} OperatorState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorState.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorState message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorState.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.lastSeenMillis != null && message.hasOwnProperty("lastSeenMillis"))
                        if (!$util.isInteger(message.lastSeenMillis) && !(message.lastSeenMillis && $util.isInteger(message.lastSeenMillis.low) && $util.isInteger(message.lastSeenMillis.high)))
                            return "lastSeenMillis: integer|Long expected";
                    if (message.callRole != null && message.hasOwnProperty("callRole"))
                        switch (message.callRole) {
                        default:
                            return "callRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an OperatorState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorState} OperatorState
                 */
                OperatorState.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorState)
                        return object;
                    var message = new $root.infinitusai.be.OperatorState();
                    if (object.lastSeenMillis != null)
                        if ($util.Long)
                            (message.lastSeenMillis = $util.Long.fromValue(object.lastSeenMillis)).unsigned = false;
                        else if (typeof object.lastSeenMillis === "string")
                            message.lastSeenMillis = parseInt(object.lastSeenMillis, 10);
                        else if (typeof object.lastSeenMillis === "number")
                            message.lastSeenMillis = object.lastSeenMillis;
                        else if (typeof object.lastSeenMillis === "object")
                            message.lastSeenMillis = new $util.LongBits(object.lastSeenMillis.low >>> 0, object.lastSeenMillis.high >>> 0).toNumber();
                    switch (object.callRole) {
                    default:
                        if (typeof object.callRole === "number") {
                            message.callRole = object.callRole;
                            break;
                        }
                        break;
                    case "CALL_ROLE_UNKNOWN":
                    case 0:
                        message.callRole = 0;
                        break;
                    case "CALL_ROLE_CONTROLLER":
                    case 1:
                        message.callRole = 1;
                        break;
                    case "CALL_ROLE_OBSERVER":
                    case 2:
                        message.callRole = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {infinitusai.be.OperatorState} message OperatorState
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorState.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastSeenMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastSeenMillis = options.longs === String ? "0" : 0;
                        object.callRole = options.enums === String ? "CALL_ROLE_UNKNOWN" : 0;
                    }
                    if (message.lastSeenMillis != null && message.hasOwnProperty("lastSeenMillis"))
                        if (typeof message.lastSeenMillis === "number")
                            object.lastSeenMillis = options.longs === String ? String(message.lastSeenMillis) : message.lastSeenMillis;
                        else
                            object.lastSeenMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastSeenMillis) : options.longs === Number ? new $util.LongBits(message.lastSeenMillis.low >>> 0, message.lastSeenMillis.high >>> 0).toNumber() : message.lastSeenMillis;
                    if (message.callRole != null && message.hasOwnProperty("callRole"))
                        object.callRole = options.enums === String ? $root.infinitusai.be.CallRole[message.callRole] === undefined ? message.callRole : $root.infinitusai.be.CallRole[message.callRole] : message.callRole;
                    return object;
                };
    
                /**
                 * Converts this OperatorState to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorState
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorState";
                };
    
                return OperatorState;
            })();
    
            be.OperatorRouteState = (function() {
    
                /**
                 * Properties of an OperatorRouteState.
                 * @memberof infinitusai.be
                 * @interface IOperatorRouteState
                 * @property {number|Long|null} [routedAtMillis] OperatorRouteState routedAtMillis
                 */
    
                /**
                 * Constructs a new OperatorRouteState.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorRouteState.
                 * @implements IOperatorRouteState
                 * @constructor
                 * @param {infinitusai.be.IOperatorRouteState=} [properties] Properties to set
                 */
                function OperatorRouteState(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorRouteState routedAtMillis.
                 * @member {number|Long} routedAtMillis
                 * @memberof infinitusai.be.OperatorRouteState
                 * @instance
                 */
                OperatorRouteState.prototype.routedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new OperatorRouteState instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {infinitusai.be.IOperatorRouteState=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorRouteState} OperatorRouteState instance
                 */
                OperatorRouteState.create = function create(properties) {
                    return new OperatorRouteState(properties);
                };
    
                /**
                 * Encodes the specified OperatorRouteState message. Does not implicitly {@link infinitusai.be.OperatorRouteState.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {infinitusai.be.IOperatorRouteState} message OperatorRouteState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorRouteState.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.routedAtMillis != null && Object.hasOwnProperty.call(message, "routedAtMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.routedAtMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorRouteState message, length delimited. Does not implicitly {@link infinitusai.be.OperatorRouteState.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {infinitusai.be.IOperatorRouteState} message OperatorRouteState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorRouteState.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorRouteState message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorRouteState} OperatorRouteState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorRouteState.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorRouteState();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.routedAtMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorRouteState message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorRouteState} OperatorRouteState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorRouteState.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorRouteState message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorRouteState.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.routedAtMillis != null && message.hasOwnProperty("routedAtMillis"))
                        if (!$util.isInteger(message.routedAtMillis) && !(message.routedAtMillis && $util.isInteger(message.routedAtMillis.low) && $util.isInteger(message.routedAtMillis.high)))
                            return "routedAtMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an OperatorRouteState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorRouteState} OperatorRouteState
                 */
                OperatorRouteState.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorRouteState)
                        return object;
                    var message = new $root.infinitusai.be.OperatorRouteState();
                    if (object.routedAtMillis != null)
                        if ($util.Long)
                            (message.routedAtMillis = $util.Long.fromValue(object.routedAtMillis)).unsigned = false;
                        else if (typeof object.routedAtMillis === "string")
                            message.routedAtMillis = parseInt(object.routedAtMillis, 10);
                        else if (typeof object.routedAtMillis === "number")
                            message.routedAtMillis = object.routedAtMillis;
                        else if (typeof object.routedAtMillis === "object")
                            message.routedAtMillis = new $util.LongBits(object.routedAtMillis.low >>> 0, object.routedAtMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorRouteState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {infinitusai.be.OperatorRouteState} message OperatorRouteState
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorRouteState.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.routedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.routedAtMillis = options.longs === String ? "0" : 0;
                    if (message.routedAtMillis != null && message.hasOwnProperty("routedAtMillis"))
                        if (typeof message.routedAtMillis === "number")
                            object.routedAtMillis = options.longs === String ? String(message.routedAtMillis) : message.routedAtMillis;
                        else
                            object.routedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.routedAtMillis) : options.longs === Number ? new $util.LongBits(message.routedAtMillis.low >>> 0, message.routedAtMillis.high >>> 0).toNumber() : message.routedAtMillis;
                    return object;
                };
    
                /**
                 * Converts this OperatorRouteState to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorRouteState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorRouteState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorRouteState
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorRouteState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorRouteState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorRouteState";
                };
    
                return OperatorRouteState;
            })();
    
            be.TaskTimeRangeTuple = (function() {
    
                /**
                 * Properties of a TaskTimeRangeTuple.
                 * @memberof infinitusai.be
                 * @interface ITaskTimeRangeTuple
                 * @property {number|Long|null} [start] TaskTimeRangeTuple start
                 * @property {number|Long|null} [end] TaskTimeRangeTuple end
                 */
    
                /**
                 * Constructs a new TaskTimeRangeTuple.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TaskTimeRangeTuple.
                 * @implements ITaskTimeRangeTuple
                 * @constructor
                 * @param {infinitusai.be.ITaskTimeRangeTuple=} [properties] Properties to set
                 */
                function TaskTimeRangeTuple(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskTimeRangeTuple start.
                 * @member {number|Long} start
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @instance
                 */
                TaskTimeRangeTuple.prototype.start = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskTimeRangeTuple end.
                 * @member {number|Long} end
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @instance
                 */
                TaskTimeRangeTuple.prototype.end = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new TaskTimeRangeTuple instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {infinitusai.be.ITaskTimeRangeTuple=} [properties] Properties to set
                 * @returns {infinitusai.be.TaskTimeRangeTuple} TaskTimeRangeTuple instance
                 */
                TaskTimeRangeTuple.create = function create(properties) {
                    return new TaskTimeRangeTuple(properties);
                };
    
                /**
                 * Encodes the specified TaskTimeRangeTuple message. Does not implicitly {@link infinitusai.be.TaskTimeRangeTuple.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {infinitusai.be.ITaskTimeRangeTuple} message TaskTimeRangeTuple message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskTimeRangeTuple.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.end);
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskTimeRangeTuple message, length delimited. Does not implicitly {@link infinitusai.be.TaskTimeRangeTuple.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {infinitusai.be.ITaskTimeRangeTuple} message TaskTimeRangeTuple message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskTimeRangeTuple.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskTimeRangeTuple message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TaskTimeRangeTuple} TaskTimeRangeTuple
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskTimeRangeTuple.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskTimeRangeTuple();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.int64();
                                break;
                            }
                        case 2: {
                                message.end = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskTimeRangeTuple message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TaskTimeRangeTuple} TaskTimeRangeTuple
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskTimeRangeTuple.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskTimeRangeTuple message.
                 * @function verify
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskTimeRangeTuple.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start) && !(message.start && $util.isInteger(message.start.low) && $util.isInteger(message.start.high)))
                            return "start: integer|Long expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end) && !(message.end && $util.isInteger(message.end.low) && $util.isInteger(message.end.high)))
                            return "end: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a TaskTimeRangeTuple message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TaskTimeRangeTuple} TaskTimeRangeTuple
                 */
                TaskTimeRangeTuple.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TaskTimeRangeTuple)
                        return object;
                    var message = new $root.infinitusai.be.TaskTimeRangeTuple();
                    if (object.start != null)
                        if ($util.Long)
                            (message.start = $util.Long.fromValue(object.start)).unsigned = false;
                        else if (typeof object.start === "string")
                            message.start = parseInt(object.start, 10);
                        else if (typeof object.start === "number")
                            message.start = object.start;
                        else if (typeof object.start === "object")
                            message.start = new $util.LongBits(object.start.low >>> 0, object.start.high >>> 0).toNumber();
                    if (object.end != null)
                        if ($util.Long)
                            (message.end = $util.Long.fromValue(object.end)).unsigned = false;
                        else if (typeof object.end === "string")
                            message.end = parseInt(object.end, 10);
                        else if (typeof object.end === "number")
                            message.end = object.end;
                        else if (typeof object.end === "object")
                            message.end = new $util.LongBits(object.end.low >>> 0, object.end.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskTimeRangeTuple message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {infinitusai.be.TaskTimeRangeTuple} message TaskTimeRangeTuple
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskTimeRangeTuple.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.start = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.start = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.end = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.end = options.longs === String ? "0" : 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (typeof message.start === "number")
                            object.start = options.longs === String ? String(message.start) : message.start;
                        else
                            object.start = options.longs === String ? $util.Long.prototype.toString.call(message.start) : options.longs === Number ? new $util.LongBits(message.start.low >>> 0, message.start.high >>> 0).toNumber() : message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (typeof message.end === "number")
                            object.end = options.longs === String ? String(message.end) : message.end;
                        else
                            object.end = options.longs === String ? $util.Long.prototype.toString.call(message.end) : options.longs === Number ? new $util.LongBits(message.end.low >>> 0, message.end.high >>> 0).toNumber() : message.end;
                    return object;
                };
    
                /**
                 * Converts this TaskTimeRangeTuple to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskTimeRangeTuple.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskTimeRangeTuple
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TaskTimeRangeTuple
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskTimeRangeTuple.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TaskTimeRangeTuple";
                };
    
                return TaskTimeRangeTuple;
            })();
    
            be.CallManagementDoc = (function() {
    
                /**
                 * Properties of a CallManagementDoc.
                 * @memberof infinitusai.be
                 * @interface ICallManagementDoc
                 * @property {boolean|null} [autoRespondIvr] CallManagementDoc autoRespondIvr
                 * @property {Object.<string,infinitusai.be.IOperatorState>|null} [operators] CallManagementDoc operators
                 * @property {boolean|null} [recordingDisabled] CallManagementDoc recordingDisabled
                 * @property {Object.<string,infinitusai.be.IOperatorRouteState>|null} [operatorsRouted] CallManagementDoc operatorsRouted
                 * @property {boolean|null} [recordingFailed] CallManagementDoc recordingFailed
                 * @property {Array.<string>|null} [taskUuids] CallManagementDoc taskUuids
                 * @property {string|null} [activeTaskUuid] CallManagementDoc activeTaskUuid
                 * @property {boolean|null} [recordingStarted] CallManagementDoc recordingStarted
                 * @property {Object.<string,infinitusai.be.ITaskTimeRangeTuple>|null} [taskTimestamps] CallManagementDoc taskTimestamps
                 * @property {Array.<string>|null} [completedTaskUuids] CallManagementDoc completedTaskUuids
                 */
    
                /**
                 * Constructs a new CallManagementDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallManagementDoc.
                 * @implements ICallManagementDoc
                 * @constructor
                 * @param {infinitusai.be.ICallManagementDoc=} [properties] Properties to set
                 */
                function CallManagementDoc(properties) {
                    this.operators = {};
                    this.operatorsRouted = {};
                    this.taskUuids = [];
                    this.taskTimestamps = {};
                    this.completedTaskUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallManagementDoc autoRespondIvr.
                 * @member {boolean} autoRespondIvr
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.autoRespondIvr = false;
    
                /**
                 * CallManagementDoc operators.
                 * @member {Object.<string,infinitusai.be.IOperatorState>} operators
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.operators = $util.emptyObject;
    
                /**
                 * CallManagementDoc recordingDisabled.
                 * @member {boolean} recordingDisabled
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.recordingDisabled = false;
    
                /**
                 * CallManagementDoc operatorsRouted.
                 * @member {Object.<string,infinitusai.be.IOperatorRouteState>} operatorsRouted
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.operatorsRouted = $util.emptyObject;
    
                /**
                 * CallManagementDoc recordingFailed.
                 * @member {boolean} recordingFailed
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.recordingFailed = false;
    
                /**
                 * CallManagementDoc taskUuids.
                 * @member {Array.<string>} taskUuids
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.taskUuids = $util.emptyArray;
    
                /**
                 * CallManagementDoc activeTaskUuid.
                 * @member {string} activeTaskUuid
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.activeTaskUuid = "";
    
                /**
                 * CallManagementDoc recordingStarted.
                 * @member {boolean} recordingStarted
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.recordingStarted = false;
    
                /**
                 * CallManagementDoc taskTimestamps.
                 * @member {Object.<string,infinitusai.be.ITaskTimeRangeTuple>} taskTimestamps
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.taskTimestamps = $util.emptyObject;
    
                /**
                 * CallManagementDoc completedTaskUuids.
                 * @member {Array.<string>} completedTaskUuids
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 */
                CallManagementDoc.prototype.completedTaskUuids = $util.emptyArray;
    
                /**
                 * Creates a new CallManagementDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {infinitusai.be.ICallManagementDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.CallManagementDoc} CallManagementDoc instance
                 */
                CallManagementDoc.create = function create(properties) {
                    return new CallManagementDoc(properties);
                };
    
                /**
                 * Encodes the specified CallManagementDoc message. Does not implicitly {@link infinitusai.be.CallManagementDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {infinitusai.be.ICallManagementDoc} message CallManagementDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallManagementDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.autoRespondIvr != null && Object.hasOwnProperty.call(message, "autoRespondIvr"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.autoRespondIvr);
                    if (message.operators != null && Object.hasOwnProperty.call(message, "operators"))
                        for (var keys = Object.keys(message.operators), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.OperatorState.encode(message.operators[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.recordingDisabled != null && Object.hasOwnProperty.call(message, "recordingDisabled"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.recordingDisabled);
                    if (message.operatorsRouted != null && Object.hasOwnProperty.call(message, "operatorsRouted"))
                        for (var keys = Object.keys(message.operatorsRouted), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.OperatorRouteState.encode(message.operatorsRouted[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.recordingFailed != null && Object.hasOwnProperty.call(message, "recordingFailed"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.recordingFailed);
                    if (message.taskUuids != null && message.taskUuids.length)
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.taskUuids[i]);
                    if (message.activeTaskUuid != null && Object.hasOwnProperty.call(message, "activeTaskUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.activeTaskUuid);
                    if (message.recordingStarted != null && Object.hasOwnProperty.call(message, "recordingStarted"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.recordingStarted);
                    if (message.taskTimestamps != null && Object.hasOwnProperty.call(message, "taskTimestamps"))
                        for (var keys = Object.keys(message.taskTimestamps), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 9, wireType 2 =*/74).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.TaskTimeRangeTuple.encode(message.taskTimestamps[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.completedTaskUuids != null && message.completedTaskUuids.length)
                        for (var i = 0; i < message.completedTaskUuids.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.completedTaskUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallManagementDoc message, length delimited. Does not implicitly {@link infinitusai.be.CallManagementDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {infinitusai.be.ICallManagementDoc} message CallManagementDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallManagementDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallManagementDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallManagementDoc} CallManagementDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallManagementDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallManagementDoc(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.autoRespondIvr = reader.bool();
                                break;
                            }
                        case 2: {
                                if (message.operators === $util.emptyObject)
                                    message.operators = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.OperatorState.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.operators[key] = value;
                                break;
                            }
                        case 3: {
                                message.recordingDisabled = reader.bool();
                                break;
                            }
                        case 4: {
                                if (message.operatorsRouted === $util.emptyObject)
                                    message.operatorsRouted = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.OperatorRouteState.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.operatorsRouted[key] = value;
                                break;
                            }
                        case 5: {
                                message.recordingFailed = reader.bool();
                                break;
                            }
                        case 6: {
                                if (!(message.taskUuids && message.taskUuids.length))
                                    message.taskUuids = [];
                                message.taskUuids.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.activeTaskUuid = reader.string();
                                break;
                            }
                        case 8: {
                                message.recordingStarted = reader.bool();
                                break;
                            }
                        case 9: {
                                if (message.taskTimestamps === $util.emptyObject)
                                    message.taskTimestamps = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.TaskTimeRangeTuple.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.taskTimestamps[key] = value;
                                break;
                            }
                        case 10: {
                                if (!(message.completedTaskUuids && message.completedTaskUuids.length))
                                    message.completedTaskUuids = [];
                                message.completedTaskUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallManagementDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallManagementDoc} CallManagementDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallManagementDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallManagementDoc message.
                 * @function verify
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallManagementDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.autoRespondIvr != null && message.hasOwnProperty("autoRespondIvr"))
                        if (typeof message.autoRespondIvr !== "boolean")
                            return "autoRespondIvr: boolean expected";
                    if (message.operators != null && message.hasOwnProperty("operators")) {
                        if (!$util.isObject(message.operators))
                            return "operators: object expected";
                        var key = Object.keys(message.operators);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.OperatorState.verify(message.operators[key[i]]);
                            if (error)
                                return "operators." + error;
                        }
                    }
                    if (message.recordingDisabled != null && message.hasOwnProperty("recordingDisabled"))
                        if (typeof message.recordingDisabled !== "boolean")
                            return "recordingDisabled: boolean expected";
                    if (message.operatorsRouted != null && message.hasOwnProperty("operatorsRouted")) {
                        if (!$util.isObject(message.operatorsRouted))
                            return "operatorsRouted: object expected";
                        var key = Object.keys(message.operatorsRouted);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.OperatorRouteState.verify(message.operatorsRouted[key[i]]);
                            if (error)
                                return "operatorsRouted." + error;
                        }
                    }
                    if (message.recordingFailed != null && message.hasOwnProperty("recordingFailed"))
                        if (typeof message.recordingFailed !== "boolean")
                            return "recordingFailed: boolean expected";
                    if (message.taskUuids != null && message.hasOwnProperty("taskUuids")) {
                        if (!Array.isArray(message.taskUuids))
                            return "taskUuids: array expected";
                        for (var i = 0; i < message.taskUuids.length; ++i)
                            if (!$util.isString(message.taskUuids[i]))
                                return "taskUuids: string[] expected";
                    }
                    if (message.activeTaskUuid != null && message.hasOwnProperty("activeTaskUuid"))
                        if (!$util.isString(message.activeTaskUuid))
                            return "activeTaskUuid: string expected";
                    if (message.recordingStarted != null && message.hasOwnProperty("recordingStarted"))
                        if (typeof message.recordingStarted !== "boolean")
                            return "recordingStarted: boolean expected";
                    if (message.taskTimestamps != null && message.hasOwnProperty("taskTimestamps")) {
                        if (!$util.isObject(message.taskTimestamps))
                            return "taskTimestamps: object expected";
                        var key = Object.keys(message.taskTimestamps);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.TaskTimeRangeTuple.verify(message.taskTimestamps[key[i]]);
                            if (error)
                                return "taskTimestamps." + error;
                        }
                    }
                    if (message.completedTaskUuids != null && message.hasOwnProperty("completedTaskUuids")) {
                        if (!Array.isArray(message.completedTaskUuids))
                            return "completedTaskUuids: array expected";
                        for (var i = 0; i < message.completedTaskUuids.length; ++i)
                            if (!$util.isString(message.completedTaskUuids[i]))
                                return "completedTaskUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a CallManagementDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallManagementDoc} CallManagementDoc
                 */
                CallManagementDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallManagementDoc)
                        return object;
                    var message = new $root.infinitusai.be.CallManagementDoc();
                    if (object.autoRespondIvr != null)
                        message.autoRespondIvr = Boolean(object.autoRespondIvr);
                    if (object.operators) {
                        if (typeof object.operators !== "object")
                            throw TypeError(".infinitusai.be.CallManagementDoc.operators: object expected");
                        message.operators = {};
                        for (var keys = Object.keys(object.operators), i = 0; i < keys.length; ++i) {
                            if (typeof object.operators[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.CallManagementDoc.operators: object expected");
                            message.operators[keys[i]] = $root.infinitusai.be.OperatorState.fromObject(object.operators[keys[i]]);
                        }
                    }
                    if (object.recordingDisabled != null)
                        message.recordingDisabled = Boolean(object.recordingDisabled);
                    if (object.operatorsRouted) {
                        if (typeof object.operatorsRouted !== "object")
                            throw TypeError(".infinitusai.be.CallManagementDoc.operatorsRouted: object expected");
                        message.operatorsRouted = {};
                        for (var keys = Object.keys(object.operatorsRouted), i = 0; i < keys.length; ++i) {
                            if (typeof object.operatorsRouted[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.CallManagementDoc.operatorsRouted: object expected");
                            message.operatorsRouted[keys[i]] = $root.infinitusai.be.OperatorRouteState.fromObject(object.operatorsRouted[keys[i]]);
                        }
                    }
                    if (object.recordingFailed != null)
                        message.recordingFailed = Boolean(object.recordingFailed);
                    if (object.taskUuids) {
                        if (!Array.isArray(object.taskUuids))
                            throw TypeError(".infinitusai.be.CallManagementDoc.taskUuids: array expected");
                        message.taskUuids = [];
                        for (var i = 0; i < object.taskUuids.length; ++i)
                            message.taskUuids[i] = String(object.taskUuids[i]);
                    }
                    if (object.activeTaskUuid != null)
                        message.activeTaskUuid = String(object.activeTaskUuid);
                    if (object.recordingStarted != null)
                        message.recordingStarted = Boolean(object.recordingStarted);
                    if (object.taskTimestamps) {
                        if (typeof object.taskTimestamps !== "object")
                            throw TypeError(".infinitusai.be.CallManagementDoc.taskTimestamps: object expected");
                        message.taskTimestamps = {};
                        for (var keys = Object.keys(object.taskTimestamps), i = 0; i < keys.length; ++i) {
                            if (typeof object.taskTimestamps[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.CallManagementDoc.taskTimestamps: object expected");
                            message.taskTimestamps[keys[i]] = $root.infinitusai.be.TaskTimeRangeTuple.fromObject(object.taskTimestamps[keys[i]]);
                        }
                    }
                    if (object.completedTaskUuids) {
                        if (!Array.isArray(object.completedTaskUuids))
                            throw TypeError(".infinitusai.be.CallManagementDoc.completedTaskUuids: array expected");
                        message.completedTaskUuids = [];
                        for (var i = 0; i < object.completedTaskUuids.length; ++i)
                            message.completedTaskUuids[i] = String(object.completedTaskUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallManagementDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {infinitusai.be.CallManagementDoc} message CallManagementDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallManagementDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.taskUuids = [];
                        object.completedTaskUuids = [];
                    }
                    if (options.objects || options.defaults) {
                        object.operators = {};
                        object.operatorsRouted = {};
                        object.taskTimestamps = {};
                    }
                    if (options.defaults) {
                        object.autoRespondIvr = false;
                        object.recordingDisabled = false;
                        object.recordingFailed = false;
                        object.activeTaskUuid = "";
                        object.recordingStarted = false;
                    }
                    if (message.autoRespondIvr != null && message.hasOwnProperty("autoRespondIvr"))
                        object.autoRespondIvr = message.autoRespondIvr;
                    var keys2;
                    if (message.operators && (keys2 = Object.keys(message.operators)).length) {
                        object.operators = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.operators[keys2[j]] = $root.infinitusai.be.OperatorState.toObject(message.operators[keys2[j]], options);
                    }
                    if (message.recordingDisabled != null && message.hasOwnProperty("recordingDisabled"))
                        object.recordingDisabled = message.recordingDisabled;
                    if (message.operatorsRouted && (keys2 = Object.keys(message.operatorsRouted)).length) {
                        object.operatorsRouted = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.operatorsRouted[keys2[j]] = $root.infinitusai.be.OperatorRouteState.toObject(message.operatorsRouted[keys2[j]], options);
                    }
                    if (message.recordingFailed != null && message.hasOwnProperty("recordingFailed"))
                        object.recordingFailed = message.recordingFailed;
                    if (message.taskUuids && message.taskUuids.length) {
                        object.taskUuids = [];
                        for (var j = 0; j < message.taskUuids.length; ++j)
                            object.taskUuids[j] = message.taskUuids[j];
                    }
                    if (message.activeTaskUuid != null && message.hasOwnProperty("activeTaskUuid"))
                        object.activeTaskUuid = message.activeTaskUuid;
                    if (message.recordingStarted != null && message.hasOwnProperty("recordingStarted"))
                        object.recordingStarted = message.recordingStarted;
                    if (message.taskTimestamps && (keys2 = Object.keys(message.taskTimestamps)).length) {
                        object.taskTimestamps = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.taskTimestamps[keys2[j]] = $root.infinitusai.be.TaskTimeRangeTuple.toObject(message.taskTimestamps[keys2[j]], options);
                    }
                    if (message.completedTaskUuids && message.completedTaskUuids.length) {
                        object.completedTaskUuids = [];
                        for (var j = 0; j < message.completedTaskUuids.length; ++j)
                            object.completedTaskUuids[j] = message.completedTaskUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this CallManagementDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallManagementDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallManagementDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallManagementDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallManagementDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallManagementDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallManagementDoc";
                };
    
                return CallManagementDoc;
            })();
    
            be.CallMonitorUpdateRequest = (function() {
    
                /**
                 * Properties of a CallMonitorUpdateRequest.
                 * @memberof infinitusai.be
                 * @interface ICallMonitorUpdateRequest
                 * @property {string|null} [taskUuid] CallMonitorUpdateRequest taskUuid
                 * @property {string|null} [callUuid] CallMonitorUpdateRequest callUuid
                 * @property {boolean|null} [autoRespondIvr] CallMonitorUpdateRequest autoRespondIvr
                 * @property {infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY|null} [recordingEnabled] CallMonitorUpdateRequest recordingEnabled
                 * @property {infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY|null} [autoRespondIvrV2] CallMonitorUpdateRequest autoRespondIvrV2
                 */
    
                /**
                 * Constructs a new CallMonitorUpdateRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallMonitorUpdateRequest.
                 * @implements ICallMonitorUpdateRequest
                 * @constructor
                 * @param {infinitusai.be.ICallMonitorUpdateRequest=} [properties] Properties to set
                 */
                function CallMonitorUpdateRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallMonitorUpdateRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @instance
                 */
                CallMonitorUpdateRequest.prototype.taskUuid = "";
    
                /**
                 * CallMonitorUpdateRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @instance
                 */
                CallMonitorUpdateRequest.prototype.callUuid = "";
    
                /**
                 * CallMonitorUpdateRequest autoRespondIvr.
                 * @member {boolean} autoRespondIvr
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @instance
                 */
                CallMonitorUpdateRequest.prototype.autoRespondIvr = false;
    
                /**
                 * CallMonitorUpdateRequest recordingEnabled.
                 * @member {infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY} recordingEnabled
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @instance
                 */
                CallMonitorUpdateRequest.prototype.recordingEnabled = 0;
    
                /**
                 * CallMonitorUpdateRequest autoRespondIvrV2.
                 * @member {infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY} autoRespondIvrV2
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @instance
                 */
                CallMonitorUpdateRequest.prototype.autoRespondIvrV2 = 0;
    
                /**
                 * Creates a new CallMonitorUpdateRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {infinitusai.be.ICallMonitorUpdateRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CallMonitorUpdateRequest} CallMonitorUpdateRequest instance
                 */
                CallMonitorUpdateRequest.create = function create(properties) {
                    return new CallMonitorUpdateRequest(properties);
                };
    
                /**
                 * Encodes the specified CallMonitorUpdateRequest message. Does not implicitly {@link infinitusai.be.CallMonitorUpdateRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {infinitusai.be.ICallMonitorUpdateRequest} message CallMonitorUpdateRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallMonitorUpdateRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.autoRespondIvr != null && Object.hasOwnProperty.call(message, "autoRespondIvr"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.autoRespondIvr);
                    if (message.recordingEnabled != null && Object.hasOwnProperty.call(message, "recordingEnabled"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.recordingEnabled);
                    if (message.autoRespondIvrV2 != null && Object.hasOwnProperty.call(message, "autoRespondIvrV2"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.autoRespondIvrV2);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallMonitorUpdateRequest message, length delimited. Does not implicitly {@link infinitusai.be.CallMonitorUpdateRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {infinitusai.be.ICallMonitorUpdateRequest} message CallMonitorUpdateRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallMonitorUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallMonitorUpdateRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallMonitorUpdateRequest} CallMonitorUpdateRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallMonitorUpdateRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallMonitorUpdateRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.autoRespondIvr = reader.bool();
                                break;
                            }
                        case 4: {
                                message.recordingEnabled = reader.int32();
                                break;
                            }
                        case 5: {
                                message.autoRespondIvrV2 = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallMonitorUpdateRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallMonitorUpdateRequest} CallMonitorUpdateRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallMonitorUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallMonitorUpdateRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallMonitorUpdateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.autoRespondIvr != null && message.hasOwnProperty("autoRespondIvr"))
                        if (typeof message.autoRespondIvr !== "boolean")
                            return "autoRespondIvr: boolean expected";
                    if (message.recordingEnabled != null && message.hasOwnProperty("recordingEnabled"))
                        switch (message.recordingEnabled) {
                        default:
                            return "recordingEnabled: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.autoRespondIvrV2 != null && message.hasOwnProperty("autoRespondIvrV2"))
                        switch (message.autoRespondIvrV2) {
                        default:
                            return "autoRespondIvrV2: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a CallMonitorUpdateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallMonitorUpdateRequest} CallMonitorUpdateRequest
                 */
                CallMonitorUpdateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallMonitorUpdateRequest)
                        return object;
                    var message = new $root.infinitusai.be.CallMonitorUpdateRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.autoRespondIvr != null)
                        message.autoRespondIvr = Boolean(object.autoRespondIvr);
                    switch (object.recordingEnabled) {
                    default:
                        if (typeof object.recordingEnabled === "number") {
                            message.recordingEnabled = object.recordingEnabled;
                            break;
                        }
                        break;
                    case "EMPTY":
                    case 0:
                        message.recordingEnabled = 0;
                        break;
                    case "FALSE":
                    case 1:
                        message.recordingEnabled = 1;
                        break;
                    case "TRUE":
                    case 2:
                        message.recordingEnabled = 2;
                        break;
                    }
                    switch (object.autoRespondIvrV2) {
                    default:
                        if (typeof object.autoRespondIvrV2 === "number") {
                            message.autoRespondIvrV2 = object.autoRespondIvrV2;
                            break;
                        }
                        break;
                    case "EMPTY":
                    case 0:
                        message.autoRespondIvrV2 = 0;
                        break;
                    case "FALSE":
                    case 1:
                        message.autoRespondIvrV2 = 1;
                        break;
                    case "TRUE":
                    case 2:
                        message.autoRespondIvrV2 = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallMonitorUpdateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {infinitusai.be.CallMonitorUpdateRequest} message CallMonitorUpdateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallMonitorUpdateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.autoRespondIvr = false;
                        object.recordingEnabled = options.enums === String ? "EMPTY" : 0;
                        object.autoRespondIvrV2 = options.enums === String ? "EMPTY" : 0;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.autoRespondIvr != null && message.hasOwnProperty("autoRespondIvr"))
                        object.autoRespondIvr = message.autoRespondIvr;
                    if (message.recordingEnabled != null && message.hasOwnProperty("recordingEnabled"))
                        object.recordingEnabled = options.enums === String ? $root.infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY[message.recordingEnabled] === undefined ? message.recordingEnabled : $root.infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY[message.recordingEnabled] : message.recordingEnabled;
                    if (message.autoRespondIvrV2 != null && message.hasOwnProperty("autoRespondIvrV2"))
                        object.autoRespondIvrV2 = options.enums === String ? $root.infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY[message.autoRespondIvrV2] === undefined ? message.autoRespondIvrV2 : $root.infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY[message.autoRespondIvrV2] : message.autoRespondIvrV2;
                    return object;
                };
    
                /**
                 * Converts this CallMonitorUpdateRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallMonitorUpdateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallMonitorUpdateRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallMonitorUpdateRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallMonitorUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallMonitorUpdateRequest";
                };
    
                /**
                 * BOOL_OR_EMPTY enum.
                 * @name infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY
                 * @enum {number}
                 * @property {number} EMPTY=0 EMPTY value
                 * @property {number} FALSE=1 FALSE value
                 * @property {number} TRUE=2 TRUE value
                 */
                CallMonitorUpdateRequest.BOOL_OR_EMPTY = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "EMPTY"] = 0;
                    values[valuesById[1] = "FALSE"] = 1;
                    values[valuesById[2] = "TRUE"] = 2;
                    return values;
                })();
    
                return CallMonitorUpdateRequest;
            })();
    
            be.CallMonitorUpdateResponse = (function() {
    
                /**
                 * Properties of a CallMonitorUpdateResponse.
                 * @memberof infinitusai.be
                 * @interface ICallMonitorUpdateResponse
                 */
    
                /**
                 * Constructs a new CallMonitorUpdateResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallMonitorUpdateResponse.
                 * @implements ICallMonitorUpdateResponse
                 * @constructor
                 * @param {infinitusai.be.ICallMonitorUpdateResponse=} [properties] Properties to set
                 */
                function CallMonitorUpdateResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CallMonitorUpdateResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {infinitusai.be.ICallMonitorUpdateResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CallMonitorUpdateResponse} CallMonitorUpdateResponse instance
                 */
                CallMonitorUpdateResponse.create = function create(properties) {
                    return new CallMonitorUpdateResponse(properties);
                };
    
                /**
                 * Encodes the specified CallMonitorUpdateResponse message. Does not implicitly {@link infinitusai.be.CallMonitorUpdateResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {infinitusai.be.ICallMonitorUpdateResponse} message CallMonitorUpdateResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallMonitorUpdateResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallMonitorUpdateResponse message, length delimited. Does not implicitly {@link infinitusai.be.CallMonitorUpdateResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {infinitusai.be.ICallMonitorUpdateResponse} message CallMonitorUpdateResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallMonitorUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallMonitorUpdateResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallMonitorUpdateResponse} CallMonitorUpdateResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallMonitorUpdateResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallMonitorUpdateResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallMonitorUpdateResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallMonitorUpdateResponse} CallMonitorUpdateResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallMonitorUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallMonitorUpdateResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallMonitorUpdateResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CallMonitorUpdateResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallMonitorUpdateResponse} CallMonitorUpdateResponse
                 */
                CallMonitorUpdateResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallMonitorUpdateResponse)
                        return object;
                    return new $root.infinitusai.be.CallMonitorUpdateResponse();
                };
    
                /**
                 * Creates a plain object from a CallMonitorUpdateResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {infinitusai.be.CallMonitorUpdateResponse} message CallMonitorUpdateResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallMonitorUpdateResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CallMonitorUpdateResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallMonitorUpdateResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallMonitorUpdateResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallMonitorUpdateResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallMonitorUpdateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallMonitorUpdateResponse";
                };
    
                return CallMonitorUpdateResponse;
            })();
    
            be.CallRecordingChangeRequest = (function() {
    
                /**
                 * Properties of a CallRecordingChangeRequest.
                 * @memberof infinitusai.be
                 * @interface ICallRecordingChangeRequest
                 * @property {string|null} [taskUuid] CallRecordingChangeRequest taskUuid
                 * @property {string|null} [callUuid] CallRecordingChangeRequest callUuid
                 * @property {boolean|null} [recordingDisabled] CallRecordingChangeRequest recordingDisabled
                 */
    
                /**
                 * Constructs a new CallRecordingChangeRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallRecordingChangeRequest.
                 * @implements ICallRecordingChangeRequest
                 * @constructor
                 * @param {infinitusai.be.ICallRecordingChangeRequest=} [properties] Properties to set
                 */
                function CallRecordingChangeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallRecordingChangeRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @instance
                 */
                CallRecordingChangeRequest.prototype.taskUuid = "";
    
                /**
                 * CallRecordingChangeRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @instance
                 */
                CallRecordingChangeRequest.prototype.callUuid = "";
    
                /**
                 * CallRecordingChangeRequest recordingDisabled.
                 * @member {boolean} recordingDisabled
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @instance
                 */
                CallRecordingChangeRequest.prototype.recordingDisabled = false;
    
                /**
                 * Creates a new CallRecordingChangeRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {infinitusai.be.ICallRecordingChangeRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CallRecordingChangeRequest} CallRecordingChangeRequest instance
                 */
                CallRecordingChangeRequest.create = function create(properties) {
                    return new CallRecordingChangeRequest(properties);
                };
    
                /**
                 * Encodes the specified CallRecordingChangeRequest message. Does not implicitly {@link infinitusai.be.CallRecordingChangeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {infinitusai.be.ICallRecordingChangeRequest} message CallRecordingChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallRecordingChangeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.recordingDisabled != null && Object.hasOwnProperty.call(message, "recordingDisabled"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.recordingDisabled);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallRecordingChangeRequest message, length delimited. Does not implicitly {@link infinitusai.be.CallRecordingChangeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {infinitusai.be.ICallRecordingChangeRequest} message CallRecordingChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallRecordingChangeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallRecordingChangeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallRecordingChangeRequest} CallRecordingChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallRecordingChangeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallRecordingChangeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.recordingDisabled = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallRecordingChangeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallRecordingChangeRequest} CallRecordingChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallRecordingChangeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallRecordingChangeRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallRecordingChangeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.recordingDisabled != null && message.hasOwnProperty("recordingDisabled"))
                        if (typeof message.recordingDisabled !== "boolean")
                            return "recordingDisabled: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a CallRecordingChangeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallRecordingChangeRequest} CallRecordingChangeRequest
                 */
                CallRecordingChangeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallRecordingChangeRequest)
                        return object;
                    var message = new $root.infinitusai.be.CallRecordingChangeRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.recordingDisabled != null)
                        message.recordingDisabled = Boolean(object.recordingDisabled);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallRecordingChangeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {infinitusai.be.CallRecordingChangeRequest} message CallRecordingChangeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallRecordingChangeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.recordingDisabled = false;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.recordingDisabled != null && message.hasOwnProperty("recordingDisabled"))
                        object.recordingDisabled = message.recordingDisabled;
                    return object;
                };
    
                /**
                 * Converts this CallRecordingChangeRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallRecordingChangeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallRecordingChangeRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallRecordingChangeRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallRecordingChangeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallRecordingChangeRequest";
                };
    
                return CallRecordingChangeRequest;
            })();
    
            be.CallRecordingChangeResponse = (function() {
    
                /**
                 * Properties of a CallRecordingChangeResponse.
                 * @memberof infinitusai.be
                 * @interface ICallRecordingChangeResponse
                 */
    
                /**
                 * Constructs a new CallRecordingChangeResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallRecordingChangeResponse.
                 * @implements ICallRecordingChangeResponse
                 * @constructor
                 * @param {infinitusai.be.ICallRecordingChangeResponse=} [properties] Properties to set
                 */
                function CallRecordingChangeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CallRecordingChangeResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {infinitusai.be.ICallRecordingChangeResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CallRecordingChangeResponse} CallRecordingChangeResponse instance
                 */
                CallRecordingChangeResponse.create = function create(properties) {
                    return new CallRecordingChangeResponse(properties);
                };
    
                /**
                 * Encodes the specified CallRecordingChangeResponse message. Does not implicitly {@link infinitusai.be.CallRecordingChangeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {infinitusai.be.ICallRecordingChangeResponse} message CallRecordingChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallRecordingChangeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallRecordingChangeResponse message, length delimited. Does not implicitly {@link infinitusai.be.CallRecordingChangeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {infinitusai.be.ICallRecordingChangeResponse} message CallRecordingChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallRecordingChangeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallRecordingChangeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallRecordingChangeResponse} CallRecordingChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallRecordingChangeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallRecordingChangeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallRecordingChangeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallRecordingChangeResponse} CallRecordingChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallRecordingChangeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallRecordingChangeResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallRecordingChangeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CallRecordingChangeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallRecordingChangeResponse} CallRecordingChangeResponse
                 */
                CallRecordingChangeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallRecordingChangeResponse)
                        return object;
                    return new $root.infinitusai.be.CallRecordingChangeResponse();
                };
    
                /**
                 * Creates a plain object from a CallRecordingChangeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {infinitusai.be.CallRecordingChangeResponse} message CallRecordingChangeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallRecordingChangeResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CallRecordingChangeResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallRecordingChangeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallRecordingChangeResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallRecordingChangeResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallRecordingChangeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallRecordingChangeResponse";
                };
    
                return CallRecordingChangeResponse;
            })();
    
            be.RegisterOperatorRequest = (function() {
    
                /**
                 * Properties of a RegisterOperatorRequest.
                 * @memberof infinitusai.be
                 * @interface IRegisterOperatorRequest
                 * @property {string|null} [taskUuid] RegisterOperatorRequest taskUuid
                 * @property {string|null} [callUuid] RegisterOperatorRequest callUuid
                 * @property {string|null} [orgUuid] RegisterOperatorRequest orgUuid
                 * @property {infinitusai.be.IAuthServerMessage|null} [authData] RegisterOperatorRequest authData
                 * @property {infinitusai.be.CallRole|null} [callRole] RegisterOperatorRequest callRole
                 */
    
                /**
                 * Constructs a new RegisterOperatorRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RegisterOperatorRequest.
                 * @implements IRegisterOperatorRequest
                 * @constructor
                 * @param {infinitusai.be.IRegisterOperatorRequest=} [properties] Properties to set
                 */
                function RegisterOperatorRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RegisterOperatorRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @instance
                 */
                RegisterOperatorRequest.prototype.taskUuid = "";
    
                /**
                 * RegisterOperatorRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @instance
                 */
                RegisterOperatorRequest.prototype.callUuid = "";
    
                /**
                 * RegisterOperatorRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @instance
                 */
                RegisterOperatorRequest.prototype.orgUuid = "";
    
                /**
                 * RegisterOperatorRequest authData.
                 * @member {infinitusai.be.IAuthServerMessage|null|undefined} authData
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @instance
                 */
                RegisterOperatorRequest.prototype.authData = null;
    
                /**
                 * RegisterOperatorRequest callRole.
                 * @member {infinitusai.be.CallRole} callRole
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @instance
                 */
                RegisterOperatorRequest.prototype.callRole = 0;
    
                /**
                 * Creates a new RegisterOperatorRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {infinitusai.be.IRegisterOperatorRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RegisterOperatorRequest} RegisterOperatorRequest instance
                 */
                RegisterOperatorRequest.create = function create(properties) {
                    return new RegisterOperatorRequest(properties);
                };
    
                /**
                 * Encodes the specified RegisterOperatorRequest message. Does not implicitly {@link infinitusai.be.RegisterOperatorRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {infinitusai.be.IRegisterOperatorRequest} message RegisterOperatorRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RegisterOperatorRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.authData != null && Object.hasOwnProperty.call(message, "authData"))
                        $root.infinitusai.be.AuthServerMessage.encode(message.authData, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.callRole != null && Object.hasOwnProperty.call(message, "callRole"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.callRole);
                    return writer;
                };
    
                /**
                 * Encodes the specified RegisterOperatorRequest message, length delimited. Does not implicitly {@link infinitusai.be.RegisterOperatorRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {infinitusai.be.IRegisterOperatorRequest} message RegisterOperatorRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RegisterOperatorRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RegisterOperatorRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RegisterOperatorRequest} RegisterOperatorRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RegisterOperatorRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RegisterOperatorRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.authData = $root.infinitusai.be.AuthServerMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.callRole = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RegisterOperatorRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RegisterOperatorRequest} RegisterOperatorRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RegisterOperatorRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RegisterOperatorRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RegisterOperatorRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.authData != null && message.hasOwnProperty("authData")) {
                        var error = $root.infinitusai.be.AuthServerMessage.verify(message.authData);
                        if (error)
                            return "authData." + error;
                    }
                    if (message.callRole != null && message.hasOwnProperty("callRole"))
                        switch (message.callRole) {
                        default:
                            return "callRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a RegisterOperatorRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RegisterOperatorRequest} RegisterOperatorRequest
                 */
                RegisterOperatorRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RegisterOperatorRequest)
                        return object;
                    var message = new $root.infinitusai.be.RegisterOperatorRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.authData != null) {
                        if (typeof object.authData !== "object")
                            throw TypeError(".infinitusai.be.RegisterOperatorRequest.authData: object expected");
                        message.authData = $root.infinitusai.be.AuthServerMessage.fromObject(object.authData);
                    }
                    switch (object.callRole) {
                    default:
                        if (typeof object.callRole === "number") {
                            message.callRole = object.callRole;
                            break;
                        }
                        break;
                    case "CALL_ROLE_UNKNOWN":
                    case 0:
                        message.callRole = 0;
                        break;
                    case "CALL_ROLE_CONTROLLER":
                    case 1:
                        message.callRole = 1;
                        break;
                    case "CALL_ROLE_OBSERVER":
                    case 2:
                        message.callRole = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RegisterOperatorRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {infinitusai.be.RegisterOperatorRequest} message RegisterOperatorRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RegisterOperatorRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.orgUuid = "";
                        object.authData = null;
                        object.callRole = options.enums === String ? "CALL_ROLE_UNKNOWN" : 0;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.authData != null && message.hasOwnProperty("authData"))
                        object.authData = $root.infinitusai.be.AuthServerMessage.toObject(message.authData, options);
                    if (message.callRole != null && message.hasOwnProperty("callRole"))
                        object.callRole = options.enums === String ? $root.infinitusai.be.CallRole[message.callRole] === undefined ? message.callRole : $root.infinitusai.be.CallRole[message.callRole] : message.callRole;
                    return object;
                };
    
                /**
                 * Converts this RegisterOperatorRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RegisterOperatorRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RegisterOperatorRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RegisterOperatorRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RegisterOperatorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RegisterOperatorRequest";
                };
    
                return RegisterOperatorRequest;
            })();
    
            be.RegisterOperatorResponse = (function() {
    
                /**
                 * Properties of a RegisterOperatorResponse.
                 * @memberof infinitusai.be
                 * @interface IRegisterOperatorResponse
                 */
    
                /**
                 * Constructs a new RegisterOperatorResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RegisterOperatorResponse.
                 * @implements IRegisterOperatorResponse
                 * @constructor
                 * @param {infinitusai.be.IRegisterOperatorResponse=} [properties] Properties to set
                 */
                function RegisterOperatorResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RegisterOperatorResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {infinitusai.be.IRegisterOperatorResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RegisterOperatorResponse} RegisterOperatorResponse instance
                 */
                RegisterOperatorResponse.create = function create(properties) {
                    return new RegisterOperatorResponse(properties);
                };
    
                /**
                 * Encodes the specified RegisterOperatorResponse message. Does not implicitly {@link infinitusai.be.RegisterOperatorResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {infinitusai.be.IRegisterOperatorResponse} message RegisterOperatorResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RegisterOperatorResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RegisterOperatorResponse message, length delimited. Does not implicitly {@link infinitusai.be.RegisterOperatorResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {infinitusai.be.IRegisterOperatorResponse} message RegisterOperatorResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RegisterOperatorResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RegisterOperatorResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RegisterOperatorResponse} RegisterOperatorResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RegisterOperatorResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RegisterOperatorResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RegisterOperatorResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RegisterOperatorResponse} RegisterOperatorResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RegisterOperatorResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RegisterOperatorResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RegisterOperatorResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RegisterOperatorResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RegisterOperatorResponse} RegisterOperatorResponse
                 */
                RegisterOperatorResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RegisterOperatorResponse)
                        return object;
                    return new $root.infinitusai.be.RegisterOperatorResponse();
                };
    
                /**
                 * Creates a plain object from a RegisterOperatorResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {infinitusai.be.RegisterOperatorResponse} message RegisterOperatorResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RegisterOperatorResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RegisterOperatorResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RegisterOperatorResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RegisterOperatorResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RegisterOperatorResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RegisterOperatorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RegisterOperatorResponse";
                };
    
                return RegisterOperatorResponse;
            })();
    
            be.OperatorCallPresence = (function() {
    
                /**
                 * Constructs a new OperatorCallPresence service.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorCallPresence
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function OperatorCallPresence(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (OperatorCallPresence.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OperatorCallPresence;
    
                /**
                 * Creates new OperatorCallPresence service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.OperatorCallPresence
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {OperatorCallPresence} RPC service. Useful where requests and/or responses are streamed.
                 */
                OperatorCallPresence.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.OperatorCallPresence#registerOperatorPresenceOnActiveCall}.
                 * @memberof infinitusai.be.OperatorCallPresence
                 * @typedef RegisterOperatorPresenceOnActiveCallCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.RegisterOperatorResponse} [response] RegisterOperatorResponse
                 */
    
                /**
                 * Calls RegisterOperatorPresenceOnActiveCall.
                 * @function registerOperatorPresenceOnActiveCall
                 * @memberof infinitusai.be.OperatorCallPresence
                 * @instance
                 * @param {infinitusai.be.IRegisterOperatorRequest} request RegisterOperatorRequest message or plain object
                 * @param {infinitusai.be.OperatorCallPresence.RegisterOperatorPresenceOnActiveCallCallback} callback Node-style callback called with the error, if any, and RegisterOperatorResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OperatorCallPresence.prototype.registerOperatorPresenceOnActiveCall = function registerOperatorPresenceOnActiveCall(request, callback) {
                    return this.rpcCall(registerOperatorPresenceOnActiveCall, $root.infinitusai.be.RegisterOperatorRequest, $root.infinitusai.be.RegisterOperatorResponse, request, callback);
                }, "name", { value: "RegisterOperatorPresenceOnActiveCall" });
    
                /**
                 * Calls RegisterOperatorPresenceOnActiveCall.
                 * @function registerOperatorPresenceOnActiveCall
                 * @memberof infinitusai.be.OperatorCallPresence
                 * @instance
                 * @param {infinitusai.be.IRegisterOperatorRequest} request RegisterOperatorRequest message or plain object
                 * @returns {Promise<infinitusai.be.RegisterOperatorResponse>} Promise
                 * @variation 2
                 */
    
                return OperatorCallPresence;
            })();
    
            be.CacheGreetingRequest = (function() {
    
                /**
                 * Properties of a CacheGreetingRequest.
                 * @memberof infinitusai.be
                 * @interface ICacheGreetingRequest
                 * @property {string|null} [orgUuid] CacheGreetingRequest orgUuid
                 * @property {string|null} [operatorEmail] CacheGreetingRequest operatorEmail
                 */
    
                /**
                 * Constructs a new CacheGreetingRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CacheGreetingRequest.
                 * @implements ICacheGreetingRequest
                 * @constructor
                 * @param {infinitusai.be.ICacheGreetingRequest=} [properties] Properties to set
                 */
                function CacheGreetingRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CacheGreetingRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @instance
                 */
                CacheGreetingRequest.prototype.orgUuid = "";
    
                /**
                 * CacheGreetingRequest operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @instance
                 */
                CacheGreetingRequest.prototype.operatorEmail = "";
    
                /**
                 * Creates a new CacheGreetingRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {infinitusai.be.ICacheGreetingRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CacheGreetingRequest} CacheGreetingRequest instance
                 */
                CacheGreetingRequest.create = function create(properties) {
                    return new CacheGreetingRequest(properties);
                };
    
                /**
                 * Encodes the specified CacheGreetingRequest message. Does not implicitly {@link infinitusai.be.CacheGreetingRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {infinitusai.be.ICacheGreetingRequest} message CacheGreetingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CacheGreetingRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.operatorEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified CacheGreetingRequest message, length delimited. Does not implicitly {@link infinitusai.be.CacheGreetingRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {infinitusai.be.ICacheGreetingRequest} message CacheGreetingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CacheGreetingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CacheGreetingRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CacheGreetingRequest} CacheGreetingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CacheGreetingRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CacheGreetingRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CacheGreetingRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CacheGreetingRequest} CacheGreetingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CacheGreetingRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CacheGreetingRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CacheGreetingRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a CacheGreetingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CacheGreetingRequest} CacheGreetingRequest
                 */
                CacheGreetingRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CacheGreetingRequest)
                        return object;
                    var message = new $root.infinitusai.be.CacheGreetingRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CacheGreetingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {infinitusai.be.CacheGreetingRequest} message CacheGreetingRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CacheGreetingRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.operatorEmail = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    return object;
                };
    
                /**
                 * Converts this CacheGreetingRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CacheGreetingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CacheGreetingRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CacheGreetingRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CacheGreetingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CacheGreetingRequest";
                };
    
                return CacheGreetingRequest;
            })();
    
            be.CacheGreetingResponse = (function() {
    
                /**
                 * Properties of a CacheGreetingResponse.
                 * @memberof infinitusai.be
                 * @interface ICacheGreetingResponse
                 */
    
                /**
                 * Constructs a new CacheGreetingResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CacheGreetingResponse.
                 * @implements ICacheGreetingResponse
                 * @constructor
                 * @param {infinitusai.be.ICacheGreetingResponse=} [properties] Properties to set
                 */
                function CacheGreetingResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CacheGreetingResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {infinitusai.be.ICacheGreetingResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CacheGreetingResponse} CacheGreetingResponse instance
                 */
                CacheGreetingResponse.create = function create(properties) {
                    return new CacheGreetingResponse(properties);
                };
    
                /**
                 * Encodes the specified CacheGreetingResponse message. Does not implicitly {@link infinitusai.be.CacheGreetingResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {infinitusai.be.ICacheGreetingResponse} message CacheGreetingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CacheGreetingResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CacheGreetingResponse message, length delimited. Does not implicitly {@link infinitusai.be.CacheGreetingResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {infinitusai.be.ICacheGreetingResponse} message CacheGreetingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CacheGreetingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CacheGreetingResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CacheGreetingResponse} CacheGreetingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CacheGreetingResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CacheGreetingResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CacheGreetingResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CacheGreetingResponse} CacheGreetingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CacheGreetingResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CacheGreetingResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CacheGreetingResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CacheGreetingResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CacheGreetingResponse} CacheGreetingResponse
                 */
                CacheGreetingResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CacheGreetingResponse)
                        return object;
                    return new $root.infinitusai.be.CacheGreetingResponse();
                };
    
                /**
                 * Creates a plain object from a CacheGreetingResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {infinitusai.be.CacheGreetingResponse} message CacheGreetingResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CacheGreetingResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CacheGreetingResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CacheGreetingResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CacheGreetingResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CacheGreetingResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CacheGreetingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CacheGreetingResponse";
                };
    
                return CacheGreetingResponse;
            })();
    
            be.CacheGreeting = (function() {
    
                /**
                 * Constructs a new CacheGreeting service.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CacheGreeting
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function CacheGreeting(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (CacheGreeting.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CacheGreeting;
    
                /**
                 * Creates new CacheGreeting service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.CacheGreeting
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {CacheGreeting} RPC service. Useful where requests and/or responses are streamed.
                 */
                CacheGreeting.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.CacheGreeting#fetchAndCacheGreetingFromDB}.
                 * @memberof infinitusai.be.CacheGreeting
                 * @typedef FetchAndCacheGreetingFromDBCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.CacheGreetingResponse} [response] CacheGreetingResponse
                 */
    
                /**
                 * Calls FetchAndCacheGreetingFromDB.
                 * @function fetchAndCacheGreetingFromDB
                 * @memberof infinitusai.be.CacheGreeting
                 * @instance
                 * @param {infinitusai.be.ICacheGreetingRequest} request CacheGreetingRequest message or plain object
                 * @param {infinitusai.be.CacheGreeting.FetchAndCacheGreetingFromDBCallback} callback Node-style callback called with the error, if any, and CacheGreetingResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CacheGreeting.prototype.fetchAndCacheGreetingFromDB = function fetchAndCacheGreetingFromDB(request, callback) {
                    return this.rpcCall(fetchAndCacheGreetingFromDB, $root.infinitusai.be.CacheGreetingRequest, $root.infinitusai.be.CacheGreetingResponse, request, callback);
                }, "name", { value: "FetchAndCacheGreetingFromDB" });
    
                /**
                 * Calls FetchAndCacheGreetingFromDB.
                 * @function fetchAndCacheGreetingFromDB
                 * @memberof infinitusai.be.CacheGreeting
                 * @instance
                 * @param {infinitusai.be.ICacheGreetingRequest} request CacheGreetingRequest message or plain object
                 * @returns {Promise<infinitusai.be.CacheGreetingResponse>} Promise
                 * @variation 2
                 */
    
                return CacheGreeting;
            })();
    
            /**
             * CallRole enum.
             * @name infinitusai.be.CallRole
             * @enum {number}
             * @property {number} CALL_ROLE_UNKNOWN=0 CALL_ROLE_UNKNOWN value
             * @property {number} CALL_ROLE_CONTROLLER=1 CALL_ROLE_CONTROLLER value
             * @property {number} CALL_ROLE_OBSERVER=2 CALL_ROLE_OBSERVER value
             */
            be.CallRole = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CALL_ROLE_UNKNOWN"] = 0;
                values[valuesById[1] = "CALL_ROLE_CONTROLLER"] = 1;
                values[valuesById[2] = "CALL_ROLE_OBSERVER"] = 2;
                return values;
            })();
    
            be.AuthClientMessage = (function() {
    
                /**
                 * Properties of an AuthClientMessage.
                 * @memberof infinitusai.be
                 * @interface IAuthClientMessage
                 * @property {string|null} [jwt] AuthClientMessage jwt
                 * @property {string|null} [orgUuid] AuthClientMessage orgUuid
                 * @property {string|null} [frontendVersion] AuthClientMessage frontendVersion
                 * @property {string|null} [appName] AuthClientMessage appName
                 */
    
                /**
                 * Constructs a new AuthClientMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AuthClientMessage.
                 * @implements IAuthClientMessage
                 * @constructor
                 * @param {infinitusai.be.IAuthClientMessage=} [properties] Properties to set
                 */
                function AuthClientMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AuthClientMessage jwt.
                 * @member {string} jwt
                 * @memberof infinitusai.be.AuthClientMessage
                 * @instance
                 */
                AuthClientMessage.prototype.jwt = "";
    
                /**
                 * AuthClientMessage orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.AuthClientMessage
                 * @instance
                 */
                AuthClientMessage.prototype.orgUuid = "";
    
                /**
                 * AuthClientMessage frontendVersion.
                 * @member {string} frontendVersion
                 * @memberof infinitusai.be.AuthClientMessage
                 * @instance
                 */
                AuthClientMessage.prototype.frontendVersion = "";
    
                /**
                 * AuthClientMessage appName.
                 * @member {string} appName
                 * @memberof infinitusai.be.AuthClientMessage
                 * @instance
                 */
                AuthClientMessage.prototype.appName = "";
    
                /**
                 * Creates a new AuthClientMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {infinitusai.be.IAuthClientMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.AuthClientMessage} AuthClientMessage instance
                 */
                AuthClientMessage.create = function create(properties) {
                    return new AuthClientMessage(properties);
                };
    
                /**
                 * Encodes the specified AuthClientMessage message. Does not implicitly {@link infinitusai.be.AuthClientMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {infinitusai.be.IAuthClientMessage} message AuthClientMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuthClientMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.jwt != null && Object.hasOwnProperty.call(message, "jwt"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.jwt);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuid);
                    if (message.frontendVersion != null && Object.hasOwnProperty.call(message, "frontendVersion"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.frontendVersion);
                    if (message.appName != null && Object.hasOwnProperty.call(message, "appName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.appName);
                    return writer;
                };
    
                /**
                 * Encodes the specified AuthClientMessage message, length delimited. Does not implicitly {@link infinitusai.be.AuthClientMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {infinitusai.be.IAuthClientMessage} message AuthClientMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuthClientMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AuthClientMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AuthClientMessage} AuthClientMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuthClientMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AuthClientMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.jwt = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.frontendVersion = reader.string();
                                break;
                            }
                        case 4: {
                                message.appName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AuthClientMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AuthClientMessage} AuthClientMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuthClientMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AuthClientMessage message.
                 * @function verify
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AuthClientMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.jwt != null && message.hasOwnProperty("jwt"))
                        if (!$util.isString(message.jwt))
                            return "jwt: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.frontendVersion != null && message.hasOwnProperty("frontendVersion"))
                        if (!$util.isString(message.frontendVersion))
                            return "frontendVersion: string expected";
                    if (message.appName != null && message.hasOwnProperty("appName"))
                        if (!$util.isString(message.appName))
                            return "appName: string expected";
                    return null;
                };
    
                /**
                 * Creates an AuthClientMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AuthClientMessage} AuthClientMessage
                 */
                AuthClientMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AuthClientMessage)
                        return object;
                    var message = new $root.infinitusai.be.AuthClientMessage();
                    if (object.jwt != null)
                        message.jwt = String(object.jwt);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.frontendVersion != null)
                        message.frontendVersion = String(object.frontendVersion);
                    if (object.appName != null)
                        message.appName = String(object.appName);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AuthClientMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {infinitusai.be.AuthClientMessage} message AuthClientMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AuthClientMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.jwt = "";
                        object.orgUuid = "";
                        object.frontendVersion = "";
                        object.appName = "";
                    }
                    if (message.jwt != null && message.hasOwnProperty("jwt"))
                        object.jwt = message.jwt;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.frontendVersion != null && message.hasOwnProperty("frontendVersion"))
                        object.frontendVersion = message.frontendVersion;
                    if (message.appName != null && message.hasOwnProperty("appName"))
                        object.appName = message.appName;
                    return object;
                };
    
                /**
                 * Converts this AuthClientMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AuthClientMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AuthClientMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AuthClientMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AuthClientMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AuthClientMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AuthClientMessage";
                };
    
                return AuthClientMessage;
            })();
    
            be.CallPageClientHeartbeat = (function() {
    
                /**
                 * Properties of a CallPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface ICallPageClientHeartbeat
                 * @property {string|null} [taskUuid] CallPageClientHeartbeat taskUuid
                 * @property {string|null} [callUuid] CallPageClientHeartbeat callUuid
                 * @property {string|null} [nexmoConversationId] CallPageClientHeartbeat nexmoConversationId
                 * @property {boolean|null} [isCallInProgress] CallPageClientHeartbeat isCallInProgress
                 * @property {infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity|null} [activity] CallPageClientHeartbeat activity
                 * @property {infinitusai.be.TaskState|null} [taskState] CallPageClientHeartbeat taskState
                 */
    
                /**
                 * Constructs a new CallPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallPageClientHeartbeat.
                 * @implements ICallPageClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.ICallPageClientHeartbeat=} [properties] Properties to set
                 */
                function CallPageClientHeartbeat(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallPageClientHeartbeat taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @instance
                 */
                CallPageClientHeartbeat.prototype.taskUuid = "";
    
                /**
                 * CallPageClientHeartbeat callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @instance
                 */
                CallPageClientHeartbeat.prototype.callUuid = "";
    
                /**
                 * CallPageClientHeartbeat nexmoConversationId.
                 * @member {string} nexmoConversationId
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @instance
                 */
                CallPageClientHeartbeat.prototype.nexmoConversationId = "";
    
                /**
                 * CallPageClientHeartbeat isCallInProgress.
                 * @member {boolean} isCallInProgress
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @instance
                 */
                CallPageClientHeartbeat.prototype.isCallInProgress = false;
    
                /**
                 * CallPageClientHeartbeat activity.
                 * @member {infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity} activity
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @instance
                 */
                CallPageClientHeartbeat.prototype.activity = 0;
    
                /**
                 * CallPageClientHeartbeat taskState.
                 * @member {infinitusai.be.TaskState} taskState
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @instance
                 */
                CallPageClientHeartbeat.prototype.taskState = 0;
    
                /**
                 * Creates a new CallPageClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ICallPageClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.CallPageClientHeartbeat} CallPageClientHeartbeat instance
                 */
                CallPageClientHeartbeat.create = function create(properties) {
                    return new CallPageClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified CallPageClientHeartbeat message. Does not implicitly {@link infinitusai.be.CallPageClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ICallPageClientHeartbeat} message CallPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallPageClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.nexmoConversationId != null && Object.hasOwnProperty.call(message, "nexmoConversationId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.nexmoConversationId);
                    if (message.isCallInProgress != null && Object.hasOwnProperty.call(message, "isCallInProgress"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isCallInProgress);
                    if (message.activity != null && Object.hasOwnProperty.call(message, "activity"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.activity);
                    if (message.taskState != null && Object.hasOwnProperty.call(message, "taskState"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.taskState);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallPageClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.CallPageClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ICallPageClientHeartbeat} message CallPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallPageClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallPageClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallPageClientHeartbeat} CallPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallPageClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallPageClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.nexmoConversationId = reader.string();
                                break;
                            }
                        case 5: {
                                message.isCallInProgress = reader.bool();
                                break;
                            }
                        case 6: {
                                message.activity = reader.int32();
                                break;
                            }
                        case 7: {
                                message.taskState = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallPageClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallPageClientHeartbeat} CallPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallPageClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallPageClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallPageClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.nexmoConversationId != null && message.hasOwnProperty("nexmoConversationId"))
                        if (!$util.isString(message.nexmoConversationId))
                            return "nexmoConversationId: string expected";
                    if (message.isCallInProgress != null && message.hasOwnProperty("isCallInProgress"))
                        if (typeof message.isCallInProgress !== "boolean")
                            return "isCallInProgress: boolean expected";
                    if (message.activity != null && message.hasOwnProperty("activity"))
                        switch (message.activity) {
                        default:
                            return "activity: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.taskState != null && message.hasOwnProperty("taskState"))
                        switch (message.taskState) {
                        default:
                            return "taskState: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a CallPageClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallPageClientHeartbeat} CallPageClientHeartbeat
                 */
                CallPageClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallPageClientHeartbeat)
                        return object;
                    var message = new $root.infinitusai.be.CallPageClientHeartbeat();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.nexmoConversationId != null)
                        message.nexmoConversationId = String(object.nexmoConversationId);
                    if (object.isCallInProgress != null)
                        message.isCallInProgress = Boolean(object.isCallInProgress);
                    switch (object.activity) {
                    default:
                        if (typeof object.activity === "number") {
                            message.activity = object.activity;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.activity = 0;
                        break;
                    case "VISIT":
                    case 1:
                        message.activity = 1;
                        break;
                    case "OBSERVE":
                    case 2:
                        message.activity = 2;
                        break;
                    case "CONTROL":
                    case 3:
                        message.activity = 3;
                        break;
                    case "SELF_REVIEW":
                    case 4:
                        message.activity = 4;
                        break;
                    case "REVIEW":
                    case 5:
                        message.activity = 5;
                        break;
                    }
                    switch (object.taskState) {
                    default:
                        if (typeof object.taskState === "number") {
                            message.taskState = object.taskState;
                            break;
                        }
                        break;
                    case "TASK_STATE_UNKNOWN":
                    case 0:
                        message.taskState = 0;
                        break;
                    case "TASK_STATE_CREATED":
                    case 1:
                        message.taskState = 1;
                        break;
                    case "TASK_STATE_CALL_CONNECTED":
                    case 2:
                        message.taskState = 2;
                        break;
                    case "TASK_STATE_CALL_DISCONNECTED":
                    case 3:
                        message.taskState = 3;
                        break;
                    case "TASK_STATE_FAILED":
                    case 4:
                        message.taskState = 4;
                        break;
                    case "TASK_STATE_REVIEWED":
                    case 5:
                        message.taskState = 5;
                        break;
                    case "TASK_STATE_READY_FOR_REVIEW":
                    case 6:
                        message.taskState = 6;
                        break;
                    case "TASK_STATE_IN_REVIEW":
                    case 7:
                        message.taskState = 7;
                        break;
                    case "TASK_STATE_IN_PROGRESS":
                    case 8:
                        message.taskState = 8;
                        break;
                    case "TASK_STATE_PARTIAL_SUCCESS":
                    case 9:
                        message.taskState = 9;
                        break;
                    case "TASK_STATE_READY_FOR_SELF_REVIEW":
                    case 10:
                        message.taskState = 10;
                        break;
                    case "TASK_STATE_AUTO_CLOSED":
                    case 11:
                        message.taskState = 11;
                        break;
                    case "TASK_STATE_PENDING_AUTO_REQUEUE":
                    case 12:
                        message.taskState = 12;
                        break;
                    case "TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL":
                    case 13:
                        message.taskState = 13;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallPageClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.CallPageClientHeartbeat} message CallPageClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallPageClientHeartbeat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.nexmoConversationId = "";
                        object.isCallInProgress = false;
                        object.activity = options.enums === String ? "UNKNOWN" : 0;
                        object.taskState = options.enums === String ? "TASK_STATE_UNKNOWN" : 0;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.nexmoConversationId != null && message.hasOwnProperty("nexmoConversationId"))
                        object.nexmoConversationId = message.nexmoConversationId;
                    if (message.isCallInProgress != null && message.hasOwnProperty("isCallInProgress"))
                        object.isCallInProgress = message.isCallInProgress;
                    if (message.activity != null && message.hasOwnProperty("activity"))
                        object.activity = options.enums === String ? $root.infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity[message.activity] === undefined ? message.activity : $root.infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity[message.activity] : message.activity;
                    if (message.taskState != null && message.hasOwnProperty("taskState"))
                        object.taskState = options.enums === String ? $root.infinitusai.be.TaskState[message.taskState] === undefined ? message.taskState : $root.infinitusai.be.TaskState[message.taskState] : message.taskState;
                    return object;
                };
    
                /**
                 * Converts this CallPageClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallPageClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallPageClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallPageClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallPageClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallPageClientHeartbeat";
                };
    
                /**
                 * CallPageOperatorActivity enum.
                 * @name infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} VISIT=1 VISIT value
                 * @property {number} OBSERVE=2 OBSERVE value
                 * @property {number} CONTROL=3 CONTROL value
                 * @property {number} SELF_REVIEW=4 SELF_REVIEW value
                 * @property {number} REVIEW=5 REVIEW value
                 */
                CallPageClientHeartbeat.CallPageOperatorActivity = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "VISIT"] = 1;
                    values[valuesById[2] = "OBSERVE"] = 2;
                    values[valuesById[3] = "CONTROL"] = 3;
                    values[valuesById[4] = "SELF_REVIEW"] = 4;
                    values[valuesById[5] = "REVIEW"] = 5;
                    return values;
                })();
    
                return CallPageClientHeartbeat;
            })();
    
            be.ActiveCallsPageClientHeartbeat = (function() {
    
                /**
                 * Properties of an ActiveCallsPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface IActiveCallsPageClientHeartbeat
                 * @property {string|null} [orgUuid] ActiveCallsPageClientHeartbeat orgUuid
                 * @property {boolean|null} [isOperatorAvailable] ActiveCallsPageClientHeartbeat isOperatorAvailable
                 */
    
                /**
                 * Constructs a new ActiveCallsPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ActiveCallsPageClientHeartbeat.
                 * @implements IActiveCallsPageClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.IActiveCallsPageClientHeartbeat=} [properties] Properties to set
                 */
                function ActiveCallsPageClientHeartbeat(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ActiveCallsPageClientHeartbeat orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @instance
                 */
                ActiveCallsPageClientHeartbeat.prototype.orgUuid = "";
    
                /**
                 * ActiveCallsPageClientHeartbeat isOperatorAvailable.
                 * @member {boolean} isOperatorAvailable
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @instance
                 */
                ActiveCallsPageClientHeartbeat.prototype.isOperatorAvailable = false;
    
                /**
                 * Creates a new ActiveCallsPageClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IActiveCallsPageClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.ActiveCallsPageClientHeartbeat} ActiveCallsPageClientHeartbeat instance
                 */
                ActiveCallsPageClientHeartbeat.create = function create(properties) {
                    return new ActiveCallsPageClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified ActiveCallsPageClientHeartbeat message. Does not implicitly {@link infinitusai.be.ActiveCallsPageClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IActiveCallsPageClientHeartbeat} message ActiveCallsPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ActiveCallsPageClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.isOperatorAvailable != null && Object.hasOwnProperty.call(message, "isOperatorAvailable"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isOperatorAvailable);
                    return writer;
                };
    
                /**
                 * Encodes the specified ActiveCallsPageClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.ActiveCallsPageClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IActiveCallsPageClientHeartbeat} message ActiveCallsPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ActiveCallsPageClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ActiveCallsPageClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ActiveCallsPageClientHeartbeat} ActiveCallsPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ActiveCallsPageClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ActiveCallsPageClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.isOperatorAvailable = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ActiveCallsPageClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ActiveCallsPageClientHeartbeat} ActiveCallsPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ActiveCallsPageClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ActiveCallsPageClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ActiveCallsPageClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.isOperatorAvailable != null && message.hasOwnProperty("isOperatorAvailable"))
                        if (typeof message.isOperatorAvailable !== "boolean")
                            return "isOperatorAvailable: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an ActiveCallsPageClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ActiveCallsPageClientHeartbeat} ActiveCallsPageClientHeartbeat
                 */
                ActiveCallsPageClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ActiveCallsPageClientHeartbeat)
                        return object;
                    var message = new $root.infinitusai.be.ActiveCallsPageClientHeartbeat();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.isOperatorAvailable != null)
                        message.isOperatorAvailable = Boolean(object.isOperatorAvailable);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ActiveCallsPageClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ActiveCallsPageClientHeartbeat} message ActiveCallsPageClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ActiveCallsPageClientHeartbeat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.isOperatorAvailable = false;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.isOperatorAvailable != null && message.hasOwnProperty("isOperatorAvailable"))
                        object.isOperatorAvailable = message.isOperatorAvailable;
                    return object;
                };
    
                /**
                 * Converts this ActiveCallsPageClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ActiveCallsPageClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ActiveCallsPageClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ActiveCallsPageClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ActiveCallsPageClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ActiveCallsPageClientHeartbeat";
                };
    
                return ActiveCallsPageClientHeartbeat;
            })();
    
            be.ReadyPageClientHeartbeat = (function() {
    
                /**
                 * Properties of a ReadyPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface IReadyPageClientHeartbeat
                 * @property {boolean|null} [isOperatorAvailable] ReadyPageClientHeartbeat isOperatorAvailable
                 */
    
                /**
                 * Constructs a new ReadyPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReadyPageClientHeartbeat.
                 * @implements IReadyPageClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.IReadyPageClientHeartbeat=} [properties] Properties to set
                 */
                function ReadyPageClientHeartbeat(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ReadyPageClientHeartbeat isOperatorAvailable.
                 * @member {boolean} isOperatorAvailable
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @instance
                 */
                ReadyPageClientHeartbeat.prototype.isOperatorAvailable = false;
    
                /**
                 * Creates a new ReadyPageClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IReadyPageClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.ReadyPageClientHeartbeat} ReadyPageClientHeartbeat instance
                 */
                ReadyPageClientHeartbeat.create = function create(properties) {
                    return new ReadyPageClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified ReadyPageClientHeartbeat message. Does not implicitly {@link infinitusai.be.ReadyPageClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IReadyPageClientHeartbeat} message ReadyPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReadyPageClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isOperatorAvailable != null && Object.hasOwnProperty.call(message, "isOperatorAvailable"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isOperatorAvailable);
                    return writer;
                };
    
                /**
                 * Encodes the specified ReadyPageClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.ReadyPageClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IReadyPageClientHeartbeat} message ReadyPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReadyPageClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReadyPageClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReadyPageClientHeartbeat} ReadyPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReadyPageClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReadyPageClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.isOperatorAvailable = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReadyPageClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReadyPageClientHeartbeat} ReadyPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReadyPageClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReadyPageClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReadyPageClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isOperatorAvailable != null && message.hasOwnProperty("isOperatorAvailable"))
                        if (typeof message.isOperatorAvailable !== "boolean")
                            return "isOperatorAvailable: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a ReadyPageClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReadyPageClientHeartbeat} ReadyPageClientHeartbeat
                 */
                ReadyPageClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReadyPageClientHeartbeat)
                        return object;
                    var message = new $root.infinitusai.be.ReadyPageClientHeartbeat();
                    if (object.isOperatorAvailable != null)
                        message.isOperatorAvailable = Boolean(object.isOperatorAvailable);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ReadyPageClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ReadyPageClientHeartbeat} message ReadyPageClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReadyPageClientHeartbeat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.isOperatorAvailable = false;
                    if (message.isOperatorAvailable != null && message.hasOwnProperty("isOperatorAvailable"))
                        object.isOperatorAvailable = message.isOperatorAvailable;
                    return object;
                };
    
                /**
                 * Converts this ReadyPageClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReadyPageClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReadyPageClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReadyPageClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReadyPageClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReadyPageClientHeartbeat";
                };
    
                return ReadyPageClientHeartbeat;
            })();
    
            be.TaskListPageClientHeartbeat = (function() {
    
                /**
                 * Properties of a TaskListPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface ITaskListPageClientHeartbeat
                 */
    
                /**
                 * Constructs a new TaskListPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TaskListPageClientHeartbeat.
                 * @implements ITaskListPageClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.ITaskListPageClientHeartbeat=} [properties] Properties to set
                 */
                function TaskListPageClientHeartbeat(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new TaskListPageClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ITaskListPageClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.TaskListPageClientHeartbeat} TaskListPageClientHeartbeat instance
                 */
                TaskListPageClientHeartbeat.create = function create(properties) {
                    return new TaskListPageClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified TaskListPageClientHeartbeat message. Does not implicitly {@link infinitusai.be.TaskListPageClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ITaskListPageClientHeartbeat} message TaskListPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskListPageClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskListPageClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.TaskListPageClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ITaskListPageClientHeartbeat} message TaskListPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskListPageClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskListPageClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TaskListPageClientHeartbeat} TaskListPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskListPageClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskListPageClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskListPageClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TaskListPageClientHeartbeat} TaskListPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskListPageClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskListPageClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskListPageClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a TaskListPageClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TaskListPageClientHeartbeat} TaskListPageClientHeartbeat
                 */
                TaskListPageClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TaskListPageClientHeartbeat)
                        return object;
                    return new $root.infinitusai.be.TaskListPageClientHeartbeat();
                };
    
                /**
                 * Creates a plain object from a TaskListPageClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.TaskListPageClientHeartbeat} message TaskListPageClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskListPageClientHeartbeat.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this TaskListPageClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskListPageClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskListPageClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TaskListPageClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskListPageClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TaskListPageClientHeartbeat";
                };
    
                return TaskListPageClientHeartbeat;
            })();
    
            be.TaskPageClientHeartbeat = (function() {
    
                /**
                 * Properties of a TaskPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface ITaskPageClientHeartbeat
                 * @property {string|null} [taskUuid] TaskPageClientHeartbeat taskUuid
                 */
    
                /**
                 * Constructs a new TaskPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TaskPageClientHeartbeat.
                 * @implements ITaskPageClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.ITaskPageClientHeartbeat=} [properties] Properties to set
                 */
                function TaskPageClientHeartbeat(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskPageClientHeartbeat taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @instance
                 */
                TaskPageClientHeartbeat.prototype.taskUuid = "";
    
                /**
                 * Creates a new TaskPageClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ITaskPageClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.TaskPageClientHeartbeat} TaskPageClientHeartbeat instance
                 */
                TaskPageClientHeartbeat.create = function create(properties) {
                    return new TaskPageClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified TaskPageClientHeartbeat message. Does not implicitly {@link infinitusai.be.TaskPageClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ITaskPageClientHeartbeat} message TaskPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskPageClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskPageClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.TaskPageClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.ITaskPageClientHeartbeat} message TaskPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskPageClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskPageClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TaskPageClientHeartbeat} TaskPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskPageClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskPageClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskPageClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TaskPageClientHeartbeat} TaskPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskPageClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskPageClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskPageClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a TaskPageClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TaskPageClientHeartbeat} TaskPageClientHeartbeat
                 */
                TaskPageClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TaskPageClientHeartbeat)
                        return object;
                    var message = new $root.infinitusai.be.TaskPageClientHeartbeat();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskPageClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.TaskPageClientHeartbeat} message TaskPageClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskPageClientHeartbeat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this TaskPageClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskPageClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskPageClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TaskPageClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskPageClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TaskPageClientHeartbeat";
                };
    
                return TaskPageClientHeartbeat;
            })();
    
            be.AdminPortalPageClientHeartbeat = (function() {
    
                /**
                 * Properties of an AdminPortalPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface IAdminPortalPageClientHeartbeat
                 */
    
                /**
                 * Constructs a new AdminPortalPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AdminPortalPageClientHeartbeat.
                 * @implements IAdminPortalPageClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.IAdminPortalPageClientHeartbeat=} [properties] Properties to set
                 */
                function AdminPortalPageClientHeartbeat(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new AdminPortalPageClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IAdminPortalPageClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.AdminPortalPageClientHeartbeat} AdminPortalPageClientHeartbeat instance
                 */
                AdminPortalPageClientHeartbeat.create = function create(properties) {
                    return new AdminPortalPageClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified AdminPortalPageClientHeartbeat message. Does not implicitly {@link infinitusai.be.AdminPortalPageClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IAdminPortalPageClientHeartbeat} message AdminPortalPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdminPortalPageClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified AdminPortalPageClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.AdminPortalPageClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IAdminPortalPageClientHeartbeat} message AdminPortalPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdminPortalPageClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AdminPortalPageClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AdminPortalPageClientHeartbeat} AdminPortalPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdminPortalPageClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AdminPortalPageClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AdminPortalPageClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AdminPortalPageClientHeartbeat} AdminPortalPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdminPortalPageClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AdminPortalPageClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AdminPortalPageClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an AdminPortalPageClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AdminPortalPageClientHeartbeat} AdminPortalPageClientHeartbeat
                 */
                AdminPortalPageClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AdminPortalPageClientHeartbeat)
                        return object;
                    return new $root.infinitusai.be.AdminPortalPageClientHeartbeat();
                };
    
                /**
                 * Creates a plain object from an AdminPortalPageClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.AdminPortalPageClientHeartbeat} message AdminPortalPageClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AdminPortalPageClientHeartbeat.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this AdminPortalPageClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AdminPortalPageClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AdminPortalPageClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AdminPortalPageClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AdminPortalPageClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AdminPortalPageClientHeartbeat";
                };
    
                return AdminPortalPageClientHeartbeat;
            })();
    
            be.UnknownPageClientHeartbeat = (function() {
    
                /**
                 * Properties of an UnknownPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface IUnknownPageClientHeartbeat
                 */
    
                /**
                 * Constructs a new UnknownPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UnknownPageClientHeartbeat.
                 * @implements IUnknownPageClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.IUnknownPageClientHeartbeat=} [properties] Properties to set
                 */
                function UnknownPageClientHeartbeat(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UnknownPageClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IUnknownPageClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.UnknownPageClientHeartbeat} UnknownPageClientHeartbeat instance
                 */
                UnknownPageClientHeartbeat.create = function create(properties) {
                    return new UnknownPageClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified UnknownPageClientHeartbeat message. Does not implicitly {@link infinitusai.be.UnknownPageClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IUnknownPageClientHeartbeat} message UnknownPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnknownPageClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UnknownPageClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.UnknownPageClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IUnknownPageClientHeartbeat} message UnknownPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnknownPageClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UnknownPageClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UnknownPageClientHeartbeat} UnknownPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnknownPageClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UnknownPageClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UnknownPageClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UnknownPageClientHeartbeat} UnknownPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnknownPageClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UnknownPageClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnknownPageClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UnknownPageClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UnknownPageClientHeartbeat} UnknownPageClientHeartbeat
                 */
                UnknownPageClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UnknownPageClientHeartbeat)
                        return object;
                    return new $root.infinitusai.be.UnknownPageClientHeartbeat();
                };
    
                /**
                 * Creates a plain object from an UnknownPageClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.UnknownPageClientHeartbeat} message UnknownPageClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnknownPageClientHeartbeat.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UnknownPageClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnknownPageClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UnknownPageClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UnknownPageClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UnknownPageClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UnknownPageClientHeartbeat";
                };
    
                return UnknownPageClientHeartbeat;
            })();
    
            be.NoPageClientHeartbeat = (function() {
    
                /**
                 * Properties of a NoPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface INoPageClientHeartbeat
                 */
    
                /**
                 * Constructs a new NoPageClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NoPageClientHeartbeat.
                 * @implements INoPageClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.INoPageClientHeartbeat=} [properties] Properties to set
                 */
                function NoPageClientHeartbeat(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new NoPageClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.INoPageClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.NoPageClientHeartbeat} NoPageClientHeartbeat instance
                 */
                NoPageClientHeartbeat.create = function create(properties) {
                    return new NoPageClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified NoPageClientHeartbeat message. Does not implicitly {@link infinitusai.be.NoPageClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.INoPageClientHeartbeat} message NoPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPageClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified NoPageClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.NoPageClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.INoPageClientHeartbeat} message NoPageClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPageClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NoPageClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NoPageClientHeartbeat} NoPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPageClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NoPageClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NoPageClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NoPageClientHeartbeat} NoPageClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPageClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NoPageClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NoPageClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a NoPageClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NoPageClientHeartbeat} NoPageClientHeartbeat
                 */
                NoPageClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NoPageClientHeartbeat)
                        return object;
                    return new $root.infinitusai.be.NoPageClientHeartbeat();
                };
    
                /**
                 * Creates a plain object from a NoPageClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {infinitusai.be.NoPageClientHeartbeat} message NoPageClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoPageClientHeartbeat.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this NoPageClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoPageClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NoPageClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NoPageClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoPageClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NoPageClientHeartbeat";
                };
    
                return NoPageClientHeartbeat;
            })();
    
            be.ActiveCall = (function() {
    
                /**
                 * Properties of an ActiveCall.
                 * @memberof infinitusai.be
                 * @interface IActiveCall
                 * @property {string|null} [callUuid] ActiveCall callUuid
                 * @property {string|null} [taskUuid] ActiveCall taskUuid
                 * @property {boolean|null} [selected] ActiveCall selected
                 */
    
                /**
                 * Constructs a new ActiveCall.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ActiveCall.
                 * @implements IActiveCall
                 * @constructor
                 * @param {infinitusai.be.IActiveCall=} [properties] Properties to set
                 */
                function ActiveCall(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ActiveCall callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ActiveCall
                 * @instance
                 */
                ActiveCall.prototype.callUuid = "";
    
                /**
                 * ActiveCall taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ActiveCall
                 * @instance
                 */
                ActiveCall.prototype.taskUuid = "";
    
                /**
                 * ActiveCall selected.
                 * @member {boolean} selected
                 * @memberof infinitusai.be.ActiveCall
                 * @instance
                 */
                ActiveCall.prototype.selected = false;
    
                /**
                 * Creates a new ActiveCall instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {infinitusai.be.IActiveCall=} [properties] Properties to set
                 * @returns {infinitusai.be.ActiveCall} ActiveCall instance
                 */
                ActiveCall.create = function create(properties) {
                    return new ActiveCall(properties);
                };
    
                /**
                 * Encodes the specified ActiveCall message. Does not implicitly {@link infinitusai.be.ActiveCall.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {infinitusai.be.IActiveCall} message ActiveCall message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ActiveCall.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.selected != null && Object.hasOwnProperty.call(message, "selected"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.selected);
                    return writer;
                };
    
                /**
                 * Encodes the specified ActiveCall message, length delimited. Does not implicitly {@link infinitusai.be.ActiveCall.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {infinitusai.be.IActiveCall} message ActiveCall message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ActiveCall.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ActiveCall message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ActiveCall} ActiveCall
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ActiveCall.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ActiveCall();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.selected = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ActiveCall message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ActiveCall} ActiveCall
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ActiveCall.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ActiveCall message.
                 * @function verify
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ActiveCall.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.selected != null && message.hasOwnProperty("selected"))
                        if (typeof message.selected !== "boolean")
                            return "selected: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an ActiveCall message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ActiveCall} ActiveCall
                 */
                ActiveCall.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ActiveCall)
                        return object;
                    var message = new $root.infinitusai.be.ActiveCall();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.selected != null)
                        message.selected = Boolean(object.selected);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ActiveCall message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {infinitusai.be.ActiveCall} message ActiveCall
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ActiveCall.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callUuid = "";
                        object.taskUuid = "";
                        object.selected = false;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.selected != null && message.hasOwnProperty("selected"))
                        object.selected = message.selected;
                    return object;
                };
    
                /**
                 * Converts this ActiveCall to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ActiveCall
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ActiveCall.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ActiveCall
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ActiveCall
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ActiveCall.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ActiveCall";
                };
    
                return ActiveCall;
            })();
    
            be.SoftphoneHomePageData = (function() {
    
                /**
                 * Properties of a SoftphoneHomePageData.
                 * @memberof infinitusai.be
                 * @interface ISoftphoneHomePageData
                 * @property {infinitusai.be.SoftphoneHomePageData.IOther|null} [other] SoftphoneHomePageData other
                 * @property {infinitusai.be.SoftphoneHomePageData.IReadyForRouting|null} [readyForRouting] SoftphoneHomePageData readyForRouting
                 */
    
                /**
                 * Constructs a new SoftphoneHomePageData.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SoftphoneHomePageData.
                 * @implements ISoftphoneHomePageData
                 * @constructor
                 * @param {infinitusai.be.ISoftphoneHomePageData=} [properties] Properties to set
                 */
                function SoftphoneHomePageData(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SoftphoneHomePageData other.
                 * @member {infinitusai.be.SoftphoneHomePageData.IOther|null|undefined} other
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @instance
                 */
                SoftphoneHomePageData.prototype.other = null;
    
                /**
                 * SoftphoneHomePageData readyForRouting.
                 * @member {infinitusai.be.SoftphoneHomePageData.IReadyForRouting|null|undefined} readyForRouting
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @instance
                 */
                SoftphoneHomePageData.prototype.readyForRouting = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * SoftphoneHomePageData data.
                 * @member {"other"|"readyForRouting"|undefined} data
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @instance
                 */
                Object.defineProperty(SoftphoneHomePageData.prototype, "data", {
                    get: $util.oneOfGetter($oneOfFields = ["other", "readyForRouting"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new SoftphoneHomePageData instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneHomePageData=} [properties] Properties to set
                 * @returns {infinitusai.be.SoftphoneHomePageData} SoftphoneHomePageData instance
                 */
                SoftphoneHomePageData.create = function create(properties) {
                    return new SoftphoneHomePageData(properties);
                };
    
                /**
                 * Encodes the specified SoftphoneHomePageData message. Does not implicitly {@link infinitusai.be.SoftphoneHomePageData.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneHomePageData} message SoftphoneHomePageData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneHomePageData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.other != null && Object.hasOwnProperty.call(message, "other"))
                        $root.infinitusai.be.SoftphoneHomePageData.Other.encode(message.other, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.readyForRouting != null && Object.hasOwnProperty.call(message, "readyForRouting"))
                        $root.infinitusai.be.SoftphoneHomePageData.ReadyForRouting.encode(message.readyForRouting, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SoftphoneHomePageData message, length delimited. Does not implicitly {@link infinitusai.be.SoftphoneHomePageData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneHomePageData} message SoftphoneHomePageData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneHomePageData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SoftphoneHomePageData message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SoftphoneHomePageData} SoftphoneHomePageData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneHomePageData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SoftphoneHomePageData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.other = $root.infinitusai.be.SoftphoneHomePageData.Other.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.readyForRouting = $root.infinitusai.be.SoftphoneHomePageData.ReadyForRouting.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SoftphoneHomePageData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SoftphoneHomePageData} SoftphoneHomePageData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneHomePageData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SoftphoneHomePageData message.
                 * @function verify
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SoftphoneHomePageData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.other != null && message.hasOwnProperty("other")) {
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.SoftphoneHomePageData.Other.verify(message.other);
                            if (error)
                                return "other." + error;
                        }
                    }
                    if (message.readyForRouting != null && message.hasOwnProperty("readyForRouting")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.SoftphoneHomePageData.ReadyForRouting.verify(message.readyForRouting);
                            if (error)
                                return "readyForRouting." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a SoftphoneHomePageData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SoftphoneHomePageData} SoftphoneHomePageData
                 */
                SoftphoneHomePageData.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SoftphoneHomePageData)
                        return object;
                    var message = new $root.infinitusai.be.SoftphoneHomePageData();
                    if (object.other != null) {
                        if (typeof object.other !== "object")
                            throw TypeError(".infinitusai.be.SoftphoneHomePageData.other: object expected");
                        message.other = $root.infinitusai.be.SoftphoneHomePageData.Other.fromObject(object.other);
                    }
                    if (object.readyForRouting != null) {
                        if (typeof object.readyForRouting !== "object")
                            throw TypeError(".infinitusai.be.SoftphoneHomePageData.readyForRouting: object expected");
                        message.readyForRouting = $root.infinitusai.be.SoftphoneHomePageData.ReadyForRouting.fromObject(object.readyForRouting);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SoftphoneHomePageData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {infinitusai.be.SoftphoneHomePageData} message SoftphoneHomePageData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SoftphoneHomePageData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.other != null && message.hasOwnProperty("other")) {
                        object.other = $root.infinitusai.be.SoftphoneHomePageData.Other.toObject(message.other, options);
                        if (options.oneofs)
                            object.data = "other";
                    }
                    if (message.readyForRouting != null && message.hasOwnProperty("readyForRouting")) {
                        object.readyForRouting = $root.infinitusai.be.SoftphoneHomePageData.ReadyForRouting.toObject(message.readyForRouting, options);
                        if (options.oneofs)
                            object.data = "readyForRouting";
                    }
                    return object;
                };
    
                /**
                 * Converts this SoftphoneHomePageData to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SoftphoneHomePageData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SoftphoneHomePageData
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SoftphoneHomePageData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SoftphoneHomePageData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SoftphoneHomePageData";
                };
    
                SoftphoneHomePageData.Other = (function() {
    
                    /**
                     * Properties of an Other.
                     * @memberof infinitusai.be.SoftphoneHomePageData
                     * @interface IOther
                     */
    
                    /**
                     * Constructs a new Other.
                     * @memberof infinitusai.be.SoftphoneHomePageData
                     * @classdesc Represents an Other.
                     * @implements IOther
                     * @constructor
                     * @param {infinitusai.be.SoftphoneHomePageData.IOther=} [properties] Properties to set
                     */
                    function Other(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new Other instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {infinitusai.be.SoftphoneHomePageData.IOther=} [properties] Properties to set
                     * @returns {infinitusai.be.SoftphoneHomePageData.Other} Other instance
                     */
                    Other.create = function create(properties) {
                        return new Other(properties);
                    };
    
                    /**
                     * Encodes the specified Other message. Does not implicitly {@link infinitusai.be.SoftphoneHomePageData.Other.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {infinitusai.be.SoftphoneHomePageData.IOther} message Other message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Other.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Other message, length delimited. Does not implicitly {@link infinitusai.be.SoftphoneHomePageData.Other.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {infinitusai.be.SoftphoneHomePageData.IOther} message Other message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Other.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Other message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.SoftphoneHomePageData.Other} Other
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Other.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SoftphoneHomePageData.Other();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Other message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.SoftphoneHomePageData.Other} Other
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Other.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Other message.
                     * @function verify
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Other.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates an Other message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.SoftphoneHomePageData.Other} Other
                     */
                    Other.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.SoftphoneHomePageData.Other)
                            return object;
                        return new $root.infinitusai.be.SoftphoneHomePageData.Other();
                    };
    
                    /**
                     * Creates a plain object from an Other message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {infinitusai.be.SoftphoneHomePageData.Other} message Other
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Other.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this Other to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Other.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Other
                     * @function getTypeUrl
                     * @memberof infinitusai.be.SoftphoneHomePageData.Other
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Other.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.SoftphoneHomePageData.Other";
                    };
    
                    return Other;
                })();
    
                SoftphoneHomePageData.ReadyForRouting = (function() {
    
                    /**
                     * Properties of a ReadyForRouting.
                     * @memberof infinitusai.be.SoftphoneHomePageData
                     * @interface IReadyForRouting
                     * @property {boolean|null} [isOperatorAvailable] ReadyForRouting isOperatorAvailable
                     */
    
                    /**
                     * Constructs a new ReadyForRouting.
                     * @memberof infinitusai.be.SoftphoneHomePageData
                     * @classdesc Represents a ReadyForRouting.
                     * @implements IReadyForRouting
                     * @constructor
                     * @param {infinitusai.be.SoftphoneHomePageData.IReadyForRouting=} [properties] Properties to set
                     */
                    function ReadyForRouting(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReadyForRouting isOperatorAvailable.
                     * @member {boolean} isOperatorAvailable
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @instance
                     */
                    ReadyForRouting.prototype.isOperatorAvailable = false;
    
                    /**
                     * Creates a new ReadyForRouting instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {infinitusai.be.SoftphoneHomePageData.IReadyForRouting=} [properties] Properties to set
                     * @returns {infinitusai.be.SoftphoneHomePageData.ReadyForRouting} ReadyForRouting instance
                     */
                    ReadyForRouting.create = function create(properties) {
                        return new ReadyForRouting(properties);
                    };
    
                    /**
                     * Encodes the specified ReadyForRouting message. Does not implicitly {@link infinitusai.be.SoftphoneHomePageData.ReadyForRouting.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {infinitusai.be.SoftphoneHomePageData.IReadyForRouting} message ReadyForRouting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReadyForRouting.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isOperatorAvailable != null && Object.hasOwnProperty.call(message, "isOperatorAvailable"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isOperatorAvailable);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReadyForRouting message, length delimited. Does not implicitly {@link infinitusai.be.SoftphoneHomePageData.ReadyForRouting.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {infinitusai.be.SoftphoneHomePageData.IReadyForRouting} message ReadyForRouting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReadyForRouting.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReadyForRouting message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.SoftphoneHomePageData.ReadyForRouting} ReadyForRouting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReadyForRouting.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SoftphoneHomePageData.ReadyForRouting();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.isOperatorAvailable = reader.bool();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReadyForRouting message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.SoftphoneHomePageData.ReadyForRouting} ReadyForRouting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReadyForRouting.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReadyForRouting message.
                     * @function verify
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReadyForRouting.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isOperatorAvailable != null && message.hasOwnProperty("isOperatorAvailable"))
                            if (typeof message.isOperatorAvailable !== "boolean")
                                return "isOperatorAvailable: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ReadyForRouting message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.SoftphoneHomePageData.ReadyForRouting} ReadyForRouting
                     */
                    ReadyForRouting.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.SoftphoneHomePageData.ReadyForRouting)
                            return object;
                        var message = new $root.infinitusai.be.SoftphoneHomePageData.ReadyForRouting();
                        if (object.isOperatorAvailable != null)
                            message.isOperatorAvailable = Boolean(object.isOperatorAvailable);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReadyForRouting message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {infinitusai.be.SoftphoneHomePageData.ReadyForRouting} message ReadyForRouting
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReadyForRouting.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.isOperatorAvailable = false;
                        if (message.isOperatorAvailable != null && message.hasOwnProperty("isOperatorAvailable"))
                            object.isOperatorAvailable = message.isOperatorAvailable;
                        return object;
                    };
    
                    /**
                     * Converts this ReadyForRouting to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReadyForRouting.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for ReadyForRouting
                     * @function getTypeUrl
                     * @memberof infinitusai.be.SoftphoneHomePageData.ReadyForRouting
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ReadyForRouting.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.SoftphoneHomePageData.ReadyForRouting";
                    };
    
                    return ReadyForRouting;
                })();
    
                return SoftphoneHomePageData;
            })();
    
            be.SoftphoneProcessedCallHistoryPageData = (function() {
    
                /**
                 * Properties of a SoftphoneProcessedCallHistoryPageData.
                 * @memberof infinitusai.be
                 * @interface ISoftphoneProcessedCallHistoryPageData
                 */
    
                /**
                 * Constructs a new SoftphoneProcessedCallHistoryPageData.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SoftphoneProcessedCallHistoryPageData.
                 * @implements ISoftphoneProcessedCallHistoryPageData
                 * @constructor
                 * @param {infinitusai.be.ISoftphoneProcessedCallHistoryPageData=} [properties] Properties to set
                 */
                function SoftphoneProcessedCallHistoryPageData(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new SoftphoneProcessedCallHistoryPageData instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneProcessedCallHistoryPageData=} [properties] Properties to set
                 * @returns {infinitusai.be.SoftphoneProcessedCallHistoryPageData} SoftphoneProcessedCallHistoryPageData instance
                 */
                SoftphoneProcessedCallHistoryPageData.create = function create(properties) {
                    return new SoftphoneProcessedCallHistoryPageData(properties);
                };
    
                /**
                 * Encodes the specified SoftphoneProcessedCallHistoryPageData message. Does not implicitly {@link infinitusai.be.SoftphoneProcessedCallHistoryPageData.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneProcessedCallHistoryPageData} message SoftphoneProcessedCallHistoryPageData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneProcessedCallHistoryPageData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified SoftphoneProcessedCallHistoryPageData message, length delimited. Does not implicitly {@link infinitusai.be.SoftphoneProcessedCallHistoryPageData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneProcessedCallHistoryPageData} message SoftphoneProcessedCallHistoryPageData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneProcessedCallHistoryPageData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SoftphoneProcessedCallHistoryPageData message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SoftphoneProcessedCallHistoryPageData} SoftphoneProcessedCallHistoryPageData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneProcessedCallHistoryPageData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SoftphoneProcessedCallHistoryPageData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SoftphoneProcessedCallHistoryPageData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SoftphoneProcessedCallHistoryPageData} SoftphoneProcessedCallHistoryPageData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneProcessedCallHistoryPageData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SoftphoneProcessedCallHistoryPageData message.
                 * @function verify
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SoftphoneProcessedCallHistoryPageData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a SoftphoneProcessedCallHistoryPageData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SoftphoneProcessedCallHistoryPageData} SoftphoneProcessedCallHistoryPageData
                 */
                SoftphoneProcessedCallHistoryPageData.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SoftphoneProcessedCallHistoryPageData)
                        return object;
                    return new $root.infinitusai.be.SoftphoneProcessedCallHistoryPageData();
                };
    
                /**
                 * Creates a plain object from a SoftphoneProcessedCallHistoryPageData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {infinitusai.be.SoftphoneProcessedCallHistoryPageData} message SoftphoneProcessedCallHistoryPageData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SoftphoneProcessedCallHistoryPageData.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this SoftphoneProcessedCallHistoryPageData to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SoftphoneProcessedCallHistoryPageData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SoftphoneProcessedCallHistoryPageData
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SoftphoneProcessedCallHistoryPageData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SoftphoneProcessedCallHistoryPageData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SoftphoneProcessedCallHistoryPageData";
                };
    
                return SoftphoneProcessedCallHistoryPageData;
            })();
    
            be.SoftphoneCallsToProcessPageData = (function() {
    
                /**
                 * Properties of a SoftphoneCallsToProcessPageData.
                 * @memberof infinitusai.be
                 * @interface ISoftphoneCallsToProcessPageData
                 * @property {string|null} [taskUuid] SoftphoneCallsToProcessPageData taskUuid
                 * @property {string|null} [callUuid] SoftphoneCallsToProcessPageData callUuid
                 */
    
                /**
                 * Constructs a new SoftphoneCallsToProcessPageData.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SoftphoneCallsToProcessPageData.
                 * @implements ISoftphoneCallsToProcessPageData
                 * @constructor
                 * @param {infinitusai.be.ISoftphoneCallsToProcessPageData=} [properties] Properties to set
                 */
                function SoftphoneCallsToProcessPageData(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SoftphoneCallsToProcessPageData taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @instance
                 */
                SoftphoneCallsToProcessPageData.prototype.taskUuid = "";
    
                /**
                 * SoftphoneCallsToProcessPageData callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @instance
                 */
                SoftphoneCallsToProcessPageData.prototype.callUuid = "";
    
                /**
                 * Creates a new SoftphoneCallsToProcessPageData instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneCallsToProcessPageData=} [properties] Properties to set
                 * @returns {infinitusai.be.SoftphoneCallsToProcessPageData} SoftphoneCallsToProcessPageData instance
                 */
                SoftphoneCallsToProcessPageData.create = function create(properties) {
                    return new SoftphoneCallsToProcessPageData(properties);
                };
    
                /**
                 * Encodes the specified SoftphoneCallsToProcessPageData message. Does not implicitly {@link infinitusai.be.SoftphoneCallsToProcessPageData.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneCallsToProcessPageData} message SoftphoneCallsToProcessPageData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneCallsToProcessPageData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified SoftphoneCallsToProcessPageData message, length delimited. Does not implicitly {@link infinitusai.be.SoftphoneCallsToProcessPageData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {infinitusai.be.ISoftphoneCallsToProcessPageData} message SoftphoneCallsToProcessPageData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SoftphoneCallsToProcessPageData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SoftphoneCallsToProcessPageData message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SoftphoneCallsToProcessPageData} SoftphoneCallsToProcessPageData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneCallsToProcessPageData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SoftphoneCallsToProcessPageData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SoftphoneCallsToProcessPageData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SoftphoneCallsToProcessPageData} SoftphoneCallsToProcessPageData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SoftphoneCallsToProcessPageData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SoftphoneCallsToProcessPageData message.
                 * @function verify
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SoftphoneCallsToProcessPageData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a SoftphoneCallsToProcessPageData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SoftphoneCallsToProcessPageData} SoftphoneCallsToProcessPageData
                 */
                SoftphoneCallsToProcessPageData.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SoftphoneCallsToProcessPageData)
                        return object;
                    var message = new $root.infinitusai.be.SoftphoneCallsToProcessPageData();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SoftphoneCallsToProcessPageData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {infinitusai.be.SoftphoneCallsToProcessPageData} message SoftphoneCallsToProcessPageData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SoftphoneCallsToProcessPageData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this SoftphoneCallsToProcessPageData to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SoftphoneCallsToProcessPageData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SoftphoneCallsToProcessPageData
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SoftphoneCallsToProcessPageData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SoftphoneCallsToProcessPageData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SoftphoneCallsToProcessPageData";
                };
    
                return SoftphoneCallsToProcessPageData;
            })();
    
            be.FastTrackSoftphoneClientHeartbeat = (function() {
    
                /**
                 * Properties of a FastTrackSoftphoneClientHeartbeat.
                 * @memberof infinitusai.be
                 * @interface IFastTrackSoftphoneClientHeartbeat
                 * @property {Array.<infinitusai.be.IActiveCall>|null} [activeCalls] FastTrackSoftphoneClientHeartbeat activeCalls
                 * @property {infinitusai.be.IUnknownPageClientHeartbeat|null} [unknown] FastTrackSoftphoneClientHeartbeat unknown
                 * @property {infinitusai.be.ISoftphoneHomePageData|null} [home] FastTrackSoftphoneClientHeartbeat home
                 * @property {infinitusai.be.ISoftphoneProcessedCallHistoryPageData|null} [processedCallHistory] FastTrackSoftphoneClientHeartbeat processedCallHistory
                 * @property {infinitusai.be.ISoftphoneCallsToProcessPageData|null} [callsToProcess] FastTrackSoftphoneClientHeartbeat callsToProcess
                 * @property {boolean|null} [isProcessingCalls] FastTrackSoftphoneClientHeartbeat isProcessingCalls
                 */
    
                /**
                 * Constructs a new FastTrackSoftphoneClientHeartbeat.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FastTrackSoftphoneClientHeartbeat.
                 * @implements IFastTrackSoftphoneClientHeartbeat
                 * @constructor
                 * @param {infinitusai.be.IFastTrackSoftphoneClientHeartbeat=} [properties] Properties to set
                 */
                function FastTrackSoftphoneClientHeartbeat(properties) {
                    this.activeCalls = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FastTrackSoftphoneClientHeartbeat activeCalls.
                 * @member {Array.<infinitusai.be.IActiveCall>} activeCalls
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @instance
                 */
                FastTrackSoftphoneClientHeartbeat.prototype.activeCalls = $util.emptyArray;
    
                /**
                 * FastTrackSoftphoneClientHeartbeat unknown.
                 * @member {infinitusai.be.IUnknownPageClientHeartbeat|null|undefined} unknown
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @instance
                 */
                FastTrackSoftphoneClientHeartbeat.prototype.unknown = null;
    
                /**
                 * FastTrackSoftphoneClientHeartbeat home.
                 * @member {infinitusai.be.ISoftphoneHomePageData|null|undefined} home
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @instance
                 */
                FastTrackSoftphoneClientHeartbeat.prototype.home = null;
    
                /**
                 * FastTrackSoftphoneClientHeartbeat processedCallHistory.
                 * @member {infinitusai.be.ISoftphoneProcessedCallHistoryPageData|null|undefined} processedCallHistory
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @instance
                 */
                FastTrackSoftphoneClientHeartbeat.prototype.processedCallHistory = null;
    
                /**
                 * FastTrackSoftphoneClientHeartbeat callsToProcess.
                 * @member {infinitusai.be.ISoftphoneCallsToProcessPageData|null|undefined} callsToProcess
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @instance
                 */
                FastTrackSoftphoneClientHeartbeat.prototype.callsToProcess = null;
    
                /**
                 * FastTrackSoftphoneClientHeartbeat isProcessingCalls.
                 * @member {boolean} isProcessingCalls
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @instance
                 */
                FastTrackSoftphoneClientHeartbeat.prototype.isProcessingCalls = false;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * FastTrackSoftphoneClientHeartbeat pageData.
                 * @member {"unknown"|"home"|"processedCallHistory"|"callsToProcess"|undefined} pageData
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @instance
                 */
                Object.defineProperty(FastTrackSoftphoneClientHeartbeat.prototype, "pageData", {
                    get: $util.oneOfGetter($oneOfFields = ["unknown", "home", "processedCallHistory", "callsToProcess"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new FastTrackSoftphoneClientHeartbeat instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IFastTrackSoftphoneClientHeartbeat=} [properties] Properties to set
                 * @returns {infinitusai.be.FastTrackSoftphoneClientHeartbeat} FastTrackSoftphoneClientHeartbeat instance
                 */
                FastTrackSoftphoneClientHeartbeat.create = function create(properties) {
                    return new FastTrackSoftphoneClientHeartbeat(properties);
                };
    
                /**
                 * Encodes the specified FastTrackSoftphoneClientHeartbeat message. Does not implicitly {@link infinitusai.be.FastTrackSoftphoneClientHeartbeat.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IFastTrackSoftphoneClientHeartbeat} message FastTrackSoftphoneClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackSoftphoneClientHeartbeat.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.activeCalls != null && message.activeCalls.length)
                        for (var i = 0; i < message.activeCalls.length; ++i)
                            $root.infinitusai.be.ActiveCall.encode(message.activeCalls[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.unknown != null && Object.hasOwnProperty.call(message, "unknown"))
                        $root.infinitusai.be.UnknownPageClientHeartbeat.encode(message.unknown, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.home != null && Object.hasOwnProperty.call(message, "home"))
                        $root.infinitusai.be.SoftphoneHomePageData.encode(message.home, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.processedCallHistory != null && Object.hasOwnProperty.call(message, "processedCallHistory"))
                        $root.infinitusai.be.SoftphoneProcessedCallHistoryPageData.encode(message.processedCallHistory, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.isProcessingCalls != null && Object.hasOwnProperty.call(message, "isProcessingCalls"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isProcessingCalls);
                    if (message.callsToProcess != null && Object.hasOwnProperty.call(message, "callsToProcess"))
                        $root.infinitusai.be.SoftphoneCallsToProcessPageData.encode(message.callsToProcess, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FastTrackSoftphoneClientHeartbeat message, length delimited. Does not implicitly {@link infinitusai.be.FastTrackSoftphoneClientHeartbeat.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {infinitusai.be.IFastTrackSoftphoneClientHeartbeat} message FastTrackSoftphoneClientHeartbeat message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastTrackSoftphoneClientHeartbeat.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FastTrackSoftphoneClientHeartbeat message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FastTrackSoftphoneClientHeartbeat} FastTrackSoftphoneClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackSoftphoneClientHeartbeat.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FastTrackSoftphoneClientHeartbeat();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.activeCalls && message.activeCalls.length))
                                    message.activeCalls = [];
                                message.activeCalls.push($root.infinitusai.be.ActiveCall.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.unknown = $root.infinitusai.be.UnknownPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.home = $root.infinitusai.be.SoftphoneHomePageData.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.processedCallHistory = $root.infinitusai.be.SoftphoneProcessedCallHistoryPageData.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.callsToProcess = $root.infinitusai.be.SoftphoneCallsToProcessPageData.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.isProcessingCalls = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FastTrackSoftphoneClientHeartbeat message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FastTrackSoftphoneClientHeartbeat} FastTrackSoftphoneClientHeartbeat
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastTrackSoftphoneClientHeartbeat.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FastTrackSoftphoneClientHeartbeat message.
                 * @function verify
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastTrackSoftphoneClientHeartbeat.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.activeCalls != null && message.hasOwnProperty("activeCalls")) {
                        if (!Array.isArray(message.activeCalls))
                            return "activeCalls: array expected";
                        for (var i = 0; i < message.activeCalls.length; ++i) {
                            var error = $root.infinitusai.be.ActiveCall.verify(message.activeCalls[i]);
                            if (error)
                                return "activeCalls." + error;
                        }
                    }
                    if (message.unknown != null && message.hasOwnProperty("unknown")) {
                        properties.pageData = 1;
                        {
                            var error = $root.infinitusai.be.UnknownPageClientHeartbeat.verify(message.unknown);
                            if (error)
                                return "unknown." + error;
                        }
                    }
                    if (message.home != null && message.hasOwnProperty("home")) {
                        if (properties.pageData === 1)
                            return "pageData: multiple values";
                        properties.pageData = 1;
                        {
                            var error = $root.infinitusai.be.SoftphoneHomePageData.verify(message.home);
                            if (error)
                                return "home." + error;
                        }
                    }
                    if (message.processedCallHistory != null && message.hasOwnProperty("processedCallHistory")) {
                        if (properties.pageData === 1)
                            return "pageData: multiple values";
                        properties.pageData = 1;
                        {
                            var error = $root.infinitusai.be.SoftphoneProcessedCallHistoryPageData.verify(message.processedCallHistory);
                            if (error)
                                return "processedCallHistory." + error;
                        }
                    }
                    if (message.callsToProcess != null && message.hasOwnProperty("callsToProcess")) {
                        if (properties.pageData === 1)
                            return "pageData: multiple values";
                        properties.pageData = 1;
                        {
                            var error = $root.infinitusai.be.SoftphoneCallsToProcessPageData.verify(message.callsToProcess);
                            if (error)
                                return "callsToProcess." + error;
                        }
                    }
                    if (message.isProcessingCalls != null && message.hasOwnProperty("isProcessingCalls"))
                        if (typeof message.isProcessingCalls !== "boolean")
                            return "isProcessingCalls: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a FastTrackSoftphoneClientHeartbeat message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FastTrackSoftphoneClientHeartbeat} FastTrackSoftphoneClientHeartbeat
                 */
                FastTrackSoftphoneClientHeartbeat.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FastTrackSoftphoneClientHeartbeat)
                        return object;
                    var message = new $root.infinitusai.be.FastTrackSoftphoneClientHeartbeat();
                    if (object.activeCalls) {
                        if (!Array.isArray(object.activeCalls))
                            throw TypeError(".infinitusai.be.FastTrackSoftphoneClientHeartbeat.activeCalls: array expected");
                        message.activeCalls = [];
                        for (var i = 0; i < object.activeCalls.length; ++i) {
                            if (typeof object.activeCalls[i] !== "object")
                                throw TypeError(".infinitusai.be.FastTrackSoftphoneClientHeartbeat.activeCalls: object expected");
                            message.activeCalls[i] = $root.infinitusai.be.ActiveCall.fromObject(object.activeCalls[i]);
                        }
                    }
                    if (object.unknown != null) {
                        if (typeof object.unknown !== "object")
                            throw TypeError(".infinitusai.be.FastTrackSoftphoneClientHeartbeat.unknown: object expected");
                        message.unknown = $root.infinitusai.be.UnknownPageClientHeartbeat.fromObject(object.unknown);
                    }
                    if (object.home != null) {
                        if (typeof object.home !== "object")
                            throw TypeError(".infinitusai.be.FastTrackSoftphoneClientHeartbeat.home: object expected");
                        message.home = $root.infinitusai.be.SoftphoneHomePageData.fromObject(object.home);
                    }
                    if (object.processedCallHistory != null) {
                        if (typeof object.processedCallHistory !== "object")
                            throw TypeError(".infinitusai.be.FastTrackSoftphoneClientHeartbeat.processedCallHistory: object expected");
                        message.processedCallHistory = $root.infinitusai.be.SoftphoneProcessedCallHistoryPageData.fromObject(object.processedCallHistory);
                    }
                    if (object.callsToProcess != null) {
                        if (typeof object.callsToProcess !== "object")
                            throw TypeError(".infinitusai.be.FastTrackSoftphoneClientHeartbeat.callsToProcess: object expected");
                        message.callsToProcess = $root.infinitusai.be.SoftphoneCallsToProcessPageData.fromObject(object.callsToProcess);
                    }
                    if (object.isProcessingCalls != null)
                        message.isProcessingCalls = Boolean(object.isProcessingCalls);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FastTrackSoftphoneClientHeartbeat message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {infinitusai.be.FastTrackSoftphoneClientHeartbeat} message FastTrackSoftphoneClientHeartbeat
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastTrackSoftphoneClientHeartbeat.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.activeCalls = [];
                    if (options.defaults)
                        object.isProcessingCalls = false;
                    if (message.activeCalls && message.activeCalls.length) {
                        object.activeCalls = [];
                        for (var j = 0; j < message.activeCalls.length; ++j)
                            object.activeCalls[j] = $root.infinitusai.be.ActiveCall.toObject(message.activeCalls[j], options);
                    }
                    if (message.unknown != null && message.hasOwnProperty("unknown")) {
                        object.unknown = $root.infinitusai.be.UnknownPageClientHeartbeat.toObject(message.unknown, options);
                        if (options.oneofs)
                            object.pageData = "unknown";
                    }
                    if (message.home != null && message.hasOwnProperty("home")) {
                        object.home = $root.infinitusai.be.SoftphoneHomePageData.toObject(message.home, options);
                        if (options.oneofs)
                            object.pageData = "home";
                    }
                    if (message.processedCallHistory != null && message.hasOwnProperty("processedCallHistory")) {
                        object.processedCallHistory = $root.infinitusai.be.SoftphoneProcessedCallHistoryPageData.toObject(message.processedCallHistory, options);
                        if (options.oneofs)
                            object.pageData = "processedCallHistory";
                    }
                    if (message.isProcessingCalls != null && message.hasOwnProperty("isProcessingCalls"))
                        object.isProcessingCalls = message.isProcessingCalls;
                    if (message.callsToProcess != null && message.hasOwnProperty("callsToProcess")) {
                        object.callsToProcess = $root.infinitusai.be.SoftphoneCallsToProcessPageData.toObject(message.callsToProcess, options);
                        if (options.oneofs)
                            object.pageData = "callsToProcess";
                    }
                    return object;
                };
    
                /**
                 * Converts this FastTrackSoftphoneClientHeartbeat to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastTrackSoftphoneClientHeartbeat.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FastTrackSoftphoneClientHeartbeat
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FastTrackSoftphoneClientHeartbeat
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FastTrackSoftphoneClientHeartbeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FastTrackSoftphoneClientHeartbeat";
                };
    
                return FastTrackSoftphoneClientHeartbeat;
            })();
    
            be.HeartbeatMessageFromClient = (function() {
    
                /**
                 * Properties of a HeartbeatMessageFromClient.
                 * @memberof infinitusai.be
                 * @interface IHeartbeatMessageFromClient
                 * @property {infinitusai.be.HeartbeatMessageFromClient.PageType|null} [pageType] HeartbeatMessageFromClient pageType
                 * @property {infinitusai.be.ICallPageClientHeartbeat|null} [callPageData] HeartbeatMessageFromClient callPageData
                 * @property {infinitusai.be.IActiveCallsPageClientHeartbeat|null} [activeCallsPageData] HeartbeatMessageFromClient activeCallsPageData
                 * @property {infinitusai.be.IReadyPageClientHeartbeat|null} [readyPageData] HeartbeatMessageFromClient readyPageData
                 * @property {infinitusai.be.IUnknownPageClientHeartbeat|null} [unknownPageData] HeartbeatMessageFromClient unknownPageData
                 * @property {infinitusai.be.ITaskListPageClientHeartbeat|null} [taskListPageData] HeartbeatMessageFromClient taskListPageData
                 * @property {infinitusai.be.IAdminPortalPageClientHeartbeat|null} [adminPortalPageData] HeartbeatMessageFromClient adminPortalPageData
                 * @property {infinitusai.be.ITaskPageClientHeartbeat|null} [taskPageData] HeartbeatMessageFromClient taskPageData
                 * @property {infinitusai.be.IFastTrackSoftphoneClientHeartbeat|null} [fasttrackSoftphonePageData] HeartbeatMessageFromClient fasttrackSoftphonePageData
                 * @property {infinitusai.be.INoPageClientHeartbeat|null} [noPageData] HeartbeatMessageFromClient noPageData
                 * @property {string|null} [frontendVersion] HeartbeatMessageFromClient frontendVersion
                 * @property {number|Long|null} [timestamp] HeartbeatMessageFromClient timestamp
                 * @property {string|null} [browserSessionUuid] HeartbeatMessageFromClient browserSessionUuid
                 * @property {number|Long|null} [lastKnownActivityTimestampMillis] HeartbeatMessageFromClient lastKnownActivityTimestampMillis
                 * @property {number|Long|null} [presenceLastUpdatedTimestamp] HeartbeatMessageFromClient presenceLastUpdatedTimestamp
                 * @property {string|null} [orgUuid] HeartbeatMessageFromClient orgUuid
                 * @property {infinitusai.be.HeartbeatMessageFromClient.Application|null} [application] HeartbeatMessageFromClient application
                 */
    
                /**
                 * Constructs a new HeartbeatMessageFromClient.
                 * @memberof infinitusai.be
                 * @classdesc Represents a HeartbeatMessageFromClient.
                 * @implements IHeartbeatMessageFromClient
                 * @constructor
                 * @param {infinitusai.be.IHeartbeatMessageFromClient=} [properties] Properties to set
                 */
                function HeartbeatMessageFromClient(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * HeartbeatMessageFromClient pageType.
                 * @member {infinitusai.be.HeartbeatMessageFromClient.PageType} pageType
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.pageType = 0;
    
                /**
                 * HeartbeatMessageFromClient callPageData.
                 * @member {infinitusai.be.ICallPageClientHeartbeat|null|undefined} callPageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.callPageData = null;
    
                /**
                 * HeartbeatMessageFromClient activeCallsPageData.
                 * @member {infinitusai.be.IActiveCallsPageClientHeartbeat|null|undefined} activeCallsPageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.activeCallsPageData = null;
    
                /**
                 * HeartbeatMessageFromClient readyPageData.
                 * @member {infinitusai.be.IReadyPageClientHeartbeat|null|undefined} readyPageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.readyPageData = null;
    
                /**
                 * HeartbeatMessageFromClient unknownPageData.
                 * @member {infinitusai.be.IUnknownPageClientHeartbeat|null|undefined} unknownPageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.unknownPageData = null;
    
                /**
                 * HeartbeatMessageFromClient taskListPageData.
                 * @member {infinitusai.be.ITaskListPageClientHeartbeat|null|undefined} taskListPageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.taskListPageData = null;
    
                /**
                 * HeartbeatMessageFromClient adminPortalPageData.
                 * @member {infinitusai.be.IAdminPortalPageClientHeartbeat|null|undefined} adminPortalPageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.adminPortalPageData = null;
    
                /**
                 * HeartbeatMessageFromClient taskPageData.
                 * @member {infinitusai.be.ITaskPageClientHeartbeat|null|undefined} taskPageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.taskPageData = null;
    
                /**
                 * HeartbeatMessageFromClient fasttrackSoftphonePageData.
                 * @member {infinitusai.be.IFastTrackSoftphoneClientHeartbeat|null|undefined} fasttrackSoftphonePageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.fasttrackSoftphonePageData = null;
    
                /**
                 * HeartbeatMessageFromClient noPageData.
                 * @member {infinitusai.be.INoPageClientHeartbeat|null|undefined} noPageData
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.noPageData = null;
    
                /**
                 * HeartbeatMessageFromClient frontendVersion.
                 * @member {string} frontendVersion
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.frontendVersion = "";
    
                /**
                 * HeartbeatMessageFromClient timestamp.
                 * @member {number|Long} timestamp
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * HeartbeatMessageFromClient browserSessionUuid.
                 * @member {string} browserSessionUuid
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.browserSessionUuid = "";
    
                /**
                 * HeartbeatMessageFromClient lastKnownActivityTimestampMillis.
                 * @member {number|Long} lastKnownActivityTimestampMillis
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.lastKnownActivityTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * HeartbeatMessageFromClient presenceLastUpdatedTimestamp.
                 * @member {number|Long} presenceLastUpdatedTimestamp
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.presenceLastUpdatedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * HeartbeatMessageFromClient orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.orgUuid = "";
    
                /**
                 * HeartbeatMessageFromClient application.
                 * @member {infinitusai.be.HeartbeatMessageFromClient.Application} application
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                HeartbeatMessageFromClient.prototype.application = 0;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * HeartbeatMessageFromClient data.
                 * @member {"callPageData"|"activeCallsPageData"|"readyPageData"|"unknownPageData"|"taskListPageData"|"adminPortalPageData"|"taskPageData"|"fasttrackSoftphonePageData"|"noPageData"|undefined} data
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 */
                Object.defineProperty(HeartbeatMessageFromClient.prototype, "data", {
                    get: $util.oneOfGetter($oneOfFields = ["callPageData", "activeCallsPageData", "readyPageData", "unknownPageData", "taskListPageData", "adminPortalPageData", "taskPageData", "fasttrackSoftphonePageData", "noPageData"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new HeartbeatMessageFromClient instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {infinitusai.be.IHeartbeatMessageFromClient=} [properties] Properties to set
                 * @returns {infinitusai.be.HeartbeatMessageFromClient} HeartbeatMessageFromClient instance
                 */
                HeartbeatMessageFromClient.create = function create(properties) {
                    return new HeartbeatMessageFromClient(properties);
                };
    
                /**
                 * Encodes the specified HeartbeatMessageFromClient message. Does not implicitly {@link infinitusai.be.HeartbeatMessageFromClient.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {infinitusai.be.IHeartbeatMessageFromClient} message HeartbeatMessageFromClient message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HeartbeatMessageFromClient.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pageType != null && Object.hasOwnProperty.call(message, "pageType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageType);
                    if (message.callPageData != null && Object.hasOwnProperty.call(message, "callPageData"))
                        $root.infinitusai.be.CallPageClientHeartbeat.encode(message.callPageData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.activeCallsPageData != null && Object.hasOwnProperty.call(message, "activeCallsPageData"))
                        $root.infinitusai.be.ActiveCallsPageClientHeartbeat.encode(message.activeCallsPageData, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.frontendVersion != null && Object.hasOwnProperty.call(message, "frontendVersion"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.frontendVersion);
                    if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.timestamp);
                    if (message.readyPageData != null && Object.hasOwnProperty.call(message, "readyPageData"))
                        $root.infinitusai.be.ReadyPageClientHeartbeat.encode(message.readyPageData, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.browserSessionUuid != null && Object.hasOwnProperty.call(message, "browserSessionUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.browserSessionUuid);
                    if (message.lastKnownActivityTimestampMillis != null && Object.hasOwnProperty.call(message, "lastKnownActivityTimestampMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.lastKnownActivityTimestampMillis);
                    if (message.unknownPageData != null && Object.hasOwnProperty.call(message, "unknownPageData"))
                        $root.infinitusai.be.UnknownPageClientHeartbeat.encode(message.unknownPageData, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.taskListPageData != null && Object.hasOwnProperty.call(message, "taskListPageData"))
                        $root.infinitusai.be.TaskListPageClientHeartbeat.encode(message.taskListPageData, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.presenceLastUpdatedTimestamp != null && Object.hasOwnProperty.call(message, "presenceLastUpdatedTimestamp"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int64(message.presenceLastUpdatedTimestamp);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.orgUuid);
                    if (message.adminPortalPageData != null && Object.hasOwnProperty.call(message, "adminPortalPageData"))
                        $root.infinitusai.be.AdminPortalPageClientHeartbeat.encode(message.adminPortalPageData, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.taskPageData != null && Object.hasOwnProperty.call(message, "taskPageData"))
                        $root.infinitusai.be.TaskPageClientHeartbeat.encode(message.taskPageData, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.application != null && Object.hasOwnProperty.call(message, "application"))
                        writer.uint32(/* id 16, wireType 0 =*/128).int32(message.application);
                    if (message.noPageData != null && Object.hasOwnProperty.call(message, "noPageData"))
                        $root.infinitusai.be.NoPageClientHeartbeat.encode(message.noPageData, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.fasttrackSoftphonePageData != null && Object.hasOwnProperty.call(message, "fasttrackSoftphonePageData"))
                        $root.infinitusai.be.FastTrackSoftphoneClientHeartbeat.encode(message.fasttrackSoftphonePageData, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified HeartbeatMessageFromClient message, length delimited. Does not implicitly {@link infinitusai.be.HeartbeatMessageFromClient.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {infinitusai.be.IHeartbeatMessageFromClient} message HeartbeatMessageFromClient message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HeartbeatMessageFromClient.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a HeartbeatMessageFromClient message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.HeartbeatMessageFromClient} HeartbeatMessageFromClient
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HeartbeatMessageFromClient.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.HeartbeatMessageFromClient();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pageType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.callPageData = $root.infinitusai.be.CallPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.activeCallsPageData = $root.infinitusai.be.ActiveCallsPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.readyPageData = $root.infinitusai.be.ReadyPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.unknownPageData = $root.infinitusai.be.UnknownPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.taskListPageData = $root.infinitusai.be.TaskListPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.adminPortalPageData = $root.infinitusai.be.AdminPortalPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.taskPageData = $root.infinitusai.be.TaskPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.fasttrackSoftphonePageData = $root.infinitusai.be.FastTrackSoftphoneClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                message.noPageData = $root.infinitusai.be.NoPageClientHeartbeat.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.frontendVersion = reader.string();
                                break;
                            }
                        case 5: {
                                message.timestamp = reader.int64();
                                break;
                            }
                        case 7: {
                                message.browserSessionUuid = reader.string();
                                break;
                            }
                        case 8: {
                                message.lastKnownActivityTimestampMillis = reader.int64();
                                break;
                            }
                        case 11: {
                                message.presenceLastUpdatedTimestamp = reader.int64();
                                break;
                            }
                        case 12: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 16: {
                                message.application = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a HeartbeatMessageFromClient message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.HeartbeatMessageFromClient} HeartbeatMessageFromClient
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HeartbeatMessageFromClient.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a HeartbeatMessageFromClient message.
                 * @function verify
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HeartbeatMessageFromClient.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.pageType != null && message.hasOwnProperty("pageType"))
                        switch (message.pageType) {
                        default:
                            return "pageType: enum value expected";
                        case 0:
                        case 17:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 14:
                            break;
                        }
                    if (message.callPageData != null && message.hasOwnProperty("callPageData")) {
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.CallPageClientHeartbeat.verify(message.callPageData);
                            if (error)
                                return "callPageData." + error;
                        }
                    }
                    if (message.activeCallsPageData != null && message.hasOwnProperty("activeCallsPageData")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.ActiveCallsPageClientHeartbeat.verify(message.activeCallsPageData);
                            if (error)
                                return "activeCallsPageData." + error;
                        }
                    }
                    if (message.readyPageData != null && message.hasOwnProperty("readyPageData")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.ReadyPageClientHeartbeat.verify(message.readyPageData);
                            if (error)
                                return "readyPageData." + error;
                        }
                    }
                    if (message.unknownPageData != null && message.hasOwnProperty("unknownPageData")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.UnknownPageClientHeartbeat.verify(message.unknownPageData);
                            if (error)
                                return "unknownPageData." + error;
                        }
                    }
                    if (message.taskListPageData != null && message.hasOwnProperty("taskListPageData")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.TaskListPageClientHeartbeat.verify(message.taskListPageData);
                            if (error)
                                return "taskListPageData." + error;
                        }
                    }
                    if (message.adminPortalPageData != null && message.hasOwnProperty("adminPortalPageData")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.AdminPortalPageClientHeartbeat.verify(message.adminPortalPageData);
                            if (error)
                                return "adminPortalPageData." + error;
                        }
                    }
                    if (message.taskPageData != null && message.hasOwnProperty("taskPageData")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.TaskPageClientHeartbeat.verify(message.taskPageData);
                            if (error)
                                return "taskPageData." + error;
                        }
                    }
                    if (message.fasttrackSoftphonePageData != null && message.hasOwnProperty("fasttrackSoftphonePageData")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.FastTrackSoftphoneClientHeartbeat.verify(message.fasttrackSoftphonePageData);
                            if (error)
                                return "fasttrackSoftphonePageData." + error;
                        }
                    }
                    if (message.noPageData != null && message.hasOwnProperty("noPageData")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.NoPageClientHeartbeat.verify(message.noPageData);
                            if (error)
                                return "noPageData." + error;
                        }
                    }
                    if (message.frontendVersion != null && message.hasOwnProperty("frontendVersion"))
                        if (!$util.isString(message.frontendVersion))
                            return "frontendVersion: string expected";
                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                        if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                            return "timestamp: integer|Long expected";
                    if (message.browserSessionUuid != null && message.hasOwnProperty("browserSessionUuid"))
                        if (!$util.isString(message.browserSessionUuid))
                            return "browserSessionUuid: string expected";
                    if (message.lastKnownActivityTimestampMillis != null && message.hasOwnProperty("lastKnownActivityTimestampMillis"))
                        if (!$util.isInteger(message.lastKnownActivityTimestampMillis) && !(message.lastKnownActivityTimestampMillis && $util.isInteger(message.lastKnownActivityTimestampMillis.low) && $util.isInteger(message.lastKnownActivityTimestampMillis.high)))
                            return "lastKnownActivityTimestampMillis: integer|Long expected";
                    if (message.presenceLastUpdatedTimestamp != null && message.hasOwnProperty("presenceLastUpdatedTimestamp"))
                        if (!$util.isInteger(message.presenceLastUpdatedTimestamp) && !(message.presenceLastUpdatedTimestamp && $util.isInteger(message.presenceLastUpdatedTimestamp.low) && $util.isInteger(message.presenceLastUpdatedTimestamp.high)))
                            return "presenceLastUpdatedTimestamp: integer|Long expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.application != null && message.hasOwnProperty("application"))
                        switch (message.application) {
                        default:
                            return "application: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a HeartbeatMessageFromClient message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.HeartbeatMessageFromClient} HeartbeatMessageFromClient
                 */
                HeartbeatMessageFromClient.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.HeartbeatMessageFromClient)
                        return object;
                    var message = new $root.infinitusai.be.HeartbeatMessageFromClient();
                    switch (object.pageType) {
                    default:
                        if (typeof object.pageType === "number") {
                            message.pageType = object.pageType;
                            break;
                        }
                        break;
                    case "UNKNOWN_PAGE":
                    case 0:
                        message.pageType = 0;
                        break;
                    case "NO_PAGE":
                    case 17:
                        message.pageType = 17;
                        break;
                    case "CALL_PAGE":
                    case 1:
                        message.pageType = 1;
                        break;
                    case "ACTIVE_CALLS_PAGE":
                    case 2:
                        message.pageType = 2;
                        break;
                    case "READY_PAGE":
                    case 3:
                        message.pageType = 3;
                        break;
                    case "TASK_LIST_PAGE":
                    case 4:
                        message.pageType = 4;
                        break;
                    case "ADMIN_PORTAL":
                    case 5:
                        message.pageType = 5;
                        break;
                    case "FASTTRACK_SOFTPHONE_PAGE":
                    case 6:
                        message.pageType = 6;
                        break;
                    case "TASK_PAGE":
                    case 14:
                        message.pageType = 14;
                        break;
                    }
                    if (object.callPageData != null) {
                        if (typeof object.callPageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.callPageData: object expected");
                        message.callPageData = $root.infinitusai.be.CallPageClientHeartbeat.fromObject(object.callPageData);
                    }
                    if (object.activeCallsPageData != null) {
                        if (typeof object.activeCallsPageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.activeCallsPageData: object expected");
                        message.activeCallsPageData = $root.infinitusai.be.ActiveCallsPageClientHeartbeat.fromObject(object.activeCallsPageData);
                    }
                    if (object.readyPageData != null) {
                        if (typeof object.readyPageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.readyPageData: object expected");
                        message.readyPageData = $root.infinitusai.be.ReadyPageClientHeartbeat.fromObject(object.readyPageData);
                    }
                    if (object.unknownPageData != null) {
                        if (typeof object.unknownPageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.unknownPageData: object expected");
                        message.unknownPageData = $root.infinitusai.be.UnknownPageClientHeartbeat.fromObject(object.unknownPageData);
                    }
                    if (object.taskListPageData != null) {
                        if (typeof object.taskListPageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.taskListPageData: object expected");
                        message.taskListPageData = $root.infinitusai.be.TaskListPageClientHeartbeat.fromObject(object.taskListPageData);
                    }
                    if (object.adminPortalPageData != null) {
                        if (typeof object.adminPortalPageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.adminPortalPageData: object expected");
                        message.adminPortalPageData = $root.infinitusai.be.AdminPortalPageClientHeartbeat.fromObject(object.adminPortalPageData);
                    }
                    if (object.taskPageData != null) {
                        if (typeof object.taskPageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.taskPageData: object expected");
                        message.taskPageData = $root.infinitusai.be.TaskPageClientHeartbeat.fromObject(object.taskPageData);
                    }
                    if (object.fasttrackSoftphonePageData != null) {
                        if (typeof object.fasttrackSoftphonePageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.fasttrackSoftphonePageData: object expected");
                        message.fasttrackSoftphonePageData = $root.infinitusai.be.FastTrackSoftphoneClientHeartbeat.fromObject(object.fasttrackSoftphonePageData);
                    }
                    if (object.noPageData != null) {
                        if (typeof object.noPageData !== "object")
                            throw TypeError(".infinitusai.be.HeartbeatMessageFromClient.noPageData: object expected");
                        message.noPageData = $root.infinitusai.be.NoPageClientHeartbeat.fromObject(object.noPageData);
                    }
                    if (object.frontendVersion != null)
                        message.frontendVersion = String(object.frontendVersion);
                    if (object.timestamp != null)
                        if ($util.Long)
                            (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                        else if (typeof object.timestamp === "string")
                            message.timestamp = parseInt(object.timestamp, 10);
                        else if (typeof object.timestamp === "number")
                            message.timestamp = object.timestamp;
                        else if (typeof object.timestamp === "object")
                            message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                    if (object.browserSessionUuid != null)
                        message.browserSessionUuid = String(object.browserSessionUuid);
                    if (object.lastKnownActivityTimestampMillis != null)
                        if ($util.Long)
                            (message.lastKnownActivityTimestampMillis = $util.Long.fromValue(object.lastKnownActivityTimestampMillis)).unsigned = false;
                        else if (typeof object.lastKnownActivityTimestampMillis === "string")
                            message.lastKnownActivityTimestampMillis = parseInt(object.lastKnownActivityTimestampMillis, 10);
                        else if (typeof object.lastKnownActivityTimestampMillis === "number")
                            message.lastKnownActivityTimestampMillis = object.lastKnownActivityTimestampMillis;
                        else if (typeof object.lastKnownActivityTimestampMillis === "object")
                            message.lastKnownActivityTimestampMillis = new $util.LongBits(object.lastKnownActivityTimestampMillis.low >>> 0, object.lastKnownActivityTimestampMillis.high >>> 0).toNumber();
                    if (object.presenceLastUpdatedTimestamp != null)
                        if ($util.Long)
                            (message.presenceLastUpdatedTimestamp = $util.Long.fromValue(object.presenceLastUpdatedTimestamp)).unsigned = false;
                        else if (typeof object.presenceLastUpdatedTimestamp === "string")
                            message.presenceLastUpdatedTimestamp = parseInt(object.presenceLastUpdatedTimestamp, 10);
                        else if (typeof object.presenceLastUpdatedTimestamp === "number")
                            message.presenceLastUpdatedTimestamp = object.presenceLastUpdatedTimestamp;
                        else if (typeof object.presenceLastUpdatedTimestamp === "object")
                            message.presenceLastUpdatedTimestamp = new $util.LongBits(object.presenceLastUpdatedTimestamp.low >>> 0, object.presenceLastUpdatedTimestamp.high >>> 0).toNumber();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    switch (object.application) {
                    default:
                        if (typeof object.application === "number") {
                            message.application = object.application;
                            break;
                        }
                        break;
                    case "UNKNOWN_APPLICATION":
                    case 0:
                        message.application = 0;
                        break;
                    case "OPERATOR_PORTAL":
                    case 1:
                        message.application = 1;
                        break;
                    case "FASTTRACK":
                    case 2:
                        message.application = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a HeartbeatMessageFromClient message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {infinitusai.be.HeartbeatMessageFromClient} message HeartbeatMessageFromClient
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HeartbeatMessageFromClient.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.pageType = options.enums === String ? "UNKNOWN_PAGE" : 0;
                        object.frontendVersion = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestamp = options.longs === String ? "0" : 0;
                        object.browserSessionUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastKnownActivityTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastKnownActivityTimestampMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.presenceLastUpdatedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.presenceLastUpdatedTimestamp = options.longs === String ? "0" : 0;
                        object.orgUuid = "";
                        object.application = options.enums === String ? "UNKNOWN_APPLICATION" : 0;
                    }
                    if (message.pageType != null && message.hasOwnProperty("pageType"))
                        object.pageType = options.enums === String ? $root.infinitusai.be.HeartbeatMessageFromClient.PageType[message.pageType] === undefined ? message.pageType : $root.infinitusai.be.HeartbeatMessageFromClient.PageType[message.pageType] : message.pageType;
                    if (message.callPageData != null && message.hasOwnProperty("callPageData")) {
                        object.callPageData = $root.infinitusai.be.CallPageClientHeartbeat.toObject(message.callPageData, options);
                        if (options.oneofs)
                            object.data = "callPageData";
                    }
                    if (message.activeCallsPageData != null && message.hasOwnProperty("activeCallsPageData")) {
                        object.activeCallsPageData = $root.infinitusai.be.ActiveCallsPageClientHeartbeat.toObject(message.activeCallsPageData, options);
                        if (options.oneofs)
                            object.data = "activeCallsPageData";
                    }
                    if (message.frontendVersion != null && message.hasOwnProperty("frontendVersion"))
                        object.frontendVersion = message.frontendVersion;
                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                        if (typeof message.timestamp === "number")
                            object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                        else
                            object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                    if (message.readyPageData != null && message.hasOwnProperty("readyPageData")) {
                        object.readyPageData = $root.infinitusai.be.ReadyPageClientHeartbeat.toObject(message.readyPageData, options);
                        if (options.oneofs)
                            object.data = "readyPageData";
                    }
                    if (message.browserSessionUuid != null && message.hasOwnProperty("browserSessionUuid"))
                        object.browserSessionUuid = message.browserSessionUuid;
                    if (message.lastKnownActivityTimestampMillis != null && message.hasOwnProperty("lastKnownActivityTimestampMillis"))
                        if (typeof message.lastKnownActivityTimestampMillis === "number")
                            object.lastKnownActivityTimestampMillis = options.longs === String ? String(message.lastKnownActivityTimestampMillis) : message.lastKnownActivityTimestampMillis;
                        else
                            object.lastKnownActivityTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastKnownActivityTimestampMillis) : options.longs === Number ? new $util.LongBits(message.lastKnownActivityTimestampMillis.low >>> 0, message.lastKnownActivityTimestampMillis.high >>> 0).toNumber() : message.lastKnownActivityTimestampMillis;
                    if (message.unknownPageData != null && message.hasOwnProperty("unknownPageData")) {
                        object.unknownPageData = $root.infinitusai.be.UnknownPageClientHeartbeat.toObject(message.unknownPageData, options);
                        if (options.oneofs)
                            object.data = "unknownPageData";
                    }
                    if (message.taskListPageData != null && message.hasOwnProperty("taskListPageData")) {
                        object.taskListPageData = $root.infinitusai.be.TaskListPageClientHeartbeat.toObject(message.taskListPageData, options);
                        if (options.oneofs)
                            object.data = "taskListPageData";
                    }
                    if (message.presenceLastUpdatedTimestamp != null && message.hasOwnProperty("presenceLastUpdatedTimestamp"))
                        if (typeof message.presenceLastUpdatedTimestamp === "number")
                            object.presenceLastUpdatedTimestamp = options.longs === String ? String(message.presenceLastUpdatedTimestamp) : message.presenceLastUpdatedTimestamp;
                        else
                            object.presenceLastUpdatedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.presenceLastUpdatedTimestamp) : options.longs === Number ? new $util.LongBits(message.presenceLastUpdatedTimestamp.low >>> 0, message.presenceLastUpdatedTimestamp.high >>> 0).toNumber() : message.presenceLastUpdatedTimestamp;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.adminPortalPageData != null && message.hasOwnProperty("adminPortalPageData")) {
                        object.adminPortalPageData = $root.infinitusai.be.AdminPortalPageClientHeartbeat.toObject(message.adminPortalPageData, options);
                        if (options.oneofs)
                            object.data = "adminPortalPageData";
                    }
                    if (message.taskPageData != null && message.hasOwnProperty("taskPageData")) {
                        object.taskPageData = $root.infinitusai.be.TaskPageClientHeartbeat.toObject(message.taskPageData, options);
                        if (options.oneofs)
                            object.data = "taskPageData";
                    }
                    if (message.application != null && message.hasOwnProperty("application"))
                        object.application = options.enums === String ? $root.infinitusai.be.HeartbeatMessageFromClient.Application[message.application] === undefined ? message.application : $root.infinitusai.be.HeartbeatMessageFromClient.Application[message.application] : message.application;
                    if (message.noPageData != null && message.hasOwnProperty("noPageData")) {
                        object.noPageData = $root.infinitusai.be.NoPageClientHeartbeat.toObject(message.noPageData, options);
                        if (options.oneofs)
                            object.data = "noPageData";
                    }
                    if (message.fasttrackSoftphonePageData != null && message.hasOwnProperty("fasttrackSoftphonePageData")) {
                        object.fasttrackSoftphonePageData = $root.infinitusai.be.FastTrackSoftphoneClientHeartbeat.toObject(message.fasttrackSoftphonePageData, options);
                        if (options.oneofs)
                            object.data = "fasttrackSoftphonePageData";
                    }
                    return object;
                };
    
                /**
                 * Converts this HeartbeatMessageFromClient to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HeartbeatMessageFromClient.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for HeartbeatMessageFromClient
                 * @function getTypeUrl
                 * @memberof infinitusai.be.HeartbeatMessageFromClient
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                HeartbeatMessageFromClient.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.HeartbeatMessageFromClient";
                };
    
                /**
                 * PageType enum.
                 * @name infinitusai.be.HeartbeatMessageFromClient.PageType
                 * @enum {number}
                 * @property {number} UNKNOWN_PAGE=0 UNKNOWN_PAGE value
                 * @property {number} NO_PAGE=17 NO_PAGE value
                 * @property {number} CALL_PAGE=1 CALL_PAGE value
                 * @property {number} ACTIVE_CALLS_PAGE=2 ACTIVE_CALLS_PAGE value
                 * @property {number} READY_PAGE=3 READY_PAGE value
                 * @property {number} TASK_LIST_PAGE=4 TASK_LIST_PAGE value
                 * @property {number} ADMIN_PORTAL=5 ADMIN_PORTAL value
                 * @property {number} FASTTRACK_SOFTPHONE_PAGE=6 FASTTRACK_SOFTPHONE_PAGE value
                 * @property {number} TASK_PAGE=14 TASK_PAGE value
                 */
                HeartbeatMessageFromClient.PageType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_PAGE"] = 0;
                    values[valuesById[17] = "NO_PAGE"] = 17;
                    values[valuesById[1] = "CALL_PAGE"] = 1;
                    values[valuesById[2] = "ACTIVE_CALLS_PAGE"] = 2;
                    values[valuesById[3] = "READY_PAGE"] = 3;
                    values[valuesById[4] = "TASK_LIST_PAGE"] = 4;
                    values[valuesById[5] = "ADMIN_PORTAL"] = 5;
                    values[valuesById[6] = "FASTTRACK_SOFTPHONE_PAGE"] = 6;
                    values[valuesById[14] = "TASK_PAGE"] = 14;
                    return values;
                })();
    
                /**
                 * Application enum.
                 * @name infinitusai.be.HeartbeatMessageFromClient.Application
                 * @enum {number}
                 * @property {number} UNKNOWN_APPLICATION=0 UNKNOWN_APPLICATION value
                 * @property {number} OPERATOR_PORTAL=1 OPERATOR_PORTAL value
                 * @property {number} FASTTRACK=2 FASTTRACK value
                 */
                HeartbeatMessageFromClient.Application = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_APPLICATION"] = 0;
                    values[valuesById[1] = "OPERATOR_PORTAL"] = 1;
                    values[valuesById[2] = "FASTTRACK"] = 2;
                    return values;
                })();
    
                return HeartbeatMessageFromClient;
            })();
    
            be.HeartbeatMessageFromServer = (function() {
    
                /**
                 * Properties of a HeartbeatMessageFromServer.
                 * @memberof infinitusai.be
                 * @interface IHeartbeatMessageFromServer
                 */
    
                /**
                 * Constructs a new HeartbeatMessageFromServer.
                 * @memberof infinitusai.be
                 * @classdesc Represents a HeartbeatMessageFromServer.
                 * @implements IHeartbeatMessageFromServer
                 * @constructor
                 * @param {infinitusai.be.IHeartbeatMessageFromServer=} [properties] Properties to set
                 */
                function HeartbeatMessageFromServer(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new HeartbeatMessageFromServer instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {infinitusai.be.IHeartbeatMessageFromServer=} [properties] Properties to set
                 * @returns {infinitusai.be.HeartbeatMessageFromServer} HeartbeatMessageFromServer instance
                 */
                HeartbeatMessageFromServer.create = function create(properties) {
                    return new HeartbeatMessageFromServer(properties);
                };
    
                /**
                 * Encodes the specified HeartbeatMessageFromServer message. Does not implicitly {@link infinitusai.be.HeartbeatMessageFromServer.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {infinitusai.be.IHeartbeatMessageFromServer} message HeartbeatMessageFromServer message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HeartbeatMessageFromServer.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified HeartbeatMessageFromServer message, length delimited. Does not implicitly {@link infinitusai.be.HeartbeatMessageFromServer.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {infinitusai.be.IHeartbeatMessageFromServer} message HeartbeatMessageFromServer message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HeartbeatMessageFromServer.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a HeartbeatMessageFromServer message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.HeartbeatMessageFromServer} HeartbeatMessageFromServer
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HeartbeatMessageFromServer.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.HeartbeatMessageFromServer();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a HeartbeatMessageFromServer message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.HeartbeatMessageFromServer} HeartbeatMessageFromServer
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HeartbeatMessageFromServer.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a HeartbeatMessageFromServer message.
                 * @function verify
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HeartbeatMessageFromServer.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a HeartbeatMessageFromServer message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.HeartbeatMessageFromServer} HeartbeatMessageFromServer
                 */
                HeartbeatMessageFromServer.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.HeartbeatMessageFromServer)
                        return object;
                    return new $root.infinitusai.be.HeartbeatMessageFromServer();
                };
    
                /**
                 * Creates a plain object from a HeartbeatMessageFromServer message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {infinitusai.be.HeartbeatMessageFromServer} message HeartbeatMessageFromServer
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HeartbeatMessageFromServer.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this HeartbeatMessageFromServer to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HeartbeatMessageFromServer.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for HeartbeatMessageFromServer
                 * @function getTypeUrl
                 * @memberof infinitusai.be.HeartbeatMessageFromServer
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                HeartbeatMessageFromServer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.HeartbeatMessageFromServer";
                };
    
                return HeartbeatMessageFromServer;
            })();
    
            be.PlayAudioMessage = (function() {
    
                /**
                 * Properties of a PlayAudioMessage.
                 * @memberof infinitusai.be
                 * @interface IPlayAudioMessage
                 * @property {string|null} [orgUuid] PlayAudioMessage orgUuid
                 * @property {string|null} [taskId] PlayAudioMessage taskId
                 * @property {string|null} [callId] PlayAudioMessage callId
                 * @property {Uint8Array|null} [audio] PlayAudioMessage audio
                 * @property {string|null} [operatorEmail] PlayAudioMessage operatorEmail
                 */
    
                /**
                 * Constructs a new PlayAudioMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PlayAudioMessage.
                 * @implements IPlayAudioMessage
                 * @constructor
                 * @param {infinitusai.be.IPlayAudioMessage=} [properties] Properties to set
                 */
                function PlayAudioMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PlayAudioMessage orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @instance
                 */
                PlayAudioMessage.prototype.orgUuid = "";
    
                /**
                 * PlayAudioMessage taskId.
                 * @member {string} taskId
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @instance
                 */
                PlayAudioMessage.prototype.taskId = "";
    
                /**
                 * PlayAudioMessage callId.
                 * @member {string} callId
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @instance
                 */
                PlayAudioMessage.prototype.callId = "";
    
                /**
                 * PlayAudioMessage audio.
                 * @member {Uint8Array} audio
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @instance
                 */
                PlayAudioMessage.prototype.audio = $util.newBuffer([]);
    
                /**
                 * PlayAudioMessage operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @instance
                 */
                PlayAudioMessage.prototype.operatorEmail = "";
    
                /**
                 * Creates a new PlayAudioMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {infinitusai.be.IPlayAudioMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.PlayAudioMessage} PlayAudioMessage instance
                 */
                PlayAudioMessage.create = function create(properties) {
                    return new PlayAudioMessage(properties);
                };
    
                /**
                 * Encodes the specified PlayAudioMessage message. Does not implicitly {@link infinitusai.be.PlayAudioMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {infinitusai.be.IPlayAudioMessage} message PlayAudioMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PlayAudioMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskId);
                    if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callId);
                    if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                        writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.audio);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.operatorEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified PlayAudioMessage message, length delimited. Does not implicitly {@link infinitusai.be.PlayAudioMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {infinitusai.be.IPlayAudioMessage} message PlayAudioMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PlayAudioMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PlayAudioMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PlayAudioMessage} PlayAudioMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PlayAudioMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PlayAudioMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskId = reader.string();
                                break;
                            }
                        case 3: {
                                message.callId = reader.string();
                                break;
                            }
                        case 4: {
                                message.audio = reader.bytes();
                                break;
                            }
                        case 5: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PlayAudioMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PlayAudioMessage} PlayAudioMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PlayAudioMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PlayAudioMessage message.
                 * @function verify
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PlayAudioMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        if (!$util.isString(message.taskId))
                            return "taskId: string expected";
                    if (message.callId != null && message.hasOwnProperty("callId"))
                        if (!$util.isString(message.callId))
                            return "callId: string expected";
                    if (message.audio != null && message.hasOwnProperty("audio"))
                        if (!(message.audio && typeof message.audio.length === "number" || $util.isString(message.audio)))
                            return "audio: buffer expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a PlayAudioMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PlayAudioMessage} PlayAudioMessage
                 */
                PlayAudioMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PlayAudioMessage)
                        return object;
                    var message = new $root.infinitusai.be.PlayAudioMessage();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskId != null)
                        message.taskId = String(object.taskId);
                    if (object.callId != null)
                        message.callId = String(object.callId);
                    if (object.audio != null)
                        if (typeof object.audio === "string")
                            $util.base64.decode(object.audio, message.audio = $util.newBuffer($util.base64.length(object.audio)), 0);
                        else if (object.audio.length >= 0)
                            message.audio = object.audio;
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PlayAudioMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {infinitusai.be.PlayAudioMessage} message PlayAudioMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PlayAudioMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskId = "";
                        object.callId = "";
                        if (options.bytes === String)
                            object.audio = "";
                        else {
                            object.audio = [];
                            if (options.bytes !== Array)
                                object.audio = $util.newBuffer(object.audio);
                        }
                        object.operatorEmail = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        object.taskId = message.taskId;
                    if (message.callId != null && message.hasOwnProperty("callId"))
                        object.callId = message.callId;
                    if (message.audio != null && message.hasOwnProperty("audio"))
                        object.audio = options.bytes === String ? $util.base64.encode(message.audio, 0, message.audio.length) : options.bytes === Array ? Array.prototype.slice.call(message.audio) : message.audio;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    return object;
                };
    
                /**
                 * Converts this PlayAudioMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PlayAudioMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PlayAudioMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PlayAudioMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PlayAudioMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PlayAudioMessage";
                };
    
                return PlayAudioMessage;
            })();
    
            be.WebsocketClientMessage = (function() {
    
                /**
                 * Properties of a WebsocketClientMessage.
                 * @memberof infinitusai.be
                 * @interface IWebsocketClientMessage
                 * @property {infinitusai.be.IAuthClientMessage|null} [auth] WebsocketClientMessage auth
                 * @property {infinitusai.be.IHeartbeatMessageFromClient|null} [heartbeatMessage] WebsocketClientMessage heartbeatMessage
                 */
    
                /**
                 * Constructs a new WebsocketClientMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebsocketClientMessage.
                 * @implements IWebsocketClientMessage
                 * @constructor
                 * @param {infinitusai.be.IWebsocketClientMessage=} [properties] Properties to set
                 */
                function WebsocketClientMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebsocketClientMessage auth.
                 * @member {infinitusai.be.IAuthClientMessage|null|undefined} auth
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @instance
                 */
                WebsocketClientMessage.prototype.auth = null;
    
                /**
                 * WebsocketClientMessage heartbeatMessage.
                 * @member {infinitusai.be.IHeartbeatMessageFromClient|null|undefined} heartbeatMessage
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @instance
                 */
                WebsocketClientMessage.prototype.heartbeatMessage = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * WebsocketClientMessage data.
                 * @member {"auth"|"heartbeatMessage"|undefined} data
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @instance
                 */
                Object.defineProperty(WebsocketClientMessage.prototype, "data", {
                    get: $util.oneOfGetter($oneOfFields = ["auth", "heartbeatMessage"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new WebsocketClientMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {infinitusai.be.IWebsocketClientMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.WebsocketClientMessage} WebsocketClientMessage instance
                 */
                WebsocketClientMessage.create = function create(properties) {
                    return new WebsocketClientMessage(properties);
                };
    
                /**
                 * Encodes the specified WebsocketClientMessage message. Does not implicitly {@link infinitusai.be.WebsocketClientMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {infinitusai.be.IWebsocketClientMessage} message WebsocketClientMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebsocketClientMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                        $root.infinitusai.be.AuthClientMessage.encode(message.auth, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.heartbeatMessage != null && Object.hasOwnProperty.call(message, "heartbeatMessage"))
                        $root.infinitusai.be.HeartbeatMessageFromClient.encode(message.heartbeatMessage, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified WebsocketClientMessage message, length delimited. Does not implicitly {@link infinitusai.be.WebsocketClientMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {infinitusai.be.IWebsocketClientMessage} message WebsocketClientMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebsocketClientMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebsocketClientMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebsocketClientMessage} WebsocketClientMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebsocketClientMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebsocketClientMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.auth = $root.infinitusai.be.AuthClientMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.heartbeatMessage = $root.infinitusai.be.HeartbeatMessageFromClient.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebsocketClientMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebsocketClientMessage} WebsocketClientMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebsocketClientMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebsocketClientMessage message.
                 * @function verify
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebsocketClientMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.auth != null && message.hasOwnProperty("auth")) {
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.AuthClientMessage.verify(message.auth);
                            if (error)
                                return "auth." + error;
                        }
                    }
                    if (message.heartbeatMessage != null && message.hasOwnProperty("heartbeatMessage")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.HeartbeatMessageFromClient.verify(message.heartbeatMessage);
                            if (error)
                                return "heartbeatMessage." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a WebsocketClientMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebsocketClientMessage} WebsocketClientMessage
                 */
                WebsocketClientMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebsocketClientMessage)
                        return object;
                    var message = new $root.infinitusai.be.WebsocketClientMessage();
                    if (object.auth != null) {
                        if (typeof object.auth !== "object")
                            throw TypeError(".infinitusai.be.WebsocketClientMessage.auth: object expected");
                        message.auth = $root.infinitusai.be.AuthClientMessage.fromObject(object.auth);
                    }
                    if (object.heartbeatMessage != null) {
                        if (typeof object.heartbeatMessage !== "object")
                            throw TypeError(".infinitusai.be.WebsocketClientMessage.heartbeatMessage: object expected");
                        message.heartbeatMessage = $root.infinitusai.be.HeartbeatMessageFromClient.fromObject(object.heartbeatMessage);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebsocketClientMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {infinitusai.be.WebsocketClientMessage} message WebsocketClientMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebsocketClientMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.auth != null && message.hasOwnProperty("auth")) {
                        object.auth = $root.infinitusai.be.AuthClientMessage.toObject(message.auth, options);
                        if (options.oneofs)
                            object.data = "auth";
                    }
                    if (message.heartbeatMessage != null && message.hasOwnProperty("heartbeatMessage")) {
                        object.heartbeatMessage = $root.infinitusai.be.HeartbeatMessageFromClient.toObject(message.heartbeatMessage, options);
                        if (options.oneofs)
                            object.data = "heartbeatMessage";
                    }
                    return object;
                };
    
                /**
                 * Converts this WebsocketClientMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebsocketClientMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebsocketClientMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebsocketClientMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebsocketClientMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebsocketClientMessage";
                };
    
                return WebsocketClientMessage;
            })();
    
            be.OperatorShouldJoinCallMessage = (function() {
    
                /**
                 * Properties of an OperatorShouldJoinCallMessage.
                 * @memberof infinitusai.be
                 * @interface IOperatorShouldJoinCallMessage
                 * @property {string|null} [orgUuid] OperatorShouldJoinCallMessage orgUuid
                 * @property {string|null} [taskUuid] OperatorShouldJoinCallMessage taskUuid
                 * @property {string|null} [callUuid] OperatorShouldJoinCallMessage callUuid
                 * @property {string|null} [operatorEmail] OperatorShouldJoinCallMessage operatorEmail
                 * @property {string|null} [nexmoConvUuid] OperatorShouldJoinCallMessage nexmoConvUuid
                 * @property {boolean|null} [useHumanVoice] OperatorShouldJoinCallMessage useHumanVoice
                 * @property {infinitusai.be.OperatorPortalEnvironment|null} [targetEnvironment] OperatorShouldJoinCallMessage targetEnvironment
                 */
    
                /**
                 * Constructs a new OperatorShouldJoinCallMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorShouldJoinCallMessage.
                 * @implements IOperatorShouldJoinCallMessage
                 * @constructor
                 * @param {infinitusai.be.IOperatorShouldJoinCallMessage=} [properties] Properties to set
                 */
                function OperatorShouldJoinCallMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorShouldJoinCallMessage orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @instance
                 */
                OperatorShouldJoinCallMessage.prototype.orgUuid = "";
    
                /**
                 * OperatorShouldJoinCallMessage taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @instance
                 */
                OperatorShouldJoinCallMessage.prototype.taskUuid = "";
    
                /**
                 * OperatorShouldJoinCallMessage callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @instance
                 */
                OperatorShouldJoinCallMessage.prototype.callUuid = "";
    
                /**
                 * OperatorShouldJoinCallMessage operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @instance
                 */
                OperatorShouldJoinCallMessage.prototype.operatorEmail = "";
    
                /**
                 * OperatorShouldJoinCallMessage nexmoConvUuid.
                 * @member {string} nexmoConvUuid
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @instance
                 */
                OperatorShouldJoinCallMessage.prototype.nexmoConvUuid = "";
    
                /**
                 * OperatorShouldJoinCallMessage useHumanVoice.
                 * @member {boolean} useHumanVoice
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @instance
                 */
                OperatorShouldJoinCallMessage.prototype.useHumanVoice = false;
    
                /**
                 * OperatorShouldJoinCallMessage targetEnvironment.
                 * @member {infinitusai.be.OperatorPortalEnvironment} targetEnvironment
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @instance
                 */
                OperatorShouldJoinCallMessage.prototype.targetEnvironment = 0;
    
                /**
                 * Creates a new OperatorShouldJoinCallMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {infinitusai.be.IOperatorShouldJoinCallMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorShouldJoinCallMessage} OperatorShouldJoinCallMessage instance
                 */
                OperatorShouldJoinCallMessage.create = function create(properties) {
                    return new OperatorShouldJoinCallMessage(properties);
                };
    
                /**
                 * Encodes the specified OperatorShouldJoinCallMessage message. Does not implicitly {@link infinitusai.be.OperatorShouldJoinCallMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {infinitusai.be.IOperatorShouldJoinCallMessage} message OperatorShouldJoinCallMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorShouldJoinCallMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.operatorEmail);
                    if (message.nexmoConvUuid != null && Object.hasOwnProperty.call(message, "nexmoConvUuid"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.nexmoConvUuid);
                    if (message.useHumanVoice != null && Object.hasOwnProperty.call(message, "useHumanVoice"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.useHumanVoice);
                    if (message.targetEnvironment != null && Object.hasOwnProperty.call(message, "targetEnvironment"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.targetEnvironment);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorShouldJoinCallMessage message, length delimited. Does not implicitly {@link infinitusai.be.OperatorShouldJoinCallMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {infinitusai.be.IOperatorShouldJoinCallMessage} message OperatorShouldJoinCallMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorShouldJoinCallMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorShouldJoinCallMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorShouldJoinCallMessage} OperatorShouldJoinCallMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorShouldJoinCallMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorShouldJoinCallMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        case 5: {
                                message.nexmoConvUuid = reader.string();
                                break;
                            }
                        case 6: {
                                message.useHumanVoice = reader.bool();
                                break;
                            }
                        case 7: {
                                message.targetEnvironment = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorShouldJoinCallMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorShouldJoinCallMessage} OperatorShouldJoinCallMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorShouldJoinCallMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorShouldJoinCallMessage message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorShouldJoinCallMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    if (message.nexmoConvUuid != null && message.hasOwnProperty("nexmoConvUuid"))
                        if (!$util.isString(message.nexmoConvUuid))
                            return "nexmoConvUuid: string expected";
                    if (message.useHumanVoice != null && message.hasOwnProperty("useHumanVoice"))
                        if (typeof message.useHumanVoice !== "boolean")
                            return "useHumanVoice: boolean expected";
                    if (message.targetEnvironment != null && message.hasOwnProperty("targetEnvironment"))
                        switch (message.targetEnvironment) {
                        default:
                            return "targetEnvironment: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an OperatorShouldJoinCallMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorShouldJoinCallMessage} OperatorShouldJoinCallMessage
                 */
                OperatorShouldJoinCallMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorShouldJoinCallMessage)
                        return object;
                    var message = new $root.infinitusai.be.OperatorShouldJoinCallMessage();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    if (object.nexmoConvUuid != null)
                        message.nexmoConvUuid = String(object.nexmoConvUuid);
                    if (object.useHumanVoice != null)
                        message.useHumanVoice = Boolean(object.useHumanVoice);
                    switch (object.targetEnvironment) {
                    default:
                        if (typeof object.targetEnvironment === "number") {
                            message.targetEnvironment = object.targetEnvironment;
                            break;
                        }
                        break;
                    case "ANY":
                    case 0:
                        message.targetEnvironment = 0;
                        break;
                    case "STAGING":
                    case 1:
                        message.targetEnvironment = 1;
                        break;
                    case "PRODUCTION":
                    case 2:
                        message.targetEnvironment = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorShouldJoinCallMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {infinitusai.be.OperatorShouldJoinCallMessage} message OperatorShouldJoinCallMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorShouldJoinCallMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.operatorEmail = "";
                        object.nexmoConvUuid = "";
                        object.useHumanVoice = false;
                        object.targetEnvironment = options.enums === String ? "ANY" : 0;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    if (message.nexmoConvUuid != null && message.hasOwnProperty("nexmoConvUuid"))
                        object.nexmoConvUuid = message.nexmoConvUuid;
                    if (message.useHumanVoice != null && message.hasOwnProperty("useHumanVoice"))
                        object.useHumanVoice = message.useHumanVoice;
                    if (message.targetEnvironment != null && message.hasOwnProperty("targetEnvironment"))
                        object.targetEnvironment = options.enums === String ? $root.infinitusai.be.OperatorPortalEnvironment[message.targetEnvironment] === undefined ? message.targetEnvironment : $root.infinitusai.be.OperatorPortalEnvironment[message.targetEnvironment] : message.targetEnvironment;
                    return object;
                };
    
                /**
                 * Converts this OperatorShouldJoinCallMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorShouldJoinCallMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorShouldJoinCallMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorShouldJoinCallMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorShouldJoinCallMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorShouldJoinCallMessage";
                };
    
                return OperatorShouldJoinCallMessage;
            })();
    
            be.OperatorShouldLeaveCallMessage = (function() {
    
                /**
                 * Properties of an OperatorShouldLeaveCallMessage.
                 * @memberof infinitusai.be
                 * @interface IOperatorShouldLeaveCallMessage
                 * @property {string|null} [orgUuid] OperatorShouldLeaveCallMessage orgUuid
                 * @property {string|null} [taskUuid] OperatorShouldLeaveCallMessage taskUuid
                 * @property {string|null} [callUuid] OperatorShouldLeaveCallMessage callUuid
                 * @property {string|null} [operatorEmail] OperatorShouldLeaveCallMessage operatorEmail
                 * @property {infinitusai.be.OperatorPortalEnvironment|null} [targetEnvironment] OperatorShouldLeaveCallMessage targetEnvironment
                 */
    
                /**
                 * Constructs a new OperatorShouldLeaveCallMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorShouldLeaveCallMessage.
                 * @implements IOperatorShouldLeaveCallMessage
                 * @constructor
                 * @param {infinitusai.be.IOperatorShouldLeaveCallMessage=} [properties] Properties to set
                 */
                function OperatorShouldLeaveCallMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorShouldLeaveCallMessage orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @instance
                 */
                OperatorShouldLeaveCallMessage.prototype.orgUuid = "";
    
                /**
                 * OperatorShouldLeaveCallMessage taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @instance
                 */
                OperatorShouldLeaveCallMessage.prototype.taskUuid = "";
    
                /**
                 * OperatorShouldLeaveCallMessage callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @instance
                 */
                OperatorShouldLeaveCallMessage.prototype.callUuid = "";
    
                /**
                 * OperatorShouldLeaveCallMessage operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @instance
                 */
                OperatorShouldLeaveCallMessage.prototype.operatorEmail = "";
    
                /**
                 * OperatorShouldLeaveCallMessage targetEnvironment.
                 * @member {infinitusai.be.OperatorPortalEnvironment} targetEnvironment
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @instance
                 */
                OperatorShouldLeaveCallMessage.prototype.targetEnvironment = 0;
    
                /**
                 * Creates a new OperatorShouldLeaveCallMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {infinitusai.be.IOperatorShouldLeaveCallMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorShouldLeaveCallMessage} OperatorShouldLeaveCallMessage instance
                 */
                OperatorShouldLeaveCallMessage.create = function create(properties) {
                    return new OperatorShouldLeaveCallMessage(properties);
                };
    
                /**
                 * Encodes the specified OperatorShouldLeaveCallMessage message. Does not implicitly {@link infinitusai.be.OperatorShouldLeaveCallMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {infinitusai.be.IOperatorShouldLeaveCallMessage} message OperatorShouldLeaveCallMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorShouldLeaveCallMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.operatorEmail);
                    if (message.targetEnvironment != null && Object.hasOwnProperty.call(message, "targetEnvironment"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.targetEnvironment);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorShouldLeaveCallMessage message, length delimited. Does not implicitly {@link infinitusai.be.OperatorShouldLeaveCallMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {infinitusai.be.IOperatorShouldLeaveCallMessage} message OperatorShouldLeaveCallMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorShouldLeaveCallMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorShouldLeaveCallMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorShouldLeaveCallMessage} OperatorShouldLeaveCallMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorShouldLeaveCallMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorShouldLeaveCallMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        case 5: {
                                message.targetEnvironment = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorShouldLeaveCallMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorShouldLeaveCallMessage} OperatorShouldLeaveCallMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorShouldLeaveCallMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorShouldLeaveCallMessage message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorShouldLeaveCallMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    if (message.targetEnvironment != null && message.hasOwnProperty("targetEnvironment"))
                        switch (message.targetEnvironment) {
                        default:
                            return "targetEnvironment: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an OperatorShouldLeaveCallMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorShouldLeaveCallMessage} OperatorShouldLeaveCallMessage
                 */
                OperatorShouldLeaveCallMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorShouldLeaveCallMessage)
                        return object;
                    var message = new $root.infinitusai.be.OperatorShouldLeaveCallMessage();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    switch (object.targetEnvironment) {
                    default:
                        if (typeof object.targetEnvironment === "number") {
                            message.targetEnvironment = object.targetEnvironment;
                            break;
                        }
                        break;
                    case "ANY":
                    case 0:
                        message.targetEnvironment = 0;
                        break;
                    case "STAGING":
                    case 1:
                        message.targetEnvironment = 1;
                        break;
                    case "PRODUCTION":
                    case 2:
                        message.targetEnvironment = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorShouldLeaveCallMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {infinitusai.be.OperatorShouldLeaveCallMessage} message OperatorShouldLeaveCallMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorShouldLeaveCallMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.operatorEmail = "";
                        object.targetEnvironment = options.enums === String ? "ANY" : 0;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    if (message.targetEnvironment != null && message.hasOwnProperty("targetEnvironment"))
                        object.targetEnvironment = options.enums === String ? $root.infinitusai.be.OperatorPortalEnvironment[message.targetEnvironment] === undefined ? message.targetEnvironment : $root.infinitusai.be.OperatorPortalEnvironment[message.targetEnvironment] : message.targetEnvironment;
                    return object;
                };
    
                /**
                 * Converts this OperatorShouldLeaveCallMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorShouldLeaveCallMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorShouldLeaveCallMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorShouldLeaveCallMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorShouldLeaveCallMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorShouldLeaveCallMessage";
                };
    
                return OperatorShouldLeaveCallMessage;
            })();
    
            be.OperatorQueueMessage = (function() {
    
                /**
                 * Properties of an OperatorQueueMessage.
                 * @memberof infinitusai.be
                 * @interface IOperatorQueueMessage
                 * @property {Array.<string>|null} [operatorsInQueue] OperatorQueueMessage operatorsInQueue
                 */
    
                /**
                 * Constructs a new OperatorQueueMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorQueueMessage.
                 * @implements IOperatorQueueMessage
                 * @constructor
                 * @param {infinitusai.be.IOperatorQueueMessage=} [properties] Properties to set
                 */
                function OperatorQueueMessage(properties) {
                    this.operatorsInQueue = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorQueueMessage operatorsInQueue.
                 * @member {Array.<string>} operatorsInQueue
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @instance
                 */
                OperatorQueueMessage.prototype.operatorsInQueue = $util.emptyArray;
    
                /**
                 * Creates a new OperatorQueueMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {infinitusai.be.IOperatorQueueMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorQueueMessage} OperatorQueueMessage instance
                 */
                OperatorQueueMessage.create = function create(properties) {
                    return new OperatorQueueMessage(properties);
                };
    
                /**
                 * Encodes the specified OperatorQueueMessage message. Does not implicitly {@link infinitusai.be.OperatorQueueMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {infinitusai.be.IOperatorQueueMessage} message OperatorQueueMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorQueueMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorsInQueue != null && message.operatorsInQueue.length)
                        for (var i = 0; i < message.operatorsInQueue.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.operatorsInQueue[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorQueueMessage message, length delimited. Does not implicitly {@link infinitusai.be.OperatorQueueMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {infinitusai.be.IOperatorQueueMessage} message OperatorQueueMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorQueueMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorQueueMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorQueueMessage} OperatorQueueMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorQueueMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorQueueMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.operatorsInQueue && message.operatorsInQueue.length))
                                    message.operatorsInQueue = [];
                                message.operatorsInQueue.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorQueueMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorQueueMessage} OperatorQueueMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorQueueMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorQueueMessage message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorQueueMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorsInQueue != null && message.hasOwnProperty("operatorsInQueue")) {
                        if (!Array.isArray(message.operatorsInQueue))
                            return "operatorsInQueue: array expected";
                        for (var i = 0; i < message.operatorsInQueue.length; ++i)
                            if (!$util.isString(message.operatorsInQueue[i]))
                                return "operatorsInQueue: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an OperatorQueueMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorQueueMessage} OperatorQueueMessage
                 */
                OperatorQueueMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorQueueMessage)
                        return object;
                    var message = new $root.infinitusai.be.OperatorQueueMessage();
                    if (object.operatorsInQueue) {
                        if (!Array.isArray(object.operatorsInQueue))
                            throw TypeError(".infinitusai.be.OperatorQueueMessage.operatorsInQueue: array expected");
                        message.operatorsInQueue = [];
                        for (var i = 0; i < object.operatorsInQueue.length; ++i)
                            message.operatorsInQueue[i] = String(object.operatorsInQueue[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorQueueMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {infinitusai.be.OperatorQueueMessage} message OperatorQueueMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorQueueMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorsInQueue = [];
                    if (message.operatorsInQueue && message.operatorsInQueue.length) {
                        object.operatorsInQueue = [];
                        for (var j = 0; j < message.operatorsInQueue.length; ++j)
                            object.operatorsInQueue[j] = message.operatorsInQueue[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this OperatorQueueMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorQueueMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorQueueMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorQueueMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorQueueMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorQueueMessage";
                };
    
                return OperatorQueueMessage;
            })();
    
            be.WebsocketServerMessage = (function() {
    
                /**
                 * Properties of a WebsocketServerMessage.
                 * @memberof infinitusai.be
                 * @interface IWebsocketServerMessage
                 * @property {infinitusai.be.IHeartbeatMessageFromServer|null} [heartbeatMessage] WebsocketServerMessage heartbeatMessage
                 * @property {infinitusai.be.IPlayAudioMessage|null} [playAudioMessage] WebsocketServerMessage playAudioMessage
                 * @property {infinitusai.be.IOperatorShouldJoinCallMessage|null} [operatorShouldJoinCallMessage] WebsocketServerMessage operatorShouldJoinCallMessage
                 * @property {infinitusai.be.IOperatorQueueMessage|null} [operatorQueueMessage] WebsocketServerMessage operatorQueueMessage
                 * @property {infinitusai.be.INotification|null} [notification] WebsocketServerMessage notification
                 * @property {infinitusai.be.IDismissNotification|null} [dismissNotification] WebsocketServerMessage dismissNotification
                 * @property {infinitusai.be.IOperatorShouldLeaveCallMessage|null} [operatorShouldLeaveCallMessage] WebsocketServerMessage operatorShouldLeaveCallMessage
                 */
    
                /**
                 * Constructs a new WebsocketServerMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebsocketServerMessage.
                 * @implements IWebsocketServerMessage
                 * @constructor
                 * @param {infinitusai.be.IWebsocketServerMessage=} [properties] Properties to set
                 */
                function WebsocketServerMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebsocketServerMessage heartbeatMessage.
                 * @member {infinitusai.be.IHeartbeatMessageFromServer|null|undefined} heartbeatMessage
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 */
                WebsocketServerMessage.prototype.heartbeatMessage = null;
    
                /**
                 * WebsocketServerMessage playAudioMessage.
                 * @member {infinitusai.be.IPlayAudioMessage|null|undefined} playAudioMessage
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 */
                WebsocketServerMessage.prototype.playAudioMessage = null;
    
                /**
                 * WebsocketServerMessage operatorShouldJoinCallMessage.
                 * @member {infinitusai.be.IOperatorShouldJoinCallMessage|null|undefined} operatorShouldJoinCallMessage
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 */
                WebsocketServerMessage.prototype.operatorShouldJoinCallMessage = null;
    
                /**
                 * WebsocketServerMessage operatorQueueMessage.
                 * @member {infinitusai.be.IOperatorQueueMessage|null|undefined} operatorQueueMessage
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 */
                WebsocketServerMessage.prototype.operatorQueueMessage = null;
    
                /**
                 * WebsocketServerMessage notification.
                 * @member {infinitusai.be.INotification|null|undefined} notification
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 */
                WebsocketServerMessage.prototype.notification = null;
    
                /**
                 * WebsocketServerMessage dismissNotification.
                 * @member {infinitusai.be.IDismissNotification|null|undefined} dismissNotification
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 */
                WebsocketServerMessage.prototype.dismissNotification = null;
    
                /**
                 * WebsocketServerMessage operatorShouldLeaveCallMessage.
                 * @member {infinitusai.be.IOperatorShouldLeaveCallMessage|null|undefined} operatorShouldLeaveCallMessage
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 */
                WebsocketServerMessage.prototype.operatorShouldLeaveCallMessage = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * WebsocketServerMessage data.
                 * @member {"heartbeatMessage"|"playAudioMessage"|"operatorShouldJoinCallMessage"|"operatorQueueMessage"|"notification"|"dismissNotification"|"operatorShouldLeaveCallMessage"|undefined} data
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 */
                Object.defineProperty(WebsocketServerMessage.prototype, "data", {
                    get: $util.oneOfGetter($oneOfFields = ["heartbeatMessage", "playAudioMessage", "operatorShouldJoinCallMessage", "operatorQueueMessage", "notification", "dismissNotification", "operatorShouldLeaveCallMessage"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new WebsocketServerMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {infinitusai.be.IWebsocketServerMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.WebsocketServerMessage} WebsocketServerMessage instance
                 */
                WebsocketServerMessage.create = function create(properties) {
                    return new WebsocketServerMessage(properties);
                };
    
                /**
                 * Encodes the specified WebsocketServerMessage message. Does not implicitly {@link infinitusai.be.WebsocketServerMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {infinitusai.be.IWebsocketServerMessage} message WebsocketServerMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebsocketServerMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.heartbeatMessage != null && Object.hasOwnProperty.call(message, "heartbeatMessage"))
                        $root.infinitusai.be.HeartbeatMessageFromServer.encode(message.heartbeatMessage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.playAudioMessage != null && Object.hasOwnProperty.call(message, "playAudioMessage"))
                        $root.infinitusai.be.PlayAudioMessage.encode(message.playAudioMessage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.operatorShouldJoinCallMessage != null && Object.hasOwnProperty.call(message, "operatorShouldJoinCallMessage"))
                        $root.infinitusai.be.OperatorShouldJoinCallMessage.encode(message.operatorShouldJoinCallMessage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.operatorQueueMessage != null && Object.hasOwnProperty.call(message, "operatorQueueMessage"))
                        $root.infinitusai.be.OperatorQueueMessage.encode(message.operatorQueueMessage, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                        $root.infinitusai.be.Notification.encode(message.notification, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.dismissNotification != null && Object.hasOwnProperty.call(message, "dismissNotification"))
                        $root.infinitusai.be.DismissNotification.encode(message.dismissNotification, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.operatorShouldLeaveCallMessage != null && Object.hasOwnProperty.call(message, "operatorShouldLeaveCallMessage"))
                        $root.infinitusai.be.OperatorShouldLeaveCallMessage.encode(message.operatorShouldLeaveCallMessage, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified WebsocketServerMessage message, length delimited. Does not implicitly {@link infinitusai.be.WebsocketServerMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {infinitusai.be.IWebsocketServerMessage} message WebsocketServerMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebsocketServerMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebsocketServerMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebsocketServerMessage} WebsocketServerMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebsocketServerMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebsocketServerMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.heartbeatMessage = $root.infinitusai.be.HeartbeatMessageFromServer.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.playAudioMessage = $root.infinitusai.be.PlayAudioMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.operatorShouldJoinCallMessage = $root.infinitusai.be.OperatorShouldJoinCallMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.operatorQueueMessage = $root.infinitusai.be.OperatorQueueMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.notification = $root.infinitusai.be.Notification.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.dismissNotification = $root.infinitusai.be.DismissNotification.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.operatorShouldLeaveCallMessage = $root.infinitusai.be.OperatorShouldLeaveCallMessage.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebsocketServerMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebsocketServerMessage} WebsocketServerMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebsocketServerMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebsocketServerMessage message.
                 * @function verify
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebsocketServerMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.heartbeatMessage != null && message.hasOwnProperty("heartbeatMessage")) {
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.HeartbeatMessageFromServer.verify(message.heartbeatMessage);
                            if (error)
                                return "heartbeatMessage." + error;
                        }
                    }
                    if (message.playAudioMessage != null && message.hasOwnProperty("playAudioMessage")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.PlayAudioMessage.verify(message.playAudioMessage);
                            if (error)
                                return "playAudioMessage." + error;
                        }
                    }
                    if (message.operatorShouldJoinCallMessage != null && message.hasOwnProperty("operatorShouldJoinCallMessage")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.OperatorShouldJoinCallMessage.verify(message.operatorShouldJoinCallMessage);
                            if (error)
                                return "operatorShouldJoinCallMessage." + error;
                        }
                    }
                    if (message.operatorQueueMessage != null && message.hasOwnProperty("operatorQueueMessage")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.OperatorQueueMessage.verify(message.operatorQueueMessage);
                            if (error)
                                return "operatorQueueMessage." + error;
                        }
                    }
                    if (message.notification != null && message.hasOwnProperty("notification")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.Notification.verify(message.notification);
                            if (error)
                                return "notification." + error;
                        }
                    }
                    if (message.dismissNotification != null && message.hasOwnProperty("dismissNotification")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.DismissNotification.verify(message.dismissNotification);
                            if (error)
                                return "dismissNotification." + error;
                        }
                    }
                    if (message.operatorShouldLeaveCallMessage != null && message.hasOwnProperty("operatorShouldLeaveCallMessage")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.OperatorShouldLeaveCallMessage.verify(message.operatorShouldLeaveCallMessage);
                            if (error)
                                return "operatorShouldLeaveCallMessage." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a WebsocketServerMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebsocketServerMessage} WebsocketServerMessage
                 */
                WebsocketServerMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebsocketServerMessage)
                        return object;
                    var message = new $root.infinitusai.be.WebsocketServerMessage();
                    if (object.heartbeatMessage != null) {
                        if (typeof object.heartbeatMessage !== "object")
                            throw TypeError(".infinitusai.be.WebsocketServerMessage.heartbeatMessage: object expected");
                        message.heartbeatMessage = $root.infinitusai.be.HeartbeatMessageFromServer.fromObject(object.heartbeatMessage);
                    }
                    if (object.playAudioMessage != null) {
                        if (typeof object.playAudioMessage !== "object")
                            throw TypeError(".infinitusai.be.WebsocketServerMessage.playAudioMessage: object expected");
                        message.playAudioMessage = $root.infinitusai.be.PlayAudioMessage.fromObject(object.playAudioMessage);
                    }
                    if (object.operatorShouldJoinCallMessage != null) {
                        if (typeof object.operatorShouldJoinCallMessage !== "object")
                            throw TypeError(".infinitusai.be.WebsocketServerMessage.operatorShouldJoinCallMessage: object expected");
                        message.operatorShouldJoinCallMessage = $root.infinitusai.be.OperatorShouldJoinCallMessage.fromObject(object.operatorShouldJoinCallMessage);
                    }
                    if (object.operatorQueueMessage != null) {
                        if (typeof object.operatorQueueMessage !== "object")
                            throw TypeError(".infinitusai.be.WebsocketServerMessage.operatorQueueMessage: object expected");
                        message.operatorQueueMessage = $root.infinitusai.be.OperatorQueueMessage.fromObject(object.operatorQueueMessage);
                    }
                    if (object.notification != null) {
                        if (typeof object.notification !== "object")
                            throw TypeError(".infinitusai.be.WebsocketServerMessage.notification: object expected");
                        message.notification = $root.infinitusai.be.Notification.fromObject(object.notification);
                    }
                    if (object.dismissNotification != null) {
                        if (typeof object.dismissNotification !== "object")
                            throw TypeError(".infinitusai.be.WebsocketServerMessage.dismissNotification: object expected");
                        message.dismissNotification = $root.infinitusai.be.DismissNotification.fromObject(object.dismissNotification);
                    }
                    if (object.operatorShouldLeaveCallMessage != null) {
                        if (typeof object.operatorShouldLeaveCallMessage !== "object")
                            throw TypeError(".infinitusai.be.WebsocketServerMessage.operatorShouldLeaveCallMessage: object expected");
                        message.operatorShouldLeaveCallMessage = $root.infinitusai.be.OperatorShouldLeaveCallMessage.fromObject(object.operatorShouldLeaveCallMessage);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebsocketServerMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {infinitusai.be.WebsocketServerMessage} message WebsocketServerMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebsocketServerMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.heartbeatMessage != null && message.hasOwnProperty("heartbeatMessage")) {
                        object.heartbeatMessage = $root.infinitusai.be.HeartbeatMessageFromServer.toObject(message.heartbeatMessage, options);
                        if (options.oneofs)
                            object.data = "heartbeatMessage";
                    }
                    if (message.playAudioMessage != null && message.hasOwnProperty("playAudioMessage")) {
                        object.playAudioMessage = $root.infinitusai.be.PlayAudioMessage.toObject(message.playAudioMessage, options);
                        if (options.oneofs)
                            object.data = "playAudioMessage";
                    }
                    if (message.operatorShouldJoinCallMessage != null && message.hasOwnProperty("operatorShouldJoinCallMessage")) {
                        object.operatorShouldJoinCallMessage = $root.infinitusai.be.OperatorShouldJoinCallMessage.toObject(message.operatorShouldJoinCallMessage, options);
                        if (options.oneofs)
                            object.data = "operatorShouldJoinCallMessage";
                    }
                    if (message.operatorQueueMessage != null && message.hasOwnProperty("operatorQueueMessage")) {
                        object.operatorQueueMessage = $root.infinitusai.be.OperatorQueueMessage.toObject(message.operatorQueueMessage, options);
                        if (options.oneofs)
                            object.data = "operatorQueueMessage";
                    }
                    if (message.notification != null && message.hasOwnProperty("notification")) {
                        object.notification = $root.infinitusai.be.Notification.toObject(message.notification, options);
                        if (options.oneofs)
                            object.data = "notification";
                    }
                    if (message.dismissNotification != null && message.hasOwnProperty("dismissNotification")) {
                        object.dismissNotification = $root.infinitusai.be.DismissNotification.toObject(message.dismissNotification, options);
                        if (options.oneofs)
                            object.data = "dismissNotification";
                    }
                    if (message.operatorShouldLeaveCallMessage != null && message.hasOwnProperty("operatorShouldLeaveCallMessage")) {
                        object.operatorShouldLeaveCallMessage = $root.infinitusai.be.OperatorShouldLeaveCallMessage.toObject(message.operatorShouldLeaveCallMessage, options);
                        if (options.oneofs)
                            object.data = "operatorShouldLeaveCallMessage";
                    }
                    return object;
                };
    
                /**
                 * Converts this WebsocketServerMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebsocketServerMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebsocketServerMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebsocketServerMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebsocketServerMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebsocketServerMessage";
                };
    
                return WebsocketServerMessage;
            })();
    
            be.AuthServerMessage = (function() {
    
                /**
                 * Properties of an AuthServerMessage.
                 * @memberof infinitusai.be
                 * @interface IAuthServerMessage
                 * @property {string|null} [UserEmail] AuthServerMessage UserEmail
                 * @property {string|null} [UserUID] AuthServerMessage UserUID
                 * @property {string|null} [UserDisplayName] AuthServerMessage UserDisplayName
                 * @property {infinitusai.be.UserRole|null} [UserRole] AuthServerMessage UserRole
                 * @property {infinitusai.be.UserStatus|null} [UserStatus] AuthServerMessage UserStatus
                 * @property {string|null} [OrgUUIDStr] AuthServerMessage OrgUUIDStr
                 * @property {Object.<string,infinitusai.be.OrgRole>|null} [UserOrgs] AuthServerMessage UserOrgs
                 * @property {infinitusai.auth.UserType|null} [UserType] AuthServerMessage UserType
                 * @property {string|null} [OrgUUID] AuthServerMessage OrgUUID
                 * @property {Array.<infinitusai.auth.PermissionName>|null} [OrgPermissions] AuthServerMessage OrgPermissions
                 * @property {boolean|null} [SuperAdmin] AuthServerMessage SuperAdmin
                 * @property {string|null} [RequestOrigin] AuthServerMessage RequestOrigin
                 * @property {Array.<infinitusai.auth.PermissionName>|null} [UserPermissions] AuthServerMessage UserPermissions
                 * @property {string|null} [IDToken] AuthServerMessage IDToken
                 * @property {string|null} [AppName] AuthServerMessage AppName
                 * @property {string|null} [RequestIPAddr] AuthServerMessage RequestIPAddr
                 */
    
                /**
                 * Constructs a new AuthServerMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AuthServerMessage.
                 * @implements IAuthServerMessage
                 * @constructor
                 * @param {infinitusai.be.IAuthServerMessage=} [properties] Properties to set
                 */
                function AuthServerMessage(properties) {
                    this.UserOrgs = {};
                    this.OrgPermissions = [];
                    this.UserPermissions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AuthServerMessage UserEmail.
                 * @member {string} UserEmail
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.UserEmail = "";
    
                /**
                 * AuthServerMessage UserUID.
                 * @member {string} UserUID
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.UserUID = "";
    
                /**
                 * AuthServerMessage UserDisplayName.
                 * @member {string} UserDisplayName
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.UserDisplayName = "";
    
                /**
                 * AuthServerMessage UserRole.
                 * @member {infinitusai.be.UserRole} UserRole
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.UserRole = 0;
    
                /**
                 * AuthServerMessage UserStatus.
                 * @member {infinitusai.be.UserStatus} UserStatus
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.UserStatus = 0;
    
                /**
                 * AuthServerMessage OrgUUIDStr.
                 * @member {string} OrgUUIDStr
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.OrgUUIDStr = "";
    
                /**
                 * AuthServerMessage UserOrgs.
                 * @member {Object.<string,infinitusai.be.OrgRole>} UserOrgs
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.UserOrgs = $util.emptyObject;
    
                /**
                 * AuthServerMessage UserType.
                 * @member {infinitusai.auth.UserType} UserType
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.UserType = 0;
    
                /**
                 * AuthServerMessage OrgUUID.
                 * @member {string} OrgUUID
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.OrgUUID = "";
    
                /**
                 * AuthServerMessage OrgPermissions.
                 * @member {Array.<infinitusai.auth.PermissionName>} OrgPermissions
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.OrgPermissions = $util.emptyArray;
    
                /**
                 * AuthServerMessage SuperAdmin.
                 * @member {boolean} SuperAdmin
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.SuperAdmin = false;
    
                /**
                 * AuthServerMessage RequestOrigin.
                 * @member {string} RequestOrigin
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.RequestOrigin = "";
    
                /**
                 * AuthServerMessage UserPermissions.
                 * @member {Array.<infinitusai.auth.PermissionName>} UserPermissions
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.UserPermissions = $util.emptyArray;
    
                /**
                 * AuthServerMessage IDToken.
                 * @member {string} IDToken
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.IDToken = "";
    
                /**
                 * AuthServerMessage AppName.
                 * @member {string} AppName
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.AppName = "";
    
                /**
                 * AuthServerMessage RequestIPAddr.
                 * @member {string} RequestIPAddr
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 */
                AuthServerMessage.prototype.RequestIPAddr = "";
    
                /**
                 * Creates a new AuthServerMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {infinitusai.be.IAuthServerMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.AuthServerMessage} AuthServerMessage instance
                 */
                AuthServerMessage.create = function create(properties) {
                    return new AuthServerMessage(properties);
                };
    
                /**
                 * Encodes the specified AuthServerMessage message. Does not implicitly {@link infinitusai.be.AuthServerMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {infinitusai.be.IAuthServerMessage} message AuthServerMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuthServerMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.UserEmail != null && Object.hasOwnProperty.call(message, "UserEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.UserEmail);
                    if (message.UserUID != null && Object.hasOwnProperty.call(message, "UserUID"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.UserUID);
                    if (message.UserDisplayName != null && Object.hasOwnProperty.call(message, "UserDisplayName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.UserDisplayName);
                    if (message.UserRole != null && Object.hasOwnProperty.call(message, "UserRole"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.UserRole);
                    if (message.UserStatus != null && Object.hasOwnProperty.call(message, "UserStatus"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.UserStatus);
                    if (message.OrgUUIDStr != null && Object.hasOwnProperty.call(message, "OrgUUIDStr"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.OrgUUIDStr);
                    if (message.UserOrgs != null && Object.hasOwnProperty.call(message, "UserOrgs"))
                        for (var keys = Object.keys(message.UserOrgs), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.UserOrgs[keys[i]]).ldelim();
                    if (message.UserType != null && Object.hasOwnProperty.call(message, "UserType"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.UserType);
                    if (message.OrgUUID != null && Object.hasOwnProperty.call(message, "OrgUUID"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.OrgUUID);
                    if (message.OrgPermissions != null && message.OrgPermissions.length) {
                        writer.uint32(/* id 10, wireType 2 =*/82).fork();
                        for (var i = 0; i < message.OrgPermissions.length; ++i)
                            writer.int32(message.OrgPermissions[i]);
                        writer.ldelim();
                    }
                    if (message.SuperAdmin != null && Object.hasOwnProperty.call(message, "SuperAdmin"))
                        writer.uint32(/* id 11, wireType 0 =*/88).bool(message.SuperAdmin);
                    if (message.RequestOrigin != null && Object.hasOwnProperty.call(message, "RequestOrigin"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.RequestOrigin);
                    if (message.UserPermissions != null && message.UserPermissions.length) {
                        writer.uint32(/* id 13, wireType 2 =*/106).fork();
                        for (var i = 0; i < message.UserPermissions.length; ++i)
                            writer.int32(message.UserPermissions[i]);
                        writer.ldelim();
                    }
                    if (message.IDToken != null && Object.hasOwnProperty.call(message, "IDToken"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.IDToken);
                    if (message.AppName != null && Object.hasOwnProperty.call(message, "AppName"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.AppName);
                    if (message.RequestIPAddr != null && Object.hasOwnProperty.call(message, "RequestIPAddr"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.RequestIPAddr);
                    return writer;
                };
    
                /**
                 * Encodes the specified AuthServerMessage message, length delimited. Does not implicitly {@link infinitusai.be.AuthServerMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {infinitusai.be.IAuthServerMessage} message AuthServerMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuthServerMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AuthServerMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AuthServerMessage} AuthServerMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuthServerMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AuthServerMessage(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.UserEmail = reader.string();
                                break;
                            }
                        case 2: {
                                message.UserUID = reader.string();
                                break;
                            }
                        case 3: {
                                message.UserDisplayName = reader.string();
                                break;
                            }
                        case 4: {
                                message.UserRole = reader.int32();
                                break;
                            }
                        case 5: {
                                message.UserStatus = reader.int32();
                                break;
                            }
                        case 6: {
                                message.OrgUUIDStr = reader.string();
                                break;
                            }
                        case 7: {
                                if (message.UserOrgs === $util.emptyObject)
                                    message.UserOrgs = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.int32();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.UserOrgs[key] = value;
                                break;
                            }
                        case 8: {
                                message.UserType = reader.int32();
                                break;
                            }
                        case 9: {
                                message.OrgUUID = reader.string();
                                break;
                            }
                        case 10: {
                                if (!(message.OrgPermissions && message.OrgPermissions.length))
                                    message.OrgPermissions = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.OrgPermissions.push(reader.int32());
                                } else
                                    message.OrgPermissions.push(reader.int32());
                                break;
                            }
                        case 11: {
                                message.SuperAdmin = reader.bool();
                                break;
                            }
                        case 12: {
                                message.RequestOrigin = reader.string();
                                break;
                            }
                        case 13: {
                                if (!(message.UserPermissions && message.UserPermissions.length))
                                    message.UserPermissions = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.UserPermissions.push(reader.int32());
                                } else
                                    message.UserPermissions.push(reader.int32());
                                break;
                            }
                        case 14: {
                                message.IDToken = reader.string();
                                break;
                            }
                        case 15: {
                                message.AppName = reader.string();
                                break;
                            }
                        case 16: {
                                message.RequestIPAddr = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AuthServerMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AuthServerMessage} AuthServerMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuthServerMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AuthServerMessage message.
                 * @function verify
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AuthServerMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.UserEmail != null && message.hasOwnProperty("UserEmail"))
                        if (!$util.isString(message.UserEmail))
                            return "UserEmail: string expected";
                    if (message.UserUID != null && message.hasOwnProperty("UserUID"))
                        if (!$util.isString(message.UserUID))
                            return "UserUID: string expected";
                    if (message.UserDisplayName != null && message.hasOwnProperty("UserDisplayName"))
                        if (!$util.isString(message.UserDisplayName))
                            return "UserDisplayName: string expected";
                    if (message.UserRole != null && message.hasOwnProperty("UserRole"))
                        switch (message.UserRole) {
                        default:
                            return "UserRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.UserStatus != null && message.hasOwnProperty("UserStatus"))
                        switch (message.UserStatus) {
                        default:
                            return "UserStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.OrgUUIDStr != null && message.hasOwnProperty("OrgUUIDStr"))
                        if (!$util.isString(message.OrgUUIDStr))
                            return "OrgUUIDStr: string expected";
                    if (message.UserOrgs != null && message.hasOwnProperty("UserOrgs")) {
                        if (!$util.isObject(message.UserOrgs))
                            return "UserOrgs: object expected";
                        var key = Object.keys(message.UserOrgs);
                        for (var i = 0; i < key.length; ++i)
                            switch (message.UserOrgs[key[i]]) {
                            default:
                                return "UserOrgs: enum value{k:string} expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                    }
                    if (message.UserType != null && message.hasOwnProperty("UserType"))
                        switch (message.UserType) {
                        default:
                            return "UserType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.OrgUUID != null && message.hasOwnProperty("OrgUUID"))
                        if (!$util.isString(message.OrgUUID))
                            return "OrgUUID: string expected";
                    if (message.OrgPermissions != null && message.hasOwnProperty("OrgPermissions")) {
                        if (!Array.isArray(message.OrgPermissions))
                            return "OrgPermissions: array expected";
                        for (var i = 0; i < message.OrgPermissions.length; ++i)
                            switch (message.OrgPermissions[i]) {
                            default:
                                return "OrgPermissions: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 32:
                            case 33:
                            case 35:
                            case 36:
                            case 37:
                            case 30:
                            case 31:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 34:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 38:
                            case 39:
                            case 40:
                            case 41:
                            case 42:
                            case 43:
                            case 44:
                                break;
                            }
                    }
                    if (message.SuperAdmin != null && message.hasOwnProperty("SuperAdmin"))
                        if (typeof message.SuperAdmin !== "boolean")
                            return "SuperAdmin: boolean expected";
                    if (message.RequestOrigin != null && message.hasOwnProperty("RequestOrigin"))
                        if (!$util.isString(message.RequestOrigin))
                            return "RequestOrigin: string expected";
                    if (message.UserPermissions != null && message.hasOwnProperty("UserPermissions")) {
                        if (!Array.isArray(message.UserPermissions))
                            return "UserPermissions: array expected";
                        for (var i = 0; i < message.UserPermissions.length; ++i)
                            switch (message.UserPermissions[i]) {
                            default:
                                return "UserPermissions: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 32:
                            case 33:
                            case 35:
                            case 36:
                            case 37:
                            case 30:
                            case 31:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 34:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 38:
                            case 39:
                            case 40:
                            case 41:
                            case 42:
                            case 43:
                            case 44:
                                break;
                            }
                    }
                    if (message.IDToken != null && message.hasOwnProperty("IDToken"))
                        if (!$util.isString(message.IDToken))
                            return "IDToken: string expected";
                    if (message.AppName != null && message.hasOwnProperty("AppName"))
                        if (!$util.isString(message.AppName))
                            return "AppName: string expected";
                    if (message.RequestIPAddr != null && message.hasOwnProperty("RequestIPAddr"))
                        if (!$util.isString(message.RequestIPAddr))
                            return "RequestIPAddr: string expected";
                    return null;
                };
    
                /**
                 * Creates an AuthServerMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AuthServerMessage} AuthServerMessage
                 */
                AuthServerMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AuthServerMessage)
                        return object;
                    var message = new $root.infinitusai.be.AuthServerMessage();
                    if (object.UserEmail != null)
                        message.UserEmail = String(object.UserEmail);
                    if (object.UserUID != null)
                        message.UserUID = String(object.UserUID);
                    if (object.UserDisplayName != null)
                        message.UserDisplayName = String(object.UserDisplayName);
                    switch (object.UserRole) {
                    default:
                        if (typeof object.UserRole === "number") {
                            message.UserRole = object.UserRole;
                            break;
                        }
                        break;
                    case "USER_ROLE_UNKNOWN":
                    case 0:
                        message.UserRole = 0;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 1:
                        message.UserRole = 1;
                        break;
                    case "USER_ROLE_OPERATOR":
                    case 2:
                        message.UserRole = 2;
                        break;
                    case "USER_ROLE_CUSTOMER":
                    case 3:
                        message.UserRole = 3;
                        break;
                    case "USER_ROLE_USER":
                    case 4:
                        message.UserRole = 4;
                        break;
                    }
                    switch (object.UserStatus) {
                    default:
                        if (typeof object.UserStatus === "number") {
                            message.UserStatus = object.UserStatus;
                            break;
                        }
                        break;
                    case "USER_STATUS_UNKNOWN":
                    case 0:
                        message.UserStatus = 0;
                        break;
                    case "USER_STATUS_ACTIVE":
                    case 1:
                        message.UserStatus = 1;
                        break;
                    case "USER_STATUS_INACTIVE":
                    case 2:
                        message.UserStatus = 2;
                        break;
                    case "USER_STATUS_DEACTIVATED":
                    case 3:
                        message.UserStatus = 3;
                        break;
                    case "USER_STATUS_PENDING":
                    case 4:
                        message.UserStatus = 4;
                        break;
                    }
                    if (object.OrgUUIDStr != null)
                        message.OrgUUIDStr = String(object.OrgUUIDStr);
                    if (object.UserOrgs) {
                        if (typeof object.UserOrgs !== "object")
                            throw TypeError(".infinitusai.be.AuthServerMessage.UserOrgs: object expected");
                        message.UserOrgs = {};
                        for (var keys = Object.keys(object.UserOrgs), i = 0; i < keys.length; ++i)
                            switch (object.UserOrgs[keys[i]]) {
                            default:
                                if (typeof object.UserOrgs[keys[i]] === "number") {
                                    message.UserOrgs[keys[i]] = object.UserOrgs[keys[i]];
                                    break;
                                }
                                break;
                            case "ORG_ROLE_UNKNOWN":
                            case 0:
                                message.UserOrgs[keys[i]] = 0;
                                break;
                            case "ORG_ROLE_OWNER":
                            case 1:
                                message.UserOrgs[keys[i]] = 1;
                                break;
                            case "ORG_ROLE_MEMBER":
                            case 2:
                                message.UserOrgs[keys[i]] = 2;
                                break;
                            case "ORG_ROLE_SUPERVISOR":
                            case 3:
                                message.UserOrgs[keys[i]] = 3;
                                break;
                            case "ORG_ROLE_OPERATOR":
                            case 4:
                                message.UserOrgs[keys[i]] = 4;
                                break;
                            }
                    }
                    switch (object.UserType) {
                    default:
                        if (typeof object.UserType === "number") {
                            message.UserType = object.UserType;
                            break;
                        }
                        break;
                    case "USER_TYPE_UNKNOWN":
                    case 0:
                        message.UserType = 0;
                        break;
                    case "USER_TYPE_ADMIN":
                    case 1:
                        message.UserType = 1;
                        break;
                    case "USER_TYPE_OPERATOR":
                    case 2:
                        message.UserType = 2;
                        break;
                    case "USER_TYPE_CUSTOMER":
                    case 3:
                        message.UserType = 3;
                        break;
                    }
                    if (object.OrgUUID != null)
                        message.OrgUUID = String(object.OrgUUID);
                    if (object.OrgPermissions) {
                        if (!Array.isArray(object.OrgPermissions))
                            throw TypeError(".infinitusai.be.AuthServerMessage.OrgPermissions: array expected");
                        message.OrgPermissions = [];
                        for (var i = 0; i < object.OrgPermissions.length; ++i)
                            switch (object.OrgPermissions[i]) {
                            default:
                                if (typeof object.OrgPermissions[i] === "number") {
                                    message.OrgPermissions[i] = object.OrgPermissions[i];
                                    break;
                                }
                            case "PERMISSION_UNKNOWN":
                            case 0:
                                message.OrgPermissions[i] = 0;
                                break;
                            case "PERMISSION_ADMIN_RBAC_READ":
                            case 1:
                                message.OrgPermissions[i] = 1;
                                break;
                            case "PERMISSION_ADMIN_RBAC_WRITE":
                            case 2:
                                message.OrgPermissions[i] = 2;
                                break;
                            case "PERMISSION_ADMIN_RBAC_ASSIGN":
                            case 3:
                                message.OrgPermissions[i] = 3;
                                break;
                            case "PERMISSION_ADMIN_ENGINEER":
                            case 32:
                                message.OrgPermissions[i] = 32;
                                break;
                            case "PERMISSION_ADMIN_PORTAL_ACCESS":
                            case 33:
                                message.OrgPermissions[i] = 33;
                                break;
                            case "PERMISSION_ADMIN_SKILLS_WRITE":
                            case 35:
                                message.OrgPermissions[i] = 35;
                                break;
                            case "PERMISSION_ADMIN_USER_SKILLS_WRITE":
                            case 36:
                                message.OrgPermissions[i] = 36;
                                break;
                            case "PERMISSION_ADMIN_QUEUE_SETTINGS_WRITE":
                            case 37:
                                message.OrgPermissions[i] = 37;
                                break;
                            case "PERMISSION_OPERATOR_PORTAL_ACCESS":
                            case 30:
                                message.OrgPermissions[i] = 30;
                                break;
                            case "PERMISSION_OPERATOR_CALLS_REVIEW":
                            case 31:
                                message.OrgPermissions[i] = 31;
                                break;
                            case "PERMISSION_CUSTOMER_OWNER":
                            case 4:
                                message.OrgPermissions[i] = 4;
                                break;
                            case "PERMISSION_CUSTOMER_RBAC_READ":
                            case 5:
                                message.OrgPermissions[i] = 5;
                                break;
                            case "PERMISSION_CUSTOMER_RBAC_WRITE":
                            case 6:
                                message.OrgPermissions[i] = 6;
                                break;
                            case "PERMISSION_CUSTOMER_RBAC_ASSIGN":
                            case 7:
                                message.OrgPermissions[i] = 7;
                                break;
                            case "PERMISSION_CUSTOMER_API_KEYS_READ":
                            case 8:
                                message.OrgPermissions[i] = 8;
                                break;
                            case "PERMISSION_CUSTOMER_API_KEYS_WRITE":
                            case 9:
                                message.OrgPermissions[i] = 9;
                                break;
                            case "PERMISSION_CUSTOMER_BILLING_READ":
                            case 10:
                                message.OrgPermissions[i] = 10;
                                break;
                            case "PERMISSION_CUSTOMER_PAYORS_READ":
                            case 11:
                                message.OrgPermissions[i] = 11;
                                break;
                            case "PERMISSION_CUSTOMER_PIE_READ":
                            case 12:
                                message.OrgPermissions[i] = 12;
                                break;
                            case "PERMISSION_CUSTOMER_PIE_WRITE":
                            case 13:
                                message.OrgPermissions[i] = 13;
                                break;
                            case "PERMISSION_CUSTOMER_SETTINGS_READ":
                            case 14:
                                message.OrgPermissions[i] = 14;
                                break;
                            case "PERMISSION_CUSTOMER_SETTINGS_WRITE":
                            case 15:
                                message.OrgPermissions[i] = 15;
                                break;
                            case "PERMISSION_CUSTOMER_TASKS_READ":
                            case 16:
                                message.OrgPermissions[i] = 16;
                                break;
                            case "PERMISSION_CUSTOMER_TASKS_WRITE":
                            case 17:
                                message.OrgPermissions[i] = 17;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_IMPORTS_READ":
                            case 18:
                                message.OrgPermissions[i] = 18;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_IMPORTS_WRITE":
                            case 19:
                                message.OrgPermissions[i] = 19;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_RECORDINGS_READ":
                            case 34:
                                message.OrgPermissions[i] = 34;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_RECORDINGS_LISTEN":
                            case 20:
                                message.OrgPermissions[i] = 20;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_RECORDINGS_DOWNLOAD":
                            case 21:
                                message.OrgPermissions[i] = 21;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_REPORTS_READ":
                            case 22:
                                message.OrgPermissions[i] = 22;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_REPORTS_WRITE":
                            case 23:
                                message.OrgPermissions[i] = 23;
                                break;
                            case "PERMISSION_CUSTOMER_FT_SETUP_APPROVE":
                            case 24:
                                message.OrgPermissions[i] = 24;
                                break;
                            case "PERMISSION_CUSTOMER_FT_TASKS_OPERATE":
                            case 25:
                                message.OrgPermissions[i] = 25;
                                break;
                            case "PERMISSION_CUSTOMER_FT_TASKS_MANAGE":
                            case 26:
                                message.OrgPermissions[i] = 26;
                                break;
                            case "PERMISSION_CUSTOMER_FT_DASHBOARD":
                            case 27:
                                message.OrgPermissions[i] = 27;
                                break;
                            case "PERMISSION_CUSTOMER_DOCS_READ":
                            case 28:
                                message.OrgPermissions[i] = 28;
                                break;
                            case "PERMISSION_CUSTOMER_PORTAL_ACCESS":
                            case 29:
                                message.OrgPermissions[i] = 29;
                                break;
                            case "PERMISSION_CUSTOMER_FT_SOFTPHONE":
                            case 38:
                                message.OrgPermissions[i] = 38;
                                break;
                            case "PERMISSION_CUSTOMER_FT_CALL_QUEUE":
                            case 39:
                                message.OrgPermissions[i] = 39;
                                break;
                            case "PERMISSION_CUSTOMER_FT_CALL_AUTO":
                            case 40:
                                message.OrgPermissions[i] = 40;
                                break;
                            case "PERMISSION_CUSTOMER_FT_CALL_MANUAL":
                            case 41:
                                message.OrgPermissions[i] = 41;
                                break;
                            case "PERMISSION_CUSTOMER_FT_CALL_SALESFORCE":
                            case 42:
                                message.OrgPermissions[i] = 42;
                                break;
                            case "PERMISSION_CUSTOMER_FT_DASHBOARD_TEAM":
                            case 43:
                                message.OrgPermissions[i] = 43;
                                break;
                            case "PERMISSION_CUSTOMER_FT_DASHBOARD_ORG":
                            case 44:
                                message.OrgPermissions[i] = 44;
                                break;
                            }
                    }
                    if (object.SuperAdmin != null)
                        message.SuperAdmin = Boolean(object.SuperAdmin);
                    if (object.RequestOrigin != null)
                        message.RequestOrigin = String(object.RequestOrigin);
                    if (object.UserPermissions) {
                        if (!Array.isArray(object.UserPermissions))
                            throw TypeError(".infinitusai.be.AuthServerMessage.UserPermissions: array expected");
                        message.UserPermissions = [];
                        for (var i = 0; i < object.UserPermissions.length; ++i)
                            switch (object.UserPermissions[i]) {
                            default:
                                if (typeof object.UserPermissions[i] === "number") {
                                    message.UserPermissions[i] = object.UserPermissions[i];
                                    break;
                                }
                            case "PERMISSION_UNKNOWN":
                            case 0:
                                message.UserPermissions[i] = 0;
                                break;
                            case "PERMISSION_ADMIN_RBAC_READ":
                            case 1:
                                message.UserPermissions[i] = 1;
                                break;
                            case "PERMISSION_ADMIN_RBAC_WRITE":
                            case 2:
                                message.UserPermissions[i] = 2;
                                break;
                            case "PERMISSION_ADMIN_RBAC_ASSIGN":
                            case 3:
                                message.UserPermissions[i] = 3;
                                break;
                            case "PERMISSION_ADMIN_ENGINEER":
                            case 32:
                                message.UserPermissions[i] = 32;
                                break;
                            case "PERMISSION_ADMIN_PORTAL_ACCESS":
                            case 33:
                                message.UserPermissions[i] = 33;
                                break;
                            case "PERMISSION_ADMIN_SKILLS_WRITE":
                            case 35:
                                message.UserPermissions[i] = 35;
                                break;
                            case "PERMISSION_ADMIN_USER_SKILLS_WRITE":
                            case 36:
                                message.UserPermissions[i] = 36;
                                break;
                            case "PERMISSION_ADMIN_QUEUE_SETTINGS_WRITE":
                            case 37:
                                message.UserPermissions[i] = 37;
                                break;
                            case "PERMISSION_OPERATOR_PORTAL_ACCESS":
                            case 30:
                                message.UserPermissions[i] = 30;
                                break;
                            case "PERMISSION_OPERATOR_CALLS_REVIEW":
                            case 31:
                                message.UserPermissions[i] = 31;
                                break;
                            case "PERMISSION_CUSTOMER_OWNER":
                            case 4:
                                message.UserPermissions[i] = 4;
                                break;
                            case "PERMISSION_CUSTOMER_RBAC_READ":
                            case 5:
                                message.UserPermissions[i] = 5;
                                break;
                            case "PERMISSION_CUSTOMER_RBAC_WRITE":
                            case 6:
                                message.UserPermissions[i] = 6;
                                break;
                            case "PERMISSION_CUSTOMER_RBAC_ASSIGN":
                            case 7:
                                message.UserPermissions[i] = 7;
                                break;
                            case "PERMISSION_CUSTOMER_API_KEYS_READ":
                            case 8:
                                message.UserPermissions[i] = 8;
                                break;
                            case "PERMISSION_CUSTOMER_API_KEYS_WRITE":
                            case 9:
                                message.UserPermissions[i] = 9;
                                break;
                            case "PERMISSION_CUSTOMER_BILLING_READ":
                            case 10:
                                message.UserPermissions[i] = 10;
                                break;
                            case "PERMISSION_CUSTOMER_PAYORS_READ":
                            case 11:
                                message.UserPermissions[i] = 11;
                                break;
                            case "PERMISSION_CUSTOMER_PIE_READ":
                            case 12:
                                message.UserPermissions[i] = 12;
                                break;
                            case "PERMISSION_CUSTOMER_PIE_WRITE":
                            case 13:
                                message.UserPermissions[i] = 13;
                                break;
                            case "PERMISSION_CUSTOMER_SETTINGS_READ":
                            case 14:
                                message.UserPermissions[i] = 14;
                                break;
                            case "PERMISSION_CUSTOMER_SETTINGS_WRITE":
                            case 15:
                                message.UserPermissions[i] = 15;
                                break;
                            case "PERMISSION_CUSTOMER_TASKS_READ":
                            case 16:
                                message.UserPermissions[i] = 16;
                                break;
                            case "PERMISSION_CUSTOMER_TASKS_WRITE":
                            case 17:
                                message.UserPermissions[i] = 17;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_IMPORTS_READ":
                            case 18:
                                message.UserPermissions[i] = 18;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_IMPORTS_WRITE":
                            case 19:
                                message.UserPermissions[i] = 19;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_RECORDINGS_READ":
                            case 34:
                                message.UserPermissions[i] = 34;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_RECORDINGS_LISTEN":
                            case 20:
                                message.UserPermissions[i] = 20;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_RECORDINGS_DOWNLOAD":
                            case 21:
                                message.UserPermissions[i] = 21;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_REPORTS_READ":
                            case 22:
                                message.UserPermissions[i] = 22;
                                break;
                            case "PERMISSION_CUSTOMER_TASK_REPORTS_WRITE":
                            case 23:
                                message.UserPermissions[i] = 23;
                                break;
                            case "PERMISSION_CUSTOMER_FT_SETUP_APPROVE":
                            case 24:
                                message.UserPermissions[i] = 24;
                                break;
                            case "PERMISSION_CUSTOMER_FT_TASKS_OPERATE":
                            case 25:
                                message.UserPermissions[i] = 25;
                                break;
                            case "PERMISSION_CUSTOMER_FT_TASKS_MANAGE":
                            case 26:
                                message.UserPermissions[i] = 26;
                                break;
                            case "PERMISSION_CUSTOMER_FT_DASHBOARD":
                            case 27:
                                message.UserPermissions[i] = 27;
                                break;
                            case "PERMISSION_CUSTOMER_DOCS_READ":
                            case 28:
                                message.UserPermissions[i] = 28;
                                break;
                            case "PERMISSION_CUSTOMER_PORTAL_ACCESS":
                            case 29:
                                message.UserPermissions[i] = 29;
                                break;
                            case "PERMISSION_CUSTOMER_FT_SOFTPHONE":
                            case 38:
                                message.UserPermissions[i] = 38;
                                break;
                            case "PERMISSION_CUSTOMER_FT_CALL_QUEUE":
                            case 39:
                                message.UserPermissions[i] = 39;
                                break;
                            case "PERMISSION_CUSTOMER_FT_CALL_AUTO":
                            case 40:
                                message.UserPermissions[i] = 40;
                                break;
                            case "PERMISSION_CUSTOMER_FT_CALL_MANUAL":
                            case 41:
                                message.UserPermissions[i] = 41;
                                break;
                            case "PERMISSION_CUSTOMER_FT_CALL_SALESFORCE":
                            case 42:
                                message.UserPermissions[i] = 42;
                                break;
                            case "PERMISSION_CUSTOMER_FT_DASHBOARD_TEAM":
                            case 43:
                                message.UserPermissions[i] = 43;
                                break;
                            case "PERMISSION_CUSTOMER_FT_DASHBOARD_ORG":
                            case 44:
                                message.UserPermissions[i] = 44;
                                break;
                            }
                    }
                    if (object.IDToken != null)
                        message.IDToken = String(object.IDToken);
                    if (object.AppName != null)
                        message.AppName = String(object.AppName);
                    if (object.RequestIPAddr != null)
                        message.RequestIPAddr = String(object.RequestIPAddr);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AuthServerMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {infinitusai.be.AuthServerMessage} message AuthServerMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AuthServerMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.OrgPermissions = [];
                        object.UserPermissions = [];
                    }
                    if (options.objects || options.defaults)
                        object.UserOrgs = {};
                    if (options.defaults) {
                        object.UserEmail = "";
                        object.UserUID = "";
                        object.UserDisplayName = "";
                        object.UserRole = options.enums === String ? "USER_ROLE_UNKNOWN" : 0;
                        object.UserStatus = options.enums === String ? "USER_STATUS_UNKNOWN" : 0;
                        object.OrgUUIDStr = "";
                        object.UserType = options.enums === String ? "USER_TYPE_UNKNOWN" : 0;
                        object.OrgUUID = "";
                        object.SuperAdmin = false;
                        object.RequestOrigin = "";
                        object.IDToken = "";
                        object.AppName = "";
                        object.RequestIPAddr = "";
                    }
                    if (message.UserEmail != null && message.hasOwnProperty("UserEmail"))
                        object.UserEmail = message.UserEmail;
                    if (message.UserUID != null && message.hasOwnProperty("UserUID"))
                        object.UserUID = message.UserUID;
                    if (message.UserDisplayName != null && message.hasOwnProperty("UserDisplayName"))
                        object.UserDisplayName = message.UserDisplayName;
                    if (message.UserRole != null && message.hasOwnProperty("UserRole"))
                        object.UserRole = options.enums === String ? $root.infinitusai.be.UserRole[message.UserRole] === undefined ? message.UserRole : $root.infinitusai.be.UserRole[message.UserRole] : message.UserRole;
                    if (message.UserStatus != null && message.hasOwnProperty("UserStatus"))
                        object.UserStatus = options.enums === String ? $root.infinitusai.be.UserStatus[message.UserStatus] === undefined ? message.UserStatus : $root.infinitusai.be.UserStatus[message.UserStatus] : message.UserStatus;
                    if (message.OrgUUIDStr != null && message.hasOwnProperty("OrgUUIDStr"))
                        object.OrgUUIDStr = message.OrgUUIDStr;
                    var keys2;
                    if (message.UserOrgs && (keys2 = Object.keys(message.UserOrgs)).length) {
                        object.UserOrgs = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.UserOrgs[keys2[j]] = options.enums === String ? $root.infinitusai.be.OrgRole[message.UserOrgs[keys2[j]]] === undefined ? message.UserOrgs[keys2[j]] : $root.infinitusai.be.OrgRole[message.UserOrgs[keys2[j]]] : message.UserOrgs[keys2[j]];
                    }
                    if (message.UserType != null && message.hasOwnProperty("UserType"))
                        object.UserType = options.enums === String ? $root.infinitusai.auth.UserType[message.UserType] === undefined ? message.UserType : $root.infinitusai.auth.UserType[message.UserType] : message.UserType;
                    if (message.OrgUUID != null && message.hasOwnProperty("OrgUUID"))
                        object.OrgUUID = message.OrgUUID;
                    if (message.OrgPermissions && message.OrgPermissions.length) {
                        object.OrgPermissions = [];
                        for (var j = 0; j < message.OrgPermissions.length; ++j)
                            object.OrgPermissions[j] = options.enums === String ? $root.infinitusai.auth.PermissionName[message.OrgPermissions[j]] === undefined ? message.OrgPermissions[j] : $root.infinitusai.auth.PermissionName[message.OrgPermissions[j]] : message.OrgPermissions[j];
                    }
                    if (message.SuperAdmin != null && message.hasOwnProperty("SuperAdmin"))
                        object.SuperAdmin = message.SuperAdmin;
                    if (message.RequestOrigin != null && message.hasOwnProperty("RequestOrigin"))
                        object.RequestOrigin = message.RequestOrigin;
                    if (message.UserPermissions && message.UserPermissions.length) {
                        object.UserPermissions = [];
                        for (var j = 0; j < message.UserPermissions.length; ++j)
                            object.UserPermissions[j] = options.enums === String ? $root.infinitusai.auth.PermissionName[message.UserPermissions[j]] === undefined ? message.UserPermissions[j] : $root.infinitusai.auth.PermissionName[message.UserPermissions[j]] : message.UserPermissions[j];
                    }
                    if (message.IDToken != null && message.hasOwnProperty("IDToken"))
                        object.IDToken = message.IDToken;
                    if (message.AppName != null && message.hasOwnProperty("AppName"))
                        object.AppName = message.AppName;
                    if (message.RequestIPAddr != null && message.hasOwnProperty("RequestIPAddr"))
                        object.RequestIPAddr = message.RequestIPAddr;
                    return object;
                };
    
                /**
                 * Converts this AuthServerMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AuthServerMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AuthServerMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AuthServerMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AuthServerMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AuthServerMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AuthServerMessage";
                };
    
                return AuthServerMessage;
            })();
    
            be.Auth = (function() {
    
                /**
                 * Constructs a new Auth service.
                 * @memberof infinitusai.be
                 * @classdesc Represents an Auth
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function Auth(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (Auth.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Auth;
    
                /**
                 * Creates new Auth service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.Auth
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {Auth} RPC service. Useful where requests and/or responses are streamed.
                 */
                Auth.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.Auth#getAuth}.
                 * @memberof infinitusai.be.Auth
                 * @typedef GetAuthCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.AuthServerMessage} [response] AuthServerMessage
                 */
    
                /**
                 * Calls GetAuth.
                 * @function getAuth
                 * @memberof infinitusai.be.Auth
                 * @instance
                 * @param {infinitusai.be.IAuthClientMessage} request AuthClientMessage message or plain object
                 * @param {infinitusai.be.Auth.GetAuthCallback} callback Node-style callback called with the error, if any, and AuthServerMessage
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Auth.prototype.getAuth = function getAuth(request, callback) {
                    return this.rpcCall(getAuth, $root.infinitusai.be.AuthClientMessage, $root.infinitusai.be.AuthServerMessage, request, callback);
                }, "name", { value: "GetAuth" });
    
                /**
                 * Calls GetAuth.
                 * @function getAuth
                 * @memberof infinitusai.be.Auth
                 * @instance
                 * @param {infinitusai.be.IAuthClientMessage} request AuthClientMessage message or plain object
                 * @returns {Promise<infinitusai.be.AuthServerMessage>} Promise
                 * @variation 2
                 */
    
                return Auth;
            })();
    
            /**
             * OperatorPortalEnvironment enum.
             * @name infinitusai.be.OperatorPortalEnvironment
             * @enum {number}
             * @property {number} ANY=0 ANY value
             * @property {number} STAGING=1 STAGING value
             * @property {number} PRODUCTION=2 PRODUCTION value
             */
            be.OperatorPortalEnvironment = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ANY"] = 0;
                values[valuesById[1] = "STAGING"] = 1;
                values[valuesById[2] = "PRODUCTION"] = 2;
                return values;
            })();
    
            be.GlobalConfig = (function() {
    
                /**
                 * Properties of a GlobalConfig.
                 * @memberof infinitusai.be
                 * @interface IGlobalConfig
                 * @property {number|null} [nexmoDalFraction] GlobalConfig nexmoDalFraction
                 * @property {infinitusai.be.IDate|null} [blizzardEndDate] GlobalConfig blizzardEndDate
                 * @property {number|null} [firstNOperatorsToPickLeastSkilled] GlobalConfig firstNOperatorsToPickLeastSkilled
                 * @property {infinitusai.be.OperatorPortalEnvironment|null} [targetOperatorEnvironment] GlobalConfig targetOperatorEnvironment
                 * @property {boolean|null} [useUnifiedQueueShadowMode] GlobalConfig useUnifiedQueueShadowMode
                 * @property {number|null} [defaultNumOperatorsAvailable] GlobalConfig defaultNumOperatorsAvailable
                 * @property {infinitusai.be.IGlobalUnifiedQueueConfig|null} [globalUnifiedQueueConfig] GlobalConfig globalUnifiedQueueConfig
                 * @property {infinitusai.be.IGlobalUnifiedReviewConfig|null} [globalUnifiedReviewConfig] GlobalConfig globalUnifiedReviewConfig
                 * @property {infinitusai.be.ITaskTypeConfig|null} [taskTypeConfig] GlobalConfig taskTypeConfig
                 * @property {number|null} [taskDueSoonHourLimit] GlobalConfig taskDueSoonHourLimit
                 * @property {number|null} [numDialogBreakdownsToRouteOperator] GlobalConfig numDialogBreakdownsToRouteOperator
                 * @property {number|null} [autoReviewClassifierHoldoutFraction] GlobalConfig autoReviewClassifierHoldoutFraction
                 */
    
                /**
                 * Constructs a new GlobalConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GlobalConfig.
                 * @implements IGlobalConfig
                 * @constructor
                 * @param {infinitusai.be.IGlobalConfig=} [properties] Properties to set
                 */
                function GlobalConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GlobalConfig nexmoDalFraction.
                 * @member {number} nexmoDalFraction
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.nexmoDalFraction = 0;
    
                /**
                 * GlobalConfig blizzardEndDate.
                 * @member {infinitusai.be.IDate|null|undefined} blizzardEndDate
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.blizzardEndDate = null;
    
                /**
                 * GlobalConfig firstNOperatorsToPickLeastSkilled.
                 * @member {number} firstNOperatorsToPickLeastSkilled
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.firstNOperatorsToPickLeastSkilled = 0;
    
                /**
                 * GlobalConfig targetOperatorEnvironment.
                 * @member {infinitusai.be.OperatorPortalEnvironment} targetOperatorEnvironment
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.targetOperatorEnvironment = 0;
    
                /**
                 * GlobalConfig useUnifiedQueueShadowMode.
                 * @member {boolean} useUnifiedQueueShadowMode
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.useUnifiedQueueShadowMode = false;
    
                /**
                 * GlobalConfig defaultNumOperatorsAvailable.
                 * @member {number} defaultNumOperatorsAvailable
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.defaultNumOperatorsAvailable = 0;
    
                /**
                 * GlobalConfig globalUnifiedQueueConfig.
                 * @member {infinitusai.be.IGlobalUnifiedQueueConfig|null|undefined} globalUnifiedQueueConfig
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.globalUnifiedQueueConfig = null;
    
                /**
                 * GlobalConfig globalUnifiedReviewConfig.
                 * @member {infinitusai.be.IGlobalUnifiedReviewConfig|null|undefined} globalUnifiedReviewConfig
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.globalUnifiedReviewConfig = null;
    
                /**
                 * GlobalConfig taskTypeConfig.
                 * @member {infinitusai.be.ITaskTypeConfig|null|undefined} taskTypeConfig
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.taskTypeConfig = null;
    
                /**
                 * GlobalConfig taskDueSoonHourLimit.
                 * @member {number} taskDueSoonHourLimit
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.taskDueSoonHourLimit = 0;
    
                /**
                 * GlobalConfig numDialogBreakdownsToRouteOperator.
                 * @member {number} numDialogBreakdownsToRouteOperator
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.numDialogBreakdownsToRouteOperator = 0;
    
                /**
                 * GlobalConfig autoReviewClassifierHoldoutFraction.
                 * @member {number} autoReviewClassifierHoldoutFraction
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 */
                GlobalConfig.prototype.autoReviewClassifierHoldoutFraction = 0;
    
                /**
                 * Creates a new GlobalConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {infinitusai.be.IGlobalConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.GlobalConfig} GlobalConfig instance
                 */
                GlobalConfig.create = function create(properties) {
                    return new GlobalConfig(properties);
                };
    
                /**
                 * Encodes the specified GlobalConfig message. Does not implicitly {@link infinitusai.be.GlobalConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {infinitusai.be.IGlobalConfig} message GlobalConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GlobalConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.nexmoDalFraction != null && Object.hasOwnProperty.call(message, "nexmoDalFraction"))
                        writer.uint32(/* id 1, wireType 1 =*/9).double(message.nexmoDalFraction);
                    if (message.blizzardEndDate != null && Object.hasOwnProperty.call(message, "blizzardEndDate"))
                        $root.infinitusai.be.Date.encode(message.blizzardEndDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.firstNOperatorsToPickLeastSkilled != null && Object.hasOwnProperty.call(message, "firstNOperatorsToPickLeastSkilled"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.firstNOperatorsToPickLeastSkilled);
                    if (message.targetOperatorEnvironment != null && Object.hasOwnProperty.call(message, "targetOperatorEnvironment"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.targetOperatorEnvironment);
                    if (message.useUnifiedQueueShadowMode != null && Object.hasOwnProperty.call(message, "useUnifiedQueueShadowMode"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.useUnifiedQueueShadowMode);
                    if (message.defaultNumOperatorsAvailable != null && Object.hasOwnProperty.call(message, "defaultNumOperatorsAvailable"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.defaultNumOperatorsAvailable);
                    if (message.globalUnifiedQueueConfig != null && Object.hasOwnProperty.call(message, "globalUnifiedQueueConfig"))
                        $root.infinitusai.be.GlobalUnifiedQueueConfig.encode(message.globalUnifiedQueueConfig, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.globalUnifiedReviewConfig != null && Object.hasOwnProperty.call(message, "globalUnifiedReviewConfig"))
                        $root.infinitusai.be.GlobalUnifiedReviewConfig.encode(message.globalUnifiedReviewConfig, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.taskTypeConfig != null && Object.hasOwnProperty.call(message, "taskTypeConfig"))
                        $root.infinitusai.be.TaskTypeConfig.encode(message.taskTypeConfig, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.taskDueSoonHourLimit != null && Object.hasOwnProperty.call(message, "taskDueSoonHourLimit"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.taskDueSoonHourLimit);
                    if (message.numDialogBreakdownsToRouteOperator != null && Object.hasOwnProperty.call(message, "numDialogBreakdownsToRouteOperator"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.numDialogBreakdownsToRouteOperator);
                    if (message.autoReviewClassifierHoldoutFraction != null && Object.hasOwnProperty.call(message, "autoReviewClassifierHoldoutFraction"))
                        writer.uint32(/* id 12, wireType 1 =*/97).double(message.autoReviewClassifierHoldoutFraction);
                    return writer;
                };
    
                /**
                 * Encodes the specified GlobalConfig message, length delimited. Does not implicitly {@link infinitusai.be.GlobalConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {infinitusai.be.IGlobalConfig} message GlobalConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GlobalConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GlobalConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GlobalConfig} GlobalConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GlobalConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GlobalConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.nexmoDalFraction = reader.double();
                                break;
                            }
                        case 2: {
                                message.blizzardEndDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.firstNOperatorsToPickLeastSkilled = reader.int32();
                                break;
                            }
                        case 4: {
                                message.targetOperatorEnvironment = reader.int32();
                                break;
                            }
                        case 5: {
                                message.useUnifiedQueueShadowMode = reader.bool();
                                break;
                            }
                        case 6: {
                                message.defaultNumOperatorsAvailable = reader.int32();
                                break;
                            }
                        case 7: {
                                message.globalUnifiedQueueConfig = $root.infinitusai.be.GlobalUnifiedQueueConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.globalUnifiedReviewConfig = $root.infinitusai.be.GlobalUnifiedReviewConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.taskTypeConfig = $root.infinitusai.be.TaskTypeConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.taskDueSoonHourLimit = reader.int32();
                                break;
                            }
                        case 11: {
                                message.numDialogBreakdownsToRouteOperator = reader.int32();
                                break;
                            }
                        case 12: {
                                message.autoReviewClassifierHoldoutFraction = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GlobalConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GlobalConfig} GlobalConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GlobalConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GlobalConfig message.
                 * @function verify
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GlobalConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.nexmoDalFraction != null && message.hasOwnProperty("nexmoDalFraction"))
                        if (typeof message.nexmoDalFraction !== "number")
                            return "nexmoDalFraction: number expected";
                    if (message.blizzardEndDate != null && message.hasOwnProperty("blizzardEndDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.blizzardEndDate);
                        if (error)
                            return "blizzardEndDate." + error;
                    }
                    if (message.firstNOperatorsToPickLeastSkilled != null && message.hasOwnProperty("firstNOperatorsToPickLeastSkilled"))
                        if (!$util.isInteger(message.firstNOperatorsToPickLeastSkilled))
                            return "firstNOperatorsToPickLeastSkilled: integer expected";
                    if (message.targetOperatorEnvironment != null && message.hasOwnProperty("targetOperatorEnvironment"))
                        switch (message.targetOperatorEnvironment) {
                        default:
                            return "targetOperatorEnvironment: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.useUnifiedQueueShadowMode != null && message.hasOwnProperty("useUnifiedQueueShadowMode"))
                        if (typeof message.useUnifiedQueueShadowMode !== "boolean")
                            return "useUnifiedQueueShadowMode: boolean expected";
                    if (message.defaultNumOperatorsAvailable != null && message.hasOwnProperty("defaultNumOperatorsAvailable"))
                        if (!$util.isInteger(message.defaultNumOperatorsAvailable))
                            return "defaultNumOperatorsAvailable: integer expected";
                    if (message.globalUnifiedQueueConfig != null && message.hasOwnProperty("globalUnifiedQueueConfig")) {
                        var error = $root.infinitusai.be.GlobalUnifiedQueueConfig.verify(message.globalUnifiedQueueConfig);
                        if (error)
                            return "globalUnifiedQueueConfig." + error;
                    }
                    if (message.globalUnifiedReviewConfig != null && message.hasOwnProperty("globalUnifiedReviewConfig")) {
                        var error = $root.infinitusai.be.GlobalUnifiedReviewConfig.verify(message.globalUnifiedReviewConfig);
                        if (error)
                            return "globalUnifiedReviewConfig." + error;
                    }
                    if (message.taskTypeConfig != null && message.hasOwnProperty("taskTypeConfig")) {
                        var error = $root.infinitusai.be.TaskTypeConfig.verify(message.taskTypeConfig);
                        if (error)
                            return "taskTypeConfig." + error;
                    }
                    if (message.taskDueSoonHourLimit != null && message.hasOwnProperty("taskDueSoonHourLimit"))
                        if (!$util.isInteger(message.taskDueSoonHourLimit))
                            return "taskDueSoonHourLimit: integer expected";
                    if (message.numDialogBreakdownsToRouteOperator != null && message.hasOwnProperty("numDialogBreakdownsToRouteOperator"))
                        if (!$util.isInteger(message.numDialogBreakdownsToRouteOperator))
                            return "numDialogBreakdownsToRouteOperator: integer expected";
                    if (message.autoReviewClassifierHoldoutFraction != null && message.hasOwnProperty("autoReviewClassifierHoldoutFraction"))
                        if (typeof message.autoReviewClassifierHoldoutFraction !== "number")
                            return "autoReviewClassifierHoldoutFraction: number expected";
                    return null;
                };
    
                /**
                 * Creates a GlobalConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GlobalConfig} GlobalConfig
                 */
                GlobalConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GlobalConfig)
                        return object;
                    var message = new $root.infinitusai.be.GlobalConfig();
                    if (object.nexmoDalFraction != null)
                        message.nexmoDalFraction = Number(object.nexmoDalFraction);
                    if (object.blizzardEndDate != null) {
                        if (typeof object.blizzardEndDate !== "object")
                            throw TypeError(".infinitusai.be.GlobalConfig.blizzardEndDate: object expected");
                        message.blizzardEndDate = $root.infinitusai.be.Date.fromObject(object.blizzardEndDate);
                    }
                    if (object.firstNOperatorsToPickLeastSkilled != null)
                        message.firstNOperatorsToPickLeastSkilled = object.firstNOperatorsToPickLeastSkilled | 0;
                    switch (object.targetOperatorEnvironment) {
                    default:
                        if (typeof object.targetOperatorEnvironment === "number") {
                            message.targetOperatorEnvironment = object.targetOperatorEnvironment;
                            break;
                        }
                        break;
                    case "ANY":
                    case 0:
                        message.targetOperatorEnvironment = 0;
                        break;
                    case "STAGING":
                    case 1:
                        message.targetOperatorEnvironment = 1;
                        break;
                    case "PRODUCTION":
                    case 2:
                        message.targetOperatorEnvironment = 2;
                        break;
                    }
                    if (object.useUnifiedQueueShadowMode != null)
                        message.useUnifiedQueueShadowMode = Boolean(object.useUnifiedQueueShadowMode);
                    if (object.defaultNumOperatorsAvailable != null)
                        message.defaultNumOperatorsAvailable = object.defaultNumOperatorsAvailable | 0;
                    if (object.globalUnifiedQueueConfig != null) {
                        if (typeof object.globalUnifiedQueueConfig !== "object")
                            throw TypeError(".infinitusai.be.GlobalConfig.globalUnifiedQueueConfig: object expected");
                        message.globalUnifiedQueueConfig = $root.infinitusai.be.GlobalUnifiedQueueConfig.fromObject(object.globalUnifiedQueueConfig);
                    }
                    if (object.globalUnifiedReviewConfig != null) {
                        if (typeof object.globalUnifiedReviewConfig !== "object")
                            throw TypeError(".infinitusai.be.GlobalConfig.globalUnifiedReviewConfig: object expected");
                        message.globalUnifiedReviewConfig = $root.infinitusai.be.GlobalUnifiedReviewConfig.fromObject(object.globalUnifiedReviewConfig);
                    }
                    if (object.taskTypeConfig != null) {
                        if (typeof object.taskTypeConfig !== "object")
                            throw TypeError(".infinitusai.be.GlobalConfig.taskTypeConfig: object expected");
                        message.taskTypeConfig = $root.infinitusai.be.TaskTypeConfig.fromObject(object.taskTypeConfig);
                    }
                    if (object.taskDueSoonHourLimit != null)
                        message.taskDueSoonHourLimit = object.taskDueSoonHourLimit | 0;
                    if (object.numDialogBreakdownsToRouteOperator != null)
                        message.numDialogBreakdownsToRouteOperator = object.numDialogBreakdownsToRouteOperator | 0;
                    if (object.autoReviewClassifierHoldoutFraction != null)
                        message.autoReviewClassifierHoldoutFraction = Number(object.autoReviewClassifierHoldoutFraction);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GlobalConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {infinitusai.be.GlobalConfig} message GlobalConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GlobalConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.nexmoDalFraction = 0;
                        object.blizzardEndDate = null;
                        object.firstNOperatorsToPickLeastSkilled = 0;
                        object.targetOperatorEnvironment = options.enums === String ? "ANY" : 0;
                        object.useUnifiedQueueShadowMode = false;
                        object.defaultNumOperatorsAvailable = 0;
                        object.globalUnifiedQueueConfig = null;
                        object.globalUnifiedReviewConfig = null;
                        object.taskTypeConfig = null;
                        object.taskDueSoonHourLimit = 0;
                        object.numDialogBreakdownsToRouteOperator = 0;
                        object.autoReviewClassifierHoldoutFraction = 0;
                    }
                    if (message.nexmoDalFraction != null && message.hasOwnProperty("nexmoDalFraction"))
                        object.nexmoDalFraction = options.json && !isFinite(message.nexmoDalFraction) ? String(message.nexmoDalFraction) : message.nexmoDalFraction;
                    if (message.blizzardEndDate != null && message.hasOwnProperty("blizzardEndDate"))
                        object.blizzardEndDate = $root.infinitusai.be.Date.toObject(message.blizzardEndDate, options);
                    if (message.firstNOperatorsToPickLeastSkilled != null && message.hasOwnProperty("firstNOperatorsToPickLeastSkilled"))
                        object.firstNOperatorsToPickLeastSkilled = message.firstNOperatorsToPickLeastSkilled;
                    if (message.targetOperatorEnvironment != null && message.hasOwnProperty("targetOperatorEnvironment"))
                        object.targetOperatorEnvironment = options.enums === String ? $root.infinitusai.be.OperatorPortalEnvironment[message.targetOperatorEnvironment] === undefined ? message.targetOperatorEnvironment : $root.infinitusai.be.OperatorPortalEnvironment[message.targetOperatorEnvironment] : message.targetOperatorEnvironment;
                    if (message.useUnifiedQueueShadowMode != null && message.hasOwnProperty("useUnifiedQueueShadowMode"))
                        object.useUnifiedQueueShadowMode = message.useUnifiedQueueShadowMode;
                    if (message.defaultNumOperatorsAvailable != null && message.hasOwnProperty("defaultNumOperatorsAvailable"))
                        object.defaultNumOperatorsAvailable = message.defaultNumOperatorsAvailable;
                    if (message.globalUnifiedQueueConfig != null && message.hasOwnProperty("globalUnifiedQueueConfig"))
                        object.globalUnifiedQueueConfig = $root.infinitusai.be.GlobalUnifiedQueueConfig.toObject(message.globalUnifiedQueueConfig, options);
                    if (message.globalUnifiedReviewConfig != null && message.hasOwnProperty("globalUnifiedReviewConfig"))
                        object.globalUnifiedReviewConfig = $root.infinitusai.be.GlobalUnifiedReviewConfig.toObject(message.globalUnifiedReviewConfig, options);
                    if (message.taskTypeConfig != null && message.hasOwnProperty("taskTypeConfig"))
                        object.taskTypeConfig = $root.infinitusai.be.TaskTypeConfig.toObject(message.taskTypeConfig, options);
                    if (message.taskDueSoonHourLimit != null && message.hasOwnProperty("taskDueSoonHourLimit"))
                        object.taskDueSoonHourLimit = message.taskDueSoonHourLimit;
                    if (message.numDialogBreakdownsToRouteOperator != null && message.hasOwnProperty("numDialogBreakdownsToRouteOperator"))
                        object.numDialogBreakdownsToRouteOperator = message.numDialogBreakdownsToRouteOperator;
                    if (message.autoReviewClassifierHoldoutFraction != null && message.hasOwnProperty("autoReviewClassifierHoldoutFraction"))
                        object.autoReviewClassifierHoldoutFraction = options.json && !isFinite(message.autoReviewClassifierHoldoutFraction) ? String(message.autoReviewClassifierHoldoutFraction) : message.autoReviewClassifierHoldoutFraction;
                    return object;
                };
    
                /**
                 * Converts this GlobalConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GlobalConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GlobalConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GlobalConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GlobalConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GlobalConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GlobalConfig";
                };
    
                return GlobalConfig;
            })();
    
            be.TaskTypeConfig = (function() {
    
                /**
                 * Properties of a TaskTypeConfig.
                 * @memberof infinitusai.be
                 * @interface ITaskTypeConfig
                 * @property {number|Long|null} [minsSelfQaAllowed] TaskTypeConfig minsSelfQaAllowed
                 * @property {number|null} [probabilisticAutoReviewThreshold] TaskTypeConfig probabilisticAutoReviewThreshold
                 */
    
                /**
                 * Constructs a new TaskTypeConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a TaskTypeConfig.
                 * @implements ITaskTypeConfig
                 * @constructor
                 * @param {infinitusai.be.ITaskTypeConfig=} [properties] Properties to set
                 */
                function TaskTypeConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskTypeConfig minsSelfQaAllowed.
                 * @member {number|Long} minsSelfQaAllowed
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @instance
                 */
                TaskTypeConfig.prototype.minsSelfQaAllowed = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * TaskTypeConfig probabilisticAutoReviewThreshold.
                 * @member {number} probabilisticAutoReviewThreshold
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @instance
                 */
                TaskTypeConfig.prototype.probabilisticAutoReviewThreshold = 0;
    
                /**
                 * Creates a new TaskTypeConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {infinitusai.be.ITaskTypeConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.TaskTypeConfig} TaskTypeConfig instance
                 */
                TaskTypeConfig.create = function create(properties) {
                    return new TaskTypeConfig(properties);
                };
    
                /**
                 * Encodes the specified TaskTypeConfig message. Does not implicitly {@link infinitusai.be.TaskTypeConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {infinitusai.be.ITaskTypeConfig} message TaskTypeConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskTypeConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.minsSelfQaAllowed != null && Object.hasOwnProperty.call(message, "minsSelfQaAllowed"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.minsSelfQaAllowed);
                    if (message.probabilisticAutoReviewThreshold != null && Object.hasOwnProperty.call(message, "probabilisticAutoReviewThreshold"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.probabilisticAutoReviewThreshold);
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskTypeConfig message, length delimited. Does not implicitly {@link infinitusai.be.TaskTypeConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {infinitusai.be.ITaskTypeConfig} message TaskTypeConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskTypeConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskTypeConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.TaskTypeConfig} TaskTypeConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskTypeConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.TaskTypeConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.minsSelfQaAllowed = reader.int64();
                                break;
                            }
                        case 2: {
                                message.probabilisticAutoReviewThreshold = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskTypeConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.TaskTypeConfig} TaskTypeConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskTypeConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskTypeConfig message.
                 * @function verify
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskTypeConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.minsSelfQaAllowed != null && message.hasOwnProperty("minsSelfQaAllowed"))
                        if (!$util.isInteger(message.minsSelfQaAllowed) && !(message.minsSelfQaAllowed && $util.isInteger(message.minsSelfQaAllowed.low) && $util.isInteger(message.minsSelfQaAllowed.high)))
                            return "minsSelfQaAllowed: integer|Long expected";
                    if (message.probabilisticAutoReviewThreshold != null && message.hasOwnProperty("probabilisticAutoReviewThreshold"))
                        if (typeof message.probabilisticAutoReviewThreshold !== "number")
                            return "probabilisticAutoReviewThreshold: number expected";
                    return null;
                };
    
                /**
                 * Creates a TaskTypeConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.TaskTypeConfig} TaskTypeConfig
                 */
                TaskTypeConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.TaskTypeConfig)
                        return object;
                    var message = new $root.infinitusai.be.TaskTypeConfig();
                    if (object.minsSelfQaAllowed != null)
                        if ($util.Long)
                            (message.minsSelfQaAllowed = $util.Long.fromValue(object.minsSelfQaAllowed)).unsigned = false;
                        else if (typeof object.minsSelfQaAllowed === "string")
                            message.minsSelfQaAllowed = parseInt(object.minsSelfQaAllowed, 10);
                        else if (typeof object.minsSelfQaAllowed === "number")
                            message.minsSelfQaAllowed = object.minsSelfQaAllowed;
                        else if (typeof object.minsSelfQaAllowed === "object")
                            message.minsSelfQaAllowed = new $util.LongBits(object.minsSelfQaAllowed.low >>> 0, object.minsSelfQaAllowed.high >>> 0).toNumber();
                    if (object.probabilisticAutoReviewThreshold != null)
                        message.probabilisticAutoReviewThreshold = Number(object.probabilisticAutoReviewThreshold);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskTypeConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {infinitusai.be.TaskTypeConfig} message TaskTypeConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskTypeConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.minsSelfQaAllowed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.minsSelfQaAllowed = options.longs === String ? "0" : 0;
                        object.probabilisticAutoReviewThreshold = 0;
                    }
                    if (message.minsSelfQaAllowed != null && message.hasOwnProperty("minsSelfQaAllowed"))
                        if (typeof message.minsSelfQaAllowed === "number")
                            object.minsSelfQaAllowed = options.longs === String ? String(message.minsSelfQaAllowed) : message.minsSelfQaAllowed;
                        else
                            object.minsSelfQaAllowed = options.longs === String ? $util.Long.prototype.toString.call(message.minsSelfQaAllowed) : options.longs === Number ? new $util.LongBits(message.minsSelfQaAllowed.low >>> 0, message.minsSelfQaAllowed.high >>> 0).toNumber() : message.minsSelfQaAllowed;
                    if (message.probabilisticAutoReviewThreshold != null && message.hasOwnProperty("probabilisticAutoReviewThreshold"))
                        object.probabilisticAutoReviewThreshold = options.json && !isFinite(message.probabilisticAutoReviewThreshold) ? String(message.probabilisticAutoReviewThreshold) : message.probabilisticAutoReviewThreshold;
                    return object;
                };
    
                /**
                 * Converts this TaskTypeConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskTypeConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskTypeConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.TaskTypeConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskTypeConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.TaskTypeConfig";
                };
    
                return TaskTypeConfig;
            })();
    
            be.GlobalUnifiedQueueConfig = (function() {
    
                /**
                 * Properties of a GlobalUnifiedQueueConfig.
                 * @memberof infinitusai.be
                 * @interface IGlobalUnifiedQueueConfig
                 * @property {boolean|null} [useUnifiedQueueShadowMode] GlobalUnifiedQueueConfig useUnifiedQueueShadowMode
                 * @property {number|null} [defaultNumOperatorsAvailable] GlobalUnifiedQueueConfig defaultNumOperatorsAvailable
                 * @property {string|null} [queueRunInEnv] GlobalUnifiedQueueConfig queueRunInEnv
                 * @property {number|null} [smoothingFactor] GlobalUnifiedQueueConfig smoothingFactor
                 * @property {boolean|null} [useTaskEligibilityPool] GlobalUnifiedQueueConfig useTaskEligibilityPool
                 */
    
                /**
                 * Constructs a new GlobalUnifiedQueueConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GlobalUnifiedQueueConfig.
                 * @implements IGlobalUnifiedQueueConfig
                 * @constructor
                 * @param {infinitusai.be.IGlobalUnifiedQueueConfig=} [properties] Properties to set
                 */
                function GlobalUnifiedQueueConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GlobalUnifiedQueueConfig useUnifiedQueueShadowMode.
                 * @member {boolean} useUnifiedQueueShadowMode
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @instance
                 */
                GlobalUnifiedQueueConfig.prototype.useUnifiedQueueShadowMode = false;
    
                /**
                 * GlobalUnifiedQueueConfig defaultNumOperatorsAvailable.
                 * @member {number} defaultNumOperatorsAvailable
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @instance
                 */
                GlobalUnifiedQueueConfig.prototype.defaultNumOperatorsAvailable = 0;
    
                /**
                 * GlobalUnifiedQueueConfig queueRunInEnv.
                 * @member {string} queueRunInEnv
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @instance
                 */
                GlobalUnifiedQueueConfig.prototype.queueRunInEnv = "";
    
                /**
                 * GlobalUnifiedQueueConfig smoothingFactor.
                 * @member {number} smoothingFactor
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @instance
                 */
                GlobalUnifiedQueueConfig.prototype.smoothingFactor = 0;
    
                /**
                 * GlobalUnifiedQueueConfig useTaskEligibilityPool.
                 * @member {boolean} useTaskEligibilityPool
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @instance
                 */
                GlobalUnifiedQueueConfig.prototype.useTaskEligibilityPool = false;
    
                /**
                 * Creates a new GlobalUnifiedQueueConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {infinitusai.be.IGlobalUnifiedQueueConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.GlobalUnifiedQueueConfig} GlobalUnifiedQueueConfig instance
                 */
                GlobalUnifiedQueueConfig.create = function create(properties) {
                    return new GlobalUnifiedQueueConfig(properties);
                };
    
                /**
                 * Encodes the specified GlobalUnifiedQueueConfig message. Does not implicitly {@link infinitusai.be.GlobalUnifiedQueueConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {infinitusai.be.IGlobalUnifiedQueueConfig} message GlobalUnifiedQueueConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GlobalUnifiedQueueConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.useUnifiedQueueShadowMode != null && Object.hasOwnProperty.call(message, "useUnifiedQueueShadowMode"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.useUnifiedQueueShadowMode);
                    if (message.defaultNumOperatorsAvailable != null && Object.hasOwnProperty.call(message, "defaultNumOperatorsAvailable"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.defaultNumOperatorsAvailable);
                    if (message.queueRunInEnv != null && Object.hasOwnProperty.call(message, "queueRunInEnv"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.queueRunInEnv);
                    if (message.smoothingFactor != null && Object.hasOwnProperty.call(message, "smoothingFactor"))
                        writer.uint32(/* id 4, wireType 5 =*/37).float(message.smoothingFactor);
                    if (message.useTaskEligibilityPool != null && Object.hasOwnProperty.call(message, "useTaskEligibilityPool"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.useTaskEligibilityPool);
                    return writer;
                };
    
                /**
                 * Encodes the specified GlobalUnifiedQueueConfig message, length delimited. Does not implicitly {@link infinitusai.be.GlobalUnifiedQueueConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {infinitusai.be.IGlobalUnifiedQueueConfig} message GlobalUnifiedQueueConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GlobalUnifiedQueueConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GlobalUnifiedQueueConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GlobalUnifiedQueueConfig} GlobalUnifiedQueueConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GlobalUnifiedQueueConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GlobalUnifiedQueueConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.useUnifiedQueueShadowMode = reader.bool();
                                break;
                            }
                        case 2: {
                                message.defaultNumOperatorsAvailable = reader.int32();
                                break;
                            }
                        case 3: {
                                message.queueRunInEnv = reader.string();
                                break;
                            }
                        case 4: {
                                message.smoothingFactor = reader.float();
                                break;
                            }
                        case 5: {
                                message.useTaskEligibilityPool = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GlobalUnifiedQueueConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GlobalUnifiedQueueConfig} GlobalUnifiedQueueConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GlobalUnifiedQueueConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GlobalUnifiedQueueConfig message.
                 * @function verify
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GlobalUnifiedQueueConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.useUnifiedQueueShadowMode != null && message.hasOwnProperty("useUnifiedQueueShadowMode"))
                        if (typeof message.useUnifiedQueueShadowMode !== "boolean")
                            return "useUnifiedQueueShadowMode: boolean expected";
                    if (message.defaultNumOperatorsAvailable != null && message.hasOwnProperty("defaultNumOperatorsAvailable"))
                        if (!$util.isInteger(message.defaultNumOperatorsAvailable))
                            return "defaultNumOperatorsAvailable: integer expected";
                    if (message.queueRunInEnv != null && message.hasOwnProperty("queueRunInEnv"))
                        if (!$util.isString(message.queueRunInEnv))
                            return "queueRunInEnv: string expected";
                    if (message.smoothingFactor != null && message.hasOwnProperty("smoothingFactor"))
                        if (typeof message.smoothingFactor !== "number")
                            return "smoothingFactor: number expected";
                    if (message.useTaskEligibilityPool != null && message.hasOwnProperty("useTaskEligibilityPool"))
                        if (typeof message.useTaskEligibilityPool !== "boolean")
                            return "useTaskEligibilityPool: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a GlobalUnifiedQueueConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GlobalUnifiedQueueConfig} GlobalUnifiedQueueConfig
                 */
                GlobalUnifiedQueueConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GlobalUnifiedQueueConfig)
                        return object;
                    var message = new $root.infinitusai.be.GlobalUnifiedQueueConfig();
                    if (object.useUnifiedQueueShadowMode != null)
                        message.useUnifiedQueueShadowMode = Boolean(object.useUnifiedQueueShadowMode);
                    if (object.defaultNumOperatorsAvailable != null)
                        message.defaultNumOperatorsAvailable = object.defaultNumOperatorsAvailable | 0;
                    if (object.queueRunInEnv != null)
                        message.queueRunInEnv = String(object.queueRunInEnv);
                    if (object.smoothingFactor != null)
                        message.smoothingFactor = Number(object.smoothingFactor);
                    if (object.useTaskEligibilityPool != null)
                        message.useTaskEligibilityPool = Boolean(object.useTaskEligibilityPool);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GlobalUnifiedQueueConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {infinitusai.be.GlobalUnifiedQueueConfig} message GlobalUnifiedQueueConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GlobalUnifiedQueueConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.useUnifiedQueueShadowMode = false;
                        object.defaultNumOperatorsAvailable = 0;
                        object.queueRunInEnv = "";
                        object.smoothingFactor = 0;
                        object.useTaskEligibilityPool = false;
                    }
                    if (message.useUnifiedQueueShadowMode != null && message.hasOwnProperty("useUnifiedQueueShadowMode"))
                        object.useUnifiedQueueShadowMode = message.useUnifiedQueueShadowMode;
                    if (message.defaultNumOperatorsAvailable != null && message.hasOwnProperty("defaultNumOperatorsAvailable"))
                        object.defaultNumOperatorsAvailable = message.defaultNumOperatorsAvailable;
                    if (message.queueRunInEnv != null && message.hasOwnProperty("queueRunInEnv"))
                        object.queueRunInEnv = message.queueRunInEnv;
                    if (message.smoothingFactor != null && message.hasOwnProperty("smoothingFactor"))
                        object.smoothingFactor = options.json && !isFinite(message.smoothingFactor) ? String(message.smoothingFactor) : message.smoothingFactor;
                    if (message.useTaskEligibilityPool != null && message.hasOwnProperty("useTaskEligibilityPool"))
                        object.useTaskEligibilityPool = message.useTaskEligibilityPool;
                    return object;
                };
    
                /**
                 * Converts this GlobalUnifiedQueueConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GlobalUnifiedQueueConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GlobalUnifiedQueueConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GlobalUnifiedQueueConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GlobalUnifiedQueueConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GlobalUnifiedQueueConfig";
                };
    
                return GlobalUnifiedQueueConfig;
            })();
    
            be.GlobalUnifiedReviewConfig = (function() {
    
                /**
                 * Properties of a GlobalUnifiedReviewConfig.
                 * @memberof infinitusai.be
                 * @interface IGlobalUnifiedReviewConfig
                 * @property {boolean|null} [enabled] GlobalUnifiedReviewConfig enabled
                 * @property {number|null} [defaultNumOperatorsAvailable] GlobalUnifiedReviewConfig defaultNumOperatorsAvailable
                 * @property {string|null} [reviewRunInEnv] GlobalUnifiedReviewConfig reviewRunInEnv
                 * @property {boolean|null} [enableAutoStartReview] GlobalUnifiedReviewConfig enableAutoStartReview
                 * @property {number|null} [pollingIntervalSeconds] GlobalUnifiedReviewConfig pollingIntervalSeconds
                 * @property {boolean|null} [useTaskEligibilityPool] GlobalUnifiedReviewConfig useTaskEligibilityPool
                 * @property {number|null} [routingDelayMinutes] GlobalUnifiedReviewConfig routingDelayMinutes
                 */
    
                /**
                 * Constructs a new GlobalUnifiedReviewConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GlobalUnifiedReviewConfig.
                 * @implements IGlobalUnifiedReviewConfig
                 * @constructor
                 * @param {infinitusai.be.IGlobalUnifiedReviewConfig=} [properties] Properties to set
                 */
                function GlobalUnifiedReviewConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GlobalUnifiedReviewConfig enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @instance
                 */
                GlobalUnifiedReviewConfig.prototype.enabled = false;
    
                /**
                 * GlobalUnifiedReviewConfig defaultNumOperatorsAvailable.
                 * @member {number} defaultNumOperatorsAvailable
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @instance
                 */
                GlobalUnifiedReviewConfig.prototype.defaultNumOperatorsAvailable = 0;
    
                /**
                 * GlobalUnifiedReviewConfig reviewRunInEnv.
                 * @member {string} reviewRunInEnv
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @instance
                 */
                GlobalUnifiedReviewConfig.prototype.reviewRunInEnv = "";
    
                /**
                 * GlobalUnifiedReviewConfig enableAutoStartReview.
                 * @member {boolean} enableAutoStartReview
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @instance
                 */
                GlobalUnifiedReviewConfig.prototype.enableAutoStartReview = false;
    
                /**
                 * GlobalUnifiedReviewConfig pollingIntervalSeconds.
                 * @member {number} pollingIntervalSeconds
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @instance
                 */
                GlobalUnifiedReviewConfig.prototype.pollingIntervalSeconds = 0;
    
                /**
                 * GlobalUnifiedReviewConfig useTaskEligibilityPool.
                 * @member {boolean} useTaskEligibilityPool
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @instance
                 */
                GlobalUnifiedReviewConfig.prototype.useTaskEligibilityPool = false;
    
                /**
                 * GlobalUnifiedReviewConfig routingDelayMinutes.
                 * @member {number} routingDelayMinutes
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @instance
                 */
                GlobalUnifiedReviewConfig.prototype.routingDelayMinutes = 0;
    
                /**
                 * Creates a new GlobalUnifiedReviewConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {infinitusai.be.IGlobalUnifiedReviewConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.GlobalUnifiedReviewConfig} GlobalUnifiedReviewConfig instance
                 */
                GlobalUnifiedReviewConfig.create = function create(properties) {
                    return new GlobalUnifiedReviewConfig(properties);
                };
    
                /**
                 * Encodes the specified GlobalUnifiedReviewConfig message. Does not implicitly {@link infinitusai.be.GlobalUnifiedReviewConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {infinitusai.be.IGlobalUnifiedReviewConfig} message GlobalUnifiedReviewConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GlobalUnifiedReviewConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
                    if (message.defaultNumOperatorsAvailable != null && Object.hasOwnProperty.call(message, "defaultNumOperatorsAvailable"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.defaultNumOperatorsAvailable);
                    if (message.reviewRunInEnv != null && Object.hasOwnProperty.call(message, "reviewRunInEnv"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.reviewRunInEnv);
                    if (message.enableAutoStartReview != null && Object.hasOwnProperty.call(message, "enableAutoStartReview"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.enableAutoStartReview);
                    if (message.pollingIntervalSeconds != null && Object.hasOwnProperty.call(message, "pollingIntervalSeconds"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pollingIntervalSeconds);
                    if (message.useTaskEligibilityPool != null && Object.hasOwnProperty.call(message, "useTaskEligibilityPool"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.useTaskEligibilityPool);
                    if (message.routingDelayMinutes != null && Object.hasOwnProperty.call(message, "routingDelayMinutes"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.routingDelayMinutes);
                    return writer;
                };
    
                /**
                 * Encodes the specified GlobalUnifiedReviewConfig message, length delimited. Does not implicitly {@link infinitusai.be.GlobalUnifiedReviewConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {infinitusai.be.IGlobalUnifiedReviewConfig} message GlobalUnifiedReviewConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GlobalUnifiedReviewConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GlobalUnifiedReviewConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GlobalUnifiedReviewConfig} GlobalUnifiedReviewConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GlobalUnifiedReviewConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GlobalUnifiedReviewConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 2: {
                                message.defaultNumOperatorsAvailable = reader.int32();
                                break;
                            }
                        case 3: {
                                message.reviewRunInEnv = reader.string();
                                break;
                            }
                        case 4: {
                                message.enableAutoStartReview = reader.bool();
                                break;
                            }
                        case 5: {
                                message.pollingIntervalSeconds = reader.int32();
                                break;
                            }
                        case 6: {
                                message.useTaskEligibilityPool = reader.bool();
                                break;
                            }
                        case 7: {
                                message.routingDelayMinutes = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GlobalUnifiedReviewConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GlobalUnifiedReviewConfig} GlobalUnifiedReviewConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GlobalUnifiedReviewConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GlobalUnifiedReviewConfig message.
                 * @function verify
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GlobalUnifiedReviewConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.defaultNumOperatorsAvailable != null && message.hasOwnProperty("defaultNumOperatorsAvailable"))
                        if (!$util.isInteger(message.defaultNumOperatorsAvailable))
                            return "defaultNumOperatorsAvailable: integer expected";
                    if (message.reviewRunInEnv != null && message.hasOwnProperty("reviewRunInEnv"))
                        if (!$util.isString(message.reviewRunInEnv))
                            return "reviewRunInEnv: string expected";
                    if (message.enableAutoStartReview != null && message.hasOwnProperty("enableAutoStartReview"))
                        if (typeof message.enableAutoStartReview !== "boolean")
                            return "enableAutoStartReview: boolean expected";
                    if (message.pollingIntervalSeconds != null && message.hasOwnProperty("pollingIntervalSeconds"))
                        if (!$util.isInteger(message.pollingIntervalSeconds))
                            return "pollingIntervalSeconds: integer expected";
                    if (message.useTaskEligibilityPool != null && message.hasOwnProperty("useTaskEligibilityPool"))
                        if (typeof message.useTaskEligibilityPool !== "boolean")
                            return "useTaskEligibilityPool: boolean expected";
                    if (message.routingDelayMinutes != null && message.hasOwnProperty("routingDelayMinutes"))
                        if (!$util.isInteger(message.routingDelayMinutes))
                            return "routingDelayMinutes: integer expected";
                    return null;
                };
    
                /**
                 * Creates a GlobalUnifiedReviewConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GlobalUnifiedReviewConfig} GlobalUnifiedReviewConfig
                 */
                GlobalUnifiedReviewConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GlobalUnifiedReviewConfig)
                        return object;
                    var message = new $root.infinitusai.be.GlobalUnifiedReviewConfig();
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.defaultNumOperatorsAvailable != null)
                        message.defaultNumOperatorsAvailable = object.defaultNumOperatorsAvailable | 0;
                    if (object.reviewRunInEnv != null)
                        message.reviewRunInEnv = String(object.reviewRunInEnv);
                    if (object.enableAutoStartReview != null)
                        message.enableAutoStartReview = Boolean(object.enableAutoStartReview);
                    if (object.pollingIntervalSeconds != null)
                        message.pollingIntervalSeconds = object.pollingIntervalSeconds | 0;
                    if (object.useTaskEligibilityPool != null)
                        message.useTaskEligibilityPool = Boolean(object.useTaskEligibilityPool);
                    if (object.routingDelayMinutes != null)
                        message.routingDelayMinutes = object.routingDelayMinutes | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GlobalUnifiedReviewConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {infinitusai.be.GlobalUnifiedReviewConfig} message GlobalUnifiedReviewConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GlobalUnifiedReviewConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.enabled = false;
                        object.defaultNumOperatorsAvailable = 0;
                        object.reviewRunInEnv = "";
                        object.enableAutoStartReview = false;
                        object.pollingIntervalSeconds = 0;
                        object.useTaskEligibilityPool = false;
                        object.routingDelayMinutes = 0;
                    }
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.defaultNumOperatorsAvailable != null && message.hasOwnProperty("defaultNumOperatorsAvailable"))
                        object.defaultNumOperatorsAvailable = message.defaultNumOperatorsAvailable;
                    if (message.reviewRunInEnv != null && message.hasOwnProperty("reviewRunInEnv"))
                        object.reviewRunInEnv = message.reviewRunInEnv;
                    if (message.enableAutoStartReview != null && message.hasOwnProperty("enableAutoStartReview"))
                        object.enableAutoStartReview = message.enableAutoStartReview;
                    if (message.pollingIntervalSeconds != null && message.hasOwnProperty("pollingIntervalSeconds"))
                        object.pollingIntervalSeconds = message.pollingIntervalSeconds;
                    if (message.useTaskEligibilityPool != null && message.hasOwnProperty("useTaskEligibilityPool"))
                        object.useTaskEligibilityPool = message.useTaskEligibilityPool;
                    if (message.routingDelayMinutes != null && message.hasOwnProperty("routingDelayMinutes"))
                        object.routingDelayMinutes = message.routingDelayMinutes;
                    return object;
                };
    
                /**
                 * Converts this GlobalUnifiedReviewConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GlobalUnifiedReviewConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GlobalUnifiedReviewConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GlobalUnifiedReviewConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GlobalUnifiedReviewConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GlobalUnifiedReviewConfig";
                };
    
                return GlobalUnifiedReviewConfig;
            })();
    
            be.DismissNotification = (function() {
    
                /**
                 * Properties of a DismissNotification.
                 * @memberof infinitusai.be
                 * @interface IDismissNotification
                 * @property {string|null} [notificationUuid] DismissNotification notificationUuid
                 * @property {string|null} [userEmail] DismissNotification userEmail
                 * @property {string|null} [actingUserEmail] DismissNotification actingUserEmail
                 * @property {string|null} [followUpMessage] DismissNotification followUpMessage
                 */
    
                /**
                 * Constructs a new DismissNotification.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DismissNotification.
                 * @implements IDismissNotification
                 * @constructor
                 * @param {infinitusai.be.IDismissNotification=} [properties] Properties to set
                 */
                function DismissNotification(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DismissNotification notificationUuid.
                 * @member {string} notificationUuid
                 * @memberof infinitusai.be.DismissNotification
                 * @instance
                 */
                DismissNotification.prototype.notificationUuid = "";
    
                /**
                 * DismissNotification userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.DismissNotification
                 * @instance
                 */
                DismissNotification.prototype.userEmail = "";
    
                /**
                 * DismissNotification actingUserEmail.
                 * @member {string} actingUserEmail
                 * @memberof infinitusai.be.DismissNotification
                 * @instance
                 */
                DismissNotification.prototype.actingUserEmail = "";
    
                /**
                 * DismissNotification followUpMessage.
                 * @member {string} followUpMessage
                 * @memberof infinitusai.be.DismissNotification
                 * @instance
                 */
                DismissNotification.prototype.followUpMessage = "";
    
                /**
                 * Creates a new DismissNotification instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {infinitusai.be.IDismissNotification=} [properties] Properties to set
                 * @returns {infinitusai.be.DismissNotification} DismissNotification instance
                 */
                DismissNotification.create = function create(properties) {
                    return new DismissNotification(properties);
                };
    
                /**
                 * Encodes the specified DismissNotification message. Does not implicitly {@link infinitusai.be.DismissNotification.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {infinitusai.be.IDismissNotification} message DismissNotification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DismissNotification.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.notificationUuid != null && Object.hasOwnProperty.call(message, "notificationUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.notificationUuid);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userEmail);
                    if (message.followUpMessage != null && Object.hasOwnProperty.call(message, "followUpMessage"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.followUpMessage);
                    if (message.actingUserEmail != null && Object.hasOwnProperty.call(message, "actingUserEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.actingUserEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified DismissNotification message, length delimited. Does not implicitly {@link infinitusai.be.DismissNotification.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {infinitusai.be.IDismissNotification} message DismissNotification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DismissNotification.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DismissNotification message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DismissNotification} DismissNotification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DismissNotification.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DismissNotification();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.notificationUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 4: {
                                message.actingUserEmail = reader.string();
                                break;
                            }
                        case 3: {
                                message.followUpMessage = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DismissNotification message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DismissNotification} DismissNotification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DismissNotification.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DismissNotification message.
                 * @function verify
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DismissNotification.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.notificationUuid != null && message.hasOwnProperty("notificationUuid"))
                        if (!$util.isString(message.notificationUuid))
                            return "notificationUuid: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.actingUserEmail != null && message.hasOwnProperty("actingUserEmail"))
                        if (!$util.isString(message.actingUserEmail))
                            return "actingUserEmail: string expected";
                    if (message.followUpMessage != null && message.hasOwnProperty("followUpMessage"))
                        if (!$util.isString(message.followUpMessage))
                            return "followUpMessage: string expected";
                    return null;
                };
    
                /**
                 * Creates a DismissNotification message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DismissNotification} DismissNotification
                 */
                DismissNotification.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DismissNotification)
                        return object;
                    var message = new $root.infinitusai.be.DismissNotification();
                    if (object.notificationUuid != null)
                        message.notificationUuid = String(object.notificationUuid);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    if (object.actingUserEmail != null)
                        message.actingUserEmail = String(object.actingUserEmail);
                    if (object.followUpMessage != null)
                        message.followUpMessage = String(object.followUpMessage);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DismissNotification message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {infinitusai.be.DismissNotification} message DismissNotification
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DismissNotification.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.notificationUuid = "";
                        object.userEmail = "";
                        object.followUpMessage = "";
                        object.actingUserEmail = "";
                    }
                    if (message.notificationUuid != null && message.hasOwnProperty("notificationUuid"))
                        object.notificationUuid = message.notificationUuid;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.followUpMessage != null && message.hasOwnProperty("followUpMessage"))
                        object.followUpMessage = message.followUpMessage;
                    if (message.actingUserEmail != null && message.hasOwnProperty("actingUserEmail"))
                        object.actingUserEmail = message.actingUserEmail;
                    return object;
                };
    
                /**
                 * Converts this DismissNotification to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DismissNotification
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DismissNotification.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DismissNotification
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DismissNotification
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DismissNotification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DismissNotification";
                };
    
                return DismissNotification;
            })();
    
            be.GenericNotification = (function() {
    
                /**
                 * Properties of a GenericNotification.
                 * @memberof infinitusai.be
                 * @interface IGenericNotification
                 * @property {string|null} [title] GenericNotification title
                 * @property {string|null} [description] GenericNotification description
                 * @property {infinitusai.be.GenericNotification.ICallToAction|null} [action] GenericNotification action
                 */
    
                /**
                 * Constructs a new GenericNotification.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenericNotification.
                 * @implements IGenericNotification
                 * @constructor
                 * @param {infinitusai.be.IGenericNotification=} [properties] Properties to set
                 */
                function GenericNotification(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenericNotification title.
                 * @member {string} title
                 * @memberof infinitusai.be.GenericNotification
                 * @instance
                 */
                GenericNotification.prototype.title = "";
    
                /**
                 * GenericNotification description.
                 * @member {string} description
                 * @memberof infinitusai.be.GenericNotification
                 * @instance
                 */
                GenericNotification.prototype.description = "";
    
                /**
                 * GenericNotification action.
                 * @member {infinitusai.be.GenericNotification.ICallToAction|null|undefined} action
                 * @memberof infinitusai.be.GenericNotification
                 * @instance
                 */
                GenericNotification.prototype.action = null;
    
                /**
                 * Creates a new GenericNotification instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {infinitusai.be.IGenericNotification=} [properties] Properties to set
                 * @returns {infinitusai.be.GenericNotification} GenericNotification instance
                 */
                GenericNotification.create = function create(properties) {
                    return new GenericNotification(properties);
                };
    
                /**
                 * Encodes the specified GenericNotification message. Does not implicitly {@link infinitusai.be.GenericNotification.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {infinitusai.be.IGenericNotification} message GenericNotification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenericNotification.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                    if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                        $root.infinitusai.be.GenericNotification.CallToAction.encode(message.action, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GenericNotification message, length delimited. Does not implicitly {@link infinitusai.be.GenericNotification.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {infinitusai.be.IGenericNotification} message GenericNotification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenericNotification.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenericNotification message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenericNotification} GenericNotification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenericNotification.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenericNotification();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.title = reader.string();
                                break;
                            }
                        case 2: {
                                message.description = reader.string();
                                break;
                            }
                        case 3: {
                                message.action = $root.infinitusai.be.GenericNotification.CallToAction.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenericNotification message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenericNotification} GenericNotification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenericNotification.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenericNotification message.
                 * @function verify
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenericNotification.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.action != null && message.hasOwnProperty("action")) {
                        var error = $root.infinitusai.be.GenericNotification.CallToAction.verify(message.action);
                        if (error)
                            return "action." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GenericNotification message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenericNotification} GenericNotification
                 */
                GenericNotification.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenericNotification)
                        return object;
                    var message = new $root.infinitusai.be.GenericNotification();
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.action != null) {
                        if (typeof object.action !== "object")
                            throw TypeError(".infinitusai.be.GenericNotification.action: object expected");
                        message.action = $root.infinitusai.be.GenericNotification.CallToAction.fromObject(object.action);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenericNotification message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {infinitusai.be.GenericNotification} message GenericNotification
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenericNotification.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.title = "";
                        object.description = "";
                        object.action = null;
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.action != null && message.hasOwnProperty("action"))
                        object.action = $root.infinitusai.be.GenericNotification.CallToAction.toObject(message.action, options);
                    return object;
                };
    
                /**
                 * Converts this GenericNotification to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenericNotification
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenericNotification.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenericNotification
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenericNotification
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenericNotification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenericNotification";
                };
    
                GenericNotification.CallToAction = (function() {
    
                    /**
                     * Properties of a CallToAction.
                     * @memberof infinitusai.be.GenericNotification
                     * @interface ICallToAction
                     * @property {string|null} [label] CallToAction label
                     * @property {string|null} [href] CallToAction href
                     */
    
                    /**
                     * Constructs a new CallToAction.
                     * @memberof infinitusai.be.GenericNotification
                     * @classdesc Represents a CallToAction.
                     * @implements ICallToAction
                     * @constructor
                     * @param {infinitusai.be.GenericNotification.ICallToAction=} [properties] Properties to set
                     */
                    function CallToAction(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CallToAction label.
                     * @member {string} label
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @instance
                     */
                    CallToAction.prototype.label = "";
    
                    /**
                     * CallToAction href.
                     * @member {string} href
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @instance
                     */
                    CallToAction.prototype.href = "";
    
                    /**
                     * Creates a new CallToAction instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {infinitusai.be.GenericNotification.ICallToAction=} [properties] Properties to set
                     * @returns {infinitusai.be.GenericNotification.CallToAction} CallToAction instance
                     */
                    CallToAction.create = function create(properties) {
                        return new CallToAction(properties);
                    };
    
                    /**
                     * Encodes the specified CallToAction message. Does not implicitly {@link infinitusai.be.GenericNotification.CallToAction.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {infinitusai.be.GenericNotification.ICallToAction} message CallToAction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CallToAction.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                        if (message.href != null && Object.hasOwnProperty.call(message, "href"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.href);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CallToAction message, length delimited. Does not implicitly {@link infinitusai.be.GenericNotification.CallToAction.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {infinitusai.be.GenericNotification.ICallToAction} message CallToAction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CallToAction.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CallToAction message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.GenericNotification.CallToAction} CallToAction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CallToAction.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenericNotification.CallToAction();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.label = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.href = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CallToAction message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.GenericNotification.CallToAction} CallToAction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CallToAction.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CallToAction message.
                     * @function verify
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CallToAction.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.label != null && message.hasOwnProperty("label"))
                            if (!$util.isString(message.label))
                                return "label: string expected";
                        if (message.href != null && message.hasOwnProperty("href"))
                            if (!$util.isString(message.href))
                                return "href: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a CallToAction message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.GenericNotification.CallToAction} CallToAction
                     */
                    CallToAction.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.GenericNotification.CallToAction)
                            return object;
                        var message = new $root.infinitusai.be.GenericNotification.CallToAction();
                        if (object.label != null)
                            message.label = String(object.label);
                        if (object.href != null)
                            message.href = String(object.href);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CallToAction message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {infinitusai.be.GenericNotification.CallToAction} message CallToAction
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CallToAction.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.label = "";
                            object.href = "";
                        }
                        if (message.label != null && message.hasOwnProperty("label"))
                            object.label = message.label;
                        if (message.href != null && message.hasOwnProperty("href"))
                            object.href = message.href;
                        return object;
                    };
    
                    /**
                     * Converts this CallToAction to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CallToAction.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for CallToAction
                     * @function getTypeUrl
                     * @memberof infinitusai.be.GenericNotification.CallToAction
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CallToAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.GenericNotification.CallToAction";
                    };
    
                    return CallToAction;
                })();
    
                return GenericNotification;
            })();
    
            be.BegForOperatorNotification = (function() {
    
                /**
                 * Properties of a BegForOperatorNotification.
                 * @memberof infinitusai.be
                 * @interface IBegForOperatorNotification
                 * @property {string|null} [timePassed] BegForOperatorNotification timePassed
                 * @property {string|null} [orgDisplayName] BegForOperatorNotification orgDisplayName
                 * @property {string|null} [orgName] BegForOperatorNotification orgName
                 * @property {string|null} [taskUuid] BegForOperatorNotification taskUuid
                 * @property {string|null} [callUuid] BegForOperatorNotification callUuid
                 * @property {string|null} [payerName] BegForOperatorNotification payerName
                 * @property {string|null} [phoneNumber] BegForOperatorNotification phoneNumber
                 * @property {number|Long|null} [dueDateMillis] BegForOperatorNotification dueDateMillis
                 * @property {string|null} [elapsedCallTime] BegForOperatorNotification elapsedCallTime
                 * @property {string|null} [previousCalls] BegForOperatorNotification previousCalls
                 */
    
                /**
                 * Constructs a new BegForOperatorNotification.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BegForOperatorNotification.
                 * @implements IBegForOperatorNotification
                 * @constructor
                 * @param {infinitusai.be.IBegForOperatorNotification=} [properties] Properties to set
                 */
                function BegForOperatorNotification(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BegForOperatorNotification timePassed.
                 * @member {string} timePassed
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.timePassed = "";
    
                /**
                 * BegForOperatorNotification orgDisplayName.
                 * @member {string} orgDisplayName
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.orgDisplayName = "";
    
                /**
                 * BegForOperatorNotification orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.orgName = "";
    
                /**
                 * BegForOperatorNotification taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.taskUuid = "";
    
                /**
                 * BegForOperatorNotification callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.callUuid = "";
    
                /**
                 * BegForOperatorNotification payerName.
                 * @member {string} payerName
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.payerName = "";
    
                /**
                 * BegForOperatorNotification phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.phoneNumber = "";
    
                /**
                 * BegForOperatorNotification dueDateMillis.
                 * @member {number|Long} dueDateMillis
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.dueDateMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * BegForOperatorNotification elapsedCallTime.
                 * @member {string} elapsedCallTime
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.elapsedCallTime = "";
    
                /**
                 * BegForOperatorNotification previousCalls.
                 * @member {string} previousCalls
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 */
                BegForOperatorNotification.prototype.previousCalls = "";
    
                /**
                 * Creates a new BegForOperatorNotification instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {infinitusai.be.IBegForOperatorNotification=} [properties] Properties to set
                 * @returns {infinitusai.be.BegForOperatorNotification} BegForOperatorNotification instance
                 */
                BegForOperatorNotification.create = function create(properties) {
                    return new BegForOperatorNotification(properties);
                };
    
                /**
                 * Encodes the specified BegForOperatorNotification message. Does not implicitly {@link infinitusai.be.BegForOperatorNotification.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {infinitusai.be.IBegForOperatorNotification} message BegForOperatorNotification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BegForOperatorNotification.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.timePassed != null && Object.hasOwnProperty.call(message, "timePassed"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.timePassed);
                    if (message.orgDisplayName != null && Object.hasOwnProperty.call(message, "orgDisplayName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgDisplayName);
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgName);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.callUuid);
                    if (message.payerName != null && Object.hasOwnProperty.call(message, "payerName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.payerName);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.phoneNumber);
                    if (message.dueDateMillis != null && Object.hasOwnProperty.call(message, "dueDateMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.dueDateMillis);
                    if (message.elapsedCallTime != null && Object.hasOwnProperty.call(message, "elapsedCallTime"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.elapsedCallTime);
                    if (message.previousCalls != null && Object.hasOwnProperty.call(message, "previousCalls"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.previousCalls);
                    return writer;
                };
    
                /**
                 * Encodes the specified BegForOperatorNotification message, length delimited. Does not implicitly {@link infinitusai.be.BegForOperatorNotification.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {infinitusai.be.IBegForOperatorNotification} message BegForOperatorNotification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BegForOperatorNotification.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BegForOperatorNotification message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BegForOperatorNotification} BegForOperatorNotification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BegForOperatorNotification.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BegForOperatorNotification();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.timePassed = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgDisplayName = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgName = reader.string();
                                break;
                            }
                        case 4: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 6: {
                                message.payerName = reader.string();
                                break;
                            }
                        case 7: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 8: {
                                message.dueDateMillis = reader.int64();
                                break;
                            }
                        case 9: {
                                message.elapsedCallTime = reader.string();
                                break;
                            }
                        case 10: {
                                message.previousCalls = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BegForOperatorNotification message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BegForOperatorNotification} BegForOperatorNotification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BegForOperatorNotification.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BegForOperatorNotification message.
                 * @function verify
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BegForOperatorNotification.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.timePassed != null && message.hasOwnProperty("timePassed"))
                        if (!$util.isString(message.timePassed))
                            return "timePassed: string expected";
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        if (!$util.isString(message.orgDisplayName))
                            return "orgDisplayName: string expected";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.payerName != null && message.hasOwnProperty("payerName"))
                        if (!$util.isString(message.payerName))
                            return "payerName: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.dueDateMillis != null && message.hasOwnProperty("dueDateMillis"))
                        if (!$util.isInteger(message.dueDateMillis) && !(message.dueDateMillis && $util.isInteger(message.dueDateMillis.low) && $util.isInteger(message.dueDateMillis.high)))
                            return "dueDateMillis: integer|Long expected";
                    if (message.elapsedCallTime != null && message.hasOwnProperty("elapsedCallTime"))
                        if (!$util.isString(message.elapsedCallTime))
                            return "elapsedCallTime: string expected";
                    if (message.previousCalls != null && message.hasOwnProperty("previousCalls"))
                        if (!$util.isString(message.previousCalls))
                            return "previousCalls: string expected";
                    return null;
                };
    
                /**
                 * Creates a BegForOperatorNotification message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BegForOperatorNotification} BegForOperatorNotification
                 */
                BegForOperatorNotification.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BegForOperatorNotification)
                        return object;
                    var message = new $root.infinitusai.be.BegForOperatorNotification();
                    if (object.timePassed != null)
                        message.timePassed = String(object.timePassed);
                    if (object.orgDisplayName != null)
                        message.orgDisplayName = String(object.orgDisplayName);
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.payerName != null)
                        message.payerName = String(object.payerName);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.dueDateMillis != null)
                        if ($util.Long)
                            (message.dueDateMillis = $util.Long.fromValue(object.dueDateMillis)).unsigned = false;
                        else if (typeof object.dueDateMillis === "string")
                            message.dueDateMillis = parseInt(object.dueDateMillis, 10);
                        else if (typeof object.dueDateMillis === "number")
                            message.dueDateMillis = object.dueDateMillis;
                        else if (typeof object.dueDateMillis === "object")
                            message.dueDateMillis = new $util.LongBits(object.dueDateMillis.low >>> 0, object.dueDateMillis.high >>> 0).toNumber();
                    if (object.elapsedCallTime != null)
                        message.elapsedCallTime = String(object.elapsedCallTime);
                    if (object.previousCalls != null)
                        message.previousCalls = String(object.previousCalls);
                    return message;
                };
    
                /**
                 * Creates a plain object from a BegForOperatorNotification message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {infinitusai.be.BegForOperatorNotification} message BegForOperatorNotification
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BegForOperatorNotification.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.timePassed = "";
                        object.orgDisplayName = "";
                        object.orgName = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.payerName = "";
                        object.phoneNumber = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.dueDateMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.dueDateMillis = options.longs === String ? "0" : 0;
                        object.elapsedCallTime = "";
                        object.previousCalls = "";
                    }
                    if (message.timePassed != null && message.hasOwnProperty("timePassed"))
                        object.timePassed = message.timePassed;
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        object.orgDisplayName = message.orgDisplayName;
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.payerName != null && message.hasOwnProperty("payerName"))
                        object.payerName = message.payerName;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.dueDateMillis != null && message.hasOwnProperty("dueDateMillis"))
                        if (typeof message.dueDateMillis === "number")
                            object.dueDateMillis = options.longs === String ? String(message.dueDateMillis) : message.dueDateMillis;
                        else
                            object.dueDateMillis = options.longs === String ? $util.Long.prototype.toString.call(message.dueDateMillis) : options.longs === Number ? new $util.LongBits(message.dueDateMillis.low >>> 0, message.dueDateMillis.high >>> 0).toNumber() : message.dueDateMillis;
                    if (message.elapsedCallTime != null && message.hasOwnProperty("elapsedCallTime"))
                        object.elapsedCallTime = message.elapsedCallTime;
                    if (message.previousCalls != null && message.hasOwnProperty("previousCalls"))
                        object.previousCalls = message.previousCalls;
                    return object;
                };
    
                /**
                 * Converts this BegForOperatorNotification to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BegForOperatorNotification.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BegForOperatorNotification
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BegForOperatorNotification
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BegForOperatorNotification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BegForOperatorNotification";
                };
    
                return BegForOperatorNotification;
            })();
    
            be.RequeueNotification = (function() {
    
                /**
                 * Properties of a RequeueNotification.
                 * @memberof infinitusai.be
                 * @interface IRequeueNotification
                 * @property {string|null} [orgDisplayName] RequeueNotification orgDisplayName
                 * @property {string|null} [orgName] RequeueNotification orgName
                 * @property {string|null} [taskUuid] RequeueNotification taskUuid
                 * @property {string|null} [callUuid] RequeueNotification callUuid
                 * @property {string|null} [operatorEmail] RequeueNotification operatorEmail
                 * @property {string|null} [reviewerEmail] RequeueNotification reviewerEmail
                 * @property {infinitusai.be.IRequeueReason|null} [requeueReason] RequeueNotification requeueReason
                 * @property {string|null} [requeueNotes] RequeueNotification requeueNotes
                 */
    
                /**
                 * Constructs a new RequeueNotification.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RequeueNotification.
                 * @implements IRequeueNotification
                 * @constructor
                 * @param {infinitusai.be.IRequeueNotification=} [properties] Properties to set
                 */
                function RequeueNotification(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RequeueNotification orgDisplayName.
                 * @member {string} orgDisplayName
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 */
                RequeueNotification.prototype.orgDisplayName = "";
    
                /**
                 * RequeueNotification orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 */
                RequeueNotification.prototype.orgName = "";
    
                /**
                 * RequeueNotification taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 */
                RequeueNotification.prototype.taskUuid = "";
    
                /**
                 * RequeueNotification callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 */
                RequeueNotification.prototype.callUuid = "";
    
                /**
                 * RequeueNotification operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 */
                RequeueNotification.prototype.operatorEmail = "";
    
                /**
                 * RequeueNotification reviewerEmail.
                 * @member {string} reviewerEmail
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 */
                RequeueNotification.prototype.reviewerEmail = "";
    
                /**
                 * RequeueNotification requeueReason.
                 * @member {infinitusai.be.IRequeueReason|null|undefined} requeueReason
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 */
                RequeueNotification.prototype.requeueReason = null;
    
                /**
                 * RequeueNotification requeueNotes.
                 * @member {string} requeueNotes
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 */
                RequeueNotification.prototype.requeueNotes = "";
    
                /**
                 * Creates a new RequeueNotification instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {infinitusai.be.IRequeueNotification=} [properties] Properties to set
                 * @returns {infinitusai.be.RequeueNotification} RequeueNotification instance
                 */
                RequeueNotification.create = function create(properties) {
                    return new RequeueNotification(properties);
                };
    
                /**
                 * Encodes the specified RequeueNotification message. Does not implicitly {@link infinitusai.be.RequeueNotification.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {infinitusai.be.IRequeueNotification} message RequeueNotification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeueNotification.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgDisplayName != null && Object.hasOwnProperty.call(message, "orgDisplayName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgDisplayName);
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgName);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.callUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.operatorEmail);
                    if (message.reviewerEmail != null && Object.hasOwnProperty.call(message, "reviewerEmail"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.reviewerEmail);
                    if (message.requeueReason != null && Object.hasOwnProperty.call(message, "requeueReason"))
                        $root.infinitusai.be.RequeueReason.encode(message.requeueReason, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.requeueNotes != null && Object.hasOwnProperty.call(message, "requeueNotes"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.requeueNotes);
                    return writer;
                };
    
                /**
                 * Encodes the specified RequeueNotification message, length delimited. Does not implicitly {@link infinitusai.be.RequeueNotification.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {infinitusai.be.IRequeueNotification} message RequeueNotification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequeueNotification.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RequeueNotification message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RequeueNotification} RequeueNotification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeueNotification.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RequeueNotification();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgDisplayName = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgName = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        case 6: {
                                message.reviewerEmail = reader.string();
                                break;
                            }
                        case 7: {
                                message.requeueReason = $root.infinitusai.be.RequeueReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.requeueNotes = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RequeueNotification message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RequeueNotification} RequeueNotification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequeueNotification.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RequeueNotification message.
                 * @function verify
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequeueNotification.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        if (!$util.isString(message.orgDisplayName))
                            return "orgDisplayName: string expected";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    if (message.reviewerEmail != null && message.hasOwnProperty("reviewerEmail"))
                        if (!$util.isString(message.reviewerEmail))
                            return "reviewerEmail: string expected";
                    if (message.requeueReason != null && message.hasOwnProperty("requeueReason")) {
                        var error = $root.infinitusai.be.RequeueReason.verify(message.requeueReason);
                        if (error)
                            return "requeueReason." + error;
                    }
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        if (!$util.isString(message.requeueNotes))
                            return "requeueNotes: string expected";
                    return null;
                };
    
                /**
                 * Creates a RequeueNotification message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RequeueNotification} RequeueNotification
                 */
                RequeueNotification.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RequeueNotification)
                        return object;
                    var message = new $root.infinitusai.be.RequeueNotification();
                    if (object.orgDisplayName != null)
                        message.orgDisplayName = String(object.orgDisplayName);
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    if (object.reviewerEmail != null)
                        message.reviewerEmail = String(object.reviewerEmail);
                    if (object.requeueReason != null) {
                        if (typeof object.requeueReason !== "object")
                            throw TypeError(".infinitusai.be.RequeueNotification.requeueReason: object expected");
                        message.requeueReason = $root.infinitusai.be.RequeueReason.fromObject(object.requeueReason);
                    }
                    if (object.requeueNotes != null)
                        message.requeueNotes = String(object.requeueNotes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RequeueNotification message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {infinitusai.be.RequeueNotification} message RequeueNotification
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequeueNotification.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgDisplayName = "";
                        object.orgName = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.operatorEmail = "";
                        object.reviewerEmail = "";
                        object.requeueReason = null;
                        object.requeueNotes = "";
                    }
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        object.orgDisplayName = message.orgDisplayName;
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    if (message.reviewerEmail != null && message.hasOwnProperty("reviewerEmail"))
                        object.reviewerEmail = message.reviewerEmail;
                    if (message.requeueReason != null && message.hasOwnProperty("requeueReason"))
                        object.requeueReason = $root.infinitusai.be.RequeueReason.toObject(message.requeueReason, options);
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        object.requeueNotes = message.requeueNotes;
                    return object;
                };
    
                /**
                 * Converts this RequeueNotification to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RequeueNotification
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequeueNotification.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RequeueNotification
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RequeueNotification
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequeueNotification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RequeueNotification";
                };
    
                return RequeueNotification;
            })();
    
            /**
             * NotificationType enum.
             * @name infinitusai.be.NotificationType
             * @enum {number}
             * @property {number} NOTIFICATION_TYPE_UNKNOWN=0 NOTIFICATION_TYPE_UNKNOWN value
             * @property {number} NOTIFICATION_TYPE_BEG_FOR_OPERATOR=1 NOTIFICATION_TYPE_BEG_FOR_OPERATOR value
             * @property {number} NOTIFICATION_TYPE_REQUEUE_TASK=2 NOTIFICATION_TYPE_REQUEUE_TASK value
             * @property {number} NOTIFICATION_TYPE_GENERIC=3 NOTIFICATION_TYPE_GENERIC value
             */
            be.NotificationType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NOTIFICATION_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "NOTIFICATION_TYPE_BEG_FOR_OPERATOR"] = 1;
                values[valuesById[2] = "NOTIFICATION_TYPE_REQUEUE_TASK"] = 2;
                values[valuesById[3] = "NOTIFICATION_TYPE_GENERIC"] = 3;
                return values;
            })();
    
            /**
             * NotificationStatus enum.
             * @name infinitusai.be.NotificationStatus
             * @enum {number}
             * @property {number} NOTIFICATION_STATUS_UNKNOWN=0 NOTIFICATION_STATUS_UNKNOWN value
             * @property {number} NOTIFICATION_STATUS_PENDING_USER_ACKNOWLEDGEMENT=1 NOTIFICATION_STATUS_PENDING_USER_ACKNOWLEDGEMENT value
             * @property {number} NOTIFICATION_STATUS_DISMISSED_BY_USER=2 NOTIFICATION_STATUS_DISMISSED_BY_USER value
             * @property {number} NOTIFICATION_STATUS_DISMISSED_BY_BACKEND=3 NOTIFICATION_STATUS_DISMISSED_BY_BACKEND value
             * @property {number} NOTIFICATION_STATUS_USER_CLICKED_ACTION=4 NOTIFICATION_STATUS_USER_CLICKED_ACTION value
             */
            be.NotificationStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NOTIFICATION_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "NOTIFICATION_STATUS_PENDING_USER_ACKNOWLEDGEMENT"] = 1;
                values[valuesById[2] = "NOTIFICATION_STATUS_DISMISSED_BY_USER"] = 2;
                values[valuesById[3] = "NOTIFICATION_STATUS_DISMISSED_BY_BACKEND"] = 3;
                values[valuesById[4] = "NOTIFICATION_STATUS_USER_CLICKED_ACTION"] = 4;
                return values;
            })();
    
            /**
             * NotificationAction enum.
             * @name infinitusai.be.NotificationAction
             * @enum {number}
             * @property {number} NOTIFICATION_ACTION_UNKNOWN=0 NOTIFICATION_ACTION_UNKNOWN value
             * @property {number} NOTIFICATION_ACTION_DISMISSED=1 NOTIFICATION_ACTION_DISMISSED value
             * @property {number} NOTIFICATION_ACTION_HIDDEN=2 NOTIFICATION_ACTION_HIDDEN value
             * @property {number} NOTIFICATION_ACTION_CLICKED=3 NOTIFICATION_ACTION_CLICKED value
             */
            be.NotificationAction = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NOTIFICATION_ACTION_UNKNOWN"] = 0;
                values[valuesById[1] = "NOTIFICATION_ACTION_DISMISSED"] = 1;
                values[valuesById[2] = "NOTIFICATION_ACTION_HIDDEN"] = 2;
                values[valuesById[3] = "NOTIFICATION_ACTION_CLICKED"] = 3;
                return values;
            })();
    
            /**
             * NotificationSource enum.
             * @name infinitusai.be.NotificationSource
             * @enum {number}
             * @property {number} NOTIFICATION_SOURCE_UNKNOWN=0 NOTIFICATION_SOURCE_UNKNOWN value
             * @property {number} NOTIFICATION_SOURCE_ADHOC=1 NOTIFICATION_SOURCE_ADHOC value
             * @property {number} NOTIFICATION_SOURCE_OPERATOR_ROUTING=2 NOTIFICATION_SOURCE_OPERATOR_ROUTING value
             * @property {number} NOTIFICATION_SOURCE_GQL_UPLOAD_GREETING=3 NOTIFICATION_SOURCE_GQL_UPLOAD_GREETING value
             * @property {number} NOTIFICATION_SOURCE_GQL_DELETE_GREETING=4 NOTIFICATION_SOURCE_GQL_DELETE_GREETING value
             * @property {number} NOTIFICATION_SOURCE_REST_REQUEUE_TASK=5 NOTIFICATION_SOURCE_REST_REQUEUE_TASK value
             */
            be.NotificationSource = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NOTIFICATION_SOURCE_UNKNOWN"] = 0;
                values[valuesById[1] = "NOTIFICATION_SOURCE_ADHOC"] = 1;
                values[valuesById[2] = "NOTIFICATION_SOURCE_OPERATOR_ROUTING"] = 2;
                values[valuesById[3] = "NOTIFICATION_SOURCE_GQL_UPLOAD_GREETING"] = 3;
                values[valuesById[4] = "NOTIFICATION_SOURCE_GQL_DELETE_GREETING"] = 4;
                values[valuesById[5] = "NOTIFICATION_SOURCE_REST_REQUEUE_TASK"] = 5;
                return values;
            })();
    
            be.Notification = (function() {
    
                /**
                 * Properties of a Notification.
                 * @memberof infinitusai.be
                 * @interface INotification
                 * @property {string|null} [uuid] Notification uuid
                 * @property {number|Long|null} [enqueuedAtMillis] Notification enqueuedAtMillis
                 * @property {string|null} [enqueuedBy] Notification enqueuedBy
                 * @property {infinitusai.be.NotificationSource|null} [enqueueSource] Notification enqueueSource
                 * @property {string|null} [userEmail] Notification userEmail
                 * @property {infinitusai.be.Notification.IPayload|null} [payload] Notification payload
                 * @property {infinitusai.be.NotificationStatus|null} [status] Notification status
                 * @property {string|null} [orgUuid] Notification orgUuid
                 */
    
                /**
                 * Constructs a new Notification.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Notification.
                 * @implements INotification
                 * @constructor
                 * @param {infinitusai.be.INotification=} [properties] Properties to set
                 */
                function Notification(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Notification uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.be.Notification
                 * @instance
                 */
                Notification.prototype.uuid = "";
    
                /**
                 * Notification enqueuedAtMillis.
                 * @member {number|Long} enqueuedAtMillis
                 * @memberof infinitusai.be.Notification
                 * @instance
                 */
                Notification.prototype.enqueuedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Notification enqueuedBy.
                 * @member {string} enqueuedBy
                 * @memberof infinitusai.be.Notification
                 * @instance
                 */
                Notification.prototype.enqueuedBy = "";
    
                /**
                 * Notification enqueueSource.
                 * @member {infinitusai.be.NotificationSource} enqueueSource
                 * @memberof infinitusai.be.Notification
                 * @instance
                 */
                Notification.prototype.enqueueSource = 0;
    
                /**
                 * Notification userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.Notification
                 * @instance
                 */
                Notification.prototype.userEmail = "";
    
                /**
                 * Notification payload.
                 * @member {infinitusai.be.Notification.IPayload|null|undefined} payload
                 * @memberof infinitusai.be.Notification
                 * @instance
                 */
                Notification.prototype.payload = null;
    
                /**
                 * Notification status.
                 * @member {infinitusai.be.NotificationStatus} status
                 * @memberof infinitusai.be.Notification
                 * @instance
                 */
                Notification.prototype.status = 0;
    
                /**
                 * Notification orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.Notification
                 * @instance
                 */
                Notification.prototype.orgUuid = "";
    
                /**
                 * Creates a new Notification instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {infinitusai.be.INotification=} [properties] Properties to set
                 * @returns {infinitusai.be.Notification} Notification instance
                 */
                Notification.create = function create(properties) {
                    return new Notification(properties);
                };
    
                /**
                 * Encodes the specified Notification message. Does not implicitly {@link infinitusai.be.Notification.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {infinitusai.be.INotification} message Notification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Notification.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.enqueuedAtMillis != null && Object.hasOwnProperty.call(message, "enqueuedAtMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.enqueuedAtMillis);
                    if (message.enqueuedBy != null && Object.hasOwnProperty.call(message, "enqueuedBy"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.enqueuedBy);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userEmail);
                    if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                        $root.infinitusai.be.Notification.Payload.encode(message.payload, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.status);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.orgUuid);
                    if (message.enqueueSource != null && Object.hasOwnProperty.call(message, "enqueueSource"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.enqueueSource);
                    return writer;
                };
    
                /**
                 * Encodes the specified Notification message, length delimited. Does not implicitly {@link infinitusai.be.Notification.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {infinitusai.be.INotification} message Notification message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Notification.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Notification message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Notification} Notification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Notification.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Notification();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.enqueuedAtMillis = reader.int64();
                                break;
                            }
                        case 3: {
                                message.enqueuedBy = reader.string();
                                break;
                            }
                        case 8: {
                                message.enqueueSource = reader.int32();
                                break;
                            }
                        case 4: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 5: {
                                message.payload = $root.infinitusai.be.Notification.Payload.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.status = reader.int32();
                                break;
                            }
                        case 7: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Notification message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Notification} Notification
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Notification.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Notification message.
                 * @function verify
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Notification.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.enqueuedAtMillis != null && message.hasOwnProperty("enqueuedAtMillis"))
                        if (!$util.isInteger(message.enqueuedAtMillis) && !(message.enqueuedAtMillis && $util.isInteger(message.enqueuedAtMillis.low) && $util.isInteger(message.enqueuedAtMillis.high)))
                            return "enqueuedAtMillis: integer|Long expected";
                    if (message.enqueuedBy != null && message.hasOwnProperty("enqueuedBy"))
                        if (!$util.isString(message.enqueuedBy))
                            return "enqueuedBy: string expected";
                    if (message.enqueueSource != null && message.hasOwnProperty("enqueueSource"))
                        switch (message.enqueueSource) {
                        default:
                            return "enqueueSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.payload != null && message.hasOwnProperty("payload")) {
                        var error = $root.infinitusai.be.Notification.Payload.verify(message.payload);
                        if (error)
                            return "payload." + error;
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a Notification message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Notification} Notification
                 */
                Notification.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Notification)
                        return object;
                    var message = new $root.infinitusai.be.Notification();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.enqueuedAtMillis != null)
                        if ($util.Long)
                            (message.enqueuedAtMillis = $util.Long.fromValue(object.enqueuedAtMillis)).unsigned = false;
                        else if (typeof object.enqueuedAtMillis === "string")
                            message.enqueuedAtMillis = parseInt(object.enqueuedAtMillis, 10);
                        else if (typeof object.enqueuedAtMillis === "number")
                            message.enqueuedAtMillis = object.enqueuedAtMillis;
                        else if (typeof object.enqueuedAtMillis === "object")
                            message.enqueuedAtMillis = new $util.LongBits(object.enqueuedAtMillis.low >>> 0, object.enqueuedAtMillis.high >>> 0).toNumber();
                    if (object.enqueuedBy != null)
                        message.enqueuedBy = String(object.enqueuedBy);
                    switch (object.enqueueSource) {
                    default:
                        if (typeof object.enqueueSource === "number") {
                            message.enqueueSource = object.enqueueSource;
                            break;
                        }
                        break;
                    case "NOTIFICATION_SOURCE_UNKNOWN":
                    case 0:
                        message.enqueueSource = 0;
                        break;
                    case "NOTIFICATION_SOURCE_ADHOC":
                    case 1:
                        message.enqueueSource = 1;
                        break;
                    case "NOTIFICATION_SOURCE_OPERATOR_ROUTING":
                    case 2:
                        message.enqueueSource = 2;
                        break;
                    case "NOTIFICATION_SOURCE_GQL_UPLOAD_GREETING":
                    case 3:
                        message.enqueueSource = 3;
                        break;
                    case "NOTIFICATION_SOURCE_GQL_DELETE_GREETING":
                    case 4:
                        message.enqueueSource = 4;
                        break;
                    case "NOTIFICATION_SOURCE_REST_REQUEUE_TASK":
                    case 5:
                        message.enqueueSource = 5;
                        break;
                    }
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    if (object.payload != null) {
                        if (typeof object.payload !== "object")
                            throw TypeError(".infinitusai.be.Notification.payload: object expected");
                        message.payload = $root.infinitusai.be.Notification.Payload.fromObject(object.payload);
                    }
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "NOTIFICATION_STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "NOTIFICATION_STATUS_PENDING_USER_ACKNOWLEDGEMENT":
                    case 1:
                        message.status = 1;
                        break;
                    case "NOTIFICATION_STATUS_DISMISSED_BY_USER":
                    case 2:
                        message.status = 2;
                        break;
                    case "NOTIFICATION_STATUS_DISMISSED_BY_BACKEND":
                    case 3:
                        message.status = 3;
                        break;
                    case "NOTIFICATION_STATUS_USER_CLICKED_ACTION":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Notification message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {infinitusai.be.Notification} message Notification
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Notification.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.enqueuedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.enqueuedAtMillis = options.longs === String ? "0" : 0;
                        object.enqueuedBy = "";
                        object.userEmail = "";
                        object.payload = null;
                        object.status = options.enums === String ? "NOTIFICATION_STATUS_UNKNOWN" : 0;
                        object.orgUuid = "";
                        object.enqueueSource = options.enums === String ? "NOTIFICATION_SOURCE_UNKNOWN" : 0;
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.enqueuedAtMillis != null && message.hasOwnProperty("enqueuedAtMillis"))
                        if (typeof message.enqueuedAtMillis === "number")
                            object.enqueuedAtMillis = options.longs === String ? String(message.enqueuedAtMillis) : message.enqueuedAtMillis;
                        else
                            object.enqueuedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.enqueuedAtMillis) : options.longs === Number ? new $util.LongBits(message.enqueuedAtMillis.low >>> 0, message.enqueuedAtMillis.high >>> 0).toNumber() : message.enqueuedAtMillis;
                    if (message.enqueuedBy != null && message.hasOwnProperty("enqueuedBy"))
                        object.enqueuedBy = message.enqueuedBy;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.payload != null && message.hasOwnProperty("payload"))
                        object.payload = $root.infinitusai.be.Notification.Payload.toObject(message.payload, options);
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.NotificationStatus[message.status] === undefined ? message.status : $root.infinitusai.be.NotificationStatus[message.status] : message.status;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.enqueueSource != null && message.hasOwnProperty("enqueueSource"))
                        object.enqueueSource = options.enums === String ? $root.infinitusai.be.NotificationSource[message.enqueueSource] === undefined ? message.enqueueSource : $root.infinitusai.be.NotificationSource[message.enqueueSource] : message.enqueueSource;
                    return object;
                };
    
                /**
                 * Converts this Notification to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Notification
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Notification.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Notification
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Notification
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Notification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Notification";
                };
    
                Notification.Payload = (function() {
    
                    /**
                     * Properties of a Payload.
                     * @memberof infinitusai.be.Notification
                     * @interface IPayload
                     * @property {infinitusai.be.IGenericNotification|null} [genericNotification] Payload genericNotification
                     * @property {infinitusai.be.IRequeueNotification|null} [requeueNotification] Payload requeueNotification
                     * @property {infinitusai.be.IBegForOperatorNotification|null} [begForOperatorNotification] Payload begForOperatorNotification
                     */
    
                    /**
                     * Constructs a new Payload.
                     * @memberof infinitusai.be.Notification
                     * @classdesc Represents a Payload.
                     * @implements IPayload
                     * @constructor
                     * @param {infinitusai.be.Notification.IPayload=} [properties] Properties to set
                     */
                    function Payload(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Payload genericNotification.
                     * @member {infinitusai.be.IGenericNotification|null|undefined} genericNotification
                     * @memberof infinitusai.be.Notification.Payload
                     * @instance
                     */
                    Payload.prototype.genericNotification = null;
    
                    /**
                     * Payload requeueNotification.
                     * @member {infinitusai.be.IRequeueNotification|null|undefined} requeueNotification
                     * @memberof infinitusai.be.Notification.Payload
                     * @instance
                     */
                    Payload.prototype.requeueNotification = null;
    
                    /**
                     * Payload begForOperatorNotification.
                     * @member {infinitusai.be.IBegForOperatorNotification|null|undefined} begForOperatorNotification
                     * @memberof infinitusai.be.Notification.Payload
                     * @instance
                     */
                    Payload.prototype.begForOperatorNotification = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * Payload data.
                     * @member {"genericNotification"|"requeueNotification"|"begForOperatorNotification"|undefined} data
                     * @memberof infinitusai.be.Notification.Payload
                     * @instance
                     */
                    Object.defineProperty(Payload.prototype, "data", {
                        get: $util.oneOfGetter($oneOfFields = ["genericNotification", "requeueNotification", "begForOperatorNotification"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new Payload instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {infinitusai.be.Notification.IPayload=} [properties] Properties to set
                     * @returns {infinitusai.be.Notification.Payload} Payload instance
                     */
                    Payload.create = function create(properties) {
                        return new Payload(properties);
                    };
    
                    /**
                     * Encodes the specified Payload message. Does not implicitly {@link infinitusai.be.Notification.Payload.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {infinitusai.be.Notification.IPayload} message Payload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Payload.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.genericNotification != null && Object.hasOwnProperty.call(message, "genericNotification"))
                            $root.infinitusai.be.GenericNotification.encode(message.genericNotification, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.requeueNotification != null && Object.hasOwnProperty.call(message, "requeueNotification"))
                            $root.infinitusai.be.RequeueNotification.encode(message.requeueNotification, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.begForOperatorNotification != null && Object.hasOwnProperty.call(message, "begForOperatorNotification"))
                            $root.infinitusai.be.BegForOperatorNotification.encode(message.begForOperatorNotification, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Payload message, length delimited. Does not implicitly {@link infinitusai.be.Notification.Payload.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {infinitusai.be.Notification.IPayload} message Payload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Payload.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Payload message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.Notification.Payload} Payload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Payload.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Notification.Payload();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.genericNotification = $root.infinitusai.be.GenericNotification.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    message.requeueNotification = $root.infinitusai.be.RequeueNotification.decode(reader, reader.uint32());
                                    break;
                                }
                            case 3: {
                                    message.begForOperatorNotification = $root.infinitusai.be.BegForOperatorNotification.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Payload message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.Notification.Payload} Payload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Payload.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Payload message.
                     * @function verify
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Payload.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.genericNotification != null && message.hasOwnProperty("genericNotification")) {
                            properties.data = 1;
                            {
                                var error = $root.infinitusai.be.GenericNotification.verify(message.genericNotification);
                                if (error)
                                    return "genericNotification." + error;
                            }
                        }
                        if (message.requeueNotification != null && message.hasOwnProperty("requeueNotification")) {
                            if (properties.data === 1)
                                return "data: multiple values";
                            properties.data = 1;
                            {
                                var error = $root.infinitusai.be.RequeueNotification.verify(message.requeueNotification);
                                if (error)
                                    return "requeueNotification." + error;
                            }
                        }
                        if (message.begForOperatorNotification != null && message.hasOwnProperty("begForOperatorNotification")) {
                            if (properties.data === 1)
                                return "data: multiple values";
                            properties.data = 1;
                            {
                                var error = $root.infinitusai.be.BegForOperatorNotification.verify(message.begForOperatorNotification);
                                if (error)
                                    return "begForOperatorNotification." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Payload message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.Notification.Payload} Payload
                     */
                    Payload.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.Notification.Payload)
                            return object;
                        var message = new $root.infinitusai.be.Notification.Payload();
                        if (object.genericNotification != null) {
                            if (typeof object.genericNotification !== "object")
                                throw TypeError(".infinitusai.be.Notification.Payload.genericNotification: object expected");
                            message.genericNotification = $root.infinitusai.be.GenericNotification.fromObject(object.genericNotification);
                        }
                        if (object.requeueNotification != null) {
                            if (typeof object.requeueNotification !== "object")
                                throw TypeError(".infinitusai.be.Notification.Payload.requeueNotification: object expected");
                            message.requeueNotification = $root.infinitusai.be.RequeueNotification.fromObject(object.requeueNotification);
                        }
                        if (object.begForOperatorNotification != null) {
                            if (typeof object.begForOperatorNotification !== "object")
                                throw TypeError(".infinitusai.be.Notification.Payload.begForOperatorNotification: object expected");
                            message.begForOperatorNotification = $root.infinitusai.be.BegForOperatorNotification.fromObject(object.begForOperatorNotification);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Payload message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {infinitusai.be.Notification.Payload} message Payload
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Payload.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.genericNotification != null && message.hasOwnProperty("genericNotification")) {
                            object.genericNotification = $root.infinitusai.be.GenericNotification.toObject(message.genericNotification, options);
                            if (options.oneofs)
                                object.data = "genericNotification";
                        }
                        if (message.requeueNotification != null && message.hasOwnProperty("requeueNotification")) {
                            object.requeueNotification = $root.infinitusai.be.RequeueNotification.toObject(message.requeueNotification, options);
                            if (options.oneofs)
                                object.data = "requeueNotification";
                        }
                        if (message.begForOperatorNotification != null && message.hasOwnProperty("begForOperatorNotification")) {
                            object.begForOperatorNotification = $root.infinitusai.be.BegForOperatorNotification.toObject(message.begForOperatorNotification, options);
                            if (options.oneofs)
                                object.data = "begForOperatorNotification";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Payload to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.Notification.Payload
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Payload.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Payload
                     * @function getTypeUrl
                     * @memberof infinitusai.be.Notification.Payload
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Payload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.Notification.Payload";
                    };
    
                    return Payload;
                })();
    
                return Notification;
            })();
    
            be.NotificationReportClickRequest = (function() {
    
                /**
                 * Properties of a NotificationReportClickRequest.
                 * @memberof infinitusai.be
                 * @interface INotificationReportClickRequest
                 * @property {string|null} [notificationUuid] NotificationReportClickRequest notificationUuid
                 * @property {string|null} [userEmail] NotificationReportClickRequest userEmail
                 * @property {infinitusai.be.NotificationType|null} [notificationType] NotificationReportClickRequest notificationType
                 * @property {infinitusai.be.NotificationAction|null} [action] NotificationReportClickRequest action
                 */
    
                /**
                 * Constructs a new NotificationReportClickRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NotificationReportClickRequest.
                 * @implements INotificationReportClickRequest
                 * @constructor
                 * @param {infinitusai.be.INotificationReportClickRequest=} [properties] Properties to set
                 */
                function NotificationReportClickRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NotificationReportClickRequest notificationUuid.
                 * @member {string} notificationUuid
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @instance
                 */
                NotificationReportClickRequest.prototype.notificationUuid = "";
    
                /**
                 * NotificationReportClickRequest userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @instance
                 */
                NotificationReportClickRequest.prototype.userEmail = "";
    
                /**
                 * NotificationReportClickRequest notificationType.
                 * @member {infinitusai.be.NotificationType} notificationType
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @instance
                 */
                NotificationReportClickRequest.prototype.notificationType = 0;
    
                /**
                 * NotificationReportClickRequest action.
                 * @member {infinitusai.be.NotificationAction} action
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @instance
                 */
                NotificationReportClickRequest.prototype.action = 0;
    
                /**
                 * Creates a new NotificationReportClickRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {infinitusai.be.INotificationReportClickRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.NotificationReportClickRequest} NotificationReportClickRequest instance
                 */
                NotificationReportClickRequest.create = function create(properties) {
                    return new NotificationReportClickRequest(properties);
                };
    
                /**
                 * Encodes the specified NotificationReportClickRequest message. Does not implicitly {@link infinitusai.be.NotificationReportClickRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {infinitusai.be.INotificationReportClickRequest} message NotificationReportClickRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NotificationReportClickRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.notificationUuid != null && Object.hasOwnProperty.call(message, "notificationUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.notificationUuid);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userEmail);
                    if (message.notificationType != null && Object.hasOwnProperty.call(message, "notificationType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.notificationType);
                    if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.action);
                    return writer;
                };
    
                /**
                 * Encodes the specified NotificationReportClickRequest message, length delimited. Does not implicitly {@link infinitusai.be.NotificationReportClickRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {infinitusai.be.INotificationReportClickRequest} message NotificationReportClickRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NotificationReportClickRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NotificationReportClickRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NotificationReportClickRequest} NotificationReportClickRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NotificationReportClickRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NotificationReportClickRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.notificationUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 3: {
                                message.notificationType = reader.int32();
                                break;
                            }
                        case 4: {
                                message.action = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NotificationReportClickRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NotificationReportClickRequest} NotificationReportClickRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NotificationReportClickRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NotificationReportClickRequest message.
                 * @function verify
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NotificationReportClickRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.notificationUuid != null && message.hasOwnProperty("notificationUuid"))
                        if (!$util.isString(message.notificationUuid))
                            return "notificationUuid: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                        switch (message.notificationType) {
                        default:
                            return "notificationType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.action != null && message.hasOwnProperty("action"))
                        switch (message.action) {
                        default:
                            return "action: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a NotificationReportClickRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NotificationReportClickRequest} NotificationReportClickRequest
                 */
                NotificationReportClickRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NotificationReportClickRequest)
                        return object;
                    var message = new $root.infinitusai.be.NotificationReportClickRequest();
                    if (object.notificationUuid != null)
                        message.notificationUuid = String(object.notificationUuid);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    switch (object.notificationType) {
                    default:
                        if (typeof object.notificationType === "number") {
                            message.notificationType = object.notificationType;
                            break;
                        }
                        break;
                    case "NOTIFICATION_TYPE_UNKNOWN":
                    case 0:
                        message.notificationType = 0;
                        break;
                    case "NOTIFICATION_TYPE_BEG_FOR_OPERATOR":
                    case 1:
                        message.notificationType = 1;
                        break;
                    case "NOTIFICATION_TYPE_REQUEUE_TASK":
                    case 2:
                        message.notificationType = 2;
                        break;
                    case "NOTIFICATION_TYPE_GENERIC":
                    case 3:
                        message.notificationType = 3;
                        break;
                    }
                    switch (object.action) {
                    default:
                        if (typeof object.action === "number") {
                            message.action = object.action;
                            break;
                        }
                        break;
                    case "NOTIFICATION_ACTION_UNKNOWN":
                    case 0:
                        message.action = 0;
                        break;
                    case "NOTIFICATION_ACTION_DISMISSED":
                    case 1:
                        message.action = 1;
                        break;
                    case "NOTIFICATION_ACTION_HIDDEN":
                    case 2:
                        message.action = 2;
                        break;
                    case "NOTIFICATION_ACTION_CLICKED":
                    case 3:
                        message.action = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a NotificationReportClickRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {infinitusai.be.NotificationReportClickRequest} message NotificationReportClickRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NotificationReportClickRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.notificationUuid = "";
                        object.userEmail = "";
                        object.notificationType = options.enums === String ? "NOTIFICATION_TYPE_UNKNOWN" : 0;
                        object.action = options.enums === String ? "NOTIFICATION_ACTION_UNKNOWN" : 0;
                    }
                    if (message.notificationUuid != null && message.hasOwnProperty("notificationUuid"))
                        object.notificationUuid = message.notificationUuid;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                        object.notificationType = options.enums === String ? $root.infinitusai.be.NotificationType[message.notificationType] === undefined ? message.notificationType : $root.infinitusai.be.NotificationType[message.notificationType] : message.notificationType;
                    if (message.action != null && message.hasOwnProperty("action"))
                        object.action = options.enums === String ? $root.infinitusai.be.NotificationAction[message.action] === undefined ? message.action : $root.infinitusai.be.NotificationAction[message.action] : message.action;
                    return object;
                };
    
                /**
                 * Converts this NotificationReportClickRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NotificationReportClickRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NotificationReportClickRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NotificationReportClickRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NotificationReportClickRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NotificationReportClickRequest";
                };
    
                return NotificationReportClickRequest;
            })();
    
            be.NotificationReportClickResponse = (function() {
    
                /**
                 * Properties of a NotificationReportClickResponse.
                 * @memberof infinitusai.be
                 * @interface INotificationReportClickResponse
                 */
    
                /**
                 * Constructs a new NotificationReportClickResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NotificationReportClickResponse.
                 * @implements INotificationReportClickResponse
                 * @constructor
                 * @param {infinitusai.be.INotificationReportClickResponse=} [properties] Properties to set
                 */
                function NotificationReportClickResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new NotificationReportClickResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {infinitusai.be.INotificationReportClickResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.NotificationReportClickResponse} NotificationReportClickResponse instance
                 */
                NotificationReportClickResponse.create = function create(properties) {
                    return new NotificationReportClickResponse(properties);
                };
    
                /**
                 * Encodes the specified NotificationReportClickResponse message. Does not implicitly {@link infinitusai.be.NotificationReportClickResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {infinitusai.be.INotificationReportClickResponse} message NotificationReportClickResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NotificationReportClickResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified NotificationReportClickResponse message, length delimited. Does not implicitly {@link infinitusai.be.NotificationReportClickResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {infinitusai.be.INotificationReportClickResponse} message NotificationReportClickResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NotificationReportClickResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NotificationReportClickResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NotificationReportClickResponse} NotificationReportClickResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NotificationReportClickResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NotificationReportClickResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NotificationReportClickResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NotificationReportClickResponse} NotificationReportClickResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NotificationReportClickResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NotificationReportClickResponse message.
                 * @function verify
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NotificationReportClickResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a NotificationReportClickResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NotificationReportClickResponse} NotificationReportClickResponse
                 */
                NotificationReportClickResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NotificationReportClickResponse)
                        return object;
                    return new $root.infinitusai.be.NotificationReportClickResponse();
                };
    
                /**
                 * Creates a plain object from a NotificationReportClickResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {infinitusai.be.NotificationReportClickResponse} message NotificationReportClickResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NotificationReportClickResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this NotificationReportClickResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NotificationReportClickResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NotificationReportClickResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NotificationReportClickResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NotificationReportClickResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NotificationReportClickResponse";
                };
    
                return NotificationReportClickResponse;
            })();
    
            be.PendingNotificationRequest = (function() {
    
                /**
                 * Properties of a PendingNotificationRequest.
                 * @memberof infinitusai.be
                 * @interface IPendingNotificationRequest
                 */
    
                /**
                 * Constructs a new PendingNotificationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PendingNotificationRequest.
                 * @implements IPendingNotificationRequest
                 * @constructor
                 * @param {infinitusai.be.IPendingNotificationRequest=} [properties] Properties to set
                 */
                function PendingNotificationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new PendingNotificationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {infinitusai.be.IPendingNotificationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.PendingNotificationRequest} PendingNotificationRequest instance
                 */
                PendingNotificationRequest.create = function create(properties) {
                    return new PendingNotificationRequest(properties);
                };
    
                /**
                 * Encodes the specified PendingNotificationRequest message. Does not implicitly {@link infinitusai.be.PendingNotificationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {infinitusai.be.IPendingNotificationRequest} message PendingNotificationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PendingNotificationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified PendingNotificationRequest message, length delimited. Does not implicitly {@link infinitusai.be.PendingNotificationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {infinitusai.be.IPendingNotificationRequest} message PendingNotificationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PendingNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PendingNotificationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PendingNotificationRequest} PendingNotificationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PendingNotificationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PendingNotificationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PendingNotificationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PendingNotificationRequest} PendingNotificationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PendingNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PendingNotificationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PendingNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a PendingNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PendingNotificationRequest} PendingNotificationRequest
                 */
                PendingNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PendingNotificationRequest)
                        return object;
                    return new $root.infinitusai.be.PendingNotificationRequest();
                };
    
                /**
                 * Creates a plain object from a PendingNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {infinitusai.be.PendingNotificationRequest} message PendingNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PendingNotificationRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this PendingNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PendingNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PendingNotificationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PendingNotificationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PendingNotificationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PendingNotificationRequest";
                };
    
                return PendingNotificationRequest;
            })();
    
            be.PendingNotificationResponse = (function() {
    
                /**
                 * Properties of a PendingNotificationResponse.
                 * @memberof infinitusai.be
                 * @interface IPendingNotificationResponse
                 * @property {Array.<infinitusai.be.INotification>|null} [notifications] PendingNotificationResponse notifications
                 */
    
                /**
                 * Constructs a new PendingNotificationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PendingNotificationResponse.
                 * @implements IPendingNotificationResponse
                 * @constructor
                 * @param {infinitusai.be.IPendingNotificationResponse=} [properties] Properties to set
                 */
                function PendingNotificationResponse(properties) {
                    this.notifications = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PendingNotificationResponse notifications.
                 * @member {Array.<infinitusai.be.INotification>} notifications
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @instance
                 */
                PendingNotificationResponse.prototype.notifications = $util.emptyArray;
    
                /**
                 * Creates a new PendingNotificationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {infinitusai.be.IPendingNotificationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.PendingNotificationResponse} PendingNotificationResponse instance
                 */
                PendingNotificationResponse.create = function create(properties) {
                    return new PendingNotificationResponse(properties);
                };
    
                /**
                 * Encodes the specified PendingNotificationResponse message. Does not implicitly {@link infinitusai.be.PendingNotificationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {infinitusai.be.IPendingNotificationResponse} message PendingNotificationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PendingNotificationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.notifications != null && message.notifications.length)
                        for (var i = 0; i < message.notifications.length; ++i)
                            $root.infinitusai.be.Notification.encode(message.notifications[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PendingNotificationResponse message, length delimited. Does not implicitly {@link infinitusai.be.PendingNotificationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {infinitusai.be.IPendingNotificationResponse} message PendingNotificationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PendingNotificationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PendingNotificationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PendingNotificationResponse} PendingNotificationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PendingNotificationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PendingNotificationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.notifications && message.notifications.length))
                                    message.notifications = [];
                                message.notifications.push($root.infinitusai.be.Notification.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PendingNotificationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PendingNotificationResponse} PendingNotificationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PendingNotificationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PendingNotificationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PendingNotificationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.notifications != null && message.hasOwnProperty("notifications")) {
                        if (!Array.isArray(message.notifications))
                            return "notifications: array expected";
                        for (var i = 0; i < message.notifications.length; ++i) {
                            var error = $root.infinitusai.be.Notification.verify(message.notifications[i]);
                            if (error)
                                return "notifications." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a PendingNotificationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PendingNotificationResponse} PendingNotificationResponse
                 */
                PendingNotificationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PendingNotificationResponse)
                        return object;
                    var message = new $root.infinitusai.be.PendingNotificationResponse();
                    if (object.notifications) {
                        if (!Array.isArray(object.notifications))
                            throw TypeError(".infinitusai.be.PendingNotificationResponse.notifications: array expected");
                        message.notifications = [];
                        for (var i = 0; i < object.notifications.length; ++i) {
                            if (typeof object.notifications[i] !== "object")
                                throw TypeError(".infinitusai.be.PendingNotificationResponse.notifications: object expected");
                            message.notifications[i] = $root.infinitusai.be.Notification.fromObject(object.notifications[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PendingNotificationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {infinitusai.be.PendingNotificationResponse} message PendingNotificationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PendingNotificationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.notifications = [];
                    if (message.notifications && message.notifications.length) {
                        object.notifications = [];
                        for (var j = 0; j < message.notifications.length; ++j)
                            object.notifications[j] = $root.infinitusai.be.Notification.toObject(message.notifications[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this PendingNotificationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PendingNotificationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PendingNotificationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PendingNotificationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PendingNotificationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PendingNotificationResponse";
                };
    
                return PendingNotificationResponse;
            })();
    
            be.BroadcastNotificationRequest = (function() {
    
                /**
                 * Properties of a BroadcastNotificationRequest.
                 * @memberof infinitusai.be
                 * @interface IBroadcastNotificationRequest
                 * @property {Array.<string>|null} [recipientEmails] BroadcastNotificationRequest recipientEmails
                 * @property {infinitusai.be.Notification.IPayload|null} [payload] BroadcastNotificationRequest payload
                 */
    
                /**
                 * Constructs a new BroadcastNotificationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BroadcastNotificationRequest.
                 * @implements IBroadcastNotificationRequest
                 * @constructor
                 * @param {infinitusai.be.IBroadcastNotificationRequest=} [properties] Properties to set
                 */
                function BroadcastNotificationRequest(properties) {
                    this.recipientEmails = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BroadcastNotificationRequest recipientEmails.
                 * @member {Array.<string>} recipientEmails
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @instance
                 */
                BroadcastNotificationRequest.prototype.recipientEmails = $util.emptyArray;
    
                /**
                 * BroadcastNotificationRequest payload.
                 * @member {infinitusai.be.Notification.IPayload|null|undefined} payload
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @instance
                 */
                BroadcastNotificationRequest.prototype.payload = null;
    
                /**
                 * Creates a new BroadcastNotificationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {infinitusai.be.IBroadcastNotificationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.BroadcastNotificationRequest} BroadcastNotificationRequest instance
                 */
                BroadcastNotificationRequest.create = function create(properties) {
                    return new BroadcastNotificationRequest(properties);
                };
    
                /**
                 * Encodes the specified BroadcastNotificationRequest message. Does not implicitly {@link infinitusai.be.BroadcastNotificationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {infinitusai.be.IBroadcastNotificationRequest} message BroadcastNotificationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BroadcastNotificationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.recipientEmails != null && message.recipientEmails.length)
                        for (var i = 0; i < message.recipientEmails.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.recipientEmails[i]);
                    if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                        $root.infinitusai.be.Notification.Payload.encode(message.payload, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified BroadcastNotificationRequest message, length delimited. Does not implicitly {@link infinitusai.be.BroadcastNotificationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {infinitusai.be.IBroadcastNotificationRequest} message BroadcastNotificationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BroadcastNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BroadcastNotificationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BroadcastNotificationRequest} BroadcastNotificationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BroadcastNotificationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BroadcastNotificationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.recipientEmails && message.recipientEmails.length))
                                    message.recipientEmails = [];
                                message.recipientEmails.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.payload = $root.infinitusai.be.Notification.Payload.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BroadcastNotificationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BroadcastNotificationRequest} BroadcastNotificationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BroadcastNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BroadcastNotificationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BroadcastNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recipientEmails != null && message.hasOwnProperty("recipientEmails")) {
                        if (!Array.isArray(message.recipientEmails))
                            return "recipientEmails: array expected";
                        for (var i = 0; i < message.recipientEmails.length; ++i)
                            if (!$util.isString(message.recipientEmails[i]))
                                return "recipientEmails: string[] expected";
                    }
                    if (message.payload != null && message.hasOwnProperty("payload")) {
                        var error = $root.infinitusai.be.Notification.Payload.verify(message.payload);
                        if (error)
                            return "payload." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a BroadcastNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BroadcastNotificationRequest} BroadcastNotificationRequest
                 */
                BroadcastNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BroadcastNotificationRequest)
                        return object;
                    var message = new $root.infinitusai.be.BroadcastNotificationRequest();
                    if (object.recipientEmails) {
                        if (!Array.isArray(object.recipientEmails))
                            throw TypeError(".infinitusai.be.BroadcastNotificationRequest.recipientEmails: array expected");
                        message.recipientEmails = [];
                        for (var i = 0; i < object.recipientEmails.length; ++i)
                            message.recipientEmails[i] = String(object.recipientEmails[i]);
                    }
                    if (object.payload != null) {
                        if (typeof object.payload !== "object")
                            throw TypeError(".infinitusai.be.BroadcastNotificationRequest.payload: object expected");
                        message.payload = $root.infinitusai.be.Notification.Payload.fromObject(object.payload);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a BroadcastNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {infinitusai.be.BroadcastNotificationRequest} message BroadcastNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BroadcastNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.recipientEmails = [];
                    if (options.defaults)
                        object.payload = null;
                    if (message.recipientEmails && message.recipientEmails.length) {
                        object.recipientEmails = [];
                        for (var j = 0; j < message.recipientEmails.length; ++j)
                            object.recipientEmails[j] = message.recipientEmails[j];
                    }
                    if (message.payload != null && message.hasOwnProperty("payload"))
                        object.payload = $root.infinitusai.be.Notification.Payload.toObject(message.payload, options);
                    return object;
                };
    
                /**
                 * Converts this BroadcastNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BroadcastNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BroadcastNotificationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BroadcastNotificationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BroadcastNotificationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BroadcastNotificationRequest";
                };
    
                return BroadcastNotificationRequest;
            })();
    
            be.BroadcastNotificationResponse = (function() {
    
                /**
                 * Properties of a BroadcastNotificationResponse.
                 * @memberof infinitusai.be
                 * @interface IBroadcastNotificationResponse
                 */
    
                /**
                 * Constructs a new BroadcastNotificationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BroadcastNotificationResponse.
                 * @implements IBroadcastNotificationResponse
                 * @constructor
                 * @param {infinitusai.be.IBroadcastNotificationResponse=} [properties] Properties to set
                 */
                function BroadcastNotificationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new BroadcastNotificationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {infinitusai.be.IBroadcastNotificationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.BroadcastNotificationResponse} BroadcastNotificationResponse instance
                 */
                BroadcastNotificationResponse.create = function create(properties) {
                    return new BroadcastNotificationResponse(properties);
                };
    
                /**
                 * Encodes the specified BroadcastNotificationResponse message. Does not implicitly {@link infinitusai.be.BroadcastNotificationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {infinitusai.be.IBroadcastNotificationResponse} message BroadcastNotificationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BroadcastNotificationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified BroadcastNotificationResponse message, length delimited. Does not implicitly {@link infinitusai.be.BroadcastNotificationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {infinitusai.be.IBroadcastNotificationResponse} message BroadcastNotificationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BroadcastNotificationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BroadcastNotificationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BroadcastNotificationResponse} BroadcastNotificationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BroadcastNotificationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BroadcastNotificationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BroadcastNotificationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BroadcastNotificationResponse} BroadcastNotificationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BroadcastNotificationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BroadcastNotificationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BroadcastNotificationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a BroadcastNotificationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BroadcastNotificationResponse} BroadcastNotificationResponse
                 */
                BroadcastNotificationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BroadcastNotificationResponse)
                        return object;
                    return new $root.infinitusai.be.BroadcastNotificationResponse();
                };
    
                /**
                 * Creates a plain object from a BroadcastNotificationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {infinitusai.be.BroadcastNotificationResponse} message BroadcastNotificationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BroadcastNotificationResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this BroadcastNotificationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BroadcastNotificationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BroadcastNotificationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BroadcastNotificationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BroadcastNotificationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BroadcastNotificationResponse";
                };
    
                return BroadcastNotificationResponse;
            })();
    
            /**
             * CallOutputFieldType enum.
             * @name infinitusai.be.CallOutputFieldType
             * @enum {number}
             * @property {number} OUTPUT_TYPE_EMPTY=0 OUTPUT_TYPE_EMPTY value
             * @property {number} OUTPUT_TYPE_STRING=1 OUTPUT_TYPE_STRING value
             * @property {number} OUTPUT_TYPE_INT=2 OUTPUT_TYPE_INT value
             * @property {number} OUTPUT_TYPE_BOOL=3 OUTPUT_TYPE_BOOL value
             * @property {number} OUTPUT_TYPE_MONEY=4 OUTPUT_TYPE_MONEY value
             * @property {number} OUTPUT_TYPE_ENUM=5 OUTPUT_TYPE_ENUM value
             * @property {number} OUTPUT_TYPE_DATE=6 OUTPUT_TYPE_DATE value
             * @property {number} OUTPUT_TYPE_ENUMS=7 OUTPUT_TYPE_ENUMS value
             */
            be.CallOutputFieldType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OUTPUT_TYPE_EMPTY"] = 0;
                values[valuesById[1] = "OUTPUT_TYPE_STRING"] = 1;
                values[valuesById[2] = "OUTPUT_TYPE_INT"] = 2;
                values[valuesById[3] = "OUTPUT_TYPE_BOOL"] = 3;
                values[valuesById[4] = "OUTPUT_TYPE_MONEY"] = 4;
                values[valuesById[5] = "OUTPUT_TYPE_ENUM"] = 5;
                values[valuesById[6] = "OUTPUT_TYPE_DATE"] = 6;
                values[valuesById[7] = "OUTPUT_TYPE_ENUMS"] = 7;
                return values;
            })();
    
            be.CopyCallOutputsRequest = (function() {
    
                /**
                 * Properties of a CopyCallOutputsRequest.
                 * @memberof infinitusai.be
                 * @interface ICopyCallOutputsRequest
                 * @property {string|null} [sourceOrgUuid] CopyCallOutputsRequest sourceOrgUuid
                 * @property {string|null} [sourceTaskUuid] CopyCallOutputsRequest sourceTaskUuid
                 * @property {string|null} [destTaskUuid] CopyCallOutputsRequest destTaskUuid
                 * @property {string|null} [destOrgUuid] CopyCallOutputsRequest destOrgUuid
                 */
    
                /**
                 * Constructs a new CopyCallOutputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CopyCallOutputsRequest.
                 * @implements ICopyCallOutputsRequest
                 * @constructor
                 * @param {infinitusai.be.ICopyCallOutputsRequest=} [properties] Properties to set
                 */
                function CopyCallOutputsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CopyCallOutputsRequest sourceOrgUuid.
                 * @member {string} sourceOrgUuid
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @instance
                 */
                CopyCallOutputsRequest.prototype.sourceOrgUuid = "";
    
                /**
                 * CopyCallOutputsRequest sourceTaskUuid.
                 * @member {string} sourceTaskUuid
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @instance
                 */
                CopyCallOutputsRequest.prototype.sourceTaskUuid = "";
    
                /**
                 * CopyCallOutputsRequest destTaskUuid.
                 * @member {string} destTaskUuid
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @instance
                 */
                CopyCallOutputsRequest.prototype.destTaskUuid = "";
    
                /**
                 * CopyCallOutputsRequest destOrgUuid.
                 * @member {string} destOrgUuid
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @instance
                 */
                CopyCallOutputsRequest.prototype.destOrgUuid = "";
    
                /**
                 * Creates a new CopyCallOutputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.ICopyCallOutputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CopyCallOutputsRequest} CopyCallOutputsRequest instance
                 */
                CopyCallOutputsRequest.create = function create(properties) {
                    return new CopyCallOutputsRequest(properties);
                };
    
                /**
                 * Encodes the specified CopyCallOutputsRequest message. Does not implicitly {@link infinitusai.be.CopyCallOutputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.ICopyCallOutputsRequest} message CopyCallOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CopyCallOutputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sourceOrgUuid != null && Object.hasOwnProperty.call(message, "sourceOrgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceOrgUuid);
                    if (message.sourceTaskUuid != null && Object.hasOwnProperty.call(message, "sourceTaskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceTaskUuid);
                    if (message.destTaskUuid != null && Object.hasOwnProperty.call(message, "destTaskUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.destTaskUuid);
                    if (message.destOrgUuid != null && Object.hasOwnProperty.call(message, "destOrgUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.destOrgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CopyCallOutputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.CopyCallOutputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.ICopyCallOutputsRequest} message CopyCallOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CopyCallOutputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CopyCallOutputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CopyCallOutputsRequest} CopyCallOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CopyCallOutputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CopyCallOutputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sourceOrgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.sourceTaskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.destTaskUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.destOrgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CopyCallOutputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CopyCallOutputsRequest} CopyCallOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CopyCallOutputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CopyCallOutputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CopyCallOutputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sourceOrgUuid != null && message.hasOwnProperty("sourceOrgUuid"))
                        if (!$util.isString(message.sourceOrgUuid))
                            return "sourceOrgUuid: string expected";
                    if (message.sourceTaskUuid != null && message.hasOwnProperty("sourceTaskUuid"))
                        if (!$util.isString(message.sourceTaskUuid))
                            return "sourceTaskUuid: string expected";
                    if (message.destTaskUuid != null && message.hasOwnProperty("destTaskUuid"))
                        if (!$util.isString(message.destTaskUuid))
                            return "destTaskUuid: string expected";
                    if (message.destOrgUuid != null && message.hasOwnProperty("destOrgUuid"))
                        if (!$util.isString(message.destOrgUuid))
                            return "destOrgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CopyCallOutputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CopyCallOutputsRequest} CopyCallOutputsRequest
                 */
                CopyCallOutputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CopyCallOutputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.CopyCallOutputsRequest();
                    if (object.sourceOrgUuid != null)
                        message.sourceOrgUuid = String(object.sourceOrgUuid);
                    if (object.sourceTaskUuid != null)
                        message.sourceTaskUuid = String(object.sourceTaskUuid);
                    if (object.destTaskUuid != null)
                        message.destTaskUuid = String(object.destTaskUuid);
                    if (object.destOrgUuid != null)
                        message.destOrgUuid = String(object.destOrgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CopyCallOutputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.CopyCallOutputsRequest} message CopyCallOutputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CopyCallOutputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sourceOrgUuid = "";
                        object.sourceTaskUuid = "";
                        object.destTaskUuid = "";
                        object.destOrgUuid = "";
                    }
                    if (message.sourceOrgUuid != null && message.hasOwnProperty("sourceOrgUuid"))
                        object.sourceOrgUuid = message.sourceOrgUuid;
                    if (message.sourceTaskUuid != null && message.hasOwnProperty("sourceTaskUuid"))
                        object.sourceTaskUuid = message.sourceTaskUuid;
                    if (message.destTaskUuid != null && message.hasOwnProperty("destTaskUuid"))
                        object.destTaskUuid = message.destTaskUuid;
                    if (message.destOrgUuid != null && message.hasOwnProperty("destOrgUuid"))
                        object.destOrgUuid = message.destOrgUuid;
                    return object;
                };
    
                /**
                 * Converts this CopyCallOutputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CopyCallOutputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CopyCallOutputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CopyCallOutputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CopyCallOutputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CopyCallOutputsRequest";
                };
    
                return CopyCallOutputsRequest;
            })();
    
            be.CopyCallOutputsResponse = (function() {
    
                /**
                 * Properties of a CopyCallOutputsResponse.
                 * @memberof infinitusai.be
                 * @interface ICopyCallOutputsResponse
                 */
    
                /**
                 * Constructs a new CopyCallOutputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CopyCallOutputsResponse.
                 * @implements ICopyCallOutputsResponse
                 * @constructor
                 * @param {infinitusai.be.ICopyCallOutputsResponse=} [properties] Properties to set
                 */
                function CopyCallOutputsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CopyCallOutputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.ICopyCallOutputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CopyCallOutputsResponse} CopyCallOutputsResponse instance
                 */
                CopyCallOutputsResponse.create = function create(properties) {
                    return new CopyCallOutputsResponse(properties);
                };
    
                /**
                 * Encodes the specified CopyCallOutputsResponse message. Does not implicitly {@link infinitusai.be.CopyCallOutputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.ICopyCallOutputsResponse} message CopyCallOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CopyCallOutputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CopyCallOutputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.CopyCallOutputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.ICopyCallOutputsResponse} message CopyCallOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CopyCallOutputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CopyCallOutputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CopyCallOutputsResponse} CopyCallOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CopyCallOutputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CopyCallOutputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CopyCallOutputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CopyCallOutputsResponse} CopyCallOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CopyCallOutputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CopyCallOutputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CopyCallOutputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CopyCallOutputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CopyCallOutputsResponse} CopyCallOutputsResponse
                 */
                CopyCallOutputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CopyCallOutputsResponse)
                        return object;
                    return new $root.infinitusai.be.CopyCallOutputsResponse();
                };
    
                /**
                 * Creates a plain object from a CopyCallOutputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.CopyCallOutputsResponse} message CopyCallOutputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CopyCallOutputsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CopyCallOutputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CopyCallOutputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CopyCallOutputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CopyCallOutputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CopyCallOutputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CopyCallOutputsResponse";
                };
    
                return CopyCallOutputsResponse;
            })();
    
            be.CallState = (function() {
    
                /**
                 * Properties of a CallState.
                 * @memberof infinitusai.be
                 * @interface ICallState
                 * @property {boolean|null} [questionMode] CallState questionMode
                 * @property {string|null} [lastQuestionAction] CallState lastQuestionAction
                 * @property {Array.<infinitusai.be.CallState.ILastQuestionAction>|null} [lastQuestionActions] CallState lastQuestionActions
                 * @property {infinitusai.be.CallState.Phase|null} [phase] CallState phase
                 * @property {infinitusai.be.INullableString|null} [agentName] CallState agentName
                 * @property {infinitusai.be.IMedSupBVCallOutputs|null} [medsupBvOutputs] CallState medsupBvOutputs
                 * @property {infinitusai.be.IFullBVCallOutputs|null} [fullBvOutputs] CallState fullBvOutputs
                 * @property {infinitusai.be.IPBMBVCallOutputs|null} [pbmBvOutputs] CallState pbmBvOutputs
                 * @property {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null} [covidVaccineAvailabilityOutputs] CallState covidVaccineAvailabilityOutputs
                 * @property {boolean|null} [persistencyToAgentMode] CallState persistencyToAgentMode
                 * @property {Array.<infinitusai.be.CallState.Phase>|null} [seenPhases] CallState seenPhases
                 * @property {Array.<infinitusai.be.CallState.IIvrConversationScriptIndex>|null} [ivrConversationScriptIndices] CallState ivrConversationScriptIndices
                 * @property {Array.<infinitusai.be.CallState.IUtteranceIntent>|null} [previousUtteranceIntents] CallState previousUtteranceIntents
                 * @property {Array.<string>|null} [previousActions] CallState previousActions
                 * @property {Array.<infinitusai.be.CallState.IAction>|null} [previousActionsInfo] CallState previousActionsInfo
                 * @property {boolean|null} [useMlClassificationForIvr] CallState useMlClassificationForIvr
                 * @property {string|null} [phoneNumber] CallState phoneNumber
                 * @property {infinitusai.be.CallState.IConversationState|null} [conversationState] CallState conversationState
                 * @property {boolean|null} [usePracticeForHopd] CallState usePracticeForHopd
                 * @property {boolean|null} [usePracticeForSpecialistOffice] CallState usePracticeForSpecialistOffice
                 * @property {string|null} [orgUuid] CallState orgUuid
                 * @property {string|null} [callOutputs] CallState callOutputs
                 * @property {Array.<infinitusai.be.CallState.IPreviousOutput>|null} [previousOutputs] CallState previousOutputs
                 * @property {infinitusai.be.IRequeueReason|null} [hangupReason] CallState hangupReason
                 * @property {string|null} [requeueNumber] CallState requeueNumber
                 * @property {boolean|null} [wasHungupByOutbound] CallState wasHungupByOutbound
                 * @property {boolean|null} [defaultToPracticeInfo] CallState defaultToPracticeInfo
                 * @property {infinitusai.be.CallState.HangupSource|null} [hangupSource] CallState hangupSource
                 * @property {infinitusai.be.ICallSummary|null} [callSummary] CallState callSummary
                 */
    
                /**
                 * Constructs a new CallState.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallState.
                 * @implements ICallState
                 * @constructor
                 * @param {infinitusai.be.ICallState=} [properties] Properties to set
                 */
                function CallState(properties) {
                    this.lastQuestionActions = [];
                    this.seenPhases = [];
                    this.ivrConversationScriptIndices = [];
                    this.previousUtteranceIntents = [];
                    this.previousActions = [];
                    this.previousActionsInfo = [];
                    this.previousOutputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallState questionMode.
                 * @member {boolean} questionMode
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.questionMode = false;
    
                /**
                 * CallState lastQuestionAction.
                 * @member {string} lastQuestionAction
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.lastQuestionAction = "";
    
                /**
                 * CallState lastQuestionActions.
                 * @member {Array.<infinitusai.be.CallState.ILastQuestionAction>} lastQuestionActions
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.lastQuestionActions = $util.emptyArray;
    
                /**
                 * CallState phase.
                 * @member {infinitusai.be.CallState.Phase} phase
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.phase = 0;
    
                /**
                 * CallState agentName.
                 * @member {infinitusai.be.INullableString|null|undefined} agentName
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.agentName = null;
    
                /**
                 * CallState medsupBvOutputs.
                 * @member {infinitusai.be.IMedSupBVCallOutputs|null|undefined} medsupBvOutputs
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.medsupBvOutputs = null;
    
                /**
                 * CallState fullBvOutputs.
                 * @member {infinitusai.be.IFullBVCallOutputs|null|undefined} fullBvOutputs
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.fullBvOutputs = null;
    
                /**
                 * CallState pbmBvOutputs.
                 * @member {infinitusai.be.IPBMBVCallOutputs|null|undefined} pbmBvOutputs
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.pbmBvOutputs = null;
    
                /**
                 * CallState covidVaccineAvailabilityOutputs.
                 * @member {infinitusai.be.ICovidVaccineAvailabilityCallOutputs|null|undefined} covidVaccineAvailabilityOutputs
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.covidVaccineAvailabilityOutputs = null;
    
                /**
                 * CallState persistencyToAgentMode.
                 * @member {boolean} persistencyToAgentMode
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.persistencyToAgentMode = false;
    
                /**
                 * CallState seenPhases.
                 * @member {Array.<infinitusai.be.CallState.Phase>} seenPhases
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.seenPhases = $util.emptyArray;
    
                /**
                 * CallState ivrConversationScriptIndices.
                 * @member {Array.<infinitusai.be.CallState.IIvrConversationScriptIndex>} ivrConversationScriptIndices
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.ivrConversationScriptIndices = $util.emptyArray;
    
                /**
                 * CallState previousUtteranceIntents.
                 * @member {Array.<infinitusai.be.CallState.IUtteranceIntent>} previousUtteranceIntents
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.previousUtteranceIntents = $util.emptyArray;
    
                /**
                 * CallState previousActions.
                 * @member {Array.<string>} previousActions
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.previousActions = $util.emptyArray;
    
                /**
                 * CallState previousActionsInfo.
                 * @member {Array.<infinitusai.be.CallState.IAction>} previousActionsInfo
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.previousActionsInfo = $util.emptyArray;
    
                /**
                 * CallState useMlClassificationForIvr.
                 * @member {boolean} useMlClassificationForIvr
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.useMlClassificationForIvr = false;
    
                /**
                 * CallState phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.phoneNumber = "";
    
                /**
                 * CallState conversationState.
                 * @member {infinitusai.be.CallState.IConversationState|null|undefined} conversationState
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.conversationState = null;
    
                /**
                 * CallState usePracticeForHopd.
                 * @member {boolean} usePracticeForHopd
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.usePracticeForHopd = false;
    
                /**
                 * CallState usePracticeForSpecialistOffice.
                 * @member {boolean} usePracticeForSpecialistOffice
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.usePracticeForSpecialistOffice = false;
    
                /**
                 * CallState orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.orgUuid = "";
    
                /**
                 * CallState callOutputs.
                 * @member {string} callOutputs
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.callOutputs = "";
    
                /**
                 * CallState previousOutputs.
                 * @member {Array.<infinitusai.be.CallState.IPreviousOutput>} previousOutputs
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.previousOutputs = $util.emptyArray;
    
                /**
                 * CallState hangupReason.
                 * @member {infinitusai.be.IRequeueReason|null|undefined} hangupReason
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.hangupReason = null;
    
                /**
                 * CallState requeueNumber.
                 * @member {string} requeueNumber
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.requeueNumber = "";
    
                /**
                 * CallState wasHungupByOutbound.
                 * @member {boolean} wasHungupByOutbound
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.wasHungupByOutbound = false;
    
                /**
                 * CallState defaultToPracticeInfo.
                 * @member {boolean} defaultToPracticeInfo
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.defaultToPracticeInfo = false;
    
                /**
                 * CallState hangupSource.
                 * @member {infinitusai.be.CallState.HangupSource} hangupSource
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.hangupSource = 0;
    
                /**
                 * CallState callSummary.
                 * @member {infinitusai.be.ICallSummary|null|undefined} callSummary
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                CallState.prototype.callSummary = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * CallState outputs.
                 * @member {"medsupBvOutputs"|"fullBvOutputs"|"pbmBvOutputs"|"covidVaccineAvailabilityOutputs"|undefined} outputs
                 * @memberof infinitusai.be.CallState
                 * @instance
                 */
                Object.defineProperty(CallState.prototype, "outputs", {
                    get: $util.oneOfGetter($oneOfFields = ["medsupBvOutputs", "fullBvOutputs", "pbmBvOutputs", "covidVaccineAvailabilityOutputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new CallState instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {infinitusai.be.ICallState=} [properties] Properties to set
                 * @returns {infinitusai.be.CallState} CallState instance
                 */
                CallState.create = function create(properties) {
                    return new CallState(properties);
                };
    
                /**
                 * Encodes the specified CallState message. Does not implicitly {@link infinitusai.be.CallState.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {infinitusai.be.ICallState} message CallState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallState.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.questionMode != null && Object.hasOwnProperty.call(message, "questionMode"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.questionMode);
                    if (message.lastQuestionAction != null && Object.hasOwnProperty.call(message, "lastQuestionAction"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastQuestionAction);
                    if (message.phase != null && Object.hasOwnProperty.call(message, "phase"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.phase);
                    if (message.agentName != null && Object.hasOwnProperty.call(message, "agentName"))
                        $root.infinitusai.be.NullableString.encode(message.agentName, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.medsupBvOutputs != null && Object.hasOwnProperty.call(message, "medsupBvOutputs"))
                        $root.infinitusai.be.MedSupBVCallOutputs.encode(message.medsupBvOutputs, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.fullBvOutputs != null && Object.hasOwnProperty.call(message, "fullBvOutputs"))
                        $root.infinitusai.be.FullBVCallOutputs.encode(message.fullBvOutputs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.persistencyToAgentMode != null && Object.hasOwnProperty.call(message, "persistencyToAgentMode"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.persistencyToAgentMode);
                    if (message.seenPhases != null && message.seenPhases.length) {
                        writer.uint32(/* id 8, wireType 2 =*/66).fork();
                        for (var i = 0; i < message.seenPhases.length; ++i)
                            writer.int32(message.seenPhases[i]);
                        writer.ldelim();
                    }
                    if (message.ivrConversationScriptIndices != null && message.ivrConversationScriptIndices.length)
                        for (var i = 0; i < message.ivrConversationScriptIndices.length; ++i)
                            $root.infinitusai.be.CallState.IvrConversationScriptIndex.encode(message.ivrConversationScriptIndices[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.previousUtteranceIntents != null && message.previousUtteranceIntents.length)
                        for (var i = 0; i < message.previousUtteranceIntents.length; ++i)
                            $root.infinitusai.be.CallState.UtteranceIntent.encode(message.previousUtteranceIntents[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.previousActions != null && message.previousActions.length)
                        for (var i = 0; i < message.previousActions.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.previousActions[i]);
                    if (message.previousActionsInfo != null && message.previousActionsInfo.length)
                        for (var i = 0; i < message.previousActionsInfo.length; ++i)
                            $root.infinitusai.be.CallState.Action.encode(message.previousActionsInfo[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.pbmBvOutputs != null && Object.hasOwnProperty.call(message, "pbmBvOutputs"))
                        $root.infinitusai.be.PBMBVCallOutputs.encode(message.pbmBvOutputs, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.useMlClassificationForIvr != null && Object.hasOwnProperty.call(message, "useMlClassificationForIvr"))
                        writer.uint32(/* id 14, wireType 0 =*/112).bool(message.useMlClassificationForIvr);
                    if (message.lastQuestionActions != null && message.lastQuestionActions.length)
                        for (var i = 0; i < message.lastQuestionActions.length; ++i)
                            $root.infinitusai.be.CallState.LastQuestionAction.encode(message.lastQuestionActions[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.phoneNumber);
                    if (message.covidVaccineAvailabilityOutputs != null && Object.hasOwnProperty.call(message, "covidVaccineAvailabilityOutputs"))
                        $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.encode(message.covidVaccineAvailabilityOutputs, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.conversationState != null && Object.hasOwnProperty.call(message, "conversationState"))
                        $root.infinitusai.be.CallState.ConversationState.encode(message.conversationState, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.usePracticeForHopd != null && Object.hasOwnProperty.call(message, "usePracticeForHopd"))
                        writer.uint32(/* id 19, wireType 0 =*/152).bool(message.usePracticeForHopd);
                    if (message.usePracticeForSpecialistOffice != null && Object.hasOwnProperty.call(message, "usePracticeForSpecialistOffice"))
                        writer.uint32(/* id 20, wireType 0 =*/160).bool(message.usePracticeForSpecialistOffice);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 21, wireType 2 =*/170).string(message.orgUuid);
                    if (message.callOutputs != null && Object.hasOwnProperty.call(message, "callOutputs"))
                        writer.uint32(/* id 22, wireType 2 =*/178).string(message.callOutputs);
                    if (message.previousOutputs != null && message.previousOutputs.length)
                        for (var i = 0; i < message.previousOutputs.length; ++i)
                            $root.infinitusai.be.CallState.PreviousOutput.encode(message.previousOutputs[i], writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                    if (message.hangupReason != null && Object.hasOwnProperty.call(message, "hangupReason"))
                        $root.infinitusai.be.RequeueReason.encode(message.hangupReason, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                    if (message.requeueNumber != null && Object.hasOwnProperty.call(message, "requeueNumber"))
                        writer.uint32(/* id 25, wireType 2 =*/202).string(message.requeueNumber);
                    if (message.wasHungupByOutbound != null && Object.hasOwnProperty.call(message, "wasHungupByOutbound"))
                        writer.uint32(/* id 26, wireType 0 =*/208).bool(message.wasHungupByOutbound);
                    if (message.defaultToPracticeInfo != null && Object.hasOwnProperty.call(message, "defaultToPracticeInfo"))
                        writer.uint32(/* id 27, wireType 0 =*/216).bool(message.defaultToPracticeInfo);
                    if (message.hangupSource != null && Object.hasOwnProperty.call(message, "hangupSource"))
                        writer.uint32(/* id 28, wireType 0 =*/224).int32(message.hangupSource);
                    if (message.callSummary != null && Object.hasOwnProperty.call(message, "callSummary"))
                        $root.infinitusai.be.CallSummary.encode(message.callSummary, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallState message, length delimited. Does not implicitly {@link infinitusai.be.CallState.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {infinitusai.be.ICallState} message CallState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallState.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallState message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallState} CallState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallState.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallState();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.questionMode = reader.bool();
                                break;
                            }
                        case 2: {
                                message.lastQuestionAction = reader.string();
                                break;
                            }
                        case 15: {
                                if (!(message.lastQuestionActions && message.lastQuestionActions.length))
                                    message.lastQuestionActions = [];
                                message.lastQuestionActions.push($root.infinitusai.be.CallState.LastQuestionAction.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.phase = reader.int32();
                                break;
                            }
                        case 4: {
                                message.agentName = $root.infinitusai.be.NullableString.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.covidVaccineAvailabilityOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.persistencyToAgentMode = reader.bool();
                                break;
                            }
                        case 8: {
                                if (!(message.seenPhases && message.seenPhases.length))
                                    message.seenPhases = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.seenPhases.push(reader.int32());
                                } else
                                    message.seenPhases.push(reader.int32());
                                break;
                            }
                        case 9: {
                                if (!(message.ivrConversationScriptIndices && message.ivrConversationScriptIndices.length))
                                    message.ivrConversationScriptIndices = [];
                                message.ivrConversationScriptIndices.push($root.infinitusai.be.CallState.IvrConversationScriptIndex.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                if (!(message.previousUtteranceIntents && message.previousUtteranceIntents.length))
                                    message.previousUtteranceIntents = [];
                                message.previousUtteranceIntents.push($root.infinitusai.be.CallState.UtteranceIntent.decode(reader, reader.uint32()));
                                break;
                            }
                        case 11: {
                                if (!(message.previousActions && message.previousActions.length))
                                    message.previousActions = [];
                                message.previousActions.push(reader.string());
                                break;
                            }
                        case 12: {
                                if (!(message.previousActionsInfo && message.previousActionsInfo.length))
                                    message.previousActionsInfo = [];
                                message.previousActionsInfo.push($root.infinitusai.be.CallState.Action.decode(reader, reader.uint32()));
                                break;
                            }
                        case 14: {
                                message.useMlClassificationForIvr = reader.bool();
                                break;
                            }
                        case 16: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 18: {
                                message.conversationState = $root.infinitusai.be.CallState.ConversationState.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.usePracticeForHopd = reader.bool();
                                break;
                            }
                        case 20: {
                                message.usePracticeForSpecialistOffice = reader.bool();
                                break;
                            }
                        case 21: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 22: {
                                message.callOutputs = reader.string();
                                break;
                            }
                        case 23: {
                                if (!(message.previousOutputs && message.previousOutputs.length))
                                    message.previousOutputs = [];
                                message.previousOutputs.push($root.infinitusai.be.CallState.PreviousOutput.decode(reader, reader.uint32()));
                                break;
                            }
                        case 24: {
                                message.hangupReason = $root.infinitusai.be.RequeueReason.decode(reader, reader.uint32());
                                break;
                            }
                        case 25: {
                                message.requeueNumber = reader.string();
                                break;
                            }
                        case 26: {
                                message.wasHungupByOutbound = reader.bool();
                                break;
                            }
                        case 27: {
                                message.defaultToPracticeInfo = reader.bool();
                                break;
                            }
                        case 28: {
                                message.hangupSource = reader.int32();
                                break;
                            }
                        case 29: {
                                message.callSummary = $root.infinitusai.be.CallSummary.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallState message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallState} CallState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallState.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallState message.
                 * @function verify
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallState.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.questionMode != null && message.hasOwnProperty("questionMode"))
                        if (typeof message.questionMode !== "boolean")
                            return "questionMode: boolean expected";
                    if (message.lastQuestionAction != null && message.hasOwnProperty("lastQuestionAction"))
                        if (!$util.isString(message.lastQuestionAction))
                            return "lastQuestionAction: string expected";
                    if (message.lastQuestionActions != null && message.hasOwnProperty("lastQuestionActions")) {
                        if (!Array.isArray(message.lastQuestionActions))
                            return "lastQuestionActions: array expected";
                        for (var i = 0; i < message.lastQuestionActions.length; ++i) {
                            var error = $root.infinitusai.be.CallState.LastQuestionAction.verify(message.lastQuestionActions[i]);
                            if (error)
                                return "lastQuestionActions." + error;
                        }
                    }
                    if (message.phase != null && message.hasOwnProperty("phase"))
                        switch (message.phase) {
                        default:
                            return "phase: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.agentName != null && message.hasOwnProperty("agentName")) {
                        var error = $root.infinitusai.be.NullableString.verify(message.agentName);
                        if (error)
                            return "agentName." + error;
                    }
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.MedSupBVCallOutputs.verify(message.medsupBvOutputs);
                            if (error)
                                return "medsupBvOutputs." + error;
                        }
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.FullBVCallOutputs.verify(message.fullBvOutputs);
                            if (error)
                                return "fullBvOutputs." + error;
                        }
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.PBMBVCallOutputs.verify(message.pbmBvOutputs);
                            if (error)
                                return "pbmBvOutputs." + error;
                        }
                    }
                    if (message.covidVaccineAvailabilityOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityOutputs")) {
                        if (properties.outputs === 1)
                            return "outputs: multiple values";
                        properties.outputs = 1;
                        {
                            var error = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.verify(message.covidVaccineAvailabilityOutputs);
                            if (error)
                                return "covidVaccineAvailabilityOutputs." + error;
                        }
                    }
                    if (message.persistencyToAgentMode != null && message.hasOwnProperty("persistencyToAgentMode"))
                        if (typeof message.persistencyToAgentMode !== "boolean")
                            return "persistencyToAgentMode: boolean expected";
                    if (message.seenPhases != null && message.hasOwnProperty("seenPhases")) {
                        if (!Array.isArray(message.seenPhases))
                            return "seenPhases: array expected";
                        for (var i = 0; i < message.seenPhases.length; ++i)
                            switch (message.seenPhases[i]) {
                            default:
                                return "seenPhases: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                    }
                    if (message.ivrConversationScriptIndices != null && message.hasOwnProperty("ivrConversationScriptIndices")) {
                        if (!Array.isArray(message.ivrConversationScriptIndices))
                            return "ivrConversationScriptIndices: array expected";
                        for (var i = 0; i < message.ivrConversationScriptIndices.length; ++i) {
                            var error = $root.infinitusai.be.CallState.IvrConversationScriptIndex.verify(message.ivrConversationScriptIndices[i]);
                            if (error)
                                return "ivrConversationScriptIndices." + error;
                        }
                    }
                    if (message.previousUtteranceIntents != null && message.hasOwnProperty("previousUtteranceIntents")) {
                        if (!Array.isArray(message.previousUtteranceIntents))
                            return "previousUtteranceIntents: array expected";
                        for (var i = 0; i < message.previousUtteranceIntents.length; ++i) {
                            var error = $root.infinitusai.be.CallState.UtteranceIntent.verify(message.previousUtteranceIntents[i]);
                            if (error)
                                return "previousUtteranceIntents." + error;
                        }
                    }
                    if (message.previousActions != null && message.hasOwnProperty("previousActions")) {
                        if (!Array.isArray(message.previousActions))
                            return "previousActions: array expected";
                        for (var i = 0; i < message.previousActions.length; ++i)
                            if (!$util.isString(message.previousActions[i]))
                                return "previousActions: string[] expected";
                    }
                    if (message.previousActionsInfo != null && message.hasOwnProperty("previousActionsInfo")) {
                        if (!Array.isArray(message.previousActionsInfo))
                            return "previousActionsInfo: array expected";
                        for (var i = 0; i < message.previousActionsInfo.length; ++i) {
                            var error = $root.infinitusai.be.CallState.Action.verify(message.previousActionsInfo[i]);
                            if (error)
                                return "previousActionsInfo." + error;
                        }
                    }
                    if (message.useMlClassificationForIvr != null && message.hasOwnProperty("useMlClassificationForIvr"))
                        if (typeof message.useMlClassificationForIvr !== "boolean")
                            return "useMlClassificationForIvr: boolean expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.conversationState != null && message.hasOwnProperty("conversationState")) {
                        var error = $root.infinitusai.be.CallState.ConversationState.verify(message.conversationState);
                        if (error)
                            return "conversationState." + error;
                    }
                    if (message.usePracticeForHopd != null && message.hasOwnProperty("usePracticeForHopd"))
                        if (typeof message.usePracticeForHopd !== "boolean")
                            return "usePracticeForHopd: boolean expected";
                    if (message.usePracticeForSpecialistOffice != null && message.hasOwnProperty("usePracticeForSpecialistOffice"))
                        if (typeof message.usePracticeForSpecialistOffice !== "boolean")
                            return "usePracticeForSpecialistOffice: boolean expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.callOutputs != null && message.hasOwnProperty("callOutputs"))
                        if (!$util.isString(message.callOutputs))
                            return "callOutputs: string expected";
                    if (message.previousOutputs != null && message.hasOwnProperty("previousOutputs")) {
                        if (!Array.isArray(message.previousOutputs))
                            return "previousOutputs: array expected";
                        for (var i = 0; i < message.previousOutputs.length; ++i) {
                            var error = $root.infinitusai.be.CallState.PreviousOutput.verify(message.previousOutputs[i]);
                            if (error)
                                return "previousOutputs." + error;
                        }
                    }
                    if (message.hangupReason != null && message.hasOwnProperty("hangupReason")) {
                        var error = $root.infinitusai.be.RequeueReason.verify(message.hangupReason);
                        if (error)
                            return "hangupReason." + error;
                    }
                    if (message.requeueNumber != null && message.hasOwnProperty("requeueNumber"))
                        if (!$util.isString(message.requeueNumber))
                            return "requeueNumber: string expected";
                    if (message.wasHungupByOutbound != null && message.hasOwnProperty("wasHungupByOutbound"))
                        if (typeof message.wasHungupByOutbound !== "boolean")
                            return "wasHungupByOutbound: boolean expected";
                    if (message.defaultToPracticeInfo != null && message.hasOwnProperty("defaultToPracticeInfo"))
                        if (typeof message.defaultToPracticeInfo !== "boolean")
                            return "defaultToPracticeInfo: boolean expected";
                    if (message.hangupSource != null && message.hasOwnProperty("hangupSource"))
                        switch (message.hangupSource) {
                        default:
                            return "hangupSource: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.callSummary != null && message.hasOwnProperty("callSummary")) {
                        var error = $root.infinitusai.be.CallSummary.verify(message.callSummary);
                        if (error)
                            return "callSummary." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CallState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallState} CallState
                 */
                CallState.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallState)
                        return object;
                    var message = new $root.infinitusai.be.CallState();
                    if (object.questionMode != null)
                        message.questionMode = Boolean(object.questionMode);
                    if (object.lastQuestionAction != null)
                        message.lastQuestionAction = String(object.lastQuestionAction);
                    if (object.lastQuestionActions) {
                        if (!Array.isArray(object.lastQuestionActions))
                            throw TypeError(".infinitusai.be.CallState.lastQuestionActions: array expected");
                        message.lastQuestionActions = [];
                        for (var i = 0; i < object.lastQuestionActions.length; ++i) {
                            if (typeof object.lastQuestionActions[i] !== "object")
                                throw TypeError(".infinitusai.be.CallState.lastQuestionActions: object expected");
                            message.lastQuestionActions[i] = $root.infinitusai.be.CallState.LastQuestionAction.fromObject(object.lastQuestionActions[i]);
                        }
                    }
                    switch (object.phase) {
                    default:
                        if (typeof object.phase === "number") {
                            message.phase = object.phase;
                            break;
                        }
                        break;
                    case "IVR":
                    case 0:
                        message.phase = 0;
                        break;
                    case "Person":
                    case 1:
                        message.phase = 1;
                        break;
                    case "Hold":
                    case 2:
                        message.phase = 2;
                        break;
                    case "Person_Authenticate":
                    case 3:
                        message.phase = 3;
                        break;
                    case "Person_Get_Information":
                    case 4:
                        message.phase = 4;
                        break;
                    }
                    if (object.agentName != null) {
                        if (typeof object.agentName !== "object")
                            throw TypeError(".infinitusai.be.CallState.agentName: object expected");
                        message.agentName = $root.infinitusai.be.NullableString.fromObject(object.agentName);
                    }
                    if (object.medsupBvOutputs != null) {
                        if (typeof object.medsupBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallState.medsupBvOutputs: object expected");
                        message.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.fromObject(object.medsupBvOutputs);
                    }
                    if (object.fullBvOutputs != null) {
                        if (typeof object.fullBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallState.fullBvOutputs: object expected");
                        message.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.fromObject(object.fullBvOutputs);
                    }
                    if (object.pbmBvOutputs != null) {
                        if (typeof object.pbmBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallState.pbmBvOutputs: object expected");
                        message.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.fromObject(object.pbmBvOutputs);
                    }
                    if (object.covidVaccineAvailabilityOutputs != null) {
                        if (typeof object.covidVaccineAvailabilityOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallState.covidVaccineAvailabilityOutputs: object expected");
                        message.covidVaccineAvailabilityOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.fromObject(object.covidVaccineAvailabilityOutputs);
                    }
                    if (object.persistencyToAgentMode != null)
                        message.persistencyToAgentMode = Boolean(object.persistencyToAgentMode);
                    if (object.seenPhases) {
                        if (!Array.isArray(object.seenPhases))
                            throw TypeError(".infinitusai.be.CallState.seenPhases: array expected");
                        message.seenPhases = [];
                        for (var i = 0; i < object.seenPhases.length; ++i)
                            switch (object.seenPhases[i]) {
                            default:
                                if (typeof object.seenPhases[i] === "number") {
                                    message.seenPhases[i] = object.seenPhases[i];
                                    break;
                                }
                            case "IVR":
                            case 0:
                                message.seenPhases[i] = 0;
                                break;
                            case "Person":
                            case 1:
                                message.seenPhases[i] = 1;
                                break;
                            case "Hold":
                            case 2:
                                message.seenPhases[i] = 2;
                                break;
                            case "Person_Authenticate":
                            case 3:
                                message.seenPhases[i] = 3;
                                break;
                            case "Person_Get_Information":
                            case 4:
                                message.seenPhases[i] = 4;
                                break;
                            }
                    }
                    if (object.ivrConversationScriptIndices) {
                        if (!Array.isArray(object.ivrConversationScriptIndices))
                            throw TypeError(".infinitusai.be.CallState.ivrConversationScriptIndices: array expected");
                        message.ivrConversationScriptIndices = [];
                        for (var i = 0; i < object.ivrConversationScriptIndices.length; ++i) {
                            if (typeof object.ivrConversationScriptIndices[i] !== "object")
                                throw TypeError(".infinitusai.be.CallState.ivrConversationScriptIndices: object expected");
                            message.ivrConversationScriptIndices[i] = $root.infinitusai.be.CallState.IvrConversationScriptIndex.fromObject(object.ivrConversationScriptIndices[i]);
                        }
                    }
                    if (object.previousUtteranceIntents) {
                        if (!Array.isArray(object.previousUtteranceIntents))
                            throw TypeError(".infinitusai.be.CallState.previousUtteranceIntents: array expected");
                        message.previousUtteranceIntents = [];
                        for (var i = 0; i < object.previousUtteranceIntents.length; ++i) {
                            if (typeof object.previousUtteranceIntents[i] !== "object")
                                throw TypeError(".infinitusai.be.CallState.previousUtteranceIntents: object expected");
                            message.previousUtteranceIntents[i] = $root.infinitusai.be.CallState.UtteranceIntent.fromObject(object.previousUtteranceIntents[i]);
                        }
                    }
                    if (object.previousActions) {
                        if (!Array.isArray(object.previousActions))
                            throw TypeError(".infinitusai.be.CallState.previousActions: array expected");
                        message.previousActions = [];
                        for (var i = 0; i < object.previousActions.length; ++i)
                            message.previousActions[i] = String(object.previousActions[i]);
                    }
                    if (object.previousActionsInfo) {
                        if (!Array.isArray(object.previousActionsInfo))
                            throw TypeError(".infinitusai.be.CallState.previousActionsInfo: array expected");
                        message.previousActionsInfo = [];
                        for (var i = 0; i < object.previousActionsInfo.length; ++i) {
                            if (typeof object.previousActionsInfo[i] !== "object")
                                throw TypeError(".infinitusai.be.CallState.previousActionsInfo: object expected");
                            message.previousActionsInfo[i] = $root.infinitusai.be.CallState.Action.fromObject(object.previousActionsInfo[i]);
                        }
                    }
                    if (object.useMlClassificationForIvr != null)
                        message.useMlClassificationForIvr = Boolean(object.useMlClassificationForIvr);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.conversationState != null) {
                        if (typeof object.conversationState !== "object")
                            throw TypeError(".infinitusai.be.CallState.conversationState: object expected");
                        message.conversationState = $root.infinitusai.be.CallState.ConversationState.fromObject(object.conversationState);
                    }
                    if (object.usePracticeForHopd != null)
                        message.usePracticeForHopd = Boolean(object.usePracticeForHopd);
                    if (object.usePracticeForSpecialistOffice != null)
                        message.usePracticeForSpecialistOffice = Boolean(object.usePracticeForSpecialistOffice);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.callOutputs != null)
                        message.callOutputs = String(object.callOutputs);
                    if (object.previousOutputs) {
                        if (!Array.isArray(object.previousOutputs))
                            throw TypeError(".infinitusai.be.CallState.previousOutputs: array expected");
                        message.previousOutputs = [];
                        for (var i = 0; i < object.previousOutputs.length; ++i) {
                            if (typeof object.previousOutputs[i] !== "object")
                                throw TypeError(".infinitusai.be.CallState.previousOutputs: object expected");
                            message.previousOutputs[i] = $root.infinitusai.be.CallState.PreviousOutput.fromObject(object.previousOutputs[i]);
                        }
                    }
                    if (object.hangupReason != null) {
                        if (typeof object.hangupReason !== "object")
                            throw TypeError(".infinitusai.be.CallState.hangupReason: object expected");
                        message.hangupReason = $root.infinitusai.be.RequeueReason.fromObject(object.hangupReason);
                    }
                    if (object.requeueNumber != null)
                        message.requeueNumber = String(object.requeueNumber);
                    if (object.wasHungupByOutbound != null)
                        message.wasHungupByOutbound = Boolean(object.wasHungupByOutbound);
                    if (object.defaultToPracticeInfo != null)
                        message.defaultToPracticeInfo = Boolean(object.defaultToPracticeInfo);
                    switch (object.hangupSource) {
                    default:
                        if (typeof object.hangupSource === "number") {
                            message.hangupSource = object.hangupSource;
                            break;
                        }
                        break;
                    case "HANGUP_SOURCE_UNKNOWN":
                    case 0:
                        message.hangupSource = 0;
                        break;
                    case "HANGUP_SOURCE_TIMEOUT":
                    case 1:
                        message.hangupSource = 1;
                        break;
                    }
                    if (object.callSummary != null) {
                        if (typeof object.callSummary !== "object")
                            throw TypeError(".infinitusai.be.CallState.callSummary: object expected");
                        message.callSummary = $root.infinitusai.be.CallSummary.fromObject(object.callSummary);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {infinitusai.be.CallState} message CallState
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallState.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.seenPhases = [];
                        object.ivrConversationScriptIndices = [];
                        object.previousUtteranceIntents = [];
                        object.previousActions = [];
                        object.previousActionsInfo = [];
                        object.lastQuestionActions = [];
                        object.previousOutputs = [];
                    }
                    if (options.defaults) {
                        object.questionMode = false;
                        object.lastQuestionAction = "";
                        object.phase = options.enums === String ? "IVR" : 0;
                        object.agentName = null;
                        object.persistencyToAgentMode = false;
                        object.useMlClassificationForIvr = false;
                        object.phoneNumber = "";
                        object.conversationState = null;
                        object.usePracticeForHopd = false;
                        object.usePracticeForSpecialistOffice = false;
                        object.orgUuid = "";
                        object.callOutputs = "";
                        object.hangupReason = null;
                        object.requeueNumber = "";
                        object.wasHungupByOutbound = false;
                        object.defaultToPracticeInfo = false;
                        object.hangupSource = options.enums === String ? "HANGUP_SOURCE_UNKNOWN" : 0;
                        object.callSummary = null;
                    }
                    if (message.questionMode != null && message.hasOwnProperty("questionMode"))
                        object.questionMode = message.questionMode;
                    if (message.lastQuestionAction != null && message.hasOwnProperty("lastQuestionAction"))
                        object.lastQuestionAction = message.lastQuestionAction;
                    if (message.phase != null && message.hasOwnProperty("phase"))
                        object.phase = options.enums === String ? $root.infinitusai.be.CallState.Phase[message.phase] === undefined ? message.phase : $root.infinitusai.be.CallState.Phase[message.phase] : message.phase;
                    if (message.agentName != null && message.hasOwnProperty("agentName"))
                        object.agentName = $root.infinitusai.be.NullableString.toObject(message.agentName, options);
                    if (message.medsupBvOutputs != null && message.hasOwnProperty("medsupBvOutputs")) {
                        object.medsupBvOutputs = $root.infinitusai.be.MedSupBVCallOutputs.toObject(message.medsupBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "medsupBvOutputs";
                    }
                    if (message.fullBvOutputs != null && message.hasOwnProperty("fullBvOutputs")) {
                        object.fullBvOutputs = $root.infinitusai.be.FullBVCallOutputs.toObject(message.fullBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "fullBvOutputs";
                    }
                    if (message.persistencyToAgentMode != null && message.hasOwnProperty("persistencyToAgentMode"))
                        object.persistencyToAgentMode = message.persistencyToAgentMode;
                    if (message.seenPhases && message.seenPhases.length) {
                        object.seenPhases = [];
                        for (var j = 0; j < message.seenPhases.length; ++j)
                            object.seenPhases[j] = options.enums === String ? $root.infinitusai.be.CallState.Phase[message.seenPhases[j]] === undefined ? message.seenPhases[j] : $root.infinitusai.be.CallState.Phase[message.seenPhases[j]] : message.seenPhases[j];
                    }
                    if (message.ivrConversationScriptIndices && message.ivrConversationScriptIndices.length) {
                        object.ivrConversationScriptIndices = [];
                        for (var j = 0; j < message.ivrConversationScriptIndices.length; ++j)
                            object.ivrConversationScriptIndices[j] = $root.infinitusai.be.CallState.IvrConversationScriptIndex.toObject(message.ivrConversationScriptIndices[j], options);
                    }
                    if (message.previousUtteranceIntents && message.previousUtteranceIntents.length) {
                        object.previousUtteranceIntents = [];
                        for (var j = 0; j < message.previousUtteranceIntents.length; ++j)
                            object.previousUtteranceIntents[j] = $root.infinitusai.be.CallState.UtteranceIntent.toObject(message.previousUtteranceIntents[j], options);
                    }
                    if (message.previousActions && message.previousActions.length) {
                        object.previousActions = [];
                        for (var j = 0; j < message.previousActions.length; ++j)
                            object.previousActions[j] = message.previousActions[j];
                    }
                    if (message.previousActionsInfo && message.previousActionsInfo.length) {
                        object.previousActionsInfo = [];
                        for (var j = 0; j < message.previousActionsInfo.length; ++j)
                            object.previousActionsInfo[j] = $root.infinitusai.be.CallState.Action.toObject(message.previousActionsInfo[j], options);
                    }
                    if (message.pbmBvOutputs != null && message.hasOwnProperty("pbmBvOutputs")) {
                        object.pbmBvOutputs = $root.infinitusai.be.PBMBVCallOutputs.toObject(message.pbmBvOutputs, options);
                        if (options.oneofs)
                            object.outputs = "pbmBvOutputs";
                    }
                    if (message.useMlClassificationForIvr != null && message.hasOwnProperty("useMlClassificationForIvr"))
                        object.useMlClassificationForIvr = message.useMlClassificationForIvr;
                    if (message.lastQuestionActions && message.lastQuestionActions.length) {
                        object.lastQuestionActions = [];
                        for (var j = 0; j < message.lastQuestionActions.length; ++j)
                            object.lastQuestionActions[j] = $root.infinitusai.be.CallState.LastQuestionAction.toObject(message.lastQuestionActions[j], options);
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.covidVaccineAvailabilityOutputs != null && message.hasOwnProperty("covidVaccineAvailabilityOutputs")) {
                        object.covidVaccineAvailabilityOutputs = $root.infinitusai.be.CovidVaccineAvailabilityCallOutputs.toObject(message.covidVaccineAvailabilityOutputs, options);
                        if (options.oneofs)
                            object.outputs = "covidVaccineAvailabilityOutputs";
                    }
                    if (message.conversationState != null && message.hasOwnProperty("conversationState"))
                        object.conversationState = $root.infinitusai.be.CallState.ConversationState.toObject(message.conversationState, options);
                    if (message.usePracticeForHopd != null && message.hasOwnProperty("usePracticeForHopd"))
                        object.usePracticeForHopd = message.usePracticeForHopd;
                    if (message.usePracticeForSpecialistOffice != null && message.hasOwnProperty("usePracticeForSpecialistOffice"))
                        object.usePracticeForSpecialistOffice = message.usePracticeForSpecialistOffice;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.callOutputs != null && message.hasOwnProperty("callOutputs"))
                        object.callOutputs = message.callOutputs;
                    if (message.previousOutputs && message.previousOutputs.length) {
                        object.previousOutputs = [];
                        for (var j = 0; j < message.previousOutputs.length; ++j)
                            object.previousOutputs[j] = $root.infinitusai.be.CallState.PreviousOutput.toObject(message.previousOutputs[j], options);
                    }
                    if (message.hangupReason != null && message.hasOwnProperty("hangupReason"))
                        object.hangupReason = $root.infinitusai.be.RequeueReason.toObject(message.hangupReason, options);
                    if (message.requeueNumber != null && message.hasOwnProperty("requeueNumber"))
                        object.requeueNumber = message.requeueNumber;
                    if (message.wasHungupByOutbound != null && message.hasOwnProperty("wasHungupByOutbound"))
                        object.wasHungupByOutbound = message.wasHungupByOutbound;
                    if (message.defaultToPracticeInfo != null && message.hasOwnProperty("defaultToPracticeInfo"))
                        object.defaultToPracticeInfo = message.defaultToPracticeInfo;
                    if (message.hangupSource != null && message.hasOwnProperty("hangupSource"))
                        object.hangupSource = options.enums === String ? $root.infinitusai.be.CallState.HangupSource[message.hangupSource] === undefined ? message.hangupSource : $root.infinitusai.be.CallState.HangupSource[message.hangupSource] : message.hangupSource;
                    if (message.callSummary != null && message.hasOwnProperty("callSummary"))
                        object.callSummary = $root.infinitusai.be.CallSummary.toObject(message.callSummary, options);
                    return object;
                };
    
                /**
                 * Converts this CallState to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallState
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallState";
                };
    
                CallState.LastQuestionAction = (function() {
    
                    /**
                     * Properties of a LastQuestionAction.
                     * @memberof infinitusai.be.CallState
                     * @interface ILastQuestionAction
                     * @property {string|null} [lastQuestionActionName] LastQuestionAction lastQuestionActionName
                     * @property {number|Long|null} [startTimeMillis] LastQuestionAction startTimeMillis
                     * @property {number|Long|null} [endTimeMillis] LastQuestionAction endTimeMillis
                     */
    
                    /**
                     * Constructs a new LastQuestionAction.
                     * @memberof infinitusai.be.CallState
                     * @classdesc Represents a LastQuestionAction.
                     * @implements ILastQuestionAction
                     * @constructor
                     * @param {infinitusai.be.CallState.ILastQuestionAction=} [properties] Properties to set
                     */
                    function LastQuestionAction(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * LastQuestionAction lastQuestionActionName.
                     * @member {string} lastQuestionActionName
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @instance
                     */
                    LastQuestionAction.prototype.lastQuestionActionName = "";
    
                    /**
                     * LastQuestionAction startTimeMillis.
                     * @member {number|Long} startTimeMillis
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @instance
                     */
                    LastQuestionAction.prototype.startTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * LastQuestionAction endTimeMillis.
                     * @member {number|Long} endTimeMillis
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @instance
                     */
                    LastQuestionAction.prototype.endTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new LastQuestionAction instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {infinitusai.be.CallState.ILastQuestionAction=} [properties] Properties to set
                     * @returns {infinitusai.be.CallState.LastQuestionAction} LastQuestionAction instance
                     */
                    LastQuestionAction.create = function create(properties) {
                        return new LastQuestionAction(properties);
                    };
    
                    /**
                     * Encodes the specified LastQuestionAction message. Does not implicitly {@link infinitusai.be.CallState.LastQuestionAction.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {infinitusai.be.CallState.ILastQuestionAction} message LastQuestionAction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LastQuestionAction.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.lastQuestionActionName != null && Object.hasOwnProperty.call(message, "lastQuestionActionName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.lastQuestionActionName);
                        if (message.startTimeMillis != null && Object.hasOwnProperty.call(message, "startTimeMillis"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMillis);
                        if (message.endTimeMillis != null && Object.hasOwnProperty.call(message, "endTimeMillis"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMillis);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified LastQuestionAction message, length delimited. Does not implicitly {@link infinitusai.be.CallState.LastQuestionAction.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {infinitusai.be.CallState.ILastQuestionAction} message LastQuestionAction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LastQuestionAction.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a LastQuestionAction message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CallState.LastQuestionAction} LastQuestionAction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LastQuestionAction.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallState.LastQuestionAction();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.lastQuestionActionName = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.startTimeMillis = reader.int64();
                                    break;
                                }
                            case 3: {
                                    message.endTimeMillis = reader.int64();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a LastQuestionAction message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CallState.LastQuestionAction} LastQuestionAction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LastQuestionAction.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a LastQuestionAction message.
                     * @function verify
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LastQuestionAction.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.lastQuestionActionName != null && message.hasOwnProperty("lastQuestionActionName"))
                            if (!$util.isString(message.lastQuestionActionName))
                                return "lastQuestionActionName: string expected";
                        if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                            if (!$util.isInteger(message.startTimeMillis) && !(message.startTimeMillis && $util.isInteger(message.startTimeMillis.low) && $util.isInteger(message.startTimeMillis.high)))
                                return "startTimeMillis: integer|Long expected";
                        if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                            if (!$util.isInteger(message.endTimeMillis) && !(message.endTimeMillis && $util.isInteger(message.endTimeMillis.low) && $util.isInteger(message.endTimeMillis.high)))
                                return "endTimeMillis: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a LastQuestionAction message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CallState.LastQuestionAction} LastQuestionAction
                     */
                    LastQuestionAction.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CallState.LastQuestionAction)
                            return object;
                        var message = new $root.infinitusai.be.CallState.LastQuestionAction();
                        if (object.lastQuestionActionName != null)
                            message.lastQuestionActionName = String(object.lastQuestionActionName);
                        if (object.startTimeMillis != null)
                            if ($util.Long)
                                (message.startTimeMillis = $util.Long.fromValue(object.startTimeMillis)).unsigned = false;
                            else if (typeof object.startTimeMillis === "string")
                                message.startTimeMillis = parseInt(object.startTimeMillis, 10);
                            else if (typeof object.startTimeMillis === "number")
                                message.startTimeMillis = object.startTimeMillis;
                            else if (typeof object.startTimeMillis === "object")
                                message.startTimeMillis = new $util.LongBits(object.startTimeMillis.low >>> 0, object.startTimeMillis.high >>> 0).toNumber();
                        if (object.endTimeMillis != null)
                            if ($util.Long)
                                (message.endTimeMillis = $util.Long.fromValue(object.endTimeMillis)).unsigned = false;
                            else if (typeof object.endTimeMillis === "string")
                                message.endTimeMillis = parseInt(object.endTimeMillis, 10);
                            else if (typeof object.endTimeMillis === "number")
                                message.endTimeMillis = object.endTimeMillis;
                            else if (typeof object.endTimeMillis === "object")
                                message.endTimeMillis = new $util.LongBits(object.endTimeMillis.low >>> 0, object.endTimeMillis.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a LastQuestionAction message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {infinitusai.be.CallState.LastQuestionAction} message LastQuestionAction
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LastQuestionAction.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.lastQuestionActionName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.startTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.startTimeMillis = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.endTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.endTimeMillis = options.longs === String ? "0" : 0;
                        }
                        if (message.lastQuestionActionName != null && message.hasOwnProperty("lastQuestionActionName"))
                            object.lastQuestionActionName = message.lastQuestionActionName;
                        if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                            if (typeof message.startTimeMillis === "number")
                                object.startTimeMillis = options.longs === String ? String(message.startTimeMillis) : message.startTimeMillis;
                            else
                                object.startTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMillis) : options.longs === Number ? new $util.LongBits(message.startTimeMillis.low >>> 0, message.startTimeMillis.high >>> 0).toNumber() : message.startTimeMillis;
                        if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                            if (typeof message.endTimeMillis === "number")
                                object.endTimeMillis = options.longs === String ? String(message.endTimeMillis) : message.endTimeMillis;
                            else
                                object.endTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMillis) : options.longs === Number ? new $util.LongBits(message.endTimeMillis.low >>> 0, message.endTimeMillis.high >>> 0).toNumber() : message.endTimeMillis;
                        return object;
                    };
    
                    /**
                     * Converts this LastQuestionAction to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LastQuestionAction.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for LastQuestionAction
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CallState.LastQuestionAction
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    LastQuestionAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CallState.LastQuestionAction";
                    };
    
                    return LastQuestionAction;
                })();
    
                /**
                 * Phase enum.
                 * @name infinitusai.be.CallState.Phase
                 * @enum {number}
                 * @property {number} IVR=0 IVR value
                 * @property {number} Person=1 Person value
                 * @property {number} Hold=2 Hold value
                 * @property {number} Person_Authenticate=3 Person_Authenticate value
                 * @property {number} Person_Get_Information=4 Person_Get_Information value
                 */
                CallState.Phase = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "IVR"] = 0;
                    values[valuesById[1] = "Person"] = 1;
                    values[valuesById[2] = "Hold"] = 2;
                    values[valuesById[3] = "Person_Authenticate"] = 3;
                    values[valuesById[4] = "Person_Get_Information"] = 4;
                    return values;
                })();
    
                CallState.IvrConversationScriptIndex = (function() {
    
                    /**
                     * Properties of an IvrConversationScriptIndex.
                     * @memberof infinitusai.be.CallState
                     * @interface IIvrConversationScriptIndex
                     * @property {string|null} [filename] IvrConversationScriptIndex filename
                     * @property {number|null} [index] IvrConversationScriptIndex index
                     */
    
                    /**
                     * Constructs a new IvrConversationScriptIndex.
                     * @memberof infinitusai.be.CallState
                     * @classdesc Represents an IvrConversationScriptIndex.
                     * @implements IIvrConversationScriptIndex
                     * @constructor
                     * @param {infinitusai.be.CallState.IIvrConversationScriptIndex=} [properties] Properties to set
                     */
                    function IvrConversationScriptIndex(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * IvrConversationScriptIndex filename.
                     * @member {string} filename
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @instance
                     */
                    IvrConversationScriptIndex.prototype.filename = "";
    
                    /**
                     * IvrConversationScriptIndex index.
                     * @member {number} index
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @instance
                     */
                    IvrConversationScriptIndex.prototype.index = 0;
    
                    /**
                     * Creates a new IvrConversationScriptIndex instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {infinitusai.be.CallState.IIvrConversationScriptIndex=} [properties] Properties to set
                     * @returns {infinitusai.be.CallState.IvrConversationScriptIndex} IvrConversationScriptIndex instance
                     */
                    IvrConversationScriptIndex.create = function create(properties) {
                        return new IvrConversationScriptIndex(properties);
                    };
    
                    /**
                     * Encodes the specified IvrConversationScriptIndex message. Does not implicitly {@link infinitusai.be.CallState.IvrConversationScriptIndex.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {infinitusai.be.CallState.IIvrConversationScriptIndex} message IvrConversationScriptIndex message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IvrConversationScriptIndex.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.filename);
                        if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.index);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified IvrConversationScriptIndex message, length delimited. Does not implicitly {@link infinitusai.be.CallState.IvrConversationScriptIndex.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {infinitusai.be.CallState.IIvrConversationScriptIndex} message IvrConversationScriptIndex message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IvrConversationScriptIndex.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an IvrConversationScriptIndex message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CallState.IvrConversationScriptIndex} IvrConversationScriptIndex
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IvrConversationScriptIndex.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallState.IvrConversationScriptIndex();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.filename = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.index = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an IvrConversationScriptIndex message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CallState.IvrConversationScriptIndex} IvrConversationScriptIndex
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IvrConversationScriptIndex.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an IvrConversationScriptIndex message.
                     * @function verify
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IvrConversationScriptIndex.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.filename != null && message.hasOwnProperty("filename"))
                            if (!$util.isString(message.filename))
                                return "filename: string expected";
                        if (message.index != null && message.hasOwnProperty("index"))
                            if (!$util.isInteger(message.index))
                                return "index: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an IvrConversationScriptIndex message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CallState.IvrConversationScriptIndex} IvrConversationScriptIndex
                     */
                    IvrConversationScriptIndex.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CallState.IvrConversationScriptIndex)
                            return object;
                        var message = new $root.infinitusai.be.CallState.IvrConversationScriptIndex();
                        if (object.filename != null)
                            message.filename = String(object.filename);
                        if (object.index != null)
                            message.index = object.index | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an IvrConversationScriptIndex message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {infinitusai.be.CallState.IvrConversationScriptIndex} message IvrConversationScriptIndex
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IvrConversationScriptIndex.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.filename = "";
                            object.index = 0;
                        }
                        if (message.filename != null && message.hasOwnProperty("filename"))
                            object.filename = message.filename;
                        if (message.index != null && message.hasOwnProperty("index"))
                            object.index = message.index;
                        return object;
                    };
    
                    /**
                     * Converts this IvrConversationScriptIndex to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IvrConversationScriptIndex.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for IvrConversationScriptIndex
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CallState.IvrConversationScriptIndex
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    IvrConversationScriptIndex.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CallState.IvrConversationScriptIndex";
                    };
    
                    return IvrConversationScriptIndex;
                })();
    
                CallState.UtteranceIntent = (function() {
    
                    /**
                     * Properties of an UtteranceIntent.
                     * @memberof infinitusai.be.CallState
                     * @interface IUtteranceIntent
                     * @property {string|null} [utterance] UtteranceIntent utterance
                     * @property {string|null} [intent] UtteranceIntent intent
                     * @property {number|Long|null} [startTimeMillis] UtteranceIntent startTimeMillis
                     * @property {number|Long|null} [endTimeMillis] UtteranceIntent endTimeMillis
                     */
    
                    /**
                     * Constructs a new UtteranceIntent.
                     * @memberof infinitusai.be.CallState
                     * @classdesc Represents an UtteranceIntent.
                     * @implements IUtteranceIntent
                     * @constructor
                     * @param {infinitusai.be.CallState.IUtteranceIntent=} [properties] Properties to set
                     */
                    function UtteranceIntent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UtteranceIntent utterance.
                     * @member {string} utterance
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @instance
                     */
                    UtteranceIntent.prototype.utterance = "";
    
                    /**
                     * UtteranceIntent intent.
                     * @member {string} intent
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @instance
                     */
                    UtteranceIntent.prototype.intent = "";
    
                    /**
                     * UtteranceIntent startTimeMillis.
                     * @member {number|Long} startTimeMillis
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @instance
                     */
                    UtteranceIntent.prototype.startTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * UtteranceIntent endTimeMillis.
                     * @member {number|Long} endTimeMillis
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @instance
                     */
                    UtteranceIntent.prototype.endTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new UtteranceIntent instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {infinitusai.be.CallState.IUtteranceIntent=} [properties] Properties to set
                     * @returns {infinitusai.be.CallState.UtteranceIntent} UtteranceIntent instance
                     */
                    UtteranceIntent.create = function create(properties) {
                        return new UtteranceIntent(properties);
                    };
    
                    /**
                     * Encodes the specified UtteranceIntent message. Does not implicitly {@link infinitusai.be.CallState.UtteranceIntent.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {infinitusai.be.CallState.IUtteranceIntent} message UtteranceIntent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UtteranceIntent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.utterance != null && Object.hasOwnProperty.call(message, "utterance"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.utterance);
                        if (message.intent != null && Object.hasOwnProperty.call(message, "intent"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.intent);
                        if (message.startTimeMillis != null && Object.hasOwnProperty.call(message, "startTimeMillis"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.startTimeMillis);
                        if (message.endTimeMillis != null && Object.hasOwnProperty.call(message, "endTimeMillis"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.endTimeMillis);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UtteranceIntent message, length delimited. Does not implicitly {@link infinitusai.be.CallState.UtteranceIntent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {infinitusai.be.CallState.IUtteranceIntent} message UtteranceIntent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UtteranceIntent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UtteranceIntent message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CallState.UtteranceIntent} UtteranceIntent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UtteranceIntent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallState.UtteranceIntent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.utterance = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.intent = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.startTimeMillis = reader.int64();
                                    break;
                                }
                            case 4: {
                                    message.endTimeMillis = reader.int64();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UtteranceIntent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CallState.UtteranceIntent} UtteranceIntent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UtteranceIntent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UtteranceIntent message.
                     * @function verify
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UtteranceIntent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.utterance != null && message.hasOwnProperty("utterance"))
                            if (!$util.isString(message.utterance))
                                return "utterance: string expected";
                        if (message.intent != null && message.hasOwnProperty("intent"))
                            if (!$util.isString(message.intent))
                                return "intent: string expected";
                        if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                            if (!$util.isInteger(message.startTimeMillis) && !(message.startTimeMillis && $util.isInteger(message.startTimeMillis.low) && $util.isInteger(message.startTimeMillis.high)))
                                return "startTimeMillis: integer|Long expected";
                        if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                            if (!$util.isInteger(message.endTimeMillis) && !(message.endTimeMillis && $util.isInteger(message.endTimeMillis.low) && $util.isInteger(message.endTimeMillis.high)))
                                return "endTimeMillis: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an UtteranceIntent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CallState.UtteranceIntent} UtteranceIntent
                     */
                    UtteranceIntent.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CallState.UtteranceIntent)
                            return object;
                        var message = new $root.infinitusai.be.CallState.UtteranceIntent();
                        if (object.utterance != null)
                            message.utterance = String(object.utterance);
                        if (object.intent != null)
                            message.intent = String(object.intent);
                        if (object.startTimeMillis != null)
                            if ($util.Long)
                                (message.startTimeMillis = $util.Long.fromValue(object.startTimeMillis)).unsigned = false;
                            else if (typeof object.startTimeMillis === "string")
                                message.startTimeMillis = parseInt(object.startTimeMillis, 10);
                            else if (typeof object.startTimeMillis === "number")
                                message.startTimeMillis = object.startTimeMillis;
                            else if (typeof object.startTimeMillis === "object")
                                message.startTimeMillis = new $util.LongBits(object.startTimeMillis.low >>> 0, object.startTimeMillis.high >>> 0).toNumber();
                        if (object.endTimeMillis != null)
                            if ($util.Long)
                                (message.endTimeMillis = $util.Long.fromValue(object.endTimeMillis)).unsigned = false;
                            else if (typeof object.endTimeMillis === "string")
                                message.endTimeMillis = parseInt(object.endTimeMillis, 10);
                            else if (typeof object.endTimeMillis === "number")
                                message.endTimeMillis = object.endTimeMillis;
                            else if (typeof object.endTimeMillis === "object")
                                message.endTimeMillis = new $util.LongBits(object.endTimeMillis.low >>> 0, object.endTimeMillis.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UtteranceIntent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {infinitusai.be.CallState.UtteranceIntent} message UtteranceIntent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UtteranceIntent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.utterance = "";
                            object.intent = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.startTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.startTimeMillis = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.endTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.endTimeMillis = options.longs === String ? "0" : 0;
                        }
                        if (message.utterance != null && message.hasOwnProperty("utterance"))
                            object.utterance = message.utterance;
                        if (message.intent != null && message.hasOwnProperty("intent"))
                            object.intent = message.intent;
                        if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                            if (typeof message.startTimeMillis === "number")
                                object.startTimeMillis = options.longs === String ? String(message.startTimeMillis) : message.startTimeMillis;
                            else
                                object.startTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMillis) : options.longs === Number ? new $util.LongBits(message.startTimeMillis.low >>> 0, message.startTimeMillis.high >>> 0).toNumber() : message.startTimeMillis;
                        if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                            if (typeof message.endTimeMillis === "number")
                                object.endTimeMillis = options.longs === String ? String(message.endTimeMillis) : message.endTimeMillis;
                            else
                                object.endTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMillis) : options.longs === Number ? new $util.LongBits(message.endTimeMillis.low >>> 0, message.endTimeMillis.high >>> 0).toNumber() : message.endTimeMillis;
                        return object;
                    };
    
                    /**
                     * Converts this UtteranceIntent to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UtteranceIntent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for UtteranceIntent
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CallState.UtteranceIntent
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    UtteranceIntent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CallState.UtteranceIntent";
                    };
    
                    return UtteranceIntent;
                })();
    
                CallState.Action = (function() {
    
                    /**
                     * Properties of an Action.
                     * @memberof infinitusai.be.CallState
                     * @interface IAction
                     * @property {string|null} [actionName] Action actionName
                     * @property {string|null} [speechUtterance] Action speechUtterance
                     * @property {string|null} [dtmfDigits] Action dtmfDigits
                     * @property {infinitusai.be.ActionSource|null} [actionSource] Action actionSource
                     * @property {number|Long|null} [startTimeMillis] Action startTimeMillis
                     * @property {number|Long|null} [endTimeMillis] Action endTimeMillis
                     */
    
                    /**
                     * Constructs a new Action.
                     * @memberof infinitusai.be.CallState
                     * @classdesc Represents an Action.
                     * @implements IAction
                     * @constructor
                     * @param {infinitusai.be.CallState.IAction=} [properties] Properties to set
                     */
                    function Action(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Action actionName.
                     * @member {string} actionName
                     * @memberof infinitusai.be.CallState.Action
                     * @instance
                     */
                    Action.prototype.actionName = "";
    
                    /**
                     * Action speechUtterance.
                     * @member {string|null|undefined} speechUtterance
                     * @memberof infinitusai.be.CallState.Action
                     * @instance
                     */
                    Action.prototype.speechUtterance = null;
    
                    /**
                     * Action dtmfDigits.
                     * @member {string|null|undefined} dtmfDigits
                     * @memberof infinitusai.be.CallState.Action
                     * @instance
                     */
                    Action.prototype.dtmfDigits = null;
    
                    /**
                     * Action actionSource.
                     * @member {infinitusai.be.ActionSource} actionSource
                     * @memberof infinitusai.be.CallState.Action
                     * @instance
                     */
                    Action.prototype.actionSource = 0;
    
                    /**
                     * Action startTimeMillis.
                     * @member {number|Long} startTimeMillis
                     * @memberof infinitusai.be.CallState.Action
                     * @instance
                     */
                    Action.prototype.startTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Action endTimeMillis.
                     * @member {number|Long} endTimeMillis
                     * @memberof infinitusai.be.CallState.Action
                     * @instance
                     */
                    Action.prototype.endTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * Action action.
                     * @member {"speechUtterance"|"dtmfDigits"|undefined} action
                     * @memberof infinitusai.be.CallState.Action
                     * @instance
                     */
                    Object.defineProperty(Action.prototype, "action", {
                        get: $util.oneOfGetter($oneOfFields = ["speechUtterance", "dtmfDigits"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new Action instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {infinitusai.be.CallState.IAction=} [properties] Properties to set
                     * @returns {infinitusai.be.CallState.Action} Action instance
                     */
                    Action.create = function create(properties) {
                        return new Action(properties);
                    };
    
                    /**
                     * Encodes the specified Action message. Does not implicitly {@link infinitusai.be.CallState.Action.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {infinitusai.be.CallState.IAction} message Action message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Action.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.actionName);
                        if (message.speechUtterance != null && Object.hasOwnProperty.call(message, "speechUtterance"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.speechUtterance);
                        if (message.dtmfDigits != null && Object.hasOwnProperty.call(message, "dtmfDigits"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dtmfDigits);
                        if (message.actionSource != null && Object.hasOwnProperty.call(message, "actionSource"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.actionSource);
                        if (message.startTimeMillis != null && Object.hasOwnProperty.call(message, "startTimeMillis"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.startTimeMillis);
                        if (message.endTimeMillis != null && Object.hasOwnProperty.call(message, "endTimeMillis"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.endTimeMillis);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Action message, length delimited. Does not implicitly {@link infinitusai.be.CallState.Action.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {infinitusai.be.CallState.IAction} message Action message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Action.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Action message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CallState.Action} Action
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Action.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallState.Action();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.actionName = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.speechUtterance = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.dtmfDigits = reader.string();
                                    break;
                                }
                            case 4: {
                                    message.actionSource = reader.int32();
                                    break;
                                }
                            case 5: {
                                    message.startTimeMillis = reader.int64();
                                    break;
                                }
                            case 6: {
                                    message.endTimeMillis = reader.int64();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Action message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CallState.Action} Action
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Action.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Action message.
                     * @function verify
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Action.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.actionName != null && message.hasOwnProperty("actionName"))
                            if (!$util.isString(message.actionName))
                                return "actionName: string expected";
                        if (message.speechUtterance != null && message.hasOwnProperty("speechUtterance")) {
                            properties.action = 1;
                            if (!$util.isString(message.speechUtterance))
                                return "speechUtterance: string expected";
                        }
                        if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits")) {
                            if (properties.action === 1)
                                return "action: multiple values";
                            properties.action = 1;
                            if (!$util.isString(message.dtmfDigits))
                                return "dtmfDigits: string expected";
                        }
                        if (message.actionSource != null && message.hasOwnProperty("actionSource"))
                            switch (message.actionSource) {
                            default:
                                return "actionSource: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                                break;
                            }
                        if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                            if (!$util.isInteger(message.startTimeMillis) && !(message.startTimeMillis && $util.isInteger(message.startTimeMillis.low) && $util.isInteger(message.startTimeMillis.high)))
                                return "startTimeMillis: integer|Long expected";
                        if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                            if (!$util.isInteger(message.endTimeMillis) && !(message.endTimeMillis && $util.isInteger(message.endTimeMillis.low) && $util.isInteger(message.endTimeMillis.high)))
                                return "endTimeMillis: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an Action message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CallState.Action} Action
                     */
                    Action.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CallState.Action)
                            return object;
                        var message = new $root.infinitusai.be.CallState.Action();
                        if (object.actionName != null)
                            message.actionName = String(object.actionName);
                        if (object.speechUtterance != null)
                            message.speechUtterance = String(object.speechUtterance);
                        if (object.dtmfDigits != null)
                            message.dtmfDigits = String(object.dtmfDigits);
                        switch (object.actionSource) {
                        default:
                            if (typeof object.actionSource === "number") {
                                message.actionSource = object.actionSource;
                                break;
                            }
                            break;
                        case "ACTION_SOURCE_UNKNOWN":
                        case 0:
                            message.actionSource = 0;
                            break;
                        case "ACTION_SOURCE_KEYBOARD":
                        case 1:
                            message.actionSource = 1;
                            break;
                        case "ACTION_SOURCE_TOP_SUGGESTION":
                        case 2:
                            message.actionSource = 2;
                            break;
                        case "ACTION_SOURCE_SUGGESTION":
                        case 3:
                            message.actionSource = 3;
                            break;
                        case "ACTION_SOURCE_AUTO_RESPOND":
                        case 4:
                            message.actionSource = 4;
                            break;
                        case "ACTION_SOURCE_FOLLOWUP_SUGGESTION":
                        case 5:
                            message.actionSource = 5;
                            break;
                        case "ACTION_SOURCE_RECORDED_AUDIO":
                        case 6:
                            message.actionSource = 6;
                            break;
                        }
                        if (object.startTimeMillis != null)
                            if ($util.Long)
                                (message.startTimeMillis = $util.Long.fromValue(object.startTimeMillis)).unsigned = false;
                            else if (typeof object.startTimeMillis === "string")
                                message.startTimeMillis = parseInt(object.startTimeMillis, 10);
                            else if (typeof object.startTimeMillis === "number")
                                message.startTimeMillis = object.startTimeMillis;
                            else if (typeof object.startTimeMillis === "object")
                                message.startTimeMillis = new $util.LongBits(object.startTimeMillis.low >>> 0, object.startTimeMillis.high >>> 0).toNumber();
                        if (object.endTimeMillis != null)
                            if ($util.Long)
                                (message.endTimeMillis = $util.Long.fromValue(object.endTimeMillis)).unsigned = false;
                            else if (typeof object.endTimeMillis === "string")
                                message.endTimeMillis = parseInt(object.endTimeMillis, 10);
                            else if (typeof object.endTimeMillis === "number")
                                message.endTimeMillis = object.endTimeMillis;
                            else if (typeof object.endTimeMillis === "object")
                                message.endTimeMillis = new $util.LongBits(object.endTimeMillis.low >>> 0, object.endTimeMillis.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Action message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {infinitusai.be.CallState.Action} message Action
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Action.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.actionName = "";
                            object.actionSource = options.enums === String ? "ACTION_SOURCE_UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.startTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.startTimeMillis = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.endTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.endTimeMillis = options.longs === String ? "0" : 0;
                        }
                        if (message.actionName != null && message.hasOwnProperty("actionName"))
                            object.actionName = message.actionName;
                        if (message.speechUtterance != null && message.hasOwnProperty("speechUtterance")) {
                            object.speechUtterance = message.speechUtterance;
                            if (options.oneofs)
                                object.action = "speechUtterance";
                        }
                        if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits")) {
                            object.dtmfDigits = message.dtmfDigits;
                            if (options.oneofs)
                                object.action = "dtmfDigits";
                        }
                        if (message.actionSource != null && message.hasOwnProperty("actionSource"))
                            object.actionSource = options.enums === String ? $root.infinitusai.be.ActionSource[message.actionSource] === undefined ? message.actionSource : $root.infinitusai.be.ActionSource[message.actionSource] : message.actionSource;
                        if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                            if (typeof message.startTimeMillis === "number")
                                object.startTimeMillis = options.longs === String ? String(message.startTimeMillis) : message.startTimeMillis;
                            else
                                object.startTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMillis) : options.longs === Number ? new $util.LongBits(message.startTimeMillis.low >>> 0, message.startTimeMillis.high >>> 0).toNumber() : message.startTimeMillis;
                        if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                            if (typeof message.endTimeMillis === "number")
                                object.endTimeMillis = options.longs === String ? String(message.endTimeMillis) : message.endTimeMillis;
                            else
                                object.endTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMillis) : options.longs === Number ? new $util.LongBits(message.endTimeMillis.low >>> 0, message.endTimeMillis.high >>> 0).toNumber() : message.endTimeMillis;
                        return object;
                    };
    
                    /**
                     * Converts this Action to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CallState.Action
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Action.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Action
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CallState.Action
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Action.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CallState.Action";
                    };
    
                    return Action;
                })();
    
                CallState.ConversationState = (function() {
    
                    /**
                     * Properties of a ConversationState.
                     * @memberof infinitusai.be.CallState
                     * @interface IConversationState
                     * @property {Object.<string,string>|null} [params] ConversationState params
                     * @property {Object.<string,string>|null} [frame] ConversationState frame
                     */
    
                    /**
                     * Constructs a new ConversationState.
                     * @memberof infinitusai.be.CallState
                     * @classdesc Represents a ConversationState.
                     * @implements IConversationState
                     * @constructor
                     * @param {infinitusai.be.CallState.IConversationState=} [properties] Properties to set
                     */
                    function ConversationState(properties) {
                        this.params = {};
                        this.frame = {};
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ConversationState params.
                     * @member {Object.<string,string>} params
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @instance
                     */
                    ConversationState.prototype.params = $util.emptyObject;
    
                    /**
                     * ConversationState frame.
                     * @member {Object.<string,string>} frame
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @instance
                     */
                    ConversationState.prototype.frame = $util.emptyObject;
    
                    /**
                     * Creates a new ConversationState instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {infinitusai.be.CallState.IConversationState=} [properties] Properties to set
                     * @returns {infinitusai.be.CallState.ConversationState} ConversationState instance
                     */
                    ConversationState.create = function create(properties) {
                        return new ConversationState(properties);
                    };
    
                    /**
                     * Encodes the specified ConversationState message. Does not implicitly {@link infinitusai.be.CallState.ConversationState.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {infinitusai.be.CallState.IConversationState} message ConversationState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConversationState.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                            for (var keys = Object.keys(message.params), i = 0; i < keys.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.params[keys[i]]).ldelim();
                        if (message.frame != null && Object.hasOwnProperty.call(message, "frame"))
                            for (var keys = Object.keys(message.frame), i = 0; i < keys.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.frame[keys[i]]).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ConversationState message, length delimited. Does not implicitly {@link infinitusai.be.CallState.ConversationState.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {infinitusai.be.CallState.IConversationState} message ConversationState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConversationState.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ConversationState message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CallState.ConversationState} ConversationState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConversationState.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallState.ConversationState(), key, value;
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (message.params === $util.emptyObject)
                                        message.params = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.params[key] = value;
                                    break;
                                }
                            case 2: {
                                    if (message.frame === $util.emptyObject)
                                        message.frame = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.frame[key] = value;
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ConversationState message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CallState.ConversationState} ConversationState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConversationState.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ConversationState message.
                     * @function verify
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ConversationState.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.params != null && message.hasOwnProperty("params")) {
                            if (!$util.isObject(message.params))
                                return "params: object expected";
                            var key = Object.keys(message.params);
                            for (var i = 0; i < key.length; ++i)
                                if (!$util.isString(message.params[key[i]]))
                                    return "params: string{k:string} expected";
                        }
                        if (message.frame != null && message.hasOwnProperty("frame")) {
                            if (!$util.isObject(message.frame))
                                return "frame: object expected";
                            var key = Object.keys(message.frame);
                            for (var i = 0; i < key.length; ++i)
                                if (!$util.isString(message.frame[key[i]]))
                                    return "frame: string{k:string} expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ConversationState message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CallState.ConversationState} ConversationState
                     */
                    ConversationState.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CallState.ConversationState)
                            return object;
                        var message = new $root.infinitusai.be.CallState.ConversationState();
                        if (object.params) {
                            if (typeof object.params !== "object")
                                throw TypeError(".infinitusai.be.CallState.ConversationState.params: object expected");
                            message.params = {};
                            for (var keys = Object.keys(object.params), i = 0; i < keys.length; ++i)
                                message.params[keys[i]] = String(object.params[keys[i]]);
                        }
                        if (object.frame) {
                            if (typeof object.frame !== "object")
                                throw TypeError(".infinitusai.be.CallState.ConversationState.frame: object expected");
                            message.frame = {};
                            for (var keys = Object.keys(object.frame), i = 0; i < keys.length; ++i)
                                message.frame[keys[i]] = String(object.frame[keys[i]]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ConversationState message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {infinitusai.be.CallState.ConversationState} message ConversationState
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ConversationState.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.objects || options.defaults) {
                            object.params = {};
                            object.frame = {};
                        }
                        var keys2;
                        if (message.params && (keys2 = Object.keys(message.params)).length) {
                            object.params = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.params[keys2[j]] = message.params[keys2[j]];
                        }
                        if (message.frame && (keys2 = Object.keys(message.frame)).length) {
                            object.frame = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.frame[keys2[j]] = message.frame[keys2[j]];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ConversationState to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ConversationState.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for ConversationState
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CallState.ConversationState
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ConversationState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CallState.ConversationState";
                    };
    
                    return ConversationState;
                })();
    
                CallState.PreviousOutput = (function() {
    
                    /**
                     * Properties of a PreviousOutput.
                     * @memberof infinitusai.be.CallState
                     * @interface IPreviousOutput
                     * @property {infinitusapi.IINFBVTaskOutput|null} [bvTaskOutput] PreviousOutput bvTaskOutput
                     * @property {infinitusapi.IINFPBMTaskOutput|null} [pbmTaskOutput] PreviousOutput pbmTaskOutput
                     */
    
                    /**
                     * Constructs a new PreviousOutput.
                     * @memberof infinitusai.be.CallState
                     * @classdesc Represents a PreviousOutput.
                     * @implements IPreviousOutput
                     * @constructor
                     * @param {infinitusai.be.CallState.IPreviousOutput=} [properties] Properties to set
                     */
                    function PreviousOutput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PreviousOutput bvTaskOutput.
                     * @member {infinitusapi.IINFBVTaskOutput|null|undefined} bvTaskOutput
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @instance
                     */
                    PreviousOutput.prototype.bvTaskOutput = null;
    
                    /**
                     * PreviousOutput pbmTaskOutput.
                     * @member {infinitusapi.IINFPBMTaskOutput|null|undefined} pbmTaskOutput
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @instance
                     */
                    PreviousOutput.prototype.pbmTaskOutput = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * PreviousOutput output.
                     * @member {"bvTaskOutput"|"pbmTaskOutput"|undefined} output
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @instance
                     */
                    Object.defineProperty(PreviousOutput.prototype, "output", {
                        get: $util.oneOfGetter($oneOfFields = ["bvTaskOutput", "pbmTaskOutput"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new PreviousOutput instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {infinitusai.be.CallState.IPreviousOutput=} [properties] Properties to set
                     * @returns {infinitusai.be.CallState.PreviousOutput} PreviousOutput instance
                     */
                    PreviousOutput.create = function create(properties) {
                        return new PreviousOutput(properties);
                    };
    
                    /**
                     * Encodes the specified PreviousOutput message. Does not implicitly {@link infinitusai.be.CallState.PreviousOutput.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {infinitusai.be.CallState.IPreviousOutput} message PreviousOutput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreviousOutput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.bvTaskOutput != null && Object.hasOwnProperty.call(message, "bvTaskOutput"))
                            $root.infinitusapi.INFBVTaskOutput.encode(message.bvTaskOutput, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pbmTaskOutput != null && Object.hasOwnProperty.call(message, "pbmTaskOutput"))
                            $root.infinitusapi.INFPBMTaskOutput.encode(message.pbmTaskOutput, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PreviousOutput message, length delimited. Does not implicitly {@link infinitusai.be.CallState.PreviousOutput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {infinitusai.be.CallState.IPreviousOutput} message PreviousOutput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreviousOutput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PreviousOutput message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CallState.PreviousOutput} PreviousOutput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreviousOutput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallState.PreviousOutput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    message.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PreviousOutput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CallState.PreviousOutput} PreviousOutput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreviousOutput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PreviousOutput message.
                     * @function verify
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PreviousOutput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.bvTaskOutput != null && message.hasOwnProperty("bvTaskOutput")) {
                            properties.output = 1;
                            {
                                var error = $root.infinitusapi.INFBVTaskOutput.verify(message.bvTaskOutput);
                                if (error)
                                    return "bvTaskOutput." + error;
                            }
                        }
                        if (message.pbmTaskOutput != null && message.hasOwnProperty("pbmTaskOutput")) {
                            if (properties.output === 1)
                                return "output: multiple values";
                            properties.output = 1;
                            {
                                var error = $root.infinitusapi.INFPBMTaskOutput.verify(message.pbmTaskOutput);
                                if (error)
                                    return "pbmTaskOutput." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PreviousOutput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CallState.PreviousOutput} PreviousOutput
                     */
                    PreviousOutput.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CallState.PreviousOutput)
                            return object;
                        var message = new $root.infinitusai.be.CallState.PreviousOutput();
                        if (object.bvTaskOutput != null) {
                            if (typeof object.bvTaskOutput !== "object")
                                throw TypeError(".infinitusai.be.CallState.PreviousOutput.bvTaskOutput: object expected");
                            message.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.fromObject(object.bvTaskOutput);
                        }
                        if (object.pbmTaskOutput != null) {
                            if (typeof object.pbmTaskOutput !== "object")
                                throw TypeError(".infinitusai.be.CallState.PreviousOutput.pbmTaskOutput: object expected");
                            message.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.fromObject(object.pbmTaskOutput);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PreviousOutput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {infinitusai.be.CallState.PreviousOutput} message PreviousOutput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PreviousOutput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.bvTaskOutput != null && message.hasOwnProperty("bvTaskOutput")) {
                            object.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.toObject(message.bvTaskOutput, options);
                            if (options.oneofs)
                                object.output = "bvTaskOutput";
                        }
                        if (message.pbmTaskOutput != null && message.hasOwnProperty("pbmTaskOutput")) {
                            object.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.toObject(message.pbmTaskOutput, options);
                            if (options.oneofs)
                                object.output = "pbmTaskOutput";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PreviousOutput to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PreviousOutput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for PreviousOutput
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CallState.PreviousOutput
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    PreviousOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CallState.PreviousOutput";
                    };
    
                    return PreviousOutput;
                })();
    
                /**
                 * HangupSource enum.
                 * @name infinitusai.be.CallState.HangupSource
                 * @enum {number}
                 * @property {number} HANGUP_SOURCE_UNKNOWN=0 HANGUP_SOURCE_UNKNOWN value
                 * @property {number} HANGUP_SOURCE_TIMEOUT=1 HANGUP_SOURCE_TIMEOUT value
                 */
                CallState.HangupSource = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "HANGUP_SOURCE_UNKNOWN"] = 0;
                    values[valuesById[1] = "HANGUP_SOURCE_TIMEOUT"] = 1;
                    return values;
                })();
    
                return CallState;
            })();
    
            be.CallSummary = (function() {
    
                /**
                 * Properties of a CallSummary.
                 * @memberof infinitusai.be
                 * @interface ICallSummary
                 * @property {boolean|null} [hasSummary] CallSummary hasSummary
                 * @property {string|null} [summary] CallSummary summary
                 */
    
                /**
                 * Constructs a new CallSummary.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallSummary.
                 * @implements ICallSummary
                 * @constructor
                 * @param {infinitusai.be.ICallSummary=} [properties] Properties to set
                 */
                function CallSummary(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallSummary hasSummary.
                 * @member {boolean} hasSummary
                 * @memberof infinitusai.be.CallSummary
                 * @instance
                 */
                CallSummary.prototype.hasSummary = false;
    
                /**
                 * CallSummary summary.
                 * @member {string} summary
                 * @memberof infinitusai.be.CallSummary
                 * @instance
                 */
                CallSummary.prototype.summary = "";
    
                /**
                 * Creates a new CallSummary instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {infinitusai.be.ICallSummary=} [properties] Properties to set
                 * @returns {infinitusai.be.CallSummary} CallSummary instance
                 */
                CallSummary.create = function create(properties) {
                    return new CallSummary(properties);
                };
    
                /**
                 * Encodes the specified CallSummary message. Does not implicitly {@link infinitusai.be.CallSummary.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {infinitusai.be.ICallSummary} message CallSummary message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallSummary.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hasSummary != null && Object.hasOwnProperty.call(message, "hasSummary"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasSummary);
                    if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.summary);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallSummary message, length delimited. Does not implicitly {@link infinitusai.be.CallSummary.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {infinitusai.be.ICallSummary} message CallSummary message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallSummary.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallSummary message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallSummary} CallSummary
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallSummary.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallSummary();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hasSummary = reader.bool();
                                break;
                            }
                        case 2: {
                                message.summary = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallSummary message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallSummary} CallSummary
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallSummary.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallSummary message.
                 * @function verify
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallSummary.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hasSummary != null && message.hasOwnProperty("hasSummary"))
                        if (typeof message.hasSummary !== "boolean")
                            return "hasSummary: boolean expected";
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        if (!$util.isString(message.summary))
                            return "summary: string expected";
                    return null;
                };
    
                /**
                 * Creates a CallSummary message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallSummary} CallSummary
                 */
                CallSummary.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallSummary)
                        return object;
                    var message = new $root.infinitusai.be.CallSummary();
                    if (object.hasSummary != null)
                        message.hasSummary = Boolean(object.hasSummary);
                    if (object.summary != null)
                        message.summary = String(object.summary);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallSummary message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {infinitusai.be.CallSummary} message CallSummary
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallSummary.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hasSummary = false;
                        object.summary = "";
                    }
                    if (message.hasSummary != null && message.hasOwnProperty("hasSummary"))
                        object.hasSummary = message.hasSummary;
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = message.summary;
                    return object;
                };
    
                /**
                 * Converts this CallSummary to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallSummary
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallSummary.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallSummary
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallSummary
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallSummary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallSummary";
                };
    
                return CallSummary;
            })();
    
            be.GetCapacityDocsResponse = (function() {
    
                /**
                 * Properties of a GetCapacityDocsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetCapacityDocsResponse
                 * @property {Array.<infinitusai.be.ICapacityDoc>|null} [capacityDocs] GetCapacityDocsResponse capacityDocs
                 */
    
                /**
                 * Constructs a new GetCapacityDocsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCapacityDocsResponse.
                 * @implements IGetCapacityDocsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetCapacityDocsResponse=} [properties] Properties to set
                 */
                function GetCapacityDocsResponse(properties) {
                    this.capacityDocs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCapacityDocsResponse capacityDocs.
                 * @member {Array.<infinitusai.be.ICapacityDoc>} capacityDocs
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @instance
                 */
                GetCapacityDocsResponse.prototype.capacityDocs = $util.emptyArray;
    
                /**
                 * Creates a new GetCapacityDocsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {infinitusai.be.IGetCapacityDocsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCapacityDocsResponse} GetCapacityDocsResponse instance
                 */
                GetCapacityDocsResponse.create = function create(properties) {
                    return new GetCapacityDocsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCapacityDocsResponse message. Does not implicitly {@link infinitusai.be.GetCapacityDocsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {infinitusai.be.IGetCapacityDocsResponse} message GetCapacityDocsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCapacityDocsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.capacityDocs != null && message.capacityDocs.length)
                        for (var i = 0; i < message.capacityDocs.length; ++i)
                            $root.infinitusai.be.CapacityDoc.encode(message.capacityDocs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCapacityDocsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetCapacityDocsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {infinitusai.be.IGetCapacityDocsResponse} message GetCapacityDocsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCapacityDocsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCapacityDocsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCapacityDocsResponse} GetCapacityDocsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCapacityDocsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCapacityDocsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.capacityDocs && message.capacityDocs.length))
                                    message.capacityDocs = [];
                                message.capacityDocs.push($root.infinitusai.be.CapacityDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCapacityDocsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCapacityDocsResponse} GetCapacityDocsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCapacityDocsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCapacityDocsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCapacityDocsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.capacityDocs != null && message.hasOwnProperty("capacityDocs")) {
                        if (!Array.isArray(message.capacityDocs))
                            return "capacityDocs: array expected";
                        for (var i = 0; i < message.capacityDocs.length; ++i) {
                            var error = $root.infinitusai.be.CapacityDoc.verify(message.capacityDocs[i]);
                            if (error)
                                return "capacityDocs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetCapacityDocsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCapacityDocsResponse} GetCapacityDocsResponse
                 */
                GetCapacityDocsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCapacityDocsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetCapacityDocsResponse();
                    if (object.capacityDocs) {
                        if (!Array.isArray(object.capacityDocs))
                            throw TypeError(".infinitusai.be.GetCapacityDocsResponse.capacityDocs: array expected");
                        message.capacityDocs = [];
                        for (var i = 0; i < object.capacityDocs.length; ++i) {
                            if (typeof object.capacityDocs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetCapacityDocsResponse.capacityDocs: object expected");
                            message.capacityDocs[i] = $root.infinitusai.be.CapacityDoc.fromObject(object.capacityDocs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCapacityDocsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {infinitusai.be.GetCapacityDocsResponse} message GetCapacityDocsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCapacityDocsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.capacityDocs = [];
                    if (message.capacityDocs && message.capacityDocs.length) {
                        object.capacityDocs = [];
                        for (var j = 0; j < message.capacityDocs.length; ++j)
                            object.capacityDocs[j] = $root.infinitusai.be.CapacityDoc.toObject(message.capacityDocs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetCapacityDocsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCapacityDocsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCapacityDocsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCapacityDocsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCapacityDocsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCapacityDocsResponse";
                };
    
                return GetCapacityDocsResponse;
            })();
    
            be.CapacityDoc = (function() {
    
                /**
                 * Properties of a CapacityDoc.
                 * @memberof infinitusai.be
                 * @interface ICapacityDoc
                 * @property {string|null} [orgUuid] CapacityDoc orgUuid
                 * @property {string|null} [programName] CapacityDoc programName
                 * @property {Object.<string,infinitusai.be.CapacityDoc.IBucket>|null} [buckets] CapacityDoc buckets
                 */
    
                /**
                 * Constructs a new CapacityDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CapacityDoc.
                 * @implements ICapacityDoc
                 * @constructor
                 * @param {infinitusai.be.ICapacityDoc=} [properties] Properties to set
                 */
                function CapacityDoc(properties) {
                    this.buckets = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CapacityDoc orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CapacityDoc
                 * @instance
                 */
                CapacityDoc.prototype.orgUuid = "";
    
                /**
                 * CapacityDoc programName.
                 * @member {string} programName
                 * @memberof infinitusai.be.CapacityDoc
                 * @instance
                 */
                CapacityDoc.prototype.programName = "";
    
                /**
                 * CapacityDoc buckets.
                 * @member {Object.<string,infinitusai.be.CapacityDoc.IBucket>} buckets
                 * @memberof infinitusai.be.CapacityDoc
                 * @instance
                 */
                CapacityDoc.prototype.buckets = $util.emptyObject;
    
                /**
                 * Creates a new CapacityDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {infinitusai.be.ICapacityDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.CapacityDoc} CapacityDoc instance
                 */
                CapacityDoc.create = function create(properties) {
                    return new CapacityDoc(properties);
                };
    
                /**
                 * Encodes the specified CapacityDoc message. Does not implicitly {@link infinitusai.be.CapacityDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {infinitusai.be.ICapacityDoc} message CapacityDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CapacityDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.programName != null && Object.hasOwnProperty.call(message, "programName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.programName);
                    if (message.buckets != null && Object.hasOwnProperty.call(message, "buckets"))
                        for (var keys = Object.keys(message.buckets), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.CapacityDoc.Bucket.encode(message.buckets[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified CapacityDoc message, length delimited. Does not implicitly {@link infinitusai.be.CapacityDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {infinitusai.be.ICapacityDoc} message CapacityDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CapacityDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CapacityDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CapacityDoc} CapacityDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CapacityDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CapacityDoc(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.programName = reader.string();
                                break;
                            }
                        case 3: {
                                if (message.buckets === $util.emptyObject)
                                    message.buckets = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.CapacityDoc.Bucket.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.buckets[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CapacityDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CapacityDoc} CapacityDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CapacityDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CapacityDoc message.
                 * @function verify
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CapacityDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        if (!$util.isString(message.programName))
                            return "programName: string expected";
                    if (message.buckets != null && message.hasOwnProperty("buckets")) {
                        if (!$util.isObject(message.buckets))
                            return "buckets: object expected";
                        var key = Object.keys(message.buckets);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.CapacityDoc.Bucket.verify(message.buckets[key[i]]);
                            if (error)
                                return "buckets." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a CapacityDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CapacityDoc} CapacityDoc
                 */
                CapacityDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CapacityDoc)
                        return object;
                    var message = new $root.infinitusai.be.CapacityDoc();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.programName != null)
                        message.programName = String(object.programName);
                    if (object.buckets) {
                        if (typeof object.buckets !== "object")
                            throw TypeError(".infinitusai.be.CapacityDoc.buckets: object expected");
                        message.buckets = {};
                        for (var keys = Object.keys(object.buckets), i = 0; i < keys.length; ++i) {
                            if (typeof object.buckets[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.CapacityDoc.buckets: object expected");
                            message.buckets[keys[i]] = $root.infinitusai.be.CapacityDoc.Bucket.fromObject(object.buckets[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CapacityDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {infinitusai.be.CapacityDoc} message CapacityDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CapacityDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.buckets = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.programName = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.programName != null && message.hasOwnProperty("programName"))
                        object.programName = message.programName;
                    var keys2;
                    if (message.buckets && (keys2 = Object.keys(message.buckets)).length) {
                        object.buckets = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.buckets[keys2[j]] = $root.infinitusai.be.CapacityDoc.Bucket.toObject(message.buckets[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this CapacityDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CapacityDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CapacityDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CapacityDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CapacityDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CapacityDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CapacityDoc";
                };
    
                CapacityDoc.Bucket = (function() {
    
                    /**
                     * Properties of a Bucket.
                     * @memberof infinitusai.be.CapacityDoc
                     * @interface IBucket
                     * @property {string|null} [assignedDate] Bucket assignedDate
                     * @property {Array.<infinitusai.be.CapacityDoc.Bucket.ITask>|null} [tasks] Bucket tasks
                     */
    
                    /**
                     * Constructs a new Bucket.
                     * @memberof infinitusai.be.CapacityDoc
                     * @classdesc Represents a Bucket.
                     * @implements IBucket
                     * @constructor
                     * @param {infinitusai.be.CapacityDoc.IBucket=} [properties] Properties to set
                     */
                    function Bucket(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Bucket assignedDate.
                     * @member {string} assignedDate
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @instance
                     */
                    Bucket.prototype.assignedDate = "";
    
                    /**
                     * Bucket tasks.
                     * @member {Array.<infinitusai.be.CapacityDoc.Bucket.ITask>} tasks
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @instance
                     */
                    Bucket.prototype.tasks = $util.emptyArray;
    
                    /**
                     * Creates a new Bucket instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {infinitusai.be.CapacityDoc.IBucket=} [properties] Properties to set
                     * @returns {infinitusai.be.CapacityDoc.Bucket} Bucket instance
                     */
                    Bucket.create = function create(properties) {
                        return new Bucket(properties);
                    };
    
                    /**
                     * Encodes the specified Bucket message. Does not implicitly {@link infinitusai.be.CapacityDoc.Bucket.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {infinitusai.be.CapacityDoc.IBucket} message Bucket message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Bucket.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.assignedDate != null && Object.hasOwnProperty.call(message, "assignedDate"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.assignedDate);
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.infinitusai.be.CapacityDoc.Bucket.Task.encode(message.tasks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Bucket message, length delimited. Does not implicitly {@link infinitusai.be.CapacityDoc.Bucket.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {infinitusai.be.CapacityDoc.IBucket} message Bucket message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Bucket.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Bucket message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CapacityDoc.Bucket} Bucket
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Bucket.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CapacityDoc.Bucket();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.assignedDate = reader.string();
                                    break;
                                }
                            case 2: {
                                    if (!(message.tasks && message.tasks.length))
                                        message.tasks = [];
                                    message.tasks.push($root.infinitusai.be.CapacityDoc.Bucket.Task.decode(reader, reader.uint32()));
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Bucket message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CapacityDoc.Bucket} Bucket
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Bucket.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Bucket message.
                     * @function verify
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Bucket.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.assignedDate != null && message.hasOwnProperty("assignedDate"))
                            if (!$util.isString(message.assignedDate))
                                return "assignedDate: string expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.infinitusai.be.CapacityDoc.Bucket.Task.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Bucket message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CapacityDoc.Bucket} Bucket
                     */
                    Bucket.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CapacityDoc.Bucket)
                            return object;
                        var message = new $root.infinitusai.be.CapacityDoc.Bucket();
                        if (object.assignedDate != null)
                            message.assignedDate = String(object.assignedDate);
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".infinitusai.be.CapacityDoc.Bucket.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".infinitusai.be.CapacityDoc.Bucket.tasks: object expected");
                                message.tasks[i] = $root.infinitusai.be.CapacityDoc.Bucket.Task.fromObject(object.tasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Bucket message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {infinitusai.be.CapacityDoc.Bucket} message Bucket
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Bucket.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (options.defaults)
                            object.assignedDate = "";
                        if (message.assignedDate != null && message.hasOwnProperty("assignedDate"))
                            object.assignedDate = message.assignedDate;
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.infinitusai.be.CapacityDoc.Bucket.Task.toObject(message.tasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Bucket to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Bucket.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Bucket
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CapacityDoc.Bucket
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Bucket.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CapacityDoc.Bucket";
                    };
    
                    Bucket.Task = (function() {
    
                        /**
                         * Properties of a Task.
                         * @memberof infinitusai.be.CapacityDoc.Bucket
                         * @interface ITask
                         * @property {string|null} [taskUuid] Task taskUuid
                         * @property {number|Long|null} [dueDateMillis] Task dueDateMillis
                         */
    
                        /**
                         * Constructs a new Task.
                         * @memberof infinitusai.be.CapacityDoc.Bucket
                         * @classdesc Represents a Task.
                         * @implements ITask
                         * @constructor
                         * @param {infinitusai.be.CapacityDoc.Bucket.ITask=} [properties] Properties to set
                         */
                        function Task(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Task taskUuid.
                         * @member {string} taskUuid
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @instance
                         */
                        Task.prototype.taskUuid = "";
    
                        /**
                         * Task dueDateMillis.
                         * @member {number|Long} dueDateMillis
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @instance
                         */
                        Task.prototype.dueDateMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * Creates a new Task instance using the specified properties.
                         * @function create
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {infinitusai.be.CapacityDoc.Bucket.ITask=} [properties] Properties to set
                         * @returns {infinitusai.be.CapacityDoc.Bucket.Task} Task instance
                         */
                        Task.create = function create(properties) {
                            return new Task(properties);
                        };
    
                        /**
                         * Encodes the specified Task message. Does not implicitly {@link infinitusai.be.CapacityDoc.Bucket.Task.verify|verify} messages.
                         * @function encode
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {infinitusai.be.CapacityDoc.Bucket.ITask} message Task message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Task.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                            if (message.dueDateMillis != null && Object.hasOwnProperty.call(message, "dueDateMillis"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.dueDateMillis);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Task message, length delimited. Does not implicitly {@link infinitusai.be.CapacityDoc.Bucket.Task.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {infinitusai.be.CapacityDoc.Bucket.ITask} message Task message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Task.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a Task message from the specified reader or buffer.
                         * @function decode
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {infinitusai.be.CapacityDoc.Bucket.Task} Task
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Task.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CapacityDoc.Bucket.Task();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.taskUuid = reader.string();
                                        break;
                                    }
                                case 2: {
                                        message.dueDateMillis = reader.int64();
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a Task message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {infinitusai.be.CapacityDoc.Bucket.Task} Task
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Task.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a Task message.
                         * @function verify
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Task.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                                if (!$util.isString(message.taskUuid))
                                    return "taskUuid: string expected";
                            if (message.dueDateMillis != null && message.hasOwnProperty("dueDateMillis"))
                                if (!$util.isInteger(message.dueDateMillis) && !(message.dueDateMillis && $util.isInteger(message.dueDateMillis.low) && $util.isInteger(message.dueDateMillis.high)))
                                    return "dueDateMillis: integer|Long expected";
                            return null;
                        };
    
                        /**
                         * Creates a Task message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {infinitusai.be.CapacityDoc.Bucket.Task} Task
                         */
                        Task.fromObject = function fromObject(object) {
                            if (object instanceof $root.infinitusai.be.CapacityDoc.Bucket.Task)
                                return object;
                            var message = new $root.infinitusai.be.CapacityDoc.Bucket.Task();
                            if (object.taskUuid != null)
                                message.taskUuid = String(object.taskUuid);
                            if (object.dueDateMillis != null)
                                if ($util.Long)
                                    (message.dueDateMillis = $util.Long.fromValue(object.dueDateMillis)).unsigned = false;
                                else if (typeof object.dueDateMillis === "string")
                                    message.dueDateMillis = parseInt(object.dueDateMillis, 10);
                                else if (typeof object.dueDateMillis === "number")
                                    message.dueDateMillis = object.dueDateMillis;
                                else if (typeof object.dueDateMillis === "object")
                                    message.dueDateMillis = new $util.LongBits(object.dueDateMillis.low >>> 0, object.dueDateMillis.high >>> 0).toNumber();
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a Task message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {infinitusai.be.CapacityDoc.Bucket.Task} message Task
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Task.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.taskUuid = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.dueDateMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.dueDateMillis = options.longs === String ? "0" : 0;
                            }
                            if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                                object.taskUuid = message.taskUuid;
                            if (message.dueDateMillis != null && message.hasOwnProperty("dueDateMillis"))
                                if (typeof message.dueDateMillis === "number")
                                    object.dueDateMillis = options.longs === String ? String(message.dueDateMillis) : message.dueDateMillis;
                                else
                                    object.dueDateMillis = options.longs === String ? $util.Long.prototype.toString.call(message.dueDateMillis) : options.longs === Number ? new $util.LongBits(message.dueDateMillis.low >>> 0, message.dueDateMillis.high >>> 0).toNumber() : message.dueDateMillis;
                            return object;
                        };
    
                        /**
                         * Converts this Task to JSON.
                         * @function toJSON
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Task.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        /**
                         * Gets the default type url for Task
                         * @function getTypeUrl
                         * @memberof infinitusai.be.CapacityDoc.Bucket.Task
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        Task.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/infinitusai.be.CapacityDoc.Bucket.Task";
                        };
    
                        return Task;
                    })();
    
                    return Bucket;
                })();
    
                return CapacityDoc;
            })();
    
            be.Calendar = (function() {
    
                /**
                 * Properties of a Calendar.
                 * @memberof infinitusai.be
                 * @interface ICalendar
                 * @property {Object.<string,infinitusai.be.Calendar.IHoliday>|null} [holidays] Calendar holidays
                 */
    
                /**
                 * Constructs a new Calendar.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Calendar.
                 * @implements ICalendar
                 * @constructor
                 * @param {infinitusai.be.ICalendar=} [properties] Properties to set
                 */
                function Calendar(properties) {
                    this.holidays = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Calendar holidays.
                 * @member {Object.<string,infinitusai.be.Calendar.IHoliday>} holidays
                 * @memberof infinitusai.be.Calendar
                 * @instance
                 */
                Calendar.prototype.holidays = $util.emptyObject;
    
                /**
                 * Creates a new Calendar instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {infinitusai.be.ICalendar=} [properties] Properties to set
                 * @returns {infinitusai.be.Calendar} Calendar instance
                 */
                Calendar.create = function create(properties) {
                    return new Calendar(properties);
                };
    
                /**
                 * Encodes the specified Calendar message. Does not implicitly {@link infinitusai.be.Calendar.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {infinitusai.be.ICalendar} message Calendar message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Calendar.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.holidays != null && Object.hasOwnProperty.call(message, "holidays"))
                        for (var keys = Object.keys(message.holidays), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.Calendar.Holiday.encode(message.holidays[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified Calendar message, length delimited. Does not implicitly {@link infinitusai.be.Calendar.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {infinitusai.be.ICalendar} message Calendar message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Calendar.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Calendar message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Calendar} Calendar
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Calendar.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Calendar(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.holidays === $util.emptyObject)
                                    message.holidays = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.Calendar.Holiday.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.holidays[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Calendar message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Calendar} Calendar
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Calendar.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Calendar message.
                 * @function verify
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Calendar.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.holidays != null && message.hasOwnProperty("holidays")) {
                        if (!$util.isObject(message.holidays))
                            return "holidays: object expected";
                        var key = Object.keys(message.holidays);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.Calendar.Holiday.verify(message.holidays[key[i]]);
                            if (error)
                                return "holidays." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Calendar message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Calendar} Calendar
                 */
                Calendar.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Calendar)
                        return object;
                    var message = new $root.infinitusai.be.Calendar();
                    if (object.holidays) {
                        if (typeof object.holidays !== "object")
                            throw TypeError(".infinitusai.be.Calendar.holidays: object expected");
                        message.holidays = {};
                        for (var keys = Object.keys(object.holidays), i = 0; i < keys.length; ++i) {
                            if (typeof object.holidays[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.Calendar.holidays: object expected");
                            message.holidays[keys[i]] = $root.infinitusai.be.Calendar.Holiday.fromObject(object.holidays[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Calendar message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {infinitusai.be.Calendar} message Calendar
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Calendar.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.holidays = {};
                    var keys2;
                    if (message.holidays && (keys2 = Object.keys(message.holidays)).length) {
                        object.holidays = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.holidays[keys2[j]] = $root.infinitusai.be.Calendar.Holiday.toObject(message.holidays[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Calendar to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Calendar
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Calendar.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Calendar
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Calendar
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Calendar.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Calendar";
                };
    
                Calendar.Holiday = (function() {
    
                    /**
                     * Properties of a Holiday.
                     * @memberof infinitusai.be.Calendar
                     * @interface IHoliday
                     * @property {string|null} [name] Holiday name
                     */
    
                    /**
                     * Constructs a new Holiday.
                     * @memberof infinitusai.be.Calendar
                     * @classdesc Represents a Holiday.
                     * @implements IHoliday
                     * @constructor
                     * @param {infinitusai.be.Calendar.IHoliday=} [properties] Properties to set
                     */
                    function Holiday(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Holiday name.
                     * @member {string} name
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @instance
                     */
                    Holiday.prototype.name = "";
    
                    /**
                     * Creates a new Holiday instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {infinitusai.be.Calendar.IHoliday=} [properties] Properties to set
                     * @returns {infinitusai.be.Calendar.Holiday} Holiday instance
                     */
                    Holiday.create = function create(properties) {
                        return new Holiday(properties);
                    };
    
                    /**
                     * Encodes the specified Holiday message. Does not implicitly {@link infinitusai.be.Calendar.Holiday.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {infinitusai.be.Calendar.IHoliday} message Holiday message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Holiday.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Holiday message, length delimited. Does not implicitly {@link infinitusai.be.Calendar.Holiday.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {infinitusai.be.Calendar.IHoliday} message Holiday message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Holiday.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Holiday message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.Calendar.Holiday} Holiday
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Holiday.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Calendar.Holiday();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.name = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Holiday message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.Calendar.Holiday} Holiday
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Holiday.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Holiday message.
                     * @function verify
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Holiday.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a Holiday message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.Calendar.Holiday} Holiday
                     */
                    Holiday.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.Calendar.Holiday)
                            return object;
                        var message = new $root.infinitusai.be.Calendar.Holiday();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Holiday message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {infinitusai.be.Calendar.Holiday} message Holiday
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Holiday.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this Holiday to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Holiday.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Holiday
                     * @function getTypeUrl
                     * @memberof infinitusai.be.Calendar.Holiday
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Holiday.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.Calendar.Holiday";
                    };
    
                    return Holiday;
                })();
    
                return Calendar;
            })();
    
            be.CustomClaims = (function() {
    
                /**
                 * Properties of a CustomClaims.
                 * @memberof infinitusai.be
                 * @interface ICustomClaims
                 * @property {infinitusai.be.UserRole|null} [role] CustomClaims role
                 * @property {Object.<string,infinitusai.be.OrgRole>|null} [organizations] CustomClaims organizations
                 */
    
                /**
                 * Constructs a new CustomClaims.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CustomClaims.
                 * @implements ICustomClaims
                 * @constructor
                 * @param {infinitusai.be.ICustomClaims=} [properties] Properties to set
                 */
                function CustomClaims(properties) {
                    this.organizations = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CustomClaims role.
                 * @member {infinitusai.be.UserRole} role
                 * @memberof infinitusai.be.CustomClaims
                 * @instance
                 */
                CustomClaims.prototype.role = 0;
    
                /**
                 * CustomClaims organizations.
                 * @member {Object.<string,infinitusai.be.OrgRole>} organizations
                 * @memberof infinitusai.be.CustomClaims
                 * @instance
                 */
                CustomClaims.prototype.organizations = $util.emptyObject;
    
                /**
                 * Creates a new CustomClaims instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {infinitusai.be.ICustomClaims=} [properties] Properties to set
                 * @returns {infinitusai.be.CustomClaims} CustomClaims instance
                 */
                CustomClaims.create = function create(properties) {
                    return new CustomClaims(properties);
                };
    
                /**
                 * Encodes the specified CustomClaims message. Does not implicitly {@link infinitusai.be.CustomClaims.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {infinitusai.be.ICustomClaims} message CustomClaims message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomClaims.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.role);
                    if (message.organizations != null && Object.hasOwnProperty.call(message, "organizations"))
                        for (var keys = Object.keys(message.organizations), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.organizations[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CustomClaims message, length delimited. Does not implicitly {@link infinitusai.be.CustomClaims.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {infinitusai.be.ICustomClaims} message CustomClaims message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomClaims.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CustomClaims message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CustomClaims} CustomClaims
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomClaims.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CustomClaims(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.role = reader.int32();
                                break;
                            }
                        case 2: {
                                if (message.organizations === $util.emptyObject)
                                    message.organizations = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.int32();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.organizations[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CustomClaims message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CustomClaims} CustomClaims
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomClaims.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CustomClaims message.
                 * @function verify
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CustomClaims.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.role != null && message.hasOwnProperty("role"))
                        switch (message.role) {
                        default:
                            return "role: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.organizations != null && message.hasOwnProperty("organizations")) {
                        if (!$util.isObject(message.organizations))
                            return "organizations: object expected";
                        var key = Object.keys(message.organizations);
                        for (var i = 0; i < key.length; ++i)
                            switch (message.organizations[key[i]]) {
                            default:
                                return "organizations: enum value{k:string} expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a CustomClaims message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CustomClaims} CustomClaims
                 */
                CustomClaims.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CustomClaims)
                        return object;
                    var message = new $root.infinitusai.be.CustomClaims();
                    switch (object.role) {
                    default:
                        if (typeof object.role === "number") {
                            message.role = object.role;
                            break;
                        }
                        break;
                    case "USER_ROLE_UNKNOWN":
                    case 0:
                        message.role = 0;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 1:
                        message.role = 1;
                        break;
                    case "USER_ROLE_OPERATOR":
                    case 2:
                        message.role = 2;
                        break;
                    case "USER_ROLE_CUSTOMER":
                    case 3:
                        message.role = 3;
                        break;
                    case "USER_ROLE_USER":
                    case 4:
                        message.role = 4;
                        break;
                    }
                    if (object.organizations) {
                        if (typeof object.organizations !== "object")
                            throw TypeError(".infinitusai.be.CustomClaims.organizations: object expected");
                        message.organizations = {};
                        for (var keys = Object.keys(object.organizations), i = 0; i < keys.length; ++i)
                            switch (object.organizations[keys[i]]) {
                            default:
                                if (typeof object.organizations[keys[i]] === "number") {
                                    message.organizations[keys[i]] = object.organizations[keys[i]];
                                    break;
                                }
                                break;
                            case "ORG_ROLE_UNKNOWN":
                            case 0:
                                message.organizations[keys[i]] = 0;
                                break;
                            case "ORG_ROLE_OWNER":
                            case 1:
                                message.organizations[keys[i]] = 1;
                                break;
                            case "ORG_ROLE_MEMBER":
                            case 2:
                                message.organizations[keys[i]] = 2;
                                break;
                            case "ORG_ROLE_SUPERVISOR":
                            case 3:
                                message.organizations[keys[i]] = 3;
                                break;
                            case "ORG_ROLE_OPERATOR":
                            case 4:
                                message.organizations[keys[i]] = 4;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CustomClaims message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {infinitusai.be.CustomClaims} message CustomClaims
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CustomClaims.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.organizations = {};
                    if (options.defaults)
                        object.role = options.enums === String ? "USER_ROLE_UNKNOWN" : 0;
                    if (message.role != null && message.hasOwnProperty("role"))
                        object.role = options.enums === String ? $root.infinitusai.be.UserRole[message.role] === undefined ? message.role : $root.infinitusai.be.UserRole[message.role] : message.role;
                    var keys2;
                    if (message.organizations && (keys2 = Object.keys(message.organizations)).length) {
                        object.organizations = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.organizations[keys2[j]] = options.enums === String ? $root.infinitusai.be.OrgRole[message.organizations[keys2[j]]] === undefined ? message.organizations[keys2[j]] : $root.infinitusai.be.OrgRole[message.organizations[keys2[j]]] : message.organizations[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this CustomClaims to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CustomClaims
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CustomClaims.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CustomClaims
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CustomClaims
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CustomClaims.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CustomClaims";
                };
    
                return CustomClaims;
            })();
    
            be.Abbreviation = (function() {
    
                /**
                 * Properties of an Abbreviation.
                 * @memberof infinitusai.be
                 * @interface IAbbreviation
                 * @property {string|null} [fullName] Abbreviation fullName
                 * @property {string|null} [abbreviation] Abbreviation abbreviation
                 */
    
                /**
                 * Constructs a new Abbreviation.
                 * @memberof infinitusai.be
                 * @classdesc Represents an Abbreviation.
                 * @implements IAbbreviation
                 * @constructor
                 * @param {infinitusai.be.IAbbreviation=} [properties] Properties to set
                 */
                function Abbreviation(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Abbreviation fullName.
                 * @member {string} fullName
                 * @memberof infinitusai.be.Abbreviation
                 * @instance
                 */
                Abbreviation.prototype.fullName = "";
    
                /**
                 * Abbreviation abbreviation.
                 * @member {string} abbreviation
                 * @memberof infinitusai.be.Abbreviation
                 * @instance
                 */
                Abbreviation.prototype.abbreviation = "";
    
                /**
                 * Creates a new Abbreviation instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {infinitusai.be.IAbbreviation=} [properties] Properties to set
                 * @returns {infinitusai.be.Abbreviation} Abbreviation instance
                 */
                Abbreviation.create = function create(properties) {
                    return new Abbreviation(properties);
                };
    
                /**
                 * Encodes the specified Abbreviation message. Does not implicitly {@link infinitusai.be.Abbreviation.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {infinitusai.be.IAbbreviation} message Abbreviation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Abbreviation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.fullName);
                    if (message.abbreviation != null && Object.hasOwnProperty.call(message, "abbreviation"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.abbreviation);
                    return writer;
                };
    
                /**
                 * Encodes the specified Abbreviation message, length delimited. Does not implicitly {@link infinitusai.be.Abbreviation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {infinitusai.be.IAbbreviation} message Abbreviation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Abbreviation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Abbreviation message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Abbreviation} Abbreviation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Abbreviation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Abbreviation();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.fullName = reader.string();
                                break;
                            }
                        case 2: {
                                message.abbreviation = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Abbreviation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Abbreviation} Abbreviation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Abbreviation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Abbreviation message.
                 * @function verify
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Abbreviation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fullName != null && message.hasOwnProperty("fullName"))
                        if (!$util.isString(message.fullName))
                            return "fullName: string expected";
                    if (message.abbreviation != null && message.hasOwnProperty("abbreviation"))
                        if (!$util.isString(message.abbreviation))
                            return "abbreviation: string expected";
                    return null;
                };
    
                /**
                 * Creates an Abbreviation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Abbreviation} Abbreviation
                 */
                Abbreviation.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Abbreviation)
                        return object;
                    var message = new $root.infinitusai.be.Abbreviation();
                    if (object.fullName != null)
                        message.fullName = String(object.fullName);
                    if (object.abbreviation != null)
                        message.abbreviation = String(object.abbreviation);
                    return message;
                };
    
                /**
                 * Creates a plain object from an Abbreviation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {infinitusai.be.Abbreviation} message Abbreviation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Abbreviation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.fullName = "";
                        object.abbreviation = "";
                    }
                    if (message.fullName != null && message.hasOwnProperty("fullName"))
                        object.fullName = message.fullName;
                    if (message.abbreviation != null && message.hasOwnProperty("abbreviation"))
                        object.abbreviation = message.abbreviation;
                    return object;
                };
    
                /**
                 * Converts this Abbreviation to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Abbreviation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Abbreviation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Abbreviation
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Abbreviation
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Abbreviation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Abbreviation";
                };
    
                return Abbreviation;
            })();
    
            be.AbbreviationList = (function() {
    
                /**
                 * Properties of an AbbreviationList.
                 * @memberof infinitusai.be
                 * @interface IAbbreviationList
                 * @property {Array.<infinitusai.be.IAbbreviation>|null} [abbreviation] AbbreviationList abbreviation
                 */
    
                /**
                 * Constructs a new AbbreviationList.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AbbreviationList.
                 * @implements IAbbreviationList
                 * @constructor
                 * @param {infinitusai.be.IAbbreviationList=} [properties] Properties to set
                 */
                function AbbreviationList(properties) {
                    this.abbreviation = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AbbreviationList abbreviation.
                 * @member {Array.<infinitusai.be.IAbbreviation>} abbreviation
                 * @memberof infinitusai.be.AbbreviationList
                 * @instance
                 */
                AbbreviationList.prototype.abbreviation = $util.emptyArray;
    
                /**
                 * Creates a new AbbreviationList instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {infinitusai.be.IAbbreviationList=} [properties] Properties to set
                 * @returns {infinitusai.be.AbbreviationList} AbbreviationList instance
                 */
                AbbreviationList.create = function create(properties) {
                    return new AbbreviationList(properties);
                };
    
                /**
                 * Encodes the specified AbbreviationList message. Does not implicitly {@link infinitusai.be.AbbreviationList.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {infinitusai.be.IAbbreviationList} message AbbreviationList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbbreviationList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.abbreviation != null && message.abbreviation.length)
                        for (var i = 0; i < message.abbreviation.length; ++i)
                            $root.infinitusai.be.Abbreviation.encode(message.abbreviation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AbbreviationList message, length delimited. Does not implicitly {@link infinitusai.be.AbbreviationList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {infinitusai.be.IAbbreviationList} message AbbreviationList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbbreviationList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AbbreviationList message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AbbreviationList} AbbreviationList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbbreviationList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AbbreviationList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.abbreviation && message.abbreviation.length))
                                    message.abbreviation = [];
                                message.abbreviation.push($root.infinitusai.be.Abbreviation.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AbbreviationList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AbbreviationList} AbbreviationList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbbreviationList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AbbreviationList message.
                 * @function verify
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbbreviationList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.abbreviation != null && message.hasOwnProperty("abbreviation")) {
                        if (!Array.isArray(message.abbreviation))
                            return "abbreviation: array expected";
                        for (var i = 0; i < message.abbreviation.length; ++i) {
                            var error = $root.infinitusai.be.Abbreviation.verify(message.abbreviation[i]);
                            if (error)
                                return "abbreviation." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an AbbreviationList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AbbreviationList} AbbreviationList
                 */
                AbbreviationList.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AbbreviationList)
                        return object;
                    var message = new $root.infinitusai.be.AbbreviationList();
                    if (object.abbreviation) {
                        if (!Array.isArray(object.abbreviation))
                            throw TypeError(".infinitusai.be.AbbreviationList.abbreviation: array expected");
                        message.abbreviation = [];
                        for (var i = 0; i < object.abbreviation.length; ++i) {
                            if (typeof object.abbreviation[i] !== "object")
                                throw TypeError(".infinitusai.be.AbbreviationList.abbreviation: object expected");
                            message.abbreviation[i] = $root.infinitusai.be.Abbreviation.fromObject(object.abbreviation[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AbbreviationList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {infinitusai.be.AbbreviationList} message AbbreviationList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbbreviationList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.abbreviation = [];
                    if (message.abbreviation && message.abbreviation.length) {
                        object.abbreviation = [];
                        for (var j = 0; j < message.abbreviation.length; ++j)
                            object.abbreviation[j] = $root.infinitusai.be.Abbreviation.toObject(message.abbreviation[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this AbbreviationList to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AbbreviationList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbbreviationList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AbbreviationList
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AbbreviationList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbbreviationList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AbbreviationList";
                };
    
                return AbbreviationList;
            })();
    
            be.WordCasing = (function() {
    
                /**
                 * Properties of a WordCasing.
                 * @memberof infinitusai.be
                 * @interface IWordCasing
                 * @property {Array.<string>|null} [allUpper] WordCasing allUpper
                 * @property {Array.<string>|null} [allLower] WordCasing allLower
                 */
    
                /**
                 * Constructs a new WordCasing.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WordCasing.
                 * @implements IWordCasing
                 * @constructor
                 * @param {infinitusai.be.IWordCasing=} [properties] Properties to set
                 */
                function WordCasing(properties) {
                    this.allUpper = [];
                    this.allLower = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WordCasing allUpper.
                 * @member {Array.<string>} allUpper
                 * @memberof infinitusai.be.WordCasing
                 * @instance
                 */
                WordCasing.prototype.allUpper = $util.emptyArray;
    
                /**
                 * WordCasing allLower.
                 * @member {Array.<string>} allLower
                 * @memberof infinitusai.be.WordCasing
                 * @instance
                 */
                WordCasing.prototype.allLower = $util.emptyArray;
    
                /**
                 * Creates a new WordCasing instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {infinitusai.be.IWordCasing=} [properties] Properties to set
                 * @returns {infinitusai.be.WordCasing} WordCasing instance
                 */
                WordCasing.create = function create(properties) {
                    return new WordCasing(properties);
                };
    
                /**
                 * Encodes the specified WordCasing message. Does not implicitly {@link infinitusai.be.WordCasing.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {infinitusai.be.IWordCasing} message WordCasing message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WordCasing.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.allUpper != null && message.allUpper.length)
                        for (var i = 0; i < message.allUpper.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.allUpper[i]);
                    if (message.allLower != null && message.allLower.length)
                        for (var i = 0; i < message.allLower.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.allLower[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified WordCasing message, length delimited. Does not implicitly {@link infinitusai.be.WordCasing.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {infinitusai.be.IWordCasing} message WordCasing message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WordCasing.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WordCasing message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WordCasing} WordCasing
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WordCasing.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WordCasing();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.allUpper && message.allUpper.length))
                                    message.allUpper = [];
                                message.allUpper.push(reader.string());
                                break;
                            }
                        case 2: {
                                if (!(message.allLower && message.allLower.length))
                                    message.allLower = [];
                                message.allLower.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WordCasing message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WordCasing} WordCasing
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WordCasing.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WordCasing message.
                 * @function verify
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WordCasing.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.allUpper != null && message.hasOwnProperty("allUpper")) {
                        if (!Array.isArray(message.allUpper))
                            return "allUpper: array expected";
                        for (var i = 0; i < message.allUpper.length; ++i)
                            if (!$util.isString(message.allUpper[i]))
                                return "allUpper: string[] expected";
                    }
                    if (message.allLower != null && message.hasOwnProperty("allLower")) {
                        if (!Array.isArray(message.allLower))
                            return "allLower: array expected";
                        for (var i = 0; i < message.allLower.length; ++i)
                            if (!$util.isString(message.allLower[i]))
                                return "allLower: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a WordCasing message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WordCasing} WordCasing
                 */
                WordCasing.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WordCasing)
                        return object;
                    var message = new $root.infinitusai.be.WordCasing();
                    if (object.allUpper) {
                        if (!Array.isArray(object.allUpper))
                            throw TypeError(".infinitusai.be.WordCasing.allUpper: array expected");
                        message.allUpper = [];
                        for (var i = 0; i < object.allUpper.length; ++i)
                            message.allUpper[i] = String(object.allUpper[i]);
                    }
                    if (object.allLower) {
                        if (!Array.isArray(object.allLower))
                            throw TypeError(".infinitusai.be.WordCasing.allLower: array expected");
                        message.allLower = [];
                        for (var i = 0; i < object.allLower.length; ++i)
                            message.allLower[i] = String(object.allLower[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a WordCasing message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {infinitusai.be.WordCasing} message WordCasing
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WordCasing.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.allUpper = [];
                        object.allLower = [];
                    }
                    if (message.allUpper && message.allUpper.length) {
                        object.allUpper = [];
                        for (var j = 0; j < message.allUpper.length; ++j)
                            object.allUpper[j] = message.allUpper[j];
                    }
                    if (message.allLower && message.allLower.length) {
                        object.allLower = [];
                        for (var j = 0; j < message.allLower.length; ++j)
                            object.allLower[j] = message.allLower[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this WordCasing to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WordCasing
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WordCasing.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WordCasing
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WordCasing
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WordCasing.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WordCasing";
                };
    
                return WordCasing;
            })();
    
            be.ConvoBuilderService = (function() {
    
                /**
                 * Constructs a new ConvoBuilderService service.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ConvoBuilderService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ConvoBuilderService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (ConvoBuilderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ConvoBuilderService;
    
                /**
                 * Creates new ConvoBuilderService service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ConvoBuilderService} RPC service. Useful where requests and/or responses are streamed.
                 */
                ConvoBuilderService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#getOutputs}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef GetOutputsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetOutputsResponse} [response] GetOutputsResponse
                 */
    
                /**
                 * Calls GetOutputs.
                 * @function getOutputs
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetOutputsRequest} request GetOutputsRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.GetOutputsCallback} callback Node-style callback called with the error, if any, and GetOutputsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.getOutputs = function getOutputs(request, callback) {
                    return this.rpcCall(getOutputs, $root.infinitusai.be.GetOutputsRequest, $root.infinitusai.be.GetOutputsResponse, request, callback);
                }, "name", { value: "GetOutputs" });
    
                /**
                 * Calls GetOutputs.
                 * @function getOutputs
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetOutputsRequest} request GetOutputsRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetOutputsResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#getNextAction}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef GetNextActionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetNextActionResponse} [response] GetNextActionResponse
                 */
    
                /**
                 * Calls GetNextAction.
                 * @function getNextAction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetNextActionRequest} request GetNextActionRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.GetNextActionCallback} callback Node-style callback called with the error, if any, and GetNextActionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.getNextAction = function getNextAction(request, callback) {
                    return this.rpcCall(getNextAction, $root.infinitusai.be.GetNextActionRequest, $root.infinitusai.be.GetNextActionResponse, request, callback);
                }, "name", { value: "GetNextAction" });
    
                /**
                 * Calls GetNextAction.
                 * @function getNextAction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetNextActionRequest} request GetNextActionRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetNextActionResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#getNextActions}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef GetNextActionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetNextActionsResponse} [response] GetNextActionsResponse
                 */
    
                /**
                 * Calls GetNextActions.
                 * @function getNextActions
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetNextActionsRequest} request GetNextActionsRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.GetNextActionsCallback} callback Node-style callback called with the error, if any, and GetNextActionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.getNextActions = function getNextActions(request, callback) {
                    return this.rpcCall(getNextActions, $root.infinitusai.be.GetNextActionsRequest, $root.infinitusai.be.GetNextActionsResponse, request, callback);
                }, "name", { value: "GetNextActions" });
    
                /**
                 * Calls GetNextActions.
                 * @function getNextActions
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetNextActionsRequest} request GetNextActionsRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetNextActionsResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#saveOutputsAndGetNextAction}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef SaveOutputsAndGetNextActionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.SaveOutputsAndGetNextActionResponse} [response] SaveOutputsAndGetNextActionResponse
                 */
    
                /**
                 * Calls SaveOutputsAndGetNextAction.
                 * @function saveOutputsAndGetNextAction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionRequest} request SaveOutputsAndGetNextActionRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.SaveOutputsAndGetNextActionCallback} callback Node-style callback called with the error, if any, and SaveOutputsAndGetNextActionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.saveOutputsAndGetNextAction = function saveOutputsAndGetNextAction(request, callback) {
                    return this.rpcCall(saveOutputsAndGetNextAction, $root.infinitusai.be.SaveOutputsAndGetNextActionRequest, $root.infinitusai.be.SaveOutputsAndGetNextActionResponse, request, callback);
                }, "name", { value: "SaveOutputsAndGetNextAction" });
    
                /**
                 * Calls SaveOutputsAndGetNextAction.
                 * @function saveOutputsAndGetNextAction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionRequest} request SaveOutputsAndGetNextActionRequest message or plain object
                 * @returns {Promise<infinitusai.be.SaveOutputsAndGetNextActionResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#getCallConversation}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef GetCallConversationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetCallConversationResponse} [response] GetCallConversationResponse
                 */
    
                /**
                 * Calls GetCallConversation.
                 * @function getCallConversation
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetCallConversationRequest} request GetCallConversationRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.GetCallConversationCallback} callback Node-style callback called with the error, if any, and GetCallConversationResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.getCallConversation = function getCallConversation(request, callback) {
                    return this.rpcCall(getCallConversation, $root.infinitusai.be.GetCallConversationRequest, $root.infinitusai.be.GetCallConversationResponse, request, callback);
                }, "name", { value: "GetCallConversation" });
    
                /**
                 * Calls GetCallConversation.
                 * @function getCallConversation
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetCallConversationRequest} request GetCallConversationRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetCallConversationResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#evaluateConditionIds}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef EvaluateConditionIdsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.EvaluateConditionIdsResponse} [response] EvaluateConditionIdsResponse
                 */
    
                /**
                 * Calls EvaluateConditionIds.
                 * @function evaluateConditionIds
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IEvaluateConditionIdsRequest} request EvaluateConditionIdsRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.EvaluateConditionIdsCallback} callback Node-style callback called with the error, if any, and EvaluateConditionIdsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.evaluateConditionIds = function evaluateConditionIds(request, callback) {
                    return this.rpcCall(evaluateConditionIds, $root.infinitusai.be.EvaluateConditionIdsRequest, $root.infinitusai.be.EvaluateConditionIdsResponse, request, callback);
                }, "name", { value: "EvaluateConditionIds" });
    
                /**
                 * Calls EvaluateConditionIds.
                 * @function evaluateConditionIds
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IEvaluateConditionIdsRequest} request EvaluateConditionIdsRequest message or plain object
                 * @returns {Promise<infinitusai.be.EvaluateConditionIdsResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#cbExecuteAction}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef CbExecuteActionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.CbExecuteActionResponse} [response] CbExecuteActionResponse
                 */
    
                /**
                 * Calls CbExecuteAction.
                 * @function cbExecuteAction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.ICbExecuteActionRequest} request CbExecuteActionRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.CbExecuteActionCallback} callback Node-style callback called with the error, if any, and CbExecuteActionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.cbExecuteAction = function cbExecuteAction(request, callback) {
                    return this.rpcCall(cbExecuteAction, $root.infinitusai.be.CbExecuteActionRequest, $root.infinitusai.be.CbExecuteActionResponse, request, callback);
                }, "name", { value: "CbExecuteAction" });
    
                /**
                 * Calls CbExecuteAction.
                 * @function cbExecuteAction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.ICbExecuteActionRequest} request CbExecuteActionRequest message or plain object
                 * @returns {Promise<infinitusai.be.CbExecuteActionResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#evaluateFunction}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef EvaluateFunctionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.EvaluateFunctionResponse} [response] EvaluateFunctionResponse
                 */
    
                /**
                 * Calls EvaluateFunction.
                 * @function evaluateFunction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IEvaluateFunctionRequest} request EvaluateFunctionRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.EvaluateFunctionCallback} callback Node-style callback called with the error, if any, and EvaluateFunctionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.evaluateFunction = function evaluateFunction(request, callback) {
                    return this.rpcCall(evaluateFunction, $root.infinitusai.be.EvaluateFunctionRequest, $root.infinitusai.be.EvaluateFunctionResponse, request, callback);
                }, "name", { value: "EvaluateFunction" });
    
                /**
                 * Calls EvaluateFunction.
                 * @function evaluateFunction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IEvaluateFunctionRequest} request EvaluateFunctionRequest message or plain object
                 * @returns {Promise<infinitusai.be.EvaluateFunctionResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ConvoBuilderService#getOutputsForAction}.
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @typedef GetOutputsForActionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetOutputsForActionResponse} [response] GetOutputsForActionResponse
                 */
    
                /**
                 * Calls GetOutputsForAction.
                 * @function getOutputsForAction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetOutputsForActionRequest} request GetOutputsForActionRequest message or plain object
                 * @param {infinitusai.be.ConvoBuilderService.GetOutputsForActionCallback} callback Node-style callback called with the error, if any, and GetOutputsForActionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ConvoBuilderService.prototype.getOutputsForAction = function getOutputsForAction(request, callback) {
                    return this.rpcCall(getOutputsForAction, $root.infinitusai.be.GetOutputsForActionRequest, $root.infinitusai.be.GetOutputsForActionResponse, request, callback);
                }, "name", { value: "GetOutputsForAction" });
    
                /**
                 * Calls GetOutputsForAction.
                 * @function getOutputsForAction
                 * @memberof infinitusai.be.ConvoBuilderService
                 * @instance
                 * @param {infinitusai.be.IGetOutputsForActionRequest} request GetOutputsForActionRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetOutputsForActionResponse>} Promise
                 * @variation 2
                 */
    
                return ConvoBuilderService;
            })();
    
            be.GetOutputsRequest = (function() {
    
                /**
                 * Properties of a GetOutputsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetOutputsRequest
                 * @property {string|null} [orgUuid] GetOutputsRequest orgUuid
                 * @property {string|null} [taskUuid] GetOutputsRequest taskUuid
                 * @property {string|null} [callUuid] GetOutputsRequest callUuid
                 */
    
                /**
                 * Constructs a new GetOutputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOutputsRequest.
                 * @implements IGetOutputsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetOutputsRequest=} [properties] Properties to set
                 */
                function GetOutputsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOutputsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @instance
                 */
                GetOutputsRequest.prototype.orgUuid = "";
    
                /**
                 * GetOutputsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @instance
                 */
                GetOutputsRequest.prototype.taskUuid = "";
    
                /**
                 * GetOutputsRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @instance
                 */
                GetOutputsRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new GetOutputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {infinitusai.be.IGetOutputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOutputsRequest} GetOutputsRequest instance
                 */
                GetOutputsRequest.create = function create(properties) {
                    return new GetOutputsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOutputsRequest message. Does not implicitly {@link infinitusai.be.GetOutputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {infinitusai.be.IGetOutputsRequest} message GetOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOutputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOutputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetOutputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {infinitusai.be.IGetOutputsRequest} message GetOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOutputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOutputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOutputsRequest} GetOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOutputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOutputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOutputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOutputsRequest} GetOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOutputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOutputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOutputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetOutputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOutputsRequest} GetOutputsRequest
                 */
                GetOutputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOutputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetOutputsRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOutputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {infinitusai.be.GetOutputsRequest} message GetOutputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOutputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this GetOutputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOutputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOutputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOutputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOutputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOutputsRequest";
                };
    
                return GetOutputsRequest;
            })();
    
            be.GetOutputsResponse = (function() {
    
                /**
                 * Properties of a GetOutputsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetOutputsResponse
                 * @property {infinitusai.be.IOutputMap|null} [outputMap] GetOutputsResponse outputMap
                 */
    
                /**
                 * Constructs a new GetOutputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOutputsResponse.
                 * @implements IGetOutputsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetOutputsResponse=} [properties] Properties to set
                 */
                function GetOutputsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOutputsResponse outputMap.
                 * @member {infinitusai.be.IOutputMap|null|undefined} outputMap
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @instance
                 */
                GetOutputsResponse.prototype.outputMap = null;
    
                /**
                 * Creates a new GetOutputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {infinitusai.be.IGetOutputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOutputsResponse} GetOutputsResponse instance
                 */
                GetOutputsResponse.create = function create(properties) {
                    return new GetOutputsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOutputsResponse message. Does not implicitly {@link infinitusai.be.GetOutputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {infinitusai.be.IGetOutputsResponse} message GetOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOutputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.outputMap != null && Object.hasOwnProperty.call(message, "outputMap"))
                        $root.infinitusai.be.OutputMap.encode(message.outputMap, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOutputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetOutputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {infinitusai.be.IGetOutputsResponse} message GetOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOutputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOutputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOutputsResponse} GetOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOutputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOutputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.outputMap = $root.infinitusai.be.OutputMap.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOutputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOutputsResponse} GetOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOutputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOutputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOutputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.outputMap != null && message.hasOwnProperty("outputMap")) {
                        var error = $root.infinitusai.be.OutputMap.verify(message.outputMap);
                        if (error)
                            return "outputMap." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOutputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOutputsResponse} GetOutputsResponse
                 */
                GetOutputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOutputsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetOutputsResponse();
                    if (object.outputMap != null) {
                        if (typeof object.outputMap !== "object")
                            throw TypeError(".infinitusai.be.GetOutputsResponse.outputMap: object expected");
                        message.outputMap = $root.infinitusai.be.OutputMap.fromObject(object.outputMap);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOutputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {infinitusai.be.GetOutputsResponse} message GetOutputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOutputsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.outputMap = null;
                    if (message.outputMap != null && message.hasOwnProperty("outputMap"))
                        object.outputMap = $root.infinitusai.be.OutputMap.toObject(message.outputMap, options);
                    return object;
                };
    
                /**
                 * Converts this GetOutputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOutputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOutputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOutputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOutputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOutputsResponse";
                };
    
                return GetOutputsResponse;
            })();
    
            be.GetNextActionRequest = (function() {
    
                /**
                 * Properties of a GetNextActionRequest.
                 * @memberof infinitusai.be
                 * @interface IGetNextActionRequest
                 * @property {string|null} [orgUuid] GetNextActionRequest orgUuid
                 * @property {string|null} [taskUuid] GetNextActionRequest taskUuid
                 * @property {string|null} [callUuid] GetNextActionRequest callUuid
                 */
    
                /**
                 * Constructs a new GetNextActionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetNextActionRequest.
                 * @implements IGetNextActionRequest
                 * @constructor
                 * @param {infinitusai.be.IGetNextActionRequest=} [properties] Properties to set
                 */
                function GetNextActionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetNextActionRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @instance
                 */
                GetNextActionRequest.prototype.orgUuid = "";
    
                /**
                 * GetNextActionRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @instance
                 */
                GetNextActionRequest.prototype.taskUuid = "";
    
                /**
                 * GetNextActionRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @instance
                 */
                GetNextActionRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new GetNextActionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {infinitusai.be.IGetNextActionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetNextActionRequest} GetNextActionRequest instance
                 */
                GetNextActionRequest.create = function create(properties) {
                    return new GetNextActionRequest(properties);
                };
    
                /**
                 * Encodes the specified GetNextActionRequest message. Does not implicitly {@link infinitusai.be.GetNextActionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {infinitusai.be.IGetNextActionRequest} message GetNextActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNextActionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetNextActionRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetNextActionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {infinitusai.be.IGetNextActionRequest} message GetNextActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNextActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetNextActionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetNextActionRequest} GetNextActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNextActionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetNextActionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetNextActionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetNextActionRequest} GetNextActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNextActionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetNextActionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetNextActionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetNextActionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetNextActionRequest} GetNextActionRequest
                 */
                GetNextActionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetNextActionRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetNextActionRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetNextActionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {infinitusai.be.GetNextActionRequest} message GetNextActionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetNextActionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this GetNextActionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetNextActionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetNextActionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetNextActionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetNextActionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetNextActionRequest";
                };
    
                return GetNextActionRequest;
            })();
    
            be.GetNextActionResponse = (function() {
    
                /**
                 * Properties of a GetNextActionResponse.
                 * @memberof infinitusai.be
                 * @interface IGetNextActionResponse
                 * @property {infinitusai.be.IExpandedAction|null} [nextAction] GetNextActionResponse nextAction
                 */
    
                /**
                 * Constructs a new GetNextActionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetNextActionResponse.
                 * @implements IGetNextActionResponse
                 * @constructor
                 * @param {infinitusai.be.IGetNextActionResponse=} [properties] Properties to set
                 */
                function GetNextActionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetNextActionResponse nextAction.
                 * @member {infinitusai.be.IExpandedAction|null|undefined} nextAction
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @instance
                 */
                GetNextActionResponse.prototype.nextAction = null;
    
                /**
                 * Creates a new GetNextActionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {infinitusai.be.IGetNextActionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetNextActionResponse} GetNextActionResponse instance
                 */
                GetNextActionResponse.create = function create(properties) {
                    return new GetNextActionResponse(properties);
                };
    
                /**
                 * Encodes the specified GetNextActionResponse message. Does not implicitly {@link infinitusai.be.GetNextActionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {infinitusai.be.IGetNextActionResponse} message GetNextActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNextActionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.nextAction != null && Object.hasOwnProperty.call(message, "nextAction"))
                        $root.infinitusai.be.ExpandedAction.encode(message.nextAction, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetNextActionResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetNextActionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {infinitusai.be.IGetNextActionResponse} message GetNextActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNextActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetNextActionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetNextActionResponse} GetNextActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNextActionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetNextActionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.nextAction = $root.infinitusai.be.ExpandedAction.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetNextActionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetNextActionResponse} GetNextActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNextActionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetNextActionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetNextActionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.nextAction != null && message.hasOwnProperty("nextAction")) {
                        var error = $root.infinitusai.be.ExpandedAction.verify(message.nextAction);
                        if (error)
                            return "nextAction." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetNextActionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetNextActionResponse} GetNextActionResponse
                 */
                GetNextActionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetNextActionResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetNextActionResponse();
                    if (object.nextAction != null) {
                        if (typeof object.nextAction !== "object")
                            throw TypeError(".infinitusai.be.GetNextActionResponse.nextAction: object expected");
                        message.nextAction = $root.infinitusai.be.ExpandedAction.fromObject(object.nextAction);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetNextActionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {infinitusai.be.GetNextActionResponse} message GetNextActionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetNextActionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.nextAction = null;
                    if (message.nextAction != null && message.hasOwnProperty("nextAction"))
                        object.nextAction = $root.infinitusai.be.ExpandedAction.toObject(message.nextAction, options);
                    return object;
                };
    
                /**
                 * Converts this GetNextActionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetNextActionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetNextActionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetNextActionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetNextActionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetNextActionResponse";
                };
    
                return GetNextActionResponse;
            })();
    
            be.GetNextActionsRequest = (function() {
    
                /**
                 * Properties of a GetNextActionsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetNextActionsRequest
                 * @property {string|null} [orgUuid] GetNextActionsRequest orgUuid
                 * @property {string|null} [taskUuid] GetNextActionsRequest taskUuid
                 * @property {string|null} [callUuid] GetNextActionsRequest callUuid
                 */
    
                /**
                 * Constructs a new GetNextActionsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetNextActionsRequest.
                 * @implements IGetNextActionsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetNextActionsRequest=} [properties] Properties to set
                 */
                function GetNextActionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetNextActionsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @instance
                 */
                GetNextActionsRequest.prototype.orgUuid = "";
    
                /**
                 * GetNextActionsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @instance
                 */
                GetNextActionsRequest.prototype.taskUuid = "";
    
                /**
                 * GetNextActionsRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @instance
                 */
                GetNextActionsRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new GetNextActionsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {infinitusai.be.IGetNextActionsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetNextActionsRequest} GetNextActionsRequest instance
                 */
                GetNextActionsRequest.create = function create(properties) {
                    return new GetNextActionsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetNextActionsRequest message. Does not implicitly {@link infinitusai.be.GetNextActionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {infinitusai.be.IGetNextActionsRequest} message GetNextActionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNextActionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetNextActionsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetNextActionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {infinitusai.be.IGetNextActionsRequest} message GetNextActionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNextActionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetNextActionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetNextActionsRequest} GetNextActionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNextActionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetNextActionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetNextActionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetNextActionsRequest} GetNextActionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNextActionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetNextActionsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetNextActionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetNextActionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetNextActionsRequest} GetNextActionsRequest
                 */
                GetNextActionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetNextActionsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetNextActionsRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetNextActionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {infinitusai.be.GetNextActionsRequest} message GetNextActionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetNextActionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this GetNextActionsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetNextActionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetNextActionsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetNextActionsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetNextActionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetNextActionsRequest";
                };
    
                return GetNextActionsRequest;
            })();
    
            be.GetNextActionsResponse = (function() {
    
                /**
                 * Properties of a GetNextActionsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetNextActionsResponse
                 * @property {Array.<infinitusai.be.IExpandedAction>|null} [expandedActions] GetNextActionsResponse expandedActions
                 * @property {Array.<infinitusai.be.IExpandedUtterance>|null} [expandedUtterances] GetNextActionsResponse expandedUtterances
                 * @property {Array.<infinitusai.be.IRelevantOutputsForGivenAction>|null} [expandedOutputsPerAction] GetNextActionsResponse expandedOutputsPerAction
                 */
    
                /**
                 * Constructs a new GetNextActionsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetNextActionsResponse.
                 * @implements IGetNextActionsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetNextActionsResponse=} [properties] Properties to set
                 */
                function GetNextActionsResponse(properties) {
                    this.expandedActions = [];
                    this.expandedUtterances = [];
                    this.expandedOutputsPerAction = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetNextActionsResponse expandedActions.
                 * @member {Array.<infinitusai.be.IExpandedAction>} expandedActions
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @instance
                 */
                GetNextActionsResponse.prototype.expandedActions = $util.emptyArray;
    
                /**
                 * GetNextActionsResponse expandedUtterances.
                 * @member {Array.<infinitusai.be.IExpandedUtterance>} expandedUtterances
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @instance
                 */
                GetNextActionsResponse.prototype.expandedUtterances = $util.emptyArray;
    
                /**
                 * GetNextActionsResponse expandedOutputsPerAction.
                 * @member {Array.<infinitusai.be.IRelevantOutputsForGivenAction>} expandedOutputsPerAction
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @instance
                 */
                GetNextActionsResponse.prototype.expandedOutputsPerAction = $util.emptyArray;
    
                /**
                 * Creates a new GetNextActionsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {infinitusai.be.IGetNextActionsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetNextActionsResponse} GetNextActionsResponse instance
                 */
                GetNextActionsResponse.create = function create(properties) {
                    return new GetNextActionsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetNextActionsResponse message. Does not implicitly {@link infinitusai.be.GetNextActionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {infinitusai.be.IGetNextActionsResponse} message GetNextActionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNextActionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedActions != null && message.expandedActions.length)
                        for (var i = 0; i < message.expandedActions.length; ++i)
                            $root.infinitusai.be.ExpandedAction.encode(message.expandedActions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.expandedUtterances != null && message.expandedUtterances.length)
                        for (var i = 0; i < message.expandedUtterances.length; ++i)
                            $root.infinitusai.be.ExpandedUtterance.encode(message.expandedUtterances[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.expandedOutputsPerAction != null && message.expandedOutputsPerAction.length)
                        for (var i = 0; i < message.expandedOutputsPerAction.length; ++i)
                            $root.infinitusai.be.RelevantOutputsForGivenAction.encode(message.expandedOutputsPerAction[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetNextActionsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetNextActionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {infinitusai.be.IGetNextActionsResponse} message GetNextActionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNextActionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetNextActionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetNextActionsResponse} GetNextActionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNextActionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetNextActionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.expandedActions && message.expandedActions.length))
                                    message.expandedActions = [];
                                message.expandedActions.push($root.infinitusai.be.ExpandedAction.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.expandedUtterances && message.expandedUtterances.length))
                                    message.expandedUtterances = [];
                                message.expandedUtterances.push($root.infinitusai.be.ExpandedUtterance.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.expandedOutputsPerAction && message.expandedOutputsPerAction.length))
                                    message.expandedOutputsPerAction = [];
                                message.expandedOutputsPerAction.push($root.infinitusai.be.RelevantOutputsForGivenAction.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetNextActionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetNextActionsResponse} GetNextActionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNextActionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetNextActionsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetNextActionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedActions != null && message.hasOwnProperty("expandedActions")) {
                        if (!Array.isArray(message.expandedActions))
                            return "expandedActions: array expected";
                        for (var i = 0; i < message.expandedActions.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedAction.verify(message.expandedActions[i]);
                            if (error)
                                return "expandedActions." + error;
                        }
                    }
                    if (message.expandedUtterances != null && message.hasOwnProperty("expandedUtterances")) {
                        if (!Array.isArray(message.expandedUtterances))
                            return "expandedUtterances: array expected";
                        for (var i = 0; i < message.expandedUtterances.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedUtterance.verify(message.expandedUtterances[i]);
                            if (error)
                                return "expandedUtterances." + error;
                        }
                    }
                    if (message.expandedOutputsPerAction != null && message.hasOwnProperty("expandedOutputsPerAction")) {
                        if (!Array.isArray(message.expandedOutputsPerAction))
                            return "expandedOutputsPerAction: array expected";
                        for (var i = 0; i < message.expandedOutputsPerAction.length; ++i) {
                            var error = $root.infinitusai.be.RelevantOutputsForGivenAction.verify(message.expandedOutputsPerAction[i]);
                            if (error)
                                return "expandedOutputsPerAction." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetNextActionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetNextActionsResponse} GetNextActionsResponse
                 */
                GetNextActionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetNextActionsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetNextActionsResponse();
                    if (object.expandedActions) {
                        if (!Array.isArray(object.expandedActions))
                            throw TypeError(".infinitusai.be.GetNextActionsResponse.expandedActions: array expected");
                        message.expandedActions = [];
                        for (var i = 0; i < object.expandedActions.length; ++i) {
                            if (typeof object.expandedActions[i] !== "object")
                                throw TypeError(".infinitusai.be.GetNextActionsResponse.expandedActions: object expected");
                            message.expandedActions[i] = $root.infinitusai.be.ExpandedAction.fromObject(object.expandedActions[i]);
                        }
                    }
                    if (object.expandedUtterances) {
                        if (!Array.isArray(object.expandedUtterances))
                            throw TypeError(".infinitusai.be.GetNextActionsResponse.expandedUtterances: array expected");
                        message.expandedUtterances = [];
                        for (var i = 0; i < object.expandedUtterances.length; ++i) {
                            if (typeof object.expandedUtterances[i] !== "object")
                                throw TypeError(".infinitusai.be.GetNextActionsResponse.expandedUtterances: object expected");
                            message.expandedUtterances[i] = $root.infinitusai.be.ExpandedUtterance.fromObject(object.expandedUtterances[i]);
                        }
                    }
                    if (object.expandedOutputsPerAction) {
                        if (!Array.isArray(object.expandedOutputsPerAction))
                            throw TypeError(".infinitusai.be.GetNextActionsResponse.expandedOutputsPerAction: array expected");
                        message.expandedOutputsPerAction = [];
                        for (var i = 0; i < object.expandedOutputsPerAction.length; ++i) {
                            if (typeof object.expandedOutputsPerAction[i] !== "object")
                                throw TypeError(".infinitusai.be.GetNextActionsResponse.expandedOutputsPerAction: object expected");
                            message.expandedOutputsPerAction[i] = $root.infinitusai.be.RelevantOutputsForGivenAction.fromObject(object.expandedOutputsPerAction[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetNextActionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {infinitusai.be.GetNextActionsResponse} message GetNextActionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetNextActionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.expandedActions = [];
                        object.expandedUtterances = [];
                        object.expandedOutputsPerAction = [];
                    }
                    if (message.expandedActions && message.expandedActions.length) {
                        object.expandedActions = [];
                        for (var j = 0; j < message.expandedActions.length; ++j)
                            object.expandedActions[j] = $root.infinitusai.be.ExpandedAction.toObject(message.expandedActions[j], options);
                    }
                    if (message.expandedUtterances && message.expandedUtterances.length) {
                        object.expandedUtterances = [];
                        for (var j = 0; j < message.expandedUtterances.length; ++j)
                            object.expandedUtterances[j] = $root.infinitusai.be.ExpandedUtterance.toObject(message.expandedUtterances[j], options);
                    }
                    if (message.expandedOutputsPerAction && message.expandedOutputsPerAction.length) {
                        object.expandedOutputsPerAction = [];
                        for (var j = 0; j < message.expandedOutputsPerAction.length; ++j)
                            object.expandedOutputsPerAction[j] = $root.infinitusai.be.RelevantOutputsForGivenAction.toObject(message.expandedOutputsPerAction[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetNextActionsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetNextActionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetNextActionsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetNextActionsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetNextActionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetNextActionsResponse";
                };
    
                return GetNextActionsResponse;
            })();
    
            be.SaveOutputsAndGetNextActionRequest = (function() {
    
                /**
                 * Properties of a SaveOutputsAndGetNextActionRequest.
                 * @memberof infinitusai.be
                 * @interface ISaveOutputsAndGetNextActionRequest
                 * @property {string|null} [orgUuid] SaveOutputsAndGetNextActionRequest orgUuid
                 * @property {string|null} [taskUuid] SaveOutputsAndGetNextActionRequest taskUuid
                 * @property {string|null} [callUuid] SaveOutputsAndGetNextActionRequest callUuid
                 * @property {Array.<infinitusai.be.IExpandedOutput>|null} [outputs] SaveOutputsAndGetNextActionRequest outputs
                 */
    
                /**
                 * Constructs a new SaveOutputsAndGetNextActionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveOutputsAndGetNextActionRequest.
                 * @implements ISaveOutputsAndGetNextActionRequest
                 * @constructor
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionRequest=} [properties] Properties to set
                 */
                function SaveOutputsAndGetNextActionRequest(properties) {
                    this.outputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SaveOutputsAndGetNextActionRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @instance
                 */
                SaveOutputsAndGetNextActionRequest.prototype.orgUuid = "";
    
                /**
                 * SaveOutputsAndGetNextActionRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @instance
                 */
                SaveOutputsAndGetNextActionRequest.prototype.taskUuid = "";
    
                /**
                 * SaveOutputsAndGetNextActionRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @instance
                 */
                SaveOutputsAndGetNextActionRequest.prototype.callUuid = "";
    
                /**
                 * SaveOutputsAndGetNextActionRequest outputs.
                 * @member {Array.<infinitusai.be.IExpandedOutput>} outputs
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @instance
                 */
                SaveOutputsAndGetNextActionRequest.prototype.outputs = $util.emptyArray;
    
                /**
                 * Creates a new SaveOutputsAndGetNextActionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveOutputsAndGetNextActionRequest} SaveOutputsAndGetNextActionRequest instance
                 */
                SaveOutputsAndGetNextActionRequest.create = function create(properties) {
                    return new SaveOutputsAndGetNextActionRequest(properties);
                };
    
                /**
                 * Encodes the specified SaveOutputsAndGetNextActionRequest message. Does not implicitly {@link infinitusai.be.SaveOutputsAndGetNextActionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionRequest} message SaveOutputsAndGetNextActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveOutputsAndGetNextActionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.outputs != null && message.outputs.length)
                        for (var i = 0; i < message.outputs.length; ++i)
                            $root.infinitusai.be.ExpandedOutput.encode(message.outputs[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveOutputsAndGetNextActionRequest message, length delimited. Does not implicitly {@link infinitusai.be.SaveOutputsAndGetNextActionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionRequest} message SaveOutputsAndGetNextActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveOutputsAndGetNextActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveOutputsAndGetNextActionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveOutputsAndGetNextActionRequest} SaveOutputsAndGetNextActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveOutputsAndGetNextActionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveOutputsAndGetNextActionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.outputs && message.outputs.length))
                                    message.outputs = [];
                                message.outputs.push($root.infinitusai.be.ExpandedOutput.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveOutputsAndGetNextActionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveOutputsAndGetNextActionRequest} SaveOutputsAndGetNextActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveOutputsAndGetNextActionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveOutputsAndGetNextActionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveOutputsAndGetNextActionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.outputs != null && message.hasOwnProperty("outputs")) {
                        if (!Array.isArray(message.outputs))
                            return "outputs: array expected";
                        for (var i = 0; i < message.outputs.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedOutput.verify(message.outputs[i]);
                            if (error)
                                return "outputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a SaveOutputsAndGetNextActionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveOutputsAndGetNextActionRequest} SaveOutputsAndGetNextActionRequest
                 */
                SaveOutputsAndGetNextActionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveOutputsAndGetNextActionRequest)
                        return object;
                    var message = new $root.infinitusai.be.SaveOutputsAndGetNextActionRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.outputs) {
                        if (!Array.isArray(object.outputs))
                            throw TypeError(".infinitusai.be.SaveOutputsAndGetNextActionRequest.outputs: array expected");
                        message.outputs = [];
                        for (var i = 0; i < object.outputs.length; ++i) {
                            if (typeof object.outputs[i] !== "object")
                                throw TypeError(".infinitusai.be.SaveOutputsAndGetNextActionRequest.outputs: object expected");
                            message.outputs[i] = $root.infinitusai.be.ExpandedOutput.fromObject(object.outputs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SaveOutputsAndGetNextActionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {infinitusai.be.SaveOutputsAndGetNextActionRequest} message SaveOutputsAndGetNextActionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveOutputsAndGetNextActionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.outputs = [];
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.outputs && message.outputs.length) {
                        object.outputs = [];
                        for (var j = 0; j < message.outputs.length; ++j)
                            object.outputs[j] = $root.infinitusai.be.ExpandedOutput.toObject(message.outputs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this SaveOutputsAndGetNextActionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveOutputsAndGetNextActionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveOutputsAndGetNextActionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveOutputsAndGetNextActionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveOutputsAndGetNextActionRequest";
                };
    
                return SaveOutputsAndGetNextActionRequest;
            })();
    
            be.SaveOutputsAndGetNextActionResponse = (function() {
    
                /**
                 * Properties of a SaveOutputsAndGetNextActionResponse.
                 * @memberof infinitusai.be
                 * @interface ISaveOutputsAndGetNextActionResponse
                 * @property {infinitusai.be.IExpandedAction|null} [expandedAction] SaveOutputsAndGetNextActionResponse expandedAction
                 */
    
                /**
                 * Constructs a new SaveOutputsAndGetNextActionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveOutputsAndGetNextActionResponse.
                 * @implements ISaveOutputsAndGetNextActionResponse
                 * @constructor
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionResponse=} [properties] Properties to set
                 */
                function SaveOutputsAndGetNextActionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SaveOutputsAndGetNextActionResponse expandedAction.
                 * @member {infinitusai.be.IExpandedAction|null|undefined} expandedAction
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @instance
                 */
                SaveOutputsAndGetNextActionResponse.prototype.expandedAction = null;
    
                /**
                 * Creates a new SaveOutputsAndGetNextActionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveOutputsAndGetNextActionResponse} SaveOutputsAndGetNextActionResponse instance
                 */
                SaveOutputsAndGetNextActionResponse.create = function create(properties) {
                    return new SaveOutputsAndGetNextActionResponse(properties);
                };
    
                /**
                 * Encodes the specified SaveOutputsAndGetNextActionResponse message. Does not implicitly {@link infinitusai.be.SaveOutputsAndGetNextActionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionResponse} message SaveOutputsAndGetNextActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveOutputsAndGetNextActionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedAction != null && Object.hasOwnProperty.call(message, "expandedAction"))
                        $root.infinitusai.be.ExpandedAction.encode(message.expandedAction, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveOutputsAndGetNextActionResponse message, length delimited. Does not implicitly {@link infinitusai.be.SaveOutputsAndGetNextActionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {infinitusai.be.ISaveOutputsAndGetNextActionResponse} message SaveOutputsAndGetNextActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveOutputsAndGetNextActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveOutputsAndGetNextActionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveOutputsAndGetNextActionResponse} SaveOutputsAndGetNextActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveOutputsAndGetNextActionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveOutputsAndGetNextActionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.expandedAction = $root.infinitusai.be.ExpandedAction.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveOutputsAndGetNextActionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveOutputsAndGetNextActionResponse} SaveOutputsAndGetNextActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveOutputsAndGetNextActionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveOutputsAndGetNextActionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveOutputsAndGetNextActionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedAction != null && message.hasOwnProperty("expandedAction")) {
                        var error = $root.infinitusai.be.ExpandedAction.verify(message.expandedAction);
                        if (error)
                            return "expandedAction." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a SaveOutputsAndGetNextActionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveOutputsAndGetNextActionResponse} SaveOutputsAndGetNextActionResponse
                 */
                SaveOutputsAndGetNextActionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveOutputsAndGetNextActionResponse)
                        return object;
                    var message = new $root.infinitusai.be.SaveOutputsAndGetNextActionResponse();
                    if (object.expandedAction != null) {
                        if (typeof object.expandedAction !== "object")
                            throw TypeError(".infinitusai.be.SaveOutputsAndGetNextActionResponse.expandedAction: object expected");
                        message.expandedAction = $root.infinitusai.be.ExpandedAction.fromObject(object.expandedAction);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SaveOutputsAndGetNextActionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {infinitusai.be.SaveOutputsAndGetNextActionResponse} message SaveOutputsAndGetNextActionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveOutputsAndGetNextActionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.expandedAction = null;
                    if (message.expandedAction != null && message.hasOwnProperty("expandedAction"))
                        object.expandedAction = $root.infinitusai.be.ExpandedAction.toObject(message.expandedAction, options);
                    return object;
                };
    
                /**
                 * Converts this SaveOutputsAndGetNextActionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveOutputsAndGetNextActionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveOutputsAndGetNextActionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveOutputsAndGetNextActionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveOutputsAndGetNextActionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveOutputsAndGetNextActionResponse";
                };
    
                return SaveOutputsAndGetNextActionResponse;
            })();
    
            be.GetCallConversationRequest = (function() {
    
                /**
                 * Properties of a GetCallConversationRequest.
                 * @memberof infinitusai.be
                 * @interface IGetCallConversationRequest
                 * @property {string|null} [orgUuid] GetCallConversationRequest orgUuid
                 * @property {string|null} [taskUuid] GetCallConversationRequest taskUuid
                 * @property {string|null} [callUuid] GetCallConversationRequest callUuid
                 */
    
                /**
                 * Constructs a new GetCallConversationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCallConversationRequest.
                 * @implements IGetCallConversationRequest
                 * @constructor
                 * @param {infinitusai.be.IGetCallConversationRequest=} [properties] Properties to set
                 */
                function GetCallConversationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCallConversationRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @instance
                 */
                GetCallConversationRequest.prototype.orgUuid = "";
    
                /**
                 * GetCallConversationRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @instance
                 */
                GetCallConversationRequest.prototype.taskUuid = "";
    
                /**
                 * GetCallConversationRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @instance
                 */
                GetCallConversationRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new GetCallConversationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {infinitusai.be.IGetCallConversationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCallConversationRequest} GetCallConversationRequest instance
                 */
                GetCallConversationRequest.create = function create(properties) {
                    return new GetCallConversationRequest(properties);
                };
    
                /**
                 * Encodes the specified GetCallConversationRequest message. Does not implicitly {@link infinitusai.be.GetCallConversationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {infinitusai.be.IGetCallConversationRequest} message GetCallConversationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallConversationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCallConversationRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetCallConversationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {infinitusai.be.IGetCallConversationRequest} message GetCallConversationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallConversationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCallConversationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCallConversationRequest} GetCallConversationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallConversationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCallConversationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCallConversationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCallConversationRequest} GetCallConversationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallConversationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCallConversationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCallConversationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetCallConversationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCallConversationRequest} GetCallConversationRequest
                 */
                GetCallConversationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCallConversationRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetCallConversationRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCallConversationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {infinitusai.be.GetCallConversationRequest} message GetCallConversationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCallConversationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this GetCallConversationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCallConversationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCallConversationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCallConversationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCallConversationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCallConversationRequest";
                };
    
                return GetCallConversationRequest;
            })();
    
            be.ModuleStack = (function() {
    
                /**
                 * Properties of a ModuleStack.
                 * @memberof infinitusai.be
                 * @interface IModuleStack
                 * @property {number|Long|null} [moduleId] ModuleStack moduleId
                 * @property {number|Long|null} [currentSubModuleId] ModuleStack currentSubModuleId
                 * @property {boolean|null} [isSubModuleAction] ModuleStack isSubModuleAction
                 * @property {number|Long|null} [executionState] ModuleStack executionState
                 */
    
                /**
                 * Constructs a new ModuleStack.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ModuleStack.
                 * @implements IModuleStack
                 * @constructor
                 * @param {infinitusai.be.IModuleStack=} [properties] Properties to set
                 */
                function ModuleStack(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ModuleStack moduleId.
                 * @member {number|Long} moduleId
                 * @memberof infinitusai.be.ModuleStack
                 * @instance
                 */
                ModuleStack.prototype.moduleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ModuleStack currentSubModuleId.
                 * @member {number|Long} currentSubModuleId
                 * @memberof infinitusai.be.ModuleStack
                 * @instance
                 */
                ModuleStack.prototype.currentSubModuleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ModuleStack isSubModuleAction.
                 * @member {boolean} isSubModuleAction
                 * @memberof infinitusai.be.ModuleStack
                 * @instance
                 */
                ModuleStack.prototype.isSubModuleAction = false;
    
                /**
                 * ModuleStack executionState.
                 * @member {number|Long} executionState
                 * @memberof infinitusai.be.ModuleStack
                 * @instance
                 */
                ModuleStack.prototype.executionState = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new ModuleStack instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {infinitusai.be.IModuleStack=} [properties] Properties to set
                 * @returns {infinitusai.be.ModuleStack} ModuleStack instance
                 */
                ModuleStack.create = function create(properties) {
                    return new ModuleStack(properties);
                };
    
                /**
                 * Encodes the specified ModuleStack message. Does not implicitly {@link infinitusai.be.ModuleStack.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {infinitusai.be.IModuleStack} message ModuleStack message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ModuleStack.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.moduleId != null && Object.hasOwnProperty.call(message, "moduleId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.moduleId);
                    if (message.currentSubModuleId != null && Object.hasOwnProperty.call(message, "currentSubModuleId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.currentSubModuleId);
                    if (message.isSubModuleAction != null && Object.hasOwnProperty.call(message, "isSubModuleAction"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isSubModuleAction);
                    if (message.executionState != null && Object.hasOwnProperty.call(message, "executionState"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.executionState);
                    return writer;
                };
    
                /**
                 * Encodes the specified ModuleStack message, length delimited. Does not implicitly {@link infinitusai.be.ModuleStack.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {infinitusai.be.IModuleStack} message ModuleStack message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ModuleStack.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ModuleStack message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ModuleStack} ModuleStack
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ModuleStack.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ModuleStack();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.moduleId = reader.int64();
                                break;
                            }
                        case 2: {
                                message.currentSubModuleId = reader.int64();
                                break;
                            }
                        case 3: {
                                message.isSubModuleAction = reader.bool();
                                break;
                            }
                        case 4: {
                                message.executionState = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ModuleStack message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ModuleStack} ModuleStack
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ModuleStack.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ModuleStack message.
                 * @function verify
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ModuleStack.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.moduleId != null && message.hasOwnProperty("moduleId"))
                        if (!$util.isInteger(message.moduleId) && !(message.moduleId && $util.isInteger(message.moduleId.low) && $util.isInteger(message.moduleId.high)))
                            return "moduleId: integer|Long expected";
                    if (message.currentSubModuleId != null && message.hasOwnProperty("currentSubModuleId"))
                        if (!$util.isInteger(message.currentSubModuleId) && !(message.currentSubModuleId && $util.isInteger(message.currentSubModuleId.low) && $util.isInteger(message.currentSubModuleId.high)))
                            return "currentSubModuleId: integer|Long expected";
                    if (message.isSubModuleAction != null && message.hasOwnProperty("isSubModuleAction"))
                        if (typeof message.isSubModuleAction !== "boolean")
                            return "isSubModuleAction: boolean expected";
                    if (message.executionState != null && message.hasOwnProperty("executionState"))
                        if (!$util.isInteger(message.executionState) && !(message.executionState && $util.isInteger(message.executionState.low) && $util.isInteger(message.executionState.high)))
                            return "executionState: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a ModuleStack message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ModuleStack} ModuleStack
                 */
                ModuleStack.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ModuleStack)
                        return object;
                    var message = new $root.infinitusai.be.ModuleStack();
                    if (object.moduleId != null)
                        if ($util.Long)
                            (message.moduleId = $util.Long.fromValue(object.moduleId)).unsigned = false;
                        else if (typeof object.moduleId === "string")
                            message.moduleId = parseInt(object.moduleId, 10);
                        else if (typeof object.moduleId === "number")
                            message.moduleId = object.moduleId;
                        else if (typeof object.moduleId === "object")
                            message.moduleId = new $util.LongBits(object.moduleId.low >>> 0, object.moduleId.high >>> 0).toNumber();
                    if (object.currentSubModuleId != null)
                        if ($util.Long)
                            (message.currentSubModuleId = $util.Long.fromValue(object.currentSubModuleId)).unsigned = false;
                        else if (typeof object.currentSubModuleId === "string")
                            message.currentSubModuleId = parseInt(object.currentSubModuleId, 10);
                        else if (typeof object.currentSubModuleId === "number")
                            message.currentSubModuleId = object.currentSubModuleId;
                        else if (typeof object.currentSubModuleId === "object")
                            message.currentSubModuleId = new $util.LongBits(object.currentSubModuleId.low >>> 0, object.currentSubModuleId.high >>> 0).toNumber();
                    if (object.isSubModuleAction != null)
                        message.isSubModuleAction = Boolean(object.isSubModuleAction);
                    if (object.executionState != null)
                        if ($util.Long)
                            (message.executionState = $util.Long.fromValue(object.executionState)).unsigned = false;
                        else if (typeof object.executionState === "string")
                            message.executionState = parseInt(object.executionState, 10);
                        else if (typeof object.executionState === "number")
                            message.executionState = object.executionState;
                        else if (typeof object.executionState === "object")
                            message.executionState = new $util.LongBits(object.executionState.low >>> 0, object.executionState.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a ModuleStack message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {infinitusai.be.ModuleStack} message ModuleStack
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ModuleStack.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.moduleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.moduleId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.currentSubModuleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.currentSubModuleId = options.longs === String ? "0" : 0;
                        object.isSubModuleAction = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.executionState = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.executionState = options.longs === String ? "0" : 0;
                    }
                    if (message.moduleId != null && message.hasOwnProperty("moduleId"))
                        if (typeof message.moduleId === "number")
                            object.moduleId = options.longs === String ? String(message.moduleId) : message.moduleId;
                        else
                            object.moduleId = options.longs === String ? $util.Long.prototype.toString.call(message.moduleId) : options.longs === Number ? new $util.LongBits(message.moduleId.low >>> 0, message.moduleId.high >>> 0).toNumber() : message.moduleId;
                    if (message.currentSubModuleId != null && message.hasOwnProperty("currentSubModuleId"))
                        if (typeof message.currentSubModuleId === "number")
                            object.currentSubModuleId = options.longs === String ? String(message.currentSubModuleId) : message.currentSubModuleId;
                        else
                            object.currentSubModuleId = options.longs === String ? $util.Long.prototype.toString.call(message.currentSubModuleId) : options.longs === Number ? new $util.LongBits(message.currentSubModuleId.low >>> 0, message.currentSubModuleId.high >>> 0).toNumber() : message.currentSubModuleId;
                    if (message.isSubModuleAction != null && message.hasOwnProperty("isSubModuleAction"))
                        object.isSubModuleAction = message.isSubModuleAction;
                    if (message.executionState != null && message.hasOwnProperty("executionState"))
                        if (typeof message.executionState === "number")
                            object.executionState = options.longs === String ? String(message.executionState) : message.executionState;
                        else
                            object.executionState = options.longs === String ? $util.Long.prototype.toString.call(message.executionState) : options.longs === Number ? new $util.LongBits(message.executionState.low >>> 0, message.executionState.high >>> 0).toNumber() : message.executionState;
                    return object;
                };
    
                /**
                 * Converts this ModuleStack to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ModuleStack
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ModuleStack.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ModuleStack
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ModuleStack
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ModuleStack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ModuleStack";
                };
    
                return ModuleStack;
            })();
    
            be.CallConversationState = (function() {
    
                /**
                 * Properties of a CallConversationState.
                 * @memberof infinitusai.be
                 * @interface ICallConversationState
                 * @property {Object.<string,boolean>|null} [actionsAlreadyPerformed] CallConversationState actionsAlreadyPerformed
                 * @property {infinitusai.be.IOutputMap|null} [currentOutputs] CallConversationState currentOutputs
                 * @property {Array.<infinitusai.be.IModuleStack>|null} [currentModuleStack] CallConversationState currentModuleStack
                 */
    
                /**
                 * Constructs a new CallConversationState.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallConversationState.
                 * @implements ICallConversationState
                 * @constructor
                 * @param {infinitusai.be.ICallConversationState=} [properties] Properties to set
                 */
                function CallConversationState(properties) {
                    this.actionsAlreadyPerformed = {};
                    this.currentModuleStack = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallConversationState actionsAlreadyPerformed.
                 * @member {Object.<string,boolean>} actionsAlreadyPerformed
                 * @memberof infinitusai.be.CallConversationState
                 * @instance
                 */
                CallConversationState.prototype.actionsAlreadyPerformed = $util.emptyObject;
    
                /**
                 * CallConversationState currentOutputs.
                 * @member {infinitusai.be.IOutputMap|null|undefined} currentOutputs
                 * @memberof infinitusai.be.CallConversationState
                 * @instance
                 */
                CallConversationState.prototype.currentOutputs = null;
    
                /**
                 * CallConversationState currentModuleStack.
                 * @member {Array.<infinitusai.be.IModuleStack>} currentModuleStack
                 * @memberof infinitusai.be.CallConversationState
                 * @instance
                 */
                CallConversationState.prototype.currentModuleStack = $util.emptyArray;
    
                /**
                 * Creates a new CallConversationState instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {infinitusai.be.ICallConversationState=} [properties] Properties to set
                 * @returns {infinitusai.be.CallConversationState} CallConversationState instance
                 */
                CallConversationState.create = function create(properties) {
                    return new CallConversationState(properties);
                };
    
                /**
                 * Encodes the specified CallConversationState message. Does not implicitly {@link infinitusai.be.CallConversationState.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {infinitusai.be.ICallConversationState} message CallConversationState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallConversationState.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.actionsAlreadyPerformed != null && Object.hasOwnProperty.call(message, "actionsAlreadyPerformed"))
                        for (var keys = Object.keys(message.actionsAlreadyPerformed), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.actionsAlreadyPerformed[keys[i]]).ldelim();
                    if (message.currentOutputs != null && Object.hasOwnProperty.call(message, "currentOutputs"))
                        $root.infinitusai.be.OutputMap.encode(message.currentOutputs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.currentModuleStack != null && message.currentModuleStack.length)
                        for (var i = 0; i < message.currentModuleStack.length; ++i)
                            $root.infinitusai.be.ModuleStack.encode(message.currentModuleStack[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallConversationState message, length delimited. Does not implicitly {@link infinitusai.be.CallConversationState.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {infinitusai.be.ICallConversationState} message CallConversationState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallConversationState.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallConversationState message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallConversationState} CallConversationState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallConversationState.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallConversationState(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.actionsAlreadyPerformed === $util.emptyObject)
                                    message.actionsAlreadyPerformed = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = false;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = reader.bool();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.actionsAlreadyPerformed[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 2: {
                                message.currentOutputs = $root.infinitusai.be.OutputMap.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.currentModuleStack && message.currentModuleStack.length))
                                    message.currentModuleStack = [];
                                message.currentModuleStack.push($root.infinitusai.be.ModuleStack.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallConversationState message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallConversationState} CallConversationState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallConversationState.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallConversationState message.
                 * @function verify
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallConversationState.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.actionsAlreadyPerformed != null && message.hasOwnProperty("actionsAlreadyPerformed")) {
                        if (!$util.isObject(message.actionsAlreadyPerformed))
                            return "actionsAlreadyPerformed: object expected";
                        var key = Object.keys(message.actionsAlreadyPerformed);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "actionsAlreadyPerformed: integer|Long key{k:int64} expected";
                            if (typeof message.actionsAlreadyPerformed[key[i]] !== "boolean")
                                return "actionsAlreadyPerformed: boolean{k:int64} expected";
                        }
                    }
                    if (message.currentOutputs != null && message.hasOwnProperty("currentOutputs")) {
                        var error = $root.infinitusai.be.OutputMap.verify(message.currentOutputs);
                        if (error)
                            return "currentOutputs." + error;
                    }
                    if (message.currentModuleStack != null && message.hasOwnProperty("currentModuleStack")) {
                        if (!Array.isArray(message.currentModuleStack))
                            return "currentModuleStack: array expected";
                        for (var i = 0; i < message.currentModuleStack.length; ++i) {
                            var error = $root.infinitusai.be.ModuleStack.verify(message.currentModuleStack[i]);
                            if (error)
                                return "currentModuleStack." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a CallConversationState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallConversationState} CallConversationState
                 */
                CallConversationState.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallConversationState)
                        return object;
                    var message = new $root.infinitusai.be.CallConversationState();
                    if (object.actionsAlreadyPerformed) {
                        if (typeof object.actionsAlreadyPerformed !== "object")
                            throw TypeError(".infinitusai.be.CallConversationState.actionsAlreadyPerformed: object expected");
                        message.actionsAlreadyPerformed = {};
                        for (var keys = Object.keys(object.actionsAlreadyPerformed), i = 0; i < keys.length; ++i)
                            message.actionsAlreadyPerformed[keys[i]] = Boolean(object.actionsAlreadyPerformed[keys[i]]);
                    }
                    if (object.currentOutputs != null) {
                        if (typeof object.currentOutputs !== "object")
                            throw TypeError(".infinitusai.be.CallConversationState.currentOutputs: object expected");
                        message.currentOutputs = $root.infinitusai.be.OutputMap.fromObject(object.currentOutputs);
                    }
                    if (object.currentModuleStack) {
                        if (!Array.isArray(object.currentModuleStack))
                            throw TypeError(".infinitusai.be.CallConversationState.currentModuleStack: array expected");
                        message.currentModuleStack = [];
                        for (var i = 0; i < object.currentModuleStack.length; ++i) {
                            if (typeof object.currentModuleStack[i] !== "object")
                                throw TypeError(".infinitusai.be.CallConversationState.currentModuleStack: object expected");
                            message.currentModuleStack[i] = $root.infinitusai.be.ModuleStack.fromObject(object.currentModuleStack[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallConversationState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {infinitusai.be.CallConversationState} message CallConversationState
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallConversationState.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.currentModuleStack = [];
                    if (options.objects || options.defaults)
                        object.actionsAlreadyPerformed = {};
                    if (options.defaults)
                        object.currentOutputs = null;
                    var keys2;
                    if (message.actionsAlreadyPerformed && (keys2 = Object.keys(message.actionsAlreadyPerformed)).length) {
                        object.actionsAlreadyPerformed = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.actionsAlreadyPerformed[keys2[j]] = message.actionsAlreadyPerformed[keys2[j]];
                    }
                    if (message.currentOutputs != null && message.hasOwnProperty("currentOutputs"))
                        object.currentOutputs = $root.infinitusai.be.OutputMap.toObject(message.currentOutputs, options);
                    if (message.currentModuleStack && message.currentModuleStack.length) {
                        object.currentModuleStack = [];
                        for (var j = 0; j < message.currentModuleStack.length; ++j)
                            object.currentModuleStack[j] = $root.infinitusai.be.ModuleStack.toObject(message.currentModuleStack[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this CallConversationState to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallConversationState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallConversationState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallConversationState
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallConversationState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallConversationState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallConversationState";
                };
    
                return CallConversationState;
            })();
    
            be.ConditionalActionToUtteranceMappingList = (function() {
    
                /**
                 * Properties of a ConditionalActionToUtteranceMappingList.
                 * @memberof infinitusai.be
                 * @interface IConditionalActionToUtteranceMappingList
                 * @property {Array.<infinitusai.be.IConditionalActionToUtteranceMapping>|null} [mappings] ConditionalActionToUtteranceMappingList mappings
                 */
    
                /**
                 * Constructs a new ConditionalActionToUtteranceMappingList.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ConditionalActionToUtteranceMappingList.
                 * @implements IConditionalActionToUtteranceMappingList
                 * @constructor
                 * @param {infinitusai.be.IConditionalActionToUtteranceMappingList=} [properties] Properties to set
                 */
                function ConditionalActionToUtteranceMappingList(properties) {
                    this.mappings = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ConditionalActionToUtteranceMappingList mappings.
                 * @member {Array.<infinitusai.be.IConditionalActionToUtteranceMapping>} mappings
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @instance
                 */
                ConditionalActionToUtteranceMappingList.prototype.mappings = $util.emptyArray;
    
                /**
                 * Creates a new ConditionalActionToUtteranceMappingList instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {infinitusai.be.IConditionalActionToUtteranceMappingList=} [properties] Properties to set
                 * @returns {infinitusai.be.ConditionalActionToUtteranceMappingList} ConditionalActionToUtteranceMappingList instance
                 */
                ConditionalActionToUtteranceMappingList.create = function create(properties) {
                    return new ConditionalActionToUtteranceMappingList(properties);
                };
    
                /**
                 * Encodes the specified ConditionalActionToUtteranceMappingList message. Does not implicitly {@link infinitusai.be.ConditionalActionToUtteranceMappingList.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {infinitusai.be.IConditionalActionToUtteranceMappingList} message ConditionalActionToUtteranceMappingList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConditionalActionToUtteranceMappingList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.mappings != null && message.mappings.length)
                        for (var i = 0; i < message.mappings.length; ++i)
                            $root.infinitusai.be.ConditionalActionToUtteranceMapping.encode(message.mappings[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ConditionalActionToUtteranceMappingList message, length delimited. Does not implicitly {@link infinitusai.be.ConditionalActionToUtteranceMappingList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {infinitusai.be.IConditionalActionToUtteranceMappingList} message ConditionalActionToUtteranceMappingList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConditionalActionToUtteranceMappingList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ConditionalActionToUtteranceMappingList message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ConditionalActionToUtteranceMappingList} ConditionalActionToUtteranceMappingList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConditionalActionToUtteranceMappingList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ConditionalActionToUtteranceMappingList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.mappings && message.mappings.length))
                                    message.mappings = [];
                                message.mappings.push($root.infinitusai.be.ConditionalActionToUtteranceMapping.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ConditionalActionToUtteranceMappingList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ConditionalActionToUtteranceMappingList} ConditionalActionToUtteranceMappingList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConditionalActionToUtteranceMappingList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ConditionalActionToUtteranceMappingList message.
                 * @function verify
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConditionalActionToUtteranceMappingList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.mappings != null && message.hasOwnProperty("mappings")) {
                        if (!Array.isArray(message.mappings))
                            return "mappings: array expected";
                        for (var i = 0; i < message.mappings.length; ++i) {
                            var error = $root.infinitusai.be.ConditionalActionToUtteranceMapping.verify(message.mappings[i]);
                            if (error)
                                return "mappings." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ConditionalActionToUtteranceMappingList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ConditionalActionToUtteranceMappingList} ConditionalActionToUtteranceMappingList
                 */
                ConditionalActionToUtteranceMappingList.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ConditionalActionToUtteranceMappingList)
                        return object;
                    var message = new $root.infinitusai.be.ConditionalActionToUtteranceMappingList();
                    if (object.mappings) {
                        if (!Array.isArray(object.mappings))
                            throw TypeError(".infinitusai.be.ConditionalActionToUtteranceMappingList.mappings: array expected");
                        message.mappings = [];
                        for (var i = 0; i < object.mappings.length; ++i) {
                            if (typeof object.mappings[i] !== "object")
                                throw TypeError(".infinitusai.be.ConditionalActionToUtteranceMappingList.mappings: object expected");
                            message.mappings[i] = $root.infinitusai.be.ConditionalActionToUtteranceMapping.fromObject(object.mappings[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ConditionalActionToUtteranceMappingList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {infinitusai.be.ConditionalActionToUtteranceMappingList} message ConditionalActionToUtteranceMappingList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConditionalActionToUtteranceMappingList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.mappings = [];
                    if (message.mappings && message.mappings.length) {
                        object.mappings = [];
                        for (var j = 0; j < message.mappings.length; ++j)
                            object.mappings[j] = $root.infinitusai.be.ConditionalActionToUtteranceMapping.toObject(message.mappings[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ConditionalActionToUtteranceMappingList to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConditionalActionToUtteranceMappingList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ConditionalActionToUtteranceMappingList
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMappingList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ConditionalActionToUtteranceMappingList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ConditionalActionToUtteranceMappingList";
                };
    
                return ConditionalActionToUtteranceMappingList;
            })();
    
            be.ExpandedUtteranceList = (function() {
    
                /**
                 * Properties of an ExpandedUtteranceList.
                 * @memberof infinitusai.be
                 * @interface IExpandedUtteranceList
                 * @property {Array.<number|Long>|null} [expandedUtteranceIds] ExpandedUtteranceList expandedUtteranceIds
                 */
    
                /**
                 * Constructs a new ExpandedUtteranceList.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedUtteranceList.
                 * @implements IExpandedUtteranceList
                 * @constructor
                 * @param {infinitusai.be.IExpandedUtteranceList=} [properties] Properties to set
                 */
                function ExpandedUtteranceList(properties) {
                    this.expandedUtteranceIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedUtteranceList expandedUtteranceIds.
                 * @member {Array.<number|Long>} expandedUtteranceIds
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @instance
                 */
                ExpandedUtteranceList.prototype.expandedUtteranceIds = $util.emptyArray;
    
                /**
                 * Creates a new ExpandedUtteranceList instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {infinitusai.be.IExpandedUtteranceList=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedUtteranceList} ExpandedUtteranceList instance
                 */
                ExpandedUtteranceList.create = function create(properties) {
                    return new ExpandedUtteranceList(properties);
                };
    
                /**
                 * Encodes the specified ExpandedUtteranceList message. Does not implicitly {@link infinitusai.be.ExpandedUtteranceList.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {infinitusai.be.IExpandedUtteranceList} message ExpandedUtteranceList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedUtteranceList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedUtteranceIds != null && message.expandedUtteranceIds.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.expandedUtteranceIds.length; ++i)
                            writer.int64(message.expandedUtteranceIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedUtteranceList message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedUtteranceList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {infinitusai.be.IExpandedUtteranceList} message ExpandedUtteranceList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedUtteranceList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedUtteranceList message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedUtteranceList} ExpandedUtteranceList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedUtteranceList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedUtteranceList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.expandedUtteranceIds && message.expandedUtteranceIds.length))
                                    message.expandedUtteranceIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.expandedUtteranceIds.push(reader.int64());
                                } else
                                    message.expandedUtteranceIds.push(reader.int64());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedUtteranceList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedUtteranceList} ExpandedUtteranceList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedUtteranceList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedUtteranceList message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedUtteranceList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedUtteranceIds != null && message.hasOwnProperty("expandedUtteranceIds")) {
                        if (!Array.isArray(message.expandedUtteranceIds))
                            return "expandedUtteranceIds: array expected";
                        for (var i = 0; i < message.expandedUtteranceIds.length; ++i)
                            if (!$util.isInteger(message.expandedUtteranceIds[i]) && !(message.expandedUtteranceIds[i] && $util.isInteger(message.expandedUtteranceIds[i].low) && $util.isInteger(message.expandedUtteranceIds[i].high)))
                                return "expandedUtteranceIds: integer|Long[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedUtteranceList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedUtteranceList} ExpandedUtteranceList
                 */
                ExpandedUtteranceList.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedUtteranceList)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedUtteranceList();
                    if (object.expandedUtteranceIds) {
                        if (!Array.isArray(object.expandedUtteranceIds))
                            throw TypeError(".infinitusai.be.ExpandedUtteranceList.expandedUtteranceIds: array expected");
                        message.expandedUtteranceIds = [];
                        for (var i = 0; i < object.expandedUtteranceIds.length; ++i)
                            if ($util.Long)
                                (message.expandedUtteranceIds[i] = $util.Long.fromValue(object.expandedUtteranceIds[i])).unsigned = false;
                            else if (typeof object.expandedUtteranceIds[i] === "string")
                                message.expandedUtteranceIds[i] = parseInt(object.expandedUtteranceIds[i], 10);
                            else if (typeof object.expandedUtteranceIds[i] === "number")
                                message.expandedUtteranceIds[i] = object.expandedUtteranceIds[i];
                            else if (typeof object.expandedUtteranceIds[i] === "object")
                                message.expandedUtteranceIds[i] = new $util.LongBits(object.expandedUtteranceIds[i].low >>> 0, object.expandedUtteranceIds[i].high >>> 0).toNumber();
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedUtteranceList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {infinitusai.be.ExpandedUtteranceList} message ExpandedUtteranceList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedUtteranceList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.expandedUtteranceIds = [];
                    if (message.expandedUtteranceIds && message.expandedUtteranceIds.length) {
                        object.expandedUtteranceIds = [];
                        for (var j = 0; j < message.expandedUtteranceIds.length; ++j)
                            if (typeof message.expandedUtteranceIds[j] === "number")
                                object.expandedUtteranceIds[j] = options.longs === String ? String(message.expandedUtteranceIds[j]) : message.expandedUtteranceIds[j];
                            else
                                object.expandedUtteranceIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.expandedUtteranceIds[j]) : options.longs === Number ? new $util.LongBits(message.expandedUtteranceIds[j].low >>> 0, message.expandedUtteranceIds[j].high >>> 0).toNumber() : message.expandedUtteranceIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ExpandedUtteranceList to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedUtteranceList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedUtteranceList
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedUtteranceList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedUtteranceList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedUtteranceList";
                };
    
                return ExpandedUtteranceList;
            })();
    
            be.ExpandedActionList = (function() {
    
                /**
                 * Properties of an ExpandedActionList.
                 * @memberof infinitusai.be
                 * @interface IExpandedActionList
                 * @property {Array.<number|Long>|null} [expandedActionIds] ExpandedActionList expandedActionIds
                 */
    
                /**
                 * Constructs a new ExpandedActionList.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedActionList.
                 * @implements IExpandedActionList
                 * @constructor
                 * @param {infinitusai.be.IExpandedActionList=} [properties] Properties to set
                 */
                function ExpandedActionList(properties) {
                    this.expandedActionIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedActionList expandedActionIds.
                 * @member {Array.<number|Long>} expandedActionIds
                 * @memberof infinitusai.be.ExpandedActionList
                 * @instance
                 */
                ExpandedActionList.prototype.expandedActionIds = $util.emptyArray;
    
                /**
                 * Creates a new ExpandedActionList instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {infinitusai.be.IExpandedActionList=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedActionList} ExpandedActionList instance
                 */
                ExpandedActionList.create = function create(properties) {
                    return new ExpandedActionList(properties);
                };
    
                /**
                 * Encodes the specified ExpandedActionList message. Does not implicitly {@link infinitusai.be.ExpandedActionList.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {infinitusai.be.IExpandedActionList} message ExpandedActionList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedActionList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedActionIds != null && message.expandedActionIds.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.expandedActionIds.length; ++i)
                            writer.int64(message.expandedActionIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedActionList message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedActionList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {infinitusai.be.IExpandedActionList} message ExpandedActionList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedActionList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedActionList message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedActionList} ExpandedActionList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedActionList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedActionList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.expandedActionIds && message.expandedActionIds.length))
                                    message.expandedActionIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.expandedActionIds.push(reader.int64());
                                } else
                                    message.expandedActionIds.push(reader.int64());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedActionList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedActionList} ExpandedActionList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedActionList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedActionList message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedActionList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedActionIds != null && message.hasOwnProperty("expandedActionIds")) {
                        if (!Array.isArray(message.expandedActionIds))
                            return "expandedActionIds: array expected";
                        for (var i = 0; i < message.expandedActionIds.length; ++i)
                            if (!$util.isInteger(message.expandedActionIds[i]) && !(message.expandedActionIds[i] && $util.isInteger(message.expandedActionIds[i].low) && $util.isInteger(message.expandedActionIds[i].high)))
                                return "expandedActionIds: integer|Long[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedActionList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedActionList} ExpandedActionList
                 */
                ExpandedActionList.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedActionList)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedActionList();
                    if (object.expandedActionIds) {
                        if (!Array.isArray(object.expandedActionIds))
                            throw TypeError(".infinitusai.be.ExpandedActionList.expandedActionIds: array expected");
                        message.expandedActionIds = [];
                        for (var i = 0; i < object.expandedActionIds.length; ++i)
                            if ($util.Long)
                                (message.expandedActionIds[i] = $util.Long.fromValue(object.expandedActionIds[i])).unsigned = false;
                            else if (typeof object.expandedActionIds[i] === "string")
                                message.expandedActionIds[i] = parseInt(object.expandedActionIds[i], 10);
                            else if (typeof object.expandedActionIds[i] === "number")
                                message.expandedActionIds[i] = object.expandedActionIds[i];
                            else if (typeof object.expandedActionIds[i] === "object")
                                message.expandedActionIds[i] = new $util.LongBits(object.expandedActionIds[i].low >>> 0, object.expandedActionIds[i].high >>> 0).toNumber();
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedActionList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {infinitusai.be.ExpandedActionList} message ExpandedActionList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedActionList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.expandedActionIds = [];
                    if (message.expandedActionIds && message.expandedActionIds.length) {
                        object.expandedActionIds = [];
                        for (var j = 0; j < message.expandedActionIds.length; ++j)
                            if (typeof message.expandedActionIds[j] === "number")
                                object.expandedActionIds[j] = options.longs === String ? String(message.expandedActionIds[j]) : message.expandedActionIds[j];
                            else
                                object.expandedActionIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.expandedActionIds[j]) : options.longs === Number ? new $util.LongBits(message.expandedActionIds[j].low >>> 0, message.expandedActionIds[j].high >>> 0).toNumber() : message.expandedActionIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ExpandedActionList to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedActionList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedActionList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedActionList
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedActionList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedActionList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedActionList";
                };
    
                return ExpandedActionList;
            })();
    
            be.ExpandedModuleList = (function() {
    
                /**
                 * Properties of an ExpandedModuleList.
                 * @memberof infinitusai.be
                 * @interface IExpandedModuleList
                 * @property {Array.<number|Long>|null} [expandedModuleIds] ExpandedModuleList expandedModuleIds
                 */
    
                /**
                 * Constructs a new ExpandedModuleList.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedModuleList.
                 * @implements IExpandedModuleList
                 * @constructor
                 * @param {infinitusai.be.IExpandedModuleList=} [properties] Properties to set
                 */
                function ExpandedModuleList(properties) {
                    this.expandedModuleIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedModuleList expandedModuleIds.
                 * @member {Array.<number|Long>} expandedModuleIds
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @instance
                 */
                ExpandedModuleList.prototype.expandedModuleIds = $util.emptyArray;
    
                /**
                 * Creates a new ExpandedModuleList instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {infinitusai.be.IExpandedModuleList=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedModuleList} ExpandedModuleList instance
                 */
                ExpandedModuleList.create = function create(properties) {
                    return new ExpandedModuleList(properties);
                };
    
                /**
                 * Encodes the specified ExpandedModuleList message. Does not implicitly {@link infinitusai.be.ExpandedModuleList.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {infinitusai.be.IExpandedModuleList} message ExpandedModuleList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedModuleList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedModuleIds != null && message.expandedModuleIds.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.expandedModuleIds.length; ++i)
                            writer.int64(message.expandedModuleIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedModuleList message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedModuleList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {infinitusai.be.IExpandedModuleList} message ExpandedModuleList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedModuleList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedModuleList message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedModuleList} ExpandedModuleList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedModuleList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedModuleList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.expandedModuleIds && message.expandedModuleIds.length))
                                    message.expandedModuleIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.expandedModuleIds.push(reader.int64());
                                } else
                                    message.expandedModuleIds.push(reader.int64());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedModuleList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedModuleList} ExpandedModuleList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedModuleList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedModuleList message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedModuleList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedModuleIds != null && message.hasOwnProperty("expandedModuleIds")) {
                        if (!Array.isArray(message.expandedModuleIds))
                            return "expandedModuleIds: array expected";
                        for (var i = 0; i < message.expandedModuleIds.length; ++i)
                            if (!$util.isInteger(message.expandedModuleIds[i]) && !(message.expandedModuleIds[i] && $util.isInteger(message.expandedModuleIds[i].low) && $util.isInteger(message.expandedModuleIds[i].high)))
                                return "expandedModuleIds: integer|Long[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedModuleList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedModuleList} ExpandedModuleList
                 */
                ExpandedModuleList.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedModuleList)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedModuleList();
                    if (object.expandedModuleIds) {
                        if (!Array.isArray(object.expandedModuleIds))
                            throw TypeError(".infinitusai.be.ExpandedModuleList.expandedModuleIds: array expected");
                        message.expandedModuleIds = [];
                        for (var i = 0; i < object.expandedModuleIds.length; ++i)
                            if ($util.Long)
                                (message.expandedModuleIds[i] = $util.Long.fromValue(object.expandedModuleIds[i])).unsigned = false;
                            else if (typeof object.expandedModuleIds[i] === "string")
                                message.expandedModuleIds[i] = parseInt(object.expandedModuleIds[i], 10);
                            else if (typeof object.expandedModuleIds[i] === "number")
                                message.expandedModuleIds[i] = object.expandedModuleIds[i];
                            else if (typeof object.expandedModuleIds[i] === "object")
                                message.expandedModuleIds[i] = new $util.LongBits(object.expandedModuleIds[i].low >>> 0, object.expandedModuleIds[i].high >>> 0).toNumber();
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedModuleList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {infinitusai.be.ExpandedModuleList} message ExpandedModuleList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedModuleList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.expandedModuleIds = [];
                    if (message.expandedModuleIds && message.expandedModuleIds.length) {
                        object.expandedModuleIds = [];
                        for (var j = 0; j < message.expandedModuleIds.length; ++j)
                            if (typeof message.expandedModuleIds[j] === "number")
                                object.expandedModuleIds[j] = options.longs === String ? String(message.expandedModuleIds[j]) : message.expandedModuleIds[j];
                            else
                                object.expandedModuleIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.expandedModuleIds[j]) : options.longs === Number ? new $util.LongBits(message.expandedModuleIds[j].low >>> 0, message.expandedModuleIds[j].high >>> 0).toNumber() : message.expandedModuleIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ExpandedModuleList to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedModuleList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedModuleList
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedModuleList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedModuleList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedModuleList";
                };
    
                return ExpandedModuleList;
            })();
    
            be.ExpandedOutputList = (function() {
    
                /**
                 * Properties of an ExpandedOutputList.
                 * @memberof infinitusai.be
                 * @interface IExpandedOutputList
                 * @property {Array.<number|Long>|null} [expandedOutputIds] ExpandedOutputList expandedOutputIds
                 */
    
                /**
                 * Constructs a new ExpandedOutputList.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedOutputList.
                 * @implements IExpandedOutputList
                 * @constructor
                 * @param {infinitusai.be.IExpandedOutputList=} [properties] Properties to set
                 */
                function ExpandedOutputList(properties) {
                    this.expandedOutputIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedOutputList expandedOutputIds.
                 * @member {Array.<number|Long>} expandedOutputIds
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @instance
                 */
                ExpandedOutputList.prototype.expandedOutputIds = $util.emptyArray;
    
                /**
                 * Creates a new ExpandedOutputList instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {infinitusai.be.IExpandedOutputList=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedOutputList} ExpandedOutputList instance
                 */
                ExpandedOutputList.create = function create(properties) {
                    return new ExpandedOutputList(properties);
                };
    
                /**
                 * Encodes the specified ExpandedOutputList message. Does not implicitly {@link infinitusai.be.ExpandedOutputList.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {infinitusai.be.IExpandedOutputList} message ExpandedOutputList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedOutputList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedOutputIds != null && message.expandedOutputIds.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.expandedOutputIds.length; ++i)
                            writer.int64(message.expandedOutputIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedOutputList message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedOutputList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {infinitusai.be.IExpandedOutputList} message ExpandedOutputList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedOutputList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedOutputList message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedOutputList} ExpandedOutputList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedOutputList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedOutputList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.expandedOutputIds && message.expandedOutputIds.length))
                                    message.expandedOutputIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.expandedOutputIds.push(reader.int64());
                                } else
                                    message.expandedOutputIds.push(reader.int64());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedOutputList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedOutputList} ExpandedOutputList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedOutputList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedOutputList message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedOutputList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedOutputIds != null && message.hasOwnProperty("expandedOutputIds")) {
                        if (!Array.isArray(message.expandedOutputIds))
                            return "expandedOutputIds: array expected";
                        for (var i = 0; i < message.expandedOutputIds.length; ++i)
                            if (!$util.isInteger(message.expandedOutputIds[i]) && !(message.expandedOutputIds[i] && $util.isInteger(message.expandedOutputIds[i].low) && $util.isInteger(message.expandedOutputIds[i].high)))
                                return "expandedOutputIds: integer|Long[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedOutputList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedOutputList} ExpandedOutputList
                 */
                ExpandedOutputList.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedOutputList)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedOutputList();
                    if (object.expandedOutputIds) {
                        if (!Array.isArray(object.expandedOutputIds))
                            throw TypeError(".infinitusai.be.ExpandedOutputList.expandedOutputIds: array expected");
                        message.expandedOutputIds = [];
                        for (var i = 0; i < object.expandedOutputIds.length; ++i)
                            if ($util.Long)
                                (message.expandedOutputIds[i] = $util.Long.fromValue(object.expandedOutputIds[i])).unsigned = false;
                            else if (typeof object.expandedOutputIds[i] === "string")
                                message.expandedOutputIds[i] = parseInt(object.expandedOutputIds[i], 10);
                            else if (typeof object.expandedOutputIds[i] === "number")
                                message.expandedOutputIds[i] = object.expandedOutputIds[i];
                            else if (typeof object.expandedOutputIds[i] === "object")
                                message.expandedOutputIds[i] = new $util.LongBits(object.expandedOutputIds[i].low >>> 0, object.expandedOutputIds[i].high >>> 0).toNumber();
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedOutputList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {infinitusai.be.ExpandedOutputList} message ExpandedOutputList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedOutputList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.expandedOutputIds = [];
                    if (message.expandedOutputIds && message.expandedOutputIds.length) {
                        object.expandedOutputIds = [];
                        for (var j = 0; j < message.expandedOutputIds.length; ++j)
                            if (typeof message.expandedOutputIds[j] === "number")
                                object.expandedOutputIds[j] = options.longs === String ? String(message.expandedOutputIds[j]) : message.expandedOutputIds[j];
                            else
                                object.expandedOutputIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.expandedOutputIds[j]) : options.longs === Number ? new $util.LongBits(message.expandedOutputIds[j].low >>> 0, message.expandedOutputIds[j].high >>> 0).toNumber() : message.expandedOutputIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ExpandedOutputList to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedOutputList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedOutputList
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedOutputList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedOutputList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedOutputList";
                };
    
                return ExpandedOutputList;
            })();
    
            be.BaseCallConversation = (function() {
    
                /**
                 * Properties of a BaseCallConversation.
                 * @memberof infinitusai.be
                 * @interface IBaseCallConversation
                 * @property {Object.<string,infinitusai.be.IStandardUtterance>|null} [standardUtterances] BaseCallConversation standardUtterances
                 * @property {Object.<string,infinitusai.be.IExpandedUtterance>|null} [expandedUtterances] BaseCallConversation expandedUtterances
                 * @property {Object.<string,infinitusai.be.IExpandedUtteranceList>|null} [standardUtteranceToExpandedUtteranceMapping] BaseCallConversation standardUtteranceToExpandedUtteranceMapping
                 * @property {Object.<string,infinitusai.be.IStandardAction>|null} [standardActions] BaseCallConversation standardActions
                 * @property {Object.<string,infinitusai.be.IExpandedAction>|null} [expandedActions] BaseCallConversation expandedActions
                 * @property {Object.<string,infinitusai.be.IExpandedActionList>|null} [standardActionToExpandedActionMapping] BaseCallConversation standardActionToExpandedActionMapping
                 * @property {Object.<string,infinitusai.be.IStandardCondition>|null} [standardConditions] BaseCallConversation standardConditions
                 * @property {Object.<string,infinitusai.be.IStandardModule>|null} [standardModules] BaseCallConversation standardModules
                 * @property {Object.<string,infinitusai.be.IExpandedModule>|null} [expandedModules] BaseCallConversation expandedModules
                 * @property {Object.<string,infinitusai.be.IExpandedModuleList>|null} [standardModuleToExpandedModuleMapping] BaseCallConversation standardModuleToExpandedModuleMapping
                 * @property {Object.<string,infinitusai.be.IStandardOutput>|null} [standardOutputs] BaseCallConversation standardOutputs
                 * @property {Object.<string,infinitusai.be.IExpandedOutput>|null} [expandedOutputs] BaseCallConversation expandedOutputs
                 * @property {Object.<string,infinitusai.be.IExpandedOutputList>|null} [standardOutputToExpandedOutputMapping] BaseCallConversation standardOutputToExpandedOutputMapping
                 * @property {Object.<string,google.protobuf.IAny>|null} [taskInputs] BaseCallConversation taskInputs
                 * @property {infinitusai.be.IBVCallInputs|null} [bvCallInputsProto] BaseCallConversation bvCallInputsProto
                 * @property {Object.<string,infinitusai.be.IConditionalActionToUtteranceMappingList>|null} [actionUtteranceMapping] BaseCallConversation actionUtteranceMapping
                 * @property {Object.<string,infinitusai.be.IConditionalActionToUtteranceMappingList>|null} [expandedActionToExpandedUtteranceMapping] BaseCallConversation expandedActionToExpandedUtteranceMapping
                 * @property {number|Long|null} [conversationStandardModule] BaseCallConversation conversationStandardModule
                 */
    
                /**
                 * Constructs a new BaseCallConversation.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BaseCallConversation.
                 * @implements IBaseCallConversation
                 * @constructor
                 * @param {infinitusai.be.IBaseCallConversation=} [properties] Properties to set
                 */
                function BaseCallConversation(properties) {
                    this.standardUtterances = {};
                    this.expandedUtterances = {};
                    this.standardUtteranceToExpandedUtteranceMapping = {};
                    this.standardActions = {};
                    this.expandedActions = {};
                    this.standardActionToExpandedActionMapping = {};
                    this.standardConditions = {};
                    this.standardModules = {};
                    this.expandedModules = {};
                    this.standardModuleToExpandedModuleMapping = {};
                    this.standardOutputs = {};
                    this.expandedOutputs = {};
                    this.standardOutputToExpandedOutputMapping = {};
                    this.taskInputs = {};
                    this.actionUtteranceMapping = {};
                    this.expandedActionToExpandedUtteranceMapping = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BaseCallConversation standardUtterances.
                 * @member {Object.<string,infinitusai.be.IStandardUtterance>} standardUtterances
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardUtterances = $util.emptyObject;
    
                /**
                 * BaseCallConversation expandedUtterances.
                 * @member {Object.<string,infinitusai.be.IExpandedUtterance>} expandedUtterances
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.expandedUtterances = $util.emptyObject;
    
                /**
                 * BaseCallConversation standardUtteranceToExpandedUtteranceMapping.
                 * @member {Object.<string,infinitusai.be.IExpandedUtteranceList>} standardUtteranceToExpandedUtteranceMapping
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardUtteranceToExpandedUtteranceMapping = $util.emptyObject;
    
                /**
                 * BaseCallConversation standardActions.
                 * @member {Object.<string,infinitusai.be.IStandardAction>} standardActions
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardActions = $util.emptyObject;
    
                /**
                 * BaseCallConversation expandedActions.
                 * @member {Object.<string,infinitusai.be.IExpandedAction>} expandedActions
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.expandedActions = $util.emptyObject;
    
                /**
                 * BaseCallConversation standardActionToExpandedActionMapping.
                 * @member {Object.<string,infinitusai.be.IExpandedActionList>} standardActionToExpandedActionMapping
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardActionToExpandedActionMapping = $util.emptyObject;
    
                /**
                 * BaseCallConversation standardConditions.
                 * @member {Object.<string,infinitusai.be.IStandardCondition>} standardConditions
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardConditions = $util.emptyObject;
    
                /**
                 * BaseCallConversation standardModules.
                 * @member {Object.<string,infinitusai.be.IStandardModule>} standardModules
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardModules = $util.emptyObject;
    
                /**
                 * BaseCallConversation expandedModules.
                 * @member {Object.<string,infinitusai.be.IExpandedModule>} expandedModules
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.expandedModules = $util.emptyObject;
    
                /**
                 * BaseCallConversation standardModuleToExpandedModuleMapping.
                 * @member {Object.<string,infinitusai.be.IExpandedModuleList>} standardModuleToExpandedModuleMapping
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardModuleToExpandedModuleMapping = $util.emptyObject;
    
                /**
                 * BaseCallConversation standardOutputs.
                 * @member {Object.<string,infinitusai.be.IStandardOutput>} standardOutputs
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardOutputs = $util.emptyObject;
    
                /**
                 * BaseCallConversation expandedOutputs.
                 * @member {Object.<string,infinitusai.be.IExpandedOutput>} expandedOutputs
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.expandedOutputs = $util.emptyObject;
    
                /**
                 * BaseCallConversation standardOutputToExpandedOutputMapping.
                 * @member {Object.<string,infinitusai.be.IExpandedOutputList>} standardOutputToExpandedOutputMapping
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.standardOutputToExpandedOutputMapping = $util.emptyObject;
    
                /**
                 * BaseCallConversation taskInputs.
                 * @member {Object.<string,google.protobuf.IAny>} taskInputs
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.taskInputs = $util.emptyObject;
    
                /**
                 * BaseCallConversation bvCallInputsProto.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} bvCallInputsProto
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.bvCallInputsProto = null;
    
                /**
                 * BaseCallConversation actionUtteranceMapping.
                 * @member {Object.<string,infinitusai.be.IConditionalActionToUtteranceMappingList>} actionUtteranceMapping
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.actionUtteranceMapping = $util.emptyObject;
    
                /**
                 * BaseCallConversation expandedActionToExpandedUtteranceMapping.
                 * @member {Object.<string,infinitusai.be.IConditionalActionToUtteranceMappingList>} expandedActionToExpandedUtteranceMapping
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.expandedActionToExpandedUtteranceMapping = $util.emptyObject;
    
                /**
                 * BaseCallConversation conversationStandardModule.
                 * @member {number|Long} conversationStandardModule
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 */
                BaseCallConversation.prototype.conversationStandardModule = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new BaseCallConversation instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {infinitusai.be.IBaseCallConversation=} [properties] Properties to set
                 * @returns {infinitusai.be.BaseCallConversation} BaseCallConversation instance
                 */
                BaseCallConversation.create = function create(properties) {
                    return new BaseCallConversation(properties);
                };
    
                /**
                 * Encodes the specified BaseCallConversation message. Does not implicitly {@link infinitusai.be.BaseCallConversation.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {infinitusai.be.IBaseCallConversation} message BaseCallConversation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BaseCallConversation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardUtterances != null && Object.hasOwnProperty.call(message, "standardUtterances"))
                        for (var keys = Object.keys(message.standardUtterances), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.StandardUtterance.encode(message.standardUtterances[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.expandedUtterances != null && Object.hasOwnProperty.call(message, "expandedUtterances"))
                        for (var keys = Object.keys(message.expandedUtterances), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedUtterance.encode(message.expandedUtterances[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.standardUtteranceToExpandedUtteranceMapping != null && Object.hasOwnProperty.call(message, "standardUtteranceToExpandedUtteranceMapping"))
                        for (var keys = Object.keys(message.standardUtteranceToExpandedUtteranceMapping), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedUtteranceList.encode(message.standardUtteranceToExpandedUtteranceMapping[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.standardActions != null && Object.hasOwnProperty.call(message, "standardActions"))
                        for (var keys = Object.keys(message.standardActions), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.StandardAction.encode(message.standardActions[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.expandedActions != null && Object.hasOwnProperty.call(message, "expandedActions"))
                        for (var keys = Object.keys(message.expandedActions), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedAction.encode(message.expandedActions[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.standardActionToExpandedActionMapping != null && Object.hasOwnProperty.call(message, "standardActionToExpandedActionMapping"))
                        for (var keys = Object.keys(message.standardActionToExpandedActionMapping), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedActionList.encode(message.standardActionToExpandedActionMapping[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.standardConditions != null && Object.hasOwnProperty.call(message, "standardConditions"))
                        for (var keys = Object.keys(message.standardConditions), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.StandardCondition.encode(message.standardConditions[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.standardModules != null && Object.hasOwnProperty.call(message, "standardModules"))
                        for (var keys = Object.keys(message.standardModules), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 8, wireType 2 =*/66).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.StandardModule.encode(message.standardModules[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.expandedModules != null && Object.hasOwnProperty.call(message, "expandedModules"))
                        for (var keys = Object.keys(message.expandedModules), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 9, wireType 2 =*/74).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedModule.encode(message.expandedModules[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.standardModuleToExpandedModuleMapping != null && Object.hasOwnProperty.call(message, "standardModuleToExpandedModuleMapping"))
                        for (var keys = Object.keys(message.standardModuleToExpandedModuleMapping), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedModuleList.encode(message.standardModuleToExpandedModuleMapping[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.standardOutputs != null && Object.hasOwnProperty.call(message, "standardOutputs"))
                        for (var keys = Object.keys(message.standardOutputs), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.StandardOutput.encode(message.standardOutputs[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.expandedOutputs != null && Object.hasOwnProperty.call(message, "expandedOutputs"))
                        for (var keys = Object.keys(message.expandedOutputs), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 12, wireType 2 =*/98).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedOutput.encode(message.expandedOutputs[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.standardOutputToExpandedOutputMapping != null && Object.hasOwnProperty.call(message, "standardOutputToExpandedOutputMapping"))
                        for (var keys = Object.keys(message.standardOutputToExpandedOutputMapping), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 13, wireType 2 =*/106).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedOutputList.encode(message.standardOutputToExpandedOutputMapping[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.taskInputs != null && Object.hasOwnProperty.call(message, "taskInputs"))
                        for (var keys = Object.keys(message.taskInputs), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 14, wireType 2 =*/114).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.google.protobuf.Any.encode(message.taskInputs[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.bvCallInputsProto != null && Object.hasOwnProperty.call(message, "bvCallInputsProto"))
                        $root.infinitusai.be.BVCallInputs.encode(message.bvCallInputsProto, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.actionUtteranceMapping != null && Object.hasOwnProperty.call(message, "actionUtteranceMapping"))
                        for (var keys = Object.keys(message.actionUtteranceMapping), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 16, wireType 2 =*/130).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ConditionalActionToUtteranceMappingList.encode(message.actionUtteranceMapping[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.expandedActionToExpandedUtteranceMapping != null && Object.hasOwnProperty.call(message, "expandedActionToExpandedUtteranceMapping"))
                        for (var keys = Object.keys(message.expandedActionToExpandedUtteranceMapping), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 17, wireType 2 =*/138).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ConditionalActionToUtteranceMappingList.encode(message.expandedActionToExpandedUtteranceMapping[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.conversationStandardModule != null && Object.hasOwnProperty.call(message, "conversationStandardModule"))
                        writer.uint32(/* id 18, wireType 0 =*/144).int64(message.conversationStandardModule);
                    return writer;
                };
    
                /**
                 * Encodes the specified BaseCallConversation message, length delimited. Does not implicitly {@link infinitusai.be.BaseCallConversation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {infinitusai.be.IBaseCallConversation} message BaseCallConversation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BaseCallConversation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BaseCallConversation message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BaseCallConversation} BaseCallConversation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BaseCallConversation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BaseCallConversation(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.standardUtterances === $util.emptyObject)
                                    message.standardUtterances = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.StandardUtterance.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardUtterances[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 2: {
                                if (message.expandedUtterances === $util.emptyObject)
                                    message.expandedUtterances = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedUtterance.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.expandedUtterances[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 3: {
                                if (message.standardUtteranceToExpandedUtteranceMapping === $util.emptyObject)
                                    message.standardUtteranceToExpandedUtteranceMapping = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedUtteranceList.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardUtteranceToExpandedUtteranceMapping[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 4: {
                                if (message.standardActions === $util.emptyObject)
                                    message.standardActions = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.StandardAction.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardActions[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 5: {
                                if (message.expandedActions === $util.emptyObject)
                                    message.expandedActions = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedAction.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.expandedActions[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 6: {
                                if (message.standardActionToExpandedActionMapping === $util.emptyObject)
                                    message.standardActionToExpandedActionMapping = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedActionList.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardActionToExpandedActionMapping[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 7: {
                                if (message.standardConditions === $util.emptyObject)
                                    message.standardConditions = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.StandardCondition.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardConditions[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 8: {
                                if (message.standardModules === $util.emptyObject)
                                    message.standardModules = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.StandardModule.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardModules[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 9: {
                                if (message.expandedModules === $util.emptyObject)
                                    message.expandedModules = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedModule.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.expandedModules[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 10: {
                                if (message.standardModuleToExpandedModuleMapping === $util.emptyObject)
                                    message.standardModuleToExpandedModuleMapping = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedModuleList.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardModuleToExpandedModuleMapping[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 11: {
                                if (message.standardOutputs === $util.emptyObject)
                                    message.standardOutputs = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.StandardOutput.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardOutputs[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 12: {
                                if (message.expandedOutputs === $util.emptyObject)
                                    message.expandedOutputs = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedOutput.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.expandedOutputs[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 13: {
                                if (message.standardOutputToExpandedOutputMapping === $util.emptyObject)
                                    message.standardOutputToExpandedOutputMapping = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedOutputList.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.standardOutputToExpandedOutputMapping[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 14: {
                                if (message.taskInputs === $util.emptyObject)
                                    message.taskInputs = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.google.protobuf.Any.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.taskInputs[key] = value;
                                break;
                            }
                        case 15: {
                                message.bvCallInputsProto = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                if (message.actionUtteranceMapping === $util.emptyObject)
                                    message.actionUtteranceMapping = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ConditionalActionToUtteranceMappingList.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.actionUtteranceMapping[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 17: {
                                if (message.expandedActionToExpandedUtteranceMapping === $util.emptyObject)
                                    message.expandedActionToExpandedUtteranceMapping = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ConditionalActionToUtteranceMappingList.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.expandedActionToExpandedUtteranceMapping[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 18: {
                                message.conversationStandardModule = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BaseCallConversation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BaseCallConversation} BaseCallConversation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BaseCallConversation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BaseCallConversation message.
                 * @function verify
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BaseCallConversation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardUtterances != null && message.hasOwnProperty("standardUtterances")) {
                        if (!$util.isObject(message.standardUtterances))
                            return "standardUtterances: object expected";
                        var key = Object.keys(message.standardUtterances);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardUtterances: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.StandardUtterance.verify(message.standardUtterances[key[i]]);
                                if (error)
                                    return "standardUtterances." + error;
                            }
                        }
                    }
                    if (message.expandedUtterances != null && message.hasOwnProperty("expandedUtterances")) {
                        if (!$util.isObject(message.expandedUtterances))
                            return "expandedUtterances: object expected";
                        var key = Object.keys(message.expandedUtterances);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "expandedUtterances: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedUtterance.verify(message.expandedUtterances[key[i]]);
                                if (error)
                                    return "expandedUtterances." + error;
                            }
                        }
                    }
                    if (message.standardUtteranceToExpandedUtteranceMapping != null && message.hasOwnProperty("standardUtteranceToExpandedUtteranceMapping")) {
                        if (!$util.isObject(message.standardUtteranceToExpandedUtteranceMapping))
                            return "standardUtteranceToExpandedUtteranceMapping: object expected";
                        var key = Object.keys(message.standardUtteranceToExpandedUtteranceMapping);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardUtteranceToExpandedUtteranceMapping: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedUtteranceList.verify(message.standardUtteranceToExpandedUtteranceMapping[key[i]]);
                                if (error)
                                    return "standardUtteranceToExpandedUtteranceMapping." + error;
                            }
                        }
                    }
                    if (message.standardActions != null && message.hasOwnProperty("standardActions")) {
                        if (!$util.isObject(message.standardActions))
                            return "standardActions: object expected";
                        var key = Object.keys(message.standardActions);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardActions: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.StandardAction.verify(message.standardActions[key[i]]);
                                if (error)
                                    return "standardActions." + error;
                            }
                        }
                    }
                    if (message.expandedActions != null && message.hasOwnProperty("expandedActions")) {
                        if (!$util.isObject(message.expandedActions))
                            return "expandedActions: object expected";
                        var key = Object.keys(message.expandedActions);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "expandedActions: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedAction.verify(message.expandedActions[key[i]]);
                                if (error)
                                    return "expandedActions." + error;
                            }
                        }
                    }
                    if (message.standardActionToExpandedActionMapping != null && message.hasOwnProperty("standardActionToExpandedActionMapping")) {
                        if (!$util.isObject(message.standardActionToExpandedActionMapping))
                            return "standardActionToExpandedActionMapping: object expected";
                        var key = Object.keys(message.standardActionToExpandedActionMapping);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardActionToExpandedActionMapping: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedActionList.verify(message.standardActionToExpandedActionMapping[key[i]]);
                                if (error)
                                    return "standardActionToExpandedActionMapping." + error;
                            }
                        }
                    }
                    if (message.standardConditions != null && message.hasOwnProperty("standardConditions")) {
                        if (!$util.isObject(message.standardConditions))
                            return "standardConditions: object expected";
                        var key = Object.keys(message.standardConditions);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardConditions: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.StandardCondition.verify(message.standardConditions[key[i]]);
                                if (error)
                                    return "standardConditions." + error;
                            }
                        }
                    }
                    if (message.standardModules != null && message.hasOwnProperty("standardModules")) {
                        if (!$util.isObject(message.standardModules))
                            return "standardModules: object expected";
                        var key = Object.keys(message.standardModules);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardModules: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.StandardModule.verify(message.standardModules[key[i]]);
                                if (error)
                                    return "standardModules." + error;
                            }
                        }
                    }
                    if (message.expandedModules != null && message.hasOwnProperty("expandedModules")) {
                        if (!$util.isObject(message.expandedModules))
                            return "expandedModules: object expected";
                        var key = Object.keys(message.expandedModules);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "expandedModules: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedModule.verify(message.expandedModules[key[i]]);
                                if (error)
                                    return "expandedModules." + error;
                            }
                        }
                    }
                    if (message.standardModuleToExpandedModuleMapping != null && message.hasOwnProperty("standardModuleToExpandedModuleMapping")) {
                        if (!$util.isObject(message.standardModuleToExpandedModuleMapping))
                            return "standardModuleToExpandedModuleMapping: object expected";
                        var key = Object.keys(message.standardModuleToExpandedModuleMapping);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardModuleToExpandedModuleMapping: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedModuleList.verify(message.standardModuleToExpandedModuleMapping[key[i]]);
                                if (error)
                                    return "standardModuleToExpandedModuleMapping." + error;
                            }
                        }
                    }
                    if (message.standardOutputs != null && message.hasOwnProperty("standardOutputs")) {
                        if (!$util.isObject(message.standardOutputs))
                            return "standardOutputs: object expected";
                        var key = Object.keys(message.standardOutputs);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardOutputs: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.StandardOutput.verify(message.standardOutputs[key[i]]);
                                if (error)
                                    return "standardOutputs." + error;
                            }
                        }
                    }
                    if (message.expandedOutputs != null && message.hasOwnProperty("expandedOutputs")) {
                        if (!$util.isObject(message.expandedOutputs))
                            return "expandedOutputs: object expected";
                        var key = Object.keys(message.expandedOutputs);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "expandedOutputs: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedOutput.verify(message.expandedOutputs[key[i]]);
                                if (error)
                                    return "expandedOutputs." + error;
                            }
                        }
                    }
                    if (message.standardOutputToExpandedOutputMapping != null && message.hasOwnProperty("standardOutputToExpandedOutputMapping")) {
                        if (!$util.isObject(message.standardOutputToExpandedOutputMapping))
                            return "standardOutputToExpandedOutputMapping: object expected";
                        var key = Object.keys(message.standardOutputToExpandedOutputMapping);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "standardOutputToExpandedOutputMapping: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedOutputList.verify(message.standardOutputToExpandedOutputMapping[key[i]]);
                                if (error)
                                    return "standardOutputToExpandedOutputMapping." + error;
                            }
                        }
                    }
                    if (message.taskInputs != null && message.hasOwnProperty("taskInputs")) {
                        if (!$util.isObject(message.taskInputs))
                            return "taskInputs: object expected";
                        var key = Object.keys(message.taskInputs);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.google.protobuf.Any.verify(message.taskInputs[key[i]]);
                            if (error)
                                return "taskInputs." + error;
                        }
                    }
                    if (message.bvCallInputsProto != null && message.hasOwnProperty("bvCallInputsProto")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.bvCallInputsProto);
                        if (error)
                            return "bvCallInputsProto." + error;
                    }
                    if (message.actionUtteranceMapping != null && message.hasOwnProperty("actionUtteranceMapping")) {
                        if (!$util.isObject(message.actionUtteranceMapping))
                            return "actionUtteranceMapping: object expected";
                        var key = Object.keys(message.actionUtteranceMapping);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "actionUtteranceMapping: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ConditionalActionToUtteranceMappingList.verify(message.actionUtteranceMapping[key[i]]);
                                if (error)
                                    return "actionUtteranceMapping." + error;
                            }
                        }
                    }
                    if (message.expandedActionToExpandedUtteranceMapping != null && message.hasOwnProperty("expandedActionToExpandedUtteranceMapping")) {
                        if (!$util.isObject(message.expandedActionToExpandedUtteranceMapping))
                            return "expandedActionToExpandedUtteranceMapping: object expected";
                        var key = Object.keys(message.expandedActionToExpandedUtteranceMapping);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "expandedActionToExpandedUtteranceMapping: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ConditionalActionToUtteranceMappingList.verify(message.expandedActionToExpandedUtteranceMapping[key[i]]);
                                if (error)
                                    return "expandedActionToExpandedUtteranceMapping." + error;
                            }
                        }
                    }
                    if (message.conversationStandardModule != null && message.hasOwnProperty("conversationStandardModule"))
                        if (!$util.isInteger(message.conversationStandardModule) && !(message.conversationStandardModule && $util.isInteger(message.conversationStandardModule.low) && $util.isInteger(message.conversationStandardModule.high)))
                            return "conversationStandardModule: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a BaseCallConversation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BaseCallConversation} BaseCallConversation
                 */
                BaseCallConversation.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BaseCallConversation)
                        return object;
                    var message = new $root.infinitusai.be.BaseCallConversation();
                    if (object.standardUtterances) {
                        if (typeof object.standardUtterances !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardUtterances: object expected");
                        message.standardUtterances = {};
                        for (var keys = Object.keys(object.standardUtterances), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardUtterances[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardUtterances: object expected");
                            message.standardUtterances[keys[i]] = $root.infinitusai.be.StandardUtterance.fromObject(object.standardUtterances[keys[i]]);
                        }
                    }
                    if (object.expandedUtterances) {
                        if (typeof object.expandedUtterances !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.expandedUtterances: object expected");
                        message.expandedUtterances = {};
                        for (var keys = Object.keys(object.expandedUtterances), i = 0; i < keys.length; ++i) {
                            if (typeof object.expandedUtterances[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.expandedUtterances: object expected");
                            message.expandedUtterances[keys[i]] = $root.infinitusai.be.ExpandedUtterance.fromObject(object.expandedUtterances[keys[i]]);
                        }
                    }
                    if (object.standardUtteranceToExpandedUtteranceMapping) {
                        if (typeof object.standardUtteranceToExpandedUtteranceMapping !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardUtteranceToExpandedUtteranceMapping: object expected");
                        message.standardUtteranceToExpandedUtteranceMapping = {};
                        for (var keys = Object.keys(object.standardUtteranceToExpandedUtteranceMapping), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardUtteranceToExpandedUtteranceMapping[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardUtteranceToExpandedUtteranceMapping: object expected");
                            message.standardUtteranceToExpandedUtteranceMapping[keys[i]] = $root.infinitusai.be.ExpandedUtteranceList.fromObject(object.standardUtteranceToExpandedUtteranceMapping[keys[i]]);
                        }
                    }
                    if (object.standardActions) {
                        if (typeof object.standardActions !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardActions: object expected");
                        message.standardActions = {};
                        for (var keys = Object.keys(object.standardActions), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardActions[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardActions: object expected");
                            message.standardActions[keys[i]] = $root.infinitusai.be.StandardAction.fromObject(object.standardActions[keys[i]]);
                        }
                    }
                    if (object.expandedActions) {
                        if (typeof object.expandedActions !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.expandedActions: object expected");
                        message.expandedActions = {};
                        for (var keys = Object.keys(object.expandedActions), i = 0; i < keys.length; ++i) {
                            if (typeof object.expandedActions[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.expandedActions: object expected");
                            message.expandedActions[keys[i]] = $root.infinitusai.be.ExpandedAction.fromObject(object.expandedActions[keys[i]]);
                        }
                    }
                    if (object.standardActionToExpandedActionMapping) {
                        if (typeof object.standardActionToExpandedActionMapping !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardActionToExpandedActionMapping: object expected");
                        message.standardActionToExpandedActionMapping = {};
                        for (var keys = Object.keys(object.standardActionToExpandedActionMapping), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardActionToExpandedActionMapping[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardActionToExpandedActionMapping: object expected");
                            message.standardActionToExpandedActionMapping[keys[i]] = $root.infinitusai.be.ExpandedActionList.fromObject(object.standardActionToExpandedActionMapping[keys[i]]);
                        }
                    }
                    if (object.standardConditions) {
                        if (typeof object.standardConditions !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardConditions: object expected");
                        message.standardConditions = {};
                        for (var keys = Object.keys(object.standardConditions), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardConditions[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardConditions: object expected");
                            message.standardConditions[keys[i]] = $root.infinitusai.be.StandardCondition.fromObject(object.standardConditions[keys[i]]);
                        }
                    }
                    if (object.standardModules) {
                        if (typeof object.standardModules !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardModules: object expected");
                        message.standardModules = {};
                        for (var keys = Object.keys(object.standardModules), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardModules[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardModules: object expected");
                            message.standardModules[keys[i]] = $root.infinitusai.be.StandardModule.fromObject(object.standardModules[keys[i]]);
                        }
                    }
                    if (object.expandedModules) {
                        if (typeof object.expandedModules !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.expandedModules: object expected");
                        message.expandedModules = {};
                        for (var keys = Object.keys(object.expandedModules), i = 0; i < keys.length; ++i) {
                            if (typeof object.expandedModules[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.expandedModules: object expected");
                            message.expandedModules[keys[i]] = $root.infinitusai.be.ExpandedModule.fromObject(object.expandedModules[keys[i]]);
                        }
                    }
                    if (object.standardModuleToExpandedModuleMapping) {
                        if (typeof object.standardModuleToExpandedModuleMapping !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardModuleToExpandedModuleMapping: object expected");
                        message.standardModuleToExpandedModuleMapping = {};
                        for (var keys = Object.keys(object.standardModuleToExpandedModuleMapping), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardModuleToExpandedModuleMapping[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardModuleToExpandedModuleMapping: object expected");
                            message.standardModuleToExpandedModuleMapping[keys[i]] = $root.infinitusai.be.ExpandedModuleList.fromObject(object.standardModuleToExpandedModuleMapping[keys[i]]);
                        }
                    }
                    if (object.standardOutputs) {
                        if (typeof object.standardOutputs !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardOutputs: object expected");
                        message.standardOutputs = {};
                        for (var keys = Object.keys(object.standardOutputs), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardOutputs[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardOutputs: object expected");
                            message.standardOutputs[keys[i]] = $root.infinitusai.be.StandardOutput.fromObject(object.standardOutputs[keys[i]]);
                        }
                    }
                    if (object.expandedOutputs) {
                        if (typeof object.expandedOutputs !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.expandedOutputs: object expected");
                        message.expandedOutputs = {};
                        for (var keys = Object.keys(object.expandedOutputs), i = 0; i < keys.length; ++i) {
                            if (typeof object.expandedOutputs[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.expandedOutputs: object expected");
                            message.expandedOutputs[keys[i]] = $root.infinitusai.be.ExpandedOutput.fromObject(object.expandedOutputs[keys[i]]);
                        }
                    }
                    if (object.standardOutputToExpandedOutputMapping) {
                        if (typeof object.standardOutputToExpandedOutputMapping !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.standardOutputToExpandedOutputMapping: object expected");
                        message.standardOutputToExpandedOutputMapping = {};
                        for (var keys = Object.keys(object.standardOutputToExpandedOutputMapping), i = 0; i < keys.length; ++i) {
                            if (typeof object.standardOutputToExpandedOutputMapping[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.standardOutputToExpandedOutputMapping: object expected");
                            message.standardOutputToExpandedOutputMapping[keys[i]] = $root.infinitusai.be.ExpandedOutputList.fromObject(object.standardOutputToExpandedOutputMapping[keys[i]]);
                        }
                    }
                    if (object.taskInputs) {
                        if (typeof object.taskInputs !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.taskInputs: object expected");
                        message.taskInputs = {};
                        for (var keys = Object.keys(object.taskInputs), i = 0; i < keys.length; ++i) {
                            if (typeof object.taskInputs[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.taskInputs: object expected");
                            message.taskInputs[keys[i]] = $root.google.protobuf.Any.fromObject(object.taskInputs[keys[i]]);
                        }
                    }
                    if (object.bvCallInputsProto != null) {
                        if (typeof object.bvCallInputsProto !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.bvCallInputsProto: object expected");
                        message.bvCallInputsProto = $root.infinitusai.be.BVCallInputs.fromObject(object.bvCallInputsProto);
                    }
                    if (object.actionUtteranceMapping) {
                        if (typeof object.actionUtteranceMapping !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.actionUtteranceMapping: object expected");
                        message.actionUtteranceMapping = {};
                        for (var keys = Object.keys(object.actionUtteranceMapping), i = 0; i < keys.length; ++i) {
                            if (typeof object.actionUtteranceMapping[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.actionUtteranceMapping: object expected");
                            message.actionUtteranceMapping[keys[i]] = $root.infinitusai.be.ConditionalActionToUtteranceMappingList.fromObject(object.actionUtteranceMapping[keys[i]]);
                        }
                    }
                    if (object.expandedActionToExpandedUtteranceMapping) {
                        if (typeof object.expandedActionToExpandedUtteranceMapping !== "object")
                            throw TypeError(".infinitusai.be.BaseCallConversation.expandedActionToExpandedUtteranceMapping: object expected");
                        message.expandedActionToExpandedUtteranceMapping = {};
                        for (var keys = Object.keys(object.expandedActionToExpandedUtteranceMapping), i = 0; i < keys.length; ++i) {
                            if (typeof object.expandedActionToExpandedUtteranceMapping[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.BaseCallConversation.expandedActionToExpandedUtteranceMapping: object expected");
                            message.expandedActionToExpandedUtteranceMapping[keys[i]] = $root.infinitusai.be.ConditionalActionToUtteranceMappingList.fromObject(object.expandedActionToExpandedUtteranceMapping[keys[i]]);
                        }
                    }
                    if (object.conversationStandardModule != null)
                        if ($util.Long)
                            (message.conversationStandardModule = $util.Long.fromValue(object.conversationStandardModule)).unsigned = false;
                        else if (typeof object.conversationStandardModule === "string")
                            message.conversationStandardModule = parseInt(object.conversationStandardModule, 10);
                        else if (typeof object.conversationStandardModule === "number")
                            message.conversationStandardModule = object.conversationStandardModule;
                        else if (typeof object.conversationStandardModule === "object")
                            message.conversationStandardModule = new $util.LongBits(object.conversationStandardModule.low >>> 0, object.conversationStandardModule.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a BaseCallConversation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {infinitusai.be.BaseCallConversation} message BaseCallConversation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BaseCallConversation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults) {
                        object.standardUtterances = {};
                        object.expandedUtterances = {};
                        object.standardUtteranceToExpandedUtteranceMapping = {};
                        object.standardActions = {};
                        object.expandedActions = {};
                        object.standardActionToExpandedActionMapping = {};
                        object.standardConditions = {};
                        object.standardModules = {};
                        object.expandedModules = {};
                        object.standardModuleToExpandedModuleMapping = {};
                        object.standardOutputs = {};
                        object.expandedOutputs = {};
                        object.standardOutputToExpandedOutputMapping = {};
                        object.taskInputs = {};
                        object.actionUtteranceMapping = {};
                        object.expandedActionToExpandedUtteranceMapping = {};
                    }
                    if (options.defaults) {
                        object.bvCallInputsProto = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.conversationStandardModule = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.conversationStandardModule = options.longs === String ? "0" : 0;
                    }
                    var keys2;
                    if (message.standardUtterances && (keys2 = Object.keys(message.standardUtterances)).length) {
                        object.standardUtterances = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardUtterances[keys2[j]] = $root.infinitusai.be.StandardUtterance.toObject(message.standardUtterances[keys2[j]], options);
                    }
                    if (message.expandedUtterances && (keys2 = Object.keys(message.expandedUtterances)).length) {
                        object.expandedUtterances = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.expandedUtterances[keys2[j]] = $root.infinitusai.be.ExpandedUtterance.toObject(message.expandedUtterances[keys2[j]], options);
                    }
                    if (message.standardUtteranceToExpandedUtteranceMapping && (keys2 = Object.keys(message.standardUtteranceToExpandedUtteranceMapping)).length) {
                        object.standardUtteranceToExpandedUtteranceMapping = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardUtteranceToExpandedUtteranceMapping[keys2[j]] = $root.infinitusai.be.ExpandedUtteranceList.toObject(message.standardUtteranceToExpandedUtteranceMapping[keys2[j]], options);
                    }
                    if (message.standardActions && (keys2 = Object.keys(message.standardActions)).length) {
                        object.standardActions = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardActions[keys2[j]] = $root.infinitusai.be.StandardAction.toObject(message.standardActions[keys2[j]], options);
                    }
                    if (message.expandedActions && (keys2 = Object.keys(message.expandedActions)).length) {
                        object.expandedActions = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.expandedActions[keys2[j]] = $root.infinitusai.be.ExpandedAction.toObject(message.expandedActions[keys2[j]], options);
                    }
                    if (message.standardActionToExpandedActionMapping && (keys2 = Object.keys(message.standardActionToExpandedActionMapping)).length) {
                        object.standardActionToExpandedActionMapping = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardActionToExpandedActionMapping[keys2[j]] = $root.infinitusai.be.ExpandedActionList.toObject(message.standardActionToExpandedActionMapping[keys2[j]], options);
                    }
                    if (message.standardConditions && (keys2 = Object.keys(message.standardConditions)).length) {
                        object.standardConditions = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardConditions[keys2[j]] = $root.infinitusai.be.StandardCondition.toObject(message.standardConditions[keys2[j]], options);
                    }
                    if (message.standardModules && (keys2 = Object.keys(message.standardModules)).length) {
                        object.standardModules = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardModules[keys2[j]] = $root.infinitusai.be.StandardModule.toObject(message.standardModules[keys2[j]], options);
                    }
                    if (message.expandedModules && (keys2 = Object.keys(message.expandedModules)).length) {
                        object.expandedModules = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.expandedModules[keys2[j]] = $root.infinitusai.be.ExpandedModule.toObject(message.expandedModules[keys2[j]], options);
                    }
                    if (message.standardModuleToExpandedModuleMapping && (keys2 = Object.keys(message.standardModuleToExpandedModuleMapping)).length) {
                        object.standardModuleToExpandedModuleMapping = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardModuleToExpandedModuleMapping[keys2[j]] = $root.infinitusai.be.ExpandedModuleList.toObject(message.standardModuleToExpandedModuleMapping[keys2[j]], options);
                    }
                    if (message.standardOutputs && (keys2 = Object.keys(message.standardOutputs)).length) {
                        object.standardOutputs = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardOutputs[keys2[j]] = $root.infinitusai.be.StandardOutput.toObject(message.standardOutputs[keys2[j]], options);
                    }
                    if (message.expandedOutputs && (keys2 = Object.keys(message.expandedOutputs)).length) {
                        object.expandedOutputs = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.expandedOutputs[keys2[j]] = $root.infinitusai.be.ExpandedOutput.toObject(message.expandedOutputs[keys2[j]], options);
                    }
                    if (message.standardOutputToExpandedOutputMapping && (keys2 = Object.keys(message.standardOutputToExpandedOutputMapping)).length) {
                        object.standardOutputToExpandedOutputMapping = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.standardOutputToExpandedOutputMapping[keys2[j]] = $root.infinitusai.be.ExpandedOutputList.toObject(message.standardOutputToExpandedOutputMapping[keys2[j]], options);
                    }
                    if (message.taskInputs && (keys2 = Object.keys(message.taskInputs)).length) {
                        object.taskInputs = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.taskInputs[keys2[j]] = $root.google.protobuf.Any.toObject(message.taskInputs[keys2[j]], options);
                    }
                    if (message.bvCallInputsProto != null && message.hasOwnProperty("bvCallInputsProto"))
                        object.bvCallInputsProto = $root.infinitusai.be.BVCallInputs.toObject(message.bvCallInputsProto, options);
                    if (message.actionUtteranceMapping && (keys2 = Object.keys(message.actionUtteranceMapping)).length) {
                        object.actionUtteranceMapping = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.actionUtteranceMapping[keys2[j]] = $root.infinitusai.be.ConditionalActionToUtteranceMappingList.toObject(message.actionUtteranceMapping[keys2[j]], options);
                    }
                    if (message.expandedActionToExpandedUtteranceMapping && (keys2 = Object.keys(message.expandedActionToExpandedUtteranceMapping)).length) {
                        object.expandedActionToExpandedUtteranceMapping = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.expandedActionToExpandedUtteranceMapping[keys2[j]] = $root.infinitusai.be.ConditionalActionToUtteranceMappingList.toObject(message.expandedActionToExpandedUtteranceMapping[keys2[j]], options);
                    }
                    if (message.conversationStandardModule != null && message.hasOwnProperty("conversationStandardModule"))
                        if (typeof message.conversationStandardModule === "number")
                            object.conversationStandardModule = options.longs === String ? String(message.conversationStandardModule) : message.conversationStandardModule;
                        else
                            object.conversationStandardModule = options.longs === String ? $util.Long.prototype.toString.call(message.conversationStandardModule) : options.longs === Number ? new $util.LongBits(message.conversationStandardModule.low >>> 0, message.conversationStandardModule.high >>> 0).toNumber() : message.conversationStandardModule;
                    return object;
                };
    
                /**
                 * Converts this BaseCallConversation to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BaseCallConversation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BaseCallConversation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BaseCallConversation
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BaseCallConversation
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BaseCallConversation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BaseCallConversation";
                };
    
                return BaseCallConversation;
            })();
    
            be.GetCallConversationResponse = (function() {
    
                /**
                 * Properties of a GetCallConversationResponse.
                 * @memberof infinitusai.be
                 * @interface IGetCallConversationResponse
                 * @property {infinitusai.be.IConversationConfig|null} [conversationConfig] GetCallConversationResponse conversationConfig
                 * @property {infinitusai.be.ICallConversationState|null} [callConversationState] GetCallConversationResponse callConversationState
                 * @property {infinitusai.be.IBaseCallConversation|null} [baseCallConversation] GetCallConversationResponse baseCallConversation
                 */
    
                /**
                 * Constructs a new GetCallConversationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCallConversationResponse.
                 * @implements IGetCallConversationResponse
                 * @constructor
                 * @param {infinitusai.be.IGetCallConversationResponse=} [properties] Properties to set
                 */
                function GetCallConversationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCallConversationResponse conversationConfig.
                 * @member {infinitusai.be.IConversationConfig|null|undefined} conversationConfig
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @instance
                 */
                GetCallConversationResponse.prototype.conversationConfig = null;
    
                /**
                 * GetCallConversationResponse callConversationState.
                 * @member {infinitusai.be.ICallConversationState|null|undefined} callConversationState
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @instance
                 */
                GetCallConversationResponse.prototype.callConversationState = null;
    
                /**
                 * GetCallConversationResponse baseCallConversation.
                 * @member {infinitusai.be.IBaseCallConversation|null|undefined} baseCallConversation
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @instance
                 */
                GetCallConversationResponse.prototype.baseCallConversation = null;
    
                /**
                 * Creates a new GetCallConversationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {infinitusai.be.IGetCallConversationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCallConversationResponse} GetCallConversationResponse instance
                 */
                GetCallConversationResponse.create = function create(properties) {
                    return new GetCallConversationResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCallConversationResponse message. Does not implicitly {@link infinitusai.be.GetCallConversationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {infinitusai.be.IGetCallConversationResponse} message GetCallConversationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallConversationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversationConfig != null && Object.hasOwnProperty.call(message, "conversationConfig"))
                        $root.infinitusai.be.ConversationConfig.encode(message.conversationConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.callConversationState != null && Object.hasOwnProperty.call(message, "callConversationState"))
                        $root.infinitusai.be.CallConversationState.encode(message.callConversationState, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.baseCallConversation != null && Object.hasOwnProperty.call(message, "baseCallConversation"))
                        $root.infinitusai.be.BaseCallConversation.encode(message.baseCallConversation, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCallConversationResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetCallConversationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {infinitusai.be.IGetCallConversationResponse} message GetCallConversationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCallConversationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCallConversationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCallConversationResponse} GetCallConversationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallConversationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCallConversationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversationConfig = $root.infinitusai.be.ConversationConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.callConversationState = $root.infinitusai.be.CallConversationState.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.baseCallConversation = $root.infinitusai.be.BaseCallConversation.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCallConversationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCallConversationResponse} GetCallConversationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCallConversationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCallConversationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCallConversationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversationConfig != null && message.hasOwnProperty("conversationConfig")) {
                        var error = $root.infinitusai.be.ConversationConfig.verify(message.conversationConfig);
                        if (error)
                            return "conversationConfig." + error;
                    }
                    if (message.callConversationState != null && message.hasOwnProperty("callConversationState")) {
                        var error = $root.infinitusai.be.CallConversationState.verify(message.callConversationState);
                        if (error)
                            return "callConversationState." + error;
                    }
                    if (message.baseCallConversation != null && message.hasOwnProperty("baseCallConversation")) {
                        var error = $root.infinitusai.be.BaseCallConversation.verify(message.baseCallConversation);
                        if (error)
                            return "baseCallConversation." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetCallConversationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCallConversationResponse} GetCallConversationResponse
                 */
                GetCallConversationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCallConversationResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetCallConversationResponse();
                    if (object.conversationConfig != null) {
                        if (typeof object.conversationConfig !== "object")
                            throw TypeError(".infinitusai.be.GetCallConversationResponse.conversationConfig: object expected");
                        message.conversationConfig = $root.infinitusai.be.ConversationConfig.fromObject(object.conversationConfig);
                    }
                    if (object.callConversationState != null) {
                        if (typeof object.callConversationState !== "object")
                            throw TypeError(".infinitusai.be.GetCallConversationResponse.callConversationState: object expected");
                        message.callConversationState = $root.infinitusai.be.CallConversationState.fromObject(object.callConversationState);
                    }
                    if (object.baseCallConversation != null) {
                        if (typeof object.baseCallConversation !== "object")
                            throw TypeError(".infinitusai.be.GetCallConversationResponse.baseCallConversation: object expected");
                        message.baseCallConversation = $root.infinitusai.be.BaseCallConversation.fromObject(object.baseCallConversation);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCallConversationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {infinitusai.be.GetCallConversationResponse} message GetCallConversationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCallConversationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.conversationConfig = null;
                        object.callConversationState = null;
                        object.baseCallConversation = null;
                    }
                    if (message.conversationConfig != null && message.hasOwnProperty("conversationConfig"))
                        object.conversationConfig = $root.infinitusai.be.ConversationConfig.toObject(message.conversationConfig, options);
                    if (message.callConversationState != null && message.hasOwnProperty("callConversationState"))
                        object.callConversationState = $root.infinitusai.be.CallConversationState.toObject(message.callConversationState, options);
                    if (message.baseCallConversation != null && message.hasOwnProperty("baseCallConversation"))
                        object.baseCallConversation = $root.infinitusai.be.BaseCallConversation.toObject(message.baseCallConversation, options);
                    return object;
                };
    
                /**
                 * Converts this GetCallConversationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCallConversationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCallConversationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCallConversationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCallConversationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCallConversationResponse";
                };
    
                return GetCallConversationResponse;
            })();
    
            be.EvaluateConditionIdsRequest = (function() {
    
                /**
                 * Properties of an EvaluateConditionIdsRequest.
                 * @memberof infinitusai.be
                 * @interface IEvaluateConditionIdsRequest
                 * @property {string|null} [orgUuid] EvaluateConditionIdsRequest orgUuid
                 * @property {string|null} [taskUuid] EvaluateConditionIdsRequest taskUuid
                 * @property {string|null} [callUuid] EvaluateConditionIdsRequest callUuid
                 * @property {Array.<number|Long>|null} [conditionIds] EvaluateConditionIdsRequest conditionIds
                 */
    
                /**
                 * Constructs a new EvaluateConditionIdsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EvaluateConditionIdsRequest.
                 * @implements IEvaluateConditionIdsRequest
                 * @constructor
                 * @param {infinitusai.be.IEvaluateConditionIdsRequest=} [properties] Properties to set
                 */
                function EvaluateConditionIdsRequest(properties) {
                    this.conditionIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EvaluateConditionIdsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @instance
                 */
                EvaluateConditionIdsRequest.prototype.orgUuid = "";
    
                /**
                 * EvaluateConditionIdsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @instance
                 */
                EvaluateConditionIdsRequest.prototype.taskUuid = "";
    
                /**
                 * EvaluateConditionIdsRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @instance
                 */
                EvaluateConditionIdsRequest.prototype.callUuid = "";
    
                /**
                 * EvaluateConditionIdsRequest conditionIds.
                 * @member {Array.<number|Long>} conditionIds
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @instance
                 */
                EvaluateConditionIdsRequest.prototype.conditionIds = $util.emptyArray;
    
                /**
                 * Creates a new EvaluateConditionIdsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {infinitusai.be.IEvaluateConditionIdsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.EvaluateConditionIdsRequest} EvaluateConditionIdsRequest instance
                 */
                EvaluateConditionIdsRequest.create = function create(properties) {
                    return new EvaluateConditionIdsRequest(properties);
                };
    
                /**
                 * Encodes the specified EvaluateConditionIdsRequest message. Does not implicitly {@link infinitusai.be.EvaluateConditionIdsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {infinitusai.be.IEvaluateConditionIdsRequest} message EvaluateConditionIdsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluateConditionIdsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.conditionIds != null && message.conditionIds.length) {
                        writer.uint32(/* id 4, wireType 2 =*/34).fork();
                        for (var i = 0; i < message.conditionIds.length; ++i)
                            writer.int64(message.conditionIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified EvaluateConditionIdsRequest message, length delimited. Does not implicitly {@link infinitusai.be.EvaluateConditionIdsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {infinitusai.be.IEvaluateConditionIdsRequest} message EvaluateConditionIdsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluateConditionIdsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EvaluateConditionIdsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EvaluateConditionIdsRequest} EvaluateConditionIdsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluateConditionIdsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EvaluateConditionIdsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.conditionIds && message.conditionIds.length))
                                    message.conditionIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.conditionIds.push(reader.int64());
                                } else
                                    message.conditionIds.push(reader.int64());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EvaluateConditionIdsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EvaluateConditionIdsRequest} EvaluateConditionIdsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluateConditionIdsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EvaluateConditionIdsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EvaluateConditionIdsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.conditionIds != null && message.hasOwnProperty("conditionIds")) {
                        if (!Array.isArray(message.conditionIds))
                            return "conditionIds: array expected";
                        for (var i = 0; i < message.conditionIds.length; ++i)
                            if (!$util.isInteger(message.conditionIds[i]) && !(message.conditionIds[i] && $util.isInteger(message.conditionIds[i].low) && $util.isInteger(message.conditionIds[i].high)))
                                return "conditionIds: integer|Long[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an EvaluateConditionIdsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EvaluateConditionIdsRequest} EvaluateConditionIdsRequest
                 */
                EvaluateConditionIdsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EvaluateConditionIdsRequest)
                        return object;
                    var message = new $root.infinitusai.be.EvaluateConditionIdsRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.conditionIds) {
                        if (!Array.isArray(object.conditionIds))
                            throw TypeError(".infinitusai.be.EvaluateConditionIdsRequest.conditionIds: array expected");
                        message.conditionIds = [];
                        for (var i = 0; i < object.conditionIds.length; ++i)
                            if ($util.Long)
                                (message.conditionIds[i] = $util.Long.fromValue(object.conditionIds[i])).unsigned = false;
                            else if (typeof object.conditionIds[i] === "string")
                                message.conditionIds[i] = parseInt(object.conditionIds[i], 10);
                            else if (typeof object.conditionIds[i] === "number")
                                message.conditionIds[i] = object.conditionIds[i];
                            else if (typeof object.conditionIds[i] === "object")
                                message.conditionIds[i] = new $util.LongBits(object.conditionIds[i].low >>> 0, object.conditionIds[i].high >>> 0).toNumber();
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EvaluateConditionIdsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {infinitusai.be.EvaluateConditionIdsRequest} message EvaluateConditionIdsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EvaluateConditionIdsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.conditionIds = [];
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.conditionIds && message.conditionIds.length) {
                        object.conditionIds = [];
                        for (var j = 0; j < message.conditionIds.length; ++j)
                            if (typeof message.conditionIds[j] === "number")
                                object.conditionIds[j] = options.longs === String ? String(message.conditionIds[j]) : message.conditionIds[j];
                            else
                                object.conditionIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.conditionIds[j]) : options.longs === Number ? new $util.LongBits(message.conditionIds[j].low >>> 0, message.conditionIds[j].high >>> 0).toNumber() : message.conditionIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this EvaluateConditionIdsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EvaluateConditionIdsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EvaluateConditionIdsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EvaluateConditionIdsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EvaluateConditionIdsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EvaluateConditionIdsRequest";
                };
    
                return EvaluateConditionIdsRequest;
            })();
    
            be.EvaluateConditionIdsResponse = (function() {
    
                /**
                 * Properties of an EvaluateConditionIdsResponse.
                 * @memberof infinitusai.be
                 * @interface IEvaluateConditionIdsResponse
                 */
    
                /**
                 * Constructs a new EvaluateConditionIdsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EvaluateConditionIdsResponse.
                 * @implements IEvaluateConditionIdsResponse
                 * @constructor
                 * @param {infinitusai.be.IEvaluateConditionIdsResponse=} [properties] Properties to set
                 */
                function EvaluateConditionIdsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new EvaluateConditionIdsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {infinitusai.be.IEvaluateConditionIdsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.EvaluateConditionIdsResponse} EvaluateConditionIdsResponse instance
                 */
                EvaluateConditionIdsResponse.create = function create(properties) {
                    return new EvaluateConditionIdsResponse(properties);
                };
    
                /**
                 * Encodes the specified EvaluateConditionIdsResponse message. Does not implicitly {@link infinitusai.be.EvaluateConditionIdsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {infinitusai.be.IEvaluateConditionIdsResponse} message EvaluateConditionIdsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluateConditionIdsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified EvaluateConditionIdsResponse message, length delimited. Does not implicitly {@link infinitusai.be.EvaluateConditionIdsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {infinitusai.be.IEvaluateConditionIdsResponse} message EvaluateConditionIdsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluateConditionIdsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EvaluateConditionIdsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EvaluateConditionIdsResponse} EvaluateConditionIdsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluateConditionIdsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EvaluateConditionIdsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EvaluateConditionIdsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EvaluateConditionIdsResponse} EvaluateConditionIdsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluateConditionIdsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EvaluateConditionIdsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EvaluateConditionIdsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an EvaluateConditionIdsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EvaluateConditionIdsResponse} EvaluateConditionIdsResponse
                 */
                EvaluateConditionIdsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EvaluateConditionIdsResponse)
                        return object;
                    return new $root.infinitusai.be.EvaluateConditionIdsResponse();
                };
    
                /**
                 * Creates a plain object from an EvaluateConditionIdsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {infinitusai.be.EvaluateConditionIdsResponse} message EvaluateConditionIdsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EvaluateConditionIdsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this EvaluateConditionIdsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EvaluateConditionIdsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EvaluateConditionIdsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EvaluateConditionIdsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EvaluateConditionIdsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EvaluateConditionIdsResponse";
                };
    
                return EvaluateConditionIdsResponse;
            })();
    
            be.CbExecuteActionRequest = (function() {
    
                /**
                 * Properties of a CbExecuteActionRequest.
                 * @memberof infinitusai.be
                 * @interface ICbExecuteActionRequest
                 * @property {string|null} [orgUuid] CbExecuteActionRequest orgUuid
                 * @property {string|null} [taskUuid] CbExecuteActionRequest taskUuid
                 * @property {string|null} [callUuid] CbExecuteActionRequest callUuid
                 * @property {number|Long|null} [expandedActionId] CbExecuteActionRequest expandedActionId
                 */
    
                /**
                 * Constructs a new CbExecuteActionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CbExecuteActionRequest.
                 * @implements ICbExecuteActionRequest
                 * @constructor
                 * @param {infinitusai.be.ICbExecuteActionRequest=} [properties] Properties to set
                 */
                function CbExecuteActionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CbExecuteActionRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @instance
                 */
                CbExecuteActionRequest.prototype.orgUuid = "";
    
                /**
                 * CbExecuteActionRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @instance
                 */
                CbExecuteActionRequest.prototype.taskUuid = "";
    
                /**
                 * CbExecuteActionRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @instance
                 */
                CbExecuteActionRequest.prototype.callUuid = "";
    
                /**
                 * CbExecuteActionRequest expandedActionId.
                 * @member {number|Long} expandedActionId
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @instance
                 */
                CbExecuteActionRequest.prototype.expandedActionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new CbExecuteActionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.ICbExecuteActionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CbExecuteActionRequest} CbExecuteActionRequest instance
                 */
                CbExecuteActionRequest.create = function create(properties) {
                    return new CbExecuteActionRequest(properties);
                };
    
                /**
                 * Encodes the specified CbExecuteActionRequest message. Does not implicitly {@link infinitusai.be.CbExecuteActionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.ICbExecuteActionRequest} message CbExecuteActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CbExecuteActionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.expandedActionId != null && Object.hasOwnProperty.call(message, "expandedActionId"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.expandedActionId);
                    return writer;
                };
    
                /**
                 * Encodes the specified CbExecuteActionRequest message, length delimited. Does not implicitly {@link infinitusai.be.CbExecuteActionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.ICbExecuteActionRequest} message CbExecuteActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CbExecuteActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CbExecuteActionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CbExecuteActionRequest} CbExecuteActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CbExecuteActionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CbExecuteActionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.expandedActionId = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CbExecuteActionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CbExecuteActionRequest} CbExecuteActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CbExecuteActionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CbExecuteActionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CbExecuteActionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.expandedActionId != null && message.hasOwnProperty("expandedActionId"))
                        if (!$util.isInteger(message.expandedActionId) && !(message.expandedActionId && $util.isInteger(message.expandedActionId.low) && $util.isInteger(message.expandedActionId.high)))
                            return "expandedActionId: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a CbExecuteActionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CbExecuteActionRequest} CbExecuteActionRequest
                 */
                CbExecuteActionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CbExecuteActionRequest)
                        return object;
                    var message = new $root.infinitusai.be.CbExecuteActionRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.expandedActionId != null)
                        if ($util.Long)
                            (message.expandedActionId = $util.Long.fromValue(object.expandedActionId)).unsigned = false;
                        else if (typeof object.expandedActionId === "string")
                            message.expandedActionId = parseInt(object.expandedActionId, 10);
                        else if (typeof object.expandedActionId === "number")
                            message.expandedActionId = object.expandedActionId;
                        else if (typeof object.expandedActionId === "object")
                            message.expandedActionId = new $util.LongBits(object.expandedActionId.low >>> 0, object.expandedActionId.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a CbExecuteActionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.CbExecuteActionRequest} message CbExecuteActionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CbExecuteActionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedActionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedActionId = options.longs === String ? "0" : 0;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.expandedActionId != null && message.hasOwnProperty("expandedActionId"))
                        if (typeof message.expandedActionId === "number")
                            object.expandedActionId = options.longs === String ? String(message.expandedActionId) : message.expandedActionId;
                        else
                            object.expandedActionId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedActionId) : options.longs === Number ? new $util.LongBits(message.expandedActionId.low >>> 0, message.expandedActionId.high >>> 0).toNumber() : message.expandedActionId;
                    return object;
                };
    
                /**
                 * Converts this CbExecuteActionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CbExecuteActionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CbExecuteActionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CbExecuteActionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CbExecuteActionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CbExecuteActionRequest";
                };
    
                return CbExecuteActionRequest;
            })();
    
            be.CbExecuteActionResponse = (function() {
    
                /**
                 * Properties of a CbExecuteActionResponse.
                 * @memberof infinitusai.be
                 * @interface ICbExecuteActionResponse
                 */
    
                /**
                 * Constructs a new CbExecuteActionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CbExecuteActionResponse.
                 * @implements ICbExecuteActionResponse
                 * @constructor
                 * @param {infinitusai.be.ICbExecuteActionResponse=} [properties] Properties to set
                 */
                function CbExecuteActionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CbExecuteActionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.ICbExecuteActionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CbExecuteActionResponse} CbExecuteActionResponse instance
                 */
                CbExecuteActionResponse.create = function create(properties) {
                    return new CbExecuteActionResponse(properties);
                };
    
                /**
                 * Encodes the specified CbExecuteActionResponse message. Does not implicitly {@link infinitusai.be.CbExecuteActionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.ICbExecuteActionResponse} message CbExecuteActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CbExecuteActionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CbExecuteActionResponse message, length delimited. Does not implicitly {@link infinitusai.be.CbExecuteActionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.ICbExecuteActionResponse} message CbExecuteActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CbExecuteActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CbExecuteActionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CbExecuteActionResponse} CbExecuteActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CbExecuteActionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CbExecuteActionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CbExecuteActionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CbExecuteActionResponse} CbExecuteActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CbExecuteActionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CbExecuteActionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CbExecuteActionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CbExecuteActionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CbExecuteActionResponse} CbExecuteActionResponse
                 */
                CbExecuteActionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CbExecuteActionResponse)
                        return object;
                    return new $root.infinitusai.be.CbExecuteActionResponse();
                };
    
                /**
                 * Creates a plain object from a CbExecuteActionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.CbExecuteActionResponse} message CbExecuteActionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CbExecuteActionResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CbExecuteActionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CbExecuteActionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CbExecuteActionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CbExecuteActionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CbExecuteActionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CbExecuteActionResponse";
                };
    
                return CbExecuteActionResponse;
            })();
    
            be.EvaluateFunctionRequest = (function() {
    
                /**
                 * Properties of an EvaluateFunctionRequest.
                 * @memberof infinitusai.be
                 * @interface IEvaluateFunctionRequest
                 * @property {string|null} [orgUuid] EvaluateFunctionRequest orgUuid
                 * @property {string|null} [taskUuid] EvaluateFunctionRequest taskUuid
                 * @property {string|null} [callUuid] EvaluateFunctionRequest callUuid
                 * @property {number|Long|null} [functionId] EvaluateFunctionRequest functionId
                 * @property {Object.<string,string>|null} [params] EvaluateFunctionRequest params
                 */
    
                /**
                 * Constructs a new EvaluateFunctionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EvaluateFunctionRequest.
                 * @implements IEvaluateFunctionRequest
                 * @constructor
                 * @param {infinitusai.be.IEvaluateFunctionRequest=} [properties] Properties to set
                 */
                function EvaluateFunctionRequest(properties) {
                    this.params = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EvaluateFunctionRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @instance
                 */
                EvaluateFunctionRequest.prototype.orgUuid = "";
    
                /**
                 * EvaluateFunctionRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @instance
                 */
                EvaluateFunctionRequest.prototype.taskUuid = "";
    
                /**
                 * EvaluateFunctionRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @instance
                 */
                EvaluateFunctionRequest.prototype.callUuid = "";
    
                /**
                 * EvaluateFunctionRequest functionId.
                 * @member {number|Long} functionId
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @instance
                 */
                EvaluateFunctionRequest.prototype.functionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * EvaluateFunctionRequest params.
                 * @member {Object.<string,string>} params
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @instance
                 */
                EvaluateFunctionRequest.prototype.params = $util.emptyObject;
    
                /**
                 * Creates a new EvaluateFunctionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {infinitusai.be.IEvaluateFunctionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.EvaluateFunctionRequest} EvaluateFunctionRequest instance
                 */
                EvaluateFunctionRequest.create = function create(properties) {
                    return new EvaluateFunctionRequest(properties);
                };
    
                /**
                 * Encodes the specified EvaluateFunctionRequest message. Does not implicitly {@link infinitusai.be.EvaluateFunctionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {infinitusai.be.IEvaluateFunctionRequest} message EvaluateFunctionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluateFunctionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.functionId != null && Object.hasOwnProperty.call(message, "functionId"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.functionId);
                    if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                        for (var keys = Object.keys(message.params), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.params[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EvaluateFunctionRequest message, length delimited. Does not implicitly {@link infinitusai.be.EvaluateFunctionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {infinitusai.be.IEvaluateFunctionRequest} message EvaluateFunctionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluateFunctionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EvaluateFunctionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EvaluateFunctionRequest} EvaluateFunctionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluateFunctionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EvaluateFunctionRequest(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.functionId = reader.int64();
                                break;
                            }
                        case 5: {
                                if (message.params === $util.emptyObject)
                                    message.params = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.params[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EvaluateFunctionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EvaluateFunctionRequest} EvaluateFunctionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluateFunctionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EvaluateFunctionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EvaluateFunctionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.functionId != null && message.hasOwnProperty("functionId"))
                        if (!$util.isInteger(message.functionId) && !(message.functionId && $util.isInteger(message.functionId.low) && $util.isInteger(message.functionId.high)))
                            return "functionId: integer|Long expected";
                    if (message.params != null && message.hasOwnProperty("params")) {
                        if (!$util.isObject(message.params))
                            return "params: object expected";
                        var key = Object.keys(message.params);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.params[key[i]]))
                                return "params: string{k:string} expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an EvaluateFunctionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EvaluateFunctionRequest} EvaluateFunctionRequest
                 */
                EvaluateFunctionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EvaluateFunctionRequest)
                        return object;
                    var message = new $root.infinitusai.be.EvaluateFunctionRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.functionId != null)
                        if ($util.Long)
                            (message.functionId = $util.Long.fromValue(object.functionId)).unsigned = false;
                        else if (typeof object.functionId === "string")
                            message.functionId = parseInt(object.functionId, 10);
                        else if (typeof object.functionId === "number")
                            message.functionId = object.functionId;
                        else if (typeof object.functionId === "object")
                            message.functionId = new $util.LongBits(object.functionId.low >>> 0, object.functionId.high >>> 0).toNumber();
                    if (object.params) {
                        if (typeof object.params !== "object")
                            throw TypeError(".infinitusai.be.EvaluateFunctionRequest.params: object expected");
                        message.params = {};
                        for (var keys = Object.keys(object.params), i = 0; i < keys.length; ++i)
                            message.params[keys[i]] = String(object.params[keys[i]]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EvaluateFunctionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {infinitusai.be.EvaluateFunctionRequest} message EvaluateFunctionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EvaluateFunctionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.params = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.functionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.functionId = options.longs === String ? "0" : 0;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.functionId != null && message.hasOwnProperty("functionId"))
                        if (typeof message.functionId === "number")
                            object.functionId = options.longs === String ? String(message.functionId) : message.functionId;
                        else
                            object.functionId = options.longs === String ? $util.Long.prototype.toString.call(message.functionId) : options.longs === Number ? new $util.LongBits(message.functionId.low >>> 0, message.functionId.high >>> 0).toNumber() : message.functionId;
                    var keys2;
                    if (message.params && (keys2 = Object.keys(message.params)).length) {
                        object.params = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.params[keys2[j]] = message.params[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this EvaluateFunctionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EvaluateFunctionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EvaluateFunctionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EvaluateFunctionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EvaluateFunctionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EvaluateFunctionRequest";
                };
    
                return EvaluateFunctionRequest;
            })();
    
            be.EvaluateFunctionResponse = (function() {
    
                /**
                 * Properties of an EvaluateFunctionResponse.
                 * @memberof infinitusai.be
                 * @interface IEvaluateFunctionResponse
                 */
    
                /**
                 * Constructs a new EvaluateFunctionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EvaluateFunctionResponse.
                 * @implements IEvaluateFunctionResponse
                 * @constructor
                 * @param {infinitusai.be.IEvaluateFunctionResponse=} [properties] Properties to set
                 */
                function EvaluateFunctionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new EvaluateFunctionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {infinitusai.be.IEvaluateFunctionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.EvaluateFunctionResponse} EvaluateFunctionResponse instance
                 */
                EvaluateFunctionResponse.create = function create(properties) {
                    return new EvaluateFunctionResponse(properties);
                };
    
                /**
                 * Encodes the specified EvaluateFunctionResponse message. Does not implicitly {@link infinitusai.be.EvaluateFunctionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {infinitusai.be.IEvaluateFunctionResponse} message EvaluateFunctionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluateFunctionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified EvaluateFunctionResponse message, length delimited. Does not implicitly {@link infinitusai.be.EvaluateFunctionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {infinitusai.be.IEvaluateFunctionResponse} message EvaluateFunctionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaluateFunctionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EvaluateFunctionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EvaluateFunctionResponse} EvaluateFunctionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluateFunctionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EvaluateFunctionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EvaluateFunctionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EvaluateFunctionResponse} EvaluateFunctionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaluateFunctionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EvaluateFunctionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EvaluateFunctionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an EvaluateFunctionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EvaluateFunctionResponse} EvaluateFunctionResponse
                 */
                EvaluateFunctionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EvaluateFunctionResponse)
                        return object;
                    return new $root.infinitusai.be.EvaluateFunctionResponse();
                };
    
                /**
                 * Creates a plain object from an EvaluateFunctionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {infinitusai.be.EvaluateFunctionResponse} message EvaluateFunctionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EvaluateFunctionResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this EvaluateFunctionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EvaluateFunctionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EvaluateFunctionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EvaluateFunctionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EvaluateFunctionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EvaluateFunctionResponse";
                };
    
                return EvaluateFunctionResponse;
            })();
    
            be.GetOutputsForActionRequest = (function() {
    
                /**
                 * Properties of a GetOutputsForActionRequest.
                 * @memberof infinitusai.be
                 * @interface IGetOutputsForActionRequest
                 * @property {string|null} [orgUuid] GetOutputsForActionRequest orgUuid
                 * @property {string|null} [taskUuid] GetOutputsForActionRequest taskUuid
                 * @property {string|null} [callUuid] GetOutputsForActionRequest callUuid
                 * @property {number|Long|null} [expandedActionId] GetOutputsForActionRequest expandedActionId
                 */
    
                /**
                 * Constructs a new GetOutputsForActionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOutputsForActionRequest.
                 * @implements IGetOutputsForActionRequest
                 * @constructor
                 * @param {infinitusai.be.IGetOutputsForActionRequest=} [properties] Properties to set
                 */
                function GetOutputsForActionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOutputsForActionRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @instance
                 */
                GetOutputsForActionRequest.prototype.orgUuid = "";
    
                /**
                 * GetOutputsForActionRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @instance
                 */
                GetOutputsForActionRequest.prototype.taskUuid = "";
    
                /**
                 * GetOutputsForActionRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @instance
                 */
                GetOutputsForActionRequest.prototype.callUuid = "";
    
                /**
                 * GetOutputsForActionRequest expandedActionId.
                 * @member {number|Long} expandedActionId
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @instance
                 */
                GetOutputsForActionRequest.prototype.expandedActionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new GetOutputsForActionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {infinitusai.be.IGetOutputsForActionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOutputsForActionRequest} GetOutputsForActionRequest instance
                 */
                GetOutputsForActionRequest.create = function create(properties) {
                    return new GetOutputsForActionRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOutputsForActionRequest message. Does not implicitly {@link infinitusai.be.GetOutputsForActionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {infinitusai.be.IGetOutputsForActionRequest} message GetOutputsForActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOutputsForActionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.expandedActionId != null && Object.hasOwnProperty.call(message, "expandedActionId"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.expandedActionId);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOutputsForActionRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetOutputsForActionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {infinitusai.be.IGetOutputsForActionRequest} message GetOutputsForActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOutputsForActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOutputsForActionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOutputsForActionRequest} GetOutputsForActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOutputsForActionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOutputsForActionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.expandedActionId = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOutputsForActionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOutputsForActionRequest} GetOutputsForActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOutputsForActionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOutputsForActionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOutputsForActionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.expandedActionId != null && message.hasOwnProperty("expandedActionId"))
                        if (!$util.isInteger(message.expandedActionId) && !(message.expandedActionId && $util.isInteger(message.expandedActionId.low) && $util.isInteger(message.expandedActionId.high)))
                            return "expandedActionId: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a GetOutputsForActionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOutputsForActionRequest} GetOutputsForActionRequest
                 */
                GetOutputsForActionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOutputsForActionRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetOutputsForActionRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.expandedActionId != null)
                        if ($util.Long)
                            (message.expandedActionId = $util.Long.fromValue(object.expandedActionId)).unsigned = false;
                        else if (typeof object.expandedActionId === "string")
                            message.expandedActionId = parseInt(object.expandedActionId, 10);
                        else if (typeof object.expandedActionId === "number")
                            message.expandedActionId = object.expandedActionId;
                        else if (typeof object.expandedActionId === "object")
                            message.expandedActionId = new $util.LongBits(object.expandedActionId.low >>> 0, object.expandedActionId.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOutputsForActionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {infinitusai.be.GetOutputsForActionRequest} message GetOutputsForActionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOutputsForActionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedActionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedActionId = options.longs === String ? "0" : 0;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.expandedActionId != null && message.hasOwnProperty("expandedActionId"))
                        if (typeof message.expandedActionId === "number")
                            object.expandedActionId = options.longs === String ? String(message.expandedActionId) : message.expandedActionId;
                        else
                            object.expandedActionId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedActionId) : options.longs === Number ? new $util.LongBits(message.expandedActionId.low >>> 0, message.expandedActionId.high >>> 0).toNumber() : message.expandedActionId;
                    return object;
                };
    
                /**
                 * Converts this GetOutputsForActionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOutputsForActionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOutputsForActionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOutputsForActionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOutputsForActionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOutputsForActionRequest";
                };
    
                return GetOutputsForActionRequest;
            })();
    
            be.GetOutputsForActionResponse = (function() {
    
                /**
                 * Properties of a GetOutputsForActionResponse.
                 * @memberof infinitusai.be
                 * @interface IGetOutputsForActionResponse
                 * @property {infinitusai.be.IRelevantOutputsForGivenAction|null} [result] GetOutputsForActionResponse result
                 */
    
                /**
                 * Constructs a new GetOutputsForActionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOutputsForActionResponse.
                 * @implements IGetOutputsForActionResponse
                 * @constructor
                 * @param {infinitusai.be.IGetOutputsForActionResponse=} [properties] Properties to set
                 */
                function GetOutputsForActionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOutputsForActionResponse result.
                 * @member {infinitusai.be.IRelevantOutputsForGivenAction|null|undefined} result
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @instance
                 */
                GetOutputsForActionResponse.prototype.result = null;
    
                /**
                 * Creates a new GetOutputsForActionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {infinitusai.be.IGetOutputsForActionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOutputsForActionResponse} GetOutputsForActionResponse instance
                 */
                GetOutputsForActionResponse.create = function create(properties) {
                    return new GetOutputsForActionResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOutputsForActionResponse message. Does not implicitly {@link infinitusai.be.GetOutputsForActionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {infinitusai.be.IGetOutputsForActionResponse} message GetOutputsForActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOutputsForActionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                        $root.infinitusai.be.RelevantOutputsForGivenAction.encode(message.result, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOutputsForActionResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetOutputsForActionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {infinitusai.be.IGetOutputsForActionResponse} message GetOutputsForActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOutputsForActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOutputsForActionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOutputsForActionResponse} GetOutputsForActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOutputsForActionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOutputsForActionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.result = $root.infinitusai.be.RelevantOutputsForGivenAction.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOutputsForActionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOutputsForActionResponse} GetOutputsForActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOutputsForActionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOutputsForActionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOutputsForActionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.result != null && message.hasOwnProperty("result")) {
                        var error = $root.infinitusai.be.RelevantOutputsForGivenAction.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOutputsForActionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOutputsForActionResponse} GetOutputsForActionResponse
                 */
                GetOutputsForActionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOutputsForActionResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetOutputsForActionResponse();
                    if (object.result != null) {
                        if (typeof object.result !== "object")
                            throw TypeError(".infinitusai.be.GetOutputsForActionResponse.result: object expected");
                        message.result = $root.infinitusai.be.RelevantOutputsForGivenAction.fromObject(object.result);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOutputsForActionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {infinitusai.be.GetOutputsForActionResponse} message GetOutputsForActionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOutputsForActionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.result = null;
                    if (message.result != null && message.hasOwnProperty("result"))
                        object.result = $root.infinitusai.be.RelevantOutputsForGivenAction.toObject(message.result, options);
                    return object;
                };
    
                /**
                 * Converts this GetOutputsForActionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOutputsForActionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOutputsForActionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOutputsForActionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOutputsForActionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOutputsForActionResponse";
                };
    
                return GetOutputsForActionResponse;
            })();
    
            be.RelevantOutputsForGivenAction = (function() {
    
                /**
                 * Properties of a RelevantOutputsForGivenAction.
                 * @memberof infinitusai.be
                 * @interface IRelevantOutputsForGivenAction
                 * @property {Array.<infinitusai.be.IExpandedOutput>|null} [outputs] RelevantOutputsForGivenAction outputs
                 */
    
                /**
                 * Constructs a new RelevantOutputsForGivenAction.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RelevantOutputsForGivenAction.
                 * @implements IRelevantOutputsForGivenAction
                 * @constructor
                 * @param {infinitusai.be.IRelevantOutputsForGivenAction=} [properties] Properties to set
                 */
                function RelevantOutputsForGivenAction(properties) {
                    this.outputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RelevantOutputsForGivenAction outputs.
                 * @member {Array.<infinitusai.be.IExpandedOutput>} outputs
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @instance
                 */
                RelevantOutputsForGivenAction.prototype.outputs = $util.emptyArray;
    
                /**
                 * Creates a new RelevantOutputsForGivenAction instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {infinitusai.be.IRelevantOutputsForGivenAction=} [properties] Properties to set
                 * @returns {infinitusai.be.RelevantOutputsForGivenAction} RelevantOutputsForGivenAction instance
                 */
                RelevantOutputsForGivenAction.create = function create(properties) {
                    return new RelevantOutputsForGivenAction(properties);
                };
    
                /**
                 * Encodes the specified RelevantOutputsForGivenAction message. Does not implicitly {@link infinitusai.be.RelevantOutputsForGivenAction.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {infinitusai.be.IRelevantOutputsForGivenAction} message RelevantOutputsForGivenAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RelevantOutputsForGivenAction.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.outputs != null && message.outputs.length)
                        for (var i = 0; i < message.outputs.length; ++i)
                            $root.infinitusai.be.ExpandedOutput.encode(message.outputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified RelevantOutputsForGivenAction message, length delimited. Does not implicitly {@link infinitusai.be.RelevantOutputsForGivenAction.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {infinitusai.be.IRelevantOutputsForGivenAction} message RelevantOutputsForGivenAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RelevantOutputsForGivenAction.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RelevantOutputsForGivenAction message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RelevantOutputsForGivenAction} RelevantOutputsForGivenAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RelevantOutputsForGivenAction.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RelevantOutputsForGivenAction();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.outputs && message.outputs.length))
                                    message.outputs = [];
                                message.outputs.push($root.infinitusai.be.ExpandedOutput.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RelevantOutputsForGivenAction message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RelevantOutputsForGivenAction} RelevantOutputsForGivenAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RelevantOutputsForGivenAction.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RelevantOutputsForGivenAction message.
                 * @function verify
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RelevantOutputsForGivenAction.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.outputs != null && message.hasOwnProperty("outputs")) {
                        if (!Array.isArray(message.outputs))
                            return "outputs: array expected";
                        for (var i = 0; i < message.outputs.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedOutput.verify(message.outputs[i]);
                            if (error)
                                return "outputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a RelevantOutputsForGivenAction message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RelevantOutputsForGivenAction} RelevantOutputsForGivenAction
                 */
                RelevantOutputsForGivenAction.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RelevantOutputsForGivenAction)
                        return object;
                    var message = new $root.infinitusai.be.RelevantOutputsForGivenAction();
                    if (object.outputs) {
                        if (!Array.isArray(object.outputs))
                            throw TypeError(".infinitusai.be.RelevantOutputsForGivenAction.outputs: array expected");
                        message.outputs = [];
                        for (var i = 0; i < object.outputs.length; ++i) {
                            if (typeof object.outputs[i] !== "object")
                                throw TypeError(".infinitusai.be.RelevantOutputsForGivenAction.outputs: object expected");
                            message.outputs[i] = $root.infinitusai.be.ExpandedOutput.fromObject(object.outputs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RelevantOutputsForGivenAction message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {infinitusai.be.RelevantOutputsForGivenAction} message RelevantOutputsForGivenAction
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RelevantOutputsForGivenAction.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.outputs = [];
                    if (message.outputs && message.outputs.length) {
                        object.outputs = [];
                        for (var j = 0; j < message.outputs.length; ++j)
                            object.outputs[j] = $root.infinitusai.be.ExpandedOutput.toObject(message.outputs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this RelevantOutputsForGivenAction to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RelevantOutputsForGivenAction.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RelevantOutputsForGivenAction
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RelevantOutputsForGivenAction
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RelevantOutputsForGivenAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RelevantOutputsForGivenAction";
                };
    
                return RelevantOutputsForGivenAction;
            })();
    
            be.FunctionWithParams = (function() {
    
                /**
                 * Properties of a FunctionWithParams.
                 * @memberof infinitusai.be
                 * @interface IFunctionWithParams
                 * @property {number|Long|null} [id] FunctionWithParams id
                 * @property {Object.<string,string>|null} [params] FunctionWithParams params
                 */
    
                /**
                 * Constructs a new FunctionWithParams.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FunctionWithParams.
                 * @implements IFunctionWithParams
                 * @constructor
                 * @param {infinitusai.be.IFunctionWithParams=} [properties] Properties to set
                 */
                function FunctionWithParams(properties) {
                    this.params = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FunctionWithParams id.
                 * @member {number|Long} id
                 * @memberof infinitusai.be.FunctionWithParams
                 * @instance
                 */
                FunctionWithParams.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * FunctionWithParams params.
                 * @member {Object.<string,string>} params
                 * @memberof infinitusai.be.FunctionWithParams
                 * @instance
                 */
                FunctionWithParams.prototype.params = $util.emptyObject;
    
                /**
                 * Creates a new FunctionWithParams instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {infinitusai.be.IFunctionWithParams=} [properties] Properties to set
                 * @returns {infinitusai.be.FunctionWithParams} FunctionWithParams instance
                 */
                FunctionWithParams.create = function create(properties) {
                    return new FunctionWithParams(properties);
                };
    
                /**
                 * Encodes the specified FunctionWithParams message. Does not implicitly {@link infinitusai.be.FunctionWithParams.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {infinitusai.be.IFunctionWithParams} message FunctionWithParams message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FunctionWithParams.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                    if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                        for (var keys = Object.keys(message.params), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.params[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FunctionWithParams message, length delimited. Does not implicitly {@link infinitusai.be.FunctionWithParams.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {infinitusai.be.IFunctionWithParams} message FunctionWithParams message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FunctionWithParams.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FunctionWithParams message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FunctionWithParams} FunctionWithParams
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FunctionWithParams.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FunctionWithParams(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int64();
                                break;
                            }
                        case 2: {
                                if (message.params === $util.emptyObject)
                                    message.params = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.params[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FunctionWithParams message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FunctionWithParams} FunctionWithParams
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FunctionWithParams.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FunctionWithParams message.
                 * @function verify
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FunctionWithParams.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                            return "id: integer|Long expected";
                    if (message.params != null && message.hasOwnProperty("params")) {
                        if (!$util.isObject(message.params))
                            return "params: object expected";
                        var key = Object.keys(message.params);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.params[key[i]]))
                                return "params: string{k:string} expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a FunctionWithParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FunctionWithParams} FunctionWithParams
                 */
                FunctionWithParams.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FunctionWithParams)
                        return object;
                    var message = new $root.infinitusai.be.FunctionWithParams();
                    if (object.id != null)
                        if ($util.Long)
                            (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                        else if (typeof object.id === "string")
                            message.id = parseInt(object.id, 10);
                        else if (typeof object.id === "number")
                            message.id = object.id;
                        else if (typeof object.id === "object")
                            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                    if (object.params) {
                        if (typeof object.params !== "object")
                            throw TypeError(".infinitusai.be.FunctionWithParams.params: object expected");
                        message.params = {};
                        for (var keys = Object.keys(object.params), i = 0; i < keys.length; ++i)
                            message.params[keys[i]] = String(object.params[keys[i]]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FunctionWithParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {infinitusai.be.FunctionWithParams} message FunctionWithParams
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FunctionWithParams.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.params = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.id = options.longs === String ? "0" : 0;
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (typeof message.id === "number")
                            object.id = options.longs === String ? String(message.id) : message.id;
                        else
                            object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                    var keys2;
                    if (message.params && (keys2 = Object.keys(message.params)).length) {
                        object.params = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.params[keys2[j]] = message.params[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this FunctionWithParams to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FunctionWithParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FunctionWithParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FunctionWithParams
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FunctionWithParams
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FunctionWithParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FunctionWithParams";
                };
    
                return FunctionWithParams;
            })();
    
            be.StandardUtterance = (function() {
    
                /**
                 * Properties of a StandardUtterance.
                 * @memberof infinitusai.be
                 * @interface IStandardUtterance
                 * @property {number|Long|null} [id] StandardUtterance id
                 * @property {string|null} [name] StandardUtterance name
                 * @property {google.protobuf.ITimestamp|null} [createdAt] StandardUtterance createdAt
                 * @property {google.protobuf.ITimestamp|null} [updatedAt] StandardUtterance updatedAt
                 * @property {string|null} [utteranceWithFormatting] StandardUtterance utteranceWithFormatting
                 * @property {Array.<infinitusai.be.IFunctionWithParams>|null} [formattingFunctions] StandardUtterance formattingFunctions
                 */
    
                /**
                 * Constructs a new StandardUtterance.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardUtterance.
                 * @implements IStandardUtterance
                 * @constructor
                 * @param {infinitusai.be.IStandardUtterance=} [properties] Properties to set
                 */
                function StandardUtterance(properties) {
                    this.formattingFunctions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardUtterance id.
                 * @member {number|Long} id
                 * @memberof infinitusai.be.StandardUtterance
                 * @instance
                 */
                StandardUtterance.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardUtterance name.
                 * @member {string} name
                 * @memberof infinitusai.be.StandardUtterance
                 * @instance
                 */
                StandardUtterance.prototype.name = "";
    
                /**
                 * StandardUtterance createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof infinitusai.be.StandardUtterance
                 * @instance
                 */
                StandardUtterance.prototype.createdAt = null;
    
                /**
                 * StandardUtterance updatedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
                 * @memberof infinitusai.be.StandardUtterance
                 * @instance
                 */
                StandardUtterance.prototype.updatedAt = null;
    
                /**
                 * StandardUtterance utteranceWithFormatting.
                 * @member {string} utteranceWithFormatting
                 * @memberof infinitusai.be.StandardUtterance
                 * @instance
                 */
                StandardUtterance.prototype.utteranceWithFormatting = "";
    
                /**
                 * StandardUtterance formattingFunctions.
                 * @member {Array.<infinitusai.be.IFunctionWithParams>} formattingFunctions
                 * @memberof infinitusai.be.StandardUtterance
                 * @instance
                 */
                StandardUtterance.prototype.formattingFunctions = $util.emptyArray;
    
                /**
                 * Creates a new StandardUtterance instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {infinitusai.be.IStandardUtterance=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardUtterance} StandardUtterance instance
                 */
                StandardUtterance.create = function create(properties) {
                    return new StandardUtterance(properties);
                };
    
                /**
                 * Encodes the specified StandardUtterance message. Does not implicitly {@link infinitusai.be.StandardUtterance.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {infinitusai.be.IStandardUtterance} message StandardUtterance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardUtterance.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                        $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                        $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.utteranceWithFormatting != null && Object.hasOwnProperty.call(message, "utteranceWithFormatting"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.utteranceWithFormatting);
                    if (message.formattingFunctions != null && message.formattingFunctions.length)
                        for (var i = 0; i < message.formattingFunctions.length; ++i)
                            $root.infinitusai.be.FunctionWithParams.encode(message.formattingFunctions[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardUtterance message, length delimited. Does not implicitly {@link infinitusai.be.StandardUtterance.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {infinitusai.be.IStandardUtterance} message StandardUtterance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardUtterance.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardUtterance message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardUtterance} StandardUtterance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardUtterance.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardUtterance();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int64();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.utteranceWithFormatting = reader.string();
                                break;
                            }
                        case 7: {
                                if (!(message.formattingFunctions && message.formattingFunctions.length))
                                    message.formattingFunctions = [];
                                message.formattingFunctions.push($root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardUtterance message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardUtterance} StandardUtterance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardUtterance.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardUtterance message.
                 * @function verify
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardUtterance.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                            return "id: integer|Long expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                        if (error)
                            return "createdAt." + error;
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
                        if (error)
                            return "updatedAt." + error;
                    }
                    if (message.utteranceWithFormatting != null && message.hasOwnProperty("utteranceWithFormatting"))
                        if (!$util.isString(message.utteranceWithFormatting))
                            return "utteranceWithFormatting: string expected";
                    if (message.formattingFunctions != null && message.hasOwnProperty("formattingFunctions")) {
                        if (!Array.isArray(message.formattingFunctions))
                            return "formattingFunctions: array expected";
                        for (var i = 0; i < message.formattingFunctions.length; ++i) {
                            var error = $root.infinitusai.be.FunctionWithParams.verify(message.formattingFunctions[i]);
                            if (error)
                                return "formattingFunctions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardUtterance message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardUtterance} StandardUtterance
                 */
                StandardUtterance.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardUtterance)
                        return object;
                    var message = new $root.infinitusai.be.StandardUtterance();
                    if (object.id != null)
                        if ($util.Long)
                            (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                        else if (typeof object.id === "string")
                            message.id = parseInt(object.id, 10);
                        else if (typeof object.id === "number")
                            message.id = object.id;
                        else if (typeof object.id === "object")
                            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.createdAt != null) {
                        if (typeof object.createdAt !== "object")
                            throw TypeError(".infinitusai.be.StandardUtterance.createdAt: object expected");
                        message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                    }
                    if (object.updatedAt != null) {
                        if (typeof object.updatedAt !== "object")
                            throw TypeError(".infinitusai.be.StandardUtterance.updatedAt: object expected");
                        message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
                    }
                    if (object.utteranceWithFormatting != null)
                        message.utteranceWithFormatting = String(object.utteranceWithFormatting);
                    if (object.formattingFunctions) {
                        if (!Array.isArray(object.formattingFunctions))
                            throw TypeError(".infinitusai.be.StandardUtterance.formattingFunctions: array expected");
                        message.formattingFunctions = [];
                        for (var i = 0; i < object.formattingFunctions.length; ++i) {
                            if (typeof object.formattingFunctions[i] !== "object")
                                throw TypeError(".infinitusai.be.StandardUtterance.formattingFunctions: object expected");
                            message.formattingFunctions[i] = $root.infinitusai.be.FunctionWithParams.fromObject(object.formattingFunctions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardUtterance message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {infinitusai.be.StandardUtterance} message StandardUtterance
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardUtterance.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.formattingFunctions = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.id = options.longs === String ? "0" : 0;
                        object.name = "";
                        object.createdAt = null;
                        object.updatedAt = null;
                        object.utteranceWithFormatting = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (typeof message.id === "number")
                            object.id = options.longs === String ? String(message.id) : message.id;
                        else
                            object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
                    if (message.utteranceWithFormatting != null && message.hasOwnProperty("utteranceWithFormatting"))
                        object.utteranceWithFormatting = message.utteranceWithFormatting;
                    if (message.formattingFunctions && message.formattingFunctions.length) {
                        object.formattingFunctions = [];
                        for (var j = 0; j < message.formattingFunctions.length; ++j)
                            object.formattingFunctions[j] = $root.infinitusai.be.FunctionWithParams.toObject(message.formattingFunctions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this StandardUtterance to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardUtterance
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardUtterance.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardUtterance
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardUtterance
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardUtterance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardUtterance";
                };
    
                return StandardUtterance;
            })();
    
            be.ExpandedUtterance = (function() {
    
                /**
                 * Properties of an ExpandedUtterance.
                 * @memberof infinitusai.be
                 * @interface IExpandedUtterance
                 * @property {number|Long|null} [expandedUtteranceId] ExpandedUtterance expandedUtteranceId
                 * @property {number|Long|null} [baseStandardUtteranceId] ExpandedUtterance baseStandardUtteranceId
                 * @property {infinitusai.be.IStandardUtterance|null} [standardUtterance] ExpandedUtterance standardUtterance
                 * @property {string|null} [expandedUtterance] ExpandedUtterance expandedUtterance
                 */
    
                /**
                 * Constructs a new ExpandedUtterance.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedUtterance.
                 * @implements IExpandedUtterance
                 * @constructor
                 * @param {infinitusai.be.IExpandedUtterance=} [properties] Properties to set
                 */
                function ExpandedUtterance(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedUtterance expandedUtteranceId.
                 * @member {number|Long} expandedUtteranceId
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @instance
                 */
                ExpandedUtterance.prototype.expandedUtteranceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedUtterance baseStandardUtteranceId.
                 * @member {number|Long} baseStandardUtteranceId
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @instance
                 */
                ExpandedUtterance.prototype.baseStandardUtteranceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedUtterance standardUtterance.
                 * @member {infinitusai.be.IStandardUtterance|null|undefined} standardUtterance
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @instance
                 */
                ExpandedUtterance.prototype.standardUtterance = null;
    
                /**
                 * ExpandedUtterance expandedUtterance.
                 * @member {string} expandedUtterance
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @instance
                 */
                ExpandedUtterance.prototype.expandedUtterance = "";
    
                /**
                 * Creates a new ExpandedUtterance instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {infinitusai.be.IExpandedUtterance=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedUtterance} ExpandedUtterance instance
                 */
                ExpandedUtterance.create = function create(properties) {
                    return new ExpandedUtterance(properties);
                };
    
                /**
                 * Encodes the specified ExpandedUtterance message. Does not implicitly {@link infinitusai.be.ExpandedUtterance.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {infinitusai.be.IExpandedUtterance} message ExpandedUtterance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedUtterance.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedUtteranceId != null && Object.hasOwnProperty.call(message, "expandedUtteranceId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.expandedUtteranceId);
                    if (message.baseStandardUtteranceId != null && Object.hasOwnProperty.call(message, "baseStandardUtteranceId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.baseStandardUtteranceId);
                    if (message.standardUtterance != null && Object.hasOwnProperty.call(message, "standardUtterance"))
                        $root.infinitusai.be.StandardUtterance.encode(message.standardUtterance, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.expandedUtterance != null && Object.hasOwnProperty.call(message, "expandedUtterance"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.expandedUtterance);
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedUtterance message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedUtterance.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {infinitusai.be.IExpandedUtterance} message ExpandedUtterance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedUtterance.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedUtterance message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedUtterance} ExpandedUtterance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedUtterance.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedUtterance();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.expandedUtteranceId = reader.int64();
                                break;
                            }
                        case 2: {
                                message.baseStandardUtteranceId = reader.int64();
                                break;
                            }
                        case 3: {
                                message.standardUtterance = $root.infinitusai.be.StandardUtterance.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.expandedUtterance = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedUtterance message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedUtterance} ExpandedUtterance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedUtterance.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedUtterance message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedUtterance.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedUtteranceId != null && message.hasOwnProperty("expandedUtteranceId"))
                        if (!$util.isInteger(message.expandedUtteranceId) && !(message.expandedUtteranceId && $util.isInteger(message.expandedUtteranceId.low) && $util.isInteger(message.expandedUtteranceId.high)))
                            return "expandedUtteranceId: integer|Long expected";
                    if (message.baseStandardUtteranceId != null && message.hasOwnProperty("baseStandardUtteranceId"))
                        if (!$util.isInteger(message.baseStandardUtteranceId) && !(message.baseStandardUtteranceId && $util.isInteger(message.baseStandardUtteranceId.low) && $util.isInteger(message.baseStandardUtteranceId.high)))
                            return "baseStandardUtteranceId: integer|Long expected";
                    if (message.standardUtterance != null && message.hasOwnProperty("standardUtterance")) {
                        var error = $root.infinitusai.be.StandardUtterance.verify(message.standardUtterance);
                        if (error)
                            return "standardUtterance." + error;
                    }
                    if (message.expandedUtterance != null && message.hasOwnProperty("expandedUtterance"))
                        if (!$util.isString(message.expandedUtterance))
                            return "expandedUtterance: string expected";
                    return null;
                };
    
                /**
                 * Creates an ExpandedUtterance message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedUtterance} ExpandedUtterance
                 */
                ExpandedUtterance.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedUtterance)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedUtterance();
                    if (object.expandedUtteranceId != null)
                        if ($util.Long)
                            (message.expandedUtteranceId = $util.Long.fromValue(object.expandedUtteranceId)).unsigned = false;
                        else if (typeof object.expandedUtteranceId === "string")
                            message.expandedUtteranceId = parseInt(object.expandedUtteranceId, 10);
                        else if (typeof object.expandedUtteranceId === "number")
                            message.expandedUtteranceId = object.expandedUtteranceId;
                        else if (typeof object.expandedUtteranceId === "object")
                            message.expandedUtteranceId = new $util.LongBits(object.expandedUtteranceId.low >>> 0, object.expandedUtteranceId.high >>> 0).toNumber();
                    if (object.baseStandardUtteranceId != null)
                        if ($util.Long)
                            (message.baseStandardUtteranceId = $util.Long.fromValue(object.baseStandardUtteranceId)).unsigned = false;
                        else if (typeof object.baseStandardUtteranceId === "string")
                            message.baseStandardUtteranceId = parseInt(object.baseStandardUtteranceId, 10);
                        else if (typeof object.baseStandardUtteranceId === "number")
                            message.baseStandardUtteranceId = object.baseStandardUtteranceId;
                        else if (typeof object.baseStandardUtteranceId === "object")
                            message.baseStandardUtteranceId = new $util.LongBits(object.baseStandardUtteranceId.low >>> 0, object.baseStandardUtteranceId.high >>> 0).toNumber();
                    if (object.standardUtterance != null) {
                        if (typeof object.standardUtterance !== "object")
                            throw TypeError(".infinitusai.be.ExpandedUtterance.standardUtterance: object expected");
                        message.standardUtterance = $root.infinitusai.be.StandardUtterance.fromObject(object.standardUtterance);
                    }
                    if (object.expandedUtterance != null)
                        message.expandedUtterance = String(object.expandedUtterance);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedUtterance message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {infinitusai.be.ExpandedUtterance} message ExpandedUtterance
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedUtterance.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedUtteranceId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedUtteranceId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.baseStandardUtteranceId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.baseStandardUtteranceId = options.longs === String ? "0" : 0;
                        object.standardUtterance = null;
                        object.expandedUtterance = "";
                    }
                    if (message.expandedUtteranceId != null && message.hasOwnProperty("expandedUtteranceId"))
                        if (typeof message.expandedUtteranceId === "number")
                            object.expandedUtteranceId = options.longs === String ? String(message.expandedUtteranceId) : message.expandedUtteranceId;
                        else
                            object.expandedUtteranceId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedUtteranceId) : options.longs === Number ? new $util.LongBits(message.expandedUtteranceId.low >>> 0, message.expandedUtteranceId.high >>> 0).toNumber() : message.expandedUtteranceId;
                    if (message.baseStandardUtteranceId != null && message.hasOwnProperty("baseStandardUtteranceId"))
                        if (typeof message.baseStandardUtteranceId === "number")
                            object.baseStandardUtteranceId = options.longs === String ? String(message.baseStandardUtteranceId) : message.baseStandardUtteranceId;
                        else
                            object.baseStandardUtteranceId = options.longs === String ? $util.Long.prototype.toString.call(message.baseStandardUtteranceId) : options.longs === Number ? new $util.LongBits(message.baseStandardUtteranceId.low >>> 0, message.baseStandardUtteranceId.high >>> 0).toNumber() : message.baseStandardUtteranceId;
                    if (message.standardUtterance != null && message.hasOwnProperty("standardUtterance"))
                        object.standardUtterance = $root.infinitusai.be.StandardUtterance.toObject(message.standardUtterance, options);
                    if (message.expandedUtterance != null && message.hasOwnProperty("expandedUtterance"))
                        object.expandedUtterance = message.expandedUtterance;
                    return object;
                };
    
                /**
                 * Converts this ExpandedUtterance to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedUtterance.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedUtterance
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedUtterance
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedUtterance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedUtterance";
                };
    
                return ExpandedUtterance;
            })();
    
            be.StandardAction = (function() {
    
                /**
                 * Properties of a StandardAction.
                 * @memberof infinitusai.be
                 * @interface IStandardAction
                 * @property {number|Long|null} [id] StandardAction id
                 * @property {string|null} [name] StandardAction name
                 * @property {Array.<infinitusai.be.IFunctionWithParams>|null} [nameFunctions] StandardAction nameFunctions
                 * @property {google.protobuf.ITimestamp|null} [createdAt] StandardAction createdAt
                 * @property {google.protobuf.ITimestamp|null} [updatedAt] StandardAction updatedAt
                 * @property {Array.<number|Long>|null} [relatedStandardOutputIds] StandardAction relatedStandardOutputIds
                 * @property {infinitusai.be.IFunctionWithParams|null} [range] StandardAction range
                 * @property {Array.<string>|null} [Hints] StandardAction Hints
                 */
    
                /**
                 * Constructs a new StandardAction.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardAction.
                 * @implements IStandardAction
                 * @constructor
                 * @param {infinitusai.be.IStandardAction=} [properties] Properties to set
                 */
                function StandardAction(properties) {
                    this.nameFunctions = [];
                    this.relatedStandardOutputIds = [];
                    this.Hints = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardAction id.
                 * @member {number|Long} id
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 */
                StandardAction.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardAction name.
                 * @member {string} name
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 */
                StandardAction.prototype.name = "";
    
                /**
                 * StandardAction nameFunctions.
                 * @member {Array.<infinitusai.be.IFunctionWithParams>} nameFunctions
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 */
                StandardAction.prototype.nameFunctions = $util.emptyArray;
    
                /**
                 * StandardAction createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 */
                StandardAction.prototype.createdAt = null;
    
                /**
                 * StandardAction updatedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 */
                StandardAction.prototype.updatedAt = null;
    
                /**
                 * StandardAction relatedStandardOutputIds.
                 * @member {Array.<number|Long>} relatedStandardOutputIds
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 */
                StandardAction.prototype.relatedStandardOutputIds = $util.emptyArray;
    
                /**
                 * StandardAction range.
                 * @member {infinitusai.be.IFunctionWithParams|null|undefined} range
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 */
                StandardAction.prototype.range = null;
    
                /**
                 * StandardAction Hints.
                 * @member {Array.<string>} Hints
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 */
                StandardAction.prototype.Hints = $util.emptyArray;
    
                /**
                 * Creates a new StandardAction instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {infinitusai.be.IStandardAction=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardAction} StandardAction instance
                 */
                StandardAction.create = function create(properties) {
                    return new StandardAction(properties);
                };
    
                /**
                 * Encodes the specified StandardAction message. Does not implicitly {@link infinitusai.be.StandardAction.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {infinitusai.be.IStandardAction} message StandardAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardAction.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.nameFunctions != null && message.nameFunctions.length)
                        for (var i = 0; i < message.nameFunctions.length; ++i)
                            $root.infinitusai.be.FunctionWithParams.encode(message.nameFunctions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                        $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                        $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.relatedStandardOutputIds != null && message.relatedStandardOutputIds.length) {
                        writer.uint32(/* id 6, wireType 2 =*/50).fork();
                        for (var i = 0; i < message.relatedStandardOutputIds.length; ++i)
                            writer.int64(message.relatedStandardOutputIds[i]);
                        writer.ldelim();
                    }
                    if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                        $root.infinitusai.be.FunctionWithParams.encode(message.range, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.Hints != null && message.Hints.length)
                        for (var i = 0; i < message.Hints.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.Hints[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardAction message, length delimited. Does not implicitly {@link infinitusai.be.StandardAction.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {infinitusai.be.IStandardAction} message StandardAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardAction.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardAction message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardAction} StandardAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardAction.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardAction();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int64();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.nameFunctions && message.nameFunctions.length))
                                    message.nameFunctions = [];
                                message.nameFunctions.push($root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                if (!(message.relatedStandardOutputIds && message.relatedStandardOutputIds.length))
                                    message.relatedStandardOutputIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.relatedStandardOutputIds.push(reader.int64());
                                } else
                                    message.relatedStandardOutputIds.push(reader.int64());
                                break;
                            }
                        case 7: {
                                message.range = $root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                if (!(message.Hints && message.Hints.length))
                                    message.Hints = [];
                                message.Hints.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardAction message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardAction} StandardAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardAction.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardAction message.
                 * @function verify
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardAction.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                            return "id: integer|Long expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.nameFunctions != null && message.hasOwnProperty("nameFunctions")) {
                        if (!Array.isArray(message.nameFunctions))
                            return "nameFunctions: array expected";
                        for (var i = 0; i < message.nameFunctions.length; ++i) {
                            var error = $root.infinitusai.be.FunctionWithParams.verify(message.nameFunctions[i]);
                            if (error)
                                return "nameFunctions." + error;
                        }
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                        if (error)
                            return "createdAt." + error;
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
                        if (error)
                            return "updatedAt." + error;
                    }
                    if (message.relatedStandardOutputIds != null && message.hasOwnProperty("relatedStandardOutputIds")) {
                        if (!Array.isArray(message.relatedStandardOutputIds))
                            return "relatedStandardOutputIds: array expected";
                        for (var i = 0; i < message.relatedStandardOutputIds.length; ++i)
                            if (!$util.isInteger(message.relatedStandardOutputIds[i]) && !(message.relatedStandardOutputIds[i] && $util.isInteger(message.relatedStandardOutputIds[i].low) && $util.isInteger(message.relatedStandardOutputIds[i].high)))
                                return "relatedStandardOutputIds: integer|Long[] expected";
                    }
                    if (message.range != null && message.hasOwnProperty("range")) {
                        var error = $root.infinitusai.be.FunctionWithParams.verify(message.range);
                        if (error)
                            return "range." + error;
                    }
                    if (message.Hints != null && message.hasOwnProperty("Hints")) {
                        if (!Array.isArray(message.Hints))
                            return "Hints: array expected";
                        for (var i = 0; i < message.Hints.length; ++i)
                            if (!$util.isString(message.Hints[i]))
                                return "Hints: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardAction message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardAction} StandardAction
                 */
                StandardAction.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardAction)
                        return object;
                    var message = new $root.infinitusai.be.StandardAction();
                    if (object.id != null)
                        if ($util.Long)
                            (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                        else if (typeof object.id === "string")
                            message.id = parseInt(object.id, 10);
                        else if (typeof object.id === "number")
                            message.id = object.id;
                        else if (typeof object.id === "object")
                            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.nameFunctions) {
                        if (!Array.isArray(object.nameFunctions))
                            throw TypeError(".infinitusai.be.StandardAction.nameFunctions: array expected");
                        message.nameFunctions = [];
                        for (var i = 0; i < object.nameFunctions.length; ++i) {
                            if (typeof object.nameFunctions[i] !== "object")
                                throw TypeError(".infinitusai.be.StandardAction.nameFunctions: object expected");
                            message.nameFunctions[i] = $root.infinitusai.be.FunctionWithParams.fromObject(object.nameFunctions[i]);
                        }
                    }
                    if (object.createdAt != null) {
                        if (typeof object.createdAt !== "object")
                            throw TypeError(".infinitusai.be.StandardAction.createdAt: object expected");
                        message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                    }
                    if (object.updatedAt != null) {
                        if (typeof object.updatedAt !== "object")
                            throw TypeError(".infinitusai.be.StandardAction.updatedAt: object expected");
                        message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
                    }
                    if (object.relatedStandardOutputIds) {
                        if (!Array.isArray(object.relatedStandardOutputIds))
                            throw TypeError(".infinitusai.be.StandardAction.relatedStandardOutputIds: array expected");
                        message.relatedStandardOutputIds = [];
                        for (var i = 0; i < object.relatedStandardOutputIds.length; ++i)
                            if ($util.Long)
                                (message.relatedStandardOutputIds[i] = $util.Long.fromValue(object.relatedStandardOutputIds[i])).unsigned = false;
                            else if (typeof object.relatedStandardOutputIds[i] === "string")
                                message.relatedStandardOutputIds[i] = parseInt(object.relatedStandardOutputIds[i], 10);
                            else if (typeof object.relatedStandardOutputIds[i] === "number")
                                message.relatedStandardOutputIds[i] = object.relatedStandardOutputIds[i];
                            else if (typeof object.relatedStandardOutputIds[i] === "object")
                                message.relatedStandardOutputIds[i] = new $util.LongBits(object.relatedStandardOutputIds[i].low >>> 0, object.relatedStandardOutputIds[i].high >>> 0).toNumber();
                    }
                    if (object.range != null) {
                        if (typeof object.range !== "object")
                            throw TypeError(".infinitusai.be.StandardAction.range: object expected");
                        message.range = $root.infinitusai.be.FunctionWithParams.fromObject(object.range);
                    }
                    if (object.Hints) {
                        if (!Array.isArray(object.Hints))
                            throw TypeError(".infinitusai.be.StandardAction.Hints: array expected");
                        message.Hints = [];
                        for (var i = 0; i < object.Hints.length; ++i)
                            message.Hints[i] = String(object.Hints[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardAction message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {infinitusai.be.StandardAction} message StandardAction
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardAction.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.nameFunctions = [];
                        object.relatedStandardOutputIds = [];
                        object.Hints = [];
                    }
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.id = options.longs === String ? "0" : 0;
                        object.name = "";
                        object.createdAt = null;
                        object.updatedAt = null;
                        object.range = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (typeof message.id === "number")
                            object.id = options.longs === String ? String(message.id) : message.id;
                        else
                            object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.nameFunctions && message.nameFunctions.length) {
                        object.nameFunctions = [];
                        for (var j = 0; j < message.nameFunctions.length; ++j)
                            object.nameFunctions[j] = $root.infinitusai.be.FunctionWithParams.toObject(message.nameFunctions[j], options);
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
                    if (message.relatedStandardOutputIds && message.relatedStandardOutputIds.length) {
                        object.relatedStandardOutputIds = [];
                        for (var j = 0; j < message.relatedStandardOutputIds.length; ++j)
                            if (typeof message.relatedStandardOutputIds[j] === "number")
                                object.relatedStandardOutputIds[j] = options.longs === String ? String(message.relatedStandardOutputIds[j]) : message.relatedStandardOutputIds[j];
                            else
                                object.relatedStandardOutputIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.relatedStandardOutputIds[j]) : options.longs === Number ? new $util.LongBits(message.relatedStandardOutputIds[j].low >>> 0, message.relatedStandardOutputIds[j].high >>> 0).toNumber() : message.relatedStandardOutputIds[j];
                    }
                    if (message.range != null && message.hasOwnProperty("range"))
                        object.range = $root.infinitusai.be.FunctionWithParams.toObject(message.range, options);
                    if (message.Hints && message.Hints.length) {
                        object.Hints = [];
                        for (var j = 0; j < message.Hints.length; ++j)
                            object.Hints[j] = message.Hints[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this StandardAction to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardAction
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardAction.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardAction
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardAction
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardAction";
                };
    
                return StandardAction;
            })();
    
            be.ExpandedAction = (function() {
    
                /**
                 * Properties of an ExpandedAction.
                 * @memberof infinitusai.be
                 * @interface IExpandedAction
                 * @property {number|Long|null} [expandedActionId] ExpandedAction expandedActionId
                 * @property {number|Long|null} [baseStandardActionId] ExpandedAction baseStandardActionId
                 * @property {infinitusai.be.IStandardAction|null} [baseStandardAction] ExpandedAction baseStandardAction
                 * @property {string|null} [expandedName] ExpandedAction expandedName
                 * @property {Array.<number|Long>|null} [expandedOutputIds] ExpandedAction expandedOutputIds
                 */
    
                /**
                 * Constructs a new ExpandedAction.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedAction.
                 * @implements IExpandedAction
                 * @constructor
                 * @param {infinitusai.be.IExpandedAction=} [properties] Properties to set
                 */
                function ExpandedAction(properties) {
                    this.expandedOutputIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedAction expandedActionId.
                 * @member {number|Long} expandedActionId
                 * @memberof infinitusai.be.ExpandedAction
                 * @instance
                 */
                ExpandedAction.prototype.expandedActionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedAction baseStandardActionId.
                 * @member {number|Long} baseStandardActionId
                 * @memberof infinitusai.be.ExpandedAction
                 * @instance
                 */
                ExpandedAction.prototype.baseStandardActionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedAction baseStandardAction.
                 * @member {infinitusai.be.IStandardAction|null|undefined} baseStandardAction
                 * @memberof infinitusai.be.ExpandedAction
                 * @instance
                 */
                ExpandedAction.prototype.baseStandardAction = null;
    
                /**
                 * ExpandedAction expandedName.
                 * @member {string} expandedName
                 * @memberof infinitusai.be.ExpandedAction
                 * @instance
                 */
                ExpandedAction.prototype.expandedName = "";
    
                /**
                 * ExpandedAction expandedOutputIds.
                 * @member {Array.<number|Long>} expandedOutputIds
                 * @memberof infinitusai.be.ExpandedAction
                 * @instance
                 */
                ExpandedAction.prototype.expandedOutputIds = $util.emptyArray;
    
                /**
                 * Creates a new ExpandedAction instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {infinitusai.be.IExpandedAction=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedAction} ExpandedAction instance
                 */
                ExpandedAction.create = function create(properties) {
                    return new ExpandedAction(properties);
                };
    
                /**
                 * Encodes the specified ExpandedAction message. Does not implicitly {@link infinitusai.be.ExpandedAction.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {infinitusai.be.IExpandedAction} message ExpandedAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedAction.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedActionId != null && Object.hasOwnProperty.call(message, "expandedActionId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.expandedActionId);
                    if (message.baseStandardActionId != null && Object.hasOwnProperty.call(message, "baseStandardActionId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.baseStandardActionId);
                    if (message.baseStandardAction != null && Object.hasOwnProperty.call(message, "baseStandardAction"))
                        $root.infinitusai.be.StandardAction.encode(message.baseStandardAction, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.expandedName != null && Object.hasOwnProperty.call(message, "expandedName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.expandedName);
                    if (message.expandedOutputIds != null && message.expandedOutputIds.length) {
                        writer.uint32(/* id 5, wireType 2 =*/42).fork();
                        for (var i = 0; i < message.expandedOutputIds.length; ++i)
                            writer.int64(message.expandedOutputIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedAction message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedAction.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {infinitusai.be.IExpandedAction} message ExpandedAction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedAction.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedAction message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedAction} ExpandedAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedAction.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedAction();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.expandedActionId = reader.int64();
                                break;
                            }
                        case 2: {
                                message.baseStandardActionId = reader.int64();
                                break;
                            }
                        case 3: {
                                message.baseStandardAction = $root.infinitusai.be.StandardAction.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.expandedName = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.expandedOutputIds && message.expandedOutputIds.length))
                                    message.expandedOutputIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.expandedOutputIds.push(reader.int64());
                                } else
                                    message.expandedOutputIds.push(reader.int64());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedAction message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedAction} ExpandedAction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedAction.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedAction message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedAction.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedActionId != null && message.hasOwnProperty("expandedActionId"))
                        if (!$util.isInteger(message.expandedActionId) && !(message.expandedActionId && $util.isInteger(message.expandedActionId.low) && $util.isInteger(message.expandedActionId.high)))
                            return "expandedActionId: integer|Long expected";
                    if (message.baseStandardActionId != null && message.hasOwnProperty("baseStandardActionId"))
                        if (!$util.isInteger(message.baseStandardActionId) && !(message.baseStandardActionId && $util.isInteger(message.baseStandardActionId.low) && $util.isInteger(message.baseStandardActionId.high)))
                            return "baseStandardActionId: integer|Long expected";
                    if (message.baseStandardAction != null && message.hasOwnProperty("baseStandardAction")) {
                        var error = $root.infinitusai.be.StandardAction.verify(message.baseStandardAction);
                        if (error)
                            return "baseStandardAction." + error;
                    }
                    if (message.expandedName != null && message.hasOwnProperty("expandedName"))
                        if (!$util.isString(message.expandedName))
                            return "expandedName: string expected";
                    if (message.expandedOutputIds != null && message.hasOwnProperty("expandedOutputIds")) {
                        if (!Array.isArray(message.expandedOutputIds))
                            return "expandedOutputIds: array expected";
                        for (var i = 0; i < message.expandedOutputIds.length; ++i)
                            if (!$util.isInteger(message.expandedOutputIds[i]) && !(message.expandedOutputIds[i] && $util.isInteger(message.expandedOutputIds[i].low) && $util.isInteger(message.expandedOutputIds[i].high)))
                                return "expandedOutputIds: integer|Long[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedAction message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedAction} ExpandedAction
                 */
                ExpandedAction.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedAction)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedAction();
                    if (object.expandedActionId != null)
                        if ($util.Long)
                            (message.expandedActionId = $util.Long.fromValue(object.expandedActionId)).unsigned = false;
                        else if (typeof object.expandedActionId === "string")
                            message.expandedActionId = parseInt(object.expandedActionId, 10);
                        else if (typeof object.expandedActionId === "number")
                            message.expandedActionId = object.expandedActionId;
                        else if (typeof object.expandedActionId === "object")
                            message.expandedActionId = new $util.LongBits(object.expandedActionId.low >>> 0, object.expandedActionId.high >>> 0).toNumber();
                    if (object.baseStandardActionId != null)
                        if ($util.Long)
                            (message.baseStandardActionId = $util.Long.fromValue(object.baseStandardActionId)).unsigned = false;
                        else if (typeof object.baseStandardActionId === "string")
                            message.baseStandardActionId = parseInt(object.baseStandardActionId, 10);
                        else if (typeof object.baseStandardActionId === "number")
                            message.baseStandardActionId = object.baseStandardActionId;
                        else if (typeof object.baseStandardActionId === "object")
                            message.baseStandardActionId = new $util.LongBits(object.baseStandardActionId.low >>> 0, object.baseStandardActionId.high >>> 0).toNumber();
                    if (object.baseStandardAction != null) {
                        if (typeof object.baseStandardAction !== "object")
                            throw TypeError(".infinitusai.be.ExpandedAction.baseStandardAction: object expected");
                        message.baseStandardAction = $root.infinitusai.be.StandardAction.fromObject(object.baseStandardAction);
                    }
                    if (object.expandedName != null)
                        message.expandedName = String(object.expandedName);
                    if (object.expandedOutputIds) {
                        if (!Array.isArray(object.expandedOutputIds))
                            throw TypeError(".infinitusai.be.ExpandedAction.expandedOutputIds: array expected");
                        message.expandedOutputIds = [];
                        for (var i = 0; i < object.expandedOutputIds.length; ++i)
                            if ($util.Long)
                                (message.expandedOutputIds[i] = $util.Long.fromValue(object.expandedOutputIds[i])).unsigned = false;
                            else if (typeof object.expandedOutputIds[i] === "string")
                                message.expandedOutputIds[i] = parseInt(object.expandedOutputIds[i], 10);
                            else if (typeof object.expandedOutputIds[i] === "number")
                                message.expandedOutputIds[i] = object.expandedOutputIds[i];
                            else if (typeof object.expandedOutputIds[i] === "object")
                                message.expandedOutputIds[i] = new $util.LongBits(object.expandedOutputIds[i].low >>> 0, object.expandedOutputIds[i].high >>> 0).toNumber();
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedAction message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {infinitusai.be.ExpandedAction} message ExpandedAction
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedAction.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.expandedOutputIds = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedActionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedActionId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.baseStandardActionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.baseStandardActionId = options.longs === String ? "0" : 0;
                        object.baseStandardAction = null;
                        object.expandedName = "";
                    }
                    if (message.expandedActionId != null && message.hasOwnProperty("expandedActionId"))
                        if (typeof message.expandedActionId === "number")
                            object.expandedActionId = options.longs === String ? String(message.expandedActionId) : message.expandedActionId;
                        else
                            object.expandedActionId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedActionId) : options.longs === Number ? new $util.LongBits(message.expandedActionId.low >>> 0, message.expandedActionId.high >>> 0).toNumber() : message.expandedActionId;
                    if (message.baseStandardActionId != null && message.hasOwnProperty("baseStandardActionId"))
                        if (typeof message.baseStandardActionId === "number")
                            object.baseStandardActionId = options.longs === String ? String(message.baseStandardActionId) : message.baseStandardActionId;
                        else
                            object.baseStandardActionId = options.longs === String ? $util.Long.prototype.toString.call(message.baseStandardActionId) : options.longs === Number ? new $util.LongBits(message.baseStandardActionId.low >>> 0, message.baseStandardActionId.high >>> 0).toNumber() : message.baseStandardActionId;
                    if (message.baseStandardAction != null && message.hasOwnProperty("baseStandardAction"))
                        object.baseStandardAction = $root.infinitusai.be.StandardAction.toObject(message.baseStandardAction, options);
                    if (message.expandedName != null && message.hasOwnProperty("expandedName"))
                        object.expandedName = message.expandedName;
                    if (message.expandedOutputIds && message.expandedOutputIds.length) {
                        object.expandedOutputIds = [];
                        for (var j = 0; j < message.expandedOutputIds.length; ++j)
                            if (typeof message.expandedOutputIds[j] === "number")
                                object.expandedOutputIds[j] = options.longs === String ? String(message.expandedOutputIds[j]) : message.expandedOutputIds[j];
                            else
                                object.expandedOutputIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.expandedOutputIds[j]) : options.longs === Number ? new $util.LongBits(message.expandedOutputIds[j].low >>> 0, message.expandedOutputIds[j].high >>> 0).toNumber() : message.expandedOutputIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ExpandedAction to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedAction
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedAction.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedAction
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedAction
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedAction";
                };
    
                return ExpandedAction;
            })();
    
            /**
             * StandardOutputType enum.
             * @name infinitusai.be.StandardOutputType
             * @enum {number}
             * @property {number} StandardOutputType_UNKNOWN=0 StandardOutputType_UNKNOWN value
             * @property {number} STRING=1 STRING value
             * @property {number} INT=2 INT value
             * @property {number} BOOL=3 BOOL value
             * @property {number} MONEY=4 MONEY value
             * @property {number} ENUM=5 ENUM value
             * @property {number} DATE=6 DATE value
             * @property {number} FLOAT=7 FLOAT value
             * @property {number} ENUMS=8 ENUMS value
             */
            be.StandardOutputType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "StandardOutputType_UNKNOWN"] = 0;
                values[valuesById[1] = "STRING"] = 1;
                values[valuesById[2] = "INT"] = 2;
                values[valuesById[3] = "BOOL"] = 3;
                values[valuesById[4] = "MONEY"] = 4;
                values[valuesById[5] = "ENUM"] = 5;
                values[valuesById[6] = "DATE"] = 6;
                values[valuesById[7] = "FLOAT"] = 7;
                values[valuesById[8] = "ENUMS"] = 8;
                return values;
            })();
    
            be.StandardOutput = (function() {
    
                /**
                 * Properties of a StandardOutput.
                 * @memberof infinitusai.be
                 * @interface IStandardOutput
                 * @property {number|Long|null} [id] StandardOutput id
                 * @property {string|null} [name] StandardOutput name
                 * @property {Array.<infinitusai.be.IFunctionWithParams>|null} [nameFunctions] StandardOutput nameFunctions
                 * @property {google.protobuf.ITimestamp|null} [createdAt] StandardOutput createdAt
                 * @property {google.protobuf.ITimestamp|null} [updatedAt] StandardOutput updatedAt
                 * @property {infinitusai.be.StandardOutputType|null} [outputType] StandardOutput outputType
                 * @property {Array.<string>|null} [allowedValues] StandardOutput allowedValues
                 * @property {Array.<string>|null} [hints] StandardOutput hints
                 * @property {Array.<infinitusai.be.IFunctionWithParams>|null} [hintFunctions] StandardOutput hintFunctions
                 * @property {string|null} [label] StandardOutput label
                 * @property {Array.<infinitusai.be.IFunctionWithParams>|null} [labelFunctions] StandardOutput labelFunctions
                 * @property {boolean|null} [required] StandardOutput required
                 * @property {Object.<string,string>|null} [allowedValuesOverrides] StandardOutput allowedValuesOverrides
                 * @property {Object.<string,string>|null} [hintOverrides] StandardOutput hintOverrides
                 * @property {Object.<string,string>|null} [labelOverrides] StandardOutput labelOverrides
                 * @property {Object.<string,boolean>|null} [requiredOverrides] StandardOutput requiredOverrides
                 * @property {infinitusai.be.IFunctionWithParams|null} [range] StandardOutput range
                 * @property {boolean|null} [autofillFromKnowledgeGraph] StandardOutput autofillFromKnowledgeGraph
                 */
    
                /**
                 * Constructs a new StandardOutput.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardOutput.
                 * @implements IStandardOutput
                 * @constructor
                 * @param {infinitusai.be.IStandardOutput=} [properties] Properties to set
                 */
                function StandardOutput(properties) {
                    this.nameFunctions = [];
                    this.allowedValues = [];
                    this.hints = [];
                    this.hintFunctions = [];
                    this.labelFunctions = [];
                    this.allowedValuesOverrides = {};
                    this.hintOverrides = {};
                    this.labelOverrides = {};
                    this.requiredOverrides = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardOutput id.
                 * @member {number|Long} id
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardOutput name.
                 * @member {string} name
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.name = "";
    
                /**
                 * StandardOutput nameFunctions.
                 * @member {Array.<infinitusai.be.IFunctionWithParams>} nameFunctions
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.nameFunctions = $util.emptyArray;
    
                /**
                 * StandardOutput createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.createdAt = null;
    
                /**
                 * StandardOutput updatedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.updatedAt = null;
    
                /**
                 * StandardOutput outputType.
                 * @member {infinitusai.be.StandardOutputType} outputType
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.outputType = 0;
    
                /**
                 * StandardOutput allowedValues.
                 * @member {Array.<string>} allowedValues
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.allowedValues = $util.emptyArray;
    
                /**
                 * StandardOutput hints.
                 * @member {Array.<string>} hints
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.hints = $util.emptyArray;
    
                /**
                 * StandardOutput hintFunctions.
                 * @member {Array.<infinitusai.be.IFunctionWithParams>} hintFunctions
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.hintFunctions = $util.emptyArray;
    
                /**
                 * StandardOutput label.
                 * @member {string} label
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.label = "";
    
                /**
                 * StandardOutput labelFunctions.
                 * @member {Array.<infinitusai.be.IFunctionWithParams>} labelFunctions
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.labelFunctions = $util.emptyArray;
    
                /**
                 * StandardOutput required.
                 * @member {boolean} required
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.required = false;
    
                /**
                 * StandardOutput allowedValuesOverrides.
                 * @member {Object.<string,string>} allowedValuesOverrides
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.allowedValuesOverrides = $util.emptyObject;
    
                /**
                 * StandardOutput hintOverrides.
                 * @member {Object.<string,string>} hintOverrides
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.hintOverrides = $util.emptyObject;
    
                /**
                 * StandardOutput labelOverrides.
                 * @member {Object.<string,string>} labelOverrides
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.labelOverrides = $util.emptyObject;
    
                /**
                 * StandardOutput requiredOverrides.
                 * @member {Object.<string,boolean>} requiredOverrides
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.requiredOverrides = $util.emptyObject;
    
                /**
                 * StandardOutput range.
                 * @member {infinitusai.be.IFunctionWithParams|null|undefined} range
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.range = null;
    
                /**
                 * StandardOutput autofillFromKnowledgeGraph.
                 * @member {boolean} autofillFromKnowledgeGraph
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 */
                StandardOutput.prototype.autofillFromKnowledgeGraph = false;
    
                /**
                 * Creates a new StandardOutput instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {infinitusai.be.IStandardOutput=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardOutput} StandardOutput instance
                 */
                StandardOutput.create = function create(properties) {
                    return new StandardOutput(properties);
                };
    
                /**
                 * Encodes the specified StandardOutput message. Does not implicitly {@link infinitusai.be.StandardOutput.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {infinitusai.be.IStandardOutput} message StandardOutput message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardOutput.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.nameFunctions != null && message.nameFunctions.length)
                        for (var i = 0; i < message.nameFunctions.length; ++i)
                            $root.infinitusai.be.FunctionWithParams.encode(message.nameFunctions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                        $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                        $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.outputType);
                    if (message.allowedValues != null && message.allowedValues.length)
                        for (var i = 0; i < message.allowedValues.length; ++i)
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.allowedValues[i]);
                    if (message.hints != null && message.hints.length)
                        for (var i = 0; i < message.hints.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.hints[i]);
                    if (message.hintFunctions != null && message.hintFunctions.length)
                        for (var i = 0; i < message.hintFunctions.length; ++i)
                            $root.infinitusai.be.FunctionWithParams.encode(message.hintFunctions[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.label);
                    if (message.labelFunctions != null && message.labelFunctions.length)
                        for (var i = 0; i < message.labelFunctions.length; ++i)
                            $root.infinitusai.be.FunctionWithParams.encode(message.labelFunctions[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.required);
                    if (message.allowedValuesOverrides != null && Object.hasOwnProperty.call(message, "allowedValuesOverrides"))
                        for (var keys = Object.keys(message.allowedValuesOverrides), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 13, wireType 2 =*/106).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.allowedValuesOverrides[keys[i]]).ldelim();
                    if (message.hintOverrides != null && Object.hasOwnProperty.call(message, "hintOverrides"))
                        for (var keys = Object.keys(message.hintOverrides), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 14, wireType 2 =*/114).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.hintOverrides[keys[i]]).ldelim();
                    if (message.labelOverrides != null && Object.hasOwnProperty.call(message, "labelOverrides"))
                        for (var keys = Object.keys(message.labelOverrides), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 15, wireType 2 =*/122).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.labelOverrides[keys[i]]).ldelim();
                    if (message.requiredOverrides != null && Object.hasOwnProperty.call(message, "requiredOverrides"))
                        for (var keys = Object.keys(message.requiredOverrides), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 16, wireType 2 =*/130).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.requiredOverrides[keys[i]]).ldelim();
                    if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                        $root.infinitusai.be.FunctionWithParams.encode(message.range, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.autofillFromKnowledgeGraph != null && Object.hasOwnProperty.call(message, "autofillFromKnowledgeGraph"))
                        writer.uint32(/* id 18, wireType 0 =*/144).bool(message.autofillFromKnowledgeGraph);
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardOutput message, length delimited. Does not implicitly {@link infinitusai.be.StandardOutput.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {infinitusai.be.IStandardOutput} message StandardOutput message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardOutput.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardOutput message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardOutput} StandardOutput
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardOutput.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardOutput(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int64();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.nameFunctions && message.nameFunctions.length))
                                    message.nameFunctions = [];
                                message.nameFunctions.push($root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.outputType = reader.int32();
                                break;
                            }
                        case 7: {
                                if (!(message.allowedValues && message.allowedValues.length))
                                    message.allowedValues = [];
                                message.allowedValues.push(reader.string());
                                break;
                            }
                        case 8: {
                                if (!(message.hints && message.hints.length))
                                    message.hints = [];
                                message.hints.push(reader.string());
                                break;
                            }
                        case 9: {
                                if (!(message.hintFunctions && message.hintFunctions.length))
                                    message.hintFunctions = [];
                                message.hintFunctions.push($root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                message.label = reader.string();
                                break;
                            }
                        case 11: {
                                if (!(message.labelFunctions && message.labelFunctions.length))
                                    message.labelFunctions = [];
                                message.labelFunctions.push($root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32()));
                                break;
                            }
                        case 12: {
                                message.required = reader.bool();
                                break;
                            }
                        case 13: {
                                if (message.allowedValuesOverrides === $util.emptyObject)
                                    message.allowedValuesOverrides = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.allowedValuesOverrides[key] = value;
                                break;
                            }
                        case 14: {
                                if (message.hintOverrides === $util.emptyObject)
                                    message.hintOverrides = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.hintOverrides[key] = value;
                                break;
                            }
                        case 15: {
                                if (message.labelOverrides === $util.emptyObject)
                                    message.labelOverrides = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.labelOverrides[key] = value;
                                break;
                            }
                        case 16: {
                                if (message.requiredOverrides === $util.emptyObject)
                                    message.requiredOverrides = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = false;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.bool();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.requiredOverrides[key] = value;
                                break;
                            }
                        case 17: {
                                message.range = $root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                message.autofillFromKnowledgeGraph = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardOutput message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardOutput} StandardOutput
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardOutput.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardOutput message.
                 * @function verify
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardOutput.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                            return "id: integer|Long expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.nameFunctions != null && message.hasOwnProperty("nameFunctions")) {
                        if (!Array.isArray(message.nameFunctions))
                            return "nameFunctions: array expected";
                        for (var i = 0; i < message.nameFunctions.length; ++i) {
                            var error = $root.infinitusai.be.FunctionWithParams.verify(message.nameFunctions[i]);
                            if (error)
                                return "nameFunctions." + error;
                        }
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                        if (error)
                            return "createdAt." + error;
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
                        if (error)
                            return "updatedAt." + error;
                    }
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        switch (message.outputType) {
                        default:
                            return "outputType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.allowedValues != null && message.hasOwnProperty("allowedValues")) {
                        if (!Array.isArray(message.allowedValues))
                            return "allowedValues: array expected";
                        for (var i = 0; i < message.allowedValues.length; ++i)
                            if (!$util.isString(message.allowedValues[i]))
                                return "allowedValues: string[] expected";
                    }
                    if (message.hints != null && message.hasOwnProperty("hints")) {
                        if (!Array.isArray(message.hints))
                            return "hints: array expected";
                        for (var i = 0; i < message.hints.length; ++i)
                            if (!$util.isString(message.hints[i]))
                                return "hints: string[] expected";
                    }
                    if (message.hintFunctions != null && message.hasOwnProperty("hintFunctions")) {
                        if (!Array.isArray(message.hintFunctions))
                            return "hintFunctions: array expected";
                        for (var i = 0; i < message.hintFunctions.length; ++i) {
                            var error = $root.infinitusai.be.FunctionWithParams.verify(message.hintFunctions[i]);
                            if (error)
                                return "hintFunctions." + error;
                        }
                    }
                    if (message.label != null && message.hasOwnProperty("label"))
                        if (!$util.isString(message.label))
                            return "label: string expected";
                    if (message.labelFunctions != null && message.hasOwnProperty("labelFunctions")) {
                        if (!Array.isArray(message.labelFunctions))
                            return "labelFunctions: array expected";
                        for (var i = 0; i < message.labelFunctions.length; ++i) {
                            var error = $root.infinitusai.be.FunctionWithParams.verify(message.labelFunctions[i]);
                            if (error)
                                return "labelFunctions." + error;
                        }
                    }
                    if (message.required != null && message.hasOwnProperty("required"))
                        if (typeof message.required !== "boolean")
                            return "required: boolean expected";
                    if (message.allowedValuesOverrides != null && message.hasOwnProperty("allowedValuesOverrides")) {
                        if (!$util.isObject(message.allowedValuesOverrides))
                            return "allowedValuesOverrides: object expected";
                        var key = Object.keys(message.allowedValuesOverrides);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.allowedValuesOverrides[key[i]]))
                                return "allowedValuesOverrides: string{k:string} expected";
                    }
                    if (message.hintOverrides != null && message.hasOwnProperty("hintOverrides")) {
                        if (!$util.isObject(message.hintOverrides))
                            return "hintOverrides: object expected";
                        var key = Object.keys(message.hintOverrides);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.hintOverrides[key[i]]))
                                return "hintOverrides: string{k:string} expected";
                    }
                    if (message.labelOverrides != null && message.hasOwnProperty("labelOverrides")) {
                        if (!$util.isObject(message.labelOverrides))
                            return "labelOverrides: object expected";
                        var key = Object.keys(message.labelOverrides);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.labelOverrides[key[i]]))
                                return "labelOverrides: string{k:string} expected";
                    }
                    if (message.requiredOverrides != null && message.hasOwnProperty("requiredOverrides")) {
                        if (!$util.isObject(message.requiredOverrides))
                            return "requiredOverrides: object expected";
                        var key = Object.keys(message.requiredOverrides);
                        for (var i = 0; i < key.length; ++i)
                            if (typeof message.requiredOverrides[key[i]] !== "boolean")
                                return "requiredOverrides: boolean{k:string} expected";
                    }
                    if (message.range != null && message.hasOwnProperty("range")) {
                        var error = $root.infinitusai.be.FunctionWithParams.verify(message.range);
                        if (error)
                            return "range." + error;
                    }
                    if (message.autofillFromKnowledgeGraph != null && message.hasOwnProperty("autofillFromKnowledgeGraph"))
                        if (typeof message.autofillFromKnowledgeGraph !== "boolean")
                            return "autofillFromKnowledgeGraph: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a StandardOutput message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardOutput} StandardOutput
                 */
                StandardOutput.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardOutput)
                        return object;
                    var message = new $root.infinitusai.be.StandardOutput();
                    if (object.id != null)
                        if ($util.Long)
                            (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                        else if (typeof object.id === "string")
                            message.id = parseInt(object.id, 10);
                        else if (typeof object.id === "number")
                            message.id = object.id;
                        else if (typeof object.id === "object")
                            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.nameFunctions) {
                        if (!Array.isArray(object.nameFunctions))
                            throw TypeError(".infinitusai.be.StandardOutput.nameFunctions: array expected");
                        message.nameFunctions = [];
                        for (var i = 0; i < object.nameFunctions.length; ++i) {
                            if (typeof object.nameFunctions[i] !== "object")
                                throw TypeError(".infinitusai.be.StandardOutput.nameFunctions: object expected");
                            message.nameFunctions[i] = $root.infinitusai.be.FunctionWithParams.fromObject(object.nameFunctions[i]);
                        }
                    }
                    if (object.createdAt != null) {
                        if (typeof object.createdAt !== "object")
                            throw TypeError(".infinitusai.be.StandardOutput.createdAt: object expected");
                        message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                    }
                    if (object.updatedAt != null) {
                        if (typeof object.updatedAt !== "object")
                            throw TypeError(".infinitusai.be.StandardOutput.updatedAt: object expected");
                        message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
                    }
                    switch (object.outputType) {
                    default:
                        if (typeof object.outputType === "number") {
                            message.outputType = object.outputType;
                            break;
                        }
                        break;
                    case "StandardOutputType_UNKNOWN":
                    case 0:
                        message.outputType = 0;
                        break;
                    case "STRING":
                    case 1:
                        message.outputType = 1;
                        break;
                    case "INT":
                    case 2:
                        message.outputType = 2;
                        break;
                    case "BOOL":
                    case 3:
                        message.outputType = 3;
                        break;
                    case "MONEY":
                    case 4:
                        message.outputType = 4;
                        break;
                    case "ENUM":
                    case 5:
                        message.outputType = 5;
                        break;
                    case "DATE":
                    case 6:
                        message.outputType = 6;
                        break;
                    case "FLOAT":
                    case 7:
                        message.outputType = 7;
                        break;
                    case "ENUMS":
                    case 8:
                        message.outputType = 8;
                        break;
                    }
                    if (object.allowedValues) {
                        if (!Array.isArray(object.allowedValues))
                            throw TypeError(".infinitusai.be.StandardOutput.allowedValues: array expected");
                        message.allowedValues = [];
                        for (var i = 0; i < object.allowedValues.length; ++i)
                            message.allowedValues[i] = String(object.allowedValues[i]);
                    }
                    if (object.hints) {
                        if (!Array.isArray(object.hints))
                            throw TypeError(".infinitusai.be.StandardOutput.hints: array expected");
                        message.hints = [];
                        for (var i = 0; i < object.hints.length; ++i)
                            message.hints[i] = String(object.hints[i]);
                    }
                    if (object.hintFunctions) {
                        if (!Array.isArray(object.hintFunctions))
                            throw TypeError(".infinitusai.be.StandardOutput.hintFunctions: array expected");
                        message.hintFunctions = [];
                        for (var i = 0; i < object.hintFunctions.length; ++i) {
                            if (typeof object.hintFunctions[i] !== "object")
                                throw TypeError(".infinitusai.be.StandardOutput.hintFunctions: object expected");
                            message.hintFunctions[i] = $root.infinitusai.be.FunctionWithParams.fromObject(object.hintFunctions[i]);
                        }
                    }
                    if (object.label != null)
                        message.label = String(object.label);
                    if (object.labelFunctions) {
                        if (!Array.isArray(object.labelFunctions))
                            throw TypeError(".infinitusai.be.StandardOutput.labelFunctions: array expected");
                        message.labelFunctions = [];
                        for (var i = 0; i < object.labelFunctions.length; ++i) {
                            if (typeof object.labelFunctions[i] !== "object")
                                throw TypeError(".infinitusai.be.StandardOutput.labelFunctions: object expected");
                            message.labelFunctions[i] = $root.infinitusai.be.FunctionWithParams.fromObject(object.labelFunctions[i]);
                        }
                    }
                    if (object.required != null)
                        message.required = Boolean(object.required);
                    if (object.allowedValuesOverrides) {
                        if (typeof object.allowedValuesOverrides !== "object")
                            throw TypeError(".infinitusai.be.StandardOutput.allowedValuesOverrides: object expected");
                        message.allowedValuesOverrides = {};
                        for (var keys = Object.keys(object.allowedValuesOverrides), i = 0; i < keys.length; ++i)
                            message.allowedValuesOverrides[keys[i]] = String(object.allowedValuesOverrides[keys[i]]);
                    }
                    if (object.hintOverrides) {
                        if (typeof object.hintOverrides !== "object")
                            throw TypeError(".infinitusai.be.StandardOutput.hintOverrides: object expected");
                        message.hintOverrides = {};
                        for (var keys = Object.keys(object.hintOverrides), i = 0; i < keys.length; ++i)
                            message.hintOverrides[keys[i]] = String(object.hintOverrides[keys[i]]);
                    }
                    if (object.labelOverrides) {
                        if (typeof object.labelOverrides !== "object")
                            throw TypeError(".infinitusai.be.StandardOutput.labelOverrides: object expected");
                        message.labelOverrides = {};
                        for (var keys = Object.keys(object.labelOverrides), i = 0; i < keys.length; ++i)
                            message.labelOverrides[keys[i]] = String(object.labelOverrides[keys[i]]);
                    }
                    if (object.requiredOverrides) {
                        if (typeof object.requiredOverrides !== "object")
                            throw TypeError(".infinitusai.be.StandardOutput.requiredOverrides: object expected");
                        message.requiredOverrides = {};
                        for (var keys = Object.keys(object.requiredOverrides), i = 0; i < keys.length; ++i)
                            message.requiredOverrides[keys[i]] = Boolean(object.requiredOverrides[keys[i]]);
                    }
                    if (object.range != null) {
                        if (typeof object.range !== "object")
                            throw TypeError(".infinitusai.be.StandardOutput.range: object expected");
                        message.range = $root.infinitusai.be.FunctionWithParams.fromObject(object.range);
                    }
                    if (object.autofillFromKnowledgeGraph != null)
                        message.autofillFromKnowledgeGraph = Boolean(object.autofillFromKnowledgeGraph);
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardOutput message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {infinitusai.be.StandardOutput} message StandardOutput
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardOutput.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.nameFunctions = [];
                        object.allowedValues = [];
                        object.hints = [];
                        object.hintFunctions = [];
                        object.labelFunctions = [];
                    }
                    if (options.objects || options.defaults) {
                        object.allowedValuesOverrides = {};
                        object.hintOverrides = {};
                        object.labelOverrides = {};
                        object.requiredOverrides = {};
                    }
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.id = options.longs === String ? "0" : 0;
                        object.name = "";
                        object.createdAt = null;
                        object.updatedAt = null;
                        object.outputType = options.enums === String ? "StandardOutputType_UNKNOWN" : 0;
                        object.label = "";
                        object.required = false;
                        object.range = null;
                        object.autofillFromKnowledgeGraph = false;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (typeof message.id === "number")
                            object.id = options.longs === String ? String(message.id) : message.id;
                        else
                            object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.nameFunctions && message.nameFunctions.length) {
                        object.nameFunctions = [];
                        for (var j = 0; j < message.nameFunctions.length; ++j)
                            object.nameFunctions[j] = $root.infinitusai.be.FunctionWithParams.toObject(message.nameFunctions[j], options);
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        object.outputType = options.enums === String ? $root.infinitusai.be.StandardOutputType[message.outputType] === undefined ? message.outputType : $root.infinitusai.be.StandardOutputType[message.outputType] : message.outputType;
                    if (message.allowedValues && message.allowedValues.length) {
                        object.allowedValues = [];
                        for (var j = 0; j < message.allowedValues.length; ++j)
                            object.allowedValues[j] = message.allowedValues[j];
                    }
                    if (message.hints && message.hints.length) {
                        object.hints = [];
                        for (var j = 0; j < message.hints.length; ++j)
                            object.hints[j] = message.hints[j];
                    }
                    if (message.hintFunctions && message.hintFunctions.length) {
                        object.hintFunctions = [];
                        for (var j = 0; j < message.hintFunctions.length; ++j)
                            object.hintFunctions[j] = $root.infinitusai.be.FunctionWithParams.toObject(message.hintFunctions[j], options);
                    }
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = message.label;
                    if (message.labelFunctions && message.labelFunctions.length) {
                        object.labelFunctions = [];
                        for (var j = 0; j < message.labelFunctions.length; ++j)
                            object.labelFunctions[j] = $root.infinitusai.be.FunctionWithParams.toObject(message.labelFunctions[j], options);
                    }
                    if (message.required != null && message.hasOwnProperty("required"))
                        object.required = message.required;
                    var keys2;
                    if (message.allowedValuesOverrides && (keys2 = Object.keys(message.allowedValuesOverrides)).length) {
                        object.allowedValuesOverrides = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.allowedValuesOverrides[keys2[j]] = message.allowedValuesOverrides[keys2[j]];
                    }
                    if (message.hintOverrides && (keys2 = Object.keys(message.hintOverrides)).length) {
                        object.hintOverrides = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.hintOverrides[keys2[j]] = message.hintOverrides[keys2[j]];
                    }
                    if (message.labelOverrides && (keys2 = Object.keys(message.labelOverrides)).length) {
                        object.labelOverrides = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.labelOverrides[keys2[j]] = message.labelOverrides[keys2[j]];
                    }
                    if (message.requiredOverrides && (keys2 = Object.keys(message.requiredOverrides)).length) {
                        object.requiredOverrides = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.requiredOverrides[keys2[j]] = message.requiredOverrides[keys2[j]];
                    }
                    if (message.range != null && message.hasOwnProperty("range"))
                        object.range = $root.infinitusai.be.FunctionWithParams.toObject(message.range, options);
                    if (message.autofillFromKnowledgeGraph != null && message.hasOwnProperty("autofillFromKnowledgeGraph"))
                        object.autofillFromKnowledgeGraph = message.autofillFromKnowledgeGraph;
                    return object;
                };
    
                /**
                 * Converts this StandardOutput to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardOutput
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardOutput.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardOutput
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardOutput
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardOutput";
                };
    
                return StandardOutput;
            })();
    
            be.ExpandedOutput = (function() {
    
                /**
                 * Properties of an ExpandedOutput.
                 * @memberof infinitusai.be
                 * @interface IExpandedOutput
                 * @property {number|Long|null} [expandedOutputId] ExpandedOutput expandedOutputId
                 * @property {number|Long|null} [baseStandardOutputId] ExpandedOutput baseStandardOutputId
                 * @property {infinitusai.be.IStandardOutput|null} [baseStandardOutput] ExpandedOutput baseStandardOutput
                 * @property {string|null} [expandedName] ExpandedOutput expandedName
                 * @property {Array.<string>|null} [expandedHints] ExpandedOutput expandedHints
                 * @property {string|null} [expandedLabel] ExpandedOutput expandedLabel
                 * @property {infinitusai.be.IOutputValue|null} [value] ExpandedOutput value
                 * @property {string|null} [valueSource] ExpandedOutput valueSource
                 * @property {string|null} [sourceRecordId] ExpandedOutput sourceRecordId
                 */
    
                /**
                 * Constructs a new ExpandedOutput.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedOutput.
                 * @implements IExpandedOutput
                 * @constructor
                 * @param {infinitusai.be.IExpandedOutput=} [properties] Properties to set
                 */
                function ExpandedOutput(properties) {
                    this.expandedHints = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedOutput expandedOutputId.
                 * @member {number|Long} expandedOutputId
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.expandedOutputId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedOutput baseStandardOutputId.
                 * @member {number|Long} baseStandardOutputId
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.baseStandardOutputId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedOutput baseStandardOutput.
                 * @member {infinitusai.be.IStandardOutput|null|undefined} baseStandardOutput
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.baseStandardOutput = null;
    
                /**
                 * ExpandedOutput expandedName.
                 * @member {string} expandedName
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.expandedName = "";
    
                /**
                 * ExpandedOutput expandedHints.
                 * @member {Array.<string>} expandedHints
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.expandedHints = $util.emptyArray;
    
                /**
                 * ExpandedOutput expandedLabel.
                 * @member {string} expandedLabel
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.expandedLabel = "";
    
                /**
                 * ExpandedOutput value.
                 * @member {infinitusai.be.IOutputValue|null|undefined} value
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.value = null;
    
                /**
                 * ExpandedOutput valueSource.
                 * @member {string} valueSource
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.valueSource = "";
    
                /**
                 * ExpandedOutput sourceRecordId.
                 * @member {string} sourceRecordId
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 */
                ExpandedOutput.prototype.sourceRecordId = "";
    
                /**
                 * Creates a new ExpandedOutput instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {infinitusai.be.IExpandedOutput=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedOutput} ExpandedOutput instance
                 */
                ExpandedOutput.create = function create(properties) {
                    return new ExpandedOutput(properties);
                };
    
                /**
                 * Encodes the specified ExpandedOutput message. Does not implicitly {@link infinitusai.be.ExpandedOutput.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {infinitusai.be.IExpandedOutput} message ExpandedOutput message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedOutput.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedOutputId != null && Object.hasOwnProperty.call(message, "expandedOutputId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.expandedOutputId);
                    if (message.baseStandardOutputId != null && Object.hasOwnProperty.call(message, "baseStandardOutputId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.baseStandardOutputId);
                    if (message.baseStandardOutput != null && Object.hasOwnProperty.call(message, "baseStandardOutput"))
                        $root.infinitusai.be.StandardOutput.encode(message.baseStandardOutput, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.expandedName != null && Object.hasOwnProperty.call(message, "expandedName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.expandedName);
                    if (message.expandedHints != null && message.expandedHints.length)
                        for (var i = 0; i < message.expandedHints.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.expandedHints[i]);
                    if (message.expandedLabel != null && Object.hasOwnProperty.call(message, "expandedLabel"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.expandedLabel);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        $root.infinitusai.be.OutputValue.encode(message.value, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.valueSource != null && Object.hasOwnProperty.call(message, "valueSource"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.valueSource);
                    if (message.sourceRecordId != null && Object.hasOwnProperty.call(message, "sourceRecordId"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.sourceRecordId);
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedOutput message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedOutput.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {infinitusai.be.IExpandedOutput} message ExpandedOutput message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedOutput.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedOutput message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedOutput} ExpandedOutput
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedOutput.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedOutput();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.expandedOutputId = reader.int64();
                                break;
                            }
                        case 2: {
                                message.baseStandardOutputId = reader.int64();
                                break;
                            }
                        case 3: {
                                message.baseStandardOutput = $root.infinitusai.be.StandardOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.expandedName = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.expandedHints && message.expandedHints.length))
                                    message.expandedHints = [];
                                message.expandedHints.push(reader.string());
                                break;
                            }
                        case 6: {
                                message.expandedLabel = reader.string();
                                break;
                            }
                        case 7: {
                                message.value = $root.infinitusai.be.OutputValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.valueSource = reader.string();
                                break;
                            }
                        case 9: {
                                message.sourceRecordId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedOutput message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedOutput} ExpandedOutput
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedOutput.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedOutput message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedOutput.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedOutputId != null && message.hasOwnProperty("expandedOutputId"))
                        if (!$util.isInteger(message.expandedOutputId) && !(message.expandedOutputId && $util.isInteger(message.expandedOutputId.low) && $util.isInteger(message.expandedOutputId.high)))
                            return "expandedOutputId: integer|Long expected";
                    if (message.baseStandardOutputId != null && message.hasOwnProperty("baseStandardOutputId"))
                        if (!$util.isInteger(message.baseStandardOutputId) && !(message.baseStandardOutputId && $util.isInteger(message.baseStandardOutputId.low) && $util.isInteger(message.baseStandardOutputId.high)))
                            return "baseStandardOutputId: integer|Long expected";
                    if (message.baseStandardOutput != null && message.hasOwnProperty("baseStandardOutput")) {
                        var error = $root.infinitusai.be.StandardOutput.verify(message.baseStandardOutput);
                        if (error)
                            return "baseStandardOutput." + error;
                    }
                    if (message.expandedName != null && message.hasOwnProperty("expandedName"))
                        if (!$util.isString(message.expandedName))
                            return "expandedName: string expected";
                    if (message.expandedHints != null && message.hasOwnProperty("expandedHints")) {
                        if (!Array.isArray(message.expandedHints))
                            return "expandedHints: array expected";
                        for (var i = 0; i < message.expandedHints.length; ++i)
                            if (!$util.isString(message.expandedHints[i]))
                                return "expandedHints: string[] expected";
                    }
                    if (message.expandedLabel != null && message.hasOwnProperty("expandedLabel"))
                        if (!$util.isString(message.expandedLabel))
                            return "expandedLabel: string expected";
                    if (message.value != null && message.hasOwnProperty("value")) {
                        var error = $root.infinitusai.be.OutputValue.verify(message.value);
                        if (error)
                            return "value." + error;
                    }
                    if (message.valueSource != null && message.hasOwnProperty("valueSource"))
                        if (!$util.isString(message.valueSource))
                            return "valueSource: string expected";
                    if (message.sourceRecordId != null && message.hasOwnProperty("sourceRecordId"))
                        if (!$util.isString(message.sourceRecordId))
                            return "sourceRecordId: string expected";
                    return null;
                };
    
                /**
                 * Creates an ExpandedOutput message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedOutput} ExpandedOutput
                 */
                ExpandedOutput.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedOutput)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedOutput();
                    if (object.expandedOutputId != null)
                        if ($util.Long)
                            (message.expandedOutputId = $util.Long.fromValue(object.expandedOutputId)).unsigned = false;
                        else if (typeof object.expandedOutputId === "string")
                            message.expandedOutputId = parseInt(object.expandedOutputId, 10);
                        else if (typeof object.expandedOutputId === "number")
                            message.expandedOutputId = object.expandedOutputId;
                        else if (typeof object.expandedOutputId === "object")
                            message.expandedOutputId = new $util.LongBits(object.expandedOutputId.low >>> 0, object.expandedOutputId.high >>> 0).toNumber();
                    if (object.baseStandardOutputId != null)
                        if ($util.Long)
                            (message.baseStandardOutputId = $util.Long.fromValue(object.baseStandardOutputId)).unsigned = false;
                        else if (typeof object.baseStandardOutputId === "string")
                            message.baseStandardOutputId = parseInt(object.baseStandardOutputId, 10);
                        else if (typeof object.baseStandardOutputId === "number")
                            message.baseStandardOutputId = object.baseStandardOutputId;
                        else if (typeof object.baseStandardOutputId === "object")
                            message.baseStandardOutputId = new $util.LongBits(object.baseStandardOutputId.low >>> 0, object.baseStandardOutputId.high >>> 0).toNumber();
                    if (object.baseStandardOutput != null) {
                        if (typeof object.baseStandardOutput !== "object")
                            throw TypeError(".infinitusai.be.ExpandedOutput.baseStandardOutput: object expected");
                        message.baseStandardOutput = $root.infinitusai.be.StandardOutput.fromObject(object.baseStandardOutput);
                    }
                    if (object.expandedName != null)
                        message.expandedName = String(object.expandedName);
                    if (object.expandedHints) {
                        if (!Array.isArray(object.expandedHints))
                            throw TypeError(".infinitusai.be.ExpandedOutput.expandedHints: array expected");
                        message.expandedHints = [];
                        for (var i = 0; i < object.expandedHints.length; ++i)
                            message.expandedHints[i] = String(object.expandedHints[i]);
                    }
                    if (object.expandedLabel != null)
                        message.expandedLabel = String(object.expandedLabel);
                    if (object.value != null) {
                        if (typeof object.value !== "object")
                            throw TypeError(".infinitusai.be.ExpandedOutput.value: object expected");
                        message.value = $root.infinitusai.be.OutputValue.fromObject(object.value);
                    }
                    if (object.valueSource != null)
                        message.valueSource = String(object.valueSource);
                    if (object.sourceRecordId != null)
                        message.sourceRecordId = String(object.sourceRecordId);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedOutput message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {infinitusai.be.ExpandedOutput} message ExpandedOutput
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedOutput.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.expandedHints = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedOutputId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedOutputId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.baseStandardOutputId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.baseStandardOutputId = options.longs === String ? "0" : 0;
                        object.baseStandardOutput = null;
                        object.expandedName = "";
                        object.expandedLabel = "";
                        object.value = null;
                        object.valueSource = "";
                        object.sourceRecordId = "";
                    }
                    if (message.expandedOutputId != null && message.hasOwnProperty("expandedOutputId"))
                        if (typeof message.expandedOutputId === "number")
                            object.expandedOutputId = options.longs === String ? String(message.expandedOutputId) : message.expandedOutputId;
                        else
                            object.expandedOutputId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedOutputId) : options.longs === Number ? new $util.LongBits(message.expandedOutputId.low >>> 0, message.expandedOutputId.high >>> 0).toNumber() : message.expandedOutputId;
                    if (message.baseStandardOutputId != null && message.hasOwnProperty("baseStandardOutputId"))
                        if (typeof message.baseStandardOutputId === "number")
                            object.baseStandardOutputId = options.longs === String ? String(message.baseStandardOutputId) : message.baseStandardOutputId;
                        else
                            object.baseStandardOutputId = options.longs === String ? $util.Long.prototype.toString.call(message.baseStandardOutputId) : options.longs === Number ? new $util.LongBits(message.baseStandardOutputId.low >>> 0, message.baseStandardOutputId.high >>> 0).toNumber() : message.baseStandardOutputId;
                    if (message.baseStandardOutput != null && message.hasOwnProperty("baseStandardOutput"))
                        object.baseStandardOutput = $root.infinitusai.be.StandardOutput.toObject(message.baseStandardOutput, options);
                    if (message.expandedName != null && message.hasOwnProperty("expandedName"))
                        object.expandedName = message.expandedName;
                    if (message.expandedHints && message.expandedHints.length) {
                        object.expandedHints = [];
                        for (var j = 0; j < message.expandedHints.length; ++j)
                            object.expandedHints[j] = message.expandedHints[j];
                    }
                    if (message.expandedLabel != null && message.hasOwnProperty("expandedLabel"))
                        object.expandedLabel = message.expandedLabel;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = $root.infinitusai.be.OutputValue.toObject(message.value, options);
                    if (message.valueSource != null && message.hasOwnProperty("valueSource"))
                        object.valueSource = message.valueSource;
                    if (message.sourceRecordId != null && message.hasOwnProperty("sourceRecordId"))
                        object.sourceRecordId = message.sourceRecordId;
                    return object;
                };
    
                /**
                 * Converts this ExpandedOutput to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedOutput
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedOutput.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedOutput
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedOutput
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedOutput";
                };
    
                return ExpandedOutput;
            })();
    
            be.StandardModule = (function() {
    
                /**
                 * Properties of a StandardModule.
                 * @memberof infinitusai.be
                 * @interface IStandardModule
                 * @property {number|Long|null} [id] StandardModule id
                 * @property {string|null} [name] StandardModule name
                 * @property {Array.<infinitusai.be.IFunctionWithParams>|null} [nameFunctions] StandardModule nameFunctions
                 * @property {google.protobuf.ITimestamp|null} [createdAt] StandardModule createdAt
                 * @property {google.protobuf.ITimestamp|null} [updatedAt] StandardModule updatedAt
                 * @property {infinitusai.be.NextNodeTypeEnum|null} [startNodeType] StandardModule startNodeType
                 * @property {number|Long|null} [startNodeId] StandardModule startNodeId
                 * @property {Object.<string,infinitusai.be.IStandardModuleEdges>|null} [moduleEdges] StandardModule moduleEdges
                 * @property {infinitusai.be.IFunctionWithParams|null} [range] StandardModule range
                 */
    
                /**
                 * Constructs a new StandardModule.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardModule.
                 * @implements IStandardModule
                 * @constructor
                 * @param {infinitusai.be.IStandardModule=} [properties] Properties to set
                 */
                function StandardModule(properties) {
                    this.nameFunctions = [];
                    this.moduleEdges = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardModule id.
                 * @member {number|Long} id
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardModule name.
                 * @member {string} name
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.name = "";
    
                /**
                 * StandardModule nameFunctions.
                 * @member {Array.<infinitusai.be.IFunctionWithParams>} nameFunctions
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.nameFunctions = $util.emptyArray;
    
                /**
                 * StandardModule createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.createdAt = null;
    
                /**
                 * StandardModule updatedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.updatedAt = null;
    
                /**
                 * StandardModule startNodeType.
                 * @member {infinitusai.be.NextNodeTypeEnum} startNodeType
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.startNodeType = 0;
    
                /**
                 * StandardModule startNodeId.
                 * @member {number|Long} startNodeId
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.startNodeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardModule moduleEdges.
                 * @member {Object.<string,infinitusai.be.IStandardModuleEdges>} moduleEdges
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.moduleEdges = $util.emptyObject;
    
                /**
                 * StandardModule range.
                 * @member {infinitusai.be.IFunctionWithParams|null|undefined} range
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 */
                StandardModule.prototype.range = null;
    
                /**
                 * Creates a new StandardModule instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {infinitusai.be.IStandardModule=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardModule} StandardModule instance
                 */
                StandardModule.create = function create(properties) {
                    return new StandardModule(properties);
                };
    
                /**
                 * Encodes the specified StandardModule message. Does not implicitly {@link infinitusai.be.StandardModule.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {infinitusai.be.IStandardModule} message StandardModule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardModule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.nameFunctions != null && message.nameFunctions.length)
                        for (var i = 0; i < message.nameFunctions.length; ++i)
                            $root.infinitusai.be.FunctionWithParams.encode(message.nameFunctions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                        $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                        $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.startNodeType != null && Object.hasOwnProperty.call(message, "startNodeType"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.startNodeType);
                    if (message.startNodeId != null && Object.hasOwnProperty.call(message, "startNodeId"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.startNodeId);
                    if (message.moduleEdges != null && Object.hasOwnProperty.call(message, "moduleEdges"))
                        for (var keys = Object.keys(message.moduleEdges), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 8, wireType 2 =*/66).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.StandardModuleEdges.encode(message.moduleEdges[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                        $root.infinitusai.be.FunctionWithParams.encode(message.range, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardModule message, length delimited. Does not implicitly {@link infinitusai.be.StandardModule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {infinitusai.be.IStandardModule} message StandardModule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardModule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardModule message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardModule} StandardModule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardModule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardModule(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int64();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.nameFunctions && message.nameFunctions.length))
                                    message.nameFunctions = [];
                                message.nameFunctions.push($root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.startNodeType = reader.int32();
                                break;
                            }
                        case 7: {
                                message.startNodeId = reader.int64();
                                break;
                            }
                        case 8: {
                                if (message.moduleEdges === $util.emptyObject)
                                    message.moduleEdges = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.StandardModuleEdges.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.moduleEdges[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        case 9: {
                                message.range = $root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardModule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardModule} StandardModule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardModule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardModule message.
                 * @function verify
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardModule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                            return "id: integer|Long expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.nameFunctions != null && message.hasOwnProperty("nameFunctions")) {
                        if (!Array.isArray(message.nameFunctions))
                            return "nameFunctions: array expected";
                        for (var i = 0; i < message.nameFunctions.length; ++i) {
                            var error = $root.infinitusai.be.FunctionWithParams.verify(message.nameFunctions[i]);
                            if (error)
                                return "nameFunctions." + error;
                        }
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                        if (error)
                            return "createdAt." + error;
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
                        if (error)
                            return "updatedAt." + error;
                    }
                    if (message.startNodeType != null && message.hasOwnProperty("startNodeType"))
                        switch (message.startNodeType) {
                        default:
                            return "startNodeType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.startNodeId != null && message.hasOwnProperty("startNodeId"))
                        if (!$util.isInteger(message.startNodeId) && !(message.startNodeId && $util.isInteger(message.startNodeId.low) && $util.isInteger(message.startNodeId.high)))
                            return "startNodeId: integer|Long expected";
                    if (message.moduleEdges != null && message.hasOwnProperty("moduleEdges")) {
                        if (!$util.isObject(message.moduleEdges))
                            return "moduleEdges: object expected";
                        var key = Object.keys(message.moduleEdges);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "moduleEdges: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.StandardModuleEdges.verify(message.moduleEdges[key[i]]);
                                if (error)
                                    return "moduleEdges." + error;
                            }
                        }
                    }
                    if (message.range != null && message.hasOwnProperty("range")) {
                        var error = $root.infinitusai.be.FunctionWithParams.verify(message.range);
                        if (error)
                            return "range." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardModule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardModule} StandardModule
                 */
                StandardModule.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardModule)
                        return object;
                    var message = new $root.infinitusai.be.StandardModule();
                    if (object.id != null)
                        if ($util.Long)
                            (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                        else if (typeof object.id === "string")
                            message.id = parseInt(object.id, 10);
                        else if (typeof object.id === "number")
                            message.id = object.id;
                        else if (typeof object.id === "object")
                            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.nameFunctions) {
                        if (!Array.isArray(object.nameFunctions))
                            throw TypeError(".infinitusai.be.StandardModule.nameFunctions: array expected");
                        message.nameFunctions = [];
                        for (var i = 0; i < object.nameFunctions.length; ++i) {
                            if (typeof object.nameFunctions[i] !== "object")
                                throw TypeError(".infinitusai.be.StandardModule.nameFunctions: object expected");
                            message.nameFunctions[i] = $root.infinitusai.be.FunctionWithParams.fromObject(object.nameFunctions[i]);
                        }
                    }
                    if (object.createdAt != null) {
                        if (typeof object.createdAt !== "object")
                            throw TypeError(".infinitusai.be.StandardModule.createdAt: object expected");
                        message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                    }
                    if (object.updatedAt != null) {
                        if (typeof object.updatedAt !== "object")
                            throw TypeError(".infinitusai.be.StandardModule.updatedAt: object expected");
                        message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
                    }
                    switch (object.startNodeType) {
                    default:
                        if (typeof object.startNodeType === "number") {
                            message.startNodeType = object.startNodeType;
                            break;
                        }
                        break;
                    case "NextNodeTypeEnum_UNKNOWN":
                    case 0:
                        message.startNodeType = 0;
                        break;
                    case "MODULE":
                    case 1:
                        message.startNodeType = 1;
                        break;
                    case "ACTION":
                    case 2:
                        message.startNodeType = 2;
                        break;
                    }
                    if (object.startNodeId != null)
                        if ($util.Long)
                            (message.startNodeId = $util.Long.fromValue(object.startNodeId)).unsigned = false;
                        else if (typeof object.startNodeId === "string")
                            message.startNodeId = parseInt(object.startNodeId, 10);
                        else if (typeof object.startNodeId === "number")
                            message.startNodeId = object.startNodeId;
                        else if (typeof object.startNodeId === "object")
                            message.startNodeId = new $util.LongBits(object.startNodeId.low >>> 0, object.startNodeId.high >>> 0).toNumber();
                    if (object.moduleEdges) {
                        if (typeof object.moduleEdges !== "object")
                            throw TypeError(".infinitusai.be.StandardModule.moduleEdges: object expected");
                        message.moduleEdges = {};
                        for (var keys = Object.keys(object.moduleEdges), i = 0; i < keys.length; ++i) {
                            if (typeof object.moduleEdges[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.StandardModule.moduleEdges: object expected");
                            message.moduleEdges[keys[i]] = $root.infinitusai.be.StandardModuleEdges.fromObject(object.moduleEdges[keys[i]]);
                        }
                    }
                    if (object.range != null) {
                        if (typeof object.range !== "object")
                            throw TypeError(".infinitusai.be.StandardModule.range: object expected");
                        message.range = $root.infinitusai.be.FunctionWithParams.fromObject(object.range);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardModule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {infinitusai.be.StandardModule} message StandardModule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardModule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.nameFunctions = [];
                    if (options.objects || options.defaults)
                        object.moduleEdges = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.id = options.longs === String ? "0" : 0;
                        object.name = "";
                        object.createdAt = null;
                        object.updatedAt = null;
                        object.startNodeType = options.enums === String ? "NextNodeTypeEnum_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startNodeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startNodeId = options.longs === String ? "0" : 0;
                        object.range = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (typeof message.id === "number")
                            object.id = options.longs === String ? String(message.id) : message.id;
                        else
                            object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.nameFunctions && message.nameFunctions.length) {
                        object.nameFunctions = [];
                        for (var j = 0; j < message.nameFunctions.length; ++j)
                            object.nameFunctions[j] = $root.infinitusai.be.FunctionWithParams.toObject(message.nameFunctions[j], options);
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
                    if (message.startNodeType != null && message.hasOwnProperty("startNodeType"))
                        object.startNodeType = options.enums === String ? $root.infinitusai.be.NextNodeTypeEnum[message.startNodeType] === undefined ? message.startNodeType : $root.infinitusai.be.NextNodeTypeEnum[message.startNodeType] : message.startNodeType;
                    if (message.startNodeId != null && message.hasOwnProperty("startNodeId"))
                        if (typeof message.startNodeId === "number")
                            object.startNodeId = options.longs === String ? String(message.startNodeId) : message.startNodeId;
                        else
                            object.startNodeId = options.longs === String ? $util.Long.prototype.toString.call(message.startNodeId) : options.longs === Number ? new $util.LongBits(message.startNodeId.low >>> 0, message.startNodeId.high >>> 0).toNumber() : message.startNodeId;
                    var keys2;
                    if (message.moduleEdges && (keys2 = Object.keys(message.moduleEdges)).length) {
                        object.moduleEdges = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.moduleEdges[keys2[j]] = $root.infinitusai.be.StandardModuleEdges.toObject(message.moduleEdges[keys2[j]], options);
                    }
                    if (message.range != null && message.hasOwnProperty("range"))
                        object.range = $root.infinitusai.be.FunctionWithParams.toObject(message.range, options);
                    return object;
                };
    
                /**
                 * Converts this StandardModule to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardModule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardModule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardModule
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardModule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardModule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardModule";
                };
    
                return StandardModule;
            })();
    
            be.ExpandedModule = (function() {
    
                /**
                 * Properties of an ExpandedModule.
                 * @memberof infinitusai.be
                 * @interface IExpandedModule
                 * @property {number|Long|null} [expandedModuleId] ExpandedModule expandedModuleId
                 * @property {number|Long|null} [baseStandardModuleId] ExpandedModule baseStandardModuleId
                 * @property {infinitusai.be.IStandardModule|null} [baseStandardModule] ExpandedModule baseStandardModule
                 * @property {string|null} [expandedName] ExpandedModule expandedName
                 * @property {number|Long|null} [expandedStartNodeId] ExpandedModule expandedStartNodeId
                 * @property {Object.<string,infinitusai.be.IExpandedModuleEdges>|null} [expandedModuleEdges] ExpandedModule expandedModuleEdges
                 */
    
                /**
                 * Constructs a new ExpandedModule.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedModule.
                 * @implements IExpandedModule
                 * @constructor
                 * @param {infinitusai.be.IExpandedModule=} [properties] Properties to set
                 */
                function ExpandedModule(properties) {
                    this.expandedModuleEdges = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedModule expandedModuleId.
                 * @member {number|Long} expandedModuleId
                 * @memberof infinitusai.be.ExpandedModule
                 * @instance
                 */
                ExpandedModule.prototype.expandedModuleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedModule baseStandardModuleId.
                 * @member {number|Long} baseStandardModuleId
                 * @memberof infinitusai.be.ExpandedModule
                 * @instance
                 */
                ExpandedModule.prototype.baseStandardModuleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedModule baseStandardModule.
                 * @member {infinitusai.be.IStandardModule|null|undefined} baseStandardModule
                 * @memberof infinitusai.be.ExpandedModule
                 * @instance
                 */
                ExpandedModule.prototype.baseStandardModule = null;
    
                /**
                 * ExpandedModule expandedName.
                 * @member {string} expandedName
                 * @memberof infinitusai.be.ExpandedModule
                 * @instance
                 */
                ExpandedModule.prototype.expandedName = "";
    
                /**
                 * ExpandedModule expandedStartNodeId.
                 * @member {number|Long} expandedStartNodeId
                 * @memberof infinitusai.be.ExpandedModule
                 * @instance
                 */
                ExpandedModule.prototype.expandedStartNodeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExpandedModule expandedModuleEdges.
                 * @member {Object.<string,infinitusai.be.IExpandedModuleEdges>} expandedModuleEdges
                 * @memberof infinitusai.be.ExpandedModule
                 * @instance
                 */
                ExpandedModule.prototype.expandedModuleEdges = $util.emptyObject;
    
                /**
                 * Creates a new ExpandedModule instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {infinitusai.be.IExpandedModule=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedModule} ExpandedModule instance
                 */
                ExpandedModule.create = function create(properties) {
                    return new ExpandedModule(properties);
                };
    
                /**
                 * Encodes the specified ExpandedModule message. Does not implicitly {@link infinitusai.be.ExpandedModule.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {infinitusai.be.IExpandedModule} message ExpandedModule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedModule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedModuleId != null && Object.hasOwnProperty.call(message, "expandedModuleId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.expandedModuleId);
                    if (message.baseStandardModuleId != null && Object.hasOwnProperty.call(message, "baseStandardModuleId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.baseStandardModuleId);
                    if (message.baseStandardModule != null && Object.hasOwnProperty.call(message, "baseStandardModule"))
                        $root.infinitusai.be.StandardModule.encode(message.baseStandardModule, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.expandedName != null && Object.hasOwnProperty.call(message, "expandedName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.expandedName);
                    if (message.expandedStartNodeId != null && Object.hasOwnProperty.call(message, "expandedStartNodeId"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.expandedStartNodeId);
                    if (message.expandedModuleEdges != null && Object.hasOwnProperty.call(message, "expandedModuleEdges"))
                        for (var keys = Object.keys(message.expandedModuleEdges), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                            $root.infinitusai.be.ExpandedModuleEdges.encode(message.expandedModuleEdges[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedModule message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedModule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {infinitusai.be.IExpandedModule} message ExpandedModule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedModule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedModule message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedModule} ExpandedModule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedModule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedModule(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.expandedModuleId = reader.int64();
                                break;
                            }
                        case 2: {
                                message.baseStandardModuleId = reader.int64();
                                break;
                            }
                        case 3: {
                                message.baseStandardModule = $root.infinitusai.be.StandardModule.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.expandedName = reader.string();
                                break;
                            }
                        case 5: {
                                message.expandedStartNodeId = reader.int64();
                                break;
                            }
                        case 6: {
                                if (message.expandedModuleEdges === $util.emptyObject)
                                    message.expandedModuleEdges = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = 0;
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.int64();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ExpandedModuleEdges.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.expandedModuleEdges[typeof key === "object" ? $util.longToHash(key) : key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedModule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedModule} ExpandedModule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedModule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedModule message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedModule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedModuleId != null && message.hasOwnProperty("expandedModuleId"))
                        if (!$util.isInteger(message.expandedModuleId) && !(message.expandedModuleId && $util.isInteger(message.expandedModuleId.low) && $util.isInteger(message.expandedModuleId.high)))
                            return "expandedModuleId: integer|Long expected";
                    if (message.baseStandardModuleId != null && message.hasOwnProperty("baseStandardModuleId"))
                        if (!$util.isInteger(message.baseStandardModuleId) && !(message.baseStandardModuleId && $util.isInteger(message.baseStandardModuleId.low) && $util.isInteger(message.baseStandardModuleId.high)))
                            return "baseStandardModuleId: integer|Long expected";
                    if (message.baseStandardModule != null && message.hasOwnProperty("baseStandardModule")) {
                        var error = $root.infinitusai.be.StandardModule.verify(message.baseStandardModule);
                        if (error)
                            return "baseStandardModule." + error;
                    }
                    if (message.expandedName != null && message.hasOwnProperty("expandedName"))
                        if (!$util.isString(message.expandedName))
                            return "expandedName: string expected";
                    if (message.expandedStartNodeId != null && message.hasOwnProperty("expandedStartNodeId"))
                        if (!$util.isInteger(message.expandedStartNodeId) && !(message.expandedStartNodeId && $util.isInteger(message.expandedStartNodeId.low) && $util.isInteger(message.expandedStartNodeId.high)))
                            return "expandedStartNodeId: integer|Long expected";
                    if (message.expandedModuleEdges != null && message.hasOwnProperty("expandedModuleEdges")) {
                        if (!$util.isObject(message.expandedModuleEdges))
                            return "expandedModuleEdges: object expected";
                        var key = Object.keys(message.expandedModuleEdges);
                        for (var i = 0; i < key.length; ++i) {
                            if (!$util.key64Re.test(key[i]))
                                return "expandedModuleEdges: integer|Long key{k:int64} expected";
                            {
                                var error = $root.infinitusai.be.ExpandedModuleEdges.verify(message.expandedModuleEdges[key[i]]);
                                if (error)
                                    return "expandedModuleEdges." + error;
                            }
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedModule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedModule} ExpandedModule
                 */
                ExpandedModule.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedModule)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedModule();
                    if (object.expandedModuleId != null)
                        if ($util.Long)
                            (message.expandedModuleId = $util.Long.fromValue(object.expandedModuleId)).unsigned = false;
                        else if (typeof object.expandedModuleId === "string")
                            message.expandedModuleId = parseInt(object.expandedModuleId, 10);
                        else if (typeof object.expandedModuleId === "number")
                            message.expandedModuleId = object.expandedModuleId;
                        else if (typeof object.expandedModuleId === "object")
                            message.expandedModuleId = new $util.LongBits(object.expandedModuleId.low >>> 0, object.expandedModuleId.high >>> 0).toNumber();
                    if (object.baseStandardModuleId != null)
                        if ($util.Long)
                            (message.baseStandardModuleId = $util.Long.fromValue(object.baseStandardModuleId)).unsigned = false;
                        else if (typeof object.baseStandardModuleId === "string")
                            message.baseStandardModuleId = parseInt(object.baseStandardModuleId, 10);
                        else if (typeof object.baseStandardModuleId === "number")
                            message.baseStandardModuleId = object.baseStandardModuleId;
                        else if (typeof object.baseStandardModuleId === "object")
                            message.baseStandardModuleId = new $util.LongBits(object.baseStandardModuleId.low >>> 0, object.baseStandardModuleId.high >>> 0).toNumber();
                    if (object.baseStandardModule != null) {
                        if (typeof object.baseStandardModule !== "object")
                            throw TypeError(".infinitusai.be.ExpandedModule.baseStandardModule: object expected");
                        message.baseStandardModule = $root.infinitusai.be.StandardModule.fromObject(object.baseStandardModule);
                    }
                    if (object.expandedName != null)
                        message.expandedName = String(object.expandedName);
                    if (object.expandedStartNodeId != null)
                        if ($util.Long)
                            (message.expandedStartNodeId = $util.Long.fromValue(object.expandedStartNodeId)).unsigned = false;
                        else if (typeof object.expandedStartNodeId === "string")
                            message.expandedStartNodeId = parseInt(object.expandedStartNodeId, 10);
                        else if (typeof object.expandedStartNodeId === "number")
                            message.expandedStartNodeId = object.expandedStartNodeId;
                        else if (typeof object.expandedStartNodeId === "object")
                            message.expandedStartNodeId = new $util.LongBits(object.expandedStartNodeId.low >>> 0, object.expandedStartNodeId.high >>> 0).toNumber();
                    if (object.expandedModuleEdges) {
                        if (typeof object.expandedModuleEdges !== "object")
                            throw TypeError(".infinitusai.be.ExpandedModule.expandedModuleEdges: object expected");
                        message.expandedModuleEdges = {};
                        for (var keys = Object.keys(object.expandedModuleEdges), i = 0; i < keys.length; ++i) {
                            if (typeof object.expandedModuleEdges[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.ExpandedModule.expandedModuleEdges: object expected");
                            message.expandedModuleEdges[keys[i]] = $root.infinitusai.be.ExpandedModuleEdges.fromObject(object.expandedModuleEdges[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedModule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {infinitusai.be.ExpandedModule} message ExpandedModule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedModule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.expandedModuleEdges = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedModuleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedModuleId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.baseStandardModuleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.baseStandardModuleId = options.longs === String ? "0" : 0;
                        object.baseStandardModule = null;
                        object.expandedName = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedStartNodeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedStartNodeId = options.longs === String ? "0" : 0;
                    }
                    if (message.expandedModuleId != null && message.hasOwnProperty("expandedModuleId"))
                        if (typeof message.expandedModuleId === "number")
                            object.expandedModuleId = options.longs === String ? String(message.expandedModuleId) : message.expandedModuleId;
                        else
                            object.expandedModuleId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedModuleId) : options.longs === Number ? new $util.LongBits(message.expandedModuleId.low >>> 0, message.expandedModuleId.high >>> 0).toNumber() : message.expandedModuleId;
                    if (message.baseStandardModuleId != null && message.hasOwnProperty("baseStandardModuleId"))
                        if (typeof message.baseStandardModuleId === "number")
                            object.baseStandardModuleId = options.longs === String ? String(message.baseStandardModuleId) : message.baseStandardModuleId;
                        else
                            object.baseStandardModuleId = options.longs === String ? $util.Long.prototype.toString.call(message.baseStandardModuleId) : options.longs === Number ? new $util.LongBits(message.baseStandardModuleId.low >>> 0, message.baseStandardModuleId.high >>> 0).toNumber() : message.baseStandardModuleId;
                    if (message.baseStandardModule != null && message.hasOwnProperty("baseStandardModule"))
                        object.baseStandardModule = $root.infinitusai.be.StandardModule.toObject(message.baseStandardModule, options);
                    if (message.expandedName != null && message.hasOwnProperty("expandedName"))
                        object.expandedName = message.expandedName;
                    if (message.expandedStartNodeId != null && message.hasOwnProperty("expandedStartNodeId"))
                        if (typeof message.expandedStartNodeId === "number")
                            object.expandedStartNodeId = options.longs === String ? String(message.expandedStartNodeId) : message.expandedStartNodeId;
                        else
                            object.expandedStartNodeId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedStartNodeId) : options.longs === Number ? new $util.LongBits(message.expandedStartNodeId.low >>> 0, message.expandedStartNodeId.high >>> 0).toNumber() : message.expandedStartNodeId;
                    var keys2;
                    if (message.expandedModuleEdges && (keys2 = Object.keys(message.expandedModuleEdges)).length) {
                        object.expandedModuleEdges = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.expandedModuleEdges[keys2[j]] = $root.infinitusai.be.ExpandedModuleEdges.toObject(message.expandedModuleEdges[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ExpandedModule to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedModule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedModule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedModule
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedModule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedModule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedModule";
                };
    
                return ExpandedModule;
            })();
    
            /**
             * NextNodeTypeEnum enum.
             * @name infinitusai.be.NextNodeTypeEnum
             * @enum {number}
             * @property {number} NextNodeTypeEnum_UNKNOWN=0 NextNodeTypeEnum_UNKNOWN value
             * @property {number} MODULE=1 MODULE value
             * @property {number} ACTION=2 ACTION value
             */
            be.NextNodeTypeEnum = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NextNodeTypeEnum_UNKNOWN"] = 0;
                values[valuesById[1] = "MODULE"] = 1;
                values[valuesById[2] = "ACTION"] = 2;
                return values;
            })();
    
            be.StandardModuleEdges = (function() {
    
                /**
                 * Properties of a StandardModuleEdges.
                 * @memberof infinitusai.be
                 * @interface IStandardModuleEdges
                 * @property {Array.<infinitusai.be.IStandardModuleEdge>|null} [edges] StandardModuleEdges edges
                 */
    
                /**
                 * Constructs a new StandardModuleEdges.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardModuleEdges.
                 * @implements IStandardModuleEdges
                 * @constructor
                 * @param {infinitusai.be.IStandardModuleEdges=} [properties] Properties to set
                 */
                function StandardModuleEdges(properties) {
                    this.edges = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardModuleEdges edges.
                 * @member {Array.<infinitusai.be.IStandardModuleEdge>} edges
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @instance
                 */
                StandardModuleEdges.prototype.edges = $util.emptyArray;
    
                /**
                 * Creates a new StandardModuleEdges instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {infinitusai.be.IStandardModuleEdges=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardModuleEdges} StandardModuleEdges instance
                 */
                StandardModuleEdges.create = function create(properties) {
                    return new StandardModuleEdges(properties);
                };
    
                /**
                 * Encodes the specified StandardModuleEdges message. Does not implicitly {@link infinitusai.be.StandardModuleEdges.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {infinitusai.be.IStandardModuleEdges} message StandardModuleEdges message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardModuleEdges.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.edges != null && message.edges.length)
                        for (var i = 0; i < message.edges.length; ++i)
                            $root.infinitusai.be.StandardModuleEdge.encode(message.edges[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardModuleEdges message, length delimited. Does not implicitly {@link infinitusai.be.StandardModuleEdges.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {infinitusai.be.IStandardModuleEdges} message StandardModuleEdges message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardModuleEdges.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardModuleEdges message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardModuleEdges} StandardModuleEdges
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardModuleEdges.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardModuleEdges();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.edges && message.edges.length))
                                    message.edges = [];
                                message.edges.push($root.infinitusai.be.StandardModuleEdge.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardModuleEdges message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardModuleEdges} StandardModuleEdges
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardModuleEdges.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardModuleEdges message.
                 * @function verify
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardModuleEdges.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.edges != null && message.hasOwnProperty("edges")) {
                        if (!Array.isArray(message.edges))
                            return "edges: array expected";
                        for (var i = 0; i < message.edges.length; ++i) {
                            var error = $root.infinitusai.be.StandardModuleEdge.verify(message.edges[i]);
                            if (error)
                                return "edges." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardModuleEdges message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardModuleEdges} StandardModuleEdges
                 */
                StandardModuleEdges.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardModuleEdges)
                        return object;
                    var message = new $root.infinitusai.be.StandardModuleEdges();
                    if (object.edges) {
                        if (!Array.isArray(object.edges))
                            throw TypeError(".infinitusai.be.StandardModuleEdges.edges: array expected");
                        message.edges = [];
                        for (var i = 0; i < object.edges.length; ++i) {
                            if (typeof object.edges[i] !== "object")
                                throw TypeError(".infinitusai.be.StandardModuleEdges.edges: object expected");
                            message.edges[i] = $root.infinitusai.be.StandardModuleEdge.fromObject(object.edges[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardModuleEdges message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {infinitusai.be.StandardModuleEdges} message StandardModuleEdges
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardModuleEdges.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.edges = [];
                    if (message.edges && message.edges.length) {
                        object.edges = [];
                        for (var j = 0; j < message.edges.length; ++j)
                            object.edges[j] = $root.infinitusai.be.StandardModuleEdge.toObject(message.edges[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this StandardModuleEdges to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardModuleEdges.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardModuleEdges
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardModuleEdges
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardModuleEdges.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardModuleEdges";
                };
    
                return StandardModuleEdges;
            })();
    
            be.StandardModuleEdge = (function() {
    
                /**
                 * Properties of a StandardModuleEdge.
                 * @memberof infinitusai.be
                 * @interface IStandardModuleEdge
                 * @property {number|Long|null} [childNode] StandardModuleEdge childNode
                 * @property {infinitusai.be.NextNodeTypeEnum|null} [childNodeType] StandardModuleEdge childNodeType
                 * @property {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions|null} [conditions] StandardModuleEdge conditions
                 */
    
                /**
                 * Constructs a new StandardModuleEdge.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardModuleEdge.
                 * @implements IStandardModuleEdge
                 * @constructor
                 * @param {infinitusai.be.IStandardModuleEdge=} [properties] Properties to set
                 */
                function StandardModuleEdge(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardModuleEdge childNode.
                 * @member {number|Long} childNode
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @instance
                 */
                StandardModuleEdge.prototype.childNode = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardModuleEdge childNodeType.
                 * @member {infinitusai.be.NextNodeTypeEnum} childNodeType
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @instance
                 */
                StandardModuleEdge.prototype.childNodeType = 0;
    
                /**
                 * StandardModuleEdge conditions.
                 * @member {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions|null|undefined} conditions
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @instance
                 */
                StandardModuleEdge.prototype.conditions = null;
    
                /**
                 * Creates a new StandardModuleEdge instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {infinitusai.be.IStandardModuleEdge=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardModuleEdge} StandardModuleEdge instance
                 */
                StandardModuleEdge.create = function create(properties) {
                    return new StandardModuleEdge(properties);
                };
    
                /**
                 * Encodes the specified StandardModuleEdge message. Does not implicitly {@link infinitusai.be.StandardModuleEdge.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {infinitusai.be.IStandardModuleEdge} message StandardModuleEdge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardModuleEdge.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.childNode != null && Object.hasOwnProperty.call(message, "childNode"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.childNode);
                    if (message.childNodeType != null && Object.hasOwnProperty.call(message, "childNodeType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.childNodeType);
                    if (message.conditions != null && Object.hasOwnProperty.call(message, "conditions"))
                        $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.encode(message.conditions, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardModuleEdge message, length delimited. Does not implicitly {@link infinitusai.be.StandardModuleEdge.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {infinitusai.be.IStandardModuleEdge} message StandardModuleEdge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardModuleEdge.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardModuleEdge message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardModuleEdge} StandardModuleEdge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardModuleEdge.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardModuleEdge();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.childNode = reader.int64();
                                break;
                            }
                        case 2: {
                                message.childNodeType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.conditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardModuleEdge message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardModuleEdge} StandardModuleEdge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardModuleEdge.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardModuleEdge message.
                 * @function verify
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardModuleEdge.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.childNode != null && message.hasOwnProperty("childNode"))
                        if (!$util.isInteger(message.childNode) && !(message.childNode && $util.isInteger(message.childNode.low) && $util.isInteger(message.childNode.high)))
                            return "childNode: integer|Long expected";
                    if (message.childNodeType != null && message.hasOwnProperty("childNodeType"))
                        switch (message.childNodeType) {
                        default:
                            return "childNodeType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.conditions != null && message.hasOwnProperty("conditions")) {
                        var error = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.verify(message.conditions);
                        if (error)
                            return "conditions." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardModuleEdge message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardModuleEdge} StandardModuleEdge
                 */
                StandardModuleEdge.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardModuleEdge)
                        return object;
                    var message = new $root.infinitusai.be.StandardModuleEdge();
                    if (object.childNode != null)
                        if ($util.Long)
                            (message.childNode = $util.Long.fromValue(object.childNode)).unsigned = false;
                        else if (typeof object.childNode === "string")
                            message.childNode = parseInt(object.childNode, 10);
                        else if (typeof object.childNode === "number")
                            message.childNode = object.childNode;
                        else if (typeof object.childNode === "object")
                            message.childNode = new $util.LongBits(object.childNode.low >>> 0, object.childNode.high >>> 0).toNumber();
                    switch (object.childNodeType) {
                    default:
                        if (typeof object.childNodeType === "number") {
                            message.childNodeType = object.childNodeType;
                            break;
                        }
                        break;
                    case "NextNodeTypeEnum_UNKNOWN":
                    case 0:
                        message.childNodeType = 0;
                        break;
                    case "MODULE":
                    case 1:
                        message.childNodeType = 1;
                        break;
                    case "ACTION":
                    case 2:
                        message.childNodeType = 2;
                        break;
                    }
                    if (object.conditions != null) {
                        if (typeof object.conditions !== "object")
                            throw TypeError(".infinitusai.be.StandardModuleEdge.conditions: object expected");
                        message.conditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.fromObject(object.conditions);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardModuleEdge message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {infinitusai.be.StandardModuleEdge} message StandardModuleEdge
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardModuleEdge.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.childNode = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.childNode = options.longs === String ? "0" : 0;
                        object.childNodeType = options.enums === String ? "NextNodeTypeEnum_UNKNOWN" : 0;
                        object.conditions = null;
                    }
                    if (message.childNode != null && message.hasOwnProperty("childNode"))
                        if (typeof message.childNode === "number")
                            object.childNode = options.longs === String ? String(message.childNode) : message.childNode;
                        else
                            object.childNode = options.longs === String ? $util.Long.prototype.toString.call(message.childNode) : options.longs === Number ? new $util.LongBits(message.childNode.low >>> 0, message.childNode.high >>> 0).toNumber() : message.childNode;
                    if (message.childNodeType != null && message.hasOwnProperty("childNodeType"))
                        object.childNodeType = options.enums === String ? $root.infinitusai.be.NextNodeTypeEnum[message.childNodeType] === undefined ? message.childNodeType : $root.infinitusai.be.NextNodeTypeEnum[message.childNodeType] : message.childNodeType;
                    if (message.conditions != null && message.hasOwnProperty("conditions"))
                        object.conditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.toObject(message.conditions, options);
                    return object;
                };
    
                /**
                 * Converts this StandardModuleEdge to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardModuleEdge.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardModuleEdge
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardModuleEdge
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardModuleEdge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardModuleEdge";
                };
    
                return StandardModuleEdge;
            })();
    
            be.ExpandedModuleEdges = (function() {
    
                /**
                 * Properties of an ExpandedModuleEdges.
                 * @memberof infinitusai.be
                 * @interface IExpandedModuleEdges
                 * @property {Array.<infinitusai.be.IExpandedModuleEdge>|null} [edges] ExpandedModuleEdges edges
                 * @property {infinitusai.be.IStandardModuleEdges|null} [baseStandardModuleEdges] ExpandedModuleEdges baseStandardModuleEdges
                 */
    
                /**
                 * Constructs a new ExpandedModuleEdges.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedModuleEdges.
                 * @implements IExpandedModuleEdges
                 * @constructor
                 * @param {infinitusai.be.IExpandedModuleEdges=} [properties] Properties to set
                 */
                function ExpandedModuleEdges(properties) {
                    this.edges = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedModuleEdges edges.
                 * @member {Array.<infinitusai.be.IExpandedModuleEdge>} edges
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @instance
                 */
                ExpandedModuleEdges.prototype.edges = $util.emptyArray;
    
                /**
                 * ExpandedModuleEdges baseStandardModuleEdges.
                 * @member {infinitusai.be.IStandardModuleEdges|null|undefined} baseStandardModuleEdges
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @instance
                 */
                ExpandedModuleEdges.prototype.baseStandardModuleEdges = null;
    
                /**
                 * Creates a new ExpandedModuleEdges instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {infinitusai.be.IExpandedModuleEdges=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedModuleEdges} ExpandedModuleEdges instance
                 */
                ExpandedModuleEdges.create = function create(properties) {
                    return new ExpandedModuleEdges(properties);
                };
    
                /**
                 * Encodes the specified ExpandedModuleEdges message. Does not implicitly {@link infinitusai.be.ExpandedModuleEdges.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {infinitusai.be.IExpandedModuleEdges} message ExpandedModuleEdges message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedModuleEdges.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.edges != null && message.edges.length)
                        for (var i = 0; i < message.edges.length; ++i)
                            $root.infinitusai.be.ExpandedModuleEdge.encode(message.edges[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.baseStandardModuleEdges != null && Object.hasOwnProperty.call(message, "baseStandardModuleEdges"))
                        $root.infinitusai.be.StandardModuleEdges.encode(message.baseStandardModuleEdges, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedModuleEdges message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedModuleEdges.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {infinitusai.be.IExpandedModuleEdges} message ExpandedModuleEdges message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedModuleEdges.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedModuleEdges message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedModuleEdges} ExpandedModuleEdges
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedModuleEdges.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedModuleEdges();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.edges && message.edges.length))
                                    message.edges = [];
                                message.edges.push($root.infinitusai.be.ExpandedModuleEdge.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.baseStandardModuleEdges = $root.infinitusai.be.StandardModuleEdges.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedModuleEdges message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedModuleEdges} ExpandedModuleEdges
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedModuleEdges.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedModuleEdges message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedModuleEdges.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.edges != null && message.hasOwnProperty("edges")) {
                        if (!Array.isArray(message.edges))
                            return "edges: array expected";
                        for (var i = 0; i < message.edges.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedModuleEdge.verify(message.edges[i]);
                            if (error)
                                return "edges." + error;
                        }
                    }
                    if (message.baseStandardModuleEdges != null && message.hasOwnProperty("baseStandardModuleEdges")) {
                        var error = $root.infinitusai.be.StandardModuleEdges.verify(message.baseStandardModuleEdges);
                        if (error)
                            return "baseStandardModuleEdges." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedModuleEdges message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedModuleEdges} ExpandedModuleEdges
                 */
                ExpandedModuleEdges.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedModuleEdges)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedModuleEdges();
                    if (object.edges) {
                        if (!Array.isArray(object.edges))
                            throw TypeError(".infinitusai.be.ExpandedModuleEdges.edges: array expected");
                        message.edges = [];
                        for (var i = 0; i < object.edges.length; ++i) {
                            if (typeof object.edges[i] !== "object")
                                throw TypeError(".infinitusai.be.ExpandedModuleEdges.edges: object expected");
                            message.edges[i] = $root.infinitusai.be.ExpandedModuleEdge.fromObject(object.edges[i]);
                        }
                    }
                    if (object.baseStandardModuleEdges != null) {
                        if (typeof object.baseStandardModuleEdges !== "object")
                            throw TypeError(".infinitusai.be.ExpandedModuleEdges.baseStandardModuleEdges: object expected");
                        message.baseStandardModuleEdges = $root.infinitusai.be.StandardModuleEdges.fromObject(object.baseStandardModuleEdges);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedModuleEdges message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {infinitusai.be.ExpandedModuleEdges} message ExpandedModuleEdges
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedModuleEdges.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.edges = [];
                    if (options.defaults)
                        object.baseStandardModuleEdges = null;
                    if (message.edges && message.edges.length) {
                        object.edges = [];
                        for (var j = 0; j < message.edges.length; ++j)
                            object.edges[j] = $root.infinitusai.be.ExpandedModuleEdge.toObject(message.edges[j], options);
                    }
                    if (message.baseStandardModuleEdges != null && message.hasOwnProperty("baseStandardModuleEdges"))
                        object.baseStandardModuleEdges = $root.infinitusai.be.StandardModuleEdges.toObject(message.baseStandardModuleEdges, options);
                    return object;
                };
    
                /**
                 * Converts this ExpandedModuleEdges to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedModuleEdges.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedModuleEdges
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedModuleEdges
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedModuleEdges.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedModuleEdges";
                };
    
                return ExpandedModuleEdges;
            })();
    
            be.ExpandedModuleEdge = (function() {
    
                /**
                 * Properties of an ExpandedModuleEdge.
                 * @memberof infinitusai.be
                 * @interface IExpandedModuleEdge
                 * @property {Array.<number|Long>|null} [childrenNodes] ExpandedModuleEdge childrenNodes
                 * @property {infinitusai.be.NextNodeTypeEnum|null} [childrenNodeType] ExpandedModuleEdge childrenNodeType
                 * @property {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions|null} [conditions] ExpandedModuleEdge conditions
                 * @property {infinitusai.be.IStandardModuleEdge|null} [baseStandardModuleEdge] ExpandedModuleEdge baseStandardModuleEdge
                 */
    
                /**
                 * Constructs a new ExpandedModuleEdge.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedModuleEdge.
                 * @implements IExpandedModuleEdge
                 * @constructor
                 * @param {infinitusai.be.IExpandedModuleEdge=} [properties] Properties to set
                 */
                function ExpandedModuleEdge(properties) {
                    this.childrenNodes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedModuleEdge childrenNodes.
                 * @member {Array.<number|Long>} childrenNodes
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @instance
                 */
                ExpandedModuleEdge.prototype.childrenNodes = $util.emptyArray;
    
                /**
                 * ExpandedModuleEdge childrenNodeType.
                 * @member {infinitusai.be.NextNodeTypeEnum} childrenNodeType
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @instance
                 */
                ExpandedModuleEdge.prototype.childrenNodeType = 0;
    
                /**
                 * ExpandedModuleEdge conditions.
                 * @member {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions|null|undefined} conditions
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @instance
                 */
                ExpandedModuleEdge.prototype.conditions = null;
    
                /**
                 * ExpandedModuleEdge baseStandardModuleEdge.
                 * @member {infinitusai.be.IStandardModuleEdge|null|undefined} baseStandardModuleEdge
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @instance
                 */
                ExpandedModuleEdge.prototype.baseStandardModuleEdge = null;
    
                /**
                 * Creates a new ExpandedModuleEdge instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {infinitusai.be.IExpandedModuleEdge=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedModuleEdge} ExpandedModuleEdge instance
                 */
                ExpandedModuleEdge.create = function create(properties) {
                    return new ExpandedModuleEdge(properties);
                };
    
                /**
                 * Encodes the specified ExpandedModuleEdge message. Does not implicitly {@link infinitusai.be.ExpandedModuleEdge.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {infinitusai.be.IExpandedModuleEdge} message ExpandedModuleEdge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedModuleEdge.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.childrenNodes != null && message.childrenNodes.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.childrenNodes.length; ++i)
                            writer.int64(message.childrenNodes[i]);
                        writer.ldelim();
                    }
                    if (message.childrenNodeType != null && Object.hasOwnProperty.call(message, "childrenNodeType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.childrenNodeType);
                    if (message.conditions != null && Object.hasOwnProperty.call(message, "conditions"))
                        $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.encode(message.conditions, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.baseStandardModuleEdge != null && Object.hasOwnProperty.call(message, "baseStandardModuleEdge"))
                        $root.infinitusai.be.StandardModuleEdge.encode(message.baseStandardModuleEdge, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedModuleEdge message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedModuleEdge.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {infinitusai.be.IExpandedModuleEdge} message ExpandedModuleEdge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedModuleEdge.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedModuleEdge message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedModuleEdge} ExpandedModuleEdge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedModuleEdge.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedModuleEdge();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.childrenNodes && message.childrenNodes.length))
                                    message.childrenNodes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.childrenNodes.push(reader.int64());
                                } else
                                    message.childrenNodes.push(reader.int64());
                                break;
                            }
                        case 2: {
                                message.childrenNodeType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.conditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.baseStandardModuleEdge = $root.infinitusai.be.StandardModuleEdge.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedModuleEdge message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedModuleEdge} ExpandedModuleEdge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedModuleEdge.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedModuleEdge message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedModuleEdge.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.childrenNodes != null && message.hasOwnProperty("childrenNodes")) {
                        if (!Array.isArray(message.childrenNodes))
                            return "childrenNodes: array expected";
                        for (var i = 0; i < message.childrenNodes.length; ++i)
                            if (!$util.isInteger(message.childrenNodes[i]) && !(message.childrenNodes[i] && $util.isInteger(message.childrenNodes[i].low) && $util.isInteger(message.childrenNodes[i].high)))
                                return "childrenNodes: integer|Long[] expected";
                    }
                    if (message.childrenNodeType != null && message.hasOwnProperty("childrenNodeType"))
                        switch (message.childrenNodeType) {
                        default:
                            return "childrenNodeType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.conditions != null && message.hasOwnProperty("conditions")) {
                        var error = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.verify(message.conditions);
                        if (error)
                            return "conditions." + error;
                    }
                    if (message.baseStandardModuleEdge != null && message.hasOwnProperty("baseStandardModuleEdge")) {
                        var error = $root.infinitusai.be.StandardModuleEdge.verify(message.baseStandardModuleEdge);
                        if (error)
                            return "baseStandardModuleEdge." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedModuleEdge message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedModuleEdge} ExpandedModuleEdge
                 */
                ExpandedModuleEdge.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedModuleEdge)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedModuleEdge();
                    if (object.childrenNodes) {
                        if (!Array.isArray(object.childrenNodes))
                            throw TypeError(".infinitusai.be.ExpandedModuleEdge.childrenNodes: array expected");
                        message.childrenNodes = [];
                        for (var i = 0; i < object.childrenNodes.length; ++i)
                            if ($util.Long)
                                (message.childrenNodes[i] = $util.Long.fromValue(object.childrenNodes[i])).unsigned = false;
                            else if (typeof object.childrenNodes[i] === "string")
                                message.childrenNodes[i] = parseInt(object.childrenNodes[i], 10);
                            else if (typeof object.childrenNodes[i] === "number")
                                message.childrenNodes[i] = object.childrenNodes[i];
                            else if (typeof object.childrenNodes[i] === "object")
                                message.childrenNodes[i] = new $util.LongBits(object.childrenNodes[i].low >>> 0, object.childrenNodes[i].high >>> 0).toNumber();
                    }
                    switch (object.childrenNodeType) {
                    default:
                        if (typeof object.childrenNodeType === "number") {
                            message.childrenNodeType = object.childrenNodeType;
                            break;
                        }
                        break;
                    case "NextNodeTypeEnum_UNKNOWN":
                    case 0:
                        message.childrenNodeType = 0;
                        break;
                    case "MODULE":
                    case 1:
                        message.childrenNodeType = 1;
                        break;
                    case "ACTION":
                    case 2:
                        message.childrenNodeType = 2;
                        break;
                    }
                    if (object.conditions != null) {
                        if (typeof object.conditions !== "object")
                            throw TypeError(".infinitusai.be.ExpandedModuleEdge.conditions: object expected");
                        message.conditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.fromObject(object.conditions);
                    }
                    if (object.baseStandardModuleEdge != null) {
                        if (typeof object.baseStandardModuleEdge !== "object")
                            throw TypeError(".infinitusai.be.ExpandedModuleEdge.baseStandardModuleEdge: object expected");
                        message.baseStandardModuleEdge = $root.infinitusai.be.StandardModuleEdge.fromObject(object.baseStandardModuleEdge);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedModuleEdge message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {infinitusai.be.ExpandedModuleEdge} message ExpandedModuleEdge
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedModuleEdge.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.childrenNodes = [];
                    if (options.defaults) {
                        object.childrenNodeType = options.enums === String ? "NextNodeTypeEnum_UNKNOWN" : 0;
                        object.conditions = null;
                        object.baseStandardModuleEdge = null;
                    }
                    if (message.childrenNodes && message.childrenNodes.length) {
                        object.childrenNodes = [];
                        for (var j = 0; j < message.childrenNodes.length; ++j)
                            if (typeof message.childrenNodes[j] === "number")
                                object.childrenNodes[j] = options.longs === String ? String(message.childrenNodes[j]) : message.childrenNodes[j];
                            else
                                object.childrenNodes[j] = options.longs === String ? $util.Long.prototype.toString.call(message.childrenNodes[j]) : options.longs === Number ? new $util.LongBits(message.childrenNodes[j].low >>> 0, message.childrenNodes[j].high >>> 0).toNumber() : message.childrenNodes[j];
                    }
                    if (message.childrenNodeType != null && message.hasOwnProperty("childrenNodeType"))
                        object.childrenNodeType = options.enums === String ? $root.infinitusai.be.NextNodeTypeEnum[message.childrenNodeType] === undefined ? message.childrenNodeType : $root.infinitusai.be.NextNodeTypeEnum[message.childrenNodeType] : message.childrenNodeType;
                    if (message.conditions != null && message.hasOwnProperty("conditions"))
                        object.conditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.toObject(message.conditions, options);
                    if (message.baseStandardModuleEdge != null && message.hasOwnProperty("baseStandardModuleEdge"))
                        object.baseStandardModuleEdge = $root.infinitusai.be.StandardModuleEdge.toObject(message.baseStandardModuleEdge, options);
                    return object;
                };
    
                /**
                 * Converts this ExpandedModuleEdge to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedModuleEdge.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedModuleEdge
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedModuleEdge
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedModuleEdge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedModuleEdge";
                };
    
                return ExpandedModuleEdge;
            })();
    
            be.StandardDisjunctionOfConjunctionsOfStandardConditions = (function() {
    
                /**
                 * Properties of a StandardDisjunctionOfConjunctionsOfStandardConditions.
                 * @memberof infinitusai.be
                 * @interface IStandardDisjunctionOfConjunctionsOfStandardConditions
                 * @property {Array.<infinitusai.be.IStandardConjunctionOfStandardConditions>|null} [conjunctions] StandardDisjunctionOfConjunctionsOfStandardConditions conjunctions
                 */
    
                /**
                 * Constructs a new StandardDisjunctionOfConjunctionsOfStandardConditions.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardDisjunctionOfConjunctionsOfStandardConditions.
                 * @implements IStandardDisjunctionOfConjunctionsOfStandardConditions
                 * @constructor
                 * @param {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions=} [properties] Properties to set
                 */
                function StandardDisjunctionOfConjunctionsOfStandardConditions(properties) {
                    this.conjunctions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardDisjunctionOfConjunctionsOfStandardConditions conjunctions.
                 * @member {Array.<infinitusai.be.IStandardConjunctionOfStandardConditions>} conjunctions
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @instance
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.prototype.conjunctions = $util.emptyArray;
    
                /**
                 * Creates a new StandardDisjunctionOfConjunctionsOfStandardConditions instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions} StandardDisjunctionOfConjunctionsOfStandardConditions instance
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.create = function create(properties) {
                    return new StandardDisjunctionOfConjunctionsOfStandardConditions(properties);
                };
    
                /**
                 * Encodes the specified StandardDisjunctionOfConjunctionsOfStandardConditions message. Does not implicitly {@link infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions} message StandardDisjunctionOfConjunctionsOfStandardConditions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conjunctions != null && message.conjunctions.length)
                        for (var i = 0; i < message.conjunctions.length; ++i)
                            $root.infinitusai.be.StandardConjunctionOfStandardConditions.encode(message.conjunctions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardDisjunctionOfConjunctionsOfStandardConditions message, length delimited. Does not implicitly {@link infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions} message StandardDisjunctionOfConjunctionsOfStandardConditions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardDisjunctionOfConjunctionsOfStandardConditions message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions} StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.conjunctions && message.conjunctions.length))
                                    message.conjunctions = [];
                                message.conjunctions.push($root.infinitusai.be.StandardConjunctionOfStandardConditions.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardDisjunctionOfConjunctionsOfStandardConditions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions} StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardDisjunctionOfConjunctionsOfStandardConditions message.
                 * @function verify
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conjunctions != null && message.hasOwnProperty("conjunctions")) {
                        if (!Array.isArray(message.conjunctions))
                            return "conjunctions: array expected";
                        for (var i = 0; i < message.conjunctions.length; ++i) {
                            var error = $root.infinitusai.be.StandardConjunctionOfStandardConditions.verify(message.conjunctions[i]);
                            if (error)
                                return "conjunctions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardDisjunctionOfConjunctionsOfStandardConditions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions} StandardDisjunctionOfConjunctionsOfStandardConditions
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions)
                        return object;
                    var message = new $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions();
                    if (object.conjunctions) {
                        if (!Array.isArray(object.conjunctions))
                            throw TypeError(".infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.conjunctions: array expected");
                        message.conjunctions = [];
                        for (var i = 0; i < object.conjunctions.length; ++i) {
                            if (typeof object.conjunctions[i] !== "object")
                                throw TypeError(".infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.conjunctions: object expected");
                            message.conjunctions[i] = $root.infinitusai.be.StandardConjunctionOfStandardConditions.fromObject(object.conjunctions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardDisjunctionOfConjunctionsOfStandardConditions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions} message StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.conjunctions = [];
                    if (message.conjunctions && message.conjunctions.length) {
                        object.conjunctions = [];
                        for (var j = 0; j < message.conjunctions.length; ++j)
                            object.conjunctions[j] = $root.infinitusai.be.StandardConjunctionOfStandardConditions.toObject(message.conjunctions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this StandardDisjunctionOfConjunctionsOfStandardConditions to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardDisjunctionOfConjunctionsOfStandardConditions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions";
                };
    
                return StandardDisjunctionOfConjunctionsOfStandardConditions;
            })();
    
            be.StandardConjunctionOfStandardConditions = (function() {
    
                /**
                 * Properties of a StandardConjunctionOfStandardConditions.
                 * @memberof infinitusai.be
                 * @interface IStandardConjunctionOfStandardConditions
                 * @property {Array.<number|Long>|null} [standardConditionIds] StandardConjunctionOfStandardConditions standardConditionIds
                 */
    
                /**
                 * Constructs a new StandardConjunctionOfStandardConditions.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardConjunctionOfStandardConditions.
                 * @implements IStandardConjunctionOfStandardConditions
                 * @constructor
                 * @param {infinitusai.be.IStandardConjunctionOfStandardConditions=} [properties] Properties to set
                 */
                function StandardConjunctionOfStandardConditions(properties) {
                    this.standardConditionIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardConjunctionOfStandardConditions standardConditionIds.
                 * @member {Array.<number|Long>} standardConditionIds
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @instance
                 */
                StandardConjunctionOfStandardConditions.prototype.standardConditionIds = $util.emptyArray;
    
                /**
                 * Creates a new StandardConjunctionOfStandardConditions instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {infinitusai.be.IStandardConjunctionOfStandardConditions=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardConjunctionOfStandardConditions} StandardConjunctionOfStandardConditions instance
                 */
                StandardConjunctionOfStandardConditions.create = function create(properties) {
                    return new StandardConjunctionOfStandardConditions(properties);
                };
    
                /**
                 * Encodes the specified StandardConjunctionOfStandardConditions message. Does not implicitly {@link infinitusai.be.StandardConjunctionOfStandardConditions.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {infinitusai.be.IStandardConjunctionOfStandardConditions} message StandardConjunctionOfStandardConditions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardConjunctionOfStandardConditions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardConditionIds != null && message.standardConditionIds.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.standardConditionIds.length; ++i)
                            writer.int64(message.standardConditionIds[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardConjunctionOfStandardConditions message, length delimited. Does not implicitly {@link infinitusai.be.StandardConjunctionOfStandardConditions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {infinitusai.be.IStandardConjunctionOfStandardConditions} message StandardConjunctionOfStandardConditions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardConjunctionOfStandardConditions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardConjunctionOfStandardConditions message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardConjunctionOfStandardConditions} StandardConjunctionOfStandardConditions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardConjunctionOfStandardConditions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardConjunctionOfStandardConditions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.standardConditionIds && message.standardConditionIds.length))
                                    message.standardConditionIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.standardConditionIds.push(reader.int64());
                                } else
                                    message.standardConditionIds.push(reader.int64());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardConjunctionOfStandardConditions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardConjunctionOfStandardConditions} StandardConjunctionOfStandardConditions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardConjunctionOfStandardConditions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardConjunctionOfStandardConditions message.
                 * @function verify
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardConjunctionOfStandardConditions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardConditionIds != null && message.hasOwnProperty("standardConditionIds")) {
                        if (!Array.isArray(message.standardConditionIds))
                            return "standardConditionIds: array expected";
                        for (var i = 0; i < message.standardConditionIds.length; ++i)
                            if (!$util.isInteger(message.standardConditionIds[i]) && !(message.standardConditionIds[i] && $util.isInteger(message.standardConditionIds[i].low) && $util.isInteger(message.standardConditionIds[i].high)))
                                return "standardConditionIds: integer|Long[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardConjunctionOfStandardConditions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardConjunctionOfStandardConditions} StandardConjunctionOfStandardConditions
                 */
                StandardConjunctionOfStandardConditions.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardConjunctionOfStandardConditions)
                        return object;
                    var message = new $root.infinitusai.be.StandardConjunctionOfStandardConditions();
                    if (object.standardConditionIds) {
                        if (!Array.isArray(object.standardConditionIds))
                            throw TypeError(".infinitusai.be.StandardConjunctionOfStandardConditions.standardConditionIds: array expected");
                        message.standardConditionIds = [];
                        for (var i = 0; i < object.standardConditionIds.length; ++i)
                            if ($util.Long)
                                (message.standardConditionIds[i] = $util.Long.fromValue(object.standardConditionIds[i])).unsigned = false;
                            else if (typeof object.standardConditionIds[i] === "string")
                                message.standardConditionIds[i] = parseInt(object.standardConditionIds[i], 10);
                            else if (typeof object.standardConditionIds[i] === "number")
                                message.standardConditionIds[i] = object.standardConditionIds[i];
                            else if (typeof object.standardConditionIds[i] === "object")
                                message.standardConditionIds[i] = new $util.LongBits(object.standardConditionIds[i].low >>> 0, object.standardConditionIds[i].high >>> 0).toNumber();
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardConjunctionOfStandardConditions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {infinitusai.be.StandardConjunctionOfStandardConditions} message StandardConjunctionOfStandardConditions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardConjunctionOfStandardConditions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.standardConditionIds = [];
                    if (message.standardConditionIds && message.standardConditionIds.length) {
                        object.standardConditionIds = [];
                        for (var j = 0; j < message.standardConditionIds.length; ++j)
                            if (typeof message.standardConditionIds[j] === "number")
                                object.standardConditionIds[j] = options.longs === String ? String(message.standardConditionIds[j]) : message.standardConditionIds[j];
                            else
                                object.standardConditionIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.standardConditionIds[j]) : options.longs === Number ? new $util.LongBits(message.standardConditionIds[j].low >>> 0, message.standardConditionIds[j].high >>> 0).toNumber() : message.standardConditionIds[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this StandardConjunctionOfStandardConditions to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardConjunctionOfStandardConditions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardConjunctionOfStandardConditions
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardConjunctionOfStandardConditions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardConjunctionOfStandardConditions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardConjunctionOfStandardConditions";
                };
    
                return StandardConjunctionOfStandardConditions;
            })();
    
            be.StandardCondition = (function() {
    
                /**
                 * Properties of a StandardCondition.
                 * @memberof infinitusai.be
                 * @interface IStandardCondition
                 * @property {number|Long|null} [id] StandardCondition id
                 * @property {string|null} [name] StandardCondition name
                 * @property {google.protobuf.ITimestamp|null} [createdAt] StandardCondition createdAt
                 * @property {google.protobuf.ITimestamp|null} [updatedAt] StandardCondition updatedAt
                 * @property {infinitusai.be.IFunctionWithParams|null} [lhsFunction] StandardCondition lhsFunction
                 * @property {infinitusai.be.ComparatorEnum|null} [comparator] StandardCondition comparator
                 * @property {infinitusai.be.IFunctionWithParams|null} [rhsFunction] StandardCondition rhsFunction
                 */
    
                /**
                 * Constructs a new StandardCondition.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardCondition.
                 * @implements IStandardCondition
                 * @constructor
                 * @param {infinitusai.be.IStandardCondition=} [properties] Properties to set
                 */
                function StandardCondition(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardCondition id.
                 * @member {number|Long} id
                 * @memberof infinitusai.be.StandardCondition
                 * @instance
                 */
                StandardCondition.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardCondition name.
                 * @member {string} name
                 * @memberof infinitusai.be.StandardCondition
                 * @instance
                 */
                StandardCondition.prototype.name = "";
    
                /**
                 * StandardCondition createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof infinitusai.be.StandardCondition
                 * @instance
                 */
                StandardCondition.prototype.createdAt = null;
    
                /**
                 * StandardCondition updatedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
                 * @memberof infinitusai.be.StandardCondition
                 * @instance
                 */
                StandardCondition.prototype.updatedAt = null;
    
                /**
                 * StandardCondition lhsFunction.
                 * @member {infinitusai.be.IFunctionWithParams|null|undefined} lhsFunction
                 * @memberof infinitusai.be.StandardCondition
                 * @instance
                 */
                StandardCondition.prototype.lhsFunction = null;
    
                /**
                 * StandardCondition comparator.
                 * @member {infinitusai.be.ComparatorEnum} comparator
                 * @memberof infinitusai.be.StandardCondition
                 * @instance
                 */
                StandardCondition.prototype.comparator = 0;
    
                /**
                 * StandardCondition rhsFunction.
                 * @member {infinitusai.be.IFunctionWithParams|null|undefined} rhsFunction
                 * @memberof infinitusai.be.StandardCondition
                 * @instance
                 */
                StandardCondition.prototype.rhsFunction = null;
    
                /**
                 * Creates a new StandardCondition instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {infinitusai.be.IStandardCondition=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardCondition} StandardCondition instance
                 */
                StandardCondition.create = function create(properties) {
                    return new StandardCondition(properties);
                };
    
                /**
                 * Encodes the specified StandardCondition message. Does not implicitly {@link infinitusai.be.StandardCondition.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {infinitusai.be.IStandardCondition} message StandardCondition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardCondition.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                        $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                        $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.lhsFunction != null && Object.hasOwnProperty.call(message, "lhsFunction"))
                        $root.infinitusai.be.FunctionWithParams.encode(message.lhsFunction, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.comparator != null && Object.hasOwnProperty.call(message, "comparator"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.comparator);
                    if (message.rhsFunction != null && Object.hasOwnProperty.call(message, "rhsFunction"))
                        $root.infinitusai.be.FunctionWithParams.encode(message.rhsFunction, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardCondition message, length delimited. Does not implicitly {@link infinitusai.be.StandardCondition.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {infinitusai.be.IStandardCondition} message StandardCondition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardCondition.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardCondition message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardCondition} StandardCondition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardCondition.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardCondition();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int64();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.lhsFunction = $root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.comparator = reader.int32();
                                break;
                            }
                        case 7: {
                                message.rhsFunction = $root.infinitusai.be.FunctionWithParams.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardCondition message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardCondition} StandardCondition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardCondition.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardCondition message.
                 * @function verify
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardCondition.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                            return "id: integer|Long expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                        if (error)
                            return "createdAt." + error;
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
                        if (error)
                            return "updatedAt." + error;
                    }
                    if (message.lhsFunction != null && message.hasOwnProperty("lhsFunction")) {
                        var error = $root.infinitusai.be.FunctionWithParams.verify(message.lhsFunction);
                        if (error)
                            return "lhsFunction." + error;
                    }
                    if (message.comparator != null && message.hasOwnProperty("comparator"))
                        switch (message.comparator) {
                        default:
                            return "comparator: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.rhsFunction != null && message.hasOwnProperty("rhsFunction")) {
                        var error = $root.infinitusai.be.FunctionWithParams.verify(message.rhsFunction);
                        if (error)
                            return "rhsFunction." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a StandardCondition message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardCondition} StandardCondition
                 */
                StandardCondition.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardCondition)
                        return object;
                    var message = new $root.infinitusai.be.StandardCondition();
                    if (object.id != null)
                        if ($util.Long)
                            (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                        else if (typeof object.id === "string")
                            message.id = parseInt(object.id, 10);
                        else if (typeof object.id === "number")
                            message.id = object.id;
                        else if (typeof object.id === "object")
                            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.createdAt != null) {
                        if (typeof object.createdAt !== "object")
                            throw TypeError(".infinitusai.be.StandardCondition.createdAt: object expected");
                        message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                    }
                    if (object.updatedAt != null) {
                        if (typeof object.updatedAt !== "object")
                            throw TypeError(".infinitusai.be.StandardCondition.updatedAt: object expected");
                        message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
                    }
                    if (object.lhsFunction != null) {
                        if (typeof object.lhsFunction !== "object")
                            throw TypeError(".infinitusai.be.StandardCondition.lhsFunction: object expected");
                        message.lhsFunction = $root.infinitusai.be.FunctionWithParams.fromObject(object.lhsFunction);
                    }
                    switch (object.comparator) {
                    default:
                        if (typeof object.comparator === "number") {
                            message.comparator = object.comparator;
                            break;
                        }
                        break;
                    case "ComparatorEnum_UNKNOWN":
                    case 0:
                        message.comparator = 0;
                        break;
                    case "EQUALS":
                    case 1:
                        message.comparator = 1;
                        break;
                    case "NOT_EQUALS":
                    case 2:
                        message.comparator = 2;
                        break;
                    case "GREATER_THAN":
                    case 3:
                        message.comparator = 3;
                        break;
                    case "LESS_THAN":
                    case 4:
                        message.comparator = 4;
                        break;
                    case "GREATER_THAN_OR_EQUALS":
                    case 5:
                        message.comparator = 5;
                        break;
                    case "LESS_THAN_OR_EQUALS":
                    case 6:
                        message.comparator = 6;
                        break;
                    case "IN":
                    case 7:
                        message.comparator = 7;
                        break;
                    case "NOT_IN":
                    case 8:
                        message.comparator = 8;
                        break;
                    }
                    if (object.rhsFunction != null) {
                        if (typeof object.rhsFunction !== "object")
                            throw TypeError(".infinitusai.be.StandardCondition.rhsFunction: object expected");
                        message.rhsFunction = $root.infinitusai.be.FunctionWithParams.fromObject(object.rhsFunction);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardCondition message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {infinitusai.be.StandardCondition} message StandardCondition
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardCondition.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.id = options.longs === String ? "0" : 0;
                        object.name = "";
                        object.createdAt = null;
                        object.updatedAt = null;
                        object.lhsFunction = null;
                        object.comparator = options.enums === String ? "ComparatorEnum_UNKNOWN" : 0;
                        object.rhsFunction = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (typeof message.id === "number")
                            object.id = options.longs === String ? String(message.id) : message.id;
                        else
                            object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
                    if (message.lhsFunction != null && message.hasOwnProperty("lhsFunction"))
                        object.lhsFunction = $root.infinitusai.be.FunctionWithParams.toObject(message.lhsFunction, options);
                    if (message.comparator != null && message.hasOwnProperty("comparator"))
                        object.comparator = options.enums === String ? $root.infinitusai.be.ComparatorEnum[message.comparator] === undefined ? message.comparator : $root.infinitusai.be.ComparatorEnum[message.comparator] : message.comparator;
                    if (message.rhsFunction != null && message.hasOwnProperty("rhsFunction"))
                        object.rhsFunction = $root.infinitusai.be.FunctionWithParams.toObject(message.rhsFunction, options);
                    return object;
                };
    
                /**
                 * Converts this StandardCondition to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardCondition
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardCondition.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardCondition
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardCondition
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardCondition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardCondition";
                };
    
                return StandardCondition;
            })();
    
            /**
             * ComparatorEnum enum.
             * @name infinitusai.be.ComparatorEnum
             * @enum {number}
             * @property {number} ComparatorEnum_UNKNOWN=0 ComparatorEnum_UNKNOWN value
             * @property {number} EQUALS=1 EQUALS value
             * @property {number} NOT_EQUALS=2 NOT_EQUALS value
             * @property {number} GREATER_THAN=3 GREATER_THAN value
             * @property {number} LESS_THAN=4 LESS_THAN value
             * @property {number} GREATER_THAN_OR_EQUALS=5 GREATER_THAN_OR_EQUALS value
             * @property {number} LESS_THAN_OR_EQUALS=6 LESS_THAN_OR_EQUALS value
             * @property {number} IN=7 IN value
             * @property {number} NOT_IN=8 NOT_IN value
             */
            be.ComparatorEnum = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ComparatorEnum_UNKNOWN"] = 0;
                values[valuesById[1] = "EQUALS"] = 1;
                values[valuesById[2] = "NOT_EQUALS"] = 2;
                values[valuesById[3] = "GREATER_THAN"] = 3;
                values[valuesById[4] = "LESS_THAN"] = 4;
                values[valuesById[5] = "GREATER_THAN_OR_EQUALS"] = 5;
                values[valuesById[6] = "LESS_THAN_OR_EQUALS"] = 6;
                values[valuesById[7] = "IN"] = 7;
                values[valuesById[8] = "NOT_IN"] = 8;
                return values;
            })();
    
            be.OutputValue = (function() {
    
                /**
                 * Properties of an OutputValue.
                 * @memberof infinitusai.be
                 * @interface IOutputValue
                 * @property {string|null} [stringValue] OutputValue stringValue
                 * @property {number|Long|null} [intValue] OutputValue intValue
                 * @property {boolean|null} [boolValue] OutputValue boolValue
                 * @property {number|Long|null} [moneyValue] OutputValue moneyValue
                 * @property {string|null} [enumValue] OutputValue enumValue
                 * @property {infinitusai.be.IDate|null} [dateValue] OutputValue dateValue
                 * @property {boolean|null} [agentCouldNotAnswer] OutputValue agentCouldNotAnswer
                 */
    
                /**
                 * Constructs a new OutputValue.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OutputValue.
                 * @implements IOutputValue
                 * @constructor
                 * @param {infinitusai.be.IOutputValue=} [properties] Properties to set
                 */
                function OutputValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OutputValue stringValue.
                 * @member {string|null|undefined} stringValue
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 */
                OutputValue.prototype.stringValue = null;
    
                /**
                 * OutputValue intValue.
                 * @member {number|Long|null|undefined} intValue
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 */
                OutputValue.prototype.intValue = null;
    
                /**
                 * OutputValue boolValue.
                 * @member {boolean|null|undefined} boolValue
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 */
                OutputValue.prototype.boolValue = null;
    
                /**
                 * OutputValue moneyValue.
                 * @member {number|Long|null|undefined} moneyValue
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 */
                OutputValue.prototype.moneyValue = null;
    
                /**
                 * OutputValue enumValue.
                 * @member {string|null|undefined} enumValue
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 */
                OutputValue.prototype.enumValue = null;
    
                /**
                 * OutputValue dateValue.
                 * @member {infinitusai.be.IDate|null|undefined} dateValue
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 */
                OutputValue.prototype.dateValue = null;
    
                /**
                 * OutputValue agentCouldNotAnswer.
                 * @member {boolean} agentCouldNotAnswer
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 */
                OutputValue.prototype.agentCouldNotAnswer = false;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * OutputValue value.
                 * @member {"stringValue"|"intValue"|"boolValue"|"moneyValue"|"enumValue"|"dateValue"|undefined} value
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 */
                Object.defineProperty(OutputValue.prototype, "value", {
                    get: $util.oneOfGetter($oneOfFields = ["stringValue", "intValue", "boolValue", "moneyValue", "enumValue", "dateValue"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new OutputValue instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {infinitusai.be.IOutputValue=} [properties] Properties to set
                 * @returns {infinitusai.be.OutputValue} OutputValue instance
                 */
                OutputValue.create = function create(properties) {
                    return new OutputValue(properties);
                };
    
                /**
                 * Encodes the specified OutputValue message. Does not implicitly {@link infinitusai.be.OutputValue.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {infinitusai.be.IOutputValue} message OutputValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stringValue);
                    if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intValue);
                    if (message.boolValue != null && Object.hasOwnProperty.call(message, "boolValue"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.boolValue);
                    if (message.moneyValue != null && Object.hasOwnProperty.call(message, "moneyValue"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.moneyValue);
                    if (message.enumValue != null && Object.hasOwnProperty.call(message, "enumValue"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.enumValue);
                    if (message.dateValue != null && Object.hasOwnProperty.call(message, "dateValue"))
                        $root.infinitusai.be.Date.encode(message.dateValue, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.agentCouldNotAnswer != null && Object.hasOwnProperty.call(message, "agentCouldNotAnswer"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.agentCouldNotAnswer);
                    return writer;
                };
    
                /**
                 * Encodes the specified OutputValue message, length delimited. Does not implicitly {@link infinitusai.be.OutputValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {infinitusai.be.IOutputValue} message OutputValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OutputValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OutputValue} OutputValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OutputValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.stringValue = reader.string();
                                break;
                            }
                        case 2: {
                                message.intValue = reader.int64();
                                break;
                            }
                        case 3: {
                                message.boolValue = reader.bool();
                                break;
                            }
                        case 4: {
                                message.moneyValue = reader.int64();
                                break;
                            }
                        case 5: {
                                message.enumValue = reader.string();
                                break;
                            }
                        case 6: {
                                message.dateValue = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.agentCouldNotAnswer = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OutputValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OutputValue} OutputValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OutputValue message.
                 * @function verify
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OutputValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                        properties.value = 1;
                        if (!$util.isString(message.stringValue))
                            return "stringValue: string expected";
                    }
                    if (message.intValue != null && message.hasOwnProperty("intValue")) {
                        if (properties.value === 1)
                            return "value: multiple values";
                        properties.value = 1;
                        if (!$util.isInteger(message.intValue) && !(message.intValue && $util.isInteger(message.intValue.low) && $util.isInteger(message.intValue.high)))
                            return "intValue: integer|Long expected";
                    }
                    if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                        if (properties.value === 1)
                            return "value: multiple values";
                        properties.value = 1;
                        if (typeof message.boolValue !== "boolean")
                            return "boolValue: boolean expected";
                    }
                    if (message.moneyValue != null && message.hasOwnProperty("moneyValue")) {
                        if (properties.value === 1)
                            return "value: multiple values";
                        properties.value = 1;
                        if (!$util.isInteger(message.moneyValue) && !(message.moneyValue && $util.isInteger(message.moneyValue.low) && $util.isInteger(message.moneyValue.high)))
                            return "moneyValue: integer|Long expected";
                    }
                    if (message.enumValue != null && message.hasOwnProperty("enumValue")) {
                        if (properties.value === 1)
                            return "value: multiple values";
                        properties.value = 1;
                        if (!$util.isString(message.enumValue))
                            return "enumValue: string expected";
                    }
                    if (message.dateValue != null && message.hasOwnProperty("dateValue")) {
                        if (properties.value === 1)
                            return "value: multiple values";
                        properties.value = 1;
                        {
                            var error = $root.infinitusai.be.Date.verify(message.dateValue);
                            if (error)
                                return "dateValue." + error;
                        }
                    }
                    if (message.agentCouldNotAnswer != null && message.hasOwnProperty("agentCouldNotAnswer"))
                        if (typeof message.agentCouldNotAnswer !== "boolean")
                            return "agentCouldNotAnswer: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an OutputValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OutputValue} OutputValue
                 */
                OutputValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OutputValue)
                        return object;
                    var message = new $root.infinitusai.be.OutputValue();
                    if (object.stringValue != null)
                        message.stringValue = String(object.stringValue);
                    if (object.intValue != null)
                        if ($util.Long)
                            (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = false;
                        else if (typeof object.intValue === "string")
                            message.intValue = parseInt(object.intValue, 10);
                        else if (typeof object.intValue === "number")
                            message.intValue = object.intValue;
                        else if (typeof object.intValue === "object")
                            message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber();
                    if (object.boolValue != null)
                        message.boolValue = Boolean(object.boolValue);
                    if (object.moneyValue != null)
                        if ($util.Long)
                            (message.moneyValue = $util.Long.fromValue(object.moneyValue)).unsigned = false;
                        else if (typeof object.moneyValue === "string")
                            message.moneyValue = parseInt(object.moneyValue, 10);
                        else if (typeof object.moneyValue === "number")
                            message.moneyValue = object.moneyValue;
                        else if (typeof object.moneyValue === "object")
                            message.moneyValue = new $util.LongBits(object.moneyValue.low >>> 0, object.moneyValue.high >>> 0).toNumber();
                    if (object.enumValue != null)
                        message.enumValue = String(object.enumValue);
                    if (object.dateValue != null) {
                        if (typeof object.dateValue !== "object")
                            throw TypeError(".infinitusai.be.OutputValue.dateValue: object expected");
                        message.dateValue = $root.infinitusai.be.Date.fromObject(object.dateValue);
                    }
                    if (object.agentCouldNotAnswer != null)
                        message.agentCouldNotAnswer = Boolean(object.agentCouldNotAnswer);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OutputValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {infinitusai.be.OutputValue} message OutputValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OutputValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.agentCouldNotAnswer = false;
                    if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                        object.stringValue = message.stringValue;
                        if (options.oneofs)
                            object.value = "stringValue";
                    }
                    if (message.intValue != null && message.hasOwnProperty("intValue")) {
                        if (typeof message.intValue === "number")
                            object.intValue = options.longs === String ? String(message.intValue) : message.intValue;
                        else
                            object.intValue = options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber() : message.intValue;
                        if (options.oneofs)
                            object.value = "intValue";
                    }
                    if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                        object.boolValue = message.boolValue;
                        if (options.oneofs)
                            object.value = "boolValue";
                    }
                    if (message.moneyValue != null && message.hasOwnProperty("moneyValue")) {
                        if (typeof message.moneyValue === "number")
                            object.moneyValue = options.longs === String ? String(message.moneyValue) : message.moneyValue;
                        else
                            object.moneyValue = options.longs === String ? $util.Long.prototype.toString.call(message.moneyValue) : options.longs === Number ? new $util.LongBits(message.moneyValue.low >>> 0, message.moneyValue.high >>> 0).toNumber() : message.moneyValue;
                        if (options.oneofs)
                            object.value = "moneyValue";
                    }
                    if (message.enumValue != null && message.hasOwnProperty("enumValue")) {
                        object.enumValue = message.enumValue;
                        if (options.oneofs)
                            object.value = "enumValue";
                    }
                    if (message.dateValue != null && message.hasOwnProperty("dateValue")) {
                        object.dateValue = $root.infinitusai.be.Date.toObject(message.dateValue, options);
                        if (options.oneofs)
                            object.value = "dateValue";
                    }
                    if (message.agentCouldNotAnswer != null && message.hasOwnProperty("agentCouldNotAnswer"))
                        object.agentCouldNotAnswer = message.agentCouldNotAnswer;
                    return object;
                };
    
                /**
                 * Converts this OutputValue to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OutputValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OutputValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OutputValue
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OutputValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OutputValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OutputValue";
                };
    
                return OutputValue;
            })();
    
            be.Output = (function() {
    
                /**
                 * Properties of an Output.
                 * @memberof infinitusai.be
                 * @interface IOutput
                 * @property {number|Long|null} [expandedOutputId] Output expandedOutputId
                 * @property {infinitusai.be.IStandardOutput|null} [baseStandardOutput] Output baseStandardOutput
                 * @property {google.protobuf.ITimestamp|null} [savedAt] Output savedAt
                 * @property {string|null} [activeSource] Output activeSource
                 * @property {Array.<infinitusai.be.IExpandedOutput>|null} [allSources] Output allSources
                 */
    
                /**
                 * Constructs a new Output.
                 * @memberof infinitusai.be
                 * @classdesc Represents an Output.
                 * @implements IOutput
                 * @constructor
                 * @param {infinitusai.be.IOutput=} [properties] Properties to set
                 */
                function Output(properties) {
                    this.allSources = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Output expandedOutputId.
                 * @member {number|Long} expandedOutputId
                 * @memberof infinitusai.be.Output
                 * @instance
                 */
                Output.prototype.expandedOutputId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Output baseStandardOutput.
                 * @member {infinitusai.be.IStandardOutput|null|undefined} baseStandardOutput
                 * @memberof infinitusai.be.Output
                 * @instance
                 */
                Output.prototype.baseStandardOutput = null;
    
                /**
                 * Output savedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} savedAt
                 * @memberof infinitusai.be.Output
                 * @instance
                 */
                Output.prototype.savedAt = null;
    
                /**
                 * Output activeSource.
                 * @member {string} activeSource
                 * @memberof infinitusai.be.Output
                 * @instance
                 */
                Output.prototype.activeSource = "";
    
                /**
                 * Output allSources.
                 * @member {Array.<infinitusai.be.IExpandedOutput>} allSources
                 * @memberof infinitusai.be.Output
                 * @instance
                 */
                Output.prototype.allSources = $util.emptyArray;
    
                /**
                 * Creates a new Output instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {infinitusai.be.IOutput=} [properties] Properties to set
                 * @returns {infinitusai.be.Output} Output instance
                 */
                Output.create = function create(properties) {
                    return new Output(properties);
                };
    
                /**
                 * Encodes the specified Output message. Does not implicitly {@link infinitusai.be.Output.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {infinitusai.be.IOutput} message Output message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Output.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.expandedOutputId != null && Object.hasOwnProperty.call(message, "expandedOutputId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.expandedOutputId);
                    if (message.baseStandardOutput != null && Object.hasOwnProperty.call(message, "baseStandardOutput"))
                        $root.infinitusai.be.StandardOutput.encode(message.baseStandardOutput, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.savedAt != null && Object.hasOwnProperty.call(message, "savedAt"))
                        $root.google.protobuf.Timestamp.encode(message.savedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.activeSource != null && Object.hasOwnProperty.call(message, "activeSource"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.activeSource);
                    if (message.allSources != null && message.allSources.length)
                        for (var i = 0; i < message.allSources.length; ++i)
                            $root.infinitusai.be.ExpandedOutput.encode(message.allSources[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Output message, length delimited. Does not implicitly {@link infinitusai.be.Output.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {infinitusai.be.IOutput} message Output message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Output.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Output message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Output} Output
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Output.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Output();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.expandedOutputId = reader.int64();
                                break;
                            }
                        case 2: {
                                message.baseStandardOutput = $root.infinitusai.be.StandardOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.savedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.activeSource = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.allSources && message.allSources.length))
                                    message.allSources = [];
                                message.allSources.push($root.infinitusai.be.ExpandedOutput.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Output message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Output} Output
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Output.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Output message.
                 * @function verify
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Output.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expandedOutputId != null && message.hasOwnProperty("expandedOutputId"))
                        if (!$util.isInteger(message.expandedOutputId) && !(message.expandedOutputId && $util.isInteger(message.expandedOutputId.low) && $util.isInteger(message.expandedOutputId.high)))
                            return "expandedOutputId: integer|Long expected";
                    if (message.baseStandardOutput != null && message.hasOwnProperty("baseStandardOutput")) {
                        var error = $root.infinitusai.be.StandardOutput.verify(message.baseStandardOutput);
                        if (error)
                            return "baseStandardOutput." + error;
                    }
                    if (message.savedAt != null && message.hasOwnProperty("savedAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.savedAt);
                        if (error)
                            return "savedAt." + error;
                    }
                    if (message.activeSource != null && message.hasOwnProperty("activeSource"))
                        if (!$util.isString(message.activeSource))
                            return "activeSource: string expected";
                    if (message.allSources != null && message.hasOwnProperty("allSources")) {
                        if (!Array.isArray(message.allSources))
                            return "allSources: array expected";
                        for (var i = 0; i < message.allSources.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedOutput.verify(message.allSources[i]);
                            if (error)
                                return "allSources." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an Output message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Output} Output
                 */
                Output.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Output)
                        return object;
                    var message = new $root.infinitusai.be.Output();
                    if (object.expandedOutputId != null)
                        if ($util.Long)
                            (message.expandedOutputId = $util.Long.fromValue(object.expandedOutputId)).unsigned = false;
                        else if (typeof object.expandedOutputId === "string")
                            message.expandedOutputId = parseInt(object.expandedOutputId, 10);
                        else if (typeof object.expandedOutputId === "number")
                            message.expandedOutputId = object.expandedOutputId;
                        else if (typeof object.expandedOutputId === "object")
                            message.expandedOutputId = new $util.LongBits(object.expandedOutputId.low >>> 0, object.expandedOutputId.high >>> 0).toNumber();
                    if (object.baseStandardOutput != null) {
                        if (typeof object.baseStandardOutput !== "object")
                            throw TypeError(".infinitusai.be.Output.baseStandardOutput: object expected");
                        message.baseStandardOutput = $root.infinitusai.be.StandardOutput.fromObject(object.baseStandardOutput);
                    }
                    if (object.savedAt != null) {
                        if (typeof object.savedAt !== "object")
                            throw TypeError(".infinitusai.be.Output.savedAt: object expected");
                        message.savedAt = $root.google.protobuf.Timestamp.fromObject(object.savedAt);
                    }
                    if (object.activeSource != null)
                        message.activeSource = String(object.activeSource);
                    if (object.allSources) {
                        if (!Array.isArray(object.allSources))
                            throw TypeError(".infinitusai.be.Output.allSources: array expected");
                        message.allSources = [];
                        for (var i = 0; i < object.allSources.length; ++i) {
                            if (typeof object.allSources[i] !== "object")
                                throw TypeError(".infinitusai.be.Output.allSources: object expected");
                            message.allSources[i] = $root.infinitusai.be.ExpandedOutput.fromObject(object.allSources[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an Output message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {infinitusai.be.Output} message Output
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Output.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.allSources = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedOutputId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedOutputId = options.longs === String ? "0" : 0;
                        object.baseStandardOutput = null;
                        object.savedAt = null;
                        object.activeSource = "";
                    }
                    if (message.expandedOutputId != null && message.hasOwnProperty("expandedOutputId"))
                        if (typeof message.expandedOutputId === "number")
                            object.expandedOutputId = options.longs === String ? String(message.expandedOutputId) : message.expandedOutputId;
                        else
                            object.expandedOutputId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedOutputId) : options.longs === Number ? new $util.LongBits(message.expandedOutputId.low >>> 0, message.expandedOutputId.high >>> 0).toNumber() : message.expandedOutputId;
                    if (message.baseStandardOutput != null && message.hasOwnProperty("baseStandardOutput"))
                        object.baseStandardOutput = $root.infinitusai.be.StandardOutput.toObject(message.baseStandardOutput, options);
                    if (message.savedAt != null && message.hasOwnProperty("savedAt"))
                        object.savedAt = $root.google.protobuf.Timestamp.toObject(message.savedAt, options);
                    if (message.activeSource != null && message.hasOwnProperty("activeSource"))
                        object.activeSource = message.activeSource;
                    if (message.allSources && message.allSources.length) {
                        object.allSources = [];
                        for (var j = 0; j < message.allSources.length; ++j)
                            object.allSources[j] = $root.infinitusai.be.ExpandedOutput.toObject(message.allSources[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Output to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Output
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Output.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Output
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Output
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Output.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Output";
                };
    
                return Output;
            })();
    
            be.OutputMap = (function() {
    
                /**
                 * Properties of an OutputMap.
                 * @memberof infinitusai.be
                 * @interface IOutputMap
                 * @property {string|null} [orgUuid] OutputMap orgUuid
                 * @property {string|null} [taskUuid] OutputMap taskUuid
                 * @property {string|null} [callUuid] OutputMap callUuid
                 * @property {Object.<string,infinitusai.be.IOutput>|null} [outputs] OutputMap outputs
                 */
    
                /**
                 * Constructs a new OutputMap.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OutputMap.
                 * @implements IOutputMap
                 * @constructor
                 * @param {infinitusai.be.IOutputMap=} [properties] Properties to set
                 */
                function OutputMap(properties) {
                    this.outputs = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OutputMap orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.OutputMap
                 * @instance
                 */
                OutputMap.prototype.orgUuid = "";
    
                /**
                 * OutputMap taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.OutputMap
                 * @instance
                 */
                OutputMap.prototype.taskUuid = "";
    
                /**
                 * OutputMap callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.OutputMap
                 * @instance
                 */
                OutputMap.prototype.callUuid = "";
    
                /**
                 * OutputMap outputs.
                 * @member {Object.<string,infinitusai.be.IOutput>} outputs
                 * @memberof infinitusai.be.OutputMap
                 * @instance
                 */
                OutputMap.prototype.outputs = $util.emptyObject;
    
                /**
                 * Creates a new OutputMap instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {infinitusai.be.IOutputMap=} [properties] Properties to set
                 * @returns {infinitusai.be.OutputMap} OutputMap instance
                 */
                OutputMap.create = function create(properties) {
                    return new OutputMap(properties);
                };
    
                /**
                 * Encodes the specified OutputMap message. Does not implicitly {@link infinitusai.be.OutputMap.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {infinitusai.be.IOutputMap} message OutputMap message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputMap.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.outputs != null && Object.hasOwnProperty.call(message, "outputs"))
                        for (var keys = Object.keys(message.outputs), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.Output.encode(message.outputs[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified OutputMap message, length delimited. Does not implicitly {@link infinitusai.be.OutputMap.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {infinitusai.be.IOutputMap} message OutputMap message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputMap.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OutputMap message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OutputMap} OutputMap
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputMap.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OutputMap(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                if (message.outputs === $util.emptyObject)
                                    message.outputs = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.Output.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.outputs[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OutputMap message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OutputMap} OutputMap
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputMap.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OutputMap message.
                 * @function verify
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OutputMap.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.outputs != null && message.hasOwnProperty("outputs")) {
                        if (!$util.isObject(message.outputs))
                            return "outputs: object expected";
                        var key = Object.keys(message.outputs);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.Output.verify(message.outputs[key[i]]);
                            if (error)
                                return "outputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an OutputMap message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OutputMap} OutputMap
                 */
                OutputMap.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OutputMap)
                        return object;
                    var message = new $root.infinitusai.be.OutputMap();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.outputs) {
                        if (typeof object.outputs !== "object")
                            throw TypeError(".infinitusai.be.OutputMap.outputs: object expected");
                        message.outputs = {};
                        for (var keys = Object.keys(object.outputs), i = 0; i < keys.length; ++i) {
                            if (typeof object.outputs[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.OutputMap.outputs: object expected");
                            message.outputs[keys[i]] = $root.infinitusai.be.Output.fromObject(object.outputs[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OutputMap message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {infinitusai.be.OutputMap} message OutputMap
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OutputMap.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.outputs = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    var keys2;
                    if (message.outputs && (keys2 = Object.keys(message.outputs)).length) {
                        object.outputs = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.outputs[keys2[j]] = $root.infinitusai.be.Output.toObject(message.outputs[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this OutputMap to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OutputMap
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OutputMap.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OutputMap
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OutputMap
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OutputMap.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OutputMap";
                };
    
                return OutputMap;
            })();
    
            be.ConversationConfig = (function() {
    
                /**
                 * Properties of a ConversationConfig.
                 * @memberof infinitusai.be
                 * @interface IConversationConfig
                 * @property {number|Long|null} [conversationConfigId] ConversationConfig conversationConfigId
                 * @property {string|null} [name] ConversationConfig name
                 * @property {string|null} [orgUuid] ConversationConfig orgUuid
                 * @property {infinitusapi.INFTaskType|null} [taskType] ConversationConfig taskType
                 * @property {string|null} [programId] ConversationConfig programId
                 * @property {number|Long|null} [startModuleId] ConversationConfig startModuleId
                 * @property {Array.<infinitusai.be.IDataOverrideRule>|null} [dataOverrideRules] ConversationConfig dataOverrideRules
                 */
    
                /**
                 * Constructs a new ConversationConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ConversationConfig.
                 * @implements IConversationConfig
                 * @constructor
                 * @param {infinitusai.be.IConversationConfig=} [properties] Properties to set
                 */
                function ConversationConfig(properties) {
                    this.dataOverrideRules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ConversationConfig conversationConfigId.
                 * @member {number|Long} conversationConfigId
                 * @memberof infinitusai.be.ConversationConfig
                 * @instance
                 */
                ConversationConfig.prototype.conversationConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ConversationConfig name.
                 * @member {string} name
                 * @memberof infinitusai.be.ConversationConfig
                 * @instance
                 */
                ConversationConfig.prototype.name = "";
    
                /**
                 * ConversationConfig orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.ConversationConfig
                 * @instance
                 */
                ConversationConfig.prototype.orgUuid = "";
    
                /**
                 * ConversationConfig taskType.
                 * @member {infinitusapi.INFTaskType} taskType
                 * @memberof infinitusai.be.ConversationConfig
                 * @instance
                 */
                ConversationConfig.prototype.taskType = 0;
    
                /**
                 * ConversationConfig programId.
                 * @member {string} programId
                 * @memberof infinitusai.be.ConversationConfig
                 * @instance
                 */
                ConversationConfig.prototype.programId = "";
    
                /**
                 * ConversationConfig startModuleId.
                 * @member {number|Long} startModuleId
                 * @memberof infinitusai.be.ConversationConfig
                 * @instance
                 */
                ConversationConfig.prototype.startModuleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ConversationConfig dataOverrideRules.
                 * @member {Array.<infinitusai.be.IDataOverrideRule>} dataOverrideRules
                 * @memberof infinitusai.be.ConversationConfig
                 * @instance
                 */
                ConversationConfig.prototype.dataOverrideRules = $util.emptyArray;
    
                /**
                 * Creates a new ConversationConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {infinitusai.be.IConversationConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.ConversationConfig} ConversationConfig instance
                 */
                ConversationConfig.create = function create(properties) {
                    return new ConversationConfig(properties);
                };
    
                /**
                 * Encodes the specified ConversationConfig message. Does not implicitly {@link infinitusai.be.ConversationConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {infinitusai.be.IConversationConfig} message ConversationConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConversationConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversationConfigId != null && Object.hasOwnProperty.call(message, "conversationConfigId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.conversationConfigId);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.taskType);
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.programId);
                    if (message.startModuleId != null && Object.hasOwnProperty.call(message, "startModuleId"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.startModuleId);
                    if (message.dataOverrideRules != null && message.dataOverrideRules.length)
                        for (var i = 0; i < message.dataOverrideRules.length; ++i)
                            $root.infinitusai.be.DataOverrideRule.encode(message.dataOverrideRules[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ConversationConfig message, length delimited. Does not implicitly {@link infinitusai.be.ConversationConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {infinitusai.be.IConversationConfig} message ConversationConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConversationConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ConversationConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ConversationConfig} ConversationConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConversationConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ConversationConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversationConfigId = reader.int64();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 5: {
                                message.programId = reader.string();
                                break;
                            }
                        case 6: {
                                message.startModuleId = reader.int64();
                                break;
                            }
                        case 8: {
                                if (!(message.dataOverrideRules && message.dataOverrideRules.length))
                                    message.dataOverrideRules = [];
                                message.dataOverrideRules.push($root.infinitusai.be.DataOverrideRule.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ConversationConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ConversationConfig} ConversationConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConversationConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ConversationConfig message.
                 * @function verify
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConversationConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversationConfigId != null && message.hasOwnProperty("conversationConfigId"))
                        if (!$util.isInteger(message.conversationConfigId) && !(message.conversationConfigId && $util.isInteger(message.conversationConfigId.low) && $util.isInteger(message.conversationConfigId.high)))
                            return "conversationConfigId: integer|Long expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    if (message.startModuleId != null && message.hasOwnProperty("startModuleId"))
                        if (!$util.isInteger(message.startModuleId) && !(message.startModuleId && $util.isInteger(message.startModuleId.low) && $util.isInteger(message.startModuleId.high)))
                            return "startModuleId: integer|Long expected";
                    if (message.dataOverrideRules != null && message.hasOwnProperty("dataOverrideRules")) {
                        if (!Array.isArray(message.dataOverrideRules))
                            return "dataOverrideRules: array expected";
                        for (var i = 0; i < message.dataOverrideRules.length; ++i) {
                            var error = $root.infinitusai.be.DataOverrideRule.verify(message.dataOverrideRules[i]);
                            if (error)
                                return "dataOverrideRules." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ConversationConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ConversationConfig} ConversationConfig
                 */
                ConversationConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ConversationConfig)
                        return object;
                    var message = new $root.infinitusai.be.ConversationConfig();
                    if (object.conversationConfigId != null)
                        if ($util.Long)
                            (message.conversationConfigId = $util.Long.fromValue(object.conversationConfigId)).unsigned = false;
                        else if (typeof object.conversationConfigId === "string")
                            message.conversationConfigId = parseInt(object.conversationConfigId, 10);
                        else if (typeof object.conversationConfigId === "number")
                            message.conversationConfigId = object.conversationConfigId;
                        else if (typeof object.conversationConfigId === "object")
                            message.conversationConfigId = new $util.LongBits(object.conversationConfigId.low >>> 0, object.conversationConfigId.high >>> 0).toNumber();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "INF_TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "INF_TASK_TYPE_BENEFITS_VERIFICATION":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "INF_TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "INF_TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "INF_TASK_TYPE_PATIENT_OUTREACH":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "INF_TASK_TYPE_PROVIDER_OUTREACH":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_MM":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_PBM":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "INF_TASK_TYPE_BASIC_BV":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "INF_TASK_TYPE_PBM_DISCOVERY":
                    case 18:
                        message.taskType = 18;
                        break;
                    }
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    if (object.startModuleId != null)
                        if ($util.Long)
                            (message.startModuleId = $util.Long.fromValue(object.startModuleId)).unsigned = false;
                        else if (typeof object.startModuleId === "string")
                            message.startModuleId = parseInt(object.startModuleId, 10);
                        else if (typeof object.startModuleId === "number")
                            message.startModuleId = object.startModuleId;
                        else if (typeof object.startModuleId === "object")
                            message.startModuleId = new $util.LongBits(object.startModuleId.low >>> 0, object.startModuleId.high >>> 0).toNumber();
                    if (object.dataOverrideRules) {
                        if (!Array.isArray(object.dataOverrideRules))
                            throw TypeError(".infinitusai.be.ConversationConfig.dataOverrideRules: array expected");
                        message.dataOverrideRules = [];
                        for (var i = 0; i < object.dataOverrideRules.length; ++i) {
                            if (typeof object.dataOverrideRules[i] !== "object")
                                throw TypeError(".infinitusai.be.ConversationConfig.dataOverrideRules: object expected");
                            message.dataOverrideRules[i] = $root.infinitusai.be.DataOverrideRule.fromObject(object.dataOverrideRules[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ConversationConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {infinitusai.be.ConversationConfig} message ConversationConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConversationConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.dataOverrideRules = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.conversationConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.conversationConfigId = options.longs === String ? "0" : 0;
                        object.name = "";
                        object.orgUuid = "";
                        object.taskType = options.enums === String ? "INF_TASK_TYPE_UNKNOWN" : 0;
                        object.programId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startModuleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startModuleId = options.longs === String ? "0" : 0;
                    }
                    if (message.conversationConfigId != null && message.hasOwnProperty("conversationConfigId"))
                        if (typeof message.conversationConfigId === "number")
                            object.conversationConfigId = options.longs === String ? String(message.conversationConfigId) : message.conversationConfigId;
                        else
                            object.conversationConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.conversationConfigId) : options.longs === Number ? new $util.LongBits(message.conversationConfigId.low >>> 0, message.conversationConfigId.high >>> 0).toNumber() : message.conversationConfigId;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusapi.INFTaskType[message.taskType] === undefined ? message.taskType : $root.infinitusapi.INFTaskType[message.taskType] : message.taskType;
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    if (message.startModuleId != null && message.hasOwnProperty("startModuleId"))
                        if (typeof message.startModuleId === "number")
                            object.startModuleId = options.longs === String ? String(message.startModuleId) : message.startModuleId;
                        else
                            object.startModuleId = options.longs === String ? $util.Long.prototype.toString.call(message.startModuleId) : options.longs === Number ? new $util.LongBits(message.startModuleId.low >>> 0, message.startModuleId.high >>> 0).toNumber() : message.startModuleId;
                    if (message.dataOverrideRules && message.dataOverrideRules.length) {
                        object.dataOverrideRules = [];
                        for (var j = 0; j < message.dataOverrideRules.length; ++j)
                            object.dataOverrideRules[j] = $root.infinitusai.be.DataOverrideRule.toObject(message.dataOverrideRules[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ConversationConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ConversationConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConversationConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ConversationConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ConversationConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ConversationConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ConversationConfig";
                };
    
                return ConversationConfig;
            })();
    
            be.DataOverrideRule = (function() {
    
                /**
                 * Properties of a DataOverrideRule.
                 * @memberof infinitusai.be
                 * @interface IDataOverrideRule
                 * @property {number|Long|null} [standardConditionId] DataOverrideRule standardConditionId
                 * @property {number|Long|null} [expandedOutputId] DataOverrideRule expandedOutputId
                 * @property {infinitusai.be.IOutputValue|null} [outputValue] DataOverrideRule outputValue
                 */
    
                /**
                 * Constructs a new DataOverrideRule.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DataOverrideRule.
                 * @implements IDataOverrideRule
                 * @constructor
                 * @param {infinitusai.be.IDataOverrideRule=} [properties] Properties to set
                 */
                function DataOverrideRule(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DataOverrideRule standardConditionId.
                 * @member {number|Long} standardConditionId
                 * @memberof infinitusai.be.DataOverrideRule
                 * @instance
                 */
                DataOverrideRule.prototype.standardConditionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * DataOverrideRule expandedOutputId.
                 * @member {number|Long} expandedOutputId
                 * @memberof infinitusai.be.DataOverrideRule
                 * @instance
                 */
                DataOverrideRule.prototype.expandedOutputId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * DataOverrideRule outputValue.
                 * @member {infinitusai.be.IOutputValue|null|undefined} outputValue
                 * @memberof infinitusai.be.DataOverrideRule
                 * @instance
                 */
                DataOverrideRule.prototype.outputValue = null;
    
                /**
                 * Creates a new DataOverrideRule instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {infinitusai.be.IDataOverrideRule=} [properties] Properties to set
                 * @returns {infinitusai.be.DataOverrideRule} DataOverrideRule instance
                 */
                DataOverrideRule.create = function create(properties) {
                    return new DataOverrideRule(properties);
                };
    
                /**
                 * Encodes the specified DataOverrideRule message. Does not implicitly {@link infinitusai.be.DataOverrideRule.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {infinitusai.be.IDataOverrideRule} message DataOverrideRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataOverrideRule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardConditionId != null && Object.hasOwnProperty.call(message, "standardConditionId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.standardConditionId);
                    if (message.expandedOutputId != null && Object.hasOwnProperty.call(message, "expandedOutputId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.expandedOutputId);
                    if (message.outputValue != null && Object.hasOwnProperty.call(message, "outputValue"))
                        $root.infinitusai.be.OutputValue.encode(message.outputValue, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified DataOverrideRule message, length delimited. Does not implicitly {@link infinitusai.be.DataOverrideRule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {infinitusai.be.IDataOverrideRule} message DataOverrideRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataOverrideRule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DataOverrideRule message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DataOverrideRule} DataOverrideRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataOverrideRule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DataOverrideRule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.standardConditionId = reader.int64();
                                break;
                            }
                        case 2: {
                                message.expandedOutputId = reader.int64();
                                break;
                            }
                        case 3: {
                                message.outputValue = $root.infinitusai.be.OutputValue.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DataOverrideRule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DataOverrideRule} DataOverrideRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataOverrideRule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DataOverrideRule message.
                 * @function verify
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DataOverrideRule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardConditionId != null && message.hasOwnProperty("standardConditionId"))
                        if (!$util.isInteger(message.standardConditionId) && !(message.standardConditionId && $util.isInteger(message.standardConditionId.low) && $util.isInteger(message.standardConditionId.high)))
                            return "standardConditionId: integer|Long expected";
                    if (message.expandedOutputId != null && message.hasOwnProperty("expandedOutputId"))
                        if (!$util.isInteger(message.expandedOutputId) && !(message.expandedOutputId && $util.isInteger(message.expandedOutputId.low) && $util.isInteger(message.expandedOutputId.high)))
                            return "expandedOutputId: integer|Long expected";
                    if (message.outputValue != null && message.hasOwnProperty("outputValue")) {
                        var error = $root.infinitusai.be.OutputValue.verify(message.outputValue);
                        if (error)
                            return "outputValue." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a DataOverrideRule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DataOverrideRule} DataOverrideRule
                 */
                DataOverrideRule.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DataOverrideRule)
                        return object;
                    var message = new $root.infinitusai.be.DataOverrideRule();
                    if (object.standardConditionId != null)
                        if ($util.Long)
                            (message.standardConditionId = $util.Long.fromValue(object.standardConditionId)).unsigned = false;
                        else if (typeof object.standardConditionId === "string")
                            message.standardConditionId = parseInt(object.standardConditionId, 10);
                        else if (typeof object.standardConditionId === "number")
                            message.standardConditionId = object.standardConditionId;
                        else if (typeof object.standardConditionId === "object")
                            message.standardConditionId = new $util.LongBits(object.standardConditionId.low >>> 0, object.standardConditionId.high >>> 0).toNumber();
                    if (object.expandedOutputId != null)
                        if ($util.Long)
                            (message.expandedOutputId = $util.Long.fromValue(object.expandedOutputId)).unsigned = false;
                        else if (typeof object.expandedOutputId === "string")
                            message.expandedOutputId = parseInt(object.expandedOutputId, 10);
                        else if (typeof object.expandedOutputId === "number")
                            message.expandedOutputId = object.expandedOutputId;
                        else if (typeof object.expandedOutputId === "object")
                            message.expandedOutputId = new $util.LongBits(object.expandedOutputId.low >>> 0, object.expandedOutputId.high >>> 0).toNumber();
                    if (object.outputValue != null) {
                        if (typeof object.outputValue !== "object")
                            throw TypeError(".infinitusai.be.DataOverrideRule.outputValue: object expected");
                        message.outputValue = $root.infinitusai.be.OutputValue.fromObject(object.outputValue);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DataOverrideRule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {infinitusai.be.DataOverrideRule} message DataOverrideRule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DataOverrideRule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.standardConditionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.standardConditionId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expandedOutputId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expandedOutputId = options.longs === String ? "0" : 0;
                        object.outputValue = null;
                    }
                    if (message.standardConditionId != null && message.hasOwnProperty("standardConditionId"))
                        if (typeof message.standardConditionId === "number")
                            object.standardConditionId = options.longs === String ? String(message.standardConditionId) : message.standardConditionId;
                        else
                            object.standardConditionId = options.longs === String ? $util.Long.prototype.toString.call(message.standardConditionId) : options.longs === Number ? new $util.LongBits(message.standardConditionId.low >>> 0, message.standardConditionId.high >>> 0).toNumber() : message.standardConditionId;
                    if (message.expandedOutputId != null && message.hasOwnProperty("expandedOutputId"))
                        if (typeof message.expandedOutputId === "number")
                            object.expandedOutputId = options.longs === String ? String(message.expandedOutputId) : message.expandedOutputId;
                        else
                            object.expandedOutputId = options.longs === String ? $util.Long.prototype.toString.call(message.expandedOutputId) : options.longs === Number ? new $util.LongBits(message.expandedOutputId.low >>> 0, message.expandedOutputId.high >>> 0).toNumber() : message.expandedOutputId;
                    if (message.outputValue != null && message.hasOwnProperty("outputValue"))
                        object.outputValue = $root.infinitusai.be.OutputValue.toObject(message.outputValue, options);
                    return object;
                };
    
                /**
                 * Converts this DataOverrideRule to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DataOverrideRule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DataOverrideRule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DataOverrideRule
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DataOverrideRule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DataOverrideRule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DataOverrideRule";
                };
    
                return DataOverrideRule;
            })();
    
            be.ActionToUtterancesMapping = (function() {
    
                /**
                 * Properties of an ActionToUtterancesMapping.
                 * @memberof infinitusai.be
                 * @interface IActionToUtterancesMapping
                 * @property {number|Long|null} [actionId] ActionToUtterancesMapping actionId
                 * @property {Array.<infinitusai.be.IConditionalActionToUtteranceMapping>|null} [conditionalUtterance] ActionToUtterancesMapping conditionalUtterance
                 */
    
                /**
                 * Constructs a new ActionToUtterancesMapping.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ActionToUtterancesMapping.
                 * @implements IActionToUtterancesMapping
                 * @constructor
                 * @param {infinitusai.be.IActionToUtterancesMapping=} [properties] Properties to set
                 */
                function ActionToUtterancesMapping(properties) {
                    this.conditionalUtterance = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ActionToUtterancesMapping actionId.
                 * @member {number|Long} actionId
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @instance
                 */
                ActionToUtterancesMapping.prototype.actionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ActionToUtterancesMapping conditionalUtterance.
                 * @member {Array.<infinitusai.be.IConditionalActionToUtteranceMapping>} conditionalUtterance
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @instance
                 */
                ActionToUtterancesMapping.prototype.conditionalUtterance = $util.emptyArray;
    
                /**
                 * Creates a new ActionToUtterancesMapping instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {infinitusai.be.IActionToUtterancesMapping=} [properties] Properties to set
                 * @returns {infinitusai.be.ActionToUtterancesMapping} ActionToUtterancesMapping instance
                 */
                ActionToUtterancesMapping.create = function create(properties) {
                    return new ActionToUtterancesMapping(properties);
                };
    
                /**
                 * Encodes the specified ActionToUtterancesMapping message. Does not implicitly {@link infinitusai.be.ActionToUtterancesMapping.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {infinitusai.be.IActionToUtterancesMapping} message ActionToUtterancesMapping message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ActionToUtterancesMapping.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.actionId != null && Object.hasOwnProperty.call(message, "actionId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.actionId);
                    if (message.conditionalUtterance != null && message.conditionalUtterance.length)
                        for (var i = 0; i < message.conditionalUtterance.length; ++i)
                            $root.infinitusai.be.ConditionalActionToUtteranceMapping.encode(message.conditionalUtterance[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ActionToUtterancesMapping message, length delimited. Does not implicitly {@link infinitusai.be.ActionToUtterancesMapping.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {infinitusai.be.IActionToUtterancesMapping} message ActionToUtterancesMapping message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ActionToUtterancesMapping.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ActionToUtterancesMapping message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ActionToUtterancesMapping} ActionToUtterancesMapping
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ActionToUtterancesMapping.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ActionToUtterancesMapping();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.actionId = reader.int64();
                                break;
                            }
                        case 2: {
                                if (!(message.conditionalUtterance && message.conditionalUtterance.length))
                                    message.conditionalUtterance = [];
                                message.conditionalUtterance.push($root.infinitusai.be.ConditionalActionToUtteranceMapping.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ActionToUtterancesMapping message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ActionToUtterancesMapping} ActionToUtterancesMapping
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ActionToUtterancesMapping.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ActionToUtterancesMapping message.
                 * @function verify
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ActionToUtterancesMapping.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.actionId != null && message.hasOwnProperty("actionId"))
                        if (!$util.isInteger(message.actionId) && !(message.actionId && $util.isInteger(message.actionId.low) && $util.isInteger(message.actionId.high)))
                            return "actionId: integer|Long expected";
                    if (message.conditionalUtterance != null && message.hasOwnProperty("conditionalUtterance")) {
                        if (!Array.isArray(message.conditionalUtterance))
                            return "conditionalUtterance: array expected";
                        for (var i = 0; i < message.conditionalUtterance.length; ++i) {
                            var error = $root.infinitusai.be.ConditionalActionToUtteranceMapping.verify(message.conditionalUtterance[i]);
                            if (error)
                                return "conditionalUtterance." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an ActionToUtterancesMapping message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ActionToUtterancesMapping} ActionToUtterancesMapping
                 */
                ActionToUtterancesMapping.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ActionToUtterancesMapping)
                        return object;
                    var message = new $root.infinitusai.be.ActionToUtterancesMapping();
                    if (object.actionId != null)
                        if ($util.Long)
                            (message.actionId = $util.Long.fromValue(object.actionId)).unsigned = false;
                        else if (typeof object.actionId === "string")
                            message.actionId = parseInt(object.actionId, 10);
                        else if (typeof object.actionId === "number")
                            message.actionId = object.actionId;
                        else if (typeof object.actionId === "object")
                            message.actionId = new $util.LongBits(object.actionId.low >>> 0, object.actionId.high >>> 0).toNumber();
                    if (object.conditionalUtterance) {
                        if (!Array.isArray(object.conditionalUtterance))
                            throw TypeError(".infinitusai.be.ActionToUtterancesMapping.conditionalUtterance: array expected");
                        message.conditionalUtterance = [];
                        for (var i = 0; i < object.conditionalUtterance.length; ++i) {
                            if (typeof object.conditionalUtterance[i] !== "object")
                                throw TypeError(".infinitusai.be.ActionToUtterancesMapping.conditionalUtterance: object expected");
                            message.conditionalUtterance[i] = $root.infinitusai.be.ConditionalActionToUtteranceMapping.fromObject(object.conditionalUtterance[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ActionToUtterancesMapping message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {infinitusai.be.ActionToUtterancesMapping} message ActionToUtterancesMapping
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ActionToUtterancesMapping.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.conditionalUtterance = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.actionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.actionId = options.longs === String ? "0" : 0;
                    if (message.actionId != null && message.hasOwnProperty("actionId"))
                        if (typeof message.actionId === "number")
                            object.actionId = options.longs === String ? String(message.actionId) : message.actionId;
                        else
                            object.actionId = options.longs === String ? $util.Long.prototype.toString.call(message.actionId) : options.longs === Number ? new $util.LongBits(message.actionId.low >>> 0, message.actionId.high >>> 0).toNumber() : message.actionId;
                    if (message.conditionalUtterance && message.conditionalUtterance.length) {
                        object.conditionalUtterance = [];
                        for (var j = 0; j < message.conditionalUtterance.length; ++j)
                            object.conditionalUtterance[j] = $root.infinitusai.be.ConditionalActionToUtteranceMapping.toObject(message.conditionalUtterance[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ActionToUtterancesMapping to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ActionToUtterancesMapping.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ActionToUtterancesMapping
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ActionToUtterancesMapping
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ActionToUtterancesMapping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ActionToUtterancesMapping";
                };
    
                return ActionToUtterancesMapping;
            })();
    
            be.ConditionalActionToUtteranceMapping = (function() {
    
                /**
                 * Properties of a ConditionalActionToUtteranceMapping.
                 * @memberof infinitusai.be
                 * @interface IConditionalActionToUtteranceMapping
                 * @property {number|Long|null} [utteranceId] ConditionalActionToUtteranceMapping utteranceId
                 * @property {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions|null} [disjunctionOfConjunctionOfConditions] ConditionalActionToUtteranceMapping disjunctionOfConjunctionOfConditions
                 * @property {number|Long|null} [priority] ConditionalActionToUtteranceMapping priority
                 * @property {number|Long|null} [id] ConditionalActionToUtteranceMapping id
                 */
    
                /**
                 * Constructs a new ConditionalActionToUtteranceMapping.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ConditionalActionToUtteranceMapping.
                 * @implements IConditionalActionToUtteranceMapping
                 * @constructor
                 * @param {infinitusai.be.IConditionalActionToUtteranceMapping=} [properties] Properties to set
                 */
                function ConditionalActionToUtteranceMapping(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ConditionalActionToUtteranceMapping utteranceId.
                 * @member {number|Long} utteranceId
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @instance
                 */
                ConditionalActionToUtteranceMapping.prototype.utteranceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ConditionalActionToUtteranceMapping disjunctionOfConjunctionOfConditions.
                 * @member {infinitusai.be.IStandardDisjunctionOfConjunctionsOfStandardConditions|null|undefined} disjunctionOfConjunctionOfConditions
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @instance
                 */
                ConditionalActionToUtteranceMapping.prototype.disjunctionOfConjunctionOfConditions = null;
    
                /**
                 * ConditionalActionToUtteranceMapping priority.
                 * @member {number|Long} priority
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @instance
                 */
                ConditionalActionToUtteranceMapping.prototype.priority = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ConditionalActionToUtteranceMapping id.
                 * @member {number|Long} id
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @instance
                 */
                ConditionalActionToUtteranceMapping.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new ConditionalActionToUtteranceMapping instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {infinitusai.be.IConditionalActionToUtteranceMapping=} [properties] Properties to set
                 * @returns {infinitusai.be.ConditionalActionToUtteranceMapping} ConditionalActionToUtteranceMapping instance
                 */
                ConditionalActionToUtteranceMapping.create = function create(properties) {
                    return new ConditionalActionToUtteranceMapping(properties);
                };
    
                /**
                 * Encodes the specified ConditionalActionToUtteranceMapping message. Does not implicitly {@link infinitusai.be.ConditionalActionToUtteranceMapping.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {infinitusai.be.IConditionalActionToUtteranceMapping} message ConditionalActionToUtteranceMapping message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConditionalActionToUtteranceMapping.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.utteranceId != null && Object.hasOwnProperty.call(message, "utteranceId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.utteranceId);
                    if (message.disjunctionOfConjunctionOfConditions != null && Object.hasOwnProperty.call(message, "disjunctionOfConjunctionOfConditions"))
                        $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.encode(message.disjunctionOfConjunctionOfConditions, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.priority);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.id);
                    return writer;
                };
    
                /**
                 * Encodes the specified ConditionalActionToUtteranceMapping message, length delimited. Does not implicitly {@link infinitusai.be.ConditionalActionToUtteranceMapping.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {infinitusai.be.IConditionalActionToUtteranceMapping} message ConditionalActionToUtteranceMapping message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConditionalActionToUtteranceMapping.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ConditionalActionToUtteranceMapping message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ConditionalActionToUtteranceMapping} ConditionalActionToUtteranceMapping
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConditionalActionToUtteranceMapping.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ConditionalActionToUtteranceMapping();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.utteranceId = reader.int64();
                                break;
                            }
                        case 4: {
                                message.disjunctionOfConjunctionOfConditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.priority = reader.int64();
                                break;
                            }
                        case 6: {
                                message.id = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ConditionalActionToUtteranceMapping message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ConditionalActionToUtteranceMapping} ConditionalActionToUtteranceMapping
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConditionalActionToUtteranceMapping.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ConditionalActionToUtteranceMapping message.
                 * @function verify
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConditionalActionToUtteranceMapping.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.utteranceId != null && message.hasOwnProperty("utteranceId"))
                        if (!$util.isInteger(message.utteranceId) && !(message.utteranceId && $util.isInteger(message.utteranceId.low) && $util.isInteger(message.utteranceId.high)))
                            return "utteranceId: integer|Long expected";
                    if (message.disjunctionOfConjunctionOfConditions != null && message.hasOwnProperty("disjunctionOfConjunctionOfConditions")) {
                        var error = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.verify(message.disjunctionOfConjunctionOfConditions);
                        if (error)
                            return "disjunctionOfConjunctionOfConditions." + error;
                    }
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        if (!$util.isInteger(message.priority) && !(message.priority && $util.isInteger(message.priority.low) && $util.isInteger(message.priority.high)))
                            return "priority: integer|Long expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                            return "id: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a ConditionalActionToUtteranceMapping message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ConditionalActionToUtteranceMapping} ConditionalActionToUtteranceMapping
                 */
                ConditionalActionToUtteranceMapping.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ConditionalActionToUtteranceMapping)
                        return object;
                    var message = new $root.infinitusai.be.ConditionalActionToUtteranceMapping();
                    if (object.utteranceId != null)
                        if ($util.Long)
                            (message.utteranceId = $util.Long.fromValue(object.utteranceId)).unsigned = false;
                        else if (typeof object.utteranceId === "string")
                            message.utteranceId = parseInt(object.utteranceId, 10);
                        else if (typeof object.utteranceId === "number")
                            message.utteranceId = object.utteranceId;
                        else if (typeof object.utteranceId === "object")
                            message.utteranceId = new $util.LongBits(object.utteranceId.low >>> 0, object.utteranceId.high >>> 0).toNumber();
                    if (object.disjunctionOfConjunctionOfConditions != null) {
                        if (typeof object.disjunctionOfConjunctionOfConditions !== "object")
                            throw TypeError(".infinitusai.be.ConditionalActionToUtteranceMapping.disjunctionOfConjunctionOfConditions: object expected");
                        message.disjunctionOfConjunctionOfConditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.fromObject(object.disjunctionOfConjunctionOfConditions);
                    }
                    if (object.priority != null)
                        if ($util.Long)
                            (message.priority = $util.Long.fromValue(object.priority)).unsigned = false;
                        else if (typeof object.priority === "string")
                            message.priority = parseInt(object.priority, 10);
                        else if (typeof object.priority === "number")
                            message.priority = object.priority;
                        else if (typeof object.priority === "object")
                            message.priority = new $util.LongBits(object.priority.low >>> 0, object.priority.high >>> 0).toNumber();
                    if (object.id != null)
                        if ($util.Long)
                            (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                        else if (typeof object.id === "string")
                            message.id = parseInt(object.id, 10);
                        else if (typeof object.id === "number")
                            message.id = object.id;
                        else if (typeof object.id === "object")
                            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a ConditionalActionToUtteranceMapping message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {infinitusai.be.ConditionalActionToUtteranceMapping} message ConditionalActionToUtteranceMapping
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConditionalActionToUtteranceMapping.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.utteranceId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.utteranceId = options.longs === String ? "0" : 0;
                        object.disjunctionOfConjunctionOfConditions = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.priority = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.priority = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.id = options.longs === String ? "0" : 0;
                    }
                    if (message.utteranceId != null && message.hasOwnProperty("utteranceId"))
                        if (typeof message.utteranceId === "number")
                            object.utteranceId = options.longs === String ? String(message.utteranceId) : message.utteranceId;
                        else
                            object.utteranceId = options.longs === String ? $util.Long.prototype.toString.call(message.utteranceId) : options.longs === Number ? new $util.LongBits(message.utteranceId.low >>> 0, message.utteranceId.high >>> 0).toNumber() : message.utteranceId;
                    if (message.disjunctionOfConjunctionOfConditions != null && message.hasOwnProperty("disjunctionOfConjunctionOfConditions"))
                        object.disjunctionOfConjunctionOfConditions = $root.infinitusai.be.StandardDisjunctionOfConjunctionsOfStandardConditions.toObject(message.disjunctionOfConjunctionOfConditions, options);
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        if (typeof message.priority === "number")
                            object.priority = options.longs === String ? String(message.priority) : message.priority;
                        else
                            object.priority = options.longs === String ? $util.Long.prototype.toString.call(message.priority) : options.longs === Number ? new $util.LongBits(message.priority.low >>> 0, message.priority.high >>> 0).toNumber() : message.priority;
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (typeof message.id === "number")
                            object.id = options.longs === String ? String(message.id) : message.id;
                        else
                            object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                    return object;
                };
    
                /**
                 * Converts this ConditionalActionToUtteranceMapping to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConditionalActionToUtteranceMapping.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ConditionalActionToUtteranceMapping
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ConditionalActionToUtteranceMapping
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ConditionalActionToUtteranceMapping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ConditionalActionToUtteranceMapping";
                };
    
                return ConditionalActionToUtteranceMapping;
            })();
    
            be.StandardQueryOptions = (function() {
    
                /**
                 * Properties of a StandardQueryOptions.
                 * @memberof infinitusai.be
                 * @interface IStandardQueryOptions
                 * @property {number|Long|null} [limit] StandardQueryOptions limit
                 * @property {number|Long|null} [offset] StandardQueryOptions offset
                 * @property {string|null} [searchByName] StandardQueryOptions searchByName
                 */
    
                /**
                 * Constructs a new StandardQueryOptions.
                 * @memberof infinitusai.be
                 * @classdesc Represents a StandardQueryOptions.
                 * @implements IStandardQueryOptions
                 * @constructor
                 * @param {infinitusai.be.IStandardQueryOptions=} [properties] Properties to set
                 */
                function StandardQueryOptions(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StandardQueryOptions limit.
                 * @member {number|Long} limit
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @instance
                 */
                StandardQueryOptions.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardQueryOptions offset.
                 * @member {number|Long} offset
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @instance
                 */
                StandardQueryOptions.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * StandardQueryOptions searchByName.
                 * @member {string} searchByName
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @instance
                 */
                StandardQueryOptions.prototype.searchByName = "";
    
                /**
                 * Creates a new StandardQueryOptions instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {infinitusai.be.IStandardQueryOptions=} [properties] Properties to set
                 * @returns {infinitusai.be.StandardQueryOptions} StandardQueryOptions instance
                 */
                StandardQueryOptions.create = function create(properties) {
                    return new StandardQueryOptions(properties);
                };
    
                /**
                 * Encodes the specified StandardQueryOptions message. Does not implicitly {@link infinitusai.be.StandardQueryOptions.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {infinitusai.be.IStandardQueryOptions} message StandardQueryOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardQueryOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.limit);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.offset);
                    if (message.searchByName != null && Object.hasOwnProperty.call(message, "searchByName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.searchByName);
                    return writer;
                };
    
                /**
                 * Encodes the specified StandardQueryOptions message, length delimited. Does not implicitly {@link infinitusai.be.StandardQueryOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {infinitusai.be.IStandardQueryOptions} message StandardQueryOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StandardQueryOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StandardQueryOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.StandardQueryOptions} StandardQueryOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardQueryOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.StandardQueryOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.limit = reader.int64();
                                break;
                            }
                        case 2: {
                                message.offset = reader.int64();
                                break;
                            }
                        case 3: {
                                message.searchByName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StandardQueryOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.StandardQueryOptions} StandardQueryOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StandardQueryOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StandardQueryOptions message.
                 * @function verify
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StandardQueryOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                            return "limit: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                            return "offset: integer|Long expected";
                    if (message.searchByName != null && message.hasOwnProperty("searchByName"))
                        if (!$util.isString(message.searchByName))
                            return "searchByName: string expected";
                    return null;
                };
    
                /**
                 * Creates a StandardQueryOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.StandardQueryOptions} StandardQueryOptions
                 */
                StandardQueryOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.StandardQueryOptions)
                        return object;
                    var message = new $root.infinitusai.be.StandardQueryOptions();
                    if (object.limit != null)
                        if ($util.Long)
                            (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                        else if (typeof object.limit === "string")
                            message.limit = parseInt(object.limit, 10);
                        else if (typeof object.limit === "number")
                            message.limit = object.limit;
                        else if (typeof object.limit === "object")
                            message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                    if (object.offset != null)
                        if ($util.Long)
                            (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                        else if (typeof object.offset === "string")
                            message.offset = parseInt(object.offset, 10);
                        else if (typeof object.offset === "number")
                            message.offset = object.offset;
                        else if (typeof object.offset === "object")
                            message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                    if (object.searchByName != null)
                        message.searchByName = String(object.searchByName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a StandardQueryOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {infinitusai.be.StandardQueryOptions} message StandardQueryOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StandardQueryOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.limit = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.offset = options.longs === String ? "0" : 0;
                        object.searchByName = "";
                    }
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (typeof message.limit === "number")
                            object.limit = options.longs === String ? String(message.limit) : message.limit;
                        else
                            object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (typeof message.offset === "number")
                            object.offset = options.longs === String ? String(message.offset) : message.offset;
                        else
                            object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                    if (message.searchByName != null && message.hasOwnProperty("searchByName"))
                        object.searchByName = message.searchByName;
                    return object;
                };
    
                /**
                 * Converts this StandardQueryOptions to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StandardQueryOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StandardQueryOptions
                 * @function getTypeUrl
                 * @memberof infinitusai.be.StandardQueryOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StandardQueryOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.StandardQueryOptions";
                };
    
                return StandardQueryOptions;
            })();
    
            be.GetStandardConditionsRequest = (function() {
    
                /**
                 * Properties of a GetStandardConditionsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetStandardConditionsRequest
                 * @property {infinitusai.be.IStandardQueryOptions|null} [queryOptions] GetStandardConditionsRequest queryOptions
                 */
    
                /**
                 * Constructs a new GetStandardConditionsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetStandardConditionsRequest.
                 * @implements IGetStandardConditionsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetStandardConditionsRequest=} [properties] Properties to set
                 */
                function GetStandardConditionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetStandardConditionsRequest queryOptions.
                 * @member {infinitusai.be.IStandardQueryOptions|null|undefined} queryOptions
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @instance
                 */
                GetStandardConditionsRequest.prototype.queryOptions = null;
    
                /**
                 * Creates a new GetStandardConditionsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardConditionsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetStandardConditionsRequest} GetStandardConditionsRequest instance
                 */
                GetStandardConditionsRequest.create = function create(properties) {
                    return new GetStandardConditionsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetStandardConditionsRequest message. Does not implicitly {@link infinitusai.be.GetStandardConditionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardConditionsRequest} message GetStandardConditionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardConditionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queryOptions != null && Object.hasOwnProperty.call(message, "queryOptions"))
                        $root.infinitusai.be.StandardQueryOptions.encode(message.queryOptions, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetStandardConditionsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetStandardConditionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardConditionsRequest} message GetStandardConditionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardConditionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetStandardConditionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetStandardConditionsRequest} GetStandardConditionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardConditionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetStandardConditionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queryOptions = $root.infinitusai.be.StandardQueryOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetStandardConditionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetStandardConditionsRequest} GetStandardConditionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardConditionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetStandardConditionsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStandardConditionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions")) {
                        var error = $root.infinitusai.be.StandardQueryOptions.verify(message.queryOptions);
                        if (error)
                            return "queryOptions." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetStandardConditionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetStandardConditionsRequest} GetStandardConditionsRequest
                 */
                GetStandardConditionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetStandardConditionsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetStandardConditionsRequest();
                    if (object.queryOptions != null) {
                        if (typeof object.queryOptions !== "object")
                            throw TypeError(".infinitusai.be.GetStandardConditionsRequest.queryOptions: object expected");
                        message.queryOptions = $root.infinitusai.be.StandardQueryOptions.fromObject(object.queryOptions);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetStandardConditionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {infinitusai.be.GetStandardConditionsRequest} message GetStandardConditionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStandardConditionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queryOptions = null;
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions"))
                        object.queryOptions = $root.infinitusai.be.StandardQueryOptions.toObject(message.queryOptions, options);
                    return object;
                };
    
                /**
                 * Converts this GetStandardConditionsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStandardConditionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetStandardConditionsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetStandardConditionsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetStandardConditionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetStandardConditionsRequest";
                };
    
                return GetStandardConditionsRequest;
            })();
    
            be.GetStandardConditionsResponse = (function() {
    
                /**
                 * Properties of a GetStandardConditionsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetStandardConditionsResponse
                 * @property {Array.<infinitusai.be.IStandardCondition>|null} [standardConditions] GetStandardConditionsResponse standardConditions
                 */
    
                /**
                 * Constructs a new GetStandardConditionsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetStandardConditionsResponse.
                 * @implements IGetStandardConditionsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetStandardConditionsResponse=} [properties] Properties to set
                 */
                function GetStandardConditionsResponse(properties) {
                    this.standardConditions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetStandardConditionsResponse standardConditions.
                 * @member {Array.<infinitusai.be.IStandardCondition>} standardConditions
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @instance
                 */
                GetStandardConditionsResponse.prototype.standardConditions = $util.emptyArray;
    
                /**
                 * Creates a new GetStandardConditionsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardConditionsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetStandardConditionsResponse} GetStandardConditionsResponse instance
                 */
                GetStandardConditionsResponse.create = function create(properties) {
                    return new GetStandardConditionsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetStandardConditionsResponse message. Does not implicitly {@link infinitusai.be.GetStandardConditionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardConditionsResponse} message GetStandardConditionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardConditionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardConditions != null && message.standardConditions.length)
                        for (var i = 0; i < message.standardConditions.length; ++i)
                            $root.infinitusai.be.StandardCondition.encode(message.standardConditions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetStandardConditionsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetStandardConditionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardConditionsResponse} message GetStandardConditionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardConditionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetStandardConditionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetStandardConditionsResponse} GetStandardConditionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardConditionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetStandardConditionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.standardConditions && message.standardConditions.length))
                                    message.standardConditions = [];
                                message.standardConditions.push($root.infinitusai.be.StandardCondition.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetStandardConditionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetStandardConditionsResponse} GetStandardConditionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardConditionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetStandardConditionsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStandardConditionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardConditions != null && message.hasOwnProperty("standardConditions")) {
                        if (!Array.isArray(message.standardConditions))
                            return "standardConditions: array expected";
                        for (var i = 0; i < message.standardConditions.length; ++i) {
                            var error = $root.infinitusai.be.StandardCondition.verify(message.standardConditions[i]);
                            if (error)
                                return "standardConditions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetStandardConditionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetStandardConditionsResponse} GetStandardConditionsResponse
                 */
                GetStandardConditionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetStandardConditionsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetStandardConditionsResponse();
                    if (object.standardConditions) {
                        if (!Array.isArray(object.standardConditions))
                            throw TypeError(".infinitusai.be.GetStandardConditionsResponse.standardConditions: array expected");
                        message.standardConditions = [];
                        for (var i = 0; i < object.standardConditions.length; ++i) {
                            if (typeof object.standardConditions[i] !== "object")
                                throw TypeError(".infinitusai.be.GetStandardConditionsResponse.standardConditions: object expected");
                            message.standardConditions[i] = $root.infinitusai.be.StandardCondition.fromObject(object.standardConditions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetStandardConditionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {infinitusai.be.GetStandardConditionsResponse} message GetStandardConditionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStandardConditionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.standardConditions = [];
                    if (message.standardConditions && message.standardConditions.length) {
                        object.standardConditions = [];
                        for (var j = 0; j < message.standardConditions.length; ++j)
                            object.standardConditions[j] = $root.infinitusai.be.StandardCondition.toObject(message.standardConditions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetStandardConditionsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStandardConditionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetStandardConditionsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetStandardConditionsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetStandardConditionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetStandardConditionsResponse";
                };
    
                return GetStandardConditionsResponse;
            })();
    
            be.UpsertStandardConditionRequest = (function() {
    
                /**
                 * Properties of an UpsertStandardConditionRequest.
                 * @memberof infinitusai.be
                 * @interface IUpsertStandardConditionRequest
                 * @property {infinitusai.be.IStandardCondition|null} [standardCondition] UpsertStandardConditionRequest standardCondition
                 */
    
                /**
                 * Constructs a new UpsertStandardConditionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertStandardConditionRequest.
                 * @implements IUpsertStandardConditionRequest
                 * @constructor
                 * @param {infinitusai.be.IUpsertStandardConditionRequest=} [properties] Properties to set
                 */
                function UpsertStandardConditionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertStandardConditionRequest standardCondition.
                 * @member {infinitusai.be.IStandardCondition|null|undefined} standardCondition
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @instance
                 */
                UpsertStandardConditionRequest.prototype.standardCondition = null;
    
                /**
                 * Creates a new UpsertStandardConditionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {infinitusai.be.IUpsertStandardConditionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertStandardConditionRequest} UpsertStandardConditionRequest instance
                 */
                UpsertStandardConditionRequest.create = function create(properties) {
                    return new UpsertStandardConditionRequest(properties);
                };
    
                /**
                 * Encodes the specified UpsertStandardConditionRequest message. Does not implicitly {@link infinitusai.be.UpsertStandardConditionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {infinitusai.be.IUpsertStandardConditionRequest} message UpsertStandardConditionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertStandardConditionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardCondition != null && Object.hasOwnProperty.call(message, "standardCondition"))
                        $root.infinitusai.be.StandardCondition.encode(message.standardCondition, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertStandardConditionRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpsertStandardConditionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {infinitusai.be.IUpsertStandardConditionRequest} message UpsertStandardConditionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertStandardConditionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertStandardConditionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertStandardConditionRequest} UpsertStandardConditionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertStandardConditionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertStandardConditionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.standardCondition = $root.infinitusai.be.StandardCondition.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertStandardConditionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertStandardConditionRequest} UpsertStandardConditionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertStandardConditionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertStandardConditionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertStandardConditionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardCondition != null && message.hasOwnProperty("standardCondition")) {
                        var error = $root.infinitusai.be.StandardCondition.verify(message.standardCondition);
                        if (error)
                            return "standardCondition." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertStandardConditionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertStandardConditionRequest} UpsertStandardConditionRequest
                 */
                UpsertStandardConditionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertStandardConditionRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpsertStandardConditionRequest();
                    if (object.standardCondition != null) {
                        if (typeof object.standardCondition !== "object")
                            throw TypeError(".infinitusai.be.UpsertStandardConditionRequest.standardCondition: object expected");
                        message.standardCondition = $root.infinitusai.be.StandardCondition.fromObject(object.standardCondition);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertStandardConditionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {infinitusai.be.UpsertStandardConditionRequest} message UpsertStandardConditionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertStandardConditionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.standardCondition = null;
                    if (message.standardCondition != null && message.hasOwnProperty("standardCondition"))
                        object.standardCondition = $root.infinitusai.be.StandardCondition.toObject(message.standardCondition, options);
                    return object;
                };
    
                /**
                 * Converts this UpsertStandardConditionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertStandardConditionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertStandardConditionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertStandardConditionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertStandardConditionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertStandardConditionRequest";
                };
    
                return UpsertStandardConditionRequest;
            })();
    
            be.UpsertStandardConditionResponse = (function() {
    
                /**
                 * Properties of an UpsertStandardConditionResponse.
                 * @memberof infinitusai.be
                 * @interface IUpsertStandardConditionResponse
                 * @property {infinitusai.be.IStandardCondition|null} [standardCondition] UpsertStandardConditionResponse standardCondition
                 */
    
                /**
                 * Constructs a new UpsertStandardConditionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertStandardConditionResponse.
                 * @implements IUpsertStandardConditionResponse
                 * @constructor
                 * @param {infinitusai.be.IUpsertStandardConditionResponse=} [properties] Properties to set
                 */
                function UpsertStandardConditionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertStandardConditionResponse standardCondition.
                 * @member {infinitusai.be.IStandardCondition|null|undefined} standardCondition
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @instance
                 */
                UpsertStandardConditionResponse.prototype.standardCondition = null;
    
                /**
                 * Creates a new UpsertStandardConditionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {infinitusai.be.IUpsertStandardConditionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertStandardConditionResponse} UpsertStandardConditionResponse instance
                 */
                UpsertStandardConditionResponse.create = function create(properties) {
                    return new UpsertStandardConditionResponse(properties);
                };
    
                /**
                 * Encodes the specified UpsertStandardConditionResponse message. Does not implicitly {@link infinitusai.be.UpsertStandardConditionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {infinitusai.be.IUpsertStandardConditionResponse} message UpsertStandardConditionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertStandardConditionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardCondition != null && Object.hasOwnProperty.call(message, "standardCondition"))
                        $root.infinitusai.be.StandardCondition.encode(message.standardCondition, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertStandardConditionResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpsertStandardConditionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {infinitusai.be.IUpsertStandardConditionResponse} message UpsertStandardConditionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertStandardConditionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertStandardConditionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertStandardConditionResponse} UpsertStandardConditionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertStandardConditionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertStandardConditionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.standardCondition = $root.infinitusai.be.StandardCondition.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertStandardConditionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertStandardConditionResponse} UpsertStandardConditionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertStandardConditionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertStandardConditionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertStandardConditionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardCondition != null && message.hasOwnProperty("standardCondition")) {
                        var error = $root.infinitusai.be.StandardCondition.verify(message.standardCondition);
                        if (error)
                            return "standardCondition." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertStandardConditionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertStandardConditionResponse} UpsertStandardConditionResponse
                 */
                UpsertStandardConditionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertStandardConditionResponse)
                        return object;
                    var message = new $root.infinitusai.be.UpsertStandardConditionResponse();
                    if (object.standardCondition != null) {
                        if (typeof object.standardCondition !== "object")
                            throw TypeError(".infinitusai.be.UpsertStandardConditionResponse.standardCondition: object expected");
                        message.standardCondition = $root.infinitusai.be.StandardCondition.fromObject(object.standardCondition);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertStandardConditionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {infinitusai.be.UpsertStandardConditionResponse} message UpsertStandardConditionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertStandardConditionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.standardCondition = null;
                    if (message.standardCondition != null && message.hasOwnProperty("standardCondition"))
                        object.standardCondition = $root.infinitusai.be.StandardCondition.toObject(message.standardCondition, options);
                    return object;
                };
    
                /**
                 * Converts this UpsertStandardConditionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertStandardConditionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertStandardConditionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertStandardConditionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertStandardConditionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertStandardConditionResponse";
                };
    
                return UpsertStandardConditionResponse;
            })();
    
            be.GetFunctionsRequirementsRequest = (function() {
    
                /**
                 * Properties of a GetFunctionsRequirementsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetFunctionsRequirementsRequest
                 */
    
                /**
                 * Constructs a new GetFunctionsRequirementsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetFunctionsRequirementsRequest.
                 * @implements IGetFunctionsRequirementsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetFunctionsRequirementsRequest=} [properties] Properties to set
                 */
                function GetFunctionsRequirementsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetFunctionsRequirementsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {infinitusai.be.IGetFunctionsRequirementsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetFunctionsRequirementsRequest} GetFunctionsRequirementsRequest instance
                 */
                GetFunctionsRequirementsRequest.create = function create(properties) {
                    return new GetFunctionsRequirementsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetFunctionsRequirementsRequest message. Does not implicitly {@link infinitusai.be.GetFunctionsRequirementsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {infinitusai.be.IGetFunctionsRequirementsRequest} message GetFunctionsRequirementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFunctionsRequirementsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetFunctionsRequirementsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetFunctionsRequirementsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {infinitusai.be.IGetFunctionsRequirementsRequest} message GetFunctionsRequirementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFunctionsRequirementsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetFunctionsRequirementsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetFunctionsRequirementsRequest} GetFunctionsRequirementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFunctionsRequirementsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetFunctionsRequirementsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetFunctionsRequirementsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetFunctionsRequirementsRequest} GetFunctionsRequirementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFunctionsRequirementsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetFunctionsRequirementsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetFunctionsRequirementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetFunctionsRequirementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetFunctionsRequirementsRequest} GetFunctionsRequirementsRequest
                 */
                GetFunctionsRequirementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetFunctionsRequirementsRequest)
                        return object;
                    return new $root.infinitusai.be.GetFunctionsRequirementsRequest();
                };
    
                /**
                 * Creates a plain object from a GetFunctionsRequirementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {infinitusai.be.GetFunctionsRequirementsRequest} message GetFunctionsRequirementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetFunctionsRequirementsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetFunctionsRequirementsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetFunctionsRequirementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetFunctionsRequirementsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetFunctionsRequirementsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetFunctionsRequirementsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetFunctionsRequirementsRequest";
                };
    
                return GetFunctionsRequirementsRequest;
            })();
    
            be.GetFunctionsRequirementsResponse = (function() {
    
                /**
                 * Properties of a GetFunctionsRequirementsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetFunctionsRequirementsResponse
                 * @property {Array.<infinitusai.be.IFunctionRequirements>|null} [functions] GetFunctionsRequirementsResponse functions
                 */
    
                /**
                 * Constructs a new GetFunctionsRequirementsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetFunctionsRequirementsResponse.
                 * @implements IGetFunctionsRequirementsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetFunctionsRequirementsResponse=} [properties] Properties to set
                 */
                function GetFunctionsRequirementsResponse(properties) {
                    this.functions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetFunctionsRequirementsResponse functions.
                 * @member {Array.<infinitusai.be.IFunctionRequirements>} functions
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @instance
                 */
                GetFunctionsRequirementsResponse.prototype.functions = $util.emptyArray;
    
                /**
                 * Creates a new GetFunctionsRequirementsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {infinitusai.be.IGetFunctionsRequirementsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetFunctionsRequirementsResponse} GetFunctionsRequirementsResponse instance
                 */
                GetFunctionsRequirementsResponse.create = function create(properties) {
                    return new GetFunctionsRequirementsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetFunctionsRequirementsResponse message. Does not implicitly {@link infinitusai.be.GetFunctionsRequirementsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {infinitusai.be.IGetFunctionsRequirementsResponse} message GetFunctionsRequirementsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFunctionsRequirementsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.functions != null && message.functions.length)
                        for (var i = 0; i < message.functions.length; ++i)
                            $root.infinitusai.be.FunctionRequirements.encode(message.functions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetFunctionsRequirementsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetFunctionsRequirementsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {infinitusai.be.IGetFunctionsRequirementsResponse} message GetFunctionsRequirementsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFunctionsRequirementsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetFunctionsRequirementsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetFunctionsRequirementsResponse} GetFunctionsRequirementsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFunctionsRequirementsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetFunctionsRequirementsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.functions && message.functions.length))
                                    message.functions = [];
                                message.functions.push($root.infinitusai.be.FunctionRequirements.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetFunctionsRequirementsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetFunctionsRequirementsResponse} GetFunctionsRequirementsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFunctionsRequirementsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetFunctionsRequirementsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetFunctionsRequirementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.functions != null && message.hasOwnProperty("functions")) {
                        if (!Array.isArray(message.functions))
                            return "functions: array expected";
                        for (var i = 0; i < message.functions.length; ++i) {
                            var error = $root.infinitusai.be.FunctionRequirements.verify(message.functions[i]);
                            if (error)
                                return "functions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetFunctionsRequirementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetFunctionsRequirementsResponse} GetFunctionsRequirementsResponse
                 */
                GetFunctionsRequirementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetFunctionsRequirementsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetFunctionsRequirementsResponse();
                    if (object.functions) {
                        if (!Array.isArray(object.functions))
                            throw TypeError(".infinitusai.be.GetFunctionsRequirementsResponse.functions: array expected");
                        message.functions = [];
                        for (var i = 0; i < object.functions.length; ++i) {
                            if (typeof object.functions[i] !== "object")
                                throw TypeError(".infinitusai.be.GetFunctionsRequirementsResponse.functions: object expected");
                            message.functions[i] = $root.infinitusai.be.FunctionRequirements.fromObject(object.functions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetFunctionsRequirementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {infinitusai.be.GetFunctionsRequirementsResponse} message GetFunctionsRequirementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetFunctionsRequirementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.functions = [];
                    if (message.functions && message.functions.length) {
                        object.functions = [];
                        for (var j = 0; j < message.functions.length; ++j)
                            object.functions[j] = $root.infinitusai.be.FunctionRequirements.toObject(message.functions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetFunctionsRequirementsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetFunctionsRequirementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetFunctionsRequirementsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetFunctionsRequirementsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetFunctionsRequirementsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetFunctionsRequirementsResponse";
                };
    
                return GetFunctionsRequirementsResponse;
            })();
    
            be.FunctionRequirements = (function() {
    
                /**
                 * Properties of a FunctionRequirements.
                 * @memberof infinitusai.be
                 * @interface IFunctionRequirements
                 * @property {number|Long|null} [id] FunctionRequirements id
                 * @property {string|null} [name] FunctionRequirements name
                 * @property {string|null} [description] FunctionRequirements description
                 * @property {Array.<string>|null} [params] FunctionRequirements params
                 * @property {string|null} [returnType] FunctionRequirements returnType
                 */
    
                /**
                 * Constructs a new FunctionRequirements.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FunctionRequirements.
                 * @implements IFunctionRequirements
                 * @constructor
                 * @param {infinitusai.be.IFunctionRequirements=} [properties] Properties to set
                 */
                function FunctionRequirements(properties) {
                    this.params = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FunctionRequirements id.
                 * @member {number|Long} id
                 * @memberof infinitusai.be.FunctionRequirements
                 * @instance
                 */
                FunctionRequirements.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * FunctionRequirements name.
                 * @member {string} name
                 * @memberof infinitusai.be.FunctionRequirements
                 * @instance
                 */
                FunctionRequirements.prototype.name = "";
    
                /**
                 * FunctionRequirements description.
                 * @member {string} description
                 * @memberof infinitusai.be.FunctionRequirements
                 * @instance
                 */
                FunctionRequirements.prototype.description = "";
    
                /**
                 * FunctionRequirements params.
                 * @member {Array.<string>} params
                 * @memberof infinitusai.be.FunctionRequirements
                 * @instance
                 */
                FunctionRequirements.prototype.params = $util.emptyArray;
    
                /**
                 * FunctionRequirements returnType.
                 * @member {string} returnType
                 * @memberof infinitusai.be.FunctionRequirements
                 * @instance
                 */
                FunctionRequirements.prototype.returnType = "";
    
                /**
                 * Creates a new FunctionRequirements instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {infinitusai.be.IFunctionRequirements=} [properties] Properties to set
                 * @returns {infinitusai.be.FunctionRequirements} FunctionRequirements instance
                 */
                FunctionRequirements.create = function create(properties) {
                    return new FunctionRequirements(properties);
                };
    
                /**
                 * Encodes the specified FunctionRequirements message. Does not implicitly {@link infinitusai.be.FunctionRequirements.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {infinitusai.be.IFunctionRequirements} message FunctionRequirements message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FunctionRequirements.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.params != null && message.params.length)
                        for (var i = 0; i < message.params.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.params[i]);
                    if (message.returnType != null && Object.hasOwnProperty.call(message, "returnType"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.returnType);
                    return writer;
                };
    
                /**
                 * Encodes the specified FunctionRequirements message, length delimited. Does not implicitly {@link infinitusai.be.FunctionRequirements.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {infinitusai.be.IFunctionRequirements} message FunctionRequirements message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FunctionRequirements.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FunctionRequirements message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FunctionRequirements} FunctionRequirements
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FunctionRequirements.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FunctionRequirements();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int64();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.params && message.params.length))
                                    message.params = [];
                                message.params.push(reader.string());
                                break;
                            }
                        case 5: {
                                message.returnType = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FunctionRequirements message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FunctionRequirements} FunctionRequirements
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FunctionRequirements.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FunctionRequirements message.
                 * @function verify
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FunctionRequirements.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                            return "id: integer|Long expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.params != null && message.hasOwnProperty("params")) {
                        if (!Array.isArray(message.params))
                            return "params: array expected";
                        for (var i = 0; i < message.params.length; ++i)
                            if (!$util.isString(message.params[i]))
                                return "params: string[] expected";
                    }
                    if (message.returnType != null && message.hasOwnProperty("returnType"))
                        if (!$util.isString(message.returnType))
                            return "returnType: string expected";
                    return null;
                };
    
                /**
                 * Creates a FunctionRequirements message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FunctionRequirements} FunctionRequirements
                 */
                FunctionRequirements.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FunctionRequirements)
                        return object;
                    var message = new $root.infinitusai.be.FunctionRequirements();
                    if (object.id != null)
                        if ($util.Long)
                            (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                        else if (typeof object.id === "string")
                            message.id = parseInt(object.id, 10);
                        else if (typeof object.id === "number")
                            message.id = object.id;
                        else if (typeof object.id === "object")
                            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.params) {
                        if (!Array.isArray(object.params))
                            throw TypeError(".infinitusai.be.FunctionRequirements.params: array expected");
                        message.params = [];
                        for (var i = 0; i < object.params.length; ++i)
                            message.params[i] = String(object.params[i]);
                    }
                    if (object.returnType != null)
                        message.returnType = String(object.returnType);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FunctionRequirements message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {infinitusai.be.FunctionRequirements} message FunctionRequirements
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FunctionRequirements.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.params = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.id = options.longs === String ? "0" : 0;
                        object.name = "";
                        object.description = "";
                        object.returnType = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (typeof message.id === "number")
                            object.id = options.longs === String ? String(message.id) : message.id;
                        else
                            object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.params && message.params.length) {
                        object.params = [];
                        for (var j = 0; j < message.params.length; ++j)
                            object.params[j] = message.params[j];
                    }
                    if (message.returnType != null && message.hasOwnProperty("returnType"))
                        object.returnType = message.returnType;
                    return object;
                };
    
                /**
                 * Converts this FunctionRequirements to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FunctionRequirements
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FunctionRequirements.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FunctionRequirements
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FunctionRequirements
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FunctionRequirements.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FunctionRequirements";
                };
    
                return FunctionRequirements;
            })();
    
            be.GetStandardOutputsRequest = (function() {
    
                /**
                 * Properties of a GetStandardOutputsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetStandardOutputsRequest
                 * @property {infinitusai.be.IStandardQueryOptions|null} [queryOptions] GetStandardOutputsRequest queryOptions
                 */
    
                /**
                 * Constructs a new GetStandardOutputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetStandardOutputsRequest.
                 * @implements IGetStandardOutputsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetStandardOutputsRequest=} [properties] Properties to set
                 */
                function GetStandardOutputsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetStandardOutputsRequest queryOptions.
                 * @member {infinitusai.be.IStandardQueryOptions|null|undefined} queryOptions
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @instance
                 */
                GetStandardOutputsRequest.prototype.queryOptions = null;
    
                /**
                 * Creates a new GetStandardOutputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardOutputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetStandardOutputsRequest} GetStandardOutputsRequest instance
                 */
                GetStandardOutputsRequest.create = function create(properties) {
                    return new GetStandardOutputsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetStandardOutputsRequest message. Does not implicitly {@link infinitusai.be.GetStandardOutputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardOutputsRequest} message GetStandardOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardOutputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queryOptions != null && Object.hasOwnProperty.call(message, "queryOptions"))
                        $root.infinitusai.be.StandardQueryOptions.encode(message.queryOptions, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetStandardOutputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetStandardOutputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardOutputsRequest} message GetStandardOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardOutputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetStandardOutputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetStandardOutputsRequest} GetStandardOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardOutputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetStandardOutputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queryOptions = $root.infinitusai.be.StandardQueryOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetStandardOutputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetStandardOutputsRequest} GetStandardOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardOutputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetStandardOutputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStandardOutputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions")) {
                        var error = $root.infinitusai.be.StandardQueryOptions.verify(message.queryOptions);
                        if (error)
                            return "queryOptions." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetStandardOutputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetStandardOutputsRequest} GetStandardOutputsRequest
                 */
                GetStandardOutputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetStandardOutputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetStandardOutputsRequest();
                    if (object.queryOptions != null) {
                        if (typeof object.queryOptions !== "object")
                            throw TypeError(".infinitusai.be.GetStandardOutputsRequest.queryOptions: object expected");
                        message.queryOptions = $root.infinitusai.be.StandardQueryOptions.fromObject(object.queryOptions);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetStandardOutputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {infinitusai.be.GetStandardOutputsRequest} message GetStandardOutputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStandardOutputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queryOptions = null;
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions"))
                        object.queryOptions = $root.infinitusai.be.StandardQueryOptions.toObject(message.queryOptions, options);
                    return object;
                };
    
                /**
                 * Converts this GetStandardOutputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStandardOutputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetStandardOutputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetStandardOutputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetStandardOutputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetStandardOutputsRequest";
                };
    
                return GetStandardOutputsRequest;
            })();
    
            be.GetStandardOutputsResponse = (function() {
    
                /**
                 * Properties of a GetStandardOutputsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetStandardOutputsResponse
                 * @property {Array.<infinitusai.be.IStandardOutput>|null} [standardOutputs] GetStandardOutputsResponse standardOutputs
                 */
    
                /**
                 * Constructs a new GetStandardOutputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetStandardOutputsResponse.
                 * @implements IGetStandardOutputsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetStandardOutputsResponse=} [properties] Properties to set
                 */
                function GetStandardOutputsResponse(properties) {
                    this.standardOutputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetStandardOutputsResponse standardOutputs.
                 * @member {Array.<infinitusai.be.IStandardOutput>} standardOutputs
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @instance
                 */
                GetStandardOutputsResponse.prototype.standardOutputs = $util.emptyArray;
    
                /**
                 * Creates a new GetStandardOutputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardOutputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetStandardOutputsResponse} GetStandardOutputsResponse instance
                 */
                GetStandardOutputsResponse.create = function create(properties) {
                    return new GetStandardOutputsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetStandardOutputsResponse message. Does not implicitly {@link infinitusai.be.GetStandardOutputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardOutputsResponse} message GetStandardOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardOutputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardOutputs != null && message.standardOutputs.length)
                        for (var i = 0; i < message.standardOutputs.length; ++i)
                            $root.infinitusai.be.StandardOutput.encode(message.standardOutputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetStandardOutputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetStandardOutputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardOutputsResponse} message GetStandardOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardOutputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetStandardOutputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetStandardOutputsResponse} GetStandardOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardOutputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetStandardOutputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.standardOutputs && message.standardOutputs.length))
                                    message.standardOutputs = [];
                                message.standardOutputs.push($root.infinitusai.be.StandardOutput.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetStandardOutputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetStandardOutputsResponse} GetStandardOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardOutputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetStandardOutputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStandardOutputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardOutputs != null && message.hasOwnProperty("standardOutputs")) {
                        if (!Array.isArray(message.standardOutputs))
                            return "standardOutputs: array expected";
                        for (var i = 0; i < message.standardOutputs.length; ++i) {
                            var error = $root.infinitusai.be.StandardOutput.verify(message.standardOutputs[i]);
                            if (error)
                                return "standardOutputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetStandardOutputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetStandardOutputsResponse} GetStandardOutputsResponse
                 */
                GetStandardOutputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetStandardOutputsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetStandardOutputsResponse();
                    if (object.standardOutputs) {
                        if (!Array.isArray(object.standardOutputs))
                            throw TypeError(".infinitusai.be.GetStandardOutputsResponse.standardOutputs: array expected");
                        message.standardOutputs = [];
                        for (var i = 0; i < object.standardOutputs.length; ++i) {
                            if (typeof object.standardOutputs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetStandardOutputsResponse.standardOutputs: object expected");
                            message.standardOutputs[i] = $root.infinitusai.be.StandardOutput.fromObject(object.standardOutputs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetStandardOutputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {infinitusai.be.GetStandardOutputsResponse} message GetStandardOutputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStandardOutputsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.standardOutputs = [];
                    if (message.standardOutputs && message.standardOutputs.length) {
                        object.standardOutputs = [];
                        for (var j = 0; j < message.standardOutputs.length; ++j)
                            object.standardOutputs[j] = $root.infinitusai.be.StandardOutput.toObject(message.standardOutputs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetStandardOutputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStandardOutputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetStandardOutputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetStandardOutputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetStandardOutputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetStandardOutputsResponse";
                };
    
                return GetStandardOutputsResponse;
            })();
    
            be.UpsertStandardOutputRequest = (function() {
    
                /**
                 * Properties of an UpsertStandardOutputRequest.
                 * @memberof infinitusai.be
                 * @interface IUpsertStandardOutputRequest
                 * @property {infinitusai.be.IStandardOutput|null} [standardOutput] UpsertStandardOutputRequest standardOutput
                 */
    
                /**
                 * Constructs a new UpsertStandardOutputRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertStandardOutputRequest.
                 * @implements IUpsertStandardOutputRequest
                 * @constructor
                 * @param {infinitusai.be.IUpsertStandardOutputRequest=} [properties] Properties to set
                 */
                function UpsertStandardOutputRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertStandardOutputRequest standardOutput.
                 * @member {infinitusai.be.IStandardOutput|null|undefined} standardOutput
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @instance
                 */
                UpsertStandardOutputRequest.prototype.standardOutput = null;
    
                /**
                 * Creates a new UpsertStandardOutputRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {infinitusai.be.IUpsertStandardOutputRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertStandardOutputRequest} UpsertStandardOutputRequest instance
                 */
                UpsertStandardOutputRequest.create = function create(properties) {
                    return new UpsertStandardOutputRequest(properties);
                };
    
                /**
                 * Encodes the specified UpsertStandardOutputRequest message. Does not implicitly {@link infinitusai.be.UpsertStandardOutputRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {infinitusai.be.IUpsertStandardOutputRequest} message UpsertStandardOutputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertStandardOutputRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardOutput != null && Object.hasOwnProperty.call(message, "standardOutput"))
                        $root.infinitusai.be.StandardOutput.encode(message.standardOutput, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertStandardOutputRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpsertStandardOutputRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {infinitusai.be.IUpsertStandardOutputRequest} message UpsertStandardOutputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertStandardOutputRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertStandardOutputRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertStandardOutputRequest} UpsertStandardOutputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertStandardOutputRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertStandardOutputRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.standardOutput = $root.infinitusai.be.StandardOutput.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertStandardOutputRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertStandardOutputRequest} UpsertStandardOutputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertStandardOutputRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertStandardOutputRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertStandardOutputRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardOutput != null && message.hasOwnProperty("standardOutput")) {
                        var error = $root.infinitusai.be.StandardOutput.verify(message.standardOutput);
                        if (error)
                            return "standardOutput." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertStandardOutputRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertStandardOutputRequest} UpsertStandardOutputRequest
                 */
                UpsertStandardOutputRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertStandardOutputRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpsertStandardOutputRequest();
                    if (object.standardOutput != null) {
                        if (typeof object.standardOutput !== "object")
                            throw TypeError(".infinitusai.be.UpsertStandardOutputRequest.standardOutput: object expected");
                        message.standardOutput = $root.infinitusai.be.StandardOutput.fromObject(object.standardOutput);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertStandardOutputRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {infinitusai.be.UpsertStandardOutputRequest} message UpsertStandardOutputRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertStandardOutputRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.standardOutput = null;
                    if (message.standardOutput != null && message.hasOwnProperty("standardOutput"))
                        object.standardOutput = $root.infinitusai.be.StandardOutput.toObject(message.standardOutput, options);
                    return object;
                };
    
                /**
                 * Converts this UpsertStandardOutputRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertStandardOutputRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertStandardOutputRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertStandardOutputRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertStandardOutputRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertStandardOutputRequest";
                };
    
                return UpsertStandardOutputRequest;
            })();
    
            be.UpsertStandardOutputResponse = (function() {
    
                /**
                 * Properties of an UpsertStandardOutputResponse.
                 * @memberof infinitusai.be
                 * @interface IUpsertStandardOutputResponse
                 * @property {infinitusai.be.IStandardOutput|null} [standardOutput] UpsertStandardOutputResponse standardOutput
                 */
    
                /**
                 * Constructs a new UpsertStandardOutputResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertStandardOutputResponse.
                 * @implements IUpsertStandardOutputResponse
                 * @constructor
                 * @param {infinitusai.be.IUpsertStandardOutputResponse=} [properties] Properties to set
                 */
                function UpsertStandardOutputResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertStandardOutputResponse standardOutput.
                 * @member {infinitusai.be.IStandardOutput|null|undefined} standardOutput
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @instance
                 */
                UpsertStandardOutputResponse.prototype.standardOutput = null;
    
                /**
                 * Creates a new UpsertStandardOutputResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {infinitusai.be.IUpsertStandardOutputResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertStandardOutputResponse} UpsertStandardOutputResponse instance
                 */
                UpsertStandardOutputResponse.create = function create(properties) {
                    return new UpsertStandardOutputResponse(properties);
                };
    
                /**
                 * Encodes the specified UpsertStandardOutputResponse message. Does not implicitly {@link infinitusai.be.UpsertStandardOutputResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {infinitusai.be.IUpsertStandardOutputResponse} message UpsertStandardOutputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertStandardOutputResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.standardOutput != null && Object.hasOwnProperty.call(message, "standardOutput"))
                        $root.infinitusai.be.StandardOutput.encode(message.standardOutput, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertStandardOutputResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpsertStandardOutputResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {infinitusai.be.IUpsertStandardOutputResponse} message UpsertStandardOutputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertStandardOutputResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertStandardOutputResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertStandardOutputResponse} UpsertStandardOutputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertStandardOutputResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertStandardOutputResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.standardOutput = $root.infinitusai.be.StandardOutput.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertStandardOutputResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertStandardOutputResponse} UpsertStandardOutputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertStandardOutputResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertStandardOutputResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertStandardOutputResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.standardOutput != null && message.hasOwnProperty("standardOutput")) {
                        var error = $root.infinitusai.be.StandardOutput.verify(message.standardOutput);
                        if (error)
                            return "standardOutput." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertStandardOutputResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertStandardOutputResponse} UpsertStandardOutputResponse
                 */
                UpsertStandardOutputResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertStandardOutputResponse)
                        return object;
                    var message = new $root.infinitusai.be.UpsertStandardOutputResponse();
                    if (object.standardOutput != null) {
                        if (typeof object.standardOutput !== "object")
                            throw TypeError(".infinitusai.be.UpsertStandardOutputResponse.standardOutput: object expected");
                        message.standardOutput = $root.infinitusai.be.StandardOutput.fromObject(object.standardOutput);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertStandardOutputResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {infinitusai.be.UpsertStandardOutputResponse} message UpsertStandardOutputResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertStandardOutputResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.standardOutput = null;
                    if (message.standardOutput != null && message.hasOwnProperty("standardOutput"))
                        object.standardOutput = $root.infinitusai.be.StandardOutput.toObject(message.standardOutput, options);
                    return object;
                };
    
                /**
                 * Converts this UpsertStandardOutputResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertStandardOutputResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertStandardOutputResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertStandardOutputResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertStandardOutputResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertStandardOutputResponse";
                };
    
                return UpsertStandardOutputResponse;
            })();
    
            be.ExpandedActionToUtteranceMapping = (function() {
    
                /**
                 * Properties of an ExpandedActionToUtteranceMapping.
                 * @memberof infinitusai.be
                 * @interface IExpandedActionToUtteranceMapping
                 * @property {infinitusai.be.IStandardAction|null} [action] ExpandedActionToUtteranceMapping action
                 * @property {Array.<infinitusai.be.ExpandedActionToUtteranceMapping.IUtteranceConditionCouple>|null} [utterances] ExpandedActionToUtteranceMapping utterances
                 * @property {Array.<infinitusai.be.IStandardOutput>|null} [outputs] ExpandedActionToUtteranceMapping outputs
                 * @property {Array.<string>|null} [modules] ExpandedActionToUtteranceMapping modules
                 */
    
                /**
                 * Constructs a new ExpandedActionToUtteranceMapping.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExpandedActionToUtteranceMapping.
                 * @implements IExpandedActionToUtteranceMapping
                 * @constructor
                 * @param {infinitusai.be.IExpandedActionToUtteranceMapping=} [properties] Properties to set
                 */
                function ExpandedActionToUtteranceMapping(properties) {
                    this.utterances = [];
                    this.outputs = [];
                    this.modules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExpandedActionToUtteranceMapping action.
                 * @member {infinitusai.be.IStandardAction|null|undefined} action
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @instance
                 */
                ExpandedActionToUtteranceMapping.prototype.action = null;
    
                /**
                 * ExpandedActionToUtteranceMapping utterances.
                 * @member {Array.<infinitusai.be.ExpandedActionToUtteranceMapping.IUtteranceConditionCouple>} utterances
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @instance
                 */
                ExpandedActionToUtteranceMapping.prototype.utterances = $util.emptyArray;
    
                /**
                 * ExpandedActionToUtteranceMapping outputs.
                 * @member {Array.<infinitusai.be.IStandardOutput>} outputs
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @instance
                 */
                ExpandedActionToUtteranceMapping.prototype.outputs = $util.emptyArray;
    
                /**
                 * ExpandedActionToUtteranceMapping modules.
                 * @member {Array.<string>} modules
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @instance
                 */
                ExpandedActionToUtteranceMapping.prototype.modules = $util.emptyArray;
    
                /**
                 * Creates a new ExpandedActionToUtteranceMapping instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {infinitusai.be.IExpandedActionToUtteranceMapping=} [properties] Properties to set
                 * @returns {infinitusai.be.ExpandedActionToUtteranceMapping} ExpandedActionToUtteranceMapping instance
                 */
                ExpandedActionToUtteranceMapping.create = function create(properties) {
                    return new ExpandedActionToUtteranceMapping(properties);
                };
    
                /**
                 * Encodes the specified ExpandedActionToUtteranceMapping message. Does not implicitly {@link infinitusai.be.ExpandedActionToUtteranceMapping.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {infinitusai.be.IExpandedActionToUtteranceMapping} message ExpandedActionToUtteranceMapping message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedActionToUtteranceMapping.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                        $root.infinitusai.be.StandardAction.encode(message.action, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.utterances != null && message.utterances.length)
                        for (var i = 0; i < message.utterances.length; ++i)
                            $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.encode(message.utterances[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.outputs != null && message.outputs.length)
                        for (var i = 0; i < message.outputs.length; ++i)
                            $root.infinitusai.be.StandardOutput.encode(message.outputs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.modules != null && message.modules.length)
                        for (var i = 0; i < message.modules.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.modules[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified ExpandedActionToUtteranceMapping message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedActionToUtteranceMapping.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {infinitusai.be.IExpandedActionToUtteranceMapping} message ExpandedActionToUtteranceMapping message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExpandedActionToUtteranceMapping.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExpandedActionToUtteranceMapping message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExpandedActionToUtteranceMapping} ExpandedActionToUtteranceMapping
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedActionToUtteranceMapping.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedActionToUtteranceMapping();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.action = $root.infinitusai.be.StandardAction.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.utterances && message.utterances.length))
                                    message.utterances = [];
                                message.utterances.push($root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.outputs && message.outputs.length))
                                    message.outputs = [];
                                message.outputs.push($root.infinitusai.be.StandardOutput.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.modules && message.modules.length))
                                    message.modules = [];
                                message.modules.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExpandedActionToUtteranceMapping message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExpandedActionToUtteranceMapping} ExpandedActionToUtteranceMapping
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExpandedActionToUtteranceMapping.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExpandedActionToUtteranceMapping message.
                 * @function verify
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedActionToUtteranceMapping.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.action != null && message.hasOwnProperty("action")) {
                        var error = $root.infinitusai.be.StandardAction.verify(message.action);
                        if (error)
                            return "action." + error;
                    }
                    if (message.utterances != null && message.hasOwnProperty("utterances")) {
                        if (!Array.isArray(message.utterances))
                            return "utterances: array expected";
                        for (var i = 0; i < message.utterances.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.verify(message.utterances[i]);
                            if (error)
                                return "utterances." + error;
                        }
                    }
                    if (message.outputs != null && message.hasOwnProperty("outputs")) {
                        if (!Array.isArray(message.outputs))
                            return "outputs: array expected";
                        for (var i = 0; i < message.outputs.length; ++i) {
                            var error = $root.infinitusai.be.StandardOutput.verify(message.outputs[i]);
                            if (error)
                                return "outputs." + error;
                        }
                    }
                    if (message.modules != null && message.hasOwnProperty("modules")) {
                        if (!Array.isArray(message.modules))
                            return "modules: array expected";
                        for (var i = 0; i < message.modules.length; ++i)
                            if (!$util.isString(message.modules[i]))
                                return "modules: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an ExpandedActionToUtteranceMapping message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExpandedActionToUtteranceMapping} ExpandedActionToUtteranceMapping
                 */
                ExpandedActionToUtteranceMapping.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExpandedActionToUtteranceMapping)
                        return object;
                    var message = new $root.infinitusai.be.ExpandedActionToUtteranceMapping();
                    if (object.action != null) {
                        if (typeof object.action !== "object")
                            throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.action: object expected");
                        message.action = $root.infinitusai.be.StandardAction.fromObject(object.action);
                    }
                    if (object.utterances) {
                        if (!Array.isArray(object.utterances))
                            throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.utterances: array expected");
                        message.utterances = [];
                        for (var i = 0; i < object.utterances.length; ++i) {
                            if (typeof object.utterances[i] !== "object")
                                throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.utterances: object expected");
                            message.utterances[i] = $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.fromObject(object.utterances[i]);
                        }
                    }
                    if (object.outputs) {
                        if (!Array.isArray(object.outputs))
                            throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.outputs: array expected");
                        message.outputs = [];
                        for (var i = 0; i < object.outputs.length; ++i) {
                            if (typeof object.outputs[i] !== "object")
                                throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.outputs: object expected");
                            message.outputs[i] = $root.infinitusai.be.StandardOutput.fromObject(object.outputs[i]);
                        }
                    }
                    if (object.modules) {
                        if (!Array.isArray(object.modules))
                            throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.modules: array expected");
                        message.modules = [];
                        for (var i = 0; i < object.modules.length; ++i)
                            message.modules[i] = String(object.modules[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExpandedActionToUtteranceMapping message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {infinitusai.be.ExpandedActionToUtteranceMapping} message ExpandedActionToUtteranceMapping
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedActionToUtteranceMapping.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.utterances = [];
                        object.outputs = [];
                        object.modules = [];
                    }
                    if (options.defaults)
                        object.action = null;
                    if (message.action != null && message.hasOwnProperty("action"))
                        object.action = $root.infinitusai.be.StandardAction.toObject(message.action, options);
                    if (message.utterances && message.utterances.length) {
                        object.utterances = [];
                        for (var j = 0; j < message.utterances.length; ++j)
                            object.utterances[j] = $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.toObject(message.utterances[j], options);
                    }
                    if (message.outputs && message.outputs.length) {
                        object.outputs = [];
                        for (var j = 0; j < message.outputs.length; ++j)
                            object.outputs[j] = $root.infinitusai.be.StandardOutput.toObject(message.outputs[j], options);
                    }
                    if (message.modules && message.modules.length) {
                        object.modules = [];
                        for (var j = 0; j < message.modules.length; ++j)
                            object.modules[j] = message.modules[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ExpandedActionToUtteranceMapping to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedActionToUtteranceMapping.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExpandedActionToUtteranceMapping
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExpandedActionToUtteranceMapping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExpandedActionToUtteranceMapping";
                };
    
                ExpandedActionToUtteranceMapping.UtteranceConditionCouple = (function() {
    
                    /**
                     * Properties of an UtteranceConditionCouple.
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                     * @interface IUtteranceConditionCouple
                     * @property {infinitusai.be.IStandardUtterance|null} [utterance] UtteranceConditionCouple utterance
                     * @property {Array.<infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.INestedConditions>|null} [conditions] UtteranceConditionCouple conditions
                     * @property {number|Long|null} [priority] UtteranceConditionCouple priority
                     * @property {number|Long|null} [id] UtteranceConditionCouple id
                     * @property {boolean|null} [toBeDeleted] UtteranceConditionCouple toBeDeleted
                     */
    
                    /**
                     * Constructs a new UtteranceConditionCouple.
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping
                     * @classdesc Represents an UtteranceConditionCouple.
                     * @implements IUtteranceConditionCouple
                     * @constructor
                     * @param {infinitusai.be.ExpandedActionToUtteranceMapping.IUtteranceConditionCouple=} [properties] Properties to set
                     */
                    function UtteranceConditionCouple(properties) {
                        this.conditions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UtteranceConditionCouple utterance.
                     * @member {infinitusai.be.IStandardUtterance|null|undefined} utterance
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @instance
                     */
                    UtteranceConditionCouple.prototype.utterance = null;
    
                    /**
                     * UtteranceConditionCouple conditions.
                     * @member {Array.<infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.INestedConditions>} conditions
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @instance
                     */
                    UtteranceConditionCouple.prototype.conditions = $util.emptyArray;
    
                    /**
                     * UtteranceConditionCouple priority.
                     * @member {number|Long} priority
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @instance
                     */
                    UtteranceConditionCouple.prototype.priority = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * UtteranceConditionCouple id.
                     * @member {number|Long} id
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @instance
                     */
                    UtteranceConditionCouple.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * UtteranceConditionCouple toBeDeleted.
                     * @member {boolean} toBeDeleted
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @instance
                     */
                    UtteranceConditionCouple.prototype.toBeDeleted = false;
    
                    /**
                     * Creates a new UtteranceConditionCouple instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {infinitusai.be.ExpandedActionToUtteranceMapping.IUtteranceConditionCouple=} [properties] Properties to set
                     * @returns {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple} UtteranceConditionCouple instance
                     */
                    UtteranceConditionCouple.create = function create(properties) {
                        return new UtteranceConditionCouple(properties);
                    };
    
                    /**
                     * Encodes the specified UtteranceConditionCouple message. Does not implicitly {@link infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {infinitusai.be.ExpandedActionToUtteranceMapping.IUtteranceConditionCouple} message UtteranceConditionCouple message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UtteranceConditionCouple.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.utterance != null && Object.hasOwnProperty.call(message, "utterance"))
                            $root.infinitusai.be.StandardUtterance.encode(message.utterance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.conditions != null && message.conditions.length)
                            for (var i = 0; i < message.conditions.length; ++i)
                                $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.encode(message.conditions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.priority);
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.id);
                        if (message.toBeDeleted != null && Object.hasOwnProperty.call(message, "toBeDeleted"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.toBeDeleted);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UtteranceConditionCouple message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {infinitusai.be.ExpandedActionToUtteranceMapping.IUtteranceConditionCouple} message UtteranceConditionCouple message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UtteranceConditionCouple.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UtteranceConditionCouple message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple} UtteranceConditionCouple
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UtteranceConditionCouple.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.utterance = $root.infinitusai.be.StandardUtterance.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    if (!(message.conditions && message.conditions.length))
                                        message.conditions = [];
                                    message.conditions.push($root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.decode(reader, reader.uint32()));
                                    break;
                                }
                            case 3: {
                                    message.priority = reader.int64();
                                    break;
                                }
                            case 4: {
                                    message.id = reader.int64();
                                    break;
                                }
                            case 5: {
                                    message.toBeDeleted = reader.bool();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UtteranceConditionCouple message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple} UtteranceConditionCouple
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UtteranceConditionCouple.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UtteranceConditionCouple message.
                     * @function verify
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UtteranceConditionCouple.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.utterance != null && message.hasOwnProperty("utterance")) {
                            var error = $root.infinitusai.be.StandardUtterance.verify(message.utterance);
                            if (error)
                                return "utterance." + error;
                        }
                        if (message.conditions != null && message.hasOwnProperty("conditions")) {
                            if (!Array.isArray(message.conditions))
                                return "conditions: array expected";
                            for (var i = 0; i < message.conditions.length; ++i) {
                                var error = $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.verify(message.conditions[i]);
                                if (error)
                                    return "conditions." + error;
                            }
                        }
                        if (message.priority != null && message.hasOwnProperty("priority"))
                            if (!$util.isInteger(message.priority) && !(message.priority && $util.isInteger(message.priority.low) && $util.isInteger(message.priority.high)))
                                return "priority: integer|Long expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.toBeDeleted != null && message.hasOwnProperty("toBeDeleted"))
                            if (typeof message.toBeDeleted !== "boolean")
                                return "toBeDeleted: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates an UtteranceConditionCouple message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple} UtteranceConditionCouple
                     */
                    UtteranceConditionCouple.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple)
                            return object;
                        var message = new $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple();
                        if (object.utterance != null) {
                            if (typeof object.utterance !== "object")
                                throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.utterance: object expected");
                            message.utterance = $root.infinitusai.be.StandardUtterance.fromObject(object.utterance);
                        }
                        if (object.conditions) {
                            if (!Array.isArray(object.conditions))
                                throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.conditions: array expected");
                            message.conditions = [];
                            for (var i = 0; i < object.conditions.length; ++i) {
                                if (typeof object.conditions[i] !== "object")
                                    throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.conditions: object expected");
                                message.conditions[i] = $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.fromObject(object.conditions[i]);
                            }
                        }
                        if (object.priority != null)
                            if ($util.Long)
                                (message.priority = $util.Long.fromValue(object.priority)).unsigned = false;
                            else if (typeof object.priority === "string")
                                message.priority = parseInt(object.priority, 10);
                            else if (typeof object.priority === "number")
                                message.priority = object.priority;
                            else if (typeof object.priority === "object")
                                message.priority = new $util.LongBits(object.priority.low >>> 0, object.priority.high >>> 0).toNumber();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.toBeDeleted != null)
                            message.toBeDeleted = Boolean(object.toBeDeleted);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UtteranceConditionCouple message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple} message UtteranceConditionCouple
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UtteranceConditionCouple.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.conditions = [];
                        if (options.defaults) {
                            object.utterance = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.priority = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.priority = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.toBeDeleted = false;
                        }
                        if (message.utterance != null && message.hasOwnProperty("utterance"))
                            object.utterance = $root.infinitusai.be.StandardUtterance.toObject(message.utterance, options);
                        if (message.conditions && message.conditions.length) {
                            object.conditions = [];
                            for (var j = 0; j < message.conditions.length; ++j)
                                object.conditions[j] = $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.toObject(message.conditions[j], options);
                        }
                        if (message.priority != null && message.hasOwnProperty("priority"))
                            if (typeof message.priority === "number")
                                object.priority = options.longs === String ? String(message.priority) : message.priority;
                            else
                                object.priority = options.longs === String ? $util.Long.prototype.toString.call(message.priority) : options.longs === Number ? new $util.LongBits(message.priority.low >>> 0, message.priority.high >>> 0).toNumber() : message.priority;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.toBeDeleted != null && message.hasOwnProperty("toBeDeleted"))
                            object.toBeDeleted = message.toBeDeleted;
                        return object;
                    };
    
                    /**
                     * Converts this UtteranceConditionCouple to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UtteranceConditionCouple.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for UtteranceConditionCouple
                     * @function getTypeUrl
                     * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    UtteranceConditionCouple.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple";
                    };
    
                    UtteranceConditionCouple.NestedConditions = (function() {
    
                        /**
                         * Properties of a NestedConditions.
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                         * @interface INestedConditions
                         * @property {Array.<infinitusai.be.IStandardCondition>|null} [conditions] NestedConditions conditions
                         */
    
                        /**
                         * Constructs a new NestedConditions.
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple
                         * @classdesc Represents a NestedConditions.
                         * @implements INestedConditions
                         * @constructor
                         * @param {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.INestedConditions=} [properties] Properties to set
                         */
                        function NestedConditions(properties) {
                            this.conditions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * NestedConditions conditions.
                         * @member {Array.<infinitusai.be.IStandardCondition>} conditions
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @instance
                         */
                        NestedConditions.prototype.conditions = $util.emptyArray;
    
                        /**
                         * Creates a new NestedConditions instance using the specified properties.
                         * @function create
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.INestedConditions=} [properties] Properties to set
                         * @returns {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions} NestedConditions instance
                         */
                        NestedConditions.create = function create(properties) {
                            return new NestedConditions(properties);
                        };
    
                        /**
                         * Encodes the specified NestedConditions message. Does not implicitly {@link infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.verify|verify} messages.
                         * @function encode
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.INestedConditions} message NestedConditions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NestedConditions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.conditions != null && message.conditions.length)
                                for (var i = 0; i < message.conditions.length; ++i)
                                    $root.infinitusai.be.StandardCondition.encode(message.conditions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };
    
                        /**
                         * Encodes the specified NestedConditions message, length delimited. Does not implicitly {@link infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.INestedConditions} message NestedConditions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NestedConditions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a NestedConditions message from the specified reader or buffer.
                         * @function decode
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions} NestedConditions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NestedConditions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        if (!(message.conditions && message.conditions.length))
                                            message.conditions = [];
                                        message.conditions.push($root.infinitusai.be.StandardCondition.decode(reader, reader.uint32()));
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a NestedConditions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions} NestedConditions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NestedConditions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a NestedConditions message.
                         * @function verify
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NestedConditions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.conditions != null && message.hasOwnProperty("conditions")) {
                                if (!Array.isArray(message.conditions))
                                    return "conditions: array expected";
                                for (var i = 0; i < message.conditions.length; ++i) {
                                    var error = $root.infinitusai.be.StandardCondition.verify(message.conditions[i]);
                                    if (error)
                                        return "conditions." + error;
                                }
                            }
                            return null;
                        };
    
                        /**
                         * Creates a NestedConditions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions} NestedConditions
                         */
                        NestedConditions.fromObject = function fromObject(object) {
                            if (object instanceof $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions)
                                return object;
                            var message = new $root.infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions();
                            if (object.conditions) {
                                if (!Array.isArray(object.conditions))
                                    throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.conditions: array expected");
                                message.conditions = [];
                                for (var i = 0; i < object.conditions.length; ++i) {
                                    if (typeof object.conditions[i] !== "object")
                                        throw TypeError(".infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions.conditions: object expected");
                                    message.conditions[i] = $root.infinitusai.be.StandardCondition.fromObject(object.conditions[i]);
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a NestedConditions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions} message NestedConditions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NestedConditions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.conditions = [];
                            if (message.conditions && message.conditions.length) {
                                object.conditions = [];
                                for (var j = 0; j < message.conditions.length; ++j)
                                    object.conditions[j] = $root.infinitusai.be.StandardCondition.toObject(message.conditions[j], options);
                            }
                            return object;
                        };
    
                        /**
                         * Converts this NestedConditions to JSON.
                         * @function toJSON
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NestedConditions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        /**
                         * Gets the default type url for NestedConditions
                         * @function getTypeUrl
                         * @memberof infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        NestedConditions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/infinitusai.be.ExpandedActionToUtteranceMapping.UtteranceConditionCouple.NestedConditions";
                        };
    
                        return NestedConditions;
                    })();
    
                    return UtteranceConditionCouple;
                })();
    
                return ExpandedActionToUtteranceMapping;
            })();
    
            be.GetExpandedActionToUtteranceMappingsRequest = (function() {
    
                /**
                 * Properties of a GetExpandedActionToUtteranceMappingsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetExpandedActionToUtteranceMappingsRequest
                 * @property {infinitusai.be.IStandardQueryOptions|null} [queryOptions] GetExpandedActionToUtteranceMappingsRequest queryOptions
                 */
    
                /**
                 * Constructs a new GetExpandedActionToUtteranceMappingsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetExpandedActionToUtteranceMappingsRequest.
                 * @implements IGetExpandedActionToUtteranceMappingsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetExpandedActionToUtteranceMappingsRequest=} [properties] Properties to set
                 */
                function GetExpandedActionToUtteranceMappingsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetExpandedActionToUtteranceMappingsRequest queryOptions.
                 * @member {infinitusai.be.IStandardQueryOptions|null|undefined} queryOptions
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @instance
                 */
                GetExpandedActionToUtteranceMappingsRequest.prototype.queryOptions = null;
    
                /**
                 * Creates a new GetExpandedActionToUtteranceMappingsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {infinitusai.be.IGetExpandedActionToUtteranceMappingsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetExpandedActionToUtteranceMappingsRequest} GetExpandedActionToUtteranceMappingsRequest instance
                 */
                GetExpandedActionToUtteranceMappingsRequest.create = function create(properties) {
                    return new GetExpandedActionToUtteranceMappingsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetExpandedActionToUtteranceMappingsRequest message. Does not implicitly {@link infinitusai.be.GetExpandedActionToUtteranceMappingsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {infinitusai.be.IGetExpandedActionToUtteranceMappingsRequest} message GetExpandedActionToUtteranceMappingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetExpandedActionToUtteranceMappingsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queryOptions != null && Object.hasOwnProperty.call(message, "queryOptions"))
                        $root.infinitusai.be.StandardQueryOptions.encode(message.queryOptions, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetExpandedActionToUtteranceMappingsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetExpandedActionToUtteranceMappingsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {infinitusai.be.IGetExpandedActionToUtteranceMappingsRequest} message GetExpandedActionToUtteranceMappingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetExpandedActionToUtteranceMappingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetExpandedActionToUtteranceMappingsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetExpandedActionToUtteranceMappingsRequest} GetExpandedActionToUtteranceMappingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetExpandedActionToUtteranceMappingsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetExpandedActionToUtteranceMappingsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queryOptions = $root.infinitusai.be.StandardQueryOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetExpandedActionToUtteranceMappingsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetExpandedActionToUtteranceMappingsRequest} GetExpandedActionToUtteranceMappingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetExpandedActionToUtteranceMappingsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetExpandedActionToUtteranceMappingsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetExpandedActionToUtteranceMappingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions")) {
                        var error = $root.infinitusai.be.StandardQueryOptions.verify(message.queryOptions);
                        if (error)
                            return "queryOptions." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetExpandedActionToUtteranceMappingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetExpandedActionToUtteranceMappingsRequest} GetExpandedActionToUtteranceMappingsRequest
                 */
                GetExpandedActionToUtteranceMappingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetExpandedActionToUtteranceMappingsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetExpandedActionToUtteranceMappingsRequest();
                    if (object.queryOptions != null) {
                        if (typeof object.queryOptions !== "object")
                            throw TypeError(".infinitusai.be.GetExpandedActionToUtteranceMappingsRequest.queryOptions: object expected");
                        message.queryOptions = $root.infinitusai.be.StandardQueryOptions.fromObject(object.queryOptions);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetExpandedActionToUtteranceMappingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {infinitusai.be.GetExpandedActionToUtteranceMappingsRequest} message GetExpandedActionToUtteranceMappingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetExpandedActionToUtteranceMappingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queryOptions = null;
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions"))
                        object.queryOptions = $root.infinitusai.be.StandardQueryOptions.toObject(message.queryOptions, options);
                    return object;
                };
    
                /**
                 * Converts this GetExpandedActionToUtteranceMappingsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetExpandedActionToUtteranceMappingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetExpandedActionToUtteranceMappingsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetExpandedActionToUtteranceMappingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetExpandedActionToUtteranceMappingsRequest";
                };
    
                return GetExpandedActionToUtteranceMappingsRequest;
            })();
    
            be.GetExpandedActionToUtteranceMappingsResponse = (function() {
    
                /**
                 * Properties of a GetExpandedActionToUtteranceMappingsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetExpandedActionToUtteranceMappingsResponse
                 * @property {Array.<infinitusai.be.IExpandedActionToUtteranceMapping>|null} [actions] GetExpandedActionToUtteranceMappingsResponse actions
                 */
    
                /**
                 * Constructs a new GetExpandedActionToUtteranceMappingsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetExpandedActionToUtteranceMappingsResponse.
                 * @implements IGetExpandedActionToUtteranceMappingsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetExpandedActionToUtteranceMappingsResponse=} [properties] Properties to set
                 */
                function GetExpandedActionToUtteranceMappingsResponse(properties) {
                    this.actions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetExpandedActionToUtteranceMappingsResponse actions.
                 * @member {Array.<infinitusai.be.IExpandedActionToUtteranceMapping>} actions
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @instance
                 */
                GetExpandedActionToUtteranceMappingsResponse.prototype.actions = $util.emptyArray;
    
                /**
                 * Creates a new GetExpandedActionToUtteranceMappingsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {infinitusai.be.IGetExpandedActionToUtteranceMappingsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetExpandedActionToUtteranceMappingsResponse} GetExpandedActionToUtteranceMappingsResponse instance
                 */
                GetExpandedActionToUtteranceMappingsResponse.create = function create(properties) {
                    return new GetExpandedActionToUtteranceMappingsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetExpandedActionToUtteranceMappingsResponse message. Does not implicitly {@link infinitusai.be.GetExpandedActionToUtteranceMappingsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {infinitusai.be.IGetExpandedActionToUtteranceMappingsResponse} message GetExpandedActionToUtteranceMappingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetExpandedActionToUtteranceMappingsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.actions != null && message.actions.length)
                        for (var i = 0; i < message.actions.length; ++i)
                            $root.infinitusai.be.ExpandedActionToUtteranceMapping.encode(message.actions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetExpandedActionToUtteranceMappingsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetExpandedActionToUtteranceMappingsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {infinitusai.be.IGetExpandedActionToUtteranceMappingsResponse} message GetExpandedActionToUtteranceMappingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetExpandedActionToUtteranceMappingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetExpandedActionToUtteranceMappingsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetExpandedActionToUtteranceMappingsResponse} GetExpandedActionToUtteranceMappingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetExpandedActionToUtteranceMappingsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetExpandedActionToUtteranceMappingsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.actions && message.actions.length))
                                    message.actions = [];
                                message.actions.push($root.infinitusai.be.ExpandedActionToUtteranceMapping.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetExpandedActionToUtteranceMappingsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetExpandedActionToUtteranceMappingsResponse} GetExpandedActionToUtteranceMappingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetExpandedActionToUtteranceMappingsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetExpandedActionToUtteranceMappingsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetExpandedActionToUtteranceMappingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.actions != null && message.hasOwnProperty("actions")) {
                        if (!Array.isArray(message.actions))
                            return "actions: array expected";
                        for (var i = 0; i < message.actions.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedActionToUtteranceMapping.verify(message.actions[i]);
                            if (error)
                                return "actions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetExpandedActionToUtteranceMappingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetExpandedActionToUtteranceMappingsResponse} GetExpandedActionToUtteranceMappingsResponse
                 */
                GetExpandedActionToUtteranceMappingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetExpandedActionToUtteranceMappingsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetExpandedActionToUtteranceMappingsResponse();
                    if (object.actions) {
                        if (!Array.isArray(object.actions))
                            throw TypeError(".infinitusai.be.GetExpandedActionToUtteranceMappingsResponse.actions: array expected");
                        message.actions = [];
                        for (var i = 0; i < object.actions.length; ++i) {
                            if (typeof object.actions[i] !== "object")
                                throw TypeError(".infinitusai.be.GetExpandedActionToUtteranceMappingsResponse.actions: object expected");
                            message.actions[i] = $root.infinitusai.be.ExpandedActionToUtteranceMapping.fromObject(object.actions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetExpandedActionToUtteranceMappingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {infinitusai.be.GetExpandedActionToUtteranceMappingsResponse} message GetExpandedActionToUtteranceMappingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetExpandedActionToUtteranceMappingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.actions = [];
                    if (message.actions && message.actions.length) {
                        object.actions = [];
                        for (var j = 0; j < message.actions.length; ++j)
                            object.actions[j] = $root.infinitusai.be.ExpandedActionToUtteranceMapping.toObject(message.actions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetExpandedActionToUtteranceMappingsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetExpandedActionToUtteranceMappingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetExpandedActionToUtteranceMappingsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetExpandedActionToUtteranceMappingsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetExpandedActionToUtteranceMappingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetExpandedActionToUtteranceMappingsResponse";
                };
    
                return GetExpandedActionToUtteranceMappingsResponse;
            })();
    
            be.UpsertExpandedActionToUtteranceMappingRequest = (function() {
    
                /**
                 * Properties of an UpsertExpandedActionToUtteranceMappingRequest.
                 * @memberof infinitusai.be
                 * @interface IUpsertExpandedActionToUtteranceMappingRequest
                 * @property {infinitusai.be.IExpandedActionToUtteranceMapping|null} [action] UpsertExpandedActionToUtteranceMappingRequest action
                 */
    
                /**
                 * Constructs a new UpsertExpandedActionToUtteranceMappingRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertExpandedActionToUtteranceMappingRequest.
                 * @implements IUpsertExpandedActionToUtteranceMappingRequest
                 * @constructor
                 * @param {infinitusai.be.IUpsertExpandedActionToUtteranceMappingRequest=} [properties] Properties to set
                 */
                function UpsertExpandedActionToUtteranceMappingRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertExpandedActionToUtteranceMappingRequest action.
                 * @member {infinitusai.be.IExpandedActionToUtteranceMapping|null|undefined} action
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @instance
                 */
                UpsertExpandedActionToUtteranceMappingRequest.prototype.action = null;
    
                /**
                 * Creates a new UpsertExpandedActionToUtteranceMappingRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {infinitusai.be.IUpsertExpandedActionToUtteranceMappingRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest} UpsertExpandedActionToUtteranceMappingRequest instance
                 */
                UpsertExpandedActionToUtteranceMappingRequest.create = function create(properties) {
                    return new UpsertExpandedActionToUtteranceMappingRequest(properties);
                };
    
                /**
                 * Encodes the specified UpsertExpandedActionToUtteranceMappingRequest message. Does not implicitly {@link infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {infinitusai.be.IUpsertExpandedActionToUtteranceMappingRequest} message UpsertExpandedActionToUtteranceMappingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertExpandedActionToUtteranceMappingRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                        $root.infinitusai.be.ExpandedActionToUtteranceMapping.encode(message.action, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertExpandedActionToUtteranceMappingRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {infinitusai.be.IUpsertExpandedActionToUtteranceMappingRequest} message UpsertExpandedActionToUtteranceMappingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertExpandedActionToUtteranceMappingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertExpandedActionToUtteranceMappingRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest} UpsertExpandedActionToUtteranceMappingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertExpandedActionToUtteranceMappingRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.action = $root.infinitusai.be.ExpandedActionToUtteranceMapping.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertExpandedActionToUtteranceMappingRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest} UpsertExpandedActionToUtteranceMappingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertExpandedActionToUtteranceMappingRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertExpandedActionToUtteranceMappingRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertExpandedActionToUtteranceMappingRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.action != null && message.hasOwnProperty("action")) {
                        var error = $root.infinitusai.be.ExpandedActionToUtteranceMapping.verify(message.action);
                        if (error)
                            return "action." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertExpandedActionToUtteranceMappingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest} UpsertExpandedActionToUtteranceMappingRequest
                 */
                UpsertExpandedActionToUtteranceMappingRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest();
                    if (object.action != null) {
                        if (typeof object.action !== "object")
                            throw TypeError(".infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest.action: object expected");
                        message.action = $root.infinitusai.be.ExpandedActionToUtteranceMapping.fromObject(object.action);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertExpandedActionToUtteranceMappingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest} message UpsertExpandedActionToUtteranceMappingRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertExpandedActionToUtteranceMappingRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.action = null;
                    if (message.action != null && message.hasOwnProperty("action"))
                        object.action = $root.infinitusai.be.ExpandedActionToUtteranceMapping.toObject(message.action, options);
                    return object;
                };
    
                /**
                 * Converts this UpsertExpandedActionToUtteranceMappingRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertExpandedActionToUtteranceMappingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertExpandedActionToUtteranceMappingRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertExpandedActionToUtteranceMappingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest";
                };
    
                return UpsertExpandedActionToUtteranceMappingRequest;
            })();
    
            be.UpsertExpandedActionToUtteranceMappingResponse = (function() {
    
                /**
                 * Properties of an UpsertExpandedActionToUtteranceMappingResponse.
                 * @memberof infinitusai.be
                 * @interface IUpsertExpandedActionToUtteranceMappingResponse
                 * @property {infinitusai.be.IExpandedActionToUtteranceMapping|null} [action] UpsertExpandedActionToUtteranceMappingResponse action
                 */
    
                /**
                 * Constructs a new UpsertExpandedActionToUtteranceMappingResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertExpandedActionToUtteranceMappingResponse.
                 * @implements IUpsertExpandedActionToUtteranceMappingResponse
                 * @constructor
                 * @param {infinitusai.be.IUpsertExpandedActionToUtteranceMappingResponse=} [properties] Properties to set
                 */
                function UpsertExpandedActionToUtteranceMappingResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertExpandedActionToUtteranceMappingResponse action.
                 * @member {infinitusai.be.IExpandedActionToUtteranceMapping|null|undefined} action
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @instance
                 */
                UpsertExpandedActionToUtteranceMappingResponse.prototype.action = null;
    
                /**
                 * Creates a new UpsertExpandedActionToUtteranceMappingResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {infinitusai.be.IUpsertExpandedActionToUtteranceMappingResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse} UpsertExpandedActionToUtteranceMappingResponse instance
                 */
                UpsertExpandedActionToUtteranceMappingResponse.create = function create(properties) {
                    return new UpsertExpandedActionToUtteranceMappingResponse(properties);
                };
    
                /**
                 * Encodes the specified UpsertExpandedActionToUtteranceMappingResponse message. Does not implicitly {@link infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {infinitusai.be.IUpsertExpandedActionToUtteranceMappingResponse} message UpsertExpandedActionToUtteranceMappingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertExpandedActionToUtteranceMappingResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                        $root.infinitusai.be.ExpandedActionToUtteranceMapping.encode(message.action, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertExpandedActionToUtteranceMappingResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {infinitusai.be.IUpsertExpandedActionToUtteranceMappingResponse} message UpsertExpandedActionToUtteranceMappingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertExpandedActionToUtteranceMappingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertExpandedActionToUtteranceMappingResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse} UpsertExpandedActionToUtteranceMappingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertExpandedActionToUtteranceMappingResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.action = $root.infinitusai.be.ExpandedActionToUtteranceMapping.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertExpandedActionToUtteranceMappingResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse} UpsertExpandedActionToUtteranceMappingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertExpandedActionToUtteranceMappingResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertExpandedActionToUtteranceMappingResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertExpandedActionToUtteranceMappingResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.action != null && message.hasOwnProperty("action")) {
                        var error = $root.infinitusai.be.ExpandedActionToUtteranceMapping.verify(message.action);
                        if (error)
                            return "action." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertExpandedActionToUtteranceMappingResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse} UpsertExpandedActionToUtteranceMappingResponse
                 */
                UpsertExpandedActionToUtteranceMappingResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse)
                        return object;
                    var message = new $root.infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse();
                    if (object.action != null) {
                        if (typeof object.action !== "object")
                            throw TypeError(".infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse.action: object expected");
                        message.action = $root.infinitusai.be.ExpandedActionToUtteranceMapping.fromObject(object.action);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertExpandedActionToUtteranceMappingResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse} message UpsertExpandedActionToUtteranceMappingResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertExpandedActionToUtteranceMappingResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.action = null;
                    if (message.action != null && message.hasOwnProperty("action"))
                        object.action = $root.infinitusai.be.ExpandedActionToUtteranceMapping.toObject(message.action, options);
                    return object;
                };
    
                /**
                 * Converts this UpsertExpandedActionToUtteranceMappingResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertExpandedActionToUtteranceMappingResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertExpandedActionToUtteranceMappingResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertExpandedActionToUtteranceMappingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse";
                };
    
                return UpsertExpandedActionToUtteranceMappingResponse;
            })();
    
            be.GetStandardUtterancesRequest = (function() {
    
                /**
                 * Properties of a GetStandardUtterancesRequest.
                 * @memberof infinitusai.be
                 * @interface IGetStandardUtterancesRequest
                 * @property {infinitusai.be.IStandardQueryOptions|null} [queryOptions] GetStandardUtterancesRequest queryOptions
                 */
    
                /**
                 * Constructs a new GetStandardUtterancesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetStandardUtterancesRequest.
                 * @implements IGetStandardUtterancesRequest
                 * @constructor
                 * @param {infinitusai.be.IGetStandardUtterancesRequest=} [properties] Properties to set
                 */
                function GetStandardUtterancesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetStandardUtterancesRequest queryOptions.
                 * @member {infinitusai.be.IStandardQueryOptions|null|undefined} queryOptions
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @instance
                 */
                GetStandardUtterancesRequest.prototype.queryOptions = null;
    
                /**
                 * Creates a new GetStandardUtterancesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardUtterancesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetStandardUtterancesRequest} GetStandardUtterancesRequest instance
                 */
                GetStandardUtterancesRequest.create = function create(properties) {
                    return new GetStandardUtterancesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetStandardUtterancesRequest message. Does not implicitly {@link infinitusai.be.GetStandardUtterancesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardUtterancesRequest} message GetStandardUtterancesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardUtterancesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queryOptions != null && Object.hasOwnProperty.call(message, "queryOptions"))
                        $root.infinitusai.be.StandardQueryOptions.encode(message.queryOptions, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetStandardUtterancesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetStandardUtterancesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {infinitusai.be.IGetStandardUtterancesRequest} message GetStandardUtterancesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardUtterancesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetStandardUtterancesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetStandardUtterancesRequest} GetStandardUtterancesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardUtterancesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetStandardUtterancesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queryOptions = $root.infinitusai.be.StandardQueryOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetStandardUtterancesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetStandardUtterancesRequest} GetStandardUtterancesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardUtterancesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetStandardUtterancesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStandardUtterancesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions")) {
                        var error = $root.infinitusai.be.StandardQueryOptions.verify(message.queryOptions);
                        if (error)
                            return "queryOptions." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetStandardUtterancesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetStandardUtterancesRequest} GetStandardUtterancesRequest
                 */
                GetStandardUtterancesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetStandardUtterancesRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetStandardUtterancesRequest();
                    if (object.queryOptions != null) {
                        if (typeof object.queryOptions !== "object")
                            throw TypeError(".infinitusai.be.GetStandardUtterancesRequest.queryOptions: object expected");
                        message.queryOptions = $root.infinitusai.be.StandardQueryOptions.fromObject(object.queryOptions);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetStandardUtterancesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {infinitusai.be.GetStandardUtterancesRequest} message GetStandardUtterancesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStandardUtterancesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queryOptions = null;
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions"))
                        object.queryOptions = $root.infinitusai.be.StandardQueryOptions.toObject(message.queryOptions, options);
                    return object;
                };
    
                /**
                 * Converts this GetStandardUtterancesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStandardUtterancesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetStandardUtterancesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetStandardUtterancesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetStandardUtterancesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetStandardUtterancesRequest";
                };
    
                return GetStandardUtterancesRequest;
            })();
    
            be.GetStandardUtterancesResponse = (function() {
    
                /**
                 * Properties of a GetStandardUtterancesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetStandardUtterancesResponse
                 * @property {Array.<infinitusai.be.IStandardUtterance>|null} [utterances] GetStandardUtterancesResponse utterances
                 */
    
                /**
                 * Constructs a new GetStandardUtterancesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetStandardUtterancesResponse.
                 * @implements IGetStandardUtterancesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetStandardUtterancesResponse=} [properties] Properties to set
                 */
                function GetStandardUtterancesResponse(properties) {
                    this.utterances = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetStandardUtterancesResponse utterances.
                 * @member {Array.<infinitusai.be.IStandardUtterance>} utterances
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @instance
                 */
                GetStandardUtterancesResponse.prototype.utterances = $util.emptyArray;
    
                /**
                 * Creates a new GetStandardUtterancesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardUtterancesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetStandardUtterancesResponse} GetStandardUtterancesResponse instance
                 */
                GetStandardUtterancesResponse.create = function create(properties) {
                    return new GetStandardUtterancesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetStandardUtterancesResponse message. Does not implicitly {@link infinitusai.be.GetStandardUtterancesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardUtterancesResponse} message GetStandardUtterancesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardUtterancesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.utterances != null && message.utterances.length)
                        for (var i = 0; i < message.utterances.length; ++i)
                            $root.infinitusai.be.StandardUtterance.encode(message.utterances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetStandardUtterancesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetStandardUtterancesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {infinitusai.be.IGetStandardUtterancesResponse} message GetStandardUtterancesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStandardUtterancesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetStandardUtterancesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetStandardUtterancesResponse} GetStandardUtterancesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardUtterancesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetStandardUtterancesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.utterances && message.utterances.length))
                                    message.utterances = [];
                                message.utterances.push($root.infinitusai.be.StandardUtterance.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetStandardUtterancesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetStandardUtterancesResponse} GetStandardUtterancesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStandardUtterancesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetStandardUtterancesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStandardUtterancesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.utterances != null && message.hasOwnProperty("utterances")) {
                        if (!Array.isArray(message.utterances))
                            return "utterances: array expected";
                        for (var i = 0; i < message.utterances.length; ++i) {
                            var error = $root.infinitusai.be.StandardUtterance.verify(message.utterances[i]);
                            if (error)
                                return "utterances." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetStandardUtterancesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetStandardUtterancesResponse} GetStandardUtterancesResponse
                 */
                GetStandardUtterancesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetStandardUtterancesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetStandardUtterancesResponse();
                    if (object.utterances) {
                        if (!Array.isArray(object.utterances))
                            throw TypeError(".infinitusai.be.GetStandardUtterancesResponse.utterances: array expected");
                        message.utterances = [];
                        for (var i = 0; i < object.utterances.length; ++i) {
                            if (typeof object.utterances[i] !== "object")
                                throw TypeError(".infinitusai.be.GetStandardUtterancesResponse.utterances: object expected");
                            message.utterances[i] = $root.infinitusai.be.StandardUtterance.fromObject(object.utterances[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetStandardUtterancesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {infinitusai.be.GetStandardUtterancesResponse} message GetStandardUtterancesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStandardUtterancesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.utterances = [];
                    if (message.utterances && message.utterances.length) {
                        object.utterances = [];
                        for (var j = 0; j < message.utterances.length; ++j)
                            object.utterances[j] = $root.infinitusai.be.StandardUtterance.toObject(message.utterances[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetStandardUtterancesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStandardUtterancesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetStandardUtterancesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetStandardUtterancesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetStandardUtterancesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetStandardUtterancesResponse";
                };
    
                return GetStandardUtterancesResponse;
            })();
    
            be.CSVSpec = (function() {
    
                /**
                 * Properties of a CSVSpec.
                 * @memberof infinitusai.be
                 * @interface ICSVSpec
                 * @property {Array.<infinitusai.be.CSVSpec.IField>|null} [fields] CSVSpec fields
                 */
    
                /**
                 * Constructs a new CSVSpec.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CSVSpec.
                 * @implements ICSVSpec
                 * @constructor
                 * @param {infinitusai.be.ICSVSpec=} [properties] Properties to set
                 */
                function CSVSpec(properties) {
                    this.fields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CSVSpec fields.
                 * @member {Array.<infinitusai.be.CSVSpec.IField>} fields
                 * @memberof infinitusai.be.CSVSpec
                 * @instance
                 */
                CSVSpec.prototype.fields = $util.emptyArray;
    
                /**
                 * Creates a new CSVSpec instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {infinitusai.be.ICSVSpec=} [properties] Properties to set
                 * @returns {infinitusai.be.CSVSpec} CSVSpec instance
                 */
                CSVSpec.create = function create(properties) {
                    return new CSVSpec(properties);
                };
    
                /**
                 * Encodes the specified CSVSpec message. Does not implicitly {@link infinitusai.be.CSVSpec.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {infinitusai.be.ICSVSpec} message CSVSpec message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CSVSpec.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fields != null && message.fields.length)
                        for (var i = 0; i < message.fields.length; ++i)
                            $root.infinitusai.be.CSVSpec.Field.encode(message.fields[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CSVSpec message, length delimited. Does not implicitly {@link infinitusai.be.CSVSpec.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {infinitusai.be.ICSVSpec} message CSVSpec message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CSVSpec.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CSVSpec message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CSVSpec} CSVSpec
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CSVSpec.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CSVSpec();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.fields && message.fields.length))
                                    message.fields = [];
                                message.fields.push($root.infinitusai.be.CSVSpec.Field.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CSVSpec message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CSVSpec} CSVSpec
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CSVSpec.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CSVSpec message.
                 * @function verify
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CSVSpec.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fields != null && message.hasOwnProperty("fields")) {
                        if (!Array.isArray(message.fields))
                            return "fields: array expected";
                        for (var i = 0; i < message.fields.length; ++i) {
                            var error = $root.infinitusai.be.CSVSpec.Field.verify(message.fields[i]);
                            if (error)
                                return "fields." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a CSVSpec message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CSVSpec} CSVSpec
                 */
                CSVSpec.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CSVSpec)
                        return object;
                    var message = new $root.infinitusai.be.CSVSpec();
                    if (object.fields) {
                        if (!Array.isArray(object.fields))
                            throw TypeError(".infinitusai.be.CSVSpec.fields: array expected");
                        message.fields = [];
                        for (var i = 0; i < object.fields.length; ++i) {
                            if (typeof object.fields[i] !== "object")
                                throw TypeError(".infinitusai.be.CSVSpec.fields: object expected");
                            message.fields[i] = $root.infinitusai.be.CSVSpec.Field.fromObject(object.fields[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CSVSpec message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {infinitusai.be.CSVSpec} message CSVSpec
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CSVSpec.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fields = [];
                    if (message.fields && message.fields.length) {
                        object.fields = [];
                        for (var j = 0; j < message.fields.length; ++j)
                            object.fields[j] = $root.infinitusai.be.CSVSpec.Field.toObject(message.fields[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this CSVSpec to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CSVSpec
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CSVSpec.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CSVSpec
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CSVSpec
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CSVSpec.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CSVSpec";
                };
    
                CSVSpec.Field = (function() {
    
                    /**
                     * Properties of a Field.
                     * @memberof infinitusai.be.CSVSpec
                     * @interface IField
                     * @property {string|null} [protoPath] Field protoPath
                     * @property {string|null} [csvColumn] Field csvColumn
                     * @property {infinitusai.be.CSVSpec.Field.IColumnTemplate|null} [csvColumnTemplate] Field csvColumnTemplate
                     * @property {boolean|null} [required] Field required
                     * @property {string|null} [description] Field description
                     * @property {string|null} [example] Field example
                     * @property {Object.<string,infinitusai.be.CSVSpec.Field.IRepeatedIndex>|null} [repeatedFieldIdx] Field repeatedFieldIdx
                     * @property {Array.<string>|null} [allowedValues] Field allowedValues
                     * @property {string|null} [type] Field type
                     */
    
                    /**
                     * Constructs a new Field.
                     * @memberof infinitusai.be.CSVSpec
                     * @classdesc Represents a Field.
                     * @implements IField
                     * @constructor
                     * @param {infinitusai.be.CSVSpec.IField=} [properties] Properties to set
                     */
                    function Field(properties) {
                        this.repeatedFieldIdx = {};
                        this.allowedValues = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Field protoPath.
                     * @member {string} protoPath
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.protoPath = "";
    
                    /**
                     * Field csvColumn.
                     * @member {string|null|undefined} csvColumn
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.csvColumn = null;
    
                    /**
                     * Field csvColumnTemplate.
                     * @member {infinitusai.be.CSVSpec.Field.IColumnTemplate|null|undefined} csvColumnTemplate
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.csvColumnTemplate = null;
    
                    /**
                     * Field required.
                     * @member {boolean} required
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.required = false;
    
                    /**
                     * Field description.
                     * @member {string} description
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.description = "";
    
                    /**
                     * Field example.
                     * @member {string} example
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.example = "";
    
                    /**
                     * Field repeatedFieldIdx.
                     * @member {Object.<string,infinitusai.be.CSVSpec.Field.IRepeatedIndex>} repeatedFieldIdx
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.repeatedFieldIdx = $util.emptyObject;
    
                    /**
                     * Field allowedValues.
                     * @member {Array.<string>} allowedValues
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.allowedValues = $util.emptyArray;
    
                    /**
                     * Field type.
                     * @member {string} type
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Field.prototype.type = "";
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * Field column.
                     * @member {"csvColumn"|"csvColumnTemplate"|undefined} column
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     */
                    Object.defineProperty(Field.prototype, "column", {
                        get: $util.oneOfGetter($oneOfFields = ["csvColumn", "csvColumnTemplate"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new Field instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {infinitusai.be.CSVSpec.IField=} [properties] Properties to set
                     * @returns {infinitusai.be.CSVSpec.Field} Field instance
                     */
                    Field.create = function create(properties) {
                        return new Field(properties);
                    };
    
                    /**
                     * Encodes the specified Field message. Does not implicitly {@link infinitusai.be.CSVSpec.Field.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {infinitusai.be.CSVSpec.IField} message Field message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.protoPath != null && Object.hasOwnProperty.call(message, "protoPath"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.protoPath);
                        if (message.csvColumn != null && Object.hasOwnProperty.call(message, "csvColumn"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.csvColumn);
                        if (message.csvColumnTemplate != null && Object.hasOwnProperty.call(message, "csvColumnTemplate"))
                            $root.infinitusai.be.CSVSpec.Field.ColumnTemplate.encode(message.csvColumnTemplate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.required);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                        if (message.example != null && Object.hasOwnProperty.call(message, "example"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.example);
                        if (message.repeatedFieldIdx != null && Object.hasOwnProperty.call(message, "repeatedFieldIdx"))
                            for (var keys = Object.keys(message.repeatedFieldIdx), i = 0; i < keys.length; ++i) {
                                writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                $root.infinitusai.be.CSVSpec.Field.RepeatedIndex.encode(message.repeatedFieldIdx[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                            }
                        if (message.allowedValues != null && message.allowedValues.length)
                            for (var i = 0; i < message.allowedValues.length; ++i)
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.allowedValues[i]);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.type);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Field message, length delimited. Does not implicitly {@link infinitusai.be.CSVSpec.Field.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {infinitusai.be.CSVSpec.IField} message Field message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Field message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.CSVSpec.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CSVSpec.Field(), key, value;
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.protoPath = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.csvColumn = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.csvColumnTemplate = $root.infinitusai.be.CSVSpec.Field.ColumnTemplate.decode(reader, reader.uint32());
                                    break;
                                }
                            case 4: {
                                    message.required = reader.bool();
                                    break;
                                }
                            case 5: {
                                    message.description = reader.string();
                                    break;
                                }
                            case 6: {
                                    message.example = reader.string();
                                    break;
                                }
                            case 7: {
                                    if (message.repeatedFieldIdx === $util.emptyObject)
                                        message.repeatedFieldIdx = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.infinitusai.be.CSVSpec.Field.RepeatedIndex.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.repeatedFieldIdx[key] = value;
                                    break;
                                }
                            case 8: {
                                    if (!(message.allowedValues && message.allowedValues.length))
                                        message.allowedValues = [];
                                    message.allowedValues.push(reader.string());
                                    break;
                                }
                            case 9: {
                                    message.type = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Field message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.CSVSpec.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Field message.
                     * @function verify
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Field.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.protoPath != null && message.hasOwnProperty("protoPath"))
                            if (!$util.isString(message.protoPath))
                                return "protoPath: string expected";
                        if (message.csvColumn != null && message.hasOwnProperty("csvColumn")) {
                            properties.column = 1;
                            if (!$util.isString(message.csvColumn))
                                return "csvColumn: string expected";
                        }
                        if (message.csvColumnTemplate != null && message.hasOwnProperty("csvColumnTemplate")) {
                            if (properties.column === 1)
                                return "column: multiple values";
                            properties.column = 1;
                            {
                                var error = $root.infinitusai.be.CSVSpec.Field.ColumnTemplate.verify(message.csvColumnTemplate);
                                if (error)
                                    return "csvColumnTemplate." + error;
                            }
                        }
                        if (message.required != null && message.hasOwnProperty("required"))
                            if (typeof message.required !== "boolean")
                                return "required: boolean expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.example != null && message.hasOwnProperty("example"))
                            if (!$util.isString(message.example))
                                return "example: string expected";
                        if (message.repeatedFieldIdx != null && message.hasOwnProperty("repeatedFieldIdx")) {
                            if (!$util.isObject(message.repeatedFieldIdx))
                                return "repeatedFieldIdx: object expected";
                            var key = Object.keys(message.repeatedFieldIdx);
                            for (var i = 0; i < key.length; ++i) {
                                var error = $root.infinitusai.be.CSVSpec.Field.RepeatedIndex.verify(message.repeatedFieldIdx[key[i]]);
                                if (error)
                                    return "repeatedFieldIdx." + error;
                            }
                        }
                        if (message.allowedValues != null && message.hasOwnProperty("allowedValues")) {
                            if (!Array.isArray(message.allowedValues))
                                return "allowedValues: array expected";
                            for (var i = 0; i < message.allowedValues.length; ++i)
                                if (!$util.isString(message.allowedValues[i]))
                                    return "allowedValues: string[] expected";
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            if (!$util.isString(message.type))
                                return "type: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a Field message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.CSVSpec.Field} Field
                     */
                    Field.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.CSVSpec.Field)
                            return object;
                        var message = new $root.infinitusai.be.CSVSpec.Field();
                        if (object.protoPath != null)
                            message.protoPath = String(object.protoPath);
                        if (object.csvColumn != null)
                            message.csvColumn = String(object.csvColumn);
                        if (object.csvColumnTemplate != null) {
                            if (typeof object.csvColumnTemplate !== "object")
                                throw TypeError(".infinitusai.be.CSVSpec.Field.csvColumnTemplate: object expected");
                            message.csvColumnTemplate = $root.infinitusai.be.CSVSpec.Field.ColumnTemplate.fromObject(object.csvColumnTemplate);
                        }
                        if (object.required != null)
                            message.required = Boolean(object.required);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.example != null)
                            message.example = String(object.example);
                        if (object.repeatedFieldIdx) {
                            if (typeof object.repeatedFieldIdx !== "object")
                                throw TypeError(".infinitusai.be.CSVSpec.Field.repeatedFieldIdx: object expected");
                            message.repeatedFieldIdx = {};
                            for (var keys = Object.keys(object.repeatedFieldIdx), i = 0; i < keys.length; ++i) {
                                if (typeof object.repeatedFieldIdx[keys[i]] !== "object")
                                    throw TypeError(".infinitusai.be.CSVSpec.Field.repeatedFieldIdx: object expected");
                                message.repeatedFieldIdx[keys[i]] = $root.infinitusai.be.CSVSpec.Field.RepeatedIndex.fromObject(object.repeatedFieldIdx[keys[i]]);
                            }
                        }
                        if (object.allowedValues) {
                            if (!Array.isArray(object.allowedValues))
                                throw TypeError(".infinitusai.be.CSVSpec.Field.allowedValues: array expected");
                            message.allowedValues = [];
                            for (var i = 0; i < object.allowedValues.length; ++i)
                                message.allowedValues[i] = String(object.allowedValues[i]);
                        }
                        if (object.type != null)
                            message.type = String(object.type);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Field message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {infinitusai.be.CSVSpec.Field} message Field
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Field.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.allowedValues = [];
                        if (options.objects || options.defaults)
                            object.repeatedFieldIdx = {};
                        if (options.defaults) {
                            object.protoPath = "";
                            object.required = false;
                            object.description = "";
                            object.example = "";
                            object.type = "";
                        }
                        if (message.protoPath != null && message.hasOwnProperty("protoPath"))
                            object.protoPath = message.protoPath;
                        if (message.csvColumn != null && message.hasOwnProperty("csvColumn")) {
                            object.csvColumn = message.csvColumn;
                            if (options.oneofs)
                                object.column = "csvColumn";
                        }
                        if (message.csvColumnTemplate != null && message.hasOwnProperty("csvColumnTemplate")) {
                            object.csvColumnTemplate = $root.infinitusai.be.CSVSpec.Field.ColumnTemplate.toObject(message.csvColumnTemplate, options);
                            if (options.oneofs)
                                object.column = "csvColumnTemplate";
                        }
                        if (message.required != null && message.hasOwnProperty("required"))
                            object.required = message.required;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.example != null && message.hasOwnProperty("example"))
                            object.example = message.example;
                        var keys2;
                        if (message.repeatedFieldIdx && (keys2 = Object.keys(message.repeatedFieldIdx)).length) {
                            object.repeatedFieldIdx = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.repeatedFieldIdx[keys2[j]] = $root.infinitusai.be.CSVSpec.Field.RepeatedIndex.toObject(message.repeatedFieldIdx[keys2[j]], options);
                        }
                        if (message.allowedValues && message.allowedValues.length) {
                            object.allowedValues = [];
                            for (var j = 0; j < message.allowedValues.length; ++j)
                                object.allowedValues[j] = message.allowedValues[j];
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = message.type;
                        return object;
                    };
    
                    /**
                     * Converts this Field to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Field.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Field
                     * @function getTypeUrl
                     * @memberof infinitusai.be.CSVSpec.Field
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Field.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.CSVSpec.Field";
                    };
    
                    Field.ColumnTemplate = (function() {
    
                        /**
                         * Properties of a ColumnTemplate.
                         * @memberof infinitusai.be.CSVSpec.Field
                         * @interface IColumnTemplate
                         * @property {string|null} [regex] ColumnTemplate regex
                         * @property {string|null} [example] ColumnTemplate example
                         */
    
                        /**
                         * Constructs a new ColumnTemplate.
                         * @memberof infinitusai.be.CSVSpec.Field
                         * @classdesc Represents a ColumnTemplate.
                         * @implements IColumnTemplate
                         * @constructor
                         * @param {infinitusai.be.CSVSpec.Field.IColumnTemplate=} [properties] Properties to set
                         */
                        function ColumnTemplate(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * ColumnTemplate regex.
                         * @member {string} regex
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @instance
                         */
                        ColumnTemplate.prototype.regex = "";
    
                        /**
                         * ColumnTemplate example.
                         * @member {string} example
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @instance
                         */
                        ColumnTemplate.prototype.example = "";
    
                        /**
                         * Creates a new ColumnTemplate instance using the specified properties.
                         * @function create
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {infinitusai.be.CSVSpec.Field.IColumnTemplate=} [properties] Properties to set
                         * @returns {infinitusai.be.CSVSpec.Field.ColumnTemplate} ColumnTemplate instance
                         */
                        ColumnTemplate.create = function create(properties) {
                            return new ColumnTemplate(properties);
                        };
    
                        /**
                         * Encodes the specified ColumnTemplate message. Does not implicitly {@link infinitusai.be.CSVSpec.Field.ColumnTemplate.verify|verify} messages.
                         * @function encode
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {infinitusai.be.CSVSpec.Field.IColumnTemplate} message ColumnTemplate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ColumnTemplate.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.regex != null && Object.hasOwnProperty.call(message, "regex"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.regex);
                            if (message.example != null && Object.hasOwnProperty.call(message, "example"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.example);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified ColumnTemplate message, length delimited. Does not implicitly {@link infinitusai.be.CSVSpec.Field.ColumnTemplate.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {infinitusai.be.CSVSpec.Field.IColumnTemplate} message ColumnTemplate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ColumnTemplate.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a ColumnTemplate message from the specified reader or buffer.
                         * @function decode
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {infinitusai.be.CSVSpec.Field.ColumnTemplate} ColumnTemplate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ColumnTemplate.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CSVSpec.Field.ColumnTemplate();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.regex = reader.string();
                                        break;
                                    }
                                case 2: {
                                        message.example = reader.string();
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a ColumnTemplate message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {infinitusai.be.CSVSpec.Field.ColumnTemplate} ColumnTemplate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ColumnTemplate.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a ColumnTemplate message.
                         * @function verify
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ColumnTemplate.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.regex != null && message.hasOwnProperty("regex"))
                                if (!$util.isString(message.regex))
                                    return "regex: string expected";
                            if (message.example != null && message.hasOwnProperty("example"))
                                if (!$util.isString(message.example))
                                    return "example: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a ColumnTemplate message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {infinitusai.be.CSVSpec.Field.ColumnTemplate} ColumnTemplate
                         */
                        ColumnTemplate.fromObject = function fromObject(object) {
                            if (object instanceof $root.infinitusai.be.CSVSpec.Field.ColumnTemplate)
                                return object;
                            var message = new $root.infinitusai.be.CSVSpec.Field.ColumnTemplate();
                            if (object.regex != null)
                                message.regex = String(object.regex);
                            if (object.example != null)
                                message.example = String(object.example);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a ColumnTemplate message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {infinitusai.be.CSVSpec.Field.ColumnTemplate} message ColumnTemplate
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ColumnTemplate.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.regex = "";
                                object.example = "";
                            }
                            if (message.regex != null && message.hasOwnProperty("regex"))
                                object.regex = message.regex;
                            if (message.example != null && message.hasOwnProperty("example"))
                                object.example = message.example;
                            return object;
                        };
    
                        /**
                         * Converts this ColumnTemplate to JSON.
                         * @function toJSON
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ColumnTemplate.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        /**
                         * Gets the default type url for ColumnTemplate
                         * @function getTypeUrl
                         * @memberof infinitusai.be.CSVSpec.Field.ColumnTemplate
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        ColumnTemplate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/infinitusai.be.CSVSpec.Field.ColumnTemplate";
                        };
    
                        return ColumnTemplate;
                    })();
    
                    Field.RepeatedIndex = (function() {
    
                        /**
                         * Properties of a RepeatedIndex.
                         * @memberof infinitusai.be.CSVSpec.Field
                         * @interface IRepeatedIndex
                         * @property {number|null} [fixedIndex] RepeatedIndex fixedIndex
                         * @property {string|null} [fromRegexGroup] RepeatedIndex fromRegexGroup
                         */
    
                        /**
                         * Constructs a new RepeatedIndex.
                         * @memberof infinitusai.be.CSVSpec.Field
                         * @classdesc Represents a RepeatedIndex.
                         * @implements IRepeatedIndex
                         * @constructor
                         * @param {infinitusai.be.CSVSpec.Field.IRepeatedIndex=} [properties] Properties to set
                         */
                        function RepeatedIndex(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * RepeatedIndex fixedIndex.
                         * @member {number} fixedIndex
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @instance
                         */
                        RepeatedIndex.prototype.fixedIndex = 0;
    
                        /**
                         * RepeatedIndex fromRegexGroup.
                         * @member {string} fromRegexGroup
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @instance
                         */
                        RepeatedIndex.prototype.fromRegexGroup = "";
    
                        /**
                         * Creates a new RepeatedIndex instance using the specified properties.
                         * @function create
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {infinitusai.be.CSVSpec.Field.IRepeatedIndex=} [properties] Properties to set
                         * @returns {infinitusai.be.CSVSpec.Field.RepeatedIndex} RepeatedIndex instance
                         */
                        RepeatedIndex.create = function create(properties) {
                            return new RepeatedIndex(properties);
                        };
    
                        /**
                         * Encodes the specified RepeatedIndex message. Does not implicitly {@link infinitusai.be.CSVSpec.Field.RepeatedIndex.verify|verify} messages.
                         * @function encode
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {infinitusai.be.CSVSpec.Field.IRepeatedIndex} message RepeatedIndex message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RepeatedIndex.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.fixedIndex != null && Object.hasOwnProperty.call(message, "fixedIndex"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.fixedIndex);
                            if (message.fromRegexGroup != null && Object.hasOwnProperty.call(message, "fromRegexGroup"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fromRegexGroup);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified RepeatedIndex message, length delimited. Does not implicitly {@link infinitusai.be.CSVSpec.Field.RepeatedIndex.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {infinitusai.be.CSVSpec.Field.IRepeatedIndex} message RepeatedIndex message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RepeatedIndex.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a RepeatedIndex message from the specified reader or buffer.
                         * @function decode
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {infinitusai.be.CSVSpec.Field.RepeatedIndex} RepeatedIndex
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RepeatedIndex.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CSVSpec.Field.RepeatedIndex();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.fixedIndex = reader.int32();
                                        break;
                                    }
                                case 2: {
                                        message.fromRegexGroup = reader.string();
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a RepeatedIndex message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {infinitusai.be.CSVSpec.Field.RepeatedIndex} RepeatedIndex
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RepeatedIndex.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a RepeatedIndex message.
                         * @function verify
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        RepeatedIndex.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.fixedIndex != null && message.hasOwnProperty("fixedIndex"))
                                if (!$util.isInteger(message.fixedIndex))
                                    return "fixedIndex: integer expected";
                            if (message.fromRegexGroup != null && message.hasOwnProperty("fromRegexGroup"))
                                if (!$util.isString(message.fromRegexGroup))
                                    return "fromRegexGroup: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a RepeatedIndex message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {infinitusai.be.CSVSpec.Field.RepeatedIndex} RepeatedIndex
                         */
                        RepeatedIndex.fromObject = function fromObject(object) {
                            if (object instanceof $root.infinitusai.be.CSVSpec.Field.RepeatedIndex)
                                return object;
                            var message = new $root.infinitusai.be.CSVSpec.Field.RepeatedIndex();
                            if (object.fixedIndex != null)
                                message.fixedIndex = object.fixedIndex | 0;
                            if (object.fromRegexGroup != null)
                                message.fromRegexGroup = String(object.fromRegexGroup);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a RepeatedIndex message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {infinitusai.be.CSVSpec.Field.RepeatedIndex} message RepeatedIndex
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        RepeatedIndex.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.fixedIndex = 0;
                                object.fromRegexGroup = "";
                            }
                            if (message.fixedIndex != null && message.hasOwnProperty("fixedIndex"))
                                object.fixedIndex = message.fixedIndex;
                            if (message.fromRegexGroup != null && message.hasOwnProperty("fromRegexGroup"))
                                object.fromRegexGroup = message.fromRegexGroup;
                            return object;
                        };
    
                        /**
                         * Converts this RepeatedIndex to JSON.
                         * @function toJSON
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        RepeatedIndex.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        /**
                         * Gets the default type url for RepeatedIndex
                         * @function getTypeUrl
                         * @memberof infinitusai.be.CSVSpec.Field.RepeatedIndex
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        RepeatedIndex.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/infinitusai.be.CSVSpec.Field.RepeatedIndex";
                        };
    
                        return RepeatedIndex;
                    })();
    
                    return Field;
                })();
    
                return CSVSpec;
            })();
    
            be.GetCSVSpecRequest = (function() {
    
                /**
                 * Properties of a GetCSVSpecRequest.
                 * @memberof infinitusai.be
                 * @interface IGetCSVSpecRequest
                 * @property {infinitusapi.INFTaskType|null} [taskType] GetCSVSpecRequest taskType
                 */
    
                /**
                 * Constructs a new GetCSVSpecRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCSVSpecRequest.
                 * @implements IGetCSVSpecRequest
                 * @constructor
                 * @param {infinitusai.be.IGetCSVSpecRequest=} [properties] Properties to set
                 */
                function GetCSVSpecRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCSVSpecRequest taskType.
                 * @member {infinitusapi.INFTaskType} taskType
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @instance
                 */
                GetCSVSpecRequest.prototype.taskType = 0;
    
                /**
                 * Creates a new GetCSVSpecRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {infinitusai.be.IGetCSVSpecRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCSVSpecRequest} GetCSVSpecRequest instance
                 */
                GetCSVSpecRequest.create = function create(properties) {
                    return new GetCSVSpecRequest(properties);
                };
    
                /**
                 * Encodes the specified GetCSVSpecRequest message. Does not implicitly {@link infinitusai.be.GetCSVSpecRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {infinitusai.be.IGetCSVSpecRequest} message GetCSVSpecRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVSpecRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCSVSpecRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetCSVSpecRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {infinitusai.be.IGetCSVSpecRequest} message GetCSVSpecRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVSpecRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCSVSpecRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCSVSpecRequest} GetCSVSpecRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVSpecRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCSVSpecRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCSVSpecRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCSVSpecRequest} GetCSVSpecRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVSpecRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCSVSpecRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCSVSpecRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GetCSVSpecRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCSVSpecRequest} GetCSVSpecRequest
                 */
                GetCSVSpecRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCSVSpecRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetCSVSpecRequest();
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "INF_TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "INF_TASK_TYPE_BENEFITS_VERIFICATION":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "INF_TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "INF_TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "INF_TASK_TYPE_PATIENT_OUTREACH":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "INF_TASK_TYPE_PROVIDER_OUTREACH":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_MM":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_PBM":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "INF_TASK_TYPE_BASIC_BV":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "INF_TASK_TYPE_PBM_DISCOVERY":
                    case 18:
                        message.taskType = 18;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCSVSpecRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {infinitusai.be.GetCSVSpecRequest} message GetCSVSpecRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCSVSpecRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskType = options.enums === String ? "INF_TASK_TYPE_UNKNOWN" : 0;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusapi.INFTaskType[message.taskType] === undefined ? message.taskType : $root.infinitusapi.INFTaskType[message.taskType] : message.taskType;
                    return object;
                };
    
                /**
                 * Converts this GetCSVSpecRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCSVSpecRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCSVSpecRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCSVSpecRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCSVSpecRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCSVSpecRequest";
                };
    
                return GetCSVSpecRequest;
            })();
    
            be.GetCSVSpecResponse = (function() {
    
                /**
                 * Properties of a GetCSVSpecResponse.
                 * @memberof infinitusai.be
                 * @interface IGetCSVSpecResponse
                 * @property {infinitusai.be.ICSVSpec|null} [inputSpec] GetCSVSpecResponse inputSpec
                 * @property {infinitusai.be.ICSVSpec|null} [outputSpec] GetCSVSpecResponse outputSpec
                 */
    
                /**
                 * Constructs a new GetCSVSpecResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCSVSpecResponse.
                 * @implements IGetCSVSpecResponse
                 * @constructor
                 * @param {infinitusai.be.IGetCSVSpecResponse=} [properties] Properties to set
                 */
                function GetCSVSpecResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCSVSpecResponse inputSpec.
                 * @member {infinitusai.be.ICSVSpec|null|undefined} inputSpec
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @instance
                 */
                GetCSVSpecResponse.prototype.inputSpec = null;
    
                /**
                 * GetCSVSpecResponse outputSpec.
                 * @member {infinitusai.be.ICSVSpec|null|undefined} outputSpec
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @instance
                 */
                GetCSVSpecResponse.prototype.outputSpec = null;
    
                /**
                 * Creates a new GetCSVSpecResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {infinitusai.be.IGetCSVSpecResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCSVSpecResponse} GetCSVSpecResponse instance
                 */
                GetCSVSpecResponse.create = function create(properties) {
                    return new GetCSVSpecResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCSVSpecResponse message. Does not implicitly {@link infinitusai.be.GetCSVSpecResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {infinitusai.be.IGetCSVSpecResponse} message GetCSVSpecResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVSpecResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inputSpec != null && Object.hasOwnProperty.call(message, "inputSpec"))
                        $root.infinitusai.be.CSVSpec.encode(message.inputSpec, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.outputSpec != null && Object.hasOwnProperty.call(message, "outputSpec"))
                        $root.infinitusai.be.CSVSpec.encode(message.outputSpec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCSVSpecResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetCSVSpecResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {infinitusai.be.IGetCSVSpecResponse} message GetCSVSpecResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVSpecResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCSVSpecResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCSVSpecResponse} GetCSVSpecResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVSpecResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCSVSpecResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inputSpec = $root.infinitusai.be.CSVSpec.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.outputSpec = $root.infinitusai.be.CSVSpec.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCSVSpecResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCSVSpecResponse} GetCSVSpecResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVSpecResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCSVSpecResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCSVSpecResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inputSpec != null && message.hasOwnProperty("inputSpec")) {
                        var error = $root.infinitusai.be.CSVSpec.verify(message.inputSpec);
                        if (error)
                            return "inputSpec." + error;
                    }
                    if (message.outputSpec != null && message.hasOwnProperty("outputSpec")) {
                        var error = $root.infinitusai.be.CSVSpec.verify(message.outputSpec);
                        if (error)
                            return "outputSpec." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetCSVSpecResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCSVSpecResponse} GetCSVSpecResponse
                 */
                GetCSVSpecResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCSVSpecResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetCSVSpecResponse();
                    if (object.inputSpec != null) {
                        if (typeof object.inputSpec !== "object")
                            throw TypeError(".infinitusai.be.GetCSVSpecResponse.inputSpec: object expected");
                        message.inputSpec = $root.infinitusai.be.CSVSpec.fromObject(object.inputSpec);
                    }
                    if (object.outputSpec != null) {
                        if (typeof object.outputSpec !== "object")
                            throw TypeError(".infinitusai.be.GetCSVSpecResponse.outputSpec: object expected");
                        message.outputSpec = $root.infinitusai.be.CSVSpec.fromObject(object.outputSpec);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCSVSpecResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {infinitusai.be.GetCSVSpecResponse} message GetCSVSpecResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCSVSpecResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.inputSpec = null;
                        object.outputSpec = null;
                    }
                    if (message.inputSpec != null && message.hasOwnProperty("inputSpec"))
                        object.inputSpec = $root.infinitusai.be.CSVSpec.toObject(message.inputSpec, options);
                    if (message.outputSpec != null && message.hasOwnProperty("outputSpec"))
                        object.outputSpec = $root.infinitusai.be.CSVSpec.toObject(message.outputSpec, options);
                    return object;
                };
    
                /**
                 * Converts this GetCSVSpecResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCSVSpecResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCSVSpecResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCSVSpecResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCSVSpecResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCSVSpecResponse";
                };
    
                return GetCSVSpecResponse;
            })();
    
            be.GenerateCSVParameters = (function() {
    
                /**
                 * Properties of a GenerateCSVParameters.
                 * @memberof infinitusai.be
                 * @interface IGenerateCSVParameters
                 * @property {number|Long|null} [reviewedMillisStart] GenerateCSVParameters reviewedMillisStart
                 * @property {number|Long|null} [reviewedMillisEnd] GenerateCSVParameters reviewedMillisEnd
                 * @property {string|null} [programId] GenerateCSVParameters programId
                 * @property {infinitusai.be.TaskType|null} [taskType] GenerateCSVParameters taskType
                 */
    
                /**
                 * Constructs a new GenerateCSVParameters.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateCSVParameters.
                 * @implements IGenerateCSVParameters
                 * @constructor
                 * @param {infinitusai.be.IGenerateCSVParameters=} [properties] Properties to set
                 */
                function GenerateCSVParameters(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateCSVParameters reviewedMillisStart.
                 * @member {number|Long} reviewedMillisStart
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @instance
                 */
                GenerateCSVParameters.prototype.reviewedMillisStart = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateCSVParameters reviewedMillisEnd.
                 * @member {number|Long} reviewedMillisEnd
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @instance
                 */
                GenerateCSVParameters.prototype.reviewedMillisEnd = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateCSVParameters programId.
                 * @member {string} programId
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @instance
                 */
                GenerateCSVParameters.prototype.programId = "";
    
                /**
                 * GenerateCSVParameters taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @instance
                 */
                GenerateCSVParameters.prototype.taskType = 0;
    
                /**
                 * Creates a new GenerateCSVParameters instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {infinitusai.be.IGenerateCSVParameters=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateCSVParameters} GenerateCSVParameters instance
                 */
                GenerateCSVParameters.create = function create(properties) {
                    return new GenerateCSVParameters(properties);
                };
    
                /**
                 * Encodes the specified GenerateCSVParameters message. Does not implicitly {@link infinitusai.be.GenerateCSVParameters.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {infinitusai.be.IGenerateCSVParameters} message GenerateCSVParameters message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateCSVParameters.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reviewedMillisStart != null && Object.hasOwnProperty.call(message, "reviewedMillisStart"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.reviewedMillisStart);
                    if (message.reviewedMillisEnd != null && Object.hasOwnProperty.call(message, "reviewedMillisEnd"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.reviewedMillisEnd);
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.programId);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateCSVParameters message, length delimited. Does not implicitly {@link infinitusai.be.GenerateCSVParameters.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {infinitusai.be.IGenerateCSVParameters} message GenerateCSVParameters message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateCSVParameters.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateCSVParameters message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateCSVParameters} GenerateCSVParameters
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateCSVParameters.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateCSVParameters();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.reviewedMillisStart = reader.int64();
                                break;
                            }
                        case 2: {
                                message.reviewedMillisEnd = reader.int64();
                                break;
                            }
                        case 3: {
                                message.programId = reader.string();
                                break;
                            }
                        case 4: {
                                message.taskType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateCSVParameters message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateCSVParameters} GenerateCSVParameters
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateCSVParameters.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateCSVParameters message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateCSVParameters.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reviewedMillisStart != null && message.hasOwnProperty("reviewedMillisStart"))
                        if (!$util.isInteger(message.reviewedMillisStart) && !(message.reviewedMillisStart && $util.isInteger(message.reviewedMillisStart.low) && $util.isInteger(message.reviewedMillisStart.high)))
                            return "reviewedMillisStart: integer|Long expected";
                    if (message.reviewedMillisEnd != null && message.hasOwnProperty("reviewedMillisEnd"))
                        if (!$util.isInteger(message.reviewedMillisEnd) && !(message.reviewedMillisEnd && $util.isInteger(message.reviewedMillisEnd.low) && $util.isInteger(message.reviewedMillisEnd.high)))
                            return "reviewedMillisEnd: integer|Long expected";
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GenerateCSVParameters message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateCSVParameters} GenerateCSVParameters
                 */
                GenerateCSVParameters.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateCSVParameters)
                        return object;
                    var message = new $root.infinitusai.be.GenerateCSVParameters();
                    if (object.reviewedMillisStart != null)
                        if ($util.Long)
                            (message.reviewedMillisStart = $util.Long.fromValue(object.reviewedMillisStart)).unsigned = false;
                        else if (typeof object.reviewedMillisStart === "string")
                            message.reviewedMillisStart = parseInt(object.reviewedMillisStart, 10);
                        else if (typeof object.reviewedMillisStart === "number")
                            message.reviewedMillisStart = object.reviewedMillisStart;
                        else if (typeof object.reviewedMillisStart === "object")
                            message.reviewedMillisStart = new $util.LongBits(object.reviewedMillisStart.low >>> 0, object.reviewedMillisStart.high >>> 0).toNumber();
                    if (object.reviewedMillisEnd != null)
                        if ($util.Long)
                            (message.reviewedMillisEnd = $util.Long.fromValue(object.reviewedMillisEnd)).unsigned = false;
                        else if (typeof object.reviewedMillisEnd === "string")
                            message.reviewedMillisEnd = parseInt(object.reviewedMillisEnd, 10);
                        else if (typeof object.reviewedMillisEnd === "number")
                            message.reviewedMillisEnd = object.reviewedMillisEnd;
                        else if (typeof object.reviewedMillisEnd === "object")
                            message.reviewedMillisEnd = new $util.LongBits(object.reviewedMillisEnd.low >>> 0, object.reviewedMillisEnd.high >>> 0).toNumber();
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateCSVParameters message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {infinitusai.be.GenerateCSVParameters} message GenerateCSVParameters
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateCSVParameters.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedMillisStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedMillisStart = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedMillisEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedMillisEnd = options.longs === String ? "0" : 0;
                        object.programId = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                    }
                    if (message.reviewedMillisStart != null && message.hasOwnProperty("reviewedMillisStart"))
                        if (typeof message.reviewedMillisStart === "number")
                            object.reviewedMillisStart = options.longs === String ? String(message.reviewedMillisStart) : message.reviewedMillisStart;
                        else
                            object.reviewedMillisStart = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedMillisStart) : options.longs === Number ? new $util.LongBits(message.reviewedMillisStart.low >>> 0, message.reviewedMillisStart.high >>> 0).toNumber() : message.reviewedMillisStart;
                    if (message.reviewedMillisEnd != null && message.hasOwnProperty("reviewedMillisEnd"))
                        if (typeof message.reviewedMillisEnd === "number")
                            object.reviewedMillisEnd = options.longs === String ? String(message.reviewedMillisEnd) : message.reviewedMillisEnd;
                        else
                            object.reviewedMillisEnd = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedMillisEnd) : options.longs === Number ? new $util.LongBits(message.reviewedMillisEnd.low >>> 0, message.reviewedMillisEnd.high >>> 0).toNumber() : message.reviewedMillisEnd;
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    return object;
                };
    
                /**
                 * Converts this GenerateCSVParameters to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateCSVParameters.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateCSVParameters
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateCSVParameters
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateCSVParameters.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateCSVParameters";
                };
    
                return GenerateCSVParameters;
            })();
    
            be.GenerateCSVRequest = (function() {
    
                /**
                 * Properties of a GenerateCSVRequest.
                 * @memberof infinitusai.be
                 * @interface IGenerateCSVRequest
                 * @property {boolean|null} [publish] GenerateCSVRequest publish
                 * @property {infinitusai.be.IGenerateCSVParameters|null} [query] GenerateCSVRequest query
                 * @property {boolean|null} [isHumanReadable] GenerateCSVRequest isHumanReadable
                 * @property {boolean|null} [excludeUnusedApiOutputs] GenerateCSVRequest excludeUnusedApiOutputs
                 */
    
                /**
                 * Constructs a new GenerateCSVRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateCSVRequest.
                 * @implements IGenerateCSVRequest
                 * @constructor
                 * @param {infinitusai.be.IGenerateCSVRequest=} [properties] Properties to set
                 */
                function GenerateCSVRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateCSVRequest publish.
                 * @member {boolean} publish
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @instance
                 */
                GenerateCSVRequest.prototype.publish = false;
    
                /**
                 * GenerateCSVRequest query.
                 * @member {infinitusai.be.IGenerateCSVParameters|null|undefined} query
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @instance
                 */
                GenerateCSVRequest.prototype.query = null;
    
                /**
                 * GenerateCSVRequest isHumanReadable.
                 * @member {boolean} isHumanReadable
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @instance
                 */
                GenerateCSVRequest.prototype.isHumanReadable = false;
    
                /**
                 * GenerateCSVRequest excludeUnusedApiOutputs.
                 * @member {boolean} excludeUnusedApiOutputs
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @instance
                 */
                GenerateCSVRequest.prototype.excludeUnusedApiOutputs = false;
    
                /**
                 * Creates a new GenerateCSVRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {infinitusai.be.IGenerateCSVRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateCSVRequest} GenerateCSVRequest instance
                 */
                GenerateCSVRequest.create = function create(properties) {
                    return new GenerateCSVRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateCSVRequest message. Does not implicitly {@link infinitusai.be.GenerateCSVRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {infinitusai.be.IGenerateCSVRequest} message GenerateCSVRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateCSVRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.publish != null && Object.hasOwnProperty.call(message, "publish"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.publish);
                    if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                        $root.infinitusai.be.GenerateCSVParameters.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.isHumanReadable != null && Object.hasOwnProperty.call(message, "isHumanReadable"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isHumanReadable);
                    if (message.excludeUnusedApiOutputs != null && Object.hasOwnProperty.call(message, "excludeUnusedApiOutputs"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.excludeUnusedApiOutputs);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateCSVRequest message, length delimited. Does not implicitly {@link infinitusai.be.GenerateCSVRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {infinitusai.be.IGenerateCSVRequest} message GenerateCSVRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateCSVRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateCSVRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateCSVRequest} GenerateCSVRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateCSVRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateCSVRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.publish = reader.bool();
                                break;
                            }
                        case 2: {
                                message.query = $root.infinitusai.be.GenerateCSVParameters.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.isHumanReadable = reader.bool();
                                break;
                            }
                        case 4: {
                                message.excludeUnusedApiOutputs = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateCSVRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateCSVRequest} GenerateCSVRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateCSVRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateCSVRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateCSVRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.publish != null && message.hasOwnProperty("publish"))
                        if (typeof message.publish !== "boolean")
                            return "publish: boolean expected";
                    if (message.query != null && message.hasOwnProperty("query")) {
                        var error = $root.infinitusai.be.GenerateCSVParameters.verify(message.query);
                        if (error)
                            return "query." + error;
                    }
                    if (message.isHumanReadable != null && message.hasOwnProperty("isHumanReadable"))
                        if (typeof message.isHumanReadable !== "boolean")
                            return "isHumanReadable: boolean expected";
                    if (message.excludeUnusedApiOutputs != null && message.hasOwnProperty("excludeUnusedApiOutputs"))
                        if (typeof message.excludeUnusedApiOutputs !== "boolean")
                            return "excludeUnusedApiOutputs: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateCSVRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateCSVRequest} GenerateCSVRequest
                 */
                GenerateCSVRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateCSVRequest)
                        return object;
                    var message = new $root.infinitusai.be.GenerateCSVRequest();
                    if (object.publish != null)
                        message.publish = Boolean(object.publish);
                    if (object.query != null) {
                        if (typeof object.query !== "object")
                            throw TypeError(".infinitusai.be.GenerateCSVRequest.query: object expected");
                        message.query = $root.infinitusai.be.GenerateCSVParameters.fromObject(object.query);
                    }
                    if (object.isHumanReadable != null)
                        message.isHumanReadable = Boolean(object.isHumanReadable);
                    if (object.excludeUnusedApiOutputs != null)
                        message.excludeUnusedApiOutputs = Boolean(object.excludeUnusedApiOutputs);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateCSVRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {infinitusai.be.GenerateCSVRequest} message GenerateCSVRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateCSVRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.publish = false;
                        object.query = null;
                        object.isHumanReadable = false;
                        object.excludeUnusedApiOutputs = false;
                    }
                    if (message.publish != null && message.hasOwnProperty("publish"))
                        object.publish = message.publish;
                    if (message.query != null && message.hasOwnProperty("query"))
                        object.query = $root.infinitusai.be.GenerateCSVParameters.toObject(message.query, options);
                    if (message.isHumanReadable != null && message.hasOwnProperty("isHumanReadable"))
                        object.isHumanReadable = message.isHumanReadable;
                    if (message.excludeUnusedApiOutputs != null && message.hasOwnProperty("excludeUnusedApiOutputs"))
                        object.excludeUnusedApiOutputs = message.excludeUnusedApiOutputs;
                    return object;
                };
    
                /**
                 * Converts this GenerateCSVRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateCSVRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateCSVRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateCSVRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateCSVRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateCSVRequest";
                };
    
                return GenerateCSVRequest;
            })();
    
            be.GenerateCSVResponse = (function() {
    
                /**
                 * Properties of a GenerateCSVResponse.
                 * @memberof infinitusai.be
                 * @interface IGenerateCSVResponse
                 * @property {string|null} [csvUrl] GenerateCSVResponse csvUrl
                 */
    
                /**
                 * Constructs a new GenerateCSVResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateCSVResponse.
                 * @implements IGenerateCSVResponse
                 * @constructor
                 * @param {infinitusai.be.IGenerateCSVResponse=} [properties] Properties to set
                 */
                function GenerateCSVResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateCSVResponse csvUrl.
                 * @member {string} csvUrl
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @instance
                 */
                GenerateCSVResponse.prototype.csvUrl = "";
    
                /**
                 * Creates a new GenerateCSVResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {infinitusai.be.IGenerateCSVResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateCSVResponse} GenerateCSVResponse instance
                 */
                GenerateCSVResponse.create = function create(properties) {
                    return new GenerateCSVResponse(properties);
                };
    
                /**
                 * Encodes the specified GenerateCSVResponse message. Does not implicitly {@link infinitusai.be.GenerateCSVResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {infinitusai.be.IGenerateCSVResponse} message GenerateCSVResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateCSVResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.csvUrl != null && Object.hasOwnProperty.call(message, "csvUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.csvUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateCSVResponse message, length delimited. Does not implicitly {@link infinitusai.be.GenerateCSVResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {infinitusai.be.IGenerateCSVResponse} message GenerateCSVResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateCSVResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateCSVResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateCSVResponse} GenerateCSVResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateCSVResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateCSVResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.csvUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateCSVResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateCSVResponse} GenerateCSVResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateCSVResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateCSVResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateCSVResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.csvUrl != null && message.hasOwnProperty("csvUrl"))
                        if (!$util.isString(message.csvUrl))
                            return "csvUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateCSVResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateCSVResponse} GenerateCSVResponse
                 */
                GenerateCSVResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateCSVResponse)
                        return object;
                    var message = new $root.infinitusai.be.GenerateCSVResponse();
                    if (object.csvUrl != null)
                        message.csvUrl = String(object.csvUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateCSVResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {infinitusai.be.GenerateCSVResponse} message GenerateCSVResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateCSVResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.csvUrl = "";
                    if (message.csvUrl != null && message.hasOwnProperty("csvUrl"))
                        object.csvUrl = message.csvUrl;
                    return object;
                };
    
                /**
                 * Converts this GenerateCSVResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateCSVResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateCSVResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateCSVResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateCSVResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateCSVResponse";
                };
    
                return GenerateCSVResponse;
            })();
    
            be.GeneratedCSVDoc = (function() {
    
                /**
                 * Properties of a GeneratedCSVDoc.
                 * @memberof infinitusai.be
                 * @interface IGeneratedCSVDoc
                 * @property {infinitusai.be.IGenerateCSVParameters|null} [query] GeneratedCSVDoc query
                 * @property {number|Long|null} [generatedAtMillis] GeneratedCSVDoc generatedAtMillis
                 * @property {string|null} [gcsPath] GeneratedCSVDoc gcsPath
                 * @property {string|null} [csvUuid] GeneratedCSVDoc csvUuid
                 * @property {boolean|null} [published] GeneratedCSVDoc published
                 * @property {string|null} [errorMessage] GeneratedCSVDoc errorMessage
                 */
    
                /**
                 * Constructs a new GeneratedCSVDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GeneratedCSVDoc.
                 * @implements IGeneratedCSVDoc
                 * @constructor
                 * @param {infinitusai.be.IGeneratedCSVDoc=} [properties] Properties to set
                 */
                function GeneratedCSVDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GeneratedCSVDoc query.
                 * @member {infinitusai.be.IGenerateCSVParameters|null|undefined} query
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.query = null;
    
                /**
                 * GeneratedCSVDoc generatedAtMillis.
                 * @member {number|Long} generatedAtMillis
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.generatedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GeneratedCSVDoc gcsPath.
                 * @member {string} gcsPath
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.gcsPath = "";
    
                /**
                 * GeneratedCSVDoc csvUuid.
                 * @member {string} csvUuid
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.csvUuid = "";
    
                /**
                 * GeneratedCSVDoc published.
                 * @member {boolean} published
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.published = false;
    
                /**
                 * GeneratedCSVDoc errorMessage.
                 * @member {string} errorMessage
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.errorMessage = "";
    
                /**
                 * Creates a new GeneratedCSVDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {infinitusai.be.IGeneratedCSVDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.GeneratedCSVDoc} GeneratedCSVDoc instance
                 */
                GeneratedCSVDoc.create = function create(properties) {
                    return new GeneratedCSVDoc(properties);
                };
    
                /**
                 * Encodes the specified GeneratedCSVDoc message. Does not implicitly {@link infinitusai.be.GeneratedCSVDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {infinitusai.be.IGeneratedCSVDoc} message GeneratedCSVDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCSVDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                        $root.infinitusai.be.GenerateCSVParameters.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.generatedAtMillis != null && Object.hasOwnProperty.call(message, "generatedAtMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generatedAtMillis);
                    if (message.gcsPath != null && Object.hasOwnProperty.call(message, "gcsPath"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.gcsPath);
                    if (message.csvUuid != null && Object.hasOwnProperty.call(message, "csvUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.csvUuid);
                    if (message.published != null && Object.hasOwnProperty.call(message, "published"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.published);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.errorMessage);
                    return writer;
                };
    
                /**
                 * Encodes the specified GeneratedCSVDoc message, length delimited. Does not implicitly {@link infinitusai.be.GeneratedCSVDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {infinitusai.be.IGeneratedCSVDoc} message GeneratedCSVDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCSVDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GeneratedCSVDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GeneratedCSVDoc} GeneratedCSVDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCSVDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GeneratedCSVDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.query = $root.infinitusai.be.GenerateCSVParameters.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.generatedAtMillis = reader.int64();
                                break;
                            }
                        case 3: {
                                message.gcsPath = reader.string();
                                break;
                            }
                        case 4: {
                                message.csvUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.published = reader.bool();
                                break;
                            }
                        case 6: {
                                message.errorMessage = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GeneratedCSVDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GeneratedCSVDoc} GeneratedCSVDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCSVDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GeneratedCSVDoc message.
                 * @function verify
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GeneratedCSVDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.query != null && message.hasOwnProperty("query")) {
                        var error = $root.infinitusai.be.GenerateCSVParameters.verify(message.query);
                        if (error)
                            return "query." + error;
                    }
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (!$util.isInteger(message.generatedAtMillis) && !(message.generatedAtMillis && $util.isInteger(message.generatedAtMillis.low) && $util.isInteger(message.generatedAtMillis.high)))
                            return "generatedAtMillis: integer|Long expected";
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        if (!$util.isString(message.gcsPath))
                            return "gcsPath: string expected";
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        if (!$util.isString(message.csvUuid))
                            return "csvUuid: string expected";
                    if (message.published != null && message.hasOwnProperty("published"))
                        if (typeof message.published !== "boolean")
                            return "published: boolean expected";
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    return null;
                };
    
                /**
                 * Creates a GeneratedCSVDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GeneratedCSVDoc} GeneratedCSVDoc
                 */
                GeneratedCSVDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GeneratedCSVDoc)
                        return object;
                    var message = new $root.infinitusai.be.GeneratedCSVDoc();
                    if (object.query != null) {
                        if (typeof object.query !== "object")
                            throw TypeError(".infinitusai.be.GeneratedCSVDoc.query: object expected");
                        message.query = $root.infinitusai.be.GenerateCSVParameters.fromObject(object.query);
                    }
                    if (object.generatedAtMillis != null)
                        if ($util.Long)
                            (message.generatedAtMillis = $util.Long.fromValue(object.generatedAtMillis)).unsigned = false;
                        else if (typeof object.generatedAtMillis === "string")
                            message.generatedAtMillis = parseInt(object.generatedAtMillis, 10);
                        else if (typeof object.generatedAtMillis === "number")
                            message.generatedAtMillis = object.generatedAtMillis;
                        else if (typeof object.generatedAtMillis === "object")
                            message.generatedAtMillis = new $util.LongBits(object.generatedAtMillis.low >>> 0, object.generatedAtMillis.high >>> 0).toNumber();
                    if (object.gcsPath != null)
                        message.gcsPath = String(object.gcsPath);
                    if (object.csvUuid != null)
                        message.csvUuid = String(object.csvUuid);
                    if (object.published != null)
                        message.published = Boolean(object.published);
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GeneratedCSVDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {infinitusai.be.GeneratedCSVDoc} message GeneratedCSVDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GeneratedCSVDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.query = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedAtMillis = options.longs === String ? "0" : 0;
                        object.gcsPath = "";
                        object.csvUuid = "";
                        object.published = false;
                        object.errorMessage = "";
                    }
                    if (message.query != null && message.hasOwnProperty("query"))
                        object.query = $root.infinitusai.be.GenerateCSVParameters.toObject(message.query, options);
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (typeof message.generatedAtMillis === "number")
                            object.generatedAtMillis = options.longs === String ? String(message.generatedAtMillis) : message.generatedAtMillis;
                        else
                            object.generatedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.generatedAtMillis) : options.longs === Number ? new $util.LongBits(message.generatedAtMillis.low >>> 0, message.generatedAtMillis.high >>> 0).toNumber() : message.generatedAtMillis;
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        object.gcsPath = message.gcsPath;
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        object.csvUuid = message.csvUuid;
                    if (message.published != null && message.hasOwnProperty("published"))
                        object.published = message.published;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    return object;
                };
    
                /**
                 * Converts this GeneratedCSVDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GeneratedCSVDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GeneratedCSVDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GeneratedCSVDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GeneratedCSVDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GeneratedCSVDoc";
                };
    
                return GeneratedCSVDoc;
            })();
    
            be.GetCSVReportsRequest = (function() {
    
                /**
                 * Properties of a GetCSVReportsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetCSVReportsRequest
                 */
    
                /**
                 * Constructs a new GetCSVReportsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCSVReportsRequest.
                 * @implements IGetCSVReportsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetCSVReportsRequest=} [properties] Properties to set
                 */
                function GetCSVReportsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetCSVReportsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {infinitusai.be.IGetCSVReportsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCSVReportsRequest} GetCSVReportsRequest instance
                 */
                GetCSVReportsRequest.create = function create(properties) {
                    return new GetCSVReportsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetCSVReportsRequest message. Does not implicitly {@link infinitusai.be.GetCSVReportsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {infinitusai.be.IGetCSVReportsRequest} message GetCSVReportsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVReportsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCSVReportsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetCSVReportsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {infinitusai.be.IGetCSVReportsRequest} message GetCSVReportsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVReportsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCSVReportsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCSVReportsRequest} GetCSVReportsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVReportsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCSVReportsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCSVReportsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCSVReportsRequest} GetCSVReportsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVReportsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCSVReportsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCSVReportsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetCSVReportsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCSVReportsRequest} GetCSVReportsRequest
                 */
                GetCSVReportsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCSVReportsRequest)
                        return object;
                    return new $root.infinitusai.be.GetCSVReportsRequest();
                };
    
                /**
                 * Creates a plain object from a GetCSVReportsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {infinitusai.be.GetCSVReportsRequest} message GetCSVReportsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCSVReportsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetCSVReportsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCSVReportsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCSVReportsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCSVReportsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCSVReportsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCSVReportsRequest";
                };
    
                return GetCSVReportsRequest;
            })();
    
            be.GetCSVReportsResponse = (function() {
    
                /**
                 * Properties of a GetCSVReportsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetCSVReportsResponse
                 * @property {Array.<infinitusai.be.IGeneratedCSVDoc>|null} [reports] GetCSVReportsResponse reports
                 */
    
                /**
                 * Constructs a new GetCSVReportsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCSVReportsResponse.
                 * @implements IGetCSVReportsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetCSVReportsResponse=} [properties] Properties to set
                 */
                function GetCSVReportsResponse(properties) {
                    this.reports = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCSVReportsResponse reports.
                 * @member {Array.<infinitusai.be.IGeneratedCSVDoc>} reports
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @instance
                 */
                GetCSVReportsResponse.prototype.reports = $util.emptyArray;
    
                /**
                 * Creates a new GetCSVReportsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {infinitusai.be.IGetCSVReportsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCSVReportsResponse} GetCSVReportsResponse instance
                 */
                GetCSVReportsResponse.create = function create(properties) {
                    return new GetCSVReportsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCSVReportsResponse message. Does not implicitly {@link infinitusai.be.GetCSVReportsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {infinitusai.be.IGetCSVReportsResponse} message GetCSVReportsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVReportsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reports != null && message.reports.length)
                        for (var i = 0; i < message.reports.length; ++i)
                            $root.infinitusai.be.GeneratedCSVDoc.encode(message.reports[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCSVReportsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetCSVReportsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {infinitusai.be.IGetCSVReportsResponse} message GetCSVReportsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVReportsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCSVReportsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCSVReportsResponse} GetCSVReportsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVReportsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCSVReportsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.reports && message.reports.length))
                                    message.reports = [];
                                message.reports.push($root.infinitusai.be.GeneratedCSVDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCSVReportsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCSVReportsResponse} GetCSVReportsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVReportsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCSVReportsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCSVReportsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reports != null && message.hasOwnProperty("reports")) {
                        if (!Array.isArray(message.reports))
                            return "reports: array expected";
                        for (var i = 0; i < message.reports.length; ++i) {
                            var error = $root.infinitusai.be.GeneratedCSVDoc.verify(message.reports[i]);
                            if (error)
                                return "reports." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetCSVReportsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCSVReportsResponse} GetCSVReportsResponse
                 */
                GetCSVReportsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCSVReportsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetCSVReportsResponse();
                    if (object.reports) {
                        if (!Array.isArray(object.reports))
                            throw TypeError(".infinitusai.be.GetCSVReportsResponse.reports: array expected");
                        message.reports = [];
                        for (var i = 0; i < object.reports.length; ++i) {
                            if (typeof object.reports[i] !== "object")
                                throw TypeError(".infinitusai.be.GetCSVReportsResponse.reports: object expected");
                            message.reports[i] = $root.infinitusai.be.GeneratedCSVDoc.fromObject(object.reports[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCSVReportsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {infinitusai.be.GetCSVReportsResponse} message GetCSVReportsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCSVReportsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.reports = [];
                    if (message.reports && message.reports.length) {
                        object.reports = [];
                        for (var j = 0; j < message.reports.length; ++j)
                            object.reports[j] = $root.infinitusai.be.GeneratedCSVDoc.toObject(message.reports[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetCSVReportsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCSVReportsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCSVReportsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCSVReportsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCSVReportsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCSVReportsResponse";
                };
    
                return GetCSVReportsResponse;
            })();
    
            be.DownloadCSVRequest = (function() {
    
                /**
                 * Properties of a DownloadCSVRequest.
                 * @memberof infinitusai.be
                 * @interface IDownloadCSVRequest
                 * @property {string|null} [csvUuid] DownloadCSVRequest csvUuid
                 */
    
                /**
                 * Constructs a new DownloadCSVRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DownloadCSVRequest.
                 * @implements IDownloadCSVRequest
                 * @constructor
                 * @param {infinitusai.be.IDownloadCSVRequest=} [properties] Properties to set
                 */
                function DownloadCSVRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DownloadCSVRequest csvUuid.
                 * @member {string} csvUuid
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @instance
                 */
                DownloadCSVRequest.prototype.csvUuid = "";
    
                /**
                 * Creates a new DownloadCSVRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {infinitusai.be.IDownloadCSVRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DownloadCSVRequest} DownloadCSVRequest instance
                 */
                DownloadCSVRequest.create = function create(properties) {
                    return new DownloadCSVRequest(properties);
                };
    
                /**
                 * Encodes the specified DownloadCSVRequest message. Does not implicitly {@link infinitusai.be.DownloadCSVRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {infinitusai.be.IDownloadCSVRequest} message DownloadCSVRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadCSVRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.csvUuid != null && Object.hasOwnProperty.call(message, "csvUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.csvUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DownloadCSVRequest message, length delimited. Does not implicitly {@link infinitusai.be.DownloadCSVRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {infinitusai.be.IDownloadCSVRequest} message DownloadCSVRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadCSVRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DownloadCSVRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DownloadCSVRequest} DownloadCSVRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadCSVRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DownloadCSVRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.csvUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DownloadCSVRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DownloadCSVRequest} DownloadCSVRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadCSVRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DownloadCSVRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DownloadCSVRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        if (!$util.isString(message.csvUuid))
                            return "csvUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DownloadCSVRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DownloadCSVRequest} DownloadCSVRequest
                 */
                DownloadCSVRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DownloadCSVRequest)
                        return object;
                    var message = new $root.infinitusai.be.DownloadCSVRequest();
                    if (object.csvUuid != null)
                        message.csvUuid = String(object.csvUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DownloadCSVRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {infinitusai.be.DownloadCSVRequest} message DownloadCSVRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DownloadCSVRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.csvUuid = "";
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        object.csvUuid = message.csvUuid;
                    return object;
                };
    
                /**
                 * Converts this DownloadCSVRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DownloadCSVRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DownloadCSVRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DownloadCSVRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DownloadCSVRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DownloadCSVRequest";
                };
    
                return DownloadCSVRequest;
            })();
    
            be.LoadAppDataRequest = (function() {
    
                /**
                 * Properties of a LoadAppDataRequest.
                 * @memberof infinitusai.be
                 * @interface ILoadAppDataRequest
                 */
    
                /**
                 * Constructs a new LoadAppDataRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LoadAppDataRequest.
                 * @implements ILoadAppDataRequest
                 * @constructor
                 * @param {infinitusai.be.ILoadAppDataRequest=} [properties] Properties to set
                 */
                function LoadAppDataRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new LoadAppDataRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {infinitusai.be.ILoadAppDataRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.LoadAppDataRequest} LoadAppDataRequest instance
                 */
                LoadAppDataRequest.create = function create(properties) {
                    return new LoadAppDataRequest(properties);
                };
    
                /**
                 * Encodes the specified LoadAppDataRequest message. Does not implicitly {@link infinitusai.be.LoadAppDataRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {infinitusai.be.ILoadAppDataRequest} message LoadAppDataRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoadAppDataRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified LoadAppDataRequest message, length delimited. Does not implicitly {@link infinitusai.be.LoadAppDataRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {infinitusai.be.ILoadAppDataRequest} message LoadAppDataRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoadAppDataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LoadAppDataRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LoadAppDataRequest} LoadAppDataRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoadAppDataRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LoadAppDataRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LoadAppDataRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LoadAppDataRequest} LoadAppDataRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoadAppDataRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LoadAppDataRequest message.
                 * @function verify
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LoadAppDataRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a LoadAppDataRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LoadAppDataRequest} LoadAppDataRequest
                 */
                LoadAppDataRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LoadAppDataRequest)
                        return object;
                    return new $root.infinitusai.be.LoadAppDataRequest();
                };
    
                /**
                 * Creates a plain object from a LoadAppDataRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {infinitusai.be.LoadAppDataRequest} message LoadAppDataRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LoadAppDataRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this LoadAppDataRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LoadAppDataRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LoadAppDataRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LoadAppDataRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LoadAppDataRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LoadAppDataRequest";
                };
    
                return LoadAppDataRequest;
            })();
    
            be.LoadAppDataResponse = (function() {
    
                /**
                 * Properties of a LoadAppDataResponse.
                 * @memberof infinitusai.be
                 * @interface ILoadAppDataResponse
                 * @property {infinitusai.be.UserRole|null} [userRole] LoadAppDataResponse userRole
                 * @property {infinitusai.be.UserStatus|null} [userStatus] LoadAppDataResponse userStatus
                 * @property {infinitusai.be.OrgRole|null} [orgRole] LoadAppDataResponse orgRole
                 * @property {string|null} [orgUuid] LoadAppDataResponse orgUuid
                 * @property {infinitusai.be.IOrganizationDoc|null} [orgDoc] LoadAppDataResponse orgDoc
                 */
    
                /**
                 * Constructs a new LoadAppDataResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LoadAppDataResponse.
                 * @implements ILoadAppDataResponse
                 * @constructor
                 * @param {infinitusai.be.ILoadAppDataResponse=} [properties] Properties to set
                 */
                function LoadAppDataResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LoadAppDataResponse userRole.
                 * @member {infinitusai.be.UserRole} userRole
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @instance
                 */
                LoadAppDataResponse.prototype.userRole = 0;
    
                /**
                 * LoadAppDataResponse userStatus.
                 * @member {infinitusai.be.UserStatus} userStatus
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @instance
                 */
                LoadAppDataResponse.prototype.userStatus = 0;
    
                /**
                 * LoadAppDataResponse orgRole.
                 * @member {infinitusai.be.OrgRole} orgRole
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @instance
                 */
                LoadAppDataResponse.prototype.orgRole = 0;
    
                /**
                 * LoadAppDataResponse orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @instance
                 */
                LoadAppDataResponse.prototype.orgUuid = "";
    
                /**
                 * LoadAppDataResponse orgDoc.
                 * @member {infinitusai.be.IOrganizationDoc|null|undefined} orgDoc
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @instance
                 */
                LoadAppDataResponse.prototype.orgDoc = null;
    
                /**
                 * Creates a new LoadAppDataResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {infinitusai.be.ILoadAppDataResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.LoadAppDataResponse} LoadAppDataResponse instance
                 */
                LoadAppDataResponse.create = function create(properties) {
                    return new LoadAppDataResponse(properties);
                };
    
                /**
                 * Encodes the specified LoadAppDataResponse message. Does not implicitly {@link infinitusai.be.LoadAppDataResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {infinitusai.be.ILoadAppDataResponse} message LoadAppDataResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoadAppDataResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userRole != null && Object.hasOwnProperty.call(message, "userRole"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.userRole);
                    if (message.orgRole != null && Object.hasOwnProperty.call(message, "orgRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orgRole);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.orgDoc != null && Object.hasOwnProperty.call(message, "orgDoc"))
                        $root.infinitusai.be.OrganizationDoc.encode(message.orgDoc, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.userStatus != null && Object.hasOwnProperty.call(message, "userStatus"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.userStatus);
                    return writer;
                };
    
                /**
                 * Encodes the specified LoadAppDataResponse message, length delimited. Does not implicitly {@link infinitusai.be.LoadAppDataResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {infinitusai.be.ILoadAppDataResponse} message LoadAppDataResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoadAppDataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LoadAppDataResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LoadAppDataResponse} LoadAppDataResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoadAppDataResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LoadAppDataResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userRole = reader.int32();
                                break;
                            }
                        case 5: {
                                message.userStatus = reader.int32();
                                break;
                            }
                        case 2: {
                                message.orgRole = reader.int32();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.orgDoc = $root.infinitusai.be.OrganizationDoc.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LoadAppDataResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LoadAppDataResponse} LoadAppDataResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoadAppDataResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LoadAppDataResponse message.
                 * @function verify
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LoadAppDataResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        switch (message.userRole) {
                        default:
                            return "userRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.userStatus != null && message.hasOwnProperty("userStatus"))
                        switch (message.userStatus) {
                        default:
                            return "userStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        switch (message.orgRole) {
                        default:
                            return "orgRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.orgDoc != null && message.hasOwnProperty("orgDoc")) {
                        var error = $root.infinitusai.be.OrganizationDoc.verify(message.orgDoc);
                        if (error)
                            return "orgDoc." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a LoadAppDataResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LoadAppDataResponse} LoadAppDataResponse
                 */
                LoadAppDataResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LoadAppDataResponse)
                        return object;
                    var message = new $root.infinitusai.be.LoadAppDataResponse();
                    switch (object.userRole) {
                    default:
                        if (typeof object.userRole === "number") {
                            message.userRole = object.userRole;
                            break;
                        }
                        break;
                    case "USER_ROLE_UNKNOWN":
                    case 0:
                        message.userRole = 0;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 1:
                        message.userRole = 1;
                        break;
                    case "USER_ROLE_OPERATOR":
                    case 2:
                        message.userRole = 2;
                        break;
                    case "USER_ROLE_CUSTOMER":
                    case 3:
                        message.userRole = 3;
                        break;
                    case "USER_ROLE_USER":
                    case 4:
                        message.userRole = 4;
                        break;
                    }
                    switch (object.userStatus) {
                    default:
                        if (typeof object.userStatus === "number") {
                            message.userStatus = object.userStatus;
                            break;
                        }
                        break;
                    case "USER_STATUS_UNKNOWN":
                    case 0:
                        message.userStatus = 0;
                        break;
                    case "USER_STATUS_ACTIVE":
                    case 1:
                        message.userStatus = 1;
                        break;
                    case "USER_STATUS_INACTIVE":
                    case 2:
                        message.userStatus = 2;
                        break;
                    case "USER_STATUS_DEACTIVATED":
                    case 3:
                        message.userStatus = 3;
                        break;
                    case "USER_STATUS_PENDING":
                    case 4:
                        message.userStatus = 4;
                        break;
                    }
                    switch (object.orgRole) {
                    default:
                        if (typeof object.orgRole === "number") {
                            message.orgRole = object.orgRole;
                            break;
                        }
                        break;
                    case "ORG_ROLE_UNKNOWN":
                    case 0:
                        message.orgRole = 0;
                        break;
                    case "ORG_ROLE_OWNER":
                    case 1:
                        message.orgRole = 1;
                        break;
                    case "ORG_ROLE_MEMBER":
                    case 2:
                        message.orgRole = 2;
                        break;
                    case "ORG_ROLE_SUPERVISOR":
                    case 3:
                        message.orgRole = 3;
                        break;
                    case "ORG_ROLE_OPERATOR":
                    case 4:
                        message.orgRole = 4;
                        break;
                    }
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.orgDoc != null) {
                        if (typeof object.orgDoc !== "object")
                            throw TypeError(".infinitusai.be.LoadAppDataResponse.orgDoc: object expected");
                        message.orgDoc = $root.infinitusai.be.OrganizationDoc.fromObject(object.orgDoc);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a LoadAppDataResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {infinitusai.be.LoadAppDataResponse} message LoadAppDataResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LoadAppDataResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userRole = options.enums === String ? "USER_ROLE_UNKNOWN" : 0;
                        object.orgRole = options.enums === String ? "ORG_ROLE_UNKNOWN" : 0;
                        object.orgUuid = "";
                        object.orgDoc = null;
                        object.userStatus = options.enums === String ? "USER_STATUS_UNKNOWN" : 0;
                    }
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        object.userRole = options.enums === String ? $root.infinitusai.be.UserRole[message.userRole] === undefined ? message.userRole : $root.infinitusai.be.UserRole[message.userRole] : message.userRole;
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        object.orgRole = options.enums === String ? $root.infinitusai.be.OrgRole[message.orgRole] === undefined ? message.orgRole : $root.infinitusai.be.OrgRole[message.orgRole] : message.orgRole;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.orgDoc != null && message.hasOwnProperty("orgDoc"))
                        object.orgDoc = $root.infinitusai.be.OrganizationDoc.toObject(message.orgDoc, options);
                    if (message.userStatus != null && message.hasOwnProperty("userStatus"))
                        object.userStatus = options.enums === String ? $root.infinitusai.be.UserStatus[message.userStatus] === undefined ? message.userStatus : $root.infinitusai.be.UserStatus[message.userStatus] : message.userStatus;
                    return object;
                };
    
                /**
                 * Converts this LoadAppDataResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LoadAppDataResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LoadAppDataResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LoadAppDataResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LoadAppDataResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LoadAppDataResponse";
                };
    
                return LoadAppDataResponse;
            })();
    
            be.EbvConfiguration = (function() {
    
                /**
                 * Properties of an EbvConfiguration.
                 * @memberof infinitusai.be
                 * @interface IEbvConfiguration
                 * @property {boolean|null} [enabled] EbvConfiguration enabled
                 * @property {number|null} [holdbackProbability] EbvConfiguration holdbackProbability
                 * @property {boolean|null} [enablePlanInfo] EbvConfiguration enablePlanInfo
                 * @property {boolean|null} [enableBenefits] EbvConfiguration enableBenefits
                 * @property {boolean|null} [enableReferral] EbvConfiguration enableReferral
                 * @property {boolean|null} [enablePriorAuth] EbvConfiguration enablePriorAuth
                 * @property {boolean|null} [enableCopayCoinsurance] EbvConfiguration enableCopayCoinsurance
                 * @property {boolean|null} [enableNetworkStatus] EbvConfiguration enableNetworkStatus
                 * @property {boolean|null} [enableCallMetadata] EbvConfiguration enableCallMetadata
                 * @property {boolean|null} [disablePrevBvFilter] EbvConfiguration disablePrevBvFilter
                 */
    
                /**
                 * Constructs a new EbvConfiguration.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EbvConfiguration.
                 * @implements IEbvConfiguration
                 * @constructor
                 * @param {infinitusai.be.IEbvConfiguration=} [properties] Properties to set
                 */
                function EbvConfiguration(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EbvConfiguration enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.enabled = false;
    
                /**
                 * EbvConfiguration holdbackProbability.
                 * @member {number} holdbackProbability
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.holdbackProbability = 0;
    
                /**
                 * EbvConfiguration enablePlanInfo.
                 * @member {boolean} enablePlanInfo
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.enablePlanInfo = false;
    
                /**
                 * EbvConfiguration enableBenefits.
                 * @member {boolean} enableBenefits
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.enableBenefits = false;
    
                /**
                 * EbvConfiguration enableReferral.
                 * @member {boolean} enableReferral
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.enableReferral = false;
    
                /**
                 * EbvConfiguration enablePriorAuth.
                 * @member {boolean} enablePriorAuth
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.enablePriorAuth = false;
    
                /**
                 * EbvConfiguration enableCopayCoinsurance.
                 * @member {boolean} enableCopayCoinsurance
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.enableCopayCoinsurance = false;
    
                /**
                 * EbvConfiguration enableNetworkStatus.
                 * @member {boolean} enableNetworkStatus
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.enableNetworkStatus = false;
    
                /**
                 * EbvConfiguration enableCallMetadata.
                 * @member {boolean} enableCallMetadata
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.enableCallMetadata = false;
    
                /**
                 * EbvConfiguration disablePrevBvFilter.
                 * @member {boolean} disablePrevBvFilter
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 */
                EbvConfiguration.prototype.disablePrevBvFilter = false;
    
                /**
                 * Creates a new EbvConfiguration instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {infinitusai.be.IEbvConfiguration=} [properties] Properties to set
                 * @returns {infinitusai.be.EbvConfiguration} EbvConfiguration instance
                 */
                EbvConfiguration.create = function create(properties) {
                    return new EbvConfiguration(properties);
                };
    
                /**
                 * Encodes the specified EbvConfiguration message. Does not implicitly {@link infinitusai.be.EbvConfiguration.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {infinitusai.be.IEbvConfiguration} message EbvConfiguration message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EbvConfiguration.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
                    if (message.holdbackProbability != null && Object.hasOwnProperty.call(message, "holdbackProbability"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.holdbackProbability);
                    if (message.enablePlanInfo != null && Object.hasOwnProperty.call(message, "enablePlanInfo"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.enablePlanInfo);
                    if (message.enableBenefits != null && Object.hasOwnProperty.call(message, "enableBenefits"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.enableBenefits);
                    if (message.enableReferral != null && Object.hasOwnProperty.call(message, "enableReferral"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.enableReferral);
                    if (message.enablePriorAuth != null && Object.hasOwnProperty.call(message, "enablePriorAuth"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.enablePriorAuth);
                    if (message.enableCopayCoinsurance != null && Object.hasOwnProperty.call(message, "enableCopayCoinsurance"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.enableCopayCoinsurance);
                    if (message.enableNetworkStatus != null && Object.hasOwnProperty.call(message, "enableNetworkStatus"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.enableNetworkStatus);
                    if (message.enableCallMetadata != null && Object.hasOwnProperty.call(message, "enableCallMetadata"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.enableCallMetadata);
                    if (message.disablePrevBvFilter != null && Object.hasOwnProperty.call(message, "disablePrevBvFilter"))
                        writer.uint32(/* id 11, wireType 0 =*/88).bool(message.disablePrevBvFilter);
                    return writer;
                };
    
                /**
                 * Encodes the specified EbvConfiguration message, length delimited. Does not implicitly {@link infinitusai.be.EbvConfiguration.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {infinitusai.be.IEbvConfiguration} message EbvConfiguration message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EbvConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EbvConfiguration message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EbvConfiguration} EbvConfiguration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EbvConfiguration.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EbvConfiguration();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 2: {
                                message.holdbackProbability = reader.float();
                                break;
                            }
                        case 3: {
                                message.enablePlanInfo = reader.bool();
                                break;
                            }
                        case 4: {
                                message.enableBenefits = reader.bool();
                                break;
                            }
                        case 5: {
                                message.enableReferral = reader.bool();
                                break;
                            }
                        case 6: {
                                message.enablePriorAuth = reader.bool();
                                break;
                            }
                        case 7: {
                                message.enableCopayCoinsurance = reader.bool();
                                break;
                            }
                        case 9: {
                                message.enableNetworkStatus = reader.bool();
                                break;
                            }
                        case 10: {
                                message.enableCallMetadata = reader.bool();
                                break;
                            }
                        case 11: {
                                message.disablePrevBvFilter = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EbvConfiguration message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EbvConfiguration} EbvConfiguration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EbvConfiguration.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EbvConfiguration message.
                 * @function verify
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EbvConfiguration.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.holdbackProbability != null && message.hasOwnProperty("holdbackProbability"))
                        if (typeof message.holdbackProbability !== "number")
                            return "holdbackProbability: number expected";
                    if (message.enablePlanInfo != null && message.hasOwnProperty("enablePlanInfo"))
                        if (typeof message.enablePlanInfo !== "boolean")
                            return "enablePlanInfo: boolean expected";
                    if (message.enableBenefits != null && message.hasOwnProperty("enableBenefits"))
                        if (typeof message.enableBenefits !== "boolean")
                            return "enableBenefits: boolean expected";
                    if (message.enableReferral != null && message.hasOwnProperty("enableReferral"))
                        if (typeof message.enableReferral !== "boolean")
                            return "enableReferral: boolean expected";
                    if (message.enablePriorAuth != null && message.hasOwnProperty("enablePriorAuth"))
                        if (typeof message.enablePriorAuth !== "boolean")
                            return "enablePriorAuth: boolean expected";
                    if (message.enableCopayCoinsurance != null && message.hasOwnProperty("enableCopayCoinsurance"))
                        if (typeof message.enableCopayCoinsurance !== "boolean")
                            return "enableCopayCoinsurance: boolean expected";
                    if (message.enableNetworkStatus != null && message.hasOwnProperty("enableNetworkStatus"))
                        if (typeof message.enableNetworkStatus !== "boolean")
                            return "enableNetworkStatus: boolean expected";
                    if (message.enableCallMetadata != null && message.hasOwnProperty("enableCallMetadata"))
                        if (typeof message.enableCallMetadata !== "boolean")
                            return "enableCallMetadata: boolean expected";
                    if (message.disablePrevBvFilter != null && message.hasOwnProperty("disablePrevBvFilter"))
                        if (typeof message.disablePrevBvFilter !== "boolean")
                            return "disablePrevBvFilter: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an EbvConfiguration message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EbvConfiguration} EbvConfiguration
                 */
                EbvConfiguration.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EbvConfiguration)
                        return object;
                    var message = new $root.infinitusai.be.EbvConfiguration();
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.holdbackProbability != null)
                        message.holdbackProbability = Number(object.holdbackProbability);
                    if (object.enablePlanInfo != null)
                        message.enablePlanInfo = Boolean(object.enablePlanInfo);
                    if (object.enableBenefits != null)
                        message.enableBenefits = Boolean(object.enableBenefits);
                    if (object.enableReferral != null)
                        message.enableReferral = Boolean(object.enableReferral);
                    if (object.enablePriorAuth != null)
                        message.enablePriorAuth = Boolean(object.enablePriorAuth);
                    if (object.enableCopayCoinsurance != null)
                        message.enableCopayCoinsurance = Boolean(object.enableCopayCoinsurance);
                    if (object.enableNetworkStatus != null)
                        message.enableNetworkStatus = Boolean(object.enableNetworkStatus);
                    if (object.enableCallMetadata != null)
                        message.enableCallMetadata = Boolean(object.enableCallMetadata);
                    if (object.disablePrevBvFilter != null)
                        message.disablePrevBvFilter = Boolean(object.disablePrevBvFilter);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EbvConfiguration message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {infinitusai.be.EbvConfiguration} message EbvConfiguration
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EbvConfiguration.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.enabled = false;
                        object.holdbackProbability = 0;
                        object.enablePlanInfo = false;
                        object.enableBenefits = false;
                        object.enableReferral = false;
                        object.enablePriorAuth = false;
                        object.enableCopayCoinsurance = false;
                        object.enableNetworkStatus = false;
                        object.enableCallMetadata = false;
                        object.disablePrevBvFilter = false;
                    }
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.holdbackProbability != null && message.hasOwnProperty("holdbackProbability"))
                        object.holdbackProbability = options.json && !isFinite(message.holdbackProbability) ? String(message.holdbackProbability) : message.holdbackProbability;
                    if (message.enablePlanInfo != null && message.hasOwnProperty("enablePlanInfo"))
                        object.enablePlanInfo = message.enablePlanInfo;
                    if (message.enableBenefits != null && message.hasOwnProperty("enableBenefits"))
                        object.enableBenefits = message.enableBenefits;
                    if (message.enableReferral != null && message.hasOwnProperty("enableReferral"))
                        object.enableReferral = message.enableReferral;
                    if (message.enablePriorAuth != null && message.hasOwnProperty("enablePriorAuth"))
                        object.enablePriorAuth = message.enablePriorAuth;
                    if (message.enableCopayCoinsurance != null && message.hasOwnProperty("enableCopayCoinsurance"))
                        object.enableCopayCoinsurance = message.enableCopayCoinsurance;
                    if (message.enableNetworkStatus != null && message.hasOwnProperty("enableNetworkStatus"))
                        object.enableNetworkStatus = message.enableNetworkStatus;
                    if (message.enableCallMetadata != null && message.hasOwnProperty("enableCallMetadata"))
                        object.enableCallMetadata = message.enableCallMetadata;
                    if (message.disablePrevBvFilter != null && message.hasOwnProperty("disablePrevBvFilter"))
                        object.disablePrevBvFilter = message.disablePrevBvFilter;
                    return object;
                };
    
                /**
                 * Converts this EbvConfiguration to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EbvConfiguration
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EbvConfiguration.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EbvConfiguration
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EbvConfiguration
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EbvConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EbvConfiguration";
                };
    
                return EbvConfiguration;
            })();
    
            be.KeyedConfig = (function() {
    
                /**
                 * Properties of a KeyedConfig.
                 * @memberof infinitusai.be
                 * @interface IKeyedConfig
                 * @property {infinitusai.be.EbvType|null} [key] KeyedConfig key
                 * @property {infinitusai.be.IEbvConfiguration|null} [config] KeyedConfig config
                 */
    
                /**
                 * Constructs a new KeyedConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a KeyedConfig.
                 * @implements IKeyedConfig
                 * @constructor
                 * @param {infinitusai.be.IKeyedConfig=} [properties] Properties to set
                 */
                function KeyedConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * KeyedConfig key.
                 * @member {infinitusai.be.EbvType} key
                 * @memberof infinitusai.be.KeyedConfig
                 * @instance
                 */
                KeyedConfig.prototype.key = 0;
    
                /**
                 * KeyedConfig config.
                 * @member {infinitusai.be.IEbvConfiguration|null|undefined} config
                 * @memberof infinitusai.be.KeyedConfig
                 * @instance
                 */
                KeyedConfig.prototype.config = null;
    
                /**
                 * Creates a new KeyedConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {infinitusai.be.IKeyedConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.KeyedConfig} KeyedConfig instance
                 */
                KeyedConfig.create = function create(properties) {
                    return new KeyedConfig(properties);
                };
    
                /**
                 * Encodes the specified KeyedConfig message. Does not implicitly {@link infinitusai.be.KeyedConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {infinitusai.be.IKeyedConfig} message KeyedConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KeyedConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.key);
                    if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                        $root.infinitusai.be.EbvConfiguration.encode(message.config, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified KeyedConfig message, length delimited. Does not implicitly {@link infinitusai.be.KeyedConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {infinitusai.be.IKeyedConfig} message KeyedConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KeyedConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a KeyedConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.KeyedConfig} KeyedConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KeyedConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.KeyedConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.key = reader.int32();
                                break;
                            }
                        case 2: {
                                message.config = $root.infinitusai.be.EbvConfiguration.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a KeyedConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.KeyedConfig} KeyedConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KeyedConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a KeyedConfig message.
                 * @function verify
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                KeyedConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        switch (message.key) {
                        default:
                            return "key: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            break;
                        }
                    if (message.config != null && message.hasOwnProperty("config")) {
                        var error = $root.infinitusai.be.EbvConfiguration.verify(message.config);
                        if (error)
                            return "config." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a KeyedConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.KeyedConfig} KeyedConfig
                 */
                KeyedConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.KeyedConfig)
                        return object;
                    var message = new $root.infinitusai.be.KeyedConfig();
                    switch (object.key) {
                    default:
                        if (typeof object.key === "number") {
                            message.key = object.key;
                            break;
                        }
                        break;
                    case "EBV_TYPE_UNKNOWN":
                    case 0:
                        message.key = 0;
                        break;
                    case "EBV_TYPE_CHC":
                    case 1:
                        message.key = 1;
                        break;
                    case "EBV_TYPE_PAYER_API":
                    case 2:
                        message.key = 2;
                        break;
                    case "EBV_TYPE_STAT_PIE":
                    case 3:
                        message.key = 3;
                        break;
                    case "EBV_TYPE_MOCK":
                    case 4:
                        message.key = 4;
                        break;
                    case "EBV_TYPE_MEDICARE":
                    case 5:
                        message.key = 5;
                        break;
                    case "EBV_TYPE_AVAILITY":
                    case 6:
                        message.key = 6;
                        break;
                    case "EBV_TYPE_CMS":
                    case 7:
                        message.key = 7;
                        break;
                    case "EBV_TYPE_BCBS":
                    case 8:
                        message.key = 8;
                        break;
                    case "EBV_TYPE_ARRIVE_HEALTH":
                    case 9:
                        message.key = 9;
                        break;
                    case "EBV_TYPE_KG_INTERNAL":
                    case 10:
                        message.key = 10;
                        break;
                    }
                    if (object.config != null) {
                        if (typeof object.config !== "object")
                            throw TypeError(".infinitusai.be.KeyedConfig.config: object expected");
                        message.config = $root.infinitusai.be.EbvConfiguration.fromObject(object.config);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a KeyedConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {infinitusai.be.KeyedConfig} message KeyedConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                KeyedConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.key = options.enums === String ? "EBV_TYPE_UNKNOWN" : 0;
                        object.config = null;
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = options.enums === String ? $root.infinitusai.be.EbvType[message.key] === undefined ? message.key : $root.infinitusai.be.EbvType[message.key] : message.key;
                    if (message.config != null && message.hasOwnProperty("config"))
                        object.config = $root.infinitusai.be.EbvConfiguration.toObject(message.config, options);
                    return object;
                };
    
                /**
                 * Converts this KeyedConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.KeyedConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                KeyedConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for KeyedConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.KeyedConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                KeyedConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.KeyedConfig";
                };
    
                return KeyedConfig;
            })();
    
            be.GetEbvConfigurationsRequest = (function() {
    
                /**
                 * Properties of a GetEbvConfigurationsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetEbvConfigurationsRequest
                 */
    
                /**
                 * Constructs a new GetEbvConfigurationsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetEbvConfigurationsRequest.
                 * @implements IGetEbvConfigurationsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetEbvConfigurationsRequest=} [properties] Properties to set
                 */
                function GetEbvConfigurationsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetEbvConfigurationsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {infinitusai.be.IGetEbvConfigurationsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetEbvConfigurationsRequest} GetEbvConfigurationsRequest instance
                 */
                GetEbvConfigurationsRequest.create = function create(properties) {
                    return new GetEbvConfigurationsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetEbvConfigurationsRequest message. Does not implicitly {@link infinitusai.be.GetEbvConfigurationsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {infinitusai.be.IGetEbvConfigurationsRequest} message GetEbvConfigurationsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEbvConfigurationsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetEbvConfigurationsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetEbvConfigurationsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {infinitusai.be.IGetEbvConfigurationsRequest} message GetEbvConfigurationsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEbvConfigurationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetEbvConfigurationsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetEbvConfigurationsRequest} GetEbvConfigurationsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEbvConfigurationsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetEbvConfigurationsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetEbvConfigurationsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetEbvConfigurationsRequest} GetEbvConfigurationsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEbvConfigurationsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetEbvConfigurationsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetEbvConfigurationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetEbvConfigurationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetEbvConfigurationsRequest} GetEbvConfigurationsRequest
                 */
                GetEbvConfigurationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetEbvConfigurationsRequest)
                        return object;
                    return new $root.infinitusai.be.GetEbvConfigurationsRequest();
                };
    
                /**
                 * Creates a plain object from a GetEbvConfigurationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {infinitusai.be.GetEbvConfigurationsRequest} message GetEbvConfigurationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetEbvConfigurationsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetEbvConfigurationsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetEbvConfigurationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetEbvConfigurationsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetEbvConfigurationsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetEbvConfigurationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetEbvConfigurationsRequest";
                };
    
                return GetEbvConfigurationsRequest;
            })();
    
            be.GetEbvConfigurationsResponse = (function() {
    
                /**
                 * Properties of a GetEbvConfigurationsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetEbvConfigurationsResponse
                 * @property {Array.<infinitusai.be.IKeyedConfig>|null} [ebvConfigurations] GetEbvConfigurationsResponse ebvConfigurations
                 */
    
                /**
                 * Constructs a new GetEbvConfigurationsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetEbvConfigurationsResponse.
                 * @implements IGetEbvConfigurationsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetEbvConfigurationsResponse=} [properties] Properties to set
                 */
                function GetEbvConfigurationsResponse(properties) {
                    this.ebvConfigurations = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetEbvConfigurationsResponse ebvConfigurations.
                 * @member {Array.<infinitusai.be.IKeyedConfig>} ebvConfigurations
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @instance
                 */
                GetEbvConfigurationsResponse.prototype.ebvConfigurations = $util.emptyArray;
    
                /**
                 * Creates a new GetEbvConfigurationsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {infinitusai.be.IGetEbvConfigurationsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetEbvConfigurationsResponse} GetEbvConfigurationsResponse instance
                 */
                GetEbvConfigurationsResponse.create = function create(properties) {
                    return new GetEbvConfigurationsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetEbvConfigurationsResponse message. Does not implicitly {@link infinitusai.be.GetEbvConfigurationsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {infinitusai.be.IGetEbvConfigurationsResponse} message GetEbvConfigurationsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEbvConfigurationsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ebvConfigurations != null && message.ebvConfigurations.length)
                        for (var i = 0; i < message.ebvConfigurations.length; ++i)
                            $root.infinitusai.be.KeyedConfig.encode(message.ebvConfigurations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetEbvConfigurationsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetEbvConfigurationsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {infinitusai.be.IGetEbvConfigurationsResponse} message GetEbvConfigurationsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEbvConfigurationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetEbvConfigurationsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetEbvConfigurationsResponse} GetEbvConfigurationsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEbvConfigurationsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetEbvConfigurationsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.ebvConfigurations && message.ebvConfigurations.length))
                                    message.ebvConfigurations = [];
                                message.ebvConfigurations.push($root.infinitusai.be.KeyedConfig.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetEbvConfigurationsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetEbvConfigurationsResponse} GetEbvConfigurationsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEbvConfigurationsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetEbvConfigurationsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetEbvConfigurationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ebvConfigurations != null && message.hasOwnProperty("ebvConfigurations")) {
                        if (!Array.isArray(message.ebvConfigurations))
                            return "ebvConfigurations: array expected";
                        for (var i = 0; i < message.ebvConfigurations.length; ++i) {
                            var error = $root.infinitusai.be.KeyedConfig.verify(message.ebvConfigurations[i]);
                            if (error)
                                return "ebvConfigurations." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetEbvConfigurationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetEbvConfigurationsResponse} GetEbvConfigurationsResponse
                 */
                GetEbvConfigurationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetEbvConfigurationsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetEbvConfigurationsResponse();
                    if (object.ebvConfigurations) {
                        if (!Array.isArray(object.ebvConfigurations))
                            throw TypeError(".infinitusai.be.GetEbvConfigurationsResponse.ebvConfigurations: array expected");
                        message.ebvConfigurations = [];
                        for (var i = 0; i < object.ebvConfigurations.length; ++i) {
                            if (typeof object.ebvConfigurations[i] !== "object")
                                throw TypeError(".infinitusai.be.GetEbvConfigurationsResponse.ebvConfigurations: object expected");
                            message.ebvConfigurations[i] = $root.infinitusai.be.KeyedConfig.fromObject(object.ebvConfigurations[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetEbvConfigurationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {infinitusai.be.GetEbvConfigurationsResponse} message GetEbvConfigurationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetEbvConfigurationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.ebvConfigurations = [];
                    if (message.ebvConfigurations && message.ebvConfigurations.length) {
                        object.ebvConfigurations = [];
                        for (var j = 0; j < message.ebvConfigurations.length; ++j)
                            object.ebvConfigurations[j] = $root.infinitusai.be.KeyedConfig.toObject(message.ebvConfigurations[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetEbvConfigurationsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetEbvConfigurationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetEbvConfigurationsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetEbvConfigurationsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetEbvConfigurationsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetEbvConfigurationsResponse";
                };
    
                return GetEbvConfigurationsResponse;
            })();
    
            be.UpdateEbvConfigurationRequest = (function() {
    
                /**
                 * Properties of an UpdateEbvConfigurationRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateEbvConfigurationRequest
                 * @property {infinitusai.be.EbvType|null} [ebvType] UpdateEbvConfigurationRequest ebvType
                 * @property {infinitusai.be.IEbvConfiguration|null} [ebvConfiguration] UpdateEbvConfigurationRequest ebvConfiguration
                 */
    
                /**
                 * Constructs a new UpdateEbvConfigurationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateEbvConfigurationRequest.
                 * @implements IUpdateEbvConfigurationRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateEbvConfigurationRequest=} [properties] Properties to set
                 */
                function UpdateEbvConfigurationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateEbvConfigurationRequest ebvType.
                 * @member {infinitusai.be.EbvType} ebvType
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @instance
                 */
                UpdateEbvConfigurationRequest.prototype.ebvType = 0;
    
                /**
                 * UpdateEbvConfigurationRequest ebvConfiguration.
                 * @member {infinitusai.be.IEbvConfiguration|null|undefined} ebvConfiguration
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @instance
                 */
                UpdateEbvConfigurationRequest.prototype.ebvConfiguration = null;
    
                /**
                 * Creates a new UpdateEbvConfigurationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {infinitusai.be.IUpdateEbvConfigurationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateEbvConfigurationRequest} UpdateEbvConfigurationRequest instance
                 */
                UpdateEbvConfigurationRequest.create = function create(properties) {
                    return new UpdateEbvConfigurationRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateEbvConfigurationRequest message. Does not implicitly {@link infinitusai.be.UpdateEbvConfigurationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {infinitusai.be.IUpdateEbvConfigurationRequest} message UpdateEbvConfigurationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateEbvConfigurationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ebvType != null && Object.hasOwnProperty.call(message, "ebvType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ebvType);
                    if (message.ebvConfiguration != null && Object.hasOwnProperty.call(message, "ebvConfiguration"))
                        $root.infinitusai.be.EbvConfiguration.encode(message.ebvConfiguration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateEbvConfigurationRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateEbvConfigurationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {infinitusai.be.IUpdateEbvConfigurationRequest} message UpdateEbvConfigurationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateEbvConfigurationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateEbvConfigurationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateEbvConfigurationRequest} UpdateEbvConfigurationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateEbvConfigurationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateEbvConfigurationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ebvType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.ebvConfiguration = $root.infinitusai.be.EbvConfiguration.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateEbvConfigurationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateEbvConfigurationRequest} UpdateEbvConfigurationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateEbvConfigurationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateEbvConfigurationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateEbvConfigurationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ebvType != null && message.hasOwnProperty("ebvType"))
                        switch (message.ebvType) {
                        default:
                            return "ebvType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            break;
                        }
                    if (message.ebvConfiguration != null && message.hasOwnProperty("ebvConfiguration")) {
                        var error = $root.infinitusai.be.EbvConfiguration.verify(message.ebvConfiguration);
                        if (error)
                            return "ebvConfiguration." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateEbvConfigurationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateEbvConfigurationRequest} UpdateEbvConfigurationRequest
                 */
                UpdateEbvConfigurationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateEbvConfigurationRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateEbvConfigurationRequest();
                    switch (object.ebvType) {
                    default:
                        if (typeof object.ebvType === "number") {
                            message.ebvType = object.ebvType;
                            break;
                        }
                        break;
                    case "EBV_TYPE_UNKNOWN":
                    case 0:
                        message.ebvType = 0;
                        break;
                    case "EBV_TYPE_CHC":
                    case 1:
                        message.ebvType = 1;
                        break;
                    case "EBV_TYPE_PAYER_API":
                    case 2:
                        message.ebvType = 2;
                        break;
                    case "EBV_TYPE_STAT_PIE":
                    case 3:
                        message.ebvType = 3;
                        break;
                    case "EBV_TYPE_MOCK":
                    case 4:
                        message.ebvType = 4;
                        break;
                    case "EBV_TYPE_MEDICARE":
                    case 5:
                        message.ebvType = 5;
                        break;
                    case "EBV_TYPE_AVAILITY":
                    case 6:
                        message.ebvType = 6;
                        break;
                    case "EBV_TYPE_CMS":
                    case 7:
                        message.ebvType = 7;
                        break;
                    case "EBV_TYPE_BCBS":
                    case 8:
                        message.ebvType = 8;
                        break;
                    case "EBV_TYPE_ARRIVE_HEALTH":
                    case 9:
                        message.ebvType = 9;
                        break;
                    case "EBV_TYPE_KG_INTERNAL":
                    case 10:
                        message.ebvType = 10;
                        break;
                    }
                    if (object.ebvConfiguration != null) {
                        if (typeof object.ebvConfiguration !== "object")
                            throw TypeError(".infinitusai.be.UpdateEbvConfigurationRequest.ebvConfiguration: object expected");
                        message.ebvConfiguration = $root.infinitusai.be.EbvConfiguration.fromObject(object.ebvConfiguration);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateEbvConfigurationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {infinitusai.be.UpdateEbvConfigurationRequest} message UpdateEbvConfigurationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateEbvConfigurationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.ebvType = options.enums === String ? "EBV_TYPE_UNKNOWN" : 0;
                        object.ebvConfiguration = null;
                    }
                    if (message.ebvType != null && message.hasOwnProperty("ebvType"))
                        object.ebvType = options.enums === String ? $root.infinitusai.be.EbvType[message.ebvType] === undefined ? message.ebvType : $root.infinitusai.be.EbvType[message.ebvType] : message.ebvType;
                    if (message.ebvConfiguration != null && message.hasOwnProperty("ebvConfiguration"))
                        object.ebvConfiguration = $root.infinitusai.be.EbvConfiguration.toObject(message.ebvConfiguration, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateEbvConfigurationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateEbvConfigurationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateEbvConfigurationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateEbvConfigurationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateEbvConfigurationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateEbvConfigurationRequest";
                };
    
                return UpdateEbvConfigurationRequest;
            })();
    
            be.UpdateEbvConfigurationResponse = (function() {
    
                /**
                 * Properties of an UpdateEbvConfigurationResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateEbvConfigurationResponse
                 * @property {infinitusai.be.IEbvConfiguration|null} [ebvConfiguration] UpdateEbvConfigurationResponse ebvConfiguration
                 */
    
                /**
                 * Constructs a new UpdateEbvConfigurationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateEbvConfigurationResponse.
                 * @implements IUpdateEbvConfigurationResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateEbvConfigurationResponse=} [properties] Properties to set
                 */
                function UpdateEbvConfigurationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateEbvConfigurationResponse ebvConfiguration.
                 * @member {infinitusai.be.IEbvConfiguration|null|undefined} ebvConfiguration
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @instance
                 */
                UpdateEbvConfigurationResponse.prototype.ebvConfiguration = null;
    
                /**
                 * Creates a new UpdateEbvConfigurationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {infinitusai.be.IUpdateEbvConfigurationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateEbvConfigurationResponse} UpdateEbvConfigurationResponse instance
                 */
                UpdateEbvConfigurationResponse.create = function create(properties) {
                    return new UpdateEbvConfigurationResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateEbvConfigurationResponse message. Does not implicitly {@link infinitusai.be.UpdateEbvConfigurationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {infinitusai.be.IUpdateEbvConfigurationResponse} message UpdateEbvConfigurationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateEbvConfigurationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ebvConfiguration != null && Object.hasOwnProperty.call(message, "ebvConfiguration"))
                        $root.infinitusai.be.EbvConfiguration.encode(message.ebvConfiguration, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateEbvConfigurationResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateEbvConfigurationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {infinitusai.be.IUpdateEbvConfigurationResponse} message UpdateEbvConfigurationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateEbvConfigurationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateEbvConfigurationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateEbvConfigurationResponse} UpdateEbvConfigurationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateEbvConfigurationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateEbvConfigurationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ebvConfiguration = $root.infinitusai.be.EbvConfiguration.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateEbvConfigurationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateEbvConfigurationResponse} UpdateEbvConfigurationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateEbvConfigurationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateEbvConfigurationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateEbvConfigurationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ebvConfiguration != null && message.hasOwnProperty("ebvConfiguration")) {
                        var error = $root.infinitusai.be.EbvConfiguration.verify(message.ebvConfiguration);
                        if (error)
                            return "ebvConfiguration." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateEbvConfigurationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateEbvConfigurationResponse} UpdateEbvConfigurationResponse
                 */
                UpdateEbvConfigurationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateEbvConfigurationResponse)
                        return object;
                    var message = new $root.infinitusai.be.UpdateEbvConfigurationResponse();
                    if (object.ebvConfiguration != null) {
                        if (typeof object.ebvConfiguration !== "object")
                            throw TypeError(".infinitusai.be.UpdateEbvConfigurationResponse.ebvConfiguration: object expected");
                        message.ebvConfiguration = $root.infinitusai.be.EbvConfiguration.fromObject(object.ebvConfiguration);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateEbvConfigurationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {infinitusai.be.UpdateEbvConfigurationResponse} message UpdateEbvConfigurationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateEbvConfigurationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.ebvConfiguration = null;
                    if (message.ebvConfiguration != null && message.hasOwnProperty("ebvConfiguration"))
                        object.ebvConfiguration = $root.infinitusai.be.EbvConfiguration.toObject(message.ebvConfiguration, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateEbvConfigurationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateEbvConfigurationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateEbvConfigurationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateEbvConfigurationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateEbvConfigurationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateEbvConfigurationResponse";
                };
    
                return UpdateEbvConfigurationResponse;
            })();
    
            be.GetKGRulesRequest = (function() {
    
                /**
                 * Properties of a GetKGRulesRequest.
                 * @memberof infinitusai.be
                 * @interface IGetKGRulesRequest
                 * @property {infinitusai.be.IKGQueryOptions|null} [queryOptions] GetKGRulesRequest queryOptions
                 */
    
                /**
                 * Constructs a new GetKGRulesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetKGRulesRequest.
                 * @implements IGetKGRulesRequest
                 * @constructor
                 * @param {infinitusai.be.IGetKGRulesRequest=} [properties] Properties to set
                 */
                function GetKGRulesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetKGRulesRequest queryOptions.
                 * @member {infinitusai.be.IKGQueryOptions|null|undefined} queryOptions
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @instance
                 */
                GetKGRulesRequest.prototype.queryOptions = null;
    
                /**
                 * Creates a new GetKGRulesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {infinitusai.be.IGetKGRulesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetKGRulesRequest} GetKGRulesRequest instance
                 */
                GetKGRulesRequest.create = function create(properties) {
                    return new GetKGRulesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetKGRulesRequest message. Does not implicitly {@link infinitusai.be.GetKGRulesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {infinitusai.be.IGetKGRulesRequest} message GetKGRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetKGRulesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queryOptions != null && Object.hasOwnProperty.call(message, "queryOptions"))
                        $root.infinitusai.be.KGQueryOptions.encode(message.queryOptions, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetKGRulesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetKGRulesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {infinitusai.be.IGetKGRulesRequest} message GetKGRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetKGRulesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetKGRulesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetKGRulesRequest} GetKGRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetKGRulesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetKGRulesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.queryOptions = $root.infinitusai.be.KGQueryOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetKGRulesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetKGRulesRequest} GetKGRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetKGRulesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetKGRulesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetKGRulesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions")) {
                        var error = $root.infinitusai.be.KGQueryOptions.verify(message.queryOptions);
                        if (error)
                            return "queryOptions." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetKGRulesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetKGRulesRequest} GetKGRulesRequest
                 */
                GetKGRulesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetKGRulesRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetKGRulesRequest();
                    if (object.queryOptions != null) {
                        if (typeof object.queryOptions !== "object")
                            throw TypeError(".infinitusai.be.GetKGRulesRequest.queryOptions: object expected");
                        message.queryOptions = $root.infinitusai.be.KGQueryOptions.fromObject(object.queryOptions);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetKGRulesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {infinitusai.be.GetKGRulesRequest} message GetKGRulesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetKGRulesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queryOptions = null;
                    if (message.queryOptions != null && message.hasOwnProperty("queryOptions"))
                        object.queryOptions = $root.infinitusai.be.KGQueryOptions.toObject(message.queryOptions, options);
                    return object;
                };
    
                /**
                 * Converts this GetKGRulesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetKGRulesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetKGRulesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetKGRulesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetKGRulesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetKGRulesRequest";
                };
    
                return GetKGRulesRequest;
            })();
    
            be.KGQueryOptions = (function() {
    
                /**
                 * Properties of a KGQueryOptions.
                 * @memberof infinitusai.be
                 * @interface IKGQueryOptions
                 * @property {string|null} [outputFieldFilter] KGQueryOptions outputFieldFilter
                 * @property {string|null} [inputFieldFilter] KGQueryOptions inputFieldFilter
                 * @property {infinitusai.be.TaskType|null} [taskType] KGQueryOptions taskType
                 * @property {infinitusai.be.TaskType|null} [outputTaskType] KGQueryOptions outputTaskType
                 * @property {infinitusai.be.TaskType|null} [inputTaskType] KGQueryOptions inputTaskType
                 * @property {infinitusai.be.EbvType|null} [source] KGQueryOptions source
                 * @property {string|null} [orgUuid] KGQueryOptions orgUuid
                 * @property {number|Long|null} [limit] KGQueryOptions limit
                 * @property {number|Long|null} [page] KGQueryOptions page
                 * @property {string|null} [sortBy] KGQueryOptions sortBy
                 * @property {string|null} [sortOrder] KGQueryOptions sortOrder
                 */
    
                /**
                 * Constructs a new KGQueryOptions.
                 * @memberof infinitusai.be
                 * @classdesc Represents a KGQueryOptions.
                 * @implements IKGQueryOptions
                 * @constructor
                 * @param {infinitusai.be.IKGQueryOptions=} [properties] Properties to set
                 */
                function KGQueryOptions(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * KGQueryOptions outputFieldFilter.
                 * @member {string} outputFieldFilter
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.outputFieldFilter = "";
    
                /**
                 * KGQueryOptions inputFieldFilter.
                 * @member {string} inputFieldFilter
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.inputFieldFilter = "";
    
                /**
                 * KGQueryOptions taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.taskType = 0;
    
                /**
                 * KGQueryOptions outputTaskType.
                 * @member {infinitusai.be.TaskType} outputTaskType
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.outputTaskType = 0;
    
                /**
                 * KGQueryOptions inputTaskType.
                 * @member {infinitusai.be.TaskType} inputTaskType
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.inputTaskType = 0;
    
                /**
                 * KGQueryOptions source.
                 * @member {infinitusai.be.EbvType} source
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.source = 0;
    
                /**
                 * KGQueryOptions orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.orgUuid = "";
    
                /**
                 * KGQueryOptions limit.
                 * @member {number|Long} limit
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * KGQueryOptions page.
                 * @member {number|Long} page
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.page = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * KGQueryOptions sortBy.
                 * @member {string} sortBy
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.sortBy = "";
    
                /**
                 * KGQueryOptions sortOrder.
                 * @member {string} sortOrder
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 */
                KGQueryOptions.prototype.sortOrder = "";
    
                /**
                 * Creates a new KGQueryOptions instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {infinitusai.be.IKGQueryOptions=} [properties] Properties to set
                 * @returns {infinitusai.be.KGQueryOptions} KGQueryOptions instance
                 */
                KGQueryOptions.create = function create(properties) {
                    return new KGQueryOptions(properties);
                };
    
                /**
                 * Encodes the specified KGQueryOptions message. Does not implicitly {@link infinitusai.be.KGQueryOptions.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {infinitusai.be.IKGQueryOptions} message KGQueryOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KGQueryOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.outputFieldFilter != null && Object.hasOwnProperty.call(message, "outputFieldFilter"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.outputFieldFilter);
                    if (message.inputFieldFilter != null && Object.hasOwnProperty.call(message, "inputFieldFilter"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputFieldFilter);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.taskType);
                    if (message.outputTaskType != null && Object.hasOwnProperty.call(message, "outputTaskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.outputTaskType);
                    if (message.inputTaskType != null && Object.hasOwnProperty.call(message, "inputTaskType"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.inputTaskType);
                    if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.source);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.orgUuid);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.limit);
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.page);
                    if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.sortBy);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.sortOrder);
                    return writer;
                };
    
                /**
                 * Encodes the specified KGQueryOptions message, length delimited. Does not implicitly {@link infinitusai.be.KGQueryOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {infinitusai.be.IKGQueryOptions} message KGQueryOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KGQueryOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a KGQueryOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.KGQueryOptions} KGQueryOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KGQueryOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.KGQueryOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.outputFieldFilter = reader.string();
                                break;
                            }
                        case 2: {
                                message.inputFieldFilter = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 4: {
                                message.outputTaskType = reader.int32();
                                break;
                            }
                        case 5: {
                                message.inputTaskType = reader.int32();
                                break;
                            }
                        case 6: {
                                message.source = reader.int32();
                                break;
                            }
                        case 7: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 8: {
                                message.limit = reader.int64();
                                break;
                            }
                        case 9: {
                                message.page = reader.int64();
                                break;
                            }
                        case 10: {
                                message.sortBy = reader.string();
                                break;
                            }
                        case 11: {
                                message.sortOrder = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a KGQueryOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.KGQueryOptions} KGQueryOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KGQueryOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a KGQueryOptions message.
                 * @function verify
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                KGQueryOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.outputFieldFilter != null && message.hasOwnProperty("outputFieldFilter"))
                        if (!$util.isString(message.outputFieldFilter))
                            return "outputFieldFilter: string expected";
                    if (message.inputFieldFilter != null && message.hasOwnProperty("inputFieldFilter"))
                        if (!$util.isString(message.inputFieldFilter))
                            return "inputFieldFilter: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.outputTaskType != null && message.hasOwnProperty("outputTaskType"))
                        switch (message.outputTaskType) {
                        default:
                            return "outputTaskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.inputTaskType != null && message.hasOwnProperty("inputTaskType"))
                        switch (message.inputTaskType) {
                        default:
                            return "inputTaskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.source != null && message.hasOwnProperty("source"))
                        switch (message.source) {
                        default:
                            return "source: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            break;
                        }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                            return "limit: integer|Long expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                            return "page: integer|Long expected";
                    if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                        if (!$util.isString(message.sortBy))
                            return "sortBy: string expected";
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        if (!$util.isString(message.sortOrder))
                            return "sortOrder: string expected";
                    return null;
                };
    
                /**
                 * Creates a KGQueryOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.KGQueryOptions} KGQueryOptions
                 */
                KGQueryOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.KGQueryOptions)
                        return object;
                    var message = new $root.infinitusai.be.KGQueryOptions();
                    if (object.outputFieldFilter != null)
                        message.outputFieldFilter = String(object.outputFieldFilter);
                    if (object.inputFieldFilter != null)
                        message.inputFieldFilter = String(object.inputFieldFilter);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    switch (object.outputTaskType) {
                    default:
                        if (typeof object.outputTaskType === "number") {
                            message.outputTaskType = object.outputTaskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.outputTaskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.outputTaskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.outputTaskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.outputTaskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.outputTaskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.outputTaskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.outputTaskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.outputTaskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.outputTaskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.outputTaskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.outputTaskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.outputTaskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.outputTaskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.outputTaskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.outputTaskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.outputTaskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.outputTaskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.outputTaskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.outputTaskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.outputTaskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.outputTaskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.outputTaskType = 21;
                        break;
                    }
                    switch (object.inputTaskType) {
                    default:
                        if (typeof object.inputTaskType === "number") {
                            message.inputTaskType = object.inputTaskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.inputTaskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.inputTaskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.inputTaskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.inputTaskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.inputTaskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.inputTaskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.inputTaskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.inputTaskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.inputTaskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.inputTaskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.inputTaskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.inputTaskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.inputTaskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.inputTaskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.inputTaskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.inputTaskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.inputTaskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.inputTaskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.inputTaskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.inputTaskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.inputTaskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.inputTaskType = 21;
                        break;
                    }
                    switch (object.source) {
                    default:
                        if (typeof object.source === "number") {
                            message.source = object.source;
                            break;
                        }
                        break;
                    case "EBV_TYPE_UNKNOWN":
                    case 0:
                        message.source = 0;
                        break;
                    case "EBV_TYPE_CHC":
                    case 1:
                        message.source = 1;
                        break;
                    case "EBV_TYPE_PAYER_API":
                    case 2:
                        message.source = 2;
                        break;
                    case "EBV_TYPE_STAT_PIE":
                    case 3:
                        message.source = 3;
                        break;
                    case "EBV_TYPE_MOCK":
                    case 4:
                        message.source = 4;
                        break;
                    case "EBV_TYPE_MEDICARE":
                    case 5:
                        message.source = 5;
                        break;
                    case "EBV_TYPE_AVAILITY":
                    case 6:
                        message.source = 6;
                        break;
                    case "EBV_TYPE_CMS":
                    case 7:
                        message.source = 7;
                        break;
                    case "EBV_TYPE_BCBS":
                    case 8:
                        message.source = 8;
                        break;
                    case "EBV_TYPE_ARRIVE_HEALTH":
                    case 9:
                        message.source = 9;
                        break;
                    case "EBV_TYPE_KG_INTERNAL":
                    case 10:
                        message.source = 10;
                        break;
                    }
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.limit != null)
                        if ($util.Long)
                            (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                        else if (typeof object.limit === "string")
                            message.limit = parseInt(object.limit, 10);
                        else if (typeof object.limit === "number")
                            message.limit = object.limit;
                        else if (typeof object.limit === "object")
                            message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                    if (object.page != null)
                        if ($util.Long)
                            (message.page = $util.Long.fromValue(object.page)).unsigned = false;
                        else if (typeof object.page === "string")
                            message.page = parseInt(object.page, 10);
                        else if (typeof object.page === "number")
                            message.page = object.page;
                        else if (typeof object.page === "object")
                            message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber();
                    if (object.sortBy != null)
                        message.sortBy = String(object.sortBy);
                    if (object.sortOrder != null)
                        message.sortOrder = String(object.sortOrder);
                    return message;
                };
    
                /**
                 * Creates a plain object from a KGQueryOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {infinitusai.be.KGQueryOptions} message KGQueryOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                KGQueryOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.outputFieldFilter = "";
                        object.inputFieldFilter = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.outputTaskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.inputTaskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.source = options.enums === String ? "EBV_TYPE_UNKNOWN" : 0;
                        object.orgUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.limit = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.page = options.longs === String ? "0" : 0;
                        object.sortBy = "";
                        object.sortOrder = "";
                    }
                    if (message.outputFieldFilter != null && message.hasOwnProperty("outputFieldFilter"))
                        object.outputFieldFilter = message.outputFieldFilter;
                    if (message.inputFieldFilter != null && message.hasOwnProperty("inputFieldFilter"))
                        object.inputFieldFilter = message.inputFieldFilter;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.outputTaskType != null && message.hasOwnProperty("outputTaskType"))
                        object.outputTaskType = options.enums === String ? $root.infinitusai.be.TaskType[message.outputTaskType] === undefined ? message.outputTaskType : $root.infinitusai.be.TaskType[message.outputTaskType] : message.outputTaskType;
                    if (message.inputTaskType != null && message.hasOwnProperty("inputTaskType"))
                        object.inputTaskType = options.enums === String ? $root.infinitusai.be.TaskType[message.inputTaskType] === undefined ? message.inputTaskType : $root.infinitusai.be.TaskType[message.inputTaskType] : message.inputTaskType;
                    if (message.source != null && message.hasOwnProperty("source"))
                        object.source = options.enums === String ? $root.infinitusai.be.EbvType[message.source] === undefined ? message.source : $root.infinitusai.be.EbvType[message.source] : message.source;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (typeof message.limit === "number")
                            object.limit = options.longs === String ? String(message.limit) : message.limit;
                        else
                            object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (typeof message.page === "number")
                            object.page = options.longs === String ? String(message.page) : message.page;
                        else
                            object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber() : message.page;
                    if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                        object.sortBy = message.sortBy;
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = message.sortOrder;
                    return object;
                };
    
                /**
                 * Converts this KGQueryOptions to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.KGQueryOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                KGQueryOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for KGQueryOptions
                 * @function getTypeUrl
                 * @memberof infinitusai.be.KGQueryOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                KGQueryOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.KGQueryOptions";
                };
    
                return KGQueryOptions;
            })();
    
            be.GetKGRulesResponse = (function() {
    
                /**
                 * Properties of a GetKGRulesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetKGRulesResponse
                 * @property {Array.<infinitusai.be.IKGRule>|null} [approvedRules] GetKGRulesResponse approvedRules
                 */
    
                /**
                 * Constructs a new GetKGRulesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetKGRulesResponse.
                 * @implements IGetKGRulesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetKGRulesResponse=} [properties] Properties to set
                 */
                function GetKGRulesResponse(properties) {
                    this.approvedRules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetKGRulesResponse approvedRules.
                 * @member {Array.<infinitusai.be.IKGRule>} approvedRules
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @instance
                 */
                GetKGRulesResponse.prototype.approvedRules = $util.emptyArray;
    
                /**
                 * Creates a new GetKGRulesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetKGRulesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetKGRulesResponse} GetKGRulesResponse instance
                 */
                GetKGRulesResponse.create = function create(properties) {
                    return new GetKGRulesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetKGRulesResponse message. Does not implicitly {@link infinitusai.be.GetKGRulesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetKGRulesResponse} message GetKGRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetKGRulesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.approvedRules != null && message.approvedRules.length)
                        for (var i = 0; i < message.approvedRules.length; ++i)
                            $root.infinitusai.be.KGRule.encode(message.approvedRules[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetKGRulesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetKGRulesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetKGRulesResponse} message GetKGRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetKGRulesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetKGRulesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetKGRulesResponse} GetKGRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetKGRulesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetKGRulesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.approvedRules && message.approvedRules.length))
                                    message.approvedRules = [];
                                message.approvedRules.push($root.infinitusai.be.KGRule.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetKGRulesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetKGRulesResponse} GetKGRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetKGRulesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetKGRulesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetKGRulesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.approvedRules != null && message.hasOwnProperty("approvedRules")) {
                        if (!Array.isArray(message.approvedRules))
                            return "approvedRules: array expected";
                        for (var i = 0; i < message.approvedRules.length; ++i) {
                            var error = $root.infinitusai.be.KGRule.verify(message.approvedRules[i]);
                            if (error)
                                return "approvedRules." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetKGRulesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetKGRulesResponse} GetKGRulesResponse
                 */
                GetKGRulesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetKGRulesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetKGRulesResponse();
                    if (object.approvedRules) {
                        if (!Array.isArray(object.approvedRules))
                            throw TypeError(".infinitusai.be.GetKGRulesResponse.approvedRules: array expected");
                        message.approvedRules = [];
                        for (var i = 0; i < object.approvedRules.length; ++i) {
                            if (typeof object.approvedRules[i] !== "object")
                                throw TypeError(".infinitusai.be.GetKGRulesResponse.approvedRules: object expected");
                            message.approvedRules[i] = $root.infinitusai.be.KGRule.fromObject(object.approvedRules[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetKGRulesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {infinitusai.be.GetKGRulesResponse} message GetKGRulesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetKGRulesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.approvedRules = [];
                    if (message.approvedRules && message.approvedRules.length) {
                        object.approvedRules = [];
                        for (var j = 0; j < message.approvedRules.length; ++j)
                            object.approvedRules[j] = $root.infinitusai.be.KGRule.toObject(message.approvedRules[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetKGRulesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetKGRulesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetKGRulesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetKGRulesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetKGRulesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetKGRulesResponse";
                };
    
                return GetKGRulesResponse;
            })();
    
            be.GetPendingKGRulesResponse = (function() {
    
                /**
                 * Properties of a GetPendingKGRulesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetPendingKGRulesResponse
                 * @property {Array.<infinitusai.be.IPendingKGRule>|null} [pendingRules] GetPendingKGRulesResponse pendingRules
                 */
    
                /**
                 * Constructs a new GetPendingKGRulesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPendingKGRulesResponse.
                 * @implements IGetPendingKGRulesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetPendingKGRulesResponse=} [properties] Properties to set
                 */
                function GetPendingKGRulesResponse(properties) {
                    this.pendingRules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPendingKGRulesResponse pendingRules.
                 * @member {Array.<infinitusai.be.IPendingKGRule>} pendingRules
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @instance
                 */
                GetPendingKGRulesResponse.prototype.pendingRules = $util.emptyArray;
    
                /**
                 * Creates a new GetPendingKGRulesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetPendingKGRulesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPendingKGRulesResponse} GetPendingKGRulesResponse instance
                 */
                GetPendingKGRulesResponse.create = function create(properties) {
                    return new GetPendingKGRulesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPendingKGRulesResponse message. Does not implicitly {@link infinitusai.be.GetPendingKGRulesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetPendingKGRulesResponse} message GetPendingKGRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPendingKGRulesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pendingRules != null && message.pendingRules.length)
                        for (var i = 0; i < message.pendingRules.length; ++i)
                            $root.infinitusai.be.PendingKGRule.encode(message.pendingRules[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPendingKGRulesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetPendingKGRulesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IGetPendingKGRulesResponse} message GetPendingKGRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPendingKGRulesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPendingKGRulesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPendingKGRulesResponse} GetPendingKGRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPendingKGRulesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPendingKGRulesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                if (!(message.pendingRules && message.pendingRules.length))
                                    message.pendingRules = [];
                                message.pendingRules.push($root.infinitusai.be.PendingKGRule.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPendingKGRulesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPendingKGRulesResponse} GetPendingKGRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPendingKGRulesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPendingKGRulesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPendingKGRulesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pendingRules != null && message.hasOwnProperty("pendingRules")) {
                        if (!Array.isArray(message.pendingRules))
                            return "pendingRules: array expected";
                        for (var i = 0; i < message.pendingRules.length; ++i) {
                            var error = $root.infinitusai.be.PendingKGRule.verify(message.pendingRules[i]);
                            if (error)
                                return "pendingRules." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPendingKGRulesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPendingKGRulesResponse} GetPendingKGRulesResponse
                 */
                GetPendingKGRulesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPendingKGRulesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetPendingKGRulesResponse();
                    if (object.pendingRules) {
                        if (!Array.isArray(object.pendingRules))
                            throw TypeError(".infinitusai.be.GetPendingKGRulesResponse.pendingRules: array expected");
                        message.pendingRules = [];
                        for (var i = 0; i < object.pendingRules.length; ++i) {
                            if (typeof object.pendingRules[i] !== "object")
                                throw TypeError(".infinitusai.be.GetPendingKGRulesResponse.pendingRules: object expected");
                            message.pendingRules[i] = $root.infinitusai.be.PendingKGRule.fromObject(object.pendingRules[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPendingKGRulesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {infinitusai.be.GetPendingKGRulesResponse} message GetPendingKGRulesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPendingKGRulesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.pendingRules = [];
                    if (message.pendingRules && message.pendingRules.length) {
                        object.pendingRules = [];
                        for (var j = 0; j < message.pendingRules.length; ++j)
                            object.pendingRules[j] = $root.infinitusai.be.PendingKGRule.toObject(message.pendingRules[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPendingKGRulesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPendingKGRulesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPendingKGRulesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPendingKGRulesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPendingKGRulesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPendingKGRulesResponse";
                };
    
                return GetPendingKGRulesResponse;
            })();
    
            be.KGRule = (function() {
    
                /**
                 * Properties of a KGRule.
                 * @memberof infinitusai.be
                 * @interface IKGRule
                 * @property {Array.<string>|null} [inputFields] KGRule inputFields
                 * @property {string|null} [outputField] KGRule outputField
                 * @property {infinitusai.be.TaskType|null} [inputTaskType] KGRule inputTaskType
                 * @property {infinitusai.be.TaskType|null} [outputTaskType] KGRule outputTaskType
                 * @property {number|Long|null} [minSamples] KGRule minSamples
                 * @property {number|null} [minAccuracy] KGRule minAccuracy
                 * @property {string|null} [orgUuid] KGRule orgUuid
                 * @property {infinitusai.be.EbvType|null} [source] KGRule source
                 * @property {boolean|null} [active] KGRule active
                 */
    
                /**
                 * Constructs a new KGRule.
                 * @memberof infinitusai.be
                 * @classdesc Represents a KGRule.
                 * @implements IKGRule
                 * @constructor
                 * @param {infinitusai.be.IKGRule=} [properties] Properties to set
                 */
                function KGRule(properties) {
                    this.inputFields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * KGRule inputFields.
                 * @member {Array.<string>} inputFields
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.inputFields = $util.emptyArray;
    
                /**
                 * KGRule outputField.
                 * @member {string} outputField
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.outputField = "";
    
                /**
                 * KGRule inputTaskType.
                 * @member {infinitusai.be.TaskType} inputTaskType
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.inputTaskType = 0;
    
                /**
                 * KGRule outputTaskType.
                 * @member {infinitusai.be.TaskType} outputTaskType
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.outputTaskType = 0;
    
                /**
                 * KGRule minSamples.
                 * @member {number|Long} minSamples
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.minSamples = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * KGRule minAccuracy.
                 * @member {number} minAccuracy
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.minAccuracy = 0;
    
                /**
                 * KGRule orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.orgUuid = "";
    
                /**
                 * KGRule source.
                 * @member {infinitusai.be.EbvType} source
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.source = 0;
    
                /**
                 * KGRule active.
                 * @member {boolean} active
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 */
                KGRule.prototype.active = false;
    
                /**
                 * Creates a new KGRule instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {infinitusai.be.IKGRule=} [properties] Properties to set
                 * @returns {infinitusai.be.KGRule} KGRule instance
                 */
                KGRule.create = function create(properties) {
                    return new KGRule(properties);
                };
    
                /**
                 * Encodes the specified KGRule message. Does not implicitly {@link infinitusai.be.KGRule.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {infinitusai.be.IKGRule} message KGRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KGRule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inputFields != null && message.inputFields.length)
                        for (var i = 0; i < message.inputFields.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inputFields[i]);
                    if (message.outputField != null && Object.hasOwnProperty.call(message, "outputField"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.outputField);
                    if (message.inputTaskType != null && Object.hasOwnProperty.call(message, "inputTaskType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.inputTaskType);
                    if (message.outputTaskType != null && Object.hasOwnProperty.call(message, "outputTaskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.outputTaskType);
                    if (message.minSamples != null && Object.hasOwnProperty.call(message, "minSamples"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.minSamples);
                    if (message.minAccuracy != null && Object.hasOwnProperty.call(message, "minAccuracy"))
                        writer.uint32(/* id 6, wireType 5 =*/53).float(message.minAccuracy);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.orgUuid);
                    if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.source);
                    if (message.active != null && Object.hasOwnProperty.call(message, "active"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.active);
                    return writer;
                };
    
                /**
                 * Encodes the specified KGRule message, length delimited. Does not implicitly {@link infinitusai.be.KGRule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {infinitusai.be.IKGRule} message KGRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                KGRule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a KGRule message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.KGRule} KGRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KGRule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.KGRule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.inputFields && message.inputFields.length))
                                    message.inputFields = [];
                                message.inputFields.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.outputField = reader.string();
                                break;
                            }
                        case 3: {
                                message.inputTaskType = reader.int32();
                                break;
                            }
                        case 4: {
                                message.outputTaskType = reader.int32();
                                break;
                            }
                        case 5: {
                                message.minSamples = reader.int64();
                                break;
                            }
                        case 6: {
                                message.minAccuracy = reader.float();
                                break;
                            }
                        case 7: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 8: {
                                message.source = reader.int32();
                                break;
                            }
                        case 9: {
                                message.active = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a KGRule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.KGRule} KGRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                KGRule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a KGRule message.
                 * @function verify
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                KGRule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inputFields != null && message.hasOwnProperty("inputFields")) {
                        if (!Array.isArray(message.inputFields))
                            return "inputFields: array expected";
                        for (var i = 0; i < message.inputFields.length; ++i)
                            if (!$util.isString(message.inputFields[i]))
                                return "inputFields: string[] expected";
                    }
                    if (message.outputField != null && message.hasOwnProperty("outputField"))
                        if (!$util.isString(message.outputField))
                            return "outputField: string expected";
                    if (message.inputTaskType != null && message.hasOwnProperty("inputTaskType"))
                        switch (message.inputTaskType) {
                        default:
                            return "inputTaskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.outputTaskType != null && message.hasOwnProperty("outputTaskType"))
                        switch (message.outputTaskType) {
                        default:
                            return "outputTaskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.minSamples != null && message.hasOwnProperty("minSamples"))
                        if (!$util.isInteger(message.minSamples) && !(message.minSamples && $util.isInteger(message.minSamples.low) && $util.isInteger(message.minSamples.high)))
                            return "minSamples: integer|Long expected";
                    if (message.minAccuracy != null && message.hasOwnProperty("minAccuracy"))
                        if (typeof message.minAccuracy !== "number")
                            return "minAccuracy: number expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.source != null && message.hasOwnProperty("source"))
                        switch (message.source) {
                        default:
                            return "source: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            break;
                        }
                    if (message.active != null && message.hasOwnProperty("active"))
                        if (typeof message.active !== "boolean")
                            return "active: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a KGRule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.KGRule} KGRule
                 */
                KGRule.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.KGRule)
                        return object;
                    var message = new $root.infinitusai.be.KGRule();
                    if (object.inputFields) {
                        if (!Array.isArray(object.inputFields))
                            throw TypeError(".infinitusai.be.KGRule.inputFields: array expected");
                        message.inputFields = [];
                        for (var i = 0; i < object.inputFields.length; ++i)
                            message.inputFields[i] = String(object.inputFields[i]);
                    }
                    if (object.outputField != null)
                        message.outputField = String(object.outputField);
                    switch (object.inputTaskType) {
                    default:
                        if (typeof object.inputTaskType === "number") {
                            message.inputTaskType = object.inputTaskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.inputTaskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.inputTaskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.inputTaskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.inputTaskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.inputTaskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.inputTaskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.inputTaskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.inputTaskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.inputTaskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.inputTaskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.inputTaskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.inputTaskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.inputTaskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.inputTaskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.inputTaskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.inputTaskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.inputTaskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.inputTaskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.inputTaskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.inputTaskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.inputTaskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.inputTaskType = 21;
                        break;
                    }
                    switch (object.outputTaskType) {
                    default:
                        if (typeof object.outputTaskType === "number") {
                            message.outputTaskType = object.outputTaskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.outputTaskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.outputTaskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.outputTaskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.outputTaskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.outputTaskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.outputTaskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.outputTaskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.outputTaskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.outputTaskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.outputTaskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.outputTaskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.outputTaskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.outputTaskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.outputTaskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.outputTaskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.outputTaskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.outputTaskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.outputTaskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.outputTaskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.outputTaskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.outputTaskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.outputTaskType = 21;
                        break;
                    }
                    if (object.minSamples != null)
                        if ($util.Long)
                            (message.minSamples = $util.Long.fromValue(object.minSamples)).unsigned = false;
                        else if (typeof object.minSamples === "string")
                            message.minSamples = parseInt(object.minSamples, 10);
                        else if (typeof object.minSamples === "number")
                            message.minSamples = object.minSamples;
                        else if (typeof object.minSamples === "object")
                            message.minSamples = new $util.LongBits(object.minSamples.low >>> 0, object.minSamples.high >>> 0).toNumber();
                    if (object.minAccuracy != null)
                        message.minAccuracy = Number(object.minAccuracy);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    switch (object.source) {
                    default:
                        if (typeof object.source === "number") {
                            message.source = object.source;
                            break;
                        }
                        break;
                    case "EBV_TYPE_UNKNOWN":
                    case 0:
                        message.source = 0;
                        break;
                    case "EBV_TYPE_CHC":
                    case 1:
                        message.source = 1;
                        break;
                    case "EBV_TYPE_PAYER_API":
                    case 2:
                        message.source = 2;
                        break;
                    case "EBV_TYPE_STAT_PIE":
                    case 3:
                        message.source = 3;
                        break;
                    case "EBV_TYPE_MOCK":
                    case 4:
                        message.source = 4;
                        break;
                    case "EBV_TYPE_MEDICARE":
                    case 5:
                        message.source = 5;
                        break;
                    case "EBV_TYPE_AVAILITY":
                    case 6:
                        message.source = 6;
                        break;
                    case "EBV_TYPE_CMS":
                    case 7:
                        message.source = 7;
                        break;
                    case "EBV_TYPE_BCBS":
                    case 8:
                        message.source = 8;
                        break;
                    case "EBV_TYPE_ARRIVE_HEALTH":
                    case 9:
                        message.source = 9;
                        break;
                    case "EBV_TYPE_KG_INTERNAL":
                    case 10:
                        message.source = 10;
                        break;
                    }
                    if (object.active != null)
                        message.active = Boolean(object.active);
                    return message;
                };
    
                /**
                 * Creates a plain object from a KGRule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {infinitusai.be.KGRule} message KGRule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                KGRule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.inputFields = [];
                    if (options.defaults) {
                        object.outputField = "";
                        object.inputTaskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.outputTaskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.minSamples = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.minSamples = options.longs === String ? "0" : 0;
                        object.minAccuracy = 0;
                        object.orgUuid = "";
                        object.source = options.enums === String ? "EBV_TYPE_UNKNOWN" : 0;
                        object.active = false;
                    }
                    if (message.inputFields && message.inputFields.length) {
                        object.inputFields = [];
                        for (var j = 0; j < message.inputFields.length; ++j)
                            object.inputFields[j] = message.inputFields[j];
                    }
                    if (message.outputField != null && message.hasOwnProperty("outputField"))
                        object.outputField = message.outputField;
                    if (message.inputTaskType != null && message.hasOwnProperty("inputTaskType"))
                        object.inputTaskType = options.enums === String ? $root.infinitusai.be.TaskType[message.inputTaskType] === undefined ? message.inputTaskType : $root.infinitusai.be.TaskType[message.inputTaskType] : message.inputTaskType;
                    if (message.outputTaskType != null && message.hasOwnProperty("outputTaskType"))
                        object.outputTaskType = options.enums === String ? $root.infinitusai.be.TaskType[message.outputTaskType] === undefined ? message.outputTaskType : $root.infinitusai.be.TaskType[message.outputTaskType] : message.outputTaskType;
                    if (message.minSamples != null && message.hasOwnProperty("minSamples"))
                        if (typeof message.minSamples === "number")
                            object.minSamples = options.longs === String ? String(message.minSamples) : message.minSamples;
                        else
                            object.minSamples = options.longs === String ? $util.Long.prototype.toString.call(message.minSamples) : options.longs === Number ? new $util.LongBits(message.minSamples.low >>> 0, message.minSamples.high >>> 0).toNumber() : message.minSamples;
                    if (message.minAccuracy != null && message.hasOwnProperty("minAccuracy"))
                        object.minAccuracy = options.json && !isFinite(message.minAccuracy) ? String(message.minAccuracy) : message.minAccuracy;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.source != null && message.hasOwnProperty("source"))
                        object.source = options.enums === String ? $root.infinitusai.be.EbvType[message.source] === undefined ? message.source : $root.infinitusai.be.EbvType[message.source] : message.source;
                    if (message.active != null && message.hasOwnProperty("active"))
                        object.active = message.active;
                    return object;
                };
    
                /**
                 * Converts this KGRule to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.KGRule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                KGRule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for KGRule
                 * @function getTypeUrl
                 * @memberof infinitusai.be.KGRule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                KGRule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.KGRule";
                };
    
                return KGRule;
            })();
    
            be.PendingKGRule = (function() {
    
                /**
                 * Properties of a PendingKGRule.
                 * @memberof infinitusai.be
                 * @interface IPendingKGRule
                 * @property {Array.<string>|null} [inputFields] PendingKGRule inputFields
                 * @property {string|null} [outputField] PendingKGRule outputField
                 * @property {infinitusai.be.TaskType|null} [taskType] PendingKGRule taskType
                 * @property {number|Long|null} [explorationTotalRuleSamples] PendingKGRule explorationTotalRuleSamples
                 * @property {number|null} [explorationAverageAccuracy] PendingKGRule explorationAverageAccuracy
                 * @property {number|Long|null} [explorationNumberRulesFound] PendingKGRule explorationNumberRulesFound
                 * @property {infinitusai.be.PendingKGRule.ReviewStatus|null} [reviewStatus] PendingKGRule reviewStatus
                 * @property {string|null} [reviewComments] PendingKGRule reviewComments
                 * @property {string|null} [orgUuid] PendingKGRule orgUuid
                 */
    
                /**
                 * Constructs a new PendingKGRule.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PendingKGRule.
                 * @implements IPendingKGRule
                 * @constructor
                 * @param {infinitusai.be.IPendingKGRule=} [properties] Properties to set
                 */
                function PendingKGRule(properties) {
                    this.inputFields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PendingKGRule inputFields.
                 * @member {Array.<string>} inputFields
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.inputFields = $util.emptyArray;
    
                /**
                 * PendingKGRule outputField.
                 * @member {string} outputField
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.outputField = "";
    
                /**
                 * PendingKGRule taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.taskType = 0;
    
                /**
                 * PendingKGRule explorationTotalRuleSamples.
                 * @member {number|Long} explorationTotalRuleSamples
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.explorationTotalRuleSamples = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * PendingKGRule explorationAverageAccuracy.
                 * @member {number} explorationAverageAccuracy
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.explorationAverageAccuracy = 0;
    
                /**
                 * PendingKGRule explorationNumberRulesFound.
                 * @member {number|Long} explorationNumberRulesFound
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.explorationNumberRulesFound = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * PendingKGRule reviewStatus.
                 * @member {infinitusai.be.PendingKGRule.ReviewStatus} reviewStatus
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.reviewStatus = 0;
    
                /**
                 * PendingKGRule reviewComments.
                 * @member {string} reviewComments
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.reviewComments = "";
    
                /**
                 * PendingKGRule orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 */
                PendingKGRule.prototype.orgUuid = "";
    
                /**
                 * Creates a new PendingKGRule instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {infinitusai.be.IPendingKGRule=} [properties] Properties to set
                 * @returns {infinitusai.be.PendingKGRule} PendingKGRule instance
                 */
                PendingKGRule.create = function create(properties) {
                    return new PendingKGRule(properties);
                };
    
                /**
                 * Encodes the specified PendingKGRule message. Does not implicitly {@link infinitusai.be.PendingKGRule.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {infinitusai.be.IPendingKGRule} message PendingKGRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PendingKGRule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inputFields != null && message.inputFields.length)
                        for (var i = 0; i < message.inputFields.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inputFields[i]);
                    if (message.outputField != null && Object.hasOwnProperty.call(message, "outputField"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.outputField);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.taskType);
                    if (message.explorationTotalRuleSamples != null && Object.hasOwnProperty.call(message, "explorationTotalRuleSamples"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.explorationTotalRuleSamples);
                    if (message.explorationAverageAccuracy != null && Object.hasOwnProperty.call(message, "explorationAverageAccuracy"))
                        writer.uint32(/* id 5, wireType 5 =*/45).float(message.explorationAverageAccuracy);
                    if (message.explorationNumberRulesFound != null && Object.hasOwnProperty.call(message, "explorationNumberRulesFound"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.explorationNumberRulesFound);
                    if (message.reviewStatus != null && Object.hasOwnProperty.call(message, "reviewStatus"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.reviewStatus);
                    if (message.reviewComments != null && Object.hasOwnProperty.call(message, "reviewComments"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.reviewComments);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified PendingKGRule message, length delimited. Does not implicitly {@link infinitusai.be.PendingKGRule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {infinitusai.be.IPendingKGRule} message PendingKGRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PendingKGRule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PendingKGRule message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PendingKGRule} PendingKGRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PendingKGRule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PendingKGRule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.inputFields && message.inputFields.length))
                                    message.inputFields = [];
                                message.inputFields.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.outputField = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 4: {
                                message.explorationTotalRuleSamples = reader.int64();
                                break;
                            }
                        case 5: {
                                message.explorationAverageAccuracy = reader.float();
                                break;
                            }
                        case 6: {
                                message.explorationNumberRulesFound = reader.int64();
                                break;
                            }
                        case 7: {
                                message.reviewStatus = reader.int32();
                                break;
                            }
                        case 8: {
                                message.reviewComments = reader.string();
                                break;
                            }
                        case 9: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PendingKGRule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PendingKGRule} PendingKGRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PendingKGRule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PendingKGRule message.
                 * @function verify
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PendingKGRule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inputFields != null && message.hasOwnProperty("inputFields")) {
                        if (!Array.isArray(message.inputFields))
                            return "inputFields: array expected";
                        for (var i = 0; i < message.inputFields.length; ++i)
                            if (!$util.isString(message.inputFields[i]))
                                return "inputFields: string[] expected";
                    }
                    if (message.outputField != null && message.hasOwnProperty("outputField"))
                        if (!$util.isString(message.outputField))
                            return "outputField: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.explorationTotalRuleSamples != null && message.hasOwnProperty("explorationTotalRuleSamples"))
                        if (!$util.isInteger(message.explorationTotalRuleSamples) && !(message.explorationTotalRuleSamples && $util.isInteger(message.explorationTotalRuleSamples.low) && $util.isInteger(message.explorationTotalRuleSamples.high)))
                            return "explorationTotalRuleSamples: integer|Long expected";
                    if (message.explorationAverageAccuracy != null && message.hasOwnProperty("explorationAverageAccuracy"))
                        if (typeof message.explorationAverageAccuracy !== "number")
                            return "explorationAverageAccuracy: number expected";
                    if (message.explorationNumberRulesFound != null && message.hasOwnProperty("explorationNumberRulesFound"))
                        if (!$util.isInteger(message.explorationNumberRulesFound) && !(message.explorationNumberRulesFound && $util.isInteger(message.explorationNumberRulesFound.low) && $util.isInteger(message.explorationNumberRulesFound.high)))
                            return "explorationNumberRulesFound: integer|Long expected";
                    if (message.reviewStatus != null && message.hasOwnProperty("reviewStatus"))
                        switch (message.reviewStatus) {
                        default:
                            return "reviewStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.reviewComments != null && message.hasOwnProperty("reviewComments"))
                        if (!$util.isString(message.reviewComments))
                            return "reviewComments: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a PendingKGRule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PendingKGRule} PendingKGRule
                 */
                PendingKGRule.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PendingKGRule)
                        return object;
                    var message = new $root.infinitusai.be.PendingKGRule();
                    if (object.inputFields) {
                        if (!Array.isArray(object.inputFields))
                            throw TypeError(".infinitusai.be.PendingKGRule.inputFields: array expected");
                        message.inputFields = [];
                        for (var i = 0; i < object.inputFields.length; ++i)
                            message.inputFields[i] = String(object.inputFields[i]);
                    }
                    if (object.outputField != null)
                        message.outputField = String(object.outputField);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.explorationTotalRuleSamples != null)
                        if ($util.Long)
                            (message.explorationTotalRuleSamples = $util.Long.fromValue(object.explorationTotalRuleSamples)).unsigned = false;
                        else if (typeof object.explorationTotalRuleSamples === "string")
                            message.explorationTotalRuleSamples = parseInt(object.explorationTotalRuleSamples, 10);
                        else if (typeof object.explorationTotalRuleSamples === "number")
                            message.explorationTotalRuleSamples = object.explorationTotalRuleSamples;
                        else if (typeof object.explorationTotalRuleSamples === "object")
                            message.explorationTotalRuleSamples = new $util.LongBits(object.explorationTotalRuleSamples.low >>> 0, object.explorationTotalRuleSamples.high >>> 0).toNumber();
                    if (object.explorationAverageAccuracy != null)
                        message.explorationAverageAccuracy = Number(object.explorationAverageAccuracy);
                    if (object.explorationNumberRulesFound != null)
                        if ($util.Long)
                            (message.explorationNumberRulesFound = $util.Long.fromValue(object.explorationNumberRulesFound)).unsigned = false;
                        else if (typeof object.explorationNumberRulesFound === "string")
                            message.explorationNumberRulesFound = parseInt(object.explorationNumberRulesFound, 10);
                        else if (typeof object.explorationNumberRulesFound === "number")
                            message.explorationNumberRulesFound = object.explorationNumberRulesFound;
                        else if (typeof object.explorationNumberRulesFound === "object")
                            message.explorationNumberRulesFound = new $util.LongBits(object.explorationNumberRulesFound.low >>> 0, object.explorationNumberRulesFound.high >>> 0).toNumber();
                    switch (object.reviewStatus) {
                    default:
                        if (typeof object.reviewStatus === "number") {
                            message.reviewStatus = object.reviewStatus;
                            break;
                        }
                        break;
                    case "PENDING":
                    case 0:
                        message.reviewStatus = 0;
                        break;
                    case "APPROVED":
                    case 1:
                        message.reviewStatus = 1;
                        break;
                    case "REJECTED":
                    case 2:
                        message.reviewStatus = 2;
                        break;
                    }
                    if (object.reviewComments != null)
                        message.reviewComments = String(object.reviewComments);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PendingKGRule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {infinitusai.be.PendingKGRule} message PendingKGRule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PendingKGRule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.inputFields = [];
                    if (options.defaults) {
                        object.outputField = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.explorationTotalRuleSamples = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.explorationTotalRuleSamples = options.longs === String ? "0" : 0;
                        object.explorationAverageAccuracy = 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.explorationNumberRulesFound = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.explorationNumberRulesFound = options.longs === String ? "0" : 0;
                        object.reviewStatus = options.enums === String ? "PENDING" : 0;
                        object.reviewComments = "";
                        object.orgUuid = "";
                    }
                    if (message.inputFields && message.inputFields.length) {
                        object.inputFields = [];
                        for (var j = 0; j < message.inputFields.length; ++j)
                            object.inputFields[j] = message.inputFields[j];
                    }
                    if (message.outputField != null && message.hasOwnProperty("outputField"))
                        object.outputField = message.outputField;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.explorationTotalRuleSamples != null && message.hasOwnProperty("explorationTotalRuleSamples"))
                        if (typeof message.explorationTotalRuleSamples === "number")
                            object.explorationTotalRuleSamples = options.longs === String ? String(message.explorationTotalRuleSamples) : message.explorationTotalRuleSamples;
                        else
                            object.explorationTotalRuleSamples = options.longs === String ? $util.Long.prototype.toString.call(message.explorationTotalRuleSamples) : options.longs === Number ? new $util.LongBits(message.explorationTotalRuleSamples.low >>> 0, message.explorationTotalRuleSamples.high >>> 0).toNumber() : message.explorationTotalRuleSamples;
                    if (message.explorationAverageAccuracy != null && message.hasOwnProperty("explorationAverageAccuracy"))
                        object.explorationAverageAccuracy = options.json && !isFinite(message.explorationAverageAccuracy) ? String(message.explorationAverageAccuracy) : message.explorationAverageAccuracy;
                    if (message.explorationNumberRulesFound != null && message.hasOwnProperty("explorationNumberRulesFound"))
                        if (typeof message.explorationNumberRulesFound === "number")
                            object.explorationNumberRulesFound = options.longs === String ? String(message.explorationNumberRulesFound) : message.explorationNumberRulesFound;
                        else
                            object.explorationNumberRulesFound = options.longs === String ? $util.Long.prototype.toString.call(message.explorationNumberRulesFound) : options.longs === Number ? new $util.LongBits(message.explorationNumberRulesFound.low >>> 0, message.explorationNumberRulesFound.high >>> 0).toNumber() : message.explorationNumberRulesFound;
                    if (message.reviewStatus != null && message.hasOwnProperty("reviewStatus"))
                        object.reviewStatus = options.enums === String ? $root.infinitusai.be.PendingKGRule.ReviewStatus[message.reviewStatus] === undefined ? message.reviewStatus : $root.infinitusai.be.PendingKGRule.ReviewStatus[message.reviewStatus] : message.reviewStatus;
                    if (message.reviewComments != null && message.hasOwnProperty("reviewComments"))
                        object.reviewComments = message.reviewComments;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this PendingKGRule to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PendingKGRule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PendingKGRule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PendingKGRule
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PendingKGRule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PendingKGRule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PendingKGRule";
                };
    
                /**
                 * ReviewStatus enum.
                 * @name infinitusai.be.PendingKGRule.ReviewStatus
                 * @enum {number}
                 * @property {number} PENDING=0 PENDING value
                 * @property {number} APPROVED=1 APPROVED value
                 * @property {number} REJECTED=2 REJECTED value
                 */
                PendingKGRule.ReviewStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PENDING"] = 0;
                    values[valuesById[1] = "APPROVED"] = 1;
                    values[valuesById[2] = "REJECTED"] = 2;
                    return values;
                })();
    
                return PendingKGRule;
            })();
    
            be.UpdateKGRulesRequest = (function() {
    
                /**
                 * Properties of an UpdateKGRulesRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateKGRulesRequest
                 * @property {Array.<infinitusai.be.IKGRule>|null} [rules] UpdateKGRulesRequest rules
                 * @property {Array.<infinitusai.be.IPendingKGRule>|null} [pendingRules] UpdateKGRulesRequest pendingRules
                 */
    
                /**
                 * Constructs a new UpdateKGRulesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateKGRulesRequest.
                 * @implements IUpdateKGRulesRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateKGRulesRequest=} [properties] Properties to set
                 */
                function UpdateKGRulesRequest(properties) {
                    this.rules = [];
                    this.pendingRules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateKGRulesRequest rules.
                 * @member {Array.<infinitusai.be.IKGRule>} rules
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @instance
                 */
                UpdateKGRulesRequest.prototype.rules = $util.emptyArray;
    
                /**
                 * UpdateKGRulesRequest pendingRules.
                 * @member {Array.<infinitusai.be.IPendingKGRule>} pendingRules
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @instance
                 */
                UpdateKGRulesRequest.prototype.pendingRules = $util.emptyArray;
    
                /**
                 * Creates a new UpdateKGRulesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {infinitusai.be.IUpdateKGRulesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateKGRulesRequest} UpdateKGRulesRequest instance
                 */
                UpdateKGRulesRequest.create = function create(properties) {
                    return new UpdateKGRulesRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateKGRulesRequest message. Does not implicitly {@link infinitusai.be.UpdateKGRulesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {infinitusai.be.IUpdateKGRulesRequest} message UpdateKGRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateKGRulesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.rules != null && message.rules.length)
                        for (var i = 0; i < message.rules.length; ++i)
                            $root.infinitusai.be.KGRule.encode(message.rules[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.pendingRules != null && message.pendingRules.length)
                        for (var i = 0; i < message.pendingRules.length; ++i)
                            $root.infinitusai.be.PendingKGRule.encode(message.pendingRules[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateKGRulesRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateKGRulesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {infinitusai.be.IUpdateKGRulesRequest} message UpdateKGRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateKGRulesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateKGRulesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateKGRulesRequest} UpdateKGRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateKGRulesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateKGRulesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.rules && message.rules.length))
                                    message.rules = [];
                                message.rules.push($root.infinitusai.be.KGRule.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.pendingRules && message.pendingRules.length))
                                    message.pendingRules = [];
                                message.pendingRules.push($root.infinitusai.be.PendingKGRule.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateKGRulesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateKGRulesRequest} UpdateKGRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateKGRulesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateKGRulesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateKGRulesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.rules != null && message.hasOwnProperty("rules")) {
                        if (!Array.isArray(message.rules))
                            return "rules: array expected";
                        for (var i = 0; i < message.rules.length; ++i) {
                            var error = $root.infinitusai.be.KGRule.verify(message.rules[i]);
                            if (error)
                                return "rules." + error;
                        }
                    }
                    if (message.pendingRules != null && message.hasOwnProperty("pendingRules")) {
                        if (!Array.isArray(message.pendingRules))
                            return "pendingRules: array expected";
                        for (var i = 0; i < message.pendingRules.length; ++i) {
                            var error = $root.infinitusai.be.PendingKGRule.verify(message.pendingRules[i]);
                            if (error)
                                return "pendingRules." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateKGRulesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateKGRulesRequest} UpdateKGRulesRequest
                 */
                UpdateKGRulesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateKGRulesRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateKGRulesRequest();
                    if (object.rules) {
                        if (!Array.isArray(object.rules))
                            throw TypeError(".infinitusai.be.UpdateKGRulesRequest.rules: array expected");
                        message.rules = [];
                        for (var i = 0; i < object.rules.length; ++i) {
                            if (typeof object.rules[i] !== "object")
                                throw TypeError(".infinitusai.be.UpdateKGRulesRequest.rules: object expected");
                            message.rules[i] = $root.infinitusai.be.KGRule.fromObject(object.rules[i]);
                        }
                    }
                    if (object.pendingRules) {
                        if (!Array.isArray(object.pendingRules))
                            throw TypeError(".infinitusai.be.UpdateKGRulesRequest.pendingRules: array expected");
                        message.pendingRules = [];
                        for (var i = 0; i < object.pendingRules.length; ++i) {
                            if (typeof object.pendingRules[i] !== "object")
                                throw TypeError(".infinitusai.be.UpdateKGRulesRequest.pendingRules: object expected");
                            message.pendingRules[i] = $root.infinitusai.be.PendingKGRule.fromObject(object.pendingRules[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateKGRulesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {infinitusai.be.UpdateKGRulesRequest} message UpdateKGRulesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateKGRulesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.rules = [];
                        object.pendingRules = [];
                    }
                    if (message.rules && message.rules.length) {
                        object.rules = [];
                        for (var j = 0; j < message.rules.length; ++j)
                            object.rules[j] = $root.infinitusai.be.KGRule.toObject(message.rules[j], options);
                    }
                    if (message.pendingRules && message.pendingRules.length) {
                        object.pendingRules = [];
                        for (var j = 0; j < message.pendingRules.length; ++j)
                            object.pendingRules[j] = $root.infinitusai.be.PendingKGRule.toObject(message.pendingRules[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this UpdateKGRulesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateKGRulesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateKGRulesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateKGRulesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateKGRulesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateKGRulesRequest";
                };
    
                return UpdateKGRulesRequest;
            })();
    
            be.UpdateKGRulesResponse = (function() {
    
                /**
                 * Properties of an UpdateKGRulesResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateKGRulesResponse
                 */
    
                /**
                 * Constructs a new UpdateKGRulesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateKGRulesResponse.
                 * @implements IUpdateKGRulesResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateKGRulesResponse=} [properties] Properties to set
                 */
                function UpdateKGRulesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpdateKGRulesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IUpdateKGRulesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateKGRulesResponse} UpdateKGRulesResponse instance
                 */
                UpdateKGRulesResponse.create = function create(properties) {
                    return new UpdateKGRulesResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateKGRulesResponse message. Does not implicitly {@link infinitusai.be.UpdateKGRulesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IUpdateKGRulesResponse} message UpdateKGRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateKGRulesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateKGRulesResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateKGRulesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {infinitusai.be.IUpdateKGRulesResponse} message UpdateKGRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateKGRulesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateKGRulesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateKGRulesResponse} UpdateKGRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateKGRulesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateKGRulesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateKGRulesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateKGRulesResponse} UpdateKGRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateKGRulesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateKGRulesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateKGRulesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateKGRulesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateKGRulesResponse} UpdateKGRulesResponse
                 */
                UpdateKGRulesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateKGRulesResponse)
                        return object;
                    return new $root.infinitusai.be.UpdateKGRulesResponse();
                };
    
                /**
                 * Creates a plain object from an UpdateKGRulesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {infinitusai.be.UpdateKGRulesResponse} message UpdateKGRulesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateKGRulesResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpdateKGRulesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateKGRulesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateKGRulesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateKGRulesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateKGRulesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateKGRulesResponse";
                };
    
                return UpdateKGRulesResponse;
            })();
    
            be.LogFrontendErrorRequest = (function() {
    
                /**
                 * Properties of a LogFrontendErrorRequest.
                 * @memberof infinitusai.be
                 * @interface ILogFrontendErrorRequest
                 * @property {number|Long|null} [timestampMillis] LogFrontendErrorRequest timestampMillis
                 * @property {string|null} [message] LogFrontendErrorRequest message
                 * @property {string|null} [stackTrace] LogFrontendErrorRequest stackTrace
                 * @property {string|null} [sourceFile] LogFrontendErrorRequest sourceFile
                 * @property {number|null} [lineNumber] LogFrontendErrorRequest lineNumber
                 * @property {number|null} [columnNumber] LogFrontendErrorRequest columnNumber
                 */
    
                /**
                 * Constructs a new LogFrontendErrorRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LogFrontendErrorRequest.
                 * @implements ILogFrontendErrorRequest
                 * @constructor
                 * @param {infinitusai.be.ILogFrontendErrorRequest=} [properties] Properties to set
                 */
                function LogFrontendErrorRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LogFrontendErrorRequest timestampMillis.
                 * @member {number|Long} timestampMillis
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @instance
                 */
                LogFrontendErrorRequest.prototype.timestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * LogFrontendErrorRequest message.
                 * @member {string} message
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @instance
                 */
                LogFrontendErrorRequest.prototype.message = "";
    
                /**
                 * LogFrontendErrorRequest stackTrace.
                 * @member {string} stackTrace
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @instance
                 */
                LogFrontendErrorRequest.prototype.stackTrace = "";
    
                /**
                 * LogFrontendErrorRequest sourceFile.
                 * @member {string} sourceFile
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @instance
                 */
                LogFrontendErrorRequest.prototype.sourceFile = "";
    
                /**
                 * LogFrontendErrorRequest lineNumber.
                 * @member {number} lineNumber
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @instance
                 */
                LogFrontendErrorRequest.prototype.lineNumber = 0;
    
                /**
                 * LogFrontendErrorRequest columnNumber.
                 * @member {number} columnNumber
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @instance
                 */
                LogFrontendErrorRequest.prototype.columnNumber = 0;
    
                /**
                 * Creates a new LogFrontendErrorRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {infinitusai.be.ILogFrontendErrorRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.LogFrontendErrorRequest} LogFrontendErrorRequest instance
                 */
                LogFrontendErrorRequest.create = function create(properties) {
                    return new LogFrontendErrorRequest(properties);
                };
    
                /**
                 * Encodes the specified LogFrontendErrorRequest message. Does not implicitly {@link infinitusai.be.LogFrontendErrorRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {infinitusai.be.ILogFrontendErrorRequest} message LogFrontendErrorRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogFrontendErrorRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.timestampMillis != null && Object.hasOwnProperty.call(message, "timestampMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timestampMillis);
                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                    if (message.stackTrace != null && Object.hasOwnProperty.call(message, "stackTrace"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.stackTrace);
                    if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.sourceFile);
                    if (message.lineNumber != null && Object.hasOwnProperty.call(message, "lineNumber"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.lineNumber);
                    if (message.columnNumber != null && Object.hasOwnProperty.call(message, "columnNumber"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.columnNumber);
                    return writer;
                };
    
                /**
                 * Encodes the specified LogFrontendErrorRequest message, length delimited. Does not implicitly {@link infinitusai.be.LogFrontendErrorRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {infinitusai.be.ILogFrontendErrorRequest} message LogFrontendErrorRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogFrontendErrorRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LogFrontendErrorRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LogFrontendErrorRequest} LogFrontendErrorRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogFrontendErrorRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LogFrontendErrorRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.timestampMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                message.message = reader.string();
                                break;
                            }
                        case 3: {
                                message.stackTrace = reader.string();
                                break;
                            }
                        case 4: {
                                message.sourceFile = reader.string();
                                break;
                            }
                        case 5: {
                                message.lineNumber = reader.int32();
                                break;
                            }
                        case 6: {
                                message.columnNumber = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LogFrontendErrorRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LogFrontendErrorRequest} LogFrontendErrorRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogFrontendErrorRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LogFrontendErrorRequest message.
                 * @function verify
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogFrontendErrorRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.timestampMillis != null && message.hasOwnProperty("timestampMillis"))
                        if (!$util.isInteger(message.timestampMillis) && !(message.timestampMillis && $util.isInteger(message.timestampMillis.low) && $util.isInteger(message.timestampMillis.high)))
                            return "timestampMillis: integer|Long expected";
                    if (message.message != null && message.hasOwnProperty("message"))
                        if (!$util.isString(message.message))
                            return "message: string expected";
                    if (message.stackTrace != null && message.hasOwnProperty("stackTrace"))
                        if (!$util.isString(message.stackTrace))
                            return "stackTrace: string expected";
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.lineNumber != null && message.hasOwnProperty("lineNumber"))
                        if (!$util.isInteger(message.lineNumber))
                            return "lineNumber: integer expected";
                    if (message.columnNumber != null && message.hasOwnProperty("columnNumber"))
                        if (!$util.isInteger(message.columnNumber))
                            return "columnNumber: integer expected";
                    return null;
                };
    
                /**
                 * Creates a LogFrontendErrorRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LogFrontendErrorRequest} LogFrontendErrorRequest
                 */
                LogFrontendErrorRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LogFrontendErrorRequest)
                        return object;
                    var message = new $root.infinitusai.be.LogFrontendErrorRequest();
                    if (object.timestampMillis != null)
                        if ($util.Long)
                            (message.timestampMillis = $util.Long.fromValue(object.timestampMillis)).unsigned = false;
                        else if (typeof object.timestampMillis === "string")
                            message.timestampMillis = parseInt(object.timestampMillis, 10);
                        else if (typeof object.timestampMillis === "number")
                            message.timestampMillis = object.timestampMillis;
                        else if (typeof object.timestampMillis === "object")
                            message.timestampMillis = new $util.LongBits(object.timestampMillis.low >>> 0, object.timestampMillis.high >>> 0).toNumber();
                    if (object.message != null)
                        message.message = String(object.message);
                    if (object.stackTrace != null)
                        message.stackTrace = String(object.stackTrace);
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.lineNumber != null)
                        message.lineNumber = object.lineNumber | 0;
                    if (object.columnNumber != null)
                        message.columnNumber = object.columnNumber | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a LogFrontendErrorRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {infinitusai.be.LogFrontendErrorRequest} message LogFrontendErrorRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogFrontendErrorRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestampMillis = options.longs === String ? "0" : 0;
                        object.message = "";
                        object.stackTrace = "";
                        object.sourceFile = "";
                        object.lineNumber = 0;
                        object.columnNumber = 0;
                    }
                    if (message.timestampMillis != null && message.hasOwnProperty("timestampMillis"))
                        if (typeof message.timestampMillis === "number")
                            object.timestampMillis = options.longs === String ? String(message.timestampMillis) : message.timestampMillis;
                        else
                            object.timestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.timestampMillis) : options.longs === Number ? new $util.LongBits(message.timestampMillis.low >>> 0, message.timestampMillis.high >>> 0).toNumber() : message.timestampMillis;
                    if (message.message != null && message.hasOwnProperty("message"))
                        object.message = message.message;
                    if (message.stackTrace != null && message.hasOwnProperty("stackTrace"))
                        object.stackTrace = message.stackTrace;
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.lineNumber != null && message.hasOwnProperty("lineNumber"))
                        object.lineNumber = message.lineNumber;
                    if (message.columnNumber != null && message.hasOwnProperty("columnNumber"))
                        object.columnNumber = message.columnNumber;
                    return object;
                };
    
                /**
                 * Converts this LogFrontendErrorRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogFrontendErrorRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LogFrontendErrorRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LogFrontendErrorRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LogFrontendErrorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LogFrontendErrorRequest";
                };
    
                return LogFrontendErrorRequest;
            })();
    
            be.LogFrontendErrorResponse = (function() {
    
                /**
                 * Properties of a LogFrontendErrorResponse.
                 * @memberof infinitusai.be
                 * @interface ILogFrontendErrorResponse
                 */
    
                /**
                 * Constructs a new LogFrontendErrorResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LogFrontendErrorResponse.
                 * @implements ILogFrontendErrorResponse
                 * @constructor
                 * @param {infinitusai.be.ILogFrontendErrorResponse=} [properties] Properties to set
                 */
                function LogFrontendErrorResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new LogFrontendErrorResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {infinitusai.be.ILogFrontendErrorResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.LogFrontendErrorResponse} LogFrontendErrorResponse instance
                 */
                LogFrontendErrorResponse.create = function create(properties) {
                    return new LogFrontendErrorResponse(properties);
                };
    
                /**
                 * Encodes the specified LogFrontendErrorResponse message. Does not implicitly {@link infinitusai.be.LogFrontendErrorResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {infinitusai.be.ILogFrontendErrorResponse} message LogFrontendErrorResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogFrontendErrorResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified LogFrontendErrorResponse message, length delimited. Does not implicitly {@link infinitusai.be.LogFrontendErrorResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {infinitusai.be.ILogFrontendErrorResponse} message LogFrontendErrorResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogFrontendErrorResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LogFrontendErrorResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LogFrontendErrorResponse} LogFrontendErrorResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogFrontendErrorResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LogFrontendErrorResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LogFrontendErrorResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LogFrontendErrorResponse} LogFrontendErrorResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogFrontendErrorResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LogFrontendErrorResponse message.
                 * @function verify
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogFrontendErrorResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a LogFrontendErrorResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LogFrontendErrorResponse} LogFrontendErrorResponse
                 */
                LogFrontendErrorResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LogFrontendErrorResponse)
                        return object;
                    return new $root.infinitusai.be.LogFrontendErrorResponse();
                };
    
                /**
                 * Creates a plain object from a LogFrontendErrorResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {infinitusai.be.LogFrontendErrorResponse} message LogFrontendErrorResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogFrontendErrorResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this LogFrontendErrorResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogFrontendErrorResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LogFrontendErrorResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LogFrontendErrorResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LogFrontendErrorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LogFrontendErrorResponse";
                };
    
                return LogFrontendErrorResponse;
            })();
    
            be.Experiment = (function() {
    
                /**
                 * Properties of an Experiment.
                 * @memberof infinitusai.be
                 * @interface IExperiment
                 * @property {string|null} [name] Experiment name
                 * @property {number|Long|null} [startTimeMillis] Experiment startTimeMillis
                 * @property {number|Long|null} [endTimeMillis] Experiment endTimeMillis
                 * @property {boolean|null} [isActive] Experiment isActive
                 */
    
                /**
                 * Constructs a new Experiment.
                 * @memberof infinitusai.be
                 * @classdesc Represents an Experiment.
                 * @implements IExperiment
                 * @constructor
                 * @param {infinitusai.be.IExperiment=} [properties] Properties to set
                 */
                function Experiment(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Experiment name.
                 * @member {string} name
                 * @memberof infinitusai.be.Experiment
                 * @instance
                 */
                Experiment.prototype.name = "";
    
                /**
                 * Experiment startTimeMillis.
                 * @member {number|Long} startTimeMillis
                 * @memberof infinitusai.be.Experiment
                 * @instance
                 */
                Experiment.prototype.startTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Experiment endTimeMillis.
                 * @member {number|Long} endTimeMillis
                 * @memberof infinitusai.be.Experiment
                 * @instance
                 */
                Experiment.prototype.endTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Experiment isActive.
                 * @member {boolean} isActive
                 * @memberof infinitusai.be.Experiment
                 * @instance
                 */
                Experiment.prototype.isActive = false;
    
                /**
                 * Creates a new Experiment instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {infinitusai.be.IExperiment=} [properties] Properties to set
                 * @returns {infinitusai.be.Experiment} Experiment instance
                 */
                Experiment.create = function create(properties) {
                    return new Experiment(properties);
                };
    
                /**
                 * Encodes the specified Experiment message. Does not implicitly {@link infinitusai.be.Experiment.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {infinitusai.be.IExperiment} message Experiment message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Experiment.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.startTimeMillis != null && Object.hasOwnProperty.call(message, "startTimeMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMillis);
                    if (message.endTimeMillis != null && Object.hasOwnProperty.call(message, "endTimeMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMillis);
                    if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isActive);
                    return writer;
                };
    
                /**
                 * Encodes the specified Experiment message, length delimited. Does not implicitly {@link infinitusai.be.Experiment.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {infinitusai.be.IExperiment} message Experiment message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Experiment.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Experiment message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Experiment} Experiment
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Experiment.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Experiment();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMillis = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMillis = reader.int64();
                                break;
                            }
                        case 4: {
                                message.isActive = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Experiment message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Experiment} Experiment
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Experiment.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Experiment message.
                 * @function verify
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Experiment.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                        if (!$util.isInteger(message.startTimeMillis) && !(message.startTimeMillis && $util.isInteger(message.startTimeMillis.low) && $util.isInteger(message.startTimeMillis.high)))
                            return "startTimeMillis: integer|Long expected";
                    if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                        if (!$util.isInteger(message.endTimeMillis) && !(message.endTimeMillis && $util.isInteger(message.endTimeMillis.low) && $util.isInteger(message.endTimeMillis.high)))
                            return "endTimeMillis: integer|Long expected";
                    if (message.isActive != null && message.hasOwnProperty("isActive"))
                        if (typeof message.isActive !== "boolean")
                            return "isActive: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an Experiment message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Experiment} Experiment
                 */
                Experiment.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Experiment)
                        return object;
                    var message = new $root.infinitusai.be.Experiment();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.startTimeMillis != null)
                        if ($util.Long)
                            (message.startTimeMillis = $util.Long.fromValue(object.startTimeMillis)).unsigned = false;
                        else if (typeof object.startTimeMillis === "string")
                            message.startTimeMillis = parseInt(object.startTimeMillis, 10);
                        else if (typeof object.startTimeMillis === "number")
                            message.startTimeMillis = object.startTimeMillis;
                        else if (typeof object.startTimeMillis === "object")
                            message.startTimeMillis = new $util.LongBits(object.startTimeMillis.low >>> 0, object.startTimeMillis.high >>> 0).toNumber();
                    if (object.endTimeMillis != null)
                        if ($util.Long)
                            (message.endTimeMillis = $util.Long.fromValue(object.endTimeMillis)).unsigned = false;
                        else if (typeof object.endTimeMillis === "string")
                            message.endTimeMillis = parseInt(object.endTimeMillis, 10);
                        else if (typeof object.endTimeMillis === "number")
                            message.endTimeMillis = object.endTimeMillis;
                        else if (typeof object.endTimeMillis === "object")
                            message.endTimeMillis = new $util.LongBits(object.endTimeMillis.low >>> 0, object.endTimeMillis.high >>> 0).toNumber();
                    if (object.isActive != null)
                        message.isActive = Boolean(object.isActive);
                    return message;
                };
    
                /**
                 * Creates a plain object from an Experiment message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {infinitusai.be.Experiment} message Experiment
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Experiment.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMillis = options.longs === String ? "0" : 0;
                        object.isActive = false;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                        if (typeof message.startTimeMillis === "number")
                            object.startTimeMillis = options.longs === String ? String(message.startTimeMillis) : message.startTimeMillis;
                        else
                            object.startTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMillis) : options.longs === Number ? new $util.LongBits(message.startTimeMillis.low >>> 0, message.startTimeMillis.high >>> 0).toNumber() : message.startTimeMillis;
                    if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                        if (typeof message.endTimeMillis === "number")
                            object.endTimeMillis = options.longs === String ? String(message.endTimeMillis) : message.endTimeMillis;
                        else
                            object.endTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMillis) : options.longs === Number ? new $util.LongBits(message.endTimeMillis.low >>> 0, message.endTimeMillis.high >>> 0).toNumber() : message.endTimeMillis;
                    if (message.isActive != null && message.hasOwnProperty("isActive"))
                        object.isActive = message.isActive;
                    return object;
                };
    
                /**
                 * Converts this Experiment to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Experiment
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Experiment.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Experiment
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Experiment
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Experiment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Experiment";
                };
    
                return Experiment;
            })();
    
            be.ExperimentParticipant = (function() {
    
                /**
                 * Properties of an ExperimentParticipant.
                 * @memberof infinitusai.be
                 * @interface IExperimentParticipant
                 * @property {string|null} [id] ExperimentParticipant id
                 * @property {string|null} [experimentId] ExperimentParticipant experimentId
                 * @property {string|null} [cohort] ExperimentParticipant cohort
                 */
    
                /**
                 * Constructs a new ExperimentParticipant.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExperimentParticipant.
                 * @implements IExperimentParticipant
                 * @constructor
                 * @param {infinitusai.be.IExperimentParticipant=} [properties] Properties to set
                 */
                function ExperimentParticipant(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExperimentParticipant id.
                 * @member {string} id
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @instance
                 */
                ExperimentParticipant.prototype.id = "";
    
                /**
                 * ExperimentParticipant experimentId.
                 * @member {string} experimentId
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @instance
                 */
                ExperimentParticipant.prototype.experimentId = "";
    
                /**
                 * ExperimentParticipant cohort.
                 * @member {string} cohort
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @instance
                 */
                ExperimentParticipant.prototype.cohort = "";
    
                /**
                 * Creates a new ExperimentParticipant instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {infinitusai.be.IExperimentParticipant=} [properties] Properties to set
                 * @returns {infinitusai.be.ExperimentParticipant} ExperimentParticipant instance
                 */
                ExperimentParticipant.create = function create(properties) {
                    return new ExperimentParticipant(properties);
                };
    
                /**
                 * Encodes the specified ExperimentParticipant message. Does not implicitly {@link infinitusai.be.ExperimentParticipant.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {infinitusai.be.IExperimentParticipant} message ExperimentParticipant message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExperimentParticipant.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.experimentId != null && Object.hasOwnProperty.call(message, "experimentId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.experimentId);
                    if (message.cohort != null && Object.hasOwnProperty.call(message, "cohort"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.cohort);
                    return writer;
                };
    
                /**
                 * Encodes the specified ExperimentParticipant message, length delimited. Does not implicitly {@link infinitusai.be.ExperimentParticipant.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {infinitusai.be.IExperimentParticipant} message ExperimentParticipant message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExperimentParticipant.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExperimentParticipant message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExperimentParticipant} ExperimentParticipant
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExperimentParticipant.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExperimentParticipant();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.experimentId = reader.string();
                                break;
                            }
                        case 3: {
                                message.cohort = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExperimentParticipant message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExperimentParticipant} ExperimentParticipant
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExperimentParticipant.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExperimentParticipant message.
                 * @function verify
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExperimentParticipant.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.experimentId != null && message.hasOwnProperty("experimentId"))
                        if (!$util.isString(message.experimentId))
                            return "experimentId: string expected";
                    if (message.cohort != null && message.hasOwnProperty("cohort"))
                        if (!$util.isString(message.cohort))
                            return "cohort: string expected";
                    return null;
                };
    
                /**
                 * Creates an ExperimentParticipant message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExperimentParticipant} ExperimentParticipant
                 */
                ExperimentParticipant.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExperimentParticipant)
                        return object;
                    var message = new $root.infinitusai.be.ExperimentParticipant();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.experimentId != null)
                        message.experimentId = String(object.experimentId);
                    if (object.cohort != null)
                        message.cohort = String(object.cohort);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExperimentParticipant message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {infinitusai.be.ExperimentParticipant} message ExperimentParticipant
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExperimentParticipant.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.experimentId = "";
                        object.cohort = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.experimentId != null && message.hasOwnProperty("experimentId"))
                        object.experimentId = message.experimentId;
                    if (message.cohort != null && message.hasOwnProperty("cohort"))
                        object.cohort = message.cohort;
                    return object;
                };
    
                /**
                 * Converts this ExperimentParticipant to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExperimentParticipant.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExperimentParticipant
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExperimentParticipant
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExperimentParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExperimentParticipant";
                };
    
                return ExperimentParticipant;
            })();
    
            be.GetDocsURLRequest = (function() {
    
                /**
                 * Properties of a GetDocsURLRequest.
                 * @memberof infinitusai.be
                 * @interface IGetDocsURLRequest
                 */
    
                /**
                 * Constructs a new GetDocsURLRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetDocsURLRequest.
                 * @implements IGetDocsURLRequest
                 * @constructor
                 * @param {infinitusai.be.IGetDocsURLRequest=} [properties] Properties to set
                 */
                function GetDocsURLRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetDocsURLRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {infinitusai.be.IGetDocsURLRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetDocsURLRequest} GetDocsURLRequest instance
                 */
                GetDocsURLRequest.create = function create(properties) {
                    return new GetDocsURLRequest(properties);
                };
    
                /**
                 * Encodes the specified GetDocsURLRequest message. Does not implicitly {@link infinitusai.be.GetDocsURLRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {infinitusai.be.IGetDocsURLRequest} message GetDocsURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDocsURLRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetDocsURLRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetDocsURLRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {infinitusai.be.IGetDocsURLRequest} message GetDocsURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDocsURLRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetDocsURLRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetDocsURLRequest} GetDocsURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDocsURLRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetDocsURLRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetDocsURLRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetDocsURLRequest} GetDocsURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDocsURLRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetDocsURLRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDocsURLRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetDocsURLRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetDocsURLRequest} GetDocsURLRequest
                 */
                GetDocsURLRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetDocsURLRequest)
                        return object;
                    return new $root.infinitusai.be.GetDocsURLRequest();
                };
    
                /**
                 * Creates a plain object from a GetDocsURLRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {infinitusai.be.GetDocsURLRequest} message GetDocsURLRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDocsURLRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetDocsURLRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDocsURLRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetDocsURLRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetDocsURLRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetDocsURLRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetDocsURLRequest";
                };
    
                return GetDocsURLRequest;
            })();
    
            be.GetDocsURLResponse = (function() {
    
                /**
                 * Properties of a GetDocsURLResponse.
                 * @memberof infinitusai.be
                 * @interface IGetDocsURLResponse
                 * @property {string|null} [docsUrl] GetDocsURLResponse docsUrl
                 */
    
                /**
                 * Constructs a new GetDocsURLResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetDocsURLResponse.
                 * @implements IGetDocsURLResponse
                 * @constructor
                 * @param {infinitusai.be.IGetDocsURLResponse=} [properties] Properties to set
                 */
                function GetDocsURLResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetDocsURLResponse docsUrl.
                 * @member {string} docsUrl
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @instance
                 */
                GetDocsURLResponse.prototype.docsUrl = "";
    
                /**
                 * Creates a new GetDocsURLResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {infinitusai.be.IGetDocsURLResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetDocsURLResponse} GetDocsURLResponse instance
                 */
                GetDocsURLResponse.create = function create(properties) {
                    return new GetDocsURLResponse(properties);
                };
    
                /**
                 * Encodes the specified GetDocsURLResponse message. Does not implicitly {@link infinitusai.be.GetDocsURLResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {infinitusai.be.IGetDocsURLResponse} message GetDocsURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDocsURLResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.docsUrl != null && Object.hasOwnProperty.call(message, "docsUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.docsUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetDocsURLResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetDocsURLResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {infinitusai.be.IGetDocsURLResponse} message GetDocsURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDocsURLResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetDocsURLResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetDocsURLResponse} GetDocsURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDocsURLResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetDocsURLResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.docsUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetDocsURLResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetDocsURLResponse} GetDocsURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDocsURLResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetDocsURLResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDocsURLResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.docsUrl != null && message.hasOwnProperty("docsUrl"))
                        if (!$util.isString(message.docsUrl))
                            return "docsUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetDocsURLResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetDocsURLResponse} GetDocsURLResponse
                 */
                GetDocsURLResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetDocsURLResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetDocsURLResponse();
                    if (object.docsUrl != null)
                        message.docsUrl = String(object.docsUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetDocsURLResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {infinitusai.be.GetDocsURLResponse} message GetDocsURLResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDocsURLResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.docsUrl = "";
                    if (message.docsUrl != null && message.hasOwnProperty("docsUrl"))
                        object.docsUrl = message.docsUrl;
                    return object;
                };
    
                /**
                 * Converts this GetDocsURLResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDocsURLResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetDocsURLResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetDocsURLResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetDocsURLResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetDocsURLResponse";
                };
    
                return GetDocsURLResponse;
            })();
    
            /**
             * InviteStatus enum.
             * @name infinitusai.be.InviteStatus
             * @enum {number}
             * @property {number} INVITE_STATUS_UNKNOWN=0 INVITE_STATUS_UNKNOWN value
             * @property {number} INVITE_STATUS_PENDING=1 INVITE_STATUS_PENDING value
             * @property {number} INVITE_STATUS_ACCEPTED=2 INVITE_STATUS_ACCEPTED value
             * @property {number} INVITE_STATUS_DECLINED=3 INVITE_STATUS_DECLINED value
             * @property {number} INVITE_STATUS_CANCELLED=4 INVITE_STATUS_CANCELLED value
             */
            be.InviteStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "INVITE_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "INVITE_STATUS_PENDING"] = 1;
                values[valuesById[2] = "INVITE_STATUS_ACCEPTED"] = 2;
                values[valuesById[3] = "INVITE_STATUS_DECLINED"] = 3;
                values[valuesById[4] = "INVITE_STATUS_CANCELLED"] = 4;
                return values;
            })();
    
            be.UserInvite = (function() {
    
                /**
                 * Properties of a UserInvite.
                 * @memberof infinitusai.be
                 * @interface IUserInvite
                 * @property {infinitusai.be.InviteStatus|null} [status] UserInvite status
                 * @property {infinitusai.be.UserRole|null} [userRole] UserInvite userRole
                 * @property {string|null} [invitedById] UserInvite invitedById
                 * @property {string|null} [invitedByName] UserInvite invitedByName
                 * @property {number|Long|null} [invitedOnMillis] UserInvite invitedOnMillis
                 */
    
                /**
                 * Constructs a new UserInvite.
                 * @memberof infinitusai.be
                 * @classdesc Represents a UserInvite.
                 * @implements IUserInvite
                 * @constructor
                 * @param {infinitusai.be.IUserInvite=} [properties] Properties to set
                 */
                function UserInvite(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UserInvite status.
                 * @member {infinitusai.be.InviteStatus} status
                 * @memberof infinitusai.be.UserInvite
                 * @instance
                 */
                UserInvite.prototype.status = 0;
    
                /**
                 * UserInvite userRole.
                 * @member {infinitusai.be.UserRole} userRole
                 * @memberof infinitusai.be.UserInvite
                 * @instance
                 */
                UserInvite.prototype.userRole = 0;
    
                /**
                 * UserInvite invitedById.
                 * @member {string} invitedById
                 * @memberof infinitusai.be.UserInvite
                 * @instance
                 */
                UserInvite.prototype.invitedById = "";
    
                /**
                 * UserInvite invitedByName.
                 * @member {string} invitedByName
                 * @memberof infinitusai.be.UserInvite
                 * @instance
                 */
                UserInvite.prototype.invitedByName = "";
    
                /**
                 * UserInvite invitedOnMillis.
                 * @member {number|Long} invitedOnMillis
                 * @memberof infinitusai.be.UserInvite
                 * @instance
                 */
                UserInvite.prototype.invitedOnMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new UserInvite instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {infinitusai.be.IUserInvite=} [properties] Properties to set
                 * @returns {infinitusai.be.UserInvite} UserInvite instance
                 */
                UserInvite.create = function create(properties) {
                    return new UserInvite(properties);
                };
    
                /**
                 * Encodes the specified UserInvite message. Does not implicitly {@link infinitusai.be.UserInvite.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {infinitusai.be.IUserInvite} message UserInvite message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserInvite.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.userRole != null && Object.hasOwnProperty.call(message, "userRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userRole);
                    if (message.invitedById != null && Object.hasOwnProperty.call(message, "invitedById"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.invitedById);
                    if (message.invitedByName != null && Object.hasOwnProperty.call(message, "invitedByName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.invitedByName);
                    if (message.invitedOnMillis != null && Object.hasOwnProperty.call(message, "invitedOnMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.invitedOnMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified UserInvite message, length delimited. Does not implicitly {@link infinitusai.be.UserInvite.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {infinitusai.be.IUserInvite} message UserInvite message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserInvite.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UserInvite message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UserInvite} UserInvite
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserInvite.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UserInvite();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                message.userRole = reader.int32();
                                break;
                            }
                        case 3: {
                                message.invitedById = reader.string();
                                break;
                            }
                        case 4: {
                                message.invitedByName = reader.string();
                                break;
                            }
                        case 5: {
                                message.invitedOnMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UserInvite message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UserInvite} UserInvite
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserInvite.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UserInvite message.
                 * @function verify
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserInvite.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        switch (message.userRole) {
                        default:
                            return "userRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.invitedById != null && message.hasOwnProperty("invitedById"))
                        if (!$util.isString(message.invitedById))
                            return "invitedById: string expected";
                    if (message.invitedByName != null && message.hasOwnProperty("invitedByName"))
                        if (!$util.isString(message.invitedByName))
                            return "invitedByName: string expected";
                    if (message.invitedOnMillis != null && message.hasOwnProperty("invitedOnMillis"))
                        if (!$util.isInteger(message.invitedOnMillis) && !(message.invitedOnMillis && $util.isInteger(message.invitedOnMillis.low) && $util.isInteger(message.invitedOnMillis.high)))
                            return "invitedOnMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a UserInvite message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UserInvite} UserInvite
                 */
                UserInvite.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UserInvite)
                        return object;
                    var message = new $root.infinitusai.be.UserInvite();
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "INVITE_STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "INVITE_STATUS_PENDING":
                    case 1:
                        message.status = 1;
                        break;
                    case "INVITE_STATUS_ACCEPTED":
                    case 2:
                        message.status = 2;
                        break;
                    case "INVITE_STATUS_DECLINED":
                    case 3:
                        message.status = 3;
                        break;
                    case "INVITE_STATUS_CANCELLED":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    switch (object.userRole) {
                    default:
                        if (typeof object.userRole === "number") {
                            message.userRole = object.userRole;
                            break;
                        }
                        break;
                    case "USER_ROLE_UNKNOWN":
                    case 0:
                        message.userRole = 0;
                        break;
                    case "USER_ROLE_ADMIN":
                    case 1:
                        message.userRole = 1;
                        break;
                    case "USER_ROLE_OPERATOR":
                    case 2:
                        message.userRole = 2;
                        break;
                    case "USER_ROLE_CUSTOMER":
                    case 3:
                        message.userRole = 3;
                        break;
                    case "USER_ROLE_USER":
                    case 4:
                        message.userRole = 4;
                        break;
                    }
                    if (object.invitedById != null)
                        message.invitedById = String(object.invitedById);
                    if (object.invitedByName != null)
                        message.invitedByName = String(object.invitedByName);
                    if (object.invitedOnMillis != null)
                        if ($util.Long)
                            (message.invitedOnMillis = $util.Long.fromValue(object.invitedOnMillis)).unsigned = false;
                        else if (typeof object.invitedOnMillis === "string")
                            message.invitedOnMillis = parseInt(object.invitedOnMillis, 10);
                        else if (typeof object.invitedOnMillis === "number")
                            message.invitedOnMillis = object.invitedOnMillis;
                        else if (typeof object.invitedOnMillis === "object")
                            message.invitedOnMillis = new $util.LongBits(object.invitedOnMillis.low >>> 0, object.invitedOnMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a UserInvite message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {infinitusai.be.UserInvite} message UserInvite
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserInvite.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.status = options.enums === String ? "INVITE_STATUS_UNKNOWN" : 0;
                        object.userRole = options.enums === String ? "USER_ROLE_UNKNOWN" : 0;
                        object.invitedById = "";
                        object.invitedByName = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.invitedOnMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.invitedOnMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.InviteStatus[message.status] === undefined ? message.status : $root.infinitusai.be.InviteStatus[message.status] : message.status;
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        object.userRole = options.enums === String ? $root.infinitusai.be.UserRole[message.userRole] === undefined ? message.userRole : $root.infinitusai.be.UserRole[message.userRole] : message.userRole;
                    if (message.invitedById != null && message.hasOwnProperty("invitedById"))
                        object.invitedById = message.invitedById;
                    if (message.invitedByName != null && message.hasOwnProperty("invitedByName"))
                        object.invitedByName = message.invitedByName;
                    if (message.invitedOnMillis != null && message.hasOwnProperty("invitedOnMillis"))
                        if (typeof message.invitedOnMillis === "number")
                            object.invitedOnMillis = options.longs === String ? String(message.invitedOnMillis) : message.invitedOnMillis;
                        else
                            object.invitedOnMillis = options.longs === String ? $util.Long.prototype.toString.call(message.invitedOnMillis) : options.longs === Number ? new $util.LongBits(message.invitedOnMillis.low >>> 0, message.invitedOnMillis.high >>> 0).toNumber() : message.invitedOnMillis;
                    return object;
                };
    
                /**
                 * Converts this UserInvite to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UserInvite
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserInvite.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UserInvite
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UserInvite
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserInvite.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UserInvite";
                };
    
                return UserInvite;
            })();
    
            be.OrgInvite = (function() {
    
                /**
                 * Properties of an OrgInvite.
                 * @memberof infinitusai.be
                 * @interface IOrgInvite
                 * @property {infinitusai.be.InviteStatus|null} [status] OrgInvite status
                 * @property {infinitusai.be.OrgRole|null} [orgRole] OrgInvite orgRole
                 * @property {string|null} [invitedById] OrgInvite invitedById
                 * @property {string|null} [invitedByName] OrgInvite invitedByName
                 * @property {number|Long|null} [invitedOnMillis] OrgInvite invitedOnMillis
                 * @property {string|null} [invitedEmail] OrgInvite invitedEmail
                 */
    
                /**
                 * Constructs a new OrgInvite.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrgInvite.
                 * @implements IOrgInvite
                 * @constructor
                 * @param {infinitusai.be.IOrgInvite=} [properties] Properties to set
                 */
                function OrgInvite(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgInvite status.
                 * @member {infinitusai.be.InviteStatus} status
                 * @memberof infinitusai.be.OrgInvite
                 * @instance
                 */
                OrgInvite.prototype.status = 0;
    
                /**
                 * OrgInvite orgRole.
                 * @member {infinitusai.be.OrgRole} orgRole
                 * @memberof infinitusai.be.OrgInvite
                 * @instance
                 */
                OrgInvite.prototype.orgRole = 0;
    
                /**
                 * OrgInvite invitedById.
                 * @member {string} invitedById
                 * @memberof infinitusai.be.OrgInvite
                 * @instance
                 */
                OrgInvite.prototype.invitedById = "";
    
                /**
                 * OrgInvite invitedByName.
                 * @member {string} invitedByName
                 * @memberof infinitusai.be.OrgInvite
                 * @instance
                 */
                OrgInvite.prototype.invitedByName = "";
    
                /**
                 * OrgInvite invitedOnMillis.
                 * @member {number|Long} invitedOnMillis
                 * @memberof infinitusai.be.OrgInvite
                 * @instance
                 */
                OrgInvite.prototype.invitedOnMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * OrgInvite invitedEmail.
                 * @member {string} invitedEmail
                 * @memberof infinitusai.be.OrgInvite
                 * @instance
                 */
                OrgInvite.prototype.invitedEmail = "";
    
                /**
                 * Creates a new OrgInvite instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {infinitusai.be.IOrgInvite=} [properties] Properties to set
                 * @returns {infinitusai.be.OrgInvite} OrgInvite instance
                 */
                OrgInvite.create = function create(properties) {
                    return new OrgInvite(properties);
                };
    
                /**
                 * Encodes the specified OrgInvite message. Does not implicitly {@link infinitusai.be.OrgInvite.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {infinitusai.be.IOrgInvite} message OrgInvite message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgInvite.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.orgRole != null && Object.hasOwnProperty.call(message, "orgRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orgRole);
                    if (message.invitedById != null && Object.hasOwnProperty.call(message, "invitedById"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.invitedById);
                    if (message.invitedByName != null && Object.hasOwnProperty.call(message, "invitedByName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.invitedByName);
                    if (message.invitedOnMillis != null && Object.hasOwnProperty.call(message, "invitedOnMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.invitedOnMillis);
                    if (message.invitedEmail != null && Object.hasOwnProperty.call(message, "invitedEmail"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.invitedEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgInvite message, length delimited. Does not implicitly {@link infinitusai.be.OrgInvite.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {infinitusai.be.IOrgInvite} message OrgInvite message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgInvite.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgInvite message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrgInvite} OrgInvite
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgInvite.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrgInvite();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                message.orgRole = reader.int32();
                                break;
                            }
                        case 3: {
                                message.invitedById = reader.string();
                                break;
                            }
                        case 4: {
                                message.invitedByName = reader.string();
                                break;
                            }
                        case 5: {
                                message.invitedOnMillis = reader.int64();
                                break;
                            }
                        case 6: {
                                message.invitedEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgInvite message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrgInvite} OrgInvite
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgInvite.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgInvite message.
                 * @function verify
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgInvite.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        switch (message.orgRole) {
                        default:
                            return "orgRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.invitedById != null && message.hasOwnProperty("invitedById"))
                        if (!$util.isString(message.invitedById))
                            return "invitedById: string expected";
                    if (message.invitedByName != null && message.hasOwnProperty("invitedByName"))
                        if (!$util.isString(message.invitedByName))
                            return "invitedByName: string expected";
                    if (message.invitedOnMillis != null && message.hasOwnProperty("invitedOnMillis"))
                        if (!$util.isInteger(message.invitedOnMillis) && !(message.invitedOnMillis && $util.isInteger(message.invitedOnMillis.low) && $util.isInteger(message.invitedOnMillis.high)))
                            return "invitedOnMillis: integer|Long expected";
                    if (message.invitedEmail != null && message.hasOwnProperty("invitedEmail"))
                        if (!$util.isString(message.invitedEmail))
                            return "invitedEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates an OrgInvite message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrgInvite} OrgInvite
                 */
                OrgInvite.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrgInvite)
                        return object;
                    var message = new $root.infinitusai.be.OrgInvite();
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "INVITE_STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "INVITE_STATUS_PENDING":
                    case 1:
                        message.status = 1;
                        break;
                    case "INVITE_STATUS_ACCEPTED":
                    case 2:
                        message.status = 2;
                        break;
                    case "INVITE_STATUS_DECLINED":
                    case 3:
                        message.status = 3;
                        break;
                    case "INVITE_STATUS_CANCELLED":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    switch (object.orgRole) {
                    default:
                        if (typeof object.orgRole === "number") {
                            message.orgRole = object.orgRole;
                            break;
                        }
                        break;
                    case "ORG_ROLE_UNKNOWN":
                    case 0:
                        message.orgRole = 0;
                        break;
                    case "ORG_ROLE_OWNER":
                    case 1:
                        message.orgRole = 1;
                        break;
                    case "ORG_ROLE_MEMBER":
                    case 2:
                        message.orgRole = 2;
                        break;
                    case "ORG_ROLE_SUPERVISOR":
                    case 3:
                        message.orgRole = 3;
                        break;
                    case "ORG_ROLE_OPERATOR":
                    case 4:
                        message.orgRole = 4;
                        break;
                    }
                    if (object.invitedById != null)
                        message.invitedById = String(object.invitedById);
                    if (object.invitedByName != null)
                        message.invitedByName = String(object.invitedByName);
                    if (object.invitedOnMillis != null)
                        if ($util.Long)
                            (message.invitedOnMillis = $util.Long.fromValue(object.invitedOnMillis)).unsigned = false;
                        else if (typeof object.invitedOnMillis === "string")
                            message.invitedOnMillis = parseInt(object.invitedOnMillis, 10);
                        else if (typeof object.invitedOnMillis === "number")
                            message.invitedOnMillis = object.invitedOnMillis;
                        else if (typeof object.invitedOnMillis === "object")
                            message.invitedOnMillis = new $util.LongBits(object.invitedOnMillis.low >>> 0, object.invitedOnMillis.high >>> 0).toNumber();
                    if (object.invitedEmail != null)
                        message.invitedEmail = String(object.invitedEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgInvite message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {infinitusai.be.OrgInvite} message OrgInvite
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgInvite.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.status = options.enums === String ? "INVITE_STATUS_UNKNOWN" : 0;
                        object.orgRole = options.enums === String ? "ORG_ROLE_UNKNOWN" : 0;
                        object.invitedById = "";
                        object.invitedByName = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.invitedOnMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.invitedOnMillis = options.longs === String ? "0" : 0;
                        object.invitedEmail = "";
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.InviteStatus[message.status] === undefined ? message.status : $root.infinitusai.be.InviteStatus[message.status] : message.status;
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        object.orgRole = options.enums === String ? $root.infinitusai.be.OrgRole[message.orgRole] === undefined ? message.orgRole : $root.infinitusai.be.OrgRole[message.orgRole] : message.orgRole;
                    if (message.invitedById != null && message.hasOwnProperty("invitedById"))
                        object.invitedById = message.invitedById;
                    if (message.invitedByName != null && message.hasOwnProperty("invitedByName"))
                        object.invitedByName = message.invitedByName;
                    if (message.invitedOnMillis != null && message.hasOwnProperty("invitedOnMillis"))
                        if (typeof message.invitedOnMillis === "number")
                            object.invitedOnMillis = options.longs === String ? String(message.invitedOnMillis) : message.invitedOnMillis;
                        else
                            object.invitedOnMillis = options.longs === String ? $util.Long.prototype.toString.call(message.invitedOnMillis) : options.longs === Number ? new $util.LongBits(message.invitedOnMillis.low >>> 0, message.invitedOnMillis.high >>> 0).toNumber() : message.invitedOnMillis;
                    if (message.invitedEmail != null && message.hasOwnProperty("invitedEmail"))
                        object.invitedEmail = message.invitedEmail;
                    return object;
                };
    
                /**
                 * Converts this OrgInvite to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrgInvite
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgInvite.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgInvite
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrgInvite
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgInvite.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrgInvite";
                };
    
                return OrgInvite;
            })();
    
            be.GetOrgInviteRequest = (function() {
    
                /**
                 * Properties of a GetOrgInviteRequest.
                 * @memberof infinitusai.be
                 * @interface IGetOrgInviteRequest
                 * @property {string|null} [orgName] GetOrgInviteRequest orgName
                 */
    
                /**
                 * Constructs a new GetOrgInviteRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOrgInviteRequest.
                 * @implements IGetOrgInviteRequest
                 * @constructor
                 * @param {infinitusai.be.IGetOrgInviteRequest=} [properties] Properties to set
                 */
                function GetOrgInviteRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgInviteRequest orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @instance
                 */
                GetOrgInviteRequest.prototype.orgName = "";
    
                /**
                 * Creates a new GetOrgInviteRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgInviteRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOrgInviteRequest} GetOrgInviteRequest instance
                 */
                GetOrgInviteRequest.create = function create(properties) {
                    return new GetOrgInviteRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgInviteRequest message. Does not implicitly {@link infinitusai.be.GetOrgInviteRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgInviteRequest} message GetOrgInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgInviteRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgName);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgInviteRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetOrgInviteRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgInviteRequest} message GetOrgInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgInviteRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOrgInviteRequest} GetOrgInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgInviteRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOrgInviteRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgInviteRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOrgInviteRequest} GetOrgInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgInviteRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgInviteRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgInviteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetOrgInviteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOrgInviteRequest} GetOrgInviteRequest
                 */
                GetOrgInviteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOrgInviteRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetOrgInviteRequest();
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgInviteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {infinitusai.be.GetOrgInviteRequest} message GetOrgInviteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgInviteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.orgName = "";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    return object;
                };
    
                /**
                 * Converts this GetOrgInviteRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgInviteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgInviteRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOrgInviteRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOrgInviteRequest";
                };
    
                return GetOrgInviteRequest;
            })();
    
            be.GetOrgInviteResponse = (function() {
    
                /**
                 * Properties of a GetOrgInviteResponse.
                 * @memberof infinitusai.be
                 * @interface IGetOrgInviteResponse
                 * @property {infinitusai.be.IOrgInvite|null} [invite] GetOrgInviteResponse invite
                 */
    
                /**
                 * Constructs a new GetOrgInviteResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOrgInviteResponse.
                 * @implements IGetOrgInviteResponse
                 * @constructor
                 * @param {infinitusai.be.IGetOrgInviteResponse=} [properties] Properties to set
                 */
                function GetOrgInviteResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgInviteResponse invite.
                 * @member {infinitusai.be.IOrgInvite|null|undefined} invite
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @instance
                 */
                GetOrgInviteResponse.prototype.invite = null;
    
                /**
                 * Creates a new GetOrgInviteResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgInviteResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOrgInviteResponse} GetOrgInviteResponse instance
                 */
                GetOrgInviteResponse.create = function create(properties) {
                    return new GetOrgInviteResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgInviteResponse message. Does not implicitly {@link infinitusai.be.GetOrgInviteResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgInviteResponse} message GetOrgInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgInviteResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                        $root.infinitusai.be.OrgInvite.encode(message.invite, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgInviteResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetOrgInviteResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgInviteResponse} message GetOrgInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgInviteResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOrgInviteResponse} GetOrgInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgInviteResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOrgInviteResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.invite = $root.infinitusai.be.OrgInvite.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgInviteResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOrgInviteResponse} GetOrgInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgInviteResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgInviteResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgInviteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invite != null && message.hasOwnProperty("invite")) {
                        var error = $root.infinitusai.be.OrgInvite.verify(message.invite);
                        if (error)
                            return "invite." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgInviteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOrgInviteResponse} GetOrgInviteResponse
                 */
                GetOrgInviteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOrgInviteResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetOrgInviteResponse();
                    if (object.invite != null) {
                        if (typeof object.invite !== "object")
                            throw TypeError(".infinitusai.be.GetOrgInviteResponse.invite: object expected");
                        message.invite = $root.infinitusai.be.OrgInvite.fromObject(object.invite);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgInviteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {infinitusai.be.GetOrgInviteResponse} message GetOrgInviteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgInviteResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.invite = null;
                    if (message.invite != null && message.hasOwnProperty("invite"))
                        object.invite = $root.infinitusai.be.OrgInvite.toObject(message.invite, options);
                    return object;
                };
    
                /**
                 * Converts this GetOrgInviteResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgInviteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgInviteResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOrgInviteResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOrgInviteResponse";
                };
    
                return GetOrgInviteResponse;
            })();
    
            be.CreateIncidentRequest = (function() {
    
                /**
                 * Properties of a CreateIncidentRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateIncidentRequest
                 * @property {string|null} [taskUUID] CreateIncidentRequest taskUUID
                 * @property {string|null} [payerID] CreateIncidentRequest payerID
                 * @property {string|null} [orgRole] CreateIncidentRequest orgRole
                 * @property {boolean|null} [onCall] CreateIncidentRequest onCall
                 * @property {string|null} [incidentTypeUUID] CreateIncidentRequest incidentTypeUUID
                 * @property {string|null} [description] CreateIncidentRequest description
                 * @property {string|null} [environment] CreateIncidentRequest environment
                 * @property {string|null} [pageURL] CreateIncidentRequest pageURL
                 * @property {Array.<string>|null} [logs] CreateIncidentRequest logs
                 * @property {Array.<Uint8Array>|null} [files] CreateIncidentRequest files
                 * @property {string|null} [feedbackTypeCategory] CreateIncidentRequest feedbackTypeCategory
                 * @property {string|null} [feedbackTypeSubcategory] CreateIncidentRequest feedbackTypeSubcategory
                 * @property {string|null} [feedbackTypePriority] CreateIncidentRequest feedbackTypePriority
                 * @property {string|null} [useTestEmail] CreateIncidentRequest useTestEmail
                 * @property {string|null} [callUUID] CreateIncidentRequest callUUID
                 * @property {string|null} [payerName] CreateIncidentRequest payerName
                 * @property {string|null} [orgName] CreateIncidentRequest orgName
                 * @property {number|null} [score] CreateIncidentRequest score
                 */
    
                /**
                 * Constructs a new CreateIncidentRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateIncidentRequest.
                 * @implements ICreateIncidentRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateIncidentRequest=} [properties] Properties to set
                 */
                function CreateIncidentRequest(properties) {
                    this.logs = [];
                    this.files = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateIncidentRequest taskUUID.
                 * @member {string} taskUUID
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.taskUUID = "";
    
                /**
                 * CreateIncidentRequest payerID.
                 * @member {string} payerID
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.payerID = "";
    
                /**
                 * CreateIncidentRequest orgRole.
                 * @member {string} orgRole
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.orgRole = "";
    
                /**
                 * CreateIncidentRequest onCall.
                 * @member {boolean} onCall
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.onCall = false;
    
                /**
                 * CreateIncidentRequest incidentTypeUUID.
                 * @member {string} incidentTypeUUID
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.incidentTypeUUID = "";
    
                /**
                 * CreateIncidentRequest description.
                 * @member {string} description
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.description = "";
    
                /**
                 * CreateIncidentRequest environment.
                 * @member {string} environment
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.environment = "";
    
                /**
                 * CreateIncidentRequest pageURL.
                 * @member {string} pageURL
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.pageURL = "";
    
                /**
                 * CreateIncidentRequest logs.
                 * @member {Array.<string>} logs
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.logs = $util.emptyArray;
    
                /**
                 * CreateIncidentRequest files.
                 * @member {Array.<Uint8Array>} files
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.files = $util.emptyArray;
    
                /**
                 * CreateIncidentRequest feedbackTypeCategory.
                 * @member {string} feedbackTypeCategory
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.feedbackTypeCategory = "";
    
                /**
                 * CreateIncidentRequest feedbackTypeSubcategory.
                 * @member {string} feedbackTypeSubcategory
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.feedbackTypeSubcategory = "";
    
                /**
                 * CreateIncidentRequest feedbackTypePriority.
                 * @member {string} feedbackTypePriority
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.feedbackTypePriority = "";
    
                /**
                 * CreateIncidentRequest useTestEmail.
                 * @member {string} useTestEmail
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.useTestEmail = "";
    
                /**
                 * CreateIncidentRequest callUUID.
                 * @member {string} callUUID
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.callUUID = "";
    
                /**
                 * CreateIncidentRequest payerName.
                 * @member {string} payerName
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.payerName = "";
    
                /**
                 * CreateIncidentRequest orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.orgName = "";
    
                /**
                 * CreateIncidentRequest score.
                 * @member {number} score
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 */
                CreateIncidentRequest.prototype.score = 0;
    
                /**
                 * Creates a new CreateIncidentRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {infinitusai.be.ICreateIncidentRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateIncidentRequest} CreateIncidentRequest instance
                 */
                CreateIncidentRequest.create = function create(properties) {
                    return new CreateIncidentRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateIncidentRequest message. Does not implicitly {@link infinitusai.be.CreateIncidentRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {infinitusai.be.ICreateIncidentRequest} message CreateIncidentRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateIncidentRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUUID != null && Object.hasOwnProperty.call(message, "taskUUID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUUID);
                    if (message.payerID != null && Object.hasOwnProperty.call(message, "payerID"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.payerID);
                    if (message.orgRole != null && Object.hasOwnProperty.call(message, "orgRole"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgRole);
                    if (message.onCall != null && Object.hasOwnProperty.call(message, "onCall"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.onCall);
                    if (message.incidentTypeUUID != null && Object.hasOwnProperty.call(message, "incidentTypeUUID"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.incidentTypeUUID);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.description);
                    if (message.environment != null && Object.hasOwnProperty.call(message, "environment"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.environment);
                    if (message.pageURL != null && Object.hasOwnProperty.call(message, "pageURL"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.pageURL);
                    if (message.logs != null && message.logs.length)
                        for (var i = 0; i < message.logs.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.logs[i]);
                    if (message.files != null && message.files.length)
                        for (var i = 0; i < message.files.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).bytes(message.files[i]);
                    if (message.feedbackTypeCategory != null && Object.hasOwnProperty.call(message, "feedbackTypeCategory"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.feedbackTypeCategory);
                    if (message.feedbackTypeSubcategory != null && Object.hasOwnProperty.call(message, "feedbackTypeSubcategory"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.feedbackTypeSubcategory);
                    if (message.feedbackTypePriority != null && Object.hasOwnProperty.call(message, "feedbackTypePriority"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.feedbackTypePriority);
                    if (message.useTestEmail != null && Object.hasOwnProperty.call(message, "useTestEmail"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.useTestEmail);
                    if (message.callUUID != null && Object.hasOwnProperty.call(message, "callUUID"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.callUUID);
                    if (message.payerName != null && Object.hasOwnProperty.call(message, "payerName"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.payerName);
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.orgName);
                    if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                        writer.uint32(/* id 19, wireType 0 =*/152).int32(message.score);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateIncidentRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateIncidentRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {infinitusai.be.ICreateIncidentRequest} message CreateIncidentRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateIncidentRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateIncidentRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateIncidentRequest} CreateIncidentRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateIncidentRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateIncidentRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUUID = reader.string();
                                break;
                            }
                        case 2: {
                                message.payerID = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgRole = reader.string();
                                break;
                            }
                        case 4: {
                                message.onCall = reader.bool();
                                break;
                            }
                        case 5: {
                                message.incidentTypeUUID = reader.string();
                                break;
                            }
                        case 6: {
                                message.description = reader.string();
                                break;
                            }
                        case 8: {
                                message.environment = reader.string();
                                break;
                            }
                        case 9: {
                                message.pageURL = reader.string();
                                break;
                            }
                        case 10: {
                                if (!(message.logs && message.logs.length))
                                    message.logs = [];
                                message.logs.push(reader.string());
                                break;
                            }
                        case 11: {
                                if (!(message.files && message.files.length))
                                    message.files = [];
                                message.files.push(reader.bytes());
                                break;
                            }
                        case 12: {
                                message.feedbackTypeCategory = reader.string();
                                break;
                            }
                        case 13: {
                                message.feedbackTypeSubcategory = reader.string();
                                break;
                            }
                        case 14: {
                                message.feedbackTypePriority = reader.string();
                                break;
                            }
                        case 15: {
                                message.useTestEmail = reader.string();
                                break;
                            }
                        case 16: {
                                message.callUUID = reader.string();
                                break;
                            }
                        case 17: {
                                message.payerName = reader.string();
                                break;
                            }
                        case 18: {
                                message.orgName = reader.string();
                                break;
                            }
                        case 19: {
                                message.score = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateIncidentRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateIncidentRequest} CreateIncidentRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateIncidentRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateIncidentRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateIncidentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        if (!$util.isString(message.taskUUID))
                            return "taskUUID: string expected";
                    if (message.payerID != null && message.hasOwnProperty("payerID"))
                        if (!$util.isString(message.payerID))
                            return "payerID: string expected";
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        if (!$util.isString(message.orgRole))
                            return "orgRole: string expected";
                    if (message.onCall != null && message.hasOwnProperty("onCall"))
                        if (typeof message.onCall !== "boolean")
                            return "onCall: boolean expected";
                    if (message.incidentTypeUUID != null && message.hasOwnProperty("incidentTypeUUID"))
                        if (!$util.isString(message.incidentTypeUUID))
                            return "incidentTypeUUID: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.environment != null && message.hasOwnProperty("environment"))
                        if (!$util.isString(message.environment))
                            return "environment: string expected";
                    if (message.pageURL != null && message.hasOwnProperty("pageURL"))
                        if (!$util.isString(message.pageURL))
                            return "pageURL: string expected";
                    if (message.logs != null && message.hasOwnProperty("logs")) {
                        if (!Array.isArray(message.logs))
                            return "logs: array expected";
                        for (var i = 0; i < message.logs.length; ++i)
                            if (!$util.isString(message.logs[i]))
                                return "logs: string[] expected";
                    }
                    if (message.files != null && message.hasOwnProperty("files")) {
                        if (!Array.isArray(message.files))
                            return "files: array expected";
                        for (var i = 0; i < message.files.length; ++i)
                            if (!(message.files[i] && typeof message.files[i].length === "number" || $util.isString(message.files[i])))
                                return "files: buffer[] expected";
                    }
                    if (message.feedbackTypeCategory != null && message.hasOwnProperty("feedbackTypeCategory"))
                        if (!$util.isString(message.feedbackTypeCategory))
                            return "feedbackTypeCategory: string expected";
                    if (message.feedbackTypeSubcategory != null && message.hasOwnProperty("feedbackTypeSubcategory"))
                        if (!$util.isString(message.feedbackTypeSubcategory))
                            return "feedbackTypeSubcategory: string expected";
                    if (message.feedbackTypePriority != null && message.hasOwnProperty("feedbackTypePriority"))
                        if (!$util.isString(message.feedbackTypePriority))
                            return "feedbackTypePriority: string expected";
                    if (message.useTestEmail != null && message.hasOwnProperty("useTestEmail"))
                        if (!$util.isString(message.useTestEmail))
                            return "useTestEmail: string expected";
                    if (message.callUUID != null && message.hasOwnProperty("callUUID"))
                        if (!$util.isString(message.callUUID))
                            return "callUUID: string expected";
                    if (message.payerName != null && message.hasOwnProperty("payerName"))
                        if (!$util.isString(message.payerName))
                            return "payerName: string expected";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    if (message.score != null && message.hasOwnProperty("score"))
                        if (!$util.isInteger(message.score))
                            return "score: integer expected";
                    return null;
                };
    
                /**
                 * Creates a CreateIncidentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateIncidentRequest} CreateIncidentRequest
                 */
                CreateIncidentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateIncidentRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateIncidentRequest();
                    if (object.taskUUID != null)
                        message.taskUUID = String(object.taskUUID);
                    if (object.payerID != null)
                        message.payerID = String(object.payerID);
                    if (object.orgRole != null)
                        message.orgRole = String(object.orgRole);
                    if (object.onCall != null)
                        message.onCall = Boolean(object.onCall);
                    if (object.incidentTypeUUID != null)
                        message.incidentTypeUUID = String(object.incidentTypeUUID);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.environment != null)
                        message.environment = String(object.environment);
                    if (object.pageURL != null)
                        message.pageURL = String(object.pageURL);
                    if (object.logs) {
                        if (!Array.isArray(object.logs))
                            throw TypeError(".infinitusai.be.CreateIncidentRequest.logs: array expected");
                        message.logs = [];
                        for (var i = 0; i < object.logs.length; ++i)
                            message.logs[i] = String(object.logs[i]);
                    }
                    if (object.files) {
                        if (!Array.isArray(object.files))
                            throw TypeError(".infinitusai.be.CreateIncidentRequest.files: array expected");
                        message.files = [];
                        for (var i = 0; i < object.files.length; ++i)
                            if (typeof object.files[i] === "string")
                                $util.base64.decode(object.files[i], message.files[i] = $util.newBuffer($util.base64.length(object.files[i])), 0);
                            else if (object.files[i].length >= 0)
                                message.files[i] = object.files[i];
                    }
                    if (object.feedbackTypeCategory != null)
                        message.feedbackTypeCategory = String(object.feedbackTypeCategory);
                    if (object.feedbackTypeSubcategory != null)
                        message.feedbackTypeSubcategory = String(object.feedbackTypeSubcategory);
                    if (object.feedbackTypePriority != null)
                        message.feedbackTypePriority = String(object.feedbackTypePriority);
                    if (object.useTestEmail != null)
                        message.useTestEmail = String(object.useTestEmail);
                    if (object.callUUID != null)
                        message.callUUID = String(object.callUUID);
                    if (object.payerName != null)
                        message.payerName = String(object.payerName);
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    if (object.score != null)
                        message.score = object.score | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateIncidentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {infinitusai.be.CreateIncidentRequest} message CreateIncidentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateIncidentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.logs = [];
                        object.files = [];
                    }
                    if (options.defaults) {
                        object.taskUUID = "";
                        object.payerID = "";
                        object.orgRole = "";
                        object.onCall = false;
                        object.incidentTypeUUID = "";
                        object.description = "";
                        object.environment = "";
                        object.pageURL = "";
                        object.feedbackTypeCategory = "";
                        object.feedbackTypeSubcategory = "";
                        object.feedbackTypePriority = "";
                        object.useTestEmail = "";
                        object.callUUID = "";
                        object.payerName = "";
                        object.orgName = "";
                        object.score = 0;
                    }
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        object.taskUUID = message.taskUUID;
                    if (message.payerID != null && message.hasOwnProperty("payerID"))
                        object.payerID = message.payerID;
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        object.orgRole = message.orgRole;
                    if (message.onCall != null && message.hasOwnProperty("onCall"))
                        object.onCall = message.onCall;
                    if (message.incidentTypeUUID != null && message.hasOwnProperty("incidentTypeUUID"))
                        object.incidentTypeUUID = message.incidentTypeUUID;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.environment != null && message.hasOwnProperty("environment"))
                        object.environment = message.environment;
                    if (message.pageURL != null && message.hasOwnProperty("pageURL"))
                        object.pageURL = message.pageURL;
                    if (message.logs && message.logs.length) {
                        object.logs = [];
                        for (var j = 0; j < message.logs.length; ++j)
                            object.logs[j] = message.logs[j];
                    }
                    if (message.files && message.files.length) {
                        object.files = [];
                        for (var j = 0; j < message.files.length; ++j)
                            object.files[j] = options.bytes === String ? $util.base64.encode(message.files[j], 0, message.files[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.files[j]) : message.files[j];
                    }
                    if (message.feedbackTypeCategory != null && message.hasOwnProperty("feedbackTypeCategory"))
                        object.feedbackTypeCategory = message.feedbackTypeCategory;
                    if (message.feedbackTypeSubcategory != null && message.hasOwnProperty("feedbackTypeSubcategory"))
                        object.feedbackTypeSubcategory = message.feedbackTypeSubcategory;
                    if (message.feedbackTypePriority != null && message.hasOwnProperty("feedbackTypePriority"))
                        object.feedbackTypePriority = message.feedbackTypePriority;
                    if (message.useTestEmail != null && message.hasOwnProperty("useTestEmail"))
                        object.useTestEmail = message.useTestEmail;
                    if (message.callUUID != null && message.hasOwnProperty("callUUID"))
                        object.callUUID = message.callUUID;
                    if (message.payerName != null && message.hasOwnProperty("payerName"))
                        object.payerName = message.payerName;
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    if (message.score != null && message.hasOwnProperty("score"))
                        object.score = message.score;
                    return object;
                };
    
                /**
                 * Converts this CreateIncidentRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateIncidentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateIncidentRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateIncidentRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateIncidentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateIncidentRequest";
                };
    
                return CreateIncidentRequest;
            })();
    
            be.CreateIncidentResponse = (function() {
    
                /**
                 * Properties of a CreateIncidentResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateIncidentResponse
                 * @property {string|null} [id] CreateIncidentResponse id
                 * @property {Array.<infinitusai.be.IFileUploadResult>|null} [uploadResults] CreateIncidentResponse uploadResults
                 */
    
                /**
                 * Constructs a new CreateIncidentResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateIncidentResponse.
                 * @implements ICreateIncidentResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateIncidentResponse=} [properties] Properties to set
                 */
                function CreateIncidentResponse(properties) {
                    this.uploadResults = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateIncidentResponse id.
                 * @member {string} id
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @instance
                 */
                CreateIncidentResponse.prototype.id = "";
    
                /**
                 * CreateIncidentResponse uploadResults.
                 * @member {Array.<infinitusai.be.IFileUploadResult>} uploadResults
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @instance
                 */
                CreateIncidentResponse.prototype.uploadResults = $util.emptyArray;
    
                /**
                 * Creates a new CreateIncidentResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {infinitusai.be.ICreateIncidentResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateIncidentResponse} CreateIncidentResponse instance
                 */
                CreateIncidentResponse.create = function create(properties) {
                    return new CreateIncidentResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateIncidentResponse message. Does not implicitly {@link infinitusai.be.CreateIncidentResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {infinitusai.be.ICreateIncidentResponse} message CreateIncidentResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateIncidentResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.uploadResults != null && message.uploadResults.length)
                        for (var i = 0; i < message.uploadResults.length; ++i)
                            $root.infinitusai.be.FileUploadResult.encode(message.uploadResults[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateIncidentResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateIncidentResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {infinitusai.be.ICreateIncidentResponse} message CreateIncidentResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateIncidentResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateIncidentResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateIncidentResponse} CreateIncidentResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateIncidentResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateIncidentResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.uploadResults && message.uploadResults.length))
                                    message.uploadResults = [];
                                message.uploadResults.push($root.infinitusai.be.FileUploadResult.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateIncidentResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateIncidentResponse} CreateIncidentResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateIncidentResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateIncidentResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateIncidentResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.uploadResults != null && message.hasOwnProperty("uploadResults")) {
                        if (!Array.isArray(message.uploadResults))
                            return "uploadResults: array expected";
                        for (var i = 0; i < message.uploadResults.length; ++i) {
                            var error = $root.infinitusai.be.FileUploadResult.verify(message.uploadResults[i]);
                            if (error)
                                return "uploadResults." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateIncidentResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateIncidentResponse} CreateIncidentResponse
                 */
                CreateIncidentResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateIncidentResponse)
                        return object;
                    var message = new $root.infinitusai.be.CreateIncidentResponse();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.uploadResults) {
                        if (!Array.isArray(object.uploadResults))
                            throw TypeError(".infinitusai.be.CreateIncidentResponse.uploadResults: array expected");
                        message.uploadResults = [];
                        for (var i = 0; i < object.uploadResults.length; ++i) {
                            if (typeof object.uploadResults[i] !== "object")
                                throw TypeError(".infinitusai.be.CreateIncidentResponse.uploadResults: object expected");
                            message.uploadResults[i] = $root.infinitusai.be.FileUploadResult.fromObject(object.uploadResults[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateIncidentResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {infinitusai.be.CreateIncidentResponse} message CreateIncidentResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateIncidentResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uploadResults = [];
                    if (options.defaults)
                        object.id = "";
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.uploadResults && message.uploadResults.length) {
                        object.uploadResults = [];
                        for (var j = 0; j < message.uploadResults.length; ++j)
                            object.uploadResults[j] = $root.infinitusai.be.FileUploadResult.toObject(message.uploadResults[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this CreateIncidentResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateIncidentResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateIncidentResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateIncidentResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateIncidentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateIncidentResponse";
                };
    
                return CreateIncidentResponse;
            })();
    
            be.GetIncidentTypesRequest = (function() {
    
                /**
                 * Properties of a GetIncidentTypesRequest.
                 * @memberof infinitusai.be
                 * @interface IGetIncidentTypesRequest
                 * @property {infinitusai.be.IIncidentTypeFilter|null} [filter] GetIncidentTypesRequest filter
                 */
    
                /**
                 * Constructs a new GetIncidentTypesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetIncidentTypesRequest.
                 * @implements IGetIncidentTypesRequest
                 * @constructor
                 * @param {infinitusai.be.IGetIncidentTypesRequest=} [properties] Properties to set
                 */
                function GetIncidentTypesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetIncidentTypesRequest filter.
                 * @member {infinitusai.be.IIncidentTypeFilter|null|undefined} filter
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @instance
                 */
                GetIncidentTypesRequest.prototype.filter = null;
    
                /**
                 * Creates a new GetIncidentTypesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {infinitusai.be.IGetIncidentTypesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetIncidentTypesRequest} GetIncidentTypesRequest instance
                 */
                GetIncidentTypesRequest.create = function create(properties) {
                    return new GetIncidentTypesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetIncidentTypesRequest message. Does not implicitly {@link infinitusai.be.GetIncidentTypesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {infinitusai.be.IGetIncidentTypesRequest} message GetIncidentTypesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncidentTypesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                        $root.infinitusai.be.IncidentTypeFilter.encode(message.filter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetIncidentTypesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetIncidentTypesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {infinitusai.be.IGetIncidentTypesRequest} message GetIncidentTypesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncidentTypesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetIncidentTypesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetIncidentTypesRequest} GetIncidentTypesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncidentTypesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetIncidentTypesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.filter = $root.infinitusai.be.IncidentTypeFilter.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetIncidentTypesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetIncidentTypesRequest} GetIncidentTypesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncidentTypesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetIncidentTypesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIncidentTypesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.filter != null && message.hasOwnProperty("filter")) {
                        var error = $root.infinitusai.be.IncidentTypeFilter.verify(message.filter);
                        if (error)
                            return "filter." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetIncidentTypesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetIncidentTypesRequest} GetIncidentTypesRequest
                 */
                GetIncidentTypesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetIncidentTypesRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetIncidentTypesRequest();
                    if (object.filter != null) {
                        if (typeof object.filter !== "object")
                            throw TypeError(".infinitusai.be.GetIncidentTypesRequest.filter: object expected");
                        message.filter = $root.infinitusai.be.IncidentTypeFilter.fromObject(object.filter);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetIncidentTypesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {infinitusai.be.GetIncidentTypesRequest} message GetIncidentTypesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIncidentTypesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.filter = null;
                    if (message.filter != null && message.hasOwnProperty("filter"))
                        object.filter = $root.infinitusai.be.IncidentTypeFilter.toObject(message.filter, options);
                    return object;
                };
    
                /**
                 * Converts this GetIncidentTypesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIncidentTypesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetIncidentTypesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetIncidentTypesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetIncidentTypesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetIncidentTypesRequest";
                };
    
                return GetIncidentTypesRequest;
            })();
    
            be.GetIncidentTypesResponse = (function() {
    
                /**
                 * Properties of a GetIncidentTypesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetIncidentTypesResponse
                 * @property {Array.<infinitusai.be.IIncidentType>|null} [incidentTypes] GetIncidentTypesResponse incidentTypes
                 */
    
                /**
                 * Constructs a new GetIncidentTypesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetIncidentTypesResponse.
                 * @implements IGetIncidentTypesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetIncidentTypesResponse=} [properties] Properties to set
                 */
                function GetIncidentTypesResponse(properties) {
                    this.incidentTypes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetIncidentTypesResponse incidentTypes.
                 * @member {Array.<infinitusai.be.IIncidentType>} incidentTypes
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @instance
                 */
                GetIncidentTypesResponse.prototype.incidentTypes = $util.emptyArray;
    
                /**
                 * Creates a new GetIncidentTypesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {infinitusai.be.IGetIncidentTypesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetIncidentTypesResponse} GetIncidentTypesResponse instance
                 */
                GetIncidentTypesResponse.create = function create(properties) {
                    return new GetIncidentTypesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetIncidentTypesResponse message. Does not implicitly {@link infinitusai.be.GetIncidentTypesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {infinitusai.be.IGetIncidentTypesResponse} message GetIncidentTypesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncidentTypesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.incidentTypes != null && message.incidentTypes.length)
                        for (var i = 0; i < message.incidentTypes.length; ++i)
                            $root.infinitusai.be.IncidentType.encode(message.incidentTypes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetIncidentTypesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetIncidentTypesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {infinitusai.be.IGetIncidentTypesResponse} message GetIncidentTypesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncidentTypesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetIncidentTypesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetIncidentTypesResponse} GetIncidentTypesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncidentTypesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetIncidentTypesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.incidentTypes && message.incidentTypes.length))
                                    message.incidentTypes = [];
                                message.incidentTypes.push($root.infinitusai.be.IncidentType.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetIncidentTypesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetIncidentTypesResponse} GetIncidentTypesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncidentTypesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetIncidentTypesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIncidentTypesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.incidentTypes != null && message.hasOwnProperty("incidentTypes")) {
                        if (!Array.isArray(message.incidentTypes))
                            return "incidentTypes: array expected";
                        for (var i = 0; i < message.incidentTypes.length; ++i) {
                            var error = $root.infinitusai.be.IncidentType.verify(message.incidentTypes[i]);
                            if (error)
                                return "incidentTypes." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetIncidentTypesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetIncidentTypesResponse} GetIncidentTypesResponse
                 */
                GetIncidentTypesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetIncidentTypesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetIncidentTypesResponse();
                    if (object.incidentTypes) {
                        if (!Array.isArray(object.incidentTypes))
                            throw TypeError(".infinitusai.be.GetIncidentTypesResponse.incidentTypes: array expected");
                        message.incidentTypes = [];
                        for (var i = 0; i < object.incidentTypes.length; ++i) {
                            if (typeof object.incidentTypes[i] !== "object")
                                throw TypeError(".infinitusai.be.GetIncidentTypesResponse.incidentTypes: object expected");
                            message.incidentTypes[i] = $root.infinitusai.be.IncidentType.fromObject(object.incidentTypes[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetIncidentTypesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {infinitusai.be.GetIncidentTypesResponse} message GetIncidentTypesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIncidentTypesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.incidentTypes = [];
                    if (message.incidentTypes && message.incidentTypes.length) {
                        object.incidentTypes = [];
                        for (var j = 0; j < message.incidentTypes.length; ++j)
                            object.incidentTypes[j] = $root.infinitusai.be.IncidentType.toObject(message.incidentTypes[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetIncidentTypesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIncidentTypesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetIncidentTypesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetIncidentTypesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetIncidentTypesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetIncidentTypesResponse";
                };
    
                return GetIncidentTypesResponse;
            })();
    
            be.GetUserIncidentsRequest = (function() {
    
                /**
                 * Properties of a GetUserIncidentsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetUserIncidentsRequest
                 * @property {number|null} [page] GetUserIncidentsRequest page
                 * @property {number|null} [pageSize] GetUserIncidentsRequest pageSize
                 * @property {string|null} [orderByCol] GetUserIncidentsRequest orderByCol
                 * @property {string|null} [orderByDir] GetUserIncidentsRequest orderByDir
                 * @property {infinitusai.be.IIncidentFilter|null} [filter] GetUserIncidentsRequest filter
                 */
    
                /**
                 * Constructs a new GetUserIncidentsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetUserIncidentsRequest.
                 * @implements IGetUserIncidentsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetUserIncidentsRequest=} [properties] Properties to set
                 */
                function GetUserIncidentsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserIncidentsRequest page.
                 * @member {number} page
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @instance
                 */
                GetUserIncidentsRequest.prototype.page = 0;
    
                /**
                 * GetUserIncidentsRequest pageSize.
                 * @member {number} pageSize
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @instance
                 */
                GetUserIncidentsRequest.prototype.pageSize = 0;
    
                /**
                 * GetUserIncidentsRequest orderByCol.
                 * @member {string} orderByCol
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @instance
                 */
                GetUserIncidentsRequest.prototype.orderByCol = "";
    
                /**
                 * GetUserIncidentsRequest orderByDir.
                 * @member {string} orderByDir
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @instance
                 */
                GetUserIncidentsRequest.prototype.orderByDir = "";
    
                /**
                 * GetUserIncidentsRequest filter.
                 * @member {infinitusai.be.IIncidentFilter|null|undefined} filter
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @instance
                 */
                GetUserIncidentsRequest.prototype.filter = null;
    
                /**
                 * Creates a new GetUserIncidentsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {infinitusai.be.IGetUserIncidentsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetUserIncidentsRequest} GetUserIncidentsRequest instance
                 */
                GetUserIncidentsRequest.create = function create(properties) {
                    return new GetUserIncidentsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetUserIncidentsRequest message. Does not implicitly {@link infinitusai.be.GetUserIncidentsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {infinitusai.be.IGetUserIncidentsRequest} message GetUserIncidentsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserIncidentsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
                    if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                    if (message.orderByCol != null && Object.hasOwnProperty.call(message, "orderByCol"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orderByCol);
                    if (message.orderByDir != null && Object.hasOwnProperty.call(message, "orderByDir"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orderByDir);
                    if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                        $root.infinitusai.be.IncidentFilter.encode(message.filter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserIncidentsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetUserIncidentsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {infinitusai.be.IGetUserIncidentsRequest} message GetUserIncidentsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserIncidentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserIncidentsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetUserIncidentsRequest} GetUserIncidentsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserIncidentsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetUserIncidentsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.page = reader.int32();
                                break;
                            }
                        case 2: {
                                message.pageSize = reader.int32();
                                break;
                            }
                        case 3: {
                                message.orderByCol = reader.string();
                                break;
                            }
                        case 4: {
                                message.orderByDir = reader.string();
                                break;
                            }
                        case 5: {
                                message.filter = $root.infinitusai.be.IncidentFilter.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserIncidentsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetUserIncidentsRequest} GetUserIncidentsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserIncidentsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserIncidentsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserIncidentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                        if (!$util.isInteger(message.pageSize))
                            return "pageSize: integer expected";
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        if (!$util.isString(message.orderByCol))
                            return "orderByCol: string expected";
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        if (!$util.isString(message.orderByDir))
                            return "orderByDir: string expected";
                    if (message.filter != null && message.hasOwnProperty("filter")) {
                        var error = $root.infinitusai.be.IncidentFilter.verify(message.filter);
                        if (error)
                            return "filter." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetUserIncidentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetUserIncidentsRequest} GetUserIncidentsRequest
                 */
                GetUserIncidentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetUserIncidentsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetUserIncidentsRequest();
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.pageSize != null)
                        message.pageSize = object.pageSize | 0;
                    if (object.orderByCol != null)
                        message.orderByCol = String(object.orderByCol);
                    if (object.orderByDir != null)
                        message.orderByDir = String(object.orderByDir);
                    if (object.filter != null) {
                        if (typeof object.filter !== "object")
                            throw TypeError(".infinitusai.be.GetUserIncidentsRequest.filter: object expected");
                        message.filter = $root.infinitusai.be.IncidentFilter.fromObject(object.filter);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserIncidentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {infinitusai.be.GetUserIncidentsRequest} message GetUserIncidentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserIncidentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.page = 0;
                        object.pageSize = 0;
                        object.orderByCol = "";
                        object.orderByDir = "";
                        object.filter = null;
                    }
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                        object.pageSize = message.pageSize;
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        object.orderByCol = message.orderByCol;
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        object.orderByDir = message.orderByDir;
                    if (message.filter != null && message.hasOwnProperty("filter"))
                        object.filter = $root.infinitusai.be.IncidentFilter.toObject(message.filter, options);
                    return object;
                };
    
                /**
                 * Converts this GetUserIncidentsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserIncidentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserIncidentsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetUserIncidentsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserIncidentsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetUserIncidentsRequest";
                };
    
                return GetUserIncidentsRequest;
            })();
    
            be.GetUserIncidentsResponse = (function() {
    
                /**
                 * Properties of a GetUserIncidentsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetUserIncidentsResponse
                 * @property {Array.<infinitusai.be.IIncident>|null} [incidents] GetUserIncidentsResponse incidents
                 */
    
                /**
                 * Constructs a new GetUserIncidentsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetUserIncidentsResponse.
                 * @implements IGetUserIncidentsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetUserIncidentsResponse=} [properties] Properties to set
                 */
                function GetUserIncidentsResponse(properties) {
                    this.incidents = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserIncidentsResponse incidents.
                 * @member {Array.<infinitusai.be.IIncident>} incidents
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @instance
                 */
                GetUserIncidentsResponse.prototype.incidents = $util.emptyArray;
    
                /**
                 * Creates a new GetUserIncidentsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {infinitusai.be.IGetUserIncidentsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetUserIncidentsResponse} GetUserIncidentsResponse instance
                 */
                GetUserIncidentsResponse.create = function create(properties) {
                    return new GetUserIncidentsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetUserIncidentsResponse message. Does not implicitly {@link infinitusai.be.GetUserIncidentsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {infinitusai.be.IGetUserIncidentsResponse} message GetUserIncidentsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserIncidentsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.incidents != null && message.incidents.length)
                        for (var i = 0; i < message.incidents.length; ++i)
                            $root.infinitusai.be.Incident.encode(message.incidents[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserIncidentsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetUserIncidentsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {infinitusai.be.IGetUserIncidentsResponse} message GetUserIncidentsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserIncidentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserIncidentsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetUserIncidentsResponse} GetUserIncidentsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserIncidentsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetUserIncidentsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.incidents && message.incidents.length))
                                    message.incidents = [];
                                message.incidents.push($root.infinitusai.be.Incident.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserIncidentsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetUserIncidentsResponse} GetUserIncidentsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserIncidentsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserIncidentsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserIncidentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.incidents != null && message.hasOwnProperty("incidents")) {
                        if (!Array.isArray(message.incidents))
                            return "incidents: array expected";
                        for (var i = 0; i < message.incidents.length; ++i) {
                            var error = $root.infinitusai.be.Incident.verify(message.incidents[i]);
                            if (error)
                                return "incidents." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetUserIncidentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetUserIncidentsResponse} GetUserIncidentsResponse
                 */
                GetUserIncidentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetUserIncidentsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetUserIncidentsResponse();
                    if (object.incidents) {
                        if (!Array.isArray(object.incidents))
                            throw TypeError(".infinitusai.be.GetUserIncidentsResponse.incidents: array expected");
                        message.incidents = [];
                        for (var i = 0; i < object.incidents.length; ++i) {
                            if (typeof object.incidents[i] !== "object")
                                throw TypeError(".infinitusai.be.GetUserIncidentsResponse.incidents: object expected");
                            message.incidents[i] = $root.infinitusai.be.Incident.fromObject(object.incidents[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserIncidentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {infinitusai.be.GetUserIncidentsResponse} message GetUserIncidentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserIncidentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.incidents = [];
                    if (message.incidents && message.incidents.length) {
                        object.incidents = [];
                        for (var j = 0; j < message.incidents.length; ++j)
                            object.incidents[j] = $root.infinitusai.be.Incident.toObject(message.incidents[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetUserIncidentsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserIncidentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserIncidentsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetUserIncidentsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserIncidentsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetUserIncidentsResponse";
                };
    
                return GetUserIncidentsResponse;
            })();
    
            be.FileUploadResult = (function() {
    
                /**
                 * Properties of a FileUploadResult.
                 * @memberof infinitusai.be
                 * @interface IFileUploadResult
                 * @property {string|null} [filename] FileUploadResult filename
                 * @property {number|Long|null} [fileSize] FileUploadResult fileSize
                 * @property {boolean|null} [uploadSuccess] FileUploadResult uploadSuccess
                 */
    
                /**
                 * Constructs a new FileUploadResult.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FileUploadResult.
                 * @implements IFileUploadResult
                 * @constructor
                 * @param {infinitusai.be.IFileUploadResult=} [properties] Properties to set
                 */
                function FileUploadResult(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileUploadResult filename.
                 * @member {string} filename
                 * @memberof infinitusai.be.FileUploadResult
                 * @instance
                 */
                FileUploadResult.prototype.filename = "";
    
                /**
                 * FileUploadResult fileSize.
                 * @member {number|Long} fileSize
                 * @memberof infinitusai.be.FileUploadResult
                 * @instance
                 */
                FileUploadResult.prototype.fileSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * FileUploadResult uploadSuccess.
                 * @member {boolean} uploadSuccess
                 * @memberof infinitusai.be.FileUploadResult
                 * @instance
                 */
                FileUploadResult.prototype.uploadSuccess = false;
    
                /**
                 * Creates a new FileUploadResult instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {infinitusai.be.IFileUploadResult=} [properties] Properties to set
                 * @returns {infinitusai.be.FileUploadResult} FileUploadResult instance
                 */
                FileUploadResult.create = function create(properties) {
                    return new FileUploadResult(properties);
                };
    
                /**
                 * Encodes the specified FileUploadResult message. Does not implicitly {@link infinitusai.be.FileUploadResult.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {infinitusai.be.IFileUploadResult} message FileUploadResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileUploadResult.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.filename);
                    if (message.fileSize != null && Object.hasOwnProperty.call(message, "fileSize"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.fileSize);
                    if (message.uploadSuccess != null && Object.hasOwnProperty.call(message, "uploadSuccess"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.uploadSuccess);
                    return writer;
                };
    
                /**
                 * Encodes the specified FileUploadResult message, length delimited. Does not implicitly {@link infinitusai.be.FileUploadResult.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {infinitusai.be.IFileUploadResult} message FileUploadResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileUploadResult.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileUploadResult message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FileUploadResult} FileUploadResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileUploadResult.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FileUploadResult();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.filename = reader.string();
                                break;
                            }
                        case 2: {
                                message.fileSize = reader.int64();
                                break;
                            }
                        case 3: {
                                message.uploadSuccess = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileUploadResult message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FileUploadResult} FileUploadResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileUploadResult.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileUploadResult message.
                 * @function verify
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileUploadResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.filename != null && message.hasOwnProperty("filename"))
                        if (!$util.isString(message.filename))
                            return "filename: string expected";
                    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
                        if (!$util.isInteger(message.fileSize) && !(message.fileSize && $util.isInteger(message.fileSize.low) && $util.isInteger(message.fileSize.high)))
                            return "fileSize: integer|Long expected";
                    if (message.uploadSuccess != null && message.hasOwnProperty("uploadSuccess"))
                        if (typeof message.uploadSuccess !== "boolean")
                            return "uploadSuccess: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a FileUploadResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FileUploadResult} FileUploadResult
                 */
                FileUploadResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FileUploadResult)
                        return object;
                    var message = new $root.infinitusai.be.FileUploadResult();
                    if (object.filename != null)
                        message.filename = String(object.filename);
                    if (object.fileSize != null)
                        if ($util.Long)
                            (message.fileSize = $util.Long.fromValue(object.fileSize)).unsigned = false;
                        else if (typeof object.fileSize === "string")
                            message.fileSize = parseInt(object.fileSize, 10);
                        else if (typeof object.fileSize === "number")
                            message.fileSize = object.fileSize;
                        else if (typeof object.fileSize === "object")
                            message.fileSize = new $util.LongBits(object.fileSize.low >>> 0, object.fileSize.high >>> 0).toNumber();
                    if (object.uploadSuccess != null)
                        message.uploadSuccess = Boolean(object.uploadSuccess);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileUploadResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {infinitusai.be.FileUploadResult} message FileUploadResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileUploadResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.filename = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fileSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fileSize = options.longs === String ? "0" : 0;
                        object.uploadSuccess = false;
                    }
                    if (message.filename != null && message.hasOwnProperty("filename"))
                        object.filename = message.filename;
                    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
                        if (typeof message.fileSize === "number")
                            object.fileSize = options.longs === String ? String(message.fileSize) : message.fileSize;
                        else
                            object.fileSize = options.longs === String ? $util.Long.prototype.toString.call(message.fileSize) : options.longs === Number ? new $util.LongBits(message.fileSize.low >>> 0, message.fileSize.high >>> 0).toNumber() : message.fileSize;
                    if (message.uploadSuccess != null && message.hasOwnProperty("uploadSuccess"))
                        object.uploadSuccess = message.uploadSuccess;
                    return object;
                };
    
                /**
                 * Converts this FileUploadResult to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FileUploadResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileUploadResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FileUploadResult
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FileUploadResult
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FileUploadResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FileUploadResult";
                };
    
                return FileUploadResult;
            })();
    
            be.IncidentType = (function() {
    
                /**
                 * Properties of an IncidentType.
                 * @memberof infinitusai.be
                 * @interface IIncidentType
                 * @property {string|null} [id] IncidentType id
                 * @property {string|null} [category] IncidentType category
                 * @property {string|null} [subcategory] IncidentType subcategory
                 * @property {string|null} [summary] IncidentType summary
                 * @property {string|null} [priority] IncidentType priority
                 * @property {boolean|null} [visible] IncidentType visible
                 */
    
                /**
                 * Constructs a new IncidentType.
                 * @memberof infinitusai.be
                 * @classdesc Represents an IncidentType.
                 * @implements IIncidentType
                 * @constructor
                 * @param {infinitusai.be.IIncidentType=} [properties] Properties to set
                 */
                function IncidentType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IncidentType id.
                 * @member {string} id
                 * @memberof infinitusai.be.IncidentType
                 * @instance
                 */
                IncidentType.prototype.id = "";
    
                /**
                 * IncidentType category.
                 * @member {string} category
                 * @memberof infinitusai.be.IncidentType
                 * @instance
                 */
                IncidentType.prototype.category = "";
    
                /**
                 * IncidentType subcategory.
                 * @member {string} subcategory
                 * @memberof infinitusai.be.IncidentType
                 * @instance
                 */
                IncidentType.prototype.subcategory = "";
    
                /**
                 * IncidentType summary.
                 * @member {string} summary
                 * @memberof infinitusai.be.IncidentType
                 * @instance
                 */
                IncidentType.prototype.summary = "";
    
                /**
                 * IncidentType priority.
                 * @member {string} priority
                 * @memberof infinitusai.be.IncidentType
                 * @instance
                 */
                IncidentType.prototype.priority = "";
    
                /**
                 * IncidentType visible.
                 * @member {boolean} visible
                 * @memberof infinitusai.be.IncidentType
                 * @instance
                 */
                IncidentType.prototype.visible = false;
    
                /**
                 * Creates a new IncidentType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {infinitusai.be.IIncidentType=} [properties] Properties to set
                 * @returns {infinitusai.be.IncidentType} IncidentType instance
                 */
                IncidentType.create = function create(properties) {
                    return new IncidentType(properties);
                };
    
                /**
                 * Encodes the specified IncidentType message. Does not implicitly {@link infinitusai.be.IncidentType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {infinitusai.be.IIncidentType} message IncidentType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncidentType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.category);
                    if (message.subcategory != null && Object.hasOwnProperty.call(message, "subcategory"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.subcategory);
                    if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.summary);
                    if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.priority);
                    if (message.visible != null && Object.hasOwnProperty.call(message, "visible"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.visible);
                    return writer;
                };
    
                /**
                 * Encodes the specified IncidentType message, length delimited. Does not implicitly {@link infinitusai.be.IncidentType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {infinitusai.be.IIncidentType} message IncidentType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncidentType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IncidentType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IncidentType} IncidentType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncidentType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IncidentType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.category = reader.string();
                                break;
                            }
                        case 3: {
                                message.subcategory = reader.string();
                                break;
                            }
                        case 4: {
                                message.summary = reader.string();
                                break;
                            }
                        case 5: {
                                message.priority = reader.string();
                                break;
                            }
                        case 6: {
                                message.visible = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IncidentType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IncidentType} IncidentType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncidentType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IncidentType message.
                 * @function verify
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IncidentType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                        if (!$util.isString(message.subcategory))
                            return "subcategory: string expected";
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        if (!$util.isString(message.summary))
                            return "summary: string expected";
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        if (!$util.isString(message.priority))
                            return "priority: string expected";
                    if (message.visible != null && message.hasOwnProperty("visible"))
                        if (typeof message.visible !== "boolean")
                            return "visible: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an IncidentType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IncidentType} IncidentType
                 */
                IncidentType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IncidentType)
                        return object;
                    var message = new $root.infinitusai.be.IncidentType();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.subcategory != null)
                        message.subcategory = String(object.subcategory);
                    if (object.summary != null)
                        message.summary = String(object.summary);
                    if (object.priority != null)
                        message.priority = String(object.priority);
                    if (object.visible != null)
                        message.visible = Boolean(object.visible);
                    return message;
                };
    
                /**
                 * Creates a plain object from an IncidentType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {infinitusai.be.IncidentType} message IncidentType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IncidentType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.category = "";
                        object.subcategory = "";
                        object.summary = "";
                        object.priority = "";
                        object.visible = false;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                        object.subcategory = message.subcategory;
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = message.summary;
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        object.priority = message.priority;
                    if (message.visible != null && message.hasOwnProperty("visible"))
                        object.visible = message.visible;
                    return object;
                };
    
                /**
                 * Converts this IncidentType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IncidentType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IncidentType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IncidentType
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IncidentType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IncidentType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IncidentType";
                };
    
                return IncidentType;
            })();
    
            be.IncidentTypeFilter = (function() {
    
                /**
                 * Properties of an IncidentTypeFilter.
                 * @memberof infinitusai.be
                 * @interface IIncidentTypeFilter
                 * @property {string|null} [incidentTypeUUID] IncidentTypeFilter incidentTypeUUID
                 * @property {string|null} [category] IncidentTypeFilter category
                 * @property {string|null} [subcategory] IncidentTypeFilter subcategory
                 * @property {string|null} [summary] IncidentTypeFilter summary
                 * @property {string|null} [priority] IncidentTypeFilter priority
                 * @property {boolean|null} [onlyVisible] IncidentTypeFilter onlyVisible
                 */
    
                /**
                 * Constructs a new IncidentTypeFilter.
                 * @memberof infinitusai.be
                 * @classdesc Represents an IncidentTypeFilter.
                 * @implements IIncidentTypeFilter
                 * @constructor
                 * @param {infinitusai.be.IIncidentTypeFilter=} [properties] Properties to set
                 */
                function IncidentTypeFilter(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IncidentTypeFilter incidentTypeUUID.
                 * @member {string} incidentTypeUUID
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @instance
                 */
                IncidentTypeFilter.prototype.incidentTypeUUID = "";
    
                /**
                 * IncidentTypeFilter category.
                 * @member {string} category
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @instance
                 */
                IncidentTypeFilter.prototype.category = "";
    
                /**
                 * IncidentTypeFilter subcategory.
                 * @member {string} subcategory
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @instance
                 */
                IncidentTypeFilter.prototype.subcategory = "";
    
                /**
                 * IncidentTypeFilter summary.
                 * @member {string} summary
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @instance
                 */
                IncidentTypeFilter.prototype.summary = "";
    
                /**
                 * IncidentTypeFilter priority.
                 * @member {string} priority
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @instance
                 */
                IncidentTypeFilter.prototype.priority = "";
    
                /**
                 * IncidentTypeFilter onlyVisible.
                 * @member {boolean} onlyVisible
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @instance
                 */
                IncidentTypeFilter.prototype.onlyVisible = false;
    
                /**
                 * Creates a new IncidentTypeFilter instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {infinitusai.be.IIncidentTypeFilter=} [properties] Properties to set
                 * @returns {infinitusai.be.IncidentTypeFilter} IncidentTypeFilter instance
                 */
                IncidentTypeFilter.create = function create(properties) {
                    return new IncidentTypeFilter(properties);
                };
    
                /**
                 * Encodes the specified IncidentTypeFilter message. Does not implicitly {@link infinitusai.be.IncidentTypeFilter.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {infinitusai.be.IIncidentTypeFilter} message IncidentTypeFilter message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncidentTypeFilter.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.incidentTypeUUID != null && Object.hasOwnProperty.call(message, "incidentTypeUUID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.incidentTypeUUID);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.category);
                    if (message.subcategory != null && Object.hasOwnProperty.call(message, "subcategory"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.subcategory);
                    if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.summary);
                    if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.priority);
                    if (message.onlyVisible != null && Object.hasOwnProperty.call(message, "onlyVisible"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.onlyVisible);
                    return writer;
                };
    
                /**
                 * Encodes the specified IncidentTypeFilter message, length delimited. Does not implicitly {@link infinitusai.be.IncidentTypeFilter.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {infinitusai.be.IIncidentTypeFilter} message IncidentTypeFilter message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncidentTypeFilter.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IncidentTypeFilter message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IncidentTypeFilter} IncidentTypeFilter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncidentTypeFilter.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IncidentTypeFilter();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.incidentTypeUUID = reader.string();
                                break;
                            }
                        case 2: {
                                message.category = reader.string();
                                break;
                            }
                        case 3: {
                                message.subcategory = reader.string();
                                break;
                            }
                        case 4: {
                                message.summary = reader.string();
                                break;
                            }
                        case 5: {
                                message.priority = reader.string();
                                break;
                            }
                        case 6: {
                                message.onlyVisible = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IncidentTypeFilter message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IncidentTypeFilter} IncidentTypeFilter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncidentTypeFilter.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IncidentTypeFilter message.
                 * @function verify
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IncidentTypeFilter.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.incidentTypeUUID != null && message.hasOwnProperty("incidentTypeUUID"))
                        if (!$util.isString(message.incidentTypeUUID))
                            return "incidentTypeUUID: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                        if (!$util.isString(message.subcategory))
                            return "subcategory: string expected";
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        if (!$util.isString(message.summary))
                            return "summary: string expected";
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        if (!$util.isString(message.priority))
                            return "priority: string expected";
                    if (message.onlyVisible != null && message.hasOwnProperty("onlyVisible"))
                        if (typeof message.onlyVisible !== "boolean")
                            return "onlyVisible: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an IncidentTypeFilter message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IncidentTypeFilter} IncidentTypeFilter
                 */
                IncidentTypeFilter.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IncidentTypeFilter)
                        return object;
                    var message = new $root.infinitusai.be.IncidentTypeFilter();
                    if (object.incidentTypeUUID != null)
                        message.incidentTypeUUID = String(object.incidentTypeUUID);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.subcategory != null)
                        message.subcategory = String(object.subcategory);
                    if (object.summary != null)
                        message.summary = String(object.summary);
                    if (object.priority != null)
                        message.priority = String(object.priority);
                    if (object.onlyVisible != null)
                        message.onlyVisible = Boolean(object.onlyVisible);
                    return message;
                };
    
                /**
                 * Creates a plain object from an IncidentTypeFilter message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {infinitusai.be.IncidentTypeFilter} message IncidentTypeFilter
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IncidentTypeFilter.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.incidentTypeUUID = "";
                        object.category = "";
                        object.subcategory = "";
                        object.summary = "";
                        object.priority = "";
                        object.onlyVisible = false;
                    }
                    if (message.incidentTypeUUID != null && message.hasOwnProperty("incidentTypeUUID"))
                        object.incidentTypeUUID = message.incidentTypeUUID;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                        object.subcategory = message.subcategory;
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = message.summary;
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        object.priority = message.priority;
                    if (message.onlyVisible != null && message.hasOwnProperty("onlyVisible"))
                        object.onlyVisible = message.onlyVisible;
                    return object;
                };
    
                /**
                 * Converts this IncidentTypeFilter to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IncidentTypeFilter.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IncidentTypeFilter
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IncidentTypeFilter
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IncidentTypeFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IncidentTypeFilter";
                };
    
                return IncidentTypeFilter;
            })();
    
            be.Incident = (function() {
    
                /**
                 * Properties of an Incident.
                 * @memberof infinitusai.be
                 * @interface IIncident
                 * @property {string|null} [UUID] Incident UUID
                 * @property {string|null} [incidentTypeUUID] Incident incidentTypeUUID
                 * @property {string|null} [reportedByUID] Incident reportedByUID
                 * @property {string|null} [orgUUID] Incident orgUUID
                 * @property {string|null} [taskUUID] Incident taskUUID
                 * @property {string|null} [callUUID] Incident callUUID
                 * @property {string|null} [orgRole] Incident orgRole
                 * @property {string|null} [operatorRegion] Incident operatorRegion
                 * @property {string|null} [environment] Incident environment
                 * @property {boolean|null} [onCall] Incident onCall
                 * @property {string|null} [pageUrl] Incident pageUrl
                 * @property {string|null} [description] Incident description
                 * @property {string|null} [gcsFolderPath] Incident gcsFolderPath
                 * @property {Array.<string>|null} [logs] Incident logs
                 * @property {number|Long|null} [createTime] Incident createTime
                 * @property {number|Long|null} [updateTime] Incident updateTime
                 * @property {Array.<string>|null} [filenames] Incident filenames
                 * @property {string|null} [payerID] Incident payerID
                 * @property {Object.<string,string>|null} [automationData] Incident automationData
                 * @property {number|null} [score] Incident score
                 */
    
                /**
                 * Constructs a new Incident.
                 * @memberof infinitusai.be
                 * @classdesc Represents an Incident.
                 * @implements IIncident
                 * @constructor
                 * @param {infinitusai.be.IIncident=} [properties] Properties to set
                 */
                function Incident(properties) {
                    this.logs = [];
                    this.filenames = [];
                    this.automationData = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Incident UUID.
                 * @member {string} UUID
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.UUID = "";
    
                /**
                 * Incident incidentTypeUUID.
                 * @member {string} incidentTypeUUID
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.incidentTypeUUID = "";
    
                /**
                 * Incident reportedByUID.
                 * @member {string} reportedByUID
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.reportedByUID = "";
    
                /**
                 * Incident orgUUID.
                 * @member {string} orgUUID
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.orgUUID = "";
    
                /**
                 * Incident taskUUID.
                 * @member {string} taskUUID
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.taskUUID = "";
    
                /**
                 * Incident callUUID.
                 * @member {string} callUUID
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.callUUID = "";
    
                /**
                 * Incident orgRole.
                 * @member {string} orgRole
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.orgRole = "";
    
                /**
                 * Incident operatorRegion.
                 * @member {string} operatorRegion
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.operatorRegion = "";
    
                /**
                 * Incident environment.
                 * @member {string} environment
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.environment = "";
    
                /**
                 * Incident onCall.
                 * @member {boolean} onCall
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.onCall = false;
    
                /**
                 * Incident pageUrl.
                 * @member {string} pageUrl
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.pageUrl = "";
    
                /**
                 * Incident description.
                 * @member {string} description
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.description = "";
    
                /**
                 * Incident gcsFolderPath.
                 * @member {string} gcsFolderPath
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.gcsFolderPath = "";
    
                /**
                 * Incident logs.
                 * @member {Array.<string>} logs
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.logs = $util.emptyArray;
    
                /**
                 * Incident createTime.
                 * @member {number|Long} createTime
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.createTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Incident updateTime.
                 * @member {number|Long} updateTime
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.updateTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Incident filenames.
                 * @member {Array.<string>} filenames
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.filenames = $util.emptyArray;
    
                /**
                 * Incident payerID.
                 * @member {string} payerID
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.payerID = "";
    
                /**
                 * Incident automationData.
                 * @member {Object.<string,string>} automationData
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.automationData = $util.emptyObject;
    
                /**
                 * Incident score.
                 * @member {number} score
                 * @memberof infinitusai.be.Incident
                 * @instance
                 */
                Incident.prototype.score = 0;
    
                /**
                 * Creates a new Incident instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {infinitusai.be.IIncident=} [properties] Properties to set
                 * @returns {infinitusai.be.Incident} Incident instance
                 */
                Incident.create = function create(properties) {
                    return new Incident(properties);
                };
    
                /**
                 * Encodes the specified Incident message. Does not implicitly {@link infinitusai.be.Incident.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {infinitusai.be.IIncident} message Incident message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Incident.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.UUID != null && Object.hasOwnProperty.call(message, "UUID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.UUID);
                    if (message.incidentTypeUUID != null && Object.hasOwnProperty.call(message, "incidentTypeUUID"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.incidentTypeUUID);
                    if (message.reportedByUID != null && Object.hasOwnProperty.call(message, "reportedByUID"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.reportedByUID);
                    if (message.orgUUID != null && Object.hasOwnProperty.call(message, "orgUUID"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orgUUID);
                    if (message.taskUUID != null && Object.hasOwnProperty.call(message, "taskUUID"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.taskUUID);
                    if (message.callUUID != null && Object.hasOwnProperty.call(message, "callUUID"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.callUUID);
                    if (message.orgRole != null && Object.hasOwnProperty.call(message, "orgRole"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.orgRole);
                    if (message.operatorRegion != null && Object.hasOwnProperty.call(message, "operatorRegion"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.operatorRegion);
                    if (message.environment != null && Object.hasOwnProperty.call(message, "environment"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.environment);
                    if (message.onCall != null && Object.hasOwnProperty.call(message, "onCall"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.onCall);
                    if (message.pageUrl != null && Object.hasOwnProperty.call(message, "pageUrl"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.pageUrl);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.description);
                    if (message.gcsFolderPath != null && Object.hasOwnProperty.call(message, "gcsFolderPath"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.gcsFolderPath);
                    if (message.logs != null && message.logs.length)
                        for (var i = 0; i < message.logs.length; ++i)
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.logs[i]);
                    if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                        writer.uint32(/* id 15, wireType 0 =*/120).int64(message.createTime);
                    if (message.updateTime != null && Object.hasOwnProperty.call(message, "updateTime"))
                        writer.uint32(/* id 16, wireType 0 =*/128).int64(message.updateTime);
                    if (message.filenames != null && message.filenames.length)
                        for (var i = 0; i < message.filenames.length; ++i)
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.filenames[i]);
                    if (message.payerID != null && Object.hasOwnProperty.call(message, "payerID"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.payerID);
                    if (message.automationData != null && Object.hasOwnProperty.call(message, "automationData"))
                        for (var keys = Object.keys(message.automationData), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 19, wireType 2 =*/154).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.automationData[keys[i]]).ldelim();
                    if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                        writer.uint32(/* id 20, wireType 0 =*/160).int32(message.score);
                    return writer;
                };
    
                /**
                 * Encodes the specified Incident message, length delimited. Does not implicitly {@link infinitusai.be.Incident.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {infinitusai.be.IIncident} message Incident message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Incident.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Incident message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Incident} Incident
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Incident.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Incident(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.UUID = reader.string();
                                break;
                            }
                        case 2: {
                                message.incidentTypeUUID = reader.string();
                                break;
                            }
                        case 3: {
                                message.reportedByUID = reader.string();
                                break;
                            }
                        case 4: {
                                message.orgUUID = reader.string();
                                break;
                            }
                        case 5: {
                                message.taskUUID = reader.string();
                                break;
                            }
                        case 6: {
                                message.callUUID = reader.string();
                                break;
                            }
                        case 7: {
                                message.orgRole = reader.string();
                                break;
                            }
                        case 8: {
                                message.operatorRegion = reader.string();
                                break;
                            }
                        case 9: {
                                message.environment = reader.string();
                                break;
                            }
                        case 10: {
                                message.onCall = reader.bool();
                                break;
                            }
                        case 11: {
                                message.pageUrl = reader.string();
                                break;
                            }
                        case 12: {
                                message.description = reader.string();
                                break;
                            }
                        case 13: {
                                message.gcsFolderPath = reader.string();
                                break;
                            }
                        case 14: {
                                if (!(message.logs && message.logs.length))
                                    message.logs = [];
                                message.logs.push(reader.string());
                                break;
                            }
                        case 15: {
                                message.createTime = reader.int64();
                                break;
                            }
                        case 16: {
                                message.updateTime = reader.int64();
                                break;
                            }
                        case 17: {
                                if (!(message.filenames && message.filenames.length))
                                    message.filenames = [];
                                message.filenames.push(reader.string());
                                break;
                            }
                        case 18: {
                                message.payerID = reader.string();
                                break;
                            }
                        case 19: {
                                if (message.automationData === $util.emptyObject)
                                    message.automationData = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.automationData[key] = value;
                                break;
                            }
                        case 20: {
                                message.score = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Incident message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Incident} Incident
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Incident.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Incident message.
                 * @function verify
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Incident.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.UUID != null && message.hasOwnProperty("UUID"))
                        if (!$util.isString(message.UUID))
                            return "UUID: string expected";
                    if (message.incidentTypeUUID != null && message.hasOwnProperty("incidentTypeUUID"))
                        if (!$util.isString(message.incidentTypeUUID))
                            return "incidentTypeUUID: string expected";
                    if (message.reportedByUID != null && message.hasOwnProperty("reportedByUID"))
                        if (!$util.isString(message.reportedByUID))
                            return "reportedByUID: string expected";
                    if (message.orgUUID != null && message.hasOwnProperty("orgUUID"))
                        if (!$util.isString(message.orgUUID))
                            return "orgUUID: string expected";
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        if (!$util.isString(message.taskUUID))
                            return "taskUUID: string expected";
                    if (message.callUUID != null && message.hasOwnProperty("callUUID"))
                        if (!$util.isString(message.callUUID))
                            return "callUUID: string expected";
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        if (!$util.isString(message.orgRole))
                            return "orgRole: string expected";
                    if (message.operatorRegion != null && message.hasOwnProperty("operatorRegion"))
                        if (!$util.isString(message.operatorRegion))
                            return "operatorRegion: string expected";
                    if (message.environment != null && message.hasOwnProperty("environment"))
                        if (!$util.isString(message.environment))
                            return "environment: string expected";
                    if (message.onCall != null && message.hasOwnProperty("onCall"))
                        if (typeof message.onCall !== "boolean")
                            return "onCall: boolean expected";
                    if (message.pageUrl != null && message.hasOwnProperty("pageUrl"))
                        if (!$util.isString(message.pageUrl))
                            return "pageUrl: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.gcsFolderPath != null && message.hasOwnProperty("gcsFolderPath"))
                        if (!$util.isString(message.gcsFolderPath))
                            return "gcsFolderPath: string expected";
                    if (message.logs != null && message.hasOwnProperty("logs")) {
                        if (!Array.isArray(message.logs))
                            return "logs: array expected";
                        for (var i = 0; i < message.logs.length; ++i)
                            if (!$util.isString(message.logs[i]))
                                return "logs: string[] expected";
                    }
                    if (message.createTime != null && message.hasOwnProperty("createTime"))
                        if (!$util.isInteger(message.createTime) && !(message.createTime && $util.isInteger(message.createTime.low) && $util.isInteger(message.createTime.high)))
                            return "createTime: integer|Long expected";
                    if (message.updateTime != null && message.hasOwnProperty("updateTime"))
                        if (!$util.isInteger(message.updateTime) && !(message.updateTime && $util.isInteger(message.updateTime.low) && $util.isInteger(message.updateTime.high)))
                            return "updateTime: integer|Long expected";
                    if (message.filenames != null && message.hasOwnProperty("filenames")) {
                        if (!Array.isArray(message.filenames))
                            return "filenames: array expected";
                        for (var i = 0; i < message.filenames.length; ++i)
                            if (!$util.isString(message.filenames[i]))
                                return "filenames: string[] expected";
                    }
                    if (message.payerID != null && message.hasOwnProperty("payerID"))
                        if (!$util.isString(message.payerID))
                            return "payerID: string expected";
                    if (message.automationData != null && message.hasOwnProperty("automationData")) {
                        if (!$util.isObject(message.automationData))
                            return "automationData: object expected";
                        var key = Object.keys(message.automationData);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.automationData[key[i]]))
                                return "automationData: string{k:string} expected";
                    }
                    if (message.score != null && message.hasOwnProperty("score"))
                        if (!$util.isInteger(message.score))
                            return "score: integer expected";
                    return null;
                };
    
                /**
                 * Creates an Incident message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Incident} Incident
                 */
                Incident.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Incident)
                        return object;
                    var message = new $root.infinitusai.be.Incident();
                    if (object.UUID != null)
                        message.UUID = String(object.UUID);
                    if (object.incidentTypeUUID != null)
                        message.incidentTypeUUID = String(object.incidentTypeUUID);
                    if (object.reportedByUID != null)
                        message.reportedByUID = String(object.reportedByUID);
                    if (object.orgUUID != null)
                        message.orgUUID = String(object.orgUUID);
                    if (object.taskUUID != null)
                        message.taskUUID = String(object.taskUUID);
                    if (object.callUUID != null)
                        message.callUUID = String(object.callUUID);
                    if (object.orgRole != null)
                        message.orgRole = String(object.orgRole);
                    if (object.operatorRegion != null)
                        message.operatorRegion = String(object.operatorRegion);
                    if (object.environment != null)
                        message.environment = String(object.environment);
                    if (object.onCall != null)
                        message.onCall = Boolean(object.onCall);
                    if (object.pageUrl != null)
                        message.pageUrl = String(object.pageUrl);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.gcsFolderPath != null)
                        message.gcsFolderPath = String(object.gcsFolderPath);
                    if (object.logs) {
                        if (!Array.isArray(object.logs))
                            throw TypeError(".infinitusai.be.Incident.logs: array expected");
                        message.logs = [];
                        for (var i = 0; i < object.logs.length; ++i)
                            message.logs[i] = String(object.logs[i]);
                    }
                    if (object.createTime != null)
                        if ($util.Long)
                            (message.createTime = $util.Long.fromValue(object.createTime)).unsigned = false;
                        else if (typeof object.createTime === "string")
                            message.createTime = parseInt(object.createTime, 10);
                        else if (typeof object.createTime === "number")
                            message.createTime = object.createTime;
                        else if (typeof object.createTime === "object")
                            message.createTime = new $util.LongBits(object.createTime.low >>> 0, object.createTime.high >>> 0).toNumber();
                    if (object.updateTime != null)
                        if ($util.Long)
                            (message.updateTime = $util.Long.fromValue(object.updateTime)).unsigned = false;
                        else if (typeof object.updateTime === "string")
                            message.updateTime = parseInt(object.updateTime, 10);
                        else if (typeof object.updateTime === "number")
                            message.updateTime = object.updateTime;
                        else if (typeof object.updateTime === "object")
                            message.updateTime = new $util.LongBits(object.updateTime.low >>> 0, object.updateTime.high >>> 0).toNumber();
                    if (object.filenames) {
                        if (!Array.isArray(object.filenames))
                            throw TypeError(".infinitusai.be.Incident.filenames: array expected");
                        message.filenames = [];
                        for (var i = 0; i < object.filenames.length; ++i)
                            message.filenames[i] = String(object.filenames[i]);
                    }
                    if (object.payerID != null)
                        message.payerID = String(object.payerID);
                    if (object.automationData) {
                        if (typeof object.automationData !== "object")
                            throw TypeError(".infinitusai.be.Incident.automationData: object expected");
                        message.automationData = {};
                        for (var keys = Object.keys(object.automationData), i = 0; i < keys.length; ++i)
                            message.automationData[keys[i]] = String(object.automationData[keys[i]]);
                    }
                    if (object.score != null)
                        message.score = object.score | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from an Incident message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {infinitusai.be.Incident} message Incident
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Incident.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.logs = [];
                        object.filenames = [];
                    }
                    if (options.objects || options.defaults)
                        object.automationData = {};
                    if (options.defaults) {
                        object.UUID = "";
                        object.incidentTypeUUID = "";
                        object.reportedByUID = "";
                        object.orgUUID = "";
                        object.taskUUID = "";
                        object.callUUID = "";
                        object.orgRole = "";
                        object.operatorRegion = "";
                        object.environment = "";
                        object.onCall = false;
                        object.pageUrl = "";
                        object.description = "";
                        object.gcsFolderPath = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTime = options.longs === String ? "0" : 0;
                        object.payerID = "";
                        object.score = 0;
                    }
                    if (message.UUID != null && message.hasOwnProperty("UUID"))
                        object.UUID = message.UUID;
                    if (message.incidentTypeUUID != null && message.hasOwnProperty("incidentTypeUUID"))
                        object.incidentTypeUUID = message.incidentTypeUUID;
                    if (message.reportedByUID != null && message.hasOwnProperty("reportedByUID"))
                        object.reportedByUID = message.reportedByUID;
                    if (message.orgUUID != null && message.hasOwnProperty("orgUUID"))
                        object.orgUUID = message.orgUUID;
                    if (message.taskUUID != null && message.hasOwnProperty("taskUUID"))
                        object.taskUUID = message.taskUUID;
                    if (message.callUUID != null && message.hasOwnProperty("callUUID"))
                        object.callUUID = message.callUUID;
                    if (message.orgRole != null && message.hasOwnProperty("orgRole"))
                        object.orgRole = message.orgRole;
                    if (message.operatorRegion != null && message.hasOwnProperty("operatorRegion"))
                        object.operatorRegion = message.operatorRegion;
                    if (message.environment != null && message.hasOwnProperty("environment"))
                        object.environment = message.environment;
                    if (message.onCall != null && message.hasOwnProperty("onCall"))
                        object.onCall = message.onCall;
                    if (message.pageUrl != null && message.hasOwnProperty("pageUrl"))
                        object.pageUrl = message.pageUrl;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.gcsFolderPath != null && message.hasOwnProperty("gcsFolderPath"))
                        object.gcsFolderPath = message.gcsFolderPath;
                    if (message.logs && message.logs.length) {
                        object.logs = [];
                        for (var j = 0; j < message.logs.length; ++j)
                            object.logs[j] = message.logs[j];
                    }
                    if (message.createTime != null && message.hasOwnProperty("createTime"))
                        if (typeof message.createTime === "number")
                            object.createTime = options.longs === String ? String(message.createTime) : message.createTime;
                        else
                            object.createTime = options.longs === String ? $util.Long.prototype.toString.call(message.createTime) : options.longs === Number ? new $util.LongBits(message.createTime.low >>> 0, message.createTime.high >>> 0).toNumber() : message.createTime;
                    if (message.updateTime != null && message.hasOwnProperty("updateTime"))
                        if (typeof message.updateTime === "number")
                            object.updateTime = options.longs === String ? String(message.updateTime) : message.updateTime;
                        else
                            object.updateTime = options.longs === String ? $util.Long.prototype.toString.call(message.updateTime) : options.longs === Number ? new $util.LongBits(message.updateTime.low >>> 0, message.updateTime.high >>> 0).toNumber() : message.updateTime;
                    if (message.filenames && message.filenames.length) {
                        object.filenames = [];
                        for (var j = 0; j < message.filenames.length; ++j)
                            object.filenames[j] = message.filenames[j];
                    }
                    if (message.payerID != null && message.hasOwnProperty("payerID"))
                        object.payerID = message.payerID;
                    var keys2;
                    if (message.automationData && (keys2 = Object.keys(message.automationData)).length) {
                        object.automationData = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.automationData[keys2[j]] = message.automationData[keys2[j]];
                    }
                    if (message.score != null && message.hasOwnProperty("score"))
                        object.score = message.score;
                    return object;
                };
    
                /**
                 * Converts this Incident to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Incident
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Incident.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Incident
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Incident
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Incident.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Incident";
                };
    
                return Incident;
            })();
    
            be.IncidentFilter = (function() {
    
                /**
                 * Properties of an IncidentFilter.
                 * @memberof infinitusai.be
                 * @interface IIncidentFilter
                 * @property {string|null} [UUID] IncidentFilter UUID
                 * @property {string|null} [incidentTypeUUID] IncidentFilter incidentTypeUUID
                 */
    
                /**
                 * Constructs a new IncidentFilter.
                 * @memberof infinitusai.be
                 * @classdesc Represents an IncidentFilter.
                 * @implements IIncidentFilter
                 * @constructor
                 * @param {infinitusai.be.IIncidentFilter=} [properties] Properties to set
                 */
                function IncidentFilter(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IncidentFilter UUID.
                 * @member {string} UUID
                 * @memberof infinitusai.be.IncidentFilter
                 * @instance
                 */
                IncidentFilter.prototype.UUID = "";
    
                /**
                 * IncidentFilter incidentTypeUUID.
                 * @member {string} incidentTypeUUID
                 * @memberof infinitusai.be.IncidentFilter
                 * @instance
                 */
                IncidentFilter.prototype.incidentTypeUUID = "";
    
                /**
                 * Creates a new IncidentFilter instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {infinitusai.be.IIncidentFilter=} [properties] Properties to set
                 * @returns {infinitusai.be.IncidentFilter} IncidentFilter instance
                 */
                IncidentFilter.create = function create(properties) {
                    return new IncidentFilter(properties);
                };
    
                /**
                 * Encodes the specified IncidentFilter message. Does not implicitly {@link infinitusai.be.IncidentFilter.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {infinitusai.be.IIncidentFilter} message IncidentFilter message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncidentFilter.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.UUID != null && Object.hasOwnProperty.call(message, "UUID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.UUID);
                    if (message.incidentTypeUUID != null && Object.hasOwnProperty.call(message, "incidentTypeUUID"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.incidentTypeUUID);
                    return writer;
                };
    
                /**
                 * Encodes the specified IncidentFilter message, length delimited. Does not implicitly {@link infinitusai.be.IncidentFilter.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {infinitusai.be.IIncidentFilter} message IncidentFilter message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncidentFilter.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IncidentFilter message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IncidentFilter} IncidentFilter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncidentFilter.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IncidentFilter();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.UUID = reader.string();
                                break;
                            }
                        case 2: {
                                message.incidentTypeUUID = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IncidentFilter message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IncidentFilter} IncidentFilter
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncidentFilter.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IncidentFilter message.
                 * @function verify
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IncidentFilter.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.UUID != null && message.hasOwnProperty("UUID"))
                        if (!$util.isString(message.UUID))
                            return "UUID: string expected";
                    if (message.incidentTypeUUID != null && message.hasOwnProperty("incidentTypeUUID"))
                        if (!$util.isString(message.incidentTypeUUID))
                            return "incidentTypeUUID: string expected";
                    return null;
                };
    
                /**
                 * Creates an IncidentFilter message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IncidentFilter} IncidentFilter
                 */
                IncidentFilter.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IncidentFilter)
                        return object;
                    var message = new $root.infinitusai.be.IncidentFilter();
                    if (object.UUID != null)
                        message.UUID = String(object.UUID);
                    if (object.incidentTypeUUID != null)
                        message.incidentTypeUUID = String(object.incidentTypeUUID);
                    return message;
                };
    
                /**
                 * Creates a plain object from an IncidentFilter message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {infinitusai.be.IncidentFilter} message IncidentFilter
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IncidentFilter.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.UUID = "";
                        object.incidentTypeUUID = "";
                    }
                    if (message.UUID != null && message.hasOwnProperty("UUID"))
                        object.UUID = message.UUID;
                    if (message.incidentTypeUUID != null && message.hasOwnProperty("incidentTypeUUID"))
                        object.incidentTypeUUID = message.incidentTypeUUID;
                    return object;
                };
    
                /**
                 * Converts this IncidentFilter to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IncidentFilter
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IncidentFilter.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IncidentFilter
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IncidentFilter
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IncidentFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IncidentFilter";
                };
    
                return IncidentFilter;
            })();
    
            be.IVRConfidence = (function() {
    
                /**
                 * Properties of a IVRConfidence.
                 * @memberof infinitusai.be
                 * @interface IIVRConfidence
                 * @property {boolean|null} [hasConfidence] IVRConfidence hasConfidence
                 * @property {number|null} [confidence] IVRConfidence confidence
                 * @property {infinitusai.be.IIVRActor|null} [attribute] IVRConfidence attribute
                 */
    
                /**
                 * Constructs a new IVRConfidence.
                 * @memberof infinitusai.be
                 * @classdesc Represents a IVRConfidence.
                 * @implements IIVRConfidence
                 * @constructor
                 * @param {infinitusai.be.IIVRConfidence=} [properties] Properties to set
                 */
                function IVRConfidence(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IVRConfidence hasConfidence.
                 * @member {boolean} hasConfidence
                 * @memberof infinitusai.be.IVRConfidence
                 * @instance
                 */
                IVRConfidence.prototype.hasConfidence = false;
    
                /**
                 * IVRConfidence confidence.
                 * @member {number} confidence
                 * @memberof infinitusai.be.IVRConfidence
                 * @instance
                 */
                IVRConfidence.prototype.confidence = 0;
    
                /**
                 * IVRConfidence attribute.
                 * @member {infinitusai.be.IIVRActor|null|undefined} attribute
                 * @memberof infinitusai.be.IVRConfidence
                 * @instance
                 */
                IVRConfidence.prototype.attribute = null;
    
                /**
                 * Creates a new IVRConfidence instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {infinitusai.be.IIVRConfidence=} [properties] Properties to set
                 * @returns {infinitusai.be.IVRConfidence} IVRConfidence instance
                 */
                IVRConfidence.create = function create(properties) {
                    return new IVRConfidence(properties);
                };
    
                /**
                 * Encodes the specified IVRConfidence message. Does not implicitly {@link infinitusai.be.IVRConfidence.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {infinitusai.be.IIVRConfidence} message IVRConfidence message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IVRConfidence.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hasConfidence != null && Object.hasOwnProperty.call(message, "hasConfidence"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasConfidence);
                    if (message.confidence != null && Object.hasOwnProperty.call(message, "confidence"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.confidence);
                    if (message.attribute != null && Object.hasOwnProperty.call(message, "attribute"))
                        $root.infinitusai.be.IVRActor.encode(message.attribute, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified IVRConfidence message, length delimited. Does not implicitly {@link infinitusai.be.IVRConfidence.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {infinitusai.be.IIVRConfidence} message IVRConfidence message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IVRConfidence.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a IVRConfidence message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IVRConfidence} IVRConfidence
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IVRConfidence.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IVRConfidence();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hasConfidence = reader.bool();
                                break;
                            }
                        case 2: {
                                message.confidence = reader.float();
                                break;
                            }
                        case 3: {
                                message.attribute = $root.infinitusai.be.IVRActor.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a IVRConfidence message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IVRConfidence} IVRConfidence
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IVRConfidence.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a IVRConfidence message.
                 * @function verify
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IVRConfidence.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hasConfidence != null && message.hasOwnProperty("hasConfidence"))
                        if (typeof message.hasConfidence !== "boolean")
                            return "hasConfidence: boolean expected";
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        if (typeof message.confidence !== "number")
                            return "confidence: number expected";
                    if (message.attribute != null && message.hasOwnProperty("attribute")) {
                        var error = $root.infinitusai.be.IVRActor.verify(message.attribute);
                        if (error)
                            return "attribute." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a IVRConfidence message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IVRConfidence} IVRConfidence
                 */
                IVRConfidence.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IVRConfidence)
                        return object;
                    var message = new $root.infinitusai.be.IVRConfidence();
                    if (object.hasConfidence != null)
                        message.hasConfidence = Boolean(object.hasConfidence);
                    if (object.confidence != null)
                        message.confidence = Number(object.confidence);
                    if (object.attribute != null) {
                        if (typeof object.attribute !== "object")
                            throw TypeError(".infinitusai.be.IVRConfidence.attribute: object expected");
                        message.attribute = $root.infinitusai.be.IVRActor.fromObject(object.attribute);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a IVRConfidence message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {infinitusai.be.IVRConfidence} message IVRConfidence
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IVRConfidence.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hasConfidence = false;
                        object.confidence = 0;
                        object.attribute = null;
                    }
                    if (message.hasConfidence != null && message.hasOwnProperty("hasConfidence"))
                        object.hasConfidence = message.hasConfidence;
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        object.confidence = options.json && !isFinite(message.confidence) ? String(message.confidence) : message.confidence;
                    if (message.attribute != null && message.hasOwnProperty("attribute"))
                        object.attribute = $root.infinitusai.be.IVRActor.toObject(message.attribute, options);
                    return object;
                };
    
                /**
                 * Converts this IVRConfidence to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IVRConfidence
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IVRConfidence.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IVRConfidence
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IVRConfidence
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IVRConfidence.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IVRConfidence";
                };
    
                return IVRConfidence;
            })();
    
            be.IVRActor = (function() {
    
                /**
                 * Properties of a IVRActor.
                 * @memberof infinitusai.be
                 * @interface IIVRActor
                 * @property {string|null} [number] IVRActor number
                 * @property {infinitusai.be.TaskType|null} [taskType] IVRActor taskType
                 */
    
                /**
                 * Constructs a new IVRActor.
                 * @memberof infinitusai.be
                 * @classdesc Represents a IVRActor.
                 * @implements IIVRActor
                 * @constructor
                 * @param {infinitusai.be.IIVRActor=} [properties] Properties to set
                 */
                function IVRActor(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IVRActor number.
                 * @member {string} number
                 * @memberof infinitusai.be.IVRActor
                 * @instance
                 */
                IVRActor.prototype.number = "";
    
                /**
                 * IVRActor taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.IVRActor
                 * @instance
                 */
                IVRActor.prototype.taskType = 0;
    
                /**
                 * Creates a new IVRActor instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {infinitusai.be.IIVRActor=} [properties] Properties to set
                 * @returns {infinitusai.be.IVRActor} IVRActor instance
                 */
                IVRActor.create = function create(properties) {
                    return new IVRActor(properties);
                };
    
                /**
                 * Encodes the specified IVRActor message. Does not implicitly {@link infinitusai.be.IVRActor.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {infinitusai.be.IIVRActor} message IVRActor message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IVRActor.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.number);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified IVRActor message, length delimited. Does not implicitly {@link infinitusai.be.IVRActor.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {infinitusai.be.IIVRActor} message IVRActor message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IVRActor.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a IVRActor message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IVRActor} IVRActor
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IVRActor.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IVRActor();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.number = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a IVRActor message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IVRActor} IVRActor
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IVRActor.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a IVRActor message.
                 * @function verify
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IVRActor.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isString(message.number))
                            return "number: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a IVRActor message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IVRActor} IVRActor
                 */
                IVRActor.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IVRActor)
                        return object;
                    var message = new $root.infinitusai.be.IVRActor();
                    if (object.number != null)
                        message.number = String(object.number);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a IVRActor message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {infinitusai.be.IVRActor} message IVRActor
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IVRActor.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.number = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                    }
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    return object;
                };
    
                /**
                 * Converts this IVRActor to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IVRActor
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IVRActor.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IVRActor
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IVRActor
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IVRActor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IVRActor";
                };
    
                return IVRActor;
            })();
    
            be.GetIVRConfidenceRequest = (function() {
    
                /**
                 * Properties of a GetIVRConfidenceRequest.
                 * @memberof infinitusai.be
                 * @interface IGetIVRConfidenceRequest
                 * @property {Array.<infinitusai.be.IIVRActor>|null} [actors] GetIVRConfidenceRequest actors
                 */
    
                /**
                 * Constructs a new GetIVRConfidenceRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetIVRConfidenceRequest.
                 * @implements IGetIVRConfidenceRequest
                 * @constructor
                 * @param {infinitusai.be.IGetIVRConfidenceRequest=} [properties] Properties to set
                 */
                function GetIVRConfidenceRequest(properties) {
                    this.actors = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetIVRConfidenceRequest actors.
                 * @member {Array.<infinitusai.be.IIVRActor>} actors
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @instance
                 */
                GetIVRConfidenceRequest.prototype.actors = $util.emptyArray;
    
                /**
                 * Creates a new GetIVRConfidenceRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {infinitusai.be.IGetIVRConfidenceRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetIVRConfidenceRequest} GetIVRConfidenceRequest instance
                 */
                GetIVRConfidenceRequest.create = function create(properties) {
                    return new GetIVRConfidenceRequest(properties);
                };
    
                /**
                 * Encodes the specified GetIVRConfidenceRequest message. Does not implicitly {@link infinitusai.be.GetIVRConfidenceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {infinitusai.be.IGetIVRConfidenceRequest} message GetIVRConfidenceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIVRConfidenceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.actors != null && message.actors.length)
                        for (var i = 0; i < message.actors.length; ++i)
                            $root.infinitusai.be.IVRActor.encode(message.actors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetIVRConfidenceRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetIVRConfidenceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {infinitusai.be.IGetIVRConfidenceRequest} message GetIVRConfidenceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIVRConfidenceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetIVRConfidenceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetIVRConfidenceRequest} GetIVRConfidenceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIVRConfidenceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetIVRConfidenceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.actors && message.actors.length))
                                    message.actors = [];
                                message.actors.push($root.infinitusai.be.IVRActor.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetIVRConfidenceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetIVRConfidenceRequest} GetIVRConfidenceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIVRConfidenceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetIVRConfidenceRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIVRConfidenceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.actors != null && message.hasOwnProperty("actors")) {
                        if (!Array.isArray(message.actors))
                            return "actors: array expected";
                        for (var i = 0; i < message.actors.length; ++i) {
                            var error = $root.infinitusai.be.IVRActor.verify(message.actors[i]);
                            if (error)
                                return "actors." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetIVRConfidenceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetIVRConfidenceRequest} GetIVRConfidenceRequest
                 */
                GetIVRConfidenceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetIVRConfidenceRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetIVRConfidenceRequest();
                    if (object.actors) {
                        if (!Array.isArray(object.actors))
                            throw TypeError(".infinitusai.be.GetIVRConfidenceRequest.actors: array expected");
                        message.actors = [];
                        for (var i = 0; i < object.actors.length; ++i) {
                            if (typeof object.actors[i] !== "object")
                                throw TypeError(".infinitusai.be.GetIVRConfidenceRequest.actors: object expected");
                            message.actors[i] = $root.infinitusai.be.IVRActor.fromObject(object.actors[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetIVRConfidenceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {infinitusai.be.GetIVRConfidenceRequest} message GetIVRConfidenceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIVRConfidenceRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.actors = [];
                    if (message.actors && message.actors.length) {
                        object.actors = [];
                        for (var j = 0; j < message.actors.length; ++j)
                            object.actors[j] = $root.infinitusai.be.IVRActor.toObject(message.actors[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetIVRConfidenceRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIVRConfidenceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetIVRConfidenceRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetIVRConfidenceRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetIVRConfidenceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetIVRConfidenceRequest";
                };
    
                return GetIVRConfidenceRequest;
            })();
    
            be.IVRConfidenceResponse = (function() {
    
                /**
                 * Properties of a IVRConfidenceResponse.
                 * @memberof infinitusai.be
                 * @interface IIVRConfidenceResponse
                 * @property {Array.<infinitusai.be.IIVRConfidence>|null} [confidence] IVRConfidenceResponse confidence
                 */
    
                /**
                 * Constructs a new IVRConfidenceResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a IVRConfidenceResponse.
                 * @implements IIVRConfidenceResponse
                 * @constructor
                 * @param {infinitusai.be.IIVRConfidenceResponse=} [properties] Properties to set
                 */
                function IVRConfidenceResponse(properties) {
                    this.confidence = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IVRConfidenceResponse confidence.
                 * @member {Array.<infinitusai.be.IIVRConfidence>} confidence
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @instance
                 */
                IVRConfidenceResponse.prototype.confidence = $util.emptyArray;
    
                /**
                 * Creates a new IVRConfidenceResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {infinitusai.be.IIVRConfidenceResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.IVRConfidenceResponse} IVRConfidenceResponse instance
                 */
                IVRConfidenceResponse.create = function create(properties) {
                    return new IVRConfidenceResponse(properties);
                };
    
                /**
                 * Encodes the specified IVRConfidenceResponse message. Does not implicitly {@link infinitusai.be.IVRConfidenceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {infinitusai.be.IIVRConfidenceResponse} message IVRConfidenceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IVRConfidenceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.confidence != null && message.confidence.length)
                        for (var i = 0; i < message.confidence.length; ++i)
                            $root.infinitusai.be.IVRConfidence.encode(message.confidence[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified IVRConfidenceResponse message, length delimited. Does not implicitly {@link infinitusai.be.IVRConfidenceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {infinitusai.be.IIVRConfidenceResponse} message IVRConfidenceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IVRConfidenceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a IVRConfidenceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.IVRConfidenceResponse} IVRConfidenceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IVRConfidenceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.IVRConfidenceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.confidence && message.confidence.length))
                                    message.confidence = [];
                                message.confidence.push($root.infinitusai.be.IVRConfidence.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a IVRConfidenceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.IVRConfidenceResponse} IVRConfidenceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IVRConfidenceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a IVRConfidenceResponse message.
                 * @function verify
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IVRConfidenceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.confidence != null && message.hasOwnProperty("confidence")) {
                        if (!Array.isArray(message.confidence))
                            return "confidence: array expected";
                        for (var i = 0; i < message.confidence.length; ++i) {
                            var error = $root.infinitusai.be.IVRConfidence.verify(message.confidence[i]);
                            if (error)
                                return "confidence." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a IVRConfidenceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.IVRConfidenceResponse} IVRConfidenceResponse
                 */
                IVRConfidenceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.IVRConfidenceResponse)
                        return object;
                    var message = new $root.infinitusai.be.IVRConfidenceResponse();
                    if (object.confidence) {
                        if (!Array.isArray(object.confidence))
                            throw TypeError(".infinitusai.be.IVRConfidenceResponse.confidence: array expected");
                        message.confidence = [];
                        for (var i = 0; i < object.confidence.length; ++i) {
                            if (typeof object.confidence[i] !== "object")
                                throw TypeError(".infinitusai.be.IVRConfidenceResponse.confidence: object expected");
                            message.confidence[i] = $root.infinitusai.be.IVRConfidence.fromObject(object.confidence[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a IVRConfidenceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {infinitusai.be.IVRConfidenceResponse} message IVRConfidenceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IVRConfidenceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.confidence = [];
                    if (message.confidence && message.confidence.length) {
                        object.confidence = [];
                        for (var j = 0; j < message.confidence.length; ++j)
                            object.confidence[j] = $root.infinitusai.be.IVRConfidence.toObject(message.confidence[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this IVRConfidenceResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IVRConfidenceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IVRConfidenceResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.IVRConfidenceResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IVRConfidenceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.IVRConfidenceResponse";
                };
    
                return IVRConfidenceResponse;
            })();
    
            be.LogClientEventsRequest = (function() {
    
                /**
                 * Properties of a LogClientEventsRequest.
                 * @memberof infinitusai.be
                 * @interface ILogClientEventsRequest
                 * @property {Array.<infinitusai.be.IClientEvent>|null} [clientEvent] LogClientEventsRequest clientEvent
                 */
    
                /**
                 * Constructs a new LogClientEventsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LogClientEventsRequest.
                 * @implements ILogClientEventsRequest
                 * @constructor
                 * @param {infinitusai.be.ILogClientEventsRequest=} [properties] Properties to set
                 */
                function LogClientEventsRequest(properties) {
                    this.clientEvent = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LogClientEventsRequest clientEvent.
                 * @member {Array.<infinitusai.be.IClientEvent>} clientEvent
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @instance
                 */
                LogClientEventsRequest.prototype.clientEvent = $util.emptyArray;
    
                /**
                 * Creates a new LogClientEventsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {infinitusai.be.ILogClientEventsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.LogClientEventsRequest} LogClientEventsRequest instance
                 */
                LogClientEventsRequest.create = function create(properties) {
                    return new LogClientEventsRequest(properties);
                };
    
                /**
                 * Encodes the specified LogClientEventsRequest message. Does not implicitly {@link infinitusai.be.LogClientEventsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {infinitusai.be.ILogClientEventsRequest} message LogClientEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogClientEventsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientEvent != null && message.clientEvent.length)
                        for (var i = 0; i < message.clientEvent.length; ++i)
                            $root.infinitusai.be.ClientEvent.encode(message.clientEvent[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified LogClientEventsRequest message, length delimited. Does not implicitly {@link infinitusai.be.LogClientEventsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {infinitusai.be.ILogClientEventsRequest} message LogClientEventsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogClientEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LogClientEventsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LogClientEventsRequest} LogClientEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogClientEventsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LogClientEventsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.clientEvent && message.clientEvent.length))
                                    message.clientEvent = [];
                                message.clientEvent.push($root.infinitusai.be.ClientEvent.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LogClientEventsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LogClientEventsRequest} LogClientEventsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogClientEventsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LogClientEventsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogClientEventsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientEvent != null && message.hasOwnProperty("clientEvent")) {
                        if (!Array.isArray(message.clientEvent))
                            return "clientEvent: array expected";
                        for (var i = 0; i < message.clientEvent.length; ++i) {
                            var error = $root.infinitusai.be.ClientEvent.verify(message.clientEvent[i]);
                            if (error)
                                return "clientEvent." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a LogClientEventsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LogClientEventsRequest} LogClientEventsRequest
                 */
                LogClientEventsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LogClientEventsRequest)
                        return object;
                    var message = new $root.infinitusai.be.LogClientEventsRequest();
                    if (object.clientEvent) {
                        if (!Array.isArray(object.clientEvent))
                            throw TypeError(".infinitusai.be.LogClientEventsRequest.clientEvent: array expected");
                        message.clientEvent = [];
                        for (var i = 0; i < object.clientEvent.length; ++i) {
                            if (typeof object.clientEvent[i] !== "object")
                                throw TypeError(".infinitusai.be.LogClientEventsRequest.clientEvent: object expected");
                            message.clientEvent[i] = $root.infinitusai.be.ClientEvent.fromObject(object.clientEvent[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a LogClientEventsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {infinitusai.be.LogClientEventsRequest} message LogClientEventsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogClientEventsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.clientEvent = [];
                    if (message.clientEvent && message.clientEvent.length) {
                        object.clientEvent = [];
                        for (var j = 0; j < message.clientEvent.length; ++j)
                            object.clientEvent[j] = $root.infinitusai.be.ClientEvent.toObject(message.clientEvent[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this LogClientEventsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogClientEventsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LogClientEventsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LogClientEventsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LogClientEventsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LogClientEventsRequest";
                };
    
                return LogClientEventsRequest;
            })();
    
            be.ClientEvent = (function() {
    
                /**
                 * Properties of a ClientEvent.
                 * @memberof infinitusai.be
                 * @interface IClientEvent
                 * @property {string|null} [callUuid] ClientEvent callUuid
                 * @property {string|null} [taskUuid] ClientEvent taskUuid
                 * @property {string|null} [orgUuid] ClientEvent orgUuid
                 * @property {string|null} [eventUrl] ClientEvent eventUrl
                 * @property {string|null} [eventTimestamp] ClientEvent eventTimestamp
                 * @property {string|null} [logMessage] ClientEvent logMessage
                 * @property {infinitusai.be.ClientEvent.ClientEventType|null} [clientEventType] ClientEvent clientEventType
                 * @property {google.protobuf.IStruct|null} [metadata] ClientEvent metadata
                 */
    
                /**
                 * Constructs a new ClientEvent.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ClientEvent.
                 * @implements IClientEvent
                 * @constructor
                 * @param {infinitusai.be.IClientEvent=} [properties] Properties to set
                 */
                function ClientEvent(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ClientEvent callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 */
                ClientEvent.prototype.callUuid = "";
    
                /**
                 * ClientEvent taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 */
                ClientEvent.prototype.taskUuid = "";
    
                /**
                 * ClientEvent orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 */
                ClientEvent.prototype.orgUuid = "";
    
                /**
                 * ClientEvent eventUrl.
                 * @member {string} eventUrl
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 */
                ClientEvent.prototype.eventUrl = "";
    
                /**
                 * ClientEvent eventTimestamp.
                 * @member {string} eventTimestamp
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 */
                ClientEvent.prototype.eventTimestamp = "";
    
                /**
                 * ClientEvent logMessage.
                 * @member {string} logMessage
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 */
                ClientEvent.prototype.logMessage = "";
    
                /**
                 * ClientEvent clientEventType.
                 * @member {infinitusai.be.ClientEvent.ClientEventType} clientEventType
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 */
                ClientEvent.prototype.clientEventType = 0;
    
                /**
                 * ClientEvent metadata.
                 * @member {google.protobuf.IStruct|null|undefined} metadata
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 */
                ClientEvent.prototype.metadata = null;
    
                /**
                 * Creates a new ClientEvent instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {infinitusai.be.IClientEvent=} [properties] Properties to set
                 * @returns {infinitusai.be.ClientEvent} ClientEvent instance
                 */
                ClientEvent.create = function create(properties) {
                    return new ClientEvent(properties);
                };
    
                /**
                 * Encodes the specified ClientEvent message. Does not implicitly {@link infinitusai.be.ClientEvent.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {infinitusai.be.IClientEvent} message ClientEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    if (message.eventUrl != null && Object.hasOwnProperty.call(message, "eventUrl"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.eventUrl);
                    if (message.eventTimestamp != null && Object.hasOwnProperty.call(message, "eventTimestamp"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.eventTimestamp);
                    if (message.logMessage != null && Object.hasOwnProperty.call(message, "logMessage"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.logMessage);
                    if (message.clientEventType != null && Object.hasOwnProperty.call(message, "clientEventType"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.clientEventType);
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.google.protobuf.Struct.encode(message.metadata, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ClientEvent message, length delimited. Does not implicitly {@link infinitusai.be.ClientEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {infinitusai.be.IClientEvent} message ClientEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ClientEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ClientEvent} ClientEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ClientEvent();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.eventUrl = reader.string();
                                break;
                            }
                        case 5: {
                                message.eventTimestamp = reader.string();
                                break;
                            }
                        case 6: {
                                message.logMessage = reader.string();
                                break;
                            }
                        case 7: {
                                message.clientEventType = reader.int32();
                                break;
                            }
                        case 8: {
                                message.metadata = $root.google.protobuf.Struct.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ClientEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ClientEvent} ClientEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ClientEvent message.
                 * @function verify
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClientEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.eventUrl != null && message.hasOwnProperty("eventUrl"))
                        if (!$util.isString(message.eventUrl))
                            return "eventUrl: string expected";
                    if (message.eventTimestamp != null && message.hasOwnProperty("eventTimestamp"))
                        if (!$util.isString(message.eventTimestamp))
                            return "eventTimestamp: string expected";
                    if (message.logMessage != null && message.hasOwnProperty("logMessage"))
                        if (!$util.isString(message.logMessage))
                            return "logMessage: string expected";
                    if (message.clientEventType != null && message.hasOwnProperty("clientEventType"))
                        switch (message.clientEventType) {
                        default:
                            return "clientEventType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        var error = $root.google.protobuf.Struct.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ClientEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ClientEvent} ClientEvent
                 */
                ClientEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ClientEvent)
                        return object;
                    var message = new $root.infinitusai.be.ClientEvent();
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.eventUrl != null)
                        message.eventUrl = String(object.eventUrl);
                    if (object.eventTimestamp != null)
                        message.eventTimestamp = String(object.eventTimestamp);
                    if (object.logMessage != null)
                        message.logMessage = String(object.logMessage);
                    switch (object.clientEventType) {
                    default:
                        if (typeof object.clientEventType === "number") {
                            message.clientEventType = object.clientEventType;
                            break;
                        }
                        break;
                    case "API_REQUEST":
                    case 0:
                        message.clientEventType = 0;
                        break;
                    case "AUDIO_CONTROLLER":
                    case 1:
                        message.clientEventType = 1;
                        break;
                    case "AUTO_JOIN_CALL":
                    case 2:
                        message.clientEventType = 2;
                        break;
                    case "BREADCRUMB":
                    case 3:
                        message.clientEventType = 3;
                        break;
                    case "CALL_ACTIONS":
                    case 4:
                        message.clientEventType = 4;
                        break;
                    case "CLICK_SUGGESTION":
                    case 5:
                        message.clientEventType = 5;
                        break;
                    case "ERROR":
                    case 6:
                        message.clientEventType = 6;
                        break;
                    case "ENTER_RESPONSE":
                    case 7:
                        message.clientEventType = 7;
                        break;
                    case "FIELD_EDIT":
                    case 8:
                        message.clientEventType = 8;
                        break;
                    case "GCS_FILE_UPLOAD":
                    case 9:
                        message.clientEventType = 9;
                        break;
                    case "GQL_ERROR":
                    case 10:
                        message.clientEventType = 10;
                        break;
                    case "INACTIVITY_DETECTOR":
                    case 11:
                        message.clientEventType = 11;
                        break;
                    case "INFO":
                    case 12:
                        message.clientEventType = 12;
                        break;
                    case "NAVIGATION":
                    case 13:
                        message.clientEventType = 13;
                        break;
                    case "NEXMO":
                    case 14:
                        message.clientEventType = 14;
                        break;
                    case "NOTIFICATION":
                    case 15:
                        message.clientEventType = 15;
                        break;
                    case "OPERATOR_STATUS":
                    case 16:
                        message.clientEventType = 16;
                        break;
                    case "ORG_MENU":
                    case 17:
                        message.clientEventType = 17;
                        break;
                    case "PROCESS_CALL":
                    case 18:
                        message.clientEventType = 18;
                        break;
                    case "SUGGESTIONS_VIEW_MODE":
                    case 19:
                        message.clientEventType = 19;
                        break;
                    case "UI_EVENT":
                    case 20:
                        message.clientEventType = 20;
                        break;
                    case "UPDATE_FORM":
                    case 21:
                        message.clientEventType = 21;
                        break;
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".infinitusai.be.ClientEvent.metadata: object expected");
                        message.metadata = $root.google.protobuf.Struct.fromObject(object.metadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ClientEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {infinitusai.be.ClientEvent} message ClientEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClientEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callUuid = "";
                        object.taskUuid = "";
                        object.orgUuid = "";
                        object.eventUrl = "";
                        object.eventTimestamp = "";
                        object.logMessage = "";
                        object.clientEventType = options.enums === String ? "API_REQUEST" : 0;
                        object.metadata = null;
                    }
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.eventUrl != null && message.hasOwnProperty("eventUrl"))
                        object.eventUrl = message.eventUrl;
                    if (message.eventTimestamp != null && message.hasOwnProperty("eventTimestamp"))
                        object.eventTimestamp = message.eventTimestamp;
                    if (message.logMessage != null && message.hasOwnProperty("logMessage"))
                        object.logMessage = message.logMessage;
                    if (message.clientEventType != null && message.hasOwnProperty("clientEventType"))
                        object.clientEventType = options.enums === String ? $root.infinitusai.be.ClientEvent.ClientEventType[message.clientEventType] === undefined ? message.clientEventType : $root.infinitusai.be.ClientEvent.ClientEventType[message.clientEventType] : message.clientEventType;
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.google.protobuf.Struct.toObject(message.metadata, options);
                    return object;
                };
    
                /**
                 * Converts this ClientEvent to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ClientEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClientEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ClientEvent
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ClientEvent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClientEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ClientEvent";
                };
    
                /**
                 * ClientEventType enum.
                 * @name infinitusai.be.ClientEvent.ClientEventType
                 * @enum {number}
                 * @property {number} API_REQUEST=0 API_REQUEST value
                 * @property {number} AUDIO_CONTROLLER=1 AUDIO_CONTROLLER value
                 * @property {number} AUTO_JOIN_CALL=2 AUTO_JOIN_CALL value
                 * @property {number} BREADCRUMB=3 BREADCRUMB value
                 * @property {number} CALL_ACTIONS=4 CALL_ACTIONS value
                 * @property {number} CLICK_SUGGESTION=5 CLICK_SUGGESTION value
                 * @property {number} ERROR=6 ERROR value
                 * @property {number} ENTER_RESPONSE=7 ENTER_RESPONSE value
                 * @property {number} FIELD_EDIT=8 FIELD_EDIT value
                 * @property {number} GCS_FILE_UPLOAD=9 GCS_FILE_UPLOAD value
                 * @property {number} GQL_ERROR=10 GQL_ERROR value
                 * @property {number} INACTIVITY_DETECTOR=11 INACTIVITY_DETECTOR value
                 * @property {number} INFO=12 INFO value
                 * @property {number} NAVIGATION=13 NAVIGATION value
                 * @property {number} NEXMO=14 NEXMO value
                 * @property {number} NOTIFICATION=15 NOTIFICATION value
                 * @property {number} OPERATOR_STATUS=16 OPERATOR_STATUS value
                 * @property {number} ORG_MENU=17 ORG_MENU value
                 * @property {number} PROCESS_CALL=18 PROCESS_CALL value
                 * @property {number} SUGGESTIONS_VIEW_MODE=19 SUGGESTIONS_VIEW_MODE value
                 * @property {number} UI_EVENT=20 UI_EVENT value
                 * @property {number} UPDATE_FORM=21 UPDATE_FORM value
                 */
                ClientEvent.ClientEventType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "API_REQUEST"] = 0;
                    values[valuesById[1] = "AUDIO_CONTROLLER"] = 1;
                    values[valuesById[2] = "AUTO_JOIN_CALL"] = 2;
                    values[valuesById[3] = "BREADCRUMB"] = 3;
                    values[valuesById[4] = "CALL_ACTIONS"] = 4;
                    values[valuesById[5] = "CLICK_SUGGESTION"] = 5;
                    values[valuesById[6] = "ERROR"] = 6;
                    values[valuesById[7] = "ENTER_RESPONSE"] = 7;
                    values[valuesById[8] = "FIELD_EDIT"] = 8;
                    values[valuesById[9] = "GCS_FILE_UPLOAD"] = 9;
                    values[valuesById[10] = "GQL_ERROR"] = 10;
                    values[valuesById[11] = "INACTIVITY_DETECTOR"] = 11;
                    values[valuesById[12] = "INFO"] = 12;
                    values[valuesById[13] = "NAVIGATION"] = 13;
                    values[valuesById[14] = "NEXMO"] = 14;
                    values[valuesById[15] = "NOTIFICATION"] = 15;
                    values[valuesById[16] = "OPERATOR_STATUS"] = 16;
                    values[valuesById[17] = "ORG_MENU"] = 17;
                    values[valuesById[18] = "PROCESS_CALL"] = 18;
                    values[valuesById[19] = "SUGGESTIONS_VIEW_MODE"] = 19;
                    values[valuesById[20] = "UI_EVENT"] = 20;
                    values[valuesById[21] = "UPDATE_FORM"] = 21;
                    return values;
                })();
    
                return ClientEvent;
            })();
    
            be.LogClientEventsResponse = (function() {
    
                /**
                 * Properties of a LogClientEventsResponse.
                 * @memberof infinitusai.be
                 * @interface ILogClientEventsResponse
                 */
    
                /**
                 * Constructs a new LogClientEventsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a LogClientEventsResponse.
                 * @implements ILogClientEventsResponse
                 * @constructor
                 * @param {infinitusai.be.ILogClientEventsResponse=} [properties] Properties to set
                 */
                function LogClientEventsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new LogClientEventsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {infinitusai.be.ILogClientEventsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.LogClientEventsResponse} LogClientEventsResponse instance
                 */
                LogClientEventsResponse.create = function create(properties) {
                    return new LogClientEventsResponse(properties);
                };
    
                /**
                 * Encodes the specified LogClientEventsResponse message. Does not implicitly {@link infinitusai.be.LogClientEventsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {infinitusai.be.ILogClientEventsResponse} message LogClientEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogClientEventsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified LogClientEventsResponse message, length delimited. Does not implicitly {@link infinitusai.be.LogClientEventsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {infinitusai.be.ILogClientEventsResponse} message LogClientEventsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogClientEventsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LogClientEventsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.LogClientEventsResponse} LogClientEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogClientEventsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.LogClientEventsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LogClientEventsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.LogClientEventsResponse} LogClientEventsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogClientEventsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LogClientEventsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogClientEventsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a LogClientEventsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.LogClientEventsResponse} LogClientEventsResponse
                 */
                LogClientEventsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.LogClientEventsResponse)
                        return object;
                    return new $root.infinitusai.be.LogClientEventsResponse();
                };
    
                /**
                 * Creates a plain object from a LogClientEventsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {infinitusai.be.LogClientEventsResponse} message LogClientEventsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogClientEventsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this LogClientEventsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogClientEventsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LogClientEventsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.LogClientEventsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LogClientEventsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.LogClientEventsResponse";
                };
    
                return LogClientEventsResponse;
            })();
    
            be.CountNextTasksForCallRequest = (function() {
    
                /**
                 * Properties of a CountNextTasksForCallRequest.
                 * @memberof infinitusai.be
                 * @interface ICountNextTasksForCallRequest
                 * @property {string|null} [orgUuid] CountNextTasksForCallRequest orgUuid
                 * @property {string|null} [taskUuid] CountNextTasksForCallRequest taskUuid
                 * @property {string|null} [callUuid] CountNextTasksForCallRequest callUuid
                 */
    
                /**
                 * Constructs a new CountNextTasksForCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CountNextTasksForCallRequest.
                 * @implements ICountNextTasksForCallRequest
                 * @constructor
                 * @param {infinitusai.be.ICountNextTasksForCallRequest=} [properties] Properties to set
                 */
                function CountNextTasksForCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CountNextTasksForCallRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @instance
                 */
                CountNextTasksForCallRequest.prototype.orgUuid = "";
    
                /**
                 * CountNextTasksForCallRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @instance
                 */
                CountNextTasksForCallRequest.prototype.taskUuid = "";
    
                /**
                 * CountNextTasksForCallRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @instance
                 */
                CountNextTasksForCallRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new CountNextTasksForCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {infinitusai.be.ICountNextTasksForCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CountNextTasksForCallRequest} CountNextTasksForCallRequest instance
                 */
                CountNextTasksForCallRequest.create = function create(properties) {
                    return new CountNextTasksForCallRequest(properties);
                };
    
                /**
                 * Encodes the specified CountNextTasksForCallRequest message. Does not implicitly {@link infinitusai.be.CountNextTasksForCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {infinitusai.be.ICountNextTasksForCallRequest} message CountNextTasksForCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CountNextTasksForCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CountNextTasksForCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.CountNextTasksForCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {infinitusai.be.ICountNextTasksForCallRequest} message CountNextTasksForCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CountNextTasksForCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CountNextTasksForCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CountNextTasksForCallRequest} CountNextTasksForCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CountNextTasksForCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CountNextTasksForCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CountNextTasksForCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CountNextTasksForCallRequest} CountNextTasksForCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CountNextTasksForCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CountNextTasksForCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CountNextTasksForCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CountNextTasksForCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CountNextTasksForCallRequest} CountNextTasksForCallRequest
                 */
                CountNextTasksForCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CountNextTasksForCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.CountNextTasksForCallRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CountNextTasksForCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {infinitusai.be.CountNextTasksForCallRequest} message CountNextTasksForCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CountNextTasksForCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this CountNextTasksForCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CountNextTasksForCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CountNextTasksForCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CountNextTasksForCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CountNextTasksForCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CountNextTasksForCallRequest";
                };
    
                return CountNextTasksForCallRequest;
            })();
    
            be.CountNextTasksForCallResponse = (function() {
    
                /**
                 * Properties of a CountNextTasksForCallResponse.
                 * @memberof infinitusai.be
                 * @interface ICountNextTasksForCallResponse
                 * @property {number|null} [taskCount] CountNextTasksForCallResponse taskCount
                 * @property {number|null} [multitaskPerCallLimit] CountNextTasksForCallResponse multitaskPerCallLimit
                 */
    
                /**
                 * Constructs a new CountNextTasksForCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CountNextTasksForCallResponse.
                 * @implements ICountNextTasksForCallResponse
                 * @constructor
                 * @param {infinitusai.be.ICountNextTasksForCallResponse=} [properties] Properties to set
                 */
                function CountNextTasksForCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CountNextTasksForCallResponse taskCount.
                 * @member {number} taskCount
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @instance
                 */
                CountNextTasksForCallResponse.prototype.taskCount = 0;
    
                /**
                 * CountNextTasksForCallResponse multitaskPerCallLimit.
                 * @member {number} multitaskPerCallLimit
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @instance
                 */
                CountNextTasksForCallResponse.prototype.multitaskPerCallLimit = 0;
    
                /**
                 * Creates a new CountNextTasksForCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {infinitusai.be.ICountNextTasksForCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CountNextTasksForCallResponse} CountNextTasksForCallResponse instance
                 */
                CountNextTasksForCallResponse.create = function create(properties) {
                    return new CountNextTasksForCallResponse(properties);
                };
    
                /**
                 * Encodes the specified CountNextTasksForCallResponse message. Does not implicitly {@link infinitusai.be.CountNextTasksForCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {infinitusai.be.ICountNextTasksForCallResponse} message CountNextTasksForCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CountNextTasksForCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskCount != null && Object.hasOwnProperty.call(message, "taskCount"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.taskCount);
                    if (message.multitaskPerCallLimit != null && Object.hasOwnProperty.call(message, "multitaskPerCallLimit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.multitaskPerCallLimit);
                    return writer;
                };
    
                /**
                 * Encodes the specified CountNextTasksForCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.CountNextTasksForCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {infinitusai.be.ICountNextTasksForCallResponse} message CountNextTasksForCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CountNextTasksForCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CountNextTasksForCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CountNextTasksForCallResponse} CountNextTasksForCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CountNextTasksForCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CountNextTasksForCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskCount = reader.int32();
                                break;
                            }
                        case 2: {
                                message.multitaskPerCallLimit = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CountNextTasksForCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CountNextTasksForCallResponse} CountNextTasksForCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CountNextTasksForCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CountNextTasksForCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CountNextTasksForCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskCount != null && message.hasOwnProperty("taskCount"))
                        if (!$util.isInteger(message.taskCount))
                            return "taskCount: integer expected";
                    if (message.multitaskPerCallLimit != null && message.hasOwnProperty("multitaskPerCallLimit"))
                        if (!$util.isInteger(message.multitaskPerCallLimit))
                            return "multitaskPerCallLimit: integer expected";
                    return null;
                };
    
                /**
                 * Creates a CountNextTasksForCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CountNextTasksForCallResponse} CountNextTasksForCallResponse
                 */
                CountNextTasksForCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CountNextTasksForCallResponse)
                        return object;
                    var message = new $root.infinitusai.be.CountNextTasksForCallResponse();
                    if (object.taskCount != null)
                        message.taskCount = object.taskCount | 0;
                    if (object.multitaskPerCallLimit != null)
                        message.multitaskPerCallLimit = object.multitaskPerCallLimit | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a CountNextTasksForCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {infinitusai.be.CountNextTasksForCallResponse} message CountNextTasksForCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CountNextTasksForCallResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskCount = 0;
                        object.multitaskPerCallLimit = 0;
                    }
                    if (message.taskCount != null && message.hasOwnProperty("taskCount"))
                        object.taskCount = message.taskCount;
                    if (message.multitaskPerCallLimit != null && message.hasOwnProperty("multitaskPerCallLimit"))
                        object.multitaskPerCallLimit = message.multitaskPerCallLimit;
                    return object;
                };
    
                /**
                 * Converts this CountNextTasksForCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CountNextTasksForCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CountNextTasksForCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CountNextTasksForCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CountNextTasksForCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CountNextTasksForCallResponse";
                };
    
                return CountNextTasksForCallResponse;
            })();
    
            be.AddTaskToCallRequest = (function() {
    
                /**
                 * Properties of an AddTaskToCallRequest.
                 * @memberof infinitusai.be
                 * @interface IAddTaskToCallRequest
                 * @property {string|null} [orgUuid] AddTaskToCallRequest orgUuid
                 * @property {string|null} [taskUuid] AddTaskToCallRequest taskUuid
                 * @property {string|null} [callUuid] AddTaskToCallRequest callUuid
                 */
    
                /**
                 * Constructs a new AddTaskToCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AddTaskToCallRequest.
                 * @implements IAddTaskToCallRequest
                 * @constructor
                 * @param {infinitusai.be.IAddTaskToCallRequest=} [properties] Properties to set
                 */
                function AddTaskToCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AddTaskToCallRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @instance
                 */
                AddTaskToCallRequest.prototype.orgUuid = "";
    
                /**
                 * AddTaskToCallRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @instance
                 */
                AddTaskToCallRequest.prototype.taskUuid = "";
    
                /**
                 * AddTaskToCallRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @instance
                 */
                AddTaskToCallRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new AddTaskToCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {infinitusai.be.IAddTaskToCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.AddTaskToCallRequest} AddTaskToCallRequest instance
                 */
                AddTaskToCallRequest.create = function create(properties) {
                    return new AddTaskToCallRequest(properties);
                };
    
                /**
                 * Encodes the specified AddTaskToCallRequest message. Does not implicitly {@link infinitusai.be.AddTaskToCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {infinitusai.be.IAddTaskToCallRequest} message AddTaskToCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddTaskToCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified AddTaskToCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.AddTaskToCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {infinitusai.be.IAddTaskToCallRequest} message AddTaskToCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddTaskToCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddTaskToCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AddTaskToCallRequest} AddTaskToCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddTaskToCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AddTaskToCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddTaskToCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AddTaskToCallRequest} AddTaskToCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddTaskToCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddTaskToCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddTaskToCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an AddTaskToCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AddTaskToCallRequest} AddTaskToCallRequest
                 */
                AddTaskToCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AddTaskToCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.AddTaskToCallRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AddTaskToCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {infinitusai.be.AddTaskToCallRequest} message AddTaskToCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddTaskToCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this AddTaskToCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddTaskToCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddTaskToCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AddTaskToCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddTaskToCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AddTaskToCallRequest";
                };
    
                return AddTaskToCallRequest;
            })();
    
            be.AddTaskToCallResponse = (function() {
    
                /**
                 * Properties of an AddTaskToCallResponse.
                 * @memberof infinitusai.be
                 * @interface IAddTaskToCallResponse
                 * @property {string|null} [taskUuid] AddTaskToCallResponse taskUuid
                 */
    
                /**
                 * Constructs a new AddTaskToCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AddTaskToCallResponse.
                 * @implements IAddTaskToCallResponse
                 * @constructor
                 * @param {infinitusai.be.IAddTaskToCallResponse=} [properties] Properties to set
                 */
                function AddTaskToCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AddTaskToCallResponse taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @instance
                 */
                AddTaskToCallResponse.prototype.taskUuid = "";
    
                /**
                 * Creates a new AddTaskToCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {infinitusai.be.IAddTaskToCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.AddTaskToCallResponse} AddTaskToCallResponse instance
                 */
                AddTaskToCallResponse.create = function create(properties) {
                    return new AddTaskToCallResponse(properties);
                };
    
                /**
                 * Encodes the specified AddTaskToCallResponse message. Does not implicitly {@link infinitusai.be.AddTaskToCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {infinitusai.be.IAddTaskToCallResponse} message AddTaskToCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddTaskToCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified AddTaskToCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.AddTaskToCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {infinitusai.be.IAddTaskToCallResponse} message AddTaskToCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddTaskToCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddTaskToCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AddTaskToCallResponse} AddTaskToCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddTaskToCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AddTaskToCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddTaskToCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AddTaskToCallResponse} AddTaskToCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddTaskToCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddTaskToCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddTaskToCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an AddTaskToCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AddTaskToCallResponse} AddTaskToCallResponse
                 */
                AddTaskToCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AddTaskToCallResponse)
                        return object;
                    var message = new $root.infinitusai.be.AddTaskToCallResponse();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AddTaskToCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {infinitusai.be.AddTaskToCallResponse} message AddTaskToCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddTaskToCallResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this AddTaskToCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddTaskToCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddTaskToCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AddTaskToCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddTaskToCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AddTaskToCallResponse";
                };
    
                return AddTaskToCallResponse;
            })();
    
            be.SwitchTasksInCallRequest = (function() {
    
                /**
                 * Properties of a SwitchTasksInCallRequest.
                 * @memberof infinitusai.be
                 * @interface ISwitchTasksInCallRequest
                 * @property {string|null} [orgUuid] SwitchTasksInCallRequest orgUuid
                 * @property {string|null} [taskUuid] SwitchTasksInCallRequest taskUuid
                 * @property {string|null} [callUuid] SwitchTasksInCallRequest callUuid
                 */
    
                /**
                 * Constructs a new SwitchTasksInCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SwitchTasksInCallRequest.
                 * @implements ISwitchTasksInCallRequest
                 * @constructor
                 * @param {infinitusai.be.ISwitchTasksInCallRequest=} [properties] Properties to set
                 */
                function SwitchTasksInCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SwitchTasksInCallRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @instance
                 */
                SwitchTasksInCallRequest.prototype.orgUuid = "";
    
                /**
                 * SwitchTasksInCallRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @instance
                 */
                SwitchTasksInCallRequest.prototype.taskUuid = "";
    
                /**
                 * SwitchTasksInCallRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @instance
                 */
                SwitchTasksInCallRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new SwitchTasksInCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {infinitusai.be.ISwitchTasksInCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SwitchTasksInCallRequest} SwitchTasksInCallRequest instance
                 */
                SwitchTasksInCallRequest.create = function create(properties) {
                    return new SwitchTasksInCallRequest(properties);
                };
    
                /**
                 * Encodes the specified SwitchTasksInCallRequest message. Does not implicitly {@link infinitusai.be.SwitchTasksInCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {infinitusai.be.ISwitchTasksInCallRequest} message SwitchTasksInCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SwitchTasksInCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified SwitchTasksInCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.SwitchTasksInCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {infinitusai.be.ISwitchTasksInCallRequest} message SwitchTasksInCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SwitchTasksInCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SwitchTasksInCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SwitchTasksInCallRequest} SwitchTasksInCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SwitchTasksInCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SwitchTasksInCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SwitchTasksInCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SwitchTasksInCallRequest} SwitchTasksInCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SwitchTasksInCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SwitchTasksInCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SwitchTasksInCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a SwitchTasksInCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SwitchTasksInCallRequest} SwitchTasksInCallRequest
                 */
                SwitchTasksInCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SwitchTasksInCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.SwitchTasksInCallRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SwitchTasksInCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {infinitusai.be.SwitchTasksInCallRequest} message SwitchTasksInCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SwitchTasksInCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this SwitchTasksInCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SwitchTasksInCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SwitchTasksInCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SwitchTasksInCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SwitchTasksInCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SwitchTasksInCallRequest";
                };
    
                return SwitchTasksInCallRequest;
            })();
    
            be.SwitchTasksInCallResponse = (function() {
    
                /**
                 * Properties of a SwitchTasksInCallResponse.
                 * @memberof infinitusai.be
                 * @interface ISwitchTasksInCallResponse
                 */
    
                /**
                 * Constructs a new SwitchTasksInCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SwitchTasksInCallResponse.
                 * @implements ISwitchTasksInCallResponse
                 * @constructor
                 * @param {infinitusai.be.ISwitchTasksInCallResponse=} [properties] Properties to set
                 */
                function SwitchTasksInCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new SwitchTasksInCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {infinitusai.be.ISwitchTasksInCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.SwitchTasksInCallResponse} SwitchTasksInCallResponse instance
                 */
                SwitchTasksInCallResponse.create = function create(properties) {
                    return new SwitchTasksInCallResponse(properties);
                };
    
                /**
                 * Encodes the specified SwitchTasksInCallResponse message. Does not implicitly {@link infinitusai.be.SwitchTasksInCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {infinitusai.be.ISwitchTasksInCallResponse} message SwitchTasksInCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SwitchTasksInCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified SwitchTasksInCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.SwitchTasksInCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {infinitusai.be.ISwitchTasksInCallResponse} message SwitchTasksInCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SwitchTasksInCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SwitchTasksInCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SwitchTasksInCallResponse} SwitchTasksInCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SwitchTasksInCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SwitchTasksInCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SwitchTasksInCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SwitchTasksInCallResponse} SwitchTasksInCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SwitchTasksInCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SwitchTasksInCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SwitchTasksInCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a SwitchTasksInCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SwitchTasksInCallResponse} SwitchTasksInCallResponse
                 */
                SwitchTasksInCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SwitchTasksInCallResponse)
                        return object;
                    return new $root.infinitusai.be.SwitchTasksInCallResponse();
                };
    
                /**
                 * Creates a plain object from a SwitchTasksInCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {infinitusai.be.SwitchTasksInCallResponse} message SwitchTasksInCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SwitchTasksInCallResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this SwitchTasksInCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SwitchTasksInCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SwitchTasksInCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SwitchTasksInCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SwitchTasksInCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SwitchTasksInCallResponse";
                };
    
                return SwitchTasksInCallResponse;
            })();
    
            be.EndTaskInCallRequest = (function() {
    
                /**
                 * Properties of an EndTaskInCallRequest.
                 * @memberof infinitusai.be
                 * @interface IEndTaskInCallRequest
                 * @property {string|null} [orgUuid] EndTaskInCallRequest orgUuid
                 * @property {string|null} [taskUuid] EndTaskInCallRequest taskUuid
                 * @property {string|null} [callUuid] EndTaskInCallRequest callUuid
                 */
    
                /**
                 * Constructs a new EndTaskInCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EndTaskInCallRequest.
                 * @implements IEndTaskInCallRequest
                 * @constructor
                 * @param {infinitusai.be.IEndTaskInCallRequest=} [properties] Properties to set
                 */
                function EndTaskInCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EndTaskInCallRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @instance
                 */
                EndTaskInCallRequest.prototype.orgUuid = "";
    
                /**
                 * EndTaskInCallRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @instance
                 */
                EndTaskInCallRequest.prototype.taskUuid = "";
    
                /**
                 * EndTaskInCallRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @instance
                 */
                EndTaskInCallRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new EndTaskInCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {infinitusai.be.IEndTaskInCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.EndTaskInCallRequest} EndTaskInCallRequest instance
                 */
                EndTaskInCallRequest.create = function create(properties) {
                    return new EndTaskInCallRequest(properties);
                };
    
                /**
                 * Encodes the specified EndTaskInCallRequest message. Does not implicitly {@link infinitusai.be.EndTaskInCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {infinitusai.be.IEndTaskInCallRequest} message EndTaskInCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EndTaskInCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified EndTaskInCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.EndTaskInCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {infinitusai.be.IEndTaskInCallRequest} message EndTaskInCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EndTaskInCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EndTaskInCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EndTaskInCallRequest} EndTaskInCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EndTaskInCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EndTaskInCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EndTaskInCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EndTaskInCallRequest} EndTaskInCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EndTaskInCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EndTaskInCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EndTaskInCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an EndTaskInCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EndTaskInCallRequest} EndTaskInCallRequest
                 */
                EndTaskInCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EndTaskInCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.EndTaskInCallRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EndTaskInCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {infinitusai.be.EndTaskInCallRequest} message EndTaskInCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EndTaskInCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this EndTaskInCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EndTaskInCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EndTaskInCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EndTaskInCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EndTaskInCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EndTaskInCallRequest";
                };
    
                return EndTaskInCallRequest;
            })();
    
            be.EndTaskInCallResponse = (function() {
    
                /**
                 * Properties of an EndTaskInCallResponse.
                 * @memberof infinitusai.be
                 * @interface IEndTaskInCallResponse
                 */
    
                /**
                 * Constructs a new EndTaskInCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EndTaskInCallResponse.
                 * @implements IEndTaskInCallResponse
                 * @constructor
                 * @param {infinitusai.be.IEndTaskInCallResponse=} [properties] Properties to set
                 */
                function EndTaskInCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new EndTaskInCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {infinitusai.be.IEndTaskInCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.EndTaskInCallResponse} EndTaskInCallResponse instance
                 */
                EndTaskInCallResponse.create = function create(properties) {
                    return new EndTaskInCallResponse(properties);
                };
    
                /**
                 * Encodes the specified EndTaskInCallResponse message. Does not implicitly {@link infinitusai.be.EndTaskInCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {infinitusai.be.IEndTaskInCallResponse} message EndTaskInCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EndTaskInCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified EndTaskInCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.EndTaskInCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {infinitusai.be.IEndTaskInCallResponse} message EndTaskInCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EndTaskInCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EndTaskInCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EndTaskInCallResponse} EndTaskInCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EndTaskInCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EndTaskInCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EndTaskInCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EndTaskInCallResponse} EndTaskInCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EndTaskInCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EndTaskInCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EndTaskInCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an EndTaskInCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EndTaskInCallResponse} EndTaskInCallResponse
                 */
                EndTaskInCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EndTaskInCallResponse)
                        return object;
                    return new $root.infinitusai.be.EndTaskInCallResponse();
                };
    
                /**
                 * Creates a plain object from an EndTaskInCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {infinitusai.be.EndTaskInCallResponse} message EndTaskInCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EndTaskInCallResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this EndTaskInCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EndTaskInCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EndTaskInCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EndTaskInCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EndTaskInCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EndTaskInCallResponse";
                };
    
                return EndTaskInCallResponse;
            })();
    
            be.CreateNexmoUserRequest = (function() {
    
                /**
                 * Properties of a CreateNexmoUserRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateNexmoUserRequest
                 * @property {string|null} [infinitusEmail] CreateNexmoUserRequest infinitusEmail
                 * @property {string|null} [nexmoUserName] CreateNexmoUserRequest nexmoUserName
                 * @property {string|null} [nexmoUserDisplayName] CreateNexmoUserRequest nexmoUserDisplayName
                 * @property {boolean|null} [canReview] CreateNexmoUserRequest canReview
                 * @property {boolean|null} [isAdmin] CreateNexmoUserRequest isAdmin
                 */
    
                /**
                 * Constructs a new CreateNexmoUserRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateNexmoUserRequest.
                 * @implements ICreateNexmoUserRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateNexmoUserRequest=} [properties] Properties to set
                 */
                function CreateNexmoUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateNexmoUserRequest infinitusEmail.
                 * @member {string} infinitusEmail
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @instance
                 */
                CreateNexmoUserRequest.prototype.infinitusEmail = "";
    
                /**
                 * CreateNexmoUserRequest nexmoUserName.
                 * @member {string} nexmoUserName
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @instance
                 */
                CreateNexmoUserRequest.prototype.nexmoUserName = "";
    
                /**
                 * CreateNexmoUserRequest nexmoUserDisplayName.
                 * @member {string} nexmoUserDisplayName
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @instance
                 */
                CreateNexmoUserRequest.prototype.nexmoUserDisplayName = "";
    
                /**
                 * CreateNexmoUserRequest canReview.
                 * @member {boolean} canReview
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @instance
                 */
                CreateNexmoUserRequest.prototype.canReview = false;
    
                /**
                 * CreateNexmoUserRequest isAdmin.
                 * @member {boolean} isAdmin
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @instance
                 */
                CreateNexmoUserRequest.prototype.isAdmin = false;
    
                /**
                 * Creates a new CreateNexmoUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {infinitusai.be.ICreateNexmoUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateNexmoUserRequest} CreateNexmoUserRequest instance
                 */
                CreateNexmoUserRequest.create = function create(properties) {
                    return new CreateNexmoUserRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateNexmoUserRequest message. Does not implicitly {@link infinitusai.be.CreateNexmoUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {infinitusai.be.ICreateNexmoUserRequest} message CreateNexmoUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateNexmoUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.infinitusEmail != null && Object.hasOwnProperty.call(message, "infinitusEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.infinitusEmail);
                    if (message.nexmoUserName != null && Object.hasOwnProperty.call(message, "nexmoUserName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.nexmoUserName);
                    if (message.nexmoUserDisplayName != null && Object.hasOwnProperty.call(message, "nexmoUserDisplayName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.nexmoUserDisplayName);
                    if (message.canReview != null && Object.hasOwnProperty.call(message, "canReview"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.canReview);
                    if (message.isAdmin != null && Object.hasOwnProperty.call(message, "isAdmin"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isAdmin);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateNexmoUserRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateNexmoUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {infinitusai.be.ICreateNexmoUserRequest} message CreateNexmoUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateNexmoUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateNexmoUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateNexmoUserRequest} CreateNexmoUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateNexmoUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateNexmoUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.infinitusEmail = reader.string();
                                break;
                            }
                        case 2: {
                                message.nexmoUserName = reader.string();
                                break;
                            }
                        case 3: {
                                message.nexmoUserDisplayName = reader.string();
                                break;
                            }
                        case 4: {
                                message.canReview = reader.bool();
                                break;
                            }
                        case 5: {
                                message.isAdmin = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateNexmoUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateNexmoUserRequest} CreateNexmoUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateNexmoUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateNexmoUserRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateNexmoUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.infinitusEmail != null && message.hasOwnProperty("infinitusEmail"))
                        if (!$util.isString(message.infinitusEmail))
                            return "infinitusEmail: string expected";
                    if (message.nexmoUserName != null && message.hasOwnProperty("nexmoUserName"))
                        if (!$util.isString(message.nexmoUserName))
                            return "nexmoUserName: string expected";
                    if (message.nexmoUserDisplayName != null && message.hasOwnProperty("nexmoUserDisplayName"))
                        if (!$util.isString(message.nexmoUserDisplayName))
                            return "nexmoUserDisplayName: string expected";
                    if (message.canReview != null && message.hasOwnProperty("canReview"))
                        if (typeof message.canReview !== "boolean")
                            return "canReview: boolean expected";
                    if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
                        if (typeof message.isAdmin !== "boolean")
                            return "isAdmin: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a CreateNexmoUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateNexmoUserRequest} CreateNexmoUserRequest
                 */
                CreateNexmoUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateNexmoUserRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateNexmoUserRequest();
                    if (object.infinitusEmail != null)
                        message.infinitusEmail = String(object.infinitusEmail);
                    if (object.nexmoUserName != null)
                        message.nexmoUserName = String(object.nexmoUserName);
                    if (object.nexmoUserDisplayName != null)
                        message.nexmoUserDisplayName = String(object.nexmoUserDisplayName);
                    if (object.canReview != null)
                        message.canReview = Boolean(object.canReview);
                    if (object.isAdmin != null)
                        message.isAdmin = Boolean(object.isAdmin);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateNexmoUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {infinitusai.be.CreateNexmoUserRequest} message CreateNexmoUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateNexmoUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.infinitusEmail = "";
                        object.nexmoUserName = "";
                        object.nexmoUserDisplayName = "";
                        object.canReview = false;
                        object.isAdmin = false;
                    }
                    if (message.infinitusEmail != null && message.hasOwnProperty("infinitusEmail"))
                        object.infinitusEmail = message.infinitusEmail;
                    if (message.nexmoUserName != null && message.hasOwnProperty("nexmoUserName"))
                        object.nexmoUserName = message.nexmoUserName;
                    if (message.nexmoUserDisplayName != null && message.hasOwnProperty("nexmoUserDisplayName"))
                        object.nexmoUserDisplayName = message.nexmoUserDisplayName;
                    if (message.canReview != null && message.hasOwnProperty("canReview"))
                        object.canReview = message.canReview;
                    if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
                        object.isAdmin = message.isAdmin;
                    return object;
                };
    
                /**
                 * Converts this CreateNexmoUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateNexmoUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateNexmoUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateNexmoUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateNexmoUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateNexmoUserRequest";
                };
    
                return CreateNexmoUserRequest;
            })();
    
            be.CreateNexmoUserResponse = (function() {
    
                /**
                 * Properties of a CreateNexmoUserResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateNexmoUserResponse
                 */
    
                /**
                 * Constructs a new CreateNexmoUserResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateNexmoUserResponse.
                 * @implements ICreateNexmoUserResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateNexmoUserResponse=} [properties] Properties to set
                 */
                function CreateNexmoUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CreateNexmoUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {infinitusai.be.ICreateNexmoUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateNexmoUserResponse} CreateNexmoUserResponse instance
                 */
                CreateNexmoUserResponse.create = function create(properties) {
                    return new CreateNexmoUserResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateNexmoUserResponse message. Does not implicitly {@link infinitusai.be.CreateNexmoUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {infinitusai.be.ICreateNexmoUserResponse} message CreateNexmoUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateNexmoUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateNexmoUserResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateNexmoUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {infinitusai.be.ICreateNexmoUserResponse} message CreateNexmoUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateNexmoUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateNexmoUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateNexmoUserResponse} CreateNexmoUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateNexmoUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateNexmoUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateNexmoUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateNexmoUserResponse} CreateNexmoUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateNexmoUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateNexmoUserResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateNexmoUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CreateNexmoUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateNexmoUserResponse} CreateNexmoUserResponse
                 */
                CreateNexmoUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateNexmoUserResponse)
                        return object;
                    return new $root.infinitusai.be.CreateNexmoUserResponse();
                };
    
                /**
                 * Creates a plain object from a CreateNexmoUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {infinitusai.be.CreateNexmoUserResponse} message CreateNexmoUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateNexmoUserResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CreateNexmoUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateNexmoUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateNexmoUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateNexmoUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateNexmoUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateNexmoUserResponse";
                };
    
                return CreateNexmoUserResponse;
            })();
    
            be.GetNexmoUserRequest = (function() {
    
                /**
                 * Properties of a GetNexmoUserRequest.
                 * @memberof infinitusai.be
                 * @interface IGetNexmoUserRequest
                 * @property {string|null} [infinitusEmail] GetNexmoUserRequest infinitusEmail
                 */
    
                /**
                 * Constructs a new GetNexmoUserRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetNexmoUserRequest.
                 * @implements IGetNexmoUserRequest
                 * @constructor
                 * @param {infinitusai.be.IGetNexmoUserRequest=} [properties] Properties to set
                 */
                function GetNexmoUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetNexmoUserRequest infinitusEmail.
                 * @member {string} infinitusEmail
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @instance
                 */
                GetNexmoUserRequest.prototype.infinitusEmail = "";
    
                /**
                 * Creates a new GetNexmoUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {infinitusai.be.IGetNexmoUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetNexmoUserRequest} GetNexmoUserRequest instance
                 */
                GetNexmoUserRequest.create = function create(properties) {
                    return new GetNexmoUserRequest(properties);
                };
    
                /**
                 * Encodes the specified GetNexmoUserRequest message. Does not implicitly {@link infinitusai.be.GetNexmoUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {infinitusai.be.IGetNexmoUserRequest} message GetNexmoUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNexmoUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.infinitusEmail != null && Object.hasOwnProperty.call(message, "infinitusEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.infinitusEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetNexmoUserRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetNexmoUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {infinitusai.be.IGetNexmoUserRequest} message GetNexmoUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNexmoUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetNexmoUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetNexmoUserRequest} GetNexmoUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNexmoUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetNexmoUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.infinitusEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetNexmoUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetNexmoUserRequest} GetNexmoUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNexmoUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetNexmoUserRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetNexmoUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.infinitusEmail != null && message.hasOwnProperty("infinitusEmail"))
                        if (!$util.isString(message.infinitusEmail))
                            return "infinitusEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetNexmoUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetNexmoUserRequest} GetNexmoUserRequest
                 */
                GetNexmoUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetNexmoUserRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetNexmoUserRequest();
                    if (object.infinitusEmail != null)
                        message.infinitusEmail = String(object.infinitusEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetNexmoUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {infinitusai.be.GetNexmoUserRequest} message GetNexmoUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetNexmoUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.infinitusEmail = "";
                    if (message.infinitusEmail != null && message.hasOwnProperty("infinitusEmail"))
                        object.infinitusEmail = message.infinitusEmail;
                    return object;
                };
    
                /**
                 * Converts this GetNexmoUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetNexmoUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetNexmoUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetNexmoUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetNexmoUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetNexmoUserRequest";
                };
    
                return GetNexmoUserRequest;
            })();
    
            be.GetNexmoUserResponse = (function() {
    
                /**
                 * Properties of a GetNexmoUserResponse.
                 * @memberof infinitusai.be
                 * @interface IGetNexmoUserResponse
                 * @property {string|null} [infinitusEmail] GetNexmoUserResponse infinitusEmail
                 * @property {string|null} [nexmoUserId] GetNexmoUserResponse nexmoUserId
                 * @property {string|null} [nexmoUserName] GetNexmoUserResponse nexmoUserName
                 */
    
                /**
                 * Constructs a new GetNexmoUserResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetNexmoUserResponse.
                 * @implements IGetNexmoUserResponse
                 * @constructor
                 * @param {infinitusai.be.IGetNexmoUserResponse=} [properties] Properties to set
                 */
                function GetNexmoUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetNexmoUserResponse infinitusEmail.
                 * @member {string} infinitusEmail
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @instance
                 */
                GetNexmoUserResponse.prototype.infinitusEmail = "";
    
                /**
                 * GetNexmoUserResponse nexmoUserId.
                 * @member {string} nexmoUserId
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @instance
                 */
                GetNexmoUserResponse.prototype.nexmoUserId = "";
    
                /**
                 * GetNexmoUserResponse nexmoUserName.
                 * @member {string} nexmoUserName
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @instance
                 */
                GetNexmoUserResponse.prototype.nexmoUserName = "";
    
                /**
                 * Creates a new GetNexmoUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {infinitusai.be.IGetNexmoUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetNexmoUserResponse} GetNexmoUserResponse instance
                 */
                GetNexmoUserResponse.create = function create(properties) {
                    return new GetNexmoUserResponse(properties);
                };
    
                /**
                 * Encodes the specified GetNexmoUserResponse message. Does not implicitly {@link infinitusai.be.GetNexmoUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {infinitusai.be.IGetNexmoUserResponse} message GetNexmoUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNexmoUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.infinitusEmail != null && Object.hasOwnProperty.call(message, "infinitusEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.infinitusEmail);
                    if (message.nexmoUserId != null && Object.hasOwnProperty.call(message, "nexmoUserId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.nexmoUserId);
                    if (message.nexmoUserName != null && Object.hasOwnProperty.call(message, "nexmoUserName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.nexmoUserName);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetNexmoUserResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetNexmoUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {infinitusai.be.IGetNexmoUserResponse} message GetNexmoUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetNexmoUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetNexmoUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetNexmoUserResponse} GetNexmoUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNexmoUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetNexmoUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.infinitusEmail = reader.string();
                                break;
                            }
                        case 2: {
                                message.nexmoUserId = reader.string();
                                break;
                            }
                        case 3: {
                                message.nexmoUserName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetNexmoUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetNexmoUserResponse} GetNexmoUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetNexmoUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetNexmoUserResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetNexmoUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.infinitusEmail != null && message.hasOwnProperty("infinitusEmail"))
                        if (!$util.isString(message.infinitusEmail))
                            return "infinitusEmail: string expected";
                    if (message.nexmoUserId != null && message.hasOwnProperty("nexmoUserId"))
                        if (!$util.isString(message.nexmoUserId))
                            return "nexmoUserId: string expected";
                    if (message.nexmoUserName != null && message.hasOwnProperty("nexmoUserName"))
                        if (!$util.isString(message.nexmoUserName))
                            return "nexmoUserName: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetNexmoUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetNexmoUserResponse} GetNexmoUserResponse
                 */
                GetNexmoUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetNexmoUserResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetNexmoUserResponse();
                    if (object.infinitusEmail != null)
                        message.infinitusEmail = String(object.infinitusEmail);
                    if (object.nexmoUserId != null)
                        message.nexmoUserId = String(object.nexmoUserId);
                    if (object.nexmoUserName != null)
                        message.nexmoUserName = String(object.nexmoUserName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetNexmoUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {infinitusai.be.GetNexmoUserResponse} message GetNexmoUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetNexmoUserResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.infinitusEmail = "";
                        object.nexmoUserId = "";
                        object.nexmoUserName = "";
                    }
                    if (message.infinitusEmail != null && message.hasOwnProperty("infinitusEmail"))
                        object.infinitusEmail = message.infinitusEmail;
                    if (message.nexmoUserId != null && message.hasOwnProperty("nexmoUserId"))
                        object.nexmoUserId = message.nexmoUserId;
                    if (message.nexmoUserName != null && message.hasOwnProperty("nexmoUserName"))
                        object.nexmoUserName = message.nexmoUserName;
                    return object;
                };
    
                /**
                 * Converts this GetNexmoUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetNexmoUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetNexmoUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetNexmoUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetNexmoUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetNexmoUserResponse";
                };
    
                return GetNexmoUserResponse;
            })();
    
            be.JoinNexmoConversationRequest = (function() {
    
                /**
                 * Properties of a JoinNexmoConversationRequest.
                 * @memberof infinitusai.be
                 * @interface IJoinNexmoConversationRequest
                 * @property {string|null} [conversationUuid] JoinNexmoConversationRequest conversationUuid
                 */
    
                /**
                 * Constructs a new JoinNexmoConversationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a JoinNexmoConversationRequest.
                 * @implements IJoinNexmoConversationRequest
                 * @constructor
                 * @param {infinitusai.be.IJoinNexmoConversationRequest=} [properties] Properties to set
                 */
                function JoinNexmoConversationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * JoinNexmoConversationRequest conversationUuid.
                 * @member {string} conversationUuid
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @instance
                 */
                JoinNexmoConversationRequest.prototype.conversationUuid = "";
    
                /**
                 * Creates a new JoinNexmoConversationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {infinitusai.be.IJoinNexmoConversationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.JoinNexmoConversationRequest} JoinNexmoConversationRequest instance
                 */
                JoinNexmoConversationRequest.create = function create(properties) {
                    return new JoinNexmoConversationRequest(properties);
                };
    
                /**
                 * Encodes the specified JoinNexmoConversationRequest message. Does not implicitly {@link infinitusai.be.JoinNexmoConversationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {infinitusai.be.IJoinNexmoConversationRequest} message JoinNexmoConversationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JoinNexmoConversationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversationUuid != null && Object.hasOwnProperty.call(message, "conversationUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified JoinNexmoConversationRequest message, length delimited. Does not implicitly {@link infinitusai.be.JoinNexmoConversationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {infinitusai.be.IJoinNexmoConversationRequest} message JoinNexmoConversationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JoinNexmoConversationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a JoinNexmoConversationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.JoinNexmoConversationRequest} JoinNexmoConversationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JoinNexmoConversationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.JoinNexmoConversationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversationUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a JoinNexmoConversationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.JoinNexmoConversationRequest} JoinNexmoConversationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JoinNexmoConversationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a JoinNexmoConversationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                JoinNexmoConversationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        if (!$util.isString(message.conversationUuid))
                            return "conversationUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a JoinNexmoConversationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.JoinNexmoConversationRequest} JoinNexmoConversationRequest
                 */
                JoinNexmoConversationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.JoinNexmoConversationRequest)
                        return object;
                    var message = new $root.infinitusai.be.JoinNexmoConversationRequest();
                    if (object.conversationUuid != null)
                        message.conversationUuid = String(object.conversationUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a JoinNexmoConversationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {infinitusai.be.JoinNexmoConversationRequest} message JoinNexmoConversationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                JoinNexmoConversationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.conversationUuid = "";
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        object.conversationUuid = message.conversationUuid;
                    return object;
                };
    
                /**
                 * Converts this JoinNexmoConversationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                JoinNexmoConversationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for JoinNexmoConversationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.JoinNexmoConversationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                JoinNexmoConversationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.JoinNexmoConversationRequest";
                };
    
                return JoinNexmoConversationRequest;
            })();
    
            be.JoinNexmoConversationResponse = (function() {
    
                /**
                 * Properties of a JoinNexmoConversationResponse.
                 * @memberof infinitusai.be
                 * @interface IJoinNexmoConversationResponse
                 */
    
                /**
                 * Constructs a new JoinNexmoConversationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a JoinNexmoConversationResponse.
                 * @implements IJoinNexmoConversationResponse
                 * @constructor
                 * @param {infinitusai.be.IJoinNexmoConversationResponse=} [properties] Properties to set
                 */
                function JoinNexmoConversationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new JoinNexmoConversationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {infinitusai.be.IJoinNexmoConversationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.JoinNexmoConversationResponse} JoinNexmoConversationResponse instance
                 */
                JoinNexmoConversationResponse.create = function create(properties) {
                    return new JoinNexmoConversationResponse(properties);
                };
    
                /**
                 * Encodes the specified JoinNexmoConversationResponse message. Does not implicitly {@link infinitusai.be.JoinNexmoConversationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {infinitusai.be.IJoinNexmoConversationResponse} message JoinNexmoConversationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JoinNexmoConversationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified JoinNexmoConversationResponse message, length delimited. Does not implicitly {@link infinitusai.be.JoinNexmoConversationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {infinitusai.be.IJoinNexmoConversationResponse} message JoinNexmoConversationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                JoinNexmoConversationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a JoinNexmoConversationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.JoinNexmoConversationResponse} JoinNexmoConversationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JoinNexmoConversationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.JoinNexmoConversationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a JoinNexmoConversationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.JoinNexmoConversationResponse} JoinNexmoConversationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                JoinNexmoConversationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a JoinNexmoConversationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                JoinNexmoConversationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a JoinNexmoConversationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.JoinNexmoConversationResponse} JoinNexmoConversationResponse
                 */
                JoinNexmoConversationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.JoinNexmoConversationResponse)
                        return object;
                    return new $root.infinitusai.be.JoinNexmoConversationResponse();
                };
    
                /**
                 * Creates a plain object from a JoinNexmoConversationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {infinitusai.be.JoinNexmoConversationResponse} message JoinNexmoConversationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                JoinNexmoConversationResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this JoinNexmoConversationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                JoinNexmoConversationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for JoinNexmoConversationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.JoinNexmoConversationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                JoinNexmoConversationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.JoinNexmoConversationResponse";
                };
    
                return JoinNexmoConversationResponse;
            })();
    
            be.GetMyLegInNexmoConversationRequest = (function() {
    
                /**
                 * Properties of a GetMyLegInNexmoConversationRequest.
                 * @memberof infinitusai.be
                 * @interface IGetMyLegInNexmoConversationRequest
                 * @property {string|null} [conversationUuid] GetMyLegInNexmoConversationRequest conversationUuid
                 */
    
                /**
                 * Constructs a new GetMyLegInNexmoConversationRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetMyLegInNexmoConversationRequest.
                 * @implements IGetMyLegInNexmoConversationRequest
                 * @constructor
                 * @param {infinitusai.be.IGetMyLegInNexmoConversationRequest=} [properties] Properties to set
                 */
                function GetMyLegInNexmoConversationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyLegInNexmoConversationRequest conversationUuid.
                 * @member {string} conversationUuid
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @instance
                 */
                GetMyLegInNexmoConversationRequest.prototype.conversationUuid = "";
    
                /**
                 * Creates a new GetMyLegInNexmoConversationRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {infinitusai.be.IGetMyLegInNexmoConversationRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetMyLegInNexmoConversationRequest} GetMyLegInNexmoConversationRequest instance
                 */
                GetMyLegInNexmoConversationRequest.create = function create(properties) {
                    return new GetMyLegInNexmoConversationRequest(properties);
                };
    
                /**
                 * Encodes the specified GetMyLegInNexmoConversationRequest message. Does not implicitly {@link infinitusai.be.GetMyLegInNexmoConversationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {infinitusai.be.IGetMyLegInNexmoConversationRequest} message GetMyLegInNexmoConversationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyLegInNexmoConversationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversationUuid != null && Object.hasOwnProperty.call(message, "conversationUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyLegInNexmoConversationRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetMyLegInNexmoConversationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {infinitusai.be.IGetMyLegInNexmoConversationRequest} message GetMyLegInNexmoConversationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyLegInNexmoConversationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyLegInNexmoConversationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetMyLegInNexmoConversationRequest} GetMyLegInNexmoConversationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyLegInNexmoConversationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetMyLegInNexmoConversationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversationUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyLegInNexmoConversationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetMyLegInNexmoConversationRequest} GetMyLegInNexmoConversationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyLegInNexmoConversationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyLegInNexmoConversationRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyLegInNexmoConversationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        if (!$util.isString(message.conversationUuid))
                            return "conversationUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetMyLegInNexmoConversationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetMyLegInNexmoConversationRequest} GetMyLegInNexmoConversationRequest
                 */
                GetMyLegInNexmoConversationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetMyLegInNexmoConversationRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetMyLegInNexmoConversationRequest();
                    if (object.conversationUuid != null)
                        message.conversationUuid = String(object.conversationUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyLegInNexmoConversationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {infinitusai.be.GetMyLegInNexmoConversationRequest} message GetMyLegInNexmoConversationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyLegInNexmoConversationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.conversationUuid = "";
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        object.conversationUuid = message.conversationUuid;
                    return object;
                };
    
                /**
                 * Converts this GetMyLegInNexmoConversationRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyLegInNexmoConversationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyLegInNexmoConversationRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyLegInNexmoConversationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetMyLegInNexmoConversationRequest";
                };
    
                return GetMyLegInNexmoConversationRequest;
            })();
    
            be.GetMyLegInNexmoConversationResponse = (function() {
    
                /**
                 * Properties of a GetMyLegInNexmoConversationResponse.
                 * @memberof infinitusai.be
                 * @interface IGetMyLegInNexmoConversationResponse
                 * @property {string|null} [legId] GetMyLegInNexmoConversationResponse legId
                 * @property {infinitusai.be.GetMyLegInNexmoConversationResponse.Status|null} [status] GetMyLegInNexmoConversationResponse status
                 */
    
                /**
                 * Constructs a new GetMyLegInNexmoConversationResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetMyLegInNexmoConversationResponse.
                 * @implements IGetMyLegInNexmoConversationResponse
                 * @constructor
                 * @param {infinitusai.be.IGetMyLegInNexmoConversationResponse=} [properties] Properties to set
                 */
                function GetMyLegInNexmoConversationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyLegInNexmoConversationResponse legId.
                 * @member {string} legId
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @instance
                 */
                GetMyLegInNexmoConversationResponse.prototype.legId = "";
    
                /**
                 * GetMyLegInNexmoConversationResponse status.
                 * @member {infinitusai.be.GetMyLegInNexmoConversationResponse.Status} status
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @instance
                 */
                GetMyLegInNexmoConversationResponse.prototype.status = 0;
    
                /**
                 * Creates a new GetMyLegInNexmoConversationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {infinitusai.be.IGetMyLegInNexmoConversationResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetMyLegInNexmoConversationResponse} GetMyLegInNexmoConversationResponse instance
                 */
                GetMyLegInNexmoConversationResponse.create = function create(properties) {
                    return new GetMyLegInNexmoConversationResponse(properties);
                };
    
                /**
                 * Encodes the specified GetMyLegInNexmoConversationResponse message. Does not implicitly {@link infinitusai.be.GetMyLegInNexmoConversationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {infinitusai.be.IGetMyLegInNexmoConversationResponse} message GetMyLegInNexmoConversationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyLegInNexmoConversationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.legId != null && Object.hasOwnProperty.call(message, "legId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.legId);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyLegInNexmoConversationResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetMyLegInNexmoConversationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {infinitusai.be.IGetMyLegInNexmoConversationResponse} message GetMyLegInNexmoConversationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyLegInNexmoConversationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyLegInNexmoConversationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetMyLegInNexmoConversationResponse} GetMyLegInNexmoConversationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyLegInNexmoConversationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetMyLegInNexmoConversationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.legId = reader.string();
                                break;
                            }
                        case 2: {
                                message.status = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyLegInNexmoConversationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetMyLegInNexmoConversationResponse} GetMyLegInNexmoConversationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyLegInNexmoConversationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyLegInNexmoConversationResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyLegInNexmoConversationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.legId != null && message.hasOwnProperty("legId"))
                        if (!$util.isString(message.legId))
                            return "legId: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GetMyLegInNexmoConversationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetMyLegInNexmoConversationResponse} GetMyLegInNexmoConversationResponse
                 */
                GetMyLegInNexmoConversationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetMyLegInNexmoConversationResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetMyLegInNexmoConversationResponse();
                    if (object.legId != null)
                        message.legId = String(object.legId);
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "STATUS_UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "STATUS_RINGING":
                    case 1:
                        message.status = 1;
                        break;
                    case "STATUS_ANSWERED":
                    case 2:
                        message.status = 2;
                        break;
                    case "STATUS_COMPLETED":
                    case 3:
                        message.status = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyLegInNexmoConversationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {infinitusai.be.GetMyLegInNexmoConversationResponse} message GetMyLegInNexmoConversationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyLegInNexmoConversationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.legId = "";
                        object.status = options.enums === String ? "STATUS_UNKNOWN" : 0;
                    }
                    if (message.legId != null && message.hasOwnProperty("legId"))
                        object.legId = message.legId;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.be.GetMyLegInNexmoConversationResponse.Status[message.status] === undefined ? message.status : $root.infinitusai.be.GetMyLegInNexmoConversationResponse.Status[message.status] : message.status;
                    return object;
                };
    
                /**
                 * Converts this GetMyLegInNexmoConversationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyLegInNexmoConversationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyLegInNexmoConversationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetMyLegInNexmoConversationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyLegInNexmoConversationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetMyLegInNexmoConversationResponse";
                };
    
                /**
                 * Status enum.
                 * @name infinitusai.be.GetMyLegInNexmoConversationResponse.Status
                 * @enum {number}
                 * @property {number} STATUS_UNKNOWN=0 STATUS_UNKNOWN value
                 * @property {number} STATUS_RINGING=1 STATUS_RINGING value
                 * @property {number} STATUS_ANSWERED=2 STATUS_ANSWERED value
                 * @property {number} STATUS_COMPLETED=3 STATUS_COMPLETED value
                 */
                GetMyLegInNexmoConversationResponse.Status = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_UNKNOWN"] = 0;
                    values[valuesById[1] = "STATUS_RINGING"] = 1;
                    values[valuesById[2] = "STATUS_ANSWERED"] = 2;
                    values[valuesById[3] = "STATUS_COMPLETED"] = 3;
                    return values;
                })();
    
                return GetMyLegInNexmoConversationResponse;
            })();
    
            be.NlpService = (function() {
    
                /**
                 * Constructs a new NlpService service.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NlpService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function NlpService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (NlpService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = NlpService;
    
                /**
                 * Creates new NlpService service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.NlpService
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {NlpService} RPC service. Useful where requests and/or responses are streamed.
                 */
                NlpService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#generateResponse}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef GenerateResponseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GenerateResponseResponse} [response] GenerateResponseResponse
                 */
    
                /**
                 * Calls GenerateResponse.
                 * @function generateResponse
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateResponseRequest} request GenerateResponseRequest message or plain object
                 * @param {infinitusai.be.NlpService.GenerateResponseCallback} callback Node-style callback called with the error, if any, and GenerateResponseResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.generateResponse = function generateResponse(request, callback) {
                    return this.rpcCall(generateResponse, $root.infinitusai.be.GenerateResponseRequest, $root.infinitusai.be.GenerateResponseResponse, request, callback);
                }, "name", { value: "GenerateResponse" });
    
                /**
                 * Calls GenerateResponse.
                 * @function generateResponse
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateResponseRequest} request GenerateResponseRequest message or plain object
                 * @returns {Promise<infinitusai.be.GenerateResponseResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#dialogBreakdown}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef DialogBreakdownCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.DialogBreakdownResponse} [response] DialogBreakdownResponse
                 */
    
                /**
                 * Calls DialogBreakdown.
                 * @function dialogBreakdown
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateResponseRequest} request GenerateResponseRequest message or plain object
                 * @param {infinitusai.be.NlpService.DialogBreakdownCallback} callback Node-style callback called with the error, if any, and DialogBreakdownResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.dialogBreakdown = function dialogBreakdown(request, callback) {
                    return this.rpcCall(dialogBreakdown, $root.infinitusai.be.GenerateResponseRequest, $root.infinitusai.be.DialogBreakdownResponse, request, callback);
                }, "name", { value: "DialogBreakdown" });
    
                /**
                 * Calls DialogBreakdown.
                 * @function dialogBreakdown
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateResponseRequest} request GenerateResponseRequest message or plain object
                 * @returns {Promise<infinitusai.be.DialogBreakdownResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#validateCallOutputsQuality}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef ValidateCallOutputsQualityCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.NlpValidateCallOutputsResponse} [response] NlpValidateCallOutputsResponse
                 */
    
                /**
                 * Calls ValidateCallOutputsQuality.
                 * @function validateCallOutputsQuality
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.INlpValidateCallOutputsRequest} request NlpValidateCallOutputsRequest message or plain object
                 * @param {infinitusai.be.NlpService.ValidateCallOutputsQualityCallback} callback Node-style callback called with the error, if any, and NlpValidateCallOutputsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.validateCallOutputsQuality = function validateCallOutputsQuality(request, callback) {
                    return this.rpcCall(validateCallOutputsQuality, $root.infinitusai.be.NlpValidateCallOutputsRequest, $root.infinitusai.be.NlpValidateCallOutputsResponse, request, callback);
                }, "name", { value: "ValidateCallOutputsQuality" });
    
                /**
                 * Calls ValidateCallOutputsQuality.
                 * @function validateCallOutputsQuality
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.INlpValidateCallOutputsRequest} request NlpValidateCallOutputsRequest message or plain object
                 * @returns {Promise<infinitusai.be.NlpValidateCallOutputsResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#generateOperatorTrainerResponse}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef GenerateOperatorTrainerResponseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GenerateOperatorTrainerResponseResponse} [response] GenerateOperatorTrainerResponseResponse
                 */
    
                /**
                 * Calls GenerateOperatorTrainerResponse.
                 * @function generateOperatorTrainerResponse
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseRequest} request GenerateOperatorTrainerResponseRequest message or plain object
                 * @param {infinitusai.be.NlpService.GenerateOperatorTrainerResponseCallback} callback Node-style callback called with the error, if any, and GenerateOperatorTrainerResponseResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.generateOperatorTrainerResponse = function generateOperatorTrainerResponse(request, callback) {
                    return this.rpcCall(generateOperatorTrainerResponse, $root.infinitusai.be.GenerateOperatorTrainerResponseRequest, $root.infinitusai.be.GenerateOperatorTrainerResponseResponse, request, callback);
                }, "name", { value: "GenerateOperatorTrainerResponse" });
    
                /**
                 * Calls GenerateOperatorTrainerResponse.
                 * @function generateOperatorTrainerResponse
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseRequest} request GenerateOperatorTrainerResponseRequest message or plain object
                 * @returns {Promise<infinitusai.be.GenerateOperatorTrainerResponseResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#getChcEligibilityInfo}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef GetChcEligibilityInfoCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetChcEligibilityInfoResponse} [response] GetChcEligibilityInfoResponse
                 */
    
                /**
                 * Calls GetChcEligibilityInfo.
                 * @function getChcEligibilityInfo
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGetChcEligibilityInfoRequest} request GetChcEligibilityInfoRequest message or plain object
                 * @param {infinitusai.be.NlpService.GetChcEligibilityInfoCallback} callback Node-style callback called with the error, if any, and GetChcEligibilityInfoResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.getChcEligibilityInfo = function getChcEligibilityInfo(request, callback) {
                    return this.rpcCall(getChcEligibilityInfo, $root.infinitusai.be.GetChcEligibilityInfoRequest, $root.infinitusai.be.GetChcEligibilityInfoResponse, request, callback);
                }, "name", { value: "GetChcEligibilityInfo" });
    
                /**
                 * Calls GetChcEligibilityInfo.
                 * @function getChcEligibilityInfo
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGetChcEligibilityInfoRequest} request GetChcEligibilityInfoRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetChcEligibilityInfoResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#getChcMetrics}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef GetChcMetricsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetChcMetricsResponse} [response] GetChcMetricsResponse
                 */
    
                /**
                 * Calls GetChcMetrics.
                 * @function getChcMetrics
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGetChcMetricsRequest} request GetChcMetricsRequest message or plain object
                 * @param {infinitusai.be.NlpService.GetChcMetricsCallback} callback Node-style callback called with the error, if any, and GetChcMetricsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.getChcMetrics = function getChcMetrics(request, callback) {
                    return this.rpcCall(getChcMetrics, $root.infinitusai.be.GetChcMetricsRequest, $root.infinitusai.be.GetChcMetricsResponse, request, callback);
                }, "name", { value: "GetChcMetrics" });
    
                /**
                 * Calls GetChcMetrics.
                 * @function getChcMetrics
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGetChcMetricsRequest} request GetChcMetricsRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetChcMetricsResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#generateBillingNotes}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef GenerateBillingNotesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GenerateBillingNotesResponse} [response] GenerateBillingNotesResponse
                 */
    
                /**
                 * Calls GenerateBillingNotes.
                 * @function generateBillingNotes
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateBillingNotesRequest} request GenerateBillingNotesRequest message or plain object
                 * @param {infinitusai.be.NlpService.GenerateBillingNotesCallback} callback Node-style callback called with the error, if any, and GenerateBillingNotesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.generateBillingNotes = function generateBillingNotes(request, callback) {
                    return this.rpcCall(generateBillingNotes, $root.infinitusai.be.GenerateBillingNotesRequest, $root.infinitusai.be.GenerateBillingNotesResponse, request, callback);
                }, "name", { value: "GenerateBillingNotes" });
    
                /**
                 * Calls GenerateBillingNotes.
                 * @function generateBillingNotes
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateBillingNotesRequest} request GenerateBillingNotesRequest message or plain object
                 * @returns {Promise<infinitusai.be.GenerateBillingNotesResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#generateGenericBillingNotes}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef GenerateGenericBillingNotesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GenerateBillingNotesResponse} [response] GenerateBillingNotesResponse
                 */
    
                /**
                 * Calls GenerateGenericBillingNotes.
                 * @function generateGenericBillingNotes
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateGenericBillingNotesRequest} request GenerateGenericBillingNotesRequest message or plain object
                 * @param {infinitusai.be.NlpService.GenerateGenericBillingNotesCallback} callback Node-style callback called with the error, if any, and GenerateBillingNotesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.generateGenericBillingNotes = function generateGenericBillingNotes(request, callback) {
                    return this.rpcCall(generateGenericBillingNotes, $root.infinitusai.be.GenerateGenericBillingNotesRequest, $root.infinitusai.be.GenerateBillingNotesResponse, request, callback);
                }, "name", { value: "GenerateGenericBillingNotes" });
    
                /**
                 * Calls GenerateGenericBillingNotes.
                 * @function generateGenericBillingNotes
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGenerateGenericBillingNotesRequest} request GenerateGenericBillingNotesRequest message or plain object
                 * @returns {Promise<infinitusai.be.GenerateBillingNotesResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#webDriver}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef WebDriverCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.WebDriverResponse} [response] WebDriverResponse
                 */
    
                /**
                 * Calls WebDriver.
                 * @function webDriver
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IWebDriverRequest} request WebDriverRequest message or plain object
                 * @param {infinitusai.be.NlpService.WebDriverCallback} callback Node-style callback called with the error, if any, and WebDriverResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.webDriver = function webDriver(request, callback) {
                    return this.rpcCall(webDriver, $root.infinitusai.be.WebDriverRequest, $root.infinitusai.be.WebDriverResponse, request, callback);
                }, "name", { value: "WebDriver" });
    
                /**
                 * Calls WebDriver.
                 * @function webDriver
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IWebDriverRequest} request WebDriverRequest message or plain object
                 * @returns {Promise<infinitusai.be.WebDriverResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#getBcbsEligibilityInfo}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef GetBcbsEligibilityInfoCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.GetBcbsEligibilityInfoResponse} [response] GetBcbsEligibilityInfoResponse
                 */
    
                /**
                 * Calls GetBcbsEligibilityInfo.
                 * @function getBcbsEligibilityInfo
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoRequest} request GetBcbsEligibilityInfoRequest message or plain object
                 * @param {infinitusai.be.NlpService.GetBcbsEligibilityInfoCallback} callback Node-style callback called with the error, if any, and GetBcbsEligibilityInfoResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.getBcbsEligibilityInfo = function getBcbsEligibilityInfo(request, callback) {
                    return this.rpcCall(getBcbsEligibilityInfo, $root.infinitusai.be.GetBcbsEligibilityInfoRequest, $root.infinitusai.be.GetBcbsEligibilityInfoResponse, request, callback);
                }, "name", { value: "GetBcbsEligibilityInfo" });
    
                /**
                 * Calls GetBcbsEligibilityInfo.
                 * @function getBcbsEligibilityInfo
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IGetBcbsEligibilityInfoRequest} request GetBcbsEligibilityInfoRequest message or plain object
                 * @returns {Promise<infinitusai.be.GetBcbsEligibilityInfoResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#extractIVROutputs}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef ExtractIVROutputsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.ExtractIVROutputsResponse} [response] ExtractIVROutputsResponse
                 */
    
                /**
                 * Calls ExtractIVROutputs.
                 * @function extractIVROutputs
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IExtractIVROutputsRequest} request ExtractIVROutputsRequest message or plain object
                 * @param {infinitusai.be.NlpService.ExtractIVROutputsCallback} callback Node-style callback called with the error, if any, and ExtractIVROutputsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.extractIVROutputs = function extractIVROutputs(request, callback) {
                    return this.rpcCall(extractIVROutputs, $root.infinitusai.be.ExtractIVROutputsRequest, $root.infinitusai.be.ExtractIVROutputsResponse, request, callback);
                }, "name", { value: "ExtractIVROutputs" });
    
                /**
                 * Calls ExtractIVROutputs.
                 * @function extractIVROutputs
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.IExtractIVROutputsRequest} request ExtractIVROutputsRequest message or plain object
                 * @returns {Promise<infinitusai.be.ExtractIVROutputsResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.NlpService#chatSOP}.
                 * @memberof infinitusai.be.NlpService
                 * @typedef ChatSOPCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.NlpChatSOPResponse} [response] NlpChatSOPResponse
                 */
    
                /**
                 * Calls ChatSOP.
                 * @function chatSOP
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.INlpChatSOPRequest} request NlpChatSOPRequest message or plain object
                 * @param {infinitusai.be.NlpService.ChatSOPCallback} callback Node-style callback called with the error, if any, and NlpChatSOPResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NlpService.prototype.chatSOP = function chatSOP(request, callback) {
                    return this.rpcCall(chatSOP, $root.infinitusai.be.NlpChatSOPRequest, $root.infinitusai.be.NlpChatSOPResponse, request, callback);
                }, "name", { value: "ChatSOP" });
    
                /**
                 * Calls ChatSOP.
                 * @function chatSOP
                 * @memberof infinitusai.be.NlpService
                 * @instance
                 * @param {infinitusai.be.INlpChatSOPRequest} request NlpChatSOPRequest message or plain object
                 * @returns {Promise<infinitusai.be.NlpChatSOPResponse>} Promise
                 * @variation 2
                 */
    
                return NlpService;
            })();
    
            be.SpeechToTextAlternative = (function() {
    
                /**
                 * Properties of a SpeechToTextAlternative.
                 * @memberof infinitusai.be
                 * @interface ISpeechToTextAlternative
                 * @property {string|null} [transcript] SpeechToTextAlternative transcript
                 * @property {number|null} [confidence] SpeechToTextAlternative confidence
                 */
    
                /**
                 * Constructs a new SpeechToTextAlternative.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SpeechToTextAlternative.
                 * @implements ISpeechToTextAlternative
                 * @constructor
                 * @param {infinitusai.be.ISpeechToTextAlternative=} [properties] Properties to set
                 */
                function SpeechToTextAlternative(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SpeechToTextAlternative transcript.
                 * @member {string} transcript
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @instance
                 */
                SpeechToTextAlternative.prototype.transcript = "";
    
                /**
                 * SpeechToTextAlternative confidence.
                 * @member {number} confidence
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @instance
                 */
                SpeechToTextAlternative.prototype.confidence = 0;
    
                /**
                 * Creates a new SpeechToTextAlternative instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {infinitusai.be.ISpeechToTextAlternative=} [properties] Properties to set
                 * @returns {infinitusai.be.SpeechToTextAlternative} SpeechToTextAlternative instance
                 */
                SpeechToTextAlternative.create = function create(properties) {
                    return new SpeechToTextAlternative(properties);
                };
    
                /**
                 * Encodes the specified SpeechToTextAlternative message. Does not implicitly {@link infinitusai.be.SpeechToTextAlternative.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {infinitusai.be.ISpeechToTextAlternative} message SpeechToTextAlternative message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpeechToTextAlternative.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.transcript != null && Object.hasOwnProperty.call(message, "transcript"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.transcript);
                    if (message.confidence != null && Object.hasOwnProperty.call(message, "confidence"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.confidence);
                    return writer;
                };
    
                /**
                 * Encodes the specified SpeechToTextAlternative message, length delimited. Does not implicitly {@link infinitusai.be.SpeechToTextAlternative.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {infinitusai.be.ISpeechToTextAlternative} message SpeechToTextAlternative message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpeechToTextAlternative.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SpeechToTextAlternative message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SpeechToTextAlternative} SpeechToTextAlternative
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpeechToTextAlternative.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SpeechToTextAlternative();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.transcript = reader.string();
                                break;
                            }
                        case 2: {
                                message.confidence = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SpeechToTextAlternative message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SpeechToTextAlternative} SpeechToTextAlternative
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpeechToTextAlternative.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SpeechToTextAlternative message.
                 * @function verify
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpeechToTextAlternative.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transcript != null && message.hasOwnProperty("transcript"))
                        if (!$util.isString(message.transcript))
                            return "transcript: string expected";
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        if (typeof message.confidence !== "number")
                            return "confidence: number expected";
                    return null;
                };
    
                /**
                 * Creates a SpeechToTextAlternative message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SpeechToTextAlternative} SpeechToTextAlternative
                 */
                SpeechToTextAlternative.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SpeechToTextAlternative)
                        return object;
                    var message = new $root.infinitusai.be.SpeechToTextAlternative();
                    if (object.transcript != null)
                        message.transcript = String(object.transcript);
                    if (object.confidence != null)
                        message.confidence = Number(object.confidence);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SpeechToTextAlternative message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {infinitusai.be.SpeechToTextAlternative} message SpeechToTextAlternative
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpeechToTextAlternative.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.transcript = "";
                        object.confidence = 0;
                    }
                    if (message.transcript != null && message.hasOwnProperty("transcript"))
                        object.transcript = message.transcript;
                    if (message.confidence != null && message.hasOwnProperty("confidence"))
                        object.confidence = options.json && !isFinite(message.confidence) ? String(message.confidence) : message.confidence;
                    return object;
                };
    
                /**
                 * Converts this SpeechToTextAlternative to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpeechToTextAlternative.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SpeechToTextAlternative
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SpeechToTextAlternative
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpeechToTextAlternative.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SpeechToTextAlternative";
                };
    
                return SpeechToTextAlternative;
            })();
    
            be.SpeechToTextMetadata = (function() {
    
                /**
                 * Properties of a SpeechToTextMetadata.
                 * @memberof infinitusai.be
                 * @interface ISpeechToTextMetadata
                 * @property {boolean|null} [IsFinal] SpeechToTextMetadata IsFinal
                 * @property {number|null} [Stability] SpeechToTextMetadata Stability
                 */
    
                /**
                 * Constructs a new SpeechToTextMetadata.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SpeechToTextMetadata.
                 * @implements ISpeechToTextMetadata
                 * @constructor
                 * @param {infinitusai.be.ISpeechToTextMetadata=} [properties] Properties to set
                 */
                function SpeechToTextMetadata(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SpeechToTextMetadata IsFinal.
                 * @member {boolean} IsFinal
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @instance
                 */
                SpeechToTextMetadata.prototype.IsFinal = false;
    
                /**
                 * SpeechToTextMetadata Stability.
                 * @member {number} Stability
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @instance
                 */
                SpeechToTextMetadata.prototype.Stability = 0;
    
                /**
                 * Creates a new SpeechToTextMetadata instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {infinitusai.be.ISpeechToTextMetadata=} [properties] Properties to set
                 * @returns {infinitusai.be.SpeechToTextMetadata} SpeechToTextMetadata instance
                 */
                SpeechToTextMetadata.create = function create(properties) {
                    return new SpeechToTextMetadata(properties);
                };
    
                /**
                 * Encodes the specified SpeechToTextMetadata message. Does not implicitly {@link infinitusai.be.SpeechToTextMetadata.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {infinitusai.be.ISpeechToTextMetadata} message SpeechToTextMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpeechToTextMetadata.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.IsFinal != null && Object.hasOwnProperty.call(message, "IsFinal"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.IsFinal);
                    if (message.Stability != null && Object.hasOwnProperty.call(message, "Stability"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.Stability);
                    return writer;
                };
    
                /**
                 * Encodes the specified SpeechToTextMetadata message, length delimited. Does not implicitly {@link infinitusai.be.SpeechToTextMetadata.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {infinitusai.be.ISpeechToTextMetadata} message SpeechToTextMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpeechToTextMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SpeechToTextMetadata message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SpeechToTextMetadata} SpeechToTextMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpeechToTextMetadata.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SpeechToTextMetadata();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.IsFinal = reader.bool();
                                break;
                            }
                        case 2: {
                                message.Stability = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SpeechToTextMetadata message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SpeechToTextMetadata} SpeechToTextMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpeechToTextMetadata.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SpeechToTextMetadata message.
                 * @function verify
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpeechToTextMetadata.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.IsFinal != null && message.hasOwnProperty("IsFinal"))
                        if (typeof message.IsFinal !== "boolean")
                            return "IsFinal: boolean expected";
                    if (message.Stability != null && message.hasOwnProperty("Stability"))
                        if (typeof message.Stability !== "number")
                            return "Stability: number expected";
                    return null;
                };
    
                /**
                 * Creates a SpeechToTextMetadata message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SpeechToTextMetadata} SpeechToTextMetadata
                 */
                SpeechToTextMetadata.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SpeechToTextMetadata)
                        return object;
                    var message = new $root.infinitusai.be.SpeechToTextMetadata();
                    if (object.IsFinal != null)
                        message.IsFinal = Boolean(object.IsFinal);
                    if (object.Stability != null)
                        message.Stability = Number(object.Stability);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SpeechToTextMetadata message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {infinitusai.be.SpeechToTextMetadata} message SpeechToTextMetadata
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpeechToTextMetadata.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.IsFinal = false;
                        object.Stability = 0;
                    }
                    if (message.IsFinal != null && message.hasOwnProperty("IsFinal"))
                        object.IsFinal = message.IsFinal;
                    if (message.Stability != null && message.hasOwnProperty("Stability"))
                        object.Stability = options.json && !isFinite(message.Stability) ? String(message.Stability) : message.Stability;
                    return object;
                };
    
                /**
                 * Converts this SpeechToTextMetadata to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpeechToTextMetadata.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SpeechToTextMetadata
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SpeechToTextMetadata
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpeechToTextMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SpeechToTextMetadata";
                };
    
                return SpeechToTextMetadata;
            })();
    
            be.NlpABTestCohort = (function() {
    
                /**
                 * Properties of a NlpABTestCohort.
                 * @memberof infinitusai.be
                 * @interface INlpABTestCohort
                 * @property {string|null} [cohortName] NlpABTestCohort cohortName
                 * @property {Array.<string>|null} [experimentLabels] NlpABTestCohort experimentLabels
                 * @property {Object.<string,string>|null} [cohortParams] NlpABTestCohort cohortParams
                 */
    
                /**
                 * Constructs a new NlpABTestCohort.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NlpABTestCohort.
                 * @implements INlpABTestCohort
                 * @constructor
                 * @param {infinitusai.be.INlpABTestCohort=} [properties] Properties to set
                 */
                function NlpABTestCohort(properties) {
                    this.experimentLabels = [];
                    this.cohortParams = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NlpABTestCohort cohortName.
                 * @member {string} cohortName
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @instance
                 */
                NlpABTestCohort.prototype.cohortName = "";
    
                /**
                 * NlpABTestCohort experimentLabels.
                 * @member {Array.<string>} experimentLabels
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @instance
                 */
                NlpABTestCohort.prototype.experimentLabels = $util.emptyArray;
    
                /**
                 * NlpABTestCohort cohortParams.
                 * @member {Object.<string,string>} cohortParams
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @instance
                 */
                NlpABTestCohort.prototype.cohortParams = $util.emptyObject;
    
                /**
                 * Creates a new NlpABTestCohort instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {infinitusai.be.INlpABTestCohort=} [properties] Properties to set
                 * @returns {infinitusai.be.NlpABTestCohort} NlpABTestCohort instance
                 */
                NlpABTestCohort.create = function create(properties) {
                    return new NlpABTestCohort(properties);
                };
    
                /**
                 * Encodes the specified NlpABTestCohort message. Does not implicitly {@link infinitusai.be.NlpABTestCohort.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {infinitusai.be.INlpABTestCohort} message NlpABTestCohort message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpABTestCohort.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cohortName != null && Object.hasOwnProperty.call(message, "cohortName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cohortName);
                    if (message.experimentLabels != null && message.experimentLabels.length)
                        for (var i = 0; i < message.experimentLabels.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.experimentLabels[i]);
                    if (message.cohortParams != null && Object.hasOwnProperty.call(message, "cohortParams"))
                        for (var keys = Object.keys(message.cohortParams), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.cohortParams[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified NlpABTestCohort message, length delimited. Does not implicitly {@link infinitusai.be.NlpABTestCohort.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {infinitusai.be.INlpABTestCohort} message NlpABTestCohort message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpABTestCohort.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NlpABTestCohort message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NlpABTestCohort} NlpABTestCohort
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpABTestCohort.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NlpABTestCohort(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.cohortName = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.experimentLabels && message.experimentLabels.length))
                                    message.experimentLabels = [];
                                message.experimentLabels.push(reader.string());
                                break;
                            }
                        case 3: {
                                if (message.cohortParams === $util.emptyObject)
                                    message.cohortParams = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.cohortParams[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NlpABTestCohort message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NlpABTestCohort} NlpABTestCohort
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpABTestCohort.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NlpABTestCohort message.
                 * @function verify
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NlpABTestCohort.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cohortName != null && message.hasOwnProperty("cohortName"))
                        if (!$util.isString(message.cohortName))
                            return "cohortName: string expected";
                    if (message.experimentLabels != null && message.hasOwnProperty("experimentLabels")) {
                        if (!Array.isArray(message.experimentLabels))
                            return "experimentLabels: array expected";
                        for (var i = 0; i < message.experimentLabels.length; ++i)
                            if (!$util.isString(message.experimentLabels[i]))
                                return "experimentLabels: string[] expected";
                    }
                    if (message.cohortParams != null && message.hasOwnProperty("cohortParams")) {
                        if (!$util.isObject(message.cohortParams))
                            return "cohortParams: object expected";
                        var key = Object.keys(message.cohortParams);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.cohortParams[key[i]]))
                                return "cohortParams: string{k:string} expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a NlpABTestCohort message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NlpABTestCohort} NlpABTestCohort
                 */
                NlpABTestCohort.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NlpABTestCohort)
                        return object;
                    var message = new $root.infinitusai.be.NlpABTestCohort();
                    if (object.cohortName != null)
                        message.cohortName = String(object.cohortName);
                    if (object.experimentLabels) {
                        if (!Array.isArray(object.experimentLabels))
                            throw TypeError(".infinitusai.be.NlpABTestCohort.experimentLabels: array expected");
                        message.experimentLabels = [];
                        for (var i = 0; i < object.experimentLabels.length; ++i)
                            message.experimentLabels[i] = String(object.experimentLabels[i]);
                    }
                    if (object.cohortParams) {
                        if (typeof object.cohortParams !== "object")
                            throw TypeError(".infinitusai.be.NlpABTestCohort.cohortParams: object expected");
                        message.cohortParams = {};
                        for (var keys = Object.keys(object.cohortParams), i = 0; i < keys.length; ++i)
                            message.cohortParams[keys[i]] = String(object.cohortParams[keys[i]]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a NlpABTestCohort message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {infinitusai.be.NlpABTestCohort} message NlpABTestCohort
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NlpABTestCohort.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.experimentLabels = [];
                    if (options.objects || options.defaults)
                        object.cohortParams = {};
                    if (options.defaults)
                        object.cohortName = "";
                    if (message.cohortName != null && message.hasOwnProperty("cohortName"))
                        object.cohortName = message.cohortName;
                    if (message.experimentLabels && message.experimentLabels.length) {
                        object.experimentLabels = [];
                        for (var j = 0; j < message.experimentLabels.length; ++j)
                            object.experimentLabels[j] = message.experimentLabels[j];
                    }
                    var keys2;
                    if (message.cohortParams && (keys2 = Object.keys(message.cohortParams)).length) {
                        object.cohortParams = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.cohortParams[keys2[j]] = message.cohortParams[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this NlpABTestCohort to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NlpABTestCohort.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NlpABTestCohort
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NlpABTestCohort
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NlpABTestCohort.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NlpABTestCohort";
                };
    
                return NlpABTestCohort;
            })();
    
            be.GenerateResponseRequest = (function() {
    
                /**
                 * Properties of a GenerateResponseRequest.
                 * @memberof infinitusai.be
                 * @interface IGenerateResponseRequest
                 * @property {string|null} [conversationUuid] GenerateResponseRequest conversationUuid
                 * @property {Array.<infinitusai.be.ISpeechToTextAlternative>|null} [alternatives] GenerateResponseRequest alternatives
                 * @property {number|Long|null} [startTimeMillis] GenerateResponseRequest startTimeMillis
                 * @property {number|Long|null} [endTimeMillis] GenerateResponseRequest endTimeMillis
                 * @property {infinitusai.be.IBVCallInputs|null} [callInputs] GenerateResponseRequest callInputs
                 * @property {infinitusai.be.ICallState|null} [callState] GenerateResponseRequest callState
                 * @property {infinitusai.be.TaskType|null} [taskType] GenerateResponseRequest taskType
                 * @property {string|null} [callInputsJson] GenerateResponseRequest callInputsJson
                 * @property {infinitusai.be.ISpeechToTextMetadata|null} [sttMetadata] GenerateResponseRequest sttMetadata
                 * @property {string|null} [startDynamicRoutingAtDefault] GenerateResponseRequest startDynamicRoutingAtDefault
                 * @property {Object.<string,infinitusai.be.IStartDynamicRoutingAtABTestConfig>|null} [startDynamicRoutingAt_ABTestConfig] GenerateResponseRequest startDynamicRoutingAt_ABTestConfig
                 * @property {Array.<infinitusai.be.IModelPastInferences>|null} [audioModelPastInferences] GenerateResponseRequest audioModelPastInferences
                 * @property {Object.<string,infinitusai.be.INlpABTestCohort>|null} [abTestingCohorts] GenerateResponseRequest abTestingCohorts
                 * @property {string|null} [nlpTaskStateJson] GenerateResponseRequest nlpTaskStateJson
                 * @property {string|null} [requestUuid] GenerateResponseRequest requestUuid
                 * @property {infinitusai.be.GenerateResponseRequestType|null} [requestType] GenerateResponseRequest requestType
                 * @property {infinitusai.be.IChatLogRecords|null} [chatLogRecords] GenerateResponseRequest chatLogRecords
                 * @property {string|null} [taskUuid] GenerateResponseRequest taskUuid
                 */
    
                /**
                 * Constructs a new GenerateResponseRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateResponseRequest.
                 * @implements IGenerateResponseRequest
                 * @constructor
                 * @param {infinitusai.be.IGenerateResponseRequest=} [properties] Properties to set
                 */
                function GenerateResponseRequest(properties) {
                    this.alternatives = [];
                    this.startDynamicRoutingAt_ABTestConfig = {};
                    this.audioModelPastInferences = [];
                    this.abTestingCohorts = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateResponseRequest conversationUuid.
                 * @member {string} conversationUuid
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.conversationUuid = "";
    
                /**
                 * GenerateResponseRequest alternatives.
                 * @member {Array.<infinitusai.be.ISpeechToTextAlternative>} alternatives
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.alternatives = $util.emptyArray;
    
                /**
                 * GenerateResponseRequest startTimeMillis.
                 * @member {number|Long} startTimeMillis
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.startTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateResponseRequest endTimeMillis.
                 * @member {number|Long} endTimeMillis
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.endTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateResponseRequest callInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} callInputs
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.callInputs = null;
    
                /**
                 * GenerateResponseRequest callState.
                 * @member {infinitusai.be.ICallState|null|undefined} callState
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.callState = null;
    
                /**
                 * GenerateResponseRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.taskType = 0;
    
                /**
                 * GenerateResponseRequest callInputsJson.
                 * @member {string} callInputsJson
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.callInputsJson = "";
    
                /**
                 * GenerateResponseRequest sttMetadata.
                 * @member {infinitusai.be.ISpeechToTextMetadata|null|undefined} sttMetadata
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.sttMetadata = null;
    
                /**
                 * GenerateResponseRequest startDynamicRoutingAtDefault.
                 * @member {string} startDynamicRoutingAtDefault
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.startDynamicRoutingAtDefault = "";
    
                /**
                 * GenerateResponseRequest startDynamicRoutingAt_ABTestConfig.
                 * @member {Object.<string,infinitusai.be.IStartDynamicRoutingAtABTestConfig>} startDynamicRoutingAt_ABTestConfig
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.startDynamicRoutingAt_ABTestConfig = $util.emptyObject;
    
                /**
                 * GenerateResponseRequest audioModelPastInferences.
                 * @member {Array.<infinitusai.be.IModelPastInferences>} audioModelPastInferences
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.audioModelPastInferences = $util.emptyArray;
    
                /**
                 * GenerateResponseRequest abTestingCohorts.
                 * @member {Object.<string,infinitusai.be.INlpABTestCohort>} abTestingCohorts
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.abTestingCohorts = $util.emptyObject;
    
                /**
                 * GenerateResponseRequest nlpTaskStateJson.
                 * @member {string} nlpTaskStateJson
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.nlpTaskStateJson = "";
    
                /**
                 * GenerateResponseRequest requestUuid.
                 * @member {string} requestUuid
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.requestUuid = "";
    
                /**
                 * GenerateResponseRequest requestType.
                 * @member {infinitusai.be.GenerateResponseRequestType} requestType
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.requestType = 0;
    
                /**
                 * GenerateResponseRequest chatLogRecords.
                 * @member {infinitusai.be.IChatLogRecords|null|undefined} chatLogRecords
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.chatLogRecords = null;
    
                /**
                 * GenerateResponseRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 */
                GenerateResponseRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new GenerateResponseRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {infinitusai.be.IGenerateResponseRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateResponseRequest} GenerateResponseRequest instance
                 */
                GenerateResponseRequest.create = function create(properties) {
                    return new GenerateResponseRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateResponseRequest message. Does not implicitly {@link infinitusai.be.GenerateResponseRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {infinitusai.be.IGenerateResponseRequest} message GenerateResponseRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateResponseRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversationUuid != null && Object.hasOwnProperty.call(message, "conversationUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationUuid);
                    if (message.alternatives != null && message.alternatives.length)
                        for (var i = 0; i < message.alternatives.length; ++i)
                            $root.infinitusai.be.SpeechToTextAlternative.encode(message.alternatives[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.startTimeMillis != null && Object.hasOwnProperty.call(message, "startTimeMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.startTimeMillis);
                    if (message.endTimeMillis != null && Object.hasOwnProperty.call(message, "endTimeMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.endTimeMillis);
                    if (message.callInputs != null && Object.hasOwnProperty.call(message, "callInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.callInputs, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.callState != null && Object.hasOwnProperty.call(message, "callState"))
                        $root.infinitusai.be.CallState.encode(message.callState, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.taskType);
                    if (message.callInputsJson != null && Object.hasOwnProperty.call(message, "callInputsJson"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.callInputsJson);
                    if (message.sttMetadata != null && Object.hasOwnProperty.call(message, "sttMetadata"))
                        $root.infinitusai.be.SpeechToTextMetadata.encode(message.sttMetadata, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.startDynamicRoutingAtDefault != null && Object.hasOwnProperty.call(message, "startDynamicRoutingAtDefault"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.startDynamicRoutingAtDefault);
                    if (message.startDynamicRoutingAt_ABTestConfig != null && Object.hasOwnProperty.call(message, "startDynamicRoutingAt_ABTestConfig"))
                        for (var keys = Object.keys(message.startDynamicRoutingAt_ABTestConfig), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.encode(message.startDynamicRoutingAt_ABTestConfig[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.audioModelPastInferences != null && message.audioModelPastInferences.length)
                        for (var i = 0; i < message.audioModelPastInferences.length; ++i)
                            $root.infinitusai.be.ModelPastInferences.encode(message.audioModelPastInferences[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.abTestingCohorts != null && Object.hasOwnProperty.call(message, "abTestingCohorts"))
                        for (var keys = Object.keys(message.abTestingCohorts), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 13, wireType 2 =*/106).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.NlpABTestCohort.encode(message.abTestingCohorts[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.nlpTaskStateJson != null && Object.hasOwnProperty.call(message, "nlpTaskStateJson"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.nlpTaskStateJson);
                    if (message.requestUuid != null && Object.hasOwnProperty.call(message, "requestUuid"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.requestUuid);
                    if (message.requestType != null && Object.hasOwnProperty.call(message, "requestType"))
                        writer.uint32(/* id 16, wireType 0 =*/128).int32(message.requestType);
                    if (message.chatLogRecords != null && Object.hasOwnProperty.call(message, "chatLogRecords"))
                        $root.infinitusai.be.ChatLogRecords.encode(message.chatLogRecords, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateResponseRequest message, length delimited. Does not implicitly {@link infinitusai.be.GenerateResponseRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {infinitusai.be.IGenerateResponseRequest} message GenerateResponseRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateResponseRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateResponseRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateResponseRequest} GenerateResponseRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateResponseRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateResponseRequest(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversationUuid = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.alternatives && message.alternatives.length))
                                    message.alternatives = [];
                                message.alternatives.push($root.infinitusai.be.SpeechToTextAlternative.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.startTimeMillis = reader.int64();
                                break;
                            }
                        case 4: {
                                message.endTimeMillis = reader.int64();
                                break;
                            }
                        case 5: {
                                message.callInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.callState = $root.infinitusai.be.CallState.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 8: {
                                message.callInputsJson = reader.string();
                                break;
                            }
                        case 9: {
                                message.sttMetadata = $root.infinitusai.be.SpeechToTextMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.startDynamicRoutingAtDefault = reader.string();
                                break;
                            }
                        case 11: {
                                if (message.startDynamicRoutingAt_ABTestConfig === $util.emptyObject)
                                    message.startDynamicRoutingAt_ABTestConfig = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.startDynamicRoutingAt_ABTestConfig[key] = value;
                                break;
                            }
                        case 12: {
                                if (!(message.audioModelPastInferences && message.audioModelPastInferences.length))
                                    message.audioModelPastInferences = [];
                                message.audioModelPastInferences.push($root.infinitusai.be.ModelPastInferences.decode(reader, reader.uint32()));
                                break;
                            }
                        case 13: {
                                if (message.abTestingCohorts === $util.emptyObject)
                                    message.abTestingCohorts = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.NlpABTestCohort.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.abTestingCohorts[key] = value;
                                break;
                            }
                        case 14: {
                                message.nlpTaskStateJson = reader.string();
                                break;
                            }
                        case 15: {
                                message.requestUuid = reader.string();
                                break;
                            }
                        case 16: {
                                message.requestType = reader.int32();
                                break;
                            }
                        case 17: {
                                message.chatLogRecords = $root.infinitusai.be.ChatLogRecords.decode(reader, reader.uint32());
                                break;
                            }
                        case 18: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateResponseRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateResponseRequest} GenerateResponseRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateResponseRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateResponseRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateResponseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        if (!$util.isString(message.conversationUuid))
                            return "conversationUuid: string expected";
                    if (message.alternatives != null && message.hasOwnProperty("alternatives")) {
                        if (!Array.isArray(message.alternatives))
                            return "alternatives: array expected";
                        for (var i = 0; i < message.alternatives.length; ++i) {
                            var error = $root.infinitusai.be.SpeechToTextAlternative.verify(message.alternatives[i]);
                            if (error)
                                return "alternatives." + error;
                        }
                    }
                    if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                        if (!$util.isInteger(message.startTimeMillis) && !(message.startTimeMillis && $util.isInteger(message.startTimeMillis.low) && $util.isInteger(message.startTimeMillis.high)))
                            return "startTimeMillis: integer|Long expected";
                    if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                        if (!$util.isInteger(message.endTimeMillis) && !(message.endTimeMillis && $util.isInteger(message.endTimeMillis.low) && $util.isInteger(message.endTimeMillis.high)))
                            return "endTimeMillis: integer|Long expected";
                    if (message.callInputs != null && message.hasOwnProperty("callInputs")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.callInputs);
                        if (error)
                            return "callInputs." + error;
                    }
                    if (message.callState != null && message.hasOwnProperty("callState")) {
                        var error = $root.infinitusai.be.CallState.verify(message.callState);
                        if (error)
                            return "callState." + error;
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.callInputsJson != null && message.hasOwnProperty("callInputsJson"))
                        if (!$util.isString(message.callInputsJson))
                            return "callInputsJson: string expected";
                    if (message.sttMetadata != null && message.hasOwnProperty("sttMetadata")) {
                        var error = $root.infinitusai.be.SpeechToTextMetadata.verify(message.sttMetadata);
                        if (error)
                            return "sttMetadata." + error;
                    }
                    if (message.startDynamicRoutingAtDefault != null && message.hasOwnProperty("startDynamicRoutingAtDefault"))
                        if (!$util.isString(message.startDynamicRoutingAtDefault))
                            return "startDynamicRoutingAtDefault: string expected";
                    if (message.startDynamicRoutingAt_ABTestConfig != null && message.hasOwnProperty("startDynamicRoutingAt_ABTestConfig")) {
                        if (!$util.isObject(message.startDynamicRoutingAt_ABTestConfig))
                            return "startDynamicRoutingAt_ABTestConfig: object expected";
                        var key = Object.keys(message.startDynamicRoutingAt_ABTestConfig);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.verify(message.startDynamicRoutingAt_ABTestConfig[key[i]]);
                            if (error)
                                return "startDynamicRoutingAt_ABTestConfig." + error;
                        }
                    }
                    if (message.audioModelPastInferences != null && message.hasOwnProperty("audioModelPastInferences")) {
                        if (!Array.isArray(message.audioModelPastInferences))
                            return "audioModelPastInferences: array expected";
                        for (var i = 0; i < message.audioModelPastInferences.length; ++i) {
                            var error = $root.infinitusai.be.ModelPastInferences.verify(message.audioModelPastInferences[i]);
                            if (error)
                                return "audioModelPastInferences." + error;
                        }
                    }
                    if (message.abTestingCohorts != null && message.hasOwnProperty("abTestingCohorts")) {
                        if (!$util.isObject(message.abTestingCohorts))
                            return "abTestingCohorts: object expected";
                        var key = Object.keys(message.abTestingCohorts);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.NlpABTestCohort.verify(message.abTestingCohorts[key[i]]);
                            if (error)
                                return "abTestingCohorts." + error;
                        }
                    }
                    if (message.nlpTaskStateJson != null && message.hasOwnProperty("nlpTaskStateJson"))
                        if (!$util.isString(message.nlpTaskStateJson))
                            return "nlpTaskStateJson: string expected";
                    if (message.requestUuid != null && message.hasOwnProperty("requestUuid"))
                        if (!$util.isString(message.requestUuid))
                            return "requestUuid: string expected";
                    if (message.requestType != null && message.hasOwnProperty("requestType"))
                        switch (message.requestType) {
                        default:
                            return "requestType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.chatLogRecords != null && message.hasOwnProperty("chatLogRecords")) {
                        var error = $root.infinitusai.be.ChatLogRecords.verify(message.chatLogRecords);
                        if (error)
                            return "chatLogRecords." + error;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateResponseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateResponseRequest} GenerateResponseRequest
                 */
                GenerateResponseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateResponseRequest)
                        return object;
                    var message = new $root.infinitusai.be.GenerateResponseRequest();
                    if (object.conversationUuid != null)
                        message.conversationUuid = String(object.conversationUuid);
                    if (object.alternatives) {
                        if (!Array.isArray(object.alternatives))
                            throw TypeError(".infinitusai.be.GenerateResponseRequest.alternatives: array expected");
                        message.alternatives = [];
                        for (var i = 0; i < object.alternatives.length; ++i) {
                            if (typeof object.alternatives[i] !== "object")
                                throw TypeError(".infinitusai.be.GenerateResponseRequest.alternatives: object expected");
                            message.alternatives[i] = $root.infinitusai.be.SpeechToTextAlternative.fromObject(object.alternatives[i]);
                        }
                    }
                    if (object.startTimeMillis != null)
                        if ($util.Long)
                            (message.startTimeMillis = $util.Long.fromValue(object.startTimeMillis)).unsigned = false;
                        else if (typeof object.startTimeMillis === "string")
                            message.startTimeMillis = parseInt(object.startTimeMillis, 10);
                        else if (typeof object.startTimeMillis === "number")
                            message.startTimeMillis = object.startTimeMillis;
                        else if (typeof object.startTimeMillis === "object")
                            message.startTimeMillis = new $util.LongBits(object.startTimeMillis.low >>> 0, object.startTimeMillis.high >>> 0).toNumber();
                    if (object.endTimeMillis != null)
                        if ($util.Long)
                            (message.endTimeMillis = $util.Long.fromValue(object.endTimeMillis)).unsigned = false;
                        else if (typeof object.endTimeMillis === "string")
                            message.endTimeMillis = parseInt(object.endTimeMillis, 10);
                        else if (typeof object.endTimeMillis === "number")
                            message.endTimeMillis = object.endTimeMillis;
                        else if (typeof object.endTimeMillis === "object")
                            message.endTimeMillis = new $util.LongBits(object.endTimeMillis.low >>> 0, object.endTimeMillis.high >>> 0).toNumber();
                    if (object.callInputs != null) {
                        if (typeof object.callInputs !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseRequest.callInputs: object expected");
                        message.callInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.callInputs);
                    }
                    if (object.callState != null) {
                        if (typeof object.callState !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseRequest.callState: object expected");
                        message.callState = $root.infinitusai.be.CallState.fromObject(object.callState);
                    }
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.callInputsJson != null)
                        message.callInputsJson = String(object.callInputsJson);
                    if (object.sttMetadata != null) {
                        if (typeof object.sttMetadata !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseRequest.sttMetadata: object expected");
                        message.sttMetadata = $root.infinitusai.be.SpeechToTextMetadata.fromObject(object.sttMetadata);
                    }
                    if (object.startDynamicRoutingAtDefault != null)
                        message.startDynamicRoutingAtDefault = String(object.startDynamicRoutingAtDefault);
                    if (object.startDynamicRoutingAt_ABTestConfig) {
                        if (typeof object.startDynamicRoutingAt_ABTestConfig !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseRequest.startDynamicRoutingAt_ABTestConfig: object expected");
                        message.startDynamicRoutingAt_ABTestConfig = {};
                        for (var keys = Object.keys(object.startDynamicRoutingAt_ABTestConfig), i = 0; i < keys.length; ++i) {
                            if (typeof object.startDynamicRoutingAt_ABTestConfig[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.GenerateResponseRequest.startDynamicRoutingAt_ABTestConfig: object expected");
                            message.startDynamicRoutingAt_ABTestConfig[keys[i]] = $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.fromObject(object.startDynamicRoutingAt_ABTestConfig[keys[i]]);
                        }
                    }
                    if (object.audioModelPastInferences) {
                        if (!Array.isArray(object.audioModelPastInferences))
                            throw TypeError(".infinitusai.be.GenerateResponseRequest.audioModelPastInferences: array expected");
                        message.audioModelPastInferences = [];
                        for (var i = 0; i < object.audioModelPastInferences.length; ++i) {
                            if (typeof object.audioModelPastInferences[i] !== "object")
                                throw TypeError(".infinitusai.be.GenerateResponseRequest.audioModelPastInferences: object expected");
                            message.audioModelPastInferences[i] = $root.infinitusai.be.ModelPastInferences.fromObject(object.audioModelPastInferences[i]);
                        }
                    }
                    if (object.abTestingCohorts) {
                        if (typeof object.abTestingCohorts !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseRequest.abTestingCohorts: object expected");
                        message.abTestingCohorts = {};
                        for (var keys = Object.keys(object.abTestingCohorts), i = 0; i < keys.length; ++i) {
                            if (typeof object.abTestingCohorts[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.GenerateResponseRequest.abTestingCohorts: object expected");
                            message.abTestingCohorts[keys[i]] = $root.infinitusai.be.NlpABTestCohort.fromObject(object.abTestingCohorts[keys[i]]);
                        }
                    }
                    if (object.nlpTaskStateJson != null)
                        message.nlpTaskStateJson = String(object.nlpTaskStateJson);
                    if (object.requestUuid != null)
                        message.requestUuid = String(object.requestUuid);
                    switch (object.requestType) {
                    default:
                        if (typeof object.requestType === "number") {
                            message.requestType = object.requestType;
                            break;
                        }
                        break;
                    case "UNKNOWN_NLP_REQUEST_TYPE":
                    case 0:
                        message.requestType = 0;
                        break;
                    case "FINALIZED_STT":
                    case 1:
                        message.requestType = 1;
                        break;
                    case "AUDIO_CNN_MODEL_CLASSIFICATION":
                    case 2:
                        message.requestType = 2;
                        break;
                    }
                    if (object.chatLogRecords != null) {
                        if (typeof object.chatLogRecords !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseRequest.chatLogRecords: object expected");
                        message.chatLogRecords = $root.infinitusai.be.ChatLogRecords.fromObject(object.chatLogRecords);
                    }
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateResponseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {infinitusai.be.GenerateResponseRequest} message GenerateResponseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateResponseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.alternatives = [];
                        object.audioModelPastInferences = [];
                    }
                    if (options.objects || options.defaults) {
                        object.startDynamicRoutingAt_ABTestConfig = {};
                        object.abTestingCohorts = {};
                    }
                    if (options.defaults) {
                        object.conversationUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMillis = options.longs === String ? "0" : 0;
                        object.callInputs = null;
                        object.callState = null;
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.callInputsJson = "";
                        object.sttMetadata = null;
                        object.startDynamicRoutingAtDefault = "";
                        object.nlpTaskStateJson = "";
                        object.requestUuid = "";
                        object.requestType = options.enums === String ? "UNKNOWN_NLP_REQUEST_TYPE" : 0;
                        object.chatLogRecords = null;
                        object.taskUuid = "";
                    }
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        object.conversationUuid = message.conversationUuid;
                    if (message.alternatives && message.alternatives.length) {
                        object.alternatives = [];
                        for (var j = 0; j < message.alternatives.length; ++j)
                            object.alternatives[j] = $root.infinitusai.be.SpeechToTextAlternative.toObject(message.alternatives[j], options);
                    }
                    if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                        if (typeof message.startTimeMillis === "number")
                            object.startTimeMillis = options.longs === String ? String(message.startTimeMillis) : message.startTimeMillis;
                        else
                            object.startTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMillis) : options.longs === Number ? new $util.LongBits(message.startTimeMillis.low >>> 0, message.startTimeMillis.high >>> 0).toNumber() : message.startTimeMillis;
                    if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                        if (typeof message.endTimeMillis === "number")
                            object.endTimeMillis = options.longs === String ? String(message.endTimeMillis) : message.endTimeMillis;
                        else
                            object.endTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMillis) : options.longs === Number ? new $util.LongBits(message.endTimeMillis.low >>> 0, message.endTimeMillis.high >>> 0).toNumber() : message.endTimeMillis;
                    if (message.callInputs != null && message.hasOwnProperty("callInputs"))
                        object.callInputs = $root.infinitusai.be.BVCallInputs.toObject(message.callInputs, options);
                    if (message.callState != null && message.hasOwnProperty("callState"))
                        object.callState = $root.infinitusai.be.CallState.toObject(message.callState, options);
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.callInputsJson != null && message.hasOwnProperty("callInputsJson"))
                        object.callInputsJson = message.callInputsJson;
                    if (message.sttMetadata != null && message.hasOwnProperty("sttMetadata"))
                        object.sttMetadata = $root.infinitusai.be.SpeechToTextMetadata.toObject(message.sttMetadata, options);
                    if (message.startDynamicRoutingAtDefault != null && message.hasOwnProperty("startDynamicRoutingAtDefault"))
                        object.startDynamicRoutingAtDefault = message.startDynamicRoutingAtDefault;
                    var keys2;
                    if (message.startDynamicRoutingAt_ABTestConfig && (keys2 = Object.keys(message.startDynamicRoutingAt_ABTestConfig)).length) {
                        object.startDynamicRoutingAt_ABTestConfig = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.startDynamicRoutingAt_ABTestConfig[keys2[j]] = $root.infinitusai.be.StartDynamicRoutingAtABTestConfig.toObject(message.startDynamicRoutingAt_ABTestConfig[keys2[j]], options);
                    }
                    if (message.audioModelPastInferences && message.audioModelPastInferences.length) {
                        object.audioModelPastInferences = [];
                        for (var j = 0; j < message.audioModelPastInferences.length; ++j)
                            object.audioModelPastInferences[j] = $root.infinitusai.be.ModelPastInferences.toObject(message.audioModelPastInferences[j], options);
                    }
                    if (message.abTestingCohorts && (keys2 = Object.keys(message.abTestingCohorts)).length) {
                        object.abTestingCohorts = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.abTestingCohorts[keys2[j]] = $root.infinitusai.be.NlpABTestCohort.toObject(message.abTestingCohorts[keys2[j]], options);
                    }
                    if (message.nlpTaskStateJson != null && message.hasOwnProperty("nlpTaskStateJson"))
                        object.nlpTaskStateJson = message.nlpTaskStateJson;
                    if (message.requestUuid != null && message.hasOwnProperty("requestUuid"))
                        object.requestUuid = message.requestUuid;
                    if (message.requestType != null && message.hasOwnProperty("requestType"))
                        object.requestType = options.enums === String ? $root.infinitusai.be.GenerateResponseRequestType[message.requestType] === undefined ? message.requestType : $root.infinitusai.be.GenerateResponseRequestType[message.requestType] : message.requestType;
                    if (message.chatLogRecords != null && message.hasOwnProperty("chatLogRecords"))
                        object.chatLogRecords = $root.infinitusai.be.ChatLogRecords.toObject(message.chatLogRecords, options);
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this GenerateResponseRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateResponseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateResponseRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateResponseRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateResponseRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateResponseRequest";
                };
    
                return GenerateResponseRequest;
            })();
    
            /**
             * GenerateResponseRequestType enum.
             * @name infinitusai.be.GenerateResponseRequestType
             * @enum {number}
             * @property {number} UNKNOWN_NLP_REQUEST_TYPE=0 UNKNOWN_NLP_REQUEST_TYPE value
             * @property {number} FINALIZED_STT=1 FINALIZED_STT value
             * @property {number} AUDIO_CNN_MODEL_CLASSIFICATION=2 AUDIO_CNN_MODEL_CLASSIFICATION value
             */
            be.GenerateResponseRequestType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_NLP_REQUEST_TYPE"] = 0;
                values[valuesById[1] = "FINALIZED_STT"] = 1;
                values[valuesById[2] = "AUDIO_CNN_MODEL_CLASSIFICATION"] = 2;
                return values;
            })();
    
            be.GenerateResponseOption = (function() {
    
                /**
                 * Properties of a GenerateResponseOption.
                 * @memberof infinitusai.be
                 * @interface IGenerateResponseOption
                 * @property {string|null} [speechUtterance] GenerateResponseOption speechUtterance
                 * @property {string|null} [dtmfDigits] GenerateResponseOption dtmfDigits
                 * @property {string|null} [intentName] GenerateResponseOption intentName
                 * @property {infinitusai.be.SuggestionCategory|null} [suggestionCategory] GenerateResponseOption suggestionCategory
                 * @property {Array.<string>|null} [protoPath] GenerateResponseOption protoPath
                 * @property {boolean|null} [popularSuggestion] GenerateResponseOption popularSuggestion
                 * @property {boolean|null} [stopRecordingSuggestion] GenerateResponseOption stopRecordingSuggestion
                 * @property {Array.<infinitusai.be.IFollowupSuggestion>|null} [followupSuggestions] GenerateResponseOption followupSuggestions
                 * @property {string|null} [suggestionId] GenerateResponseOption suggestionId
                 * @property {boolean|null} [hangupAndRequeue] GenerateResponseOption hangupAndRequeue
                 * @property {boolean|null} [isCallSpecific] GenerateResponseOption isCallSpecific
                 * @property {string|null} [modelId] GenerateResponseOption modelId
                 * @property {infinitusai.be.SuggestionSubcategory|null} [suggestionSubcategory] GenerateResponseOption suggestionSubcategory
                 * @property {boolean|null} [shouldCapturePayerIntelligenceState] GenerateResponseOption shouldCapturePayerIntelligenceState
                 * @property {string|null} [fallbackSpeechUtterance] GenerateResponseOption fallbackSpeechUtterance
                 * @property {string|null} [actionName] GenerateResponseOption actionName
                 * @property {Array.<string>|null} [actionNames] GenerateResponseOption actionNames
                 * @property {infinitusai.be.NlpExecutionFollowup|null} [followup] GenerateResponseOption followup
                 * @property {string|null} [taskNoteUpdate] GenerateResponseOption taskNoteUpdate
                 * @property {number|null} [pingTimer] GenerateResponseOption pingTimer
                 * @property {string|null} [logs] GenerateResponseOption logs
                 * @property {infinitusai.be.INullableBool|null} [startRoutingOperator] GenerateResponseOption startRoutingOperator
                 * @property {string|null} [nlpLogs] GenerateResponseOption nlpLogs
                 * @property {infinitusai.be.RequeueReason.Type|null} [hangupReason] GenerateResponseOption hangupReason
                 * @property {string|null} [requeueNumber] GenerateResponseOption requeueNumber
                 */
    
                /**
                 * Constructs a new GenerateResponseOption.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateResponseOption.
                 * @implements IGenerateResponseOption
                 * @constructor
                 * @param {infinitusai.be.IGenerateResponseOption=} [properties] Properties to set
                 */
                function GenerateResponseOption(properties) {
                    this.protoPath = [];
                    this.followupSuggestions = [];
                    this.actionNames = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateResponseOption speechUtterance.
                 * @member {string|null|undefined} speechUtterance
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.speechUtterance = null;
    
                /**
                 * GenerateResponseOption dtmfDigits.
                 * @member {string|null|undefined} dtmfDigits
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.dtmfDigits = null;
    
                /**
                 * GenerateResponseOption intentName.
                 * @member {string} intentName
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.intentName = "";
    
                /**
                 * GenerateResponseOption suggestionCategory.
                 * @member {infinitusai.be.SuggestionCategory} suggestionCategory
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.suggestionCategory = 0;
    
                /**
                 * GenerateResponseOption protoPath.
                 * @member {Array.<string>} protoPath
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.protoPath = $util.emptyArray;
    
                /**
                 * GenerateResponseOption popularSuggestion.
                 * @member {boolean} popularSuggestion
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.popularSuggestion = false;
    
                /**
                 * GenerateResponseOption stopRecordingSuggestion.
                 * @member {boolean} stopRecordingSuggestion
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.stopRecordingSuggestion = false;
    
                /**
                 * GenerateResponseOption followupSuggestions.
                 * @member {Array.<infinitusai.be.IFollowupSuggestion>} followupSuggestions
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.followupSuggestions = $util.emptyArray;
    
                /**
                 * GenerateResponseOption suggestionId.
                 * @member {string} suggestionId
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.suggestionId = "";
    
                /**
                 * GenerateResponseOption hangupAndRequeue.
                 * @member {boolean} hangupAndRequeue
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.hangupAndRequeue = false;
    
                /**
                 * GenerateResponseOption isCallSpecific.
                 * @member {boolean} isCallSpecific
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.isCallSpecific = false;
    
                /**
                 * GenerateResponseOption modelId.
                 * @member {string} modelId
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.modelId = "";
    
                /**
                 * GenerateResponseOption suggestionSubcategory.
                 * @member {infinitusai.be.SuggestionSubcategory} suggestionSubcategory
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.suggestionSubcategory = 0;
    
                /**
                 * GenerateResponseOption shouldCapturePayerIntelligenceState.
                 * @member {boolean} shouldCapturePayerIntelligenceState
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.shouldCapturePayerIntelligenceState = false;
    
                /**
                 * GenerateResponseOption fallbackSpeechUtterance.
                 * @member {string} fallbackSpeechUtterance
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.fallbackSpeechUtterance = "";
    
                /**
                 * GenerateResponseOption actionName.
                 * @member {string} actionName
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.actionName = "";
    
                /**
                 * GenerateResponseOption actionNames.
                 * @member {Array.<string>} actionNames
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.actionNames = $util.emptyArray;
    
                /**
                 * GenerateResponseOption followup.
                 * @member {infinitusai.be.NlpExecutionFollowup} followup
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.followup = 0;
    
                /**
                 * GenerateResponseOption taskNoteUpdate.
                 * @member {string} taskNoteUpdate
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.taskNoteUpdate = "";
    
                /**
                 * GenerateResponseOption pingTimer.
                 * @member {number} pingTimer
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.pingTimer = 0;
    
                /**
                 * GenerateResponseOption logs.
                 * @member {string} logs
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.logs = "";
    
                /**
                 * GenerateResponseOption startRoutingOperator.
                 * @member {infinitusai.be.INullableBool|null|undefined} startRoutingOperator
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.startRoutingOperator = null;
    
                /**
                 * GenerateResponseOption nlpLogs.
                 * @member {string} nlpLogs
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.nlpLogs = "";
    
                /**
                 * GenerateResponseOption hangupReason.
                 * @member {infinitusai.be.RequeueReason.Type} hangupReason
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.hangupReason = 0;
    
                /**
                 * GenerateResponseOption requeueNumber.
                 * @member {string} requeueNumber
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                GenerateResponseOption.prototype.requeueNumber = "";
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * GenerateResponseOption action.
                 * @member {"speechUtterance"|"dtmfDigits"|undefined} action
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 */
                Object.defineProperty(GenerateResponseOption.prototype, "action", {
                    get: $util.oneOfGetter($oneOfFields = ["speechUtterance", "dtmfDigits"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new GenerateResponseOption instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {infinitusai.be.IGenerateResponseOption=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateResponseOption} GenerateResponseOption instance
                 */
                GenerateResponseOption.create = function create(properties) {
                    return new GenerateResponseOption(properties);
                };
    
                /**
                 * Encodes the specified GenerateResponseOption message. Does not implicitly {@link infinitusai.be.GenerateResponseOption.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {infinitusai.be.IGenerateResponseOption} message GenerateResponseOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateResponseOption.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.speechUtterance != null && Object.hasOwnProperty.call(message, "speechUtterance"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.speechUtterance);
                    if (message.dtmfDigits != null && Object.hasOwnProperty.call(message, "dtmfDigits"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dtmfDigits);
                    if (message.intentName != null && Object.hasOwnProperty.call(message, "intentName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.intentName);
                    if (message.suggestionCategory != null && Object.hasOwnProperty.call(message, "suggestionCategory"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.suggestionCategory);
                    if (message.protoPath != null && message.protoPath.length)
                        for (var i = 0; i < message.protoPath.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.protoPath[i]);
                    if (message.popularSuggestion != null && Object.hasOwnProperty.call(message, "popularSuggestion"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.popularSuggestion);
                    if (message.stopRecordingSuggestion != null && Object.hasOwnProperty.call(message, "stopRecordingSuggestion"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.stopRecordingSuggestion);
                    if (message.followupSuggestions != null && message.followupSuggestions.length)
                        for (var i = 0; i < message.followupSuggestions.length; ++i)
                            $root.infinitusai.be.FollowupSuggestion.encode(message.followupSuggestions[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.suggestionId != null && Object.hasOwnProperty.call(message, "suggestionId"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.suggestionId);
                    if (message.hangupAndRequeue != null && Object.hasOwnProperty.call(message, "hangupAndRequeue"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.hangupAndRequeue);
                    if (message.isCallSpecific != null && Object.hasOwnProperty.call(message, "isCallSpecific"))
                        writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isCallSpecific);
                    if (message.modelId != null && Object.hasOwnProperty.call(message, "modelId"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.modelId);
                    if (message.suggestionSubcategory != null && Object.hasOwnProperty.call(message, "suggestionSubcategory"))
                        writer.uint32(/* id 13, wireType 0 =*/104).int32(message.suggestionSubcategory);
                    if (message.shouldCapturePayerIntelligenceState != null && Object.hasOwnProperty.call(message, "shouldCapturePayerIntelligenceState"))
                        writer.uint32(/* id 14, wireType 0 =*/112).bool(message.shouldCapturePayerIntelligenceState);
                    if (message.fallbackSpeechUtterance != null && Object.hasOwnProperty.call(message, "fallbackSpeechUtterance"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.fallbackSpeechUtterance);
                    if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.actionName);
                    if (message.followup != null && Object.hasOwnProperty.call(message, "followup"))
                        writer.uint32(/* id 17, wireType 0 =*/136).int32(message.followup);
                    if (message.taskNoteUpdate != null && Object.hasOwnProperty.call(message, "taskNoteUpdate"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.taskNoteUpdate);
                    if (message.pingTimer != null && Object.hasOwnProperty.call(message, "pingTimer"))
                        writer.uint32(/* id 19, wireType 5 =*/157).float(message.pingTimer);
                    if (message.logs != null && Object.hasOwnProperty.call(message, "logs"))
                        writer.uint32(/* id 20, wireType 2 =*/162).string(message.logs);
                    if (message.startRoutingOperator != null && Object.hasOwnProperty.call(message, "startRoutingOperator"))
                        $root.infinitusai.be.NullableBool.encode(message.startRoutingOperator, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    if (message.nlpLogs != null && Object.hasOwnProperty.call(message, "nlpLogs"))
                        writer.uint32(/* id 22, wireType 2 =*/178).string(message.nlpLogs);
                    if (message.hangupReason != null && Object.hasOwnProperty.call(message, "hangupReason"))
                        writer.uint32(/* id 23, wireType 0 =*/184).int32(message.hangupReason);
                    if (message.requeueNumber != null && Object.hasOwnProperty.call(message, "requeueNumber"))
                        writer.uint32(/* id 24, wireType 2 =*/194).string(message.requeueNumber);
                    if (message.actionNames != null && message.actionNames.length)
                        for (var i = 0; i < message.actionNames.length; ++i)
                            writer.uint32(/* id 25, wireType 2 =*/202).string(message.actionNames[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateResponseOption message, length delimited. Does not implicitly {@link infinitusai.be.GenerateResponseOption.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {infinitusai.be.IGenerateResponseOption} message GenerateResponseOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateResponseOption.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateResponseOption message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateResponseOption} GenerateResponseOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateResponseOption.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateResponseOption();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.speechUtterance = reader.string();
                                break;
                            }
                        case 2: {
                                message.dtmfDigits = reader.string();
                                break;
                            }
                        case 3: {
                                message.intentName = reader.string();
                                break;
                            }
                        case 4: {
                                message.suggestionCategory = reader.int32();
                                break;
                            }
                        case 5: {
                                if (!(message.protoPath && message.protoPath.length))
                                    message.protoPath = [];
                                message.protoPath.push(reader.string());
                                break;
                            }
                        case 6: {
                                message.popularSuggestion = reader.bool();
                                break;
                            }
                        case 7: {
                                message.stopRecordingSuggestion = reader.bool();
                                break;
                            }
                        case 8: {
                                if (!(message.followupSuggestions && message.followupSuggestions.length))
                                    message.followupSuggestions = [];
                                message.followupSuggestions.push($root.infinitusai.be.FollowupSuggestion.decode(reader, reader.uint32()));
                                break;
                            }
                        case 9: {
                                message.suggestionId = reader.string();
                                break;
                            }
                        case 10: {
                                message.hangupAndRequeue = reader.bool();
                                break;
                            }
                        case 11: {
                                message.isCallSpecific = reader.bool();
                                break;
                            }
                        case 12: {
                                message.modelId = reader.string();
                                break;
                            }
                        case 13: {
                                message.suggestionSubcategory = reader.int32();
                                break;
                            }
                        case 14: {
                                message.shouldCapturePayerIntelligenceState = reader.bool();
                                break;
                            }
                        case 15: {
                                message.fallbackSpeechUtterance = reader.string();
                                break;
                            }
                        case 16: {
                                message.actionName = reader.string();
                                break;
                            }
                        case 25: {
                                if (!(message.actionNames && message.actionNames.length))
                                    message.actionNames = [];
                                message.actionNames.push(reader.string());
                                break;
                            }
                        case 17: {
                                message.followup = reader.int32();
                                break;
                            }
                        case 18: {
                                message.taskNoteUpdate = reader.string();
                                break;
                            }
                        case 19: {
                                message.pingTimer = reader.float();
                                break;
                            }
                        case 20: {
                                message.logs = reader.string();
                                break;
                            }
                        case 21: {
                                message.startRoutingOperator = $root.infinitusai.be.NullableBool.decode(reader, reader.uint32());
                                break;
                            }
                        case 22: {
                                message.nlpLogs = reader.string();
                                break;
                            }
                        case 23: {
                                message.hangupReason = reader.int32();
                                break;
                            }
                        case 24: {
                                message.requeueNumber = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateResponseOption message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateResponseOption} GenerateResponseOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateResponseOption.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateResponseOption message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateResponseOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.speechUtterance != null && message.hasOwnProperty("speechUtterance")) {
                        properties.action = 1;
                        if (!$util.isString(message.speechUtterance))
                            return "speechUtterance: string expected";
                    }
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits")) {
                        if (properties.action === 1)
                            return "action: multiple values";
                        properties.action = 1;
                        if (!$util.isString(message.dtmfDigits))
                            return "dtmfDigits: string expected";
                    }
                    if (message.intentName != null && message.hasOwnProperty("intentName"))
                        if (!$util.isString(message.intentName))
                            return "intentName: string expected";
                    if (message.suggestionCategory != null && message.hasOwnProperty("suggestionCategory"))
                        switch (message.suggestionCategory) {
                        default:
                            return "suggestionCategory: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.protoPath != null && message.hasOwnProperty("protoPath")) {
                        if (!Array.isArray(message.protoPath))
                            return "protoPath: array expected";
                        for (var i = 0; i < message.protoPath.length; ++i)
                            if (!$util.isString(message.protoPath[i]))
                                return "protoPath: string[] expected";
                    }
                    if (message.popularSuggestion != null && message.hasOwnProperty("popularSuggestion"))
                        if (typeof message.popularSuggestion !== "boolean")
                            return "popularSuggestion: boolean expected";
                    if (message.stopRecordingSuggestion != null && message.hasOwnProperty("stopRecordingSuggestion"))
                        if (typeof message.stopRecordingSuggestion !== "boolean")
                            return "stopRecordingSuggestion: boolean expected";
                    if (message.followupSuggestions != null && message.hasOwnProperty("followupSuggestions")) {
                        if (!Array.isArray(message.followupSuggestions))
                            return "followupSuggestions: array expected";
                        for (var i = 0; i < message.followupSuggestions.length; ++i) {
                            var error = $root.infinitusai.be.FollowupSuggestion.verify(message.followupSuggestions[i]);
                            if (error)
                                return "followupSuggestions." + error;
                        }
                    }
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        if (!$util.isString(message.suggestionId))
                            return "suggestionId: string expected";
                    if (message.hangupAndRequeue != null && message.hasOwnProperty("hangupAndRequeue"))
                        if (typeof message.hangupAndRequeue !== "boolean")
                            return "hangupAndRequeue: boolean expected";
                    if (message.isCallSpecific != null && message.hasOwnProperty("isCallSpecific"))
                        if (typeof message.isCallSpecific !== "boolean")
                            return "isCallSpecific: boolean expected";
                    if (message.modelId != null && message.hasOwnProperty("modelId"))
                        if (!$util.isString(message.modelId))
                            return "modelId: string expected";
                    if (message.suggestionSubcategory != null && message.hasOwnProperty("suggestionSubcategory"))
                        switch (message.suggestionSubcategory) {
                        default:
                            return "suggestionSubcategory: enum value expected";
                        case 0:
                        case 1:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.shouldCapturePayerIntelligenceState != null && message.hasOwnProperty("shouldCapturePayerIntelligenceState"))
                        if (typeof message.shouldCapturePayerIntelligenceState !== "boolean")
                            return "shouldCapturePayerIntelligenceState: boolean expected";
                    if (message.fallbackSpeechUtterance != null && message.hasOwnProperty("fallbackSpeechUtterance"))
                        if (!$util.isString(message.fallbackSpeechUtterance))
                            return "fallbackSpeechUtterance: string expected";
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        if (!$util.isString(message.actionName))
                            return "actionName: string expected";
                    if (message.actionNames != null && message.hasOwnProperty("actionNames")) {
                        if (!Array.isArray(message.actionNames))
                            return "actionNames: array expected";
                        for (var i = 0; i < message.actionNames.length; ++i)
                            if (!$util.isString(message.actionNames[i]))
                                return "actionNames: string[] expected";
                    }
                    if (message.followup != null && message.hasOwnProperty("followup"))
                        switch (message.followup) {
                        default:
                            return "followup: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.taskNoteUpdate != null && message.hasOwnProperty("taskNoteUpdate"))
                        if (!$util.isString(message.taskNoteUpdate))
                            return "taskNoteUpdate: string expected";
                    if (message.pingTimer != null && message.hasOwnProperty("pingTimer"))
                        if (typeof message.pingTimer !== "number")
                            return "pingTimer: number expected";
                    if (message.logs != null && message.hasOwnProperty("logs"))
                        if (!$util.isString(message.logs))
                            return "logs: string expected";
                    if (message.startRoutingOperator != null && message.hasOwnProperty("startRoutingOperator")) {
                        var error = $root.infinitusai.be.NullableBool.verify(message.startRoutingOperator);
                        if (error)
                            return "startRoutingOperator." + error;
                    }
                    if (message.nlpLogs != null && message.hasOwnProperty("nlpLogs"))
                        if (!$util.isString(message.nlpLogs))
                            return "nlpLogs: string expected";
                    if (message.hangupReason != null && message.hasOwnProperty("hangupReason"))
                        switch (message.hangupReason) {
                        default:
                            return "hangupReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 29:
                        case 21:
                        case 30:
                        case 22:
                        case 23:
                        case 24:
                        case 32:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 31:
                        case 33:
                        case 34:
                        case 42:
                        case 43:
                        case 45:
                        case 46:
                        case 47:
                        case 52:
                        case 53:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 99:
                        case 35:
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 48:
                        case 49:
                        case 50:
                        case 51:
                        case 55:
                        case 56:
                        case 57:
                        case 58:
                        case 59:
                        case 44:
                        case 60:
                            break;
                        }
                    if (message.requeueNumber != null && message.hasOwnProperty("requeueNumber"))
                        if (!$util.isString(message.requeueNumber))
                            return "requeueNumber: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateResponseOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateResponseOption} GenerateResponseOption
                 */
                GenerateResponseOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateResponseOption)
                        return object;
                    var message = new $root.infinitusai.be.GenerateResponseOption();
                    if (object.speechUtterance != null)
                        message.speechUtterance = String(object.speechUtterance);
                    if (object.dtmfDigits != null)
                        message.dtmfDigits = String(object.dtmfDigits);
                    if (object.intentName != null)
                        message.intentName = String(object.intentName);
                    switch (object.suggestionCategory) {
                    default:
                        if (typeof object.suggestionCategory === "number") {
                            message.suggestionCategory = object.suggestionCategory;
                            break;
                        }
                        break;
                    case "SUGGESTION_CATEGORY_UNKNOWN":
                    case 0:
                        message.suggestionCategory = 0;
                        break;
                    case "SUGGESTION_CATEGORY_CHITCHAT":
                    case 1:
                        message.suggestionCategory = 1;
                        break;
                    case "SUGGESTION_CATEGORY_IVR":
                    case 2:
                        message.suggestionCategory = 2;
                        break;
                    case "SUGGESTION_CATEGORY_ANSWERS":
                    case 3:
                        message.suggestionCategory = 3;
                        break;
                    case "SUGGESTION_CATEGORY_QUESTIONS":
                    case 4:
                        message.suggestionCategory = 4;
                        break;
                    case "SUGGESTION_CATEGORY_PUSHBACK":
                    case 5:
                        message.suggestionCategory = 5;
                        break;
                    }
                    if (object.protoPath) {
                        if (!Array.isArray(object.protoPath))
                            throw TypeError(".infinitusai.be.GenerateResponseOption.protoPath: array expected");
                        message.protoPath = [];
                        for (var i = 0; i < object.protoPath.length; ++i)
                            message.protoPath[i] = String(object.protoPath[i]);
                    }
                    if (object.popularSuggestion != null)
                        message.popularSuggestion = Boolean(object.popularSuggestion);
                    if (object.stopRecordingSuggestion != null)
                        message.stopRecordingSuggestion = Boolean(object.stopRecordingSuggestion);
                    if (object.followupSuggestions) {
                        if (!Array.isArray(object.followupSuggestions))
                            throw TypeError(".infinitusai.be.GenerateResponseOption.followupSuggestions: array expected");
                        message.followupSuggestions = [];
                        for (var i = 0; i < object.followupSuggestions.length; ++i) {
                            if (typeof object.followupSuggestions[i] !== "object")
                                throw TypeError(".infinitusai.be.GenerateResponseOption.followupSuggestions: object expected");
                            message.followupSuggestions[i] = $root.infinitusai.be.FollowupSuggestion.fromObject(object.followupSuggestions[i]);
                        }
                    }
                    if (object.suggestionId != null)
                        message.suggestionId = String(object.suggestionId);
                    if (object.hangupAndRequeue != null)
                        message.hangupAndRequeue = Boolean(object.hangupAndRequeue);
                    if (object.isCallSpecific != null)
                        message.isCallSpecific = Boolean(object.isCallSpecific);
                    if (object.modelId != null)
                        message.modelId = String(object.modelId);
                    switch (object.suggestionSubcategory) {
                    default:
                        if (typeof object.suggestionSubcategory === "number") {
                            message.suggestionSubcategory = object.suggestionSubcategory;
                            break;
                        }
                        break;
                    case "SUGGESTION_SUBCATEGORY_UNKNOWN":
                    case 0:
                        message.suggestionSubcategory = 0;
                        break;
                    case "SUGGESTION_SUBCATEGORY_PLAN_INFO":
                    case 1:
                        message.suggestionSubcategory = 1;
                        break;
                    case "SUGGESTION_SUBCATEGORY_OTHER_INSURANCE":
                    case 3:
                        message.suggestionSubcategory = 3;
                        break;
                    case "SUGGESTION_SUBCATEGORY_PLAN_DESIGN":
                    case 4:
                        message.suggestionSubcategory = 4;
                        break;
                    case "SUGGESTION_SUBCATEGORY_COST_SHARE":
                    case 5:
                        message.suggestionSubcategory = 5;
                        break;
                    case "SUGGESTION_SUBCATEGORY_ACCESS_INFORMATION":
                    case 6:
                        message.suggestionSubcategory = 6;
                        break;
                    }
                    if (object.shouldCapturePayerIntelligenceState != null)
                        message.shouldCapturePayerIntelligenceState = Boolean(object.shouldCapturePayerIntelligenceState);
                    if (object.fallbackSpeechUtterance != null)
                        message.fallbackSpeechUtterance = String(object.fallbackSpeechUtterance);
                    if (object.actionName != null)
                        message.actionName = String(object.actionName);
                    if (object.actionNames) {
                        if (!Array.isArray(object.actionNames))
                            throw TypeError(".infinitusai.be.GenerateResponseOption.actionNames: array expected");
                        message.actionNames = [];
                        for (var i = 0; i < object.actionNames.length; ++i)
                            message.actionNames[i] = String(object.actionNames[i]);
                    }
                    switch (object.followup) {
                    default:
                        if (typeof object.followup === "number") {
                            message.followup = object.followup;
                            break;
                        }
                        break;
                    case "NONE":
                    case 0:
                        message.followup = 0;
                        break;
                    case "HANGUP":
                    case 1:
                        message.followup = 1;
                        break;
                    case "HANGUP_AND_REQUEUE":
                    case 2:
                        message.followup = 2;
                        break;
                    case "HANGUP_AND_LEAVE_DISCONNECTED":
                    case 3:
                        message.followup = 3;
                        break;
                    }
                    if (object.taskNoteUpdate != null)
                        message.taskNoteUpdate = String(object.taskNoteUpdate);
                    if (object.pingTimer != null)
                        message.pingTimer = Number(object.pingTimer);
                    if (object.logs != null)
                        message.logs = String(object.logs);
                    if (object.startRoutingOperator != null) {
                        if (typeof object.startRoutingOperator !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseOption.startRoutingOperator: object expected");
                        message.startRoutingOperator = $root.infinitusai.be.NullableBool.fromObject(object.startRoutingOperator);
                    }
                    if (object.nlpLogs != null)
                        message.nlpLogs = String(object.nlpLogs);
                    switch (object.hangupReason) {
                    default:
                        if (typeof object.hangupReason === "number") {
                            message.hangupReason = object.hangupReason;
                            break;
                        }
                        break;
                    case "REASON_UNKNOWN":
                    case 0:
                        message.hangupReason = 0;
                        break;
                    case "REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 1:
                        message.hangupReason = 1;
                        break;
                    case "REASON_AGENT_DID_NOT_PROVIDE_INFO":
                    case 2:
                        message.hangupReason = 2;
                        break;
                    case "REASON_AGENT_PROVIDED_UNRELIABLE_DATA":
                    case 3:
                        message.hangupReason = 3;
                        break;
                    case "REASON_AGENT_REQUESTED_UNAVAILABLE_INFO":
                    case 4:
                        message.hangupReason = 4;
                        break;
                    case "REASON_AGENT_TOO_SLOW":
                    case 5:
                        message.hangupReason = 5;
                        break;
                    case "REASON_OPERATOR_ERROR":
                    case 6:
                        message.hangupReason = 6;
                        break;
                    case "REASON_SYSTEM_ISSUE":
                    case 7:
                        message.hangupReason = 7;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER":
                    case 8:
                        message.hangupReason = 8;
                        break;
                    case "REASON_GATHERED_PIN_FOR_MEDICAL":
                    case 9:
                        message.hangupReason = 9;
                        break;
                    case "REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT":
                    case 10:
                        message.hangupReason = 10;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_BV":
                    case 11:
                        message.hangupReason = 11;
                        break;
                    case "REASON_PAYER_SYSTEM_ISSUE":
                    case 12:
                        message.hangupReason = 12;
                        break;
                    case "REASON_PAYER_UNREACHABLE":
                    case 29:
                        message.hangupReason = 29;
                        break;
                    case "REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY":
                    case 21:
                        message.hangupReason = 21;
                        break;
                    case "REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA":
                    case 30:
                        message.hangupReason = 30;
                        break;
                    case "REASON_MISSING_INPUT_INFORMATION":
                    case 22:
                        message.hangupReason = 22;
                        break;
                    case "REASON_IVR_NAVIGATION_FAILURE":
                    case 23:
                        message.hangupReason = 23;
                        break;
                    case "REASON_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 24:
                        message.hangupReason = 24;
                        break;
                    case "REASON_AGENT_AUTOMATION_HANGUP":
                    case 32:
                        message.hangupReason = 32;
                        break;
                    case "REASON_PIE_ESCALATION_NEEDED":
                    case 25:
                        message.hangupReason = 25;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER_PLAN":
                    case 26:
                        message.hangupReason = 26;
                        break;
                    case "REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED":
                    case 27:
                        message.hangupReason = 27;
                        break;
                    case "REASON_INCORRECT_INPUT_INFORMATION":
                    case 28:
                        message.hangupReason = 28;
                        break;
                    case "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD":
                    case 31:
                        message.hangupReason = 31;
                        break;
                    case "REASON_INCORRECT_PROMPT_SELECTED":
                    case 33:
                        message.hangupReason = 33;
                        break;
                    case "REASON_QUALITY_CONCERNS":
                    case 34:
                        message.hangupReason = 34;
                        break;
                    case "REASON_PROVIDER_IS_INACTIVE":
                    case 42:
                        message.hangupReason = 42;
                        break;
                    case "REASON_NO_OPERATOR_AVAILABLE":
                    case 43:
                        message.hangupReason = 43;
                        break;
                    case "REASON_NO_BENEFITS_UNDER_THIS_PLAN":
                    case 45:
                        message.hangupReason = 45;
                        break;
                    case "REASON_UNABLE_TO_REVERIFY_BENEFITS":
                    case 46:
                        message.hangupReason = 46;
                        break;
                    case "REASON_LONG_HOLD_TIME":
                    case 47:
                        message.hangupReason = 47;
                        break;
                    case "REASON_HOLD_NAVIGATION_FAILURE":
                    case 52:
                        message.hangupReason = 52;
                        break;
                    case "REASON_IVR_TIMEOUT":
                    case 53:
                        message.hangupReason = 53;
                        break;
                    case "REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK":
                    case 13:
                        message.hangupReason = 13;
                        break;
                    case "REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS":
                    case 14:
                        message.hangupReason = 14;
                        break;
                    case "REASON_INCOMPLETE_ANSWER_TO_QUESTIONS":
                    case 15:
                        message.hangupReason = 15;
                        break;
                    case "REASON_INVALID_ANSWERS_TO_QUESTIONS":
                    case 16:
                        message.hangupReason = 16;
                        break;
                    case "REASON_UNANSWERED_QUESTIONS_REMAINING":
                    case 17:
                        message.hangupReason = 17;
                        break;
                    case "REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED":
                    case 18:
                        message.hangupReason = 18;
                        break;
                    case "REASON_BOTH_IN_AND_OON_NOT_COLLECTED":
                    case 19:
                        message.hangupReason = 19;
                        break;
                    case "REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS":
                    case 20:
                        message.hangupReason = 20;
                        break;
                    case "REASON_OTHER":
                    case 99:
                        message.hangupReason = 99;
                        break;
                    case "REASON_NLP_PLAN_TERMINATED":
                    case 35:
                        message.hangupReason = 35;
                        break;
                    case "REASON_NLP_PAYER_UNREACHABLE":
                    case 36:
                        message.hangupReason = 36;
                        break;
                    case "REASON_NLP_ERROR":
                    case 37:
                        message.hangupReason = 37;
                        break;
                    case "REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 38:
                        message.hangupReason = 38;
                        break;
                    case "REASON_NLP_READY_FOR_REVIEW":
                    case 39:
                        message.hangupReason = 39;
                        break;
                    case "REASON_NLP_PAYER_SYSTEM_ISSUE":
                    case 40:
                        message.hangupReason = 40;
                        break;
                    case "REASON_NLP_CALL_NEW_NUMBER":
                    case 41:
                        message.hangupReason = 41;
                        break;
                    case "REASON_NLP_BAD_DATA":
                    case 48:
                        message.hangupReason = 48;
                        break;
                    case "REASON_NLP_AGENT_CANT_FIND_MEMBER":
                    case 49:
                        message.hangupReason = 49;
                        break;
                    case "REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 50:
                        message.hangupReason = 50;
                        break;
                    case "REASON_NLP_INPUT_NOT_RECOGNIZED":
                    case 51:
                        message.hangupReason = 51;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK":
                    case 55:
                        message.hangupReason = 55;
                        break;
                    case "REASON_NEED_TO_CALL_TPA":
                    case 56:
                        message.hangupReason = 56;
                        break;
                    case "REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT":
                    case 57:
                        message.hangupReason = 57;
                        break;
                    case "REASON_IVR_WRONG_DEPARTMENT":
                    case 58:
                        message.hangupReason = 58;
                        break;
                    case "REASON_AGENT_ISSUE":
                    case 59:
                        message.hangupReason = 59;
                        break;
                    case "REASON_NONE":
                    case 44:
                        message.hangupReason = 44;
                        break;
                    case "REASON_CHAINED_TASK":
                    case 60:
                        message.hangupReason = 60;
                        break;
                    }
                    if (object.requeueNumber != null)
                        message.requeueNumber = String(object.requeueNumber);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateResponseOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {infinitusai.be.GenerateResponseOption} message GenerateResponseOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateResponseOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.protoPath = [];
                        object.followupSuggestions = [];
                        object.actionNames = [];
                    }
                    if (options.defaults) {
                        object.intentName = "";
                        object.suggestionCategory = options.enums === String ? "SUGGESTION_CATEGORY_UNKNOWN" : 0;
                        object.popularSuggestion = false;
                        object.stopRecordingSuggestion = false;
                        object.suggestionId = "";
                        object.hangupAndRequeue = false;
                        object.isCallSpecific = false;
                        object.modelId = "";
                        object.suggestionSubcategory = options.enums === String ? "SUGGESTION_SUBCATEGORY_UNKNOWN" : 0;
                        object.shouldCapturePayerIntelligenceState = false;
                        object.fallbackSpeechUtterance = "";
                        object.actionName = "";
                        object.followup = options.enums === String ? "NONE" : 0;
                        object.taskNoteUpdate = "";
                        object.pingTimer = 0;
                        object.logs = "";
                        object.startRoutingOperator = null;
                        object.nlpLogs = "";
                        object.hangupReason = options.enums === String ? "REASON_UNKNOWN" : 0;
                        object.requeueNumber = "";
                    }
                    if (message.speechUtterance != null && message.hasOwnProperty("speechUtterance")) {
                        object.speechUtterance = message.speechUtterance;
                        if (options.oneofs)
                            object.action = "speechUtterance";
                    }
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits")) {
                        object.dtmfDigits = message.dtmfDigits;
                        if (options.oneofs)
                            object.action = "dtmfDigits";
                    }
                    if (message.intentName != null && message.hasOwnProperty("intentName"))
                        object.intentName = message.intentName;
                    if (message.suggestionCategory != null && message.hasOwnProperty("suggestionCategory"))
                        object.suggestionCategory = options.enums === String ? $root.infinitusai.be.SuggestionCategory[message.suggestionCategory] === undefined ? message.suggestionCategory : $root.infinitusai.be.SuggestionCategory[message.suggestionCategory] : message.suggestionCategory;
                    if (message.protoPath && message.protoPath.length) {
                        object.protoPath = [];
                        for (var j = 0; j < message.protoPath.length; ++j)
                            object.protoPath[j] = message.protoPath[j];
                    }
                    if (message.popularSuggestion != null && message.hasOwnProperty("popularSuggestion"))
                        object.popularSuggestion = message.popularSuggestion;
                    if (message.stopRecordingSuggestion != null && message.hasOwnProperty("stopRecordingSuggestion"))
                        object.stopRecordingSuggestion = message.stopRecordingSuggestion;
                    if (message.followupSuggestions && message.followupSuggestions.length) {
                        object.followupSuggestions = [];
                        for (var j = 0; j < message.followupSuggestions.length; ++j)
                            object.followupSuggestions[j] = $root.infinitusai.be.FollowupSuggestion.toObject(message.followupSuggestions[j], options);
                    }
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        object.suggestionId = message.suggestionId;
                    if (message.hangupAndRequeue != null && message.hasOwnProperty("hangupAndRequeue"))
                        object.hangupAndRequeue = message.hangupAndRequeue;
                    if (message.isCallSpecific != null && message.hasOwnProperty("isCallSpecific"))
                        object.isCallSpecific = message.isCallSpecific;
                    if (message.modelId != null && message.hasOwnProperty("modelId"))
                        object.modelId = message.modelId;
                    if (message.suggestionSubcategory != null && message.hasOwnProperty("suggestionSubcategory"))
                        object.suggestionSubcategory = options.enums === String ? $root.infinitusai.be.SuggestionSubcategory[message.suggestionSubcategory] === undefined ? message.suggestionSubcategory : $root.infinitusai.be.SuggestionSubcategory[message.suggestionSubcategory] : message.suggestionSubcategory;
                    if (message.shouldCapturePayerIntelligenceState != null && message.hasOwnProperty("shouldCapturePayerIntelligenceState"))
                        object.shouldCapturePayerIntelligenceState = message.shouldCapturePayerIntelligenceState;
                    if (message.fallbackSpeechUtterance != null && message.hasOwnProperty("fallbackSpeechUtterance"))
                        object.fallbackSpeechUtterance = message.fallbackSpeechUtterance;
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        object.actionName = message.actionName;
                    if (message.followup != null && message.hasOwnProperty("followup"))
                        object.followup = options.enums === String ? $root.infinitusai.be.NlpExecutionFollowup[message.followup] === undefined ? message.followup : $root.infinitusai.be.NlpExecutionFollowup[message.followup] : message.followup;
                    if (message.taskNoteUpdate != null && message.hasOwnProperty("taskNoteUpdate"))
                        object.taskNoteUpdate = message.taskNoteUpdate;
                    if (message.pingTimer != null && message.hasOwnProperty("pingTimer"))
                        object.pingTimer = options.json && !isFinite(message.pingTimer) ? String(message.pingTimer) : message.pingTimer;
                    if (message.logs != null && message.hasOwnProperty("logs"))
                        object.logs = message.logs;
                    if (message.startRoutingOperator != null && message.hasOwnProperty("startRoutingOperator"))
                        object.startRoutingOperator = $root.infinitusai.be.NullableBool.toObject(message.startRoutingOperator, options);
                    if (message.nlpLogs != null && message.hasOwnProperty("nlpLogs"))
                        object.nlpLogs = message.nlpLogs;
                    if (message.hangupReason != null && message.hasOwnProperty("hangupReason"))
                        object.hangupReason = options.enums === String ? $root.infinitusai.be.RequeueReason.Type[message.hangupReason] === undefined ? message.hangupReason : $root.infinitusai.be.RequeueReason.Type[message.hangupReason] : message.hangupReason;
                    if (message.requeueNumber != null && message.hasOwnProperty("requeueNumber"))
                        object.requeueNumber = message.requeueNumber;
                    if (message.actionNames && message.actionNames.length) {
                        object.actionNames = [];
                        for (var j = 0; j < message.actionNames.length; ++j)
                            object.actionNames[j] = message.actionNames[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GenerateResponseOption to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateResponseOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateResponseOption
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateResponseOption
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateResponseOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateResponseOption";
                };
    
                return GenerateResponseOption;
            })();
    
            be.GenerateResponseResponse = (function() {
    
                /**
                 * Properties of a GenerateResponseResponse.
                 * @memberof infinitusai.be
                 * @interface IGenerateResponseResponse
                 * @property {infinitusai.be.IGenerateResponseOption|null} [response] GenerateResponseResponse response
                 * @property {infinitusai.be.ICallState|null} [callState] GenerateResponseResponse callState
                 * @property {google.protobuf.IFieldMask|null} [outputsFieldmask] GenerateResponseResponse outputsFieldmask
                 * @property {infinitusai.be.ResponseCode|null} [responseCode] GenerateResponseResponse responseCode
                 * @property {string|null} [nlpTaskStateJson] GenerateResponseResponse nlpTaskStateJson
                 * @property {string|null} [envValue] GenerateResponseResponse envValue
                 * @property {string|null} [gitHash] GenerateResponseResponse gitHash
                 * @property {string|null} [nlpServerChecksum] GenerateResponseResponse nlpServerChecksum
                 */
    
                /**
                 * Constructs a new GenerateResponseResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateResponseResponse.
                 * @implements IGenerateResponseResponse
                 * @constructor
                 * @param {infinitusai.be.IGenerateResponseResponse=} [properties] Properties to set
                 */
                function GenerateResponseResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateResponseResponse response.
                 * @member {infinitusai.be.IGenerateResponseOption|null|undefined} response
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 */
                GenerateResponseResponse.prototype.response = null;
    
                /**
                 * GenerateResponseResponse callState.
                 * @member {infinitusai.be.ICallState|null|undefined} callState
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 */
                GenerateResponseResponse.prototype.callState = null;
    
                /**
                 * GenerateResponseResponse outputsFieldmask.
                 * @member {google.protobuf.IFieldMask|null|undefined} outputsFieldmask
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 */
                GenerateResponseResponse.prototype.outputsFieldmask = null;
    
                /**
                 * GenerateResponseResponse responseCode.
                 * @member {infinitusai.be.ResponseCode} responseCode
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 */
                GenerateResponseResponse.prototype.responseCode = 0;
    
                /**
                 * GenerateResponseResponse nlpTaskStateJson.
                 * @member {string} nlpTaskStateJson
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 */
                GenerateResponseResponse.prototype.nlpTaskStateJson = "";
    
                /**
                 * GenerateResponseResponse envValue.
                 * @member {string} envValue
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 */
                GenerateResponseResponse.prototype.envValue = "";
    
                /**
                 * GenerateResponseResponse gitHash.
                 * @member {string} gitHash
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 */
                GenerateResponseResponse.prototype.gitHash = "";
    
                /**
                 * GenerateResponseResponse nlpServerChecksum.
                 * @member {string} nlpServerChecksum
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 */
                GenerateResponseResponse.prototype.nlpServerChecksum = "";
    
                /**
                 * Creates a new GenerateResponseResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {infinitusai.be.IGenerateResponseResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateResponseResponse} GenerateResponseResponse instance
                 */
                GenerateResponseResponse.create = function create(properties) {
                    return new GenerateResponseResponse(properties);
                };
    
                /**
                 * Encodes the specified GenerateResponseResponse message. Does not implicitly {@link infinitusai.be.GenerateResponseResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {infinitusai.be.IGenerateResponseResponse} message GenerateResponseResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateResponseResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                        $root.infinitusai.be.GenerateResponseOption.encode(message.response, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.callState != null && Object.hasOwnProperty.call(message, "callState"))
                        $root.infinitusai.be.CallState.encode(message.callState, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.outputsFieldmask != null && Object.hasOwnProperty.call(message, "outputsFieldmask"))
                        $root.google.protobuf.FieldMask.encode(message.outputsFieldmask, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.responseCode != null && Object.hasOwnProperty.call(message, "responseCode"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.responseCode);
                    if (message.nlpTaskStateJson != null && Object.hasOwnProperty.call(message, "nlpTaskStateJson"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.nlpTaskStateJson);
                    if (message.envValue != null && Object.hasOwnProperty.call(message, "envValue"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.envValue);
                    if (message.gitHash != null && Object.hasOwnProperty.call(message, "gitHash"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.gitHash);
                    if (message.nlpServerChecksum != null && Object.hasOwnProperty.call(message, "nlpServerChecksum"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.nlpServerChecksum);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateResponseResponse message, length delimited. Does not implicitly {@link infinitusai.be.GenerateResponseResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {infinitusai.be.IGenerateResponseResponse} message GenerateResponseResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateResponseResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateResponseResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateResponseResponse} GenerateResponseResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateResponseResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateResponseResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.response = $root.infinitusai.be.GenerateResponseOption.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.callState = $root.infinitusai.be.CallState.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.outputsFieldmask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.responseCode = reader.int32();
                                break;
                            }
                        case 5: {
                                message.nlpTaskStateJson = reader.string();
                                break;
                            }
                        case 6: {
                                message.envValue = reader.string();
                                break;
                            }
                        case 7: {
                                message.gitHash = reader.string();
                                break;
                            }
                        case 8: {
                                message.nlpServerChecksum = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateResponseResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateResponseResponse} GenerateResponseResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateResponseResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateResponseResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateResponseResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.response != null && message.hasOwnProperty("response")) {
                        var error = $root.infinitusai.be.GenerateResponseOption.verify(message.response);
                        if (error)
                            return "response." + error;
                    }
                    if (message.callState != null && message.hasOwnProperty("callState")) {
                        var error = $root.infinitusai.be.CallState.verify(message.callState);
                        if (error)
                            return "callState." + error;
                    }
                    if (message.outputsFieldmask != null && message.hasOwnProperty("outputsFieldmask")) {
                        var error = $root.google.protobuf.FieldMask.verify(message.outputsFieldmask);
                        if (error)
                            return "outputsFieldmask." + error;
                    }
                    if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                        switch (message.responseCode) {
                        default:
                            return "responseCode: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.nlpTaskStateJson != null && message.hasOwnProperty("nlpTaskStateJson"))
                        if (!$util.isString(message.nlpTaskStateJson))
                            return "nlpTaskStateJson: string expected";
                    if (message.envValue != null && message.hasOwnProperty("envValue"))
                        if (!$util.isString(message.envValue))
                            return "envValue: string expected";
                    if (message.gitHash != null && message.hasOwnProperty("gitHash"))
                        if (!$util.isString(message.gitHash))
                            return "gitHash: string expected";
                    if (message.nlpServerChecksum != null && message.hasOwnProperty("nlpServerChecksum"))
                        if (!$util.isString(message.nlpServerChecksum))
                            return "nlpServerChecksum: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateResponseResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateResponseResponse} GenerateResponseResponse
                 */
                GenerateResponseResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateResponseResponse)
                        return object;
                    var message = new $root.infinitusai.be.GenerateResponseResponse();
                    if (object.response != null) {
                        if (typeof object.response !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseResponse.response: object expected");
                        message.response = $root.infinitusai.be.GenerateResponseOption.fromObject(object.response);
                    }
                    if (object.callState != null) {
                        if (typeof object.callState !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseResponse.callState: object expected");
                        message.callState = $root.infinitusai.be.CallState.fromObject(object.callState);
                    }
                    if (object.outputsFieldmask != null) {
                        if (typeof object.outputsFieldmask !== "object")
                            throw TypeError(".infinitusai.be.GenerateResponseResponse.outputsFieldmask: object expected");
                        message.outputsFieldmask = $root.google.protobuf.FieldMask.fromObject(object.outputsFieldmask);
                    }
                    switch (object.responseCode) {
                    default:
                        if (typeof object.responseCode === "number") {
                            message.responseCode = object.responseCode;
                            break;
                        }
                        break;
                    case "DEFAULT":
                    case 0:
                        message.responseCode = 0;
                        break;
                    case "IGNORE":
                    case 1:
                        message.responseCode = 1;
                        break;
                    }
                    if (object.nlpTaskStateJson != null)
                        message.nlpTaskStateJson = String(object.nlpTaskStateJson);
                    if (object.envValue != null)
                        message.envValue = String(object.envValue);
                    if (object.gitHash != null)
                        message.gitHash = String(object.gitHash);
                    if (object.nlpServerChecksum != null)
                        message.nlpServerChecksum = String(object.nlpServerChecksum);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateResponseResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {infinitusai.be.GenerateResponseResponse} message GenerateResponseResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateResponseResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.response = null;
                        object.callState = null;
                        object.outputsFieldmask = null;
                        object.responseCode = options.enums === String ? "DEFAULT" : 0;
                        object.nlpTaskStateJson = "";
                        object.envValue = "";
                        object.gitHash = "";
                        object.nlpServerChecksum = "";
                    }
                    if (message.response != null && message.hasOwnProperty("response"))
                        object.response = $root.infinitusai.be.GenerateResponseOption.toObject(message.response, options);
                    if (message.callState != null && message.hasOwnProperty("callState"))
                        object.callState = $root.infinitusai.be.CallState.toObject(message.callState, options);
                    if (message.outputsFieldmask != null && message.hasOwnProperty("outputsFieldmask"))
                        object.outputsFieldmask = $root.google.protobuf.FieldMask.toObject(message.outputsFieldmask, options);
                    if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                        object.responseCode = options.enums === String ? $root.infinitusai.be.ResponseCode[message.responseCode] === undefined ? message.responseCode : $root.infinitusai.be.ResponseCode[message.responseCode] : message.responseCode;
                    if (message.nlpTaskStateJson != null && message.hasOwnProperty("nlpTaskStateJson"))
                        object.nlpTaskStateJson = message.nlpTaskStateJson;
                    if (message.envValue != null && message.hasOwnProperty("envValue"))
                        object.envValue = message.envValue;
                    if (message.gitHash != null && message.hasOwnProperty("gitHash"))
                        object.gitHash = message.gitHash;
                    if (message.nlpServerChecksum != null && message.hasOwnProperty("nlpServerChecksum"))
                        object.nlpServerChecksum = message.nlpServerChecksum;
                    return object;
                };
    
                /**
                 * Converts this GenerateResponseResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateResponseResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateResponseResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateResponseResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateResponseResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateResponseResponse";
                };
    
                return GenerateResponseResponse;
            })();
    
            be.DialogBreakdownResponse = (function() {
    
                /**
                 * Properties of a DialogBreakdownResponse.
                 * @memberof infinitusai.be
                 * @interface IDialogBreakdownResponse
                 */
    
                /**
                 * Constructs a new DialogBreakdownResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DialogBreakdownResponse.
                 * @implements IDialogBreakdownResponse
                 * @constructor
                 * @param {infinitusai.be.IDialogBreakdownResponse=} [properties] Properties to set
                 */
                function DialogBreakdownResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DialogBreakdownResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {infinitusai.be.IDialogBreakdownResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DialogBreakdownResponse} DialogBreakdownResponse instance
                 */
                DialogBreakdownResponse.create = function create(properties) {
                    return new DialogBreakdownResponse(properties);
                };
    
                /**
                 * Encodes the specified DialogBreakdownResponse message. Does not implicitly {@link infinitusai.be.DialogBreakdownResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {infinitusai.be.IDialogBreakdownResponse} message DialogBreakdownResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DialogBreakdownResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DialogBreakdownResponse message, length delimited. Does not implicitly {@link infinitusai.be.DialogBreakdownResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {infinitusai.be.IDialogBreakdownResponse} message DialogBreakdownResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DialogBreakdownResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DialogBreakdownResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DialogBreakdownResponse} DialogBreakdownResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DialogBreakdownResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DialogBreakdownResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DialogBreakdownResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DialogBreakdownResponse} DialogBreakdownResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DialogBreakdownResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DialogBreakdownResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DialogBreakdownResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DialogBreakdownResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DialogBreakdownResponse} DialogBreakdownResponse
                 */
                DialogBreakdownResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DialogBreakdownResponse)
                        return object;
                    return new $root.infinitusai.be.DialogBreakdownResponse();
                };
    
                /**
                 * Creates a plain object from a DialogBreakdownResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {infinitusai.be.DialogBreakdownResponse} message DialogBreakdownResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DialogBreakdownResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DialogBreakdownResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DialogBreakdownResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DialogBreakdownResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DialogBreakdownResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DialogBreakdownResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DialogBreakdownResponse";
                };
    
                return DialogBreakdownResponse;
            })();
    
            /**
             * ResponseCode enum.
             * @name infinitusai.be.ResponseCode
             * @enum {number}
             * @property {number} DEFAULT=0 DEFAULT value
             * @property {number} IGNORE=1 IGNORE value
             */
            be.ResponseCode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DEFAULT"] = 0;
                values[valuesById[1] = "IGNORE"] = 1;
                return values;
            })();
    
            be.GenerateSuggestionsRequest = (function() {
    
                /**
                 * Properties of a GenerateSuggestionsRequest.
                 * @memberof infinitusai.be
                 * @interface IGenerateSuggestionsRequest
                 * @property {infinitusai.be.IOrganizationDoc|null} [orgDoc] GenerateSuggestionsRequest orgDoc
                 * @property {string|null} [conversationUuid] GenerateSuggestionsRequest conversationUuid
                 * @property {infinitusai.be.IBVCallInputs|null} [callInputs] GenerateSuggestionsRequest callInputs
                 * @property {infinitusai.be.ICallState|null} [callState] GenerateSuggestionsRequest callState
                 * @property {infinitusai.be.TaskType|null} [taskType] GenerateSuggestionsRequest taskType
                 * @property {infinitusai.be.ICallOutputs|null} [outputs] GenerateSuggestionsRequest outputs
                 * @property {string|null} [taskUuid] GenerateSuggestionsRequest taskUuid
                 * @property {infinitusai.be.ICallOutputs|null} [previousBvOutputs] GenerateSuggestionsRequest previousBvOutputs
                 */
    
                /**
                 * Constructs a new GenerateSuggestionsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateSuggestionsRequest.
                 * @implements IGenerateSuggestionsRequest
                 * @constructor
                 * @param {infinitusai.be.IGenerateSuggestionsRequest=} [properties] Properties to set
                 */
                function GenerateSuggestionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateSuggestionsRequest orgDoc.
                 * @member {infinitusai.be.IOrganizationDoc|null|undefined} orgDoc
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 */
                GenerateSuggestionsRequest.prototype.orgDoc = null;
    
                /**
                 * GenerateSuggestionsRequest conversationUuid.
                 * @member {string} conversationUuid
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 */
                GenerateSuggestionsRequest.prototype.conversationUuid = "";
    
                /**
                 * GenerateSuggestionsRequest callInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} callInputs
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 */
                GenerateSuggestionsRequest.prototype.callInputs = null;
    
                /**
                 * GenerateSuggestionsRequest callState.
                 * @member {infinitusai.be.ICallState|null|undefined} callState
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 */
                GenerateSuggestionsRequest.prototype.callState = null;
    
                /**
                 * GenerateSuggestionsRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 */
                GenerateSuggestionsRequest.prototype.taskType = 0;
    
                /**
                 * GenerateSuggestionsRequest outputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} outputs
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 */
                GenerateSuggestionsRequest.prototype.outputs = null;
    
                /**
                 * GenerateSuggestionsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 */
                GenerateSuggestionsRequest.prototype.taskUuid = "";
    
                /**
                 * GenerateSuggestionsRequest previousBvOutputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} previousBvOutputs
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 */
                GenerateSuggestionsRequest.prototype.previousBvOutputs = null;
    
                /**
                 * Creates a new GenerateSuggestionsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {infinitusai.be.IGenerateSuggestionsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateSuggestionsRequest} GenerateSuggestionsRequest instance
                 */
                GenerateSuggestionsRequest.create = function create(properties) {
                    return new GenerateSuggestionsRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateSuggestionsRequest message. Does not implicitly {@link infinitusai.be.GenerateSuggestionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {infinitusai.be.IGenerateSuggestionsRequest} message GenerateSuggestionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateSuggestionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversationUuid != null && Object.hasOwnProperty.call(message, "conversationUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationUuid);
                    if (message.callInputs != null && Object.hasOwnProperty.call(message, "callInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.callInputs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.callState != null && Object.hasOwnProperty.call(message, "callState"))
                        $root.infinitusai.be.CallState.encode(message.callState, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.taskType);
                    if (message.orgDoc != null && Object.hasOwnProperty.call(message, "orgDoc"))
                        $root.infinitusai.be.OrganizationDoc.encode(message.orgDoc, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.outputs != null && Object.hasOwnProperty.call(message, "outputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.outputs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.taskUuid);
                    if (message.previousBvOutputs != null && Object.hasOwnProperty.call(message, "previousBvOutputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.previousBvOutputs, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateSuggestionsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GenerateSuggestionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {infinitusai.be.IGenerateSuggestionsRequest} message GenerateSuggestionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateSuggestionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateSuggestionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateSuggestionsRequest} GenerateSuggestionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateSuggestionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateSuggestionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 5: {
                                message.orgDoc = $root.infinitusai.be.OrganizationDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 1: {
                                message.conversationUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.callState = $root.infinitusai.be.CallState.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 6: {
                                message.outputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 8: {
                                message.previousBvOutputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateSuggestionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateSuggestionsRequest} GenerateSuggestionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateSuggestionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateSuggestionsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateSuggestionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgDoc != null && message.hasOwnProperty("orgDoc")) {
                        var error = $root.infinitusai.be.OrganizationDoc.verify(message.orgDoc);
                        if (error)
                            return "orgDoc." + error;
                    }
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        if (!$util.isString(message.conversationUuid))
                            return "conversationUuid: string expected";
                    if (message.callInputs != null && message.hasOwnProperty("callInputs")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.callInputs);
                        if (error)
                            return "callInputs." + error;
                    }
                    if (message.callState != null && message.hasOwnProperty("callState")) {
                        var error = $root.infinitusai.be.CallState.verify(message.callState);
                        if (error)
                            return "callState." + error;
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.outputs != null && message.hasOwnProperty("outputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.outputs);
                        if (error)
                            return "outputs." + error;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.previousBvOutputs != null && message.hasOwnProperty("previousBvOutputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.previousBvOutputs);
                        if (error)
                            return "previousBvOutputs." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GenerateSuggestionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateSuggestionsRequest} GenerateSuggestionsRequest
                 */
                GenerateSuggestionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateSuggestionsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GenerateSuggestionsRequest();
                    if (object.orgDoc != null) {
                        if (typeof object.orgDoc !== "object")
                            throw TypeError(".infinitusai.be.GenerateSuggestionsRequest.orgDoc: object expected");
                        message.orgDoc = $root.infinitusai.be.OrganizationDoc.fromObject(object.orgDoc);
                    }
                    if (object.conversationUuid != null)
                        message.conversationUuid = String(object.conversationUuid);
                    if (object.callInputs != null) {
                        if (typeof object.callInputs !== "object")
                            throw TypeError(".infinitusai.be.GenerateSuggestionsRequest.callInputs: object expected");
                        message.callInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.callInputs);
                    }
                    if (object.callState != null) {
                        if (typeof object.callState !== "object")
                            throw TypeError(".infinitusai.be.GenerateSuggestionsRequest.callState: object expected");
                        message.callState = $root.infinitusai.be.CallState.fromObject(object.callState);
                    }
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.outputs != null) {
                        if (typeof object.outputs !== "object")
                            throw TypeError(".infinitusai.be.GenerateSuggestionsRequest.outputs: object expected");
                        message.outputs = $root.infinitusai.be.CallOutputs.fromObject(object.outputs);
                    }
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.previousBvOutputs != null) {
                        if (typeof object.previousBvOutputs !== "object")
                            throw TypeError(".infinitusai.be.GenerateSuggestionsRequest.previousBvOutputs: object expected");
                        message.previousBvOutputs = $root.infinitusai.be.CallOutputs.fromObject(object.previousBvOutputs);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateSuggestionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {infinitusai.be.GenerateSuggestionsRequest} message GenerateSuggestionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateSuggestionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.conversationUuid = "";
                        object.callInputs = null;
                        object.callState = null;
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.orgDoc = null;
                        object.outputs = null;
                        object.taskUuid = "";
                        object.previousBvOutputs = null;
                    }
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        object.conversationUuid = message.conversationUuid;
                    if (message.callInputs != null && message.hasOwnProperty("callInputs"))
                        object.callInputs = $root.infinitusai.be.BVCallInputs.toObject(message.callInputs, options);
                    if (message.callState != null && message.hasOwnProperty("callState"))
                        object.callState = $root.infinitusai.be.CallState.toObject(message.callState, options);
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.orgDoc != null && message.hasOwnProperty("orgDoc"))
                        object.orgDoc = $root.infinitusai.be.OrganizationDoc.toObject(message.orgDoc, options);
                    if (message.outputs != null && message.hasOwnProperty("outputs"))
                        object.outputs = $root.infinitusai.be.CallOutputs.toObject(message.outputs, options);
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.previousBvOutputs != null && message.hasOwnProperty("previousBvOutputs"))
                        object.previousBvOutputs = $root.infinitusai.be.CallOutputs.toObject(message.previousBvOutputs, options);
                    return object;
                };
    
                /**
                 * Converts this GenerateSuggestionsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateSuggestionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateSuggestionsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateSuggestionsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateSuggestionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateSuggestionsRequest";
                };
    
                return GenerateSuggestionsRequest;
            })();
    
            be.GenerateSuggestionsResponse = (function() {
    
                /**
                 * Properties of a GenerateSuggestionsResponse.
                 * @memberof infinitusai.be
                 * @interface IGenerateSuggestionsResponse
                 * @property {Array.<infinitusai.be.IGenerateResponseOption>|null} [options] GenerateSuggestionsResponse options
                 */
    
                /**
                 * Constructs a new GenerateSuggestionsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateSuggestionsResponse.
                 * @implements IGenerateSuggestionsResponse
                 * @constructor
                 * @param {infinitusai.be.IGenerateSuggestionsResponse=} [properties] Properties to set
                 */
                function GenerateSuggestionsResponse(properties) {
                    this.options = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateSuggestionsResponse options.
                 * @member {Array.<infinitusai.be.IGenerateResponseOption>} options
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @instance
                 */
                GenerateSuggestionsResponse.prototype.options = $util.emptyArray;
    
                /**
                 * Creates a new GenerateSuggestionsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {infinitusai.be.IGenerateSuggestionsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateSuggestionsResponse} GenerateSuggestionsResponse instance
                 */
                GenerateSuggestionsResponse.create = function create(properties) {
                    return new GenerateSuggestionsResponse(properties);
                };
    
                /**
                 * Encodes the specified GenerateSuggestionsResponse message. Does not implicitly {@link infinitusai.be.GenerateSuggestionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {infinitusai.be.IGenerateSuggestionsResponse} message GenerateSuggestionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateSuggestionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.options != null && message.options.length)
                        for (var i = 0; i < message.options.length; ++i)
                            $root.infinitusai.be.GenerateResponseOption.encode(message.options[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateSuggestionsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GenerateSuggestionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {infinitusai.be.IGenerateSuggestionsResponse} message GenerateSuggestionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateSuggestionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateSuggestionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateSuggestionsResponse} GenerateSuggestionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateSuggestionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateSuggestionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.options && message.options.length))
                                    message.options = [];
                                message.options.push($root.infinitusai.be.GenerateResponseOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateSuggestionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateSuggestionsResponse} GenerateSuggestionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateSuggestionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateSuggestionsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateSuggestionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        if (!Array.isArray(message.options))
                            return "options: array expected";
                        for (var i = 0; i < message.options.length; ++i) {
                            var error = $root.infinitusai.be.GenerateResponseOption.verify(message.options[i]);
                            if (error)
                                return "options." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GenerateSuggestionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateSuggestionsResponse} GenerateSuggestionsResponse
                 */
                GenerateSuggestionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateSuggestionsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GenerateSuggestionsResponse();
                    if (object.options) {
                        if (!Array.isArray(object.options))
                            throw TypeError(".infinitusai.be.GenerateSuggestionsResponse.options: array expected");
                        message.options = [];
                        for (var i = 0; i < object.options.length; ++i) {
                            if (typeof object.options[i] !== "object")
                                throw TypeError(".infinitusai.be.GenerateSuggestionsResponse.options: object expected");
                            message.options[i] = $root.infinitusai.be.GenerateResponseOption.fromObject(object.options[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateSuggestionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {infinitusai.be.GenerateSuggestionsResponse} message GenerateSuggestionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateSuggestionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.options = [];
                    if (message.options && message.options.length) {
                        object.options = [];
                        for (var j = 0; j < message.options.length; ++j)
                            object.options[j] = $root.infinitusai.be.GenerateResponseOption.toObject(message.options[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GenerateSuggestionsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateSuggestionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateSuggestionsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateSuggestionsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateSuggestionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateSuggestionsResponse";
                };
    
                return GenerateSuggestionsResponse;
            })();
    
            be.ExecuteActionRequest = (function() {
    
                /**
                 * Properties of an ExecuteActionRequest.
                 * @memberof infinitusai.be
                 * @interface IExecuteActionRequest
                 * @property {string|null} [conversationUuid] ExecuteActionRequest conversationUuid
                 * @property {infinitusai.be.IBVCallInputs|null} [callInputs] ExecuteActionRequest callInputs
                 * @property {infinitusai.be.ICallState|null} [callState] ExecuteActionRequest callState
                 * @property {string|null} [actionName] ExecuteActionRequest actionName
                 * @property {infinitusai.be.TaskType|null} [taskType] ExecuteActionRequest taskType
                 * @property {string|null} [speechUtterance] ExecuteActionRequest speechUtterance
                 * @property {string|null} [dtmfDigits] ExecuteActionRequest dtmfDigits
                 * @property {number|Long|null} [startTimeMillis] ExecuteActionRequest startTimeMillis
                 * @property {number|Long|null} [endTimeMillis] ExecuteActionRequest endTimeMillis
                 */
    
                /**
                 * Constructs a new ExecuteActionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExecuteActionRequest.
                 * @implements IExecuteActionRequest
                 * @constructor
                 * @param {infinitusai.be.IExecuteActionRequest=} [properties] Properties to set
                 */
                function ExecuteActionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExecuteActionRequest conversationUuid.
                 * @member {string} conversationUuid
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.conversationUuid = "";
    
                /**
                 * ExecuteActionRequest callInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} callInputs
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.callInputs = null;
    
                /**
                 * ExecuteActionRequest callState.
                 * @member {infinitusai.be.ICallState|null|undefined} callState
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.callState = null;
    
                /**
                 * ExecuteActionRequest actionName.
                 * @member {string} actionName
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.actionName = "";
    
                /**
                 * ExecuteActionRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.taskType = 0;
    
                /**
                 * ExecuteActionRequest speechUtterance.
                 * @member {string|null|undefined} speechUtterance
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.speechUtterance = null;
    
                /**
                 * ExecuteActionRequest dtmfDigits.
                 * @member {string|null|undefined} dtmfDigits
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.dtmfDigits = null;
    
                /**
                 * ExecuteActionRequest startTimeMillis.
                 * @member {number|Long} startTimeMillis
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.startTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * ExecuteActionRequest endTimeMillis.
                 * @member {number|Long} endTimeMillis
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                ExecuteActionRequest.prototype.endTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * ExecuteActionRequest action.
                 * @member {"speechUtterance"|"dtmfDigits"|undefined} action
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 */
                Object.defineProperty(ExecuteActionRequest.prototype, "action", {
                    get: $util.oneOfGetter($oneOfFields = ["speechUtterance", "dtmfDigits"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new ExecuteActionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.IExecuteActionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ExecuteActionRequest} ExecuteActionRequest instance
                 */
                ExecuteActionRequest.create = function create(properties) {
                    return new ExecuteActionRequest(properties);
                };
    
                /**
                 * Encodes the specified ExecuteActionRequest message. Does not implicitly {@link infinitusai.be.ExecuteActionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.IExecuteActionRequest} message ExecuteActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExecuteActionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversationUuid != null && Object.hasOwnProperty.call(message, "conversationUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationUuid);
                    if (message.callInputs != null && Object.hasOwnProperty.call(message, "callInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.callInputs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.callState != null && Object.hasOwnProperty.call(message, "callState"))
                        $root.infinitusai.be.CallState.encode(message.callState, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.actionName);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.taskType);
                    if (message.speechUtterance != null && Object.hasOwnProperty.call(message, "speechUtterance"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.speechUtterance);
                    if (message.dtmfDigits != null && Object.hasOwnProperty.call(message, "dtmfDigits"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.dtmfDigits);
                    if (message.startTimeMillis != null && Object.hasOwnProperty.call(message, "startTimeMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.startTimeMillis);
                    if (message.endTimeMillis != null && Object.hasOwnProperty.call(message, "endTimeMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.endTimeMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified ExecuteActionRequest message, length delimited. Does not implicitly {@link infinitusai.be.ExecuteActionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.IExecuteActionRequest} message ExecuteActionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExecuteActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExecuteActionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExecuteActionRequest} ExecuteActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExecuteActionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExecuteActionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversationUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.callState = $root.infinitusai.be.CallState.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.actionName = reader.string();
                                break;
                            }
                        case 5: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 6: {
                                message.speechUtterance = reader.string();
                                break;
                            }
                        case 7: {
                                message.dtmfDigits = reader.string();
                                break;
                            }
                        case 8: {
                                message.startTimeMillis = reader.int64();
                                break;
                            }
                        case 9: {
                                message.endTimeMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExecuteActionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExecuteActionRequest} ExecuteActionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExecuteActionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExecuteActionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExecuteActionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        if (!$util.isString(message.conversationUuid))
                            return "conversationUuid: string expected";
                    if (message.callInputs != null && message.hasOwnProperty("callInputs")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.callInputs);
                        if (error)
                            return "callInputs." + error;
                    }
                    if (message.callState != null && message.hasOwnProperty("callState")) {
                        var error = $root.infinitusai.be.CallState.verify(message.callState);
                        if (error)
                            return "callState." + error;
                    }
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        if (!$util.isString(message.actionName))
                            return "actionName: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.speechUtterance != null && message.hasOwnProperty("speechUtterance")) {
                        properties.action = 1;
                        if (!$util.isString(message.speechUtterance))
                            return "speechUtterance: string expected";
                    }
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits")) {
                        if (properties.action === 1)
                            return "action: multiple values";
                        properties.action = 1;
                        if (!$util.isString(message.dtmfDigits))
                            return "dtmfDigits: string expected";
                    }
                    if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                        if (!$util.isInteger(message.startTimeMillis) && !(message.startTimeMillis && $util.isInteger(message.startTimeMillis.low) && $util.isInteger(message.startTimeMillis.high)))
                            return "startTimeMillis: integer|Long expected";
                    if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                        if (!$util.isInteger(message.endTimeMillis) && !(message.endTimeMillis && $util.isInteger(message.endTimeMillis.low) && $util.isInteger(message.endTimeMillis.high)))
                            return "endTimeMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an ExecuteActionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExecuteActionRequest} ExecuteActionRequest
                 */
                ExecuteActionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExecuteActionRequest)
                        return object;
                    var message = new $root.infinitusai.be.ExecuteActionRequest();
                    if (object.conversationUuid != null)
                        message.conversationUuid = String(object.conversationUuid);
                    if (object.callInputs != null) {
                        if (typeof object.callInputs !== "object")
                            throw TypeError(".infinitusai.be.ExecuteActionRequest.callInputs: object expected");
                        message.callInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.callInputs);
                    }
                    if (object.callState != null) {
                        if (typeof object.callState !== "object")
                            throw TypeError(".infinitusai.be.ExecuteActionRequest.callState: object expected");
                        message.callState = $root.infinitusai.be.CallState.fromObject(object.callState);
                    }
                    if (object.actionName != null)
                        message.actionName = String(object.actionName);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.speechUtterance != null)
                        message.speechUtterance = String(object.speechUtterance);
                    if (object.dtmfDigits != null)
                        message.dtmfDigits = String(object.dtmfDigits);
                    if (object.startTimeMillis != null)
                        if ($util.Long)
                            (message.startTimeMillis = $util.Long.fromValue(object.startTimeMillis)).unsigned = false;
                        else if (typeof object.startTimeMillis === "string")
                            message.startTimeMillis = parseInt(object.startTimeMillis, 10);
                        else if (typeof object.startTimeMillis === "number")
                            message.startTimeMillis = object.startTimeMillis;
                        else if (typeof object.startTimeMillis === "object")
                            message.startTimeMillis = new $util.LongBits(object.startTimeMillis.low >>> 0, object.startTimeMillis.high >>> 0).toNumber();
                    if (object.endTimeMillis != null)
                        if ($util.Long)
                            (message.endTimeMillis = $util.Long.fromValue(object.endTimeMillis)).unsigned = false;
                        else if (typeof object.endTimeMillis === "string")
                            message.endTimeMillis = parseInt(object.endTimeMillis, 10);
                        else if (typeof object.endTimeMillis === "number")
                            message.endTimeMillis = object.endTimeMillis;
                        else if (typeof object.endTimeMillis === "object")
                            message.endTimeMillis = new $util.LongBits(object.endTimeMillis.low >>> 0, object.endTimeMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExecuteActionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {infinitusai.be.ExecuteActionRequest} message ExecuteActionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExecuteActionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.conversationUuid = "";
                        object.callInputs = null;
                        object.callState = null;
                        object.actionName = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        object.conversationUuid = message.conversationUuid;
                    if (message.callInputs != null && message.hasOwnProperty("callInputs"))
                        object.callInputs = $root.infinitusai.be.BVCallInputs.toObject(message.callInputs, options);
                    if (message.callState != null && message.hasOwnProperty("callState"))
                        object.callState = $root.infinitusai.be.CallState.toObject(message.callState, options);
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        object.actionName = message.actionName;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.speechUtterance != null && message.hasOwnProperty("speechUtterance")) {
                        object.speechUtterance = message.speechUtterance;
                        if (options.oneofs)
                            object.action = "speechUtterance";
                    }
                    if (message.dtmfDigits != null && message.hasOwnProperty("dtmfDigits")) {
                        object.dtmfDigits = message.dtmfDigits;
                        if (options.oneofs)
                            object.action = "dtmfDigits";
                    }
                    if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                        if (typeof message.startTimeMillis === "number")
                            object.startTimeMillis = options.longs === String ? String(message.startTimeMillis) : message.startTimeMillis;
                        else
                            object.startTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMillis) : options.longs === Number ? new $util.LongBits(message.startTimeMillis.low >>> 0, message.startTimeMillis.high >>> 0).toNumber() : message.startTimeMillis;
                    if (message.endTimeMillis != null && message.hasOwnProperty("endTimeMillis"))
                        if (typeof message.endTimeMillis === "number")
                            object.endTimeMillis = options.longs === String ? String(message.endTimeMillis) : message.endTimeMillis;
                        else
                            object.endTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMillis) : options.longs === Number ? new $util.LongBits(message.endTimeMillis.low >>> 0, message.endTimeMillis.high >>> 0).toNumber() : message.endTimeMillis;
                    return object;
                };
    
                /**
                 * Converts this ExecuteActionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExecuteActionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExecuteActionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExecuteActionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExecuteActionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExecuteActionRequest";
                };
    
                return ExecuteActionRequest;
            })();
    
            be.ExecuteActionResponse = (function() {
    
                /**
                 * Properties of an ExecuteActionResponse.
                 * @memberof infinitusai.be
                 * @interface IExecuteActionResponse
                 * @property {infinitusai.be.ICallState|null} [callState] ExecuteActionResponse callState
                 * @property {boolean|null} [hangupAndRequeue] ExecuteActionResponse hangupAndRequeue
                 * @property {infinitusai.be.NlpExecutionFollowup|null} [followup] ExecuteActionResponse followup
                 * @property {string|null} [taskNoteUpdate] ExecuteActionResponse taskNoteUpdate
                 */
    
                /**
                 * Constructs a new ExecuteActionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExecuteActionResponse.
                 * @implements IExecuteActionResponse
                 * @constructor
                 * @param {infinitusai.be.IExecuteActionResponse=} [properties] Properties to set
                 */
                function ExecuteActionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExecuteActionResponse callState.
                 * @member {infinitusai.be.ICallState|null|undefined} callState
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @instance
                 */
                ExecuteActionResponse.prototype.callState = null;
    
                /**
                 * ExecuteActionResponse hangupAndRequeue.
                 * @member {boolean} hangupAndRequeue
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @instance
                 */
                ExecuteActionResponse.prototype.hangupAndRequeue = false;
    
                /**
                 * ExecuteActionResponse followup.
                 * @member {infinitusai.be.NlpExecutionFollowup} followup
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @instance
                 */
                ExecuteActionResponse.prototype.followup = 0;
    
                /**
                 * ExecuteActionResponse taskNoteUpdate.
                 * @member {string} taskNoteUpdate
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @instance
                 */
                ExecuteActionResponse.prototype.taskNoteUpdate = "";
    
                /**
                 * Creates a new ExecuteActionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.IExecuteActionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ExecuteActionResponse} ExecuteActionResponse instance
                 */
                ExecuteActionResponse.create = function create(properties) {
                    return new ExecuteActionResponse(properties);
                };
    
                /**
                 * Encodes the specified ExecuteActionResponse message. Does not implicitly {@link infinitusai.be.ExecuteActionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.IExecuteActionResponse} message ExecuteActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExecuteActionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callState != null && Object.hasOwnProperty.call(message, "callState"))
                        $root.infinitusai.be.CallState.encode(message.callState, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.hangupAndRequeue != null && Object.hasOwnProperty.call(message, "hangupAndRequeue"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hangupAndRequeue);
                    if (message.followup != null && Object.hasOwnProperty.call(message, "followup"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.followup);
                    if (message.taskNoteUpdate != null && Object.hasOwnProperty.call(message, "taskNoteUpdate"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskNoteUpdate);
                    return writer;
                };
    
                /**
                 * Encodes the specified ExecuteActionResponse message, length delimited. Does not implicitly {@link infinitusai.be.ExecuteActionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.IExecuteActionResponse} message ExecuteActionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExecuteActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExecuteActionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExecuteActionResponse} ExecuteActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExecuteActionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExecuteActionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callState = $root.infinitusai.be.CallState.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.hangupAndRequeue = reader.bool();
                                break;
                            }
                        case 3: {
                                message.followup = reader.int32();
                                break;
                            }
                        case 4: {
                                message.taskNoteUpdate = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExecuteActionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExecuteActionResponse} ExecuteActionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExecuteActionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExecuteActionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExecuteActionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callState != null && message.hasOwnProperty("callState")) {
                        var error = $root.infinitusai.be.CallState.verify(message.callState);
                        if (error)
                            return "callState." + error;
                    }
                    if (message.hangupAndRequeue != null && message.hasOwnProperty("hangupAndRequeue"))
                        if (typeof message.hangupAndRequeue !== "boolean")
                            return "hangupAndRequeue: boolean expected";
                    if (message.followup != null && message.hasOwnProperty("followup"))
                        switch (message.followup) {
                        default:
                            return "followup: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.taskNoteUpdate != null && message.hasOwnProperty("taskNoteUpdate"))
                        if (!$util.isString(message.taskNoteUpdate))
                            return "taskNoteUpdate: string expected";
                    return null;
                };
    
                /**
                 * Creates an ExecuteActionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExecuteActionResponse} ExecuteActionResponse
                 */
                ExecuteActionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExecuteActionResponse)
                        return object;
                    var message = new $root.infinitusai.be.ExecuteActionResponse();
                    if (object.callState != null) {
                        if (typeof object.callState !== "object")
                            throw TypeError(".infinitusai.be.ExecuteActionResponse.callState: object expected");
                        message.callState = $root.infinitusai.be.CallState.fromObject(object.callState);
                    }
                    if (object.hangupAndRequeue != null)
                        message.hangupAndRequeue = Boolean(object.hangupAndRequeue);
                    switch (object.followup) {
                    default:
                        if (typeof object.followup === "number") {
                            message.followup = object.followup;
                            break;
                        }
                        break;
                    case "NONE":
                    case 0:
                        message.followup = 0;
                        break;
                    case "HANGUP":
                    case 1:
                        message.followup = 1;
                        break;
                    case "HANGUP_AND_REQUEUE":
                    case 2:
                        message.followup = 2;
                        break;
                    case "HANGUP_AND_LEAVE_DISCONNECTED":
                    case 3:
                        message.followup = 3;
                        break;
                    }
                    if (object.taskNoteUpdate != null)
                        message.taskNoteUpdate = String(object.taskNoteUpdate);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExecuteActionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {infinitusai.be.ExecuteActionResponse} message ExecuteActionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExecuteActionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callState = null;
                        object.hangupAndRequeue = false;
                        object.followup = options.enums === String ? "NONE" : 0;
                        object.taskNoteUpdate = "";
                    }
                    if (message.callState != null && message.hasOwnProperty("callState"))
                        object.callState = $root.infinitusai.be.CallState.toObject(message.callState, options);
                    if (message.hangupAndRequeue != null && message.hasOwnProperty("hangupAndRequeue"))
                        object.hangupAndRequeue = message.hangupAndRequeue;
                    if (message.followup != null && message.hasOwnProperty("followup"))
                        object.followup = options.enums === String ? $root.infinitusai.be.NlpExecutionFollowup[message.followup] === undefined ? message.followup : $root.infinitusai.be.NlpExecutionFollowup[message.followup] : message.followup;
                    if (message.taskNoteUpdate != null && message.hasOwnProperty("taskNoteUpdate"))
                        object.taskNoteUpdate = message.taskNoteUpdate;
                    return object;
                };
    
                /**
                 * Converts this ExecuteActionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExecuteActionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExecuteActionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExecuteActionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExecuteActionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExecuteActionResponse";
                };
    
                return ExecuteActionResponse;
            })();
    
            be.NlpValidateCallOutputsRequest = (function() {
    
                /**
                 * Properties of a NlpValidateCallOutputsRequest.
                 * @memberof infinitusai.be
                 * @interface INlpValidateCallOutputsRequest
                 * @property {infinitusai.be.IBVCallInputs|null} [bvInputs] NlpValidateCallOutputsRequest bvInputs
                 * @property {infinitusapi.GetTaskResultsResponse.IDataMessage|null} [outputs] NlpValidateCallOutputsRequest outputs
                 * @property {Array.<infinitusai.be.IAutoKeyTemplates>|null} [autoKeyTemplates] NlpValidateCallOutputsRequest autoKeyTemplates
                 * @property {string|null} [inputsJson] NlpValidateCallOutputsRequest inputsJson
                 * @property {string|null} [callOutputsBagJson] NlpValidateCallOutputsRequest callOutputsBagJson
                 * @property {string|null} [orgUuid] NlpValidateCallOutputsRequest orgUuid
                 * @property {string|null} [outputDefsJson] NlpValidateCallOutputsRequest outputDefsJson
                 * @property {infinitusai.be.IChatLogRecords|null} [chatLogs] NlpValidateCallOutputsRequest chatLogs
                 */
    
                /**
                 * Constructs a new NlpValidateCallOutputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NlpValidateCallOutputsRequest.
                 * @implements INlpValidateCallOutputsRequest
                 * @constructor
                 * @param {infinitusai.be.INlpValidateCallOutputsRequest=} [properties] Properties to set
                 */
                function NlpValidateCallOutputsRequest(properties) {
                    this.autoKeyTemplates = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NlpValidateCallOutputsRequest bvInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} bvInputs
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                NlpValidateCallOutputsRequest.prototype.bvInputs = null;
    
                /**
                 * NlpValidateCallOutputsRequest outputs.
                 * @member {infinitusapi.GetTaskResultsResponse.IDataMessage|null|undefined} outputs
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                NlpValidateCallOutputsRequest.prototype.outputs = null;
    
                /**
                 * NlpValidateCallOutputsRequest autoKeyTemplates.
                 * @member {Array.<infinitusai.be.IAutoKeyTemplates>} autoKeyTemplates
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                NlpValidateCallOutputsRequest.prototype.autoKeyTemplates = $util.emptyArray;
    
                /**
                 * NlpValidateCallOutputsRequest inputsJson.
                 * @member {string} inputsJson
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                NlpValidateCallOutputsRequest.prototype.inputsJson = "";
    
                /**
                 * NlpValidateCallOutputsRequest callOutputsBagJson.
                 * @member {string} callOutputsBagJson
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                NlpValidateCallOutputsRequest.prototype.callOutputsBagJson = "";
    
                /**
                 * NlpValidateCallOutputsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                NlpValidateCallOutputsRequest.prototype.orgUuid = "";
    
                /**
                 * NlpValidateCallOutputsRequest outputDefsJson.
                 * @member {string} outputDefsJson
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                NlpValidateCallOutputsRequest.prototype.outputDefsJson = "";
    
                /**
                 * NlpValidateCallOutputsRequest chatLogs.
                 * @member {infinitusai.be.IChatLogRecords|null|undefined} chatLogs
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                NlpValidateCallOutputsRequest.prototype.chatLogs = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * NlpValidateCallOutputsRequest inputs.
                 * @member {"bvInputs"|undefined} inputs
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 */
                Object.defineProperty(NlpValidateCallOutputsRequest.prototype, "inputs", {
                    get: $util.oneOfGetter($oneOfFields = ["bvInputs"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new NlpValidateCallOutputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.INlpValidateCallOutputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.NlpValidateCallOutputsRequest} NlpValidateCallOutputsRequest instance
                 */
                NlpValidateCallOutputsRequest.create = function create(properties) {
                    return new NlpValidateCallOutputsRequest(properties);
                };
    
                /**
                 * Encodes the specified NlpValidateCallOutputsRequest message. Does not implicitly {@link infinitusai.be.NlpValidateCallOutputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.INlpValidateCallOutputsRequest} message NlpValidateCallOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpValidateCallOutputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bvInputs != null && Object.hasOwnProperty.call(message, "bvInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.bvInputs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.outputs != null && Object.hasOwnProperty.call(message, "outputs"))
                        $root.infinitusapi.GetTaskResultsResponse.DataMessage.encode(message.outputs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.autoKeyTemplates != null && message.autoKeyTemplates.length)
                        for (var i = 0; i < message.autoKeyTemplates.length; ++i)
                            $root.infinitusai.be.AutoKeyTemplates.encode(message.autoKeyTemplates[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.inputsJson != null && Object.hasOwnProperty.call(message, "inputsJson"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.inputsJson);
                    if (message.callOutputsBagJson != null && Object.hasOwnProperty.call(message, "callOutputsBagJson"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.callOutputsBagJson);
                    if (message.chatLogs != null && Object.hasOwnProperty.call(message, "chatLogs"))
                        $root.infinitusai.be.ChatLogRecords.encode(message.chatLogs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.orgUuid);
                    if (message.outputDefsJson != null && Object.hasOwnProperty.call(message, "outputDefsJson"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.outputDefsJson);
                    return writer;
                };
    
                /**
                 * Encodes the specified NlpValidateCallOutputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.NlpValidateCallOutputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.INlpValidateCallOutputsRequest} message NlpValidateCallOutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpValidateCallOutputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NlpValidateCallOutputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NlpValidateCallOutputsRequest} NlpValidateCallOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpValidateCallOutputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NlpValidateCallOutputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.bvInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.outputs = $root.infinitusapi.GetTaskResultsResponse.DataMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.autoKeyTemplates && message.autoKeyTemplates.length))
                                    message.autoKeyTemplates = [];
                                message.autoKeyTemplates.push($root.infinitusai.be.AutoKeyTemplates.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.inputsJson = reader.string();
                                break;
                            }
                        case 5: {
                                message.callOutputsBagJson = reader.string();
                                break;
                            }
                        case 7: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 8: {
                                message.outputDefsJson = reader.string();
                                break;
                            }
                        case 6: {
                                message.chatLogs = $root.infinitusai.be.ChatLogRecords.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NlpValidateCallOutputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NlpValidateCallOutputsRequest} NlpValidateCallOutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpValidateCallOutputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NlpValidateCallOutputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NlpValidateCallOutputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        properties.inputs = 1;
                        {
                            var error = $root.infinitusai.be.BVCallInputs.verify(message.bvInputs);
                            if (error)
                                return "bvInputs." + error;
                        }
                    }
                    if (message.outputs != null && message.hasOwnProperty("outputs")) {
                        var error = $root.infinitusapi.GetTaskResultsResponse.DataMessage.verify(message.outputs);
                        if (error)
                            return "outputs." + error;
                    }
                    if (message.autoKeyTemplates != null && message.hasOwnProperty("autoKeyTemplates")) {
                        if (!Array.isArray(message.autoKeyTemplates))
                            return "autoKeyTemplates: array expected";
                        for (var i = 0; i < message.autoKeyTemplates.length; ++i) {
                            var error = $root.infinitusai.be.AutoKeyTemplates.verify(message.autoKeyTemplates[i]);
                            if (error)
                                return "autoKeyTemplates." + error;
                        }
                    }
                    if (message.inputsJson != null && message.hasOwnProperty("inputsJson"))
                        if (!$util.isString(message.inputsJson))
                            return "inputsJson: string expected";
                    if (message.callOutputsBagJson != null && message.hasOwnProperty("callOutputsBagJson"))
                        if (!$util.isString(message.callOutputsBagJson))
                            return "callOutputsBagJson: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.outputDefsJson != null && message.hasOwnProperty("outputDefsJson"))
                        if (!$util.isString(message.outputDefsJson))
                            return "outputDefsJson: string expected";
                    if (message.chatLogs != null && message.hasOwnProperty("chatLogs")) {
                        var error = $root.infinitusai.be.ChatLogRecords.verify(message.chatLogs);
                        if (error)
                            return "chatLogs." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a NlpValidateCallOutputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NlpValidateCallOutputsRequest} NlpValidateCallOutputsRequest
                 */
                NlpValidateCallOutputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NlpValidateCallOutputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.NlpValidateCallOutputsRequest();
                    if (object.bvInputs != null) {
                        if (typeof object.bvInputs !== "object")
                            throw TypeError(".infinitusai.be.NlpValidateCallOutputsRequest.bvInputs: object expected");
                        message.bvInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.bvInputs);
                    }
                    if (object.outputs != null) {
                        if (typeof object.outputs !== "object")
                            throw TypeError(".infinitusai.be.NlpValidateCallOutputsRequest.outputs: object expected");
                        message.outputs = $root.infinitusapi.GetTaskResultsResponse.DataMessage.fromObject(object.outputs);
                    }
                    if (object.autoKeyTemplates) {
                        if (!Array.isArray(object.autoKeyTemplates))
                            throw TypeError(".infinitusai.be.NlpValidateCallOutputsRequest.autoKeyTemplates: array expected");
                        message.autoKeyTemplates = [];
                        for (var i = 0; i < object.autoKeyTemplates.length; ++i) {
                            if (typeof object.autoKeyTemplates[i] !== "object")
                                throw TypeError(".infinitusai.be.NlpValidateCallOutputsRequest.autoKeyTemplates: object expected");
                            message.autoKeyTemplates[i] = $root.infinitusai.be.AutoKeyTemplates.fromObject(object.autoKeyTemplates[i]);
                        }
                    }
                    if (object.inputsJson != null)
                        message.inputsJson = String(object.inputsJson);
                    if (object.callOutputsBagJson != null)
                        message.callOutputsBagJson = String(object.callOutputsBagJson);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.outputDefsJson != null)
                        message.outputDefsJson = String(object.outputDefsJson);
                    if (object.chatLogs != null) {
                        if (typeof object.chatLogs !== "object")
                            throw TypeError(".infinitusai.be.NlpValidateCallOutputsRequest.chatLogs: object expected");
                        message.chatLogs = $root.infinitusai.be.ChatLogRecords.fromObject(object.chatLogs);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a NlpValidateCallOutputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {infinitusai.be.NlpValidateCallOutputsRequest} message NlpValidateCallOutputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NlpValidateCallOutputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.autoKeyTemplates = [];
                    if (options.defaults) {
                        object.outputs = null;
                        object.inputsJson = "";
                        object.callOutputsBagJson = "";
                        object.chatLogs = null;
                        object.orgUuid = "";
                        object.outputDefsJson = "";
                    }
                    if (message.bvInputs != null && message.hasOwnProperty("bvInputs")) {
                        object.bvInputs = $root.infinitusai.be.BVCallInputs.toObject(message.bvInputs, options);
                        if (options.oneofs)
                            object.inputs = "bvInputs";
                    }
                    if (message.outputs != null && message.hasOwnProperty("outputs"))
                        object.outputs = $root.infinitusapi.GetTaskResultsResponse.DataMessage.toObject(message.outputs, options);
                    if (message.autoKeyTemplates && message.autoKeyTemplates.length) {
                        object.autoKeyTemplates = [];
                        for (var j = 0; j < message.autoKeyTemplates.length; ++j)
                            object.autoKeyTemplates[j] = $root.infinitusai.be.AutoKeyTemplates.toObject(message.autoKeyTemplates[j], options);
                    }
                    if (message.inputsJson != null && message.hasOwnProperty("inputsJson"))
                        object.inputsJson = message.inputsJson;
                    if (message.callOutputsBagJson != null && message.hasOwnProperty("callOutputsBagJson"))
                        object.callOutputsBagJson = message.callOutputsBagJson;
                    if (message.chatLogs != null && message.hasOwnProperty("chatLogs"))
                        object.chatLogs = $root.infinitusai.be.ChatLogRecords.toObject(message.chatLogs, options);
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.outputDefsJson != null && message.hasOwnProperty("outputDefsJson"))
                        object.outputDefsJson = message.outputDefsJson;
                    return object;
                };
    
                /**
                 * Converts this NlpValidateCallOutputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NlpValidateCallOutputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NlpValidateCallOutputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NlpValidateCallOutputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NlpValidateCallOutputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NlpValidateCallOutputsRequest";
                };
    
                return NlpValidateCallOutputsRequest;
            })();
    
            be.AutoKeyTemplates = (function() {
    
                /**
                 * Properties of an AutoKeyTemplates.
                 * @memberof infinitusai.be
                 * @interface IAutoKeyTemplates
                 * @property {infinitusai.be.AutoKey|null} [documentName] AutoKeyTemplates documentName
                 * @property {infinitusai.be.IAutoValue|null} [autoKeyValues] AutoKeyTemplates autoKeyValues
                 */
    
                /**
                 * Constructs a new AutoKeyTemplates.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AutoKeyTemplates.
                 * @implements IAutoKeyTemplates
                 * @constructor
                 * @param {infinitusai.be.IAutoKeyTemplates=} [properties] Properties to set
                 */
                function AutoKeyTemplates(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AutoKeyTemplates documentName.
                 * @member {infinitusai.be.AutoKey} documentName
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @instance
                 */
                AutoKeyTemplates.prototype.documentName = 0;
    
                /**
                 * AutoKeyTemplates autoKeyValues.
                 * @member {infinitusai.be.IAutoValue|null|undefined} autoKeyValues
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @instance
                 */
                AutoKeyTemplates.prototype.autoKeyValues = null;
    
                /**
                 * Creates a new AutoKeyTemplates instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {infinitusai.be.IAutoKeyTemplates=} [properties] Properties to set
                 * @returns {infinitusai.be.AutoKeyTemplates} AutoKeyTemplates instance
                 */
                AutoKeyTemplates.create = function create(properties) {
                    return new AutoKeyTemplates(properties);
                };
    
                /**
                 * Encodes the specified AutoKeyTemplates message. Does not implicitly {@link infinitusai.be.AutoKeyTemplates.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {infinitusai.be.IAutoKeyTemplates} message AutoKeyTemplates message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoKeyTemplates.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.documentName != null && Object.hasOwnProperty.call(message, "documentName"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.documentName);
                    if (message.autoKeyValues != null && Object.hasOwnProperty.call(message, "autoKeyValues"))
                        $root.infinitusai.be.AutoValue.encode(message.autoKeyValues, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AutoKeyTemplates message, length delimited. Does not implicitly {@link infinitusai.be.AutoKeyTemplates.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {infinitusai.be.IAutoKeyTemplates} message AutoKeyTemplates message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AutoKeyTemplates.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AutoKeyTemplates message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AutoKeyTemplates} AutoKeyTemplates
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoKeyTemplates.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AutoKeyTemplates();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.documentName = reader.int32();
                                break;
                            }
                        case 2: {
                                message.autoKeyValues = $root.infinitusai.be.AutoValue.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AutoKeyTemplates message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AutoKeyTemplates} AutoKeyTemplates
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AutoKeyTemplates.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AutoKeyTemplates message.
                 * @function verify
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AutoKeyTemplates.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.documentName != null && message.hasOwnProperty("documentName"))
                        switch (message.documentName) {
                        default:
                            return "documentName: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 4:
                        case 5:
                        case 7:
                        case 9:
                        case 10:
                        case 13:
                        case 14:
                        case 15:
                        case 11:
                        case 12:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 3:
                        case 6:
                        case 8:
                            break;
                        }
                    if (message.autoKeyValues != null && message.hasOwnProperty("autoKeyValues")) {
                        var error = $root.infinitusai.be.AutoValue.verify(message.autoKeyValues);
                        if (error)
                            return "autoKeyValues." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an AutoKeyTemplates message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AutoKeyTemplates} AutoKeyTemplates
                 */
                AutoKeyTemplates.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AutoKeyTemplates)
                        return object;
                    var message = new $root.infinitusai.be.AutoKeyTemplates();
                    switch (object.documentName) {
                    default:
                        if (typeof object.documentName === "number") {
                            message.documentName = object.documentName;
                            break;
                        }
                        break;
                    case "AUTO_KEY_UNKNOWN":
                    case 0:
                        message.documentName = 0;
                        break;
                    case "AUTO_KEY_PAYER_NAME":
                    case 1:
                        message.documentName = 1;
                        break;
                    case "AUTO_KEY_SPECIALTY_PHARMACY":
                    case 2:
                        message.documentName = 2;
                        break;
                    case "AUTO_KEY_PLAN_NAME":
                    case 4:
                        message.documentName = 4;
                        break;
                    case "AUTO_KEY_TASK_NOTES":
                    case 5:
                        message.documentName = 5;
                        break;
                    case "AUTO_KEY_CALL_FEEDBACK":
                    case 7:
                        message.documentName = 7;
                        break;
                    case "AUTO_KEY_PA_ENTITY":
                    case 9:
                        message.documentName = 9;
                        break;
                    case "AUTO_KEY_PA_CONTACT":
                    case 10:
                        message.documentName = 10;
                        break;
                    case "AUTO_KEY_PA_PHONE":
                    case 13:
                        message.documentName = 13;
                        break;
                    case "AUTO_KEY_PA_WEB":
                    case 14:
                        message.documentName = 14;
                        break;
                    case "AUTO_KEY_POTENTIAL_ADVERSE_EVENT_NOTES":
                    case 15:
                        message.documentName = 15;
                        break;
                    case "AUTO_KEY_OTHER_INSURANCE_NAME":
                    case 11:
                        message.documentName = 11;
                        break;
                    case "AUTO_KEY_STEP_THERAPY_TREATMENT":
                    case 12:
                        message.documentName = 12;
                        break;
                    case "AUTO_KEY_ACCEPTED_WORDS":
                    case 16:
                        message.documentName = 16;
                        break;
                    case "AUTO_KEY_GROUP_NAME":
                    case 17:
                        message.documentName = 17;
                        break;
                    case "AUTO_KEY_PA_TURNAROUND_TIME":
                    case 18:
                        message.documentName = 18;
                        break;
                    case "AUTO_KEY_AGENT_NAME":
                    case 19:
                        message.documentName = 19;
                        break;
                    case "AUTO_KEY_GROUP_NUMBER":
                    case 20:
                        message.documentName = 20;
                        break;
                    case "AUTO_KEY_PROCESSOR_CONTROL_NUMBER":
                    case 21:
                        message.documentName = 21;
                        break;
                    case "AUTO_KEY_BANK_IDENTIFICATION_NUMBER":
                    case 22:
                        message.documentName = 22;
                        break;
                    case "AUTO_KEY_CREATE_MESSAGE":
                    case 3:
                        message.documentName = 3;
                        break;
                    case "AUTO_KEY_PA_NOTES":
                    case 6:
                        message.documentName = 6;
                        break;
                    case "AUTO_KEY_OTHER_INSURANCE_NOTES":
                    case 8:
                        message.documentName = 8;
                        break;
                    }
                    if (object.autoKeyValues != null) {
                        if (typeof object.autoKeyValues !== "object")
                            throw TypeError(".infinitusai.be.AutoKeyTemplates.autoKeyValues: object expected");
                        message.autoKeyValues = $root.infinitusai.be.AutoValue.fromObject(object.autoKeyValues);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AutoKeyTemplates message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {infinitusai.be.AutoKeyTemplates} message AutoKeyTemplates
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AutoKeyTemplates.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.documentName = options.enums === String ? "AUTO_KEY_UNKNOWN" : 0;
                        object.autoKeyValues = null;
                    }
                    if (message.documentName != null && message.hasOwnProperty("documentName"))
                        object.documentName = options.enums === String ? $root.infinitusai.be.AutoKey[message.documentName] === undefined ? message.documentName : $root.infinitusai.be.AutoKey[message.documentName] : message.documentName;
                    if (message.autoKeyValues != null && message.hasOwnProperty("autoKeyValues"))
                        object.autoKeyValues = $root.infinitusai.be.AutoValue.toObject(message.autoKeyValues, options);
                    return object;
                };
    
                /**
                 * Converts this AutoKeyTemplates to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AutoKeyTemplates.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AutoKeyTemplates
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AutoKeyTemplates
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AutoKeyTemplates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AutoKeyTemplates";
                };
    
                return AutoKeyTemplates;
            })();
    
            be.NlpValidateCallOutputsResponse = (function() {
    
                /**
                 * Properties of a NlpValidateCallOutputsResponse.
                 * @memberof infinitusai.be
                 * @interface INlpValidateCallOutputsResponse
                 * @property {Array.<infinitusai.be.ICallOutputsDataValidationError>|null} [errors] NlpValidateCallOutputsResponse errors
                 */
    
                /**
                 * Constructs a new NlpValidateCallOutputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NlpValidateCallOutputsResponse.
                 * @implements INlpValidateCallOutputsResponse
                 * @constructor
                 * @param {infinitusai.be.INlpValidateCallOutputsResponse=} [properties] Properties to set
                 */
                function NlpValidateCallOutputsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NlpValidateCallOutputsResponse errors.
                 * @member {Array.<infinitusai.be.ICallOutputsDataValidationError>} errors
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @instance
                 */
                NlpValidateCallOutputsResponse.prototype.errors = $util.emptyArray;
    
                /**
                 * Creates a new NlpValidateCallOutputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.INlpValidateCallOutputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.NlpValidateCallOutputsResponse} NlpValidateCallOutputsResponse instance
                 */
                NlpValidateCallOutputsResponse.create = function create(properties) {
                    return new NlpValidateCallOutputsResponse(properties);
                };
    
                /**
                 * Encodes the specified NlpValidateCallOutputsResponse message. Does not implicitly {@link infinitusai.be.NlpValidateCallOutputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.INlpValidateCallOutputsResponse} message NlpValidateCallOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpValidateCallOutputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.errors != null && message.errors.length)
                        for (var i = 0; i < message.errors.length; ++i)
                            $root.infinitusai.be.CallOutputsDataValidationError.encode(message.errors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified NlpValidateCallOutputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.NlpValidateCallOutputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.INlpValidateCallOutputsResponse} message NlpValidateCallOutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpValidateCallOutputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NlpValidateCallOutputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NlpValidateCallOutputsResponse} NlpValidateCallOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpValidateCallOutputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NlpValidateCallOutputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.errors && message.errors.length))
                                    message.errors = [];
                                message.errors.push($root.infinitusai.be.CallOutputsDataValidationError.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NlpValidateCallOutputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NlpValidateCallOutputsResponse} NlpValidateCallOutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpValidateCallOutputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NlpValidateCallOutputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NlpValidateCallOutputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (var i = 0; i < message.errors.length; ++i) {
                            var error = $root.infinitusai.be.CallOutputsDataValidationError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a NlpValidateCallOutputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NlpValidateCallOutputsResponse} NlpValidateCallOutputsResponse
                 */
                NlpValidateCallOutputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NlpValidateCallOutputsResponse)
                        return object;
                    var message = new $root.infinitusai.be.NlpValidateCallOutputsResponse();
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".infinitusai.be.NlpValidateCallOutputsResponse.errors: array expected");
                        message.errors = [];
                        for (var i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".infinitusai.be.NlpValidateCallOutputsResponse.errors: object expected");
                            message.errors[i] = $root.infinitusai.be.CallOutputsDataValidationError.fromObject(object.errors[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a NlpValidateCallOutputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {infinitusai.be.NlpValidateCallOutputsResponse} message NlpValidateCallOutputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NlpValidateCallOutputsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (var j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.infinitusai.be.CallOutputsDataValidationError.toObject(message.errors[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this NlpValidateCallOutputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NlpValidateCallOutputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NlpValidateCallOutputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NlpValidateCallOutputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NlpValidateCallOutputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NlpValidateCallOutputsResponse";
                };
    
                return NlpValidateCallOutputsResponse;
            })();
    
            be.GetChcEligibilityInfoRequest = (function() {
    
                /**
                 * Properties of a GetChcEligibilityInfoRequest.
                 * @memberof infinitusai.be
                 * @interface IGetChcEligibilityInfoRequest
                 * @property {infinitusai.be.IPayerInfo|null} [payerInfo] GetChcEligibilityInfoRequest payerInfo
                 * @property {infinitusai.be.IProviderInfo|null} [providerInfo] GetChcEligibilityInfoRequest providerInfo
                 * @property {infinitusai.be.IPatientInfo|null} [patientInfo] GetChcEligibilityInfoRequest patientInfo
                 * @property {infinitusai.be.IPolicyInfo|null} [policyInfo] GetChcEligibilityInfoRequest policyInfo
                 * @property {infinitusai.be.ITreatmentInfo|null} [treatmentInfo] GetChcEligibilityInfoRequest treatmentInfo
                 */
    
                /**
                 * Constructs a new GetChcEligibilityInfoRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetChcEligibilityInfoRequest.
                 * @implements IGetChcEligibilityInfoRequest
                 * @constructor
                 * @param {infinitusai.be.IGetChcEligibilityInfoRequest=} [properties] Properties to set
                 */
                function GetChcEligibilityInfoRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetChcEligibilityInfoRequest payerInfo.
                 * @member {infinitusai.be.IPayerInfo|null|undefined} payerInfo
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @instance
                 */
                GetChcEligibilityInfoRequest.prototype.payerInfo = null;
    
                /**
                 * GetChcEligibilityInfoRequest providerInfo.
                 * @member {infinitusai.be.IProviderInfo|null|undefined} providerInfo
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @instance
                 */
                GetChcEligibilityInfoRequest.prototype.providerInfo = null;
    
                /**
                 * GetChcEligibilityInfoRequest patientInfo.
                 * @member {infinitusai.be.IPatientInfo|null|undefined} patientInfo
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @instance
                 */
                GetChcEligibilityInfoRequest.prototype.patientInfo = null;
    
                /**
                 * GetChcEligibilityInfoRequest policyInfo.
                 * @member {infinitusai.be.IPolicyInfo|null|undefined} policyInfo
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @instance
                 */
                GetChcEligibilityInfoRequest.prototype.policyInfo = null;
    
                /**
                 * GetChcEligibilityInfoRequest treatmentInfo.
                 * @member {infinitusai.be.ITreatmentInfo|null|undefined} treatmentInfo
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @instance
                 */
                GetChcEligibilityInfoRequest.prototype.treatmentInfo = null;
    
                /**
                 * Creates a new GetChcEligibilityInfoRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {infinitusai.be.IGetChcEligibilityInfoRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetChcEligibilityInfoRequest} GetChcEligibilityInfoRequest instance
                 */
                GetChcEligibilityInfoRequest.create = function create(properties) {
                    return new GetChcEligibilityInfoRequest(properties);
                };
    
                /**
                 * Encodes the specified GetChcEligibilityInfoRequest message. Does not implicitly {@link infinitusai.be.GetChcEligibilityInfoRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {infinitusai.be.IGetChcEligibilityInfoRequest} message GetChcEligibilityInfoRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetChcEligibilityInfoRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payerInfo != null && Object.hasOwnProperty.call(message, "payerInfo"))
                        $root.infinitusai.be.PayerInfo.encode(message.payerInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.providerInfo != null && Object.hasOwnProperty.call(message, "providerInfo"))
                        $root.infinitusai.be.ProviderInfo.encode(message.providerInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.patientInfo != null && Object.hasOwnProperty.call(message, "patientInfo"))
                        $root.infinitusai.be.PatientInfo.encode(message.patientInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.policyInfo != null && Object.hasOwnProperty.call(message, "policyInfo"))
                        $root.infinitusai.be.PolicyInfo.encode(message.policyInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.treatmentInfo != null && Object.hasOwnProperty.call(message, "treatmentInfo"))
                        $root.infinitusai.be.TreatmentInfo.encode(message.treatmentInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetChcEligibilityInfoRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetChcEligibilityInfoRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {infinitusai.be.IGetChcEligibilityInfoRequest} message GetChcEligibilityInfoRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetChcEligibilityInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetChcEligibilityInfoRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetChcEligibilityInfoRequest} GetChcEligibilityInfoRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetChcEligibilityInfoRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetChcEligibilityInfoRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.payerInfo = $root.infinitusai.be.PayerInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.providerInfo = $root.infinitusai.be.ProviderInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.patientInfo = $root.infinitusai.be.PatientInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.policyInfo = $root.infinitusai.be.PolicyInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.treatmentInfo = $root.infinitusai.be.TreatmentInfo.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetChcEligibilityInfoRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetChcEligibilityInfoRequest} GetChcEligibilityInfoRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetChcEligibilityInfoRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetChcEligibilityInfoRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetChcEligibilityInfoRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payerInfo != null && message.hasOwnProperty("payerInfo")) {
                        var error = $root.infinitusai.be.PayerInfo.verify(message.payerInfo);
                        if (error)
                            return "payerInfo." + error;
                    }
                    if (message.providerInfo != null && message.hasOwnProperty("providerInfo")) {
                        var error = $root.infinitusai.be.ProviderInfo.verify(message.providerInfo);
                        if (error)
                            return "providerInfo." + error;
                    }
                    if (message.patientInfo != null && message.hasOwnProperty("patientInfo")) {
                        var error = $root.infinitusai.be.PatientInfo.verify(message.patientInfo);
                        if (error)
                            return "patientInfo." + error;
                    }
                    if (message.policyInfo != null && message.hasOwnProperty("policyInfo")) {
                        var error = $root.infinitusai.be.PolicyInfo.verify(message.policyInfo);
                        if (error)
                            return "policyInfo." + error;
                    }
                    if (message.treatmentInfo != null && message.hasOwnProperty("treatmentInfo")) {
                        var error = $root.infinitusai.be.TreatmentInfo.verify(message.treatmentInfo);
                        if (error)
                            return "treatmentInfo." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetChcEligibilityInfoRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetChcEligibilityInfoRequest} GetChcEligibilityInfoRequest
                 */
                GetChcEligibilityInfoRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetChcEligibilityInfoRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetChcEligibilityInfoRequest();
                    if (object.payerInfo != null) {
                        if (typeof object.payerInfo !== "object")
                            throw TypeError(".infinitusai.be.GetChcEligibilityInfoRequest.payerInfo: object expected");
                        message.payerInfo = $root.infinitusai.be.PayerInfo.fromObject(object.payerInfo);
                    }
                    if (object.providerInfo != null) {
                        if (typeof object.providerInfo !== "object")
                            throw TypeError(".infinitusai.be.GetChcEligibilityInfoRequest.providerInfo: object expected");
                        message.providerInfo = $root.infinitusai.be.ProviderInfo.fromObject(object.providerInfo);
                    }
                    if (object.patientInfo != null) {
                        if (typeof object.patientInfo !== "object")
                            throw TypeError(".infinitusai.be.GetChcEligibilityInfoRequest.patientInfo: object expected");
                        message.patientInfo = $root.infinitusai.be.PatientInfo.fromObject(object.patientInfo);
                    }
                    if (object.policyInfo != null) {
                        if (typeof object.policyInfo !== "object")
                            throw TypeError(".infinitusai.be.GetChcEligibilityInfoRequest.policyInfo: object expected");
                        message.policyInfo = $root.infinitusai.be.PolicyInfo.fromObject(object.policyInfo);
                    }
                    if (object.treatmentInfo != null) {
                        if (typeof object.treatmentInfo !== "object")
                            throw TypeError(".infinitusai.be.GetChcEligibilityInfoRequest.treatmentInfo: object expected");
                        message.treatmentInfo = $root.infinitusai.be.TreatmentInfo.fromObject(object.treatmentInfo);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetChcEligibilityInfoRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {infinitusai.be.GetChcEligibilityInfoRequest} message GetChcEligibilityInfoRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetChcEligibilityInfoRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.payerInfo = null;
                        object.providerInfo = null;
                        object.patientInfo = null;
                        object.policyInfo = null;
                        object.treatmentInfo = null;
                    }
                    if (message.payerInfo != null && message.hasOwnProperty("payerInfo"))
                        object.payerInfo = $root.infinitusai.be.PayerInfo.toObject(message.payerInfo, options);
                    if (message.providerInfo != null && message.hasOwnProperty("providerInfo"))
                        object.providerInfo = $root.infinitusai.be.ProviderInfo.toObject(message.providerInfo, options);
                    if (message.patientInfo != null && message.hasOwnProperty("patientInfo"))
                        object.patientInfo = $root.infinitusai.be.PatientInfo.toObject(message.patientInfo, options);
                    if (message.policyInfo != null && message.hasOwnProperty("policyInfo"))
                        object.policyInfo = $root.infinitusai.be.PolicyInfo.toObject(message.policyInfo, options);
                    if (message.treatmentInfo != null && message.hasOwnProperty("treatmentInfo"))
                        object.treatmentInfo = $root.infinitusai.be.TreatmentInfo.toObject(message.treatmentInfo, options);
                    return object;
                };
    
                /**
                 * Converts this GetChcEligibilityInfoRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetChcEligibilityInfoRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetChcEligibilityInfoRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetChcEligibilityInfoRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetChcEligibilityInfoRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetChcEligibilityInfoRequest";
                };
    
                return GetChcEligibilityInfoRequest;
            })();
    
            be.GetChcEligibilityInfoResponse = (function() {
    
                /**
                 * Properties of a GetChcEligibilityInfoResponse.
                 * @memberof infinitusai.be
                 * @interface IGetChcEligibilityInfoResponse
                 * @property {number|null} [statusCode] GetChcEligibilityInfoResponse statusCode
                 * @property {boolean|null} [chcEnabled] GetChcEligibilityInfoResponse chcEnabled
                 * @property {string|null} [debugRequest] GetChcEligibilityInfoResponse debugRequest
                 * @property {string|null} [debugResponse] GetChcEligibilityInfoResponse debugResponse
                 * @property {string|null} [rawData] GetChcEligibilityInfoResponse rawData
                 * @property {infinitusai.be.IChangeHealthcareOutputs|null} [chcOutputs] GetChcEligibilityInfoResponse chcOutputs
                 */
    
                /**
                 * Constructs a new GetChcEligibilityInfoResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetChcEligibilityInfoResponse.
                 * @implements IGetChcEligibilityInfoResponse
                 * @constructor
                 * @param {infinitusai.be.IGetChcEligibilityInfoResponse=} [properties] Properties to set
                 */
                function GetChcEligibilityInfoResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetChcEligibilityInfoResponse statusCode.
                 * @member {number} statusCode
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @instance
                 */
                GetChcEligibilityInfoResponse.prototype.statusCode = 0;
    
                /**
                 * GetChcEligibilityInfoResponse chcEnabled.
                 * @member {boolean} chcEnabled
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @instance
                 */
                GetChcEligibilityInfoResponse.prototype.chcEnabled = false;
    
                /**
                 * GetChcEligibilityInfoResponse debugRequest.
                 * @member {string} debugRequest
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @instance
                 */
                GetChcEligibilityInfoResponse.prototype.debugRequest = "";
    
                /**
                 * GetChcEligibilityInfoResponse debugResponse.
                 * @member {string} debugResponse
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @instance
                 */
                GetChcEligibilityInfoResponse.prototype.debugResponse = "";
    
                /**
                 * GetChcEligibilityInfoResponse rawData.
                 * @member {string} rawData
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @instance
                 */
                GetChcEligibilityInfoResponse.prototype.rawData = "";
    
                /**
                 * GetChcEligibilityInfoResponse chcOutputs.
                 * @member {infinitusai.be.IChangeHealthcareOutputs|null|undefined} chcOutputs
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @instance
                 */
                GetChcEligibilityInfoResponse.prototype.chcOutputs = null;
    
                /**
                 * Creates a new GetChcEligibilityInfoResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {infinitusai.be.IGetChcEligibilityInfoResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetChcEligibilityInfoResponse} GetChcEligibilityInfoResponse instance
                 */
                GetChcEligibilityInfoResponse.create = function create(properties) {
                    return new GetChcEligibilityInfoResponse(properties);
                };
    
                /**
                 * Encodes the specified GetChcEligibilityInfoResponse message. Does not implicitly {@link infinitusai.be.GetChcEligibilityInfoResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {infinitusai.be.IGetChcEligibilityInfoResponse} message GetChcEligibilityInfoResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetChcEligibilityInfoResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.statusCode != null && Object.hasOwnProperty.call(message, "statusCode"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.statusCode);
                    if (message.chcEnabled != null && Object.hasOwnProperty.call(message, "chcEnabled"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.chcEnabled);
                    if (message.debugRequest != null && Object.hasOwnProperty.call(message, "debugRequest"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.debugRequest);
                    if (message.debugResponse != null && Object.hasOwnProperty.call(message, "debugResponse"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.debugResponse);
                    if (message.rawData != null && Object.hasOwnProperty.call(message, "rawData"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.rawData);
                    if (message.chcOutputs != null && Object.hasOwnProperty.call(message, "chcOutputs"))
                        $root.infinitusai.be.ChangeHealthcareOutputs.encode(message.chcOutputs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetChcEligibilityInfoResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetChcEligibilityInfoResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {infinitusai.be.IGetChcEligibilityInfoResponse} message GetChcEligibilityInfoResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetChcEligibilityInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetChcEligibilityInfoResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetChcEligibilityInfoResponse} GetChcEligibilityInfoResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetChcEligibilityInfoResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetChcEligibilityInfoResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.statusCode = reader.int32();
                                break;
                            }
                        case 2: {
                                message.chcEnabled = reader.bool();
                                break;
                            }
                        case 3: {
                                message.debugRequest = reader.string();
                                break;
                            }
                        case 4: {
                                message.debugResponse = reader.string();
                                break;
                            }
                        case 5: {
                                message.rawData = reader.string();
                                break;
                            }
                        case 6: {
                                message.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetChcEligibilityInfoResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetChcEligibilityInfoResponse} GetChcEligibilityInfoResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetChcEligibilityInfoResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetChcEligibilityInfoResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetChcEligibilityInfoResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                        if (!$util.isInteger(message.statusCode))
                            return "statusCode: integer expected";
                    if (message.chcEnabled != null && message.hasOwnProperty("chcEnabled"))
                        if (typeof message.chcEnabled !== "boolean")
                            return "chcEnabled: boolean expected";
                    if (message.debugRequest != null && message.hasOwnProperty("debugRequest"))
                        if (!$util.isString(message.debugRequest))
                            return "debugRequest: string expected";
                    if (message.debugResponse != null && message.hasOwnProperty("debugResponse"))
                        if (!$util.isString(message.debugResponse))
                            return "debugResponse: string expected";
                    if (message.rawData != null && message.hasOwnProperty("rawData"))
                        if (!$util.isString(message.rawData))
                            return "rawData: string expected";
                    if (message.chcOutputs != null && message.hasOwnProperty("chcOutputs")) {
                        var error = $root.infinitusai.be.ChangeHealthcareOutputs.verify(message.chcOutputs);
                        if (error)
                            return "chcOutputs." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetChcEligibilityInfoResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetChcEligibilityInfoResponse} GetChcEligibilityInfoResponse
                 */
                GetChcEligibilityInfoResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetChcEligibilityInfoResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetChcEligibilityInfoResponse();
                    if (object.statusCode != null)
                        message.statusCode = object.statusCode | 0;
                    if (object.chcEnabled != null)
                        message.chcEnabled = Boolean(object.chcEnabled);
                    if (object.debugRequest != null)
                        message.debugRequest = String(object.debugRequest);
                    if (object.debugResponse != null)
                        message.debugResponse = String(object.debugResponse);
                    if (object.rawData != null)
                        message.rawData = String(object.rawData);
                    if (object.chcOutputs != null) {
                        if (typeof object.chcOutputs !== "object")
                            throw TypeError(".infinitusai.be.GetChcEligibilityInfoResponse.chcOutputs: object expected");
                        message.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.fromObject(object.chcOutputs);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetChcEligibilityInfoResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {infinitusai.be.GetChcEligibilityInfoResponse} message GetChcEligibilityInfoResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetChcEligibilityInfoResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.statusCode = 0;
                        object.chcEnabled = false;
                        object.debugRequest = "";
                        object.debugResponse = "";
                        object.rawData = "";
                        object.chcOutputs = null;
                    }
                    if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                        object.statusCode = message.statusCode;
                    if (message.chcEnabled != null && message.hasOwnProperty("chcEnabled"))
                        object.chcEnabled = message.chcEnabled;
                    if (message.debugRequest != null && message.hasOwnProperty("debugRequest"))
                        object.debugRequest = message.debugRequest;
                    if (message.debugResponse != null && message.hasOwnProperty("debugResponse"))
                        object.debugResponse = message.debugResponse;
                    if (message.rawData != null && message.hasOwnProperty("rawData"))
                        object.rawData = message.rawData;
                    if (message.chcOutputs != null && message.hasOwnProperty("chcOutputs"))
                        object.chcOutputs = $root.infinitusai.be.ChangeHealthcareOutputs.toObject(message.chcOutputs, options);
                    return object;
                };
    
                /**
                 * Converts this GetChcEligibilityInfoResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetChcEligibilityInfoResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetChcEligibilityInfoResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetChcEligibilityInfoResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetChcEligibilityInfoResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetChcEligibilityInfoResponse";
                };
    
                return GetChcEligibilityInfoResponse;
            })();
    
            be.GetChcMetricsRequest = (function() {
    
                /**
                 * Properties of a GetChcMetricsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetChcMetricsRequest
                 * @property {infinitusai.be.ITaskDoc|null} [taskDoc] GetChcMetricsRequest taskDoc
                 */
    
                /**
                 * Constructs a new GetChcMetricsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetChcMetricsRequest.
                 * @implements IGetChcMetricsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetChcMetricsRequest=} [properties] Properties to set
                 */
                function GetChcMetricsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetChcMetricsRequest taskDoc.
                 * @member {infinitusai.be.ITaskDoc|null|undefined} taskDoc
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @instance
                 */
                GetChcMetricsRequest.prototype.taskDoc = null;
    
                /**
                 * Creates a new GetChcMetricsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {infinitusai.be.IGetChcMetricsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetChcMetricsRequest} GetChcMetricsRequest instance
                 */
                GetChcMetricsRequest.create = function create(properties) {
                    return new GetChcMetricsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetChcMetricsRequest message. Does not implicitly {@link infinitusai.be.GetChcMetricsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {infinitusai.be.IGetChcMetricsRequest} message GetChcMetricsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetChcMetricsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskDoc != null && Object.hasOwnProperty.call(message, "taskDoc"))
                        $root.infinitusai.be.TaskDoc.encode(message.taskDoc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetChcMetricsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetChcMetricsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {infinitusai.be.IGetChcMetricsRequest} message GetChcMetricsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetChcMetricsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetChcMetricsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetChcMetricsRequest} GetChcMetricsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetChcMetricsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetChcMetricsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskDoc = $root.infinitusai.be.TaskDoc.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetChcMetricsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetChcMetricsRequest} GetChcMetricsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetChcMetricsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetChcMetricsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetChcMetricsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskDoc != null && message.hasOwnProperty("taskDoc")) {
                        var error = $root.infinitusai.be.TaskDoc.verify(message.taskDoc);
                        if (error)
                            return "taskDoc." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetChcMetricsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetChcMetricsRequest} GetChcMetricsRequest
                 */
                GetChcMetricsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetChcMetricsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetChcMetricsRequest();
                    if (object.taskDoc != null) {
                        if (typeof object.taskDoc !== "object")
                            throw TypeError(".infinitusai.be.GetChcMetricsRequest.taskDoc: object expected");
                        message.taskDoc = $root.infinitusai.be.TaskDoc.fromObject(object.taskDoc);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetChcMetricsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {infinitusai.be.GetChcMetricsRequest} message GetChcMetricsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetChcMetricsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskDoc = null;
                    if (message.taskDoc != null && message.hasOwnProperty("taskDoc"))
                        object.taskDoc = $root.infinitusai.be.TaskDoc.toObject(message.taskDoc, options);
                    return object;
                };
    
                /**
                 * Converts this GetChcMetricsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetChcMetricsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetChcMetricsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetChcMetricsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetChcMetricsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetChcMetricsRequest";
                };
    
                return GetChcMetricsRequest;
            })();
    
            be.GetChcMetricsResponse = (function() {
    
                /**
                 * Properties of a GetChcMetricsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetChcMetricsResponse
                 * @property {Array.<infinitusai.be.IChangeHealthcareMetric>|null} [chcMetrics] GetChcMetricsResponse chcMetrics
                 */
    
                /**
                 * Constructs a new GetChcMetricsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetChcMetricsResponse.
                 * @implements IGetChcMetricsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetChcMetricsResponse=} [properties] Properties to set
                 */
                function GetChcMetricsResponse(properties) {
                    this.chcMetrics = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetChcMetricsResponse chcMetrics.
                 * @member {Array.<infinitusai.be.IChangeHealthcareMetric>} chcMetrics
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @instance
                 */
                GetChcMetricsResponse.prototype.chcMetrics = $util.emptyArray;
    
                /**
                 * Creates a new GetChcMetricsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {infinitusai.be.IGetChcMetricsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetChcMetricsResponse} GetChcMetricsResponse instance
                 */
                GetChcMetricsResponse.create = function create(properties) {
                    return new GetChcMetricsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetChcMetricsResponse message. Does not implicitly {@link infinitusai.be.GetChcMetricsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {infinitusai.be.IGetChcMetricsResponse} message GetChcMetricsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetChcMetricsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.chcMetrics != null && message.chcMetrics.length)
                        for (var i = 0; i < message.chcMetrics.length; ++i)
                            $root.infinitusai.be.ChangeHealthcareMetric.encode(message.chcMetrics[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetChcMetricsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetChcMetricsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {infinitusai.be.IGetChcMetricsResponse} message GetChcMetricsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetChcMetricsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetChcMetricsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetChcMetricsResponse} GetChcMetricsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetChcMetricsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetChcMetricsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                if (!(message.chcMetrics && message.chcMetrics.length))
                                    message.chcMetrics = [];
                                message.chcMetrics.push($root.infinitusai.be.ChangeHealthcareMetric.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetChcMetricsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetChcMetricsResponse} GetChcMetricsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetChcMetricsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetChcMetricsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetChcMetricsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.chcMetrics != null && message.hasOwnProperty("chcMetrics")) {
                        if (!Array.isArray(message.chcMetrics))
                            return "chcMetrics: array expected";
                        for (var i = 0; i < message.chcMetrics.length; ++i) {
                            var error = $root.infinitusai.be.ChangeHealthcareMetric.verify(message.chcMetrics[i]);
                            if (error)
                                return "chcMetrics." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetChcMetricsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetChcMetricsResponse} GetChcMetricsResponse
                 */
                GetChcMetricsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetChcMetricsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetChcMetricsResponse();
                    if (object.chcMetrics) {
                        if (!Array.isArray(object.chcMetrics))
                            throw TypeError(".infinitusai.be.GetChcMetricsResponse.chcMetrics: array expected");
                        message.chcMetrics = [];
                        for (var i = 0; i < object.chcMetrics.length; ++i) {
                            if (typeof object.chcMetrics[i] !== "object")
                                throw TypeError(".infinitusai.be.GetChcMetricsResponse.chcMetrics: object expected");
                            message.chcMetrics[i] = $root.infinitusai.be.ChangeHealthcareMetric.fromObject(object.chcMetrics[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetChcMetricsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {infinitusai.be.GetChcMetricsResponse} message GetChcMetricsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetChcMetricsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.chcMetrics = [];
                    if (message.chcMetrics && message.chcMetrics.length) {
                        object.chcMetrics = [];
                        for (var j = 0; j < message.chcMetrics.length; ++j)
                            object.chcMetrics[j] = $root.infinitusai.be.ChangeHealthcareMetric.toObject(message.chcMetrics[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetChcMetricsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetChcMetricsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetChcMetricsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetChcMetricsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetChcMetricsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetChcMetricsResponse";
                };
    
                return GetChcMetricsResponse;
            })();
    
            be.GetIVRHoldTimeMillisRequest = (function() {
    
                /**
                 * Properties of a GetIVRHoldTimeMillisRequest.
                 * @memberof infinitusai.be
                 * @interface IGetIVRHoldTimeMillisRequest
                 * @property {string|null} [outboundNumber] GetIVRHoldTimeMillisRequest outboundNumber
                 */
    
                /**
                 * Constructs a new GetIVRHoldTimeMillisRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetIVRHoldTimeMillisRequest.
                 * @implements IGetIVRHoldTimeMillisRequest
                 * @constructor
                 * @param {infinitusai.be.IGetIVRHoldTimeMillisRequest=} [properties] Properties to set
                 */
                function GetIVRHoldTimeMillisRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetIVRHoldTimeMillisRequest outboundNumber.
                 * @member {string} outboundNumber
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @instance
                 */
                GetIVRHoldTimeMillisRequest.prototype.outboundNumber = "";
    
                /**
                 * Creates a new GetIVRHoldTimeMillisRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {infinitusai.be.IGetIVRHoldTimeMillisRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetIVRHoldTimeMillisRequest} GetIVRHoldTimeMillisRequest instance
                 */
                GetIVRHoldTimeMillisRequest.create = function create(properties) {
                    return new GetIVRHoldTimeMillisRequest(properties);
                };
    
                /**
                 * Encodes the specified GetIVRHoldTimeMillisRequest message. Does not implicitly {@link infinitusai.be.GetIVRHoldTimeMillisRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {infinitusai.be.IGetIVRHoldTimeMillisRequest} message GetIVRHoldTimeMillisRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIVRHoldTimeMillisRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.outboundNumber != null && Object.hasOwnProperty.call(message, "outboundNumber"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.outboundNumber);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetIVRHoldTimeMillisRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetIVRHoldTimeMillisRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {infinitusai.be.IGetIVRHoldTimeMillisRequest} message GetIVRHoldTimeMillisRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIVRHoldTimeMillisRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetIVRHoldTimeMillisRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetIVRHoldTimeMillisRequest} GetIVRHoldTimeMillisRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIVRHoldTimeMillisRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetIVRHoldTimeMillisRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.outboundNumber = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetIVRHoldTimeMillisRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetIVRHoldTimeMillisRequest} GetIVRHoldTimeMillisRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIVRHoldTimeMillisRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetIVRHoldTimeMillisRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIVRHoldTimeMillisRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.outboundNumber != null && message.hasOwnProperty("outboundNumber"))
                        if (!$util.isString(message.outboundNumber))
                            return "outboundNumber: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetIVRHoldTimeMillisRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetIVRHoldTimeMillisRequest} GetIVRHoldTimeMillisRequest
                 */
                GetIVRHoldTimeMillisRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetIVRHoldTimeMillisRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetIVRHoldTimeMillisRequest();
                    if (object.outboundNumber != null)
                        message.outboundNumber = String(object.outboundNumber);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetIVRHoldTimeMillisRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {infinitusai.be.GetIVRHoldTimeMillisRequest} message GetIVRHoldTimeMillisRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIVRHoldTimeMillisRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.outboundNumber = "";
                    if (message.outboundNumber != null && message.hasOwnProperty("outboundNumber"))
                        object.outboundNumber = message.outboundNumber;
                    return object;
                };
    
                /**
                 * Converts this GetIVRHoldTimeMillisRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIVRHoldTimeMillisRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetIVRHoldTimeMillisRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetIVRHoldTimeMillisRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetIVRHoldTimeMillisRequest";
                };
    
                return GetIVRHoldTimeMillisRequest;
            })();
    
            be.GetIVRHoldTimeMillisResponse = (function() {
    
                /**
                 * Properties of a GetIVRHoldTimeMillisResponse.
                 * @memberof infinitusai.be
                 * @interface IGetIVRHoldTimeMillisResponse
                 * @property {number|Long|null} [ivrHoldTimeMillis] GetIVRHoldTimeMillisResponse ivrHoldTimeMillis
                 */
    
                /**
                 * Constructs a new GetIVRHoldTimeMillisResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetIVRHoldTimeMillisResponse.
                 * @implements IGetIVRHoldTimeMillisResponse
                 * @constructor
                 * @param {infinitusai.be.IGetIVRHoldTimeMillisResponse=} [properties] Properties to set
                 */
                function GetIVRHoldTimeMillisResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetIVRHoldTimeMillisResponse ivrHoldTimeMillis.
                 * @member {number|Long} ivrHoldTimeMillis
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @instance
                 */
                GetIVRHoldTimeMillisResponse.prototype.ivrHoldTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new GetIVRHoldTimeMillisResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {infinitusai.be.IGetIVRHoldTimeMillisResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetIVRHoldTimeMillisResponse} GetIVRHoldTimeMillisResponse instance
                 */
                GetIVRHoldTimeMillisResponse.create = function create(properties) {
                    return new GetIVRHoldTimeMillisResponse(properties);
                };
    
                /**
                 * Encodes the specified GetIVRHoldTimeMillisResponse message. Does not implicitly {@link infinitusai.be.GetIVRHoldTimeMillisResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {infinitusai.be.IGetIVRHoldTimeMillisResponse} message GetIVRHoldTimeMillisResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIVRHoldTimeMillisResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ivrHoldTimeMillis != null && Object.hasOwnProperty.call(message, "ivrHoldTimeMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.ivrHoldTimeMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetIVRHoldTimeMillisResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetIVRHoldTimeMillisResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {infinitusai.be.IGetIVRHoldTimeMillisResponse} message GetIVRHoldTimeMillisResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIVRHoldTimeMillisResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetIVRHoldTimeMillisResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetIVRHoldTimeMillisResponse} GetIVRHoldTimeMillisResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIVRHoldTimeMillisResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetIVRHoldTimeMillisResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ivrHoldTimeMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetIVRHoldTimeMillisResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetIVRHoldTimeMillisResponse} GetIVRHoldTimeMillisResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIVRHoldTimeMillisResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetIVRHoldTimeMillisResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIVRHoldTimeMillisResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ivrHoldTimeMillis != null && message.hasOwnProperty("ivrHoldTimeMillis"))
                        if (!$util.isInteger(message.ivrHoldTimeMillis) && !(message.ivrHoldTimeMillis && $util.isInteger(message.ivrHoldTimeMillis.low) && $util.isInteger(message.ivrHoldTimeMillis.high)))
                            return "ivrHoldTimeMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a GetIVRHoldTimeMillisResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetIVRHoldTimeMillisResponse} GetIVRHoldTimeMillisResponse
                 */
                GetIVRHoldTimeMillisResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetIVRHoldTimeMillisResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetIVRHoldTimeMillisResponse();
                    if (object.ivrHoldTimeMillis != null)
                        if ($util.Long)
                            (message.ivrHoldTimeMillis = $util.Long.fromValue(object.ivrHoldTimeMillis)).unsigned = false;
                        else if (typeof object.ivrHoldTimeMillis === "string")
                            message.ivrHoldTimeMillis = parseInt(object.ivrHoldTimeMillis, 10);
                        else if (typeof object.ivrHoldTimeMillis === "number")
                            message.ivrHoldTimeMillis = object.ivrHoldTimeMillis;
                        else if (typeof object.ivrHoldTimeMillis === "object")
                            message.ivrHoldTimeMillis = new $util.LongBits(object.ivrHoldTimeMillis.low >>> 0, object.ivrHoldTimeMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetIVRHoldTimeMillisResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {infinitusai.be.GetIVRHoldTimeMillisResponse} message GetIVRHoldTimeMillisResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIVRHoldTimeMillisResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.ivrHoldTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.ivrHoldTimeMillis = options.longs === String ? "0" : 0;
                    if (message.ivrHoldTimeMillis != null && message.hasOwnProperty("ivrHoldTimeMillis"))
                        if (typeof message.ivrHoldTimeMillis === "number")
                            object.ivrHoldTimeMillis = options.longs === String ? String(message.ivrHoldTimeMillis) : message.ivrHoldTimeMillis;
                        else
                            object.ivrHoldTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.ivrHoldTimeMillis) : options.longs === Number ? new $util.LongBits(message.ivrHoldTimeMillis.low >>> 0, message.ivrHoldTimeMillis.high >>> 0).toNumber() : message.ivrHoldTimeMillis;
                    return object;
                };
    
                /**
                 * Converts this GetIVRHoldTimeMillisResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIVRHoldTimeMillisResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetIVRHoldTimeMillisResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetIVRHoldTimeMillisResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetIVRHoldTimeMillisResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetIVRHoldTimeMillisResponse";
                };
    
                return GetIVRHoldTimeMillisResponse;
            })();
    
            be.GenerateBillingNotesRequest = (function() {
    
                /**
                 * Properties of a GenerateBillingNotesRequest.
                 * @memberof infinitusai.be
                 * @interface IGenerateBillingNotesRequest
                 * @property {infinitusai.be.IBVCallInputs|null} [taskIn] GenerateBillingNotesRequest taskIn
                 * @property {infinitusapi.IINFBVTaskOutput|null} [taskOut] GenerateBillingNotesRequest taskOut
                 * @property {infinitusapi.IINFBVTaskOutput|null} [prevTaskOut] GenerateBillingNotesRequest prevTaskOut
                 * @property {number|Long|null} [taskCompletedMillis] GenerateBillingNotesRequest taskCompletedMillis
                 * @property {string|null} [taskInJson] GenerateBillingNotesRequest taskInJson
                 * @property {infinitusapi.IINFPBMTaskOutput|null} [pbmTaskOut] GenerateBillingNotesRequest pbmTaskOut
                 * @property {infinitusapi.IINFPBMTaskOutput|null} [prevPbmTaskOut] GenerateBillingNotesRequest prevPbmTaskOut
                 * @property {string|null} [orgId] GenerateBillingNotesRequest orgId
                 */
    
                /**
                 * Constructs a new GenerateBillingNotesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateBillingNotesRequest.
                 * @implements IGenerateBillingNotesRequest
                 * @constructor
                 * @param {infinitusai.be.IGenerateBillingNotesRequest=} [properties] Properties to set
                 */
                function GenerateBillingNotesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateBillingNotesRequest taskIn.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} taskIn
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 */
                GenerateBillingNotesRequest.prototype.taskIn = null;
    
                /**
                 * GenerateBillingNotesRequest taskOut.
                 * @member {infinitusapi.IINFBVTaskOutput|null|undefined} taskOut
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 */
                GenerateBillingNotesRequest.prototype.taskOut = null;
    
                /**
                 * GenerateBillingNotesRequest prevTaskOut.
                 * @member {infinitusapi.IINFBVTaskOutput|null|undefined} prevTaskOut
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 */
                GenerateBillingNotesRequest.prototype.prevTaskOut = null;
    
                /**
                 * GenerateBillingNotesRequest taskCompletedMillis.
                 * @member {number|Long} taskCompletedMillis
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 */
                GenerateBillingNotesRequest.prototype.taskCompletedMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateBillingNotesRequest taskInJson.
                 * @member {string} taskInJson
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 */
                GenerateBillingNotesRequest.prototype.taskInJson = "";
    
                /**
                 * GenerateBillingNotesRequest pbmTaskOut.
                 * @member {infinitusapi.IINFPBMTaskOutput|null|undefined} pbmTaskOut
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 */
                GenerateBillingNotesRequest.prototype.pbmTaskOut = null;
    
                /**
                 * GenerateBillingNotesRequest prevPbmTaskOut.
                 * @member {infinitusapi.IINFPBMTaskOutput|null|undefined} prevPbmTaskOut
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 */
                GenerateBillingNotesRequest.prototype.prevPbmTaskOut = null;
    
                /**
                 * GenerateBillingNotesRequest orgId.
                 * @member {string} orgId
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 */
                GenerateBillingNotesRequest.prototype.orgId = "";
    
                /**
                 * Creates a new GenerateBillingNotesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {infinitusai.be.IGenerateBillingNotesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateBillingNotesRequest} GenerateBillingNotesRequest instance
                 */
                GenerateBillingNotesRequest.create = function create(properties) {
                    return new GenerateBillingNotesRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateBillingNotesRequest message. Does not implicitly {@link infinitusai.be.GenerateBillingNotesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {infinitusai.be.IGenerateBillingNotesRequest} message GenerateBillingNotesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateBillingNotesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskIn != null && Object.hasOwnProperty.call(message, "taskIn"))
                        $root.infinitusai.be.BVCallInputs.encode(message.taskIn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.taskOut != null && Object.hasOwnProperty.call(message, "taskOut"))
                        $root.infinitusapi.INFBVTaskOutput.encode(message.taskOut, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.prevTaskOut != null && Object.hasOwnProperty.call(message, "prevTaskOut"))
                        $root.infinitusapi.INFBVTaskOutput.encode(message.prevTaskOut, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.taskCompletedMillis != null && Object.hasOwnProperty.call(message, "taskCompletedMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.taskCompletedMillis);
                    if (message.taskInJson != null && Object.hasOwnProperty.call(message, "taskInJson"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.taskInJson);
                    if (message.pbmTaskOut != null && Object.hasOwnProperty.call(message, "pbmTaskOut"))
                        $root.infinitusapi.INFPBMTaskOutput.encode(message.pbmTaskOut, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.prevPbmTaskOut != null && Object.hasOwnProperty.call(message, "prevPbmTaskOut"))
                        $root.infinitusapi.INFPBMTaskOutput.encode(message.prevPbmTaskOut, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.orgId);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateBillingNotesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GenerateBillingNotesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {infinitusai.be.IGenerateBillingNotesRequest} message GenerateBillingNotesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateBillingNotesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateBillingNotesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateBillingNotesRequest} GenerateBillingNotesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateBillingNotesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateBillingNotesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskIn = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.taskOut = $root.infinitusapi.INFBVTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.prevTaskOut = $root.infinitusapi.INFBVTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.taskCompletedMillis = reader.int64();
                                break;
                            }
                        case 5: {
                                message.taskInJson = reader.string();
                                break;
                            }
                        case 6: {
                                message.pbmTaskOut = $root.infinitusapi.INFPBMTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.prevPbmTaskOut = $root.infinitusapi.INFPBMTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.orgId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateBillingNotesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateBillingNotesRequest} GenerateBillingNotesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateBillingNotesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateBillingNotesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateBillingNotesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskIn != null && message.hasOwnProperty("taskIn")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.taskIn);
                        if (error)
                            return "taskIn." + error;
                    }
                    if (message.taskOut != null && message.hasOwnProperty("taskOut")) {
                        var error = $root.infinitusapi.INFBVTaskOutput.verify(message.taskOut);
                        if (error)
                            return "taskOut." + error;
                    }
                    if (message.prevTaskOut != null && message.hasOwnProperty("prevTaskOut")) {
                        var error = $root.infinitusapi.INFBVTaskOutput.verify(message.prevTaskOut);
                        if (error)
                            return "prevTaskOut." + error;
                    }
                    if (message.taskCompletedMillis != null && message.hasOwnProperty("taskCompletedMillis"))
                        if (!$util.isInteger(message.taskCompletedMillis) && !(message.taskCompletedMillis && $util.isInteger(message.taskCompletedMillis.low) && $util.isInteger(message.taskCompletedMillis.high)))
                            return "taskCompletedMillis: integer|Long expected";
                    if (message.taskInJson != null && message.hasOwnProperty("taskInJson"))
                        if (!$util.isString(message.taskInJson))
                            return "taskInJson: string expected";
                    if (message.pbmTaskOut != null && message.hasOwnProperty("pbmTaskOut")) {
                        var error = $root.infinitusapi.INFPBMTaskOutput.verify(message.pbmTaskOut);
                        if (error)
                            return "pbmTaskOut." + error;
                    }
                    if (message.prevPbmTaskOut != null && message.hasOwnProperty("prevPbmTaskOut")) {
                        var error = $root.infinitusapi.INFPBMTaskOutput.verify(message.prevPbmTaskOut);
                        if (error)
                            return "prevPbmTaskOut." + error;
                    }
                    if (message.orgId != null && message.hasOwnProperty("orgId"))
                        if (!$util.isString(message.orgId))
                            return "orgId: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateBillingNotesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateBillingNotesRequest} GenerateBillingNotesRequest
                 */
                GenerateBillingNotesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateBillingNotesRequest)
                        return object;
                    var message = new $root.infinitusai.be.GenerateBillingNotesRequest();
                    if (object.taskIn != null) {
                        if (typeof object.taskIn !== "object")
                            throw TypeError(".infinitusai.be.GenerateBillingNotesRequest.taskIn: object expected");
                        message.taskIn = $root.infinitusai.be.BVCallInputs.fromObject(object.taskIn);
                    }
                    if (object.taskOut != null) {
                        if (typeof object.taskOut !== "object")
                            throw TypeError(".infinitusai.be.GenerateBillingNotesRequest.taskOut: object expected");
                        message.taskOut = $root.infinitusapi.INFBVTaskOutput.fromObject(object.taskOut);
                    }
                    if (object.prevTaskOut != null) {
                        if (typeof object.prevTaskOut !== "object")
                            throw TypeError(".infinitusai.be.GenerateBillingNotesRequest.prevTaskOut: object expected");
                        message.prevTaskOut = $root.infinitusapi.INFBVTaskOutput.fromObject(object.prevTaskOut);
                    }
                    if (object.taskCompletedMillis != null)
                        if ($util.Long)
                            (message.taskCompletedMillis = $util.Long.fromValue(object.taskCompletedMillis)).unsigned = false;
                        else if (typeof object.taskCompletedMillis === "string")
                            message.taskCompletedMillis = parseInt(object.taskCompletedMillis, 10);
                        else if (typeof object.taskCompletedMillis === "number")
                            message.taskCompletedMillis = object.taskCompletedMillis;
                        else if (typeof object.taskCompletedMillis === "object")
                            message.taskCompletedMillis = new $util.LongBits(object.taskCompletedMillis.low >>> 0, object.taskCompletedMillis.high >>> 0).toNumber();
                    if (object.taskInJson != null)
                        message.taskInJson = String(object.taskInJson);
                    if (object.pbmTaskOut != null) {
                        if (typeof object.pbmTaskOut !== "object")
                            throw TypeError(".infinitusai.be.GenerateBillingNotesRequest.pbmTaskOut: object expected");
                        message.pbmTaskOut = $root.infinitusapi.INFPBMTaskOutput.fromObject(object.pbmTaskOut);
                    }
                    if (object.prevPbmTaskOut != null) {
                        if (typeof object.prevPbmTaskOut !== "object")
                            throw TypeError(".infinitusai.be.GenerateBillingNotesRequest.prevPbmTaskOut: object expected");
                        message.prevPbmTaskOut = $root.infinitusapi.INFPBMTaskOutput.fromObject(object.prevPbmTaskOut);
                    }
                    if (object.orgId != null)
                        message.orgId = String(object.orgId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateBillingNotesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {infinitusai.be.GenerateBillingNotesRequest} message GenerateBillingNotesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateBillingNotesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskIn = null;
                        object.taskOut = null;
                        object.prevTaskOut = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.taskCompletedMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.taskCompletedMillis = options.longs === String ? "0" : 0;
                        object.taskInJson = "";
                        object.pbmTaskOut = null;
                        object.prevPbmTaskOut = null;
                        object.orgId = "";
                    }
                    if (message.taskIn != null && message.hasOwnProperty("taskIn"))
                        object.taskIn = $root.infinitusai.be.BVCallInputs.toObject(message.taskIn, options);
                    if (message.taskOut != null && message.hasOwnProperty("taskOut"))
                        object.taskOut = $root.infinitusapi.INFBVTaskOutput.toObject(message.taskOut, options);
                    if (message.prevTaskOut != null && message.hasOwnProperty("prevTaskOut"))
                        object.prevTaskOut = $root.infinitusapi.INFBVTaskOutput.toObject(message.prevTaskOut, options);
                    if (message.taskCompletedMillis != null && message.hasOwnProperty("taskCompletedMillis"))
                        if (typeof message.taskCompletedMillis === "number")
                            object.taskCompletedMillis = options.longs === String ? String(message.taskCompletedMillis) : message.taskCompletedMillis;
                        else
                            object.taskCompletedMillis = options.longs === String ? $util.Long.prototype.toString.call(message.taskCompletedMillis) : options.longs === Number ? new $util.LongBits(message.taskCompletedMillis.low >>> 0, message.taskCompletedMillis.high >>> 0).toNumber() : message.taskCompletedMillis;
                    if (message.taskInJson != null && message.hasOwnProperty("taskInJson"))
                        object.taskInJson = message.taskInJson;
                    if (message.pbmTaskOut != null && message.hasOwnProperty("pbmTaskOut"))
                        object.pbmTaskOut = $root.infinitusapi.INFPBMTaskOutput.toObject(message.pbmTaskOut, options);
                    if (message.prevPbmTaskOut != null && message.hasOwnProperty("prevPbmTaskOut"))
                        object.prevPbmTaskOut = $root.infinitusapi.INFPBMTaskOutput.toObject(message.prevPbmTaskOut, options);
                    if (message.orgId != null && message.hasOwnProperty("orgId"))
                        object.orgId = message.orgId;
                    return object;
                };
    
                /**
                 * Converts this GenerateBillingNotesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateBillingNotesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateBillingNotesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateBillingNotesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateBillingNotesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateBillingNotesRequest";
                };
    
                return GenerateBillingNotesRequest;
            })();
    
            be.GenerateGenericBillingNotesRequest = (function() {
    
                /**
                 * Properties of a GenerateGenericBillingNotesRequest.
                 * @memberof infinitusai.be
                 * @interface IGenerateGenericBillingNotesRequest
                 * @property {infinitusai.be.IBVCallInputs|null} [taskIn] GenerateGenericBillingNotesRequest taskIn
                 * @property {infinitusai.be.TaskType|null} [taskType] GenerateGenericBillingNotesRequest taskType
                 * @property {infinitusapi.IINFPBMDiscoveryTaskOutput|null} [pbmDiscoveryTaskOut] GenerateGenericBillingNotesRequest pbmDiscoveryTaskOut
                 */
    
                /**
                 * Constructs a new GenerateGenericBillingNotesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateGenericBillingNotesRequest.
                 * @implements IGenerateGenericBillingNotesRequest
                 * @constructor
                 * @param {infinitusai.be.IGenerateGenericBillingNotesRequest=} [properties] Properties to set
                 */
                function GenerateGenericBillingNotesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateGenericBillingNotesRequest taskIn.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} taskIn
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @instance
                 */
                GenerateGenericBillingNotesRequest.prototype.taskIn = null;
    
                /**
                 * GenerateGenericBillingNotesRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @instance
                 */
                GenerateGenericBillingNotesRequest.prototype.taskType = 0;
    
                /**
                 * GenerateGenericBillingNotesRequest pbmDiscoveryTaskOut.
                 * @member {infinitusapi.IINFPBMDiscoveryTaskOutput|null|undefined} pbmDiscoveryTaskOut
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @instance
                 */
                GenerateGenericBillingNotesRequest.prototype.pbmDiscoveryTaskOut = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * GenerateGenericBillingNotesRequest taskOutput.
                 * @member {"pbmDiscoveryTaskOut"|undefined} taskOutput
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @instance
                 */
                Object.defineProperty(GenerateGenericBillingNotesRequest.prototype, "taskOutput", {
                    get: $util.oneOfGetter($oneOfFields = ["pbmDiscoveryTaskOut"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new GenerateGenericBillingNotesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {infinitusai.be.IGenerateGenericBillingNotesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateGenericBillingNotesRequest} GenerateGenericBillingNotesRequest instance
                 */
                GenerateGenericBillingNotesRequest.create = function create(properties) {
                    return new GenerateGenericBillingNotesRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateGenericBillingNotesRequest message. Does not implicitly {@link infinitusai.be.GenerateGenericBillingNotesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {infinitusai.be.IGenerateGenericBillingNotesRequest} message GenerateGenericBillingNotesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateGenericBillingNotesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskIn != null && Object.hasOwnProperty.call(message, "taskIn"))
                        $root.infinitusai.be.BVCallInputs.encode(message.taskIn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    if (message.pbmDiscoveryTaskOut != null && Object.hasOwnProperty.call(message, "pbmDiscoveryTaskOut"))
                        $root.infinitusapi.INFPBMDiscoveryTaskOutput.encode(message.pbmDiscoveryTaskOut, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateGenericBillingNotesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GenerateGenericBillingNotesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {infinitusai.be.IGenerateGenericBillingNotesRequest} message GenerateGenericBillingNotesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateGenericBillingNotesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateGenericBillingNotesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateGenericBillingNotesRequest} GenerateGenericBillingNotesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateGenericBillingNotesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateGenericBillingNotesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskIn = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.pbmDiscoveryTaskOut = $root.infinitusapi.INFPBMDiscoveryTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateGenericBillingNotesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateGenericBillingNotesRequest} GenerateGenericBillingNotesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateGenericBillingNotesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateGenericBillingNotesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateGenericBillingNotesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.taskIn != null && message.hasOwnProperty("taskIn")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.taskIn);
                        if (error)
                            return "taskIn." + error;
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.pbmDiscoveryTaskOut != null && message.hasOwnProperty("pbmDiscoveryTaskOut")) {
                        properties.taskOutput = 1;
                        {
                            var error = $root.infinitusapi.INFPBMDiscoveryTaskOutput.verify(message.pbmDiscoveryTaskOut);
                            if (error)
                                return "pbmDiscoveryTaskOut." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GenerateGenericBillingNotesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateGenericBillingNotesRequest} GenerateGenericBillingNotesRequest
                 */
                GenerateGenericBillingNotesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateGenericBillingNotesRequest)
                        return object;
                    var message = new $root.infinitusai.be.GenerateGenericBillingNotesRequest();
                    if (object.taskIn != null) {
                        if (typeof object.taskIn !== "object")
                            throw TypeError(".infinitusai.be.GenerateGenericBillingNotesRequest.taskIn: object expected");
                        message.taskIn = $root.infinitusai.be.BVCallInputs.fromObject(object.taskIn);
                    }
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.pbmDiscoveryTaskOut != null) {
                        if (typeof object.pbmDiscoveryTaskOut !== "object")
                            throw TypeError(".infinitusai.be.GenerateGenericBillingNotesRequest.pbmDiscoveryTaskOut: object expected");
                        message.pbmDiscoveryTaskOut = $root.infinitusapi.INFPBMDiscoveryTaskOutput.fromObject(object.pbmDiscoveryTaskOut);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateGenericBillingNotesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {infinitusai.be.GenerateGenericBillingNotesRequest} message GenerateGenericBillingNotesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateGenericBillingNotesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskIn = null;
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                    }
                    if (message.taskIn != null && message.hasOwnProperty("taskIn"))
                        object.taskIn = $root.infinitusai.be.BVCallInputs.toObject(message.taskIn, options);
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.pbmDiscoveryTaskOut != null && message.hasOwnProperty("pbmDiscoveryTaskOut")) {
                        object.pbmDiscoveryTaskOut = $root.infinitusapi.INFPBMDiscoveryTaskOutput.toObject(message.pbmDiscoveryTaskOut, options);
                        if (options.oneofs)
                            object.taskOutput = "pbmDiscoveryTaskOut";
                    }
                    return object;
                };
    
                /**
                 * Converts this GenerateGenericBillingNotesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateGenericBillingNotesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateGenericBillingNotesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateGenericBillingNotesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateGenericBillingNotesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateGenericBillingNotesRequest";
                };
    
                return GenerateGenericBillingNotesRequest;
            })();
    
            be.GenerateBillingNotesResponse = (function() {
    
                /**
                 * Properties of a GenerateBillingNotesResponse.
                 * @memberof infinitusai.be
                 * @interface IGenerateBillingNotesResponse
                 * @property {string|null} [billingNotes] GenerateBillingNotesResponse billingNotes
                 */
    
                /**
                 * Constructs a new GenerateBillingNotesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateBillingNotesResponse.
                 * @implements IGenerateBillingNotesResponse
                 * @constructor
                 * @param {infinitusai.be.IGenerateBillingNotesResponse=} [properties] Properties to set
                 */
                function GenerateBillingNotesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateBillingNotesResponse billingNotes.
                 * @member {string} billingNotes
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @instance
                 */
                GenerateBillingNotesResponse.prototype.billingNotes = "";
    
                /**
                 * Creates a new GenerateBillingNotesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {infinitusai.be.IGenerateBillingNotesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateBillingNotesResponse} GenerateBillingNotesResponse instance
                 */
                GenerateBillingNotesResponse.create = function create(properties) {
                    return new GenerateBillingNotesResponse(properties);
                };
    
                /**
                 * Encodes the specified GenerateBillingNotesResponse message. Does not implicitly {@link infinitusai.be.GenerateBillingNotesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {infinitusai.be.IGenerateBillingNotesResponse} message GenerateBillingNotesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateBillingNotesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.billingNotes != null && Object.hasOwnProperty.call(message, "billingNotes"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.billingNotes);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateBillingNotesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GenerateBillingNotesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {infinitusai.be.IGenerateBillingNotesResponse} message GenerateBillingNotesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateBillingNotesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateBillingNotesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateBillingNotesResponse} GenerateBillingNotesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateBillingNotesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateBillingNotesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.billingNotes = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateBillingNotesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateBillingNotesResponse} GenerateBillingNotesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateBillingNotesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateBillingNotesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateBillingNotesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.billingNotes != null && message.hasOwnProperty("billingNotes"))
                        if (!$util.isString(message.billingNotes))
                            return "billingNotes: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateBillingNotesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateBillingNotesResponse} GenerateBillingNotesResponse
                 */
                GenerateBillingNotesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateBillingNotesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GenerateBillingNotesResponse();
                    if (object.billingNotes != null)
                        message.billingNotes = String(object.billingNotes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateBillingNotesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {infinitusai.be.GenerateBillingNotesResponse} message GenerateBillingNotesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateBillingNotesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.billingNotes = "";
                    if (message.billingNotes != null && message.hasOwnProperty("billingNotes"))
                        object.billingNotes = message.billingNotes;
                    return object;
                };
    
                /**
                 * Converts this GenerateBillingNotesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateBillingNotesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateBillingNotesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateBillingNotesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateBillingNotesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateBillingNotesResponse";
                };
    
                return GenerateBillingNotesResponse;
            })();
    
            be.ExtractIVROutputsRequest = (function() {
    
                /**
                 * Properties of an ExtractIVROutputsRequest.
                 * @memberof infinitusai.be
                 * @interface IExtractIVROutputsRequest
                 * @property {string|null} [callTranscript] ExtractIVROutputsRequest callTranscript
                 * @property {string|null} [payerId] ExtractIVROutputsRequest payerId
                 */
    
                /**
                 * Constructs a new ExtractIVROutputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExtractIVROutputsRequest.
                 * @implements IExtractIVROutputsRequest
                 * @constructor
                 * @param {infinitusai.be.IExtractIVROutputsRequest=} [properties] Properties to set
                 */
                function ExtractIVROutputsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExtractIVROutputsRequest callTranscript.
                 * @member {string} callTranscript
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @instance
                 */
                ExtractIVROutputsRequest.prototype.callTranscript = "";
    
                /**
                 * ExtractIVROutputsRequest payerId.
                 * @member {string} payerId
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @instance
                 */
                ExtractIVROutputsRequest.prototype.payerId = "";
    
                /**
                 * Creates a new ExtractIVROutputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {infinitusai.be.IExtractIVROutputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ExtractIVROutputsRequest} ExtractIVROutputsRequest instance
                 */
                ExtractIVROutputsRequest.create = function create(properties) {
                    return new ExtractIVROutputsRequest(properties);
                };
    
                /**
                 * Encodes the specified ExtractIVROutputsRequest message. Does not implicitly {@link infinitusai.be.ExtractIVROutputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {infinitusai.be.IExtractIVROutputsRequest} message ExtractIVROutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtractIVROutputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callTranscript != null && Object.hasOwnProperty.call(message, "callTranscript"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.callTranscript);
                    if (message.payerId != null && Object.hasOwnProperty.call(message, "payerId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.payerId);
                    return writer;
                };
    
                /**
                 * Encodes the specified ExtractIVROutputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.ExtractIVROutputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {infinitusai.be.IExtractIVROutputsRequest} message ExtractIVROutputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtractIVROutputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExtractIVROutputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExtractIVROutputsRequest} ExtractIVROutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtractIVROutputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExtractIVROutputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.callTranscript = reader.string();
                                break;
                            }
                        case 2: {
                                message.payerId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExtractIVROutputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExtractIVROutputsRequest} ExtractIVROutputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtractIVROutputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExtractIVROutputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtractIVROutputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callTranscript != null && message.hasOwnProperty("callTranscript"))
                        if (!$util.isString(message.callTranscript))
                            return "callTranscript: string expected";
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        if (!$util.isString(message.payerId))
                            return "payerId: string expected";
                    return null;
                };
    
                /**
                 * Creates an ExtractIVROutputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExtractIVROutputsRequest} ExtractIVROutputsRequest
                 */
                ExtractIVROutputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExtractIVROutputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.ExtractIVROutputsRequest();
                    if (object.callTranscript != null)
                        message.callTranscript = String(object.callTranscript);
                    if (object.payerId != null)
                        message.payerId = String(object.payerId);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExtractIVROutputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {infinitusai.be.ExtractIVROutputsRequest} message ExtractIVROutputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtractIVROutputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.callTranscript = "";
                        object.payerId = "";
                    }
                    if (message.callTranscript != null && message.hasOwnProperty("callTranscript"))
                        object.callTranscript = message.callTranscript;
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        object.payerId = message.payerId;
                    return object;
                };
    
                /**
                 * Converts this ExtractIVROutputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtractIVROutputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExtractIVROutputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExtractIVROutputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExtractIVROutputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExtractIVROutputsRequest";
                };
    
                return ExtractIVROutputsRequest;
            })();
    
            be.ExtractIVROutputsResponse = (function() {
    
                /**
                 * Properties of an ExtractIVROutputsResponse.
                 * @memberof infinitusai.be
                 * @interface IExtractIVROutputsResponse
                 * @property {string|null} [ivrOutputsJson] ExtractIVROutputsResponse ivrOutputsJson
                 */
    
                /**
                 * Constructs a new ExtractIVROutputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ExtractIVROutputsResponse.
                 * @implements IExtractIVROutputsResponse
                 * @constructor
                 * @param {infinitusai.be.IExtractIVROutputsResponse=} [properties] Properties to set
                 */
                function ExtractIVROutputsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExtractIVROutputsResponse ivrOutputsJson.
                 * @member {string} ivrOutputsJson
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @instance
                 */
                ExtractIVROutputsResponse.prototype.ivrOutputsJson = "";
    
                /**
                 * Creates a new ExtractIVROutputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {infinitusai.be.IExtractIVROutputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ExtractIVROutputsResponse} ExtractIVROutputsResponse instance
                 */
                ExtractIVROutputsResponse.create = function create(properties) {
                    return new ExtractIVROutputsResponse(properties);
                };
    
                /**
                 * Encodes the specified ExtractIVROutputsResponse message. Does not implicitly {@link infinitusai.be.ExtractIVROutputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {infinitusai.be.IExtractIVROutputsResponse} message ExtractIVROutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtractIVROutputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ivrOutputsJson != null && Object.hasOwnProperty.call(message, "ivrOutputsJson"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.ivrOutputsJson);
                    return writer;
                };
    
                /**
                 * Encodes the specified ExtractIVROutputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.ExtractIVROutputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {infinitusai.be.IExtractIVROutputsResponse} message ExtractIVROutputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtractIVROutputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExtractIVROutputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ExtractIVROutputsResponse} ExtractIVROutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtractIVROutputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ExtractIVROutputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ivrOutputsJson = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExtractIVROutputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ExtractIVROutputsResponse} ExtractIVROutputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtractIVROutputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExtractIVROutputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtractIVROutputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ivrOutputsJson != null && message.hasOwnProperty("ivrOutputsJson"))
                        if (!$util.isString(message.ivrOutputsJson))
                            return "ivrOutputsJson: string expected";
                    return null;
                };
    
                /**
                 * Creates an ExtractIVROutputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ExtractIVROutputsResponse} ExtractIVROutputsResponse
                 */
                ExtractIVROutputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ExtractIVROutputsResponse)
                        return object;
                    var message = new $root.infinitusai.be.ExtractIVROutputsResponse();
                    if (object.ivrOutputsJson != null)
                        message.ivrOutputsJson = String(object.ivrOutputsJson);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExtractIVROutputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {infinitusai.be.ExtractIVROutputsResponse} message ExtractIVROutputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtractIVROutputsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.ivrOutputsJson = "";
                    if (message.ivrOutputsJson != null && message.hasOwnProperty("ivrOutputsJson"))
                        object.ivrOutputsJson = message.ivrOutputsJson;
                    return object;
                };
    
                /**
                 * Converts this ExtractIVROutputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtractIVROutputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ExtractIVROutputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ExtractIVROutputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExtractIVROutputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ExtractIVROutputsResponse";
                };
    
                return ExtractIVROutputsResponse;
            })();
    
            be.NlpChatSOPRequest = (function() {
    
                /**
                 * Properties of a NlpChatSOPRequest.
                 * @memberof infinitusai.be
                 * @interface INlpChatSOPRequest
                 * @property {string|null} [orgUuid] NlpChatSOPRequest orgUuid
                 * @property {string|null} [taskUuid] NlpChatSOPRequest taskUuid
                 * @property {string|null} [callUuid] NlpChatSOPRequest callUuid
                 * @property {string|null} [actionName] NlpChatSOPRequest actionName
                 */
    
                /**
                 * Constructs a new NlpChatSOPRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NlpChatSOPRequest.
                 * @implements INlpChatSOPRequest
                 * @constructor
                 * @param {infinitusai.be.INlpChatSOPRequest=} [properties] Properties to set
                 */
                function NlpChatSOPRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NlpChatSOPRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @instance
                 */
                NlpChatSOPRequest.prototype.orgUuid = "";
    
                /**
                 * NlpChatSOPRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @instance
                 */
                NlpChatSOPRequest.prototype.taskUuid = "";
    
                /**
                 * NlpChatSOPRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @instance
                 */
                NlpChatSOPRequest.prototype.callUuid = "";
    
                /**
                 * NlpChatSOPRequest actionName.
                 * @member {string} actionName
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @instance
                 */
                NlpChatSOPRequest.prototype.actionName = "";
    
                /**
                 * Creates a new NlpChatSOPRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {infinitusai.be.INlpChatSOPRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.NlpChatSOPRequest} NlpChatSOPRequest instance
                 */
                NlpChatSOPRequest.create = function create(properties) {
                    return new NlpChatSOPRequest(properties);
                };
    
                /**
                 * Encodes the specified NlpChatSOPRequest message. Does not implicitly {@link infinitusai.be.NlpChatSOPRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {infinitusai.be.INlpChatSOPRequest} message NlpChatSOPRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpChatSOPRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.actionName);
                    return writer;
                };
    
                /**
                 * Encodes the specified NlpChatSOPRequest message, length delimited. Does not implicitly {@link infinitusai.be.NlpChatSOPRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {infinitusai.be.INlpChatSOPRequest} message NlpChatSOPRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpChatSOPRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NlpChatSOPRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NlpChatSOPRequest} NlpChatSOPRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpChatSOPRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NlpChatSOPRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.actionName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NlpChatSOPRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NlpChatSOPRequest} NlpChatSOPRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpChatSOPRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NlpChatSOPRequest message.
                 * @function verify
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NlpChatSOPRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        if (!$util.isString(message.actionName))
                            return "actionName: string expected";
                    return null;
                };
    
                /**
                 * Creates a NlpChatSOPRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NlpChatSOPRequest} NlpChatSOPRequest
                 */
                NlpChatSOPRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NlpChatSOPRequest)
                        return object;
                    var message = new $root.infinitusai.be.NlpChatSOPRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.actionName != null)
                        message.actionName = String(object.actionName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a NlpChatSOPRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {infinitusai.be.NlpChatSOPRequest} message NlpChatSOPRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NlpChatSOPRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.actionName = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        object.actionName = message.actionName;
                    return object;
                };
    
                /**
                 * Converts this NlpChatSOPRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NlpChatSOPRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NlpChatSOPRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NlpChatSOPRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NlpChatSOPRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NlpChatSOPRequest";
                };
    
                return NlpChatSOPRequest;
            })();
    
            be.NlpChatSOPResponse = (function() {
    
                /**
                 * Properties of a NlpChatSOPResponse.
                 * @memberof infinitusai.be
                 * @interface INlpChatSOPResponse
                 * @property {string|null} [url] NlpChatSOPResponse url
                 */
    
                /**
                 * Constructs a new NlpChatSOPResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NlpChatSOPResponse.
                 * @implements INlpChatSOPResponse
                 * @constructor
                 * @param {infinitusai.be.INlpChatSOPResponse=} [properties] Properties to set
                 */
                function NlpChatSOPResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NlpChatSOPResponse url.
                 * @member {string} url
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @instance
                 */
                NlpChatSOPResponse.prototype.url = "";
    
                /**
                 * Creates a new NlpChatSOPResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {infinitusai.be.INlpChatSOPResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.NlpChatSOPResponse} NlpChatSOPResponse instance
                 */
                NlpChatSOPResponse.create = function create(properties) {
                    return new NlpChatSOPResponse(properties);
                };
    
                /**
                 * Encodes the specified NlpChatSOPResponse message. Does not implicitly {@link infinitusai.be.NlpChatSOPResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {infinitusai.be.INlpChatSOPResponse} message NlpChatSOPResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpChatSOPResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                    return writer;
                };
    
                /**
                 * Encodes the specified NlpChatSOPResponse message, length delimited. Does not implicitly {@link infinitusai.be.NlpChatSOPResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {infinitusai.be.INlpChatSOPResponse} message NlpChatSOPResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NlpChatSOPResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NlpChatSOPResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NlpChatSOPResponse} NlpChatSOPResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpChatSOPResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NlpChatSOPResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.url = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NlpChatSOPResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NlpChatSOPResponse} NlpChatSOPResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NlpChatSOPResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NlpChatSOPResponse message.
                 * @function verify
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NlpChatSOPResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    return null;
                };
    
                /**
                 * Creates a NlpChatSOPResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NlpChatSOPResponse} NlpChatSOPResponse
                 */
                NlpChatSOPResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NlpChatSOPResponse)
                        return object;
                    var message = new $root.infinitusai.be.NlpChatSOPResponse();
                    if (object.url != null)
                        message.url = String(object.url);
                    return message;
                };
    
                /**
                 * Creates a plain object from a NlpChatSOPResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {infinitusai.be.NlpChatSOPResponse} message NlpChatSOPResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NlpChatSOPResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.url = "";
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    return object;
                };
    
                /**
                 * Converts this NlpChatSOPResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NlpChatSOPResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NlpChatSOPResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NlpChatSOPResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NlpChatSOPResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NlpChatSOPResponse";
                };
    
                return NlpChatSOPResponse;
            })();
    
            be.OperatorUtteranceWithIntent = (function() {
    
                /**
                 * Properties of an OperatorUtteranceWithIntent.
                 * @memberof infinitusai.be
                 * @interface IOperatorUtteranceWithIntent
                 * @property {string|null} [utterance] OperatorUtteranceWithIntent utterance
                 * @property {string|null} [intent] OperatorUtteranceWithIntent intent
                 */
    
                /**
                 * Constructs a new OperatorUtteranceWithIntent.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorUtteranceWithIntent.
                 * @implements IOperatorUtteranceWithIntent
                 * @constructor
                 * @param {infinitusai.be.IOperatorUtteranceWithIntent=} [properties] Properties to set
                 */
                function OperatorUtteranceWithIntent(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorUtteranceWithIntent utterance.
                 * @member {string} utterance
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @instance
                 */
                OperatorUtteranceWithIntent.prototype.utterance = "";
    
                /**
                 * OperatorUtteranceWithIntent intent.
                 * @member {string} intent
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @instance
                 */
                OperatorUtteranceWithIntent.prototype.intent = "";
    
                /**
                 * Creates a new OperatorUtteranceWithIntent instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {infinitusai.be.IOperatorUtteranceWithIntent=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorUtteranceWithIntent} OperatorUtteranceWithIntent instance
                 */
                OperatorUtteranceWithIntent.create = function create(properties) {
                    return new OperatorUtteranceWithIntent(properties);
                };
    
                /**
                 * Encodes the specified OperatorUtteranceWithIntent message. Does not implicitly {@link infinitusai.be.OperatorUtteranceWithIntent.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {infinitusai.be.IOperatorUtteranceWithIntent} message OperatorUtteranceWithIntent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorUtteranceWithIntent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.utterance != null && Object.hasOwnProperty.call(message, "utterance"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.utterance);
                    if (message.intent != null && Object.hasOwnProperty.call(message, "intent"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.intent);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorUtteranceWithIntent message, length delimited. Does not implicitly {@link infinitusai.be.OperatorUtteranceWithIntent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {infinitusai.be.IOperatorUtteranceWithIntent} message OperatorUtteranceWithIntent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorUtteranceWithIntent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorUtteranceWithIntent message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorUtteranceWithIntent} OperatorUtteranceWithIntent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorUtteranceWithIntent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorUtteranceWithIntent();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.utterance = reader.string();
                                break;
                            }
                        case 2: {
                                message.intent = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorUtteranceWithIntent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorUtteranceWithIntent} OperatorUtteranceWithIntent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorUtteranceWithIntent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorUtteranceWithIntent message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorUtteranceWithIntent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        if (!$util.isString(message.utterance))
                            return "utterance: string expected";
                    if (message.intent != null && message.hasOwnProperty("intent"))
                        if (!$util.isString(message.intent))
                            return "intent: string expected";
                    return null;
                };
    
                /**
                 * Creates an OperatorUtteranceWithIntent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorUtteranceWithIntent} OperatorUtteranceWithIntent
                 */
                OperatorUtteranceWithIntent.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorUtteranceWithIntent)
                        return object;
                    var message = new $root.infinitusai.be.OperatorUtteranceWithIntent();
                    if (object.utterance != null)
                        message.utterance = String(object.utterance);
                    if (object.intent != null)
                        message.intent = String(object.intent);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorUtteranceWithIntent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {infinitusai.be.OperatorUtteranceWithIntent} message OperatorUtteranceWithIntent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorUtteranceWithIntent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.utterance = "";
                        object.intent = "";
                    }
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        object.utterance = message.utterance;
                    if (message.intent != null && message.hasOwnProperty("intent"))
                        object.intent = message.intent;
                    return object;
                };
    
                /**
                 * Converts this OperatorUtteranceWithIntent to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorUtteranceWithIntent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorUtteranceWithIntent
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorUtteranceWithIntent
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorUtteranceWithIntent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorUtteranceWithIntent";
                };
    
                return OperatorUtteranceWithIntent;
            })();
    
            be.GenerateOperatorTrainerResponseRequest = (function() {
    
                /**
                 * Properties of a GenerateOperatorTrainerResponseRequest.
                 * @memberof infinitusai.be
                 * @interface IGenerateOperatorTrainerResponseRequest
                 * @property {string|null} [conversationUuid] GenerateOperatorTrainerResponseRequest conversationUuid
                 * @property {infinitusai.be.IOperatorUtteranceWithIntent|null} [utteranceIntent] GenerateOperatorTrainerResponseRequest utteranceIntent
                 * @property {infinitusai.be.ICallOutputs|null} [callOutputs] GenerateOperatorTrainerResponseRequest callOutputs
                 * @property {infinitusai.be.IOperatorTrainerCallState|null} [callState] GenerateOperatorTrainerResponseRequest callState
                 * @property {infinitusai.be.IBVCallInputs|null} [callInputs] GenerateOperatorTrainerResponseRequest callInputs
                 * @property {infinitusai.be.TaskType|null} [taskType] GenerateOperatorTrainerResponseRequest taskType
                 * @property {Array.<infinitusai.be.Curveball>|null} [curveballs] GenerateOperatorTrainerResponseRequest curveballs
                 * @property {string|null} [callInputsJson] GenerateOperatorTrainerResponseRequest callInputsJson
                 */
    
                /**
                 * Constructs a new GenerateOperatorTrainerResponseRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateOperatorTrainerResponseRequest.
                 * @implements IGenerateOperatorTrainerResponseRequest
                 * @constructor
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseRequest=} [properties] Properties to set
                 */
                function GenerateOperatorTrainerResponseRequest(properties) {
                    this.curveballs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateOperatorTrainerResponseRequest conversationUuid.
                 * @member {string} conversationUuid
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 */
                GenerateOperatorTrainerResponseRequest.prototype.conversationUuid = "";
    
                /**
                 * GenerateOperatorTrainerResponseRequest utteranceIntent.
                 * @member {infinitusai.be.IOperatorUtteranceWithIntent|null|undefined} utteranceIntent
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 */
                GenerateOperatorTrainerResponseRequest.prototype.utteranceIntent = null;
    
                /**
                 * GenerateOperatorTrainerResponseRequest callOutputs.
                 * @member {infinitusai.be.ICallOutputs|null|undefined} callOutputs
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 */
                GenerateOperatorTrainerResponseRequest.prototype.callOutputs = null;
    
                /**
                 * GenerateOperatorTrainerResponseRequest callState.
                 * @member {infinitusai.be.IOperatorTrainerCallState|null|undefined} callState
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 */
                GenerateOperatorTrainerResponseRequest.prototype.callState = null;
    
                /**
                 * GenerateOperatorTrainerResponseRequest callInputs.
                 * @member {infinitusai.be.IBVCallInputs|null|undefined} callInputs
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 */
                GenerateOperatorTrainerResponseRequest.prototype.callInputs = null;
    
                /**
                 * GenerateOperatorTrainerResponseRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 */
                GenerateOperatorTrainerResponseRequest.prototype.taskType = 0;
    
                /**
                 * GenerateOperatorTrainerResponseRequest curveballs.
                 * @member {Array.<infinitusai.be.Curveball>} curveballs
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 */
                GenerateOperatorTrainerResponseRequest.prototype.curveballs = $util.emptyArray;
    
                /**
                 * GenerateOperatorTrainerResponseRequest callInputsJson.
                 * @member {string} callInputsJson
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 */
                GenerateOperatorTrainerResponseRequest.prototype.callInputsJson = "";
    
                /**
                 * Creates a new GenerateOperatorTrainerResponseRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseRequest} GenerateOperatorTrainerResponseRequest instance
                 */
                GenerateOperatorTrainerResponseRequest.create = function create(properties) {
                    return new GenerateOperatorTrainerResponseRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateOperatorTrainerResponseRequest message. Does not implicitly {@link infinitusai.be.GenerateOperatorTrainerResponseRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseRequest} message GenerateOperatorTrainerResponseRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateOperatorTrainerResponseRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conversationUuid != null && Object.hasOwnProperty.call(message, "conversationUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationUuid);
                    if (message.utteranceIntent != null && Object.hasOwnProperty.call(message, "utteranceIntent"))
                        $root.infinitusai.be.OperatorUtteranceWithIntent.encode(message.utteranceIntent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.callOutputs != null && Object.hasOwnProperty.call(message, "callOutputs"))
                        $root.infinitusai.be.CallOutputs.encode(message.callOutputs, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.callState != null && Object.hasOwnProperty.call(message, "callState"))
                        $root.infinitusai.be.OperatorTrainerCallState.encode(message.callState, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.callInputs != null && Object.hasOwnProperty.call(message, "callInputs"))
                        $root.infinitusai.be.BVCallInputs.encode(message.callInputs, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.taskType);
                    if (message.curveballs != null && message.curveballs.length) {
                        writer.uint32(/* id 7, wireType 2 =*/58).fork();
                        for (var i = 0; i < message.curveballs.length; ++i)
                            writer.int32(message.curveballs[i]);
                        writer.ldelim();
                    }
                    if (message.callInputsJson != null && Object.hasOwnProperty.call(message, "callInputsJson"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.callInputsJson);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateOperatorTrainerResponseRequest message, length delimited. Does not implicitly {@link infinitusai.be.GenerateOperatorTrainerResponseRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseRequest} message GenerateOperatorTrainerResponseRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateOperatorTrainerResponseRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateOperatorTrainerResponseRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseRequest} GenerateOperatorTrainerResponseRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateOperatorTrainerResponseRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateOperatorTrainerResponseRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.conversationUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.utteranceIntent = $root.infinitusai.be.OperatorUtteranceWithIntent.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.callOutputs = $root.infinitusai.be.CallOutputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.callState = $root.infinitusai.be.OperatorTrainerCallState.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.callInputs = $root.infinitusai.be.BVCallInputs.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 7: {
                                if (!(message.curveballs && message.curveballs.length))
                                    message.curveballs = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.curveballs.push(reader.int32());
                                } else
                                    message.curveballs.push(reader.int32());
                                break;
                            }
                        case 8: {
                                message.callInputsJson = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateOperatorTrainerResponseRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseRequest} GenerateOperatorTrainerResponseRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateOperatorTrainerResponseRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateOperatorTrainerResponseRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateOperatorTrainerResponseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        if (!$util.isString(message.conversationUuid))
                            return "conversationUuid: string expected";
                    if (message.utteranceIntent != null && message.hasOwnProperty("utteranceIntent")) {
                        var error = $root.infinitusai.be.OperatorUtteranceWithIntent.verify(message.utteranceIntent);
                        if (error)
                            return "utteranceIntent." + error;
                    }
                    if (message.callOutputs != null && message.hasOwnProperty("callOutputs")) {
                        var error = $root.infinitusai.be.CallOutputs.verify(message.callOutputs);
                        if (error)
                            return "callOutputs." + error;
                    }
                    if (message.callState != null && message.hasOwnProperty("callState")) {
                        var error = $root.infinitusai.be.OperatorTrainerCallState.verify(message.callState);
                        if (error)
                            return "callState." + error;
                    }
                    if (message.callInputs != null && message.hasOwnProperty("callInputs")) {
                        var error = $root.infinitusai.be.BVCallInputs.verify(message.callInputs);
                        if (error)
                            return "callInputs." + error;
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.curveballs != null && message.hasOwnProperty("curveballs")) {
                        if (!Array.isArray(message.curveballs))
                            return "curveballs: array expected";
                        for (var i = 0; i < message.curveballs.length; ++i)
                            switch (message.curveballs[i]) {
                            default:
                                return "curveballs: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                                break;
                            }
                    }
                    if (message.callInputsJson != null && message.hasOwnProperty("callInputsJson"))
                        if (!$util.isString(message.callInputsJson))
                            return "callInputsJson: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateOperatorTrainerResponseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseRequest} GenerateOperatorTrainerResponseRequest
                 */
                GenerateOperatorTrainerResponseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateOperatorTrainerResponseRequest)
                        return object;
                    var message = new $root.infinitusai.be.GenerateOperatorTrainerResponseRequest();
                    if (object.conversationUuid != null)
                        message.conversationUuid = String(object.conversationUuid);
                    if (object.utteranceIntent != null) {
                        if (typeof object.utteranceIntent !== "object")
                            throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseRequest.utteranceIntent: object expected");
                        message.utteranceIntent = $root.infinitusai.be.OperatorUtteranceWithIntent.fromObject(object.utteranceIntent);
                    }
                    if (object.callOutputs != null) {
                        if (typeof object.callOutputs !== "object")
                            throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseRequest.callOutputs: object expected");
                        message.callOutputs = $root.infinitusai.be.CallOutputs.fromObject(object.callOutputs);
                    }
                    if (object.callState != null) {
                        if (typeof object.callState !== "object")
                            throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseRequest.callState: object expected");
                        message.callState = $root.infinitusai.be.OperatorTrainerCallState.fromObject(object.callState);
                    }
                    if (object.callInputs != null) {
                        if (typeof object.callInputs !== "object")
                            throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseRequest.callInputs: object expected");
                        message.callInputs = $root.infinitusai.be.BVCallInputs.fromObject(object.callInputs);
                    }
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.curveballs) {
                        if (!Array.isArray(object.curveballs))
                            throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseRequest.curveballs: array expected");
                        message.curveballs = [];
                        for (var i = 0; i < object.curveballs.length; ++i)
                            switch (object.curveballs[i]) {
                            default:
                                if (typeof object.curveballs[i] === "number") {
                                    message.curveballs[i] = object.curveballs[i];
                                    break;
                                }
                            case "CURVEBALL_UNKNOWN":
                            case 0:
                                message.curveballs[i] = 0;
                                break;
                            case "CURVEBALL_REQUEST_FOR_HUMAN":
                            case 1:
                                message.curveballs[i] = 1;
                                break;
                            case "CURVEBALL_REQUEST_FOR_LIVE_AGENT":
                            case 2:
                                message.curveballs[i] = 2;
                                break;
                            case "CURVEBALL_HIPAA_CONCERNS_PUSHBACK":
                            case 3:
                                message.curveballs[i] = 3;
                                break;
                            case "CURVEBALL_AGENT_PLAYS_WITH_EVA":
                            case 4:
                                message.curveballs[i] = 4;
                                break;
                            case "CURVEBALL_AGENT_RELENTLESSLY_PLAYS_WITH_EVA":
                            case 5:
                                message.curveballs[i] = 5;
                                break;
                            case "CURVEBALL_AGENT_SHARES_TOO_MUCH_INFO":
                            case 6:
                                message.curveballs[i] = 6;
                                break;
                            case "CURVEBALL_FRUSTRATED_AGENT_SHARES_TOO_MUCH_INFO":
                            case 7:
                                message.curveballs[i] = 7;
                                break;
                            case "CURVEBALL_STEP_THERAPY_CONFUSION":
                            case 8:
                                message.curveballs[i] = 8;
                                break;
                            case "CURVEBALL_SPECIALTY_PHARMACY_CONFUSION":
                            case 9:
                                message.curveballs[i] = 9;
                                break;
                            case "CURVEBALL_PREDETERMINATION_RECOMMENDED_NOT_REQUIRED":
                            case 10:
                                message.curveballs[i] = 10;
                                break;
                            case "CURVEBALL_PREDETERMINATION_RECOMMENDED":
                            case 11:
                                message.curveballs[i] = 11;
                                break;
                            case "CURVEBALL_PREDETERMINATION_OPTIONAL":
                            case 12:
                                message.curveballs[i] = 12;
                                break;
                            case "CURVEBALL_VAGUE_LIFETIME_MAX_NO":
                            case 13:
                                message.curveballs[i] = 13;
                                break;
                            case "CURVEBALL_VAGUE_LIFETIME_MAX_YES":
                            case 14:
                                message.curveballs[i] = 14;
                                break;
                            case "CURVEBALL_VAGUE_LIFETIME_MAX_MEDICARE":
                            case 15:
                                message.curveballs[i] = 15;
                                break;
                            case "CURVEBALL_VAGUE_LIFETIME_MAX_LARGE_VALUE":
                            case 16:
                                message.curveballs[i] = 16;
                                break;
                            case "CURVEBALL_VAGUE_COPAY_COINSURANCE_YES":
                            case 17:
                                message.curveballs[i] = 17;
                                break;
                            case "CURVEBALL_VAGUE_COPAY_COINSURANCE_NO":
                            case 18:
                                message.curveballs[i] = 18;
                                break;
                            case "CURVEBALL_OOP_ALREADY_MET":
                            case 19:
                                message.curveballs[i] = 19;
                                break;
                            case "CURVEBALL_COB_NOT_UP_TO_DATE_NO_SECONDARY":
                            case 20:
                                message.curveballs[i] = 20;
                                break;
                            case "CURVEBALL_COB_NOT_UP_TO_DATE_WITH_SECONDARY":
                            case 21:
                                message.curveballs[i] = 21;
                                break;
                            }
                    }
                    if (object.callInputsJson != null)
                        message.callInputsJson = String(object.callInputsJson);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateOperatorTrainerResponseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {infinitusai.be.GenerateOperatorTrainerResponseRequest} message GenerateOperatorTrainerResponseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateOperatorTrainerResponseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.curveballs = [];
                    if (options.defaults) {
                        object.conversationUuid = "";
                        object.utteranceIntent = null;
                        object.callOutputs = null;
                        object.callState = null;
                        object.callInputs = null;
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.callInputsJson = "";
                    }
                    if (message.conversationUuid != null && message.hasOwnProperty("conversationUuid"))
                        object.conversationUuid = message.conversationUuid;
                    if (message.utteranceIntent != null && message.hasOwnProperty("utteranceIntent"))
                        object.utteranceIntent = $root.infinitusai.be.OperatorUtteranceWithIntent.toObject(message.utteranceIntent, options);
                    if (message.callOutputs != null && message.hasOwnProperty("callOutputs"))
                        object.callOutputs = $root.infinitusai.be.CallOutputs.toObject(message.callOutputs, options);
                    if (message.callState != null && message.hasOwnProperty("callState"))
                        object.callState = $root.infinitusai.be.OperatorTrainerCallState.toObject(message.callState, options);
                    if (message.callInputs != null && message.hasOwnProperty("callInputs"))
                        object.callInputs = $root.infinitusai.be.BVCallInputs.toObject(message.callInputs, options);
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.curveballs && message.curveballs.length) {
                        object.curveballs = [];
                        for (var j = 0; j < message.curveballs.length; ++j)
                            object.curveballs[j] = options.enums === String ? $root.infinitusai.be.Curveball[message.curveballs[j]] === undefined ? message.curveballs[j] : $root.infinitusai.be.Curveball[message.curveballs[j]] : message.curveballs[j];
                    }
                    if (message.callInputsJson != null && message.hasOwnProperty("callInputsJson"))
                        object.callInputsJson = message.callInputsJson;
                    return object;
                };
    
                /**
                 * Converts this GenerateOperatorTrainerResponseRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateOperatorTrainerResponseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateOperatorTrainerResponseRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateOperatorTrainerResponseRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateOperatorTrainerResponseRequest";
                };
    
                return GenerateOperatorTrainerResponseRequest;
            })();
    
            be.GenerateOperatorTrainerResponseOption = (function() {
    
                /**
                 * Properties of a GenerateOperatorTrainerResponseOption.
                 * @memberof infinitusai.be
                 * @interface IGenerateOperatorTrainerResponseOption
                 * @property {string|null} [utterance] GenerateOperatorTrainerResponseOption utterance
                 * @property {string|null} [intentName] GenerateOperatorTrainerResponseOption intentName
                 * @property {string|null} [suggestionId] GenerateOperatorTrainerResponseOption suggestionId
                 * @property {infinitusai.be.SuggestionCategory|null} [suggestionCategory] GenerateOperatorTrainerResponseOption suggestionCategory
                 */
    
                /**
                 * Constructs a new GenerateOperatorTrainerResponseOption.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateOperatorTrainerResponseOption.
                 * @implements IGenerateOperatorTrainerResponseOption
                 * @constructor
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseOption=} [properties] Properties to set
                 */
                function GenerateOperatorTrainerResponseOption(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateOperatorTrainerResponseOption utterance.
                 * @member {string} utterance
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @instance
                 */
                GenerateOperatorTrainerResponseOption.prototype.utterance = "";
    
                /**
                 * GenerateOperatorTrainerResponseOption intentName.
                 * @member {string} intentName
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @instance
                 */
                GenerateOperatorTrainerResponseOption.prototype.intentName = "";
    
                /**
                 * GenerateOperatorTrainerResponseOption suggestionId.
                 * @member {string} suggestionId
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @instance
                 */
                GenerateOperatorTrainerResponseOption.prototype.suggestionId = "";
    
                /**
                 * GenerateOperatorTrainerResponseOption suggestionCategory.
                 * @member {infinitusai.be.SuggestionCategory} suggestionCategory
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @instance
                 */
                GenerateOperatorTrainerResponseOption.prototype.suggestionCategory = 0;
    
                /**
                 * Creates a new GenerateOperatorTrainerResponseOption instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseOption=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseOption} GenerateOperatorTrainerResponseOption instance
                 */
                GenerateOperatorTrainerResponseOption.create = function create(properties) {
                    return new GenerateOperatorTrainerResponseOption(properties);
                };
    
                /**
                 * Encodes the specified GenerateOperatorTrainerResponseOption message. Does not implicitly {@link infinitusai.be.GenerateOperatorTrainerResponseOption.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseOption} message GenerateOperatorTrainerResponseOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateOperatorTrainerResponseOption.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.utterance != null && Object.hasOwnProperty.call(message, "utterance"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.utterance);
                    if (message.intentName != null && Object.hasOwnProperty.call(message, "intentName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.intentName);
                    if (message.suggestionId != null && Object.hasOwnProperty.call(message, "suggestionId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.suggestionId);
                    if (message.suggestionCategory != null && Object.hasOwnProperty.call(message, "suggestionCategory"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.suggestionCategory);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateOperatorTrainerResponseOption message, length delimited. Does not implicitly {@link infinitusai.be.GenerateOperatorTrainerResponseOption.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseOption} message GenerateOperatorTrainerResponseOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateOperatorTrainerResponseOption.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateOperatorTrainerResponseOption message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseOption} GenerateOperatorTrainerResponseOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateOperatorTrainerResponseOption.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateOperatorTrainerResponseOption();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.utterance = reader.string();
                                break;
                            }
                        case 2: {
                                message.intentName = reader.string();
                                break;
                            }
                        case 3: {
                                message.suggestionId = reader.string();
                                break;
                            }
                        case 4: {
                                message.suggestionCategory = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateOperatorTrainerResponseOption message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseOption} GenerateOperatorTrainerResponseOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateOperatorTrainerResponseOption.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateOperatorTrainerResponseOption message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateOperatorTrainerResponseOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        if (!$util.isString(message.utterance))
                            return "utterance: string expected";
                    if (message.intentName != null && message.hasOwnProperty("intentName"))
                        if (!$util.isString(message.intentName))
                            return "intentName: string expected";
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        if (!$util.isString(message.suggestionId))
                            return "suggestionId: string expected";
                    if (message.suggestionCategory != null && message.hasOwnProperty("suggestionCategory"))
                        switch (message.suggestionCategory) {
                        default:
                            return "suggestionCategory: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GenerateOperatorTrainerResponseOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseOption} GenerateOperatorTrainerResponseOption
                 */
                GenerateOperatorTrainerResponseOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateOperatorTrainerResponseOption)
                        return object;
                    var message = new $root.infinitusai.be.GenerateOperatorTrainerResponseOption();
                    if (object.utterance != null)
                        message.utterance = String(object.utterance);
                    if (object.intentName != null)
                        message.intentName = String(object.intentName);
                    if (object.suggestionId != null)
                        message.suggestionId = String(object.suggestionId);
                    switch (object.suggestionCategory) {
                    default:
                        if (typeof object.suggestionCategory === "number") {
                            message.suggestionCategory = object.suggestionCategory;
                            break;
                        }
                        break;
                    case "SUGGESTION_CATEGORY_UNKNOWN":
                    case 0:
                        message.suggestionCategory = 0;
                        break;
                    case "SUGGESTION_CATEGORY_CHITCHAT":
                    case 1:
                        message.suggestionCategory = 1;
                        break;
                    case "SUGGESTION_CATEGORY_IVR":
                    case 2:
                        message.suggestionCategory = 2;
                        break;
                    case "SUGGESTION_CATEGORY_ANSWERS":
                    case 3:
                        message.suggestionCategory = 3;
                        break;
                    case "SUGGESTION_CATEGORY_QUESTIONS":
                    case 4:
                        message.suggestionCategory = 4;
                        break;
                    case "SUGGESTION_CATEGORY_PUSHBACK":
                    case 5:
                        message.suggestionCategory = 5;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateOperatorTrainerResponseOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {infinitusai.be.GenerateOperatorTrainerResponseOption} message GenerateOperatorTrainerResponseOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateOperatorTrainerResponseOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.utterance = "";
                        object.intentName = "";
                        object.suggestionId = "";
                        object.suggestionCategory = options.enums === String ? "SUGGESTION_CATEGORY_UNKNOWN" : 0;
                    }
                    if (message.utterance != null && message.hasOwnProperty("utterance"))
                        object.utterance = message.utterance;
                    if (message.intentName != null && message.hasOwnProperty("intentName"))
                        object.intentName = message.intentName;
                    if (message.suggestionId != null && message.hasOwnProperty("suggestionId"))
                        object.suggestionId = message.suggestionId;
                    if (message.suggestionCategory != null && message.hasOwnProperty("suggestionCategory"))
                        object.suggestionCategory = options.enums === String ? $root.infinitusai.be.SuggestionCategory[message.suggestionCategory] === undefined ? message.suggestionCategory : $root.infinitusai.be.SuggestionCategory[message.suggestionCategory] : message.suggestionCategory;
                    return object;
                };
    
                /**
                 * Converts this GenerateOperatorTrainerResponseOption to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateOperatorTrainerResponseOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateOperatorTrainerResponseOption
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseOption
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateOperatorTrainerResponseOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateOperatorTrainerResponseOption";
                };
    
                return GenerateOperatorTrainerResponseOption;
            })();
    
            be.GenerateOperatorTrainerResponseResponse = (function() {
    
                /**
                 * Properties of a GenerateOperatorTrainerResponseResponse.
                 * @memberof infinitusai.be
                 * @interface IGenerateOperatorTrainerResponseResponse
                 * @property {infinitusai.be.IGenerateOperatorTrainerResponseOption|null} [topResponse] GenerateOperatorTrainerResponseResponse topResponse
                 * @property {Array.<infinitusai.be.IGenerateOperatorTrainerResponseOption>|null} [options] GenerateOperatorTrainerResponseResponse options
                 * @property {infinitusai.be.IOperatorTrainerCallState|null} [callState] GenerateOperatorTrainerResponseResponse callState
                 */
    
                /**
                 * Constructs a new GenerateOperatorTrainerResponseResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GenerateOperatorTrainerResponseResponse.
                 * @implements IGenerateOperatorTrainerResponseResponse
                 * @constructor
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseResponse=} [properties] Properties to set
                 */
                function GenerateOperatorTrainerResponseResponse(properties) {
                    this.options = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateOperatorTrainerResponseResponse topResponse.
                 * @member {infinitusai.be.IGenerateOperatorTrainerResponseOption|null|undefined} topResponse
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @instance
                 */
                GenerateOperatorTrainerResponseResponse.prototype.topResponse = null;
    
                /**
                 * GenerateOperatorTrainerResponseResponse options.
                 * @member {Array.<infinitusai.be.IGenerateOperatorTrainerResponseOption>} options
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @instance
                 */
                GenerateOperatorTrainerResponseResponse.prototype.options = $util.emptyArray;
    
                /**
                 * GenerateOperatorTrainerResponseResponse callState.
                 * @member {infinitusai.be.IOperatorTrainerCallState|null|undefined} callState
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @instance
                 */
                GenerateOperatorTrainerResponseResponse.prototype.callState = null;
    
                /**
                 * Creates a new GenerateOperatorTrainerResponseResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseResponse} GenerateOperatorTrainerResponseResponse instance
                 */
                GenerateOperatorTrainerResponseResponse.create = function create(properties) {
                    return new GenerateOperatorTrainerResponseResponse(properties);
                };
    
                /**
                 * Encodes the specified GenerateOperatorTrainerResponseResponse message. Does not implicitly {@link infinitusai.be.GenerateOperatorTrainerResponseResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseResponse} message GenerateOperatorTrainerResponseResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateOperatorTrainerResponseResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.topResponse != null && Object.hasOwnProperty.call(message, "topResponse"))
                        $root.infinitusai.be.GenerateOperatorTrainerResponseOption.encode(message.topResponse, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.options != null && message.options.length)
                        for (var i = 0; i < message.options.length; ++i)
                            $root.infinitusai.be.GenerateOperatorTrainerResponseOption.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.callState != null && Object.hasOwnProperty.call(message, "callState"))
                        $root.infinitusai.be.OperatorTrainerCallState.encode(message.callState, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateOperatorTrainerResponseResponse message, length delimited. Does not implicitly {@link infinitusai.be.GenerateOperatorTrainerResponseResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {infinitusai.be.IGenerateOperatorTrainerResponseResponse} message GenerateOperatorTrainerResponseResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateOperatorTrainerResponseResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateOperatorTrainerResponseResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseResponse} GenerateOperatorTrainerResponseResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateOperatorTrainerResponseResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GenerateOperatorTrainerResponseResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.topResponse = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.options && message.options.length))
                                    message.options = [];
                                message.options.push($root.infinitusai.be.GenerateOperatorTrainerResponseOption.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.callState = $root.infinitusai.be.OperatorTrainerCallState.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateOperatorTrainerResponseResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseResponse} GenerateOperatorTrainerResponseResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateOperatorTrainerResponseResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateOperatorTrainerResponseResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateOperatorTrainerResponseResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.topResponse != null && message.hasOwnProperty("topResponse")) {
                        var error = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.verify(message.topResponse);
                        if (error)
                            return "topResponse." + error;
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        if (!Array.isArray(message.options))
                            return "options: array expected";
                        for (var i = 0; i < message.options.length; ++i) {
                            var error = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.verify(message.options[i]);
                            if (error)
                                return "options." + error;
                        }
                    }
                    if (message.callState != null && message.hasOwnProperty("callState")) {
                        var error = $root.infinitusai.be.OperatorTrainerCallState.verify(message.callState);
                        if (error)
                            return "callState." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GenerateOperatorTrainerResponseResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GenerateOperatorTrainerResponseResponse} GenerateOperatorTrainerResponseResponse
                 */
                GenerateOperatorTrainerResponseResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GenerateOperatorTrainerResponseResponse)
                        return object;
                    var message = new $root.infinitusai.be.GenerateOperatorTrainerResponseResponse();
                    if (object.topResponse != null) {
                        if (typeof object.topResponse !== "object")
                            throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseResponse.topResponse: object expected");
                        message.topResponse = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.fromObject(object.topResponse);
                    }
                    if (object.options) {
                        if (!Array.isArray(object.options))
                            throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseResponse.options: array expected");
                        message.options = [];
                        for (var i = 0; i < object.options.length; ++i) {
                            if (typeof object.options[i] !== "object")
                                throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseResponse.options: object expected");
                            message.options[i] = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.fromObject(object.options[i]);
                        }
                    }
                    if (object.callState != null) {
                        if (typeof object.callState !== "object")
                            throw TypeError(".infinitusai.be.GenerateOperatorTrainerResponseResponse.callState: object expected");
                        message.callState = $root.infinitusai.be.OperatorTrainerCallState.fromObject(object.callState);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateOperatorTrainerResponseResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {infinitusai.be.GenerateOperatorTrainerResponseResponse} message GenerateOperatorTrainerResponseResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateOperatorTrainerResponseResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.options = [];
                    if (options.defaults) {
                        object.topResponse = null;
                        object.callState = null;
                    }
                    if (message.topResponse != null && message.hasOwnProperty("topResponse"))
                        object.topResponse = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.toObject(message.topResponse, options);
                    if (message.options && message.options.length) {
                        object.options = [];
                        for (var j = 0; j < message.options.length; ++j)
                            object.options[j] = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.toObject(message.options[j], options);
                    }
                    if (message.callState != null && message.hasOwnProperty("callState"))
                        object.callState = $root.infinitusai.be.OperatorTrainerCallState.toObject(message.callState, options);
                    return object;
                };
    
                /**
                 * Converts this GenerateOperatorTrainerResponseResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateOperatorTrainerResponseResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateOperatorTrainerResponseResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GenerateOperatorTrainerResponseResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateOperatorTrainerResponseResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GenerateOperatorTrainerResponseResponse";
                };
    
                return GenerateOperatorTrainerResponseResponse;
            })();
    
            /**
             * OperatorTrainerPhase enum.
             * @name infinitusai.be.OperatorTrainerPhase
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} WELCOME=1 WELCOME value
             * @property {number} AUTHENTICATE_INTRO=2 AUTHENTICATE_INTRO value
             * @property {number} AUTHENTICATE=3 AUTHENTICATE value
             * @property {number} GET_INFORMATION_INTRO=4 GET_INFORMATION_INTRO value
             * @property {number} GET_INFORMATION=5 GET_INFORMATION value
             */
            be.OperatorTrainerPhase = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "WELCOME"] = 1;
                values[valuesById[2] = "AUTHENTICATE_INTRO"] = 2;
                values[valuesById[3] = "AUTHENTICATE"] = 3;
                values[valuesById[4] = "GET_INFORMATION_INTRO"] = 4;
                values[valuesById[5] = "GET_INFORMATION"] = 5;
                return values;
            })();
    
            be.OperatorTrainerCallState = (function() {
    
                /**
                 * Properties of an OperatorTrainerCallState.
                 * @memberof infinitusai.be
                 * @interface IOperatorTrainerCallState
                 * @property {infinitusai.be.OperatorTrainerPhase|null} [phase] OperatorTrainerCallState phase
                 * @property {Array.<infinitusai.be.OperatorTrainerPhase>|null} [pastPhases] OperatorTrainerCallState pastPhases
                 * @property {Array.<infinitusai.be.IOperatorUtteranceWithIntent>|null} [previousUtteranceIntents] OperatorTrainerCallState previousUtteranceIntents
                 * @property {Array.<infinitusai.be.IGenerateOperatorTrainerResponseOption>|null} [previousActions] OperatorTrainerCallState previousActions
                 * @property {string|null} [lastState] OperatorTrainerCallState lastState
                 */
    
                /**
                 * Constructs a new OperatorTrainerCallState.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorTrainerCallState.
                 * @implements IOperatorTrainerCallState
                 * @constructor
                 * @param {infinitusai.be.IOperatorTrainerCallState=} [properties] Properties to set
                 */
                function OperatorTrainerCallState(properties) {
                    this.pastPhases = [];
                    this.previousUtteranceIntents = [];
                    this.previousActions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorTrainerCallState phase.
                 * @member {infinitusai.be.OperatorTrainerPhase} phase
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @instance
                 */
                OperatorTrainerCallState.prototype.phase = 0;
    
                /**
                 * OperatorTrainerCallState pastPhases.
                 * @member {Array.<infinitusai.be.OperatorTrainerPhase>} pastPhases
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @instance
                 */
                OperatorTrainerCallState.prototype.pastPhases = $util.emptyArray;
    
                /**
                 * OperatorTrainerCallState previousUtteranceIntents.
                 * @member {Array.<infinitusai.be.IOperatorUtteranceWithIntent>} previousUtteranceIntents
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @instance
                 */
                OperatorTrainerCallState.prototype.previousUtteranceIntents = $util.emptyArray;
    
                /**
                 * OperatorTrainerCallState previousActions.
                 * @member {Array.<infinitusai.be.IGenerateOperatorTrainerResponseOption>} previousActions
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @instance
                 */
                OperatorTrainerCallState.prototype.previousActions = $util.emptyArray;
    
                /**
                 * OperatorTrainerCallState lastState.
                 * @member {string} lastState
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @instance
                 */
                OperatorTrainerCallState.prototype.lastState = "";
    
                /**
                 * Creates a new OperatorTrainerCallState instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {infinitusai.be.IOperatorTrainerCallState=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorTrainerCallState} OperatorTrainerCallState instance
                 */
                OperatorTrainerCallState.create = function create(properties) {
                    return new OperatorTrainerCallState(properties);
                };
    
                /**
                 * Encodes the specified OperatorTrainerCallState message. Does not implicitly {@link infinitusai.be.OperatorTrainerCallState.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {infinitusai.be.IOperatorTrainerCallState} message OperatorTrainerCallState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorTrainerCallState.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.phase != null && Object.hasOwnProperty.call(message, "phase"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.phase);
                    if (message.pastPhases != null && message.pastPhases.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.pastPhases.length; ++i)
                            writer.int32(message.pastPhases[i]);
                        writer.ldelim();
                    }
                    if (message.previousUtteranceIntents != null && message.previousUtteranceIntents.length)
                        for (var i = 0; i < message.previousUtteranceIntents.length; ++i)
                            $root.infinitusai.be.OperatorUtteranceWithIntent.encode(message.previousUtteranceIntents[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.previousActions != null && message.previousActions.length)
                        for (var i = 0; i < message.previousActions.length; ++i)
                            $root.infinitusai.be.GenerateOperatorTrainerResponseOption.encode(message.previousActions[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.lastState != null && Object.hasOwnProperty.call(message, "lastState"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastState);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorTrainerCallState message, length delimited. Does not implicitly {@link infinitusai.be.OperatorTrainerCallState.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {infinitusai.be.IOperatorTrainerCallState} message OperatorTrainerCallState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorTrainerCallState.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorTrainerCallState message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorTrainerCallState} OperatorTrainerCallState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorTrainerCallState.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorTrainerCallState();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.phase = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.pastPhases && message.pastPhases.length))
                                    message.pastPhases = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.pastPhases.push(reader.int32());
                                } else
                                    message.pastPhases.push(reader.int32());
                                break;
                            }
                        case 3: {
                                if (!(message.previousUtteranceIntents && message.previousUtteranceIntents.length))
                                    message.previousUtteranceIntents = [];
                                message.previousUtteranceIntents.push($root.infinitusai.be.OperatorUtteranceWithIntent.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.previousActions && message.previousActions.length))
                                    message.previousActions = [];
                                message.previousActions.push($root.infinitusai.be.GenerateOperatorTrainerResponseOption.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                message.lastState = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorTrainerCallState message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorTrainerCallState} OperatorTrainerCallState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorTrainerCallState.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorTrainerCallState message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorTrainerCallState.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.phase != null && message.hasOwnProperty("phase"))
                        switch (message.phase) {
                        default:
                            return "phase: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.pastPhases != null && message.hasOwnProperty("pastPhases")) {
                        if (!Array.isArray(message.pastPhases))
                            return "pastPhases: array expected";
                        for (var i = 0; i < message.pastPhases.length; ++i)
                            switch (message.pastPhases[i]) {
                            default:
                                return "pastPhases: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                    }
                    if (message.previousUtteranceIntents != null && message.hasOwnProperty("previousUtteranceIntents")) {
                        if (!Array.isArray(message.previousUtteranceIntents))
                            return "previousUtteranceIntents: array expected";
                        for (var i = 0; i < message.previousUtteranceIntents.length; ++i) {
                            var error = $root.infinitusai.be.OperatorUtteranceWithIntent.verify(message.previousUtteranceIntents[i]);
                            if (error)
                                return "previousUtteranceIntents." + error;
                        }
                    }
                    if (message.previousActions != null && message.hasOwnProperty("previousActions")) {
                        if (!Array.isArray(message.previousActions))
                            return "previousActions: array expected";
                        for (var i = 0; i < message.previousActions.length; ++i) {
                            var error = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.verify(message.previousActions[i]);
                            if (error)
                                return "previousActions." + error;
                        }
                    }
                    if (message.lastState != null && message.hasOwnProperty("lastState"))
                        if (!$util.isString(message.lastState))
                            return "lastState: string expected";
                    return null;
                };
    
                /**
                 * Creates an OperatorTrainerCallState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorTrainerCallState} OperatorTrainerCallState
                 */
                OperatorTrainerCallState.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorTrainerCallState)
                        return object;
                    var message = new $root.infinitusai.be.OperatorTrainerCallState();
                    switch (object.phase) {
                    default:
                        if (typeof object.phase === "number") {
                            message.phase = object.phase;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.phase = 0;
                        break;
                    case "WELCOME":
                    case 1:
                        message.phase = 1;
                        break;
                    case "AUTHENTICATE_INTRO":
                    case 2:
                        message.phase = 2;
                        break;
                    case "AUTHENTICATE":
                    case 3:
                        message.phase = 3;
                        break;
                    case "GET_INFORMATION_INTRO":
                    case 4:
                        message.phase = 4;
                        break;
                    case "GET_INFORMATION":
                    case 5:
                        message.phase = 5;
                        break;
                    }
                    if (object.pastPhases) {
                        if (!Array.isArray(object.pastPhases))
                            throw TypeError(".infinitusai.be.OperatorTrainerCallState.pastPhases: array expected");
                        message.pastPhases = [];
                        for (var i = 0; i < object.pastPhases.length; ++i)
                            switch (object.pastPhases[i]) {
                            default:
                                if (typeof object.pastPhases[i] === "number") {
                                    message.pastPhases[i] = object.pastPhases[i];
                                    break;
                                }
                            case "UNKNOWN":
                            case 0:
                                message.pastPhases[i] = 0;
                                break;
                            case "WELCOME":
                            case 1:
                                message.pastPhases[i] = 1;
                                break;
                            case "AUTHENTICATE_INTRO":
                            case 2:
                                message.pastPhases[i] = 2;
                                break;
                            case "AUTHENTICATE":
                            case 3:
                                message.pastPhases[i] = 3;
                                break;
                            case "GET_INFORMATION_INTRO":
                            case 4:
                                message.pastPhases[i] = 4;
                                break;
                            case "GET_INFORMATION":
                            case 5:
                                message.pastPhases[i] = 5;
                                break;
                            }
                    }
                    if (object.previousUtteranceIntents) {
                        if (!Array.isArray(object.previousUtteranceIntents))
                            throw TypeError(".infinitusai.be.OperatorTrainerCallState.previousUtteranceIntents: array expected");
                        message.previousUtteranceIntents = [];
                        for (var i = 0; i < object.previousUtteranceIntents.length; ++i) {
                            if (typeof object.previousUtteranceIntents[i] !== "object")
                                throw TypeError(".infinitusai.be.OperatorTrainerCallState.previousUtteranceIntents: object expected");
                            message.previousUtteranceIntents[i] = $root.infinitusai.be.OperatorUtteranceWithIntent.fromObject(object.previousUtteranceIntents[i]);
                        }
                    }
                    if (object.previousActions) {
                        if (!Array.isArray(object.previousActions))
                            throw TypeError(".infinitusai.be.OperatorTrainerCallState.previousActions: array expected");
                        message.previousActions = [];
                        for (var i = 0; i < object.previousActions.length; ++i) {
                            if (typeof object.previousActions[i] !== "object")
                                throw TypeError(".infinitusai.be.OperatorTrainerCallState.previousActions: object expected");
                            message.previousActions[i] = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.fromObject(object.previousActions[i]);
                        }
                    }
                    if (object.lastState != null)
                        message.lastState = String(object.lastState);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorTrainerCallState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {infinitusai.be.OperatorTrainerCallState} message OperatorTrainerCallState
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorTrainerCallState.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.pastPhases = [];
                        object.previousUtteranceIntents = [];
                        object.previousActions = [];
                    }
                    if (options.defaults) {
                        object.phase = options.enums === String ? "UNKNOWN" : 0;
                        object.lastState = "";
                    }
                    if (message.phase != null && message.hasOwnProperty("phase"))
                        object.phase = options.enums === String ? $root.infinitusai.be.OperatorTrainerPhase[message.phase] === undefined ? message.phase : $root.infinitusai.be.OperatorTrainerPhase[message.phase] : message.phase;
                    if (message.pastPhases && message.pastPhases.length) {
                        object.pastPhases = [];
                        for (var j = 0; j < message.pastPhases.length; ++j)
                            object.pastPhases[j] = options.enums === String ? $root.infinitusai.be.OperatorTrainerPhase[message.pastPhases[j]] === undefined ? message.pastPhases[j] : $root.infinitusai.be.OperatorTrainerPhase[message.pastPhases[j]] : message.pastPhases[j];
                    }
                    if (message.previousUtteranceIntents && message.previousUtteranceIntents.length) {
                        object.previousUtteranceIntents = [];
                        for (var j = 0; j < message.previousUtteranceIntents.length; ++j)
                            object.previousUtteranceIntents[j] = $root.infinitusai.be.OperatorUtteranceWithIntent.toObject(message.previousUtteranceIntents[j], options);
                    }
                    if (message.previousActions && message.previousActions.length) {
                        object.previousActions = [];
                        for (var j = 0; j < message.previousActions.length; ++j)
                            object.previousActions[j] = $root.infinitusai.be.GenerateOperatorTrainerResponseOption.toObject(message.previousActions[j], options);
                    }
                    if (message.lastState != null && message.hasOwnProperty("lastState"))
                        object.lastState = message.lastState;
                    return object;
                };
    
                /**
                 * Converts this OperatorTrainerCallState to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorTrainerCallState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorTrainerCallState
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorTrainerCallState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorTrainerCallState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorTrainerCallState";
                };
    
                return OperatorTrainerCallState;
            })();
    
            /**
             * WebDriverCommandType enum.
             * @name infinitusai.be.WebDriverCommandType
             * @enum {number}
             * @property {number} WEB_DRIVER_COMMAND_TYPE_CLICK=0 WEB_DRIVER_COMMAND_TYPE_CLICK value
             * @property {number} WEB_DRIVER_COMMAND_TYPE_INPUT=1 WEB_DRIVER_COMMAND_TYPE_INPUT value
             * @property {number} WEB_DRIVER_COMMAND_TYPE_READ_INNER_HTML=2 WEB_DRIVER_COMMAND_TYPE_READ_INNER_HTML value
             * @property {number} WEB_DRIVER_COMMAND_TYPE_READ_ATTRIBUTE=3 WEB_DRIVER_COMMAND_TYPE_READ_ATTRIBUTE value
             * @property {number} WEB_DRIVER_COMMAND_TYPE_READ_XPATH=4 WEB_DRIVER_COMMAND_TYPE_READ_XPATH value
             * @property {number} WEB_DRIVER_COMMAND_TYPE_READ_XPATH_MULTI_RESULT=5 WEB_DRIVER_COMMAND_TYPE_READ_XPATH_MULTI_RESULT value
             */
            be.WebDriverCommandType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WEB_DRIVER_COMMAND_TYPE_CLICK"] = 0;
                values[valuesById[1] = "WEB_DRIVER_COMMAND_TYPE_INPUT"] = 1;
                values[valuesById[2] = "WEB_DRIVER_COMMAND_TYPE_READ_INNER_HTML"] = 2;
                values[valuesById[3] = "WEB_DRIVER_COMMAND_TYPE_READ_ATTRIBUTE"] = 3;
                values[valuesById[4] = "WEB_DRIVER_COMMAND_TYPE_READ_XPATH"] = 4;
                values[valuesById[5] = "WEB_DRIVER_COMMAND_TYPE_READ_XPATH_MULTI_RESULT"] = 5;
                return values;
            })();
    
            be.WebDriverCommand = (function() {
    
                /**
                 * Properties of a WebDriverCommand.
                 * @memberof infinitusai.be
                 * @interface IWebDriverCommand
                 * @property {string|null} [selector] WebDriverCommand selector
                 * @property {infinitusai.be.WebDriverCommandType|null} [commandType] WebDriverCommand commandType
                 * @property {string|null} [name] WebDriverCommand name
                 * @property {string|null} [value] WebDriverCommand value
                 * @property {string|null} [option] WebDriverCommand option
                 * @property {boolean|null} [failOnError] WebDriverCommand failOnError
                 */
    
                /**
                 * Constructs a new WebDriverCommand.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebDriverCommand.
                 * @implements IWebDriverCommand
                 * @constructor
                 * @param {infinitusai.be.IWebDriverCommand=} [properties] Properties to set
                 */
                function WebDriverCommand(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebDriverCommand selector.
                 * @member {string} selector
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                WebDriverCommand.prototype.selector = "";
    
                /**
                 * WebDriverCommand commandType.
                 * @member {infinitusai.be.WebDriverCommandType} commandType
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                WebDriverCommand.prototype.commandType = 0;
    
                /**
                 * WebDriverCommand name.
                 * @member {string} name
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                WebDriverCommand.prototype.name = "";
    
                /**
                 * WebDriverCommand value.
                 * @member {string|null|undefined} value
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                WebDriverCommand.prototype.value = null;
    
                /**
                 * WebDriverCommand option.
                 * @member {string|null|undefined} option
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                WebDriverCommand.prototype.option = null;
    
                /**
                 * WebDriverCommand failOnError.
                 * @member {boolean|null|undefined} failOnError
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                WebDriverCommand.prototype.failOnError = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * WebDriverCommand _value.
                 * @member {"value"|undefined} _value
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                Object.defineProperty(WebDriverCommand.prototype, "_value", {
                    get: $util.oneOfGetter($oneOfFields = ["value"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * WebDriverCommand _option.
                 * @member {"option"|undefined} _option
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                Object.defineProperty(WebDriverCommand.prototype, "_option", {
                    get: $util.oneOfGetter($oneOfFields = ["option"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * WebDriverCommand _failOnError.
                 * @member {"failOnError"|undefined} _failOnError
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 */
                Object.defineProperty(WebDriverCommand.prototype, "_failOnError", {
                    get: $util.oneOfGetter($oneOfFields = ["failOnError"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new WebDriverCommand instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {infinitusai.be.IWebDriverCommand=} [properties] Properties to set
                 * @returns {infinitusai.be.WebDriverCommand} WebDriverCommand instance
                 */
                WebDriverCommand.create = function create(properties) {
                    return new WebDriverCommand(properties);
                };
    
                /**
                 * Encodes the specified WebDriverCommand message. Does not implicitly {@link infinitusai.be.WebDriverCommand.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {infinitusai.be.IWebDriverCommand} message WebDriverCommand message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebDriverCommand.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.selector != null && Object.hasOwnProperty.call(message, "selector"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.selector);
                    if (message.commandType != null && Object.hasOwnProperty.call(message, "commandType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.commandType);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.value);
                    if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.option);
                    if (message.failOnError != null && Object.hasOwnProperty.call(message, "failOnError"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.failOnError);
                    return writer;
                };
    
                /**
                 * Encodes the specified WebDriverCommand message, length delimited. Does not implicitly {@link infinitusai.be.WebDriverCommand.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {infinitusai.be.IWebDriverCommand} message WebDriverCommand message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebDriverCommand.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebDriverCommand message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebDriverCommand} WebDriverCommand
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebDriverCommand.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebDriverCommand();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.selector = reader.string();
                                break;
                            }
                        case 2: {
                                message.commandType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                message.value = reader.string();
                                break;
                            }
                        case 5: {
                                message.option = reader.string();
                                break;
                            }
                        case 6: {
                                message.failOnError = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebDriverCommand message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebDriverCommand} WebDriverCommand
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebDriverCommand.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebDriverCommand message.
                 * @function verify
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebDriverCommand.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.selector != null && message.hasOwnProperty("selector"))
                        if (!$util.isString(message.selector))
                            return "selector: string expected";
                    if (message.commandType != null && message.hasOwnProperty("commandType"))
                        switch (message.commandType) {
                        default:
                            return "commandType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.value != null && message.hasOwnProperty("value")) {
                        properties._value = 1;
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    }
                    if (message.option != null && message.hasOwnProperty("option")) {
                        properties._option = 1;
                        if (!$util.isString(message.option))
                            return "option: string expected";
                    }
                    if (message.failOnError != null && message.hasOwnProperty("failOnError")) {
                        properties._failOnError = 1;
                        if (typeof message.failOnError !== "boolean")
                            return "failOnError: boolean expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a WebDriverCommand message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebDriverCommand} WebDriverCommand
                 */
                WebDriverCommand.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebDriverCommand)
                        return object;
                    var message = new $root.infinitusai.be.WebDriverCommand();
                    if (object.selector != null)
                        message.selector = String(object.selector);
                    switch (object.commandType) {
                    default:
                        if (typeof object.commandType === "number") {
                            message.commandType = object.commandType;
                            break;
                        }
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_CLICK":
                    case 0:
                        message.commandType = 0;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_INPUT":
                    case 1:
                        message.commandType = 1;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_READ_INNER_HTML":
                    case 2:
                        message.commandType = 2;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_READ_ATTRIBUTE":
                    case 3:
                        message.commandType = 3;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_READ_XPATH":
                    case 4:
                        message.commandType = 4;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_READ_XPATH_MULTI_RESULT":
                    case 5:
                        message.commandType = 5;
                        break;
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.option != null)
                        message.option = String(object.option);
                    if (object.failOnError != null)
                        message.failOnError = Boolean(object.failOnError);
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebDriverCommand message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {infinitusai.be.WebDriverCommand} message WebDriverCommand
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebDriverCommand.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.selector = "";
                        object.commandType = options.enums === String ? "WEB_DRIVER_COMMAND_TYPE_CLICK" : 0;
                        object.name = "";
                    }
                    if (message.selector != null && message.hasOwnProperty("selector"))
                        object.selector = message.selector;
                    if (message.commandType != null && message.hasOwnProperty("commandType"))
                        object.commandType = options.enums === String ? $root.infinitusai.be.WebDriverCommandType[message.commandType] === undefined ? message.commandType : $root.infinitusai.be.WebDriverCommandType[message.commandType] : message.commandType;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.value != null && message.hasOwnProperty("value")) {
                        object.value = message.value;
                        if (options.oneofs)
                            object._value = "value";
                    }
                    if (message.option != null && message.hasOwnProperty("option")) {
                        object.option = message.option;
                        if (options.oneofs)
                            object._option = "option";
                    }
                    if (message.failOnError != null && message.hasOwnProperty("failOnError")) {
                        object.failOnError = message.failOnError;
                        if (options.oneofs)
                            object._failOnError = "failOnError";
                    }
                    return object;
                };
    
                /**
                 * Converts this WebDriverCommand to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebDriverCommand
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebDriverCommand.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebDriverCommand
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebDriverCommand
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebDriverCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebDriverCommand";
                };
    
                return WebDriverCommand;
            })();
    
            be.WebDriverResult = (function() {
    
                /**
                 * Properties of a WebDriverResult.
                 * @memberof infinitusai.be
                 * @interface IWebDriverResult
                 * @property {infinitusai.be.WebDriverCommandType|null} [commandType] WebDriverResult commandType
                 * @property {string|null} [name] WebDriverResult name
                 * @property {string|null} [value] WebDriverResult value
                 * @property {Array.<string>|null} [logs] WebDriverResult logs
                 * @property {Array.<string>|null} [values] WebDriverResult values
                 */
    
                /**
                 * Constructs a new WebDriverResult.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebDriverResult.
                 * @implements IWebDriverResult
                 * @constructor
                 * @param {infinitusai.be.IWebDriverResult=} [properties] Properties to set
                 */
                function WebDriverResult(properties) {
                    this.logs = [];
                    this.values = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebDriverResult commandType.
                 * @member {infinitusai.be.WebDriverCommandType} commandType
                 * @memberof infinitusai.be.WebDriverResult
                 * @instance
                 */
                WebDriverResult.prototype.commandType = 0;
    
                /**
                 * WebDriverResult name.
                 * @member {string} name
                 * @memberof infinitusai.be.WebDriverResult
                 * @instance
                 */
                WebDriverResult.prototype.name = "";
    
                /**
                 * WebDriverResult value.
                 * @member {string|null|undefined} value
                 * @memberof infinitusai.be.WebDriverResult
                 * @instance
                 */
                WebDriverResult.prototype.value = null;
    
                /**
                 * WebDriverResult logs.
                 * @member {Array.<string>} logs
                 * @memberof infinitusai.be.WebDriverResult
                 * @instance
                 */
                WebDriverResult.prototype.logs = $util.emptyArray;
    
                /**
                 * WebDriverResult values.
                 * @member {Array.<string>} values
                 * @memberof infinitusai.be.WebDriverResult
                 * @instance
                 */
                WebDriverResult.prototype.values = $util.emptyArray;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * WebDriverResult _value.
                 * @member {"value"|undefined} _value
                 * @memberof infinitusai.be.WebDriverResult
                 * @instance
                 */
                Object.defineProperty(WebDriverResult.prototype, "_value", {
                    get: $util.oneOfGetter($oneOfFields = ["value"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new WebDriverResult instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {infinitusai.be.IWebDriverResult=} [properties] Properties to set
                 * @returns {infinitusai.be.WebDriverResult} WebDriverResult instance
                 */
                WebDriverResult.create = function create(properties) {
                    return new WebDriverResult(properties);
                };
    
                /**
                 * Encodes the specified WebDriverResult message. Does not implicitly {@link infinitusai.be.WebDriverResult.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {infinitusai.be.IWebDriverResult} message WebDriverResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebDriverResult.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.commandType != null && Object.hasOwnProperty.call(message, "commandType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.commandType);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.value);
                    if (message.logs != null && message.logs.length)
                        for (var i = 0; i < message.logs.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.logs[i]);
                    if (message.values != null && message.values.length)
                        for (var i = 0; i < message.values.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.values[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified WebDriverResult message, length delimited. Does not implicitly {@link infinitusai.be.WebDriverResult.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {infinitusai.be.IWebDriverResult} message WebDriverResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebDriverResult.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebDriverResult message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebDriverResult} WebDriverResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebDriverResult.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebDriverResult();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.commandType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                message.value = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.logs && message.logs.length))
                                    message.logs = [];
                                message.logs.push(reader.string());
                                break;
                            }
                        case 6: {
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebDriverResult message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebDriverResult} WebDriverResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebDriverResult.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebDriverResult message.
                 * @function verify
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebDriverResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.commandType != null && message.hasOwnProperty("commandType"))
                        switch (message.commandType) {
                        default:
                            return "commandType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.value != null && message.hasOwnProperty("value")) {
                        properties._value = 1;
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    }
                    if (message.logs != null && message.hasOwnProperty("logs")) {
                        if (!Array.isArray(message.logs))
                            return "logs: array expected";
                        for (var i = 0; i < message.logs.length; ++i)
                            if (!$util.isString(message.logs[i]))
                                return "logs: string[] expected";
                    }
                    if (message.values != null && message.hasOwnProperty("values")) {
                        if (!Array.isArray(message.values))
                            return "values: array expected";
                        for (var i = 0; i < message.values.length; ++i)
                            if (!$util.isString(message.values[i]))
                                return "values: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a WebDriverResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebDriverResult} WebDriverResult
                 */
                WebDriverResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebDriverResult)
                        return object;
                    var message = new $root.infinitusai.be.WebDriverResult();
                    switch (object.commandType) {
                    default:
                        if (typeof object.commandType === "number") {
                            message.commandType = object.commandType;
                            break;
                        }
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_CLICK":
                    case 0:
                        message.commandType = 0;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_INPUT":
                    case 1:
                        message.commandType = 1;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_READ_INNER_HTML":
                    case 2:
                        message.commandType = 2;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_READ_ATTRIBUTE":
                    case 3:
                        message.commandType = 3;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_READ_XPATH":
                    case 4:
                        message.commandType = 4;
                        break;
                    case "WEB_DRIVER_COMMAND_TYPE_READ_XPATH_MULTI_RESULT":
                    case 5:
                        message.commandType = 5;
                        break;
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.logs) {
                        if (!Array.isArray(object.logs))
                            throw TypeError(".infinitusai.be.WebDriverResult.logs: array expected");
                        message.logs = [];
                        for (var i = 0; i < object.logs.length; ++i)
                            message.logs[i] = String(object.logs[i]);
                    }
                    if (object.values) {
                        if (!Array.isArray(object.values))
                            throw TypeError(".infinitusai.be.WebDriverResult.values: array expected");
                        message.values = [];
                        for (var i = 0; i < object.values.length; ++i)
                            message.values[i] = String(object.values[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebDriverResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {infinitusai.be.WebDriverResult} message WebDriverResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebDriverResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.logs = [];
                        object.values = [];
                    }
                    if (options.defaults) {
                        object.commandType = options.enums === String ? "WEB_DRIVER_COMMAND_TYPE_CLICK" : 0;
                        object.name = "";
                    }
                    if (message.commandType != null && message.hasOwnProperty("commandType"))
                        object.commandType = options.enums === String ? $root.infinitusai.be.WebDriverCommandType[message.commandType] === undefined ? message.commandType : $root.infinitusai.be.WebDriverCommandType[message.commandType] : message.commandType;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.value != null && message.hasOwnProperty("value")) {
                        object.value = message.value;
                        if (options.oneofs)
                            object._value = "value";
                    }
                    if (message.logs && message.logs.length) {
                        object.logs = [];
                        for (var j = 0; j < message.logs.length; ++j)
                            object.logs[j] = message.logs[j];
                    }
                    if (message.values && message.values.length) {
                        object.values = [];
                        for (var j = 0; j < message.values.length; ++j)
                            object.values[j] = message.values[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this WebDriverResult to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebDriverResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebDriverResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebDriverResult
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebDriverResult
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebDriverResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebDriverResult";
                };
    
                return WebDriverResult;
            })();
    
            be.WebDriverRequest = (function() {
    
                /**
                 * Properties of a WebDriverRequest.
                 * @memberof infinitusai.be
                 * @interface IWebDriverRequest
                 * @property {string|null} [url] WebDriverRequest url
                 * @property {Array.<infinitusai.be.IWebDriverCommand>|null} [commands] WebDriverRequest commands
                 */
    
                /**
                 * Constructs a new WebDriverRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebDriverRequest.
                 * @implements IWebDriverRequest
                 * @constructor
                 * @param {infinitusai.be.IWebDriverRequest=} [properties] Properties to set
                 */
                function WebDriverRequest(properties) {
                    this.commands = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebDriverRequest url.
                 * @member {string} url
                 * @memberof infinitusai.be.WebDriverRequest
                 * @instance
                 */
                WebDriverRequest.prototype.url = "";
    
                /**
                 * WebDriverRequest commands.
                 * @member {Array.<infinitusai.be.IWebDriverCommand>} commands
                 * @memberof infinitusai.be.WebDriverRequest
                 * @instance
                 */
                WebDriverRequest.prototype.commands = $util.emptyArray;
    
                /**
                 * Creates a new WebDriverRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {infinitusai.be.IWebDriverRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.WebDriverRequest} WebDriverRequest instance
                 */
                WebDriverRequest.create = function create(properties) {
                    return new WebDriverRequest(properties);
                };
    
                /**
                 * Encodes the specified WebDriverRequest message. Does not implicitly {@link infinitusai.be.WebDriverRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {infinitusai.be.IWebDriverRequest} message WebDriverRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebDriverRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                    if (message.commands != null && message.commands.length)
                        for (var i = 0; i < message.commands.length; ++i)
                            $root.infinitusai.be.WebDriverCommand.encode(message.commands[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified WebDriverRequest message, length delimited. Does not implicitly {@link infinitusai.be.WebDriverRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {infinitusai.be.IWebDriverRequest} message WebDriverRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebDriverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebDriverRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebDriverRequest} WebDriverRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebDriverRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebDriverRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.url = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.commands && message.commands.length))
                                    message.commands = [];
                                message.commands.push($root.infinitusai.be.WebDriverCommand.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebDriverRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebDriverRequest} WebDriverRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebDriverRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebDriverRequest message.
                 * @function verify
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebDriverRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.commands != null && message.hasOwnProperty("commands")) {
                        if (!Array.isArray(message.commands))
                            return "commands: array expected";
                        for (var i = 0; i < message.commands.length; ++i) {
                            var error = $root.infinitusai.be.WebDriverCommand.verify(message.commands[i]);
                            if (error)
                                return "commands." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a WebDriverRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebDriverRequest} WebDriverRequest
                 */
                WebDriverRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebDriverRequest)
                        return object;
                    var message = new $root.infinitusai.be.WebDriverRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.commands) {
                        if (!Array.isArray(object.commands))
                            throw TypeError(".infinitusai.be.WebDriverRequest.commands: array expected");
                        message.commands = [];
                        for (var i = 0; i < object.commands.length; ++i) {
                            if (typeof object.commands[i] !== "object")
                                throw TypeError(".infinitusai.be.WebDriverRequest.commands: object expected");
                            message.commands[i] = $root.infinitusai.be.WebDriverCommand.fromObject(object.commands[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebDriverRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {infinitusai.be.WebDriverRequest} message WebDriverRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebDriverRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.commands = [];
                    if (options.defaults)
                        object.url = "";
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.commands && message.commands.length) {
                        object.commands = [];
                        for (var j = 0; j < message.commands.length; ++j)
                            object.commands[j] = $root.infinitusai.be.WebDriverCommand.toObject(message.commands[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this WebDriverRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebDriverRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebDriverRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebDriverRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebDriverRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebDriverRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebDriverRequest";
                };
    
                return WebDriverRequest;
            })();
    
            be.WebDriverResponse = (function() {
    
                /**
                 * Properties of a WebDriverResponse.
                 * @memberof infinitusai.be
                 * @interface IWebDriverResponse
                 * @property {Array.<infinitusai.be.IWebDriverResult>|null} [results] WebDriverResponse results
                 * @property {string|null} [html] WebDriverResponse html
                 */
    
                /**
                 * Constructs a new WebDriverResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a WebDriverResponse.
                 * @implements IWebDriverResponse
                 * @constructor
                 * @param {infinitusai.be.IWebDriverResponse=} [properties] Properties to set
                 */
                function WebDriverResponse(properties) {
                    this.results = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * WebDriverResponse results.
                 * @member {Array.<infinitusai.be.IWebDriverResult>} results
                 * @memberof infinitusai.be.WebDriverResponse
                 * @instance
                 */
                WebDriverResponse.prototype.results = $util.emptyArray;
    
                /**
                 * WebDriverResponse html.
                 * @member {string|null|undefined} html
                 * @memberof infinitusai.be.WebDriverResponse
                 * @instance
                 */
                WebDriverResponse.prototype.html = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * WebDriverResponse _html.
                 * @member {"html"|undefined} _html
                 * @memberof infinitusai.be.WebDriverResponse
                 * @instance
                 */
                Object.defineProperty(WebDriverResponse.prototype, "_html", {
                    get: $util.oneOfGetter($oneOfFields = ["html"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new WebDriverResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {infinitusai.be.IWebDriverResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.WebDriverResponse} WebDriverResponse instance
                 */
                WebDriverResponse.create = function create(properties) {
                    return new WebDriverResponse(properties);
                };
    
                /**
                 * Encodes the specified WebDriverResponse message. Does not implicitly {@link infinitusai.be.WebDriverResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {infinitusai.be.IWebDriverResponse} message WebDriverResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebDriverResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.results != null && message.results.length)
                        for (var i = 0; i < message.results.length; ++i)
                            $root.infinitusai.be.WebDriverResult.encode(message.results[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.html != null && Object.hasOwnProperty.call(message, "html"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.html);
                    return writer;
                };
    
                /**
                 * Encodes the specified WebDriverResponse message, length delimited. Does not implicitly {@link infinitusai.be.WebDriverResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {infinitusai.be.IWebDriverResponse} message WebDriverResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebDriverResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a WebDriverResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.WebDriverResponse} WebDriverResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebDriverResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.WebDriverResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.results && message.results.length))
                                    message.results = [];
                                message.results.push($root.infinitusai.be.WebDriverResult.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.html = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a WebDriverResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.WebDriverResponse} WebDriverResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebDriverResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a WebDriverResponse message.
                 * @function verify
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebDriverResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.results != null && message.hasOwnProperty("results")) {
                        if (!Array.isArray(message.results))
                            return "results: array expected";
                        for (var i = 0; i < message.results.length; ++i) {
                            var error = $root.infinitusai.be.WebDriverResult.verify(message.results[i]);
                            if (error)
                                return "results." + error;
                        }
                    }
                    if (message.html != null && message.hasOwnProperty("html")) {
                        properties._html = 1;
                        if (!$util.isString(message.html))
                            return "html: string expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a WebDriverResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.WebDriverResponse} WebDriverResponse
                 */
                WebDriverResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.WebDriverResponse)
                        return object;
                    var message = new $root.infinitusai.be.WebDriverResponse();
                    if (object.results) {
                        if (!Array.isArray(object.results))
                            throw TypeError(".infinitusai.be.WebDriverResponse.results: array expected");
                        message.results = [];
                        for (var i = 0; i < object.results.length; ++i) {
                            if (typeof object.results[i] !== "object")
                                throw TypeError(".infinitusai.be.WebDriverResponse.results: object expected");
                            message.results[i] = $root.infinitusai.be.WebDriverResult.fromObject(object.results[i]);
                        }
                    }
                    if (object.html != null)
                        message.html = String(object.html);
                    return message;
                };
    
                /**
                 * Creates a plain object from a WebDriverResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {infinitusai.be.WebDriverResponse} message WebDriverResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebDriverResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.results = [];
                    if (message.results && message.results.length) {
                        object.results = [];
                        for (var j = 0; j < message.results.length; ++j)
                            object.results[j] = $root.infinitusai.be.WebDriverResult.toObject(message.results[j], options);
                    }
                    if (message.html != null && message.hasOwnProperty("html")) {
                        object.html = message.html;
                        if (options.oneofs)
                            object._html = "html";
                    }
                    return object;
                };
    
                /**
                 * Converts this WebDriverResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.WebDriverResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebDriverResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for WebDriverResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.WebDriverResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WebDriverResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.WebDriverResponse";
                };
    
                return WebDriverResponse;
            })();
    
            be.OperatorWorkingState = (function() {
    
                /**
                 * Properties of an OperatorWorkingState.
                 * @memberof infinitusai.be
                 * @interface IOperatorWorkingState
                 * @property {infinitusai.be.IOperatorActivityUpdate|null} [operatorActivityUpdate] OperatorWorkingState operatorActivityUpdate
                 * @property {string|null} [userEmail] OperatorWorkingState userEmail
                 * @property {string|null} [userId] OperatorWorkingState userId
                 * @property {string|null} [userDisplayName] OperatorWorkingState userDisplayName
                 * @property {number|Long|null} [lastStatusUpdateTimestampMillis] OperatorWorkingState lastStatusUpdateTimestampMillis
                 * @property {infinitusai.be.OperatorOnlineStatus|null} [operatorOnlineStatus] OperatorWorkingState operatorOnlineStatus
                 * @property {infinitusai.be.OperatorOfflineReason|null} [operatorOfflineReason] OperatorWorkingState operatorOfflineReason
                 * @property {infinitusai.be.OperatorWorkingState.IQueuePositionMetadata|null} [queuePositionMetadata] OperatorWorkingState queuePositionMetadata
                 */
    
                /**
                 * Constructs a new OperatorWorkingState.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorWorkingState.
                 * @implements IOperatorWorkingState
                 * @constructor
                 * @param {infinitusai.be.IOperatorWorkingState=} [properties] Properties to set
                 */
                function OperatorWorkingState(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorWorkingState operatorActivityUpdate.
                 * @member {infinitusai.be.IOperatorActivityUpdate|null|undefined} operatorActivityUpdate
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 */
                OperatorWorkingState.prototype.operatorActivityUpdate = null;
    
                /**
                 * OperatorWorkingState userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 */
                OperatorWorkingState.prototype.userEmail = "";
    
                /**
                 * OperatorWorkingState userId.
                 * @member {string} userId
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 */
                OperatorWorkingState.prototype.userId = "";
    
                /**
                 * OperatorWorkingState userDisplayName.
                 * @member {string} userDisplayName
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 */
                OperatorWorkingState.prototype.userDisplayName = "";
    
                /**
                 * OperatorWorkingState lastStatusUpdateTimestampMillis.
                 * @member {number|Long} lastStatusUpdateTimestampMillis
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 */
                OperatorWorkingState.prototype.lastStatusUpdateTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * OperatorWorkingState operatorOnlineStatus.
                 * @member {infinitusai.be.OperatorOnlineStatus} operatorOnlineStatus
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 */
                OperatorWorkingState.prototype.operatorOnlineStatus = 0;
    
                /**
                 * OperatorWorkingState operatorOfflineReason.
                 * @member {infinitusai.be.OperatorOfflineReason} operatorOfflineReason
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 */
                OperatorWorkingState.prototype.operatorOfflineReason = 0;
    
                /**
                 * OperatorWorkingState queuePositionMetadata.
                 * @member {infinitusai.be.OperatorWorkingState.IQueuePositionMetadata|null|undefined} queuePositionMetadata
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 */
                OperatorWorkingState.prototype.queuePositionMetadata = null;
    
                /**
                 * Creates a new OperatorWorkingState instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {infinitusai.be.IOperatorWorkingState=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorWorkingState} OperatorWorkingState instance
                 */
                OperatorWorkingState.create = function create(properties) {
                    return new OperatorWorkingState(properties);
                };
    
                /**
                 * Encodes the specified OperatorWorkingState message. Does not implicitly {@link infinitusai.be.OperatorWorkingState.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {infinitusai.be.IOperatorWorkingState} message OperatorWorkingState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorWorkingState.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorActivityUpdate != null && Object.hasOwnProperty.call(message, "operatorActivityUpdate"))
                        $root.infinitusai.be.OperatorActivityUpdate.encode(message.operatorActivityUpdate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userEmail);
                    if (message.userDisplayName != null && Object.hasOwnProperty.call(message, "userDisplayName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.userDisplayName);
                    if (message.lastStatusUpdateTimestampMillis != null && Object.hasOwnProperty.call(message, "lastStatusUpdateTimestampMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.lastStatusUpdateTimestampMillis);
                    if (message.operatorOnlineStatus != null && Object.hasOwnProperty.call(message, "operatorOnlineStatus"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.operatorOnlineStatus);
                    if (message.operatorOfflineReason != null && Object.hasOwnProperty.call(message, "operatorOfflineReason"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.operatorOfflineReason);
                    if (message.queuePositionMetadata != null && Object.hasOwnProperty.call(message, "queuePositionMetadata"))
                        $root.infinitusai.be.OperatorWorkingState.QueuePositionMetadata.encode(message.queuePositionMetadata, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.userId);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorWorkingState message, length delimited. Does not implicitly {@link infinitusai.be.OperatorWorkingState.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {infinitusai.be.IOperatorWorkingState} message OperatorWorkingState message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorWorkingState.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorWorkingState message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorWorkingState} OperatorWorkingState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorWorkingState.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorWorkingState();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operatorActivityUpdate = $root.infinitusai.be.OperatorActivityUpdate.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 16: {
                                message.userId = reader.string();
                                break;
                            }
                        case 3: {
                                message.userDisplayName = reader.string();
                                break;
                            }
                        case 9: {
                                message.lastStatusUpdateTimestampMillis = reader.int64();
                                break;
                            }
                        case 11: {
                                message.operatorOnlineStatus = reader.int32();
                                break;
                            }
                        case 12: {
                                message.operatorOfflineReason = reader.int32();
                                break;
                            }
                        case 13: {
                                message.queuePositionMetadata = $root.infinitusai.be.OperatorWorkingState.QueuePositionMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorWorkingState message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorWorkingState} OperatorWorkingState
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorWorkingState.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorWorkingState message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorWorkingState.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorActivityUpdate != null && message.hasOwnProperty("operatorActivityUpdate")) {
                        var error = $root.infinitusai.be.OperatorActivityUpdate.verify(message.operatorActivityUpdate);
                        if (error)
                            return "operatorActivityUpdate." + error;
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.userDisplayName != null && message.hasOwnProperty("userDisplayName"))
                        if (!$util.isString(message.userDisplayName))
                            return "userDisplayName: string expected";
                    if (message.lastStatusUpdateTimestampMillis != null && message.hasOwnProperty("lastStatusUpdateTimestampMillis"))
                        if (!$util.isInteger(message.lastStatusUpdateTimestampMillis) && !(message.lastStatusUpdateTimestampMillis && $util.isInteger(message.lastStatusUpdateTimestampMillis.low) && $util.isInteger(message.lastStatusUpdateTimestampMillis.high)))
                            return "lastStatusUpdateTimestampMillis: integer|Long expected";
                    if (message.operatorOnlineStatus != null && message.hasOwnProperty("operatorOnlineStatus"))
                        switch (message.operatorOnlineStatus) {
                        default:
                            return "operatorOnlineStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.operatorOfflineReason != null && message.hasOwnProperty("operatorOfflineReason"))
                        switch (message.operatorOfflineReason) {
                        default:
                            return "operatorOfflineReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            break;
                        }
                    if (message.queuePositionMetadata != null && message.hasOwnProperty("queuePositionMetadata")) {
                        var error = $root.infinitusai.be.OperatorWorkingState.QueuePositionMetadata.verify(message.queuePositionMetadata);
                        if (error)
                            return "queuePositionMetadata." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an OperatorWorkingState message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorWorkingState} OperatorWorkingState
                 */
                OperatorWorkingState.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorWorkingState)
                        return object;
                    var message = new $root.infinitusai.be.OperatorWorkingState();
                    if (object.operatorActivityUpdate != null) {
                        if (typeof object.operatorActivityUpdate !== "object")
                            throw TypeError(".infinitusai.be.OperatorWorkingState.operatorActivityUpdate: object expected");
                        message.operatorActivityUpdate = $root.infinitusai.be.OperatorActivityUpdate.fromObject(object.operatorActivityUpdate);
                    }
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.userDisplayName != null)
                        message.userDisplayName = String(object.userDisplayName);
                    if (object.lastStatusUpdateTimestampMillis != null)
                        if ($util.Long)
                            (message.lastStatusUpdateTimestampMillis = $util.Long.fromValue(object.lastStatusUpdateTimestampMillis)).unsigned = false;
                        else if (typeof object.lastStatusUpdateTimestampMillis === "string")
                            message.lastStatusUpdateTimestampMillis = parseInt(object.lastStatusUpdateTimestampMillis, 10);
                        else if (typeof object.lastStatusUpdateTimestampMillis === "number")
                            message.lastStatusUpdateTimestampMillis = object.lastStatusUpdateTimestampMillis;
                        else if (typeof object.lastStatusUpdateTimestampMillis === "object")
                            message.lastStatusUpdateTimestampMillis = new $util.LongBits(object.lastStatusUpdateTimestampMillis.low >>> 0, object.lastStatusUpdateTimestampMillis.high >>> 0).toNumber();
                    switch (object.operatorOnlineStatus) {
                    default:
                        if (typeof object.operatorOnlineStatus === "number") {
                            message.operatorOnlineStatus = object.operatorOnlineStatus;
                            break;
                        }
                        break;
                    case "OPERATOR_ONLINE_STATUS_UNKNOWN":
                    case 0:
                        message.operatorOnlineStatus = 0;
                        break;
                    case "OPERATOR_ONLINE_STATUS_OFFLINE":
                    case 1:
                        message.operatorOnlineStatus = 1;
                        break;
                    case "OPERATOR_ONLINE_STATUS_ONLINE":
                    case 2:
                        message.operatorOnlineStatus = 2;
                        break;
                    }
                    switch (object.operatorOfflineReason) {
                    default:
                        if (typeof object.operatorOfflineReason === "number") {
                            message.operatorOfflineReason = object.operatorOfflineReason;
                            break;
                        }
                        break;
                    case "OPERATOR_OFFLINE_REASON_UNKNOWN":
                    case 0:
                        message.operatorOfflineReason = 0;
                        break;
                    case "OPERATOR_OFFLINE_REASON_MEETING":
                    case 1:
                        message.operatorOfflineReason = 1;
                        break;
                    case "OPERATOR_OFFLINE_REASON_MANAGER_MEETING":
                    case 2:
                        message.operatorOfflineReason = 2;
                        break;
                    case "OPERATOR_OFFLINE_REASON_PROJECT":
                    case 3:
                        message.operatorOfflineReason = 3;
                        break;
                    case "OPERATOR_OFFLINE_REASON_LUNCH":
                    case 4:
                        message.operatorOfflineReason = 4;
                        break;
                    case "OPERATOR_OFFLINE_REASON_BREAK":
                    case 5:
                        message.operatorOfflineReason = 5;
                        break;
                    case "OPERATOR_OFFLINE_REASON_DONE_TODAY":
                    case 6:
                        message.operatorOfflineReason = 6;
                        break;
                    case "OPERATOR_OFFLINE_REASON_OTHER":
                    case 7:
                        message.operatorOfflineReason = 7;
                        break;
                    case "OPERATOR_OFFLINE_REASON_NULL":
                    case 8:
                        message.operatorOfflineReason = 8;
                        break;
                    case "OPERATOR_OFFLINE_REASON_INACTIVE":
                    case 9:
                        message.operatorOfflineReason = 9;
                        break;
                    case "OPERATOR_OFFLINE_REASON_TRAINING":
                    case 10:
                        message.operatorOfflineReason = 10;
                        break;
                    }
                    if (object.queuePositionMetadata != null) {
                        if (typeof object.queuePositionMetadata !== "object")
                            throw TypeError(".infinitusai.be.OperatorWorkingState.queuePositionMetadata: object expected");
                        message.queuePositionMetadata = $root.infinitusai.be.OperatorWorkingState.QueuePositionMetadata.fromObject(object.queuePositionMetadata);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorWorkingState message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {infinitusai.be.OperatorWorkingState} message OperatorWorkingState
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorWorkingState.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operatorActivityUpdate = null;
                        object.userEmail = "";
                        object.userDisplayName = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? "0" : 0;
                        object.operatorOnlineStatus = options.enums === String ? "OPERATOR_ONLINE_STATUS_UNKNOWN" : 0;
                        object.operatorOfflineReason = options.enums === String ? "OPERATOR_OFFLINE_REASON_UNKNOWN" : 0;
                        object.queuePositionMetadata = null;
                        object.userId = "";
                    }
                    if (message.operatorActivityUpdate != null && message.hasOwnProperty("operatorActivityUpdate"))
                        object.operatorActivityUpdate = $root.infinitusai.be.OperatorActivityUpdate.toObject(message.operatorActivityUpdate, options);
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.userDisplayName != null && message.hasOwnProperty("userDisplayName"))
                        object.userDisplayName = message.userDisplayName;
                    if (message.lastStatusUpdateTimestampMillis != null && message.hasOwnProperty("lastStatusUpdateTimestampMillis"))
                        if (typeof message.lastStatusUpdateTimestampMillis === "number")
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? String(message.lastStatusUpdateTimestampMillis) : message.lastStatusUpdateTimestampMillis;
                        else
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastStatusUpdateTimestampMillis) : options.longs === Number ? new $util.LongBits(message.lastStatusUpdateTimestampMillis.low >>> 0, message.lastStatusUpdateTimestampMillis.high >>> 0).toNumber() : message.lastStatusUpdateTimestampMillis;
                    if (message.operatorOnlineStatus != null && message.hasOwnProperty("operatorOnlineStatus"))
                        object.operatorOnlineStatus = options.enums === String ? $root.infinitusai.be.OperatorOnlineStatus[message.operatorOnlineStatus] === undefined ? message.operatorOnlineStatus : $root.infinitusai.be.OperatorOnlineStatus[message.operatorOnlineStatus] : message.operatorOnlineStatus;
                    if (message.operatorOfflineReason != null && message.hasOwnProperty("operatorOfflineReason"))
                        object.operatorOfflineReason = options.enums === String ? $root.infinitusai.be.OperatorOfflineReason[message.operatorOfflineReason] === undefined ? message.operatorOfflineReason : $root.infinitusai.be.OperatorOfflineReason[message.operatorOfflineReason] : message.operatorOfflineReason;
                    if (message.queuePositionMetadata != null && message.hasOwnProperty("queuePositionMetadata"))
                        object.queuePositionMetadata = $root.infinitusai.be.OperatorWorkingState.QueuePositionMetadata.toObject(message.queuePositionMetadata, options);
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };
    
                /**
                 * Converts this OperatorWorkingState to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorWorkingState.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorWorkingState
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorWorkingState
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorWorkingState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorWorkingState";
                };
    
                OperatorWorkingState.QueuePositionMetadata = (function() {
    
                    /**
                     * Properties of a QueuePositionMetadata.
                     * @memberof infinitusai.be.OperatorWorkingState
                     * @interface IQueuePositionMetadata
                     * @property {number|null} [queueSize] QueuePositionMetadata queueSize
                     * @property {number|null} [queuePosition] QueuePositionMetadata queuePosition
                     */
    
                    /**
                     * Constructs a new QueuePositionMetadata.
                     * @memberof infinitusai.be.OperatorWorkingState
                     * @classdesc Represents a QueuePositionMetadata.
                     * @implements IQueuePositionMetadata
                     * @constructor
                     * @param {infinitusai.be.OperatorWorkingState.IQueuePositionMetadata=} [properties] Properties to set
                     */
                    function QueuePositionMetadata(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * QueuePositionMetadata queueSize.
                     * @member {number} queueSize
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @instance
                     */
                    QueuePositionMetadata.prototype.queueSize = 0;
    
                    /**
                     * QueuePositionMetadata queuePosition.
                     * @member {number} queuePosition
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @instance
                     */
                    QueuePositionMetadata.prototype.queuePosition = 0;
    
                    /**
                     * Creates a new QueuePositionMetadata instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {infinitusai.be.OperatorWorkingState.IQueuePositionMetadata=} [properties] Properties to set
                     * @returns {infinitusai.be.OperatorWorkingState.QueuePositionMetadata} QueuePositionMetadata instance
                     */
                    QueuePositionMetadata.create = function create(properties) {
                        return new QueuePositionMetadata(properties);
                    };
    
                    /**
                     * Encodes the specified QueuePositionMetadata message. Does not implicitly {@link infinitusai.be.OperatorWorkingState.QueuePositionMetadata.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {infinitusai.be.OperatorWorkingState.IQueuePositionMetadata} message QueuePositionMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QueuePositionMetadata.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.queueSize != null && Object.hasOwnProperty.call(message, "queueSize"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.queueSize);
                        if (message.queuePosition != null && Object.hasOwnProperty.call(message, "queuePosition"))
                            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.queuePosition);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified QueuePositionMetadata message, length delimited. Does not implicitly {@link infinitusai.be.OperatorWorkingState.QueuePositionMetadata.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {infinitusai.be.OperatorWorkingState.IQueuePositionMetadata} message QueuePositionMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QueuePositionMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a QueuePositionMetadata message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.OperatorWorkingState.QueuePositionMetadata} QueuePositionMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QueuePositionMetadata.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorWorkingState.QueuePositionMetadata();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 14: {
                                    message.queueSize = reader.int32();
                                    break;
                                }
                            case 15: {
                                    message.queuePosition = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a QueuePositionMetadata message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.OperatorWorkingState.QueuePositionMetadata} QueuePositionMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QueuePositionMetadata.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a QueuePositionMetadata message.
                     * @function verify
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QueuePositionMetadata.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.queueSize != null && message.hasOwnProperty("queueSize"))
                            if (!$util.isInteger(message.queueSize))
                                return "queueSize: integer expected";
                        if (message.queuePosition != null && message.hasOwnProperty("queuePosition"))
                            if (!$util.isInteger(message.queuePosition))
                                return "queuePosition: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a QueuePositionMetadata message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.OperatorWorkingState.QueuePositionMetadata} QueuePositionMetadata
                     */
                    QueuePositionMetadata.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.OperatorWorkingState.QueuePositionMetadata)
                            return object;
                        var message = new $root.infinitusai.be.OperatorWorkingState.QueuePositionMetadata();
                        if (object.queueSize != null)
                            message.queueSize = object.queueSize | 0;
                        if (object.queuePosition != null)
                            message.queuePosition = object.queuePosition | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a QueuePositionMetadata message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {infinitusai.be.OperatorWorkingState.QueuePositionMetadata} message QueuePositionMetadata
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QueuePositionMetadata.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.queueSize = 0;
                            object.queuePosition = 0;
                        }
                        if (message.queueSize != null && message.hasOwnProperty("queueSize"))
                            object.queueSize = message.queueSize;
                        if (message.queuePosition != null && message.hasOwnProperty("queuePosition"))
                            object.queuePosition = message.queuePosition;
                        return object;
                    };
    
                    /**
                     * Converts this QueuePositionMetadata to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QueuePositionMetadata.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for QueuePositionMetadata
                     * @function getTypeUrl
                     * @memberof infinitusai.be.OperatorWorkingState.QueuePositionMetadata
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    QueuePositionMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.OperatorWorkingState.QueuePositionMetadata";
                    };
    
                    return QueuePositionMetadata;
                })();
    
                return OperatorWorkingState;
            })();
    
            be.CurrentUserActivityRequest = (function() {
    
                /**
                 * Properties of a CurrentUserActivityRequest.
                 * @memberof infinitusai.be
                 * @interface ICurrentUserActivityRequest
                 */
    
                /**
                 * Constructs a new CurrentUserActivityRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CurrentUserActivityRequest.
                 * @implements ICurrentUserActivityRequest
                 * @constructor
                 * @param {infinitusai.be.ICurrentUserActivityRequest=} [properties] Properties to set
                 */
                function CurrentUserActivityRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CurrentUserActivityRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {infinitusai.be.ICurrentUserActivityRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CurrentUserActivityRequest} CurrentUserActivityRequest instance
                 */
                CurrentUserActivityRequest.create = function create(properties) {
                    return new CurrentUserActivityRequest(properties);
                };
    
                /**
                 * Encodes the specified CurrentUserActivityRequest message. Does not implicitly {@link infinitusai.be.CurrentUserActivityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {infinitusai.be.ICurrentUserActivityRequest} message CurrentUserActivityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CurrentUserActivityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CurrentUserActivityRequest message, length delimited. Does not implicitly {@link infinitusai.be.CurrentUserActivityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {infinitusai.be.ICurrentUserActivityRequest} message CurrentUserActivityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CurrentUserActivityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CurrentUserActivityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CurrentUserActivityRequest} CurrentUserActivityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CurrentUserActivityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CurrentUserActivityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CurrentUserActivityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CurrentUserActivityRequest} CurrentUserActivityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CurrentUserActivityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CurrentUserActivityRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CurrentUserActivityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CurrentUserActivityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CurrentUserActivityRequest} CurrentUserActivityRequest
                 */
                CurrentUserActivityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CurrentUserActivityRequest)
                        return object;
                    return new $root.infinitusai.be.CurrentUserActivityRequest();
                };
    
                /**
                 * Creates a plain object from a CurrentUserActivityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {infinitusai.be.CurrentUserActivityRequest} message CurrentUserActivityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CurrentUserActivityRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CurrentUserActivityRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CurrentUserActivityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CurrentUserActivityRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CurrentUserActivityRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CurrentUserActivityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CurrentUserActivityRequest";
                };
    
                return CurrentUserActivityRequest;
            })();
    
            be.CurrentUserActivityResponse = (function() {
    
                /**
                 * Properties of a CurrentUserActivityResponse.
                 * @memberof infinitusai.be
                 * @interface ICurrentUserActivityResponse
                 * @property {number|Long|null} [lastKnownActivityTimestampMillis] CurrentUserActivityResponse lastKnownActivityTimestampMillis
                 * @property {boolean|null} [inActiveCall] CurrentUserActivityResponse inActiveCall
                 */
    
                /**
                 * Constructs a new CurrentUserActivityResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CurrentUserActivityResponse.
                 * @implements ICurrentUserActivityResponse
                 * @constructor
                 * @param {infinitusai.be.ICurrentUserActivityResponse=} [properties] Properties to set
                 */
                function CurrentUserActivityResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CurrentUserActivityResponse lastKnownActivityTimestampMillis.
                 * @member {number|Long} lastKnownActivityTimestampMillis
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @instance
                 */
                CurrentUserActivityResponse.prototype.lastKnownActivityTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * CurrentUserActivityResponse inActiveCall.
                 * @member {boolean} inActiveCall
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @instance
                 */
                CurrentUserActivityResponse.prototype.inActiveCall = false;
    
                /**
                 * Creates a new CurrentUserActivityResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {infinitusai.be.ICurrentUserActivityResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CurrentUserActivityResponse} CurrentUserActivityResponse instance
                 */
                CurrentUserActivityResponse.create = function create(properties) {
                    return new CurrentUserActivityResponse(properties);
                };
    
                /**
                 * Encodes the specified CurrentUserActivityResponse message. Does not implicitly {@link infinitusai.be.CurrentUserActivityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {infinitusai.be.ICurrentUserActivityResponse} message CurrentUserActivityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CurrentUserActivityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.lastKnownActivityTimestampMillis != null && Object.hasOwnProperty.call(message, "lastKnownActivityTimestampMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.lastKnownActivityTimestampMillis);
                    if (message.inActiveCall != null && Object.hasOwnProperty.call(message, "inActiveCall"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.inActiveCall);
                    return writer;
                };
    
                /**
                 * Encodes the specified CurrentUserActivityResponse message, length delimited. Does not implicitly {@link infinitusai.be.CurrentUserActivityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {infinitusai.be.ICurrentUserActivityResponse} message CurrentUserActivityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CurrentUserActivityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CurrentUserActivityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CurrentUserActivityResponse} CurrentUserActivityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CurrentUserActivityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CurrentUserActivityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.lastKnownActivityTimestampMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                message.inActiveCall = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CurrentUserActivityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CurrentUserActivityResponse} CurrentUserActivityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CurrentUserActivityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CurrentUserActivityResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CurrentUserActivityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.lastKnownActivityTimestampMillis != null && message.hasOwnProperty("lastKnownActivityTimestampMillis"))
                        if (!$util.isInteger(message.lastKnownActivityTimestampMillis) && !(message.lastKnownActivityTimestampMillis && $util.isInteger(message.lastKnownActivityTimestampMillis.low) && $util.isInteger(message.lastKnownActivityTimestampMillis.high)))
                            return "lastKnownActivityTimestampMillis: integer|Long expected";
                    if (message.inActiveCall != null && message.hasOwnProperty("inActiveCall"))
                        if (typeof message.inActiveCall !== "boolean")
                            return "inActiveCall: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a CurrentUserActivityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CurrentUserActivityResponse} CurrentUserActivityResponse
                 */
                CurrentUserActivityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CurrentUserActivityResponse)
                        return object;
                    var message = new $root.infinitusai.be.CurrentUserActivityResponse();
                    if (object.lastKnownActivityTimestampMillis != null)
                        if ($util.Long)
                            (message.lastKnownActivityTimestampMillis = $util.Long.fromValue(object.lastKnownActivityTimestampMillis)).unsigned = false;
                        else if (typeof object.lastKnownActivityTimestampMillis === "string")
                            message.lastKnownActivityTimestampMillis = parseInt(object.lastKnownActivityTimestampMillis, 10);
                        else if (typeof object.lastKnownActivityTimestampMillis === "number")
                            message.lastKnownActivityTimestampMillis = object.lastKnownActivityTimestampMillis;
                        else if (typeof object.lastKnownActivityTimestampMillis === "object")
                            message.lastKnownActivityTimestampMillis = new $util.LongBits(object.lastKnownActivityTimestampMillis.low >>> 0, object.lastKnownActivityTimestampMillis.high >>> 0).toNumber();
                    if (object.inActiveCall != null)
                        message.inActiveCall = Boolean(object.inActiveCall);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CurrentUserActivityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {infinitusai.be.CurrentUserActivityResponse} message CurrentUserActivityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CurrentUserActivityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastKnownActivityTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastKnownActivityTimestampMillis = options.longs === String ? "0" : 0;
                        object.inActiveCall = false;
                    }
                    if (message.lastKnownActivityTimestampMillis != null && message.hasOwnProperty("lastKnownActivityTimestampMillis"))
                        if (typeof message.lastKnownActivityTimestampMillis === "number")
                            object.lastKnownActivityTimestampMillis = options.longs === String ? String(message.lastKnownActivityTimestampMillis) : message.lastKnownActivityTimestampMillis;
                        else
                            object.lastKnownActivityTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastKnownActivityTimestampMillis) : options.longs === Number ? new $util.LongBits(message.lastKnownActivityTimestampMillis.low >>> 0, message.lastKnownActivityTimestampMillis.high >>> 0).toNumber() : message.lastKnownActivityTimestampMillis;
                    if (message.inActiveCall != null && message.hasOwnProperty("inActiveCall"))
                        object.inActiveCall = message.inActiveCall;
                    return object;
                };
    
                /**
                 * Converts this CurrentUserActivityResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CurrentUserActivityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CurrentUserActivityResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CurrentUserActivityResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CurrentUserActivityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CurrentUserActivityResponse";
                };
    
                return CurrentUserActivityResponse;
            })();
    
            be.GetOperatorWorkingStatesRequest = (function() {
    
                /**
                 * Properties of a GetOperatorWorkingStatesRequest.
                 * @memberof infinitusai.be
                 * @interface IGetOperatorWorkingStatesRequest
                 * @property {Array.<string>|null} [userEmails] GetOperatorWorkingStatesRequest userEmails
                 */
    
                /**
                 * Constructs a new GetOperatorWorkingStatesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOperatorWorkingStatesRequest.
                 * @implements IGetOperatorWorkingStatesRequest
                 * @constructor
                 * @param {infinitusai.be.IGetOperatorWorkingStatesRequest=} [properties] Properties to set
                 */
                function GetOperatorWorkingStatesRequest(properties) {
                    this.userEmails = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOperatorWorkingStatesRequest userEmails.
                 * @member {Array.<string>} userEmails
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @instance
                 */
                GetOperatorWorkingStatesRequest.prototype.userEmails = $util.emptyArray;
    
                /**
                 * Creates a new GetOperatorWorkingStatesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorWorkingStatesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOperatorWorkingStatesRequest} GetOperatorWorkingStatesRequest instance
                 */
                GetOperatorWorkingStatesRequest.create = function create(properties) {
                    return new GetOperatorWorkingStatesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOperatorWorkingStatesRequest message. Does not implicitly {@link infinitusai.be.GetOperatorWorkingStatesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorWorkingStatesRequest} message GetOperatorWorkingStatesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorWorkingStatesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userEmails != null && message.userEmails.length)
                        for (var i = 0; i < message.userEmails.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userEmails[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOperatorWorkingStatesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetOperatorWorkingStatesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {infinitusai.be.IGetOperatorWorkingStatesRequest} message GetOperatorWorkingStatesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorWorkingStatesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOperatorWorkingStatesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOperatorWorkingStatesRequest} GetOperatorWorkingStatesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorWorkingStatesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOperatorWorkingStatesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.userEmails && message.userEmails.length))
                                    message.userEmails = [];
                                message.userEmails.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOperatorWorkingStatesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOperatorWorkingStatesRequest} GetOperatorWorkingStatesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorWorkingStatesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOperatorWorkingStatesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOperatorWorkingStatesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userEmails != null && message.hasOwnProperty("userEmails")) {
                        if (!Array.isArray(message.userEmails))
                            return "userEmails: array expected";
                        for (var i = 0; i < message.userEmails.length; ++i)
                            if (!$util.isString(message.userEmails[i]))
                                return "userEmails: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOperatorWorkingStatesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOperatorWorkingStatesRequest} GetOperatorWorkingStatesRequest
                 */
                GetOperatorWorkingStatesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOperatorWorkingStatesRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetOperatorWorkingStatesRequest();
                    if (object.userEmails) {
                        if (!Array.isArray(object.userEmails))
                            throw TypeError(".infinitusai.be.GetOperatorWorkingStatesRequest.userEmails: array expected");
                        message.userEmails = [];
                        for (var i = 0; i < object.userEmails.length; ++i)
                            message.userEmails[i] = String(object.userEmails[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOperatorWorkingStatesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {infinitusai.be.GetOperatorWorkingStatesRequest} message GetOperatorWorkingStatesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOperatorWorkingStatesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.userEmails = [];
                    if (message.userEmails && message.userEmails.length) {
                        object.userEmails = [];
                        for (var j = 0; j < message.userEmails.length; ++j)
                            object.userEmails[j] = message.userEmails[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOperatorWorkingStatesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOperatorWorkingStatesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOperatorWorkingStatesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOperatorWorkingStatesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOperatorWorkingStatesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOperatorWorkingStatesRequest";
                };
    
                return GetOperatorWorkingStatesRequest;
            })();
    
            be.GetOperatorWorkingStatesResponse = (function() {
    
                /**
                 * Properties of a GetOperatorWorkingStatesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetOperatorWorkingStatesResponse
                 * @property {number|Long|null} [lastKnownActivityTimestampMillis] GetOperatorWorkingStatesResponse lastKnownActivityTimestampMillis
                 * @property {Array.<infinitusai.be.IOperatorWorkingState>|null} [operatorWorkingStates] GetOperatorWorkingStatesResponse operatorWorkingStates
                 */
    
                /**
                 * Constructs a new GetOperatorWorkingStatesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOperatorWorkingStatesResponse.
                 * @implements IGetOperatorWorkingStatesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetOperatorWorkingStatesResponse=} [properties] Properties to set
                 */
                function GetOperatorWorkingStatesResponse(properties) {
                    this.operatorWorkingStates = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOperatorWorkingStatesResponse lastKnownActivityTimestampMillis.
                 * @member {number|Long} lastKnownActivityTimestampMillis
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @instance
                 */
                GetOperatorWorkingStatesResponse.prototype.lastKnownActivityTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GetOperatorWorkingStatesResponse operatorWorkingStates.
                 * @member {Array.<infinitusai.be.IOperatorWorkingState>} operatorWorkingStates
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @instance
                 */
                GetOperatorWorkingStatesResponse.prototype.operatorWorkingStates = $util.emptyArray;
    
                /**
                 * Creates a new GetOperatorWorkingStatesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorWorkingStatesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOperatorWorkingStatesResponse} GetOperatorWorkingStatesResponse instance
                 */
                GetOperatorWorkingStatesResponse.create = function create(properties) {
                    return new GetOperatorWorkingStatesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOperatorWorkingStatesResponse message. Does not implicitly {@link infinitusai.be.GetOperatorWorkingStatesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorWorkingStatesResponse} message GetOperatorWorkingStatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorWorkingStatesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.lastKnownActivityTimestampMillis != null && Object.hasOwnProperty.call(message, "lastKnownActivityTimestampMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.lastKnownActivityTimestampMillis);
                    if (message.operatorWorkingStates != null && message.operatorWorkingStates.length)
                        for (var i = 0; i < message.operatorWorkingStates.length; ++i)
                            $root.infinitusai.be.OperatorWorkingState.encode(message.operatorWorkingStates[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOperatorWorkingStatesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetOperatorWorkingStatesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {infinitusai.be.IGetOperatorWorkingStatesResponse} message GetOperatorWorkingStatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOperatorWorkingStatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOperatorWorkingStatesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOperatorWorkingStatesResponse} GetOperatorWorkingStatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorWorkingStatesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOperatorWorkingStatesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.lastKnownActivityTimestampMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                if (!(message.operatorWorkingStates && message.operatorWorkingStates.length))
                                    message.operatorWorkingStates = [];
                                message.operatorWorkingStates.push($root.infinitusai.be.OperatorWorkingState.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOperatorWorkingStatesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOperatorWorkingStatesResponse} GetOperatorWorkingStatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOperatorWorkingStatesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOperatorWorkingStatesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOperatorWorkingStatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.lastKnownActivityTimestampMillis != null && message.hasOwnProperty("lastKnownActivityTimestampMillis"))
                        if (!$util.isInteger(message.lastKnownActivityTimestampMillis) && !(message.lastKnownActivityTimestampMillis && $util.isInteger(message.lastKnownActivityTimestampMillis.low) && $util.isInteger(message.lastKnownActivityTimestampMillis.high)))
                            return "lastKnownActivityTimestampMillis: integer|Long expected";
                    if (message.operatorWorkingStates != null && message.hasOwnProperty("operatorWorkingStates")) {
                        if (!Array.isArray(message.operatorWorkingStates))
                            return "operatorWorkingStates: array expected";
                        for (var i = 0; i < message.operatorWorkingStates.length; ++i) {
                            var error = $root.infinitusai.be.OperatorWorkingState.verify(message.operatorWorkingStates[i]);
                            if (error)
                                return "operatorWorkingStates." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOperatorWorkingStatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOperatorWorkingStatesResponse} GetOperatorWorkingStatesResponse
                 */
                GetOperatorWorkingStatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOperatorWorkingStatesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetOperatorWorkingStatesResponse();
                    if (object.lastKnownActivityTimestampMillis != null)
                        if ($util.Long)
                            (message.lastKnownActivityTimestampMillis = $util.Long.fromValue(object.lastKnownActivityTimestampMillis)).unsigned = false;
                        else if (typeof object.lastKnownActivityTimestampMillis === "string")
                            message.lastKnownActivityTimestampMillis = parseInt(object.lastKnownActivityTimestampMillis, 10);
                        else if (typeof object.lastKnownActivityTimestampMillis === "number")
                            message.lastKnownActivityTimestampMillis = object.lastKnownActivityTimestampMillis;
                        else if (typeof object.lastKnownActivityTimestampMillis === "object")
                            message.lastKnownActivityTimestampMillis = new $util.LongBits(object.lastKnownActivityTimestampMillis.low >>> 0, object.lastKnownActivityTimestampMillis.high >>> 0).toNumber();
                    if (object.operatorWorkingStates) {
                        if (!Array.isArray(object.operatorWorkingStates))
                            throw TypeError(".infinitusai.be.GetOperatorWorkingStatesResponse.operatorWorkingStates: array expected");
                        message.operatorWorkingStates = [];
                        for (var i = 0; i < object.operatorWorkingStates.length; ++i) {
                            if (typeof object.operatorWorkingStates[i] !== "object")
                                throw TypeError(".infinitusai.be.GetOperatorWorkingStatesResponse.operatorWorkingStates: object expected");
                            message.operatorWorkingStates[i] = $root.infinitusai.be.OperatorWorkingState.fromObject(object.operatorWorkingStates[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOperatorWorkingStatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {infinitusai.be.GetOperatorWorkingStatesResponse} message GetOperatorWorkingStatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOperatorWorkingStatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorWorkingStates = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastKnownActivityTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastKnownActivityTimestampMillis = options.longs === String ? "0" : 0;
                    if (message.lastKnownActivityTimestampMillis != null && message.hasOwnProperty("lastKnownActivityTimestampMillis"))
                        if (typeof message.lastKnownActivityTimestampMillis === "number")
                            object.lastKnownActivityTimestampMillis = options.longs === String ? String(message.lastKnownActivityTimestampMillis) : message.lastKnownActivityTimestampMillis;
                        else
                            object.lastKnownActivityTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastKnownActivityTimestampMillis) : options.longs === Number ? new $util.LongBits(message.lastKnownActivityTimestampMillis.low >>> 0, message.lastKnownActivityTimestampMillis.high >>> 0).toNumber() : message.lastKnownActivityTimestampMillis;
                    if (message.operatorWorkingStates && message.operatorWorkingStates.length) {
                        object.operatorWorkingStates = [];
                        for (var j = 0; j < message.operatorWorkingStates.length; ++j)
                            object.operatorWorkingStates[j] = $root.infinitusai.be.OperatorWorkingState.toObject(message.operatorWorkingStates[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOperatorWorkingStatesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOperatorWorkingStatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOperatorWorkingStatesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOperatorWorkingStatesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOperatorWorkingStatesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOperatorWorkingStatesResponse";
                };
    
                return GetOperatorWorkingStatesResponse;
            })();
    
            be.OperatorActivityUpdate = (function() {
    
                /**
                 * Properties of an OperatorActivityUpdate.
                 * @memberof infinitusai.be
                 * @interface IOperatorActivityUpdate
                 * @property {infinitusai.be.IHeartbeatMessageFromClient|null} [heartbeat] OperatorActivityUpdate heartbeat
                 */
    
                /**
                 * Constructs a new OperatorActivityUpdate.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorActivityUpdate.
                 * @implements IOperatorActivityUpdate
                 * @constructor
                 * @param {infinitusai.be.IOperatorActivityUpdate=} [properties] Properties to set
                 */
                function OperatorActivityUpdate(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorActivityUpdate heartbeat.
                 * @member {infinitusai.be.IHeartbeatMessageFromClient|null|undefined} heartbeat
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @instance
                 */
                OperatorActivityUpdate.prototype.heartbeat = null;
    
                /**
                 * Creates a new OperatorActivityUpdate instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {infinitusai.be.IOperatorActivityUpdate=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorActivityUpdate} OperatorActivityUpdate instance
                 */
                OperatorActivityUpdate.create = function create(properties) {
                    return new OperatorActivityUpdate(properties);
                };
    
                /**
                 * Encodes the specified OperatorActivityUpdate message. Does not implicitly {@link infinitusai.be.OperatorActivityUpdate.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {infinitusai.be.IOperatorActivityUpdate} message OperatorActivityUpdate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorActivityUpdate.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.heartbeat != null && Object.hasOwnProperty.call(message, "heartbeat"))
                        $root.infinitusai.be.HeartbeatMessageFromClient.encode(message.heartbeat, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorActivityUpdate message, length delimited. Does not implicitly {@link infinitusai.be.OperatorActivityUpdate.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {infinitusai.be.IOperatorActivityUpdate} message OperatorActivityUpdate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorActivityUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorActivityUpdate message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorActivityUpdate} OperatorActivityUpdate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorActivityUpdate.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorActivityUpdate();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.heartbeat = $root.infinitusai.be.HeartbeatMessageFromClient.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorActivityUpdate message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorActivityUpdate} OperatorActivityUpdate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorActivityUpdate.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorActivityUpdate message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorActivityUpdate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                        var error = $root.infinitusai.be.HeartbeatMessageFromClient.verify(message.heartbeat);
                        if (error)
                            return "heartbeat." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an OperatorActivityUpdate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorActivityUpdate} OperatorActivityUpdate
                 */
                OperatorActivityUpdate.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorActivityUpdate)
                        return object;
                    var message = new $root.infinitusai.be.OperatorActivityUpdate();
                    if (object.heartbeat != null) {
                        if (typeof object.heartbeat !== "object")
                            throw TypeError(".infinitusai.be.OperatorActivityUpdate.heartbeat: object expected");
                        message.heartbeat = $root.infinitusai.be.HeartbeatMessageFromClient.fromObject(object.heartbeat);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorActivityUpdate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {infinitusai.be.OperatorActivityUpdate} message OperatorActivityUpdate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorActivityUpdate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.heartbeat = null;
                    if (message.heartbeat != null && message.hasOwnProperty("heartbeat"))
                        object.heartbeat = $root.infinitusai.be.HeartbeatMessageFromClient.toObject(message.heartbeat, options);
                    return object;
                };
    
                /**
                 * Converts this OperatorActivityUpdate to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorActivityUpdate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorActivityUpdate
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorActivityUpdate
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorActivityUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorActivityUpdate";
                };
    
                return OperatorActivityUpdate;
            })();
    
            be.RedisOperatorJoinCallMessage = (function() {
    
                /**
                 * Properties of a RedisOperatorJoinCallMessage.
                 * @memberof infinitusai.be
                 * @interface IRedisOperatorJoinCallMessage
                 * @property {string|null} [orgUuid] RedisOperatorJoinCallMessage orgUuid
                 * @property {string|null} [taskUuid] RedisOperatorJoinCallMessage taskUuid
                 * @property {string|null} [callUuid] RedisOperatorJoinCallMessage callUuid
                 * @property {string|null} [operatorEmail] RedisOperatorJoinCallMessage operatorEmail
                 * @property {string|null} [nexmoConvUuid] RedisOperatorJoinCallMessage nexmoConvUuid
                 * @property {boolean|null} [useHumanVoice] RedisOperatorJoinCallMessage useHumanVoice
                 * @property {infinitusai.be.OperatorPortalEnvironment|null} [targetEnvironment] RedisOperatorJoinCallMessage targetEnvironment
                 */
    
                /**
                 * Constructs a new RedisOperatorJoinCallMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RedisOperatorJoinCallMessage.
                 * @implements IRedisOperatorJoinCallMessage
                 * @constructor
                 * @param {infinitusai.be.IRedisOperatorJoinCallMessage=} [properties] Properties to set
                 */
                function RedisOperatorJoinCallMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RedisOperatorJoinCallMessage orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @instance
                 */
                RedisOperatorJoinCallMessage.prototype.orgUuid = "";
    
                /**
                 * RedisOperatorJoinCallMessage taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @instance
                 */
                RedisOperatorJoinCallMessage.prototype.taskUuid = "";
    
                /**
                 * RedisOperatorJoinCallMessage callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @instance
                 */
                RedisOperatorJoinCallMessage.prototype.callUuid = "";
    
                /**
                 * RedisOperatorJoinCallMessage operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @instance
                 */
                RedisOperatorJoinCallMessage.prototype.operatorEmail = "";
    
                /**
                 * RedisOperatorJoinCallMessage nexmoConvUuid.
                 * @member {string} nexmoConvUuid
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @instance
                 */
                RedisOperatorJoinCallMessage.prototype.nexmoConvUuid = "";
    
                /**
                 * RedisOperatorJoinCallMessage useHumanVoice.
                 * @member {boolean} useHumanVoice
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @instance
                 */
                RedisOperatorJoinCallMessage.prototype.useHumanVoice = false;
    
                /**
                 * RedisOperatorJoinCallMessage targetEnvironment.
                 * @member {infinitusai.be.OperatorPortalEnvironment} targetEnvironment
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @instance
                 */
                RedisOperatorJoinCallMessage.prototype.targetEnvironment = 0;
    
                /**
                 * Creates a new RedisOperatorJoinCallMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {infinitusai.be.IRedisOperatorJoinCallMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.RedisOperatorJoinCallMessage} RedisOperatorJoinCallMessage instance
                 */
                RedisOperatorJoinCallMessage.create = function create(properties) {
                    return new RedisOperatorJoinCallMessage(properties);
                };
    
                /**
                 * Encodes the specified RedisOperatorJoinCallMessage message. Does not implicitly {@link infinitusai.be.RedisOperatorJoinCallMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {infinitusai.be.IRedisOperatorJoinCallMessage} message RedisOperatorJoinCallMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedisOperatorJoinCallMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.operatorEmail);
                    if (message.nexmoConvUuid != null && Object.hasOwnProperty.call(message, "nexmoConvUuid"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.nexmoConvUuid);
                    if (message.useHumanVoice != null && Object.hasOwnProperty.call(message, "useHumanVoice"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.useHumanVoice);
                    if (message.targetEnvironment != null && Object.hasOwnProperty.call(message, "targetEnvironment"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.targetEnvironment);
                    return writer;
                };
    
                /**
                 * Encodes the specified RedisOperatorJoinCallMessage message, length delimited. Does not implicitly {@link infinitusai.be.RedisOperatorJoinCallMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {infinitusai.be.IRedisOperatorJoinCallMessage} message RedisOperatorJoinCallMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedisOperatorJoinCallMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RedisOperatorJoinCallMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RedisOperatorJoinCallMessage} RedisOperatorJoinCallMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedisOperatorJoinCallMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RedisOperatorJoinCallMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        case 5: {
                                message.nexmoConvUuid = reader.string();
                                break;
                            }
                        case 6: {
                                message.useHumanVoice = reader.bool();
                                break;
                            }
                        case 7: {
                                message.targetEnvironment = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RedisOperatorJoinCallMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RedisOperatorJoinCallMessage} RedisOperatorJoinCallMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedisOperatorJoinCallMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RedisOperatorJoinCallMessage message.
                 * @function verify
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedisOperatorJoinCallMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    if (message.nexmoConvUuid != null && message.hasOwnProperty("nexmoConvUuid"))
                        if (!$util.isString(message.nexmoConvUuid))
                            return "nexmoConvUuid: string expected";
                    if (message.useHumanVoice != null && message.hasOwnProperty("useHumanVoice"))
                        if (typeof message.useHumanVoice !== "boolean")
                            return "useHumanVoice: boolean expected";
                    if (message.targetEnvironment != null && message.hasOwnProperty("targetEnvironment"))
                        switch (message.targetEnvironment) {
                        default:
                            return "targetEnvironment: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a RedisOperatorJoinCallMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RedisOperatorJoinCallMessage} RedisOperatorJoinCallMessage
                 */
                RedisOperatorJoinCallMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RedisOperatorJoinCallMessage)
                        return object;
                    var message = new $root.infinitusai.be.RedisOperatorJoinCallMessage();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    if (object.nexmoConvUuid != null)
                        message.nexmoConvUuid = String(object.nexmoConvUuid);
                    if (object.useHumanVoice != null)
                        message.useHumanVoice = Boolean(object.useHumanVoice);
                    switch (object.targetEnvironment) {
                    default:
                        if (typeof object.targetEnvironment === "number") {
                            message.targetEnvironment = object.targetEnvironment;
                            break;
                        }
                        break;
                    case "ANY":
                    case 0:
                        message.targetEnvironment = 0;
                        break;
                    case "STAGING":
                    case 1:
                        message.targetEnvironment = 1;
                        break;
                    case "PRODUCTION":
                    case 2:
                        message.targetEnvironment = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RedisOperatorJoinCallMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {infinitusai.be.RedisOperatorJoinCallMessage} message RedisOperatorJoinCallMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedisOperatorJoinCallMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.operatorEmail = "";
                        object.nexmoConvUuid = "";
                        object.useHumanVoice = false;
                        object.targetEnvironment = options.enums === String ? "ANY" : 0;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    if (message.nexmoConvUuid != null && message.hasOwnProperty("nexmoConvUuid"))
                        object.nexmoConvUuid = message.nexmoConvUuid;
                    if (message.useHumanVoice != null && message.hasOwnProperty("useHumanVoice"))
                        object.useHumanVoice = message.useHumanVoice;
                    if (message.targetEnvironment != null && message.hasOwnProperty("targetEnvironment"))
                        object.targetEnvironment = options.enums === String ? $root.infinitusai.be.OperatorPortalEnvironment[message.targetEnvironment] === undefined ? message.targetEnvironment : $root.infinitusai.be.OperatorPortalEnvironment[message.targetEnvironment] : message.targetEnvironment;
                    return object;
                };
    
                /**
                 * Converts this RedisOperatorJoinCallMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedisOperatorJoinCallMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RedisOperatorJoinCallMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RedisOperatorJoinCallMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedisOperatorJoinCallMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RedisOperatorJoinCallMessage";
                };
    
                return RedisOperatorJoinCallMessage;
            })();
    
            be.RedisOperatorLeaveCallMessage = (function() {
    
                /**
                 * Properties of a RedisOperatorLeaveCallMessage.
                 * @memberof infinitusai.be
                 * @interface IRedisOperatorLeaveCallMessage
                 * @property {string|null} [orgUuid] RedisOperatorLeaveCallMessage orgUuid
                 * @property {string|null} [taskUuid] RedisOperatorLeaveCallMessage taskUuid
                 * @property {string|null} [callUuid] RedisOperatorLeaveCallMessage callUuid
                 * @property {string|null} [operatorEmail] RedisOperatorLeaveCallMessage operatorEmail
                 * @property {infinitusai.be.OperatorPortalEnvironment|null} [targetEnvironment] RedisOperatorLeaveCallMessage targetEnvironment
                 */
    
                /**
                 * Constructs a new RedisOperatorLeaveCallMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RedisOperatorLeaveCallMessage.
                 * @implements IRedisOperatorLeaveCallMessage
                 * @constructor
                 * @param {infinitusai.be.IRedisOperatorLeaveCallMessage=} [properties] Properties to set
                 */
                function RedisOperatorLeaveCallMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RedisOperatorLeaveCallMessage orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @instance
                 */
                RedisOperatorLeaveCallMessage.prototype.orgUuid = "";
    
                /**
                 * RedisOperatorLeaveCallMessage taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @instance
                 */
                RedisOperatorLeaveCallMessage.prototype.taskUuid = "";
    
                /**
                 * RedisOperatorLeaveCallMessage callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @instance
                 */
                RedisOperatorLeaveCallMessage.prototype.callUuid = "";
    
                /**
                 * RedisOperatorLeaveCallMessage operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @instance
                 */
                RedisOperatorLeaveCallMessage.prototype.operatorEmail = "";
    
                /**
                 * RedisOperatorLeaveCallMessage targetEnvironment.
                 * @member {infinitusai.be.OperatorPortalEnvironment} targetEnvironment
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @instance
                 */
                RedisOperatorLeaveCallMessage.prototype.targetEnvironment = 0;
    
                /**
                 * Creates a new RedisOperatorLeaveCallMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {infinitusai.be.IRedisOperatorLeaveCallMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.RedisOperatorLeaveCallMessage} RedisOperatorLeaveCallMessage instance
                 */
                RedisOperatorLeaveCallMessage.create = function create(properties) {
                    return new RedisOperatorLeaveCallMessage(properties);
                };
    
                /**
                 * Encodes the specified RedisOperatorLeaveCallMessage message. Does not implicitly {@link infinitusai.be.RedisOperatorLeaveCallMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {infinitusai.be.IRedisOperatorLeaveCallMessage} message RedisOperatorLeaveCallMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedisOperatorLeaveCallMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.operatorEmail);
                    if (message.targetEnvironment != null && Object.hasOwnProperty.call(message, "targetEnvironment"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.targetEnvironment);
                    return writer;
                };
    
                /**
                 * Encodes the specified RedisOperatorLeaveCallMessage message, length delimited. Does not implicitly {@link infinitusai.be.RedisOperatorLeaveCallMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {infinitusai.be.IRedisOperatorLeaveCallMessage} message RedisOperatorLeaveCallMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedisOperatorLeaveCallMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RedisOperatorLeaveCallMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RedisOperatorLeaveCallMessage} RedisOperatorLeaveCallMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedisOperatorLeaveCallMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RedisOperatorLeaveCallMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        case 5: {
                                message.targetEnvironment = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RedisOperatorLeaveCallMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RedisOperatorLeaveCallMessage} RedisOperatorLeaveCallMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedisOperatorLeaveCallMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RedisOperatorLeaveCallMessage message.
                 * @function verify
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedisOperatorLeaveCallMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    if (message.targetEnvironment != null && message.hasOwnProperty("targetEnvironment"))
                        switch (message.targetEnvironment) {
                        default:
                            return "targetEnvironment: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a RedisOperatorLeaveCallMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RedisOperatorLeaveCallMessage} RedisOperatorLeaveCallMessage
                 */
                RedisOperatorLeaveCallMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RedisOperatorLeaveCallMessage)
                        return object;
                    var message = new $root.infinitusai.be.RedisOperatorLeaveCallMessage();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    switch (object.targetEnvironment) {
                    default:
                        if (typeof object.targetEnvironment === "number") {
                            message.targetEnvironment = object.targetEnvironment;
                            break;
                        }
                        break;
                    case "ANY":
                    case 0:
                        message.targetEnvironment = 0;
                        break;
                    case "STAGING":
                    case 1:
                        message.targetEnvironment = 1;
                        break;
                    case "PRODUCTION":
                    case 2:
                        message.targetEnvironment = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RedisOperatorLeaveCallMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {infinitusai.be.RedisOperatorLeaveCallMessage} message RedisOperatorLeaveCallMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedisOperatorLeaveCallMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.operatorEmail = "";
                        object.targetEnvironment = options.enums === String ? "ANY" : 0;
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    if (message.targetEnvironment != null && message.hasOwnProperty("targetEnvironment"))
                        object.targetEnvironment = options.enums === String ? $root.infinitusai.be.OperatorPortalEnvironment[message.targetEnvironment] === undefined ? message.targetEnvironment : $root.infinitusai.be.OperatorPortalEnvironment[message.targetEnvironment] : message.targetEnvironment;
                    return object;
                };
    
                /**
                 * Converts this RedisOperatorLeaveCallMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedisOperatorLeaveCallMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RedisOperatorLeaveCallMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RedisOperatorLeaveCallMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedisOperatorLeaveCallMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RedisOperatorLeaveCallMessage";
                };
    
                return RedisOperatorLeaveCallMessage;
            })();
    
            be.RedisSuggestionsRefreshRequest = (function() {
    
                /**
                 * Properties of a RedisSuggestionsRefreshRequest.
                 * @memberof infinitusai.be
                 * @interface IRedisSuggestionsRefreshRequest
                 * @property {string|null} [orgUuid] RedisSuggestionsRefreshRequest orgUuid
                 * @property {string|null} [taskUuid] RedisSuggestionsRefreshRequest taskUuid
                 * @property {string|null} [callUuid] RedisSuggestionsRefreshRequest callUuid
                 * @property {string|null} [operatorEmail] RedisSuggestionsRefreshRequest operatorEmail
                 */
    
                /**
                 * Constructs a new RedisSuggestionsRefreshRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RedisSuggestionsRefreshRequest.
                 * @implements IRedisSuggestionsRefreshRequest
                 * @constructor
                 * @param {infinitusai.be.IRedisSuggestionsRefreshRequest=} [properties] Properties to set
                 */
                function RedisSuggestionsRefreshRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RedisSuggestionsRefreshRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @instance
                 */
                RedisSuggestionsRefreshRequest.prototype.orgUuid = "";
    
                /**
                 * RedisSuggestionsRefreshRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @instance
                 */
                RedisSuggestionsRefreshRequest.prototype.taskUuid = "";
    
                /**
                 * RedisSuggestionsRefreshRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @instance
                 */
                RedisSuggestionsRefreshRequest.prototype.callUuid = "";
    
                /**
                 * RedisSuggestionsRefreshRequest operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @instance
                 */
                RedisSuggestionsRefreshRequest.prototype.operatorEmail = "";
    
                /**
                 * Creates a new RedisSuggestionsRefreshRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {infinitusai.be.IRedisSuggestionsRefreshRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RedisSuggestionsRefreshRequest} RedisSuggestionsRefreshRequest instance
                 */
                RedisSuggestionsRefreshRequest.create = function create(properties) {
                    return new RedisSuggestionsRefreshRequest(properties);
                };
    
                /**
                 * Encodes the specified RedisSuggestionsRefreshRequest message. Does not implicitly {@link infinitusai.be.RedisSuggestionsRefreshRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {infinitusai.be.IRedisSuggestionsRefreshRequest} message RedisSuggestionsRefreshRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedisSuggestionsRefreshRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.operatorEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified RedisSuggestionsRefreshRequest message, length delimited. Does not implicitly {@link infinitusai.be.RedisSuggestionsRefreshRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {infinitusai.be.IRedisSuggestionsRefreshRequest} message RedisSuggestionsRefreshRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedisSuggestionsRefreshRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RedisSuggestionsRefreshRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RedisSuggestionsRefreshRequest} RedisSuggestionsRefreshRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedisSuggestionsRefreshRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RedisSuggestionsRefreshRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RedisSuggestionsRefreshRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RedisSuggestionsRefreshRequest} RedisSuggestionsRefreshRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedisSuggestionsRefreshRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RedisSuggestionsRefreshRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedisSuggestionsRefreshRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a RedisSuggestionsRefreshRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RedisSuggestionsRefreshRequest} RedisSuggestionsRefreshRequest
                 */
                RedisSuggestionsRefreshRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RedisSuggestionsRefreshRequest)
                        return object;
                    var message = new $root.infinitusai.be.RedisSuggestionsRefreshRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RedisSuggestionsRefreshRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {infinitusai.be.RedisSuggestionsRefreshRequest} message RedisSuggestionsRefreshRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedisSuggestionsRefreshRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.operatorEmail = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    return object;
                };
    
                /**
                 * Converts this RedisSuggestionsRefreshRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedisSuggestionsRefreshRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RedisSuggestionsRefreshRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RedisSuggestionsRefreshRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedisSuggestionsRefreshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RedisSuggestionsRefreshRequest";
                };
    
                return RedisSuggestionsRefreshRequest;
            })();
    
            be.RedisPubSubServerMessage = (function() {
    
                /**
                 * Properties of a RedisPubSubServerMessage.
                 * @memberof infinitusai.be
                 * @interface IRedisPubSubServerMessage
                 * @property {infinitusai.be.IRedisOperatorJoinCallMessage|null} [joinCallMessage] RedisPubSubServerMessage joinCallMessage
                 * @property {infinitusai.be.IRedisSuggestionsRefreshRequest|null} [suggestionsRefreshRequest] RedisPubSubServerMessage suggestionsRefreshRequest
                 * @property {infinitusai.be.INotification|null} [notification] RedisPubSubServerMessage notification
                 * @property {infinitusai.be.IDismissNotification|null} [dismissNotification] RedisPubSubServerMessage dismissNotification
                 * @property {infinitusai.be.IPlayAudioMessage|null} [playAudio] RedisPubSubServerMessage playAudio
                 * @property {infinitusai.be.IRedisOperatorLeaveCallMessage|null} [leaveCallMessage] RedisPubSubServerMessage leaveCallMessage
                 * @property {number|Long|null} [insertTimeMillis] RedisPubSubServerMessage insertTimeMillis
                 */
    
                /**
                 * Constructs a new RedisPubSubServerMessage.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RedisPubSubServerMessage.
                 * @implements IRedisPubSubServerMessage
                 * @constructor
                 * @param {infinitusai.be.IRedisPubSubServerMessage=} [properties] Properties to set
                 */
                function RedisPubSubServerMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RedisPubSubServerMessage joinCallMessage.
                 * @member {infinitusai.be.IRedisOperatorJoinCallMessage|null|undefined} joinCallMessage
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 */
                RedisPubSubServerMessage.prototype.joinCallMessage = null;
    
                /**
                 * RedisPubSubServerMessage suggestionsRefreshRequest.
                 * @member {infinitusai.be.IRedisSuggestionsRefreshRequest|null|undefined} suggestionsRefreshRequest
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 */
                RedisPubSubServerMessage.prototype.suggestionsRefreshRequest = null;
    
                /**
                 * RedisPubSubServerMessage notification.
                 * @member {infinitusai.be.INotification|null|undefined} notification
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 */
                RedisPubSubServerMessage.prototype.notification = null;
    
                /**
                 * RedisPubSubServerMessage dismissNotification.
                 * @member {infinitusai.be.IDismissNotification|null|undefined} dismissNotification
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 */
                RedisPubSubServerMessage.prototype.dismissNotification = null;
    
                /**
                 * RedisPubSubServerMessage playAudio.
                 * @member {infinitusai.be.IPlayAudioMessage|null|undefined} playAudio
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 */
                RedisPubSubServerMessage.prototype.playAudio = null;
    
                /**
                 * RedisPubSubServerMessage leaveCallMessage.
                 * @member {infinitusai.be.IRedisOperatorLeaveCallMessage|null|undefined} leaveCallMessage
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 */
                RedisPubSubServerMessage.prototype.leaveCallMessage = null;
    
                /**
                 * RedisPubSubServerMessage insertTimeMillis.
                 * @member {number|Long} insertTimeMillis
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 */
                RedisPubSubServerMessage.prototype.insertTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * RedisPubSubServerMessage data.
                 * @member {"joinCallMessage"|"suggestionsRefreshRequest"|"notification"|"dismissNotification"|"playAudio"|"leaveCallMessage"|undefined} data
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 */
                Object.defineProperty(RedisPubSubServerMessage.prototype, "data", {
                    get: $util.oneOfGetter($oneOfFields = ["joinCallMessage", "suggestionsRefreshRequest", "notification", "dismissNotification", "playAudio", "leaveCallMessage"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new RedisPubSubServerMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {infinitusai.be.IRedisPubSubServerMessage=} [properties] Properties to set
                 * @returns {infinitusai.be.RedisPubSubServerMessage} RedisPubSubServerMessage instance
                 */
                RedisPubSubServerMessage.create = function create(properties) {
                    return new RedisPubSubServerMessage(properties);
                };
    
                /**
                 * Encodes the specified RedisPubSubServerMessage message. Does not implicitly {@link infinitusai.be.RedisPubSubServerMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {infinitusai.be.IRedisPubSubServerMessage} message RedisPubSubServerMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedisPubSubServerMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.joinCallMessage != null && Object.hasOwnProperty.call(message, "joinCallMessage"))
                        $root.infinitusai.be.RedisOperatorJoinCallMessage.encode(message.joinCallMessage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.insertTimeMillis != null && Object.hasOwnProperty.call(message, "insertTimeMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.insertTimeMillis);
                    if (message.suggestionsRefreshRequest != null && Object.hasOwnProperty.call(message, "suggestionsRefreshRequest"))
                        $root.infinitusai.be.RedisSuggestionsRefreshRequest.encode(message.suggestionsRefreshRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                        $root.infinitusai.be.Notification.encode(message.notification, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.dismissNotification != null && Object.hasOwnProperty.call(message, "dismissNotification"))
                        $root.infinitusai.be.DismissNotification.encode(message.dismissNotification, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.playAudio != null && Object.hasOwnProperty.call(message, "playAudio"))
                        $root.infinitusai.be.PlayAudioMessage.encode(message.playAudio, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.leaveCallMessage != null && Object.hasOwnProperty.call(message, "leaveCallMessage"))
                        $root.infinitusai.be.RedisOperatorLeaveCallMessage.encode(message.leaveCallMessage, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified RedisPubSubServerMessage message, length delimited. Does not implicitly {@link infinitusai.be.RedisPubSubServerMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {infinitusai.be.IRedisPubSubServerMessage} message RedisPubSubServerMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedisPubSubServerMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RedisPubSubServerMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RedisPubSubServerMessage} RedisPubSubServerMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedisPubSubServerMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RedisPubSubServerMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.joinCallMessage = $root.infinitusai.be.RedisOperatorJoinCallMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.suggestionsRefreshRequest = $root.infinitusai.be.RedisSuggestionsRefreshRequest.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.notification = $root.infinitusai.be.Notification.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.dismissNotification = $root.infinitusai.be.DismissNotification.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.playAudio = $root.infinitusai.be.PlayAudioMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.leaveCallMessage = $root.infinitusai.be.RedisOperatorLeaveCallMessage.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.insertTimeMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RedisPubSubServerMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RedisPubSubServerMessage} RedisPubSubServerMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedisPubSubServerMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RedisPubSubServerMessage message.
                 * @function verify
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedisPubSubServerMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.joinCallMessage != null && message.hasOwnProperty("joinCallMessage")) {
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.RedisOperatorJoinCallMessage.verify(message.joinCallMessage);
                            if (error)
                                return "joinCallMessage." + error;
                        }
                    }
                    if (message.suggestionsRefreshRequest != null && message.hasOwnProperty("suggestionsRefreshRequest")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.RedisSuggestionsRefreshRequest.verify(message.suggestionsRefreshRequest);
                            if (error)
                                return "suggestionsRefreshRequest." + error;
                        }
                    }
                    if (message.notification != null && message.hasOwnProperty("notification")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.Notification.verify(message.notification);
                            if (error)
                                return "notification." + error;
                        }
                    }
                    if (message.dismissNotification != null && message.hasOwnProperty("dismissNotification")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.DismissNotification.verify(message.dismissNotification);
                            if (error)
                                return "dismissNotification." + error;
                        }
                    }
                    if (message.playAudio != null && message.hasOwnProperty("playAudio")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.PlayAudioMessage.verify(message.playAudio);
                            if (error)
                                return "playAudio." + error;
                        }
                    }
                    if (message.leaveCallMessage != null && message.hasOwnProperty("leaveCallMessage")) {
                        if (properties.data === 1)
                            return "data: multiple values";
                        properties.data = 1;
                        {
                            var error = $root.infinitusai.be.RedisOperatorLeaveCallMessage.verify(message.leaveCallMessage);
                            if (error)
                                return "leaveCallMessage." + error;
                        }
                    }
                    if (message.insertTimeMillis != null && message.hasOwnProperty("insertTimeMillis"))
                        if (!$util.isInteger(message.insertTimeMillis) && !(message.insertTimeMillis && $util.isInteger(message.insertTimeMillis.low) && $util.isInteger(message.insertTimeMillis.high)))
                            return "insertTimeMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a RedisPubSubServerMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RedisPubSubServerMessage} RedisPubSubServerMessage
                 */
                RedisPubSubServerMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RedisPubSubServerMessage)
                        return object;
                    var message = new $root.infinitusai.be.RedisPubSubServerMessage();
                    if (object.joinCallMessage != null) {
                        if (typeof object.joinCallMessage !== "object")
                            throw TypeError(".infinitusai.be.RedisPubSubServerMessage.joinCallMessage: object expected");
                        message.joinCallMessage = $root.infinitusai.be.RedisOperatorJoinCallMessage.fromObject(object.joinCallMessage);
                    }
                    if (object.suggestionsRefreshRequest != null) {
                        if (typeof object.suggestionsRefreshRequest !== "object")
                            throw TypeError(".infinitusai.be.RedisPubSubServerMessage.suggestionsRefreshRequest: object expected");
                        message.suggestionsRefreshRequest = $root.infinitusai.be.RedisSuggestionsRefreshRequest.fromObject(object.suggestionsRefreshRequest);
                    }
                    if (object.notification != null) {
                        if (typeof object.notification !== "object")
                            throw TypeError(".infinitusai.be.RedisPubSubServerMessage.notification: object expected");
                        message.notification = $root.infinitusai.be.Notification.fromObject(object.notification);
                    }
                    if (object.dismissNotification != null) {
                        if (typeof object.dismissNotification !== "object")
                            throw TypeError(".infinitusai.be.RedisPubSubServerMessage.dismissNotification: object expected");
                        message.dismissNotification = $root.infinitusai.be.DismissNotification.fromObject(object.dismissNotification);
                    }
                    if (object.playAudio != null) {
                        if (typeof object.playAudio !== "object")
                            throw TypeError(".infinitusai.be.RedisPubSubServerMessage.playAudio: object expected");
                        message.playAudio = $root.infinitusai.be.PlayAudioMessage.fromObject(object.playAudio);
                    }
                    if (object.leaveCallMessage != null) {
                        if (typeof object.leaveCallMessage !== "object")
                            throw TypeError(".infinitusai.be.RedisPubSubServerMessage.leaveCallMessage: object expected");
                        message.leaveCallMessage = $root.infinitusai.be.RedisOperatorLeaveCallMessage.fromObject(object.leaveCallMessage);
                    }
                    if (object.insertTimeMillis != null)
                        if ($util.Long)
                            (message.insertTimeMillis = $util.Long.fromValue(object.insertTimeMillis)).unsigned = false;
                        else if (typeof object.insertTimeMillis === "string")
                            message.insertTimeMillis = parseInt(object.insertTimeMillis, 10);
                        else if (typeof object.insertTimeMillis === "number")
                            message.insertTimeMillis = object.insertTimeMillis;
                        else if (typeof object.insertTimeMillis === "object")
                            message.insertTimeMillis = new $util.LongBits(object.insertTimeMillis.low >>> 0, object.insertTimeMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a RedisPubSubServerMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {infinitusai.be.RedisPubSubServerMessage} message RedisPubSubServerMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedisPubSubServerMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.insertTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.insertTimeMillis = options.longs === String ? "0" : 0;
                    if (message.joinCallMessage != null && message.hasOwnProperty("joinCallMessage")) {
                        object.joinCallMessage = $root.infinitusai.be.RedisOperatorJoinCallMessage.toObject(message.joinCallMessage, options);
                        if (options.oneofs)
                            object.data = "joinCallMessage";
                    }
                    if (message.insertTimeMillis != null && message.hasOwnProperty("insertTimeMillis"))
                        if (typeof message.insertTimeMillis === "number")
                            object.insertTimeMillis = options.longs === String ? String(message.insertTimeMillis) : message.insertTimeMillis;
                        else
                            object.insertTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.insertTimeMillis) : options.longs === Number ? new $util.LongBits(message.insertTimeMillis.low >>> 0, message.insertTimeMillis.high >>> 0).toNumber() : message.insertTimeMillis;
                    if (message.suggestionsRefreshRequest != null && message.hasOwnProperty("suggestionsRefreshRequest")) {
                        object.suggestionsRefreshRequest = $root.infinitusai.be.RedisSuggestionsRefreshRequest.toObject(message.suggestionsRefreshRequest, options);
                        if (options.oneofs)
                            object.data = "suggestionsRefreshRequest";
                    }
                    if (message.notification != null && message.hasOwnProperty("notification")) {
                        object.notification = $root.infinitusai.be.Notification.toObject(message.notification, options);
                        if (options.oneofs)
                            object.data = "notification";
                    }
                    if (message.dismissNotification != null && message.hasOwnProperty("dismissNotification")) {
                        object.dismissNotification = $root.infinitusai.be.DismissNotification.toObject(message.dismissNotification, options);
                        if (options.oneofs)
                            object.data = "dismissNotification";
                    }
                    if (message.playAudio != null && message.hasOwnProperty("playAudio")) {
                        object.playAudio = $root.infinitusai.be.PlayAudioMessage.toObject(message.playAudio, options);
                        if (options.oneofs)
                            object.data = "playAudio";
                    }
                    if (message.leaveCallMessage != null && message.hasOwnProperty("leaveCallMessage")) {
                        object.leaveCallMessage = $root.infinitusai.be.RedisOperatorLeaveCallMessage.toObject(message.leaveCallMessage, options);
                        if (options.oneofs)
                            object.data = "leaveCallMessage";
                    }
                    return object;
                };
    
                /**
                 * Converts this RedisPubSubServerMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedisPubSubServerMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RedisPubSubServerMessage
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RedisPubSubServerMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedisPubSubServerMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RedisPubSubServerMessage";
                };
    
                return RedisPubSubServerMessage;
            })();
    
            be.QueryCallEventsTableRequest = (function() {
    
                /**
                 * Properties of a QueryCallEventsTableRequest.
                 * @memberof infinitusai.be
                 * @interface IQueryCallEventsTableRequest
                 * @property {infinitusai.be.IDate|null} [startDate] QueryCallEventsTableRequest startDate
                 * @property {infinitusai.be.IDate|null} [endDate] QueryCallEventsTableRequest endDate
                 */
    
                /**
                 * Constructs a new QueryCallEventsTableRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueryCallEventsTableRequest.
                 * @implements IQueryCallEventsTableRequest
                 * @constructor
                 * @param {infinitusai.be.IQueryCallEventsTableRequest=} [properties] Properties to set
                 */
                function QueryCallEventsTableRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * QueryCallEventsTableRequest startDate.
                 * @member {infinitusai.be.IDate|null|undefined} startDate
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @instance
                 */
                QueryCallEventsTableRequest.prototype.startDate = null;
    
                /**
                 * QueryCallEventsTableRequest endDate.
                 * @member {infinitusai.be.IDate|null|undefined} endDate
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @instance
                 */
                QueryCallEventsTableRequest.prototype.endDate = null;
    
                /**
                 * Creates a new QueryCallEventsTableRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {infinitusai.be.IQueryCallEventsTableRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.QueryCallEventsTableRequest} QueryCallEventsTableRequest instance
                 */
                QueryCallEventsTableRequest.create = function create(properties) {
                    return new QueryCallEventsTableRequest(properties);
                };
    
                /**
                 * Encodes the specified QueryCallEventsTableRequest message. Does not implicitly {@link infinitusai.be.QueryCallEventsTableRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {infinitusai.be.IQueryCallEventsTableRequest} message QueryCallEventsTableRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryCallEventsTableRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                        $root.infinitusai.be.Date.encode(message.startDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                        $root.infinitusai.be.Date.encode(message.endDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified QueryCallEventsTableRequest message, length delimited. Does not implicitly {@link infinitusai.be.QueryCallEventsTableRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {infinitusai.be.IQueryCallEventsTableRequest} message QueryCallEventsTableRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryCallEventsTableRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueryCallEventsTableRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueryCallEventsTableRequest} QueryCallEventsTableRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryCallEventsTableRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueryCallEventsTableRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.startDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.endDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueryCallEventsTableRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueryCallEventsTableRequest} QueryCallEventsTableRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryCallEventsTableRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueryCallEventsTableRequest message.
                 * @function verify
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryCallEventsTableRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.startDate != null && message.hasOwnProperty("startDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.startDate);
                        if (error)
                            return "startDate." + error;
                    }
                    if (message.endDate != null && message.hasOwnProperty("endDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.endDate);
                        if (error)
                            return "endDate." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a QueryCallEventsTableRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueryCallEventsTableRequest} QueryCallEventsTableRequest
                 */
                QueryCallEventsTableRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueryCallEventsTableRequest)
                        return object;
                    var message = new $root.infinitusai.be.QueryCallEventsTableRequest();
                    if (object.startDate != null) {
                        if (typeof object.startDate !== "object")
                            throw TypeError(".infinitusai.be.QueryCallEventsTableRequest.startDate: object expected");
                        message.startDate = $root.infinitusai.be.Date.fromObject(object.startDate);
                    }
                    if (object.endDate != null) {
                        if (typeof object.endDate !== "object")
                            throw TypeError(".infinitusai.be.QueryCallEventsTableRequest.endDate: object expected");
                        message.endDate = $root.infinitusai.be.Date.fromObject(object.endDate);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a QueryCallEventsTableRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {infinitusai.be.QueryCallEventsTableRequest} message QueryCallEventsTableRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryCallEventsTableRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.startDate = null;
                        object.endDate = null;
                    }
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        object.startDate = $root.infinitusai.be.Date.toObject(message.startDate, options);
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        object.endDate = $root.infinitusai.be.Date.toObject(message.endDate, options);
                    return object;
                };
    
                /**
                 * Converts this QueryCallEventsTableRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryCallEventsTableRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueryCallEventsTableRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueryCallEventsTableRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueryCallEventsTableRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueryCallEventsTableRequest";
                };
    
                return QueryCallEventsTableRequest;
            })();
    
            be.QueryCallEventsTableResponse = (function() {
    
                /**
                 * Properties of a QueryCallEventsTableResponse.
                 * @memberof infinitusai.be
                 * @interface IQueryCallEventsTableResponse
                 * @property {Array.<infinitusai.be.ICallEventRow>|null} [callEventRows] QueryCallEventsTableResponse callEventRows
                 */
    
                /**
                 * Constructs a new QueryCallEventsTableResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueryCallEventsTableResponse.
                 * @implements IQueryCallEventsTableResponse
                 * @constructor
                 * @param {infinitusai.be.IQueryCallEventsTableResponse=} [properties] Properties to set
                 */
                function QueryCallEventsTableResponse(properties) {
                    this.callEventRows = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * QueryCallEventsTableResponse callEventRows.
                 * @member {Array.<infinitusai.be.ICallEventRow>} callEventRows
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @instance
                 */
                QueryCallEventsTableResponse.prototype.callEventRows = $util.emptyArray;
    
                /**
                 * Creates a new QueryCallEventsTableResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {infinitusai.be.IQueryCallEventsTableResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.QueryCallEventsTableResponse} QueryCallEventsTableResponse instance
                 */
                QueryCallEventsTableResponse.create = function create(properties) {
                    return new QueryCallEventsTableResponse(properties);
                };
    
                /**
                 * Encodes the specified QueryCallEventsTableResponse message. Does not implicitly {@link infinitusai.be.QueryCallEventsTableResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {infinitusai.be.IQueryCallEventsTableResponse} message QueryCallEventsTableResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryCallEventsTableResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callEventRows != null && message.callEventRows.length)
                        for (var i = 0; i < message.callEventRows.length; ++i)
                            $root.infinitusai.be.CallEventRow.encode(message.callEventRows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified QueryCallEventsTableResponse message, length delimited. Does not implicitly {@link infinitusai.be.QueryCallEventsTableResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {infinitusai.be.IQueryCallEventsTableResponse} message QueryCallEventsTableResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryCallEventsTableResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueryCallEventsTableResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueryCallEventsTableResponse} QueryCallEventsTableResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryCallEventsTableResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueryCallEventsTableResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.callEventRows && message.callEventRows.length))
                                    message.callEventRows = [];
                                message.callEventRows.push($root.infinitusai.be.CallEventRow.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueryCallEventsTableResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueryCallEventsTableResponse} QueryCallEventsTableResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryCallEventsTableResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueryCallEventsTableResponse message.
                 * @function verify
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryCallEventsTableResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callEventRows != null && message.hasOwnProperty("callEventRows")) {
                        if (!Array.isArray(message.callEventRows))
                            return "callEventRows: array expected";
                        for (var i = 0; i < message.callEventRows.length; ++i) {
                            var error = $root.infinitusai.be.CallEventRow.verify(message.callEventRows[i]);
                            if (error)
                                return "callEventRows." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a QueryCallEventsTableResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueryCallEventsTableResponse} QueryCallEventsTableResponse
                 */
                QueryCallEventsTableResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueryCallEventsTableResponse)
                        return object;
                    var message = new $root.infinitusai.be.QueryCallEventsTableResponse();
                    if (object.callEventRows) {
                        if (!Array.isArray(object.callEventRows))
                            throw TypeError(".infinitusai.be.QueryCallEventsTableResponse.callEventRows: array expected");
                        message.callEventRows = [];
                        for (var i = 0; i < object.callEventRows.length; ++i) {
                            if (typeof object.callEventRows[i] !== "object")
                                throw TypeError(".infinitusai.be.QueryCallEventsTableResponse.callEventRows: object expected");
                            message.callEventRows[i] = $root.infinitusai.be.CallEventRow.fromObject(object.callEventRows[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a QueryCallEventsTableResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {infinitusai.be.QueryCallEventsTableResponse} message QueryCallEventsTableResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryCallEventsTableResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.callEventRows = [];
                    if (message.callEventRows && message.callEventRows.length) {
                        object.callEventRows = [];
                        for (var j = 0; j < message.callEventRows.length; ++j)
                            object.callEventRows[j] = $root.infinitusai.be.CallEventRow.toObject(message.callEventRows[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this QueryCallEventsTableResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryCallEventsTableResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueryCallEventsTableResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueryCallEventsTableResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueryCallEventsTableResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueryCallEventsTableResponse";
                };
    
                return QueryCallEventsTableResponse;
            })();
    
            /**
             * CallStatus enum.
             * @name infinitusai.be.CallStatus
             * @enum {number}
             * @property {number} CALL_STATUS_UNKNOWN=0 CALL_STATUS_UNKNOWN value
             * @property {number} CALL_STATUS_SUCCESSFULLY_FINISHED=1 CALL_STATUS_SUCCESSFULLY_FINISHED value
             * @property {number} CALL_STATUS_OPERATOR_REQUEUED=2 CALL_STATUS_OPERATOR_REQUEUED value
             * @property {number} CALL_STATUS_REVIEWER_REQUEUED=3 CALL_STATUS_REVIEWER_REQUEUED value
             * @property {number} CALL_STATUS_AWAITING_REVIEW=4 CALL_STATUS_AWAITING_REVIEW value
             */
            be.CallStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CALL_STATUS_UNKNOWN"] = 0;
                values[valuesById[1] = "CALL_STATUS_SUCCESSFULLY_FINISHED"] = 1;
                values[valuesById[2] = "CALL_STATUS_OPERATOR_REQUEUED"] = 2;
                values[valuesById[3] = "CALL_STATUS_REVIEWER_REQUEUED"] = 3;
                values[valuesById[4] = "CALL_STATUS_AWAITING_REVIEW"] = 4;
                return values;
            })();
    
            be.CallEventRow = (function() {
    
                /**
                 * Properties of a CallEventRow.
                 * @memberof infinitusai.be
                 * @interface ICallEventRow
                 * @property {string|null} [taskUuid] CallEventRow taskUuid
                 * @property {string|null} [callUuid] CallEventRow callUuid
                 * @property {string|null} [operatorEmail] CallEventRow operatorEmail
                 * @property {infinitusai.be.IDate|null} [date] CallEventRow date
                 * @property {number|null} [readyPageDurationMins] CallEventRow readyPageDurationMins
                 * @property {number|null} [callDurationMins] CallEventRow callDurationMins
                 * @property {infinitusai.be.CallStatus|null} [callStatus] CallEventRow callStatus
                 */
    
                /**
                 * Constructs a new CallEventRow.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallEventRow.
                 * @implements ICallEventRow
                 * @constructor
                 * @param {infinitusai.be.ICallEventRow=} [properties] Properties to set
                 */
                function CallEventRow(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallEventRow taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.CallEventRow
                 * @instance
                 */
                CallEventRow.prototype.taskUuid = "";
    
                /**
                 * CallEventRow callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.CallEventRow
                 * @instance
                 */
                CallEventRow.prototype.callUuid = "";
    
                /**
                 * CallEventRow operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.CallEventRow
                 * @instance
                 */
                CallEventRow.prototype.operatorEmail = "";
    
                /**
                 * CallEventRow date.
                 * @member {infinitusai.be.IDate|null|undefined} date
                 * @memberof infinitusai.be.CallEventRow
                 * @instance
                 */
                CallEventRow.prototype.date = null;
    
                /**
                 * CallEventRow readyPageDurationMins.
                 * @member {number} readyPageDurationMins
                 * @memberof infinitusai.be.CallEventRow
                 * @instance
                 */
                CallEventRow.prototype.readyPageDurationMins = 0;
    
                /**
                 * CallEventRow callDurationMins.
                 * @member {number} callDurationMins
                 * @memberof infinitusai.be.CallEventRow
                 * @instance
                 */
                CallEventRow.prototype.callDurationMins = 0;
    
                /**
                 * CallEventRow callStatus.
                 * @member {infinitusai.be.CallStatus} callStatus
                 * @memberof infinitusai.be.CallEventRow
                 * @instance
                 */
                CallEventRow.prototype.callStatus = 0;
    
                /**
                 * Creates a new CallEventRow instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {infinitusai.be.ICallEventRow=} [properties] Properties to set
                 * @returns {infinitusai.be.CallEventRow} CallEventRow instance
                 */
                CallEventRow.create = function create(properties) {
                    return new CallEventRow(properties);
                };
    
                /**
                 * Encodes the specified CallEventRow message. Does not implicitly {@link infinitusai.be.CallEventRow.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {infinitusai.be.ICallEventRow} message CallEventRow message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallEventRow.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.operatorEmail);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        $root.infinitusai.be.Date.encode(message.date, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.readyPageDurationMins != null && Object.hasOwnProperty.call(message, "readyPageDurationMins"))
                        writer.uint32(/* id 5, wireType 5 =*/45).float(message.readyPageDurationMins);
                    if (message.callDurationMins != null && Object.hasOwnProperty.call(message, "callDurationMins"))
                        writer.uint32(/* id 6, wireType 5 =*/53).float(message.callDurationMins);
                    if (message.callStatus != null && Object.hasOwnProperty.call(message, "callStatus"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.callStatus);
                    return writer;
                };
    
                /**
                 * Encodes the specified CallEventRow message, length delimited. Does not implicitly {@link infinitusai.be.CallEventRow.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {infinitusai.be.ICallEventRow} message CallEventRow message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallEventRow.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallEventRow message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallEventRow} CallEventRow
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallEventRow.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallEventRow();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        case 4: {
                                message.date = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.readyPageDurationMins = reader.float();
                                break;
                            }
                        case 6: {
                                message.callDurationMins = reader.float();
                                break;
                            }
                        case 7: {
                                message.callStatus = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallEventRow message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallEventRow} CallEventRow
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallEventRow.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallEventRow message.
                 * @function verify
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallEventRow.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.infinitusai.be.Date.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    if (message.readyPageDurationMins != null && message.hasOwnProperty("readyPageDurationMins"))
                        if (typeof message.readyPageDurationMins !== "number")
                            return "readyPageDurationMins: number expected";
                    if (message.callDurationMins != null && message.hasOwnProperty("callDurationMins"))
                        if (typeof message.callDurationMins !== "number")
                            return "callDurationMins: number expected";
                    if (message.callStatus != null && message.hasOwnProperty("callStatus"))
                        switch (message.callStatus) {
                        default:
                            return "callStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a CallEventRow message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallEventRow} CallEventRow
                 */
                CallEventRow.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallEventRow)
                        return object;
                    var message = new $root.infinitusai.be.CallEventRow();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".infinitusai.be.CallEventRow.date: object expected");
                        message.date = $root.infinitusai.be.Date.fromObject(object.date);
                    }
                    if (object.readyPageDurationMins != null)
                        message.readyPageDurationMins = Number(object.readyPageDurationMins);
                    if (object.callDurationMins != null)
                        message.callDurationMins = Number(object.callDurationMins);
                    switch (object.callStatus) {
                    default:
                        if (typeof object.callStatus === "number") {
                            message.callStatus = object.callStatus;
                            break;
                        }
                        break;
                    case "CALL_STATUS_UNKNOWN":
                    case 0:
                        message.callStatus = 0;
                        break;
                    case "CALL_STATUS_SUCCESSFULLY_FINISHED":
                    case 1:
                        message.callStatus = 1;
                        break;
                    case "CALL_STATUS_OPERATOR_REQUEUED":
                    case 2:
                        message.callStatus = 2;
                        break;
                    case "CALL_STATUS_REVIEWER_REQUEUED":
                    case 3:
                        message.callStatus = 3;
                        break;
                    case "CALL_STATUS_AWAITING_REVIEW":
                    case 4:
                        message.callStatus = 4;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallEventRow message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {infinitusai.be.CallEventRow} message CallEventRow
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallEventRow.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.operatorEmail = "";
                        object.date = null;
                        object.readyPageDurationMins = 0;
                        object.callDurationMins = 0;
                        object.callStatus = options.enums === String ? "CALL_STATUS_UNKNOWN" : 0;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.infinitusai.be.Date.toObject(message.date, options);
                    if (message.readyPageDurationMins != null && message.hasOwnProperty("readyPageDurationMins"))
                        object.readyPageDurationMins = options.json && !isFinite(message.readyPageDurationMins) ? String(message.readyPageDurationMins) : message.readyPageDurationMins;
                    if (message.callDurationMins != null && message.hasOwnProperty("callDurationMins"))
                        object.callDurationMins = options.json && !isFinite(message.callDurationMins) ? String(message.callDurationMins) : message.callDurationMins;
                    if (message.callStatus != null && message.hasOwnProperty("callStatus"))
                        object.callStatus = options.enums === String ? $root.infinitusai.be.CallStatus[message.callStatus] === undefined ? message.callStatus : $root.infinitusai.be.CallStatus[message.callStatus] : message.callStatus;
                    return object;
                };
    
                /**
                 * Converts this CallEventRow to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallEventRow
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallEventRow.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallEventRow
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallEventRow
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallEventRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallEventRow";
                };
    
                return CallEventRow;
            })();
    
            be.CallEventRows = (function() {
    
                /**
                 * Properties of a CallEventRows.
                 * @memberof infinitusai.be
                 * @interface ICallEventRows
                 * @property {Array.<infinitusai.be.ICallEventRow>|null} [callEventRows] CallEventRows callEventRows
                 */
    
                /**
                 * Constructs a new CallEventRows.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CallEventRows.
                 * @implements ICallEventRows
                 * @constructor
                 * @param {infinitusai.be.ICallEventRows=} [properties] Properties to set
                 */
                function CallEventRows(properties) {
                    this.callEventRows = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CallEventRows callEventRows.
                 * @member {Array.<infinitusai.be.ICallEventRow>} callEventRows
                 * @memberof infinitusai.be.CallEventRows
                 * @instance
                 */
                CallEventRows.prototype.callEventRows = $util.emptyArray;
    
                /**
                 * Creates a new CallEventRows instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {infinitusai.be.ICallEventRows=} [properties] Properties to set
                 * @returns {infinitusai.be.CallEventRows} CallEventRows instance
                 */
                CallEventRows.create = function create(properties) {
                    return new CallEventRows(properties);
                };
    
                /**
                 * Encodes the specified CallEventRows message. Does not implicitly {@link infinitusai.be.CallEventRows.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {infinitusai.be.ICallEventRows} message CallEventRows message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallEventRows.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callEventRows != null && message.callEventRows.length)
                        for (var i = 0; i < message.callEventRows.length; ++i)
                            $root.infinitusai.be.CallEventRow.encode(message.callEventRows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CallEventRows message, length delimited. Does not implicitly {@link infinitusai.be.CallEventRows.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {infinitusai.be.ICallEventRows} message CallEventRows message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CallEventRows.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CallEventRows message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CallEventRows} CallEventRows
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallEventRows.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CallEventRows();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.callEventRows && message.callEventRows.length))
                                    message.callEventRows = [];
                                message.callEventRows.push($root.infinitusai.be.CallEventRow.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CallEventRows message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CallEventRows} CallEventRows
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CallEventRows.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CallEventRows message.
                 * @function verify
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CallEventRows.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callEventRows != null && message.hasOwnProperty("callEventRows")) {
                        if (!Array.isArray(message.callEventRows))
                            return "callEventRows: array expected";
                        for (var i = 0; i < message.callEventRows.length; ++i) {
                            var error = $root.infinitusai.be.CallEventRow.verify(message.callEventRows[i]);
                            if (error)
                                return "callEventRows." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a CallEventRows message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CallEventRows} CallEventRows
                 */
                CallEventRows.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CallEventRows)
                        return object;
                    var message = new $root.infinitusai.be.CallEventRows();
                    if (object.callEventRows) {
                        if (!Array.isArray(object.callEventRows))
                            throw TypeError(".infinitusai.be.CallEventRows.callEventRows: array expected");
                        message.callEventRows = [];
                        for (var i = 0; i < object.callEventRows.length; ++i) {
                            if (typeof object.callEventRows[i] !== "object")
                                throw TypeError(".infinitusai.be.CallEventRows.callEventRows: object expected");
                            message.callEventRows[i] = $root.infinitusai.be.CallEventRow.fromObject(object.callEventRows[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CallEventRows message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {infinitusai.be.CallEventRows} message CallEventRows
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CallEventRows.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.callEventRows = [];
                    if (message.callEventRows && message.callEventRows.length) {
                        object.callEventRows = [];
                        for (var j = 0; j < message.callEventRows.length; ++j)
                            object.callEventRows[j] = $root.infinitusai.be.CallEventRow.toObject(message.callEventRows[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this CallEventRows to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CallEventRows
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CallEventRows.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CallEventRows
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CallEventRows
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CallEventRows.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CallEventRows";
                };
    
                return CallEventRows;
            })();
    
            be.QueryUserProductivityMetricsTableRequest = (function() {
    
                /**
                 * Properties of a QueryUserProductivityMetricsTableRequest.
                 * @memberof infinitusai.be
                 * @interface IQueryUserProductivityMetricsTableRequest
                 * @property {infinitusai.be.IDate|null} [startDate] QueryUserProductivityMetricsTableRequest startDate
                 * @property {infinitusai.be.IDate|null} [endDate] QueryUserProductivityMetricsTableRequest endDate
                 */
    
                /**
                 * Constructs a new QueryUserProductivityMetricsTableRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueryUserProductivityMetricsTableRequest.
                 * @implements IQueryUserProductivityMetricsTableRequest
                 * @constructor
                 * @param {infinitusai.be.IQueryUserProductivityMetricsTableRequest=} [properties] Properties to set
                 */
                function QueryUserProductivityMetricsTableRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * QueryUserProductivityMetricsTableRequest startDate.
                 * @member {infinitusai.be.IDate|null|undefined} startDate
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @instance
                 */
                QueryUserProductivityMetricsTableRequest.prototype.startDate = null;
    
                /**
                 * QueryUserProductivityMetricsTableRequest endDate.
                 * @member {infinitusai.be.IDate|null|undefined} endDate
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @instance
                 */
                QueryUserProductivityMetricsTableRequest.prototype.endDate = null;
    
                /**
                 * Creates a new QueryUserProductivityMetricsTableRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserProductivityMetricsTableRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.QueryUserProductivityMetricsTableRequest} QueryUserProductivityMetricsTableRequest instance
                 */
                QueryUserProductivityMetricsTableRequest.create = function create(properties) {
                    return new QueryUserProductivityMetricsTableRequest(properties);
                };
    
                /**
                 * Encodes the specified QueryUserProductivityMetricsTableRequest message. Does not implicitly {@link infinitusai.be.QueryUserProductivityMetricsTableRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserProductivityMetricsTableRequest} message QueryUserProductivityMetricsTableRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserProductivityMetricsTableRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                        $root.infinitusai.be.Date.encode(message.startDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                        $root.infinitusai.be.Date.encode(message.endDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified QueryUserProductivityMetricsTableRequest message, length delimited. Does not implicitly {@link infinitusai.be.QueryUserProductivityMetricsTableRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserProductivityMetricsTableRequest} message QueryUserProductivityMetricsTableRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserProductivityMetricsTableRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueryUserProductivityMetricsTableRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueryUserProductivityMetricsTableRequest} QueryUserProductivityMetricsTableRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserProductivityMetricsTableRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueryUserProductivityMetricsTableRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.startDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.endDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueryUserProductivityMetricsTableRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueryUserProductivityMetricsTableRequest} QueryUserProductivityMetricsTableRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserProductivityMetricsTableRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueryUserProductivityMetricsTableRequest message.
                 * @function verify
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryUserProductivityMetricsTableRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.startDate != null && message.hasOwnProperty("startDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.startDate);
                        if (error)
                            return "startDate." + error;
                    }
                    if (message.endDate != null && message.hasOwnProperty("endDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.endDate);
                        if (error)
                            return "endDate." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a QueryUserProductivityMetricsTableRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueryUserProductivityMetricsTableRequest} QueryUserProductivityMetricsTableRequest
                 */
                QueryUserProductivityMetricsTableRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueryUserProductivityMetricsTableRequest)
                        return object;
                    var message = new $root.infinitusai.be.QueryUserProductivityMetricsTableRequest();
                    if (object.startDate != null) {
                        if (typeof object.startDate !== "object")
                            throw TypeError(".infinitusai.be.QueryUserProductivityMetricsTableRequest.startDate: object expected");
                        message.startDate = $root.infinitusai.be.Date.fromObject(object.startDate);
                    }
                    if (object.endDate != null) {
                        if (typeof object.endDate !== "object")
                            throw TypeError(".infinitusai.be.QueryUserProductivityMetricsTableRequest.endDate: object expected");
                        message.endDate = $root.infinitusai.be.Date.fromObject(object.endDate);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a QueryUserProductivityMetricsTableRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {infinitusai.be.QueryUserProductivityMetricsTableRequest} message QueryUserProductivityMetricsTableRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryUserProductivityMetricsTableRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.startDate = null;
                        object.endDate = null;
                    }
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        object.startDate = $root.infinitusai.be.Date.toObject(message.startDate, options);
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        object.endDate = $root.infinitusai.be.Date.toObject(message.endDate, options);
                    return object;
                };
    
                /**
                 * Converts this QueryUserProductivityMetricsTableRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryUserProductivityMetricsTableRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueryUserProductivityMetricsTableRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueryUserProductivityMetricsTableRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueryUserProductivityMetricsTableRequest";
                };
    
                return QueryUserProductivityMetricsTableRequest;
            })();
    
            be.QueryUserProductivityMetricsTableResponse = (function() {
    
                /**
                 * Properties of a QueryUserProductivityMetricsTableResponse.
                 * @memberof infinitusai.be
                 * @interface IQueryUserProductivityMetricsTableResponse
                 * @property {Array.<infinitusai.be.IUserProductivityMetricRow>|null} [userProductivityMetricRows] QueryUserProductivityMetricsTableResponse userProductivityMetricRows
                 * @property {number|Long|null} [cacheTimestampMs] QueryUserProductivityMetricsTableResponse cacheTimestampMs
                 */
    
                /**
                 * Constructs a new QueryUserProductivityMetricsTableResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueryUserProductivityMetricsTableResponse.
                 * @implements IQueryUserProductivityMetricsTableResponse
                 * @constructor
                 * @param {infinitusai.be.IQueryUserProductivityMetricsTableResponse=} [properties] Properties to set
                 */
                function QueryUserProductivityMetricsTableResponse(properties) {
                    this.userProductivityMetricRows = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * QueryUserProductivityMetricsTableResponse userProductivityMetricRows.
                 * @member {Array.<infinitusai.be.IUserProductivityMetricRow>} userProductivityMetricRows
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @instance
                 */
                QueryUserProductivityMetricsTableResponse.prototype.userProductivityMetricRows = $util.emptyArray;
    
                /**
                 * QueryUserProductivityMetricsTableResponse cacheTimestampMs.
                 * @member {number|Long} cacheTimestampMs
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @instance
                 */
                QueryUserProductivityMetricsTableResponse.prototype.cacheTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new QueryUserProductivityMetricsTableResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {infinitusai.be.IQueryUserProductivityMetricsTableResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.QueryUserProductivityMetricsTableResponse} QueryUserProductivityMetricsTableResponse instance
                 */
                QueryUserProductivityMetricsTableResponse.create = function create(properties) {
                    return new QueryUserProductivityMetricsTableResponse(properties);
                };
    
                /**
                 * Encodes the specified QueryUserProductivityMetricsTableResponse message. Does not implicitly {@link infinitusai.be.QueryUserProductivityMetricsTableResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {infinitusai.be.IQueryUserProductivityMetricsTableResponse} message QueryUserProductivityMetricsTableResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserProductivityMetricsTableResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userProductivityMetricRows != null && message.userProductivityMetricRows.length)
                        for (var i = 0; i < message.userProductivityMetricRows.length; ++i)
                            $root.infinitusai.be.UserProductivityMetricRow.encode(message.userProductivityMetricRows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.cacheTimestampMs != null && Object.hasOwnProperty.call(message, "cacheTimestampMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cacheTimestampMs);
                    return writer;
                };
    
                /**
                 * Encodes the specified QueryUserProductivityMetricsTableResponse message, length delimited. Does not implicitly {@link infinitusai.be.QueryUserProductivityMetricsTableResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {infinitusai.be.IQueryUserProductivityMetricsTableResponse} message QueryUserProductivityMetricsTableResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserProductivityMetricsTableResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueryUserProductivityMetricsTableResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueryUserProductivityMetricsTableResponse} QueryUserProductivityMetricsTableResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserProductivityMetricsTableResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueryUserProductivityMetricsTableResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.userProductivityMetricRows && message.userProductivityMetricRows.length))
                                    message.userProductivityMetricRows = [];
                                message.userProductivityMetricRows.push($root.infinitusai.be.UserProductivityMetricRow.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.cacheTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueryUserProductivityMetricsTableResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueryUserProductivityMetricsTableResponse} QueryUserProductivityMetricsTableResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserProductivityMetricsTableResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueryUserProductivityMetricsTableResponse message.
                 * @function verify
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryUserProductivityMetricsTableResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userProductivityMetricRows != null && message.hasOwnProperty("userProductivityMetricRows")) {
                        if (!Array.isArray(message.userProductivityMetricRows))
                            return "userProductivityMetricRows: array expected";
                        for (var i = 0; i < message.userProductivityMetricRows.length; ++i) {
                            var error = $root.infinitusai.be.UserProductivityMetricRow.verify(message.userProductivityMetricRows[i]);
                            if (error)
                                return "userProductivityMetricRows." + error;
                        }
                    }
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (!$util.isInteger(message.cacheTimestampMs) && !(message.cacheTimestampMs && $util.isInteger(message.cacheTimestampMs.low) && $util.isInteger(message.cacheTimestampMs.high)))
                            return "cacheTimestampMs: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a QueryUserProductivityMetricsTableResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueryUserProductivityMetricsTableResponse} QueryUserProductivityMetricsTableResponse
                 */
                QueryUserProductivityMetricsTableResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueryUserProductivityMetricsTableResponse)
                        return object;
                    var message = new $root.infinitusai.be.QueryUserProductivityMetricsTableResponse();
                    if (object.userProductivityMetricRows) {
                        if (!Array.isArray(object.userProductivityMetricRows))
                            throw TypeError(".infinitusai.be.QueryUserProductivityMetricsTableResponse.userProductivityMetricRows: array expected");
                        message.userProductivityMetricRows = [];
                        for (var i = 0; i < object.userProductivityMetricRows.length; ++i) {
                            if (typeof object.userProductivityMetricRows[i] !== "object")
                                throw TypeError(".infinitusai.be.QueryUserProductivityMetricsTableResponse.userProductivityMetricRows: object expected");
                            message.userProductivityMetricRows[i] = $root.infinitusai.be.UserProductivityMetricRow.fromObject(object.userProductivityMetricRows[i]);
                        }
                    }
                    if (object.cacheTimestampMs != null)
                        if ($util.Long)
                            (message.cacheTimestampMs = $util.Long.fromValue(object.cacheTimestampMs)).unsigned = false;
                        else if (typeof object.cacheTimestampMs === "string")
                            message.cacheTimestampMs = parseInt(object.cacheTimestampMs, 10);
                        else if (typeof object.cacheTimestampMs === "number")
                            message.cacheTimestampMs = object.cacheTimestampMs;
                        else if (typeof object.cacheTimestampMs === "object")
                            message.cacheTimestampMs = new $util.LongBits(object.cacheTimestampMs.low >>> 0, object.cacheTimestampMs.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a QueryUserProductivityMetricsTableResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {infinitusai.be.QueryUserProductivityMetricsTableResponse} message QueryUserProductivityMetricsTableResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryUserProductivityMetricsTableResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.userProductivityMetricRows = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.cacheTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cacheTimestampMs = options.longs === String ? "0" : 0;
                    if (message.userProductivityMetricRows && message.userProductivityMetricRows.length) {
                        object.userProductivityMetricRows = [];
                        for (var j = 0; j < message.userProductivityMetricRows.length; ++j)
                            object.userProductivityMetricRows[j] = $root.infinitusai.be.UserProductivityMetricRow.toObject(message.userProductivityMetricRows[j], options);
                    }
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (typeof message.cacheTimestampMs === "number")
                            object.cacheTimestampMs = options.longs === String ? String(message.cacheTimestampMs) : message.cacheTimestampMs;
                        else
                            object.cacheTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.cacheTimestampMs) : options.longs === Number ? new $util.LongBits(message.cacheTimestampMs.low >>> 0, message.cacheTimestampMs.high >>> 0).toNumber() : message.cacheTimestampMs;
                    return object;
                };
    
                /**
                 * Converts this QueryUserProductivityMetricsTableResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryUserProductivityMetricsTableResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueryUserProductivityMetricsTableResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueryUserProductivityMetricsTableResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueryUserProductivityMetricsTableResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueryUserProductivityMetricsTableResponse";
                };
    
                return QueryUserProductivityMetricsTableResponse;
            })();
    
            be.UserProductivityMetricRow = (function() {
    
                /**
                 * Properties of a UserProductivityMetricRow.
                 * @memberof infinitusai.be
                 * @interface IUserProductivityMetricRow
                 * @property {string|null} [name] UserProductivityMetricRow name
                 * @property {string|null} [email] UserProductivityMetricRow email
                 * @property {string|null} [team] UserProductivityMetricRow team
                 * @property {string|null} [role] UserProductivityMetricRow role
                 * @property {string|null} [employer] UserProductivityMetricRow employer
                 * @property {string|null} [manager] UserProductivityMetricRow manager
                 * @property {number|null} [callTimeTenureDays] UserProductivityMetricRow callTimeTenureDays
                 * @property {string|null} [day] UserProductivityMetricRow day
                 * @property {number|null} [operatorScore] UserProductivityMetricRow operatorScore
                 * @property {number|null} [reviewScore] UserProductivityMetricRow reviewScore
                 * @property {number|null} [totalScore] UserProductivityMetricRow totalScore
                 * @property {number|null} [totalPotentialScore] UserProductivityMetricRow totalPotentialScore
                 */
    
                /**
                 * Constructs a new UserProductivityMetricRow.
                 * @memberof infinitusai.be
                 * @classdesc Represents a UserProductivityMetricRow.
                 * @implements IUserProductivityMetricRow
                 * @constructor
                 * @param {infinitusai.be.IUserProductivityMetricRow=} [properties] Properties to set
                 */
                function UserProductivityMetricRow(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UserProductivityMetricRow name.
                 * @member {string} name
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.name = "";
    
                /**
                 * UserProductivityMetricRow email.
                 * @member {string} email
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.email = "";
    
                /**
                 * UserProductivityMetricRow team.
                 * @member {string} team
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.team = "";
    
                /**
                 * UserProductivityMetricRow role.
                 * @member {string} role
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.role = "";
    
                /**
                 * UserProductivityMetricRow employer.
                 * @member {string} employer
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.employer = "";
    
                /**
                 * UserProductivityMetricRow manager.
                 * @member {string} manager
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.manager = "";
    
                /**
                 * UserProductivityMetricRow callTimeTenureDays.
                 * @member {number} callTimeTenureDays
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.callTimeTenureDays = 0;
    
                /**
                 * UserProductivityMetricRow day.
                 * @member {string} day
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.day = "";
    
                /**
                 * UserProductivityMetricRow operatorScore.
                 * @member {number} operatorScore
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.operatorScore = 0;
    
                /**
                 * UserProductivityMetricRow reviewScore.
                 * @member {number} reviewScore
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.reviewScore = 0;
    
                /**
                 * UserProductivityMetricRow totalScore.
                 * @member {number} totalScore
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.totalScore = 0;
    
                /**
                 * UserProductivityMetricRow totalPotentialScore.
                 * @member {number} totalPotentialScore
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 */
                UserProductivityMetricRow.prototype.totalPotentialScore = 0;
    
                /**
                 * Creates a new UserProductivityMetricRow instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {infinitusai.be.IUserProductivityMetricRow=} [properties] Properties to set
                 * @returns {infinitusai.be.UserProductivityMetricRow} UserProductivityMetricRow instance
                 */
                UserProductivityMetricRow.create = function create(properties) {
                    return new UserProductivityMetricRow(properties);
                };
    
                /**
                 * Encodes the specified UserProductivityMetricRow message. Does not implicitly {@link infinitusai.be.UserProductivityMetricRow.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {infinitusai.be.IUserProductivityMetricRow} message UserProductivityMetricRow message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProductivityMetricRow.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                    if (message.team != null && Object.hasOwnProperty.call(message, "team"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.team);
                    if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.role);
                    if (message.employer != null && Object.hasOwnProperty.call(message, "employer"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.employer);
                    if (message.manager != null && Object.hasOwnProperty.call(message, "manager"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.manager);
                    if (message.callTimeTenureDays != null && Object.hasOwnProperty.call(message, "callTimeTenureDays"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.callTimeTenureDays);
                    if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.day);
                    if (message.operatorScore != null && Object.hasOwnProperty.call(message, "operatorScore"))
                        writer.uint32(/* id 9, wireType 5 =*/77).float(message.operatorScore);
                    if (message.reviewScore != null && Object.hasOwnProperty.call(message, "reviewScore"))
                        writer.uint32(/* id 10, wireType 5 =*/85).float(message.reviewScore);
                    if (message.totalScore != null && Object.hasOwnProperty.call(message, "totalScore"))
                        writer.uint32(/* id 11, wireType 5 =*/93).float(message.totalScore);
                    if (message.totalPotentialScore != null && Object.hasOwnProperty.call(message, "totalPotentialScore"))
                        writer.uint32(/* id 12, wireType 5 =*/101).float(message.totalPotentialScore);
                    return writer;
                };
    
                /**
                 * Encodes the specified UserProductivityMetricRow message, length delimited. Does not implicitly {@link infinitusai.be.UserProductivityMetricRow.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {infinitusai.be.IUserProductivityMetricRow} message UserProductivityMetricRow message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProductivityMetricRow.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UserProductivityMetricRow message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UserProductivityMetricRow} UserProductivityMetricRow
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProductivityMetricRow.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UserProductivityMetricRow();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.email = reader.string();
                                break;
                            }
                        case 3: {
                                message.team = reader.string();
                                break;
                            }
                        case 4: {
                                message.role = reader.string();
                                break;
                            }
                        case 5: {
                                message.employer = reader.string();
                                break;
                            }
                        case 6: {
                                message.manager = reader.string();
                                break;
                            }
                        case 7: {
                                message.callTimeTenureDays = reader.int32();
                                break;
                            }
                        case 8: {
                                message.day = reader.string();
                                break;
                            }
                        case 9: {
                                message.operatorScore = reader.float();
                                break;
                            }
                        case 10: {
                                message.reviewScore = reader.float();
                                break;
                            }
                        case 11: {
                                message.totalScore = reader.float();
                                break;
                            }
                        case 12: {
                                message.totalPotentialScore = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UserProductivityMetricRow message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UserProductivityMetricRow} UserProductivityMetricRow
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProductivityMetricRow.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UserProductivityMetricRow message.
                 * @function verify
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProductivityMetricRow.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.team != null && message.hasOwnProperty("team"))
                        if (!$util.isString(message.team))
                            return "team: string expected";
                    if (message.role != null && message.hasOwnProperty("role"))
                        if (!$util.isString(message.role))
                            return "role: string expected";
                    if (message.employer != null && message.hasOwnProperty("employer"))
                        if (!$util.isString(message.employer))
                            return "employer: string expected";
                    if (message.manager != null && message.hasOwnProperty("manager"))
                        if (!$util.isString(message.manager))
                            return "manager: string expected";
                    if (message.callTimeTenureDays != null && message.hasOwnProperty("callTimeTenureDays"))
                        if (!$util.isInteger(message.callTimeTenureDays))
                            return "callTimeTenureDays: integer expected";
                    if (message.day != null && message.hasOwnProperty("day"))
                        if (!$util.isString(message.day))
                            return "day: string expected";
                    if (message.operatorScore != null && message.hasOwnProperty("operatorScore"))
                        if (typeof message.operatorScore !== "number")
                            return "operatorScore: number expected";
                    if (message.reviewScore != null && message.hasOwnProperty("reviewScore"))
                        if (typeof message.reviewScore !== "number")
                            return "reviewScore: number expected";
                    if (message.totalScore != null && message.hasOwnProperty("totalScore"))
                        if (typeof message.totalScore !== "number")
                            return "totalScore: number expected";
                    if (message.totalPotentialScore != null && message.hasOwnProperty("totalPotentialScore"))
                        if (typeof message.totalPotentialScore !== "number")
                            return "totalPotentialScore: number expected";
                    return null;
                };
    
                /**
                 * Creates a UserProductivityMetricRow message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UserProductivityMetricRow} UserProductivityMetricRow
                 */
                UserProductivityMetricRow.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UserProductivityMetricRow)
                        return object;
                    var message = new $root.infinitusai.be.UserProductivityMetricRow();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.team != null)
                        message.team = String(object.team);
                    if (object.role != null)
                        message.role = String(object.role);
                    if (object.employer != null)
                        message.employer = String(object.employer);
                    if (object.manager != null)
                        message.manager = String(object.manager);
                    if (object.callTimeTenureDays != null)
                        message.callTimeTenureDays = object.callTimeTenureDays | 0;
                    if (object.day != null)
                        message.day = String(object.day);
                    if (object.operatorScore != null)
                        message.operatorScore = Number(object.operatorScore);
                    if (object.reviewScore != null)
                        message.reviewScore = Number(object.reviewScore);
                    if (object.totalScore != null)
                        message.totalScore = Number(object.totalScore);
                    if (object.totalPotentialScore != null)
                        message.totalPotentialScore = Number(object.totalPotentialScore);
                    return message;
                };
    
                /**
                 * Creates a plain object from a UserProductivityMetricRow message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {infinitusai.be.UserProductivityMetricRow} message UserProductivityMetricRow
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProductivityMetricRow.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.email = "";
                        object.team = "";
                        object.role = "";
                        object.employer = "";
                        object.manager = "";
                        object.callTimeTenureDays = 0;
                        object.day = "";
                        object.operatorScore = 0;
                        object.reviewScore = 0;
                        object.totalScore = 0;
                        object.totalPotentialScore = 0;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.team != null && message.hasOwnProperty("team"))
                        object.team = message.team;
                    if (message.role != null && message.hasOwnProperty("role"))
                        object.role = message.role;
                    if (message.employer != null && message.hasOwnProperty("employer"))
                        object.employer = message.employer;
                    if (message.manager != null && message.hasOwnProperty("manager"))
                        object.manager = message.manager;
                    if (message.callTimeTenureDays != null && message.hasOwnProperty("callTimeTenureDays"))
                        object.callTimeTenureDays = message.callTimeTenureDays;
                    if (message.day != null && message.hasOwnProperty("day"))
                        object.day = message.day;
                    if (message.operatorScore != null && message.hasOwnProperty("operatorScore"))
                        object.operatorScore = options.json && !isFinite(message.operatorScore) ? String(message.operatorScore) : message.operatorScore;
                    if (message.reviewScore != null && message.hasOwnProperty("reviewScore"))
                        object.reviewScore = options.json && !isFinite(message.reviewScore) ? String(message.reviewScore) : message.reviewScore;
                    if (message.totalScore != null && message.hasOwnProperty("totalScore"))
                        object.totalScore = options.json && !isFinite(message.totalScore) ? String(message.totalScore) : message.totalScore;
                    if (message.totalPotentialScore != null && message.hasOwnProperty("totalPotentialScore"))
                        object.totalPotentialScore = options.json && !isFinite(message.totalPotentialScore) ? String(message.totalPotentialScore) : message.totalPotentialScore;
                    return object;
                };
    
                /**
                 * Converts this UserProductivityMetricRow to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProductivityMetricRow.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UserProductivityMetricRow
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UserProductivityMetricRow
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProductivityMetricRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UserProductivityMetricRow";
                };
    
                return UserProductivityMetricRow;
            })();
    
            be.UserProductivityMetricRows = (function() {
    
                /**
                 * Properties of a UserProductivityMetricRows.
                 * @memberof infinitusai.be
                 * @interface IUserProductivityMetricRows
                 * @property {Array.<infinitusai.be.IUserProductivityMetricRow>|null} [userProductivityMetricRows] UserProductivityMetricRows userProductivityMetricRows
                 */
    
                /**
                 * Constructs a new UserProductivityMetricRows.
                 * @memberof infinitusai.be
                 * @classdesc Represents a UserProductivityMetricRows.
                 * @implements IUserProductivityMetricRows
                 * @constructor
                 * @param {infinitusai.be.IUserProductivityMetricRows=} [properties] Properties to set
                 */
                function UserProductivityMetricRows(properties) {
                    this.userProductivityMetricRows = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UserProductivityMetricRows userProductivityMetricRows.
                 * @member {Array.<infinitusai.be.IUserProductivityMetricRow>} userProductivityMetricRows
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @instance
                 */
                UserProductivityMetricRows.prototype.userProductivityMetricRows = $util.emptyArray;
    
                /**
                 * Creates a new UserProductivityMetricRows instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {infinitusai.be.IUserProductivityMetricRows=} [properties] Properties to set
                 * @returns {infinitusai.be.UserProductivityMetricRows} UserProductivityMetricRows instance
                 */
                UserProductivityMetricRows.create = function create(properties) {
                    return new UserProductivityMetricRows(properties);
                };
    
                /**
                 * Encodes the specified UserProductivityMetricRows message. Does not implicitly {@link infinitusai.be.UserProductivityMetricRows.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {infinitusai.be.IUserProductivityMetricRows} message UserProductivityMetricRows message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProductivityMetricRows.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userProductivityMetricRows != null && message.userProductivityMetricRows.length)
                        for (var i = 0; i < message.userProductivityMetricRows.length; ++i)
                            $root.infinitusai.be.UserProductivityMetricRow.encode(message.userProductivityMetricRows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UserProductivityMetricRows message, length delimited. Does not implicitly {@link infinitusai.be.UserProductivityMetricRows.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {infinitusai.be.IUserProductivityMetricRows} message UserProductivityMetricRows message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProductivityMetricRows.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UserProductivityMetricRows message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UserProductivityMetricRows} UserProductivityMetricRows
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProductivityMetricRows.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UserProductivityMetricRows();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.userProductivityMetricRows && message.userProductivityMetricRows.length))
                                    message.userProductivityMetricRows = [];
                                message.userProductivityMetricRows.push($root.infinitusai.be.UserProductivityMetricRow.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UserProductivityMetricRows message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UserProductivityMetricRows} UserProductivityMetricRows
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProductivityMetricRows.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UserProductivityMetricRows message.
                 * @function verify
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProductivityMetricRows.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userProductivityMetricRows != null && message.hasOwnProperty("userProductivityMetricRows")) {
                        if (!Array.isArray(message.userProductivityMetricRows))
                            return "userProductivityMetricRows: array expected";
                        for (var i = 0; i < message.userProductivityMetricRows.length; ++i) {
                            var error = $root.infinitusai.be.UserProductivityMetricRow.verify(message.userProductivityMetricRows[i]);
                            if (error)
                                return "userProductivityMetricRows." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a UserProductivityMetricRows message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UserProductivityMetricRows} UserProductivityMetricRows
                 */
                UserProductivityMetricRows.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UserProductivityMetricRows)
                        return object;
                    var message = new $root.infinitusai.be.UserProductivityMetricRows();
                    if (object.userProductivityMetricRows) {
                        if (!Array.isArray(object.userProductivityMetricRows))
                            throw TypeError(".infinitusai.be.UserProductivityMetricRows.userProductivityMetricRows: array expected");
                        message.userProductivityMetricRows = [];
                        for (var i = 0; i < object.userProductivityMetricRows.length; ++i) {
                            if (typeof object.userProductivityMetricRows[i] !== "object")
                                throw TypeError(".infinitusai.be.UserProductivityMetricRows.userProductivityMetricRows: object expected");
                            message.userProductivityMetricRows[i] = $root.infinitusai.be.UserProductivityMetricRow.fromObject(object.userProductivityMetricRows[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a UserProductivityMetricRows message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {infinitusai.be.UserProductivityMetricRows} message UserProductivityMetricRows
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProductivityMetricRows.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.userProductivityMetricRows = [];
                    if (message.userProductivityMetricRows && message.userProductivityMetricRows.length) {
                        object.userProductivityMetricRows = [];
                        for (var j = 0; j < message.userProductivityMetricRows.length; ++j)
                            object.userProductivityMetricRows[j] = $root.infinitusai.be.UserProductivityMetricRow.toObject(message.userProductivityMetricRows[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this UserProductivityMetricRows to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProductivityMetricRows.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UserProductivityMetricRows
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UserProductivityMetricRows
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProductivityMetricRows.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UserProductivityMetricRows";
                };
    
                return UserProductivityMetricRows;
            })();
    
            be.MyRecentCallActivityRequest = (function() {
    
                /**
                 * Properties of a MyRecentCallActivityRequest.
                 * @memberof infinitusai.be
                 * @interface IMyRecentCallActivityRequest
                 * @property {infinitusai.be.IDate|null} [startDate] MyRecentCallActivityRequest startDate
                 * @property {infinitusai.be.IDate|null} [endDate] MyRecentCallActivityRequest endDate
                 */
    
                /**
                 * Constructs a new MyRecentCallActivityRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MyRecentCallActivityRequest.
                 * @implements IMyRecentCallActivityRequest
                 * @constructor
                 * @param {infinitusai.be.IMyRecentCallActivityRequest=} [properties] Properties to set
                 */
                function MyRecentCallActivityRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MyRecentCallActivityRequest startDate.
                 * @member {infinitusai.be.IDate|null|undefined} startDate
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @instance
                 */
                MyRecentCallActivityRequest.prototype.startDate = null;
    
                /**
                 * MyRecentCallActivityRequest endDate.
                 * @member {infinitusai.be.IDate|null|undefined} endDate
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @instance
                 */
                MyRecentCallActivityRequest.prototype.endDate = null;
    
                /**
                 * Creates a new MyRecentCallActivityRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.MyRecentCallActivityRequest} MyRecentCallActivityRequest instance
                 */
                MyRecentCallActivityRequest.create = function create(properties) {
                    return new MyRecentCallActivityRequest(properties);
                };
    
                /**
                 * Encodes the specified MyRecentCallActivityRequest message. Does not implicitly {@link infinitusai.be.MyRecentCallActivityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityRequest} message MyRecentCallActivityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MyRecentCallActivityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                        $root.infinitusai.be.Date.encode(message.startDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                        $root.infinitusai.be.Date.encode(message.endDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MyRecentCallActivityRequest message, length delimited. Does not implicitly {@link infinitusai.be.MyRecentCallActivityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityRequest} message MyRecentCallActivityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MyRecentCallActivityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MyRecentCallActivityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MyRecentCallActivityRequest} MyRecentCallActivityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MyRecentCallActivityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MyRecentCallActivityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.startDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.endDate = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MyRecentCallActivityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MyRecentCallActivityRequest} MyRecentCallActivityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MyRecentCallActivityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MyRecentCallActivityRequest message.
                 * @function verify
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MyRecentCallActivityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.startDate != null && message.hasOwnProperty("startDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.startDate);
                        if (error)
                            return "startDate." + error;
                    }
                    if (message.endDate != null && message.hasOwnProperty("endDate")) {
                        var error = $root.infinitusai.be.Date.verify(message.endDate);
                        if (error)
                            return "endDate." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a MyRecentCallActivityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MyRecentCallActivityRequest} MyRecentCallActivityRequest
                 */
                MyRecentCallActivityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MyRecentCallActivityRequest)
                        return object;
                    var message = new $root.infinitusai.be.MyRecentCallActivityRequest();
                    if (object.startDate != null) {
                        if (typeof object.startDate !== "object")
                            throw TypeError(".infinitusai.be.MyRecentCallActivityRequest.startDate: object expected");
                        message.startDate = $root.infinitusai.be.Date.fromObject(object.startDate);
                    }
                    if (object.endDate != null) {
                        if (typeof object.endDate !== "object")
                            throw TypeError(".infinitusai.be.MyRecentCallActivityRequest.endDate: object expected");
                        message.endDate = $root.infinitusai.be.Date.fromObject(object.endDate);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MyRecentCallActivityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {infinitusai.be.MyRecentCallActivityRequest} message MyRecentCallActivityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MyRecentCallActivityRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.startDate = null;
                        object.endDate = null;
                    }
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        object.startDate = $root.infinitusai.be.Date.toObject(message.startDate, options);
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        object.endDate = $root.infinitusai.be.Date.toObject(message.endDate, options);
                    return object;
                };
    
                /**
                 * Converts this MyRecentCallActivityRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MyRecentCallActivityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MyRecentCallActivityRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MyRecentCallActivityRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MyRecentCallActivityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MyRecentCallActivityRequest";
                };
    
                return MyRecentCallActivityRequest;
            })();
    
            be.MyRecentCallActivityResponse = (function() {
    
                /**
                 * Properties of a MyRecentCallActivityResponse.
                 * @memberof infinitusai.be
                 * @interface IMyRecentCallActivityResponse
                 * @property {Array.<infinitusai.be.IRecentCallEventActivity>|null} [recentCallEventActivity] MyRecentCallActivityResponse recentCallEventActivity
                 * @property {number|Long|null} [cacheTimestampMs] MyRecentCallActivityResponse cacheTimestampMs
                 */
    
                /**
                 * Constructs a new MyRecentCallActivityResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MyRecentCallActivityResponse.
                 * @implements IMyRecentCallActivityResponse
                 * @constructor
                 * @param {infinitusai.be.IMyRecentCallActivityResponse=} [properties] Properties to set
                 */
                function MyRecentCallActivityResponse(properties) {
                    this.recentCallEventActivity = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MyRecentCallActivityResponse recentCallEventActivity.
                 * @member {Array.<infinitusai.be.IRecentCallEventActivity>} recentCallEventActivity
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @instance
                 */
                MyRecentCallActivityResponse.prototype.recentCallEventActivity = $util.emptyArray;
    
                /**
                 * MyRecentCallActivityResponse cacheTimestampMs.
                 * @member {number|Long} cacheTimestampMs
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @instance
                 */
                MyRecentCallActivityResponse.prototype.cacheTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new MyRecentCallActivityResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.MyRecentCallActivityResponse} MyRecentCallActivityResponse instance
                 */
                MyRecentCallActivityResponse.create = function create(properties) {
                    return new MyRecentCallActivityResponse(properties);
                };
    
                /**
                 * Encodes the specified MyRecentCallActivityResponse message. Does not implicitly {@link infinitusai.be.MyRecentCallActivityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityResponse} message MyRecentCallActivityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MyRecentCallActivityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.recentCallEventActivity != null && message.recentCallEventActivity.length)
                        for (var i = 0; i < message.recentCallEventActivity.length; ++i)
                            $root.infinitusai.be.RecentCallEventActivity.encode(message.recentCallEventActivity[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.cacheTimestampMs != null && Object.hasOwnProperty.call(message, "cacheTimestampMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cacheTimestampMs);
                    return writer;
                };
    
                /**
                 * Encodes the specified MyRecentCallActivityResponse message, length delimited. Does not implicitly {@link infinitusai.be.MyRecentCallActivityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityResponse} message MyRecentCallActivityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MyRecentCallActivityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MyRecentCallActivityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MyRecentCallActivityResponse} MyRecentCallActivityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MyRecentCallActivityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MyRecentCallActivityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.recentCallEventActivity && message.recentCallEventActivity.length))
                                    message.recentCallEventActivity = [];
                                message.recentCallEventActivity.push($root.infinitusai.be.RecentCallEventActivity.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.cacheTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MyRecentCallActivityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MyRecentCallActivityResponse} MyRecentCallActivityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MyRecentCallActivityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MyRecentCallActivityResponse message.
                 * @function verify
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MyRecentCallActivityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recentCallEventActivity != null && message.hasOwnProperty("recentCallEventActivity")) {
                        if (!Array.isArray(message.recentCallEventActivity))
                            return "recentCallEventActivity: array expected";
                        for (var i = 0; i < message.recentCallEventActivity.length; ++i) {
                            var error = $root.infinitusai.be.RecentCallEventActivity.verify(message.recentCallEventActivity[i]);
                            if (error)
                                return "recentCallEventActivity." + error;
                        }
                    }
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (!$util.isInteger(message.cacheTimestampMs) && !(message.cacheTimestampMs && $util.isInteger(message.cacheTimestampMs.low) && $util.isInteger(message.cacheTimestampMs.high)))
                            return "cacheTimestampMs: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a MyRecentCallActivityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MyRecentCallActivityResponse} MyRecentCallActivityResponse
                 */
                MyRecentCallActivityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MyRecentCallActivityResponse)
                        return object;
                    var message = new $root.infinitusai.be.MyRecentCallActivityResponse();
                    if (object.recentCallEventActivity) {
                        if (!Array.isArray(object.recentCallEventActivity))
                            throw TypeError(".infinitusai.be.MyRecentCallActivityResponse.recentCallEventActivity: array expected");
                        message.recentCallEventActivity = [];
                        for (var i = 0; i < object.recentCallEventActivity.length; ++i) {
                            if (typeof object.recentCallEventActivity[i] !== "object")
                                throw TypeError(".infinitusai.be.MyRecentCallActivityResponse.recentCallEventActivity: object expected");
                            message.recentCallEventActivity[i] = $root.infinitusai.be.RecentCallEventActivity.fromObject(object.recentCallEventActivity[i]);
                        }
                    }
                    if (object.cacheTimestampMs != null)
                        if ($util.Long)
                            (message.cacheTimestampMs = $util.Long.fromValue(object.cacheTimestampMs)).unsigned = false;
                        else if (typeof object.cacheTimestampMs === "string")
                            message.cacheTimestampMs = parseInt(object.cacheTimestampMs, 10);
                        else if (typeof object.cacheTimestampMs === "number")
                            message.cacheTimestampMs = object.cacheTimestampMs;
                        else if (typeof object.cacheTimestampMs === "object")
                            message.cacheTimestampMs = new $util.LongBits(object.cacheTimestampMs.low >>> 0, object.cacheTimestampMs.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a MyRecentCallActivityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {infinitusai.be.MyRecentCallActivityResponse} message MyRecentCallActivityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MyRecentCallActivityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.recentCallEventActivity = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.cacheTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cacheTimestampMs = options.longs === String ? "0" : 0;
                    if (message.recentCallEventActivity && message.recentCallEventActivity.length) {
                        object.recentCallEventActivity = [];
                        for (var j = 0; j < message.recentCallEventActivity.length; ++j)
                            object.recentCallEventActivity[j] = $root.infinitusai.be.RecentCallEventActivity.toObject(message.recentCallEventActivity[j], options);
                    }
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (typeof message.cacheTimestampMs === "number")
                            object.cacheTimestampMs = options.longs === String ? String(message.cacheTimestampMs) : message.cacheTimestampMs;
                        else
                            object.cacheTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.cacheTimestampMs) : options.longs === Number ? new $util.LongBits(message.cacheTimestampMs.low >>> 0, message.cacheTimestampMs.high >>> 0).toNumber() : message.cacheTimestampMs;
                    return object;
                };
    
                /**
                 * Converts this MyRecentCallActivityResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MyRecentCallActivityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MyRecentCallActivityResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MyRecentCallActivityResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MyRecentCallActivityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MyRecentCallActivityResponse";
                };
    
                return MyRecentCallActivityResponse;
            })();
    
            be.MyRecentCallActivityRows = (function() {
    
                /**
                 * Properties of a MyRecentCallActivityRows.
                 * @memberof infinitusai.be
                 * @interface IMyRecentCallActivityRows
                 * @property {Array.<infinitusai.be.IRecentCallEventActivity>|null} [recentCallEventActivity] MyRecentCallActivityRows recentCallEventActivity
                 */
    
                /**
                 * Constructs a new MyRecentCallActivityRows.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MyRecentCallActivityRows.
                 * @implements IMyRecentCallActivityRows
                 * @constructor
                 * @param {infinitusai.be.IMyRecentCallActivityRows=} [properties] Properties to set
                 */
                function MyRecentCallActivityRows(properties) {
                    this.recentCallEventActivity = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MyRecentCallActivityRows recentCallEventActivity.
                 * @member {Array.<infinitusai.be.IRecentCallEventActivity>} recentCallEventActivity
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @instance
                 */
                MyRecentCallActivityRows.prototype.recentCallEventActivity = $util.emptyArray;
    
                /**
                 * Creates a new MyRecentCallActivityRows instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityRows=} [properties] Properties to set
                 * @returns {infinitusai.be.MyRecentCallActivityRows} MyRecentCallActivityRows instance
                 */
                MyRecentCallActivityRows.create = function create(properties) {
                    return new MyRecentCallActivityRows(properties);
                };
    
                /**
                 * Encodes the specified MyRecentCallActivityRows message. Does not implicitly {@link infinitusai.be.MyRecentCallActivityRows.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityRows} message MyRecentCallActivityRows message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MyRecentCallActivityRows.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.recentCallEventActivity != null && message.recentCallEventActivity.length)
                        for (var i = 0; i < message.recentCallEventActivity.length; ++i)
                            $root.infinitusai.be.RecentCallEventActivity.encode(message.recentCallEventActivity[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MyRecentCallActivityRows message, length delimited. Does not implicitly {@link infinitusai.be.MyRecentCallActivityRows.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {infinitusai.be.IMyRecentCallActivityRows} message MyRecentCallActivityRows message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MyRecentCallActivityRows.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MyRecentCallActivityRows message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MyRecentCallActivityRows} MyRecentCallActivityRows
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MyRecentCallActivityRows.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MyRecentCallActivityRows();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.recentCallEventActivity && message.recentCallEventActivity.length))
                                    message.recentCallEventActivity = [];
                                message.recentCallEventActivity.push($root.infinitusai.be.RecentCallEventActivity.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MyRecentCallActivityRows message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MyRecentCallActivityRows} MyRecentCallActivityRows
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MyRecentCallActivityRows.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MyRecentCallActivityRows message.
                 * @function verify
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MyRecentCallActivityRows.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recentCallEventActivity != null && message.hasOwnProperty("recentCallEventActivity")) {
                        if (!Array.isArray(message.recentCallEventActivity))
                            return "recentCallEventActivity: array expected";
                        for (var i = 0; i < message.recentCallEventActivity.length; ++i) {
                            var error = $root.infinitusai.be.RecentCallEventActivity.verify(message.recentCallEventActivity[i]);
                            if (error)
                                return "recentCallEventActivity." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MyRecentCallActivityRows message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MyRecentCallActivityRows} MyRecentCallActivityRows
                 */
                MyRecentCallActivityRows.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MyRecentCallActivityRows)
                        return object;
                    var message = new $root.infinitusai.be.MyRecentCallActivityRows();
                    if (object.recentCallEventActivity) {
                        if (!Array.isArray(object.recentCallEventActivity))
                            throw TypeError(".infinitusai.be.MyRecentCallActivityRows.recentCallEventActivity: array expected");
                        message.recentCallEventActivity = [];
                        for (var i = 0; i < object.recentCallEventActivity.length; ++i) {
                            if (typeof object.recentCallEventActivity[i] !== "object")
                                throw TypeError(".infinitusai.be.MyRecentCallActivityRows.recentCallEventActivity: object expected");
                            message.recentCallEventActivity[i] = $root.infinitusai.be.RecentCallEventActivity.fromObject(object.recentCallEventActivity[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MyRecentCallActivityRows message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {infinitusai.be.MyRecentCallActivityRows} message MyRecentCallActivityRows
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MyRecentCallActivityRows.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.recentCallEventActivity = [];
                    if (message.recentCallEventActivity && message.recentCallEventActivity.length) {
                        object.recentCallEventActivity = [];
                        for (var j = 0; j < message.recentCallEventActivity.length; ++j)
                            object.recentCallEventActivity[j] = $root.infinitusai.be.RecentCallEventActivity.toObject(message.recentCallEventActivity[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MyRecentCallActivityRows to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MyRecentCallActivityRows.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MyRecentCallActivityRows
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MyRecentCallActivityRows
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MyRecentCallActivityRows.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MyRecentCallActivityRows";
                };
    
                return MyRecentCallActivityRows;
            })();
    
            be.SelfRequeueCallStatus = (function() {
    
                /**
                 * Properties of a SelfRequeueCallStatus.
                 * @memberof infinitusai.be
                 * @interface ISelfRequeueCallStatus
                 * @property {infinitusai.be.RequeueReason.Type|null} [requeueReason] SelfRequeueCallStatus requeueReason
                 * @property {string|null} [requeueNotes] SelfRequeueCallStatus requeueNotes
                 */
    
                /**
                 * Constructs a new SelfRequeueCallStatus.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SelfRequeueCallStatus.
                 * @implements ISelfRequeueCallStatus
                 * @constructor
                 * @param {infinitusai.be.ISelfRequeueCallStatus=} [properties] Properties to set
                 */
                function SelfRequeueCallStatus(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SelfRequeueCallStatus requeueReason.
                 * @member {infinitusai.be.RequeueReason.Type} requeueReason
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @instance
                 */
                SelfRequeueCallStatus.prototype.requeueReason = 0;
    
                /**
                 * SelfRequeueCallStatus requeueNotes.
                 * @member {string} requeueNotes
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @instance
                 */
                SelfRequeueCallStatus.prototype.requeueNotes = "";
    
                /**
                 * Creates a new SelfRequeueCallStatus instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {infinitusai.be.ISelfRequeueCallStatus=} [properties] Properties to set
                 * @returns {infinitusai.be.SelfRequeueCallStatus} SelfRequeueCallStatus instance
                 */
                SelfRequeueCallStatus.create = function create(properties) {
                    return new SelfRequeueCallStatus(properties);
                };
    
                /**
                 * Encodes the specified SelfRequeueCallStatus message. Does not implicitly {@link infinitusai.be.SelfRequeueCallStatus.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {infinitusai.be.ISelfRequeueCallStatus} message SelfRequeueCallStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelfRequeueCallStatus.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.requeueReason != null && Object.hasOwnProperty.call(message, "requeueReason"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.requeueReason);
                    if (message.requeueNotes != null && Object.hasOwnProperty.call(message, "requeueNotes"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.requeueNotes);
                    return writer;
                };
    
                /**
                 * Encodes the specified SelfRequeueCallStatus message, length delimited. Does not implicitly {@link infinitusai.be.SelfRequeueCallStatus.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {infinitusai.be.ISelfRequeueCallStatus} message SelfRequeueCallStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelfRequeueCallStatus.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SelfRequeueCallStatus message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SelfRequeueCallStatus} SelfRequeueCallStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelfRequeueCallStatus.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SelfRequeueCallStatus();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.requeueReason = reader.int32();
                                break;
                            }
                        case 2: {
                                message.requeueNotes = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SelfRequeueCallStatus message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SelfRequeueCallStatus} SelfRequeueCallStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelfRequeueCallStatus.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SelfRequeueCallStatus message.
                 * @function verify
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SelfRequeueCallStatus.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.requeueReason != null && message.hasOwnProperty("requeueReason"))
                        switch (message.requeueReason) {
                        default:
                            return "requeueReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 29:
                        case 21:
                        case 30:
                        case 22:
                        case 23:
                        case 24:
                        case 32:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 31:
                        case 33:
                        case 34:
                        case 42:
                        case 43:
                        case 45:
                        case 46:
                        case 47:
                        case 52:
                        case 53:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 99:
                        case 35:
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 48:
                        case 49:
                        case 50:
                        case 51:
                        case 55:
                        case 56:
                        case 57:
                        case 58:
                        case 59:
                        case 44:
                        case 60:
                            break;
                        }
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        if (!$util.isString(message.requeueNotes))
                            return "requeueNotes: string expected";
                    return null;
                };
    
                /**
                 * Creates a SelfRequeueCallStatus message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SelfRequeueCallStatus} SelfRequeueCallStatus
                 */
                SelfRequeueCallStatus.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SelfRequeueCallStatus)
                        return object;
                    var message = new $root.infinitusai.be.SelfRequeueCallStatus();
                    switch (object.requeueReason) {
                    default:
                        if (typeof object.requeueReason === "number") {
                            message.requeueReason = object.requeueReason;
                            break;
                        }
                        break;
                    case "REASON_UNKNOWN":
                    case 0:
                        message.requeueReason = 0;
                        break;
                    case "REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 1:
                        message.requeueReason = 1;
                        break;
                    case "REASON_AGENT_DID_NOT_PROVIDE_INFO":
                    case 2:
                        message.requeueReason = 2;
                        break;
                    case "REASON_AGENT_PROVIDED_UNRELIABLE_DATA":
                    case 3:
                        message.requeueReason = 3;
                        break;
                    case "REASON_AGENT_REQUESTED_UNAVAILABLE_INFO":
                    case 4:
                        message.requeueReason = 4;
                        break;
                    case "REASON_AGENT_TOO_SLOW":
                    case 5:
                        message.requeueReason = 5;
                        break;
                    case "REASON_OPERATOR_ERROR":
                    case 6:
                        message.requeueReason = 6;
                        break;
                    case "REASON_SYSTEM_ISSUE":
                    case 7:
                        message.requeueReason = 7;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER":
                    case 8:
                        message.requeueReason = 8;
                        break;
                    case "REASON_GATHERED_PIN_FOR_MEDICAL":
                    case 9:
                        message.requeueReason = 9;
                        break;
                    case "REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT":
                    case 10:
                        message.requeueReason = 10;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_BV":
                    case 11:
                        message.requeueReason = 11;
                        break;
                    case "REASON_PAYER_SYSTEM_ISSUE":
                    case 12:
                        message.requeueReason = 12;
                        break;
                    case "REASON_PAYER_UNREACHABLE":
                    case 29:
                        message.requeueReason = 29;
                        break;
                    case "REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY":
                    case 21:
                        message.requeueReason = 21;
                        break;
                    case "REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA":
                    case 30:
                        message.requeueReason = 30;
                        break;
                    case "REASON_MISSING_INPUT_INFORMATION":
                    case 22:
                        message.requeueReason = 22;
                        break;
                    case "REASON_IVR_NAVIGATION_FAILURE":
                    case 23:
                        message.requeueReason = 23;
                        break;
                    case "REASON_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 24:
                        message.requeueReason = 24;
                        break;
                    case "REASON_AGENT_AUTOMATION_HANGUP":
                    case 32:
                        message.requeueReason = 32;
                        break;
                    case "REASON_PIE_ESCALATION_NEEDED":
                    case 25:
                        message.requeueReason = 25;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER_PLAN":
                    case 26:
                        message.requeueReason = 26;
                        break;
                    case "REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED":
                    case 27:
                        message.requeueReason = 27;
                        break;
                    case "REASON_INCORRECT_INPUT_INFORMATION":
                    case 28:
                        message.requeueReason = 28;
                        break;
                    case "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD":
                    case 31:
                        message.requeueReason = 31;
                        break;
                    case "REASON_INCORRECT_PROMPT_SELECTED":
                    case 33:
                        message.requeueReason = 33;
                        break;
                    case "REASON_QUALITY_CONCERNS":
                    case 34:
                        message.requeueReason = 34;
                        break;
                    case "REASON_PROVIDER_IS_INACTIVE":
                    case 42:
                        message.requeueReason = 42;
                        break;
                    case "REASON_NO_OPERATOR_AVAILABLE":
                    case 43:
                        message.requeueReason = 43;
                        break;
                    case "REASON_NO_BENEFITS_UNDER_THIS_PLAN":
                    case 45:
                        message.requeueReason = 45;
                        break;
                    case "REASON_UNABLE_TO_REVERIFY_BENEFITS":
                    case 46:
                        message.requeueReason = 46;
                        break;
                    case "REASON_LONG_HOLD_TIME":
                    case 47:
                        message.requeueReason = 47;
                        break;
                    case "REASON_HOLD_NAVIGATION_FAILURE":
                    case 52:
                        message.requeueReason = 52;
                        break;
                    case "REASON_IVR_TIMEOUT":
                    case 53:
                        message.requeueReason = 53;
                        break;
                    case "REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK":
                    case 13:
                        message.requeueReason = 13;
                        break;
                    case "REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS":
                    case 14:
                        message.requeueReason = 14;
                        break;
                    case "REASON_INCOMPLETE_ANSWER_TO_QUESTIONS":
                    case 15:
                        message.requeueReason = 15;
                        break;
                    case "REASON_INVALID_ANSWERS_TO_QUESTIONS":
                    case 16:
                        message.requeueReason = 16;
                        break;
                    case "REASON_UNANSWERED_QUESTIONS_REMAINING":
                    case 17:
                        message.requeueReason = 17;
                        break;
                    case "REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED":
                    case 18:
                        message.requeueReason = 18;
                        break;
                    case "REASON_BOTH_IN_AND_OON_NOT_COLLECTED":
                    case 19:
                        message.requeueReason = 19;
                        break;
                    case "REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS":
                    case 20:
                        message.requeueReason = 20;
                        break;
                    case "REASON_OTHER":
                    case 99:
                        message.requeueReason = 99;
                        break;
                    case "REASON_NLP_PLAN_TERMINATED":
                    case 35:
                        message.requeueReason = 35;
                        break;
                    case "REASON_NLP_PAYER_UNREACHABLE":
                    case 36:
                        message.requeueReason = 36;
                        break;
                    case "REASON_NLP_ERROR":
                    case 37:
                        message.requeueReason = 37;
                        break;
                    case "REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 38:
                        message.requeueReason = 38;
                        break;
                    case "REASON_NLP_READY_FOR_REVIEW":
                    case 39:
                        message.requeueReason = 39;
                        break;
                    case "REASON_NLP_PAYER_SYSTEM_ISSUE":
                    case 40:
                        message.requeueReason = 40;
                        break;
                    case "REASON_NLP_CALL_NEW_NUMBER":
                    case 41:
                        message.requeueReason = 41;
                        break;
                    case "REASON_NLP_BAD_DATA":
                    case 48:
                        message.requeueReason = 48;
                        break;
                    case "REASON_NLP_AGENT_CANT_FIND_MEMBER":
                    case 49:
                        message.requeueReason = 49;
                        break;
                    case "REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 50:
                        message.requeueReason = 50;
                        break;
                    case "REASON_NLP_INPUT_NOT_RECOGNIZED":
                    case 51:
                        message.requeueReason = 51;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK":
                    case 55:
                        message.requeueReason = 55;
                        break;
                    case "REASON_NEED_TO_CALL_TPA":
                    case 56:
                        message.requeueReason = 56;
                        break;
                    case "REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT":
                    case 57:
                        message.requeueReason = 57;
                        break;
                    case "REASON_IVR_WRONG_DEPARTMENT":
                    case 58:
                        message.requeueReason = 58;
                        break;
                    case "REASON_AGENT_ISSUE":
                    case 59:
                        message.requeueReason = 59;
                        break;
                    case "REASON_NONE":
                    case 44:
                        message.requeueReason = 44;
                        break;
                    case "REASON_CHAINED_TASK":
                    case 60:
                        message.requeueReason = 60;
                        break;
                    }
                    if (object.requeueNotes != null)
                        message.requeueNotes = String(object.requeueNotes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SelfRequeueCallStatus message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {infinitusai.be.SelfRequeueCallStatus} message SelfRequeueCallStatus
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SelfRequeueCallStatus.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.requeueReason = options.enums === String ? "REASON_UNKNOWN" : 0;
                        object.requeueNotes = "";
                    }
                    if (message.requeueReason != null && message.hasOwnProperty("requeueReason"))
                        object.requeueReason = options.enums === String ? $root.infinitusai.be.RequeueReason.Type[message.requeueReason] === undefined ? message.requeueReason : $root.infinitusai.be.RequeueReason.Type[message.requeueReason] : message.requeueReason;
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        object.requeueNotes = message.requeueNotes;
                    return object;
                };
    
                /**
                 * Converts this SelfRequeueCallStatus to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SelfRequeueCallStatus.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SelfRequeueCallStatus
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SelfRequeueCallStatus
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SelfRequeueCallStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SelfRequeueCallStatus";
                };
    
                return SelfRequeueCallStatus;
            })();
    
            be.ReviewerRequeueCallStatus = (function() {
    
                /**
                 * Properties of a ReviewerRequeueCallStatus.
                 * @memberof infinitusai.be
                 * @interface IReviewerRequeueCallStatus
                 * @property {infinitusai.be.RequeueReason.Type|null} [requeueReason] ReviewerRequeueCallStatus requeueReason
                 * @property {string|null} [requeueNotes] ReviewerRequeueCallStatus requeueNotes
                 * @property {string|null} [reviewerEmail] ReviewerRequeueCallStatus reviewerEmail
                 */
    
                /**
                 * Constructs a new ReviewerRequeueCallStatus.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReviewerRequeueCallStatus.
                 * @implements IReviewerRequeueCallStatus
                 * @constructor
                 * @param {infinitusai.be.IReviewerRequeueCallStatus=} [properties] Properties to set
                 */
                function ReviewerRequeueCallStatus(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ReviewerRequeueCallStatus requeueReason.
                 * @member {infinitusai.be.RequeueReason.Type} requeueReason
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @instance
                 */
                ReviewerRequeueCallStatus.prototype.requeueReason = 0;
    
                /**
                 * ReviewerRequeueCallStatus requeueNotes.
                 * @member {string} requeueNotes
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @instance
                 */
                ReviewerRequeueCallStatus.prototype.requeueNotes = "";
    
                /**
                 * ReviewerRequeueCallStatus reviewerEmail.
                 * @member {string} reviewerEmail
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @instance
                 */
                ReviewerRequeueCallStatus.prototype.reviewerEmail = "";
    
                /**
                 * Creates a new ReviewerRequeueCallStatus instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {infinitusai.be.IReviewerRequeueCallStatus=} [properties] Properties to set
                 * @returns {infinitusai.be.ReviewerRequeueCallStatus} ReviewerRequeueCallStatus instance
                 */
                ReviewerRequeueCallStatus.create = function create(properties) {
                    return new ReviewerRequeueCallStatus(properties);
                };
    
                /**
                 * Encodes the specified ReviewerRequeueCallStatus message. Does not implicitly {@link infinitusai.be.ReviewerRequeueCallStatus.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {infinitusai.be.IReviewerRequeueCallStatus} message ReviewerRequeueCallStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReviewerRequeueCallStatus.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.requeueReason != null && Object.hasOwnProperty.call(message, "requeueReason"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.requeueReason);
                    if (message.requeueNotes != null && Object.hasOwnProperty.call(message, "requeueNotes"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.requeueNotes);
                    if (message.reviewerEmail != null && Object.hasOwnProperty.call(message, "reviewerEmail"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.reviewerEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified ReviewerRequeueCallStatus message, length delimited. Does not implicitly {@link infinitusai.be.ReviewerRequeueCallStatus.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {infinitusai.be.IReviewerRequeueCallStatus} message ReviewerRequeueCallStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReviewerRequeueCallStatus.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReviewerRequeueCallStatus message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReviewerRequeueCallStatus} ReviewerRequeueCallStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReviewerRequeueCallStatus.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReviewerRequeueCallStatus();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.requeueReason = reader.int32();
                                break;
                            }
                        case 2: {
                                message.requeueNotes = reader.string();
                                break;
                            }
                        case 3: {
                                message.reviewerEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReviewerRequeueCallStatus message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReviewerRequeueCallStatus} ReviewerRequeueCallStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReviewerRequeueCallStatus.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReviewerRequeueCallStatus message.
                 * @function verify
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReviewerRequeueCallStatus.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.requeueReason != null && message.hasOwnProperty("requeueReason"))
                        switch (message.requeueReason) {
                        default:
                            return "requeueReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 29:
                        case 21:
                        case 30:
                        case 22:
                        case 23:
                        case 24:
                        case 32:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 31:
                        case 33:
                        case 34:
                        case 42:
                        case 43:
                        case 45:
                        case 46:
                        case 47:
                        case 52:
                        case 53:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 99:
                        case 35:
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 48:
                        case 49:
                        case 50:
                        case 51:
                        case 55:
                        case 56:
                        case 57:
                        case 58:
                        case 59:
                        case 44:
                        case 60:
                            break;
                        }
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        if (!$util.isString(message.requeueNotes))
                            return "requeueNotes: string expected";
                    if (message.reviewerEmail != null && message.hasOwnProperty("reviewerEmail"))
                        if (!$util.isString(message.reviewerEmail))
                            return "reviewerEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a ReviewerRequeueCallStatus message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReviewerRequeueCallStatus} ReviewerRequeueCallStatus
                 */
                ReviewerRequeueCallStatus.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReviewerRequeueCallStatus)
                        return object;
                    var message = new $root.infinitusai.be.ReviewerRequeueCallStatus();
                    switch (object.requeueReason) {
                    default:
                        if (typeof object.requeueReason === "number") {
                            message.requeueReason = object.requeueReason;
                            break;
                        }
                        break;
                    case "REASON_UNKNOWN":
                    case 0:
                        message.requeueReason = 0;
                        break;
                    case "REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 1:
                        message.requeueReason = 1;
                        break;
                    case "REASON_AGENT_DID_NOT_PROVIDE_INFO":
                    case 2:
                        message.requeueReason = 2;
                        break;
                    case "REASON_AGENT_PROVIDED_UNRELIABLE_DATA":
                    case 3:
                        message.requeueReason = 3;
                        break;
                    case "REASON_AGENT_REQUESTED_UNAVAILABLE_INFO":
                    case 4:
                        message.requeueReason = 4;
                        break;
                    case "REASON_AGENT_TOO_SLOW":
                    case 5:
                        message.requeueReason = 5;
                        break;
                    case "REASON_OPERATOR_ERROR":
                    case 6:
                        message.requeueReason = 6;
                        break;
                    case "REASON_SYSTEM_ISSUE":
                    case 7:
                        message.requeueReason = 7;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER":
                    case 8:
                        message.requeueReason = 8;
                        break;
                    case "REASON_GATHERED_PIN_FOR_MEDICAL":
                    case 9:
                        message.requeueReason = 9;
                        break;
                    case "REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT":
                    case 10:
                        message.requeueReason = 10;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_BV":
                    case 11:
                        message.requeueReason = 11;
                        break;
                    case "REASON_PAYER_SYSTEM_ISSUE":
                    case 12:
                        message.requeueReason = 12;
                        break;
                    case "REASON_PAYER_UNREACHABLE":
                    case 29:
                        message.requeueReason = 29;
                        break;
                    case "REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY":
                    case 21:
                        message.requeueReason = 21;
                        break;
                    case "REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA":
                    case 30:
                        message.requeueReason = 30;
                        break;
                    case "REASON_MISSING_INPUT_INFORMATION":
                    case 22:
                        message.requeueReason = 22;
                        break;
                    case "REASON_IVR_NAVIGATION_FAILURE":
                    case 23:
                        message.requeueReason = 23;
                        break;
                    case "REASON_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 24:
                        message.requeueReason = 24;
                        break;
                    case "REASON_AGENT_AUTOMATION_HANGUP":
                    case 32:
                        message.requeueReason = 32;
                        break;
                    case "REASON_PIE_ESCALATION_NEEDED":
                    case 25:
                        message.requeueReason = 25;
                        break;
                    case "REASON_AGENT_CANT_FIND_MEMBER_PLAN":
                    case 26:
                        message.requeueReason = 26;
                        break;
                    case "REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED":
                    case 27:
                        message.requeueReason = 27;
                        break;
                    case "REASON_INCORRECT_INPUT_INFORMATION":
                    case 28:
                        message.requeueReason = 28;
                        break;
                    case "REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD":
                    case 31:
                        message.requeueReason = 31;
                        break;
                    case "REASON_INCORRECT_PROMPT_SELECTED":
                    case 33:
                        message.requeueReason = 33;
                        break;
                    case "REASON_QUALITY_CONCERNS":
                    case 34:
                        message.requeueReason = 34;
                        break;
                    case "REASON_PROVIDER_IS_INACTIVE":
                    case 42:
                        message.requeueReason = 42;
                        break;
                    case "REASON_NO_OPERATOR_AVAILABLE":
                    case 43:
                        message.requeueReason = 43;
                        break;
                    case "REASON_NO_BENEFITS_UNDER_THIS_PLAN":
                    case 45:
                        message.requeueReason = 45;
                        break;
                    case "REASON_UNABLE_TO_REVERIFY_BENEFITS":
                    case 46:
                        message.requeueReason = 46;
                        break;
                    case "REASON_LONG_HOLD_TIME":
                    case 47:
                        message.requeueReason = 47;
                        break;
                    case "REASON_HOLD_NAVIGATION_FAILURE":
                    case 52:
                        message.requeueReason = 52;
                        break;
                    case "REASON_IVR_TIMEOUT":
                    case 53:
                        message.requeueReason = 53;
                        break;
                    case "REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK":
                    case 13:
                        message.requeueReason = 13;
                        break;
                    case "REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS":
                    case 14:
                        message.requeueReason = 14;
                        break;
                    case "REASON_INCOMPLETE_ANSWER_TO_QUESTIONS":
                    case 15:
                        message.requeueReason = 15;
                        break;
                    case "REASON_INVALID_ANSWERS_TO_QUESTIONS":
                    case 16:
                        message.requeueReason = 16;
                        break;
                    case "REASON_UNANSWERED_QUESTIONS_REMAINING":
                    case 17:
                        message.requeueReason = 17;
                        break;
                    case "REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED":
                    case 18:
                        message.requeueReason = 18;
                        break;
                    case "REASON_BOTH_IN_AND_OON_NOT_COLLECTED":
                    case 19:
                        message.requeueReason = 19;
                        break;
                    case "REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS":
                    case 20:
                        message.requeueReason = 20;
                        break;
                    case "REASON_OTHER":
                    case 99:
                        message.requeueReason = 99;
                        break;
                    case "REASON_NLP_PLAN_TERMINATED":
                    case 35:
                        message.requeueReason = 35;
                        break;
                    case "REASON_NLP_PAYER_UNREACHABLE":
                    case 36:
                        message.requeueReason = 36;
                        break;
                    case "REASON_NLP_ERROR":
                    case 37:
                        message.requeueReason = 37;
                        break;
                    case "REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA":
                    case 38:
                        message.requeueReason = 38;
                        break;
                    case "REASON_NLP_READY_FOR_REVIEW":
                    case 39:
                        message.requeueReason = 39;
                        break;
                    case "REASON_NLP_PAYER_SYSTEM_ISSUE":
                    case 40:
                        message.requeueReason = 40;
                        break;
                    case "REASON_NLP_CALL_NEW_NUMBER":
                    case 41:
                        message.requeueReason = 41;
                        break;
                    case "REASON_NLP_BAD_DATA":
                    case 48:
                        message.requeueReason = 48;
                        break;
                    case "REASON_NLP_AGENT_CANT_FIND_MEMBER":
                    case 49:
                        message.requeueReason = 49;
                        break;
                    case "REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP":
                    case 50:
                        message.requeueReason = 50;
                        break;
                    case "REASON_NLP_INPUT_NOT_RECOGNIZED":
                    case 51:
                        message.requeueReason = 51;
                        break;
                    case "REASON_PROVIDED_CORRECT_NUMBER_FOR_FASTTRACK":
                    case 55:
                        message.requeueReason = 55;
                        break;
                    case "REASON_NEED_TO_CALL_TPA":
                    case 56:
                        message.requeueReason = 56;
                        break;
                    case "REASON_NEED_TO_CALL_ANOTHER_DEPARTMENT":
                    case 57:
                        message.requeueReason = 57;
                        break;
                    case "REASON_IVR_WRONG_DEPARTMENT":
                    case 58:
                        message.requeueReason = 58;
                        break;
                    case "REASON_AGENT_ISSUE":
                    case 59:
                        message.requeueReason = 59;
                        break;
                    case "REASON_NONE":
                    case 44:
                        message.requeueReason = 44;
                        break;
                    case "REASON_CHAINED_TASK":
                    case 60:
                        message.requeueReason = 60;
                        break;
                    }
                    if (object.requeueNotes != null)
                        message.requeueNotes = String(object.requeueNotes);
                    if (object.reviewerEmail != null)
                        message.reviewerEmail = String(object.reviewerEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ReviewerRequeueCallStatus message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {infinitusai.be.ReviewerRequeueCallStatus} message ReviewerRequeueCallStatus
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReviewerRequeueCallStatus.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.requeueReason = options.enums === String ? "REASON_UNKNOWN" : 0;
                        object.requeueNotes = "";
                        object.reviewerEmail = "";
                    }
                    if (message.requeueReason != null && message.hasOwnProperty("requeueReason"))
                        object.requeueReason = options.enums === String ? $root.infinitusai.be.RequeueReason.Type[message.requeueReason] === undefined ? message.requeueReason : $root.infinitusai.be.RequeueReason.Type[message.requeueReason] : message.requeueReason;
                    if (message.requeueNotes != null && message.hasOwnProperty("requeueNotes"))
                        object.requeueNotes = message.requeueNotes;
                    if (message.reviewerEmail != null && message.hasOwnProperty("reviewerEmail"))
                        object.reviewerEmail = message.reviewerEmail;
                    return object;
                };
    
                /**
                 * Converts this ReviewerRequeueCallStatus to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReviewerRequeueCallStatus.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReviewerRequeueCallStatus
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReviewerRequeueCallStatus
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReviewerRequeueCallStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReviewerRequeueCallStatus";
                };
    
                return ReviewerRequeueCallStatus;
            })();
    
            be.NoCallStatusMetadata = (function() {
    
                /**
                 * Properties of a NoCallStatusMetadata.
                 * @memberof infinitusai.be
                 * @interface INoCallStatusMetadata
                 */
    
                /**
                 * Constructs a new NoCallStatusMetadata.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NoCallStatusMetadata.
                 * @implements INoCallStatusMetadata
                 * @constructor
                 * @param {infinitusai.be.INoCallStatusMetadata=} [properties] Properties to set
                 */
                function NoCallStatusMetadata(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new NoCallStatusMetadata instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {infinitusai.be.INoCallStatusMetadata=} [properties] Properties to set
                 * @returns {infinitusai.be.NoCallStatusMetadata} NoCallStatusMetadata instance
                 */
                NoCallStatusMetadata.create = function create(properties) {
                    return new NoCallStatusMetadata(properties);
                };
    
                /**
                 * Encodes the specified NoCallStatusMetadata message. Does not implicitly {@link infinitusai.be.NoCallStatusMetadata.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {infinitusai.be.INoCallStatusMetadata} message NoCallStatusMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoCallStatusMetadata.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified NoCallStatusMetadata message, length delimited. Does not implicitly {@link infinitusai.be.NoCallStatusMetadata.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {infinitusai.be.INoCallStatusMetadata} message NoCallStatusMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoCallStatusMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NoCallStatusMetadata message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NoCallStatusMetadata} NoCallStatusMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoCallStatusMetadata.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NoCallStatusMetadata();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NoCallStatusMetadata message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NoCallStatusMetadata} NoCallStatusMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoCallStatusMetadata.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NoCallStatusMetadata message.
                 * @function verify
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NoCallStatusMetadata.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a NoCallStatusMetadata message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NoCallStatusMetadata} NoCallStatusMetadata
                 */
                NoCallStatusMetadata.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NoCallStatusMetadata)
                        return object;
                    return new $root.infinitusai.be.NoCallStatusMetadata();
                };
    
                /**
                 * Creates a plain object from a NoCallStatusMetadata message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {infinitusai.be.NoCallStatusMetadata} message NoCallStatusMetadata
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoCallStatusMetadata.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this NoCallStatusMetadata to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoCallStatusMetadata.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NoCallStatusMetadata
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NoCallStatusMetadata
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoCallStatusMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NoCallStatusMetadata";
                };
    
                return NoCallStatusMetadata;
            })();
    
            be.RecentCallEventActivity = (function() {
    
                /**
                 * Properties of a RecentCallEventActivity.
                 * @memberof infinitusai.be
                 * @interface IRecentCallEventActivity
                 * @property {string|null} [orgName] RecentCallEventActivity orgName
                 * @property {string|null} [orgUuid] RecentCallEventActivity orgUuid
                 * @property {string|null} [taskUuid] RecentCallEventActivity taskUuid
                 * @property {string|null} [callUuid] RecentCallEventActivity callUuid
                 * @property {string|null} [operatorEmail] RecentCallEventActivity operatorEmail
                 * @property {infinitusai.be.CallStatus|null} [callStatus] RecentCallEventActivity callStatus
                 * @property {number|Long|null} [timestampMs] RecentCallEventActivity timestampMs
                 * @property {infinitusai.be.INoCallStatusMetadata|null} [noCallStatusMetadata] RecentCallEventActivity noCallStatusMetadata
                 * @property {infinitusai.be.ISelfRequeueCallStatus|null} [selfRequeueCallStatus] RecentCallEventActivity selfRequeueCallStatus
                 * @property {infinitusai.be.IReviewerRequeueCallStatus|null} [reviewerRequeueCallStatus] RecentCallEventActivity reviewerRequeueCallStatus
                 */
    
                /**
                 * Constructs a new RecentCallEventActivity.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RecentCallEventActivity.
                 * @implements IRecentCallEventActivity
                 * @constructor
                 * @param {infinitusai.be.IRecentCallEventActivity=} [properties] Properties to set
                 */
                function RecentCallEventActivity(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RecentCallEventActivity orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.orgName = "";
    
                /**
                 * RecentCallEventActivity orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.orgUuid = "";
    
                /**
                 * RecentCallEventActivity taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.taskUuid = "";
    
                /**
                 * RecentCallEventActivity callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.callUuid = "";
    
                /**
                 * RecentCallEventActivity operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.operatorEmail = "";
    
                /**
                 * RecentCallEventActivity callStatus.
                 * @member {infinitusai.be.CallStatus} callStatus
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.callStatus = 0;
    
                /**
                 * RecentCallEventActivity timestampMs.
                 * @member {number|Long} timestampMs
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.timestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * RecentCallEventActivity noCallStatusMetadata.
                 * @member {infinitusai.be.INoCallStatusMetadata|null|undefined} noCallStatusMetadata
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.noCallStatusMetadata = null;
    
                /**
                 * RecentCallEventActivity selfRequeueCallStatus.
                 * @member {infinitusai.be.ISelfRequeueCallStatus|null|undefined} selfRequeueCallStatus
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.selfRequeueCallStatus = null;
    
                /**
                 * RecentCallEventActivity reviewerRequeueCallStatus.
                 * @member {infinitusai.be.IReviewerRequeueCallStatus|null|undefined} reviewerRequeueCallStatus
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                RecentCallEventActivity.prototype.reviewerRequeueCallStatus = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * RecentCallEventActivity callStatusMetadata.
                 * @member {"noCallStatusMetadata"|"selfRequeueCallStatus"|"reviewerRequeueCallStatus"|undefined} callStatusMetadata
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 */
                Object.defineProperty(RecentCallEventActivity.prototype, "callStatusMetadata", {
                    get: $util.oneOfGetter($oneOfFields = ["noCallStatusMetadata", "selfRequeueCallStatus", "reviewerRequeueCallStatus"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new RecentCallEventActivity instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {infinitusai.be.IRecentCallEventActivity=} [properties] Properties to set
                 * @returns {infinitusai.be.RecentCallEventActivity} RecentCallEventActivity instance
                 */
                RecentCallEventActivity.create = function create(properties) {
                    return new RecentCallEventActivity(properties);
                };
    
                /**
                 * Encodes the specified RecentCallEventActivity message. Does not implicitly {@link infinitusai.be.RecentCallEventActivity.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {infinitusai.be.IRecentCallEventActivity} message RecentCallEventActivity message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecentCallEventActivity.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgName);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.callUuid);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.operatorEmail);
                    if (message.callStatus != null && Object.hasOwnProperty.call(message, "callStatus"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.callStatus);
                    if (message.timestampMs != null && Object.hasOwnProperty.call(message, "timestampMs"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.timestampMs);
                    if (message.noCallStatusMetadata != null && Object.hasOwnProperty.call(message, "noCallStatusMetadata"))
                        $root.infinitusai.be.NoCallStatusMetadata.encode(message.noCallStatusMetadata, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.selfRequeueCallStatus != null && Object.hasOwnProperty.call(message, "selfRequeueCallStatus"))
                        $root.infinitusai.be.SelfRequeueCallStatus.encode(message.selfRequeueCallStatus, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.reviewerRequeueCallStatus != null && Object.hasOwnProperty.call(message, "reviewerRequeueCallStatus"))
                        $root.infinitusai.be.ReviewerRequeueCallStatus.encode(message.reviewerRequeueCallStatus, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified RecentCallEventActivity message, length delimited. Does not implicitly {@link infinitusai.be.RecentCallEventActivity.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {infinitusai.be.IRecentCallEventActivity} message RecentCallEventActivity message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RecentCallEventActivity.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RecentCallEventActivity message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RecentCallEventActivity} RecentCallEventActivity
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecentCallEventActivity.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RecentCallEventActivity();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgName = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        case 6: {
                                message.callStatus = reader.int32();
                                break;
                            }
                        case 7: {
                                message.timestampMs = reader.int64();
                                break;
                            }
                        case 8: {
                                message.noCallStatusMetadata = $root.infinitusai.be.NoCallStatusMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.selfRequeueCallStatus = $root.infinitusai.be.SelfRequeueCallStatus.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.reviewerRequeueCallStatus = $root.infinitusai.be.ReviewerRequeueCallStatus.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RecentCallEventActivity message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RecentCallEventActivity} RecentCallEventActivity
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RecentCallEventActivity.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RecentCallEventActivity message.
                 * @function verify
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RecentCallEventActivity.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    if (message.callStatus != null && message.hasOwnProperty("callStatus"))
                        switch (message.callStatus) {
                        default:
                            return "callStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.timestampMs != null && message.hasOwnProperty("timestampMs"))
                        if (!$util.isInteger(message.timestampMs) && !(message.timestampMs && $util.isInteger(message.timestampMs.low) && $util.isInteger(message.timestampMs.high)))
                            return "timestampMs: integer|Long expected";
                    if (message.noCallStatusMetadata != null && message.hasOwnProperty("noCallStatusMetadata")) {
                        properties.callStatusMetadata = 1;
                        {
                            var error = $root.infinitusai.be.NoCallStatusMetadata.verify(message.noCallStatusMetadata);
                            if (error)
                                return "noCallStatusMetadata." + error;
                        }
                    }
                    if (message.selfRequeueCallStatus != null && message.hasOwnProperty("selfRequeueCallStatus")) {
                        if (properties.callStatusMetadata === 1)
                            return "callStatusMetadata: multiple values";
                        properties.callStatusMetadata = 1;
                        {
                            var error = $root.infinitusai.be.SelfRequeueCallStatus.verify(message.selfRequeueCallStatus);
                            if (error)
                                return "selfRequeueCallStatus." + error;
                        }
                    }
                    if (message.reviewerRequeueCallStatus != null && message.hasOwnProperty("reviewerRequeueCallStatus")) {
                        if (properties.callStatusMetadata === 1)
                            return "callStatusMetadata: multiple values";
                        properties.callStatusMetadata = 1;
                        {
                            var error = $root.infinitusai.be.ReviewerRequeueCallStatus.verify(message.reviewerRequeueCallStatus);
                            if (error)
                                return "reviewerRequeueCallStatus." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a RecentCallEventActivity message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RecentCallEventActivity} RecentCallEventActivity
                 */
                RecentCallEventActivity.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RecentCallEventActivity)
                        return object;
                    var message = new $root.infinitusai.be.RecentCallEventActivity();
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    switch (object.callStatus) {
                    default:
                        if (typeof object.callStatus === "number") {
                            message.callStatus = object.callStatus;
                            break;
                        }
                        break;
                    case "CALL_STATUS_UNKNOWN":
                    case 0:
                        message.callStatus = 0;
                        break;
                    case "CALL_STATUS_SUCCESSFULLY_FINISHED":
                    case 1:
                        message.callStatus = 1;
                        break;
                    case "CALL_STATUS_OPERATOR_REQUEUED":
                    case 2:
                        message.callStatus = 2;
                        break;
                    case "CALL_STATUS_REVIEWER_REQUEUED":
                    case 3:
                        message.callStatus = 3;
                        break;
                    case "CALL_STATUS_AWAITING_REVIEW":
                    case 4:
                        message.callStatus = 4;
                        break;
                    }
                    if (object.timestampMs != null)
                        if ($util.Long)
                            (message.timestampMs = $util.Long.fromValue(object.timestampMs)).unsigned = false;
                        else if (typeof object.timestampMs === "string")
                            message.timestampMs = parseInt(object.timestampMs, 10);
                        else if (typeof object.timestampMs === "number")
                            message.timestampMs = object.timestampMs;
                        else if (typeof object.timestampMs === "object")
                            message.timestampMs = new $util.LongBits(object.timestampMs.low >>> 0, object.timestampMs.high >>> 0).toNumber();
                    if (object.noCallStatusMetadata != null) {
                        if (typeof object.noCallStatusMetadata !== "object")
                            throw TypeError(".infinitusai.be.RecentCallEventActivity.noCallStatusMetadata: object expected");
                        message.noCallStatusMetadata = $root.infinitusai.be.NoCallStatusMetadata.fromObject(object.noCallStatusMetadata);
                    }
                    if (object.selfRequeueCallStatus != null) {
                        if (typeof object.selfRequeueCallStatus !== "object")
                            throw TypeError(".infinitusai.be.RecentCallEventActivity.selfRequeueCallStatus: object expected");
                        message.selfRequeueCallStatus = $root.infinitusai.be.SelfRequeueCallStatus.fromObject(object.selfRequeueCallStatus);
                    }
                    if (object.reviewerRequeueCallStatus != null) {
                        if (typeof object.reviewerRequeueCallStatus !== "object")
                            throw TypeError(".infinitusai.be.RecentCallEventActivity.reviewerRequeueCallStatus: object expected");
                        message.reviewerRequeueCallStatus = $root.infinitusai.be.ReviewerRequeueCallStatus.fromObject(object.reviewerRequeueCallStatus);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RecentCallEventActivity message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {infinitusai.be.RecentCallEventActivity} message RecentCallEventActivity
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RecentCallEventActivity.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgName = "";
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.operatorEmail = "";
                        object.callStatus = options.enums === String ? "CALL_STATUS_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestampMs = options.longs === String ? "0" : 0;
                    }
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    if (message.callStatus != null && message.hasOwnProperty("callStatus"))
                        object.callStatus = options.enums === String ? $root.infinitusai.be.CallStatus[message.callStatus] === undefined ? message.callStatus : $root.infinitusai.be.CallStatus[message.callStatus] : message.callStatus;
                    if (message.timestampMs != null && message.hasOwnProperty("timestampMs"))
                        if (typeof message.timestampMs === "number")
                            object.timestampMs = options.longs === String ? String(message.timestampMs) : message.timestampMs;
                        else
                            object.timestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.timestampMs) : options.longs === Number ? new $util.LongBits(message.timestampMs.low >>> 0, message.timestampMs.high >>> 0).toNumber() : message.timestampMs;
                    if (message.noCallStatusMetadata != null && message.hasOwnProperty("noCallStatusMetadata")) {
                        object.noCallStatusMetadata = $root.infinitusai.be.NoCallStatusMetadata.toObject(message.noCallStatusMetadata, options);
                        if (options.oneofs)
                            object.callStatusMetadata = "noCallStatusMetadata";
                    }
                    if (message.selfRequeueCallStatus != null && message.hasOwnProperty("selfRequeueCallStatus")) {
                        object.selfRequeueCallStatus = $root.infinitusai.be.SelfRequeueCallStatus.toObject(message.selfRequeueCallStatus, options);
                        if (options.oneofs)
                            object.callStatusMetadata = "selfRequeueCallStatus";
                    }
                    if (message.reviewerRequeueCallStatus != null && message.hasOwnProperty("reviewerRequeueCallStatus")) {
                        object.reviewerRequeueCallStatus = $root.infinitusai.be.ReviewerRequeueCallStatus.toObject(message.reviewerRequeueCallStatus, options);
                        if (options.oneofs)
                            object.callStatusMetadata = "reviewerRequeueCallStatus";
                    }
                    return object;
                };
    
                /**
                 * Converts this RecentCallEventActivity to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RecentCallEventActivity.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RecentCallEventActivity
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RecentCallEventActivity
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RecentCallEventActivity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RecentCallEventActivity";
                };
    
                return RecentCallEventActivity;
            })();
    
            be.QueryUserPastWeekSkillMetricsRequest = (function() {
    
                /**
                 * Properties of a QueryUserPastWeekSkillMetricsRequest.
                 * @memberof infinitusai.be
                 * @interface IQueryUserPastWeekSkillMetricsRequest
                 */
    
                /**
                 * Constructs a new QueryUserPastWeekSkillMetricsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueryUserPastWeekSkillMetricsRequest.
                 * @implements IQueryUserPastWeekSkillMetricsRequest
                 * @constructor
                 * @param {infinitusai.be.IQueryUserPastWeekSkillMetricsRequest=} [properties] Properties to set
                 */
                function QueryUserPastWeekSkillMetricsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new QueryUserPastWeekSkillMetricsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserPastWeekSkillMetricsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.QueryUserPastWeekSkillMetricsRequest} QueryUserPastWeekSkillMetricsRequest instance
                 */
                QueryUserPastWeekSkillMetricsRequest.create = function create(properties) {
                    return new QueryUserPastWeekSkillMetricsRequest(properties);
                };
    
                /**
                 * Encodes the specified QueryUserPastWeekSkillMetricsRequest message. Does not implicitly {@link infinitusai.be.QueryUserPastWeekSkillMetricsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserPastWeekSkillMetricsRequest} message QueryUserPastWeekSkillMetricsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserPastWeekSkillMetricsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified QueryUserPastWeekSkillMetricsRequest message, length delimited. Does not implicitly {@link infinitusai.be.QueryUserPastWeekSkillMetricsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserPastWeekSkillMetricsRequest} message QueryUserPastWeekSkillMetricsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserPastWeekSkillMetricsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueryUserPastWeekSkillMetricsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueryUserPastWeekSkillMetricsRequest} QueryUserPastWeekSkillMetricsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserPastWeekSkillMetricsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueryUserPastWeekSkillMetricsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueryUserPastWeekSkillMetricsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueryUserPastWeekSkillMetricsRequest} QueryUserPastWeekSkillMetricsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserPastWeekSkillMetricsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueryUserPastWeekSkillMetricsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryUserPastWeekSkillMetricsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a QueryUserPastWeekSkillMetricsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueryUserPastWeekSkillMetricsRequest} QueryUserPastWeekSkillMetricsRequest
                 */
                QueryUserPastWeekSkillMetricsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueryUserPastWeekSkillMetricsRequest)
                        return object;
                    return new $root.infinitusai.be.QueryUserPastWeekSkillMetricsRequest();
                };
    
                /**
                 * Creates a plain object from a QueryUserPastWeekSkillMetricsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {infinitusai.be.QueryUserPastWeekSkillMetricsRequest} message QueryUserPastWeekSkillMetricsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryUserPastWeekSkillMetricsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this QueryUserPastWeekSkillMetricsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryUserPastWeekSkillMetricsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueryUserPastWeekSkillMetricsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueryUserPastWeekSkillMetricsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueryUserPastWeekSkillMetricsRequest";
                };
    
                return QueryUserPastWeekSkillMetricsRequest;
            })();
    
            be.QueryUserPastWeekSkillMetricsResponse = (function() {
    
                /**
                 * Properties of a QueryUserPastWeekSkillMetricsResponse.
                 * @memberof infinitusai.be
                 * @interface IQueryUserPastWeekSkillMetricsResponse
                 * @property {Array.<infinitusai.be.IUserPastWeekSkillMetric>|null} [userPastWeekSkillMetrics] QueryUserPastWeekSkillMetricsResponse userPastWeekSkillMetrics
                 * @property {number|Long|null} [cacheTimestampMs] QueryUserPastWeekSkillMetricsResponse cacheTimestampMs
                 */
    
                /**
                 * Constructs a new QueryUserPastWeekSkillMetricsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueryUserPastWeekSkillMetricsResponse.
                 * @implements IQueryUserPastWeekSkillMetricsResponse
                 * @constructor
                 * @param {infinitusai.be.IQueryUserPastWeekSkillMetricsResponse=} [properties] Properties to set
                 */
                function QueryUserPastWeekSkillMetricsResponse(properties) {
                    this.userPastWeekSkillMetrics = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * QueryUserPastWeekSkillMetricsResponse userPastWeekSkillMetrics.
                 * @member {Array.<infinitusai.be.IUserPastWeekSkillMetric>} userPastWeekSkillMetrics
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @instance
                 */
                QueryUserPastWeekSkillMetricsResponse.prototype.userPastWeekSkillMetrics = $util.emptyArray;
    
                /**
                 * QueryUserPastWeekSkillMetricsResponse cacheTimestampMs.
                 * @member {number|Long} cacheTimestampMs
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @instance
                 */
                QueryUserPastWeekSkillMetricsResponse.prototype.cacheTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new QueryUserPastWeekSkillMetricsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {infinitusai.be.IQueryUserPastWeekSkillMetricsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.QueryUserPastWeekSkillMetricsResponse} QueryUserPastWeekSkillMetricsResponse instance
                 */
                QueryUserPastWeekSkillMetricsResponse.create = function create(properties) {
                    return new QueryUserPastWeekSkillMetricsResponse(properties);
                };
    
                /**
                 * Encodes the specified QueryUserPastWeekSkillMetricsResponse message. Does not implicitly {@link infinitusai.be.QueryUserPastWeekSkillMetricsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {infinitusai.be.IQueryUserPastWeekSkillMetricsResponse} message QueryUserPastWeekSkillMetricsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserPastWeekSkillMetricsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userPastWeekSkillMetrics != null && message.userPastWeekSkillMetrics.length)
                        for (var i = 0; i < message.userPastWeekSkillMetrics.length; ++i)
                            $root.infinitusai.be.UserPastWeekSkillMetric.encode(message.userPastWeekSkillMetrics[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.cacheTimestampMs != null && Object.hasOwnProperty.call(message, "cacheTimestampMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cacheTimestampMs);
                    return writer;
                };
    
                /**
                 * Encodes the specified QueryUserPastWeekSkillMetricsResponse message, length delimited. Does not implicitly {@link infinitusai.be.QueryUserPastWeekSkillMetricsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {infinitusai.be.IQueryUserPastWeekSkillMetricsResponse} message QueryUserPastWeekSkillMetricsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserPastWeekSkillMetricsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueryUserPastWeekSkillMetricsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueryUserPastWeekSkillMetricsResponse} QueryUserPastWeekSkillMetricsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserPastWeekSkillMetricsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueryUserPastWeekSkillMetricsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.userPastWeekSkillMetrics && message.userPastWeekSkillMetrics.length))
                                    message.userPastWeekSkillMetrics = [];
                                message.userPastWeekSkillMetrics.push($root.infinitusai.be.UserPastWeekSkillMetric.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.cacheTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueryUserPastWeekSkillMetricsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueryUserPastWeekSkillMetricsResponse} QueryUserPastWeekSkillMetricsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserPastWeekSkillMetricsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueryUserPastWeekSkillMetricsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryUserPastWeekSkillMetricsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userPastWeekSkillMetrics != null && message.hasOwnProperty("userPastWeekSkillMetrics")) {
                        if (!Array.isArray(message.userPastWeekSkillMetrics))
                            return "userPastWeekSkillMetrics: array expected";
                        for (var i = 0; i < message.userPastWeekSkillMetrics.length; ++i) {
                            var error = $root.infinitusai.be.UserPastWeekSkillMetric.verify(message.userPastWeekSkillMetrics[i]);
                            if (error)
                                return "userPastWeekSkillMetrics." + error;
                        }
                    }
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (!$util.isInteger(message.cacheTimestampMs) && !(message.cacheTimestampMs && $util.isInteger(message.cacheTimestampMs.low) && $util.isInteger(message.cacheTimestampMs.high)))
                            return "cacheTimestampMs: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a QueryUserPastWeekSkillMetricsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueryUserPastWeekSkillMetricsResponse} QueryUserPastWeekSkillMetricsResponse
                 */
                QueryUserPastWeekSkillMetricsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueryUserPastWeekSkillMetricsResponse)
                        return object;
                    var message = new $root.infinitusai.be.QueryUserPastWeekSkillMetricsResponse();
                    if (object.userPastWeekSkillMetrics) {
                        if (!Array.isArray(object.userPastWeekSkillMetrics))
                            throw TypeError(".infinitusai.be.QueryUserPastWeekSkillMetricsResponse.userPastWeekSkillMetrics: array expected");
                        message.userPastWeekSkillMetrics = [];
                        for (var i = 0; i < object.userPastWeekSkillMetrics.length; ++i) {
                            if (typeof object.userPastWeekSkillMetrics[i] !== "object")
                                throw TypeError(".infinitusai.be.QueryUserPastWeekSkillMetricsResponse.userPastWeekSkillMetrics: object expected");
                            message.userPastWeekSkillMetrics[i] = $root.infinitusai.be.UserPastWeekSkillMetric.fromObject(object.userPastWeekSkillMetrics[i]);
                        }
                    }
                    if (object.cacheTimestampMs != null)
                        if ($util.Long)
                            (message.cacheTimestampMs = $util.Long.fromValue(object.cacheTimestampMs)).unsigned = false;
                        else if (typeof object.cacheTimestampMs === "string")
                            message.cacheTimestampMs = parseInt(object.cacheTimestampMs, 10);
                        else if (typeof object.cacheTimestampMs === "number")
                            message.cacheTimestampMs = object.cacheTimestampMs;
                        else if (typeof object.cacheTimestampMs === "object")
                            message.cacheTimestampMs = new $util.LongBits(object.cacheTimestampMs.low >>> 0, object.cacheTimestampMs.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a QueryUserPastWeekSkillMetricsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {infinitusai.be.QueryUserPastWeekSkillMetricsResponse} message QueryUserPastWeekSkillMetricsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryUserPastWeekSkillMetricsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.userPastWeekSkillMetrics = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.cacheTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cacheTimestampMs = options.longs === String ? "0" : 0;
                    if (message.userPastWeekSkillMetrics && message.userPastWeekSkillMetrics.length) {
                        object.userPastWeekSkillMetrics = [];
                        for (var j = 0; j < message.userPastWeekSkillMetrics.length; ++j)
                            object.userPastWeekSkillMetrics[j] = $root.infinitusai.be.UserPastWeekSkillMetric.toObject(message.userPastWeekSkillMetrics[j], options);
                    }
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (typeof message.cacheTimestampMs === "number")
                            object.cacheTimestampMs = options.longs === String ? String(message.cacheTimestampMs) : message.cacheTimestampMs;
                        else
                            object.cacheTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.cacheTimestampMs) : options.longs === Number ? new $util.LongBits(message.cacheTimestampMs.low >>> 0, message.cacheTimestampMs.high >>> 0).toNumber() : message.cacheTimestampMs;
                    return object;
                };
    
                /**
                 * Converts this QueryUserPastWeekSkillMetricsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryUserPastWeekSkillMetricsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueryUserPastWeekSkillMetricsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueryUserPastWeekSkillMetricsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueryUserPastWeekSkillMetricsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueryUserPastWeekSkillMetricsResponse";
                };
    
                return QueryUserPastWeekSkillMetricsResponse;
            })();
    
            be.UserPastWeekSkillMetric = (function() {
    
                /**
                 * Properties of a UserPastWeekSkillMetric.
                 * @memberof infinitusai.be
                 * @interface IUserPastWeekSkillMetric
                 * @property {string|null} [skillUuid] UserPastWeekSkillMetric skillUuid
                 * @property {number|Long|null} [numOfCalls] UserPastWeekSkillMetric numOfCalls
                 * @property {number|null} [selfRequeueRate] UserPastWeekSkillMetric selfRequeueRate
                 * @property {number|null} [reviewerRequeueRate] UserPastWeekSkillMetric reviewerRequeueRate
                 * @property {number|null} [reviewerCorrectionRate] UserPastWeekSkillMetric reviewerCorrectionRate
                 */
    
                /**
                 * Constructs a new UserPastWeekSkillMetric.
                 * @memberof infinitusai.be
                 * @classdesc Represents a UserPastWeekSkillMetric.
                 * @implements IUserPastWeekSkillMetric
                 * @constructor
                 * @param {infinitusai.be.IUserPastWeekSkillMetric=} [properties] Properties to set
                 */
                function UserPastWeekSkillMetric(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UserPastWeekSkillMetric skillUuid.
                 * @member {string} skillUuid
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @instance
                 */
                UserPastWeekSkillMetric.prototype.skillUuid = "";
    
                /**
                 * UserPastWeekSkillMetric numOfCalls.
                 * @member {number|Long} numOfCalls
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @instance
                 */
                UserPastWeekSkillMetric.prototype.numOfCalls = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UserPastWeekSkillMetric selfRequeueRate.
                 * @member {number} selfRequeueRate
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @instance
                 */
                UserPastWeekSkillMetric.prototype.selfRequeueRate = 0;
    
                /**
                 * UserPastWeekSkillMetric reviewerRequeueRate.
                 * @member {number} reviewerRequeueRate
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @instance
                 */
                UserPastWeekSkillMetric.prototype.reviewerRequeueRate = 0;
    
                /**
                 * UserPastWeekSkillMetric reviewerCorrectionRate.
                 * @member {number} reviewerCorrectionRate
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @instance
                 */
                UserPastWeekSkillMetric.prototype.reviewerCorrectionRate = 0;
    
                /**
                 * Creates a new UserPastWeekSkillMetric instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {infinitusai.be.IUserPastWeekSkillMetric=} [properties] Properties to set
                 * @returns {infinitusai.be.UserPastWeekSkillMetric} UserPastWeekSkillMetric instance
                 */
                UserPastWeekSkillMetric.create = function create(properties) {
                    return new UserPastWeekSkillMetric(properties);
                };
    
                /**
                 * Encodes the specified UserPastWeekSkillMetric message. Does not implicitly {@link infinitusai.be.UserPastWeekSkillMetric.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {infinitusai.be.IUserPastWeekSkillMetric} message UserPastWeekSkillMetric message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserPastWeekSkillMetric.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skillUuid != null && Object.hasOwnProperty.call(message, "skillUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.skillUuid);
                    if (message.numOfCalls != null && Object.hasOwnProperty.call(message, "numOfCalls"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.numOfCalls);
                    if (message.selfRequeueRate != null && Object.hasOwnProperty.call(message, "selfRequeueRate"))
                        writer.uint32(/* id 3, wireType 5 =*/29).float(message.selfRequeueRate);
                    if (message.reviewerRequeueRate != null && Object.hasOwnProperty.call(message, "reviewerRequeueRate"))
                        writer.uint32(/* id 4, wireType 5 =*/37).float(message.reviewerRequeueRate);
                    if (message.reviewerCorrectionRate != null && Object.hasOwnProperty.call(message, "reviewerCorrectionRate"))
                        writer.uint32(/* id 5, wireType 5 =*/45).float(message.reviewerCorrectionRate);
                    return writer;
                };
    
                /**
                 * Encodes the specified UserPastWeekSkillMetric message, length delimited. Does not implicitly {@link infinitusai.be.UserPastWeekSkillMetric.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {infinitusai.be.IUserPastWeekSkillMetric} message UserPastWeekSkillMetric message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserPastWeekSkillMetric.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UserPastWeekSkillMetric message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UserPastWeekSkillMetric} UserPastWeekSkillMetric
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserPastWeekSkillMetric.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UserPastWeekSkillMetric();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.skillUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.numOfCalls = reader.int64();
                                break;
                            }
                        case 3: {
                                message.selfRequeueRate = reader.float();
                                break;
                            }
                        case 4: {
                                message.reviewerRequeueRate = reader.float();
                                break;
                            }
                        case 5: {
                                message.reviewerCorrectionRate = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UserPastWeekSkillMetric message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UserPastWeekSkillMetric} UserPastWeekSkillMetric
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserPastWeekSkillMetric.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UserPastWeekSkillMetric message.
                 * @function verify
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserPastWeekSkillMetric.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        if (!$util.isString(message.skillUuid))
                            return "skillUuid: string expected";
                    if (message.numOfCalls != null && message.hasOwnProperty("numOfCalls"))
                        if (!$util.isInteger(message.numOfCalls) && !(message.numOfCalls && $util.isInteger(message.numOfCalls.low) && $util.isInteger(message.numOfCalls.high)))
                            return "numOfCalls: integer|Long expected";
                    if (message.selfRequeueRate != null && message.hasOwnProperty("selfRequeueRate"))
                        if (typeof message.selfRequeueRate !== "number")
                            return "selfRequeueRate: number expected";
                    if (message.reviewerRequeueRate != null && message.hasOwnProperty("reviewerRequeueRate"))
                        if (typeof message.reviewerRequeueRate !== "number")
                            return "reviewerRequeueRate: number expected";
                    if (message.reviewerCorrectionRate != null && message.hasOwnProperty("reviewerCorrectionRate"))
                        if (typeof message.reviewerCorrectionRate !== "number")
                            return "reviewerCorrectionRate: number expected";
                    return null;
                };
    
                /**
                 * Creates a UserPastWeekSkillMetric message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UserPastWeekSkillMetric} UserPastWeekSkillMetric
                 */
                UserPastWeekSkillMetric.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UserPastWeekSkillMetric)
                        return object;
                    var message = new $root.infinitusai.be.UserPastWeekSkillMetric();
                    if (object.skillUuid != null)
                        message.skillUuid = String(object.skillUuid);
                    if (object.numOfCalls != null)
                        if ($util.Long)
                            (message.numOfCalls = $util.Long.fromValue(object.numOfCalls)).unsigned = false;
                        else if (typeof object.numOfCalls === "string")
                            message.numOfCalls = parseInt(object.numOfCalls, 10);
                        else if (typeof object.numOfCalls === "number")
                            message.numOfCalls = object.numOfCalls;
                        else if (typeof object.numOfCalls === "object")
                            message.numOfCalls = new $util.LongBits(object.numOfCalls.low >>> 0, object.numOfCalls.high >>> 0).toNumber();
                    if (object.selfRequeueRate != null)
                        message.selfRequeueRate = Number(object.selfRequeueRate);
                    if (object.reviewerRequeueRate != null)
                        message.reviewerRequeueRate = Number(object.reviewerRequeueRate);
                    if (object.reviewerCorrectionRate != null)
                        message.reviewerCorrectionRate = Number(object.reviewerCorrectionRate);
                    return message;
                };
    
                /**
                 * Creates a plain object from a UserPastWeekSkillMetric message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {infinitusai.be.UserPastWeekSkillMetric} message UserPastWeekSkillMetric
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserPastWeekSkillMetric.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.skillUuid = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.numOfCalls = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.numOfCalls = options.longs === String ? "0" : 0;
                        object.selfRequeueRate = 0;
                        object.reviewerRequeueRate = 0;
                        object.reviewerCorrectionRate = 0;
                    }
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        object.skillUuid = message.skillUuid;
                    if (message.numOfCalls != null && message.hasOwnProperty("numOfCalls"))
                        if (typeof message.numOfCalls === "number")
                            object.numOfCalls = options.longs === String ? String(message.numOfCalls) : message.numOfCalls;
                        else
                            object.numOfCalls = options.longs === String ? $util.Long.prototype.toString.call(message.numOfCalls) : options.longs === Number ? new $util.LongBits(message.numOfCalls.low >>> 0, message.numOfCalls.high >>> 0).toNumber() : message.numOfCalls;
                    if (message.selfRequeueRate != null && message.hasOwnProperty("selfRequeueRate"))
                        object.selfRequeueRate = options.json && !isFinite(message.selfRequeueRate) ? String(message.selfRequeueRate) : message.selfRequeueRate;
                    if (message.reviewerRequeueRate != null && message.hasOwnProperty("reviewerRequeueRate"))
                        object.reviewerRequeueRate = options.json && !isFinite(message.reviewerRequeueRate) ? String(message.reviewerRequeueRate) : message.reviewerRequeueRate;
                    if (message.reviewerCorrectionRate != null && message.hasOwnProperty("reviewerCorrectionRate"))
                        object.reviewerCorrectionRate = options.json && !isFinite(message.reviewerCorrectionRate) ? String(message.reviewerCorrectionRate) : message.reviewerCorrectionRate;
                    return object;
                };
    
                /**
                 * Converts this UserPastWeekSkillMetric to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserPastWeekSkillMetric.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UserPastWeekSkillMetric
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UserPastWeekSkillMetric
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserPastWeekSkillMetric.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UserPastWeekSkillMetric";
                };
    
                return UserPastWeekSkillMetric;
            })();
    
            be.UserPastWeekSkillMetrics = (function() {
    
                /**
                 * Properties of a UserPastWeekSkillMetrics.
                 * @memberof infinitusai.be
                 * @interface IUserPastWeekSkillMetrics
                 * @property {Array.<infinitusai.be.IUserPastWeekSkillMetric>|null} [userPastWeekSkillMetrics] UserPastWeekSkillMetrics userPastWeekSkillMetrics
                 */
    
                /**
                 * Constructs a new UserPastWeekSkillMetrics.
                 * @memberof infinitusai.be
                 * @classdesc Represents a UserPastWeekSkillMetrics.
                 * @implements IUserPastWeekSkillMetrics
                 * @constructor
                 * @param {infinitusai.be.IUserPastWeekSkillMetrics=} [properties] Properties to set
                 */
                function UserPastWeekSkillMetrics(properties) {
                    this.userPastWeekSkillMetrics = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UserPastWeekSkillMetrics userPastWeekSkillMetrics.
                 * @member {Array.<infinitusai.be.IUserPastWeekSkillMetric>} userPastWeekSkillMetrics
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @instance
                 */
                UserPastWeekSkillMetrics.prototype.userPastWeekSkillMetrics = $util.emptyArray;
    
                /**
                 * Creates a new UserPastWeekSkillMetrics instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {infinitusai.be.IUserPastWeekSkillMetrics=} [properties] Properties to set
                 * @returns {infinitusai.be.UserPastWeekSkillMetrics} UserPastWeekSkillMetrics instance
                 */
                UserPastWeekSkillMetrics.create = function create(properties) {
                    return new UserPastWeekSkillMetrics(properties);
                };
    
                /**
                 * Encodes the specified UserPastWeekSkillMetrics message. Does not implicitly {@link infinitusai.be.UserPastWeekSkillMetrics.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {infinitusai.be.IUserPastWeekSkillMetrics} message UserPastWeekSkillMetrics message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserPastWeekSkillMetrics.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userPastWeekSkillMetrics != null && message.userPastWeekSkillMetrics.length)
                        for (var i = 0; i < message.userPastWeekSkillMetrics.length; ++i)
                            $root.infinitusai.be.UserPastWeekSkillMetric.encode(message.userPastWeekSkillMetrics[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UserPastWeekSkillMetrics message, length delimited. Does not implicitly {@link infinitusai.be.UserPastWeekSkillMetrics.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {infinitusai.be.IUserPastWeekSkillMetrics} message UserPastWeekSkillMetrics message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserPastWeekSkillMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UserPastWeekSkillMetrics message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UserPastWeekSkillMetrics} UserPastWeekSkillMetrics
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserPastWeekSkillMetrics.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UserPastWeekSkillMetrics();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.userPastWeekSkillMetrics && message.userPastWeekSkillMetrics.length))
                                    message.userPastWeekSkillMetrics = [];
                                message.userPastWeekSkillMetrics.push($root.infinitusai.be.UserPastWeekSkillMetric.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UserPastWeekSkillMetrics message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UserPastWeekSkillMetrics} UserPastWeekSkillMetrics
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserPastWeekSkillMetrics.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UserPastWeekSkillMetrics message.
                 * @function verify
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserPastWeekSkillMetrics.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userPastWeekSkillMetrics != null && message.hasOwnProperty("userPastWeekSkillMetrics")) {
                        if (!Array.isArray(message.userPastWeekSkillMetrics))
                            return "userPastWeekSkillMetrics: array expected";
                        for (var i = 0; i < message.userPastWeekSkillMetrics.length; ++i) {
                            var error = $root.infinitusai.be.UserPastWeekSkillMetric.verify(message.userPastWeekSkillMetrics[i]);
                            if (error)
                                return "userPastWeekSkillMetrics." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a UserPastWeekSkillMetrics message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UserPastWeekSkillMetrics} UserPastWeekSkillMetrics
                 */
                UserPastWeekSkillMetrics.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UserPastWeekSkillMetrics)
                        return object;
                    var message = new $root.infinitusai.be.UserPastWeekSkillMetrics();
                    if (object.userPastWeekSkillMetrics) {
                        if (!Array.isArray(object.userPastWeekSkillMetrics))
                            throw TypeError(".infinitusai.be.UserPastWeekSkillMetrics.userPastWeekSkillMetrics: array expected");
                        message.userPastWeekSkillMetrics = [];
                        for (var i = 0; i < object.userPastWeekSkillMetrics.length; ++i) {
                            if (typeof object.userPastWeekSkillMetrics[i] !== "object")
                                throw TypeError(".infinitusai.be.UserPastWeekSkillMetrics.userPastWeekSkillMetrics: object expected");
                            message.userPastWeekSkillMetrics[i] = $root.infinitusai.be.UserPastWeekSkillMetric.fromObject(object.userPastWeekSkillMetrics[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a UserPastWeekSkillMetrics message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {infinitusai.be.UserPastWeekSkillMetrics} message UserPastWeekSkillMetrics
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserPastWeekSkillMetrics.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.userPastWeekSkillMetrics = [];
                    if (message.userPastWeekSkillMetrics && message.userPastWeekSkillMetrics.length) {
                        object.userPastWeekSkillMetrics = [];
                        for (var j = 0; j < message.userPastWeekSkillMetrics.length; ++j)
                            object.userPastWeekSkillMetrics[j] = $root.infinitusai.be.UserPastWeekSkillMetric.toObject(message.userPastWeekSkillMetrics[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this UserPastWeekSkillMetrics to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserPastWeekSkillMetrics.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UserPastWeekSkillMetrics
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UserPastWeekSkillMetrics
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserPastWeekSkillMetrics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UserPastWeekSkillMetrics";
                };
    
                return UserPastWeekSkillMetrics;
            })();
    
            be.QueryUserAchievementsRequest = (function() {
    
                /**
                 * Properties of a QueryUserAchievementsRequest.
                 * @memberof infinitusai.be
                 * @interface IQueryUserAchievementsRequest
                 */
    
                /**
                 * Constructs a new QueryUserAchievementsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueryUserAchievementsRequest.
                 * @implements IQueryUserAchievementsRequest
                 * @constructor
                 * @param {infinitusai.be.IQueryUserAchievementsRequest=} [properties] Properties to set
                 */
                function QueryUserAchievementsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new QueryUserAchievementsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserAchievementsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.QueryUserAchievementsRequest} QueryUserAchievementsRequest instance
                 */
                QueryUserAchievementsRequest.create = function create(properties) {
                    return new QueryUserAchievementsRequest(properties);
                };
    
                /**
                 * Encodes the specified QueryUserAchievementsRequest message. Does not implicitly {@link infinitusai.be.QueryUserAchievementsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserAchievementsRequest} message QueryUserAchievementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserAchievementsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified QueryUserAchievementsRequest message, length delimited. Does not implicitly {@link infinitusai.be.QueryUserAchievementsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {infinitusai.be.IQueryUserAchievementsRequest} message QueryUserAchievementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserAchievementsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueryUserAchievementsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueryUserAchievementsRequest} QueryUserAchievementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserAchievementsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueryUserAchievementsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueryUserAchievementsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueryUserAchievementsRequest} QueryUserAchievementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserAchievementsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueryUserAchievementsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryUserAchievementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a QueryUserAchievementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueryUserAchievementsRequest} QueryUserAchievementsRequest
                 */
                QueryUserAchievementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueryUserAchievementsRequest)
                        return object;
                    return new $root.infinitusai.be.QueryUserAchievementsRequest();
                };
    
                /**
                 * Creates a plain object from a QueryUserAchievementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {infinitusai.be.QueryUserAchievementsRequest} message QueryUserAchievementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryUserAchievementsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this QueryUserAchievementsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryUserAchievementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueryUserAchievementsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueryUserAchievementsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueryUserAchievementsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueryUserAchievementsRequest";
                };
    
                return QueryUserAchievementsRequest;
            })();
    
            be.QueryUserAchievementsReqponse = (function() {
    
                /**
                 * Properties of a QueryUserAchievementsReqponse.
                 * @memberof infinitusai.be
                 * @interface IQueryUserAchievementsReqponse
                 * @property {Array.<infinitusai.be.IAchievement>|null} [achievements] QueryUserAchievementsReqponse achievements
                 * @property {number|Long|null} [cacheTimestampMs] QueryUserAchievementsReqponse cacheTimestampMs
                 */
    
                /**
                 * Constructs a new QueryUserAchievementsReqponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueryUserAchievementsReqponse.
                 * @implements IQueryUserAchievementsReqponse
                 * @constructor
                 * @param {infinitusai.be.IQueryUserAchievementsReqponse=} [properties] Properties to set
                 */
                function QueryUserAchievementsReqponse(properties) {
                    this.achievements = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * QueryUserAchievementsReqponse achievements.
                 * @member {Array.<infinitusai.be.IAchievement>} achievements
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @instance
                 */
                QueryUserAchievementsReqponse.prototype.achievements = $util.emptyArray;
    
                /**
                 * QueryUserAchievementsReqponse cacheTimestampMs.
                 * @member {number|Long} cacheTimestampMs
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @instance
                 */
                QueryUserAchievementsReqponse.prototype.cacheTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new QueryUserAchievementsReqponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {infinitusai.be.IQueryUserAchievementsReqponse=} [properties] Properties to set
                 * @returns {infinitusai.be.QueryUserAchievementsReqponse} QueryUserAchievementsReqponse instance
                 */
                QueryUserAchievementsReqponse.create = function create(properties) {
                    return new QueryUserAchievementsReqponse(properties);
                };
    
                /**
                 * Encodes the specified QueryUserAchievementsReqponse message. Does not implicitly {@link infinitusai.be.QueryUserAchievementsReqponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {infinitusai.be.IQueryUserAchievementsReqponse} message QueryUserAchievementsReqponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserAchievementsReqponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.achievements != null && message.achievements.length)
                        for (var i = 0; i < message.achievements.length; ++i)
                            $root.infinitusai.be.Achievement.encode(message.achievements[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.cacheTimestampMs != null && Object.hasOwnProperty.call(message, "cacheTimestampMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cacheTimestampMs);
                    return writer;
                };
    
                /**
                 * Encodes the specified QueryUserAchievementsReqponse message, length delimited. Does not implicitly {@link infinitusai.be.QueryUserAchievementsReqponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {infinitusai.be.IQueryUserAchievementsReqponse} message QueryUserAchievementsReqponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueryUserAchievementsReqponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueryUserAchievementsReqponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueryUserAchievementsReqponse} QueryUserAchievementsReqponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserAchievementsReqponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueryUserAchievementsReqponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.achievements && message.achievements.length))
                                    message.achievements = [];
                                message.achievements.push($root.infinitusai.be.Achievement.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.cacheTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueryUserAchievementsReqponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueryUserAchievementsReqponse} QueryUserAchievementsReqponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueryUserAchievementsReqponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueryUserAchievementsReqponse message.
                 * @function verify
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueryUserAchievementsReqponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.achievements != null && message.hasOwnProperty("achievements")) {
                        if (!Array.isArray(message.achievements))
                            return "achievements: array expected";
                        for (var i = 0; i < message.achievements.length; ++i) {
                            var error = $root.infinitusai.be.Achievement.verify(message.achievements[i]);
                            if (error)
                                return "achievements." + error;
                        }
                    }
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (!$util.isInteger(message.cacheTimestampMs) && !(message.cacheTimestampMs && $util.isInteger(message.cacheTimestampMs.low) && $util.isInteger(message.cacheTimestampMs.high)))
                            return "cacheTimestampMs: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a QueryUserAchievementsReqponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueryUserAchievementsReqponse} QueryUserAchievementsReqponse
                 */
                QueryUserAchievementsReqponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueryUserAchievementsReqponse)
                        return object;
                    var message = new $root.infinitusai.be.QueryUserAchievementsReqponse();
                    if (object.achievements) {
                        if (!Array.isArray(object.achievements))
                            throw TypeError(".infinitusai.be.QueryUserAchievementsReqponse.achievements: array expected");
                        message.achievements = [];
                        for (var i = 0; i < object.achievements.length; ++i) {
                            if (typeof object.achievements[i] !== "object")
                                throw TypeError(".infinitusai.be.QueryUserAchievementsReqponse.achievements: object expected");
                            message.achievements[i] = $root.infinitusai.be.Achievement.fromObject(object.achievements[i]);
                        }
                    }
                    if (object.cacheTimestampMs != null)
                        if ($util.Long)
                            (message.cacheTimestampMs = $util.Long.fromValue(object.cacheTimestampMs)).unsigned = false;
                        else if (typeof object.cacheTimestampMs === "string")
                            message.cacheTimestampMs = parseInt(object.cacheTimestampMs, 10);
                        else if (typeof object.cacheTimestampMs === "number")
                            message.cacheTimestampMs = object.cacheTimestampMs;
                        else if (typeof object.cacheTimestampMs === "object")
                            message.cacheTimestampMs = new $util.LongBits(object.cacheTimestampMs.low >>> 0, object.cacheTimestampMs.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a QueryUserAchievementsReqponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {infinitusai.be.QueryUserAchievementsReqponse} message QueryUserAchievementsReqponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueryUserAchievementsReqponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.achievements = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.cacheTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cacheTimestampMs = options.longs === String ? "0" : 0;
                    if (message.achievements && message.achievements.length) {
                        object.achievements = [];
                        for (var j = 0; j < message.achievements.length; ++j)
                            object.achievements[j] = $root.infinitusai.be.Achievement.toObject(message.achievements[j], options);
                    }
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (typeof message.cacheTimestampMs === "number")
                            object.cacheTimestampMs = options.longs === String ? String(message.cacheTimestampMs) : message.cacheTimestampMs;
                        else
                            object.cacheTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.cacheTimestampMs) : options.longs === Number ? new $util.LongBits(message.cacheTimestampMs.low >>> 0, message.cacheTimestampMs.high >>> 0).toNumber() : message.cacheTimestampMs;
                    return object;
                };
    
                /**
                 * Converts this QueryUserAchievementsReqponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueryUserAchievementsReqponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueryUserAchievementsReqponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueryUserAchievementsReqponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueryUserAchievementsReqponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueryUserAchievementsReqponse";
                };
    
                return QueryUserAchievementsReqponse;
            })();
    
            be.ProficiencyBadge = (function() {
    
                /**
                 * Properties of a ProficiencyBadge.
                 * @memberof infinitusai.be
                 * @interface IProficiencyBadge
                 * @property {string|null} [orgUuid] ProficiencyBadge orgUuid
                 * @property {string|null} [orgName] ProficiencyBadge orgName
                 * @property {string|null} [orgDisplayName] ProficiencyBadge orgDisplayName
                 */
    
                /**
                 * Constructs a new ProficiencyBadge.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProficiencyBadge.
                 * @implements IProficiencyBadge
                 * @constructor
                 * @param {infinitusai.be.IProficiencyBadge=} [properties] Properties to set
                 */
                function ProficiencyBadge(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProficiencyBadge orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @instance
                 */
                ProficiencyBadge.prototype.orgUuid = "";
    
                /**
                 * ProficiencyBadge orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @instance
                 */
                ProficiencyBadge.prototype.orgName = "";
    
                /**
                 * ProficiencyBadge orgDisplayName.
                 * @member {string} orgDisplayName
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @instance
                 */
                ProficiencyBadge.prototype.orgDisplayName = "";
    
                /**
                 * Creates a new ProficiencyBadge instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {infinitusai.be.IProficiencyBadge=} [properties] Properties to set
                 * @returns {infinitusai.be.ProficiencyBadge} ProficiencyBadge instance
                 */
                ProficiencyBadge.create = function create(properties) {
                    return new ProficiencyBadge(properties);
                };
    
                /**
                 * Encodes the specified ProficiencyBadge message. Does not implicitly {@link infinitusai.be.ProficiencyBadge.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {infinitusai.be.IProficiencyBadge} message ProficiencyBadge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProficiencyBadge.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgName);
                    if (message.orgDisplayName != null && Object.hasOwnProperty.call(message, "orgDisplayName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgDisplayName);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProficiencyBadge message, length delimited. Does not implicitly {@link infinitusai.be.ProficiencyBadge.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {infinitusai.be.IProficiencyBadge} message ProficiencyBadge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProficiencyBadge.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProficiencyBadge message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProficiencyBadge} ProficiencyBadge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProficiencyBadge.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProficiencyBadge();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.orgName = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgDisplayName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProficiencyBadge message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProficiencyBadge} ProficiencyBadge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProficiencyBadge.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProficiencyBadge message.
                 * @function verify
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProficiencyBadge.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        if (!$util.isString(message.orgDisplayName))
                            return "orgDisplayName: string expected";
                    return null;
                };
    
                /**
                 * Creates a ProficiencyBadge message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProficiencyBadge} ProficiencyBadge
                 */
                ProficiencyBadge.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProficiencyBadge)
                        return object;
                    var message = new $root.infinitusai.be.ProficiencyBadge();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    if (object.orgDisplayName != null)
                        message.orgDisplayName = String(object.orgDisplayName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProficiencyBadge message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {infinitusai.be.ProficiencyBadge} message ProficiencyBadge
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProficiencyBadge.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.orgName = "";
                        object.orgDisplayName = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        object.orgDisplayName = message.orgDisplayName;
                    return object;
                };
    
                /**
                 * Converts this ProficiencyBadge to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProficiencyBadge.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProficiencyBadge
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProficiencyBadge
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProficiencyBadge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProficiencyBadge";
                };
    
                return ProficiencyBadge;
            })();
    
            be.ProductiveDayStreakBadge = (function() {
    
                /**
                 * Properties of a ProductiveDayStreakBadge.
                 * @memberof infinitusai.be
                 * @interface IProductiveDayStreakBadge
                 * @property {number|Long|null} [dayStreak] ProductiveDayStreakBadge dayStreak
                 */
    
                /**
                 * Constructs a new ProductiveDayStreakBadge.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProductiveDayStreakBadge.
                 * @implements IProductiveDayStreakBadge
                 * @constructor
                 * @param {infinitusai.be.IProductiveDayStreakBadge=} [properties] Properties to set
                 */
                function ProductiveDayStreakBadge(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProductiveDayStreakBadge dayStreak.
                 * @member {number|Long} dayStreak
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @instance
                 */
                ProductiveDayStreakBadge.prototype.dayStreak = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new ProductiveDayStreakBadge instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {infinitusai.be.IProductiveDayStreakBadge=} [properties] Properties to set
                 * @returns {infinitusai.be.ProductiveDayStreakBadge} ProductiveDayStreakBadge instance
                 */
                ProductiveDayStreakBadge.create = function create(properties) {
                    return new ProductiveDayStreakBadge(properties);
                };
    
                /**
                 * Encodes the specified ProductiveDayStreakBadge message. Does not implicitly {@link infinitusai.be.ProductiveDayStreakBadge.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {infinitusai.be.IProductiveDayStreakBadge} message ProductiveDayStreakBadge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProductiveDayStreakBadge.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.dayStreak != null && Object.hasOwnProperty.call(message, "dayStreak"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.dayStreak);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProductiveDayStreakBadge message, length delimited. Does not implicitly {@link infinitusai.be.ProductiveDayStreakBadge.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {infinitusai.be.IProductiveDayStreakBadge} message ProductiveDayStreakBadge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProductiveDayStreakBadge.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProductiveDayStreakBadge message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProductiveDayStreakBadge} ProductiveDayStreakBadge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProductiveDayStreakBadge.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProductiveDayStreakBadge();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.dayStreak = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProductiveDayStreakBadge message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProductiveDayStreakBadge} ProductiveDayStreakBadge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProductiveDayStreakBadge.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProductiveDayStreakBadge message.
                 * @function verify
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProductiveDayStreakBadge.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.dayStreak != null && message.hasOwnProperty("dayStreak"))
                        if (!$util.isInteger(message.dayStreak) && !(message.dayStreak && $util.isInteger(message.dayStreak.low) && $util.isInteger(message.dayStreak.high)))
                            return "dayStreak: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a ProductiveDayStreakBadge message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProductiveDayStreakBadge} ProductiveDayStreakBadge
                 */
                ProductiveDayStreakBadge.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProductiveDayStreakBadge)
                        return object;
                    var message = new $root.infinitusai.be.ProductiveDayStreakBadge();
                    if (object.dayStreak != null)
                        if ($util.Long)
                            (message.dayStreak = $util.Long.fromValue(object.dayStreak)).unsigned = false;
                        else if (typeof object.dayStreak === "string")
                            message.dayStreak = parseInt(object.dayStreak, 10);
                        else if (typeof object.dayStreak === "number")
                            message.dayStreak = object.dayStreak;
                        else if (typeof object.dayStreak === "object")
                            message.dayStreak = new $util.LongBits(object.dayStreak.low >>> 0, object.dayStreak.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProductiveDayStreakBadge message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {infinitusai.be.ProductiveDayStreakBadge} message ProductiveDayStreakBadge
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProductiveDayStreakBadge.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.dayStreak = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.dayStreak = options.longs === String ? "0" : 0;
                    if (message.dayStreak != null && message.hasOwnProperty("dayStreak"))
                        if (typeof message.dayStreak === "number")
                            object.dayStreak = options.longs === String ? String(message.dayStreak) : message.dayStreak;
                        else
                            object.dayStreak = options.longs === String ? $util.Long.prototype.toString.call(message.dayStreak) : options.longs === Number ? new $util.LongBits(message.dayStreak.low >>> 0, message.dayStreak.high >>> 0).toNumber() : message.dayStreak;
                    return object;
                };
    
                /**
                 * Converts this ProductiveDayStreakBadge to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProductiveDayStreakBadge.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProductiveDayStreakBadge
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProductiveDayStreakBadge
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProductiveDayStreakBadge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProductiveDayStreakBadge";
                };
    
                return ProductiveDayStreakBadge;
            })();
    
            be.Achievement = (function() {
    
                /**
                 * Properties of an Achievement.
                 * @memberof infinitusai.be
                 * @interface IAchievement
                 * @property {infinitusai.be.IProficiencyBadge|null} [proficiencyBadge] Achievement proficiencyBadge
                 * @property {infinitusai.be.IProductiveDayStreakBadge|null} [productiveDayStreakBadge] Achievement productiveDayStreakBadge
                 */
    
                /**
                 * Constructs a new Achievement.
                 * @memberof infinitusai.be
                 * @classdesc Represents an Achievement.
                 * @implements IAchievement
                 * @constructor
                 * @param {infinitusai.be.IAchievement=} [properties] Properties to set
                 */
                function Achievement(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Achievement proficiencyBadge.
                 * @member {infinitusai.be.IProficiencyBadge|null|undefined} proficiencyBadge
                 * @memberof infinitusai.be.Achievement
                 * @instance
                 */
                Achievement.prototype.proficiencyBadge = null;
    
                /**
                 * Achievement productiveDayStreakBadge.
                 * @member {infinitusai.be.IProductiveDayStreakBadge|null|undefined} productiveDayStreakBadge
                 * @memberof infinitusai.be.Achievement
                 * @instance
                 */
                Achievement.prototype.productiveDayStreakBadge = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * Achievement badge.
                 * @member {"proficiencyBadge"|"productiveDayStreakBadge"|undefined} badge
                 * @memberof infinitusai.be.Achievement
                 * @instance
                 */
                Object.defineProperty(Achievement.prototype, "badge", {
                    get: $util.oneOfGetter($oneOfFields = ["proficiencyBadge", "productiveDayStreakBadge"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new Achievement instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {infinitusai.be.IAchievement=} [properties] Properties to set
                 * @returns {infinitusai.be.Achievement} Achievement instance
                 */
                Achievement.create = function create(properties) {
                    return new Achievement(properties);
                };
    
                /**
                 * Encodes the specified Achievement message. Does not implicitly {@link infinitusai.be.Achievement.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {infinitusai.be.IAchievement} message Achievement message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Achievement.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.proficiencyBadge != null && Object.hasOwnProperty.call(message, "proficiencyBadge"))
                        $root.infinitusai.be.ProficiencyBadge.encode(message.proficiencyBadge, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.productiveDayStreakBadge != null && Object.hasOwnProperty.call(message, "productiveDayStreakBadge"))
                        $root.infinitusai.be.ProductiveDayStreakBadge.encode(message.productiveDayStreakBadge, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Achievement message, length delimited. Does not implicitly {@link infinitusai.be.Achievement.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {infinitusai.be.IAchievement} message Achievement message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Achievement.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Achievement message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Achievement} Achievement
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Achievement.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Achievement();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.proficiencyBadge = $root.infinitusai.be.ProficiencyBadge.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.productiveDayStreakBadge = $root.infinitusai.be.ProductiveDayStreakBadge.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Achievement message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Achievement} Achievement
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Achievement.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Achievement message.
                 * @function verify
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Achievement.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.proficiencyBadge != null && message.hasOwnProperty("proficiencyBadge")) {
                        properties.badge = 1;
                        {
                            var error = $root.infinitusai.be.ProficiencyBadge.verify(message.proficiencyBadge);
                            if (error)
                                return "proficiencyBadge." + error;
                        }
                    }
                    if (message.productiveDayStreakBadge != null && message.hasOwnProperty("productiveDayStreakBadge")) {
                        if (properties.badge === 1)
                            return "badge: multiple values";
                        properties.badge = 1;
                        {
                            var error = $root.infinitusai.be.ProductiveDayStreakBadge.verify(message.productiveDayStreakBadge);
                            if (error)
                                return "productiveDayStreakBadge." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an Achievement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Achievement} Achievement
                 */
                Achievement.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Achievement)
                        return object;
                    var message = new $root.infinitusai.be.Achievement();
                    if (object.proficiencyBadge != null) {
                        if (typeof object.proficiencyBadge !== "object")
                            throw TypeError(".infinitusai.be.Achievement.proficiencyBadge: object expected");
                        message.proficiencyBadge = $root.infinitusai.be.ProficiencyBadge.fromObject(object.proficiencyBadge);
                    }
                    if (object.productiveDayStreakBadge != null) {
                        if (typeof object.productiveDayStreakBadge !== "object")
                            throw TypeError(".infinitusai.be.Achievement.productiveDayStreakBadge: object expected");
                        message.productiveDayStreakBadge = $root.infinitusai.be.ProductiveDayStreakBadge.fromObject(object.productiveDayStreakBadge);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an Achievement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {infinitusai.be.Achievement} message Achievement
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Achievement.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.proficiencyBadge != null && message.hasOwnProperty("proficiencyBadge")) {
                        object.proficiencyBadge = $root.infinitusai.be.ProficiencyBadge.toObject(message.proficiencyBadge, options);
                        if (options.oneofs)
                            object.badge = "proficiencyBadge";
                    }
                    if (message.productiveDayStreakBadge != null && message.hasOwnProperty("productiveDayStreakBadge")) {
                        object.productiveDayStreakBadge = $root.infinitusai.be.ProductiveDayStreakBadge.toObject(message.productiveDayStreakBadge, options);
                        if (options.oneofs)
                            object.badge = "productiveDayStreakBadge";
                    }
                    return object;
                };
    
                /**
                 * Converts this Achievement to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Achievement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Achievement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Achievement
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Achievement
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Achievement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Achievement";
                };
    
                return Achievement;
            })();
    
            be.UserMetricCacheTimestamp = (function() {
    
                /**
                 * Properties of a UserMetricCacheTimestamp.
                 * @memberof infinitusai.be
                 * @interface IUserMetricCacheTimestamp
                 * @property {number|Long|null} [cacheTimestampMs] UserMetricCacheTimestamp cacheTimestampMs
                 */
    
                /**
                 * Constructs a new UserMetricCacheTimestamp.
                 * @memberof infinitusai.be
                 * @classdesc Represents a UserMetricCacheTimestamp.
                 * @implements IUserMetricCacheTimestamp
                 * @constructor
                 * @param {infinitusai.be.IUserMetricCacheTimestamp=} [properties] Properties to set
                 */
                function UserMetricCacheTimestamp(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UserMetricCacheTimestamp cacheTimestampMs.
                 * @member {number|Long} cacheTimestampMs
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @instance
                 */
                UserMetricCacheTimestamp.prototype.cacheTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new UserMetricCacheTimestamp instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {infinitusai.be.IUserMetricCacheTimestamp=} [properties] Properties to set
                 * @returns {infinitusai.be.UserMetricCacheTimestamp} UserMetricCacheTimestamp instance
                 */
                UserMetricCacheTimestamp.create = function create(properties) {
                    return new UserMetricCacheTimestamp(properties);
                };
    
                /**
                 * Encodes the specified UserMetricCacheTimestamp message. Does not implicitly {@link infinitusai.be.UserMetricCacheTimestamp.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {infinitusai.be.IUserMetricCacheTimestamp} message UserMetricCacheTimestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserMetricCacheTimestamp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cacheTimestampMs != null && Object.hasOwnProperty.call(message, "cacheTimestampMs"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cacheTimestampMs);
                    return writer;
                };
    
                /**
                 * Encodes the specified UserMetricCacheTimestamp message, length delimited. Does not implicitly {@link infinitusai.be.UserMetricCacheTimestamp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {infinitusai.be.IUserMetricCacheTimestamp} message UserMetricCacheTimestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserMetricCacheTimestamp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UserMetricCacheTimestamp message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UserMetricCacheTimestamp} UserMetricCacheTimestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserMetricCacheTimestamp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UserMetricCacheTimestamp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.cacheTimestampMs = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UserMetricCacheTimestamp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UserMetricCacheTimestamp} UserMetricCacheTimestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserMetricCacheTimestamp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UserMetricCacheTimestamp message.
                 * @function verify
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserMetricCacheTimestamp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (!$util.isInteger(message.cacheTimestampMs) && !(message.cacheTimestampMs && $util.isInteger(message.cacheTimestampMs.low) && $util.isInteger(message.cacheTimestampMs.high)))
                            return "cacheTimestampMs: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a UserMetricCacheTimestamp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UserMetricCacheTimestamp} UserMetricCacheTimestamp
                 */
                UserMetricCacheTimestamp.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UserMetricCacheTimestamp)
                        return object;
                    var message = new $root.infinitusai.be.UserMetricCacheTimestamp();
                    if (object.cacheTimestampMs != null)
                        if ($util.Long)
                            (message.cacheTimestampMs = $util.Long.fromValue(object.cacheTimestampMs)).unsigned = false;
                        else if (typeof object.cacheTimestampMs === "string")
                            message.cacheTimestampMs = parseInt(object.cacheTimestampMs, 10);
                        else if (typeof object.cacheTimestampMs === "number")
                            message.cacheTimestampMs = object.cacheTimestampMs;
                        else if (typeof object.cacheTimestampMs === "object")
                            message.cacheTimestampMs = new $util.LongBits(object.cacheTimestampMs.low >>> 0, object.cacheTimestampMs.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a UserMetricCacheTimestamp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {infinitusai.be.UserMetricCacheTimestamp} message UserMetricCacheTimestamp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserMetricCacheTimestamp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.cacheTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cacheTimestampMs = options.longs === String ? "0" : 0;
                    if (message.cacheTimestampMs != null && message.hasOwnProperty("cacheTimestampMs"))
                        if (typeof message.cacheTimestampMs === "number")
                            object.cacheTimestampMs = options.longs === String ? String(message.cacheTimestampMs) : message.cacheTimestampMs;
                        else
                            object.cacheTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.cacheTimestampMs) : options.longs === Number ? new $util.LongBits(message.cacheTimestampMs.low >>> 0, message.cacheTimestampMs.high >>> 0).toNumber() : message.cacheTimestampMs;
                    return object;
                };
    
                /**
                 * Converts this UserMetricCacheTimestamp to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserMetricCacheTimestamp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UserMetricCacheTimestamp
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UserMetricCacheTimestamp
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserMetricCacheTimestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UserMetricCacheTimestamp";
                };
    
                return UserMetricCacheTimestamp;
            })();
    
            be.NexmoUserInfo = (function() {
    
                /**
                 * Properties of a NexmoUserInfo.
                 * @memberof infinitusai.be
                 * @interface INexmoUserInfo
                 * @property {string|null} [name] NexmoUserInfo name
                 * @property {string|null} [displayUserName] NexmoUserInfo displayUserName
                 * @property {string|null} [userId] NexmoUserInfo userId
                 */
    
                /**
                 * Constructs a new NexmoUserInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents a NexmoUserInfo.
                 * @implements INexmoUserInfo
                 * @constructor
                 * @param {infinitusai.be.INexmoUserInfo=} [properties] Properties to set
                 */
                function NexmoUserInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * NexmoUserInfo name.
                 * @member {string} name
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @instance
                 */
                NexmoUserInfo.prototype.name = "";
    
                /**
                 * NexmoUserInfo displayUserName.
                 * @member {string} displayUserName
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @instance
                 */
                NexmoUserInfo.prototype.displayUserName = "";
    
                /**
                 * NexmoUserInfo userId.
                 * @member {string} userId
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @instance
                 */
                NexmoUserInfo.prototype.userId = "";
    
                /**
                 * Creates a new NexmoUserInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {infinitusai.be.INexmoUserInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.NexmoUserInfo} NexmoUserInfo instance
                 */
                NexmoUserInfo.create = function create(properties) {
                    return new NexmoUserInfo(properties);
                };
    
                /**
                 * Encodes the specified NexmoUserInfo message. Does not implicitly {@link infinitusai.be.NexmoUserInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {infinitusai.be.INexmoUserInfo} message NexmoUserInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NexmoUserInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.displayUserName != null && Object.hasOwnProperty.call(message, "displayUserName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayUserName);
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
                    return writer;
                };
    
                /**
                 * Encodes the specified NexmoUserInfo message, length delimited. Does not implicitly {@link infinitusai.be.NexmoUserInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {infinitusai.be.INexmoUserInfo} message NexmoUserInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NexmoUserInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a NexmoUserInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.NexmoUserInfo} NexmoUserInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NexmoUserInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.NexmoUserInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.displayUserName = reader.string();
                                break;
                            }
                        case 3: {
                                message.userId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a NexmoUserInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.NexmoUserInfo} NexmoUserInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NexmoUserInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a NexmoUserInfo message.
                 * @function verify
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NexmoUserInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.displayUserName != null && message.hasOwnProperty("displayUserName"))
                        if (!$util.isString(message.displayUserName))
                            return "displayUserName: string expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };
    
                /**
                 * Creates a NexmoUserInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.NexmoUserInfo} NexmoUserInfo
                 */
                NexmoUserInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.NexmoUserInfo)
                        return object;
                    var message = new $root.infinitusai.be.NexmoUserInfo();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.displayUserName != null)
                        message.displayUserName = String(object.displayUserName);
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a NexmoUserInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {infinitusai.be.NexmoUserInfo} message NexmoUserInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NexmoUserInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.displayUserName = "";
                        object.userId = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.displayUserName != null && message.hasOwnProperty("displayUserName"))
                        object.displayUserName = message.displayUserName;
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };
    
                /**
                 * Converts this NexmoUserInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NexmoUserInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for NexmoUserInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.NexmoUserInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NexmoUserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.NexmoUserInfo";
                };
    
                return NexmoUserInfo;
            })();
    
            be.OperatorInfo = (function() {
    
                /**
                 * Properties of an OperatorInfo.
                 * @memberof infinitusai.be
                 * @interface IOperatorInfo
                 * @property {string|null} [phone] OperatorInfo phone
                 * @property {boolean|null} [canReview] OperatorInfo canReview
                 * @property {Object.<string,infinitusai.be.INexmoUserInfo>|null} [nexmoUserInfo] OperatorInfo nexmoUserInfo
                 * @property {boolean|null} [isAdmin] OperatorInfo isAdmin
                 * @property {boolean|null} [selectiveReviewEnabled] OperatorInfo selectiveReviewEnabled
                 * @property {Object.<string,boolean>|null} [selectiveReviewOrgsEligible] OperatorInfo selectiveReviewOrgsEligible
                 * @property {string|null} [userEmail] OperatorInfo userEmail
                 * @property {string|null} [userID] OperatorInfo userID
                 * @property {string|null} [assembledUserID] OperatorInfo assembledUserID
                 * @property {string|null} [assembledAgentID] OperatorInfo assembledAgentID
                 * @property {number|Long|null} [lastStatusUpdateTimestampMillis] OperatorInfo lastStatusUpdateTimestampMillis
                 * @property {string|null} [statusLastUpdatedFromUrl] OperatorInfo statusLastUpdatedFromUrl
                 * @property {infinitusai.be.OperatorOnlineStatus|null} [operatorOnlineStatus] OperatorInfo operatorOnlineStatus
                 * @property {infinitusai.be.OperatorOfflineReason|null} [operatorOfflineReason] OperatorInfo operatorOfflineReason
                 */
    
                /**
                 * Constructs a new OperatorInfo.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorInfo.
                 * @implements IOperatorInfo
                 * @constructor
                 * @param {infinitusai.be.IOperatorInfo=} [properties] Properties to set
                 */
                function OperatorInfo(properties) {
                    this.nexmoUserInfo = {};
                    this.selectiveReviewOrgsEligible = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorInfo phone.
                 * @member {string} phone
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.phone = "";
    
                /**
                 * OperatorInfo canReview.
                 * @member {boolean} canReview
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.canReview = false;
    
                /**
                 * OperatorInfo nexmoUserInfo.
                 * @member {Object.<string,infinitusai.be.INexmoUserInfo>} nexmoUserInfo
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.nexmoUserInfo = $util.emptyObject;
    
                /**
                 * OperatorInfo isAdmin.
                 * @member {boolean} isAdmin
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.isAdmin = false;
    
                /**
                 * OperatorInfo selectiveReviewEnabled.
                 * @member {boolean} selectiveReviewEnabled
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.selectiveReviewEnabled = false;
    
                /**
                 * OperatorInfo selectiveReviewOrgsEligible.
                 * @member {Object.<string,boolean>} selectiveReviewOrgsEligible
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.selectiveReviewOrgsEligible = $util.emptyObject;
    
                /**
                 * OperatorInfo userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.userEmail = "";
    
                /**
                 * OperatorInfo userID.
                 * @member {string} userID
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.userID = "";
    
                /**
                 * OperatorInfo assembledUserID.
                 * @member {string} assembledUserID
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.assembledUserID = "";
    
                /**
                 * OperatorInfo assembledAgentID.
                 * @member {string} assembledAgentID
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.assembledAgentID = "";
    
                /**
                 * OperatorInfo lastStatusUpdateTimestampMillis.
                 * @member {number|Long} lastStatusUpdateTimestampMillis
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.lastStatusUpdateTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * OperatorInfo statusLastUpdatedFromUrl.
                 * @member {string} statusLastUpdatedFromUrl
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.statusLastUpdatedFromUrl = "";
    
                /**
                 * OperatorInfo operatorOnlineStatus.
                 * @member {infinitusai.be.OperatorOnlineStatus} operatorOnlineStatus
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.operatorOnlineStatus = 0;
    
                /**
                 * OperatorInfo operatorOfflineReason.
                 * @member {infinitusai.be.OperatorOfflineReason} operatorOfflineReason
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 */
                OperatorInfo.prototype.operatorOfflineReason = 0;
    
                /**
                 * Creates a new OperatorInfo instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {infinitusai.be.IOperatorInfo=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorInfo} OperatorInfo instance
                 */
                OperatorInfo.create = function create(properties) {
                    return new OperatorInfo(properties);
                };
    
                /**
                 * Encodes the specified OperatorInfo message. Does not implicitly {@link infinitusai.be.OperatorInfo.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {infinitusai.be.IOperatorInfo} message OperatorInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
                    if (message.canReview != null && Object.hasOwnProperty.call(message, "canReview"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.canReview);
                    if (message.nexmoUserInfo != null && Object.hasOwnProperty.call(message, "nexmoUserInfo"))
                        for (var keys = Object.keys(message.nexmoUserInfo), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.NexmoUserInfo.encode(message.nexmoUserInfo[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.isAdmin != null && Object.hasOwnProperty.call(message, "isAdmin"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isAdmin);
                    if (message.selectiveReviewEnabled != null && Object.hasOwnProperty.call(message, "selectiveReviewEnabled"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.selectiveReviewEnabled);
                    if (message.selectiveReviewOrgsEligible != null && Object.hasOwnProperty.call(message, "selectiveReviewOrgsEligible"))
                        for (var keys = Object.keys(message.selectiveReviewOrgsEligible), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.selectiveReviewOrgsEligible[keys[i]]).ldelim();
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.userEmail);
                    if (message.userID != null && Object.hasOwnProperty.call(message, "userID"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.userID);
                    if (message.assembledUserID != null && Object.hasOwnProperty.call(message, "assembledUserID"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.assembledUserID);
                    if (message.assembledAgentID != null && Object.hasOwnProperty.call(message, "assembledAgentID"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.assembledAgentID);
                    if (message.lastStatusUpdateTimestampMillis != null && Object.hasOwnProperty.call(message, "lastStatusUpdateTimestampMillis"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int64(message.lastStatusUpdateTimestampMillis);
                    if (message.statusLastUpdatedFromUrl != null && Object.hasOwnProperty.call(message, "statusLastUpdatedFromUrl"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.statusLastUpdatedFromUrl);
                    if (message.operatorOnlineStatus != null && Object.hasOwnProperty.call(message, "operatorOnlineStatus"))
                        writer.uint32(/* id 13, wireType 0 =*/104).int32(message.operatorOnlineStatus);
                    if (message.operatorOfflineReason != null && Object.hasOwnProperty.call(message, "operatorOfflineReason"))
                        writer.uint32(/* id 14, wireType 0 =*/112).int32(message.operatorOfflineReason);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorInfo message, length delimited. Does not implicitly {@link infinitusai.be.OperatorInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {infinitusai.be.IOperatorInfo} message OperatorInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorInfo} OperatorInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorInfo(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.phone = reader.string();
                                break;
                            }
                        case 2: {
                                message.canReview = reader.bool();
                                break;
                            }
                        case 3: {
                                if (message.nexmoUserInfo === $util.emptyObject)
                                    message.nexmoUserInfo = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.NexmoUserInfo.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.nexmoUserInfo[key] = value;
                                break;
                            }
                        case 4: {
                                message.isAdmin = reader.bool();
                                break;
                            }
                        case 5: {
                                message.selectiveReviewEnabled = reader.bool();
                                break;
                            }
                        case 6: {
                                if (message.selectiveReviewOrgsEligible === $util.emptyObject)
                                    message.selectiveReviewOrgsEligible = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = false;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.bool();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.selectiveReviewOrgsEligible[key] = value;
                                break;
                            }
                        case 7: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 8: {
                                message.userID = reader.string();
                                break;
                            }
                        case 9: {
                                message.assembledUserID = reader.string();
                                break;
                            }
                        case 10: {
                                message.assembledAgentID = reader.string();
                                break;
                            }
                        case 11: {
                                message.lastStatusUpdateTimestampMillis = reader.int64();
                                break;
                            }
                        case 12: {
                                message.statusLastUpdatedFromUrl = reader.string();
                                break;
                            }
                        case 13: {
                                message.operatorOnlineStatus = reader.int32();
                                break;
                            }
                        case 14: {
                                message.operatorOfflineReason = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorInfo} OperatorInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorInfo message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.canReview != null && message.hasOwnProperty("canReview"))
                        if (typeof message.canReview !== "boolean")
                            return "canReview: boolean expected";
                    if (message.nexmoUserInfo != null && message.hasOwnProperty("nexmoUserInfo")) {
                        if (!$util.isObject(message.nexmoUserInfo))
                            return "nexmoUserInfo: object expected";
                        var key = Object.keys(message.nexmoUserInfo);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.NexmoUserInfo.verify(message.nexmoUserInfo[key[i]]);
                            if (error)
                                return "nexmoUserInfo." + error;
                        }
                    }
                    if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
                        if (typeof message.isAdmin !== "boolean")
                            return "isAdmin: boolean expected";
                    if (message.selectiveReviewEnabled != null && message.hasOwnProperty("selectiveReviewEnabled"))
                        if (typeof message.selectiveReviewEnabled !== "boolean")
                            return "selectiveReviewEnabled: boolean expected";
                    if (message.selectiveReviewOrgsEligible != null && message.hasOwnProperty("selectiveReviewOrgsEligible")) {
                        if (!$util.isObject(message.selectiveReviewOrgsEligible))
                            return "selectiveReviewOrgsEligible: object expected";
                        var key = Object.keys(message.selectiveReviewOrgsEligible);
                        for (var i = 0; i < key.length; ++i)
                            if (typeof message.selectiveReviewOrgsEligible[key[i]] !== "boolean")
                                return "selectiveReviewOrgsEligible: boolean{k:string} expected";
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.userID != null && message.hasOwnProperty("userID"))
                        if (!$util.isString(message.userID))
                            return "userID: string expected";
                    if (message.assembledUserID != null && message.hasOwnProperty("assembledUserID"))
                        if (!$util.isString(message.assembledUserID))
                            return "assembledUserID: string expected";
                    if (message.assembledAgentID != null && message.hasOwnProperty("assembledAgentID"))
                        if (!$util.isString(message.assembledAgentID))
                            return "assembledAgentID: string expected";
                    if (message.lastStatusUpdateTimestampMillis != null && message.hasOwnProperty("lastStatusUpdateTimestampMillis"))
                        if (!$util.isInteger(message.lastStatusUpdateTimestampMillis) && !(message.lastStatusUpdateTimestampMillis && $util.isInteger(message.lastStatusUpdateTimestampMillis.low) && $util.isInteger(message.lastStatusUpdateTimestampMillis.high)))
                            return "lastStatusUpdateTimestampMillis: integer|Long expected";
                    if (message.statusLastUpdatedFromUrl != null && message.hasOwnProperty("statusLastUpdatedFromUrl"))
                        if (!$util.isString(message.statusLastUpdatedFromUrl))
                            return "statusLastUpdatedFromUrl: string expected";
                    if (message.operatorOnlineStatus != null && message.hasOwnProperty("operatorOnlineStatus"))
                        switch (message.operatorOnlineStatus) {
                        default:
                            return "operatorOnlineStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.operatorOfflineReason != null && message.hasOwnProperty("operatorOfflineReason"))
                        switch (message.operatorOfflineReason) {
                        default:
                            return "operatorOfflineReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an OperatorInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorInfo} OperatorInfo
                 */
                OperatorInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorInfo)
                        return object;
                    var message = new $root.infinitusai.be.OperatorInfo();
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.canReview != null)
                        message.canReview = Boolean(object.canReview);
                    if (object.nexmoUserInfo) {
                        if (typeof object.nexmoUserInfo !== "object")
                            throw TypeError(".infinitusai.be.OperatorInfo.nexmoUserInfo: object expected");
                        message.nexmoUserInfo = {};
                        for (var keys = Object.keys(object.nexmoUserInfo), i = 0; i < keys.length; ++i) {
                            if (typeof object.nexmoUserInfo[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.OperatorInfo.nexmoUserInfo: object expected");
                            message.nexmoUserInfo[keys[i]] = $root.infinitusai.be.NexmoUserInfo.fromObject(object.nexmoUserInfo[keys[i]]);
                        }
                    }
                    if (object.isAdmin != null)
                        message.isAdmin = Boolean(object.isAdmin);
                    if (object.selectiveReviewEnabled != null)
                        message.selectiveReviewEnabled = Boolean(object.selectiveReviewEnabled);
                    if (object.selectiveReviewOrgsEligible) {
                        if (typeof object.selectiveReviewOrgsEligible !== "object")
                            throw TypeError(".infinitusai.be.OperatorInfo.selectiveReviewOrgsEligible: object expected");
                        message.selectiveReviewOrgsEligible = {};
                        for (var keys = Object.keys(object.selectiveReviewOrgsEligible), i = 0; i < keys.length; ++i)
                            message.selectiveReviewOrgsEligible[keys[i]] = Boolean(object.selectiveReviewOrgsEligible[keys[i]]);
                    }
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    if (object.userID != null)
                        message.userID = String(object.userID);
                    if (object.assembledUserID != null)
                        message.assembledUserID = String(object.assembledUserID);
                    if (object.assembledAgentID != null)
                        message.assembledAgentID = String(object.assembledAgentID);
                    if (object.lastStatusUpdateTimestampMillis != null)
                        if ($util.Long)
                            (message.lastStatusUpdateTimestampMillis = $util.Long.fromValue(object.lastStatusUpdateTimestampMillis)).unsigned = false;
                        else if (typeof object.lastStatusUpdateTimestampMillis === "string")
                            message.lastStatusUpdateTimestampMillis = parseInt(object.lastStatusUpdateTimestampMillis, 10);
                        else if (typeof object.lastStatusUpdateTimestampMillis === "number")
                            message.lastStatusUpdateTimestampMillis = object.lastStatusUpdateTimestampMillis;
                        else if (typeof object.lastStatusUpdateTimestampMillis === "object")
                            message.lastStatusUpdateTimestampMillis = new $util.LongBits(object.lastStatusUpdateTimestampMillis.low >>> 0, object.lastStatusUpdateTimestampMillis.high >>> 0).toNumber();
                    if (object.statusLastUpdatedFromUrl != null)
                        message.statusLastUpdatedFromUrl = String(object.statusLastUpdatedFromUrl);
                    switch (object.operatorOnlineStatus) {
                    default:
                        if (typeof object.operatorOnlineStatus === "number") {
                            message.operatorOnlineStatus = object.operatorOnlineStatus;
                            break;
                        }
                        break;
                    case "OPERATOR_ONLINE_STATUS_UNKNOWN":
                    case 0:
                        message.operatorOnlineStatus = 0;
                        break;
                    case "OPERATOR_ONLINE_STATUS_OFFLINE":
                    case 1:
                        message.operatorOnlineStatus = 1;
                        break;
                    case "OPERATOR_ONLINE_STATUS_ONLINE":
                    case 2:
                        message.operatorOnlineStatus = 2;
                        break;
                    }
                    switch (object.operatorOfflineReason) {
                    default:
                        if (typeof object.operatorOfflineReason === "number") {
                            message.operatorOfflineReason = object.operatorOfflineReason;
                            break;
                        }
                        break;
                    case "OPERATOR_OFFLINE_REASON_UNKNOWN":
                    case 0:
                        message.operatorOfflineReason = 0;
                        break;
                    case "OPERATOR_OFFLINE_REASON_MEETING":
                    case 1:
                        message.operatorOfflineReason = 1;
                        break;
                    case "OPERATOR_OFFLINE_REASON_MANAGER_MEETING":
                    case 2:
                        message.operatorOfflineReason = 2;
                        break;
                    case "OPERATOR_OFFLINE_REASON_PROJECT":
                    case 3:
                        message.operatorOfflineReason = 3;
                        break;
                    case "OPERATOR_OFFLINE_REASON_LUNCH":
                    case 4:
                        message.operatorOfflineReason = 4;
                        break;
                    case "OPERATOR_OFFLINE_REASON_BREAK":
                    case 5:
                        message.operatorOfflineReason = 5;
                        break;
                    case "OPERATOR_OFFLINE_REASON_DONE_TODAY":
                    case 6:
                        message.operatorOfflineReason = 6;
                        break;
                    case "OPERATOR_OFFLINE_REASON_OTHER":
                    case 7:
                        message.operatorOfflineReason = 7;
                        break;
                    case "OPERATOR_OFFLINE_REASON_NULL":
                    case 8:
                        message.operatorOfflineReason = 8;
                        break;
                    case "OPERATOR_OFFLINE_REASON_INACTIVE":
                    case 9:
                        message.operatorOfflineReason = 9;
                        break;
                    case "OPERATOR_OFFLINE_REASON_TRAINING":
                    case 10:
                        message.operatorOfflineReason = 10;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {infinitusai.be.OperatorInfo} message OperatorInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults) {
                        object.nexmoUserInfo = {};
                        object.selectiveReviewOrgsEligible = {};
                    }
                    if (options.defaults) {
                        object.phone = "";
                        object.canReview = false;
                        object.isAdmin = false;
                        object.selectiveReviewEnabled = false;
                        object.userEmail = "";
                        object.userID = "";
                        object.assembledUserID = "";
                        object.assembledAgentID = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? "0" : 0;
                        object.statusLastUpdatedFromUrl = "";
                        object.operatorOnlineStatus = options.enums === String ? "OPERATOR_ONLINE_STATUS_UNKNOWN" : 0;
                        object.operatorOfflineReason = options.enums === String ? "OPERATOR_OFFLINE_REASON_UNKNOWN" : 0;
                    }
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.canReview != null && message.hasOwnProperty("canReview"))
                        object.canReview = message.canReview;
                    var keys2;
                    if (message.nexmoUserInfo && (keys2 = Object.keys(message.nexmoUserInfo)).length) {
                        object.nexmoUserInfo = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.nexmoUserInfo[keys2[j]] = $root.infinitusai.be.NexmoUserInfo.toObject(message.nexmoUserInfo[keys2[j]], options);
                    }
                    if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
                        object.isAdmin = message.isAdmin;
                    if (message.selectiveReviewEnabled != null && message.hasOwnProperty("selectiveReviewEnabled"))
                        object.selectiveReviewEnabled = message.selectiveReviewEnabled;
                    if (message.selectiveReviewOrgsEligible && (keys2 = Object.keys(message.selectiveReviewOrgsEligible)).length) {
                        object.selectiveReviewOrgsEligible = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.selectiveReviewOrgsEligible[keys2[j]] = message.selectiveReviewOrgsEligible[keys2[j]];
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.userID != null && message.hasOwnProperty("userID"))
                        object.userID = message.userID;
                    if (message.assembledUserID != null && message.hasOwnProperty("assembledUserID"))
                        object.assembledUserID = message.assembledUserID;
                    if (message.assembledAgentID != null && message.hasOwnProperty("assembledAgentID"))
                        object.assembledAgentID = message.assembledAgentID;
                    if (message.lastStatusUpdateTimestampMillis != null && message.hasOwnProperty("lastStatusUpdateTimestampMillis"))
                        if (typeof message.lastStatusUpdateTimestampMillis === "number")
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? String(message.lastStatusUpdateTimestampMillis) : message.lastStatusUpdateTimestampMillis;
                        else
                            object.lastStatusUpdateTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastStatusUpdateTimestampMillis) : options.longs === Number ? new $util.LongBits(message.lastStatusUpdateTimestampMillis.low >>> 0, message.lastStatusUpdateTimestampMillis.high >>> 0).toNumber() : message.lastStatusUpdateTimestampMillis;
                    if (message.statusLastUpdatedFromUrl != null && message.hasOwnProperty("statusLastUpdatedFromUrl"))
                        object.statusLastUpdatedFromUrl = message.statusLastUpdatedFromUrl;
                    if (message.operatorOnlineStatus != null && message.hasOwnProperty("operatorOnlineStatus"))
                        object.operatorOnlineStatus = options.enums === String ? $root.infinitusai.be.OperatorOnlineStatus[message.operatorOnlineStatus] === undefined ? message.operatorOnlineStatus : $root.infinitusai.be.OperatorOnlineStatus[message.operatorOnlineStatus] : message.operatorOnlineStatus;
                    if (message.operatorOfflineReason != null && message.hasOwnProperty("operatorOfflineReason"))
                        object.operatorOfflineReason = options.enums === String ? $root.infinitusai.be.OperatorOfflineReason[message.operatorOfflineReason] === undefined ? message.operatorOfflineReason : $root.infinitusai.be.OperatorOfflineReason[message.operatorOfflineReason] : message.operatorOfflineReason;
                    return object;
                };
    
                /**
                 * Converts this OperatorInfo to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorInfo
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorInfo";
                };
    
                return OperatorInfo;
            })();
    
            be.OperatorsDoc = (function() {
    
                /**
                 * Properties of an OperatorsDoc.
                 * @memberof infinitusai.be
                 * @interface IOperatorsDoc
                 * @property {Object.<string,infinitusai.be.IOperatorInfo>|null} [operators] OperatorsDoc operators
                 * @property {number|null} [operatorInfoMigrationRollout] OperatorsDoc operatorInfoMigrationRollout
                 */
    
                /**
                 * Constructs a new OperatorsDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorsDoc.
                 * @implements IOperatorsDoc
                 * @constructor
                 * @param {infinitusai.be.IOperatorsDoc=} [properties] Properties to set
                 */
                function OperatorsDoc(properties) {
                    this.operators = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorsDoc operators.
                 * @member {Object.<string,infinitusai.be.IOperatorInfo>} operators
                 * @memberof infinitusai.be.OperatorsDoc
                 * @instance
                 */
                OperatorsDoc.prototype.operators = $util.emptyObject;
    
                /**
                 * OperatorsDoc operatorInfoMigrationRollout.
                 * @member {number} operatorInfoMigrationRollout
                 * @memberof infinitusai.be.OperatorsDoc
                 * @instance
                 */
                OperatorsDoc.prototype.operatorInfoMigrationRollout = 0;
    
                /**
                 * Creates a new OperatorsDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {infinitusai.be.IOperatorsDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorsDoc} OperatorsDoc instance
                 */
                OperatorsDoc.create = function create(properties) {
                    return new OperatorsDoc(properties);
                };
    
                /**
                 * Encodes the specified OperatorsDoc message. Does not implicitly {@link infinitusai.be.OperatorsDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {infinitusai.be.IOperatorsDoc} message OperatorsDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorsDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operators != null && Object.hasOwnProperty.call(message, "operators"))
                        for (var keys = Object.keys(message.operators), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.OperatorInfo.encode(message.operators[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.operatorInfoMigrationRollout != null && Object.hasOwnProperty.call(message, "operatorInfoMigrationRollout"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.operatorInfoMigrationRollout);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorsDoc message, length delimited. Does not implicitly {@link infinitusai.be.OperatorsDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {infinitusai.be.IOperatorsDoc} message OperatorsDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorsDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorsDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorsDoc} OperatorsDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorsDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorsDoc(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.operators === $util.emptyObject)
                                    message.operators = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.OperatorInfo.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.operators[key] = value;
                                break;
                            }
                        case 2: {
                                message.operatorInfoMigrationRollout = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorsDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorsDoc} OperatorsDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorsDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorsDoc message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorsDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operators != null && message.hasOwnProperty("operators")) {
                        if (!$util.isObject(message.operators))
                            return "operators: object expected";
                        var key = Object.keys(message.operators);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.OperatorInfo.verify(message.operators[key[i]]);
                            if (error)
                                return "operators." + error;
                        }
                    }
                    if (message.operatorInfoMigrationRollout != null && message.hasOwnProperty("operatorInfoMigrationRollout"))
                        if (typeof message.operatorInfoMigrationRollout !== "number")
                            return "operatorInfoMigrationRollout: number expected";
                    return null;
                };
    
                /**
                 * Creates an OperatorsDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorsDoc} OperatorsDoc
                 */
                OperatorsDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorsDoc)
                        return object;
                    var message = new $root.infinitusai.be.OperatorsDoc();
                    if (object.operators) {
                        if (typeof object.operators !== "object")
                            throw TypeError(".infinitusai.be.OperatorsDoc.operators: object expected");
                        message.operators = {};
                        for (var keys = Object.keys(object.operators), i = 0; i < keys.length; ++i) {
                            if (typeof object.operators[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.OperatorsDoc.operators: object expected");
                            message.operators[keys[i]] = $root.infinitusai.be.OperatorInfo.fromObject(object.operators[keys[i]]);
                        }
                    }
                    if (object.operatorInfoMigrationRollout != null)
                        message.operatorInfoMigrationRollout = Number(object.operatorInfoMigrationRollout);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorsDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {infinitusai.be.OperatorsDoc} message OperatorsDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorsDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.operators = {};
                    if (options.defaults)
                        object.operatorInfoMigrationRollout = 0;
                    var keys2;
                    if (message.operators && (keys2 = Object.keys(message.operators)).length) {
                        object.operators = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.operators[keys2[j]] = $root.infinitusai.be.OperatorInfo.toObject(message.operators[keys2[j]], options);
                    }
                    if (message.operatorInfoMigrationRollout != null && message.hasOwnProperty("operatorInfoMigrationRollout"))
                        object.operatorInfoMigrationRollout = options.json && !isFinite(message.operatorInfoMigrationRollout) ? String(message.operatorInfoMigrationRollout) : message.operatorInfoMigrationRollout;
                    return object;
                };
    
                /**
                 * Converts this OperatorsDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorsDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorsDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorsDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorsDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorsDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorsDoc";
                };
    
                return OperatorsDoc;
            })();
    
            be.MarkOperatorUnavailableRequest = (function() {
    
                /**
                 * Properties of a MarkOperatorUnavailableRequest.
                 * @memberof infinitusai.be
                 * @interface IMarkOperatorUnavailableRequest
                 * @property {string|null} [orgUuid] MarkOperatorUnavailableRequest orgUuid
                 */
    
                /**
                 * Constructs a new MarkOperatorUnavailableRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MarkOperatorUnavailableRequest.
                 * @implements IMarkOperatorUnavailableRequest
                 * @constructor
                 * @param {infinitusai.be.IMarkOperatorUnavailableRequest=} [properties] Properties to set
                 */
                function MarkOperatorUnavailableRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MarkOperatorUnavailableRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @instance
                 */
                MarkOperatorUnavailableRequest.prototype.orgUuid = "";
    
                /**
                 * Creates a new MarkOperatorUnavailableRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {infinitusai.be.IMarkOperatorUnavailableRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.MarkOperatorUnavailableRequest} MarkOperatorUnavailableRequest instance
                 */
                MarkOperatorUnavailableRequest.create = function create(properties) {
                    return new MarkOperatorUnavailableRequest(properties);
                };
    
                /**
                 * Encodes the specified MarkOperatorUnavailableRequest message. Does not implicitly {@link infinitusai.be.MarkOperatorUnavailableRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {infinitusai.be.IMarkOperatorUnavailableRequest} message MarkOperatorUnavailableRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkOperatorUnavailableRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified MarkOperatorUnavailableRequest message, length delimited. Does not implicitly {@link infinitusai.be.MarkOperatorUnavailableRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {infinitusai.be.IMarkOperatorUnavailableRequest} message MarkOperatorUnavailableRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkOperatorUnavailableRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MarkOperatorUnavailableRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MarkOperatorUnavailableRequest} MarkOperatorUnavailableRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkOperatorUnavailableRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MarkOperatorUnavailableRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MarkOperatorUnavailableRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MarkOperatorUnavailableRequest} MarkOperatorUnavailableRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkOperatorUnavailableRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MarkOperatorUnavailableRequest message.
                 * @function verify
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MarkOperatorUnavailableRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a MarkOperatorUnavailableRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MarkOperatorUnavailableRequest} MarkOperatorUnavailableRequest
                 */
                MarkOperatorUnavailableRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MarkOperatorUnavailableRequest)
                        return object;
                    var message = new $root.infinitusai.be.MarkOperatorUnavailableRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a MarkOperatorUnavailableRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {infinitusai.be.MarkOperatorUnavailableRequest} message MarkOperatorUnavailableRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MarkOperatorUnavailableRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.orgUuid = "";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this MarkOperatorUnavailableRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MarkOperatorUnavailableRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MarkOperatorUnavailableRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MarkOperatorUnavailableRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MarkOperatorUnavailableRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MarkOperatorUnavailableRequest";
                };
    
                return MarkOperatorUnavailableRequest;
            })();
    
            be.ReinstateHeadlessRequest = (function() {
    
                /**
                 * Properties of a ReinstateHeadlessRequest.
                 * @memberof infinitusai.be
                 * @interface IReinstateHeadlessRequest
                 * @property {string|null} [taskUuid] ReinstateHeadlessRequest taskUuid
                 * @property {string|null} [callUuid] ReinstateHeadlessRequest callUuid
                 * @property {boolean|null} [useRecordedGreeting] ReinstateHeadlessRequest useRecordedGreeting
                 */
    
                /**
                 * Constructs a new ReinstateHeadlessRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReinstateHeadlessRequest.
                 * @implements IReinstateHeadlessRequest
                 * @constructor
                 * @param {infinitusai.be.IReinstateHeadlessRequest=} [properties] Properties to set
                 */
                function ReinstateHeadlessRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ReinstateHeadlessRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @instance
                 */
                ReinstateHeadlessRequest.prototype.taskUuid = "";
    
                /**
                 * ReinstateHeadlessRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @instance
                 */
                ReinstateHeadlessRequest.prototype.callUuid = "";
    
                /**
                 * ReinstateHeadlessRequest useRecordedGreeting.
                 * @member {boolean} useRecordedGreeting
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @instance
                 */
                ReinstateHeadlessRequest.prototype.useRecordedGreeting = false;
    
                /**
                 * Creates a new ReinstateHeadlessRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {infinitusai.be.IReinstateHeadlessRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ReinstateHeadlessRequest} ReinstateHeadlessRequest instance
                 */
                ReinstateHeadlessRequest.create = function create(properties) {
                    return new ReinstateHeadlessRequest(properties);
                };
    
                /**
                 * Encodes the specified ReinstateHeadlessRequest message. Does not implicitly {@link infinitusai.be.ReinstateHeadlessRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {infinitusai.be.IReinstateHeadlessRequest} message ReinstateHeadlessRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReinstateHeadlessRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.useRecordedGreeting != null && Object.hasOwnProperty.call(message, "useRecordedGreeting"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.useRecordedGreeting);
                    return writer;
                };
    
                /**
                 * Encodes the specified ReinstateHeadlessRequest message, length delimited. Does not implicitly {@link infinitusai.be.ReinstateHeadlessRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {infinitusai.be.IReinstateHeadlessRequest} message ReinstateHeadlessRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReinstateHeadlessRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReinstateHeadlessRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReinstateHeadlessRequest} ReinstateHeadlessRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReinstateHeadlessRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReinstateHeadlessRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.useRecordedGreeting = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReinstateHeadlessRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReinstateHeadlessRequest} ReinstateHeadlessRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReinstateHeadlessRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReinstateHeadlessRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReinstateHeadlessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.useRecordedGreeting != null && message.hasOwnProperty("useRecordedGreeting"))
                        if (typeof message.useRecordedGreeting !== "boolean")
                            return "useRecordedGreeting: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a ReinstateHeadlessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReinstateHeadlessRequest} ReinstateHeadlessRequest
                 */
                ReinstateHeadlessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReinstateHeadlessRequest)
                        return object;
                    var message = new $root.infinitusai.be.ReinstateHeadlessRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.useRecordedGreeting != null)
                        message.useRecordedGreeting = Boolean(object.useRecordedGreeting);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ReinstateHeadlessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {infinitusai.be.ReinstateHeadlessRequest} message ReinstateHeadlessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReinstateHeadlessRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.useRecordedGreeting = false;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.useRecordedGreeting != null && message.hasOwnProperty("useRecordedGreeting"))
                        object.useRecordedGreeting = message.useRecordedGreeting;
                    return object;
                };
    
                /**
                 * Converts this ReinstateHeadlessRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReinstateHeadlessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReinstateHeadlessRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReinstateHeadlessRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReinstateHeadlessRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReinstateHeadlessRequest";
                };
    
                return ReinstateHeadlessRequest;
            })();
    
            be.RedirectAfterProcessCallRequest = (function() {
    
                /**
                 * Properties of a RedirectAfterProcessCallRequest.
                 * @memberof infinitusai.be
                 * @interface IRedirectAfterProcessCallRequest
                 * @property {string|null} [taskUuid] RedirectAfterProcessCallRequest taskUuid
                 * @property {string|null} [callUuid] RedirectAfterProcessCallRequest callUuid
                 */
    
                /**
                 * Constructs a new RedirectAfterProcessCallRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RedirectAfterProcessCallRequest.
                 * @implements IRedirectAfterProcessCallRequest
                 * @constructor
                 * @param {infinitusai.be.IRedirectAfterProcessCallRequest=} [properties] Properties to set
                 */
                function RedirectAfterProcessCallRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RedirectAfterProcessCallRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @instance
                 */
                RedirectAfterProcessCallRequest.prototype.taskUuid = "";
    
                /**
                 * RedirectAfterProcessCallRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @instance
                 */
                RedirectAfterProcessCallRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new RedirectAfterProcessCallRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {infinitusai.be.IRedirectAfterProcessCallRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RedirectAfterProcessCallRequest} RedirectAfterProcessCallRequest instance
                 */
                RedirectAfterProcessCallRequest.create = function create(properties) {
                    return new RedirectAfterProcessCallRequest(properties);
                };
    
                /**
                 * Encodes the specified RedirectAfterProcessCallRequest message. Does not implicitly {@link infinitusai.be.RedirectAfterProcessCallRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {infinitusai.be.IRedirectAfterProcessCallRequest} message RedirectAfterProcessCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedirectAfterProcessCallRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RedirectAfterProcessCallRequest message, length delimited. Does not implicitly {@link infinitusai.be.RedirectAfterProcessCallRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {infinitusai.be.IRedirectAfterProcessCallRequest} message RedirectAfterProcessCallRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedirectAfterProcessCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RedirectAfterProcessCallRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RedirectAfterProcessCallRequest} RedirectAfterProcessCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedirectAfterProcessCallRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RedirectAfterProcessCallRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RedirectAfterProcessCallRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RedirectAfterProcessCallRequest} RedirectAfterProcessCallRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedirectAfterProcessCallRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RedirectAfterProcessCallRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedirectAfterProcessCallRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RedirectAfterProcessCallRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RedirectAfterProcessCallRequest} RedirectAfterProcessCallRequest
                 */
                RedirectAfterProcessCallRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RedirectAfterProcessCallRequest)
                        return object;
                    var message = new $root.infinitusai.be.RedirectAfterProcessCallRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RedirectAfterProcessCallRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {infinitusai.be.RedirectAfterProcessCallRequest} message RedirectAfterProcessCallRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedirectAfterProcessCallRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this RedirectAfterProcessCallRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedirectAfterProcessCallRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RedirectAfterProcessCallRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RedirectAfterProcessCallRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedirectAfterProcessCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RedirectAfterProcessCallRequest";
                };
    
                return RedirectAfterProcessCallRequest;
            })();
    
            be.RedirectAfterProcessCallResponse = (function() {
    
                /**
                 * Properties of a RedirectAfterProcessCallResponse.
                 * @memberof infinitusai.be
                 * @interface IRedirectAfterProcessCallResponse
                 * @property {string|null} [taskUuid] RedirectAfterProcessCallResponse taskUuid
                 * @property {string|null} [callUuid] RedirectAfterProcessCallResponse callUuid
                 */
    
                /**
                 * Constructs a new RedirectAfterProcessCallResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RedirectAfterProcessCallResponse.
                 * @implements IRedirectAfterProcessCallResponse
                 * @constructor
                 * @param {infinitusai.be.IRedirectAfterProcessCallResponse=} [properties] Properties to set
                 */
                function RedirectAfterProcessCallResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RedirectAfterProcessCallResponse taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @instance
                 */
                RedirectAfterProcessCallResponse.prototype.taskUuid = "";
    
                /**
                 * RedirectAfterProcessCallResponse callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @instance
                 */
                RedirectAfterProcessCallResponse.prototype.callUuid = "";
    
                /**
                 * Creates a new RedirectAfterProcessCallResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {infinitusai.be.IRedirectAfterProcessCallResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RedirectAfterProcessCallResponse} RedirectAfterProcessCallResponse instance
                 */
                RedirectAfterProcessCallResponse.create = function create(properties) {
                    return new RedirectAfterProcessCallResponse(properties);
                };
    
                /**
                 * Encodes the specified RedirectAfterProcessCallResponse message. Does not implicitly {@link infinitusai.be.RedirectAfterProcessCallResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {infinitusai.be.IRedirectAfterProcessCallResponse} message RedirectAfterProcessCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedirectAfterProcessCallResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RedirectAfterProcessCallResponse message, length delimited. Does not implicitly {@link infinitusai.be.RedirectAfterProcessCallResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {infinitusai.be.IRedirectAfterProcessCallResponse} message RedirectAfterProcessCallResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedirectAfterProcessCallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RedirectAfterProcessCallResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RedirectAfterProcessCallResponse} RedirectAfterProcessCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedirectAfterProcessCallResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RedirectAfterProcessCallResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RedirectAfterProcessCallResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RedirectAfterProcessCallResponse} RedirectAfterProcessCallResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedirectAfterProcessCallResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RedirectAfterProcessCallResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedirectAfterProcessCallResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RedirectAfterProcessCallResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RedirectAfterProcessCallResponse} RedirectAfterProcessCallResponse
                 */
                RedirectAfterProcessCallResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RedirectAfterProcessCallResponse)
                        return object;
                    var message = new $root.infinitusai.be.RedirectAfterProcessCallResponse();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RedirectAfterProcessCallResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {infinitusai.be.RedirectAfterProcessCallResponse} message RedirectAfterProcessCallResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedirectAfterProcessCallResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this RedirectAfterProcessCallResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedirectAfterProcessCallResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RedirectAfterProcessCallResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RedirectAfterProcessCallResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedirectAfterProcessCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RedirectAfterProcessCallResponse";
                };
    
                return RedirectAfterProcessCallResponse;
            })();
    
            be.RedirectInCallPhaseHoldRequest = (function() {
    
                /**
                 * Properties of a RedirectInCallPhaseHoldRequest.
                 * @memberof infinitusai.be
                 * @interface IRedirectInCallPhaseHoldRequest
                 * @property {string|null} [taskUuid] RedirectInCallPhaseHoldRequest taskUuid
                 * @property {string|null} [callUuid] RedirectInCallPhaseHoldRequest callUuid
                 */
    
                /**
                 * Constructs a new RedirectInCallPhaseHoldRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RedirectInCallPhaseHoldRequest.
                 * @implements IRedirectInCallPhaseHoldRequest
                 * @constructor
                 * @param {infinitusai.be.IRedirectInCallPhaseHoldRequest=} [properties] Properties to set
                 */
                function RedirectInCallPhaseHoldRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RedirectInCallPhaseHoldRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @instance
                 */
                RedirectInCallPhaseHoldRequest.prototype.taskUuid = "";
    
                /**
                 * RedirectInCallPhaseHoldRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @instance
                 */
                RedirectInCallPhaseHoldRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new RedirectInCallPhaseHoldRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {infinitusai.be.IRedirectInCallPhaseHoldRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RedirectInCallPhaseHoldRequest} RedirectInCallPhaseHoldRequest instance
                 */
                RedirectInCallPhaseHoldRequest.create = function create(properties) {
                    return new RedirectInCallPhaseHoldRequest(properties);
                };
    
                /**
                 * Encodes the specified RedirectInCallPhaseHoldRequest message. Does not implicitly {@link infinitusai.be.RedirectInCallPhaseHoldRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {infinitusai.be.IRedirectInCallPhaseHoldRequest} message RedirectInCallPhaseHoldRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedirectInCallPhaseHoldRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RedirectInCallPhaseHoldRequest message, length delimited. Does not implicitly {@link infinitusai.be.RedirectInCallPhaseHoldRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {infinitusai.be.IRedirectInCallPhaseHoldRequest} message RedirectInCallPhaseHoldRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedirectInCallPhaseHoldRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RedirectInCallPhaseHoldRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RedirectInCallPhaseHoldRequest} RedirectInCallPhaseHoldRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedirectInCallPhaseHoldRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RedirectInCallPhaseHoldRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RedirectInCallPhaseHoldRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RedirectInCallPhaseHoldRequest} RedirectInCallPhaseHoldRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedirectInCallPhaseHoldRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RedirectInCallPhaseHoldRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedirectInCallPhaseHoldRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RedirectInCallPhaseHoldRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RedirectInCallPhaseHoldRequest} RedirectInCallPhaseHoldRequest
                 */
                RedirectInCallPhaseHoldRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RedirectInCallPhaseHoldRequest)
                        return object;
                    var message = new $root.infinitusai.be.RedirectInCallPhaseHoldRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RedirectInCallPhaseHoldRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {infinitusai.be.RedirectInCallPhaseHoldRequest} message RedirectInCallPhaseHoldRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedirectInCallPhaseHoldRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this RedirectInCallPhaseHoldRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedirectInCallPhaseHoldRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RedirectInCallPhaseHoldRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedirectInCallPhaseHoldRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RedirectInCallPhaseHoldRequest";
                };
    
                return RedirectInCallPhaseHoldRequest;
            })();
    
            be.RedirectInCallPhaseHoldResponse = (function() {
    
                /**
                 * Properties of a RedirectInCallPhaseHoldResponse.
                 * @memberof infinitusai.be
                 * @interface IRedirectInCallPhaseHoldResponse
                 * @property {boolean|null} [shouldRedirect] RedirectInCallPhaseHoldResponse shouldRedirect
                 */
    
                /**
                 * Constructs a new RedirectInCallPhaseHoldResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RedirectInCallPhaseHoldResponse.
                 * @implements IRedirectInCallPhaseHoldResponse
                 * @constructor
                 * @param {infinitusai.be.IRedirectInCallPhaseHoldResponse=} [properties] Properties to set
                 */
                function RedirectInCallPhaseHoldResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RedirectInCallPhaseHoldResponse shouldRedirect.
                 * @member {boolean} shouldRedirect
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @instance
                 */
                RedirectInCallPhaseHoldResponse.prototype.shouldRedirect = false;
    
                /**
                 * Creates a new RedirectInCallPhaseHoldResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {infinitusai.be.IRedirectInCallPhaseHoldResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RedirectInCallPhaseHoldResponse} RedirectInCallPhaseHoldResponse instance
                 */
                RedirectInCallPhaseHoldResponse.create = function create(properties) {
                    return new RedirectInCallPhaseHoldResponse(properties);
                };
    
                /**
                 * Encodes the specified RedirectInCallPhaseHoldResponse message. Does not implicitly {@link infinitusai.be.RedirectInCallPhaseHoldResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {infinitusai.be.IRedirectInCallPhaseHoldResponse} message RedirectInCallPhaseHoldResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedirectInCallPhaseHoldResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.shouldRedirect != null && Object.hasOwnProperty.call(message, "shouldRedirect"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.shouldRedirect);
                    return writer;
                };
    
                /**
                 * Encodes the specified RedirectInCallPhaseHoldResponse message, length delimited. Does not implicitly {@link infinitusai.be.RedirectInCallPhaseHoldResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {infinitusai.be.IRedirectInCallPhaseHoldResponse} message RedirectInCallPhaseHoldResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedirectInCallPhaseHoldResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RedirectInCallPhaseHoldResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RedirectInCallPhaseHoldResponse} RedirectInCallPhaseHoldResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedirectInCallPhaseHoldResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RedirectInCallPhaseHoldResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.shouldRedirect = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RedirectInCallPhaseHoldResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RedirectInCallPhaseHoldResponse} RedirectInCallPhaseHoldResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedirectInCallPhaseHoldResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RedirectInCallPhaseHoldResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedirectInCallPhaseHoldResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.shouldRedirect != null && message.hasOwnProperty("shouldRedirect"))
                        if (typeof message.shouldRedirect !== "boolean")
                            return "shouldRedirect: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a RedirectInCallPhaseHoldResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RedirectInCallPhaseHoldResponse} RedirectInCallPhaseHoldResponse
                 */
                RedirectInCallPhaseHoldResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RedirectInCallPhaseHoldResponse)
                        return object;
                    var message = new $root.infinitusai.be.RedirectInCallPhaseHoldResponse();
                    if (object.shouldRedirect != null)
                        message.shouldRedirect = Boolean(object.shouldRedirect);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RedirectInCallPhaseHoldResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {infinitusai.be.RedirectInCallPhaseHoldResponse} message RedirectInCallPhaseHoldResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedirectInCallPhaseHoldResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.shouldRedirect = false;
                    if (message.shouldRedirect != null && message.hasOwnProperty("shouldRedirect"))
                        object.shouldRedirect = message.shouldRedirect;
                    return object;
                };
    
                /**
                 * Converts this RedirectInCallPhaseHoldResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedirectInCallPhaseHoldResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RedirectInCallPhaseHoldResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RedirectInCallPhaseHoldResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedirectInCallPhaseHoldResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RedirectInCallPhaseHoldResponse";
                };
    
                return RedirectInCallPhaseHoldResponse;
            })();
    
            be.OrgApiOutputConfig = (function() {
    
                /**
                 * Properties of an OrgApiOutputConfig.
                 * @memberof infinitusai.be
                 * @interface IOrgApiOutputConfig
                 * @property {number|null} [id] OrgApiOutputConfig id
                 * @property {string|null} [orgUuid] OrgApiOutputConfig orgUuid
                 * @property {string|null} [taskType] OrgApiOutputConfig taskType
                 * @property {string|null} [programId] OrgApiOutputConfig programId
                 * @property {string|null} [apiOutputFieldPath] OrgApiOutputConfig apiOutputFieldPath
                 * @property {boolean|null} [requiredForCompletion] OrgApiOutputConfig requiredForCompletion
                 * @property {string|null} [customLabel] OrgApiOutputConfig customLabel
                 * @property {number|Long|null} [createTimeMillis] OrgApiOutputConfig createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] OrgApiOutputConfig updateTimeMillis
                 * @property {boolean|null} [enabled] OrgApiOutputConfig enabled
                 * @property {number|null} [csvMaxArraySize] OrgApiOutputConfig csvMaxArraySize
                 */
    
                /**
                 * Constructs a new OrgApiOutputConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrgApiOutputConfig.
                 * @implements IOrgApiOutputConfig
                 * @constructor
                 * @param {infinitusai.be.IOrgApiOutputConfig=} [properties] Properties to set
                 */
                function OrgApiOutputConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgApiOutputConfig id.
                 * @member {number} id
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.id = 0;
    
                /**
                 * OrgApiOutputConfig orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.orgUuid = "";
    
                /**
                 * OrgApiOutputConfig taskType.
                 * @member {string} taskType
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.taskType = "";
    
                /**
                 * OrgApiOutputConfig programId.
                 * @member {string} programId
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.programId = "";
    
                /**
                 * OrgApiOutputConfig apiOutputFieldPath.
                 * @member {string} apiOutputFieldPath
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.apiOutputFieldPath = "";
    
                /**
                 * OrgApiOutputConfig requiredForCompletion.
                 * @member {boolean} requiredForCompletion
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.requiredForCompletion = false;
    
                /**
                 * OrgApiOutputConfig customLabel.
                 * @member {string} customLabel
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.customLabel = "";
    
                /**
                 * OrgApiOutputConfig createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * OrgApiOutputConfig updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * OrgApiOutputConfig enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.enabled = false;
    
                /**
                 * OrgApiOutputConfig csvMaxArraySize.
                 * @member {number} csvMaxArraySize
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 */
                OrgApiOutputConfig.prototype.csvMaxArraySize = 0;
    
                /**
                 * Creates a new OrgApiOutputConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {infinitusai.be.IOrgApiOutputConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.OrgApiOutputConfig} OrgApiOutputConfig instance
                 */
                OrgApiOutputConfig.create = function create(properties) {
                    return new OrgApiOutputConfig(properties);
                };
    
                /**
                 * Encodes the specified OrgApiOutputConfig message. Does not implicitly {@link infinitusai.be.OrgApiOutputConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {infinitusai.be.IOrgApiOutputConfig} message OrgApiOutputConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgApiOutputConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuid);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskType);
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.programId);
                    if (message.apiOutputFieldPath != null && Object.hasOwnProperty.call(message, "apiOutputFieldPath"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.apiOutputFieldPath);
                    if (message.requiredForCompletion != null && Object.hasOwnProperty.call(message, "requiredForCompletion"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.requiredForCompletion);
                    if (message.customLabel != null && Object.hasOwnProperty.call(message, "customLabel"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.customLabel);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.updateTimeMillis);
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.enabled);
                    if (message.csvMaxArraySize != null && Object.hasOwnProperty.call(message, "csvMaxArraySize"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.csvMaxArraySize);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgApiOutputConfig message, length delimited. Does not implicitly {@link infinitusai.be.OrgApiOutputConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {infinitusai.be.IOrgApiOutputConfig} message OrgApiOutputConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgApiOutputConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgApiOutputConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrgApiOutputConfig} OrgApiOutputConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgApiOutputConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrgApiOutputConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int32();
                                break;
                            }
                        case 2: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskType = reader.string();
                                break;
                            }
                        case 4: {
                                message.programId = reader.string();
                                break;
                            }
                        case 5: {
                                message.apiOutputFieldPath = reader.string();
                                break;
                            }
                        case 6: {
                                message.requiredForCompletion = reader.bool();
                                break;
                            }
                        case 7: {
                                message.customLabel = reader.string();
                                break;
                            }
                        case 8: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 9: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        case 10: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 11: {
                                message.csvMaxArraySize = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgApiOutputConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrgApiOutputConfig} OrgApiOutputConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgApiOutputConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgApiOutputConfig message.
                 * @function verify
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgApiOutputConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        if (!$util.isString(message.taskType))
                            return "taskType: string expected";
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    if (message.apiOutputFieldPath != null && message.hasOwnProperty("apiOutputFieldPath"))
                        if (!$util.isString(message.apiOutputFieldPath))
                            return "apiOutputFieldPath: string expected";
                    if (message.requiredForCompletion != null && message.hasOwnProperty("requiredForCompletion"))
                        if (typeof message.requiredForCompletion !== "boolean")
                            return "requiredForCompletion: boolean expected";
                    if (message.customLabel != null && message.hasOwnProperty("customLabel"))
                        if (!$util.isString(message.customLabel))
                            return "customLabel: string expected";
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.csvMaxArraySize != null && message.hasOwnProperty("csvMaxArraySize"))
                        if (!$util.isInteger(message.csvMaxArraySize))
                            return "csvMaxArraySize: integer expected";
                    return null;
                };
    
                /**
                 * Creates an OrgApiOutputConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrgApiOutputConfig} OrgApiOutputConfig
                 */
                OrgApiOutputConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrgApiOutputConfig)
                        return object;
                    var message = new $root.infinitusai.be.OrgApiOutputConfig();
                    if (object.id != null)
                        message.id = object.id | 0;
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskType != null)
                        message.taskType = String(object.taskType);
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    if (object.apiOutputFieldPath != null)
                        message.apiOutputFieldPath = String(object.apiOutputFieldPath);
                    if (object.requiredForCompletion != null)
                        message.requiredForCompletion = Boolean(object.requiredForCompletion);
                    if (object.customLabel != null)
                        message.customLabel = String(object.customLabel);
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.csvMaxArraySize != null)
                        message.csvMaxArraySize = object.csvMaxArraySize | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgApiOutputConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {infinitusai.be.OrgApiOutputConfig} message OrgApiOutputConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgApiOutputConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = 0;
                        object.orgUuid = "";
                        object.taskType = "";
                        object.programId = "";
                        object.apiOutputFieldPath = "";
                        object.requiredForCompletion = false;
                        object.customLabel = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                        object.enabled = false;
                        object.csvMaxArraySize = 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = message.taskType;
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    if (message.apiOutputFieldPath != null && message.hasOwnProperty("apiOutputFieldPath"))
                        object.apiOutputFieldPath = message.apiOutputFieldPath;
                    if (message.requiredForCompletion != null && message.hasOwnProperty("requiredForCompletion"))
                        object.requiredForCompletion = message.requiredForCompletion;
                    if (message.customLabel != null && message.hasOwnProperty("customLabel"))
                        object.customLabel = message.customLabel;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.csvMaxArraySize != null && message.hasOwnProperty("csvMaxArraySize"))
                        object.csvMaxArraySize = message.csvMaxArraySize;
                    return object;
                };
    
                /**
                 * Converts this OrgApiOutputConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgApiOutputConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgApiOutputConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrgApiOutputConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgApiOutputConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrgApiOutputConfig";
                };
    
                return OrgApiOutputConfig;
            })();
    
            be.OrgApiOutputConfigInput = (function() {
    
                /**
                 * Properties of an OrgApiOutputConfigInput.
                 * @memberof infinitusai.be
                 * @interface IOrgApiOutputConfigInput
                 * @property {number|null} [id] OrgApiOutputConfigInput id
                 * @property {string|null} [orgUuid] OrgApiOutputConfigInput orgUuid
                 * @property {string|null} [taskType] OrgApiOutputConfigInput taskType
                 * @property {string|null} [programId] OrgApiOutputConfigInput programId
                 * @property {string|null} [apiOutputFieldPath] OrgApiOutputConfigInput apiOutputFieldPath
                 * @property {boolean|null} [requiredForCompletion] OrgApiOutputConfigInput requiredForCompletion
                 * @property {string|null} [customLabel] OrgApiOutputConfigInput customLabel
                 * @property {boolean|null} [enabled] OrgApiOutputConfigInput enabled
                 * @property {number|null} [csvMaxArraySize] OrgApiOutputConfigInput csvMaxArraySize
                 */
    
                /**
                 * Constructs a new OrgApiOutputConfigInput.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OrgApiOutputConfigInput.
                 * @implements IOrgApiOutputConfigInput
                 * @constructor
                 * @param {infinitusai.be.IOrgApiOutputConfigInput=} [properties] Properties to set
                 */
                function OrgApiOutputConfigInput(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgApiOutputConfigInput id.
                 * @member {number} id
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.id = 0;
    
                /**
                 * OrgApiOutputConfigInput orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.orgUuid = "";
    
                /**
                 * OrgApiOutputConfigInput taskType.
                 * @member {string} taskType
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.taskType = "";
    
                /**
                 * OrgApiOutputConfigInput programId.
                 * @member {string} programId
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.programId = "";
    
                /**
                 * OrgApiOutputConfigInput apiOutputFieldPath.
                 * @member {string} apiOutputFieldPath
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.apiOutputFieldPath = "";
    
                /**
                 * OrgApiOutputConfigInput requiredForCompletion.
                 * @member {boolean} requiredForCompletion
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.requiredForCompletion = false;
    
                /**
                 * OrgApiOutputConfigInput customLabel.
                 * @member {string} customLabel
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.customLabel = "";
    
                /**
                 * OrgApiOutputConfigInput enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.enabled = false;
    
                /**
                 * OrgApiOutputConfigInput csvMaxArraySize.
                 * @member {number} csvMaxArraySize
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 */
                OrgApiOutputConfigInput.prototype.csvMaxArraySize = 0;
    
                /**
                 * Creates a new OrgApiOutputConfigInput instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {infinitusai.be.IOrgApiOutputConfigInput=} [properties] Properties to set
                 * @returns {infinitusai.be.OrgApiOutputConfigInput} OrgApiOutputConfigInput instance
                 */
                OrgApiOutputConfigInput.create = function create(properties) {
                    return new OrgApiOutputConfigInput(properties);
                };
    
                /**
                 * Encodes the specified OrgApiOutputConfigInput message. Does not implicitly {@link infinitusai.be.OrgApiOutputConfigInput.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {infinitusai.be.IOrgApiOutputConfigInput} message OrgApiOutputConfigInput message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgApiOutputConfigInput.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuid);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskType);
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.programId);
                    if (message.apiOutputFieldPath != null && Object.hasOwnProperty.call(message, "apiOutputFieldPath"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.apiOutputFieldPath);
                    if (message.requiredForCompletion != null && Object.hasOwnProperty.call(message, "requiredForCompletion"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.requiredForCompletion);
                    if (message.customLabel != null && Object.hasOwnProperty.call(message, "customLabel"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.customLabel);
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.enabled);
                    if (message.csvMaxArraySize != null && Object.hasOwnProperty.call(message, "csvMaxArraySize"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.csvMaxArraySize);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgApiOutputConfigInput message, length delimited. Does not implicitly {@link infinitusai.be.OrgApiOutputConfigInput.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {infinitusai.be.IOrgApiOutputConfigInput} message OrgApiOutputConfigInput message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgApiOutputConfigInput.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgApiOutputConfigInput message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OrgApiOutputConfigInput} OrgApiOutputConfigInput
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgApiOutputConfigInput.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OrgApiOutputConfigInput();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int32();
                                break;
                            }
                        case 2: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskType = reader.string();
                                break;
                            }
                        case 4: {
                                message.programId = reader.string();
                                break;
                            }
                        case 5: {
                                message.apiOutputFieldPath = reader.string();
                                break;
                            }
                        case 6: {
                                message.requiredForCompletion = reader.bool();
                                break;
                            }
                        case 7: {
                                message.customLabel = reader.string();
                                break;
                            }
                        case 8: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 9: {
                                message.csvMaxArraySize = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgApiOutputConfigInput message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OrgApiOutputConfigInput} OrgApiOutputConfigInput
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgApiOutputConfigInput.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgApiOutputConfigInput message.
                 * @function verify
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgApiOutputConfigInput.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        if (!$util.isString(message.taskType))
                            return "taskType: string expected";
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    if (message.apiOutputFieldPath != null && message.hasOwnProperty("apiOutputFieldPath"))
                        if (!$util.isString(message.apiOutputFieldPath))
                            return "apiOutputFieldPath: string expected";
                    if (message.requiredForCompletion != null && message.hasOwnProperty("requiredForCompletion"))
                        if (typeof message.requiredForCompletion !== "boolean")
                            return "requiredForCompletion: boolean expected";
                    if (message.customLabel != null && message.hasOwnProperty("customLabel"))
                        if (!$util.isString(message.customLabel))
                            return "customLabel: string expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.csvMaxArraySize != null && message.hasOwnProperty("csvMaxArraySize"))
                        if (!$util.isInteger(message.csvMaxArraySize))
                            return "csvMaxArraySize: integer expected";
                    return null;
                };
    
                /**
                 * Creates an OrgApiOutputConfigInput message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OrgApiOutputConfigInput} OrgApiOutputConfigInput
                 */
                OrgApiOutputConfigInput.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OrgApiOutputConfigInput)
                        return object;
                    var message = new $root.infinitusai.be.OrgApiOutputConfigInput();
                    if (object.id != null)
                        message.id = object.id | 0;
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskType != null)
                        message.taskType = String(object.taskType);
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    if (object.apiOutputFieldPath != null)
                        message.apiOutputFieldPath = String(object.apiOutputFieldPath);
                    if (object.requiredForCompletion != null)
                        message.requiredForCompletion = Boolean(object.requiredForCompletion);
                    if (object.customLabel != null)
                        message.customLabel = String(object.customLabel);
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.csvMaxArraySize != null)
                        message.csvMaxArraySize = object.csvMaxArraySize | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgApiOutputConfigInput message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {infinitusai.be.OrgApiOutputConfigInput} message OrgApiOutputConfigInput
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgApiOutputConfigInput.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = 0;
                        object.orgUuid = "";
                        object.taskType = "";
                        object.programId = "";
                        object.apiOutputFieldPath = "";
                        object.requiredForCompletion = false;
                        object.customLabel = "";
                        object.enabled = false;
                        object.csvMaxArraySize = 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = message.taskType;
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    if (message.apiOutputFieldPath != null && message.hasOwnProperty("apiOutputFieldPath"))
                        object.apiOutputFieldPath = message.apiOutputFieldPath;
                    if (message.requiredForCompletion != null && message.hasOwnProperty("requiredForCompletion"))
                        object.requiredForCompletion = message.requiredForCompletion;
                    if (message.customLabel != null && message.hasOwnProperty("customLabel"))
                        object.customLabel = message.customLabel;
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.csvMaxArraySize != null && message.hasOwnProperty("csvMaxArraySize"))
                        object.csvMaxArraySize = message.csvMaxArraySize;
                    return object;
                };
    
                /**
                 * Converts this OrgApiOutputConfigInput to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgApiOutputConfigInput.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgApiOutputConfigInput
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OrgApiOutputConfigInput
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgApiOutputConfigInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OrgApiOutputConfigInput";
                };
    
                return OrgApiOutputConfigInput;
            })();
    
            be.GetOrgApiOutputConfigsRequest = (function() {
    
                /**
                 * Properties of a GetOrgApiOutputConfigsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetOrgApiOutputConfigsRequest
                 * @property {string|null} [orgUuid] GetOrgApiOutputConfigsRequest orgUuid
                 * @property {string|null} [taskType] GetOrgApiOutputConfigsRequest taskType
                 * @property {string|null} [programId] GetOrgApiOutputConfigsRequest programId
                 */
    
                /**
                 * Constructs a new GetOrgApiOutputConfigsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOrgApiOutputConfigsRequest.
                 * @implements IGetOrgApiOutputConfigsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetOrgApiOutputConfigsRequest=} [properties] Properties to set
                 */
                function GetOrgApiOutputConfigsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgApiOutputConfigsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @instance
                 */
                GetOrgApiOutputConfigsRequest.prototype.orgUuid = "";
    
                /**
                 * GetOrgApiOutputConfigsRequest taskType.
                 * @member {string} taskType
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @instance
                 */
                GetOrgApiOutputConfigsRequest.prototype.taskType = "";
    
                /**
                 * GetOrgApiOutputConfigsRequest programId.
                 * @member {string} programId
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @instance
                 */
                GetOrgApiOutputConfigsRequest.prototype.programId = "";
    
                /**
                 * Creates a new GetOrgApiOutputConfigsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgApiOutputConfigsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOrgApiOutputConfigsRequest} GetOrgApiOutputConfigsRequest instance
                 */
                GetOrgApiOutputConfigsRequest.create = function create(properties) {
                    return new GetOrgApiOutputConfigsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgApiOutputConfigsRequest message. Does not implicitly {@link infinitusai.be.GetOrgApiOutputConfigsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgApiOutputConfigsRequest} message GetOrgApiOutputConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgApiOutputConfigsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskType);
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.programId);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgApiOutputConfigsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetOrgApiOutputConfigsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {infinitusai.be.IGetOrgApiOutputConfigsRequest} message GetOrgApiOutputConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgApiOutputConfigsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgApiOutputConfigsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOrgApiOutputConfigsRequest} GetOrgApiOutputConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgApiOutputConfigsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOrgApiOutputConfigsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.string();
                                break;
                            }
                        case 3: {
                                message.programId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgApiOutputConfigsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOrgApiOutputConfigsRequest} GetOrgApiOutputConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgApiOutputConfigsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgApiOutputConfigsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgApiOutputConfigsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        if (!$util.isString(message.taskType))
                            return "taskType: string expected";
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetOrgApiOutputConfigsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOrgApiOutputConfigsRequest} GetOrgApiOutputConfigsRequest
                 */
                GetOrgApiOutputConfigsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOrgApiOutputConfigsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetOrgApiOutputConfigsRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskType != null)
                        message.taskType = String(object.taskType);
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgApiOutputConfigsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {infinitusai.be.GetOrgApiOutputConfigsRequest} message GetOrgApiOutputConfigsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgApiOutputConfigsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskType = "";
                        object.programId = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = message.taskType;
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    return object;
                };
    
                /**
                 * Converts this GetOrgApiOutputConfigsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgApiOutputConfigsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgApiOutputConfigsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgApiOutputConfigsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOrgApiOutputConfigsRequest";
                };
    
                return GetOrgApiOutputConfigsRequest;
            })();
    
            be.GetOrgApiOutputConfigsResponse = (function() {
    
                /**
                 * Properties of a GetOrgApiOutputConfigsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetOrgApiOutputConfigsResponse
                 * @property {Array.<infinitusai.be.IOrgApiOutputConfig>|null} [orgApiOutputConfigs] GetOrgApiOutputConfigsResponse orgApiOutputConfigs
                 */
    
                /**
                 * Constructs a new GetOrgApiOutputConfigsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetOrgApiOutputConfigsResponse.
                 * @implements IGetOrgApiOutputConfigsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetOrgApiOutputConfigsResponse=} [properties] Properties to set
                 */
                function GetOrgApiOutputConfigsResponse(properties) {
                    this.orgApiOutputConfigs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgApiOutputConfigsResponse orgApiOutputConfigs.
                 * @member {Array.<infinitusai.be.IOrgApiOutputConfig>} orgApiOutputConfigs
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @instance
                 */
                GetOrgApiOutputConfigsResponse.prototype.orgApiOutputConfigs = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgApiOutputConfigsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgApiOutputConfigsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetOrgApiOutputConfigsResponse} GetOrgApiOutputConfigsResponse instance
                 */
                GetOrgApiOutputConfigsResponse.create = function create(properties) {
                    return new GetOrgApiOutputConfigsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgApiOutputConfigsResponse message. Does not implicitly {@link infinitusai.be.GetOrgApiOutputConfigsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgApiOutputConfigsResponse} message GetOrgApiOutputConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgApiOutputConfigsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgApiOutputConfigs != null && message.orgApiOutputConfigs.length)
                        for (var i = 0; i < message.orgApiOutputConfigs.length; ++i)
                            $root.infinitusai.be.OrgApiOutputConfig.encode(message.orgApiOutputConfigs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgApiOutputConfigsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetOrgApiOutputConfigsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {infinitusai.be.IGetOrgApiOutputConfigsResponse} message GetOrgApiOutputConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgApiOutputConfigsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgApiOutputConfigsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetOrgApiOutputConfigsResponse} GetOrgApiOutputConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgApiOutputConfigsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetOrgApiOutputConfigsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.orgApiOutputConfigs && message.orgApiOutputConfigs.length))
                                    message.orgApiOutputConfigs = [];
                                message.orgApiOutputConfigs.push($root.infinitusai.be.OrgApiOutputConfig.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgApiOutputConfigsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetOrgApiOutputConfigsResponse} GetOrgApiOutputConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgApiOutputConfigsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgApiOutputConfigsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgApiOutputConfigsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgApiOutputConfigs != null && message.hasOwnProperty("orgApiOutputConfigs")) {
                        if (!Array.isArray(message.orgApiOutputConfigs))
                            return "orgApiOutputConfigs: array expected";
                        for (var i = 0; i < message.orgApiOutputConfigs.length; ++i) {
                            var error = $root.infinitusai.be.OrgApiOutputConfig.verify(message.orgApiOutputConfigs[i]);
                            if (error)
                                return "orgApiOutputConfigs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgApiOutputConfigsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetOrgApiOutputConfigsResponse} GetOrgApiOutputConfigsResponse
                 */
                GetOrgApiOutputConfigsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetOrgApiOutputConfigsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetOrgApiOutputConfigsResponse();
                    if (object.orgApiOutputConfigs) {
                        if (!Array.isArray(object.orgApiOutputConfigs))
                            throw TypeError(".infinitusai.be.GetOrgApiOutputConfigsResponse.orgApiOutputConfigs: array expected");
                        message.orgApiOutputConfigs = [];
                        for (var i = 0; i < object.orgApiOutputConfigs.length; ++i) {
                            if (typeof object.orgApiOutputConfigs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetOrgApiOutputConfigsResponse.orgApiOutputConfigs: object expected");
                            message.orgApiOutputConfigs[i] = $root.infinitusai.be.OrgApiOutputConfig.fromObject(object.orgApiOutputConfigs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgApiOutputConfigsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {infinitusai.be.GetOrgApiOutputConfigsResponse} message GetOrgApiOutputConfigsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgApiOutputConfigsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgApiOutputConfigs = [];
                    if (message.orgApiOutputConfigs && message.orgApiOutputConfigs.length) {
                        object.orgApiOutputConfigs = [];
                        for (var j = 0; j < message.orgApiOutputConfigs.length; ++j)
                            object.orgApiOutputConfigs[j] = $root.infinitusai.be.OrgApiOutputConfig.toObject(message.orgApiOutputConfigs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgApiOutputConfigsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgApiOutputConfigsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgApiOutputConfigsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetOrgApiOutputConfigsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgApiOutputConfigsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetOrgApiOutputConfigsResponse";
                };
    
                return GetOrgApiOutputConfigsResponse;
            })();
    
            be.BatchUpdateOrgApiOutputConfigsRequest = (function() {
    
                /**
                 * Properties of a BatchUpdateOrgApiOutputConfigsRequest.
                 * @memberof infinitusai.be
                 * @interface IBatchUpdateOrgApiOutputConfigsRequest
                 * @property {Array.<infinitusai.be.IOrgApiOutputConfigInput>|null} [addList] BatchUpdateOrgApiOutputConfigsRequest addList
                 * @property {Array.<infinitusai.be.IOrgApiOutputConfigInput>|null} [updateList] BatchUpdateOrgApiOutputConfigsRequest updateList
                 * @property {Array.<infinitusai.be.IOrgApiOutputConfigInput>|null} [deleteList] BatchUpdateOrgApiOutputConfigsRequest deleteList
                 */
    
                /**
                 * Constructs a new BatchUpdateOrgApiOutputConfigsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BatchUpdateOrgApiOutputConfigsRequest.
                 * @implements IBatchUpdateOrgApiOutputConfigsRequest
                 * @constructor
                 * @param {infinitusai.be.IBatchUpdateOrgApiOutputConfigsRequest=} [properties] Properties to set
                 */
                function BatchUpdateOrgApiOutputConfigsRequest(properties) {
                    this.addList = [];
                    this.updateList = [];
                    this.deleteList = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BatchUpdateOrgApiOutputConfigsRequest addList.
                 * @member {Array.<infinitusai.be.IOrgApiOutputConfigInput>} addList
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @instance
                 */
                BatchUpdateOrgApiOutputConfigsRequest.prototype.addList = $util.emptyArray;
    
                /**
                 * BatchUpdateOrgApiOutputConfigsRequest updateList.
                 * @member {Array.<infinitusai.be.IOrgApiOutputConfigInput>} updateList
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @instance
                 */
                BatchUpdateOrgApiOutputConfigsRequest.prototype.updateList = $util.emptyArray;
    
                /**
                 * BatchUpdateOrgApiOutputConfigsRequest deleteList.
                 * @member {Array.<infinitusai.be.IOrgApiOutputConfigInput>} deleteList
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @instance
                 */
                BatchUpdateOrgApiOutputConfigsRequest.prototype.deleteList = $util.emptyArray;
    
                /**
                 * Creates a new BatchUpdateOrgApiOutputConfigsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {infinitusai.be.IBatchUpdateOrgApiOutputConfigsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest} BatchUpdateOrgApiOutputConfigsRequest instance
                 */
                BatchUpdateOrgApiOutputConfigsRequest.create = function create(properties) {
                    return new BatchUpdateOrgApiOutputConfigsRequest(properties);
                };
    
                /**
                 * Encodes the specified BatchUpdateOrgApiOutputConfigsRequest message. Does not implicitly {@link infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {infinitusai.be.IBatchUpdateOrgApiOutputConfigsRequest} message BatchUpdateOrgApiOutputConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BatchUpdateOrgApiOutputConfigsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.addList != null && message.addList.length)
                        for (var i = 0; i < message.addList.length; ++i)
                            $root.infinitusai.be.OrgApiOutputConfigInput.encode(message.addList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.updateList != null && message.updateList.length)
                        for (var i = 0; i < message.updateList.length; ++i)
                            $root.infinitusai.be.OrgApiOutputConfigInput.encode(message.updateList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.deleteList != null && message.deleteList.length)
                        for (var i = 0; i < message.deleteList.length; ++i)
                            $root.infinitusai.be.OrgApiOutputConfigInput.encode(message.deleteList[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified BatchUpdateOrgApiOutputConfigsRequest message, length delimited. Does not implicitly {@link infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {infinitusai.be.IBatchUpdateOrgApiOutputConfigsRequest} message BatchUpdateOrgApiOutputConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BatchUpdateOrgApiOutputConfigsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BatchUpdateOrgApiOutputConfigsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest} BatchUpdateOrgApiOutputConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BatchUpdateOrgApiOutputConfigsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.addList && message.addList.length))
                                    message.addList = [];
                                message.addList.push($root.infinitusai.be.OrgApiOutputConfigInput.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.updateList && message.updateList.length))
                                    message.updateList = [];
                                message.updateList.push($root.infinitusai.be.OrgApiOutputConfigInput.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.deleteList && message.deleteList.length))
                                    message.deleteList = [];
                                message.deleteList.push($root.infinitusai.be.OrgApiOutputConfigInput.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BatchUpdateOrgApiOutputConfigsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest} BatchUpdateOrgApiOutputConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BatchUpdateOrgApiOutputConfigsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BatchUpdateOrgApiOutputConfigsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BatchUpdateOrgApiOutputConfigsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.addList != null && message.hasOwnProperty("addList")) {
                        if (!Array.isArray(message.addList))
                            return "addList: array expected";
                        for (var i = 0; i < message.addList.length; ++i) {
                            var error = $root.infinitusai.be.OrgApiOutputConfigInput.verify(message.addList[i]);
                            if (error)
                                return "addList." + error;
                        }
                    }
                    if (message.updateList != null && message.hasOwnProperty("updateList")) {
                        if (!Array.isArray(message.updateList))
                            return "updateList: array expected";
                        for (var i = 0; i < message.updateList.length; ++i) {
                            var error = $root.infinitusai.be.OrgApiOutputConfigInput.verify(message.updateList[i]);
                            if (error)
                                return "updateList." + error;
                        }
                    }
                    if (message.deleteList != null && message.hasOwnProperty("deleteList")) {
                        if (!Array.isArray(message.deleteList))
                            return "deleteList: array expected";
                        for (var i = 0; i < message.deleteList.length; ++i) {
                            var error = $root.infinitusai.be.OrgApiOutputConfigInput.verify(message.deleteList[i]);
                            if (error)
                                return "deleteList." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a BatchUpdateOrgApiOutputConfigsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest} BatchUpdateOrgApiOutputConfigsRequest
                 */
                BatchUpdateOrgApiOutputConfigsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest)
                        return object;
                    var message = new $root.infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest();
                    if (object.addList) {
                        if (!Array.isArray(object.addList))
                            throw TypeError(".infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest.addList: array expected");
                        message.addList = [];
                        for (var i = 0; i < object.addList.length; ++i) {
                            if (typeof object.addList[i] !== "object")
                                throw TypeError(".infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest.addList: object expected");
                            message.addList[i] = $root.infinitusai.be.OrgApiOutputConfigInput.fromObject(object.addList[i]);
                        }
                    }
                    if (object.updateList) {
                        if (!Array.isArray(object.updateList))
                            throw TypeError(".infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest.updateList: array expected");
                        message.updateList = [];
                        for (var i = 0; i < object.updateList.length; ++i) {
                            if (typeof object.updateList[i] !== "object")
                                throw TypeError(".infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest.updateList: object expected");
                            message.updateList[i] = $root.infinitusai.be.OrgApiOutputConfigInput.fromObject(object.updateList[i]);
                        }
                    }
                    if (object.deleteList) {
                        if (!Array.isArray(object.deleteList))
                            throw TypeError(".infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest.deleteList: array expected");
                        message.deleteList = [];
                        for (var i = 0; i < object.deleteList.length; ++i) {
                            if (typeof object.deleteList[i] !== "object")
                                throw TypeError(".infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest.deleteList: object expected");
                            message.deleteList[i] = $root.infinitusai.be.OrgApiOutputConfigInput.fromObject(object.deleteList[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a BatchUpdateOrgApiOutputConfigsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest} message BatchUpdateOrgApiOutputConfigsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BatchUpdateOrgApiOutputConfigsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.addList = [];
                        object.updateList = [];
                        object.deleteList = [];
                    }
                    if (message.addList && message.addList.length) {
                        object.addList = [];
                        for (var j = 0; j < message.addList.length; ++j)
                            object.addList[j] = $root.infinitusai.be.OrgApiOutputConfigInput.toObject(message.addList[j], options);
                    }
                    if (message.updateList && message.updateList.length) {
                        object.updateList = [];
                        for (var j = 0; j < message.updateList.length; ++j)
                            object.updateList[j] = $root.infinitusai.be.OrgApiOutputConfigInput.toObject(message.updateList[j], options);
                    }
                    if (message.deleteList && message.deleteList.length) {
                        object.deleteList = [];
                        for (var j = 0; j < message.deleteList.length; ++j)
                            object.deleteList[j] = $root.infinitusai.be.OrgApiOutputConfigInput.toObject(message.deleteList[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this BatchUpdateOrgApiOutputConfigsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BatchUpdateOrgApiOutputConfigsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BatchUpdateOrgApiOutputConfigsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BatchUpdateOrgApiOutputConfigsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest";
                };
    
                return BatchUpdateOrgApiOutputConfigsRequest;
            })();
    
            be.BatchUpdateOrgApiOutputConfigsResponse = (function() {
    
                /**
                 * Properties of a BatchUpdateOrgApiOutputConfigsResponse.
                 * @memberof infinitusai.be
                 * @interface IBatchUpdateOrgApiOutputConfigsResponse
                 * @property {string|null} [message] BatchUpdateOrgApiOutputConfigsResponse message
                 */
    
                /**
                 * Constructs a new BatchUpdateOrgApiOutputConfigsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BatchUpdateOrgApiOutputConfigsResponse.
                 * @implements IBatchUpdateOrgApiOutputConfigsResponse
                 * @constructor
                 * @param {infinitusai.be.IBatchUpdateOrgApiOutputConfigsResponse=} [properties] Properties to set
                 */
                function BatchUpdateOrgApiOutputConfigsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BatchUpdateOrgApiOutputConfigsResponse message.
                 * @member {string} message
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @instance
                 */
                BatchUpdateOrgApiOutputConfigsResponse.prototype.message = "";
    
                /**
                 * Creates a new BatchUpdateOrgApiOutputConfigsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {infinitusai.be.IBatchUpdateOrgApiOutputConfigsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse} BatchUpdateOrgApiOutputConfigsResponse instance
                 */
                BatchUpdateOrgApiOutputConfigsResponse.create = function create(properties) {
                    return new BatchUpdateOrgApiOutputConfigsResponse(properties);
                };
    
                /**
                 * Encodes the specified BatchUpdateOrgApiOutputConfigsResponse message. Does not implicitly {@link infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {infinitusai.be.IBatchUpdateOrgApiOutputConfigsResponse} message BatchUpdateOrgApiOutputConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BatchUpdateOrgApiOutputConfigsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
                    return writer;
                };
    
                /**
                 * Encodes the specified BatchUpdateOrgApiOutputConfigsResponse message, length delimited. Does not implicitly {@link infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {infinitusai.be.IBatchUpdateOrgApiOutputConfigsResponse} message BatchUpdateOrgApiOutputConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BatchUpdateOrgApiOutputConfigsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BatchUpdateOrgApiOutputConfigsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse} BatchUpdateOrgApiOutputConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BatchUpdateOrgApiOutputConfigsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.message = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BatchUpdateOrgApiOutputConfigsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse} BatchUpdateOrgApiOutputConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BatchUpdateOrgApiOutputConfigsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BatchUpdateOrgApiOutputConfigsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BatchUpdateOrgApiOutputConfigsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.message != null && message.hasOwnProperty("message"))
                        if (!$util.isString(message.message))
                            return "message: string expected";
                    return null;
                };
    
                /**
                 * Creates a BatchUpdateOrgApiOutputConfigsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse} BatchUpdateOrgApiOutputConfigsResponse
                 */
                BatchUpdateOrgApiOutputConfigsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse)
                        return object;
                    var message = new $root.infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse();
                    if (object.message != null)
                        message.message = String(object.message);
                    return message;
                };
    
                /**
                 * Creates a plain object from a BatchUpdateOrgApiOutputConfigsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse} message BatchUpdateOrgApiOutputConfigsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BatchUpdateOrgApiOutputConfigsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.message = "";
                    if (message.message != null && message.hasOwnProperty("message"))
                        object.message = message.message;
                    return object;
                };
    
                /**
                 * Converts this BatchUpdateOrgApiOutputConfigsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BatchUpdateOrgApiOutputConfigsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BatchUpdateOrgApiOutputConfigsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BatchUpdateOrgApiOutputConfigsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse";
                };
    
                return BatchUpdateOrgApiOutputConfigsResponse;
            })();
    
            be.OutputService = (function() {
    
                /**
                 * Constructs a new OutputService service.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OutputService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function OutputService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (OutputService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OutputService;
    
                /**
                 * Creates new OutputService service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.OutputService
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {OutputService} RPC service. Useful where requests and/or responses are streamed.
                 */
                OutputService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.OutputService#saveOutput}.
                 * @memberof infinitusai.be.OutputService
                 * @typedef SaveOutputCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.SaveOutputResponse} [response] SaveOutputResponse
                 */
    
                /**
                 * Calls SaveOutput.
                 * @function saveOutput
                 * @memberof infinitusai.be.OutputService
                 * @instance
                 * @param {infinitusai.be.ISaveOutputRequest} request SaveOutputRequest message or plain object
                 * @param {infinitusai.be.OutputService.SaveOutputCallback} callback Node-style callback called with the error, if any, and SaveOutputResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OutputService.prototype.saveOutput = function saveOutput(request, callback) {
                    return this.rpcCall(saveOutput, $root.infinitusai.be.SaveOutputRequest, $root.infinitusai.be.SaveOutputResponse, request, callback);
                }, "name", { value: "SaveOutput" });
    
                /**
                 * Calls SaveOutput.
                 * @function saveOutput
                 * @memberof infinitusai.be.OutputService
                 * @instance
                 * @param {infinitusai.be.ISaveOutputRequest} request SaveOutputRequest message or plain object
                 * @returns {Promise<infinitusai.be.SaveOutputResponse>} Promise
                 * @variation 2
                 */
    
                return OutputService;
            })();
    
            be.SaveOutputRequest = (function() {
    
                /**
                 * Properties of a SaveOutputRequest.
                 * @memberof infinitusai.be
                 * @interface ISaveOutputRequest
                 * @property {string|null} [orgUuid] SaveOutputRequest orgUuid
                 * @property {string|null} [taskUuid] SaveOutputRequest taskUuid
                 * @property {string|null} [callUuid] SaveOutputRequest callUuid
                 * @property {Array.<infinitusai.be.IExpandedOutput>|null} [outputs] SaveOutputRequest outputs
                 */
    
                /**
                 * Constructs a new SaveOutputRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveOutputRequest.
                 * @implements ISaveOutputRequest
                 * @constructor
                 * @param {infinitusai.be.ISaveOutputRequest=} [properties] Properties to set
                 */
                function SaveOutputRequest(properties) {
                    this.outputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SaveOutputRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @instance
                 */
                SaveOutputRequest.prototype.orgUuid = "";
    
                /**
                 * SaveOutputRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @instance
                 */
                SaveOutputRequest.prototype.taskUuid = "";
    
                /**
                 * SaveOutputRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @instance
                 */
                SaveOutputRequest.prototype.callUuid = "";
    
                /**
                 * SaveOutputRequest outputs.
                 * @member {Array.<infinitusai.be.IExpandedOutput>} outputs
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @instance
                 */
                SaveOutputRequest.prototype.outputs = $util.emptyArray;
    
                /**
                 * Creates a new SaveOutputRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {infinitusai.be.ISaveOutputRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveOutputRequest} SaveOutputRequest instance
                 */
                SaveOutputRequest.create = function create(properties) {
                    return new SaveOutputRequest(properties);
                };
    
                /**
                 * Encodes the specified SaveOutputRequest message. Does not implicitly {@link infinitusai.be.SaveOutputRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {infinitusai.be.ISaveOutputRequest} message SaveOutputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveOutputRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.outputs != null && message.outputs.length)
                        for (var i = 0; i < message.outputs.length; ++i)
                            $root.infinitusai.be.ExpandedOutput.encode(message.outputs[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveOutputRequest message, length delimited. Does not implicitly {@link infinitusai.be.SaveOutputRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {infinitusai.be.ISaveOutputRequest} message SaveOutputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveOutputRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveOutputRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveOutputRequest} SaveOutputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveOutputRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveOutputRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.outputs && message.outputs.length))
                                    message.outputs = [];
                                message.outputs.push($root.infinitusai.be.ExpandedOutput.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveOutputRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveOutputRequest} SaveOutputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveOutputRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveOutputRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveOutputRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.outputs != null && message.hasOwnProperty("outputs")) {
                        if (!Array.isArray(message.outputs))
                            return "outputs: array expected";
                        for (var i = 0; i < message.outputs.length; ++i) {
                            var error = $root.infinitusai.be.ExpandedOutput.verify(message.outputs[i]);
                            if (error)
                                return "outputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a SaveOutputRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveOutputRequest} SaveOutputRequest
                 */
                SaveOutputRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveOutputRequest)
                        return object;
                    var message = new $root.infinitusai.be.SaveOutputRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.outputs) {
                        if (!Array.isArray(object.outputs))
                            throw TypeError(".infinitusai.be.SaveOutputRequest.outputs: array expected");
                        message.outputs = [];
                        for (var i = 0; i < object.outputs.length; ++i) {
                            if (typeof object.outputs[i] !== "object")
                                throw TypeError(".infinitusai.be.SaveOutputRequest.outputs: object expected");
                            message.outputs[i] = $root.infinitusai.be.ExpandedOutput.fromObject(object.outputs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SaveOutputRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {infinitusai.be.SaveOutputRequest} message SaveOutputRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveOutputRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.outputs = [];
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.outputs && message.outputs.length) {
                        object.outputs = [];
                        for (var j = 0; j < message.outputs.length; ++j)
                            object.outputs[j] = $root.infinitusai.be.ExpandedOutput.toObject(message.outputs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this SaveOutputRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveOutputRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveOutputRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveOutputRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveOutputRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveOutputRequest";
                };
    
                return SaveOutputRequest;
            })();
    
            be.SaveOutputResponse = (function() {
    
                /**
                 * Properties of a SaveOutputResponse.
                 * @memberof infinitusai.be
                 * @interface ISaveOutputResponse
                 * @property {boolean|null} [success] SaveOutputResponse success
                 */
    
                /**
                 * Constructs a new SaveOutputResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SaveOutputResponse.
                 * @implements ISaveOutputResponse
                 * @constructor
                 * @param {infinitusai.be.ISaveOutputResponse=} [properties] Properties to set
                 */
                function SaveOutputResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SaveOutputResponse success.
                 * @member {boolean} success
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @instance
                 */
                SaveOutputResponse.prototype.success = false;
    
                /**
                 * Creates a new SaveOutputResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {infinitusai.be.ISaveOutputResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.SaveOutputResponse} SaveOutputResponse instance
                 */
                SaveOutputResponse.create = function create(properties) {
                    return new SaveOutputResponse(properties);
                };
    
                /**
                 * Encodes the specified SaveOutputResponse message. Does not implicitly {@link infinitusai.be.SaveOutputResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {infinitusai.be.ISaveOutputResponse} message SaveOutputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveOutputResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
                    return writer;
                };
    
                /**
                 * Encodes the specified SaveOutputResponse message, length delimited. Does not implicitly {@link infinitusai.be.SaveOutputResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {infinitusai.be.ISaveOutputResponse} message SaveOutputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SaveOutputResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SaveOutputResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SaveOutputResponse} SaveOutputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveOutputResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SaveOutputResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.success = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SaveOutputResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SaveOutputResponse} SaveOutputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SaveOutputResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SaveOutputResponse message.
                 * @function verify
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveOutputResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.success != null && message.hasOwnProperty("success"))
                        if (typeof message.success !== "boolean")
                            return "success: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a SaveOutputResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SaveOutputResponse} SaveOutputResponse
                 */
                SaveOutputResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SaveOutputResponse)
                        return object;
                    var message = new $root.infinitusai.be.SaveOutputResponse();
                    if (object.success != null)
                        message.success = Boolean(object.success);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SaveOutputResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {infinitusai.be.SaveOutputResponse} message SaveOutputResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveOutputResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.success = false;
                    if (message.success != null && message.hasOwnProperty("success"))
                        object.success = message.success;
                    return object;
                };
    
                /**
                 * Converts this SaveOutputResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveOutputResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SaveOutputResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SaveOutputResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SaveOutputResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SaveOutputResponse";
                };
    
                return SaveOutputResponse;
            })();
    
            be.PayerDoc = (function() {
    
                /**
                 * Properties of a PayerDoc.
                 * @memberof infinitusai.be
                 * @interface IPayerDoc
                 * @property {string|null} [id] PayerDoc id
                 * @property {string|null} [name] PayerDoc name
                 * @property {Array.<string>|null} [website] PayerDoc website
                 * @property {Array.<string>|null} [alternateNameRegex] PayerDoc alternateNameRegex
                 * @property {Array.<string>|null} [states] PayerDoc states
                 * @property {Array.<string>|null} [phoneNumber] PayerDoc phoneNumber
                 * @property {infinitusai.be.PayerDoc.DoNotCallReason|null} [doNotCall] PayerDoc doNotCall
                 * @property {string|null} [chcPayerId] PayerDoc chcPayerId
                 * @property {Object.<string,string>|null} [chcPayerIdByState] PayerDoc chcPayerIdByState
                 * @property {infinitusai.be.Association|null} [association] PayerDoc association
                 * @property {Array.<string>|null} [restrictedToOrgUuids] PayerDoc restrictedToOrgUuids
                 * @property {boolean|null} [enableAutoRetries] PayerDoc enableAutoRetries
                 * @property {boolean|null} [needsHumanIntro] PayerDoc needsHumanIntro
                 * @property {string|null} [uhcPayerId] PayerDoc uhcPayerId
                 * @property {boolean|null} [isMultitaskEnabled] PayerDoc isMultitaskEnabled
                 * @property {number|Long|null} [multitaskPerCallLimit] PayerDoc multitaskPerCallLimit
                 * @property {number|null} [concurrentCallLimit] PayerDoc concurrentCallLimit
                 * @property {infinitusai.be.IEvaOverridingHumanCalls|null} [humanCallOverrideConfig] PayerDoc humanCallOverrideConfig
                 * @property {boolean|null} [isFasttrackEnabled] PayerDoc isFasttrackEnabled
                 * @property {infinitusai.be.IElevenLabsConfig|null} [elevenLabsConfig] PayerDoc elevenLabsConfig
                 * @property {infinitusai.be.IMultitaskConfig|null} [multitaskConfig] PayerDoc multitaskConfig
                 * @property {string|null} [pverifyPayerId] PayerDoc pverifyPayerId
                 * @property {boolean|null} [enableCvsApi] PayerDoc enableCvsApi
                 * @property {infinitusai.be.IPayorDown|null} [payorDown] PayerDoc payorDown
                 * @property {Object.<string,string>|null} [linkedPayersMap] PayerDoc linkedPayersMap
                 * @property {string|null} [availityPayerId] PayerDoc availityPayerId
                 * @property {number|Long|null} [createdMillis] PayerDoc createdMillis
                 * @property {number|Long|null} [updatedMillis] PayerDoc updatedMillis
                 * @property {infinitusai.be.PayerDoc.PayerStatus|null} [payerStatus] PayerDoc payerStatus
                 * @property {boolean|null} [refusesToTalkToTpa] PayerDoc refusesToTalkToTpa
                 * @property {string|null} [availityPriorAuthPayerId] PayerDoc availityPriorAuthPayerId
                 */
    
                /**
                 * Constructs a new PayerDoc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerDoc.
                 * @implements IPayerDoc
                 * @constructor
                 * @param {infinitusai.be.IPayerDoc=} [properties] Properties to set
                 */
                function PayerDoc(properties) {
                    this.website = [];
                    this.alternateNameRegex = [];
                    this.states = [];
                    this.phoneNumber = [];
                    this.chcPayerIdByState = {};
                    this.restrictedToOrgUuids = [];
                    this.linkedPayersMap = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerDoc id.
                 * @member {string} id
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.id = "";
    
                /**
                 * PayerDoc name.
                 * @member {string} name
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.name = "";
    
                /**
                 * PayerDoc website.
                 * @member {Array.<string>} website
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.website = $util.emptyArray;
    
                /**
                 * PayerDoc alternateNameRegex.
                 * @member {Array.<string>} alternateNameRegex
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.alternateNameRegex = $util.emptyArray;
    
                /**
                 * PayerDoc states.
                 * @member {Array.<string>} states
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.states = $util.emptyArray;
    
                /**
                 * PayerDoc phoneNumber.
                 * @member {Array.<string>} phoneNumber
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.phoneNumber = $util.emptyArray;
    
                /**
                 * PayerDoc doNotCall.
                 * @member {infinitusai.be.PayerDoc.DoNotCallReason} doNotCall
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.doNotCall = 0;
    
                /**
                 * PayerDoc chcPayerId.
                 * @member {string} chcPayerId
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.chcPayerId = "";
    
                /**
                 * PayerDoc chcPayerIdByState.
                 * @member {Object.<string,string>} chcPayerIdByState
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.chcPayerIdByState = $util.emptyObject;
    
                /**
                 * PayerDoc association.
                 * @member {infinitusai.be.Association} association
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.association = 0;
    
                /**
                 * PayerDoc restrictedToOrgUuids.
                 * @member {Array.<string>} restrictedToOrgUuids
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.restrictedToOrgUuids = $util.emptyArray;
    
                /**
                 * PayerDoc enableAutoRetries.
                 * @member {boolean} enableAutoRetries
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.enableAutoRetries = false;
    
                /**
                 * PayerDoc needsHumanIntro.
                 * @member {boolean} needsHumanIntro
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.needsHumanIntro = false;
    
                /**
                 * PayerDoc uhcPayerId.
                 * @member {string} uhcPayerId
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.uhcPayerId = "";
    
                /**
                 * PayerDoc isMultitaskEnabled.
                 * @member {boolean} isMultitaskEnabled
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.isMultitaskEnabled = false;
    
                /**
                 * PayerDoc multitaskPerCallLimit.
                 * @member {number|Long} multitaskPerCallLimit
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.multitaskPerCallLimit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * PayerDoc concurrentCallLimit.
                 * @member {number} concurrentCallLimit
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.concurrentCallLimit = 0;
    
                /**
                 * PayerDoc humanCallOverrideConfig.
                 * @member {infinitusai.be.IEvaOverridingHumanCalls|null|undefined} humanCallOverrideConfig
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.humanCallOverrideConfig = null;
    
                /**
                 * PayerDoc isFasttrackEnabled.
                 * @member {boolean} isFasttrackEnabled
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.isFasttrackEnabled = false;
    
                /**
                 * PayerDoc elevenLabsConfig.
                 * @member {infinitusai.be.IElevenLabsConfig|null|undefined} elevenLabsConfig
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.elevenLabsConfig = null;
    
                /**
                 * PayerDoc multitaskConfig.
                 * @member {infinitusai.be.IMultitaskConfig|null|undefined} multitaskConfig
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.multitaskConfig = null;
    
                /**
                 * PayerDoc pverifyPayerId.
                 * @member {string} pverifyPayerId
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.pverifyPayerId = "";
    
                /**
                 * PayerDoc enableCvsApi.
                 * @member {boolean} enableCvsApi
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.enableCvsApi = false;
    
                /**
                 * PayerDoc payorDown.
                 * @member {infinitusai.be.IPayorDown|null|undefined} payorDown
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.payorDown = null;
    
                /**
                 * PayerDoc linkedPayersMap.
                 * @member {Object.<string,string>} linkedPayersMap
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.linkedPayersMap = $util.emptyObject;
    
                /**
                 * PayerDoc availityPayerId.
                 * @member {string} availityPayerId
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.availityPayerId = "";
    
                /**
                 * PayerDoc createdMillis.
                 * @member {number|Long} createdMillis
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.createdMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * PayerDoc updatedMillis.
                 * @member {number|Long} updatedMillis
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.updatedMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * PayerDoc payerStatus.
                 * @member {infinitusai.be.PayerDoc.PayerStatus} payerStatus
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.payerStatus = 0;
    
                /**
                 * PayerDoc refusesToTalkToTpa.
                 * @member {boolean} refusesToTalkToTpa
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.refusesToTalkToTpa = false;
    
                /**
                 * PayerDoc availityPriorAuthPayerId.
                 * @member {string} availityPriorAuthPayerId
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 */
                PayerDoc.prototype.availityPriorAuthPayerId = "";
    
                /**
                 * Creates a new PayerDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {infinitusai.be.IPayerDoc=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerDoc} PayerDoc instance
                 */
                PayerDoc.create = function create(properties) {
                    return new PayerDoc(properties);
                };
    
                /**
                 * Encodes the specified PayerDoc message. Does not implicitly {@link infinitusai.be.PayerDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {infinitusai.be.IPayerDoc} message PayerDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.alternateNameRegex != null && message.alternateNameRegex.length)
                        for (var i = 0; i < message.alternateNameRegex.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.alternateNameRegex[i]);
                    if (message.states != null && message.states.length)
                        for (var i = 0; i < message.states.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.states[i]);
                    if (message.phoneNumber != null && message.phoneNumber.length)
                        for (var i = 0; i < message.phoneNumber.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.phoneNumber[i]);
                    if (message.doNotCall != null && Object.hasOwnProperty.call(message, "doNotCall"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.doNotCall);
                    if (message.chcPayerId != null && Object.hasOwnProperty.call(message, "chcPayerId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.chcPayerId);
                    if (message.chcPayerIdByState != null && Object.hasOwnProperty.call(message, "chcPayerIdByState"))
                        for (var keys = Object.keys(message.chcPayerIdByState), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.chcPayerIdByState[keys[i]]).ldelim();
                    if (message.association != null && Object.hasOwnProperty.call(message, "association"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.association);
                    if (message.restrictedToOrgUuids != null && message.restrictedToOrgUuids.length)
                        for (var i = 0; i < message.restrictedToOrgUuids.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.restrictedToOrgUuids[i]);
                    if (message.enableAutoRetries != null && Object.hasOwnProperty.call(message, "enableAutoRetries"))
                        writer.uint32(/* id 13, wireType 0 =*/104).bool(message.enableAutoRetries);
                    if (message.website != null && message.website.length)
                        for (var i = 0; i < message.website.length; ++i)
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.website[i]);
                    if (message.needsHumanIntro != null && Object.hasOwnProperty.call(message, "needsHumanIntro"))
                        writer.uint32(/* id 21, wireType 0 =*/168).bool(message.needsHumanIntro);
                    if (message.uhcPayerId != null && Object.hasOwnProperty.call(message, "uhcPayerId"))
                        writer.uint32(/* id 22, wireType 2 =*/178).string(message.uhcPayerId);
                    if (message.isMultitaskEnabled != null && Object.hasOwnProperty.call(message, "isMultitaskEnabled"))
                        writer.uint32(/* id 23, wireType 0 =*/184).bool(message.isMultitaskEnabled);
                    if (message.multitaskPerCallLimit != null && Object.hasOwnProperty.call(message, "multitaskPerCallLimit"))
                        writer.uint32(/* id 24, wireType 0 =*/192).int64(message.multitaskPerCallLimit);
                    if (message.concurrentCallLimit != null && Object.hasOwnProperty.call(message, "concurrentCallLimit"))
                        writer.uint32(/* id 25, wireType 0 =*/200).int32(message.concurrentCallLimit);
                    if (message.humanCallOverrideConfig != null && Object.hasOwnProperty.call(message, "humanCallOverrideConfig"))
                        $root.infinitusai.be.EvaOverridingHumanCalls.encode(message.humanCallOverrideConfig, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                    if (message.isFasttrackEnabled != null && Object.hasOwnProperty.call(message, "isFasttrackEnabled"))
                        writer.uint32(/* id 27, wireType 0 =*/216).bool(message.isFasttrackEnabled);
                    if (message.elevenLabsConfig != null && Object.hasOwnProperty.call(message, "elevenLabsConfig"))
                        $root.infinitusai.be.ElevenLabsConfig.encode(message.elevenLabsConfig, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                    if (message.multitaskConfig != null && Object.hasOwnProperty.call(message, "multitaskConfig"))
                        $root.infinitusai.be.MultitaskConfig.encode(message.multitaskConfig, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                    if (message.pverifyPayerId != null && Object.hasOwnProperty.call(message, "pverifyPayerId"))
                        writer.uint32(/* id 30, wireType 2 =*/242).string(message.pverifyPayerId);
                    if (message.enableCvsApi != null && Object.hasOwnProperty.call(message, "enableCvsApi"))
                        writer.uint32(/* id 31, wireType 0 =*/248).bool(message.enableCvsApi);
                    if (message.payorDown != null && Object.hasOwnProperty.call(message, "payorDown"))
                        $root.infinitusai.be.PayorDown.encode(message.payorDown, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                    if (message.linkedPayersMap != null && Object.hasOwnProperty.call(message, "linkedPayersMap"))
                        for (var keys = Object.keys(message.linkedPayersMap), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 33, wireType 2 =*/266).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.linkedPayersMap[keys[i]]).ldelim();
                    if (message.availityPayerId != null && Object.hasOwnProperty.call(message, "availityPayerId"))
                        writer.uint32(/* id 34, wireType 2 =*/274).string(message.availityPayerId);
                    if (message.createdMillis != null && Object.hasOwnProperty.call(message, "createdMillis"))
                        writer.uint32(/* id 35, wireType 0 =*/280).int64(message.createdMillis);
                    if (message.updatedMillis != null && Object.hasOwnProperty.call(message, "updatedMillis"))
                        writer.uint32(/* id 36, wireType 0 =*/288).int64(message.updatedMillis);
                    if (message.payerStatus != null && Object.hasOwnProperty.call(message, "payerStatus"))
                        writer.uint32(/* id 37, wireType 0 =*/296).int32(message.payerStatus);
                    if (message.refusesToTalkToTpa != null && Object.hasOwnProperty.call(message, "refusesToTalkToTpa"))
                        writer.uint32(/* id 38, wireType 0 =*/304).bool(message.refusesToTalkToTpa);
                    if (message.availityPriorAuthPayerId != null && Object.hasOwnProperty.call(message, "availityPriorAuthPayerId"))
                        writer.uint32(/* id 39, wireType 2 =*/314).string(message.availityPriorAuthPayerId);
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerDoc message, length delimited. Does not implicitly {@link infinitusai.be.PayerDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {infinitusai.be.IPayerDoc} message PayerDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerDoc} PayerDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerDoc(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 17: {
                                if (!(message.website && message.website.length))
                                    message.website = [];
                                message.website.push(reader.string());
                                break;
                            }
                        case 3: {
                                if (!(message.alternateNameRegex && message.alternateNameRegex.length))
                                    message.alternateNameRegex = [];
                                message.alternateNameRegex.push(reader.string());
                                break;
                            }
                        case 4: {
                                if (!(message.states && message.states.length))
                                    message.states = [];
                                message.states.push(reader.string());
                                break;
                            }
                        case 5: {
                                if (!(message.phoneNumber && message.phoneNumber.length))
                                    message.phoneNumber = [];
                                message.phoneNumber.push(reader.string());
                                break;
                            }
                        case 6: {
                                message.doNotCall = reader.int32();
                                break;
                            }
                        case 7: {
                                message.chcPayerId = reader.string();
                                break;
                            }
                        case 8: {
                                if (message.chcPayerIdByState === $util.emptyObject)
                                    message.chcPayerIdByState = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.chcPayerIdByState[key] = value;
                                break;
                            }
                        case 9: {
                                message.association = reader.int32();
                                break;
                            }
                        case 10: {
                                if (!(message.restrictedToOrgUuids && message.restrictedToOrgUuids.length))
                                    message.restrictedToOrgUuids = [];
                                message.restrictedToOrgUuids.push(reader.string());
                                break;
                            }
                        case 13: {
                                message.enableAutoRetries = reader.bool();
                                break;
                            }
                        case 21: {
                                message.needsHumanIntro = reader.bool();
                                break;
                            }
                        case 22: {
                                message.uhcPayerId = reader.string();
                                break;
                            }
                        case 23: {
                                message.isMultitaskEnabled = reader.bool();
                                break;
                            }
                        case 24: {
                                message.multitaskPerCallLimit = reader.int64();
                                break;
                            }
                        case 25: {
                                message.concurrentCallLimit = reader.int32();
                                break;
                            }
                        case 26: {
                                message.humanCallOverrideConfig = $root.infinitusai.be.EvaOverridingHumanCalls.decode(reader, reader.uint32());
                                break;
                            }
                        case 27: {
                                message.isFasttrackEnabled = reader.bool();
                                break;
                            }
                        case 28: {
                                message.elevenLabsConfig = $root.infinitusai.be.ElevenLabsConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 29: {
                                message.multitaskConfig = $root.infinitusai.be.MultitaskConfig.decode(reader, reader.uint32());
                                break;
                            }
                        case 30: {
                                message.pverifyPayerId = reader.string();
                                break;
                            }
                        case 31: {
                                message.enableCvsApi = reader.bool();
                                break;
                            }
                        case 32: {
                                message.payorDown = $root.infinitusai.be.PayorDown.decode(reader, reader.uint32());
                                break;
                            }
                        case 33: {
                                if (message.linkedPayersMap === $util.emptyObject)
                                    message.linkedPayersMap = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.linkedPayersMap[key] = value;
                                break;
                            }
                        case 34: {
                                message.availityPayerId = reader.string();
                                break;
                            }
                        case 35: {
                                message.createdMillis = reader.int64();
                                break;
                            }
                        case 36: {
                                message.updatedMillis = reader.int64();
                                break;
                            }
                        case 37: {
                                message.payerStatus = reader.int32();
                                break;
                            }
                        case 38: {
                                message.refusesToTalkToTpa = reader.bool();
                                break;
                            }
                        case 39: {
                                message.availityPriorAuthPayerId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerDoc} PayerDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerDoc message.
                 * @function verify
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.website != null && message.hasOwnProperty("website")) {
                        if (!Array.isArray(message.website))
                            return "website: array expected";
                        for (var i = 0; i < message.website.length; ++i)
                            if (!$util.isString(message.website[i]))
                                return "website: string[] expected";
                    }
                    if (message.alternateNameRegex != null && message.hasOwnProperty("alternateNameRegex")) {
                        if (!Array.isArray(message.alternateNameRegex))
                            return "alternateNameRegex: array expected";
                        for (var i = 0; i < message.alternateNameRegex.length; ++i)
                            if (!$util.isString(message.alternateNameRegex[i]))
                                return "alternateNameRegex: string[] expected";
                    }
                    if (message.states != null && message.hasOwnProperty("states")) {
                        if (!Array.isArray(message.states))
                            return "states: array expected";
                        for (var i = 0; i < message.states.length; ++i)
                            if (!$util.isString(message.states[i]))
                                return "states: string[] expected";
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        if (!Array.isArray(message.phoneNumber))
                            return "phoneNumber: array expected";
                        for (var i = 0; i < message.phoneNumber.length; ++i)
                            if (!$util.isString(message.phoneNumber[i]))
                                return "phoneNumber: string[] expected";
                    }
                    if (message.doNotCall != null && message.hasOwnProperty("doNotCall"))
                        switch (message.doNotCall) {
                        default:
                            return "doNotCall: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.chcPayerId != null && message.hasOwnProperty("chcPayerId"))
                        if (!$util.isString(message.chcPayerId))
                            return "chcPayerId: string expected";
                    if (message.chcPayerIdByState != null && message.hasOwnProperty("chcPayerIdByState")) {
                        if (!$util.isObject(message.chcPayerIdByState))
                            return "chcPayerIdByState: object expected";
                        var key = Object.keys(message.chcPayerIdByState);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.chcPayerIdByState[key[i]]))
                                return "chcPayerIdByState: string{k:string} expected";
                    }
                    if (message.association != null && message.hasOwnProperty("association"))
                        switch (message.association) {
                        default:
                            return "association: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.restrictedToOrgUuids != null && message.hasOwnProperty("restrictedToOrgUuids")) {
                        if (!Array.isArray(message.restrictedToOrgUuids))
                            return "restrictedToOrgUuids: array expected";
                        for (var i = 0; i < message.restrictedToOrgUuids.length; ++i)
                            if (!$util.isString(message.restrictedToOrgUuids[i]))
                                return "restrictedToOrgUuids: string[] expected";
                    }
                    if (message.enableAutoRetries != null && message.hasOwnProperty("enableAutoRetries"))
                        if (typeof message.enableAutoRetries !== "boolean")
                            return "enableAutoRetries: boolean expected";
                    if (message.needsHumanIntro != null && message.hasOwnProperty("needsHumanIntro"))
                        if (typeof message.needsHumanIntro !== "boolean")
                            return "needsHumanIntro: boolean expected";
                    if (message.uhcPayerId != null && message.hasOwnProperty("uhcPayerId"))
                        if (!$util.isString(message.uhcPayerId))
                            return "uhcPayerId: string expected";
                    if (message.isMultitaskEnabled != null && message.hasOwnProperty("isMultitaskEnabled"))
                        if (typeof message.isMultitaskEnabled !== "boolean")
                            return "isMultitaskEnabled: boolean expected";
                    if (message.multitaskPerCallLimit != null && message.hasOwnProperty("multitaskPerCallLimit"))
                        if (!$util.isInteger(message.multitaskPerCallLimit) && !(message.multitaskPerCallLimit && $util.isInteger(message.multitaskPerCallLimit.low) && $util.isInteger(message.multitaskPerCallLimit.high)))
                            return "multitaskPerCallLimit: integer|Long expected";
                    if (message.concurrentCallLimit != null && message.hasOwnProperty("concurrentCallLimit"))
                        if (!$util.isInteger(message.concurrentCallLimit))
                            return "concurrentCallLimit: integer expected";
                    if (message.humanCallOverrideConfig != null && message.hasOwnProperty("humanCallOverrideConfig")) {
                        var error = $root.infinitusai.be.EvaOverridingHumanCalls.verify(message.humanCallOverrideConfig);
                        if (error)
                            return "humanCallOverrideConfig." + error;
                    }
                    if (message.isFasttrackEnabled != null && message.hasOwnProperty("isFasttrackEnabled"))
                        if (typeof message.isFasttrackEnabled !== "boolean")
                            return "isFasttrackEnabled: boolean expected";
                    if (message.elevenLabsConfig != null && message.hasOwnProperty("elevenLabsConfig")) {
                        var error = $root.infinitusai.be.ElevenLabsConfig.verify(message.elevenLabsConfig);
                        if (error)
                            return "elevenLabsConfig." + error;
                    }
                    if (message.multitaskConfig != null && message.hasOwnProperty("multitaskConfig")) {
                        var error = $root.infinitusai.be.MultitaskConfig.verify(message.multitaskConfig);
                        if (error)
                            return "multitaskConfig." + error;
                    }
                    if (message.pverifyPayerId != null && message.hasOwnProperty("pverifyPayerId"))
                        if (!$util.isString(message.pverifyPayerId))
                            return "pverifyPayerId: string expected";
                    if (message.enableCvsApi != null && message.hasOwnProperty("enableCvsApi"))
                        if (typeof message.enableCvsApi !== "boolean")
                            return "enableCvsApi: boolean expected";
                    if (message.payorDown != null && message.hasOwnProperty("payorDown")) {
                        var error = $root.infinitusai.be.PayorDown.verify(message.payorDown);
                        if (error)
                            return "payorDown." + error;
                    }
                    if (message.linkedPayersMap != null && message.hasOwnProperty("linkedPayersMap")) {
                        if (!$util.isObject(message.linkedPayersMap))
                            return "linkedPayersMap: object expected";
                        var key = Object.keys(message.linkedPayersMap);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.linkedPayersMap[key[i]]))
                                return "linkedPayersMap: string{k:string} expected";
                    }
                    if (message.availityPayerId != null && message.hasOwnProperty("availityPayerId"))
                        if (!$util.isString(message.availityPayerId))
                            return "availityPayerId: string expected";
                    if (message.createdMillis != null && message.hasOwnProperty("createdMillis"))
                        if (!$util.isInteger(message.createdMillis) && !(message.createdMillis && $util.isInteger(message.createdMillis.low) && $util.isInteger(message.createdMillis.high)))
                            return "createdMillis: integer|Long expected";
                    if (message.updatedMillis != null && message.hasOwnProperty("updatedMillis"))
                        if (!$util.isInteger(message.updatedMillis) && !(message.updatedMillis && $util.isInteger(message.updatedMillis.low) && $util.isInteger(message.updatedMillis.high)))
                            return "updatedMillis: integer|Long expected";
                    if (message.payerStatus != null && message.hasOwnProperty("payerStatus"))
                        switch (message.payerStatus) {
                        default:
                            return "payerStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.refusesToTalkToTpa != null && message.hasOwnProperty("refusesToTalkToTpa"))
                        if (typeof message.refusesToTalkToTpa !== "boolean")
                            return "refusesToTalkToTpa: boolean expected";
                    if (message.availityPriorAuthPayerId != null && message.hasOwnProperty("availityPriorAuthPayerId"))
                        if (!$util.isString(message.availityPriorAuthPayerId))
                            return "availityPriorAuthPayerId: string expected";
                    return null;
                };
    
                /**
                 * Creates a PayerDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerDoc} PayerDoc
                 */
                PayerDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerDoc)
                        return object;
                    var message = new $root.infinitusai.be.PayerDoc();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.website) {
                        if (!Array.isArray(object.website))
                            throw TypeError(".infinitusai.be.PayerDoc.website: array expected");
                        message.website = [];
                        for (var i = 0; i < object.website.length; ++i)
                            message.website[i] = String(object.website[i]);
                    }
                    if (object.alternateNameRegex) {
                        if (!Array.isArray(object.alternateNameRegex))
                            throw TypeError(".infinitusai.be.PayerDoc.alternateNameRegex: array expected");
                        message.alternateNameRegex = [];
                        for (var i = 0; i < object.alternateNameRegex.length; ++i)
                            message.alternateNameRegex[i] = String(object.alternateNameRegex[i]);
                    }
                    if (object.states) {
                        if (!Array.isArray(object.states))
                            throw TypeError(".infinitusai.be.PayerDoc.states: array expected");
                        message.states = [];
                        for (var i = 0; i < object.states.length; ++i)
                            message.states[i] = String(object.states[i]);
                    }
                    if (object.phoneNumber) {
                        if (!Array.isArray(object.phoneNumber))
                            throw TypeError(".infinitusai.be.PayerDoc.phoneNumber: array expected");
                        message.phoneNumber = [];
                        for (var i = 0; i < object.phoneNumber.length; ++i)
                            message.phoneNumber[i] = String(object.phoneNumber[i]);
                    }
                    switch (object.doNotCall) {
                    default:
                        if (typeof object.doNotCall === "number") {
                            message.doNotCall = object.doNotCall;
                            break;
                        }
                        break;
                    case "REASON_OK_TO_CALL":
                    case 0:
                        message.doNotCall = 0;
                        break;
                    case "REASON_REFUSES_TO_TALK_WITH_EVA":
                    case 1:
                        message.doNotCall = 1;
                        break;
                    case "REASON_PHARMACY_BENEFIT_MANAGER":
                    case 2:
                        message.doNotCall = 2;
                        break;
                    case "REASON_OTHER":
                    case 3:
                        message.doNotCall = 3;
                        break;
                    }
                    if (object.chcPayerId != null)
                        message.chcPayerId = String(object.chcPayerId);
                    if (object.chcPayerIdByState) {
                        if (typeof object.chcPayerIdByState !== "object")
                            throw TypeError(".infinitusai.be.PayerDoc.chcPayerIdByState: object expected");
                        message.chcPayerIdByState = {};
                        for (var keys = Object.keys(object.chcPayerIdByState), i = 0; i < keys.length; ++i)
                            message.chcPayerIdByState[keys[i]] = String(object.chcPayerIdByState[keys[i]]);
                    }
                    switch (object.association) {
                    default:
                        if (typeof object.association === "number") {
                            message.association = object.association;
                            break;
                        }
                        break;
                    case "ASSOCIATION_UNKNOWN":
                    case 0:
                        message.association = 0;
                        break;
                    case "ASSOCIATION_BCBS":
                    case 1:
                        message.association = 1;
                        break;
                    case "ASSOCIATION_BCBS_FEDERAL":
                    case 2:
                        message.association = 2;
                        break;
                    }
                    if (object.restrictedToOrgUuids) {
                        if (!Array.isArray(object.restrictedToOrgUuids))
                            throw TypeError(".infinitusai.be.PayerDoc.restrictedToOrgUuids: array expected");
                        message.restrictedToOrgUuids = [];
                        for (var i = 0; i < object.restrictedToOrgUuids.length; ++i)
                            message.restrictedToOrgUuids[i] = String(object.restrictedToOrgUuids[i]);
                    }
                    if (object.enableAutoRetries != null)
                        message.enableAutoRetries = Boolean(object.enableAutoRetries);
                    if (object.needsHumanIntro != null)
                        message.needsHumanIntro = Boolean(object.needsHumanIntro);
                    if (object.uhcPayerId != null)
                        message.uhcPayerId = String(object.uhcPayerId);
                    if (object.isMultitaskEnabled != null)
                        message.isMultitaskEnabled = Boolean(object.isMultitaskEnabled);
                    if (object.multitaskPerCallLimit != null)
                        if ($util.Long)
                            (message.multitaskPerCallLimit = $util.Long.fromValue(object.multitaskPerCallLimit)).unsigned = false;
                        else if (typeof object.multitaskPerCallLimit === "string")
                            message.multitaskPerCallLimit = parseInt(object.multitaskPerCallLimit, 10);
                        else if (typeof object.multitaskPerCallLimit === "number")
                            message.multitaskPerCallLimit = object.multitaskPerCallLimit;
                        else if (typeof object.multitaskPerCallLimit === "object")
                            message.multitaskPerCallLimit = new $util.LongBits(object.multitaskPerCallLimit.low >>> 0, object.multitaskPerCallLimit.high >>> 0).toNumber();
                    if (object.concurrentCallLimit != null)
                        message.concurrentCallLimit = object.concurrentCallLimit | 0;
                    if (object.humanCallOverrideConfig != null) {
                        if (typeof object.humanCallOverrideConfig !== "object")
                            throw TypeError(".infinitusai.be.PayerDoc.humanCallOverrideConfig: object expected");
                        message.humanCallOverrideConfig = $root.infinitusai.be.EvaOverridingHumanCalls.fromObject(object.humanCallOverrideConfig);
                    }
                    if (object.isFasttrackEnabled != null)
                        message.isFasttrackEnabled = Boolean(object.isFasttrackEnabled);
                    if (object.elevenLabsConfig != null) {
                        if (typeof object.elevenLabsConfig !== "object")
                            throw TypeError(".infinitusai.be.PayerDoc.elevenLabsConfig: object expected");
                        message.elevenLabsConfig = $root.infinitusai.be.ElevenLabsConfig.fromObject(object.elevenLabsConfig);
                    }
                    if (object.multitaskConfig != null) {
                        if (typeof object.multitaskConfig !== "object")
                            throw TypeError(".infinitusai.be.PayerDoc.multitaskConfig: object expected");
                        message.multitaskConfig = $root.infinitusai.be.MultitaskConfig.fromObject(object.multitaskConfig);
                    }
                    if (object.pverifyPayerId != null)
                        message.pverifyPayerId = String(object.pverifyPayerId);
                    if (object.enableCvsApi != null)
                        message.enableCvsApi = Boolean(object.enableCvsApi);
                    if (object.payorDown != null) {
                        if (typeof object.payorDown !== "object")
                            throw TypeError(".infinitusai.be.PayerDoc.payorDown: object expected");
                        message.payorDown = $root.infinitusai.be.PayorDown.fromObject(object.payorDown);
                    }
                    if (object.linkedPayersMap) {
                        if (typeof object.linkedPayersMap !== "object")
                            throw TypeError(".infinitusai.be.PayerDoc.linkedPayersMap: object expected");
                        message.linkedPayersMap = {};
                        for (var keys = Object.keys(object.linkedPayersMap), i = 0; i < keys.length; ++i)
                            message.linkedPayersMap[keys[i]] = String(object.linkedPayersMap[keys[i]]);
                    }
                    if (object.availityPayerId != null)
                        message.availityPayerId = String(object.availityPayerId);
                    if (object.createdMillis != null)
                        if ($util.Long)
                            (message.createdMillis = $util.Long.fromValue(object.createdMillis)).unsigned = false;
                        else if (typeof object.createdMillis === "string")
                            message.createdMillis = parseInt(object.createdMillis, 10);
                        else if (typeof object.createdMillis === "number")
                            message.createdMillis = object.createdMillis;
                        else if (typeof object.createdMillis === "object")
                            message.createdMillis = new $util.LongBits(object.createdMillis.low >>> 0, object.createdMillis.high >>> 0).toNumber();
                    if (object.updatedMillis != null)
                        if ($util.Long)
                            (message.updatedMillis = $util.Long.fromValue(object.updatedMillis)).unsigned = false;
                        else if (typeof object.updatedMillis === "string")
                            message.updatedMillis = parseInt(object.updatedMillis, 10);
                        else if (typeof object.updatedMillis === "number")
                            message.updatedMillis = object.updatedMillis;
                        else if (typeof object.updatedMillis === "object")
                            message.updatedMillis = new $util.LongBits(object.updatedMillis.low >>> 0, object.updatedMillis.high >>> 0).toNumber();
                    switch (object.payerStatus) {
                    default:
                        if (typeof object.payerStatus === "number") {
                            message.payerStatus = object.payerStatus;
                            break;
                        }
                        break;
                    case "PAYER_STATUS_ACTIVE":
                    case 0:
                        message.payerStatus = 0;
                        break;
                    case "PAYER_STATUS_ARCHIVED":
                    case 1:
                        message.payerStatus = 1;
                        break;
                    case "PAYER_STATUS_INTERNAL":
                    case 2:
                        message.payerStatus = 2;
                        break;
                    }
                    if (object.refusesToTalkToTpa != null)
                        message.refusesToTalkToTpa = Boolean(object.refusesToTalkToTpa);
                    if (object.availityPriorAuthPayerId != null)
                        message.availityPriorAuthPayerId = String(object.availityPriorAuthPayerId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {infinitusai.be.PayerDoc} message PayerDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.alternateNameRegex = [];
                        object.states = [];
                        object.phoneNumber = [];
                        object.restrictedToOrgUuids = [];
                        object.website = [];
                    }
                    if (options.objects || options.defaults) {
                        object.chcPayerIdByState = {};
                        object.linkedPayersMap = {};
                    }
                    if (options.defaults) {
                        object.id = "";
                        object.name = "";
                        object.doNotCall = options.enums === String ? "REASON_OK_TO_CALL" : 0;
                        object.chcPayerId = "";
                        object.association = options.enums === String ? "ASSOCIATION_UNKNOWN" : 0;
                        object.enableAutoRetries = false;
                        object.needsHumanIntro = false;
                        object.uhcPayerId = "";
                        object.isMultitaskEnabled = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.multitaskPerCallLimit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.multitaskPerCallLimit = options.longs === String ? "0" : 0;
                        object.concurrentCallLimit = 0;
                        object.humanCallOverrideConfig = null;
                        object.isFasttrackEnabled = false;
                        object.elevenLabsConfig = null;
                        object.multitaskConfig = null;
                        object.pverifyPayerId = "";
                        object.enableCvsApi = false;
                        object.payorDown = null;
                        object.availityPayerId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updatedMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedMillis = options.longs === String ? "0" : 0;
                        object.payerStatus = options.enums === String ? "PAYER_STATUS_ACTIVE" : 0;
                        object.refusesToTalkToTpa = false;
                        object.availityPriorAuthPayerId = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.alternateNameRegex && message.alternateNameRegex.length) {
                        object.alternateNameRegex = [];
                        for (var j = 0; j < message.alternateNameRegex.length; ++j)
                            object.alternateNameRegex[j] = message.alternateNameRegex[j];
                    }
                    if (message.states && message.states.length) {
                        object.states = [];
                        for (var j = 0; j < message.states.length; ++j)
                            object.states[j] = message.states[j];
                    }
                    if (message.phoneNumber && message.phoneNumber.length) {
                        object.phoneNumber = [];
                        for (var j = 0; j < message.phoneNumber.length; ++j)
                            object.phoneNumber[j] = message.phoneNumber[j];
                    }
                    if (message.doNotCall != null && message.hasOwnProperty("doNotCall"))
                        object.doNotCall = options.enums === String ? $root.infinitusai.be.PayerDoc.DoNotCallReason[message.doNotCall] === undefined ? message.doNotCall : $root.infinitusai.be.PayerDoc.DoNotCallReason[message.doNotCall] : message.doNotCall;
                    if (message.chcPayerId != null && message.hasOwnProperty("chcPayerId"))
                        object.chcPayerId = message.chcPayerId;
                    var keys2;
                    if (message.chcPayerIdByState && (keys2 = Object.keys(message.chcPayerIdByState)).length) {
                        object.chcPayerIdByState = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.chcPayerIdByState[keys2[j]] = message.chcPayerIdByState[keys2[j]];
                    }
                    if (message.association != null && message.hasOwnProperty("association"))
                        object.association = options.enums === String ? $root.infinitusai.be.Association[message.association] === undefined ? message.association : $root.infinitusai.be.Association[message.association] : message.association;
                    if (message.restrictedToOrgUuids && message.restrictedToOrgUuids.length) {
                        object.restrictedToOrgUuids = [];
                        for (var j = 0; j < message.restrictedToOrgUuids.length; ++j)
                            object.restrictedToOrgUuids[j] = message.restrictedToOrgUuids[j];
                    }
                    if (message.enableAutoRetries != null && message.hasOwnProperty("enableAutoRetries"))
                        object.enableAutoRetries = message.enableAutoRetries;
                    if (message.website && message.website.length) {
                        object.website = [];
                        for (var j = 0; j < message.website.length; ++j)
                            object.website[j] = message.website[j];
                    }
                    if (message.needsHumanIntro != null && message.hasOwnProperty("needsHumanIntro"))
                        object.needsHumanIntro = message.needsHumanIntro;
                    if (message.uhcPayerId != null && message.hasOwnProperty("uhcPayerId"))
                        object.uhcPayerId = message.uhcPayerId;
                    if (message.isMultitaskEnabled != null && message.hasOwnProperty("isMultitaskEnabled"))
                        object.isMultitaskEnabled = message.isMultitaskEnabled;
                    if (message.multitaskPerCallLimit != null && message.hasOwnProperty("multitaskPerCallLimit"))
                        if (typeof message.multitaskPerCallLimit === "number")
                            object.multitaskPerCallLimit = options.longs === String ? String(message.multitaskPerCallLimit) : message.multitaskPerCallLimit;
                        else
                            object.multitaskPerCallLimit = options.longs === String ? $util.Long.prototype.toString.call(message.multitaskPerCallLimit) : options.longs === Number ? new $util.LongBits(message.multitaskPerCallLimit.low >>> 0, message.multitaskPerCallLimit.high >>> 0).toNumber() : message.multitaskPerCallLimit;
                    if (message.concurrentCallLimit != null && message.hasOwnProperty("concurrentCallLimit"))
                        object.concurrentCallLimit = message.concurrentCallLimit;
                    if (message.humanCallOverrideConfig != null && message.hasOwnProperty("humanCallOverrideConfig"))
                        object.humanCallOverrideConfig = $root.infinitusai.be.EvaOverridingHumanCalls.toObject(message.humanCallOverrideConfig, options);
                    if (message.isFasttrackEnabled != null && message.hasOwnProperty("isFasttrackEnabled"))
                        object.isFasttrackEnabled = message.isFasttrackEnabled;
                    if (message.elevenLabsConfig != null && message.hasOwnProperty("elevenLabsConfig"))
                        object.elevenLabsConfig = $root.infinitusai.be.ElevenLabsConfig.toObject(message.elevenLabsConfig, options);
                    if (message.multitaskConfig != null && message.hasOwnProperty("multitaskConfig"))
                        object.multitaskConfig = $root.infinitusai.be.MultitaskConfig.toObject(message.multitaskConfig, options);
                    if (message.pverifyPayerId != null && message.hasOwnProperty("pverifyPayerId"))
                        object.pverifyPayerId = message.pverifyPayerId;
                    if (message.enableCvsApi != null && message.hasOwnProperty("enableCvsApi"))
                        object.enableCvsApi = message.enableCvsApi;
                    if (message.payorDown != null && message.hasOwnProperty("payorDown"))
                        object.payorDown = $root.infinitusai.be.PayorDown.toObject(message.payorDown, options);
                    if (message.linkedPayersMap && (keys2 = Object.keys(message.linkedPayersMap)).length) {
                        object.linkedPayersMap = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.linkedPayersMap[keys2[j]] = message.linkedPayersMap[keys2[j]];
                    }
                    if (message.availityPayerId != null && message.hasOwnProperty("availityPayerId"))
                        object.availityPayerId = message.availityPayerId;
                    if (message.createdMillis != null && message.hasOwnProperty("createdMillis"))
                        if (typeof message.createdMillis === "number")
                            object.createdMillis = options.longs === String ? String(message.createdMillis) : message.createdMillis;
                        else
                            object.createdMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdMillis) : options.longs === Number ? new $util.LongBits(message.createdMillis.low >>> 0, message.createdMillis.high >>> 0).toNumber() : message.createdMillis;
                    if (message.updatedMillis != null && message.hasOwnProperty("updatedMillis"))
                        if (typeof message.updatedMillis === "number")
                            object.updatedMillis = options.longs === String ? String(message.updatedMillis) : message.updatedMillis;
                        else
                            object.updatedMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updatedMillis) : options.longs === Number ? new $util.LongBits(message.updatedMillis.low >>> 0, message.updatedMillis.high >>> 0).toNumber() : message.updatedMillis;
                    if (message.payerStatus != null && message.hasOwnProperty("payerStatus"))
                        object.payerStatus = options.enums === String ? $root.infinitusai.be.PayerDoc.PayerStatus[message.payerStatus] === undefined ? message.payerStatus : $root.infinitusai.be.PayerDoc.PayerStatus[message.payerStatus] : message.payerStatus;
                    if (message.refusesToTalkToTpa != null && message.hasOwnProperty("refusesToTalkToTpa"))
                        object.refusesToTalkToTpa = message.refusesToTalkToTpa;
                    if (message.availityPriorAuthPayerId != null && message.hasOwnProperty("availityPriorAuthPayerId"))
                        object.availityPriorAuthPayerId = message.availityPriorAuthPayerId;
                    return object;
                };
    
                /**
                 * Converts this PayerDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerDoc";
                };
    
                /**
                 * DoNotCallReason enum.
                 * @name infinitusai.be.PayerDoc.DoNotCallReason
                 * @enum {number}
                 * @property {number} REASON_OK_TO_CALL=0 REASON_OK_TO_CALL value
                 * @property {number} REASON_REFUSES_TO_TALK_WITH_EVA=1 REASON_REFUSES_TO_TALK_WITH_EVA value
                 * @property {number} REASON_PHARMACY_BENEFIT_MANAGER=2 REASON_PHARMACY_BENEFIT_MANAGER value
                 * @property {number} REASON_OTHER=3 REASON_OTHER value
                 */
                PayerDoc.DoNotCallReason = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REASON_OK_TO_CALL"] = 0;
                    values[valuesById[1] = "REASON_REFUSES_TO_TALK_WITH_EVA"] = 1;
                    values[valuesById[2] = "REASON_PHARMACY_BENEFIT_MANAGER"] = 2;
                    values[valuesById[3] = "REASON_OTHER"] = 3;
                    return values;
                })();
    
                /**
                 * PayerStatus enum.
                 * @name infinitusai.be.PayerDoc.PayerStatus
                 * @enum {number}
                 * @property {number} PAYER_STATUS_ACTIVE=0 PAYER_STATUS_ACTIVE value
                 * @property {number} PAYER_STATUS_ARCHIVED=1 PAYER_STATUS_ARCHIVED value
                 * @property {number} PAYER_STATUS_INTERNAL=2 PAYER_STATUS_INTERNAL value
                 */
                PayerDoc.PayerStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PAYER_STATUS_ACTIVE"] = 0;
                    values[valuesById[1] = "PAYER_STATUS_ARCHIVED"] = 1;
                    values[valuesById[2] = "PAYER_STATUS_INTERNAL"] = 2;
                    return values;
                })();
    
                return PayerDoc;
            })();
    
            be.EvaOverridingHumanCalls = (function() {
    
                /**
                 * Properties of an EvaOverridingHumanCalls.
                 * @memberof infinitusai.be
                 * @interface IEvaOverridingHumanCalls
                 * @property {boolean|null} [isParticipating] EvaOverridingHumanCalls isParticipating
                 * @property {Array.<string>|null} [orgUuids] EvaOverridingHumanCalls orgUuids
                 */
    
                /**
                 * Constructs a new EvaOverridingHumanCalls.
                 * @memberof infinitusai.be
                 * @classdesc Represents an EvaOverridingHumanCalls.
                 * @implements IEvaOverridingHumanCalls
                 * @constructor
                 * @param {infinitusai.be.IEvaOverridingHumanCalls=} [properties] Properties to set
                 */
                function EvaOverridingHumanCalls(properties) {
                    this.orgUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EvaOverridingHumanCalls isParticipating.
                 * @member {boolean} isParticipating
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @instance
                 */
                EvaOverridingHumanCalls.prototype.isParticipating = false;
    
                /**
                 * EvaOverridingHumanCalls orgUuids.
                 * @member {Array.<string>} orgUuids
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @instance
                 */
                EvaOverridingHumanCalls.prototype.orgUuids = $util.emptyArray;
    
                /**
                 * Creates a new EvaOverridingHumanCalls instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {infinitusai.be.IEvaOverridingHumanCalls=} [properties] Properties to set
                 * @returns {infinitusai.be.EvaOverridingHumanCalls} EvaOverridingHumanCalls instance
                 */
                EvaOverridingHumanCalls.create = function create(properties) {
                    return new EvaOverridingHumanCalls(properties);
                };
    
                /**
                 * Encodes the specified EvaOverridingHumanCalls message. Does not implicitly {@link infinitusai.be.EvaOverridingHumanCalls.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {infinitusai.be.IEvaOverridingHumanCalls} message EvaOverridingHumanCalls message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaOverridingHumanCalls.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isParticipating != null && Object.hasOwnProperty.call(message, "isParticipating"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isParticipating);
                    if (message.orgUuids != null && message.orgUuids.length)
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified EvaOverridingHumanCalls message, length delimited. Does not implicitly {@link infinitusai.be.EvaOverridingHumanCalls.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {infinitusai.be.IEvaOverridingHumanCalls} message EvaOverridingHumanCalls message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EvaOverridingHumanCalls.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EvaOverridingHumanCalls message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.EvaOverridingHumanCalls} EvaOverridingHumanCalls
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaOverridingHumanCalls.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.EvaOverridingHumanCalls();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isParticipating = reader.bool();
                                break;
                            }
                        case 2: {
                                if (!(message.orgUuids && message.orgUuids.length))
                                    message.orgUuids = [];
                                message.orgUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EvaOverridingHumanCalls message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.EvaOverridingHumanCalls} EvaOverridingHumanCalls
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EvaOverridingHumanCalls.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EvaOverridingHumanCalls message.
                 * @function verify
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EvaOverridingHumanCalls.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isParticipating != null && message.hasOwnProperty("isParticipating"))
                        if (typeof message.isParticipating !== "boolean")
                            return "isParticipating: boolean expected";
                    if (message.orgUuids != null && message.hasOwnProperty("orgUuids")) {
                        if (!Array.isArray(message.orgUuids))
                            return "orgUuids: array expected";
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            if (!$util.isString(message.orgUuids[i]))
                                return "orgUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an EvaOverridingHumanCalls message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.EvaOverridingHumanCalls} EvaOverridingHumanCalls
                 */
                EvaOverridingHumanCalls.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.EvaOverridingHumanCalls)
                        return object;
                    var message = new $root.infinitusai.be.EvaOverridingHumanCalls();
                    if (object.isParticipating != null)
                        message.isParticipating = Boolean(object.isParticipating);
                    if (object.orgUuids) {
                        if (!Array.isArray(object.orgUuids))
                            throw TypeError(".infinitusai.be.EvaOverridingHumanCalls.orgUuids: array expected");
                        message.orgUuids = [];
                        for (var i = 0; i < object.orgUuids.length; ++i)
                            message.orgUuids[i] = String(object.orgUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EvaOverridingHumanCalls message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {infinitusai.be.EvaOverridingHumanCalls} message EvaOverridingHumanCalls
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EvaOverridingHumanCalls.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgUuids = [];
                    if (options.defaults)
                        object.isParticipating = false;
                    if (message.isParticipating != null && message.hasOwnProperty("isParticipating"))
                        object.isParticipating = message.isParticipating;
                    if (message.orgUuids && message.orgUuids.length) {
                        object.orgUuids = [];
                        for (var j = 0; j < message.orgUuids.length; ++j)
                            object.orgUuids[j] = message.orgUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this EvaOverridingHumanCalls to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EvaOverridingHumanCalls.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EvaOverridingHumanCalls
                 * @function getTypeUrl
                 * @memberof infinitusai.be.EvaOverridingHumanCalls
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EvaOverridingHumanCalls.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.EvaOverridingHumanCalls";
                };
    
                return EvaOverridingHumanCalls;
            })();
    
            be.PayorDown = (function() {
    
                /**
                 * Properties of a PayorDown.
                 * @memberof infinitusai.be
                 * @interface IPayorDown
                 * @property {boolean|null} [isDown] PayorDown isDown
                 * @property {string|null} [payorDownReason] PayorDown payorDownReason
                 * @property {string|null} [payorNextAttemptTime] PayorDown payorNextAttemptTime
                 */
    
                /**
                 * Constructs a new PayorDown.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayorDown.
                 * @implements IPayorDown
                 * @constructor
                 * @param {infinitusai.be.IPayorDown=} [properties] Properties to set
                 */
                function PayorDown(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayorDown isDown.
                 * @member {boolean} isDown
                 * @memberof infinitusai.be.PayorDown
                 * @instance
                 */
                PayorDown.prototype.isDown = false;
    
                /**
                 * PayorDown payorDownReason.
                 * @member {string} payorDownReason
                 * @memberof infinitusai.be.PayorDown
                 * @instance
                 */
                PayorDown.prototype.payorDownReason = "";
    
                /**
                 * PayorDown payorNextAttemptTime.
                 * @member {string} payorNextAttemptTime
                 * @memberof infinitusai.be.PayorDown
                 * @instance
                 */
                PayorDown.prototype.payorNextAttemptTime = "";
    
                /**
                 * Creates a new PayorDown instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {infinitusai.be.IPayorDown=} [properties] Properties to set
                 * @returns {infinitusai.be.PayorDown} PayorDown instance
                 */
                PayorDown.create = function create(properties) {
                    return new PayorDown(properties);
                };
    
                /**
                 * Encodes the specified PayorDown message. Does not implicitly {@link infinitusai.be.PayorDown.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {infinitusai.be.IPayorDown} message PayorDown message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayorDown.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isDown != null && Object.hasOwnProperty.call(message, "isDown"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isDown);
                    if (message.payorDownReason != null && Object.hasOwnProperty.call(message, "payorDownReason"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.payorDownReason);
                    if (message.payorNextAttemptTime != null && Object.hasOwnProperty.call(message, "payorNextAttemptTime"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.payorNextAttemptTime);
                    return writer;
                };
    
                /**
                 * Encodes the specified PayorDown message, length delimited. Does not implicitly {@link infinitusai.be.PayorDown.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {infinitusai.be.IPayorDown} message PayorDown message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayorDown.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayorDown message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayorDown} PayorDown
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayorDown.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayorDown();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isDown = reader.bool();
                                break;
                            }
                        case 2: {
                                message.payorDownReason = reader.string();
                                break;
                            }
                        case 3: {
                                message.payorNextAttemptTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayorDown message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayorDown} PayorDown
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayorDown.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayorDown message.
                 * @function verify
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayorDown.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isDown != null && message.hasOwnProperty("isDown"))
                        if (typeof message.isDown !== "boolean")
                            return "isDown: boolean expected";
                    if (message.payorDownReason != null && message.hasOwnProperty("payorDownReason"))
                        if (!$util.isString(message.payorDownReason))
                            return "payorDownReason: string expected";
                    if (message.payorNextAttemptTime != null && message.hasOwnProperty("payorNextAttemptTime"))
                        if (!$util.isString(message.payorNextAttemptTime))
                            return "payorNextAttemptTime: string expected";
                    return null;
                };
    
                /**
                 * Creates a PayorDown message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayorDown} PayorDown
                 */
                PayorDown.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayorDown)
                        return object;
                    var message = new $root.infinitusai.be.PayorDown();
                    if (object.isDown != null)
                        message.isDown = Boolean(object.isDown);
                    if (object.payorDownReason != null)
                        message.payorDownReason = String(object.payorDownReason);
                    if (object.payorNextAttemptTime != null)
                        message.payorNextAttemptTime = String(object.payorNextAttemptTime);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayorDown message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {infinitusai.be.PayorDown} message PayorDown
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayorDown.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isDown = false;
                        object.payorDownReason = "";
                        object.payorNextAttemptTime = "";
                    }
                    if (message.isDown != null && message.hasOwnProperty("isDown"))
                        object.isDown = message.isDown;
                    if (message.payorDownReason != null && message.hasOwnProperty("payorDownReason"))
                        object.payorDownReason = message.payorDownReason;
                    if (message.payorNextAttemptTime != null && message.hasOwnProperty("payorNextAttemptTime"))
                        object.payorNextAttemptTime = message.payorNextAttemptTime;
                    return object;
                };
    
                /**
                 * Converts this PayorDown to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayorDown
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayorDown.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayorDown
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayorDown
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayorDown.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayorDown";
                };
    
                return PayorDown;
            })();
    
            be.ElevenLabsConfig = (function() {
    
                /**
                 * Properties of an ElevenLabsConfig.
                 * @memberof infinitusai.be
                 * @interface IElevenLabsConfig
                 * @property {boolean|null} [useElevenLabs] ElevenLabsConfig useElevenLabs
                 * @property {boolean|null} [useForAllOrgs] ElevenLabsConfig useForAllOrgs
                 * @property {Array.<string>|null} [orgUuids] ElevenLabsConfig orgUuids
                 */
    
                /**
                 * Constructs a new ElevenLabsConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents an ElevenLabsConfig.
                 * @implements IElevenLabsConfig
                 * @constructor
                 * @param {infinitusai.be.IElevenLabsConfig=} [properties] Properties to set
                 */
                function ElevenLabsConfig(properties) {
                    this.orgUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ElevenLabsConfig useElevenLabs.
                 * @member {boolean} useElevenLabs
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @instance
                 */
                ElevenLabsConfig.prototype.useElevenLabs = false;
    
                /**
                 * ElevenLabsConfig useForAllOrgs.
                 * @member {boolean} useForAllOrgs
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @instance
                 */
                ElevenLabsConfig.prototype.useForAllOrgs = false;
    
                /**
                 * ElevenLabsConfig orgUuids.
                 * @member {Array.<string>} orgUuids
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @instance
                 */
                ElevenLabsConfig.prototype.orgUuids = $util.emptyArray;
    
                /**
                 * Creates a new ElevenLabsConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.ElevenLabsConfig} ElevenLabsConfig instance
                 */
                ElevenLabsConfig.create = function create(properties) {
                    return new ElevenLabsConfig(properties);
                };
    
                /**
                 * Encodes the specified ElevenLabsConfig message. Does not implicitly {@link infinitusai.be.ElevenLabsConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfig} message ElevenLabsConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElevenLabsConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.useElevenLabs != null && Object.hasOwnProperty.call(message, "useElevenLabs"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.useElevenLabs);
                    if (message.useForAllOrgs != null && Object.hasOwnProperty.call(message, "useForAllOrgs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.useForAllOrgs);
                    if (message.orgUuids != null && message.orgUuids.length)
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified ElevenLabsConfig message, length delimited. Does not implicitly {@link infinitusai.be.ElevenLabsConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {infinitusai.be.IElevenLabsConfig} message ElevenLabsConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ElevenLabsConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ElevenLabsConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ElevenLabsConfig} ElevenLabsConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElevenLabsConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ElevenLabsConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.useElevenLabs = reader.bool();
                                break;
                            }
                        case 2: {
                                message.useForAllOrgs = reader.bool();
                                break;
                            }
                        case 3: {
                                if (!(message.orgUuids && message.orgUuids.length))
                                    message.orgUuids = [];
                                message.orgUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ElevenLabsConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ElevenLabsConfig} ElevenLabsConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ElevenLabsConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ElevenLabsConfig message.
                 * @function verify
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ElevenLabsConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.useElevenLabs != null && message.hasOwnProperty("useElevenLabs"))
                        if (typeof message.useElevenLabs !== "boolean")
                            return "useElevenLabs: boolean expected";
                    if (message.useForAllOrgs != null && message.hasOwnProperty("useForAllOrgs"))
                        if (typeof message.useForAllOrgs !== "boolean")
                            return "useForAllOrgs: boolean expected";
                    if (message.orgUuids != null && message.hasOwnProperty("orgUuids")) {
                        if (!Array.isArray(message.orgUuids))
                            return "orgUuids: array expected";
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            if (!$util.isString(message.orgUuids[i]))
                                return "orgUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an ElevenLabsConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ElevenLabsConfig} ElevenLabsConfig
                 */
                ElevenLabsConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ElevenLabsConfig)
                        return object;
                    var message = new $root.infinitusai.be.ElevenLabsConfig();
                    if (object.useElevenLabs != null)
                        message.useElevenLabs = Boolean(object.useElevenLabs);
                    if (object.useForAllOrgs != null)
                        message.useForAllOrgs = Boolean(object.useForAllOrgs);
                    if (object.orgUuids) {
                        if (!Array.isArray(object.orgUuids))
                            throw TypeError(".infinitusai.be.ElevenLabsConfig.orgUuids: array expected");
                        message.orgUuids = [];
                        for (var i = 0; i < object.orgUuids.length; ++i)
                            message.orgUuids[i] = String(object.orgUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ElevenLabsConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {infinitusai.be.ElevenLabsConfig} message ElevenLabsConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ElevenLabsConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgUuids = [];
                    if (options.defaults) {
                        object.useElevenLabs = false;
                        object.useForAllOrgs = false;
                    }
                    if (message.useElevenLabs != null && message.hasOwnProperty("useElevenLabs"))
                        object.useElevenLabs = message.useElevenLabs;
                    if (message.useForAllOrgs != null && message.hasOwnProperty("useForAllOrgs"))
                        object.useForAllOrgs = message.useForAllOrgs;
                    if (message.orgUuids && message.orgUuids.length) {
                        object.orgUuids = [];
                        for (var j = 0; j < message.orgUuids.length; ++j)
                            object.orgUuids[j] = message.orgUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ElevenLabsConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ElevenLabsConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ElevenLabsConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ElevenLabsConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ElevenLabsConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ElevenLabsConfig";
                };
    
                return ElevenLabsConfig;
            })();
    
            be.MultitaskConfig = (function() {
    
                /**
                 * Properties of a MultitaskConfig.
                 * @memberof infinitusai.be
                 * @interface IMultitaskConfig
                 * @property {Object.<string,boolean>|null} [evaOnlyEnforced] MultitaskConfig evaOnlyEnforced
                 */
    
                /**
                 * Constructs a new MultitaskConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a MultitaskConfig.
                 * @implements IMultitaskConfig
                 * @constructor
                 * @param {infinitusai.be.IMultitaskConfig=} [properties] Properties to set
                 */
                function MultitaskConfig(properties) {
                    this.evaOnlyEnforced = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MultitaskConfig evaOnlyEnforced.
                 * @member {Object.<string,boolean>} evaOnlyEnforced
                 * @memberof infinitusai.be.MultitaskConfig
                 * @instance
                 */
                MultitaskConfig.prototype.evaOnlyEnforced = $util.emptyObject;
    
                /**
                 * Creates a new MultitaskConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {infinitusai.be.IMultitaskConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.MultitaskConfig} MultitaskConfig instance
                 */
                MultitaskConfig.create = function create(properties) {
                    return new MultitaskConfig(properties);
                };
    
                /**
                 * Encodes the specified MultitaskConfig message. Does not implicitly {@link infinitusai.be.MultitaskConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {infinitusai.be.IMultitaskConfig} message MultitaskConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultitaskConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.evaOnlyEnforced != null && Object.hasOwnProperty.call(message, "evaOnlyEnforced"))
                        for (var keys = Object.keys(message.evaOnlyEnforced), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.evaOnlyEnforced[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MultitaskConfig message, length delimited. Does not implicitly {@link infinitusai.be.MultitaskConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {infinitusai.be.IMultitaskConfig} message MultitaskConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultitaskConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MultitaskConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.MultitaskConfig} MultitaskConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultitaskConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.MultitaskConfig(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.evaOnlyEnforced === $util.emptyObject)
                                    message.evaOnlyEnforced = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = false;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.bool();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.evaOnlyEnforced[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MultitaskConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.MultitaskConfig} MultitaskConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultitaskConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MultitaskConfig message.
                 * @function verify
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MultitaskConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.evaOnlyEnforced != null && message.hasOwnProperty("evaOnlyEnforced")) {
                        if (!$util.isObject(message.evaOnlyEnforced))
                            return "evaOnlyEnforced: object expected";
                        var key = Object.keys(message.evaOnlyEnforced);
                        for (var i = 0; i < key.length; ++i)
                            if (typeof message.evaOnlyEnforced[key[i]] !== "boolean")
                                return "evaOnlyEnforced: boolean{k:string} expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a MultitaskConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.MultitaskConfig} MultitaskConfig
                 */
                MultitaskConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.MultitaskConfig)
                        return object;
                    var message = new $root.infinitusai.be.MultitaskConfig();
                    if (object.evaOnlyEnforced) {
                        if (typeof object.evaOnlyEnforced !== "object")
                            throw TypeError(".infinitusai.be.MultitaskConfig.evaOnlyEnforced: object expected");
                        message.evaOnlyEnforced = {};
                        for (var keys = Object.keys(object.evaOnlyEnforced), i = 0; i < keys.length; ++i)
                            message.evaOnlyEnforced[keys[i]] = Boolean(object.evaOnlyEnforced[keys[i]]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MultitaskConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {infinitusai.be.MultitaskConfig} message MultitaskConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MultitaskConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.evaOnlyEnforced = {};
                    var keys2;
                    if (message.evaOnlyEnforced && (keys2 = Object.keys(message.evaOnlyEnforced)).length) {
                        object.evaOnlyEnforced = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.evaOnlyEnforced[keys2[j]] = message.evaOnlyEnforced[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this MultitaskConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.MultitaskConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MultitaskConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MultitaskConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.MultitaskConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MultitaskConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.MultitaskConfig";
                };
    
                return MultitaskConfig;
            })();
    
            be.PayerPrefix = (function() {
    
                /**
                 * Properties of a PayerPrefix.
                 * @memberof infinitusai.be
                 * @interface IPayerPrefix
                 * @property {string|null} [prefix] PayerPrefix prefix
                 * @property {Array.<infinitusai.be.PayerPrefix.IMapping>|null} [mappings] PayerPrefix mappings
                 */
    
                /**
                 * Constructs a new PayerPrefix.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerPrefix.
                 * @implements IPayerPrefix
                 * @constructor
                 * @param {infinitusai.be.IPayerPrefix=} [properties] Properties to set
                 */
                function PayerPrefix(properties) {
                    this.mappings = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerPrefix prefix.
                 * @member {string} prefix
                 * @memberof infinitusai.be.PayerPrefix
                 * @instance
                 */
                PayerPrefix.prototype.prefix = "";
    
                /**
                 * PayerPrefix mappings.
                 * @member {Array.<infinitusai.be.PayerPrefix.IMapping>} mappings
                 * @memberof infinitusai.be.PayerPrefix
                 * @instance
                 */
                PayerPrefix.prototype.mappings = $util.emptyArray;
    
                /**
                 * Creates a new PayerPrefix instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {infinitusai.be.IPayerPrefix=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerPrefix} PayerPrefix instance
                 */
                PayerPrefix.create = function create(properties) {
                    return new PayerPrefix(properties);
                };
    
                /**
                 * Encodes the specified PayerPrefix message. Does not implicitly {@link infinitusai.be.PayerPrefix.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {infinitusai.be.IPayerPrefix} message PayerPrefix message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerPrefix.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.prefix != null && Object.hasOwnProperty.call(message, "prefix"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.prefix);
                    if (message.mappings != null && message.mappings.length)
                        for (var i = 0; i < message.mappings.length; ++i)
                            $root.infinitusai.be.PayerPrefix.Mapping.encode(message.mappings[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerPrefix message, length delimited. Does not implicitly {@link infinitusai.be.PayerPrefix.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {infinitusai.be.IPayerPrefix} message PayerPrefix message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerPrefix.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerPrefix message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerPrefix} PayerPrefix
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerPrefix.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerPrefix();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.prefix = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.mappings && message.mappings.length))
                                    message.mappings = [];
                                message.mappings.push($root.infinitusai.be.PayerPrefix.Mapping.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerPrefix message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerPrefix} PayerPrefix
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerPrefix.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerPrefix message.
                 * @function verify
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerPrefix.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.prefix != null && message.hasOwnProperty("prefix"))
                        if (!$util.isString(message.prefix))
                            return "prefix: string expected";
                    if (message.mappings != null && message.hasOwnProperty("mappings")) {
                        if (!Array.isArray(message.mappings))
                            return "mappings: array expected";
                        for (var i = 0; i < message.mappings.length; ++i) {
                            var error = $root.infinitusai.be.PayerPrefix.Mapping.verify(message.mappings[i]);
                            if (error)
                                return "mappings." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a PayerPrefix message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerPrefix} PayerPrefix
                 */
                PayerPrefix.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerPrefix)
                        return object;
                    var message = new $root.infinitusai.be.PayerPrefix();
                    if (object.prefix != null)
                        message.prefix = String(object.prefix);
                    if (object.mappings) {
                        if (!Array.isArray(object.mappings))
                            throw TypeError(".infinitusai.be.PayerPrefix.mappings: array expected");
                        message.mappings = [];
                        for (var i = 0; i < object.mappings.length; ++i) {
                            if (typeof object.mappings[i] !== "object")
                                throw TypeError(".infinitusai.be.PayerPrefix.mappings: object expected");
                            message.mappings[i] = $root.infinitusai.be.PayerPrefix.Mapping.fromObject(object.mappings[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerPrefix message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {infinitusai.be.PayerPrefix} message PayerPrefix
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerPrefix.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.mappings = [];
                    if (options.defaults)
                        object.prefix = "";
                    if (message.prefix != null && message.hasOwnProperty("prefix"))
                        object.prefix = message.prefix;
                    if (message.mappings && message.mappings.length) {
                        object.mappings = [];
                        for (var j = 0; j < message.mappings.length; ++j)
                            object.mappings[j] = $root.infinitusai.be.PayerPrefix.Mapping.toObject(message.mappings[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this PayerPrefix to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerPrefix
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerPrefix.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerPrefix
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerPrefix
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerPrefix.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerPrefix";
                };
    
                PayerPrefix.Mapping = (function() {
    
                    /**
                     * Properties of a Mapping.
                     * @memberof infinitusai.be.PayerPrefix
                     * @interface IMapping
                     * @property {infinitusai.be.Association|null} [association] Mapping association
                     * @property {infinitusai.be.PayerPrefix.Mapping.Source|null} [source] Mapping source
                     * @property {number|Long|null} [createdMillis] Mapping createdMillis
                     * @property {string|null} [payerId] Mapping payerId
                     * @property {string|null} [payerName] Mapping payerName
                     * @property {string|null} [payerWebsite] Mapping payerWebsite
                     */
    
                    /**
                     * Constructs a new Mapping.
                     * @memberof infinitusai.be.PayerPrefix
                     * @classdesc Represents a Mapping.
                     * @implements IMapping
                     * @constructor
                     * @param {infinitusai.be.PayerPrefix.IMapping=} [properties] Properties to set
                     */
                    function Mapping(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Mapping association.
                     * @member {infinitusai.be.Association} association
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @instance
                     */
                    Mapping.prototype.association = 0;
    
                    /**
                     * Mapping source.
                     * @member {infinitusai.be.PayerPrefix.Mapping.Source} source
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @instance
                     */
                    Mapping.prototype.source = 0;
    
                    /**
                     * Mapping createdMillis.
                     * @member {number|Long} createdMillis
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @instance
                     */
                    Mapping.prototype.createdMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Mapping payerId.
                     * @member {string} payerId
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @instance
                     */
                    Mapping.prototype.payerId = "";
    
                    /**
                     * Mapping payerName.
                     * @member {string} payerName
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @instance
                     */
                    Mapping.prototype.payerName = "";
    
                    /**
                     * Mapping payerWebsite.
                     * @member {string} payerWebsite
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @instance
                     */
                    Mapping.prototype.payerWebsite = "";
    
                    /**
                     * Creates a new Mapping instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {infinitusai.be.PayerPrefix.IMapping=} [properties] Properties to set
                     * @returns {infinitusai.be.PayerPrefix.Mapping} Mapping instance
                     */
                    Mapping.create = function create(properties) {
                        return new Mapping(properties);
                    };
    
                    /**
                     * Encodes the specified Mapping message. Does not implicitly {@link infinitusai.be.PayerPrefix.Mapping.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {infinitusai.be.PayerPrefix.IMapping} message Mapping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Mapping.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.association != null && Object.hasOwnProperty.call(message, "association"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.association);
                        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.source);
                        if (message.createdMillis != null && Object.hasOwnProperty.call(message, "createdMillis"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.createdMillis);
                        if (message.payerId != null && Object.hasOwnProperty.call(message, "payerId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.payerId);
                        if (message.payerName != null && Object.hasOwnProperty.call(message, "payerName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.payerName);
                        if (message.payerWebsite != null && Object.hasOwnProperty.call(message, "payerWebsite"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.payerWebsite);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Mapping message, length delimited. Does not implicitly {@link infinitusai.be.PayerPrefix.Mapping.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {infinitusai.be.PayerPrefix.IMapping} message Mapping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Mapping.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Mapping message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.be.PayerPrefix.Mapping} Mapping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Mapping.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerPrefix.Mapping();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.association = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.source = reader.int32();
                                    break;
                                }
                            case 3: {
                                    message.createdMillis = reader.int64();
                                    break;
                                }
                            case 4: {
                                    message.payerId = reader.string();
                                    break;
                                }
                            case 5: {
                                    message.payerName = reader.string();
                                    break;
                                }
                            case 6: {
                                    message.payerWebsite = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Mapping message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.be.PayerPrefix.Mapping} Mapping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Mapping.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Mapping message.
                     * @function verify
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Mapping.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.association != null && message.hasOwnProperty("association"))
                            switch (message.association) {
                            default:
                                return "association: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.source != null && message.hasOwnProperty("source"))
                            switch (message.source) {
                            default:
                                return "source: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.createdMillis != null && message.hasOwnProperty("createdMillis"))
                            if (!$util.isInteger(message.createdMillis) && !(message.createdMillis && $util.isInteger(message.createdMillis.low) && $util.isInteger(message.createdMillis.high)))
                                return "createdMillis: integer|Long expected";
                        if (message.payerId != null && message.hasOwnProperty("payerId"))
                            if (!$util.isString(message.payerId))
                                return "payerId: string expected";
                        if (message.payerName != null && message.hasOwnProperty("payerName"))
                            if (!$util.isString(message.payerName))
                                return "payerName: string expected";
                        if (message.payerWebsite != null && message.hasOwnProperty("payerWebsite"))
                            if (!$util.isString(message.payerWebsite))
                                return "payerWebsite: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a Mapping message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.be.PayerPrefix.Mapping} Mapping
                     */
                    Mapping.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.be.PayerPrefix.Mapping)
                            return object;
                        var message = new $root.infinitusai.be.PayerPrefix.Mapping();
                        switch (object.association) {
                        default:
                            if (typeof object.association === "number") {
                                message.association = object.association;
                                break;
                            }
                            break;
                        case "ASSOCIATION_UNKNOWN":
                        case 0:
                            message.association = 0;
                            break;
                        case "ASSOCIATION_BCBS":
                        case 1:
                            message.association = 1;
                            break;
                        case "ASSOCIATION_BCBS_FEDERAL":
                        case 2:
                            message.association = 2;
                            break;
                        }
                        switch (object.source) {
                        default:
                            if (typeof object.source === "number") {
                                message.source = object.source;
                                break;
                            }
                            break;
                        case "SOURCE_UNKNOWN":
                        case 0:
                            message.source = 0;
                            break;
                        case "SOURCE_BCBS_MEMBER_SERVICES":
                        case 1:
                            message.source = 1;
                            break;
                        }
                        if (object.createdMillis != null)
                            if ($util.Long)
                                (message.createdMillis = $util.Long.fromValue(object.createdMillis)).unsigned = false;
                            else if (typeof object.createdMillis === "string")
                                message.createdMillis = parseInt(object.createdMillis, 10);
                            else if (typeof object.createdMillis === "number")
                                message.createdMillis = object.createdMillis;
                            else if (typeof object.createdMillis === "object")
                                message.createdMillis = new $util.LongBits(object.createdMillis.low >>> 0, object.createdMillis.high >>> 0).toNumber();
                        if (object.payerId != null)
                            message.payerId = String(object.payerId);
                        if (object.payerName != null)
                            message.payerName = String(object.payerName);
                        if (object.payerWebsite != null)
                            message.payerWebsite = String(object.payerWebsite);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Mapping message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {infinitusai.be.PayerPrefix.Mapping} message Mapping
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Mapping.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.association = options.enums === String ? "ASSOCIATION_UNKNOWN" : 0;
                            object.source = options.enums === String ? "SOURCE_UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createdMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createdMillis = options.longs === String ? "0" : 0;
                            object.payerId = "";
                            object.payerName = "";
                            object.payerWebsite = "";
                        }
                        if (message.association != null && message.hasOwnProperty("association"))
                            object.association = options.enums === String ? $root.infinitusai.be.Association[message.association] === undefined ? message.association : $root.infinitusai.be.Association[message.association] : message.association;
                        if (message.source != null && message.hasOwnProperty("source"))
                            object.source = options.enums === String ? $root.infinitusai.be.PayerPrefix.Mapping.Source[message.source] === undefined ? message.source : $root.infinitusai.be.PayerPrefix.Mapping.Source[message.source] : message.source;
                        if (message.createdMillis != null && message.hasOwnProperty("createdMillis"))
                            if (typeof message.createdMillis === "number")
                                object.createdMillis = options.longs === String ? String(message.createdMillis) : message.createdMillis;
                            else
                                object.createdMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdMillis) : options.longs === Number ? new $util.LongBits(message.createdMillis.low >>> 0, message.createdMillis.high >>> 0).toNumber() : message.createdMillis;
                        if (message.payerId != null && message.hasOwnProperty("payerId"))
                            object.payerId = message.payerId;
                        if (message.payerName != null && message.hasOwnProperty("payerName"))
                            object.payerName = message.payerName;
                        if (message.payerWebsite != null && message.hasOwnProperty("payerWebsite"))
                            object.payerWebsite = message.payerWebsite;
                        return object;
                    };
    
                    /**
                     * Converts this Mapping to JSON.
                     * @function toJSON
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Mapping.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Mapping
                     * @function getTypeUrl
                     * @memberof infinitusai.be.PayerPrefix.Mapping
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Mapping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.be.PayerPrefix.Mapping";
                    };
    
                    /**
                     * Source enum.
                     * @name infinitusai.be.PayerPrefix.Mapping.Source
                     * @enum {number}
                     * @property {number} SOURCE_UNKNOWN=0 SOURCE_UNKNOWN value
                     * @property {number} SOURCE_BCBS_MEMBER_SERVICES=1 SOURCE_BCBS_MEMBER_SERVICES value
                     */
                    Mapping.Source = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "SOURCE_UNKNOWN"] = 0;
                        values[valuesById[1] = "SOURCE_BCBS_MEMBER_SERVICES"] = 1;
                        return values;
                    })();
    
                    return Mapping;
                })();
    
                return PayerPrefix;
            })();
    
            be.GetPayersRequest = (function() {
    
                /**
                 * Properties of a GetPayersRequest.
                 * @memberof infinitusai.be
                 * @interface IGetPayersRequest
                 */
    
                /**
                 * Constructs a new GetPayersRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPayersRequest.
                 * @implements IGetPayersRequest
                 * @constructor
                 * @param {infinitusai.be.IGetPayersRequest=} [properties] Properties to set
                 */
                function GetPayersRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetPayersRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {infinitusai.be.IGetPayersRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPayersRequest} GetPayersRequest instance
                 */
                GetPayersRequest.create = function create(properties) {
                    return new GetPayersRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPayersRequest message. Does not implicitly {@link infinitusai.be.GetPayersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {infinitusai.be.IGetPayersRequest} message GetPayersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayersRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayersRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetPayersRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {infinitusai.be.IGetPayersRequest} message GetPayersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPayersRequest} GetPayersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayersRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPayersRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayersRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPayersRequest} GetPayersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayersRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayersRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetPayersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPayersRequest} GetPayersRequest
                 */
                GetPayersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPayersRequest)
                        return object;
                    return new $root.infinitusai.be.GetPayersRequest();
                };
    
                /**
                 * Creates a plain object from a GetPayersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {infinitusai.be.GetPayersRequest} message GetPayersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayersRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetPayersRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPayersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayersRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPayersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPayersRequest";
                };
    
                return GetPayersRequest;
            })();
    
            be.GetPayersResponse = (function() {
    
                /**
                 * Properties of a GetPayersResponse.
                 * @memberof infinitusai.be
                 * @interface IGetPayersResponse
                 * @property {Array.<infinitusai.be.IPayerDoc>|null} [payers] GetPayersResponse payers
                 */
    
                /**
                 * Constructs a new GetPayersResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPayersResponse.
                 * @implements IGetPayersResponse
                 * @constructor
                 * @param {infinitusai.be.IGetPayersResponse=} [properties] Properties to set
                 */
                function GetPayersResponse(properties) {
                    this.payers = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPayersResponse payers.
                 * @member {Array.<infinitusai.be.IPayerDoc>} payers
                 * @memberof infinitusai.be.GetPayersResponse
                 * @instance
                 */
                GetPayersResponse.prototype.payers = $util.emptyArray;
    
                /**
                 * Creates a new GetPayersResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {infinitusai.be.IGetPayersResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPayersResponse} GetPayersResponse instance
                 */
                GetPayersResponse.create = function create(properties) {
                    return new GetPayersResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPayersResponse message. Does not implicitly {@link infinitusai.be.GetPayersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {infinitusai.be.IGetPayersResponse} message GetPayersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payers != null && message.payers.length)
                        for (var i = 0; i < message.payers.length; ++i)
                            $root.infinitusai.be.PayerDoc.encode(message.payers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayersResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetPayersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {infinitusai.be.IGetPayersResponse} message GetPayersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPayersResponse} GetPayersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPayersResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.payers && message.payers.length))
                                    message.payers = [];
                                message.payers.push($root.infinitusai.be.PayerDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPayersResponse} GetPayersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayersResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payers != null && message.hasOwnProperty("payers")) {
                        if (!Array.isArray(message.payers))
                            return "payers: array expected";
                        for (var i = 0; i < message.payers.length; ++i) {
                            var error = $root.infinitusai.be.PayerDoc.verify(message.payers[i]);
                            if (error)
                                return "payers." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPayersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPayersResponse} GetPayersResponse
                 */
                GetPayersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPayersResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetPayersResponse();
                    if (object.payers) {
                        if (!Array.isArray(object.payers))
                            throw TypeError(".infinitusai.be.GetPayersResponse.payers: array expected");
                        message.payers = [];
                        for (var i = 0; i < object.payers.length; ++i) {
                            if (typeof object.payers[i] !== "object")
                                throw TypeError(".infinitusai.be.GetPayersResponse.payers: object expected");
                            message.payers[i] = $root.infinitusai.be.PayerDoc.fromObject(object.payers[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPayersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {infinitusai.be.GetPayersResponse} message GetPayersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.payers = [];
                    if (message.payers && message.payers.length) {
                        object.payers = [];
                        for (var j = 0; j < message.payers.length; ++j)
                            object.payers[j] = $root.infinitusai.be.PayerDoc.toObject(message.payers[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPayersResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPayersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayersResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPayersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPayersResponse";
                };
    
                return GetPayersResponse;
            })();
    
            be.PayerOrgEnablement = (function() {
    
                /**
                 * Properties of a PayerOrgEnablement.
                 * @memberof infinitusai.be
                 * @interface IPayerOrgEnablement
                 * @property {boolean|null} [hasRecord] PayerOrgEnablement hasRecord
                 * @property {boolean|null} [enabled] PayerOrgEnablement enabled
                 * @property {number|Long|null} [timestampMillis] PayerOrgEnablement timestampMillis
                 * @property {string|null} [payerId] PayerOrgEnablement payerId
                 * @property {string|null} [orgId] PayerOrgEnablement orgId
                 */
    
                /**
                 * Constructs a new PayerOrgEnablement.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerOrgEnablement.
                 * @implements IPayerOrgEnablement
                 * @constructor
                 * @param {infinitusai.be.IPayerOrgEnablement=} [properties] Properties to set
                 */
                function PayerOrgEnablement(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerOrgEnablement hasRecord.
                 * @member {boolean} hasRecord
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @instance
                 */
                PayerOrgEnablement.prototype.hasRecord = false;
    
                /**
                 * PayerOrgEnablement enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @instance
                 */
                PayerOrgEnablement.prototype.enabled = false;
    
                /**
                 * PayerOrgEnablement timestampMillis.
                 * @member {number|Long} timestampMillis
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @instance
                 */
                PayerOrgEnablement.prototype.timestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * PayerOrgEnablement payerId.
                 * @member {string} payerId
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @instance
                 */
                PayerOrgEnablement.prototype.payerId = "";
    
                /**
                 * PayerOrgEnablement orgId.
                 * @member {string} orgId
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @instance
                 */
                PayerOrgEnablement.prototype.orgId = "";
    
                /**
                 * Creates a new PayerOrgEnablement instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {infinitusai.be.IPayerOrgEnablement=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerOrgEnablement} PayerOrgEnablement instance
                 */
                PayerOrgEnablement.create = function create(properties) {
                    return new PayerOrgEnablement(properties);
                };
    
                /**
                 * Encodes the specified PayerOrgEnablement message. Does not implicitly {@link infinitusai.be.PayerOrgEnablement.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {infinitusai.be.IPayerOrgEnablement} message PayerOrgEnablement message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerOrgEnablement.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hasRecord != null && Object.hasOwnProperty.call(message, "hasRecord"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasRecord);
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.enabled);
                    if (message.timestampMillis != null && Object.hasOwnProperty.call(message, "timestampMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.timestampMillis);
                    if (message.payerId != null && Object.hasOwnProperty.call(message, "payerId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.payerId);
                    if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.orgId);
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerOrgEnablement message, length delimited. Does not implicitly {@link infinitusai.be.PayerOrgEnablement.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {infinitusai.be.IPayerOrgEnablement} message PayerOrgEnablement message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerOrgEnablement.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerOrgEnablement message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerOrgEnablement} PayerOrgEnablement
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerOrgEnablement.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerOrgEnablement();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hasRecord = reader.bool();
                                break;
                            }
                        case 2: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 3: {
                                message.timestampMillis = reader.int64();
                                break;
                            }
                        case 4: {
                                message.payerId = reader.string();
                                break;
                            }
                        case 5: {
                                message.orgId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerOrgEnablement message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerOrgEnablement} PayerOrgEnablement
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerOrgEnablement.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerOrgEnablement message.
                 * @function verify
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerOrgEnablement.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hasRecord != null && message.hasOwnProperty("hasRecord"))
                        if (typeof message.hasRecord !== "boolean")
                            return "hasRecord: boolean expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.timestampMillis != null && message.hasOwnProperty("timestampMillis"))
                        if (!$util.isInteger(message.timestampMillis) && !(message.timestampMillis && $util.isInteger(message.timestampMillis.low) && $util.isInteger(message.timestampMillis.high)))
                            return "timestampMillis: integer|Long expected";
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        if (!$util.isString(message.payerId))
                            return "payerId: string expected";
                    if (message.orgId != null && message.hasOwnProperty("orgId"))
                        if (!$util.isString(message.orgId))
                            return "orgId: string expected";
                    return null;
                };
    
                /**
                 * Creates a PayerOrgEnablement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerOrgEnablement} PayerOrgEnablement
                 */
                PayerOrgEnablement.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerOrgEnablement)
                        return object;
                    var message = new $root.infinitusai.be.PayerOrgEnablement();
                    if (object.hasRecord != null)
                        message.hasRecord = Boolean(object.hasRecord);
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.timestampMillis != null)
                        if ($util.Long)
                            (message.timestampMillis = $util.Long.fromValue(object.timestampMillis)).unsigned = false;
                        else if (typeof object.timestampMillis === "string")
                            message.timestampMillis = parseInt(object.timestampMillis, 10);
                        else if (typeof object.timestampMillis === "number")
                            message.timestampMillis = object.timestampMillis;
                        else if (typeof object.timestampMillis === "object")
                            message.timestampMillis = new $util.LongBits(object.timestampMillis.low >>> 0, object.timestampMillis.high >>> 0).toNumber();
                    if (object.payerId != null)
                        message.payerId = String(object.payerId);
                    if (object.orgId != null)
                        message.orgId = String(object.orgId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerOrgEnablement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {infinitusai.be.PayerOrgEnablement} message PayerOrgEnablement
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerOrgEnablement.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hasRecord = false;
                        object.enabled = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestampMillis = options.longs === String ? "0" : 0;
                        object.payerId = "";
                        object.orgId = "";
                    }
                    if (message.hasRecord != null && message.hasOwnProperty("hasRecord"))
                        object.hasRecord = message.hasRecord;
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.timestampMillis != null && message.hasOwnProperty("timestampMillis"))
                        if (typeof message.timestampMillis === "number")
                            object.timestampMillis = options.longs === String ? String(message.timestampMillis) : message.timestampMillis;
                        else
                            object.timestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.timestampMillis) : options.longs === Number ? new $util.LongBits(message.timestampMillis.low >>> 0, message.timestampMillis.high >>> 0).toNumber() : message.timestampMillis;
                    if (message.payerId != null && message.hasOwnProperty("payerId"))
                        object.payerId = message.payerId;
                    if (message.orgId != null && message.hasOwnProperty("orgId"))
                        object.orgId = message.orgId;
                    return object;
                };
    
                /**
                 * Converts this PayerOrgEnablement to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerOrgEnablement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerOrgEnablement
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerOrgEnablement
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerOrgEnablement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerOrgEnablement";
                };
    
                return PayerOrgEnablement;
            })();
    
            be.PayerWithDetails = (function() {
    
                /**
                 * Properties of a PayerWithDetails.
                 * @memberof infinitusai.be
                 * @interface IPayerWithDetails
                 * @property {infinitusai.be.IPayerDoc|null} [payer] PayerWithDetails payer
                 * @property {Array.<infinitusai.be.ICustomerPortalPhoneNumber>|null} [phoneNumbers] PayerWithDetails phoneNumbers
                 * @property {infinitusai.be.IPayerOrgEnablement|null} [payerOrgEnablement] PayerWithDetails payerOrgEnablement
                 */
    
                /**
                 * Constructs a new PayerWithDetails.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PayerWithDetails.
                 * @implements IPayerWithDetails
                 * @constructor
                 * @param {infinitusai.be.IPayerWithDetails=} [properties] Properties to set
                 */
                function PayerWithDetails(properties) {
                    this.phoneNumbers = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PayerWithDetails payer.
                 * @member {infinitusai.be.IPayerDoc|null|undefined} payer
                 * @memberof infinitusai.be.PayerWithDetails
                 * @instance
                 */
                PayerWithDetails.prototype.payer = null;
    
                /**
                 * PayerWithDetails phoneNumbers.
                 * @member {Array.<infinitusai.be.ICustomerPortalPhoneNumber>} phoneNumbers
                 * @memberof infinitusai.be.PayerWithDetails
                 * @instance
                 */
                PayerWithDetails.prototype.phoneNumbers = $util.emptyArray;
    
                /**
                 * PayerWithDetails payerOrgEnablement.
                 * @member {infinitusai.be.IPayerOrgEnablement|null|undefined} payerOrgEnablement
                 * @memberof infinitusai.be.PayerWithDetails
                 * @instance
                 */
                PayerWithDetails.prototype.payerOrgEnablement = null;
    
                /**
                 * Creates a new PayerWithDetails instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {infinitusai.be.IPayerWithDetails=} [properties] Properties to set
                 * @returns {infinitusai.be.PayerWithDetails} PayerWithDetails instance
                 */
                PayerWithDetails.create = function create(properties) {
                    return new PayerWithDetails(properties);
                };
    
                /**
                 * Encodes the specified PayerWithDetails message. Does not implicitly {@link infinitusai.be.PayerWithDetails.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {infinitusai.be.IPayerWithDetails} message PayerWithDetails message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerWithDetails.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payer != null && Object.hasOwnProperty.call(message, "payer"))
                        $root.infinitusai.be.PayerDoc.encode(message.payer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.phoneNumbers != null && message.phoneNumbers.length)
                        for (var i = 0; i < message.phoneNumbers.length; ++i)
                            $root.infinitusai.be.CustomerPortalPhoneNumber.encode(message.phoneNumbers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.payerOrgEnablement != null && Object.hasOwnProperty.call(message, "payerOrgEnablement"))
                        $root.infinitusai.be.PayerOrgEnablement.encode(message.payerOrgEnablement, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PayerWithDetails message, length delimited. Does not implicitly {@link infinitusai.be.PayerWithDetails.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {infinitusai.be.IPayerWithDetails} message PayerWithDetails message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayerWithDetails.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PayerWithDetails message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PayerWithDetails} PayerWithDetails
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerWithDetails.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PayerWithDetails();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.payer = $root.infinitusai.be.PayerDoc.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.phoneNumbers && message.phoneNumbers.length))
                                    message.phoneNumbers = [];
                                message.phoneNumbers.push($root.infinitusai.be.CustomerPortalPhoneNumber.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.payerOrgEnablement = $root.infinitusai.be.PayerOrgEnablement.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PayerWithDetails message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PayerWithDetails} PayerWithDetails
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayerWithDetails.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PayerWithDetails message.
                 * @function verify
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayerWithDetails.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payer != null && message.hasOwnProperty("payer")) {
                        var error = $root.infinitusai.be.PayerDoc.verify(message.payer);
                        if (error)
                            return "payer." + error;
                    }
                    if (message.phoneNumbers != null && message.hasOwnProperty("phoneNumbers")) {
                        if (!Array.isArray(message.phoneNumbers))
                            return "phoneNumbers: array expected";
                        for (var i = 0; i < message.phoneNumbers.length; ++i) {
                            var error = $root.infinitusai.be.CustomerPortalPhoneNumber.verify(message.phoneNumbers[i]);
                            if (error)
                                return "phoneNumbers." + error;
                        }
                    }
                    if (message.payerOrgEnablement != null && message.hasOwnProperty("payerOrgEnablement")) {
                        var error = $root.infinitusai.be.PayerOrgEnablement.verify(message.payerOrgEnablement);
                        if (error)
                            return "payerOrgEnablement." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a PayerWithDetails message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PayerWithDetails} PayerWithDetails
                 */
                PayerWithDetails.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PayerWithDetails)
                        return object;
                    var message = new $root.infinitusai.be.PayerWithDetails();
                    if (object.payer != null) {
                        if (typeof object.payer !== "object")
                            throw TypeError(".infinitusai.be.PayerWithDetails.payer: object expected");
                        message.payer = $root.infinitusai.be.PayerDoc.fromObject(object.payer);
                    }
                    if (object.phoneNumbers) {
                        if (!Array.isArray(object.phoneNumbers))
                            throw TypeError(".infinitusai.be.PayerWithDetails.phoneNumbers: array expected");
                        message.phoneNumbers = [];
                        for (var i = 0; i < object.phoneNumbers.length; ++i) {
                            if (typeof object.phoneNumbers[i] !== "object")
                                throw TypeError(".infinitusai.be.PayerWithDetails.phoneNumbers: object expected");
                            message.phoneNumbers[i] = $root.infinitusai.be.CustomerPortalPhoneNumber.fromObject(object.phoneNumbers[i]);
                        }
                    }
                    if (object.payerOrgEnablement != null) {
                        if (typeof object.payerOrgEnablement !== "object")
                            throw TypeError(".infinitusai.be.PayerWithDetails.payerOrgEnablement: object expected");
                        message.payerOrgEnablement = $root.infinitusai.be.PayerOrgEnablement.fromObject(object.payerOrgEnablement);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PayerWithDetails message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {infinitusai.be.PayerWithDetails} message PayerWithDetails
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayerWithDetails.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.phoneNumbers = [];
                    if (options.defaults) {
                        object.payer = null;
                        object.payerOrgEnablement = null;
                    }
                    if (message.payer != null && message.hasOwnProperty("payer"))
                        object.payer = $root.infinitusai.be.PayerDoc.toObject(message.payer, options);
                    if (message.phoneNumbers && message.phoneNumbers.length) {
                        object.phoneNumbers = [];
                        for (var j = 0; j < message.phoneNumbers.length; ++j)
                            object.phoneNumbers[j] = $root.infinitusai.be.CustomerPortalPhoneNumber.toObject(message.phoneNumbers[j], options);
                    }
                    if (message.payerOrgEnablement != null && message.hasOwnProperty("payerOrgEnablement"))
                        object.payerOrgEnablement = $root.infinitusai.be.PayerOrgEnablement.toObject(message.payerOrgEnablement, options);
                    return object;
                };
    
                /**
                 * Converts this PayerWithDetails to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PayerWithDetails
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayerWithDetails.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PayerWithDetails
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PayerWithDetails
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PayerWithDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PayerWithDetails";
                };
    
                return PayerWithDetails;
            })();
    
            be.GetPayersWithDetailsRequest = (function() {
    
                /**
                 * Properties of a GetPayersWithDetailsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetPayersWithDetailsRequest
                 */
    
                /**
                 * Constructs a new GetPayersWithDetailsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPayersWithDetailsRequest.
                 * @implements IGetPayersWithDetailsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetPayersWithDetailsRequest=} [properties] Properties to set
                 */
                function GetPayersWithDetailsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetPayersWithDetailsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {infinitusai.be.IGetPayersWithDetailsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPayersWithDetailsRequest} GetPayersWithDetailsRequest instance
                 */
                GetPayersWithDetailsRequest.create = function create(properties) {
                    return new GetPayersWithDetailsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPayersWithDetailsRequest message. Does not implicitly {@link infinitusai.be.GetPayersWithDetailsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {infinitusai.be.IGetPayersWithDetailsRequest} message GetPayersWithDetailsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayersWithDetailsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayersWithDetailsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetPayersWithDetailsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {infinitusai.be.IGetPayersWithDetailsRequest} message GetPayersWithDetailsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayersWithDetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayersWithDetailsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPayersWithDetailsRequest} GetPayersWithDetailsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayersWithDetailsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPayersWithDetailsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayersWithDetailsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPayersWithDetailsRequest} GetPayersWithDetailsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayersWithDetailsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayersWithDetailsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayersWithDetailsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetPayersWithDetailsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPayersWithDetailsRequest} GetPayersWithDetailsRequest
                 */
                GetPayersWithDetailsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPayersWithDetailsRequest)
                        return object;
                    return new $root.infinitusai.be.GetPayersWithDetailsRequest();
                };
    
                /**
                 * Creates a plain object from a GetPayersWithDetailsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {infinitusai.be.GetPayersWithDetailsRequest} message GetPayersWithDetailsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayersWithDetailsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetPayersWithDetailsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayersWithDetailsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayersWithDetailsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPayersWithDetailsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayersWithDetailsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPayersWithDetailsRequest";
                };
    
                return GetPayersWithDetailsRequest;
            })();
    
            be.GetPayersWithDetailsResponse = (function() {
    
                /**
                 * Properties of a GetPayersWithDetailsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetPayersWithDetailsResponse
                 * @property {Array.<infinitusai.be.IPayerWithDetails>|null} [payersWithDetails] GetPayersWithDetailsResponse payersWithDetails
                 */
    
                /**
                 * Constructs a new GetPayersWithDetailsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPayersWithDetailsResponse.
                 * @implements IGetPayersWithDetailsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetPayersWithDetailsResponse=} [properties] Properties to set
                 */
                function GetPayersWithDetailsResponse(properties) {
                    this.payersWithDetails = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPayersWithDetailsResponse payersWithDetails.
                 * @member {Array.<infinitusai.be.IPayerWithDetails>} payersWithDetails
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @instance
                 */
                GetPayersWithDetailsResponse.prototype.payersWithDetails = $util.emptyArray;
    
                /**
                 * Creates a new GetPayersWithDetailsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {infinitusai.be.IGetPayersWithDetailsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPayersWithDetailsResponse} GetPayersWithDetailsResponse instance
                 */
                GetPayersWithDetailsResponse.create = function create(properties) {
                    return new GetPayersWithDetailsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPayersWithDetailsResponse message. Does not implicitly {@link infinitusai.be.GetPayersWithDetailsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {infinitusai.be.IGetPayersWithDetailsResponse} message GetPayersWithDetailsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayersWithDetailsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payersWithDetails != null && message.payersWithDetails.length)
                        for (var i = 0; i < message.payersWithDetails.length; ++i)
                            $root.infinitusai.be.PayerWithDetails.encode(message.payersWithDetails[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayersWithDetailsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetPayersWithDetailsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {infinitusai.be.IGetPayersWithDetailsResponse} message GetPayersWithDetailsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayersWithDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayersWithDetailsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPayersWithDetailsResponse} GetPayersWithDetailsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayersWithDetailsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPayersWithDetailsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.payersWithDetails && message.payersWithDetails.length))
                                    message.payersWithDetails = [];
                                message.payersWithDetails.push($root.infinitusai.be.PayerWithDetails.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayersWithDetailsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPayersWithDetailsResponse} GetPayersWithDetailsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayersWithDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayersWithDetailsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayersWithDetailsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payersWithDetails != null && message.hasOwnProperty("payersWithDetails")) {
                        if (!Array.isArray(message.payersWithDetails))
                            return "payersWithDetails: array expected";
                        for (var i = 0; i < message.payersWithDetails.length; ++i) {
                            var error = $root.infinitusai.be.PayerWithDetails.verify(message.payersWithDetails[i]);
                            if (error)
                                return "payersWithDetails." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPayersWithDetailsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPayersWithDetailsResponse} GetPayersWithDetailsResponse
                 */
                GetPayersWithDetailsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPayersWithDetailsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetPayersWithDetailsResponse();
                    if (object.payersWithDetails) {
                        if (!Array.isArray(object.payersWithDetails))
                            throw TypeError(".infinitusai.be.GetPayersWithDetailsResponse.payersWithDetails: array expected");
                        message.payersWithDetails = [];
                        for (var i = 0; i < object.payersWithDetails.length; ++i) {
                            if (typeof object.payersWithDetails[i] !== "object")
                                throw TypeError(".infinitusai.be.GetPayersWithDetailsResponse.payersWithDetails: object expected");
                            message.payersWithDetails[i] = $root.infinitusai.be.PayerWithDetails.fromObject(object.payersWithDetails[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPayersWithDetailsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {infinitusai.be.GetPayersWithDetailsResponse} message GetPayersWithDetailsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayersWithDetailsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.payersWithDetails = [];
                    if (message.payersWithDetails && message.payersWithDetails.length) {
                        object.payersWithDetails = [];
                        for (var j = 0; j < message.payersWithDetails.length; ++j)
                            object.payersWithDetails[j] = $root.infinitusai.be.PayerWithDetails.toObject(message.payersWithDetails[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPayersWithDetailsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayersWithDetailsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayersWithDetailsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPayersWithDetailsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayersWithDetailsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPayersWithDetailsResponse";
                };
    
                return GetPayersWithDetailsResponse;
            })();
    
            be.GetPayerOrgEnablementsRequest = (function() {
    
                /**
                 * Properties of a GetPayerOrgEnablementsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetPayerOrgEnablementsRequest
                 */
    
                /**
                 * Constructs a new GetPayerOrgEnablementsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPayerOrgEnablementsRequest.
                 * @implements IGetPayerOrgEnablementsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetPayerOrgEnablementsRequest=} [properties] Properties to set
                 */
                function GetPayerOrgEnablementsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetPayerOrgEnablementsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {infinitusai.be.IGetPayerOrgEnablementsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPayerOrgEnablementsRequest} GetPayerOrgEnablementsRequest instance
                 */
                GetPayerOrgEnablementsRequest.create = function create(properties) {
                    return new GetPayerOrgEnablementsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPayerOrgEnablementsRequest message. Does not implicitly {@link infinitusai.be.GetPayerOrgEnablementsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {infinitusai.be.IGetPayerOrgEnablementsRequest} message GetPayerOrgEnablementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerOrgEnablementsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayerOrgEnablementsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetPayerOrgEnablementsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {infinitusai.be.IGetPayerOrgEnablementsRequest} message GetPayerOrgEnablementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerOrgEnablementsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayerOrgEnablementsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPayerOrgEnablementsRequest} GetPayerOrgEnablementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerOrgEnablementsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPayerOrgEnablementsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayerOrgEnablementsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPayerOrgEnablementsRequest} GetPayerOrgEnablementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerOrgEnablementsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayerOrgEnablementsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayerOrgEnablementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetPayerOrgEnablementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPayerOrgEnablementsRequest} GetPayerOrgEnablementsRequest
                 */
                GetPayerOrgEnablementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPayerOrgEnablementsRequest)
                        return object;
                    return new $root.infinitusai.be.GetPayerOrgEnablementsRequest();
                };
    
                /**
                 * Creates a plain object from a GetPayerOrgEnablementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {infinitusai.be.GetPayerOrgEnablementsRequest} message GetPayerOrgEnablementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayerOrgEnablementsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetPayerOrgEnablementsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayerOrgEnablementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayerOrgEnablementsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPayerOrgEnablementsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayerOrgEnablementsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPayerOrgEnablementsRequest";
                };
    
                return GetPayerOrgEnablementsRequest;
            })();
    
            be.GetPayerOrgEnablementsResponse = (function() {
    
                /**
                 * Properties of a GetPayerOrgEnablementsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetPayerOrgEnablementsResponse
                 * @property {Array.<infinitusai.be.IPayerOrgEnablement>|null} [payerOrgEnablements] GetPayerOrgEnablementsResponse payerOrgEnablements
                 */
    
                /**
                 * Constructs a new GetPayerOrgEnablementsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetPayerOrgEnablementsResponse.
                 * @implements IGetPayerOrgEnablementsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetPayerOrgEnablementsResponse=} [properties] Properties to set
                 */
                function GetPayerOrgEnablementsResponse(properties) {
                    this.payerOrgEnablements = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPayerOrgEnablementsResponse payerOrgEnablements.
                 * @member {Array.<infinitusai.be.IPayerOrgEnablement>} payerOrgEnablements
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @instance
                 */
                GetPayerOrgEnablementsResponse.prototype.payerOrgEnablements = $util.emptyArray;
    
                /**
                 * Creates a new GetPayerOrgEnablementsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {infinitusai.be.IGetPayerOrgEnablementsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetPayerOrgEnablementsResponse} GetPayerOrgEnablementsResponse instance
                 */
                GetPayerOrgEnablementsResponse.create = function create(properties) {
                    return new GetPayerOrgEnablementsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPayerOrgEnablementsResponse message. Does not implicitly {@link infinitusai.be.GetPayerOrgEnablementsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {infinitusai.be.IGetPayerOrgEnablementsResponse} message GetPayerOrgEnablementsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerOrgEnablementsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payerOrgEnablements != null && message.payerOrgEnablements.length)
                        for (var i = 0; i < message.payerOrgEnablements.length; ++i)
                            $root.infinitusai.be.PayerOrgEnablement.encode(message.payerOrgEnablements[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayerOrgEnablementsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetPayerOrgEnablementsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {infinitusai.be.IGetPayerOrgEnablementsResponse} message GetPayerOrgEnablementsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerOrgEnablementsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayerOrgEnablementsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetPayerOrgEnablementsResponse} GetPayerOrgEnablementsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerOrgEnablementsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetPayerOrgEnablementsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.payerOrgEnablements && message.payerOrgEnablements.length))
                                    message.payerOrgEnablements = [];
                                message.payerOrgEnablements.push($root.infinitusai.be.PayerOrgEnablement.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayerOrgEnablementsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetPayerOrgEnablementsResponse} GetPayerOrgEnablementsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerOrgEnablementsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayerOrgEnablementsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayerOrgEnablementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payerOrgEnablements != null && message.hasOwnProperty("payerOrgEnablements")) {
                        if (!Array.isArray(message.payerOrgEnablements))
                            return "payerOrgEnablements: array expected";
                        for (var i = 0; i < message.payerOrgEnablements.length; ++i) {
                            var error = $root.infinitusai.be.PayerOrgEnablement.verify(message.payerOrgEnablements[i]);
                            if (error)
                                return "payerOrgEnablements." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPayerOrgEnablementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetPayerOrgEnablementsResponse} GetPayerOrgEnablementsResponse
                 */
                GetPayerOrgEnablementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetPayerOrgEnablementsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetPayerOrgEnablementsResponse();
                    if (object.payerOrgEnablements) {
                        if (!Array.isArray(object.payerOrgEnablements))
                            throw TypeError(".infinitusai.be.GetPayerOrgEnablementsResponse.payerOrgEnablements: array expected");
                        message.payerOrgEnablements = [];
                        for (var i = 0; i < object.payerOrgEnablements.length; ++i) {
                            if (typeof object.payerOrgEnablements[i] !== "object")
                                throw TypeError(".infinitusai.be.GetPayerOrgEnablementsResponse.payerOrgEnablements: object expected");
                            message.payerOrgEnablements[i] = $root.infinitusai.be.PayerOrgEnablement.fromObject(object.payerOrgEnablements[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPayerOrgEnablementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {infinitusai.be.GetPayerOrgEnablementsResponse} message GetPayerOrgEnablementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayerOrgEnablementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.payerOrgEnablements = [];
                    if (message.payerOrgEnablements && message.payerOrgEnablements.length) {
                        object.payerOrgEnablements = [];
                        for (var j = 0; j < message.payerOrgEnablements.length; ++j)
                            object.payerOrgEnablements[j] = $root.infinitusai.be.PayerOrgEnablement.toObject(message.payerOrgEnablements[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPayerOrgEnablementsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayerOrgEnablementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayerOrgEnablementsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetPayerOrgEnablementsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayerOrgEnablementsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetPayerOrgEnablementsResponse";
                };
    
                return GetPayerOrgEnablementsResponse;
            })();
    
            be.HoldTime = (function() {
    
                /**
                 * Properties of a HoldTime.
                 * @memberof infinitusai.be
                 * @interface IHoldTime
                 * @property {number|Long|null} [measureTimeMillis] HoldTime measureTimeMillis
                 * @property {number|Long|null} [timeToPersonMillis] HoldTime timeToPersonMillis
                 */
    
                /**
                 * Constructs a new HoldTime.
                 * @memberof infinitusai.be
                 * @classdesc Represents a HoldTime.
                 * @implements IHoldTime
                 * @constructor
                 * @param {infinitusai.be.IHoldTime=} [properties] Properties to set
                 */
                function HoldTime(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * HoldTime measureTimeMillis.
                 * @member {number|Long} measureTimeMillis
                 * @memberof infinitusai.be.HoldTime
                 * @instance
                 */
                HoldTime.prototype.measureTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * HoldTime timeToPersonMillis.
                 * @member {number|Long} timeToPersonMillis
                 * @memberof infinitusai.be.HoldTime
                 * @instance
                 */
                HoldTime.prototype.timeToPersonMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new HoldTime instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {infinitusai.be.IHoldTime=} [properties] Properties to set
                 * @returns {infinitusai.be.HoldTime} HoldTime instance
                 */
                HoldTime.create = function create(properties) {
                    return new HoldTime(properties);
                };
    
                /**
                 * Encodes the specified HoldTime message. Does not implicitly {@link infinitusai.be.HoldTime.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {infinitusai.be.IHoldTime} message HoldTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HoldTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.measureTimeMillis != null && Object.hasOwnProperty.call(message, "measureTimeMillis"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.measureTimeMillis);
                    if (message.timeToPersonMillis != null && Object.hasOwnProperty.call(message, "timeToPersonMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timeToPersonMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified HoldTime message, length delimited. Does not implicitly {@link infinitusai.be.HoldTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {infinitusai.be.IHoldTime} message HoldTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HoldTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a HoldTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.HoldTime} HoldTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HoldTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.HoldTime();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.measureTimeMillis = reader.int64();
                                break;
                            }
                        case 2: {
                                message.timeToPersonMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a HoldTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.HoldTime} HoldTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HoldTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a HoldTime message.
                 * @function verify
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HoldTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.measureTimeMillis != null && message.hasOwnProperty("measureTimeMillis"))
                        if (!$util.isInteger(message.measureTimeMillis) && !(message.measureTimeMillis && $util.isInteger(message.measureTimeMillis.low) && $util.isInteger(message.measureTimeMillis.high)))
                            return "measureTimeMillis: integer|Long expected";
                    if (message.timeToPersonMillis != null && message.hasOwnProperty("timeToPersonMillis"))
                        if (!$util.isInteger(message.timeToPersonMillis) && !(message.timeToPersonMillis && $util.isInteger(message.timeToPersonMillis.low) && $util.isInteger(message.timeToPersonMillis.high)))
                            return "timeToPersonMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a HoldTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.HoldTime} HoldTime
                 */
                HoldTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.HoldTime)
                        return object;
                    var message = new $root.infinitusai.be.HoldTime();
                    if (object.measureTimeMillis != null)
                        if ($util.Long)
                            (message.measureTimeMillis = $util.Long.fromValue(object.measureTimeMillis)).unsigned = false;
                        else if (typeof object.measureTimeMillis === "string")
                            message.measureTimeMillis = parseInt(object.measureTimeMillis, 10);
                        else if (typeof object.measureTimeMillis === "number")
                            message.measureTimeMillis = object.measureTimeMillis;
                        else if (typeof object.measureTimeMillis === "object")
                            message.measureTimeMillis = new $util.LongBits(object.measureTimeMillis.low >>> 0, object.measureTimeMillis.high >>> 0).toNumber();
                    if (object.timeToPersonMillis != null)
                        if ($util.Long)
                            (message.timeToPersonMillis = $util.Long.fromValue(object.timeToPersonMillis)).unsigned = false;
                        else if (typeof object.timeToPersonMillis === "string")
                            message.timeToPersonMillis = parseInt(object.timeToPersonMillis, 10);
                        else if (typeof object.timeToPersonMillis === "number")
                            message.timeToPersonMillis = object.timeToPersonMillis;
                        else if (typeof object.timeToPersonMillis === "object")
                            message.timeToPersonMillis = new $util.LongBits(object.timeToPersonMillis.low >>> 0, object.timeToPersonMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a HoldTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {infinitusai.be.HoldTime} message HoldTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HoldTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.measureTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.measureTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timeToPersonMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timeToPersonMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.measureTimeMillis != null && message.hasOwnProperty("measureTimeMillis"))
                        if (typeof message.measureTimeMillis === "number")
                            object.measureTimeMillis = options.longs === String ? String(message.measureTimeMillis) : message.measureTimeMillis;
                        else
                            object.measureTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.measureTimeMillis) : options.longs === Number ? new $util.LongBits(message.measureTimeMillis.low >>> 0, message.measureTimeMillis.high >>> 0).toNumber() : message.measureTimeMillis;
                    if (message.timeToPersonMillis != null && message.hasOwnProperty("timeToPersonMillis"))
                        if (typeof message.timeToPersonMillis === "number")
                            object.timeToPersonMillis = options.longs === String ? String(message.timeToPersonMillis) : message.timeToPersonMillis;
                        else
                            object.timeToPersonMillis = options.longs === String ? $util.Long.prototype.toString.call(message.timeToPersonMillis) : options.longs === Number ? new $util.LongBits(message.timeToPersonMillis.low >>> 0, message.timeToPersonMillis.high >>> 0).toNumber() : message.timeToPersonMillis;
                    return object;
                };
    
                /**
                 * Converts this HoldTime to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.HoldTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HoldTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for HoldTime
                 * @function getTypeUrl
                 * @memberof infinitusai.be.HoldTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                HoldTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.HoldTime";
                };
    
                return HoldTime;
            })();
    
            /**
             * PhoneNumberType enum.
             * @name infinitusai.be.PhoneNumberType
             * @enum {number}
             * @property {number} TYPE_UNDEFINED=0 TYPE_UNDEFINED value
             * @property {number} TYPE_COMMERCIAL=1 TYPE_COMMERCIAL value
             * @property {number} TYPE_FEDERAL=2 TYPE_FEDERAL value
             * @property {number} TYPE_MEDICARE_ADVANTAGE=3 TYPE_MEDICARE_ADVANTAGE value
             */
            be.PhoneNumberType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TYPE_UNDEFINED"] = 0;
                values[valuesById[1] = "TYPE_COMMERCIAL"] = 1;
                values[valuesById[2] = "TYPE_FEDERAL"] = 2;
                values[valuesById[3] = "TYPE_MEDICARE_ADVANTAGE"] = 3;
                return values;
            })();
    
            be.PhoneNumber = (function() {
    
                /**
                 * Properties of a PhoneNumber.
                 * @memberof infinitusai.be
                 * @interface IPhoneNumber
                 * @property {string|null} [number] PhoneNumber number
                 * @property {string|null} [name] PhoneNumber name
                 * @property {number|null} [priority] PhoneNumber priority
                 * @property {infinitusai.be.PhoneNumberType|null} [type] PhoneNumber type
                 * @property {boolean|null} [enableAutoIvr] PhoneNumber enableAutoIvr
                 * @property {boolean|null} [enableCallQueueing] PhoneNumber enableCallQueueing
                 * @property {google.type.ITimeOfDay|null} [openPst] PhoneNumber openPst
                 * @property {google.type.ITimeOfDay|null} [closePst] PhoneNumber closePst
                 * @property {Array.<string>|null} [payerIds] PhoneNumber payerIds
                 * @property {Array.<infinitusai.be.IHoldTime>|null} [holdTimes] PhoneNumber holdTimes
                 * @property {Array.<string>|null} [restrictedToOrgUuids] PhoneNumber restrictedToOrgUuids
                 * @property {Array.<string>|null} [memberIdRegex] PhoneNumber memberIdRegex
                 * @property {number|null} [numAttemptsToReachAgent] PhoneNumber numAttemptsToReachAgent
                 * @property {Array.<infinitusai.be.TaskType>|null} [allowedTaskTypes] PhoneNumber allowedTaskTypes
                 * @property {boolean|null} [isPhoneNumberAutoGenerated] PhoneNumber isPhoneNumberAutoGenerated
                 */
    
                /**
                 * Constructs a new PhoneNumber.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PhoneNumber.
                 * @implements IPhoneNumber
                 * @constructor
                 * @param {infinitusai.be.IPhoneNumber=} [properties] Properties to set
                 */
                function PhoneNumber(properties) {
                    this.payerIds = [];
                    this.holdTimes = [];
                    this.restrictedToOrgUuids = [];
                    this.memberIdRegex = [];
                    this.allowedTaskTypes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PhoneNumber number.
                 * @member {string} number
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.number = "";
    
                /**
                 * PhoneNumber name.
                 * @member {string} name
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.name = "";
    
                /**
                 * PhoneNumber priority.
                 * @member {number} priority
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.priority = 0;
    
                /**
                 * PhoneNumber type.
                 * @member {infinitusai.be.PhoneNumberType} type
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.type = 0;
    
                /**
                 * PhoneNumber enableAutoIvr.
                 * @member {boolean} enableAutoIvr
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.enableAutoIvr = false;
    
                /**
                 * PhoneNumber enableCallQueueing.
                 * @member {boolean} enableCallQueueing
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.enableCallQueueing = false;
    
                /**
                 * PhoneNumber openPst.
                 * @member {google.type.ITimeOfDay|null|undefined} openPst
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.openPst = null;
    
                /**
                 * PhoneNumber closePst.
                 * @member {google.type.ITimeOfDay|null|undefined} closePst
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.closePst = null;
    
                /**
                 * PhoneNumber payerIds.
                 * @member {Array.<string>} payerIds
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.payerIds = $util.emptyArray;
    
                /**
                 * PhoneNumber holdTimes.
                 * @member {Array.<infinitusai.be.IHoldTime>} holdTimes
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.holdTimes = $util.emptyArray;
    
                /**
                 * PhoneNumber restrictedToOrgUuids.
                 * @member {Array.<string>} restrictedToOrgUuids
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.restrictedToOrgUuids = $util.emptyArray;
    
                /**
                 * PhoneNumber memberIdRegex.
                 * @member {Array.<string>} memberIdRegex
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.memberIdRegex = $util.emptyArray;
    
                /**
                 * PhoneNumber numAttemptsToReachAgent.
                 * @member {number} numAttemptsToReachAgent
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.numAttemptsToReachAgent = 0;
    
                /**
                 * PhoneNumber allowedTaskTypes.
                 * @member {Array.<infinitusai.be.TaskType>} allowedTaskTypes
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.allowedTaskTypes = $util.emptyArray;
    
                /**
                 * PhoneNumber isPhoneNumberAutoGenerated.
                 * @member {boolean} isPhoneNumberAutoGenerated
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 */
                PhoneNumber.prototype.isPhoneNumberAutoGenerated = false;
    
                /**
                 * Creates a new PhoneNumber instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {infinitusai.be.IPhoneNumber=} [properties] Properties to set
                 * @returns {infinitusai.be.PhoneNumber} PhoneNumber instance
                 */
                PhoneNumber.create = function create(properties) {
                    return new PhoneNumber(properties);
                };
    
                /**
                 * Encodes the specified PhoneNumber message. Does not implicitly {@link infinitusai.be.PhoneNumber.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {infinitusai.be.IPhoneNumber} message PhoneNumber message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PhoneNumber.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.number);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                    if (message.enableAutoIvr != null && Object.hasOwnProperty.call(message, "enableAutoIvr"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.enableAutoIvr);
                    if (message.enableCallQueueing != null && Object.hasOwnProperty.call(message, "enableCallQueueing"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.enableCallQueueing);
                    if (message.holdTimes != null && message.holdTimes.length)
                        for (var i = 0; i < message.holdTimes.length; ++i)
                            $root.infinitusai.be.HoldTime.encode(message.holdTimes[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
                    if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.priority);
                    if (message.openPst != null && Object.hasOwnProperty.call(message, "openPst"))
                        $root.google.type.TimeOfDay.encode(message.openPst, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.closePst != null && Object.hasOwnProperty.call(message, "closePst"))
                        $root.google.type.TimeOfDay.encode(message.closePst, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.payerIds != null && message.payerIds.length)
                        for (var i = 0; i < message.payerIds.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.payerIds[i]);
                    if (message.restrictedToOrgUuids != null && message.restrictedToOrgUuids.length)
                        for (var i = 0; i < message.restrictedToOrgUuids.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.restrictedToOrgUuids[i]);
                    if (message.memberIdRegex != null && message.memberIdRegex.length)
                        for (var i = 0; i < message.memberIdRegex.length; ++i)
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.memberIdRegex[i]);
                    if (message.numAttemptsToReachAgent != null && Object.hasOwnProperty.call(message, "numAttemptsToReachAgent"))
                        writer.uint32(/* id 13, wireType 0 =*/104).int32(message.numAttemptsToReachAgent);
                    if (message.allowedTaskTypes != null && message.allowedTaskTypes.length) {
                        writer.uint32(/* id 14, wireType 2 =*/114).fork();
                        for (var i = 0; i < message.allowedTaskTypes.length; ++i)
                            writer.int32(message.allowedTaskTypes[i]);
                        writer.ldelim();
                    }
                    if (message.isPhoneNumberAutoGenerated != null && Object.hasOwnProperty.call(message, "isPhoneNumberAutoGenerated"))
                        writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isPhoneNumberAutoGenerated);
                    return writer;
                };
    
                /**
                 * Encodes the specified PhoneNumber message, length delimited. Does not implicitly {@link infinitusai.be.PhoneNumber.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {infinitusai.be.IPhoneNumber} message PhoneNumber message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PhoneNumber.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PhoneNumber message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PhoneNumber} PhoneNumber
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PhoneNumber.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PhoneNumber();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.number = reader.string();
                                break;
                            }
                        case 6: {
                                message.name = reader.string();
                                break;
                            }
                        case 7: {
                                message.priority = reader.int32();
                                break;
                            }
                        case 2: {
                                message.type = reader.int32();
                                break;
                            }
                        case 3: {
                                message.enableAutoIvr = reader.bool();
                                break;
                            }
                        case 4: {
                                message.enableCallQueueing = reader.bool();
                                break;
                            }
                        case 8: {
                                message.openPst = $root.google.type.TimeOfDay.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.closePst = $root.google.type.TimeOfDay.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                if (!(message.payerIds && message.payerIds.length))
                                    message.payerIds = [];
                                message.payerIds.push(reader.string());
                                break;
                            }
                        case 5: {
                                if (!(message.holdTimes && message.holdTimes.length))
                                    message.holdTimes = [];
                                message.holdTimes.push($root.infinitusai.be.HoldTime.decode(reader, reader.uint32()));
                                break;
                            }
                        case 11: {
                                if (!(message.restrictedToOrgUuids && message.restrictedToOrgUuids.length))
                                    message.restrictedToOrgUuids = [];
                                message.restrictedToOrgUuids.push(reader.string());
                                break;
                            }
                        case 12: {
                                if (!(message.memberIdRegex && message.memberIdRegex.length))
                                    message.memberIdRegex = [];
                                message.memberIdRegex.push(reader.string());
                                break;
                            }
                        case 13: {
                                message.numAttemptsToReachAgent = reader.int32();
                                break;
                            }
                        case 14: {
                                if (!(message.allowedTaskTypes && message.allowedTaskTypes.length))
                                    message.allowedTaskTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.allowedTaskTypes.push(reader.int32());
                                } else
                                    message.allowedTaskTypes.push(reader.int32());
                                break;
                            }
                        case 15: {
                                message.isPhoneNumberAutoGenerated = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PhoneNumber message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PhoneNumber} PhoneNumber
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PhoneNumber.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PhoneNumber message.
                 * @function verify
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PhoneNumber.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isString(message.number))
                            return "number: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        if (!$util.isInteger(message.priority))
                            return "priority: integer expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.enableAutoIvr != null && message.hasOwnProperty("enableAutoIvr"))
                        if (typeof message.enableAutoIvr !== "boolean")
                            return "enableAutoIvr: boolean expected";
                    if (message.enableCallQueueing != null && message.hasOwnProperty("enableCallQueueing"))
                        if (typeof message.enableCallQueueing !== "boolean")
                            return "enableCallQueueing: boolean expected";
                    if (message.openPst != null && message.hasOwnProperty("openPst")) {
                        var error = $root.google.type.TimeOfDay.verify(message.openPst);
                        if (error)
                            return "openPst." + error;
                    }
                    if (message.closePst != null && message.hasOwnProperty("closePst")) {
                        var error = $root.google.type.TimeOfDay.verify(message.closePst);
                        if (error)
                            return "closePst." + error;
                    }
                    if (message.payerIds != null && message.hasOwnProperty("payerIds")) {
                        if (!Array.isArray(message.payerIds))
                            return "payerIds: array expected";
                        for (var i = 0; i < message.payerIds.length; ++i)
                            if (!$util.isString(message.payerIds[i]))
                                return "payerIds: string[] expected";
                    }
                    if (message.holdTimes != null && message.hasOwnProperty("holdTimes")) {
                        if (!Array.isArray(message.holdTimes))
                            return "holdTimes: array expected";
                        for (var i = 0; i < message.holdTimes.length; ++i) {
                            var error = $root.infinitusai.be.HoldTime.verify(message.holdTimes[i]);
                            if (error)
                                return "holdTimes." + error;
                        }
                    }
                    if (message.restrictedToOrgUuids != null && message.hasOwnProperty("restrictedToOrgUuids")) {
                        if (!Array.isArray(message.restrictedToOrgUuids))
                            return "restrictedToOrgUuids: array expected";
                        for (var i = 0; i < message.restrictedToOrgUuids.length; ++i)
                            if (!$util.isString(message.restrictedToOrgUuids[i]))
                                return "restrictedToOrgUuids: string[] expected";
                    }
                    if (message.memberIdRegex != null && message.hasOwnProperty("memberIdRegex")) {
                        if (!Array.isArray(message.memberIdRegex))
                            return "memberIdRegex: array expected";
                        for (var i = 0; i < message.memberIdRegex.length; ++i)
                            if (!$util.isString(message.memberIdRegex[i]))
                                return "memberIdRegex: string[] expected";
                    }
                    if (message.numAttemptsToReachAgent != null && message.hasOwnProperty("numAttemptsToReachAgent"))
                        if (!$util.isInteger(message.numAttemptsToReachAgent))
                            return "numAttemptsToReachAgent: integer expected";
                    if (message.allowedTaskTypes != null && message.hasOwnProperty("allowedTaskTypes")) {
                        if (!Array.isArray(message.allowedTaskTypes))
                            return "allowedTaskTypes: array expected";
                        for (var i = 0; i < message.allowedTaskTypes.length; ++i)
                            switch (message.allowedTaskTypes[i]) {
                            default:
                                return "allowedTaskTypes: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                                break;
                            }
                    }
                    if (message.isPhoneNumberAutoGenerated != null && message.hasOwnProperty("isPhoneNumberAutoGenerated"))
                        if (typeof message.isPhoneNumberAutoGenerated !== "boolean")
                            return "isPhoneNumberAutoGenerated: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a PhoneNumber message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PhoneNumber} PhoneNumber
                 */
                PhoneNumber.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PhoneNumber)
                        return object;
                    var message = new $root.infinitusai.be.PhoneNumber();
                    if (object.number != null)
                        message.number = String(object.number);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.priority != null)
                        message.priority = object.priority | 0;
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "TYPE_UNDEFINED":
                    case 0:
                        message.type = 0;
                        break;
                    case "TYPE_COMMERCIAL":
                    case 1:
                        message.type = 1;
                        break;
                    case "TYPE_FEDERAL":
                    case 2:
                        message.type = 2;
                        break;
                    case "TYPE_MEDICARE_ADVANTAGE":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.enableAutoIvr != null)
                        message.enableAutoIvr = Boolean(object.enableAutoIvr);
                    if (object.enableCallQueueing != null)
                        message.enableCallQueueing = Boolean(object.enableCallQueueing);
                    if (object.openPst != null) {
                        if (typeof object.openPst !== "object")
                            throw TypeError(".infinitusai.be.PhoneNumber.openPst: object expected");
                        message.openPst = $root.google.type.TimeOfDay.fromObject(object.openPst);
                    }
                    if (object.closePst != null) {
                        if (typeof object.closePst !== "object")
                            throw TypeError(".infinitusai.be.PhoneNumber.closePst: object expected");
                        message.closePst = $root.google.type.TimeOfDay.fromObject(object.closePst);
                    }
                    if (object.payerIds) {
                        if (!Array.isArray(object.payerIds))
                            throw TypeError(".infinitusai.be.PhoneNumber.payerIds: array expected");
                        message.payerIds = [];
                        for (var i = 0; i < object.payerIds.length; ++i)
                            message.payerIds[i] = String(object.payerIds[i]);
                    }
                    if (object.holdTimes) {
                        if (!Array.isArray(object.holdTimes))
                            throw TypeError(".infinitusai.be.PhoneNumber.holdTimes: array expected");
                        message.holdTimes = [];
                        for (var i = 0; i < object.holdTimes.length; ++i) {
                            if (typeof object.holdTimes[i] !== "object")
                                throw TypeError(".infinitusai.be.PhoneNumber.holdTimes: object expected");
                            message.holdTimes[i] = $root.infinitusai.be.HoldTime.fromObject(object.holdTimes[i]);
                        }
                    }
                    if (object.restrictedToOrgUuids) {
                        if (!Array.isArray(object.restrictedToOrgUuids))
                            throw TypeError(".infinitusai.be.PhoneNumber.restrictedToOrgUuids: array expected");
                        message.restrictedToOrgUuids = [];
                        for (var i = 0; i < object.restrictedToOrgUuids.length; ++i)
                            message.restrictedToOrgUuids[i] = String(object.restrictedToOrgUuids[i]);
                    }
                    if (object.memberIdRegex) {
                        if (!Array.isArray(object.memberIdRegex))
                            throw TypeError(".infinitusai.be.PhoneNumber.memberIdRegex: array expected");
                        message.memberIdRegex = [];
                        for (var i = 0; i < object.memberIdRegex.length; ++i)
                            message.memberIdRegex[i] = String(object.memberIdRegex[i]);
                    }
                    if (object.numAttemptsToReachAgent != null)
                        message.numAttemptsToReachAgent = object.numAttemptsToReachAgent | 0;
                    if (object.allowedTaskTypes) {
                        if (!Array.isArray(object.allowedTaskTypes))
                            throw TypeError(".infinitusai.be.PhoneNumber.allowedTaskTypes: array expected");
                        message.allowedTaskTypes = [];
                        for (var i = 0; i < object.allowedTaskTypes.length; ++i)
                            switch (object.allowedTaskTypes[i]) {
                            default:
                                if (typeof object.allowedTaskTypes[i] === "number") {
                                    message.allowedTaskTypes[i] = object.allowedTaskTypes[i];
                                    break;
                                }
                            case "TASK_TYPE_UNKNOWN":
                            case 0:
                                message.allowedTaskTypes[i] = 0;
                                break;
                            case "TASK_TYPE_MED_SUPP_BV":
                            case 1:
                                message.allowedTaskTypes[i] = 1;
                                break;
                            case "TASK_TYPE_FULL_BI":
                            case 2:
                                message.allowedTaskTypes[i] = 2;
                                break;
                            case "TASK_TYPE_PBM_BV":
                            case 3:
                                message.allowedTaskTypes[i] = 3;
                                break;
                            case "TASK_TYPE_COVID_VACCINATIONS":
                            case 4:
                                message.allowedTaskTypes[i] = 4;
                                break;
                            case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                            case 5:
                                message.allowedTaskTypes[i] = 5;
                                break;
                            case "TASK_TYPE_INBOUND":
                            case 6:
                                message.allowedTaskTypes[i] = 6;
                                break;
                            case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                            case 7:
                                message.allowedTaskTypes[i] = 7;
                                break;
                            case "TASK_TYPE_CLAIMS_FOLLOWUP":
                            case 8:
                                message.allowedTaskTypes[i] = 8;
                                break;
                            case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                            case 9:
                                message.allowedTaskTypes[i] = 9;
                                break;
                            case "TASK_TYPE_DENTAL_BV":
                            case 10:
                                message.allowedTaskTypes[i] = 10;
                                break;
                            case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                            case 11:
                                message.allowedTaskTypes[i] = 11;
                                break;
                            case "TASK_TYPE_PATIENT_OUTREACH":
                            case 12:
                                message.allowedTaskTypes[i] = 12;
                                break;
                            case "TASK_TYPE_PROVIDER_OUTREACH":
                            case 13:
                                message.allowedTaskTypes[i] = 13;
                                break;
                            case "TASK_TYPE_FASTTRACK_MM":
                            case 14:
                                message.allowedTaskTypes[i] = 14;
                                break;
                            case "TASK_TYPE_FASTTRACK_PBM":
                            case 15:
                                message.allowedTaskTypes[i] = 15;
                                break;
                            case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                            case 16:
                                message.allowedTaskTypes[i] = 16;
                                break;
                            case "TASK_TYPE_BASIC_BV":
                            case 17:
                                message.allowedTaskTypes[i] = 17;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                            case 18:
                                message.allowedTaskTypes[i] = 18;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                            case 19:
                                message.allowedTaskTypes[i] = 19;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                            case 20:
                                message.allowedTaskTypes[i] = 20;
                                break;
                            case "TASK_TYPE_PBM_DISCOVERY":
                            case 21:
                                message.allowedTaskTypes[i] = 21;
                                break;
                            }
                    }
                    if (object.isPhoneNumberAutoGenerated != null)
                        message.isPhoneNumberAutoGenerated = Boolean(object.isPhoneNumberAutoGenerated);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PhoneNumber message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {infinitusai.be.PhoneNumber} message PhoneNumber
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PhoneNumber.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.holdTimes = [];
                        object.payerIds = [];
                        object.restrictedToOrgUuids = [];
                        object.memberIdRegex = [];
                        object.allowedTaskTypes = [];
                    }
                    if (options.defaults) {
                        object.number = "";
                        object.type = options.enums === String ? "TYPE_UNDEFINED" : 0;
                        object.enableAutoIvr = false;
                        object.enableCallQueueing = false;
                        object.name = "";
                        object.priority = 0;
                        object.openPst = null;
                        object.closePst = null;
                        object.numAttemptsToReachAgent = 0;
                        object.isPhoneNumberAutoGenerated = false;
                    }
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.PhoneNumberType[message.type] === undefined ? message.type : $root.infinitusai.be.PhoneNumberType[message.type] : message.type;
                    if (message.enableAutoIvr != null && message.hasOwnProperty("enableAutoIvr"))
                        object.enableAutoIvr = message.enableAutoIvr;
                    if (message.enableCallQueueing != null && message.hasOwnProperty("enableCallQueueing"))
                        object.enableCallQueueing = message.enableCallQueueing;
                    if (message.holdTimes && message.holdTimes.length) {
                        object.holdTimes = [];
                        for (var j = 0; j < message.holdTimes.length; ++j)
                            object.holdTimes[j] = $root.infinitusai.be.HoldTime.toObject(message.holdTimes[j], options);
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        object.priority = message.priority;
                    if (message.openPst != null && message.hasOwnProperty("openPst"))
                        object.openPst = $root.google.type.TimeOfDay.toObject(message.openPst, options);
                    if (message.closePst != null && message.hasOwnProperty("closePst"))
                        object.closePst = $root.google.type.TimeOfDay.toObject(message.closePst, options);
                    if (message.payerIds && message.payerIds.length) {
                        object.payerIds = [];
                        for (var j = 0; j < message.payerIds.length; ++j)
                            object.payerIds[j] = message.payerIds[j];
                    }
                    if (message.restrictedToOrgUuids && message.restrictedToOrgUuids.length) {
                        object.restrictedToOrgUuids = [];
                        for (var j = 0; j < message.restrictedToOrgUuids.length; ++j)
                            object.restrictedToOrgUuids[j] = message.restrictedToOrgUuids[j];
                    }
                    if (message.memberIdRegex && message.memberIdRegex.length) {
                        object.memberIdRegex = [];
                        for (var j = 0; j < message.memberIdRegex.length; ++j)
                            object.memberIdRegex[j] = message.memberIdRegex[j];
                    }
                    if (message.numAttemptsToReachAgent != null && message.hasOwnProperty("numAttemptsToReachAgent"))
                        object.numAttemptsToReachAgent = message.numAttemptsToReachAgent;
                    if (message.allowedTaskTypes && message.allowedTaskTypes.length) {
                        object.allowedTaskTypes = [];
                        for (var j = 0; j < message.allowedTaskTypes.length; ++j)
                            object.allowedTaskTypes[j] = options.enums === String ? $root.infinitusai.be.TaskType[message.allowedTaskTypes[j]] === undefined ? message.allowedTaskTypes[j] : $root.infinitusai.be.TaskType[message.allowedTaskTypes[j]] : message.allowedTaskTypes[j];
                    }
                    if (message.isPhoneNumberAutoGenerated != null && message.hasOwnProperty("isPhoneNumberAutoGenerated"))
                        object.isPhoneNumberAutoGenerated = message.isPhoneNumberAutoGenerated;
                    return object;
                };
    
                /**
                 * Converts this PhoneNumber to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PhoneNumber
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PhoneNumber.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PhoneNumber
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PhoneNumber
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PhoneNumber.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PhoneNumber";
                };
    
                return PhoneNumber;
            })();
    
            be.PhoneNumberList = (function() {
    
                /**
                 * Properties of a PhoneNumberList.
                 * @memberof infinitusai.be
                 * @interface IPhoneNumberList
                 * @property {Array.<infinitusai.be.IPhoneNumber>|null} [phoneNumber] PhoneNumberList phoneNumber
                 */
    
                /**
                 * Constructs a new PhoneNumberList.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PhoneNumberList.
                 * @implements IPhoneNumberList
                 * @constructor
                 * @param {infinitusai.be.IPhoneNumberList=} [properties] Properties to set
                 */
                function PhoneNumberList(properties) {
                    this.phoneNumber = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PhoneNumberList phoneNumber.
                 * @member {Array.<infinitusai.be.IPhoneNumber>} phoneNumber
                 * @memberof infinitusai.be.PhoneNumberList
                 * @instance
                 */
                PhoneNumberList.prototype.phoneNumber = $util.emptyArray;
    
                /**
                 * Creates a new PhoneNumberList instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {infinitusai.be.IPhoneNumberList=} [properties] Properties to set
                 * @returns {infinitusai.be.PhoneNumberList} PhoneNumberList instance
                 */
                PhoneNumberList.create = function create(properties) {
                    return new PhoneNumberList(properties);
                };
    
                /**
                 * Encodes the specified PhoneNumberList message. Does not implicitly {@link infinitusai.be.PhoneNumberList.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {infinitusai.be.IPhoneNumberList} message PhoneNumberList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PhoneNumberList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.phoneNumber != null && message.phoneNumber.length)
                        for (var i = 0; i < message.phoneNumber.length; ++i)
                            $root.infinitusai.be.PhoneNumber.encode(message.phoneNumber[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified PhoneNumberList message, length delimited. Does not implicitly {@link infinitusai.be.PhoneNumberList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {infinitusai.be.IPhoneNumberList} message PhoneNumberList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PhoneNumberList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PhoneNumberList message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PhoneNumberList} PhoneNumberList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PhoneNumberList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PhoneNumberList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.phoneNumber && message.phoneNumber.length))
                                    message.phoneNumber = [];
                                message.phoneNumber.push($root.infinitusai.be.PhoneNumber.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PhoneNumberList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PhoneNumberList} PhoneNumberList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PhoneNumberList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PhoneNumberList message.
                 * @function verify
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PhoneNumberList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        if (!Array.isArray(message.phoneNumber))
                            return "phoneNumber: array expected";
                        for (var i = 0; i < message.phoneNumber.length; ++i) {
                            var error = $root.infinitusai.be.PhoneNumber.verify(message.phoneNumber[i]);
                            if (error)
                                return "phoneNumber." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a PhoneNumberList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PhoneNumberList} PhoneNumberList
                 */
                PhoneNumberList.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PhoneNumberList)
                        return object;
                    var message = new $root.infinitusai.be.PhoneNumberList();
                    if (object.phoneNumber) {
                        if (!Array.isArray(object.phoneNumber))
                            throw TypeError(".infinitusai.be.PhoneNumberList.phoneNumber: array expected");
                        message.phoneNumber = [];
                        for (var i = 0; i < object.phoneNumber.length; ++i) {
                            if (typeof object.phoneNumber[i] !== "object")
                                throw TypeError(".infinitusai.be.PhoneNumberList.phoneNumber: object expected");
                            message.phoneNumber[i] = $root.infinitusai.be.PhoneNumber.fromObject(object.phoneNumber[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a PhoneNumberList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {infinitusai.be.PhoneNumberList} message PhoneNumberList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PhoneNumberList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.phoneNumber = [];
                    if (message.phoneNumber && message.phoneNumber.length) {
                        object.phoneNumber = [];
                        for (var j = 0; j < message.phoneNumber.length; ++j)
                            object.phoneNumber[j] = $root.infinitusai.be.PhoneNumber.toObject(message.phoneNumber[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this PhoneNumberList to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PhoneNumberList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PhoneNumberList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PhoneNumberList
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PhoneNumberList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PhoneNumberList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PhoneNumberList";
                };
    
                return PhoneNumberList;
            })();
    
            be.CustomerPortalPhoneNumber = (function() {
    
                /**
                 * Properties of a CustomerPortalPhoneNumber.
                 * @memberof infinitusai.be
                 * @interface ICustomerPortalPhoneNumber
                 * @property {string|null} [number] CustomerPortalPhoneNumber number
                 * @property {infinitusai.be.PhoneNumberType|null} [type] CustomerPortalPhoneNumber type
                 * @property {Array.<infinitusai.be.TaskType>|null} [allowedTaskTypes] CustomerPortalPhoneNumber allowedTaskTypes
                 */
    
                /**
                 * Constructs a new CustomerPortalPhoneNumber.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CustomerPortalPhoneNumber.
                 * @implements ICustomerPortalPhoneNumber
                 * @constructor
                 * @param {infinitusai.be.ICustomerPortalPhoneNumber=} [properties] Properties to set
                 */
                function CustomerPortalPhoneNumber(properties) {
                    this.allowedTaskTypes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CustomerPortalPhoneNumber number.
                 * @member {string} number
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @instance
                 */
                CustomerPortalPhoneNumber.prototype.number = "";
    
                /**
                 * CustomerPortalPhoneNumber type.
                 * @member {infinitusai.be.PhoneNumberType} type
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @instance
                 */
                CustomerPortalPhoneNumber.prototype.type = 0;
    
                /**
                 * CustomerPortalPhoneNumber allowedTaskTypes.
                 * @member {Array.<infinitusai.be.TaskType>} allowedTaskTypes
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @instance
                 */
                CustomerPortalPhoneNumber.prototype.allowedTaskTypes = $util.emptyArray;
    
                /**
                 * Creates a new CustomerPortalPhoneNumber instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {infinitusai.be.ICustomerPortalPhoneNumber=} [properties] Properties to set
                 * @returns {infinitusai.be.CustomerPortalPhoneNumber} CustomerPortalPhoneNumber instance
                 */
                CustomerPortalPhoneNumber.create = function create(properties) {
                    return new CustomerPortalPhoneNumber(properties);
                };
    
                /**
                 * Encodes the specified CustomerPortalPhoneNumber message. Does not implicitly {@link infinitusai.be.CustomerPortalPhoneNumber.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {infinitusai.be.ICustomerPortalPhoneNumber} message CustomerPortalPhoneNumber message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomerPortalPhoneNumber.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.number);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                    if (message.allowedTaskTypes != null && message.allowedTaskTypes.length) {
                        writer.uint32(/* id 14, wireType 2 =*/114).fork();
                        for (var i = 0; i < message.allowedTaskTypes.length; ++i)
                            writer.int32(message.allowedTaskTypes[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified CustomerPortalPhoneNumber message, length delimited. Does not implicitly {@link infinitusai.be.CustomerPortalPhoneNumber.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {infinitusai.be.ICustomerPortalPhoneNumber} message CustomerPortalPhoneNumber message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomerPortalPhoneNumber.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CustomerPortalPhoneNumber message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CustomerPortalPhoneNumber} CustomerPortalPhoneNumber
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomerPortalPhoneNumber.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CustomerPortalPhoneNumber();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.number = reader.string();
                                break;
                            }
                        case 2: {
                                message.type = reader.int32();
                                break;
                            }
                        case 14: {
                                if (!(message.allowedTaskTypes && message.allowedTaskTypes.length))
                                    message.allowedTaskTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.allowedTaskTypes.push(reader.int32());
                                } else
                                    message.allowedTaskTypes.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CustomerPortalPhoneNumber message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CustomerPortalPhoneNumber} CustomerPortalPhoneNumber
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomerPortalPhoneNumber.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CustomerPortalPhoneNumber message.
                 * @function verify
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CustomerPortalPhoneNumber.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isString(message.number))
                            return "number: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.allowedTaskTypes != null && message.hasOwnProperty("allowedTaskTypes")) {
                        if (!Array.isArray(message.allowedTaskTypes))
                            return "allowedTaskTypes: array expected";
                        for (var i = 0; i < message.allowedTaskTypes.length; ++i)
                            switch (message.allowedTaskTypes[i]) {
                            default:
                                return "allowedTaskTypes: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a CustomerPortalPhoneNumber message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CustomerPortalPhoneNumber} CustomerPortalPhoneNumber
                 */
                CustomerPortalPhoneNumber.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CustomerPortalPhoneNumber)
                        return object;
                    var message = new $root.infinitusai.be.CustomerPortalPhoneNumber();
                    if (object.number != null)
                        message.number = String(object.number);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "TYPE_UNDEFINED":
                    case 0:
                        message.type = 0;
                        break;
                    case "TYPE_COMMERCIAL":
                    case 1:
                        message.type = 1;
                        break;
                    case "TYPE_FEDERAL":
                    case 2:
                        message.type = 2;
                        break;
                    case "TYPE_MEDICARE_ADVANTAGE":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.allowedTaskTypes) {
                        if (!Array.isArray(object.allowedTaskTypes))
                            throw TypeError(".infinitusai.be.CustomerPortalPhoneNumber.allowedTaskTypes: array expected");
                        message.allowedTaskTypes = [];
                        for (var i = 0; i < object.allowedTaskTypes.length; ++i)
                            switch (object.allowedTaskTypes[i]) {
                            default:
                                if (typeof object.allowedTaskTypes[i] === "number") {
                                    message.allowedTaskTypes[i] = object.allowedTaskTypes[i];
                                    break;
                                }
                            case "TASK_TYPE_UNKNOWN":
                            case 0:
                                message.allowedTaskTypes[i] = 0;
                                break;
                            case "TASK_TYPE_MED_SUPP_BV":
                            case 1:
                                message.allowedTaskTypes[i] = 1;
                                break;
                            case "TASK_TYPE_FULL_BI":
                            case 2:
                                message.allowedTaskTypes[i] = 2;
                                break;
                            case "TASK_TYPE_PBM_BV":
                            case 3:
                                message.allowedTaskTypes[i] = 3;
                                break;
                            case "TASK_TYPE_COVID_VACCINATIONS":
                            case 4:
                                message.allowedTaskTypes[i] = 4;
                                break;
                            case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                            case 5:
                                message.allowedTaskTypes[i] = 5;
                                break;
                            case "TASK_TYPE_INBOUND":
                            case 6:
                                message.allowedTaskTypes[i] = 6;
                                break;
                            case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                            case 7:
                                message.allowedTaskTypes[i] = 7;
                                break;
                            case "TASK_TYPE_CLAIMS_FOLLOWUP":
                            case 8:
                                message.allowedTaskTypes[i] = 8;
                                break;
                            case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                            case 9:
                                message.allowedTaskTypes[i] = 9;
                                break;
                            case "TASK_TYPE_DENTAL_BV":
                            case 10:
                                message.allowedTaskTypes[i] = 10;
                                break;
                            case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                            case 11:
                                message.allowedTaskTypes[i] = 11;
                                break;
                            case "TASK_TYPE_PATIENT_OUTREACH":
                            case 12:
                                message.allowedTaskTypes[i] = 12;
                                break;
                            case "TASK_TYPE_PROVIDER_OUTREACH":
                            case 13:
                                message.allowedTaskTypes[i] = 13;
                                break;
                            case "TASK_TYPE_FASTTRACK_MM":
                            case 14:
                                message.allowedTaskTypes[i] = 14;
                                break;
                            case "TASK_TYPE_FASTTRACK_PBM":
                            case 15:
                                message.allowedTaskTypes[i] = 15;
                                break;
                            case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                            case 16:
                                message.allowedTaskTypes[i] = 16;
                                break;
                            case "TASK_TYPE_BASIC_BV":
                            case 17:
                                message.allowedTaskTypes[i] = 17;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                            case 18:
                                message.allowedTaskTypes[i] = 18;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                            case 19:
                                message.allowedTaskTypes[i] = 19;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                            case 20:
                                message.allowedTaskTypes[i] = 20;
                                break;
                            case "TASK_TYPE_PBM_DISCOVERY":
                            case 21:
                                message.allowedTaskTypes[i] = 21;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CustomerPortalPhoneNumber message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {infinitusai.be.CustomerPortalPhoneNumber} message CustomerPortalPhoneNumber
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CustomerPortalPhoneNumber.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.allowedTaskTypes = [];
                    if (options.defaults) {
                        object.number = "";
                        object.type = options.enums === String ? "TYPE_UNDEFINED" : 0;
                    }
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.be.PhoneNumberType[message.type] === undefined ? message.type : $root.infinitusai.be.PhoneNumberType[message.type] : message.type;
                    if (message.allowedTaskTypes && message.allowedTaskTypes.length) {
                        object.allowedTaskTypes = [];
                        for (var j = 0; j < message.allowedTaskTypes.length; ++j)
                            object.allowedTaskTypes[j] = options.enums === String ? $root.infinitusai.be.TaskType[message.allowedTaskTypes[j]] === undefined ? message.allowedTaskTypes[j] : $root.infinitusai.be.TaskType[message.allowedTaskTypes[j]] : message.allowedTaskTypes[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this CustomerPortalPhoneNumber to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CustomerPortalPhoneNumber.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CustomerPortalPhoneNumber
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CustomerPortalPhoneNumber
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CustomerPortalPhoneNumber.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CustomerPortalPhoneNumber";
                };
    
                return CustomerPortalPhoneNumber;
            })();
    
            be.SpeedTestDownloadRequest = (function() {
    
                /**
                 * Properties of a SpeedTestDownloadRequest.
                 * @memberof infinitusai.be
                 * @interface ISpeedTestDownloadRequest
                 * @property {number|Long|null} [value] SpeedTestDownloadRequest value
                 * @property {infinitusai.be.SpeedTestDownloadRequest.MeasurementUnit|null} [unit] SpeedTestDownloadRequest unit
                 */
    
                /**
                 * Constructs a new SpeedTestDownloadRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SpeedTestDownloadRequest.
                 * @implements ISpeedTestDownloadRequest
                 * @constructor
                 * @param {infinitusai.be.ISpeedTestDownloadRequest=} [properties] Properties to set
                 */
                function SpeedTestDownloadRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SpeedTestDownloadRequest value.
                 * @member {number|Long} value
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @instance
                 */
                SpeedTestDownloadRequest.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * SpeedTestDownloadRequest unit.
                 * @member {infinitusai.be.SpeedTestDownloadRequest.MeasurementUnit} unit
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @instance
                 */
                SpeedTestDownloadRequest.prototype.unit = 0;
    
                /**
                 * Creates a new SpeedTestDownloadRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {infinitusai.be.ISpeedTestDownloadRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.SpeedTestDownloadRequest} SpeedTestDownloadRequest instance
                 */
                SpeedTestDownloadRequest.create = function create(properties) {
                    return new SpeedTestDownloadRequest(properties);
                };
    
                /**
                 * Encodes the specified SpeedTestDownloadRequest message. Does not implicitly {@link infinitusai.be.SpeedTestDownloadRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {infinitusai.be.ISpeedTestDownloadRequest} message SpeedTestDownloadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpeedTestDownloadRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.value);
                    if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.unit);
                    return writer;
                };
    
                /**
                 * Encodes the specified SpeedTestDownloadRequest message, length delimited. Does not implicitly {@link infinitusai.be.SpeedTestDownloadRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {infinitusai.be.ISpeedTestDownloadRequest} message SpeedTestDownloadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpeedTestDownloadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SpeedTestDownloadRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SpeedTestDownloadRequest} SpeedTestDownloadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpeedTestDownloadRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SpeedTestDownloadRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.int64();
                                break;
                            }
                        case 2: {
                                message.unit = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SpeedTestDownloadRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SpeedTestDownloadRequest} SpeedTestDownloadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpeedTestDownloadRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SpeedTestDownloadRequest message.
                 * @function verify
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpeedTestDownloadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                            return "value: integer|Long expected";
                    if (message.unit != null && message.hasOwnProperty("unit"))
                        switch (message.unit) {
                        default:
                            return "unit: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a SpeedTestDownloadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SpeedTestDownloadRequest} SpeedTestDownloadRequest
                 */
                SpeedTestDownloadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SpeedTestDownloadRequest)
                        return object;
                    var message = new $root.infinitusai.be.SpeedTestDownloadRequest();
                    if (object.value != null)
                        if ($util.Long)
                            (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                        else if (typeof object.value === "string")
                            message.value = parseInt(object.value, 10);
                        else if (typeof object.value === "number")
                            message.value = object.value;
                        else if (typeof object.value === "object")
                            message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
                    switch (object.unit) {
                    default:
                        if (typeof object.unit === "number") {
                            message.unit = object.unit;
                            break;
                        }
                        break;
                    case "BYTES":
                    case 0:
                        message.unit = 0;
                        break;
                    case "KILOBYTES":
                    case 1:
                        message.unit = 1;
                        break;
                    case "MEGABYTES":
                    case 2:
                        message.unit = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SpeedTestDownloadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {infinitusai.be.SpeedTestDownloadRequest} message SpeedTestDownloadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpeedTestDownloadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.value = options.longs === String ? "0" : 0;
                        object.unit = options.enums === String ? "BYTES" : 0;
                    }
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value === "number")
                            object.value = options.longs === String ? String(message.value) : message.value;
                        else
                            object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
                    if (message.unit != null && message.hasOwnProperty("unit"))
                        object.unit = options.enums === String ? $root.infinitusai.be.SpeedTestDownloadRequest.MeasurementUnit[message.unit] === undefined ? message.unit : $root.infinitusai.be.SpeedTestDownloadRequest.MeasurementUnit[message.unit] : message.unit;
                    return object;
                };
    
                /**
                 * Converts this SpeedTestDownloadRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpeedTestDownloadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SpeedTestDownloadRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SpeedTestDownloadRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpeedTestDownloadRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SpeedTestDownloadRequest";
                };
    
                /**
                 * MeasurementUnit enum.
                 * @name infinitusai.be.SpeedTestDownloadRequest.MeasurementUnit
                 * @enum {number}
                 * @property {number} BYTES=0 BYTES value
                 * @property {number} KILOBYTES=1 KILOBYTES value
                 * @property {number} MEGABYTES=2 MEGABYTES value
                 */
                SpeedTestDownloadRequest.MeasurementUnit = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "BYTES"] = 0;
                    values[valuesById[1] = "KILOBYTES"] = 1;
                    values[valuesById[2] = "MEGABYTES"] = 2;
                    return values;
                })();
    
                return SpeedTestDownloadRequest;
            })();
    
            be.PostProcessingService = (function() {
    
                /**
                 * Constructs a new PostProcessingService service.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PostProcessingService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function PostProcessingService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (PostProcessingService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PostProcessingService;
    
                /**
                 * Creates new PostProcessingService service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.PostProcessingService
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {PostProcessingService} RPC service. Useful where requests and/or responses are streamed.
                 */
                PostProcessingService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.PostProcessingService#runPostProcessingJobs}.
                 * @memberof infinitusai.be.PostProcessingService
                 * @typedef RunPostProcessingJobsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.RunPostProcessingJobsResponse} [response] RunPostProcessingJobsResponse
                 */
    
                /**
                 * Calls RunPostProcessingJobs.
                 * @function runPostProcessingJobs
                 * @memberof infinitusai.be.PostProcessingService
                 * @instance
                 * @param {infinitusai.be.IRunPostProcessingJobsRequest} request RunPostProcessingJobsRequest message or plain object
                 * @param {infinitusai.be.PostProcessingService.RunPostProcessingJobsCallback} callback Node-style callback called with the error, if any, and RunPostProcessingJobsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PostProcessingService.prototype.runPostProcessingJobs = function runPostProcessingJobs(request, callback) {
                    return this.rpcCall(runPostProcessingJobs, $root.infinitusai.be.RunPostProcessingJobsRequest, $root.infinitusai.be.RunPostProcessingJobsResponse, request, callback);
                }, "name", { value: "RunPostProcessingJobs" });
    
                /**
                 * Calls RunPostProcessingJobs.
                 * @function runPostProcessingJobs
                 * @memberof infinitusai.be.PostProcessingService
                 * @instance
                 * @param {infinitusai.be.IRunPostProcessingJobsRequest} request RunPostProcessingJobsRequest message or plain object
                 * @returns {Promise<infinitusai.be.RunPostProcessingJobsResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.PostProcessingService#runFTPostProcessingJobs}.
                 * @memberof infinitusai.be.PostProcessingService
                 * @typedef RunFTPostProcessingJobsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.RunPostProcessingJobsResponse} [response] RunPostProcessingJobsResponse
                 */
    
                /**
                 * Calls RunFTPostProcessingJobs.
                 * @function runFTPostProcessingJobs
                 * @memberof infinitusai.be.PostProcessingService
                 * @instance
                 * @param {infinitusai.be.IRunPostProcessingJobsRequest} request RunPostProcessingJobsRequest message or plain object
                 * @param {infinitusai.be.PostProcessingService.RunFTPostProcessingJobsCallback} callback Node-style callback called with the error, if any, and RunPostProcessingJobsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PostProcessingService.prototype.runFTPostProcessingJobs = function runFTPostProcessingJobs(request, callback) {
                    return this.rpcCall(runFTPostProcessingJobs, $root.infinitusai.be.RunPostProcessingJobsRequest, $root.infinitusai.be.RunPostProcessingJobsResponse, request, callback);
                }, "name", { value: "RunFTPostProcessingJobs" });
    
                /**
                 * Calls RunFTPostProcessingJobs.
                 * @function runFTPostProcessingJobs
                 * @memberof infinitusai.be.PostProcessingService
                 * @instance
                 * @param {infinitusai.be.IRunPostProcessingJobsRequest} request RunPostProcessingJobsRequest message or plain object
                 * @returns {Promise<infinitusai.be.RunPostProcessingJobsResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.PostProcessingService#runAutoReviewJobs}.
                 * @memberof infinitusai.be.PostProcessingService
                 * @typedef RunAutoReviewJobsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.RunAutoReviewJobsResponse} [response] RunAutoReviewJobsResponse
                 */
    
                /**
                 * Calls RunAutoReviewJobs.
                 * @function runAutoReviewJobs
                 * @memberof infinitusai.be.PostProcessingService
                 * @instance
                 * @param {infinitusai.be.IRunAutoReviewJobsRequest} request RunAutoReviewJobsRequest message or plain object
                 * @param {infinitusai.be.PostProcessingService.RunAutoReviewJobsCallback} callback Node-style callback called with the error, if any, and RunAutoReviewJobsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PostProcessingService.prototype.runAutoReviewJobs = function runAutoReviewJobs(request, callback) {
                    return this.rpcCall(runAutoReviewJobs, $root.infinitusai.be.RunAutoReviewJobsRequest, $root.infinitusai.be.RunAutoReviewJobsResponse, request, callback);
                }, "name", { value: "RunAutoReviewJobs" });
    
                /**
                 * Calls RunAutoReviewJobs.
                 * @function runAutoReviewJobs
                 * @memberof infinitusai.be.PostProcessingService
                 * @instance
                 * @param {infinitusai.be.IRunAutoReviewJobsRequest} request RunAutoReviewJobsRequest message or plain object
                 * @returns {Promise<infinitusai.be.RunAutoReviewJobsResponse>} Promise
                 * @variation 2
                 */
    
                return PostProcessingService;
            })();
    
            be.RunPostProcessingJobsRequest = (function() {
    
                /**
                 * Properties of a RunPostProcessingJobsRequest.
                 * @memberof infinitusai.be
                 * @interface IRunPostProcessingJobsRequest
                 * @property {string|null} [orgUuid] RunPostProcessingJobsRequest orgUuid
                 * @property {string|null} [taskUuid] RunPostProcessingJobsRequest taskUuid
                 * @property {string|null} [callUuid] RunPostProcessingJobsRequest callUuid
                 */
    
                /**
                 * Constructs a new RunPostProcessingJobsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RunPostProcessingJobsRequest.
                 * @implements IRunPostProcessingJobsRequest
                 * @constructor
                 * @param {infinitusai.be.IRunPostProcessingJobsRequest=} [properties] Properties to set
                 */
                function RunPostProcessingJobsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RunPostProcessingJobsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @instance
                 */
                RunPostProcessingJobsRequest.prototype.orgUuid = "";
    
                /**
                 * RunPostProcessingJobsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @instance
                 */
                RunPostProcessingJobsRequest.prototype.taskUuid = "";
    
                /**
                 * RunPostProcessingJobsRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @instance
                 */
                RunPostProcessingJobsRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new RunPostProcessingJobsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {infinitusai.be.IRunPostProcessingJobsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RunPostProcessingJobsRequest} RunPostProcessingJobsRequest instance
                 */
                RunPostProcessingJobsRequest.create = function create(properties) {
                    return new RunPostProcessingJobsRequest(properties);
                };
    
                /**
                 * Encodes the specified RunPostProcessingJobsRequest message. Does not implicitly {@link infinitusai.be.RunPostProcessingJobsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {infinitusai.be.IRunPostProcessingJobsRequest} message RunPostProcessingJobsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RunPostProcessingJobsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RunPostProcessingJobsRequest message, length delimited. Does not implicitly {@link infinitusai.be.RunPostProcessingJobsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {infinitusai.be.IRunPostProcessingJobsRequest} message RunPostProcessingJobsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RunPostProcessingJobsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RunPostProcessingJobsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RunPostProcessingJobsRequest} RunPostProcessingJobsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RunPostProcessingJobsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RunPostProcessingJobsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RunPostProcessingJobsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RunPostProcessingJobsRequest} RunPostProcessingJobsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RunPostProcessingJobsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RunPostProcessingJobsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RunPostProcessingJobsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RunPostProcessingJobsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RunPostProcessingJobsRequest} RunPostProcessingJobsRequest
                 */
                RunPostProcessingJobsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RunPostProcessingJobsRequest)
                        return object;
                    var message = new $root.infinitusai.be.RunPostProcessingJobsRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RunPostProcessingJobsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {infinitusai.be.RunPostProcessingJobsRequest} message RunPostProcessingJobsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RunPostProcessingJobsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this RunPostProcessingJobsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RunPostProcessingJobsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RunPostProcessingJobsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RunPostProcessingJobsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RunPostProcessingJobsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RunPostProcessingJobsRequest";
                };
    
                return RunPostProcessingJobsRequest;
            })();
    
            be.RunPostProcessingJobsResponse = (function() {
    
                /**
                 * Properties of a RunPostProcessingJobsResponse.
                 * @memberof infinitusai.be
                 * @interface IRunPostProcessingJobsResponse
                 */
    
                /**
                 * Constructs a new RunPostProcessingJobsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RunPostProcessingJobsResponse.
                 * @implements IRunPostProcessingJobsResponse
                 * @constructor
                 * @param {infinitusai.be.IRunPostProcessingJobsResponse=} [properties] Properties to set
                 */
                function RunPostProcessingJobsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RunPostProcessingJobsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {infinitusai.be.IRunPostProcessingJobsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RunPostProcessingJobsResponse} RunPostProcessingJobsResponse instance
                 */
                RunPostProcessingJobsResponse.create = function create(properties) {
                    return new RunPostProcessingJobsResponse(properties);
                };
    
                /**
                 * Encodes the specified RunPostProcessingJobsResponse message. Does not implicitly {@link infinitusai.be.RunPostProcessingJobsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {infinitusai.be.IRunPostProcessingJobsResponse} message RunPostProcessingJobsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RunPostProcessingJobsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RunPostProcessingJobsResponse message, length delimited. Does not implicitly {@link infinitusai.be.RunPostProcessingJobsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {infinitusai.be.IRunPostProcessingJobsResponse} message RunPostProcessingJobsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RunPostProcessingJobsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RunPostProcessingJobsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RunPostProcessingJobsResponse} RunPostProcessingJobsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RunPostProcessingJobsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RunPostProcessingJobsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RunPostProcessingJobsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RunPostProcessingJobsResponse} RunPostProcessingJobsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RunPostProcessingJobsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RunPostProcessingJobsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RunPostProcessingJobsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RunPostProcessingJobsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RunPostProcessingJobsResponse} RunPostProcessingJobsResponse
                 */
                RunPostProcessingJobsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RunPostProcessingJobsResponse)
                        return object;
                    return new $root.infinitusai.be.RunPostProcessingJobsResponse();
                };
    
                /**
                 * Creates a plain object from a RunPostProcessingJobsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {infinitusai.be.RunPostProcessingJobsResponse} message RunPostProcessingJobsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RunPostProcessingJobsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RunPostProcessingJobsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RunPostProcessingJobsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RunPostProcessingJobsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RunPostProcessingJobsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RunPostProcessingJobsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RunPostProcessingJobsResponse";
                };
    
                return RunPostProcessingJobsResponse;
            })();
    
            be.RunAutoReviewJobsRequest = (function() {
    
                /**
                 * Properties of a RunAutoReviewJobsRequest.
                 * @memberof infinitusai.be
                 * @interface IRunAutoReviewJobsRequest
                 * @property {string|null} [orgUuid] RunAutoReviewJobsRequest orgUuid
                 * @property {string|null} [taskUuid] RunAutoReviewJobsRequest taskUuid
                 * @property {string|null} [callUuid] RunAutoReviewJobsRequest callUuid
                 */
    
                /**
                 * Constructs a new RunAutoReviewJobsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RunAutoReviewJobsRequest.
                 * @implements IRunAutoReviewJobsRequest
                 * @constructor
                 * @param {infinitusai.be.IRunAutoReviewJobsRequest=} [properties] Properties to set
                 */
                function RunAutoReviewJobsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RunAutoReviewJobsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @instance
                 */
                RunAutoReviewJobsRequest.prototype.orgUuid = "";
    
                /**
                 * RunAutoReviewJobsRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @instance
                 */
                RunAutoReviewJobsRequest.prototype.taskUuid = "";
    
                /**
                 * RunAutoReviewJobsRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @instance
                 */
                RunAutoReviewJobsRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new RunAutoReviewJobsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {infinitusai.be.IRunAutoReviewJobsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.RunAutoReviewJobsRequest} RunAutoReviewJobsRequest instance
                 */
                RunAutoReviewJobsRequest.create = function create(properties) {
                    return new RunAutoReviewJobsRequest(properties);
                };
    
                /**
                 * Encodes the specified RunAutoReviewJobsRequest message. Does not implicitly {@link infinitusai.be.RunAutoReviewJobsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {infinitusai.be.IRunAutoReviewJobsRequest} message RunAutoReviewJobsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RunAutoReviewJobsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RunAutoReviewJobsRequest message, length delimited. Does not implicitly {@link infinitusai.be.RunAutoReviewJobsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {infinitusai.be.IRunAutoReviewJobsRequest} message RunAutoReviewJobsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RunAutoReviewJobsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RunAutoReviewJobsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RunAutoReviewJobsRequest} RunAutoReviewJobsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RunAutoReviewJobsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RunAutoReviewJobsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RunAutoReviewJobsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RunAutoReviewJobsRequest} RunAutoReviewJobsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RunAutoReviewJobsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RunAutoReviewJobsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RunAutoReviewJobsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RunAutoReviewJobsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RunAutoReviewJobsRequest} RunAutoReviewJobsRequest
                 */
                RunAutoReviewJobsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RunAutoReviewJobsRequest)
                        return object;
                    var message = new $root.infinitusai.be.RunAutoReviewJobsRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RunAutoReviewJobsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {infinitusai.be.RunAutoReviewJobsRequest} message RunAutoReviewJobsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RunAutoReviewJobsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this RunAutoReviewJobsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RunAutoReviewJobsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RunAutoReviewJobsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RunAutoReviewJobsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RunAutoReviewJobsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RunAutoReviewJobsRequest";
                };
    
                return RunAutoReviewJobsRequest;
            })();
    
            be.RunAutoReviewJobsResponse = (function() {
    
                /**
                 * Properties of a RunAutoReviewJobsResponse.
                 * @memberof infinitusai.be
                 * @interface IRunAutoReviewJobsResponse
                 */
    
                /**
                 * Constructs a new RunAutoReviewJobsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a RunAutoReviewJobsResponse.
                 * @implements IRunAutoReviewJobsResponse
                 * @constructor
                 * @param {infinitusai.be.IRunAutoReviewJobsResponse=} [properties] Properties to set
                 */
                function RunAutoReviewJobsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RunAutoReviewJobsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {infinitusai.be.IRunAutoReviewJobsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.RunAutoReviewJobsResponse} RunAutoReviewJobsResponse instance
                 */
                RunAutoReviewJobsResponse.create = function create(properties) {
                    return new RunAutoReviewJobsResponse(properties);
                };
    
                /**
                 * Encodes the specified RunAutoReviewJobsResponse message. Does not implicitly {@link infinitusai.be.RunAutoReviewJobsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {infinitusai.be.IRunAutoReviewJobsResponse} message RunAutoReviewJobsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RunAutoReviewJobsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RunAutoReviewJobsResponse message, length delimited. Does not implicitly {@link infinitusai.be.RunAutoReviewJobsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {infinitusai.be.IRunAutoReviewJobsResponse} message RunAutoReviewJobsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RunAutoReviewJobsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RunAutoReviewJobsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.RunAutoReviewJobsResponse} RunAutoReviewJobsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RunAutoReviewJobsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.RunAutoReviewJobsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RunAutoReviewJobsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.RunAutoReviewJobsResponse} RunAutoReviewJobsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RunAutoReviewJobsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RunAutoReviewJobsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RunAutoReviewJobsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RunAutoReviewJobsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.RunAutoReviewJobsResponse} RunAutoReviewJobsResponse
                 */
                RunAutoReviewJobsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.RunAutoReviewJobsResponse)
                        return object;
                    return new $root.infinitusai.be.RunAutoReviewJobsResponse();
                };
    
                /**
                 * Creates a plain object from a RunAutoReviewJobsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {infinitusai.be.RunAutoReviewJobsResponse} message RunAutoReviewJobsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RunAutoReviewJobsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RunAutoReviewJobsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RunAutoReviewJobsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RunAutoReviewJobsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.RunAutoReviewJobsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RunAutoReviewJobsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.RunAutoReviewJobsResponse";
                };
    
                return RunAutoReviewJobsResponse;
            })();
    
            /**
             * QueueEnvironment enum.
             * @name infinitusai.be.QueueEnvironment
             * @enum {number}
             * @property {number} QUEUE_UNSTABLE=0 QUEUE_UNSTABLE value
             * @property {number} QUEUE_STAGING=1 QUEUE_STAGING value
             * @property {number} QUEUE_PRODUCTION=2 QUEUE_PRODUCTION value
             */
            be.QueueEnvironment = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "QUEUE_UNSTABLE"] = 0;
                values[valuesById[1] = "QUEUE_STAGING"] = 1;
                values[valuesById[2] = "QUEUE_PRODUCTION"] = 2;
                return values;
            })();
    
            be.QueueV2Doc = (function() {
    
                /**
                 * Properties of a QueueV2Doc.
                 * @memberof infinitusai.be
                 * @interface IQueueV2Doc
                 * @property {Object.<string,string>|null} [callUuids] QueueV2Doc callUuids
                 * @property {string|null} [queueUuid] QueueV2Doc queueUuid
                 * @property {number|null} [avgOpCallTimeSeconds] QueueV2Doc avgOpCallTimeSeconds
                 */
    
                /**
                 * Constructs a new QueueV2Doc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueueV2Doc.
                 * @implements IQueueV2Doc
                 * @constructor
                 * @param {infinitusai.be.IQueueV2Doc=} [properties] Properties to set
                 */
                function QueueV2Doc(properties) {
                    this.callUuids = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * QueueV2Doc callUuids.
                 * @member {Object.<string,string>} callUuids
                 * @memberof infinitusai.be.QueueV2Doc
                 * @instance
                 */
                QueueV2Doc.prototype.callUuids = $util.emptyObject;
    
                /**
                 * QueueV2Doc queueUuid.
                 * @member {string} queueUuid
                 * @memberof infinitusai.be.QueueV2Doc
                 * @instance
                 */
                QueueV2Doc.prototype.queueUuid = "";
    
                /**
                 * QueueV2Doc avgOpCallTimeSeconds.
                 * @member {number} avgOpCallTimeSeconds
                 * @memberof infinitusai.be.QueueV2Doc
                 * @instance
                 */
                QueueV2Doc.prototype.avgOpCallTimeSeconds = 0;
    
                /**
                 * Creates a new QueueV2Doc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {infinitusai.be.IQueueV2Doc=} [properties] Properties to set
                 * @returns {infinitusai.be.QueueV2Doc} QueueV2Doc instance
                 */
                QueueV2Doc.create = function create(properties) {
                    return new QueueV2Doc(properties);
                };
    
                /**
                 * Encodes the specified QueueV2Doc message. Does not implicitly {@link infinitusai.be.QueueV2Doc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {infinitusai.be.IQueueV2Doc} message QueueV2Doc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueueV2Doc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.callUuids != null && Object.hasOwnProperty.call(message, "callUuids"))
                        for (var keys = Object.keys(message.callUuids), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.callUuids[keys[i]]).ldelim();
                    if (message.queueUuid != null && Object.hasOwnProperty.call(message, "queueUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.queueUuid);
                    if (message.avgOpCallTimeSeconds != null && Object.hasOwnProperty.call(message, "avgOpCallTimeSeconds"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.avgOpCallTimeSeconds);
                    return writer;
                };
    
                /**
                 * Encodes the specified QueueV2Doc message, length delimited. Does not implicitly {@link infinitusai.be.QueueV2Doc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {infinitusai.be.IQueueV2Doc} message QueueV2Doc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueueV2Doc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueueV2Doc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueueV2Doc} QueueV2Doc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueueV2Doc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueueV2Doc(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.callUuids === $util.emptyObject)
                                    message.callUuids = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.callUuids[key] = value;
                                break;
                            }
                        case 2: {
                                message.queueUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.avgOpCallTimeSeconds = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueueV2Doc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueueV2Doc} QueueV2Doc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueueV2Doc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueueV2Doc message.
                 * @function verify
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueueV2Doc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.callUuids != null && message.hasOwnProperty("callUuids")) {
                        if (!$util.isObject(message.callUuids))
                            return "callUuids: object expected";
                        var key = Object.keys(message.callUuids);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.callUuids[key[i]]))
                                return "callUuids: string{k:string} expected";
                    }
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        if (!$util.isString(message.queueUuid))
                            return "queueUuid: string expected";
                    if (message.avgOpCallTimeSeconds != null && message.hasOwnProperty("avgOpCallTimeSeconds"))
                        if (!$util.isInteger(message.avgOpCallTimeSeconds))
                            return "avgOpCallTimeSeconds: integer expected";
                    return null;
                };
    
                /**
                 * Creates a QueueV2Doc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueueV2Doc} QueueV2Doc
                 */
                QueueV2Doc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueueV2Doc)
                        return object;
                    var message = new $root.infinitusai.be.QueueV2Doc();
                    if (object.callUuids) {
                        if (typeof object.callUuids !== "object")
                            throw TypeError(".infinitusai.be.QueueV2Doc.callUuids: object expected");
                        message.callUuids = {};
                        for (var keys = Object.keys(object.callUuids), i = 0; i < keys.length; ++i)
                            message.callUuids[keys[i]] = String(object.callUuids[keys[i]]);
                    }
                    if (object.queueUuid != null)
                        message.queueUuid = String(object.queueUuid);
                    if (object.avgOpCallTimeSeconds != null)
                        message.avgOpCallTimeSeconds = object.avgOpCallTimeSeconds | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a QueueV2Doc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {infinitusai.be.QueueV2Doc} message QueueV2Doc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueueV2Doc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.callUuids = {};
                    if (options.defaults) {
                        object.queueUuid = "";
                        object.avgOpCallTimeSeconds = 0;
                    }
                    var keys2;
                    if (message.callUuids && (keys2 = Object.keys(message.callUuids)).length) {
                        object.callUuids = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.callUuids[keys2[j]] = message.callUuids[keys2[j]];
                    }
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        object.queueUuid = message.queueUuid;
                    if (message.avgOpCallTimeSeconds != null && message.hasOwnProperty("avgOpCallTimeSeconds"))
                        object.avgOpCallTimeSeconds = message.avgOpCallTimeSeconds;
                    return object;
                };
    
                /**
                 * Converts this QueueV2Doc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueueV2Doc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueueV2Doc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueueV2Doc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueueV2Doc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueueV2Doc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueueV2Doc";
                };
    
                return QueueV2Doc;
            })();
    
            be.QueueConfigV2Doc = (function() {
    
                /**
                 * Properties of a QueueConfigV2Doc.
                 * @memberof infinitusai.be
                 * @interface IQueueConfigV2Doc
                 * @property {number|null} [queueMaxSize] QueueConfigV2Doc queueMaxSize
                 * @property {number|null} [queueMinSize] QueueConfigV2Doc queueMinSize
                 * @property {number|null} [queueIntervalMinutes] QueueConfigV2Doc queueIntervalMinutes
                 * @property {boolean|null} [queueEnabled] QueueConfigV2Doc queueEnabled
                 * @property {number|null} [queueStartHourPst] QueueConfigV2Doc queueStartHourPst
                 * @property {number|null} [queueEndHourPst] QueueConfigV2Doc queueEndHourPst
                 * @property {string|null} [queueUuid] QueueConfigV2Doc queueUuid
                 * @property {number|null} [queueIntervalSeconds] QueueConfigV2Doc queueIntervalSeconds
                 * @property {number|null} [maxCallsPerNum] QueueConfigV2Doc maxCallsPerNum
                 * @property {number|Long|null} [dueDateCutoffMillis] QueueConfigV2Doc dueDateCutoffMillis
                 */
    
                /**
                 * Constructs a new QueueConfigV2Doc.
                 * @memberof infinitusai.be
                 * @classdesc Represents a QueueConfigV2Doc.
                 * @implements IQueueConfigV2Doc
                 * @constructor
                 * @param {infinitusai.be.IQueueConfigV2Doc=} [properties] Properties to set
                 */
                function QueueConfigV2Doc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * QueueConfigV2Doc queueMaxSize.
                 * @member {number} queueMaxSize
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.queueMaxSize = 0;
    
                /**
                 * QueueConfigV2Doc queueMinSize.
                 * @member {number} queueMinSize
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.queueMinSize = 0;
    
                /**
                 * QueueConfigV2Doc queueIntervalMinutes.
                 * @member {number} queueIntervalMinutes
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.queueIntervalMinutes = 0;
    
                /**
                 * QueueConfigV2Doc queueEnabled.
                 * @member {boolean} queueEnabled
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.queueEnabled = false;
    
                /**
                 * QueueConfigV2Doc queueStartHourPst.
                 * @member {number} queueStartHourPst
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.queueStartHourPst = 0;
    
                /**
                 * QueueConfigV2Doc queueEndHourPst.
                 * @member {number} queueEndHourPst
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.queueEndHourPst = 0;
    
                /**
                 * QueueConfigV2Doc queueUuid.
                 * @member {string} queueUuid
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.queueUuid = "";
    
                /**
                 * QueueConfigV2Doc queueIntervalSeconds.
                 * @member {number} queueIntervalSeconds
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.queueIntervalSeconds = 0;
    
                /**
                 * QueueConfigV2Doc maxCallsPerNum.
                 * @member {number} maxCallsPerNum
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.maxCallsPerNum = 0;
    
                /**
                 * QueueConfigV2Doc dueDateCutoffMillis.
                 * @member {number|Long} dueDateCutoffMillis
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 */
                QueueConfigV2Doc.prototype.dueDateCutoffMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new QueueConfigV2Doc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {infinitusai.be.IQueueConfigV2Doc=} [properties] Properties to set
                 * @returns {infinitusai.be.QueueConfigV2Doc} QueueConfigV2Doc instance
                 */
                QueueConfigV2Doc.create = function create(properties) {
                    return new QueueConfigV2Doc(properties);
                };
    
                /**
                 * Encodes the specified QueueConfigV2Doc message. Does not implicitly {@link infinitusai.be.QueueConfigV2Doc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {infinitusai.be.IQueueConfigV2Doc} message QueueConfigV2Doc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueueConfigV2Doc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queueMaxSize != null && Object.hasOwnProperty.call(message, "queueMaxSize"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.queueMaxSize);
                    if (message.queueMinSize != null && Object.hasOwnProperty.call(message, "queueMinSize"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.queueMinSize);
                    if (message.queueIntervalMinutes != null && Object.hasOwnProperty.call(message, "queueIntervalMinutes"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.queueIntervalMinutes);
                    if (message.queueEnabled != null && Object.hasOwnProperty.call(message, "queueEnabled"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.queueEnabled);
                    if (message.queueStartHourPst != null && Object.hasOwnProperty.call(message, "queueStartHourPst"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.queueStartHourPst);
                    if (message.queueEndHourPst != null && Object.hasOwnProperty.call(message, "queueEndHourPst"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.queueEndHourPst);
                    if (message.queueUuid != null && Object.hasOwnProperty.call(message, "queueUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.queueUuid);
                    if (message.queueIntervalSeconds != null && Object.hasOwnProperty.call(message, "queueIntervalSeconds"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.queueIntervalSeconds);
                    if (message.maxCallsPerNum != null && Object.hasOwnProperty.call(message, "maxCallsPerNum"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.maxCallsPerNum);
                    if (message.dueDateCutoffMillis != null && Object.hasOwnProperty.call(message, "dueDateCutoffMillis"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int64(message.dueDateCutoffMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified QueueConfigV2Doc message, length delimited. Does not implicitly {@link infinitusai.be.QueueConfigV2Doc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {infinitusai.be.IQueueConfigV2Doc} message QueueConfigV2Doc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                QueueConfigV2Doc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a QueueConfigV2Doc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.QueueConfigV2Doc} QueueConfigV2Doc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueueConfigV2Doc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.QueueConfigV2Doc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queueMaxSize = reader.int32();
                                break;
                            }
                        case 2: {
                                message.queueMinSize = reader.int32();
                                break;
                            }
                        case 3: {
                                message.queueIntervalMinutes = reader.int32();
                                break;
                            }
                        case 4: {
                                message.queueEnabled = reader.bool();
                                break;
                            }
                        case 5: {
                                message.queueStartHourPst = reader.int32();
                                break;
                            }
                        case 6: {
                                message.queueEndHourPst = reader.int32();
                                break;
                            }
                        case 7: {
                                message.queueUuid = reader.string();
                                break;
                            }
                        case 8: {
                                message.queueIntervalSeconds = reader.int32();
                                break;
                            }
                        case 9: {
                                message.maxCallsPerNum = reader.int32();
                                break;
                            }
                        case 10: {
                                message.dueDateCutoffMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a QueueConfigV2Doc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.QueueConfigV2Doc} QueueConfigV2Doc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                QueueConfigV2Doc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a QueueConfigV2Doc message.
                 * @function verify
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                QueueConfigV2Doc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queueMaxSize != null && message.hasOwnProperty("queueMaxSize"))
                        if (!$util.isInteger(message.queueMaxSize))
                            return "queueMaxSize: integer expected";
                    if (message.queueMinSize != null && message.hasOwnProperty("queueMinSize"))
                        if (!$util.isInteger(message.queueMinSize))
                            return "queueMinSize: integer expected";
                    if (message.queueIntervalMinutes != null && message.hasOwnProperty("queueIntervalMinutes"))
                        if (!$util.isInteger(message.queueIntervalMinutes))
                            return "queueIntervalMinutes: integer expected";
                    if (message.queueEnabled != null && message.hasOwnProperty("queueEnabled"))
                        if (typeof message.queueEnabled !== "boolean")
                            return "queueEnabled: boolean expected";
                    if (message.queueStartHourPst != null && message.hasOwnProperty("queueStartHourPst"))
                        if (!$util.isInteger(message.queueStartHourPst))
                            return "queueStartHourPst: integer expected";
                    if (message.queueEndHourPst != null && message.hasOwnProperty("queueEndHourPst"))
                        if (!$util.isInteger(message.queueEndHourPst))
                            return "queueEndHourPst: integer expected";
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        if (!$util.isString(message.queueUuid))
                            return "queueUuid: string expected";
                    if (message.queueIntervalSeconds != null && message.hasOwnProperty("queueIntervalSeconds"))
                        if (!$util.isInteger(message.queueIntervalSeconds))
                            return "queueIntervalSeconds: integer expected";
                    if (message.maxCallsPerNum != null && message.hasOwnProperty("maxCallsPerNum"))
                        if (!$util.isInteger(message.maxCallsPerNum))
                            return "maxCallsPerNum: integer expected";
                    if (message.dueDateCutoffMillis != null && message.hasOwnProperty("dueDateCutoffMillis"))
                        if (!$util.isInteger(message.dueDateCutoffMillis) && !(message.dueDateCutoffMillis && $util.isInteger(message.dueDateCutoffMillis.low) && $util.isInteger(message.dueDateCutoffMillis.high)))
                            return "dueDateCutoffMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a QueueConfigV2Doc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.QueueConfigV2Doc} QueueConfigV2Doc
                 */
                QueueConfigV2Doc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.QueueConfigV2Doc)
                        return object;
                    var message = new $root.infinitusai.be.QueueConfigV2Doc();
                    if (object.queueMaxSize != null)
                        message.queueMaxSize = object.queueMaxSize | 0;
                    if (object.queueMinSize != null)
                        message.queueMinSize = object.queueMinSize | 0;
                    if (object.queueIntervalMinutes != null)
                        message.queueIntervalMinutes = object.queueIntervalMinutes | 0;
                    if (object.queueEnabled != null)
                        message.queueEnabled = Boolean(object.queueEnabled);
                    if (object.queueStartHourPst != null)
                        message.queueStartHourPst = object.queueStartHourPst | 0;
                    if (object.queueEndHourPst != null)
                        message.queueEndHourPst = object.queueEndHourPst | 0;
                    if (object.queueUuid != null)
                        message.queueUuid = String(object.queueUuid);
                    if (object.queueIntervalSeconds != null)
                        message.queueIntervalSeconds = object.queueIntervalSeconds | 0;
                    if (object.maxCallsPerNum != null)
                        message.maxCallsPerNum = object.maxCallsPerNum | 0;
                    if (object.dueDateCutoffMillis != null)
                        if ($util.Long)
                            (message.dueDateCutoffMillis = $util.Long.fromValue(object.dueDateCutoffMillis)).unsigned = false;
                        else if (typeof object.dueDateCutoffMillis === "string")
                            message.dueDateCutoffMillis = parseInt(object.dueDateCutoffMillis, 10);
                        else if (typeof object.dueDateCutoffMillis === "number")
                            message.dueDateCutoffMillis = object.dueDateCutoffMillis;
                        else if (typeof object.dueDateCutoffMillis === "object")
                            message.dueDateCutoffMillis = new $util.LongBits(object.dueDateCutoffMillis.low >>> 0, object.dueDateCutoffMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a QueueConfigV2Doc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {infinitusai.be.QueueConfigV2Doc} message QueueConfigV2Doc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                QueueConfigV2Doc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.queueMaxSize = 0;
                        object.queueMinSize = 0;
                        object.queueIntervalMinutes = 0;
                        object.queueEnabled = false;
                        object.queueStartHourPst = 0;
                        object.queueEndHourPst = 0;
                        object.queueUuid = "";
                        object.queueIntervalSeconds = 0;
                        object.maxCallsPerNum = 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.dueDateCutoffMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.dueDateCutoffMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.queueMaxSize != null && message.hasOwnProperty("queueMaxSize"))
                        object.queueMaxSize = message.queueMaxSize;
                    if (message.queueMinSize != null && message.hasOwnProperty("queueMinSize"))
                        object.queueMinSize = message.queueMinSize;
                    if (message.queueIntervalMinutes != null && message.hasOwnProperty("queueIntervalMinutes"))
                        object.queueIntervalMinutes = message.queueIntervalMinutes;
                    if (message.queueEnabled != null && message.hasOwnProperty("queueEnabled"))
                        object.queueEnabled = message.queueEnabled;
                    if (message.queueStartHourPst != null && message.hasOwnProperty("queueStartHourPst"))
                        object.queueStartHourPst = message.queueStartHourPst;
                    if (message.queueEndHourPst != null && message.hasOwnProperty("queueEndHourPst"))
                        object.queueEndHourPst = message.queueEndHourPst;
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        object.queueUuid = message.queueUuid;
                    if (message.queueIntervalSeconds != null && message.hasOwnProperty("queueIntervalSeconds"))
                        object.queueIntervalSeconds = message.queueIntervalSeconds;
                    if (message.maxCallsPerNum != null && message.hasOwnProperty("maxCallsPerNum"))
                        object.maxCallsPerNum = message.maxCallsPerNum;
                    if (message.dueDateCutoffMillis != null && message.hasOwnProperty("dueDateCutoffMillis"))
                        if (typeof message.dueDateCutoffMillis === "number")
                            object.dueDateCutoffMillis = options.longs === String ? String(message.dueDateCutoffMillis) : message.dueDateCutoffMillis;
                        else
                            object.dueDateCutoffMillis = options.longs === String ? $util.Long.prototype.toString.call(message.dueDateCutoffMillis) : options.longs === Number ? new $util.LongBits(message.dueDateCutoffMillis.low >>> 0, message.dueDateCutoffMillis.high >>> 0).toNumber() : message.dueDateCutoffMillis;
                    return object;
                };
    
                /**
                 * Converts this QueueConfigV2Doc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                QueueConfigV2Doc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for QueueConfigV2Doc
                 * @function getTypeUrl
                 * @memberof infinitusai.be.QueueConfigV2Doc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                QueueConfigV2Doc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.QueueConfigV2Doc";
                };
    
                return QueueConfigV2Doc;
            })();
    
            be.Queue = (function() {
    
                /**
                 * Properties of a Queue.
                 * @memberof infinitusai.be
                 * @interface IQueue
                 * @property {string|null} [uuid] Queue uuid
                 * @property {string|null} [name] Queue name
                 * @property {Array.<string>|null} [skillUuids] Queue skillUuids
                 * @property {string|null} [assembledId] Queue assembledId
                 */
    
                /**
                 * Constructs a new Queue.
                 * @memberof infinitusai.be
                 * @classdesc Represents a Queue.
                 * @implements IQueue
                 * @constructor
                 * @param {infinitusai.be.IQueue=} [properties] Properties to set
                 */
                function Queue(properties) {
                    this.skillUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Queue uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.be.Queue
                 * @instance
                 */
                Queue.prototype.uuid = "";
    
                /**
                 * Queue name.
                 * @member {string} name
                 * @memberof infinitusai.be.Queue
                 * @instance
                 */
                Queue.prototype.name = "";
    
                /**
                 * Queue skillUuids.
                 * @member {Array.<string>} skillUuids
                 * @memberof infinitusai.be.Queue
                 * @instance
                 */
                Queue.prototype.skillUuids = $util.emptyArray;
    
                /**
                 * Queue assembledId.
                 * @member {string} assembledId
                 * @memberof infinitusai.be.Queue
                 * @instance
                 */
                Queue.prototype.assembledId = "";
    
                /**
                 * Creates a new Queue instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {infinitusai.be.IQueue=} [properties] Properties to set
                 * @returns {infinitusai.be.Queue} Queue instance
                 */
                Queue.create = function create(properties) {
                    return new Queue(properties);
                };
    
                /**
                 * Encodes the specified Queue message. Does not implicitly {@link infinitusai.be.Queue.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {infinitusai.be.IQueue} message Queue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Queue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.skillUuids != null && message.skillUuids.length)
                        for (var i = 0; i < message.skillUuids.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.skillUuids[i]);
                    if (message.assembledId != null && Object.hasOwnProperty.call(message, "assembledId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.assembledId);
                    return writer;
                };
    
                /**
                 * Encodes the specified Queue message, length delimited. Does not implicitly {@link infinitusai.be.Queue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {infinitusai.be.IQueue} message Queue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Queue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Queue message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.Queue} Queue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Queue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.Queue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.skillUuids && message.skillUuids.length))
                                    message.skillUuids = [];
                                message.skillUuids.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.assembledId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Queue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.Queue} Queue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Queue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Queue message.
                 * @function verify
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Queue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.skillUuids != null && message.hasOwnProperty("skillUuids")) {
                        if (!Array.isArray(message.skillUuids))
                            return "skillUuids: array expected";
                        for (var i = 0; i < message.skillUuids.length; ++i)
                            if (!$util.isString(message.skillUuids[i]))
                                return "skillUuids: string[] expected";
                    }
                    if (message.assembledId != null && message.hasOwnProperty("assembledId"))
                        if (!$util.isString(message.assembledId))
                            return "assembledId: string expected";
                    return null;
                };
    
                /**
                 * Creates a Queue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.Queue} Queue
                 */
                Queue.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.Queue)
                        return object;
                    var message = new $root.infinitusai.be.Queue();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.skillUuids) {
                        if (!Array.isArray(object.skillUuids))
                            throw TypeError(".infinitusai.be.Queue.skillUuids: array expected");
                        message.skillUuids = [];
                        for (var i = 0; i < object.skillUuids.length; ++i)
                            message.skillUuids[i] = String(object.skillUuids[i]);
                    }
                    if (object.assembledId != null)
                        message.assembledId = String(object.assembledId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Queue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {infinitusai.be.Queue} message Queue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Queue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skillUuids = [];
                    if (options.defaults) {
                        object.uuid = "";
                        object.name = "";
                        object.assembledId = "";
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.skillUuids && message.skillUuids.length) {
                        object.skillUuids = [];
                        for (var j = 0; j < message.skillUuids.length; ++j)
                            object.skillUuids[j] = message.skillUuids[j];
                    }
                    if (message.assembledId != null && message.hasOwnProperty("assembledId"))
                        object.assembledId = message.assembledId;
                    return object;
                };
    
                /**
                 * Converts this Queue to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.Queue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Queue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Queue
                 * @function getTypeUrl
                 * @memberof infinitusai.be.Queue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Queue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.Queue";
                };
    
                return Queue;
            })();
    
            be.UnifiedQueueConfig = (function() {
    
                /**
                 * Properties of an UnifiedQueueConfig.
                 * @memberof infinitusai.be
                 * @interface IUnifiedQueueConfig
                 * @property {number|null} [queueMaxSize] UnifiedQueueConfig queueMaxSize
                 * @property {number|null} [queueMinSize] UnifiedQueueConfig queueMinSize
                 * @property {boolean|null} [queueEnabled] UnifiedQueueConfig queueEnabled
                 * @property {string|null} [queueStartTimePst] UnifiedQueueConfig queueStartTimePst
                 * @property {string|null} [queueEndTimePst] UnifiedQueueConfig queueEndTimePst
                 * @property {string|null} [queueUuid] UnifiedQueueConfig queueUuid
                 * @property {infinitusai.be.QueueEnvironment|null} [environment] UnifiedQueueConfig environment
                 * @property {boolean|null} [overrideSystemSettings] UnifiedQueueConfig overrideSystemSettings
                 * @property {number|null} [trafficSplitUnstable] UnifiedQueueConfig trafficSplitUnstable
                 * @property {number|null} [trafficSplitStaging] UnifiedQueueConfig trafficSplitStaging
                 * @property {number|null} [trafficSplitProd] UnifiedQueueConfig trafficSplitProd
                 */
    
                /**
                 * Constructs a new UnifiedQueueConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UnifiedQueueConfig.
                 * @implements IUnifiedQueueConfig
                 * @constructor
                 * @param {infinitusai.be.IUnifiedQueueConfig=} [properties] Properties to set
                 */
                function UnifiedQueueConfig(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UnifiedQueueConfig queueMaxSize.
                 * @member {number} queueMaxSize
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.queueMaxSize = 0;
    
                /**
                 * UnifiedQueueConfig queueMinSize.
                 * @member {number} queueMinSize
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.queueMinSize = 0;
    
                /**
                 * UnifiedQueueConfig queueEnabled.
                 * @member {boolean} queueEnabled
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.queueEnabled = false;
    
                /**
                 * UnifiedQueueConfig queueStartTimePst.
                 * @member {string} queueStartTimePst
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.queueStartTimePst = "";
    
                /**
                 * UnifiedQueueConfig queueEndTimePst.
                 * @member {string} queueEndTimePst
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.queueEndTimePst = "";
    
                /**
                 * UnifiedQueueConfig queueUuid.
                 * @member {string} queueUuid
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.queueUuid = "";
    
                /**
                 * UnifiedQueueConfig environment.
                 * @member {infinitusai.be.QueueEnvironment} environment
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.environment = 0;
    
                /**
                 * UnifiedQueueConfig overrideSystemSettings.
                 * @member {boolean} overrideSystemSettings
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.overrideSystemSettings = false;
    
                /**
                 * UnifiedQueueConfig trafficSplitUnstable.
                 * @member {number|null|undefined} trafficSplitUnstable
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.trafficSplitUnstable = null;
    
                /**
                 * UnifiedQueueConfig trafficSplitStaging.
                 * @member {number|null|undefined} trafficSplitStaging
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.trafficSplitStaging = null;
    
                /**
                 * UnifiedQueueConfig trafficSplitProd.
                 * @member {number|null|undefined} trafficSplitProd
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                UnifiedQueueConfig.prototype.trafficSplitProd = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * UnifiedQueueConfig _trafficSplitUnstable.
                 * @member {"trafficSplitUnstable"|undefined} _trafficSplitUnstable
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                Object.defineProperty(UnifiedQueueConfig.prototype, "_trafficSplitUnstable", {
                    get: $util.oneOfGetter($oneOfFields = ["trafficSplitUnstable"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * UnifiedQueueConfig _trafficSplitStaging.
                 * @member {"trafficSplitStaging"|undefined} _trafficSplitStaging
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                Object.defineProperty(UnifiedQueueConfig.prototype, "_trafficSplitStaging", {
                    get: $util.oneOfGetter($oneOfFields = ["trafficSplitStaging"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * UnifiedQueueConfig _trafficSplitProd.
                 * @member {"trafficSplitProd"|undefined} _trafficSplitProd
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 */
                Object.defineProperty(UnifiedQueueConfig.prototype, "_trafficSplitProd", {
                    get: $util.oneOfGetter($oneOfFields = ["trafficSplitProd"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new UnifiedQueueConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {infinitusai.be.IUnifiedQueueConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.UnifiedQueueConfig} UnifiedQueueConfig instance
                 */
                UnifiedQueueConfig.create = function create(properties) {
                    return new UnifiedQueueConfig(properties);
                };
    
                /**
                 * Encodes the specified UnifiedQueueConfig message. Does not implicitly {@link infinitusai.be.UnifiedQueueConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {infinitusai.be.IUnifiedQueueConfig} message UnifiedQueueConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnifiedQueueConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queueMaxSize != null && Object.hasOwnProperty.call(message, "queueMaxSize"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.queueMaxSize);
                    if (message.queueMinSize != null && Object.hasOwnProperty.call(message, "queueMinSize"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.queueMinSize);
                    if (message.queueEnabled != null && Object.hasOwnProperty.call(message, "queueEnabled"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.queueEnabled);
                    if (message.queueStartTimePst != null && Object.hasOwnProperty.call(message, "queueStartTimePst"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.queueStartTimePst);
                    if (message.queueEndTimePst != null && Object.hasOwnProperty.call(message, "queueEndTimePst"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.queueEndTimePst);
                    if (message.queueUuid != null && Object.hasOwnProperty.call(message, "queueUuid"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.queueUuid);
                    if (message.environment != null && Object.hasOwnProperty.call(message, "environment"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.environment);
                    if (message.overrideSystemSettings != null && Object.hasOwnProperty.call(message, "overrideSystemSettings"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.overrideSystemSettings);
                    if (message.trafficSplitUnstable != null && Object.hasOwnProperty.call(message, "trafficSplitUnstable"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.trafficSplitUnstable);
                    if (message.trafficSplitStaging != null && Object.hasOwnProperty.call(message, "trafficSplitStaging"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.trafficSplitStaging);
                    if (message.trafficSplitProd != null && Object.hasOwnProperty.call(message, "trafficSplitProd"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.trafficSplitProd);
                    return writer;
                };
    
                /**
                 * Encodes the specified UnifiedQueueConfig message, length delimited. Does not implicitly {@link infinitusai.be.UnifiedQueueConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {infinitusai.be.IUnifiedQueueConfig} message UnifiedQueueConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnifiedQueueConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UnifiedQueueConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UnifiedQueueConfig} UnifiedQueueConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnifiedQueueConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UnifiedQueueConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queueMaxSize = reader.int32();
                                break;
                            }
                        case 2: {
                                message.queueMinSize = reader.int32();
                                break;
                            }
                        case 3: {
                                message.queueEnabled = reader.bool();
                                break;
                            }
                        case 4: {
                                message.queueStartTimePst = reader.string();
                                break;
                            }
                        case 5: {
                                message.queueEndTimePst = reader.string();
                                break;
                            }
                        case 6: {
                                message.queueUuid = reader.string();
                                break;
                            }
                        case 7: {
                                message.environment = reader.int32();
                                break;
                            }
                        case 8: {
                                message.overrideSystemSettings = reader.bool();
                                break;
                            }
                        case 9: {
                                message.trafficSplitUnstable = reader.int32();
                                break;
                            }
                        case 10: {
                                message.trafficSplitStaging = reader.int32();
                                break;
                            }
                        case 11: {
                                message.trafficSplitProd = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UnifiedQueueConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UnifiedQueueConfig} UnifiedQueueConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnifiedQueueConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UnifiedQueueConfig message.
                 * @function verify
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnifiedQueueConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.queueMaxSize != null && message.hasOwnProperty("queueMaxSize"))
                        if (!$util.isInteger(message.queueMaxSize))
                            return "queueMaxSize: integer expected";
                    if (message.queueMinSize != null && message.hasOwnProperty("queueMinSize"))
                        if (!$util.isInteger(message.queueMinSize))
                            return "queueMinSize: integer expected";
                    if (message.queueEnabled != null && message.hasOwnProperty("queueEnabled"))
                        if (typeof message.queueEnabled !== "boolean")
                            return "queueEnabled: boolean expected";
                    if (message.queueStartTimePst != null && message.hasOwnProperty("queueStartTimePst"))
                        if (!$util.isString(message.queueStartTimePst))
                            return "queueStartTimePst: string expected";
                    if (message.queueEndTimePst != null && message.hasOwnProperty("queueEndTimePst"))
                        if (!$util.isString(message.queueEndTimePst))
                            return "queueEndTimePst: string expected";
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        if (!$util.isString(message.queueUuid))
                            return "queueUuid: string expected";
                    if (message.environment != null && message.hasOwnProperty("environment"))
                        switch (message.environment) {
                        default:
                            return "environment: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.overrideSystemSettings != null && message.hasOwnProperty("overrideSystemSettings"))
                        if (typeof message.overrideSystemSettings !== "boolean")
                            return "overrideSystemSettings: boolean expected";
                    if (message.trafficSplitUnstable != null && message.hasOwnProperty("trafficSplitUnstable")) {
                        properties._trafficSplitUnstable = 1;
                        if (!$util.isInteger(message.trafficSplitUnstable))
                            return "trafficSplitUnstable: integer expected";
                    }
                    if (message.trafficSplitStaging != null && message.hasOwnProperty("trafficSplitStaging")) {
                        properties._trafficSplitStaging = 1;
                        if (!$util.isInteger(message.trafficSplitStaging))
                            return "trafficSplitStaging: integer expected";
                    }
                    if (message.trafficSplitProd != null && message.hasOwnProperty("trafficSplitProd")) {
                        properties._trafficSplitProd = 1;
                        if (!$util.isInteger(message.trafficSplitProd))
                            return "trafficSplitProd: integer expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an UnifiedQueueConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UnifiedQueueConfig} UnifiedQueueConfig
                 */
                UnifiedQueueConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UnifiedQueueConfig)
                        return object;
                    var message = new $root.infinitusai.be.UnifiedQueueConfig();
                    if (object.queueMaxSize != null)
                        message.queueMaxSize = object.queueMaxSize | 0;
                    if (object.queueMinSize != null)
                        message.queueMinSize = object.queueMinSize | 0;
                    if (object.queueEnabled != null)
                        message.queueEnabled = Boolean(object.queueEnabled);
                    if (object.queueStartTimePst != null)
                        message.queueStartTimePst = String(object.queueStartTimePst);
                    if (object.queueEndTimePst != null)
                        message.queueEndTimePst = String(object.queueEndTimePst);
                    if (object.queueUuid != null)
                        message.queueUuid = String(object.queueUuid);
                    switch (object.environment) {
                    default:
                        if (typeof object.environment === "number") {
                            message.environment = object.environment;
                            break;
                        }
                        break;
                    case "QUEUE_UNSTABLE":
                    case 0:
                        message.environment = 0;
                        break;
                    case "QUEUE_STAGING":
                    case 1:
                        message.environment = 1;
                        break;
                    case "QUEUE_PRODUCTION":
                    case 2:
                        message.environment = 2;
                        break;
                    }
                    if (object.overrideSystemSettings != null)
                        message.overrideSystemSettings = Boolean(object.overrideSystemSettings);
                    if (object.trafficSplitUnstable != null)
                        message.trafficSplitUnstable = object.trafficSplitUnstable | 0;
                    if (object.trafficSplitStaging != null)
                        message.trafficSplitStaging = object.trafficSplitStaging | 0;
                    if (object.trafficSplitProd != null)
                        message.trafficSplitProd = object.trafficSplitProd | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from an UnifiedQueueConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {infinitusai.be.UnifiedQueueConfig} message UnifiedQueueConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnifiedQueueConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.queueMaxSize = 0;
                        object.queueMinSize = 0;
                        object.queueEnabled = false;
                        object.queueStartTimePst = "";
                        object.queueEndTimePst = "";
                        object.queueUuid = "";
                        object.environment = options.enums === String ? "QUEUE_UNSTABLE" : 0;
                        object.overrideSystemSettings = false;
                    }
                    if (message.queueMaxSize != null && message.hasOwnProperty("queueMaxSize"))
                        object.queueMaxSize = message.queueMaxSize;
                    if (message.queueMinSize != null && message.hasOwnProperty("queueMinSize"))
                        object.queueMinSize = message.queueMinSize;
                    if (message.queueEnabled != null && message.hasOwnProperty("queueEnabled"))
                        object.queueEnabled = message.queueEnabled;
                    if (message.queueStartTimePst != null && message.hasOwnProperty("queueStartTimePst"))
                        object.queueStartTimePst = message.queueStartTimePst;
                    if (message.queueEndTimePst != null && message.hasOwnProperty("queueEndTimePst"))
                        object.queueEndTimePst = message.queueEndTimePst;
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        object.queueUuid = message.queueUuid;
                    if (message.environment != null && message.hasOwnProperty("environment"))
                        object.environment = options.enums === String ? $root.infinitusai.be.QueueEnvironment[message.environment] === undefined ? message.environment : $root.infinitusai.be.QueueEnvironment[message.environment] : message.environment;
                    if (message.overrideSystemSettings != null && message.hasOwnProperty("overrideSystemSettings"))
                        object.overrideSystemSettings = message.overrideSystemSettings;
                    if (message.trafficSplitUnstable != null && message.hasOwnProperty("trafficSplitUnstable")) {
                        object.trafficSplitUnstable = message.trafficSplitUnstable;
                        if (options.oneofs)
                            object._trafficSplitUnstable = "trafficSplitUnstable";
                    }
                    if (message.trafficSplitStaging != null && message.hasOwnProperty("trafficSplitStaging")) {
                        object.trafficSplitStaging = message.trafficSplitStaging;
                        if (options.oneofs)
                            object._trafficSplitStaging = "trafficSplitStaging";
                    }
                    if (message.trafficSplitProd != null && message.hasOwnProperty("trafficSplitProd")) {
                        object.trafficSplitProd = message.trafficSplitProd;
                        if (options.oneofs)
                            object._trafficSplitProd = "trafficSplitProd";
                    }
                    return object;
                };
    
                /**
                 * Converts this UnifiedQueueConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnifiedQueueConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UnifiedQueueConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UnifiedQueueConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UnifiedQueueConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UnifiedQueueConfig";
                };
    
                return UnifiedQueueConfig;
            })();
    
            be.UpsertQueueRequest = (function() {
    
                /**
                 * Properties of an UpsertQueueRequest.
                 * @memberof infinitusai.be
                 * @interface IUpsertQueueRequest
                 * @property {infinitusai.be.IQueue|null} [queue] UpsertQueueRequest queue
                 */
    
                /**
                 * Constructs a new UpsertQueueRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertQueueRequest.
                 * @implements IUpsertQueueRequest
                 * @constructor
                 * @param {infinitusai.be.IUpsertQueueRequest=} [properties] Properties to set
                 */
                function UpsertQueueRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertQueueRequest queue.
                 * @member {infinitusai.be.IQueue|null|undefined} queue
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @instance
                 */
                UpsertQueueRequest.prototype.queue = null;
    
                /**
                 * Creates a new UpsertQueueRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {infinitusai.be.IUpsertQueueRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertQueueRequest} UpsertQueueRequest instance
                 */
                UpsertQueueRequest.create = function create(properties) {
                    return new UpsertQueueRequest(properties);
                };
    
                /**
                 * Encodes the specified UpsertQueueRequest message. Does not implicitly {@link infinitusai.be.UpsertQueueRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {infinitusai.be.IUpsertQueueRequest} message UpsertQueueRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertQueueRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queue != null && Object.hasOwnProperty.call(message, "queue"))
                        $root.infinitusai.be.Queue.encode(message.queue, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertQueueRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpsertQueueRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {infinitusai.be.IUpsertQueueRequest} message UpsertQueueRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertQueueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertQueueRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertQueueRequest} UpsertQueueRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertQueueRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertQueueRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queue = $root.infinitusai.be.Queue.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertQueueRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertQueueRequest} UpsertQueueRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertQueueRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertQueueRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertQueueRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queue != null && message.hasOwnProperty("queue")) {
                        var error = $root.infinitusai.be.Queue.verify(message.queue);
                        if (error)
                            return "queue." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertQueueRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertQueueRequest} UpsertQueueRequest
                 */
                UpsertQueueRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertQueueRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpsertQueueRequest();
                    if (object.queue != null) {
                        if (typeof object.queue !== "object")
                            throw TypeError(".infinitusai.be.UpsertQueueRequest.queue: object expected");
                        message.queue = $root.infinitusai.be.Queue.fromObject(object.queue);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertQueueRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {infinitusai.be.UpsertQueueRequest} message UpsertQueueRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertQueueRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queue = null;
                    if (message.queue != null && message.hasOwnProperty("queue"))
                        object.queue = $root.infinitusai.be.Queue.toObject(message.queue, options);
                    return object;
                };
    
                /**
                 * Converts this UpsertQueueRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertQueueRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertQueueRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertQueueRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertQueueRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertQueueRequest";
                };
    
                return UpsertQueueRequest;
            })();
    
            be.UpsertQueueConfigRequest = (function() {
    
                /**
                 * Properties of an UpsertQueueConfigRequest.
                 * @memberof infinitusai.be
                 * @interface IUpsertQueueConfigRequest
                 * @property {infinitusai.be.IUnifiedQueueConfig|null} [queueConfig] UpsertQueueConfigRequest queueConfig
                 */
    
                /**
                 * Constructs a new UpsertQueueConfigRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertQueueConfigRequest.
                 * @implements IUpsertQueueConfigRequest
                 * @constructor
                 * @param {infinitusai.be.IUpsertQueueConfigRequest=} [properties] Properties to set
                 */
                function UpsertQueueConfigRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertQueueConfigRequest queueConfig.
                 * @member {infinitusai.be.IUnifiedQueueConfig|null|undefined} queueConfig
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @instance
                 */
                UpsertQueueConfigRequest.prototype.queueConfig = null;
    
                /**
                 * Creates a new UpsertQueueConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {infinitusai.be.IUpsertQueueConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertQueueConfigRequest} UpsertQueueConfigRequest instance
                 */
                UpsertQueueConfigRequest.create = function create(properties) {
                    return new UpsertQueueConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified UpsertQueueConfigRequest message. Does not implicitly {@link infinitusai.be.UpsertQueueConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {infinitusai.be.IUpsertQueueConfigRequest} message UpsertQueueConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertQueueConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queueConfig != null && Object.hasOwnProperty.call(message, "queueConfig"))
                        $root.infinitusai.be.UnifiedQueueConfig.encode(message.queueConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertQueueConfigRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpsertQueueConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {infinitusai.be.IUpsertQueueConfigRequest} message UpsertQueueConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertQueueConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertQueueConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertQueueConfigRequest} UpsertQueueConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertQueueConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertQueueConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queueConfig = $root.infinitusai.be.UnifiedQueueConfig.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertQueueConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertQueueConfigRequest} UpsertQueueConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertQueueConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertQueueConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertQueueConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queueConfig != null && message.hasOwnProperty("queueConfig")) {
                        var error = $root.infinitusai.be.UnifiedQueueConfig.verify(message.queueConfig);
                        if (error)
                            return "queueConfig." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertQueueConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertQueueConfigRequest} UpsertQueueConfigRequest
                 */
                UpsertQueueConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertQueueConfigRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpsertQueueConfigRequest();
                    if (object.queueConfig != null) {
                        if (typeof object.queueConfig !== "object")
                            throw TypeError(".infinitusai.be.UpsertQueueConfigRequest.queueConfig: object expected");
                        message.queueConfig = $root.infinitusai.be.UnifiedQueueConfig.fromObject(object.queueConfig);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertQueueConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {infinitusai.be.UpsertQueueConfigRequest} message UpsertQueueConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertQueueConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queueConfig = null;
                    if (message.queueConfig != null && message.hasOwnProperty("queueConfig"))
                        object.queueConfig = $root.infinitusai.be.UnifiedQueueConfig.toObject(message.queueConfig, options);
                    return object;
                };
    
                /**
                 * Converts this UpsertQueueConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertQueueConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertQueueConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertQueueConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertQueueConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertQueueConfigRequest";
                };
    
                return UpsertQueueConfigRequest;
            })();
    
            be.GetAllQueuesRequest = (function() {
    
                /**
                 * Properties of a GetAllQueuesRequest.
                 * @memberof infinitusai.be
                 * @interface IGetAllQueuesRequest
                 */
    
                /**
                 * Constructs a new GetAllQueuesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAllQueuesRequest.
                 * @implements IGetAllQueuesRequest
                 * @constructor
                 * @param {infinitusai.be.IGetAllQueuesRequest=} [properties] Properties to set
                 */
                function GetAllQueuesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetAllQueuesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {infinitusai.be.IGetAllQueuesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAllQueuesRequest} GetAllQueuesRequest instance
                 */
                GetAllQueuesRequest.create = function create(properties) {
                    return new GetAllQueuesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetAllQueuesRequest message. Does not implicitly {@link infinitusai.be.GetAllQueuesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {infinitusai.be.IGetAllQueuesRequest} message GetAllQueuesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAllQueuesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAllQueuesRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetAllQueuesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {infinitusai.be.IGetAllQueuesRequest} message GetAllQueuesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAllQueuesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAllQueuesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAllQueuesRequest} GetAllQueuesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAllQueuesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAllQueuesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAllQueuesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAllQueuesRequest} GetAllQueuesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAllQueuesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAllQueuesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAllQueuesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetAllQueuesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAllQueuesRequest} GetAllQueuesRequest
                 */
                GetAllQueuesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAllQueuesRequest)
                        return object;
                    return new $root.infinitusai.be.GetAllQueuesRequest();
                };
    
                /**
                 * Creates a plain object from a GetAllQueuesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {infinitusai.be.GetAllQueuesRequest} message GetAllQueuesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAllQueuesRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetAllQueuesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAllQueuesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAllQueuesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAllQueuesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAllQueuesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAllQueuesRequest";
                };
    
                return GetAllQueuesRequest;
            })();
    
            be.GetAllQueuesResponse = (function() {
    
                /**
                 * Properties of a GetAllQueuesResponse.
                 * @memberof infinitusai.be
                 * @interface IGetAllQueuesResponse
                 * @property {Array.<infinitusai.be.IQueue>|null} [queues] GetAllQueuesResponse queues
                 */
    
                /**
                 * Constructs a new GetAllQueuesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAllQueuesResponse.
                 * @implements IGetAllQueuesResponse
                 * @constructor
                 * @param {infinitusai.be.IGetAllQueuesResponse=} [properties] Properties to set
                 */
                function GetAllQueuesResponse(properties) {
                    this.queues = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAllQueuesResponse queues.
                 * @member {Array.<infinitusai.be.IQueue>} queues
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @instance
                 */
                GetAllQueuesResponse.prototype.queues = $util.emptyArray;
    
                /**
                 * Creates a new GetAllQueuesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {infinitusai.be.IGetAllQueuesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAllQueuesResponse} GetAllQueuesResponse instance
                 */
                GetAllQueuesResponse.create = function create(properties) {
                    return new GetAllQueuesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetAllQueuesResponse message. Does not implicitly {@link infinitusai.be.GetAllQueuesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {infinitusai.be.IGetAllQueuesResponse} message GetAllQueuesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAllQueuesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queues != null && message.queues.length)
                        for (var i = 0; i < message.queues.length; ++i)
                            $root.infinitusai.be.Queue.encode(message.queues[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAllQueuesResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetAllQueuesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {infinitusai.be.IGetAllQueuesResponse} message GetAllQueuesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAllQueuesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAllQueuesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAllQueuesResponse} GetAllQueuesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAllQueuesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAllQueuesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.queues && message.queues.length))
                                    message.queues = [];
                                message.queues.push($root.infinitusai.be.Queue.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAllQueuesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAllQueuesResponse} GetAllQueuesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAllQueuesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAllQueuesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAllQueuesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queues != null && message.hasOwnProperty("queues")) {
                        if (!Array.isArray(message.queues))
                            return "queues: array expected";
                        for (var i = 0; i < message.queues.length; ++i) {
                            var error = $root.infinitusai.be.Queue.verify(message.queues[i]);
                            if (error)
                                return "queues." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetAllQueuesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAllQueuesResponse} GetAllQueuesResponse
                 */
                GetAllQueuesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAllQueuesResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetAllQueuesResponse();
                    if (object.queues) {
                        if (!Array.isArray(object.queues))
                            throw TypeError(".infinitusai.be.GetAllQueuesResponse.queues: array expected");
                        message.queues = [];
                        for (var i = 0; i < object.queues.length; ++i) {
                            if (typeof object.queues[i] !== "object")
                                throw TypeError(".infinitusai.be.GetAllQueuesResponse.queues: object expected");
                            message.queues[i] = $root.infinitusai.be.Queue.fromObject(object.queues[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAllQueuesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {infinitusai.be.GetAllQueuesResponse} message GetAllQueuesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAllQueuesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.queues = [];
                    if (message.queues && message.queues.length) {
                        object.queues = [];
                        for (var j = 0; j < message.queues.length; ++j)
                            object.queues[j] = $root.infinitusai.be.Queue.toObject(message.queues[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetAllQueuesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAllQueuesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAllQueuesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAllQueuesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAllQueuesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAllQueuesResponse";
                };
    
                return GetAllQueuesResponse;
            })();
    
            be.GetQueueConfigRequest = (function() {
    
                /**
                 * Properties of a GetQueueConfigRequest.
                 * @memberof infinitusai.be
                 * @interface IGetQueueConfigRequest
                 * @property {string|null} [queueUuid] GetQueueConfigRequest queueUuid
                 */
    
                /**
                 * Constructs a new GetQueueConfigRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetQueueConfigRequest.
                 * @implements IGetQueueConfigRequest
                 * @constructor
                 * @param {infinitusai.be.IGetQueueConfigRequest=} [properties] Properties to set
                 */
                function GetQueueConfigRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetQueueConfigRequest queueUuid.
                 * @member {string} queueUuid
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @instance
                 */
                GetQueueConfigRequest.prototype.queueUuid = "";
    
                /**
                 * Creates a new GetQueueConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {infinitusai.be.IGetQueueConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetQueueConfigRequest} GetQueueConfigRequest instance
                 */
                GetQueueConfigRequest.create = function create(properties) {
                    return new GetQueueConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified GetQueueConfigRequest message. Does not implicitly {@link infinitusai.be.GetQueueConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {infinitusai.be.IGetQueueConfigRequest} message GetQueueConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetQueueConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queueUuid != null && Object.hasOwnProperty.call(message, "queueUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.queueUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetQueueConfigRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetQueueConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {infinitusai.be.IGetQueueConfigRequest} message GetQueueConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetQueueConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetQueueConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetQueueConfigRequest} GetQueueConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetQueueConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetQueueConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queueUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetQueueConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetQueueConfigRequest} GetQueueConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetQueueConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetQueueConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetQueueConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        if (!$util.isString(message.queueUuid))
                            return "queueUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetQueueConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetQueueConfigRequest} GetQueueConfigRequest
                 */
                GetQueueConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetQueueConfigRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetQueueConfigRequest();
                    if (object.queueUuid != null)
                        message.queueUuid = String(object.queueUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetQueueConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {infinitusai.be.GetQueueConfigRequest} message GetQueueConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetQueueConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queueUuid = "";
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        object.queueUuid = message.queueUuid;
                    return object;
                };
    
                /**
                 * Converts this GetQueueConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetQueueConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetQueueConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetQueueConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetQueueConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetQueueConfigRequest";
                };
    
                return GetQueueConfigRequest;
            })();
    
            be.GetQueueConfigResponse = (function() {
    
                /**
                 * Properties of a GetQueueConfigResponse.
                 * @memberof infinitusai.be
                 * @interface IGetQueueConfigResponse
                 * @property {infinitusai.be.IUnifiedQueueConfig|null} [queueConfig] GetQueueConfigResponse queueConfig
                 */
    
                /**
                 * Constructs a new GetQueueConfigResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetQueueConfigResponse.
                 * @implements IGetQueueConfigResponse
                 * @constructor
                 * @param {infinitusai.be.IGetQueueConfigResponse=} [properties] Properties to set
                 */
                function GetQueueConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetQueueConfigResponse queueConfig.
                 * @member {infinitusai.be.IUnifiedQueueConfig|null|undefined} queueConfig
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @instance
                 */
                GetQueueConfigResponse.prototype.queueConfig = null;
    
                /**
                 * Creates a new GetQueueConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {infinitusai.be.IGetQueueConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetQueueConfigResponse} GetQueueConfigResponse instance
                 */
                GetQueueConfigResponse.create = function create(properties) {
                    return new GetQueueConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified GetQueueConfigResponse message. Does not implicitly {@link infinitusai.be.GetQueueConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {infinitusai.be.IGetQueueConfigResponse} message GetQueueConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetQueueConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queueConfig != null && Object.hasOwnProperty.call(message, "queueConfig"))
                        $root.infinitusai.be.UnifiedQueueConfig.encode(message.queueConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetQueueConfigResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetQueueConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {infinitusai.be.IGetQueueConfigResponse} message GetQueueConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetQueueConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetQueueConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetQueueConfigResponse} GetQueueConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetQueueConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetQueueConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queueConfig = $root.infinitusai.be.UnifiedQueueConfig.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetQueueConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetQueueConfigResponse} GetQueueConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetQueueConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetQueueConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetQueueConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queueConfig != null && message.hasOwnProperty("queueConfig")) {
                        var error = $root.infinitusai.be.UnifiedQueueConfig.verify(message.queueConfig);
                        if (error)
                            return "queueConfig." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetQueueConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetQueueConfigResponse} GetQueueConfigResponse
                 */
                GetQueueConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetQueueConfigResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetQueueConfigResponse();
                    if (object.queueConfig != null) {
                        if (typeof object.queueConfig !== "object")
                            throw TypeError(".infinitusai.be.GetQueueConfigResponse.queueConfig: object expected");
                        message.queueConfig = $root.infinitusai.be.UnifiedQueueConfig.fromObject(object.queueConfig);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetQueueConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {infinitusai.be.GetQueueConfigResponse} message GetQueueConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetQueueConfigResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queueConfig = null;
                    if (message.queueConfig != null && message.hasOwnProperty("queueConfig"))
                        object.queueConfig = $root.infinitusai.be.UnifiedQueueConfig.toObject(message.queueConfig, options);
                    return object;
                };
    
                /**
                 * Converts this GetQueueConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetQueueConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetQueueConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetQueueConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetQueueConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetQueueConfigResponse";
                };
    
                return GetQueueConfigResponse;
            })();
    
            be.DeleteQueueRequest = (function() {
    
                /**
                 * Properties of a DeleteQueueRequest.
                 * @memberof infinitusai.be
                 * @interface IDeleteQueueRequest
                 * @property {string|null} [queueUuid] DeleteQueueRequest queueUuid
                 */
    
                /**
                 * Constructs a new DeleteQueueRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteQueueRequest.
                 * @implements IDeleteQueueRequest
                 * @constructor
                 * @param {infinitusai.be.IDeleteQueueRequest=} [properties] Properties to set
                 */
                function DeleteQueueRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteQueueRequest queueUuid.
                 * @member {string} queueUuid
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @instance
                 */
                DeleteQueueRequest.prototype.queueUuid = "";
    
                /**
                 * Creates a new DeleteQueueRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {infinitusai.be.IDeleteQueueRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteQueueRequest} DeleteQueueRequest instance
                 */
                DeleteQueueRequest.create = function create(properties) {
                    return new DeleteQueueRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteQueueRequest message. Does not implicitly {@link infinitusai.be.DeleteQueueRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {infinitusai.be.IDeleteQueueRequest} message DeleteQueueRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteQueueRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.queueUuid != null && Object.hasOwnProperty.call(message, "queueUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.queueUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteQueueRequest message, length delimited. Does not implicitly {@link infinitusai.be.DeleteQueueRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {infinitusai.be.IDeleteQueueRequest} message DeleteQueueRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteQueueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteQueueRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteQueueRequest} DeleteQueueRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteQueueRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteQueueRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.queueUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteQueueRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteQueueRequest} DeleteQueueRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteQueueRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteQueueRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteQueueRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        if (!$util.isString(message.queueUuid))
                            return "queueUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteQueueRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteQueueRequest} DeleteQueueRequest
                 */
                DeleteQueueRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteQueueRequest)
                        return object;
                    var message = new $root.infinitusai.be.DeleteQueueRequest();
                    if (object.queueUuid != null)
                        message.queueUuid = String(object.queueUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteQueueRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {infinitusai.be.DeleteQueueRequest} message DeleteQueueRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteQueueRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.queueUuid = "";
                    if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                        object.queueUuid = message.queueUuid;
                    return object;
                };
    
                /**
                 * Converts this DeleteQueueRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteQueueRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteQueueRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteQueueRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteQueueRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteQueueRequest";
                };
    
                return DeleteQueueRequest;
            })();
    
            be.ReportCallFeedbackRequest = (function() {
    
                /**
                 * Properties of a ReportCallFeedbackRequest.
                 * @memberof infinitusai.be
                 * @interface IReportCallFeedbackRequest
                 * @property {string|null} [taskUuid] ReportCallFeedbackRequest taskUuid
                 * @property {string|null} [callUuid] ReportCallFeedbackRequest callUuid
                 * @property {boolean|null} [isPositive] ReportCallFeedbackRequest isPositive
                 * @property {string|null} [msg] ReportCallFeedbackRequest msg
                 */
    
                /**
                 * Constructs a new ReportCallFeedbackRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReportCallFeedbackRequest.
                 * @implements IReportCallFeedbackRequest
                 * @constructor
                 * @param {infinitusai.be.IReportCallFeedbackRequest=} [properties] Properties to set
                 */
                function ReportCallFeedbackRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ReportCallFeedbackRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @instance
                 */
                ReportCallFeedbackRequest.prototype.taskUuid = "";
    
                /**
                 * ReportCallFeedbackRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @instance
                 */
                ReportCallFeedbackRequest.prototype.callUuid = "";
    
                /**
                 * ReportCallFeedbackRequest isPositive.
                 * @member {boolean} isPositive
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @instance
                 */
                ReportCallFeedbackRequest.prototype.isPositive = false;
    
                /**
                 * ReportCallFeedbackRequest msg.
                 * @member {string} msg
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @instance
                 */
                ReportCallFeedbackRequest.prototype.msg = "";
    
                /**
                 * Creates a new ReportCallFeedbackRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {infinitusai.be.IReportCallFeedbackRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ReportCallFeedbackRequest} ReportCallFeedbackRequest instance
                 */
                ReportCallFeedbackRequest.create = function create(properties) {
                    return new ReportCallFeedbackRequest(properties);
                };
    
                /**
                 * Encodes the specified ReportCallFeedbackRequest message. Does not implicitly {@link infinitusai.be.ReportCallFeedbackRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {infinitusai.be.IReportCallFeedbackRequest} message ReportCallFeedbackRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportCallFeedbackRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.isPositive != null && Object.hasOwnProperty.call(message, "isPositive"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isPositive);
                    if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.msg);
                    return writer;
                };
    
                /**
                 * Encodes the specified ReportCallFeedbackRequest message, length delimited. Does not implicitly {@link infinitusai.be.ReportCallFeedbackRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {infinitusai.be.IReportCallFeedbackRequest} message ReportCallFeedbackRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportCallFeedbackRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReportCallFeedbackRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReportCallFeedbackRequest} ReportCallFeedbackRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportCallFeedbackRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReportCallFeedbackRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.isPositive = reader.bool();
                                break;
                            }
                        case 4: {
                                message.msg = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReportCallFeedbackRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReportCallFeedbackRequest} ReportCallFeedbackRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportCallFeedbackRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReportCallFeedbackRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportCallFeedbackRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.isPositive != null && message.hasOwnProperty("isPositive"))
                        if (typeof message.isPositive !== "boolean")
                            return "isPositive: boolean expected";
                    if (message.msg != null && message.hasOwnProperty("msg"))
                        if (!$util.isString(message.msg))
                            return "msg: string expected";
                    return null;
                };
    
                /**
                 * Creates a ReportCallFeedbackRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReportCallFeedbackRequest} ReportCallFeedbackRequest
                 */
                ReportCallFeedbackRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReportCallFeedbackRequest)
                        return object;
                    var message = new $root.infinitusai.be.ReportCallFeedbackRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.isPositive != null)
                        message.isPositive = Boolean(object.isPositive);
                    if (object.msg != null)
                        message.msg = String(object.msg);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ReportCallFeedbackRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {infinitusai.be.ReportCallFeedbackRequest} message ReportCallFeedbackRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportCallFeedbackRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.isPositive = false;
                        object.msg = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.isPositive != null && message.hasOwnProperty("isPositive"))
                        object.isPositive = message.isPositive;
                    if (message.msg != null && message.hasOwnProperty("msg"))
                        object.msg = message.msg;
                    return object;
                };
    
                /**
                 * Converts this ReportCallFeedbackRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportCallFeedbackRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReportCallFeedbackRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReportCallFeedbackRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReportCallFeedbackRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReportCallFeedbackRequest";
                };
    
                return ReportCallFeedbackRequest;
            })();
    
            be.ReportCallFeedbackResponse = (function() {
    
                /**
                 * Properties of a ReportCallFeedbackResponse.
                 * @memberof infinitusai.be
                 * @interface IReportCallFeedbackResponse
                 */
    
                /**
                 * Constructs a new ReportCallFeedbackResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReportCallFeedbackResponse.
                 * @implements IReportCallFeedbackResponse
                 * @constructor
                 * @param {infinitusai.be.IReportCallFeedbackResponse=} [properties] Properties to set
                 */
                function ReportCallFeedbackResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new ReportCallFeedbackResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {infinitusai.be.IReportCallFeedbackResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ReportCallFeedbackResponse} ReportCallFeedbackResponse instance
                 */
                ReportCallFeedbackResponse.create = function create(properties) {
                    return new ReportCallFeedbackResponse(properties);
                };
    
                /**
                 * Encodes the specified ReportCallFeedbackResponse message. Does not implicitly {@link infinitusai.be.ReportCallFeedbackResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {infinitusai.be.IReportCallFeedbackResponse} message ReportCallFeedbackResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportCallFeedbackResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified ReportCallFeedbackResponse message, length delimited. Does not implicitly {@link infinitusai.be.ReportCallFeedbackResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {infinitusai.be.IReportCallFeedbackResponse} message ReportCallFeedbackResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportCallFeedbackResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ReportCallFeedbackResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ReportCallFeedbackResponse} ReportCallFeedbackResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportCallFeedbackResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ReportCallFeedbackResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ReportCallFeedbackResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ReportCallFeedbackResponse} ReportCallFeedbackResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportCallFeedbackResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ReportCallFeedbackResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportCallFeedbackResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a ReportCallFeedbackResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ReportCallFeedbackResponse} ReportCallFeedbackResponse
                 */
                ReportCallFeedbackResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ReportCallFeedbackResponse)
                        return object;
                    return new $root.infinitusai.be.ReportCallFeedbackResponse();
                };
    
                /**
                 * Creates a plain object from a ReportCallFeedbackResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {infinitusai.be.ReportCallFeedbackResponse} message ReportCallFeedbackResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportCallFeedbackResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this ReportCallFeedbackResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportCallFeedbackResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ReportCallFeedbackResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ReportCallFeedbackResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReportCallFeedbackResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ReportCallFeedbackResponse";
                };
    
                return ReportCallFeedbackResponse;
            })();
    
            be.ReviewService = (function() {
    
                /**
                 * Constructs a new ReviewService service.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ReviewService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ReviewService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (ReviewService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ReviewService;
    
                /**
                 * Creates new ReviewService service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.ReviewService
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ReviewService} RPC service. Useful where requests and/or responses are streamed.
                 */
                ReviewService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.ReviewService#claimReview}.
                 * @memberof infinitusai.be.ReviewService
                 * @typedef ClaimReviewCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.ClaimReviewResponse} [response] ClaimReviewResponse
                 */
    
                /**
                 * Calls ClaimReview.
                 * @function claimReview
                 * @memberof infinitusai.be.ReviewService
                 * @instance
                 * @param {infinitusai.be.IClaimReviewRequest} request ClaimReviewRequest message or plain object
                 * @param {infinitusai.be.ReviewService.ClaimReviewCallback} callback Node-style callback called with the error, if any, and ClaimReviewResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ReviewService.prototype.claimReview = function claimReview(request, callback) {
                    return this.rpcCall(claimReview, $root.infinitusai.be.ClaimReviewRequest, $root.infinitusai.be.ClaimReviewResponse, request, callback);
                }, "name", { value: "ClaimReview" });
    
                /**
                 * Calls ClaimReview.
                 * @function claimReview
                 * @memberof infinitusai.be.ReviewService
                 * @instance
                 * @param {infinitusai.be.IClaimReviewRequest} request ClaimReviewRequest message or plain object
                 * @returns {Promise<infinitusai.be.ClaimReviewResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ReviewService#completeReview}.
                 * @memberof infinitusai.be.ReviewService
                 * @typedef CompleteReviewCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.CompleteReviewResponse} [response] CompleteReviewResponse
                 */
    
                /**
                 * Calls CompleteReview.
                 * @function completeReview
                 * @memberof infinitusai.be.ReviewService
                 * @instance
                 * @param {infinitusai.be.ICompleteReviewRequest} request CompleteReviewRequest message or plain object
                 * @param {infinitusai.be.ReviewService.CompleteReviewCallback} callback Node-style callback called with the error, if any, and CompleteReviewResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ReviewService.prototype.completeReview = function completeReview(request, callback) {
                    return this.rpcCall(completeReview, $root.infinitusai.be.CompleteReviewRequest, $root.infinitusai.be.CompleteReviewResponse, request, callback);
                }, "name", { value: "CompleteReview" });
    
                /**
                 * Calls CompleteReview.
                 * @function completeReview
                 * @memberof infinitusai.be.ReviewService
                 * @instance
                 * @param {infinitusai.be.ICompleteReviewRequest} request CompleteReviewRequest message or plain object
                 * @returns {Promise<infinitusai.be.CompleteReviewResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.ReviewService#relinquishReview}.
                 * @memberof infinitusai.be.ReviewService
                 * @typedef RelinquishReviewCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.RelinquishReviewResponse} [response] RelinquishReviewResponse
                 */
    
                /**
                 * Calls RelinquishReview.
                 * @function relinquishReview
                 * @memberof infinitusai.be.ReviewService
                 * @instance
                 * @param {infinitusai.be.IRelinquishReviewRequest} request RelinquishReviewRequest message or plain object
                 * @param {infinitusai.be.ReviewService.RelinquishReviewCallback} callback Node-style callback called with the error, if any, and RelinquishReviewResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ReviewService.prototype.relinquishReview = function relinquishReview(request, callback) {
                    return this.rpcCall(relinquishReview, $root.infinitusai.be.RelinquishReviewRequest, $root.infinitusai.be.RelinquishReviewResponse, request, callback);
                }, "name", { value: "RelinquishReview" });
    
                /**
                 * Calls RelinquishReview.
                 * @function relinquishReview
                 * @memberof infinitusai.be.ReviewService
                 * @instance
                 * @param {infinitusai.be.IRelinquishReviewRequest} request RelinquishReviewRequest message or plain object
                 * @returns {Promise<infinitusai.be.RelinquishReviewResponse>} Promise
                 * @variation 2
                 */
    
                return ReviewService;
            })();
    
            be.CreateROIInputRequest = (function() {
    
                /**
                 * Properties of a CreateROIInputRequest.
                 * @memberof infinitusai.be
                 * @interface ICreateROIInputRequest
                 * @property {infinitusai.be.TaskType|null} [taskType] CreateROIInputRequest taskType
                 * @property {number|null} [numCallsToResolution] CreateROIInputRequest numCallsToResolution
                 * @property {number|null} [getInfoMinutes] CreateROIInputRequest getInfoMinutes
                 * @property {number|null} [personAuthMinutes] CreateROIInputRequest personAuthMinutes
                 * @property {string|null} [orgUuid] CreateROIInputRequest orgUuid
                 */
    
                /**
                 * Constructs a new CreateROIInputRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateROIInputRequest.
                 * @implements ICreateROIInputRequest
                 * @constructor
                 * @param {infinitusai.be.ICreateROIInputRequest=} [properties] Properties to set
                 */
                function CreateROIInputRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateROIInputRequest taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @instance
                 */
                CreateROIInputRequest.prototype.taskType = 0;
    
                /**
                 * CreateROIInputRequest numCallsToResolution.
                 * @member {number} numCallsToResolution
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @instance
                 */
                CreateROIInputRequest.prototype.numCallsToResolution = 0;
    
                /**
                 * CreateROIInputRequest getInfoMinutes.
                 * @member {number} getInfoMinutes
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @instance
                 */
                CreateROIInputRequest.prototype.getInfoMinutes = 0;
    
                /**
                 * CreateROIInputRequest personAuthMinutes.
                 * @member {number} personAuthMinutes
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @instance
                 */
                CreateROIInputRequest.prototype.personAuthMinutes = 0;
    
                /**
                 * CreateROIInputRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @instance
                 */
                CreateROIInputRequest.prototype.orgUuid = "";
    
                /**
                 * Creates a new CreateROIInputRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {infinitusai.be.ICreateROIInputRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateROIInputRequest} CreateROIInputRequest instance
                 */
                CreateROIInputRequest.create = function create(properties) {
                    return new CreateROIInputRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateROIInputRequest message. Does not implicitly {@link infinitusai.be.CreateROIInputRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {infinitusai.be.ICreateROIInputRequest} message CreateROIInputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateROIInputRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.taskType);
                    if (message.numCallsToResolution != null && Object.hasOwnProperty.call(message, "numCallsToResolution"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.numCallsToResolution);
                    if (message.getInfoMinutes != null && Object.hasOwnProperty.call(message, "getInfoMinutes"))
                        writer.uint32(/* id 3, wireType 5 =*/29).float(message.getInfoMinutes);
                    if (message.personAuthMinutes != null && Object.hasOwnProperty.call(message, "personAuthMinutes"))
                        writer.uint32(/* id 4, wireType 5 =*/37).float(message.personAuthMinutes);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateROIInputRequest message, length delimited. Does not implicitly {@link infinitusai.be.CreateROIInputRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {infinitusai.be.ICreateROIInputRequest} message CreateROIInputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateROIInputRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateROIInputRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateROIInputRequest} CreateROIInputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateROIInputRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateROIInputRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.numCallsToResolution = reader.float();
                                break;
                            }
                        case 3: {
                                message.getInfoMinutes = reader.float();
                                break;
                            }
                        case 4: {
                                message.personAuthMinutes = reader.float();
                                break;
                            }
                        case 5: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateROIInputRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateROIInputRequest} CreateROIInputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateROIInputRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateROIInputRequest message.
                 * @function verify
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateROIInputRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.numCallsToResolution != null && message.hasOwnProperty("numCallsToResolution"))
                        if (typeof message.numCallsToResolution !== "number")
                            return "numCallsToResolution: number expected";
                    if (message.getInfoMinutes != null && message.hasOwnProperty("getInfoMinutes"))
                        if (typeof message.getInfoMinutes !== "number")
                            return "getInfoMinutes: number expected";
                    if (message.personAuthMinutes != null && message.hasOwnProperty("personAuthMinutes"))
                        if (typeof message.personAuthMinutes !== "number")
                            return "personAuthMinutes: number expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateROIInputRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateROIInputRequest} CreateROIInputRequest
                 */
                CreateROIInputRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateROIInputRequest)
                        return object;
                    var message = new $root.infinitusai.be.CreateROIInputRequest();
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.numCallsToResolution != null)
                        message.numCallsToResolution = Number(object.numCallsToResolution);
                    if (object.getInfoMinutes != null)
                        message.getInfoMinutes = Number(object.getInfoMinutes);
                    if (object.personAuthMinutes != null)
                        message.personAuthMinutes = Number(object.personAuthMinutes);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateROIInputRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {infinitusai.be.CreateROIInputRequest} message CreateROIInputRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateROIInputRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.numCallsToResolution = 0;
                        object.getInfoMinutes = 0;
                        object.personAuthMinutes = 0;
                        object.orgUuid = "";
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.numCallsToResolution != null && message.hasOwnProperty("numCallsToResolution"))
                        object.numCallsToResolution = options.json && !isFinite(message.numCallsToResolution) ? String(message.numCallsToResolution) : message.numCallsToResolution;
                    if (message.getInfoMinutes != null && message.hasOwnProperty("getInfoMinutes"))
                        object.getInfoMinutes = options.json && !isFinite(message.getInfoMinutes) ? String(message.getInfoMinutes) : message.getInfoMinutes;
                    if (message.personAuthMinutes != null && message.hasOwnProperty("personAuthMinutes"))
                        object.personAuthMinutes = options.json && !isFinite(message.personAuthMinutes) ? String(message.personAuthMinutes) : message.personAuthMinutes;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this CreateROIInputRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateROIInputRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateROIInputRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateROIInputRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateROIInputRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateROIInputRequest";
                };
    
                return CreateROIInputRequest;
            })();
    
            be.CreateROIInputResponse = (function() {
    
                /**
                 * Properties of a CreateROIInputResponse.
                 * @memberof infinitusai.be
                 * @interface ICreateROIInputResponse
                 */
    
                /**
                 * Constructs a new CreateROIInputResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a CreateROIInputResponse.
                 * @implements ICreateROIInputResponse
                 * @constructor
                 * @param {infinitusai.be.ICreateROIInputResponse=} [properties] Properties to set
                 */
                function CreateROIInputResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CreateROIInputResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {infinitusai.be.ICreateROIInputResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.CreateROIInputResponse} CreateROIInputResponse instance
                 */
                CreateROIInputResponse.create = function create(properties) {
                    return new CreateROIInputResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateROIInputResponse message. Does not implicitly {@link infinitusai.be.CreateROIInputResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {infinitusai.be.ICreateROIInputResponse} message CreateROIInputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateROIInputResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateROIInputResponse message, length delimited. Does not implicitly {@link infinitusai.be.CreateROIInputResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {infinitusai.be.ICreateROIInputResponse} message CreateROIInputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateROIInputResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateROIInputResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.CreateROIInputResponse} CreateROIInputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateROIInputResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.CreateROIInputResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateROIInputResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.CreateROIInputResponse} CreateROIInputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateROIInputResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateROIInputResponse message.
                 * @function verify
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateROIInputResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CreateROIInputResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.CreateROIInputResponse} CreateROIInputResponse
                 */
                CreateROIInputResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.CreateROIInputResponse)
                        return object;
                    return new $root.infinitusai.be.CreateROIInputResponse();
                };
    
                /**
                 * Creates a plain object from a CreateROIInputResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {infinitusai.be.CreateROIInputResponse} message CreateROIInputResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateROIInputResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CreateROIInputResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateROIInputResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateROIInputResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.CreateROIInputResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateROIInputResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.CreateROIInputResponse";
                };
    
                return CreateROIInputResponse;
            })();
    
            be.GetROIInputsRequest = (function() {
    
                /**
                 * Properties of a GetROIInputsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetROIInputsRequest
                 * @property {string|null} [orgUuid] GetROIInputsRequest orgUuid
                 */
    
                /**
                 * Constructs a new GetROIInputsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetROIInputsRequest.
                 * @implements IGetROIInputsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetROIInputsRequest=} [properties] Properties to set
                 */
                function GetROIInputsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetROIInputsRequest orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @instance
                 */
                GetROIInputsRequest.prototype.orgUuid = "";
    
                /**
                 * Creates a new GetROIInputsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {infinitusai.be.IGetROIInputsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetROIInputsRequest} GetROIInputsRequest instance
                 */
                GetROIInputsRequest.create = function create(properties) {
                    return new GetROIInputsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetROIInputsRequest message. Does not implicitly {@link infinitusai.be.GetROIInputsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {infinitusai.be.IGetROIInputsRequest} message GetROIInputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetROIInputsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetROIInputsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetROIInputsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {infinitusai.be.IGetROIInputsRequest} message GetROIInputsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetROIInputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetROIInputsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetROIInputsRequest} GetROIInputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetROIInputsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetROIInputsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetROIInputsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetROIInputsRequest} GetROIInputsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetROIInputsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetROIInputsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetROIInputsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetROIInputsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetROIInputsRequest} GetROIInputsRequest
                 */
                GetROIInputsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetROIInputsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetROIInputsRequest();
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetROIInputsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {infinitusai.be.GetROIInputsRequest} message GetROIInputsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetROIInputsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.orgUuid = "";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this GetROIInputsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetROIInputsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetROIInputsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetROIInputsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetROIInputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetROIInputsRequest";
                };
    
                return GetROIInputsRequest;
            })();
    
            be.GetROIInputsResponse = (function() {
    
                /**
                 * Properties of a GetROIInputsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetROIInputsResponse
                 * @property {Array.<infinitusai.be.IROIInput>|null} [roiInputs] GetROIInputsResponse roiInputs
                 */
    
                /**
                 * Constructs a new GetROIInputsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetROIInputsResponse.
                 * @implements IGetROIInputsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetROIInputsResponse=} [properties] Properties to set
                 */
                function GetROIInputsResponse(properties) {
                    this.roiInputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetROIInputsResponse roiInputs.
                 * @member {Array.<infinitusai.be.IROIInput>} roiInputs
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @instance
                 */
                GetROIInputsResponse.prototype.roiInputs = $util.emptyArray;
    
                /**
                 * Creates a new GetROIInputsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {infinitusai.be.IGetROIInputsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetROIInputsResponse} GetROIInputsResponse instance
                 */
                GetROIInputsResponse.create = function create(properties) {
                    return new GetROIInputsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetROIInputsResponse message. Does not implicitly {@link infinitusai.be.GetROIInputsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {infinitusai.be.IGetROIInputsResponse} message GetROIInputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetROIInputsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roiInputs != null && message.roiInputs.length)
                        for (var i = 0; i < message.roiInputs.length; ++i)
                            $root.infinitusai.be.ROIInput.encode(message.roiInputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetROIInputsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetROIInputsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {infinitusai.be.IGetROIInputsResponse} message GetROIInputsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetROIInputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetROIInputsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetROIInputsResponse} GetROIInputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetROIInputsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetROIInputsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.roiInputs && message.roiInputs.length))
                                    message.roiInputs = [];
                                message.roiInputs.push($root.infinitusai.be.ROIInput.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetROIInputsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetROIInputsResponse} GetROIInputsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetROIInputsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetROIInputsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetROIInputsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roiInputs != null && message.hasOwnProperty("roiInputs")) {
                        if (!Array.isArray(message.roiInputs))
                            return "roiInputs: array expected";
                        for (var i = 0; i < message.roiInputs.length; ++i) {
                            var error = $root.infinitusai.be.ROIInput.verify(message.roiInputs[i]);
                            if (error)
                                return "roiInputs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetROIInputsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetROIInputsResponse} GetROIInputsResponse
                 */
                GetROIInputsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetROIInputsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetROIInputsResponse();
                    if (object.roiInputs) {
                        if (!Array.isArray(object.roiInputs))
                            throw TypeError(".infinitusai.be.GetROIInputsResponse.roiInputs: array expected");
                        message.roiInputs = [];
                        for (var i = 0; i < object.roiInputs.length; ++i) {
                            if (typeof object.roiInputs[i] !== "object")
                                throw TypeError(".infinitusai.be.GetROIInputsResponse.roiInputs: object expected");
                            message.roiInputs[i] = $root.infinitusai.be.ROIInput.fromObject(object.roiInputs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetROIInputsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {infinitusai.be.GetROIInputsResponse} message GetROIInputsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetROIInputsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.roiInputs = [];
                    if (message.roiInputs && message.roiInputs.length) {
                        object.roiInputs = [];
                        for (var j = 0; j < message.roiInputs.length; ++j)
                            object.roiInputs[j] = $root.infinitusai.be.ROIInput.toObject(message.roiInputs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetROIInputsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetROIInputsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetROIInputsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetROIInputsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetROIInputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetROIInputsResponse";
                };
    
                return GetROIInputsResponse;
            })();
    
            be.GetROIInputRequest = (function() {
    
                /**
                 * Properties of a GetROIInputRequest.
                 * @memberof infinitusai.be
                 * @interface IGetROIInputRequest
                 * @property {number|null} [uid] GetROIInputRequest uid
                 */
    
                /**
                 * Constructs a new GetROIInputRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetROIInputRequest.
                 * @implements IGetROIInputRequest
                 * @constructor
                 * @param {infinitusai.be.IGetROIInputRequest=} [properties] Properties to set
                 */
                function GetROIInputRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetROIInputRequest uid.
                 * @member {number} uid
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @instance
                 */
                GetROIInputRequest.prototype.uid = 0;
    
                /**
                 * Creates a new GetROIInputRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {infinitusai.be.IGetROIInputRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetROIInputRequest} GetROIInputRequest instance
                 */
                GetROIInputRequest.create = function create(properties) {
                    return new GetROIInputRequest(properties);
                };
    
                /**
                 * Encodes the specified GetROIInputRequest message. Does not implicitly {@link infinitusai.be.GetROIInputRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {infinitusai.be.IGetROIInputRequest} message GetROIInputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetROIInputRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.uid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetROIInputRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetROIInputRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {infinitusai.be.IGetROIInputRequest} message GetROIInputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetROIInputRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetROIInputRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetROIInputRequest} GetROIInputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetROIInputRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetROIInputRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uid = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetROIInputRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetROIInputRequest} GetROIInputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetROIInputRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetROIInputRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetROIInputRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uid != null && message.hasOwnProperty("uid"))
                        if (!$util.isInteger(message.uid))
                            return "uid: integer expected";
                    return null;
                };
    
                /**
                 * Creates a GetROIInputRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetROIInputRequest} GetROIInputRequest
                 */
                GetROIInputRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetROIInputRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetROIInputRequest();
                    if (object.uid != null)
                        message.uid = object.uid | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetROIInputRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {infinitusai.be.GetROIInputRequest} message GetROIInputRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetROIInputRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uid = 0;
                    if (message.uid != null && message.hasOwnProperty("uid"))
                        object.uid = message.uid;
                    return object;
                };
    
                /**
                 * Converts this GetROIInputRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetROIInputRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetROIInputRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetROIInputRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetROIInputRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetROIInputRequest";
                };
    
                return GetROIInputRequest;
            })();
    
            be.GetROIInputResponse = (function() {
    
                /**
                 * Properties of a GetROIInputResponse.
                 * @memberof infinitusai.be
                 * @interface IGetROIInputResponse
                 * @property {infinitusai.be.IROIInput|null} [roiInput] GetROIInputResponse roiInput
                 */
    
                /**
                 * Constructs a new GetROIInputResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetROIInputResponse.
                 * @implements IGetROIInputResponse
                 * @constructor
                 * @param {infinitusai.be.IGetROIInputResponse=} [properties] Properties to set
                 */
                function GetROIInputResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetROIInputResponse roiInput.
                 * @member {infinitusai.be.IROIInput|null|undefined} roiInput
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @instance
                 */
                GetROIInputResponse.prototype.roiInput = null;
    
                /**
                 * Creates a new GetROIInputResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {infinitusai.be.IGetROIInputResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetROIInputResponse} GetROIInputResponse instance
                 */
                GetROIInputResponse.create = function create(properties) {
                    return new GetROIInputResponse(properties);
                };
    
                /**
                 * Encodes the specified GetROIInputResponse message. Does not implicitly {@link infinitusai.be.GetROIInputResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {infinitusai.be.IGetROIInputResponse} message GetROIInputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetROIInputResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roiInput != null && Object.hasOwnProperty.call(message, "roiInput"))
                        $root.infinitusai.be.ROIInput.encode(message.roiInput, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetROIInputResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetROIInputResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {infinitusai.be.IGetROIInputResponse} message GetROIInputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetROIInputResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetROIInputResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetROIInputResponse} GetROIInputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetROIInputResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetROIInputResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roiInput = $root.infinitusai.be.ROIInput.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetROIInputResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetROIInputResponse} GetROIInputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetROIInputResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetROIInputResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetROIInputResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roiInput != null && message.hasOwnProperty("roiInput")) {
                        var error = $root.infinitusai.be.ROIInput.verify(message.roiInput);
                        if (error)
                            return "roiInput." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetROIInputResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetROIInputResponse} GetROIInputResponse
                 */
                GetROIInputResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetROIInputResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetROIInputResponse();
                    if (object.roiInput != null) {
                        if (typeof object.roiInput !== "object")
                            throw TypeError(".infinitusai.be.GetROIInputResponse.roiInput: object expected");
                        message.roiInput = $root.infinitusai.be.ROIInput.fromObject(object.roiInput);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetROIInputResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {infinitusai.be.GetROIInputResponse} message GetROIInputResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetROIInputResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.roiInput = null;
                    if (message.roiInput != null && message.hasOwnProperty("roiInput"))
                        object.roiInput = $root.infinitusai.be.ROIInput.toObject(message.roiInput, options);
                    return object;
                };
    
                /**
                 * Converts this GetROIInputResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetROIInputResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetROIInputResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetROIInputResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetROIInputResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetROIInputResponse";
                };
    
                return GetROIInputResponse;
            })();
    
            be.UpdateROIInputRequest = (function() {
    
                /**
                 * Properties of an UpdateROIInputRequest.
                 * @memberof infinitusai.be
                 * @interface IUpdateROIInputRequest
                 * @property {infinitusai.be.IROIInput|null} [roiInput] UpdateROIInputRequest roiInput
                 */
    
                /**
                 * Constructs a new UpdateROIInputRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateROIInputRequest.
                 * @implements IUpdateROIInputRequest
                 * @constructor
                 * @param {infinitusai.be.IUpdateROIInputRequest=} [properties] Properties to set
                 */
                function UpdateROIInputRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateROIInputRequest roiInput.
                 * @member {infinitusai.be.IROIInput|null|undefined} roiInput
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @instance
                 */
                UpdateROIInputRequest.prototype.roiInput = null;
    
                /**
                 * Creates a new UpdateROIInputRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {infinitusai.be.IUpdateROIInputRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateROIInputRequest} UpdateROIInputRequest instance
                 */
                UpdateROIInputRequest.create = function create(properties) {
                    return new UpdateROIInputRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateROIInputRequest message. Does not implicitly {@link infinitusai.be.UpdateROIInputRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {infinitusai.be.IUpdateROIInputRequest} message UpdateROIInputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateROIInputRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roiInput != null && Object.hasOwnProperty.call(message, "roiInput"))
                        $root.infinitusai.be.ROIInput.encode(message.roiInput, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateROIInputRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpdateROIInputRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {infinitusai.be.IUpdateROIInputRequest} message UpdateROIInputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateROIInputRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateROIInputRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateROIInputRequest} UpdateROIInputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateROIInputRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateROIInputRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roiInput = $root.infinitusai.be.ROIInput.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateROIInputRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateROIInputRequest} UpdateROIInputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateROIInputRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateROIInputRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateROIInputRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roiInput != null && message.hasOwnProperty("roiInput")) {
                        var error = $root.infinitusai.be.ROIInput.verify(message.roiInput);
                        if (error)
                            return "roiInput." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateROIInputRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateROIInputRequest} UpdateROIInputRequest
                 */
                UpdateROIInputRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateROIInputRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpdateROIInputRequest();
                    if (object.roiInput != null) {
                        if (typeof object.roiInput !== "object")
                            throw TypeError(".infinitusai.be.UpdateROIInputRequest.roiInput: object expected");
                        message.roiInput = $root.infinitusai.be.ROIInput.fromObject(object.roiInput);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateROIInputRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {infinitusai.be.UpdateROIInputRequest} message UpdateROIInputRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateROIInputRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.roiInput = null;
                    if (message.roiInput != null && message.hasOwnProperty("roiInput"))
                        object.roiInput = $root.infinitusai.be.ROIInput.toObject(message.roiInput, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateROIInputRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateROIInputRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateROIInputRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateROIInputRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateROIInputRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateROIInputRequest";
                };
    
                return UpdateROIInputRequest;
            })();
    
            be.UpdateROIInputResponse = (function() {
    
                /**
                 * Properties of an UpdateROIInputResponse.
                 * @memberof infinitusai.be
                 * @interface IUpdateROIInputResponse
                 * @property {infinitusai.be.IROIInput|null} [roiInput] UpdateROIInputResponse roiInput
                 */
    
                /**
                 * Constructs a new UpdateROIInputResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpdateROIInputResponse.
                 * @implements IUpdateROIInputResponse
                 * @constructor
                 * @param {infinitusai.be.IUpdateROIInputResponse=} [properties] Properties to set
                 */
                function UpdateROIInputResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateROIInputResponse roiInput.
                 * @member {infinitusai.be.IROIInput|null|undefined} roiInput
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @instance
                 */
                UpdateROIInputResponse.prototype.roiInput = null;
    
                /**
                 * Creates a new UpdateROIInputResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {infinitusai.be.IUpdateROIInputResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpdateROIInputResponse} UpdateROIInputResponse instance
                 */
                UpdateROIInputResponse.create = function create(properties) {
                    return new UpdateROIInputResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateROIInputResponse message. Does not implicitly {@link infinitusai.be.UpdateROIInputResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {infinitusai.be.IUpdateROIInputResponse} message UpdateROIInputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateROIInputResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roiInput != null && Object.hasOwnProperty.call(message, "roiInput"))
                        $root.infinitusai.be.ROIInput.encode(message.roiInput, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateROIInputResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpdateROIInputResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {infinitusai.be.IUpdateROIInputResponse} message UpdateROIInputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateROIInputResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateROIInputResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpdateROIInputResponse} UpdateROIInputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateROIInputResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpdateROIInputResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roiInput = $root.infinitusai.be.ROIInput.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateROIInputResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpdateROIInputResponse} UpdateROIInputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateROIInputResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateROIInputResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateROIInputResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roiInput != null && message.hasOwnProperty("roiInput")) {
                        var error = $root.infinitusai.be.ROIInput.verify(message.roiInput);
                        if (error)
                            return "roiInput." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateROIInputResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpdateROIInputResponse} UpdateROIInputResponse
                 */
                UpdateROIInputResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpdateROIInputResponse)
                        return object;
                    var message = new $root.infinitusai.be.UpdateROIInputResponse();
                    if (object.roiInput != null) {
                        if (typeof object.roiInput !== "object")
                            throw TypeError(".infinitusai.be.UpdateROIInputResponse.roiInput: object expected");
                        message.roiInput = $root.infinitusai.be.ROIInput.fromObject(object.roiInput);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateROIInputResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {infinitusai.be.UpdateROIInputResponse} message UpdateROIInputResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateROIInputResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.roiInput = null;
                    if (message.roiInput != null && message.hasOwnProperty("roiInput"))
                        object.roiInput = $root.infinitusai.be.ROIInput.toObject(message.roiInput, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateROIInputResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateROIInputResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateROIInputResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpdateROIInputResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateROIInputResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpdateROIInputResponse";
                };
    
                return UpdateROIInputResponse;
            })();
    
            be.DeleteROIInputRequest = (function() {
    
                /**
                 * Properties of a DeleteROIInputRequest.
                 * @memberof infinitusai.be
                 * @interface IDeleteROIInputRequest
                 * @property {number|null} [id] DeleteROIInputRequest id
                 */
    
                /**
                 * Constructs a new DeleteROIInputRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteROIInputRequest.
                 * @implements IDeleteROIInputRequest
                 * @constructor
                 * @param {infinitusai.be.IDeleteROIInputRequest=} [properties] Properties to set
                 */
                function DeleteROIInputRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteROIInputRequest id.
                 * @member {number} id
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @instance
                 */
                DeleteROIInputRequest.prototype.id = 0;
    
                /**
                 * Creates a new DeleteROIInputRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {infinitusai.be.IDeleteROIInputRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteROIInputRequest} DeleteROIInputRequest instance
                 */
                DeleteROIInputRequest.create = function create(properties) {
                    return new DeleteROIInputRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteROIInputRequest message. Does not implicitly {@link infinitusai.be.DeleteROIInputRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {infinitusai.be.IDeleteROIInputRequest} message DeleteROIInputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteROIInputRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteROIInputRequest message, length delimited. Does not implicitly {@link infinitusai.be.DeleteROIInputRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {infinitusai.be.IDeleteROIInputRequest} message DeleteROIInputRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteROIInputRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteROIInputRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteROIInputRequest} DeleteROIInputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteROIInputRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteROIInputRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteROIInputRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteROIInputRequest} DeleteROIInputRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteROIInputRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteROIInputRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteROIInputRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteROIInputRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteROIInputRequest} DeleteROIInputRequest
                 */
                DeleteROIInputRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteROIInputRequest)
                        return object;
                    var message = new $root.infinitusai.be.DeleteROIInputRequest();
                    if (object.id != null)
                        message.id = object.id | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteROIInputRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {infinitusai.be.DeleteROIInputRequest} message DeleteROIInputRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteROIInputRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.id = 0;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };
    
                /**
                 * Converts this DeleteROIInputRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteROIInputRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteROIInputRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteROIInputRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteROIInputRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteROIInputRequest";
                };
    
                return DeleteROIInputRequest;
            })();
    
            be.DeleteROIInputResponse = (function() {
    
                /**
                 * Properties of a DeleteROIInputResponse.
                 * @memberof infinitusai.be
                 * @interface IDeleteROIInputResponse
                 */
    
                /**
                 * Constructs a new DeleteROIInputResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteROIInputResponse.
                 * @implements IDeleteROIInputResponse
                 * @constructor
                 * @param {infinitusai.be.IDeleteROIInputResponse=} [properties] Properties to set
                 */
                function DeleteROIInputResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteROIInputResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {infinitusai.be.IDeleteROIInputResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteROIInputResponse} DeleteROIInputResponse instance
                 */
                DeleteROIInputResponse.create = function create(properties) {
                    return new DeleteROIInputResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteROIInputResponse message. Does not implicitly {@link infinitusai.be.DeleteROIInputResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {infinitusai.be.IDeleteROIInputResponse} message DeleteROIInputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteROIInputResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteROIInputResponse message, length delimited. Does not implicitly {@link infinitusai.be.DeleteROIInputResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {infinitusai.be.IDeleteROIInputResponse} message DeleteROIInputResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteROIInputResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteROIInputResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteROIInputResponse} DeleteROIInputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteROIInputResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteROIInputResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteROIInputResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteROIInputResponse} DeleteROIInputResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteROIInputResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteROIInputResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteROIInputResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteROIInputResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteROIInputResponse} DeleteROIInputResponse
                 */
                DeleteROIInputResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteROIInputResponse)
                        return object;
                    return new $root.infinitusai.be.DeleteROIInputResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteROIInputResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {infinitusai.be.DeleteROIInputResponse} message DeleteROIInputResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteROIInputResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteROIInputResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteROIInputResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteROIInputResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteROIInputResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteROIInputResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteROIInputResponse";
                };
    
                return DeleteROIInputResponse;
            })();
    
            be.ROIInput = (function() {
    
                /**
                 * Properties of a ROIInput.
                 * @memberof infinitusai.be
                 * @interface IROIInput
                 * @property {number|null} [id] ROIInput id
                 * @property {infinitusai.be.TaskType|null} [taskType] ROIInput taskType
                 * @property {number|null} [numCallsToResolution] ROIInput numCallsToResolution
                 * @property {number|null} [getInfoMinutes] ROIInput getInfoMinutes
                 * @property {number|null} [personAuthMinutes] ROIInput personAuthMinutes
                 * @property {string|null} [orgUuid] ROIInput orgUuid
                 */
    
                /**
                 * Constructs a new ROIInput.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ROIInput.
                 * @implements IROIInput
                 * @constructor
                 * @param {infinitusai.be.IROIInput=} [properties] Properties to set
                 */
                function ROIInput(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ROIInput id.
                 * @member {number} id
                 * @memberof infinitusai.be.ROIInput
                 * @instance
                 */
                ROIInput.prototype.id = 0;
    
                /**
                 * ROIInput taskType.
                 * @member {infinitusai.be.TaskType} taskType
                 * @memberof infinitusai.be.ROIInput
                 * @instance
                 */
                ROIInput.prototype.taskType = 0;
    
                /**
                 * ROIInput numCallsToResolution.
                 * @member {number} numCallsToResolution
                 * @memberof infinitusai.be.ROIInput
                 * @instance
                 */
                ROIInput.prototype.numCallsToResolution = 0;
    
                /**
                 * ROIInput getInfoMinutes.
                 * @member {number} getInfoMinutes
                 * @memberof infinitusai.be.ROIInput
                 * @instance
                 */
                ROIInput.prototype.getInfoMinutes = 0;
    
                /**
                 * ROIInput personAuthMinutes.
                 * @member {number} personAuthMinutes
                 * @memberof infinitusai.be.ROIInput
                 * @instance
                 */
                ROIInput.prototype.personAuthMinutes = 0;
    
                /**
                 * ROIInput orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.be.ROIInput
                 * @instance
                 */
                ROIInput.prototype.orgUuid = "";
    
                /**
                 * Creates a new ROIInput instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {infinitusai.be.IROIInput=} [properties] Properties to set
                 * @returns {infinitusai.be.ROIInput} ROIInput instance
                 */
                ROIInput.create = function create(properties) {
                    return new ROIInput(properties);
                };
    
                /**
                 * Encodes the specified ROIInput message. Does not implicitly {@link infinitusai.be.ROIInput.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {infinitusai.be.IROIInput} message ROIInput message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ROIInput.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    if (message.numCallsToResolution != null && Object.hasOwnProperty.call(message, "numCallsToResolution"))
                        writer.uint32(/* id 3, wireType 5 =*/29).float(message.numCallsToResolution);
                    if (message.getInfoMinutes != null && Object.hasOwnProperty.call(message, "getInfoMinutes"))
                        writer.uint32(/* id 4, wireType 5 =*/37).float(message.getInfoMinutes);
                    if (message.personAuthMinutes != null && Object.hasOwnProperty.call(message, "personAuthMinutes"))
                        writer.uint32(/* id 5, wireType 5 =*/45).float(message.personAuthMinutes);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified ROIInput message, length delimited. Does not implicitly {@link infinitusai.be.ROIInput.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {infinitusai.be.IROIInput} message ROIInput message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ROIInput.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ROIInput message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ROIInput} ROIInput
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ROIInput.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ROIInput();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int32();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.numCallsToResolution = reader.float();
                                break;
                            }
                        case 4: {
                                message.getInfoMinutes = reader.float();
                                break;
                            }
                        case 5: {
                                message.personAuthMinutes = reader.float();
                                break;
                            }
                        case 6: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ROIInput message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ROIInput} ROIInput
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ROIInput.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ROIInput message.
                 * @function verify
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ROIInput.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.numCallsToResolution != null && message.hasOwnProperty("numCallsToResolution"))
                        if (typeof message.numCallsToResolution !== "number")
                            return "numCallsToResolution: number expected";
                    if (message.getInfoMinutes != null && message.hasOwnProperty("getInfoMinutes"))
                        if (typeof message.getInfoMinutes !== "number")
                            return "getInfoMinutes: number expected";
                    if (message.personAuthMinutes != null && message.hasOwnProperty("personAuthMinutes"))
                        if (typeof message.personAuthMinutes !== "number")
                            return "personAuthMinutes: number expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a ROIInput message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ROIInput} ROIInput
                 */
                ROIInput.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ROIInput)
                        return object;
                    var message = new $root.infinitusai.be.ROIInput();
                    if (object.id != null)
                        message.id = object.id | 0;
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.numCallsToResolution != null)
                        message.numCallsToResolution = Number(object.numCallsToResolution);
                    if (object.getInfoMinutes != null)
                        message.getInfoMinutes = Number(object.getInfoMinutes);
                    if (object.personAuthMinutes != null)
                        message.personAuthMinutes = Number(object.personAuthMinutes);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ROIInput message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {infinitusai.be.ROIInput} message ROIInput
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ROIInput.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = 0;
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.numCallsToResolution = 0;
                        object.getInfoMinutes = 0;
                        object.personAuthMinutes = 0;
                        object.orgUuid = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.be.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.be.TaskType[message.taskType] : message.taskType;
                    if (message.numCallsToResolution != null && message.hasOwnProperty("numCallsToResolution"))
                        object.numCallsToResolution = options.json && !isFinite(message.numCallsToResolution) ? String(message.numCallsToResolution) : message.numCallsToResolution;
                    if (message.getInfoMinutes != null && message.hasOwnProperty("getInfoMinutes"))
                        object.getInfoMinutes = options.json && !isFinite(message.getInfoMinutes) ? String(message.getInfoMinutes) : message.getInfoMinutes;
                    if (message.personAuthMinutes != null && message.hasOwnProperty("personAuthMinutes"))
                        object.personAuthMinutes = options.json && !isFinite(message.personAuthMinutes) ? String(message.personAuthMinutes) : message.personAuthMinutes;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this ROIInput to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ROIInput
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ROIInput.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ROIInput
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ROIInput
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ROIInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ROIInput";
                };
    
                return ROIInput;
            })();
    
            be.GetCustomerTaskTimeSavingsRequest = (function() {
    
                /**
                 * Properties of a GetCustomerTaskTimeSavingsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetCustomerTaskTimeSavingsRequest
                 * @property {string|null} [customerTaskUuid] GetCustomerTaskTimeSavingsRequest customerTaskUuid
                 */
    
                /**
                 * Constructs a new GetCustomerTaskTimeSavingsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCustomerTaskTimeSavingsRequest.
                 * @implements IGetCustomerTaskTimeSavingsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetCustomerTaskTimeSavingsRequest=} [properties] Properties to set
                 */
                function GetCustomerTaskTimeSavingsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCustomerTaskTimeSavingsRequest customerTaskUuid.
                 * @member {string} customerTaskUuid
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @instance
                 */
                GetCustomerTaskTimeSavingsRequest.prototype.customerTaskUuid = "";
    
                /**
                 * Creates a new GetCustomerTaskTimeSavingsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {infinitusai.be.IGetCustomerTaskTimeSavingsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCustomerTaskTimeSavingsRequest} GetCustomerTaskTimeSavingsRequest instance
                 */
                GetCustomerTaskTimeSavingsRequest.create = function create(properties) {
                    return new GetCustomerTaskTimeSavingsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetCustomerTaskTimeSavingsRequest message. Does not implicitly {@link infinitusai.be.GetCustomerTaskTimeSavingsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {infinitusai.be.IGetCustomerTaskTimeSavingsRequest} message GetCustomerTaskTimeSavingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCustomerTaskTimeSavingsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.customerTaskUuid != null && Object.hasOwnProperty.call(message, "customerTaskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerTaskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCustomerTaskTimeSavingsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetCustomerTaskTimeSavingsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {infinitusai.be.IGetCustomerTaskTimeSavingsRequest} message GetCustomerTaskTimeSavingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCustomerTaskTimeSavingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCustomerTaskTimeSavingsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCustomerTaskTimeSavingsRequest} GetCustomerTaskTimeSavingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCustomerTaskTimeSavingsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCustomerTaskTimeSavingsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.customerTaskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCustomerTaskTimeSavingsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCustomerTaskTimeSavingsRequest} GetCustomerTaskTimeSavingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCustomerTaskTimeSavingsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCustomerTaskTimeSavingsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCustomerTaskTimeSavingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.customerTaskUuid != null && message.hasOwnProperty("customerTaskUuid"))
                        if (!$util.isString(message.customerTaskUuid))
                            return "customerTaskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetCustomerTaskTimeSavingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCustomerTaskTimeSavingsRequest} GetCustomerTaskTimeSavingsRequest
                 */
                GetCustomerTaskTimeSavingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCustomerTaskTimeSavingsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetCustomerTaskTimeSavingsRequest();
                    if (object.customerTaskUuid != null)
                        message.customerTaskUuid = String(object.customerTaskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCustomerTaskTimeSavingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {infinitusai.be.GetCustomerTaskTimeSavingsRequest} message GetCustomerTaskTimeSavingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCustomerTaskTimeSavingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.customerTaskUuid = "";
                    if (message.customerTaskUuid != null && message.hasOwnProperty("customerTaskUuid"))
                        object.customerTaskUuid = message.customerTaskUuid;
                    return object;
                };
    
                /**
                 * Converts this GetCustomerTaskTimeSavingsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCustomerTaskTimeSavingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCustomerTaskTimeSavingsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCustomerTaskTimeSavingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCustomerTaskTimeSavingsRequest";
                };
    
                return GetCustomerTaskTimeSavingsRequest;
            })();
    
            be.GetCustomerTaskTimeSavingsResponse = (function() {
    
                /**
                 * Properties of a GetCustomerTaskTimeSavingsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetCustomerTaskTimeSavingsResponse
                 * @property {number|null} [timeSavingsMinutes] GetCustomerTaskTimeSavingsResponse timeSavingsMinutes
                 */
    
                /**
                 * Constructs a new GetCustomerTaskTimeSavingsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetCustomerTaskTimeSavingsResponse.
                 * @implements IGetCustomerTaskTimeSavingsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetCustomerTaskTimeSavingsResponse=} [properties] Properties to set
                 */
                function GetCustomerTaskTimeSavingsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCustomerTaskTimeSavingsResponse timeSavingsMinutes.
                 * @member {number} timeSavingsMinutes
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @instance
                 */
                GetCustomerTaskTimeSavingsResponse.prototype.timeSavingsMinutes = 0;
    
                /**
                 * Creates a new GetCustomerTaskTimeSavingsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {infinitusai.be.IGetCustomerTaskTimeSavingsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetCustomerTaskTimeSavingsResponse} GetCustomerTaskTimeSavingsResponse instance
                 */
                GetCustomerTaskTimeSavingsResponse.create = function create(properties) {
                    return new GetCustomerTaskTimeSavingsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCustomerTaskTimeSavingsResponse message. Does not implicitly {@link infinitusai.be.GetCustomerTaskTimeSavingsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {infinitusai.be.IGetCustomerTaskTimeSavingsResponse} message GetCustomerTaskTimeSavingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCustomerTaskTimeSavingsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.timeSavingsMinutes != null && Object.hasOwnProperty.call(message, "timeSavingsMinutes"))
                        writer.uint32(/* id 1, wireType 5 =*/13).float(message.timeSavingsMinutes);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCustomerTaskTimeSavingsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetCustomerTaskTimeSavingsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {infinitusai.be.IGetCustomerTaskTimeSavingsResponse} message GetCustomerTaskTimeSavingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCustomerTaskTimeSavingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCustomerTaskTimeSavingsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetCustomerTaskTimeSavingsResponse} GetCustomerTaskTimeSavingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCustomerTaskTimeSavingsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetCustomerTaskTimeSavingsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.timeSavingsMinutes = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCustomerTaskTimeSavingsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetCustomerTaskTimeSavingsResponse} GetCustomerTaskTimeSavingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCustomerTaskTimeSavingsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCustomerTaskTimeSavingsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCustomerTaskTimeSavingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.timeSavingsMinutes != null && message.hasOwnProperty("timeSavingsMinutes"))
                        if (typeof message.timeSavingsMinutes !== "number")
                            return "timeSavingsMinutes: number expected";
                    return null;
                };
    
                /**
                 * Creates a GetCustomerTaskTimeSavingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetCustomerTaskTimeSavingsResponse} GetCustomerTaskTimeSavingsResponse
                 */
                GetCustomerTaskTimeSavingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetCustomerTaskTimeSavingsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetCustomerTaskTimeSavingsResponse();
                    if (object.timeSavingsMinutes != null)
                        message.timeSavingsMinutes = Number(object.timeSavingsMinutes);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCustomerTaskTimeSavingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {infinitusai.be.GetCustomerTaskTimeSavingsResponse} message GetCustomerTaskTimeSavingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCustomerTaskTimeSavingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.timeSavingsMinutes = 0;
                    if (message.timeSavingsMinutes != null && message.hasOwnProperty("timeSavingsMinutes"))
                        object.timeSavingsMinutes = options.json && !isFinite(message.timeSavingsMinutes) ? String(message.timeSavingsMinutes) : message.timeSavingsMinutes;
                    return object;
                };
    
                /**
                 * Converts this GetCustomerTaskTimeSavingsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCustomerTaskTimeSavingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCustomerTaskTimeSavingsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetCustomerTaskTimeSavingsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCustomerTaskTimeSavingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetCustomerTaskTimeSavingsResponse";
                };
    
                return GetCustomerTaskTimeSavingsResponse;
            })();
    
            be.ProductCodes = (function() {
    
                /**
                 * Properties of a ProductCodes.
                 * @memberof infinitusai.be
                 * @interface IProductCodes
                 * @property {Array.<string>|null} [products] ProductCodes products
                 */
    
                /**
                 * Constructs a new ProductCodes.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProductCodes.
                 * @implements IProductCodes
                 * @constructor
                 * @param {infinitusai.be.IProductCodes=} [properties] Properties to set
                 */
                function ProductCodes(properties) {
                    this.products = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProductCodes products.
                 * @member {Array.<string>} products
                 * @memberof infinitusai.be.ProductCodes
                 * @instance
                 */
                ProductCodes.prototype.products = $util.emptyArray;
    
                /**
                 * Creates a new ProductCodes instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {infinitusai.be.IProductCodes=} [properties] Properties to set
                 * @returns {infinitusai.be.ProductCodes} ProductCodes instance
                 */
                ProductCodes.create = function create(properties) {
                    return new ProductCodes(properties);
                };
    
                /**
                 * Encodes the specified ProductCodes message. Does not implicitly {@link infinitusai.be.ProductCodes.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {infinitusai.be.IProductCodes} message ProductCodes message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProductCodes.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.products != null && message.products.length)
                        for (var i = 0; i < message.products.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.products[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProductCodes message, length delimited. Does not implicitly {@link infinitusai.be.ProductCodes.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {infinitusai.be.IProductCodes} message ProductCodes message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProductCodes.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProductCodes message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProductCodes} ProductCodes
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProductCodes.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProductCodes();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.products && message.products.length))
                                    message.products = [];
                                message.products.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProductCodes message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProductCodes} ProductCodes
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProductCodes.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProductCodes message.
                 * @function verify
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProductCodes.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.products != null && message.hasOwnProperty("products")) {
                        if (!Array.isArray(message.products))
                            return "products: array expected";
                        for (var i = 0; i < message.products.length; ++i)
                            if (!$util.isString(message.products[i]))
                                return "products: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ProductCodes message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProductCodes} ProductCodes
                 */
                ProductCodes.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProductCodes)
                        return object;
                    var message = new $root.infinitusai.be.ProductCodes();
                    if (object.products) {
                        if (!Array.isArray(object.products))
                            throw TypeError(".infinitusai.be.ProductCodes.products: array expected");
                        message.products = [];
                        for (var i = 0; i < object.products.length; ++i)
                            message.products[i] = String(object.products[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProductCodes message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {infinitusai.be.ProductCodes} message ProductCodes
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProductCodes.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.products = [];
                    if (message.products && message.products.length) {
                        object.products = [];
                        for (var j = 0; j < message.products.length; ++j)
                            object.products[j] = message.products[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ProductCodes to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProductCodes
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProductCodes.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProductCodes
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProductCodes
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProductCodes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProductCodes";
                };
    
                return ProductCodes;
            })();
    
            be.ProcedureCodeFamily = (function() {
    
                /**
                 * Properties of a ProcedureCodeFamily.
                 * @memberof infinitusai.be
                 * @interface IProcedureCodeFamily
                 * @property {Array.<string>|null} [procedures] ProcedureCodeFamily procedures
                 */
    
                /**
                 * Constructs a new ProcedureCodeFamily.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ProcedureCodeFamily.
                 * @implements IProcedureCodeFamily
                 * @constructor
                 * @param {infinitusai.be.IProcedureCodeFamily=} [properties] Properties to set
                 */
                function ProcedureCodeFamily(properties) {
                    this.procedures = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProcedureCodeFamily procedures.
                 * @member {Array.<string>} procedures
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @instance
                 */
                ProcedureCodeFamily.prototype.procedures = $util.emptyArray;
    
                /**
                 * Creates a new ProcedureCodeFamily instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {infinitusai.be.IProcedureCodeFamily=} [properties] Properties to set
                 * @returns {infinitusai.be.ProcedureCodeFamily} ProcedureCodeFamily instance
                 */
                ProcedureCodeFamily.create = function create(properties) {
                    return new ProcedureCodeFamily(properties);
                };
    
                /**
                 * Encodes the specified ProcedureCodeFamily message. Does not implicitly {@link infinitusai.be.ProcedureCodeFamily.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {infinitusai.be.IProcedureCodeFamily} message ProcedureCodeFamily message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcedureCodeFamily.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.procedures != null && message.procedures.length)
                        for (var i = 0; i < message.procedures.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.procedures[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProcedureCodeFamily message, length delimited. Does not implicitly {@link infinitusai.be.ProcedureCodeFamily.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {infinitusai.be.IProcedureCodeFamily} message ProcedureCodeFamily message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProcedureCodeFamily.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProcedureCodeFamily message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ProcedureCodeFamily} ProcedureCodeFamily
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcedureCodeFamily.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ProcedureCodeFamily();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.procedures && message.procedures.length))
                                    message.procedures = [];
                                message.procedures.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProcedureCodeFamily message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ProcedureCodeFamily} ProcedureCodeFamily
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProcedureCodeFamily.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProcedureCodeFamily message.
                 * @function verify
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProcedureCodeFamily.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.procedures != null && message.hasOwnProperty("procedures")) {
                        if (!Array.isArray(message.procedures))
                            return "procedures: array expected";
                        for (var i = 0; i < message.procedures.length; ++i)
                            if (!$util.isString(message.procedures[i]))
                                return "procedures: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a ProcedureCodeFamily message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ProcedureCodeFamily} ProcedureCodeFamily
                 */
                ProcedureCodeFamily.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ProcedureCodeFamily)
                        return object;
                    var message = new $root.infinitusai.be.ProcedureCodeFamily();
                    if (object.procedures) {
                        if (!Array.isArray(object.procedures))
                            throw TypeError(".infinitusai.be.ProcedureCodeFamily.procedures: array expected");
                        message.procedures = [];
                        for (var i = 0; i < object.procedures.length; ++i)
                            message.procedures[i] = String(object.procedures[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProcedureCodeFamily message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {infinitusai.be.ProcedureCodeFamily} message ProcedureCodeFamily
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProcedureCodeFamily.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.procedures = [];
                    if (message.procedures && message.procedures.length) {
                        object.procedures = [];
                        for (var j = 0; j < message.procedures.length; ++j)
                            object.procedures[j] = message.procedures[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this ProcedureCodeFamily to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProcedureCodeFamily.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProcedureCodeFamily
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ProcedureCodeFamily
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProcedureCodeFamily.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ProcedureCodeFamily";
                };
    
                return ProcedureCodeFamily;
            })();
    
            be.SisOrganizationMappings = (function() {
    
                /**
                 * Properties of a SisOrganizationMappings.
                 * @memberof infinitusai.be
                 * @interface ISisOrganizationMappings
                 * @property {Object.<string,infinitusapi.IINFTaskInputProvider>|null} [providersByNpi] SisOrganizationMappings providersByNpi
                 * @property {Object.<string,infinitusai.be.IProductCodes>|null} [productCodesByProcedure] SisOrganizationMappings productCodesByProcedure
                 * @property {Object.<string,infinitusapi.IINFTaskInputProduct>|null} [productsByCode] SisOrganizationMappings productsByCode
                 * @property {Object.<string,string>|null} [payerIdsByInsName] SisOrganizationMappings payerIdsByInsName
                 * @property {Object.<string,infinitusapi.IINFTaskInputPractice>|null} [practicesByCity] SisOrganizationMappings practicesByCity
                 * @property {string|null} [defaultProgramId] SisOrganizationMappings defaultProgramId
                 * @property {Array.<infinitusai.be.IProcedureCodeFamily>|null} [procedureCodeFamilies] SisOrganizationMappings procedureCodeFamilies
                 * @property {Object.<string,infinitusapi.INFBVTaskInputNetworkStatus>|null} [networkStatusByPayerId] SisOrganizationMappings networkStatusByPayerId
                 */
    
                /**
                 * Constructs a new SisOrganizationMappings.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SisOrganizationMappings.
                 * @implements ISisOrganizationMappings
                 * @constructor
                 * @param {infinitusai.be.ISisOrganizationMappings=} [properties] Properties to set
                 */
                function SisOrganizationMappings(properties) {
                    this.providersByNpi = {};
                    this.productCodesByProcedure = {};
                    this.productsByCode = {};
                    this.payerIdsByInsName = {};
                    this.practicesByCity = {};
                    this.procedureCodeFamilies = [];
                    this.networkStatusByPayerId = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SisOrganizationMappings providersByNpi.
                 * @member {Object.<string,infinitusapi.IINFTaskInputProvider>} providersByNpi
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 */
                SisOrganizationMappings.prototype.providersByNpi = $util.emptyObject;
    
                /**
                 * SisOrganizationMappings productCodesByProcedure.
                 * @member {Object.<string,infinitusai.be.IProductCodes>} productCodesByProcedure
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 */
                SisOrganizationMappings.prototype.productCodesByProcedure = $util.emptyObject;
    
                /**
                 * SisOrganizationMappings productsByCode.
                 * @member {Object.<string,infinitusapi.IINFTaskInputProduct>} productsByCode
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 */
                SisOrganizationMappings.prototype.productsByCode = $util.emptyObject;
    
                /**
                 * SisOrganizationMappings payerIdsByInsName.
                 * @member {Object.<string,string>} payerIdsByInsName
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 */
                SisOrganizationMappings.prototype.payerIdsByInsName = $util.emptyObject;
    
                /**
                 * SisOrganizationMappings practicesByCity.
                 * @member {Object.<string,infinitusapi.IINFTaskInputPractice>} practicesByCity
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 */
                SisOrganizationMappings.prototype.practicesByCity = $util.emptyObject;
    
                /**
                 * SisOrganizationMappings defaultProgramId.
                 * @member {string} defaultProgramId
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 */
                SisOrganizationMappings.prototype.defaultProgramId = "";
    
                /**
                 * SisOrganizationMappings procedureCodeFamilies.
                 * @member {Array.<infinitusai.be.IProcedureCodeFamily>} procedureCodeFamilies
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 */
                SisOrganizationMappings.prototype.procedureCodeFamilies = $util.emptyArray;
    
                /**
                 * SisOrganizationMappings networkStatusByPayerId.
                 * @member {Object.<string,infinitusapi.INFBVTaskInputNetworkStatus>} networkStatusByPayerId
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 */
                SisOrganizationMappings.prototype.networkStatusByPayerId = $util.emptyObject;
    
                /**
                 * Creates a new SisOrganizationMappings instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {infinitusai.be.ISisOrganizationMappings=} [properties] Properties to set
                 * @returns {infinitusai.be.SisOrganizationMappings} SisOrganizationMappings instance
                 */
                SisOrganizationMappings.create = function create(properties) {
                    return new SisOrganizationMappings(properties);
                };
    
                /**
                 * Encodes the specified SisOrganizationMappings message. Does not implicitly {@link infinitusai.be.SisOrganizationMappings.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {infinitusai.be.ISisOrganizationMappings} message SisOrganizationMappings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SisOrganizationMappings.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providersByNpi != null && Object.hasOwnProperty.call(message, "providersByNpi"))
                        for (var keys = Object.keys(message.providersByNpi), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusapi.INFTaskInputProvider.encode(message.providersByNpi[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.productCodesByProcedure != null && Object.hasOwnProperty.call(message, "productCodesByProcedure"))
                        for (var keys = Object.keys(message.productCodesByProcedure), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.ProductCodes.encode(message.productCodesByProcedure[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.productsByCode != null && Object.hasOwnProperty.call(message, "productsByCode"))
                        for (var keys = Object.keys(message.productsByCode), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusapi.INFTaskInputProduct.encode(message.productsByCode[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.payerIdsByInsName != null && Object.hasOwnProperty.call(message, "payerIdsByInsName"))
                        for (var keys = Object.keys(message.payerIdsByInsName), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.payerIdsByInsName[keys[i]]).ldelim();
                    if (message.practicesByCity != null && Object.hasOwnProperty.call(message, "practicesByCity"))
                        for (var keys = Object.keys(message.practicesByCity), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusapi.INFTaskInputPractice.encode(message.practicesByCity[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.defaultProgramId != null && Object.hasOwnProperty.call(message, "defaultProgramId"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.defaultProgramId);
                    if (message.procedureCodeFamilies != null && message.procedureCodeFamilies.length)
                        for (var i = 0; i < message.procedureCodeFamilies.length; ++i)
                            $root.infinitusai.be.ProcedureCodeFamily.encode(message.procedureCodeFamilies[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.networkStatusByPayerId != null && Object.hasOwnProperty.call(message, "networkStatusByPayerId"))
                        for (var keys = Object.keys(message.networkStatusByPayerId), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.networkStatusByPayerId[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SisOrganizationMappings message, length delimited. Does not implicitly {@link infinitusai.be.SisOrganizationMappings.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {infinitusai.be.ISisOrganizationMappings} message SisOrganizationMappings message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SisOrganizationMappings.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SisOrganizationMappings message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SisOrganizationMappings} SisOrganizationMappings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SisOrganizationMappings.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SisOrganizationMappings(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.providersByNpi === $util.emptyObject)
                                    message.providersByNpi = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusapi.INFTaskInputProvider.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.providersByNpi[key] = value;
                                break;
                            }
                        case 2: {
                                if (message.productCodesByProcedure === $util.emptyObject)
                                    message.productCodesByProcedure = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.ProductCodes.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.productCodesByProcedure[key] = value;
                                break;
                            }
                        case 3: {
                                if (message.productsByCode === $util.emptyObject)
                                    message.productsByCode = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusapi.INFTaskInputProduct.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.productsByCode[key] = value;
                                break;
                            }
                        case 4: {
                                if (message.payerIdsByInsName === $util.emptyObject)
                                    message.payerIdsByInsName = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.payerIdsByInsName[key] = value;
                                break;
                            }
                        case 5: {
                                if (message.practicesByCity === $util.emptyObject)
                                    message.practicesByCity = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusapi.INFTaskInputPractice.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.practicesByCity[key] = value;
                                break;
                            }
                        case 6: {
                                message.defaultProgramId = reader.string();
                                break;
                            }
                        case 7: {
                                if (!(message.procedureCodeFamilies && message.procedureCodeFamilies.length))
                                    message.procedureCodeFamilies = [];
                                message.procedureCodeFamilies.push($root.infinitusai.be.ProcedureCodeFamily.decode(reader, reader.uint32()));
                                break;
                            }
                        case 8: {
                                if (message.networkStatusByPayerId === $util.emptyObject)
                                    message.networkStatusByPayerId = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.int32();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.networkStatusByPayerId[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SisOrganizationMappings message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SisOrganizationMappings} SisOrganizationMappings
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SisOrganizationMappings.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SisOrganizationMappings message.
                 * @function verify
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SisOrganizationMappings.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providersByNpi != null && message.hasOwnProperty("providersByNpi")) {
                        if (!$util.isObject(message.providersByNpi))
                            return "providersByNpi: object expected";
                        var key = Object.keys(message.providersByNpi);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusapi.INFTaskInputProvider.verify(message.providersByNpi[key[i]]);
                            if (error)
                                return "providersByNpi." + error;
                        }
                    }
                    if (message.productCodesByProcedure != null && message.hasOwnProperty("productCodesByProcedure")) {
                        if (!$util.isObject(message.productCodesByProcedure))
                            return "productCodesByProcedure: object expected";
                        var key = Object.keys(message.productCodesByProcedure);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.ProductCodes.verify(message.productCodesByProcedure[key[i]]);
                            if (error)
                                return "productCodesByProcedure." + error;
                        }
                    }
                    if (message.productsByCode != null && message.hasOwnProperty("productsByCode")) {
                        if (!$util.isObject(message.productsByCode))
                            return "productsByCode: object expected";
                        var key = Object.keys(message.productsByCode);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusapi.INFTaskInputProduct.verify(message.productsByCode[key[i]]);
                            if (error)
                                return "productsByCode." + error;
                        }
                    }
                    if (message.payerIdsByInsName != null && message.hasOwnProperty("payerIdsByInsName")) {
                        if (!$util.isObject(message.payerIdsByInsName))
                            return "payerIdsByInsName: object expected";
                        var key = Object.keys(message.payerIdsByInsName);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.payerIdsByInsName[key[i]]))
                                return "payerIdsByInsName: string{k:string} expected";
                    }
                    if (message.practicesByCity != null && message.hasOwnProperty("practicesByCity")) {
                        if (!$util.isObject(message.practicesByCity))
                            return "practicesByCity: object expected";
                        var key = Object.keys(message.practicesByCity);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusapi.INFTaskInputPractice.verify(message.practicesByCity[key[i]]);
                            if (error)
                                return "practicesByCity." + error;
                        }
                    }
                    if (message.defaultProgramId != null && message.hasOwnProperty("defaultProgramId"))
                        if (!$util.isString(message.defaultProgramId))
                            return "defaultProgramId: string expected";
                    if (message.procedureCodeFamilies != null && message.hasOwnProperty("procedureCodeFamilies")) {
                        if (!Array.isArray(message.procedureCodeFamilies))
                            return "procedureCodeFamilies: array expected";
                        for (var i = 0; i < message.procedureCodeFamilies.length; ++i) {
                            var error = $root.infinitusai.be.ProcedureCodeFamily.verify(message.procedureCodeFamilies[i]);
                            if (error)
                                return "procedureCodeFamilies." + error;
                        }
                    }
                    if (message.networkStatusByPayerId != null && message.hasOwnProperty("networkStatusByPayerId")) {
                        if (!$util.isObject(message.networkStatusByPayerId))
                            return "networkStatusByPayerId: object expected";
                        var key = Object.keys(message.networkStatusByPayerId);
                        for (var i = 0; i < key.length; ++i)
                            switch (message.networkStatusByPayerId[key[i]]) {
                            default:
                                return "networkStatusByPayerId: enum value{k:string} expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a SisOrganizationMappings message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SisOrganizationMappings} SisOrganizationMappings
                 */
                SisOrganizationMappings.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SisOrganizationMappings)
                        return object;
                    var message = new $root.infinitusai.be.SisOrganizationMappings();
                    if (object.providersByNpi) {
                        if (typeof object.providersByNpi !== "object")
                            throw TypeError(".infinitusai.be.SisOrganizationMappings.providersByNpi: object expected");
                        message.providersByNpi = {};
                        for (var keys = Object.keys(object.providersByNpi), i = 0; i < keys.length; ++i) {
                            if (typeof object.providersByNpi[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.SisOrganizationMappings.providersByNpi: object expected");
                            message.providersByNpi[keys[i]] = $root.infinitusapi.INFTaskInputProvider.fromObject(object.providersByNpi[keys[i]]);
                        }
                    }
                    if (object.productCodesByProcedure) {
                        if (typeof object.productCodesByProcedure !== "object")
                            throw TypeError(".infinitusai.be.SisOrganizationMappings.productCodesByProcedure: object expected");
                        message.productCodesByProcedure = {};
                        for (var keys = Object.keys(object.productCodesByProcedure), i = 0; i < keys.length; ++i) {
                            if (typeof object.productCodesByProcedure[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.SisOrganizationMappings.productCodesByProcedure: object expected");
                            message.productCodesByProcedure[keys[i]] = $root.infinitusai.be.ProductCodes.fromObject(object.productCodesByProcedure[keys[i]]);
                        }
                    }
                    if (object.productsByCode) {
                        if (typeof object.productsByCode !== "object")
                            throw TypeError(".infinitusai.be.SisOrganizationMappings.productsByCode: object expected");
                        message.productsByCode = {};
                        for (var keys = Object.keys(object.productsByCode), i = 0; i < keys.length; ++i) {
                            if (typeof object.productsByCode[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.SisOrganizationMappings.productsByCode: object expected");
                            message.productsByCode[keys[i]] = $root.infinitusapi.INFTaskInputProduct.fromObject(object.productsByCode[keys[i]]);
                        }
                    }
                    if (object.payerIdsByInsName) {
                        if (typeof object.payerIdsByInsName !== "object")
                            throw TypeError(".infinitusai.be.SisOrganizationMappings.payerIdsByInsName: object expected");
                        message.payerIdsByInsName = {};
                        for (var keys = Object.keys(object.payerIdsByInsName), i = 0; i < keys.length; ++i)
                            message.payerIdsByInsName[keys[i]] = String(object.payerIdsByInsName[keys[i]]);
                    }
                    if (object.practicesByCity) {
                        if (typeof object.practicesByCity !== "object")
                            throw TypeError(".infinitusai.be.SisOrganizationMappings.practicesByCity: object expected");
                        message.practicesByCity = {};
                        for (var keys = Object.keys(object.practicesByCity), i = 0; i < keys.length; ++i) {
                            if (typeof object.practicesByCity[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.SisOrganizationMappings.practicesByCity: object expected");
                            message.practicesByCity[keys[i]] = $root.infinitusapi.INFTaskInputPractice.fromObject(object.practicesByCity[keys[i]]);
                        }
                    }
                    if (object.defaultProgramId != null)
                        message.defaultProgramId = String(object.defaultProgramId);
                    if (object.procedureCodeFamilies) {
                        if (!Array.isArray(object.procedureCodeFamilies))
                            throw TypeError(".infinitusai.be.SisOrganizationMappings.procedureCodeFamilies: array expected");
                        message.procedureCodeFamilies = [];
                        for (var i = 0; i < object.procedureCodeFamilies.length; ++i) {
                            if (typeof object.procedureCodeFamilies[i] !== "object")
                                throw TypeError(".infinitusai.be.SisOrganizationMappings.procedureCodeFamilies: object expected");
                            message.procedureCodeFamilies[i] = $root.infinitusai.be.ProcedureCodeFamily.fromObject(object.procedureCodeFamilies[i]);
                        }
                    }
                    if (object.networkStatusByPayerId) {
                        if (typeof object.networkStatusByPayerId !== "object")
                            throw TypeError(".infinitusai.be.SisOrganizationMappings.networkStatusByPayerId: object expected");
                        message.networkStatusByPayerId = {};
                        for (var keys = Object.keys(object.networkStatusByPayerId), i = 0; i < keys.length; ++i)
                            switch (object.networkStatusByPayerId[keys[i]]) {
                            default:
                                if (typeof object.networkStatusByPayerId[keys[i]] === "number") {
                                    message.networkStatusByPayerId[keys[i]] = object.networkStatusByPayerId[keys[i]];
                                    break;
                                }
                                break;
                            case "INF_INPUT_NETWORK_STATUS_UNKNOWN":
                            case 0:
                                message.networkStatusByPayerId[keys[i]] = 0;
                                break;
                            case "INF_INPUT_NETWORK_STATUS_IN_NETWORK":
                            case 1:
                                message.networkStatusByPayerId[keys[i]] = 1;
                                break;
                            case "INF_INPUT_NETWORK_STATUS_OUT_OF_NETWORK":
                            case 2:
                                message.networkStatusByPayerId[keys[i]] = 2;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SisOrganizationMappings message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {infinitusai.be.SisOrganizationMappings} message SisOrganizationMappings
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SisOrganizationMappings.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.procedureCodeFamilies = [];
                    if (options.objects || options.defaults) {
                        object.providersByNpi = {};
                        object.productCodesByProcedure = {};
                        object.productsByCode = {};
                        object.payerIdsByInsName = {};
                        object.practicesByCity = {};
                        object.networkStatusByPayerId = {};
                    }
                    if (options.defaults)
                        object.defaultProgramId = "";
                    var keys2;
                    if (message.providersByNpi && (keys2 = Object.keys(message.providersByNpi)).length) {
                        object.providersByNpi = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.providersByNpi[keys2[j]] = $root.infinitusapi.INFTaskInputProvider.toObject(message.providersByNpi[keys2[j]], options);
                    }
                    if (message.productCodesByProcedure && (keys2 = Object.keys(message.productCodesByProcedure)).length) {
                        object.productCodesByProcedure = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.productCodesByProcedure[keys2[j]] = $root.infinitusai.be.ProductCodes.toObject(message.productCodesByProcedure[keys2[j]], options);
                    }
                    if (message.productsByCode && (keys2 = Object.keys(message.productsByCode)).length) {
                        object.productsByCode = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.productsByCode[keys2[j]] = $root.infinitusapi.INFTaskInputProduct.toObject(message.productsByCode[keys2[j]], options);
                    }
                    if (message.payerIdsByInsName && (keys2 = Object.keys(message.payerIdsByInsName)).length) {
                        object.payerIdsByInsName = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.payerIdsByInsName[keys2[j]] = message.payerIdsByInsName[keys2[j]];
                    }
                    if (message.practicesByCity && (keys2 = Object.keys(message.practicesByCity)).length) {
                        object.practicesByCity = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.practicesByCity[keys2[j]] = $root.infinitusapi.INFTaskInputPractice.toObject(message.practicesByCity[keys2[j]], options);
                    }
                    if (message.defaultProgramId != null && message.hasOwnProperty("defaultProgramId"))
                        object.defaultProgramId = message.defaultProgramId;
                    if (message.procedureCodeFamilies && message.procedureCodeFamilies.length) {
                        object.procedureCodeFamilies = [];
                        for (var j = 0; j < message.procedureCodeFamilies.length; ++j)
                            object.procedureCodeFamilies[j] = $root.infinitusai.be.ProcedureCodeFamily.toObject(message.procedureCodeFamilies[j], options);
                    }
                    if (message.networkStatusByPayerId && (keys2 = Object.keys(message.networkStatusByPayerId)).length) {
                        object.networkStatusByPayerId = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.networkStatusByPayerId[keys2[j]] = options.enums === String ? $root.infinitusapi.INFBVTaskInputNetworkStatus[message.networkStatusByPayerId[keys2[j]]] === undefined ? message.networkStatusByPayerId[keys2[j]] : $root.infinitusapi.INFBVTaskInputNetworkStatus[message.networkStatusByPayerId[keys2[j]]] : message.networkStatusByPayerId[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this SisOrganizationMappings to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SisOrganizationMappings.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SisOrganizationMappings
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SisOrganizationMappings
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SisOrganizationMappings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SisOrganizationMappings";
                };
    
                return SisOrganizationMappings;
            })();
    
            be.SkillDefinition = (function() {
    
                /**
                 * Properties of a SkillDefinition.
                 * @memberof infinitusai.be
                 * @interface ISkillDefinition
                 * @property {string|null} [name] SkillDefinition name
                 * @property {string|null} [description] SkillDefinition description
                 * @property {string|null} [uuid] SkillDefinition uuid
                 * @property {Array.<infinitusai.be.ISkillAttribute>|null} [attributes] SkillDefinition attributes
                 * @property {string|null} [assembledID] SkillDefinition assembledID
                 * @property {infinitusai.be.INullableInt32|null} [tierLevel] SkillDefinition tierLevel
                 */
    
                /**
                 * Constructs a new SkillDefinition.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SkillDefinition.
                 * @implements ISkillDefinition
                 * @constructor
                 * @param {infinitusai.be.ISkillDefinition=} [properties] Properties to set
                 */
                function SkillDefinition(properties) {
                    this.attributes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SkillDefinition name.
                 * @member {string} name
                 * @memberof infinitusai.be.SkillDefinition
                 * @instance
                 */
                SkillDefinition.prototype.name = "";
    
                /**
                 * SkillDefinition description.
                 * @member {string} description
                 * @memberof infinitusai.be.SkillDefinition
                 * @instance
                 */
                SkillDefinition.prototype.description = "";
    
                /**
                 * SkillDefinition uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.be.SkillDefinition
                 * @instance
                 */
                SkillDefinition.prototype.uuid = "";
    
                /**
                 * SkillDefinition attributes.
                 * @member {Array.<infinitusai.be.ISkillAttribute>} attributes
                 * @memberof infinitusai.be.SkillDefinition
                 * @instance
                 */
                SkillDefinition.prototype.attributes = $util.emptyArray;
    
                /**
                 * SkillDefinition assembledID.
                 * @member {string} assembledID
                 * @memberof infinitusai.be.SkillDefinition
                 * @instance
                 */
                SkillDefinition.prototype.assembledID = "";
    
                /**
                 * SkillDefinition tierLevel.
                 * @member {infinitusai.be.INullableInt32|null|undefined} tierLevel
                 * @memberof infinitusai.be.SkillDefinition
                 * @instance
                 */
                SkillDefinition.prototype.tierLevel = null;
    
                /**
                 * Creates a new SkillDefinition instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {infinitusai.be.ISkillDefinition=} [properties] Properties to set
                 * @returns {infinitusai.be.SkillDefinition} SkillDefinition instance
                 */
                SkillDefinition.create = function create(properties) {
                    return new SkillDefinition(properties);
                };
    
                /**
                 * Encodes the specified SkillDefinition message. Does not implicitly {@link infinitusai.be.SkillDefinition.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {infinitusai.be.ISkillDefinition} message SkillDefinition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SkillDefinition.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.uuid);
                    if (message.attributes != null && message.attributes.length)
                        for (var i = 0; i < message.attributes.length; ++i)
                            $root.infinitusai.be.SkillAttribute.encode(message.attributes[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.assembledID != null && Object.hasOwnProperty.call(message, "assembledID"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.assembledID);
                    if (message.tierLevel != null && Object.hasOwnProperty.call(message, "tierLevel"))
                        $root.infinitusai.be.NullableInt32.encode(message.tierLevel, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SkillDefinition message, length delimited. Does not implicitly {@link infinitusai.be.SkillDefinition.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {infinitusai.be.ISkillDefinition} message SkillDefinition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SkillDefinition.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SkillDefinition message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SkillDefinition} SkillDefinition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SkillDefinition.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SkillDefinition();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.description = reader.string();
                                break;
                            }
                        case 3: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.attributes && message.attributes.length))
                                    message.attributes = [];
                                message.attributes.push($root.infinitusai.be.SkillAttribute.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                message.assembledID = reader.string();
                                break;
                            }
                        case 6: {
                                message.tierLevel = $root.infinitusai.be.NullableInt32.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SkillDefinition message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SkillDefinition} SkillDefinition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SkillDefinition.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SkillDefinition message.
                 * @function verify
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SkillDefinition.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.attributes != null && message.hasOwnProperty("attributes")) {
                        if (!Array.isArray(message.attributes))
                            return "attributes: array expected";
                        for (var i = 0; i < message.attributes.length; ++i) {
                            var error = $root.infinitusai.be.SkillAttribute.verify(message.attributes[i]);
                            if (error)
                                return "attributes." + error;
                        }
                    }
                    if (message.assembledID != null && message.hasOwnProperty("assembledID"))
                        if (!$util.isString(message.assembledID))
                            return "assembledID: string expected";
                    if (message.tierLevel != null && message.hasOwnProperty("tierLevel")) {
                        var error = $root.infinitusai.be.NullableInt32.verify(message.tierLevel);
                        if (error)
                            return "tierLevel." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a SkillDefinition message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SkillDefinition} SkillDefinition
                 */
                SkillDefinition.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SkillDefinition)
                        return object;
                    var message = new $root.infinitusai.be.SkillDefinition();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.attributes) {
                        if (!Array.isArray(object.attributes))
                            throw TypeError(".infinitusai.be.SkillDefinition.attributes: array expected");
                        message.attributes = [];
                        for (var i = 0; i < object.attributes.length; ++i) {
                            if (typeof object.attributes[i] !== "object")
                                throw TypeError(".infinitusai.be.SkillDefinition.attributes: object expected");
                            message.attributes[i] = $root.infinitusai.be.SkillAttribute.fromObject(object.attributes[i]);
                        }
                    }
                    if (object.assembledID != null)
                        message.assembledID = String(object.assembledID);
                    if (object.tierLevel != null) {
                        if (typeof object.tierLevel !== "object")
                            throw TypeError(".infinitusai.be.SkillDefinition.tierLevel: object expected");
                        message.tierLevel = $root.infinitusai.be.NullableInt32.fromObject(object.tierLevel);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SkillDefinition message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {infinitusai.be.SkillDefinition} message SkillDefinition
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SkillDefinition.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.attributes = [];
                    if (options.defaults) {
                        object.name = "";
                        object.description = "";
                        object.uuid = "";
                        object.assembledID = "";
                        object.tierLevel = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.attributes && message.attributes.length) {
                        object.attributes = [];
                        for (var j = 0; j < message.attributes.length; ++j)
                            object.attributes[j] = $root.infinitusai.be.SkillAttribute.toObject(message.attributes[j], options);
                    }
                    if (message.assembledID != null && message.hasOwnProperty("assembledID"))
                        object.assembledID = message.assembledID;
                    if (message.tierLevel != null && message.hasOwnProperty("tierLevel"))
                        object.tierLevel = $root.infinitusai.be.NullableInt32.toObject(message.tierLevel, options);
                    return object;
                };
    
                /**
                 * Converts this SkillDefinition to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SkillDefinition
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SkillDefinition.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SkillDefinition
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SkillDefinition
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SkillDefinition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SkillDefinition";
                };
    
                return SkillDefinition;
            })();
    
            be.UpsertSkillsRequest = (function() {
    
                /**
                 * Properties of an UpsertSkillsRequest.
                 * @memberof infinitusai.be
                 * @interface IUpsertSkillsRequest
                 * @property {Array.<infinitusai.be.ISkillDefinition>|null} [skills] UpsertSkillsRequest skills
                 */
    
                /**
                 * Constructs a new UpsertSkillsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertSkillsRequest.
                 * @implements IUpsertSkillsRequest
                 * @constructor
                 * @param {infinitusai.be.IUpsertSkillsRequest=} [properties] Properties to set
                 */
                function UpsertSkillsRequest(properties) {
                    this.skills = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertSkillsRequest skills.
                 * @member {Array.<infinitusai.be.ISkillDefinition>} skills
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @instance
                 */
                UpsertSkillsRequest.prototype.skills = $util.emptyArray;
    
                /**
                 * Creates a new UpsertSkillsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {infinitusai.be.IUpsertSkillsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertSkillsRequest} UpsertSkillsRequest instance
                 */
                UpsertSkillsRequest.create = function create(properties) {
                    return new UpsertSkillsRequest(properties);
                };
    
                /**
                 * Encodes the specified UpsertSkillsRequest message. Does not implicitly {@link infinitusai.be.UpsertSkillsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {infinitusai.be.IUpsertSkillsRequest} message UpsertSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertSkillsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skills != null && message.skills.length)
                        for (var i = 0; i < message.skills.length; ++i)
                            $root.infinitusai.be.SkillDefinition.encode(message.skills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertSkillsRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpsertSkillsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {infinitusai.be.IUpsertSkillsRequest} message UpsertSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertSkillsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertSkillsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertSkillsRequest} UpsertSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertSkillsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertSkillsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.skills && message.skills.length))
                                    message.skills = [];
                                message.skills.push($root.infinitusai.be.SkillDefinition.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertSkillsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertSkillsRequest} UpsertSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertSkillsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertSkillsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skills != null && message.hasOwnProperty("skills")) {
                        if (!Array.isArray(message.skills))
                            return "skills: array expected";
                        for (var i = 0; i < message.skills.length; ++i) {
                            var error = $root.infinitusai.be.SkillDefinition.verify(message.skills[i]);
                            if (error)
                                return "skills." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertSkillsRequest} UpsertSkillsRequest
                 */
                UpsertSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertSkillsRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpsertSkillsRequest();
                    if (object.skills) {
                        if (!Array.isArray(object.skills))
                            throw TypeError(".infinitusai.be.UpsertSkillsRequest.skills: array expected");
                        message.skills = [];
                        for (var i = 0; i < object.skills.length; ++i) {
                            if (typeof object.skills[i] !== "object")
                                throw TypeError(".infinitusai.be.UpsertSkillsRequest.skills: object expected");
                            message.skills[i] = $root.infinitusai.be.SkillDefinition.fromObject(object.skills[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {infinitusai.be.UpsertSkillsRequest} message UpsertSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skills = [];
                    if (message.skills && message.skills.length) {
                        object.skills = [];
                        for (var j = 0; j < message.skills.length; ++j)
                            object.skills[j] = $root.infinitusai.be.SkillDefinition.toObject(message.skills[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this UpsertSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertSkillsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertSkillsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertSkillsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertSkillsRequest";
                };
    
                return UpsertSkillsRequest;
            })();
    
            be.UpsertSkillsResponse = (function() {
    
                /**
                 * Properties of an UpsertSkillsResponse.
                 * @memberof infinitusai.be
                 * @interface IUpsertSkillsResponse
                 * @property {Array.<infinitusai.be.ISkillDefinition>|null} [upsertedSkills] UpsertSkillsResponse upsertedSkills
                 */
    
                /**
                 * Constructs a new UpsertSkillsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertSkillsResponse.
                 * @implements IUpsertSkillsResponse
                 * @constructor
                 * @param {infinitusai.be.IUpsertSkillsResponse=} [properties] Properties to set
                 */
                function UpsertSkillsResponse(properties) {
                    this.upsertedSkills = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertSkillsResponse upsertedSkills.
                 * @member {Array.<infinitusai.be.ISkillDefinition>} upsertedSkills
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @instance
                 */
                UpsertSkillsResponse.prototype.upsertedSkills = $util.emptyArray;
    
                /**
                 * Creates a new UpsertSkillsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {infinitusai.be.IUpsertSkillsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertSkillsResponse} UpsertSkillsResponse instance
                 */
                UpsertSkillsResponse.create = function create(properties) {
                    return new UpsertSkillsResponse(properties);
                };
    
                /**
                 * Encodes the specified UpsertSkillsResponse message. Does not implicitly {@link infinitusai.be.UpsertSkillsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {infinitusai.be.IUpsertSkillsResponse} message UpsertSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertSkillsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.upsertedSkills != null && message.upsertedSkills.length)
                        for (var i = 0; i < message.upsertedSkills.length; ++i)
                            $root.infinitusai.be.SkillDefinition.encode(message.upsertedSkills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertSkillsResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpsertSkillsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {infinitusai.be.IUpsertSkillsResponse} message UpsertSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertSkillsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertSkillsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertSkillsResponse} UpsertSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertSkillsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertSkillsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.upsertedSkills && message.upsertedSkills.length))
                                    message.upsertedSkills = [];
                                message.upsertedSkills.push($root.infinitusai.be.SkillDefinition.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertSkillsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertSkillsResponse} UpsertSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertSkillsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertSkillsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.upsertedSkills != null && message.hasOwnProperty("upsertedSkills")) {
                        if (!Array.isArray(message.upsertedSkills))
                            return "upsertedSkills: array expected";
                        for (var i = 0; i < message.upsertedSkills.length; ++i) {
                            var error = $root.infinitusai.be.SkillDefinition.verify(message.upsertedSkills[i]);
                            if (error)
                                return "upsertedSkills." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertSkillsResponse} UpsertSkillsResponse
                 */
                UpsertSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertSkillsResponse)
                        return object;
                    var message = new $root.infinitusai.be.UpsertSkillsResponse();
                    if (object.upsertedSkills) {
                        if (!Array.isArray(object.upsertedSkills))
                            throw TypeError(".infinitusai.be.UpsertSkillsResponse.upsertedSkills: array expected");
                        message.upsertedSkills = [];
                        for (var i = 0; i < object.upsertedSkills.length; ++i) {
                            if (typeof object.upsertedSkills[i] !== "object")
                                throw TypeError(".infinitusai.be.UpsertSkillsResponse.upsertedSkills: object expected");
                            message.upsertedSkills[i] = $root.infinitusai.be.SkillDefinition.fromObject(object.upsertedSkills[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {infinitusai.be.UpsertSkillsResponse} message UpsertSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.upsertedSkills = [];
                    if (message.upsertedSkills && message.upsertedSkills.length) {
                        object.upsertedSkills = [];
                        for (var j = 0; j < message.upsertedSkills.length; ++j)
                            object.upsertedSkills[j] = $root.infinitusai.be.SkillDefinition.toObject(message.upsertedSkills[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this UpsertSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertSkillsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertSkillsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertSkillsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertSkillsResponse";
                };
    
                return UpsertSkillsResponse;
            })();
    
            be.GetSkillsRequest = (function() {
    
                /**
                 * Properties of a GetSkillsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetSkillsRequest
                 * @property {Array.<string>|null} [uuids] GetSkillsRequest uuids
                 */
    
                /**
                 * Constructs a new GetSkillsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetSkillsRequest.
                 * @implements IGetSkillsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetSkillsRequest=} [properties] Properties to set
                 */
                function GetSkillsRequest(properties) {
                    this.uuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSkillsRequest uuids.
                 * @member {Array.<string>} uuids
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @instance
                 */
                GetSkillsRequest.prototype.uuids = $util.emptyArray;
    
                /**
                 * Creates a new GetSkillsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetSkillsRequest} GetSkillsRequest instance
                 */
                GetSkillsRequest.create = function create(properties) {
                    return new GetSkillsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetSkillsRequest message. Does not implicitly {@link infinitusai.be.GetSkillsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsRequest} message GetSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuids != null && message.uuids.length)
                        for (var i = 0; i < message.uuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSkillsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetSkillsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsRequest} message GetSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSkillsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetSkillsRequest} GetSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetSkillsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.uuids && message.uuids.length))
                                    message.uuids = [];
                                message.uuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSkillsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetSkillsRequest} GetSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSkillsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuids != null && message.hasOwnProperty("uuids")) {
                        if (!Array.isArray(message.uuids))
                            return "uuids: array expected";
                        for (var i = 0; i < message.uuids.length; ++i)
                            if (!$util.isString(message.uuids[i]))
                                return "uuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetSkillsRequest} GetSkillsRequest
                 */
                GetSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetSkillsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetSkillsRequest();
                    if (object.uuids) {
                        if (!Array.isArray(object.uuids))
                            throw TypeError(".infinitusai.be.GetSkillsRequest.uuids: array expected");
                        message.uuids = [];
                        for (var i = 0; i < object.uuids.length; ++i)
                            message.uuids[i] = String(object.uuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {infinitusai.be.GetSkillsRequest} message GetSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uuids = [];
                    if (message.uuids && message.uuids.length) {
                        object.uuids = [];
                        for (var j = 0; j < message.uuids.length; ++j)
                            object.uuids[j] = message.uuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSkillsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetSkillsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSkillsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetSkillsRequest";
                };
    
                return GetSkillsRequest;
            })();
    
            be.FoundSkillOrNot = (function() {
    
                /**
                 * Properties of a FoundSkillOrNot.
                 * @memberof infinitusai.be
                 * @interface IFoundSkillOrNot
                 * @property {boolean|null} [found] FoundSkillOrNot found
                 * @property {infinitusai.be.ISkillDefinition|null} [skill] FoundSkillOrNot skill
                 */
    
                /**
                 * Constructs a new FoundSkillOrNot.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FoundSkillOrNot.
                 * @implements IFoundSkillOrNot
                 * @constructor
                 * @param {infinitusai.be.IFoundSkillOrNot=} [properties] Properties to set
                 */
                function FoundSkillOrNot(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FoundSkillOrNot found.
                 * @member {boolean} found
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @instance
                 */
                FoundSkillOrNot.prototype.found = false;
    
                /**
                 * FoundSkillOrNot skill.
                 * @member {infinitusai.be.ISkillDefinition|null|undefined} skill
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @instance
                 */
                FoundSkillOrNot.prototype.skill = null;
    
                /**
                 * Creates a new FoundSkillOrNot instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {infinitusai.be.IFoundSkillOrNot=} [properties] Properties to set
                 * @returns {infinitusai.be.FoundSkillOrNot} FoundSkillOrNot instance
                 */
                FoundSkillOrNot.create = function create(properties) {
                    return new FoundSkillOrNot(properties);
                };
    
                /**
                 * Encodes the specified FoundSkillOrNot message. Does not implicitly {@link infinitusai.be.FoundSkillOrNot.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {infinitusai.be.IFoundSkillOrNot} message FoundSkillOrNot message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FoundSkillOrNot.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.found != null && Object.hasOwnProperty.call(message, "found"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.found);
                    if (message.skill != null && Object.hasOwnProperty.call(message, "skill"))
                        $root.infinitusai.be.SkillDefinition.encode(message.skill, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FoundSkillOrNot message, length delimited. Does not implicitly {@link infinitusai.be.FoundSkillOrNot.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {infinitusai.be.IFoundSkillOrNot} message FoundSkillOrNot message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FoundSkillOrNot.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FoundSkillOrNot message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FoundSkillOrNot} FoundSkillOrNot
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FoundSkillOrNot.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FoundSkillOrNot();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.found = reader.bool();
                                break;
                            }
                        case 2: {
                                message.skill = $root.infinitusai.be.SkillDefinition.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FoundSkillOrNot message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FoundSkillOrNot} FoundSkillOrNot
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FoundSkillOrNot.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FoundSkillOrNot message.
                 * @function verify
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FoundSkillOrNot.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.found != null && message.hasOwnProperty("found"))
                        if (typeof message.found !== "boolean")
                            return "found: boolean expected";
                    if (message.skill != null && message.hasOwnProperty("skill")) {
                        var error = $root.infinitusai.be.SkillDefinition.verify(message.skill);
                        if (error)
                            return "skill." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FoundSkillOrNot message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FoundSkillOrNot} FoundSkillOrNot
                 */
                FoundSkillOrNot.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FoundSkillOrNot)
                        return object;
                    var message = new $root.infinitusai.be.FoundSkillOrNot();
                    if (object.found != null)
                        message.found = Boolean(object.found);
                    if (object.skill != null) {
                        if (typeof object.skill !== "object")
                            throw TypeError(".infinitusai.be.FoundSkillOrNot.skill: object expected");
                        message.skill = $root.infinitusai.be.SkillDefinition.fromObject(object.skill);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FoundSkillOrNot message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {infinitusai.be.FoundSkillOrNot} message FoundSkillOrNot
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FoundSkillOrNot.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.found = false;
                        object.skill = null;
                    }
                    if (message.found != null && message.hasOwnProperty("found"))
                        object.found = message.found;
                    if (message.skill != null && message.hasOwnProperty("skill"))
                        object.skill = $root.infinitusai.be.SkillDefinition.toObject(message.skill, options);
                    return object;
                };
    
                /**
                 * Converts this FoundSkillOrNot to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FoundSkillOrNot.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FoundSkillOrNot
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FoundSkillOrNot
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FoundSkillOrNot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FoundSkillOrNot";
                };
    
                return FoundSkillOrNot;
            })();
    
            be.GetSkillsResponse = (function() {
    
                /**
                 * Properties of a GetSkillsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetSkillsResponse
                 * @property {Array.<infinitusai.be.IFoundSkillOrNot>|null} [skills] GetSkillsResponse skills
                 */
    
                /**
                 * Constructs a new GetSkillsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetSkillsResponse.
                 * @implements IGetSkillsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetSkillsResponse=} [properties] Properties to set
                 */
                function GetSkillsResponse(properties) {
                    this.skills = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSkillsResponse skills.
                 * @member {Array.<infinitusai.be.IFoundSkillOrNot>} skills
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @instance
                 */
                GetSkillsResponse.prototype.skills = $util.emptyArray;
    
                /**
                 * Creates a new GetSkillsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetSkillsResponse} GetSkillsResponse instance
                 */
                GetSkillsResponse.create = function create(properties) {
                    return new GetSkillsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetSkillsResponse message. Does not implicitly {@link infinitusai.be.GetSkillsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsResponse} message GetSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skills != null && message.skills.length)
                        for (var i = 0; i < message.skills.length; ++i)
                            $root.infinitusai.be.FoundSkillOrNot.encode(message.skills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSkillsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetSkillsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsResponse} message GetSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSkillsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetSkillsResponse} GetSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetSkillsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.skills && message.skills.length))
                                    message.skills = [];
                                message.skills.push($root.infinitusai.be.FoundSkillOrNot.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSkillsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetSkillsResponse} GetSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSkillsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skills != null && message.hasOwnProperty("skills")) {
                        if (!Array.isArray(message.skills))
                            return "skills: array expected";
                        for (var i = 0; i < message.skills.length; ++i) {
                            var error = $root.infinitusai.be.FoundSkillOrNot.verify(message.skills[i]);
                            if (error)
                                return "skills." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetSkillsResponse} GetSkillsResponse
                 */
                GetSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetSkillsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetSkillsResponse();
                    if (object.skills) {
                        if (!Array.isArray(object.skills))
                            throw TypeError(".infinitusai.be.GetSkillsResponse.skills: array expected");
                        message.skills = [];
                        for (var i = 0; i < object.skills.length; ++i) {
                            if (typeof object.skills[i] !== "object")
                                throw TypeError(".infinitusai.be.GetSkillsResponse.skills: object expected");
                            message.skills[i] = $root.infinitusai.be.FoundSkillOrNot.fromObject(object.skills[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {infinitusai.be.GetSkillsResponse} message GetSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skills = [];
                    if (message.skills && message.skills.length) {
                        object.skills = [];
                        for (var j = 0; j < message.skills.length; ++j)
                            object.skills[j] = $root.infinitusai.be.FoundSkillOrNot.toObject(message.skills[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSkillsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetSkillsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSkillsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetSkillsResponse";
                };
    
                return GetSkillsResponse;
            })();
    
            be.GetAllSkillsRequest = (function() {
    
                /**
                 * Properties of a GetAllSkillsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetAllSkillsRequest
                 */
    
                /**
                 * Constructs a new GetAllSkillsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAllSkillsRequest.
                 * @implements IGetAllSkillsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetAllSkillsRequest=} [properties] Properties to set
                 */
                function GetAllSkillsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetAllSkillsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetAllSkillsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAllSkillsRequest} GetAllSkillsRequest instance
                 */
                GetAllSkillsRequest.create = function create(properties) {
                    return new GetAllSkillsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetAllSkillsRequest message. Does not implicitly {@link infinitusai.be.GetAllSkillsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetAllSkillsRequest} message GetAllSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAllSkillsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAllSkillsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetAllSkillsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetAllSkillsRequest} message GetAllSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAllSkillsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAllSkillsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAllSkillsRequest} GetAllSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAllSkillsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAllSkillsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAllSkillsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAllSkillsRequest} GetAllSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAllSkillsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAllSkillsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAllSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetAllSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAllSkillsRequest} GetAllSkillsRequest
                 */
                GetAllSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAllSkillsRequest)
                        return object;
                    return new $root.infinitusai.be.GetAllSkillsRequest();
                };
    
                /**
                 * Creates a plain object from a GetAllSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {infinitusai.be.GetAllSkillsRequest} message GetAllSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAllSkillsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetAllSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAllSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAllSkillsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAllSkillsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAllSkillsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAllSkillsRequest";
                };
    
                return GetAllSkillsRequest;
            })();
    
            be.GetAllSkillsResponse = (function() {
    
                /**
                 * Properties of a GetAllSkillsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetAllSkillsResponse
                 * @property {Array.<infinitusai.be.ISkillDefinition>|null} [skills] GetAllSkillsResponse skills
                 */
    
                /**
                 * Constructs a new GetAllSkillsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAllSkillsResponse.
                 * @implements IGetAllSkillsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetAllSkillsResponse=} [properties] Properties to set
                 */
                function GetAllSkillsResponse(properties) {
                    this.skills = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAllSkillsResponse skills.
                 * @member {Array.<infinitusai.be.ISkillDefinition>} skills
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @instance
                 */
                GetAllSkillsResponse.prototype.skills = $util.emptyArray;
    
                /**
                 * Creates a new GetAllSkillsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetAllSkillsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAllSkillsResponse} GetAllSkillsResponse instance
                 */
                GetAllSkillsResponse.create = function create(properties) {
                    return new GetAllSkillsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetAllSkillsResponse message. Does not implicitly {@link infinitusai.be.GetAllSkillsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetAllSkillsResponse} message GetAllSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAllSkillsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skills != null && message.skills.length)
                        for (var i = 0; i < message.skills.length; ++i)
                            $root.infinitusai.be.SkillDefinition.encode(message.skills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAllSkillsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetAllSkillsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetAllSkillsResponse} message GetAllSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAllSkillsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAllSkillsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAllSkillsResponse} GetAllSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAllSkillsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAllSkillsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.skills && message.skills.length))
                                    message.skills = [];
                                message.skills.push($root.infinitusai.be.SkillDefinition.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAllSkillsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAllSkillsResponse} GetAllSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAllSkillsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAllSkillsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAllSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skills != null && message.hasOwnProperty("skills")) {
                        if (!Array.isArray(message.skills))
                            return "skills: array expected";
                        for (var i = 0; i < message.skills.length; ++i) {
                            var error = $root.infinitusai.be.SkillDefinition.verify(message.skills[i]);
                            if (error)
                                return "skills." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetAllSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAllSkillsResponse} GetAllSkillsResponse
                 */
                GetAllSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAllSkillsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetAllSkillsResponse();
                    if (object.skills) {
                        if (!Array.isArray(object.skills))
                            throw TypeError(".infinitusai.be.GetAllSkillsResponse.skills: array expected");
                        message.skills = [];
                        for (var i = 0; i < object.skills.length; ++i) {
                            if (typeof object.skills[i] !== "object")
                                throw TypeError(".infinitusai.be.GetAllSkillsResponse.skills: object expected");
                            message.skills[i] = $root.infinitusai.be.SkillDefinition.fromObject(object.skills[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAllSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {infinitusai.be.GetAllSkillsResponse} message GetAllSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAllSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skills = [];
                    if (message.skills && message.skills.length) {
                        object.skills = [];
                        for (var j = 0; j < message.skills.length; ++j)
                            object.skills[j] = $root.infinitusai.be.SkillDefinition.toObject(message.skills[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetAllSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAllSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAllSkillsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAllSkillsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAllSkillsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAllSkillsResponse";
                };
    
                return GetAllSkillsResponse;
            })();
    
            be.DeleteSkillRequest = (function() {
    
                /**
                 * Properties of a DeleteSkillRequest.
                 * @memberof infinitusai.be
                 * @interface IDeleteSkillRequest
                 * @property {string|null} [uuid] DeleteSkillRequest uuid
                 */
    
                /**
                 * Constructs a new DeleteSkillRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteSkillRequest.
                 * @implements IDeleteSkillRequest
                 * @constructor
                 * @param {infinitusai.be.IDeleteSkillRequest=} [properties] Properties to set
                 */
                function DeleteSkillRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteSkillRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @instance
                 */
                DeleteSkillRequest.prototype.uuid = "";
    
                /**
                 * Creates a new DeleteSkillRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {infinitusai.be.IDeleteSkillRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteSkillRequest} DeleteSkillRequest instance
                 */
                DeleteSkillRequest.create = function create(properties) {
                    return new DeleteSkillRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteSkillRequest message. Does not implicitly {@link infinitusai.be.DeleteSkillRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {infinitusai.be.IDeleteSkillRequest} message DeleteSkillRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSkillRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteSkillRequest message, length delimited. Does not implicitly {@link infinitusai.be.DeleteSkillRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {infinitusai.be.IDeleteSkillRequest} message DeleteSkillRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSkillRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteSkillRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteSkillRequest} DeleteSkillRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSkillRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteSkillRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteSkillRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteSkillRequest} DeleteSkillRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSkillRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteSkillRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSkillRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteSkillRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteSkillRequest} DeleteSkillRequest
                 */
                DeleteSkillRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteSkillRequest)
                        return object;
                    var message = new $root.infinitusai.be.DeleteSkillRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteSkillRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {infinitusai.be.DeleteSkillRequest} message DeleteSkillRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSkillRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this DeleteSkillRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSkillRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteSkillRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteSkillRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSkillRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteSkillRequest";
                };
    
                return DeleteSkillRequest;
            })();
    
            be.DeleteSkillResponse = (function() {
    
                /**
                 * Properties of a DeleteSkillResponse.
                 * @memberof infinitusai.be
                 * @interface IDeleteSkillResponse
                 */
    
                /**
                 * Constructs a new DeleteSkillResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteSkillResponse.
                 * @implements IDeleteSkillResponse
                 * @constructor
                 * @param {infinitusai.be.IDeleteSkillResponse=} [properties] Properties to set
                 */
                function DeleteSkillResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteSkillResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {infinitusai.be.IDeleteSkillResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteSkillResponse} DeleteSkillResponse instance
                 */
                DeleteSkillResponse.create = function create(properties) {
                    return new DeleteSkillResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteSkillResponse message. Does not implicitly {@link infinitusai.be.DeleteSkillResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {infinitusai.be.IDeleteSkillResponse} message DeleteSkillResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSkillResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteSkillResponse message, length delimited. Does not implicitly {@link infinitusai.be.DeleteSkillResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {infinitusai.be.IDeleteSkillResponse} message DeleteSkillResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSkillResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteSkillResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteSkillResponse} DeleteSkillResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSkillResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteSkillResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteSkillResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteSkillResponse} DeleteSkillResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSkillResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteSkillResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSkillResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteSkillResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteSkillResponse} DeleteSkillResponse
                 */
                DeleteSkillResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteSkillResponse)
                        return object;
                    return new $root.infinitusai.be.DeleteSkillResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteSkillResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {infinitusai.be.DeleteSkillResponse} message DeleteSkillResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSkillResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteSkillResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSkillResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteSkillResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteSkillResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSkillResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteSkillResponse";
                };
    
                return DeleteSkillResponse;
            })();
    
            be.OperatorSkill = (function() {
    
                /**
                 * Properties of an OperatorSkill.
                 * @memberof infinitusai.be
                 * @interface IOperatorSkill
                 * @property {string|null} [operatorUuid] OperatorSkill operatorUuid
                 * @property {string|null} [skillUuid] OperatorSkill skillUuid
                 * @property {infinitusai.be.OperatorSkill.LEVEL|null} [level] OperatorSkill level
                 * @property {infinitusai.be.IDate|null} [date] OperatorSkill date
                 */
    
                /**
                 * Constructs a new OperatorSkill.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorSkill.
                 * @implements IOperatorSkill
                 * @constructor
                 * @param {infinitusai.be.IOperatorSkill=} [properties] Properties to set
                 */
                function OperatorSkill(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorSkill operatorUuid.
                 * @member {string} operatorUuid
                 * @memberof infinitusai.be.OperatorSkill
                 * @instance
                 */
                OperatorSkill.prototype.operatorUuid = "";
    
                /**
                 * OperatorSkill skillUuid.
                 * @member {string} skillUuid
                 * @memberof infinitusai.be.OperatorSkill
                 * @instance
                 */
                OperatorSkill.prototype.skillUuid = "";
    
                /**
                 * OperatorSkill level.
                 * @member {infinitusai.be.OperatorSkill.LEVEL} level
                 * @memberof infinitusai.be.OperatorSkill
                 * @instance
                 */
                OperatorSkill.prototype.level = 0;
    
                /**
                 * OperatorSkill date.
                 * @member {infinitusai.be.IDate|null|undefined} date
                 * @memberof infinitusai.be.OperatorSkill
                 * @instance
                 */
                OperatorSkill.prototype.date = null;
    
                /**
                 * Creates a new OperatorSkill instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {infinitusai.be.IOperatorSkill=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorSkill} OperatorSkill instance
                 */
                OperatorSkill.create = function create(properties) {
                    return new OperatorSkill(properties);
                };
    
                /**
                 * Encodes the specified OperatorSkill message. Does not implicitly {@link infinitusai.be.OperatorSkill.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {infinitusai.be.IOperatorSkill} message OperatorSkill message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorSkill.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorUuid != null && Object.hasOwnProperty.call(message, "operatorUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.operatorUuid);
                    if (message.skillUuid != null && Object.hasOwnProperty.call(message, "skillUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.skillUuid);
                    if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.level);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        $root.infinitusai.be.Date.encode(message.date, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorSkill message, length delimited. Does not implicitly {@link infinitusai.be.OperatorSkill.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {infinitusai.be.IOperatorSkill} message OperatorSkill message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorSkill.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorSkill message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorSkill} OperatorSkill
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorSkill.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorSkill();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operatorUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.skillUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.level = reader.int32();
                                break;
                            }
                        case 4: {
                                message.date = $root.infinitusai.be.Date.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorSkill message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorSkill} OperatorSkill
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorSkill.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorSkill message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorSkill.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorUuid != null && message.hasOwnProperty("operatorUuid"))
                        if (!$util.isString(message.operatorUuid))
                            return "operatorUuid: string expected";
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        if (!$util.isString(message.skillUuid))
                            return "skillUuid: string expected";
                    if (message.level != null && message.hasOwnProperty("level"))
                        switch (message.level) {
                        default:
                            return "level: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.infinitusai.be.Date.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an OperatorSkill message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorSkill} OperatorSkill
                 */
                OperatorSkill.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorSkill)
                        return object;
                    var message = new $root.infinitusai.be.OperatorSkill();
                    if (object.operatorUuid != null)
                        message.operatorUuid = String(object.operatorUuid);
                    if (object.skillUuid != null)
                        message.skillUuid = String(object.skillUuid);
                    switch (object.level) {
                    default:
                        if (typeof object.level === "number") {
                            message.level = object.level;
                            break;
                        }
                        break;
                    case "INACTIVE":
                    case 0:
                        message.level = 0;
                        break;
                    case "SECONDARY":
                    case 1:
                        message.level = 1;
                        break;
                    case "PRIMARY":
                    case 2:
                        message.level = 2;
                        break;
                    }
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".infinitusai.be.OperatorSkill.date: object expected");
                        message.date = $root.infinitusai.be.Date.fromObject(object.date);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorSkill message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {infinitusai.be.OperatorSkill} message OperatorSkill
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorSkill.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operatorUuid = "";
                        object.skillUuid = "";
                        object.level = options.enums === String ? "INACTIVE" : 0;
                        object.date = null;
                    }
                    if (message.operatorUuid != null && message.hasOwnProperty("operatorUuid"))
                        object.operatorUuid = message.operatorUuid;
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        object.skillUuid = message.skillUuid;
                    if (message.level != null && message.hasOwnProperty("level"))
                        object.level = options.enums === String ? $root.infinitusai.be.OperatorSkill.LEVEL[message.level] === undefined ? message.level : $root.infinitusai.be.OperatorSkill.LEVEL[message.level] : message.level;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.infinitusai.be.Date.toObject(message.date, options);
                    return object;
                };
    
                /**
                 * Converts this OperatorSkill to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorSkill
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorSkill.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorSkill
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorSkill
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorSkill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorSkill";
                };
    
                /**
                 * LEVEL enum.
                 * @name infinitusai.be.OperatorSkill.LEVEL
                 * @enum {number}
                 * @property {number} INACTIVE=0 INACTIVE value
                 * @property {number} SECONDARY=1 SECONDARY value
                 * @property {number} PRIMARY=2 PRIMARY value
                 */
                OperatorSkill.LEVEL = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "INACTIVE"] = 0;
                    values[valuesById[1] = "SECONDARY"] = 1;
                    values[valuesById[2] = "PRIMARY"] = 2;
                    return values;
                })();
    
                return OperatorSkill;
            })();
    
            be.UpsertOperatorSkillRequest = (function() {
    
                /**
                 * Properties of an UpsertOperatorSkillRequest.
                 * @memberof infinitusai.be
                 * @interface IUpsertOperatorSkillRequest
                 * @property {Array.<infinitusai.be.IOperatorSkill>|null} [operatorSkills] UpsertOperatorSkillRequest operatorSkills
                 */
    
                /**
                 * Constructs a new UpsertOperatorSkillRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertOperatorSkillRequest.
                 * @implements IUpsertOperatorSkillRequest
                 * @constructor
                 * @param {infinitusai.be.IUpsertOperatorSkillRequest=} [properties] Properties to set
                 */
                function UpsertOperatorSkillRequest(properties) {
                    this.operatorSkills = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertOperatorSkillRequest operatorSkills.
                 * @member {Array.<infinitusai.be.IOperatorSkill>} operatorSkills
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @instance
                 */
                UpsertOperatorSkillRequest.prototype.operatorSkills = $util.emptyArray;
    
                /**
                 * Creates a new UpsertOperatorSkillRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {infinitusai.be.IUpsertOperatorSkillRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertOperatorSkillRequest} UpsertOperatorSkillRequest instance
                 */
                UpsertOperatorSkillRequest.create = function create(properties) {
                    return new UpsertOperatorSkillRequest(properties);
                };
    
                /**
                 * Encodes the specified UpsertOperatorSkillRequest message. Does not implicitly {@link infinitusai.be.UpsertOperatorSkillRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {infinitusai.be.IUpsertOperatorSkillRequest} message UpsertOperatorSkillRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertOperatorSkillRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorSkills != null && message.operatorSkills.length)
                        for (var i = 0; i < message.operatorSkills.length; ++i)
                            $root.infinitusai.be.OperatorSkill.encode(message.operatorSkills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertOperatorSkillRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpsertOperatorSkillRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {infinitusai.be.IUpsertOperatorSkillRequest} message UpsertOperatorSkillRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertOperatorSkillRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertOperatorSkillRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertOperatorSkillRequest} UpsertOperatorSkillRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertOperatorSkillRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertOperatorSkillRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.operatorSkills && message.operatorSkills.length))
                                    message.operatorSkills = [];
                                message.operatorSkills.push($root.infinitusai.be.OperatorSkill.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertOperatorSkillRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertOperatorSkillRequest} UpsertOperatorSkillRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertOperatorSkillRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertOperatorSkillRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertOperatorSkillRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorSkills != null && message.hasOwnProperty("operatorSkills")) {
                        if (!Array.isArray(message.operatorSkills))
                            return "operatorSkills: array expected";
                        for (var i = 0; i < message.operatorSkills.length; ++i) {
                            var error = $root.infinitusai.be.OperatorSkill.verify(message.operatorSkills[i]);
                            if (error)
                                return "operatorSkills." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertOperatorSkillRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertOperatorSkillRequest} UpsertOperatorSkillRequest
                 */
                UpsertOperatorSkillRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertOperatorSkillRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpsertOperatorSkillRequest();
                    if (object.operatorSkills) {
                        if (!Array.isArray(object.operatorSkills))
                            throw TypeError(".infinitusai.be.UpsertOperatorSkillRequest.operatorSkills: array expected");
                        message.operatorSkills = [];
                        for (var i = 0; i < object.operatorSkills.length; ++i) {
                            if (typeof object.operatorSkills[i] !== "object")
                                throw TypeError(".infinitusai.be.UpsertOperatorSkillRequest.operatorSkills: object expected");
                            message.operatorSkills[i] = $root.infinitusai.be.OperatorSkill.fromObject(object.operatorSkills[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertOperatorSkillRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {infinitusai.be.UpsertOperatorSkillRequest} message UpsertOperatorSkillRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertOperatorSkillRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorSkills = [];
                    if (message.operatorSkills && message.operatorSkills.length) {
                        object.operatorSkills = [];
                        for (var j = 0; j < message.operatorSkills.length; ++j)
                            object.operatorSkills[j] = $root.infinitusai.be.OperatorSkill.toObject(message.operatorSkills[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this UpsertOperatorSkillRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertOperatorSkillRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertOperatorSkillRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertOperatorSkillRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertOperatorSkillRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertOperatorSkillRequest";
                };
    
                return UpsertOperatorSkillRequest;
            })();
    
            be.UpsertOperatorSkillResponse = (function() {
    
                /**
                 * Properties of an UpsertOperatorSkillResponse.
                 * @memberof infinitusai.be
                 * @interface IUpsertOperatorSkillResponse
                 */
    
                /**
                 * Constructs a new UpsertOperatorSkillResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertOperatorSkillResponse.
                 * @implements IUpsertOperatorSkillResponse
                 * @constructor
                 * @param {infinitusai.be.IUpsertOperatorSkillResponse=} [properties] Properties to set
                 */
                function UpsertOperatorSkillResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpsertOperatorSkillResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {infinitusai.be.IUpsertOperatorSkillResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertOperatorSkillResponse} UpsertOperatorSkillResponse instance
                 */
                UpsertOperatorSkillResponse.create = function create(properties) {
                    return new UpsertOperatorSkillResponse(properties);
                };
    
                /**
                 * Encodes the specified UpsertOperatorSkillResponse message. Does not implicitly {@link infinitusai.be.UpsertOperatorSkillResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {infinitusai.be.IUpsertOperatorSkillResponse} message UpsertOperatorSkillResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertOperatorSkillResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertOperatorSkillResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpsertOperatorSkillResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {infinitusai.be.IUpsertOperatorSkillResponse} message UpsertOperatorSkillResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertOperatorSkillResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertOperatorSkillResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertOperatorSkillResponse} UpsertOperatorSkillResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertOperatorSkillResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertOperatorSkillResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertOperatorSkillResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertOperatorSkillResponse} UpsertOperatorSkillResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertOperatorSkillResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertOperatorSkillResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertOperatorSkillResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpsertOperatorSkillResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertOperatorSkillResponse} UpsertOperatorSkillResponse
                 */
                UpsertOperatorSkillResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertOperatorSkillResponse)
                        return object;
                    return new $root.infinitusai.be.UpsertOperatorSkillResponse();
                };
    
                /**
                 * Creates a plain object from an UpsertOperatorSkillResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {infinitusai.be.UpsertOperatorSkillResponse} message UpsertOperatorSkillResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertOperatorSkillResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpsertOperatorSkillResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertOperatorSkillResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertOperatorSkillResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertOperatorSkillResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertOperatorSkillResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertOperatorSkillResponse";
                };
    
                return UpsertOperatorSkillResponse;
            })();
    
            be.GetSkillsForOperatorsRequest = (function() {
    
                /**
                 * Properties of a GetSkillsForOperatorsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetSkillsForOperatorsRequest
                 * @property {Array.<string>|null} [operatorUuids] GetSkillsForOperatorsRequest operatorUuids
                 */
    
                /**
                 * Constructs a new GetSkillsForOperatorsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetSkillsForOperatorsRequest.
                 * @implements IGetSkillsForOperatorsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetSkillsForOperatorsRequest=} [properties] Properties to set
                 */
                function GetSkillsForOperatorsRequest(properties) {
                    this.operatorUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSkillsForOperatorsRequest operatorUuids.
                 * @member {Array.<string>} operatorUuids
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @instance
                 */
                GetSkillsForOperatorsRequest.prototype.operatorUuids = $util.emptyArray;
    
                /**
                 * Creates a new GetSkillsForOperatorsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsForOperatorsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetSkillsForOperatorsRequest} GetSkillsForOperatorsRequest instance
                 */
                GetSkillsForOperatorsRequest.create = function create(properties) {
                    return new GetSkillsForOperatorsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetSkillsForOperatorsRequest message. Does not implicitly {@link infinitusai.be.GetSkillsForOperatorsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsForOperatorsRequest} message GetSkillsForOperatorsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsForOperatorsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorUuids != null && message.operatorUuids.length)
                        for (var i = 0; i < message.operatorUuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.operatorUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSkillsForOperatorsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetSkillsForOperatorsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsForOperatorsRequest} message GetSkillsForOperatorsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsForOperatorsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSkillsForOperatorsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetSkillsForOperatorsRequest} GetSkillsForOperatorsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsForOperatorsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetSkillsForOperatorsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.operatorUuids && message.operatorUuids.length))
                                    message.operatorUuids = [];
                                message.operatorUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSkillsForOperatorsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetSkillsForOperatorsRequest} GetSkillsForOperatorsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsForOperatorsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSkillsForOperatorsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSkillsForOperatorsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorUuids != null && message.hasOwnProperty("operatorUuids")) {
                        if (!Array.isArray(message.operatorUuids))
                            return "operatorUuids: array expected";
                        for (var i = 0; i < message.operatorUuids.length; ++i)
                            if (!$util.isString(message.operatorUuids[i]))
                                return "operatorUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetSkillsForOperatorsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetSkillsForOperatorsRequest} GetSkillsForOperatorsRequest
                 */
                GetSkillsForOperatorsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetSkillsForOperatorsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetSkillsForOperatorsRequest();
                    if (object.operatorUuids) {
                        if (!Array.isArray(object.operatorUuids))
                            throw TypeError(".infinitusai.be.GetSkillsForOperatorsRequest.operatorUuids: array expected");
                        message.operatorUuids = [];
                        for (var i = 0; i < object.operatorUuids.length; ++i)
                            message.operatorUuids[i] = String(object.operatorUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSkillsForOperatorsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {infinitusai.be.GetSkillsForOperatorsRequest} message GetSkillsForOperatorsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSkillsForOperatorsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorUuids = [];
                    if (message.operatorUuids && message.operatorUuids.length) {
                        object.operatorUuids = [];
                        for (var j = 0; j < message.operatorUuids.length; ++j)
                            object.operatorUuids[j] = message.operatorUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetSkillsForOperatorsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSkillsForOperatorsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSkillsForOperatorsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetSkillsForOperatorsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSkillsForOperatorsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetSkillsForOperatorsRequest";
                };
    
                return GetSkillsForOperatorsRequest;
            })();
    
            be.OperatorSkills = (function() {
    
                /**
                 * Properties of an OperatorSkills.
                 * @memberof infinitusai.be
                 * @interface IOperatorSkills
                 * @property {Array.<infinitusai.be.IOperatorSkill>|null} [operatorSkills] OperatorSkills operatorSkills
                 */
    
                /**
                 * Constructs a new OperatorSkills.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorSkills.
                 * @implements IOperatorSkills
                 * @constructor
                 * @param {infinitusai.be.IOperatorSkills=} [properties] Properties to set
                 */
                function OperatorSkills(properties) {
                    this.operatorSkills = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorSkills operatorSkills.
                 * @member {Array.<infinitusai.be.IOperatorSkill>} operatorSkills
                 * @memberof infinitusai.be.OperatorSkills
                 * @instance
                 */
                OperatorSkills.prototype.operatorSkills = $util.emptyArray;
    
                /**
                 * Creates a new OperatorSkills instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {infinitusai.be.IOperatorSkills=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorSkills} OperatorSkills instance
                 */
                OperatorSkills.create = function create(properties) {
                    return new OperatorSkills(properties);
                };
    
                /**
                 * Encodes the specified OperatorSkills message. Does not implicitly {@link infinitusai.be.OperatorSkills.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {infinitusai.be.IOperatorSkills} message OperatorSkills message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorSkills.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorSkills != null && message.operatorSkills.length)
                        for (var i = 0; i < message.operatorSkills.length; ++i)
                            $root.infinitusai.be.OperatorSkill.encode(message.operatorSkills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorSkills message, length delimited. Does not implicitly {@link infinitusai.be.OperatorSkills.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {infinitusai.be.IOperatorSkills} message OperatorSkills message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorSkills.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorSkills message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorSkills} OperatorSkills
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorSkills.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorSkills();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.operatorSkills && message.operatorSkills.length))
                                    message.operatorSkills = [];
                                message.operatorSkills.push($root.infinitusai.be.OperatorSkill.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorSkills message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorSkills} OperatorSkills
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorSkills.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorSkills message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorSkills.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorSkills != null && message.hasOwnProperty("operatorSkills")) {
                        if (!Array.isArray(message.operatorSkills))
                            return "operatorSkills: array expected";
                        for (var i = 0; i < message.operatorSkills.length; ++i) {
                            var error = $root.infinitusai.be.OperatorSkill.verify(message.operatorSkills[i]);
                            if (error)
                                return "operatorSkills." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an OperatorSkills message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorSkills} OperatorSkills
                 */
                OperatorSkills.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorSkills)
                        return object;
                    var message = new $root.infinitusai.be.OperatorSkills();
                    if (object.operatorSkills) {
                        if (!Array.isArray(object.operatorSkills))
                            throw TypeError(".infinitusai.be.OperatorSkills.operatorSkills: array expected");
                        message.operatorSkills = [];
                        for (var i = 0; i < object.operatorSkills.length; ++i) {
                            if (typeof object.operatorSkills[i] !== "object")
                                throw TypeError(".infinitusai.be.OperatorSkills.operatorSkills: object expected");
                            message.operatorSkills[i] = $root.infinitusai.be.OperatorSkill.fromObject(object.operatorSkills[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorSkills message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {infinitusai.be.OperatorSkills} message OperatorSkills
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorSkills.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorSkills = [];
                    if (message.operatorSkills && message.operatorSkills.length) {
                        object.operatorSkills = [];
                        for (var j = 0; j < message.operatorSkills.length; ++j)
                            object.operatorSkills[j] = $root.infinitusai.be.OperatorSkill.toObject(message.operatorSkills[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this OperatorSkills to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorSkills
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorSkills.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorSkills
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorSkills
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorSkills.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorSkills";
                };
    
                return OperatorSkills;
            })();
    
            be.GetSkillsForOperatorsResponse = (function() {
    
                /**
                 * Properties of a GetSkillsForOperatorsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetSkillsForOperatorsResponse
                 * @property {Object.<string,infinitusai.be.IOperatorSkills>|null} [operatorSkillsMapped] GetSkillsForOperatorsResponse operatorSkillsMapped
                 */
    
                /**
                 * Constructs a new GetSkillsForOperatorsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetSkillsForOperatorsResponse.
                 * @implements IGetSkillsForOperatorsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetSkillsForOperatorsResponse=} [properties] Properties to set
                 */
                function GetSkillsForOperatorsResponse(properties) {
                    this.operatorSkillsMapped = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSkillsForOperatorsResponse operatorSkillsMapped.
                 * @member {Object.<string,infinitusai.be.IOperatorSkills>} operatorSkillsMapped
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @instance
                 */
                GetSkillsForOperatorsResponse.prototype.operatorSkillsMapped = $util.emptyObject;
    
                /**
                 * Creates a new GetSkillsForOperatorsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsForOperatorsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetSkillsForOperatorsResponse} GetSkillsForOperatorsResponse instance
                 */
                GetSkillsForOperatorsResponse.create = function create(properties) {
                    return new GetSkillsForOperatorsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetSkillsForOperatorsResponse message. Does not implicitly {@link infinitusai.be.GetSkillsForOperatorsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsForOperatorsResponse} message GetSkillsForOperatorsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsForOperatorsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorSkillsMapped != null && Object.hasOwnProperty.call(message, "operatorSkillsMapped"))
                        for (var keys = Object.keys(message.operatorSkillsMapped), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.OperatorSkills.encode(message.operatorSkillsMapped[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSkillsForOperatorsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetSkillsForOperatorsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsForOperatorsResponse} message GetSkillsForOperatorsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsForOperatorsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSkillsForOperatorsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetSkillsForOperatorsResponse} GetSkillsForOperatorsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsForOperatorsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetSkillsForOperatorsResponse(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.operatorSkillsMapped === $util.emptyObject)
                                    message.operatorSkillsMapped = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.OperatorSkills.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.operatorSkillsMapped[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSkillsForOperatorsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetSkillsForOperatorsResponse} GetSkillsForOperatorsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsForOperatorsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSkillsForOperatorsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSkillsForOperatorsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorSkillsMapped != null && message.hasOwnProperty("operatorSkillsMapped")) {
                        if (!$util.isObject(message.operatorSkillsMapped))
                            return "operatorSkillsMapped: object expected";
                        var key = Object.keys(message.operatorSkillsMapped);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.OperatorSkills.verify(message.operatorSkillsMapped[key[i]]);
                            if (error)
                                return "operatorSkillsMapped." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetSkillsForOperatorsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetSkillsForOperatorsResponse} GetSkillsForOperatorsResponse
                 */
                GetSkillsForOperatorsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetSkillsForOperatorsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetSkillsForOperatorsResponse();
                    if (object.operatorSkillsMapped) {
                        if (typeof object.operatorSkillsMapped !== "object")
                            throw TypeError(".infinitusai.be.GetSkillsForOperatorsResponse.operatorSkillsMapped: object expected");
                        message.operatorSkillsMapped = {};
                        for (var keys = Object.keys(object.operatorSkillsMapped), i = 0; i < keys.length; ++i) {
                            if (typeof object.operatorSkillsMapped[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.GetSkillsForOperatorsResponse.operatorSkillsMapped: object expected");
                            message.operatorSkillsMapped[keys[i]] = $root.infinitusai.be.OperatorSkills.fromObject(object.operatorSkillsMapped[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSkillsForOperatorsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {infinitusai.be.GetSkillsForOperatorsResponse} message GetSkillsForOperatorsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSkillsForOperatorsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.operatorSkillsMapped = {};
                    var keys2;
                    if (message.operatorSkillsMapped && (keys2 = Object.keys(message.operatorSkillsMapped)).length) {
                        object.operatorSkillsMapped = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.operatorSkillsMapped[keys2[j]] = $root.infinitusai.be.OperatorSkills.toObject(message.operatorSkillsMapped[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetSkillsForOperatorsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSkillsForOperatorsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSkillsForOperatorsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetSkillsForOperatorsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSkillsForOperatorsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetSkillsForOperatorsResponse";
                };
    
                return GetSkillsForOperatorsResponse;
            })();
    
            be.GetMySkillsRequest = (function() {
    
                /**
                 * Properties of a GetMySkillsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetMySkillsRequest
                 */
    
                /**
                 * Constructs a new GetMySkillsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetMySkillsRequest.
                 * @implements IGetMySkillsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetMySkillsRequest=} [properties] Properties to set
                 */
                function GetMySkillsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetMySkillsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetMySkillsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetMySkillsRequest} GetMySkillsRequest instance
                 */
                GetMySkillsRequest.create = function create(properties) {
                    return new GetMySkillsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetMySkillsRequest message. Does not implicitly {@link infinitusai.be.GetMySkillsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetMySkillsRequest} message GetMySkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMySkillsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMySkillsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetMySkillsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetMySkillsRequest} message GetMySkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMySkillsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMySkillsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetMySkillsRequest} GetMySkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMySkillsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetMySkillsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMySkillsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetMySkillsRequest} GetMySkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMySkillsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMySkillsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMySkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetMySkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetMySkillsRequest} GetMySkillsRequest
                 */
                GetMySkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetMySkillsRequest)
                        return object;
                    return new $root.infinitusai.be.GetMySkillsRequest();
                };
    
                /**
                 * Creates a plain object from a GetMySkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {infinitusai.be.GetMySkillsRequest} message GetMySkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMySkillsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetMySkillsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMySkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMySkillsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetMySkillsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMySkillsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetMySkillsRequest";
                };
    
                return GetMySkillsRequest;
            })();
    
            be.GetMySkillsResponse = (function() {
    
                /**
                 * Properties of a GetMySkillsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetMySkillsResponse
                 * @property {Array.<infinitusai.be.IOperatorSkill>|null} [operatorSkills] GetMySkillsResponse operatorSkills
                 * @property {Array.<infinitusai.be.ISkillDefinition>|null} [skillDefinitions] GetMySkillsResponse skillDefinitions
                 */
    
                /**
                 * Constructs a new GetMySkillsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetMySkillsResponse.
                 * @implements IGetMySkillsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetMySkillsResponse=} [properties] Properties to set
                 */
                function GetMySkillsResponse(properties) {
                    this.operatorSkills = [];
                    this.skillDefinitions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMySkillsResponse operatorSkills.
                 * @member {Array.<infinitusai.be.IOperatorSkill>} operatorSkills
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @instance
                 */
                GetMySkillsResponse.prototype.operatorSkills = $util.emptyArray;
    
                /**
                 * GetMySkillsResponse skillDefinitions.
                 * @member {Array.<infinitusai.be.ISkillDefinition>} skillDefinitions
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @instance
                 */
                GetMySkillsResponse.prototype.skillDefinitions = $util.emptyArray;
    
                /**
                 * Creates a new GetMySkillsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetMySkillsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetMySkillsResponse} GetMySkillsResponse instance
                 */
                GetMySkillsResponse.create = function create(properties) {
                    return new GetMySkillsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetMySkillsResponse message. Does not implicitly {@link infinitusai.be.GetMySkillsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetMySkillsResponse} message GetMySkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMySkillsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorSkills != null && message.operatorSkills.length)
                        for (var i = 0; i < message.operatorSkills.length; ++i)
                            $root.infinitusai.be.OperatorSkill.encode(message.operatorSkills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.skillDefinitions != null && message.skillDefinitions.length)
                        for (var i = 0; i < message.skillDefinitions.length; ++i)
                            $root.infinitusai.be.SkillDefinition.encode(message.skillDefinitions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMySkillsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetMySkillsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetMySkillsResponse} message GetMySkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMySkillsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMySkillsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetMySkillsResponse} GetMySkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMySkillsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetMySkillsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.operatorSkills && message.operatorSkills.length))
                                    message.operatorSkills = [];
                                message.operatorSkills.push($root.infinitusai.be.OperatorSkill.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.skillDefinitions && message.skillDefinitions.length))
                                    message.skillDefinitions = [];
                                message.skillDefinitions.push($root.infinitusai.be.SkillDefinition.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMySkillsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetMySkillsResponse} GetMySkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMySkillsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMySkillsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMySkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorSkills != null && message.hasOwnProperty("operatorSkills")) {
                        if (!Array.isArray(message.operatorSkills))
                            return "operatorSkills: array expected";
                        for (var i = 0; i < message.operatorSkills.length; ++i) {
                            var error = $root.infinitusai.be.OperatorSkill.verify(message.operatorSkills[i]);
                            if (error)
                                return "operatorSkills." + error;
                        }
                    }
                    if (message.skillDefinitions != null && message.hasOwnProperty("skillDefinitions")) {
                        if (!Array.isArray(message.skillDefinitions))
                            return "skillDefinitions: array expected";
                        for (var i = 0; i < message.skillDefinitions.length; ++i) {
                            var error = $root.infinitusai.be.SkillDefinition.verify(message.skillDefinitions[i]);
                            if (error)
                                return "skillDefinitions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetMySkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetMySkillsResponse} GetMySkillsResponse
                 */
                GetMySkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetMySkillsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetMySkillsResponse();
                    if (object.operatorSkills) {
                        if (!Array.isArray(object.operatorSkills))
                            throw TypeError(".infinitusai.be.GetMySkillsResponse.operatorSkills: array expected");
                        message.operatorSkills = [];
                        for (var i = 0; i < object.operatorSkills.length; ++i) {
                            if (typeof object.operatorSkills[i] !== "object")
                                throw TypeError(".infinitusai.be.GetMySkillsResponse.operatorSkills: object expected");
                            message.operatorSkills[i] = $root.infinitusai.be.OperatorSkill.fromObject(object.operatorSkills[i]);
                        }
                    }
                    if (object.skillDefinitions) {
                        if (!Array.isArray(object.skillDefinitions))
                            throw TypeError(".infinitusai.be.GetMySkillsResponse.skillDefinitions: array expected");
                        message.skillDefinitions = [];
                        for (var i = 0; i < object.skillDefinitions.length; ++i) {
                            if (typeof object.skillDefinitions[i] !== "object")
                                throw TypeError(".infinitusai.be.GetMySkillsResponse.skillDefinitions: object expected");
                            message.skillDefinitions[i] = $root.infinitusai.be.SkillDefinition.fromObject(object.skillDefinitions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMySkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {infinitusai.be.GetMySkillsResponse} message GetMySkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMySkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.operatorSkills = [];
                        object.skillDefinitions = [];
                    }
                    if (message.operatorSkills && message.operatorSkills.length) {
                        object.operatorSkills = [];
                        for (var j = 0; j < message.operatorSkills.length; ++j)
                            object.operatorSkills[j] = $root.infinitusai.be.OperatorSkill.toObject(message.operatorSkills[j], options);
                    }
                    if (message.skillDefinitions && message.skillDefinitions.length) {
                        object.skillDefinitions = [];
                        for (var j = 0; j < message.skillDefinitions.length; ++j)
                            object.skillDefinitions[j] = $root.infinitusai.be.SkillDefinition.toObject(message.skillDefinitions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetMySkillsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMySkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMySkillsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetMySkillsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMySkillsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetMySkillsResponse";
                };
    
                return GetMySkillsResponse;
            })();
    
            be.OperatorSkillPrimaryKey = (function() {
    
                /**
                 * Properties of an OperatorSkillPrimaryKey.
                 * @memberof infinitusai.be
                 * @interface IOperatorSkillPrimaryKey
                 * @property {string|null} [operatorUuid] OperatorSkillPrimaryKey operatorUuid
                 * @property {string|null} [skillUuid] OperatorSkillPrimaryKey skillUuid
                 */
    
                /**
                 * Constructs a new OperatorSkillPrimaryKey.
                 * @memberof infinitusai.be
                 * @classdesc Represents an OperatorSkillPrimaryKey.
                 * @implements IOperatorSkillPrimaryKey
                 * @constructor
                 * @param {infinitusai.be.IOperatorSkillPrimaryKey=} [properties] Properties to set
                 */
                function OperatorSkillPrimaryKey(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OperatorSkillPrimaryKey operatorUuid.
                 * @member {string} operatorUuid
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @instance
                 */
                OperatorSkillPrimaryKey.prototype.operatorUuid = "";
    
                /**
                 * OperatorSkillPrimaryKey skillUuid.
                 * @member {string} skillUuid
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @instance
                 */
                OperatorSkillPrimaryKey.prototype.skillUuid = "";
    
                /**
                 * Creates a new OperatorSkillPrimaryKey instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {infinitusai.be.IOperatorSkillPrimaryKey=} [properties] Properties to set
                 * @returns {infinitusai.be.OperatorSkillPrimaryKey} OperatorSkillPrimaryKey instance
                 */
                OperatorSkillPrimaryKey.create = function create(properties) {
                    return new OperatorSkillPrimaryKey(properties);
                };
    
                /**
                 * Encodes the specified OperatorSkillPrimaryKey message. Does not implicitly {@link infinitusai.be.OperatorSkillPrimaryKey.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {infinitusai.be.IOperatorSkillPrimaryKey} message OperatorSkillPrimaryKey message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorSkillPrimaryKey.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorUuid != null && Object.hasOwnProperty.call(message, "operatorUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.operatorUuid);
                    if (message.skillUuid != null && Object.hasOwnProperty.call(message, "skillUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.skillUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified OperatorSkillPrimaryKey message, length delimited. Does not implicitly {@link infinitusai.be.OperatorSkillPrimaryKey.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {infinitusai.be.IOperatorSkillPrimaryKey} message OperatorSkillPrimaryKey message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OperatorSkillPrimaryKey.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OperatorSkillPrimaryKey message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.OperatorSkillPrimaryKey} OperatorSkillPrimaryKey
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorSkillPrimaryKey.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.OperatorSkillPrimaryKey();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operatorUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.skillUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OperatorSkillPrimaryKey message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.OperatorSkillPrimaryKey} OperatorSkillPrimaryKey
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OperatorSkillPrimaryKey.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OperatorSkillPrimaryKey message.
                 * @function verify
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OperatorSkillPrimaryKey.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorUuid != null && message.hasOwnProperty("operatorUuid"))
                        if (!$util.isString(message.operatorUuid))
                            return "operatorUuid: string expected";
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        if (!$util.isString(message.skillUuid))
                            return "skillUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an OperatorSkillPrimaryKey message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.OperatorSkillPrimaryKey} OperatorSkillPrimaryKey
                 */
                OperatorSkillPrimaryKey.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.OperatorSkillPrimaryKey)
                        return object;
                    var message = new $root.infinitusai.be.OperatorSkillPrimaryKey();
                    if (object.operatorUuid != null)
                        message.operatorUuid = String(object.operatorUuid);
                    if (object.skillUuid != null)
                        message.skillUuid = String(object.skillUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OperatorSkillPrimaryKey message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {infinitusai.be.OperatorSkillPrimaryKey} message OperatorSkillPrimaryKey
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OperatorSkillPrimaryKey.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operatorUuid = "";
                        object.skillUuid = "";
                    }
                    if (message.operatorUuid != null && message.hasOwnProperty("operatorUuid"))
                        object.operatorUuid = message.operatorUuid;
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        object.skillUuid = message.skillUuid;
                    return object;
                };
    
                /**
                 * Converts this OperatorSkillPrimaryKey to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OperatorSkillPrimaryKey.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OperatorSkillPrimaryKey
                 * @function getTypeUrl
                 * @memberof infinitusai.be.OperatorSkillPrimaryKey
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OperatorSkillPrimaryKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.OperatorSkillPrimaryKey";
                };
    
                return OperatorSkillPrimaryKey;
            })();
    
            be.DeleteOperatorSkillsRequest = (function() {
    
                /**
                 * Properties of a DeleteOperatorSkillsRequest.
                 * @memberof infinitusai.be
                 * @interface IDeleteOperatorSkillsRequest
                 * @property {Array.<infinitusai.be.IOperatorSkillPrimaryKey>|null} [operatorSkills] DeleteOperatorSkillsRequest operatorSkills
                 */
    
                /**
                 * Constructs a new DeleteOperatorSkillsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteOperatorSkillsRequest.
                 * @implements IDeleteOperatorSkillsRequest
                 * @constructor
                 * @param {infinitusai.be.IDeleteOperatorSkillsRequest=} [properties] Properties to set
                 */
                function DeleteOperatorSkillsRequest(properties) {
                    this.operatorSkills = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteOperatorSkillsRequest operatorSkills.
                 * @member {Array.<infinitusai.be.IOperatorSkillPrimaryKey>} operatorSkills
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @instance
                 */
                DeleteOperatorSkillsRequest.prototype.operatorSkills = $util.emptyArray;
    
                /**
                 * Creates a new DeleteOperatorSkillsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorSkillsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteOperatorSkillsRequest} DeleteOperatorSkillsRequest instance
                 */
                DeleteOperatorSkillsRequest.create = function create(properties) {
                    return new DeleteOperatorSkillsRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteOperatorSkillsRequest message. Does not implicitly {@link infinitusai.be.DeleteOperatorSkillsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorSkillsRequest} message DeleteOperatorSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteOperatorSkillsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorSkills != null && message.operatorSkills.length)
                        for (var i = 0; i < message.operatorSkills.length; ++i)
                            $root.infinitusai.be.OperatorSkillPrimaryKey.encode(message.operatorSkills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteOperatorSkillsRequest message, length delimited. Does not implicitly {@link infinitusai.be.DeleteOperatorSkillsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorSkillsRequest} message DeleteOperatorSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteOperatorSkillsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteOperatorSkillsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteOperatorSkillsRequest} DeleteOperatorSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteOperatorSkillsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteOperatorSkillsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.operatorSkills && message.operatorSkills.length))
                                    message.operatorSkills = [];
                                message.operatorSkills.push($root.infinitusai.be.OperatorSkillPrimaryKey.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteOperatorSkillsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteOperatorSkillsRequest} DeleteOperatorSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteOperatorSkillsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteOperatorSkillsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteOperatorSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorSkills != null && message.hasOwnProperty("operatorSkills")) {
                        if (!Array.isArray(message.operatorSkills))
                            return "operatorSkills: array expected";
                        for (var i = 0; i < message.operatorSkills.length; ++i) {
                            var error = $root.infinitusai.be.OperatorSkillPrimaryKey.verify(message.operatorSkills[i]);
                            if (error)
                                return "operatorSkills." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a DeleteOperatorSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteOperatorSkillsRequest} DeleteOperatorSkillsRequest
                 */
                DeleteOperatorSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteOperatorSkillsRequest)
                        return object;
                    var message = new $root.infinitusai.be.DeleteOperatorSkillsRequest();
                    if (object.operatorSkills) {
                        if (!Array.isArray(object.operatorSkills))
                            throw TypeError(".infinitusai.be.DeleteOperatorSkillsRequest.operatorSkills: array expected");
                        message.operatorSkills = [];
                        for (var i = 0; i < object.operatorSkills.length; ++i) {
                            if (typeof object.operatorSkills[i] !== "object")
                                throw TypeError(".infinitusai.be.DeleteOperatorSkillsRequest.operatorSkills: object expected");
                            message.operatorSkills[i] = $root.infinitusai.be.OperatorSkillPrimaryKey.fromObject(object.operatorSkills[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteOperatorSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {infinitusai.be.DeleteOperatorSkillsRequest} message DeleteOperatorSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteOperatorSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorSkills = [];
                    if (message.operatorSkills && message.operatorSkills.length) {
                        object.operatorSkills = [];
                        for (var j = 0; j < message.operatorSkills.length; ++j)
                            object.operatorSkills[j] = $root.infinitusai.be.OperatorSkillPrimaryKey.toObject(message.operatorSkills[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this DeleteOperatorSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteOperatorSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteOperatorSkillsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteOperatorSkillsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteOperatorSkillsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteOperatorSkillsRequest";
                };
    
                return DeleteOperatorSkillsRequest;
            })();
    
            be.DeleteOperatorSkillsResponse = (function() {
    
                /**
                 * Properties of a DeleteOperatorSkillsResponse.
                 * @memberof infinitusai.be
                 * @interface IDeleteOperatorSkillsResponse
                 */
    
                /**
                 * Constructs a new DeleteOperatorSkillsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteOperatorSkillsResponse.
                 * @implements IDeleteOperatorSkillsResponse
                 * @constructor
                 * @param {infinitusai.be.IDeleteOperatorSkillsResponse=} [properties] Properties to set
                 */
                function DeleteOperatorSkillsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteOperatorSkillsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorSkillsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteOperatorSkillsResponse} DeleteOperatorSkillsResponse instance
                 */
                DeleteOperatorSkillsResponse.create = function create(properties) {
                    return new DeleteOperatorSkillsResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteOperatorSkillsResponse message. Does not implicitly {@link infinitusai.be.DeleteOperatorSkillsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorSkillsResponse} message DeleteOperatorSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteOperatorSkillsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteOperatorSkillsResponse message, length delimited. Does not implicitly {@link infinitusai.be.DeleteOperatorSkillsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {infinitusai.be.IDeleteOperatorSkillsResponse} message DeleteOperatorSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteOperatorSkillsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteOperatorSkillsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteOperatorSkillsResponse} DeleteOperatorSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteOperatorSkillsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteOperatorSkillsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteOperatorSkillsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteOperatorSkillsResponse} DeleteOperatorSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteOperatorSkillsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteOperatorSkillsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteOperatorSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteOperatorSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteOperatorSkillsResponse} DeleteOperatorSkillsResponse
                 */
                DeleteOperatorSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteOperatorSkillsResponse)
                        return object;
                    return new $root.infinitusai.be.DeleteOperatorSkillsResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteOperatorSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {infinitusai.be.DeleteOperatorSkillsResponse} message DeleteOperatorSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteOperatorSkillsResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteOperatorSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteOperatorSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteOperatorSkillsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteOperatorSkillsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteOperatorSkillsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteOperatorSkillsResponse";
                };
    
                return DeleteOperatorSkillsResponse;
            })();
    
            be.GetAttributesForSkillsRequest = (function() {
    
                /**
                 * Properties of a GetAttributesForSkillsRequest.
                 * @memberof infinitusai.be
                 * @interface IGetAttributesForSkillsRequest
                 * @property {Array.<string>|null} [skillUuids] GetAttributesForSkillsRequest skillUuids
                 */
    
                /**
                 * Constructs a new GetAttributesForSkillsRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAttributesForSkillsRequest.
                 * @implements IGetAttributesForSkillsRequest
                 * @constructor
                 * @param {infinitusai.be.IGetAttributesForSkillsRequest=} [properties] Properties to set
                 */
                function GetAttributesForSkillsRequest(properties) {
                    this.skillUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAttributesForSkillsRequest skillUuids.
                 * @member {Array.<string>} skillUuids
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @instance
                 */
                GetAttributesForSkillsRequest.prototype.skillUuids = $util.emptyArray;
    
                /**
                 * Creates a new GetAttributesForSkillsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetAttributesForSkillsRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAttributesForSkillsRequest} GetAttributesForSkillsRequest instance
                 */
                GetAttributesForSkillsRequest.create = function create(properties) {
                    return new GetAttributesForSkillsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetAttributesForSkillsRequest message. Does not implicitly {@link infinitusai.be.GetAttributesForSkillsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetAttributesForSkillsRequest} message GetAttributesForSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAttributesForSkillsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skillUuids != null && message.skillUuids.length)
                        for (var i = 0; i < message.skillUuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.skillUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAttributesForSkillsRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetAttributesForSkillsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {infinitusai.be.IGetAttributesForSkillsRequest} message GetAttributesForSkillsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAttributesForSkillsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAttributesForSkillsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAttributesForSkillsRequest} GetAttributesForSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAttributesForSkillsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAttributesForSkillsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.skillUuids && message.skillUuids.length))
                                    message.skillUuids = [];
                                message.skillUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAttributesForSkillsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAttributesForSkillsRequest} GetAttributesForSkillsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAttributesForSkillsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAttributesForSkillsRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAttributesForSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillUuids != null && message.hasOwnProperty("skillUuids")) {
                        if (!Array.isArray(message.skillUuids))
                            return "skillUuids: array expected";
                        for (var i = 0; i < message.skillUuids.length; ++i)
                            if (!$util.isString(message.skillUuids[i]))
                                return "skillUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetAttributesForSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAttributesForSkillsRequest} GetAttributesForSkillsRequest
                 */
                GetAttributesForSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAttributesForSkillsRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetAttributesForSkillsRequest();
                    if (object.skillUuids) {
                        if (!Array.isArray(object.skillUuids))
                            throw TypeError(".infinitusai.be.GetAttributesForSkillsRequest.skillUuids: array expected");
                        message.skillUuids = [];
                        for (var i = 0; i < object.skillUuids.length; ++i)
                            message.skillUuids[i] = String(object.skillUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAttributesForSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {infinitusai.be.GetAttributesForSkillsRequest} message GetAttributesForSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAttributesForSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skillUuids = [];
                    if (message.skillUuids && message.skillUuids.length) {
                        object.skillUuids = [];
                        for (var j = 0; j < message.skillUuids.length; ++j)
                            object.skillUuids[j] = message.skillUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetAttributesForSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAttributesForSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAttributesForSkillsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAttributesForSkillsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAttributesForSkillsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAttributesForSkillsRequest";
                };
    
                return GetAttributesForSkillsRequest;
            })();
    
            be.SkillAttribute = (function() {
    
                /**
                 * Properties of a SkillAttribute.
                 * @memberof infinitusai.be
                 * @interface ISkillAttribute
                 * @property {string|null} [skillUuid] SkillAttribute skillUuid
                 * @property {infinitusai.be.SkillAttribute.Type|null} [attribute] SkillAttribute attribute
                 * @property {string|null} [attributeValue] SkillAttribute attributeValue
                 * @property {infinitusai.be.OperatorSkill.LEVEL|null} [skillLevelNeeded] SkillAttribute skillLevelNeeded
                 */
    
                /**
                 * Constructs a new SkillAttribute.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SkillAttribute.
                 * @implements ISkillAttribute
                 * @constructor
                 * @param {infinitusai.be.ISkillAttribute=} [properties] Properties to set
                 */
                function SkillAttribute(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SkillAttribute skillUuid.
                 * @member {string} skillUuid
                 * @memberof infinitusai.be.SkillAttribute
                 * @instance
                 */
                SkillAttribute.prototype.skillUuid = "";
    
                /**
                 * SkillAttribute attribute.
                 * @member {infinitusai.be.SkillAttribute.Type} attribute
                 * @memberof infinitusai.be.SkillAttribute
                 * @instance
                 */
                SkillAttribute.prototype.attribute = 0;
    
                /**
                 * SkillAttribute attributeValue.
                 * @member {string} attributeValue
                 * @memberof infinitusai.be.SkillAttribute
                 * @instance
                 */
                SkillAttribute.prototype.attributeValue = "";
    
                /**
                 * SkillAttribute skillLevelNeeded.
                 * @member {infinitusai.be.OperatorSkill.LEVEL} skillLevelNeeded
                 * @memberof infinitusai.be.SkillAttribute
                 * @instance
                 */
                SkillAttribute.prototype.skillLevelNeeded = 0;
    
                /**
                 * Creates a new SkillAttribute instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {infinitusai.be.ISkillAttribute=} [properties] Properties to set
                 * @returns {infinitusai.be.SkillAttribute} SkillAttribute instance
                 */
                SkillAttribute.create = function create(properties) {
                    return new SkillAttribute(properties);
                };
    
                /**
                 * Encodes the specified SkillAttribute message. Does not implicitly {@link infinitusai.be.SkillAttribute.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {infinitusai.be.ISkillAttribute} message SkillAttribute message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SkillAttribute.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skillUuid != null && Object.hasOwnProperty.call(message, "skillUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.skillUuid);
                    if (message.attribute != null && Object.hasOwnProperty.call(message, "attribute"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.attribute);
                    if (message.attributeValue != null && Object.hasOwnProperty.call(message, "attributeValue"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.attributeValue);
                    if (message.skillLevelNeeded != null && Object.hasOwnProperty.call(message, "skillLevelNeeded"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.skillLevelNeeded);
                    return writer;
                };
    
                /**
                 * Encodes the specified SkillAttribute message, length delimited. Does not implicitly {@link infinitusai.be.SkillAttribute.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {infinitusai.be.ISkillAttribute} message SkillAttribute message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SkillAttribute.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SkillAttribute message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SkillAttribute} SkillAttribute
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SkillAttribute.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SkillAttribute();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.skillUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.attribute = reader.int32();
                                break;
                            }
                        case 3: {
                                message.attributeValue = reader.string();
                                break;
                            }
                        case 4: {
                                message.skillLevelNeeded = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SkillAttribute message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SkillAttribute} SkillAttribute
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SkillAttribute.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SkillAttribute message.
                 * @function verify
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SkillAttribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        if (!$util.isString(message.skillUuid))
                            return "skillUuid: string expected";
                    if (message.attribute != null && message.hasOwnProperty("attribute"))
                        switch (message.attribute) {
                        default:
                            return "attribute: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                            break;
                        }
                    if (message.attributeValue != null && message.hasOwnProperty("attributeValue"))
                        if (!$util.isString(message.attributeValue))
                            return "attributeValue: string expected";
                    if (message.skillLevelNeeded != null && message.hasOwnProperty("skillLevelNeeded"))
                        switch (message.skillLevelNeeded) {
                        default:
                            return "skillLevelNeeded: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a SkillAttribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SkillAttribute} SkillAttribute
                 */
                SkillAttribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SkillAttribute)
                        return object;
                    var message = new $root.infinitusai.be.SkillAttribute();
                    if (object.skillUuid != null)
                        message.skillUuid = String(object.skillUuid);
                    switch (object.attribute) {
                    default:
                        if (typeof object.attribute === "number") {
                            message.attribute = object.attribute;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.attribute = 0;
                        break;
                    case "ORG_NAME":
                    case 1:
                        message.attribute = 1;
                        break;
                    case "PROGRAM_NAME":
                    case 2:
                        message.attribute = 2;
                        break;
                    case "NEEDS_HUMAN_INTRO":
                    case 3:
                        message.attribute = 3;
                        break;
                    case "TASK_STATE":
                    case 4:
                        message.attribute = 4;
                        break;
                    case "TASK_TYPE":
                    case 5:
                        message.attribute = 5;
                        break;
                    case "MULTI_TASK":
                    case 6:
                        message.attribute = 6;
                        break;
                    case "IS_ESCALATED":
                    case 7:
                        message.attribute = 7;
                        break;
                    case "MULTI_BENEFIT":
                    case 8:
                        message.attribute = 8;
                        break;
                    case "NEEDS_MANUAL_IVR":
                    case 9:
                        message.attribute = 9;
                        break;
                    case "PAYER_NAME":
                    case 10:
                        message.attribute = 10;
                        break;
                    case "PHONE_NUMBER":
                    case 11:
                        message.attribute = 11;
                        break;
                    case "IS_PILOT_ORG":
                    case 12:
                        message.attribute = 12;
                        break;
                    }
                    if (object.attributeValue != null)
                        message.attributeValue = String(object.attributeValue);
                    switch (object.skillLevelNeeded) {
                    default:
                        if (typeof object.skillLevelNeeded === "number") {
                            message.skillLevelNeeded = object.skillLevelNeeded;
                            break;
                        }
                        break;
                    case "INACTIVE":
                    case 0:
                        message.skillLevelNeeded = 0;
                        break;
                    case "SECONDARY":
                    case 1:
                        message.skillLevelNeeded = 1;
                        break;
                    case "PRIMARY":
                    case 2:
                        message.skillLevelNeeded = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SkillAttribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {infinitusai.be.SkillAttribute} message SkillAttribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SkillAttribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.skillUuid = "";
                        object.attribute = options.enums === String ? "UNKNOWN" : 0;
                        object.attributeValue = "";
                        object.skillLevelNeeded = options.enums === String ? "INACTIVE" : 0;
                    }
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        object.skillUuid = message.skillUuid;
                    if (message.attribute != null && message.hasOwnProperty("attribute"))
                        object.attribute = options.enums === String ? $root.infinitusai.be.SkillAttribute.Type[message.attribute] === undefined ? message.attribute : $root.infinitusai.be.SkillAttribute.Type[message.attribute] : message.attribute;
                    if (message.attributeValue != null && message.hasOwnProperty("attributeValue"))
                        object.attributeValue = message.attributeValue;
                    if (message.skillLevelNeeded != null && message.hasOwnProperty("skillLevelNeeded"))
                        object.skillLevelNeeded = options.enums === String ? $root.infinitusai.be.OperatorSkill.LEVEL[message.skillLevelNeeded] === undefined ? message.skillLevelNeeded : $root.infinitusai.be.OperatorSkill.LEVEL[message.skillLevelNeeded] : message.skillLevelNeeded;
                    return object;
                };
    
                /**
                 * Converts this SkillAttribute to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SkillAttribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SkillAttribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SkillAttribute
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SkillAttribute
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SkillAttribute.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SkillAttribute";
                };
    
                /**
                 * Type enum.
                 * @name infinitusai.be.SkillAttribute.Type
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} ORG_NAME=1 ORG_NAME value
                 * @property {number} PROGRAM_NAME=2 PROGRAM_NAME value
                 * @property {number} NEEDS_HUMAN_INTRO=3 NEEDS_HUMAN_INTRO value
                 * @property {number} TASK_STATE=4 TASK_STATE value
                 * @property {number} TASK_TYPE=5 TASK_TYPE value
                 * @property {number} MULTI_TASK=6 MULTI_TASK value
                 * @property {number} IS_ESCALATED=7 IS_ESCALATED value
                 * @property {number} MULTI_BENEFIT=8 MULTI_BENEFIT value
                 * @property {number} NEEDS_MANUAL_IVR=9 NEEDS_MANUAL_IVR value
                 * @property {number} PAYER_NAME=10 PAYER_NAME value
                 * @property {number} PHONE_NUMBER=11 PHONE_NUMBER value
                 * @property {number} IS_PILOT_ORG=12 IS_PILOT_ORG value
                 */
                SkillAttribute.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "ORG_NAME"] = 1;
                    values[valuesById[2] = "PROGRAM_NAME"] = 2;
                    values[valuesById[3] = "NEEDS_HUMAN_INTRO"] = 3;
                    values[valuesById[4] = "TASK_STATE"] = 4;
                    values[valuesById[5] = "TASK_TYPE"] = 5;
                    values[valuesById[6] = "MULTI_TASK"] = 6;
                    values[valuesById[7] = "IS_ESCALATED"] = 7;
                    values[valuesById[8] = "MULTI_BENEFIT"] = 8;
                    values[valuesById[9] = "NEEDS_MANUAL_IVR"] = 9;
                    values[valuesById[10] = "PAYER_NAME"] = 10;
                    values[valuesById[11] = "PHONE_NUMBER"] = 11;
                    values[valuesById[12] = "IS_PILOT_ORG"] = 12;
                    return values;
                })();
    
                return SkillAttribute;
            })();
    
            be.SkillAttributes = (function() {
    
                /**
                 * Properties of a SkillAttributes.
                 * @memberof infinitusai.be
                 * @interface ISkillAttributes
                 * @property {Array.<infinitusai.be.ISkillAttribute>|null} [skillAttributes] SkillAttributes skillAttributes
                 */
    
                /**
                 * Constructs a new SkillAttributes.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SkillAttributes.
                 * @implements ISkillAttributes
                 * @constructor
                 * @param {infinitusai.be.ISkillAttributes=} [properties] Properties to set
                 */
                function SkillAttributes(properties) {
                    this.skillAttributes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SkillAttributes skillAttributes.
                 * @member {Array.<infinitusai.be.ISkillAttribute>} skillAttributes
                 * @memberof infinitusai.be.SkillAttributes
                 * @instance
                 */
                SkillAttributes.prototype.skillAttributes = $util.emptyArray;
    
                /**
                 * Creates a new SkillAttributes instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {infinitusai.be.ISkillAttributes=} [properties] Properties to set
                 * @returns {infinitusai.be.SkillAttributes} SkillAttributes instance
                 */
                SkillAttributes.create = function create(properties) {
                    return new SkillAttributes(properties);
                };
    
                /**
                 * Encodes the specified SkillAttributes message. Does not implicitly {@link infinitusai.be.SkillAttributes.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {infinitusai.be.ISkillAttributes} message SkillAttributes message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SkillAttributes.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skillAttributes != null && message.skillAttributes.length)
                        for (var i = 0; i < message.skillAttributes.length; ++i)
                            $root.infinitusai.be.SkillAttribute.encode(message.skillAttributes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SkillAttributes message, length delimited. Does not implicitly {@link infinitusai.be.SkillAttributes.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {infinitusai.be.ISkillAttributes} message SkillAttributes message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SkillAttributes.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SkillAttributes message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SkillAttributes} SkillAttributes
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SkillAttributes.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SkillAttributes();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.skillAttributes && message.skillAttributes.length))
                                    message.skillAttributes = [];
                                message.skillAttributes.push($root.infinitusai.be.SkillAttribute.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SkillAttributes message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SkillAttributes} SkillAttributes
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SkillAttributes.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SkillAttributes message.
                 * @function verify
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SkillAttributes.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillAttributes != null && message.hasOwnProperty("skillAttributes")) {
                        if (!Array.isArray(message.skillAttributes))
                            return "skillAttributes: array expected";
                        for (var i = 0; i < message.skillAttributes.length; ++i) {
                            var error = $root.infinitusai.be.SkillAttribute.verify(message.skillAttributes[i]);
                            if (error)
                                return "skillAttributes." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a SkillAttributes message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SkillAttributes} SkillAttributes
                 */
                SkillAttributes.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SkillAttributes)
                        return object;
                    var message = new $root.infinitusai.be.SkillAttributes();
                    if (object.skillAttributes) {
                        if (!Array.isArray(object.skillAttributes))
                            throw TypeError(".infinitusai.be.SkillAttributes.skillAttributes: array expected");
                        message.skillAttributes = [];
                        for (var i = 0; i < object.skillAttributes.length; ++i) {
                            if (typeof object.skillAttributes[i] !== "object")
                                throw TypeError(".infinitusai.be.SkillAttributes.skillAttributes: object expected");
                            message.skillAttributes[i] = $root.infinitusai.be.SkillAttribute.fromObject(object.skillAttributes[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SkillAttributes message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {infinitusai.be.SkillAttributes} message SkillAttributes
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SkillAttributes.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skillAttributes = [];
                    if (message.skillAttributes && message.skillAttributes.length) {
                        object.skillAttributes = [];
                        for (var j = 0; j < message.skillAttributes.length; ++j)
                            object.skillAttributes[j] = $root.infinitusai.be.SkillAttribute.toObject(message.skillAttributes[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this SkillAttributes to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SkillAttributes
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SkillAttributes.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SkillAttributes
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SkillAttributes
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SkillAttributes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SkillAttributes";
                };
    
                return SkillAttributes;
            })();
    
            be.GetAttributesForSkillsResponse = (function() {
    
                /**
                 * Properties of a GetAttributesForSkillsResponse.
                 * @memberof infinitusai.be
                 * @interface IGetAttributesForSkillsResponse
                 * @property {Object.<string,infinitusai.be.ISkillAttributes>|null} [skillAttributesMapped] GetAttributesForSkillsResponse skillAttributesMapped
                 */
    
                /**
                 * Constructs a new GetAttributesForSkillsResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetAttributesForSkillsResponse.
                 * @implements IGetAttributesForSkillsResponse
                 * @constructor
                 * @param {infinitusai.be.IGetAttributesForSkillsResponse=} [properties] Properties to set
                 */
                function GetAttributesForSkillsResponse(properties) {
                    this.skillAttributesMapped = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAttributesForSkillsResponse skillAttributesMapped.
                 * @member {Object.<string,infinitusai.be.ISkillAttributes>} skillAttributesMapped
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @instance
                 */
                GetAttributesForSkillsResponse.prototype.skillAttributesMapped = $util.emptyObject;
    
                /**
                 * Creates a new GetAttributesForSkillsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetAttributesForSkillsResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetAttributesForSkillsResponse} GetAttributesForSkillsResponse instance
                 */
                GetAttributesForSkillsResponse.create = function create(properties) {
                    return new GetAttributesForSkillsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetAttributesForSkillsResponse message. Does not implicitly {@link infinitusai.be.GetAttributesForSkillsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetAttributesForSkillsResponse} message GetAttributesForSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAttributesForSkillsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skillAttributesMapped != null && Object.hasOwnProperty.call(message, "skillAttributesMapped"))
                        for (var keys = Object.keys(message.skillAttributesMapped), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.be.SkillAttributes.encode(message.skillAttributesMapped[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAttributesForSkillsResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetAttributesForSkillsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {infinitusai.be.IGetAttributesForSkillsResponse} message GetAttributesForSkillsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAttributesForSkillsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAttributesForSkillsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetAttributesForSkillsResponse} GetAttributesForSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAttributesForSkillsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetAttributesForSkillsResponse(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.skillAttributesMapped === $util.emptyObject)
                                    message.skillAttributesMapped = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.be.SkillAttributes.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.skillAttributesMapped[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAttributesForSkillsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetAttributesForSkillsResponse} GetAttributesForSkillsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAttributesForSkillsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAttributesForSkillsResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAttributesForSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillAttributesMapped != null && message.hasOwnProperty("skillAttributesMapped")) {
                        if (!$util.isObject(message.skillAttributesMapped))
                            return "skillAttributesMapped: object expected";
                        var key = Object.keys(message.skillAttributesMapped);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.be.SkillAttributes.verify(message.skillAttributesMapped[key[i]]);
                            if (error)
                                return "skillAttributesMapped." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetAttributesForSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetAttributesForSkillsResponse} GetAttributesForSkillsResponse
                 */
                GetAttributesForSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetAttributesForSkillsResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetAttributesForSkillsResponse();
                    if (object.skillAttributesMapped) {
                        if (typeof object.skillAttributesMapped !== "object")
                            throw TypeError(".infinitusai.be.GetAttributesForSkillsResponse.skillAttributesMapped: object expected");
                        message.skillAttributesMapped = {};
                        for (var keys = Object.keys(object.skillAttributesMapped), i = 0; i < keys.length; ++i) {
                            if (typeof object.skillAttributesMapped[keys[i]] !== "object")
                                throw TypeError(".infinitusai.be.GetAttributesForSkillsResponse.skillAttributesMapped: object expected");
                            message.skillAttributesMapped[keys[i]] = $root.infinitusai.be.SkillAttributes.fromObject(object.skillAttributesMapped[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAttributesForSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {infinitusai.be.GetAttributesForSkillsResponse} message GetAttributesForSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAttributesForSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.skillAttributesMapped = {};
                    var keys2;
                    if (message.skillAttributesMapped && (keys2 = Object.keys(message.skillAttributesMapped)).length) {
                        object.skillAttributesMapped = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.skillAttributesMapped[keys2[j]] = $root.infinitusai.be.SkillAttributes.toObject(message.skillAttributesMapped[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetAttributesForSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAttributesForSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAttributesForSkillsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetAttributesForSkillsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAttributesForSkillsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetAttributesForSkillsResponse";
                };
    
                return GetAttributesForSkillsResponse;
            })();
    
            be.UpsertSkillAttributesRequest = (function() {
    
                /**
                 * Properties of an UpsertSkillAttributesRequest.
                 * @memberof infinitusai.be
                 * @interface IUpsertSkillAttributesRequest
                 * @property {Array.<infinitusai.be.ISkillAttribute>|null} [skillAttributes] UpsertSkillAttributesRequest skillAttributes
                 */
    
                /**
                 * Constructs a new UpsertSkillAttributesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertSkillAttributesRequest.
                 * @implements IUpsertSkillAttributesRequest
                 * @constructor
                 * @param {infinitusai.be.IUpsertSkillAttributesRequest=} [properties] Properties to set
                 */
                function UpsertSkillAttributesRequest(properties) {
                    this.skillAttributes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpsertSkillAttributesRequest skillAttributes.
                 * @member {Array.<infinitusai.be.ISkillAttribute>} skillAttributes
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @instance
                 */
                UpsertSkillAttributesRequest.prototype.skillAttributes = $util.emptyArray;
    
                /**
                 * Creates a new UpsertSkillAttributesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {infinitusai.be.IUpsertSkillAttributesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertSkillAttributesRequest} UpsertSkillAttributesRequest instance
                 */
                UpsertSkillAttributesRequest.create = function create(properties) {
                    return new UpsertSkillAttributesRequest(properties);
                };
    
                /**
                 * Encodes the specified UpsertSkillAttributesRequest message. Does not implicitly {@link infinitusai.be.UpsertSkillAttributesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {infinitusai.be.IUpsertSkillAttributesRequest} message UpsertSkillAttributesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertSkillAttributesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skillAttributes != null && message.skillAttributes.length)
                        for (var i = 0; i < message.skillAttributes.length; ++i)
                            $root.infinitusai.be.SkillAttribute.encode(message.skillAttributes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertSkillAttributesRequest message, length delimited. Does not implicitly {@link infinitusai.be.UpsertSkillAttributesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {infinitusai.be.IUpsertSkillAttributesRequest} message UpsertSkillAttributesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertSkillAttributesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertSkillAttributesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertSkillAttributesRequest} UpsertSkillAttributesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertSkillAttributesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertSkillAttributesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.skillAttributes && message.skillAttributes.length))
                                    message.skillAttributes = [];
                                message.skillAttributes.push($root.infinitusai.be.SkillAttribute.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertSkillAttributesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertSkillAttributesRequest} UpsertSkillAttributesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertSkillAttributesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertSkillAttributesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertSkillAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillAttributes != null && message.hasOwnProperty("skillAttributes")) {
                        if (!Array.isArray(message.skillAttributes))
                            return "skillAttributes: array expected";
                        for (var i = 0; i < message.skillAttributes.length; ++i) {
                            var error = $root.infinitusai.be.SkillAttribute.verify(message.skillAttributes[i]);
                            if (error)
                                return "skillAttributes." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an UpsertSkillAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertSkillAttributesRequest} UpsertSkillAttributesRequest
                 */
                UpsertSkillAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertSkillAttributesRequest)
                        return object;
                    var message = new $root.infinitusai.be.UpsertSkillAttributesRequest();
                    if (object.skillAttributes) {
                        if (!Array.isArray(object.skillAttributes))
                            throw TypeError(".infinitusai.be.UpsertSkillAttributesRequest.skillAttributes: array expected");
                        message.skillAttributes = [];
                        for (var i = 0; i < object.skillAttributes.length; ++i) {
                            if (typeof object.skillAttributes[i] !== "object")
                                throw TypeError(".infinitusai.be.UpsertSkillAttributesRequest.skillAttributes: object expected");
                            message.skillAttributes[i] = $root.infinitusai.be.SkillAttribute.fromObject(object.skillAttributes[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpsertSkillAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {infinitusai.be.UpsertSkillAttributesRequest} message UpsertSkillAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertSkillAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skillAttributes = [];
                    if (message.skillAttributes && message.skillAttributes.length) {
                        object.skillAttributes = [];
                        for (var j = 0; j < message.skillAttributes.length; ++j)
                            object.skillAttributes[j] = $root.infinitusai.be.SkillAttribute.toObject(message.skillAttributes[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this UpsertSkillAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertSkillAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertSkillAttributesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertSkillAttributesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertSkillAttributesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertSkillAttributesRequest";
                };
    
                return UpsertSkillAttributesRequest;
            })();
    
            be.UpsertSkillAttributesResponse = (function() {
    
                /**
                 * Properties of an UpsertSkillAttributesResponse.
                 * @memberof infinitusai.be
                 * @interface IUpsertSkillAttributesResponse
                 */
    
                /**
                 * Constructs a new UpsertSkillAttributesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UpsertSkillAttributesResponse.
                 * @implements IUpsertSkillAttributesResponse
                 * @constructor
                 * @param {infinitusai.be.IUpsertSkillAttributesResponse=} [properties] Properties to set
                 */
                function UpsertSkillAttributesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpsertSkillAttributesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {infinitusai.be.IUpsertSkillAttributesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.UpsertSkillAttributesResponse} UpsertSkillAttributesResponse instance
                 */
                UpsertSkillAttributesResponse.create = function create(properties) {
                    return new UpsertSkillAttributesResponse(properties);
                };
    
                /**
                 * Encodes the specified UpsertSkillAttributesResponse message. Does not implicitly {@link infinitusai.be.UpsertSkillAttributesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {infinitusai.be.IUpsertSkillAttributesResponse} message UpsertSkillAttributesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertSkillAttributesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpsertSkillAttributesResponse message, length delimited. Does not implicitly {@link infinitusai.be.UpsertSkillAttributesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {infinitusai.be.IUpsertSkillAttributesResponse} message UpsertSkillAttributesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpsertSkillAttributesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpsertSkillAttributesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UpsertSkillAttributesResponse} UpsertSkillAttributesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertSkillAttributesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UpsertSkillAttributesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpsertSkillAttributesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UpsertSkillAttributesResponse} UpsertSkillAttributesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpsertSkillAttributesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpsertSkillAttributesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpsertSkillAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpsertSkillAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UpsertSkillAttributesResponse} UpsertSkillAttributesResponse
                 */
                UpsertSkillAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UpsertSkillAttributesResponse)
                        return object;
                    return new $root.infinitusai.be.UpsertSkillAttributesResponse();
                };
    
                /**
                 * Creates a plain object from an UpsertSkillAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {infinitusai.be.UpsertSkillAttributesResponse} message UpsertSkillAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpsertSkillAttributesResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpsertSkillAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpsertSkillAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpsertSkillAttributesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UpsertSkillAttributesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpsertSkillAttributesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UpsertSkillAttributesResponse";
                };
    
                return UpsertSkillAttributesResponse;
            })();
    
            be.DeleteSkillAttributesRequest = (function() {
    
                /**
                 * Properties of a DeleteSkillAttributesRequest.
                 * @memberof infinitusai.be
                 * @interface IDeleteSkillAttributesRequest
                 * @property {Array.<infinitusai.be.ISkillAttribute>|null} [skillAttributes] DeleteSkillAttributesRequest skillAttributes
                 */
    
                /**
                 * Constructs a new DeleteSkillAttributesRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteSkillAttributesRequest.
                 * @implements IDeleteSkillAttributesRequest
                 * @constructor
                 * @param {infinitusai.be.IDeleteSkillAttributesRequest=} [properties] Properties to set
                 */
                function DeleteSkillAttributesRequest(properties) {
                    this.skillAttributes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteSkillAttributesRequest skillAttributes.
                 * @member {Array.<infinitusai.be.ISkillAttribute>} skillAttributes
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @instance
                 */
                DeleteSkillAttributesRequest.prototype.skillAttributes = $util.emptyArray;
    
                /**
                 * Creates a new DeleteSkillAttributesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {infinitusai.be.IDeleteSkillAttributesRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteSkillAttributesRequest} DeleteSkillAttributesRequest instance
                 */
                DeleteSkillAttributesRequest.create = function create(properties) {
                    return new DeleteSkillAttributesRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteSkillAttributesRequest message. Does not implicitly {@link infinitusai.be.DeleteSkillAttributesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {infinitusai.be.IDeleteSkillAttributesRequest} message DeleteSkillAttributesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSkillAttributesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skillAttributes != null && message.skillAttributes.length)
                        for (var i = 0; i < message.skillAttributes.length; ++i)
                            $root.infinitusai.be.SkillAttribute.encode(message.skillAttributes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteSkillAttributesRequest message, length delimited. Does not implicitly {@link infinitusai.be.DeleteSkillAttributesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {infinitusai.be.IDeleteSkillAttributesRequest} message DeleteSkillAttributesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSkillAttributesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteSkillAttributesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteSkillAttributesRequest} DeleteSkillAttributesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSkillAttributesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteSkillAttributesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.skillAttributes && message.skillAttributes.length))
                                    message.skillAttributes = [];
                                message.skillAttributes.push($root.infinitusai.be.SkillAttribute.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteSkillAttributesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteSkillAttributesRequest} DeleteSkillAttributesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSkillAttributesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteSkillAttributesRequest message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSkillAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillAttributes != null && message.hasOwnProperty("skillAttributes")) {
                        if (!Array.isArray(message.skillAttributes))
                            return "skillAttributes: array expected";
                        for (var i = 0; i < message.skillAttributes.length; ++i) {
                            var error = $root.infinitusai.be.SkillAttribute.verify(message.skillAttributes[i]);
                            if (error)
                                return "skillAttributes." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a DeleteSkillAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteSkillAttributesRequest} DeleteSkillAttributesRequest
                 */
                DeleteSkillAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteSkillAttributesRequest)
                        return object;
                    var message = new $root.infinitusai.be.DeleteSkillAttributesRequest();
                    if (object.skillAttributes) {
                        if (!Array.isArray(object.skillAttributes))
                            throw TypeError(".infinitusai.be.DeleteSkillAttributesRequest.skillAttributes: array expected");
                        message.skillAttributes = [];
                        for (var i = 0; i < object.skillAttributes.length; ++i) {
                            if (typeof object.skillAttributes[i] !== "object")
                                throw TypeError(".infinitusai.be.DeleteSkillAttributesRequest.skillAttributes: object expected");
                            message.skillAttributes[i] = $root.infinitusai.be.SkillAttribute.fromObject(object.skillAttributes[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteSkillAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {infinitusai.be.DeleteSkillAttributesRequest} message DeleteSkillAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSkillAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skillAttributes = [];
                    if (message.skillAttributes && message.skillAttributes.length) {
                        object.skillAttributes = [];
                        for (var j = 0; j < message.skillAttributes.length; ++j)
                            object.skillAttributes[j] = $root.infinitusai.be.SkillAttribute.toObject(message.skillAttributes[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this DeleteSkillAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSkillAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteSkillAttributesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteSkillAttributesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSkillAttributesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteSkillAttributesRequest";
                };
    
                return DeleteSkillAttributesRequest;
            })();
    
            be.DeleteSkillAttributesResponse = (function() {
    
                /**
                 * Properties of a DeleteSkillAttributesResponse.
                 * @memberof infinitusai.be
                 * @interface IDeleteSkillAttributesResponse
                 */
    
                /**
                 * Constructs a new DeleteSkillAttributesResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a DeleteSkillAttributesResponse.
                 * @implements IDeleteSkillAttributesResponse
                 * @constructor
                 * @param {infinitusai.be.IDeleteSkillAttributesResponse=} [properties] Properties to set
                 */
                function DeleteSkillAttributesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteSkillAttributesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {infinitusai.be.IDeleteSkillAttributesResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.DeleteSkillAttributesResponse} DeleteSkillAttributesResponse instance
                 */
                DeleteSkillAttributesResponse.create = function create(properties) {
                    return new DeleteSkillAttributesResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteSkillAttributesResponse message. Does not implicitly {@link infinitusai.be.DeleteSkillAttributesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {infinitusai.be.IDeleteSkillAttributesResponse} message DeleteSkillAttributesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSkillAttributesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteSkillAttributesResponse message, length delimited. Does not implicitly {@link infinitusai.be.DeleteSkillAttributesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {infinitusai.be.IDeleteSkillAttributesResponse} message DeleteSkillAttributesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSkillAttributesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteSkillAttributesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.DeleteSkillAttributesResponse} DeleteSkillAttributesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSkillAttributesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.DeleteSkillAttributesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteSkillAttributesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.DeleteSkillAttributesResponse} DeleteSkillAttributesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSkillAttributesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteSkillAttributesResponse message.
                 * @function verify
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSkillAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteSkillAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.DeleteSkillAttributesResponse} DeleteSkillAttributesResponse
                 */
                DeleteSkillAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.DeleteSkillAttributesResponse)
                        return object;
                    return new $root.infinitusai.be.DeleteSkillAttributesResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteSkillAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {infinitusai.be.DeleteSkillAttributesResponse} message DeleteSkillAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSkillAttributesResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteSkillAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSkillAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteSkillAttributesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.DeleteSkillAttributesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSkillAttributesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.DeleteSkillAttributesResponse";
                };
    
                return DeleteSkillAttributesResponse;
            })();
    
            be.GetSkillsForTaskRequest = (function() {
    
                /**
                 * Properties of a GetSkillsForTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IGetSkillsForTaskRequest
                 * @property {string|null} [taskUuid] GetSkillsForTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new GetSkillsForTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetSkillsForTaskRequest.
                 * @implements IGetSkillsForTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IGetSkillsForTaskRequest=} [properties] Properties to set
                 */
                function GetSkillsForTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSkillsForTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @instance
                 */
                GetSkillsForTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new GetSkillsForTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsForTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetSkillsForTaskRequest} GetSkillsForTaskRequest instance
                 */
                GetSkillsForTaskRequest.create = function create(properties) {
                    return new GetSkillsForTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified GetSkillsForTaskRequest message. Does not implicitly {@link infinitusai.be.GetSkillsForTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsForTaskRequest} message GetSkillsForTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsForTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSkillsForTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetSkillsForTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillsForTaskRequest} message GetSkillsForTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsForTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSkillsForTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetSkillsForTaskRequest} GetSkillsForTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsForTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetSkillsForTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSkillsForTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetSkillsForTaskRequest} GetSkillsForTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsForTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSkillsForTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSkillsForTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetSkillsForTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetSkillsForTaskRequest} GetSkillsForTaskRequest
                 */
                GetSkillsForTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetSkillsForTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetSkillsForTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSkillsForTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {infinitusai.be.GetSkillsForTaskRequest} message GetSkillsForTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSkillsForTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this GetSkillsForTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSkillsForTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSkillsForTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetSkillsForTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSkillsForTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetSkillsForTaskRequest";
                };
    
                return GetSkillsForTaskRequest;
            })();
    
            be.GetSkillsForTaskResponse = (function() {
    
                /**
                 * Properties of a GetSkillsForTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IGetSkillsForTaskResponse
                 * @property {Array.<infinitusai.be.ISkillDefinition>|null} [skills] GetSkillsForTaskResponse skills
                 */
    
                /**
                 * Constructs a new GetSkillsForTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetSkillsForTaskResponse.
                 * @implements IGetSkillsForTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IGetSkillsForTaskResponse=} [properties] Properties to set
                 */
                function GetSkillsForTaskResponse(properties) {
                    this.skills = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSkillsForTaskResponse skills.
                 * @member {Array.<infinitusai.be.ISkillDefinition>} skills
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @instance
                 */
                GetSkillsForTaskResponse.prototype.skills = $util.emptyArray;
    
                /**
                 * Creates a new GetSkillsForTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsForTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetSkillsForTaskResponse} GetSkillsForTaskResponse instance
                 */
                GetSkillsForTaskResponse.create = function create(properties) {
                    return new GetSkillsForTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified GetSkillsForTaskResponse message. Does not implicitly {@link infinitusai.be.GetSkillsForTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsForTaskResponse} message GetSkillsForTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsForTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skills != null && message.skills.length)
                        for (var i = 0; i < message.skills.length; ++i)
                            $root.infinitusai.be.SkillDefinition.encode(message.skills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSkillsForTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetSkillsForTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillsForTaskResponse} message GetSkillsForTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillsForTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSkillsForTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetSkillsForTaskResponse} GetSkillsForTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsForTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetSkillsForTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.skills && message.skills.length))
                                    message.skills = [];
                                message.skills.push($root.infinitusai.be.SkillDefinition.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSkillsForTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetSkillsForTaskResponse} GetSkillsForTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillsForTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSkillsForTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSkillsForTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skills != null && message.hasOwnProperty("skills")) {
                        if (!Array.isArray(message.skills))
                            return "skills: array expected";
                        for (var i = 0; i < message.skills.length; ++i) {
                            var error = $root.infinitusai.be.SkillDefinition.verify(message.skills[i]);
                            if (error)
                                return "skills." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetSkillsForTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetSkillsForTaskResponse} GetSkillsForTaskResponse
                 */
                GetSkillsForTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetSkillsForTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetSkillsForTaskResponse();
                    if (object.skills) {
                        if (!Array.isArray(object.skills))
                            throw TypeError(".infinitusai.be.GetSkillsForTaskResponse.skills: array expected");
                        message.skills = [];
                        for (var i = 0; i < object.skills.length; ++i) {
                            if (typeof object.skills[i] !== "object")
                                throw TypeError(".infinitusai.be.GetSkillsForTaskResponse.skills: object expected");
                            message.skills[i] = $root.infinitusai.be.SkillDefinition.fromObject(object.skills[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSkillsForTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {infinitusai.be.GetSkillsForTaskResponse} message GetSkillsForTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSkillsForTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.skills = [];
                    if (message.skills && message.skills.length) {
                        object.skills = [];
                        for (var j = 0; j < message.skills.length; ++j)
                            object.skills[j] = $root.infinitusai.be.SkillDefinition.toObject(message.skills[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetSkillsForTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSkillsForTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSkillsForTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetSkillsForTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSkillsForTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetSkillsForTaskResponse";
                };
    
                return GetSkillsForTaskResponse;
            })();
    
            be.GetSkillBasedOperatorsForTaskRequest = (function() {
    
                /**
                 * Properties of a GetSkillBasedOperatorsForTaskRequest.
                 * @memberof infinitusai.be
                 * @interface IGetSkillBasedOperatorsForTaskRequest
                 * @property {string|null} [taskUuid] GetSkillBasedOperatorsForTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new GetSkillBasedOperatorsForTaskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetSkillBasedOperatorsForTaskRequest.
                 * @implements IGetSkillBasedOperatorsForTaskRequest
                 * @constructor
                 * @param {infinitusai.be.IGetSkillBasedOperatorsForTaskRequest=} [properties] Properties to set
                 */
                function GetSkillBasedOperatorsForTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSkillBasedOperatorsForTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @instance
                 */
                GetSkillBasedOperatorsForTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new GetSkillBasedOperatorsForTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillBasedOperatorsForTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.GetSkillBasedOperatorsForTaskRequest} GetSkillBasedOperatorsForTaskRequest instance
                 */
                GetSkillBasedOperatorsForTaskRequest.create = function create(properties) {
                    return new GetSkillBasedOperatorsForTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified GetSkillBasedOperatorsForTaskRequest message. Does not implicitly {@link infinitusai.be.GetSkillBasedOperatorsForTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillBasedOperatorsForTaskRequest} message GetSkillBasedOperatorsForTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillBasedOperatorsForTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSkillBasedOperatorsForTaskRequest message, length delimited. Does not implicitly {@link infinitusai.be.GetSkillBasedOperatorsForTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {infinitusai.be.IGetSkillBasedOperatorsForTaskRequest} message GetSkillBasedOperatorsForTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillBasedOperatorsForTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSkillBasedOperatorsForTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetSkillBasedOperatorsForTaskRequest} GetSkillBasedOperatorsForTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillBasedOperatorsForTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetSkillBasedOperatorsForTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSkillBasedOperatorsForTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetSkillBasedOperatorsForTaskRequest} GetSkillBasedOperatorsForTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillBasedOperatorsForTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSkillBasedOperatorsForTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSkillBasedOperatorsForTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetSkillBasedOperatorsForTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetSkillBasedOperatorsForTaskRequest} GetSkillBasedOperatorsForTaskRequest
                 */
                GetSkillBasedOperatorsForTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetSkillBasedOperatorsForTaskRequest)
                        return object;
                    var message = new $root.infinitusai.be.GetSkillBasedOperatorsForTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSkillBasedOperatorsForTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {infinitusai.be.GetSkillBasedOperatorsForTaskRequest} message GetSkillBasedOperatorsForTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSkillBasedOperatorsForTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this GetSkillBasedOperatorsForTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSkillBasedOperatorsForTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSkillBasedOperatorsForTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSkillBasedOperatorsForTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetSkillBasedOperatorsForTaskRequest";
                };
    
                return GetSkillBasedOperatorsForTaskRequest;
            })();
    
            be.GetSkillBasedOperatorsForTaskResponse = (function() {
    
                /**
                 * Properties of a GetSkillBasedOperatorsForTaskResponse.
                 * @memberof infinitusai.be
                 * @interface IGetSkillBasedOperatorsForTaskResponse
                 * @property {Array.<string>|null} [operatorUuids] GetSkillBasedOperatorsForTaskResponse operatorUuids
                 */
    
                /**
                 * Constructs a new GetSkillBasedOperatorsForTaskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a GetSkillBasedOperatorsForTaskResponse.
                 * @implements IGetSkillBasedOperatorsForTaskResponse
                 * @constructor
                 * @param {infinitusai.be.IGetSkillBasedOperatorsForTaskResponse=} [properties] Properties to set
                 */
                function GetSkillBasedOperatorsForTaskResponse(properties) {
                    this.operatorUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSkillBasedOperatorsForTaskResponse operatorUuids.
                 * @member {Array.<string>} operatorUuids
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @instance
                 */
                GetSkillBasedOperatorsForTaskResponse.prototype.operatorUuids = $util.emptyArray;
    
                /**
                 * Creates a new GetSkillBasedOperatorsForTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillBasedOperatorsForTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.GetSkillBasedOperatorsForTaskResponse} GetSkillBasedOperatorsForTaskResponse instance
                 */
                GetSkillBasedOperatorsForTaskResponse.create = function create(properties) {
                    return new GetSkillBasedOperatorsForTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified GetSkillBasedOperatorsForTaskResponse message. Does not implicitly {@link infinitusai.be.GetSkillBasedOperatorsForTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillBasedOperatorsForTaskResponse} message GetSkillBasedOperatorsForTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillBasedOperatorsForTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorUuids != null && message.operatorUuids.length)
                        for (var i = 0; i < message.operatorUuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.operatorUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSkillBasedOperatorsForTaskResponse message, length delimited. Does not implicitly {@link infinitusai.be.GetSkillBasedOperatorsForTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {infinitusai.be.IGetSkillBasedOperatorsForTaskResponse} message GetSkillBasedOperatorsForTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSkillBasedOperatorsForTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSkillBasedOperatorsForTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.GetSkillBasedOperatorsForTaskResponse} GetSkillBasedOperatorsForTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillBasedOperatorsForTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.GetSkillBasedOperatorsForTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.operatorUuids && message.operatorUuids.length))
                                    message.operatorUuids = [];
                                message.operatorUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSkillBasedOperatorsForTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.GetSkillBasedOperatorsForTaskResponse} GetSkillBasedOperatorsForTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSkillBasedOperatorsForTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSkillBasedOperatorsForTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSkillBasedOperatorsForTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorUuids != null && message.hasOwnProperty("operatorUuids")) {
                        if (!Array.isArray(message.operatorUuids))
                            return "operatorUuids: array expected";
                        for (var i = 0; i < message.operatorUuids.length; ++i)
                            if (!$util.isString(message.operatorUuids[i]))
                                return "operatorUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetSkillBasedOperatorsForTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.GetSkillBasedOperatorsForTaskResponse} GetSkillBasedOperatorsForTaskResponse
                 */
                GetSkillBasedOperatorsForTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.GetSkillBasedOperatorsForTaskResponse)
                        return object;
                    var message = new $root.infinitusai.be.GetSkillBasedOperatorsForTaskResponse();
                    if (object.operatorUuids) {
                        if (!Array.isArray(object.operatorUuids))
                            throw TypeError(".infinitusai.be.GetSkillBasedOperatorsForTaskResponse.operatorUuids: array expected");
                        message.operatorUuids = [];
                        for (var i = 0; i < object.operatorUuids.length; ++i)
                            message.operatorUuids[i] = String(object.operatorUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSkillBasedOperatorsForTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {infinitusai.be.GetSkillBasedOperatorsForTaskResponse} message GetSkillBasedOperatorsForTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSkillBasedOperatorsForTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.operatorUuids = [];
                    if (message.operatorUuids && message.operatorUuids.length) {
                        object.operatorUuids = [];
                        for (var j = 0; j < message.operatorUuids.length; ++j)
                            object.operatorUuids[j] = message.operatorUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetSkillBasedOperatorsForTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSkillBasedOperatorsForTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSkillBasedOperatorsForTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.GetSkillBasedOperatorsForTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSkillBasedOperatorsForTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.GetSkillBasedOperatorsForTaskResponse";
                };
    
                return GetSkillBasedOperatorsForTaskResponse;
            })();
    
            be.AssembledLaunchConfig = (function() {
    
                /**
                 * Properties of an AssembledLaunchConfig.
                 * @memberof infinitusai.be
                 * @interface IAssembledLaunchConfig
                 * @property {boolean|null} [enableAgentStatePush] AssembledLaunchConfig enableAgentStatePush
                 * @property {Object.<string,boolean>|null} [enableConversationPush] AssembledLaunchConfig enableConversationPush
                 * @property {boolean|null} [enableGlobalConversationPush] AssembledLaunchConfig enableGlobalConversationPush
                 */
    
                /**
                 * Constructs a new AssembledLaunchConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents an AssembledLaunchConfig.
                 * @implements IAssembledLaunchConfig
                 * @constructor
                 * @param {infinitusai.be.IAssembledLaunchConfig=} [properties] Properties to set
                 */
                function AssembledLaunchConfig(properties) {
                    this.enableConversationPush = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AssembledLaunchConfig enableAgentStatePush.
                 * @member {boolean} enableAgentStatePush
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @instance
                 */
                AssembledLaunchConfig.prototype.enableAgentStatePush = false;
    
                /**
                 * AssembledLaunchConfig enableConversationPush.
                 * @member {Object.<string,boolean>} enableConversationPush
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @instance
                 */
                AssembledLaunchConfig.prototype.enableConversationPush = $util.emptyObject;
    
                /**
                 * AssembledLaunchConfig enableGlobalConversationPush.
                 * @member {boolean} enableGlobalConversationPush
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @instance
                 */
                AssembledLaunchConfig.prototype.enableGlobalConversationPush = false;
    
                /**
                 * Creates a new AssembledLaunchConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {infinitusai.be.IAssembledLaunchConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.AssembledLaunchConfig} AssembledLaunchConfig instance
                 */
                AssembledLaunchConfig.create = function create(properties) {
                    return new AssembledLaunchConfig(properties);
                };
    
                /**
                 * Encodes the specified AssembledLaunchConfig message. Does not implicitly {@link infinitusai.be.AssembledLaunchConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {infinitusai.be.IAssembledLaunchConfig} message AssembledLaunchConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AssembledLaunchConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.enableAgentStatePush != null && Object.hasOwnProperty.call(message, "enableAgentStatePush"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enableAgentStatePush);
                    if (message.enableConversationPush != null && Object.hasOwnProperty.call(message, "enableConversationPush"))
                        for (var keys = Object.keys(message.enableConversationPush), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.enableConversationPush[keys[i]]).ldelim();
                    if (message.enableGlobalConversationPush != null && Object.hasOwnProperty.call(message, "enableGlobalConversationPush"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.enableGlobalConversationPush);
                    return writer;
                };
    
                /**
                 * Encodes the specified AssembledLaunchConfig message, length delimited. Does not implicitly {@link infinitusai.be.AssembledLaunchConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {infinitusai.be.IAssembledLaunchConfig} message AssembledLaunchConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AssembledLaunchConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AssembledLaunchConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.AssembledLaunchConfig} AssembledLaunchConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AssembledLaunchConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.AssembledLaunchConfig(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.enableAgentStatePush = reader.bool();
                                break;
                            }
                        case 2: {
                                if (message.enableConversationPush === $util.emptyObject)
                                    message.enableConversationPush = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = false;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.bool();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.enableConversationPush[key] = value;
                                break;
                            }
                        case 3: {
                                message.enableGlobalConversationPush = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AssembledLaunchConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.AssembledLaunchConfig} AssembledLaunchConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AssembledLaunchConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AssembledLaunchConfig message.
                 * @function verify
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AssembledLaunchConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.enableAgentStatePush != null && message.hasOwnProperty("enableAgentStatePush"))
                        if (typeof message.enableAgentStatePush !== "boolean")
                            return "enableAgentStatePush: boolean expected";
                    if (message.enableConversationPush != null && message.hasOwnProperty("enableConversationPush")) {
                        if (!$util.isObject(message.enableConversationPush))
                            return "enableConversationPush: object expected";
                        var key = Object.keys(message.enableConversationPush);
                        for (var i = 0; i < key.length; ++i)
                            if (typeof message.enableConversationPush[key[i]] !== "boolean")
                                return "enableConversationPush: boolean{k:string} expected";
                    }
                    if (message.enableGlobalConversationPush != null && message.hasOwnProperty("enableGlobalConversationPush"))
                        if (typeof message.enableGlobalConversationPush !== "boolean")
                            return "enableGlobalConversationPush: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an AssembledLaunchConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.AssembledLaunchConfig} AssembledLaunchConfig
                 */
                AssembledLaunchConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.AssembledLaunchConfig)
                        return object;
                    var message = new $root.infinitusai.be.AssembledLaunchConfig();
                    if (object.enableAgentStatePush != null)
                        message.enableAgentStatePush = Boolean(object.enableAgentStatePush);
                    if (object.enableConversationPush) {
                        if (typeof object.enableConversationPush !== "object")
                            throw TypeError(".infinitusai.be.AssembledLaunchConfig.enableConversationPush: object expected");
                        message.enableConversationPush = {};
                        for (var keys = Object.keys(object.enableConversationPush), i = 0; i < keys.length; ++i)
                            message.enableConversationPush[keys[i]] = Boolean(object.enableConversationPush[keys[i]]);
                    }
                    if (object.enableGlobalConversationPush != null)
                        message.enableGlobalConversationPush = Boolean(object.enableGlobalConversationPush);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AssembledLaunchConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {infinitusai.be.AssembledLaunchConfig} message AssembledLaunchConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AssembledLaunchConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.enableConversationPush = {};
                    if (options.defaults) {
                        object.enableAgentStatePush = false;
                        object.enableGlobalConversationPush = false;
                    }
                    if (message.enableAgentStatePush != null && message.hasOwnProperty("enableAgentStatePush"))
                        object.enableAgentStatePush = message.enableAgentStatePush;
                    var keys2;
                    if (message.enableConversationPush && (keys2 = Object.keys(message.enableConversationPush)).length) {
                        object.enableConversationPush = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.enableConversationPush[keys2[j]] = message.enableConversationPush[keys2[j]];
                    }
                    if (message.enableGlobalConversationPush != null && message.hasOwnProperty("enableGlobalConversationPush"))
                        object.enableGlobalConversationPush = message.enableGlobalConversationPush;
                    return object;
                };
    
                /**
                 * Converts this AssembledLaunchConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AssembledLaunchConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AssembledLaunchConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.AssembledLaunchConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AssembledLaunchConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.AssembledLaunchConfig";
                };
    
                return AssembledLaunchConfig;
            })();
    
            be.PushAssembledStateRequest = (function() {
    
                /**
                 * Properties of a PushAssembledStateRequest.
                 * @memberof infinitusai.be
                 * @interface IPushAssembledStateRequest
                 * @property {infinitusai.be.IOperatorActivityUpdate|null} [operatorActivityUpdate] PushAssembledStateRequest operatorActivityUpdate
                 * @property {infinitusai.be.PushAssembledStateRequest.AssembledAgentState|null} [assembledAgentState] PushAssembledStateRequest assembledAgentState
                 * @property {string|null} [operatorEmail] PushAssembledStateRequest operatorEmail
                 */
    
                /**
                 * Constructs a new PushAssembledStateRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PushAssembledStateRequest.
                 * @implements IPushAssembledStateRequest
                 * @constructor
                 * @param {infinitusai.be.IPushAssembledStateRequest=} [properties] Properties to set
                 */
                function PushAssembledStateRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PushAssembledStateRequest operatorActivityUpdate.
                 * @member {infinitusai.be.IOperatorActivityUpdate|null|undefined} operatorActivityUpdate
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @instance
                 */
                PushAssembledStateRequest.prototype.operatorActivityUpdate = null;
    
                /**
                 * PushAssembledStateRequest assembledAgentState.
                 * @member {infinitusai.be.PushAssembledStateRequest.AssembledAgentState} assembledAgentState
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @instance
                 */
                PushAssembledStateRequest.prototype.assembledAgentState = 0;
    
                /**
                 * PushAssembledStateRequest operatorEmail.
                 * @member {string} operatorEmail
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @instance
                 */
                PushAssembledStateRequest.prototype.operatorEmail = "";
    
                /**
                 * Creates a new PushAssembledStateRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {infinitusai.be.IPushAssembledStateRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.PushAssembledStateRequest} PushAssembledStateRequest instance
                 */
                PushAssembledStateRequest.create = function create(properties) {
                    return new PushAssembledStateRequest(properties);
                };
    
                /**
                 * Encodes the specified PushAssembledStateRequest message. Does not implicitly {@link infinitusai.be.PushAssembledStateRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {infinitusai.be.IPushAssembledStateRequest} message PushAssembledStateRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushAssembledStateRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatorActivityUpdate != null && Object.hasOwnProperty.call(message, "operatorActivityUpdate"))
                        $root.infinitusai.be.OperatorActivityUpdate.encode(message.operatorActivityUpdate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.assembledAgentState != null && Object.hasOwnProperty.call(message, "assembledAgentState"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.assembledAgentState);
                    if (message.operatorEmail != null && Object.hasOwnProperty.call(message, "operatorEmail"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.operatorEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified PushAssembledStateRequest message, length delimited. Does not implicitly {@link infinitusai.be.PushAssembledStateRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {infinitusai.be.IPushAssembledStateRequest} message PushAssembledStateRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushAssembledStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PushAssembledStateRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PushAssembledStateRequest} PushAssembledStateRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushAssembledStateRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PushAssembledStateRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operatorActivityUpdate = $root.infinitusai.be.OperatorActivityUpdate.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.assembledAgentState = reader.int32();
                                break;
                            }
                        case 3: {
                                message.operatorEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PushAssembledStateRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PushAssembledStateRequest} PushAssembledStateRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushAssembledStateRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PushAssembledStateRequest message.
                 * @function verify
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PushAssembledStateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatorActivityUpdate != null && message.hasOwnProperty("operatorActivityUpdate")) {
                        var error = $root.infinitusai.be.OperatorActivityUpdate.verify(message.operatorActivityUpdate);
                        if (error)
                            return "operatorActivityUpdate." + error;
                    }
                    if (message.assembledAgentState != null && message.hasOwnProperty("assembledAgentState"))
                        switch (message.assembledAgentState) {
                        default:
                            return "assembledAgentState: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                            break;
                        }
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        if (!$util.isString(message.operatorEmail))
                            return "operatorEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a PushAssembledStateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PushAssembledStateRequest} PushAssembledStateRequest
                 */
                PushAssembledStateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PushAssembledStateRequest)
                        return object;
                    var message = new $root.infinitusai.be.PushAssembledStateRequest();
                    if (object.operatorActivityUpdate != null) {
                        if (typeof object.operatorActivityUpdate !== "object")
                            throw TypeError(".infinitusai.be.PushAssembledStateRequest.operatorActivityUpdate: object expected");
                        message.operatorActivityUpdate = $root.infinitusai.be.OperatorActivityUpdate.fromObject(object.operatorActivityUpdate);
                    }
                    switch (object.assembledAgentState) {
                    default:
                        if (typeof object.assembledAgentState === "number") {
                            message.assembledAgentState = object.assembledAgentState;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.assembledAgentState = 0;
                        break;
                    case "OFFLINE":
                    case 1:
                        message.assembledAgentState = 1;
                        break;
                    case "ONLINE":
                    case 2:
                        message.assembledAgentState = 2;
                        break;
                    case "VIEWING_TASK":
                    case 3:
                        message.assembledAgentState = 3;
                        break;
                    case "READY":
                    case 4:
                        message.assembledAgentState = 4;
                        break;
                    case "READY_PAGE_IDLE":
                    case 5:
                        message.assembledAgentState = 5;
                        break;
                    case "VIEWING_TASK_LIST":
                    case 6:
                        message.assembledAgentState = 6;
                        break;
                    case "CALL_IN_CONTROL":
                    case 7:
                        message.assembledAgentState = 7;
                        break;
                    case "CALL_IN_OBSERVE":
                    case 8:
                        message.assembledAgentState = 8;
                        break;
                    case "CALL_IN_SELF_REVIEW":
                    case 9:
                        message.assembledAgentState = 9;
                        break;
                    case "CALL_IN_REVIEW":
                    case 10:
                        message.assembledAgentState = 10;
                        break;
                    case "CALL_IN_VISIT":
                    case 11:
                        message.assembledAgentState = 11;
                        break;
                    }
                    if (object.operatorEmail != null)
                        message.operatorEmail = String(object.operatorEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a PushAssembledStateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {infinitusai.be.PushAssembledStateRequest} message PushAssembledStateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PushAssembledStateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operatorActivityUpdate = null;
                        object.assembledAgentState = options.enums === String ? "UNKNOWN" : 0;
                        object.operatorEmail = "";
                    }
                    if (message.operatorActivityUpdate != null && message.hasOwnProperty("operatorActivityUpdate"))
                        object.operatorActivityUpdate = $root.infinitusai.be.OperatorActivityUpdate.toObject(message.operatorActivityUpdate, options);
                    if (message.assembledAgentState != null && message.hasOwnProperty("assembledAgentState"))
                        object.assembledAgentState = options.enums === String ? $root.infinitusai.be.PushAssembledStateRequest.AssembledAgentState[message.assembledAgentState] === undefined ? message.assembledAgentState : $root.infinitusai.be.PushAssembledStateRequest.AssembledAgentState[message.assembledAgentState] : message.assembledAgentState;
                    if (message.operatorEmail != null && message.hasOwnProperty("operatorEmail"))
                        object.operatorEmail = message.operatorEmail;
                    return object;
                };
    
                /**
                 * Converts this PushAssembledStateRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PushAssembledStateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PushAssembledStateRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PushAssembledStateRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PushAssembledStateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PushAssembledStateRequest";
                };
    
                /**
                 * AssembledAgentState enum.
                 * @name infinitusai.be.PushAssembledStateRequest.AssembledAgentState
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} OFFLINE=1 OFFLINE value
                 * @property {number} ONLINE=2 ONLINE value
                 * @property {number} VIEWING_TASK=3 VIEWING_TASK value
                 * @property {number} READY=4 READY value
                 * @property {number} READY_PAGE_IDLE=5 READY_PAGE_IDLE value
                 * @property {number} VIEWING_TASK_LIST=6 VIEWING_TASK_LIST value
                 * @property {number} CALL_IN_CONTROL=7 CALL_IN_CONTROL value
                 * @property {number} CALL_IN_OBSERVE=8 CALL_IN_OBSERVE value
                 * @property {number} CALL_IN_SELF_REVIEW=9 CALL_IN_SELF_REVIEW value
                 * @property {number} CALL_IN_REVIEW=10 CALL_IN_REVIEW value
                 * @property {number} CALL_IN_VISIT=11 CALL_IN_VISIT value
                 */
                PushAssembledStateRequest.AssembledAgentState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "OFFLINE"] = 1;
                    values[valuesById[2] = "ONLINE"] = 2;
                    values[valuesById[3] = "VIEWING_TASK"] = 3;
                    values[valuesById[4] = "READY"] = 4;
                    values[valuesById[5] = "READY_PAGE_IDLE"] = 5;
                    values[valuesById[6] = "VIEWING_TASK_LIST"] = 6;
                    values[valuesById[7] = "CALL_IN_CONTROL"] = 7;
                    values[valuesById[8] = "CALL_IN_OBSERVE"] = 8;
                    values[valuesById[9] = "CALL_IN_SELF_REVIEW"] = 9;
                    values[valuesById[10] = "CALL_IN_REVIEW"] = 10;
                    values[valuesById[11] = "CALL_IN_VISIT"] = 11;
                    return values;
                })();
    
                return PushAssembledStateRequest;
            })();
    
            be.PushAssembledStateResponse = (function() {
    
                /**
                 * Properties of a PushAssembledStateResponse.
                 * @memberof infinitusai.be
                 * @interface IPushAssembledStateResponse
                 */
    
                /**
                 * Constructs a new PushAssembledStateResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PushAssembledStateResponse.
                 * @implements IPushAssembledStateResponse
                 * @constructor
                 * @param {infinitusai.be.IPushAssembledStateResponse=} [properties] Properties to set
                 */
                function PushAssembledStateResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new PushAssembledStateResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {infinitusai.be.IPushAssembledStateResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.PushAssembledStateResponse} PushAssembledStateResponse instance
                 */
                PushAssembledStateResponse.create = function create(properties) {
                    return new PushAssembledStateResponse(properties);
                };
    
                /**
                 * Encodes the specified PushAssembledStateResponse message. Does not implicitly {@link infinitusai.be.PushAssembledStateResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {infinitusai.be.IPushAssembledStateResponse} message PushAssembledStateResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushAssembledStateResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified PushAssembledStateResponse message, length delimited. Does not implicitly {@link infinitusai.be.PushAssembledStateResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {infinitusai.be.IPushAssembledStateResponse} message PushAssembledStateResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushAssembledStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PushAssembledStateResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PushAssembledStateResponse} PushAssembledStateResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushAssembledStateResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PushAssembledStateResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PushAssembledStateResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PushAssembledStateResponse} PushAssembledStateResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushAssembledStateResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PushAssembledStateResponse message.
                 * @function verify
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PushAssembledStateResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a PushAssembledStateResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PushAssembledStateResponse} PushAssembledStateResponse
                 */
                PushAssembledStateResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PushAssembledStateResponse)
                        return object;
                    return new $root.infinitusai.be.PushAssembledStateResponse();
                };
    
                /**
                 * Creates a plain object from a PushAssembledStateResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {infinitusai.be.PushAssembledStateResponse} message PushAssembledStateResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PushAssembledStateResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this PushAssembledStateResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PushAssembledStateResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PushAssembledStateResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PushAssembledStateResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PushAssembledStateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PushAssembledStateResponse";
                };
    
                return PushAssembledStateResponse;
            })();
    
            be.PushUserStateRequest = (function() {
    
                /**
                 * Properties of a PushUserStateRequest.
                 * @memberof infinitusai.be
                 * @interface IPushUserStateRequest
                 * @property {string|null} [userId] PushUserStateRequest userId
                 * @property {string|null} [userEmail] PushUserStateRequest userEmail
                 * @property {infinitusai.be.PushAssembledStateRequest.AssembledAgentState|null} [oldState] PushUserStateRequest oldState
                 * @property {infinitusai.be.PushAssembledStateRequest.AssembledAgentState|null} [newState] PushUserStateRequest newState
                 * @property {number|Long|null} [eventTimestampMillis] PushUserStateRequest eventTimestampMillis
                 */
    
                /**
                 * Constructs a new PushUserStateRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PushUserStateRequest.
                 * @implements IPushUserStateRequest
                 * @constructor
                 * @param {infinitusai.be.IPushUserStateRequest=} [properties] Properties to set
                 */
                function PushUserStateRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * PushUserStateRequest userId.
                 * @member {string} userId
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @instance
                 */
                PushUserStateRequest.prototype.userId = "";
    
                /**
                 * PushUserStateRequest userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @instance
                 */
                PushUserStateRequest.prototype.userEmail = "";
    
                /**
                 * PushUserStateRequest oldState.
                 * @member {infinitusai.be.PushAssembledStateRequest.AssembledAgentState} oldState
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @instance
                 */
                PushUserStateRequest.prototype.oldState = 0;
    
                /**
                 * PushUserStateRequest newState.
                 * @member {infinitusai.be.PushAssembledStateRequest.AssembledAgentState} newState
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @instance
                 */
                PushUserStateRequest.prototype.newState = 0;
    
                /**
                 * PushUserStateRequest eventTimestampMillis.
                 * @member {number|Long} eventTimestampMillis
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @instance
                 */
                PushUserStateRequest.prototype.eventTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new PushUserStateRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {infinitusai.be.IPushUserStateRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.PushUserStateRequest} PushUserStateRequest instance
                 */
                PushUserStateRequest.create = function create(properties) {
                    return new PushUserStateRequest(properties);
                };
    
                /**
                 * Encodes the specified PushUserStateRequest message. Does not implicitly {@link infinitusai.be.PushUserStateRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {infinitusai.be.IPushUserStateRequest} message PushUserStateRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushUserStateRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userEmail);
                    if (message.oldState != null && Object.hasOwnProperty.call(message, "oldState"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.oldState);
                    if (message.newState != null && Object.hasOwnProperty.call(message, "newState"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.newState);
                    if (message.eventTimestampMillis != null && Object.hasOwnProperty.call(message, "eventTimestampMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.eventTimestampMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified PushUserStateRequest message, length delimited. Does not implicitly {@link infinitusai.be.PushUserStateRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {infinitusai.be.IPushUserStateRequest} message PushUserStateRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushUserStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PushUserStateRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PushUserStateRequest} PushUserStateRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushUserStateRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PushUserStateRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 3: {
                                message.oldState = reader.int32();
                                break;
                            }
                        case 4: {
                                message.newState = reader.int32();
                                break;
                            }
                        case 5: {
                                message.eventTimestampMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PushUserStateRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PushUserStateRequest} PushUserStateRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushUserStateRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PushUserStateRequest message.
                 * @function verify
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PushUserStateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.oldState != null && message.hasOwnProperty("oldState"))
                        switch (message.oldState) {
                        default:
                            return "oldState: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                            break;
                        }
                    if (message.newState != null && message.hasOwnProperty("newState"))
                        switch (message.newState) {
                        default:
                            return "newState: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                            break;
                        }
                    if (message.eventTimestampMillis != null && message.hasOwnProperty("eventTimestampMillis"))
                        if (!$util.isInteger(message.eventTimestampMillis) && !(message.eventTimestampMillis && $util.isInteger(message.eventTimestampMillis.low) && $util.isInteger(message.eventTimestampMillis.high)))
                            return "eventTimestampMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a PushUserStateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PushUserStateRequest} PushUserStateRequest
                 */
                PushUserStateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PushUserStateRequest)
                        return object;
                    var message = new $root.infinitusai.be.PushUserStateRequest();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    switch (object.oldState) {
                    default:
                        if (typeof object.oldState === "number") {
                            message.oldState = object.oldState;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.oldState = 0;
                        break;
                    case "OFFLINE":
                    case 1:
                        message.oldState = 1;
                        break;
                    case "ONLINE":
                    case 2:
                        message.oldState = 2;
                        break;
                    case "VIEWING_TASK":
                    case 3:
                        message.oldState = 3;
                        break;
                    case "READY":
                    case 4:
                        message.oldState = 4;
                        break;
                    case "READY_PAGE_IDLE":
                    case 5:
                        message.oldState = 5;
                        break;
                    case "VIEWING_TASK_LIST":
                    case 6:
                        message.oldState = 6;
                        break;
                    case "CALL_IN_CONTROL":
                    case 7:
                        message.oldState = 7;
                        break;
                    case "CALL_IN_OBSERVE":
                    case 8:
                        message.oldState = 8;
                        break;
                    case "CALL_IN_SELF_REVIEW":
                    case 9:
                        message.oldState = 9;
                        break;
                    case "CALL_IN_REVIEW":
                    case 10:
                        message.oldState = 10;
                        break;
                    case "CALL_IN_VISIT":
                    case 11:
                        message.oldState = 11;
                        break;
                    }
                    switch (object.newState) {
                    default:
                        if (typeof object.newState === "number") {
                            message.newState = object.newState;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.newState = 0;
                        break;
                    case "OFFLINE":
                    case 1:
                        message.newState = 1;
                        break;
                    case "ONLINE":
                    case 2:
                        message.newState = 2;
                        break;
                    case "VIEWING_TASK":
                    case 3:
                        message.newState = 3;
                        break;
                    case "READY":
                    case 4:
                        message.newState = 4;
                        break;
                    case "READY_PAGE_IDLE":
                    case 5:
                        message.newState = 5;
                        break;
                    case "VIEWING_TASK_LIST":
                    case 6:
                        message.newState = 6;
                        break;
                    case "CALL_IN_CONTROL":
                    case 7:
                        message.newState = 7;
                        break;
                    case "CALL_IN_OBSERVE":
                    case 8:
                        message.newState = 8;
                        break;
                    case "CALL_IN_SELF_REVIEW":
                    case 9:
                        message.newState = 9;
                        break;
                    case "CALL_IN_REVIEW":
                    case 10:
                        message.newState = 10;
                        break;
                    case "CALL_IN_VISIT":
                    case 11:
                        message.newState = 11;
                        break;
                    }
                    if (object.eventTimestampMillis != null)
                        if ($util.Long)
                            (message.eventTimestampMillis = $util.Long.fromValue(object.eventTimestampMillis)).unsigned = false;
                        else if (typeof object.eventTimestampMillis === "string")
                            message.eventTimestampMillis = parseInt(object.eventTimestampMillis, 10);
                        else if (typeof object.eventTimestampMillis === "number")
                            message.eventTimestampMillis = object.eventTimestampMillis;
                        else if (typeof object.eventTimestampMillis === "object")
                            message.eventTimestampMillis = new $util.LongBits(object.eventTimestampMillis.low >>> 0, object.eventTimestampMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a PushUserStateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {infinitusai.be.PushUserStateRequest} message PushUserStateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PushUserStateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userId = "";
                        object.userEmail = "";
                        object.oldState = options.enums === String ? "UNKNOWN" : 0;
                        object.newState = options.enums === String ? "UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.eventTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.eventTimestampMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.oldState != null && message.hasOwnProperty("oldState"))
                        object.oldState = options.enums === String ? $root.infinitusai.be.PushAssembledStateRequest.AssembledAgentState[message.oldState] === undefined ? message.oldState : $root.infinitusai.be.PushAssembledStateRequest.AssembledAgentState[message.oldState] : message.oldState;
                    if (message.newState != null && message.hasOwnProperty("newState"))
                        object.newState = options.enums === String ? $root.infinitusai.be.PushAssembledStateRequest.AssembledAgentState[message.newState] === undefined ? message.newState : $root.infinitusai.be.PushAssembledStateRequest.AssembledAgentState[message.newState] : message.newState;
                    if (message.eventTimestampMillis != null && message.hasOwnProperty("eventTimestampMillis"))
                        if (typeof message.eventTimestampMillis === "number")
                            object.eventTimestampMillis = options.longs === String ? String(message.eventTimestampMillis) : message.eventTimestampMillis;
                        else
                            object.eventTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.eventTimestampMillis) : options.longs === Number ? new $util.LongBits(message.eventTimestampMillis.low >>> 0, message.eventTimestampMillis.high >>> 0).toNumber() : message.eventTimestampMillis;
                    return object;
                };
    
                /**
                 * Converts this PushUserStateRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PushUserStateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PushUserStateRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PushUserStateRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PushUserStateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PushUserStateRequest";
                };
    
                return PushUserStateRequest;
            })();
    
            be.PushUserStateResponse = (function() {
    
                /**
                 * Properties of a PushUserStateResponse.
                 * @memberof infinitusai.be
                 * @interface IPushUserStateResponse
                 */
    
                /**
                 * Constructs a new PushUserStateResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PushUserStateResponse.
                 * @implements IPushUserStateResponse
                 * @constructor
                 * @param {infinitusai.be.IPushUserStateResponse=} [properties] Properties to set
                 */
                function PushUserStateResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new PushUserStateResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {infinitusai.be.IPushUserStateResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.PushUserStateResponse} PushUserStateResponse instance
                 */
                PushUserStateResponse.create = function create(properties) {
                    return new PushUserStateResponse(properties);
                };
    
                /**
                 * Encodes the specified PushUserStateResponse message. Does not implicitly {@link infinitusai.be.PushUserStateResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {infinitusai.be.IPushUserStateResponse} message PushUserStateResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushUserStateResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified PushUserStateResponse message, length delimited. Does not implicitly {@link infinitusai.be.PushUserStateResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {infinitusai.be.IPushUserStateResponse} message PushUserStateResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushUserStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a PushUserStateResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.PushUserStateResponse} PushUserStateResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushUserStateResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.PushUserStateResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a PushUserStateResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.PushUserStateResponse} PushUserStateResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushUserStateResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a PushUserStateResponse message.
                 * @function verify
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PushUserStateResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a PushUserStateResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.PushUserStateResponse} PushUserStateResponse
                 */
                PushUserStateResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.PushUserStateResponse)
                        return object;
                    return new $root.infinitusai.be.PushUserStateResponse();
                };
    
                /**
                 * Creates a plain object from a PushUserStateResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {infinitusai.be.PushUserStateResponse} message PushUserStateResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PushUserStateResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this PushUserStateResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PushUserStateResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for PushUserStateResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.PushUserStateResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PushUserStateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.PushUserStateResponse";
                };
    
                return PushUserStateResponse;
            })();
    
            be.PushAssembledState = (function() {
    
                /**
                 * Constructs a new PushAssembledState service.
                 * @memberof infinitusai.be
                 * @classdesc Represents a PushAssembledState
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function PushAssembledState(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }
    
                (PushAssembledState.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PushAssembledState;
    
                /**
                 * Creates new PushAssembledState service using the specified rpc implementation.
                 * @function create
                 * @memberof infinitusai.be.PushAssembledState
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {PushAssembledState} RPC service. Useful where requests and/or responses are streamed.
                 */
                PushAssembledState.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };
    
                /**
                 * Callback as used by {@link infinitusai.be.PushAssembledState#pushAssembledState}.
                 * @memberof infinitusai.be.PushAssembledState
                 * @typedef PushAssembledStateCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.PushAssembledStateResponse} [response] PushAssembledStateResponse
                 */
    
                /**
                 * Calls PushAssembledState.
                 * @function pushAssembledState
                 * @memberof infinitusai.be.PushAssembledState
                 * @instance
                 * @param {infinitusai.be.IPushAssembledStateRequest} request PushAssembledStateRequest message or plain object
                 * @param {infinitusai.be.PushAssembledState.PushAssembledStateCallback} callback Node-style callback called with the error, if any, and PushAssembledStateResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PushAssembledState.prototype.pushAssembledState = function pushAssembledState(request, callback) {
                    return this.rpcCall(pushAssembledState, $root.infinitusai.be.PushAssembledStateRequest, $root.infinitusai.be.PushAssembledStateResponse, request, callback);
                }, "name", { value: "PushAssembledState" });
    
                /**
                 * Calls PushAssembledState.
                 * @function pushAssembledState
                 * @memberof infinitusai.be.PushAssembledState
                 * @instance
                 * @param {infinitusai.be.IPushAssembledStateRequest} request PushAssembledStateRequest message or plain object
                 * @returns {Promise<infinitusai.be.PushAssembledStateResponse>} Promise
                 * @variation 2
                 */
    
                /**
                 * Callback as used by {@link infinitusai.be.PushAssembledState#pushUserStateChange}.
                 * @memberof infinitusai.be.PushAssembledState
                 * @typedef PushUserStateChangeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {infinitusai.be.PushUserStateResponse} [response] PushUserStateResponse
                 */
    
                /**
                 * Calls PushUserStateChange.
                 * @function pushUserStateChange
                 * @memberof infinitusai.be.PushAssembledState
                 * @instance
                 * @param {infinitusai.be.IPushUserStateRequest} request PushUserStateRequest message or plain object
                 * @param {infinitusai.be.PushAssembledState.PushUserStateChangeCallback} callback Node-style callback called with the error, if any, and PushUserStateResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PushAssembledState.prototype.pushUserStateChange = function pushUserStateChange(request, callback) {
                    return this.rpcCall(pushUserStateChange, $root.infinitusai.be.PushUserStateRequest, $root.infinitusai.be.PushUserStateResponse, request, callback);
                }, "name", { value: "PushUserStateChange" });
    
                /**
                 * Calls PushUserStateChange.
                 * @function pushUserStateChange
                 * @memberof infinitusai.be.PushAssembledState
                 * @instance
                 * @param {infinitusai.be.IPushUserStateRequest} request PushUserStateRequest message or plain object
                 * @returns {Promise<infinitusai.be.PushUserStateResponse>} Promise
                 * @variation 2
                 */
    
                return PushAssembledState;
            })();
    
            be.SkillConfig = (function() {
    
                /**
                 * Properties of a SkillConfig.
                 * @memberof infinitusai.be
                 * @interface ISkillConfig
                 * @property {Object.<string,boolean>|null} [userEmailsAllowedToUpsertSkills] SkillConfig userEmailsAllowedToUpsertSkills
                 * @property {Object.<string,boolean>|null} [userEmailsAllowedToChangeUserSkills] SkillConfig userEmailsAllowedToChangeUserSkills
                 */
    
                /**
                 * Constructs a new SkillConfig.
                 * @memberof infinitusai.be
                 * @classdesc Represents a SkillConfig.
                 * @implements ISkillConfig
                 * @constructor
                 * @param {infinitusai.be.ISkillConfig=} [properties] Properties to set
                 */
                function SkillConfig(properties) {
                    this.userEmailsAllowedToUpsertSkills = {};
                    this.userEmailsAllowedToChangeUserSkills = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SkillConfig userEmailsAllowedToUpsertSkills.
                 * @member {Object.<string,boolean>} userEmailsAllowedToUpsertSkills
                 * @memberof infinitusai.be.SkillConfig
                 * @instance
                 */
                SkillConfig.prototype.userEmailsAllowedToUpsertSkills = $util.emptyObject;
    
                /**
                 * SkillConfig userEmailsAllowedToChangeUserSkills.
                 * @member {Object.<string,boolean>} userEmailsAllowedToChangeUserSkills
                 * @memberof infinitusai.be.SkillConfig
                 * @instance
                 */
                SkillConfig.prototype.userEmailsAllowedToChangeUserSkills = $util.emptyObject;
    
                /**
                 * Creates a new SkillConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {infinitusai.be.ISkillConfig=} [properties] Properties to set
                 * @returns {infinitusai.be.SkillConfig} SkillConfig instance
                 */
                SkillConfig.create = function create(properties) {
                    return new SkillConfig(properties);
                };
    
                /**
                 * Encodes the specified SkillConfig message. Does not implicitly {@link infinitusai.be.SkillConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {infinitusai.be.ISkillConfig} message SkillConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SkillConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userEmailsAllowedToUpsertSkills != null && Object.hasOwnProperty.call(message, "userEmailsAllowedToUpsertSkills"))
                        for (var keys = Object.keys(message.userEmailsAllowedToUpsertSkills), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.userEmailsAllowedToUpsertSkills[keys[i]]).ldelim();
                    if (message.userEmailsAllowedToChangeUserSkills != null && Object.hasOwnProperty.call(message, "userEmailsAllowedToChangeUserSkills"))
                        for (var keys = Object.keys(message.userEmailsAllowedToChangeUserSkills), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.userEmailsAllowedToChangeUserSkills[keys[i]]).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SkillConfig message, length delimited. Does not implicitly {@link infinitusai.be.SkillConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {infinitusai.be.ISkillConfig} message SkillConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SkillConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SkillConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.SkillConfig} SkillConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SkillConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.SkillConfig(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.userEmailsAllowedToUpsertSkills === $util.emptyObject)
                                    message.userEmailsAllowedToUpsertSkills = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = false;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.bool();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.userEmailsAllowedToUpsertSkills[key] = value;
                                break;
                            }
                        case 2: {
                                if (message.userEmailsAllowedToChangeUserSkills === $util.emptyObject)
                                    message.userEmailsAllowedToChangeUserSkills = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = false;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.bool();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.userEmailsAllowedToChangeUserSkills[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SkillConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.SkillConfig} SkillConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SkillConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SkillConfig message.
                 * @function verify
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SkillConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userEmailsAllowedToUpsertSkills != null && message.hasOwnProperty("userEmailsAllowedToUpsertSkills")) {
                        if (!$util.isObject(message.userEmailsAllowedToUpsertSkills))
                            return "userEmailsAllowedToUpsertSkills: object expected";
                        var key = Object.keys(message.userEmailsAllowedToUpsertSkills);
                        for (var i = 0; i < key.length; ++i)
                            if (typeof message.userEmailsAllowedToUpsertSkills[key[i]] !== "boolean")
                                return "userEmailsAllowedToUpsertSkills: boolean{k:string} expected";
                    }
                    if (message.userEmailsAllowedToChangeUserSkills != null && message.hasOwnProperty("userEmailsAllowedToChangeUserSkills")) {
                        if (!$util.isObject(message.userEmailsAllowedToChangeUserSkills))
                            return "userEmailsAllowedToChangeUserSkills: object expected";
                        var key = Object.keys(message.userEmailsAllowedToChangeUserSkills);
                        for (var i = 0; i < key.length; ++i)
                            if (typeof message.userEmailsAllowedToChangeUserSkills[key[i]] !== "boolean")
                                return "userEmailsAllowedToChangeUserSkills: boolean{k:string} expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a SkillConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.SkillConfig} SkillConfig
                 */
                SkillConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.SkillConfig)
                        return object;
                    var message = new $root.infinitusai.be.SkillConfig();
                    if (object.userEmailsAllowedToUpsertSkills) {
                        if (typeof object.userEmailsAllowedToUpsertSkills !== "object")
                            throw TypeError(".infinitusai.be.SkillConfig.userEmailsAllowedToUpsertSkills: object expected");
                        message.userEmailsAllowedToUpsertSkills = {};
                        for (var keys = Object.keys(object.userEmailsAllowedToUpsertSkills), i = 0; i < keys.length; ++i)
                            message.userEmailsAllowedToUpsertSkills[keys[i]] = Boolean(object.userEmailsAllowedToUpsertSkills[keys[i]]);
                    }
                    if (object.userEmailsAllowedToChangeUserSkills) {
                        if (typeof object.userEmailsAllowedToChangeUserSkills !== "object")
                            throw TypeError(".infinitusai.be.SkillConfig.userEmailsAllowedToChangeUserSkills: object expected");
                        message.userEmailsAllowedToChangeUserSkills = {};
                        for (var keys = Object.keys(object.userEmailsAllowedToChangeUserSkills), i = 0; i < keys.length; ++i)
                            message.userEmailsAllowedToChangeUserSkills[keys[i]] = Boolean(object.userEmailsAllowedToChangeUserSkills[keys[i]]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SkillConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {infinitusai.be.SkillConfig} message SkillConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SkillConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults) {
                        object.userEmailsAllowedToUpsertSkills = {};
                        object.userEmailsAllowedToChangeUserSkills = {};
                    }
                    var keys2;
                    if (message.userEmailsAllowedToUpsertSkills && (keys2 = Object.keys(message.userEmailsAllowedToUpsertSkills)).length) {
                        object.userEmailsAllowedToUpsertSkills = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.userEmailsAllowedToUpsertSkills[keys2[j]] = message.userEmailsAllowedToUpsertSkills[keys2[j]];
                    }
                    if (message.userEmailsAllowedToChangeUserSkills && (keys2 = Object.keys(message.userEmailsAllowedToChangeUserSkills)).length) {
                        object.userEmailsAllowedToChangeUserSkills = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.userEmailsAllowedToChangeUserSkills[keys2[j]] = message.userEmailsAllowedToChangeUserSkills[keys2[j]];
                    }
                    return object;
                };
    
                /**
                 * Converts this SkillConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.SkillConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SkillConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SkillConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.be.SkillConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SkillConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.SkillConfig";
                };
    
                return SkillConfig;
            })();
    
            be.ChatSOPRequest = (function() {
    
                /**
                 * Properties of a ChatSOPRequest.
                 * @memberof infinitusai.be
                 * @interface IChatSOPRequest
                 * @property {string|null} [taskUuid] ChatSOPRequest taskUuid
                 * @property {string|null} [callUuid] ChatSOPRequest callUuid
                 * @property {string|null} [actionName] ChatSOPRequest actionName
                 */
    
                /**
                 * Constructs a new ChatSOPRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChatSOPRequest.
                 * @implements IChatSOPRequest
                 * @constructor
                 * @param {infinitusai.be.IChatSOPRequest=} [properties] Properties to set
                 */
                function ChatSOPRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChatSOPRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @instance
                 */
                ChatSOPRequest.prototype.taskUuid = "";
    
                /**
                 * ChatSOPRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @instance
                 */
                ChatSOPRequest.prototype.callUuid = "";
    
                /**
                 * ChatSOPRequest actionName.
                 * @member {string} actionName
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @instance
                 */
                ChatSOPRequest.prototype.actionName = "";
    
                /**
                 * Creates a new ChatSOPRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {infinitusai.be.IChatSOPRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.ChatSOPRequest} ChatSOPRequest instance
                 */
                ChatSOPRequest.create = function create(properties) {
                    return new ChatSOPRequest(properties);
                };
    
                /**
                 * Encodes the specified ChatSOPRequest message. Does not implicitly {@link infinitusai.be.ChatSOPRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {infinitusai.be.IChatSOPRequest} message ChatSOPRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatSOPRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.actionName);
                    return writer;
                };
    
                /**
                 * Encodes the specified ChatSOPRequest message, length delimited. Does not implicitly {@link infinitusai.be.ChatSOPRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {infinitusai.be.IChatSOPRequest} message ChatSOPRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatSOPRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChatSOPRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChatSOPRequest} ChatSOPRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatSOPRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChatSOPRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.actionName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChatSOPRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChatSOPRequest} ChatSOPRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatSOPRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChatSOPRequest message.
                 * @function verify
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChatSOPRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        if (!$util.isString(message.actionName))
                            return "actionName: string expected";
                    return null;
                };
    
                /**
                 * Creates a ChatSOPRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChatSOPRequest} ChatSOPRequest
                 */
                ChatSOPRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChatSOPRequest)
                        return object;
                    var message = new $root.infinitusai.be.ChatSOPRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.actionName != null)
                        message.actionName = String(object.actionName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChatSOPRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {infinitusai.be.ChatSOPRequest} message ChatSOPRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChatSOPRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.actionName = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        object.actionName = message.actionName;
                    return object;
                };
    
                /**
                 * Converts this ChatSOPRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChatSOPRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChatSOPRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChatSOPRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChatSOPRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChatSOPRequest";
                };
    
                return ChatSOPRequest;
            })();
    
            be.ChatSOPResponse = (function() {
    
                /**
                 * Properties of a ChatSOPResponse.
                 * @memberof infinitusai.be
                 * @interface IChatSOPResponse
                 * @property {string|null} [url] ChatSOPResponse url
                 */
    
                /**
                 * Constructs a new ChatSOPResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a ChatSOPResponse.
                 * @implements IChatSOPResponse
                 * @constructor
                 * @param {infinitusai.be.IChatSOPResponse=} [properties] Properties to set
                 */
                function ChatSOPResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ChatSOPResponse url.
                 * @member {string} url
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @instance
                 */
                ChatSOPResponse.prototype.url = "";
    
                /**
                 * Creates a new ChatSOPResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {infinitusai.be.IChatSOPResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.ChatSOPResponse} ChatSOPResponse instance
                 */
                ChatSOPResponse.create = function create(properties) {
                    return new ChatSOPResponse(properties);
                };
    
                /**
                 * Encodes the specified ChatSOPResponse message. Does not implicitly {@link infinitusai.be.ChatSOPResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {infinitusai.be.IChatSOPResponse} message ChatSOPResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatSOPResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                    return writer;
                };
    
                /**
                 * Encodes the specified ChatSOPResponse message, length delimited. Does not implicitly {@link infinitusai.be.ChatSOPResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {infinitusai.be.IChatSOPResponse} message ChatSOPResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChatSOPResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ChatSOPResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.ChatSOPResponse} ChatSOPResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatSOPResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.ChatSOPResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.url = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ChatSOPResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.ChatSOPResponse} ChatSOPResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChatSOPResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ChatSOPResponse message.
                 * @function verify
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChatSOPResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    return null;
                };
    
                /**
                 * Creates a ChatSOPResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.ChatSOPResponse} ChatSOPResponse
                 */
                ChatSOPResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.ChatSOPResponse)
                        return object;
                    var message = new $root.infinitusai.be.ChatSOPResponse();
                    if (object.url != null)
                        message.url = String(object.url);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ChatSOPResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {infinitusai.be.ChatSOPResponse} message ChatSOPResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChatSOPResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.url = "";
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    return object;
                };
    
                /**
                 * Converts this ChatSOPResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChatSOPResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ChatSOPResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.ChatSOPResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChatSOPResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.ChatSOPResponse";
                };
    
                return ChatSOPResponse;
            })();
    
            be.InfiniAskRequest = (function() {
    
                /**
                 * Properties of an InfiniAskRequest.
                 * @memberof infinitusai.be
                 * @interface IInfiniAskRequest
                 * @property {string|null} [userGoogleId] InfiniAskRequest userGoogleId
                 * @property {string|null} [taskUuid] InfiniAskRequest taskUuid
                 * @property {string|null} [callUuid] InfiniAskRequest callUuid
                 * @property {string|null} [actionText] InfiniAskRequest actionText
                 * @property {string|null} [actionName] InfiniAskRequest actionName
                 * @property {string|null} [issue] InfiniAskRequest issue
                 */
    
                /**
                 * Constructs a new InfiniAskRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents an InfiniAskRequest.
                 * @implements IInfiniAskRequest
                 * @constructor
                 * @param {infinitusai.be.IInfiniAskRequest=} [properties] Properties to set
                 */
                function InfiniAskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * InfiniAskRequest userGoogleId.
                 * @member {string} userGoogleId
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @instance
                 */
                InfiniAskRequest.prototype.userGoogleId = "";
    
                /**
                 * InfiniAskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @instance
                 */
                InfiniAskRequest.prototype.taskUuid = "";
    
                /**
                 * InfiniAskRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @instance
                 */
                InfiniAskRequest.prototype.callUuid = "";
    
                /**
                 * InfiniAskRequest actionText.
                 * @member {string} actionText
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @instance
                 */
                InfiniAskRequest.prototype.actionText = "";
    
                /**
                 * InfiniAskRequest actionName.
                 * @member {string} actionName
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @instance
                 */
                InfiniAskRequest.prototype.actionName = "";
    
                /**
                 * InfiniAskRequest issue.
                 * @member {string} issue
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @instance
                 */
                InfiniAskRequest.prototype.issue = "";
    
                /**
                 * Creates a new InfiniAskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {infinitusai.be.IInfiniAskRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.InfiniAskRequest} InfiniAskRequest instance
                 */
                InfiniAskRequest.create = function create(properties) {
                    return new InfiniAskRequest(properties);
                };
    
                /**
                 * Encodes the specified InfiniAskRequest message. Does not implicitly {@link infinitusai.be.InfiniAskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {infinitusai.be.IInfiniAskRequest} message InfiniAskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InfiniAskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userGoogleId != null && Object.hasOwnProperty.call(message, "userGoogleId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userGoogleId);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.callUuid);
                    if (message.actionText != null && Object.hasOwnProperty.call(message, "actionText"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.actionText);
                    if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.actionName);
                    if (message.issue != null && Object.hasOwnProperty.call(message, "issue"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.issue);
                    return writer;
                };
    
                /**
                 * Encodes the specified InfiniAskRequest message, length delimited. Does not implicitly {@link infinitusai.be.InfiniAskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {infinitusai.be.IInfiniAskRequest} message InfiniAskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InfiniAskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an InfiniAskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.InfiniAskRequest} InfiniAskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InfiniAskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.InfiniAskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userGoogleId = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.actionText = reader.string();
                                break;
                            }
                        case 5: {
                                message.actionName = reader.string();
                                break;
                            }
                        case 6: {
                                message.issue = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an InfiniAskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.InfiniAskRequest} InfiniAskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InfiniAskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an InfiniAskRequest message.
                 * @function verify
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InfiniAskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGoogleId != null && message.hasOwnProperty("userGoogleId"))
                        if (!$util.isString(message.userGoogleId))
                            return "userGoogleId: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.actionText != null && message.hasOwnProperty("actionText"))
                        if (!$util.isString(message.actionText))
                            return "actionText: string expected";
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        if (!$util.isString(message.actionName))
                            return "actionName: string expected";
                    if (message.issue != null && message.hasOwnProperty("issue"))
                        if (!$util.isString(message.issue))
                            return "issue: string expected";
                    return null;
                };
    
                /**
                 * Creates an InfiniAskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.InfiniAskRequest} InfiniAskRequest
                 */
                InfiniAskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.InfiniAskRequest)
                        return object;
                    var message = new $root.infinitusai.be.InfiniAskRequest();
                    if (object.userGoogleId != null)
                        message.userGoogleId = String(object.userGoogleId);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.actionText != null)
                        message.actionText = String(object.actionText);
                    if (object.actionName != null)
                        message.actionName = String(object.actionName);
                    if (object.issue != null)
                        message.issue = String(object.issue);
                    return message;
                };
    
                /**
                 * Creates a plain object from an InfiniAskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {infinitusai.be.InfiniAskRequest} message InfiniAskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InfiniAskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userGoogleId = "";
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.actionText = "";
                        object.actionName = "";
                        object.issue = "";
                    }
                    if (message.userGoogleId != null && message.hasOwnProperty("userGoogleId"))
                        object.userGoogleId = message.userGoogleId;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.actionText != null && message.hasOwnProperty("actionText"))
                        object.actionText = message.actionText;
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        object.actionName = message.actionName;
                    if (message.issue != null && message.hasOwnProperty("issue"))
                        object.issue = message.issue;
                    return object;
                };
    
                /**
                 * Converts this InfiniAskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InfiniAskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for InfiniAskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.InfiniAskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InfiniAskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.InfiniAskRequest";
                };
    
                return InfiniAskRequest;
            })();
    
            be.InfiniAskResponse = (function() {
    
                /**
                 * Properties of an InfiniAskResponse.
                 * @memberof infinitusai.be
                 * @interface IInfiniAskResponse
                 */
    
                /**
                 * Constructs a new InfiniAskResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents an InfiniAskResponse.
                 * @implements IInfiniAskResponse
                 * @constructor
                 * @param {infinitusai.be.IInfiniAskResponse=} [properties] Properties to set
                 */
                function InfiniAskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new InfiniAskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {infinitusai.be.IInfiniAskResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.InfiniAskResponse} InfiniAskResponse instance
                 */
                InfiniAskResponse.create = function create(properties) {
                    return new InfiniAskResponse(properties);
                };
    
                /**
                 * Encodes the specified InfiniAskResponse message. Does not implicitly {@link infinitusai.be.InfiniAskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {infinitusai.be.IInfiniAskResponse} message InfiniAskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InfiniAskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified InfiniAskResponse message, length delimited. Does not implicitly {@link infinitusai.be.InfiniAskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {infinitusai.be.IInfiniAskResponse} message InfiniAskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InfiniAskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an InfiniAskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.InfiniAskResponse} InfiniAskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InfiniAskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.InfiniAskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an InfiniAskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.InfiniAskResponse} InfiniAskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InfiniAskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an InfiniAskResponse message.
                 * @function verify
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InfiniAskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an InfiniAskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.InfiniAskResponse} InfiniAskResponse
                 */
                InfiniAskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.InfiniAskResponse)
                        return object;
                    return new $root.infinitusai.be.InfiniAskResponse();
                };
    
                /**
                 * Creates a plain object from an InfiniAskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {infinitusai.be.InfiniAskResponse} message InfiniAskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InfiniAskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this InfiniAskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InfiniAskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for InfiniAskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.InfiniAskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InfiniAskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.InfiniAskResponse";
                };
    
                return InfiniAskResponse;
            })();
    
            be.BroadcastSuggestionsRefreshRequest = (function() {
    
                /**
                 * Properties of a BroadcastSuggestionsRefreshRequest.
                 * @memberof infinitusai.be
                 * @interface IBroadcastSuggestionsRefreshRequest
                 * @property {string|null} [taskUuid] BroadcastSuggestionsRefreshRequest taskUuid
                 * @property {string|null} [callUuid] BroadcastSuggestionsRefreshRequest callUuid
                 */
    
                /**
                 * Constructs a new BroadcastSuggestionsRefreshRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BroadcastSuggestionsRefreshRequest.
                 * @implements IBroadcastSuggestionsRefreshRequest
                 * @constructor
                 * @param {infinitusai.be.IBroadcastSuggestionsRefreshRequest=} [properties] Properties to set
                 */
                function BroadcastSuggestionsRefreshRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BroadcastSuggestionsRefreshRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @instance
                 */
                BroadcastSuggestionsRefreshRequest.prototype.taskUuid = "";
    
                /**
                 * BroadcastSuggestionsRefreshRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @instance
                 */
                BroadcastSuggestionsRefreshRequest.prototype.callUuid = "";
    
                /**
                 * Creates a new BroadcastSuggestionsRefreshRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {infinitusai.be.IBroadcastSuggestionsRefreshRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.BroadcastSuggestionsRefreshRequest} BroadcastSuggestionsRefreshRequest instance
                 */
                BroadcastSuggestionsRefreshRequest.create = function create(properties) {
                    return new BroadcastSuggestionsRefreshRequest(properties);
                };
    
                /**
                 * Encodes the specified BroadcastSuggestionsRefreshRequest message. Does not implicitly {@link infinitusai.be.BroadcastSuggestionsRefreshRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {infinitusai.be.IBroadcastSuggestionsRefreshRequest} message BroadcastSuggestionsRefreshRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BroadcastSuggestionsRefreshRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified BroadcastSuggestionsRefreshRequest message, length delimited. Does not implicitly {@link infinitusai.be.BroadcastSuggestionsRefreshRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {infinitusai.be.IBroadcastSuggestionsRefreshRequest} message BroadcastSuggestionsRefreshRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BroadcastSuggestionsRefreshRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BroadcastSuggestionsRefreshRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BroadcastSuggestionsRefreshRequest} BroadcastSuggestionsRefreshRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BroadcastSuggestionsRefreshRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BroadcastSuggestionsRefreshRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BroadcastSuggestionsRefreshRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BroadcastSuggestionsRefreshRequest} BroadcastSuggestionsRefreshRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BroadcastSuggestionsRefreshRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BroadcastSuggestionsRefreshRequest message.
                 * @function verify
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BroadcastSuggestionsRefreshRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a BroadcastSuggestionsRefreshRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BroadcastSuggestionsRefreshRequest} BroadcastSuggestionsRefreshRequest
                 */
                BroadcastSuggestionsRefreshRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BroadcastSuggestionsRefreshRequest)
                        return object;
                    var message = new $root.infinitusai.be.BroadcastSuggestionsRefreshRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a BroadcastSuggestionsRefreshRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {infinitusai.be.BroadcastSuggestionsRefreshRequest} message BroadcastSuggestionsRefreshRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BroadcastSuggestionsRefreshRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    return object;
                };
    
                /**
                 * Converts this BroadcastSuggestionsRefreshRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BroadcastSuggestionsRefreshRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BroadcastSuggestionsRefreshRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BroadcastSuggestionsRefreshRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BroadcastSuggestionsRefreshRequest";
                };
    
                return BroadcastSuggestionsRefreshRequest;
            })();
    
            be.BroadcastSuggestionsRefreshResponse = (function() {
    
                /**
                 * Properties of a BroadcastSuggestionsRefreshResponse.
                 * @memberof infinitusai.be
                 * @interface IBroadcastSuggestionsRefreshResponse
                 */
    
                /**
                 * Constructs a new BroadcastSuggestionsRefreshResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a BroadcastSuggestionsRefreshResponse.
                 * @implements IBroadcastSuggestionsRefreshResponse
                 * @constructor
                 * @param {infinitusai.be.IBroadcastSuggestionsRefreshResponse=} [properties] Properties to set
                 */
                function BroadcastSuggestionsRefreshResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new BroadcastSuggestionsRefreshResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {infinitusai.be.IBroadcastSuggestionsRefreshResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.BroadcastSuggestionsRefreshResponse} BroadcastSuggestionsRefreshResponse instance
                 */
                BroadcastSuggestionsRefreshResponse.create = function create(properties) {
                    return new BroadcastSuggestionsRefreshResponse(properties);
                };
    
                /**
                 * Encodes the specified BroadcastSuggestionsRefreshResponse message. Does not implicitly {@link infinitusai.be.BroadcastSuggestionsRefreshResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {infinitusai.be.IBroadcastSuggestionsRefreshResponse} message BroadcastSuggestionsRefreshResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BroadcastSuggestionsRefreshResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified BroadcastSuggestionsRefreshResponse message, length delimited. Does not implicitly {@link infinitusai.be.BroadcastSuggestionsRefreshResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {infinitusai.be.IBroadcastSuggestionsRefreshResponse} message BroadcastSuggestionsRefreshResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BroadcastSuggestionsRefreshResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BroadcastSuggestionsRefreshResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.BroadcastSuggestionsRefreshResponse} BroadcastSuggestionsRefreshResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BroadcastSuggestionsRefreshResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.BroadcastSuggestionsRefreshResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BroadcastSuggestionsRefreshResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.BroadcastSuggestionsRefreshResponse} BroadcastSuggestionsRefreshResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BroadcastSuggestionsRefreshResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BroadcastSuggestionsRefreshResponse message.
                 * @function verify
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BroadcastSuggestionsRefreshResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a BroadcastSuggestionsRefreshResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.BroadcastSuggestionsRefreshResponse} BroadcastSuggestionsRefreshResponse
                 */
                BroadcastSuggestionsRefreshResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.BroadcastSuggestionsRefreshResponse)
                        return object;
                    return new $root.infinitusai.be.BroadcastSuggestionsRefreshResponse();
                };
    
                /**
                 * Creates a plain object from a BroadcastSuggestionsRefreshResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {infinitusai.be.BroadcastSuggestionsRefreshResponse} message BroadcastSuggestionsRefreshResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BroadcastSuggestionsRefreshResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this BroadcastSuggestionsRefreshResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BroadcastSuggestionsRefreshResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BroadcastSuggestionsRefreshResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.BroadcastSuggestionsRefreshResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BroadcastSuggestionsRefreshResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.BroadcastSuggestionsRefreshResponse";
                };
    
                return BroadcastSuggestionsRefreshResponse;
            })();
    
            be.UhcAuth = (function() {
    
                /**
                 * Properties of an UhcAuth.
                 * @memberof infinitusai.be
                 * @interface IUhcAuth
                 * @property {string|null} [accessToken] UhcAuth accessToken
                 * @property {string|null} [expiresIn] UhcAuth expiresIn
                 * @property {number|Long|null} [expiresAtMillis] UhcAuth expiresAtMillis
                 */
    
                /**
                 * Constructs a new UhcAuth.
                 * @memberof infinitusai.be
                 * @classdesc Represents an UhcAuth.
                 * @implements IUhcAuth
                 * @constructor
                 * @param {infinitusai.be.IUhcAuth=} [properties] Properties to set
                 */
                function UhcAuth(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UhcAuth accessToken.
                 * @member {string} accessToken
                 * @memberof infinitusai.be.UhcAuth
                 * @instance
                 */
                UhcAuth.prototype.accessToken = "";
    
                /**
                 * UhcAuth expiresIn.
                 * @member {string} expiresIn
                 * @memberof infinitusai.be.UhcAuth
                 * @instance
                 */
                UhcAuth.prototype.expiresIn = "";
    
                /**
                 * UhcAuth expiresAtMillis.
                 * @member {number|Long} expiresAtMillis
                 * @memberof infinitusai.be.UhcAuth
                 * @instance
                 */
                UhcAuth.prototype.expiresAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new UhcAuth instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {infinitusai.be.IUhcAuth=} [properties] Properties to set
                 * @returns {infinitusai.be.UhcAuth} UhcAuth instance
                 */
                UhcAuth.create = function create(properties) {
                    return new UhcAuth(properties);
                };
    
                /**
                 * Encodes the specified UhcAuth message. Does not implicitly {@link infinitusai.be.UhcAuth.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {infinitusai.be.IUhcAuth} message UhcAuth message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UhcAuth.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.accessToken);
                    if (message.expiresIn != null && Object.hasOwnProperty.call(message, "expiresIn"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.expiresIn);
                    if (message.expiresAtMillis != null && Object.hasOwnProperty.call(message, "expiresAtMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.expiresAtMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified UhcAuth message, length delimited. Does not implicitly {@link infinitusai.be.UhcAuth.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {infinitusai.be.IUhcAuth} message UhcAuth message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UhcAuth.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UhcAuth message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.UhcAuth} UhcAuth
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UhcAuth.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.UhcAuth();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.accessToken = reader.string();
                                break;
                            }
                        case 2: {
                                message.expiresIn = reader.string();
                                break;
                            }
                        case 3: {
                                message.expiresAtMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UhcAuth message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.UhcAuth} UhcAuth
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UhcAuth.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UhcAuth message.
                 * @function verify
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UhcAuth.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                        if (!$util.isString(message.accessToken))
                            return "accessToken: string expected";
                    if (message.expiresIn != null && message.hasOwnProperty("expiresIn"))
                        if (!$util.isString(message.expiresIn))
                            return "expiresIn: string expected";
                    if (message.expiresAtMillis != null && message.hasOwnProperty("expiresAtMillis"))
                        if (!$util.isInteger(message.expiresAtMillis) && !(message.expiresAtMillis && $util.isInteger(message.expiresAtMillis.low) && $util.isInteger(message.expiresAtMillis.high)))
                            return "expiresAtMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an UhcAuth message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.UhcAuth} UhcAuth
                 */
                UhcAuth.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.UhcAuth)
                        return object;
                    var message = new $root.infinitusai.be.UhcAuth();
                    if (object.accessToken != null)
                        message.accessToken = String(object.accessToken);
                    if (object.expiresIn != null)
                        message.expiresIn = String(object.expiresIn);
                    if (object.expiresAtMillis != null)
                        if ($util.Long)
                            (message.expiresAtMillis = $util.Long.fromValue(object.expiresAtMillis)).unsigned = false;
                        else if (typeof object.expiresAtMillis === "string")
                            message.expiresAtMillis = parseInt(object.expiresAtMillis, 10);
                        else if (typeof object.expiresAtMillis === "number")
                            message.expiresAtMillis = object.expiresAtMillis;
                        else if (typeof object.expiresAtMillis === "object")
                            message.expiresAtMillis = new $util.LongBits(object.expiresAtMillis.low >>> 0, object.expiresAtMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an UhcAuth message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {infinitusai.be.UhcAuth} message UhcAuth
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UhcAuth.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.accessToken = "";
                        object.expiresIn = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expiresAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expiresAtMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                        object.accessToken = message.accessToken;
                    if (message.expiresIn != null && message.hasOwnProperty("expiresIn"))
                        object.expiresIn = message.expiresIn;
                    if (message.expiresAtMillis != null && message.hasOwnProperty("expiresAtMillis"))
                        if (typeof message.expiresAtMillis === "number")
                            object.expiresAtMillis = options.longs === String ? String(message.expiresAtMillis) : message.expiresAtMillis;
                        else
                            object.expiresAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.expiresAtMillis) : options.longs === Number ? new $util.LongBits(message.expiresAtMillis.low >>> 0, message.expiresAtMillis.high >>> 0).toNumber() : message.expiresAtMillis;
                    return object;
                };
    
                /**
                 * Converts this UhcAuth to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.UhcAuth
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UhcAuth.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UhcAuth
                 * @function getTypeUrl
                 * @memberof infinitusai.be.UhcAuth
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UhcAuth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.UhcAuth";
                };
    
                return UhcAuth;
            })();
    
            be.FrontendVersionRequest = (function() {
    
                /**
                 * Properties of a FrontendVersionRequest.
                 * @memberof infinitusai.be
                 * @interface IFrontendVersionRequest
                 * @property {string|null} [projectName] FrontendVersionRequest projectName
                 */
    
                /**
                 * Constructs a new FrontendVersionRequest.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FrontendVersionRequest.
                 * @implements IFrontendVersionRequest
                 * @constructor
                 * @param {infinitusai.be.IFrontendVersionRequest=} [properties] Properties to set
                 */
                function FrontendVersionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FrontendVersionRequest projectName.
                 * @member {string} projectName
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @instance
                 */
                FrontendVersionRequest.prototype.projectName = "";
    
                /**
                 * Creates a new FrontendVersionRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {infinitusai.be.IFrontendVersionRequest=} [properties] Properties to set
                 * @returns {infinitusai.be.FrontendVersionRequest} FrontendVersionRequest instance
                 */
                FrontendVersionRequest.create = function create(properties) {
                    return new FrontendVersionRequest(properties);
                };
    
                /**
                 * Encodes the specified FrontendVersionRequest message. Does not implicitly {@link infinitusai.be.FrontendVersionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {infinitusai.be.IFrontendVersionRequest} message FrontendVersionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FrontendVersionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.projectName != null && Object.hasOwnProperty.call(message, "projectName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectName);
                    return writer;
                };
    
                /**
                 * Encodes the specified FrontendVersionRequest message, length delimited. Does not implicitly {@link infinitusai.be.FrontendVersionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {infinitusai.be.IFrontendVersionRequest} message FrontendVersionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FrontendVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FrontendVersionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FrontendVersionRequest} FrontendVersionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FrontendVersionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FrontendVersionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.projectName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FrontendVersionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FrontendVersionRequest} FrontendVersionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FrontendVersionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FrontendVersionRequest message.
                 * @function verify
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FrontendVersionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.projectName != null && message.hasOwnProperty("projectName"))
                        if (!$util.isString(message.projectName))
                            return "projectName: string expected";
                    return null;
                };
    
                /**
                 * Creates a FrontendVersionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FrontendVersionRequest} FrontendVersionRequest
                 */
                FrontendVersionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FrontendVersionRequest)
                        return object;
                    var message = new $root.infinitusai.be.FrontendVersionRequest();
                    if (object.projectName != null)
                        message.projectName = String(object.projectName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FrontendVersionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {infinitusai.be.FrontendVersionRequest} message FrontendVersionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FrontendVersionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.projectName = "";
                    if (message.projectName != null && message.hasOwnProperty("projectName"))
                        object.projectName = message.projectName;
                    return object;
                };
    
                /**
                 * Converts this FrontendVersionRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FrontendVersionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FrontendVersionRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FrontendVersionRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FrontendVersionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FrontendVersionRequest";
                };
    
                return FrontendVersionRequest;
            })();
    
            be.FrontendVersionResponse = (function() {
    
                /**
                 * Properties of a FrontendVersionResponse.
                 * @memberof infinitusai.be
                 * @interface IFrontendVersionResponse
                 * @property {string|null} [version] FrontendVersionResponse version
                 */
    
                /**
                 * Constructs a new FrontendVersionResponse.
                 * @memberof infinitusai.be
                 * @classdesc Represents a FrontendVersionResponse.
                 * @implements IFrontendVersionResponse
                 * @constructor
                 * @param {infinitusai.be.IFrontendVersionResponse=} [properties] Properties to set
                 */
                function FrontendVersionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FrontendVersionResponse version.
                 * @member {string} version
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @instance
                 */
                FrontendVersionResponse.prototype.version = "";
    
                /**
                 * Creates a new FrontendVersionResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {infinitusai.be.IFrontendVersionResponse=} [properties] Properties to set
                 * @returns {infinitusai.be.FrontendVersionResponse} FrontendVersionResponse instance
                 */
                FrontendVersionResponse.create = function create(properties) {
                    return new FrontendVersionResponse(properties);
                };
    
                /**
                 * Encodes the specified FrontendVersionResponse message. Does not implicitly {@link infinitusai.be.FrontendVersionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {infinitusai.be.IFrontendVersionResponse} message FrontendVersionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FrontendVersionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.version);
                    return writer;
                };
    
                /**
                 * Encodes the specified FrontendVersionResponse message, length delimited. Does not implicitly {@link infinitusai.be.FrontendVersionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {infinitusai.be.IFrontendVersionResponse} message FrontendVersionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FrontendVersionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FrontendVersionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.be.FrontendVersionResponse} FrontendVersionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FrontendVersionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.be.FrontendVersionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.version = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FrontendVersionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.be.FrontendVersionResponse} FrontendVersionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FrontendVersionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FrontendVersionResponse message.
                 * @function verify
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FrontendVersionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.version != null && message.hasOwnProperty("version"))
                        if (!$util.isString(message.version))
                            return "version: string expected";
                    return null;
                };
    
                /**
                 * Creates a FrontendVersionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.be.FrontendVersionResponse} FrontendVersionResponse
                 */
                FrontendVersionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.be.FrontendVersionResponse)
                        return object;
                    var message = new $root.infinitusai.be.FrontendVersionResponse();
                    if (object.version != null)
                        message.version = String(object.version);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FrontendVersionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {infinitusai.be.FrontendVersionResponse} message FrontendVersionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FrontendVersionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.version = "";
                    if (message.version != null && message.hasOwnProperty("version"))
                        object.version = message.version;
                    return object;
                };
    
                /**
                 * Converts this FrontendVersionResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FrontendVersionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FrontendVersionResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.be.FrontendVersionResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FrontendVersionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.be.FrontendVersionResponse";
                };
    
                return FrontendVersionResponse;
            })();
    
            return be;
        })();
    
        infinitusai.announcements = (function() {
    
            /**
             * Namespace announcements.
             * @memberof infinitusai
             * @namespace
             */
            var announcements = {};
    
            announcements.GetMyAnnouncementsRequest = (function() {
    
                /**
                 * Properties of a GetMyAnnouncementsRequest.
                 * @memberof infinitusai.announcements
                 * @interface IGetMyAnnouncementsRequest
                 * @property {infinitusai.auth.AppName|null} [appName] GetMyAnnouncementsRequest appName
                 */
    
                /**
                 * Constructs a new GetMyAnnouncementsRequest.
                 * @memberof infinitusai.announcements
                 * @classdesc Represents a GetMyAnnouncementsRequest.
                 * @implements IGetMyAnnouncementsRequest
                 * @constructor
                 * @param {infinitusai.announcements.IGetMyAnnouncementsRequest=} [properties] Properties to set
                 */
                function GetMyAnnouncementsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyAnnouncementsRequest appName.
                 * @member {infinitusai.auth.AppName} appName
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @instance
                 */
                GetMyAnnouncementsRequest.prototype.appName = 0;
    
                /**
                 * Creates a new GetMyAnnouncementsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {infinitusai.announcements.IGetMyAnnouncementsRequest=} [properties] Properties to set
                 * @returns {infinitusai.announcements.GetMyAnnouncementsRequest} GetMyAnnouncementsRequest instance
                 */
                GetMyAnnouncementsRequest.create = function create(properties) {
                    return new GetMyAnnouncementsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetMyAnnouncementsRequest message. Does not implicitly {@link infinitusai.announcements.GetMyAnnouncementsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {infinitusai.announcements.IGetMyAnnouncementsRequest} message GetMyAnnouncementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyAnnouncementsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appName != null && Object.hasOwnProperty.call(message, "appName"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.appName);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyAnnouncementsRequest message, length delimited. Does not implicitly {@link infinitusai.announcements.GetMyAnnouncementsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {infinitusai.announcements.IGetMyAnnouncementsRequest} message GetMyAnnouncementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyAnnouncementsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyAnnouncementsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.announcements.GetMyAnnouncementsRequest} GetMyAnnouncementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyAnnouncementsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.announcements.GetMyAnnouncementsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appName = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyAnnouncementsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.announcements.GetMyAnnouncementsRequest} GetMyAnnouncementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyAnnouncementsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyAnnouncementsRequest message.
                 * @function verify
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyAnnouncementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appName != null && message.hasOwnProperty("appName"))
                        switch (message.appName) {
                        default:
                            return "appName: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GetMyAnnouncementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.announcements.GetMyAnnouncementsRequest} GetMyAnnouncementsRequest
                 */
                GetMyAnnouncementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.announcements.GetMyAnnouncementsRequest)
                        return object;
                    var message = new $root.infinitusai.announcements.GetMyAnnouncementsRequest();
                    switch (object.appName) {
                    default:
                        if (typeof object.appName === "number") {
                            message.appName = object.appName;
                            break;
                        }
                        break;
                    case "APP_NAME_UNKNOWN":
                    case 0:
                        message.appName = 0;
                        break;
                    case "APP_NAME_ADMIN":
                    case 1:
                        message.appName = 1;
                        break;
                    case "APP_NAME_OPERATOR":
                    case 2:
                        message.appName = 2;
                        break;
                    case "APP_NAME_CUSTOMER":
                    case 3:
                        message.appName = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyAnnouncementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {infinitusai.announcements.GetMyAnnouncementsRequest} message GetMyAnnouncementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyAnnouncementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.appName = options.enums === String ? "APP_NAME_UNKNOWN" : 0;
                    if (message.appName != null && message.hasOwnProperty("appName"))
                        object.appName = options.enums === String ? $root.infinitusai.auth.AppName[message.appName] === undefined ? message.appName : $root.infinitusai.auth.AppName[message.appName] : message.appName;
                    return object;
                };
    
                /**
                 * Converts this GetMyAnnouncementsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyAnnouncementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyAnnouncementsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.announcements.GetMyAnnouncementsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyAnnouncementsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.announcements.GetMyAnnouncementsRequest";
                };
    
                return GetMyAnnouncementsRequest;
            })();
    
            announcements.GetMyAnnouncementsResponse = (function() {
    
                /**
                 * Properties of a GetMyAnnouncementsResponse.
                 * @memberof infinitusai.announcements
                 * @interface IGetMyAnnouncementsResponse
                 * @property {Array.<infinitusai.announcements.IAnnouncement>|null} [announcements] GetMyAnnouncementsResponse announcements
                 */
    
                /**
                 * Constructs a new GetMyAnnouncementsResponse.
                 * @memberof infinitusai.announcements
                 * @classdesc Represents a GetMyAnnouncementsResponse.
                 * @implements IGetMyAnnouncementsResponse
                 * @constructor
                 * @param {infinitusai.announcements.IGetMyAnnouncementsResponse=} [properties] Properties to set
                 */
                function GetMyAnnouncementsResponse(properties) {
                    this.announcements = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyAnnouncementsResponse announcements.
                 * @member {Array.<infinitusai.announcements.IAnnouncement>} announcements
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @instance
                 */
                GetMyAnnouncementsResponse.prototype.announcements = $util.emptyArray;
    
                /**
                 * Creates a new GetMyAnnouncementsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {infinitusai.announcements.IGetMyAnnouncementsResponse=} [properties] Properties to set
                 * @returns {infinitusai.announcements.GetMyAnnouncementsResponse} GetMyAnnouncementsResponse instance
                 */
                GetMyAnnouncementsResponse.create = function create(properties) {
                    return new GetMyAnnouncementsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetMyAnnouncementsResponse message. Does not implicitly {@link infinitusai.announcements.GetMyAnnouncementsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {infinitusai.announcements.IGetMyAnnouncementsResponse} message GetMyAnnouncementsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyAnnouncementsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.announcements != null && message.announcements.length)
                        for (var i = 0; i < message.announcements.length; ++i)
                            $root.infinitusai.announcements.Announcement.encode(message.announcements[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyAnnouncementsResponse message, length delimited. Does not implicitly {@link infinitusai.announcements.GetMyAnnouncementsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {infinitusai.announcements.IGetMyAnnouncementsResponse} message GetMyAnnouncementsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyAnnouncementsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyAnnouncementsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.announcements.GetMyAnnouncementsResponse} GetMyAnnouncementsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyAnnouncementsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.announcements.GetMyAnnouncementsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.announcements && message.announcements.length))
                                    message.announcements = [];
                                message.announcements.push($root.infinitusai.announcements.Announcement.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyAnnouncementsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.announcements.GetMyAnnouncementsResponse} GetMyAnnouncementsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyAnnouncementsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyAnnouncementsResponse message.
                 * @function verify
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyAnnouncementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.announcements != null && message.hasOwnProperty("announcements")) {
                        if (!Array.isArray(message.announcements))
                            return "announcements: array expected";
                        for (var i = 0; i < message.announcements.length; ++i) {
                            var error = $root.infinitusai.announcements.Announcement.verify(message.announcements[i]);
                            if (error)
                                return "announcements." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetMyAnnouncementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.announcements.GetMyAnnouncementsResponse} GetMyAnnouncementsResponse
                 */
                GetMyAnnouncementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.announcements.GetMyAnnouncementsResponse)
                        return object;
                    var message = new $root.infinitusai.announcements.GetMyAnnouncementsResponse();
                    if (object.announcements) {
                        if (!Array.isArray(object.announcements))
                            throw TypeError(".infinitusai.announcements.GetMyAnnouncementsResponse.announcements: array expected");
                        message.announcements = [];
                        for (var i = 0; i < object.announcements.length; ++i) {
                            if (typeof object.announcements[i] !== "object")
                                throw TypeError(".infinitusai.announcements.GetMyAnnouncementsResponse.announcements: object expected");
                            message.announcements[i] = $root.infinitusai.announcements.Announcement.fromObject(object.announcements[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyAnnouncementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {infinitusai.announcements.GetMyAnnouncementsResponse} message GetMyAnnouncementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyAnnouncementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.announcements = [];
                    if (message.announcements && message.announcements.length) {
                        object.announcements = [];
                        for (var j = 0; j < message.announcements.length; ++j)
                            object.announcements[j] = $root.infinitusai.announcements.Announcement.toObject(message.announcements[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetMyAnnouncementsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyAnnouncementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyAnnouncementsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.announcements.GetMyAnnouncementsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyAnnouncementsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.announcements.GetMyAnnouncementsResponse";
                };
    
                return GetMyAnnouncementsResponse;
            })();
    
            announcements.MarkAnnouncementViewedRequest = (function() {
    
                /**
                 * Properties of a MarkAnnouncementViewedRequest.
                 * @memberof infinitusai.announcements
                 * @interface IMarkAnnouncementViewedRequest
                 * @property {string|null} [uuid] MarkAnnouncementViewedRequest uuid
                 */
    
                /**
                 * Constructs a new MarkAnnouncementViewedRequest.
                 * @memberof infinitusai.announcements
                 * @classdesc Represents a MarkAnnouncementViewedRequest.
                 * @implements IMarkAnnouncementViewedRequest
                 * @constructor
                 * @param {infinitusai.announcements.IMarkAnnouncementViewedRequest=} [properties] Properties to set
                 */
                function MarkAnnouncementViewedRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MarkAnnouncementViewedRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @instance
                 */
                MarkAnnouncementViewedRequest.prototype.uuid = "";
    
                /**
                 * Creates a new MarkAnnouncementViewedRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {infinitusai.announcements.IMarkAnnouncementViewedRequest=} [properties] Properties to set
                 * @returns {infinitusai.announcements.MarkAnnouncementViewedRequest} MarkAnnouncementViewedRequest instance
                 */
                MarkAnnouncementViewedRequest.create = function create(properties) {
                    return new MarkAnnouncementViewedRequest(properties);
                };
    
                /**
                 * Encodes the specified MarkAnnouncementViewedRequest message. Does not implicitly {@link infinitusai.announcements.MarkAnnouncementViewedRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {infinitusai.announcements.IMarkAnnouncementViewedRequest} message MarkAnnouncementViewedRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkAnnouncementViewedRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified MarkAnnouncementViewedRequest message, length delimited. Does not implicitly {@link infinitusai.announcements.MarkAnnouncementViewedRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {infinitusai.announcements.IMarkAnnouncementViewedRequest} message MarkAnnouncementViewedRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkAnnouncementViewedRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MarkAnnouncementViewedRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.announcements.MarkAnnouncementViewedRequest} MarkAnnouncementViewedRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkAnnouncementViewedRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.announcements.MarkAnnouncementViewedRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MarkAnnouncementViewedRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.announcements.MarkAnnouncementViewedRequest} MarkAnnouncementViewedRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkAnnouncementViewedRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MarkAnnouncementViewedRequest message.
                 * @function verify
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MarkAnnouncementViewedRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a MarkAnnouncementViewedRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.announcements.MarkAnnouncementViewedRequest} MarkAnnouncementViewedRequest
                 */
                MarkAnnouncementViewedRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.announcements.MarkAnnouncementViewedRequest)
                        return object;
                    var message = new $root.infinitusai.announcements.MarkAnnouncementViewedRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a MarkAnnouncementViewedRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {infinitusai.announcements.MarkAnnouncementViewedRequest} message MarkAnnouncementViewedRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MarkAnnouncementViewedRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this MarkAnnouncementViewedRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MarkAnnouncementViewedRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MarkAnnouncementViewedRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MarkAnnouncementViewedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.announcements.MarkAnnouncementViewedRequest";
                };
    
                return MarkAnnouncementViewedRequest;
            })();
    
            announcements.MarkAnnouncementViewedResponse = (function() {
    
                /**
                 * Properties of a MarkAnnouncementViewedResponse.
                 * @memberof infinitusai.announcements
                 * @interface IMarkAnnouncementViewedResponse
                 */
    
                /**
                 * Constructs a new MarkAnnouncementViewedResponse.
                 * @memberof infinitusai.announcements
                 * @classdesc Represents a MarkAnnouncementViewedResponse.
                 * @implements IMarkAnnouncementViewedResponse
                 * @constructor
                 * @param {infinitusai.announcements.IMarkAnnouncementViewedResponse=} [properties] Properties to set
                 */
                function MarkAnnouncementViewedResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new MarkAnnouncementViewedResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {infinitusai.announcements.IMarkAnnouncementViewedResponse=} [properties] Properties to set
                 * @returns {infinitusai.announcements.MarkAnnouncementViewedResponse} MarkAnnouncementViewedResponse instance
                 */
                MarkAnnouncementViewedResponse.create = function create(properties) {
                    return new MarkAnnouncementViewedResponse(properties);
                };
    
                /**
                 * Encodes the specified MarkAnnouncementViewedResponse message. Does not implicitly {@link infinitusai.announcements.MarkAnnouncementViewedResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {infinitusai.announcements.IMarkAnnouncementViewedResponse} message MarkAnnouncementViewedResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkAnnouncementViewedResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified MarkAnnouncementViewedResponse message, length delimited. Does not implicitly {@link infinitusai.announcements.MarkAnnouncementViewedResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {infinitusai.announcements.IMarkAnnouncementViewedResponse} message MarkAnnouncementViewedResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MarkAnnouncementViewedResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MarkAnnouncementViewedResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.announcements.MarkAnnouncementViewedResponse} MarkAnnouncementViewedResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkAnnouncementViewedResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.announcements.MarkAnnouncementViewedResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MarkAnnouncementViewedResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.announcements.MarkAnnouncementViewedResponse} MarkAnnouncementViewedResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MarkAnnouncementViewedResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MarkAnnouncementViewedResponse message.
                 * @function verify
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MarkAnnouncementViewedResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a MarkAnnouncementViewedResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.announcements.MarkAnnouncementViewedResponse} MarkAnnouncementViewedResponse
                 */
                MarkAnnouncementViewedResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.announcements.MarkAnnouncementViewedResponse)
                        return object;
                    return new $root.infinitusai.announcements.MarkAnnouncementViewedResponse();
                };
    
                /**
                 * Creates a plain object from a MarkAnnouncementViewedResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {infinitusai.announcements.MarkAnnouncementViewedResponse} message MarkAnnouncementViewedResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MarkAnnouncementViewedResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this MarkAnnouncementViewedResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MarkAnnouncementViewedResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MarkAnnouncementViewedResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.announcements.MarkAnnouncementViewedResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MarkAnnouncementViewedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.announcements.MarkAnnouncementViewedResponse";
                };
    
                return MarkAnnouncementViewedResponse;
            })();
    
            /**
             * AnnouncementType enum.
             * @name infinitusai.announcements.AnnouncementType
             * @enum {number}
             * @property {number} ANNOUNCEMENT_TYPE_UNKONWN=0 ANNOUNCEMENT_TYPE_UNKONWN value
             * @property {number} ANNOUNCEMENT_TYPE_MODAL=1 ANNOUNCEMENT_TYPE_MODAL value
             * @property {number} ANNOUNCEMENT_TYPE_BANNER=2 ANNOUNCEMENT_TYPE_BANNER value
             */
            announcements.AnnouncementType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ANNOUNCEMENT_TYPE_UNKONWN"] = 0;
                values[valuesById[1] = "ANNOUNCEMENT_TYPE_MODAL"] = 1;
                values[valuesById[2] = "ANNOUNCEMENT_TYPE_BANNER"] = 2;
                return values;
            })();
    
            announcements.Announcement = (function() {
    
                /**
                 * Properties of an Announcement.
                 * @memberof infinitusai.announcements
                 * @interface IAnnouncement
                 * @property {string|null} [uuid] Announcement uuid
                 * @property {string|null} [title] Announcement title
                 * @property {infinitusai.auth.AppName|null} [appName] Announcement appName
                 * @property {infinitusai.announcements.AnnouncementType|null} [type] Announcement type
                 * @property {number|Long|null} [createTimeMillis] Announcement createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] Announcement updateTimeMillis
                 */
    
                /**
                 * Constructs a new Announcement.
                 * @memberof infinitusai.announcements
                 * @classdesc Represents an Announcement.
                 * @implements IAnnouncement
                 * @constructor
                 * @param {infinitusai.announcements.IAnnouncement=} [properties] Properties to set
                 */
                function Announcement(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Announcement uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.announcements.Announcement
                 * @instance
                 */
                Announcement.prototype.uuid = "";
    
                /**
                 * Announcement title.
                 * @member {string} title
                 * @memberof infinitusai.announcements.Announcement
                 * @instance
                 */
                Announcement.prototype.title = "";
    
                /**
                 * Announcement appName.
                 * @member {infinitusai.auth.AppName} appName
                 * @memberof infinitusai.announcements.Announcement
                 * @instance
                 */
                Announcement.prototype.appName = 0;
    
                /**
                 * Announcement type.
                 * @member {infinitusai.announcements.AnnouncementType} type
                 * @memberof infinitusai.announcements.Announcement
                 * @instance
                 */
                Announcement.prototype.type = 0;
    
                /**
                 * Announcement createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.announcements.Announcement
                 * @instance
                 */
                Announcement.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Announcement updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.announcements.Announcement
                 * @instance
                 */
                Announcement.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new Announcement instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {infinitusai.announcements.IAnnouncement=} [properties] Properties to set
                 * @returns {infinitusai.announcements.Announcement} Announcement instance
                 */
                Announcement.create = function create(properties) {
                    return new Announcement(properties);
                };
    
                /**
                 * Encodes the specified Announcement message. Does not implicitly {@link infinitusai.announcements.Announcement.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {infinitusai.announcements.IAnnouncement} message Announcement message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Announcement.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                    if (message.appName != null && Object.hasOwnProperty.call(message, "appName"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.appName);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.updateTimeMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified Announcement message, length delimited. Does not implicitly {@link infinitusai.announcements.Announcement.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {infinitusai.announcements.IAnnouncement} message Announcement message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Announcement.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Announcement message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.announcements.Announcement} Announcement
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Announcement.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.announcements.Announcement();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.title = reader.string();
                                break;
                            }
                        case 3: {
                                message.appName = reader.int32();
                                break;
                            }
                        case 4: {
                                message.type = reader.int32();
                                break;
                            }
                        case 5: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 6: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Announcement message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.announcements.Announcement} Announcement
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Announcement.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Announcement message.
                 * @function verify
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Announcement.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.appName != null && message.hasOwnProperty("appName"))
                        switch (message.appName) {
                        default:
                            return "appName: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an Announcement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.announcements.Announcement} Announcement
                 */
                Announcement.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.announcements.Announcement)
                        return object;
                    var message = new $root.infinitusai.announcements.Announcement();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.title != null)
                        message.title = String(object.title);
                    switch (object.appName) {
                    default:
                        if (typeof object.appName === "number") {
                            message.appName = object.appName;
                            break;
                        }
                        break;
                    case "APP_NAME_UNKNOWN":
                    case 0:
                        message.appName = 0;
                        break;
                    case "APP_NAME_ADMIN":
                    case 1:
                        message.appName = 1;
                        break;
                    case "APP_NAME_OPERATOR":
                    case 2:
                        message.appName = 2;
                        break;
                    case "APP_NAME_CUSTOMER":
                    case 3:
                        message.appName = 3;
                        break;
                    }
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "ANNOUNCEMENT_TYPE_UNKONWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "ANNOUNCEMENT_TYPE_MODAL":
                    case 1:
                        message.type = 1;
                        break;
                    case "ANNOUNCEMENT_TYPE_BANNER":
                    case 2:
                        message.type = 2;
                        break;
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an Announcement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {infinitusai.announcements.Announcement} message Announcement
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Announcement.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uuid = "";
                        object.title = "";
                        object.appName = options.enums === String ? "APP_NAME_UNKNOWN" : 0;
                        object.type = options.enums === String ? "ANNOUNCEMENT_TYPE_UNKONWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.appName != null && message.hasOwnProperty("appName"))
                        object.appName = options.enums === String ? $root.infinitusai.auth.AppName[message.appName] === undefined ? message.appName : $root.infinitusai.auth.AppName[message.appName] : message.appName;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.announcements.AnnouncementType[message.type] === undefined ? message.type : $root.infinitusai.announcements.AnnouncementType[message.type] : message.type;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    return object;
                };
    
                /**
                 * Converts this Announcement to JSON.
                 * @function toJSON
                 * @memberof infinitusai.announcements.Announcement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Announcement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Announcement
                 * @function getTypeUrl
                 * @memberof infinitusai.announcements.Announcement
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Announcement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.announcements.Announcement";
                };
    
                return Announcement;
            })();
    
            return announcements;
        })();
    
        infinitusai.auth = (function() {
    
            /**
             * Namespace auth.
             * @memberof infinitusai
             * @namespace
             */
            var auth = {};
    
            /**
             * AppName enum.
             * @name infinitusai.auth.AppName
             * @enum {number}
             * @property {number} APP_NAME_UNKNOWN=0 APP_NAME_UNKNOWN value
             * @property {number} APP_NAME_ADMIN=1 APP_NAME_ADMIN value
             * @property {number} APP_NAME_OPERATOR=2 APP_NAME_OPERATOR value
             * @property {number} APP_NAME_CUSTOMER=3 APP_NAME_CUSTOMER value
             */
            auth.AppName = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "APP_NAME_UNKNOWN"] = 0;
                values[valuesById[1] = "APP_NAME_ADMIN"] = 1;
                values[valuesById[2] = "APP_NAME_OPERATOR"] = 2;
                values[valuesById[3] = "APP_NAME_CUSTOMER"] = 3;
                return values;
            })();
    
            /**
             * PermissionType enum.
             * @name infinitusai.auth.PermissionType
             * @enum {number}
             * @property {number} PERMISSION_TYPE_UNKNOWN=0 PERMISSION_TYPE_UNKNOWN value
             * @property {number} PERMISSION_TYPE_ADMIN=1 PERMISSION_TYPE_ADMIN value
             * @property {number} PERMISSION_TYPE_OPERATOR=2 PERMISSION_TYPE_OPERATOR value
             * @property {number} PERMISSION_TYPE_CUSTOMER=3 PERMISSION_TYPE_CUSTOMER value
             */
            auth.PermissionType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PERMISSION_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "PERMISSION_TYPE_ADMIN"] = 1;
                values[valuesById[2] = "PERMISSION_TYPE_OPERATOR"] = 2;
                values[valuesById[3] = "PERMISSION_TYPE_CUSTOMER"] = 3;
                return values;
            })();
    
            /**
             * RoleType enum.
             * @name infinitusai.auth.RoleType
             * @enum {number}
             * @property {number} ROLE_TYPE_UNKNOWN=0 ROLE_TYPE_UNKNOWN value
             * @property {number} ROLE_TYPE_ADMIN=1 ROLE_TYPE_ADMIN value
             * @property {number} ROLE_TYPE_OPERATOR=2 ROLE_TYPE_OPERATOR value
             * @property {number} ROLE_TYPE_CUSTOMER=3 ROLE_TYPE_CUSTOMER value
             */
            auth.RoleType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ROLE_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "ROLE_TYPE_ADMIN"] = 1;
                values[valuesById[2] = "ROLE_TYPE_OPERATOR"] = 2;
                values[valuesById[3] = "ROLE_TYPE_CUSTOMER"] = 3;
                return values;
            })();
    
            /**
             * InviteType enum.
             * @name infinitusai.auth.InviteType
             * @enum {number}
             * @property {number} INVITE_TYPE_UNKNOWN=0 INVITE_TYPE_UNKNOWN value
             * @property {number} INVITE_TYPE_ADMIN=1 INVITE_TYPE_ADMIN value
             * @property {number} INVITE_TYPE_OPERATOR=2 INVITE_TYPE_OPERATOR value
             * @property {number} INVITE_TYPE_CUSTOMER=3 INVITE_TYPE_CUSTOMER value
             */
            auth.InviteType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "INVITE_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "INVITE_TYPE_ADMIN"] = 1;
                values[valuesById[2] = "INVITE_TYPE_OPERATOR"] = 2;
                values[valuesById[3] = "INVITE_TYPE_CUSTOMER"] = 3;
                return values;
            })();
    
            /**
             * UserType enum.
             * @name infinitusai.auth.UserType
             * @enum {number}
             * @property {number} USER_TYPE_UNKNOWN=0 USER_TYPE_UNKNOWN value
             * @property {number} USER_TYPE_ADMIN=1 USER_TYPE_ADMIN value
             * @property {number} USER_TYPE_OPERATOR=2 USER_TYPE_OPERATOR value
             * @property {number} USER_TYPE_CUSTOMER=3 USER_TYPE_CUSTOMER value
             */
            auth.UserType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "USER_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "USER_TYPE_ADMIN"] = 1;
                values[valuesById[2] = "USER_TYPE_OPERATOR"] = 2;
                values[valuesById[3] = "USER_TYPE_CUSTOMER"] = 3;
                return values;
            })();
    
            /**
             * InviteState enum.
             * @name infinitusai.auth.InviteState
             * @enum {number}
             * @property {number} INVITE_STATE_UNKNOWN=0 INVITE_STATE_UNKNOWN value
             * @property {number} INVITE_STATE_PENDING=1 INVITE_STATE_PENDING value
             * @property {number} INVITE_STATE_ACCEPTED=2 INVITE_STATE_ACCEPTED value
             * @property {number} INVITE_STATE_DECLINED=3 INVITE_STATE_DECLINED value
             * @property {number} INVITE_STATE_CANCELED=4 INVITE_STATE_CANCELED value
             */
            auth.InviteState = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "INVITE_STATE_UNKNOWN"] = 0;
                values[valuesById[1] = "INVITE_STATE_PENDING"] = 1;
                values[valuesById[2] = "INVITE_STATE_ACCEPTED"] = 2;
                values[valuesById[3] = "INVITE_STATE_DECLINED"] = 3;
                values[valuesById[4] = "INVITE_STATE_CANCELED"] = 4;
                return values;
            })();
    
            auth.CustomClaims = (function() {
    
                /**
                 * Properties of a CustomClaims.
                 * @memberof infinitusai.auth
                 * @interface ICustomClaims
                 * @property {boolean|null} [superAdmin] CustomClaims superAdmin
                 * @property {infinitusai.auth.UserType|null} [userType] CustomClaims userType
                 */
    
                /**
                 * Constructs a new CustomClaims.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CustomClaims.
                 * @implements ICustomClaims
                 * @constructor
                 * @param {infinitusai.auth.ICustomClaims=} [properties] Properties to set
                 */
                function CustomClaims(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CustomClaims superAdmin.
                 * @member {boolean} superAdmin
                 * @memberof infinitusai.auth.CustomClaims
                 * @instance
                 */
                CustomClaims.prototype.superAdmin = false;
    
                /**
                 * CustomClaims userType.
                 * @member {infinitusai.auth.UserType} userType
                 * @memberof infinitusai.auth.CustomClaims
                 * @instance
                 */
                CustomClaims.prototype.userType = 0;
    
                /**
                 * Creates a new CustomClaims instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {infinitusai.auth.ICustomClaims=} [properties] Properties to set
                 * @returns {infinitusai.auth.CustomClaims} CustomClaims instance
                 */
                CustomClaims.create = function create(properties) {
                    return new CustomClaims(properties);
                };
    
                /**
                 * Encodes the specified CustomClaims message. Does not implicitly {@link infinitusai.auth.CustomClaims.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {infinitusai.auth.ICustomClaims} message CustomClaims message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomClaims.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.superAdmin != null && Object.hasOwnProperty.call(message, "superAdmin"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.superAdmin);
                    if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userType);
                    return writer;
                };
    
                /**
                 * Encodes the specified CustomClaims message, length delimited. Does not implicitly {@link infinitusai.auth.CustomClaims.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {infinitusai.auth.ICustomClaims} message CustomClaims message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomClaims.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CustomClaims message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CustomClaims} CustomClaims
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomClaims.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CustomClaims();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.superAdmin = reader.bool();
                                break;
                            }
                        case 2: {
                                message.userType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CustomClaims message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CustomClaims} CustomClaims
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomClaims.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CustomClaims message.
                 * @function verify
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CustomClaims.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.superAdmin != null && message.hasOwnProperty("superAdmin"))
                        if (typeof message.superAdmin !== "boolean")
                            return "superAdmin: boolean expected";
                    if (message.userType != null && message.hasOwnProperty("userType"))
                        switch (message.userType) {
                        default:
                            return "userType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a CustomClaims message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CustomClaims} CustomClaims
                 */
                CustomClaims.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CustomClaims)
                        return object;
                    var message = new $root.infinitusai.auth.CustomClaims();
                    if (object.superAdmin != null)
                        message.superAdmin = Boolean(object.superAdmin);
                    switch (object.userType) {
                    default:
                        if (typeof object.userType === "number") {
                            message.userType = object.userType;
                            break;
                        }
                        break;
                    case "USER_TYPE_UNKNOWN":
                    case 0:
                        message.userType = 0;
                        break;
                    case "USER_TYPE_ADMIN":
                    case 1:
                        message.userType = 1;
                        break;
                    case "USER_TYPE_OPERATOR":
                    case 2:
                        message.userType = 2;
                        break;
                    case "USER_TYPE_CUSTOMER":
                    case 3:
                        message.userType = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CustomClaims message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {infinitusai.auth.CustomClaims} message CustomClaims
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CustomClaims.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.superAdmin = false;
                        object.userType = options.enums === String ? "USER_TYPE_UNKNOWN" : 0;
                    }
                    if (message.superAdmin != null && message.hasOwnProperty("superAdmin"))
                        object.superAdmin = message.superAdmin;
                    if (message.userType != null && message.hasOwnProperty("userType"))
                        object.userType = options.enums === String ? $root.infinitusai.auth.UserType[message.userType] === undefined ? message.userType : $root.infinitusai.auth.UserType[message.userType] : message.userType;
                    return object;
                };
    
                /**
                 * Converts this CustomClaims to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CustomClaims
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CustomClaims.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CustomClaims
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CustomClaims
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CustomClaims.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CustomClaims";
                };
    
                return CustomClaims;
            })();
    
            auth.Permission = (function() {
    
                /**
                 * Properties of a Permission.
                 * @memberof infinitusai.auth
                 * @interface IPermission
                 * @property {string|null} [uuid] Permission uuid
                 * @property {infinitusai.auth.PermissionName|null} [name] Permission name
                 * @property {string|null} [displayName] Permission displayName
                 * @property {string|null} [description] Permission description
                 * @property {string|null} [category] Permission category
                 * @property {boolean|null} [readOnly] Permission readOnly
                 * @property {infinitusai.auth.PermissionType|null} [type] Permission type
                 * @property {number|Long|null} [createTimeMillis] Permission createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] Permission updateTimeMillis
                 * @property {Array.<string>|null} [orgNames] Permission orgNames
                 */
    
                /**
                 * Constructs a new Permission.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a Permission.
                 * @implements IPermission
                 * @constructor
                 * @param {infinitusai.auth.IPermission=} [properties] Properties to set
                 */
                function Permission(properties) {
                    this.orgNames = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Permission uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.uuid = "";
    
                /**
                 * Permission name.
                 * @member {infinitusai.auth.PermissionName} name
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.name = 0;
    
                /**
                 * Permission displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.displayName = "";
    
                /**
                 * Permission description.
                 * @member {string} description
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.description = "";
    
                /**
                 * Permission category.
                 * @member {string} category
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.category = "";
    
                /**
                 * Permission readOnly.
                 * @member {boolean} readOnly
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.readOnly = false;
    
                /**
                 * Permission type.
                 * @member {infinitusai.auth.PermissionType} type
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.type = 0;
    
                /**
                 * Permission createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Permission updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Permission orgNames.
                 * @member {Array.<string>} orgNames
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 */
                Permission.prototype.orgNames = $util.emptyArray;
    
                /**
                 * Creates a new Permission instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {infinitusai.auth.IPermission=} [properties] Properties to set
                 * @returns {infinitusai.auth.Permission} Permission instance
                 */
                Permission.create = function create(properties) {
                    return new Permission(properties);
                };
    
                /**
                 * Encodes the specified Permission message. Does not implicitly {@link infinitusai.auth.Permission.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {infinitusai.auth.IPermission} message Permission message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Permission.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.name);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.category);
                    if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.readOnly);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.type);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.updateTimeMillis);
                    if (message.orgNames != null && message.orgNames.length)
                        for (var i = 0; i < message.orgNames.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.orgNames[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified Permission message, length delimited. Does not implicitly {@link infinitusai.auth.Permission.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {infinitusai.auth.IPermission} message Permission message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Permission.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Permission message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.Permission} Permission
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Permission.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.Permission();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.int32();
                                break;
                            }
                        case 3: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 4: {
                                message.description = reader.string();
                                break;
                            }
                        case 5: {
                                message.category = reader.string();
                                break;
                            }
                        case 6: {
                                message.readOnly = reader.bool();
                                break;
                            }
                        case 7: {
                                message.type = reader.int32();
                                break;
                            }
                        case 8: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 9: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        case 10: {
                                if (!(message.orgNames && message.orgNames.length))
                                    message.orgNames = [];
                                message.orgNames.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Permission message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.Permission} Permission
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Permission.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Permission message.
                 * @function verify
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Permission.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        switch (message.name) {
                        default:
                            return "name: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 32:
                        case 33:
                        case 35:
                        case 36:
                        case 37:
                        case 30:
                        case 31:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 34:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 29:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 42:
                        case 43:
                        case 44:
                            break;
                        }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                        if (typeof message.readOnly !== "boolean")
                            return "readOnly: boolean expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    if (message.orgNames != null && message.hasOwnProperty("orgNames")) {
                        if (!Array.isArray(message.orgNames))
                            return "orgNames: array expected";
                        for (var i = 0; i < message.orgNames.length; ++i)
                            if (!$util.isString(message.orgNames[i]))
                                return "orgNames: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a Permission message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.Permission} Permission
                 */
                Permission.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.Permission)
                        return object;
                    var message = new $root.infinitusai.auth.Permission();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    switch (object.name) {
                    default:
                        if (typeof object.name === "number") {
                            message.name = object.name;
                            break;
                        }
                        break;
                    case "PERMISSION_UNKNOWN":
                    case 0:
                        message.name = 0;
                        break;
                    case "PERMISSION_ADMIN_RBAC_READ":
                    case 1:
                        message.name = 1;
                        break;
                    case "PERMISSION_ADMIN_RBAC_WRITE":
                    case 2:
                        message.name = 2;
                        break;
                    case "PERMISSION_ADMIN_RBAC_ASSIGN":
                    case 3:
                        message.name = 3;
                        break;
                    case "PERMISSION_ADMIN_ENGINEER":
                    case 32:
                        message.name = 32;
                        break;
                    case "PERMISSION_ADMIN_PORTAL_ACCESS":
                    case 33:
                        message.name = 33;
                        break;
                    case "PERMISSION_ADMIN_SKILLS_WRITE":
                    case 35:
                        message.name = 35;
                        break;
                    case "PERMISSION_ADMIN_USER_SKILLS_WRITE":
                    case 36:
                        message.name = 36;
                        break;
                    case "PERMISSION_ADMIN_QUEUE_SETTINGS_WRITE":
                    case 37:
                        message.name = 37;
                        break;
                    case "PERMISSION_OPERATOR_PORTAL_ACCESS":
                    case 30:
                        message.name = 30;
                        break;
                    case "PERMISSION_OPERATOR_CALLS_REVIEW":
                    case 31:
                        message.name = 31;
                        break;
                    case "PERMISSION_CUSTOMER_OWNER":
                    case 4:
                        message.name = 4;
                        break;
                    case "PERMISSION_CUSTOMER_RBAC_READ":
                    case 5:
                        message.name = 5;
                        break;
                    case "PERMISSION_CUSTOMER_RBAC_WRITE":
                    case 6:
                        message.name = 6;
                        break;
                    case "PERMISSION_CUSTOMER_RBAC_ASSIGN":
                    case 7:
                        message.name = 7;
                        break;
                    case "PERMISSION_CUSTOMER_API_KEYS_READ":
                    case 8:
                        message.name = 8;
                        break;
                    case "PERMISSION_CUSTOMER_API_KEYS_WRITE":
                    case 9:
                        message.name = 9;
                        break;
                    case "PERMISSION_CUSTOMER_BILLING_READ":
                    case 10:
                        message.name = 10;
                        break;
                    case "PERMISSION_CUSTOMER_PAYORS_READ":
                    case 11:
                        message.name = 11;
                        break;
                    case "PERMISSION_CUSTOMER_PIE_READ":
                    case 12:
                        message.name = 12;
                        break;
                    case "PERMISSION_CUSTOMER_PIE_WRITE":
                    case 13:
                        message.name = 13;
                        break;
                    case "PERMISSION_CUSTOMER_SETTINGS_READ":
                    case 14:
                        message.name = 14;
                        break;
                    case "PERMISSION_CUSTOMER_SETTINGS_WRITE":
                    case 15:
                        message.name = 15;
                        break;
                    case "PERMISSION_CUSTOMER_TASKS_READ":
                    case 16:
                        message.name = 16;
                        break;
                    case "PERMISSION_CUSTOMER_TASKS_WRITE":
                    case 17:
                        message.name = 17;
                        break;
                    case "PERMISSION_CUSTOMER_TASK_IMPORTS_READ":
                    case 18:
                        message.name = 18;
                        break;
                    case "PERMISSION_CUSTOMER_TASK_IMPORTS_WRITE":
                    case 19:
                        message.name = 19;
                        break;
                    case "PERMISSION_CUSTOMER_TASK_RECORDINGS_READ":
                    case 34:
                        message.name = 34;
                        break;
                    case "PERMISSION_CUSTOMER_TASK_RECORDINGS_LISTEN":
                    case 20:
                        message.name = 20;
                        break;
                    case "PERMISSION_CUSTOMER_TASK_RECORDINGS_DOWNLOAD":
                    case 21:
                        message.name = 21;
                        break;
                    case "PERMISSION_CUSTOMER_TASK_REPORTS_READ":
                    case 22:
                        message.name = 22;
                        break;
                    case "PERMISSION_CUSTOMER_TASK_REPORTS_WRITE":
                    case 23:
                        message.name = 23;
                        break;
                    case "PERMISSION_CUSTOMER_FT_SETUP_APPROVE":
                    case 24:
                        message.name = 24;
                        break;
                    case "PERMISSION_CUSTOMER_FT_TASKS_OPERATE":
                    case 25:
                        message.name = 25;
                        break;
                    case "PERMISSION_CUSTOMER_FT_TASKS_MANAGE":
                    case 26:
                        message.name = 26;
                        break;
                    case "PERMISSION_CUSTOMER_FT_DASHBOARD":
                    case 27:
                        message.name = 27;
                        break;
                    case "PERMISSION_CUSTOMER_DOCS_READ":
                    case 28:
                        message.name = 28;
                        break;
                    case "PERMISSION_CUSTOMER_PORTAL_ACCESS":
                    case 29:
                        message.name = 29;
                        break;
                    case "PERMISSION_CUSTOMER_FT_SOFTPHONE":
                    case 38:
                        message.name = 38;
                        break;
                    case "PERMISSION_CUSTOMER_FT_CALL_QUEUE":
                    case 39:
                        message.name = 39;
                        break;
                    case "PERMISSION_CUSTOMER_FT_CALL_AUTO":
                    case 40:
                        message.name = 40;
                        break;
                    case "PERMISSION_CUSTOMER_FT_CALL_MANUAL":
                    case 41:
                        message.name = 41;
                        break;
                    case "PERMISSION_CUSTOMER_FT_CALL_SALESFORCE":
                    case 42:
                        message.name = 42;
                        break;
                    case "PERMISSION_CUSTOMER_FT_DASHBOARD_TEAM":
                    case 43:
                        message.name = 43;
                        break;
                    case "PERMISSION_CUSTOMER_FT_DASHBOARD_ORG":
                    case 44:
                        message.name = 44;
                        break;
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.readOnly != null)
                        message.readOnly = Boolean(object.readOnly);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "PERMISSION_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "PERMISSION_TYPE_ADMIN":
                    case 1:
                        message.type = 1;
                        break;
                    case "PERMISSION_TYPE_OPERATOR":
                    case 2:
                        message.type = 2;
                        break;
                    case "PERMISSION_TYPE_CUSTOMER":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    if (object.orgNames) {
                        if (!Array.isArray(object.orgNames))
                            throw TypeError(".infinitusai.auth.Permission.orgNames: array expected");
                        message.orgNames = [];
                        for (var i = 0; i < object.orgNames.length; ++i)
                            message.orgNames[i] = String(object.orgNames[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Permission message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {infinitusai.auth.Permission} message Permission
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Permission.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgNames = [];
                    if (options.defaults) {
                        object.uuid = "";
                        object.name = options.enums === String ? "PERMISSION_UNKNOWN" : 0;
                        object.displayName = "";
                        object.description = "";
                        object.category = "";
                        object.readOnly = false;
                        object.type = options.enums === String ? "PERMISSION_TYPE_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = options.enums === String ? $root.infinitusai.auth.PermissionName[message.name] === undefined ? message.name : $root.infinitusai.auth.PermissionName[message.name] : message.name;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                        object.readOnly = message.readOnly;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.auth.PermissionType[message.type] === undefined ? message.type : $root.infinitusai.auth.PermissionType[message.type] : message.type;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    if (message.orgNames && message.orgNames.length) {
                        object.orgNames = [];
                        for (var j = 0; j < message.orgNames.length; ++j)
                            object.orgNames[j] = message.orgNames[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this Permission to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.Permission
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Permission.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Permission
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.Permission
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Permission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.Permission";
                };
    
                return Permission;
            })();
    
            auth.Role = (function() {
    
                /**
                 * Properties of a Role.
                 * @memberof infinitusai.auth
                 * @interface IRole
                 * @property {string|null} [uuid] Role uuid
                 * @property {string|null} [name] Role name
                 * @property {string|null} [description] Role description
                 * @property {boolean|null} [readOnly] Role readOnly
                 * @property {infinitusai.auth.RoleType|null} [type] Role type
                 * @property {number|Long|null} [createTimeMillis] Role createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] Role updateTimeMillis
                 * @property {number|Long|null} [userCount] Role userCount
                 * @property {Array.<string>|null} [orgNames] Role orgNames
                 * @property {Array.<string>|null} [orgUuids] Role orgUuids
                 */
    
                /**
                 * Constructs a new Role.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a Role.
                 * @implements IRole
                 * @constructor
                 * @param {infinitusai.auth.IRole=} [properties] Properties to set
                 */
                function Role(properties) {
                    this.orgNames = [];
                    this.orgUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Role uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.uuid = "";
    
                /**
                 * Role name.
                 * @member {string} name
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.name = "";
    
                /**
                 * Role description.
                 * @member {string} description
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.description = "";
    
                /**
                 * Role readOnly.
                 * @member {boolean} readOnly
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.readOnly = false;
    
                /**
                 * Role type.
                 * @member {infinitusai.auth.RoleType} type
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.type = 0;
    
                /**
                 * Role createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Role updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Role userCount.
                 * @member {number|Long} userCount
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.userCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Role orgNames.
                 * @member {Array.<string>} orgNames
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.orgNames = $util.emptyArray;
    
                /**
                 * Role orgUuids.
                 * @member {Array.<string>} orgUuids
                 * @memberof infinitusai.auth.Role
                 * @instance
                 */
                Role.prototype.orgUuids = $util.emptyArray;
    
                /**
                 * Creates a new Role instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {infinitusai.auth.IRole=} [properties] Properties to set
                 * @returns {infinitusai.auth.Role} Role instance
                 */
                Role.create = function create(properties) {
                    return new Role(properties);
                };
    
                /**
                 * Encodes the specified Role message. Does not implicitly {@link infinitusai.auth.Role.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {infinitusai.auth.IRole} message Role message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Role.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.readOnly);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.updateTimeMillis);
                    if (message.userCount != null && Object.hasOwnProperty.call(message, "userCount"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.userCount);
                    if (message.orgNames != null && message.orgNames.length)
                        for (var i = 0; i < message.orgNames.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.orgNames[i]);
                    if (message.orgUuids != null && message.orgUuids.length)
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.orgUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified Role message, length delimited. Does not implicitly {@link infinitusai.auth.Role.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {infinitusai.auth.IRole} message Role message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Role.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Role message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.Role} Role
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Role.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.Role();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.readOnly = reader.bool();
                                break;
                            }
                        case 5: {
                                message.type = reader.int32();
                                break;
                            }
                        case 6: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 7: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        case 8: {
                                message.userCount = reader.int64();
                                break;
                            }
                        case 10: {
                                if (!(message.orgNames && message.orgNames.length))
                                    message.orgNames = [];
                                message.orgNames.push(reader.string());
                                break;
                            }
                        case 11: {
                                if (!(message.orgUuids && message.orgUuids.length))
                                    message.orgUuids = [];
                                message.orgUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Role message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.Role} Role
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Role.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Role message.
                 * @function verify
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Role.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                        if (typeof message.readOnly !== "boolean")
                            return "readOnly: boolean expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    if (message.userCount != null && message.hasOwnProperty("userCount"))
                        if (!$util.isInteger(message.userCount) && !(message.userCount && $util.isInteger(message.userCount.low) && $util.isInteger(message.userCount.high)))
                            return "userCount: integer|Long expected";
                    if (message.orgNames != null && message.hasOwnProperty("orgNames")) {
                        if (!Array.isArray(message.orgNames))
                            return "orgNames: array expected";
                        for (var i = 0; i < message.orgNames.length; ++i)
                            if (!$util.isString(message.orgNames[i]))
                                return "orgNames: string[] expected";
                    }
                    if (message.orgUuids != null && message.hasOwnProperty("orgUuids")) {
                        if (!Array.isArray(message.orgUuids))
                            return "orgUuids: array expected";
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            if (!$util.isString(message.orgUuids[i]))
                                return "orgUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a Role message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.Role} Role
                 */
                Role.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.Role)
                        return object;
                    var message = new $root.infinitusai.auth.Role();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.readOnly != null)
                        message.readOnly = Boolean(object.readOnly);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "ROLE_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "ROLE_TYPE_ADMIN":
                    case 1:
                        message.type = 1;
                        break;
                    case "ROLE_TYPE_OPERATOR":
                    case 2:
                        message.type = 2;
                        break;
                    case "ROLE_TYPE_CUSTOMER":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    if (object.userCount != null)
                        if ($util.Long)
                            (message.userCount = $util.Long.fromValue(object.userCount)).unsigned = false;
                        else if (typeof object.userCount === "string")
                            message.userCount = parseInt(object.userCount, 10);
                        else if (typeof object.userCount === "number")
                            message.userCount = object.userCount;
                        else if (typeof object.userCount === "object")
                            message.userCount = new $util.LongBits(object.userCount.low >>> 0, object.userCount.high >>> 0).toNumber();
                    if (object.orgNames) {
                        if (!Array.isArray(object.orgNames))
                            throw TypeError(".infinitusai.auth.Role.orgNames: array expected");
                        message.orgNames = [];
                        for (var i = 0; i < object.orgNames.length; ++i)
                            message.orgNames[i] = String(object.orgNames[i]);
                    }
                    if (object.orgUuids) {
                        if (!Array.isArray(object.orgUuids))
                            throw TypeError(".infinitusai.auth.Role.orgUuids: array expected");
                        message.orgUuids = [];
                        for (var i = 0; i < object.orgUuids.length; ++i)
                            message.orgUuids[i] = String(object.orgUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Role message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {infinitusai.auth.Role} message Role
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Role.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.orgNames = [];
                        object.orgUuids = [];
                    }
                    if (options.defaults) {
                        object.uuid = "";
                        object.name = "";
                        object.description = "";
                        object.readOnly = false;
                        object.type = options.enums === String ? "ROLE_TYPE_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.userCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.userCount = options.longs === String ? "0" : 0;
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                        object.readOnly = message.readOnly;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.auth.RoleType[message.type] === undefined ? message.type : $root.infinitusai.auth.RoleType[message.type] : message.type;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    if (message.userCount != null && message.hasOwnProperty("userCount"))
                        if (typeof message.userCount === "number")
                            object.userCount = options.longs === String ? String(message.userCount) : message.userCount;
                        else
                            object.userCount = options.longs === String ? $util.Long.prototype.toString.call(message.userCount) : options.longs === Number ? new $util.LongBits(message.userCount.low >>> 0, message.userCount.high >>> 0).toNumber() : message.userCount;
                    if (message.orgNames && message.orgNames.length) {
                        object.orgNames = [];
                        for (var j = 0; j < message.orgNames.length; ++j)
                            object.orgNames[j] = message.orgNames[j];
                    }
                    if (message.orgUuids && message.orgUuids.length) {
                        object.orgUuids = [];
                        for (var j = 0; j < message.orgUuids.length; ++j)
                            object.orgUuids[j] = message.orgUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this Role to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.Role
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Role.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Role
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.Role
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Role.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.Role";
                };
    
                return Role;
            })();
    
            auth.Invite = (function() {
    
                /**
                 * Properties of an Invite.
                 * @memberof infinitusai.auth
                 * @interface IInvite
                 * @property {string|null} [uuid] Invite uuid
                 * @property {string|null} [inviteeEmail] Invite inviteeEmail
                 * @property {string|null} [inviterEmail] Invite inviterEmail
                 * @property {string|null} [orgUuid] Invite orgUuid
                 * @property {infinitusai.auth.InviteType|null} [type] Invite type
                 * @property {infinitusai.auth.InviteState|null} [state] Invite state
                 * @property {Array.<string>|null} [roleUuids] Invite roleUuids
                 * @property {Array.<string>|null} [roleNames] Invite roleNames
                 * @property {number|Long|null} [createTimeMillis] Invite createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] Invite updateTimeMillis
                 * @property {string|null} [orgName] Invite orgName
                 */
    
                /**
                 * Constructs a new Invite.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an Invite.
                 * @implements IInvite
                 * @constructor
                 * @param {infinitusai.auth.IInvite=} [properties] Properties to set
                 */
                function Invite(properties) {
                    this.roleUuids = [];
                    this.roleNames = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Invite uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.uuid = "";
    
                /**
                 * Invite inviteeEmail.
                 * @member {string} inviteeEmail
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.inviteeEmail = "";
    
                /**
                 * Invite inviterEmail.
                 * @member {string} inviterEmail
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.inviterEmail = "";
    
                /**
                 * Invite orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.orgUuid = "";
    
                /**
                 * Invite type.
                 * @member {infinitusai.auth.InviteType} type
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.type = 0;
    
                /**
                 * Invite state.
                 * @member {infinitusai.auth.InviteState} state
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.state = 0;
    
                /**
                 * Invite roleUuids.
                 * @member {Array.<string>} roleUuids
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.roleUuids = $util.emptyArray;
    
                /**
                 * Invite roleNames.
                 * @member {Array.<string>} roleNames
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.roleNames = $util.emptyArray;
    
                /**
                 * Invite createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Invite updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Invite orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 */
                Invite.prototype.orgName = "";
    
                /**
                 * Creates a new Invite instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {infinitusai.auth.IInvite=} [properties] Properties to set
                 * @returns {infinitusai.auth.Invite} Invite instance
                 */
                Invite.create = function create(properties) {
                    return new Invite(properties);
                };
    
                /**
                 * Encodes the specified Invite message. Does not implicitly {@link infinitusai.auth.Invite.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {infinitusai.auth.IInvite} message Invite message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Invite.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.inviteeEmail != null && Object.hasOwnProperty.call(message, "inviteeEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteeEmail);
                    if (message.inviterEmail != null && Object.hasOwnProperty.call(message, "inviterEmail"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.inviterEmail);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orgUuid);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.state);
                    if (message.roleUuids != null && message.roleUuids.length)
                        for (var i = 0; i < message.roleUuids.length; ++i)
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.roleUuids[i]);
                    if (message.roleNames != null && message.roleNames.length)
                        for (var i = 0; i < message.roleNames.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.roleNames[i]);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int64(message.updateTimeMillis);
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.orgName);
                    return writer;
                };
    
                /**
                 * Encodes the specified Invite message, length delimited. Does not implicitly {@link infinitusai.auth.Invite.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {infinitusai.auth.IInvite} message Invite message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Invite.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Invite message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.Invite} Invite
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Invite.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.Invite();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.inviteeEmail = reader.string();
                                break;
                            }
                        case 3: {
                                message.inviterEmail = reader.string();
                                break;
                            }
                        case 4: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.type = reader.int32();
                                break;
                            }
                        case 6: {
                                message.state = reader.int32();
                                break;
                            }
                        case 7: {
                                if (!(message.roleUuids && message.roleUuids.length))
                                    message.roleUuids = [];
                                message.roleUuids.push(reader.string());
                                break;
                            }
                        case 8: {
                                if (!(message.roleNames && message.roleNames.length))
                                    message.roleNames = [];
                                message.roleNames.push(reader.string());
                                break;
                            }
                        case 9: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 10: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        case 11: {
                                message.orgName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Invite message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.Invite} Invite
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Invite.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Invite message.
                 * @function verify
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Invite.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.inviteeEmail != null && message.hasOwnProperty("inviteeEmail"))
                        if (!$util.isString(message.inviteeEmail))
                            return "inviteeEmail: string expected";
                    if (message.inviterEmail != null && message.hasOwnProperty("inviterEmail"))
                        if (!$util.isString(message.inviterEmail))
                            return "inviterEmail: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.roleUuids != null && message.hasOwnProperty("roleUuids")) {
                        if (!Array.isArray(message.roleUuids))
                            return "roleUuids: array expected";
                        for (var i = 0; i < message.roleUuids.length; ++i)
                            if (!$util.isString(message.roleUuids[i]))
                                return "roleUuids: string[] expected";
                    }
                    if (message.roleNames != null && message.hasOwnProperty("roleNames")) {
                        if (!Array.isArray(message.roleNames))
                            return "roleNames: array expected";
                        for (var i = 0; i < message.roleNames.length; ++i)
                            if (!$util.isString(message.roleNames[i]))
                                return "roleNames: string[] expected";
                    }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    return null;
                };
    
                /**
                 * Creates an Invite message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.Invite} Invite
                 */
                Invite.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.Invite)
                        return object;
                    var message = new $root.infinitusai.auth.Invite();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.inviteeEmail != null)
                        message.inviteeEmail = String(object.inviteeEmail);
                    if (object.inviterEmail != null)
                        message.inviterEmail = String(object.inviterEmail);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "INVITE_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "INVITE_TYPE_ADMIN":
                    case 1:
                        message.type = 1;
                        break;
                    case "INVITE_TYPE_OPERATOR":
                    case 2:
                        message.type = 2;
                        break;
                    case "INVITE_TYPE_CUSTOMER":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "INVITE_STATE_UNKNOWN":
                    case 0:
                        message.state = 0;
                        break;
                    case "INVITE_STATE_PENDING":
                    case 1:
                        message.state = 1;
                        break;
                    case "INVITE_STATE_ACCEPTED":
                    case 2:
                        message.state = 2;
                        break;
                    case "INVITE_STATE_DECLINED":
                    case 3:
                        message.state = 3;
                        break;
                    case "INVITE_STATE_CANCELED":
                    case 4:
                        message.state = 4;
                        break;
                    }
                    if (object.roleUuids) {
                        if (!Array.isArray(object.roleUuids))
                            throw TypeError(".infinitusai.auth.Invite.roleUuids: array expected");
                        message.roleUuids = [];
                        for (var i = 0; i < object.roleUuids.length; ++i)
                            message.roleUuids[i] = String(object.roleUuids[i]);
                    }
                    if (object.roleNames) {
                        if (!Array.isArray(object.roleNames))
                            throw TypeError(".infinitusai.auth.Invite.roleNames: array expected");
                        message.roleNames = [];
                        for (var i = 0; i < object.roleNames.length; ++i)
                            message.roleNames[i] = String(object.roleNames[i]);
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    return message;
                };
    
                /**
                 * Creates a plain object from an Invite message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {infinitusai.auth.Invite} message Invite
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Invite.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.roleUuids = [];
                        object.roleNames = [];
                    }
                    if (options.defaults) {
                        object.uuid = "";
                        object.inviteeEmail = "";
                        object.inviterEmail = "";
                        object.orgUuid = "";
                        object.type = options.enums === String ? "INVITE_TYPE_UNKNOWN" : 0;
                        object.state = options.enums === String ? "INVITE_STATE_UNKNOWN" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                        object.orgName = "";
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.inviteeEmail != null && message.hasOwnProperty("inviteeEmail"))
                        object.inviteeEmail = message.inviteeEmail;
                    if (message.inviterEmail != null && message.hasOwnProperty("inviterEmail"))
                        object.inviterEmail = message.inviterEmail;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.auth.InviteType[message.type] === undefined ? message.type : $root.infinitusai.auth.InviteType[message.type] : message.type;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.infinitusai.auth.InviteState[message.state] === undefined ? message.state : $root.infinitusai.auth.InviteState[message.state] : message.state;
                    if (message.roleUuids && message.roleUuids.length) {
                        object.roleUuids = [];
                        for (var j = 0; j < message.roleUuids.length; ++j)
                            object.roleUuids[j] = message.roleUuids[j];
                    }
                    if (message.roleNames && message.roleNames.length) {
                        object.roleNames = [];
                        for (var j = 0; j < message.roleNames.length; ++j)
                            object.roleNames[j] = message.roleNames[j];
                    }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    return object;
                };
    
                /**
                 * Converts this Invite to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.Invite
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Invite.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Invite
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.Invite
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Invite.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.Invite";
                };
    
                return Invite;
            })();
    
            auth.User = (function() {
    
                /**
                 * Properties of a User.
                 * @memberof infinitusai.auth
                 * @interface IUser
                 * @property {string|null} [uid] User uid
                 * @property {string|null} [email] User email
                 * @property {boolean|null} [emailVerified] User emailVerified
                 * @property {string|null} [displayName] User displayName
                 * @property {string|null} [photoUrl] User photoUrl
                 * @property {boolean|null} [superAdmin] User superAdmin
                 * @property {infinitusai.auth.UserType|null} [type] User type
                 * @property {boolean|null} [disabled] User disabled
                 * @property {number|Long|null} [lastActiveTimeMillis] User lastActiveTimeMillis
                 * @property {number|Long|null} [createTimeMillis] User createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] User updateTimeMillis
                 * @property {Array.<string>|null} [roleNames] User roleNames
                 * @property {Array.<string>|null} [orgNames] User orgNames
                 */
    
                /**
                 * Constructs a new User.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a User.
                 * @implements IUser
                 * @constructor
                 * @param {infinitusai.auth.IUser=} [properties] Properties to set
                 */
                function User(properties) {
                    this.roleNames = [];
                    this.orgNames = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * User uid.
                 * @member {string} uid
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.uid = "";
    
                /**
                 * User email.
                 * @member {string} email
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.email = "";
    
                /**
                 * User emailVerified.
                 * @member {boolean} emailVerified
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.emailVerified = false;
    
                /**
                 * User displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.displayName = "";
    
                /**
                 * User photoUrl.
                 * @member {string} photoUrl
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.photoUrl = "";
    
                /**
                 * User superAdmin.
                 * @member {boolean} superAdmin
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.superAdmin = false;
    
                /**
                 * User type.
                 * @member {infinitusai.auth.UserType} type
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.type = 0;
    
                /**
                 * User disabled.
                 * @member {boolean} disabled
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.disabled = false;
    
                /**
                 * User lastActiveTimeMillis.
                 * @member {number|Long} lastActiveTimeMillis
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.lastActiveTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * User createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * User updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * User roleNames.
                 * @member {Array.<string>} roleNames
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.roleNames = $util.emptyArray;
    
                /**
                 * User orgNames.
                 * @member {Array.<string>} orgNames
                 * @memberof infinitusai.auth.User
                 * @instance
                 */
                User.prototype.orgNames = $util.emptyArray;
    
                /**
                 * Creates a new User instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {infinitusai.auth.IUser=} [properties] Properties to set
                 * @returns {infinitusai.auth.User} User instance
                 */
                User.create = function create(properties) {
                    return new User(properties);
                };
    
                /**
                 * Encodes the specified User message. Does not implicitly {@link infinitusai.auth.User.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {infinitusai.auth.IUser} message User message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                User.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                    if (message.emailVerified != null && Object.hasOwnProperty.call(message, "emailVerified"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.emailVerified);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.displayName);
                    if (message.photoUrl != null && Object.hasOwnProperty.call(message, "photoUrl"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.photoUrl);
                    if (message.superAdmin != null && Object.hasOwnProperty.call(message, "superAdmin"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.superAdmin);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.type);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.disabled);
                    if (message.lastActiveTimeMillis != null && Object.hasOwnProperty.call(message, "lastActiveTimeMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.lastActiveTimeMillis);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int64(message.updateTimeMillis);
                    if (message.roleNames != null && message.roleNames.length)
                        for (var i = 0; i < message.roleNames.length; ++i)
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.roleNames[i]);
                    if (message.orgNames != null && message.orgNames.length)
                        for (var i = 0; i < message.orgNames.length; ++i)
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.orgNames[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified User message, length delimited. Does not implicitly {@link infinitusai.auth.User.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {infinitusai.auth.IUser} message User message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                User.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a User message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.User} User
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                User.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.User();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uid = reader.string();
                                break;
                            }
                        case 2: {
                                message.email = reader.string();
                                break;
                            }
                        case 3: {
                                message.emailVerified = reader.bool();
                                break;
                            }
                        case 4: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 5: {
                                message.photoUrl = reader.string();
                                break;
                            }
                        case 6: {
                                message.superAdmin = reader.bool();
                                break;
                            }
                        case 7: {
                                message.type = reader.int32();
                                break;
                            }
                        case 8: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 9: {
                                message.lastActiveTimeMillis = reader.int64();
                                break;
                            }
                        case 10: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 11: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        case 12: {
                                if (!(message.roleNames && message.roleNames.length))
                                    message.roleNames = [];
                                message.roleNames.push(reader.string());
                                break;
                            }
                        case 13: {
                                if (!(message.orgNames && message.orgNames.length))
                                    message.orgNames = [];
                                message.orgNames.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a User message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.User} User
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                User.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a User message.
                 * @function verify
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                User.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uid != null && message.hasOwnProperty("uid"))
                        if (!$util.isString(message.uid))
                            return "uid: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.emailVerified != null && message.hasOwnProperty("emailVerified"))
                        if (typeof message.emailVerified !== "boolean")
                            return "emailVerified: boolean expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.photoUrl != null && message.hasOwnProperty("photoUrl"))
                        if (!$util.isString(message.photoUrl))
                            return "photoUrl: string expected";
                    if (message.superAdmin != null && message.hasOwnProperty("superAdmin"))
                        if (typeof message.superAdmin !== "boolean")
                            return "superAdmin: boolean expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.lastActiveTimeMillis != null && message.hasOwnProperty("lastActiveTimeMillis"))
                        if (!$util.isInteger(message.lastActiveTimeMillis) && !(message.lastActiveTimeMillis && $util.isInteger(message.lastActiveTimeMillis.low) && $util.isInteger(message.lastActiveTimeMillis.high)))
                            return "lastActiveTimeMillis: integer|Long expected";
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    if (message.roleNames != null && message.hasOwnProperty("roleNames")) {
                        if (!Array.isArray(message.roleNames))
                            return "roleNames: array expected";
                        for (var i = 0; i < message.roleNames.length; ++i)
                            if (!$util.isString(message.roleNames[i]))
                                return "roleNames: string[] expected";
                    }
                    if (message.orgNames != null && message.hasOwnProperty("orgNames")) {
                        if (!Array.isArray(message.orgNames))
                            return "orgNames: array expected";
                        for (var i = 0; i < message.orgNames.length; ++i)
                            if (!$util.isString(message.orgNames[i]))
                                return "orgNames: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a User message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.User} User
                 */
                User.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.User)
                        return object;
                    var message = new $root.infinitusai.auth.User();
                    if (object.uid != null)
                        message.uid = String(object.uid);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.emailVerified != null)
                        message.emailVerified = Boolean(object.emailVerified);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.photoUrl != null)
                        message.photoUrl = String(object.photoUrl);
                    if (object.superAdmin != null)
                        message.superAdmin = Boolean(object.superAdmin);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "USER_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "USER_TYPE_ADMIN":
                    case 1:
                        message.type = 1;
                        break;
                    case "USER_TYPE_OPERATOR":
                    case 2:
                        message.type = 2;
                        break;
                    case "USER_TYPE_CUSTOMER":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.lastActiveTimeMillis != null)
                        if ($util.Long)
                            (message.lastActiveTimeMillis = $util.Long.fromValue(object.lastActiveTimeMillis)).unsigned = false;
                        else if (typeof object.lastActiveTimeMillis === "string")
                            message.lastActiveTimeMillis = parseInt(object.lastActiveTimeMillis, 10);
                        else if (typeof object.lastActiveTimeMillis === "number")
                            message.lastActiveTimeMillis = object.lastActiveTimeMillis;
                        else if (typeof object.lastActiveTimeMillis === "object")
                            message.lastActiveTimeMillis = new $util.LongBits(object.lastActiveTimeMillis.low >>> 0, object.lastActiveTimeMillis.high >>> 0).toNumber();
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    if (object.roleNames) {
                        if (!Array.isArray(object.roleNames))
                            throw TypeError(".infinitusai.auth.User.roleNames: array expected");
                        message.roleNames = [];
                        for (var i = 0; i < object.roleNames.length; ++i)
                            message.roleNames[i] = String(object.roleNames[i]);
                    }
                    if (object.orgNames) {
                        if (!Array.isArray(object.orgNames))
                            throw TypeError(".infinitusai.auth.User.orgNames: array expected");
                        message.orgNames = [];
                        for (var i = 0; i < object.orgNames.length; ++i)
                            message.orgNames[i] = String(object.orgNames[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a User message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {infinitusai.auth.User} message User
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                User.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.roleNames = [];
                        object.orgNames = [];
                    }
                    if (options.defaults) {
                        object.uid = "";
                        object.email = "";
                        object.emailVerified = false;
                        object.displayName = "";
                        object.photoUrl = "";
                        object.superAdmin = false;
                        object.type = options.enums === String ? "USER_TYPE_UNKNOWN" : 0;
                        object.disabled = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.lastActiveTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastActiveTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.uid != null && message.hasOwnProperty("uid"))
                        object.uid = message.uid;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.emailVerified != null && message.hasOwnProperty("emailVerified"))
                        object.emailVerified = message.emailVerified;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.photoUrl != null && message.hasOwnProperty("photoUrl"))
                        object.photoUrl = message.photoUrl;
                    if (message.superAdmin != null && message.hasOwnProperty("superAdmin"))
                        object.superAdmin = message.superAdmin;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.auth.UserType[message.type] === undefined ? message.type : $root.infinitusai.auth.UserType[message.type] : message.type;
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.lastActiveTimeMillis != null && message.hasOwnProperty("lastActiveTimeMillis"))
                        if (typeof message.lastActiveTimeMillis === "number")
                            object.lastActiveTimeMillis = options.longs === String ? String(message.lastActiveTimeMillis) : message.lastActiveTimeMillis;
                        else
                            object.lastActiveTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastActiveTimeMillis) : options.longs === Number ? new $util.LongBits(message.lastActiveTimeMillis.low >>> 0, message.lastActiveTimeMillis.high >>> 0).toNumber() : message.lastActiveTimeMillis;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    if (message.roleNames && message.roleNames.length) {
                        object.roleNames = [];
                        for (var j = 0; j < message.roleNames.length; ++j)
                            object.roleNames[j] = message.roleNames[j];
                    }
                    if (message.orgNames && message.orgNames.length) {
                        object.orgNames = [];
                        for (var j = 0; j < message.orgNames.length; ++j)
                            object.orgNames[j] = message.orgNames[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this User to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.User
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                User.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for User
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.User
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.User";
                };
    
                return User;
            })();
    
            /**
             * PermissionName enum.
             * @name infinitusai.auth.PermissionName
             * @enum {number}
             * @property {number} PERMISSION_UNKNOWN=0 PERMISSION_UNKNOWN value
             * @property {number} PERMISSION_ADMIN_RBAC_READ=1 PERMISSION_ADMIN_RBAC_READ value
             * @property {number} PERMISSION_ADMIN_RBAC_WRITE=2 PERMISSION_ADMIN_RBAC_WRITE value
             * @property {number} PERMISSION_ADMIN_RBAC_ASSIGN=3 PERMISSION_ADMIN_RBAC_ASSIGN value
             * @property {number} PERMISSION_ADMIN_ENGINEER=32 PERMISSION_ADMIN_ENGINEER value
             * @property {number} PERMISSION_ADMIN_PORTAL_ACCESS=33 PERMISSION_ADMIN_PORTAL_ACCESS value
             * @property {number} PERMISSION_ADMIN_SKILLS_WRITE=35 PERMISSION_ADMIN_SKILLS_WRITE value
             * @property {number} PERMISSION_ADMIN_USER_SKILLS_WRITE=36 PERMISSION_ADMIN_USER_SKILLS_WRITE value
             * @property {number} PERMISSION_ADMIN_QUEUE_SETTINGS_WRITE=37 PERMISSION_ADMIN_QUEUE_SETTINGS_WRITE value
             * @property {number} PERMISSION_OPERATOR_PORTAL_ACCESS=30 PERMISSION_OPERATOR_PORTAL_ACCESS value
             * @property {number} PERMISSION_OPERATOR_CALLS_REVIEW=31 PERMISSION_OPERATOR_CALLS_REVIEW value
             * @property {number} PERMISSION_CUSTOMER_OWNER=4 PERMISSION_CUSTOMER_OWNER value
             * @property {number} PERMISSION_CUSTOMER_RBAC_READ=5 PERMISSION_CUSTOMER_RBAC_READ value
             * @property {number} PERMISSION_CUSTOMER_RBAC_WRITE=6 PERMISSION_CUSTOMER_RBAC_WRITE value
             * @property {number} PERMISSION_CUSTOMER_RBAC_ASSIGN=7 PERMISSION_CUSTOMER_RBAC_ASSIGN value
             * @property {number} PERMISSION_CUSTOMER_API_KEYS_READ=8 PERMISSION_CUSTOMER_API_KEYS_READ value
             * @property {number} PERMISSION_CUSTOMER_API_KEYS_WRITE=9 PERMISSION_CUSTOMER_API_KEYS_WRITE value
             * @property {number} PERMISSION_CUSTOMER_BILLING_READ=10 PERMISSION_CUSTOMER_BILLING_READ value
             * @property {number} PERMISSION_CUSTOMER_PAYORS_READ=11 PERMISSION_CUSTOMER_PAYORS_READ value
             * @property {number} PERMISSION_CUSTOMER_PIE_READ=12 PERMISSION_CUSTOMER_PIE_READ value
             * @property {number} PERMISSION_CUSTOMER_PIE_WRITE=13 PERMISSION_CUSTOMER_PIE_WRITE value
             * @property {number} PERMISSION_CUSTOMER_SETTINGS_READ=14 PERMISSION_CUSTOMER_SETTINGS_READ value
             * @property {number} PERMISSION_CUSTOMER_SETTINGS_WRITE=15 PERMISSION_CUSTOMER_SETTINGS_WRITE value
             * @property {number} PERMISSION_CUSTOMER_TASKS_READ=16 PERMISSION_CUSTOMER_TASKS_READ value
             * @property {number} PERMISSION_CUSTOMER_TASKS_WRITE=17 PERMISSION_CUSTOMER_TASKS_WRITE value
             * @property {number} PERMISSION_CUSTOMER_TASK_IMPORTS_READ=18 PERMISSION_CUSTOMER_TASK_IMPORTS_READ value
             * @property {number} PERMISSION_CUSTOMER_TASK_IMPORTS_WRITE=19 PERMISSION_CUSTOMER_TASK_IMPORTS_WRITE value
             * @property {number} PERMISSION_CUSTOMER_TASK_RECORDINGS_READ=34 PERMISSION_CUSTOMER_TASK_RECORDINGS_READ value
             * @property {number} PERMISSION_CUSTOMER_TASK_RECORDINGS_LISTEN=20 PERMISSION_CUSTOMER_TASK_RECORDINGS_LISTEN value
             * @property {number} PERMISSION_CUSTOMER_TASK_RECORDINGS_DOWNLOAD=21 PERMISSION_CUSTOMER_TASK_RECORDINGS_DOWNLOAD value
             * @property {number} PERMISSION_CUSTOMER_TASK_REPORTS_READ=22 PERMISSION_CUSTOMER_TASK_REPORTS_READ value
             * @property {number} PERMISSION_CUSTOMER_TASK_REPORTS_WRITE=23 PERMISSION_CUSTOMER_TASK_REPORTS_WRITE value
             * @property {number} PERMISSION_CUSTOMER_FT_SETUP_APPROVE=24 PERMISSION_CUSTOMER_FT_SETUP_APPROVE value
             * @property {number} PERMISSION_CUSTOMER_FT_TASKS_OPERATE=25 PERMISSION_CUSTOMER_FT_TASKS_OPERATE value
             * @property {number} PERMISSION_CUSTOMER_FT_TASKS_MANAGE=26 PERMISSION_CUSTOMER_FT_TASKS_MANAGE value
             * @property {number} PERMISSION_CUSTOMER_FT_DASHBOARD=27 PERMISSION_CUSTOMER_FT_DASHBOARD value
             * @property {number} PERMISSION_CUSTOMER_DOCS_READ=28 PERMISSION_CUSTOMER_DOCS_READ value
             * @property {number} PERMISSION_CUSTOMER_PORTAL_ACCESS=29 PERMISSION_CUSTOMER_PORTAL_ACCESS value
             * @property {number} PERMISSION_CUSTOMER_FT_SOFTPHONE=38 PERMISSION_CUSTOMER_FT_SOFTPHONE value
             * @property {number} PERMISSION_CUSTOMER_FT_CALL_QUEUE=39 PERMISSION_CUSTOMER_FT_CALL_QUEUE value
             * @property {number} PERMISSION_CUSTOMER_FT_CALL_AUTO=40 PERMISSION_CUSTOMER_FT_CALL_AUTO value
             * @property {number} PERMISSION_CUSTOMER_FT_CALL_MANUAL=41 PERMISSION_CUSTOMER_FT_CALL_MANUAL value
             * @property {number} PERMISSION_CUSTOMER_FT_CALL_SALESFORCE=42 PERMISSION_CUSTOMER_FT_CALL_SALESFORCE value
             * @property {number} PERMISSION_CUSTOMER_FT_DASHBOARD_TEAM=43 PERMISSION_CUSTOMER_FT_DASHBOARD_TEAM value
             * @property {number} PERMISSION_CUSTOMER_FT_DASHBOARD_ORG=44 PERMISSION_CUSTOMER_FT_DASHBOARD_ORG value
             */
            auth.PermissionName = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PERMISSION_UNKNOWN"] = 0;
                values[valuesById[1] = "PERMISSION_ADMIN_RBAC_READ"] = 1;
                values[valuesById[2] = "PERMISSION_ADMIN_RBAC_WRITE"] = 2;
                values[valuesById[3] = "PERMISSION_ADMIN_RBAC_ASSIGN"] = 3;
                values[valuesById[32] = "PERMISSION_ADMIN_ENGINEER"] = 32;
                values[valuesById[33] = "PERMISSION_ADMIN_PORTAL_ACCESS"] = 33;
                values[valuesById[35] = "PERMISSION_ADMIN_SKILLS_WRITE"] = 35;
                values[valuesById[36] = "PERMISSION_ADMIN_USER_SKILLS_WRITE"] = 36;
                values[valuesById[37] = "PERMISSION_ADMIN_QUEUE_SETTINGS_WRITE"] = 37;
                values[valuesById[30] = "PERMISSION_OPERATOR_PORTAL_ACCESS"] = 30;
                values[valuesById[31] = "PERMISSION_OPERATOR_CALLS_REVIEW"] = 31;
                values[valuesById[4] = "PERMISSION_CUSTOMER_OWNER"] = 4;
                values[valuesById[5] = "PERMISSION_CUSTOMER_RBAC_READ"] = 5;
                values[valuesById[6] = "PERMISSION_CUSTOMER_RBAC_WRITE"] = 6;
                values[valuesById[7] = "PERMISSION_CUSTOMER_RBAC_ASSIGN"] = 7;
                values[valuesById[8] = "PERMISSION_CUSTOMER_API_KEYS_READ"] = 8;
                values[valuesById[9] = "PERMISSION_CUSTOMER_API_KEYS_WRITE"] = 9;
                values[valuesById[10] = "PERMISSION_CUSTOMER_BILLING_READ"] = 10;
                values[valuesById[11] = "PERMISSION_CUSTOMER_PAYORS_READ"] = 11;
                values[valuesById[12] = "PERMISSION_CUSTOMER_PIE_READ"] = 12;
                values[valuesById[13] = "PERMISSION_CUSTOMER_PIE_WRITE"] = 13;
                values[valuesById[14] = "PERMISSION_CUSTOMER_SETTINGS_READ"] = 14;
                values[valuesById[15] = "PERMISSION_CUSTOMER_SETTINGS_WRITE"] = 15;
                values[valuesById[16] = "PERMISSION_CUSTOMER_TASKS_READ"] = 16;
                values[valuesById[17] = "PERMISSION_CUSTOMER_TASKS_WRITE"] = 17;
                values[valuesById[18] = "PERMISSION_CUSTOMER_TASK_IMPORTS_READ"] = 18;
                values[valuesById[19] = "PERMISSION_CUSTOMER_TASK_IMPORTS_WRITE"] = 19;
                values[valuesById[34] = "PERMISSION_CUSTOMER_TASK_RECORDINGS_READ"] = 34;
                values[valuesById[20] = "PERMISSION_CUSTOMER_TASK_RECORDINGS_LISTEN"] = 20;
                values[valuesById[21] = "PERMISSION_CUSTOMER_TASK_RECORDINGS_DOWNLOAD"] = 21;
                values[valuesById[22] = "PERMISSION_CUSTOMER_TASK_REPORTS_READ"] = 22;
                values[valuesById[23] = "PERMISSION_CUSTOMER_TASK_REPORTS_WRITE"] = 23;
                values[valuesById[24] = "PERMISSION_CUSTOMER_FT_SETUP_APPROVE"] = 24;
                values[valuesById[25] = "PERMISSION_CUSTOMER_FT_TASKS_OPERATE"] = 25;
                values[valuesById[26] = "PERMISSION_CUSTOMER_FT_TASKS_MANAGE"] = 26;
                values[valuesById[27] = "PERMISSION_CUSTOMER_FT_DASHBOARD"] = 27;
                values[valuesById[28] = "PERMISSION_CUSTOMER_DOCS_READ"] = 28;
                values[valuesById[29] = "PERMISSION_CUSTOMER_PORTAL_ACCESS"] = 29;
                values[valuesById[38] = "PERMISSION_CUSTOMER_FT_SOFTPHONE"] = 38;
                values[valuesById[39] = "PERMISSION_CUSTOMER_FT_CALL_QUEUE"] = 39;
                values[valuesById[40] = "PERMISSION_CUSTOMER_FT_CALL_AUTO"] = 40;
                values[valuesById[41] = "PERMISSION_CUSTOMER_FT_CALL_MANUAL"] = 41;
                values[valuesById[42] = "PERMISSION_CUSTOMER_FT_CALL_SALESFORCE"] = 42;
                values[valuesById[43] = "PERMISSION_CUSTOMER_FT_DASHBOARD_TEAM"] = 43;
                values[valuesById[44] = "PERMISSION_CUSTOMER_FT_DASHBOARD_ORG"] = 44;
                return values;
            })();
    
            auth.CreateRoleRequest = (function() {
    
                /**
                 * Properties of a CreateRoleRequest.
                 * @memberof infinitusai.auth
                 * @interface ICreateRoleRequest
                 * @property {string|null} [name] CreateRoleRequest name
                 * @property {string|null} [description] CreateRoleRequest description
                 * @property {boolean|null} [readOnly] CreateRoleRequest readOnly
                 * @property {infinitusai.auth.RoleType|null} [type] CreateRoleRequest type
                 */
    
                /**
                 * Constructs a new CreateRoleRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateRoleRequest.
                 * @implements ICreateRoleRequest
                 * @constructor
                 * @param {infinitusai.auth.ICreateRoleRequest=} [properties] Properties to set
                 */
                function CreateRoleRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateRoleRequest name.
                 * @member {string} name
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @instance
                 */
                CreateRoleRequest.prototype.name = "";
    
                /**
                 * CreateRoleRequest description.
                 * @member {string} description
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @instance
                 */
                CreateRoleRequest.prototype.description = "";
    
                /**
                 * CreateRoleRequest readOnly.
                 * @member {boolean} readOnly
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @instance
                 */
                CreateRoleRequest.prototype.readOnly = false;
    
                /**
                 * CreateRoleRequest type.
                 * @member {infinitusai.auth.RoleType} type
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @instance
                 */
                CreateRoleRequest.prototype.type = 0;
    
                /**
                 * Creates a new CreateRoleRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {infinitusai.auth.ICreateRoleRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateRoleRequest} CreateRoleRequest instance
                 */
                CreateRoleRequest.create = function create(properties) {
                    return new CreateRoleRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateRoleRequest message. Does not implicitly {@link infinitusai.auth.CreateRoleRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {infinitusai.auth.ICreateRoleRequest} message CreateRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateRoleRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                    if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateRoleRequest message, length delimited. Does not implicitly {@link infinitusai.auth.CreateRoleRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {infinitusai.auth.ICreateRoleRequest} message CreateRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateRoleRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateRoleRequest} CreateRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateRoleRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateRoleRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.description = reader.string();
                                break;
                            }
                        case 3: {
                                message.readOnly = reader.bool();
                                break;
                            }
                        case 4: {
                                message.type = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateRoleRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateRoleRequest} CreateRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateRoleRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateRoleRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateRoleRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                        if (typeof message.readOnly !== "boolean")
                            return "readOnly: boolean expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a CreateRoleRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateRoleRequest} CreateRoleRequest
                 */
                CreateRoleRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateRoleRequest)
                        return object;
                    var message = new $root.infinitusai.auth.CreateRoleRequest();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.readOnly != null)
                        message.readOnly = Boolean(object.readOnly);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "ROLE_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "ROLE_TYPE_ADMIN":
                    case 1:
                        message.type = 1;
                        break;
                    case "ROLE_TYPE_OPERATOR":
                    case 2:
                        message.type = 2;
                        break;
                    case "ROLE_TYPE_CUSTOMER":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateRoleRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {infinitusai.auth.CreateRoleRequest} message CreateRoleRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateRoleRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.description = "";
                        object.readOnly = false;
                        object.type = options.enums === String ? "ROLE_TYPE_UNKNOWN" : 0;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                        object.readOnly = message.readOnly;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.auth.RoleType[message.type] === undefined ? message.type : $root.infinitusai.auth.RoleType[message.type] : message.type;
                    return object;
                };
    
                /**
                 * Converts this CreateRoleRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateRoleRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateRoleRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateRoleRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateRoleRequest";
                };
    
                return CreateRoleRequest;
            })();
    
            auth.CreateRoleResponse = (function() {
    
                /**
                 * Properties of a CreateRoleResponse.
                 * @memberof infinitusai.auth
                 * @interface ICreateRoleResponse
                 * @property {infinitusai.auth.IRole|null} [role] CreateRoleResponse role
                 */
    
                /**
                 * Constructs a new CreateRoleResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateRoleResponse.
                 * @implements ICreateRoleResponse
                 * @constructor
                 * @param {infinitusai.auth.ICreateRoleResponse=} [properties] Properties to set
                 */
                function CreateRoleResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateRoleResponse role.
                 * @member {infinitusai.auth.IRole|null|undefined} role
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @instance
                 */
                CreateRoleResponse.prototype.role = null;
    
                /**
                 * Creates a new CreateRoleResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {infinitusai.auth.ICreateRoleResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateRoleResponse} CreateRoleResponse instance
                 */
                CreateRoleResponse.create = function create(properties) {
                    return new CreateRoleResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateRoleResponse message. Does not implicitly {@link infinitusai.auth.CreateRoleResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {infinitusai.auth.ICreateRoleResponse} message CreateRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateRoleResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                        $root.infinitusai.auth.Role.encode(message.role, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateRoleResponse message, length delimited. Does not implicitly {@link infinitusai.auth.CreateRoleResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {infinitusai.auth.ICreateRoleResponse} message CreateRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateRoleResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateRoleResponse} CreateRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateRoleResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateRoleResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.role = $root.infinitusai.auth.Role.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateRoleResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateRoleResponse} CreateRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateRoleResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateRoleResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateRoleResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.role != null && message.hasOwnProperty("role")) {
                        var error = $root.infinitusai.auth.Role.verify(message.role);
                        if (error)
                            return "role." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateRoleResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateRoleResponse} CreateRoleResponse
                 */
                CreateRoleResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateRoleResponse)
                        return object;
                    var message = new $root.infinitusai.auth.CreateRoleResponse();
                    if (object.role != null) {
                        if (typeof object.role !== "object")
                            throw TypeError(".infinitusai.auth.CreateRoleResponse.role: object expected");
                        message.role = $root.infinitusai.auth.Role.fromObject(object.role);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateRoleResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {infinitusai.auth.CreateRoleResponse} message CreateRoleResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateRoleResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.role = null;
                    if (message.role != null && message.hasOwnProperty("role"))
                        object.role = $root.infinitusai.auth.Role.toObject(message.role, options);
                    return object;
                };
    
                /**
                 * Converts this CreateRoleResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateRoleResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateRoleResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateRoleResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateRoleResponse";
                };
    
                return CreateRoleResponse;
            })();
    
            auth.GetRolesRequest = (function() {
    
                /**
                 * Properties of a GetRolesRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetRolesRequest
                 * @property {number|null} [page] GetRolesRequest page
                 * @property {number|null} [limit] GetRolesRequest limit
                 * @property {string|null} [name] GetRolesRequest name
                 * @property {Array.<infinitusai.auth.RoleType>|null} [types] GetRolesRequest types
                 * @property {string|null} [orderByCol] GetRolesRequest orderByCol
                 * @property {string|null} [orderByDir] GetRolesRequest orderByDir
                 * @property {boolean|null} [unscopedOnly] GetRolesRequest unscopedOnly
                 */
    
                /**
                 * Constructs a new GetRolesRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRolesRequest.
                 * @implements IGetRolesRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetRolesRequest=} [properties] Properties to set
                 */
                function GetRolesRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRolesRequest page.
                 * @member {number} page
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @instance
                 */
                GetRolesRequest.prototype.page = 0;
    
                /**
                 * GetRolesRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @instance
                 */
                GetRolesRequest.prototype.limit = 0;
    
                /**
                 * GetRolesRequest name.
                 * @member {string} name
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @instance
                 */
                GetRolesRequest.prototype.name = "";
    
                /**
                 * GetRolesRequest types.
                 * @member {Array.<infinitusai.auth.RoleType>} types
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @instance
                 */
                GetRolesRequest.prototype.types = $util.emptyArray;
    
                /**
                 * GetRolesRequest orderByCol.
                 * @member {string} orderByCol
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @instance
                 */
                GetRolesRequest.prototype.orderByCol = "";
    
                /**
                 * GetRolesRequest orderByDir.
                 * @member {string} orderByDir
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @instance
                 */
                GetRolesRequest.prototype.orderByDir = "";
    
                /**
                 * GetRolesRequest unscopedOnly.
                 * @member {boolean} unscopedOnly
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @instance
                 */
                GetRolesRequest.prototype.unscopedOnly = false;
    
                /**
                 * Creates a new GetRolesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetRolesRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRolesRequest} GetRolesRequest instance
                 */
                GetRolesRequest.create = function create(properties) {
                    return new GetRolesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetRolesRequest message. Does not implicitly {@link infinitusai.auth.GetRolesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetRolesRequest} message GetRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRolesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 4, wireType 2 =*/34).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    if (message.orderByCol != null && Object.hasOwnProperty.call(message, "orderByCol"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.orderByCol);
                    if (message.orderByDir != null && Object.hasOwnProperty.call(message, "orderByDir"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.orderByDir);
                    if (message.unscopedOnly != null && Object.hasOwnProperty.call(message, "unscopedOnly"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.unscopedOnly);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRolesRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetRolesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetRolesRequest} message GetRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRolesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRolesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRolesRequest} GetRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRolesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRolesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.page = reader.int32();
                                break;
                            }
                        case 2: {
                                message.limit = reader.int32();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        case 5: {
                                message.orderByCol = reader.string();
                                break;
                            }
                        case 6: {
                                message.orderByDir = reader.string();
                                break;
                            }
                        case 7: {
                                message.unscopedOnly = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRolesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRolesRequest} GetRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRolesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRolesRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRolesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        if (!$util.isString(message.orderByCol))
                            return "orderByCol: string expected";
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        if (!$util.isString(message.orderByDir))
                            return "orderByDir: string expected";
                    if (message.unscopedOnly != null && message.hasOwnProperty("unscopedOnly"))
                        if (typeof message.unscopedOnly !== "boolean")
                            return "unscopedOnly: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a GetRolesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRolesRequest} GetRolesRequest
                 */
                GetRolesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRolesRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetRolesRequest();
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetRolesRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "ROLE_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "ROLE_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "ROLE_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "ROLE_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    if (object.orderByCol != null)
                        message.orderByCol = String(object.orderByCol);
                    if (object.orderByDir != null)
                        message.orderByDir = String(object.orderByDir);
                    if (object.unscopedOnly != null)
                        message.unscopedOnly = Boolean(object.unscopedOnly);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRolesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {infinitusai.auth.GetRolesRequest} message GetRolesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRolesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (options.defaults) {
                        object.page = 0;
                        object.limit = 0;
                        object.name = "";
                        object.orderByCol = "";
                        object.orderByDir = "";
                        object.unscopedOnly = false;
                    }
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.RoleType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.RoleType[message.types[j]] : message.types[j];
                    }
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        object.orderByCol = message.orderByCol;
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        object.orderByDir = message.orderByDir;
                    if (message.unscopedOnly != null && message.hasOwnProperty("unscopedOnly"))
                        object.unscopedOnly = message.unscopedOnly;
                    return object;
                };
    
                /**
                 * Converts this GetRolesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRolesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRolesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRolesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRolesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRolesRequest";
                };
    
                return GetRolesRequest;
            })();
    
            auth.GetRolesResponse = (function() {
    
                /**
                 * Properties of a GetRolesResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetRolesResponse
                 * @property {number|Long|null} [total] GetRolesResponse total
                 * @property {Array.<infinitusai.auth.IRole>|null} [roles] GetRolesResponse roles
                 */
    
                /**
                 * Constructs a new GetRolesResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRolesResponse.
                 * @implements IGetRolesResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetRolesResponse=} [properties] Properties to set
                 */
                function GetRolesResponse(properties) {
                    this.roles = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRolesResponse total.
                 * @member {number|Long} total
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @instance
                 */
                GetRolesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GetRolesResponse roles.
                 * @member {Array.<infinitusai.auth.IRole>} roles
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @instance
                 */
                GetRolesResponse.prototype.roles = $util.emptyArray;
    
                /**
                 * Creates a new GetRolesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetRolesResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRolesResponse} GetRolesResponse instance
                 */
                GetRolesResponse.create = function create(properties) {
                    return new GetRolesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetRolesResponse message. Does not implicitly {@link infinitusai.auth.GetRolesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetRolesResponse} message GetRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRolesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.total);
                    if (message.roles != null && message.roles.length)
                        for (var i = 0; i < message.roles.length; ++i)
                            $root.infinitusai.auth.Role.encode(message.roles[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRolesResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetRolesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetRolesResponse} message GetRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRolesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRolesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRolesResponse} GetRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRolesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRolesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.total = reader.int64();
                                break;
                            }
                        case 2: {
                                if (!(message.roles && message.roles.length))
                                    message.roles = [];
                                message.roles.push($root.infinitusai.auth.Role.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRolesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRolesResponse} GetRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRolesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRolesResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRolesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.roles != null && message.hasOwnProperty("roles")) {
                        if (!Array.isArray(message.roles))
                            return "roles: array expected";
                        for (var i = 0; i < message.roles.length; ++i) {
                            var error = $root.infinitusai.auth.Role.verify(message.roles[i]);
                            if (error)
                                return "roles." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetRolesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRolesResponse} GetRolesResponse
                 */
                GetRolesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRolesResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetRolesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.roles) {
                        if (!Array.isArray(object.roles))
                            throw TypeError(".infinitusai.auth.GetRolesResponse.roles: array expected");
                        message.roles = [];
                        for (var i = 0; i < object.roles.length; ++i) {
                            if (typeof object.roles[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetRolesResponse.roles: object expected");
                            message.roles[i] = $root.infinitusai.auth.Role.fromObject(object.roles[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRolesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {infinitusai.auth.GetRolesResponse} message GetRolesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRolesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.roles = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.roles && message.roles.length) {
                        object.roles = [];
                        for (var j = 0; j < message.roles.length; ++j)
                            object.roles[j] = $root.infinitusai.auth.Role.toObject(message.roles[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetRolesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRolesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRolesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRolesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRolesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRolesResponse";
                };
    
                return GetRolesResponse;
            })();
    
            auth.GetRoleRequest = (function() {
    
                /**
                 * Properties of a GetRoleRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetRoleRequest
                 * @property {string|null} [uuid] GetRoleRequest uuid
                 */
    
                /**
                 * Constructs a new GetRoleRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRoleRequest.
                 * @implements IGetRoleRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetRoleRequest=} [properties] Properties to set
                 */
                function GetRoleRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRoleRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @instance
                 */
                GetRoleRequest.prototype.uuid = "";
    
                /**
                 * Creates a new GetRoleRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRoleRequest} GetRoleRequest instance
                 */
                GetRoleRequest.create = function create(properties) {
                    return new GetRoleRequest(properties);
                };
    
                /**
                 * Encodes the specified GetRoleRequest message. Does not implicitly {@link infinitusai.auth.GetRoleRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleRequest} message GetRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRoleRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetRoleRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleRequest} message GetRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRoleRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRoleRequest} GetRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRoleRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRoleRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRoleRequest} GetRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRoleRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRoleRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetRoleRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRoleRequest} GetRoleRequest
                 */
                GetRoleRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRoleRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetRoleRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRoleRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {infinitusai.auth.GetRoleRequest} message GetRoleRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRoleRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this GetRoleRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRoleRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRoleRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRoleRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRoleRequest";
                };
    
                return GetRoleRequest;
            })();
    
            auth.GetRoleResponse = (function() {
    
                /**
                 * Properties of a GetRoleResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetRoleResponse
                 * @property {infinitusai.auth.IRole|null} [role] GetRoleResponse role
                 */
    
                /**
                 * Constructs a new GetRoleResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRoleResponse.
                 * @implements IGetRoleResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetRoleResponse=} [properties] Properties to set
                 */
                function GetRoleResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRoleResponse role.
                 * @member {infinitusai.auth.IRole|null|undefined} role
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @instance
                 */
                GetRoleResponse.prototype.role = null;
    
                /**
                 * Creates a new GetRoleResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRoleResponse} GetRoleResponse instance
                 */
                GetRoleResponse.create = function create(properties) {
                    return new GetRoleResponse(properties);
                };
    
                /**
                 * Encodes the specified GetRoleResponse message. Does not implicitly {@link infinitusai.auth.GetRoleResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleResponse} message GetRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                        $root.infinitusai.auth.Role.encode(message.role, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRoleResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetRoleResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleResponse} message GetRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRoleResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRoleResponse} GetRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRoleResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.role = $root.infinitusai.auth.Role.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRoleResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRoleResponse} GetRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRoleResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRoleResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.role != null && message.hasOwnProperty("role")) {
                        var error = $root.infinitusai.auth.Role.verify(message.role);
                        if (error)
                            return "role." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetRoleResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRoleResponse} GetRoleResponse
                 */
                GetRoleResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRoleResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetRoleResponse();
                    if (object.role != null) {
                        if (typeof object.role !== "object")
                            throw TypeError(".infinitusai.auth.GetRoleResponse.role: object expected");
                        message.role = $root.infinitusai.auth.Role.fromObject(object.role);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRoleResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {infinitusai.auth.GetRoleResponse} message GetRoleResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRoleResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.role = null;
                    if (message.role != null && message.hasOwnProperty("role"))
                        object.role = $root.infinitusai.auth.Role.toObject(message.role, options);
                    return object;
                };
    
                /**
                 * Converts this GetRoleResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRoleResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRoleResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRoleResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRoleResponse";
                };
    
                return GetRoleResponse;
            })();
    
            auth.UpdateRoleRequest = (function() {
    
                /**
                 * Properties of an UpdateRoleRequest.
                 * @memberof infinitusai.auth
                 * @interface IUpdateRoleRequest
                 * @property {string|null} [uuid] UpdateRoleRequest uuid
                 * @property {string|null} [name] UpdateRoleRequest name
                 * @property {string|null} [description] UpdateRoleRequest description
                 */
    
                /**
                 * Constructs a new UpdateRoleRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UpdateRoleRequest.
                 * @implements IUpdateRoleRequest
                 * @constructor
                 * @param {infinitusai.auth.IUpdateRoleRequest=} [properties] Properties to set
                 */
                function UpdateRoleRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateRoleRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @instance
                 */
                UpdateRoleRequest.prototype.uuid = "";
    
                /**
                 * UpdateRoleRequest name.
                 * @member {string} name
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @instance
                 */
                UpdateRoleRequest.prototype.name = "";
    
                /**
                 * UpdateRoleRequest description.
                 * @member {string} description
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @instance
                 */
                UpdateRoleRequest.prototype.description = "";
    
                /**
                 * Creates a new UpdateRoleRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateRoleRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.UpdateRoleRequest} UpdateRoleRequest instance
                 */
                UpdateRoleRequest.create = function create(properties) {
                    return new UpdateRoleRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateRoleRequest message. Does not implicitly {@link infinitusai.auth.UpdateRoleRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateRoleRequest} message UpdateRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateRoleRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateRoleRequest message, length delimited. Does not implicitly {@link infinitusai.auth.UpdateRoleRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateRoleRequest} message UpdateRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateRoleRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UpdateRoleRequest} UpdateRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateRoleRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UpdateRoleRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateRoleRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UpdateRoleRequest} UpdateRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateRoleRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateRoleRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateRoleRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateRoleRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UpdateRoleRequest} UpdateRoleRequest
                 */
                UpdateRoleRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UpdateRoleRequest)
                        return object;
                    var message = new $root.infinitusai.auth.UpdateRoleRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateRoleRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {infinitusai.auth.UpdateRoleRequest} message UpdateRoleRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateRoleRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uuid = "";
                        object.name = "";
                        object.description = "";
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    return object;
                };
    
                /**
                 * Converts this UpdateRoleRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateRoleRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateRoleRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UpdateRoleRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UpdateRoleRequest";
                };
    
                return UpdateRoleRequest;
            })();
    
            auth.UpdateRoleResponse = (function() {
    
                /**
                 * Properties of an UpdateRoleResponse.
                 * @memberof infinitusai.auth
                 * @interface IUpdateRoleResponse
                 * @property {infinitusai.auth.IRole|null} [role] UpdateRoleResponse role
                 */
    
                /**
                 * Constructs a new UpdateRoleResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UpdateRoleResponse.
                 * @implements IUpdateRoleResponse
                 * @constructor
                 * @param {infinitusai.auth.IUpdateRoleResponse=} [properties] Properties to set
                 */
                function UpdateRoleResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateRoleResponse role.
                 * @member {infinitusai.auth.IRole|null|undefined} role
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @instance
                 */
                UpdateRoleResponse.prototype.role = null;
    
                /**
                 * Creates a new UpdateRoleResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateRoleResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.UpdateRoleResponse} UpdateRoleResponse instance
                 */
                UpdateRoleResponse.create = function create(properties) {
                    return new UpdateRoleResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateRoleResponse message. Does not implicitly {@link infinitusai.auth.UpdateRoleResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateRoleResponse} message UpdateRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateRoleResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                        $root.infinitusai.auth.Role.encode(message.role, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateRoleResponse message, length delimited. Does not implicitly {@link infinitusai.auth.UpdateRoleResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateRoleResponse} message UpdateRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateRoleResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UpdateRoleResponse} UpdateRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateRoleResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UpdateRoleResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.role = $root.infinitusai.auth.Role.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateRoleResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UpdateRoleResponse} UpdateRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateRoleResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateRoleResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateRoleResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.role != null && message.hasOwnProperty("role")) {
                        var error = $root.infinitusai.auth.Role.verify(message.role);
                        if (error)
                            return "role." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateRoleResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UpdateRoleResponse} UpdateRoleResponse
                 */
                UpdateRoleResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UpdateRoleResponse)
                        return object;
                    var message = new $root.infinitusai.auth.UpdateRoleResponse();
                    if (object.role != null) {
                        if (typeof object.role !== "object")
                            throw TypeError(".infinitusai.auth.UpdateRoleResponse.role: object expected");
                        message.role = $root.infinitusai.auth.Role.fromObject(object.role);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateRoleResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {infinitusai.auth.UpdateRoleResponse} message UpdateRoleResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateRoleResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.role = null;
                    if (message.role != null && message.hasOwnProperty("role"))
                        object.role = $root.infinitusai.auth.Role.toObject(message.role, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateRoleResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateRoleResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateRoleResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UpdateRoleResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UpdateRoleResponse";
                };
    
                return UpdateRoleResponse;
            })();
    
            auth.DeleteRoleRequest = (function() {
    
                /**
                 * Properties of a DeleteRoleRequest.
                 * @memberof infinitusai.auth
                 * @interface IDeleteRoleRequest
                 * @property {string|null} [uuid] DeleteRoleRequest uuid
                 */
    
                /**
                 * Constructs a new DeleteRoleRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeleteRoleRequest.
                 * @implements IDeleteRoleRequest
                 * @constructor
                 * @param {infinitusai.auth.IDeleteRoleRequest=} [properties] Properties to set
                 */
                function DeleteRoleRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteRoleRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @instance
                 */
                DeleteRoleRequest.prototype.uuid = "";
    
                /**
                 * Creates a new DeleteRoleRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteRoleRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeleteRoleRequest} DeleteRoleRequest instance
                 */
                DeleteRoleRequest.create = function create(properties) {
                    return new DeleteRoleRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteRoleRequest message. Does not implicitly {@link infinitusai.auth.DeleteRoleRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteRoleRequest} message DeleteRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteRoleRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteRoleRequest message, length delimited. Does not implicitly {@link infinitusai.auth.DeleteRoleRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteRoleRequest} message DeleteRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteRoleRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeleteRoleRequest} DeleteRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteRoleRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeleteRoleRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteRoleRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeleteRoleRequest} DeleteRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteRoleRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteRoleRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteRoleRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteRoleRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeleteRoleRequest} DeleteRoleRequest
                 */
                DeleteRoleRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeleteRoleRequest)
                        return object;
                    var message = new $root.infinitusai.auth.DeleteRoleRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteRoleRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {infinitusai.auth.DeleteRoleRequest} message DeleteRoleRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteRoleRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this DeleteRoleRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteRoleRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteRoleRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeleteRoleRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeleteRoleRequest";
                };
    
                return DeleteRoleRequest;
            })();
    
            auth.DeleteRoleResponse = (function() {
    
                /**
                 * Properties of a DeleteRoleResponse.
                 * @memberof infinitusai.auth
                 * @interface IDeleteRoleResponse
                 */
    
                /**
                 * Constructs a new DeleteRoleResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeleteRoleResponse.
                 * @implements IDeleteRoleResponse
                 * @constructor
                 * @param {infinitusai.auth.IDeleteRoleResponse=} [properties] Properties to set
                 */
                function DeleteRoleResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteRoleResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteRoleResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeleteRoleResponse} DeleteRoleResponse instance
                 */
                DeleteRoleResponse.create = function create(properties) {
                    return new DeleteRoleResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteRoleResponse message. Does not implicitly {@link infinitusai.auth.DeleteRoleResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteRoleResponse} message DeleteRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteRoleResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteRoleResponse message, length delimited. Does not implicitly {@link infinitusai.auth.DeleteRoleResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteRoleResponse} message DeleteRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteRoleResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeleteRoleResponse} DeleteRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteRoleResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeleteRoleResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteRoleResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeleteRoleResponse} DeleteRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteRoleResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteRoleResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteRoleResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteRoleResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeleteRoleResponse} DeleteRoleResponse
                 */
                DeleteRoleResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeleteRoleResponse)
                        return object;
                    return new $root.infinitusai.auth.DeleteRoleResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteRoleResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {infinitusai.auth.DeleteRoleResponse} message DeleteRoleResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteRoleResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteRoleResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteRoleResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteRoleResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeleteRoleResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeleteRoleResponse";
                };
    
                return DeleteRoleResponse;
            })();
    
            auth.GetOrgRolesRequest = (function() {
    
                /**
                 * Properties of a GetOrgRolesRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgRolesRequest
                 * @property {Array.<infinitusai.auth.RoleType>|null} [types] GetOrgRolesRequest types
                 */
    
                /**
                 * Constructs a new GetOrgRolesRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgRolesRequest.
                 * @implements IGetOrgRolesRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgRolesRequest=} [properties] Properties to set
                 */
                function GetOrgRolesRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgRolesRequest types.
                 * @member {Array.<infinitusai.auth.RoleType>} types
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @instance
                 */
                GetOrgRolesRequest.prototype.types = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgRolesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgRolesRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgRolesRequest} GetOrgRolesRequest instance
                 */
                GetOrgRolesRequest.create = function create(properties) {
                    return new GetOrgRolesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgRolesRequest message. Does not implicitly {@link infinitusai.auth.GetOrgRolesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgRolesRequest} message GetOrgRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgRolesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgRolesRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgRolesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgRolesRequest} message GetOrgRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgRolesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgRolesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgRolesRequest} GetOrgRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgRolesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgRolesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgRolesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgRolesRequest} GetOrgRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgRolesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgRolesRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgRolesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgRolesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgRolesRequest} GetOrgRolesRequest
                 */
                GetOrgRolesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgRolesRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgRolesRequest();
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetOrgRolesRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "ROLE_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "ROLE_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "ROLE_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "ROLE_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgRolesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {infinitusai.auth.GetOrgRolesRequest} message GetOrgRolesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgRolesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.RoleType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.RoleType[message.types[j]] : message.types[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgRolesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgRolesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgRolesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgRolesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgRolesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgRolesRequest";
                };
    
                return GetOrgRolesRequest;
            })();
    
            auth.GetOrgRolesResponse = (function() {
    
                /**
                 * Properties of a GetOrgRolesResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgRolesResponse
                 * @property {Array.<infinitusai.auth.IRole>|null} [roles] GetOrgRolesResponse roles
                 */
    
                /**
                 * Constructs a new GetOrgRolesResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgRolesResponse.
                 * @implements IGetOrgRolesResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgRolesResponse=} [properties] Properties to set
                 */
                function GetOrgRolesResponse(properties) {
                    this.roles = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgRolesResponse roles.
                 * @member {Array.<infinitusai.auth.IRole>} roles
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @instance
                 */
                GetOrgRolesResponse.prototype.roles = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgRolesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgRolesResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgRolesResponse} GetOrgRolesResponse instance
                 */
                GetOrgRolesResponse.create = function create(properties) {
                    return new GetOrgRolesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgRolesResponse message. Does not implicitly {@link infinitusai.auth.GetOrgRolesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgRolesResponse} message GetOrgRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgRolesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roles != null && message.roles.length)
                        for (var i = 0; i < message.roles.length; ++i)
                            $root.infinitusai.auth.Role.encode(message.roles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgRolesResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgRolesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgRolesResponse} message GetOrgRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgRolesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgRolesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgRolesResponse} GetOrgRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgRolesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgRolesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.roles && message.roles.length))
                                    message.roles = [];
                                message.roles.push($root.infinitusai.auth.Role.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgRolesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgRolesResponse} GetOrgRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgRolesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgRolesResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgRolesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roles != null && message.hasOwnProperty("roles")) {
                        if (!Array.isArray(message.roles))
                            return "roles: array expected";
                        for (var i = 0; i < message.roles.length; ++i) {
                            var error = $root.infinitusai.auth.Role.verify(message.roles[i]);
                            if (error)
                                return "roles." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgRolesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgRolesResponse} GetOrgRolesResponse
                 */
                GetOrgRolesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgRolesResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgRolesResponse();
                    if (object.roles) {
                        if (!Array.isArray(object.roles))
                            throw TypeError(".infinitusai.auth.GetOrgRolesResponse.roles: array expected");
                        message.roles = [];
                        for (var i = 0; i < object.roles.length; ++i) {
                            if (typeof object.roles[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetOrgRolesResponse.roles: object expected");
                            message.roles[i] = $root.infinitusai.auth.Role.fromObject(object.roles[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgRolesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {infinitusai.auth.GetOrgRolesResponse} message GetOrgRolesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgRolesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.roles = [];
                    if (message.roles && message.roles.length) {
                        object.roles = [];
                        for (var j = 0; j < message.roles.length; ++j)
                            object.roles[j] = $root.infinitusai.auth.Role.toObject(message.roles[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgRolesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgRolesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgRolesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgRolesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgRolesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgRolesResponse";
                };
    
                return GetOrgRolesResponse;
            })();
    
            auth.AddPermissionToOrgRequest = (function() {
    
                /**
                 * Properties of an AddPermissionToOrgRequest.
                 * @memberof infinitusai.auth
                 * @interface IAddPermissionToOrgRequest
                 * @property {string|null} [permissionUuid] AddPermissionToOrgRequest permissionUuid
                 */
    
                /**
                 * Constructs a new AddPermissionToOrgRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AddPermissionToOrgRequest.
                 * @implements IAddPermissionToOrgRequest
                 * @constructor
                 * @param {infinitusai.auth.IAddPermissionToOrgRequest=} [properties] Properties to set
                 */
                function AddPermissionToOrgRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AddPermissionToOrgRequest permissionUuid.
                 * @member {string} permissionUuid
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @instance
                 */
                AddPermissionToOrgRequest.prototype.permissionUuid = "";
    
                /**
                 * Creates a new AddPermissionToOrgRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToOrgRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.AddPermissionToOrgRequest} AddPermissionToOrgRequest instance
                 */
                AddPermissionToOrgRequest.create = function create(properties) {
                    return new AddPermissionToOrgRequest(properties);
                };
    
                /**
                 * Encodes the specified AddPermissionToOrgRequest message. Does not implicitly {@link infinitusai.auth.AddPermissionToOrgRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToOrgRequest} message AddPermissionToOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddPermissionToOrgRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.permissionUuid != null && Object.hasOwnProperty.call(message, "permissionUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.permissionUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified AddPermissionToOrgRequest message, length delimited. Does not implicitly {@link infinitusai.auth.AddPermissionToOrgRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToOrgRequest} message AddPermissionToOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddPermissionToOrgRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddPermissionToOrgRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AddPermissionToOrgRequest} AddPermissionToOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddPermissionToOrgRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AddPermissionToOrgRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.permissionUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddPermissionToOrgRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AddPermissionToOrgRequest} AddPermissionToOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddPermissionToOrgRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddPermissionToOrgRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddPermissionToOrgRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.permissionUuid != null && message.hasOwnProperty("permissionUuid"))
                        if (!$util.isString(message.permissionUuid))
                            return "permissionUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an AddPermissionToOrgRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AddPermissionToOrgRequest} AddPermissionToOrgRequest
                 */
                AddPermissionToOrgRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AddPermissionToOrgRequest)
                        return object;
                    var message = new $root.infinitusai.auth.AddPermissionToOrgRequest();
                    if (object.permissionUuid != null)
                        message.permissionUuid = String(object.permissionUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AddPermissionToOrgRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {infinitusai.auth.AddPermissionToOrgRequest} message AddPermissionToOrgRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddPermissionToOrgRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.permissionUuid = "";
                    if (message.permissionUuid != null && message.hasOwnProperty("permissionUuid"))
                        object.permissionUuid = message.permissionUuid;
                    return object;
                };
    
                /**
                 * Converts this AddPermissionToOrgRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddPermissionToOrgRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddPermissionToOrgRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AddPermissionToOrgRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddPermissionToOrgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AddPermissionToOrgRequest";
                };
    
                return AddPermissionToOrgRequest;
            })();
    
            auth.AddPermissionToOrgResponse = (function() {
    
                /**
                 * Properties of an AddPermissionToOrgResponse.
                 * @memberof infinitusai.auth
                 * @interface IAddPermissionToOrgResponse
                 */
    
                /**
                 * Constructs a new AddPermissionToOrgResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AddPermissionToOrgResponse.
                 * @implements IAddPermissionToOrgResponse
                 * @constructor
                 * @param {infinitusai.auth.IAddPermissionToOrgResponse=} [properties] Properties to set
                 */
                function AddPermissionToOrgResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new AddPermissionToOrgResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToOrgResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.AddPermissionToOrgResponse} AddPermissionToOrgResponse instance
                 */
                AddPermissionToOrgResponse.create = function create(properties) {
                    return new AddPermissionToOrgResponse(properties);
                };
    
                /**
                 * Encodes the specified AddPermissionToOrgResponse message. Does not implicitly {@link infinitusai.auth.AddPermissionToOrgResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToOrgResponse} message AddPermissionToOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddPermissionToOrgResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified AddPermissionToOrgResponse message, length delimited. Does not implicitly {@link infinitusai.auth.AddPermissionToOrgResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToOrgResponse} message AddPermissionToOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddPermissionToOrgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddPermissionToOrgResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AddPermissionToOrgResponse} AddPermissionToOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddPermissionToOrgResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AddPermissionToOrgResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddPermissionToOrgResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AddPermissionToOrgResponse} AddPermissionToOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddPermissionToOrgResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddPermissionToOrgResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddPermissionToOrgResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an AddPermissionToOrgResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AddPermissionToOrgResponse} AddPermissionToOrgResponse
                 */
                AddPermissionToOrgResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AddPermissionToOrgResponse)
                        return object;
                    return new $root.infinitusai.auth.AddPermissionToOrgResponse();
                };
    
                /**
                 * Creates a plain object from an AddPermissionToOrgResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {infinitusai.auth.AddPermissionToOrgResponse} message AddPermissionToOrgResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddPermissionToOrgResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this AddPermissionToOrgResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddPermissionToOrgResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddPermissionToOrgResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AddPermissionToOrgResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddPermissionToOrgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AddPermissionToOrgResponse";
                };
    
                return AddPermissionToOrgResponse;
            })();
    
            auth.RemovePermissionFromOrgRequest = (function() {
    
                /**
                 * Properties of a RemovePermissionFromOrgRequest.
                 * @memberof infinitusai.auth
                 * @interface IRemovePermissionFromOrgRequest
                 * @property {string|null} [permissionUuid] RemovePermissionFromOrgRequest permissionUuid
                 */
    
                /**
                 * Constructs a new RemovePermissionFromOrgRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemovePermissionFromOrgRequest.
                 * @implements IRemovePermissionFromOrgRequest
                 * @constructor
                 * @param {infinitusai.auth.IRemovePermissionFromOrgRequest=} [properties] Properties to set
                 */
                function RemovePermissionFromOrgRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RemovePermissionFromOrgRequest permissionUuid.
                 * @member {string} permissionUuid
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @instance
                 */
                RemovePermissionFromOrgRequest.prototype.permissionUuid = "";
    
                /**
                 * Creates a new RemovePermissionFromOrgRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromOrgRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemovePermissionFromOrgRequest} RemovePermissionFromOrgRequest instance
                 */
                RemovePermissionFromOrgRequest.create = function create(properties) {
                    return new RemovePermissionFromOrgRequest(properties);
                };
    
                /**
                 * Encodes the specified RemovePermissionFromOrgRequest message. Does not implicitly {@link infinitusai.auth.RemovePermissionFromOrgRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromOrgRequest} message RemovePermissionFromOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePermissionFromOrgRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.permissionUuid != null && Object.hasOwnProperty.call(message, "permissionUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.permissionUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RemovePermissionFromOrgRequest message, length delimited. Does not implicitly {@link infinitusai.auth.RemovePermissionFromOrgRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromOrgRequest} message RemovePermissionFromOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePermissionFromOrgRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemovePermissionFromOrgRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemovePermissionFromOrgRequest} RemovePermissionFromOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePermissionFromOrgRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemovePermissionFromOrgRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.permissionUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemovePermissionFromOrgRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemovePermissionFromOrgRequest} RemovePermissionFromOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePermissionFromOrgRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemovePermissionFromOrgRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemovePermissionFromOrgRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.permissionUuid != null && message.hasOwnProperty("permissionUuid"))
                        if (!$util.isString(message.permissionUuid))
                            return "permissionUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RemovePermissionFromOrgRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemovePermissionFromOrgRequest} RemovePermissionFromOrgRequest
                 */
                RemovePermissionFromOrgRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemovePermissionFromOrgRequest)
                        return object;
                    var message = new $root.infinitusai.auth.RemovePermissionFromOrgRequest();
                    if (object.permissionUuid != null)
                        message.permissionUuid = String(object.permissionUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RemovePermissionFromOrgRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.RemovePermissionFromOrgRequest} message RemovePermissionFromOrgRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemovePermissionFromOrgRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.permissionUuid = "";
                    if (message.permissionUuid != null && message.hasOwnProperty("permissionUuid"))
                        object.permissionUuid = message.permissionUuid;
                    return object;
                };
    
                /**
                 * Converts this RemovePermissionFromOrgRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemovePermissionFromOrgRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemovePermissionFromOrgRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemovePermissionFromOrgRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemovePermissionFromOrgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemovePermissionFromOrgRequest";
                };
    
                return RemovePermissionFromOrgRequest;
            })();
    
            auth.RemovePermissionFromOrgResponse = (function() {
    
                /**
                 * Properties of a RemovePermissionFromOrgResponse.
                 * @memberof infinitusai.auth
                 * @interface IRemovePermissionFromOrgResponse
                 */
    
                /**
                 * Constructs a new RemovePermissionFromOrgResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemovePermissionFromOrgResponse.
                 * @implements IRemovePermissionFromOrgResponse
                 * @constructor
                 * @param {infinitusai.auth.IRemovePermissionFromOrgResponse=} [properties] Properties to set
                 */
                function RemovePermissionFromOrgResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RemovePermissionFromOrgResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromOrgResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemovePermissionFromOrgResponse} RemovePermissionFromOrgResponse instance
                 */
                RemovePermissionFromOrgResponse.create = function create(properties) {
                    return new RemovePermissionFromOrgResponse(properties);
                };
    
                /**
                 * Encodes the specified RemovePermissionFromOrgResponse message. Does not implicitly {@link infinitusai.auth.RemovePermissionFromOrgResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromOrgResponse} message RemovePermissionFromOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePermissionFromOrgResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RemovePermissionFromOrgResponse message, length delimited. Does not implicitly {@link infinitusai.auth.RemovePermissionFromOrgResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromOrgResponse} message RemovePermissionFromOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePermissionFromOrgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemovePermissionFromOrgResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemovePermissionFromOrgResponse} RemovePermissionFromOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePermissionFromOrgResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemovePermissionFromOrgResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemovePermissionFromOrgResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemovePermissionFromOrgResponse} RemovePermissionFromOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePermissionFromOrgResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemovePermissionFromOrgResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemovePermissionFromOrgResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RemovePermissionFromOrgResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemovePermissionFromOrgResponse} RemovePermissionFromOrgResponse
                 */
                RemovePermissionFromOrgResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemovePermissionFromOrgResponse)
                        return object;
                    return new $root.infinitusai.auth.RemovePermissionFromOrgResponse();
                };
    
                /**
                 * Creates a plain object from a RemovePermissionFromOrgResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.RemovePermissionFromOrgResponse} message RemovePermissionFromOrgResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemovePermissionFromOrgResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RemovePermissionFromOrgResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemovePermissionFromOrgResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemovePermissionFromOrgResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemovePermissionFromOrgResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemovePermissionFromOrgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemovePermissionFromOrgResponse";
                };
    
                return RemovePermissionFromOrgResponse;
            })();
    
            auth.AddPermissionToRoleRequest = (function() {
    
                /**
                 * Properties of an AddPermissionToRoleRequest.
                 * @memberof infinitusai.auth
                 * @interface IAddPermissionToRoleRequest
                 * @property {string|null} [roleUuid] AddPermissionToRoleRequest roleUuid
                 * @property {string|null} [permissionUuid] AddPermissionToRoleRequest permissionUuid
                 */
    
                /**
                 * Constructs a new AddPermissionToRoleRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AddPermissionToRoleRequest.
                 * @implements IAddPermissionToRoleRequest
                 * @constructor
                 * @param {infinitusai.auth.IAddPermissionToRoleRequest=} [properties] Properties to set
                 */
                function AddPermissionToRoleRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AddPermissionToRoleRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @instance
                 */
                AddPermissionToRoleRequest.prototype.roleUuid = "";
    
                /**
                 * AddPermissionToRoleRequest permissionUuid.
                 * @member {string} permissionUuid
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @instance
                 */
                AddPermissionToRoleRequest.prototype.permissionUuid = "";
    
                /**
                 * Creates a new AddPermissionToRoleRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToRoleRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.AddPermissionToRoleRequest} AddPermissionToRoleRequest instance
                 */
                AddPermissionToRoleRequest.create = function create(properties) {
                    return new AddPermissionToRoleRequest(properties);
                };
    
                /**
                 * Encodes the specified AddPermissionToRoleRequest message. Does not implicitly {@link infinitusai.auth.AddPermissionToRoleRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToRoleRequest} message AddPermissionToRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddPermissionToRoleRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleUuid);
                    if (message.permissionUuid != null && Object.hasOwnProperty.call(message, "permissionUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.permissionUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified AddPermissionToRoleRequest message, length delimited. Does not implicitly {@link infinitusai.auth.AddPermissionToRoleRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToRoleRequest} message AddPermissionToRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddPermissionToRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddPermissionToRoleRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AddPermissionToRoleRequest} AddPermissionToRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddPermissionToRoleRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AddPermissionToRoleRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.permissionUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddPermissionToRoleRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AddPermissionToRoleRequest} AddPermissionToRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddPermissionToRoleRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddPermissionToRoleRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddPermissionToRoleRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    if (message.permissionUuid != null && message.hasOwnProperty("permissionUuid"))
                        if (!$util.isString(message.permissionUuid))
                            return "permissionUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an AddPermissionToRoleRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AddPermissionToRoleRequest} AddPermissionToRoleRequest
                 */
                AddPermissionToRoleRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AddPermissionToRoleRequest)
                        return object;
                    var message = new $root.infinitusai.auth.AddPermissionToRoleRequest();
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    if (object.permissionUuid != null)
                        message.permissionUuid = String(object.permissionUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AddPermissionToRoleRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {infinitusai.auth.AddPermissionToRoleRequest} message AddPermissionToRoleRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddPermissionToRoleRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.roleUuid = "";
                        object.permissionUuid = "";
                    }
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    if (message.permissionUuid != null && message.hasOwnProperty("permissionUuid"))
                        object.permissionUuid = message.permissionUuid;
                    return object;
                };
    
                /**
                 * Converts this AddPermissionToRoleRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddPermissionToRoleRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddPermissionToRoleRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AddPermissionToRoleRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddPermissionToRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AddPermissionToRoleRequest";
                };
    
                return AddPermissionToRoleRequest;
            })();
    
            auth.AddPermissionToRoleResponse = (function() {
    
                /**
                 * Properties of an AddPermissionToRoleResponse.
                 * @memberof infinitusai.auth
                 * @interface IAddPermissionToRoleResponse
                 */
    
                /**
                 * Constructs a new AddPermissionToRoleResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AddPermissionToRoleResponse.
                 * @implements IAddPermissionToRoleResponse
                 * @constructor
                 * @param {infinitusai.auth.IAddPermissionToRoleResponse=} [properties] Properties to set
                 */
                function AddPermissionToRoleResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new AddPermissionToRoleResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToRoleResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.AddPermissionToRoleResponse} AddPermissionToRoleResponse instance
                 */
                AddPermissionToRoleResponse.create = function create(properties) {
                    return new AddPermissionToRoleResponse(properties);
                };
    
                /**
                 * Encodes the specified AddPermissionToRoleResponse message. Does not implicitly {@link infinitusai.auth.AddPermissionToRoleResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToRoleResponse} message AddPermissionToRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddPermissionToRoleResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified AddPermissionToRoleResponse message, length delimited. Does not implicitly {@link infinitusai.auth.AddPermissionToRoleResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {infinitusai.auth.IAddPermissionToRoleResponse} message AddPermissionToRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddPermissionToRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddPermissionToRoleResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AddPermissionToRoleResponse} AddPermissionToRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddPermissionToRoleResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AddPermissionToRoleResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddPermissionToRoleResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AddPermissionToRoleResponse} AddPermissionToRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddPermissionToRoleResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddPermissionToRoleResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddPermissionToRoleResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an AddPermissionToRoleResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AddPermissionToRoleResponse} AddPermissionToRoleResponse
                 */
                AddPermissionToRoleResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AddPermissionToRoleResponse)
                        return object;
                    return new $root.infinitusai.auth.AddPermissionToRoleResponse();
                };
    
                /**
                 * Creates a plain object from an AddPermissionToRoleResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {infinitusai.auth.AddPermissionToRoleResponse} message AddPermissionToRoleResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddPermissionToRoleResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this AddPermissionToRoleResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddPermissionToRoleResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddPermissionToRoleResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AddPermissionToRoleResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddPermissionToRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AddPermissionToRoleResponse";
                };
    
                return AddPermissionToRoleResponse;
            })();
    
            auth.RemovePermissionFromRoleRequest = (function() {
    
                /**
                 * Properties of a RemovePermissionFromRoleRequest.
                 * @memberof infinitusai.auth
                 * @interface IRemovePermissionFromRoleRequest
                 * @property {string|null} [roleUuid] RemovePermissionFromRoleRequest roleUuid
                 * @property {string|null} [permissionUuid] RemovePermissionFromRoleRequest permissionUuid
                 */
    
                /**
                 * Constructs a new RemovePermissionFromRoleRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemovePermissionFromRoleRequest.
                 * @implements IRemovePermissionFromRoleRequest
                 * @constructor
                 * @param {infinitusai.auth.IRemovePermissionFromRoleRequest=} [properties] Properties to set
                 */
                function RemovePermissionFromRoleRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RemovePermissionFromRoleRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @instance
                 */
                RemovePermissionFromRoleRequest.prototype.roleUuid = "";
    
                /**
                 * RemovePermissionFromRoleRequest permissionUuid.
                 * @member {string} permissionUuid
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @instance
                 */
                RemovePermissionFromRoleRequest.prototype.permissionUuid = "";
    
                /**
                 * Creates a new RemovePermissionFromRoleRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromRoleRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemovePermissionFromRoleRequest} RemovePermissionFromRoleRequest instance
                 */
                RemovePermissionFromRoleRequest.create = function create(properties) {
                    return new RemovePermissionFromRoleRequest(properties);
                };
    
                /**
                 * Encodes the specified RemovePermissionFromRoleRequest message. Does not implicitly {@link infinitusai.auth.RemovePermissionFromRoleRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromRoleRequest} message RemovePermissionFromRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePermissionFromRoleRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleUuid);
                    if (message.permissionUuid != null && Object.hasOwnProperty.call(message, "permissionUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.permissionUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RemovePermissionFromRoleRequest message, length delimited. Does not implicitly {@link infinitusai.auth.RemovePermissionFromRoleRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromRoleRequest} message RemovePermissionFromRoleRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePermissionFromRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemovePermissionFromRoleRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemovePermissionFromRoleRequest} RemovePermissionFromRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePermissionFromRoleRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemovePermissionFromRoleRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.permissionUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemovePermissionFromRoleRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemovePermissionFromRoleRequest} RemovePermissionFromRoleRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePermissionFromRoleRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemovePermissionFromRoleRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemovePermissionFromRoleRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    if (message.permissionUuid != null && message.hasOwnProperty("permissionUuid"))
                        if (!$util.isString(message.permissionUuid))
                            return "permissionUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RemovePermissionFromRoleRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemovePermissionFromRoleRequest} RemovePermissionFromRoleRequest
                 */
                RemovePermissionFromRoleRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemovePermissionFromRoleRequest)
                        return object;
                    var message = new $root.infinitusai.auth.RemovePermissionFromRoleRequest();
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    if (object.permissionUuid != null)
                        message.permissionUuid = String(object.permissionUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RemovePermissionFromRoleRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {infinitusai.auth.RemovePermissionFromRoleRequest} message RemovePermissionFromRoleRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemovePermissionFromRoleRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.roleUuid = "";
                        object.permissionUuid = "";
                    }
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    if (message.permissionUuid != null && message.hasOwnProperty("permissionUuid"))
                        object.permissionUuid = message.permissionUuid;
                    return object;
                };
    
                /**
                 * Converts this RemovePermissionFromRoleRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemovePermissionFromRoleRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemovePermissionFromRoleRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemovePermissionFromRoleRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemovePermissionFromRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemovePermissionFromRoleRequest";
                };
    
                return RemovePermissionFromRoleRequest;
            })();
    
            auth.RemovePermissionFromRoleResponse = (function() {
    
                /**
                 * Properties of a RemovePermissionFromRoleResponse.
                 * @memberof infinitusai.auth
                 * @interface IRemovePermissionFromRoleResponse
                 */
    
                /**
                 * Constructs a new RemovePermissionFromRoleResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemovePermissionFromRoleResponse.
                 * @implements IRemovePermissionFromRoleResponse
                 * @constructor
                 * @param {infinitusai.auth.IRemovePermissionFromRoleResponse=} [properties] Properties to set
                 */
                function RemovePermissionFromRoleResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RemovePermissionFromRoleResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromRoleResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemovePermissionFromRoleResponse} RemovePermissionFromRoleResponse instance
                 */
                RemovePermissionFromRoleResponse.create = function create(properties) {
                    return new RemovePermissionFromRoleResponse(properties);
                };
    
                /**
                 * Encodes the specified RemovePermissionFromRoleResponse message. Does not implicitly {@link infinitusai.auth.RemovePermissionFromRoleResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromRoleResponse} message RemovePermissionFromRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePermissionFromRoleResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RemovePermissionFromRoleResponse message, length delimited. Does not implicitly {@link infinitusai.auth.RemovePermissionFromRoleResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {infinitusai.auth.IRemovePermissionFromRoleResponse} message RemovePermissionFromRoleResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePermissionFromRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemovePermissionFromRoleResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemovePermissionFromRoleResponse} RemovePermissionFromRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePermissionFromRoleResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemovePermissionFromRoleResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemovePermissionFromRoleResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemovePermissionFromRoleResponse} RemovePermissionFromRoleResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePermissionFromRoleResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemovePermissionFromRoleResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemovePermissionFromRoleResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RemovePermissionFromRoleResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemovePermissionFromRoleResponse} RemovePermissionFromRoleResponse
                 */
                RemovePermissionFromRoleResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemovePermissionFromRoleResponse)
                        return object;
                    return new $root.infinitusai.auth.RemovePermissionFromRoleResponse();
                };
    
                /**
                 * Creates a plain object from a RemovePermissionFromRoleResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {infinitusai.auth.RemovePermissionFromRoleResponse} message RemovePermissionFromRoleResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemovePermissionFromRoleResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RemovePermissionFromRoleResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemovePermissionFromRoleResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemovePermissionFromRoleResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemovePermissionFromRoleResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemovePermissionFromRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemovePermissionFromRoleResponse";
                };
    
                return RemovePermissionFromRoleResponse;
            })();
    
            auth.GetPermissionsRequest = (function() {
    
                /**
                 * Properties of a GetPermissionsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetPermissionsRequest
                 * @property {google.protobuf.IBoolValue|null} [readOnly] GetPermissionsRequest readOnly
                 * @property {Array.<infinitusai.auth.PermissionType>|null} [types] GetPermissionsRequest types
                 */
    
                /**
                 * Constructs a new GetPermissionsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetPermissionsRequest.
                 * @implements IGetPermissionsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetPermissionsRequest=} [properties] Properties to set
                 */
                function GetPermissionsRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPermissionsRequest readOnly.
                 * @member {google.protobuf.IBoolValue|null|undefined} readOnly
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @instance
                 */
                GetPermissionsRequest.prototype.readOnly = null;
    
                /**
                 * GetPermissionsRequest types.
                 * @member {Array.<infinitusai.auth.PermissionType>} types
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @instance
                 */
                GetPermissionsRequest.prototype.types = $util.emptyArray;
    
                /**
                 * Creates a new GetPermissionsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetPermissionsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetPermissionsRequest} GetPermissionsRequest instance
                 */
                GetPermissionsRequest.create = function create(properties) {
                    return new GetPermissionsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPermissionsRequest message. Does not implicitly {@link infinitusai.auth.GetPermissionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetPermissionsRequest} message GetPermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPermissionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                        $root.google.protobuf.BoolValue.encode(message.readOnly, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPermissionsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetPermissionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetPermissionsRequest} message GetPermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPermissionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPermissionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetPermissionsRequest} GetPermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPermissionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetPermissionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.readOnly = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPermissionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetPermissionsRequest} GetPermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPermissionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPermissionsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPermissionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.readOnly != null && message.hasOwnProperty("readOnly")) {
                        var error = $root.google.protobuf.BoolValue.verify(message.readOnly);
                        if (error)
                            return "readOnly." + error;
                    }
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPermissionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetPermissionsRequest} GetPermissionsRequest
                 */
                GetPermissionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetPermissionsRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetPermissionsRequest();
                    if (object.readOnly != null) {
                        if (typeof object.readOnly !== "object")
                            throw TypeError(".infinitusai.auth.GetPermissionsRequest.readOnly: object expected");
                        message.readOnly = $root.google.protobuf.BoolValue.fromObject(object.readOnly);
                    }
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetPermissionsRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "PERMISSION_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "PERMISSION_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "PERMISSION_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "PERMISSION_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPermissionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.GetPermissionsRequest} message GetPermissionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPermissionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (options.defaults)
                        object.readOnly = null;
                    if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                        object.readOnly = $root.google.protobuf.BoolValue.toObject(message.readOnly, options);
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.PermissionType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.PermissionType[message.types[j]] : message.types[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPermissionsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPermissionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPermissionsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetPermissionsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPermissionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetPermissionsRequest";
                };
    
                return GetPermissionsRequest;
            })();
    
            auth.GetPermissionsResponse = (function() {
    
                /**
                 * Properties of a GetPermissionsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetPermissionsResponse
                 * @property {Array.<infinitusai.auth.IPermission>|null} [permissions] GetPermissionsResponse permissions
                 */
    
                /**
                 * Constructs a new GetPermissionsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetPermissionsResponse.
                 * @implements IGetPermissionsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetPermissionsResponse=} [properties] Properties to set
                 */
                function GetPermissionsResponse(properties) {
                    this.permissions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPermissionsResponse permissions.
                 * @member {Array.<infinitusai.auth.IPermission>} permissions
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @instance
                 */
                GetPermissionsResponse.prototype.permissions = $util.emptyArray;
    
                /**
                 * Creates a new GetPermissionsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetPermissionsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetPermissionsResponse} GetPermissionsResponse instance
                 */
                GetPermissionsResponse.create = function create(properties) {
                    return new GetPermissionsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPermissionsResponse message. Does not implicitly {@link infinitusai.auth.GetPermissionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetPermissionsResponse} message GetPermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPermissionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.permissions != null && message.permissions.length)
                        for (var i = 0; i < message.permissions.length; ++i)
                            $root.infinitusai.auth.Permission.encode(message.permissions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPermissionsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetPermissionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetPermissionsResponse} message GetPermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPermissionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPermissionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetPermissionsResponse} GetPermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPermissionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetPermissionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.permissions && message.permissions.length))
                                    message.permissions = [];
                                message.permissions.push($root.infinitusai.auth.Permission.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPermissionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetPermissionsResponse} GetPermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPermissionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPermissionsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPermissionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.permissions != null && message.hasOwnProperty("permissions")) {
                        if (!Array.isArray(message.permissions))
                            return "permissions: array expected";
                        for (var i = 0; i < message.permissions.length; ++i) {
                            var error = $root.infinitusai.auth.Permission.verify(message.permissions[i]);
                            if (error)
                                return "permissions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPermissionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetPermissionsResponse} GetPermissionsResponse
                 */
                GetPermissionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetPermissionsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetPermissionsResponse();
                    if (object.permissions) {
                        if (!Array.isArray(object.permissions))
                            throw TypeError(".infinitusai.auth.GetPermissionsResponse.permissions: array expected");
                        message.permissions = [];
                        for (var i = 0; i < object.permissions.length; ++i) {
                            if (typeof object.permissions[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetPermissionsResponse.permissions: object expected");
                            message.permissions[i] = $root.infinitusai.auth.Permission.fromObject(object.permissions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPermissionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.GetPermissionsResponse} message GetPermissionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPermissionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.permissions = [];
                    if (message.permissions && message.permissions.length) {
                        object.permissions = [];
                        for (var j = 0; j < message.permissions.length; ++j)
                            object.permissions[j] = $root.infinitusai.auth.Permission.toObject(message.permissions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPermissionsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPermissionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPermissionsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetPermissionsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPermissionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetPermissionsResponse";
                };
    
                return GetPermissionsResponse;
            })();
    
            auth.GetPermissionOrgsRequest = (function() {
    
                /**
                 * Properties of a GetPermissionOrgsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetPermissionOrgsRequest
                 * @property {string|null} [uuid] GetPermissionOrgsRequest uuid
                 */
    
                /**
                 * Constructs a new GetPermissionOrgsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetPermissionOrgsRequest.
                 * @implements IGetPermissionOrgsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetPermissionOrgsRequest=} [properties] Properties to set
                 */
                function GetPermissionOrgsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPermissionOrgsRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @instance
                 */
                GetPermissionOrgsRequest.prototype.uuid = "";
    
                /**
                 * Creates a new GetPermissionOrgsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetPermissionOrgsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetPermissionOrgsRequest} GetPermissionOrgsRequest instance
                 */
                GetPermissionOrgsRequest.create = function create(properties) {
                    return new GetPermissionOrgsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPermissionOrgsRequest message. Does not implicitly {@link infinitusai.auth.GetPermissionOrgsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetPermissionOrgsRequest} message GetPermissionOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPermissionOrgsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPermissionOrgsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetPermissionOrgsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetPermissionOrgsRequest} message GetPermissionOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPermissionOrgsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPermissionOrgsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetPermissionOrgsRequest} GetPermissionOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPermissionOrgsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetPermissionOrgsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPermissionOrgsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetPermissionOrgsRequest} GetPermissionOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPermissionOrgsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPermissionOrgsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPermissionOrgsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetPermissionOrgsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetPermissionOrgsRequest} GetPermissionOrgsRequest
                 */
                GetPermissionOrgsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetPermissionOrgsRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetPermissionOrgsRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPermissionOrgsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {infinitusai.auth.GetPermissionOrgsRequest} message GetPermissionOrgsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPermissionOrgsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this GetPermissionOrgsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPermissionOrgsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPermissionOrgsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetPermissionOrgsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPermissionOrgsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetPermissionOrgsRequest";
                };
    
                return GetPermissionOrgsRequest;
            })();
    
            auth.GetPermissionOrgsResponse = (function() {
    
                /**
                 * Properties of a GetPermissionOrgsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetPermissionOrgsResponse
                 * @property {Array.<infinitusai.orgs.IOrg>|null} [orgs] GetPermissionOrgsResponse orgs
                 */
    
                /**
                 * Constructs a new GetPermissionOrgsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetPermissionOrgsResponse.
                 * @implements IGetPermissionOrgsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetPermissionOrgsResponse=} [properties] Properties to set
                 */
                function GetPermissionOrgsResponse(properties) {
                    this.orgs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPermissionOrgsResponse orgs.
                 * @member {Array.<infinitusai.orgs.IOrg>} orgs
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @instance
                 */
                GetPermissionOrgsResponse.prototype.orgs = $util.emptyArray;
    
                /**
                 * Creates a new GetPermissionOrgsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetPermissionOrgsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetPermissionOrgsResponse} GetPermissionOrgsResponse instance
                 */
                GetPermissionOrgsResponse.create = function create(properties) {
                    return new GetPermissionOrgsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPermissionOrgsResponse message. Does not implicitly {@link infinitusai.auth.GetPermissionOrgsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetPermissionOrgsResponse} message GetPermissionOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPermissionOrgsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgs != null && message.orgs.length)
                        for (var i = 0; i < message.orgs.length; ++i)
                            $root.infinitusai.orgs.Org.encode(message.orgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPermissionOrgsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetPermissionOrgsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetPermissionOrgsResponse} message GetPermissionOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPermissionOrgsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPermissionOrgsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetPermissionOrgsResponse} GetPermissionOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPermissionOrgsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetPermissionOrgsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.orgs && message.orgs.length))
                                    message.orgs = [];
                                message.orgs.push($root.infinitusai.orgs.Org.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPermissionOrgsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetPermissionOrgsResponse} GetPermissionOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPermissionOrgsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPermissionOrgsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPermissionOrgsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgs != null && message.hasOwnProperty("orgs")) {
                        if (!Array.isArray(message.orgs))
                            return "orgs: array expected";
                        for (var i = 0; i < message.orgs.length; ++i) {
                            var error = $root.infinitusai.orgs.Org.verify(message.orgs[i]);
                            if (error)
                                return "orgs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPermissionOrgsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetPermissionOrgsResponse} GetPermissionOrgsResponse
                 */
                GetPermissionOrgsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetPermissionOrgsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetPermissionOrgsResponse();
                    if (object.orgs) {
                        if (!Array.isArray(object.orgs))
                            throw TypeError(".infinitusai.auth.GetPermissionOrgsResponse.orgs: array expected");
                        message.orgs = [];
                        for (var i = 0; i < object.orgs.length; ++i) {
                            if (typeof object.orgs[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetPermissionOrgsResponse.orgs: object expected");
                            message.orgs[i] = $root.infinitusai.orgs.Org.fromObject(object.orgs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPermissionOrgsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {infinitusai.auth.GetPermissionOrgsResponse} message GetPermissionOrgsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPermissionOrgsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgs = [];
                    if (message.orgs && message.orgs.length) {
                        object.orgs = [];
                        for (var j = 0; j < message.orgs.length; ++j)
                            object.orgs[j] = $root.infinitusai.orgs.Org.toObject(message.orgs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPermissionOrgsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPermissionOrgsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPermissionOrgsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetPermissionOrgsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPermissionOrgsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetPermissionOrgsResponse";
                };
    
                return GetPermissionOrgsResponse;
            })();
    
            auth.GetRolePermissionsRequest = (function() {
    
                /**
                 * Properties of a GetRolePermissionsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetRolePermissionsRequest
                 * @property {string|null} [roleUuid] GetRolePermissionsRequest roleUuid
                 */
    
                /**
                 * Constructs a new GetRolePermissionsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRolePermissionsRequest.
                 * @implements IGetRolePermissionsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetRolePermissionsRequest=} [properties] Properties to set
                 */
                function GetRolePermissionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRolePermissionsRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @instance
                 */
                GetRolePermissionsRequest.prototype.roleUuid = "";
    
                /**
                 * Creates a new GetRolePermissionsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetRolePermissionsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRolePermissionsRequest} GetRolePermissionsRequest instance
                 */
                GetRolePermissionsRequest.create = function create(properties) {
                    return new GetRolePermissionsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetRolePermissionsRequest message. Does not implicitly {@link infinitusai.auth.GetRolePermissionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetRolePermissionsRequest} message GetRolePermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRolePermissionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRolePermissionsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetRolePermissionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetRolePermissionsRequest} message GetRolePermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRolePermissionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRolePermissionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRolePermissionsRequest} GetRolePermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRolePermissionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRolePermissionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRolePermissionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRolePermissionsRequest} GetRolePermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRolePermissionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRolePermissionsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRolePermissionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetRolePermissionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRolePermissionsRequest} GetRolePermissionsRequest
                 */
                GetRolePermissionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRolePermissionsRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetRolePermissionsRequest();
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRolePermissionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {infinitusai.auth.GetRolePermissionsRequest} message GetRolePermissionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRolePermissionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.roleUuid = "";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    return object;
                };
    
                /**
                 * Converts this GetRolePermissionsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRolePermissionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRolePermissionsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRolePermissionsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRolePermissionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRolePermissionsRequest";
                };
    
                return GetRolePermissionsRequest;
            })();
    
            auth.GetRolePermissionsResponse = (function() {
    
                /**
                 * Properties of a GetRolePermissionsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetRolePermissionsResponse
                 * @property {Array.<infinitusai.auth.IPermission>|null} [permissions] GetRolePermissionsResponse permissions
                 */
    
                /**
                 * Constructs a new GetRolePermissionsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRolePermissionsResponse.
                 * @implements IGetRolePermissionsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetRolePermissionsResponse=} [properties] Properties to set
                 */
                function GetRolePermissionsResponse(properties) {
                    this.permissions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRolePermissionsResponse permissions.
                 * @member {Array.<infinitusai.auth.IPermission>} permissions
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @instance
                 */
                GetRolePermissionsResponse.prototype.permissions = $util.emptyArray;
    
                /**
                 * Creates a new GetRolePermissionsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetRolePermissionsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRolePermissionsResponse} GetRolePermissionsResponse instance
                 */
                GetRolePermissionsResponse.create = function create(properties) {
                    return new GetRolePermissionsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetRolePermissionsResponse message. Does not implicitly {@link infinitusai.auth.GetRolePermissionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetRolePermissionsResponse} message GetRolePermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRolePermissionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.permissions != null && message.permissions.length)
                        for (var i = 0; i < message.permissions.length; ++i)
                            $root.infinitusai.auth.Permission.encode(message.permissions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRolePermissionsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetRolePermissionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetRolePermissionsResponse} message GetRolePermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRolePermissionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRolePermissionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRolePermissionsResponse} GetRolePermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRolePermissionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRolePermissionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.permissions && message.permissions.length))
                                    message.permissions = [];
                                message.permissions.push($root.infinitusai.auth.Permission.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRolePermissionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRolePermissionsResponse} GetRolePermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRolePermissionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRolePermissionsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRolePermissionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.permissions != null && message.hasOwnProperty("permissions")) {
                        if (!Array.isArray(message.permissions))
                            return "permissions: array expected";
                        for (var i = 0; i < message.permissions.length; ++i) {
                            var error = $root.infinitusai.auth.Permission.verify(message.permissions[i]);
                            if (error)
                                return "permissions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetRolePermissionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRolePermissionsResponse} GetRolePermissionsResponse
                 */
                GetRolePermissionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRolePermissionsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetRolePermissionsResponse();
                    if (object.permissions) {
                        if (!Array.isArray(object.permissions))
                            throw TypeError(".infinitusai.auth.GetRolePermissionsResponse.permissions: array expected");
                        message.permissions = [];
                        for (var i = 0; i < object.permissions.length; ++i) {
                            if (typeof object.permissions[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetRolePermissionsResponse.permissions: object expected");
                            message.permissions[i] = $root.infinitusai.auth.Permission.fromObject(object.permissions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRolePermissionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {infinitusai.auth.GetRolePermissionsResponse} message GetRolePermissionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRolePermissionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.permissions = [];
                    if (message.permissions && message.permissions.length) {
                        object.permissions = [];
                        for (var j = 0; j < message.permissions.length; ++j)
                            object.permissions[j] = $root.infinitusai.auth.Permission.toObject(message.permissions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetRolePermissionsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRolePermissionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRolePermissionsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRolePermissionsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRolePermissionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRolePermissionsResponse";
                };
    
                return GetRolePermissionsResponse;
            })();
    
            auth.GetRoleOrgsRequest = (function() {
    
                /**
                 * Properties of a GetRoleOrgsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetRoleOrgsRequest
                 * @property {string|null} [roleUuid] GetRoleOrgsRequest roleUuid
                 */
    
                /**
                 * Constructs a new GetRoleOrgsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRoleOrgsRequest.
                 * @implements IGetRoleOrgsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetRoleOrgsRequest=} [properties] Properties to set
                 */
                function GetRoleOrgsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRoleOrgsRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @instance
                 */
                GetRoleOrgsRequest.prototype.roleUuid = "";
    
                /**
                 * Creates a new GetRoleOrgsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleOrgsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRoleOrgsRequest} GetRoleOrgsRequest instance
                 */
                GetRoleOrgsRequest.create = function create(properties) {
                    return new GetRoleOrgsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetRoleOrgsRequest message. Does not implicitly {@link infinitusai.auth.GetRoleOrgsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleOrgsRequest} message GetRoleOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleOrgsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRoleOrgsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetRoleOrgsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleOrgsRequest} message GetRoleOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleOrgsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRoleOrgsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRoleOrgsRequest} GetRoleOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleOrgsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRoleOrgsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRoleOrgsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRoleOrgsRequest} GetRoleOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleOrgsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRoleOrgsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRoleOrgsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetRoleOrgsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRoleOrgsRequest} GetRoleOrgsRequest
                 */
                GetRoleOrgsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRoleOrgsRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetRoleOrgsRequest();
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRoleOrgsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {infinitusai.auth.GetRoleOrgsRequest} message GetRoleOrgsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRoleOrgsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.roleUuid = "";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    return object;
                };
    
                /**
                 * Converts this GetRoleOrgsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRoleOrgsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRoleOrgsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRoleOrgsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRoleOrgsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRoleOrgsRequest";
                };
    
                return GetRoleOrgsRequest;
            })();
    
            auth.GetRoleOrgsResponse = (function() {
    
                /**
                 * Properties of a GetRoleOrgsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetRoleOrgsResponse
                 * @property {Array.<infinitusai.orgs.IOrg>|null} [orgs] GetRoleOrgsResponse orgs
                 */
    
                /**
                 * Constructs a new GetRoleOrgsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRoleOrgsResponse.
                 * @implements IGetRoleOrgsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetRoleOrgsResponse=} [properties] Properties to set
                 */
                function GetRoleOrgsResponse(properties) {
                    this.orgs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRoleOrgsResponse orgs.
                 * @member {Array.<infinitusai.orgs.IOrg>} orgs
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @instance
                 */
                GetRoleOrgsResponse.prototype.orgs = $util.emptyArray;
    
                /**
                 * Creates a new GetRoleOrgsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleOrgsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRoleOrgsResponse} GetRoleOrgsResponse instance
                 */
                GetRoleOrgsResponse.create = function create(properties) {
                    return new GetRoleOrgsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetRoleOrgsResponse message. Does not implicitly {@link infinitusai.auth.GetRoleOrgsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleOrgsResponse} message GetRoleOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleOrgsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgs != null && message.orgs.length)
                        for (var i = 0; i < message.orgs.length; ++i)
                            $root.infinitusai.orgs.Org.encode(message.orgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRoleOrgsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetRoleOrgsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleOrgsResponse} message GetRoleOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleOrgsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRoleOrgsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRoleOrgsResponse} GetRoleOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleOrgsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRoleOrgsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.orgs && message.orgs.length))
                                    message.orgs = [];
                                message.orgs.push($root.infinitusai.orgs.Org.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRoleOrgsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRoleOrgsResponse} GetRoleOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleOrgsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRoleOrgsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRoleOrgsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgs != null && message.hasOwnProperty("orgs")) {
                        if (!Array.isArray(message.orgs))
                            return "orgs: array expected";
                        for (var i = 0; i < message.orgs.length; ++i) {
                            var error = $root.infinitusai.orgs.Org.verify(message.orgs[i]);
                            if (error)
                                return "orgs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetRoleOrgsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRoleOrgsResponse} GetRoleOrgsResponse
                 */
                GetRoleOrgsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRoleOrgsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetRoleOrgsResponse();
                    if (object.orgs) {
                        if (!Array.isArray(object.orgs))
                            throw TypeError(".infinitusai.auth.GetRoleOrgsResponse.orgs: array expected");
                        message.orgs = [];
                        for (var i = 0; i < object.orgs.length; ++i) {
                            if (typeof object.orgs[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetRoleOrgsResponse.orgs: object expected");
                            message.orgs[i] = $root.infinitusai.orgs.Org.fromObject(object.orgs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRoleOrgsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {infinitusai.auth.GetRoleOrgsResponse} message GetRoleOrgsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRoleOrgsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgs = [];
                    if (message.orgs && message.orgs.length) {
                        object.orgs = [];
                        for (var j = 0; j < message.orgs.length; ++j)
                            object.orgs[j] = $root.infinitusai.orgs.Org.toObject(message.orgs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetRoleOrgsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRoleOrgsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRoleOrgsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRoleOrgsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRoleOrgsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRoleOrgsResponse";
                };
    
                return GetRoleOrgsResponse;
            })();
    
            auth.GetUserPermissionsRequest = (function() {
    
                /**
                 * Properties of a GetUserPermissionsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetUserPermissionsRequest
                 * @property {string|null} [userUid] GetUserPermissionsRequest userUid
                 */
    
                /**
                 * Constructs a new GetUserPermissionsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUserPermissionsRequest.
                 * @implements IGetUserPermissionsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetUserPermissionsRequest=} [properties] Properties to set
                 */
                function GetUserPermissionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserPermissionsRequest userUid.
                 * @member {string} userUid
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @instance
                 */
                GetUserPermissionsRequest.prototype.userUid = "";
    
                /**
                 * Creates a new GetUserPermissionsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserPermissionsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUserPermissionsRequest} GetUserPermissionsRequest instance
                 */
                GetUserPermissionsRequest.create = function create(properties) {
                    return new GetUserPermissionsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetUserPermissionsRequest message. Does not implicitly {@link infinitusai.auth.GetUserPermissionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserPermissionsRequest} message GetUserPermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserPermissionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserPermissionsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetUserPermissionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserPermissionsRequest} message GetUserPermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserPermissionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserPermissionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUserPermissionsRequest} GetUserPermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserPermissionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUserPermissionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userUid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserPermissionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUserPermissionsRequest} GetUserPermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserPermissionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserPermissionsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserPermissionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        if (!$util.isString(message.userUid))
                            return "userUid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetUserPermissionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUserPermissionsRequest} GetUserPermissionsRequest
                 */
                GetUserPermissionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUserPermissionsRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetUserPermissionsRequest();
                    if (object.userUid != null)
                        message.userUid = String(object.userUid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserPermissionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.GetUserPermissionsRequest} message GetUserPermissionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserPermissionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userUid = "";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        object.userUid = message.userUid;
                    return object;
                };
    
                /**
                 * Converts this GetUserPermissionsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserPermissionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserPermissionsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUserPermissionsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserPermissionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUserPermissionsRequest";
                };
    
                return GetUserPermissionsRequest;
            })();
    
            auth.GetUserPermissionsResponse = (function() {
    
                /**
                 * Properties of a GetUserPermissionsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetUserPermissionsResponse
                 * @property {Array.<infinitusai.auth.IPermission>|null} [permissions] GetUserPermissionsResponse permissions
                 */
    
                /**
                 * Constructs a new GetUserPermissionsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUserPermissionsResponse.
                 * @implements IGetUserPermissionsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetUserPermissionsResponse=} [properties] Properties to set
                 */
                function GetUserPermissionsResponse(properties) {
                    this.permissions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserPermissionsResponse permissions.
                 * @member {Array.<infinitusai.auth.IPermission>} permissions
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @instance
                 */
                GetUserPermissionsResponse.prototype.permissions = $util.emptyArray;
    
                /**
                 * Creates a new GetUserPermissionsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserPermissionsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUserPermissionsResponse} GetUserPermissionsResponse instance
                 */
                GetUserPermissionsResponse.create = function create(properties) {
                    return new GetUserPermissionsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetUserPermissionsResponse message. Does not implicitly {@link infinitusai.auth.GetUserPermissionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserPermissionsResponse} message GetUserPermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserPermissionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.permissions != null && message.permissions.length)
                        for (var i = 0; i < message.permissions.length; ++i)
                            $root.infinitusai.auth.Permission.encode(message.permissions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserPermissionsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetUserPermissionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserPermissionsResponse} message GetUserPermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserPermissionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserPermissionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUserPermissionsResponse} GetUserPermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserPermissionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUserPermissionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.permissions && message.permissions.length))
                                    message.permissions = [];
                                message.permissions.push($root.infinitusai.auth.Permission.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserPermissionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUserPermissionsResponse} GetUserPermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserPermissionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserPermissionsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserPermissionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.permissions != null && message.hasOwnProperty("permissions")) {
                        if (!Array.isArray(message.permissions))
                            return "permissions: array expected";
                        for (var i = 0; i < message.permissions.length; ++i) {
                            var error = $root.infinitusai.auth.Permission.verify(message.permissions[i]);
                            if (error)
                                return "permissions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetUserPermissionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUserPermissionsResponse} GetUserPermissionsResponse
                 */
                GetUserPermissionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUserPermissionsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetUserPermissionsResponse();
                    if (object.permissions) {
                        if (!Array.isArray(object.permissions))
                            throw TypeError(".infinitusai.auth.GetUserPermissionsResponse.permissions: array expected");
                        message.permissions = [];
                        for (var i = 0; i < object.permissions.length; ++i) {
                            if (typeof object.permissions[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetUserPermissionsResponse.permissions: object expected");
                            message.permissions[i] = $root.infinitusai.auth.Permission.fromObject(object.permissions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserPermissionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.GetUserPermissionsResponse} message GetUserPermissionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserPermissionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.permissions = [];
                    if (message.permissions && message.permissions.length) {
                        object.permissions = [];
                        for (var j = 0; j < message.permissions.length; ++j)
                            object.permissions[j] = $root.infinitusai.auth.Permission.toObject(message.permissions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetUserPermissionsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserPermissionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserPermissionsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUserPermissionsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserPermissionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUserPermissionsResponse";
                };
    
                return GetUserPermissionsResponse;
            })();
    
            auth.GetOrgPermissionsRequest = (function() {
    
                /**
                 * Properties of a GetOrgPermissionsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgPermissionsRequest
                 * @property {google.protobuf.IBoolValue|null} [readOnly] GetOrgPermissionsRequest readOnly
                 * @property {Array.<infinitusai.auth.PermissionType>|null} [types] GetOrgPermissionsRequest types
                 */
    
                /**
                 * Constructs a new GetOrgPermissionsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgPermissionsRequest.
                 * @implements IGetOrgPermissionsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgPermissionsRequest=} [properties] Properties to set
                 */
                function GetOrgPermissionsRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgPermissionsRequest readOnly.
                 * @member {google.protobuf.IBoolValue|null|undefined} readOnly
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @instance
                 */
                GetOrgPermissionsRequest.prototype.readOnly = null;
    
                /**
                 * GetOrgPermissionsRequest types.
                 * @member {Array.<infinitusai.auth.PermissionType>} types
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @instance
                 */
                GetOrgPermissionsRequest.prototype.types = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgPermissionsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgPermissionsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgPermissionsRequest} GetOrgPermissionsRequest instance
                 */
                GetOrgPermissionsRequest.create = function create(properties) {
                    return new GetOrgPermissionsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgPermissionsRequest message. Does not implicitly {@link infinitusai.auth.GetOrgPermissionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgPermissionsRequest} message GetOrgPermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgPermissionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                        $root.google.protobuf.BoolValue.encode(message.readOnly, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgPermissionsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgPermissionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgPermissionsRequest} message GetOrgPermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgPermissionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgPermissionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgPermissionsRequest} GetOrgPermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgPermissionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgPermissionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.readOnly = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgPermissionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgPermissionsRequest} GetOrgPermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgPermissionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgPermissionsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgPermissionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.readOnly != null && message.hasOwnProperty("readOnly")) {
                        var error = $root.google.protobuf.BoolValue.verify(message.readOnly);
                        if (error)
                            return "readOnly." + error;
                    }
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgPermissionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgPermissionsRequest} GetOrgPermissionsRequest
                 */
                GetOrgPermissionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgPermissionsRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgPermissionsRequest();
                    if (object.readOnly != null) {
                        if (typeof object.readOnly !== "object")
                            throw TypeError(".infinitusai.auth.GetOrgPermissionsRequest.readOnly: object expected");
                        message.readOnly = $root.google.protobuf.BoolValue.fromObject(object.readOnly);
                    }
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetOrgPermissionsRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "PERMISSION_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "PERMISSION_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "PERMISSION_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "PERMISSION_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgPermissionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.GetOrgPermissionsRequest} message GetOrgPermissionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgPermissionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (options.defaults)
                        object.readOnly = null;
                    if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                        object.readOnly = $root.google.protobuf.BoolValue.toObject(message.readOnly, options);
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.PermissionType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.PermissionType[message.types[j]] : message.types[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgPermissionsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgPermissionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgPermissionsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgPermissionsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgPermissionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgPermissionsRequest";
                };
    
                return GetOrgPermissionsRequest;
            })();
    
            auth.GetOrgPermissionsResponse = (function() {
    
                /**
                 * Properties of a GetOrgPermissionsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgPermissionsResponse
                 * @property {Array.<infinitusai.auth.IPermission>|null} [permissions] GetOrgPermissionsResponse permissions
                 */
    
                /**
                 * Constructs a new GetOrgPermissionsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgPermissionsResponse.
                 * @implements IGetOrgPermissionsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgPermissionsResponse=} [properties] Properties to set
                 */
                function GetOrgPermissionsResponse(properties) {
                    this.permissions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgPermissionsResponse permissions.
                 * @member {Array.<infinitusai.auth.IPermission>} permissions
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @instance
                 */
                GetOrgPermissionsResponse.prototype.permissions = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgPermissionsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgPermissionsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgPermissionsResponse} GetOrgPermissionsResponse instance
                 */
                GetOrgPermissionsResponse.create = function create(properties) {
                    return new GetOrgPermissionsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgPermissionsResponse message. Does not implicitly {@link infinitusai.auth.GetOrgPermissionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgPermissionsResponse} message GetOrgPermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgPermissionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.permissions != null && message.permissions.length)
                        for (var i = 0; i < message.permissions.length; ++i)
                            $root.infinitusai.auth.Permission.encode(message.permissions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgPermissionsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgPermissionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgPermissionsResponse} message GetOrgPermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgPermissionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgPermissionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgPermissionsResponse} GetOrgPermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgPermissionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgPermissionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.permissions && message.permissions.length))
                                    message.permissions = [];
                                message.permissions.push($root.infinitusai.auth.Permission.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgPermissionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgPermissionsResponse} GetOrgPermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgPermissionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgPermissionsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgPermissionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.permissions != null && message.hasOwnProperty("permissions")) {
                        if (!Array.isArray(message.permissions))
                            return "permissions: array expected";
                        for (var i = 0; i < message.permissions.length; ++i) {
                            var error = $root.infinitusai.auth.Permission.verify(message.permissions[i]);
                            if (error)
                                return "permissions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgPermissionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgPermissionsResponse} GetOrgPermissionsResponse
                 */
                GetOrgPermissionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgPermissionsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgPermissionsResponse();
                    if (object.permissions) {
                        if (!Array.isArray(object.permissions))
                            throw TypeError(".infinitusai.auth.GetOrgPermissionsResponse.permissions: array expected");
                        message.permissions = [];
                        for (var i = 0; i < object.permissions.length; ++i) {
                            if (typeof object.permissions[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetOrgPermissionsResponse.permissions: object expected");
                            message.permissions[i] = $root.infinitusai.auth.Permission.fromObject(object.permissions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgPermissionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.GetOrgPermissionsResponse} message GetOrgPermissionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgPermissionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.permissions = [];
                    if (message.permissions && message.permissions.length) {
                        object.permissions = [];
                        for (var j = 0; j < message.permissions.length; ++j)
                            object.permissions[j] = $root.infinitusai.auth.Permission.toObject(message.permissions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgPermissionsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgPermissionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgPermissionsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgPermissionsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgPermissionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgPermissionsResponse";
                };
    
                return GetOrgPermissionsResponse;
            })();
    
            auth.GetMyOrgsRequest = (function() {
    
                /**
                 * Properties of a GetMyOrgsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetMyOrgsRequest
                 */
    
                /**
                 * Constructs a new GetMyOrgsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetMyOrgsRequest.
                 * @implements IGetMyOrgsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetMyOrgsRequest=} [properties] Properties to set
                 */
                function GetMyOrgsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetMyOrgsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyOrgsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetMyOrgsRequest} GetMyOrgsRequest instance
                 */
                GetMyOrgsRequest.create = function create(properties) {
                    return new GetMyOrgsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetMyOrgsRequest message. Does not implicitly {@link infinitusai.auth.GetMyOrgsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyOrgsRequest} message GetMyOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyOrgsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyOrgsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetMyOrgsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyOrgsRequest} message GetMyOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyOrgsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyOrgsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetMyOrgsRequest} GetMyOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyOrgsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetMyOrgsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyOrgsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetMyOrgsRequest} GetMyOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyOrgsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyOrgsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyOrgsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetMyOrgsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetMyOrgsRequest} GetMyOrgsRequest
                 */
                GetMyOrgsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetMyOrgsRequest)
                        return object;
                    return new $root.infinitusai.auth.GetMyOrgsRequest();
                };
    
                /**
                 * Creates a plain object from a GetMyOrgsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {infinitusai.auth.GetMyOrgsRequest} message GetMyOrgsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyOrgsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetMyOrgsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyOrgsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyOrgsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetMyOrgsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyOrgsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetMyOrgsRequest";
                };
    
                return GetMyOrgsRequest;
            })();
    
            auth.GetMyOrgsResponse = (function() {
    
                /**
                 * Properties of a GetMyOrgsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetMyOrgsResponse
                 * @property {Array.<infinitusai.orgs.IOrg>|null} [orgs] GetMyOrgsResponse orgs
                 */
    
                /**
                 * Constructs a new GetMyOrgsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetMyOrgsResponse.
                 * @implements IGetMyOrgsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetMyOrgsResponse=} [properties] Properties to set
                 */
                function GetMyOrgsResponse(properties) {
                    this.orgs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyOrgsResponse orgs.
                 * @member {Array.<infinitusai.orgs.IOrg>} orgs
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @instance
                 */
                GetMyOrgsResponse.prototype.orgs = $util.emptyArray;
    
                /**
                 * Creates a new GetMyOrgsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyOrgsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetMyOrgsResponse} GetMyOrgsResponse instance
                 */
                GetMyOrgsResponse.create = function create(properties) {
                    return new GetMyOrgsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetMyOrgsResponse message. Does not implicitly {@link infinitusai.auth.GetMyOrgsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyOrgsResponse} message GetMyOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyOrgsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgs != null && message.orgs.length)
                        for (var i = 0; i < message.orgs.length; ++i)
                            $root.infinitusai.orgs.Org.encode(message.orgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyOrgsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetMyOrgsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyOrgsResponse} message GetMyOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyOrgsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyOrgsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetMyOrgsResponse} GetMyOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyOrgsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetMyOrgsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.orgs && message.orgs.length))
                                    message.orgs = [];
                                message.orgs.push($root.infinitusai.orgs.Org.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyOrgsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetMyOrgsResponse} GetMyOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyOrgsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyOrgsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyOrgsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgs != null && message.hasOwnProperty("orgs")) {
                        if (!Array.isArray(message.orgs))
                            return "orgs: array expected";
                        for (var i = 0; i < message.orgs.length; ++i) {
                            var error = $root.infinitusai.orgs.Org.verify(message.orgs[i]);
                            if (error)
                                return "orgs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetMyOrgsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetMyOrgsResponse} GetMyOrgsResponse
                 */
                GetMyOrgsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetMyOrgsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetMyOrgsResponse();
                    if (object.orgs) {
                        if (!Array.isArray(object.orgs))
                            throw TypeError(".infinitusai.auth.GetMyOrgsResponse.orgs: array expected");
                        message.orgs = [];
                        for (var i = 0; i < object.orgs.length; ++i) {
                            if (typeof object.orgs[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetMyOrgsResponse.orgs: object expected");
                            message.orgs[i] = $root.infinitusai.orgs.Org.fromObject(object.orgs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyOrgsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {infinitusai.auth.GetMyOrgsResponse} message GetMyOrgsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyOrgsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgs = [];
                    if (message.orgs && message.orgs.length) {
                        object.orgs = [];
                        for (var j = 0; j < message.orgs.length; ++j)
                            object.orgs[j] = $root.infinitusai.orgs.Org.toObject(message.orgs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetMyOrgsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyOrgsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyOrgsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetMyOrgsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyOrgsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetMyOrgsResponse";
                };
    
                return GetMyOrgsResponse;
            })();
    
            auth.GetOrgsRequest = (function() {
    
                /**
                 * Properties of a GetOrgsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgsRequest
                 */
    
                /**
                 * Constructs a new GetOrgsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgsRequest.
                 * @implements IGetOrgsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgsRequest=} [properties] Properties to set
                 */
                function GetOrgsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetOrgsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgsRequest} GetOrgsRequest instance
                 */
                GetOrgsRequest.create = function create(properties) {
                    return new GetOrgsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgsRequest message. Does not implicitly {@link infinitusai.auth.GetOrgsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgsRequest} message GetOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgsRequest} message GetOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgsRequest} GetOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgsRequest} GetOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetOrgsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgsRequest} GetOrgsRequest
                 */
                GetOrgsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgsRequest)
                        return object;
                    return new $root.infinitusai.auth.GetOrgsRequest();
                };
    
                /**
                 * Creates a plain object from a GetOrgsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {infinitusai.auth.GetOrgsRequest} message GetOrgsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetOrgsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgsRequest";
                };
    
                return GetOrgsRequest;
            })();
    
            auth.GetOrgsResponse = (function() {
    
                /**
                 * Properties of a GetOrgsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgsResponse
                 * @property {Array.<infinitusai.orgs.IOrg>|null} [orgs] GetOrgsResponse orgs
                 */
    
                /**
                 * Constructs a new GetOrgsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgsResponse.
                 * @implements IGetOrgsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgsResponse=} [properties] Properties to set
                 */
                function GetOrgsResponse(properties) {
                    this.orgs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgsResponse orgs.
                 * @member {Array.<infinitusai.orgs.IOrg>} orgs
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @instance
                 */
                GetOrgsResponse.prototype.orgs = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgsResponse} GetOrgsResponse instance
                 */
                GetOrgsResponse.create = function create(properties) {
                    return new GetOrgsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgsResponse message. Does not implicitly {@link infinitusai.auth.GetOrgsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgsResponse} message GetOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgs != null && message.orgs.length)
                        for (var i = 0; i < message.orgs.length; ++i)
                            $root.infinitusai.orgs.Org.encode(message.orgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgsResponse} message GetOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgsResponse} GetOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.orgs && message.orgs.length))
                                    message.orgs = [];
                                message.orgs.push($root.infinitusai.orgs.Org.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgsResponse} GetOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgs != null && message.hasOwnProperty("orgs")) {
                        if (!Array.isArray(message.orgs))
                            return "orgs: array expected";
                        for (var i = 0; i < message.orgs.length; ++i) {
                            var error = $root.infinitusai.orgs.Org.verify(message.orgs[i]);
                            if (error)
                                return "orgs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgsResponse} GetOrgsResponse
                 */
                GetOrgsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgsResponse();
                    if (object.orgs) {
                        if (!Array.isArray(object.orgs))
                            throw TypeError(".infinitusai.auth.GetOrgsResponse.orgs: array expected");
                        message.orgs = [];
                        for (var i = 0; i < object.orgs.length; ++i) {
                            if (typeof object.orgs[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetOrgsResponse.orgs: object expected");
                            message.orgs[i] = $root.infinitusai.orgs.Org.fromObject(object.orgs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {infinitusai.auth.GetOrgsResponse} message GetOrgsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgs = [];
                    if (message.orgs && message.orgs.length) {
                        object.orgs = [];
                        for (var j = 0; j < message.orgs.length; ++j)
                            object.orgs[j] = $root.infinitusai.orgs.Org.toObject(message.orgs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgsResponse";
                };
    
                return GetOrgsResponse;
            })();
    
            auth.CreateOrUpdateInviteRequest = (function() {
    
                /**
                 * Properties of a CreateOrUpdateInviteRequest.
                 * @memberof infinitusai.auth
                 * @interface ICreateOrUpdateInviteRequest
                 * @property {string|null} [inviteeEmail] CreateOrUpdateInviteRequest inviteeEmail
                 * @property {Array.<string>|null} [roleUuids] CreateOrUpdateInviteRequest roleUuids
                 * @property {infinitusai.auth.InviteType|null} [type] CreateOrUpdateInviteRequest type
                 * @property {string|null} [inviteBaseUrl] CreateOrUpdateInviteRequest inviteBaseUrl
                 */
    
                /**
                 * Constructs a new CreateOrUpdateInviteRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateOrUpdateInviteRequest.
                 * @implements ICreateOrUpdateInviteRequest
                 * @constructor
                 * @param {infinitusai.auth.ICreateOrUpdateInviteRequest=} [properties] Properties to set
                 */
                function CreateOrUpdateInviteRequest(properties) {
                    this.roleUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateOrUpdateInviteRequest inviteeEmail.
                 * @member {string} inviteeEmail
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @instance
                 */
                CreateOrUpdateInviteRequest.prototype.inviteeEmail = "";
    
                /**
                 * CreateOrUpdateInviteRequest roleUuids.
                 * @member {Array.<string>} roleUuids
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @instance
                 */
                CreateOrUpdateInviteRequest.prototype.roleUuids = $util.emptyArray;
    
                /**
                 * CreateOrUpdateInviteRequest type.
                 * @member {infinitusai.auth.InviteType} type
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @instance
                 */
                CreateOrUpdateInviteRequest.prototype.type = 0;
    
                /**
                 * CreateOrUpdateInviteRequest inviteBaseUrl.
                 * @member {string} inviteBaseUrl
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @instance
                 */
                CreateOrUpdateInviteRequest.prototype.inviteBaseUrl = "";
    
                /**
                 * Creates a new CreateOrUpdateInviteRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {infinitusai.auth.ICreateOrUpdateInviteRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateOrUpdateInviteRequest} CreateOrUpdateInviteRequest instance
                 */
                CreateOrUpdateInviteRequest.create = function create(properties) {
                    return new CreateOrUpdateInviteRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateOrUpdateInviteRequest message. Does not implicitly {@link infinitusai.auth.CreateOrUpdateInviteRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {infinitusai.auth.ICreateOrUpdateInviteRequest} message CreateOrUpdateInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateOrUpdateInviteRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inviteeEmail != null && Object.hasOwnProperty.call(message, "inviteeEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteeEmail);
                    if (message.roleUuids != null && message.roleUuids.length)
                        for (var i = 0; i < message.roleUuids.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.roleUuids[i]);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                    if (message.inviteBaseUrl != null && Object.hasOwnProperty.call(message, "inviteBaseUrl"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.inviteBaseUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateOrUpdateInviteRequest message, length delimited. Does not implicitly {@link infinitusai.auth.CreateOrUpdateInviteRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {infinitusai.auth.ICreateOrUpdateInviteRequest} message CreateOrUpdateInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateOrUpdateInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateOrUpdateInviteRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateOrUpdateInviteRequest} CreateOrUpdateInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateOrUpdateInviteRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateOrUpdateInviteRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inviteeEmail = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.roleUuids && message.roleUuids.length))
                                    message.roleUuids = [];
                                message.roleUuids.push(reader.string());
                                break;
                            }
                        case 3: {
                                message.type = reader.int32();
                                break;
                            }
                        case 4: {
                                message.inviteBaseUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateOrUpdateInviteRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateOrUpdateInviteRequest} CreateOrUpdateInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateOrUpdateInviteRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateOrUpdateInviteRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateOrUpdateInviteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inviteeEmail != null && message.hasOwnProperty("inviteeEmail"))
                        if (!$util.isString(message.inviteeEmail))
                            return "inviteeEmail: string expected";
                    if (message.roleUuids != null && message.hasOwnProperty("roleUuids")) {
                        if (!Array.isArray(message.roleUuids))
                            return "roleUuids: array expected";
                        for (var i = 0; i < message.roleUuids.length; ++i)
                            if (!$util.isString(message.roleUuids[i]))
                                return "roleUuids: string[] expected";
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.inviteBaseUrl != null && message.hasOwnProperty("inviteBaseUrl"))
                        if (!$util.isString(message.inviteBaseUrl))
                            return "inviteBaseUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateOrUpdateInviteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateOrUpdateInviteRequest} CreateOrUpdateInviteRequest
                 */
                CreateOrUpdateInviteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateOrUpdateInviteRequest)
                        return object;
                    var message = new $root.infinitusai.auth.CreateOrUpdateInviteRequest();
                    if (object.inviteeEmail != null)
                        message.inviteeEmail = String(object.inviteeEmail);
                    if (object.roleUuids) {
                        if (!Array.isArray(object.roleUuids))
                            throw TypeError(".infinitusai.auth.CreateOrUpdateInviteRequest.roleUuids: array expected");
                        message.roleUuids = [];
                        for (var i = 0; i < object.roleUuids.length; ++i)
                            message.roleUuids[i] = String(object.roleUuids[i]);
                    }
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "INVITE_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "INVITE_TYPE_ADMIN":
                    case 1:
                        message.type = 1;
                        break;
                    case "INVITE_TYPE_OPERATOR":
                    case 2:
                        message.type = 2;
                        break;
                    case "INVITE_TYPE_CUSTOMER":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.inviteBaseUrl != null)
                        message.inviteBaseUrl = String(object.inviteBaseUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateOrUpdateInviteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {infinitusai.auth.CreateOrUpdateInviteRequest} message CreateOrUpdateInviteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateOrUpdateInviteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.roleUuids = [];
                    if (options.defaults) {
                        object.inviteeEmail = "";
                        object.type = options.enums === String ? "INVITE_TYPE_UNKNOWN" : 0;
                        object.inviteBaseUrl = "";
                    }
                    if (message.inviteeEmail != null && message.hasOwnProperty("inviteeEmail"))
                        object.inviteeEmail = message.inviteeEmail;
                    if (message.roleUuids && message.roleUuids.length) {
                        object.roleUuids = [];
                        for (var j = 0; j < message.roleUuids.length; ++j)
                            object.roleUuids[j] = message.roleUuids[j];
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.auth.InviteType[message.type] === undefined ? message.type : $root.infinitusai.auth.InviteType[message.type] : message.type;
                    if (message.inviteBaseUrl != null && message.hasOwnProperty("inviteBaseUrl"))
                        object.inviteBaseUrl = message.inviteBaseUrl;
                    return object;
                };
    
                /**
                 * Converts this CreateOrUpdateInviteRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateOrUpdateInviteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateOrUpdateInviteRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateOrUpdateInviteRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateOrUpdateInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateOrUpdateInviteRequest";
                };
    
                return CreateOrUpdateInviteRequest;
            })();
    
            auth.CreateOrUpdateInviteResponse = (function() {
    
                /**
                 * Properties of a CreateOrUpdateInviteResponse.
                 * @memberof infinitusai.auth
                 * @interface ICreateOrUpdateInviteResponse
                 * @property {infinitusai.auth.IInvite|null} [invite] CreateOrUpdateInviteResponse invite
                 */
    
                /**
                 * Constructs a new CreateOrUpdateInviteResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateOrUpdateInviteResponse.
                 * @implements ICreateOrUpdateInviteResponse
                 * @constructor
                 * @param {infinitusai.auth.ICreateOrUpdateInviteResponse=} [properties] Properties to set
                 */
                function CreateOrUpdateInviteResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateOrUpdateInviteResponse invite.
                 * @member {infinitusai.auth.IInvite|null|undefined} invite
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @instance
                 */
                CreateOrUpdateInviteResponse.prototype.invite = null;
    
                /**
                 * Creates a new CreateOrUpdateInviteResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {infinitusai.auth.ICreateOrUpdateInviteResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateOrUpdateInviteResponse} CreateOrUpdateInviteResponse instance
                 */
                CreateOrUpdateInviteResponse.create = function create(properties) {
                    return new CreateOrUpdateInviteResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateOrUpdateInviteResponse message. Does not implicitly {@link infinitusai.auth.CreateOrUpdateInviteResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {infinitusai.auth.ICreateOrUpdateInviteResponse} message CreateOrUpdateInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateOrUpdateInviteResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                        $root.infinitusai.auth.Invite.encode(message.invite, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateOrUpdateInviteResponse message, length delimited. Does not implicitly {@link infinitusai.auth.CreateOrUpdateInviteResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {infinitusai.auth.ICreateOrUpdateInviteResponse} message CreateOrUpdateInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateOrUpdateInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateOrUpdateInviteResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateOrUpdateInviteResponse} CreateOrUpdateInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateOrUpdateInviteResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateOrUpdateInviteResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.invite = $root.infinitusai.auth.Invite.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateOrUpdateInviteResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateOrUpdateInviteResponse} CreateOrUpdateInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateOrUpdateInviteResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateOrUpdateInviteResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateOrUpdateInviteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invite != null && message.hasOwnProperty("invite")) {
                        var error = $root.infinitusai.auth.Invite.verify(message.invite);
                        if (error)
                            return "invite." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateOrUpdateInviteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateOrUpdateInviteResponse} CreateOrUpdateInviteResponse
                 */
                CreateOrUpdateInviteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateOrUpdateInviteResponse)
                        return object;
                    var message = new $root.infinitusai.auth.CreateOrUpdateInviteResponse();
                    if (object.invite != null) {
                        if (typeof object.invite !== "object")
                            throw TypeError(".infinitusai.auth.CreateOrUpdateInviteResponse.invite: object expected");
                        message.invite = $root.infinitusai.auth.Invite.fromObject(object.invite);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateOrUpdateInviteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {infinitusai.auth.CreateOrUpdateInviteResponse} message CreateOrUpdateInviteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateOrUpdateInviteResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.invite = null;
                    if (message.invite != null && message.hasOwnProperty("invite"))
                        object.invite = $root.infinitusai.auth.Invite.toObject(message.invite, options);
                    return object;
                };
    
                /**
                 * Converts this CreateOrUpdateInviteResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateOrUpdateInviteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateOrUpdateInviteResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateOrUpdateInviteResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateOrUpdateInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateOrUpdateInviteResponse";
                };
    
                return CreateOrUpdateInviteResponse;
            })();
    
            auth.AssignRolesOrCreateInviteRequest = (function() {
    
                /**
                 * Properties of an AssignRolesOrCreateInviteRequest.
                 * @memberof infinitusai.auth
                 * @interface IAssignRolesOrCreateInviteRequest
                 * @property {string|null} [inviteeEmail] AssignRolesOrCreateInviteRequest inviteeEmail
                 * @property {Array.<string>|null} [roleUuids] AssignRolesOrCreateInviteRequest roleUuids
                 * @property {infinitusai.auth.InviteType|null} [type] AssignRolesOrCreateInviteRequest type
                 * @property {string|null} [inviteBaseUrl] AssignRolesOrCreateInviteRequest inviteBaseUrl
                 */
    
                /**
                 * Constructs a new AssignRolesOrCreateInviteRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AssignRolesOrCreateInviteRequest.
                 * @implements IAssignRolesOrCreateInviteRequest
                 * @constructor
                 * @param {infinitusai.auth.IAssignRolesOrCreateInviteRequest=} [properties] Properties to set
                 */
                function AssignRolesOrCreateInviteRequest(properties) {
                    this.roleUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AssignRolesOrCreateInviteRequest inviteeEmail.
                 * @member {string} inviteeEmail
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @instance
                 */
                AssignRolesOrCreateInviteRequest.prototype.inviteeEmail = "";
    
                /**
                 * AssignRolesOrCreateInviteRequest roleUuids.
                 * @member {Array.<string>} roleUuids
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @instance
                 */
                AssignRolesOrCreateInviteRequest.prototype.roleUuids = $util.emptyArray;
    
                /**
                 * AssignRolesOrCreateInviteRequest type.
                 * @member {infinitusai.auth.InviteType} type
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @instance
                 */
                AssignRolesOrCreateInviteRequest.prototype.type = 0;
    
                /**
                 * AssignRolesOrCreateInviteRequest inviteBaseUrl.
                 * @member {string} inviteBaseUrl
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @instance
                 */
                AssignRolesOrCreateInviteRequest.prototype.inviteBaseUrl = "";
    
                /**
                 * Creates a new AssignRolesOrCreateInviteRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {infinitusai.auth.IAssignRolesOrCreateInviteRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.AssignRolesOrCreateInviteRequest} AssignRolesOrCreateInviteRequest instance
                 */
                AssignRolesOrCreateInviteRequest.create = function create(properties) {
                    return new AssignRolesOrCreateInviteRequest(properties);
                };
    
                /**
                 * Encodes the specified AssignRolesOrCreateInviteRequest message. Does not implicitly {@link infinitusai.auth.AssignRolesOrCreateInviteRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {infinitusai.auth.IAssignRolesOrCreateInviteRequest} message AssignRolesOrCreateInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AssignRolesOrCreateInviteRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inviteeEmail != null && Object.hasOwnProperty.call(message, "inviteeEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteeEmail);
                    if (message.roleUuids != null && message.roleUuids.length)
                        for (var i = 0; i < message.roleUuids.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.roleUuids[i]);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                    if (message.inviteBaseUrl != null && Object.hasOwnProperty.call(message, "inviteBaseUrl"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.inviteBaseUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified AssignRolesOrCreateInviteRequest message, length delimited. Does not implicitly {@link infinitusai.auth.AssignRolesOrCreateInviteRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {infinitusai.auth.IAssignRolesOrCreateInviteRequest} message AssignRolesOrCreateInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AssignRolesOrCreateInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AssignRolesOrCreateInviteRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AssignRolesOrCreateInviteRequest} AssignRolesOrCreateInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AssignRolesOrCreateInviteRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AssignRolesOrCreateInviteRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inviteeEmail = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.roleUuids && message.roleUuids.length))
                                    message.roleUuids = [];
                                message.roleUuids.push(reader.string());
                                break;
                            }
                        case 3: {
                                message.type = reader.int32();
                                break;
                            }
                        case 4: {
                                message.inviteBaseUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AssignRolesOrCreateInviteRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AssignRolesOrCreateInviteRequest} AssignRolesOrCreateInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AssignRolesOrCreateInviteRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AssignRolesOrCreateInviteRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AssignRolesOrCreateInviteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inviteeEmail != null && message.hasOwnProperty("inviteeEmail"))
                        if (!$util.isString(message.inviteeEmail))
                            return "inviteeEmail: string expected";
                    if (message.roleUuids != null && message.hasOwnProperty("roleUuids")) {
                        if (!Array.isArray(message.roleUuids))
                            return "roleUuids: array expected";
                        for (var i = 0; i < message.roleUuids.length; ++i)
                            if (!$util.isString(message.roleUuids[i]))
                                return "roleUuids: string[] expected";
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.inviteBaseUrl != null && message.hasOwnProperty("inviteBaseUrl"))
                        if (!$util.isString(message.inviteBaseUrl))
                            return "inviteBaseUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates an AssignRolesOrCreateInviteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AssignRolesOrCreateInviteRequest} AssignRolesOrCreateInviteRequest
                 */
                AssignRolesOrCreateInviteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AssignRolesOrCreateInviteRequest)
                        return object;
                    var message = new $root.infinitusai.auth.AssignRolesOrCreateInviteRequest();
                    if (object.inviteeEmail != null)
                        message.inviteeEmail = String(object.inviteeEmail);
                    if (object.roleUuids) {
                        if (!Array.isArray(object.roleUuids))
                            throw TypeError(".infinitusai.auth.AssignRolesOrCreateInviteRequest.roleUuids: array expected");
                        message.roleUuids = [];
                        for (var i = 0; i < object.roleUuids.length; ++i)
                            message.roleUuids[i] = String(object.roleUuids[i]);
                    }
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "INVITE_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "INVITE_TYPE_ADMIN":
                    case 1:
                        message.type = 1;
                        break;
                    case "INVITE_TYPE_OPERATOR":
                    case 2:
                        message.type = 2;
                        break;
                    case "INVITE_TYPE_CUSTOMER":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.inviteBaseUrl != null)
                        message.inviteBaseUrl = String(object.inviteBaseUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AssignRolesOrCreateInviteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {infinitusai.auth.AssignRolesOrCreateInviteRequest} message AssignRolesOrCreateInviteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AssignRolesOrCreateInviteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.roleUuids = [];
                    if (options.defaults) {
                        object.inviteeEmail = "";
                        object.type = options.enums === String ? "INVITE_TYPE_UNKNOWN" : 0;
                        object.inviteBaseUrl = "";
                    }
                    if (message.inviteeEmail != null && message.hasOwnProperty("inviteeEmail"))
                        object.inviteeEmail = message.inviteeEmail;
                    if (message.roleUuids && message.roleUuids.length) {
                        object.roleUuids = [];
                        for (var j = 0; j < message.roleUuids.length; ++j)
                            object.roleUuids[j] = message.roleUuids[j];
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.auth.InviteType[message.type] === undefined ? message.type : $root.infinitusai.auth.InviteType[message.type] : message.type;
                    if (message.inviteBaseUrl != null && message.hasOwnProperty("inviteBaseUrl"))
                        object.inviteBaseUrl = message.inviteBaseUrl;
                    return object;
                };
    
                /**
                 * Converts this AssignRolesOrCreateInviteRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AssignRolesOrCreateInviteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AssignRolesOrCreateInviteRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AssignRolesOrCreateInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AssignRolesOrCreateInviteRequest";
                };
    
                return AssignRolesOrCreateInviteRequest;
            })();
    
            auth.AssignRolesOrCreateInviteResponse = (function() {
    
                /**
                 * Properties of an AssignRolesOrCreateInviteResponse.
                 * @memberof infinitusai.auth
                 * @interface IAssignRolesOrCreateInviteResponse
                 * @property {boolean|null} [inviteCreated] AssignRolesOrCreateInviteResponse inviteCreated
                 * @property {infinitusai.auth.IInvite|null} [invite] AssignRolesOrCreateInviteResponse invite
                 */
    
                /**
                 * Constructs a new AssignRolesOrCreateInviteResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AssignRolesOrCreateInviteResponse.
                 * @implements IAssignRolesOrCreateInviteResponse
                 * @constructor
                 * @param {infinitusai.auth.IAssignRolesOrCreateInviteResponse=} [properties] Properties to set
                 */
                function AssignRolesOrCreateInviteResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AssignRolesOrCreateInviteResponse inviteCreated.
                 * @member {boolean} inviteCreated
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @instance
                 */
                AssignRolesOrCreateInviteResponse.prototype.inviteCreated = false;
    
                /**
                 * AssignRolesOrCreateInviteResponse invite.
                 * @member {infinitusai.auth.IInvite|null|undefined} invite
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @instance
                 */
                AssignRolesOrCreateInviteResponse.prototype.invite = null;
    
                /**
                 * Creates a new AssignRolesOrCreateInviteResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {infinitusai.auth.IAssignRolesOrCreateInviteResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.AssignRolesOrCreateInviteResponse} AssignRolesOrCreateInviteResponse instance
                 */
                AssignRolesOrCreateInviteResponse.create = function create(properties) {
                    return new AssignRolesOrCreateInviteResponse(properties);
                };
    
                /**
                 * Encodes the specified AssignRolesOrCreateInviteResponse message. Does not implicitly {@link infinitusai.auth.AssignRolesOrCreateInviteResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {infinitusai.auth.IAssignRolesOrCreateInviteResponse} message AssignRolesOrCreateInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AssignRolesOrCreateInviteResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inviteCreated != null && Object.hasOwnProperty.call(message, "inviteCreated"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.inviteCreated);
                    if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                        $root.infinitusai.auth.Invite.encode(message.invite, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AssignRolesOrCreateInviteResponse message, length delimited. Does not implicitly {@link infinitusai.auth.AssignRolesOrCreateInviteResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {infinitusai.auth.IAssignRolesOrCreateInviteResponse} message AssignRolesOrCreateInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AssignRolesOrCreateInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AssignRolesOrCreateInviteResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AssignRolesOrCreateInviteResponse} AssignRolesOrCreateInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AssignRolesOrCreateInviteResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AssignRolesOrCreateInviteResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inviteCreated = reader.bool();
                                break;
                            }
                        case 2: {
                                message.invite = $root.infinitusai.auth.Invite.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AssignRolesOrCreateInviteResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AssignRolesOrCreateInviteResponse} AssignRolesOrCreateInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AssignRolesOrCreateInviteResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AssignRolesOrCreateInviteResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AssignRolesOrCreateInviteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inviteCreated != null && message.hasOwnProperty("inviteCreated"))
                        if (typeof message.inviteCreated !== "boolean")
                            return "inviteCreated: boolean expected";
                    if (message.invite != null && message.hasOwnProperty("invite")) {
                        var error = $root.infinitusai.auth.Invite.verify(message.invite);
                        if (error)
                            return "invite." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an AssignRolesOrCreateInviteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AssignRolesOrCreateInviteResponse} AssignRolesOrCreateInviteResponse
                 */
                AssignRolesOrCreateInviteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AssignRolesOrCreateInviteResponse)
                        return object;
                    var message = new $root.infinitusai.auth.AssignRolesOrCreateInviteResponse();
                    if (object.inviteCreated != null)
                        message.inviteCreated = Boolean(object.inviteCreated);
                    if (object.invite != null) {
                        if (typeof object.invite !== "object")
                            throw TypeError(".infinitusai.auth.AssignRolesOrCreateInviteResponse.invite: object expected");
                        message.invite = $root.infinitusai.auth.Invite.fromObject(object.invite);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AssignRolesOrCreateInviteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {infinitusai.auth.AssignRolesOrCreateInviteResponse} message AssignRolesOrCreateInviteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AssignRolesOrCreateInviteResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.inviteCreated = false;
                        object.invite = null;
                    }
                    if (message.inviteCreated != null && message.hasOwnProperty("inviteCreated"))
                        object.inviteCreated = message.inviteCreated;
                    if (message.invite != null && message.hasOwnProperty("invite"))
                        object.invite = $root.infinitusai.auth.Invite.toObject(message.invite, options);
                    return object;
                };
    
                /**
                 * Converts this AssignRolesOrCreateInviteResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AssignRolesOrCreateInviteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AssignRolesOrCreateInviteResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AssignRolesOrCreateInviteResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AssignRolesOrCreateInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AssignRolesOrCreateInviteResponse";
                };
    
                return AssignRolesOrCreateInviteResponse;
            })();
    
            auth.GetInvitesRequest = (function() {
    
                /**
                 * Properties of a GetInvitesRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetInvitesRequest
                 * @property {number|null} [page] GetInvitesRequest page
                 * @property {number|null} [limit] GetInvitesRequest limit
                 * @property {string|null} [search] GetInvitesRequest search
                 * @property {Array.<infinitusai.auth.InviteType>|null} [types] GetInvitesRequest types
                 * @property {Array.<infinitusai.auth.InviteState>|null} [states] GetInvitesRequest states
                 * @property {string|null} [orderByCol] GetInvitesRequest orderByCol
                 * @property {string|null} [orderByDir] GetInvitesRequest orderByDir
                 * @property {Array.<string>|null} [orgUuids] GetInvitesRequest orgUuids
                 */
    
                /**
                 * Constructs a new GetInvitesRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetInvitesRequest.
                 * @implements IGetInvitesRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetInvitesRequest=} [properties] Properties to set
                 */
                function GetInvitesRequest(properties) {
                    this.types = [];
                    this.states = [];
                    this.orgUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetInvitesRequest page.
                 * @member {number} page
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 */
                GetInvitesRequest.prototype.page = 0;
    
                /**
                 * GetInvitesRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 */
                GetInvitesRequest.prototype.limit = 0;
    
                /**
                 * GetInvitesRequest search.
                 * @member {string} search
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 */
                GetInvitesRequest.prototype.search = "";
    
                /**
                 * GetInvitesRequest types.
                 * @member {Array.<infinitusai.auth.InviteType>} types
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 */
                GetInvitesRequest.prototype.types = $util.emptyArray;
    
                /**
                 * GetInvitesRequest states.
                 * @member {Array.<infinitusai.auth.InviteState>} states
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 */
                GetInvitesRequest.prototype.states = $util.emptyArray;
    
                /**
                 * GetInvitesRequest orderByCol.
                 * @member {string} orderByCol
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 */
                GetInvitesRequest.prototype.orderByCol = "";
    
                /**
                 * GetInvitesRequest orderByDir.
                 * @member {string} orderByDir
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 */
                GetInvitesRequest.prototype.orderByDir = "";
    
                /**
                 * GetInvitesRequest orgUuids.
                 * @member {Array.<string>} orgUuids
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 */
                GetInvitesRequest.prototype.orgUuids = $util.emptyArray;
    
                /**
                 * Creates a new GetInvitesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {infinitusai.auth.IGetInvitesRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetInvitesRequest} GetInvitesRequest instance
                 */
                GetInvitesRequest.create = function create(properties) {
                    return new GetInvitesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetInvitesRequest message. Does not implicitly {@link infinitusai.auth.GetInvitesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {infinitusai.auth.IGetInvitesRequest} message GetInvitesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInvitesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
                    if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.search);
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 4, wireType 2 =*/34).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    if (message.states != null && message.states.length) {
                        writer.uint32(/* id 5, wireType 2 =*/42).fork();
                        for (var i = 0; i < message.states.length; ++i)
                            writer.int32(message.states[i]);
                        writer.ldelim();
                    }
                    if (message.orderByCol != null && Object.hasOwnProperty.call(message, "orderByCol"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.orderByCol);
                    if (message.orderByDir != null && Object.hasOwnProperty.call(message, "orderByDir"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.orderByDir);
                    if (message.orgUuids != null && message.orgUuids.length)
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.orgUuids[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetInvitesRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetInvitesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {infinitusai.auth.IGetInvitesRequest} message GetInvitesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInvitesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetInvitesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetInvitesRequest} GetInvitesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInvitesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetInvitesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.page = reader.int32();
                                break;
                            }
                        case 2: {
                                message.limit = reader.int32();
                                break;
                            }
                        case 3: {
                                message.search = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        case 5: {
                                if (!(message.states && message.states.length))
                                    message.states = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.states.push(reader.int32());
                                } else
                                    message.states.push(reader.int32());
                                break;
                            }
                        case 6: {
                                message.orderByCol = reader.string();
                                break;
                            }
                        case 7: {
                                message.orderByDir = reader.string();
                                break;
                            }
                        case 8: {
                                if (!(message.orgUuids && message.orgUuids.length))
                                    message.orgUuids = [];
                                message.orgUuids.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetInvitesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetInvitesRequest} GetInvitesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInvitesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetInvitesRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInvitesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.search != null && message.hasOwnProperty("search"))
                        if (!$util.isString(message.search))
                            return "search: string expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.states != null && message.hasOwnProperty("states")) {
                        if (!Array.isArray(message.states))
                            return "states: array expected";
                        for (var i = 0; i < message.states.length; ++i)
                            switch (message.states[i]) {
                            default:
                                return "states: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                    }
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        if (!$util.isString(message.orderByCol))
                            return "orderByCol: string expected";
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        if (!$util.isString(message.orderByDir))
                            return "orderByDir: string expected";
                    if (message.orgUuids != null && message.hasOwnProperty("orgUuids")) {
                        if (!Array.isArray(message.orgUuids))
                            return "orgUuids: array expected";
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            if (!$util.isString(message.orgUuids[i]))
                                return "orgUuids: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a GetInvitesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetInvitesRequest} GetInvitesRequest
                 */
                GetInvitesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetInvitesRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetInvitesRequest();
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    if (object.search != null)
                        message.search = String(object.search);
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetInvitesRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "INVITE_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "INVITE_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "INVITE_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "INVITE_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    if (object.states) {
                        if (!Array.isArray(object.states))
                            throw TypeError(".infinitusai.auth.GetInvitesRequest.states: array expected");
                        message.states = [];
                        for (var i = 0; i < object.states.length; ++i)
                            switch (object.states[i]) {
                            default:
                                if (typeof object.states[i] === "number") {
                                    message.states[i] = object.states[i];
                                    break;
                                }
                            case "INVITE_STATE_UNKNOWN":
                            case 0:
                                message.states[i] = 0;
                                break;
                            case "INVITE_STATE_PENDING":
                            case 1:
                                message.states[i] = 1;
                                break;
                            case "INVITE_STATE_ACCEPTED":
                            case 2:
                                message.states[i] = 2;
                                break;
                            case "INVITE_STATE_DECLINED":
                            case 3:
                                message.states[i] = 3;
                                break;
                            case "INVITE_STATE_CANCELED":
                            case 4:
                                message.states[i] = 4;
                                break;
                            }
                    }
                    if (object.orderByCol != null)
                        message.orderByCol = String(object.orderByCol);
                    if (object.orderByDir != null)
                        message.orderByDir = String(object.orderByDir);
                    if (object.orgUuids) {
                        if (!Array.isArray(object.orgUuids))
                            throw TypeError(".infinitusai.auth.GetInvitesRequest.orgUuids: array expected");
                        message.orgUuids = [];
                        for (var i = 0; i < object.orgUuids.length; ++i)
                            message.orgUuids[i] = String(object.orgUuids[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetInvitesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {infinitusai.auth.GetInvitesRequest} message GetInvitesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInvitesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.types = [];
                        object.states = [];
                        object.orgUuids = [];
                    }
                    if (options.defaults) {
                        object.page = 0;
                        object.limit = 0;
                        object.search = "";
                        object.orderByCol = "";
                        object.orderByDir = "";
                    }
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.search != null && message.hasOwnProperty("search"))
                        object.search = message.search;
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.InviteType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.InviteType[message.types[j]] : message.types[j];
                    }
                    if (message.states && message.states.length) {
                        object.states = [];
                        for (var j = 0; j < message.states.length; ++j)
                            object.states[j] = options.enums === String ? $root.infinitusai.auth.InviteState[message.states[j]] === undefined ? message.states[j] : $root.infinitusai.auth.InviteState[message.states[j]] : message.states[j];
                    }
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        object.orderByCol = message.orderByCol;
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        object.orderByDir = message.orderByDir;
                    if (message.orgUuids && message.orgUuids.length) {
                        object.orgUuids = [];
                        for (var j = 0; j < message.orgUuids.length; ++j)
                            object.orgUuids[j] = message.orgUuids[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetInvitesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInvitesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetInvitesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetInvitesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInvitesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetInvitesRequest";
                };
    
                return GetInvitesRequest;
            })();
    
            auth.GetInvitesResponse = (function() {
    
                /**
                 * Properties of a GetInvitesResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetInvitesResponse
                 * @property {number|Long|null} [total] GetInvitesResponse total
                 * @property {Array.<infinitusai.auth.IInvite>|null} [invites] GetInvitesResponse invites
                 */
    
                /**
                 * Constructs a new GetInvitesResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetInvitesResponse.
                 * @implements IGetInvitesResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetInvitesResponse=} [properties] Properties to set
                 */
                function GetInvitesResponse(properties) {
                    this.invites = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetInvitesResponse total.
                 * @member {number|Long} total
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @instance
                 */
                GetInvitesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GetInvitesResponse invites.
                 * @member {Array.<infinitusai.auth.IInvite>} invites
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @instance
                 */
                GetInvitesResponse.prototype.invites = $util.emptyArray;
    
                /**
                 * Creates a new GetInvitesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {infinitusai.auth.IGetInvitesResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetInvitesResponse} GetInvitesResponse instance
                 */
                GetInvitesResponse.create = function create(properties) {
                    return new GetInvitesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetInvitesResponse message. Does not implicitly {@link infinitusai.auth.GetInvitesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {infinitusai.auth.IGetInvitesResponse} message GetInvitesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInvitesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.total);
                    if (message.invites != null && message.invites.length)
                        for (var i = 0; i < message.invites.length; ++i)
                            $root.infinitusai.auth.Invite.encode(message.invites[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetInvitesResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetInvitesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {infinitusai.auth.IGetInvitesResponse} message GetInvitesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInvitesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetInvitesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetInvitesResponse} GetInvitesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInvitesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetInvitesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.total = reader.int64();
                                break;
                            }
                        case 2: {
                                if (!(message.invites && message.invites.length))
                                    message.invites = [];
                                message.invites.push($root.infinitusai.auth.Invite.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetInvitesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetInvitesResponse} GetInvitesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInvitesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetInvitesResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInvitesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.invites != null && message.hasOwnProperty("invites")) {
                        if (!Array.isArray(message.invites))
                            return "invites: array expected";
                        for (var i = 0; i < message.invites.length; ++i) {
                            var error = $root.infinitusai.auth.Invite.verify(message.invites[i]);
                            if (error)
                                return "invites." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetInvitesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetInvitesResponse} GetInvitesResponse
                 */
                GetInvitesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetInvitesResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetInvitesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.invites) {
                        if (!Array.isArray(object.invites))
                            throw TypeError(".infinitusai.auth.GetInvitesResponse.invites: array expected");
                        message.invites = [];
                        for (var i = 0; i < object.invites.length; ++i) {
                            if (typeof object.invites[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetInvitesResponse.invites: object expected");
                            message.invites[i] = $root.infinitusai.auth.Invite.fromObject(object.invites[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetInvitesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {infinitusai.auth.GetInvitesResponse} message GetInvitesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInvitesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.invites = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.invites && message.invites.length) {
                        object.invites = [];
                        for (var j = 0; j < message.invites.length; ++j)
                            object.invites[j] = $root.infinitusai.auth.Invite.toObject(message.invites[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetInvitesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInvitesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetInvitesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetInvitesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInvitesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetInvitesResponse";
                };
    
                return GetInvitesResponse;
            })();
    
            auth.GetOrgInvitesRequest = (function() {
    
                /**
                 * Properties of a GetOrgInvitesRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgInvitesRequest
                 * @property {Array.<infinitusai.auth.InviteType>|null} [types] GetOrgInvitesRequest types
                 * @property {Array.<infinitusai.auth.InviteState>|null} [states] GetOrgInvitesRequest states
                 */
    
                /**
                 * Constructs a new GetOrgInvitesRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgInvitesRequest.
                 * @implements IGetOrgInvitesRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgInvitesRequest=} [properties] Properties to set
                 */
                function GetOrgInvitesRequest(properties) {
                    this.types = [];
                    this.states = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgInvitesRequest types.
                 * @member {Array.<infinitusai.auth.InviteType>} types
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @instance
                 */
                GetOrgInvitesRequest.prototype.types = $util.emptyArray;
    
                /**
                 * GetOrgInvitesRequest states.
                 * @member {Array.<infinitusai.auth.InviteState>} states
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @instance
                 */
                GetOrgInvitesRequest.prototype.states = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgInvitesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgInvitesRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgInvitesRequest} GetOrgInvitesRequest instance
                 */
                GetOrgInvitesRequest.create = function create(properties) {
                    return new GetOrgInvitesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgInvitesRequest message. Does not implicitly {@link infinitusai.auth.GetOrgInvitesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgInvitesRequest} message GetOrgInvitesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgInvitesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    if (message.states != null && message.states.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.states.length; ++i)
                            writer.int32(message.states[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgInvitesRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgInvitesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgInvitesRequest} message GetOrgInvitesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgInvitesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgInvitesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgInvitesRequest} GetOrgInvitesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgInvitesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgInvitesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        case 2: {
                                if (!(message.states && message.states.length))
                                    message.states = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.states.push(reader.int32());
                                } else
                                    message.states.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgInvitesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgInvitesRequest} GetOrgInvitesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgInvitesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgInvitesRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgInvitesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.states != null && message.hasOwnProperty("states")) {
                        if (!Array.isArray(message.states))
                            return "states: array expected";
                        for (var i = 0; i < message.states.length; ++i)
                            switch (message.states[i]) {
                            default:
                                return "states: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgInvitesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgInvitesRequest} GetOrgInvitesRequest
                 */
                GetOrgInvitesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgInvitesRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgInvitesRequest();
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetOrgInvitesRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "INVITE_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "INVITE_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "INVITE_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "INVITE_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    if (object.states) {
                        if (!Array.isArray(object.states))
                            throw TypeError(".infinitusai.auth.GetOrgInvitesRequest.states: array expected");
                        message.states = [];
                        for (var i = 0; i < object.states.length; ++i)
                            switch (object.states[i]) {
                            default:
                                if (typeof object.states[i] === "number") {
                                    message.states[i] = object.states[i];
                                    break;
                                }
                            case "INVITE_STATE_UNKNOWN":
                            case 0:
                                message.states[i] = 0;
                                break;
                            case "INVITE_STATE_PENDING":
                            case 1:
                                message.states[i] = 1;
                                break;
                            case "INVITE_STATE_ACCEPTED":
                            case 2:
                                message.states[i] = 2;
                                break;
                            case "INVITE_STATE_DECLINED":
                            case 3:
                                message.states[i] = 3;
                                break;
                            case "INVITE_STATE_CANCELED":
                            case 4:
                                message.states[i] = 4;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgInvitesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {infinitusai.auth.GetOrgInvitesRequest} message GetOrgInvitesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgInvitesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.types = [];
                        object.states = [];
                    }
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.InviteType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.InviteType[message.types[j]] : message.types[j];
                    }
                    if (message.states && message.states.length) {
                        object.states = [];
                        for (var j = 0; j < message.states.length; ++j)
                            object.states[j] = options.enums === String ? $root.infinitusai.auth.InviteState[message.states[j]] === undefined ? message.states[j] : $root.infinitusai.auth.InviteState[message.states[j]] : message.states[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgInvitesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgInvitesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgInvitesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgInvitesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgInvitesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgInvitesRequest";
                };
    
                return GetOrgInvitesRequest;
            })();
    
            auth.GetOrgInvitesResponse = (function() {
    
                /**
                 * Properties of a GetOrgInvitesResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgInvitesResponse
                 * @property {Array.<infinitusai.auth.IInvite>|null} [invites] GetOrgInvitesResponse invites
                 */
    
                /**
                 * Constructs a new GetOrgInvitesResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgInvitesResponse.
                 * @implements IGetOrgInvitesResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgInvitesResponse=} [properties] Properties to set
                 */
                function GetOrgInvitesResponse(properties) {
                    this.invites = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgInvitesResponse invites.
                 * @member {Array.<infinitusai.auth.IInvite>} invites
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @instance
                 */
                GetOrgInvitesResponse.prototype.invites = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgInvitesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgInvitesResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgInvitesResponse} GetOrgInvitesResponse instance
                 */
                GetOrgInvitesResponse.create = function create(properties) {
                    return new GetOrgInvitesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgInvitesResponse message. Does not implicitly {@link infinitusai.auth.GetOrgInvitesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgInvitesResponse} message GetOrgInvitesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgInvitesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invites != null && message.invites.length)
                        for (var i = 0; i < message.invites.length; ++i)
                            $root.infinitusai.auth.Invite.encode(message.invites[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgInvitesResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgInvitesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgInvitesResponse} message GetOrgInvitesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgInvitesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgInvitesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgInvitesResponse} GetOrgInvitesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgInvitesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgInvitesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                if (!(message.invites && message.invites.length))
                                    message.invites = [];
                                message.invites.push($root.infinitusai.auth.Invite.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgInvitesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgInvitesResponse} GetOrgInvitesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgInvitesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgInvitesResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgInvitesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invites != null && message.hasOwnProperty("invites")) {
                        if (!Array.isArray(message.invites))
                            return "invites: array expected";
                        for (var i = 0; i < message.invites.length; ++i) {
                            var error = $root.infinitusai.auth.Invite.verify(message.invites[i]);
                            if (error)
                                return "invites." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgInvitesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgInvitesResponse} GetOrgInvitesResponse
                 */
                GetOrgInvitesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgInvitesResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgInvitesResponse();
                    if (object.invites) {
                        if (!Array.isArray(object.invites))
                            throw TypeError(".infinitusai.auth.GetOrgInvitesResponse.invites: array expected");
                        message.invites = [];
                        for (var i = 0; i < object.invites.length; ++i) {
                            if (typeof object.invites[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetOrgInvitesResponse.invites: object expected");
                            message.invites[i] = $root.infinitusai.auth.Invite.fromObject(object.invites[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgInvitesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {infinitusai.auth.GetOrgInvitesResponse} message GetOrgInvitesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgInvitesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.invites = [];
                    if (message.invites && message.invites.length) {
                        object.invites = [];
                        for (var j = 0; j < message.invites.length; ++j)
                            object.invites[j] = $root.infinitusai.auth.Invite.toObject(message.invites[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgInvitesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgInvitesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgInvitesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgInvitesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgInvitesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgInvitesResponse";
                };
    
                return GetOrgInvitesResponse;
            })();
    
            auth.GetInviteRequest = (function() {
    
                /**
                 * Properties of a GetInviteRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetInviteRequest
                 * @property {string|null} [uuid] GetInviteRequest uuid
                 */
    
                /**
                 * Constructs a new GetInviteRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetInviteRequest.
                 * @implements IGetInviteRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetInviteRequest=} [properties] Properties to set
                 */
                function GetInviteRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetInviteRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @instance
                 */
                GetInviteRequest.prototype.uuid = "";
    
                /**
                 * Creates a new GetInviteRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {infinitusai.auth.IGetInviteRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetInviteRequest} GetInviteRequest instance
                 */
                GetInviteRequest.create = function create(properties) {
                    return new GetInviteRequest(properties);
                };
    
                /**
                 * Encodes the specified GetInviteRequest message. Does not implicitly {@link infinitusai.auth.GetInviteRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {infinitusai.auth.IGetInviteRequest} message GetInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInviteRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetInviteRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetInviteRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {infinitusai.auth.IGetInviteRequest} message GetInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetInviteRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetInviteRequest} GetInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInviteRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetInviteRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetInviteRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetInviteRequest} GetInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInviteRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetInviteRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInviteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetInviteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetInviteRequest} GetInviteRequest
                 */
                GetInviteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetInviteRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetInviteRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetInviteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {infinitusai.auth.GetInviteRequest} message GetInviteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInviteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this GetInviteRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInviteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetInviteRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetInviteRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetInviteRequest";
                };
    
                return GetInviteRequest;
            })();
    
            auth.GetInviteResponse = (function() {
    
                /**
                 * Properties of a GetInviteResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetInviteResponse
                 * @property {infinitusai.auth.IInvite|null} [invite] GetInviteResponse invite
                 * @property {string|null} [orgName] GetInviteResponse orgName
                 */
    
                /**
                 * Constructs a new GetInviteResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetInviteResponse.
                 * @implements IGetInviteResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetInviteResponse=} [properties] Properties to set
                 */
                function GetInviteResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetInviteResponse invite.
                 * @member {infinitusai.auth.IInvite|null|undefined} invite
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @instance
                 */
                GetInviteResponse.prototype.invite = null;
    
                /**
                 * GetInviteResponse orgName.
                 * @member {string} orgName
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @instance
                 */
                GetInviteResponse.prototype.orgName = "";
    
                /**
                 * Creates a new GetInviteResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {infinitusai.auth.IGetInviteResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetInviteResponse} GetInviteResponse instance
                 */
                GetInviteResponse.create = function create(properties) {
                    return new GetInviteResponse(properties);
                };
    
                /**
                 * Encodes the specified GetInviteResponse message. Does not implicitly {@link infinitusai.auth.GetInviteResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {infinitusai.auth.IGetInviteResponse} message GetInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInviteResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                        $root.infinitusai.auth.Invite.encode(message.invite, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.orgName != null && Object.hasOwnProperty.call(message, "orgName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgName);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetInviteResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetInviteResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {infinitusai.auth.IGetInviteResponse} message GetInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetInviteResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetInviteResponse} GetInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInviteResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetInviteResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.invite = $root.infinitusai.auth.Invite.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.orgName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetInviteResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetInviteResponse} GetInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetInviteResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetInviteResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetInviteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invite != null && message.hasOwnProperty("invite")) {
                        var error = $root.infinitusai.auth.Invite.verify(message.invite);
                        if (error)
                            return "invite." + error;
                    }
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        if (!$util.isString(message.orgName))
                            return "orgName: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetInviteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetInviteResponse} GetInviteResponse
                 */
                GetInviteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetInviteResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetInviteResponse();
                    if (object.invite != null) {
                        if (typeof object.invite !== "object")
                            throw TypeError(".infinitusai.auth.GetInviteResponse.invite: object expected");
                        message.invite = $root.infinitusai.auth.Invite.fromObject(object.invite);
                    }
                    if (object.orgName != null)
                        message.orgName = String(object.orgName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetInviteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {infinitusai.auth.GetInviteResponse} message GetInviteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetInviteResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.invite = null;
                        object.orgName = "";
                    }
                    if (message.invite != null && message.hasOwnProperty("invite"))
                        object.invite = $root.infinitusai.auth.Invite.toObject(message.invite, options);
                    if (message.orgName != null && message.hasOwnProperty("orgName"))
                        object.orgName = message.orgName;
                    return object;
                };
    
                /**
                 * Converts this GetInviteResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetInviteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetInviteResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetInviteResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetInviteResponse";
                };
    
                return GetInviteResponse;
            })();
    
            auth.AddRoleToOrgRequest = (function() {
    
                /**
                 * Properties of an AddRoleToOrgRequest.
                 * @memberof infinitusai.auth
                 * @interface IAddRoleToOrgRequest
                 * @property {string|null} [roleUuid] AddRoleToOrgRequest roleUuid
                 */
    
                /**
                 * Constructs a new AddRoleToOrgRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AddRoleToOrgRequest.
                 * @implements IAddRoleToOrgRequest
                 * @constructor
                 * @param {infinitusai.auth.IAddRoleToOrgRequest=} [properties] Properties to set
                 */
                function AddRoleToOrgRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AddRoleToOrgRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @instance
                 */
                AddRoleToOrgRequest.prototype.roleUuid = "";
    
                /**
                 * Creates a new AddRoleToOrgRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {infinitusai.auth.IAddRoleToOrgRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.AddRoleToOrgRequest} AddRoleToOrgRequest instance
                 */
                AddRoleToOrgRequest.create = function create(properties) {
                    return new AddRoleToOrgRequest(properties);
                };
    
                /**
                 * Encodes the specified AddRoleToOrgRequest message. Does not implicitly {@link infinitusai.auth.AddRoleToOrgRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {infinitusai.auth.IAddRoleToOrgRequest} message AddRoleToOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddRoleToOrgRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified AddRoleToOrgRequest message, length delimited. Does not implicitly {@link infinitusai.auth.AddRoleToOrgRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {infinitusai.auth.IAddRoleToOrgRequest} message AddRoleToOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddRoleToOrgRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddRoleToOrgRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AddRoleToOrgRequest} AddRoleToOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddRoleToOrgRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AddRoleToOrgRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddRoleToOrgRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AddRoleToOrgRequest} AddRoleToOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddRoleToOrgRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddRoleToOrgRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddRoleToOrgRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an AddRoleToOrgRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AddRoleToOrgRequest} AddRoleToOrgRequest
                 */
                AddRoleToOrgRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AddRoleToOrgRequest)
                        return object;
                    var message = new $root.infinitusai.auth.AddRoleToOrgRequest();
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AddRoleToOrgRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {infinitusai.auth.AddRoleToOrgRequest} message AddRoleToOrgRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddRoleToOrgRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.roleUuid = "";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    return object;
                };
    
                /**
                 * Converts this AddRoleToOrgRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddRoleToOrgRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddRoleToOrgRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AddRoleToOrgRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddRoleToOrgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AddRoleToOrgRequest";
                };
    
                return AddRoleToOrgRequest;
            })();
    
            auth.AddRoleToOrgResponse = (function() {
    
                /**
                 * Properties of an AddRoleToOrgResponse.
                 * @memberof infinitusai.auth
                 * @interface IAddRoleToOrgResponse
                 */
    
                /**
                 * Constructs a new AddRoleToOrgResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AddRoleToOrgResponse.
                 * @implements IAddRoleToOrgResponse
                 * @constructor
                 * @param {infinitusai.auth.IAddRoleToOrgResponse=} [properties] Properties to set
                 */
                function AddRoleToOrgResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new AddRoleToOrgResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {infinitusai.auth.IAddRoleToOrgResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.AddRoleToOrgResponse} AddRoleToOrgResponse instance
                 */
                AddRoleToOrgResponse.create = function create(properties) {
                    return new AddRoleToOrgResponse(properties);
                };
    
                /**
                 * Encodes the specified AddRoleToOrgResponse message. Does not implicitly {@link infinitusai.auth.AddRoleToOrgResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {infinitusai.auth.IAddRoleToOrgResponse} message AddRoleToOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddRoleToOrgResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified AddRoleToOrgResponse message, length delimited. Does not implicitly {@link infinitusai.auth.AddRoleToOrgResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {infinitusai.auth.IAddRoleToOrgResponse} message AddRoleToOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddRoleToOrgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddRoleToOrgResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AddRoleToOrgResponse} AddRoleToOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddRoleToOrgResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AddRoleToOrgResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddRoleToOrgResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AddRoleToOrgResponse} AddRoleToOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddRoleToOrgResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddRoleToOrgResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddRoleToOrgResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an AddRoleToOrgResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AddRoleToOrgResponse} AddRoleToOrgResponse
                 */
                AddRoleToOrgResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AddRoleToOrgResponse)
                        return object;
                    return new $root.infinitusai.auth.AddRoleToOrgResponse();
                };
    
                /**
                 * Creates a plain object from an AddRoleToOrgResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {infinitusai.auth.AddRoleToOrgResponse} message AddRoleToOrgResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddRoleToOrgResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this AddRoleToOrgResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddRoleToOrgResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddRoleToOrgResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AddRoleToOrgResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddRoleToOrgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AddRoleToOrgResponse";
                };
    
                return AddRoleToOrgResponse;
            })();
    
            auth.RemoveRoleFromOrgRequest = (function() {
    
                /**
                 * Properties of a RemoveRoleFromOrgRequest.
                 * @memberof infinitusai.auth
                 * @interface IRemoveRoleFromOrgRequest
                 * @property {string|null} [roleUuid] RemoveRoleFromOrgRequest roleUuid
                 */
    
                /**
                 * Constructs a new RemoveRoleFromOrgRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemoveRoleFromOrgRequest.
                 * @implements IRemoveRoleFromOrgRequest
                 * @constructor
                 * @param {infinitusai.auth.IRemoveRoleFromOrgRequest=} [properties] Properties to set
                 */
                function RemoveRoleFromOrgRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RemoveRoleFromOrgRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @instance
                 */
                RemoveRoleFromOrgRequest.prototype.roleUuid = "";
    
                /**
                 * Creates a new RemoveRoleFromOrgRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromOrgRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemoveRoleFromOrgRequest} RemoveRoleFromOrgRequest instance
                 */
                RemoveRoleFromOrgRequest.create = function create(properties) {
                    return new RemoveRoleFromOrgRequest(properties);
                };
    
                /**
                 * Encodes the specified RemoveRoleFromOrgRequest message. Does not implicitly {@link infinitusai.auth.RemoveRoleFromOrgRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromOrgRequest} message RemoveRoleFromOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveRoleFromOrgRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RemoveRoleFromOrgRequest message, length delimited. Does not implicitly {@link infinitusai.auth.RemoveRoleFromOrgRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromOrgRequest} message RemoveRoleFromOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveRoleFromOrgRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemoveRoleFromOrgRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemoveRoleFromOrgRequest} RemoveRoleFromOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveRoleFromOrgRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemoveRoleFromOrgRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemoveRoleFromOrgRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemoveRoleFromOrgRequest} RemoveRoleFromOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveRoleFromOrgRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemoveRoleFromOrgRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemoveRoleFromOrgRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RemoveRoleFromOrgRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemoveRoleFromOrgRequest} RemoveRoleFromOrgRequest
                 */
                RemoveRoleFromOrgRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemoveRoleFromOrgRequest)
                        return object;
                    var message = new $root.infinitusai.auth.RemoveRoleFromOrgRequest();
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RemoveRoleFromOrgRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.RemoveRoleFromOrgRequest} message RemoveRoleFromOrgRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemoveRoleFromOrgRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.roleUuid = "";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    return object;
                };
    
                /**
                 * Converts this RemoveRoleFromOrgRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemoveRoleFromOrgRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemoveRoleFromOrgRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemoveRoleFromOrgRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemoveRoleFromOrgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemoveRoleFromOrgRequest";
                };
    
                return RemoveRoleFromOrgRequest;
            })();
    
            auth.RemoveRoleFromOrgResponse = (function() {
    
                /**
                 * Properties of a RemoveRoleFromOrgResponse.
                 * @memberof infinitusai.auth
                 * @interface IRemoveRoleFromOrgResponse
                 */
    
                /**
                 * Constructs a new RemoveRoleFromOrgResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemoveRoleFromOrgResponse.
                 * @implements IRemoveRoleFromOrgResponse
                 * @constructor
                 * @param {infinitusai.auth.IRemoveRoleFromOrgResponse=} [properties] Properties to set
                 */
                function RemoveRoleFromOrgResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RemoveRoleFromOrgResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromOrgResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemoveRoleFromOrgResponse} RemoveRoleFromOrgResponse instance
                 */
                RemoveRoleFromOrgResponse.create = function create(properties) {
                    return new RemoveRoleFromOrgResponse(properties);
                };
    
                /**
                 * Encodes the specified RemoveRoleFromOrgResponse message. Does not implicitly {@link infinitusai.auth.RemoveRoleFromOrgResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromOrgResponse} message RemoveRoleFromOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveRoleFromOrgResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RemoveRoleFromOrgResponse message, length delimited. Does not implicitly {@link infinitusai.auth.RemoveRoleFromOrgResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromOrgResponse} message RemoveRoleFromOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveRoleFromOrgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemoveRoleFromOrgResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemoveRoleFromOrgResponse} RemoveRoleFromOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveRoleFromOrgResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemoveRoleFromOrgResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemoveRoleFromOrgResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemoveRoleFromOrgResponse} RemoveRoleFromOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveRoleFromOrgResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemoveRoleFromOrgResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemoveRoleFromOrgResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RemoveRoleFromOrgResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemoveRoleFromOrgResponse} RemoveRoleFromOrgResponse
                 */
                RemoveRoleFromOrgResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemoveRoleFromOrgResponse)
                        return object;
                    return new $root.infinitusai.auth.RemoveRoleFromOrgResponse();
                };
    
                /**
                 * Creates a plain object from a RemoveRoleFromOrgResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.RemoveRoleFromOrgResponse} message RemoveRoleFromOrgResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemoveRoleFromOrgResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RemoveRoleFromOrgResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemoveRoleFromOrgResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemoveRoleFromOrgResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemoveRoleFromOrgResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemoveRoleFromOrgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemoveRoleFromOrgResponse";
                };
    
                return RemoveRoleFromOrgResponse;
            })();
    
            auth.AddRoleToUserRequest = (function() {
    
                /**
                 * Properties of an AddRoleToUserRequest.
                 * @memberof infinitusai.auth
                 * @interface IAddRoleToUserRequest
                 * @property {string|null} [roleUuid] AddRoleToUserRequest roleUuid
                 * @property {string|null} [userUid] AddRoleToUserRequest userUid
                 */
    
                /**
                 * Constructs a new AddRoleToUserRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AddRoleToUserRequest.
                 * @implements IAddRoleToUserRequest
                 * @constructor
                 * @param {infinitusai.auth.IAddRoleToUserRequest=} [properties] Properties to set
                 */
                function AddRoleToUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AddRoleToUserRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @instance
                 */
                AddRoleToUserRequest.prototype.roleUuid = "";
    
                /**
                 * AddRoleToUserRequest userUid.
                 * @member {string} userUid
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @instance
                 */
                AddRoleToUserRequest.prototype.userUid = "";
    
                /**
                 * Creates a new AddRoleToUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {infinitusai.auth.IAddRoleToUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.AddRoleToUserRequest} AddRoleToUserRequest instance
                 */
                AddRoleToUserRequest.create = function create(properties) {
                    return new AddRoleToUserRequest(properties);
                };
    
                /**
                 * Encodes the specified AddRoleToUserRequest message. Does not implicitly {@link infinitusai.auth.AddRoleToUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {infinitusai.auth.IAddRoleToUserRequest} message AddRoleToUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddRoleToUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleUuid);
                    if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userUid);
                    return writer;
                };
    
                /**
                 * Encodes the specified AddRoleToUserRequest message, length delimited. Does not implicitly {@link infinitusai.auth.AddRoleToUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {infinitusai.auth.IAddRoleToUserRequest} message AddRoleToUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddRoleToUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddRoleToUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AddRoleToUserRequest} AddRoleToUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddRoleToUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AddRoleToUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.userUid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddRoleToUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AddRoleToUserRequest} AddRoleToUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddRoleToUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddRoleToUserRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddRoleToUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        if (!$util.isString(message.userUid))
                            return "userUid: string expected";
                    return null;
                };
    
                /**
                 * Creates an AddRoleToUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AddRoleToUserRequest} AddRoleToUserRequest
                 */
                AddRoleToUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AddRoleToUserRequest)
                        return object;
                    var message = new $root.infinitusai.auth.AddRoleToUserRequest();
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    if (object.userUid != null)
                        message.userUid = String(object.userUid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AddRoleToUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {infinitusai.auth.AddRoleToUserRequest} message AddRoleToUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddRoleToUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.roleUuid = "";
                        object.userUid = "";
                    }
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        object.userUid = message.userUid;
                    return object;
                };
    
                /**
                 * Converts this AddRoleToUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddRoleToUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddRoleToUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AddRoleToUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddRoleToUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AddRoleToUserRequest";
                };
    
                return AddRoleToUserRequest;
            })();
    
            auth.AddRoleToUserResponse = (function() {
    
                /**
                 * Properties of an AddRoleToUserResponse.
                 * @memberof infinitusai.auth
                 * @interface IAddRoleToUserResponse
                 */
    
                /**
                 * Constructs a new AddRoleToUserResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AddRoleToUserResponse.
                 * @implements IAddRoleToUserResponse
                 * @constructor
                 * @param {infinitusai.auth.IAddRoleToUserResponse=} [properties] Properties to set
                 */
                function AddRoleToUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new AddRoleToUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {infinitusai.auth.IAddRoleToUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.AddRoleToUserResponse} AddRoleToUserResponse instance
                 */
                AddRoleToUserResponse.create = function create(properties) {
                    return new AddRoleToUserResponse(properties);
                };
    
                /**
                 * Encodes the specified AddRoleToUserResponse message. Does not implicitly {@link infinitusai.auth.AddRoleToUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {infinitusai.auth.IAddRoleToUserResponse} message AddRoleToUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddRoleToUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified AddRoleToUserResponse message, length delimited. Does not implicitly {@link infinitusai.auth.AddRoleToUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {infinitusai.auth.IAddRoleToUserResponse} message AddRoleToUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddRoleToUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddRoleToUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AddRoleToUserResponse} AddRoleToUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddRoleToUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AddRoleToUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddRoleToUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AddRoleToUserResponse} AddRoleToUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddRoleToUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddRoleToUserResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddRoleToUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an AddRoleToUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AddRoleToUserResponse} AddRoleToUserResponse
                 */
                AddRoleToUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AddRoleToUserResponse)
                        return object;
                    return new $root.infinitusai.auth.AddRoleToUserResponse();
                };
    
                /**
                 * Creates a plain object from an AddRoleToUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {infinitusai.auth.AddRoleToUserResponse} message AddRoleToUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddRoleToUserResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this AddRoleToUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddRoleToUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddRoleToUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AddRoleToUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddRoleToUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AddRoleToUserResponse";
                };
    
                return AddRoleToUserResponse;
            })();
    
            auth.RemoveRoleFromUserRequest = (function() {
    
                /**
                 * Properties of a RemoveRoleFromUserRequest.
                 * @memberof infinitusai.auth
                 * @interface IRemoveRoleFromUserRequest
                 * @property {string|null} [roleUuid] RemoveRoleFromUserRequest roleUuid
                 * @property {string|null} [userUid] RemoveRoleFromUserRequest userUid
                 */
    
                /**
                 * Constructs a new RemoveRoleFromUserRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemoveRoleFromUserRequest.
                 * @implements IRemoveRoleFromUserRequest
                 * @constructor
                 * @param {infinitusai.auth.IRemoveRoleFromUserRequest=} [properties] Properties to set
                 */
                function RemoveRoleFromUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RemoveRoleFromUserRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @instance
                 */
                RemoveRoleFromUserRequest.prototype.roleUuid = "";
    
                /**
                 * RemoveRoleFromUserRequest userUid.
                 * @member {string} userUid
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @instance
                 */
                RemoveRoleFromUserRequest.prototype.userUid = "";
    
                /**
                 * Creates a new RemoveRoleFromUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemoveRoleFromUserRequest} RemoveRoleFromUserRequest instance
                 */
                RemoveRoleFromUserRequest.create = function create(properties) {
                    return new RemoveRoleFromUserRequest(properties);
                };
    
                /**
                 * Encodes the specified RemoveRoleFromUserRequest message. Does not implicitly {@link infinitusai.auth.RemoveRoleFromUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromUserRequest} message RemoveRoleFromUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveRoleFromUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roleUuid);
                    if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userUid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RemoveRoleFromUserRequest message, length delimited. Does not implicitly {@link infinitusai.auth.RemoveRoleFromUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromUserRequest} message RemoveRoleFromUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveRoleFromUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemoveRoleFromUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemoveRoleFromUserRequest} RemoveRoleFromUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveRoleFromUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemoveRoleFromUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.userUid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemoveRoleFromUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemoveRoleFromUserRequest} RemoveRoleFromUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveRoleFromUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemoveRoleFromUserRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemoveRoleFromUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        if (!$util.isString(message.userUid))
                            return "userUid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RemoveRoleFromUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemoveRoleFromUserRequest} RemoveRoleFromUserRequest
                 */
                RemoveRoleFromUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemoveRoleFromUserRequest)
                        return object;
                    var message = new $root.infinitusai.auth.RemoveRoleFromUserRequest();
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    if (object.userUid != null)
                        message.userUid = String(object.userUid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RemoveRoleFromUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {infinitusai.auth.RemoveRoleFromUserRequest} message RemoveRoleFromUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemoveRoleFromUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.roleUuid = "";
                        object.userUid = "";
                    }
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        object.userUid = message.userUid;
                    return object;
                };
    
                /**
                 * Converts this RemoveRoleFromUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemoveRoleFromUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemoveRoleFromUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemoveRoleFromUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemoveRoleFromUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemoveRoleFromUserRequest";
                };
    
                return RemoveRoleFromUserRequest;
            })();
    
            auth.RemoveRoleFromUserResponse = (function() {
    
                /**
                 * Properties of a RemoveRoleFromUserResponse.
                 * @memberof infinitusai.auth
                 * @interface IRemoveRoleFromUserResponse
                 */
    
                /**
                 * Constructs a new RemoveRoleFromUserResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemoveRoleFromUserResponse.
                 * @implements IRemoveRoleFromUserResponse
                 * @constructor
                 * @param {infinitusai.auth.IRemoveRoleFromUserResponse=} [properties] Properties to set
                 */
                function RemoveRoleFromUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RemoveRoleFromUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemoveRoleFromUserResponse} RemoveRoleFromUserResponse instance
                 */
                RemoveRoleFromUserResponse.create = function create(properties) {
                    return new RemoveRoleFromUserResponse(properties);
                };
    
                /**
                 * Encodes the specified RemoveRoleFromUserResponse message. Does not implicitly {@link infinitusai.auth.RemoveRoleFromUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromUserResponse} message RemoveRoleFromUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveRoleFromUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RemoveRoleFromUserResponse message, length delimited. Does not implicitly {@link infinitusai.auth.RemoveRoleFromUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveRoleFromUserResponse} message RemoveRoleFromUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveRoleFromUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemoveRoleFromUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemoveRoleFromUserResponse} RemoveRoleFromUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveRoleFromUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemoveRoleFromUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemoveRoleFromUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemoveRoleFromUserResponse} RemoveRoleFromUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveRoleFromUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemoveRoleFromUserResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemoveRoleFromUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RemoveRoleFromUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemoveRoleFromUserResponse} RemoveRoleFromUserResponse
                 */
                RemoveRoleFromUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemoveRoleFromUserResponse)
                        return object;
                    return new $root.infinitusai.auth.RemoveRoleFromUserResponse();
                };
    
                /**
                 * Creates a plain object from a RemoveRoleFromUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {infinitusai.auth.RemoveRoleFromUserResponse} message RemoveRoleFromUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemoveRoleFromUserResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RemoveRoleFromUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemoveRoleFromUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemoveRoleFromUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemoveRoleFromUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemoveRoleFromUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemoveRoleFromUserResponse";
                };
    
                return RemoveRoleFromUserResponse;
            })();
    
            auth.GetUserRolesRequest = (function() {
    
                /**
                 * Properties of a GetUserRolesRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetUserRolesRequest
                 * @property {string|null} [userUid] GetUserRolesRequest userUid
                 * @property {Array.<infinitusai.auth.RoleType>|null} [types] GetUserRolesRequest types
                 */
    
                /**
                 * Constructs a new GetUserRolesRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUserRolesRequest.
                 * @implements IGetUserRolesRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetUserRolesRequest=} [properties] Properties to set
                 */
                function GetUserRolesRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserRolesRequest userUid.
                 * @member {string} userUid
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @instance
                 */
                GetUserRolesRequest.prototype.userUid = "";
    
                /**
                 * GetUserRolesRequest types.
                 * @member {Array.<infinitusai.auth.RoleType>} types
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @instance
                 */
                GetUserRolesRequest.prototype.types = $util.emptyArray;
    
                /**
                 * Creates a new GetUserRolesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserRolesRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUserRolesRequest} GetUserRolesRequest instance
                 */
                GetUserRolesRequest.create = function create(properties) {
                    return new GetUserRolesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetUserRolesRequest message. Does not implicitly {@link infinitusai.auth.GetUserRolesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserRolesRequest} message GetUserRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserRolesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUid);
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserRolesRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetUserRolesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserRolesRequest} message GetUserRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserRolesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserRolesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUserRolesRequest} GetUserRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserRolesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUserRolesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userUid = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserRolesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUserRolesRequest} GetUserRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserRolesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserRolesRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserRolesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        if (!$util.isString(message.userUid))
                            return "userUid: string expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetUserRolesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUserRolesRequest} GetUserRolesRequest
                 */
                GetUserRolesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUserRolesRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetUserRolesRequest();
                    if (object.userUid != null)
                        message.userUid = String(object.userUid);
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetUserRolesRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "ROLE_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "ROLE_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "ROLE_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "ROLE_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserRolesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {infinitusai.auth.GetUserRolesRequest} message GetUserRolesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserRolesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (options.defaults)
                        object.userUid = "";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        object.userUid = message.userUid;
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.RoleType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.RoleType[message.types[j]] : message.types[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetUserRolesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserRolesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserRolesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUserRolesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserRolesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUserRolesRequest";
                };
    
                return GetUserRolesRequest;
            })();
    
            auth.GetUserRolesResponse = (function() {
    
                /**
                 * Properties of a GetUserRolesResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetUserRolesResponse
                 * @property {Array.<infinitusai.auth.IRole>|null} [roles] GetUserRolesResponse roles
                 */
    
                /**
                 * Constructs a new GetUserRolesResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUserRolesResponse.
                 * @implements IGetUserRolesResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetUserRolesResponse=} [properties] Properties to set
                 */
                function GetUserRolesResponse(properties) {
                    this.roles = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserRolesResponse roles.
                 * @member {Array.<infinitusai.auth.IRole>} roles
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @instance
                 */
                GetUserRolesResponse.prototype.roles = $util.emptyArray;
    
                /**
                 * Creates a new GetUserRolesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserRolesResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUserRolesResponse} GetUserRolesResponse instance
                 */
                GetUserRolesResponse.create = function create(properties) {
                    return new GetUserRolesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetUserRolesResponse message. Does not implicitly {@link infinitusai.auth.GetUserRolesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserRolesResponse} message GetUserRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserRolesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roles != null && message.roles.length)
                        for (var i = 0; i < message.roles.length; ++i)
                            $root.infinitusai.auth.Role.encode(message.roles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserRolesResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetUserRolesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserRolesResponse} message GetUserRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserRolesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserRolesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUserRolesResponse} GetUserRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserRolesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUserRolesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.roles && message.roles.length))
                                    message.roles = [];
                                message.roles.push($root.infinitusai.auth.Role.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserRolesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUserRolesResponse} GetUserRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserRolesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserRolesResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserRolesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roles != null && message.hasOwnProperty("roles")) {
                        if (!Array.isArray(message.roles))
                            return "roles: array expected";
                        for (var i = 0; i < message.roles.length; ++i) {
                            var error = $root.infinitusai.auth.Role.verify(message.roles[i]);
                            if (error)
                                return "roles." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetUserRolesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUserRolesResponse} GetUserRolesResponse
                 */
                GetUserRolesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUserRolesResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetUserRolesResponse();
                    if (object.roles) {
                        if (!Array.isArray(object.roles))
                            throw TypeError(".infinitusai.auth.GetUserRolesResponse.roles: array expected");
                        message.roles = [];
                        for (var i = 0; i < object.roles.length; ++i) {
                            if (typeof object.roles[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetUserRolesResponse.roles: object expected");
                            message.roles[i] = $root.infinitusai.auth.Role.fromObject(object.roles[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserRolesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {infinitusai.auth.GetUserRolesResponse} message GetUserRolesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserRolesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.roles = [];
                    if (message.roles && message.roles.length) {
                        object.roles = [];
                        for (var j = 0; j < message.roles.length; ++j)
                            object.roles[j] = $root.infinitusai.auth.Role.toObject(message.roles[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetUserRolesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserRolesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserRolesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUserRolesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserRolesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUserRolesResponse";
                };
    
                return GetUserRolesResponse;
            })();
    
            auth.GetMyUserRolesRequest = (function() {
    
                /**
                 * Properties of a GetMyUserRolesRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetMyUserRolesRequest
                 * @property {Array.<infinitusai.auth.RoleType>|null} [types] GetMyUserRolesRequest types
                 */
    
                /**
                 * Constructs a new GetMyUserRolesRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetMyUserRolesRequest.
                 * @implements IGetMyUserRolesRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetMyUserRolesRequest=} [properties] Properties to set
                 */
                function GetMyUserRolesRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyUserRolesRequest types.
                 * @member {Array.<infinitusai.auth.RoleType>} types
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @instance
                 */
                GetMyUserRolesRequest.prototype.types = $util.emptyArray;
    
                /**
                 * Creates a new GetMyUserRolesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyUserRolesRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetMyUserRolesRequest} GetMyUserRolesRequest instance
                 */
                GetMyUserRolesRequest.create = function create(properties) {
                    return new GetMyUserRolesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetMyUserRolesRequest message. Does not implicitly {@link infinitusai.auth.GetMyUserRolesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyUserRolesRequest} message GetMyUserRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyUserRolesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyUserRolesRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetMyUserRolesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyUserRolesRequest} message GetMyUserRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyUserRolesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyUserRolesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetMyUserRolesRequest} GetMyUserRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyUserRolesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetMyUserRolesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyUserRolesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetMyUserRolesRequest} GetMyUserRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyUserRolesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyUserRolesRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyUserRolesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetMyUserRolesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetMyUserRolesRequest} GetMyUserRolesRequest
                 */
                GetMyUserRolesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetMyUserRolesRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetMyUserRolesRequest();
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetMyUserRolesRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "ROLE_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "ROLE_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "ROLE_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "ROLE_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyUserRolesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {infinitusai.auth.GetMyUserRolesRequest} message GetMyUserRolesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyUserRolesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.RoleType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.RoleType[message.types[j]] : message.types[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetMyUserRolesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyUserRolesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyUserRolesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetMyUserRolesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyUserRolesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetMyUserRolesRequest";
                };
    
                return GetMyUserRolesRequest;
            })();
    
            auth.GetMyUserRolesResponse = (function() {
    
                /**
                 * Properties of a GetMyUserRolesResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetMyUserRolesResponse
                 * @property {Array.<infinitusai.auth.IRole>|null} [roles] GetMyUserRolesResponse roles
                 */
    
                /**
                 * Constructs a new GetMyUserRolesResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetMyUserRolesResponse.
                 * @implements IGetMyUserRolesResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetMyUserRolesResponse=} [properties] Properties to set
                 */
                function GetMyUserRolesResponse(properties) {
                    this.roles = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyUserRolesResponse roles.
                 * @member {Array.<infinitusai.auth.IRole>} roles
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @instance
                 */
                GetMyUserRolesResponse.prototype.roles = $util.emptyArray;
    
                /**
                 * Creates a new GetMyUserRolesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyUserRolesResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetMyUserRolesResponse} GetMyUserRolesResponse instance
                 */
                GetMyUserRolesResponse.create = function create(properties) {
                    return new GetMyUserRolesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetMyUserRolesResponse message. Does not implicitly {@link infinitusai.auth.GetMyUserRolesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyUserRolesResponse} message GetMyUserRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyUserRolesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roles != null && message.roles.length)
                        for (var i = 0; i < message.roles.length; ++i)
                            $root.infinitusai.auth.Role.encode(message.roles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyUserRolesResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetMyUserRolesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyUserRolesResponse} message GetMyUserRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyUserRolesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyUserRolesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetMyUserRolesResponse} GetMyUserRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyUserRolesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetMyUserRolesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.roles && message.roles.length))
                                    message.roles = [];
                                message.roles.push($root.infinitusai.auth.Role.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyUserRolesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetMyUserRolesResponse} GetMyUserRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyUserRolesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyUserRolesResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyUserRolesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roles != null && message.hasOwnProperty("roles")) {
                        if (!Array.isArray(message.roles))
                            return "roles: array expected";
                        for (var i = 0; i < message.roles.length; ++i) {
                            var error = $root.infinitusai.auth.Role.verify(message.roles[i]);
                            if (error)
                                return "roles." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetMyUserRolesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetMyUserRolesResponse} GetMyUserRolesResponse
                 */
                GetMyUserRolesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetMyUserRolesResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetMyUserRolesResponse();
                    if (object.roles) {
                        if (!Array.isArray(object.roles))
                            throw TypeError(".infinitusai.auth.GetMyUserRolesResponse.roles: array expected");
                        message.roles = [];
                        for (var i = 0; i < object.roles.length; ++i) {
                            if (typeof object.roles[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetMyUserRolesResponse.roles: object expected");
                            message.roles[i] = $root.infinitusai.auth.Role.fromObject(object.roles[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyUserRolesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {infinitusai.auth.GetMyUserRolesResponse} message GetMyUserRolesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyUserRolesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.roles = [];
                    if (message.roles && message.roles.length) {
                        object.roles = [];
                        for (var j = 0; j < message.roles.length; ++j)
                            object.roles[j] = $root.infinitusai.auth.Role.toObject(message.roles[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetMyUserRolesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyUserRolesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyUserRolesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetMyUserRolesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyUserRolesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetMyUserRolesResponse";
                };
    
                return GetMyUserRolesResponse;
            })();
    
            auth.CancelInviteRequest = (function() {
    
                /**
                 * Properties of a CancelInviteRequest.
                 * @memberof infinitusai.auth
                 * @interface ICancelInviteRequest
                 * @property {string|null} [uuid] CancelInviteRequest uuid
                 */
    
                /**
                 * Constructs a new CancelInviteRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CancelInviteRequest.
                 * @implements ICancelInviteRequest
                 * @constructor
                 * @param {infinitusai.auth.ICancelInviteRequest=} [properties] Properties to set
                 */
                function CancelInviteRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CancelInviteRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @instance
                 */
                CancelInviteRequest.prototype.uuid = "";
    
                /**
                 * Creates a new CancelInviteRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {infinitusai.auth.ICancelInviteRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.CancelInviteRequest} CancelInviteRequest instance
                 */
                CancelInviteRequest.create = function create(properties) {
                    return new CancelInviteRequest(properties);
                };
    
                /**
                 * Encodes the specified CancelInviteRequest message. Does not implicitly {@link infinitusai.auth.CancelInviteRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {infinitusai.auth.ICancelInviteRequest} message CancelInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelInviteRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CancelInviteRequest message, length delimited. Does not implicitly {@link infinitusai.auth.CancelInviteRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {infinitusai.auth.ICancelInviteRequest} message CancelInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CancelInviteRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CancelInviteRequest} CancelInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelInviteRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CancelInviteRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CancelInviteRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CancelInviteRequest} CancelInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelInviteRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CancelInviteRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CancelInviteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CancelInviteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CancelInviteRequest} CancelInviteRequest
                 */
                CancelInviteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CancelInviteRequest)
                        return object;
                    var message = new $root.infinitusai.auth.CancelInviteRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CancelInviteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {infinitusai.auth.CancelInviteRequest} message CancelInviteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CancelInviteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this CancelInviteRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CancelInviteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CancelInviteRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CancelInviteRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CancelInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CancelInviteRequest";
                };
    
                return CancelInviteRequest;
            })();
    
            auth.CancelInviteResponse = (function() {
    
                /**
                 * Properties of a CancelInviteResponse.
                 * @memberof infinitusai.auth
                 * @interface ICancelInviteResponse
                 * @property {infinitusai.auth.IInvite|null} [invite] CancelInviteResponse invite
                 */
    
                /**
                 * Constructs a new CancelInviteResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CancelInviteResponse.
                 * @implements ICancelInviteResponse
                 * @constructor
                 * @param {infinitusai.auth.ICancelInviteResponse=} [properties] Properties to set
                 */
                function CancelInviteResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CancelInviteResponse invite.
                 * @member {infinitusai.auth.IInvite|null|undefined} invite
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @instance
                 */
                CancelInviteResponse.prototype.invite = null;
    
                /**
                 * Creates a new CancelInviteResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {infinitusai.auth.ICancelInviteResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.CancelInviteResponse} CancelInviteResponse instance
                 */
                CancelInviteResponse.create = function create(properties) {
                    return new CancelInviteResponse(properties);
                };
    
                /**
                 * Encodes the specified CancelInviteResponse message. Does not implicitly {@link infinitusai.auth.CancelInviteResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {infinitusai.auth.ICancelInviteResponse} message CancelInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelInviteResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                        $root.infinitusai.auth.Invite.encode(message.invite, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CancelInviteResponse message, length delimited. Does not implicitly {@link infinitusai.auth.CancelInviteResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {infinitusai.auth.ICancelInviteResponse} message CancelInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CancelInviteResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CancelInviteResponse} CancelInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelInviteResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CancelInviteResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.invite = $root.infinitusai.auth.Invite.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CancelInviteResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CancelInviteResponse} CancelInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelInviteResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CancelInviteResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CancelInviteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invite != null && message.hasOwnProperty("invite")) {
                        var error = $root.infinitusai.auth.Invite.verify(message.invite);
                        if (error)
                            return "invite." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CancelInviteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CancelInviteResponse} CancelInviteResponse
                 */
                CancelInviteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CancelInviteResponse)
                        return object;
                    var message = new $root.infinitusai.auth.CancelInviteResponse();
                    if (object.invite != null) {
                        if (typeof object.invite !== "object")
                            throw TypeError(".infinitusai.auth.CancelInviteResponse.invite: object expected");
                        message.invite = $root.infinitusai.auth.Invite.fromObject(object.invite);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CancelInviteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {infinitusai.auth.CancelInviteResponse} message CancelInviteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CancelInviteResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.invite = null;
                    if (message.invite != null && message.hasOwnProperty("invite"))
                        object.invite = $root.infinitusai.auth.Invite.toObject(message.invite, options);
                    return object;
                };
    
                /**
                 * Converts this CancelInviteResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CancelInviteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CancelInviteResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CancelInviteResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CancelInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CancelInviteResponse";
                };
    
                return CancelInviteResponse;
            })();
    
            auth.DeclineInviteRequest = (function() {
    
                /**
                 * Properties of a DeclineInviteRequest.
                 * @memberof infinitusai.auth
                 * @interface IDeclineInviteRequest
                 * @property {string|null} [uuid] DeclineInviteRequest uuid
                 */
    
                /**
                 * Constructs a new DeclineInviteRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeclineInviteRequest.
                 * @implements IDeclineInviteRequest
                 * @constructor
                 * @param {infinitusai.auth.IDeclineInviteRequest=} [properties] Properties to set
                 */
                function DeclineInviteRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeclineInviteRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @instance
                 */
                DeclineInviteRequest.prototype.uuid = "";
    
                /**
                 * Creates a new DeclineInviteRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {infinitusai.auth.IDeclineInviteRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeclineInviteRequest} DeclineInviteRequest instance
                 */
                DeclineInviteRequest.create = function create(properties) {
                    return new DeclineInviteRequest(properties);
                };
    
                /**
                 * Encodes the specified DeclineInviteRequest message. Does not implicitly {@link infinitusai.auth.DeclineInviteRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {infinitusai.auth.IDeclineInviteRequest} message DeclineInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeclineInviteRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeclineInviteRequest message, length delimited. Does not implicitly {@link infinitusai.auth.DeclineInviteRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {infinitusai.auth.IDeclineInviteRequest} message DeclineInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeclineInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeclineInviteRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeclineInviteRequest} DeclineInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeclineInviteRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeclineInviteRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeclineInviteRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeclineInviteRequest} DeclineInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeclineInviteRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeclineInviteRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeclineInviteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeclineInviteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeclineInviteRequest} DeclineInviteRequest
                 */
                DeclineInviteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeclineInviteRequest)
                        return object;
                    var message = new $root.infinitusai.auth.DeclineInviteRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeclineInviteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {infinitusai.auth.DeclineInviteRequest} message DeclineInviteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeclineInviteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this DeclineInviteRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeclineInviteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeclineInviteRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeclineInviteRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeclineInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeclineInviteRequest";
                };
    
                return DeclineInviteRequest;
            })();
    
            auth.DeclineInviteResponse = (function() {
    
                /**
                 * Properties of a DeclineInviteResponse.
                 * @memberof infinitusai.auth
                 * @interface IDeclineInviteResponse
                 * @property {infinitusai.auth.IInvite|null} [invite] DeclineInviteResponse invite
                 */
    
                /**
                 * Constructs a new DeclineInviteResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeclineInviteResponse.
                 * @implements IDeclineInviteResponse
                 * @constructor
                 * @param {infinitusai.auth.IDeclineInviteResponse=} [properties] Properties to set
                 */
                function DeclineInviteResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeclineInviteResponse invite.
                 * @member {infinitusai.auth.IInvite|null|undefined} invite
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @instance
                 */
                DeclineInviteResponse.prototype.invite = null;
    
                /**
                 * Creates a new DeclineInviteResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {infinitusai.auth.IDeclineInviteResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeclineInviteResponse} DeclineInviteResponse instance
                 */
                DeclineInviteResponse.create = function create(properties) {
                    return new DeclineInviteResponse(properties);
                };
    
                /**
                 * Encodes the specified DeclineInviteResponse message. Does not implicitly {@link infinitusai.auth.DeclineInviteResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {infinitusai.auth.IDeclineInviteResponse} message DeclineInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeclineInviteResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                        $root.infinitusai.auth.Invite.encode(message.invite, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeclineInviteResponse message, length delimited. Does not implicitly {@link infinitusai.auth.DeclineInviteResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {infinitusai.auth.IDeclineInviteResponse} message DeclineInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeclineInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeclineInviteResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeclineInviteResponse} DeclineInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeclineInviteResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeclineInviteResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.invite = $root.infinitusai.auth.Invite.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeclineInviteResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeclineInviteResponse} DeclineInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeclineInviteResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeclineInviteResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeclineInviteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invite != null && message.hasOwnProperty("invite")) {
                        var error = $root.infinitusai.auth.Invite.verify(message.invite);
                        if (error)
                            return "invite." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a DeclineInviteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeclineInviteResponse} DeclineInviteResponse
                 */
                DeclineInviteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeclineInviteResponse)
                        return object;
                    var message = new $root.infinitusai.auth.DeclineInviteResponse();
                    if (object.invite != null) {
                        if (typeof object.invite !== "object")
                            throw TypeError(".infinitusai.auth.DeclineInviteResponse.invite: object expected");
                        message.invite = $root.infinitusai.auth.Invite.fromObject(object.invite);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeclineInviteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {infinitusai.auth.DeclineInviteResponse} message DeclineInviteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeclineInviteResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.invite = null;
                    if (message.invite != null && message.hasOwnProperty("invite"))
                        object.invite = $root.infinitusai.auth.Invite.toObject(message.invite, options);
                    return object;
                };
    
                /**
                 * Converts this DeclineInviteResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeclineInviteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeclineInviteResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeclineInviteResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeclineInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeclineInviteResponse";
                };
    
                return DeclineInviteResponse;
            })();
    
            auth.AcceptInviteRequest = (function() {
    
                /**
                 * Properties of an AcceptInviteRequest.
                 * @memberof infinitusai.auth
                 * @interface IAcceptInviteRequest
                 * @property {string|null} [uuid] AcceptInviteRequest uuid
                 */
    
                /**
                 * Constructs a new AcceptInviteRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AcceptInviteRequest.
                 * @implements IAcceptInviteRequest
                 * @constructor
                 * @param {infinitusai.auth.IAcceptInviteRequest=} [properties] Properties to set
                 */
                function AcceptInviteRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AcceptInviteRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @instance
                 */
                AcceptInviteRequest.prototype.uuid = "";
    
                /**
                 * Creates a new AcceptInviteRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {infinitusai.auth.IAcceptInviteRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.AcceptInviteRequest} AcceptInviteRequest instance
                 */
                AcceptInviteRequest.create = function create(properties) {
                    return new AcceptInviteRequest(properties);
                };
    
                /**
                 * Encodes the specified AcceptInviteRequest message. Does not implicitly {@link infinitusai.auth.AcceptInviteRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {infinitusai.auth.IAcceptInviteRequest} message AcceptInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcceptInviteRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified AcceptInviteRequest message, length delimited. Does not implicitly {@link infinitusai.auth.AcceptInviteRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {infinitusai.auth.IAcceptInviteRequest} message AcceptInviteRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcceptInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AcceptInviteRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AcceptInviteRequest} AcceptInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcceptInviteRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AcceptInviteRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AcceptInviteRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AcceptInviteRequest} AcceptInviteRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcceptInviteRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AcceptInviteRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AcceptInviteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates an AcceptInviteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AcceptInviteRequest} AcceptInviteRequest
                 */
                AcceptInviteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AcceptInviteRequest)
                        return object;
                    var message = new $root.infinitusai.auth.AcceptInviteRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AcceptInviteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {infinitusai.auth.AcceptInviteRequest} message AcceptInviteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AcceptInviteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this AcceptInviteRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AcceptInviteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AcceptInviteRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AcceptInviteRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AcceptInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AcceptInviteRequest";
                };
    
                return AcceptInviteRequest;
            })();
    
            auth.AcceptInviteResponse = (function() {
    
                /**
                 * Properties of an AcceptInviteResponse.
                 * @memberof infinitusai.auth
                 * @interface IAcceptInviteResponse
                 * @property {infinitusai.auth.IInvite|null} [invite] AcceptInviteResponse invite
                 */
    
                /**
                 * Constructs a new AcceptInviteResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AcceptInviteResponse.
                 * @implements IAcceptInviteResponse
                 * @constructor
                 * @param {infinitusai.auth.IAcceptInviteResponse=} [properties] Properties to set
                 */
                function AcceptInviteResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AcceptInviteResponse invite.
                 * @member {infinitusai.auth.IInvite|null|undefined} invite
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @instance
                 */
                AcceptInviteResponse.prototype.invite = null;
    
                /**
                 * Creates a new AcceptInviteResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {infinitusai.auth.IAcceptInviteResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.AcceptInviteResponse} AcceptInviteResponse instance
                 */
                AcceptInviteResponse.create = function create(properties) {
                    return new AcceptInviteResponse(properties);
                };
    
                /**
                 * Encodes the specified AcceptInviteResponse message. Does not implicitly {@link infinitusai.auth.AcceptInviteResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {infinitusai.auth.IAcceptInviteResponse} message AcceptInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcceptInviteResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                        $root.infinitusai.auth.Invite.encode(message.invite, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified AcceptInviteResponse message, length delimited. Does not implicitly {@link infinitusai.auth.AcceptInviteResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {infinitusai.auth.IAcceptInviteResponse} message AcceptInviteResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcceptInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AcceptInviteResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AcceptInviteResponse} AcceptInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcceptInviteResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AcceptInviteResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.invite = $root.infinitusai.auth.Invite.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AcceptInviteResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AcceptInviteResponse} AcceptInviteResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcceptInviteResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AcceptInviteResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AcceptInviteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invite != null && message.hasOwnProperty("invite")) {
                        var error = $root.infinitusai.auth.Invite.verify(message.invite);
                        if (error)
                            return "invite." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an AcceptInviteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AcceptInviteResponse} AcceptInviteResponse
                 */
                AcceptInviteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AcceptInviteResponse)
                        return object;
                    var message = new $root.infinitusai.auth.AcceptInviteResponse();
                    if (object.invite != null) {
                        if (typeof object.invite !== "object")
                            throw TypeError(".infinitusai.auth.AcceptInviteResponse.invite: object expected");
                        message.invite = $root.infinitusai.auth.Invite.fromObject(object.invite);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AcceptInviteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {infinitusai.auth.AcceptInviteResponse} message AcceptInviteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AcceptInviteResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.invite = null;
                    if (message.invite != null && message.hasOwnProperty("invite"))
                        object.invite = $root.infinitusai.auth.Invite.toObject(message.invite, options);
                    return object;
                };
    
                /**
                 * Converts this AcceptInviteResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AcceptInviteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AcceptInviteResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AcceptInviteResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AcceptInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AcceptInviteResponse";
                };
    
                return AcceptInviteResponse;
            })();
    
            auth.RemoveUserFromOrgRequest = (function() {
    
                /**
                 * Properties of a RemoveUserFromOrgRequest.
                 * @memberof infinitusai.auth
                 * @interface IRemoveUserFromOrgRequest
                 * @property {string|null} [userUid] RemoveUserFromOrgRequest userUid
                 */
    
                /**
                 * Constructs a new RemoveUserFromOrgRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemoveUserFromOrgRequest.
                 * @implements IRemoveUserFromOrgRequest
                 * @constructor
                 * @param {infinitusai.auth.IRemoveUserFromOrgRequest=} [properties] Properties to set
                 */
                function RemoveUserFromOrgRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RemoveUserFromOrgRequest userUid.
                 * @member {string} userUid
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @instance
                 */
                RemoveUserFromOrgRequest.prototype.userUid = "";
    
                /**
                 * Creates a new RemoveUserFromOrgRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveUserFromOrgRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemoveUserFromOrgRequest} RemoveUserFromOrgRequest instance
                 */
                RemoveUserFromOrgRequest.create = function create(properties) {
                    return new RemoveUserFromOrgRequest(properties);
                };
    
                /**
                 * Encodes the specified RemoveUserFromOrgRequest message. Does not implicitly {@link infinitusai.auth.RemoveUserFromOrgRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveUserFromOrgRequest} message RemoveUserFromOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveUserFromOrgRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUid);
                    return writer;
                };
    
                /**
                 * Encodes the specified RemoveUserFromOrgRequest message, length delimited. Does not implicitly {@link infinitusai.auth.RemoveUserFromOrgRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.IRemoveUserFromOrgRequest} message RemoveUserFromOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveUserFromOrgRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemoveUserFromOrgRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemoveUserFromOrgRequest} RemoveUserFromOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveUserFromOrgRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemoveUserFromOrgRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userUid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemoveUserFromOrgRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemoveUserFromOrgRequest} RemoveUserFromOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveUserFromOrgRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemoveUserFromOrgRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemoveUserFromOrgRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        if (!$util.isString(message.userUid))
                            return "userUid: string expected";
                    return null;
                };
    
                /**
                 * Creates a RemoveUserFromOrgRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemoveUserFromOrgRequest} RemoveUserFromOrgRequest
                 */
                RemoveUserFromOrgRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemoveUserFromOrgRequest)
                        return object;
                    var message = new $root.infinitusai.auth.RemoveUserFromOrgRequest();
                    if (object.userUid != null)
                        message.userUid = String(object.userUid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RemoveUserFromOrgRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {infinitusai.auth.RemoveUserFromOrgRequest} message RemoveUserFromOrgRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemoveUserFromOrgRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userUid = "";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        object.userUid = message.userUid;
                    return object;
                };
    
                /**
                 * Converts this RemoveUserFromOrgRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemoveUserFromOrgRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemoveUserFromOrgRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemoveUserFromOrgRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemoveUserFromOrgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemoveUserFromOrgRequest";
                };
    
                return RemoveUserFromOrgRequest;
            })();
    
            auth.RemoveUserFromOrgResponse = (function() {
    
                /**
                 * Properties of a RemoveUserFromOrgResponse.
                 * @memberof infinitusai.auth
                 * @interface IRemoveUserFromOrgResponse
                 */
    
                /**
                 * Constructs a new RemoveUserFromOrgResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a RemoveUserFromOrgResponse.
                 * @implements IRemoveUserFromOrgResponse
                 * @constructor
                 * @param {infinitusai.auth.IRemoveUserFromOrgResponse=} [properties] Properties to set
                 */
                function RemoveUserFromOrgResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new RemoveUserFromOrgResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveUserFromOrgResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.RemoveUserFromOrgResponse} RemoveUserFromOrgResponse instance
                 */
                RemoveUserFromOrgResponse.create = function create(properties) {
                    return new RemoveUserFromOrgResponse(properties);
                };
    
                /**
                 * Encodes the specified RemoveUserFromOrgResponse message. Does not implicitly {@link infinitusai.auth.RemoveUserFromOrgResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveUserFromOrgResponse} message RemoveUserFromOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveUserFromOrgResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified RemoveUserFromOrgResponse message, length delimited. Does not implicitly {@link infinitusai.auth.RemoveUserFromOrgResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.IRemoveUserFromOrgResponse} message RemoveUserFromOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemoveUserFromOrgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RemoveUserFromOrgResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.RemoveUserFromOrgResponse} RemoveUserFromOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveUserFromOrgResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.RemoveUserFromOrgResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RemoveUserFromOrgResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.RemoveUserFromOrgResponse} RemoveUserFromOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemoveUserFromOrgResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RemoveUserFromOrgResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemoveUserFromOrgResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a RemoveUserFromOrgResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.RemoveUserFromOrgResponse} RemoveUserFromOrgResponse
                 */
                RemoveUserFromOrgResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.RemoveUserFromOrgResponse)
                        return object;
                    return new $root.infinitusai.auth.RemoveUserFromOrgResponse();
                };
    
                /**
                 * Creates a plain object from a RemoveUserFromOrgResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {infinitusai.auth.RemoveUserFromOrgResponse} message RemoveUserFromOrgResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemoveUserFromOrgResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this RemoveUserFromOrgResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemoveUserFromOrgResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RemoveUserFromOrgResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.RemoveUserFromOrgResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RemoveUserFromOrgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.RemoveUserFromOrgResponse";
                };
    
                return RemoveUserFromOrgResponse;
            })();
    
            auth.GetMyPermissionsRequest = (function() {
    
                /**
                 * Properties of a GetMyPermissionsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetMyPermissionsRequest
                 */
    
                /**
                 * Constructs a new GetMyPermissionsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetMyPermissionsRequest.
                 * @implements IGetMyPermissionsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetMyPermissionsRequest=} [properties] Properties to set
                 */
                function GetMyPermissionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetMyPermissionsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyPermissionsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetMyPermissionsRequest} GetMyPermissionsRequest instance
                 */
                GetMyPermissionsRequest.create = function create(properties) {
                    return new GetMyPermissionsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetMyPermissionsRequest message. Does not implicitly {@link infinitusai.auth.GetMyPermissionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyPermissionsRequest} message GetMyPermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyPermissionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyPermissionsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetMyPermissionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyPermissionsRequest} message GetMyPermissionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyPermissionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyPermissionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetMyPermissionsRequest} GetMyPermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyPermissionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetMyPermissionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyPermissionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetMyPermissionsRequest} GetMyPermissionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyPermissionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyPermissionsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyPermissionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetMyPermissionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetMyPermissionsRequest} GetMyPermissionsRequest
                 */
                GetMyPermissionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetMyPermissionsRequest)
                        return object;
                    return new $root.infinitusai.auth.GetMyPermissionsRequest();
                };
    
                /**
                 * Creates a plain object from a GetMyPermissionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {infinitusai.auth.GetMyPermissionsRequest} message GetMyPermissionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyPermissionsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetMyPermissionsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyPermissionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyPermissionsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetMyPermissionsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyPermissionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetMyPermissionsRequest";
                };
    
                return GetMyPermissionsRequest;
            })();
    
            auth.GetMyPermissionsResponse = (function() {
    
                /**
                 * Properties of a GetMyPermissionsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetMyPermissionsResponse
                 * @property {Array.<infinitusai.auth.IPermission>|null} [permissions] GetMyPermissionsResponse permissions
                 */
    
                /**
                 * Constructs a new GetMyPermissionsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetMyPermissionsResponse.
                 * @implements IGetMyPermissionsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetMyPermissionsResponse=} [properties] Properties to set
                 */
                function GetMyPermissionsResponse(properties) {
                    this.permissions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyPermissionsResponse permissions.
                 * @member {Array.<infinitusai.auth.IPermission>} permissions
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @instance
                 */
                GetMyPermissionsResponse.prototype.permissions = $util.emptyArray;
    
                /**
                 * Creates a new GetMyPermissionsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyPermissionsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetMyPermissionsResponse} GetMyPermissionsResponse instance
                 */
                GetMyPermissionsResponse.create = function create(properties) {
                    return new GetMyPermissionsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetMyPermissionsResponse message. Does not implicitly {@link infinitusai.auth.GetMyPermissionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyPermissionsResponse} message GetMyPermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyPermissionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.permissions != null && message.permissions.length)
                        for (var i = 0; i < message.permissions.length; ++i)
                            $root.infinitusai.auth.Permission.encode(message.permissions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyPermissionsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetMyPermissionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyPermissionsResponse} message GetMyPermissionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyPermissionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyPermissionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetMyPermissionsResponse} GetMyPermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyPermissionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetMyPermissionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.permissions && message.permissions.length))
                                    message.permissions = [];
                                message.permissions.push($root.infinitusai.auth.Permission.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyPermissionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetMyPermissionsResponse} GetMyPermissionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyPermissionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyPermissionsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyPermissionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.permissions != null && message.hasOwnProperty("permissions")) {
                        if (!Array.isArray(message.permissions))
                            return "permissions: array expected";
                        for (var i = 0; i < message.permissions.length; ++i) {
                            var error = $root.infinitusai.auth.Permission.verify(message.permissions[i]);
                            if (error)
                                return "permissions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetMyPermissionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetMyPermissionsResponse} GetMyPermissionsResponse
                 */
                GetMyPermissionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetMyPermissionsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetMyPermissionsResponse();
                    if (object.permissions) {
                        if (!Array.isArray(object.permissions))
                            throw TypeError(".infinitusai.auth.GetMyPermissionsResponse.permissions: array expected");
                        message.permissions = [];
                        for (var i = 0; i < object.permissions.length; ++i) {
                            if (typeof object.permissions[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetMyPermissionsResponse.permissions: object expected");
                            message.permissions[i] = $root.infinitusai.auth.Permission.fromObject(object.permissions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyPermissionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {infinitusai.auth.GetMyPermissionsResponse} message GetMyPermissionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyPermissionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.permissions = [];
                    if (message.permissions && message.permissions.length) {
                        object.permissions = [];
                        for (var j = 0; j < message.permissions.length; ++j)
                            object.permissions[j] = $root.infinitusai.auth.Permission.toObject(message.permissions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetMyPermissionsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyPermissionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyPermissionsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetMyPermissionsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyPermissionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetMyPermissionsResponse";
                };
    
                return GetMyPermissionsResponse;
            })();
    
            auth.GetOrgUsersRequest = (function() {
    
                /**
                 * Properties of a GetOrgUsersRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgUsersRequest
                 * @property {Array.<infinitusai.auth.UserType>|null} [types] GetOrgUsersRequest types
                 */
    
                /**
                 * Constructs a new GetOrgUsersRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgUsersRequest.
                 * @implements IGetOrgUsersRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgUsersRequest=} [properties] Properties to set
                 */
                function GetOrgUsersRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgUsersRequest types.
                 * @member {Array.<infinitusai.auth.UserType>} types
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @instance
                 */
                GetOrgUsersRequest.prototype.types = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgUsersRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgUsersRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgUsersRequest} GetOrgUsersRequest instance
                 */
                GetOrgUsersRequest.create = function create(properties) {
                    return new GetOrgUsersRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgUsersRequest message. Does not implicitly {@link infinitusai.auth.GetOrgUsersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgUsersRequest} message GetOrgUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgUsersRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgUsersRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgUsersRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetOrgUsersRequest} message GetOrgUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgUsersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgUsersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgUsersRequest} GetOrgUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgUsersRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgUsersRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgUsersRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgUsersRequest} GetOrgUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgUsersRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgUsersRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgUsersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgUsersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgUsersRequest} GetOrgUsersRequest
                 */
                GetOrgUsersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgUsersRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgUsersRequest();
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetOrgUsersRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "USER_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "USER_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "USER_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "USER_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgUsersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {infinitusai.auth.GetOrgUsersRequest} message GetOrgUsersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgUsersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.UserType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.UserType[message.types[j]] : message.types[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgUsersRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgUsersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgUsersRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgUsersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgUsersRequest";
                };
    
                return GetOrgUsersRequest;
            })();
    
            auth.GetOrgUsersResponse = (function() {
    
                /**
                 * Properties of a GetOrgUsersResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetOrgUsersResponse
                 * @property {Array.<infinitusai.auth.IUser>|null} [users] GetOrgUsersResponse users
                 */
    
                /**
                 * Constructs a new GetOrgUsersResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetOrgUsersResponse.
                 * @implements IGetOrgUsersResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetOrgUsersResponse=} [properties] Properties to set
                 */
                function GetOrgUsersResponse(properties) {
                    this.users = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgUsersResponse users.
                 * @member {Array.<infinitusai.auth.IUser>} users
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @instance
                 */
                GetOrgUsersResponse.prototype.users = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgUsersResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgUsersResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetOrgUsersResponse} GetOrgUsersResponse instance
                 */
                GetOrgUsersResponse.create = function create(properties) {
                    return new GetOrgUsersResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgUsersResponse message. Does not implicitly {@link infinitusai.auth.GetOrgUsersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgUsersResponse} message GetOrgUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgUsersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.users != null && message.users.length)
                        for (var i = 0; i < message.users.length; ++i)
                            $root.infinitusai.auth.User.encode(message.users[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgUsersResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetOrgUsersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetOrgUsersResponse} message GetOrgUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgUsersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgUsersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetOrgUsersResponse} GetOrgUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgUsersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetOrgUsersResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.users && message.users.length))
                                    message.users = [];
                                message.users.push($root.infinitusai.auth.User.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgUsersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetOrgUsersResponse} GetOrgUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgUsersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgUsersResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgUsersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.users != null && message.hasOwnProperty("users")) {
                        if (!Array.isArray(message.users))
                            return "users: array expected";
                        for (var i = 0; i < message.users.length; ++i) {
                            var error = $root.infinitusai.auth.User.verify(message.users[i]);
                            if (error)
                                return "users." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgUsersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetOrgUsersResponse} GetOrgUsersResponse
                 */
                GetOrgUsersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetOrgUsersResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetOrgUsersResponse();
                    if (object.users) {
                        if (!Array.isArray(object.users))
                            throw TypeError(".infinitusai.auth.GetOrgUsersResponse.users: array expected");
                        message.users = [];
                        for (var i = 0; i < object.users.length; ++i) {
                            if (typeof object.users[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetOrgUsersResponse.users: object expected");
                            message.users[i] = $root.infinitusai.auth.User.fromObject(object.users[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgUsersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {infinitusai.auth.GetOrgUsersResponse} message GetOrgUsersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgUsersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.users = [];
                    if (message.users && message.users.length) {
                        object.users = [];
                        for (var j = 0; j < message.users.length; ++j)
                            object.users[j] = $root.infinitusai.auth.User.toObject(message.users[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgUsersResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgUsersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgUsersResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetOrgUsersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetOrgUsersResponse";
                };
    
                return GetOrgUsersResponse;
            })();
    
            auth.GetRoleUsersRequest = (function() {
    
                /**
                 * Properties of a GetRoleUsersRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetRoleUsersRequest
                 * @property {number|null} [page] GetRoleUsersRequest page
                 * @property {number|null} [limit] GetRoleUsersRequest limit
                 * @property {string|null} [roleUuid] GetRoleUsersRequest roleUuid
                 * @property {string|null} [search] GetRoleUsersRequest search
                 * @property {Array.<infinitusai.auth.UserType>|null} [types] GetRoleUsersRequest types
                 * @property {string|null} [orderByCol] GetRoleUsersRequest orderByCol
                 * @property {string|null} [orderByDir] GetRoleUsersRequest orderByDir
                 */
    
                /**
                 * Constructs a new GetRoleUsersRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRoleUsersRequest.
                 * @implements IGetRoleUsersRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetRoleUsersRequest=} [properties] Properties to set
                 */
                function GetRoleUsersRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRoleUsersRequest page.
                 * @member {number} page
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @instance
                 */
                GetRoleUsersRequest.prototype.page = 0;
    
                /**
                 * GetRoleUsersRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @instance
                 */
                GetRoleUsersRequest.prototype.limit = 0;
    
                /**
                 * GetRoleUsersRequest roleUuid.
                 * @member {string} roleUuid
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @instance
                 */
                GetRoleUsersRequest.prototype.roleUuid = "";
    
                /**
                 * GetRoleUsersRequest search.
                 * @member {string} search
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @instance
                 */
                GetRoleUsersRequest.prototype.search = "";
    
                /**
                 * GetRoleUsersRequest types.
                 * @member {Array.<infinitusai.auth.UserType>} types
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @instance
                 */
                GetRoleUsersRequest.prototype.types = $util.emptyArray;
    
                /**
                 * GetRoleUsersRequest orderByCol.
                 * @member {string} orderByCol
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @instance
                 */
                GetRoleUsersRequest.prototype.orderByCol = "";
    
                /**
                 * GetRoleUsersRequest orderByDir.
                 * @member {string} orderByDir
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @instance
                 */
                GetRoleUsersRequest.prototype.orderByDir = "";
    
                /**
                 * Creates a new GetRoleUsersRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleUsersRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRoleUsersRequest} GetRoleUsersRequest instance
                 */
                GetRoleUsersRequest.create = function create(properties) {
                    return new GetRoleUsersRequest(properties);
                };
    
                /**
                 * Encodes the specified GetRoleUsersRequest message. Does not implicitly {@link infinitusai.auth.GetRoleUsersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleUsersRequest} message GetRoleUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleUsersRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
                    if (message.roleUuid != null && Object.hasOwnProperty.call(message, "roleUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.roleUuid);
                    if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.search);
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 5, wireType 2 =*/42).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    if (message.orderByCol != null && Object.hasOwnProperty.call(message, "orderByCol"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.orderByCol);
                    if (message.orderByDir != null && Object.hasOwnProperty.call(message, "orderByDir"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.orderByDir);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRoleUsersRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetRoleUsersRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetRoleUsersRequest} message GetRoleUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleUsersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRoleUsersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRoleUsersRequest} GetRoleUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleUsersRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRoleUsersRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.page = reader.int32();
                                break;
                            }
                        case 2: {
                                message.limit = reader.int32();
                                break;
                            }
                        case 3: {
                                message.roleUuid = reader.string();
                                break;
                            }
                        case 4: {
                                message.search = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        case 6: {
                                message.orderByCol = reader.string();
                                break;
                            }
                        case 7: {
                                message.orderByDir = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRoleUsersRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRoleUsersRequest} GetRoleUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleUsersRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRoleUsersRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRoleUsersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        if (!$util.isString(message.roleUuid))
                            return "roleUuid: string expected";
                    if (message.search != null && message.hasOwnProperty("search"))
                        if (!$util.isString(message.search))
                            return "search: string expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        if (!$util.isString(message.orderByCol))
                            return "orderByCol: string expected";
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        if (!$util.isString(message.orderByDir))
                            return "orderByDir: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetRoleUsersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRoleUsersRequest} GetRoleUsersRequest
                 */
                GetRoleUsersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRoleUsersRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetRoleUsersRequest();
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    if (object.roleUuid != null)
                        message.roleUuid = String(object.roleUuid);
                    if (object.search != null)
                        message.search = String(object.search);
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetRoleUsersRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "USER_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "USER_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "USER_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "USER_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    if (object.orderByCol != null)
                        message.orderByCol = String(object.orderByCol);
                    if (object.orderByDir != null)
                        message.orderByDir = String(object.orderByDir);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRoleUsersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {infinitusai.auth.GetRoleUsersRequest} message GetRoleUsersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRoleUsersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (options.defaults) {
                        object.page = 0;
                        object.limit = 0;
                        object.roleUuid = "";
                        object.search = "";
                        object.orderByCol = "";
                        object.orderByDir = "";
                    }
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.roleUuid != null && message.hasOwnProperty("roleUuid"))
                        object.roleUuid = message.roleUuid;
                    if (message.search != null && message.hasOwnProperty("search"))
                        object.search = message.search;
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.UserType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.UserType[message.types[j]] : message.types[j];
                    }
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        object.orderByCol = message.orderByCol;
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        object.orderByDir = message.orderByDir;
                    return object;
                };
    
                /**
                 * Converts this GetRoleUsersRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRoleUsersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRoleUsersRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRoleUsersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRoleUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRoleUsersRequest";
                };
    
                return GetRoleUsersRequest;
            })();
    
            auth.GetRoleUsersResponse = (function() {
    
                /**
                 * Properties of a GetRoleUsersResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetRoleUsersResponse
                 * @property {number|Long|null} [total] GetRoleUsersResponse total
                 * @property {Array.<infinitusai.auth.IUser>|null} [users] GetRoleUsersResponse users
                 */
    
                /**
                 * Constructs a new GetRoleUsersResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetRoleUsersResponse.
                 * @implements IGetRoleUsersResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetRoleUsersResponse=} [properties] Properties to set
                 */
                function GetRoleUsersResponse(properties) {
                    this.users = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetRoleUsersResponse total.
                 * @member {number|Long} total
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @instance
                 */
                GetRoleUsersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GetRoleUsersResponse users.
                 * @member {Array.<infinitusai.auth.IUser>} users
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @instance
                 */
                GetRoleUsersResponse.prototype.users = $util.emptyArray;
    
                /**
                 * Creates a new GetRoleUsersResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleUsersResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetRoleUsersResponse} GetRoleUsersResponse instance
                 */
                GetRoleUsersResponse.create = function create(properties) {
                    return new GetRoleUsersResponse(properties);
                };
    
                /**
                 * Encodes the specified GetRoleUsersResponse message. Does not implicitly {@link infinitusai.auth.GetRoleUsersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleUsersResponse} message GetRoleUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleUsersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.total);
                    if (message.users != null && message.users.length)
                        for (var i = 0; i < message.users.length; ++i)
                            $root.infinitusai.auth.User.encode(message.users[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetRoleUsersResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetRoleUsersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetRoleUsersResponse} message GetRoleUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetRoleUsersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetRoleUsersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetRoleUsersResponse} GetRoleUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleUsersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetRoleUsersResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.total = reader.int64();
                                break;
                            }
                        case 2: {
                                if (!(message.users && message.users.length))
                                    message.users = [];
                                message.users.push($root.infinitusai.auth.User.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetRoleUsersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetRoleUsersResponse} GetRoleUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetRoleUsersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetRoleUsersResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRoleUsersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.users != null && message.hasOwnProperty("users")) {
                        if (!Array.isArray(message.users))
                            return "users: array expected";
                        for (var i = 0; i < message.users.length; ++i) {
                            var error = $root.infinitusai.auth.User.verify(message.users[i]);
                            if (error)
                                return "users." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetRoleUsersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetRoleUsersResponse} GetRoleUsersResponse
                 */
                GetRoleUsersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetRoleUsersResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetRoleUsersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.users) {
                        if (!Array.isArray(object.users))
                            throw TypeError(".infinitusai.auth.GetRoleUsersResponse.users: array expected");
                        message.users = [];
                        for (var i = 0; i < object.users.length; ++i) {
                            if (typeof object.users[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetRoleUsersResponse.users: object expected");
                            message.users[i] = $root.infinitusai.auth.User.fromObject(object.users[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetRoleUsersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {infinitusai.auth.GetRoleUsersResponse} message GetRoleUsersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRoleUsersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.users = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.users && message.users.length) {
                        object.users = [];
                        for (var j = 0; j < message.users.length; ++j)
                            object.users[j] = $root.infinitusai.auth.User.toObject(message.users[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetRoleUsersResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRoleUsersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetRoleUsersResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetRoleUsersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetRoleUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetRoleUsersResponse";
                };
    
                return GetRoleUsersResponse;
            })();
    
            auth.GetUsersRequest = (function() {
    
                /**
                 * Properties of a GetUsersRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetUsersRequest
                 * @property {number|null} [page] GetUsersRequest page
                 * @property {number|null} [limit] GetUsersRequest limit
                 * @property {Array.<infinitusai.auth.UserType>|null} [types] GetUsersRequest types
                 * @property {string|null} [search] GetUsersRequest search
                 * @property {google.protobuf.IBoolValue|null} [disabled] GetUsersRequest disabled
                 * @property {google.protobuf.IBoolValue|null} [superAdmin] GetUsersRequest superAdmin
                 * @property {string|null} [orderByCol] GetUsersRequest orderByCol
                 * @property {string|null} [orderByDir] GetUsersRequest orderByDir
                 * @property {string|null} [lastActiveMillisGte] GetUsersRequest lastActiveMillisGte
                 * @property {string|null} [lastActiveMillisLte] GetUsersRequest lastActiveMillisLte
                 * @property {string|null} [createdAtMillisGte] GetUsersRequest createdAtMillisGte
                 * @property {string|null} [createdAtMillisLte] GetUsersRequest createdAtMillisLte
                 */
    
                /**
                 * Constructs a new GetUsersRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUsersRequest.
                 * @implements IGetUsersRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetUsersRequest=} [properties] Properties to set
                 */
                function GetUsersRequest(properties) {
                    this.types = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUsersRequest page.
                 * @member {number} page
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.page = 0;
    
                /**
                 * GetUsersRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.limit = 0;
    
                /**
                 * GetUsersRequest types.
                 * @member {Array.<infinitusai.auth.UserType>} types
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.types = $util.emptyArray;
    
                /**
                 * GetUsersRequest search.
                 * @member {string} search
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.search = "";
    
                /**
                 * GetUsersRequest disabled.
                 * @member {google.protobuf.IBoolValue|null|undefined} disabled
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.disabled = null;
    
                /**
                 * GetUsersRequest superAdmin.
                 * @member {google.protobuf.IBoolValue|null|undefined} superAdmin
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.superAdmin = null;
    
                /**
                 * GetUsersRequest orderByCol.
                 * @member {string} orderByCol
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.orderByCol = "";
    
                /**
                 * GetUsersRequest orderByDir.
                 * @member {string} orderByDir
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.orderByDir = "";
    
                /**
                 * GetUsersRequest lastActiveMillisGte.
                 * @member {string} lastActiveMillisGte
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.lastActiveMillisGte = "";
    
                /**
                 * GetUsersRequest lastActiveMillisLte.
                 * @member {string} lastActiveMillisLte
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.lastActiveMillisLte = "";
    
                /**
                 * GetUsersRequest createdAtMillisGte.
                 * @member {string} createdAtMillisGte
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.createdAtMillisGte = "";
    
                /**
                 * GetUsersRequest createdAtMillisLte.
                 * @member {string} createdAtMillisLte
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 */
                GetUsersRequest.prototype.createdAtMillisLte = "";
    
                /**
                 * Creates a new GetUsersRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetUsersRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUsersRequest} GetUsersRequest instance
                 */
                GetUsersRequest.create = function create(properties) {
                    return new GetUsersRequest(properties);
                };
    
                /**
                 * Encodes the specified GetUsersRequest message. Does not implicitly {@link infinitusai.auth.GetUsersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetUsersRequest} message GetUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUsersRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
                    if (message.types != null && message.types.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.types.length; ++i)
                            writer.int32(message.types[i]);
                        writer.ldelim();
                    }
                    if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.search);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        $root.google.protobuf.BoolValue.encode(message.disabled, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.superAdmin != null && Object.hasOwnProperty.call(message, "superAdmin"))
                        $root.google.protobuf.BoolValue.encode(message.superAdmin, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.orderByCol != null && Object.hasOwnProperty.call(message, "orderByCol"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.orderByCol);
                    if (message.orderByDir != null && Object.hasOwnProperty.call(message, "orderByDir"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.orderByDir);
                    if (message.lastActiveMillisGte != null && Object.hasOwnProperty.call(message, "lastActiveMillisGte"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.lastActiveMillisGte);
                    if (message.lastActiveMillisLte != null && Object.hasOwnProperty.call(message, "lastActiveMillisLte"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.lastActiveMillisLte);
                    if (message.createdAtMillisGte != null && Object.hasOwnProperty.call(message, "createdAtMillisGte"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.createdAtMillisGte);
                    if (message.createdAtMillisLte != null && Object.hasOwnProperty.call(message, "createdAtMillisLte"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.createdAtMillisLte);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUsersRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetUsersRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetUsersRequest} message GetUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUsersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUsersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUsersRequest} GetUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUsersRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUsersRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.page = reader.int32();
                                break;
                            }
                        case 2: {
                                message.limit = reader.int32();
                                break;
                            }
                        case 3: {
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            }
                        case 4: {
                                message.search = reader.string();
                                break;
                            }
                        case 5: {
                                message.disabled = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.superAdmin = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.orderByCol = reader.string();
                                break;
                            }
                        case 8: {
                                message.orderByDir = reader.string();
                                break;
                            }
                        case 9: {
                                message.lastActiveMillisGte = reader.string();
                                break;
                            }
                        case 10: {
                                message.lastActiveMillisLte = reader.string();
                                break;
                            }
                        case 11: {
                                message.createdAtMillisGte = reader.string();
                                break;
                            }
                        case 12: {
                                message.createdAtMillisLte = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUsersRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUsersRequest} GetUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUsersRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUsersRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUsersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.types != null && message.hasOwnProperty("types")) {
                        if (!Array.isArray(message.types))
                            return "types: array expected";
                        for (var i = 0; i < message.types.length; ++i)
                            switch (message.types[i]) {
                            default:
                                return "types: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.search != null && message.hasOwnProperty("search"))
                        if (!$util.isString(message.search))
                            return "search: string expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled")) {
                        var error = $root.google.protobuf.BoolValue.verify(message.disabled);
                        if (error)
                            return "disabled." + error;
                    }
                    if (message.superAdmin != null && message.hasOwnProperty("superAdmin")) {
                        var error = $root.google.protobuf.BoolValue.verify(message.superAdmin);
                        if (error)
                            return "superAdmin." + error;
                    }
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        if (!$util.isString(message.orderByCol))
                            return "orderByCol: string expected";
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        if (!$util.isString(message.orderByDir))
                            return "orderByDir: string expected";
                    if (message.lastActiveMillisGte != null && message.hasOwnProperty("lastActiveMillisGte"))
                        if (!$util.isString(message.lastActiveMillisGte))
                            return "lastActiveMillisGte: string expected";
                    if (message.lastActiveMillisLte != null && message.hasOwnProperty("lastActiveMillisLte"))
                        if (!$util.isString(message.lastActiveMillisLte))
                            return "lastActiveMillisLte: string expected";
                    if (message.createdAtMillisGte != null && message.hasOwnProperty("createdAtMillisGte"))
                        if (!$util.isString(message.createdAtMillisGte))
                            return "createdAtMillisGte: string expected";
                    if (message.createdAtMillisLte != null && message.hasOwnProperty("createdAtMillisLte"))
                        if (!$util.isString(message.createdAtMillisLte))
                            return "createdAtMillisLte: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetUsersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUsersRequest} GetUsersRequest
                 */
                GetUsersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUsersRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetUsersRequest();
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    if (object.types) {
                        if (!Array.isArray(object.types))
                            throw TypeError(".infinitusai.auth.GetUsersRequest.types: array expected");
                        message.types = [];
                        for (var i = 0; i < object.types.length; ++i)
                            switch (object.types[i]) {
                            default:
                                if (typeof object.types[i] === "number") {
                                    message.types[i] = object.types[i];
                                    break;
                                }
                            case "USER_TYPE_UNKNOWN":
                            case 0:
                                message.types[i] = 0;
                                break;
                            case "USER_TYPE_ADMIN":
                            case 1:
                                message.types[i] = 1;
                                break;
                            case "USER_TYPE_OPERATOR":
                            case 2:
                                message.types[i] = 2;
                                break;
                            case "USER_TYPE_CUSTOMER":
                            case 3:
                                message.types[i] = 3;
                                break;
                            }
                    }
                    if (object.search != null)
                        message.search = String(object.search);
                    if (object.disabled != null) {
                        if (typeof object.disabled !== "object")
                            throw TypeError(".infinitusai.auth.GetUsersRequest.disabled: object expected");
                        message.disabled = $root.google.protobuf.BoolValue.fromObject(object.disabled);
                    }
                    if (object.superAdmin != null) {
                        if (typeof object.superAdmin !== "object")
                            throw TypeError(".infinitusai.auth.GetUsersRequest.superAdmin: object expected");
                        message.superAdmin = $root.google.protobuf.BoolValue.fromObject(object.superAdmin);
                    }
                    if (object.orderByCol != null)
                        message.orderByCol = String(object.orderByCol);
                    if (object.orderByDir != null)
                        message.orderByDir = String(object.orderByDir);
                    if (object.lastActiveMillisGte != null)
                        message.lastActiveMillisGte = String(object.lastActiveMillisGte);
                    if (object.lastActiveMillisLte != null)
                        message.lastActiveMillisLte = String(object.lastActiveMillisLte);
                    if (object.createdAtMillisGte != null)
                        message.createdAtMillisGte = String(object.createdAtMillisGte);
                    if (object.createdAtMillisLte != null)
                        message.createdAtMillisLte = String(object.createdAtMillisLte);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUsersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {infinitusai.auth.GetUsersRequest} message GetUsersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUsersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.types = [];
                    if (options.defaults) {
                        object.page = 0;
                        object.limit = 0;
                        object.search = "";
                        object.disabled = null;
                        object.superAdmin = null;
                        object.orderByCol = "";
                        object.orderByDir = "";
                        object.lastActiveMillisGte = "";
                        object.lastActiveMillisLte = "";
                        object.createdAtMillisGte = "";
                        object.createdAtMillisLte = "";
                    }
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.types && message.types.length) {
                        object.types = [];
                        for (var j = 0; j < message.types.length; ++j)
                            object.types[j] = options.enums === String ? $root.infinitusai.auth.UserType[message.types[j]] === undefined ? message.types[j] : $root.infinitusai.auth.UserType[message.types[j]] : message.types[j];
                    }
                    if (message.search != null && message.hasOwnProperty("search"))
                        object.search = message.search;
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = $root.google.protobuf.BoolValue.toObject(message.disabled, options);
                    if (message.superAdmin != null && message.hasOwnProperty("superAdmin"))
                        object.superAdmin = $root.google.protobuf.BoolValue.toObject(message.superAdmin, options);
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        object.orderByCol = message.orderByCol;
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        object.orderByDir = message.orderByDir;
                    if (message.lastActiveMillisGte != null && message.hasOwnProperty("lastActiveMillisGte"))
                        object.lastActiveMillisGte = message.lastActiveMillisGte;
                    if (message.lastActiveMillisLte != null && message.hasOwnProperty("lastActiveMillisLte"))
                        object.lastActiveMillisLte = message.lastActiveMillisLte;
                    if (message.createdAtMillisGte != null && message.hasOwnProperty("createdAtMillisGte"))
                        object.createdAtMillisGte = message.createdAtMillisGte;
                    if (message.createdAtMillisLte != null && message.hasOwnProperty("createdAtMillisLte"))
                        object.createdAtMillisLte = message.createdAtMillisLte;
                    return object;
                };
    
                /**
                 * Converts this GetUsersRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUsersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUsersRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUsersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUsersRequest";
                };
    
                return GetUsersRequest;
            })();
    
            auth.GetUsersResponse = (function() {
    
                /**
                 * Properties of a GetUsersResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetUsersResponse
                 * @property {number|Long|null} [total] GetUsersResponse total
                 * @property {Array.<infinitusai.auth.IUser>|null} [users] GetUsersResponse users
                 */
    
                /**
                 * Constructs a new GetUsersResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUsersResponse.
                 * @implements IGetUsersResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetUsersResponse=} [properties] Properties to set
                 */
                function GetUsersResponse(properties) {
                    this.users = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUsersResponse total.
                 * @member {number|Long} total
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @instance
                 */
                GetUsersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GetUsersResponse users.
                 * @member {Array.<infinitusai.auth.IUser>} users
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @instance
                 */
                GetUsersResponse.prototype.users = $util.emptyArray;
    
                /**
                 * Creates a new GetUsersResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetUsersResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUsersResponse} GetUsersResponse instance
                 */
                GetUsersResponse.create = function create(properties) {
                    return new GetUsersResponse(properties);
                };
    
                /**
                 * Encodes the specified GetUsersResponse message. Does not implicitly {@link infinitusai.auth.GetUsersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetUsersResponse} message GetUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUsersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.total);
                    if (message.users != null && message.users.length)
                        for (var i = 0; i < message.users.length; ++i)
                            $root.infinitusai.auth.User.encode(message.users[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUsersResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetUsersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetUsersResponse} message GetUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUsersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUsersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUsersResponse} GetUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUsersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUsersResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.total = reader.int64();
                                break;
                            }
                        case 2: {
                                if (!(message.users && message.users.length))
                                    message.users = [];
                                message.users.push($root.infinitusai.auth.User.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUsersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUsersResponse} GetUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUsersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUsersResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUsersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.users != null && message.hasOwnProperty("users")) {
                        if (!Array.isArray(message.users))
                            return "users: array expected";
                        for (var i = 0; i < message.users.length; ++i) {
                            var error = $root.infinitusai.auth.User.verify(message.users[i]);
                            if (error)
                                return "users." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetUsersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUsersResponse} GetUsersResponse
                 */
                GetUsersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUsersResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetUsersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.users) {
                        if (!Array.isArray(object.users))
                            throw TypeError(".infinitusai.auth.GetUsersResponse.users: array expected");
                        message.users = [];
                        for (var i = 0; i < object.users.length; ++i) {
                            if (typeof object.users[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetUsersResponse.users: object expected");
                            message.users[i] = $root.infinitusai.auth.User.fromObject(object.users[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUsersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {infinitusai.auth.GetUsersResponse} message GetUsersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUsersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.users = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.users && message.users.length) {
                        object.users = [];
                        for (var j = 0; j < message.users.length; ++j)
                            object.users[j] = $root.infinitusai.auth.User.toObject(message.users[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetUsersResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUsersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUsersResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUsersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUsersResponse";
                };
    
                return GetUsersResponse;
            })();
    
            auth.GetUserRequest = (function() {
    
                /**
                 * Properties of a GetUserRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetUserRequest
                 * @property {string|null} [uid] GetUserRequest uid
                 */
    
                /**
                 * Constructs a new GetUserRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUserRequest.
                 * @implements IGetUserRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetUserRequest=} [properties] Properties to set
                 */
                function GetUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserRequest uid.
                 * @member {string} uid
                 * @memberof infinitusai.auth.GetUserRequest
                 * @instance
                 */
                GetUserRequest.prototype.uid = "";
    
                /**
                 * Creates a new GetUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUserRequest} GetUserRequest instance
                 */
                GetUserRequest.create = function create(properties) {
                    return new GetUserRequest(properties);
                };
    
                /**
                 * Encodes the specified GetUserRequest message. Does not implicitly {@link infinitusai.auth.GetUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserRequest} message GetUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserRequest} message GetUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUserRequest} GetUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUserRequest} GetUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uid != null && message.hasOwnProperty("uid"))
                        if (!$util.isString(message.uid))
                            return "uid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUserRequest} GetUserRequest
                 */
                GetUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUserRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetUserRequest();
                    if (object.uid != null)
                        message.uid = String(object.uid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {infinitusai.auth.GetUserRequest} message GetUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uid = "";
                    if (message.uid != null && message.hasOwnProperty("uid"))
                        object.uid = message.uid;
                    return object;
                };
    
                /**
                 * Converts this GetUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUserRequest";
                };
    
                return GetUserRequest;
            })();
    
            auth.GetUserResponse = (function() {
    
                /**
                 * Properties of a GetUserResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetUserResponse
                 * @property {infinitusai.auth.IUser|null} [user] GetUserResponse user
                 */
    
                /**
                 * Constructs a new GetUserResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUserResponse.
                 * @implements IGetUserResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetUserResponse=} [properties] Properties to set
                 */
                function GetUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserResponse user.
                 * @member {infinitusai.auth.IUser|null|undefined} user
                 * @memberof infinitusai.auth.GetUserResponse
                 * @instance
                 */
                GetUserResponse.prototype.user = null;
    
                /**
                 * Creates a new GetUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUserResponse} GetUserResponse instance
                 */
                GetUserResponse.create = function create(properties) {
                    return new GetUserResponse(properties);
                };
    
                /**
                 * Encodes the specified GetUserResponse message. Does not implicitly {@link infinitusai.auth.GetUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserResponse} message GetUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        $root.infinitusai.auth.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserResponse} message GetUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUserResponse} GetUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.user = $root.infinitusai.auth.User.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUserResponse} GetUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        var error = $root.infinitusai.auth.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUserResponse} GetUserResponse
                 */
                GetUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUserResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetUserResponse();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".infinitusai.auth.GetUserResponse.user: object expected");
                        message.user = $root.infinitusai.auth.User.fromObject(object.user);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {infinitusai.auth.GetUserResponse} message GetUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.infinitusai.auth.User.toObject(message.user, options);
                    return object;
                };
    
                /**
                 * Converts this GetUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUserResponse";
                };
    
                return GetUserResponse;
            })();
    
            auth.GetUserOrgsRequest = (function() {
    
                /**
                 * Properties of a GetUserOrgsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetUserOrgsRequest
                 * @property {string|null} [userUid] GetUserOrgsRequest userUid
                 */
    
                /**
                 * Constructs a new GetUserOrgsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUserOrgsRequest.
                 * @implements IGetUserOrgsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetUserOrgsRequest=} [properties] Properties to set
                 */
                function GetUserOrgsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserOrgsRequest userUid.
                 * @member {string} userUid
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @instance
                 */
                GetUserOrgsRequest.prototype.userUid = "";
    
                /**
                 * Creates a new GetUserOrgsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserOrgsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUserOrgsRequest} GetUserOrgsRequest instance
                 */
                GetUserOrgsRequest.create = function create(properties) {
                    return new GetUserOrgsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetUserOrgsRequest message. Does not implicitly {@link infinitusai.auth.GetUserOrgsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserOrgsRequest} message GetUserOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserOrgsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserOrgsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetUserOrgsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {infinitusai.auth.IGetUserOrgsRequest} message GetUserOrgsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserOrgsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserOrgsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUserOrgsRequest} GetUserOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserOrgsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUserOrgsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userUid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserOrgsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUserOrgsRequest} GetUserOrgsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserOrgsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserOrgsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserOrgsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        if (!$util.isString(message.userUid))
                            return "userUid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetUserOrgsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUserOrgsRequest} GetUserOrgsRequest
                 */
                GetUserOrgsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUserOrgsRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetUserOrgsRequest();
                    if (object.userUid != null)
                        message.userUid = String(object.userUid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserOrgsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {infinitusai.auth.GetUserOrgsRequest} message GetUserOrgsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserOrgsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userUid = "";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        object.userUid = message.userUid;
                    return object;
                };
    
                /**
                 * Converts this GetUserOrgsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserOrgsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserOrgsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUserOrgsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserOrgsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUserOrgsRequest";
                };
    
                return GetUserOrgsRequest;
            })();
    
            auth.GetUserOrgsResponse = (function() {
    
                /**
                 * Properties of a GetUserOrgsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetUserOrgsResponse
                 * @property {Array.<infinitusai.orgs.IOrg>|null} [orgs] GetUserOrgsResponse orgs
                 */
    
                /**
                 * Constructs a new GetUserOrgsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetUserOrgsResponse.
                 * @implements IGetUserOrgsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetUserOrgsResponse=} [properties] Properties to set
                 */
                function GetUserOrgsResponse(properties) {
                    this.orgs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetUserOrgsResponse orgs.
                 * @member {Array.<infinitusai.orgs.IOrg>} orgs
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @instance
                 */
                GetUserOrgsResponse.prototype.orgs = $util.emptyArray;
    
                /**
                 * Creates a new GetUserOrgsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserOrgsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetUserOrgsResponse} GetUserOrgsResponse instance
                 */
                GetUserOrgsResponse.create = function create(properties) {
                    return new GetUserOrgsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetUserOrgsResponse message. Does not implicitly {@link infinitusai.auth.GetUserOrgsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserOrgsResponse} message GetUserOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserOrgsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgs != null && message.orgs.length)
                        for (var i = 0; i < message.orgs.length; ++i)
                            $root.infinitusai.orgs.Org.encode(message.orgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetUserOrgsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetUserOrgsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {infinitusai.auth.IGetUserOrgsResponse} message GetUserOrgsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetUserOrgsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetUserOrgsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetUserOrgsResponse} GetUserOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserOrgsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetUserOrgsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.orgs && message.orgs.length))
                                    message.orgs = [];
                                message.orgs.push($root.infinitusai.orgs.Org.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetUserOrgsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetUserOrgsResponse} GetUserOrgsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetUserOrgsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetUserOrgsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserOrgsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgs != null && message.hasOwnProperty("orgs")) {
                        if (!Array.isArray(message.orgs))
                            return "orgs: array expected";
                        for (var i = 0; i < message.orgs.length; ++i) {
                            var error = $root.infinitusai.orgs.Org.verify(message.orgs[i]);
                            if (error)
                                return "orgs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetUserOrgsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetUserOrgsResponse} GetUserOrgsResponse
                 */
                GetUserOrgsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetUserOrgsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetUserOrgsResponse();
                    if (object.orgs) {
                        if (!Array.isArray(object.orgs))
                            throw TypeError(".infinitusai.auth.GetUserOrgsResponse.orgs: array expected");
                        message.orgs = [];
                        for (var i = 0; i < object.orgs.length; ++i) {
                            if (typeof object.orgs[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetUserOrgsResponse.orgs: object expected");
                            message.orgs[i] = $root.infinitusai.orgs.Org.fromObject(object.orgs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetUserOrgsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {infinitusai.auth.GetUserOrgsResponse} message GetUserOrgsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserOrgsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgs = [];
                    if (message.orgs && message.orgs.length) {
                        object.orgs = [];
                        for (var j = 0; j < message.orgs.length; ++j)
                            object.orgs[j] = $root.infinitusai.orgs.Org.toObject(message.orgs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetUserOrgsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserOrgsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetUserOrgsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetUserOrgsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetUserOrgsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetUserOrgsResponse";
                };
    
                return GetUserOrgsResponse;
            })();
    
            auth.UpdateUserRequest = (function() {
    
                /**
                 * Properties of an UpdateUserRequest.
                 * @memberof infinitusai.auth
                 * @interface IUpdateUserRequest
                 * @property {string|null} [uid] UpdateUserRequest uid
                 * @property {string|null} [displayName] UpdateUserRequest displayName
                 * @property {string|null} [photoUrl] UpdateUserRequest photoUrl
                 * @property {boolean|null} [superAdmin] UpdateUserRequest superAdmin
                 * @property {infinitusai.auth.UserType|null} [type] UpdateUserRequest type
                 */
    
                /**
                 * Constructs a new UpdateUserRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UpdateUserRequest.
                 * @implements IUpdateUserRequest
                 * @constructor
                 * @param {infinitusai.auth.IUpdateUserRequest=} [properties] Properties to set
                 */
                function UpdateUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateUserRequest uid.
                 * @member {string} uid
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @instance
                 */
                UpdateUserRequest.prototype.uid = "";
    
                /**
                 * UpdateUserRequest displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @instance
                 */
                UpdateUserRequest.prototype.displayName = "";
    
                /**
                 * UpdateUserRequest photoUrl.
                 * @member {string} photoUrl
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @instance
                 */
                UpdateUserRequest.prototype.photoUrl = "";
    
                /**
                 * UpdateUserRequest superAdmin.
                 * @member {boolean} superAdmin
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @instance
                 */
                UpdateUserRequest.prototype.superAdmin = false;
    
                /**
                 * UpdateUserRequest type.
                 * @member {infinitusai.auth.UserType} type
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @instance
                 */
                UpdateUserRequest.prototype.type = 0;
    
                /**
                 * Creates a new UpdateUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.UpdateUserRequest} UpdateUserRequest instance
                 */
                UpdateUserRequest.create = function create(properties) {
                    return new UpdateUserRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateUserRequest message. Does not implicitly {@link infinitusai.auth.UpdateUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateUserRequest} message UpdateUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayName);
                    if (message.photoUrl != null && Object.hasOwnProperty.call(message, "photoUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.photoUrl);
                    if (message.superAdmin != null && Object.hasOwnProperty.call(message, "superAdmin"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.superAdmin);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateUserRequest message, length delimited. Does not implicitly {@link infinitusai.auth.UpdateUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateUserRequest} message UpdateUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UpdateUserRequest} UpdateUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UpdateUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uid = reader.string();
                                break;
                            }
                        case 2: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 3: {
                                message.photoUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.superAdmin = reader.bool();
                                break;
                            }
                        case 5: {
                                message.type = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UpdateUserRequest} UpdateUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateUserRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uid != null && message.hasOwnProperty("uid"))
                        if (!$util.isString(message.uid))
                            return "uid: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.photoUrl != null && message.hasOwnProperty("photoUrl"))
                        if (!$util.isString(message.photoUrl))
                            return "photoUrl: string expected";
                    if (message.superAdmin != null && message.hasOwnProperty("superAdmin"))
                        if (typeof message.superAdmin !== "boolean")
                            return "superAdmin: boolean expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an UpdateUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UpdateUserRequest} UpdateUserRequest
                 */
                UpdateUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UpdateUserRequest)
                        return object;
                    var message = new $root.infinitusai.auth.UpdateUserRequest();
                    if (object.uid != null)
                        message.uid = String(object.uid);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.photoUrl != null)
                        message.photoUrl = String(object.photoUrl);
                    if (object.superAdmin != null)
                        message.superAdmin = Boolean(object.superAdmin);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "USER_TYPE_UNKNOWN":
                    case 0:
                        message.type = 0;
                        break;
                    case "USER_TYPE_ADMIN":
                    case 1:
                        message.type = 1;
                        break;
                    case "USER_TYPE_OPERATOR":
                    case 2:
                        message.type = 2;
                        break;
                    case "USER_TYPE_CUSTOMER":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {infinitusai.auth.UpdateUserRequest} message UpdateUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uid = "";
                        object.displayName = "";
                        object.photoUrl = "";
                        object.superAdmin = false;
                        object.type = options.enums === String ? "USER_TYPE_UNKNOWN" : 0;
                    }
                    if (message.uid != null && message.hasOwnProperty("uid"))
                        object.uid = message.uid;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.photoUrl != null && message.hasOwnProperty("photoUrl"))
                        object.photoUrl = message.photoUrl;
                    if (message.superAdmin != null && message.hasOwnProperty("superAdmin"))
                        object.superAdmin = message.superAdmin;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.infinitusai.auth.UserType[message.type] === undefined ? message.type : $root.infinitusai.auth.UserType[message.type] : message.type;
                    return object;
                };
    
                /**
                 * Converts this UpdateUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UpdateUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UpdateUserRequest";
                };
    
                return UpdateUserRequest;
            })();
    
            auth.UpdateUserResponse = (function() {
    
                /**
                 * Properties of an UpdateUserResponse.
                 * @memberof infinitusai.auth
                 * @interface IUpdateUserResponse
                 * @property {infinitusai.auth.IUser|null} [user] UpdateUserResponse user
                 */
    
                /**
                 * Constructs a new UpdateUserResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UpdateUserResponse.
                 * @implements IUpdateUserResponse
                 * @constructor
                 * @param {infinitusai.auth.IUpdateUserResponse=} [properties] Properties to set
                 */
                function UpdateUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateUserResponse user.
                 * @member {infinitusai.auth.IUser|null|undefined} user
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @instance
                 */
                UpdateUserResponse.prototype.user = null;
    
                /**
                 * Creates a new UpdateUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.UpdateUserResponse} UpdateUserResponse instance
                 */
                UpdateUserResponse.create = function create(properties) {
                    return new UpdateUserResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateUserResponse message. Does not implicitly {@link infinitusai.auth.UpdateUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateUserResponse} message UpdateUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        $root.infinitusai.auth.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateUserResponse message, length delimited. Does not implicitly {@link infinitusai.auth.UpdateUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateUserResponse} message UpdateUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UpdateUserResponse} UpdateUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UpdateUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.user = $root.infinitusai.auth.User.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UpdateUserResponse} UpdateUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateUserResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        var error = $root.infinitusai.auth.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UpdateUserResponse} UpdateUserResponse
                 */
                UpdateUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UpdateUserResponse)
                        return object;
                    var message = new $root.infinitusai.auth.UpdateUserResponse();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".infinitusai.auth.UpdateUserResponse.user: object expected");
                        message.user = $root.infinitusai.auth.User.fromObject(object.user);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {infinitusai.auth.UpdateUserResponse} message UpdateUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.infinitusai.auth.User.toObject(message.user, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UpdateUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UpdateUserResponse";
                };
    
                return UpdateUserResponse;
            })();
    
            auth.DisableUserRequest = (function() {
    
                /**
                 * Properties of a DisableUserRequest.
                 * @memberof infinitusai.auth
                 * @interface IDisableUserRequest
                 * @property {string|null} [userUid] DisableUserRequest userUid
                 */
    
                /**
                 * Constructs a new DisableUserRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DisableUserRequest.
                 * @implements IDisableUserRequest
                 * @constructor
                 * @param {infinitusai.auth.IDisableUserRequest=} [properties] Properties to set
                 */
                function DisableUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DisableUserRequest userUid.
                 * @member {string} userUid
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @instance
                 */
                DisableUserRequest.prototype.userUid = "";
    
                /**
                 * Creates a new DisableUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {infinitusai.auth.IDisableUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.DisableUserRequest} DisableUserRequest instance
                 */
                DisableUserRequest.create = function create(properties) {
                    return new DisableUserRequest(properties);
                };
    
                /**
                 * Encodes the specified DisableUserRequest message. Does not implicitly {@link infinitusai.auth.DisableUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {infinitusai.auth.IDisableUserRequest} message DisableUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisableUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DisableUserRequest message, length delimited. Does not implicitly {@link infinitusai.auth.DisableUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {infinitusai.auth.IDisableUserRequest} message DisableUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisableUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DisableUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DisableUserRequest} DisableUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisableUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DisableUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userUid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DisableUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DisableUserRequest} DisableUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisableUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DisableUserRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        if (!$util.isString(message.userUid))
                            return "userUid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DisableUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DisableUserRequest} DisableUserRequest
                 */
                DisableUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DisableUserRequest)
                        return object;
                    var message = new $root.infinitusai.auth.DisableUserRequest();
                    if (object.userUid != null)
                        message.userUid = String(object.userUid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DisableUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {infinitusai.auth.DisableUserRequest} message DisableUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userUid = "";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        object.userUid = message.userUid;
                    return object;
                };
    
                /**
                 * Converts this DisableUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DisableUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DisableUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DisableUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DisableUserRequest";
                };
    
                return DisableUserRequest;
            })();
    
            auth.DisableUserResponse = (function() {
    
                /**
                 * Properties of a DisableUserResponse.
                 * @memberof infinitusai.auth
                 * @interface IDisableUserResponse
                 */
    
                /**
                 * Constructs a new DisableUserResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DisableUserResponse.
                 * @implements IDisableUserResponse
                 * @constructor
                 * @param {infinitusai.auth.IDisableUserResponse=} [properties] Properties to set
                 */
                function DisableUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DisableUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {infinitusai.auth.IDisableUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.DisableUserResponse} DisableUserResponse instance
                 */
                DisableUserResponse.create = function create(properties) {
                    return new DisableUserResponse(properties);
                };
    
                /**
                 * Encodes the specified DisableUserResponse message. Does not implicitly {@link infinitusai.auth.DisableUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {infinitusai.auth.IDisableUserResponse} message DisableUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisableUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DisableUserResponse message, length delimited. Does not implicitly {@link infinitusai.auth.DisableUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {infinitusai.auth.IDisableUserResponse} message DisableUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisableUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DisableUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DisableUserResponse} DisableUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisableUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DisableUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DisableUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DisableUserResponse} DisableUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisableUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DisableUserResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DisableUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DisableUserResponse} DisableUserResponse
                 */
                DisableUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DisableUserResponse)
                        return object;
                    return new $root.infinitusai.auth.DisableUserResponse();
                };
    
                /**
                 * Creates a plain object from a DisableUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {infinitusai.auth.DisableUserResponse} message DisableUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableUserResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DisableUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DisableUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DisableUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DisableUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DisableUserResponse";
                };
    
                return DisableUserResponse;
            })();
    
            auth.EnableUserRequest = (function() {
    
                /**
                 * Properties of an EnableUserRequest.
                 * @memberof infinitusai.auth
                 * @interface IEnableUserRequest
                 * @property {string|null} [userUid] EnableUserRequest userUid
                 */
    
                /**
                 * Constructs a new EnableUserRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an EnableUserRequest.
                 * @implements IEnableUserRequest
                 * @constructor
                 * @param {infinitusai.auth.IEnableUserRequest=} [properties] Properties to set
                 */
                function EnableUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnableUserRequest userUid.
                 * @member {string} userUid
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @instance
                 */
                EnableUserRequest.prototype.userUid = "";
    
                /**
                 * Creates a new EnableUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {infinitusai.auth.IEnableUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.EnableUserRequest} EnableUserRequest instance
                 */
                EnableUserRequest.create = function create(properties) {
                    return new EnableUserRequest(properties);
                };
    
                /**
                 * Encodes the specified EnableUserRequest message. Does not implicitly {@link infinitusai.auth.EnableUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {infinitusai.auth.IEnableUserRequest} message EnableUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnableUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userUid != null && Object.hasOwnProperty.call(message, "userUid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUid);
                    return writer;
                };
    
                /**
                 * Encodes the specified EnableUserRequest message, length delimited. Does not implicitly {@link infinitusai.auth.EnableUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {infinitusai.auth.IEnableUserRequest} message EnableUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnableUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnableUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.EnableUserRequest} EnableUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnableUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.EnableUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userUid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnableUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.EnableUserRequest} EnableUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnableUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnableUserRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnableUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        if (!$util.isString(message.userUid))
                            return "userUid: string expected";
                    return null;
                };
    
                /**
                 * Creates an EnableUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.EnableUserRequest} EnableUserRequest
                 */
                EnableUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.EnableUserRequest)
                        return object;
                    var message = new $root.infinitusai.auth.EnableUserRequest();
                    if (object.userUid != null)
                        message.userUid = String(object.userUid);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnableUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {infinitusai.auth.EnableUserRequest} message EnableUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnableUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userUid = "";
                    if (message.userUid != null && message.hasOwnProperty("userUid"))
                        object.userUid = message.userUid;
                    return object;
                };
    
                /**
                 * Converts this EnableUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnableUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnableUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.EnableUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnableUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.EnableUserRequest";
                };
    
                return EnableUserRequest;
            })();
    
            auth.EnableUserResponse = (function() {
    
                /**
                 * Properties of an EnableUserResponse.
                 * @memberof infinitusai.auth
                 * @interface IEnableUserResponse
                 */
    
                /**
                 * Constructs a new EnableUserResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an EnableUserResponse.
                 * @implements IEnableUserResponse
                 * @constructor
                 * @param {infinitusai.auth.IEnableUserResponse=} [properties] Properties to set
                 */
                function EnableUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new EnableUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {infinitusai.auth.IEnableUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.EnableUserResponse} EnableUserResponse instance
                 */
                EnableUserResponse.create = function create(properties) {
                    return new EnableUserResponse(properties);
                };
    
                /**
                 * Encodes the specified EnableUserResponse message. Does not implicitly {@link infinitusai.auth.EnableUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {infinitusai.auth.IEnableUserResponse} message EnableUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnableUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnableUserResponse message, length delimited. Does not implicitly {@link infinitusai.auth.EnableUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {infinitusai.auth.IEnableUserResponse} message EnableUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnableUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnableUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.EnableUserResponse} EnableUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnableUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.EnableUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnableUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.EnableUserResponse} EnableUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnableUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnableUserResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnableUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an EnableUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.EnableUserResponse} EnableUserResponse
                 */
                EnableUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.EnableUserResponse)
                        return object;
                    return new $root.infinitusai.auth.EnableUserResponse();
                };
    
                /**
                 * Creates a plain object from an EnableUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {infinitusai.auth.EnableUserResponse} message EnableUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnableUserResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this EnableUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnableUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnableUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.EnableUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnableUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.EnableUserResponse";
                };
    
                return EnableUserResponse;
            })();
    
            auth.CreateDefaultRolesRequest = (function() {
    
                /**
                 * Properties of a CreateDefaultRolesRequest.
                 * @memberof infinitusai.auth
                 * @interface ICreateDefaultRolesRequest
                 */
    
                /**
                 * Constructs a new CreateDefaultRolesRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateDefaultRolesRequest.
                 * @implements ICreateDefaultRolesRequest
                 * @constructor
                 * @param {infinitusai.auth.ICreateDefaultRolesRequest=} [properties] Properties to set
                 */
                function CreateDefaultRolesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CreateDefaultRolesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {infinitusai.auth.ICreateDefaultRolesRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateDefaultRolesRequest} CreateDefaultRolesRequest instance
                 */
                CreateDefaultRolesRequest.create = function create(properties) {
                    return new CreateDefaultRolesRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateDefaultRolesRequest message. Does not implicitly {@link infinitusai.auth.CreateDefaultRolesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {infinitusai.auth.ICreateDefaultRolesRequest} message CreateDefaultRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateDefaultRolesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateDefaultRolesRequest message, length delimited. Does not implicitly {@link infinitusai.auth.CreateDefaultRolesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {infinitusai.auth.ICreateDefaultRolesRequest} message CreateDefaultRolesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateDefaultRolesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateDefaultRolesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateDefaultRolesRequest} CreateDefaultRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateDefaultRolesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateDefaultRolesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateDefaultRolesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateDefaultRolesRequest} CreateDefaultRolesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateDefaultRolesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateDefaultRolesRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateDefaultRolesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CreateDefaultRolesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateDefaultRolesRequest} CreateDefaultRolesRequest
                 */
                CreateDefaultRolesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateDefaultRolesRequest)
                        return object;
                    return new $root.infinitusai.auth.CreateDefaultRolesRequest();
                };
    
                /**
                 * Creates a plain object from a CreateDefaultRolesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {infinitusai.auth.CreateDefaultRolesRequest} message CreateDefaultRolesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateDefaultRolesRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CreateDefaultRolesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateDefaultRolesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateDefaultRolesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateDefaultRolesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateDefaultRolesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateDefaultRolesRequest";
                };
    
                return CreateDefaultRolesRequest;
            })();
    
            auth.CreateDefaultRolesResponse = (function() {
    
                /**
                 * Properties of a CreateDefaultRolesResponse.
                 * @memberof infinitusai.auth
                 * @interface ICreateDefaultRolesResponse
                 */
    
                /**
                 * Constructs a new CreateDefaultRolesResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateDefaultRolesResponse.
                 * @implements ICreateDefaultRolesResponse
                 * @constructor
                 * @param {infinitusai.auth.ICreateDefaultRolesResponse=} [properties] Properties to set
                 */
                function CreateDefaultRolesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CreateDefaultRolesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {infinitusai.auth.ICreateDefaultRolesResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateDefaultRolesResponse} CreateDefaultRolesResponse instance
                 */
                CreateDefaultRolesResponse.create = function create(properties) {
                    return new CreateDefaultRolesResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateDefaultRolesResponse message. Does not implicitly {@link infinitusai.auth.CreateDefaultRolesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {infinitusai.auth.ICreateDefaultRolesResponse} message CreateDefaultRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateDefaultRolesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateDefaultRolesResponse message, length delimited. Does not implicitly {@link infinitusai.auth.CreateDefaultRolesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {infinitusai.auth.ICreateDefaultRolesResponse} message CreateDefaultRolesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateDefaultRolesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateDefaultRolesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateDefaultRolesResponse} CreateDefaultRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateDefaultRolesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateDefaultRolesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateDefaultRolesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateDefaultRolesResponse} CreateDefaultRolesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateDefaultRolesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateDefaultRolesResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateDefaultRolesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CreateDefaultRolesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateDefaultRolesResponse} CreateDefaultRolesResponse
                 */
                CreateDefaultRolesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateDefaultRolesResponse)
                        return object;
                    return new $root.infinitusai.auth.CreateDefaultRolesResponse();
                };
    
                /**
                 * Creates a plain object from a CreateDefaultRolesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {infinitusai.auth.CreateDefaultRolesResponse} message CreateDefaultRolesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateDefaultRolesResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CreateDefaultRolesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateDefaultRolesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateDefaultRolesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateDefaultRolesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateDefaultRolesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateDefaultRolesResponse";
                };
    
                return CreateDefaultRolesResponse;
            })();
    
            /**
             * AuthProvider enum.
             * @name infinitusai.auth.AuthProvider
             * @enum {number}
             * @property {number} AUTH_PROVIDER_UNKNOWN=0 AUTH_PROVIDER_UNKNOWN value
             * @property {number} AUTH_PROVIDER_GOOGLE=1 AUTH_PROVIDER_GOOGLE value
             * @property {number} AUTH_PROVIDER_MICROSOFT=2 AUTH_PROVIDER_MICROSOFT value
             * @property {number} AUTH_PROVIDER_SAML=3 AUTH_PROVIDER_SAML value
             * @property {number} AUTH_PROVIDER_EMAIL=4 AUTH_PROVIDER_EMAIL value
             * @property {number} AUTH_PROVIDER_CUSTOM=5 AUTH_PROVIDER_CUSTOM value
             */
            auth.AuthProvider = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AUTH_PROVIDER_UNKNOWN"] = 0;
                values[valuesById[1] = "AUTH_PROVIDER_GOOGLE"] = 1;
                values[valuesById[2] = "AUTH_PROVIDER_MICROSOFT"] = 2;
                values[valuesById[3] = "AUTH_PROVIDER_SAML"] = 3;
                values[valuesById[4] = "AUTH_PROVIDER_EMAIL"] = 4;
                values[valuesById[5] = "AUTH_PROVIDER_CUSTOM"] = 5;
                return values;
            })();
    
            auth.AuthConfig = (function() {
    
                /**
                 * Properties of an AuthConfig.
                 * @memberof infinitusai.auth
                 * @interface IAuthConfig
                 * @property {string|null} [emailDomain] AuthConfig emailDomain
                 * @property {boolean|null} [enabled] AuthConfig enabled
                 * @property {Array.<infinitusai.auth.AuthProvider>|null} [approvedAuthProviders] AuthConfig approvedAuthProviders
                 * @property {number|Long|null} [createTimeMillis] AuthConfig createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] AuthConfig updateTimeMillis
                 */
    
                /**
                 * Constructs a new AuthConfig.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an AuthConfig.
                 * @implements IAuthConfig
                 * @constructor
                 * @param {infinitusai.auth.IAuthConfig=} [properties] Properties to set
                 */
                function AuthConfig(properties) {
                    this.approvedAuthProviders = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AuthConfig emailDomain.
                 * @member {string} emailDomain
                 * @memberof infinitusai.auth.AuthConfig
                 * @instance
                 */
                AuthConfig.prototype.emailDomain = "";
    
                /**
                 * AuthConfig enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.auth.AuthConfig
                 * @instance
                 */
                AuthConfig.prototype.enabled = false;
    
                /**
                 * AuthConfig approvedAuthProviders.
                 * @member {Array.<infinitusai.auth.AuthProvider>} approvedAuthProviders
                 * @memberof infinitusai.auth.AuthConfig
                 * @instance
                 */
                AuthConfig.prototype.approvedAuthProviders = $util.emptyArray;
    
                /**
                 * AuthConfig createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.auth.AuthConfig
                 * @instance
                 */
                AuthConfig.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * AuthConfig updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.auth.AuthConfig
                 * @instance
                 */
                AuthConfig.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new AuthConfig instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {infinitusai.auth.IAuthConfig=} [properties] Properties to set
                 * @returns {infinitusai.auth.AuthConfig} AuthConfig instance
                 */
                AuthConfig.create = function create(properties) {
                    return new AuthConfig(properties);
                };
    
                /**
                 * Encodes the specified AuthConfig message. Does not implicitly {@link infinitusai.auth.AuthConfig.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {infinitusai.auth.IAuthConfig} message AuthConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuthConfig.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.emailDomain != null && Object.hasOwnProperty.call(message, "emailDomain"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailDomain);
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.enabled);
                    if (message.approvedAuthProviders != null && message.approvedAuthProviders.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.approvedAuthProviders.length; ++i)
                            writer.int32(message.approvedAuthProviders[i]);
                        writer.ldelim();
                    }
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.updateTimeMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified AuthConfig message, length delimited. Does not implicitly {@link infinitusai.auth.AuthConfig.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {infinitusai.auth.IAuthConfig} message AuthConfig message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuthConfig.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AuthConfig message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.AuthConfig} AuthConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuthConfig.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.AuthConfig();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.emailDomain = reader.string();
                                break;
                            }
                        case 2: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 3: {
                                if (!(message.approvedAuthProviders && message.approvedAuthProviders.length))
                                    message.approvedAuthProviders = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.approvedAuthProviders.push(reader.int32());
                                } else
                                    message.approvedAuthProviders.push(reader.int32());
                                break;
                            }
                        case 4: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 5: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AuthConfig message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.AuthConfig} AuthConfig
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuthConfig.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AuthConfig message.
                 * @function verify
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AuthConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.emailDomain != null && message.hasOwnProperty("emailDomain"))
                        if (!$util.isString(message.emailDomain))
                            return "emailDomain: string expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.approvedAuthProviders != null && message.hasOwnProperty("approvedAuthProviders")) {
                        if (!Array.isArray(message.approvedAuthProviders))
                            return "approvedAuthProviders: array expected";
                        for (var i = 0; i < message.approvedAuthProviders.length; ++i)
                            switch (message.approvedAuthProviders[i]) {
                            default:
                                return "approvedAuthProviders: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                    }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an AuthConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.AuthConfig} AuthConfig
                 */
                AuthConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.AuthConfig)
                        return object;
                    var message = new $root.infinitusai.auth.AuthConfig();
                    if (object.emailDomain != null)
                        message.emailDomain = String(object.emailDomain);
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.approvedAuthProviders) {
                        if (!Array.isArray(object.approvedAuthProviders))
                            throw TypeError(".infinitusai.auth.AuthConfig.approvedAuthProviders: array expected");
                        message.approvedAuthProviders = [];
                        for (var i = 0; i < object.approvedAuthProviders.length; ++i)
                            switch (object.approvedAuthProviders[i]) {
                            default:
                                if (typeof object.approvedAuthProviders[i] === "number") {
                                    message.approvedAuthProviders[i] = object.approvedAuthProviders[i];
                                    break;
                                }
                            case "AUTH_PROVIDER_UNKNOWN":
                            case 0:
                                message.approvedAuthProviders[i] = 0;
                                break;
                            case "AUTH_PROVIDER_GOOGLE":
                            case 1:
                                message.approvedAuthProviders[i] = 1;
                                break;
                            case "AUTH_PROVIDER_MICROSOFT":
                            case 2:
                                message.approvedAuthProviders[i] = 2;
                                break;
                            case "AUTH_PROVIDER_SAML":
                            case 3:
                                message.approvedAuthProviders[i] = 3;
                                break;
                            case "AUTH_PROVIDER_EMAIL":
                            case 4:
                                message.approvedAuthProviders[i] = 4;
                                break;
                            case "AUTH_PROVIDER_CUSTOM":
                            case 5:
                                message.approvedAuthProviders[i] = 5;
                                break;
                            }
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an AuthConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {infinitusai.auth.AuthConfig} message AuthConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AuthConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.approvedAuthProviders = [];
                    if (options.defaults) {
                        object.emailDomain = "";
                        object.enabled = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.emailDomain != null && message.hasOwnProperty("emailDomain"))
                        object.emailDomain = message.emailDomain;
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.approvedAuthProviders && message.approvedAuthProviders.length) {
                        object.approvedAuthProviders = [];
                        for (var j = 0; j < message.approvedAuthProviders.length; ++j)
                            object.approvedAuthProviders[j] = options.enums === String ? $root.infinitusai.auth.AuthProvider[message.approvedAuthProviders[j]] === undefined ? message.approvedAuthProviders[j] : $root.infinitusai.auth.AuthProvider[message.approvedAuthProviders[j]] : message.approvedAuthProviders[j];
                    }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    return object;
                };
    
                /**
                 * Converts this AuthConfig to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.AuthConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AuthConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AuthConfig
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.AuthConfig
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AuthConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.AuthConfig";
                };
    
                return AuthConfig;
            })();
    
            auth.GetAuthConfigsRequest = (function() {
    
                /**
                 * Properties of a GetAuthConfigsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetAuthConfigsRequest
                 */
    
                /**
                 * Constructs a new GetAuthConfigsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetAuthConfigsRequest.
                 * @implements IGetAuthConfigsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetAuthConfigsRequest=} [properties] Properties to set
                 */
                function GetAuthConfigsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetAuthConfigsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {infinitusai.auth.IGetAuthConfigsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetAuthConfigsRequest} GetAuthConfigsRequest instance
                 */
                GetAuthConfigsRequest.create = function create(properties) {
                    return new GetAuthConfigsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetAuthConfigsRequest message. Does not implicitly {@link infinitusai.auth.GetAuthConfigsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {infinitusai.auth.IGetAuthConfigsRequest} message GetAuthConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAuthConfigsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAuthConfigsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetAuthConfigsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {infinitusai.auth.IGetAuthConfigsRequest} message GetAuthConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAuthConfigsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAuthConfigsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetAuthConfigsRequest} GetAuthConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAuthConfigsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetAuthConfigsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAuthConfigsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetAuthConfigsRequest} GetAuthConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAuthConfigsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAuthConfigsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAuthConfigsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetAuthConfigsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetAuthConfigsRequest} GetAuthConfigsRequest
                 */
                GetAuthConfigsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetAuthConfigsRequest)
                        return object;
                    return new $root.infinitusai.auth.GetAuthConfigsRequest();
                };
    
                /**
                 * Creates a plain object from a GetAuthConfigsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {infinitusai.auth.GetAuthConfigsRequest} message GetAuthConfigsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAuthConfigsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetAuthConfigsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAuthConfigsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAuthConfigsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetAuthConfigsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAuthConfigsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetAuthConfigsRequest";
                };
    
                return GetAuthConfigsRequest;
            })();
    
            auth.GetAuthConfigsResponse = (function() {
    
                /**
                 * Properties of a GetAuthConfigsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetAuthConfigsResponse
                 * @property {Array.<infinitusai.auth.IAuthConfig>|null} [authConfigs] GetAuthConfigsResponse authConfigs
                 */
    
                /**
                 * Constructs a new GetAuthConfigsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetAuthConfigsResponse.
                 * @implements IGetAuthConfigsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetAuthConfigsResponse=} [properties] Properties to set
                 */
                function GetAuthConfigsResponse(properties) {
                    this.authConfigs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAuthConfigsResponse authConfigs.
                 * @member {Array.<infinitusai.auth.IAuthConfig>} authConfigs
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @instance
                 */
                GetAuthConfigsResponse.prototype.authConfigs = $util.emptyArray;
    
                /**
                 * Creates a new GetAuthConfigsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {infinitusai.auth.IGetAuthConfigsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetAuthConfigsResponse} GetAuthConfigsResponse instance
                 */
                GetAuthConfigsResponse.create = function create(properties) {
                    return new GetAuthConfigsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetAuthConfigsResponse message. Does not implicitly {@link infinitusai.auth.GetAuthConfigsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {infinitusai.auth.IGetAuthConfigsResponse} message GetAuthConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAuthConfigsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.authConfigs != null && message.authConfigs.length)
                        for (var i = 0; i < message.authConfigs.length; ++i)
                            $root.infinitusai.auth.AuthConfig.encode(message.authConfigs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAuthConfigsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetAuthConfigsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {infinitusai.auth.IGetAuthConfigsResponse} message GetAuthConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAuthConfigsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAuthConfigsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetAuthConfigsResponse} GetAuthConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAuthConfigsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetAuthConfigsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.authConfigs && message.authConfigs.length))
                                    message.authConfigs = [];
                                message.authConfigs.push($root.infinitusai.auth.AuthConfig.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAuthConfigsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetAuthConfigsResponse} GetAuthConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAuthConfigsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAuthConfigsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAuthConfigsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.authConfigs != null && message.hasOwnProperty("authConfigs")) {
                        if (!Array.isArray(message.authConfigs))
                            return "authConfigs: array expected";
                        for (var i = 0; i < message.authConfigs.length; ++i) {
                            var error = $root.infinitusai.auth.AuthConfig.verify(message.authConfigs[i]);
                            if (error)
                                return "authConfigs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetAuthConfigsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetAuthConfigsResponse} GetAuthConfigsResponse
                 */
                GetAuthConfigsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetAuthConfigsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetAuthConfigsResponse();
                    if (object.authConfigs) {
                        if (!Array.isArray(object.authConfigs))
                            throw TypeError(".infinitusai.auth.GetAuthConfigsResponse.authConfigs: array expected");
                        message.authConfigs = [];
                        for (var i = 0; i < object.authConfigs.length; ++i) {
                            if (typeof object.authConfigs[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetAuthConfigsResponse.authConfigs: object expected");
                            message.authConfigs[i] = $root.infinitusai.auth.AuthConfig.fromObject(object.authConfigs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAuthConfigsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {infinitusai.auth.GetAuthConfigsResponse} message GetAuthConfigsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAuthConfigsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.authConfigs = [];
                    if (message.authConfigs && message.authConfigs.length) {
                        object.authConfigs = [];
                        for (var j = 0; j < message.authConfigs.length; ++j)
                            object.authConfigs[j] = $root.infinitusai.auth.AuthConfig.toObject(message.authConfigs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetAuthConfigsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAuthConfigsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAuthConfigsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetAuthConfigsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAuthConfigsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetAuthConfigsResponse";
                };
    
                return GetAuthConfigsResponse;
            })();
    
            auth.GetMyAuthConfigRequest = (function() {
    
                /**
                 * Properties of a GetMyAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetMyAuthConfigRequest
                 */
    
                /**
                 * Constructs a new GetMyAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetMyAuthConfigRequest.
                 * @implements IGetMyAuthConfigRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetMyAuthConfigRequest=} [properties] Properties to set
                 */
                function GetMyAuthConfigRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetMyAuthConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyAuthConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetMyAuthConfigRequest} GetMyAuthConfigRequest instance
                 */
                GetMyAuthConfigRequest.create = function create(properties) {
                    return new GetMyAuthConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified GetMyAuthConfigRequest message. Does not implicitly {@link infinitusai.auth.GetMyAuthConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyAuthConfigRequest} message GetMyAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyAuthConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyAuthConfigRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetMyAuthConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IGetMyAuthConfigRequest} message GetMyAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyAuthConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyAuthConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetMyAuthConfigRequest} GetMyAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyAuthConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetMyAuthConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyAuthConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetMyAuthConfigRequest} GetMyAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyAuthConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyAuthConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyAuthConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetMyAuthConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetMyAuthConfigRequest} GetMyAuthConfigRequest
                 */
                GetMyAuthConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetMyAuthConfigRequest)
                        return object;
                    return new $root.infinitusai.auth.GetMyAuthConfigRequest();
                };
    
                /**
                 * Creates a plain object from a GetMyAuthConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.GetMyAuthConfigRequest} message GetMyAuthConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyAuthConfigRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetMyAuthConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyAuthConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyAuthConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetMyAuthConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyAuthConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetMyAuthConfigRequest";
                };
    
                return GetMyAuthConfigRequest;
            })();
    
            auth.GetMyAuthConfigResponse = (function() {
    
                /**
                 * Properties of a GetMyAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetMyAuthConfigResponse
                 * @property {infinitusai.auth.IAuthConfig|null} [authConfig] GetMyAuthConfigResponse authConfig
                 */
    
                /**
                 * Constructs a new GetMyAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetMyAuthConfigResponse.
                 * @implements IGetMyAuthConfigResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetMyAuthConfigResponse=} [properties] Properties to set
                 */
                function GetMyAuthConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetMyAuthConfigResponse authConfig.
                 * @member {infinitusai.auth.IAuthConfig|null|undefined} authConfig
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @instance
                 */
                GetMyAuthConfigResponse.prototype.authConfig = null;
    
                /**
                 * Creates a new GetMyAuthConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyAuthConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetMyAuthConfigResponse} GetMyAuthConfigResponse instance
                 */
                GetMyAuthConfigResponse.create = function create(properties) {
                    return new GetMyAuthConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified GetMyAuthConfigResponse message. Does not implicitly {@link infinitusai.auth.GetMyAuthConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyAuthConfigResponse} message GetMyAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyAuthConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.authConfig != null && Object.hasOwnProperty.call(message, "authConfig"))
                        $root.infinitusai.auth.AuthConfig.encode(message.authConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetMyAuthConfigResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetMyAuthConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IGetMyAuthConfigResponse} message GetMyAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetMyAuthConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetMyAuthConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetMyAuthConfigResponse} GetMyAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyAuthConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetMyAuthConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.authConfig = $root.infinitusai.auth.AuthConfig.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetMyAuthConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetMyAuthConfigResponse} GetMyAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetMyAuthConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetMyAuthConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetMyAuthConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.authConfig != null && message.hasOwnProperty("authConfig")) {
                        var error = $root.infinitusai.auth.AuthConfig.verify(message.authConfig);
                        if (error)
                            return "authConfig." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetMyAuthConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetMyAuthConfigResponse} GetMyAuthConfigResponse
                 */
                GetMyAuthConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetMyAuthConfigResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetMyAuthConfigResponse();
                    if (object.authConfig != null) {
                        if (typeof object.authConfig !== "object")
                            throw TypeError(".infinitusai.auth.GetMyAuthConfigResponse.authConfig: object expected");
                        message.authConfig = $root.infinitusai.auth.AuthConfig.fromObject(object.authConfig);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetMyAuthConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.GetMyAuthConfigResponse} message GetMyAuthConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetMyAuthConfigResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.authConfig = null;
                    if (message.authConfig != null && message.hasOwnProperty("authConfig"))
                        object.authConfig = $root.infinitusai.auth.AuthConfig.toObject(message.authConfig, options);
                    return object;
                };
    
                /**
                 * Converts this GetMyAuthConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetMyAuthConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetMyAuthConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetMyAuthConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetMyAuthConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetMyAuthConfigResponse";
                };
    
                return GetMyAuthConfigResponse;
            })();
    
            auth.CreateAuthConfigRequest = (function() {
    
                /**
                 * Properties of a CreateAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @interface ICreateAuthConfigRequest
                 * @property {string|null} [emailDomain] CreateAuthConfigRequest emailDomain
                 * @property {boolean|null} [enabled] CreateAuthConfigRequest enabled
                 * @property {Array.<infinitusai.auth.AuthProvider>|null} [approvedAuthProviders] CreateAuthConfigRequest approvedAuthProviders
                 */
    
                /**
                 * Constructs a new CreateAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateAuthConfigRequest.
                 * @implements ICreateAuthConfigRequest
                 * @constructor
                 * @param {infinitusai.auth.ICreateAuthConfigRequest=} [properties] Properties to set
                 */
                function CreateAuthConfigRequest(properties) {
                    this.approvedAuthProviders = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateAuthConfigRequest emailDomain.
                 * @member {string} emailDomain
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @instance
                 */
                CreateAuthConfigRequest.prototype.emailDomain = "";
    
                /**
                 * CreateAuthConfigRequest enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @instance
                 */
                CreateAuthConfigRequest.prototype.enabled = false;
    
                /**
                 * CreateAuthConfigRequest approvedAuthProviders.
                 * @member {Array.<infinitusai.auth.AuthProvider>} approvedAuthProviders
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @instance
                 */
                CreateAuthConfigRequest.prototype.approvedAuthProviders = $util.emptyArray;
    
                /**
                 * Creates a new CreateAuthConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.ICreateAuthConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateAuthConfigRequest} CreateAuthConfigRequest instance
                 */
                CreateAuthConfigRequest.create = function create(properties) {
                    return new CreateAuthConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateAuthConfigRequest message. Does not implicitly {@link infinitusai.auth.CreateAuthConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.ICreateAuthConfigRequest} message CreateAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAuthConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.emailDomain != null && Object.hasOwnProperty.call(message, "emailDomain"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailDomain);
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.enabled);
                    if (message.approvedAuthProviders != null && message.approvedAuthProviders.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.approvedAuthProviders.length; ++i)
                            writer.int32(message.approvedAuthProviders[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateAuthConfigRequest message, length delimited. Does not implicitly {@link infinitusai.auth.CreateAuthConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.ICreateAuthConfigRequest} message CreateAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAuthConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateAuthConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateAuthConfigRequest} CreateAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAuthConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateAuthConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.emailDomain = reader.string();
                                break;
                            }
                        case 2: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 3: {
                                if (!(message.approvedAuthProviders && message.approvedAuthProviders.length))
                                    message.approvedAuthProviders = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.approvedAuthProviders.push(reader.int32());
                                } else
                                    message.approvedAuthProviders.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateAuthConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateAuthConfigRequest} CreateAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAuthConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateAuthConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAuthConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.emailDomain != null && message.hasOwnProperty("emailDomain"))
                        if (!$util.isString(message.emailDomain))
                            return "emailDomain: string expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.approvedAuthProviders != null && message.hasOwnProperty("approvedAuthProviders")) {
                        if (!Array.isArray(message.approvedAuthProviders))
                            return "approvedAuthProviders: array expected";
                        for (var i = 0; i < message.approvedAuthProviders.length; ++i)
                            switch (message.approvedAuthProviders[i]) {
                            default:
                                return "approvedAuthProviders: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateAuthConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateAuthConfigRequest} CreateAuthConfigRequest
                 */
                CreateAuthConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateAuthConfigRequest)
                        return object;
                    var message = new $root.infinitusai.auth.CreateAuthConfigRequest();
                    if (object.emailDomain != null)
                        message.emailDomain = String(object.emailDomain);
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.approvedAuthProviders) {
                        if (!Array.isArray(object.approvedAuthProviders))
                            throw TypeError(".infinitusai.auth.CreateAuthConfigRequest.approvedAuthProviders: array expected");
                        message.approvedAuthProviders = [];
                        for (var i = 0; i < object.approvedAuthProviders.length; ++i)
                            switch (object.approvedAuthProviders[i]) {
                            default:
                                if (typeof object.approvedAuthProviders[i] === "number") {
                                    message.approvedAuthProviders[i] = object.approvedAuthProviders[i];
                                    break;
                                }
                            case "AUTH_PROVIDER_UNKNOWN":
                            case 0:
                                message.approvedAuthProviders[i] = 0;
                                break;
                            case "AUTH_PROVIDER_GOOGLE":
                            case 1:
                                message.approvedAuthProviders[i] = 1;
                                break;
                            case "AUTH_PROVIDER_MICROSOFT":
                            case 2:
                                message.approvedAuthProviders[i] = 2;
                                break;
                            case "AUTH_PROVIDER_SAML":
                            case 3:
                                message.approvedAuthProviders[i] = 3;
                                break;
                            case "AUTH_PROVIDER_EMAIL":
                            case 4:
                                message.approvedAuthProviders[i] = 4;
                                break;
                            case "AUTH_PROVIDER_CUSTOM":
                            case 5:
                                message.approvedAuthProviders[i] = 5;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateAuthConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.CreateAuthConfigRequest} message CreateAuthConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAuthConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.approvedAuthProviders = [];
                    if (options.defaults) {
                        object.emailDomain = "";
                        object.enabled = false;
                    }
                    if (message.emailDomain != null && message.hasOwnProperty("emailDomain"))
                        object.emailDomain = message.emailDomain;
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.approvedAuthProviders && message.approvedAuthProviders.length) {
                        object.approvedAuthProviders = [];
                        for (var j = 0; j < message.approvedAuthProviders.length; ++j)
                            object.approvedAuthProviders[j] = options.enums === String ? $root.infinitusai.auth.AuthProvider[message.approvedAuthProviders[j]] === undefined ? message.approvedAuthProviders[j] : $root.infinitusai.auth.AuthProvider[message.approvedAuthProviders[j]] : message.approvedAuthProviders[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this CreateAuthConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAuthConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateAuthConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateAuthConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateAuthConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateAuthConfigRequest";
                };
    
                return CreateAuthConfigRequest;
            })();
    
            auth.CreateAuthConfigResponse = (function() {
    
                /**
                 * Properties of a CreateAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @interface ICreateAuthConfigResponse
                 * @property {infinitusai.auth.IAuthConfig|null} [authConfig] CreateAuthConfigResponse authConfig
                 */
    
                /**
                 * Constructs a new CreateAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateAuthConfigResponse.
                 * @implements ICreateAuthConfigResponse
                 * @constructor
                 * @param {infinitusai.auth.ICreateAuthConfigResponse=} [properties] Properties to set
                 */
                function CreateAuthConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateAuthConfigResponse authConfig.
                 * @member {infinitusai.auth.IAuthConfig|null|undefined} authConfig
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @instance
                 */
                CreateAuthConfigResponse.prototype.authConfig = null;
    
                /**
                 * Creates a new CreateAuthConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.ICreateAuthConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateAuthConfigResponse} CreateAuthConfigResponse instance
                 */
                CreateAuthConfigResponse.create = function create(properties) {
                    return new CreateAuthConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateAuthConfigResponse message. Does not implicitly {@link infinitusai.auth.CreateAuthConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.ICreateAuthConfigResponse} message CreateAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAuthConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.authConfig != null && Object.hasOwnProperty.call(message, "authConfig"))
                        $root.infinitusai.auth.AuthConfig.encode(message.authConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateAuthConfigResponse message, length delimited. Does not implicitly {@link infinitusai.auth.CreateAuthConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.ICreateAuthConfigResponse} message CreateAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAuthConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateAuthConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateAuthConfigResponse} CreateAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAuthConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateAuthConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.authConfig = $root.infinitusai.auth.AuthConfig.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateAuthConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateAuthConfigResponse} CreateAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAuthConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateAuthConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAuthConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.authConfig != null && message.hasOwnProperty("authConfig")) {
                        var error = $root.infinitusai.auth.AuthConfig.verify(message.authConfig);
                        if (error)
                            return "authConfig." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateAuthConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateAuthConfigResponse} CreateAuthConfigResponse
                 */
                CreateAuthConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateAuthConfigResponse)
                        return object;
                    var message = new $root.infinitusai.auth.CreateAuthConfigResponse();
                    if (object.authConfig != null) {
                        if (typeof object.authConfig !== "object")
                            throw TypeError(".infinitusai.auth.CreateAuthConfigResponse.authConfig: object expected");
                        message.authConfig = $root.infinitusai.auth.AuthConfig.fromObject(object.authConfig);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateAuthConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.CreateAuthConfigResponse} message CreateAuthConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAuthConfigResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.authConfig = null;
                    if (message.authConfig != null && message.hasOwnProperty("authConfig"))
                        object.authConfig = $root.infinitusai.auth.AuthConfig.toObject(message.authConfig, options);
                    return object;
                };
    
                /**
                 * Converts this CreateAuthConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAuthConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateAuthConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateAuthConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateAuthConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateAuthConfigResponse";
                };
    
                return CreateAuthConfigResponse;
            })();
    
            auth.UpdateAuthConfigRequest = (function() {
    
                /**
                 * Properties of an UpdateAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @interface IUpdateAuthConfigRequest
                 * @property {string|null} [emailDomain] UpdateAuthConfigRequest emailDomain
                 * @property {boolean|null} [enabled] UpdateAuthConfigRequest enabled
                 * @property {Array.<infinitusai.auth.AuthProvider>|null} [approvedAuthProviders] UpdateAuthConfigRequest approvedAuthProviders
                 */
    
                /**
                 * Constructs a new UpdateAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UpdateAuthConfigRequest.
                 * @implements IUpdateAuthConfigRequest
                 * @constructor
                 * @param {infinitusai.auth.IUpdateAuthConfigRequest=} [properties] Properties to set
                 */
                function UpdateAuthConfigRequest(properties) {
                    this.approvedAuthProviders = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateAuthConfigRequest emailDomain.
                 * @member {string} emailDomain
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @instance
                 */
                UpdateAuthConfigRequest.prototype.emailDomain = "";
    
                /**
                 * UpdateAuthConfigRequest enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @instance
                 */
                UpdateAuthConfigRequest.prototype.enabled = false;
    
                /**
                 * UpdateAuthConfigRequest approvedAuthProviders.
                 * @member {Array.<infinitusai.auth.AuthProvider>} approvedAuthProviders
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @instance
                 */
                UpdateAuthConfigRequest.prototype.approvedAuthProviders = $util.emptyArray;
    
                /**
                 * Creates a new UpdateAuthConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateAuthConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.UpdateAuthConfigRequest} UpdateAuthConfigRequest instance
                 */
                UpdateAuthConfigRequest.create = function create(properties) {
                    return new UpdateAuthConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateAuthConfigRequest message. Does not implicitly {@link infinitusai.auth.UpdateAuthConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateAuthConfigRequest} message UpdateAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateAuthConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.emailDomain != null && Object.hasOwnProperty.call(message, "emailDomain"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailDomain);
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.enabled);
                    if (message.approvedAuthProviders != null && message.approvedAuthProviders.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.approvedAuthProviders.length; ++i)
                            writer.int32(message.approvedAuthProviders[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateAuthConfigRequest message, length delimited. Does not implicitly {@link infinitusai.auth.UpdateAuthConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateAuthConfigRequest} message UpdateAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateAuthConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateAuthConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UpdateAuthConfigRequest} UpdateAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateAuthConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UpdateAuthConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.emailDomain = reader.string();
                                break;
                            }
                        case 2: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 3: {
                                if (!(message.approvedAuthProviders && message.approvedAuthProviders.length))
                                    message.approvedAuthProviders = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.approvedAuthProviders.push(reader.int32());
                                } else
                                    message.approvedAuthProviders.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateAuthConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UpdateAuthConfigRequest} UpdateAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateAuthConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateAuthConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateAuthConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.emailDomain != null && message.hasOwnProperty("emailDomain"))
                        if (!$util.isString(message.emailDomain))
                            return "emailDomain: string expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.approvedAuthProviders != null && message.hasOwnProperty("approvedAuthProviders")) {
                        if (!Array.isArray(message.approvedAuthProviders))
                            return "approvedAuthProviders: array expected";
                        for (var i = 0; i < message.approvedAuthProviders.length; ++i)
                            switch (message.approvedAuthProviders[i]) {
                            default:
                                return "approvedAuthProviders: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateAuthConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UpdateAuthConfigRequest} UpdateAuthConfigRequest
                 */
                UpdateAuthConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UpdateAuthConfigRequest)
                        return object;
                    var message = new $root.infinitusai.auth.UpdateAuthConfigRequest();
                    if (object.emailDomain != null)
                        message.emailDomain = String(object.emailDomain);
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.approvedAuthProviders) {
                        if (!Array.isArray(object.approvedAuthProviders))
                            throw TypeError(".infinitusai.auth.UpdateAuthConfigRequest.approvedAuthProviders: array expected");
                        message.approvedAuthProviders = [];
                        for (var i = 0; i < object.approvedAuthProviders.length; ++i)
                            switch (object.approvedAuthProviders[i]) {
                            default:
                                if (typeof object.approvedAuthProviders[i] === "number") {
                                    message.approvedAuthProviders[i] = object.approvedAuthProviders[i];
                                    break;
                                }
                            case "AUTH_PROVIDER_UNKNOWN":
                            case 0:
                                message.approvedAuthProviders[i] = 0;
                                break;
                            case "AUTH_PROVIDER_GOOGLE":
                            case 1:
                                message.approvedAuthProviders[i] = 1;
                                break;
                            case "AUTH_PROVIDER_MICROSOFT":
                            case 2:
                                message.approvedAuthProviders[i] = 2;
                                break;
                            case "AUTH_PROVIDER_SAML":
                            case 3:
                                message.approvedAuthProviders[i] = 3;
                                break;
                            case "AUTH_PROVIDER_EMAIL":
                            case 4:
                                message.approvedAuthProviders[i] = 4;
                                break;
                            case "AUTH_PROVIDER_CUSTOM":
                            case 5:
                                message.approvedAuthProviders[i] = 5;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateAuthConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.UpdateAuthConfigRequest} message UpdateAuthConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateAuthConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.approvedAuthProviders = [];
                    if (options.defaults) {
                        object.emailDomain = "";
                        object.enabled = false;
                    }
                    if (message.emailDomain != null && message.hasOwnProperty("emailDomain"))
                        object.emailDomain = message.emailDomain;
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.approvedAuthProviders && message.approvedAuthProviders.length) {
                        object.approvedAuthProviders = [];
                        for (var j = 0; j < message.approvedAuthProviders.length; ++j)
                            object.approvedAuthProviders[j] = options.enums === String ? $root.infinitusai.auth.AuthProvider[message.approvedAuthProviders[j]] === undefined ? message.approvedAuthProviders[j] : $root.infinitusai.auth.AuthProvider[message.approvedAuthProviders[j]] : message.approvedAuthProviders[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this UpdateAuthConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateAuthConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateAuthConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UpdateAuthConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateAuthConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UpdateAuthConfigRequest";
                };
    
                return UpdateAuthConfigRequest;
            })();
    
            auth.UpdateAuthConfigResponse = (function() {
    
                /**
                 * Properties of an UpdateAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @interface IUpdateAuthConfigResponse
                 * @property {infinitusai.auth.IAuthConfig|null} [authConfig] UpdateAuthConfigResponse authConfig
                 */
    
                /**
                 * Constructs a new UpdateAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UpdateAuthConfigResponse.
                 * @implements IUpdateAuthConfigResponse
                 * @constructor
                 * @param {infinitusai.auth.IUpdateAuthConfigResponse=} [properties] Properties to set
                 */
                function UpdateAuthConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateAuthConfigResponse authConfig.
                 * @member {infinitusai.auth.IAuthConfig|null|undefined} authConfig
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @instance
                 */
                UpdateAuthConfigResponse.prototype.authConfig = null;
    
                /**
                 * Creates a new UpdateAuthConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateAuthConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.UpdateAuthConfigResponse} UpdateAuthConfigResponse instance
                 */
                UpdateAuthConfigResponse.create = function create(properties) {
                    return new UpdateAuthConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateAuthConfigResponse message. Does not implicitly {@link infinitusai.auth.UpdateAuthConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateAuthConfigResponse} message UpdateAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateAuthConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.authConfig != null && Object.hasOwnProperty.call(message, "authConfig"))
                        $root.infinitusai.auth.AuthConfig.encode(message.authConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateAuthConfigResponse message, length delimited. Does not implicitly {@link infinitusai.auth.UpdateAuthConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateAuthConfigResponse} message UpdateAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateAuthConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateAuthConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UpdateAuthConfigResponse} UpdateAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateAuthConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UpdateAuthConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.authConfig = $root.infinitusai.auth.AuthConfig.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateAuthConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UpdateAuthConfigResponse} UpdateAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateAuthConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateAuthConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateAuthConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.authConfig != null && message.hasOwnProperty("authConfig")) {
                        var error = $root.infinitusai.auth.AuthConfig.verify(message.authConfig);
                        if (error)
                            return "authConfig." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateAuthConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UpdateAuthConfigResponse} UpdateAuthConfigResponse
                 */
                UpdateAuthConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UpdateAuthConfigResponse)
                        return object;
                    var message = new $root.infinitusai.auth.UpdateAuthConfigResponse();
                    if (object.authConfig != null) {
                        if (typeof object.authConfig !== "object")
                            throw TypeError(".infinitusai.auth.UpdateAuthConfigResponse.authConfig: object expected");
                        message.authConfig = $root.infinitusai.auth.AuthConfig.fromObject(object.authConfig);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateAuthConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.UpdateAuthConfigResponse} message UpdateAuthConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateAuthConfigResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.authConfig = null;
                    if (message.authConfig != null && message.hasOwnProperty("authConfig"))
                        object.authConfig = $root.infinitusai.auth.AuthConfig.toObject(message.authConfig, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateAuthConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateAuthConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateAuthConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UpdateAuthConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateAuthConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UpdateAuthConfigResponse";
                };
    
                return UpdateAuthConfigResponse;
            })();
    
            auth.DeleteAuthConfigRequest = (function() {
    
                /**
                 * Properties of a DeleteAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @interface IDeleteAuthConfigRequest
                 * @property {string|null} [emailDomain] DeleteAuthConfigRequest emailDomain
                 */
    
                /**
                 * Constructs a new DeleteAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeleteAuthConfigRequest.
                 * @implements IDeleteAuthConfigRequest
                 * @constructor
                 * @param {infinitusai.auth.IDeleteAuthConfigRequest=} [properties] Properties to set
                 */
                function DeleteAuthConfigRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteAuthConfigRequest emailDomain.
                 * @member {string} emailDomain
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @instance
                 */
                DeleteAuthConfigRequest.prototype.emailDomain = "";
    
                /**
                 * Creates a new DeleteAuthConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteAuthConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeleteAuthConfigRequest} DeleteAuthConfigRequest instance
                 */
                DeleteAuthConfigRequest.create = function create(properties) {
                    return new DeleteAuthConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteAuthConfigRequest message. Does not implicitly {@link infinitusai.auth.DeleteAuthConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteAuthConfigRequest} message DeleteAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteAuthConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.emailDomain != null && Object.hasOwnProperty.call(message, "emailDomain"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailDomain);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteAuthConfigRequest message, length delimited. Does not implicitly {@link infinitusai.auth.DeleteAuthConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteAuthConfigRequest} message DeleteAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteAuthConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteAuthConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeleteAuthConfigRequest} DeleteAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteAuthConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeleteAuthConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.emailDomain = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteAuthConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeleteAuthConfigRequest} DeleteAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteAuthConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteAuthConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteAuthConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.emailDomain != null && message.hasOwnProperty("emailDomain"))
                        if (!$util.isString(message.emailDomain))
                            return "emailDomain: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteAuthConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeleteAuthConfigRequest} DeleteAuthConfigRequest
                 */
                DeleteAuthConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeleteAuthConfigRequest)
                        return object;
                    var message = new $root.infinitusai.auth.DeleteAuthConfigRequest();
                    if (object.emailDomain != null)
                        message.emailDomain = String(object.emailDomain);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteAuthConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.DeleteAuthConfigRequest} message DeleteAuthConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteAuthConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.emailDomain = "";
                    if (message.emailDomain != null && message.hasOwnProperty("emailDomain"))
                        object.emailDomain = message.emailDomain;
                    return object;
                };
    
                /**
                 * Converts this DeleteAuthConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteAuthConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteAuthConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeleteAuthConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteAuthConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeleteAuthConfigRequest";
                };
    
                return DeleteAuthConfigRequest;
            })();
    
            auth.DeleteAuthConfigResponse = (function() {
    
                /**
                 * Properties of a DeleteAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @interface IDeleteAuthConfigResponse
                 */
    
                /**
                 * Constructs a new DeleteAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeleteAuthConfigResponse.
                 * @implements IDeleteAuthConfigResponse
                 * @constructor
                 * @param {infinitusai.auth.IDeleteAuthConfigResponse=} [properties] Properties to set
                 */
                function DeleteAuthConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteAuthConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteAuthConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeleteAuthConfigResponse} DeleteAuthConfigResponse instance
                 */
                DeleteAuthConfigResponse.create = function create(properties) {
                    return new DeleteAuthConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteAuthConfigResponse message. Does not implicitly {@link infinitusai.auth.DeleteAuthConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteAuthConfigResponse} message DeleteAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteAuthConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteAuthConfigResponse message, length delimited. Does not implicitly {@link infinitusai.auth.DeleteAuthConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteAuthConfigResponse} message DeleteAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteAuthConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteAuthConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeleteAuthConfigResponse} DeleteAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteAuthConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeleteAuthConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteAuthConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeleteAuthConfigResponse} DeleteAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteAuthConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteAuthConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteAuthConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteAuthConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeleteAuthConfigResponse} DeleteAuthConfigResponse
                 */
                DeleteAuthConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeleteAuthConfigResponse)
                        return object;
                    return new $root.infinitusai.auth.DeleteAuthConfigResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteAuthConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.DeleteAuthConfigResponse} message DeleteAuthConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteAuthConfigResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteAuthConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteAuthConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteAuthConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeleteAuthConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteAuthConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeleteAuthConfigResponse";
                };
    
                return DeleteAuthConfigResponse;
            })();
    
            /**
             * EhrType enum.
             * @name infinitusai.auth.EhrType
             * @enum {number}
             * @property {number} EPIC=0 EPIC value
             */
            auth.EhrType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "EPIC"] = 0;
                return values;
            })();
    
            auth.EhrUser = (function() {
    
                /**
                 * Properties of an EhrUser.
                 * @memberof infinitusai.auth
                 * @interface IEhrUser
                 * @property {string|null} [ehrUserId] EhrUser ehrUserId
                 * @property {infinitusai.auth.EhrType|null} [ehrType] EhrUser ehrType
                 * @property {string|null} [infinitusUserId] EhrUser infinitusUserId
                 * @property {number|Long|null} [createdMillis] EhrUser createdMillis
                 * @property {number|Long|null} [updatedMillis] EhrUser updatedMillis
                 */
    
                /**
                 * Constructs a new EhrUser.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an EhrUser.
                 * @implements IEhrUser
                 * @constructor
                 * @param {infinitusai.auth.IEhrUser=} [properties] Properties to set
                 */
                function EhrUser(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EhrUser ehrUserId.
                 * @member {string} ehrUserId
                 * @memberof infinitusai.auth.EhrUser
                 * @instance
                 */
                EhrUser.prototype.ehrUserId = "";
    
                /**
                 * EhrUser ehrType.
                 * @member {infinitusai.auth.EhrType} ehrType
                 * @memberof infinitusai.auth.EhrUser
                 * @instance
                 */
                EhrUser.prototype.ehrType = 0;
    
                /**
                 * EhrUser infinitusUserId.
                 * @member {string} infinitusUserId
                 * @memberof infinitusai.auth.EhrUser
                 * @instance
                 */
                EhrUser.prototype.infinitusUserId = "";
    
                /**
                 * EhrUser createdMillis.
                 * @member {number|Long} createdMillis
                 * @memberof infinitusai.auth.EhrUser
                 * @instance
                 */
                EhrUser.prototype.createdMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * EhrUser updatedMillis.
                 * @member {number|Long} updatedMillis
                 * @memberof infinitusai.auth.EhrUser
                 * @instance
                 */
                EhrUser.prototype.updatedMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new EhrUser instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {infinitusai.auth.IEhrUser=} [properties] Properties to set
                 * @returns {infinitusai.auth.EhrUser} EhrUser instance
                 */
                EhrUser.create = function create(properties) {
                    return new EhrUser(properties);
                };
    
                /**
                 * Encodes the specified EhrUser message. Does not implicitly {@link infinitusai.auth.EhrUser.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {infinitusai.auth.IEhrUser} message EhrUser message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EhrUser.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ehrUserId != null && Object.hasOwnProperty.call(message, "ehrUserId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.ehrUserId);
                    if (message.ehrType != null && Object.hasOwnProperty.call(message, "ehrType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ehrType);
                    if (message.infinitusUserId != null && Object.hasOwnProperty.call(message, "infinitusUserId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.infinitusUserId);
                    if (message.createdMillis != null && Object.hasOwnProperty.call(message, "createdMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createdMillis);
                    if (message.updatedMillis != null && Object.hasOwnProperty.call(message, "updatedMillis"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.updatedMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified EhrUser message, length delimited. Does not implicitly {@link infinitusai.auth.EhrUser.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {infinitusai.auth.IEhrUser} message EhrUser message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EhrUser.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EhrUser message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.EhrUser} EhrUser
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EhrUser.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.EhrUser();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ehrUserId = reader.string();
                                break;
                            }
                        case 2: {
                                message.ehrType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.infinitusUserId = reader.string();
                                break;
                            }
                        case 4: {
                                message.createdMillis = reader.int64();
                                break;
                            }
                        case 5: {
                                message.updatedMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EhrUser message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.EhrUser} EhrUser
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EhrUser.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EhrUser message.
                 * @function verify
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EhrUser.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ehrUserId != null && message.hasOwnProperty("ehrUserId"))
                        if (!$util.isString(message.ehrUserId))
                            return "ehrUserId: string expected";
                    if (message.ehrType != null && message.hasOwnProperty("ehrType"))
                        switch (message.ehrType) {
                        default:
                            return "ehrType: enum value expected";
                        case 0:
                            break;
                        }
                    if (message.infinitusUserId != null && message.hasOwnProperty("infinitusUserId"))
                        if (!$util.isString(message.infinitusUserId))
                            return "infinitusUserId: string expected";
                    if (message.createdMillis != null && message.hasOwnProperty("createdMillis"))
                        if (!$util.isInteger(message.createdMillis) && !(message.createdMillis && $util.isInteger(message.createdMillis.low) && $util.isInteger(message.createdMillis.high)))
                            return "createdMillis: integer|Long expected";
                    if (message.updatedMillis != null && message.hasOwnProperty("updatedMillis"))
                        if (!$util.isInteger(message.updatedMillis) && !(message.updatedMillis && $util.isInteger(message.updatedMillis.low) && $util.isInteger(message.updatedMillis.high)))
                            return "updatedMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an EhrUser message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.EhrUser} EhrUser
                 */
                EhrUser.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.EhrUser)
                        return object;
                    var message = new $root.infinitusai.auth.EhrUser();
                    if (object.ehrUserId != null)
                        message.ehrUserId = String(object.ehrUserId);
                    switch (object.ehrType) {
                    default:
                        if (typeof object.ehrType === "number") {
                            message.ehrType = object.ehrType;
                            break;
                        }
                        break;
                    case "EPIC":
                    case 0:
                        message.ehrType = 0;
                        break;
                    }
                    if (object.infinitusUserId != null)
                        message.infinitusUserId = String(object.infinitusUserId);
                    if (object.createdMillis != null)
                        if ($util.Long)
                            (message.createdMillis = $util.Long.fromValue(object.createdMillis)).unsigned = false;
                        else if (typeof object.createdMillis === "string")
                            message.createdMillis = parseInt(object.createdMillis, 10);
                        else if (typeof object.createdMillis === "number")
                            message.createdMillis = object.createdMillis;
                        else if (typeof object.createdMillis === "object")
                            message.createdMillis = new $util.LongBits(object.createdMillis.low >>> 0, object.createdMillis.high >>> 0).toNumber();
                    if (object.updatedMillis != null)
                        if ($util.Long)
                            (message.updatedMillis = $util.Long.fromValue(object.updatedMillis)).unsigned = false;
                        else if (typeof object.updatedMillis === "string")
                            message.updatedMillis = parseInt(object.updatedMillis, 10);
                        else if (typeof object.updatedMillis === "number")
                            message.updatedMillis = object.updatedMillis;
                        else if (typeof object.updatedMillis === "object")
                            message.updatedMillis = new $util.LongBits(object.updatedMillis.low >>> 0, object.updatedMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an EhrUser message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {infinitusai.auth.EhrUser} message EhrUser
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EhrUser.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.ehrUserId = "";
                        object.ehrType = options.enums === String ? "EPIC" : 0;
                        object.infinitusUserId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createdMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updatedMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.ehrUserId != null && message.hasOwnProperty("ehrUserId"))
                        object.ehrUserId = message.ehrUserId;
                    if (message.ehrType != null && message.hasOwnProperty("ehrType"))
                        object.ehrType = options.enums === String ? $root.infinitusai.auth.EhrType[message.ehrType] === undefined ? message.ehrType : $root.infinitusai.auth.EhrType[message.ehrType] : message.ehrType;
                    if (message.infinitusUserId != null && message.hasOwnProperty("infinitusUserId"))
                        object.infinitusUserId = message.infinitusUserId;
                    if (message.createdMillis != null && message.hasOwnProperty("createdMillis"))
                        if (typeof message.createdMillis === "number")
                            object.createdMillis = options.longs === String ? String(message.createdMillis) : message.createdMillis;
                        else
                            object.createdMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createdMillis) : options.longs === Number ? new $util.LongBits(message.createdMillis.low >>> 0, message.createdMillis.high >>> 0).toNumber() : message.createdMillis;
                    if (message.updatedMillis != null && message.hasOwnProperty("updatedMillis"))
                        if (typeof message.updatedMillis === "number")
                            object.updatedMillis = options.longs === String ? String(message.updatedMillis) : message.updatedMillis;
                        else
                            object.updatedMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updatedMillis) : options.longs === Number ? new $util.LongBits(message.updatedMillis.low >>> 0, message.updatedMillis.high >>> 0).toNumber() : message.updatedMillis;
                    return object;
                };
    
                /**
                 * Converts this EhrUser to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.EhrUser
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EhrUser.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EhrUser
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.EhrUser
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EhrUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.EhrUser";
                };
    
                return EhrUser;
            })();
    
            auth.CreateEhrUserRequest = (function() {
    
                /**
                 * Properties of a CreateEhrUserRequest.
                 * @memberof infinitusai.auth
                 * @interface ICreateEhrUserRequest
                 * @property {string|null} [ehrUserId] CreateEhrUserRequest ehrUserId
                 * @property {infinitusai.auth.EhrType|null} [ehrType] CreateEhrUserRequest ehrType
                 * @property {string|null} [infinitusEmail] CreateEhrUserRequest infinitusEmail
                 */
    
                /**
                 * Constructs a new CreateEhrUserRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateEhrUserRequest.
                 * @implements ICreateEhrUserRequest
                 * @constructor
                 * @param {infinitusai.auth.ICreateEhrUserRequest=} [properties] Properties to set
                 */
                function CreateEhrUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateEhrUserRequest ehrUserId.
                 * @member {string} ehrUserId
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @instance
                 */
                CreateEhrUserRequest.prototype.ehrUserId = "";
    
                /**
                 * CreateEhrUserRequest ehrType.
                 * @member {infinitusai.auth.EhrType} ehrType
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @instance
                 */
                CreateEhrUserRequest.prototype.ehrType = 0;
    
                /**
                 * CreateEhrUserRequest infinitusEmail.
                 * @member {string} infinitusEmail
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @instance
                 */
                CreateEhrUserRequest.prototype.infinitusEmail = "";
    
                /**
                 * Creates a new CreateEhrUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {infinitusai.auth.ICreateEhrUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateEhrUserRequest} CreateEhrUserRequest instance
                 */
                CreateEhrUserRequest.create = function create(properties) {
                    return new CreateEhrUserRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateEhrUserRequest message. Does not implicitly {@link infinitusai.auth.CreateEhrUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {infinitusai.auth.ICreateEhrUserRequest} message CreateEhrUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateEhrUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ehrUserId != null && Object.hasOwnProperty.call(message, "ehrUserId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.ehrUserId);
                    if (message.ehrType != null && Object.hasOwnProperty.call(message, "ehrType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ehrType);
                    if (message.infinitusEmail != null && Object.hasOwnProperty.call(message, "infinitusEmail"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.infinitusEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateEhrUserRequest message, length delimited. Does not implicitly {@link infinitusai.auth.CreateEhrUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {infinitusai.auth.ICreateEhrUserRequest} message CreateEhrUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateEhrUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateEhrUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateEhrUserRequest} CreateEhrUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateEhrUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateEhrUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ehrUserId = reader.string();
                                break;
                            }
                        case 2: {
                                message.ehrType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.infinitusEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateEhrUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateEhrUserRequest} CreateEhrUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateEhrUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateEhrUserRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateEhrUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ehrUserId != null && message.hasOwnProperty("ehrUserId"))
                        if (!$util.isString(message.ehrUserId))
                            return "ehrUserId: string expected";
                    if (message.ehrType != null && message.hasOwnProperty("ehrType"))
                        switch (message.ehrType) {
                        default:
                            return "ehrType: enum value expected";
                        case 0:
                            break;
                        }
                    if (message.infinitusEmail != null && message.hasOwnProperty("infinitusEmail"))
                        if (!$util.isString(message.infinitusEmail))
                            return "infinitusEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a CreateEhrUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateEhrUserRequest} CreateEhrUserRequest
                 */
                CreateEhrUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateEhrUserRequest)
                        return object;
                    var message = new $root.infinitusai.auth.CreateEhrUserRequest();
                    if (object.ehrUserId != null)
                        message.ehrUserId = String(object.ehrUserId);
                    switch (object.ehrType) {
                    default:
                        if (typeof object.ehrType === "number") {
                            message.ehrType = object.ehrType;
                            break;
                        }
                        break;
                    case "EPIC":
                    case 0:
                        message.ehrType = 0;
                        break;
                    }
                    if (object.infinitusEmail != null)
                        message.infinitusEmail = String(object.infinitusEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateEhrUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {infinitusai.auth.CreateEhrUserRequest} message CreateEhrUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateEhrUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.ehrUserId = "";
                        object.ehrType = options.enums === String ? "EPIC" : 0;
                        object.infinitusEmail = "";
                    }
                    if (message.ehrUserId != null && message.hasOwnProperty("ehrUserId"))
                        object.ehrUserId = message.ehrUserId;
                    if (message.ehrType != null && message.hasOwnProperty("ehrType"))
                        object.ehrType = options.enums === String ? $root.infinitusai.auth.EhrType[message.ehrType] === undefined ? message.ehrType : $root.infinitusai.auth.EhrType[message.ehrType] : message.ehrType;
                    if (message.infinitusEmail != null && message.hasOwnProperty("infinitusEmail"))
                        object.infinitusEmail = message.infinitusEmail;
                    return object;
                };
    
                /**
                 * Converts this CreateEhrUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateEhrUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateEhrUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateEhrUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateEhrUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateEhrUserRequest";
                };
    
                return CreateEhrUserRequest;
            })();
    
            auth.CreateEhrUserResponse = (function() {
    
                /**
                 * Properties of a CreateEhrUserResponse.
                 * @memberof infinitusai.auth
                 * @interface ICreateEhrUserResponse
                 */
    
                /**
                 * Constructs a new CreateEhrUserResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateEhrUserResponse.
                 * @implements ICreateEhrUserResponse
                 * @constructor
                 * @param {infinitusai.auth.ICreateEhrUserResponse=} [properties] Properties to set
                 */
                function CreateEhrUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CreateEhrUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {infinitusai.auth.ICreateEhrUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateEhrUserResponse} CreateEhrUserResponse instance
                 */
                CreateEhrUserResponse.create = function create(properties) {
                    return new CreateEhrUserResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateEhrUserResponse message. Does not implicitly {@link infinitusai.auth.CreateEhrUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {infinitusai.auth.ICreateEhrUserResponse} message CreateEhrUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateEhrUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateEhrUserResponse message, length delimited. Does not implicitly {@link infinitusai.auth.CreateEhrUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {infinitusai.auth.ICreateEhrUserResponse} message CreateEhrUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateEhrUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateEhrUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateEhrUserResponse} CreateEhrUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateEhrUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateEhrUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateEhrUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateEhrUserResponse} CreateEhrUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateEhrUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateEhrUserResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateEhrUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CreateEhrUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateEhrUserResponse} CreateEhrUserResponse
                 */
                CreateEhrUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateEhrUserResponse)
                        return object;
                    return new $root.infinitusai.auth.CreateEhrUserResponse();
                };
    
                /**
                 * Creates a plain object from a CreateEhrUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {infinitusai.auth.CreateEhrUserResponse} message CreateEhrUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateEhrUserResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CreateEhrUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateEhrUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateEhrUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateEhrUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateEhrUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateEhrUserResponse";
                };
    
                return CreateEhrUserResponse;
            })();
    
            auth.UploadEhrUsersRequest = (function() {
    
                /**
                 * Properties of an UploadEhrUsersRequest.
                 * @memberof infinitusai.auth
                 * @interface IUploadEhrUsersRequest
                 * @property {string|null} [content] UploadEhrUsersRequest content
                 * @property {infinitusai.auth.EhrType|null} [ehrType] UploadEhrUsersRequest ehrType
                 */
    
                /**
                 * Constructs a new UploadEhrUsersRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UploadEhrUsersRequest.
                 * @implements IUploadEhrUsersRequest
                 * @constructor
                 * @param {infinitusai.auth.IUploadEhrUsersRequest=} [properties] Properties to set
                 */
                function UploadEhrUsersRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UploadEhrUsersRequest content.
                 * @member {string} content
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @instance
                 */
                UploadEhrUsersRequest.prototype.content = "";
    
                /**
                 * UploadEhrUsersRequest ehrType.
                 * @member {infinitusai.auth.EhrType} ehrType
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @instance
                 */
                UploadEhrUsersRequest.prototype.ehrType = 0;
    
                /**
                 * Creates a new UploadEhrUsersRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {infinitusai.auth.IUploadEhrUsersRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.UploadEhrUsersRequest} UploadEhrUsersRequest instance
                 */
                UploadEhrUsersRequest.create = function create(properties) {
                    return new UploadEhrUsersRequest(properties);
                };
    
                /**
                 * Encodes the specified UploadEhrUsersRequest message. Does not implicitly {@link infinitusai.auth.UploadEhrUsersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {infinitusai.auth.IUploadEhrUsersRequest} message UploadEhrUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadEhrUsersRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.content);
                    if (message.ehrType != null && Object.hasOwnProperty.call(message, "ehrType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ehrType);
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadEhrUsersRequest message, length delimited. Does not implicitly {@link infinitusai.auth.UploadEhrUsersRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {infinitusai.auth.IUploadEhrUsersRequest} message UploadEhrUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadEhrUsersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadEhrUsersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UploadEhrUsersRequest} UploadEhrUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadEhrUsersRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UploadEhrUsersRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.content = reader.string();
                                break;
                            }
                        case 2: {
                                message.ehrType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadEhrUsersRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UploadEhrUsersRequest} UploadEhrUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadEhrUsersRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadEhrUsersRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadEhrUsersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    if (message.ehrType != null && message.hasOwnProperty("ehrType"))
                        switch (message.ehrType) {
                        default:
                            return "ehrType: enum value expected";
                        case 0:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an UploadEhrUsersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UploadEhrUsersRequest} UploadEhrUsersRequest
                 */
                UploadEhrUsersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UploadEhrUsersRequest)
                        return object;
                    var message = new $root.infinitusai.auth.UploadEhrUsersRequest();
                    if (object.content != null)
                        message.content = String(object.content);
                    switch (object.ehrType) {
                    default:
                        if (typeof object.ehrType === "number") {
                            message.ehrType = object.ehrType;
                            break;
                        }
                        break;
                    case "EPIC":
                    case 0:
                        message.ehrType = 0;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UploadEhrUsersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {infinitusai.auth.UploadEhrUsersRequest} message UploadEhrUsersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadEhrUsersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.content = "";
                        object.ehrType = options.enums === String ? "EPIC" : 0;
                    }
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    if (message.ehrType != null && message.hasOwnProperty("ehrType"))
                        object.ehrType = options.enums === String ? $root.infinitusai.auth.EhrType[message.ehrType] === undefined ? message.ehrType : $root.infinitusai.auth.EhrType[message.ehrType] : message.ehrType;
                    return object;
                };
    
                /**
                 * Converts this UploadEhrUsersRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadEhrUsersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadEhrUsersRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UploadEhrUsersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadEhrUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UploadEhrUsersRequest";
                };
    
                return UploadEhrUsersRequest;
            })();
    
            auth.UploadEhrUsersResponse = (function() {
    
                /**
                 * Properties of an UploadEhrUsersResponse.
                 * @memberof infinitusai.auth
                 * @interface IUploadEhrUsersResponse
                 */
    
                /**
                 * Constructs a new UploadEhrUsersResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UploadEhrUsersResponse.
                 * @implements IUploadEhrUsersResponse
                 * @constructor
                 * @param {infinitusai.auth.IUploadEhrUsersResponse=} [properties] Properties to set
                 */
                function UploadEhrUsersResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UploadEhrUsersResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {infinitusai.auth.IUploadEhrUsersResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.UploadEhrUsersResponse} UploadEhrUsersResponse instance
                 */
                UploadEhrUsersResponse.create = function create(properties) {
                    return new UploadEhrUsersResponse(properties);
                };
    
                /**
                 * Encodes the specified UploadEhrUsersResponse message. Does not implicitly {@link infinitusai.auth.UploadEhrUsersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {infinitusai.auth.IUploadEhrUsersResponse} message UploadEhrUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadEhrUsersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadEhrUsersResponse message, length delimited. Does not implicitly {@link infinitusai.auth.UploadEhrUsersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {infinitusai.auth.IUploadEhrUsersResponse} message UploadEhrUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadEhrUsersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadEhrUsersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UploadEhrUsersResponse} UploadEhrUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadEhrUsersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UploadEhrUsersResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadEhrUsersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UploadEhrUsersResponse} UploadEhrUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadEhrUsersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadEhrUsersResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadEhrUsersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UploadEhrUsersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UploadEhrUsersResponse} UploadEhrUsersResponse
                 */
                UploadEhrUsersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UploadEhrUsersResponse)
                        return object;
                    return new $root.infinitusai.auth.UploadEhrUsersResponse();
                };
    
                /**
                 * Creates a plain object from an UploadEhrUsersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {infinitusai.auth.UploadEhrUsersResponse} message UploadEhrUsersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadEhrUsersResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UploadEhrUsersResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadEhrUsersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadEhrUsersResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UploadEhrUsersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadEhrUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UploadEhrUsersResponse";
                };
    
                return UploadEhrUsersResponse;
            })();
    
            auth.GetEhrUsersRequest = (function() {
    
                /**
                 * Properties of a GetEhrUsersRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetEhrUsersRequest
                 * @property {number|null} [limit] GetEhrUsersRequest limit
                 * @property {number|null} [page] GetEhrUsersRequest page
                 * @property {string|null} [search] GetEhrUsersRequest search
                 * @property {string|null} [orderByCol] GetEhrUsersRequest orderByCol
                 * @property {string|null} [orderByDir] GetEhrUsersRequest orderByDir
                 */
    
                /**
                 * Constructs a new GetEhrUsersRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetEhrUsersRequest.
                 * @implements IGetEhrUsersRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetEhrUsersRequest=} [properties] Properties to set
                 */
                function GetEhrUsersRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetEhrUsersRequest limit.
                 * @member {number} limit
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @instance
                 */
                GetEhrUsersRequest.prototype.limit = 0;
    
                /**
                 * GetEhrUsersRequest page.
                 * @member {number} page
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @instance
                 */
                GetEhrUsersRequest.prototype.page = 0;
    
                /**
                 * GetEhrUsersRequest search.
                 * @member {string} search
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @instance
                 */
                GetEhrUsersRequest.prototype.search = "";
    
                /**
                 * GetEhrUsersRequest orderByCol.
                 * @member {string} orderByCol
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @instance
                 */
                GetEhrUsersRequest.prototype.orderByCol = "";
    
                /**
                 * GetEhrUsersRequest orderByDir.
                 * @member {string} orderByDir
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @instance
                 */
                GetEhrUsersRequest.prototype.orderByDir = "";
    
                /**
                 * Creates a new GetEhrUsersRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetEhrUsersRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetEhrUsersRequest} GetEhrUsersRequest instance
                 */
                GetEhrUsersRequest.create = function create(properties) {
                    return new GetEhrUsersRequest(properties);
                };
    
                /**
                 * Encodes the specified GetEhrUsersRequest message. Does not implicitly {@link infinitusai.auth.GetEhrUsersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetEhrUsersRequest} message GetEhrUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEhrUsersRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
                    if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.search);
                    if (message.orderByCol != null && Object.hasOwnProperty.call(message, "orderByCol"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orderByCol);
                    if (message.orderByDir != null && Object.hasOwnProperty.call(message, "orderByDir"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.orderByDir);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetEhrUsersRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetEhrUsersRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {infinitusai.auth.IGetEhrUsersRequest} message GetEhrUsersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEhrUsersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetEhrUsersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetEhrUsersRequest} GetEhrUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEhrUsersRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetEhrUsersRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.limit = reader.int32();
                                break;
                            }
                        case 2: {
                                message.page = reader.int32();
                                break;
                            }
                        case 3: {
                                message.search = reader.string();
                                break;
                            }
                        case 4: {
                                message.orderByCol = reader.string();
                                break;
                            }
                        case 5: {
                                message.orderByDir = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetEhrUsersRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetEhrUsersRequest} GetEhrUsersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEhrUsersRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetEhrUsersRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetEhrUsersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.search != null && message.hasOwnProperty("search"))
                        if (!$util.isString(message.search))
                            return "search: string expected";
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        if (!$util.isString(message.orderByCol))
                            return "orderByCol: string expected";
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        if (!$util.isString(message.orderByDir))
                            return "orderByDir: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetEhrUsersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetEhrUsersRequest} GetEhrUsersRequest
                 */
                GetEhrUsersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetEhrUsersRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetEhrUsersRequest();
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.search != null)
                        message.search = String(object.search);
                    if (object.orderByCol != null)
                        message.orderByCol = String(object.orderByCol);
                    if (object.orderByDir != null)
                        message.orderByDir = String(object.orderByDir);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetEhrUsersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {infinitusai.auth.GetEhrUsersRequest} message GetEhrUsersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetEhrUsersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.limit = 0;
                        object.page = 0;
                        object.search = "";
                        object.orderByCol = "";
                        object.orderByDir = "";
                    }
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.search != null && message.hasOwnProperty("search"))
                        object.search = message.search;
                    if (message.orderByCol != null && message.hasOwnProperty("orderByCol"))
                        object.orderByCol = message.orderByCol;
                    if (message.orderByDir != null && message.hasOwnProperty("orderByDir"))
                        object.orderByDir = message.orderByDir;
                    return object;
                };
    
                /**
                 * Converts this GetEhrUsersRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetEhrUsersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetEhrUsersRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetEhrUsersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetEhrUsersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetEhrUsersRequest";
                };
    
                return GetEhrUsersRequest;
            })();
    
            auth.GetEhrUsersResponse = (function() {
    
                /**
                 * Properties of a GetEhrUsersResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetEhrUsersResponse
                 * @property {Array.<infinitusai.auth.IEhrUser>|null} [users] GetEhrUsersResponse users
                 * @property {number|null} [totalCount] GetEhrUsersResponse totalCount
                 * @property {number|null} [page] GetEhrUsersResponse page
                 * @property {number|null} [limit] GetEhrUsersResponse limit
                 */
    
                /**
                 * Constructs a new GetEhrUsersResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetEhrUsersResponse.
                 * @implements IGetEhrUsersResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetEhrUsersResponse=} [properties] Properties to set
                 */
                function GetEhrUsersResponse(properties) {
                    this.users = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetEhrUsersResponse users.
                 * @member {Array.<infinitusai.auth.IEhrUser>} users
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @instance
                 */
                GetEhrUsersResponse.prototype.users = $util.emptyArray;
    
                /**
                 * GetEhrUsersResponse totalCount.
                 * @member {number} totalCount
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @instance
                 */
                GetEhrUsersResponse.prototype.totalCount = 0;
    
                /**
                 * GetEhrUsersResponse page.
                 * @member {number} page
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @instance
                 */
                GetEhrUsersResponse.prototype.page = 0;
    
                /**
                 * GetEhrUsersResponse limit.
                 * @member {number} limit
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @instance
                 */
                GetEhrUsersResponse.prototype.limit = 0;
    
                /**
                 * Creates a new GetEhrUsersResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetEhrUsersResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetEhrUsersResponse} GetEhrUsersResponse instance
                 */
                GetEhrUsersResponse.create = function create(properties) {
                    return new GetEhrUsersResponse(properties);
                };
    
                /**
                 * Encodes the specified GetEhrUsersResponse message. Does not implicitly {@link infinitusai.auth.GetEhrUsersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetEhrUsersResponse} message GetEhrUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEhrUsersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.users != null && message.users.length)
                        for (var i = 0; i < message.users.length; ++i)
                            $root.infinitusai.auth.EhrUser.encode(message.users[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalCount);
                    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.page);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetEhrUsersResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetEhrUsersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {infinitusai.auth.IGetEhrUsersResponse} message GetEhrUsersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetEhrUsersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetEhrUsersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetEhrUsersResponse} GetEhrUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEhrUsersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetEhrUsersResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.users && message.users.length))
                                    message.users = [];
                                message.users.push($root.infinitusai.auth.EhrUser.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.totalCount = reader.int32();
                                break;
                            }
                        case 3: {
                                message.page = reader.int32();
                                break;
                            }
                        case 4: {
                                message.limit = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetEhrUsersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetEhrUsersResponse} GetEhrUsersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetEhrUsersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetEhrUsersResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetEhrUsersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.users != null && message.hasOwnProperty("users")) {
                        if (!Array.isArray(message.users))
                            return "users: array expected";
                        for (var i = 0; i < message.users.length; ++i) {
                            var error = $root.infinitusai.auth.EhrUser.verify(message.users[i]);
                            if (error)
                                return "users." + error;
                        }
                    }
                    if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                        if (!$util.isInteger(message.totalCount))
                            return "totalCount: integer expected";
                    if (message.page != null && message.hasOwnProperty("page"))
                        if (!$util.isInteger(message.page))
                            return "page: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    return null;
                };
    
                /**
                 * Creates a GetEhrUsersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetEhrUsersResponse} GetEhrUsersResponse
                 */
                GetEhrUsersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetEhrUsersResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetEhrUsersResponse();
                    if (object.users) {
                        if (!Array.isArray(object.users))
                            throw TypeError(".infinitusai.auth.GetEhrUsersResponse.users: array expected");
                        message.users = [];
                        for (var i = 0; i < object.users.length; ++i) {
                            if (typeof object.users[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetEhrUsersResponse.users: object expected");
                            message.users[i] = $root.infinitusai.auth.EhrUser.fromObject(object.users[i]);
                        }
                    }
                    if (object.totalCount != null)
                        message.totalCount = object.totalCount | 0;
                    if (object.page != null)
                        message.page = object.page | 0;
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetEhrUsersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {infinitusai.auth.GetEhrUsersResponse} message GetEhrUsersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetEhrUsersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.users = [];
                    if (options.defaults) {
                        object.totalCount = 0;
                        object.page = 0;
                        object.limit = 0;
                    }
                    if (message.users && message.users.length) {
                        object.users = [];
                        for (var j = 0; j < message.users.length; ++j)
                            object.users[j] = $root.infinitusai.auth.EhrUser.toObject(message.users[j], options);
                    }
                    if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                        object.totalCount = message.totalCount;
                    if (message.page != null && message.hasOwnProperty("page"))
                        object.page = message.page;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    return object;
                };
    
                /**
                 * Converts this GetEhrUsersResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetEhrUsersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetEhrUsersResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetEhrUsersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetEhrUsersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetEhrUsersResponse";
                };
    
                return GetEhrUsersResponse;
            })();
    
            auth.DeleteEhrUserRequest = (function() {
    
                /**
                 * Properties of a DeleteEhrUserRequest.
                 * @memberof infinitusai.auth
                 * @interface IDeleteEhrUserRequest
                 * @property {string|null} [infinitusUserId] DeleteEhrUserRequest infinitusUserId
                 */
    
                /**
                 * Constructs a new DeleteEhrUserRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeleteEhrUserRequest.
                 * @implements IDeleteEhrUserRequest
                 * @constructor
                 * @param {infinitusai.auth.IDeleteEhrUserRequest=} [properties] Properties to set
                 */
                function DeleteEhrUserRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteEhrUserRequest infinitusUserId.
                 * @member {string} infinitusUserId
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @instance
                 */
                DeleteEhrUserRequest.prototype.infinitusUserId = "";
    
                /**
                 * Creates a new DeleteEhrUserRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteEhrUserRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeleteEhrUserRequest} DeleteEhrUserRequest instance
                 */
                DeleteEhrUserRequest.create = function create(properties) {
                    return new DeleteEhrUserRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteEhrUserRequest message. Does not implicitly {@link infinitusai.auth.DeleteEhrUserRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteEhrUserRequest} message DeleteEhrUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteEhrUserRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.infinitusUserId != null && Object.hasOwnProperty.call(message, "infinitusUserId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.infinitusUserId);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteEhrUserRequest message, length delimited. Does not implicitly {@link infinitusai.auth.DeleteEhrUserRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteEhrUserRequest} message DeleteEhrUserRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteEhrUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteEhrUserRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeleteEhrUserRequest} DeleteEhrUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteEhrUserRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeleteEhrUserRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.infinitusUserId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteEhrUserRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeleteEhrUserRequest} DeleteEhrUserRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteEhrUserRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteEhrUserRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteEhrUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.infinitusUserId != null && message.hasOwnProperty("infinitusUserId"))
                        if (!$util.isString(message.infinitusUserId))
                            return "infinitusUserId: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteEhrUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeleteEhrUserRequest} DeleteEhrUserRequest
                 */
                DeleteEhrUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeleteEhrUserRequest)
                        return object;
                    var message = new $root.infinitusai.auth.DeleteEhrUserRequest();
                    if (object.infinitusUserId != null)
                        message.infinitusUserId = String(object.infinitusUserId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteEhrUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {infinitusai.auth.DeleteEhrUserRequest} message DeleteEhrUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteEhrUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.infinitusUserId = "";
                    if (message.infinitusUserId != null && message.hasOwnProperty("infinitusUserId"))
                        object.infinitusUserId = message.infinitusUserId;
                    return object;
                };
    
                /**
                 * Converts this DeleteEhrUserRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteEhrUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteEhrUserRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeleteEhrUserRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteEhrUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeleteEhrUserRequest";
                };
    
                return DeleteEhrUserRequest;
            })();
    
            auth.DeleteEhrUserResponse = (function() {
    
                /**
                 * Properties of a DeleteEhrUserResponse.
                 * @memberof infinitusai.auth
                 * @interface IDeleteEhrUserResponse
                 */
    
                /**
                 * Constructs a new DeleteEhrUserResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeleteEhrUserResponse.
                 * @implements IDeleteEhrUserResponse
                 * @constructor
                 * @param {infinitusai.auth.IDeleteEhrUserResponse=} [properties] Properties to set
                 */
                function DeleteEhrUserResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteEhrUserResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteEhrUserResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeleteEhrUserResponse} DeleteEhrUserResponse instance
                 */
                DeleteEhrUserResponse.create = function create(properties) {
                    return new DeleteEhrUserResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteEhrUserResponse message. Does not implicitly {@link infinitusai.auth.DeleteEhrUserResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteEhrUserResponse} message DeleteEhrUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteEhrUserResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteEhrUserResponse message, length delimited. Does not implicitly {@link infinitusai.auth.DeleteEhrUserResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteEhrUserResponse} message DeleteEhrUserResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteEhrUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteEhrUserResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeleteEhrUserResponse} DeleteEhrUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteEhrUserResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeleteEhrUserResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteEhrUserResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeleteEhrUserResponse} DeleteEhrUserResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteEhrUserResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteEhrUserResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteEhrUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteEhrUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeleteEhrUserResponse} DeleteEhrUserResponse
                 */
                DeleteEhrUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeleteEhrUserResponse)
                        return object;
                    return new $root.infinitusai.auth.DeleteEhrUserResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteEhrUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {infinitusai.auth.DeleteEhrUserResponse} message DeleteEhrUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteEhrUserResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteEhrUserResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteEhrUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteEhrUserResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeleteEhrUserResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteEhrUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeleteEhrUserResponse";
                };
    
                return DeleteEhrUserResponse;
            })();
    
            auth.SmartConfigurationResponse = (function() {
    
                /**
                 * Properties of a SmartConfigurationResponse.
                 * @memberof infinitusai.auth
                 * @interface ISmartConfigurationResponse
                 * @property {string|null} [authorizationEndpoint] SmartConfigurationResponse authorizationEndpoint
                 * @property {string|null} [tokenEndpoint] SmartConfigurationResponse tokenEndpoint
                 */
    
                /**
                 * Constructs a new SmartConfigurationResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a SmartConfigurationResponse.
                 * @implements ISmartConfigurationResponse
                 * @constructor
                 * @param {infinitusai.auth.ISmartConfigurationResponse=} [properties] Properties to set
                 */
                function SmartConfigurationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SmartConfigurationResponse authorizationEndpoint.
                 * @member {string} authorizationEndpoint
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @instance
                 */
                SmartConfigurationResponse.prototype.authorizationEndpoint = "";
    
                /**
                 * SmartConfigurationResponse tokenEndpoint.
                 * @member {string} tokenEndpoint
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @instance
                 */
                SmartConfigurationResponse.prototype.tokenEndpoint = "";
    
                /**
                 * Creates a new SmartConfigurationResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {infinitusai.auth.ISmartConfigurationResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.SmartConfigurationResponse} SmartConfigurationResponse instance
                 */
                SmartConfigurationResponse.create = function create(properties) {
                    return new SmartConfigurationResponse(properties);
                };
    
                /**
                 * Encodes the specified SmartConfigurationResponse message. Does not implicitly {@link infinitusai.auth.SmartConfigurationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {infinitusai.auth.ISmartConfigurationResponse} message SmartConfigurationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartConfigurationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.authorizationEndpoint != null && Object.hasOwnProperty.call(message, "authorizationEndpoint"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.authorizationEndpoint);
                    if (message.tokenEndpoint != null && Object.hasOwnProperty.call(message, "tokenEndpoint"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.tokenEndpoint);
                    return writer;
                };
    
                /**
                 * Encodes the specified SmartConfigurationResponse message, length delimited. Does not implicitly {@link infinitusai.auth.SmartConfigurationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {infinitusai.auth.ISmartConfigurationResponse} message SmartConfigurationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartConfigurationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SmartConfigurationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.SmartConfigurationResponse} SmartConfigurationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartConfigurationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.SmartConfigurationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.authorizationEndpoint = reader.string();
                                break;
                            }
                        case 2: {
                                message.tokenEndpoint = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SmartConfigurationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.SmartConfigurationResponse} SmartConfigurationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartConfigurationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SmartConfigurationResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SmartConfigurationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.authorizationEndpoint != null && message.hasOwnProperty("authorizationEndpoint"))
                        if (!$util.isString(message.authorizationEndpoint))
                            return "authorizationEndpoint: string expected";
                    if (message.tokenEndpoint != null && message.hasOwnProperty("tokenEndpoint"))
                        if (!$util.isString(message.tokenEndpoint))
                            return "tokenEndpoint: string expected";
                    return null;
                };
    
                /**
                 * Creates a SmartConfigurationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.SmartConfigurationResponse} SmartConfigurationResponse
                 */
                SmartConfigurationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.SmartConfigurationResponse)
                        return object;
                    var message = new $root.infinitusai.auth.SmartConfigurationResponse();
                    if (object.authorizationEndpoint != null)
                        message.authorizationEndpoint = String(object.authorizationEndpoint);
                    if (object.tokenEndpoint != null)
                        message.tokenEndpoint = String(object.tokenEndpoint);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SmartConfigurationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {infinitusai.auth.SmartConfigurationResponse} message SmartConfigurationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SmartConfigurationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.authorizationEndpoint = "";
                        object.tokenEndpoint = "";
                    }
                    if (message.authorizationEndpoint != null && message.hasOwnProperty("authorizationEndpoint"))
                        object.authorizationEndpoint = message.authorizationEndpoint;
                    if (message.tokenEndpoint != null && message.hasOwnProperty("tokenEndpoint"))
                        object.tokenEndpoint = message.tokenEndpoint;
                    return object;
                };
    
                /**
                 * Converts this SmartConfigurationResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SmartConfigurationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SmartConfigurationResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.SmartConfigurationResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SmartConfigurationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.SmartConfigurationResponse";
                };
    
                return SmartConfigurationResponse;
            })();
    
            auth.SmartAccessTokenResponse = (function() {
    
                /**
                 * Properties of a SmartAccessTokenResponse.
                 * @memberof infinitusai.auth
                 * @interface ISmartAccessTokenResponse
                 * @property {string|null} [accessToken] SmartAccessTokenResponse accessToken
                 * @property {number|Long|null} [expiresIn] SmartAccessTokenResponse expiresIn
                 * @property {string|null} [refreshToken] SmartAccessTokenResponse refreshToken
                 * @property {string|null} [scope] SmartAccessTokenResponse scope
                 * @property {string|null} [tokenType] SmartAccessTokenResponse tokenType
                 * @property {string|null} [idToken] SmartAccessTokenResponse idToken
                 * @property {string|null} [patient] SmartAccessTokenResponse patient
                 * @property {string|null} [encounter] SmartAccessTokenResponse encounter
                 * @property {string|null} [user] SmartAccessTokenResponse user
                 * @property {string|null} [patientId] SmartAccessTokenResponse patientId
                 * @property {string|null} [patientDob] SmartAccessTokenResponse patientDob
                 * @property {string|null} [patientFirstName] SmartAccessTokenResponse patientFirstName
                 * @property {string|null} [patientLastName] SmartAccessTokenResponse patientLastName
                 * @property {string|null} [patientZipCode] SmartAccessTokenResponse patientZipCode
                 * @property {string|null} [providerNpi] SmartAccessTokenResponse providerNpi
                 * @property {string|null} [providerId] SmartAccessTokenResponse providerId
                 */
    
                /**
                 * Constructs a new SmartAccessTokenResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a SmartAccessTokenResponse.
                 * @implements ISmartAccessTokenResponse
                 * @constructor
                 * @param {infinitusai.auth.ISmartAccessTokenResponse=} [properties] Properties to set
                 */
                function SmartAccessTokenResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SmartAccessTokenResponse accessToken.
                 * @member {string} accessToken
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.accessToken = "";
    
                /**
                 * SmartAccessTokenResponse expiresIn.
                 * @member {number|Long} expiresIn
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.expiresIn = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * SmartAccessTokenResponse refreshToken.
                 * @member {string} refreshToken
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.refreshToken = "";
    
                /**
                 * SmartAccessTokenResponse scope.
                 * @member {string} scope
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.scope = "";
    
                /**
                 * SmartAccessTokenResponse tokenType.
                 * @member {string} tokenType
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.tokenType = "";
    
                /**
                 * SmartAccessTokenResponse idToken.
                 * @member {string} idToken
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.idToken = "";
    
                /**
                 * SmartAccessTokenResponse patient.
                 * @member {string} patient
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.patient = "";
    
                /**
                 * SmartAccessTokenResponse encounter.
                 * @member {string} encounter
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.encounter = "";
    
                /**
                 * SmartAccessTokenResponse user.
                 * @member {string} user
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.user = "";
    
                /**
                 * SmartAccessTokenResponse patientId.
                 * @member {string} patientId
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.patientId = "";
    
                /**
                 * SmartAccessTokenResponse patientDob.
                 * @member {string} patientDob
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.patientDob = "";
    
                /**
                 * SmartAccessTokenResponse patientFirstName.
                 * @member {string} patientFirstName
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.patientFirstName = "";
    
                /**
                 * SmartAccessTokenResponse patientLastName.
                 * @member {string} patientLastName
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.patientLastName = "";
    
                /**
                 * SmartAccessTokenResponse patientZipCode.
                 * @member {string} patientZipCode
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.patientZipCode = "";
    
                /**
                 * SmartAccessTokenResponse providerNpi.
                 * @member {string} providerNpi
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.providerNpi = "";
    
                /**
                 * SmartAccessTokenResponse providerId.
                 * @member {string} providerId
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 */
                SmartAccessTokenResponse.prototype.providerId = "";
    
                /**
                 * Creates a new SmartAccessTokenResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {infinitusai.auth.ISmartAccessTokenResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.SmartAccessTokenResponse} SmartAccessTokenResponse instance
                 */
                SmartAccessTokenResponse.create = function create(properties) {
                    return new SmartAccessTokenResponse(properties);
                };
    
                /**
                 * Encodes the specified SmartAccessTokenResponse message. Does not implicitly {@link infinitusai.auth.SmartAccessTokenResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {infinitusai.auth.ISmartAccessTokenResponse} message SmartAccessTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartAccessTokenResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.accessToken);
                    if (message.expiresIn != null && Object.hasOwnProperty.call(message, "expiresIn"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.expiresIn);
                    if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.refreshToken);
                    if (message.scope != null && Object.hasOwnProperty.call(message, "scope"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.scope);
                    if (message.tokenType != null && Object.hasOwnProperty.call(message, "tokenType"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.tokenType);
                    if (message.idToken != null && Object.hasOwnProperty.call(message, "idToken"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.idToken);
                    if (message.patient != null && Object.hasOwnProperty.call(message, "patient"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.patient);
                    if (message.encounter != null && Object.hasOwnProperty.call(message, "encounter"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.encounter);
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.user);
                    if (message.patientId != null && Object.hasOwnProperty.call(message, "patientId"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.patientId);
                    if (message.patientDob != null && Object.hasOwnProperty.call(message, "patientDob"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.patientDob);
                    if (message.patientFirstName != null && Object.hasOwnProperty.call(message, "patientFirstName"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.patientFirstName);
                    if (message.patientLastName != null && Object.hasOwnProperty.call(message, "patientLastName"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.patientLastName);
                    if (message.patientZipCode != null && Object.hasOwnProperty.call(message, "patientZipCode"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.patientZipCode);
                    if (message.providerNpi != null && Object.hasOwnProperty.call(message, "providerNpi"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.providerNpi);
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.providerId);
                    return writer;
                };
    
                /**
                 * Encodes the specified SmartAccessTokenResponse message, length delimited. Does not implicitly {@link infinitusai.auth.SmartAccessTokenResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {infinitusai.auth.ISmartAccessTokenResponse} message SmartAccessTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartAccessTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SmartAccessTokenResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.SmartAccessTokenResponse} SmartAccessTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartAccessTokenResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.SmartAccessTokenResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.accessToken = reader.string();
                                break;
                            }
                        case 2: {
                                message.expiresIn = reader.int64();
                                break;
                            }
                        case 3: {
                                message.refreshToken = reader.string();
                                break;
                            }
                        case 4: {
                                message.scope = reader.string();
                                break;
                            }
                        case 5: {
                                message.tokenType = reader.string();
                                break;
                            }
                        case 6: {
                                message.idToken = reader.string();
                                break;
                            }
                        case 7: {
                                message.patient = reader.string();
                                break;
                            }
                        case 8: {
                                message.encounter = reader.string();
                                break;
                            }
                        case 9: {
                                message.user = reader.string();
                                break;
                            }
                        case 10: {
                                message.patientId = reader.string();
                                break;
                            }
                        case 11: {
                                message.patientDob = reader.string();
                                break;
                            }
                        case 12: {
                                message.patientFirstName = reader.string();
                                break;
                            }
                        case 13: {
                                message.patientLastName = reader.string();
                                break;
                            }
                        case 14: {
                                message.patientZipCode = reader.string();
                                break;
                            }
                        case 15: {
                                message.providerNpi = reader.string();
                                break;
                            }
                        case 16: {
                                message.providerId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SmartAccessTokenResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.SmartAccessTokenResponse} SmartAccessTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartAccessTokenResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SmartAccessTokenResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SmartAccessTokenResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                        if (!$util.isString(message.accessToken))
                            return "accessToken: string expected";
                    if (message.expiresIn != null && message.hasOwnProperty("expiresIn"))
                        if (!$util.isInteger(message.expiresIn) && !(message.expiresIn && $util.isInteger(message.expiresIn.low) && $util.isInteger(message.expiresIn.high)))
                            return "expiresIn: integer|Long expected";
                    if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                        if (!$util.isString(message.refreshToken))
                            return "refreshToken: string expected";
                    if (message.scope != null && message.hasOwnProperty("scope"))
                        if (!$util.isString(message.scope))
                            return "scope: string expected";
                    if (message.tokenType != null && message.hasOwnProperty("tokenType"))
                        if (!$util.isString(message.tokenType))
                            return "tokenType: string expected";
                    if (message.idToken != null && message.hasOwnProperty("idToken"))
                        if (!$util.isString(message.idToken))
                            return "idToken: string expected";
                    if (message.patient != null && message.hasOwnProperty("patient"))
                        if (!$util.isString(message.patient))
                            return "patient: string expected";
                    if (message.encounter != null && message.hasOwnProperty("encounter"))
                        if (!$util.isString(message.encounter))
                            return "encounter: string expected";
                    if (message.user != null && message.hasOwnProperty("user"))
                        if (!$util.isString(message.user))
                            return "user: string expected";
                    if (message.patientId != null && message.hasOwnProperty("patientId"))
                        if (!$util.isString(message.patientId))
                            return "patientId: string expected";
                    if (message.patientDob != null && message.hasOwnProperty("patientDob"))
                        if (!$util.isString(message.patientDob))
                            return "patientDob: string expected";
                    if (message.patientFirstName != null && message.hasOwnProperty("patientFirstName"))
                        if (!$util.isString(message.patientFirstName))
                            return "patientFirstName: string expected";
                    if (message.patientLastName != null && message.hasOwnProperty("patientLastName"))
                        if (!$util.isString(message.patientLastName))
                            return "patientLastName: string expected";
                    if (message.patientZipCode != null && message.hasOwnProperty("patientZipCode"))
                        if (!$util.isString(message.patientZipCode))
                            return "patientZipCode: string expected";
                    if (message.providerNpi != null && message.hasOwnProperty("providerNpi"))
                        if (!$util.isString(message.providerNpi))
                            return "providerNpi: string expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    return null;
                };
    
                /**
                 * Creates a SmartAccessTokenResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.SmartAccessTokenResponse} SmartAccessTokenResponse
                 */
                SmartAccessTokenResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.SmartAccessTokenResponse)
                        return object;
                    var message = new $root.infinitusai.auth.SmartAccessTokenResponse();
                    if (object.accessToken != null)
                        message.accessToken = String(object.accessToken);
                    if (object.expiresIn != null)
                        if ($util.Long)
                            (message.expiresIn = $util.Long.fromValue(object.expiresIn)).unsigned = false;
                        else if (typeof object.expiresIn === "string")
                            message.expiresIn = parseInt(object.expiresIn, 10);
                        else if (typeof object.expiresIn === "number")
                            message.expiresIn = object.expiresIn;
                        else if (typeof object.expiresIn === "object")
                            message.expiresIn = new $util.LongBits(object.expiresIn.low >>> 0, object.expiresIn.high >>> 0).toNumber();
                    if (object.refreshToken != null)
                        message.refreshToken = String(object.refreshToken);
                    if (object.scope != null)
                        message.scope = String(object.scope);
                    if (object.tokenType != null)
                        message.tokenType = String(object.tokenType);
                    if (object.idToken != null)
                        message.idToken = String(object.idToken);
                    if (object.patient != null)
                        message.patient = String(object.patient);
                    if (object.encounter != null)
                        message.encounter = String(object.encounter);
                    if (object.user != null)
                        message.user = String(object.user);
                    if (object.patientId != null)
                        message.patientId = String(object.patientId);
                    if (object.patientDob != null)
                        message.patientDob = String(object.patientDob);
                    if (object.patientFirstName != null)
                        message.patientFirstName = String(object.patientFirstName);
                    if (object.patientLastName != null)
                        message.patientLastName = String(object.patientLastName);
                    if (object.patientZipCode != null)
                        message.patientZipCode = String(object.patientZipCode);
                    if (object.providerNpi != null)
                        message.providerNpi = String(object.providerNpi);
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SmartAccessTokenResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {infinitusai.auth.SmartAccessTokenResponse} message SmartAccessTokenResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SmartAccessTokenResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.accessToken = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.expiresIn = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expiresIn = options.longs === String ? "0" : 0;
                        object.refreshToken = "";
                        object.scope = "";
                        object.tokenType = "";
                        object.idToken = "";
                        object.patient = "";
                        object.encounter = "";
                        object.user = "";
                        object.patientId = "";
                        object.patientDob = "";
                        object.patientFirstName = "";
                        object.patientLastName = "";
                        object.patientZipCode = "";
                        object.providerNpi = "";
                        object.providerId = "";
                    }
                    if (message.accessToken != null && message.hasOwnProperty("accessToken"))
                        object.accessToken = message.accessToken;
                    if (message.expiresIn != null && message.hasOwnProperty("expiresIn"))
                        if (typeof message.expiresIn === "number")
                            object.expiresIn = options.longs === String ? String(message.expiresIn) : message.expiresIn;
                        else
                            object.expiresIn = options.longs === String ? $util.Long.prototype.toString.call(message.expiresIn) : options.longs === Number ? new $util.LongBits(message.expiresIn.low >>> 0, message.expiresIn.high >>> 0).toNumber() : message.expiresIn;
                    if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                        object.refreshToken = message.refreshToken;
                    if (message.scope != null && message.hasOwnProperty("scope"))
                        object.scope = message.scope;
                    if (message.tokenType != null && message.hasOwnProperty("tokenType"))
                        object.tokenType = message.tokenType;
                    if (message.idToken != null && message.hasOwnProperty("idToken"))
                        object.idToken = message.idToken;
                    if (message.patient != null && message.hasOwnProperty("patient"))
                        object.patient = message.patient;
                    if (message.encounter != null && message.hasOwnProperty("encounter"))
                        object.encounter = message.encounter;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = message.user;
                    if (message.patientId != null && message.hasOwnProperty("patientId"))
                        object.patientId = message.patientId;
                    if (message.patientDob != null && message.hasOwnProperty("patientDob"))
                        object.patientDob = message.patientDob;
                    if (message.patientFirstName != null && message.hasOwnProperty("patientFirstName"))
                        object.patientFirstName = message.patientFirstName;
                    if (message.patientLastName != null && message.hasOwnProperty("patientLastName"))
                        object.patientLastName = message.patientLastName;
                    if (message.patientZipCode != null && message.hasOwnProperty("patientZipCode"))
                        object.patientZipCode = message.patientZipCode;
                    if (message.providerNpi != null && message.hasOwnProperty("providerNpi"))
                        object.providerNpi = message.providerNpi;
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    return object;
                };
    
                /**
                 * Converts this SmartAccessTokenResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SmartAccessTokenResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SmartAccessTokenResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.SmartAccessTokenResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SmartAccessTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.SmartAccessTokenResponse";
                };
    
                return SmartAccessTokenResponse;
            })();
    
            auth.SmartAccessIDTokenClaims = (function() {
    
                /**
                 * Properties of a SmartAccessIDTokenClaims.
                 * @memberof infinitusai.auth
                 * @interface ISmartAccessIDTokenClaims
                 * @property {string|null} [aud] SmartAccessIDTokenClaims aud
                 * @property {number|Long|null} [exp] SmartAccessIDTokenClaims exp
                 * @property {string|null} [fhirUser] SmartAccessIDTokenClaims fhirUser
                 * @property {number|Long|null} [iat] SmartAccessIDTokenClaims iat
                 * @property {string|null} [iss] SmartAccessIDTokenClaims iss
                 * @property {string|null} [preferredUsername] SmartAccessIDTokenClaims preferredUsername
                 * @property {string|null} [sub] SmartAccessIDTokenClaims sub
                 */
    
                /**
                 * Constructs a new SmartAccessIDTokenClaims.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a SmartAccessIDTokenClaims.
                 * @implements ISmartAccessIDTokenClaims
                 * @constructor
                 * @param {infinitusai.auth.ISmartAccessIDTokenClaims=} [properties] Properties to set
                 */
                function SmartAccessIDTokenClaims(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SmartAccessIDTokenClaims aud.
                 * @member {string} aud
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @instance
                 */
                SmartAccessIDTokenClaims.prototype.aud = "";
    
                /**
                 * SmartAccessIDTokenClaims exp.
                 * @member {number|Long} exp
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @instance
                 */
                SmartAccessIDTokenClaims.prototype.exp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * SmartAccessIDTokenClaims fhirUser.
                 * @member {string} fhirUser
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @instance
                 */
                SmartAccessIDTokenClaims.prototype.fhirUser = "";
    
                /**
                 * SmartAccessIDTokenClaims iat.
                 * @member {number|Long} iat
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @instance
                 */
                SmartAccessIDTokenClaims.prototype.iat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * SmartAccessIDTokenClaims iss.
                 * @member {string} iss
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @instance
                 */
                SmartAccessIDTokenClaims.prototype.iss = "";
    
                /**
                 * SmartAccessIDTokenClaims preferredUsername.
                 * @member {string} preferredUsername
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @instance
                 */
                SmartAccessIDTokenClaims.prototype.preferredUsername = "";
    
                /**
                 * SmartAccessIDTokenClaims sub.
                 * @member {string} sub
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @instance
                 */
                SmartAccessIDTokenClaims.prototype.sub = "";
    
                /**
                 * Creates a new SmartAccessIDTokenClaims instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {infinitusai.auth.ISmartAccessIDTokenClaims=} [properties] Properties to set
                 * @returns {infinitusai.auth.SmartAccessIDTokenClaims} SmartAccessIDTokenClaims instance
                 */
                SmartAccessIDTokenClaims.create = function create(properties) {
                    return new SmartAccessIDTokenClaims(properties);
                };
    
                /**
                 * Encodes the specified SmartAccessIDTokenClaims message. Does not implicitly {@link infinitusai.auth.SmartAccessIDTokenClaims.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {infinitusai.auth.ISmartAccessIDTokenClaims} message SmartAccessIDTokenClaims message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartAccessIDTokenClaims.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.aud != null && Object.hasOwnProperty.call(message, "aud"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.aud);
                    if (message.exp != null && Object.hasOwnProperty.call(message, "exp"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.exp);
                    if (message.fhirUser != null && Object.hasOwnProperty.call(message, "fhirUser"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.fhirUser);
                    if (message.iat != null && Object.hasOwnProperty.call(message, "iat"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.iat);
                    if (message.iss != null && Object.hasOwnProperty.call(message, "iss"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.iss);
                    if (message.preferredUsername != null && Object.hasOwnProperty.call(message, "preferredUsername"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.preferredUsername);
                    if (message.sub != null && Object.hasOwnProperty.call(message, "sub"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.sub);
                    return writer;
                };
    
                /**
                 * Encodes the specified SmartAccessIDTokenClaims message, length delimited. Does not implicitly {@link infinitusai.auth.SmartAccessIDTokenClaims.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {infinitusai.auth.ISmartAccessIDTokenClaims} message SmartAccessIDTokenClaims message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartAccessIDTokenClaims.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SmartAccessIDTokenClaims message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.SmartAccessIDTokenClaims} SmartAccessIDTokenClaims
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartAccessIDTokenClaims.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.SmartAccessIDTokenClaims();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.aud = reader.string();
                                break;
                            }
                        case 2: {
                                message.exp = reader.int64();
                                break;
                            }
                        case 3: {
                                message.fhirUser = reader.string();
                                break;
                            }
                        case 4: {
                                message.iat = reader.int64();
                                break;
                            }
                        case 5: {
                                message.iss = reader.string();
                                break;
                            }
                        case 6: {
                                message.preferredUsername = reader.string();
                                break;
                            }
                        case 7: {
                                message.sub = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SmartAccessIDTokenClaims message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.SmartAccessIDTokenClaims} SmartAccessIDTokenClaims
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartAccessIDTokenClaims.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SmartAccessIDTokenClaims message.
                 * @function verify
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SmartAccessIDTokenClaims.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.aud != null && message.hasOwnProperty("aud"))
                        if (!$util.isString(message.aud))
                            return "aud: string expected";
                    if (message.exp != null && message.hasOwnProperty("exp"))
                        if (!$util.isInteger(message.exp) && !(message.exp && $util.isInteger(message.exp.low) && $util.isInteger(message.exp.high)))
                            return "exp: integer|Long expected";
                    if (message.fhirUser != null && message.hasOwnProperty("fhirUser"))
                        if (!$util.isString(message.fhirUser))
                            return "fhirUser: string expected";
                    if (message.iat != null && message.hasOwnProperty("iat"))
                        if (!$util.isInteger(message.iat) && !(message.iat && $util.isInteger(message.iat.low) && $util.isInteger(message.iat.high)))
                            return "iat: integer|Long expected";
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        if (!$util.isString(message.iss))
                            return "iss: string expected";
                    if (message.preferredUsername != null && message.hasOwnProperty("preferredUsername"))
                        if (!$util.isString(message.preferredUsername))
                            return "preferredUsername: string expected";
                    if (message.sub != null && message.hasOwnProperty("sub"))
                        if (!$util.isString(message.sub))
                            return "sub: string expected";
                    return null;
                };
    
                /**
                 * Creates a SmartAccessIDTokenClaims message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.SmartAccessIDTokenClaims} SmartAccessIDTokenClaims
                 */
                SmartAccessIDTokenClaims.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.SmartAccessIDTokenClaims)
                        return object;
                    var message = new $root.infinitusai.auth.SmartAccessIDTokenClaims();
                    if (object.aud != null)
                        message.aud = String(object.aud);
                    if (object.exp != null)
                        if ($util.Long)
                            (message.exp = $util.Long.fromValue(object.exp)).unsigned = false;
                        else if (typeof object.exp === "string")
                            message.exp = parseInt(object.exp, 10);
                        else if (typeof object.exp === "number")
                            message.exp = object.exp;
                        else if (typeof object.exp === "object")
                            message.exp = new $util.LongBits(object.exp.low >>> 0, object.exp.high >>> 0).toNumber();
                    if (object.fhirUser != null)
                        message.fhirUser = String(object.fhirUser);
                    if (object.iat != null)
                        if ($util.Long)
                            (message.iat = $util.Long.fromValue(object.iat)).unsigned = false;
                        else if (typeof object.iat === "string")
                            message.iat = parseInt(object.iat, 10);
                        else if (typeof object.iat === "number")
                            message.iat = object.iat;
                        else if (typeof object.iat === "object")
                            message.iat = new $util.LongBits(object.iat.low >>> 0, object.iat.high >>> 0).toNumber();
                    if (object.iss != null)
                        message.iss = String(object.iss);
                    if (object.preferredUsername != null)
                        message.preferredUsername = String(object.preferredUsername);
                    if (object.sub != null)
                        message.sub = String(object.sub);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SmartAccessIDTokenClaims message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {infinitusai.auth.SmartAccessIDTokenClaims} message SmartAccessIDTokenClaims
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SmartAccessIDTokenClaims.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.aud = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.exp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.exp = options.longs === String ? "0" : 0;
                        object.fhirUser = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.iat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.iat = options.longs === String ? "0" : 0;
                        object.iss = "";
                        object.preferredUsername = "";
                        object.sub = "";
                    }
                    if (message.aud != null && message.hasOwnProperty("aud"))
                        object.aud = message.aud;
                    if (message.exp != null && message.hasOwnProperty("exp"))
                        if (typeof message.exp === "number")
                            object.exp = options.longs === String ? String(message.exp) : message.exp;
                        else
                            object.exp = options.longs === String ? $util.Long.prototype.toString.call(message.exp) : options.longs === Number ? new $util.LongBits(message.exp.low >>> 0, message.exp.high >>> 0).toNumber() : message.exp;
                    if (message.fhirUser != null && message.hasOwnProperty("fhirUser"))
                        object.fhirUser = message.fhirUser;
                    if (message.iat != null && message.hasOwnProperty("iat"))
                        if (typeof message.iat === "number")
                            object.iat = options.longs === String ? String(message.iat) : message.iat;
                        else
                            object.iat = options.longs === String ? $util.Long.prototype.toString.call(message.iat) : options.longs === Number ? new $util.LongBits(message.iat.low >>> 0, message.iat.high >>> 0).toNumber() : message.iat;
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        object.iss = message.iss;
                    if (message.preferredUsername != null && message.hasOwnProperty("preferredUsername"))
                        object.preferredUsername = message.preferredUsername;
                    if (message.sub != null && message.hasOwnProperty("sub"))
                        object.sub = message.sub;
                    return object;
                };
    
                /**
                 * Converts this SmartAccessIDTokenClaims to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SmartAccessIDTokenClaims.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SmartAccessIDTokenClaims
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.SmartAccessIDTokenClaims
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SmartAccessIDTokenClaims.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.SmartAccessIDTokenClaims";
                };
    
                return SmartAccessIDTokenClaims;
            })();
    
            auth.SmartLaunchResponse = (function() {
    
                /**
                 * Properties of a SmartLaunchResponse.
                 * @memberof infinitusai.auth
                 * @interface ISmartLaunchResponse
                 * @property {string|null} [res] SmartLaunchResponse res
                 * @property {infinitusai.auth.ISmartAccessTokenResponse|null} [smartAccessTokenResponse] SmartLaunchResponse smartAccessTokenResponse
                 * @property {infinitusai.auth.ISmartAccessIDTokenClaims|null} [smartAccessIDTokenClaims] SmartLaunchResponse smartAccessIDTokenClaims
                 */
    
                /**
                 * Constructs a new SmartLaunchResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a SmartLaunchResponse.
                 * @implements ISmartLaunchResponse
                 * @constructor
                 * @param {infinitusai.auth.ISmartLaunchResponse=} [properties] Properties to set
                 */
                function SmartLaunchResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SmartLaunchResponse res.
                 * @member {string} res
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @instance
                 */
                SmartLaunchResponse.prototype.res = "";
    
                /**
                 * SmartLaunchResponse smartAccessTokenResponse.
                 * @member {infinitusai.auth.ISmartAccessTokenResponse|null|undefined} smartAccessTokenResponse
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @instance
                 */
                SmartLaunchResponse.prototype.smartAccessTokenResponse = null;
    
                /**
                 * SmartLaunchResponse smartAccessIDTokenClaims.
                 * @member {infinitusai.auth.ISmartAccessIDTokenClaims|null|undefined} smartAccessIDTokenClaims
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @instance
                 */
                SmartLaunchResponse.prototype.smartAccessIDTokenClaims = null;
    
                /**
                 * Creates a new SmartLaunchResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {infinitusai.auth.ISmartLaunchResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.SmartLaunchResponse} SmartLaunchResponse instance
                 */
                SmartLaunchResponse.create = function create(properties) {
                    return new SmartLaunchResponse(properties);
                };
    
                /**
                 * Encodes the specified SmartLaunchResponse message. Does not implicitly {@link infinitusai.auth.SmartLaunchResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {infinitusai.auth.ISmartLaunchResponse} message SmartLaunchResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartLaunchResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.res != null && Object.hasOwnProperty.call(message, "res"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.res);
                    if (message.smartAccessTokenResponse != null && Object.hasOwnProperty.call(message, "smartAccessTokenResponse"))
                        $root.infinitusai.auth.SmartAccessTokenResponse.encode(message.smartAccessTokenResponse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.smartAccessIDTokenClaims != null && Object.hasOwnProperty.call(message, "smartAccessIDTokenClaims"))
                        $root.infinitusai.auth.SmartAccessIDTokenClaims.encode(message.smartAccessIDTokenClaims, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SmartLaunchResponse message, length delimited. Does not implicitly {@link infinitusai.auth.SmartLaunchResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {infinitusai.auth.ISmartLaunchResponse} message SmartLaunchResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartLaunchResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SmartLaunchResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.SmartLaunchResponse} SmartLaunchResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartLaunchResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.SmartLaunchResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.res = reader.string();
                                break;
                            }
                        case 2: {
                                message.smartAccessTokenResponse = $root.infinitusai.auth.SmartAccessTokenResponse.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.smartAccessIDTokenClaims = $root.infinitusai.auth.SmartAccessIDTokenClaims.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SmartLaunchResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.SmartLaunchResponse} SmartLaunchResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartLaunchResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SmartLaunchResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SmartLaunchResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.res != null && message.hasOwnProperty("res"))
                        if (!$util.isString(message.res))
                            return "res: string expected";
                    if (message.smartAccessTokenResponse != null && message.hasOwnProperty("smartAccessTokenResponse")) {
                        var error = $root.infinitusai.auth.SmartAccessTokenResponse.verify(message.smartAccessTokenResponse);
                        if (error)
                            return "smartAccessTokenResponse." + error;
                    }
                    if (message.smartAccessIDTokenClaims != null && message.hasOwnProperty("smartAccessIDTokenClaims")) {
                        var error = $root.infinitusai.auth.SmartAccessIDTokenClaims.verify(message.smartAccessIDTokenClaims);
                        if (error)
                            return "smartAccessIDTokenClaims." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a SmartLaunchResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.SmartLaunchResponse} SmartLaunchResponse
                 */
                SmartLaunchResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.SmartLaunchResponse)
                        return object;
                    var message = new $root.infinitusai.auth.SmartLaunchResponse();
                    if (object.res != null)
                        message.res = String(object.res);
                    if (object.smartAccessTokenResponse != null) {
                        if (typeof object.smartAccessTokenResponse !== "object")
                            throw TypeError(".infinitusai.auth.SmartLaunchResponse.smartAccessTokenResponse: object expected");
                        message.smartAccessTokenResponse = $root.infinitusai.auth.SmartAccessTokenResponse.fromObject(object.smartAccessTokenResponse);
                    }
                    if (object.smartAccessIDTokenClaims != null) {
                        if (typeof object.smartAccessIDTokenClaims !== "object")
                            throw TypeError(".infinitusai.auth.SmartLaunchResponse.smartAccessIDTokenClaims: object expected");
                        message.smartAccessIDTokenClaims = $root.infinitusai.auth.SmartAccessIDTokenClaims.fromObject(object.smartAccessIDTokenClaims);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SmartLaunchResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {infinitusai.auth.SmartLaunchResponse} message SmartLaunchResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SmartLaunchResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.res = "";
                        object.smartAccessTokenResponse = null;
                        object.smartAccessIDTokenClaims = null;
                    }
                    if (message.res != null && message.hasOwnProperty("res"))
                        object.res = message.res;
                    if (message.smartAccessTokenResponse != null && message.hasOwnProperty("smartAccessTokenResponse"))
                        object.smartAccessTokenResponse = $root.infinitusai.auth.SmartAccessTokenResponse.toObject(message.smartAccessTokenResponse, options);
                    if (message.smartAccessIDTokenClaims != null && message.hasOwnProperty("smartAccessIDTokenClaims"))
                        object.smartAccessIDTokenClaims = $root.infinitusai.auth.SmartAccessIDTokenClaims.toObject(message.smartAccessIDTokenClaims, options);
                    return object;
                };
    
                /**
                 * Converts this SmartLaunchResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SmartLaunchResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SmartLaunchResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.SmartLaunchResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SmartLaunchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.SmartLaunchResponse";
                };
    
                return SmartLaunchResponse;
            })();
    
            auth.SmartAuthConfigDoc = (function() {
    
                /**
                 * Properties of a SmartAuthConfigDoc.
                 * @memberof infinitusai.auth
                 * @interface ISmartAuthConfigDoc
                 * @property {string|null} [iss] SmartAuthConfigDoc iss
                 * @property {string|null} [name] SmartAuthConfigDoc name
                 * @property {string|null} [clientId] SmartAuthConfigDoc clientId
                 * @property {string|null} [fhirEndpoint] SmartAuthConfigDoc fhirEndpoint
                 * @property {boolean|null} [enabled] SmartAuthConfigDoc enabled
                 * @property {Array.<string>|null} [orgUuids] SmartAuthConfigDoc orgUuids
                 * @property {number|Long|null} [createTimeMillis] SmartAuthConfigDoc createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] SmartAuthConfigDoc updateTimeMillis
                 */
    
                /**
                 * Constructs a new SmartAuthConfigDoc.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a SmartAuthConfigDoc.
                 * @implements ISmartAuthConfigDoc
                 * @constructor
                 * @param {infinitusai.auth.ISmartAuthConfigDoc=} [properties] Properties to set
                 */
                function SmartAuthConfigDoc(properties) {
                    this.orgUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SmartAuthConfigDoc iss.
                 * @member {string} iss
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 */
                SmartAuthConfigDoc.prototype.iss = "";
    
                /**
                 * SmartAuthConfigDoc name.
                 * @member {string} name
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 */
                SmartAuthConfigDoc.prototype.name = "";
    
                /**
                 * SmartAuthConfigDoc clientId.
                 * @member {string} clientId
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 */
                SmartAuthConfigDoc.prototype.clientId = "";
    
                /**
                 * SmartAuthConfigDoc fhirEndpoint.
                 * @member {string} fhirEndpoint
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 */
                SmartAuthConfigDoc.prototype.fhirEndpoint = "";
    
                /**
                 * SmartAuthConfigDoc enabled.
                 * @member {boolean} enabled
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 */
                SmartAuthConfigDoc.prototype.enabled = false;
    
                /**
                 * SmartAuthConfigDoc orgUuids.
                 * @member {Array.<string>} orgUuids
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 */
                SmartAuthConfigDoc.prototype.orgUuids = $util.emptyArray;
    
                /**
                 * SmartAuthConfigDoc createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 */
                SmartAuthConfigDoc.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * SmartAuthConfigDoc updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 */
                SmartAuthConfigDoc.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new SmartAuthConfigDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {infinitusai.auth.ISmartAuthConfigDoc=} [properties] Properties to set
                 * @returns {infinitusai.auth.SmartAuthConfigDoc} SmartAuthConfigDoc instance
                 */
                SmartAuthConfigDoc.create = function create(properties) {
                    return new SmartAuthConfigDoc(properties);
                };
    
                /**
                 * Encodes the specified SmartAuthConfigDoc message. Does not implicitly {@link infinitusai.auth.SmartAuthConfigDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {infinitusai.auth.ISmartAuthConfigDoc} message SmartAuthConfigDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartAuthConfigDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.iss != null && Object.hasOwnProperty.call(message, "iss"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.iss);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.clientId);
                    if (message.fhirEndpoint != null && Object.hasOwnProperty.call(message, "fhirEndpoint"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.fhirEndpoint);
                    if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.enabled);
                    if (message.orgUuids != null && message.orgUuids.length)
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.orgUuids[i]);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.updateTimeMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified SmartAuthConfigDoc message, length delimited. Does not implicitly {@link infinitusai.auth.SmartAuthConfigDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {infinitusai.auth.ISmartAuthConfigDoc} message SmartAuthConfigDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartAuthConfigDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SmartAuthConfigDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.SmartAuthConfigDoc} SmartAuthConfigDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartAuthConfigDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.SmartAuthConfigDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.iss = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.clientId = reader.string();
                                break;
                            }
                        case 4: {
                                message.fhirEndpoint = reader.string();
                                break;
                            }
                        case 5: {
                                message.enabled = reader.bool();
                                break;
                            }
                        case 6: {
                                if (!(message.orgUuids && message.orgUuids.length))
                                    message.orgUuids = [];
                                message.orgUuids.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 8: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SmartAuthConfigDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.SmartAuthConfigDoc} SmartAuthConfigDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartAuthConfigDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SmartAuthConfigDoc message.
                 * @function verify
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SmartAuthConfigDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        if (!$util.isString(message.iss))
                            return "iss: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        if (!$util.isString(message.clientId))
                            return "clientId: string expected";
                    if (message.fhirEndpoint != null && message.hasOwnProperty("fhirEndpoint"))
                        if (!$util.isString(message.fhirEndpoint))
                            return "fhirEndpoint: string expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.orgUuids != null && message.hasOwnProperty("orgUuids")) {
                        if (!Array.isArray(message.orgUuids))
                            return "orgUuids: array expected";
                        for (var i = 0; i < message.orgUuids.length; ++i)
                            if (!$util.isString(message.orgUuids[i]))
                                return "orgUuids: string[] expected";
                    }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a SmartAuthConfigDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.SmartAuthConfigDoc} SmartAuthConfigDoc
                 */
                SmartAuthConfigDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.SmartAuthConfigDoc)
                        return object;
                    var message = new $root.infinitusai.auth.SmartAuthConfigDoc();
                    if (object.iss != null)
                        message.iss = String(object.iss);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.clientId != null)
                        message.clientId = String(object.clientId);
                    if (object.fhirEndpoint != null)
                        message.fhirEndpoint = String(object.fhirEndpoint);
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.orgUuids) {
                        if (!Array.isArray(object.orgUuids))
                            throw TypeError(".infinitusai.auth.SmartAuthConfigDoc.orgUuids: array expected");
                        message.orgUuids = [];
                        for (var i = 0; i < object.orgUuids.length; ++i)
                            message.orgUuids[i] = String(object.orgUuids[i]);
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a SmartAuthConfigDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {infinitusai.auth.SmartAuthConfigDoc} message SmartAuthConfigDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SmartAuthConfigDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgUuids = [];
                    if (options.defaults) {
                        object.iss = "";
                        object.name = "";
                        object.clientId = "";
                        object.fhirEndpoint = "";
                        object.enabled = false;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        object.iss = message.iss;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        object.clientId = message.clientId;
                    if (message.fhirEndpoint != null && message.hasOwnProperty("fhirEndpoint"))
                        object.fhirEndpoint = message.fhirEndpoint;
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.orgUuids && message.orgUuids.length) {
                        object.orgUuids = [];
                        for (var j = 0; j < message.orgUuids.length; ++j)
                            object.orgUuids[j] = message.orgUuids[j];
                    }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    return object;
                };
    
                /**
                 * Converts this SmartAuthConfigDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SmartAuthConfigDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SmartAuthConfigDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.SmartAuthConfigDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SmartAuthConfigDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.SmartAuthConfigDoc";
                };
    
                return SmartAuthConfigDoc;
            })();
    
            auth.DeleteSmartAuthConfigRequest = (function() {
    
                /**
                 * Properties of a DeleteSmartAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @interface IDeleteSmartAuthConfigRequest
                 * @property {string|null} [iss] DeleteSmartAuthConfigRequest iss
                 */
    
                /**
                 * Constructs a new DeleteSmartAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeleteSmartAuthConfigRequest.
                 * @implements IDeleteSmartAuthConfigRequest
                 * @constructor
                 * @param {infinitusai.auth.IDeleteSmartAuthConfigRequest=} [properties] Properties to set
                 */
                function DeleteSmartAuthConfigRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteSmartAuthConfigRequest iss.
                 * @member {string} iss
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @instance
                 */
                DeleteSmartAuthConfigRequest.prototype.iss = "";
    
                /**
                 * Creates a new DeleteSmartAuthConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteSmartAuthConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeleteSmartAuthConfigRequest} DeleteSmartAuthConfigRequest instance
                 */
                DeleteSmartAuthConfigRequest.create = function create(properties) {
                    return new DeleteSmartAuthConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteSmartAuthConfigRequest message. Does not implicitly {@link infinitusai.auth.DeleteSmartAuthConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteSmartAuthConfigRequest} message DeleteSmartAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSmartAuthConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.iss != null && Object.hasOwnProperty.call(message, "iss"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.iss);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteSmartAuthConfigRequest message, length delimited. Does not implicitly {@link infinitusai.auth.DeleteSmartAuthConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IDeleteSmartAuthConfigRequest} message DeleteSmartAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSmartAuthConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteSmartAuthConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeleteSmartAuthConfigRequest} DeleteSmartAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSmartAuthConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeleteSmartAuthConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.iss = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteSmartAuthConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeleteSmartAuthConfigRequest} DeleteSmartAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSmartAuthConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteSmartAuthConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSmartAuthConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        if (!$util.isString(message.iss))
                            return "iss: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteSmartAuthConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeleteSmartAuthConfigRequest} DeleteSmartAuthConfigRequest
                 */
                DeleteSmartAuthConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeleteSmartAuthConfigRequest)
                        return object;
                    var message = new $root.infinitusai.auth.DeleteSmartAuthConfigRequest();
                    if (object.iss != null)
                        message.iss = String(object.iss);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteSmartAuthConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.DeleteSmartAuthConfigRequest} message DeleteSmartAuthConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSmartAuthConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.iss = "";
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        object.iss = message.iss;
                    return object;
                };
    
                /**
                 * Converts this DeleteSmartAuthConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSmartAuthConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteSmartAuthConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSmartAuthConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeleteSmartAuthConfigRequest";
                };
    
                return DeleteSmartAuthConfigRequest;
            })();
    
            auth.DeleteSmartAuthConfigResponse = (function() {
    
                /**
                 * Properties of a DeleteSmartAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @interface IDeleteSmartAuthConfigResponse
                 */
    
                /**
                 * Constructs a new DeleteSmartAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a DeleteSmartAuthConfigResponse.
                 * @implements IDeleteSmartAuthConfigResponse
                 * @constructor
                 * @param {infinitusai.auth.IDeleteSmartAuthConfigResponse=} [properties] Properties to set
                 */
                function DeleteSmartAuthConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteSmartAuthConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteSmartAuthConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.DeleteSmartAuthConfigResponse} DeleteSmartAuthConfigResponse instance
                 */
                DeleteSmartAuthConfigResponse.create = function create(properties) {
                    return new DeleteSmartAuthConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteSmartAuthConfigResponse message. Does not implicitly {@link infinitusai.auth.DeleteSmartAuthConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteSmartAuthConfigResponse} message DeleteSmartAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSmartAuthConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteSmartAuthConfigResponse message, length delimited. Does not implicitly {@link infinitusai.auth.DeleteSmartAuthConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IDeleteSmartAuthConfigResponse} message DeleteSmartAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteSmartAuthConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteSmartAuthConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.DeleteSmartAuthConfigResponse} DeleteSmartAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSmartAuthConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.DeleteSmartAuthConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteSmartAuthConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.DeleteSmartAuthConfigResponse} DeleteSmartAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteSmartAuthConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteSmartAuthConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSmartAuthConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteSmartAuthConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.DeleteSmartAuthConfigResponse} DeleteSmartAuthConfigResponse
                 */
                DeleteSmartAuthConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.DeleteSmartAuthConfigResponse)
                        return object;
                    return new $root.infinitusai.auth.DeleteSmartAuthConfigResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteSmartAuthConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.DeleteSmartAuthConfigResponse} message DeleteSmartAuthConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSmartAuthConfigResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteSmartAuthConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSmartAuthConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteSmartAuthConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.DeleteSmartAuthConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteSmartAuthConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.DeleteSmartAuthConfigResponse";
                };
    
                return DeleteSmartAuthConfigResponse;
            })();
    
            auth.CreateSmartAuthConfigRequest = (function() {
    
                /**
                 * Properties of a CreateSmartAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @interface ICreateSmartAuthConfigRequest
                 * @property {infinitusai.auth.ISmartAuthConfigDoc|null} [smartAuthConfigDoc] CreateSmartAuthConfigRequest smartAuthConfigDoc
                 */
    
                /**
                 * Constructs a new CreateSmartAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateSmartAuthConfigRequest.
                 * @implements ICreateSmartAuthConfigRequest
                 * @constructor
                 * @param {infinitusai.auth.ICreateSmartAuthConfigRequest=} [properties] Properties to set
                 */
                function CreateSmartAuthConfigRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateSmartAuthConfigRequest smartAuthConfigDoc.
                 * @member {infinitusai.auth.ISmartAuthConfigDoc|null|undefined} smartAuthConfigDoc
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @instance
                 */
                CreateSmartAuthConfigRequest.prototype.smartAuthConfigDoc = null;
    
                /**
                 * Creates a new CreateSmartAuthConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.ICreateSmartAuthConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateSmartAuthConfigRequest} CreateSmartAuthConfigRequest instance
                 */
                CreateSmartAuthConfigRequest.create = function create(properties) {
                    return new CreateSmartAuthConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateSmartAuthConfigRequest message. Does not implicitly {@link infinitusai.auth.CreateSmartAuthConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.ICreateSmartAuthConfigRequest} message CreateSmartAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateSmartAuthConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.smartAuthConfigDoc != null && Object.hasOwnProperty.call(message, "smartAuthConfigDoc"))
                        $root.infinitusai.auth.SmartAuthConfigDoc.encode(message.smartAuthConfigDoc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateSmartAuthConfigRequest message, length delimited. Does not implicitly {@link infinitusai.auth.CreateSmartAuthConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.ICreateSmartAuthConfigRequest} message CreateSmartAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateSmartAuthConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateSmartAuthConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateSmartAuthConfigRequest} CreateSmartAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateSmartAuthConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateSmartAuthConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.smartAuthConfigDoc = $root.infinitusai.auth.SmartAuthConfigDoc.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateSmartAuthConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateSmartAuthConfigRequest} CreateSmartAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateSmartAuthConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateSmartAuthConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSmartAuthConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.smartAuthConfigDoc != null && message.hasOwnProperty("smartAuthConfigDoc")) {
                        var error = $root.infinitusai.auth.SmartAuthConfigDoc.verify(message.smartAuthConfigDoc);
                        if (error)
                            return "smartAuthConfigDoc." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateSmartAuthConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateSmartAuthConfigRequest} CreateSmartAuthConfigRequest
                 */
                CreateSmartAuthConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateSmartAuthConfigRequest)
                        return object;
                    var message = new $root.infinitusai.auth.CreateSmartAuthConfigRequest();
                    if (object.smartAuthConfigDoc != null) {
                        if (typeof object.smartAuthConfigDoc !== "object")
                            throw TypeError(".infinitusai.auth.CreateSmartAuthConfigRequest.smartAuthConfigDoc: object expected");
                        message.smartAuthConfigDoc = $root.infinitusai.auth.SmartAuthConfigDoc.fromObject(object.smartAuthConfigDoc);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateSmartAuthConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.CreateSmartAuthConfigRequest} message CreateSmartAuthConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSmartAuthConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.smartAuthConfigDoc = null;
                    if (message.smartAuthConfigDoc != null && message.hasOwnProperty("smartAuthConfigDoc"))
                        object.smartAuthConfigDoc = $root.infinitusai.auth.SmartAuthConfigDoc.toObject(message.smartAuthConfigDoc, options);
                    return object;
                };
    
                /**
                 * Converts this CreateSmartAuthConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSmartAuthConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateSmartAuthConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateSmartAuthConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateSmartAuthConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateSmartAuthConfigRequest";
                };
    
                return CreateSmartAuthConfigRequest;
            })();
    
            auth.CreateSmartAuthConfigResponse = (function() {
    
                /**
                 * Properties of a CreateSmartAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @interface ICreateSmartAuthConfigResponse
                 */
    
                /**
                 * Constructs a new CreateSmartAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a CreateSmartAuthConfigResponse.
                 * @implements ICreateSmartAuthConfigResponse
                 * @constructor
                 * @param {infinitusai.auth.ICreateSmartAuthConfigResponse=} [properties] Properties to set
                 */
                function CreateSmartAuthConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CreateSmartAuthConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.ICreateSmartAuthConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.CreateSmartAuthConfigResponse} CreateSmartAuthConfigResponse instance
                 */
                CreateSmartAuthConfigResponse.create = function create(properties) {
                    return new CreateSmartAuthConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateSmartAuthConfigResponse message. Does not implicitly {@link infinitusai.auth.CreateSmartAuthConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.ICreateSmartAuthConfigResponse} message CreateSmartAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateSmartAuthConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateSmartAuthConfigResponse message, length delimited. Does not implicitly {@link infinitusai.auth.CreateSmartAuthConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.ICreateSmartAuthConfigResponse} message CreateSmartAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateSmartAuthConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateSmartAuthConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.CreateSmartAuthConfigResponse} CreateSmartAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateSmartAuthConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.CreateSmartAuthConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateSmartAuthConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.CreateSmartAuthConfigResponse} CreateSmartAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateSmartAuthConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateSmartAuthConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSmartAuthConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CreateSmartAuthConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.CreateSmartAuthConfigResponse} CreateSmartAuthConfigResponse
                 */
                CreateSmartAuthConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.CreateSmartAuthConfigResponse)
                        return object;
                    return new $root.infinitusai.auth.CreateSmartAuthConfigResponse();
                };
    
                /**
                 * Creates a plain object from a CreateSmartAuthConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.CreateSmartAuthConfigResponse} message CreateSmartAuthConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSmartAuthConfigResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CreateSmartAuthConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSmartAuthConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateSmartAuthConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.CreateSmartAuthConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateSmartAuthConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.CreateSmartAuthConfigResponse";
                };
    
                return CreateSmartAuthConfigResponse;
            })();
    
            auth.UpdateSmartAuthConfigRequest = (function() {
    
                /**
                 * Properties of an UpdateSmartAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @interface IUpdateSmartAuthConfigRequest
                 * @property {infinitusai.auth.ISmartAuthConfigDoc|null} [smartAuthConfigDoc] UpdateSmartAuthConfigRequest smartAuthConfigDoc
                 */
    
                /**
                 * Constructs a new UpdateSmartAuthConfigRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UpdateSmartAuthConfigRequest.
                 * @implements IUpdateSmartAuthConfigRequest
                 * @constructor
                 * @param {infinitusai.auth.IUpdateSmartAuthConfigRequest=} [properties] Properties to set
                 */
                function UpdateSmartAuthConfigRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateSmartAuthConfigRequest smartAuthConfigDoc.
                 * @member {infinitusai.auth.ISmartAuthConfigDoc|null|undefined} smartAuthConfigDoc
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @instance
                 */
                UpdateSmartAuthConfigRequest.prototype.smartAuthConfigDoc = null;
    
                /**
                 * Creates a new UpdateSmartAuthConfigRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateSmartAuthConfigRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.UpdateSmartAuthConfigRequest} UpdateSmartAuthConfigRequest instance
                 */
                UpdateSmartAuthConfigRequest.create = function create(properties) {
                    return new UpdateSmartAuthConfigRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateSmartAuthConfigRequest message. Does not implicitly {@link infinitusai.auth.UpdateSmartAuthConfigRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateSmartAuthConfigRequest} message UpdateSmartAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateSmartAuthConfigRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.smartAuthConfigDoc != null && Object.hasOwnProperty.call(message, "smartAuthConfigDoc"))
                        $root.infinitusai.auth.SmartAuthConfigDoc.encode(message.smartAuthConfigDoc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateSmartAuthConfigRequest message, length delimited. Does not implicitly {@link infinitusai.auth.UpdateSmartAuthConfigRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.IUpdateSmartAuthConfigRequest} message UpdateSmartAuthConfigRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateSmartAuthConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateSmartAuthConfigRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UpdateSmartAuthConfigRequest} UpdateSmartAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateSmartAuthConfigRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UpdateSmartAuthConfigRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.smartAuthConfigDoc = $root.infinitusai.auth.SmartAuthConfigDoc.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateSmartAuthConfigRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UpdateSmartAuthConfigRequest} UpdateSmartAuthConfigRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateSmartAuthConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateSmartAuthConfigRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSmartAuthConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.smartAuthConfigDoc != null && message.hasOwnProperty("smartAuthConfigDoc")) {
                        var error = $root.infinitusai.auth.SmartAuthConfigDoc.verify(message.smartAuthConfigDoc);
                        if (error)
                            return "smartAuthConfigDoc." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateSmartAuthConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UpdateSmartAuthConfigRequest} UpdateSmartAuthConfigRequest
                 */
                UpdateSmartAuthConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UpdateSmartAuthConfigRequest)
                        return object;
                    var message = new $root.infinitusai.auth.UpdateSmartAuthConfigRequest();
                    if (object.smartAuthConfigDoc != null) {
                        if (typeof object.smartAuthConfigDoc !== "object")
                            throw TypeError(".infinitusai.auth.UpdateSmartAuthConfigRequest.smartAuthConfigDoc: object expected");
                        message.smartAuthConfigDoc = $root.infinitusai.auth.SmartAuthConfigDoc.fromObject(object.smartAuthConfigDoc);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateSmartAuthConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {infinitusai.auth.UpdateSmartAuthConfigRequest} message UpdateSmartAuthConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSmartAuthConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.smartAuthConfigDoc = null;
                    if (message.smartAuthConfigDoc != null && message.hasOwnProperty("smartAuthConfigDoc"))
                        object.smartAuthConfigDoc = $root.infinitusai.auth.SmartAuthConfigDoc.toObject(message.smartAuthConfigDoc, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateSmartAuthConfigRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSmartAuthConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateSmartAuthConfigRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateSmartAuthConfigRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UpdateSmartAuthConfigRequest";
                };
    
                return UpdateSmartAuthConfigRequest;
            })();
    
            auth.UpdateSmartAuthConfigResponse = (function() {
    
                /**
                 * Properties of an UpdateSmartAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @interface IUpdateSmartAuthConfigResponse
                 */
    
                /**
                 * Constructs a new UpdateSmartAuthConfigResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents an UpdateSmartAuthConfigResponse.
                 * @implements IUpdateSmartAuthConfigResponse
                 * @constructor
                 * @param {infinitusai.auth.IUpdateSmartAuthConfigResponse=} [properties] Properties to set
                 */
                function UpdateSmartAuthConfigResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpdateSmartAuthConfigResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateSmartAuthConfigResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.UpdateSmartAuthConfigResponse} UpdateSmartAuthConfigResponse instance
                 */
                UpdateSmartAuthConfigResponse.create = function create(properties) {
                    return new UpdateSmartAuthConfigResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateSmartAuthConfigResponse message. Does not implicitly {@link infinitusai.auth.UpdateSmartAuthConfigResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateSmartAuthConfigResponse} message UpdateSmartAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateSmartAuthConfigResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateSmartAuthConfigResponse message, length delimited. Does not implicitly {@link infinitusai.auth.UpdateSmartAuthConfigResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.IUpdateSmartAuthConfigResponse} message UpdateSmartAuthConfigResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateSmartAuthConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateSmartAuthConfigResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.UpdateSmartAuthConfigResponse} UpdateSmartAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateSmartAuthConfigResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.UpdateSmartAuthConfigResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateSmartAuthConfigResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.UpdateSmartAuthConfigResponse} UpdateSmartAuthConfigResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateSmartAuthConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateSmartAuthConfigResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSmartAuthConfigResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateSmartAuthConfigResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.UpdateSmartAuthConfigResponse} UpdateSmartAuthConfigResponse
                 */
                UpdateSmartAuthConfigResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.UpdateSmartAuthConfigResponse)
                        return object;
                    return new $root.infinitusai.auth.UpdateSmartAuthConfigResponse();
                };
    
                /**
                 * Creates a plain object from an UpdateSmartAuthConfigResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {infinitusai.auth.UpdateSmartAuthConfigResponse} message UpdateSmartAuthConfigResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSmartAuthConfigResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpdateSmartAuthConfigResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSmartAuthConfigResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateSmartAuthConfigResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.UpdateSmartAuthConfigResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateSmartAuthConfigResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.UpdateSmartAuthConfigResponse";
                };
    
                return UpdateSmartAuthConfigResponse;
            })();
    
            auth.GetSmartAuthConfigsRequest = (function() {
    
                /**
                 * Properties of a GetSmartAuthConfigsRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetSmartAuthConfigsRequest
                 */
    
                /**
                 * Constructs a new GetSmartAuthConfigsRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetSmartAuthConfigsRequest.
                 * @implements IGetSmartAuthConfigsRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetSmartAuthConfigsRequest=} [properties] Properties to set
                 */
                function GetSmartAuthConfigsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetSmartAuthConfigsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthConfigsRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetSmartAuthConfigsRequest} GetSmartAuthConfigsRequest instance
                 */
                GetSmartAuthConfigsRequest.create = function create(properties) {
                    return new GetSmartAuthConfigsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetSmartAuthConfigsRequest message. Does not implicitly {@link infinitusai.auth.GetSmartAuthConfigsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthConfigsRequest} message GetSmartAuthConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSmartAuthConfigsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSmartAuthConfigsRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetSmartAuthConfigsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthConfigsRequest} message GetSmartAuthConfigsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSmartAuthConfigsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSmartAuthConfigsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetSmartAuthConfigsRequest} GetSmartAuthConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSmartAuthConfigsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetSmartAuthConfigsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSmartAuthConfigsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetSmartAuthConfigsRequest} GetSmartAuthConfigsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSmartAuthConfigsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSmartAuthConfigsRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSmartAuthConfigsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetSmartAuthConfigsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetSmartAuthConfigsRequest} GetSmartAuthConfigsRequest
                 */
                GetSmartAuthConfigsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetSmartAuthConfigsRequest)
                        return object;
                    return new $root.infinitusai.auth.GetSmartAuthConfigsRequest();
                };
    
                /**
                 * Creates a plain object from a GetSmartAuthConfigsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {infinitusai.auth.GetSmartAuthConfigsRequest} message GetSmartAuthConfigsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSmartAuthConfigsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetSmartAuthConfigsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSmartAuthConfigsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSmartAuthConfigsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetSmartAuthConfigsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSmartAuthConfigsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetSmartAuthConfigsRequest";
                };
    
                return GetSmartAuthConfigsRequest;
            })();
    
            auth.GetSmartAuthConfigsResponse = (function() {
    
                /**
                 * Properties of a GetSmartAuthConfigsResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetSmartAuthConfigsResponse
                 * @property {Array.<infinitusai.auth.ISmartAuthConfigDoc>|null} [smartAuthConfigDocs] GetSmartAuthConfigsResponse smartAuthConfigDocs
                 */
    
                /**
                 * Constructs a new GetSmartAuthConfigsResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetSmartAuthConfigsResponse.
                 * @implements IGetSmartAuthConfigsResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetSmartAuthConfigsResponse=} [properties] Properties to set
                 */
                function GetSmartAuthConfigsResponse(properties) {
                    this.smartAuthConfigDocs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSmartAuthConfigsResponse smartAuthConfigDocs.
                 * @member {Array.<infinitusai.auth.ISmartAuthConfigDoc>} smartAuthConfigDocs
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @instance
                 */
                GetSmartAuthConfigsResponse.prototype.smartAuthConfigDocs = $util.emptyArray;
    
                /**
                 * Creates a new GetSmartAuthConfigsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthConfigsResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetSmartAuthConfigsResponse} GetSmartAuthConfigsResponse instance
                 */
                GetSmartAuthConfigsResponse.create = function create(properties) {
                    return new GetSmartAuthConfigsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetSmartAuthConfigsResponse message. Does not implicitly {@link infinitusai.auth.GetSmartAuthConfigsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthConfigsResponse} message GetSmartAuthConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSmartAuthConfigsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.smartAuthConfigDocs != null && message.smartAuthConfigDocs.length)
                        for (var i = 0; i < message.smartAuthConfigDocs.length; ++i)
                            $root.infinitusai.auth.SmartAuthConfigDoc.encode(message.smartAuthConfigDocs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSmartAuthConfigsResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetSmartAuthConfigsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthConfigsResponse} message GetSmartAuthConfigsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSmartAuthConfigsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSmartAuthConfigsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetSmartAuthConfigsResponse} GetSmartAuthConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSmartAuthConfigsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetSmartAuthConfigsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.smartAuthConfigDocs && message.smartAuthConfigDocs.length))
                                    message.smartAuthConfigDocs = [];
                                message.smartAuthConfigDocs.push($root.infinitusai.auth.SmartAuthConfigDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSmartAuthConfigsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetSmartAuthConfigsResponse} GetSmartAuthConfigsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSmartAuthConfigsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSmartAuthConfigsResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSmartAuthConfigsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.smartAuthConfigDocs != null && message.hasOwnProperty("smartAuthConfigDocs")) {
                        if (!Array.isArray(message.smartAuthConfigDocs))
                            return "smartAuthConfigDocs: array expected";
                        for (var i = 0; i < message.smartAuthConfigDocs.length; ++i) {
                            var error = $root.infinitusai.auth.SmartAuthConfigDoc.verify(message.smartAuthConfigDocs[i]);
                            if (error)
                                return "smartAuthConfigDocs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetSmartAuthConfigsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetSmartAuthConfigsResponse} GetSmartAuthConfigsResponse
                 */
                GetSmartAuthConfigsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetSmartAuthConfigsResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetSmartAuthConfigsResponse();
                    if (object.smartAuthConfigDocs) {
                        if (!Array.isArray(object.smartAuthConfigDocs))
                            throw TypeError(".infinitusai.auth.GetSmartAuthConfigsResponse.smartAuthConfigDocs: array expected");
                        message.smartAuthConfigDocs = [];
                        for (var i = 0; i < object.smartAuthConfigDocs.length; ++i) {
                            if (typeof object.smartAuthConfigDocs[i] !== "object")
                                throw TypeError(".infinitusai.auth.GetSmartAuthConfigsResponse.smartAuthConfigDocs: object expected");
                            message.smartAuthConfigDocs[i] = $root.infinitusai.auth.SmartAuthConfigDoc.fromObject(object.smartAuthConfigDocs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSmartAuthConfigsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {infinitusai.auth.GetSmartAuthConfigsResponse} message GetSmartAuthConfigsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSmartAuthConfigsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.smartAuthConfigDocs = [];
                    if (message.smartAuthConfigDocs && message.smartAuthConfigDocs.length) {
                        object.smartAuthConfigDocs = [];
                        for (var j = 0; j < message.smartAuthConfigDocs.length; ++j)
                            object.smartAuthConfigDocs[j] = $root.infinitusai.auth.SmartAuthConfigDoc.toObject(message.smartAuthConfigDocs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetSmartAuthConfigsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSmartAuthConfigsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSmartAuthConfigsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetSmartAuthConfigsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSmartAuthConfigsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetSmartAuthConfigsResponse";
                };
    
                return GetSmartAuthConfigsResponse;
            })();
    
            /**
             * SmartAuthStateStatus enum.
             * @name infinitusai.auth.SmartAuthStateStatus
             * @enum {number}
             * @property {number} SMART_AUTH_STATE_STATUS_PENDING=0 SMART_AUTH_STATE_STATUS_PENDING value
             * @property {number} SMART_AUTH_STATE_STATUS_COMPLETED=1 SMART_AUTH_STATE_STATUS_COMPLETED value
             * @property {number} SMART_AUTH_STATE_STATUS_FAILED=2 SMART_AUTH_STATE_STATUS_FAILED value
             */
            auth.SmartAuthStateStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SMART_AUTH_STATE_STATUS_PENDING"] = 0;
                values[valuesById[1] = "SMART_AUTH_STATE_STATUS_COMPLETED"] = 1;
                values[valuesById[2] = "SMART_AUTH_STATE_STATUS_FAILED"] = 2;
                return values;
            })();
    
            auth.SmartAuthStateDoc = (function() {
    
                /**
                 * Properties of a SmartAuthStateDoc.
                 * @memberof infinitusai.auth
                 * @interface ISmartAuthStateDoc
                 * @property {string|null} [iss] SmartAuthStateDoc iss
                 * @property {string|null} [launch] SmartAuthStateDoc launch
                 * @property {string|null} [authEndpoint] SmartAuthStateDoc authEndpoint
                 * @property {string|null} [tokenEndpoint] SmartAuthStateDoc tokenEndpoint
                 * @property {string|null} [userUuid] SmartAuthStateDoc userUuid
                 * @property {string|null} [orgUuid] SmartAuthStateDoc orgUuid
                 * @property {string|null} [taskUuid] SmartAuthStateDoc taskUuid
                 * @property {infinitusai.auth.SmartAuthStateStatus|null} [status] SmartAuthStateDoc status
                 * @property {number|Long|null} [createTimeMillis] SmartAuthStateDoc createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] SmartAuthStateDoc updateTimeMillis
                 * @property {string|null} [userEmail] SmartAuthStateDoc userEmail
                 * @property {infinitusai.auth.SmartAuthAppName|null} [appName] SmartAuthStateDoc appName
                 * @property {infinitusai.auth.ISmartAccessTokenResponse|null} [smartAccessTokenResponse] SmartAuthStateDoc smartAccessTokenResponse
                 * @property {google.protobuf.ITimestamp|null} [expireAt] SmartAuthStateDoc expireAt
                 * @property {string|null} [fhirEndpoint] SmartAuthStateDoc fhirEndpoint
                 */
    
                /**
                 * Constructs a new SmartAuthStateDoc.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a SmartAuthStateDoc.
                 * @implements ISmartAuthStateDoc
                 * @constructor
                 * @param {infinitusai.auth.ISmartAuthStateDoc=} [properties] Properties to set
                 */
                function SmartAuthStateDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SmartAuthStateDoc iss.
                 * @member {string} iss
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.iss = "";
    
                /**
                 * SmartAuthStateDoc launch.
                 * @member {string} launch
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.launch = "";
    
                /**
                 * SmartAuthStateDoc authEndpoint.
                 * @member {string} authEndpoint
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.authEndpoint = "";
    
                /**
                 * SmartAuthStateDoc tokenEndpoint.
                 * @member {string} tokenEndpoint
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.tokenEndpoint = "";
    
                /**
                 * SmartAuthStateDoc userUuid.
                 * @member {string} userUuid
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.userUuid = "";
    
                /**
                 * SmartAuthStateDoc orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.orgUuid = "";
    
                /**
                 * SmartAuthStateDoc taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.taskUuid = "";
    
                /**
                 * SmartAuthStateDoc status.
                 * @member {infinitusai.auth.SmartAuthStateStatus} status
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.status = 0;
    
                /**
                 * SmartAuthStateDoc createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * SmartAuthStateDoc updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * SmartAuthStateDoc userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.userEmail = "";
    
                /**
                 * SmartAuthStateDoc appName.
                 * @member {infinitusai.auth.SmartAuthAppName} appName
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.appName = 0;
    
                /**
                 * SmartAuthStateDoc smartAccessTokenResponse.
                 * @member {infinitusai.auth.ISmartAccessTokenResponse|null|undefined} smartAccessTokenResponse
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.smartAccessTokenResponse = null;
    
                /**
                 * SmartAuthStateDoc expireAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} expireAt
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.expireAt = null;
    
                /**
                 * SmartAuthStateDoc fhirEndpoint.
                 * @member {string} fhirEndpoint
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 */
                SmartAuthStateDoc.prototype.fhirEndpoint = "";
    
                /**
                 * Creates a new SmartAuthStateDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {infinitusai.auth.ISmartAuthStateDoc=} [properties] Properties to set
                 * @returns {infinitusai.auth.SmartAuthStateDoc} SmartAuthStateDoc instance
                 */
                SmartAuthStateDoc.create = function create(properties) {
                    return new SmartAuthStateDoc(properties);
                };
    
                /**
                 * Encodes the specified SmartAuthStateDoc message. Does not implicitly {@link infinitusai.auth.SmartAuthStateDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {infinitusai.auth.ISmartAuthStateDoc} message SmartAuthStateDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartAuthStateDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.iss != null && Object.hasOwnProperty.call(message, "iss"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.iss);
                    if (message.launch != null && Object.hasOwnProperty.call(message, "launch"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.launch);
                    if (message.authEndpoint != null && Object.hasOwnProperty.call(message, "authEndpoint"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.authEndpoint);
                    if (message.tokenEndpoint != null && Object.hasOwnProperty.call(message, "tokenEndpoint"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.tokenEndpoint);
                    if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.userUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.orgUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.taskUuid);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.status);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int64(message.updateTimeMillis);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.userEmail);
                    if (message.appName != null && Object.hasOwnProperty.call(message, "appName"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.appName);
                    if (message.smartAccessTokenResponse != null && Object.hasOwnProperty.call(message, "smartAccessTokenResponse"))
                        $root.infinitusai.auth.SmartAccessTokenResponse.encode(message.smartAccessTokenResponse, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.expireAt != null && Object.hasOwnProperty.call(message, "expireAt"))
                        $root.google.protobuf.Timestamp.encode(message.expireAt, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.fhirEndpoint != null && Object.hasOwnProperty.call(message, "fhirEndpoint"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.fhirEndpoint);
                    return writer;
                };
    
                /**
                 * Encodes the specified SmartAuthStateDoc message, length delimited. Does not implicitly {@link infinitusai.auth.SmartAuthStateDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {infinitusai.auth.ISmartAuthStateDoc} message SmartAuthStateDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SmartAuthStateDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SmartAuthStateDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.SmartAuthStateDoc} SmartAuthStateDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartAuthStateDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.SmartAuthStateDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.iss = reader.string();
                                break;
                            }
                        case 2: {
                                message.launch = reader.string();
                                break;
                            }
                        case 3: {
                                message.authEndpoint = reader.string();
                                break;
                            }
                        case 4: {
                                message.tokenEndpoint = reader.string();
                                break;
                            }
                        case 5: {
                                message.userUuid = reader.string();
                                break;
                            }
                        case 6: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        case 7: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 8: {
                                message.status = reader.int32();
                                break;
                            }
                        case 9: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 10: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        case 11: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 12: {
                                message.appName = reader.int32();
                                break;
                            }
                        case 13: {
                                message.smartAccessTokenResponse = $root.infinitusai.auth.SmartAccessTokenResponse.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.expireAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.fhirEndpoint = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SmartAuthStateDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.SmartAuthStateDoc} SmartAuthStateDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SmartAuthStateDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SmartAuthStateDoc message.
                 * @function verify
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SmartAuthStateDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        if (!$util.isString(message.iss))
                            return "iss: string expected";
                    if (message.launch != null && message.hasOwnProperty("launch"))
                        if (!$util.isString(message.launch))
                            return "launch: string expected";
                    if (message.authEndpoint != null && message.hasOwnProperty("authEndpoint"))
                        if (!$util.isString(message.authEndpoint))
                            return "authEndpoint: string expected";
                    if (message.tokenEndpoint != null && message.hasOwnProperty("tokenEndpoint"))
                        if (!$util.isString(message.tokenEndpoint))
                            return "tokenEndpoint: string expected";
                    if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                        if (!$util.isString(message.userUuid))
                            return "userUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.appName != null && message.hasOwnProperty("appName"))
                        switch (message.appName) {
                        default:
                            return "appName: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.smartAccessTokenResponse != null && message.hasOwnProperty("smartAccessTokenResponse")) {
                        var error = $root.infinitusai.auth.SmartAccessTokenResponse.verify(message.smartAccessTokenResponse);
                        if (error)
                            return "smartAccessTokenResponse." + error;
                    }
                    if (message.expireAt != null && message.hasOwnProperty("expireAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.expireAt);
                        if (error)
                            return "expireAt." + error;
                    }
                    if (message.fhirEndpoint != null && message.hasOwnProperty("fhirEndpoint"))
                        if (!$util.isString(message.fhirEndpoint))
                            return "fhirEndpoint: string expected";
                    return null;
                };
    
                /**
                 * Creates a SmartAuthStateDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.SmartAuthStateDoc} SmartAuthStateDoc
                 */
                SmartAuthStateDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.SmartAuthStateDoc)
                        return object;
                    var message = new $root.infinitusai.auth.SmartAuthStateDoc();
                    if (object.iss != null)
                        message.iss = String(object.iss);
                    if (object.launch != null)
                        message.launch = String(object.launch);
                    if (object.authEndpoint != null)
                        message.authEndpoint = String(object.authEndpoint);
                    if (object.tokenEndpoint != null)
                        message.tokenEndpoint = String(object.tokenEndpoint);
                    if (object.userUuid != null)
                        message.userUuid = String(object.userUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "SMART_AUTH_STATE_STATUS_PENDING":
                    case 0:
                        message.status = 0;
                        break;
                    case "SMART_AUTH_STATE_STATUS_COMPLETED":
                    case 1:
                        message.status = 1;
                        break;
                    case "SMART_AUTH_STATE_STATUS_FAILED":
                    case 2:
                        message.status = 2;
                        break;
                    }
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    switch (object.appName) {
                    default:
                        if (typeof object.appName === "number") {
                            message.appName = object.appName;
                            break;
                        }
                        break;
                    case "SMART_AUTH_APP_NAME_UNKNOWN":
                    case 0:
                        message.appName = 0;
                        break;
                    case "SMART_AUTH_APP_NAME_FASTTRACK":
                    case 1:
                        message.appName = 1;
                        break;
                    case "SMART_AUTH_APP_NAME_CUSTOMER_PORTAL":
                    case 2:
                        message.appName = 2;
                        break;
                    }
                    if (object.smartAccessTokenResponse != null) {
                        if (typeof object.smartAccessTokenResponse !== "object")
                            throw TypeError(".infinitusai.auth.SmartAuthStateDoc.smartAccessTokenResponse: object expected");
                        message.smartAccessTokenResponse = $root.infinitusai.auth.SmartAccessTokenResponse.fromObject(object.smartAccessTokenResponse);
                    }
                    if (object.expireAt != null) {
                        if (typeof object.expireAt !== "object")
                            throw TypeError(".infinitusai.auth.SmartAuthStateDoc.expireAt: object expected");
                        message.expireAt = $root.google.protobuf.Timestamp.fromObject(object.expireAt);
                    }
                    if (object.fhirEndpoint != null)
                        message.fhirEndpoint = String(object.fhirEndpoint);
                    return message;
                };
    
                /**
                 * Creates a plain object from a SmartAuthStateDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {infinitusai.auth.SmartAuthStateDoc} message SmartAuthStateDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SmartAuthStateDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.iss = "";
                        object.launch = "";
                        object.authEndpoint = "";
                        object.tokenEndpoint = "";
                        object.userUuid = "";
                        object.orgUuid = "";
                        object.taskUuid = "";
                        object.status = options.enums === String ? "SMART_AUTH_STATE_STATUS_PENDING" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                        object.userEmail = "";
                        object.appName = options.enums === String ? "SMART_AUTH_APP_NAME_UNKNOWN" : 0;
                        object.smartAccessTokenResponse = null;
                        object.expireAt = null;
                        object.fhirEndpoint = "";
                    }
                    if (message.iss != null && message.hasOwnProperty("iss"))
                        object.iss = message.iss;
                    if (message.launch != null && message.hasOwnProperty("launch"))
                        object.launch = message.launch;
                    if (message.authEndpoint != null && message.hasOwnProperty("authEndpoint"))
                        object.authEndpoint = message.authEndpoint;
                    if (message.tokenEndpoint != null && message.hasOwnProperty("tokenEndpoint"))
                        object.tokenEndpoint = message.tokenEndpoint;
                    if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                        object.userUuid = message.userUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusai.auth.SmartAuthStateStatus[message.status] === undefined ? message.status : $root.infinitusai.auth.SmartAuthStateStatus[message.status] : message.status;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.appName != null && message.hasOwnProperty("appName"))
                        object.appName = options.enums === String ? $root.infinitusai.auth.SmartAuthAppName[message.appName] === undefined ? message.appName : $root.infinitusai.auth.SmartAuthAppName[message.appName] : message.appName;
                    if (message.smartAccessTokenResponse != null && message.hasOwnProperty("smartAccessTokenResponse"))
                        object.smartAccessTokenResponse = $root.infinitusai.auth.SmartAccessTokenResponse.toObject(message.smartAccessTokenResponse, options);
                    if (message.expireAt != null && message.hasOwnProperty("expireAt"))
                        object.expireAt = $root.google.protobuf.Timestamp.toObject(message.expireAt, options);
                    if (message.fhirEndpoint != null && message.hasOwnProperty("fhirEndpoint"))
                        object.fhirEndpoint = message.fhirEndpoint;
                    return object;
                };
    
                /**
                 * Converts this SmartAuthStateDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SmartAuthStateDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SmartAuthStateDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.SmartAuthStateDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SmartAuthStateDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.SmartAuthStateDoc";
                };
    
                return SmartAuthStateDoc;
            })();
    
            /**
             * SmartAuthAppName enum.
             * @name infinitusai.auth.SmartAuthAppName
             * @enum {number}
             * @property {number} SMART_AUTH_APP_NAME_UNKNOWN=0 SMART_AUTH_APP_NAME_UNKNOWN value
             * @property {number} SMART_AUTH_APP_NAME_FASTTRACK=1 SMART_AUTH_APP_NAME_FASTTRACK value
             * @property {number} SMART_AUTH_APP_NAME_CUSTOMER_PORTAL=2 SMART_AUTH_APP_NAME_CUSTOMER_PORTAL value
             */
            auth.SmartAuthAppName = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SMART_AUTH_APP_NAME_UNKNOWN"] = 0;
                values[valuesById[1] = "SMART_AUTH_APP_NAME_FASTTRACK"] = 1;
                values[valuesById[2] = "SMART_AUTH_APP_NAME_CUSTOMER_PORTAL"] = 2;
                return values;
            })();
    
            auth.GetSmartAuthStateDocRequest = (function() {
    
                /**
                 * Properties of a GetSmartAuthStateDocRequest.
                 * @memberof infinitusai.auth
                 * @interface IGetSmartAuthStateDocRequest
                 * @property {string|null} [state] GetSmartAuthStateDocRequest state
                 */
    
                /**
                 * Constructs a new GetSmartAuthStateDocRequest.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetSmartAuthStateDocRequest.
                 * @implements IGetSmartAuthStateDocRequest
                 * @constructor
                 * @param {infinitusai.auth.IGetSmartAuthStateDocRequest=} [properties] Properties to set
                 */
                function GetSmartAuthStateDocRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSmartAuthStateDocRequest state.
                 * @member {string} state
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @instance
                 */
                GetSmartAuthStateDocRequest.prototype.state = "";
    
                /**
                 * Creates a new GetSmartAuthStateDocRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthStateDocRequest=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetSmartAuthStateDocRequest} GetSmartAuthStateDocRequest instance
                 */
                GetSmartAuthStateDocRequest.create = function create(properties) {
                    return new GetSmartAuthStateDocRequest(properties);
                };
    
                /**
                 * Encodes the specified GetSmartAuthStateDocRequest message. Does not implicitly {@link infinitusai.auth.GetSmartAuthStateDocRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthStateDocRequest} message GetSmartAuthStateDocRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSmartAuthStateDocRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.state);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSmartAuthStateDocRequest message, length delimited. Does not implicitly {@link infinitusai.auth.GetSmartAuthStateDocRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthStateDocRequest} message GetSmartAuthStateDocRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSmartAuthStateDocRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSmartAuthStateDocRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetSmartAuthStateDocRequest} GetSmartAuthStateDocRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSmartAuthStateDocRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetSmartAuthStateDocRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.state = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSmartAuthStateDocRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetSmartAuthStateDocRequest} GetSmartAuthStateDocRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSmartAuthStateDocRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSmartAuthStateDocRequest message.
                 * @function verify
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSmartAuthStateDocRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        if (!$util.isString(message.state))
                            return "state: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetSmartAuthStateDocRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetSmartAuthStateDocRequest} GetSmartAuthStateDocRequest
                 */
                GetSmartAuthStateDocRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetSmartAuthStateDocRequest)
                        return object;
                    var message = new $root.infinitusai.auth.GetSmartAuthStateDocRequest();
                    if (object.state != null)
                        message.state = String(object.state);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSmartAuthStateDocRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {infinitusai.auth.GetSmartAuthStateDocRequest} message GetSmartAuthStateDocRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSmartAuthStateDocRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.state = "";
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = message.state;
                    return object;
                };
    
                /**
                 * Converts this GetSmartAuthStateDocRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSmartAuthStateDocRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSmartAuthStateDocRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetSmartAuthStateDocRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSmartAuthStateDocRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetSmartAuthStateDocRequest";
                };
    
                return GetSmartAuthStateDocRequest;
            })();
    
            auth.GetSmartAuthStateDocResponse = (function() {
    
                /**
                 * Properties of a GetSmartAuthStateDocResponse.
                 * @memberof infinitusai.auth
                 * @interface IGetSmartAuthStateDocResponse
                 * @property {infinitusai.auth.ISmartAuthStateDoc|null} [smartAuthStateDoc] GetSmartAuthStateDocResponse smartAuthStateDoc
                 */
    
                /**
                 * Constructs a new GetSmartAuthStateDocResponse.
                 * @memberof infinitusai.auth
                 * @classdesc Represents a GetSmartAuthStateDocResponse.
                 * @implements IGetSmartAuthStateDocResponse
                 * @constructor
                 * @param {infinitusai.auth.IGetSmartAuthStateDocResponse=} [properties] Properties to set
                 */
                function GetSmartAuthStateDocResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetSmartAuthStateDocResponse smartAuthStateDoc.
                 * @member {infinitusai.auth.ISmartAuthStateDoc|null|undefined} smartAuthStateDoc
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @instance
                 */
                GetSmartAuthStateDocResponse.prototype.smartAuthStateDoc = null;
    
                /**
                 * Creates a new GetSmartAuthStateDocResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthStateDocResponse=} [properties] Properties to set
                 * @returns {infinitusai.auth.GetSmartAuthStateDocResponse} GetSmartAuthStateDocResponse instance
                 */
                GetSmartAuthStateDocResponse.create = function create(properties) {
                    return new GetSmartAuthStateDocResponse(properties);
                };
    
                /**
                 * Encodes the specified GetSmartAuthStateDocResponse message. Does not implicitly {@link infinitusai.auth.GetSmartAuthStateDocResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthStateDocResponse} message GetSmartAuthStateDocResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSmartAuthStateDocResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.smartAuthStateDoc != null && Object.hasOwnProperty.call(message, "smartAuthStateDoc"))
                        $root.infinitusai.auth.SmartAuthStateDoc.encode(message.smartAuthStateDoc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetSmartAuthStateDocResponse message, length delimited. Does not implicitly {@link infinitusai.auth.GetSmartAuthStateDocResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {infinitusai.auth.IGetSmartAuthStateDocResponse} message GetSmartAuthStateDocResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSmartAuthStateDocResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetSmartAuthStateDocResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.auth.GetSmartAuthStateDocResponse} GetSmartAuthStateDocResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSmartAuthStateDocResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.auth.GetSmartAuthStateDocResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.smartAuthStateDoc = $root.infinitusai.auth.SmartAuthStateDoc.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetSmartAuthStateDocResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.auth.GetSmartAuthStateDocResponse} GetSmartAuthStateDocResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSmartAuthStateDocResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetSmartAuthStateDocResponse message.
                 * @function verify
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSmartAuthStateDocResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.smartAuthStateDoc != null && message.hasOwnProperty("smartAuthStateDoc")) {
                        var error = $root.infinitusai.auth.SmartAuthStateDoc.verify(message.smartAuthStateDoc);
                        if (error)
                            return "smartAuthStateDoc." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetSmartAuthStateDocResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.auth.GetSmartAuthStateDocResponse} GetSmartAuthStateDocResponse
                 */
                GetSmartAuthStateDocResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.auth.GetSmartAuthStateDocResponse)
                        return object;
                    var message = new $root.infinitusai.auth.GetSmartAuthStateDocResponse();
                    if (object.smartAuthStateDoc != null) {
                        if (typeof object.smartAuthStateDoc !== "object")
                            throw TypeError(".infinitusai.auth.GetSmartAuthStateDocResponse.smartAuthStateDoc: object expected");
                        message.smartAuthStateDoc = $root.infinitusai.auth.SmartAuthStateDoc.fromObject(object.smartAuthStateDoc);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetSmartAuthStateDocResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {infinitusai.auth.GetSmartAuthStateDocResponse} message GetSmartAuthStateDocResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSmartAuthStateDocResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.smartAuthStateDoc = null;
                    if (message.smartAuthStateDoc != null && message.hasOwnProperty("smartAuthStateDoc"))
                        object.smartAuthStateDoc = $root.infinitusai.auth.SmartAuthStateDoc.toObject(message.smartAuthStateDoc, options);
                    return object;
                };
    
                /**
                 * Converts this GetSmartAuthStateDocResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSmartAuthStateDocResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetSmartAuthStateDocResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.auth.GetSmartAuthStateDocResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetSmartAuthStateDocResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.auth.GetSmartAuthStateDocResponse";
                };
    
                return GetSmartAuthStateDocResponse;
            })();
    
            return auth;
        })();
    
        infinitusai.orgs = (function() {
    
            /**
             * Namespace orgs.
             * @memberof infinitusai
             * @namespace
             */
            var orgs = {};
    
            orgs.Org = (function() {
    
                /**
                 * Properties of an Org.
                 * @memberof infinitusai.orgs
                 * @interface IOrg
                 * @property {string|null} [uuid] Org uuid
                 * @property {string|null} [name] Org name
                 * @property {string|null} [displayName] Org displayName
                 * @property {string|null} [imageUrl] Org imageUrl
                 * @property {string|null} [supportEmail] Org supportEmail
                 * @property {Array.<string>|null} [emailDomains] Org emailDomains
                 * @property {Array.<string>|null} [whitelistedIpAddresses] Org whitelistedIpAddresses
                 * @property {string|null} [defaultProgramId] Org defaultProgramId
                 * @property {number|null} [daysTasksAvailableForAccess] Org daysTasksAvailableForAccess
                 * @property {Object.<string,infinitusai.orgs.IProgramDoc>|null} [programs] Org programs
                 * @property {Array.<infinitusai.orgs.CustomerPortalFeature>|null} [enabledCustomerPortalFeatures] Org enabledCustomerPortalFeatures
                 * @property {google.protobuf.IInt32Value|null} [availableSeats] Org availableSeats
                 * @property {boolean|null} [isLive] Org isLive
                 * @property {boolean|null} [enableTesting] Org enableTesting
                 * @property {infinitusai.orgs.IOrgCustomization|null} [custom] Org custom
                 * @property {boolean|null} [demo] Org demo
                 * @property {Array.<string>|null} [roleNames] Org roleNames
                 * @property {boolean|null} [isInternal] Org isInternal
                 * @property {boolean|null} [disableFetchRecording] Org disableFetchRecording
                 * @property {string|null} [timeSavingsStartDate] Org timeSavingsStartDate
                 * @property {Array.<infinitusai.orgs.OperatorPortalFeature>|null} [enabledOperatorPortalFeatures] Org enabledOperatorPortalFeatures
                 */
    
                /**
                 * Constructs a new Org.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents an Org.
                 * @implements IOrg
                 * @constructor
                 * @param {infinitusai.orgs.IOrg=} [properties] Properties to set
                 */
                function Org(properties) {
                    this.emailDomains = [];
                    this.whitelistedIpAddresses = [];
                    this.programs = {};
                    this.enabledCustomerPortalFeatures = [];
                    this.roleNames = [];
                    this.enabledOperatorPortalFeatures = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Org uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.uuid = "";
    
                /**
                 * Org name.
                 * @member {string} name
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.name = "";
    
                /**
                 * Org displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.displayName = "";
    
                /**
                 * Org imageUrl.
                 * @member {string} imageUrl
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.imageUrl = "";
    
                /**
                 * Org supportEmail.
                 * @member {string} supportEmail
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.supportEmail = "";
    
                /**
                 * Org emailDomains.
                 * @member {Array.<string>} emailDomains
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.emailDomains = $util.emptyArray;
    
                /**
                 * Org whitelistedIpAddresses.
                 * @member {Array.<string>} whitelistedIpAddresses
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.whitelistedIpAddresses = $util.emptyArray;
    
                /**
                 * Org defaultProgramId.
                 * @member {string} defaultProgramId
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.defaultProgramId = "";
    
                /**
                 * Org daysTasksAvailableForAccess.
                 * @member {number} daysTasksAvailableForAccess
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.daysTasksAvailableForAccess = 0;
    
                /**
                 * Org programs.
                 * @member {Object.<string,infinitusai.orgs.IProgramDoc>} programs
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.programs = $util.emptyObject;
    
                /**
                 * Org enabledCustomerPortalFeatures.
                 * @member {Array.<infinitusai.orgs.CustomerPortalFeature>} enabledCustomerPortalFeatures
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.enabledCustomerPortalFeatures = $util.emptyArray;
    
                /**
                 * Org availableSeats.
                 * @member {google.protobuf.IInt32Value|null|undefined} availableSeats
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.availableSeats = null;
    
                /**
                 * Org isLive.
                 * @member {boolean} isLive
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.isLive = false;
    
                /**
                 * Org enableTesting.
                 * @member {boolean} enableTesting
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.enableTesting = false;
    
                /**
                 * Org custom.
                 * @member {infinitusai.orgs.IOrgCustomization|null|undefined} custom
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.custom = null;
    
                /**
                 * Org demo.
                 * @member {boolean} demo
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.demo = false;
    
                /**
                 * Org roleNames.
                 * @member {Array.<string>} roleNames
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.roleNames = $util.emptyArray;
    
                /**
                 * Org isInternal.
                 * @member {boolean} isInternal
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.isInternal = false;
    
                /**
                 * Org disableFetchRecording.
                 * @member {boolean} disableFetchRecording
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.disableFetchRecording = false;
    
                /**
                 * Org timeSavingsStartDate.
                 * @member {string} timeSavingsStartDate
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.timeSavingsStartDate = "";
    
                /**
                 * Org enabledOperatorPortalFeatures.
                 * @member {Array.<infinitusai.orgs.OperatorPortalFeature>} enabledOperatorPortalFeatures
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 */
                Org.prototype.enabledOperatorPortalFeatures = $util.emptyArray;
    
                /**
                 * Creates a new Org instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {infinitusai.orgs.IOrg=} [properties] Properties to set
                 * @returns {infinitusai.orgs.Org} Org instance
                 */
                Org.create = function create(properties) {
                    return new Org(properties);
                };
    
                /**
                 * Encodes the specified Org message. Does not implicitly {@link infinitusai.orgs.Org.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {infinitusai.orgs.IOrg} message Org message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Org.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
                    if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.imageUrl);
                    if (message.supportEmail != null && Object.hasOwnProperty.call(message, "supportEmail"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.supportEmail);
                    if (message.emailDomains != null && message.emailDomains.length)
                        for (var i = 0; i < message.emailDomains.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.emailDomains[i]);
                    if (message.whitelistedIpAddresses != null && message.whitelistedIpAddresses.length)
                        for (var i = 0; i < message.whitelistedIpAddresses.length; ++i)
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.whitelistedIpAddresses[i]);
                    if (message.defaultProgramId != null && Object.hasOwnProperty.call(message, "defaultProgramId"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.defaultProgramId);
                    if (message.daysTasksAvailableForAccess != null && Object.hasOwnProperty.call(message, "daysTasksAvailableForAccess"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.daysTasksAvailableForAccess);
                    if (message.programs != null && Object.hasOwnProperty.call(message, "programs"))
                        for (var keys = Object.keys(message.programs), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.infinitusai.orgs.ProgramDoc.encode(message.programs[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    if (message.enabledCustomerPortalFeatures != null && message.enabledCustomerPortalFeatures.length) {
                        writer.uint32(/* id 11, wireType 2 =*/90).fork();
                        for (var i = 0; i < message.enabledCustomerPortalFeatures.length; ++i)
                            writer.int32(message.enabledCustomerPortalFeatures[i]);
                        writer.ldelim();
                    }
                    if (message.availableSeats != null && Object.hasOwnProperty.call(message, "availableSeats"))
                        $root.google.protobuf.Int32Value.encode(message.availableSeats, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.isLive != null && Object.hasOwnProperty.call(message, "isLive"))
                        writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isLive);
                    if (message.enableTesting != null && Object.hasOwnProperty.call(message, "enableTesting"))
                        writer.uint32(/* id 14, wireType 0 =*/112).bool(message.enableTesting);
                    if (message.custom != null && Object.hasOwnProperty.call(message, "custom"))
                        $root.infinitusai.orgs.OrgCustomization.encode(message.custom, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.demo != null && Object.hasOwnProperty.call(message, "demo"))
                        writer.uint32(/* id 16, wireType 0 =*/128).bool(message.demo);
                    if (message.roleNames != null && message.roleNames.length)
                        for (var i = 0; i < message.roleNames.length; ++i)
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.roleNames[i]);
                    if (message.isInternal != null && Object.hasOwnProperty.call(message, "isInternal"))
                        writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isInternal);
                    if (message.disableFetchRecording != null && Object.hasOwnProperty.call(message, "disableFetchRecording"))
                        writer.uint32(/* id 19, wireType 0 =*/152).bool(message.disableFetchRecording);
                    if (message.timeSavingsStartDate != null && Object.hasOwnProperty.call(message, "timeSavingsStartDate"))
                        writer.uint32(/* id 20, wireType 2 =*/162).string(message.timeSavingsStartDate);
                    if (message.enabledOperatorPortalFeatures != null && message.enabledOperatorPortalFeatures.length) {
                        writer.uint32(/* id 21, wireType 2 =*/170).fork();
                        for (var i = 0; i < message.enabledOperatorPortalFeatures.length; ++i)
                            writer.int32(message.enabledOperatorPortalFeatures[i]);
                        writer.ldelim();
                    }
                    return writer;
                };
    
                /**
                 * Encodes the specified Org message, length delimited. Does not implicitly {@link infinitusai.orgs.Org.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {infinitusai.orgs.IOrg} message Org message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Org.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Org message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.Org} Org
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Org.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.Org(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 4: {
                                message.imageUrl = reader.string();
                                break;
                            }
                        case 5: {
                                message.supportEmail = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.emailDomains && message.emailDomains.length))
                                    message.emailDomains = [];
                                message.emailDomains.push(reader.string());
                                break;
                            }
                        case 7: {
                                if (!(message.whitelistedIpAddresses && message.whitelistedIpAddresses.length))
                                    message.whitelistedIpAddresses = [];
                                message.whitelistedIpAddresses.push(reader.string());
                                break;
                            }
                        case 8: {
                                message.defaultProgramId = reader.string();
                                break;
                            }
                        case 9: {
                                message.daysTasksAvailableForAccess = reader.int32();
                                break;
                            }
                        case 10: {
                                if (message.programs === $util.emptyObject)
                                    message.programs = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.infinitusai.orgs.ProgramDoc.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.programs[key] = value;
                                break;
                            }
                        case 11: {
                                if (!(message.enabledCustomerPortalFeatures && message.enabledCustomerPortalFeatures.length))
                                    message.enabledCustomerPortalFeatures = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.enabledCustomerPortalFeatures.push(reader.int32());
                                } else
                                    message.enabledCustomerPortalFeatures.push(reader.int32());
                                break;
                            }
                        case 12: {
                                message.availableSeats = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.isLive = reader.bool();
                                break;
                            }
                        case 14: {
                                message.enableTesting = reader.bool();
                                break;
                            }
                        case 15: {
                                message.custom = $root.infinitusai.orgs.OrgCustomization.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.demo = reader.bool();
                                break;
                            }
                        case 17: {
                                if (!(message.roleNames && message.roleNames.length))
                                    message.roleNames = [];
                                message.roleNames.push(reader.string());
                                break;
                            }
                        case 18: {
                                message.isInternal = reader.bool();
                                break;
                            }
                        case 19: {
                                message.disableFetchRecording = reader.bool();
                                break;
                            }
                        case 20: {
                                message.timeSavingsStartDate = reader.string();
                                break;
                            }
                        case 21: {
                                if (!(message.enabledOperatorPortalFeatures && message.enabledOperatorPortalFeatures.length))
                                    message.enabledOperatorPortalFeatures = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.enabledOperatorPortalFeatures.push(reader.int32());
                                } else
                                    message.enabledOperatorPortalFeatures.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Org message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.Org} Org
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Org.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Org message.
                 * @function verify
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Org.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        if (!$util.isString(message.imageUrl))
                            return "imageUrl: string expected";
                    if (message.supportEmail != null && message.hasOwnProperty("supportEmail"))
                        if (!$util.isString(message.supportEmail))
                            return "supportEmail: string expected";
                    if (message.emailDomains != null && message.hasOwnProperty("emailDomains")) {
                        if (!Array.isArray(message.emailDomains))
                            return "emailDomains: array expected";
                        for (var i = 0; i < message.emailDomains.length; ++i)
                            if (!$util.isString(message.emailDomains[i]))
                                return "emailDomains: string[] expected";
                    }
                    if (message.whitelistedIpAddresses != null && message.hasOwnProperty("whitelistedIpAddresses")) {
                        if (!Array.isArray(message.whitelistedIpAddresses))
                            return "whitelistedIpAddresses: array expected";
                        for (var i = 0; i < message.whitelistedIpAddresses.length; ++i)
                            if (!$util.isString(message.whitelistedIpAddresses[i]))
                                return "whitelistedIpAddresses: string[] expected";
                    }
                    if (message.defaultProgramId != null && message.hasOwnProperty("defaultProgramId"))
                        if (!$util.isString(message.defaultProgramId))
                            return "defaultProgramId: string expected";
                    if (message.daysTasksAvailableForAccess != null && message.hasOwnProperty("daysTasksAvailableForAccess"))
                        if (!$util.isInteger(message.daysTasksAvailableForAccess))
                            return "daysTasksAvailableForAccess: integer expected";
                    if (message.programs != null && message.hasOwnProperty("programs")) {
                        if (!$util.isObject(message.programs))
                            return "programs: object expected";
                        var key = Object.keys(message.programs);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.infinitusai.orgs.ProgramDoc.verify(message.programs[key[i]]);
                            if (error)
                                return "programs." + error;
                        }
                    }
                    if (message.enabledCustomerPortalFeatures != null && message.hasOwnProperty("enabledCustomerPortalFeatures")) {
                        if (!Array.isArray(message.enabledCustomerPortalFeatures))
                            return "enabledCustomerPortalFeatures: array expected";
                        for (var i = 0; i < message.enabledCustomerPortalFeatures.length; ++i)
                            switch (message.enabledCustomerPortalFeatures[i]) {
                            default:
                                return "enabledCustomerPortalFeatures: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 30:
                            case 31:
                            case 32:
                            case 33:
                                break;
                            }
                    }
                    if (message.availableSeats != null && message.hasOwnProperty("availableSeats")) {
                        var error = $root.google.protobuf.Int32Value.verify(message.availableSeats);
                        if (error)
                            return "availableSeats." + error;
                    }
                    if (message.isLive != null && message.hasOwnProperty("isLive"))
                        if (typeof message.isLive !== "boolean")
                            return "isLive: boolean expected";
                    if (message.enableTesting != null && message.hasOwnProperty("enableTesting"))
                        if (typeof message.enableTesting !== "boolean")
                            return "enableTesting: boolean expected";
                    if (message.custom != null && message.hasOwnProperty("custom")) {
                        var error = $root.infinitusai.orgs.OrgCustomization.verify(message.custom);
                        if (error)
                            return "custom." + error;
                    }
                    if (message.demo != null && message.hasOwnProperty("demo"))
                        if (typeof message.demo !== "boolean")
                            return "demo: boolean expected";
                    if (message.roleNames != null && message.hasOwnProperty("roleNames")) {
                        if (!Array.isArray(message.roleNames))
                            return "roleNames: array expected";
                        for (var i = 0; i < message.roleNames.length; ++i)
                            if (!$util.isString(message.roleNames[i]))
                                return "roleNames: string[] expected";
                    }
                    if (message.isInternal != null && message.hasOwnProperty("isInternal"))
                        if (typeof message.isInternal !== "boolean")
                            return "isInternal: boolean expected";
                    if (message.disableFetchRecording != null && message.hasOwnProperty("disableFetchRecording"))
                        if (typeof message.disableFetchRecording !== "boolean")
                            return "disableFetchRecording: boolean expected";
                    if (message.timeSavingsStartDate != null && message.hasOwnProperty("timeSavingsStartDate"))
                        if (!$util.isString(message.timeSavingsStartDate))
                            return "timeSavingsStartDate: string expected";
                    if (message.enabledOperatorPortalFeatures != null && message.hasOwnProperty("enabledOperatorPortalFeatures")) {
                        if (!Array.isArray(message.enabledOperatorPortalFeatures))
                            return "enabledOperatorPortalFeatures: array expected";
                        for (var i = 0; i < message.enabledOperatorPortalFeatures.length; ++i)
                            switch (message.enabledOperatorPortalFeatures[i]) {
                            default:
                                return "enabledOperatorPortalFeatures: enum value[] expected";
                            case 0:
                            case 5:
                            case 6:
                            case 7:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 30:
                            case 31:
                            case 32:
                            case 33:
                            case 34:
                            case 35:
                            case 36:
                            case 37:
                            case 38:
                                break;
                            }
                    }
                    return null;
                };
    
                /**
                 * Creates an Org message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.Org} Org
                 */
                Org.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.Org)
                        return object;
                    var message = new $root.infinitusai.orgs.Org();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.imageUrl != null)
                        message.imageUrl = String(object.imageUrl);
                    if (object.supportEmail != null)
                        message.supportEmail = String(object.supportEmail);
                    if (object.emailDomains) {
                        if (!Array.isArray(object.emailDomains))
                            throw TypeError(".infinitusai.orgs.Org.emailDomains: array expected");
                        message.emailDomains = [];
                        for (var i = 0; i < object.emailDomains.length; ++i)
                            message.emailDomains[i] = String(object.emailDomains[i]);
                    }
                    if (object.whitelistedIpAddresses) {
                        if (!Array.isArray(object.whitelistedIpAddresses))
                            throw TypeError(".infinitusai.orgs.Org.whitelistedIpAddresses: array expected");
                        message.whitelistedIpAddresses = [];
                        for (var i = 0; i < object.whitelistedIpAddresses.length; ++i)
                            message.whitelistedIpAddresses[i] = String(object.whitelistedIpAddresses[i]);
                    }
                    if (object.defaultProgramId != null)
                        message.defaultProgramId = String(object.defaultProgramId);
                    if (object.daysTasksAvailableForAccess != null)
                        message.daysTasksAvailableForAccess = object.daysTasksAvailableForAccess | 0;
                    if (object.programs) {
                        if (typeof object.programs !== "object")
                            throw TypeError(".infinitusai.orgs.Org.programs: object expected");
                        message.programs = {};
                        for (var keys = Object.keys(object.programs), i = 0; i < keys.length; ++i) {
                            if (typeof object.programs[keys[i]] !== "object")
                                throw TypeError(".infinitusai.orgs.Org.programs: object expected");
                            message.programs[keys[i]] = $root.infinitusai.orgs.ProgramDoc.fromObject(object.programs[keys[i]]);
                        }
                    }
                    if (object.enabledCustomerPortalFeatures) {
                        if (!Array.isArray(object.enabledCustomerPortalFeatures))
                            throw TypeError(".infinitusai.orgs.Org.enabledCustomerPortalFeatures: array expected");
                        message.enabledCustomerPortalFeatures = [];
                        for (var i = 0; i < object.enabledCustomerPortalFeatures.length; ++i)
                            switch (object.enabledCustomerPortalFeatures[i]) {
                            default:
                                if (typeof object.enabledCustomerPortalFeatures[i] === "number") {
                                    message.enabledCustomerPortalFeatures[i] = object.enabledCustomerPortalFeatures[i];
                                    break;
                                }
                            case "CUSTOMER_PORTAL_FEATURE_UNKNOWN":
                            case 0:
                                message.enabledCustomerPortalFeatures[i] = 0;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_PAYER_INTELLIGENCE":
                            case 1:
                                message.enabledCustomerPortalFeatures[i] = 1;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_REPORTS":
                            case 2:
                                message.enabledCustomerPortalFeatures[i] = 2;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CREATE_TASK":
                            case 3:
                                message.enabledCustomerPortalFeatures[i] = 3;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BILLING":
                            case 4:
                                message.enabledCustomerPortalFeatures[i] = 4;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_EXPOSE_PHI":
                            case 5:
                                message.enabledCustomerPortalFeatures[i] = 5;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_ALL_TASKS_REQUIRE_REVIEW":
                            case 6:
                                message.enabledCustomerPortalFeatures[i] = 6;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_UPDATE_TASK_INPUTS":
                            case 7:
                                message.enabledCustomerPortalFeatures[i] = 7;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IMPORT_TASKS_CSV":
                            case 8:
                                message.enabledCustomerPortalFeatures[i] = 8;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BILLING_NOTES":
                            case 9:
                                message.enabledCustomerPortalFeatures[i] = 9;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IVR_AND_HOLD":
                            case 10:
                                message.enabledCustomerPortalFeatures[i] = 10;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_IMPORT_RULES":
                            case 11:
                                message.enabledCustomerPortalFeatures[i] = 11;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TASK_TABLE_TASK_TYPES":
                            case 12:
                                message.enabledCustomerPortalFeatures[i] = 12;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TASK_TABLE_PRODUCTS":
                            case 13:
                                message.enabledCustomerPortalFeatures[i] = 13;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_MARK_TASK_COMPLETE":
                            case 14:
                                message.enabledCustomerPortalFeatures[i] = 14;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_RBAC":
                            case 15:
                                message.enabledCustomerPortalFeatures[i] = 15;
                                break;
                            case "CUSTOMER_PORTAL_UI_UPDATES":
                            case 16:
                                message.enabledCustomerPortalFeatures[i] = 16;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_V2":
                            case 17:
                                message.enabledCustomerPortalFeatures[i] = 17;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_HUMAN_READABLE_CSV_REPORT":
                            case 18:
                                message.enabledCustomerPortalFeatures[i] = 18;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SELF_SERVE_TESTING":
                            case 19:
                                message.enabledCustomerPortalFeatures[i] = 19;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CSWII":
                            case 20:
                                message.enabledCustomerPortalFeatures[i] = 20;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SHOW_TIME_SAVINGS":
                            case 21:
                                message.enabledCustomerPortalFeatures[i] = 21;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_ACTIVE_CALLS":
                            case 22:
                                message.enabledCustomerPortalFeatures[i] = 22;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_NEW_VOICE_ARCHITECTURE":
                            case 23:
                                message.enabledCustomerPortalFeatures[i] = 23;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_DISABLE_FT_HOLD_TIMES":
                            case 24:
                                message.enabledCustomerPortalFeatures[i] = 24;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CALL_SUMMARIZATION":
                            case 25:
                                message.enabledCustomerPortalFeatures[i] = 25;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_SHOW_HUMAN_INVOLVEMENT_FLAG":
                            case 26:
                                message.enabledCustomerPortalFeatures[i] = 26;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_MULTI_PLAYER_MODE_INTERNAL":
                            case 27:
                                message.enabledCustomerPortalFeatures[i] = 27;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FASTTRACK_SINGLE_PLAYER_MODE_INTERNAL":
                            case 28:
                                message.enabledCustomerPortalFeatures[i] = 28;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_CSV_REPORT_EXCLUDE_UNUSED_API_OUTPUTS":
                            case 29:
                                message.enabledCustomerPortalFeatures[i] = 29;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_DEMO":
                            case 30:
                                message.enabledCustomerPortalFeatures[i] = 30;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_TEAM_MANAGEMENT":
                            case 31:
                                message.enabledCustomerPortalFeatures[i] = 31;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_FT_TRANSCRIPT":
                            case 32:
                                message.enabledCustomerPortalFeatures[i] = 32;
                                break;
                            case "CUSTOMER_PORTAL_FEATURE_BUNDLE_CUSTOMER_TASKS":
                            case 33:
                                message.enabledCustomerPortalFeatures[i] = 33;
                                break;
                            }
                    }
                    if (object.availableSeats != null) {
                        if (typeof object.availableSeats !== "object")
                            throw TypeError(".infinitusai.orgs.Org.availableSeats: object expected");
                        message.availableSeats = $root.google.protobuf.Int32Value.fromObject(object.availableSeats);
                    }
                    if (object.isLive != null)
                        message.isLive = Boolean(object.isLive);
                    if (object.enableTesting != null)
                        message.enableTesting = Boolean(object.enableTesting);
                    if (object.custom != null) {
                        if (typeof object.custom !== "object")
                            throw TypeError(".infinitusai.orgs.Org.custom: object expected");
                        message.custom = $root.infinitusai.orgs.OrgCustomization.fromObject(object.custom);
                    }
                    if (object.demo != null)
                        message.demo = Boolean(object.demo);
                    if (object.roleNames) {
                        if (!Array.isArray(object.roleNames))
                            throw TypeError(".infinitusai.orgs.Org.roleNames: array expected");
                        message.roleNames = [];
                        for (var i = 0; i < object.roleNames.length; ++i)
                            message.roleNames[i] = String(object.roleNames[i]);
                    }
                    if (object.isInternal != null)
                        message.isInternal = Boolean(object.isInternal);
                    if (object.disableFetchRecording != null)
                        message.disableFetchRecording = Boolean(object.disableFetchRecording);
                    if (object.timeSavingsStartDate != null)
                        message.timeSavingsStartDate = String(object.timeSavingsStartDate);
                    if (object.enabledOperatorPortalFeatures) {
                        if (!Array.isArray(object.enabledOperatorPortalFeatures))
                            throw TypeError(".infinitusai.orgs.Org.enabledOperatorPortalFeatures: array expected");
                        message.enabledOperatorPortalFeatures = [];
                        for (var i = 0; i < object.enabledOperatorPortalFeatures.length; ++i)
                            switch (object.enabledOperatorPortalFeatures[i]) {
                            default:
                                if (typeof object.enabledOperatorPortalFeatures[i] === "number") {
                                    message.enabledOperatorPortalFeatures[i] = object.enabledOperatorPortalFeatures[i];
                                    break;
                                }
                            case "OPERATOR_PORTAL_FEATURE_UNKNOWN":
                            case 0:
                                message.enabledOperatorPortalFeatures[i] = 0;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_PROCESS_TASK":
                            case 5:
                                message.enabledOperatorPortalFeatures[i] = 5;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SKIP_REVIEW":
                            case 6:
                                message.enabledOperatorPortalFeatures[i] = 6;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SKIP_REVIEW_IGNORE_OPERATOR_CRITERIA":
                            case 7:
                                message.enabledOperatorPortalFeatures[i] = 7;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_INPUTS":
                            case 9:
                                message.enabledOperatorPortalFeatures[i] = 9;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_MULTITASK_ENABLED":
                            case 10:
                                message.enabledOperatorPortalFeatures[i] = 10;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SUPPRESS_BEG_NOTIFICATIONS":
                            case 11:
                                message.enabledOperatorPortalFeatures[i] = 11;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ADD_INPUTS_TO_OUTPUT_CSV":
                            case 12:
                                message.enabledOperatorPortalFeatures[i] = 12;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_OUTPUTS":
                            case 13:
                                message.enabledOperatorPortalFeatures[i] = 13;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_RBAC":
                            case 14:
                                message.enabledOperatorPortalFeatures[i] = 14;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_AUTO_POPULATE_FROM_STAT_PIE":
                            case 15:
                                message.enabledOperatorPortalFeatures[i] = 15;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_PARTIAL_REVIEW":
                            case 16:
                                message.enabledOperatorPortalFeatures[i] = 16;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SOCKET_IO":
                            case 17:
                                message.enabledOperatorPortalFeatures[i] = 17;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_PRIORITIZE_BLIZZARD_OVER_PROD_TASKS":
                            case 18:
                                message.enabledOperatorPortalFeatures[i] = 18;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_UNIFIED_QUEUE":
                            case 19:
                                message.enabledOperatorPortalFeatures[i] = 19;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ML_IVR_TESTING":
                            case 20:
                                message.enabledOperatorPortalFeatures[i] = 20;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_INLINE_CALL_OUTPUT_VALIDATION":
                            case 21:
                                message.enabledOperatorPortalFeatures[i] = 21;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ALWAYS_AUTODIAL_FIRST_CALL":
                            case 22:
                                message.enabledOperatorPortalFeatures[i] = 22;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ALLOW_NEW_CALL_WITHOUT_PROCESSING":
                            case 23:
                                message.enabledOperatorPortalFeatures[i] = 23;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ENFORCE_HUMAN_CALL_REASON":
                            case 24:
                                message.enabledOperatorPortalFeatures[i] = 24;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_USE_CONVO_BUILDER_2":
                            case 25:
                                message.enabledOperatorPortalFeatures[i] = 25;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_USE_NEW_PROCESS_CALL_FLOW":
                            case 26:
                                message.enabledOperatorPortalFeatures[i] = 26;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_EARLY_FAIL_TPA_TASKS":
                            case 27:
                                message.enabledOperatorPortalFeatures[i] = 27;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_PLAN_NAME_XP":
                            case 28:
                                message.enabledOperatorPortalFeatures[i] = 28;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_GENERIC_EBV":
                            case 29:
                                message.enabledOperatorPortalFeatures[i] = 29;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_MULTIBENEFITS_ENABLED":
                            case 30:
                                message.enabledOperatorPortalFeatures[i] = 30;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_REPORT_CONTROL_CALL_REASON":
                            case 31:
                                message.enabledOperatorPortalFeatures[i] = 31;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_AUTO_REDIRECT_IN_HOLD":
                            case 32:
                                message.enabledOperatorPortalFeatures[i] = 32;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_LOG_TRIGGERED_CUSTOMER_PIE":
                            case 33:
                                message.enabledOperatorPortalFeatures[i] = 33;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_ROUTE_OPS_FROM_AUTODIALER":
                            case 34:
                                message.enabledOperatorPortalFeatures[i] = 34;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_HIDE_ROUTE_OPERATOR_BUTTON":
                            case 35:
                                message.enabledOperatorPortalFeatures[i] = 35;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_AUTO_REVIEW_ENABLED":
                            case 36:
                                message.enabledOperatorPortalFeatures[i] = 36;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_HIDE_FREE_TEXT_INPUT":
                            case 37:
                                message.enabledOperatorPortalFeatures[i] = 37;
                                break;
                            case "OPERATOR_PORTAL_FEATURE_SHOW_TASKS_FOR_NON_ADMINS":
                            case 38:
                                message.enabledOperatorPortalFeatures[i] = 38;
                                break;
                            }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an Org message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {infinitusai.orgs.Org} message Org
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Org.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.emailDomains = [];
                        object.whitelistedIpAddresses = [];
                        object.enabledCustomerPortalFeatures = [];
                        object.roleNames = [];
                        object.enabledOperatorPortalFeatures = [];
                    }
                    if (options.objects || options.defaults)
                        object.programs = {};
                    if (options.defaults) {
                        object.uuid = "";
                        object.name = "";
                        object.displayName = "";
                        object.imageUrl = "";
                        object.supportEmail = "";
                        object.defaultProgramId = "";
                        object.daysTasksAvailableForAccess = 0;
                        object.availableSeats = null;
                        object.isLive = false;
                        object.enableTesting = false;
                        object.custom = null;
                        object.demo = false;
                        object.isInternal = false;
                        object.disableFetchRecording = false;
                        object.timeSavingsStartDate = "";
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        object.imageUrl = message.imageUrl;
                    if (message.supportEmail != null && message.hasOwnProperty("supportEmail"))
                        object.supportEmail = message.supportEmail;
                    if (message.emailDomains && message.emailDomains.length) {
                        object.emailDomains = [];
                        for (var j = 0; j < message.emailDomains.length; ++j)
                            object.emailDomains[j] = message.emailDomains[j];
                    }
                    if (message.whitelistedIpAddresses && message.whitelistedIpAddresses.length) {
                        object.whitelistedIpAddresses = [];
                        for (var j = 0; j < message.whitelistedIpAddresses.length; ++j)
                            object.whitelistedIpAddresses[j] = message.whitelistedIpAddresses[j];
                    }
                    if (message.defaultProgramId != null && message.hasOwnProperty("defaultProgramId"))
                        object.defaultProgramId = message.defaultProgramId;
                    if (message.daysTasksAvailableForAccess != null && message.hasOwnProperty("daysTasksAvailableForAccess"))
                        object.daysTasksAvailableForAccess = message.daysTasksAvailableForAccess;
                    var keys2;
                    if (message.programs && (keys2 = Object.keys(message.programs)).length) {
                        object.programs = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.programs[keys2[j]] = $root.infinitusai.orgs.ProgramDoc.toObject(message.programs[keys2[j]], options);
                    }
                    if (message.enabledCustomerPortalFeatures && message.enabledCustomerPortalFeatures.length) {
                        object.enabledCustomerPortalFeatures = [];
                        for (var j = 0; j < message.enabledCustomerPortalFeatures.length; ++j)
                            object.enabledCustomerPortalFeatures[j] = options.enums === String ? $root.infinitusai.orgs.CustomerPortalFeature[message.enabledCustomerPortalFeatures[j]] === undefined ? message.enabledCustomerPortalFeatures[j] : $root.infinitusai.orgs.CustomerPortalFeature[message.enabledCustomerPortalFeatures[j]] : message.enabledCustomerPortalFeatures[j];
                    }
                    if (message.availableSeats != null && message.hasOwnProperty("availableSeats"))
                        object.availableSeats = $root.google.protobuf.Int32Value.toObject(message.availableSeats, options);
                    if (message.isLive != null && message.hasOwnProperty("isLive"))
                        object.isLive = message.isLive;
                    if (message.enableTesting != null && message.hasOwnProperty("enableTesting"))
                        object.enableTesting = message.enableTesting;
                    if (message.custom != null && message.hasOwnProperty("custom"))
                        object.custom = $root.infinitusai.orgs.OrgCustomization.toObject(message.custom, options);
                    if (message.demo != null && message.hasOwnProperty("demo"))
                        object.demo = message.demo;
                    if (message.roleNames && message.roleNames.length) {
                        object.roleNames = [];
                        for (var j = 0; j < message.roleNames.length; ++j)
                            object.roleNames[j] = message.roleNames[j];
                    }
                    if (message.isInternal != null && message.hasOwnProperty("isInternal"))
                        object.isInternal = message.isInternal;
                    if (message.disableFetchRecording != null && message.hasOwnProperty("disableFetchRecording"))
                        object.disableFetchRecording = message.disableFetchRecording;
                    if (message.timeSavingsStartDate != null && message.hasOwnProperty("timeSavingsStartDate"))
                        object.timeSavingsStartDate = message.timeSavingsStartDate;
                    if (message.enabledOperatorPortalFeatures && message.enabledOperatorPortalFeatures.length) {
                        object.enabledOperatorPortalFeatures = [];
                        for (var j = 0; j < message.enabledOperatorPortalFeatures.length; ++j)
                            object.enabledOperatorPortalFeatures[j] = options.enums === String ? $root.infinitusai.orgs.OperatorPortalFeature[message.enabledOperatorPortalFeatures[j]] === undefined ? message.enabledOperatorPortalFeatures[j] : $root.infinitusai.orgs.OperatorPortalFeature[message.enabledOperatorPortalFeatures[j]] : message.enabledOperatorPortalFeatures[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this Org to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.Org
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Org.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Org
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.Org
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Org.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.Org";
                };
    
                return Org;
            })();
    
            orgs.OrgCustomization = (function() {
    
                /**
                 * Properties of an OrgCustomization.
                 * @memberof infinitusai.orgs
                 * @interface IOrgCustomization
                 * @property {Array.<string>|null} [prunedSuggestions] OrgCustomization prunedSuggestions
                 * @property {string|null} [introSentence] OrgCustomization introSentence
                 * @property {string|null} [entityName] OrgCustomization entityName
                 * @property {Array.<string>|null} [prunedBvInputs] OrgCustomization prunedBvInputs
                 * @property {Array.<string>|null} [prunedBvOutputs] OrgCustomization prunedBvOutputs
                 */
    
                /**
                 * Constructs a new OrgCustomization.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents an OrgCustomization.
                 * @implements IOrgCustomization
                 * @constructor
                 * @param {infinitusai.orgs.IOrgCustomization=} [properties] Properties to set
                 */
                function OrgCustomization(properties) {
                    this.prunedSuggestions = [];
                    this.prunedBvInputs = [];
                    this.prunedBvOutputs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgCustomization prunedSuggestions.
                 * @member {Array.<string>} prunedSuggestions
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @instance
                 */
                OrgCustomization.prototype.prunedSuggestions = $util.emptyArray;
    
                /**
                 * OrgCustomization introSentence.
                 * @member {string} introSentence
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @instance
                 */
                OrgCustomization.prototype.introSentence = "";
    
                /**
                 * OrgCustomization entityName.
                 * @member {string} entityName
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @instance
                 */
                OrgCustomization.prototype.entityName = "";
    
                /**
                 * OrgCustomization prunedBvInputs.
                 * @member {Array.<string>} prunedBvInputs
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @instance
                 */
                OrgCustomization.prototype.prunedBvInputs = $util.emptyArray;
    
                /**
                 * OrgCustomization prunedBvOutputs.
                 * @member {Array.<string>} prunedBvOutputs
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @instance
                 */
                OrgCustomization.prototype.prunedBvOutputs = $util.emptyArray;
    
                /**
                 * Creates a new OrgCustomization instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {infinitusai.orgs.IOrgCustomization=} [properties] Properties to set
                 * @returns {infinitusai.orgs.OrgCustomization} OrgCustomization instance
                 */
                OrgCustomization.create = function create(properties) {
                    return new OrgCustomization(properties);
                };
    
                /**
                 * Encodes the specified OrgCustomization message. Does not implicitly {@link infinitusai.orgs.OrgCustomization.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {infinitusai.orgs.IOrgCustomization} message OrgCustomization message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgCustomization.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.prunedSuggestions != null && message.prunedSuggestions.length)
                        for (var i = 0; i < message.prunedSuggestions.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.prunedSuggestions[i]);
                    if (message.introSentence != null && Object.hasOwnProperty.call(message, "introSentence"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.introSentence);
                    if (message.entityName != null && Object.hasOwnProperty.call(message, "entityName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.entityName);
                    if (message.prunedBvInputs != null && message.prunedBvInputs.length)
                        for (var i = 0; i < message.prunedBvInputs.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.prunedBvInputs[i]);
                    if (message.prunedBvOutputs != null && message.prunedBvOutputs.length)
                        for (var i = 0; i < message.prunedBvOutputs.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.prunedBvOutputs[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgCustomization message, length delimited. Does not implicitly {@link infinitusai.orgs.OrgCustomization.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {infinitusai.orgs.IOrgCustomization} message OrgCustomization message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgCustomization.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgCustomization message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.OrgCustomization} OrgCustomization
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgCustomization.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.OrgCustomization();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.prunedSuggestions && message.prunedSuggestions.length))
                                    message.prunedSuggestions = [];
                                message.prunedSuggestions.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.introSentence = reader.string();
                                break;
                            }
                        case 3: {
                                message.entityName = reader.string();
                                break;
                            }
                        case 4: {
                                if (!(message.prunedBvInputs && message.prunedBvInputs.length))
                                    message.prunedBvInputs = [];
                                message.prunedBvInputs.push(reader.string());
                                break;
                            }
                        case 5: {
                                if (!(message.prunedBvOutputs && message.prunedBvOutputs.length))
                                    message.prunedBvOutputs = [];
                                message.prunedBvOutputs.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgCustomization message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.OrgCustomization} OrgCustomization
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgCustomization.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgCustomization message.
                 * @function verify
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgCustomization.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.prunedSuggestions != null && message.hasOwnProperty("prunedSuggestions")) {
                        if (!Array.isArray(message.prunedSuggestions))
                            return "prunedSuggestions: array expected";
                        for (var i = 0; i < message.prunedSuggestions.length; ++i)
                            if (!$util.isString(message.prunedSuggestions[i]))
                                return "prunedSuggestions: string[] expected";
                    }
                    if (message.introSentence != null && message.hasOwnProperty("introSentence"))
                        if (!$util.isString(message.introSentence))
                            return "introSentence: string expected";
                    if (message.entityName != null && message.hasOwnProperty("entityName"))
                        if (!$util.isString(message.entityName))
                            return "entityName: string expected";
                    if (message.prunedBvInputs != null && message.hasOwnProperty("prunedBvInputs")) {
                        if (!Array.isArray(message.prunedBvInputs))
                            return "prunedBvInputs: array expected";
                        for (var i = 0; i < message.prunedBvInputs.length; ++i)
                            if (!$util.isString(message.prunedBvInputs[i]))
                                return "prunedBvInputs: string[] expected";
                    }
                    if (message.prunedBvOutputs != null && message.hasOwnProperty("prunedBvOutputs")) {
                        if (!Array.isArray(message.prunedBvOutputs))
                            return "prunedBvOutputs: array expected";
                        for (var i = 0; i < message.prunedBvOutputs.length; ++i)
                            if (!$util.isString(message.prunedBvOutputs[i]))
                                return "prunedBvOutputs: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an OrgCustomization message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.OrgCustomization} OrgCustomization
                 */
                OrgCustomization.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.OrgCustomization)
                        return object;
                    var message = new $root.infinitusai.orgs.OrgCustomization();
                    if (object.prunedSuggestions) {
                        if (!Array.isArray(object.prunedSuggestions))
                            throw TypeError(".infinitusai.orgs.OrgCustomization.prunedSuggestions: array expected");
                        message.prunedSuggestions = [];
                        for (var i = 0; i < object.prunedSuggestions.length; ++i)
                            message.prunedSuggestions[i] = String(object.prunedSuggestions[i]);
                    }
                    if (object.introSentence != null)
                        message.introSentence = String(object.introSentence);
                    if (object.entityName != null)
                        message.entityName = String(object.entityName);
                    if (object.prunedBvInputs) {
                        if (!Array.isArray(object.prunedBvInputs))
                            throw TypeError(".infinitusai.orgs.OrgCustomization.prunedBvInputs: array expected");
                        message.prunedBvInputs = [];
                        for (var i = 0; i < object.prunedBvInputs.length; ++i)
                            message.prunedBvInputs[i] = String(object.prunedBvInputs[i]);
                    }
                    if (object.prunedBvOutputs) {
                        if (!Array.isArray(object.prunedBvOutputs))
                            throw TypeError(".infinitusai.orgs.OrgCustomization.prunedBvOutputs: array expected");
                        message.prunedBvOutputs = [];
                        for (var i = 0; i < object.prunedBvOutputs.length; ++i)
                            message.prunedBvOutputs[i] = String(object.prunedBvOutputs[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgCustomization message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {infinitusai.orgs.OrgCustomization} message OrgCustomization
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgCustomization.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.prunedSuggestions = [];
                        object.prunedBvInputs = [];
                        object.prunedBvOutputs = [];
                    }
                    if (options.defaults) {
                        object.introSentence = "";
                        object.entityName = "";
                    }
                    if (message.prunedSuggestions && message.prunedSuggestions.length) {
                        object.prunedSuggestions = [];
                        for (var j = 0; j < message.prunedSuggestions.length; ++j)
                            object.prunedSuggestions[j] = message.prunedSuggestions[j];
                    }
                    if (message.introSentence != null && message.hasOwnProperty("introSentence"))
                        object.introSentence = message.introSentence;
                    if (message.entityName != null && message.hasOwnProperty("entityName"))
                        object.entityName = message.entityName;
                    if (message.prunedBvInputs && message.prunedBvInputs.length) {
                        object.prunedBvInputs = [];
                        for (var j = 0; j < message.prunedBvInputs.length; ++j)
                            object.prunedBvInputs[j] = message.prunedBvInputs[j];
                    }
                    if (message.prunedBvOutputs && message.prunedBvOutputs.length) {
                        object.prunedBvOutputs = [];
                        for (var j = 0; j < message.prunedBvOutputs.length; ++j)
                            object.prunedBvOutputs[j] = message.prunedBvOutputs[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this OrgCustomization to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgCustomization.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgCustomization
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.OrgCustomization
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgCustomization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.OrgCustomization";
                };
    
                return OrgCustomization;
            })();
    
            orgs.ProgramDoc = (function() {
    
                /**
                 * Properties of a ProgramDoc.
                 * @memberof infinitusai.orgs
                 * @interface IProgramDoc
                 * @property {string|null} [name] ProgramDoc name
                 * @property {string|null} [displayName] ProgramDoc displayName
                 */
    
                /**
                 * Constructs a new ProgramDoc.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents a ProgramDoc.
                 * @implements IProgramDoc
                 * @constructor
                 * @param {infinitusai.orgs.IProgramDoc=} [properties] Properties to set
                 */
                function ProgramDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ProgramDoc name.
                 * @member {string} name
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.name = "";
    
                /**
                 * ProgramDoc displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @instance
                 */
                ProgramDoc.prototype.displayName = "";
    
                /**
                 * Creates a new ProgramDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {infinitusai.orgs.IProgramDoc=} [properties] Properties to set
                 * @returns {infinitusai.orgs.ProgramDoc} ProgramDoc instance
                 */
                ProgramDoc.create = function create(properties) {
                    return new ProgramDoc(properties);
                };
    
                /**
                 * Encodes the specified ProgramDoc message. Does not implicitly {@link infinitusai.orgs.ProgramDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {infinitusai.orgs.IProgramDoc} message ProgramDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProgramDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayName);
                    return writer;
                };
    
                /**
                 * Encodes the specified ProgramDoc message, length delimited. Does not implicitly {@link infinitusai.orgs.ProgramDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {infinitusai.orgs.IProgramDoc} message ProgramDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProgramDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ProgramDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.ProgramDoc} ProgramDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProgramDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.ProgramDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.displayName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ProgramDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.ProgramDoc} ProgramDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProgramDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ProgramDoc message.
                 * @function verify
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProgramDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };
    
                /**
                 * Creates a ProgramDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.ProgramDoc} ProgramDoc
                 */
                ProgramDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.ProgramDoc)
                        return object;
                    var message = new $root.infinitusai.orgs.ProgramDoc();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a ProgramDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {infinitusai.orgs.ProgramDoc} message ProgramDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProgramDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.displayName = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };
    
                /**
                 * Converts this ProgramDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProgramDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ProgramDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.ProgramDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProgramDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.ProgramDoc";
                };
    
                return ProgramDoc;
            })();
    
            /**
             * CustomerPortalFeature enum.
             * @name infinitusai.orgs.CustomerPortalFeature
             * @enum {number}
             * @property {number} CUSTOMER_PORTAL_FEATURE_UNKNOWN=0 CUSTOMER_PORTAL_FEATURE_UNKNOWN value
             * @property {number} CUSTOMER_PORTAL_FEATURE_PAYER_INTELLIGENCE=1 CUSTOMER_PORTAL_FEATURE_PAYER_INTELLIGENCE value
             * @property {number} CUSTOMER_PORTAL_FEATURE_REPORTS=2 CUSTOMER_PORTAL_FEATURE_REPORTS value
             * @property {number} CUSTOMER_PORTAL_FEATURE_CREATE_TASK=3 CUSTOMER_PORTAL_FEATURE_CREATE_TASK value
             * @property {number} CUSTOMER_PORTAL_FEATURE_BILLING=4 CUSTOMER_PORTAL_FEATURE_BILLING value
             * @property {number} CUSTOMER_PORTAL_FEATURE_EXPOSE_PHI=5 CUSTOMER_PORTAL_FEATURE_EXPOSE_PHI value
             * @property {number} CUSTOMER_PORTAL_FEATURE_ALL_TASKS_REQUIRE_REVIEW=6 CUSTOMER_PORTAL_FEATURE_ALL_TASKS_REQUIRE_REVIEW value
             * @property {number} CUSTOMER_PORTAL_FEATURE_UPDATE_TASK_INPUTS=7 CUSTOMER_PORTAL_FEATURE_UPDATE_TASK_INPUTS value
             * @property {number} CUSTOMER_PORTAL_FEATURE_IMPORT_TASKS_CSV=8 CUSTOMER_PORTAL_FEATURE_IMPORT_TASKS_CSV value
             * @property {number} CUSTOMER_PORTAL_FEATURE_BILLING_NOTES=9 CUSTOMER_PORTAL_FEATURE_BILLING_NOTES value
             * @property {number} CUSTOMER_PORTAL_FEATURE_IVR_AND_HOLD=10 CUSTOMER_PORTAL_FEATURE_IVR_AND_HOLD value
             * @property {number} CUSTOMER_PORTAL_FEATURE_IMPORT_RULES=11 CUSTOMER_PORTAL_FEATURE_IMPORT_RULES value
             * @property {number} CUSTOMER_PORTAL_FEATURE_TASK_TABLE_TASK_TYPES=12 CUSTOMER_PORTAL_FEATURE_TASK_TABLE_TASK_TYPES value
             * @property {number} CUSTOMER_PORTAL_FEATURE_TASK_TABLE_PRODUCTS=13 CUSTOMER_PORTAL_FEATURE_TASK_TABLE_PRODUCTS value
             * @property {number} CUSTOMER_PORTAL_FEATURE_MARK_TASK_COMPLETE=14 CUSTOMER_PORTAL_FEATURE_MARK_TASK_COMPLETE value
             * @property {number} CUSTOMER_PORTAL_FEATURE_RBAC=15 CUSTOMER_PORTAL_FEATURE_RBAC value
             * @property {number} CUSTOMER_PORTAL_UI_UPDATES=16 CUSTOMER_PORTAL_UI_UPDATES value
             * @property {number} CUSTOMER_PORTAL_FEATURE_V2=17 CUSTOMER_PORTAL_FEATURE_V2 value
             * @property {number} CUSTOMER_PORTAL_FEATURE_HUMAN_READABLE_CSV_REPORT=18 CUSTOMER_PORTAL_FEATURE_HUMAN_READABLE_CSV_REPORT value
             * @property {number} CUSTOMER_PORTAL_FEATURE_SELF_SERVE_TESTING=19 CUSTOMER_PORTAL_FEATURE_SELF_SERVE_TESTING value
             * @property {number} CUSTOMER_PORTAL_FEATURE_CSWII=20 CUSTOMER_PORTAL_FEATURE_CSWII value
             * @property {number} CUSTOMER_PORTAL_FEATURE_SHOW_TIME_SAVINGS=21 CUSTOMER_PORTAL_FEATURE_SHOW_TIME_SAVINGS value
             * @property {number} CUSTOMER_PORTAL_FEATURE_FASTTRACK_ACTIVE_CALLS=22 CUSTOMER_PORTAL_FEATURE_FASTTRACK_ACTIVE_CALLS value
             * @property {number} CUSTOMER_PORTAL_FEATURE_NEW_VOICE_ARCHITECTURE=23 CUSTOMER_PORTAL_FEATURE_NEW_VOICE_ARCHITECTURE value
             * @property {number} CUSTOMER_PORTAL_FEATURE_DISABLE_FT_HOLD_TIMES=24 CUSTOMER_PORTAL_FEATURE_DISABLE_FT_HOLD_TIMES value
             * @property {number} CUSTOMER_PORTAL_FEATURE_CALL_SUMMARIZATION=25 CUSTOMER_PORTAL_FEATURE_CALL_SUMMARIZATION value
             * @property {number} CUSTOMER_PORTAL_FEATURE_SHOW_HUMAN_INVOLVEMENT_FLAG=26 CUSTOMER_PORTAL_FEATURE_SHOW_HUMAN_INVOLVEMENT_FLAG value
             * @property {number} CUSTOMER_PORTAL_FEATURE_FASTTRACK_MULTI_PLAYER_MODE_INTERNAL=27 CUSTOMER_PORTAL_FEATURE_FASTTRACK_MULTI_PLAYER_MODE_INTERNAL value
             * @property {number} CUSTOMER_PORTAL_FEATURE_FASTTRACK_SINGLE_PLAYER_MODE_INTERNAL=28 CUSTOMER_PORTAL_FEATURE_FASTTRACK_SINGLE_PLAYER_MODE_INTERNAL value
             * @property {number} CUSTOMER_PORTAL_FEATURE_CSV_REPORT_EXCLUDE_UNUSED_API_OUTPUTS=29 CUSTOMER_PORTAL_FEATURE_CSV_REPORT_EXCLUDE_UNUSED_API_OUTPUTS value
             * @property {number} CUSTOMER_PORTAL_FEATURE_DEMO=30 CUSTOMER_PORTAL_FEATURE_DEMO value
             * @property {number} CUSTOMER_PORTAL_FEATURE_TEAM_MANAGEMENT=31 CUSTOMER_PORTAL_FEATURE_TEAM_MANAGEMENT value
             * @property {number} CUSTOMER_PORTAL_FEATURE_FT_TRANSCRIPT=32 CUSTOMER_PORTAL_FEATURE_FT_TRANSCRIPT value
             * @property {number} CUSTOMER_PORTAL_FEATURE_BUNDLE_CUSTOMER_TASKS=33 CUSTOMER_PORTAL_FEATURE_BUNDLE_CUSTOMER_TASKS value
             */
            orgs.CustomerPortalFeature = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CUSTOMER_PORTAL_FEATURE_UNKNOWN"] = 0;
                values[valuesById[1] = "CUSTOMER_PORTAL_FEATURE_PAYER_INTELLIGENCE"] = 1;
                values[valuesById[2] = "CUSTOMER_PORTAL_FEATURE_REPORTS"] = 2;
                values[valuesById[3] = "CUSTOMER_PORTAL_FEATURE_CREATE_TASK"] = 3;
                values[valuesById[4] = "CUSTOMER_PORTAL_FEATURE_BILLING"] = 4;
                values[valuesById[5] = "CUSTOMER_PORTAL_FEATURE_EXPOSE_PHI"] = 5;
                values[valuesById[6] = "CUSTOMER_PORTAL_FEATURE_ALL_TASKS_REQUIRE_REVIEW"] = 6;
                values[valuesById[7] = "CUSTOMER_PORTAL_FEATURE_UPDATE_TASK_INPUTS"] = 7;
                values[valuesById[8] = "CUSTOMER_PORTAL_FEATURE_IMPORT_TASKS_CSV"] = 8;
                values[valuesById[9] = "CUSTOMER_PORTAL_FEATURE_BILLING_NOTES"] = 9;
                values[valuesById[10] = "CUSTOMER_PORTAL_FEATURE_IVR_AND_HOLD"] = 10;
                values[valuesById[11] = "CUSTOMER_PORTAL_FEATURE_IMPORT_RULES"] = 11;
                values[valuesById[12] = "CUSTOMER_PORTAL_FEATURE_TASK_TABLE_TASK_TYPES"] = 12;
                values[valuesById[13] = "CUSTOMER_PORTAL_FEATURE_TASK_TABLE_PRODUCTS"] = 13;
                values[valuesById[14] = "CUSTOMER_PORTAL_FEATURE_MARK_TASK_COMPLETE"] = 14;
                values[valuesById[15] = "CUSTOMER_PORTAL_FEATURE_RBAC"] = 15;
                values[valuesById[16] = "CUSTOMER_PORTAL_UI_UPDATES"] = 16;
                values[valuesById[17] = "CUSTOMER_PORTAL_FEATURE_V2"] = 17;
                values[valuesById[18] = "CUSTOMER_PORTAL_FEATURE_HUMAN_READABLE_CSV_REPORT"] = 18;
                values[valuesById[19] = "CUSTOMER_PORTAL_FEATURE_SELF_SERVE_TESTING"] = 19;
                values[valuesById[20] = "CUSTOMER_PORTAL_FEATURE_CSWII"] = 20;
                values[valuesById[21] = "CUSTOMER_PORTAL_FEATURE_SHOW_TIME_SAVINGS"] = 21;
                values[valuesById[22] = "CUSTOMER_PORTAL_FEATURE_FASTTRACK_ACTIVE_CALLS"] = 22;
                values[valuesById[23] = "CUSTOMER_PORTAL_FEATURE_NEW_VOICE_ARCHITECTURE"] = 23;
                values[valuesById[24] = "CUSTOMER_PORTAL_FEATURE_DISABLE_FT_HOLD_TIMES"] = 24;
                values[valuesById[25] = "CUSTOMER_PORTAL_FEATURE_CALL_SUMMARIZATION"] = 25;
                values[valuesById[26] = "CUSTOMER_PORTAL_FEATURE_SHOW_HUMAN_INVOLVEMENT_FLAG"] = 26;
                values[valuesById[27] = "CUSTOMER_PORTAL_FEATURE_FASTTRACK_MULTI_PLAYER_MODE_INTERNAL"] = 27;
                values[valuesById[28] = "CUSTOMER_PORTAL_FEATURE_FASTTRACK_SINGLE_PLAYER_MODE_INTERNAL"] = 28;
                values[valuesById[29] = "CUSTOMER_PORTAL_FEATURE_CSV_REPORT_EXCLUDE_UNUSED_API_OUTPUTS"] = 29;
                values[valuesById[30] = "CUSTOMER_PORTAL_FEATURE_DEMO"] = 30;
                values[valuesById[31] = "CUSTOMER_PORTAL_FEATURE_TEAM_MANAGEMENT"] = 31;
                values[valuesById[32] = "CUSTOMER_PORTAL_FEATURE_FT_TRANSCRIPT"] = 32;
                values[valuesById[33] = "CUSTOMER_PORTAL_FEATURE_BUNDLE_CUSTOMER_TASKS"] = 33;
                return values;
            })();
    
            /**
             * OperatorPortalFeature enum.
             * @name infinitusai.orgs.OperatorPortalFeature
             * @enum {number}
             * @property {number} OPERATOR_PORTAL_FEATURE_UNKNOWN=0 OPERATOR_PORTAL_FEATURE_UNKNOWN value
             * @property {number} OPERATOR_PORTAL_FEATURE_PROCESS_TASK=5 OPERATOR_PORTAL_FEATURE_PROCESS_TASK value
             * @property {number} OPERATOR_PORTAL_FEATURE_SKIP_REVIEW=6 OPERATOR_PORTAL_FEATURE_SKIP_REVIEW value
             * @property {number} OPERATOR_PORTAL_FEATURE_SKIP_REVIEW_IGNORE_OPERATOR_CRITERIA=7 OPERATOR_PORTAL_FEATURE_SKIP_REVIEW_IGNORE_OPERATOR_CRITERIA value
             * @property {number} OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_INPUTS=9 OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_INPUTS value
             * @property {number} OPERATOR_PORTAL_FEATURE_MULTITASK_ENABLED=10 OPERATOR_PORTAL_FEATURE_MULTITASK_ENABLED value
             * @property {number} OPERATOR_PORTAL_FEATURE_SUPPRESS_BEG_NOTIFICATIONS=11 OPERATOR_PORTAL_FEATURE_SUPPRESS_BEG_NOTIFICATIONS value
             * @property {number} OPERATOR_PORTAL_FEATURE_ADD_INPUTS_TO_OUTPUT_CSV=12 OPERATOR_PORTAL_FEATURE_ADD_INPUTS_TO_OUTPUT_CSV value
             * @property {number} OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_OUTPUTS=13 OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_OUTPUTS value
             * @property {number} OPERATOR_PORTAL_FEATURE_RBAC=14 OPERATOR_PORTAL_FEATURE_RBAC value
             * @property {number} OPERATOR_PORTAL_FEATURE_AUTO_POPULATE_FROM_STAT_PIE=15 OPERATOR_PORTAL_FEATURE_AUTO_POPULATE_FROM_STAT_PIE value
             * @property {number} OPERATOR_PORTAL_FEATURE_PARTIAL_REVIEW=16 OPERATOR_PORTAL_FEATURE_PARTIAL_REVIEW value
             * @property {number} OPERATOR_PORTAL_FEATURE_SOCKET_IO=17 OPERATOR_PORTAL_FEATURE_SOCKET_IO value
             * @property {number} OPERATOR_PORTAL_FEATURE_PRIORITIZE_BLIZZARD_OVER_PROD_TASKS=18 OPERATOR_PORTAL_FEATURE_PRIORITIZE_BLIZZARD_OVER_PROD_TASKS value
             * @property {number} OPERATOR_PORTAL_FEATURE_UNIFIED_QUEUE=19 OPERATOR_PORTAL_FEATURE_UNIFIED_QUEUE value
             * @property {number} OPERATOR_PORTAL_FEATURE_ML_IVR_TESTING=20 OPERATOR_PORTAL_FEATURE_ML_IVR_TESTING value
             * @property {number} OPERATOR_PORTAL_FEATURE_INLINE_CALL_OUTPUT_VALIDATION=21 OPERATOR_PORTAL_FEATURE_INLINE_CALL_OUTPUT_VALIDATION value
             * @property {number} OPERATOR_PORTAL_FEATURE_ALWAYS_AUTODIAL_FIRST_CALL=22 OPERATOR_PORTAL_FEATURE_ALWAYS_AUTODIAL_FIRST_CALL value
             * @property {number} OPERATOR_PORTAL_FEATURE_ALLOW_NEW_CALL_WITHOUT_PROCESSING=23 OPERATOR_PORTAL_FEATURE_ALLOW_NEW_CALL_WITHOUT_PROCESSING value
             * @property {number} OPERATOR_PORTAL_FEATURE_ENFORCE_HUMAN_CALL_REASON=24 OPERATOR_PORTAL_FEATURE_ENFORCE_HUMAN_CALL_REASON value
             * @property {number} OPERATOR_PORTAL_FEATURE_USE_CONVO_BUILDER_2=25 OPERATOR_PORTAL_FEATURE_USE_CONVO_BUILDER_2 value
             * @property {number} OPERATOR_PORTAL_FEATURE_USE_NEW_PROCESS_CALL_FLOW=26 OPERATOR_PORTAL_FEATURE_USE_NEW_PROCESS_CALL_FLOW value
             * @property {number} OPERATOR_PORTAL_FEATURE_EARLY_FAIL_TPA_TASKS=27 OPERATOR_PORTAL_FEATURE_EARLY_FAIL_TPA_TASKS value
             * @property {number} OPERATOR_PORTAL_FEATURE_PLAN_NAME_XP=28 OPERATOR_PORTAL_FEATURE_PLAN_NAME_XP value
             * @property {number} OPERATOR_PORTAL_FEATURE_GENERIC_EBV=29 OPERATOR_PORTAL_FEATURE_GENERIC_EBV value
             * @property {number} OPERATOR_PORTAL_FEATURE_MULTIBENEFITS_ENABLED=30 OPERATOR_PORTAL_FEATURE_MULTIBENEFITS_ENABLED value
             * @property {number} OPERATOR_PORTAL_FEATURE_REPORT_CONTROL_CALL_REASON=31 OPERATOR_PORTAL_FEATURE_REPORT_CONTROL_CALL_REASON value
             * @property {number} OPERATOR_PORTAL_FEATURE_AUTO_REDIRECT_IN_HOLD=32 OPERATOR_PORTAL_FEATURE_AUTO_REDIRECT_IN_HOLD value
             * @property {number} OPERATOR_PORTAL_FEATURE_LOG_TRIGGERED_CUSTOMER_PIE=33 OPERATOR_PORTAL_FEATURE_LOG_TRIGGERED_CUSTOMER_PIE value
             * @property {number} OPERATOR_PORTAL_FEATURE_ROUTE_OPS_FROM_AUTODIALER=34 OPERATOR_PORTAL_FEATURE_ROUTE_OPS_FROM_AUTODIALER value
             * @property {number} OPERATOR_PORTAL_FEATURE_HIDE_ROUTE_OPERATOR_BUTTON=35 OPERATOR_PORTAL_FEATURE_HIDE_ROUTE_OPERATOR_BUTTON value
             * @property {number} OPERATOR_PORTAL_FEATURE_AUTO_REVIEW_ENABLED=36 OPERATOR_PORTAL_FEATURE_AUTO_REVIEW_ENABLED value
             * @property {number} OPERATOR_PORTAL_FEATURE_HIDE_FREE_TEXT_INPUT=37 OPERATOR_PORTAL_FEATURE_HIDE_FREE_TEXT_INPUT value
             * @property {number} OPERATOR_PORTAL_FEATURE_SHOW_TASKS_FOR_NON_ADMINS=38 OPERATOR_PORTAL_FEATURE_SHOW_TASKS_FOR_NON_ADMINS value
             */
            orgs.OperatorPortalFeature = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OPERATOR_PORTAL_FEATURE_UNKNOWN"] = 0;
                values[valuesById[5] = "OPERATOR_PORTAL_FEATURE_PROCESS_TASK"] = 5;
                values[valuesById[6] = "OPERATOR_PORTAL_FEATURE_SKIP_REVIEW"] = 6;
                values[valuesById[7] = "OPERATOR_PORTAL_FEATURE_SKIP_REVIEW_IGNORE_OPERATOR_CRITERIA"] = 7;
                values[valuesById[9] = "OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_INPUTS"] = 9;
                values[valuesById[10] = "OPERATOR_PORTAL_FEATURE_MULTITASK_ENABLED"] = 10;
                values[valuesById[11] = "OPERATOR_PORTAL_FEATURE_SUPPRESS_BEG_NOTIFICATIONS"] = 11;
                values[valuesById[12] = "OPERATOR_PORTAL_FEATURE_ADD_INPUTS_TO_OUTPUT_CSV"] = 12;
                values[valuesById[13] = "OPERATOR_PORTAL_FEATURE_FLEXIBLE_TASK_OUTPUTS"] = 13;
                values[valuesById[14] = "OPERATOR_PORTAL_FEATURE_RBAC"] = 14;
                values[valuesById[15] = "OPERATOR_PORTAL_FEATURE_AUTO_POPULATE_FROM_STAT_PIE"] = 15;
                values[valuesById[16] = "OPERATOR_PORTAL_FEATURE_PARTIAL_REVIEW"] = 16;
                values[valuesById[17] = "OPERATOR_PORTAL_FEATURE_SOCKET_IO"] = 17;
                values[valuesById[18] = "OPERATOR_PORTAL_FEATURE_PRIORITIZE_BLIZZARD_OVER_PROD_TASKS"] = 18;
                values[valuesById[19] = "OPERATOR_PORTAL_FEATURE_UNIFIED_QUEUE"] = 19;
                values[valuesById[20] = "OPERATOR_PORTAL_FEATURE_ML_IVR_TESTING"] = 20;
                values[valuesById[21] = "OPERATOR_PORTAL_FEATURE_INLINE_CALL_OUTPUT_VALIDATION"] = 21;
                values[valuesById[22] = "OPERATOR_PORTAL_FEATURE_ALWAYS_AUTODIAL_FIRST_CALL"] = 22;
                values[valuesById[23] = "OPERATOR_PORTAL_FEATURE_ALLOW_NEW_CALL_WITHOUT_PROCESSING"] = 23;
                values[valuesById[24] = "OPERATOR_PORTAL_FEATURE_ENFORCE_HUMAN_CALL_REASON"] = 24;
                values[valuesById[25] = "OPERATOR_PORTAL_FEATURE_USE_CONVO_BUILDER_2"] = 25;
                values[valuesById[26] = "OPERATOR_PORTAL_FEATURE_USE_NEW_PROCESS_CALL_FLOW"] = 26;
                values[valuesById[27] = "OPERATOR_PORTAL_FEATURE_EARLY_FAIL_TPA_TASKS"] = 27;
                values[valuesById[28] = "OPERATOR_PORTAL_FEATURE_PLAN_NAME_XP"] = 28;
                values[valuesById[29] = "OPERATOR_PORTAL_FEATURE_GENERIC_EBV"] = 29;
                values[valuesById[30] = "OPERATOR_PORTAL_FEATURE_MULTIBENEFITS_ENABLED"] = 30;
                values[valuesById[31] = "OPERATOR_PORTAL_FEATURE_REPORT_CONTROL_CALL_REASON"] = 31;
                values[valuesById[32] = "OPERATOR_PORTAL_FEATURE_AUTO_REDIRECT_IN_HOLD"] = 32;
                values[valuesById[33] = "OPERATOR_PORTAL_FEATURE_LOG_TRIGGERED_CUSTOMER_PIE"] = 33;
                values[valuesById[34] = "OPERATOR_PORTAL_FEATURE_ROUTE_OPS_FROM_AUTODIALER"] = 34;
                values[valuesById[35] = "OPERATOR_PORTAL_FEATURE_HIDE_ROUTE_OPERATOR_BUTTON"] = 35;
                values[valuesById[36] = "OPERATOR_PORTAL_FEATURE_AUTO_REVIEW_ENABLED"] = 36;
                values[valuesById[37] = "OPERATOR_PORTAL_FEATURE_HIDE_FREE_TEXT_INPUT"] = 37;
                values[valuesById[38] = "OPERATOR_PORTAL_FEATURE_SHOW_TASKS_FOR_NON_ADMINS"] = 38;
                return values;
            })();
    
            /**
             * ApiFeature enum.
             * @name infinitusai.orgs.ApiFeature
             * @enum {number}
             * @property {number} API_FEATURE_UNKNOWN=0 API_FEATURE_UNKNOWN value
             * @property {number} API_FEATURE_TASK_RECORDINGS_API=1 API_FEATURE_TASK_RECORDINGS_API value
             * @property {number} API_FEATURE_CANCEL_TASKS_API=2 API_FEATURE_CANCEL_TASKS_API value
             */
            orgs.ApiFeature = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "API_FEATURE_UNKNOWN"] = 0;
                values[valuesById[1] = "API_FEATURE_TASK_RECORDINGS_API"] = 1;
                values[valuesById[2] = "API_FEATURE_CANCEL_TASKS_API"] = 2;
                return values;
            })();
    
            orgs.GetBillingReportRequest = (function() {
    
                /**
                 * Properties of a GetBillingReportRequest.
                 * @memberof infinitusai.orgs
                 * @interface IGetBillingReportRequest
                 * @property {string|null} [month] GetBillingReportRequest month
                 * @property {string|null} [year] GetBillingReportRequest year
                 */
    
                /**
                 * Constructs a new GetBillingReportRequest.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents a GetBillingReportRequest.
                 * @implements IGetBillingReportRequest
                 * @constructor
                 * @param {infinitusai.orgs.IGetBillingReportRequest=} [properties] Properties to set
                 */
                function GetBillingReportRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBillingReportRequest month.
                 * @member {string} month
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @instance
                 */
                GetBillingReportRequest.prototype.month = "";
    
                /**
                 * GetBillingReportRequest year.
                 * @member {string} year
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @instance
                 */
                GetBillingReportRequest.prototype.year = "";
    
                /**
                 * Creates a new GetBillingReportRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {infinitusai.orgs.IGetBillingReportRequest=} [properties] Properties to set
                 * @returns {infinitusai.orgs.GetBillingReportRequest} GetBillingReportRequest instance
                 */
                GetBillingReportRequest.create = function create(properties) {
                    return new GetBillingReportRequest(properties);
                };
    
                /**
                 * Encodes the specified GetBillingReportRequest message. Does not implicitly {@link infinitusai.orgs.GetBillingReportRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {infinitusai.orgs.IGetBillingReportRequest} message GetBillingReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.month);
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.year);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBillingReportRequest message, length delimited. Does not implicitly {@link infinitusai.orgs.GetBillingReportRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {infinitusai.orgs.IGetBillingReportRequest} message GetBillingReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBillingReportRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.GetBillingReportRequest} GetBillingReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.GetBillingReportRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.month = reader.string();
                                break;
                            }
                        case 2: {
                                message.year = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBillingReportRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.GetBillingReportRequest} GetBillingReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBillingReportRequest message.
                 * @function verify
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingReportRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isString(message.month))
                            return "month: string expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isString(message.year))
                            return "year: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetBillingReportRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.GetBillingReportRequest} GetBillingReportRequest
                 */
                GetBillingReportRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.GetBillingReportRequest)
                        return object;
                    var message = new $root.infinitusai.orgs.GetBillingReportRequest();
                    if (object.month != null)
                        message.month = String(object.month);
                    if (object.year != null)
                        message.year = String(object.year);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBillingReportRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {infinitusai.orgs.GetBillingReportRequest} message GetBillingReportRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingReportRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.month = "";
                        object.year = "";
                    }
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    return object;
                };
    
                /**
                 * Converts this GetBillingReportRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingReportRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBillingReportRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.GetBillingReportRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBillingReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.GetBillingReportRequest";
                };
    
                return GetBillingReportRequest;
            })();
    
            orgs.GetBillingReportResponse = (function() {
    
                /**
                 * Properties of a GetBillingReportResponse.
                 * @memberof infinitusai.orgs
                 * @interface IGetBillingReportResponse
                 * @property {string|null} [id] GetBillingReportResponse id
                 * @property {string|null} [url] GetBillingReportResponse url
                 */
    
                /**
                 * Constructs a new GetBillingReportResponse.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents a GetBillingReportResponse.
                 * @implements IGetBillingReportResponse
                 * @constructor
                 * @param {infinitusai.orgs.IGetBillingReportResponse=} [properties] Properties to set
                 */
                function GetBillingReportResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetBillingReportResponse id.
                 * @member {string} id
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @instance
                 */
                GetBillingReportResponse.prototype.id = "";
    
                /**
                 * GetBillingReportResponse url.
                 * @member {string} url
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @instance
                 */
                GetBillingReportResponse.prototype.url = "";
    
                /**
                 * Creates a new GetBillingReportResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {infinitusai.orgs.IGetBillingReportResponse=} [properties] Properties to set
                 * @returns {infinitusai.orgs.GetBillingReportResponse} GetBillingReportResponse instance
                 */
                GetBillingReportResponse.create = function create(properties) {
                    return new GetBillingReportResponse(properties);
                };
    
                /**
                 * Encodes the specified GetBillingReportResponse message. Does not implicitly {@link infinitusai.orgs.GetBillingReportResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {infinitusai.orgs.IGetBillingReportResponse} message GetBillingReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetBillingReportResponse message, length delimited. Does not implicitly {@link infinitusai.orgs.GetBillingReportResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {infinitusai.orgs.IGetBillingReportResponse} message GetBillingReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBillingReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetBillingReportResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.GetBillingReportResponse} GetBillingReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.GetBillingReportResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.url = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetBillingReportResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.GetBillingReportResponse} GetBillingReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBillingReportResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetBillingReportResponse message.
                 * @function verify
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingReportResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetBillingReportResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.GetBillingReportResponse} GetBillingReportResponse
                 */
                GetBillingReportResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.GetBillingReportResponse)
                        return object;
                    var message = new $root.infinitusai.orgs.GetBillingReportResponse();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.url != null)
                        message.url = String(object.url);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetBillingReportResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {infinitusai.orgs.GetBillingReportResponse} message GetBillingReportResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingReportResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.url = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    return object;
                };
    
                /**
                 * Converts this GetBillingReportResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingReportResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetBillingReportResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.GetBillingReportResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetBillingReportResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.GetBillingReportResponse";
                };
    
                return GetBillingReportResponse;
            })();
    
            orgs.GetOrgRequest = (function() {
    
                /**
                 * Properties of a GetOrgRequest.
                 * @memberof infinitusai.orgs
                 * @interface IGetOrgRequest
                 */
    
                /**
                 * Constructs a new GetOrgRequest.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents a GetOrgRequest.
                 * @implements IGetOrgRequest
                 * @constructor
                 * @param {infinitusai.orgs.IGetOrgRequest=} [properties] Properties to set
                 */
                function GetOrgRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetOrgRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {infinitusai.orgs.IGetOrgRequest=} [properties] Properties to set
                 * @returns {infinitusai.orgs.GetOrgRequest} GetOrgRequest instance
                 */
                GetOrgRequest.create = function create(properties) {
                    return new GetOrgRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgRequest message. Does not implicitly {@link infinitusai.orgs.GetOrgRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {infinitusai.orgs.IGetOrgRequest} message GetOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgRequest message, length delimited. Does not implicitly {@link infinitusai.orgs.GetOrgRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {infinitusai.orgs.IGetOrgRequest} message GetOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.GetOrgRequest} GetOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.GetOrgRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.GetOrgRequest} GetOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgRequest message.
                 * @function verify
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetOrgRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.GetOrgRequest} GetOrgRequest
                 */
                GetOrgRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.GetOrgRequest)
                        return object;
                    return new $root.infinitusai.orgs.GetOrgRequest();
                };
    
                /**
                 * Creates a plain object from a GetOrgRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {infinitusai.orgs.GetOrgRequest} message GetOrgRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetOrgRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.GetOrgRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.GetOrgRequest";
                };
    
                return GetOrgRequest;
            })();
    
            orgs.GetOrgResponse = (function() {
    
                /**
                 * Properties of a GetOrgResponse.
                 * @memberof infinitusai.orgs
                 * @interface IGetOrgResponse
                 * @property {infinitusai.orgs.IOrg|null} [org] GetOrgResponse org
                 */
    
                /**
                 * Constructs a new GetOrgResponse.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents a GetOrgResponse.
                 * @implements IGetOrgResponse
                 * @constructor
                 * @param {infinitusai.orgs.IGetOrgResponse=} [properties] Properties to set
                 */
                function GetOrgResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgResponse org.
                 * @member {infinitusai.orgs.IOrg|null|undefined} org
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @instance
                 */
                GetOrgResponse.prototype.org = null;
    
                /**
                 * Creates a new GetOrgResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {infinitusai.orgs.IGetOrgResponse=} [properties] Properties to set
                 * @returns {infinitusai.orgs.GetOrgResponse} GetOrgResponse instance
                 */
                GetOrgResponse.create = function create(properties) {
                    return new GetOrgResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgResponse message. Does not implicitly {@link infinitusai.orgs.GetOrgResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {infinitusai.orgs.IGetOrgResponse} message GetOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.org != null && Object.hasOwnProperty.call(message, "org"))
                        $root.infinitusai.orgs.Org.encode(message.org, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgResponse message, length delimited. Does not implicitly {@link infinitusai.orgs.GetOrgResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {infinitusai.orgs.IGetOrgResponse} message GetOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.GetOrgResponse} GetOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.GetOrgResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.org = $root.infinitusai.orgs.Org.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.GetOrgResponse} GetOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgResponse message.
                 * @function verify
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.org != null && message.hasOwnProperty("org")) {
                        var error = $root.infinitusai.orgs.Org.verify(message.org);
                        if (error)
                            return "org." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.GetOrgResponse} GetOrgResponse
                 */
                GetOrgResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.GetOrgResponse)
                        return object;
                    var message = new $root.infinitusai.orgs.GetOrgResponse();
                    if (object.org != null) {
                        if (typeof object.org !== "object")
                            throw TypeError(".infinitusai.orgs.GetOrgResponse.org: object expected");
                        message.org = $root.infinitusai.orgs.Org.fromObject(object.org);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {infinitusai.orgs.GetOrgResponse} message GetOrgResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.org = null;
                    if (message.org != null && message.hasOwnProperty("org"))
                        object.org = $root.infinitusai.orgs.Org.toObject(message.org, options);
                    return object;
                };
    
                /**
                 * Converts this GetOrgResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.GetOrgResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.GetOrgResponse";
                };
    
                return GetOrgResponse;
            })();
    
            orgs.UpdateOrgRequest = (function() {
    
                /**
                 * Properties of an UpdateOrgRequest.
                 * @memberof infinitusai.orgs
                 * @interface IUpdateOrgRequest
                 * @property {string|null} [orgImageUrl] UpdateOrgRequest orgImageUrl
                 * @property {Array.<string>|null} [orgEmailDomains] UpdateOrgRequest orgEmailDomains
                 * @property {string|null} [orgDisplayName] UpdateOrgRequest orgDisplayName
                 * @property {string|null} [orgSupportEmail] UpdateOrgRequest orgSupportEmail
                 * @property {Array.<string>|null} [orgWhitelistedIpAddresses] UpdateOrgRequest orgWhitelistedIpAddresses
                 */
    
                /**
                 * Constructs a new UpdateOrgRequest.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents an UpdateOrgRequest.
                 * @implements IUpdateOrgRequest
                 * @constructor
                 * @param {infinitusai.orgs.IUpdateOrgRequest=} [properties] Properties to set
                 */
                function UpdateOrgRequest(properties) {
                    this.orgEmailDomains = [];
                    this.orgWhitelistedIpAddresses = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateOrgRequest orgImageUrl.
                 * @member {string} orgImageUrl
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @instance
                 */
                UpdateOrgRequest.prototype.orgImageUrl = "";
    
                /**
                 * UpdateOrgRequest orgEmailDomains.
                 * @member {Array.<string>} orgEmailDomains
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @instance
                 */
                UpdateOrgRequest.prototype.orgEmailDomains = $util.emptyArray;
    
                /**
                 * UpdateOrgRequest orgDisplayName.
                 * @member {string} orgDisplayName
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @instance
                 */
                UpdateOrgRequest.prototype.orgDisplayName = "";
    
                /**
                 * UpdateOrgRequest orgSupportEmail.
                 * @member {string} orgSupportEmail
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @instance
                 */
                UpdateOrgRequest.prototype.orgSupportEmail = "";
    
                /**
                 * UpdateOrgRequest orgWhitelistedIpAddresses.
                 * @member {Array.<string>} orgWhitelistedIpAddresses
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @instance
                 */
                UpdateOrgRequest.prototype.orgWhitelistedIpAddresses = $util.emptyArray;
    
                /**
                 * Creates a new UpdateOrgRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {infinitusai.orgs.IUpdateOrgRequest=} [properties] Properties to set
                 * @returns {infinitusai.orgs.UpdateOrgRequest} UpdateOrgRequest instance
                 */
                UpdateOrgRequest.create = function create(properties) {
                    return new UpdateOrgRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateOrgRequest message. Does not implicitly {@link infinitusai.orgs.UpdateOrgRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {infinitusai.orgs.IUpdateOrgRequest} message UpdateOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateOrgRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgImageUrl != null && Object.hasOwnProperty.call(message, "orgImageUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgImageUrl);
                    if (message.orgEmailDomains != null && message.orgEmailDomains.length)
                        for (var i = 0; i < message.orgEmailDomains.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.orgEmailDomains[i]);
                    if (message.orgDisplayName != null && Object.hasOwnProperty.call(message, "orgDisplayName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgDisplayName);
                    if (message.orgSupportEmail != null && Object.hasOwnProperty.call(message, "orgSupportEmail"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.orgSupportEmail);
                    if (message.orgWhitelistedIpAddresses != null && message.orgWhitelistedIpAddresses.length)
                        for (var i = 0; i < message.orgWhitelistedIpAddresses.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.orgWhitelistedIpAddresses[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateOrgRequest message, length delimited. Does not implicitly {@link infinitusai.orgs.UpdateOrgRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {infinitusai.orgs.IUpdateOrgRequest} message UpdateOrgRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateOrgRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateOrgRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.UpdateOrgRequest} UpdateOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateOrgRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.UpdateOrgRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgImageUrl = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.orgEmailDomains && message.orgEmailDomains.length))
                                    message.orgEmailDomains = [];
                                message.orgEmailDomains.push(reader.string());
                                break;
                            }
                        case 3: {
                                message.orgDisplayName = reader.string();
                                break;
                            }
                        case 4: {
                                message.orgSupportEmail = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.orgWhitelistedIpAddresses && message.orgWhitelistedIpAddresses.length))
                                    message.orgWhitelistedIpAddresses = [];
                                message.orgWhitelistedIpAddresses.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateOrgRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.UpdateOrgRequest} UpdateOrgRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateOrgRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateOrgRequest message.
                 * @function verify
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateOrgRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgImageUrl != null && message.hasOwnProperty("orgImageUrl"))
                        if (!$util.isString(message.orgImageUrl))
                            return "orgImageUrl: string expected";
                    if (message.orgEmailDomains != null && message.hasOwnProperty("orgEmailDomains")) {
                        if (!Array.isArray(message.orgEmailDomains))
                            return "orgEmailDomains: array expected";
                        for (var i = 0; i < message.orgEmailDomains.length; ++i)
                            if (!$util.isString(message.orgEmailDomains[i]))
                                return "orgEmailDomains: string[] expected";
                    }
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        if (!$util.isString(message.orgDisplayName))
                            return "orgDisplayName: string expected";
                    if (message.orgSupportEmail != null && message.hasOwnProperty("orgSupportEmail"))
                        if (!$util.isString(message.orgSupportEmail))
                            return "orgSupportEmail: string expected";
                    if (message.orgWhitelistedIpAddresses != null && message.hasOwnProperty("orgWhitelistedIpAddresses")) {
                        if (!Array.isArray(message.orgWhitelistedIpAddresses))
                            return "orgWhitelistedIpAddresses: array expected";
                        for (var i = 0; i < message.orgWhitelistedIpAddresses.length; ++i)
                            if (!$util.isString(message.orgWhitelistedIpAddresses[i]))
                                return "orgWhitelistedIpAddresses: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateOrgRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.UpdateOrgRequest} UpdateOrgRequest
                 */
                UpdateOrgRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.UpdateOrgRequest)
                        return object;
                    var message = new $root.infinitusai.orgs.UpdateOrgRequest();
                    if (object.orgImageUrl != null)
                        message.orgImageUrl = String(object.orgImageUrl);
                    if (object.orgEmailDomains) {
                        if (!Array.isArray(object.orgEmailDomains))
                            throw TypeError(".infinitusai.orgs.UpdateOrgRequest.orgEmailDomains: array expected");
                        message.orgEmailDomains = [];
                        for (var i = 0; i < object.orgEmailDomains.length; ++i)
                            message.orgEmailDomains[i] = String(object.orgEmailDomains[i]);
                    }
                    if (object.orgDisplayName != null)
                        message.orgDisplayName = String(object.orgDisplayName);
                    if (object.orgSupportEmail != null)
                        message.orgSupportEmail = String(object.orgSupportEmail);
                    if (object.orgWhitelistedIpAddresses) {
                        if (!Array.isArray(object.orgWhitelistedIpAddresses))
                            throw TypeError(".infinitusai.orgs.UpdateOrgRequest.orgWhitelistedIpAddresses: array expected");
                        message.orgWhitelistedIpAddresses = [];
                        for (var i = 0; i < object.orgWhitelistedIpAddresses.length; ++i)
                            message.orgWhitelistedIpAddresses[i] = String(object.orgWhitelistedIpAddresses[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateOrgRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {infinitusai.orgs.UpdateOrgRequest} message UpdateOrgRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateOrgRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.orgEmailDomains = [];
                        object.orgWhitelistedIpAddresses = [];
                    }
                    if (options.defaults) {
                        object.orgImageUrl = "";
                        object.orgDisplayName = "";
                        object.orgSupportEmail = "";
                    }
                    if (message.orgImageUrl != null && message.hasOwnProperty("orgImageUrl"))
                        object.orgImageUrl = message.orgImageUrl;
                    if (message.orgEmailDomains && message.orgEmailDomains.length) {
                        object.orgEmailDomains = [];
                        for (var j = 0; j < message.orgEmailDomains.length; ++j)
                            object.orgEmailDomains[j] = message.orgEmailDomains[j];
                    }
                    if (message.orgDisplayName != null && message.hasOwnProperty("orgDisplayName"))
                        object.orgDisplayName = message.orgDisplayName;
                    if (message.orgSupportEmail != null && message.hasOwnProperty("orgSupportEmail"))
                        object.orgSupportEmail = message.orgSupportEmail;
                    if (message.orgWhitelistedIpAddresses && message.orgWhitelistedIpAddresses.length) {
                        object.orgWhitelistedIpAddresses = [];
                        for (var j = 0; j < message.orgWhitelistedIpAddresses.length; ++j)
                            object.orgWhitelistedIpAddresses[j] = message.orgWhitelistedIpAddresses[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this UpdateOrgRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateOrgRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateOrgRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.UpdateOrgRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateOrgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.UpdateOrgRequest";
                };
    
                return UpdateOrgRequest;
            })();
    
            orgs.UpdateOrgResponse = (function() {
    
                /**
                 * Properties of an UpdateOrgResponse.
                 * @memberof infinitusai.orgs
                 * @interface IUpdateOrgResponse
                 * @property {infinitusai.orgs.IOrg|null} [org] UpdateOrgResponse org
                 */
    
                /**
                 * Constructs a new UpdateOrgResponse.
                 * @memberof infinitusai.orgs
                 * @classdesc Represents an UpdateOrgResponse.
                 * @implements IUpdateOrgResponse
                 * @constructor
                 * @param {infinitusai.orgs.IUpdateOrgResponse=} [properties] Properties to set
                 */
                function UpdateOrgResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateOrgResponse org.
                 * @member {infinitusai.orgs.IOrg|null|undefined} org
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @instance
                 */
                UpdateOrgResponse.prototype.org = null;
    
                /**
                 * Creates a new UpdateOrgResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {infinitusai.orgs.IUpdateOrgResponse=} [properties] Properties to set
                 * @returns {infinitusai.orgs.UpdateOrgResponse} UpdateOrgResponse instance
                 */
                UpdateOrgResponse.create = function create(properties) {
                    return new UpdateOrgResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateOrgResponse message. Does not implicitly {@link infinitusai.orgs.UpdateOrgResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {infinitusai.orgs.IUpdateOrgResponse} message UpdateOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateOrgResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.org != null && Object.hasOwnProperty.call(message, "org"))
                        $root.infinitusai.orgs.Org.encode(message.org, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateOrgResponse message, length delimited. Does not implicitly {@link infinitusai.orgs.UpdateOrgResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {infinitusai.orgs.IUpdateOrgResponse} message UpdateOrgResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateOrgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateOrgResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.orgs.UpdateOrgResponse} UpdateOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateOrgResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.orgs.UpdateOrgResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.org = $root.infinitusai.orgs.Org.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateOrgResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.orgs.UpdateOrgResponse} UpdateOrgResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateOrgResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateOrgResponse message.
                 * @function verify
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateOrgResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.org != null && message.hasOwnProperty("org")) {
                        var error = $root.infinitusai.orgs.Org.verify(message.org);
                        if (error)
                            return "org." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateOrgResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.orgs.UpdateOrgResponse} UpdateOrgResponse
                 */
                UpdateOrgResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.orgs.UpdateOrgResponse)
                        return object;
                    var message = new $root.infinitusai.orgs.UpdateOrgResponse();
                    if (object.org != null) {
                        if (typeof object.org !== "object")
                            throw TypeError(".infinitusai.orgs.UpdateOrgResponse.org: object expected");
                        message.org = $root.infinitusai.orgs.Org.fromObject(object.org);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateOrgResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {infinitusai.orgs.UpdateOrgResponse} message UpdateOrgResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateOrgResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.org = null;
                    if (message.org != null && message.hasOwnProperty("org"))
                        object.org = $root.infinitusai.orgs.Org.toObject(message.org, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateOrgResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateOrgResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateOrgResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.orgs.UpdateOrgResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateOrgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.orgs.UpdateOrgResponse";
                };
    
                return UpdateOrgResponse;
            })();
    
            return orgs;
        })();
    
        infinitusai.docs = (function() {
    
            /**
             * Namespace docs.
             * @memberof infinitusai
             * @namespace
             */
            var docs = {};
    
            docs.GetDocsRequest = (function() {
    
                /**
                 * Properties of a GetDocsRequest.
                 * @memberof infinitusai.docs
                 * @interface IGetDocsRequest
                 */
    
                /**
                 * Constructs a new GetDocsRequest.
                 * @memberof infinitusai.docs
                 * @classdesc Represents a GetDocsRequest.
                 * @implements IGetDocsRequest
                 * @constructor
                 * @param {infinitusai.docs.IGetDocsRequest=} [properties] Properties to set
                 */
                function GetDocsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetDocsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {infinitusai.docs.IGetDocsRequest=} [properties] Properties to set
                 * @returns {infinitusai.docs.GetDocsRequest} GetDocsRequest instance
                 */
                GetDocsRequest.create = function create(properties) {
                    return new GetDocsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetDocsRequest message. Does not implicitly {@link infinitusai.docs.GetDocsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {infinitusai.docs.IGetDocsRequest} message GetDocsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDocsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetDocsRequest message, length delimited. Does not implicitly {@link infinitusai.docs.GetDocsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {infinitusai.docs.IGetDocsRequest} message GetDocsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDocsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetDocsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.docs.GetDocsRequest} GetDocsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDocsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.docs.GetDocsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetDocsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.docs.GetDocsRequest} GetDocsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDocsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetDocsRequest message.
                 * @function verify
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDocsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetDocsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.docs.GetDocsRequest} GetDocsRequest
                 */
                GetDocsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.docs.GetDocsRequest)
                        return object;
                    return new $root.infinitusai.docs.GetDocsRequest();
                };
    
                /**
                 * Creates a plain object from a GetDocsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {infinitusai.docs.GetDocsRequest} message GetDocsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDocsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetDocsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDocsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetDocsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.docs.GetDocsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetDocsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.docs.GetDocsRequest";
                };
    
                return GetDocsRequest;
            })();
    
            docs.GetDocsResponse = (function() {
    
                /**
                 * Properties of a GetDocsResponse.
                 * @memberof infinitusai.docs
                 * @interface IGetDocsResponse
                 * @property {string|null} [docsUrl] GetDocsResponse docsUrl
                 */
    
                /**
                 * Constructs a new GetDocsResponse.
                 * @memberof infinitusai.docs
                 * @classdesc Represents a GetDocsResponse.
                 * @implements IGetDocsResponse
                 * @constructor
                 * @param {infinitusai.docs.IGetDocsResponse=} [properties] Properties to set
                 */
                function GetDocsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetDocsResponse docsUrl.
                 * @member {string} docsUrl
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @instance
                 */
                GetDocsResponse.prototype.docsUrl = "";
    
                /**
                 * Creates a new GetDocsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {infinitusai.docs.IGetDocsResponse=} [properties] Properties to set
                 * @returns {infinitusai.docs.GetDocsResponse} GetDocsResponse instance
                 */
                GetDocsResponse.create = function create(properties) {
                    return new GetDocsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetDocsResponse message. Does not implicitly {@link infinitusai.docs.GetDocsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {infinitusai.docs.IGetDocsResponse} message GetDocsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDocsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.docsUrl != null && Object.hasOwnProperty.call(message, "docsUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.docsUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetDocsResponse message, length delimited. Does not implicitly {@link infinitusai.docs.GetDocsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {infinitusai.docs.IGetDocsResponse} message GetDocsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDocsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetDocsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.docs.GetDocsResponse} GetDocsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDocsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.docs.GetDocsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.docsUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetDocsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.docs.GetDocsResponse} GetDocsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDocsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetDocsResponse message.
                 * @function verify
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDocsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.docsUrl != null && message.hasOwnProperty("docsUrl"))
                        if (!$util.isString(message.docsUrl))
                            return "docsUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetDocsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.docs.GetDocsResponse} GetDocsResponse
                 */
                GetDocsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.docs.GetDocsResponse)
                        return object;
                    var message = new $root.infinitusai.docs.GetDocsResponse();
                    if (object.docsUrl != null)
                        message.docsUrl = String(object.docsUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetDocsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {infinitusai.docs.GetDocsResponse} message GetDocsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDocsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.docsUrl = "";
                    if (message.docsUrl != null && message.hasOwnProperty("docsUrl"))
                        object.docsUrl = message.docsUrl;
                    return object;
                };
    
                /**
                 * Converts this GetDocsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDocsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetDocsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.docs.GetDocsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetDocsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.docs.GetDocsResponse";
                };
    
                return GetDocsResponse;
            })();
    
            return docs;
        })();
    
        infinitusai.teams = (function() {
    
            /**
             * Namespace teams.
             * @memberof infinitusai
             * @namespace
             */
            var teams = {};
    
            teams.CreateTeamRequest = (function() {
    
                /**
                 * Properties of a CreateTeamRequest.
                 * @memberof infinitusai.teams
                 * @interface ICreateTeamRequest
                 * @property {string|null} [name] CreateTeamRequest name
                 * @property {Array.<string>|null} [memberEmails] CreateTeamRequest memberEmails
                 */
    
                /**
                 * Constructs a new CreateTeamRequest.
                 * @memberof infinitusai.teams
                 * @classdesc CreateTeamRequest is used to create a new team
                 * we will be getting the org_uuid from the request headers
                 * constraints:
                 * - name: must be unique for this org
                 * @implements ICreateTeamRequest
                 * @constructor
                 * @param {infinitusai.teams.ICreateTeamRequest=} [properties] Properties to set
                 */
                function CreateTeamRequest(properties) {
                    this.memberEmails = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateTeamRequest name.
                 * @member {string} name
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @instance
                 */
                CreateTeamRequest.prototype.name = "";
    
                /**
                 * CreateTeamRequest memberEmails.
                 * @member {Array.<string>} memberEmails
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @instance
                 */
                CreateTeamRequest.prototype.memberEmails = $util.emptyArray;
    
                /**
                 * Creates a new CreateTeamRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {infinitusai.teams.ICreateTeamRequest=} [properties] Properties to set
                 * @returns {infinitusai.teams.CreateTeamRequest} CreateTeamRequest instance
                 */
                CreateTeamRequest.create = function create(properties) {
                    return new CreateTeamRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateTeamRequest message. Does not implicitly {@link infinitusai.teams.CreateTeamRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {infinitusai.teams.ICreateTeamRequest} message CreateTeamRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTeamRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.memberEmails != null && message.memberEmails.length)
                        for (var i = 0; i < message.memberEmails.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.memberEmails[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateTeamRequest message, length delimited. Does not implicitly {@link infinitusai.teams.CreateTeamRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {infinitusai.teams.ICreateTeamRequest} message CreateTeamRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTeamRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateTeamRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.CreateTeamRequest} CreateTeamRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTeamRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.CreateTeamRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.memberEmails && message.memberEmails.length))
                                    message.memberEmails = [];
                                message.memberEmails.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateTeamRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.CreateTeamRequest} CreateTeamRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTeamRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateTeamRequest message.
                 * @function verify
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTeamRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.memberEmails != null && message.hasOwnProperty("memberEmails")) {
                        if (!Array.isArray(message.memberEmails))
                            return "memberEmails: array expected";
                        for (var i = 0; i < message.memberEmails.length; ++i)
                            if (!$util.isString(message.memberEmails[i]))
                                return "memberEmails: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateTeamRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.CreateTeamRequest} CreateTeamRequest
                 */
                CreateTeamRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.CreateTeamRequest)
                        return object;
                    var message = new $root.infinitusai.teams.CreateTeamRequest();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.memberEmails) {
                        if (!Array.isArray(object.memberEmails))
                            throw TypeError(".infinitusai.teams.CreateTeamRequest.memberEmails: array expected");
                        message.memberEmails = [];
                        for (var i = 0; i < object.memberEmails.length; ++i)
                            message.memberEmails[i] = String(object.memberEmails[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateTeamRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {infinitusai.teams.CreateTeamRequest} message CreateTeamRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTeamRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.memberEmails = [];
                    if (options.defaults)
                        object.name = "";
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.memberEmails && message.memberEmails.length) {
                        object.memberEmails = [];
                        for (var j = 0; j < message.memberEmails.length; ++j)
                            object.memberEmails[j] = message.memberEmails[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this CreateTeamRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTeamRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateTeamRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.CreateTeamRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateTeamRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.CreateTeamRequest";
                };
    
                return CreateTeamRequest;
            })();
    
            teams.CreateTeamResponse = (function() {
    
                /**
                 * Properties of a CreateTeamResponse.
                 * @memberof infinitusai.teams
                 * @interface ICreateTeamResponse
                 * @property {string|null} [teamUuid] CreateTeamResponse teamUuid
                 * @property {string|null} [name] CreateTeamResponse name
                 * @property {Array.<string>|null} [memberEmails] CreateTeamResponse memberEmails
                 */
    
                /**
                 * Constructs a new CreateTeamResponse.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a CreateTeamResponse.
                 * @implements ICreateTeamResponse
                 * @constructor
                 * @param {infinitusai.teams.ICreateTeamResponse=} [properties] Properties to set
                 */
                function CreateTeamResponse(properties) {
                    this.memberEmails = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateTeamResponse teamUuid.
                 * @member {string} teamUuid
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @instance
                 */
                CreateTeamResponse.prototype.teamUuid = "";
    
                /**
                 * CreateTeamResponse name.
                 * @member {string} name
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @instance
                 */
                CreateTeamResponse.prototype.name = "";
    
                /**
                 * CreateTeamResponse memberEmails.
                 * @member {Array.<string>} memberEmails
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @instance
                 */
                CreateTeamResponse.prototype.memberEmails = $util.emptyArray;
    
                /**
                 * Creates a new CreateTeamResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {infinitusai.teams.ICreateTeamResponse=} [properties] Properties to set
                 * @returns {infinitusai.teams.CreateTeamResponse} CreateTeamResponse instance
                 */
                CreateTeamResponse.create = function create(properties) {
                    return new CreateTeamResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateTeamResponse message. Does not implicitly {@link infinitusai.teams.CreateTeamResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {infinitusai.teams.ICreateTeamResponse} message CreateTeamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTeamResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teamUuid != null && Object.hasOwnProperty.call(message, "teamUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamUuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.memberEmails != null && message.memberEmails.length)
                        for (var i = 0; i < message.memberEmails.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.memberEmails[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateTeamResponse message, length delimited. Does not implicitly {@link infinitusai.teams.CreateTeamResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {infinitusai.teams.ICreateTeamResponse} message CreateTeamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTeamResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateTeamResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.CreateTeamResponse} CreateTeamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTeamResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.CreateTeamResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teamUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.memberEmails && message.memberEmails.length))
                                    message.memberEmails = [];
                                message.memberEmails.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateTeamResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.CreateTeamResponse} CreateTeamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTeamResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateTeamResponse message.
                 * @function verify
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTeamResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        if (!$util.isString(message.teamUuid))
                            return "teamUuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.memberEmails != null && message.hasOwnProperty("memberEmails")) {
                        if (!Array.isArray(message.memberEmails))
                            return "memberEmails: array expected";
                        for (var i = 0; i < message.memberEmails.length; ++i)
                            if (!$util.isString(message.memberEmails[i]))
                                return "memberEmails: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateTeamResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.CreateTeamResponse} CreateTeamResponse
                 */
                CreateTeamResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.CreateTeamResponse)
                        return object;
                    var message = new $root.infinitusai.teams.CreateTeamResponse();
                    if (object.teamUuid != null)
                        message.teamUuid = String(object.teamUuid);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.memberEmails) {
                        if (!Array.isArray(object.memberEmails))
                            throw TypeError(".infinitusai.teams.CreateTeamResponse.memberEmails: array expected");
                        message.memberEmails = [];
                        for (var i = 0; i < object.memberEmails.length; ++i)
                            message.memberEmails[i] = String(object.memberEmails[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateTeamResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {infinitusai.teams.CreateTeamResponse} message CreateTeamResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTeamResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.memberEmails = [];
                    if (options.defaults) {
                        object.teamUuid = "";
                        object.name = "";
                    }
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        object.teamUuid = message.teamUuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.memberEmails && message.memberEmails.length) {
                        object.memberEmails = [];
                        for (var j = 0; j < message.memberEmails.length; ++j)
                            object.memberEmails[j] = message.memberEmails[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this CreateTeamResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTeamResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateTeamResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.CreateTeamResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateTeamResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.CreateTeamResponse";
                };
    
                return CreateTeamResponse;
            })();
    
            teams.GetTeamsRequest = (function() {
    
                /**
                 * Properties of a GetTeamsRequest.
                 * @memberof infinitusai.teams
                 * @interface IGetTeamsRequest
                 */
    
                /**
                 * Constructs a new GetTeamsRequest.
                 * @memberof infinitusai.teams
                 * @classdesc GetTeamsRequest is used to get all teams for an org
                 * we use the orgUUID from the auth header
                 * @implements IGetTeamsRequest
                 * @constructor
                 * @param {infinitusai.teams.IGetTeamsRequest=} [properties] Properties to set
                 */
                function GetTeamsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetTeamsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {infinitusai.teams.IGetTeamsRequest=} [properties] Properties to set
                 * @returns {infinitusai.teams.GetTeamsRequest} GetTeamsRequest instance
                 */
                GetTeamsRequest.create = function create(properties) {
                    return new GetTeamsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTeamsRequest message. Does not implicitly {@link infinitusai.teams.GetTeamsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {infinitusai.teams.IGetTeamsRequest} message GetTeamsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTeamsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTeamsRequest message, length delimited. Does not implicitly {@link infinitusai.teams.GetTeamsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {infinitusai.teams.IGetTeamsRequest} message GetTeamsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTeamsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTeamsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.GetTeamsRequest} GetTeamsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTeamsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.GetTeamsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTeamsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.GetTeamsRequest} GetTeamsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTeamsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTeamsRequest message.
                 * @function verify
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTeamsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetTeamsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.GetTeamsRequest} GetTeamsRequest
                 */
                GetTeamsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.GetTeamsRequest)
                        return object;
                    return new $root.infinitusai.teams.GetTeamsRequest();
                };
    
                /**
                 * Creates a plain object from a GetTeamsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {infinitusai.teams.GetTeamsRequest} message GetTeamsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTeamsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetTeamsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTeamsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTeamsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.GetTeamsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTeamsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.GetTeamsRequest";
                };
    
                return GetTeamsRequest;
            })();
    
            teams.GetTeamsResponse = (function() {
    
                /**
                 * Properties of a GetTeamsResponse.
                 * @memberof infinitusai.teams
                 * @interface IGetTeamsResponse
                 * @property {Array.<infinitusai.teams.ITeam>|null} [teams] GetTeamsResponse teams
                 */
    
                /**
                 * Constructs a new GetTeamsResponse.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a GetTeamsResponse.
                 * @implements IGetTeamsResponse
                 * @constructor
                 * @param {infinitusai.teams.IGetTeamsResponse=} [properties] Properties to set
                 */
                function GetTeamsResponse(properties) {
                    this.teams = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTeamsResponse teams.
                 * @member {Array.<infinitusai.teams.ITeam>} teams
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @instance
                 */
                GetTeamsResponse.prototype.teams = $util.emptyArray;
    
                /**
                 * Creates a new GetTeamsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {infinitusai.teams.IGetTeamsResponse=} [properties] Properties to set
                 * @returns {infinitusai.teams.GetTeamsResponse} GetTeamsResponse instance
                 */
                GetTeamsResponse.create = function create(properties) {
                    return new GetTeamsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetTeamsResponse message. Does not implicitly {@link infinitusai.teams.GetTeamsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {infinitusai.teams.IGetTeamsResponse} message GetTeamsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTeamsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teams != null && message.teams.length)
                        for (var i = 0; i < message.teams.length; ++i)
                            $root.infinitusai.teams.Team.encode(message.teams[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTeamsResponse message, length delimited. Does not implicitly {@link infinitusai.teams.GetTeamsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {infinitusai.teams.IGetTeamsResponse} message GetTeamsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTeamsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTeamsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.GetTeamsResponse} GetTeamsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTeamsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.GetTeamsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.teams && message.teams.length))
                                    message.teams = [];
                                message.teams.push($root.infinitusai.teams.Team.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTeamsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.GetTeamsResponse} GetTeamsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTeamsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTeamsResponse message.
                 * @function verify
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTeamsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teams != null && message.hasOwnProperty("teams")) {
                        if (!Array.isArray(message.teams))
                            return "teams: array expected";
                        for (var i = 0; i < message.teams.length; ++i) {
                            var error = $root.infinitusai.teams.Team.verify(message.teams[i]);
                            if (error)
                                return "teams." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetTeamsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.GetTeamsResponse} GetTeamsResponse
                 */
                GetTeamsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.GetTeamsResponse)
                        return object;
                    var message = new $root.infinitusai.teams.GetTeamsResponse();
                    if (object.teams) {
                        if (!Array.isArray(object.teams))
                            throw TypeError(".infinitusai.teams.GetTeamsResponse.teams: array expected");
                        message.teams = [];
                        for (var i = 0; i < object.teams.length; ++i) {
                            if (typeof object.teams[i] !== "object")
                                throw TypeError(".infinitusai.teams.GetTeamsResponse.teams: object expected");
                            message.teams[i] = $root.infinitusai.teams.Team.fromObject(object.teams[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTeamsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {infinitusai.teams.GetTeamsResponse} message GetTeamsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTeamsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.teams = [];
                    if (message.teams && message.teams.length) {
                        object.teams = [];
                        for (var j = 0; j < message.teams.length; ++j)
                            object.teams[j] = $root.infinitusai.teams.Team.toObject(message.teams[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetTeamsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTeamsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTeamsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.GetTeamsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTeamsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.GetTeamsResponse";
                };
    
                return GetTeamsResponse;
            })();
    
            teams.Team = (function() {
    
                /**
                 * Properties of a Team.
                 * @memberof infinitusai.teams
                 * @interface ITeam
                 * @property {string|null} [teamUuid] Team teamUuid
                 * @property {string|null} [name] Team name
                 * @property {Array.<infinitusai.teams.IMember>|null} [members] Team members
                 * @property {number|Long|null} [memberCount] Team memberCount
                 */
    
                /**
                 * Constructs a new Team.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a Team.
                 * @implements ITeam
                 * @constructor
                 * @param {infinitusai.teams.ITeam=} [properties] Properties to set
                 */
                function Team(properties) {
                    this.members = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Team teamUuid.
                 * @member {string} teamUuid
                 * @memberof infinitusai.teams.Team
                 * @instance
                 */
                Team.prototype.teamUuid = "";
    
                /**
                 * Team name.
                 * @member {string} name
                 * @memberof infinitusai.teams.Team
                 * @instance
                 */
                Team.prototype.name = "";
    
                /**
                 * Team members.
                 * @member {Array.<infinitusai.teams.IMember>} members
                 * @memberof infinitusai.teams.Team
                 * @instance
                 */
                Team.prototype.members = $util.emptyArray;
    
                /**
                 * Team memberCount.
                 * @member {number|Long} memberCount
                 * @memberof infinitusai.teams.Team
                 * @instance
                 */
                Team.prototype.memberCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new Team instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {infinitusai.teams.ITeam=} [properties] Properties to set
                 * @returns {infinitusai.teams.Team} Team instance
                 */
                Team.create = function create(properties) {
                    return new Team(properties);
                };
    
                /**
                 * Encodes the specified Team message. Does not implicitly {@link infinitusai.teams.Team.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {infinitusai.teams.ITeam} message Team message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Team.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teamUuid != null && Object.hasOwnProperty.call(message, "teamUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamUuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.members != null && message.members.length)
                        for (var i = 0; i < message.members.length; ++i)
                            $root.infinitusai.teams.Member.encode(message.members[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.memberCount != null && Object.hasOwnProperty.call(message, "memberCount"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.memberCount);
                    return writer;
                };
    
                /**
                 * Encodes the specified Team message, length delimited. Does not implicitly {@link infinitusai.teams.Team.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {infinitusai.teams.ITeam} message Team message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Team.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Team message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.Team} Team
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Team.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.Team();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teamUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.members && message.members.length))
                                    message.members = [];
                                message.members.push($root.infinitusai.teams.Member.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.memberCount = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Team message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.Team} Team
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Team.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Team message.
                 * @function verify
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Team.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        if (!$util.isString(message.teamUuid))
                            return "teamUuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.members != null && message.hasOwnProperty("members")) {
                        if (!Array.isArray(message.members))
                            return "members: array expected";
                        for (var i = 0; i < message.members.length; ++i) {
                            var error = $root.infinitusai.teams.Member.verify(message.members[i]);
                            if (error)
                                return "members." + error;
                        }
                    }
                    if (message.memberCount != null && message.hasOwnProperty("memberCount"))
                        if (!$util.isInteger(message.memberCount) && !(message.memberCount && $util.isInteger(message.memberCount.low) && $util.isInteger(message.memberCount.high)))
                            return "memberCount: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a Team message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.Team} Team
                 */
                Team.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.Team)
                        return object;
                    var message = new $root.infinitusai.teams.Team();
                    if (object.teamUuid != null)
                        message.teamUuid = String(object.teamUuid);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.members) {
                        if (!Array.isArray(object.members))
                            throw TypeError(".infinitusai.teams.Team.members: array expected");
                        message.members = [];
                        for (var i = 0; i < object.members.length; ++i) {
                            if (typeof object.members[i] !== "object")
                                throw TypeError(".infinitusai.teams.Team.members: object expected");
                            message.members[i] = $root.infinitusai.teams.Member.fromObject(object.members[i]);
                        }
                    }
                    if (object.memberCount != null)
                        if ($util.Long)
                            (message.memberCount = $util.Long.fromValue(object.memberCount)).unsigned = false;
                        else if (typeof object.memberCount === "string")
                            message.memberCount = parseInt(object.memberCount, 10);
                        else if (typeof object.memberCount === "number")
                            message.memberCount = object.memberCount;
                        else if (typeof object.memberCount === "object")
                            message.memberCount = new $util.LongBits(object.memberCount.low >>> 0, object.memberCount.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a Team message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {infinitusai.teams.Team} message Team
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Team.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.members = [];
                    if (options.defaults) {
                        object.teamUuid = "";
                        object.name = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.memberCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.memberCount = options.longs === String ? "0" : 0;
                    }
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        object.teamUuid = message.teamUuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.members && message.members.length) {
                        object.members = [];
                        for (var j = 0; j < message.members.length; ++j)
                            object.members[j] = $root.infinitusai.teams.Member.toObject(message.members[j], options);
                    }
                    if (message.memberCount != null && message.hasOwnProperty("memberCount"))
                        if (typeof message.memberCount === "number")
                            object.memberCount = options.longs === String ? String(message.memberCount) : message.memberCount;
                        else
                            object.memberCount = options.longs === String ? $util.Long.prototype.toString.call(message.memberCount) : options.longs === Number ? new $util.LongBits(message.memberCount.low >>> 0, message.memberCount.high >>> 0).toNumber() : message.memberCount;
                    return object;
                };
    
                /**
                 * Converts this Team to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.Team
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Team.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Team
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.Team
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Team.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.Team";
                };
    
                return Team;
            })();
    
            teams.Member = (function() {
    
                /**
                 * Properties of a Member.
                 * @memberof infinitusai.teams
                 * @interface IMember
                 * @property {infinitusai.teams.IUser|null} [user] Member user
                 * @property {Array.<infinitusai.teams.IRole>|null} [roles] Member roles
                 * @property {Array.<infinitusai.teams.ISkill>|null} [skills] Member skills
                 * @property {Array.<infinitusai.teams.ITeamIDs>|null} [teams] Member teams
                 */
    
                /**
                 * Constructs a new Member.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a Member.
                 * @implements IMember
                 * @constructor
                 * @param {infinitusai.teams.IMember=} [properties] Properties to set
                 */
                function Member(properties) {
                    this.roles = [];
                    this.skills = [];
                    this.teams = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Member user.
                 * @member {infinitusai.teams.IUser|null|undefined} user
                 * @memberof infinitusai.teams.Member
                 * @instance
                 */
                Member.prototype.user = null;
    
                /**
                 * Member roles.
                 * @member {Array.<infinitusai.teams.IRole>} roles
                 * @memberof infinitusai.teams.Member
                 * @instance
                 */
                Member.prototype.roles = $util.emptyArray;
    
                /**
                 * Member skills.
                 * @member {Array.<infinitusai.teams.ISkill>} skills
                 * @memberof infinitusai.teams.Member
                 * @instance
                 */
                Member.prototype.skills = $util.emptyArray;
    
                /**
                 * Member teams.
                 * @member {Array.<infinitusai.teams.ITeamIDs>} teams
                 * @memberof infinitusai.teams.Member
                 * @instance
                 */
                Member.prototype.teams = $util.emptyArray;
    
                /**
                 * Creates a new Member instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {infinitusai.teams.IMember=} [properties] Properties to set
                 * @returns {infinitusai.teams.Member} Member instance
                 */
                Member.create = function create(properties) {
                    return new Member(properties);
                };
    
                /**
                 * Encodes the specified Member message. Does not implicitly {@link infinitusai.teams.Member.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {infinitusai.teams.IMember} message Member message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Member.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        $root.infinitusai.teams.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.roles != null && message.roles.length)
                        for (var i = 0; i < message.roles.length; ++i)
                            $root.infinitusai.teams.Role.encode(message.roles[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.skills != null && message.skills.length)
                        for (var i = 0; i < message.skills.length; ++i)
                            $root.infinitusai.teams.Skill.encode(message.skills[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.teams != null && message.teams.length)
                        for (var i = 0; i < message.teams.length; ++i)
                            $root.infinitusai.teams.TeamIDs.encode(message.teams[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Member message, length delimited. Does not implicitly {@link infinitusai.teams.Member.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {infinitusai.teams.IMember} message Member message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Member.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Member message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.Member} Member
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Member.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.Member();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.user = $root.infinitusai.teams.User.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.roles && message.roles.length))
                                    message.roles = [];
                                message.roles.push($root.infinitusai.teams.Role.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.skills && message.skills.length))
                                    message.skills = [];
                                message.skills.push($root.infinitusai.teams.Skill.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(message.teams && message.teams.length))
                                    message.teams = [];
                                message.teams.push($root.infinitusai.teams.TeamIDs.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Member message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.Member} Member
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Member.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Member message.
                 * @function verify
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Member.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        var error = $root.infinitusai.teams.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.roles != null && message.hasOwnProperty("roles")) {
                        if (!Array.isArray(message.roles))
                            return "roles: array expected";
                        for (var i = 0; i < message.roles.length; ++i) {
                            var error = $root.infinitusai.teams.Role.verify(message.roles[i]);
                            if (error)
                                return "roles." + error;
                        }
                    }
                    if (message.skills != null && message.hasOwnProperty("skills")) {
                        if (!Array.isArray(message.skills))
                            return "skills: array expected";
                        for (var i = 0; i < message.skills.length; ++i) {
                            var error = $root.infinitusai.teams.Skill.verify(message.skills[i]);
                            if (error)
                                return "skills." + error;
                        }
                    }
                    if (message.teams != null && message.hasOwnProperty("teams")) {
                        if (!Array.isArray(message.teams))
                            return "teams: array expected";
                        for (var i = 0; i < message.teams.length; ++i) {
                            var error = $root.infinitusai.teams.TeamIDs.verify(message.teams[i]);
                            if (error)
                                return "teams." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Member message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.Member} Member
                 */
                Member.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.Member)
                        return object;
                    var message = new $root.infinitusai.teams.Member();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".infinitusai.teams.Member.user: object expected");
                        message.user = $root.infinitusai.teams.User.fromObject(object.user);
                    }
                    if (object.roles) {
                        if (!Array.isArray(object.roles))
                            throw TypeError(".infinitusai.teams.Member.roles: array expected");
                        message.roles = [];
                        for (var i = 0; i < object.roles.length; ++i) {
                            if (typeof object.roles[i] !== "object")
                                throw TypeError(".infinitusai.teams.Member.roles: object expected");
                            message.roles[i] = $root.infinitusai.teams.Role.fromObject(object.roles[i]);
                        }
                    }
                    if (object.skills) {
                        if (!Array.isArray(object.skills))
                            throw TypeError(".infinitusai.teams.Member.skills: array expected");
                        message.skills = [];
                        for (var i = 0; i < object.skills.length; ++i) {
                            if (typeof object.skills[i] !== "object")
                                throw TypeError(".infinitusai.teams.Member.skills: object expected");
                            message.skills[i] = $root.infinitusai.teams.Skill.fromObject(object.skills[i]);
                        }
                    }
                    if (object.teams) {
                        if (!Array.isArray(object.teams))
                            throw TypeError(".infinitusai.teams.Member.teams: array expected");
                        message.teams = [];
                        for (var i = 0; i < object.teams.length; ++i) {
                            if (typeof object.teams[i] !== "object")
                                throw TypeError(".infinitusai.teams.Member.teams: object expected");
                            message.teams[i] = $root.infinitusai.teams.TeamIDs.fromObject(object.teams[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Member message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {infinitusai.teams.Member} message Member
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Member.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.roles = [];
                        object.skills = [];
                        object.teams = [];
                    }
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.infinitusai.teams.User.toObject(message.user, options);
                    if (message.roles && message.roles.length) {
                        object.roles = [];
                        for (var j = 0; j < message.roles.length; ++j)
                            object.roles[j] = $root.infinitusai.teams.Role.toObject(message.roles[j], options);
                    }
                    if (message.skills && message.skills.length) {
                        object.skills = [];
                        for (var j = 0; j < message.skills.length; ++j)
                            object.skills[j] = $root.infinitusai.teams.Skill.toObject(message.skills[j], options);
                    }
                    if (message.teams && message.teams.length) {
                        object.teams = [];
                        for (var j = 0; j < message.teams.length; ++j)
                            object.teams[j] = $root.infinitusai.teams.TeamIDs.toObject(message.teams[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Member to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.Member
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Member.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Member
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.Member
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Member.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.Member";
                };
    
                return Member;
            })();
    
            teams.User = (function() {
    
                /**
                 * Properties of a User.
                 * @memberof infinitusai.teams
                 * @interface IUser
                 * @property {string|null} [userId] User userId
                 * @property {string|null} [email] User email
                 * @property {string|null} [displayName] User displayName
                 */
    
                /**
                 * Constructs a new User.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a User.
                 * @implements IUser
                 * @constructor
                 * @param {infinitusai.teams.IUser=} [properties] Properties to set
                 */
                function User(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * User userId.
                 * @member {string} userId
                 * @memberof infinitusai.teams.User
                 * @instance
                 */
                User.prototype.userId = "";
    
                /**
                 * User email.
                 * @member {string} email
                 * @memberof infinitusai.teams.User
                 * @instance
                 */
                User.prototype.email = "";
    
                /**
                 * User displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.teams.User
                 * @instance
                 */
                User.prototype.displayName = "";
    
                /**
                 * Creates a new User instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {infinitusai.teams.IUser=} [properties] Properties to set
                 * @returns {infinitusai.teams.User} User instance
                 */
                User.create = function create(properties) {
                    return new User(properties);
                };
    
                /**
                 * Encodes the specified User message. Does not implicitly {@link infinitusai.teams.User.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {infinitusai.teams.IUser} message User message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                User.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
                    return writer;
                };
    
                /**
                 * Encodes the specified User message, length delimited. Does not implicitly {@link infinitusai.teams.User.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {infinitusai.teams.IUser} message User message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                User.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a User message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.User} User
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                User.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.User();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.email = reader.string();
                                break;
                            }
                        case 3: {
                                message.displayName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a User message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.User} User
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                User.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a User message.
                 * @function verify
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                User.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };
    
                /**
                 * Creates a User message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.User} User
                 */
                User.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.User)
                        return object;
                    var message = new $root.infinitusai.teams.User();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a User message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {infinitusai.teams.User} message User
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                User.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userId = "";
                        object.email = "";
                        object.displayName = "";
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };
    
                /**
                 * Converts this User to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.User
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                User.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for User
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.User
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.User";
                };
    
                return User;
            })();
    
            teams.TeamIDs = (function() {
    
                /**
                 * Properties of a TeamIDs.
                 * @memberof infinitusai.teams
                 * @interface ITeamIDs
                 * @property {string|null} [teamUuid] TeamIDs teamUuid
                 * @property {string|null} [teamName] TeamIDs teamName
                 */
    
                /**
                 * Constructs a new TeamIDs.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a TeamIDs.
                 * @implements ITeamIDs
                 * @constructor
                 * @param {infinitusai.teams.ITeamIDs=} [properties] Properties to set
                 */
                function TeamIDs(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TeamIDs teamUuid.
                 * @member {string} teamUuid
                 * @memberof infinitusai.teams.TeamIDs
                 * @instance
                 */
                TeamIDs.prototype.teamUuid = "";
    
                /**
                 * TeamIDs teamName.
                 * @member {string} teamName
                 * @memberof infinitusai.teams.TeamIDs
                 * @instance
                 */
                TeamIDs.prototype.teamName = "";
    
                /**
                 * Creates a new TeamIDs instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {infinitusai.teams.ITeamIDs=} [properties] Properties to set
                 * @returns {infinitusai.teams.TeamIDs} TeamIDs instance
                 */
                TeamIDs.create = function create(properties) {
                    return new TeamIDs(properties);
                };
    
                /**
                 * Encodes the specified TeamIDs message. Does not implicitly {@link infinitusai.teams.TeamIDs.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {infinitusai.teams.ITeamIDs} message TeamIDs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TeamIDs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teamUuid != null && Object.hasOwnProperty.call(message, "teamUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamUuid);
                    if (message.teamName != null && Object.hasOwnProperty.call(message, "teamName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.teamName);
                    return writer;
                };
    
                /**
                 * Encodes the specified TeamIDs message, length delimited. Does not implicitly {@link infinitusai.teams.TeamIDs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {infinitusai.teams.ITeamIDs} message TeamIDs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TeamIDs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TeamIDs message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.TeamIDs} TeamIDs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TeamIDs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.TeamIDs();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teamUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.teamName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TeamIDs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.TeamIDs} TeamIDs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TeamIDs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TeamIDs message.
                 * @function verify
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TeamIDs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        if (!$util.isString(message.teamUuid))
                            return "teamUuid: string expected";
                    if (message.teamName != null && message.hasOwnProperty("teamName"))
                        if (!$util.isString(message.teamName))
                            return "teamName: string expected";
                    return null;
                };
    
                /**
                 * Creates a TeamIDs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.TeamIDs} TeamIDs
                 */
                TeamIDs.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.TeamIDs)
                        return object;
                    var message = new $root.infinitusai.teams.TeamIDs();
                    if (object.teamUuid != null)
                        message.teamUuid = String(object.teamUuid);
                    if (object.teamName != null)
                        message.teamName = String(object.teamName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TeamIDs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {infinitusai.teams.TeamIDs} message TeamIDs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TeamIDs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.teamUuid = "";
                        object.teamName = "";
                    }
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        object.teamUuid = message.teamUuid;
                    if (message.teamName != null && message.hasOwnProperty("teamName"))
                        object.teamName = message.teamName;
                    return object;
                };
    
                /**
                 * Converts this TeamIDs to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.TeamIDs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TeamIDs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TeamIDs
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.TeamIDs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TeamIDs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.TeamIDs";
                };
    
                return TeamIDs;
            })();
    
            teams.Role = (function() {
    
                /**
                 * Properties of a Role.
                 * @memberof infinitusai.teams
                 * @interface IRole
                 * @property {string|null} [name] Role name
                 */
    
                /**
                 * Constructs a new Role.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a Role.
                 * @implements IRole
                 * @constructor
                 * @param {infinitusai.teams.IRole=} [properties] Properties to set
                 */
                function Role(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Role name.
                 * @member {string} name
                 * @memberof infinitusai.teams.Role
                 * @instance
                 */
                Role.prototype.name = "";
    
                /**
                 * Creates a new Role instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {infinitusai.teams.IRole=} [properties] Properties to set
                 * @returns {infinitusai.teams.Role} Role instance
                 */
                Role.create = function create(properties) {
                    return new Role(properties);
                };
    
                /**
                 * Encodes the specified Role message. Does not implicitly {@link infinitusai.teams.Role.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {infinitusai.teams.IRole} message Role message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Role.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    return writer;
                };
    
                /**
                 * Encodes the specified Role message, length delimited. Does not implicitly {@link infinitusai.teams.Role.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {infinitusai.teams.IRole} message Role message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Role.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Role message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.Role} Role
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Role.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.Role();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Role message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.Role} Role
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Role.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Role message.
                 * @function verify
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Role.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    return null;
                };
    
                /**
                 * Creates a Role message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.Role} Role
                 */
                Role.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.Role)
                        return object;
                    var message = new $root.infinitusai.teams.Role();
                    if (object.name != null)
                        message.name = String(object.name);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Role message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {infinitusai.teams.Role} message Role
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Role.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.name = "";
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    return object;
                };
    
                /**
                 * Converts this Role to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.Role
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Role.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Role
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.Role
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Role.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.Role";
                };
    
                return Role;
            })();
    
            teams.Skill = (function() {
    
                /**
                 * Properties of a Skill.
                 * @memberof infinitusai.teams
                 * @interface ISkill
                 * @property {string|null} [skillUuid] Skill skillUuid
                 * @property {string|null} [name] Skill name
                 */
    
                /**
                 * Constructs a new Skill.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a Skill.
                 * @implements ISkill
                 * @constructor
                 * @param {infinitusai.teams.ISkill=} [properties] Properties to set
                 */
                function Skill(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Skill skillUuid.
                 * @member {string} skillUuid
                 * @memberof infinitusai.teams.Skill
                 * @instance
                 */
                Skill.prototype.skillUuid = "";
    
                /**
                 * Skill name.
                 * @member {string} name
                 * @memberof infinitusai.teams.Skill
                 * @instance
                 */
                Skill.prototype.name = "";
    
                /**
                 * Creates a new Skill instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {infinitusai.teams.ISkill=} [properties] Properties to set
                 * @returns {infinitusai.teams.Skill} Skill instance
                 */
                Skill.create = function create(properties) {
                    return new Skill(properties);
                };
    
                /**
                 * Encodes the specified Skill message. Does not implicitly {@link infinitusai.teams.Skill.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {infinitusai.teams.ISkill} message Skill message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Skill.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.skillUuid != null && Object.hasOwnProperty.call(message, "skillUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.skillUuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    return writer;
                };
    
                /**
                 * Encodes the specified Skill message, length delimited. Does not implicitly {@link infinitusai.teams.Skill.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {infinitusai.teams.ISkill} message Skill message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Skill.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Skill message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.Skill} Skill
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Skill.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.Skill();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.skillUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Skill message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.Skill} Skill
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Skill.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Skill message.
                 * @function verify
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Skill.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        if (!$util.isString(message.skillUuid))
                            return "skillUuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    return null;
                };
    
                /**
                 * Creates a Skill message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.Skill} Skill
                 */
                Skill.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.Skill)
                        return object;
                    var message = new $root.infinitusai.teams.Skill();
                    if (object.skillUuid != null)
                        message.skillUuid = String(object.skillUuid);
                    if (object.name != null)
                        message.name = String(object.name);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Skill message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {infinitusai.teams.Skill} message Skill
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Skill.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.skillUuid = "";
                        object.name = "";
                    }
                    if (message.skillUuid != null && message.hasOwnProperty("skillUuid"))
                        object.skillUuid = message.skillUuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    return object;
                };
    
                /**
                 * Converts this Skill to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.Skill
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Skill.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Skill
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.Skill
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Skill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.Skill";
                };
    
                return Skill;
            })();
    
            teams.GetTeamRequest = (function() {
    
                /**
                 * Properties of a GetTeamRequest.
                 * @memberof infinitusai.teams
                 * @interface IGetTeamRequest
                 * @property {string|null} [teamUuid] GetTeamRequest teamUuid
                 */
    
                /**
                 * Constructs a new GetTeamRequest.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a GetTeamRequest.
                 * @implements IGetTeamRequest
                 * @constructor
                 * @param {infinitusai.teams.IGetTeamRequest=} [properties] Properties to set
                 */
                function GetTeamRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTeamRequest teamUuid.
                 * @member {string} teamUuid
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @instance
                 */
                GetTeamRequest.prototype.teamUuid = "";
    
                /**
                 * Creates a new GetTeamRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {infinitusai.teams.IGetTeamRequest=} [properties] Properties to set
                 * @returns {infinitusai.teams.GetTeamRequest} GetTeamRequest instance
                 */
                GetTeamRequest.create = function create(properties) {
                    return new GetTeamRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTeamRequest message. Does not implicitly {@link infinitusai.teams.GetTeamRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {infinitusai.teams.IGetTeamRequest} message GetTeamRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTeamRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teamUuid != null && Object.hasOwnProperty.call(message, "teamUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTeamRequest message, length delimited. Does not implicitly {@link infinitusai.teams.GetTeamRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {infinitusai.teams.IGetTeamRequest} message GetTeamRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTeamRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTeamRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.GetTeamRequest} GetTeamRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTeamRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.GetTeamRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teamUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTeamRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.GetTeamRequest} GetTeamRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTeamRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTeamRequest message.
                 * @function verify
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTeamRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        if (!$util.isString(message.teamUuid))
                            return "teamUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetTeamRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.GetTeamRequest} GetTeamRequest
                 */
                GetTeamRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.GetTeamRequest)
                        return object;
                    var message = new $root.infinitusai.teams.GetTeamRequest();
                    if (object.teamUuid != null)
                        message.teamUuid = String(object.teamUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTeamRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {infinitusai.teams.GetTeamRequest} message GetTeamRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTeamRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.teamUuid = "";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        object.teamUuid = message.teamUuid;
                    return object;
                };
    
                /**
                 * Converts this GetTeamRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTeamRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTeamRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.GetTeamRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTeamRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.GetTeamRequest";
                };
    
                return GetTeamRequest;
            })();
    
            teams.GetTeamResponse = (function() {
    
                /**
                 * Properties of a GetTeamResponse.
                 * @memberof infinitusai.teams
                 * @interface IGetTeamResponse
                 * @property {infinitusai.teams.ITeam|null} [team] GetTeamResponse team
                 */
    
                /**
                 * Constructs a new GetTeamResponse.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a GetTeamResponse.
                 * @implements IGetTeamResponse
                 * @constructor
                 * @param {infinitusai.teams.IGetTeamResponse=} [properties] Properties to set
                 */
                function GetTeamResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTeamResponse team.
                 * @member {infinitusai.teams.ITeam|null|undefined} team
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @instance
                 */
                GetTeamResponse.prototype.team = null;
    
                /**
                 * Creates a new GetTeamResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {infinitusai.teams.IGetTeamResponse=} [properties] Properties to set
                 * @returns {infinitusai.teams.GetTeamResponse} GetTeamResponse instance
                 */
                GetTeamResponse.create = function create(properties) {
                    return new GetTeamResponse(properties);
                };
    
                /**
                 * Encodes the specified GetTeamResponse message. Does not implicitly {@link infinitusai.teams.GetTeamResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {infinitusai.teams.IGetTeamResponse} message GetTeamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTeamResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.team != null && Object.hasOwnProperty.call(message, "team"))
                        $root.infinitusai.teams.Team.encode(message.team, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTeamResponse message, length delimited. Does not implicitly {@link infinitusai.teams.GetTeamResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {infinitusai.teams.IGetTeamResponse} message GetTeamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTeamResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTeamResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.GetTeamResponse} GetTeamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTeamResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.GetTeamResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.team = $root.infinitusai.teams.Team.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTeamResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.GetTeamResponse} GetTeamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTeamResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTeamResponse message.
                 * @function verify
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTeamResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.team != null && message.hasOwnProperty("team")) {
                        var error = $root.infinitusai.teams.Team.verify(message.team);
                        if (error)
                            return "team." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a GetTeamResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.GetTeamResponse} GetTeamResponse
                 */
                GetTeamResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.GetTeamResponse)
                        return object;
                    var message = new $root.infinitusai.teams.GetTeamResponse();
                    if (object.team != null) {
                        if (typeof object.team !== "object")
                            throw TypeError(".infinitusai.teams.GetTeamResponse.team: object expected");
                        message.team = $root.infinitusai.teams.Team.fromObject(object.team);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTeamResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {infinitusai.teams.GetTeamResponse} message GetTeamResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTeamResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.team = null;
                    if (message.team != null && message.hasOwnProperty("team"))
                        object.team = $root.infinitusai.teams.Team.toObject(message.team, options);
                    return object;
                };
    
                /**
                 * Converts this GetTeamResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTeamResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTeamResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.GetTeamResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTeamResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.GetTeamResponse";
                };
    
                return GetTeamResponse;
            })();
    
            teams.DeleteTeamRequest = (function() {
    
                /**
                 * Properties of a DeleteTeamRequest.
                 * @memberof infinitusai.teams
                 * @interface IDeleteTeamRequest
                 * @property {string|null} [teamUuid] DeleteTeamRequest teamUuid
                 */
    
                /**
                 * Constructs a new DeleteTeamRequest.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a DeleteTeamRequest.
                 * @implements IDeleteTeamRequest
                 * @constructor
                 * @param {infinitusai.teams.IDeleteTeamRequest=} [properties] Properties to set
                 */
                function DeleteTeamRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteTeamRequest teamUuid.
                 * @member {string} teamUuid
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @instance
                 */
                DeleteTeamRequest.prototype.teamUuid = "";
    
                /**
                 * Creates a new DeleteTeamRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {infinitusai.teams.IDeleteTeamRequest=} [properties] Properties to set
                 * @returns {infinitusai.teams.DeleteTeamRequest} DeleteTeamRequest instance
                 */
                DeleteTeamRequest.create = function create(properties) {
                    return new DeleteTeamRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteTeamRequest message. Does not implicitly {@link infinitusai.teams.DeleteTeamRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {infinitusai.teams.IDeleteTeamRequest} message DeleteTeamRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteTeamRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teamUuid != null && Object.hasOwnProperty.call(message, "teamUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteTeamRequest message, length delimited. Does not implicitly {@link infinitusai.teams.DeleteTeamRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {infinitusai.teams.IDeleteTeamRequest} message DeleteTeamRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteTeamRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteTeamRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.DeleteTeamRequest} DeleteTeamRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteTeamRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.DeleteTeamRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teamUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteTeamRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.DeleteTeamRequest} DeleteTeamRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteTeamRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteTeamRequest message.
                 * @function verify
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteTeamRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        if (!$util.isString(message.teamUuid))
                            return "teamUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteTeamRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.DeleteTeamRequest} DeleteTeamRequest
                 */
                DeleteTeamRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.DeleteTeamRequest)
                        return object;
                    var message = new $root.infinitusai.teams.DeleteTeamRequest();
                    if (object.teamUuid != null)
                        message.teamUuid = String(object.teamUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteTeamRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {infinitusai.teams.DeleteTeamRequest} message DeleteTeamRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteTeamRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.teamUuid = "";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        object.teamUuid = message.teamUuid;
                    return object;
                };
    
                /**
                 * Converts this DeleteTeamRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteTeamRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteTeamRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.DeleteTeamRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteTeamRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.DeleteTeamRequest";
                };
    
                return DeleteTeamRequest;
            })();
    
            teams.DeleteTeamResponse = (function() {
    
                /**
                 * Properties of a DeleteTeamResponse.
                 * @memberof infinitusai.teams
                 * @interface IDeleteTeamResponse
                 */
    
                /**
                 * Constructs a new DeleteTeamResponse.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a DeleteTeamResponse.
                 * @implements IDeleteTeamResponse
                 * @constructor
                 * @param {infinitusai.teams.IDeleteTeamResponse=} [properties] Properties to set
                 */
                function DeleteTeamResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteTeamResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {infinitusai.teams.IDeleteTeamResponse=} [properties] Properties to set
                 * @returns {infinitusai.teams.DeleteTeamResponse} DeleteTeamResponse instance
                 */
                DeleteTeamResponse.create = function create(properties) {
                    return new DeleteTeamResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteTeamResponse message. Does not implicitly {@link infinitusai.teams.DeleteTeamResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {infinitusai.teams.IDeleteTeamResponse} message DeleteTeamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteTeamResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteTeamResponse message, length delimited. Does not implicitly {@link infinitusai.teams.DeleteTeamResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {infinitusai.teams.IDeleteTeamResponse} message DeleteTeamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteTeamResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteTeamResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.DeleteTeamResponse} DeleteTeamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteTeamResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.DeleteTeamResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteTeamResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.DeleteTeamResponse} DeleteTeamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteTeamResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteTeamResponse message.
                 * @function verify
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteTeamResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteTeamResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.DeleteTeamResponse} DeleteTeamResponse
                 */
                DeleteTeamResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.DeleteTeamResponse)
                        return object;
                    return new $root.infinitusai.teams.DeleteTeamResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteTeamResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {infinitusai.teams.DeleteTeamResponse} message DeleteTeamResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteTeamResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteTeamResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteTeamResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteTeamResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.DeleteTeamResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteTeamResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.DeleteTeamResponse";
                };
    
                return DeleteTeamResponse;
            })();
    
            teams.DeleteMembershipRequest = (function() {
    
                /**
                 * Properties of a DeleteMembershipRequest.
                 * @memberof infinitusai.teams
                 * @interface IDeleteMembershipRequest
                 * @property {string|null} [teamUuid] DeleteMembershipRequest teamUuid
                 * @property {string|null} [userEmail] DeleteMembershipRequest userEmail
                 */
    
                /**
                 * Constructs a new DeleteMembershipRequest.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a DeleteMembershipRequest.
                 * @implements IDeleteMembershipRequest
                 * @constructor
                 * @param {infinitusai.teams.IDeleteMembershipRequest=} [properties] Properties to set
                 */
                function DeleteMembershipRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteMembershipRequest teamUuid.
                 * @member {string} teamUuid
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @instance
                 */
                DeleteMembershipRequest.prototype.teamUuid = "";
    
                /**
                 * DeleteMembershipRequest userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @instance
                 */
                DeleteMembershipRequest.prototype.userEmail = "";
    
                /**
                 * Creates a new DeleteMembershipRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {infinitusai.teams.IDeleteMembershipRequest=} [properties] Properties to set
                 * @returns {infinitusai.teams.DeleteMembershipRequest} DeleteMembershipRequest instance
                 */
                DeleteMembershipRequest.create = function create(properties) {
                    return new DeleteMembershipRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteMembershipRequest message. Does not implicitly {@link infinitusai.teams.DeleteMembershipRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {infinitusai.teams.IDeleteMembershipRequest} message DeleteMembershipRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteMembershipRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teamUuid != null && Object.hasOwnProperty.call(message, "teamUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamUuid);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userEmail);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteMembershipRequest message, length delimited. Does not implicitly {@link infinitusai.teams.DeleteMembershipRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {infinitusai.teams.IDeleteMembershipRequest} message DeleteMembershipRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteMembershipRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteMembershipRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.DeleteMembershipRequest} DeleteMembershipRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteMembershipRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.DeleteMembershipRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teamUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.userEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteMembershipRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.DeleteMembershipRequest} DeleteMembershipRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteMembershipRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteMembershipRequest message.
                 * @function verify
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteMembershipRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        if (!$util.isString(message.teamUuid))
                            return "teamUuid: string expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteMembershipRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.DeleteMembershipRequest} DeleteMembershipRequest
                 */
                DeleteMembershipRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.DeleteMembershipRequest)
                        return object;
                    var message = new $root.infinitusai.teams.DeleteMembershipRequest();
                    if (object.teamUuid != null)
                        message.teamUuid = String(object.teamUuid);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteMembershipRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {infinitusai.teams.DeleteMembershipRequest} message DeleteMembershipRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteMembershipRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.teamUuid = "";
                        object.userEmail = "";
                    }
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        object.teamUuid = message.teamUuid;
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    return object;
                };
    
                /**
                 * Converts this DeleteMembershipRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteMembershipRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteMembershipRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.DeleteMembershipRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteMembershipRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.DeleteMembershipRequest";
                };
    
                return DeleteMembershipRequest;
            })();
    
            teams.DeleteMembershipResponse = (function() {
    
                /**
                 * Properties of a DeleteMembershipResponse.
                 * @memberof infinitusai.teams
                 * @interface IDeleteMembershipResponse
                 */
    
                /**
                 * Constructs a new DeleteMembershipResponse.
                 * @memberof infinitusai.teams
                 * @classdesc Represents a DeleteMembershipResponse.
                 * @implements IDeleteMembershipResponse
                 * @constructor
                 * @param {infinitusai.teams.IDeleteMembershipResponse=} [properties] Properties to set
                 */
                function DeleteMembershipResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteMembershipResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {infinitusai.teams.IDeleteMembershipResponse=} [properties] Properties to set
                 * @returns {infinitusai.teams.DeleteMembershipResponse} DeleteMembershipResponse instance
                 */
                DeleteMembershipResponse.create = function create(properties) {
                    return new DeleteMembershipResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteMembershipResponse message. Does not implicitly {@link infinitusai.teams.DeleteMembershipResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {infinitusai.teams.IDeleteMembershipResponse} message DeleteMembershipResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteMembershipResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteMembershipResponse message, length delimited. Does not implicitly {@link infinitusai.teams.DeleteMembershipResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {infinitusai.teams.IDeleteMembershipResponse} message DeleteMembershipResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteMembershipResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteMembershipResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.DeleteMembershipResponse} DeleteMembershipResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteMembershipResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.DeleteMembershipResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteMembershipResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.DeleteMembershipResponse} DeleteMembershipResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteMembershipResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteMembershipResponse message.
                 * @function verify
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteMembershipResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteMembershipResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.DeleteMembershipResponse} DeleteMembershipResponse
                 */
                DeleteMembershipResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.DeleteMembershipResponse)
                        return object;
                    return new $root.infinitusai.teams.DeleteMembershipResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteMembershipResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {infinitusai.teams.DeleteMembershipResponse} message DeleteMembershipResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteMembershipResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteMembershipResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteMembershipResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteMembershipResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.DeleteMembershipResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteMembershipResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.DeleteMembershipResponse";
                };
    
                return DeleteMembershipResponse;
            })();
    
            teams.AddMembersRequest = (function() {
    
                /**
                 * Properties of an AddMembersRequest.
                 * @memberof infinitusai.teams
                 * @interface IAddMembersRequest
                 * @property {string|null} [teamUuid] AddMembersRequest teamUuid
                 * @property {Array.<string>|null} [memberEmails] AddMembersRequest memberEmails
                 */
    
                /**
                 * Constructs a new AddMembersRequest.
                 * @memberof infinitusai.teams
                 * @classdesc Represents an AddMembersRequest.
                 * @implements IAddMembersRequest
                 * @constructor
                 * @param {infinitusai.teams.IAddMembersRequest=} [properties] Properties to set
                 */
                function AddMembersRequest(properties) {
                    this.memberEmails = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AddMembersRequest teamUuid.
                 * @member {string} teamUuid
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @instance
                 */
                AddMembersRequest.prototype.teamUuid = "";
    
                /**
                 * AddMembersRequest memberEmails.
                 * @member {Array.<string>} memberEmails
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @instance
                 */
                AddMembersRequest.prototype.memberEmails = $util.emptyArray;
    
                /**
                 * Creates a new AddMembersRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {infinitusai.teams.IAddMembersRequest=} [properties] Properties to set
                 * @returns {infinitusai.teams.AddMembersRequest} AddMembersRequest instance
                 */
                AddMembersRequest.create = function create(properties) {
                    return new AddMembersRequest(properties);
                };
    
                /**
                 * Encodes the specified AddMembersRequest message. Does not implicitly {@link infinitusai.teams.AddMembersRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {infinitusai.teams.IAddMembersRequest} message AddMembersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddMembersRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teamUuid != null && Object.hasOwnProperty.call(message, "teamUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamUuid);
                    if (message.memberEmails != null && message.memberEmails.length)
                        for (var i = 0; i < message.memberEmails.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.memberEmails[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified AddMembersRequest message, length delimited. Does not implicitly {@link infinitusai.teams.AddMembersRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {infinitusai.teams.IAddMembersRequest} message AddMembersRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddMembersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddMembersRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.AddMembersRequest} AddMembersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddMembersRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.AddMembersRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teamUuid = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.memberEmails && message.memberEmails.length))
                                    message.memberEmails = [];
                                message.memberEmails.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddMembersRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.AddMembersRequest} AddMembersRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddMembersRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddMembersRequest message.
                 * @function verify
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddMembersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        if (!$util.isString(message.teamUuid))
                            return "teamUuid: string expected";
                    if (message.memberEmails != null && message.hasOwnProperty("memberEmails")) {
                        if (!Array.isArray(message.memberEmails))
                            return "memberEmails: array expected";
                        for (var i = 0; i < message.memberEmails.length; ++i)
                            if (!$util.isString(message.memberEmails[i]))
                                return "memberEmails: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an AddMembersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.AddMembersRequest} AddMembersRequest
                 */
                AddMembersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.AddMembersRequest)
                        return object;
                    var message = new $root.infinitusai.teams.AddMembersRequest();
                    if (object.teamUuid != null)
                        message.teamUuid = String(object.teamUuid);
                    if (object.memberEmails) {
                        if (!Array.isArray(object.memberEmails))
                            throw TypeError(".infinitusai.teams.AddMembersRequest.memberEmails: array expected");
                        message.memberEmails = [];
                        for (var i = 0; i < object.memberEmails.length; ++i)
                            message.memberEmails[i] = String(object.memberEmails[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an AddMembersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {infinitusai.teams.AddMembersRequest} message AddMembersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddMembersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.memberEmails = [];
                    if (options.defaults)
                        object.teamUuid = "";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        object.teamUuid = message.teamUuid;
                    if (message.memberEmails && message.memberEmails.length) {
                        object.memberEmails = [];
                        for (var j = 0; j < message.memberEmails.length; ++j)
                            object.memberEmails[j] = message.memberEmails[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this AddMembersRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddMembersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddMembersRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.AddMembersRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddMembersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.AddMembersRequest";
                };
    
                return AddMembersRequest;
            })();
    
            teams.AddMembersResponse = (function() {
    
                /**
                 * Properties of an AddMembersResponse.
                 * @memberof infinitusai.teams
                 * @interface IAddMembersResponse
                 */
    
                /**
                 * Constructs a new AddMembersResponse.
                 * @memberof infinitusai.teams
                 * @classdesc Represents an AddMembersResponse.
                 * @implements IAddMembersResponse
                 * @constructor
                 * @param {infinitusai.teams.IAddMembersResponse=} [properties] Properties to set
                 */
                function AddMembersResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new AddMembersResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {infinitusai.teams.IAddMembersResponse=} [properties] Properties to set
                 * @returns {infinitusai.teams.AddMembersResponse} AddMembersResponse instance
                 */
                AddMembersResponse.create = function create(properties) {
                    return new AddMembersResponse(properties);
                };
    
                /**
                 * Encodes the specified AddMembersResponse message. Does not implicitly {@link infinitusai.teams.AddMembersResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {infinitusai.teams.IAddMembersResponse} message AddMembersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddMembersResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified AddMembersResponse message, length delimited. Does not implicitly {@link infinitusai.teams.AddMembersResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {infinitusai.teams.IAddMembersResponse} message AddMembersResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddMembersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AddMembersResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.AddMembersResponse} AddMembersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddMembersResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.AddMembersResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AddMembersResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.AddMembersResponse} AddMembersResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddMembersResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AddMembersResponse message.
                 * @function verify
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddMembersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an AddMembersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.AddMembersResponse} AddMembersResponse
                 */
                AddMembersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.AddMembersResponse)
                        return object;
                    return new $root.infinitusai.teams.AddMembersResponse();
                };
    
                /**
                 * Creates a plain object from an AddMembersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {infinitusai.teams.AddMembersResponse} message AddMembersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddMembersResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this AddMembersResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddMembersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AddMembersResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.AddMembersResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddMembersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.AddMembersResponse";
                };
    
                return AddMembersResponse;
            })();
    
            teams.EditTeamRequest = (function() {
    
                /**
                 * Properties of an EditTeamRequest.
                 * @memberof infinitusai.teams
                 * @interface IEditTeamRequest
                 * @property {string|null} [teamUuid] EditTeamRequest teamUuid
                 * @property {string|null} [name] EditTeamRequest name
                 */
    
                /**
                 * Constructs a new EditTeamRequest.
                 * @memberof infinitusai.teams
                 * @classdesc Represents an EditTeamRequest.
                 * @implements IEditTeamRequest
                 * @constructor
                 * @param {infinitusai.teams.IEditTeamRequest=} [properties] Properties to set
                 */
                function EditTeamRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EditTeamRequest teamUuid.
                 * @member {string} teamUuid
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @instance
                 */
                EditTeamRequest.prototype.teamUuid = "";
    
                /**
                 * EditTeamRequest name.
                 * @member {string} name
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @instance
                 */
                EditTeamRequest.prototype.name = "";
    
                /**
                 * Creates a new EditTeamRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {infinitusai.teams.IEditTeamRequest=} [properties] Properties to set
                 * @returns {infinitusai.teams.EditTeamRequest} EditTeamRequest instance
                 */
                EditTeamRequest.create = function create(properties) {
                    return new EditTeamRequest(properties);
                };
    
                /**
                 * Encodes the specified EditTeamRequest message. Does not implicitly {@link infinitusai.teams.EditTeamRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {infinitusai.teams.IEditTeamRequest} message EditTeamRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EditTeamRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.teamUuid != null && Object.hasOwnProperty.call(message, "teamUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamUuid);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    return writer;
                };
    
                /**
                 * Encodes the specified EditTeamRequest message, length delimited. Does not implicitly {@link infinitusai.teams.EditTeamRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {infinitusai.teams.IEditTeamRequest} message EditTeamRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EditTeamRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EditTeamRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.EditTeamRequest} EditTeamRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EditTeamRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.EditTeamRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.teamUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EditTeamRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.EditTeamRequest} EditTeamRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EditTeamRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EditTeamRequest message.
                 * @function verify
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EditTeamRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        if (!$util.isString(message.teamUuid))
                            return "teamUuid: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    return null;
                };
    
                /**
                 * Creates an EditTeamRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.EditTeamRequest} EditTeamRequest
                 */
                EditTeamRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.EditTeamRequest)
                        return object;
                    var message = new $root.infinitusai.teams.EditTeamRequest();
                    if (object.teamUuid != null)
                        message.teamUuid = String(object.teamUuid);
                    if (object.name != null)
                        message.name = String(object.name);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EditTeamRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {infinitusai.teams.EditTeamRequest} message EditTeamRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EditTeamRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.teamUuid = "";
                        object.name = "";
                    }
                    if (message.teamUuid != null && message.hasOwnProperty("teamUuid"))
                        object.teamUuid = message.teamUuid;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    return object;
                };
    
                /**
                 * Converts this EditTeamRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EditTeamRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EditTeamRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.EditTeamRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EditTeamRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.EditTeamRequest";
                };
    
                return EditTeamRequest;
            })();
    
            teams.EditTeamResponse = (function() {
    
                /**
                 * Properties of an EditTeamResponse.
                 * @memberof infinitusai.teams
                 * @interface IEditTeamResponse
                 */
    
                /**
                 * Constructs a new EditTeamResponse.
                 * @memberof infinitusai.teams
                 * @classdesc Represents an EditTeamResponse.
                 * @implements IEditTeamResponse
                 * @constructor
                 * @param {infinitusai.teams.IEditTeamResponse=} [properties] Properties to set
                 */
                function EditTeamResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new EditTeamResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {infinitusai.teams.IEditTeamResponse=} [properties] Properties to set
                 * @returns {infinitusai.teams.EditTeamResponse} EditTeamResponse instance
                 */
                EditTeamResponse.create = function create(properties) {
                    return new EditTeamResponse(properties);
                };
    
                /**
                 * Encodes the specified EditTeamResponse message. Does not implicitly {@link infinitusai.teams.EditTeamResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {infinitusai.teams.IEditTeamResponse} message EditTeamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EditTeamResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified EditTeamResponse message, length delimited. Does not implicitly {@link infinitusai.teams.EditTeamResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {infinitusai.teams.IEditTeamResponse} message EditTeamResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EditTeamResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EditTeamResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.teams.EditTeamResponse} EditTeamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EditTeamResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.teams.EditTeamResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EditTeamResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.teams.EditTeamResponse} EditTeamResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EditTeamResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EditTeamResponse message.
                 * @function verify
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EditTeamResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an EditTeamResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.teams.EditTeamResponse} EditTeamResponse
                 */
                EditTeamResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.teams.EditTeamResponse)
                        return object;
                    return new $root.infinitusai.teams.EditTeamResponse();
                };
    
                /**
                 * Creates a plain object from an EditTeamResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {infinitusai.teams.EditTeamResponse} message EditTeamResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EditTeamResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this EditTeamResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EditTeamResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EditTeamResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.teams.EditTeamResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EditTeamResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.teams.EditTeamResponse";
                };
    
                return EditTeamResponse;
            })();
    
            return teams;
        })();
    
        infinitusai.tags = (function() {
    
            /**
             * Namespace tags.
             * @memberof infinitusai
             * @namespace
             */
            var tags = {};
    
            tags.CreateTagRequest = (function() {
    
                /**
                 * Properties of a CreateTagRequest.
                 * @memberof infinitusai.tags
                 * @interface ICreateTagRequest
                 * @property {infinitusai.tags.ITag|null} [tag] CreateTagRequest tag
                 */
    
                /**
                 * Constructs a new CreateTagRequest.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a CreateTagRequest.
                 * @implements ICreateTagRequest
                 * @constructor
                 * @param {infinitusai.tags.ICreateTagRequest=} [properties] Properties to set
                 */
                function CreateTagRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateTagRequest tag.
                 * @member {infinitusai.tags.ITag|null|undefined} tag
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @instance
                 */
                CreateTagRequest.prototype.tag = null;
    
                /**
                 * Creates a new CreateTagRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {infinitusai.tags.ICreateTagRequest=} [properties] Properties to set
                 * @returns {infinitusai.tags.CreateTagRequest} CreateTagRequest instance
                 */
                CreateTagRequest.create = function create(properties) {
                    return new CreateTagRequest(properties);
                };
    
                /**
                 * Encodes the specified CreateTagRequest message. Does not implicitly {@link infinitusai.tags.CreateTagRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {infinitusai.tags.ICreateTagRequest} message CreateTagRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTagRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                        $root.infinitusai.tags.Tag.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateTagRequest message, length delimited. Does not implicitly {@link infinitusai.tags.CreateTagRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {infinitusai.tags.ICreateTagRequest} message CreateTagRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTagRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateTagRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.CreateTagRequest} CreateTagRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTagRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.CreateTagRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.tag = $root.infinitusai.tags.Tag.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateTagRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.CreateTagRequest} CreateTagRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTagRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateTagRequest message.
                 * @function verify
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTagRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tag != null && message.hasOwnProperty("tag")) {
                        var error = $root.infinitusai.tags.Tag.verify(message.tag);
                        if (error)
                            return "tag." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateTagRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.CreateTagRequest} CreateTagRequest
                 */
                CreateTagRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.CreateTagRequest)
                        return object;
                    var message = new $root.infinitusai.tags.CreateTagRequest();
                    if (object.tag != null) {
                        if (typeof object.tag !== "object")
                            throw TypeError(".infinitusai.tags.CreateTagRequest.tag: object expected");
                        message.tag = $root.infinitusai.tags.Tag.fromObject(object.tag);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateTagRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {infinitusai.tags.CreateTagRequest} message CreateTagRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTagRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.tag = null;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = $root.infinitusai.tags.Tag.toObject(message.tag, options);
                    return object;
                };
    
                /**
                 * Converts this CreateTagRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTagRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateTagRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.CreateTagRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateTagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.CreateTagRequest";
                };
    
                return CreateTagRequest;
            })();
    
            tags.CreateTagResponse = (function() {
    
                /**
                 * Properties of a CreateTagResponse.
                 * @memberof infinitusai.tags
                 * @interface ICreateTagResponse
                 * @property {infinitusai.tags.ITag|null} [tag] CreateTagResponse tag
                 */
    
                /**
                 * Constructs a new CreateTagResponse.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a CreateTagResponse.
                 * @implements ICreateTagResponse
                 * @constructor
                 * @param {infinitusai.tags.ICreateTagResponse=} [properties] Properties to set
                 */
                function CreateTagResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CreateTagResponse tag.
                 * @member {infinitusai.tags.ITag|null|undefined} tag
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @instance
                 */
                CreateTagResponse.prototype.tag = null;
    
                /**
                 * Creates a new CreateTagResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {infinitusai.tags.ICreateTagResponse=} [properties] Properties to set
                 * @returns {infinitusai.tags.CreateTagResponse} CreateTagResponse instance
                 */
                CreateTagResponse.create = function create(properties) {
                    return new CreateTagResponse(properties);
                };
    
                /**
                 * Encodes the specified CreateTagResponse message. Does not implicitly {@link infinitusai.tags.CreateTagResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {infinitusai.tags.ICreateTagResponse} message CreateTagResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTagResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                        $root.infinitusai.tags.Tag.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified CreateTagResponse message, length delimited. Does not implicitly {@link infinitusai.tags.CreateTagResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {infinitusai.tags.ICreateTagResponse} message CreateTagResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateTagResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CreateTagResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.CreateTagResponse} CreateTagResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTagResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.CreateTagResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.tag = $root.infinitusai.tags.Tag.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CreateTagResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.CreateTagResponse} CreateTagResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateTagResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CreateTagResponse message.
                 * @function verify
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTagResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tag != null && message.hasOwnProperty("tag")) {
                        var error = $root.infinitusai.tags.Tag.verify(message.tag);
                        if (error)
                            return "tag." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a CreateTagResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.CreateTagResponse} CreateTagResponse
                 */
                CreateTagResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.CreateTagResponse)
                        return object;
                    var message = new $root.infinitusai.tags.CreateTagResponse();
                    if (object.tag != null) {
                        if (typeof object.tag !== "object")
                            throw TypeError(".infinitusai.tags.CreateTagResponse.tag: object expected");
                        message.tag = $root.infinitusai.tags.Tag.fromObject(object.tag);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a CreateTagResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {infinitusai.tags.CreateTagResponse} message CreateTagResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTagResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.tag = null;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = $root.infinitusai.tags.Tag.toObject(message.tag, options);
                    return object;
                };
    
                /**
                 * Converts this CreateTagResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTagResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CreateTagResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.CreateTagResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CreateTagResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.CreateTagResponse";
                };
    
                return CreateTagResponse;
            })();
    
            tags.UpdateTagRequest = (function() {
    
                /**
                 * Properties of an UpdateTagRequest.
                 * @memberof infinitusai.tags
                 * @interface IUpdateTagRequest
                 * @property {infinitusai.tags.ITag|null} [tag] UpdateTagRequest tag
                 */
    
                /**
                 * Constructs a new UpdateTagRequest.
                 * @memberof infinitusai.tags
                 * @classdesc Represents an UpdateTagRequest.
                 * @implements IUpdateTagRequest
                 * @constructor
                 * @param {infinitusai.tags.IUpdateTagRequest=} [properties] Properties to set
                 */
                function UpdateTagRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UpdateTagRequest tag.
                 * @member {infinitusai.tags.ITag|null|undefined} tag
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @instance
                 */
                UpdateTagRequest.prototype.tag = null;
    
                /**
                 * Creates a new UpdateTagRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {infinitusai.tags.IUpdateTagRequest=} [properties] Properties to set
                 * @returns {infinitusai.tags.UpdateTagRequest} UpdateTagRequest instance
                 */
                UpdateTagRequest.create = function create(properties) {
                    return new UpdateTagRequest(properties);
                };
    
                /**
                 * Encodes the specified UpdateTagRequest message. Does not implicitly {@link infinitusai.tags.UpdateTagRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {infinitusai.tags.IUpdateTagRequest} message UpdateTagRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateTagRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                        $root.infinitusai.tags.Tag.encode(message.tag, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateTagRequest message, length delimited. Does not implicitly {@link infinitusai.tags.UpdateTagRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {infinitusai.tags.IUpdateTagRequest} message UpdateTagRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateTagRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateTagRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.UpdateTagRequest} UpdateTagRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateTagRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.UpdateTagRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.tag = $root.infinitusai.tags.Tag.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateTagRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.UpdateTagRequest} UpdateTagRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateTagRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateTagRequest message.
                 * @function verify
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateTagRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tag != null && message.hasOwnProperty("tag")) {
                        var error = $root.infinitusai.tags.Tag.verify(message.tag);
                        if (error)
                            return "tag." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an UpdateTagRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.UpdateTagRequest} UpdateTagRequest
                 */
                UpdateTagRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.UpdateTagRequest)
                        return object;
                    var message = new $root.infinitusai.tags.UpdateTagRequest();
                    if (object.tag != null) {
                        if (typeof object.tag !== "object")
                            throw TypeError(".infinitusai.tags.UpdateTagRequest.tag: object expected");
                        message.tag = $root.infinitusai.tags.Tag.fromObject(object.tag);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UpdateTagRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {infinitusai.tags.UpdateTagRequest} message UpdateTagRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateTagRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.tag = null;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = $root.infinitusai.tags.Tag.toObject(message.tag, options);
                    return object;
                };
    
                /**
                 * Converts this UpdateTagRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateTagRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateTagRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.UpdateTagRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateTagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.UpdateTagRequest";
                };
    
                return UpdateTagRequest;
            })();
    
            tags.UpdateTagResponse = (function() {
    
                /**
                 * Properties of an UpdateTagResponse.
                 * @memberof infinitusai.tags
                 * @interface IUpdateTagResponse
                 */
    
                /**
                 * Constructs a new UpdateTagResponse.
                 * @memberof infinitusai.tags
                 * @classdesc Represents an UpdateTagResponse.
                 * @implements IUpdateTagResponse
                 * @constructor
                 * @param {infinitusai.tags.IUpdateTagResponse=} [properties] Properties to set
                 */
                function UpdateTagResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new UpdateTagResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {infinitusai.tags.IUpdateTagResponse=} [properties] Properties to set
                 * @returns {infinitusai.tags.UpdateTagResponse} UpdateTagResponse instance
                 */
                UpdateTagResponse.create = function create(properties) {
                    return new UpdateTagResponse(properties);
                };
    
                /**
                 * Encodes the specified UpdateTagResponse message. Does not implicitly {@link infinitusai.tags.UpdateTagResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {infinitusai.tags.IUpdateTagResponse} message UpdateTagResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateTagResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified UpdateTagResponse message, length delimited. Does not implicitly {@link infinitusai.tags.UpdateTagResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {infinitusai.tags.IUpdateTagResponse} message UpdateTagResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateTagResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UpdateTagResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.UpdateTagResponse} UpdateTagResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateTagResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.UpdateTagResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UpdateTagResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.UpdateTagResponse} UpdateTagResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateTagResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UpdateTagResponse message.
                 * @function verify
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateTagResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an UpdateTagResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.UpdateTagResponse} UpdateTagResponse
                 */
                UpdateTagResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.UpdateTagResponse)
                        return object;
                    return new $root.infinitusai.tags.UpdateTagResponse();
                };
    
                /**
                 * Creates a plain object from an UpdateTagResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {infinitusai.tags.UpdateTagResponse} message UpdateTagResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateTagResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this UpdateTagResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateTagResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UpdateTagResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.UpdateTagResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UpdateTagResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.UpdateTagResponse";
                };
    
                return UpdateTagResponse;
            })();
    
            tags.GetAvailableTagsRequest = (function() {
    
                /**
                 * Properties of a GetAvailableTagsRequest.
                 * @memberof infinitusai.tags
                 * @interface IGetAvailableTagsRequest
                 * @property {Array.<string>|null} [uuids] GetAvailableTagsRequest uuids
                 * @property {infinitusai.tasks.TaskType|null} [taskType] GetAvailableTagsRequest taskType
                 * @property {string|null} [name] GetAvailableTagsRequest name
                 */
    
                /**
                 * Constructs a new GetAvailableTagsRequest.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a GetAvailableTagsRequest.
                 * @implements IGetAvailableTagsRequest
                 * @constructor
                 * @param {infinitusai.tags.IGetAvailableTagsRequest=} [properties] Properties to set
                 */
                function GetAvailableTagsRequest(properties) {
                    this.uuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAvailableTagsRequest uuids.
                 * @member {Array.<string>} uuids
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @instance
                 */
                GetAvailableTagsRequest.prototype.uuids = $util.emptyArray;
    
                /**
                 * GetAvailableTagsRequest taskType.
                 * @member {infinitusai.tasks.TaskType} taskType
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @instance
                 */
                GetAvailableTagsRequest.prototype.taskType = 0;
    
                /**
                 * GetAvailableTagsRequest name.
                 * @member {string} name
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @instance
                 */
                GetAvailableTagsRequest.prototype.name = "";
    
                /**
                 * Creates a new GetAvailableTagsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {infinitusai.tags.IGetAvailableTagsRequest=} [properties] Properties to set
                 * @returns {infinitusai.tags.GetAvailableTagsRequest} GetAvailableTagsRequest instance
                 */
                GetAvailableTagsRequest.create = function create(properties) {
                    return new GetAvailableTagsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetAvailableTagsRequest message. Does not implicitly {@link infinitusai.tags.GetAvailableTagsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {infinitusai.tags.IGetAvailableTagsRequest} message GetAvailableTagsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAvailableTagsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuids != null && message.uuids.length)
                        for (var i = 0; i < message.uuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuids[i]);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAvailableTagsRequest message, length delimited. Does not implicitly {@link infinitusai.tags.GetAvailableTagsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {infinitusai.tags.IGetAvailableTagsRequest} message GetAvailableTagsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAvailableTagsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAvailableTagsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.GetAvailableTagsRequest} GetAvailableTagsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAvailableTagsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.GetAvailableTagsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.uuids && message.uuids.length))
                                    message.uuids = [];
                                message.uuids.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAvailableTagsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.GetAvailableTagsRequest} GetAvailableTagsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAvailableTagsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAvailableTagsRequest message.
                 * @function verify
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAvailableTagsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuids != null && message.hasOwnProperty("uuids")) {
                        if (!Array.isArray(message.uuids))
                            return "uuids: array expected";
                        for (var i = 0; i < message.uuids.length; ++i)
                            if (!$util.isString(message.uuids[i]))
                                return "uuids: string[] expected";
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetAvailableTagsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.GetAvailableTagsRequest} GetAvailableTagsRequest
                 */
                GetAvailableTagsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.GetAvailableTagsRequest)
                        return object;
                    var message = new $root.infinitusai.tags.GetAvailableTagsRequest();
                    if (object.uuids) {
                        if (!Array.isArray(object.uuids))
                            throw TypeError(".infinitusai.tags.GetAvailableTagsRequest.uuids: array expected");
                        message.uuids = [];
                        for (var i = 0; i < object.uuids.length; ++i)
                            message.uuids[i] = String(object.uuids[i]);
                    }
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAvailableTagsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {infinitusai.tags.GetAvailableTagsRequest} message GetAvailableTagsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAvailableTagsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uuids = [];
                    if (options.defaults) {
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.name = "";
                    }
                    if (message.uuids && message.uuids.length) {
                        object.uuids = [];
                        for (var j = 0; j < message.uuids.length; ++j)
                            object.uuids[j] = message.uuids[j];
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.tasks.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.tasks.TaskType[message.taskType] : message.taskType;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    return object;
                };
    
                /**
                 * Converts this GetAvailableTagsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAvailableTagsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAvailableTagsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.GetAvailableTagsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAvailableTagsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.GetAvailableTagsRequest";
                };
    
                return GetAvailableTagsRequest;
            })();
    
            tags.GetAvailableTagsResponse = (function() {
    
                /**
                 * Properties of a GetAvailableTagsResponse.
                 * @memberof infinitusai.tags
                 * @interface IGetAvailableTagsResponse
                 * @property {Array.<infinitusai.tags.ITag>|null} [tags] GetAvailableTagsResponse tags
                 */
    
                /**
                 * Constructs a new GetAvailableTagsResponse.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a GetAvailableTagsResponse.
                 * @implements IGetAvailableTagsResponse
                 * @constructor
                 * @param {infinitusai.tags.IGetAvailableTagsResponse=} [properties] Properties to set
                 */
                function GetAvailableTagsResponse(properties) {
                    this.tags = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetAvailableTagsResponse tags.
                 * @member {Array.<infinitusai.tags.ITag>} tags
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @instance
                 */
                GetAvailableTagsResponse.prototype.tags = $util.emptyArray;
    
                /**
                 * Creates a new GetAvailableTagsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {infinitusai.tags.IGetAvailableTagsResponse=} [properties] Properties to set
                 * @returns {infinitusai.tags.GetAvailableTagsResponse} GetAvailableTagsResponse instance
                 */
                GetAvailableTagsResponse.create = function create(properties) {
                    return new GetAvailableTagsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetAvailableTagsResponse message. Does not implicitly {@link infinitusai.tags.GetAvailableTagsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {infinitusai.tags.IGetAvailableTagsResponse} message GetAvailableTagsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAvailableTagsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tags != null && message.tags.length)
                        for (var i = 0; i < message.tags.length; ++i)
                            $root.infinitusai.tags.Tag.encode(message.tags[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetAvailableTagsResponse message, length delimited. Does not implicitly {@link infinitusai.tags.GetAvailableTagsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {infinitusai.tags.IGetAvailableTagsResponse} message GetAvailableTagsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetAvailableTagsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetAvailableTagsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.GetAvailableTagsResponse} GetAvailableTagsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAvailableTagsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.GetAvailableTagsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.tags && message.tags.length))
                                    message.tags = [];
                                message.tags.push($root.infinitusai.tags.Tag.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetAvailableTagsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.GetAvailableTagsResponse} GetAvailableTagsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetAvailableTagsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetAvailableTagsResponse message.
                 * @function verify
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAvailableTagsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tags != null && message.hasOwnProperty("tags")) {
                        if (!Array.isArray(message.tags))
                            return "tags: array expected";
                        for (var i = 0; i < message.tags.length; ++i) {
                            var error = $root.infinitusai.tags.Tag.verify(message.tags[i]);
                            if (error)
                                return "tags." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetAvailableTagsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.GetAvailableTagsResponse} GetAvailableTagsResponse
                 */
                GetAvailableTagsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.GetAvailableTagsResponse)
                        return object;
                    var message = new $root.infinitusai.tags.GetAvailableTagsResponse();
                    if (object.tags) {
                        if (!Array.isArray(object.tags))
                            throw TypeError(".infinitusai.tags.GetAvailableTagsResponse.tags: array expected");
                        message.tags = [];
                        for (var i = 0; i < object.tags.length; ++i) {
                            if (typeof object.tags[i] !== "object")
                                throw TypeError(".infinitusai.tags.GetAvailableTagsResponse.tags: object expected");
                            message.tags[i] = $root.infinitusai.tags.Tag.fromObject(object.tags[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetAvailableTagsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {infinitusai.tags.GetAvailableTagsResponse} message GetAvailableTagsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAvailableTagsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.tags = [];
                    if (message.tags && message.tags.length) {
                        object.tags = [];
                        for (var j = 0; j < message.tags.length; ++j)
                            object.tags[j] = $root.infinitusai.tags.Tag.toObject(message.tags[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetAvailableTagsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAvailableTagsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetAvailableTagsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.GetAvailableTagsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetAvailableTagsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.GetAvailableTagsResponse";
                };
    
                return GetAvailableTagsResponse;
            })();
    
            tags.TagTaskRequest = (function() {
    
                /**
                 * Properties of a TagTaskRequest.
                 * @memberof infinitusai.tags
                 * @interface ITagTaskRequest
                 * @property {string|null} [taskUuid] TagTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new TagTaskRequest.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a TagTaskRequest.
                 * @implements ITagTaskRequest
                 * @constructor
                 * @param {infinitusai.tags.ITagTaskRequest=} [properties] Properties to set
                 */
                function TagTaskRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TagTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @instance
                 */
                TagTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new TagTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {infinitusai.tags.ITagTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.tags.TagTaskRequest} TagTaskRequest instance
                 */
                TagTaskRequest.create = function create(properties) {
                    return new TagTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified TagTaskRequest message. Does not implicitly {@link infinitusai.tags.TagTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {infinitusai.tags.ITagTaskRequest} message TagTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TagTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified TagTaskRequest message, length delimited. Does not implicitly {@link infinitusai.tags.TagTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {infinitusai.tags.ITagTaskRequest} message TagTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TagTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TagTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.TagTaskRequest} TagTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TagTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.TagTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TagTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.TagTaskRequest} TagTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TagTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TagTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TagTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a TagTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.TagTaskRequest} TagTaskRequest
                 */
                TagTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.TagTaskRequest)
                        return object;
                    var message = new $root.infinitusai.tags.TagTaskRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TagTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {infinitusai.tags.TagTaskRequest} message TagTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TagTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this TagTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TagTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TagTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.TagTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TagTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.TagTaskRequest";
                };
    
                return TagTaskRequest;
            })();
    
            tags.TagTaskResponse = (function() {
    
                /**
                 * Properties of a TagTaskResponse.
                 * @memberof infinitusai.tags
                 * @interface ITagTaskResponse
                 * @property {Array.<infinitusai.tags.ITag>|null} [tags] TagTaskResponse tags
                 */
    
                /**
                 * Constructs a new TagTaskResponse.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a TagTaskResponse.
                 * @implements ITagTaskResponse
                 * @constructor
                 * @param {infinitusai.tags.ITagTaskResponse=} [properties] Properties to set
                 */
                function TagTaskResponse(properties) {
                    this.tags = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TagTaskResponse tags.
                 * @member {Array.<infinitusai.tags.ITag>} tags
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @instance
                 */
                TagTaskResponse.prototype.tags = $util.emptyArray;
    
                /**
                 * Creates a new TagTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {infinitusai.tags.ITagTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.tags.TagTaskResponse} TagTaskResponse instance
                 */
                TagTaskResponse.create = function create(properties) {
                    return new TagTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified TagTaskResponse message. Does not implicitly {@link infinitusai.tags.TagTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {infinitusai.tags.ITagTaskResponse} message TagTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TagTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tags != null && message.tags.length)
                        for (var i = 0; i < message.tags.length; ++i)
                            $root.infinitusai.tags.Tag.encode(message.tags[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified TagTaskResponse message, length delimited. Does not implicitly {@link infinitusai.tags.TagTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {infinitusai.tags.ITagTaskResponse} message TagTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TagTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TagTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.TagTaskResponse} TagTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TagTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.TagTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.tags && message.tags.length))
                                    message.tags = [];
                                message.tags.push($root.infinitusai.tags.Tag.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TagTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.TagTaskResponse} TagTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TagTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TagTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TagTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tags != null && message.hasOwnProperty("tags")) {
                        if (!Array.isArray(message.tags))
                            return "tags: array expected";
                        for (var i = 0; i < message.tags.length; ++i) {
                            var error = $root.infinitusai.tags.Tag.verify(message.tags[i]);
                            if (error)
                                return "tags." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a TagTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.TagTaskResponse} TagTaskResponse
                 */
                TagTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.TagTaskResponse)
                        return object;
                    var message = new $root.infinitusai.tags.TagTaskResponse();
                    if (object.tags) {
                        if (!Array.isArray(object.tags))
                            throw TypeError(".infinitusai.tags.TagTaskResponse.tags: array expected");
                        message.tags = [];
                        for (var i = 0; i < object.tags.length; ++i) {
                            if (typeof object.tags[i] !== "object")
                                throw TypeError(".infinitusai.tags.TagTaskResponse.tags: object expected");
                            message.tags[i] = $root.infinitusai.tags.Tag.fromObject(object.tags[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a TagTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {infinitusai.tags.TagTaskResponse} message TagTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TagTaskResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.tags = [];
                    if (message.tags && message.tags.length) {
                        object.tags = [];
                        for (var j = 0; j < message.tags.length; ++j)
                            object.tags[j] = $root.infinitusai.tags.Tag.toObject(message.tags[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this TagTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TagTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TagTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.TagTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TagTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.TagTaskResponse";
                };
    
                return TagTaskResponse;
            })();
    
            tags.GetTagsRequest = (function() {
    
                /**
                 * Properties of a GetTagsRequest.
                 * @memberof infinitusai.tags
                 * @interface IGetTagsRequest
                 * @property {Array.<infinitusai.tasks.TaskType>|null} [taskTypes] GetTagsRequest taskTypes
                 * @property {string|null} [name] GetTagsRequest name
                 * @property {string|null} [taskId] GetTagsRequest taskId
                 */
    
                /**
                 * Constructs a new GetTagsRequest.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a GetTagsRequest.
                 * @implements IGetTagsRequest
                 * @constructor
                 * @param {infinitusai.tags.IGetTagsRequest=} [properties] Properties to set
                 */
                function GetTagsRequest(properties) {
                    this.taskTypes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTagsRequest taskTypes.
                 * @member {Array.<infinitusai.tasks.TaskType>} taskTypes
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @instance
                 */
                GetTagsRequest.prototype.taskTypes = $util.emptyArray;
    
                /**
                 * GetTagsRequest name.
                 * @member {string} name
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @instance
                 */
                GetTagsRequest.prototype.name = "";
    
                /**
                 * GetTagsRequest taskId.
                 * @member {string} taskId
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @instance
                 */
                GetTagsRequest.prototype.taskId = "";
    
                /**
                 * Creates a new GetTagsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {infinitusai.tags.IGetTagsRequest=} [properties] Properties to set
                 * @returns {infinitusai.tags.GetTagsRequest} GetTagsRequest instance
                 */
                GetTagsRequest.create = function create(properties) {
                    return new GetTagsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetTagsRequest message. Does not implicitly {@link infinitusai.tags.GetTagsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {infinitusai.tags.IGetTagsRequest} message GetTagsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTagsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskTypes != null && message.taskTypes.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.taskTypes.length; ++i)
                            writer.int32(message.taskTypes[i]);
                        writer.ldelim();
                    }
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskId);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTagsRequest message, length delimited. Does not implicitly {@link infinitusai.tags.GetTagsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {infinitusai.tags.IGetTagsRequest} message GetTagsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTagsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTagsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.GetTagsRequest} GetTagsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTagsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.GetTagsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.taskTypes && message.taskTypes.length))
                                    message.taskTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.taskTypes.push(reader.int32());
                                } else
                                    message.taskTypes.push(reader.int32());
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTagsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.GetTagsRequest} GetTagsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTagsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTagsRequest message.
                 * @function verify
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTagsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskTypes != null && message.hasOwnProperty("taskTypes")) {
                        if (!Array.isArray(message.taskTypes))
                            return "taskTypes: array expected";
                        for (var i = 0; i < message.taskTypes.length; ++i)
                            switch (message.taskTypes[i]) {
                            default:
                                return "taskTypes: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                                break;
                            }
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        if (!$util.isString(message.taskId))
                            return "taskId: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetTagsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.GetTagsRequest} GetTagsRequest
                 */
                GetTagsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.GetTagsRequest)
                        return object;
                    var message = new $root.infinitusai.tags.GetTagsRequest();
                    if (object.taskTypes) {
                        if (!Array.isArray(object.taskTypes))
                            throw TypeError(".infinitusai.tags.GetTagsRequest.taskTypes: array expected");
                        message.taskTypes = [];
                        for (var i = 0; i < object.taskTypes.length; ++i)
                            switch (object.taskTypes[i]) {
                            default:
                                if (typeof object.taskTypes[i] === "number") {
                                    message.taskTypes[i] = object.taskTypes[i];
                                    break;
                                }
                            case "TASK_TYPE_UNKNOWN":
                            case 0:
                                message.taskTypes[i] = 0;
                                break;
                            case "TASK_TYPE_MED_SUPP_BV":
                            case 1:
                                message.taskTypes[i] = 1;
                                break;
                            case "TASK_TYPE_FULL_BI":
                            case 2:
                                message.taskTypes[i] = 2;
                                break;
                            case "TASK_TYPE_PBM_BV":
                            case 3:
                                message.taskTypes[i] = 3;
                                break;
                            case "TASK_TYPE_COVID_VACCINATIONS":
                            case 4:
                                message.taskTypes[i] = 4;
                                break;
                            case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                            case 5:
                                message.taskTypes[i] = 5;
                                break;
                            case "TASK_TYPE_INBOUND":
                            case 6:
                                message.taskTypes[i] = 6;
                                break;
                            case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                            case 7:
                                message.taskTypes[i] = 7;
                                break;
                            case "TASK_TYPE_CLAIMS_FOLLOWUP":
                            case 8:
                                message.taskTypes[i] = 8;
                                break;
                            case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                            case 9:
                                message.taskTypes[i] = 9;
                                break;
                            case "TASK_TYPE_DENTAL_BV":
                            case 10:
                                message.taskTypes[i] = 10;
                                break;
                            case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                            case 11:
                                message.taskTypes[i] = 11;
                                break;
                            case "TASK_TYPE_PATIENT_OUTREACH":
                            case 12:
                                message.taskTypes[i] = 12;
                                break;
                            case "TASK_TYPE_PROVIDER_OUTREACH":
                            case 13:
                                message.taskTypes[i] = 13;
                                break;
                            case "TASK_TYPE_FASTTRACK_MM":
                            case 14:
                                message.taskTypes[i] = 14;
                                break;
                            case "TASK_TYPE_FASTTRACK_PBM":
                            case 15:
                                message.taskTypes[i] = 15;
                                break;
                            case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                            case 16:
                                message.taskTypes[i] = 16;
                                break;
                            case "TASK_TYPE_BASIC_BV":
                            case 17:
                                message.taskTypes[i] = 17;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                            case 18:
                                message.taskTypes[i] = 18;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                            case 19:
                                message.taskTypes[i] = 19;
                                break;
                            case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                            case 20:
                                message.taskTypes[i] = 20;
                                break;
                            case "TASK_TYPE_PBM_DISCOVERY":
                            case 21:
                                message.taskTypes[i] = 21;
                                break;
                            }
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.taskId != null)
                        message.taskId = String(object.taskId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTagsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {infinitusai.tags.GetTagsRequest} message GetTagsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTagsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.taskTypes = [];
                    if (options.defaults) {
                        object.name = "";
                        object.taskId = "";
                    }
                    if (message.taskTypes && message.taskTypes.length) {
                        object.taskTypes = [];
                        for (var j = 0; j < message.taskTypes.length; ++j)
                            object.taskTypes[j] = options.enums === String ? $root.infinitusai.tasks.TaskType[message.taskTypes[j]] === undefined ? message.taskTypes[j] : $root.infinitusai.tasks.TaskType[message.taskTypes[j]] : message.taskTypes[j];
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        object.taskId = message.taskId;
                    return object;
                };
    
                /**
                 * Converts this GetTagsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTagsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTagsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.GetTagsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTagsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.GetTagsRequest";
                };
    
                return GetTagsRequest;
            })();
    
            tags.GetTagsResponse = (function() {
    
                /**
                 * Properties of a GetTagsResponse.
                 * @memberof infinitusai.tags
                 * @interface IGetTagsResponse
                 * @property {Array.<infinitusai.tags.ITag>|null} [tags] GetTagsResponse tags
                 */
    
                /**
                 * Constructs a new GetTagsResponse.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a GetTagsResponse.
                 * @implements IGetTagsResponse
                 * @constructor
                 * @param {infinitusai.tags.IGetTagsResponse=} [properties] Properties to set
                 */
                function GetTagsResponse(properties) {
                    this.tags = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetTagsResponse tags.
                 * @member {Array.<infinitusai.tags.ITag>} tags
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @instance
                 */
                GetTagsResponse.prototype.tags = $util.emptyArray;
    
                /**
                 * Creates a new GetTagsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {infinitusai.tags.IGetTagsResponse=} [properties] Properties to set
                 * @returns {infinitusai.tags.GetTagsResponse} GetTagsResponse instance
                 */
                GetTagsResponse.create = function create(properties) {
                    return new GetTagsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetTagsResponse message. Does not implicitly {@link infinitusai.tags.GetTagsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {infinitusai.tags.IGetTagsResponse} message GetTagsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTagsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tags != null && message.tags.length)
                        for (var i = 0; i < message.tags.length; ++i)
                            $root.infinitusai.tags.Tag.encode(message.tags[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetTagsResponse message, length delimited. Does not implicitly {@link infinitusai.tags.GetTagsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {infinitusai.tags.IGetTagsResponse} message GetTagsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetTagsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetTagsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.GetTagsResponse} GetTagsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTagsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.GetTagsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.tags && message.tags.length))
                                    message.tags = [];
                                message.tags.push($root.infinitusai.tags.Tag.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetTagsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.GetTagsResponse} GetTagsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetTagsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetTagsResponse message.
                 * @function verify
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTagsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tags != null && message.hasOwnProperty("tags")) {
                        if (!Array.isArray(message.tags))
                            return "tags: array expected";
                        for (var i = 0; i < message.tags.length; ++i) {
                            var error = $root.infinitusai.tags.Tag.verify(message.tags[i]);
                            if (error)
                                return "tags." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetTagsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.GetTagsResponse} GetTagsResponse
                 */
                GetTagsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.GetTagsResponse)
                        return object;
                    var message = new $root.infinitusai.tags.GetTagsResponse();
                    if (object.tags) {
                        if (!Array.isArray(object.tags))
                            throw TypeError(".infinitusai.tags.GetTagsResponse.tags: array expected");
                        message.tags = [];
                        for (var i = 0; i < object.tags.length; ++i) {
                            if (typeof object.tags[i] !== "object")
                                throw TypeError(".infinitusai.tags.GetTagsResponse.tags: object expected");
                            message.tags[i] = $root.infinitusai.tags.Tag.fromObject(object.tags[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetTagsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {infinitusai.tags.GetTagsResponse} message GetTagsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTagsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.tags = [];
                    if (message.tags && message.tags.length) {
                        object.tags = [];
                        for (var j = 0; j < message.tags.length; ++j)
                            object.tags[j] = $root.infinitusai.tags.Tag.toObject(message.tags[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetTagsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTagsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetTagsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.GetTagsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetTagsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.GetTagsResponse";
                };
    
                return GetTagsResponse;
            })();
    
            tags.TaggedTasksAndTagsRequest = (function() {
    
                /**
                 * Properties of a TaggedTasksAndTagsRequest.
                 * @memberof infinitusai.tags
                 * @interface ITaggedTasksAndTagsRequest
                 * @property {string|null} [name] TaggedTasksAndTagsRequest name
                 */
    
                /**
                 * Constructs a new TaggedTasksAndTagsRequest.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a TaggedTasksAndTagsRequest.
                 * @implements ITaggedTasksAndTagsRequest
                 * @constructor
                 * @param {infinitusai.tags.ITaggedTasksAndTagsRequest=} [properties] Properties to set
                 */
                function TaggedTasksAndTagsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaggedTasksAndTagsRequest name.
                 * @member {string} name
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @instance
                 */
                TaggedTasksAndTagsRequest.prototype.name = "";
    
                /**
                 * Creates a new TaggedTasksAndTagsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {infinitusai.tags.ITaggedTasksAndTagsRequest=} [properties] Properties to set
                 * @returns {infinitusai.tags.TaggedTasksAndTagsRequest} TaggedTasksAndTagsRequest instance
                 */
                TaggedTasksAndTagsRequest.create = function create(properties) {
                    return new TaggedTasksAndTagsRequest(properties);
                };
    
                /**
                 * Encodes the specified TaggedTasksAndTagsRequest message. Does not implicitly {@link infinitusai.tags.TaggedTasksAndTagsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {infinitusai.tags.ITaggedTasksAndTagsRequest} message TaggedTasksAndTagsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaggedTasksAndTagsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    return writer;
                };
    
                /**
                 * Encodes the specified TaggedTasksAndTagsRequest message, length delimited. Does not implicitly {@link infinitusai.tags.TaggedTasksAndTagsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {infinitusai.tags.ITaggedTasksAndTagsRequest} message TaggedTasksAndTagsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaggedTasksAndTagsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaggedTasksAndTagsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.TaggedTasksAndTagsRequest} TaggedTasksAndTagsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaggedTasksAndTagsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.TaggedTasksAndTagsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaggedTasksAndTagsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.TaggedTasksAndTagsRequest} TaggedTasksAndTagsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaggedTasksAndTagsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaggedTasksAndTagsRequest message.
                 * @function verify
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaggedTasksAndTagsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    return null;
                };
    
                /**
                 * Creates a TaggedTasksAndTagsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.TaggedTasksAndTagsRequest} TaggedTasksAndTagsRequest
                 */
                TaggedTasksAndTagsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.TaggedTasksAndTagsRequest)
                        return object;
                    var message = new $root.infinitusai.tags.TaggedTasksAndTagsRequest();
                    if (object.name != null)
                        message.name = String(object.name);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaggedTasksAndTagsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {infinitusai.tags.TaggedTasksAndTagsRequest} message TaggedTasksAndTagsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaggedTasksAndTagsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.name = "";
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    return object;
                };
    
                /**
                 * Converts this TaggedTasksAndTagsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaggedTasksAndTagsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaggedTasksAndTagsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.TaggedTasksAndTagsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaggedTasksAndTagsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.TaggedTasksAndTagsRequest";
                };
    
                return TaggedTasksAndTagsRequest;
            })();
    
            tags.TaggedTasksAndTagsResponse = (function() {
    
                /**
                 * Properties of a TaggedTasksAndTagsResponse.
                 * @memberof infinitusai.tags
                 * @interface ITaggedTasksAndTagsResponse
                 * @property {Array.<infinitusai.tags.TaggedTasksAndTagsResponse.ITaskAndTags>|null} [data] TaggedTasksAndTagsResponse data
                 */
    
                /**
                 * Constructs a new TaggedTasksAndTagsResponse.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a TaggedTasksAndTagsResponse.
                 * @implements ITaggedTasksAndTagsResponse
                 * @constructor
                 * @param {infinitusai.tags.ITaggedTasksAndTagsResponse=} [properties] Properties to set
                 */
                function TaggedTasksAndTagsResponse(properties) {
                    this.data = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaggedTasksAndTagsResponse data.
                 * @member {Array.<infinitusai.tags.TaggedTasksAndTagsResponse.ITaskAndTags>} data
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @instance
                 */
                TaggedTasksAndTagsResponse.prototype.data = $util.emptyArray;
    
                /**
                 * Creates a new TaggedTasksAndTagsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {infinitusai.tags.ITaggedTasksAndTagsResponse=} [properties] Properties to set
                 * @returns {infinitusai.tags.TaggedTasksAndTagsResponse} TaggedTasksAndTagsResponse instance
                 */
                TaggedTasksAndTagsResponse.create = function create(properties) {
                    return new TaggedTasksAndTagsResponse(properties);
                };
    
                /**
                 * Encodes the specified TaggedTasksAndTagsResponse message. Does not implicitly {@link infinitusai.tags.TaggedTasksAndTagsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {infinitusai.tags.ITaggedTasksAndTagsResponse} message TaggedTasksAndTagsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaggedTasksAndTagsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (var i = 0; i < message.data.length; ++i)
                            $root.infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified TaggedTasksAndTagsResponse message, length delimited. Does not implicitly {@link infinitusai.tags.TaggedTasksAndTagsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {infinitusai.tags.ITaggedTasksAndTagsResponse} message TaggedTasksAndTagsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaggedTasksAndTagsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaggedTasksAndTagsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.TaggedTasksAndTagsResponse} TaggedTasksAndTagsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaggedTasksAndTagsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.TaggedTasksAndTagsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaggedTasksAndTagsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.TaggedTasksAndTagsResponse} TaggedTasksAndTagsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaggedTasksAndTagsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaggedTasksAndTagsResponse message.
                 * @function verify
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaggedTasksAndTagsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (var i = 0; i < message.data.length; ++i) {
                            var error = $root.infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a TaggedTasksAndTagsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.TaggedTasksAndTagsResponse} TaggedTasksAndTagsResponse
                 */
                TaggedTasksAndTagsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.TaggedTasksAndTagsResponse)
                        return object;
                    var message = new $root.infinitusai.tags.TaggedTasksAndTagsResponse();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".infinitusai.tags.TaggedTasksAndTagsResponse.data: array expected");
                        message.data = [];
                        for (var i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".infinitusai.tags.TaggedTasksAndTagsResponse.data: object expected");
                            message.data[i] = $root.infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaggedTasksAndTagsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {infinitusai.tags.TaggedTasksAndTagsResponse} message TaggedTasksAndTagsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaggedTasksAndTagsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (var j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.toObject(message.data[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this TaggedTasksAndTagsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaggedTasksAndTagsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaggedTasksAndTagsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaggedTasksAndTagsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.TaggedTasksAndTagsResponse";
                };
    
                TaggedTasksAndTagsResponse.TaskAndTags = (function() {
    
                    /**
                     * Properties of a TaskAndTags.
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                     * @interface ITaskAndTags
                     * @property {string|null} [taskUuid] TaskAndTags taskUuid
                     * @property {Array.<infinitusai.tags.ITag>|null} [tags] TaskAndTags tags
                     */
    
                    /**
                     * Constructs a new TaskAndTags.
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse
                     * @classdesc Represents a TaskAndTags.
                     * @implements ITaskAndTags
                     * @constructor
                     * @param {infinitusai.tags.TaggedTasksAndTagsResponse.ITaskAndTags=} [properties] Properties to set
                     */
                    function TaskAndTags(properties) {
                        this.tags = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TaskAndTags taskUuid.
                     * @member {string} taskUuid
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @instance
                     */
                    TaskAndTags.prototype.taskUuid = "";
    
                    /**
                     * TaskAndTags tags.
                     * @member {Array.<infinitusai.tags.ITag>} tags
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @instance
                     */
                    TaskAndTags.prototype.tags = $util.emptyArray;
    
                    /**
                     * Creates a new TaskAndTags instance using the specified properties.
                     * @function create
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {infinitusai.tags.TaggedTasksAndTagsResponse.ITaskAndTags=} [properties] Properties to set
                     * @returns {infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags} TaskAndTags instance
                     */
                    TaskAndTags.create = function create(properties) {
                        return new TaskAndTags(properties);
                    };
    
                    /**
                     * Encodes the specified TaskAndTags message. Does not implicitly {@link infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {infinitusai.tags.TaggedTasksAndTagsResponse.ITaskAndTags} message TaskAndTags message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskAndTags.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                        if (message.tags != null && message.tags.length)
                            for (var i = 0; i < message.tags.length; ++i)
                                $root.infinitusai.tags.Tag.encode(message.tags[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TaskAndTags message, length delimited. Does not implicitly {@link infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {infinitusai.tags.TaggedTasksAndTagsResponse.ITaskAndTags} message TaskAndTags message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskAndTags.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TaskAndTags message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags} TaskAndTags
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskAndTags.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.taskUuid = reader.string();
                                    break;
                                }
                            case 2: {
                                    if (!(message.tags && message.tags.length))
                                        message.tags = [];
                                    message.tags.push($root.infinitusai.tags.Tag.decode(reader, reader.uint32()));
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TaskAndTags message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags} TaskAndTags
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskAndTags.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TaskAndTags message.
                     * @function verify
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskAndTags.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                            if (!$util.isString(message.taskUuid))
                                return "taskUuid: string expected";
                        if (message.tags != null && message.hasOwnProperty("tags")) {
                            if (!Array.isArray(message.tags))
                                return "tags: array expected";
                            for (var i = 0; i < message.tags.length; ++i) {
                                var error = $root.infinitusai.tags.Tag.verify(message.tags[i]);
                                if (error)
                                    return "tags." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TaskAndTags message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags} TaskAndTags
                     */
                    TaskAndTags.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags)
                            return object;
                        var message = new $root.infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags();
                        if (object.taskUuid != null)
                            message.taskUuid = String(object.taskUuid);
                        if (object.tags) {
                            if (!Array.isArray(object.tags))
                                throw TypeError(".infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.tags: array expected");
                            message.tags = [];
                            for (var i = 0; i < object.tags.length; ++i) {
                                if (typeof object.tags[i] !== "object")
                                    throw TypeError(".infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags.tags: object expected");
                                message.tags[i] = $root.infinitusai.tags.Tag.fromObject(object.tags[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TaskAndTags message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags} message TaskAndTags
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskAndTags.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tags = [];
                        if (options.defaults)
                            object.taskUuid = "";
                        if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                            object.taskUuid = message.taskUuid;
                        if (message.tags && message.tags.length) {
                            object.tags = [];
                            for (var j = 0; j < message.tags.length; ++j)
                                object.tags[j] = $root.infinitusai.tags.Tag.toObject(message.tags[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this TaskAndTags to JSON.
                     * @function toJSON
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskAndTags.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for TaskAndTags
                     * @function getTypeUrl
                     * @memberof infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TaskAndTags.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusai.tags.TaggedTasksAndTagsResponse.TaskAndTags";
                    };
    
                    return TaskAndTags;
                })();
    
                return TaggedTasksAndTagsResponse;
            })();
    
            tags.CompleteIntegrationTaskRequest = (function() {
    
                /**
                 * Properties of a CompleteIntegrationTaskRequest.
                 * @memberof infinitusai.tags
                 * @interface ICompleteIntegrationTaskRequest
                 * @property {Array.<string>|null} [tagUuids] CompleteIntegrationTaskRequest tagUuids
                 * @property {string|null} [taskUuid] CompleteIntegrationTaskRequest taskUuid
                 */
    
                /**
                 * Constructs a new CompleteIntegrationTaskRequest.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a CompleteIntegrationTaskRequest.
                 * @implements ICompleteIntegrationTaskRequest
                 * @constructor
                 * @param {infinitusai.tags.ICompleteIntegrationTaskRequest=} [properties] Properties to set
                 */
                function CompleteIntegrationTaskRequest(properties) {
                    this.tagUuids = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CompleteIntegrationTaskRequest tagUuids.
                 * @member {Array.<string>} tagUuids
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @instance
                 */
                CompleteIntegrationTaskRequest.prototype.tagUuids = $util.emptyArray;
    
                /**
                 * CompleteIntegrationTaskRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @instance
                 */
                CompleteIntegrationTaskRequest.prototype.taskUuid = "";
    
                /**
                 * Creates a new CompleteIntegrationTaskRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {infinitusai.tags.ICompleteIntegrationTaskRequest=} [properties] Properties to set
                 * @returns {infinitusai.tags.CompleteIntegrationTaskRequest} CompleteIntegrationTaskRequest instance
                 */
                CompleteIntegrationTaskRequest.create = function create(properties) {
                    return new CompleteIntegrationTaskRequest(properties);
                };
    
                /**
                 * Encodes the specified CompleteIntegrationTaskRequest message. Does not implicitly {@link infinitusai.tags.CompleteIntegrationTaskRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {infinitusai.tags.ICompleteIntegrationTaskRequest} message CompleteIntegrationTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteIntegrationTaskRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tagUuids != null && message.tagUuids.length)
                        for (var i = 0; i < message.tagUuids.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.tagUuids[i]);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified CompleteIntegrationTaskRequest message, length delimited. Does not implicitly {@link infinitusai.tags.CompleteIntegrationTaskRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {infinitusai.tags.ICompleteIntegrationTaskRequest} message CompleteIntegrationTaskRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteIntegrationTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CompleteIntegrationTaskRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.CompleteIntegrationTaskRequest} CompleteIntegrationTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteIntegrationTaskRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.CompleteIntegrationTaskRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.tagUuids && message.tagUuids.length))
                                    message.tagUuids = [];
                                message.tagUuids.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CompleteIntegrationTaskRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.CompleteIntegrationTaskRequest} CompleteIntegrationTaskRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteIntegrationTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CompleteIntegrationTaskRequest message.
                 * @function verify
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteIntegrationTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tagUuids != null && message.hasOwnProperty("tagUuids")) {
                        if (!Array.isArray(message.tagUuids))
                            return "tagUuids: array expected";
                        for (var i = 0; i < message.tagUuids.length; ++i)
                            if (!$util.isString(message.tagUuids[i]))
                                return "tagUuids: string[] expected";
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a CompleteIntegrationTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.CompleteIntegrationTaskRequest} CompleteIntegrationTaskRequest
                 */
                CompleteIntegrationTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.CompleteIntegrationTaskRequest)
                        return object;
                    var message = new $root.infinitusai.tags.CompleteIntegrationTaskRequest();
                    if (object.tagUuids) {
                        if (!Array.isArray(object.tagUuids))
                            throw TypeError(".infinitusai.tags.CompleteIntegrationTaskRequest.tagUuids: array expected");
                        message.tagUuids = [];
                        for (var i = 0; i < object.tagUuids.length; ++i)
                            message.tagUuids[i] = String(object.tagUuids[i]);
                    }
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CompleteIntegrationTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {infinitusai.tags.CompleteIntegrationTaskRequest} message CompleteIntegrationTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteIntegrationTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.tagUuids = [];
                    if (options.defaults)
                        object.taskUuid = "";
                    if (message.tagUuids && message.tagUuids.length) {
                        object.tagUuids = [];
                        for (var j = 0; j < message.tagUuids.length; ++j)
                            object.tagUuids[j] = message.tagUuids[j];
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    return object;
                };
    
                /**
                 * Converts this CompleteIntegrationTaskRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteIntegrationTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CompleteIntegrationTaskRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.CompleteIntegrationTaskRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CompleteIntegrationTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.CompleteIntegrationTaskRequest";
                };
    
                return CompleteIntegrationTaskRequest;
            })();
    
            tags.CompleteIntegrationTaskResponse = (function() {
    
                /**
                 * Properties of a CompleteIntegrationTaskResponse.
                 * @memberof infinitusai.tags
                 * @interface ICompleteIntegrationTaskResponse
                 */
    
                /**
                 * Constructs a new CompleteIntegrationTaskResponse.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a CompleteIntegrationTaskResponse.
                 * @implements ICompleteIntegrationTaskResponse
                 * @constructor
                 * @param {infinitusai.tags.ICompleteIntegrationTaskResponse=} [properties] Properties to set
                 */
                function CompleteIntegrationTaskResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new CompleteIntegrationTaskResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {infinitusai.tags.ICompleteIntegrationTaskResponse=} [properties] Properties to set
                 * @returns {infinitusai.tags.CompleteIntegrationTaskResponse} CompleteIntegrationTaskResponse instance
                 */
                CompleteIntegrationTaskResponse.create = function create(properties) {
                    return new CompleteIntegrationTaskResponse(properties);
                };
    
                /**
                 * Encodes the specified CompleteIntegrationTaskResponse message. Does not implicitly {@link infinitusai.tags.CompleteIntegrationTaskResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {infinitusai.tags.ICompleteIntegrationTaskResponse} message CompleteIntegrationTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteIntegrationTaskResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified CompleteIntegrationTaskResponse message, length delimited. Does not implicitly {@link infinitusai.tags.CompleteIntegrationTaskResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {infinitusai.tags.ICompleteIntegrationTaskResponse} message CompleteIntegrationTaskResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteIntegrationTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CompleteIntegrationTaskResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.CompleteIntegrationTaskResponse} CompleteIntegrationTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteIntegrationTaskResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.CompleteIntegrationTaskResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CompleteIntegrationTaskResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.CompleteIntegrationTaskResponse} CompleteIntegrationTaskResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteIntegrationTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CompleteIntegrationTaskResponse message.
                 * @function verify
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteIntegrationTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a CompleteIntegrationTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.CompleteIntegrationTaskResponse} CompleteIntegrationTaskResponse
                 */
                CompleteIntegrationTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.CompleteIntegrationTaskResponse)
                        return object;
                    return new $root.infinitusai.tags.CompleteIntegrationTaskResponse();
                };
    
                /**
                 * Creates a plain object from a CompleteIntegrationTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {infinitusai.tags.CompleteIntegrationTaskResponse} message CompleteIntegrationTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteIntegrationTaskResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this CompleteIntegrationTaskResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteIntegrationTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for CompleteIntegrationTaskResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.CompleteIntegrationTaskResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CompleteIntegrationTaskResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.CompleteIntegrationTaskResponse";
                };
    
                return CompleteIntegrationTaskResponse;
            })();
    
            tags.DeleteTagRequest = (function() {
    
                /**
                 * Properties of a DeleteTagRequest.
                 * @memberof infinitusai.tags
                 * @interface IDeleteTagRequest
                 * @property {string|null} [uuid] DeleteTagRequest uuid
                 */
    
                /**
                 * Constructs a new DeleteTagRequest.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a DeleteTagRequest.
                 * @implements IDeleteTagRequest
                 * @constructor
                 * @param {infinitusai.tags.IDeleteTagRequest=} [properties] Properties to set
                 */
                function DeleteTagRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DeleteTagRequest uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @instance
                 */
                DeleteTagRequest.prototype.uuid = "";
    
                /**
                 * Creates a new DeleteTagRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {infinitusai.tags.IDeleteTagRequest=} [properties] Properties to set
                 * @returns {infinitusai.tags.DeleteTagRequest} DeleteTagRequest instance
                 */
                DeleteTagRequest.create = function create(properties) {
                    return new DeleteTagRequest(properties);
                };
    
                /**
                 * Encodes the specified DeleteTagRequest message. Does not implicitly {@link infinitusai.tags.DeleteTagRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {infinitusai.tags.IDeleteTagRequest} message DeleteTagRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteTagRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteTagRequest message, length delimited. Does not implicitly {@link infinitusai.tags.DeleteTagRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {infinitusai.tags.IDeleteTagRequest} message DeleteTagRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteTagRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteTagRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.DeleteTagRequest} DeleteTagRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteTagRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.DeleteTagRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteTagRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.DeleteTagRequest} DeleteTagRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteTagRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteTagRequest message.
                 * @function verify
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteTagRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteTagRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.DeleteTagRequest} DeleteTagRequest
                 */
                DeleteTagRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.DeleteTagRequest)
                        return object;
                    var message = new $root.infinitusai.tags.DeleteTagRequest();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DeleteTagRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {infinitusai.tags.DeleteTagRequest} message DeleteTagRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteTagRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.uuid = "";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    return object;
                };
    
                /**
                 * Converts this DeleteTagRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteTagRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteTagRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.DeleteTagRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteTagRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.DeleteTagRequest";
                };
    
                return DeleteTagRequest;
            })();
    
            tags.DeleteTagResponse = (function() {
    
                /**
                 * Properties of a DeleteTagResponse.
                 * @memberof infinitusai.tags
                 * @interface IDeleteTagResponse
                 */
    
                /**
                 * Constructs a new DeleteTagResponse.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a DeleteTagResponse.
                 * @implements IDeleteTagResponse
                 * @constructor
                 * @param {infinitusai.tags.IDeleteTagResponse=} [properties] Properties to set
                 */
                function DeleteTagResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new DeleteTagResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {infinitusai.tags.IDeleteTagResponse=} [properties] Properties to set
                 * @returns {infinitusai.tags.DeleteTagResponse} DeleteTagResponse instance
                 */
                DeleteTagResponse.create = function create(properties) {
                    return new DeleteTagResponse(properties);
                };
    
                /**
                 * Encodes the specified DeleteTagResponse message. Does not implicitly {@link infinitusai.tags.DeleteTagResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {infinitusai.tags.IDeleteTagResponse} message DeleteTagResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteTagResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified DeleteTagResponse message, length delimited. Does not implicitly {@link infinitusai.tags.DeleteTagResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {infinitusai.tags.IDeleteTagResponse} message DeleteTagResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeleteTagResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DeleteTagResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.DeleteTagResponse} DeleteTagResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteTagResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.DeleteTagResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DeleteTagResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.DeleteTagResponse} DeleteTagResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeleteTagResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DeleteTagResponse message.
                 * @function verify
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteTagResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a DeleteTagResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.DeleteTagResponse} DeleteTagResponse
                 */
                DeleteTagResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.DeleteTagResponse)
                        return object;
                    return new $root.infinitusai.tags.DeleteTagResponse();
                };
    
                /**
                 * Creates a plain object from a DeleteTagResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {infinitusai.tags.DeleteTagResponse} message DeleteTagResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteTagResponse.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this DeleteTagResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteTagResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DeleteTagResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.DeleteTagResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DeleteTagResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.DeleteTagResponse";
                };
    
                return DeleteTagResponse;
            })();
    
            tags.Tag = (function() {
    
                /**
                 * Properties of a Tag.
                 * @memberof infinitusai.tags
                 * @interface ITag
                 * @property {string|null} [uuid] Tag uuid
                 * @property {infinitusai.tasks.TaskType|null} [taskType] Tag taskType
                 * @property {string|null} [name] Tag name
                 * @property {string|null} [key] Tag key
                 * @property {string|null} [value] Tag value
                 * @property {string|null} [category] Tag category
                 * @property {number|Long|null} [createTimeMillis] Tag createTimeMillis
                 * @property {number|Long|null} [updateTimeMillis] Tag updateTimeMillis
                 * @property {string|null} [lastUpdatedBy] Tag lastUpdatedBy
                 */
    
                /**
                 * Constructs a new Tag.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a Tag.
                 * @implements ITag
                 * @constructor
                 * @param {infinitusai.tags.ITag=} [properties] Properties to set
                 */
                function Tag(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Tag uuid.
                 * @member {string} uuid
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.uuid = "";
    
                /**
                 * Tag taskType.
                 * @member {infinitusai.tasks.TaskType} taskType
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.taskType = 0;
    
                /**
                 * Tag name.
                 * @member {string} name
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.name = "";
    
                /**
                 * Tag key.
                 * @member {string} key
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.key = "";
    
                /**
                 * Tag value.
                 * @member {string} value
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.value = "";
    
                /**
                 * Tag category.
                 * @member {string} category
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.category = "";
    
                /**
                 * Tag createTimeMillis.
                 * @member {number|Long} createTimeMillis
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.createTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Tag updateTimeMillis.
                 * @member {number|Long} updateTimeMillis
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.updateTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Tag lastUpdatedBy.
                 * @member {string} lastUpdatedBy
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 */
                Tag.prototype.lastUpdatedBy = "";
    
                /**
                 * Creates a new Tag instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {infinitusai.tags.ITag=} [properties] Properties to set
                 * @returns {infinitusai.tags.Tag} Tag instance
                 */
                Tag.create = function create(properties) {
                    return new Tag(properties);
                };
    
                /**
                 * Encodes the specified Tag message. Does not implicitly {@link infinitusai.tags.Tag.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {infinitusai.tags.ITag} message Tag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tag.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskType);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.key);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.value);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.category);
                    if (message.createTimeMillis != null && Object.hasOwnProperty.call(message, "createTimeMillis"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createTimeMillis);
                    if (message.updateTimeMillis != null && Object.hasOwnProperty.call(message, "updateTimeMillis"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.updateTimeMillis);
                    if (message.lastUpdatedBy != null && Object.hasOwnProperty.call(message, "lastUpdatedBy"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.lastUpdatedBy);
                    return writer;
                };
    
                /**
                 * Encodes the specified Tag message, length delimited. Does not implicitly {@link infinitusai.tags.Tag.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {infinitusai.tags.ITag} message Tag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Tag.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Tag message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.Tag} Tag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tag.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.Tag();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                message.key = reader.string();
                                break;
                            }
                        case 5: {
                                message.value = reader.string();
                                break;
                            }
                        case 6: {
                                message.category = reader.string();
                                break;
                            }
                        case 7: {
                                message.createTimeMillis = reader.int64();
                                break;
                            }
                        case 8: {
                                message.updateTimeMillis = reader.int64();
                                break;
                            }
                        case 9: {
                                message.lastUpdatedBy = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Tag message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.Tag} Tag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Tag.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Tag message.
                 * @function verify
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Tag.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        if (!$util.isString(message.uuid))
                            return "uuid: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (!$util.isInteger(message.createTimeMillis) && !(message.createTimeMillis && $util.isInteger(message.createTimeMillis.low) && $util.isInteger(message.createTimeMillis.high)))
                            return "createTimeMillis: integer|Long expected";
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (!$util.isInteger(message.updateTimeMillis) && !(message.updateTimeMillis && $util.isInteger(message.updateTimeMillis.low) && $util.isInteger(message.updateTimeMillis.high)))
                            return "updateTimeMillis: integer|Long expected";
                    if (message.lastUpdatedBy != null && message.hasOwnProperty("lastUpdatedBy"))
                        if (!$util.isString(message.lastUpdatedBy))
                            return "lastUpdatedBy: string expected";
                    return null;
                };
    
                /**
                 * Creates a Tag message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.Tag} Tag
                 */
                Tag.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.Tag)
                        return object;
                    var message = new $root.infinitusai.tags.Tag();
                    if (object.uuid != null)
                        message.uuid = String(object.uuid);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.createTimeMillis != null)
                        if ($util.Long)
                            (message.createTimeMillis = $util.Long.fromValue(object.createTimeMillis)).unsigned = false;
                        else if (typeof object.createTimeMillis === "string")
                            message.createTimeMillis = parseInt(object.createTimeMillis, 10);
                        else if (typeof object.createTimeMillis === "number")
                            message.createTimeMillis = object.createTimeMillis;
                        else if (typeof object.createTimeMillis === "object")
                            message.createTimeMillis = new $util.LongBits(object.createTimeMillis.low >>> 0, object.createTimeMillis.high >>> 0).toNumber();
                    if (object.updateTimeMillis != null)
                        if ($util.Long)
                            (message.updateTimeMillis = $util.Long.fromValue(object.updateTimeMillis)).unsigned = false;
                        else if (typeof object.updateTimeMillis === "string")
                            message.updateTimeMillis = parseInt(object.updateTimeMillis, 10);
                        else if (typeof object.updateTimeMillis === "number")
                            message.updateTimeMillis = object.updateTimeMillis;
                        else if (typeof object.updateTimeMillis === "object")
                            message.updateTimeMillis = new $util.LongBits(object.updateTimeMillis.low >>> 0, object.updateTimeMillis.high >>> 0).toNumber();
                    if (object.lastUpdatedBy != null)
                        message.lastUpdatedBy = String(object.lastUpdatedBy);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Tag message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {infinitusai.tags.Tag} message Tag
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Tag.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uuid = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.name = "";
                        object.key = "";
                        object.value = "";
                        object.category = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.createTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createTimeMillis = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.updateTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updateTimeMillis = options.longs === String ? "0" : 0;
                        object.lastUpdatedBy = "";
                    }
                    if (message.uuid != null && message.hasOwnProperty("uuid"))
                        object.uuid = message.uuid;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.tasks.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.tasks.TaskType[message.taskType] : message.taskType;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.createTimeMillis != null && message.hasOwnProperty("createTimeMillis"))
                        if (typeof message.createTimeMillis === "number")
                            object.createTimeMillis = options.longs === String ? String(message.createTimeMillis) : message.createTimeMillis;
                        else
                            object.createTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMillis) : options.longs === Number ? new $util.LongBits(message.createTimeMillis.low >>> 0, message.createTimeMillis.high >>> 0).toNumber() : message.createTimeMillis;
                    if (message.updateTimeMillis != null && message.hasOwnProperty("updateTimeMillis"))
                        if (typeof message.updateTimeMillis === "number")
                            object.updateTimeMillis = options.longs === String ? String(message.updateTimeMillis) : message.updateTimeMillis;
                        else
                            object.updateTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMillis) : options.longs === Number ? new $util.LongBits(message.updateTimeMillis.low >>> 0, message.updateTimeMillis.high >>> 0).toNumber() : message.updateTimeMillis;
                    if (message.lastUpdatedBy != null && message.hasOwnProperty("lastUpdatedBy"))
                        object.lastUpdatedBy = message.lastUpdatedBy;
                    return object;
                };
    
                /**
                 * Converts this Tag to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.Tag
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Tag.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Tag
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.Tag
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Tag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.Tag";
                };
    
                return Tag;
            })();
    
            tags.TaskTag = (function() {
    
                /**
                 * Properties of a TaskTag.
                 * @memberof infinitusai.tags
                 * @interface ITaskTag
                 * @property {string|null} [tagUuid] TaskTag tagUuid
                 * @property {string|null} [taskUuid] TaskTag taskUuid
                 * @property {string|null} [orgUuid] TaskTag orgUuid
                 */
    
                /**
                 * Constructs a new TaskTag.
                 * @memberof infinitusai.tags
                 * @classdesc Represents a TaskTag.
                 * @implements ITaskTag
                 * @constructor
                 * @param {infinitusai.tags.ITaskTag=} [properties] Properties to set
                 */
                function TaskTag(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskTag tagUuid.
                 * @member {string} tagUuid
                 * @memberof infinitusai.tags.TaskTag
                 * @instance
                 */
                TaskTag.prototype.tagUuid = "";
    
                /**
                 * TaskTag taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.tags.TaskTag
                 * @instance
                 */
                TaskTag.prototype.taskUuid = "";
    
                /**
                 * TaskTag orgUuid.
                 * @member {string} orgUuid
                 * @memberof infinitusai.tags.TaskTag
                 * @instance
                 */
                TaskTag.prototype.orgUuid = "";
    
                /**
                 * Creates a new TaskTag instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {infinitusai.tags.ITaskTag=} [properties] Properties to set
                 * @returns {infinitusai.tags.TaskTag} TaskTag instance
                 */
                TaskTag.create = function create(properties) {
                    return new TaskTag(properties);
                };
    
                /**
                 * Encodes the specified TaskTag message. Does not implicitly {@link infinitusai.tags.TaskTag.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {infinitusai.tags.ITaskTag} message TaskTag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskTag.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.tagUuid != null && Object.hasOwnProperty.call(message, "tagUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.tagUuid);
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskUuid);
                    if (message.orgUuid != null && Object.hasOwnProperty.call(message, "orgUuid"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orgUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskTag message, length delimited. Does not implicitly {@link infinitusai.tags.TaskTag.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {infinitusai.tags.ITaskTag} message TaskTag message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskTag.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskTag message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tags.TaskTag} TaskTag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskTag.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tags.TaskTag();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.tagUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.orgUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskTag message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tags.TaskTag} TaskTag
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskTag.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskTag message.
                 * @function verify
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskTag.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tagUuid != null && message.hasOwnProperty("tagUuid"))
                        if (!$util.isString(message.tagUuid))
                            return "tagUuid: string expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        if (!$util.isString(message.orgUuid))
                            return "orgUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a TaskTag message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tags.TaskTag} TaskTag
                 */
                TaskTag.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tags.TaskTag)
                        return object;
                    var message = new $root.infinitusai.tags.TaskTag();
                    if (object.tagUuid != null)
                        message.tagUuid = String(object.tagUuid);
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.orgUuid != null)
                        message.orgUuid = String(object.orgUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskTag message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {infinitusai.tags.TaskTag} message TaskTag
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskTag.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.tagUuid = "";
                        object.taskUuid = "";
                        object.orgUuid = "";
                    }
                    if (message.tagUuid != null && message.hasOwnProperty("tagUuid"))
                        object.tagUuid = message.tagUuid;
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.orgUuid != null && message.hasOwnProperty("orgUuid"))
                        object.orgUuid = message.orgUuid;
                    return object;
                };
    
                /**
                 * Converts this TaskTag to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tags.TaskTag
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskTag.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskTag
                 * @function getTypeUrl
                 * @memberof infinitusai.tags.TaskTag
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskTag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tags.TaskTag";
                };
    
                return TaskTag;
            })();
    
            return tags;
        })();
    
        infinitusai.tasks = (function() {
    
            /**
             * Namespace tasks.
             * @memberof infinitusai
             * @namespace
             */
            var tasks = {};
    
            /**
             * INFTaskType enum.
             * @name infinitusai.tasks.INFTaskType
             * @enum {number}
             * @property {number} INF_TASK_TYPE_UNKNOWN=0 INF_TASK_TYPE_UNKNOWN value
             * @property {number} INF_TASK_TYPE_BENEFITS_VERIFICATION=1 INF_TASK_TYPE_BENEFITS_VERIFICATION value
             * @property {number} INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER=2 INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER value
             * @property {number} INF_TASK_TYPE_PRESCRIPTION_TRANSFER=3 INF_TASK_TYPE_PRESCRIPTION_TRANSFER value
             * @property {number} INF_TASK_TYPE_PRESCRIPTION_SAVINGS=4 INF_TASK_TYPE_PRESCRIPTION_SAVINGS value
             * @property {number} INF_TASK_TYPE_CLAIMS_FOLLOWUP=5 INF_TASK_TYPE_CLAIMS_FOLLOWUP value
             * @property {number} INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP=6 INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP value
             * @property {number} INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION=7 INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION value
             * @property {number} INF_TASK_TYPE_SHIPMENT_FOLLOWUP=8 INF_TASK_TYPE_SHIPMENT_FOLLOWUP value
             * @property {number} INF_TASK_TYPE_PATIENT_OUTREACH=9 INF_TASK_TYPE_PATIENT_OUTREACH value
             * @property {number} INF_TASK_TYPE_PROVIDER_OUTREACH=10 INF_TASK_TYPE_PROVIDER_OUTREACH value
             * @property {number} INF_TASK_TYPE_FASTTRACK_MM=11 INF_TASK_TYPE_FASTTRACK_MM value
             * @property {number} INF_TASK_TYPE_FASTTRACK_PBM=12 INF_TASK_TYPE_FASTTRACK_PBM value
             * @property {number} INF_TASK_TYPE_PHARMACY_STOCK_CHECK=13 INF_TASK_TYPE_PHARMACY_STOCK_CHECK value
             * @property {number} INF_TASK_TYPE_BASIC_BV=14 INF_TASK_TYPE_BASIC_BV value
             * @property {number} INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP=15 INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP value
             * @property {number} INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL=16 INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL value
             * @property {number} INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL=17 INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL value
             * @property {number} INF_TASK_TYPE_PBM_DISCOVERY=18 INF_TASK_TYPE_PBM_DISCOVERY value
             */
            tasks.INFTaskType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "INF_TASK_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "INF_TASK_TYPE_BENEFITS_VERIFICATION"] = 1;
                values[valuesById[2] = "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER"] = 2;
                values[valuesById[3] = "INF_TASK_TYPE_PRESCRIPTION_TRANSFER"] = 3;
                values[valuesById[4] = "INF_TASK_TYPE_PRESCRIPTION_SAVINGS"] = 4;
                values[valuesById[5] = "INF_TASK_TYPE_CLAIMS_FOLLOWUP"] = 5;
                values[valuesById[6] = "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP"] = 6;
                values[valuesById[7] = "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION"] = 7;
                values[valuesById[8] = "INF_TASK_TYPE_SHIPMENT_FOLLOWUP"] = 8;
                values[valuesById[9] = "INF_TASK_TYPE_PATIENT_OUTREACH"] = 9;
                values[valuesById[10] = "INF_TASK_TYPE_PROVIDER_OUTREACH"] = 10;
                values[valuesById[11] = "INF_TASK_TYPE_FASTTRACK_MM"] = 11;
                values[valuesById[12] = "INF_TASK_TYPE_FASTTRACK_PBM"] = 12;
                values[valuesById[13] = "INF_TASK_TYPE_PHARMACY_STOCK_CHECK"] = 13;
                values[valuesById[14] = "INF_TASK_TYPE_BASIC_BV"] = 14;
                values[valuesById[15] = "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP"] = 15;
                values[valuesById[16] = "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL"] = 16;
                values[valuesById[17] = "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL"] = 17;
                values[valuesById[18] = "INF_TASK_TYPE_PBM_DISCOVERY"] = 18;
                return values;
            })();
    
            /**
             * TaskType enum.
             * @name infinitusai.tasks.TaskType
             * @enum {number}
             * @property {number} TASK_TYPE_UNKNOWN=0 TASK_TYPE_UNKNOWN value
             * @property {number} TASK_TYPE_MED_SUPP_BV=1 TASK_TYPE_MED_SUPP_BV value
             * @property {number} TASK_TYPE_FULL_BI=2 TASK_TYPE_FULL_BI value
             * @property {number} TASK_TYPE_PBM_BV=3 TASK_TYPE_PBM_BV value
             * @property {number} TASK_TYPE_COVID_VACCINATIONS=4 TASK_TYPE_COVID_VACCINATIONS value
             * @property {number} TASK_TYPE_PRESCRIPTION_TRANSFER=5 TASK_TYPE_PRESCRIPTION_TRANSFER value
             * @property {number} TASK_TYPE_INBOUND=6 TASK_TYPE_INBOUND value
             * @property {number} TASK_TYPE_PRESCRIPTION_SAVINGS=7 TASK_TYPE_PRESCRIPTION_SAVINGS value
             * @property {number} TASK_TYPE_CLAIMS_FOLLOWUP=8 TASK_TYPE_CLAIMS_FOLLOWUP value
             * @property {number} TASK_TYPE_PRIOR_AUTH_FOLLOWUP=9 TASK_TYPE_PRIOR_AUTH_FOLLOWUP value
             * @property {number} TASK_TYPE_DENTAL_BV=10 TASK_TYPE_DENTAL_BV value
             * @property {number} TASK_TYPE_SHIPMENT_FOLLOWUP=11 TASK_TYPE_SHIPMENT_FOLLOWUP value
             * @property {number} TASK_TYPE_PATIENT_OUTREACH=12 TASK_TYPE_PATIENT_OUTREACH value
             * @property {number} TASK_TYPE_PROVIDER_OUTREACH=13 TASK_TYPE_PROVIDER_OUTREACH value
             * @property {number} TASK_TYPE_FASTTRACK_MM=14 TASK_TYPE_FASTTRACK_MM value
             * @property {number} TASK_TYPE_FASTTRACK_PBM=15 TASK_TYPE_FASTTRACK_PBM value
             * @property {number} TASK_TYPE_PHARMACY_STOCK_CHECK=16 TASK_TYPE_PHARMACY_STOCK_CHECK value
             * @property {number} TASK_TYPE_BASIC_BV=17 TASK_TYPE_BASIC_BV value
             * @property {number} TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP=18 TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP value
             * @property {number} TASK_TYPE_FASTTRACK_CLAIMS_DENIAL=19 TASK_TYPE_FASTTRACK_CLAIMS_DENIAL value
             * @property {number} TASK_TYPE_FASTTRACK_CLAIMS_APPEAL=20 TASK_TYPE_FASTTRACK_CLAIMS_APPEAL value
             * @property {number} TASK_TYPE_PBM_DISCOVERY=21 TASK_TYPE_PBM_DISCOVERY value
             */
            tasks.TaskType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TASK_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "TASK_TYPE_MED_SUPP_BV"] = 1;
                values[valuesById[2] = "TASK_TYPE_FULL_BI"] = 2;
                values[valuesById[3] = "TASK_TYPE_PBM_BV"] = 3;
                values[valuesById[4] = "TASK_TYPE_COVID_VACCINATIONS"] = 4;
                values[valuesById[5] = "TASK_TYPE_PRESCRIPTION_TRANSFER"] = 5;
                values[valuesById[6] = "TASK_TYPE_INBOUND"] = 6;
                values[valuesById[7] = "TASK_TYPE_PRESCRIPTION_SAVINGS"] = 7;
                values[valuesById[8] = "TASK_TYPE_CLAIMS_FOLLOWUP"] = 8;
                values[valuesById[9] = "TASK_TYPE_PRIOR_AUTH_FOLLOWUP"] = 9;
                values[valuesById[10] = "TASK_TYPE_DENTAL_BV"] = 10;
                values[valuesById[11] = "TASK_TYPE_SHIPMENT_FOLLOWUP"] = 11;
                values[valuesById[12] = "TASK_TYPE_PATIENT_OUTREACH"] = 12;
                values[valuesById[13] = "TASK_TYPE_PROVIDER_OUTREACH"] = 13;
                values[valuesById[14] = "TASK_TYPE_FASTTRACK_MM"] = 14;
                values[valuesById[15] = "TASK_TYPE_FASTTRACK_PBM"] = 15;
                values[valuesById[16] = "TASK_TYPE_PHARMACY_STOCK_CHECK"] = 16;
                values[valuesById[17] = "TASK_TYPE_BASIC_BV"] = 17;
                values[valuesById[18] = "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP"] = 18;
                values[valuesById[19] = "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL"] = 19;
                values[valuesById[20] = "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL"] = 20;
                values[valuesById[21] = "TASK_TYPE_PBM_DISCOVERY"] = 21;
                return values;
            })();
    
            /**
             * TaskSource enum.
             * @name infinitusai.tasks.TaskSource
             * @enum {number}
             * @property {number} TASK_SOURCE_UNKNOWN=0 TASK_SOURCE_UNKNOWN value
             * @property {number} TASK_SOURCE_ETL_SCRIPT=1 TASK_SOURCE_ETL_SCRIPT value
             * @property {number} TASK_SOURCE_FE=2 TASK_SOURCE_FE value
             * @property {number} TASK_SOURCE_CUSTOMER_PORTAL=3 TASK_SOURCE_CUSTOMER_PORTAL value
             * @property {number} TASK_SOURCE_CUSTOMER_API=4 TASK_SOURCE_CUSTOMER_API value
             * @property {number} TASK_SOURCE_SETUP=5 TASK_SOURCE_SETUP value
             * @property {number} TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD=6 TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD value
             */
            tasks.TaskSource = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TASK_SOURCE_UNKNOWN"] = 0;
                values[valuesById[1] = "TASK_SOURCE_ETL_SCRIPT"] = 1;
                values[valuesById[2] = "TASK_SOURCE_FE"] = 2;
                values[valuesById[3] = "TASK_SOURCE_CUSTOMER_PORTAL"] = 3;
                values[valuesById[4] = "TASK_SOURCE_CUSTOMER_API"] = 4;
                values[valuesById[5] = "TASK_SOURCE_SETUP"] = 5;
                values[valuesById[6] = "TASK_SOURCE_CUSTOMER_PORTAL_CSV_UPLOAD"] = 6;
                return values;
            })();
    
            /**
             * CSVType enum.
             * @name infinitusai.tasks.CSVType
             * @enum {number}
             * @property {number} CSV_INPUT=0 CSV_INPUT value
             * @property {number} CSV_ACCEPTED=1 CSV_ACCEPTED value
             * @property {number} CSV_REJECTED=2 CSV_REJECTED value
             */
            tasks.CSVType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CSV_INPUT"] = 0;
                values[valuesById[1] = "CSV_ACCEPTED"] = 1;
                values[valuesById[2] = "CSV_REJECTED"] = 2;
                return values;
            })();
    
            tasks.GeneratedCSVDoc = (function() {
    
                /**
                 * Properties of a GeneratedCSVDoc.
                 * @memberof infinitusai.tasks
                 * @interface IGeneratedCSVDoc
                 * @property {infinitusai.tasks.IGenerateCSVParameters|null} [query] GeneratedCSVDoc query
                 * @property {number|Long|null} [generatedAtMillis] GeneratedCSVDoc generatedAtMillis
                 * @property {string|null} [gcsPath] GeneratedCSVDoc gcsPath
                 * @property {string|null} [csvUuid] GeneratedCSVDoc csvUuid
                 * @property {boolean|null} [published] GeneratedCSVDoc published
                 * @property {string|null} [errorMessage] GeneratedCSVDoc errorMessage
                 */
    
                /**
                 * Constructs a new GeneratedCSVDoc.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GeneratedCSVDoc.
                 * @implements IGeneratedCSVDoc
                 * @constructor
                 * @param {infinitusai.tasks.IGeneratedCSVDoc=} [properties] Properties to set
                 */
                function GeneratedCSVDoc(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GeneratedCSVDoc query.
                 * @member {infinitusai.tasks.IGenerateCSVParameters|null|undefined} query
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.query = null;
    
                /**
                 * GeneratedCSVDoc generatedAtMillis.
                 * @member {number|Long} generatedAtMillis
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.generatedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GeneratedCSVDoc gcsPath.
                 * @member {string} gcsPath
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.gcsPath = "";
    
                /**
                 * GeneratedCSVDoc csvUuid.
                 * @member {string} csvUuid
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.csvUuid = "";
    
                /**
                 * GeneratedCSVDoc published.
                 * @member {boolean} published
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.published = false;
    
                /**
                 * GeneratedCSVDoc errorMessage.
                 * @member {string} errorMessage
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @instance
                 */
                GeneratedCSVDoc.prototype.errorMessage = "";
    
                /**
                 * Creates a new GeneratedCSVDoc instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {infinitusai.tasks.IGeneratedCSVDoc=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GeneratedCSVDoc} GeneratedCSVDoc instance
                 */
                GeneratedCSVDoc.create = function create(properties) {
                    return new GeneratedCSVDoc(properties);
                };
    
                /**
                 * Encodes the specified GeneratedCSVDoc message. Does not implicitly {@link infinitusai.tasks.GeneratedCSVDoc.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {infinitusai.tasks.IGeneratedCSVDoc} message GeneratedCSVDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCSVDoc.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                        $root.infinitusai.tasks.GenerateCSVParameters.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.generatedAtMillis != null && Object.hasOwnProperty.call(message, "generatedAtMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generatedAtMillis);
                    if (message.gcsPath != null && Object.hasOwnProperty.call(message, "gcsPath"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.gcsPath);
                    if (message.csvUuid != null && Object.hasOwnProperty.call(message, "csvUuid"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.csvUuid);
                    if (message.published != null && Object.hasOwnProperty.call(message, "published"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.published);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.errorMessage);
                    return writer;
                };
    
                /**
                 * Encodes the specified GeneratedCSVDoc message, length delimited. Does not implicitly {@link infinitusai.tasks.GeneratedCSVDoc.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {infinitusai.tasks.IGeneratedCSVDoc} message GeneratedCSVDoc message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCSVDoc.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GeneratedCSVDoc message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GeneratedCSVDoc} GeneratedCSVDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCSVDoc.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GeneratedCSVDoc();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.query = $root.infinitusai.tasks.GenerateCSVParameters.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.generatedAtMillis = reader.int64();
                                break;
                            }
                        case 3: {
                                message.gcsPath = reader.string();
                                break;
                            }
                        case 4: {
                                message.csvUuid = reader.string();
                                break;
                            }
                        case 5: {
                                message.published = reader.bool();
                                break;
                            }
                        case 6: {
                                message.errorMessage = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GeneratedCSVDoc message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GeneratedCSVDoc} GeneratedCSVDoc
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCSVDoc.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GeneratedCSVDoc message.
                 * @function verify
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GeneratedCSVDoc.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.query != null && message.hasOwnProperty("query")) {
                        var error = $root.infinitusai.tasks.GenerateCSVParameters.verify(message.query);
                        if (error)
                            return "query." + error;
                    }
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (!$util.isInteger(message.generatedAtMillis) && !(message.generatedAtMillis && $util.isInteger(message.generatedAtMillis.low) && $util.isInteger(message.generatedAtMillis.high)))
                            return "generatedAtMillis: integer|Long expected";
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        if (!$util.isString(message.gcsPath))
                            return "gcsPath: string expected";
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        if (!$util.isString(message.csvUuid))
                            return "csvUuid: string expected";
                    if (message.published != null && message.hasOwnProperty("published"))
                        if (typeof message.published !== "boolean")
                            return "published: boolean expected";
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    return null;
                };
    
                /**
                 * Creates a GeneratedCSVDoc message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GeneratedCSVDoc} GeneratedCSVDoc
                 */
                GeneratedCSVDoc.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GeneratedCSVDoc)
                        return object;
                    var message = new $root.infinitusai.tasks.GeneratedCSVDoc();
                    if (object.query != null) {
                        if (typeof object.query !== "object")
                            throw TypeError(".infinitusai.tasks.GeneratedCSVDoc.query: object expected");
                        message.query = $root.infinitusai.tasks.GenerateCSVParameters.fromObject(object.query);
                    }
                    if (object.generatedAtMillis != null)
                        if ($util.Long)
                            (message.generatedAtMillis = $util.Long.fromValue(object.generatedAtMillis)).unsigned = false;
                        else if (typeof object.generatedAtMillis === "string")
                            message.generatedAtMillis = parseInt(object.generatedAtMillis, 10);
                        else if (typeof object.generatedAtMillis === "number")
                            message.generatedAtMillis = object.generatedAtMillis;
                        else if (typeof object.generatedAtMillis === "object")
                            message.generatedAtMillis = new $util.LongBits(object.generatedAtMillis.low >>> 0, object.generatedAtMillis.high >>> 0).toNumber();
                    if (object.gcsPath != null)
                        message.gcsPath = String(object.gcsPath);
                    if (object.csvUuid != null)
                        message.csvUuid = String(object.csvUuid);
                    if (object.published != null)
                        message.published = Boolean(object.published);
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GeneratedCSVDoc message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {infinitusai.tasks.GeneratedCSVDoc} message GeneratedCSVDoc
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GeneratedCSVDoc.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.query = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedAtMillis = options.longs === String ? "0" : 0;
                        object.gcsPath = "";
                        object.csvUuid = "";
                        object.published = false;
                        object.errorMessage = "";
                    }
                    if (message.query != null && message.hasOwnProperty("query"))
                        object.query = $root.infinitusai.tasks.GenerateCSVParameters.toObject(message.query, options);
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (typeof message.generatedAtMillis === "number")
                            object.generatedAtMillis = options.longs === String ? String(message.generatedAtMillis) : message.generatedAtMillis;
                        else
                            object.generatedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.generatedAtMillis) : options.longs === Number ? new $util.LongBits(message.generatedAtMillis.low >>> 0, message.generatedAtMillis.high >>> 0).toNumber() : message.generatedAtMillis;
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        object.gcsPath = message.gcsPath;
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        object.csvUuid = message.csvUuid;
                    if (message.published != null && message.hasOwnProperty("published"))
                        object.published = message.published;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    return object;
                };
    
                /**
                 * Converts this GeneratedCSVDoc to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GeneratedCSVDoc.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GeneratedCSVDoc
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GeneratedCSVDoc
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GeneratedCSVDoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GeneratedCSVDoc";
                };
    
                return GeneratedCSVDoc;
            })();
    
            tasks.GenerateCSVParameters = (function() {
    
                /**
                 * Properties of a GenerateCSVParameters.
                 * @memberof infinitusai.tasks
                 * @interface IGenerateCSVParameters
                 * @property {number|Long|null} [reviewedMillisStart] GenerateCSVParameters reviewedMillisStart
                 * @property {number|Long|null} [reviewedMillisEnd] GenerateCSVParameters reviewedMillisEnd
                 * @property {string|null} [programId] GenerateCSVParameters programId
                 * @property {infinitusai.tasks.TaskType|null} [taskType] GenerateCSVParameters taskType
                 */
    
                /**
                 * Constructs a new GenerateCSVParameters.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GenerateCSVParameters.
                 * @implements IGenerateCSVParameters
                 * @constructor
                 * @param {infinitusai.tasks.IGenerateCSVParameters=} [properties] Properties to set
                 */
                function GenerateCSVParameters(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateCSVParameters reviewedMillisStart.
                 * @member {number|Long} reviewedMillisStart
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @instance
                 */
                GenerateCSVParameters.prototype.reviewedMillisStart = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateCSVParameters reviewedMillisEnd.
                 * @member {number|Long} reviewedMillisEnd
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @instance
                 */
                GenerateCSVParameters.prototype.reviewedMillisEnd = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * GenerateCSVParameters programId.
                 * @member {string} programId
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @instance
                 */
                GenerateCSVParameters.prototype.programId = "";
    
                /**
                 * GenerateCSVParameters taskType.
                 * @member {infinitusai.tasks.TaskType} taskType
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @instance
                 */
                GenerateCSVParameters.prototype.taskType = 0;
    
                /**
                 * Creates a new GenerateCSVParameters instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {infinitusai.tasks.IGenerateCSVParameters=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GenerateCSVParameters} GenerateCSVParameters instance
                 */
                GenerateCSVParameters.create = function create(properties) {
                    return new GenerateCSVParameters(properties);
                };
    
                /**
                 * Encodes the specified GenerateCSVParameters message. Does not implicitly {@link infinitusai.tasks.GenerateCSVParameters.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {infinitusai.tasks.IGenerateCSVParameters} message GenerateCSVParameters message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateCSVParameters.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reviewedMillisStart != null && Object.hasOwnProperty.call(message, "reviewedMillisStart"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.reviewedMillisStart);
                    if (message.reviewedMillisEnd != null && Object.hasOwnProperty.call(message, "reviewedMillisEnd"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.reviewedMillisEnd);
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.programId);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateCSVParameters message, length delimited. Does not implicitly {@link infinitusai.tasks.GenerateCSVParameters.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {infinitusai.tasks.IGenerateCSVParameters} message GenerateCSVParameters message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateCSVParameters.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateCSVParameters message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GenerateCSVParameters} GenerateCSVParameters
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateCSVParameters.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GenerateCSVParameters();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.reviewedMillisStart = reader.int64();
                                break;
                            }
                        case 2: {
                                message.reviewedMillisEnd = reader.int64();
                                break;
                            }
                        case 3: {
                                message.programId = reader.string();
                                break;
                            }
                        case 4: {
                                message.taskType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateCSVParameters message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GenerateCSVParameters} GenerateCSVParameters
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateCSVParameters.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateCSVParameters message.
                 * @function verify
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateCSVParameters.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reviewedMillisStart != null && message.hasOwnProperty("reviewedMillisStart"))
                        if (!$util.isInteger(message.reviewedMillisStart) && !(message.reviewedMillisStart && $util.isInteger(message.reviewedMillisStart.low) && $util.isInteger(message.reviewedMillisStart.high)))
                            return "reviewedMillisStart: integer|Long expected";
                    if (message.reviewedMillisEnd != null && message.hasOwnProperty("reviewedMillisEnd"))
                        if (!$util.isInteger(message.reviewedMillisEnd) && !(message.reviewedMillisEnd && $util.isInteger(message.reviewedMillisEnd.low) && $util.isInteger(message.reviewedMillisEnd.high)))
                            return "reviewedMillisEnd: integer|Long expected";
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a GenerateCSVParameters message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GenerateCSVParameters} GenerateCSVParameters
                 */
                GenerateCSVParameters.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GenerateCSVParameters)
                        return object;
                    var message = new $root.infinitusai.tasks.GenerateCSVParameters();
                    if (object.reviewedMillisStart != null)
                        if ($util.Long)
                            (message.reviewedMillisStart = $util.Long.fromValue(object.reviewedMillisStart)).unsigned = false;
                        else if (typeof object.reviewedMillisStart === "string")
                            message.reviewedMillisStart = parseInt(object.reviewedMillisStart, 10);
                        else if (typeof object.reviewedMillisStart === "number")
                            message.reviewedMillisStart = object.reviewedMillisStart;
                        else if (typeof object.reviewedMillisStart === "object")
                            message.reviewedMillisStart = new $util.LongBits(object.reviewedMillisStart.low >>> 0, object.reviewedMillisStart.high >>> 0).toNumber();
                    if (object.reviewedMillisEnd != null)
                        if ($util.Long)
                            (message.reviewedMillisEnd = $util.Long.fromValue(object.reviewedMillisEnd)).unsigned = false;
                        else if (typeof object.reviewedMillisEnd === "string")
                            message.reviewedMillisEnd = parseInt(object.reviewedMillisEnd, 10);
                        else if (typeof object.reviewedMillisEnd === "number")
                            message.reviewedMillisEnd = object.reviewedMillisEnd;
                        else if (typeof object.reviewedMillisEnd === "object")
                            message.reviewedMillisEnd = new $util.LongBits(object.reviewedMillisEnd.low >>> 0, object.reviewedMillisEnd.high >>> 0).toNumber();
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateCSVParameters message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {infinitusai.tasks.GenerateCSVParameters} message GenerateCSVParameters
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateCSVParameters.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedMillisStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedMillisStart = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewedMillisEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewedMillisEnd = options.longs === String ? "0" : 0;
                        object.programId = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                    }
                    if (message.reviewedMillisStart != null && message.hasOwnProperty("reviewedMillisStart"))
                        if (typeof message.reviewedMillisStart === "number")
                            object.reviewedMillisStart = options.longs === String ? String(message.reviewedMillisStart) : message.reviewedMillisStart;
                        else
                            object.reviewedMillisStart = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedMillisStart) : options.longs === Number ? new $util.LongBits(message.reviewedMillisStart.low >>> 0, message.reviewedMillisStart.high >>> 0).toNumber() : message.reviewedMillisStart;
                    if (message.reviewedMillisEnd != null && message.hasOwnProperty("reviewedMillisEnd"))
                        if (typeof message.reviewedMillisEnd === "number")
                            object.reviewedMillisEnd = options.longs === String ? String(message.reviewedMillisEnd) : message.reviewedMillisEnd;
                        else
                            object.reviewedMillisEnd = options.longs === String ? $util.Long.prototype.toString.call(message.reviewedMillisEnd) : options.longs === Number ? new $util.LongBits(message.reviewedMillisEnd.low >>> 0, message.reviewedMillisEnd.high >>> 0).toNumber() : message.reviewedMillisEnd;
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.tasks.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.tasks.TaskType[message.taskType] : message.taskType;
                    return object;
                };
    
                /**
                 * Converts this GenerateCSVParameters to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateCSVParameters.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateCSVParameters
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GenerateCSVParameters
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateCSVParameters.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GenerateCSVParameters";
                };
    
                return GenerateCSVParameters;
            })();
    
            tasks.OrgProgram = (function() {
    
                /**
                 * Properties of an OrgProgram.
                 * @memberof infinitusai.tasks
                 * @interface IOrgProgram
                 * @property {string|null} [name] OrgProgram name
                 * @property {string|null} [displayName] OrgProgram displayName
                 */
    
                /**
                 * Constructs a new OrgProgram.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an OrgProgram.
                 * @implements IOrgProgram
                 * @constructor
                 * @param {infinitusai.tasks.IOrgProgram=} [properties] Properties to set
                 */
                function OrgProgram(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgProgram name.
                 * @member {string} name
                 * @memberof infinitusai.tasks.OrgProgram
                 * @instance
                 */
                OrgProgram.prototype.name = "";
    
                /**
                 * OrgProgram displayName.
                 * @member {string} displayName
                 * @memberof infinitusai.tasks.OrgProgram
                 * @instance
                 */
                OrgProgram.prototype.displayName = "";
    
                /**
                 * Creates a new OrgProgram instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {infinitusai.tasks.IOrgProgram=} [properties] Properties to set
                 * @returns {infinitusai.tasks.OrgProgram} OrgProgram instance
                 */
                OrgProgram.create = function create(properties) {
                    return new OrgProgram(properties);
                };
    
                /**
                 * Encodes the specified OrgProgram message. Does not implicitly {@link infinitusai.tasks.OrgProgram.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {infinitusai.tasks.IOrgProgram} message OrgProgram message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgProgram.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayName);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgProgram message, length delimited. Does not implicitly {@link infinitusai.tasks.OrgProgram.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {infinitusai.tasks.IOrgProgram} message OrgProgram message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgProgram.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgProgram message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.OrgProgram} OrgProgram
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgProgram.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.OrgProgram();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.displayName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgProgram message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.OrgProgram} OrgProgram
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgProgram.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgProgram message.
                 * @function verify
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgProgram.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };
    
                /**
                 * Creates an OrgProgram message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.OrgProgram} OrgProgram
                 */
                OrgProgram.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.OrgProgram)
                        return object;
                    var message = new $root.infinitusai.tasks.OrgProgram();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgProgram message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {infinitusai.tasks.OrgProgram} message OrgProgram
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgProgram.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.displayName = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };
    
                /**
                 * Converts this OrgProgram to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.OrgProgram
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgProgram.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgProgram
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.OrgProgram
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgProgram.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.OrgProgram";
                };
    
                return OrgProgram;
            })();
    
            tasks.RulesFileUpload = (function() {
    
                /**
                 * Properties of a RulesFileUpload.
                 * @memberof infinitusai.tasks
                 * @interface IRulesFileUpload
                 * @property {string|null} [userEmail] RulesFileUpload userEmail
                 * @property {string|null} [taskType] RulesFileUpload taskType
                 * @property {string|null} [uploadId] RulesFileUpload uploadId
                 * @property {number|Long|null} [uploadTimestampMillis] RulesFileUpload uploadTimestampMillis
                 * @property {string|null} [fileExt] RulesFileUpload fileExt
                 * @property {number|Long|null} [numRulesCreated] RulesFileUpload numRulesCreated
                 * @property {string|null} [uploadError] RulesFileUpload uploadError
                 */
    
                /**
                 * Constructs a new RulesFileUpload.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a RulesFileUpload.
                 * @implements IRulesFileUpload
                 * @constructor
                 * @param {infinitusai.tasks.IRulesFileUpload=} [properties] Properties to set
                 */
                function RulesFileUpload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RulesFileUpload userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @instance
                 */
                RulesFileUpload.prototype.userEmail = "";
    
                /**
                 * RulesFileUpload taskType.
                 * @member {string} taskType
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @instance
                 */
                RulesFileUpload.prototype.taskType = "";
    
                /**
                 * RulesFileUpload uploadId.
                 * @member {string} uploadId
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @instance
                 */
                RulesFileUpload.prototype.uploadId = "";
    
                /**
                 * RulesFileUpload uploadTimestampMillis.
                 * @member {number|Long} uploadTimestampMillis
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @instance
                 */
                RulesFileUpload.prototype.uploadTimestampMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * RulesFileUpload fileExt.
                 * @member {string} fileExt
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @instance
                 */
                RulesFileUpload.prototype.fileExt = "";
    
                /**
                 * RulesFileUpload numRulesCreated.
                 * @member {number|Long} numRulesCreated
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @instance
                 */
                RulesFileUpload.prototype.numRulesCreated = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * RulesFileUpload uploadError.
                 * @member {string} uploadError
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @instance
                 */
                RulesFileUpload.prototype.uploadError = "";
    
                /**
                 * Creates a new RulesFileUpload instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {infinitusai.tasks.IRulesFileUpload=} [properties] Properties to set
                 * @returns {infinitusai.tasks.RulesFileUpload} RulesFileUpload instance
                 */
                RulesFileUpload.create = function create(properties) {
                    return new RulesFileUpload(properties);
                };
    
                /**
                 * Encodes the specified RulesFileUpload message. Does not implicitly {@link infinitusai.tasks.RulesFileUpload.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {infinitusai.tasks.IRulesFileUpload} message RulesFileUpload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RulesFileUpload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userEmail);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskType);
                    if (message.uploadId != null && Object.hasOwnProperty.call(message, "uploadId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.uploadId);
                    if (message.uploadTimestampMillis != null && Object.hasOwnProperty.call(message, "uploadTimestampMillis"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.uploadTimestampMillis);
                    if (message.fileExt != null && Object.hasOwnProperty.call(message, "fileExt"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.fileExt);
                    if (message.numRulesCreated != null && Object.hasOwnProperty.call(message, "numRulesCreated"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.numRulesCreated);
                    if (message.uploadError != null && Object.hasOwnProperty.call(message, "uploadError"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.uploadError);
                    return writer;
                };
    
                /**
                 * Encodes the specified RulesFileUpload message, length delimited. Does not implicitly {@link infinitusai.tasks.RulesFileUpload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {infinitusai.tasks.IRulesFileUpload} message RulesFileUpload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RulesFileUpload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RulesFileUpload message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.RulesFileUpload} RulesFileUpload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RulesFileUpload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.RulesFileUpload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskType = reader.string();
                                break;
                            }
                        case 3: {
                                message.uploadId = reader.string();
                                break;
                            }
                        case 4: {
                                message.uploadTimestampMillis = reader.int64();
                                break;
                            }
                        case 5: {
                                message.fileExt = reader.string();
                                break;
                            }
                        case 6: {
                                message.numRulesCreated = reader.int64();
                                break;
                            }
                        case 7: {
                                message.uploadError = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RulesFileUpload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.RulesFileUpload} RulesFileUpload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RulesFileUpload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RulesFileUpload message.
                 * @function verify
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RulesFileUpload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        if (!$util.isString(message.taskType))
                            return "taskType: string expected";
                    if (message.uploadId != null && message.hasOwnProperty("uploadId"))
                        if (!$util.isString(message.uploadId))
                            return "uploadId: string expected";
                    if (message.uploadTimestampMillis != null && message.hasOwnProperty("uploadTimestampMillis"))
                        if (!$util.isInteger(message.uploadTimestampMillis) && !(message.uploadTimestampMillis && $util.isInteger(message.uploadTimestampMillis.low) && $util.isInteger(message.uploadTimestampMillis.high)))
                            return "uploadTimestampMillis: integer|Long expected";
                    if (message.fileExt != null && message.hasOwnProperty("fileExt"))
                        if (!$util.isString(message.fileExt))
                            return "fileExt: string expected";
                    if (message.numRulesCreated != null && message.hasOwnProperty("numRulesCreated"))
                        if (!$util.isInteger(message.numRulesCreated) && !(message.numRulesCreated && $util.isInteger(message.numRulesCreated.low) && $util.isInteger(message.numRulesCreated.high)))
                            return "numRulesCreated: integer|Long expected";
                    if (message.uploadError != null && message.hasOwnProperty("uploadError"))
                        if (!$util.isString(message.uploadError))
                            return "uploadError: string expected";
                    return null;
                };
    
                /**
                 * Creates a RulesFileUpload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.RulesFileUpload} RulesFileUpload
                 */
                RulesFileUpload.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.RulesFileUpload)
                        return object;
                    var message = new $root.infinitusai.tasks.RulesFileUpload();
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    if (object.taskType != null)
                        message.taskType = String(object.taskType);
                    if (object.uploadId != null)
                        message.uploadId = String(object.uploadId);
                    if (object.uploadTimestampMillis != null)
                        if ($util.Long)
                            (message.uploadTimestampMillis = $util.Long.fromValue(object.uploadTimestampMillis)).unsigned = false;
                        else if (typeof object.uploadTimestampMillis === "string")
                            message.uploadTimestampMillis = parseInt(object.uploadTimestampMillis, 10);
                        else if (typeof object.uploadTimestampMillis === "number")
                            message.uploadTimestampMillis = object.uploadTimestampMillis;
                        else if (typeof object.uploadTimestampMillis === "object")
                            message.uploadTimestampMillis = new $util.LongBits(object.uploadTimestampMillis.low >>> 0, object.uploadTimestampMillis.high >>> 0).toNumber();
                    if (object.fileExt != null)
                        message.fileExt = String(object.fileExt);
                    if (object.numRulesCreated != null)
                        if ($util.Long)
                            (message.numRulesCreated = $util.Long.fromValue(object.numRulesCreated)).unsigned = false;
                        else if (typeof object.numRulesCreated === "string")
                            message.numRulesCreated = parseInt(object.numRulesCreated, 10);
                        else if (typeof object.numRulesCreated === "number")
                            message.numRulesCreated = object.numRulesCreated;
                        else if (typeof object.numRulesCreated === "object")
                            message.numRulesCreated = new $util.LongBits(object.numRulesCreated.low >>> 0, object.numRulesCreated.high >>> 0).toNumber();
                    if (object.uploadError != null)
                        message.uploadError = String(object.uploadError);
                    return message;
                };
    
                /**
                 * Creates a plain object from a RulesFileUpload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {infinitusai.tasks.RulesFileUpload} message RulesFileUpload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RulesFileUpload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userEmail = "";
                        object.taskType = "";
                        object.uploadId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.uploadTimestampMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.uploadTimestampMillis = options.longs === String ? "0" : 0;
                        object.fileExt = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.numRulesCreated = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.numRulesCreated = options.longs === String ? "0" : 0;
                        object.uploadError = "";
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = message.taskType;
                    if (message.uploadId != null && message.hasOwnProperty("uploadId"))
                        object.uploadId = message.uploadId;
                    if (message.uploadTimestampMillis != null && message.hasOwnProperty("uploadTimestampMillis"))
                        if (typeof message.uploadTimestampMillis === "number")
                            object.uploadTimestampMillis = options.longs === String ? String(message.uploadTimestampMillis) : message.uploadTimestampMillis;
                        else
                            object.uploadTimestampMillis = options.longs === String ? $util.Long.prototype.toString.call(message.uploadTimestampMillis) : options.longs === Number ? new $util.LongBits(message.uploadTimestampMillis.low >>> 0, message.uploadTimestampMillis.high >>> 0).toNumber() : message.uploadTimestampMillis;
                    if (message.fileExt != null && message.hasOwnProperty("fileExt"))
                        object.fileExt = message.fileExt;
                    if (message.numRulesCreated != null && message.hasOwnProperty("numRulesCreated"))
                        if (typeof message.numRulesCreated === "number")
                            object.numRulesCreated = options.longs === String ? String(message.numRulesCreated) : message.numRulesCreated;
                        else
                            object.numRulesCreated = options.longs === String ? $util.Long.prototype.toString.call(message.numRulesCreated) : options.longs === Number ? new $util.LongBits(message.numRulesCreated.low >>> 0, message.numRulesCreated.high >>> 0).toNumber() : message.numRulesCreated;
                    if (message.uploadError != null && message.hasOwnProperty("uploadError"))
                        object.uploadError = message.uploadError;
                    return object;
                };
    
                /**
                 * Converts this RulesFileUpload to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RulesFileUpload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RulesFileUpload
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.RulesFileUpload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RulesFileUpload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.RulesFileUpload";
                };
    
                return RulesFileUpload;
            })();
    
            tasks.Condition = (function() {
    
                /**
                 * Properties of a Condition.
                 * @memberof infinitusai.tasks
                 * @interface ICondition
                 * @property {string|null} [outputName] Condition outputName
                 * @property {infinitusai.tasks.IPredicate|null} [predicate] Condition predicate
                 * @property {number|Long|null} [pluralPredicate] Condition pluralPredicate
                 * @property {string|null} [description] Condition description
                 * @property {infinitusai.tasks.SourceType|null} [sourceType] Condition sourceType
                 */
    
                /**
                 * Constructs a new Condition.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a Condition.
                 * @implements ICondition
                 * @constructor
                 * @param {infinitusai.tasks.ICondition=} [properties] Properties to set
                 */
                function Condition(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Condition outputName.
                 * @member {string} outputName
                 * @memberof infinitusai.tasks.Condition
                 * @instance
                 */
                Condition.prototype.outputName = "";
    
                /**
                 * Condition predicate.
                 * @member {infinitusai.tasks.IPredicate|null|undefined} predicate
                 * @memberof infinitusai.tasks.Condition
                 * @instance
                 */
                Condition.prototype.predicate = null;
    
                /**
                 * Condition pluralPredicate.
                 * @member {number|Long} pluralPredicate
                 * @memberof infinitusai.tasks.Condition
                 * @instance
                 */
                Condition.prototype.pluralPredicate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Condition description.
                 * @member {string} description
                 * @memberof infinitusai.tasks.Condition
                 * @instance
                 */
                Condition.prototype.description = "";
    
                /**
                 * Condition sourceType.
                 * @member {infinitusai.tasks.SourceType} sourceType
                 * @memberof infinitusai.tasks.Condition
                 * @instance
                 */
                Condition.prototype.sourceType = 0;
    
                /**
                 * Creates a new Condition instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {infinitusai.tasks.ICondition=} [properties] Properties to set
                 * @returns {infinitusai.tasks.Condition} Condition instance
                 */
                Condition.create = function create(properties) {
                    return new Condition(properties);
                };
    
                /**
                 * Encodes the specified Condition message. Does not implicitly {@link infinitusai.tasks.Condition.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {infinitusai.tasks.ICondition} message Condition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Condition.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.outputName != null && Object.hasOwnProperty.call(message, "outputName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.outputName);
                    if (message.predicate != null && Object.hasOwnProperty.call(message, "predicate"))
                        $root.infinitusai.tasks.Predicate.encode(message.predicate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.pluralPredicate != null && Object.hasOwnProperty.call(message, "pluralPredicate"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.pluralPredicate);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                    if (message.sourceType != null && Object.hasOwnProperty.call(message, "sourceType"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sourceType);
                    return writer;
                };
    
                /**
                 * Encodes the specified Condition message, length delimited. Does not implicitly {@link infinitusai.tasks.Condition.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {infinitusai.tasks.ICondition} message Condition message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Condition.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Condition message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.Condition} Condition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Condition.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.Condition();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.outputName = reader.string();
                                break;
                            }
                        case 2: {
                                message.predicate = $root.infinitusai.tasks.Predicate.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.pluralPredicate = reader.int64();
                                break;
                            }
                        case 4: {
                                message.description = reader.string();
                                break;
                            }
                        case 5: {
                                message.sourceType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Condition message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.Condition} Condition
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Condition.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Condition message.
                 * @function verify
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Condition.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.outputName != null && message.hasOwnProperty("outputName"))
                        if (!$util.isString(message.outputName))
                            return "outputName: string expected";
                    if (message.predicate != null && message.hasOwnProperty("predicate")) {
                        var error = $root.infinitusai.tasks.Predicate.verify(message.predicate);
                        if (error)
                            return "predicate." + error;
                    }
                    if (message.pluralPredicate != null && message.hasOwnProperty("pluralPredicate"))
                        if (!$util.isInteger(message.pluralPredicate) && !(message.pluralPredicate && $util.isInteger(message.pluralPredicate.low) && $util.isInteger(message.pluralPredicate.high)))
                            return "pluralPredicate: integer|Long expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.sourceType != null && message.hasOwnProperty("sourceType"))
                        switch (message.sourceType) {
                        default:
                            return "sourceType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a Condition message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.Condition} Condition
                 */
                Condition.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.Condition)
                        return object;
                    var message = new $root.infinitusai.tasks.Condition();
                    if (object.outputName != null)
                        message.outputName = String(object.outputName);
                    if (object.predicate != null) {
                        if (typeof object.predicate !== "object")
                            throw TypeError(".infinitusai.tasks.Condition.predicate: object expected");
                        message.predicate = $root.infinitusai.tasks.Predicate.fromObject(object.predicate);
                    }
                    if (object.pluralPredicate != null)
                        if ($util.Long)
                            (message.pluralPredicate = $util.Long.fromValue(object.pluralPredicate)).unsigned = false;
                        else if (typeof object.pluralPredicate === "string")
                            message.pluralPredicate = parseInt(object.pluralPredicate, 10);
                        else if (typeof object.pluralPredicate === "number")
                            message.pluralPredicate = object.pluralPredicate;
                        else if (typeof object.pluralPredicate === "object")
                            message.pluralPredicate = new $util.LongBits(object.pluralPredicate.low >>> 0, object.pluralPredicate.high >>> 0).toNumber();
                    if (object.description != null)
                        message.description = String(object.description);
                    switch (object.sourceType) {
                    default:
                        if (typeof object.sourceType === "number") {
                            message.sourceType = object.sourceType;
                            break;
                        }
                        break;
                    case "UNKOWN":
                    case 0:
                        message.sourceType = 0;
                        break;
                    case "INPUT":
                    case 1:
                        message.sourceType = 1;
                        break;
                    case "OUTPUT":
                    case 2:
                        message.sourceType = 2;
                        break;
                    case "OPTIONAL_OUTPUT":
                    case 3:
                        message.sourceType = 3;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Condition message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {infinitusai.tasks.Condition} message Condition
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Condition.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.outputName = "";
                        object.predicate = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.pluralPredicate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.pluralPredicate = options.longs === String ? "0" : 0;
                        object.description = "";
                        object.sourceType = options.enums === String ? "UNKOWN" : 0;
                    }
                    if (message.outputName != null && message.hasOwnProperty("outputName"))
                        object.outputName = message.outputName;
                    if (message.predicate != null && message.hasOwnProperty("predicate"))
                        object.predicate = $root.infinitusai.tasks.Predicate.toObject(message.predicate, options);
                    if (message.pluralPredicate != null && message.hasOwnProperty("pluralPredicate"))
                        if (typeof message.pluralPredicate === "number")
                            object.pluralPredicate = options.longs === String ? String(message.pluralPredicate) : message.pluralPredicate;
                        else
                            object.pluralPredicate = options.longs === String ? $util.Long.prototype.toString.call(message.pluralPredicate) : options.longs === Number ? new $util.LongBits(message.pluralPredicate.low >>> 0, message.pluralPredicate.high >>> 0).toNumber() : message.pluralPredicate;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.sourceType != null && message.hasOwnProperty("sourceType"))
                        object.sourceType = options.enums === String ? $root.infinitusai.tasks.SourceType[message.sourceType] === undefined ? message.sourceType : $root.infinitusai.tasks.SourceType[message.sourceType] : message.sourceType;
                    return object;
                };
    
                /**
                 * Converts this Condition to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.Condition
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Condition.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Condition
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.Condition
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Condition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.Condition";
                };
    
                return Condition;
            })();
    
            tasks.Conditions = (function() {
    
                /**
                 * Properties of a Conditions.
                 * @memberof infinitusai.tasks
                 * @interface IConditions
                 * @property {Array.<infinitusai.tasks.ICondition>|null} [conditions] Conditions conditions
                 */
    
                /**
                 * Constructs a new Conditions.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a Conditions.
                 * @implements IConditions
                 * @constructor
                 * @param {infinitusai.tasks.IConditions=} [properties] Properties to set
                 */
                function Conditions(properties) {
                    this.conditions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Conditions conditions.
                 * @member {Array.<infinitusai.tasks.ICondition>} conditions
                 * @memberof infinitusai.tasks.Conditions
                 * @instance
                 */
                Conditions.prototype.conditions = $util.emptyArray;
    
                /**
                 * Creates a new Conditions instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {infinitusai.tasks.IConditions=} [properties] Properties to set
                 * @returns {infinitusai.tasks.Conditions} Conditions instance
                 */
                Conditions.create = function create(properties) {
                    return new Conditions(properties);
                };
    
                /**
                 * Encodes the specified Conditions message. Does not implicitly {@link infinitusai.tasks.Conditions.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {infinitusai.tasks.IConditions} message Conditions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Conditions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.conditions != null && message.conditions.length)
                        for (var i = 0; i < message.conditions.length; ++i)
                            $root.infinitusai.tasks.Condition.encode(message.conditions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Conditions message, length delimited. Does not implicitly {@link infinitusai.tasks.Conditions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {infinitusai.tasks.IConditions} message Conditions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Conditions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Conditions message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.Conditions} Conditions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Conditions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.Conditions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.conditions && message.conditions.length))
                                    message.conditions = [];
                                message.conditions.push($root.infinitusai.tasks.Condition.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Conditions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.Conditions} Conditions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Conditions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Conditions message.
                 * @function verify
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Conditions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.conditions != null && message.hasOwnProperty("conditions")) {
                        if (!Array.isArray(message.conditions))
                            return "conditions: array expected";
                        for (var i = 0; i < message.conditions.length; ++i) {
                            var error = $root.infinitusai.tasks.Condition.verify(message.conditions[i]);
                            if (error)
                                return "conditions." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Conditions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.Conditions} Conditions
                 */
                Conditions.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.Conditions)
                        return object;
                    var message = new $root.infinitusai.tasks.Conditions();
                    if (object.conditions) {
                        if (!Array.isArray(object.conditions))
                            throw TypeError(".infinitusai.tasks.Conditions.conditions: array expected");
                        message.conditions = [];
                        for (var i = 0; i < object.conditions.length; ++i) {
                            if (typeof object.conditions[i] !== "object")
                                throw TypeError(".infinitusai.tasks.Conditions.conditions: object expected");
                            message.conditions[i] = $root.infinitusai.tasks.Condition.fromObject(object.conditions[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Conditions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {infinitusai.tasks.Conditions} message Conditions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Conditions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.conditions = [];
                    if (message.conditions && message.conditions.length) {
                        object.conditions = [];
                        for (var j = 0; j < message.conditions.length; ++j)
                            object.conditions[j] = $root.infinitusai.tasks.Condition.toObject(message.conditions[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Conditions to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.Conditions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Conditions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Conditions
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.Conditions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Conditions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.Conditions";
                };
    
                return Conditions;
            })();
    
            /**
             * SourceType enum.
             * @name infinitusai.tasks.SourceType
             * @enum {number}
             * @property {number} UNKOWN=0 UNKOWN value
             * @property {number} INPUT=1 INPUT value
             * @property {number} OUTPUT=2 OUTPUT value
             * @property {number} OPTIONAL_OUTPUT=3 OPTIONAL_OUTPUT value
             */
            tasks.SourceType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKOWN"] = 0;
                values[valuesById[1] = "INPUT"] = 1;
                values[valuesById[2] = "OUTPUT"] = 2;
                values[valuesById[3] = "OPTIONAL_OUTPUT"] = 3;
                return values;
            })();
    
            tasks.Predicate = (function() {
    
                /**
                 * Properties of a Predicate.
                 * @memberof infinitusai.tasks
                 * @interface IPredicate
                 * @property {infinitusai.tasks.IEqualValue|null} [equalPredicate] Predicate equalPredicate
                 * @property {infinitusai.tasks.IEqualValue|null} [notEqualPredicate] Predicate notEqualPredicate
                 * @property {infinitusai.tasks.IRepeatedEqualValue|null} [inPredicate] Predicate inPredicate
                 * @property {infinitusai.tasks.IRepeatedEqualValue|null} [notInPredicate] Predicate notInPredicate
                 * @property {string|null} [hasPrefixPredicate] Predicate hasPrefixPredicate
                 * @property {string|null} [hasSubstringPredicate] Predicate hasSubstringPredicate
                 * @property {infinitusai.tasks.IInequalityValue|null} [gte] Predicate gte
                 * @property {infinitusai.tasks.IInequalityValue|null} [gt] Predicate gt
                 * @property {infinitusai.tasks.IInequalityValue|null} [lte] Predicate lte
                 * @property {infinitusai.tasks.IInequalityValue|null} [lt] Predicate lt
                 */
    
                /**
                 * Constructs a new Predicate.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a Predicate.
                 * @implements IPredicate
                 * @constructor
                 * @param {infinitusai.tasks.IPredicate=} [properties] Properties to set
                 */
                function Predicate(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Predicate equalPredicate.
                 * @member {infinitusai.tasks.IEqualValue|null|undefined} equalPredicate
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.equalPredicate = null;
    
                /**
                 * Predicate notEqualPredicate.
                 * @member {infinitusai.tasks.IEqualValue|null|undefined} notEqualPredicate
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.notEqualPredicate = null;
    
                /**
                 * Predicate inPredicate.
                 * @member {infinitusai.tasks.IRepeatedEqualValue|null|undefined} inPredicate
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.inPredicate = null;
    
                /**
                 * Predicate notInPredicate.
                 * @member {infinitusai.tasks.IRepeatedEqualValue|null|undefined} notInPredicate
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.notInPredicate = null;
    
                /**
                 * Predicate hasPrefixPredicate.
                 * @member {string|null|undefined} hasPrefixPredicate
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.hasPrefixPredicate = null;
    
                /**
                 * Predicate hasSubstringPredicate.
                 * @member {string|null|undefined} hasSubstringPredicate
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.hasSubstringPredicate = null;
    
                /**
                 * Predicate gte.
                 * @member {infinitusai.tasks.IInequalityValue|null|undefined} gte
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.gte = null;
    
                /**
                 * Predicate gt.
                 * @member {infinitusai.tasks.IInequalityValue|null|undefined} gt
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.gt = null;
    
                /**
                 * Predicate lte.
                 * @member {infinitusai.tasks.IInequalityValue|null|undefined} lte
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.lte = null;
    
                /**
                 * Predicate lt.
                 * @member {infinitusai.tasks.IInequalityValue|null|undefined} lt
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Predicate.prototype.lt = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * Predicate oneOfPredicate.
                 * @member {"equalPredicate"|"notEqualPredicate"|"inPredicate"|"notInPredicate"|"hasPrefixPredicate"|"hasSubstringPredicate"|"gte"|"gt"|"lte"|"lt"|undefined} oneOfPredicate
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 */
                Object.defineProperty(Predicate.prototype, "oneOfPredicate", {
                    get: $util.oneOfGetter($oneOfFields = ["equalPredicate", "notEqualPredicate", "inPredicate", "notInPredicate", "hasPrefixPredicate", "hasSubstringPredicate", "gte", "gt", "lte", "lt"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new Predicate instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {infinitusai.tasks.IPredicate=} [properties] Properties to set
                 * @returns {infinitusai.tasks.Predicate} Predicate instance
                 */
                Predicate.create = function create(properties) {
                    return new Predicate(properties);
                };
    
                /**
                 * Encodes the specified Predicate message. Does not implicitly {@link infinitusai.tasks.Predicate.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {infinitusai.tasks.IPredicate} message Predicate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Predicate.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.equalPredicate != null && Object.hasOwnProperty.call(message, "equalPredicate"))
                        $root.infinitusai.tasks.EqualValue.encode(message.equalPredicate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.notEqualPredicate != null && Object.hasOwnProperty.call(message, "notEqualPredicate"))
                        $root.infinitusai.tasks.EqualValue.encode(message.notEqualPredicate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.inPredicate != null && Object.hasOwnProperty.call(message, "inPredicate"))
                        $root.infinitusai.tasks.RepeatedEqualValue.encode(message.inPredicate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.notInPredicate != null && Object.hasOwnProperty.call(message, "notInPredicate"))
                        $root.infinitusai.tasks.RepeatedEqualValue.encode(message.notInPredicate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.hasPrefixPredicate != null && Object.hasOwnProperty.call(message, "hasPrefixPredicate"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.hasPrefixPredicate);
                    if (message.hasSubstringPredicate != null && Object.hasOwnProperty.call(message, "hasSubstringPredicate"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.hasSubstringPredicate);
                    if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                        $root.infinitusai.tasks.InequalityValue.encode(message.gte, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                        $root.infinitusai.tasks.InequalityValue.encode(message.gt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                        $root.infinitusai.tasks.InequalityValue.encode(message.lte, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                        $root.infinitusai.tasks.InequalityValue.encode(message.lt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Predicate message, length delimited. Does not implicitly {@link infinitusai.tasks.Predicate.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {infinitusai.tasks.IPredicate} message Predicate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Predicate.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Predicate message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.Predicate} Predicate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Predicate.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.Predicate();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.equalPredicate = $root.infinitusai.tasks.EqualValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.notEqualPredicate = $root.infinitusai.tasks.EqualValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.inPredicate = $root.infinitusai.tasks.RepeatedEqualValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.notInPredicate = $root.infinitusai.tasks.RepeatedEqualValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.hasPrefixPredicate = reader.string();
                                break;
                            }
                        case 7: {
                                message.hasSubstringPredicate = reader.string();
                                break;
                            }
                        case 8: {
                                message.gte = $root.infinitusai.tasks.InequalityValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.gt = $root.infinitusai.tasks.InequalityValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.lte = $root.infinitusai.tasks.InequalityValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.lt = $root.infinitusai.tasks.InequalityValue.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Predicate message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.Predicate} Predicate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Predicate.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Predicate message.
                 * @function verify
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Predicate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.equalPredicate != null && message.hasOwnProperty("equalPredicate")) {
                        properties.oneOfPredicate = 1;
                        {
                            var error = $root.infinitusai.tasks.EqualValue.verify(message.equalPredicate);
                            if (error)
                                return "equalPredicate." + error;
                        }
                    }
                    if (message.notEqualPredicate != null && message.hasOwnProperty("notEqualPredicate")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        {
                            var error = $root.infinitusai.tasks.EqualValue.verify(message.notEqualPredicate);
                            if (error)
                                return "notEqualPredicate." + error;
                        }
                    }
                    if (message.inPredicate != null && message.hasOwnProperty("inPredicate")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        {
                            var error = $root.infinitusai.tasks.RepeatedEqualValue.verify(message.inPredicate);
                            if (error)
                                return "inPredicate." + error;
                        }
                    }
                    if (message.notInPredicate != null && message.hasOwnProperty("notInPredicate")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        {
                            var error = $root.infinitusai.tasks.RepeatedEqualValue.verify(message.notInPredicate);
                            if (error)
                                return "notInPredicate." + error;
                        }
                    }
                    if (message.hasPrefixPredicate != null && message.hasOwnProperty("hasPrefixPredicate")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        if (!$util.isString(message.hasPrefixPredicate))
                            return "hasPrefixPredicate: string expected";
                    }
                    if (message.hasSubstringPredicate != null && message.hasOwnProperty("hasSubstringPredicate")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        if (!$util.isString(message.hasSubstringPredicate))
                            return "hasSubstringPredicate: string expected";
                    }
                    if (message.gte != null && message.hasOwnProperty("gte")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        {
                            var error = $root.infinitusai.tasks.InequalityValue.verify(message.gte);
                            if (error)
                                return "gte." + error;
                        }
                    }
                    if (message.gt != null && message.hasOwnProperty("gt")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        {
                            var error = $root.infinitusai.tasks.InequalityValue.verify(message.gt);
                            if (error)
                                return "gt." + error;
                        }
                    }
                    if (message.lte != null && message.hasOwnProperty("lte")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        {
                            var error = $root.infinitusai.tasks.InequalityValue.verify(message.lte);
                            if (error)
                                return "lte." + error;
                        }
                    }
                    if (message.lt != null && message.hasOwnProperty("lt")) {
                        if (properties.oneOfPredicate === 1)
                            return "oneOfPredicate: multiple values";
                        properties.oneOfPredicate = 1;
                        {
                            var error = $root.infinitusai.tasks.InequalityValue.verify(message.lt);
                            if (error)
                                return "lt." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Predicate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.Predicate} Predicate
                 */
                Predicate.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.Predicate)
                        return object;
                    var message = new $root.infinitusai.tasks.Predicate();
                    if (object.equalPredicate != null) {
                        if (typeof object.equalPredicate !== "object")
                            throw TypeError(".infinitusai.tasks.Predicate.equalPredicate: object expected");
                        message.equalPredicate = $root.infinitusai.tasks.EqualValue.fromObject(object.equalPredicate);
                    }
                    if (object.notEqualPredicate != null) {
                        if (typeof object.notEqualPredicate !== "object")
                            throw TypeError(".infinitusai.tasks.Predicate.notEqualPredicate: object expected");
                        message.notEqualPredicate = $root.infinitusai.tasks.EqualValue.fromObject(object.notEqualPredicate);
                    }
                    if (object.inPredicate != null) {
                        if (typeof object.inPredicate !== "object")
                            throw TypeError(".infinitusai.tasks.Predicate.inPredicate: object expected");
                        message.inPredicate = $root.infinitusai.tasks.RepeatedEqualValue.fromObject(object.inPredicate);
                    }
                    if (object.notInPredicate != null) {
                        if (typeof object.notInPredicate !== "object")
                            throw TypeError(".infinitusai.tasks.Predicate.notInPredicate: object expected");
                        message.notInPredicate = $root.infinitusai.tasks.RepeatedEqualValue.fromObject(object.notInPredicate);
                    }
                    if (object.hasPrefixPredicate != null)
                        message.hasPrefixPredicate = String(object.hasPrefixPredicate);
                    if (object.hasSubstringPredicate != null)
                        message.hasSubstringPredicate = String(object.hasSubstringPredicate);
                    if (object.gte != null) {
                        if (typeof object.gte !== "object")
                            throw TypeError(".infinitusai.tasks.Predicate.gte: object expected");
                        message.gte = $root.infinitusai.tasks.InequalityValue.fromObject(object.gte);
                    }
                    if (object.gt != null) {
                        if (typeof object.gt !== "object")
                            throw TypeError(".infinitusai.tasks.Predicate.gt: object expected");
                        message.gt = $root.infinitusai.tasks.InequalityValue.fromObject(object.gt);
                    }
                    if (object.lte != null) {
                        if (typeof object.lte !== "object")
                            throw TypeError(".infinitusai.tasks.Predicate.lte: object expected");
                        message.lte = $root.infinitusai.tasks.InequalityValue.fromObject(object.lte);
                    }
                    if (object.lt != null) {
                        if (typeof object.lt !== "object")
                            throw TypeError(".infinitusai.tasks.Predicate.lt: object expected");
                        message.lt = $root.infinitusai.tasks.InequalityValue.fromObject(object.lt);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Predicate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {infinitusai.tasks.Predicate} message Predicate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Predicate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.equalPredicate != null && message.hasOwnProperty("equalPredicate")) {
                        object.equalPredicate = $root.infinitusai.tasks.EqualValue.toObject(message.equalPredicate, options);
                        if (options.oneofs)
                            object.oneOfPredicate = "equalPredicate";
                    }
                    if (message.notEqualPredicate != null && message.hasOwnProperty("notEqualPredicate")) {
                        object.notEqualPredicate = $root.infinitusai.tasks.EqualValue.toObject(message.notEqualPredicate, options);
                        if (options.oneofs)
                            object.oneOfPredicate = "notEqualPredicate";
                    }
                    if (message.inPredicate != null && message.hasOwnProperty("inPredicate")) {
                        object.inPredicate = $root.infinitusai.tasks.RepeatedEqualValue.toObject(message.inPredicate, options);
                        if (options.oneofs)
                            object.oneOfPredicate = "inPredicate";
                    }
                    if (message.notInPredicate != null && message.hasOwnProperty("notInPredicate")) {
                        object.notInPredicate = $root.infinitusai.tasks.RepeatedEqualValue.toObject(message.notInPredicate, options);
                        if (options.oneofs)
                            object.oneOfPredicate = "notInPredicate";
                    }
                    if (message.hasPrefixPredicate != null && message.hasOwnProperty("hasPrefixPredicate")) {
                        object.hasPrefixPredicate = message.hasPrefixPredicate;
                        if (options.oneofs)
                            object.oneOfPredicate = "hasPrefixPredicate";
                    }
                    if (message.hasSubstringPredicate != null && message.hasOwnProperty("hasSubstringPredicate")) {
                        object.hasSubstringPredicate = message.hasSubstringPredicate;
                        if (options.oneofs)
                            object.oneOfPredicate = "hasSubstringPredicate";
                    }
                    if (message.gte != null && message.hasOwnProperty("gte")) {
                        object.gte = $root.infinitusai.tasks.InequalityValue.toObject(message.gte, options);
                        if (options.oneofs)
                            object.oneOfPredicate = "gte";
                    }
                    if (message.gt != null && message.hasOwnProperty("gt")) {
                        object.gt = $root.infinitusai.tasks.InequalityValue.toObject(message.gt, options);
                        if (options.oneofs)
                            object.oneOfPredicate = "gt";
                    }
                    if (message.lte != null && message.hasOwnProperty("lte")) {
                        object.lte = $root.infinitusai.tasks.InequalityValue.toObject(message.lte, options);
                        if (options.oneofs)
                            object.oneOfPredicate = "lte";
                    }
                    if (message.lt != null && message.hasOwnProperty("lt")) {
                        object.lt = $root.infinitusai.tasks.InequalityValue.toObject(message.lt, options);
                        if (options.oneofs)
                            object.oneOfPredicate = "lt";
                    }
                    return object;
                };
    
                /**
                 * Converts this Predicate to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.Predicate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Predicate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Predicate
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.Predicate
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Predicate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.Predicate";
                };
    
                return Predicate;
            })();
    
            tasks.EqualValue = (function() {
    
                /**
                 * Properties of an EqualValue.
                 * @memberof infinitusai.tasks
                 * @interface IEqualValue
                 * @property {string|null} [stringType] EqualValue stringType
                 * @property {number|Long|null} [intType] EqualValue intType
                 * @property {boolean|null} [boolType] EqualValue boolType
                 * @property {number|Long|null} [moneyType] EqualValue moneyType
                 * @property {string|null} [enumType] EqualValue enumType
                 * @property {infinitusai.tasks.IDateType|null} [dateType] EqualValue dateType
                 */
    
                /**
                 * Constructs a new EqualValue.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an EqualValue.
                 * @implements IEqualValue
                 * @constructor
                 * @param {infinitusai.tasks.IEqualValue=} [properties] Properties to set
                 */
                function EqualValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EqualValue stringType.
                 * @member {string|null|undefined} stringType
                 * @memberof infinitusai.tasks.EqualValue
                 * @instance
                 */
                EqualValue.prototype.stringType = null;
    
                /**
                 * EqualValue intType.
                 * @member {number|Long|null|undefined} intType
                 * @memberof infinitusai.tasks.EqualValue
                 * @instance
                 */
                EqualValue.prototype.intType = null;
    
                /**
                 * EqualValue boolType.
                 * @member {boolean|null|undefined} boolType
                 * @memberof infinitusai.tasks.EqualValue
                 * @instance
                 */
                EqualValue.prototype.boolType = null;
    
                /**
                 * EqualValue moneyType.
                 * @member {number|Long|null|undefined} moneyType
                 * @memberof infinitusai.tasks.EqualValue
                 * @instance
                 */
                EqualValue.prototype.moneyType = null;
    
                /**
                 * EqualValue enumType.
                 * @member {string|null|undefined} enumType
                 * @memberof infinitusai.tasks.EqualValue
                 * @instance
                 */
                EqualValue.prototype.enumType = null;
    
                /**
                 * EqualValue dateType.
                 * @member {infinitusai.tasks.IDateType|null|undefined} dateType
                 * @memberof infinitusai.tasks.EqualValue
                 * @instance
                 */
                EqualValue.prototype.dateType = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * EqualValue oneOf.
                 * @member {"stringType"|"intType"|"boolType"|"moneyType"|"enumType"|"dateType"|undefined} oneOf
                 * @memberof infinitusai.tasks.EqualValue
                 * @instance
                 */
                Object.defineProperty(EqualValue.prototype, "oneOf", {
                    get: $util.oneOfGetter($oneOfFields = ["stringType", "intType", "boolType", "moneyType", "enumType", "dateType"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new EqualValue instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {infinitusai.tasks.IEqualValue=} [properties] Properties to set
                 * @returns {infinitusai.tasks.EqualValue} EqualValue instance
                 */
                EqualValue.create = function create(properties) {
                    return new EqualValue(properties);
                };
    
                /**
                 * Encodes the specified EqualValue message. Does not implicitly {@link infinitusai.tasks.EqualValue.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {infinitusai.tasks.IEqualValue} message EqualValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EqualValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stringType != null && Object.hasOwnProperty.call(message, "stringType"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stringType);
                    if (message.intType != null && Object.hasOwnProperty.call(message, "intType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intType);
                    if (message.boolType != null && Object.hasOwnProperty.call(message, "boolType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.boolType);
                    if (message.moneyType != null && Object.hasOwnProperty.call(message, "moneyType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.moneyType);
                    if (message.enumType != null && Object.hasOwnProperty.call(message, "enumType"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.enumType);
                    if (message.dateType != null && Object.hasOwnProperty.call(message, "dateType"))
                        $root.infinitusai.tasks.DateType.encode(message.dateType, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EqualValue message, length delimited. Does not implicitly {@link infinitusai.tasks.EqualValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {infinitusai.tasks.IEqualValue} message EqualValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EqualValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EqualValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.EqualValue} EqualValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EqualValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.EqualValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.stringType = reader.string();
                                break;
                            }
                        case 2: {
                                message.intType = reader.int64();
                                break;
                            }
                        case 3: {
                                message.boolType = reader.bool();
                                break;
                            }
                        case 4: {
                                message.moneyType = reader.int64();
                                break;
                            }
                        case 5: {
                                message.enumType = reader.string();
                                break;
                            }
                        case 6: {
                                message.dateType = $root.infinitusai.tasks.DateType.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EqualValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.EqualValue} EqualValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EqualValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EqualValue message.
                 * @function verify
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EqualValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.stringType != null && message.hasOwnProperty("stringType")) {
                        properties.oneOf = 1;
                        if (!$util.isString(message.stringType))
                            return "stringType: string expected";
                    }
                    if (message.intType != null && message.hasOwnProperty("intType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (!$util.isInteger(message.intType) && !(message.intType && $util.isInteger(message.intType.low) && $util.isInteger(message.intType.high)))
                            return "intType: integer|Long expected";
                    }
                    if (message.boolType != null && message.hasOwnProperty("boolType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (typeof message.boolType !== "boolean")
                            return "boolType: boolean expected";
                    }
                    if (message.moneyType != null && message.hasOwnProperty("moneyType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (!$util.isInteger(message.moneyType) && !(message.moneyType && $util.isInteger(message.moneyType.low) && $util.isInteger(message.moneyType.high)))
                            return "moneyType: integer|Long expected";
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (!$util.isString(message.enumType))
                            return "enumType: string expected";
                    }
                    if (message.dateType != null && message.hasOwnProperty("dateType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        {
                            var error = $root.infinitusai.tasks.DateType.verify(message.dateType);
                            if (error)
                                return "dateType." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an EqualValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.EqualValue} EqualValue
                 */
                EqualValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.EqualValue)
                        return object;
                    var message = new $root.infinitusai.tasks.EqualValue();
                    if (object.stringType != null)
                        message.stringType = String(object.stringType);
                    if (object.intType != null)
                        if ($util.Long)
                            (message.intType = $util.Long.fromValue(object.intType)).unsigned = false;
                        else if (typeof object.intType === "string")
                            message.intType = parseInt(object.intType, 10);
                        else if (typeof object.intType === "number")
                            message.intType = object.intType;
                        else if (typeof object.intType === "object")
                            message.intType = new $util.LongBits(object.intType.low >>> 0, object.intType.high >>> 0).toNumber();
                    if (object.boolType != null)
                        message.boolType = Boolean(object.boolType);
                    if (object.moneyType != null)
                        if ($util.Long)
                            (message.moneyType = $util.Long.fromValue(object.moneyType)).unsigned = false;
                        else if (typeof object.moneyType === "string")
                            message.moneyType = parseInt(object.moneyType, 10);
                        else if (typeof object.moneyType === "number")
                            message.moneyType = object.moneyType;
                        else if (typeof object.moneyType === "object")
                            message.moneyType = new $util.LongBits(object.moneyType.low >>> 0, object.moneyType.high >>> 0).toNumber();
                    if (object.enumType != null)
                        message.enumType = String(object.enumType);
                    if (object.dateType != null) {
                        if (typeof object.dateType !== "object")
                            throw TypeError(".infinitusai.tasks.EqualValue.dateType: object expected");
                        message.dateType = $root.infinitusai.tasks.DateType.fromObject(object.dateType);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EqualValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {infinitusai.tasks.EqualValue} message EqualValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EqualValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.stringType != null && message.hasOwnProperty("stringType")) {
                        object.stringType = message.stringType;
                        if (options.oneofs)
                            object.oneOf = "stringType";
                    }
                    if (message.intType != null && message.hasOwnProperty("intType")) {
                        if (typeof message.intType === "number")
                            object.intType = options.longs === String ? String(message.intType) : message.intType;
                        else
                            object.intType = options.longs === String ? $util.Long.prototype.toString.call(message.intType) : options.longs === Number ? new $util.LongBits(message.intType.low >>> 0, message.intType.high >>> 0).toNumber() : message.intType;
                        if (options.oneofs)
                            object.oneOf = "intType";
                    }
                    if (message.boolType != null && message.hasOwnProperty("boolType")) {
                        object.boolType = message.boolType;
                        if (options.oneofs)
                            object.oneOf = "boolType";
                    }
                    if (message.moneyType != null && message.hasOwnProperty("moneyType")) {
                        if (typeof message.moneyType === "number")
                            object.moneyType = options.longs === String ? String(message.moneyType) : message.moneyType;
                        else
                            object.moneyType = options.longs === String ? $util.Long.prototype.toString.call(message.moneyType) : options.longs === Number ? new $util.LongBits(message.moneyType.low >>> 0, message.moneyType.high >>> 0).toNumber() : message.moneyType;
                        if (options.oneofs)
                            object.oneOf = "moneyType";
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        object.enumType = message.enumType;
                        if (options.oneofs)
                            object.oneOf = "enumType";
                    }
                    if (message.dateType != null && message.hasOwnProperty("dateType")) {
                        object.dateType = $root.infinitusai.tasks.DateType.toObject(message.dateType, options);
                        if (options.oneofs)
                            object.oneOf = "dateType";
                    }
                    return object;
                };
    
                /**
                 * Converts this EqualValue to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.EqualValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EqualValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EqualValue
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.EqualValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EqualValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.EqualValue";
                };
    
                return EqualValue;
            })();
    
            tasks.InequalityValue = (function() {
    
                /**
                 * Properties of an InequalityValue.
                 * @memberof infinitusai.tasks
                 * @interface IInequalityValue
                 * @property {number|Long|null} [intType] InequalityValue intType
                 * @property {number|Long|null} [moneyType] InequalityValue moneyType
                 * @property {infinitusai.tasks.IDateType|null} [dateType] InequalityValue dateType
                 */
    
                /**
                 * Constructs a new InequalityValue.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an InequalityValue.
                 * @implements IInequalityValue
                 * @constructor
                 * @param {infinitusai.tasks.IInequalityValue=} [properties] Properties to set
                 */
                function InequalityValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * InequalityValue intType.
                 * @member {number|Long|null|undefined} intType
                 * @memberof infinitusai.tasks.InequalityValue
                 * @instance
                 */
                InequalityValue.prototype.intType = null;
    
                /**
                 * InequalityValue moneyType.
                 * @member {number|Long|null|undefined} moneyType
                 * @memberof infinitusai.tasks.InequalityValue
                 * @instance
                 */
                InequalityValue.prototype.moneyType = null;
    
                /**
                 * InequalityValue dateType.
                 * @member {infinitusai.tasks.IDateType|null|undefined} dateType
                 * @memberof infinitusai.tasks.InequalityValue
                 * @instance
                 */
                InequalityValue.prototype.dateType = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * InequalityValue oneOf.
                 * @member {"intType"|"moneyType"|"dateType"|undefined} oneOf
                 * @memberof infinitusai.tasks.InequalityValue
                 * @instance
                 */
                Object.defineProperty(InequalityValue.prototype, "oneOf", {
                    get: $util.oneOfGetter($oneOfFields = ["intType", "moneyType", "dateType"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new InequalityValue instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {infinitusai.tasks.IInequalityValue=} [properties] Properties to set
                 * @returns {infinitusai.tasks.InequalityValue} InequalityValue instance
                 */
                InequalityValue.create = function create(properties) {
                    return new InequalityValue(properties);
                };
    
                /**
                 * Encodes the specified InequalityValue message. Does not implicitly {@link infinitusai.tasks.InequalityValue.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {infinitusai.tasks.IInequalityValue} message InequalityValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InequalityValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.intType != null && Object.hasOwnProperty.call(message, "intType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intType);
                    if (message.moneyType != null && Object.hasOwnProperty.call(message, "moneyType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.moneyType);
                    if (message.dateType != null && Object.hasOwnProperty.call(message, "dateType"))
                        $root.infinitusai.tasks.DateType.encode(message.dateType, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified InequalityValue message, length delimited. Does not implicitly {@link infinitusai.tasks.InequalityValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {infinitusai.tasks.IInequalityValue} message InequalityValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InequalityValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an InequalityValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.InequalityValue} InequalityValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InequalityValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.InequalityValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.intType = reader.int64();
                                break;
                            }
                        case 4: {
                                message.moneyType = reader.int64();
                                break;
                            }
                        case 6: {
                                message.dateType = $root.infinitusai.tasks.DateType.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an InequalityValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.InequalityValue} InequalityValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InequalityValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an InequalityValue message.
                 * @function verify
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InequalityValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.intType != null && message.hasOwnProperty("intType")) {
                        properties.oneOf = 1;
                        if (!$util.isInteger(message.intType) && !(message.intType && $util.isInteger(message.intType.low) && $util.isInteger(message.intType.high)))
                            return "intType: integer|Long expected";
                    }
                    if (message.moneyType != null && message.hasOwnProperty("moneyType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (!$util.isInteger(message.moneyType) && !(message.moneyType && $util.isInteger(message.moneyType.low) && $util.isInteger(message.moneyType.high)))
                            return "moneyType: integer|Long expected";
                    }
                    if (message.dateType != null && message.hasOwnProperty("dateType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        {
                            var error = $root.infinitusai.tasks.DateType.verify(message.dateType);
                            if (error)
                                return "dateType." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an InequalityValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.InequalityValue} InequalityValue
                 */
                InequalityValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.InequalityValue)
                        return object;
                    var message = new $root.infinitusai.tasks.InequalityValue();
                    if (object.intType != null)
                        if ($util.Long)
                            (message.intType = $util.Long.fromValue(object.intType)).unsigned = false;
                        else if (typeof object.intType === "string")
                            message.intType = parseInt(object.intType, 10);
                        else if (typeof object.intType === "number")
                            message.intType = object.intType;
                        else if (typeof object.intType === "object")
                            message.intType = new $util.LongBits(object.intType.low >>> 0, object.intType.high >>> 0).toNumber();
                    if (object.moneyType != null)
                        if ($util.Long)
                            (message.moneyType = $util.Long.fromValue(object.moneyType)).unsigned = false;
                        else if (typeof object.moneyType === "string")
                            message.moneyType = parseInt(object.moneyType, 10);
                        else if (typeof object.moneyType === "number")
                            message.moneyType = object.moneyType;
                        else if (typeof object.moneyType === "object")
                            message.moneyType = new $util.LongBits(object.moneyType.low >>> 0, object.moneyType.high >>> 0).toNumber();
                    if (object.dateType != null) {
                        if (typeof object.dateType !== "object")
                            throw TypeError(".infinitusai.tasks.InequalityValue.dateType: object expected");
                        message.dateType = $root.infinitusai.tasks.DateType.fromObject(object.dateType);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an InequalityValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {infinitusai.tasks.InequalityValue} message InequalityValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InequalityValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.intType != null && message.hasOwnProperty("intType")) {
                        if (typeof message.intType === "number")
                            object.intType = options.longs === String ? String(message.intType) : message.intType;
                        else
                            object.intType = options.longs === String ? $util.Long.prototype.toString.call(message.intType) : options.longs === Number ? new $util.LongBits(message.intType.low >>> 0, message.intType.high >>> 0).toNumber() : message.intType;
                        if (options.oneofs)
                            object.oneOf = "intType";
                    }
                    if (message.moneyType != null && message.hasOwnProperty("moneyType")) {
                        if (typeof message.moneyType === "number")
                            object.moneyType = options.longs === String ? String(message.moneyType) : message.moneyType;
                        else
                            object.moneyType = options.longs === String ? $util.Long.prototype.toString.call(message.moneyType) : options.longs === Number ? new $util.LongBits(message.moneyType.low >>> 0, message.moneyType.high >>> 0).toNumber() : message.moneyType;
                        if (options.oneofs)
                            object.oneOf = "moneyType";
                    }
                    if (message.dateType != null && message.hasOwnProperty("dateType")) {
                        object.dateType = $root.infinitusai.tasks.DateType.toObject(message.dateType, options);
                        if (options.oneofs)
                            object.oneOf = "dateType";
                    }
                    return object;
                };
    
                /**
                 * Converts this InequalityValue to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.InequalityValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InequalityValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for InequalityValue
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.InequalityValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InequalityValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.InequalityValue";
                };
    
                return InequalityValue;
            })();
    
            tasks.RepeatedEqualValue = (function() {
    
                /**
                 * Properties of a RepeatedEqualValue.
                 * @memberof infinitusai.tasks
                 * @interface IRepeatedEqualValue
                 * @property {Array.<infinitusai.tasks.IEqualValue>|null} [equalValue] RepeatedEqualValue equalValue
                 */
    
                /**
                 * Constructs a new RepeatedEqualValue.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a RepeatedEqualValue.
                 * @implements IRepeatedEqualValue
                 * @constructor
                 * @param {infinitusai.tasks.IRepeatedEqualValue=} [properties] Properties to set
                 */
                function RepeatedEqualValue(properties) {
                    this.equalValue = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * RepeatedEqualValue equalValue.
                 * @member {Array.<infinitusai.tasks.IEqualValue>} equalValue
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @instance
                 */
                RepeatedEqualValue.prototype.equalValue = $util.emptyArray;
    
                /**
                 * Creates a new RepeatedEqualValue instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {infinitusai.tasks.IRepeatedEqualValue=} [properties] Properties to set
                 * @returns {infinitusai.tasks.RepeatedEqualValue} RepeatedEqualValue instance
                 */
                RepeatedEqualValue.create = function create(properties) {
                    return new RepeatedEqualValue(properties);
                };
    
                /**
                 * Encodes the specified RepeatedEqualValue message. Does not implicitly {@link infinitusai.tasks.RepeatedEqualValue.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {infinitusai.tasks.IRepeatedEqualValue} message RepeatedEqualValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RepeatedEqualValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.equalValue != null && message.equalValue.length)
                        for (var i = 0; i < message.equalValue.length; ++i)
                            $root.infinitusai.tasks.EqualValue.encode(message.equalValue[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified RepeatedEqualValue message, length delimited. Does not implicitly {@link infinitusai.tasks.RepeatedEqualValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {infinitusai.tasks.IRepeatedEqualValue} message RepeatedEqualValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RepeatedEqualValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a RepeatedEqualValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.RepeatedEqualValue} RepeatedEqualValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RepeatedEqualValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.RepeatedEqualValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.equalValue && message.equalValue.length))
                                    message.equalValue = [];
                                message.equalValue.push($root.infinitusai.tasks.EqualValue.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a RepeatedEqualValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.RepeatedEqualValue} RepeatedEqualValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RepeatedEqualValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a RepeatedEqualValue message.
                 * @function verify
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RepeatedEqualValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.equalValue != null && message.hasOwnProperty("equalValue")) {
                        if (!Array.isArray(message.equalValue))
                            return "equalValue: array expected";
                        for (var i = 0; i < message.equalValue.length; ++i) {
                            var error = $root.infinitusai.tasks.EqualValue.verify(message.equalValue[i]);
                            if (error)
                                return "equalValue." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a RepeatedEqualValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.RepeatedEqualValue} RepeatedEqualValue
                 */
                RepeatedEqualValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.RepeatedEqualValue)
                        return object;
                    var message = new $root.infinitusai.tasks.RepeatedEqualValue();
                    if (object.equalValue) {
                        if (!Array.isArray(object.equalValue))
                            throw TypeError(".infinitusai.tasks.RepeatedEqualValue.equalValue: array expected");
                        message.equalValue = [];
                        for (var i = 0; i < object.equalValue.length; ++i) {
                            if (typeof object.equalValue[i] !== "object")
                                throw TypeError(".infinitusai.tasks.RepeatedEqualValue.equalValue: object expected");
                            message.equalValue[i] = $root.infinitusai.tasks.EqualValue.fromObject(object.equalValue[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a RepeatedEqualValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {infinitusai.tasks.RepeatedEqualValue} message RepeatedEqualValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RepeatedEqualValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.equalValue = [];
                    if (message.equalValue && message.equalValue.length) {
                        object.equalValue = [];
                        for (var j = 0; j < message.equalValue.length; ++j)
                            object.equalValue[j] = $root.infinitusai.tasks.EqualValue.toObject(message.equalValue[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this RepeatedEqualValue to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RepeatedEqualValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for RepeatedEqualValue
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.RepeatedEqualValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RepeatedEqualValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.RepeatedEqualValue";
                };
    
                return RepeatedEqualValue;
            })();
    
            tasks.Effect = (function() {
    
                /**
                 * Properties of an Effect.
                 * @memberof infinitusai.tasks
                 * @interface IEffect
                 * @property {infinitusai.tasks.IOutputFieldVisibilityEffect|null} [ouputFieldVisibilityEffect] Effect ouputFieldVisibilityEffect
                 * @property {infinitusai.tasks.IOutputFieldValueEffect|null} [outputFieldValueEffect] Effect outputFieldValueEffect
                 * @property {infinitusai.tasks.IFollowupSuggestionVisibilityEffect|null} [followupSuggestionVisibilityEffect] Effect followupSuggestionVisibilityEffect
                 */
    
                /**
                 * Constructs a new Effect.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an Effect.
                 * @implements IEffect
                 * @constructor
                 * @param {infinitusai.tasks.IEffect=} [properties] Properties to set
                 */
                function Effect(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Effect ouputFieldVisibilityEffect.
                 * @member {infinitusai.tasks.IOutputFieldVisibilityEffect|null|undefined} ouputFieldVisibilityEffect
                 * @memberof infinitusai.tasks.Effect
                 * @instance
                 */
                Effect.prototype.ouputFieldVisibilityEffect = null;
    
                /**
                 * Effect outputFieldValueEffect.
                 * @member {infinitusai.tasks.IOutputFieldValueEffect|null|undefined} outputFieldValueEffect
                 * @memberof infinitusai.tasks.Effect
                 * @instance
                 */
                Effect.prototype.outputFieldValueEffect = null;
    
                /**
                 * Effect followupSuggestionVisibilityEffect.
                 * @member {infinitusai.tasks.IFollowupSuggestionVisibilityEffect|null|undefined} followupSuggestionVisibilityEffect
                 * @memberof infinitusai.tasks.Effect
                 * @instance
                 */
                Effect.prototype.followupSuggestionVisibilityEffect = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * Effect oneOfEffects.
                 * @member {"ouputFieldVisibilityEffect"|"outputFieldValueEffect"|"followupSuggestionVisibilityEffect"|undefined} oneOfEffects
                 * @memberof infinitusai.tasks.Effect
                 * @instance
                 */
                Object.defineProperty(Effect.prototype, "oneOfEffects", {
                    get: $util.oneOfGetter($oneOfFields = ["ouputFieldVisibilityEffect", "outputFieldValueEffect", "followupSuggestionVisibilityEffect"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new Effect instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {infinitusai.tasks.IEffect=} [properties] Properties to set
                 * @returns {infinitusai.tasks.Effect} Effect instance
                 */
                Effect.create = function create(properties) {
                    return new Effect(properties);
                };
    
                /**
                 * Encodes the specified Effect message. Does not implicitly {@link infinitusai.tasks.Effect.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {infinitusai.tasks.IEffect} message Effect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Effect.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ouputFieldVisibilityEffect != null && Object.hasOwnProperty.call(message, "ouputFieldVisibilityEffect"))
                        $root.infinitusai.tasks.OutputFieldVisibilityEffect.encode(message.ouputFieldVisibilityEffect, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.outputFieldValueEffect != null && Object.hasOwnProperty.call(message, "outputFieldValueEffect"))
                        $root.infinitusai.tasks.OutputFieldValueEffect.encode(message.outputFieldValueEffect, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.followupSuggestionVisibilityEffect != null && Object.hasOwnProperty.call(message, "followupSuggestionVisibilityEffect"))
                        $root.infinitusai.tasks.FollowupSuggestionVisibilityEffect.encode(message.followupSuggestionVisibilityEffect, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Effect message, length delimited. Does not implicitly {@link infinitusai.tasks.Effect.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {infinitusai.tasks.IEffect} message Effect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Effect.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Effect message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.Effect} Effect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Effect.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.Effect();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ouputFieldVisibilityEffect = $root.infinitusai.tasks.OutputFieldVisibilityEffect.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.outputFieldValueEffect = $root.infinitusai.tasks.OutputFieldValueEffect.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.followupSuggestionVisibilityEffect = $root.infinitusai.tasks.FollowupSuggestionVisibilityEffect.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Effect message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.Effect} Effect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Effect.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Effect message.
                 * @function verify
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Effect.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.ouputFieldVisibilityEffect != null && message.hasOwnProperty("ouputFieldVisibilityEffect")) {
                        properties.oneOfEffects = 1;
                        {
                            var error = $root.infinitusai.tasks.OutputFieldVisibilityEffect.verify(message.ouputFieldVisibilityEffect);
                            if (error)
                                return "ouputFieldVisibilityEffect." + error;
                        }
                    }
                    if (message.outputFieldValueEffect != null && message.hasOwnProperty("outputFieldValueEffect")) {
                        if (properties.oneOfEffects === 1)
                            return "oneOfEffects: multiple values";
                        properties.oneOfEffects = 1;
                        {
                            var error = $root.infinitusai.tasks.OutputFieldValueEffect.verify(message.outputFieldValueEffect);
                            if (error)
                                return "outputFieldValueEffect." + error;
                        }
                    }
                    if (message.followupSuggestionVisibilityEffect != null && message.hasOwnProperty("followupSuggestionVisibilityEffect")) {
                        if (properties.oneOfEffects === 1)
                            return "oneOfEffects: multiple values";
                        properties.oneOfEffects = 1;
                        {
                            var error = $root.infinitusai.tasks.FollowupSuggestionVisibilityEffect.verify(message.followupSuggestionVisibilityEffect);
                            if (error)
                                return "followupSuggestionVisibilityEffect." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an Effect message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.Effect} Effect
                 */
                Effect.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.Effect)
                        return object;
                    var message = new $root.infinitusai.tasks.Effect();
                    if (object.ouputFieldVisibilityEffect != null) {
                        if (typeof object.ouputFieldVisibilityEffect !== "object")
                            throw TypeError(".infinitusai.tasks.Effect.ouputFieldVisibilityEffect: object expected");
                        message.ouputFieldVisibilityEffect = $root.infinitusai.tasks.OutputFieldVisibilityEffect.fromObject(object.ouputFieldVisibilityEffect);
                    }
                    if (object.outputFieldValueEffect != null) {
                        if (typeof object.outputFieldValueEffect !== "object")
                            throw TypeError(".infinitusai.tasks.Effect.outputFieldValueEffect: object expected");
                        message.outputFieldValueEffect = $root.infinitusai.tasks.OutputFieldValueEffect.fromObject(object.outputFieldValueEffect);
                    }
                    if (object.followupSuggestionVisibilityEffect != null) {
                        if (typeof object.followupSuggestionVisibilityEffect !== "object")
                            throw TypeError(".infinitusai.tasks.Effect.followupSuggestionVisibilityEffect: object expected");
                        message.followupSuggestionVisibilityEffect = $root.infinitusai.tasks.FollowupSuggestionVisibilityEffect.fromObject(object.followupSuggestionVisibilityEffect);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an Effect message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {infinitusai.tasks.Effect} message Effect
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Effect.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.ouputFieldVisibilityEffect != null && message.hasOwnProperty("ouputFieldVisibilityEffect")) {
                        object.ouputFieldVisibilityEffect = $root.infinitusai.tasks.OutputFieldVisibilityEffect.toObject(message.ouputFieldVisibilityEffect, options);
                        if (options.oneofs)
                            object.oneOfEffects = "ouputFieldVisibilityEffect";
                    }
                    if (message.outputFieldValueEffect != null && message.hasOwnProperty("outputFieldValueEffect")) {
                        object.outputFieldValueEffect = $root.infinitusai.tasks.OutputFieldValueEffect.toObject(message.outputFieldValueEffect, options);
                        if (options.oneofs)
                            object.oneOfEffects = "outputFieldValueEffect";
                    }
                    if (message.followupSuggestionVisibilityEffect != null && message.hasOwnProperty("followupSuggestionVisibilityEffect")) {
                        object.followupSuggestionVisibilityEffect = $root.infinitusai.tasks.FollowupSuggestionVisibilityEffect.toObject(message.followupSuggestionVisibilityEffect, options);
                        if (options.oneofs)
                            object.oneOfEffects = "followupSuggestionVisibilityEffect";
                    }
                    return object;
                };
    
                /**
                 * Converts this Effect to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.Effect
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Effect.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Effect
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.Effect
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Effect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.Effect";
                };
    
                return Effect;
            })();
    
            tasks.OutputFieldValueEffect = (function() {
    
                /**
                 * Properties of an OutputFieldValueEffect.
                 * @memberof infinitusai.tasks
                 * @interface IOutputFieldValueEffect
                 * @property {infinitusai.tasks.OutputFieldValueEffectType|null} [effectType] OutputFieldValueEffect effectType
                 * @property {string|null} [outputName] OutputFieldValueEffect outputName
                 * @property {infinitusai.tasks.IOutputValue|null} [expectedValue] OutputFieldValueEffect expectedValue
                 * @property {string|null} [description] OutputFieldValueEffect description
                 * @property {string|null} [fieldPath] OutputFieldValueEffect fieldPath
                 * @property {string|null} [fieldValue] OutputFieldValueEffect fieldValue
                 */
    
                /**
                 * Constructs a new OutputFieldValueEffect.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an OutputFieldValueEffect.
                 * @implements IOutputFieldValueEffect
                 * @constructor
                 * @param {infinitusai.tasks.IOutputFieldValueEffect=} [properties] Properties to set
                 */
                function OutputFieldValueEffect(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OutputFieldValueEffect effectType.
                 * @member {infinitusai.tasks.OutputFieldValueEffectType} effectType
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @instance
                 */
                OutputFieldValueEffect.prototype.effectType = 0;
    
                /**
                 * OutputFieldValueEffect outputName.
                 * @member {string} outputName
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @instance
                 */
                OutputFieldValueEffect.prototype.outputName = "";
    
                /**
                 * OutputFieldValueEffect expectedValue.
                 * @member {infinitusai.tasks.IOutputValue|null|undefined} expectedValue
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @instance
                 */
                OutputFieldValueEffect.prototype.expectedValue = null;
    
                /**
                 * OutputFieldValueEffect description.
                 * @member {string} description
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @instance
                 */
                OutputFieldValueEffect.prototype.description = "";
    
                /**
                 * OutputFieldValueEffect fieldPath.
                 * @member {string} fieldPath
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @instance
                 */
                OutputFieldValueEffect.prototype.fieldPath = "";
    
                /**
                 * OutputFieldValueEffect fieldValue.
                 * @member {string} fieldValue
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @instance
                 */
                OutputFieldValueEffect.prototype.fieldValue = "";
    
                /**
                 * Creates a new OutputFieldValueEffect instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {infinitusai.tasks.IOutputFieldValueEffect=} [properties] Properties to set
                 * @returns {infinitusai.tasks.OutputFieldValueEffect} OutputFieldValueEffect instance
                 */
                OutputFieldValueEffect.create = function create(properties) {
                    return new OutputFieldValueEffect(properties);
                };
    
                /**
                 * Encodes the specified OutputFieldValueEffect message. Does not implicitly {@link infinitusai.tasks.OutputFieldValueEffect.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {infinitusai.tasks.IOutputFieldValueEffect} message OutputFieldValueEffect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputFieldValueEffect.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.effectType != null && Object.hasOwnProperty.call(message, "effectType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.effectType);
                    if (message.outputName != null && Object.hasOwnProperty.call(message, "outputName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.outputName);
                    if (message.expectedValue != null && Object.hasOwnProperty.call(message, "expectedValue"))
                        $root.infinitusai.tasks.OutputValue.encode(message.expectedValue, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                    if (message.fieldPath != null && Object.hasOwnProperty.call(message, "fieldPath"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.fieldPath);
                    if (message.fieldValue != null && Object.hasOwnProperty.call(message, "fieldValue"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.fieldValue);
                    return writer;
                };
    
                /**
                 * Encodes the specified OutputFieldValueEffect message, length delimited. Does not implicitly {@link infinitusai.tasks.OutputFieldValueEffect.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {infinitusai.tasks.IOutputFieldValueEffect} message OutputFieldValueEffect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputFieldValueEffect.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OutputFieldValueEffect message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.OutputFieldValueEffect} OutputFieldValueEffect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputFieldValueEffect.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.OutputFieldValueEffect();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.effectType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.outputName = reader.string();
                                break;
                            }
                        case 3: {
                                message.expectedValue = $root.infinitusai.tasks.OutputValue.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.description = reader.string();
                                break;
                            }
                        case 5: {
                                message.fieldPath = reader.string();
                                break;
                            }
                        case 6: {
                                message.fieldValue = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OutputFieldValueEffect message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.OutputFieldValueEffect} OutputFieldValueEffect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputFieldValueEffect.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OutputFieldValueEffect message.
                 * @function verify
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OutputFieldValueEffect.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.effectType != null && message.hasOwnProperty("effectType"))
                        switch (message.effectType) {
                        default:
                            return "effectType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.outputName != null && message.hasOwnProperty("outputName"))
                        if (!$util.isString(message.outputName))
                            return "outputName: string expected";
                    if (message.expectedValue != null && message.hasOwnProperty("expectedValue")) {
                        var error = $root.infinitusai.tasks.OutputValue.verify(message.expectedValue);
                        if (error)
                            return "expectedValue." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                        if (!$util.isString(message.fieldPath))
                            return "fieldPath: string expected";
                    if (message.fieldValue != null && message.hasOwnProperty("fieldValue"))
                        if (!$util.isString(message.fieldValue))
                            return "fieldValue: string expected";
                    return null;
                };
    
                /**
                 * Creates an OutputFieldValueEffect message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.OutputFieldValueEffect} OutputFieldValueEffect
                 */
                OutputFieldValueEffect.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.OutputFieldValueEffect)
                        return object;
                    var message = new $root.infinitusai.tasks.OutputFieldValueEffect();
                    switch (object.effectType) {
                    default:
                        if (typeof object.effectType === "number") {
                            message.effectType = object.effectType;
                            break;
                        }
                        break;
                    case "PUSH_BACK":
                    case 0:
                        message.effectType = 0;
                        break;
                    case "ESCALATE":
                    case 1:
                        message.effectType = 1;
                        break;
                    case "OVERRIDE":
                    case 2:
                        message.effectType = 2;
                        break;
                    }
                    if (object.outputName != null)
                        message.outputName = String(object.outputName);
                    if (object.expectedValue != null) {
                        if (typeof object.expectedValue !== "object")
                            throw TypeError(".infinitusai.tasks.OutputFieldValueEffect.expectedValue: object expected");
                        message.expectedValue = $root.infinitusai.tasks.OutputValue.fromObject(object.expectedValue);
                    }
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.fieldPath != null)
                        message.fieldPath = String(object.fieldPath);
                    if (object.fieldValue != null)
                        message.fieldValue = String(object.fieldValue);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OutputFieldValueEffect message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {infinitusai.tasks.OutputFieldValueEffect} message OutputFieldValueEffect
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OutputFieldValueEffect.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.effectType = options.enums === String ? "PUSH_BACK" : 0;
                        object.outputName = "";
                        object.expectedValue = null;
                        object.description = "";
                        object.fieldPath = "";
                        object.fieldValue = "";
                    }
                    if (message.effectType != null && message.hasOwnProperty("effectType"))
                        object.effectType = options.enums === String ? $root.infinitusai.tasks.OutputFieldValueEffectType[message.effectType] === undefined ? message.effectType : $root.infinitusai.tasks.OutputFieldValueEffectType[message.effectType] : message.effectType;
                    if (message.outputName != null && message.hasOwnProperty("outputName"))
                        object.outputName = message.outputName;
                    if (message.expectedValue != null && message.hasOwnProperty("expectedValue"))
                        object.expectedValue = $root.infinitusai.tasks.OutputValue.toObject(message.expectedValue, options);
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                        object.fieldPath = message.fieldPath;
                    if (message.fieldValue != null && message.hasOwnProperty("fieldValue"))
                        object.fieldValue = message.fieldValue;
                    return object;
                };
    
                /**
                 * Converts this OutputFieldValueEffect to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OutputFieldValueEffect.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OutputFieldValueEffect
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.OutputFieldValueEffect
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OutputFieldValueEffect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.OutputFieldValueEffect";
                };
    
                return OutputFieldValueEffect;
            })();
    
            /**
             * OutputFieldValueEffectType enum.
             * @name infinitusai.tasks.OutputFieldValueEffectType
             * @enum {number}
             * @property {number} PUSH_BACK=0 PUSH_BACK value
             * @property {number} ESCALATE=1 ESCALATE value
             * @property {number} OVERRIDE=2 OVERRIDE value
             */
            tasks.OutputFieldValueEffectType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PUSH_BACK"] = 0;
                values[valuesById[1] = "ESCALATE"] = 1;
                values[valuesById[2] = "OVERRIDE"] = 2;
                return values;
            })();
    
            tasks.OutputValue = (function() {
    
                /**
                 * Properties of an OutputValue.
                 * @memberof infinitusai.tasks
                 * @interface IOutputValue
                 * @property {string|null} [stringType] OutputValue stringType
                 * @property {number|Long|null} [intType] OutputValue intType
                 * @property {boolean|null} [boolType] OutputValue boolType
                 * @property {number|Long|null} [moneyType] OutputValue moneyType
                 * @property {string|null} [enumType] OutputValue enumType
                 * @property {infinitusai.tasks.IDateType|null} [dateType] OutputValue dateType
                 * @property {boolean|null} [agentCouldNotAnswer] OutputValue agentCouldNotAnswer
                 */
    
                /**
                 * Constructs a new OutputValue.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an OutputValue.
                 * @implements IOutputValue
                 * @constructor
                 * @param {infinitusai.tasks.IOutputValue=} [properties] Properties to set
                 */
                function OutputValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OutputValue stringType.
                 * @member {string|null|undefined} stringType
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 */
                OutputValue.prototype.stringType = null;
    
                /**
                 * OutputValue intType.
                 * @member {number|Long|null|undefined} intType
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 */
                OutputValue.prototype.intType = null;
    
                /**
                 * OutputValue boolType.
                 * @member {boolean|null|undefined} boolType
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 */
                OutputValue.prototype.boolType = null;
    
                /**
                 * OutputValue moneyType.
                 * @member {number|Long|null|undefined} moneyType
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 */
                OutputValue.prototype.moneyType = null;
    
                /**
                 * OutputValue enumType.
                 * @member {string|null|undefined} enumType
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 */
                OutputValue.prototype.enumType = null;
    
                /**
                 * OutputValue dateType.
                 * @member {infinitusai.tasks.IDateType|null|undefined} dateType
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 */
                OutputValue.prototype.dateType = null;
    
                /**
                 * OutputValue agentCouldNotAnswer.
                 * @member {boolean|null|undefined} agentCouldNotAnswer
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 */
                OutputValue.prototype.agentCouldNotAnswer = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * OutputValue oneOf.
                 * @member {"stringType"|"intType"|"boolType"|"moneyType"|"enumType"|"dateType"|"agentCouldNotAnswer"|undefined} oneOf
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 */
                Object.defineProperty(OutputValue.prototype, "oneOf", {
                    get: $util.oneOfGetter($oneOfFields = ["stringType", "intType", "boolType", "moneyType", "enumType", "dateType", "agentCouldNotAnswer"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new OutputValue instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {infinitusai.tasks.IOutputValue=} [properties] Properties to set
                 * @returns {infinitusai.tasks.OutputValue} OutputValue instance
                 */
                OutputValue.create = function create(properties) {
                    return new OutputValue(properties);
                };
    
                /**
                 * Encodes the specified OutputValue message. Does not implicitly {@link infinitusai.tasks.OutputValue.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {infinitusai.tasks.IOutputValue} message OutputValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stringType != null && Object.hasOwnProperty.call(message, "stringType"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stringType);
                    if (message.intType != null && Object.hasOwnProperty.call(message, "intType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intType);
                    if (message.boolType != null && Object.hasOwnProperty.call(message, "boolType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.boolType);
                    if (message.moneyType != null && Object.hasOwnProperty.call(message, "moneyType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.moneyType);
                    if (message.enumType != null && Object.hasOwnProperty.call(message, "enumType"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.enumType);
                    if (message.dateType != null && Object.hasOwnProperty.call(message, "dateType"))
                        $root.infinitusai.tasks.DateType.encode(message.dateType, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.agentCouldNotAnswer != null && Object.hasOwnProperty.call(message, "agentCouldNotAnswer"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.agentCouldNotAnswer);
                    return writer;
                };
    
                /**
                 * Encodes the specified OutputValue message, length delimited. Does not implicitly {@link infinitusai.tasks.OutputValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {infinitusai.tasks.IOutputValue} message OutputValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OutputValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.OutputValue} OutputValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.OutputValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.stringType = reader.string();
                                break;
                            }
                        case 2: {
                                message.intType = reader.int64();
                                break;
                            }
                        case 3: {
                                message.boolType = reader.bool();
                                break;
                            }
                        case 4: {
                                message.moneyType = reader.int64();
                                break;
                            }
                        case 5: {
                                message.enumType = reader.string();
                                break;
                            }
                        case 6: {
                                message.dateType = $root.infinitusai.tasks.DateType.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.agentCouldNotAnswer = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OutputValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.OutputValue} OutputValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OutputValue message.
                 * @function verify
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OutputValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.stringType != null && message.hasOwnProperty("stringType")) {
                        properties.oneOf = 1;
                        if (!$util.isString(message.stringType))
                            return "stringType: string expected";
                    }
                    if (message.intType != null && message.hasOwnProperty("intType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (!$util.isInteger(message.intType) && !(message.intType && $util.isInteger(message.intType.low) && $util.isInteger(message.intType.high)))
                            return "intType: integer|Long expected";
                    }
                    if (message.boolType != null && message.hasOwnProperty("boolType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (typeof message.boolType !== "boolean")
                            return "boolType: boolean expected";
                    }
                    if (message.moneyType != null && message.hasOwnProperty("moneyType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (!$util.isInteger(message.moneyType) && !(message.moneyType && $util.isInteger(message.moneyType.low) && $util.isInteger(message.moneyType.high)))
                            return "moneyType: integer|Long expected";
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (!$util.isString(message.enumType))
                            return "enumType: string expected";
                    }
                    if (message.dateType != null && message.hasOwnProperty("dateType")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        {
                            var error = $root.infinitusai.tasks.DateType.verify(message.dateType);
                            if (error)
                                return "dateType." + error;
                        }
                    }
                    if (message.agentCouldNotAnswer != null && message.hasOwnProperty("agentCouldNotAnswer")) {
                        if (properties.oneOf === 1)
                            return "oneOf: multiple values";
                        properties.oneOf = 1;
                        if (typeof message.agentCouldNotAnswer !== "boolean")
                            return "agentCouldNotAnswer: boolean expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an OutputValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.OutputValue} OutputValue
                 */
                OutputValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.OutputValue)
                        return object;
                    var message = new $root.infinitusai.tasks.OutputValue();
                    if (object.stringType != null)
                        message.stringType = String(object.stringType);
                    if (object.intType != null)
                        if ($util.Long)
                            (message.intType = $util.Long.fromValue(object.intType)).unsigned = false;
                        else if (typeof object.intType === "string")
                            message.intType = parseInt(object.intType, 10);
                        else if (typeof object.intType === "number")
                            message.intType = object.intType;
                        else if (typeof object.intType === "object")
                            message.intType = new $util.LongBits(object.intType.low >>> 0, object.intType.high >>> 0).toNumber();
                    if (object.boolType != null)
                        message.boolType = Boolean(object.boolType);
                    if (object.moneyType != null)
                        if ($util.Long)
                            (message.moneyType = $util.Long.fromValue(object.moneyType)).unsigned = false;
                        else if (typeof object.moneyType === "string")
                            message.moneyType = parseInt(object.moneyType, 10);
                        else if (typeof object.moneyType === "number")
                            message.moneyType = object.moneyType;
                        else if (typeof object.moneyType === "object")
                            message.moneyType = new $util.LongBits(object.moneyType.low >>> 0, object.moneyType.high >>> 0).toNumber();
                    if (object.enumType != null)
                        message.enumType = String(object.enumType);
                    if (object.dateType != null) {
                        if (typeof object.dateType !== "object")
                            throw TypeError(".infinitusai.tasks.OutputValue.dateType: object expected");
                        message.dateType = $root.infinitusai.tasks.DateType.fromObject(object.dateType);
                    }
                    if (object.agentCouldNotAnswer != null)
                        message.agentCouldNotAnswer = Boolean(object.agentCouldNotAnswer);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OutputValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {infinitusai.tasks.OutputValue} message OutputValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OutputValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.stringType != null && message.hasOwnProperty("stringType")) {
                        object.stringType = message.stringType;
                        if (options.oneofs)
                            object.oneOf = "stringType";
                    }
                    if (message.intType != null && message.hasOwnProperty("intType")) {
                        if (typeof message.intType === "number")
                            object.intType = options.longs === String ? String(message.intType) : message.intType;
                        else
                            object.intType = options.longs === String ? $util.Long.prototype.toString.call(message.intType) : options.longs === Number ? new $util.LongBits(message.intType.low >>> 0, message.intType.high >>> 0).toNumber() : message.intType;
                        if (options.oneofs)
                            object.oneOf = "intType";
                    }
                    if (message.boolType != null && message.hasOwnProperty("boolType")) {
                        object.boolType = message.boolType;
                        if (options.oneofs)
                            object.oneOf = "boolType";
                    }
                    if (message.moneyType != null && message.hasOwnProperty("moneyType")) {
                        if (typeof message.moneyType === "number")
                            object.moneyType = options.longs === String ? String(message.moneyType) : message.moneyType;
                        else
                            object.moneyType = options.longs === String ? $util.Long.prototype.toString.call(message.moneyType) : options.longs === Number ? new $util.LongBits(message.moneyType.low >>> 0, message.moneyType.high >>> 0).toNumber() : message.moneyType;
                        if (options.oneofs)
                            object.oneOf = "moneyType";
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        object.enumType = message.enumType;
                        if (options.oneofs)
                            object.oneOf = "enumType";
                    }
                    if (message.dateType != null && message.hasOwnProperty("dateType")) {
                        object.dateType = $root.infinitusai.tasks.DateType.toObject(message.dateType, options);
                        if (options.oneofs)
                            object.oneOf = "dateType";
                    }
                    if (message.agentCouldNotAnswer != null && message.hasOwnProperty("agentCouldNotAnswer")) {
                        object.agentCouldNotAnswer = message.agentCouldNotAnswer;
                        if (options.oneofs)
                            object.oneOf = "agentCouldNotAnswer";
                    }
                    return object;
                };
    
                /**
                 * Converts this OutputValue to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.OutputValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OutputValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OutputValue
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.OutputValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OutputValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.OutputValue";
                };
    
                return OutputValue;
            })();
    
            tasks.DateType = (function() {
    
                /**
                 * Properties of a DateType.
                 * @memberof infinitusai.tasks
                 * @interface IDateType
                 * @property {number|Long|null} [year] DateType year
                 * @property {number|Long|null} [month] DateType month
                 * @property {number|Long|null} [day] DateType day
                 */
    
                /**
                 * Constructs a new DateType.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a DateType.
                 * @implements IDateType
                 * @constructor
                 * @param {infinitusai.tasks.IDateType=} [properties] Properties to set
                 */
                function DateType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DateType year.
                 * @member {number|Long} year
                 * @memberof infinitusai.tasks.DateType
                 * @instance
                 */
                DateType.prototype.year = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * DateType month.
                 * @member {number|Long} month
                 * @memberof infinitusai.tasks.DateType
                 * @instance
                 */
                DateType.prototype.month = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * DateType day.
                 * @member {number|Long} day
                 * @memberof infinitusai.tasks.DateType
                 * @instance
                 */
                DateType.prototype.day = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new DateType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {infinitusai.tasks.IDateType=} [properties] Properties to set
                 * @returns {infinitusai.tasks.DateType} DateType instance
                 */
                DateType.create = function create(properties) {
                    return new DateType(properties);
                };
    
                /**
                 * Encodes the specified DateType message. Does not implicitly {@link infinitusai.tasks.DateType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {infinitusai.tasks.IDateType} message DateType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DateType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.year);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.month);
                    if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.day);
                    return writer;
                };
    
                /**
                 * Encodes the specified DateType message, length delimited. Does not implicitly {@link infinitusai.tasks.DateType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {infinitusai.tasks.IDateType} message DateType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DateType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DateType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.DateType} DateType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DateType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.DateType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.year = reader.int64();
                                break;
                            }
                        case 2: {
                                message.month = reader.int64();
                                break;
                            }
                        case 3: {
                                message.day = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DateType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.DateType} DateType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DateType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DateType message.
                 * @function verify
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DateType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year) && !(message.year && $util.isInteger(message.year.low) && $util.isInteger(message.year.high)))
                            return "year: integer|Long expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isInteger(message.month) && !(message.month && $util.isInteger(message.month.low) && $util.isInteger(message.month.high)))
                            return "month: integer|Long expected";
                    if (message.day != null && message.hasOwnProperty("day"))
                        if (!$util.isInteger(message.day) && !(message.day && $util.isInteger(message.day.low) && $util.isInteger(message.day.high)))
                            return "day: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a DateType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.DateType} DateType
                 */
                DateType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.DateType)
                        return object;
                    var message = new $root.infinitusai.tasks.DateType();
                    if (object.year != null)
                        if ($util.Long)
                            (message.year = $util.Long.fromValue(object.year)).unsigned = false;
                        else if (typeof object.year === "string")
                            message.year = parseInt(object.year, 10);
                        else if (typeof object.year === "number")
                            message.year = object.year;
                        else if (typeof object.year === "object")
                            message.year = new $util.LongBits(object.year.low >>> 0, object.year.high >>> 0).toNumber();
                    if (object.month != null)
                        if ($util.Long)
                            (message.month = $util.Long.fromValue(object.month)).unsigned = false;
                        else if (typeof object.month === "string")
                            message.month = parseInt(object.month, 10);
                        else if (typeof object.month === "number")
                            message.month = object.month;
                        else if (typeof object.month === "object")
                            message.month = new $util.LongBits(object.month.low >>> 0, object.month.high >>> 0).toNumber();
                    if (object.day != null)
                        if ($util.Long)
                            (message.day = $util.Long.fromValue(object.day)).unsigned = false;
                        else if (typeof object.day === "string")
                            message.day = parseInt(object.day, 10);
                        else if (typeof object.day === "number")
                            message.day = object.day;
                        else if (typeof object.day === "object")
                            message.day = new $util.LongBits(object.day.low >>> 0, object.day.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a DateType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {infinitusai.tasks.DateType} message DateType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DateType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.year = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.year = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.month = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.month = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.day = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.day = options.longs === String ? "0" : 0;
                    }
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (typeof message.year === "number")
                            object.year = options.longs === String ? String(message.year) : message.year;
                        else
                            object.year = options.longs === String ? $util.Long.prototype.toString.call(message.year) : options.longs === Number ? new $util.LongBits(message.year.low >>> 0, message.year.high >>> 0).toNumber() : message.year;
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (typeof message.month === "number")
                            object.month = options.longs === String ? String(message.month) : message.month;
                        else
                            object.month = options.longs === String ? $util.Long.prototype.toString.call(message.month) : options.longs === Number ? new $util.LongBits(message.month.low >>> 0, message.month.high >>> 0).toNumber() : message.month;
                    if (message.day != null && message.hasOwnProperty("day"))
                        if (typeof message.day === "number")
                            object.day = options.longs === String ? String(message.day) : message.day;
                        else
                            object.day = options.longs === String ? $util.Long.prototype.toString.call(message.day) : options.longs === Number ? new $util.LongBits(message.day.low >>> 0, message.day.high >>> 0).toNumber() : message.day;
                    return object;
                };
    
                /**
                 * Converts this DateType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.DateType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DateType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DateType
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.DateType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DateType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.DateType";
                };
    
                return DateType;
            })();
    
            tasks.OutputFieldVisibilityEffect = (function() {
    
                /**
                 * Properties of an OutputFieldVisibilityEffect.
                 * @memberof infinitusai.tasks
                 * @interface IOutputFieldVisibilityEffect
                 * @property {infinitusai.tasks.Visibility|null} [visibility] OutputFieldVisibilityEffect visibility
                 * @property {string|null} [outputName] OutputFieldVisibilityEffect outputName
                 */
    
                /**
                 * Constructs a new OutputFieldVisibilityEffect.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an OutputFieldVisibilityEffect.
                 * @implements IOutputFieldVisibilityEffect
                 * @constructor
                 * @param {infinitusai.tasks.IOutputFieldVisibilityEffect=} [properties] Properties to set
                 */
                function OutputFieldVisibilityEffect(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OutputFieldVisibilityEffect visibility.
                 * @member {infinitusai.tasks.Visibility} visibility
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @instance
                 */
                OutputFieldVisibilityEffect.prototype.visibility = 0;
    
                /**
                 * OutputFieldVisibilityEffect outputName.
                 * @member {string} outputName
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @instance
                 */
                OutputFieldVisibilityEffect.prototype.outputName = "";
    
                /**
                 * Creates a new OutputFieldVisibilityEffect instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {infinitusai.tasks.IOutputFieldVisibilityEffect=} [properties] Properties to set
                 * @returns {infinitusai.tasks.OutputFieldVisibilityEffect} OutputFieldVisibilityEffect instance
                 */
                OutputFieldVisibilityEffect.create = function create(properties) {
                    return new OutputFieldVisibilityEffect(properties);
                };
    
                /**
                 * Encodes the specified OutputFieldVisibilityEffect message. Does not implicitly {@link infinitusai.tasks.OutputFieldVisibilityEffect.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {infinitusai.tasks.IOutputFieldVisibilityEffect} message OutputFieldVisibilityEffect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputFieldVisibilityEffect.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.visibility);
                    if (message.outputName != null && Object.hasOwnProperty.call(message, "outputName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.outputName);
                    return writer;
                };
    
                /**
                 * Encodes the specified OutputFieldVisibilityEffect message, length delimited. Does not implicitly {@link infinitusai.tasks.OutputFieldVisibilityEffect.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {infinitusai.tasks.IOutputFieldVisibilityEffect} message OutputFieldVisibilityEffect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OutputFieldVisibilityEffect.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OutputFieldVisibilityEffect message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.OutputFieldVisibilityEffect} OutputFieldVisibilityEffect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputFieldVisibilityEffect.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.OutputFieldVisibilityEffect();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.visibility = reader.int32();
                                break;
                            }
                        case 2: {
                                message.outputName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OutputFieldVisibilityEffect message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.OutputFieldVisibilityEffect} OutputFieldVisibilityEffect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OutputFieldVisibilityEffect.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OutputFieldVisibilityEffect message.
                 * @function verify
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OutputFieldVisibilityEffect.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.visibility != null && message.hasOwnProperty("visibility"))
                        switch (message.visibility) {
                        default:
                            return "visibility: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.outputName != null && message.hasOwnProperty("outputName"))
                        if (!$util.isString(message.outputName))
                            return "outputName: string expected";
                    return null;
                };
    
                /**
                 * Creates an OutputFieldVisibilityEffect message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.OutputFieldVisibilityEffect} OutputFieldVisibilityEffect
                 */
                OutputFieldVisibilityEffect.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.OutputFieldVisibilityEffect)
                        return object;
                    var message = new $root.infinitusai.tasks.OutputFieldVisibilityEffect();
                    switch (object.visibility) {
                    default:
                        if (typeof object.visibility === "number") {
                            message.visibility = object.visibility;
                            break;
                        }
                        break;
                    case "SHOW":
                    case 0:
                        message.visibility = 0;
                        break;
                    case "HIDE":
                    case 1:
                        message.visibility = 1;
                        break;
                    }
                    if (object.outputName != null)
                        message.outputName = String(object.outputName);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OutputFieldVisibilityEffect message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {infinitusai.tasks.OutputFieldVisibilityEffect} message OutputFieldVisibilityEffect
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OutputFieldVisibilityEffect.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.visibility = options.enums === String ? "SHOW" : 0;
                        object.outputName = "";
                    }
                    if (message.visibility != null && message.hasOwnProperty("visibility"))
                        object.visibility = options.enums === String ? $root.infinitusai.tasks.Visibility[message.visibility] === undefined ? message.visibility : $root.infinitusai.tasks.Visibility[message.visibility] : message.visibility;
                    if (message.outputName != null && message.hasOwnProperty("outputName"))
                        object.outputName = message.outputName;
                    return object;
                };
    
                /**
                 * Converts this OutputFieldVisibilityEffect to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OutputFieldVisibilityEffect.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OutputFieldVisibilityEffect
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.OutputFieldVisibilityEffect
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OutputFieldVisibilityEffect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.OutputFieldVisibilityEffect";
                };
    
                return OutputFieldVisibilityEffect;
            })();
    
            /**
             * Visibility enum.
             * @name infinitusai.tasks.Visibility
             * @enum {number}
             * @property {number} SHOW=0 SHOW value
             * @property {number} HIDE=1 HIDE value
             */
            tasks.Visibility = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SHOW"] = 0;
                values[valuesById[1] = "HIDE"] = 1;
                return values;
            })();
    
            tasks.FollowupSuggestionVisibilityEffect = (function() {
    
                /**
                 * Properties of a FollowupSuggestionVisibilityEffect.
                 * @memberof infinitusai.tasks
                 * @interface IFollowupSuggestionVisibilityEffect
                 * @property {infinitusai.tasks.Visibility|null} [visibility] FollowupSuggestionVisibilityEffect visibility
                 * @property {string|null} [suggestionName] FollowupSuggestionVisibilityEffect suggestionName
                 */
    
                /**
                 * Constructs a new FollowupSuggestionVisibilityEffect.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a FollowupSuggestionVisibilityEffect.
                 * @implements IFollowupSuggestionVisibilityEffect
                 * @constructor
                 * @param {infinitusai.tasks.IFollowupSuggestionVisibilityEffect=} [properties] Properties to set
                 */
                function FollowupSuggestionVisibilityEffect(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FollowupSuggestionVisibilityEffect visibility.
                 * @member {infinitusai.tasks.Visibility} visibility
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @instance
                 */
                FollowupSuggestionVisibilityEffect.prototype.visibility = 0;
    
                /**
                 * FollowupSuggestionVisibilityEffect suggestionName.
                 * @member {string} suggestionName
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @instance
                 */
                FollowupSuggestionVisibilityEffect.prototype.suggestionName = "";
    
                /**
                 * Creates a new FollowupSuggestionVisibilityEffect instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {infinitusai.tasks.IFollowupSuggestionVisibilityEffect=} [properties] Properties to set
                 * @returns {infinitusai.tasks.FollowupSuggestionVisibilityEffect} FollowupSuggestionVisibilityEffect instance
                 */
                FollowupSuggestionVisibilityEffect.create = function create(properties) {
                    return new FollowupSuggestionVisibilityEffect(properties);
                };
    
                /**
                 * Encodes the specified FollowupSuggestionVisibilityEffect message. Does not implicitly {@link infinitusai.tasks.FollowupSuggestionVisibilityEffect.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {infinitusai.tasks.IFollowupSuggestionVisibilityEffect} message FollowupSuggestionVisibilityEffect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FollowupSuggestionVisibilityEffect.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.visibility);
                    if (message.suggestionName != null && Object.hasOwnProperty.call(message, "suggestionName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.suggestionName);
                    return writer;
                };
    
                /**
                 * Encodes the specified FollowupSuggestionVisibilityEffect message, length delimited. Does not implicitly {@link infinitusai.tasks.FollowupSuggestionVisibilityEffect.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {infinitusai.tasks.IFollowupSuggestionVisibilityEffect} message FollowupSuggestionVisibilityEffect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FollowupSuggestionVisibilityEffect.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FollowupSuggestionVisibilityEffect message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.FollowupSuggestionVisibilityEffect} FollowupSuggestionVisibilityEffect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FollowupSuggestionVisibilityEffect.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.FollowupSuggestionVisibilityEffect();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.visibility = reader.int32();
                                break;
                            }
                        case 2: {
                                message.suggestionName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FollowupSuggestionVisibilityEffect message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.FollowupSuggestionVisibilityEffect} FollowupSuggestionVisibilityEffect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FollowupSuggestionVisibilityEffect.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FollowupSuggestionVisibilityEffect message.
                 * @function verify
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FollowupSuggestionVisibilityEffect.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.visibility != null && message.hasOwnProperty("visibility"))
                        switch (message.visibility) {
                        default:
                            return "visibility: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.suggestionName != null && message.hasOwnProperty("suggestionName"))
                        if (!$util.isString(message.suggestionName))
                            return "suggestionName: string expected";
                    return null;
                };
    
                /**
                 * Creates a FollowupSuggestionVisibilityEffect message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.FollowupSuggestionVisibilityEffect} FollowupSuggestionVisibilityEffect
                 */
                FollowupSuggestionVisibilityEffect.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.FollowupSuggestionVisibilityEffect)
                        return object;
                    var message = new $root.infinitusai.tasks.FollowupSuggestionVisibilityEffect();
                    switch (object.visibility) {
                    default:
                        if (typeof object.visibility === "number") {
                            message.visibility = object.visibility;
                            break;
                        }
                        break;
                    case "SHOW":
                    case 0:
                        message.visibility = 0;
                        break;
                    case "HIDE":
                    case 1:
                        message.visibility = 1;
                        break;
                    }
                    if (object.suggestionName != null)
                        message.suggestionName = String(object.suggestionName);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FollowupSuggestionVisibilityEffect message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {infinitusai.tasks.FollowupSuggestionVisibilityEffect} message FollowupSuggestionVisibilityEffect
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FollowupSuggestionVisibilityEffect.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.visibility = options.enums === String ? "SHOW" : 0;
                        object.suggestionName = "";
                    }
                    if (message.visibility != null && message.hasOwnProperty("visibility"))
                        object.visibility = options.enums === String ? $root.infinitusai.tasks.Visibility[message.visibility] === undefined ? message.visibility : $root.infinitusai.tasks.Visibility[message.visibility] : message.visibility;
                    if (message.suggestionName != null && message.hasOwnProperty("suggestionName"))
                        object.suggestionName = message.suggestionName;
                    return object;
                };
    
                /**
                 * Converts this FollowupSuggestionVisibilityEffect to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FollowupSuggestionVisibilityEffect.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FollowupSuggestionVisibilityEffect
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.FollowupSuggestionVisibilityEffect
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FollowupSuggestionVisibilityEffect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.FollowupSuggestionVisibilityEffect";
                };
    
                return FollowupSuggestionVisibilityEffect;
            })();
    
            tasks.Rule = (function() {
    
                /**
                 * Properties of a Rule.
                 * @memberof infinitusai.tasks
                 * @interface IRule
                 * @property {string|null} [payerIntelId] Rule payerIntelId
                 * @property {Array.<infinitusai.tasks.IConditions>|null} [conditions] Rule conditions
                 * @property {Array.<infinitusai.tasks.IEffect>|null} [effects] Rule effects
                 * @property {string|null} [category] Rule category
                 * @property {number|Long|null} [weight] Rule weight
                 * @property {string|null} [taskType] Rule taskType
                 */
    
                /**
                 * Constructs a new Rule.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a Rule.
                 * @implements IRule
                 * @constructor
                 * @param {infinitusai.tasks.IRule=} [properties] Properties to set
                 */
                function Rule(properties) {
                    this.conditions = [];
                    this.effects = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Rule payerIntelId.
                 * @member {string} payerIntelId
                 * @memberof infinitusai.tasks.Rule
                 * @instance
                 */
                Rule.prototype.payerIntelId = "";
    
                /**
                 * Rule conditions.
                 * @member {Array.<infinitusai.tasks.IConditions>} conditions
                 * @memberof infinitusai.tasks.Rule
                 * @instance
                 */
                Rule.prototype.conditions = $util.emptyArray;
    
                /**
                 * Rule effects.
                 * @member {Array.<infinitusai.tasks.IEffect>} effects
                 * @memberof infinitusai.tasks.Rule
                 * @instance
                 */
                Rule.prototype.effects = $util.emptyArray;
    
                /**
                 * Rule category.
                 * @member {string} category
                 * @memberof infinitusai.tasks.Rule
                 * @instance
                 */
                Rule.prototype.category = "";
    
                /**
                 * Rule weight.
                 * @member {number|Long} weight
                 * @memberof infinitusai.tasks.Rule
                 * @instance
                 */
                Rule.prototype.weight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Rule taskType.
                 * @member {string} taskType
                 * @memberof infinitusai.tasks.Rule
                 * @instance
                 */
                Rule.prototype.taskType = "";
    
                /**
                 * Creates a new Rule instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {infinitusai.tasks.IRule=} [properties] Properties to set
                 * @returns {infinitusai.tasks.Rule} Rule instance
                 */
                Rule.create = function create(properties) {
                    return new Rule(properties);
                };
    
                /**
                 * Encodes the specified Rule message. Does not implicitly {@link infinitusai.tasks.Rule.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {infinitusai.tasks.IRule} message Rule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Rule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payerIntelId != null && Object.hasOwnProperty.call(message, "payerIntelId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.payerIntelId);
                    if (message.conditions != null && message.conditions.length)
                        for (var i = 0; i < message.conditions.length; ++i)
                            $root.infinitusai.tasks.Conditions.encode(message.conditions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.effects != null && message.effects.length)
                        for (var i = 0; i < message.effects.length; ++i)
                            $root.infinitusai.tasks.Effect.encode(message.effects[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.category);
                    if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.weight);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified Rule message, length delimited. Does not implicitly {@link infinitusai.tasks.Rule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {infinitusai.tasks.IRule} message Rule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Rule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Rule message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.Rule} Rule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Rule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.Rule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.payerIntelId = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.conditions && message.conditions.length))
                                    message.conditions = [];
                                message.conditions.push($root.infinitusai.tasks.Conditions.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.effects && message.effects.length))
                                    message.effects = [];
                                message.effects.push($root.infinitusai.tasks.Effect.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.category = reader.string();
                                break;
                            }
                        case 5: {
                                message.weight = reader.int64();
                                break;
                            }
                        case 6: {
                                message.taskType = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Rule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.Rule} Rule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Rule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Rule message.
                 * @function verify
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Rule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payerIntelId != null && message.hasOwnProperty("payerIntelId"))
                        if (!$util.isString(message.payerIntelId))
                            return "payerIntelId: string expected";
                    if (message.conditions != null && message.hasOwnProperty("conditions")) {
                        if (!Array.isArray(message.conditions))
                            return "conditions: array expected";
                        for (var i = 0; i < message.conditions.length; ++i) {
                            var error = $root.infinitusai.tasks.Conditions.verify(message.conditions[i]);
                            if (error)
                                return "conditions." + error;
                        }
                    }
                    if (message.effects != null && message.hasOwnProperty("effects")) {
                        if (!Array.isArray(message.effects))
                            return "effects: array expected";
                        for (var i = 0; i < message.effects.length; ++i) {
                            var error = $root.infinitusai.tasks.Effect.verify(message.effects[i]);
                            if (error)
                                return "effects." + error;
                        }
                    }
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.weight != null && message.hasOwnProperty("weight"))
                        if (!$util.isInteger(message.weight) && !(message.weight && $util.isInteger(message.weight.low) && $util.isInteger(message.weight.high)))
                            return "weight: integer|Long expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        if (!$util.isString(message.taskType))
                            return "taskType: string expected";
                    return null;
                };
    
                /**
                 * Creates a Rule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.Rule} Rule
                 */
                Rule.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.Rule)
                        return object;
                    var message = new $root.infinitusai.tasks.Rule();
                    if (object.payerIntelId != null)
                        message.payerIntelId = String(object.payerIntelId);
                    if (object.conditions) {
                        if (!Array.isArray(object.conditions))
                            throw TypeError(".infinitusai.tasks.Rule.conditions: array expected");
                        message.conditions = [];
                        for (var i = 0; i < object.conditions.length; ++i) {
                            if (typeof object.conditions[i] !== "object")
                                throw TypeError(".infinitusai.tasks.Rule.conditions: object expected");
                            message.conditions[i] = $root.infinitusai.tasks.Conditions.fromObject(object.conditions[i]);
                        }
                    }
                    if (object.effects) {
                        if (!Array.isArray(object.effects))
                            throw TypeError(".infinitusai.tasks.Rule.effects: array expected");
                        message.effects = [];
                        for (var i = 0; i < object.effects.length; ++i) {
                            if (typeof object.effects[i] !== "object")
                                throw TypeError(".infinitusai.tasks.Rule.effects: object expected");
                            message.effects[i] = $root.infinitusai.tasks.Effect.fromObject(object.effects[i]);
                        }
                    }
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.weight != null)
                        if ($util.Long)
                            (message.weight = $util.Long.fromValue(object.weight)).unsigned = false;
                        else if (typeof object.weight === "string")
                            message.weight = parseInt(object.weight, 10);
                        else if (typeof object.weight === "number")
                            message.weight = object.weight;
                        else if (typeof object.weight === "object")
                            message.weight = new $util.LongBits(object.weight.low >>> 0, object.weight.high >>> 0).toNumber();
                    if (object.taskType != null)
                        message.taskType = String(object.taskType);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Rule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {infinitusai.tasks.Rule} message Rule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Rule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.conditions = [];
                        object.effects = [];
                    }
                    if (options.defaults) {
                        object.payerIntelId = "";
                        object.category = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.weight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.weight = options.longs === String ? "0" : 0;
                        object.taskType = "";
                    }
                    if (message.payerIntelId != null && message.hasOwnProperty("payerIntelId"))
                        object.payerIntelId = message.payerIntelId;
                    if (message.conditions && message.conditions.length) {
                        object.conditions = [];
                        for (var j = 0; j < message.conditions.length; ++j)
                            object.conditions[j] = $root.infinitusai.tasks.Conditions.toObject(message.conditions[j], options);
                    }
                    if (message.effects && message.effects.length) {
                        object.effects = [];
                        for (var j = 0; j < message.effects.length; ++j)
                            object.effects[j] = $root.infinitusai.tasks.Effect.toObject(message.effects[j], options);
                    }
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.weight != null && message.hasOwnProperty("weight"))
                        if (typeof message.weight === "number")
                            object.weight = options.longs === String ? String(message.weight) : message.weight;
                        else
                            object.weight = options.longs === String ? $util.Long.prototype.toString.call(message.weight) : options.longs === Number ? new $util.LongBits(message.weight.low >>> 0, message.weight.high >>> 0).toNumber() : message.weight;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = message.taskType;
                    return object;
                };
    
                /**
                 * Converts this Rule to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.Rule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Rule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Rule
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.Rule
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Rule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.Rule";
                };
    
                return Rule;
            })();
    
            tasks.OrgTaskType = (function() {
    
                /**
                 * Properties of an OrgTaskType.
                 * @memberof infinitusai.tasks
                 * @interface IOrgTaskType
                 * @property {infinitusai.tasks.TaskType|null} [taskType] OrgTaskType taskType
                 * @property {infinitusai.tasks.INFTaskType|null} [INFTaskType] OrgTaskType INFTaskType
                 * @property {boolean|null} [csvTaskImportSupported] OrgTaskType csvTaskImportSupported
                 */
    
                /**
                 * Constructs a new OrgTaskType.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an OrgTaskType.
                 * @implements IOrgTaskType
                 * @constructor
                 * @param {infinitusai.tasks.IOrgTaskType=} [properties] Properties to set
                 */
                function OrgTaskType(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OrgTaskType taskType.
                 * @member {infinitusai.tasks.TaskType} taskType
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @instance
                 */
                OrgTaskType.prototype.taskType = 0;
    
                /**
                 * OrgTaskType INFTaskType.
                 * @member {infinitusai.tasks.INFTaskType} INFTaskType
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @instance
                 */
                OrgTaskType.prototype.INFTaskType = 0;
    
                /**
                 * OrgTaskType csvTaskImportSupported.
                 * @member {boolean} csvTaskImportSupported
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @instance
                 */
                OrgTaskType.prototype.csvTaskImportSupported = false;
    
                /**
                 * Creates a new OrgTaskType instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {infinitusai.tasks.IOrgTaskType=} [properties] Properties to set
                 * @returns {infinitusai.tasks.OrgTaskType} OrgTaskType instance
                 */
                OrgTaskType.create = function create(properties) {
                    return new OrgTaskType(properties);
                };
    
                /**
                 * Encodes the specified OrgTaskType message. Does not implicitly {@link infinitusai.tasks.OrgTaskType.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {infinitusai.tasks.IOrgTaskType} message OrgTaskType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgTaskType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.taskType);
                    if (message.INFTaskType != null && Object.hasOwnProperty.call(message, "INFTaskType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.INFTaskType);
                    if (message.csvTaskImportSupported != null && Object.hasOwnProperty.call(message, "csvTaskImportSupported"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.csvTaskImportSupported);
                    return writer;
                };
    
                /**
                 * Encodes the specified OrgTaskType message, length delimited. Does not implicitly {@link infinitusai.tasks.OrgTaskType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {infinitusai.tasks.IOrgTaskType} message OrgTaskType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrgTaskType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OrgTaskType message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.OrgTaskType} OrgTaskType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgTaskType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.OrgTaskType();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.INFTaskType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.csvTaskImportSupported = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OrgTaskType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.OrgTaskType} OrgTaskType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrgTaskType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OrgTaskType message.
                 * @function verify
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrgTaskType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    if (message.INFTaskType != null && message.hasOwnProperty("INFTaskType"))
                        switch (message.INFTaskType) {
                        default:
                            return "INFTaskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.csvTaskImportSupported != null && message.hasOwnProperty("csvTaskImportSupported"))
                        if (typeof message.csvTaskImportSupported !== "boolean")
                            return "csvTaskImportSupported: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an OrgTaskType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.OrgTaskType} OrgTaskType
                 */
                OrgTaskType.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.OrgTaskType)
                        return object;
                    var message = new $root.infinitusai.tasks.OrgTaskType();
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    switch (object.INFTaskType) {
                    default:
                        if (typeof object.INFTaskType === "number") {
                            message.INFTaskType = object.INFTaskType;
                            break;
                        }
                        break;
                    case "INF_TASK_TYPE_UNKNOWN":
                    case 0:
                        message.INFTaskType = 0;
                        break;
                    case "INF_TASK_TYPE_BENEFITS_VERIFICATION":
                    case 1:
                        message.INFTaskType = 1;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER":
                    case 2:
                        message.INFTaskType = 2;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 3:
                        message.INFTaskType = 3;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 4:
                        message.INFTaskType = 4;
                        break;
                    case "INF_TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 5:
                        message.INFTaskType = 5;
                        break;
                    case "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 6:
                        message.INFTaskType = 6;
                        break;
                    case "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION":
                    case 7:
                        message.INFTaskType = 7;
                        break;
                    case "INF_TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 8:
                        message.INFTaskType = 8;
                        break;
                    case "INF_TASK_TYPE_PATIENT_OUTREACH":
                    case 9:
                        message.INFTaskType = 9;
                        break;
                    case "INF_TASK_TYPE_PROVIDER_OUTREACH":
                    case 10:
                        message.INFTaskType = 10;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_MM":
                    case 11:
                        message.INFTaskType = 11;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_PBM":
                    case 12:
                        message.INFTaskType = 12;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 13:
                        message.INFTaskType = 13;
                        break;
                    case "INF_TASK_TYPE_BASIC_BV":
                    case 14:
                        message.INFTaskType = 14;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 15:
                        message.INFTaskType = 15;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 16:
                        message.INFTaskType = 16;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 17:
                        message.INFTaskType = 17;
                        break;
                    case "INF_TASK_TYPE_PBM_DISCOVERY":
                    case 18:
                        message.INFTaskType = 18;
                        break;
                    }
                    if (object.csvTaskImportSupported != null)
                        message.csvTaskImportSupported = Boolean(object.csvTaskImportSupported);
                    return message;
                };
    
                /**
                 * Creates a plain object from an OrgTaskType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {infinitusai.tasks.OrgTaskType} message OrgTaskType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrgTaskType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                        object.INFTaskType = options.enums === String ? "INF_TASK_TYPE_UNKNOWN" : 0;
                        object.csvTaskImportSupported = false;
                    }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.tasks.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.tasks.TaskType[message.taskType] : message.taskType;
                    if (message.INFTaskType != null && message.hasOwnProperty("INFTaskType"))
                        object.INFTaskType = options.enums === String ? $root.infinitusai.tasks.INFTaskType[message.INFTaskType] === undefined ? message.INFTaskType : $root.infinitusai.tasks.INFTaskType[message.INFTaskType] : message.INFTaskType;
                    if (message.csvTaskImportSupported != null && message.hasOwnProperty("csvTaskImportSupported"))
                        object.csvTaskImportSupported = message.csvTaskImportSupported;
                    return object;
                };
    
                /**
                 * Converts this OrgTaskType to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrgTaskType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OrgTaskType
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.OrgTaskType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrgTaskType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.OrgTaskType";
                };
    
                return OrgTaskType;
            })();
    
            tasks.DownloadCSVByTypeRequest = (function() {
    
                /**
                 * Properties of a DownloadCSVByTypeRequest.
                 * @memberof infinitusai.tasks
                 * @interface IDownloadCSVByTypeRequest
                 * @property {string|null} [csvUuid] DownloadCSVByTypeRequest csvUuid
                 * @property {infinitusai.tasks.CSVType|null} [csvType] DownloadCSVByTypeRequest csvType
                 */
    
                /**
                 * Constructs a new DownloadCSVByTypeRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a DownloadCSVByTypeRequest.
                 * @implements IDownloadCSVByTypeRequest
                 * @constructor
                 * @param {infinitusai.tasks.IDownloadCSVByTypeRequest=} [properties] Properties to set
                 */
                function DownloadCSVByTypeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DownloadCSVByTypeRequest csvUuid.
                 * @member {string} csvUuid
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @instance
                 */
                DownloadCSVByTypeRequest.prototype.csvUuid = "";
    
                /**
                 * DownloadCSVByTypeRequest csvType.
                 * @member {infinitusai.tasks.CSVType} csvType
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @instance
                 */
                DownloadCSVByTypeRequest.prototype.csvType = 0;
    
                /**
                 * Creates a new DownloadCSVByTypeRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadCSVByTypeRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.DownloadCSVByTypeRequest} DownloadCSVByTypeRequest instance
                 */
                DownloadCSVByTypeRequest.create = function create(properties) {
                    return new DownloadCSVByTypeRequest(properties);
                };
    
                /**
                 * Encodes the specified DownloadCSVByTypeRequest message. Does not implicitly {@link infinitusai.tasks.DownloadCSVByTypeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadCSVByTypeRequest} message DownloadCSVByTypeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadCSVByTypeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.csvUuid != null && Object.hasOwnProperty.call(message, "csvUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.csvUuid);
                    if (message.csvType != null && Object.hasOwnProperty.call(message, "csvType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.csvType);
                    return writer;
                };
    
                /**
                 * Encodes the specified DownloadCSVByTypeRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.DownloadCSVByTypeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadCSVByTypeRequest} message DownloadCSVByTypeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadCSVByTypeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DownloadCSVByTypeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.DownloadCSVByTypeRequest} DownloadCSVByTypeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadCSVByTypeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.DownloadCSVByTypeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.csvUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.csvType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DownloadCSVByTypeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.DownloadCSVByTypeRequest} DownloadCSVByTypeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadCSVByTypeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DownloadCSVByTypeRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DownloadCSVByTypeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        if (!$util.isString(message.csvUuid))
                            return "csvUuid: string expected";
                    if (message.csvType != null && message.hasOwnProperty("csvType"))
                        switch (message.csvType) {
                        default:
                            return "csvType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a DownloadCSVByTypeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.DownloadCSVByTypeRequest} DownloadCSVByTypeRequest
                 */
                DownloadCSVByTypeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.DownloadCSVByTypeRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.DownloadCSVByTypeRequest();
                    if (object.csvUuid != null)
                        message.csvUuid = String(object.csvUuid);
                    switch (object.csvType) {
                    default:
                        if (typeof object.csvType === "number") {
                            message.csvType = object.csvType;
                            break;
                        }
                        break;
                    case "CSV_INPUT":
                    case 0:
                        message.csvType = 0;
                        break;
                    case "CSV_ACCEPTED":
                    case 1:
                        message.csvType = 1;
                        break;
                    case "CSV_REJECTED":
                    case 2:
                        message.csvType = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DownloadCSVByTypeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {infinitusai.tasks.DownloadCSVByTypeRequest} message DownloadCSVByTypeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DownloadCSVByTypeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.csvUuid = "";
                        object.csvType = options.enums === String ? "CSV_INPUT" : 0;
                    }
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        object.csvUuid = message.csvUuid;
                    if (message.csvType != null && message.hasOwnProperty("csvType"))
                        object.csvType = options.enums === String ? $root.infinitusai.tasks.CSVType[message.csvType] === undefined ? message.csvType : $root.infinitusai.tasks.CSVType[message.csvType] : message.csvType;
                    return object;
                };
    
                /**
                 * Converts this DownloadCSVByTypeRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DownloadCSVByTypeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DownloadCSVByTypeRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.DownloadCSVByTypeRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DownloadCSVByTypeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.DownloadCSVByTypeRequest";
                };
    
                return DownloadCSVByTypeRequest;
            })();
    
            tasks.DownloadCSVByTypeResponse = (function() {
    
                /**
                 * Properties of a DownloadCSVByTypeResponse.
                 * @memberof infinitusai.tasks
                 * @interface IDownloadCSVByTypeResponse
                 * @property {string|null} [csvObject] DownloadCSVByTypeResponse csvObject
                 */
    
                /**
                 * Constructs a new DownloadCSVByTypeResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a DownloadCSVByTypeResponse.
                 * @implements IDownloadCSVByTypeResponse
                 * @constructor
                 * @param {infinitusai.tasks.IDownloadCSVByTypeResponse=} [properties] Properties to set
                 */
                function DownloadCSVByTypeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DownloadCSVByTypeResponse csvObject.
                 * @member {string} csvObject
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @instance
                 */
                DownloadCSVByTypeResponse.prototype.csvObject = "";
    
                /**
                 * Creates a new DownloadCSVByTypeResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadCSVByTypeResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.DownloadCSVByTypeResponse} DownloadCSVByTypeResponse instance
                 */
                DownloadCSVByTypeResponse.create = function create(properties) {
                    return new DownloadCSVByTypeResponse(properties);
                };
    
                /**
                 * Encodes the specified DownloadCSVByTypeResponse message. Does not implicitly {@link infinitusai.tasks.DownloadCSVByTypeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadCSVByTypeResponse} message DownloadCSVByTypeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadCSVByTypeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.csvObject != null && Object.hasOwnProperty.call(message, "csvObject"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.csvObject);
                    return writer;
                };
    
                /**
                 * Encodes the specified DownloadCSVByTypeResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.DownloadCSVByTypeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadCSVByTypeResponse} message DownloadCSVByTypeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadCSVByTypeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DownloadCSVByTypeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.DownloadCSVByTypeResponse} DownloadCSVByTypeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadCSVByTypeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.DownloadCSVByTypeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.csvObject = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DownloadCSVByTypeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.DownloadCSVByTypeResponse} DownloadCSVByTypeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadCSVByTypeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DownloadCSVByTypeResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DownloadCSVByTypeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.csvObject != null && message.hasOwnProperty("csvObject"))
                        if (!$util.isString(message.csvObject))
                            return "csvObject: string expected";
                    return null;
                };
    
                /**
                 * Creates a DownloadCSVByTypeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.DownloadCSVByTypeResponse} DownloadCSVByTypeResponse
                 */
                DownloadCSVByTypeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.DownloadCSVByTypeResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.DownloadCSVByTypeResponse();
                    if (object.csvObject != null)
                        message.csvObject = String(object.csvObject);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DownloadCSVByTypeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {infinitusai.tasks.DownloadCSVByTypeResponse} message DownloadCSVByTypeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DownloadCSVByTypeResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.csvObject = "";
                    if (message.csvObject != null && message.hasOwnProperty("csvObject"))
                        object.csvObject = message.csvObject;
                    return object;
                };
    
                /**
                 * Converts this DownloadCSVByTypeResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DownloadCSVByTypeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DownloadCSVByTypeResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.DownloadCSVByTypeResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DownloadCSVByTypeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.DownloadCSVByTypeResponse";
                };
    
                return DownloadCSVByTypeResponse;
            })();
    
            tasks.GetCSVUploadHistoryRequest = (function() {
    
                /**
                 * Properties of a GetCSVUploadHistoryRequest.
                 * @memberof infinitusai.tasks
                 * @interface IGetCSVUploadHistoryRequest
                 */
    
                /**
                 * Constructs a new GetCSVUploadHistoryRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetCSVUploadHistoryRequest.
                 * @implements IGetCSVUploadHistoryRequest
                 * @constructor
                 * @param {infinitusai.tasks.IGetCSVUploadHistoryRequest=} [properties] Properties to set
                 */
                function GetCSVUploadHistoryRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetCSVUploadHistoryRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {infinitusai.tasks.IGetCSVUploadHistoryRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetCSVUploadHistoryRequest} GetCSVUploadHistoryRequest instance
                 */
                GetCSVUploadHistoryRequest.create = function create(properties) {
                    return new GetCSVUploadHistoryRequest(properties);
                };
    
                /**
                 * Encodes the specified GetCSVUploadHistoryRequest message. Does not implicitly {@link infinitusai.tasks.GetCSVUploadHistoryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {infinitusai.tasks.IGetCSVUploadHistoryRequest} message GetCSVUploadHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVUploadHistoryRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCSVUploadHistoryRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.GetCSVUploadHistoryRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {infinitusai.tasks.IGetCSVUploadHistoryRequest} message GetCSVUploadHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVUploadHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCSVUploadHistoryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetCSVUploadHistoryRequest} GetCSVUploadHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVUploadHistoryRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetCSVUploadHistoryRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCSVUploadHistoryRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetCSVUploadHistoryRequest} GetCSVUploadHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVUploadHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCSVUploadHistoryRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCSVUploadHistoryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetCSVUploadHistoryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetCSVUploadHistoryRequest} GetCSVUploadHistoryRequest
                 */
                GetCSVUploadHistoryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetCSVUploadHistoryRequest)
                        return object;
                    return new $root.infinitusai.tasks.GetCSVUploadHistoryRequest();
                };
    
                /**
                 * Creates a plain object from a GetCSVUploadHistoryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {infinitusai.tasks.GetCSVUploadHistoryRequest} message GetCSVUploadHistoryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCSVUploadHistoryRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetCSVUploadHistoryRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCSVUploadHistoryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCSVUploadHistoryRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCSVUploadHistoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetCSVUploadHistoryRequest";
                };
    
                return GetCSVUploadHistoryRequest;
            })();
    
            tasks.GetCSVUploadHistoryResponse = (function() {
    
                /**
                 * Properties of a GetCSVUploadHistoryResponse.
                 * @memberof infinitusai.tasks
                 * @interface IGetCSVUploadHistoryResponse
                 * @property {Array.<infinitusai.tasks.IUploadCSVResponse>|null} [uploads] GetCSVUploadHistoryResponse uploads
                 */
    
                /**
                 * Constructs a new GetCSVUploadHistoryResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetCSVUploadHistoryResponse.
                 * @implements IGetCSVUploadHistoryResponse
                 * @constructor
                 * @param {infinitusai.tasks.IGetCSVUploadHistoryResponse=} [properties] Properties to set
                 */
                function GetCSVUploadHistoryResponse(properties) {
                    this.uploads = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetCSVUploadHistoryResponse uploads.
                 * @member {Array.<infinitusai.tasks.IUploadCSVResponse>} uploads
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @instance
                 */
                GetCSVUploadHistoryResponse.prototype.uploads = $util.emptyArray;
    
                /**
                 * Creates a new GetCSVUploadHistoryResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {infinitusai.tasks.IGetCSVUploadHistoryResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetCSVUploadHistoryResponse} GetCSVUploadHistoryResponse instance
                 */
                GetCSVUploadHistoryResponse.create = function create(properties) {
                    return new GetCSVUploadHistoryResponse(properties);
                };
    
                /**
                 * Encodes the specified GetCSVUploadHistoryResponse message. Does not implicitly {@link infinitusai.tasks.GetCSVUploadHistoryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {infinitusai.tasks.IGetCSVUploadHistoryResponse} message GetCSVUploadHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVUploadHistoryResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uploads != null && message.uploads.length)
                        for (var i = 0; i < message.uploads.length; ++i)
                            $root.infinitusai.tasks.UploadCSVResponse.encode(message.uploads[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetCSVUploadHistoryResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.GetCSVUploadHistoryResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {infinitusai.tasks.IGetCSVUploadHistoryResponse} message GetCSVUploadHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCSVUploadHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetCSVUploadHistoryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetCSVUploadHistoryResponse} GetCSVUploadHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVUploadHistoryResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetCSVUploadHistoryResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.uploads && message.uploads.length))
                                    message.uploads = [];
                                message.uploads.push($root.infinitusai.tasks.UploadCSVResponse.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetCSVUploadHistoryResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetCSVUploadHistoryResponse} GetCSVUploadHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCSVUploadHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetCSVUploadHistoryResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCSVUploadHistoryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uploads != null && message.hasOwnProperty("uploads")) {
                        if (!Array.isArray(message.uploads))
                            return "uploads: array expected";
                        for (var i = 0; i < message.uploads.length; ++i) {
                            var error = $root.infinitusai.tasks.UploadCSVResponse.verify(message.uploads[i]);
                            if (error)
                                return "uploads." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetCSVUploadHistoryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetCSVUploadHistoryResponse} GetCSVUploadHistoryResponse
                 */
                GetCSVUploadHistoryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetCSVUploadHistoryResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.GetCSVUploadHistoryResponse();
                    if (object.uploads) {
                        if (!Array.isArray(object.uploads))
                            throw TypeError(".infinitusai.tasks.GetCSVUploadHistoryResponse.uploads: array expected");
                        message.uploads = [];
                        for (var i = 0; i < object.uploads.length; ++i) {
                            if (typeof object.uploads[i] !== "object")
                                throw TypeError(".infinitusai.tasks.GetCSVUploadHistoryResponse.uploads: object expected");
                            message.uploads[i] = $root.infinitusai.tasks.UploadCSVResponse.fromObject(object.uploads[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetCSVUploadHistoryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {infinitusai.tasks.GetCSVUploadHistoryResponse} message GetCSVUploadHistoryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCSVUploadHistoryResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uploads = [];
                    if (message.uploads && message.uploads.length) {
                        object.uploads = [];
                        for (var j = 0; j < message.uploads.length; ++j)
                            object.uploads[j] = $root.infinitusai.tasks.UploadCSVResponse.toObject(message.uploads[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetCSVUploadHistoryResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCSVUploadHistoryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetCSVUploadHistoryResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetCSVUploadHistoryResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetCSVUploadHistoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetCSVUploadHistoryResponse";
                };
    
                return GetCSVUploadHistoryResponse;
            })();
    
            tasks.UploadCSVRequest = (function() {
    
                /**
                 * Properties of an UploadCSVRequest.
                 * @memberof infinitusai.tasks
                 * @interface IUploadCSVRequest
                 * @property {string|null} [infTaskType] UploadCSVRequest infTaskType
                 * @property {string|null} [content] UploadCSVRequest content
                 */
    
                /**
                 * Constructs a new UploadCSVRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an UploadCSVRequest.
                 * @implements IUploadCSVRequest
                 * @constructor
                 * @param {infinitusai.tasks.IUploadCSVRequest=} [properties] Properties to set
                 */
                function UploadCSVRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UploadCSVRequest infTaskType.
                 * @member {string} infTaskType
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @instance
                 */
                UploadCSVRequest.prototype.infTaskType = "";
    
                /**
                 * UploadCSVRequest content.
                 * @member {string} content
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @instance
                 */
                UploadCSVRequest.prototype.content = "";
    
                /**
                 * Creates a new UploadCSVRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.UploadCSVRequest} UploadCSVRequest instance
                 */
                UploadCSVRequest.create = function create(properties) {
                    return new UploadCSVRequest(properties);
                };
    
                /**
                 * Encodes the specified UploadCSVRequest message. Does not implicitly {@link infinitusai.tasks.UploadCSVRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVRequest} message UploadCSVRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCSVRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.infTaskType != null && Object.hasOwnProperty.call(message, "infTaskType"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.infTaskType);
                    if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadCSVRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.UploadCSVRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVRequest} message UploadCSVRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCSVRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadCSVRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.UploadCSVRequest} UploadCSVRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCSVRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.UploadCSVRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.infTaskType = reader.string();
                                break;
                            }
                        case 2: {
                                message.content = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadCSVRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.UploadCSVRequest} UploadCSVRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCSVRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadCSVRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadCSVRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.infTaskType != null && message.hasOwnProperty("infTaskType"))
                        if (!$util.isString(message.infTaskType))
                            return "infTaskType: string expected";
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    return null;
                };
    
                /**
                 * Creates an UploadCSVRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.UploadCSVRequest} UploadCSVRequest
                 */
                UploadCSVRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.UploadCSVRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.UploadCSVRequest();
                    if (object.infTaskType != null)
                        message.infTaskType = String(object.infTaskType);
                    if (object.content != null)
                        message.content = String(object.content);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UploadCSVRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {infinitusai.tasks.UploadCSVRequest} message UploadCSVRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadCSVRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.infTaskType = "";
                        object.content = "";
                    }
                    if (message.infTaskType != null && message.hasOwnProperty("infTaskType"))
                        object.infTaskType = message.infTaskType;
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    return object;
                };
    
                /**
                 * Converts this UploadCSVRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadCSVRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadCSVRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.UploadCSVRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadCSVRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.UploadCSVRequest";
                };
    
                return UploadCSVRequest;
            })();
    
            tasks.UploadCSVError = (function() {
    
                /**
                 * Properties of an UploadCSVError.
                 * @memberof infinitusai.tasks
                 * @interface IUploadCSVError
                 * @property {string|null} [customerTaskId] UploadCSVError customerTaskId
                 * @property {string|null} [errorMessage] UploadCSVError errorMessage
                 */
    
                /**
                 * Constructs a new UploadCSVError.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an UploadCSVError.
                 * @implements IUploadCSVError
                 * @constructor
                 * @param {infinitusai.tasks.IUploadCSVError=} [properties] Properties to set
                 */
                function UploadCSVError(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UploadCSVError customerTaskId.
                 * @member {string} customerTaskId
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @instance
                 */
                UploadCSVError.prototype.customerTaskId = "";
    
                /**
                 * UploadCSVError errorMessage.
                 * @member {string} errorMessage
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @instance
                 */
                UploadCSVError.prototype.errorMessage = "";
    
                /**
                 * Creates a new UploadCSVError instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVError=} [properties] Properties to set
                 * @returns {infinitusai.tasks.UploadCSVError} UploadCSVError instance
                 */
                UploadCSVError.create = function create(properties) {
                    return new UploadCSVError(properties);
                };
    
                /**
                 * Encodes the specified UploadCSVError message. Does not implicitly {@link infinitusai.tasks.UploadCSVError.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVError} message UploadCSVError message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCSVError.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.customerTaskId != null && Object.hasOwnProperty.call(message, "customerTaskId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerTaskId);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadCSVError message, length delimited. Does not implicitly {@link infinitusai.tasks.UploadCSVError.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVError} message UploadCSVError message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCSVError.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadCSVError message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.UploadCSVError} UploadCSVError
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCSVError.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.UploadCSVError();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.customerTaskId = reader.string();
                                break;
                            }
                        case 2: {
                                message.errorMessage = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadCSVError message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.UploadCSVError} UploadCSVError
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCSVError.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadCSVError message.
                 * @function verify
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadCSVError.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.customerTaskId != null && message.hasOwnProperty("customerTaskId"))
                        if (!$util.isString(message.customerTaskId))
                            return "customerTaskId: string expected";
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    return null;
                };
    
                /**
                 * Creates an UploadCSVError message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.UploadCSVError} UploadCSVError
                 */
                UploadCSVError.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.UploadCSVError)
                        return object;
                    var message = new $root.infinitusai.tasks.UploadCSVError();
                    if (object.customerTaskId != null)
                        message.customerTaskId = String(object.customerTaskId);
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UploadCSVError message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {infinitusai.tasks.UploadCSVError} message UploadCSVError
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadCSVError.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.customerTaskId = "";
                        object.errorMessage = "";
                    }
                    if (message.customerTaskId != null && message.hasOwnProperty("customerTaskId"))
                        object.customerTaskId = message.customerTaskId;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    return object;
                };
    
                /**
                 * Converts this UploadCSVError to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadCSVError.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadCSVError
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.UploadCSVError
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadCSVError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.UploadCSVError";
                };
    
                return UploadCSVError;
            })();
    
            tasks.UploadCSVResponse = (function() {
    
                /**
                 * Properties of an UploadCSVResponse.
                 * @memberof infinitusai.tasks
                 * @interface IUploadCSVResponse
                 * @property {string|null} [userEmail] UploadCSVResponse userEmail
                 * @property {string|null} [infTaskType] UploadCSVResponse infTaskType
                 * @property {string|null} [uploadId] UploadCSVResponse uploadId
                 * @property {google.protobuf.ITimestamp|null} [uploadTimestamp] UploadCSVResponse uploadTimestamp
                 * @property {number|Long|null} [numAccepted] UploadCSVResponse numAccepted
                 * @property {number|Long|null} [numRejected] UploadCSVResponse numRejected
                 * @property {string|null} [gcsPath] UploadCSVResponse gcsPath
                 * @property {Array.<infinitusai.tasks.IUploadCSVError>|null} [errors] UploadCSVResponse errors
                 */
    
                /**
                 * Constructs a new UploadCSVResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an UploadCSVResponse.
                 * @implements IUploadCSVResponse
                 * @constructor
                 * @param {infinitusai.tasks.IUploadCSVResponse=} [properties] Properties to set
                 */
                function UploadCSVResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UploadCSVResponse userEmail.
                 * @member {string} userEmail
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 */
                UploadCSVResponse.prototype.userEmail = "";
    
                /**
                 * UploadCSVResponse infTaskType.
                 * @member {string} infTaskType
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 */
                UploadCSVResponse.prototype.infTaskType = "";
    
                /**
                 * UploadCSVResponse uploadId.
                 * @member {string} uploadId
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 */
                UploadCSVResponse.prototype.uploadId = "";
    
                /**
                 * UploadCSVResponse uploadTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} uploadTimestamp
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 */
                UploadCSVResponse.prototype.uploadTimestamp = null;
    
                /**
                 * UploadCSVResponse numAccepted.
                 * @member {number|Long} numAccepted
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 */
                UploadCSVResponse.prototype.numAccepted = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UploadCSVResponse numRejected.
                 * @member {number|Long} numRejected
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 */
                UploadCSVResponse.prototype.numRejected = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UploadCSVResponse gcsPath.
                 * @member {string} gcsPath
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 */
                UploadCSVResponse.prototype.gcsPath = "";
    
                /**
                 * UploadCSVResponse errors.
                 * @member {Array.<infinitusai.tasks.IUploadCSVError>} errors
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 */
                UploadCSVResponse.prototype.errors = $util.emptyArray;
    
                /**
                 * Creates a new UploadCSVResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.UploadCSVResponse} UploadCSVResponse instance
                 */
                UploadCSVResponse.create = function create(properties) {
                    return new UploadCSVResponse(properties);
                };
    
                /**
                 * Encodes the specified UploadCSVResponse message. Does not implicitly {@link infinitusai.tasks.UploadCSVResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVResponse} message UploadCSVResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCSVResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userEmail);
                    if (message.infTaskType != null && Object.hasOwnProperty.call(message, "infTaskType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.infTaskType);
                    if (message.uploadId != null && Object.hasOwnProperty.call(message, "uploadId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.uploadId);
                    if (message.uploadTimestamp != null && Object.hasOwnProperty.call(message, "uploadTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.uploadTimestamp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.numAccepted != null && Object.hasOwnProperty.call(message, "numAccepted"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.numAccepted);
                    if (message.numRejected != null && Object.hasOwnProperty.call(message, "numRejected"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.numRejected);
                    if (message.gcsPath != null && Object.hasOwnProperty.call(message, "gcsPath"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.gcsPath);
                    if (message.errors != null && message.errors.length)
                        for (var i = 0; i < message.errors.length; ++i)
                            $root.infinitusai.tasks.UploadCSVError.encode(message.errors[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified UploadCSVResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.UploadCSVResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {infinitusai.tasks.IUploadCSVResponse} message UploadCSVResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UploadCSVResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UploadCSVResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.UploadCSVResponse} UploadCSVResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCSVResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.UploadCSVResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 2: {
                                message.infTaskType = reader.string();
                                break;
                            }
                        case 3: {
                                message.uploadId = reader.string();
                                break;
                            }
                        case 4: {
                                message.uploadTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.numAccepted = reader.int64();
                                break;
                            }
                        case 6: {
                                message.numRejected = reader.int64();
                                break;
                            }
                        case 7: {
                                message.gcsPath = reader.string();
                                break;
                            }
                        case 8: {
                                if (!(message.errors && message.errors.length))
                                    message.errors = [];
                                message.errors.push($root.infinitusai.tasks.UploadCSVError.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UploadCSVResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.UploadCSVResponse} UploadCSVResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UploadCSVResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UploadCSVResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UploadCSVResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.infTaskType != null && message.hasOwnProperty("infTaskType"))
                        if (!$util.isString(message.infTaskType))
                            return "infTaskType: string expected";
                    if (message.uploadId != null && message.hasOwnProperty("uploadId"))
                        if (!$util.isString(message.uploadId))
                            return "uploadId: string expected";
                    if (message.uploadTimestamp != null && message.hasOwnProperty("uploadTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.uploadTimestamp);
                        if (error)
                            return "uploadTimestamp." + error;
                    }
                    if (message.numAccepted != null && message.hasOwnProperty("numAccepted"))
                        if (!$util.isInteger(message.numAccepted) && !(message.numAccepted && $util.isInteger(message.numAccepted.low) && $util.isInteger(message.numAccepted.high)))
                            return "numAccepted: integer|Long expected";
                    if (message.numRejected != null && message.hasOwnProperty("numRejected"))
                        if (!$util.isInteger(message.numRejected) && !(message.numRejected && $util.isInteger(message.numRejected.low) && $util.isInteger(message.numRejected.high)))
                            return "numRejected: integer|Long expected";
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        if (!$util.isString(message.gcsPath))
                            return "gcsPath: string expected";
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (var i = 0; i < message.errors.length; ++i) {
                            var error = $root.infinitusai.tasks.UploadCSVError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an UploadCSVResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.UploadCSVResponse} UploadCSVResponse
                 */
                UploadCSVResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.UploadCSVResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.UploadCSVResponse();
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    if (object.infTaskType != null)
                        message.infTaskType = String(object.infTaskType);
                    if (object.uploadId != null)
                        message.uploadId = String(object.uploadId);
                    if (object.uploadTimestamp != null) {
                        if (typeof object.uploadTimestamp !== "object")
                            throw TypeError(".infinitusai.tasks.UploadCSVResponse.uploadTimestamp: object expected");
                        message.uploadTimestamp = $root.google.protobuf.Timestamp.fromObject(object.uploadTimestamp);
                    }
                    if (object.numAccepted != null)
                        if ($util.Long)
                            (message.numAccepted = $util.Long.fromValue(object.numAccepted)).unsigned = false;
                        else if (typeof object.numAccepted === "string")
                            message.numAccepted = parseInt(object.numAccepted, 10);
                        else if (typeof object.numAccepted === "number")
                            message.numAccepted = object.numAccepted;
                        else if (typeof object.numAccepted === "object")
                            message.numAccepted = new $util.LongBits(object.numAccepted.low >>> 0, object.numAccepted.high >>> 0).toNumber();
                    if (object.numRejected != null)
                        if ($util.Long)
                            (message.numRejected = $util.Long.fromValue(object.numRejected)).unsigned = false;
                        else if (typeof object.numRejected === "string")
                            message.numRejected = parseInt(object.numRejected, 10);
                        else if (typeof object.numRejected === "number")
                            message.numRejected = object.numRejected;
                        else if (typeof object.numRejected === "object")
                            message.numRejected = new $util.LongBits(object.numRejected.low >>> 0, object.numRejected.high >>> 0).toNumber();
                    if (object.gcsPath != null)
                        message.gcsPath = String(object.gcsPath);
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".infinitusai.tasks.UploadCSVResponse.errors: array expected");
                        message.errors = [];
                        for (var i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".infinitusai.tasks.UploadCSVResponse.errors: object expected");
                            message.errors[i] = $root.infinitusai.tasks.UploadCSVError.fromObject(object.errors[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an UploadCSVResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {infinitusai.tasks.UploadCSVResponse} message UploadCSVResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UploadCSVResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.userEmail = "";
                        object.infTaskType = "";
                        object.uploadId = "";
                        object.uploadTimestamp = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.numAccepted = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.numAccepted = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.numRejected = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.numRejected = options.longs === String ? "0" : 0;
                        object.gcsPath = "";
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.infTaskType != null && message.hasOwnProperty("infTaskType"))
                        object.infTaskType = message.infTaskType;
                    if (message.uploadId != null && message.hasOwnProperty("uploadId"))
                        object.uploadId = message.uploadId;
                    if (message.uploadTimestamp != null && message.hasOwnProperty("uploadTimestamp"))
                        object.uploadTimestamp = $root.google.protobuf.Timestamp.toObject(message.uploadTimestamp, options);
                    if (message.numAccepted != null && message.hasOwnProperty("numAccepted"))
                        if (typeof message.numAccepted === "number")
                            object.numAccepted = options.longs === String ? String(message.numAccepted) : message.numAccepted;
                        else
                            object.numAccepted = options.longs === String ? $util.Long.prototype.toString.call(message.numAccepted) : options.longs === Number ? new $util.LongBits(message.numAccepted.low >>> 0, message.numAccepted.high >>> 0).toNumber() : message.numAccepted;
                    if (message.numRejected != null && message.hasOwnProperty("numRejected"))
                        if (typeof message.numRejected === "number")
                            object.numRejected = options.longs === String ? String(message.numRejected) : message.numRejected;
                        else
                            object.numRejected = options.longs === String ? $util.Long.prototype.toString.call(message.numRejected) : options.longs === Number ? new $util.LongBits(message.numRejected.low >>> 0, message.numRejected.high >>> 0).toNumber() : message.numRejected;
                    if (message.gcsPath != null && message.hasOwnProperty("gcsPath"))
                        object.gcsPath = message.gcsPath;
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (var j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.infinitusai.tasks.UploadCSVError.toObject(message.errors[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this UploadCSVResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UploadCSVResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UploadCSVResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.UploadCSVResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UploadCSVResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.UploadCSVResponse";
                };
    
                return UploadCSVResponse;
            })();
    
            tasks.GetReportsRequest = (function() {
    
                /**
                 * Properties of a GetReportsRequest.
                 * @memberof infinitusai.tasks
                 * @interface IGetReportsRequest
                 */
    
                /**
                 * Constructs a new GetReportsRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetReportsRequest.
                 * @implements IGetReportsRequest
                 * @constructor
                 * @param {infinitusai.tasks.IGetReportsRequest=} [properties] Properties to set
                 */
                function GetReportsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetReportsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {infinitusai.tasks.IGetReportsRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetReportsRequest} GetReportsRequest instance
                 */
                GetReportsRequest.create = function create(properties) {
                    return new GetReportsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetReportsRequest message. Does not implicitly {@link infinitusai.tasks.GetReportsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {infinitusai.tasks.IGetReportsRequest} message GetReportsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReportsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetReportsRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.GetReportsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {infinitusai.tasks.IGetReportsRequest} message GetReportsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReportsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetReportsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetReportsRequest} GetReportsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReportsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetReportsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetReportsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetReportsRequest} GetReportsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReportsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetReportsRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetReportsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetReportsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetReportsRequest} GetReportsRequest
                 */
                GetReportsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetReportsRequest)
                        return object;
                    return new $root.infinitusai.tasks.GetReportsRequest();
                };
    
                /**
                 * Creates a plain object from a GetReportsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {infinitusai.tasks.GetReportsRequest} message GetReportsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetReportsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetReportsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetReportsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetReportsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetReportsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetReportsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetReportsRequest";
                };
    
                return GetReportsRequest;
            })();
    
            tasks.GetReportsResponse = (function() {
    
                /**
                 * Properties of a GetReportsResponse.
                 * @memberof infinitusai.tasks
                 * @interface IGetReportsResponse
                 * @property {Array.<infinitusai.tasks.IGeneratedCSVDoc>|null} [reports] GetReportsResponse reports
                 */
    
                /**
                 * Constructs a new GetReportsResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetReportsResponse.
                 * @implements IGetReportsResponse
                 * @constructor
                 * @param {infinitusai.tasks.IGetReportsResponse=} [properties] Properties to set
                 */
                function GetReportsResponse(properties) {
                    this.reports = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetReportsResponse reports.
                 * @member {Array.<infinitusai.tasks.IGeneratedCSVDoc>} reports
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @instance
                 */
                GetReportsResponse.prototype.reports = $util.emptyArray;
    
                /**
                 * Creates a new GetReportsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {infinitusai.tasks.IGetReportsResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetReportsResponse} GetReportsResponse instance
                 */
                GetReportsResponse.create = function create(properties) {
                    return new GetReportsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetReportsResponse message. Does not implicitly {@link infinitusai.tasks.GetReportsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {infinitusai.tasks.IGetReportsResponse} message GetReportsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReportsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reports != null && message.reports.length)
                        for (var i = 0; i < message.reports.length; ++i)
                            $root.infinitusai.tasks.GeneratedCSVDoc.encode(message.reports[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetReportsResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.GetReportsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {infinitusai.tasks.IGetReportsResponse} message GetReportsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReportsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetReportsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetReportsResponse} GetReportsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReportsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetReportsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.reports && message.reports.length))
                                    message.reports = [];
                                message.reports.push($root.infinitusai.tasks.GeneratedCSVDoc.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetReportsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetReportsResponse} GetReportsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReportsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetReportsResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetReportsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reports != null && message.hasOwnProperty("reports")) {
                        if (!Array.isArray(message.reports))
                            return "reports: array expected";
                        for (var i = 0; i < message.reports.length; ++i) {
                            var error = $root.infinitusai.tasks.GeneratedCSVDoc.verify(message.reports[i]);
                            if (error)
                                return "reports." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetReportsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetReportsResponse} GetReportsResponse
                 */
                GetReportsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetReportsResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.GetReportsResponse();
                    if (object.reports) {
                        if (!Array.isArray(object.reports))
                            throw TypeError(".infinitusai.tasks.GetReportsResponse.reports: array expected");
                        message.reports = [];
                        for (var i = 0; i < object.reports.length; ++i) {
                            if (typeof object.reports[i] !== "object")
                                throw TypeError(".infinitusai.tasks.GetReportsResponse.reports: object expected");
                            message.reports[i] = $root.infinitusai.tasks.GeneratedCSVDoc.fromObject(object.reports[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetReportsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {infinitusai.tasks.GetReportsResponse} message GetReportsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetReportsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.reports = [];
                    if (message.reports && message.reports.length) {
                        object.reports = [];
                        for (var j = 0; j < message.reports.length; ++j)
                            object.reports[j] = $root.infinitusai.tasks.GeneratedCSVDoc.toObject(message.reports[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetReportsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetReportsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetReportsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetReportsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetReportsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetReportsResponse";
                };
    
                return GetReportsResponse;
            })();
    
            tasks.GenerateReportRequest = (function() {
    
                /**
                 * Properties of a GenerateReportRequest.
                 * @memberof infinitusai.tasks
                 * @interface IGenerateReportRequest
                 * @property {boolean|null} [publish] GenerateReportRequest publish
                 * @property {infinitusai.tasks.IGenerateCSVParameters|null} [query] GenerateReportRequest query
                 * @property {boolean|null} [isHumanReadable] GenerateReportRequest isHumanReadable
                 * @property {boolean|null} [excludeUnusedApiOutputs] GenerateReportRequest excludeUnusedApiOutputs
                 */
    
                /**
                 * Constructs a new GenerateReportRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GenerateReportRequest.
                 * @implements IGenerateReportRequest
                 * @constructor
                 * @param {infinitusai.tasks.IGenerateReportRequest=} [properties] Properties to set
                 */
                function GenerateReportRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateReportRequest publish.
                 * @member {boolean} publish
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @instance
                 */
                GenerateReportRequest.prototype.publish = false;
    
                /**
                 * GenerateReportRequest query.
                 * @member {infinitusai.tasks.IGenerateCSVParameters|null|undefined} query
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @instance
                 */
                GenerateReportRequest.prototype.query = null;
    
                /**
                 * GenerateReportRequest isHumanReadable.
                 * @member {boolean} isHumanReadable
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @instance
                 */
                GenerateReportRequest.prototype.isHumanReadable = false;
    
                /**
                 * GenerateReportRequest excludeUnusedApiOutputs.
                 * @member {boolean} excludeUnusedApiOutputs
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @instance
                 */
                GenerateReportRequest.prototype.excludeUnusedApiOutputs = false;
    
                /**
                 * Creates a new GenerateReportRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {infinitusai.tasks.IGenerateReportRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GenerateReportRequest} GenerateReportRequest instance
                 */
                GenerateReportRequest.create = function create(properties) {
                    return new GenerateReportRequest(properties);
                };
    
                /**
                 * Encodes the specified GenerateReportRequest message. Does not implicitly {@link infinitusai.tasks.GenerateReportRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {infinitusai.tasks.IGenerateReportRequest} message GenerateReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateReportRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.publish != null && Object.hasOwnProperty.call(message, "publish"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.publish);
                    if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                        $root.infinitusai.tasks.GenerateCSVParameters.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.isHumanReadable != null && Object.hasOwnProperty.call(message, "isHumanReadable"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isHumanReadable);
                    if (message.excludeUnusedApiOutputs != null && Object.hasOwnProperty.call(message, "excludeUnusedApiOutputs"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.excludeUnusedApiOutputs);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateReportRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.GenerateReportRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {infinitusai.tasks.IGenerateReportRequest} message GenerateReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateReportRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GenerateReportRequest} GenerateReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateReportRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GenerateReportRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.publish = reader.bool();
                                break;
                            }
                        case 2: {
                                message.query = $root.infinitusai.tasks.GenerateCSVParameters.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.isHumanReadable = reader.bool();
                                break;
                            }
                        case 4: {
                                message.excludeUnusedApiOutputs = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateReportRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GenerateReportRequest} GenerateReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateReportRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateReportRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateReportRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.publish != null && message.hasOwnProperty("publish"))
                        if (typeof message.publish !== "boolean")
                            return "publish: boolean expected";
                    if (message.query != null && message.hasOwnProperty("query")) {
                        var error = $root.infinitusai.tasks.GenerateCSVParameters.verify(message.query);
                        if (error)
                            return "query." + error;
                    }
                    if (message.isHumanReadable != null && message.hasOwnProperty("isHumanReadable"))
                        if (typeof message.isHumanReadable !== "boolean")
                            return "isHumanReadable: boolean expected";
                    if (message.excludeUnusedApiOutputs != null && message.hasOwnProperty("excludeUnusedApiOutputs"))
                        if (typeof message.excludeUnusedApiOutputs !== "boolean")
                            return "excludeUnusedApiOutputs: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateReportRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GenerateReportRequest} GenerateReportRequest
                 */
                GenerateReportRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GenerateReportRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.GenerateReportRequest();
                    if (object.publish != null)
                        message.publish = Boolean(object.publish);
                    if (object.query != null) {
                        if (typeof object.query !== "object")
                            throw TypeError(".infinitusai.tasks.GenerateReportRequest.query: object expected");
                        message.query = $root.infinitusai.tasks.GenerateCSVParameters.fromObject(object.query);
                    }
                    if (object.isHumanReadable != null)
                        message.isHumanReadable = Boolean(object.isHumanReadable);
                    if (object.excludeUnusedApiOutputs != null)
                        message.excludeUnusedApiOutputs = Boolean(object.excludeUnusedApiOutputs);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateReportRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {infinitusai.tasks.GenerateReportRequest} message GenerateReportRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateReportRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.publish = false;
                        object.query = null;
                        object.isHumanReadable = false;
                        object.excludeUnusedApiOutputs = false;
                    }
                    if (message.publish != null && message.hasOwnProperty("publish"))
                        object.publish = message.publish;
                    if (message.query != null && message.hasOwnProperty("query"))
                        object.query = $root.infinitusai.tasks.GenerateCSVParameters.toObject(message.query, options);
                    if (message.isHumanReadable != null && message.hasOwnProperty("isHumanReadable"))
                        object.isHumanReadable = message.isHumanReadable;
                    if (message.excludeUnusedApiOutputs != null && message.hasOwnProperty("excludeUnusedApiOutputs"))
                        object.excludeUnusedApiOutputs = message.excludeUnusedApiOutputs;
                    return object;
                };
    
                /**
                 * Converts this GenerateReportRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateReportRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateReportRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GenerateReportRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GenerateReportRequest";
                };
    
                return GenerateReportRequest;
            })();
    
            tasks.GenerateReportResponse = (function() {
    
                /**
                 * Properties of a GenerateReportResponse.
                 * @memberof infinitusai.tasks
                 * @interface IGenerateReportResponse
                 * @property {string|null} [csvUrl] GenerateReportResponse csvUrl
                 */
    
                /**
                 * Constructs a new GenerateReportResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GenerateReportResponse.
                 * @implements IGenerateReportResponse
                 * @constructor
                 * @param {infinitusai.tasks.IGenerateReportResponse=} [properties] Properties to set
                 */
                function GenerateReportResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GenerateReportResponse csvUrl.
                 * @member {string} csvUrl
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @instance
                 */
                GenerateReportResponse.prototype.csvUrl = "";
    
                /**
                 * Creates a new GenerateReportResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {infinitusai.tasks.IGenerateReportResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GenerateReportResponse} GenerateReportResponse instance
                 */
                GenerateReportResponse.create = function create(properties) {
                    return new GenerateReportResponse(properties);
                };
    
                /**
                 * Encodes the specified GenerateReportResponse message. Does not implicitly {@link infinitusai.tasks.GenerateReportResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {infinitusai.tasks.IGenerateReportResponse} message GenerateReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateReportResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.csvUrl != null && Object.hasOwnProperty.call(message, "csvUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.csvUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified GenerateReportResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.GenerateReportResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {infinitusai.tasks.IGenerateReportResponse} message GenerateReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GenerateReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GenerateReportResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GenerateReportResponse} GenerateReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateReportResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GenerateReportResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.csvUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GenerateReportResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GenerateReportResponse} GenerateReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GenerateReportResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GenerateReportResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateReportResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.csvUrl != null && message.hasOwnProperty("csvUrl"))
                        if (!$util.isString(message.csvUrl))
                            return "csvUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates a GenerateReportResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GenerateReportResponse} GenerateReportResponse
                 */
                GenerateReportResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GenerateReportResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.GenerateReportResponse();
                    if (object.csvUrl != null)
                        message.csvUrl = String(object.csvUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GenerateReportResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {infinitusai.tasks.GenerateReportResponse} message GenerateReportResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateReportResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.csvUrl = "";
                    if (message.csvUrl != null && message.hasOwnProperty("csvUrl"))
                        object.csvUrl = message.csvUrl;
                    return object;
                };
    
                /**
                 * Converts this GenerateReportResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateReportResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GenerateReportResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GenerateReportResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GenerateReportResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GenerateReportResponse";
                };
    
                return GenerateReportResponse;
            })();
    
            tasks.DownloadReportRequest = (function() {
    
                /**
                 * Properties of a DownloadReportRequest.
                 * @memberof infinitusai.tasks
                 * @interface IDownloadReportRequest
                 * @property {string|null} [csvUuid] DownloadReportRequest csvUuid
                 */
    
                /**
                 * Constructs a new DownloadReportRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a DownloadReportRequest.
                 * @implements IDownloadReportRequest
                 * @constructor
                 * @param {infinitusai.tasks.IDownloadReportRequest=} [properties] Properties to set
                 */
                function DownloadReportRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DownloadReportRequest csvUuid.
                 * @member {string} csvUuid
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @instance
                 */
                DownloadReportRequest.prototype.csvUuid = "";
    
                /**
                 * Creates a new DownloadReportRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadReportRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.DownloadReportRequest} DownloadReportRequest instance
                 */
                DownloadReportRequest.create = function create(properties) {
                    return new DownloadReportRequest(properties);
                };
    
                /**
                 * Encodes the specified DownloadReportRequest message. Does not implicitly {@link infinitusai.tasks.DownloadReportRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadReportRequest} message DownloadReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadReportRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.csvUuid != null && Object.hasOwnProperty.call(message, "csvUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.csvUuid);
                    return writer;
                };
    
                /**
                 * Encodes the specified DownloadReportRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.DownloadReportRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadReportRequest} message DownloadReportRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DownloadReportRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.DownloadReportRequest} DownloadReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadReportRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.DownloadReportRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.csvUuid = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DownloadReportRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.DownloadReportRequest} DownloadReportRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadReportRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DownloadReportRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DownloadReportRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        if (!$util.isString(message.csvUuid))
                            return "csvUuid: string expected";
                    return null;
                };
    
                /**
                 * Creates a DownloadReportRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.DownloadReportRequest} DownloadReportRequest
                 */
                DownloadReportRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.DownloadReportRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.DownloadReportRequest();
                    if (object.csvUuid != null)
                        message.csvUuid = String(object.csvUuid);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DownloadReportRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {infinitusai.tasks.DownloadReportRequest} message DownloadReportRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DownloadReportRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.csvUuid = "";
                    if (message.csvUuid != null && message.hasOwnProperty("csvUuid"))
                        object.csvUuid = message.csvUuid;
                    return object;
                };
    
                /**
                 * Converts this DownloadReportRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DownloadReportRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DownloadReportRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.DownloadReportRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DownloadReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.DownloadReportRequest";
                };
    
                return DownloadReportRequest;
            })();
    
            tasks.DownloadReportResponse = (function() {
    
                /**
                 * Properties of a DownloadReportResponse.
                 * @memberof infinitusai.tasks
                 * @interface IDownloadReportResponse
                 * @property {string|null} [csvObject] DownloadReportResponse csvObject
                 * @property {number|Long|null} [generatedAtMillis] DownloadReportResponse generatedAtMillis
                 */
    
                /**
                 * Constructs a new DownloadReportResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a DownloadReportResponse.
                 * @implements IDownloadReportResponse
                 * @constructor
                 * @param {infinitusai.tasks.IDownloadReportResponse=} [properties] Properties to set
                 */
                function DownloadReportResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DownloadReportResponse csvObject.
                 * @member {string} csvObject
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @instance
                 */
                DownloadReportResponse.prototype.csvObject = "";
    
                /**
                 * DownloadReportResponse generatedAtMillis.
                 * @member {number|Long} generatedAtMillis
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @instance
                 */
                DownloadReportResponse.prototype.generatedAtMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new DownloadReportResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadReportResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.DownloadReportResponse} DownloadReportResponse instance
                 */
                DownloadReportResponse.create = function create(properties) {
                    return new DownloadReportResponse(properties);
                };
    
                /**
                 * Encodes the specified DownloadReportResponse message. Does not implicitly {@link infinitusai.tasks.DownloadReportResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadReportResponse} message DownloadReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadReportResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.csvObject != null && Object.hasOwnProperty.call(message, "csvObject"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.csvObject);
                    if (message.generatedAtMillis != null && Object.hasOwnProperty.call(message, "generatedAtMillis"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generatedAtMillis);
                    return writer;
                };
    
                /**
                 * Encodes the specified DownloadReportResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.DownloadReportResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadReportResponse} message DownloadReportResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DownloadReportResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.DownloadReportResponse} DownloadReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadReportResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.DownloadReportResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.csvObject = reader.string();
                                break;
                            }
                        case 2: {
                                message.generatedAtMillis = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DownloadReportResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.DownloadReportResponse} DownloadReportResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadReportResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DownloadReportResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DownloadReportResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.csvObject != null && message.hasOwnProperty("csvObject"))
                        if (!$util.isString(message.csvObject))
                            return "csvObject: string expected";
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (!$util.isInteger(message.generatedAtMillis) && !(message.generatedAtMillis && $util.isInteger(message.generatedAtMillis.low) && $util.isInteger(message.generatedAtMillis.high)))
                            return "generatedAtMillis: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a DownloadReportResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.DownloadReportResponse} DownloadReportResponse
                 */
                DownloadReportResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.DownloadReportResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.DownloadReportResponse();
                    if (object.csvObject != null)
                        message.csvObject = String(object.csvObject);
                    if (object.generatedAtMillis != null)
                        if ($util.Long)
                            (message.generatedAtMillis = $util.Long.fromValue(object.generatedAtMillis)).unsigned = false;
                        else if (typeof object.generatedAtMillis === "string")
                            message.generatedAtMillis = parseInt(object.generatedAtMillis, 10);
                        else if (typeof object.generatedAtMillis === "number")
                            message.generatedAtMillis = object.generatedAtMillis;
                        else if (typeof object.generatedAtMillis === "object")
                            message.generatedAtMillis = new $util.LongBits(object.generatedAtMillis.low >>> 0, object.generatedAtMillis.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a DownloadReportResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {infinitusai.tasks.DownloadReportResponse} message DownloadReportResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DownloadReportResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.csvObject = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedAtMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedAtMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.csvObject != null && message.hasOwnProperty("csvObject"))
                        object.csvObject = message.csvObject;
                    if (message.generatedAtMillis != null && message.hasOwnProperty("generatedAtMillis"))
                        if (typeof message.generatedAtMillis === "number")
                            object.generatedAtMillis = options.longs === String ? String(message.generatedAtMillis) : message.generatedAtMillis;
                        else
                            object.generatedAtMillis = options.longs === String ? $util.Long.prototype.toString.call(message.generatedAtMillis) : options.longs === Number ? new $util.LongBits(message.generatedAtMillis.low >>> 0, message.generatedAtMillis.high >>> 0).toNumber() : message.generatedAtMillis;
                    return object;
                };
    
                /**
                 * Converts this DownloadReportResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DownloadReportResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DownloadReportResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.DownloadReportResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DownloadReportResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.DownloadReportResponse";
                };
    
                return DownloadReportResponse;
            })();
    
            tasks.GetOrgProgramsRequest = (function() {
    
                /**
                 * Properties of a GetOrgProgramsRequest.
                 * @memberof infinitusai.tasks
                 * @interface IGetOrgProgramsRequest
                 */
    
                /**
                 * Constructs a new GetOrgProgramsRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetOrgProgramsRequest.
                 * @implements IGetOrgProgramsRequest
                 * @constructor
                 * @param {infinitusai.tasks.IGetOrgProgramsRequest=} [properties] Properties to set
                 */
                function GetOrgProgramsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new GetOrgProgramsRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {infinitusai.tasks.IGetOrgProgramsRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetOrgProgramsRequest} GetOrgProgramsRequest instance
                 */
                GetOrgProgramsRequest.create = function create(properties) {
                    return new GetOrgProgramsRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgProgramsRequest message. Does not implicitly {@link infinitusai.tasks.GetOrgProgramsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {infinitusai.tasks.IGetOrgProgramsRequest} message GetOrgProgramsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgProgramsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgProgramsRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.GetOrgProgramsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {infinitusai.tasks.IGetOrgProgramsRequest} message GetOrgProgramsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgProgramsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgProgramsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetOrgProgramsRequest} GetOrgProgramsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgProgramsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetOrgProgramsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgProgramsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetOrgProgramsRequest} GetOrgProgramsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgProgramsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgProgramsRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgProgramsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a GetOrgProgramsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetOrgProgramsRequest} GetOrgProgramsRequest
                 */
                GetOrgProgramsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetOrgProgramsRequest)
                        return object;
                    return new $root.infinitusai.tasks.GetOrgProgramsRequest();
                };
    
                /**
                 * Creates a plain object from a GetOrgProgramsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {infinitusai.tasks.GetOrgProgramsRequest} message GetOrgProgramsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgProgramsRequest.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this GetOrgProgramsRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgProgramsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgProgramsRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetOrgProgramsRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgProgramsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetOrgProgramsRequest";
                };
    
                return GetOrgProgramsRequest;
            })();
    
            tasks.GetOrgProgramsResponse = (function() {
    
                /**
                 * Properties of a GetOrgProgramsResponse.
                 * @memberof infinitusai.tasks
                 * @interface IGetOrgProgramsResponse
                 * @property {Array.<infinitusai.tasks.IOrgProgram>|null} [programs] GetOrgProgramsResponse programs
                 */
    
                /**
                 * Constructs a new GetOrgProgramsResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetOrgProgramsResponse.
                 * @implements IGetOrgProgramsResponse
                 * @constructor
                 * @param {infinitusai.tasks.IGetOrgProgramsResponse=} [properties] Properties to set
                 */
                function GetOrgProgramsResponse(properties) {
                    this.programs = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgProgramsResponse programs.
                 * @member {Array.<infinitusai.tasks.IOrgProgram>} programs
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @instance
                 */
                GetOrgProgramsResponse.prototype.programs = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgProgramsResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {infinitusai.tasks.IGetOrgProgramsResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetOrgProgramsResponse} GetOrgProgramsResponse instance
                 */
                GetOrgProgramsResponse.create = function create(properties) {
                    return new GetOrgProgramsResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgProgramsResponse message. Does not implicitly {@link infinitusai.tasks.GetOrgProgramsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {infinitusai.tasks.IGetOrgProgramsResponse} message GetOrgProgramsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgProgramsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.programs != null && message.programs.length)
                        for (var i = 0; i < message.programs.length; ++i)
                            $root.infinitusai.tasks.OrgProgram.encode(message.programs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgProgramsResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.GetOrgProgramsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {infinitusai.tasks.IGetOrgProgramsResponse} message GetOrgProgramsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgProgramsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgProgramsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetOrgProgramsResponse} GetOrgProgramsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgProgramsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetOrgProgramsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.programs && message.programs.length))
                                    message.programs = [];
                                message.programs.push($root.infinitusai.tasks.OrgProgram.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgProgramsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetOrgProgramsResponse} GetOrgProgramsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgProgramsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgProgramsResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgProgramsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.programs != null && message.hasOwnProperty("programs")) {
                        if (!Array.isArray(message.programs))
                            return "programs: array expected";
                        for (var i = 0; i < message.programs.length; ++i) {
                            var error = $root.infinitusai.tasks.OrgProgram.verify(message.programs[i]);
                            if (error)
                                return "programs." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgProgramsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetOrgProgramsResponse} GetOrgProgramsResponse
                 */
                GetOrgProgramsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetOrgProgramsResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.GetOrgProgramsResponse();
                    if (object.programs) {
                        if (!Array.isArray(object.programs))
                            throw TypeError(".infinitusai.tasks.GetOrgProgramsResponse.programs: array expected");
                        message.programs = [];
                        for (var i = 0; i < object.programs.length; ++i) {
                            if (typeof object.programs[i] !== "object")
                                throw TypeError(".infinitusai.tasks.GetOrgProgramsResponse.programs: object expected");
                            message.programs[i] = $root.infinitusai.tasks.OrgProgram.fromObject(object.programs[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgProgramsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {infinitusai.tasks.GetOrgProgramsResponse} message GetOrgProgramsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgProgramsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.programs = [];
                    if (message.programs && message.programs.length) {
                        object.programs = [];
                        for (var j = 0; j < message.programs.length; ++j)
                            object.programs[j] = $root.infinitusai.tasks.OrgProgram.toObject(message.programs[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgProgramsResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgProgramsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgProgramsResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetOrgProgramsResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgProgramsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetOrgProgramsResponse";
                };
    
                return GetOrgProgramsResponse;
            })();
    
            tasks.IngestPayerIntelRulesRequest = (function() {
    
                /**
                 * Properties of an IngestPayerIntelRulesRequest.
                 * @memberof infinitusai.tasks
                 * @interface IIngestPayerIntelRulesRequest
                 * @property {string|null} [uploadId] IngestPayerIntelRulesRequest uploadId
                 * @property {string|null} [fileExt] IngestPayerIntelRulesRequest fileExt
                 * @property {infinitusai.tasks.TaskType|null} [taskType] IngestPayerIntelRulesRequest taskType
                 */
    
                /**
                 * Constructs a new IngestPayerIntelRulesRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an IngestPayerIntelRulesRequest.
                 * @implements IIngestPayerIntelRulesRequest
                 * @constructor
                 * @param {infinitusai.tasks.IIngestPayerIntelRulesRequest=} [properties] Properties to set
                 */
                function IngestPayerIntelRulesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IngestPayerIntelRulesRequest uploadId.
                 * @member {string} uploadId
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @instance
                 */
                IngestPayerIntelRulesRequest.prototype.uploadId = "";
    
                /**
                 * IngestPayerIntelRulesRequest fileExt.
                 * @member {string} fileExt
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @instance
                 */
                IngestPayerIntelRulesRequest.prototype.fileExt = "";
    
                /**
                 * IngestPayerIntelRulesRequest taskType.
                 * @member {infinitusai.tasks.TaskType} taskType
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @instance
                 */
                IngestPayerIntelRulesRequest.prototype.taskType = 0;
    
                /**
                 * Creates a new IngestPayerIntelRulesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {infinitusai.tasks.IIngestPayerIntelRulesRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.IngestPayerIntelRulesRequest} IngestPayerIntelRulesRequest instance
                 */
                IngestPayerIntelRulesRequest.create = function create(properties) {
                    return new IngestPayerIntelRulesRequest(properties);
                };
    
                /**
                 * Encodes the specified IngestPayerIntelRulesRequest message. Does not implicitly {@link infinitusai.tasks.IngestPayerIntelRulesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {infinitusai.tasks.IIngestPayerIntelRulesRequest} message IngestPayerIntelRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IngestPayerIntelRulesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uploadId != null && Object.hasOwnProperty.call(message, "uploadId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uploadId);
                    if (message.fileExt != null && Object.hasOwnProperty.call(message, "fileExt"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileExt);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified IngestPayerIntelRulesRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.IngestPayerIntelRulesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {infinitusai.tasks.IIngestPayerIntelRulesRequest} message IngestPayerIntelRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IngestPayerIntelRulesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IngestPayerIntelRulesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.IngestPayerIntelRulesRequest} IngestPayerIntelRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IngestPayerIntelRulesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.IngestPayerIntelRulesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uploadId = reader.string();
                                break;
                            }
                        case 2: {
                                message.fileExt = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IngestPayerIntelRulesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.IngestPayerIntelRulesRequest} IngestPayerIntelRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IngestPayerIntelRulesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IngestPayerIntelRulesRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IngestPayerIntelRulesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uploadId != null && message.hasOwnProperty("uploadId"))
                        if (!$util.isString(message.uploadId))
                            return "uploadId: string expected";
                    if (message.fileExt != null && message.hasOwnProperty("fileExt"))
                        if (!$util.isString(message.fileExt))
                            return "fileExt: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates an IngestPayerIntelRulesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.IngestPayerIntelRulesRequest} IngestPayerIntelRulesRequest
                 */
                IngestPayerIntelRulesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.IngestPayerIntelRulesRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.IngestPayerIntelRulesRequest();
                    if (object.uploadId != null)
                        message.uploadId = String(object.uploadId);
                    if (object.fileExt != null)
                        message.fileExt = String(object.fileExt);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "TASK_TYPE_MED_SUPP_BV":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "TASK_TYPE_FULL_BI":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "TASK_TYPE_PBM_BV":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "TASK_TYPE_COVID_VACCINATIONS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "TASK_TYPE_INBOUND":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "TASK_TYPE_DENTAL_BV":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "TASK_TYPE_PATIENT_OUTREACH":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "TASK_TYPE_PROVIDER_OUTREACH":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "TASK_TYPE_FASTTRACK_MM":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "TASK_TYPE_FASTTRACK_PBM":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "TASK_TYPE_BASIC_BV":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 18:
                        message.taskType = 18;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 19:
                        message.taskType = 19;
                        break;
                    case "TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 20:
                        message.taskType = 20;
                        break;
                    case "TASK_TYPE_PBM_DISCOVERY":
                    case 21:
                        message.taskType = 21;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an IngestPayerIntelRulesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {infinitusai.tasks.IngestPayerIntelRulesRequest} message IngestPayerIntelRulesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IngestPayerIntelRulesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uploadId = "";
                        object.fileExt = "";
                        object.taskType = options.enums === String ? "TASK_TYPE_UNKNOWN" : 0;
                    }
                    if (message.uploadId != null && message.hasOwnProperty("uploadId"))
                        object.uploadId = message.uploadId;
                    if (message.fileExt != null && message.hasOwnProperty("fileExt"))
                        object.fileExt = message.fileExt;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusai.tasks.TaskType[message.taskType] === undefined ? message.taskType : $root.infinitusai.tasks.TaskType[message.taskType] : message.taskType;
                    return object;
                };
    
                /**
                 * Converts this IngestPayerIntelRulesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IngestPayerIntelRulesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IngestPayerIntelRulesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IngestPayerIntelRulesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.IngestPayerIntelRulesRequest";
                };
    
                return IngestPayerIntelRulesRequest;
            })();
    
            tasks.IngestPayerIntelRulesResponse = (function() {
    
                /**
                 * Properties of an IngestPayerIntelRulesResponse.
                 * @memberof infinitusai.tasks
                 * @interface IIngestPayerIntelRulesResponse
                 * @property {number|Long|null} [numRulesCreated] IngestPayerIntelRulesResponse numRulesCreated
                 */
    
                /**
                 * Constructs a new IngestPayerIntelRulesResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents an IngestPayerIntelRulesResponse.
                 * @implements IIngestPayerIntelRulesResponse
                 * @constructor
                 * @param {infinitusai.tasks.IIngestPayerIntelRulesResponse=} [properties] Properties to set
                 */
                function IngestPayerIntelRulesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * IngestPayerIntelRulesResponse numRulesCreated.
                 * @member {number|Long} numRulesCreated
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @instance
                 */
                IngestPayerIntelRulesResponse.prototype.numRulesCreated = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new IngestPayerIntelRulesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.tasks.IIngestPayerIntelRulesResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.IngestPayerIntelRulesResponse} IngestPayerIntelRulesResponse instance
                 */
                IngestPayerIntelRulesResponse.create = function create(properties) {
                    return new IngestPayerIntelRulesResponse(properties);
                };
    
                /**
                 * Encodes the specified IngestPayerIntelRulesResponse message. Does not implicitly {@link infinitusai.tasks.IngestPayerIntelRulesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.tasks.IIngestPayerIntelRulesResponse} message IngestPayerIntelRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IngestPayerIntelRulesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.numRulesCreated != null && Object.hasOwnProperty.call(message, "numRulesCreated"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.numRulesCreated);
                    return writer;
                };
    
                /**
                 * Encodes the specified IngestPayerIntelRulesResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.IngestPayerIntelRulesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.tasks.IIngestPayerIntelRulesResponse} message IngestPayerIntelRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IngestPayerIntelRulesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an IngestPayerIntelRulesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.IngestPayerIntelRulesResponse} IngestPayerIntelRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IngestPayerIntelRulesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.IngestPayerIntelRulesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.numRulesCreated = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an IngestPayerIntelRulesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.IngestPayerIntelRulesResponse} IngestPayerIntelRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IngestPayerIntelRulesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an IngestPayerIntelRulesResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IngestPayerIntelRulesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.numRulesCreated != null && message.hasOwnProperty("numRulesCreated"))
                        if (!$util.isInteger(message.numRulesCreated) && !(message.numRulesCreated && $util.isInteger(message.numRulesCreated.low) && $util.isInteger(message.numRulesCreated.high)))
                            return "numRulesCreated: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an IngestPayerIntelRulesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.IngestPayerIntelRulesResponse} IngestPayerIntelRulesResponse
                 */
                IngestPayerIntelRulesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.IngestPayerIntelRulesResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.IngestPayerIntelRulesResponse();
                    if (object.numRulesCreated != null)
                        if ($util.Long)
                            (message.numRulesCreated = $util.Long.fromValue(object.numRulesCreated)).unsigned = false;
                        else if (typeof object.numRulesCreated === "string")
                            message.numRulesCreated = parseInt(object.numRulesCreated, 10);
                        else if (typeof object.numRulesCreated === "number")
                            message.numRulesCreated = object.numRulesCreated;
                        else if (typeof object.numRulesCreated === "object")
                            message.numRulesCreated = new $util.LongBits(object.numRulesCreated.low >>> 0, object.numRulesCreated.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an IngestPayerIntelRulesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.tasks.IngestPayerIntelRulesResponse} message IngestPayerIntelRulesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IngestPayerIntelRulesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.numRulesCreated = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.numRulesCreated = options.longs === String ? "0" : 0;
                    if (message.numRulesCreated != null && message.hasOwnProperty("numRulesCreated"))
                        if (typeof message.numRulesCreated === "number")
                            object.numRulesCreated = options.longs === String ? String(message.numRulesCreated) : message.numRulesCreated;
                        else
                            object.numRulesCreated = options.longs === String ? $util.Long.prototype.toString.call(message.numRulesCreated) : options.longs === Number ? new $util.LongBits(message.numRulesCreated.low >>> 0, message.numRulesCreated.high >>> 0).toNumber() : message.numRulesCreated;
                    return object;
                };
    
                /**
                 * Converts this IngestPayerIntelRulesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IngestPayerIntelRulesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for IngestPayerIntelRulesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.IngestPayerIntelRulesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IngestPayerIntelRulesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.IngestPayerIntelRulesResponse";
                };
    
                return IngestPayerIntelRulesResponse;
            })();
    
            tasks.DownloadPayerIntelCsvRequest = (function() {
    
                /**
                 * Properties of a DownloadPayerIntelCsvRequest.
                 * @memberof infinitusai.tasks
                 * @interface IDownloadPayerIntelCsvRequest
                 * @property {string|null} [uploadId] DownloadPayerIntelCsvRequest uploadId
                 * @property {string|null} [fileExt] DownloadPayerIntelCsvRequest fileExt
                 */
    
                /**
                 * Constructs a new DownloadPayerIntelCsvRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a DownloadPayerIntelCsvRequest.
                 * @implements IDownloadPayerIntelCsvRequest
                 * @constructor
                 * @param {infinitusai.tasks.IDownloadPayerIntelCsvRequest=} [properties] Properties to set
                 */
                function DownloadPayerIntelCsvRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DownloadPayerIntelCsvRequest uploadId.
                 * @member {string} uploadId
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @instance
                 */
                DownloadPayerIntelCsvRequest.prototype.uploadId = "";
    
                /**
                 * DownloadPayerIntelCsvRequest fileExt.
                 * @member {string} fileExt
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @instance
                 */
                DownloadPayerIntelCsvRequest.prototype.fileExt = "";
    
                /**
                 * Creates a new DownloadPayerIntelCsvRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadPayerIntelCsvRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.DownloadPayerIntelCsvRequest} DownloadPayerIntelCsvRequest instance
                 */
                DownloadPayerIntelCsvRequest.create = function create(properties) {
                    return new DownloadPayerIntelCsvRequest(properties);
                };
    
                /**
                 * Encodes the specified DownloadPayerIntelCsvRequest message. Does not implicitly {@link infinitusai.tasks.DownloadPayerIntelCsvRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadPayerIntelCsvRequest} message DownloadPayerIntelCsvRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadPayerIntelCsvRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uploadId != null && Object.hasOwnProperty.call(message, "uploadId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uploadId);
                    if (message.fileExt != null && Object.hasOwnProperty.call(message, "fileExt"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileExt);
                    return writer;
                };
    
                /**
                 * Encodes the specified DownloadPayerIntelCsvRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.DownloadPayerIntelCsvRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {infinitusai.tasks.IDownloadPayerIntelCsvRequest} message DownloadPayerIntelCsvRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadPayerIntelCsvRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DownloadPayerIntelCsvRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.DownloadPayerIntelCsvRequest} DownloadPayerIntelCsvRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadPayerIntelCsvRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.DownloadPayerIntelCsvRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.uploadId = reader.string();
                                break;
                            }
                        case 2: {
                                message.fileExt = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DownloadPayerIntelCsvRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.DownloadPayerIntelCsvRequest} DownloadPayerIntelCsvRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadPayerIntelCsvRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DownloadPayerIntelCsvRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DownloadPayerIntelCsvRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uploadId != null && message.hasOwnProperty("uploadId"))
                        if (!$util.isString(message.uploadId))
                            return "uploadId: string expected";
                    if (message.fileExt != null && message.hasOwnProperty("fileExt"))
                        if (!$util.isString(message.fileExt))
                            return "fileExt: string expected";
                    return null;
                };
    
                /**
                 * Creates a DownloadPayerIntelCsvRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.DownloadPayerIntelCsvRequest} DownloadPayerIntelCsvRequest
                 */
                DownloadPayerIntelCsvRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.DownloadPayerIntelCsvRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.DownloadPayerIntelCsvRequest();
                    if (object.uploadId != null)
                        message.uploadId = String(object.uploadId);
                    if (object.fileExt != null)
                        message.fileExt = String(object.fileExt);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DownloadPayerIntelCsvRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {infinitusai.tasks.DownloadPayerIntelCsvRequest} message DownloadPayerIntelCsvRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DownloadPayerIntelCsvRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uploadId = "";
                        object.fileExt = "";
                    }
                    if (message.uploadId != null && message.hasOwnProperty("uploadId"))
                        object.uploadId = message.uploadId;
                    if (message.fileExt != null && message.hasOwnProperty("fileExt"))
                        object.fileExt = message.fileExt;
                    return object;
                };
    
                /**
                 * Converts this DownloadPayerIntelCsvRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DownloadPayerIntelCsvRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DownloadPayerIntelCsvRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DownloadPayerIntelCsvRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.DownloadPayerIntelCsvRequest";
                };
    
                return DownloadPayerIntelCsvRequest;
            })();
    
            tasks.DownloadPayerIntelCsvResponse = (function() {
    
                /**
                 * Properties of a DownloadPayerIntelCsvResponse.
                 * @memberof infinitusai.tasks
                 * @interface IDownloadPayerIntelCsvResponse
                 * @property {string|null} [content] DownloadPayerIntelCsvResponse content
                 */
    
                /**
                 * Constructs a new DownloadPayerIntelCsvResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a DownloadPayerIntelCsvResponse.
                 * @implements IDownloadPayerIntelCsvResponse
                 * @constructor
                 * @param {infinitusai.tasks.IDownloadPayerIntelCsvResponse=} [properties] Properties to set
                 */
                function DownloadPayerIntelCsvResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DownloadPayerIntelCsvResponse content.
                 * @member {string} content
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @instance
                 */
                DownloadPayerIntelCsvResponse.prototype.content = "";
    
                /**
                 * Creates a new DownloadPayerIntelCsvResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadPayerIntelCsvResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.DownloadPayerIntelCsvResponse} DownloadPayerIntelCsvResponse instance
                 */
                DownloadPayerIntelCsvResponse.create = function create(properties) {
                    return new DownloadPayerIntelCsvResponse(properties);
                };
    
                /**
                 * Encodes the specified DownloadPayerIntelCsvResponse message. Does not implicitly {@link infinitusai.tasks.DownloadPayerIntelCsvResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadPayerIntelCsvResponse} message DownloadPayerIntelCsvResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadPayerIntelCsvResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.content);
                    return writer;
                };
    
                /**
                 * Encodes the specified DownloadPayerIntelCsvResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.DownloadPayerIntelCsvResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {infinitusai.tasks.IDownloadPayerIntelCsvResponse} message DownloadPayerIntelCsvResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DownloadPayerIntelCsvResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DownloadPayerIntelCsvResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.DownloadPayerIntelCsvResponse} DownloadPayerIntelCsvResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadPayerIntelCsvResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.DownloadPayerIntelCsvResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.content = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DownloadPayerIntelCsvResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.DownloadPayerIntelCsvResponse} DownloadPayerIntelCsvResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DownloadPayerIntelCsvResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DownloadPayerIntelCsvResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DownloadPayerIntelCsvResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    return null;
                };
    
                /**
                 * Creates a DownloadPayerIntelCsvResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.DownloadPayerIntelCsvResponse} DownloadPayerIntelCsvResponse
                 */
                DownloadPayerIntelCsvResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.DownloadPayerIntelCsvResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.DownloadPayerIntelCsvResponse();
                    if (object.content != null)
                        message.content = String(object.content);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DownloadPayerIntelCsvResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {infinitusai.tasks.DownloadPayerIntelCsvResponse} message DownloadPayerIntelCsvResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DownloadPayerIntelCsvResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.content = "";
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    return object;
                };
    
                /**
                 * Converts this DownloadPayerIntelCsvResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DownloadPayerIntelCsvResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DownloadPayerIntelCsvResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.DownloadPayerIntelCsvResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DownloadPayerIntelCsvResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.DownloadPayerIntelCsvResponse";
                };
    
                return DownloadPayerIntelCsvResponse;
            })();
    
            tasks.GetPayerIntelUploadHistoryResponse = (function() {
    
                /**
                 * Properties of a GetPayerIntelUploadHistoryResponse.
                 * @memberof infinitusai.tasks
                 * @interface IGetPayerIntelUploadHistoryResponse
                 * @property {Array.<infinitusai.tasks.IRulesFileUpload>|null} [uploads] GetPayerIntelUploadHistoryResponse uploads
                 */
    
                /**
                 * Constructs a new GetPayerIntelUploadHistoryResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetPayerIntelUploadHistoryResponse.
                 * @implements IGetPayerIntelUploadHistoryResponse
                 * @constructor
                 * @param {infinitusai.tasks.IGetPayerIntelUploadHistoryResponse=} [properties] Properties to set
                 */
                function GetPayerIntelUploadHistoryResponse(properties) {
                    this.uploads = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPayerIntelUploadHistoryResponse uploads.
                 * @member {Array.<infinitusai.tasks.IRulesFileUpload>} uploads
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @instance
                 */
                GetPayerIntelUploadHistoryResponse.prototype.uploads = $util.emptyArray;
    
                /**
                 * Creates a new GetPayerIntelUploadHistoryResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelUploadHistoryResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetPayerIntelUploadHistoryResponse} GetPayerIntelUploadHistoryResponse instance
                 */
                GetPayerIntelUploadHistoryResponse.create = function create(properties) {
                    return new GetPayerIntelUploadHistoryResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPayerIntelUploadHistoryResponse message. Does not implicitly {@link infinitusai.tasks.GetPayerIntelUploadHistoryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelUploadHistoryResponse} message GetPayerIntelUploadHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerIntelUploadHistoryResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uploads != null && message.uploads.length)
                        for (var i = 0; i < message.uploads.length; ++i)
                            $root.infinitusai.tasks.RulesFileUpload.encode(message.uploads[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayerIntelUploadHistoryResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.GetPayerIntelUploadHistoryResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelUploadHistoryResponse} message GetPayerIntelUploadHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerIntelUploadHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayerIntelUploadHistoryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetPayerIntelUploadHistoryResponse} GetPayerIntelUploadHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerIntelUploadHistoryResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetPayerIntelUploadHistoryResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.uploads && message.uploads.length))
                                    message.uploads = [];
                                message.uploads.push($root.infinitusai.tasks.RulesFileUpload.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayerIntelUploadHistoryResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetPayerIntelUploadHistoryResponse} GetPayerIntelUploadHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerIntelUploadHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayerIntelUploadHistoryResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayerIntelUploadHistoryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uploads != null && message.hasOwnProperty("uploads")) {
                        if (!Array.isArray(message.uploads))
                            return "uploads: array expected";
                        for (var i = 0; i < message.uploads.length; ++i) {
                            var error = $root.infinitusai.tasks.RulesFileUpload.verify(message.uploads[i]);
                            if (error)
                                return "uploads." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPayerIntelUploadHistoryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetPayerIntelUploadHistoryResponse} GetPayerIntelUploadHistoryResponse
                 */
                GetPayerIntelUploadHistoryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetPayerIntelUploadHistoryResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.GetPayerIntelUploadHistoryResponse();
                    if (object.uploads) {
                        if (!Array.isArray(object.uploads))
                            throw TypeError(".infinitusai.tasks.GetPayerIntelUploadHistoryResponse.uploads: array expected");
                        message.uploads = [];
                        for (var i = 0; i < object.uploads.length; ++i) {
                            if (typeof object.uploads[i] !== "object")
                                throw TypeError(".infinitusai.tasks.GetPayerIntelUploadHistoryResponse.uploads: object expected");
                            message.uploads[i] = $root.infinitusai.tasks.RulesFileUpload.fromObject(object.uploads[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPayerIntelUploadHistoryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {infinitusai.tasks.GetPayerIntelUploadHistoryResponse} message GetPayerIntelUploadHistoryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayerIntelUploadHistoryResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uploads = [];
                    if (message.uploads && message.uploads.length) {
                        object.uploads = [];
                        for (var j = 0; j < message.uploads.length; ++j)
                            object.uploads[j] = $root.infinitusai.tasks.RulesFileUpload.toObject(message.uploads[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPayerIntelUploadHistoryResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayerIntelUploadHistoryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayerIntelUploadHistoryResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayerIntelUploadHistoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetPayerIntelUploadHistoryResponse";
                };
    
                return GetPayerIntelUploadHistoryResponse;
            })();
    
            tasks.GetPayerIntelUploadHistoryRequest = (function() {
    
                /**
                 * Properties of a GetPayerIntelUploadHistoryRequest.
                 * @memberof infinitusai.tasks
                 * @interface IGetPayerIntelUploadHistoryRequest
                 * @property {number|Long|null} [limit] GetPayerIntelUploadHistoryRequest limit
                 */
    
                /**
                 * Constructs a new GetPayerIntelUploadHistoryRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetPayerIntelUploadHistoryRequest.
                 * @implements IGetPayerIntelUploadHistoryRequest
                 * @constructor
                 * @param {infinitusai.tasks.IGetPayerIntelUploadHistoryRequest=} [properties] Properties to set
                 */
                function GetPayerIntelUploadHistoryRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPayerIntelUploadHistoryRequest limit.
                 * @member {number|Long} limit
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @instance
                 */
                GetPayerIntelUploadHistoryRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new GetPayerIntelUploadHistoryRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelUploadHistoryRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetPayerIntelUploadHistoryRequest} GetPayerIntelUploadHistoryRequest instance
                 */
                GetPayerIntelUploadHistoryRequest.create = function create(properties) {
                    return new GetPayerIntelUploadHistoryRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPayerIntelUploadHistoryRequest message. Does not implicitly {@link infinitusai.tasks.GetPayerIntelUploadHistoryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelUploadHistoryRequest} message GetPayerIntelUploadHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerIntelUploadHistoryRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.limit);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayerIntelUploadHistoryRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.GetPayerIntelUploadHistoryRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelUploadHistoryRequest} message GetPayerIntelUploadHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerIntelUploadHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayerIntelUploadHistoryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetPayerIntelUploadHistoryRequest} GetPayerIntelUploadHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerIntelUploadHistoryRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetPayerIntelUploadHistoryRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.limit = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayerIntelUploadHistoryRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetPayerIntelUploadHistoryRequest} GetPayerIntelUploadHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerIntelUploadHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayerIntelUploadHistoryRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayerIntelUploadHistoryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                            return "limit: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a GetPayerIntelUploadHistoryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetPayerIntelUploadHistoryRequest} GetPayerIntelUploadHistoryRequest
                 */
                GetPayerIntelUploadHistoryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetPayerIntelUploadHistoryRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.GetPayerIntelUploadHistoryRequest();
                    if (object.limit != null)
                        if ($util.Long)
                            (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                        else if (typeof object.limit === "string")
                            message.limit = parseInt(object.limit, 10);
                        else if (typeof object.limit === "number")
                            message.limit = object.limit;
                        else if (typeof object.limit === "object")
                            message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPayerIntelUploadHistoryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {infinitusai.tasks.GetPayerIntelUploadHistoryRequest} message GetPayerIntelUploadHistoryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayerIntelUploadHistoryRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.limit = options.longs === String ? "0" : 0;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (typeof message.limit === "number")
                            object.limit = options.longs === String ? String(message.limit) : message.limit;
                        else
                            object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                    return object;
                };
    
                /**
                 * Converts this GetPayerIntelUploadHistoryRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayerIntelUploadHistoryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayerIntelUploadHistoryRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetPayerIntelUploadHistoryRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayerIntelUploadHistoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetPayerIntelUploadHistoryRequest";
                };
    
                return GetPayerIntelUploadHistoryRequest;
            })();
    
            tasks.GetPayerIntelRulesResponse = (function() {
    
                /**
                 * Properties of a GetPayerIntelRulesResponse.
                 * @memberof infinitusai.tasks
                 * @interface IGetPayerIntelRulesResponse
                 * @property {Array.<infinitusai.tasks.IRule>|null} [rules] GetPayerIntelRulesResponse rules
                 */
    
                /**
                 * Constructs a new GetPayerIntelRulesResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetPayerIntelRulesResponse.
                 * @implements IGetPayerIntelRulesResponse
                 * @constructor
                 * @param {infinitusai.tasks.IGetPayerIntelRulesResponse=} [properties] Properties to set
                 */
                function GetPayerIntelRulesResponse(properties) {
                    this.rules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPayerIntelRulesResponse rules.
                 * @member {Array.<infinitusai.tasks.IRule>} rules
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @instance
                 */
                GetPayerIntelRulesResponse.prototype.rules = $util.emptyArray;
    
                /**
                 * Creates a new GetPayerIntelRulesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelRulesResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetPayerIntelRulesResponse} GetPayerIntelRulesResponse instance
                 */
                GetPayerIntelRulesResponse.create = function create(properties) {
                    return new GetPayerIntelRulesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetPayerIntelRulesResponse message. Does not implicitly {@link infinitusai.tasks.GetPayerIntelRulesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelRulesResponse} message GetPayerIntelRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerIntelRulesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.rules != null && message.rules.length)
                        for (var i = 0; i < message.rules.length; ++i)
                            $root.infinitusai.tasks.Rule.encode(message.rules[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayerIntelRulesResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.GetPayerIntelRulesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelRulesResponse} message GetPayerIntelRulesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerIntelRulesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayerIntelRulesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetPayerIntelRulesResponse} GetPayerIntelRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerIntelRulesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetPayerIntelRulesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.rules && message.rules.length))
                                    message.rules = [];
                                message.rules.push($root.infinitusai.tasks.Rule.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayerIntelRulesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetPayerIntelRulesResponse} GetPayerIntelRulesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerIntelRulesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayerIntelRulesResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayerIntelRulesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.rules != null && message.hasOwnProperty("rules")) {
                        if (!Array.isArray(message.rules))
                            return "rules: array expected";
                        for (var i = 0; i < message.rules.length; ++i) {
                            var error = $root.infinitusai.tasks.Rule.verify(message.rules[i]);
                            if (error)
                                return "rules." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetPayerIntelRulesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetPayerIntelRulesResponse} GetPayerIntelRulesResponse
                 */
                GetPayerIntelRulesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetPayerIntelRulesResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.GetPayerIntelRulesResponse();
                    if (object.rules) {
                        if (!Array.isArray(object.rules))
                            throw TypeError(".infinitusai.tasks.GetPayerIntelRulesResponse.rules: array expected");
                        message.rules = [];
                        for (var i = 0; i < object.rules.length; ++i) {
                            if (typeof object.rules[i] !== "object")
                                throw TypeError(".infinitusai.tasks.GetPayerIntelRulesResponse.rules: object expected");
                            message.rules[i] = $root.infinitusai.tasks.Rule.fromObject(object.rules[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPayerIntelRulesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {infinitusai.tasks.GetPayerIntelRulesResponse} message GetPayerIntelRulesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayerIntelRulesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.rules = [];
                    if (message.rules && message.rules.length) {
                        object.rules = [];
                        for (var j = 0; j < message.rules.length; ++j)
                            object.rules[j] = $root.infinitusai.tasks.Rule.toObject(message.rules[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetPayerIntelRulesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayerIntelRulesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayerIntelRulesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetPayerIntelRulesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayerIntelRulesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetPayerIntelRulesResponse";
                };
    
                return GetPayerIntelRulesResponse;
            })();
    
            tasks.GetPayerIntelRulesRequest = (function() {
    
                /**
                 * Properties of a GetPayerIntelRulesRequest.
                 * @memberof infinitusai.tasks
                 * @interface IGetPayerIntelRulesRequest
                 * @property {string|null} [taskUuid] GetPayerIntelRulesRequest taskUuid
                 * @property {string|null} [callUuid] GetPayerIntelRulesRequest callUuid
                 * @property {string|null} [taskType] GetPayerIntelRulesRequest taskType
                 * @property {boolean|null} [includeInputCond] GetPayerIntelRulesRequest includeInputCond
                 */
    
                /**
                 * Constructs a new GetPayerIntelRulesRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetPayerIntelRulesRequest.
                 * @implements IGetPayerIntelRulesRequest
                 * @constructor
                 * @param {infinitusai.tasks.IGetPayerIntelRulesRequest=} [properties] Properties to set
                 */
                function GetPayerIntelRulesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetPayerIntelRulesRequest taskUuid.
                 * @member {string} taskUuid
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @instance
                 */
                GetPayerIntelRulesRequest.prototype.taskUuid = "";
    
                /**
                 * GetPayerIntelRulesRequest callUuid.
                 * @member {string} callUuid
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @instance
                 */
                GetPayerIntelRulesRequest.prototype.callUuid = "";
    
                /**
                 * GetPayerIntelRulesRequest taskType.
                 * @member {string} taskType
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @instance
                 */
                GetPayerIntelRulesRequest.prototype.taskType = "";
    
                /**
                 * GetPayerIntelRulesRequest includeInputCond.
                 * @member {boolean} includeInputCond
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @instance
                 */
                GetPayerIntelRulesRequest.prototype.includeInputCond = false;
    
                /**
                 * Creates a new GetPayerIntelRulesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelRulesRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetPayerIntelRulesRequest} GetPayerIntelRulesRequest instance
                 */
                GetPayerIntelRulesRequest.create = function create(properties) {
                    return new GetPayerIntelRulesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetPayerIntelRulesRequest message. Does not implicitly {@link infinitusai.tasks.GetPayerIntelRulesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelRulesRequest} message GetPayerIntelRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerIntelRulesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.taskUuid != null && Object.hasOwnProperty.call(message, "taskUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskUuid);
                    if (message.callUuid != null && Object.hasOwnProperty.call(message, "callUuid"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.callUuid);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskType);
                    if (message.includeInputCond != null && Object.hasOwnProperty.call(message, "includeInputCond"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeInputCond);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetPayerIntelRulesRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.GetPayerIntelRulesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {infinitusai.tasks.IGetPayerIntelRulesRequest} message GetPayerIntelRulesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayerIntelRulesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetPayerIntelRulesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetPayerIntelRulesRequest} GetPayerIntelRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerIntelRulesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetPayerIntelRulesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.taskUuid = reader.string();
                                break;
                            }
                        case 2: {
                                message.callUuid = reader.string();
                                break;
                            }
                        case 3: {
                                message.taskType = reader.string();
                                break;
                            }
                        case 4: {
                                message.includeInputCond = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetPayerIntelRulesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetPayerIntelRulesRequest} GetPayerIntelRulesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayerIntelRulesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetPayerIntelRulesRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayerIntelRulesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        if (!$util.isString(message.taskUuid))
                            return "taskUuid: string expected";
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        if (!$util.isString(message.callUuid))
                            return "callUuid: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        if (!$util.isString(message.taskType))
                            return "taskType: string expected";
                    if (message.includeInputCond != null && message.hasOwnProperty("includeInputCond"))
                        if (typeof message.includeInputCond !== "boolean")
                            return "includeInputCond: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a GetPayerIntelRulesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetPayerIntelRulesRequest} GetPayerIntelRulesRequest
                 */
                GetPayerIntelRulesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetPayerIntelRulesRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.GetPayerIntelRulesRequest();
                    if (object.taskUuid != null)
                        message.taskUuid = String(object.taskUuid);
                    if (object.callUuid != null)
                        message.callUuid = String(object.callUuid);
                    if (object.taskType != null)
                        message.taskType = String(object.taskType);
                    if (object.includeInputCond != null)
                        message.includeInputCond = Boolean(object.includeInputCond);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetPayerIntelRulesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {infinitusai.tasks.GetPayerIntelRulesRequest} message GetPayerIntelRulesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayerIntelRulesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.taskUuid = "";
                        object.callUuid = "";
                        object.taskType = "";
                        object.includeInputCond = false;
                    }
                    if (message.taskUuid != null && message.hasOwnProperty("taskUuid"))
                        object.taskUuid = message.taskUuid;
                    if (message.callUuid != null && message.hasOwnProperty("callUuid"))
                        object.callUuid = message.callUuid;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = message.taskType;
                    if (message.includeInputCond != null && message.hasOwnProperty("includeInputCond"))
                        object.includeInputCond = message.includeInputCond;
                    return object;
                };
    
                /**
                 * Converts this GetPayerIntelRulesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayerIntelRulesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetPayerIntelRulesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetPayerIntelRulesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetPayerIntelRulesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetPayerIntelRulesRequest";
                };
    
                return GetPayerIntelRulesRequest;
            })();
    
            tasks.GetOrgTaskTypesRequest = (function() {
    
                /**
                 * Properties of a GetOrgTaskTypesRequest.
                 * @memberof infinitusai.tasks
                 * @interface IGetOrgTaskTypesRequest
                 * @property {string|null} [orgUUID] GetOrgTaskTypesRequest orgUUID
                 */
    
                /**
                 * Constructs a new GetOrgTaskTypesRequest.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetOrgTaskTypesRequest.
                 * @implements IGetOrgTaskTypesRequest
                 * @constructor
                 * @param {infinitusai.tasks.IGetOrgTaskTypesRequest=} [properties] Properties to set
                 */
                function GetOrgTaskTypesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgTaskTypesRequest orgUUID.
                 * @member {string} orgUUID
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @instance
                 */
                GetOrgTaskTypesRequest.prototype.orgUUID = "";
    
                /**
                 * Creates a new GetOrgTaskTypesRequest instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {infinitusai.tasks.IGetOrgTaskTypesRequest=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetOrgTaskTypesRequest} GetOrgTaskTypesRequest instance
                 */
                GetOrgTaskTypesRequest.create = function create(properties) {
                    return new GetOrgTaskTypesRequest(properties);
                };
    
                /**
                 * Encodes the specified GetOrgTaskTypesRequest message. Does not implicitly {@link infinitusai.tasks.GetOrgTaskTypesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {infinitusai.tasks.IGetOrgTaskTypesRequest} message GetOrgTaskTypesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgTaskTypesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgUUID != null && Object.hasOwnProperty.call(message, "orgUUID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orgUUID);
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgTaskTypesRequest message, length delimited. Does not implicitly {@link infinitusai.tasks.GetOrgTaskTypesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {infinitusai.tasks.IGetOrgTaskTypesRequest} message GetOrgTaskTypesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgTaskTypesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgTaskTypesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetOrgTaskTypesRequest} GetOrgTaskTypesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgTaskTypesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetOrgTaskTypesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orgUUID = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgTaskTypesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetOrgTaskTypesRequest} GetOrgTaskTypesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgTaskTypesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgTaskTypesRequest message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgTaskTypesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgUUID != null && message.hasOwnProperty("orgUUID"))
                        if (!$util.isString(message.orgUUID))
                            return "orgUUID: string expected";
                    return null;
                };
    
                /**
                 * Creates a GetOrgTaskTypesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetOrgTaskTypesRequest} GetOrgTaskTypesRequest
                 */
                GetOrgTaskTypesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetOrgTaskTypesRequest)
                        return object;
                    var message = new $root.infinitusai.tasks.GetOrgTaskTypesRequest();
                    if (object.orgUUID != null)
                        message.orgUUID = String(object.orgUUID);
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgTaskTypesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {infinitusai.tasks.GetOrgTaskTypesRequest} message GetOrgTaskTypesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgTaskTypesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.orgUUID = "";
                    if (message.orgUUID != null && message.hasOwnProperty("orgUUID"))
                        object.orgUUID = message.orgUUID;
                    return object;
                };
    
                /**
                 * Converts this GetOrgTaskTypesRequest to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgTaskTypesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgTaskTypesRequest
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetOrgTaskTypesRequest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgTaskTypesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetOrgTaskTypesRequest";
                };
    
                return GetOrgTaskTypesRequest;
            })();
    
            tasks.GetOrgTaskTypesResponse = (function() {
    
                /**
                 * Properties of a GetOrgTaskTypesResponse.
                 * @memberof infinitusai.tasks
                 * @interface IGetOrgTaskTypesResponse
                 * @property {Array.<infinitusai.tasks.IOrgTaskType>|null} [orgTaskTypes] GetOrgTaskTypesResponse orgTaskTypes
                 */
    
                /**
                 * Constructs a new GetOrgTaskTypesResponse.
                 * @memberof infinitusai.tasks
                 * @classdesc Represents a GetOrgTaskTypesResponse.
                 * @implements IGetOrgTaskTypesResponse
                 * @constructor
                 * @param {infinitusai.tasks.IGetOrgTaskTypesResponse=} [properties] Properties to set
                 */
                function GetOrgTaskTypesResponse(properties) {
                    this.orgTaskTypes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GetOrgTaskTypesResponse orgTaskTypes.
                 * @member {Array.<infinitusai.tasks.IOrgTaskType>} orgTaskTypes
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @instance
                 */
                GetOrgTaskTypesResponse.prototype.orgTaskTypes = $util.emptyArray;
    
                /**
                 * Creates a new GetOrgTaskTypesResponse instance using the specified properties.
                 * @function create
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {infinitusai.tasks.IGetOrgTaskTypesResponse=} [properties] Properties to set
                 * @returns {infinitusai.tasks.GetOrgTaskTypesResponse} GetOrgTaskTypesResponse instance
                 */
                GetOrgTaskTypesResponse.create = function create(properties) {
                    return new GetOrgTaskTypesResponse(properties);
                };
    
                /**
                 * Encodes the specified GetOrgTaskTypesResponse message. Does not implicitly {@link infinitusai.tasks.GetOrgTaskTypesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {infinitusai.tasks.IGetOrgTaskTypesResponse} message GetOrgTaskTypesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgTaskTypesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orgTaskTypes != null && message.orgTaskTypes.length)
                        for (var i = 0; i < message.orgTaskTypes.length; ++i)
                            $root.infinitusai.tasks.OrgTaskType.encode(message.orgTaskTypes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GetOrgTaskTypesResponse message, length delimited. Does not implicitly {@link infinitusai.tasks.GetOrgTaskTypesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {infinitusai.tasks.IGetOrgTaskTypesResponse} message GetOrgTaskTypesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetOrgTaskTypesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GetOrgTaskTypesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusai.tasks.GetOrgTaskTypesResponse} GetOrgTaskTypesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgTaskTypesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusai.tasks.GetOrgTaskTypesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.orgTaskTypes && message.orgTaskTypes.length))
                                    message.orgTaskTypes = [];
                                message.orgTaskTypes.push($root.infinitusai.tasks.OrgTaskType.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GetOrgTaskTypesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusai.tasks.GetOrgTaskTypesResponse} GetOrgTaskTypesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetOrgTaskTypesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GetOrgTaskTypesResponse message.
                 * @function verify
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetOrgTaskTypesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orgTaskTypes != null && message.hasOwnProperty("orgTaskTypes")) {
                        if (!Array.isArray(message.orgTaskTypes))
                            return "orgTaskTypes: array expected";
                        for (var i = 0; i < message.orgTaskTypes.length; ++i) {
                            var error = $root.infinitusai.tasks.OrgTaskType.verify(message.orgTaskTypes[i]);
                            if (error)
                                return "orgTaskTypes." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GetOrgTaskTypesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusai.tasks.GetOrgTaskTypesResponse} GetOrgTaskTypesResponse
                 */
                GetOrgTaskTypesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusai.tasks.GetOrgTaskTypesResponse)
                        return object;
                    var message = new $root.infinitusai.tasks.GetOrgTaskTypesResponse();
                    if (object.orgTaskTypes) {
                        if (!Array.isArray(object.orgTaskTypes))
                            throw TypeError(".infinitusai.tasks.GetOrgTaskTypesResponse.orgTaskTypes: array expected");
                        message.orgTaskTypes = [];
                        for (var i = 0; i < object.orgTaskTypes.length; ++i) {
                            if (typeof object.orgTaskTypes[i] !== "object")
                                throw TypeError(".infinitusai.tasks.GetOrgTaskTypesResponse.orgTaskTypes: object expected");
                            message.orgTaskTypes[i] = $root.infinitusai.tasks.OrgTaskType.fromObject(object.orgTaskTypes[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GetOrgTaskTypesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {infinitusai.tasks.GetOrgTaskTypesResponse} message GetOrgTaskTypesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetOrgTaskTypesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orgTaskTypes = [];
                    if (message.orgTaskTypes && message.orgTaskTypes.length) {
                        object.orgTaskTypes = [];
                        for (var j = 0; j < message.orgTaskTypes.length; ++j)
                            object.orgTaskTypes[j] = $root.infinitusai.tasks.OrgTaskType.toObject(message.orgTaskTypes[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GetOrgTaskTypesResponse to JSON.
                 * @function toJSON
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetOrgTaskTypesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GetOrgTaskTypesResponse
                 * @function getTypeUrl
                 * @memberof infinitusai.tasks.GetOrgTaskTypesResponse
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GetOrgTaskTypesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusai.tasks.GetOrgTaskTypesResponse";
                };
    
                return GetOrgTaskTypesResponse;
            })();
    
            return tasks;
        })();
    
        return infinitusai;
    })();
    
    $root.infinitusapi = (function() {
    
        /**
         * Namespace infinitusapi.
         * @exports infinitusapi
         * @namespace
         */
        var infinitusapi = {};
    
        infinitusapi.APILogEntry = (function() {
    
            /**
             * Properties of a APILogEntry.
             * @memberof infinitusapi
             * @interface IAPILogEntry
             * @property {infinitusapi.APILogEntry.EntryType|null} [type] APILogEntry type
             * @property {string|null} [parentRequestUuid] APILogEntry parentRequestUuid
             * @property {number|Long|null} [requestMillis] APILogEntry requestMillis
             * @property {number|Long|null} [responseMillis] APILogEntry responseMillis
             * @property {infinitusapi.ICreateTaskRequestLogEntry|null} [createTaskRequest] APILogEntry createTaskRequest
             */
    
            /**
             * Constructs a new APILogEntry.
             * @memberof infinitusapi
             * @classdesc Represents a APILogEntry.
             * @implements IAPILogEntry
             * @constructor
             * @param {infinitusapi.IAPILogEntry=} [properties] Properties to set
             */
            function APILogEntry(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * APILogEntry type.
             * @member {infinitusapi.APILogEntry.EntryType} type
             * @memberof infinitusapi.APILogEntry
             * @instance
             */
            APILogEntry.prototype.type = 0;
    
            /**
             * APILogEntry parentRequestUuid.
             * @member {string} parentRequestUuid
             * @memberof infinitusapi.APILogEntry
             * @instance
             */
            APILogEntry.prototype.parentRequestUuid = "";
    
            /**
             * APILogEntry requestMillis.
             * @member {number|Long} requestMillis
             * @memberof infinitusapi.APILogEntry
             * @instance
             */
            APILogEntry.prototype.requestMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * APILogEntry responseMillis.
             * @member {number|Long} responseMillis
             * @memberof infinitusapi.APILogEntry
             * @instance
             */
            APILogEntry.prototype.responseMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
            /**
             * APILogEntry createTaskRequest.
             * @member {infinitusapi.ICreateTaskRequestLogEntry|null|undefined} createTaskRequest
             * @memberof infinitusapi.APILogEntry
             * @instance
             */
            APILogEntry.prototype.createTaskRequest = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * APILogEntry logEntry.
             * @member {"createTaskRequest"|undefined} logEntry
             * @memberof infinitusapi.APILogEntry
             * @instance
             */
            Object.defineProperty(APILogEntry.prototype, "logEntry", {
                get: $util.oneOfGetter($oneOfFields = ["createTaskRequest"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new APILogEntry instance using the specified properties.
             * @function create
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {infinitusapi.IAPILogEntry=} [properties] Properties to set
             * @returns {infinitusapi.APILogEntry} APILogEntry instance
             */
            APILogEntry.create = function create(properties) {
                return new APILogEntry(properties);
            };
    
            /**
             * Encodes the specified APILogEntry message. Does not implicitly {@link infinitusapi.APILogEntry.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {infinitusapi.IAPILogEntry} message APILogEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            APILogEntry.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.parentRequestUuid != null && Object.hasOwnProperty.call(message, "parentRequestUuid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.parentRequestUuid);
                if (message.requestMillis != null && Object.hasOwnProperty.call(message, "requestMillis"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.requestMillis);
                if (message.responseMillis != null && Object.hasOwnProperty.call(message, "responseMillis"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.responseMillis);
                if (message.createTaskRequest != null && Object.hasOwnProperty.call(message, "createTaskRequest"))
                    $root.infinitusapi.CreateTaskRequestLogEntry.encode(message.createTaskRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified APILogEntry message, length delimited. Does not implicitly {@link infinitusapi.APILogEntry.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {infinitusapi.IAPILogEntry} message APILogEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            APILogEntry.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a APILogEntry message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.APILogEntry} APILogEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            APILogEntry.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.APILogEntry();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type = reader.int32();
                            break;
                        }
                    case 2: {
                            message.parentRequestUuid = reader.string();
                            break;
                        }
                    case 3: {
                            message.requestMillis = reader.int64();
                            break;
                        }
                    case 4: {
                            message.responseMillis = reader.int64();
                            break;
                        }
                    case 5: {
                            message.createTaskRequest = $root.infinitusapi.CreateTaskRequestLogEntry.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a APILogEntry message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.APILogEntry} APILogEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            APILogEntry.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a APILogEntry message.
             * @function verify
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            APILogEntry.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.parentRequestUuid != null && message.hasOwnProperty("parentRequestUuid"))
                    if (!$util.isString(message.parentRequestUuid))
                        return "parentRequestUuid: string expected";
                if (message.requestMillis != null && message.hasOwnProperty("requestMillis"))
                    if (!$util.isInteger(message.requestMillis) && !(message.requestMillis && $util.isInteger(message.requestMillis.low) && $util.isInteger(message.requestMillis.high)))
                        return "requestMillis: integer|Long expected";
                if (message.responseMillis != null && message.hasOwnProperty("responseMillis"))
                    if (!$util.isInteger(message.responseMillis) && !(message.responseMillis && $util.isInteger(message.responseMillis.low) && $util.isInteger(message.responseMillis.high)))
                        return "responseMillis: integer|Long expected";
                if (message.createTaskRequest != null && message.hasOwnProperty("createTaskRequest")) {
                    properties.logEntry = 1;
                    {
                        var error = $root.infinitusapi.CreateTaskRequestLogEntry.verify(message.createTaskRequest);
                        if (error)
                            return "createTaskRequest." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a APILogEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.APILogEntry} APILogEntry
             */
            APILogEntry.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.APILogEntry)
                    return object;
                var message = new $root.infinitusapi.APILogEntry();
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "ENTRY_TYPE_UNKNOWN":
                case 0:
                    message.type = 0;
                    break;
                case "ENTRY_TYPE_CREATE_TASK_REQUEST":
                case 1:
                    message.type = 1;
                    break;
                }
                if (object.parentRequestUuid != null)
                    message.parentRequestUuid = String(object.parentRequestUuid);
                if (object.requestMillis != null)
                    if ($util.Long)
                        (message.requestMillis = $util.Long.fromValue(object.requestMillis)).unsigned = false;
                    else if (typeof object.requestMillis === "string")
                        message.requestMillis = parseInt(object.requestMillis, 10);
                    else if (typeof object.requestMillis === "number")
                        message.requestMillis = object.requestMillis;
                    else if (typeof object.requestMillis === "object")
                        message.requestMillis = new $util.LongBits(object.requestMillis.low >>> 0, object.requestMillis.high >>> 0).toNumber();
                if (object.responseMillis != null)
                    if ($util.Long)
                        (message.responseMillis = $util.Long.fromValue(object.responseMillis)).unsigned = false;
                    else if (typeof object.responseMillis === "string")
                        message.responseMillis = parseInt(object.responseMillis, 10);
                    else if (typeof object.responseMillis === "number")
                        message.responseMillis = object.responseMillis;
                    else if (typeof object.responseMillis === "object")
                        message.responseMillis = new $util.LongBits(object.responseMillis.low >>> 0, object.responseMillis.high >>> 0).toNumber();
                if (object.createTaskRequest != null) {
                    if (typeof object.createTaskRequest !== "object")
                        throw TypeError(".infinitusapi.APILogEntry.createTaskRequest: object expected");
                    message.createTaskRequest = $root.infinitusapi.CreateTaskRequestLogEntry.fromObject(object.createTaskRequest);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a APILogEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {infinitusapi.APILogEntry} message APILogEntry
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            APILogEntry.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "ENTRY_TYPE_UNKNOWN" : 0;
                    object.parentRequestUuid = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.requestMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestMillis = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.responseMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.responseMillis = options.longs === String ? "0" : 0;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.infinitusapi.APILogEntry.EntryType[message.type] === undefined ? message.type : $root.infinitusapi.APILogEntry.EntryType[message.type] : message.type;
                if (message.parentRequestUuid != null && message.hasOwnProperty("parentRequestUuid"))
                    object.parentRequestUuid = message.parentRequestUuid;
                if (message.requestMillis != null && message.hasOwnProperty("requestMillis"))
                    if (typeof message.requestMillis === "number")
                        object.requestMillis = options.longs === String ? String(message.requestMillis) : message.requestMillis;
                    else
                        object.requestMillis = options.longs === String ? $util.Long.prototype.toString.call(message.requestMillis) : options.longs === Number ? new $util.LongBits(message.requestMillis.low >>> 0, message.requestMillis.high >>> 0).toNumber() : message.requestMillis;
                if (message.responseMillis != null && message.hasOwnProperty("responseMillis"))
                    if (typeof message.responseMillis === "number")
                        object.responseMillis = options.longs === String ? String(message.responseMillis) : message.responseMillis;
                    else
                        object.responseMillis = options.longs === String ? $util.Long.prototype.toString.call(message.responseMillis) : options.longs === Number ? new $util.LongBits(message.responseMillis.low >>> 0, message.responseMillis.high >>> 0).toNumber() : message.responseMillis;
                if (message.createTaskRequest != null && message.hasOwnProperty("createTaskRequest")) {
                    object.createTaskRequest = $root.infinitusapi.CreateTaskRequestLogEntry.toObject(message.createTaskRequest, options);
                    if (options.oneofs)
                        object.logEntry = "createTaskRequest";
                }
                return object;
            };
    
            /**
             * Converts this APILogEntry to JSON.
             * @function toJSON
             * @memberof infinitusapi.APILogEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            APILogEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for APILogEntry
             * @function getTypeUrl
             * @memberof infinitusapi.APILogEntry
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            APILogEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.APILogEntry";
            };
    
            /**
             * EntryType enum.
             * @name infinitusapi.APILogEntry.EntryType
             * @enum {number}
             * @property {number} ENTRY_TYPE_UNKNOWN=0 ENTRY_TYPE_UNKNOWN value
             * @property {number} ENTRY_TYPE_CREATE_TASK_REQUEST=1 ENTRY_TYPE_CREATE_TASK_REQUEST value
             */
            APILogEntry.EntryType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ENTRY_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "ENTRY_TYPE_CREATE_TASK_REQUEST"] = 1;
                return values;
            })();
    
            return APILogEntry;
        })();
    
        infinitusapi.CreateTaskRequestLogEntry = (function() {
    
            /**
             * Properties of a CreateTaskRequestLogEntry.
             * @memberof infinitusapi
             * @interface ICreateTaskRequestLogEntry
             * @property {infinitusapi.CreateTasksRequest.ITasksMessage|null} [apiRequest] CreateTaskRequestLogEntry apiRequest
             * @property {infinitusapi.CreateTasksResponse.IDataMessage|null} [apiResponse] CreateTaskRequestLogEntry apiResponse
             */
    
            /**
             * Constructs a new CreateTaskRequestLogEntry.
             * @memberof infinitusapi
             * @classdesc Represents a CreateTaskRequestLogEntry.
             * @implements ICreateTaskRequestLogEntry
             * @constructor
             * @param {infinitusapi.ICreateTaskRequestLogEntry=} [properties] Properties to set
             */
            function CreateTaskRequestLogEntry(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateTaskRequestLogEntry apiRequest.
             * @member {infinitusapi.CreateTasksRequest.ITasksMessage|null|undefined} apiRequest
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @instance
             */
            CreateTaskRequestLogEntry.prototype.apiRequest = null;
    
            /**
             * CreateTaskRequestLogEntry apiResponse.
             * @member {infinitusapi.CreateTasksResponse.IDataMessage|null|undefined} apiResponse
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @instance
             */
            CreateTaskRequestLogEntry.prototype.apiResponse = null;
    
            /**
             * Creates a new CreateTaskRequestLogEntry instance using the specified properties.
             * @function create
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {infinitusapi.ICreateTaskRequestLogEntry=} [properties] Properties to set
             * @returns {infinitusapi.CreateTaskRequestLogEntry} CreateTaskRequestLogEntry instance
             */
            CreateTaskRequestLogEntry.create = function create(properties) {
                return new CreateTaskRequestLogEntry(properties);
            };
    
            /**
             * Encodes the specified CreateTaskRequestLogEntry message. Does not implicitly {@link infinitusapi.CreateTaskRequestLogEntry.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {infinitusapi.ICreateTaskRequestLogEntry} message CreateTaskRequestLogEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateTaskRequestLogEntry.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.apiRequest != null && Object.hasOwnProperty.call(message, "apiRequest"))
                    $root.infinitusapi.CreateTasksRequest.TasksMessage.encode(message.apiRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.apiResponse != null && Object.hasOwnProperty.call(message, "apiResponse"))
                    $root.infinitusapi.CreateTasksResponse.DataMessage.encode(message.apiResponse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CreateTaskRequestLogEntry message, length delimited. Does not implicitly {@link infinitusapi.CreateTaskRequestLogEntry.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {infinitusapi.ICreateTaskRequestLogEntry} message CreateTaskRequestLogEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateTaskRequestLogEntry.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateTaskRequestLogEntry message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.CreateTaskRequestLogEntry} CreateTaskRequestLogEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateTaskRequestLogEntry.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CreateTaskRequestLogEntry();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.apiRequest = $root.infinitusapi.CreateTasksRequest.TasksMessage.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.apiResponse = $root.infinitusapi.CreateTasksResponse.DataMessage.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateTaskRequestLogEntry message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.CreateTaskRequestLogEntry} CreateTaskRequestLogEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateTaskRequestLogEntry.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateTaskRequestLogEntry message.
             * @function verify
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateTaskRequestLogEntry.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.apiRequest != null && message.hasOwnProperty("apiRequest")) {
                    var error = $root.infinitusapi.CreateTasksRequest.TasksMessage.verify(message.apiRequest);
                    if (error)
                        return "apiRequest." + error;
                }
                if (message.apiResponse != null && message.hasOwnProperty("apiResponse")) {
                    var error = $root.infinitusapi.CreateTasksResponse.DataMessage.verify(message.apiResponse);
                    if (error)
                        return "apiResponse." + error;
                }
                return null;
            };
    
            /**
             * Creates a CreateTaskRequestLogEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.CreateTaskRequestLogEntry} CreateTaskRequestLogEntry
             */
            CreateTaskRequestLogEntry.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.CreateTaskRequestLogEntry)
                    return object;
                var message = new $root.infinitusapi.CreateTaskRequestLogEntry();
                if (object.apiRequest != null) {
                    if (typeof object.apiRequest !== "object")
                        throw TypeError(".infinitusapi.CreateTaskRequestLogEntry.apiRequest: object expected");
                    message.apiRequest = $root.infinitusapi.CreateTasksRequest.TasksMessage.fromObject(object.apiRequest);
                }
                if (object.apiResponse != null) {
                    if (typeof object.apiResponse !== "object")
                        throw TypeError(".infinitusapi.CreateTaskRequestLogEntry.apiResponse: object expected");
                    message.apiResponse = $root.infinitusapi.CreateTasksResponse.DataMessage.fromObject(object.apiResponse);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateTaskRequestLogEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {infinitusapi.CreateTaskRequestLogEntry} message CreateTaskRequestLogEntry
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateTaskRequestLogEntry.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.apiRequest = null;
                    object.apiResponse = null;
                }
                if (message.apiRequest != null && message.hasOwnProperty("apiRequest"))
                    object.apiRequest = $root.infinitusapi.CreateTasksRequest.TasksMessage.toObject(message.apiRequest, options);
                if (message.apiResponse != null && message.hasOwnProperty("apiResponse"))
                    object.apiResponse = $root.infinitusapi.CreateTasksResponse.DataMessage.toObject(message.apiResponse, options);
                return object;
            };
    
            /**
             * Converts this CreateTaskRequestLogEntry to JSON.
             * @function toJSON
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateTaskRequestLogEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CreateTaskRequestLogEntry
             * @function getTypeUrl
             * @memberof infinitusapi.CreateTaskRequestLogEntry
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateTaskRequestLogEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.CreateTaskRequestLogEntry";
            };
    
            return CreateTaskRequestLogEntry;
        })();
    
        /**
         * INFAppealsNotificationMethod enum.
         * @name infinitusapi.INFAppealsNotificationMethod
         * @enum {number}
         * @property {number} INF_APPEALS_NOTIFICATION_METHOD_UNKNOWN=0 INF_APPEALS_NOTIFICATION_METHOD_UNKNOWN value
         * @property {number} INF_APPEALS_NOTIFICATION_METHOD_PHONE=1 INF_APPEALS_NOTIFICATION_METHOD_PHONE value
         * @property {number} INF_APPEALS_NOTIFICATION_METHOD_FAX=2 INF_APPEALS_NOTIFICATION_METHOD_FAX value
         * @property {number} INF_APPEALS_NOTIFICATION_METHOD_EMAIL=3 INF_APPEALS_NOTIFICATION_METHOD_EMAIL value
         * @property {number} INF_APPEALS_NOTIFICATION_METHOD_WEBSITE=4 INF_APPEALS_NOTIFICATION_METHOD_WEBSITE value
         * @property {number} INF_APPEALS_NOTIFICATION_METHOD_NOT_APPLICABLE=5 INF_APPEALS_NOTIFICATION_METHOD_NOT_APPLICABLE value
         */
        infinitusapi.INFAppealsNotificationMethod = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_APPEALS_NOTIFICATION_METHOD_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_APPEALS_NOTIFICATION_METHOD_PHONE"] = 1;
            values[valuesById[2] = "INF_APPEALS_NOTIFICATION_METHOD_FAX"] = 2;
            values[valuesById[3] = "INF_APPEALS_NOTIFICATION_METHOD_EMAIL"] = 3;
            values[valuesById[4] = "INF_APPEALS_NOTIFICATION_METHOD_WEBSITE"] = 4;
            values[valuesById[5] = "INF_APPEALS_NOTIFICATION_METHOD_NOT_APPLICABLE"] = 5;
            return values;
        })();
    
        /**
         * INFAppealsRequiredDocuments enum.
         * @name infinitusapi.INFAppealsRequiredDocuments
         * @enum {number}
         * @property {number} INF_APPEALS_REQUIRED_DOCUMENTS_UNKNOWN=0 INF_APPEALS_REQUIRED_DOCUMENTS_UNKNOWN value
         * @property {number} INF_APPEALS_REQUIRED_DOCUMENTS_NOT_APPLICABLE=1 INF_APPEALS_REQUIRED_DOCUMENTS_NOT_APPLICABLE value
         * @property {number} INF_APPEALS_REQUIRED_DOCUMENTS_DENIAL_LETTER=2 INF_APPEALS_REQUIRED_DOCUMENTS_DENIAL_LETTER value
         * @property {number} INF_APPEALS_REQUIRED_DOCUMENTS_LETTER_OF_MEDICAL_NECESSITY=3 INF_APPEALS_REQUIRED_DOCUMENTS_LETTER_OF_MEDICAL_NECESSITY value
         * @property {number} INF_APPEALS_REQUIRED_DOCUMENTS_NONE=4 INF_APPEALS_REQUIRED_DOCUMENTS_NONE value
         */
        infinitusapi.INFAppealsRequiredDocuments = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_APPEALS_REQUIRED_DOCUMENTS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_APPEALS_REQUIRED_DOCUMENTS_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_APPEALS_REQUIRED_DOCUMENTS_DENIAL_LETTER"] = 2;
            values[valuesById[3] = "INF_APPEALS_REQUIRED_DOCUMENTS_LETTER_OF_MEDICAL_NECESSITY"] = 3;
            values[valuesById[4] = "INF_APPEALS_REQUIRED_DOCUMENTS_NONE"] = 4;
            return values;
        })();
    
        /**
         * INFBVTaskInputNetworkStatus enum.
         * @name infinitusapi.INFBVTaskInputNetworkStatus
         * @enum {number}
         * @property {number} INF_INPUT_NETWORK_STATUS_UNKNOWN=0 INF_INPUT_NETWORK_STATUS_UNKNOWN value
         * @property {number} INF_INPUT_NETWORK_STATUS_IN_NETWORK=1 INF_INPUT_NETWORK_STATUS_IN_NETWORK value
         * @property {number} INF_INPUT_NETWORK_STATUS_OUT_OF_NETWORK=2 INF_INPUT_NETWORK_STATUS_OUT_OF_NETWORK value
         */
        infinitusapi.INFBVTaskInputNetworkStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_INPUT_NETWORK_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_INPUT_NETWORK_STATUS_IN_NETWORK"] = 1;
            values[valuesById[2] = "INF_INPUT_NETWORK_STATUS_OUT_OF_NETWORK"] = 2;
            return values;
        })();
    
        /**
         * INFBVTaskOutputPlanInfoSiteOfCareNetworkStatus enum.
         * @name infinitusapi.INFBVTaskOutputPlanInfoSiteOfCareNetworkStatus
         * @enum {number}
         * @property {number} INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_UNKNOWN=0 INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_UNKNOWN value
         * @property {number} INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK=1 INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK value
         * @property {number} INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS=2 INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS value
         * @property {number} INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_OUT_OF_NETWORK=3 INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_OUT_OF_NETWORK value
         */
        infinitusapi.INFBVTaskOutputPlanInfoSiteOfCareNetworkStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK"] = 1;
            values[valuesById[2] = "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS"] = 2;
            values[valuesById[3] = "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_OUT_OF_NETWORK"] = 3;
            return values;
        })();
    
        /**
         * INFBuyAndBillAvailability enum.
         * @name infinitusapi.INFBuyAndBillAvailability
         * @enum {number}
         * @property {number} INF_BUY_AND_BILL_AVAILABILITY_UNKNOWN=0 INF_BUY_AND_BILL_AVAILABILITY_UNKNOWN value
         * @property {number} INF_BUY_AND_BILL_AVAILABILITY_NOT_APPLICABLE=1 INF_BUY_AND_BILL_AVAILABILITY_NOT_APPLICABLE value
         * @property {number} INF_BUY_AND_BILL_AVAILABILITY_REQUIRED=2 INF_BUY_AND_BILL_AVAILABILITY_REQUIRED value
         * @property {number} INF_BUY_AND_BILL_AVAILABILITY_AVAILABLE=3 INF_BUY_AND_BILL_AVAILABILITY_AVAILABLE value
         * @property {number} INF_BUY_AND_BILL_AVAILABILITY_NOT_AVAILABLE=4 INF_BUY_AND_BILL_AVAILABILITY_NOT_AVAILABLE value
         */
        infinitusapi.INFBuyAndBillAvailability = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_BUY_AND_BILL_AVAILABILITY_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_BUY_AND_BILL_AVAILABILITY_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_BUY_AND_BILL_AVAILABILITY_REQUIRED"] = 2;
            values[valuesById[3] = "INF_BUY_AND_BILL_AVAILABILITY_AVAILABLE"] = 3;
            values[valuesById[4] = "INF_BUY_AND_BILL_AVAILABILITY_NOT_AVAILABLE"] = 4;
            return values;
        })();
    
        /**
         * INFCoordinationOfBenefits enum.
         * @name infinitusapi.INFCoordinationOfBenefits
         * @enum {number}
         * @property {number} INF_COORDINATION_OF_BENEFITS_UNKNOWN=0 INF_COORDINATION_OF_BENEFITS_UNKNOWN value
         * @property {number} INF_COORDINATION_OF_BENEFITS_NOT_APPLICABLE=1 INF_COORDINATION_OF_BENEFITS_NOT_APPLICABLE value
         * @property {number} INF_COORDINATION_OF_BENEFITS_SUPPLEMENT=2 INF_COORDINATION_OF_BENEFITS_SUPPLEMENT value
         * @property {number} INF_COORDINATION_OF_BENEFITS_STANDARD=3 INF_COORDINATION_OF_BENEFITS_STANDARD value
         * @property {number} INF_COORDINATION_OF_BENEFITS_NON_DUPLICATION=4 INF_COORDINATION_OF_BENEFITS_NON_DUPLICATION value
         * @property {number} INF_COORDINATION_OF_BENEFITS_COME_OUT_WHOLE=5 INF_COORDINATION_OF_BENEFITS_COME_OUT_WHOLE value
         */
        infinitusapi.INFCoordinationOfBenefits = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_COORDINATION_OF_BENEFITS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_COORDINATION_OF_BENEFITS_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_COORDINATION_OF_BENEFITS_SUPPLEMENT"] = 2;
            values[valuesById[3] = "INF_COORDINATION_OF_BENEFITS_STANDARD"] = 3;
            values[valuesById[4] = "INF_COORDINATION_OF_BENEFITS_NON_DUPLICATION"] = 4;
            values[valuesById[5] = "INF_COORDINATION_OF_BENEFITS_COME_OUT_WHOLE"] = 5;
            return values;
        })();
    
        /**
         * INFCreateTaskErrorCode enum.
         * @name infinitusapi.INFCreateTaskErrorCode
         * @enum {number}
         * @property {number} INF_CREATE_TASK_ERROR_CODE_INVALID_INPUT=0 INF_CREATE_TASK_ERROR_CODE_INVALID_INPUT value
         * @property {number} INF_CREATE_TASK_ERROR_CODE_MISSING_INPUT=1 INF_CREATE_TASK_ERROR_CODE_MISSING_INPUT value
         * @property {number} INF_CREATE_TASK_ERROR_CODE_CAPACITY_REACHED=2 INF_CREATE_TASK_ERROR_CODE_CAPACITY_REACHED value
         * @property {number} INF_CREATE_TASK_ERROR_CODE_DUPLICATE_TASK=3 INF_CREATE_TASK_ERROR_CODE_DUPLICATE_TASK value
         * @property {number} INF_CREATE_TASK_ERROR_CODE_INTEGRATION_TEST_NO_OUTPUTS_AVAILABLE=4 INF_CREATE_TASK_ERROR_CODE_INTEGRATION_TEST_NO_OUTPUTS_AVAILABLE value
         * @property {number} INF_CREATE_TASK_ERROR_CODE_INTERNAL_SERVER_ERROR=5 INF_CREATE_TASK_ERROR_CODE_INTERNAL_SERVER_ERROR value
         * @property {number} INF_CREATE_TASK_ERROR_CODE_BUNDLE_TASK_REJECTION=6 INF_CREATE_TASK_ERROR_CODE_BUNDLE_TASK_REJECTION value
         */
        infinitusapi.INFCreateTaskErrorCode = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_CREATE_TASK_ERROR_CODE_INVALID_INPUT"] = 0;
            values[valuesById[1] = "INF_CREATE_TASK_ERROR_CODE_MISSING_INPUT"] = 1;
            values[valuesById[2] = "INF_CREATE_TASK_ERROR_CODE_CAPACITY_REACHED"] = 2;
            values[valuesById[3] = "INF_CREATE_TASK_ERROR_CODE_DUPLICATE_TASK"] = 3;
            values[valuesById[4] = "INF_CREATE_TASK_ERROR_CODE_INTEGRATION_TEST_NO_OUTPUTS_AVAILABLE"] = 4;
            values[valuesById[5] = "INF_CREATE_TASK_ERROR_CODE_INTERNAL_SERVER_ERROR"] = 5;
            values[valuesById[6] = "INF_CREATE_TASK_ERROR_CODE_BUNDLE_TASK_REJECTION"] = 6;
            return values;
        })();
    
        /**
         * INFCurrentPlanPriority enum.
         * @name infinitusapi.INFCurrentPlanPriority
         * @enum {number}
         * @property {number} INF_CURRENT_PLAN_PRIORITY_UNKNOWN=0 INF_CURRENT_PLAN_PRIORITY_UNKNOWN value
         * @property {number} INF_CURRENT_PLAN_PRIORITY_NOT_APPLICABLE=1 INF_CURRENT_PLAN_PRIORITY_NOT_APPLICABLE value
         * @property {number} INF_CURRENT_PLAN_PRIORITY_PRIMARY=2 INF_CURRENT_PLAN_PRIORITY_PRIMARY value
         * @property {number} INF_CURRENT_PLAN_PRIORITY_SECONDARY=3 INF_CURRENT_PLAN_PRIORITY_SECONDARY value
         * @property {number} INF_CURRENT_PLAN_PRIORITY_TERTIARY=4 INF_CURRENT_PLAN_PRIORITY_TERTIARY value
         */
        infinitusapi.INFCurrentPlanPriority = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_CURRENT_PLAN_PRIORITY_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_CURRENT_PLAN_PRIORITY_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_CURRENT_PLAN_PRIORITY_PRIMARY"] = 2;
            values[valuesById[3] = "INF_CURRENT_PLAN_PRIORITY_SECONDARY"] = 3;
            values[valuesById[4] = "INF_CURRENT_PLAN_PRIORITY_TERTIARY"] = 4;
            return values;
        })();
    
        /**
         * INFCustomerWebhookStatus enum.
         * @name infinitusapi.INFCustomerWebhookStatus
         * @enum {number}
         * @property {number} INF_CUSTOMER_WEBHOOK_STATUS_UNKNOWN=0 INF_CUSTOMER_WEBHOOK_STATUS_UNKNOWN value
         * @property {number} INF_CUSTOMER_WEBHOOK_STATUS_SUCCESS=1 INF_CUSTOMER_WEBHOOK_STATUS_SUCCESS value
         * @property {number} INF_CUSTOMER_WEBHOOK_STATUS_FAILURE=2 INF_CUSTOMER_WEBHOOK_STATUS_FAILURE value
         */
        infinitusapi.INFCustomerWebhookStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_CUSTOMER_WEBHOOK_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_CUSTOMER_WEBHOOK_STATUS_SUCCESS"] = 1;
            values[valuesById[2] = "INF_CUSTOMER_WEBHOOK_STATUS_FAILURE"] = 2;
            return values;
        })();
    
        /**
         * INFDaysSupply enum.
         * @name infinitusapi.INFDaysSupply
         * @enum {number}
         * @property {number} INF_DAYS_SUPPLY_UNKNOWN=0 INF_DAYS_SUPPLY_UNKNOWN value
         * @property {number} INF_DAYS_SUPPLY_30_DAYS=1 INF_DAYS_SUPPLY_30_DAYS value
         * @property {number} INF_DAYS_SUPPLY_90_DAYS=2 INF_DAYS_SUPPLY_90_DAYS value
         * @property {number} INF_DAYS_SUPPLY_60_DAYS=3 INF_DAYS_SUPPLY_60_DAYS value
         */
        infinitusapi.INFDaysSupply = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_DAYS_SUPPLY_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_DAYS_SUPPLY_30_DAYS"] = 1;
            values[valuesById[2] = "INF_DAYS_SUPPLY_90_DAYS"] = 2;
            values[valuesById[3] = "INF_DAYS_SUPPLY_60_DAYS"] = 3;
            return values;
        })();
    
        /**
         * INFFacilityType enum.
         * @name infinitusapi.INFFacilityType
         * @enum {number}
         * @property {number} INF_FACILITY_TYPE_UNKNOWN=0 INF_FACILITY_TYPE_UNKNOWN value
         * @property {number} INF_FACILITY_TYPE_SPECIALIST_OFFICE=1 INF_FACILITY_TYPE_SPECIALIST_OFFICE value
         * @property {number} INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT=2 INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT value
         * @property {number} INF_FACILITY_TYPE_PHARMACY=3 INF_FACILITY_TYPE_PHARMACY value
         * @property {number} INF_FACILITY_TYPE_SHIP_TO_HOME=4 INF_FACILITY_TYPE_SHIP_TO_HOME value
         * @property {number} INF_FACILITY_TYPE_INFUSION_CENTER=5 INF_FACILITY_TYPE_INFUSION_CENTER value
         * @property {number} INF_FACILITY_TYPE_AMBULATORY_SURGICAL_CENTER=6 INF_FACILITY_TYPE_AMBULATORY_SURGICAL_CENTER value
         * @property {number} INF_FACILITY_TYPE_HOME_INFUSION=7 INF_FACILITY_TYPE_HOME_INFUSION value
         * @property {number} INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT_OFF_CAMPUS=8 INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT_OFF_CAMPUS value
         * @property {number} INF_FACILITY_TYPE_HOSPITAL_INPATIENT=9 INF_FACILITY_TYPE_HOSPITAL_INPATIENT value
         * @property {number} INF_FACILITY_TYPE_SNF=10 INF_FACILITY_TYPE_SNF value
         * @property {number} INF_FACILITY_TYPE_HOSPICE=11 INF_FACILITY_TYPE_HOSPICE value
         * @property {number} INF_FACILITY_TYPE_IHC=12 INF_FACILITY_TYPE_IHC value
         * @property {number} INF_FACILITY_TYPE_FED_QUALIFIED_HC=13 INF_FACILITY_TYPE_FED_QUALIFIED_HC value
         * @property {number} INF_FACILITY_TYPE_INPATIENT_REHAB=14 INF_FACILITY_TYPE_INPATIENT_REHAB value
         * @property {number} INF_FACILITY_TYPE_OUTPATIENT_REHAB=15 INF_FACILITY_TYPE_OUTPATIENT_REHAB value
         * @property {number} INF_FACILITY_TYPE_PUBLIC_HEALTH_CLINIC=16 INF_FACILITY_TYPE_PUBLIC_HEALTH_CLINIC value
         * @property {number} INF_FACILITY_TYPE_RURAL_HEALTH_CLINIC=17 INF_FACILITY_TYPE_RURAL_HEALTH_CLINIC value
         * @property {number} INF_FACILITY_TYPE_DIAGNOSTIC_TEST_LAB=18 INF_FACILITY_TYPE_DIAGNOSTIC_TEST_LAB value
         */
        infinitusapi.INFFacilityType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_FACILITY_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_FACILITY_TYPE_SPECIALIST_OFFICE"] = 1;
            values[valuesById[2] = "INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT"] = 2;
            values[valuesById[3] = "INF_FACILITY_TYPE_PHARMACY"] = 3;
            values[valuesById[4] = "INF_FACILITY_TYPE_SHIP_TO_HOME"] = 4;
            values[valuesById[5] = "INF_FACILITY_TYPE_INFUSION_CENTER"] = 5;
            values[valuesById[6] = "INF_FACILITY_TYPE_AMBULATORY_SURGICAL_CENTER"] = 6;
            values[valuesById[7] = "INF_FACILITY_TYPE_HOME_INFUSION"] = 7;
            values[valuesById[8] = "INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT_OFF_CAMPUS"] = 8;
            values[valuesById[9] = "INF_FACILITY_TYPE_HOSPITAL_INPATIENT"] = 9;
            values[valuesById[10] = "INF_FACILITY_TYPE_SNF"] = 10;
            values[valuesById[11] = "INF_FACILITY_TYPE_HOSPICE"] = 11;
            values[valuesById[12] = "INF_FACILITY_TYPE_IHC"] = 12;
            values[valuesById[13] = "INF_FACILITY_TYPE_FED_QUALIFIED_HC"] = 13;
            values[valuesById[14] = "INF_FACILITY_TYPE_INPATIENT_REHAB"] = 14;
            values[valuesById[15] = "INF_FACILITY_TYPE_OUTPATIENT_REHAB"] = 15;
            values[valuesById[16] = "INF_FACILITY_TYPE_PUBLIC_HEALTH_CLINIC"] = 16;
            values[valuesById[17] = "INF_FACILITY_TYPE_RURAL_HEALTH_CLINIC"] = 17;
            values[valuesById[18] = "INF_FACILITY_TYPE_DIAGNOSTIC_TEST_LAB"] = 18;
            return values;
        })();
    
        /**
         * INFFailureReason enum.
         * @name infinitusapi.INFFailureReason
         * @enum {number}
         * @property {number} INF_FAILURE_REASON_UNKNOWN=0 INF_FAILURE_REASON_UNKNOWN value
         * @property {number} INF_FAILURE_REASON_BAD_DATA=1 INF_FAILURE_REASON_BAD_DATA value
         * @property {number} INF_FAILURE_REASON_PAYER_UNREACHABLE=2 INF_FAILURE_REASON_PAYER_UNREACHABLE value
         * @property {number} INF_FAILURE_REASON_PAYER_REFUSED_THIRD_PARTY=3 INF_FAILURE_REASON_PAYER_REFUSED_THIRD_PARTY value
         * @property {number} INF_FAILURE_REASON_CANCELLED_BY_CUSTOMER=4 INF_FAILURE_REASON_CANCELLED_BY_CUSTOMER value
         * @property {number} INF_FAILURE_REASON_TASK_EXPIRED=5 INF_FAILURE_REASON_TASK_EXPIRED value
         * @property {number} INF_FAILURE_REASON_OTHER=6 INF_FAILURE_REASON_OTHER value
         * @property {number} INF_FAILURE_REASON_PRESCRIBER_UNREACHABLE=7 INF_FAILURE_REASON_PRESCRIBER_UNREACHABLE value
         */
        infinitusapi.INFFailureReason = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_FAILURE_REASON_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_FAILURE_REASON_BAD_DATA"] = 1;
            values[valuesById[2] = "INF_FAILURE_REASON_PAYER_UNREACHABLE"] = 2;
            values[valuesById[3] = "INF_FAILURE_REASON_PAYER_REFUSED_THIRD_PARTY"] = 3;
            values[valuesById[4] = "INF_FAILURE_REASON_CANCELLED_BY_CUSTOMER"] = 4;
            values[valuesById[5] = "INF_FAILURE_REASON_TASK_EXPIRED"] = 5;
            values[valuesById[6] = "INF_FAILURE_REASON_OTHER"] = 6;
            values[valuesById[7] = "INF_FAILURE_REASON_PRESCRIBER_UNREACHABLE"] = 7;
            return values;
        })();
    
        /**
         * INFFormularyExceptionNotificationMethod enum.
         * @name infinitusapi.INFFormularyExceptionNotificationMethod
         * @enum {number}
         * @property {number} INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_UNKNOWN=0 INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_UNKNOWN value
         * @property {number} INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_PHONE=1 INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_PHONE value
         * @property {number} INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_FAX=2 INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_FAX value
         * @property {number} INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_EMAIL=3 INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_EMAIL value
         * @property {number} INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_WEBSITE=4 INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_WEBSITE value
         * @property {number} INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_MAIL=5 INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_MAIL value
         * @property {number} INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_NOT_APPLICABLE=6 INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_NOT_APPLICABLE value
         */
        infinitusapi.INFFormularyExceptionNotificationMethod = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_PHONE"] = 1;
            values[valuesById[2] = "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_FAX"] = 2;
            values[valuesById[3] = "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_EMAIL"] = 3;
            values[valuesById[4] = "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_WEBSITE"] = 4;
            values[valuesById[5] = "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_MAIL"] = 5;
            values[valuesById[6] = "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_NOT_APPLICABLE"] = 6;
            return values;
        })();
    
        /**
         * INFFormularyExceptionStatus enum.
         * @name infinitusapi.INFFormularyExceptionStatus
         * @enum {number}
         * @property {number} INF_FORMULARY_EXCEPTION_STATUS_UNKNOWN=0 INF_FORMULARY_EXCEPTION_STATUS_UNKNOWN value
         * @property {number} INF_FORMULARY_EXCEPTION_STATUS_ON_FILE=1 INF_FORMULARY_EXCEPTION_STATUS_ON_FILE value
         * @property {number} INF_FORMULARY_EXCEPTION_STATUS_NOT_ON_FILE=2 INF_FORMULARY_EXCEPTION_STATUS_NOT_ON_FILE value
         * @property {number} INF_FORMULARY_EXCEPTION_STATUS_NOT_APPLICABLE=3 INF_FORMULARY_EXCEPTION_STATUS_NOT_APPLICABLE value
         */
        infinitusapi.INFFormularyExceptionStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_FORMULARY_EXCEPTION_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_FORMULARY_EXCEPTION_STATUS_ON_FILE"] = 1;
            values[valuesById[2] = "INF_FORMULARY_EXCEPTION_STATUS_NOT_ON_FILE"] = 2;
            values[valuesById[3] = "INF_FORMULARY_EXCEPTION_STATUS_NOT_APPLICABLE"] = 3;
            return values;
        })();
    
        /**
         * INFFundingType enum.
         * @name infinitusapi.INFFundingType
         * @enum {number}
         * @property {number} INF_FUNDING_TYPE_UNKNOWN=0 INF_FUNDING_TYPE_UNKNOWN value
         * @property {number} INF_FUNDING_TYPE_NOT_APPLICABLE=1 INF_FUNDING_TYPE_NOT_APPLICABLE value
         * @property {number} INF_FUNDING_TYPE_SELF=2 INF_FUNDING_TYPE_SELF value
         * @property {number} INF_FUNDING_TYPE_FULLY=3 INF_FUNDING_TYPE_FULLY value
         */
        infinitusapi.INFFundingType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_FUNDING_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_FUNDING_TYPE_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_FUNDING_TYPE_SELF"] = 2;
            values[valuesById[3] = "INF_FUNDING_TYPE_FULLY"] = 3;
            return values;
        })();
    
        /**
         * INFLowIncomeSubsidyLevel enum.
         * @name infinitusapi.INFLowIncomeSubsidyLevel
         * @enum {number}
         * @property {number} INF_LOW_INCOME_SUBSIDY_LEVEL_UNKNOWN=0 INF_LOW_INCOME_SUBSIDY_LEVEL_UNKNOWN value
         * @property {number} INF_LOW_INCOME_SUBSIDY_LEVEL_ONE=1 INF_LOW_INCOME_SUBSIDY_LEVEL_ONE value
         * @property {number} INF_LOW_INCOME_SUBSIDY_LEVEL_TWO=2 INF_LOW_INCOME_SUBSIDY_LEVEL_TWO value
         * @property {number} INF_LOW_INCOME_SUBSIDY_LEVEL_THREE=3 INF_LOW_INCOME_SUBSIDY_LEVEL_THREE value
         * @property {number} INF_LOW_INCOME_SUBSIDY_LEVEL_FOUR=4 INF_LOW_INCOME_SUBSIDY_LEVEL_FOUR value
         * @property {number} INF_LOW_INCOME_SUBSIDY_LEVEL_NOT_APPLICABLE=5 INF_LOW_INCOME_SUBSIDY_LEVEL_NOT_APPLICABLE value
         */
        infinitusapi.INFLowIncomeSubsidyLevel = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_LOW_INCOME_SUBSIDY_LEVEL_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_LOW_INCOME_SUBSIDY_LEVEL_ONE"] = 1;
            values[valuesById[2] = "INF_LOW_INCOME_SUBSIDY_LEVEL_TWO"] = 2;
            values[valuesById[3] = "INF_LOW_INCOME_SUBSIDY_LEVEL_THREE"] = 3;
            values[valuesById[4] = "INF_LOW_INCOME_SUBSIDY_LEVEL_FOUR"] = 4;
            values[valuesById[5] = "INF_LOW_INCOME_SUBSIDY_LEVEL_NOT_APPLICABLE"] = 5;
            return values;
        })();
    
        /**
         * INFOtherInsuranceStatus enum.
         * @name infinitusapi.INFOtherInsuranceStatus
         * @enum {number}
         * @property {number} INF_OTHER_INSURANCE_STATUS_UNKNOWN=0 INF_OTHER_INSURANCE_STATUS_UNKNOWN value
         * @property {number} INF_OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B=1 INF_OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B value
         * @property {number} INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY=2 INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY value
         * @property {number} INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY=3 INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY value
         * @property {number} INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY=4 INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY value
         */
        infinitusapi.INFOtherInsuranceStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_OTHER_INSURANCE_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B"] = 1;
            values[valuesById[2] = "INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY"] = 2;
            values[valuesById[3] = "INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY"] = 3;
            values[valuesById[4] = "INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY"] = 4;
            return values;
        })();
    
        /**
         * INFPBMSpecialtyPharmacyRequirements enum.
         * @name infinitusapi.INFPBMSpecialtyPharmacyRequirements
         * @enum {number}
         * @property {number} INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_UNKNOWN=0 INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_UNKNOWN value
         * @property {number} INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_PREFERRED=1 INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_PREFERRED value
         * @property {number} INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_REQUIRED=2 INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_REQUIRED value
         * @property {number} INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_NOT_APPLICABLE=3 INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_NOT_APPLICABLE value
         */
        infinitusapi.INFPBMSpecialtyPharmacyRequirements = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_PREFERRED"] = 1;
            values[valuesById[2] = "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_REQUIRED"] = 2;
            values[valuesById[3] = "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_NOT_APPLICABLE"] = 3;
            return values;
        })();
    
        /**
         * INFPartDCurrentStage enum.
         * @name infinitusapi.INFPartDCurrentStage
         * @enum {number}
         * @property {number} INF_PART_D_STAGE_UNKNOWN=0 INF_PART_D_STAGE_UNKNOWN value
         * @property {number} INF_PART_D_STAGE_INITIAL_COVERAGE=1 INF_PART_D_STAGE_INITIAL_COVERAGE value
         * @property {number} INF_PART_D_STAGE_COVERAGE_GAP=2 INF_PART_D_STAGE_COVERAGE_GAP value
         * @property {number} INF_PART_D_STAGE_CATASTROPHIC=3 INF_PART_D_STAGE_CATASTROPHIC value
         * @property {number} INF_PART_D_STAGE_NOT_APPLICABLE=4 INF_PART_D_STAGE_NOT_APPLICABLE value
         */
        infinitusapi.INFPartDCurrentStage = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PART_D_STAGE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PART_D_STAGE_INITIAL_COVERAGE"] = 1;
            values[valuesById[2] = "INF_PART_D_STAGE_COVERAGE_GAP"] = 2;
            values[valuesById[3] = "INF_PART_D_STAGE_CATASTROPHIC"] = 3;
            values[valuesById[4] = "INF_PART_D_STAGE_NOT_APPLICABLE"] = 4;
            return values;
        })();
    
        /**
         * INFPlaceOfServiceNetworkStatus enum.
         * @name infinitusapi.INFPlaceOfServiceNetworkStatus
         * @enum {number}
         * @property {number} INF_PLACE_OF_SERVICE_NETWORK_STATUS_UNKNOWN=0 INF_PLACE_OF_SERVICE_NETWORK_STATUS_UNKNOWN value
         * @property {number} INF_PLACE_OF_SERVICE_NETWORK_STATUS_IN_NETWORK=1 INF_PLACE_OF_SERVICE_NETWORK_STATUS_IN_NETWORK value
         * @property {number} INF_PLACE_OF_SERVICE_NETWORK_STATUS_OUT_OF_NETWORK=2 INF_PLACE_OF_SERVICE_NETWORK_STATUS_OUT_OF_NETWORK value
         */
        infinitusapi.INFPlaceOfServiceNetworkStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PLACE_OF_SERVICE_NETWORK_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PLACE_OF_SERVICE_NETWORK_STATUS_IN_NETWORK"] = 1;
            values[valuesById[2] = "INF_PLACE_OF_SERVICE_NETWORK_STATUS_OUT_OF_NETWORK"] = 2;
            return values;
        })();
    
        /**
         * INFPlanRenewalMonth enum.
         * @name infinitusapi.INFPlanRenewalMonth
         * @enum {number}
         * @property {number} INF_PLAN_RENEWAL_MONTH_UNKNOWN=0 INF_PLAN_RENEWAL_MONTH_UNKNOWN value
         * @property {number} INF_PLAN_RENEWAL_MONTH_NOT_APPLICABLE=1 INF_PLAN_RENEWAL_MONTH_NOT_APPLICABLE value
         * @property {number} INF_PLAN_RENEWAL_MONTH_JAN=2 INF_PLAN_RENEWAL_MONTH_JAN value
         * @property {number} INF_PLAN_RENEWAL_MONTH_FEB=3 INF_PLAN_RENEWAL_MONTH_FEB value
         * @property {number} INF_PLAN_RENEWAL_MONTH_MAR=4 INF_PLAN_RENEWAL_MONTH_MAR value
         * @property {number} INF_PLAN_RENEWAL_MONTH_APR=5 INF_PLAN_RENEWAL_MONTH_APR value
         * @property {number} INF_PLAN_RENEWAL_MONTH_MAY=6 INF_PLAN_RENEWAL_MONTH_MAY value
         * @property {number} INF_PLAN_RENEWAL_MONTH_JUN=7 INF_PLAN_RENEWAL_MONTH_JUN value
         * @property {number} INF_PLAN_RENEWAL_MONTH_JUL=8 INF_PLAN_RENEWAL_MONTH_JUL value
         * @property {number} INF_PLAN_RENEWAL_MONTH_AUG=9 INF_PLAN_RENEWAL_MONTH_AUG value
         * @property {number} INF_PLAN_RENEWAL_MONTH_SEP=10 INF_PLAN_RENEWAL_MONTH_SEP value
         * @property {number} INF_PLAN_RENEWAL_MONTH_OCT=11 INF_PLAN_RENEWAL_MONTH_OCT value
         * @property {number} INF_PLAN_RENEWAL_MONTH_NOV=12 INF_PLAN_RENEWAL_MONTH_NOV value
         * @property {number} INF_PLAN_RENEWAL_MONTH_DEC=13 INF_PLAN_RENEWAL_MONTH_DEC value
         */
        infinitusapi.INFPlanRenewalMonth = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PLAN_RENEWAL_MONTH_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PLAN_RENEWAL_MONTH_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PLAN_RENEWAL_MONTH_JAN"] = 2;
            values[valuesById[3] = "INF_PLAN_RENEWAL_MONTH_FEB"] = 3;
            values[valuesById[4] = "INF_PLAN_RENEWAL_MONTH_MAR"] = 4;
            values[valuesById[5] = "INF_PLAN_RENEWAL_MONTH_APR"] = 5;
            values[valuesById[6] = "INF_PLAN_RENEWAL_MONTH_MAY"] = 6;
            values[valuesById[7] = "INF_PLAN_RENEWAL_MONTH_JUN"] = 7;
            values[valuesById[8] = "INF_PLAN_RENEWAL_MONTH_JUL"] = 8;
            values[valuesById[9] = "INF_PLAN_RENEWAL_MONTH_AUG"] = 9;
            values[valuesById[10] = "INF_PLAN_RENEWAL_MONTH_SEP"] = 10;
            values[valuesById[11] = "INF_PLAN_RENEWAL_MONTH_OCT"] = 11;
            values[valuesById[12] = "INF_PLAN_RENEWAL_MONTH_NOV"] = 12;
            values[valuesById[13] = "INF_PLAN_RENEWAL_MONTH_DEC"] = 13;
            return values;
        })();
    
        /**
         * INFPlanRenewalType enum.
         * @name infinitusapi.INFPlanRenewalType
         * @enum {number}
         * @property {number} INF_PLAN_RENEWAL_TYPE_UNKNOWN=0 INF_PLAN_RENEWAL_TYPE_UNKNOWN value
         * @property {number} INF_PLAN_RENEWAL_TYPE_NOT_APPLICABLE=1 INF_PLAN_RENEWAL_TYPE_NOT_APPLICABLE value
         * @property {number} INF_PLAN_RENEWAL_TYPE_CALENDAR_YEARLY=2 INF_PLAN_RENEWAL_TYPE_CALENDAR_YEARLY value
         * @property {number} INF_PLAN_RENEWAL_TYPE_CALENDAR_QUARTERLY=3 INF_PLAN_RENEWAL_TYPE_CALENDAR_QUARTERLY value
         * @property {number} INF_PLAN_RENEWAL_TYPE_CALENDAR_MONTHLY=4 INF_PLAN_RENEWAL_TYPE_CALENDAR_MONTHLY value
         * @property {number} INF_PLAN_RENEWAL_TYPE_BENEFIT_YEARLY=5 INF_PLAN_RENEWAL_TYPE_BENEFIT_YEARLY value
         * @property {number} INF_PLAN_RENEWAL_TYPE_BENEFIT_QUARTERLY=6 INF_PLAN_RENEWAL_TYPE_BENEFIT_QUARTERLY value
         * @property {number} INF_PLAN_RENEWAL_TYPE_BENEFIT_MONTHLY=7 INF_PLAN_RENEWAL_TYPE_BENEFIT_MONTHLY value
         */
        infinitusapi.INFPlanRenewalType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PLAN_RENEWAL_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PLAN_RENEWAL_TYPE_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PLAN_RENEWAL_TYPE_CALENDAR_YEARLY"] = 2;
            values[valuesById[3] = "INF_PLAN_RENEWAL_TYPE_CALENDAR_QUARTERLY"] = 3;
            values[valuesById[4] = "INF_PLAN_RENEWAL_TYPE_CALENDAR_MONTHLY"] = 4;
            values[valuesById[5] = "INF_PLAN_RENEWAL_TYPE_BENEFIT_YEARLY"] = 5;
            values[valuesById[6] = "INF_PLAN_RENEWAL_TYPE_BENEFIT_QUARTERLY"] = 6;
            values[valuesById[7] = "INF_PLAN_RENEWAL_TYPE_BENEFIT_MONTHLY"] = 7;
            return values;
        })();
    
        /**
         * INFPlanType enum.
         * @name infinitusapi.INFPlanType
         * @enum {number}
         * @property {number} INF_PLAN_TYPE_UNKNOWN=0 INF_PLAN_TYPE_UNKNOWN value
         * @property {number} INF_PLAN_TYPE_NOT_APPLICABLE=1 INF_PLAN_TYPE_NOT_APPLICABLE value
         * @property {number} INF_PLAN_TYPE_EPO=2 INF_PLAN_TYPE_EPO value
         * @property {number} INF_PLAN_TYPE_HMO=3 INF_PLAN_TYPE_HMO value
         * @property {number} INF_PLAN_TYPE_INDEMNITY=4 INF_PLAN_TYPE_INDEMNITY value
         * @property {number} INF_PLAN_TYPE_MEDICAID=5 INF_PLAN_TYPE_MEDICAID value
         * @property {number} INF_PLAN_TYPE_OAP=6 INF_PLAN_TYPE_OAP value
         * @property {number} INF_PLAN_TYPE_POS=7 INF_PLAN_TYPE_POS value
         * @property {number} INF_PLAN_TYPE_PPO=8 INF_PLAN_TYPE_PPO value
         * @property {number} INF_PLAN_TYPE_TRICARE=9 INF_PLAN_TYPE_TRICARE value
         * @property {number} INF_PLAN_TYPE_MEDICARE_SUPPLEMENT=10 INF_PLAN_TYPE_MEDICARE_SUPPLEMENT value
         * @property {number} INF_PLAN_TYPE_HMO_POS=11 INF_PLAN_TYPE_HMO_POS value
         * @property {number} INF_PLAN_TYPE_HDHP=12 INF_PLAN_TYPE_HDHP value
         */
        infinitusapi.INFPlanType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PLAN_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PLAN_TYPE_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PLAN_TYPE_EPO"] = 2;
            values[valuesById[3] = "INF_PLAN_TYPE_HMO"] = 3;
            values[valuesById[4] = "INF_PLAN_TYPE_INDEMNITY"] = 4;
            values[valuesById[5] = "INF_PLAN_TYPE_MEDICAID"] = 5;
            values[valuesById[6] = "INF_PLAN_TYPE_OAP"] = 6;
            values[valuesById[7] = "INF_PLAN_TYPE_POS"] = 7;
            values[valuesById[8] = "INF_PLAN_TYPE_PPO"] = 8;
            values[valuesById[9] = "INF_PLAN_TYPE_TRICARE"] = 9;
            values[valuesById[10] = "INF_PLAN_TYPE_MEDICARE_SUPPLEMENT"] = 10;
            values[valuesById[11] = "INF_PLAN_TYPE_HMO_POS"] = 11;
            values[valuesById[12] = "INF_PLAN_TYPE_HDHP"] = 12;
            return values;
        })();
    
        /**
         * INFPolicyHolderRelationship enum.
         * @name infinitusapi.INFPolicyHolderRelationship
         * @enum {number}
         * @property {number} INF_POLICY_HOLDER_RELATIONSHIP_UNKNOWN=0 INF_POLICY_HOLDER_RELATIONSHIP_UNKNOWN value
         * @property {number} INF_POLICY_HOLDER_RELATIONSHIP_SELF=1 INF_POLICY_HOLDER_RELATIONSHIP_SELF value
         * @property {number} INF_POLICY_HOLDER_RELATIONSHIP_CHILD=2 INF_POLICY_HOLDER_RELATIONSHIP_CHILD value
         * @property {number} INF_POLICY_HOLDER_RELATIONSHIP_SPOUSE=3 INF_POLICY_HOLDER_RELATIONSHIP_SPOUSE value
         * @property {number} INF_POLICY_HOLDER_RELATIONSHIP_OTHER=4 INF_POLICY_HOLDER_RELATIONSHIP_OTHER value
         * @property {number} INF_POLICY_HOLDER_RELATIONSHIP_NOT_APPLICABLE=5 INF_POLICY_HOLDER_RELATIONSHIP_NOT_APPLICABLE value
         */
        infinitusapi.INFPolicyHolderRelationship = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_POLICY_HOLDER_RELATIONSHIP_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_POLICY_HOLDER_RELATIONSHIP_SELF"] = 1;
            values[valuesById[2] = "INF_POLICY_HOLDER_RELATIONSHIP_CHILD"] = 2;
            values[valuesById[3] = "INF_POLICY_HOLDER_RELATIONSHIP_SPOUSE"] = 3;
            values[valuesById[4] = "INF_POLICY_HOLDER_RELATIONSHIP_OTHER"] = 4;
            values[valuesById[5] = "INF_POLICY_HOLDER_RELATIONSHIP_NOT_APPLICABLE"] = 5;
            return values;
        })();
    
        /**
         * INFPolicyType enum.
         * @name infinitusapi.INFPolicyType
         * @enum {number}
         * @property {number} INF_POLICY_TYPE_UNKNOWN=0 INF_POLICY_TYPE_UNKNOWN value
         * @property {number} INF_POLICY_TYPE_NOT_APPLICABLE=1 INF_POLICY_TYPE_NOT_APPLICABLE value
         * @property {number} INF_POLICY_TYPE_OTHER=2 INF_POLICY_TYPE_OTHER value
         * @property {number} INF_POLICY_TYPE_COMMERCIAL=3 INF_POLICY_TYPE_COMMERCIAL value
         * @property {number} INF_POLICY_TYPE_GOVERNMENT_MEDICARE=4 INF_POLICY_TYPE_GOVERNMENT_MEDICARE value
         * @property {number} INF_POLICY_TYPE_GOVERNMENT_MEDICAID=5 INF_POLICY_TYPE_GOVERNMENT_MEDICAID value
         * @property {number} INF_POLICY_TYPE_GOVERNMENT_TRICARE=6 INF_POLICY_TYPE_GOVERNMENT_TRICARE value
         * @property {number} INF_POLICY_TYPE_FEDERAL_EMPLOYEE_PROGRAM=7 INF_POLICY_TYPE_FEDERAL_EMPLOYEE_PROGRAM value
         * @property {number} INF_POLICY_TYPE_MANAGED_MEDICAID=8 INF_POLICY_TYPE_MANAGED_MEDICAID value
         * @property {number} INF_POLICY_TYPE_MEDICARE_ADVANTAGE=9 INF_POLICY_TYPE_MEDICARE_ADVANTAGE value
         * @property {number} INF_POLICY_TYPE_MEDICARE_PDP=10 INF_POLICY_TYPE_MEDICARE_PDP value
         */
        infinitusapi.INFPolicyType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_POLICY_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_POLICY_TYPE_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_POLICY_TYPE_OTHER"] = 2;
            values[valuesById[3] = "INF_POLICY_TYPE_COMMERCIAL"] = 3;
            values[valuesById[4] = "INF_POLICY_TYPE_GOVERNMENT_MEDICARE"] = 4;
            values[valuesById[5] = "INF_POLICY_TYPE_GOVERNMENT_MEDICAID"] = 5;
            values[valuesById[6] = "INF_POLICY_TYPE_GOVERNMENT_TRICARE"] = 6;
            values[valuesById[7] = "INF_POLICY_TYPE_FEDERAL_EMPLOYEE_PROGRAM"] = 7;
            values[valuesById[8] = "INF_POLICY_TYPE_MANAGED_MEDICAID"] = 8;
            values[valuesById[9] = "INF_POLICY_TYPE_MEDICARE_ADVANTAGE"] = 9;
            values[valuesById[10] = "INF_POLICY_TYPE_MEDICARE_PDP"] = 10;
            return values;
        })();
    
        /**
         * INFPracticeNetworkStatus enum.
         * @name infinitusapi.INFPracticeNetworkStatus
         * @enum {number}
         * @property {number} INF_PRACTICE_NETWORK_STATUS_UNKNOWN=0 INF_PRACTICE_NETWORK_STATUS_UNKNOWN value
         * @property {number} INF_PRACTICE_NETWORK_STATUS_NOT_APPLICABLE=1 INF_PRACTICE_NETWORK_STATUS_NOT_APPLICABLE value
         * @property {number} INF_PRACTICE_NETWORK_STATUS_IN_NETWORK=2 INF_PRACTICE_NETWORK_STATUS_IN_NETWORK value
         * @property {number} INF_PRACTICE_NETWORK_STATUS_OUT_OF_NETWORK=3 INF_PRACTICE_NETWORK_STATUS_OUT_OF_NETWORK value
         */
        infinitusapi.INFPracticeNetworkStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRACTICE_NETWORK_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PRACTICE_NETWORK_STATUS_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PRACTICE_NETWORK_STATUS_IN_NETWORK"] = 2;
            values[valuesById[3] = "INF_PRACTICE_NETWORK_STATUS_OUT_OF_NETWORK"] = 3;
            return values;
        })();
    
        /**
         * INFPredeterminationRequirementType enum.
         * @name infinitusapi.INFPredeterminationRequirementType
         * @enum {number}
         * @property {number} INF_PREDETERMINATION_REQUIREMENT_UNKNOWN=0 INF_PREDETERMINATION_REQUIREMENT_UNKNOWN value
         * @property {number} INF_PREDETERMINATION_REQUIREMENT_NOT_APPLICABLE=1 INF_PREDETERMINATION_REQUIREMENT_NOT_APPLICABLE value
         * @property {number} INF_PREDETERMINATION_REQUIREMENT_REQUIRED=2 INF_PREDETERMINATION_REQUIREMENT_REQUIRED value
         * @property {number} INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED=3 INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED value
         * @property {number} INF_PREDETERMINATION_REQUIREMENT_AVAILABLE=4 INF_PREDETERMINATION_REQUIREMENT_AVAILABLE value
         * @property {number} INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE=5 INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE value
         */
        infinitusapi.INFPredeterminationRequirementType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PREDETERMINATION_REQUIREMENT_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PREDETERMINATION_REQUIREMENT_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PREDETERMINATION_REQUIREMENT_REQUIRED"] = 2;
            values[valuesById[3] = "INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED"] = 3;
            values[valuesById[4] = "INF_PREDETERMINATION_REQUIREMENT_AVAILABLE"] = 4;
            values[valuesById[5] = "INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE"] = 5;
            return values;
        })();
    
        /**
         * INFPredeterminationStatus enum.
         * @name infinitusapi.INFPredeterminationStatus
         * @enum {number}
         * @property {number} INF_PREDETERMINATION_STATUS_UNKNOWN=0 INF_PREDETERMINATION_STATUS_UNKNOWN value
         * @property {number} INF_PREDETERMINATION_STATUS_NOT_APPLICABLE=1 INF_PREDETERMINATION_STATUS_NOT_APPLICABLE value
         * @property {number} INF_PREDETERMINATION_STATUS_NOT_ON_FILE=2 INF_PREDETERMINATION_STATUS_NOT_ON_FILE value
         * @property {number} INF_PREDETERMINATION_STATUS_APPROVED=3 INF_PREDETERMINATION_STATUS_APPROVED value
         * @property {number} INF_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR=4 INF_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR value
         * @property {number} INF_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS=5 INF_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS value
         * @property {number} INF_PREDETERMINATION_STATUS_DENIED=6 INF_PREDETERMINATION_STATUS_DENIED value
         * @property {number} INF_PREDETERMINATION_STATUS_EXPIRED=7 INF_PREDETERMINATION_STATUS_EXPIRED value
         * @property {number} INF_PREDETERMINATION_STATUS_FUTURE=8 INF_PREDETERMINATION_STATUS_FUTURE value
         * @property {number} INF_PREDETERMINATION_STATUS_PENDING=9 INF_PREDETERMINATION_STATUS_PENDING value
         * @property {number} INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS=10 INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS value
         * @property {number} INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS=11 INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS value
         */
        infinitusapi.INFPredeterminationStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PREDETERMINATION_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PREDETERMINATION_STATUS_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PREDETERMINATION_STATUS_NOT_ON_FILE"] = 2;
            values[valuesById[3] = "INF_PREDETERMINATION_STATUS_APPROVED"] = 3;
            values[valuesById[4] = "INF_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR"] = 4;
            values[valuesById[5] = "INF_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS"] = 5;
            values[valuesById[6] = "INF_PREDETERMINATION_STATUS_DENIED"] = 6;
            values[valuesById[7] = "INF_PREDETERMINATION_STATUS_EXPIRED"] = 7;
            values[valuesById[8] = "INF_PREDETERMINATION_STATUS_FUTURE"] = 8;
            values[valuesById[9] = "INF_PREDETERMINATION_STATUS_PENDING"] = 9;
            values[valuesById[10] = "INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS"] = 10;
            values[valuesById[11] = "INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS"] = 11;
            return values;
        })();
    
        /**
         * INFPrescriptionTransferTaskOutputOutcome enum.
         * @name infinitusapi.INFPrescriptionTransferTaskOutputOutcome
         * @enum {number}
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_UNKNOWN=0 INF_PRESCRIPTION_TRANSFER_OUTCOME_UNKNOWN value
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_ESCRIPTS=1 INF_PRESCRIPTION_TRANSFER_OUTCOME_ESCRIPTS value
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_FAX=2 INF_PRESCRIPTION_TRANSFER_OUTCOME_FAX value
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_SEE_PATIENT=3 INF_PRESCRIPTION_TRANSFER_OUTCOME_SEE_PATIENT value
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_TALK_TO_PATIENT=4 INF_PRESCRIPTION_TRANSFER_OUTCOME_TALK_TO_PATIENT value
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_NO_ACTIVE_SCRIPTS=5 INF_PRESCRIPTION_TRANSFER_OUTCOME_NO_ACTIVE_SCRIPTS value
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_NEED_VERBAL_TRANSFER=6 INF_PRESCRIPTION_TRANSFER_OUTCOME_NEED_VERBAL_TRANSFER value
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_VOICEMAIL=7 INF_PRESCRIPTION_TRANSFER_OUTCOME_VOICEMAIL value
         * @property {number} INF_PRESCRIPTION_TRANSFER_OUTCOME_OTHER=8 INF_PRESCRIPTION_TRANSFER_OUTCOME_OTHER value
         */
        infinitusapi.INFPrescriptionTransferTaskOutputOutcome = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_ESCRIPTS"] = 1;
            values[valuesById[2] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_FAX"] = 2;
            values[valuesById[3] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_SEE_PATIENT"] = 3;
            values[valuesById[4] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_TALK_TO_PATIENT"] = 4;
            values[valuesById[5] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_NO_ACTIVE_SCRIPTS"] = 5;
            values[valuesById[6] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_NEED_VERBAL_TRANSFER"] = 6;
            values[valuesById[7] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_VOICEMAIL"] = 7;
            values[valuesById[8] = "INF_PRESCRIPTION_TRANSFER_OUTCOME_OTHER"] = 8;
            return values;
        })();
    
        /**
         * INFPriorAuthApprovalLevel enum.
         * @name infinitusapi.INFPriorAuthApprovalLevel
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_APPROVAL_LEVEL_NDC=0 INF_PRIOR_AUTH_APPROVAL_LEVEL_NDC value
         * @property {number} INF_PRIOR_AUTH_APPROVAL_LEVEL_DRUG=1 INF_PRIOR_AUTH_APPROVAL_LEVEL_DRUG value
         * @property {number} INF_PRIOR_AUTH_APPROVAL_LEVEL_NOT_APPLICABLE=2 INF_PRIOR_AUTH_APPROVAL_LEVEL_NOT_APPLICABLE value
         * @property {number} INF_PRIOR_AUTH_APPROVAL_LEVEL_UNKNOWN=3 INF_PRIOR_AUTH_APPROVAL_LEVEL_UNKNOWN value
         */
        infinitusapi.INFPriorAuthApprovalLevel = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_APPROVAL_LEVEL_NDC"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_APPROVAL_LEVEL_DRUG"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_APPROVAL_LEVEL_NOT_APPLICABLE"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_APPROVAL_LEVEL_UNKNOWN"] = 3;
            return values;
        })();
    
        /**
         * INFPriorAuthDenialReason enum.
         * @name infinitusapi.INFPriorAuthDenialReason
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_OTHER=0 INF_PRIOR_AUTH_DENIAL_REASON_OTHER value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_MISSING_CLINICAL_DOCUMENTATION=1 INF_PRIOR_AUTH_DENIAL_REASON_MISSING_CLINICAL_DOCUMENTATION value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_CRITERIA_NOT_MET=2 INF_PRIOR_AUTH_DENIAL_REASON_CRITERIA_NOT_MET value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_LACK_OF_MEDICAL_NECESSITY=3 INF_PRIOR_AUTH_DENIAL_REASON_LACK_OF_MEDICAL_NECESSITY value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_MISSING_LETTER_OF_MEDICAL_NECESSITY=4 INF_PRIOR_AUTH_DENIAL_REASON_MISSING_LETTER_OF_MEDICAL_NECESSITY value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_NON_FORMULARY_DRUG=5 INF_PRIOR_AUTH_DENIAL_REASON_NON_FORMULARY_DRUG value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_STEP_THERAPY_REQUIRED=6 INF_PRIOR_AUTH_DENIAL_REASON_STEP_THERAPY_REQUIRED value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_NON_PREFERRED_TREATMENT=7 INF_PRIOR_AUTH_DENIAL_REASON_NON_PREFERRED_TREATMENT value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_DATE_OF_SERVICE_PASSED=8 INF_PRIOR_AUTH_DENIAL_REASON_DATE_OF_SERVICE_PASSED value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_PROVIDER_NOT_IN_NETWORK=9 INF_PRIOR_AUTH_DENIAL_REASON_PROVIDER_NOT_IN_NETWORK value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_NOT_COVERED_BY_PATIENT_PLAN=10 INF_PRIOR_AUTH_DENIAL_REASON_NOT_COVERED_BY_PATIENT_PLAN value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_PATIENT_PLAN_PROCEDURES_NOT_FOLLOWED=11 INF_PRIOR_AUTH_DENIAL_REASON_PATIENT_PLAN_PROCEDURES_NOT_FOLLOWED value
         * @property {number} INF_PRIOR_AUTH_DENIAL_REASON_NOT_APPLICABLE=12 INF_PRIOR_AUTH_DENIAL_REASON_NOT_APPLICABLE value
         */
        infinitusapi.INFPriorAuthDenialReason = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_DENIAL_REASON_OTHER"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_DENIAL_REASON_MISSING_CLINICAL_DOCUMENTATION"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_DENIAL_REASON_CRITERIA_NOT_MET"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_DENIAL_REASON_LACK_OF_MEDICAL_NECESSITY"] = 3;
            values[valuesById[4] = "INF_PRIOR_AUTH_DENIAL_REASON_MISSING_LETTER_OF_MEDICAL_NECESSITY"] = 4;
            values[valuesById[5] = "INF_PRIOR_AUTH_DENIAL_REASON_NON_FORMULARY_DRUG"] = 5;
            values[valuesById[6] = "INF_PRIOR_AUTH_DENIAL_REASON_STEP_THERAPY_REQUIRED"] = 6;
            values[valuesById[7] = "INF_PRIOR_AUTH_DENIAL_REASON_NON_PREFERRED_TREATMENT"] = 7;
            values[valuesById[8] = "INF_PRIOR_AUTH_DENIAL_REASON_DATE_OF_SERVICE_PASSED"] = 8;
            values[valuesById[9] = "INF_PRIOR_AUTH_DENIAL_REASON_PROVIDER_NOT_IN_NETWORK"] = 9;
            values[valuesById[10] = "INF_PRIOR_AUTH_DENIAL_REASON_NOT_COVERED_BY_PATIENT_PLAN"] = 10;
            values[valuesById[11] = "INF_PRIOR_AUTH_DENIAL_REASON_PATIENT_PLAN_PROCEDURES_NOT_FOLLOWED"] = 11;
            values[valuesById[12] = "INF_PRIOR_AUTH_DENIAL_REASON_NOT_APPLICABLE"] = 12;
            return values;
        })();
    
        /**
         * INFPriorAuthEntityOnFileType enum.
         * @name infinitusapi.INFPriorAuthEntityOnFileType
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PROVIDER=0 INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PROVIDER value
         * @property {number} INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PLACE_OF_SERVICE=1 INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PLACE_OF_SERVICE value
         * @property {number} INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_UNKNOWN=2 INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_UNKNOWN value
         */
        infinitusapi.INFPriorAuthEntityOnFileType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PROVIDER"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PLACE_OF_SERVICE"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_UNKNOWN"] = 2;
            return values;
        })();
    
        /**
         * INFPriorAuthNotificationMethod enum.
         * @name infinitusapi.INFPriorAuthNotificationMethod
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN=0 INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN value
         * @property {number} INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE=1 INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE value
         * @property {number} INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE=2 INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE value
         * @property {number} INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX=3 INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX value
         * @property {number} INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL=4 INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL value
         * @property {number} INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL=5 INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL value
         * @property {number} INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE=6 INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE value
         */
        infinitusapi.INFPriorAuthNotificationMethod = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX"] = 3;
            values[valuesById[4] = "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL"] = 4;
            values[valuesById[5] = "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL"] = 5;
            values[valuesById[6] = "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE"] = 6;
            return values;
        })();
    
        /**
         * INFPriorAuthProductSourcingMethod enum.
         * @name infinitusapi.INFPriorAuthProductSourcingMethod
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_BUY_AND_BILL=0 INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_BUY_AND_BILL value
         * @property {number} INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_SPECIALTY_PHARMACY=1 INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_SPECIALTY_PHARMACY value
         * @property {number} INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_PHARMACY_BENEFIT_MANAGER=2 INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_PHARMACY_BENEFIT_MANAGER value
         * @property {number} INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_NOT_APPLICABLE=3 INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_NOT_APPLICABLE value
         */
        infinitusapi.INFPriorAuthProductSourcingMethod = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_BUY_AND_BILL"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_SPECIALTY_PHARMACY"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_PHARMACY_BENEFIT_MANAGER"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_NOT_APPLICABLE"] = 3;
            return values;
        })();
    
        /**
         * INFPriorAuthRequirementType enum.
         * @name infinitusapi.INFPriorAuthRequirementType
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_REQUIREMENT_UNKNOWN=0 INF_PRIOR_AUTH_REQUIREMENT_UNKNOWN value
         * @property {number} INF_PRIOR_AUTH_REQUIREMENT_PA_FORM=1 INF_PRIOR_AUTH_REQUIREMENT_PA_FORM value
         * @property {number} INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY=2 INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY value
         * @property {number} INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS=3 INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS value
         * @property {number} INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET=4 INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET value
         * @property {number} INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE=5 INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE value
         * @property {number} INF_PRIOR_AUTH_REQUIREMENT_NOT_APPLICABLE=6 INF_PRIOR_AUTH_REQUIREMENT_NOT_APPLICABLE value
         */
        infinitusapi.INFPriorAuthRequirementType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_REQUIREMENT_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_REQUIREMENT_PA_FORM"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS"] = 3;
            values[valuesById[4] = "INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET"] = 4;
            values[valuesById[5] = "INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE"] = 5;
            values[valuesById[6] = "INF_PRIOR_AUTH_REQUIREMENT_NOT_APPLICABLE"] = 6;
            return values;
        })();
    
        /**
         * INFPriorAuthResponsibleOrg enum.
         * @name infinitusapi.INFPriorAuthResponsibleOrg
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_RESPONSIBLE_ORG_UNKNOWN=0 INF_PRIOR_AUTH_RESPONSIBLE_ORG_UNKNOWN value
         * @property {number} INF_PRIOR_AUTH_RESPONSIBLE_ORG_NOT_APPLICABLE=1 INF_PRIOR_AUTH_RESPONSIBLE_ORG_NOT_APPLICABLE value
         * @property {number} INF_PRIOR_AUTH_RESPONSIBLE_ORG_PLAN=2 INF_PRIOR_AUTH_RESPONSIBLE_ORG_PLAN value
         * @property {number} INF_PRIOR_AUTH_RESPONSIBLE_ORG_MEDICAL_GROUP=3 INF_PRIOR_AUTH_RESPONSIBLE_ORG_MEDICAL_GROUP value
         */
        infinitusapi.INFPriorAuthResponsibleOrg = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_RESPONSIBLE_ORG_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_RESPONSIBLE_ORG_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_RESPONSIBLE_ORG_PLAN"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_RESPONSIBLE_ORG_MEDICAL_GROUP"] = 3;
            return values;
        })();
    
        /**
         * INFPriorAuthStatus enum.
         * @name infinitusapi.INFPriorAuthStatus
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_STATUS_UNKNOWN=0 INF_PRIOR_AUTH_STATUS_UNKNOWN value
         * @property {number} INF_PRIOR_AUTH_STATUS_NOT_APPLICABLE=1 INF_PRIOR_AUTH_STATUS_NOT_APPLICABLE value
         * @property {number} INF_PRIOR_AUTH_STATUS_NOT_ON_FILE=2 INF_PRIOR_AUTH_STATUS_NOT_ON_FILE value
         * @property {number} INF_PRIOR_AUTH_STATUS_APPROVED=3 INF_PRIOR_AUTH_STATUS_APPROVED value
         * @property {number} INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR=4 INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR value
         * @property {number} INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS=5 INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS value
         * @property {number} INF_PRIOR_AUTH_STATUS_DENIED=6 INF_PRIOR_AUTH_STATUS_DENIED value
         * @property {number} INF_PRIOR_AUTH_STATUS_EXPIRED=7 INF_PRIOR_AUTH_STATUS_EXPIRED value
         * @property {number} INF_PRIOR_AUTH_STATUS_FUTURE=8 INF_PRIOR_AUTH_STATUS_FUTURE value
         * @property {number} INF_PRIOR_AUTH_STATUS_PENDING=9 INF_PRIOR_AUTH_STATUS_PENDING value
         * @property {number} INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS=10 INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS value
         * @property {number} INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS=11 INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS value
         */
        infinitusapi.INFPriorAuthStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_STATUS_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_STATUS_NOT_ON_FILE"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_STATUS_APPROVED"] = 3;
            values[valuesById[4] = "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR"] = 4;
            values[valuesById[5] = "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS"] = 5;
            values[valuesById[6] = "INF_PRIOR_AUTH_STATUS_DENIED"] = 6;
            values[valuesById[7] = "INF_PRIOR_AUTH_STATUS_EXPIRED"] = 7;
            values[valuesById[8] = "INF_PRIOR_AUTH_STATUS_FUTURE"] = 8;
            values[valuesById[9] = "INF_PRIOR_AUTH_STATUS_PENDING"] = 9;
            values[valuesById[10] = "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS"] = 10;
            values[valuesById[11] = "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS"] = 11;
            return values;
        })();
    
        /**
         * INFPriorAuthSubmissionMethod enum.
         * @name infinitusapi.INFPriorAuthSubmissionMethod
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN=0 INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN value
         * @property {number} INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE=1 INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE value
         * @property {number} INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE=2 INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE value
         * @property {number} INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX=3 INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX value
         * @property {number} INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL=4 INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL value
         * @property {number} INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE=5 INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE value
         * @property {number} INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL=6 INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL value
         * @property {number} INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION=7 INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION value
         */
        infinitusapi.INFPriorAuthSubmissionMethod = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX"] = 3;
            values[valuesById[4] = "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL"] = 4;
            values[valuesById[5] = "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE"] = 5;
            values[valuesById[6] = "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL"] = 6;
            values[valuesById[7] = "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION"] = 7;
            return values;
        })();
    
        /**
         * INFPriorAuthType enum.
         * @name infinitusapi.INFPriorAuthType
         * @enum {number}
         * @property {number} INF_PRIOR_AUTH_TYPE_UNKNOWN=0 INF_PRIOR_AUTH_TYPE_UNKNOWN value
         * @property {number} INF_PRIOR_AUTH_TYPE_PRIOR_AUTHORIZATION=1 INF_PRIOR_AUTH_TYPE_PRIOR_AUTHORIZATION value
         * @property {number} INF_PRIOR_AUTH_TYPE_PRECERTIFICATION=2 INF_PRIOR_AUTH_TYPE_PRECERTIFICATION value
         * @property {number} INF_PRIOR_AUTH_TYPE_PREDETERMINATION=3 INF_PRIOR_AUTH_TYPE_PREDETERMINATION value
         * @property {number} INF_PRIOR_AUTH_TYPE_FORMULARY_EXCEPTION=4 INF_PRIOR_AUTH_TYPE_FORMULARY_EXCEPTION value
         * @property {number} INF_PRIOR_AUTH_TYPE_TIER_EXCEPTION=5 INF_PRIOR_AUTH_TYPE_TIER_EXCEPTION value
         * @property {number} INF_PRIOR_AUTH_TYPE_NOT_APPLICABLE=6 INF_PRIOR_AUTH_TYPE_NOT_APPLICABLE value
         * @property {number} INF_PRIOR_AUTH_TYPE_NONE=7 INF_PRIOR_AUTH_TYPE_NONE value
         */
        infinitusapi.INFPriorAuthType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PRIOR_AUTH_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PRIOR_AUTH_TYPE_PRIOR_AUTHORIZATION"] = 1;
            values[valuesById[2] = "INF_PRIOR_AUTH_TYPE_PRECERTIFICATION"] = 2;
            values[valuesById[3] = "INF_PRIOR_AUTH_TYPE_PREDETERMINATION"] = 3;
            values[valuesById[4] = "INF_PRIOR_AUTH_TYPE_FORMULARY_EXCEPTION"] = 4;
            values[valuesById[5] = "INF_PRIOR_AUTH_TYPE_TIER_EXCEPTION"] = 5;
            values[valuesById[6] = "INF_PRIOR_AUTH_TYPE_NOT_APPLICABLE"] = 6;
            values[valuesById[7] = "INF_PRIOR_AUTH_TYPE_NONE"] = 7;
            return values;
        })();
    
        /**
         * INFProviderNetworkStatus enum.
         * @name infinitusapi.INFProviderNetworkStatus
         * @enum {number}
         * @property {number} INF_PROVIDER_NETWORK_STATUS_UNKNOWN=0 INF_PROVIDER_NETWORK_STATUS_UNKNOWN value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_NOT_APPLICABLE=1 INF_PROVIDER_NETWORK_STATUS_NOT_APPLICABLE value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_IN_NETWORK=2 INF_PROVIDER_NETWORK_STATUS_IN_NETWORK value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_OUT_OF_NETWORK=3 INF_PROVIDER_NETWORK_STATUS_OUT_OF_NETWORK value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_IN=4 INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_IN value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_IN=5 INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_IN value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_OUT=6 INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_OUT value
         */
        infinitusapi.INFProviderNetworkStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PROVIDER_NETWORK_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PROVIDER_NETWORK_STATUS_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PROVIDER_NETWORK_STATUS_IN_NETWORK"] = 2;
            values[valuesById[3] = "INF_PROVIDER_NETWORK_STATUS_OUT_OF_NETWORK"] = 3;
            values[valuesById[4] = "INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_IN"] = 4;
            values[valuesById[5] = "INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_IN"] = 5;
            values[valuesById[6] = "INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_OUT"] = 6;
            return values;
        })();
    
        /**
         * INFProviderNetworkStatusOnly enum.
         * @name infinitusapi.INFProviderNetworkStatusOnly
         * @enum {number}
         * @property {number} INF_PROVIDER_NETWORK_STATUS_ONLY_UNKNOWN=0 INF_PROVIDER_NETWORK_STATUS_ONLY_UNKNOWN value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_ONLY_NOT_APPLICABLE=1 INF_PROVIDER_NETWORK_STATUS_ONLY_NOT_APPLICABLE value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK=2 INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS=3 INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS value
         * @property {number} INF_PROVIDER_NETWORK_STATUS_ONLY_OUT_OF_NETWORK=4 INF_PROVIDER_NETWORK_STATUS_ONLY_OUT_OF_NETWORK value
         */
        infinitusapi.INFProviderNetworkStatusOnly = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_PROVIDER_NETWORK_STATUS_ONLY_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PROVIDER_NETWORK_STATUS_ONLY_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK"] = 2;
            values[valuesById[3] = "INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS"] = 3;
            values[valuesById[4] = "INF_PROVIDER_NETWORK_STATUS_ONLY_OUT_OF_NETWORK"] = 4;
            return values;
        })();
    
        /**
         * INFReasonForNonCoverage enum.
         * @name infinitusapi.INFReasonForNonCoverage
         * @enum {number}
         * @property {number} INF_REASON_FOR_NON_COVERAGE_UNKNOWN=0 INF_REASON_FOR_NON_COVERAGE_UNKNOWN value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_NOT_APPLICABLE=1 INF_REASON_FOR_NON_COVERAGE_NOT_APPLICABLE value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM=2 INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS=3 INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B=4 INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_CAP_MET=5 INF_REASON_FOR_NON_COVERAGE_CAP_MET value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS=6 INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG=7 INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT=8 INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG=9 INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL=10 INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY=11 INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION=12 INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY=13 INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED=14 INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED=15 INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK=16 INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK value
         * @property {number} INF_REASON_FOR_NON_COVERAGE_OTHER=17 INF_REASON_FOR_NON_COVERAGE_OTHER value
         */
        infinitusapi.INFReasonForNonCoverage = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_REASON_FOR_NON_COVERAGE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_REASON_FOR_NON_COVERAGE_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM"] = 2;
            values[valuesById[3] = "INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS"] = 3;
            values[valuesById[4] = "INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B"] = 4;
            values[valuesById[5] = "INF_REASON_FOR_NON_COVERAGE_CAP_MET"] = 5;
            values[valuesById[6] = "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS"] = 6;
            values[valuesById[7] = "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG"] = 7;
            values[valuesById[8] = "INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT"] = 8;
            values[valuesById[9] = "INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG"] = 9;
            values[valuesById[10] = "INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL"] = 10;
            values[valuesById[11] = "INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY"] = 11;
            values[valuesById[12] = "INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION"] = 12;
            values[valuesById[13] = "INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY"] = 13;
            values[valuesById[14] = "INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED"] = 14;
            values[valuesById[15] = "INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED"] = 15;
            values[valuesById[16] = "INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK"] = 16;
            values[valuesById[17] = "INF_REASON_FOR_NON_COVERAGE_OTHER"] = 17;
            return values;
        })();
    
        /**
         * INFSpecialtyPharmacyAvailability enum.
         * @name infinitusapi.INFSpecialtyPharmacyAvailability
         * @enum {number}
         * @property {number} INF_SPECIALTY_PHARMACY_AVAILABILITY_UNKNOWN=0 INF_SPECIALTY_PHARMACY_AVAILABILITY_UNKNOWN value
         * @property {number} INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_APPLICABLE=1 INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_APPLICABLE value
         * @property {number} INF_SPECIALTY_PHARMACY_AVAILABILITY_REQUIRED=2 INF_SPECIALTY_PHARMACY_AVAILABILITY_REQUIRED value
         * @property {number} INF_SPECIALTY_PHARMACY_AVAILABILITY_AVAILABLE=3 INF_SPECIALTY_PHARMACY_AVAILABILITY_AVAILABLE value
         * @property {number} INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_AVAILABLE=4 INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_AVAILABLE value
         */
        infinitusapi.INFSpecialtyPharmacyAvailability = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_SPECIALTY_PHARMACY_AVAILABILITY_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_SPECIALTY_PHARMACY_AVAILABILITY_REQUIRED"] = 2;
            values[valuesById[3] = "INF_SPECIALTY_PHARMACY_AVAILABILITY_AVAILABLE"] = 3;
            values[valuesById[4] = "INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_AVAILABLE"] = 4;
            return values;
        })();
    
        /**
         * INFSpecialtyPharmacyNetwork enum.
         * @name infinitusapi.INFSpecialtyPharmacyNetwork
         * @enum {number}
         * @property {number} INF_SPECIALTY_PHARMACY_NETWORK_TYPE_UNKNOWN=0 INF_SPECIALTY_PHARMACY_NETWORK_TYPE_UNKNOWN value
         * @property {number} INF_SPECIALTY_PHARMACY_NETWORK_TYPE_NOT_APPLICABLE=1 INF_SPECIALTY_PHARMACY_NETWORK_TYPE_NOT_APPLICABLE value
         * @property {number} INF_SPECIALTY_PHARMACY_NETWORK_TYPE_EXCLUSIVE=2 INF_SPECIALTY_PHARMACY_NETWORK_TYPE_EXCLUSIVE value
         * @property {number} INF_SPECIALTY_PHARMACY_NETWORK_TYPE_PREFERRED=3 INF_SPECIALTY_PHARMACY_NETWORK_TYPE_PREFERRED value
         * @property {number} INF_SPECIALTY_PHARMACY_NETWORK_TYPE_LIMITED=4 INF_SPECIALTY_PHARMACY_NETWORK_TYPE_LIMITED value
         * @property {number} INF_SPECIALTY_PHARMACY_NETWORK_TYPE_OPEN=5 INF_SPECIALTY_PHARMACY_NETWORK_TYPE_OPEN value
         */
        infinitusapi.INFSpecialtyPharmacyNetwork = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_EXCLUSIVE"] = 2;
            values[valuesById[3] = "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_PREFERRED"] = 3;
            values[valuesById[4] = "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_LIMITED"] = 4;
            values[valuesById[5] = "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_OPEN"] = 5;
            return values;
        })();
    
        /**
         * INFSpecialtyPharmacyPredeterminationStatus enum.
         * @name infinitusapi.INFSpecialtyPharmacyPredeterminationStatus
         * @enum {number}
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_UNKNOWN=0 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_UNKNOWN value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_APPLICABLE=1 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_APPLICABLE value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_ON_FILE=2 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_ON_FILE value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED=3 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR=4 INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS=5 INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_DENIED=6 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_DENIED value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_EXPIRED=7 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_EXPIRED value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_FUTURE=8 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_FUTURE value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_PENDING=9 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_PENDING value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS=10 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS value
         * @property {number} INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS=11 INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS value
         */
        infinitusapi.INFSpecialtyPharmacyPredeterminationStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_ON_FILE"] = 2;
            values[valuesById[3] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED"] = 3;
            values[valuesById[4] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR"] = 4;
            values[valuesById[5] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS"] = 5;
            values[valuesById[6] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_DENIED"] = 6;
            values[valuesById[7] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_EXPIRED"] = 7;
            values[valuesById[8] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_FUTURE"] = 8;
            values[valuesById[9] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_PENDING"] = 9;
            values[valuesById[10] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS"] = 10;
            values[valuesById[11] = "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS"] = 11;
            return values;
        })();
    
        /**
         * INFStandardPlanLetter enum.
         * @name infinitusapi.INFStandardPlanLetter
         * @enum {number}
         * @property {number} INF_UNKNOWN=0 INF_UNKNOWN value
         * @property {number} INF_PLAN_LETTER_NOT_APPLICABLE=1 INF_PLAN_LETTER_NOT_APPLICABLE value
         * @property {number} INF_A=2 INF_A value
         * @property {number} INF_B=3 INF_B value
         * @property {number} INF_C=4 INF_C value
         * @property {number} INF_D=5 INF_D value
         * @property {number} INF_E=6 INF_E value
         * @property {number} INF_F=7 INF_F value
         * @property {number} INF_G=8 INF_G value
         * @property {number} INF_H=9 INF_H value
         * @property {number} INF_I=10 INF_I value
         * @property {number} INF_J=11 INF_J value
         * @property {number} INF_K=12 INF_K value
         * @property {number} INF_L=13 INF_L value
         * @property {number} INF_M=14 INF_M value
         * @property {number} INF_N=15 INF_N value
         */
        infinitusapi.INFStandardPlanLetter = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_PLAN_LETTER_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_A"] = 2;
            values[valuesById[3] = "INF_B"] = 3;
            values[valuesById[4] = "INF_C"] = 4;
            values[valuesById[5] = "INF_D"] = 5;
            values[valuesById[6] = "INF_E"] = 6;
            values[valuesById[7] = "INF_F"] = 7;
            values[valuesById[8] = "INF_G"] = 8;
            values[valuesById[9] = "INF_H"] = 9;
            values[valuesById[10] = "INF_I"] = 10;
            values[valuesById[11] = "INF_J"] = 11;
            values[valuesById[12] = "INF_K"] = 12;
            values[valuesById[13] = "INF_L"] = 13;
            values[valuesById[14] = "INF_M"] = 14;
            values[valuesById[15] = "INF_N"] = 15;
            return values;
        })();
    
        /**
         * INFStepTherapyMethod enum.
         * @name infinitusapi.INFStepTherapyMethod
         * @enum {number}
         * @property {number} INF_STEP_THERAPY_METHOD_UNKNOWN=0 INF_STEP_THERAPY_METHOD_UNKNOWN value
         * @property {number} INF_STEP_THERAPY_METHOD_NOT_APPLICABLE=1 INF_STEP_THERAPY_METHOD_NOT_APPLICABLE value
         * @property {number} INF_STEP_THERAPY_METHOD_PREFERRED_TREATMENT=2 INF_STEP_THERAPY_METHOD_PREFERRED_TREATMENT value
         * @property {number} INF_STEP_THERAPY_METHOD_SUBMIT_CLINICAL_NOTES=3 INF_STEP_THERAPY_METHOD_SUBMIT_CLINICAL_NOTES value
         */
        infinitusapi.INFStepTherapyMethod = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_STEP_THERAPY_METHOD_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_STEP_THERAPY_METHOD_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_STEP_THERAPY_METHOD_PREFERRED_TREATMENT"] = 2;
            values[valuesById[3] = "INF_STEP_THERAPY_METHOD_SUBMIT_CLINICAL_NOTES"] = 3;
            return values;
        })();
    
        /**
         * INFTaskCancellationStatus enum.
         * @name infinitusapi.INFTaskCancellationStatus
         * @enum {number}
         * @property {number} INF_CANCEL_SUCCESSFUL=0 INF_CANCEL_SUCCESSFUL value
         * @property {number} INF_CANCEL_FAILED=1 INF_CANCEL_FAILED value
         */
        infinitusapi.INFTaskCancellationStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_CANCEL_SUCCESSFUL"] = 0;
            values[valuesById[1] = "INF_CANCEL_FAILED"] = 1;
            return values;
        })();
    
        /**
         * INFTaskStatus enum.
         * @name infinitusapi.INFTaskStatus
         * @enum {number}
         * @property {number} INF_TASK_STATUS_UNKNOWN=0 INF_TASK_STATUS_UNKNOWN value
         * @property {number} INF_TASK_STATUS_SUCCESSFUL=1 INF_TASK_STATUS_SUCCESSFUL value
         * @property {number} INF_TASK_STATUS_UNSUCCESSFUL=2 INF_TASK_STATUS_UNSUCCESSFUL value
         * @property {number} INF_TASK_STATUS_IN_PROGRESS=3 INF_TASK_STATUS_IN_PROGRESS value
         */
        infinitusapi.INFTaskStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_TASK_STATUS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_TASK_STATUS_SUCCESSFUL"] = 1;
            values[valuesById[2] = "INF_TASK_STATUS_UNSUCCESSFUL"] = 2;
            values[valuesById[3] = "INF_TASK_STATUS_IN_PROGRESS"] = 3;
            return values;
        })();
    
        /**
         * INFTaskType enum.
         * @name infinitusapi.INFTaskType
         * @enum {number}
         * @property {number} INF_TASK_TYPE_UNKNOWN=0 INF_TASK_TYPE_UNKNOWN value
         * @property {number} INF_TASK_TYPE_BENEFITS_VERIFICATION=1 INF_TASK_TYPE_BENEFITS_VERIFICATION value
         * @property {number} INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER=2 INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER value
         * @property {number} INF_TASK_TYPE_PRESCRIPTION_TRANSFER=3 INF_TASK_TYPE_PRESCRIPTION_TRANSFER value
         * @property {number} INF_TASK_TYPE_PRESCRIPTION_SAVINGS=4 INF_TASK_TYPE_PRESCRIPTION_SAVINGS value
         * @property {number} INF_TASK_TYPE_CLAIMS_FOLLOWUP=5 INF_TASK_TYPE_CLAIMS_FOLLOWUP value
         * @property {number} INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP=6 INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP value
         * @property {number} INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION=7 INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION value
         * @property {number} INF_TASK_TYPE_SHIPMENT_FOLLOWUP=8 INF_TASK_TYPE_SHIPMENT_FOLLOWUP value
         * @property {number} INF_TASK_TYPE_PATIENT_OUTREACH=9 INF_TASK_TYPE_PATIENT_OUTREACH value
         * @property {number} INF_TASK_TYPE_PROVIDER_OUTREACH=10 INF_TASK_TYPE_PROVIDER_OUTREACH value
         * @property {number} INF_TASK_TYPE_FASTTRACK_MM=11 INF_TASK_TYPE_FASTTRACK_MM value
         * @property {number} INF_TASK_TYPE_FASTTRACK_PBM=12 INF_TASK_TYPE_FASTTRACK_PBM value
         * @property {number} INF_TASK_TYPE_PHARMACY_STOCK_CHECK=13 INF_TASK_TYPE_PHARMACY_STOCK_CHECK value
         * @property {number} INF_TASK_TYPE_BASIC_BV=14 INF_TASK_TYPE_BASIC_BV value
         * @property {number} INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP=15 INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP value
         * @property {number} INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL=16 INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL value
         * @property {number} INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL=17 INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL value
         * @property {number} INF_TASK_TYPE_PBM_DISCOVERY=18 INF_TASK_TYPE_PBM_DISCOVERY value
         */
        infinitusapi.INFTaskType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_TASK_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_TASK_TYPE_BENEFITS_VERIFICATION"] = 1;
            values[valuesById[2] = "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER"] = 2;
            values[valuesById[3] = "INF_TASK_TYPE_PRESCRIPTION_TRANSFER"] = 3;
            values[valuesById[4] = "INF_TASK_TYPE_PRESCRIPTION_SAVINGS"] = 4;
            values[valuesById[5] = "INF_TASK_TYPE_CLAIMS_FOLLOWUP"] = 5;
            values[valuesById[6] = "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP"] = 6;
            values[valuesById[7] = "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION"] = 7;
            values[valuesById[8] = "INF_TASK_TYPE_SHIPMENT_FOLLOWUP"] = 8;
            values[valuesById[9] = "INF_TASK_TYPE_PATIENT_OUTREACH"] = 9;
            values[valuesById[10] = "INF_TASK_TYPE_PROVIDER_OUTREACH"] = 10;
            values[valuesById[11] = "INF_TASK_TYPE_FASTTRACK_MM"] = 11;
            values[valuesById[12] = "INF_TASK_TYPE_FASTTRACK_PBM"] = 12;
            values[valuesById[13] = "INF_TASK_TYPE_PHARMACY_STOCK_CHECK"] = 13;
            values[valuesById[14] = "INF_TASK_TYPE_BASIC_BV"] = 14;
            values[valuesById[15] = "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP"] = 15;
            values[valuesById[16] = "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL"] = 16;
            values[valuesById[17] = "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL"] = 17;
            values[valuesById[18] = "INF_TASK_TYPE_PBM_DISCOVERY"] = 18;
            return values;
        })();
    
        /**
         * InfReferralRequirementsType enum.
         * @name infinitusapi.InfReferralRequirementsType
         * @enum {number}
         * @property {number} INF_REFERRAL_REQUIREMENTS_UNKNOWN=0 INF_REFERRAL_REQUIREMENTS_UNKNOWN value
         * @property {number} INF_REFERRAL_REQUIREMENTS_NOT_APPLICABLE=1 INF_REFERRAL_REQUIREMENTS_NOT_APPLICABLE value
         * @property {number} INF_REFERRAL_REQUIREMENTS_NOT_REQUIRED=2 INF_REFERRAL_REQUIREMENTS_NOT_REQUIRED value
         * @property {number} INF_REFERRAL_REQUIREMENTS_REQUIRED=3 INF_REFERRAL_REQUIREMENTS_REQUIRED value
         * @property {number} INF_REFERRAL_REQUIREMENTS_REQUIRED_BUT_WAIVED=4 INF_REFERRAL_REQUIREMENTS_REQUIRED_BUT_WAIVED value
         * @property {number} INF_REFERRAL_REQUIREMENTS_DOCTOR_TO_DOCTOR=5 INF_REFERRAL_REQUIREMENTS_DOCTOR_TO_DOCTOR value
         */
        infinitusapi.InfReferralRequirementsType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INF_REFERRAL_REQUIREMENTS_UNKNOWN"] = 0;
            values[valuesById[1] = "INF_REFERRAL_REQUIREMENTS_NOT_APPLICABLE"] = 1;
            values[valuesById[2] = "INF_REFERRAL_REQUIREMENTS_NOT_REQUIRED"] = 2;
            values[valuesById[3] = "INF_REFERRAL_REQUIREMENTS_REQUIRED"] = 3;
            values[valuesById[4] = "INF_REFERRAL_REQUIREMENTS_REQUIRED_BUT_WAIVED"] = 4;
            values[valuesById[5] = "INF_REFERRAL_REQUIREMENTS_DOCTOR_TO_DOCTOR"] = 5;
            return values;
        })();
    
        infinitusapi.APIError = (function() {
    
            /**
             * Properties of a APIError.
             * @memberof infinitusapi
             * @interface IAPIError
             * @property {string|null} [message] APIError message
             */
    
            /**
             * Constructs a new APIError.
             * @memberof infinitusapi
             * @classdesc Represents a APIError.
             * @implements IAPIError
             * @constructor
             * @param {infinitusapi.IAPIError=} [properties] Properties to set
             */
            function APIError(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * APIError message.
             * @member {string} message
             * @memberof infinitusapi.APIError
             * @instance
             */
            APIError.prototype.message = "";
    
            /**
             * Creates a new APIError instance using the specified properties.
             * @function create
             * @memberof infinitusapi.APIError
             * @static
             * @param {infinitusapi.IAPIError=} [properties] Properties to set
             * @returns {infinitusapi.APIError} APIError instance
             */
            APIError.create = function create(properties) {
                return new APIError(properties);
            };
    
            /**
             * Encodes the specified APIError message. Does not implicitly {@link infinitusapi.APIError.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.APIError
             * @static
             * @param {infinitusapi.IAPIError} message APIError message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            APIError.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
                return writer;
            };
    
            /**
             * Encodes the specified APIError message, length delimited. Does not implicitly {@link infinitusapi.APIError.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.APIError
             * @static
             * @param {infinitusapi.IAPIError} message APIError message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            APIError.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a APIError message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.APIError
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.APIError} APIError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            APIError.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.APIError();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.message = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a APIError message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.APIError
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.APIError} APIError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            APIError.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a APIError message.
             * @function verify
             * @memberof infinitusapi.APIError
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            APIError.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.message != null && message.hasOwnProperty("message"))
                    if (!$util.isString(message.message))
                        return "message: string expected";
                return null;
            };
    
            /**
             * Creates a APIError message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.APIError
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.APIError} APIError
             */
            APIError.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.APIError)
                    return object;
                var message = new $root.infinitusapi.APIError();
                if (object.message != null)
                    message.message = String(object.message);
                return message;
            };
    
            /**
             * Creates a plain object from a APIError message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.APIError
             * @static
             * @param {infinitusapi.APIError} message APIError
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            APIError.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.message = "";
                if (message.message != null && message.hasOwnProperty("message"))
                    object.message = message.message;
                return object;
            };
    
            /**
             * Converts this APIError to JSON.
             * @function toJSON
             * @memberof infinitusapi.APIError
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            APIError.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for APIError
             * @function getTypeUrl
             * @memberof infinitusapi.APIError
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            APIError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.APIError";
            };
    
            return APIError;
        })();
    
        infinitusapi.CancelTasksRequest = (function() {
    
            /**
             * Properties of a CancelTasksRequest.
             * @memberof infinitusapi
             * @interface ICancelTasksRequest
             * @property {Array.<string>|null} [taskIDs] CancelTasksRequest taskIDs
             */
    
            /**
             * Constructs a new CancelTasksRequest.
             * @memberof infinitusapi
             * @classdesc Represents a CancelTasksRequest.
             * @implements ICancelTasksRequest
             * @constructor
             * @param {infinitusapi.ICancelTasksRequest=} [properties] Properties to set
             */
            function CancelTasksRequest(properties) {
                this.taskIDs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CancelTasksRequest taskIDs.
             * @member {Array.<string>} taskIDs
             * @memberof infinitusapi.CancelTasksRequest
             * @instance
             */
            CancelTasksRequest.prototype.taskIDs = $util.emptyArray;
    
            /**
             * Creates a new CancelTasksRequest instance using the specified properties.
             * @function create
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {infinitusapi.ICancelTasksRequest=} [properties] Properties to set
             * @returns {infinitusapi.CancelTasksRequest} CancelTasksRequest instance
             */
            CancelTasksRequest.create = function create(properties) {
                return new CancelTasksRequest(properties);
            };
    
            /**
             * Encodes the specified CancelTasksRequest message. Does not implicitly {@link infinitusapi.CancelTasksRequest.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {infinitusapi.ICancelTasksRequest} message CancelTasksRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelTasksRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.taskIDs != null && message.taskIDs.length)
                    for (var i = 0; i < message.taskIDs.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskIDs[i]);
                return writer;
            };
    
            /**
             * Encodes the specified CancelTasksRequest message, length delimited. Does not implicitly {@link infinitusapi.CancelTasksRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {infinitusapi.ICancelTasksRequest} message CancelTasksRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CancelTasksRequest message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.CancelTasksRequest} CancelTasksRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelTasksRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CancelTasksRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.taskIDs && message.taskIDs.length))
                                message.taskIDs = [];
                            message.taskIDs.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CancelTasksRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.CancelTasksRequest} CancelTasksRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CancelTasksRequest message.
             * @function verify
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CancelTasksRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.taskIDs != null && message.hasOwnProperty("taskIDs")) {
                    if (!Array.isArray(message.taskIDs))
                        return "taskIDs: array expected";
                    for (var i = 0; i < message.taskIDs.length; ++i)
                        if (!$util.isString(message.taskIDs[i]))
                            return "taskIDs: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a CancelTasksRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.CancelTasksRequest} CancelTasksRequest
             */
            CancelTasksRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.CancelTasksRequest)
                    return object;
                var message = new $root.infinitusapi.CancelTasksRequest();
                if (object.taskIDs) {
                    if (!Array.isArray(object.taskIDs))
                        throw TypeError(".infinitusapi.CancelTasksRequest.taskIDs: array expected");
                    message.taskIDs = [];
                    for (var i = 0; i < object.taskIDs.length; ++i)
                        message.taskIDs[i] = String(object.taskIDs[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CancelTasksRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {infinitusapi.CancelTasksRequest} message CancelTasksRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelTasksRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.taskIDs = [];
                if (message.taskIDs && message.taskIDs.length) {
                    object.taskIDs = [];
                    for (var j = 0; j < message.taskIDs.length; ++j)
                        object.taskIDs[j] = message.taskIDs[j];
                }
                return object;
            };
    
            /**
             * Converts this CancelTasksRequest to JSON.
             * @function toJSON
             * @memberof infinitusapi.CancelTasksRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelTasksRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CancelTasksRequest
             * @function getTypeUrl
             * @memberof infinitusapi.CancelTasksRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CancelTasksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.CancelTasksRequest";
            };
    
            return CancelTasksRequest;
        })();
    
        infinitusapi.CancelTasksResponse = (function() {
    
            /**
             * Properties of a CancelTasksResponse.
             * @memberof infinitusapi
             * @interface ICancelTasksResponse
             * @property {Array.<infinitusapi.CancelTasksResponse.IDataMessage>|null} [data] CancelTasksResponse data
             */
    
            /**
             * Constructs a new CancelTasksResponse.
             * @memberof infinitusapi
             * @classdesc Represents a CancelTasksResponse.
             * @implements ICancelTasksResponse
             * @constructor
             * @param {infinitusapi.ICancelTasksResponse=} [properties] Properties to set
             */
            function CancelTasksResponse(properties) {
                this.data = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CancelTasksResponse data.
             * @member {Array.<infinitusapi.CancelTasksResponse.IDataMessage>} data
             * @memberof infinitusapi.CancelTasksResponse
             * @instance
             */
            CancelTasksResponse.prototype.data = $util.emptyArray;
    
            /**
             * Creates a new CancelTasksResponse instance using the specified properties.
             * @function create
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {infinitusapi.ICancelTasksResponse=} [properties] Properties to set
             * @returns {infinitusapi.CancelTasksResponse} CancelTasksResponse instance
             */
            CancelTasksResponse.create = function create(properties) {
                return new CancelTasksResponse(properties);
            };
    
            /**
             * Encodes the specified CancelTasksResponse message. Does not implicitly {@link infinitusapi.CancelTasksResponse.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {infinitusapi.ICancelTasksResponse} message CancelTasksResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelTasksResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && message.data.length)
                    for (var i = 0; i < message.data.length; ++i)
                        $root.infinitusapi.CancelTasksResponse.DataMessage.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CancelTasksResponse message, length delimited. Does not implicitly {@link infinitusapi.CancelTasksResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {infinitusapi.ICancelTasksResponse} message CancelTasksResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CancelTasksResponse message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.CancelTasksResponse} CancelTasksResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelTasksResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CancelTasksResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.data && message.data.length))
                                message.data = [];
                            message.data.push($root.infinitusapi.CancelTasksResponse.DataMessage.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CancelTasksResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.CancelTasksResponse} CancelTasksResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CancelTasksResponse message.
             * @function verify
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CancelTasksResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data")) {
                    if (!Array.isArray(message.data))
                        return "data: array expected";
                    for (var i = 0; i < message.data.length; ++i) {
                        var error = $root.infinitusapi.CancelTasksResponse.DataMessage.verify(message.data[i]);
                        if (error)
                            return "data." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CancelTasksResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.CancelTasksResponse} CancelTasksResponse
             */
            CancelTasksResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.CancelTasksResponse)
                    return object;
                var message = new $root.infinitusapi.CancelTasksResponse();
                if (object.data) {
                    if (!Array.isArray(object.data))
                        throw TypeError(".infinitusapi.CancelTasksResponse.data: array expected");
                    message.data = [];
                    for (var i = 0; i < object.data.length; ++i) {
                        if (typeof object.data[i] !== "object")
                            throw TypeError(".infinitusapi.CancelTasksResponse.data: object expected");
                        message.data[i] = $root.infinitusapi.CancelTasksResponse.DataMessage.fromObject(object.data[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CancelTasksResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {infinitusapi.CancelTasksResponse} message CancelTasksResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelTasksResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.data = [];
                if (message.data && message.data.length) {
                    object.data = [];
                    for (var j = 0; j < message.data.length; ++j)
                        object.data[j] = $root.infinitusapi.CancelTasksResponse.DataMessage.toObject(message.data[j], options);
                }
                return object;
            };
    
            /**
             * Converts this CancelTasksResponse to JSON.
             * @function toJSON
             * @memberof infinitusapi.CancelTasksResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelTasksResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CancelTasksResponse
             * @function getTypeUrl
             * @memberof infinitusapi.CancelTasksResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CancelTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.CancelTasksResponse";
            };
    
            CancelTasksResponse.DataMessage = (function() {
    
                /**
                 * Properties of a DataMessage.
                 * @memberof infinitusapi.CancelTasksResponse
                 * @interface IDataMessage
                 * @property {string|null} [error] DataMessage error
                 * @property {infinitusapi.INFTaskCancellationStatus|null} [status] DataMessage status
                 * @property {string|null} [taskID] DataMessage taskID
                 */
    
                /**
                 * Constructs a new DataMessage.
                 * @memberof infinitusapi.CancelTasksResponse
                 * @classdesc Represents a DataMessage.
                 * @implements IDataMessage
                 * @constructor
                 * @param {infinitusapi.CancelTasksResponse.IDataMessage=} [properties] Properties to set
                 */
                function DataMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DataMessage error.
                 * @member {string} error
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.error = "";
    
                /**
                 * DataMessage status.
                 * @member {infinitusapi.INFTaskCancellationStatus} status
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.status = 0;
    
                /**
                 * DataMessage taskID.
                 * @member {string} taskID
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskID = "";
    
                /**
                 * Creates a new DataMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CancelTasksResponse.IDataMessage=} [properties] Properties to set
                 * @returns {infinitusapi.CancelTasksResponse.DataMessage} DataMessage instance
                 */
                DataMessage.create = function create(properties) {
                    return new DataMessage(properties);
                };
    
                /**
                 * Encodes the specified DataMessage message. Does not implicitly {@link infinitusapi.CancelTasksResponse.DataMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CancelTasksResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.error);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                    if (message.taskID != null && Object.hasOwnProperty.call(message, "taskID"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskID);
                    return writer;
                };
    
                /**
                 * Encodes the specified DataMessage message, length delimited. Does not implicitly {@link infinitusapi.CancelTasksResponse.DataMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CancelTasksResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusapi.CancelTasksResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CancelTasksResponse.DataMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.error = reader.string();
                                break;
                            }
                        case 2: {
                                message.status = reader.int32();
                                break;
                            }
                        case 3: {
                                message.taskID = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusapi.CancelTasksResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DataMessage message.
                 * @function verify
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DataMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.error != null && message.hasOwnProperty("error"))
                        if (!$util.isString(message.error))
                            return "error: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.taskID != null && message.hasOwnProperty("taskID"))
                        if (!$util.isString(message.taskID))
                            return "taskID: string expected";
                    return null;
                };
    
                /**
                 * Creates a DataMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusapi.CancelTasksResponse.DataMessage} DataMessage
                 */
                DataMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusapi.CancelTasksResponse.DataMessage)
                        return object;
                    var message = new $root.infinitusapi.CancelTasksResponse.DataMessage();
                    if (object.error != null)
                        message.error = String(object.error);
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "INF_CANCEL_SUCCESSFUL":
                    case 0:
                        message.status = 0;
                        break;
                    case "INF_CANCEL_FAILED":
                    case 1:
                        message.status = 1;
                        break;
                    }
                    if (object.taskID != null)
                        message.taskID = String(object.taskID);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DataMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CancelTasksResponse.DataMessage} message DataMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DataMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.error = "";
                        object.status = options.enums === String ? "INF_CANCEL_SUCCESSFUL" : 0;
                        object.taskID = "";
                    }
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = message.error;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.infinitusapi.INFTaskCancellationStatus[message.status] === undefined ? message.status : $root.infinitusapi.INFTaskCancellationStatus[message.status] : message.status;
                    if (message.taskID != null && message.hasOwnProperty("taskID"))
                        object.taskID = message.taskID;
                    return object;
                };
    
                /**
                 * Converts this DataMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DataMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DataMessage
                 * @function getTypeUrl
                 * @memberof infinitusapi.CancelTasksResponse.DataMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DataMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusapi.CancelTasksResponse.DataMessage";
                };
    
                return DataMessage;
            })();
    
            return CancelTasksResponse;
        })();
    
        infinitusapi.CheckPrefixesResponse = (function() {
    
            /**
             * Properties of a CheckPrefixesResponse.
             * @memberof infinitusapi
             * @interface ICheckPrefixesResponse
             * @property {Array.<infinitusapi.CheckPrefixesResponse.IDataMessage>|null} [data] CheckPrefixesResponse data
             */
    
            /**
             * Constructs a new CheckPrefixesResponse.
             * @memberof infinitusapi
             * @classdesc Represents a CheckPrefixesResponse.
             * @implements ICheckPrefixesResponse
             * @constructor
             * @param {infinitusapi.ICheckPrefixesResponse=} [properties] Properties to set
             */
            function CheckPrefixesResponse(properties) {
                this.data = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CheckPrefixesResponse data.
             * @member {Array.<infinitusapi.CheckPrefixesResponse.IDataMessage>} data
             * @memberof infinitusapi.CheckPrefixesResponse
             * @instance
             */
            CheckPrefixesResponse.prototype.data = $util.emptyArray;
    
            /**
             * Creates a new CheckPrefixesResponse instance using the specified properties.
             * @function create
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {infinitusapi.ICheckPrefixesResponse=} [properties] Properties to set
             * @returns {infinitusapi.CheckPrefixesResponse} CheckPrefixesResponse instance
             */
            CheckPrefixesResponse.create = function create(properties) {
                return new CheckPrefixesResponse(properties);
            };
    
            /**
             * Encodes the specified CheckPrefixesResponse message. Does not implicitly {@link infinitusapi.CheckPrefixesResponse.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {infinitusapi.ICheckPrefixesResponse} message CheckPrefixesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckPrefixesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && message.data.length)
                    for (var i = 0; i < message.data.length; ++i)
                        $root.infinitusapi.CheckPrefixesResponse.DataMessage.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CheckPrefixesResponse message, length delimited. Does not implicitly {@link infinitusapi.CheckPrefixesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {infinitusapi.ICheckPrefixesResponse} message CheckPrefixesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckPrefixesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CheckPrefixesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.CheckPrefixesResponse} CheckPrefixesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckPrefixesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CheckPrefixesResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.data && message.data.length))
                                message.data = [];
                            message.data.push($root.infinitusapi.CheckPrefixesResponse.DataMessage.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CheckPrefixesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.CheckPrefixesResponse} CheckPrefixesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckPrefixesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CheckPrefixesResponse message.
             * @function verify
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckPrefixesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data")) {
                    if (!Array.isArray(message.data))
                        return "data: array expected";
                    for (var i = 0; i < message.data.length; ++i) {
                        var error = $root.infinitusapi.CheckPrefixesResponse.DataMessage.verify(message.data[i]);
                        if (error)
                            return "data." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CheckPrefixesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.CheckPrefixesResponse} CheckPrefixesResponse
             */
            CheckPrefixesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.CheckPrefixesResponse)
                    return object;
                var message = new $root.infinitusapi.CheckPrefixesResponse();
                if (object.data) {
                    if (!Array.isArray(object.data))
                        throw TypeError(".infinitusapi.CheckPrefixesResponse.data: array expected");
                    message.data = [];
                    for (var i = 0; i < object.data.length; ++i) {
                        if (typeof object.data[i] !== "object")
                            throw TypeError(".infinitusapi.CheckPrefixesResponse.data: object expected");
                        message.data[i] = $root.infinitusapi.CheckPrefixesResponse.DataMessage.fromObject(object.data[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CheckPrefixesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {infinitusapi.CheckPrefixesResponse} message CheckPrefixesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckPrefixesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.data = [];
                if (message.data && message.data.length) {
                    object.data = [];
                    for (var j = 0; j < message.data.length; ++j)
                        object.data[j] = $root.infinitusapi.CheckPrefixesResponse.DataMessage.toObject(message.data[j], options);
                }
                return object;
            };
    
            /**
             * Converts this CheckPrefixesResponse to JSON.
             * @function toJSON
             * @memberof infinitusapi.CheckPrefixesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckPrefixesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CheckPrefixesResponse
             * @function getTypeUrl
             * @memberof infinitusapi.CheckPrefixesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckPrefixesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.CheckPrefixesResponse";
            };
    
            CheckPrefixesResponse.DataMessage = (function() {
    
                /**
                 * Properties of a DataMessage.
                 * @memberof infinitusapi.CheckPrefixesResponse
                 * @interface IDataMessage
                 * @property {string|null} [prefix] DataMessage prefix
                 * @property {boolean|null} [supported] DataMessage supported
                 */
    
                /**
                 * Constructs a new DataMessage.
                 * @memberof infinitusapi.CheckPrefixesResponse
                 * @classdesc Represents a DataMessage.
                 * @implements IDataMessage
                 * @constructor
                 * @param {infinitusapi.CheckPrefixesResponse.IDataMessage=} [properties] Properties to set
                 */
                function DataMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DataMessage prefix.
                 * @member {string} prefix
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.prefix = "";
    
                /**
                 * DataMessage supported.
                 * @member {boolean} supported
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.supported = false;
    
                /**
                 * Creates a new DataMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CheckPrefixesResponse.IDataMessage=} [properties] Properties to set
                 * @returns {infinitusapi.CheckPrefixesResponse.DataMessage} DataMessage instance
                 */
                DataMessage.create = function create(properties) {
                    return new DataMessage(properties);
                };
    
                /**
                 * Encodes the specified DataMessage message. Does not implicitly {@link infinitusapi.CheckPrefixesResponse.DataMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CheckPrefixesResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.prefix != null && Object.hasOwnProperty.call(message, "prefix"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.prefix);
                    if (message.supported != null && Object.hasOwnProperty.call(message, "supported"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.supported);
                    return writer;
                };
    
                /**
                 * Encodes the specified DataMessage message, length delimited. Does not implicitly {@link infinitusapi.CheckPrefixesResponse.DataMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CheckPrefixesResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusapi.CheckPrefixesResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CheckPrefixesResponse.DataMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.prefix = reader.string();
                                break;
                            }
                        case 2: {
                                message.supported = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusapi.CheckPrefixesResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DataMessage message.
                 * @function verify
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DataMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.prefix != null && message.hasOwnProperty("prefix"))
                        if (!$util.isString(message.prefix))
                            return "prefix: string expected";
                    if (message.supported != null && message.hasOwnProperty("supported"))
                        if (typeof message.supported !== "boolean")
                            return "supported: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a DataMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusapi.CheckPrefixesResponse.DataMessage} DataMessage
                 */
                DataMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusapi.CheckPrefixesResponse.DataMessage)
                        return object;
                    var message = new $root.infinitusapi.CheckPrefixesResponse.DataMessage();
                    if (object.prefix != null)
                        message.prefix = String(object.prefix);
                    if (object.supported != null)
                        message.supported = Boolean(object.supported);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DataMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CheckPrefixesResponse.DataMessage} message DataMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DataMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.prefix = "";
                        object.supported = false;
                    }
                    if (message.prefix != null && message.hasOwnProperty("prefix"))
                        object.prefix = message.prefix;
                    if (message.supported != null && message.hasOwnProperty("supported"))
                        object.supported = message.supported;
                    return object;
                };
    
                /**
                 * Converts this DataMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DataMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DataMessage
                 * @function getTypeUrl
                 * @memberof infinitusapi.CheckPrefixesResponse.DataMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DataMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusapi.CheckPrefixesResponse.DataMessage";
                };
    
                return DataMessage;
            })();
    
            return CheckPrefixesResponse;
        })();
    
        infinitusapi.CreateTasksRequest = (function() {
    
            /**
             * Properties of a CreateTasksRequest.
             * @memberof infinitusapi
             * @interface ICreateTasksRequest
             * @property {Array.<infinitusapi.CreateTasksRequest.ITasksMessage>|null} [tasks] CreateTasksRequest tasks
             */
    
            /**
             * Constructs a new CreateTasksRequest.
             * @memberof infinitusapi
             * @classdesc Represents a CreateTasksRequest.
             * @implements ICreateTasksRequest
             * @constructor
             * @param {infinitusapi.ICreateTasksRequest=} [properties] Properties to set
             */
            function CreateTasksRequest(properties) {
                this.tasks = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateTasksRequest tasks.
             * @member {Array.<infinitusapi.CreateTasksRequest.ITasksMessage>} tasks
             * @memberof infinitusapi.CreateTasksRequest
             * @instance
             */
            CreateTasksRequest.prototype.tasks = $util.emptyArray;
    
            /**
             * Creates a new CreateTasksRequest instance using the specified properties.
             * @function create
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {infinitusapi.ICreateTasksRequest=} [properties] Properties to set
             * @returns {infinitusapi.CreateTasksRequest} CreateTasksRequest instance
             */
            CreateTasksRequest.create = function create(properties) {
                return new CreateTasksRequest(properties);
            };
    
            /**
             * Encodes the specified CreateTasksRequest message. Does not implicitly {@link infinitusapi.CreateTasksRequest.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {infinitusapi.ICreateTasksRequest} message CreateTasksRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateTasksRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tasks != null && message.tasks.length)
                    for (var i = 0; i < message.tasks.length; ++i)
                        $root.infinitusapi.CreateTasksRequest.TasksMessage.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CreateTasksRequest message, length delimited. Does not implicitly {@link infinitusapi.CreateTasksRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {infinitusapi.ICreateTasksRequest} message CreateTasksRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateTasksRequest message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.CreateTasksRequest} CreateTasksRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateTasksRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CreateTasksRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.tasks && message.tasks.length))
                                message.tasks = [];
                            message.tasks.push($root.infinitusapi.CreateTasksRequest.TasksMessage.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateTasksRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.CreateTasksRequest} CreateTasksRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateTasksRequest message.
             * @function verify
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateTasksRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tasks != null && message.hasOwnProperty("tasks")) {
                    if (!Array.isArray(message.tasks))
                        return "tasks: array expected";
                    for (var i = 0; i < message.tasks.length; ++i) {
                        var error = $root.infinitusapi.CreateTasksRequest.TasksMessage.verify(message.tasks[i]);
                        if (error)
                            return "tasks." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CreateTasksRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.CreateTasksRequest} CreateTasksRequest
             */
            CreateTasksRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.CreateTasksRequest)
                    return object;
                var message = new $root.infinitusapi.CreateTasksRequest();
                if (object.tasks) {
                    if (!Array.isArray(object.tasks))
                        throw TypeError(".infinitusapi.CreateTasksRequest.tasks: array expected");
                    message.tasks = [];
                    for (var i = 0; i < object.tasks.length; ++i) {
                        if (typeof object.tasks[i] !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.tasks: object expected");
                        message.tasks[i] = $root.infinitusapi.CreateTasksRequest.TasksMessage.fromObject(object.tasks[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateTasksRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {infinitusapi.CreateTasksRequest} message CreateTasksRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateTasksRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tasks = [];
                if (message.tasks && message.tasks.length) {
                    object.tasks = [];
                    for (var j = 0; j < message.tasks.length; ++j)
                        object.tasks[j] = $root.infinitusapi.CreateTasksRequest.TasksMessage.toObject(message.tasks[j], options);
                }
                return object;
            };
    
            /**
             * Converts this CreateTasksRequest to JSON.
             * @function toJSON
             * @memberof infinitusapi.CreateTasksRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateTasksRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CreateTasksRequest
             * @function getTypeUrl
             * @memberof infinitusapi.CreateTasksRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateTasksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.CreateTasksRequest";
            };
    
            CreateTasksRequest.TasksMessage = (function() {
    
                /**
                 * Properties of a TasksMessage.
                 * @memberof infinitusapi.CreateTasksRequest
                 * @interface ITasksMessage
                 * @property {infinitusapi.IINFBVTaskInput|null} [bvTaskInput] TasksMessage bvTaskInput
                 * @property {string|null} [customerId] TasksMessage customerId
                 * @property {string|null} [customerSegment] TasksMessage customerSegment
                 * @property {string|null} [customerUrl] TasksMessage customerUrl
                 * @property {infinitusapi.IEhrData|null} [ehrData] TasksMessage ehrData
                 * @property {infinitusapi.IINFPBMTaskInput|null} [pbmTaskInput] TasksMessage pbmTaskInput
                 * @property {infinitusapi.IINFPrescriptionSavingsTaskInput|null} [prescriptionSavingsTaskInput] TasksMessage prescriptionSavingsTaskInput
                 * @property {Array.<infinitusapi.CreateTasksRequest.TasksMessage.IPreviousBvResultsMessage>|null} [previousBvResults] TasksMessage previousBvResults
                 * @property {Array.<infinitusapi.CreateTasksRequest.TasksMessage.IPreviousPBMResultsMessage>|null} [previousPBMResults] TasksMessage previousPBMResults
                 * @property {infinitusapi.IINFPriorAuthTaskInput|null} [priorAuthTaskInput] TasksMessage priorAuthTaskInput
                 * @property {string|null} [programId] TasksMessage programId
                 * @property {boolean|null} [shouldSave] TasksMessage shouldSave
                 * @property {infinitusapi.IINFTaskInput|null} [taskInput] TasksMessage taskInput
                 * @property {string|null} [taskInputJSON] TasksMessage taskInputJSON
                 * @property {infinitusapi.INFTaskType|null} [taskType] TasksMessage taskType
                 * @property {infinitusapi.IINFWebhookBasicAuth|null} [webhookBasicAuth] TasksMessage webhookBasicAuth
                 * @property {string|null} [webhookUrl] TasksMessage webhookUrl
                 * @property {infinitusapi.IINFPBMDiscoveryTaskInput|null} [pbmDiscoveryTaskInput] TasksMessage pbmDiscoveryTaskInput
                 * @property {boolean|null} [chainingRequired] TasksMessage chainingRequired
                 */
    
                /**
                 * Constructs a new TasksMessage.
                 * @memberof infinitusapi.CreateTasksRequest
                 * @classdesc Represents a TasksMessage.
                 * @implements ITasksMessage
                 * @constructor
                 * @param {infinitusapi.CreateTasksRequest.ITasksMessage=} [properties] Properties to set
                 */
                function TasksMessage(properties) {
                    this.previousBvResults = [];
                    this.previousPBMResults = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TasksMessage bvTaskInput.
                 * @member {infinitusapi.IINFBVTaskInput|null|undefined} bvTaskInput
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.bvTaskInput = null;
    
                /**
                 * TasksMessage customerId.
                 * @member {string} customerId
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.customerId = "";
    
                /**
                 * TasksMessage customerSegment.
                 * @member {string} customerSegment
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.customerSegment = "";
    
                /**
                 * TasksMessage customerUrl.
                 * @member {string} customerUrl
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.customerUrl = "";
    
                /**
                 * TasksMessage ehrData.
                 * @member {infinitusapi.IEhrData|null|undefined} ehrData
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.ehrData = null;
    
                /**
                 * TasksMessage pbmTaskInput.
                 * @member {infinitusapi.IINFPBMTaskInput|null|undefined} pbmTaskInput
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.pbmTaskInput = null;
    
                /**
                 * TasksMessage prescriptionSavingsTaskInput.
                 * @member {infinitusapi.IINFPrescriptionSavingsTaskInput|null|undefined} prescriptionSavingsTaskInput
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.prescriptionSavingsTaskInput = null;
    
                /**
                 * TasksMessage previousBvResults.
                 * @member {Array.<infinitusapi.CreateTasksRequest.TasksMessage.IPreviousBvResultsMessage>} previousBvResults
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.previousBvResults = $util.emptyArray;
    
                /**
                 * TasksMessage previousPBMResults.
                 * @member {Array.<infinitusapi.CreateTasksRequest.TasksMessage.IPreviousPBMResultsMessage>} previousPBMResults
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.previousPBMResults = $util.emptyArray;
    
                /**
                 * TasksMessage priorAuthTaskInput.
                 * @member {infinitusapi.IINFPriorAuthTaskInput|null|undefined} priorAuthTaskInput
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.priorAuthTaskInput = null;
    
                /**
                 * TasksMessage programId.
                 * @member {string} programId
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.programId = "";
    
                /**
                 * TasksMessage shouldSave.
                 * @member {boolean} shouldSave
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.shouldSave = false;
    
                /**
                 * TasksMessage taskInput.
                 * @member {infinitusapi.IINFTaskInput|null|undefined} taskInput
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.taskInput = null;
    
                /**
                 * TasksMessage taskInputJSON.
                 * @member {string} taskInputJSON
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.taskInputJSON = "";
    
                /**
                 * TasksMessage taskType.
                 * @member {infinitusapi.INFTaskType} taskType
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.taskType = 0;
    
                /**
                 * TasksMessage webhookBasicAuth.
                 * @member {infinitusapi.IINFWebhookBasicAuth|null|undefined} webhookBasicAuth
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.webhookBasicAuth = null;
    
                /**
                 * TasksMessage webhookUrl.
                 * @member {string} webhookUrl
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.webhookUrl = "";
    
                /**
                 * TasksMessage pbmDiscoveryTaskInput.
                 * @member {infinitusapi.IINFPBMDiscoveryTaskInput|null|undefined} pbmDiscoveryTaskInput
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.pbmDiscoveryTaskInput = null;
    
                /**
                 * TasksMessage chainingRequired.
                 * @member {boolean} chainingRequired
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 */
                TasksMessage.prototype.chainingRequired = false;
    
                /**
                 * Creates a new TasksMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {infinitusapi.CreateTasksRequest.ITasksMessage=} [properties] Properties to set
                 * @returns {infinitusapi.CreateTasksRequest.TasksMessage} TasksMessage instance
                 */
                TasksMessage.create = function create(properties) {
                    return new TasksMessage(properties);
                };
    
                /**
                 * Encodes the specified TasksMessage message. Does not implicitly {@link infinitusapi.CreateTasksRequest.TasksMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {infinitusapi.CreateTasksRequest.ITasksMessage} message TasksMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TasksMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bvTaskInput != null && Object.hasOwnProperty.call(message, "bvTaskInput"))
                        $root.infinitusapi.INFBVTaskInput.encode(message.bvTaskInput, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerId);
                    if (message.customerSegment != null && Object.hasOwnProperty.call(message, "customerSegment"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.customerSegment);
                    if (message.customerUrl != null && Object.hasOwnProperty.call(message, "customerUrl"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.customerUrl);
                    if (message.ehrData != null && Object.hasOwnProperty.call(message, "ehrData"))
                        $root.infinitusapi.EhrData.encode(message.ehrData, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.pbmTaskInput != null && Object.hasOwnProperty.call(message, "pbmTaskInput"))
                        $root.infinitusapi.INFPBMTaskInput.encode(message.pbmTaskInput, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.prescriptionSavingsTaskInput != null && Object.hasOwnProperty.call(message, "prescriptionSavingsTaskInput"))
                        $root.infinitusapi.INFPrescriptionSavingsTaskInput.encode(message.prescriptionSavingsTaskInput, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.previousBvResults != null && message.previousBvResults.length)
                        for (var i = 0; i < message.previousBvResults.length; ++i)
                            $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage.encode(message.previousBvResults[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.previousPBMResults != null && message.previousPBMResults.length)
                        for (var i = 0; i < message.previousPBMResults.length; ++i)
                            $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage.encode(message.previousPBMResults[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.priorAuthTaskInput != null && Object.hasOwnProperty.call(message, "priorAuthTaskInput"))
                        $root.infinitusapi.INFPriorAuthTaskInput.encode(message.priorAuthTaskInput, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.programId);
                    if (message.shouldSave != null && Object.hasOwnProperty.call(message, "shouldSave"))
                        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.shouldSave);
                    if (message.taskInput != null && Object.hasOwnProperty.call(message, "taskInput"))
                        $root.infinitusapi.INFTaskInput.encode(message.taskInput, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.taskInputJSON != null && Object.hasOwnProperty.call(message, "taskInputJSON"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.taskInputJSON);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 15, wireType 0 =*/120).int32(message.taskType);
                    if (message.webhookBasicAuth != null && Object.hasOwnProperty.call(message, "webhookBasicAuth"))
                        $root.infinitusapi.INFWebhookBasicAuth.encode(message.webhookBasicAuth, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    if (message.webhookUrl != null && Object.hasOwnProperty.call(message, "webhookUrl"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.webhookUrl);
                    if (message.pbmDiscoveryTaskInput != null && Object.hasOwnProperty.call(message, "pbmDiscoveryTaskInput"))
                        $root.infinitusapi.INFPBMDiscoveryTaskInput.encode(message.pbmDiscoveryTaskInput, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    if (message.chainingRequired != null && Object.hasOwnProperty.call(message, "chainingRequired"))
                        writer.uint32(/* id 19, wireType 0 =*/152).bool(message.chainingRequired);
                    return writer;
                };
    
                /**
                 * Encodes the specified TasksMessage message, length delimited. Does not implicitly {@link infinitusapi.CreateTasksRequest.TasksMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {infinitusapi.CreateTasksRequest.ITasksMessage} message TasksMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TasksMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TasksMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusapi.CreateTasksRequest.TasksMessage} TasksMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TasksMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CreateTasksRequest.TasksMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.bvTaskInput = $root.infinitusapi.INFBVTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.customerId = reader.string();
                                break;
                            }
                        case 3: {
                                message.customerSegment = reader.string();
                                break;
                            }
                        case 4: {
                                message.customerUrl = reader.string();
                                break;
                            }
                        case 5: {
                                message.ehrData = $root.infinitusapi.EhrData.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.pbmTaskInput = $root.infinitusapi.INFPBMTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.prescriptionSavingsTaskInput = $root.infinitusapi.INFPrescriptionSavingsTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                if (!(message.previousBvResults && message.previousBvResults.length))
                                    message.previousBvResults = [];
                                message.previousBvResults.push($root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage.decode(reader, reader.uint32()));
                                break;
                            }
                        case 9: {
                                if (!(message.previousPBMResults && message.previousPBMResults.length))
                                    message.previousPBMResults = [];
                                message.previousPBMResults.push($root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                message.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.programId = reader.string();
                                break;
                            }
                        case 12: {
                                message.shouldSave = reader.bool();
                                break;
                            }
                        case 13: {
                                message.taskInput = $root.infinitusapi.INFTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.taskInputJSON = reader.string();
                                break;
                            }
                        case 15: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 16: {
                                message.webhookBasicAuth = $root.infinitusapi.INFWebhookBasicAuth.decode(reader, reader.uint32());
                                break;
                            }
                        case 17: {
                                message.webhookUrl = reader.string();
                                break;
                            }
                        case 18: {
                                message.pbmDiscoveryTaskInput = $root.infinitusapi.INFPBMDiscoveryTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 19: {
                                message.chainingRequired = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TasksMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusapi.CreateTasksRequest.TasksMessage} TasksMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TasksMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TasksMessage message.
                 * @function verify
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TasksMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bvTaskInput != null && message.hasOwnProperty("bvTaskInput")) {
                        var error = $root.infinitusapi.INFBVTaskInput.verify(message.bvTaskInput);
                        if (error)
                            return "bvTaskInput." + error;
                    }
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        if (!$util.isString(message.customerId))
                            return "customerId: string expected";
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        if (!$util.isString(message.customerSegment))
                            return "customerSegment: string expected";
                    if (message.customerUrl != null && message.hasOwnProperty("customerUrl"))
                        if (!$util.isString(message.customerUrl))
                            return "customerUrl: string expected";
                    if (message.ehrData != null && message.hasOwnProperty("ehrData")) {
                        var error = $root.infinitusapi.EhrData.verify(message.ehrData);
                        if (error)
                            return "ehrData." + error;
                    }
                    if (message.pbmTaskInput != null && message.hasOwnProperty("pbmTaskInput")) {
                        var error = $root.infinitusapi.INFPBMTaskInput.verify(message.pbmTaskInput);
                        if (error)
                            return "pbmTaskInput." + error;
                    }
                    if (message.prescriptionSavingsTaskInput != null && message.hasOwnProperty("prescriptionSavingsTaskInput")) {
                        var error = $root.infinitusapi.INFPrescriptionSavingsTaskInput.verify(message.prescriptionSavingsTaskInput);
                        if (error)
                            return "prescriptionSavingsTaskInput." + error;
                    }
                    if (message.previousBvResults != null && message.hasOwnProperty("previousBvResults")) {
                        if (!Array.isArray(message.previousBvResults))
                            return "previousBvResults: array expected";
                        for (var i = 0; i < message.previousBvResults.length; ++i) {
                            var error = $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage.verify(message.previousBvResults[i]);
                            if (error)
                                return "previousBvResults." + error;
                        }
                    }
                    if (message.previousPBMResults != null && message.hasOwnProperty("previousPBMResults")) {
                        if (!Array.isArray(message.previousPBMResults))
                            return "previousPBMResults: array expected";
                        for (var i = 0; i < message.previousPBMResults.length; ++i) {
                            var error = $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage.verify(message.previousPBMResults[i]);
                            if (error)
                                return "previousPBMResults." + error;
                        }
                    }
                    if (message.priorAuthTaskInput != null && message.hasOwnProperty("priorAuthTaskInput")) {
                        var error = $root.infinitusapi.INFPriorAuthTaskInput.verify(message.priorAuthTaskInput);
                        if (error)
                            return "priorAuthTaskInput." + error;
                    }
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    if (message.shouldSave != null && message.hasOwnProperty("shouldSave"))
                        if (typeof message.shouldSave !== "boolean")
                            return "shouldSave: boolean expected";
                    if (message.taskInput != null && message.hasOwnProperty("taskInput")) {
                        var error = $root.infinitusapi.INFTaskInput.verify(message.taskInput);
                        if (error)
                            return "taskInput." + error;
                    }
                    if (message.taskInputJSON != null && message.hasOwnProperty("taskInputJSON"))
                        if (!$util.isString(message.taskInputJSON))
                            return "taskInputJSON: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.webhookBasicAuth != null && message.hasOwnProperty("webhookBasicAuth")) {
                        var error = $root.infinitusapi.INFWebhookBasicAuth.verify(message.webhookBasicAuth);
                        if (error)
                            return "webhookBasicAuth." + error;
                    }
                    if (message.webhookUrl != null && message.hasOwnProperty("webhookUrl"))
                        if (!$util.isString(message.webhookUrl))
                            return "webhookUrl: string expected";
                    if (message.pbmDiscoveryTaskInput != null && message.hasOwnProperty("pbmDiscoveryTaskInput")) {
                        var error = $root.infinitusapi.INFPBMDiscoveryTaskInput.verify(message.pbmDiscoveryTaskInput);
                        if (error)
                            return "pbmDiscoveryTaskInput." + error;
                    }
                    if (message.chainingRequired != null && message.hasOwnProperty("chainingRequired"))
                        if (typeof message.chainingRequired !== "boolean")
                            return "chainingRequired: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a TasksMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusapi.CreateTasksRequest.TasksMessage} TasksMessage
                 */
                TasksMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusapi.CreateTasksRequest.TasksMessage)
                        return object;
                    var message = new $root.infinitusapi.CreateTasksRequest.TasksMessage();
                    if (object.bvTaskInput != null) {
                        if (typeof object.bvTaskInput !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.bvTaskInput: object expected");
                        message.bvTaskInput = $root.infinitusapi.INFBVTaskInput.fromObject(object.bvTaskInput);
                    }
                    if (object.customerId != null)
                        message.customerId = String(object.customerId);
                    if (object.customerSegment != null)
                        message.customerSegment = String(object.customerSegment);
                    if (object.customerUrl != null)
                        message.customerUrl = String(object.customerUrl);
                    if (object.ehrData != null) {
                        if (typeof object.ehrData !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.ehrData: object expected");
                        message.ehrData = $root.infinitusapi.EhrData.fromObject(object.ehrData);
                    }
                    if (object.pbmTaskInput != null) {
                        if (typeof object.pbmTaskInput !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.pbmTaskInput: object expected");
                        message.pbmTaskInput = $root.infinitusapi.INFPBMTaskInput.fromObject(object.pbmTaskInput);
                    }
                    if (object.prescriptionSavingsTaskInput != null) {
                        if (typeof object.prescriptionSavingsTaskInput !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.prescriptionSavingsTaskInput: object expected");
                        message.prescriptionSavingsTaskInput = $root.infinitusapi.INFPrescriptionSavingsTaskInput.fromObject(object.prescriptionSavingsTaskInput);
                    }
                    if (object.previousBvResults) {
                        if (!Array.isArray(object.previousBvResults))
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.previousBvResults: array expected");
                        message.previousBvResults = [];
                        for (var i = 0; i < object.previousBvResults.length; ++i) {
                            if (typeof object.previousBvResults[i] !== "object")
                                throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.previousBvResults: object expected");
                            message.previousBvResults[i] = $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage.fromObject(object.previousBvResults[i]);
                        }
                    }
                    if (object.previousPBMResults) {
                        if (!Array.isArray(object.previousPBMResults))
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.previousPBMResults: array expected");
                        message.previousPBMResults = [];
                        for (var i = 0; i < object.previousPBMResults.length; ++i) {
                            if (typeof object.previousPBMResults[i] !== "object")
                                throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.previousPBMResults: object expected");
                            message.previousPBMResults[i] = $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage.fromObject(object.previousPBMResults[i]);
                        }
                    }
                    if (object.priorAuthTaskInput != null) {
                        if (typeof object.priorAuthTaskInput !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.priorAuthTaskInput: object expected");
                        message.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.fromObject(object.priorAuthTaskInput);
                    }
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    if (object.shouldSave != null)
                        message.shouldSave = Boolean(object.shouldSave);
                    if (object.taskInput != null) {
                        if (typeof object.taskInput !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.taskInput: object expected");
                        message.taskInput = $root.infinitusapi.INFTaskInput.fromObject(object.taskInput);
                    }
                    if (object.taskInputJSON != null)
                        message.taskInputJSON = String(object.taskInputJSON);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "INF_TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "INF_TASK_TYPE_BENEFITS_VERIFICATION":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "INF_TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "INF_TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "INF_TASK_TYPE_PATIENT_OUTREACH":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "INF_TASK_TYPE_PROVIDER_OUTREACH":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_MM":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_PBM":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "INF_TASK_TYPE_BASIC_BV":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "INF_TASK_TYPE_PBM_DISCOVERY":
                    case 18:
                        message.taskType = 18;
                        break;
                    }
                    if (object.webhookBasicAuth != null) {
                        if (typeof object.webhookBasicAuth !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.webhookBasicAuth: object expected");
                        message.webhookBasicAuth = $root.infinitusapi.INFWebhookBasicAuth.fromObject(object.webhookBasicAuth);
                    }
                    if (object.webhookUrl != null)
                        message.webhookUrl = String(object.webhookUrl);
                    if (object.pbmDiscoveryTaskInput != null) {
                        if (typeof object.pbmDiscoveryTaskInput !== "object")
                            throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.pbmDiscoveryTaskInput: object expected");
                        message.pbmDiscoveryTaskInput = $root.infinitusapi.INFPBMDiscoveryTaskInput.fromObject(object.pbmDiscoveryTaskInput);
                    }
                    if (object.chainingRequired != null)
                        message.chainingRequired = Boolean(object.chainingRequired);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TasksMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {infinitusapi.CreateTasksRequest.TasksMessage} message TasksMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TasksMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.previousBvResults = [];
                        object.previousPBMResults = [];
                    }
                    if (options.defaults) {
                        object.bvTaskInput = null;
                        object.customerId = "";
                        object.customerSegment = "";
                        object.customerUrl = "";
                        object.ehrData = null;
                        object.pbmTaskInput = null;
                        object.prescriptionSavingsTaskInput = null;
                        object.priorAuthTaskInput = null;
                        object.programId = "";
                        object.shouldSave = false;
                        object.taskInput = null;
                        object.taskInputJSON = "";
                        object.taskType = options.enums === String ? "INF_TASK_TYPE_UNKNOWN" : 0;
                        object.webhookBasicAuth = null;
                        object.webhookUrl = "";
                        object.pbmDiscoveryTaskInput = null;
                        object.chainingRequired = false;
                    }
                    if (message.bvTaskInput != null && message.hasOwnProperty("bvTaskInput"))
                        object.bvTaskInput = $root.infinitusapi.INFBVTaskInput.toObject(message.bvTaskInput, options);
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        object.customerId = message.customerId;
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        object.customerSegment = message.customerSegment;
                    if (message.customerUrl != null && message.hasOwnProperty("customerUrl"))
                        object.customerUrl = message.customerUrl;
                    if (message.ehrData != null && message.hasOwnProperty("ehrData"))
                        object.ehrData = $root.infinitusapi.EhrData.toObject(message.ehrData, options);
                    if (message.pbmTaskInput != null && message.hasOwnProperty("pbmTaskInput"))
                        object.pbmTaskInput = $root.infinitusapi.INFPBMTaskInput.toObject(message.pbmTaskInput, options);
                    if (message.prescriptionSavingsTaskInput != null && message.hasOwnProperty("prescriptionSavingsTaskInput"))
                        object.prescriptionSavingsTaskInput = $root.infinitusapi.INFPrescriptionSavingsTaskInput.toObject(message.prescriptionSavingsTaskInput, options);
                    if (message.previousBvResults && message.previousBvResults.length) {
                        object.previousBvResults = [];
                        for (var j = 0; j < message.previousBvResults.length; ++j)
                            object.previousBvResults[j] = $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage.toObject(message.previousBvResults[j], options);
                    }
                    if (message.previousPBMResults && message.previousPBMResults.length) {
                        object.previousPBMResults = [];
                        for (var j = 0; j < message.previousPBMResults.length; ++j)
                            object.previousPBMResults[j] = $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage.toObject(message.previousPBMResults[j], options);
                    }
                    if (message.priorAuthTaskInput != null && message.hasOwnProperty("priorAuthTaskInput"))
                        object.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.toObject(message.priorAuthTaskInput, options);
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    if (message.shouldSave != null && message.hasOwnProperty("shouldSave"))
                        object.shouldSave = message.shouldSave;
                    if (message.taskInput != null && message.hasOwnProperty("taskInput"))
                        object.taskInput = $root.infinitusapi.INFTaskInput.toObject(message.taskInput, options);
                    if (message.taskInputJSON != null && message.hasOwnProperty("taskInputJSON"))
                        object.taskInputJSON = message.taskInputJSON;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusapi.INFTaskType[message.taskType] === undefined ? message.taskType : $root.infinitusapi.INFTaskType[message.taskType] : message.taskType;
                    if (message.webhookBasicAuth != null && message.hasOwnProperty("webhookBasicAuth"))
                        object.webhookBasicAuth = $root.infinitusapi.INFWebhookBasicAuth.toObject(message.webhookBasicAuth, options);
                    if (message.webhookUrl != null && message.hasOwnProperty("webhookUrl"))
                        object.webhookUrl = message.webhookUrl;
                    if (message.pbmDiscoveryTaskInput != null && message.hasOwnProperty("pbmDiscoveryTaskInput"))
                        object.pbmDiscoveryTaskInput = $root.infinitusapi.INFPBMDiscoveryTaskInput.toObject(message.pbmDiscoveryTaskInput, options);
                    if (message.chainingRequired != null && message.hasOwnProperty("chainingRequired"))
                        object.chainingRequired = message.chainingRequired;
                    return object;
                };
    
                /**
                 * Converts this TasksMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TasksMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TasksMessage
                 * @function getTypeUrl
                 * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TasksMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusapi.CreateTasksRequest.TasksMessage";
                };
    
                TasksMessage.PreviousBvResultsMessage = (function() {
    
                    /**
                     * Properties of a PreviousBvResultsMessage.
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                     * @interface IPreviousBvResultsMessage
                     * @property {infinitusapi.IINFBVTaskOutput|null} [bvTaskOutputs] PreviousBvResultsMessage bvTaskOutputs
                     * @property {string|null} [previousBvDate] PreviousBvResultsMessage previousBvDate
                     * @property {string|null} [taskId] PreviousBvResultsMessage taskId
                     */
    
                    /**
                     * Constructs a new PreviousBvResultsMessage.
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                     * @classdesc Represents a PreviousBvResultsMessage.
                     * @implements IPreviousBvResultsMessage
                     * @constructor
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.IPreviousBvResultsMessage=} [properties] Properties to set
                     */
                    function PreviousBvResultsMessage(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PreviousBvResultsMessage bvTaskOutputs.
                     * @member {infinitusapi.IINFBVTaskOutput|null|undefined} bvTaskOutputs
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @instance
                     */
                    PreviousBvResultsMessage.prototype.bvTaskOutputs = null;
    
                    /**
                     * PreviousBvResultsMessage previousBvDate.
                     * @member {string} previousBvDate
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @instance
                     */
                    PreviousBvResultsMessage.prototype.previousBvDate = "";
    
                    /**
                     * PreviousBvResultsMessage taskId.
                     * @member {string} taskId
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @instance
                     */
                    PreviousBvResultsMessage.prototype.taskId = "";
    
                    /**
                     * Creates a new PreviousBvResultsMessage instance using the specified properties.
                     * @function create
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.IPreviousBvResultsMessage=} [properties] Properties to set
                     * @returns {infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage} PreviousBvResultsMessage instance
                     */
                    PreviousBvResultsMessage.create = function create(properties) {
                        return new PreviousBvResultsMessage(properties);
                    };
    
                    /**
                     * Encodes the specified PreviousBvResultsMessage message. Does not implicitly {@link infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.IPreviousBvResultsMessage} message PreviousBvResultsMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreviousBvResultsMessage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.bvTaskOutputs != null && Object.hasOwnProperty.call(message, "bvTaskOutputs"))
                            $root.infinitusapi.INFBVTaskOutput.encode(message.bvTaskOutputs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.previousBvDate != null && Object.hasOwnProperty.call(message, "previousBvDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.previousBvDate);
                        if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PreviousBvResultsMessage message, length delimited. Does not implicitly {@link infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.IPreviousBvResultsMessage} message PreviousBvResultsMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreviousBvResultsMessage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PreviousBvResultsMessage message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage} PreviousBvResultsMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreviousBvResultsMessage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.bvTaskOutputs = $root.infinitusapi.INFBVTaskOutput.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    message.previousBvDate = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.taskId = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PreviousBvResultsMessage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage} PreviousBvResultsMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreviousBvResultsMessage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PreviousBvResultsMessage message.
                     * @function verify
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PreviousBvResultsMessage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.bvTaskOutputs != null && message.hasOwnProperty("bvTaskOutputs")) {
                            var error = $root.infinitusapi.INFBVTaskOutput.verify(message.bvTaskOutputs);
                            if (error)
                                return "bvTaskOutputs." + error;
                        }
                        if (message.previousBvDate != null && message.hasOwnProperty("previousBvDate"))
                            if (!$util.isString(message.previousBvDate))
                                return "previousBvDate: string expected";
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            if (!$util.isString(message.taskId))
                                return "taskId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a PreviousBvResultsMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage} PreviousBvResultsMessage
                     */
                    PreviousBvResultsMessage.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage)
                            return object;
                        var message = new $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage();
                        if (object.bvTaskOutputs != null) {
                            if (typeof object.bvTaskOutputs !== "object")
                                throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage.bvTaskOutputs: object expected");
                            message.bvTaskOutputs = $root.infinitusapi.INFBVTaskOutput.fromObject(object.bvTaskOutputs);
                        }
                        if (object.previousBvDate != null)
                            message.previousBvDate = String(object.previousBvDate);
                        if (object.taskId != null)
                            message.taskId = String(object.taskId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PreviousBvResultsMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage} message PreviousBvResultsMessage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PreviousBvResultsMessage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.bvTaskOutputs = null;
                            object.previousBvDate = "";
                            object.taskId = "";
                        }
                        if (message.bvTaskOutputs != null && message.hasOwnProperty("bvTaskOutputs"))
                            object.bvTaskOutputs = $root.infinitusapi.INFBVTaskOutput.toObject(message.bvTaskOutputs, options);
                        if (message.previousBvDate != null && message.hasOwnProperty("previousBvDate"))
                            object.previousBvDate = message.previousBvDate;
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            object.taskId = message.taskId;
                        return object;
                    };
    
                    /**
                     * Converts this PreviousBvResultsMessage to JSON.
                     * @function toJSON
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PreviousBvResultsMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for PreviousBvResultsMessage
                     * @function getTypeUrl
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    PreviousBvResultsMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusapi.CreateTasksRequest.TasksMessage.PreviousBvResultsMessage";
                    };
    
                    return PreviousBvResultsMessage;
                })();
    
                TasksMessage.PreviousPBMResultsMessage = (function() {
    
                    /**
                     * Properties of a PreviousPBMResultsMessage.
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                     * @interface IPreviousPBMResultsMessage
                     * @property {infinitusapi.IINFPBMTaskOutput|null} [pbmTaskOutputs] PreviousPBMResultsMessage pbmTaskOutputs
                     * @property {string|null} [previousPbmDate] PreviousPBMResultsMessage previousPbmDate
                     * @property {string|null} [taskId] PreviousPBMResultsMessage taskId
                     */
    
                    /**
                     * Constructs a new PreviousPBMResultsMessage.
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage
                     * @classdesc Represents a PreviousPBMResultsMessage.
                     * @implements IPreviousPBMResultsMessage
                     * @constructor
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.IPreviousPBMResultsMessage=} [properties] Properties to set
                     */
                    function PreviousPBMResultsMessage(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PreviousPBMResultsMessage pbmTaskOutputs.
                     * @member {infinitusapi.IINFPBMTaskOutput|null|undefined} pbmTaskOutputs
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @instance
                     */
                    PreviousPBMResultsMessage.prototype.pbmTaskOutputs = null;
    
                    /**
                     * PreviousPBMResultsMessage previousPbmDate.
                     * @member {string} previousPbmDate
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @instance
                     */
                    PreviousPBMResultsMessage.prototype.previousPbmDate = "";
    
                    /**
                     * PreviousPBMResultsMessage taskId.
                     * @member {string} taskId
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @instance
                     */
                    PreviousPBMResultsMessage.prototype.taskId = "";
    
                    /**
                     * Creates a new PreviousPBMResultsMessage instance using the specified properties.
                     * @function create
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.IPreviousPBMResultsMessage=} [properties] Properties to set
                     * @returns {infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage} PreviousPBMResultsMessage instance
                     */
                    PreviousPBMResultsMessage.create = function create(properties) {
                        return new PreviousPBMResultsMessage(properties);
                    };
    
                    /**
                     * Encodes the specified PreviousPBMResultsMessage message. Does not implicitly {@link infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage.verify|verify} messages.
                     * @function encode
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.IPreviousPBMResultsMessage} message PreviousPBMResultsMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreviousPBMResultsMessage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pbmTaskOutputs != null && Object.hasOwnProperty.call(message, "pbmTaskOutputs"))
                            $root.infinitusapi.INFPBMTaskOutput.encode(message.pbmTaskOutputs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.previousPbmDate != null && Object.hasOwnProperty.call(message, "previousPbmDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.previousPbmDate);
                        if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PreviousPBMResultsMessage message, length delimited. Does not implicitly {@link infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.IPreviousPBMResultsMessage} message PreviousPBMResultsMessage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreviousPBMResultsMessage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PreviousPBMResultsMessage message from the specified reader or buffer.
                     * @function decode
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage} PreviousPBMResultsMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreviousPBMResultsMessage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.pbmTaskOutputs = $root.infinitusapi.INFPBMTaskOutput.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    message.previousPbmDate = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.taskId = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PreviousPBMResultsMessage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage} PreviousPBMResultsMessage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreviousPBMResultsMessage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PreviousPBMResultsMessage message.
                     * @function verify
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PreviousPBMResultsMessage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pbmTaskOutputs != null && message.hasOwnProperty("pbmTaskOutputs")) {
                            var error = $root.infinitusapi.INFPBMTaskOutput.verify(message.pbmTaskOutputs);
                            if (error)
                                return "pbmTaskOutputs." + error;
                        }
                        if (message.previousPbmDate != null && message.hasOwnProperty("previousPbmDate"))
                            if (!$util.isString(message.previousPbmDate))
                                return "previousPbmDate: string expected";
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            if (!$util.isString(message.taskId))
                                return "taskId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a PreviousPBMResultsMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage} PreviousPBMResultsMessage
                     */
                    PreviousPBMResultsMessage.fromObject = function fromObject(object) {
                        if (object instanceof $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage)
                            return object;
                        var message = new $root.infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage();
                        if (object.pbmTaskOutputs != null) {
                            if (typeof object.pbmTaskOutputs !== "object")
                                throw TypeError(".infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage.pbmTaskOutputs: object expected");
                            message.pbmTaskOutputs = $root.infinitusapi.INFPBMTaskOutput.fromObject(object.pbmTaskOutputs);
                        }
                        if (object.previousPbmDate != null)
                            message.previousPbmDate = String(object.previousPbmDate);
                        if (object.taskId != null)
                            message.taskId = String(object.taskId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PreviousPBMResultsMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage} message PreviousPBMResultsMessage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PreviousPBMResultsMessage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pbmTaskOutputs = null;
                            object.previousPbmDate = "";
                            object.taskId = "";
                        }
                        if (message.pbmTaskOutputs != null && message.hasOwnProperty("pbmTaskOutputs"))
                            object.pbmTaskOutputs = $root.infinitusapi.INFPBMTaskOutput.toObject(message.pbmTaskOutputs, options);
                        if (message.previousPbmDate != null && message.hasOwnProperty("previousPbmDate"))
                            object.previousPbmDate = message.previousPbmDate;
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            object.taskId = message.taskId;
                        return object;
                    };
    
                    /**
                     * Converts this PreviousPBMResultsMessage to JSON.
                     * @function toJSON
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PreviousPBMResultsMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for PreviousPBMResultsMessage
                     * @function getTypeUrl
                     * @memberof infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    PreviousPBMResultsMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/infinitusapi.CreateTasksRequest.TasksMessage.PreviousPBMResultsMessage";
                    };
    
                    return PreviousPBMResultsMessage;
                })();
    
                return TasksMessage;
            })();
    
            return CreateTasksRequest;
        })();
    
        infinitusapi.CreateTasksResponse = (function() {
    
            /**
             * Properties of a CreateTasksResponse.
             * @memberof infinitusapi
             * @interface ICreateTasksResponse
             * @property {Array.<infinitusapi.CreateTasksResponse.IDataMessage>|null} [data] CreateTasksResponse data
             */
    
            /**
             * Constructs a new CreateTasksResponse.
             * @memberof infinitusapi
             * @classdesc Represents a CreateTasksResponse.
             * @implements ICreateTasksResponse
             * @constructor
             * @param {infinitusapi.ICreateTasksResponse=} [properties] Properties to set
             */
            function CreateTasksResponse(properties) {
                this.data = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * CreateTasksResponse data.
             * @member {Array.<infinitusapi.CreateTasksResponse.IDataMessage>} data
             * @memberof infinitusapi.CreateTasksResponse
             * @instance
             */
            CreateTasksResponse.prototype.data = $util.emptyArray;
    
            /**
             * Creates a new CreateTasksResponse instance using the specified properties.
             * @function create
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {infinitusapi.ICreateTasksResponse=} [properties] Properties to set
             * @returns {infinitusapi.CreateTasksResponse} CreateTasksResponse instance
             */
            CreateTasksResponse.create = function create(properties) {
                return new CreateTasksResponse(properties);
            };
    
            /**
             * Encodes the specified CreateTasksResponse message. Does not implicitly {@link infinitusapi.CreateTasksResponse.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {infinitusapi.ICreateTasksResponse} message CreateTasksResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateTasksResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && message.data.length)
                    for (var i = 0; i < message.data.length; ++i)
                        $root.infinitusapi.CreateTasksResponse.DataMessage.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified CreateTasksResponse message, length delimited. Does not implicitly {@link infinitusapi.CreateTasksResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {infinitusapi.ICreateTasksResponse} message CreateTasksResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateTasksResponse message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.CreateTasksResponse} CreateTasksResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateTasksResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CreateTasksResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.data && message.data.length))
                                message.data = [];
                            message.data.push($root.infinitusapi.CreateTasksResponse.DataMessage.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateTasksResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.CreateTasksResponse} CreateTasksResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateTasksResponse message.
             * @function verify
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateTasksResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data")) {
                    if (!Array.isArray(message.data))
                        return "data: array expected";
                    for (var i = 0; i < message.data.length; ++i) {
                        var error = $root.infinitusapi.CreateTasksResponse.DataMessage.verify(message.data[i]);
                        if (error)
                            return "data." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a CreateTasksResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.CreateTasksResponse} CreateTasksResponse
             */
            CreateTasksResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.CreateTasksResponse)
                    return object;
                var message = new $root.infinitusapi.CreateTasksResponse();
                if (object.data) {
                    if (!Array.isArray(object.data))
                        throw TypeError(".infinitusapi.CreateTasksResponse.data: array expected");
                    message.data = [];
                    for (var i = 0; i < object.data.length; ++i) {
                        if (typeof object.data[i] !== "object")
                            throw TypeError(".infinitusapi.CreateTasksResponse.data: object expected");
                        message.data[i] = $root.infinitusapi.CreateTasksResponse.DataMessage.fromObject(object.data[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a CreateTasksResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {infinitusapi.CreateTasksResponse} message CreateTasksResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateTasksResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.data = [];
                if (message.data && message.data.length) {
                    object.data = [];
                    for (var j = 0; j < message.data.length; ++j)
                        object.data[j] = $root.infinitusapi.CreateTasksResponse.DataMessage.toObject(message.data[j], options);
                }
                return object;
            };
    
            /**
             * Converts this CreateTasksResponse to JSON.
             * @function toJSON
             * @memberof infinitusapi.CreateTasksResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateTasksResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for CreateTasksResponse
             * @function getTypeUrl
             * @memberof infinitusapi.CreateTasksResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.CreateTasksResponse";
            };
    
            CreateTasksResponse.DataMessage = (function() {
    
                /**
                 * Properties of a DataMessage.
                 * @memberof infinitusapi.CreateTasksResponse
                 * @interface IDataMessage
                 * @property {string|null} [customerId] DataMessage customerId
                 * @property {string|null} [customerSegment] DataMessage customerSegment
                 * @property {infinitusapi.IINFCreateTasksError|null} [error] DataMessage error
                 * @property {Array.<infinitusapi.IINFCreateTasksError>|null} [errors] DataMessage errors
                 * @property {string|null} [infinitusPayerId] DataMessage infinitusPayerId
                 * @property {boolean|null} [isSaved] DataMessage isSaved
                 * @property {string|null} [programId] DataMessage programId
                 * @property {string|null} [taskId] DataMessage taskId
                 */
    
                /**
                 * Constructs a new DataMessage.
                 * @memberof infinitusapi.CreateTasksResponse
                 * @classdesc Represents a DataMessage.
                 * @implements IDataMessage
                 * @constructor
                 * @param {infinitusapi.CreateTasksResponse.IDataMessage=} [properties] Properties to set
                 */
                function DataMessage(properties) {
                    this.errors = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DataMessage customerId.
                 * @member {string} customerId
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.customerId = "";
    
                /**
                 * DataMessage customerSegment.
                 * @member {string} customerSegment
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.customerSegment = "";
    
                /**
                 * DataMessage error.
                 * @member {infinitusapi.IINFCreateTasksError|null|undefined} error
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.error = null;
    
                /**
                 * DataMessage errors.
                 * @member {Array.<infinitusapi.IINFCreateTasksError>} errors
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.errors = $util.emptyArray;
    
                /**
                 * DataMessage infinitusPayerId.
                 * @member {string} infinitusPayerId
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.infinitusPayerId = "";
    
                /**
                 * DataMessage isSaved.
                 * @member {boolean} isSaved
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.isSaved = false;
    
                /**
                 * DataMessage programId.
                 * @member {string} programId
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.programId = "";
    
                /**
                 * DataMessage taskId.
                 * @member {string} taskId
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskId = "";
    
                /**
                 * Creates a new DataMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CreateTasksResponse.IDataMessage=} [properties] Properties to set
                 * @returns {infinitusapi.CreateTasksResponse.DataMessage} DataMessage instance
                 */
                DataMessage.create = function create(properties) {
                    return new DataMessage(properties);
                };
    
                /**
                 * Encodes the specified DataMessage message. Does not implicitly {@link infinitusapi.CreateTasksResponse.DataMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CreateTasksResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
                    if (message.customerSegment != null && Object.hasOwnProperty.call(message, "customerSegment"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerSegment);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        $root.infinitusapi.INFCreateTasksError.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.errors != null && message.errors.length)
                        for (var i = 0; i < message.errors.length; ++i)
                            $root.infinitusapi.INFCreateTasksError.encode(message.errors[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.infinitusPayerId != null && Object.hasOwnProperty.call(message, "infinitusPayerId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.infinitusPayerId);
                    if (message.isSaved != null && Object.hasOwnProperty.call(message, "isSaved"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isSaved);
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.programId);
                    if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.taskId);
                    return writer;
                };
    
                /**
                 * Encodes the specified DataMessage message, length delimited. Does not implicitly {@link infinitusapi.CreateTasksResponse.DataMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CreateTasksResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusapi.CreateTasksResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.CreateTasksResponse.DataMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.customerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.customerSegment = reader.string();
                                break;
                            }
                        case 3: {
                                message.error = $root.infinitusapi.INFCreateTasksError.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                if (!(message.errors && message.errors.length))
                                    message.errors = [];
                                message.errors.push($root.infinitusapi.INFCreateTasksError.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                message.infinitusPayerId = reader.string();
                                break;
                            }
                        case 6: {
                                message.isSaved = reader.bool();
                                break;
                            }
                        case 7: {
                                message.programId = reader.string();
                                break;
                            }
                        case 8: {
                                message.taskId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusapi.CreateTasksResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DataMessage message.
                 * @function verify
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DataMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        if (!$util.isString(message.customerId))
                            return "customerId: string expected";
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        if (!$util.isString(message.customerSegment))
                            return "customerSegment: string expected";
                    if (message.error != null && message.hasOwnProperty("error")) {
                        var error = $root.infinitusapi.INFCreateTasksError.verify(message.error);
                        if (error)
                            return "error." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (var i = 0; i < message.errors.length; ++i) {
                            var error = $root.infinitusapi.INFCreateTasksError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                        if (!$util.isString(message.infinitusPayerId))
                            return "infinitusPayerId: string expected";
                    if (message.isSaved != null && message.hasOwnProperty("isSaved"))
                        if (typeof message.isSaved !== "boolean")
                            return "isSaved: boolean expected";
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        if (!$util.isString(message.taskId))
                            return "taskId: string expected";
                    return null;
                };
    
                /**
                 * Creates a DataMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusapi.CreateTasksResponse.DataMessage} DataMessage
                 */
                DataMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusapi.CreateTasksResponse.DataMessage)
                        return object;
                    var message = new $root.infinitusapi.CreateTasksResponse.DataMessage();
                    if (object.customerId != null)
                        message.customerId = String(object.customerId);
                    if (object.customerSegment != null)
                        message.customerSegment = String(object.customerSegment);
                    if (object.error != null) {
                        if (typeof object.error !== "object")
                            throw TypeError(".infinitusapi.CreateTasksResponse.DataMessage.error: object expected");
                        message.error = $root.infinitusapi.INFCreateTasksError.fromObject(object.error);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".infinitusapi.CreateTasksResponse.DataMessage.errors: array expected");
                        message.errors = [];
                        for (var i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".infinitusapi.CreateTasksResponse.DataMessage.errors: object expected");
                            message.errors[i] = $root.infinitusapi.INFCreateTasksError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.infinitusPayerId != null)
                        message.infinitusPayerId = String(object.infinitusPayerId);
                    if (object.isSaved != null)
                        message.isSaved = Boolean(object.isSaved);
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    if (object.taskId != null)
                        message.taskId = String(object.taskId);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DataMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {infinitusapi.CreateTasksResponse.DataMessage} message DataMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DataMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.customerId = "";
                        object.customerSegment = "";
                        object.error = null;
                        object.infinitusPayerId = "";
                        object.isSaved = false;
                        object.programId = "";
                        object.taskId = "";
                    }
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        object.customerId = message.customerId;
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        object.customerSegment = message.customerSegment;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = $root.infinitusapi.INFCreateTasksError.toObject(message.error, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (var j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.infinitusapi.INFCreateTasksError.toObject(message.errors[j], options);
                    }
                    if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                        object.infinitusPayerId = message.infinitusPayerId;
                    if (message.isSaved != null && message.hasOwnProperty("isSaved"))
                        object.isSaved = message.isSaved;
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        object.taskId = message.taskId;
                    return object;
                };
    
                /**
                 * Converts this DataMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DataMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DataMessage
                 * @function getTypeUrl
                 * @memberof infinitusapi.CreateTasksResponse.DataMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DataMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusapi.CreateTasksResponse.DataMessage";
                };
    
                return DataMessage;
            })();
    
            return CreateTasksResponse;
        })();
    
        infinitusapi.EhrData = (function() {
    
            /**
             * Properties of an EhrData.
             * @memberof infinitusapi
             * @interface IEhrData
             * @property {string|null} [episodeId] EhrData episodeId
             * @property {string|null} [patientId] EhrData patientId
             */
    
            /**
             * Constructs a new EhrData.
             * @memberof infinitusapi
             * @classdesc Represents an EhrData.
             * @implements IEhrData
             * @constructor
             * @param {infinitusapi.IEhrData=} [properties] Properties to set
             */
            function EhrData(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * EhrData episodeId.
             * @member {string} episodeId
             * @memberof infinitusapi.EhrData
             * @instance
             */
            EhrData.prototype.episodeId = "";
    
            /**
             * EhrData patientId.
             * @member {string} patientId
             * @memberof infinitusapi.EhrData
             * @instance
             */
            EhrData.prototype.patientId = "";
    
            /**
             * Creates a new EhrData instance using the specified properties.
             * @function create
             * @memberof infinitusapi.EhrData
             * @static
             * @param {infinitusapi.IEhrData=} [properties] Properties to set
             * @returns {infinitusapi.EhrData} EhrData instance
             */
            EhrData.create = function create(properties) {
                return new EhrData(properties);
            };
    
            /**
             * Encodes the specified EhrData message. Does not implicitly {@link infinitusapi.EhrData.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.EhrData
             * @static
             * @param {infinitusapi.IEhrData} message EhrData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EhrData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.episodeId != null && Object.hasOwnProperty.call(message, "episodeId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.episodeId);
                if (message.patientId != null && Object.hasOwnProperty.call(message, "patientId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.patientId);
                return writer;
            };
    
            /**
             * Encodes the specified EhrData message, length delimited. Does not implicitly {@link infinitusapi.EhrData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.EhrData
             * @static
             * @param {infinitusapi.IEhrData} message EhrData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EhrData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an EhrData message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.EhrData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.EhrData} EhrData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EhrData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.EhrData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.episodeId = reader.string();
                            break;
                        }
                    case 2: {
                            message.patientId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an EhrData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.EhrData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.EhrData} EhrData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EhrData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an EhrData message.
             * @function verify
             * @memberof infinitusapi.EhrData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EhrData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.episodeId != null && message.hasOwnProperty("episodeId"))
                    if (!$util.isString(message.episodeId))
                        return "episodeId: string expected";
                if (message.patientId != null && message.hasOwnProperty("patientId"))
                    if (!$util.isString(message.patientId))
                        return "patientId: string expected";
                return null;
            };
    
            /**
             * Creates an EhrData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.EhrData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.EhrData} EhrData
             */
            EhrData.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.EhrData)
                    return object;
                var message = new $root.infinitusapi.EhrData();
                if (object.episodeId != null)
                    message.episodeId = String(object.episodeId);
                if (object.patientId != null)
                    message.patientId = String(object.patientId);
                return message;
            };
    
            /**
             * Creates a plain object from an EhrData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.EhrData
             * @static
             * @param {infinitusapi.EhrData} message EhrData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EhrData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.episodeId = "";
                    object.patientId = "";
                }
                if (message.episodeId != null && message.hasOwnProperty("episodeId"))
                    object.episodeId = message.episodeId;
                if (message.patientId != null && message.hasOwnProperty("patientId"))
                    object.patientId = message.patientId;
                return object;
            };
    
            /**
             * Converts this EhrData to JSON.
             * @function toJSON
             * @memberof infinitusapi.EhrData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EhrData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for EhrData
             * @function getTypeUrl
             * @memberof infinitusapi.EhrData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EhrData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.EhrData";
            };
    
            return EhrData;
        })();
    
        infinitusapi.GetMyOrgResponse = (function() {
    
            /**
             * Properties of a GetMyOrgResponse.
             * @memberof infinitusapi
             * @interface IGetMyOrgResponse
             * @property {infinitusapi.GetMyOrgResponse.IDataMessage|null} [data] GetMyOrgResponse data
             */
    
            /**
             * Constructs a new GetMyOrgResponse.
             * @memberof infinitusapi
             * @classdesc Represents a GetMyOrgResponse.
             * @implements IGetMyOrgResponse
             * @constructor
             * @param {infinitusapi.IGetMyOrgResponse=} [properties] Properties to set
             */
            function GetMyOrgResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GetMyOrgResponse data.
             * @member {infinitusapi.GetMyOrgResponse.IDataMessage|null|undefined} data
             * @memberof infinitusapi.GetMyOrgResponse
             * @instance
             */
            GetMyOrgResponse.prototype.data = null;
    
            /**
             * Creates a new GetMyOrgResponse instance using the specified properties.
             * @function create
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {infinitusapi.IGetMyOrgResponse=} [properties] Properties to set
             * @returns {infinitusapi.GetMyOrgResponse} GetMyOrgResponse instance
             */
            GetMyOrgResponse.create = function create(properties) {
                return new GetMyOrgResponse(properties);
            };
    
            /**
             * Encodes the specified GetMyOrgResponse message. Does not implicitly {@link infinitusapi.GetMyOrgResponse.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {infinitusapi.IGetMyOrgResponse} message GetMyOrgResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetMyOrgResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    $root.infinitusapi.GetMyOrgResponse.DataMessage.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified GetMyOrgResponse message, length delimited. Does not implicitly {@link infinitusapi.GetMyOrgResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {infinitusapi.IGetMyOrgResponse} message GetMyOrgResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetMyOrgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GetMyOrgResponse message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.GetMyOrgResponse} GetMyOrgResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetMyOrgResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.GetMyOrgResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.data = $root.infinitusapi.GetMyOrgResponse.DataMessage.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GetMyOrgResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.GetMyOrgResponse} GetMyOrgResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetMyOrgResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GetMyOrgResponse message.
             * @function verify
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetMyOrgResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data")) {
                    var error = $root.infinitusapi.GetMyOrgResponse.DataMessage.verify(message.data);
                    if (error)
                        return "data." + error;
                }
                return null;
            };
    
            /**
             * Creates a GetMyOrgResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.GetMyOrgResponse} GetMyOrgResponse
             */
            GetMyOrgResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.GetMyOrgResponse)
                    return object;
                var message = new $root.infinitusapi.GetMyOrgResponse();
                if (object.data != null) {
                    if (typeof object.data !== "object")
                        throw TypeError(".infinitusapi.GetMyOrgResponse.data: object expected");
                    message.data = $root.infinitusapi.GetMyOrgResponse.DataMessage.fromObject(object.data);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GetMyOrgResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {infinitusapi.GetMyOrgResponse} message GetMyOrgResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetMyOrgResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.data = null;
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = $root.infinitusapi.GetMyOrgResponse.DataMessage.toObject(message.data, options);
                return object;
            };
    
            /**
             * Converts this GetMyOrgResponse to JSON.
             * @function toJSON
             * @memberof infinitusapi.GetMyOrgResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetMyOrgResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GetMyOrgResponse
             * @function getTypeUrl
             * @memberof infinitusapi.GetMyOrgResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetMyOrgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.GetMyOrgResponse";
            };
    
            GetMyOrgResponse.DataMessage = (function() {
    
                /**
                 * Properties of a DataMessage.
                 * @memberof infinitusapi.GetMyOrgResponse
                 * @interface IDataMessage
                 * @property {string|null} [displayName] DataMessage displayName
                 * @property {string|null} [id] DataMessage id
                 * @property {string|null} [imageUrl] DataMessage imageUrl
                 * @property {string|null} [name] DataMessage name
                 */
    
                /**
                 * Constructs a new DataMessage.
                 * @memberof infinitusapi.GetMyOrgResponse
                 * @classdesc Represents a DataMessage.
                 * @implements IDataMessage
                 * @constructor
                 * @param {infinitusapi.GetMyOrgResponse.IDataMessage=} [properties] Properties to set
                 */
                function DataMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DataMessage displayName.
                 * @member {string} displayName
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.displayName = "";
    
                /**
                 * DataMessage id.
                 * @member {string} id
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.id = "";
    
                /**
                 * DataMessage imageUrl.
                 * @member {string} imageUrl
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.imageUrl = "";
    
                /**
                 * DataMessage name.
                 * @member {string} name
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.name = "";
    
                /**
                 * Creates a new DataMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetMyOrgResponse.IDataMessage=} [properties] Properties to set
                 * @returns {infinitusapi.GetMyOrgResponse.DataMessage} DataMessage instance
                 */
                DataMessage.create = function create(properties) {
                    return new DataMessage(properties);
                };
    
                /**
                 * Encodes the specified DataMessage message. Does not implicitly {@link infinitusapi.GetMyOrgResponse.DataMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetMyOrgResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.displayName);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
                    if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.imageUrl);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                    return writer;
                };
    
                /**
                 * Encodes the specified DataMessage message, length delimited. Does not implicitly {@link infinitusapi.GetMyOrgResponse.DataMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetMyOrgResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusapi.GetMyOrgResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.GetMyOrgResponse.DataMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 2: {
                                message.id = reader.string();
                                break;
                            }
                        case 3: {
                                message.imageUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.name = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusapi.GetMyOrgResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DataMessage message.
                 * @function verify
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DataMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        if (!$util.isString(message.imageUrl))
                            return "imageUrl: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    return null;
                };
    
                /**
                 * Creates a DataMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusapi.GetMyOrgResponse.DataMessage} DataMessage
                 */
                DataMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusapi.GetMyOrgResponse.DataMessage)
                        return object;
                    var message = new $root.infinitusapi.GetMyOrgResponse.DataMessage();
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.imageUrl != null)
                        message.imageUrl = String(object.imageUrl);
                    if (object.name != null)
                        message.name = String(object.name);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DataMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetMyOrgResponse.DataMessage} message DataMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DataMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.displayName = "";
                        object.id = "";
                        object.imageUrl = "";
                        object.name = "";
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        object.imageUrl = message.imageUrl;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    return object;
                };
    
                /**
                 * Converts this DataMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DataMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DataMessage
                 * @function getTypeUrl
                 * @memberof infinitusapi.GetMyOrgResponse.DataMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DataMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusapi.GetMyOrgResponse.DataMessage";
                };
    
                return DataMessage;
            })();
    
            return GetMyOrgResponse;
        })();
    
        infinitusapi.GetTaskRecordingsResponse = (function() {
    
            /**
             * Properties of a GetTaskRecordingsResponse.
             * @memberof infinitusapi
             * @interface IGetTaskRecordingsResponse
             * @property {Array.<infinitusapi.GetTaskRecordingsResponse.IDataMessage>|null} [data] GetTaskRecordingsResponse data
             */
    
            /**
             * Constructs a new GetTaskRecordingsResponse.
             * @memberof infinitusapi
             * @classdesc Represents a GetTaskRecordingsResponse.
             * @implements IGetTaskRecordingsResponse
             * @constructor
             * @param {infinitusapi.IGetTaskRecordingsResponse=} [properties] Properties to set
             */
            function GetTaskRecordingsResponse(properties) {
                this.data = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GetTaskRecordingsResponse data.
             * @member {Array.<infinitusapi.GetTaskRecordingsResponse.IDataMessage>} data
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @instance
             */
            GetTaskRecordingsResponse.prototype.data = $util.emptyArray;
    
            /**
             * Creates a new GetTaskRecordingsResponse instance using the specified properties.
             * @function create
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {infinitusapi.IGetTaskRecordingsResponse=} [properties] Properties to set
             * @returns {infinitusapi.GetTaskRecordingsResponse} GetTaskRecordingsResponse instance
             */
            GetTaskRecordingsResponse.create = function create(properties) {
                return new GetTaskRecordingsResponse(properties);
            };
    
            /**
             * Encodes the specified GetTaskRecordingsResponse message. Does not implicitly {@link infinitusapi.GetTaskRecordingsResponse.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {infinitusapi.IGetTaskRecordingsResponse} message GetTaskRecordingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTaskRecordingsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && message.data.length)
                    for (var i = 0; i < message.data.length; ++i)
                        $root.infinitusapi.GetTaskRecordingsResponse.DataMessage.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified GetTaskRecordingsResponse message, length delimited. Does not implicitly {@link infinitusapi.GetTaskRecordingsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {infinitusapi.IGetTaskRecordingsResponse} message GetTaskRecordingsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTaskRecordingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GetTaskRecordingsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.GetTaskRecordingsResponse} GetTaskRecordingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTaskRecordingsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.GetTaskRecordingsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.data && message.data.length))
                                message.data = [];
                            message.data.push($root.infinitusapi.GetTaskRecordingsResponse.DataMessage.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GetTaskRecordingsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.GetTaskRecordingsResponse} GetTaskRecordingsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTaskRecordingsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GetTaskRecordingsResponse message.
             * @function verify
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTaskRecordingsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data")) {
                    if (!Array.isArray(message.data))
                        return "data: array expected";
                    for (var i = 0; i < message.data.length; ++i) {
                        var error = $root.infinitusapi.GetTaskRecordingsResponse.DataMessage.verify(message.data[i]);
                        if (error)
                            return "data." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a GetTaskRecordingsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.GetTaskRecordingsResponse} GetTaskRecordingsResponse
             */
            GetTaskRecordingsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.GetTaskRecordingsResponse)
                    return object;
                var message = new $root.infinitusapi.GetTaskRecordingsResponse();
                if (object.data) {
                    if (!Array.isArray(object.data))
                        throw TypeError(".infinitusapi.GetTaskRecordingsResponse.data: array expected");
                    message.data = [];
                    for (var i = 0; i < object.data.length; ++i) {
                        if (typeof object.data[i] !== "object")
                            throw TypeError(".infinitusapi.GetTaskRecordingsResponse.data: object expected");
                        message.data[i] = $root.infinitusapi.GetTaskRecordingsResponse.DataMessage.fromObject(object.data[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GetTaskRecordingsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {infinitusapi.GetTaskRecordingsResponse} message GetTaskRecordingsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTaskRecordingsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.data = [];
                if (message.data && message.data.length) {
                    object.data = [];
                    for (var j = 0; j < message.data.length; ++j)
                        object.data[j] = $root.infinitusapi.GetTaskRecordingsResponse.DataMessage.toObject(message.data[j], options);
                }
                return object;
            };
    
            /**
             * Converts this GetTaskRecordingsResponse to JSON.
             * @function toJSON
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTaskRecordingsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GetTaskRecordingsResponse
             * @function getTypeUrl
             * @memberof infinitusapi.GetTaskRecordingsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTaskRecordingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.GetTaskRecordingsResponse";
            };
    
            GetTaskRecordingsResponse.DataMessage = (function() {
    
                /**
                 * Properties of a DataMessage.
                 * @memberof infinitusapi.GetTaskRecordingsResponse
                 * @interface IDataMessage
                 * @property {string|null} [completionTime] DataMessage completionTime
                 * @property {string|null} [errorMessage] DataMessage errorMessage
                 * @property {string|null} [recordingUrl] DataMessage recordingUrl
                 * @property {infinitusapi.INFTaskType|null} [taskType] DataMessage taskType
                 */
    
                /**
                 * Constructs a new DataMessage.
                 * @memberof infinitusapi.GetTaskRecordingsResponse
                 * @classdesc Represents a DataMessage.
                 * @implements IDataMessage
                 * @constructor
                 * @param {infinitusapi.GetTaskRecordingsResponse.IDataMessage=} [properties] Properties to set
                 */
                function DataMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DataMessage completionTime.
                 * @member {string} completionTime
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.completionTime = "";
    
                /**
                 * DataMessage errorMessage.
                 * @member {string} errorMessage
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.errorMessage = "";
    
                /**
                 * DataMessage recordingUrl.
                 * @member {string} recordingUrl
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.recordingUrl = "";
    
                /**
                 * DataMessage taskType.
                 * @member {infinitusapi.INFTaskType} taskType
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskType = 0;
    
                /**
                 * Creates a new DataMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetTaskRecordingsResponse.IDataMessage=} [properties] Properties to set
                 * @returns {infinitusapi.GetTaskRecordingsResponse.DataMessage} DataMessage instance
                 */
                DataMessage.create = function create(properties) {
                    return new DataMessage(properties);
                };
    
                /**
                 * Encodes the specified DataMessage message. Does not implicitly {@link infinitusapi.GetTaskRecordingsResponse.DataMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetTaskRecordingsResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.completionTime != null && Object.hasOwnProperty.call(message, "completionTime"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.completionTime);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
                    if (message.recordingUrl != null && Object.hasOwnProperty.call(message, "recordingUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.recordingUrl);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.taskType);
                    return writer;
                };
    
                /**
                 * Encodes the specified DataMessage message, length delimited. Does not implicitly {@link infinitusapi.GetTaskRecordingsResponse.DataMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetTaskRecordingsResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusapi.GetTaskRecordingsResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.GetTaskRecordingsResponse.DataMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.completionTime = reader.string();
                                break;
                            }
                        case 2: {
                                message.errorMessage = reader.string();
                                break;
                            }
                        case 3: {
                                message.recordingUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.taskType = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusapi.GetTaskRecordingsResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DataMessage message.
                 * @function verify
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DataMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.completionTime != null && message.hasOwnProperty("completionTime"))
                        if (!$util.isString(message.completionTime))
                            return "completionTime: string expected";
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    if (message.recordingUrl != null && message.hasOwnProperty("recordingUrl"))
                        if (!$util.isString(message.recordingUrl))
                            return "recordingUrl: string expected";
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a DataMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusapi.GetTaskRecordingsResponse.DataMessage} DataMessage
                 */
                DataMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusapi.GetTaskRecordingsResponse.DataMessage)
                        return object;
                    var message = new $root.infinitusapi.GetTaskRecordingsResponse.DataMessage();
                    if (object.completionTime != null)
                        message.completionTime = String(object.completionTime);
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    if (object.recordingUrl != null)
                        message.recordingUrl = String(object.recordingUrl);
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "INF_TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "INF_TASK_TYPE_BENEFITS_VERIFICATION":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "INF_TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "INF_TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "INF_TASK_TYPE_PATIENT_OUTREACH":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "INF_TASK_TYPE_PROVIDER_OUTREACH":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_MM":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_PBM":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "INF_TASK_TYPE_BASIC_BV":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "INF_TASK_TYPE_PBM_DISCOVERY":
                    case 18:
                        message.taskType = 18;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DataMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetTaskRecordingsResponse.DataMessage} message DataMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DataMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.completionTime = "";
                        object.errorMessage = "";
                        object.recordingUrl = "";
                        object.taskType = options.enums === String ? "INF_TASK_TYPE_UNKNOWN" : 0;
                    }
                    if (message.completionTime != null && message.hasOwnProperty("completionTime"))
                        object.completionTime = message.completionTime;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    if (message.recordingUrl != null && message.hasOwnProperty("recordingUrl"))
                        object.recordingUrl = message.recordingUrl;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusapi.INFTaskType[message.taskType] === undefined ? message.taskType : $root.infinitusapi.INFTaskType[message.taskType] : message.taskType;
                    return object;
                };
    
                /**
                 * Converts this DataMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DataMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DataMessage
                 * @function getTypeUrl
                 * @memberof infinitusapi.GetTaskRecordingsResponse.DataMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DataMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusapi.GetTaskRecordingsResponse.DataMessage";
                };
    
                return DataMessage;
            })();
    
            return GetTaskRecordingsResponse;
        })();
    
        infinitusapi.GetTaskResultsResponse = (function() {
    
            /**
             * Properties of a GetTaskResultsResponse.
             * @memberof infinitusapi
             * @interface IGetTaskResultsResponse
             * @property {Array.<infinitusapi.GetTaskResultsResponse.IDataMessage>|null} [data] GetTaskResultsResponse data
             */
    
            /**
             * Constructs a new GetTaskResultsResponse.
             * @memberof infinitusapi
             * @classdesc Represents a GetTaskResultsResponse.
             * @implements IGetTaskResultsResponse
             * @constructor
             * @param {infinitusapi.IGetTaskResultsResponse=} [properties] Properties to set
             */
            function GetTaskResultsResponse(properties) {
                this.data = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * GetTaskResultsResponse data.
             * @member {Array.<infinitusapi.GetTaskResultsResponse.IDataMessage>} data
             * @memberof infinitusapi.GetTaskResultsResponse
             * @instance
             */
            GetTaskResultsResponse.prototype.data = $util.emptyArray;
    
            /**
             * Creates a new GetTaskResultsResponse instance using the specified properties.
             * @function create
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {infinitusapi.IGetTaskResultsResponse=} [properties] Properties to set
             * @returns {infinitusapi.GetTaskResultsResponse} GetTaskResultsResponse instance
             */
            GetTaskResultsResponse.create = function create(properties) {
                return new GetTaskResultsResponse(properties);
            };
    
            /**
             * Encodes the specified GetTaskResultsResponse message. Does not implicitly {@link infinitusapi.GetTaskResultsResponse.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {infinitusapi.IGetTaskResultsResponse} message GetTaskResultsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTaskResultsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && message.data.length)
                    for (var i = 0; i < message.data.length; ++i)
                        $root.infinitusapi.GetTaskResultsResponse.DataMessage.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified GetTaskResultsResponse message, length delimited. Does not implicitly {@link infinitusapi.GetTaskResultsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {infinitusapi.IGetTaskResultsResponse} message GetTaskResultsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTaskResultsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a GetTaskResultsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.GetTaskResultsResponse} GetTaskResultsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTaskResultsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.GetTaskResultsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.data && message.data.length))
                                message.data = [];
                            message.data.push($root.infinitusapi.GetTaskResultsResponse.DataMessage.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a GetTaskResultsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.GetTaskResultsResponse} GetTaskResultsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTaskResultsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a GetTaskResultsResponse message.
             * @function verify
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTaskResultsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data")) {
                    if (!Array.isArray(message.data))
                        return "data: array expected";
                    for (var i = 0; i < message.data.length; ++i) {
                        var error = $root.infinitusapi.GetTaskResultsResponse.DataMessage.verify(message.data[i]);
                        if (error)
                            return "data." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a GetTaskResultsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.GetTaskResultsResponse} GetTaskResultsResponse
             */
            GetTaskResultsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.GetTaskResultsResponse)
                    return object;
                var message = new $root.infinitusapi.GetTaskResultsResponse();
                if (object.data) {
                    if (!Array.isArray(object.data))
                        throw TypeError(".infinitusapi.GetTaskResultsResponse.data: array expected");
                    message.data = [];
                    for (var i = 0; i < object.data.length; ++i) {
                        if (typeof object.data[i] !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.data: object expected");
                        message.data[i] = $root.infinitusapi.GetTaskResultsResponse.DataMessage.fromObject(object.data[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a GetTaskResultsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {infinitusapi.GetTaskResultsResponse} message GetTaskResultsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTaskResultsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.data = [];
                if (message.data && message.data.length) {
                    object.data = [];
                    for (var j = 0; j < message.data.length; ++j)
                        object.data[j] = $root.infinitusapi.GetTaskResultsResponse.DataMessage.toObject(message.data[j], options);
                }
                return object;
            };
    
            /**
             * Converts this GetTaskResultsResponse to JSON.
             * @function toJSON
             * @memberof infinitusapi.GetTaskResultsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTaskResultsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for GetTaskResultsResponse
             * @function getTypeUrl
             * @memberof infinitusapi.GetTaskResultsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetTaskResultsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.GetTaskResultsResponse";
            };
    
            GetTaskResultsResponse.DataMessage = (function() {
    
                /**
                 * Properties of a DataMessage.
                 * @memberof infinitusapi.GetTaskResultsResponse
                 * @interface IDataMessage
                 * @property {infinitusapi.IINFBVTaskOutput|null} [bvTaskOutput] DataMessage bvTaskOutput
                 * @property {string|null} [customerId] DataMessage customerId
                 * @property {string|null} [customerSegment] DataMessage customerSegment
                 * @property {infinitusapi.IINFPBMTaskOutput|null} [pbmTaskOutput] DataMessage pbmTaskOutput
                 * @property {infinitusapi.IINFPrescriptionSavingsTaskInput|null} [prescriptionSavingsTaskInput] DataMessage prescriptionSavingsTaskInput
                 * @property {infinitusapi.IINFPrescriptionSavingsTaskOutput|null} [prescriptionSavingsTaskOutput] DataMessage prescriptionSavingsTaskOutput
                 * @property {infinitusapi.IINFPrescriptionTransferTaskOutput|null} [prescriptionTransferTaskOutput] DataMessage prescriptionTransferTaskOutput
                 * @property {infinitusapi.IINFPriorAuthTaskInput|null} [priorAuthTaskInput] DataMessage priorAuthTaskInput
                 * @property {infinitusapi.IINFPriorAuthTaskOutput|null} [priorAuthTaskOutput] DataMessage priorAuthTaskOutput
                 * @property {string|null} [programId] DataMessage programId
                 * @property {string|null} [taskCompletionDate] DataMessage taskCompletionDate
                 * @property {string|null} [taskCreationDate] DataMessage taskCreationDate
                 * @property {string|null} [taskId] DataMessage taskId
                 * @property {infinitusapi.IINFTaskInput|null} [taskInputs] DataMessage taskInputs
                 * @property {infinitusapi.IINFTaskOutput|null} [taskOutput] DataMessage taskOutput
                 * @property {infinitusapi.INFTaskStatus|null} [taskStatus] DataMessage taskStatus
                 * @property {infinitusapi.INFTaskType|null} [taskType] DataMessage taskType
                 * @property {infinitusapi.IINFPBMDiscoveryTaskOutput|null} [pbmDiscoveryTaskOutput] DataMessage pbmDiscoveryTaskOutput
                 */
    
                /**
                 * Constructs a new DataMessage.
                 * @memberof infinitusapi.GetTaskResultsResponse
                 * @classdesc Represents a DataMessage.
                 * @implements IDataMessage
                 * @constructor
                 * @param {infinitusapi.GetTaskResultsResponse.IDataMessage=} [properties] Properties to set
                 */
                function DataMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DataMessage bvTaskOutput.
                 * @member {infinitusapi.IINFBVTaskOutput|null|undefined} bvTaskOutput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.bvTaskOutput = null;
    
                /**
                 * DataMessage customerId.
                 * @member {string} customerId
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.customerId = "";
    
                /**
                 * DataMessage customerSegment.
                 * @member {string} customerSegment
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.customerSegment = "";
    
                /**
                 * DataMessage pbmTaskOutput.
                 * @member {infinitusapi.IINFPBMTaskOutput|null|undefined} pbmTaskOutput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.pbmTaskOutput = null;
    
                /**
                 * DataMessage prescriptionSavingsTaskInput.
                 * @member {infinitusapi.IINFPrescriptionSavingsTaskInput|null|undefined} prescriptionSavingsTaskInput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.prescriptionSavingsTaskInput = null;
    
                /**
                 * DataMessage prescriptionSavingsTaskOutput.
                 * @member {infinitusapi.IINFPrescriptionSavingsTaskOutput|null|undefined} prescriptionSavingsTaskOutput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.prescriptionSavingsTaskOutput = null;
    
                /**
                 * DataMessage prescriptionTransferTaskOutput.
                 * @member {infinitusapi.IINFPrescriptionTransferTaskOutput|null|undefined} prescriptionTransferTaskOutput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.prescriptionTransferTaskOutput = null;
    
                /**
                 * DataMessage priorAuthTaskInput.
                 * @member {infinitusapi.IINFPriorAuthTaskInput|null|undefined} priorAuthTaskInput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.priorAuthTaskInput = null;
    
                /**
                 * DataMessage priorAuthTaskOutput.
                 * @member {infinitusapi.IINFPriorAuthTaskOutput|null|undefined} priorAuthTaskOutput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.priorAuthTaskOutput = null;
    
                /**
                 * DataMessage programId.
                 * @member {string} programId
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.programId = "";
    
                /**
                 * DataMessage taskCompletionDate.
                 * @member {string} taskCompletionDate
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskCompletionDate = "";
    
                /**
                 * DataMessage taskCreationDate.
                 * @member {string} taskCreationDate
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskCreationDate = "";
    
                /**
                 * DataMessage taskId.
                 * @member {string} taskId
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskId = "";
    
                /**
                 * DataMessage taskInputs.
                 * @member {infinitusapi.IINFTaskInput|null|undefined} taskInputs
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskInputs = null;
    
                /**
                 * DataMessage taskOutput.
                 * @member {infinitusapi.IINFTaskOutput|null|undefined} taskOutput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskOutput = null;
    
                /**
                 * DataMessage taskStatus.
                 * @member {infinitusapi.INFTaskStatus} taskStatus
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskStatus = 0;
    
                /**
                 * DataMessage taskType.
                 * @member {infinitusapi.INFTaskType} taskType
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.taskType = 0;
    
                /**
                 * DataMessage pbmDiscoveryTaskOutput.
                 * @member {infinitusapi.IINFPBMDiscoveryTaskOutput|null|undefined} pbmDiscoveryTaskOutput
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.pbmDiscoveryTaskOutput = null;
    
                /**
                 * Creates a new DataMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetTaskResultsResponse.IDataMessage=} [properties] Properties to set
                 * @returns {infinitusapi.GetTaskResultsResponse.DataMessage} DataMessage instance
                 */
                DataMessage.create = function create(properties) {
                    return new DataMessage(properties);
                };
    
                /**
                 * Encodes the specified DataMessage message. Does not implicitly {@link infinitusapi.GetTaskResultsResponse.DataMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetTaskResultsResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bvTaskOutput != null && Object.hasOwnProperty.call(message, "bvTaskOutput"))
                        $root.infinitusapi.INFBVTaskOutput.encode(message.bvTaskOutput, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerId);
                    if (message.customerSegment != null && Object.hasOwnProperty.call(message, "customerSegment"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.customerSegment);
                    if (message.pbmTaskOutput != null && Object.hasOwnProperty.call(message, "pbmTaskOutput"))
                        $root.infinitusapi.INFPBMTaskOutput.encode(message.pbmTaskOutput, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.prescriptionSavingsTaskInput != null && Object.hasOwnProperty.call(message, "prescriptionSavingsTaskInput"))
                        $root.infinitusapi.INFPrescriptionSavingsTaskInput.encode(message.prescriptionSavingsTaskInput, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.prescriptionSavingsTaskOutput != null && Object.hasOwnProperty.call(message, "prescriptionSavingsTaskOutput"))
                        $root.infinitusapi.INFPrescriptionSavingsTaskOutput.encode(message.prescriptionSavingsTaskOutput, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.prescriptionTransferTaskOutput != null && Object.hasOwnProperty.call(message, "prescriptionTransferTaskOutput"))
                        $root.infinitusapi.INFPrescriptionTransferTaskOutput.encode(message.prescriptionTransferTaskOutput, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.priorAuthTaskInput != null && Object.hasOwnProperty.call(message, "priorAuthTaskInput"))
                        $root.infinitusapi.INFPriorAuthTaskInput.encode(message.priorAuthTaskInput, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.priorAuthTaskOutput != null && Object.hasOwnProperty.call(message, "priorAuthTaskOutput"))
                        $root.infinitusapi.INFPriorAuthTaskOutput.encode(message.priorAuthTaskOutput, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.programId != null && Object.hasOwnProperty.call(message, "programId"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.programId);
                    if (message.taskCompletionDate != null && Object.hasOwnProperty.call(message, "taskCompletionDate"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.taskCompletionDate);
                    if (message.taskCreationDate != null && Object.hasOwnProperty.call(message, "taskCreationDate"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.taskCreationDate);
                    if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.taskId);
                    if (message.taskInputs != null && Object.hasOwnProperty.call(message, "taskInputs"))
                        $root.infinitusapi.INFTaskInput.encode(message.taskInputs, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.taskOutput != null && Object.hasOwnProperty.call(message, "taskOutput"))
                        $root.infinitusapi.INFTaskOutput.encode(message.taskOutput, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.taskStatus != null && Object.hasOwnProperty.call(message, "taskStatus"))
                        writer.uint32(/* id 16, wireType 0 =*/128).int32(message.taskStatus);
                    if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                        writer.uint32(/* id 17, wireType 0 =*/136).int32(message.taskType);
                    if (message.pbmDiscoveryTaskOutput != null && Object.hasOwnProperty.call(message, "pbmDiscoveryTaskOutput"))
                        $root.infinitusapi.INFPBMDiscoveryTaskOutput.encode(message.pbmDiscoveryTaskOutput, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified DataMessage message, length delimited. Does not implicitly {@link infinitusapi.GetTaskResultsResponse.DataMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetTaskResultsResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusapi.GetTaskResultsResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.GetTaskResultsResponse.DataMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.customerId = reader.string();
                                break;
                            }
                        case 3: {
                                message.customerSegment = reader.string();
                                break;
                            }
                        case 4: {
                                message.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.prescriptionSavingsTaskInput = $root.infinitusapi.INFPrescriptionSavingsTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.prescriptionSavingsTaskOutput = $root.infinitusapi.INFPrescriptionSavingsTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.prescriptionTransferTaskOutput = $root.infinitusapi.INFPrescriptionTransferTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.priorAuthTaskOutput = $root.infinitusapi.INFPriorAuthTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.programId = reader.string();
                                break;
                            }
                        case 11: {
                                message.taskCompletionDate = reader.string();
                                break;
                            }
                        case 12: {
                                message.taskCreationDate = reader.string();
                                break;
                            }
                        case 13: {
                                message.taskId = reader.string();
                                break;
                            }
                        case 14: {
                                message.taskInputs = $root.infinitusapi.INFTaskInput.decode(reader, reader.uint32());
                                break;
                            }
                        case 15: {
                                message.taskOutput = $root.infinitusapi.INFTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        case 16: {
                                message.taskStatus = reader.int32();
                                break;
                            }
                        case 17: {
                                message.taskType = reader.int32();
                                break;
                            }
                        case 18: {
                                message.pbmDiscoveryTaskOutput = $root.infinitusapi.INFPBMDiscoveryTaskOutput.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusapi.GetTaskResultsResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DataMessage message.
                 * @function verify
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DataMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bvTaskOutput != null && message.hasOwnProperty("bvTaskOutput")) {
                        var error = $root.infinitusapi.INFBVTaskOutput.verify(message.bvTaskOutput);
                        if (error)
                            return "bvTaskOutput." + error;
                    }
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        if (!$util.isString(message.customerId))
                            return "customerId: string expected";
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        if (!$util.isString(message.customerSegment))
                            return "customerSegment: string expected";
                    if (message.pbmTaskOutput != null && message.hasOwnProperty("pbmTaskOutput")) {
                        var error = $root.infinitusapi.INFPBMTaskOutput.verify(message.pbmTaskOutput);
                        if (error)
                            return "pbmTaskOutput." + error;
                    }
                    if (message.prescriptionSavingsTaskInput != null && message.hasOwnProperty("prescriptionSavingsTaskInput")) {
                        var error = $root.infinitusapi.INFPrescriptionSavingsTaskInput.verify(message.prescriptionSavingsTaskInput);
                        if (error)
                            return "prescriptionSavingsTaskInput." + error;
                    }
                    if (message.prescriptionSavingsTaskOutput != null && message.hasOwnProperty("prescriptionSavingsTaskOutput")) {
                        var error = $root.infinitusapi.INFPrescriptionSavingsTaskOutput.verify(message.prescriptionSavingsTaskOutput);
                        if (error)
                            return "prescriptionSavingsTaskOutput." + error;
                    }
                    if (message.prescriptionTransferTaskOutput != null && message.hasOwnProperty("prescriptionTransferTaskOutput")) {
                        var error = $root.infinitusapi.INFPrescriptionTransferTaskOutput.verify(message.prescriptionTransferTaskOutput);
                        if (error)
                            return "prescriptionTransferTaskOutput." + error;
                    }
                    if (message.priorAuthTaskInput != null && message.hasOwnProperty("priorAuthTaskInput")) {
                        var error = $root.infinitusapi.INFPriorAuthTaskInput.verify(message.priorAuthTaskInput);
                        if (error)
                            return "priorAuthTaskInput." + error;
                    }
                    if (message.priorAuthTaskOutput != null && message.hasOwnProperty("priorAuthTaskOutput")) {
                        var error = $root.infinitusapi.INFPriorAuthTaskOutput.verify(message.priorAuthTaskOutput);
                        if (error)
                            return "priorAuthTaskOutput." + error;
                    }
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        if (!$util.isString(message.programId))
                            return "programId: string expected";
                    if (message.taskCompletionDate != null && message.hasOwnProperty("taskCompletionDate"))
                        if (!$util.isString(message.taskCompletionDate))
                            return "taskCompletionDate: string expected";
                    if (message.taskCreationDate != null && message.hasOwnProperty("taskCreationDate"))
                        if (!$util.isString(message.taskCreationDate))
                            return "taskCreationDate: string expected";
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        if (!$util.isString(message.taskId))
                            return "taskId: string expected";
                    if (message.taskInputs != null && message.hasOwnProperty("taskInputs")) {
                        var error = $root.infinitusapi.INFTaskInput.verify(message.taskInputs);
                        if (error)
                            return "taskInputs." + error;
                    }
                    if (message.taskOutput != null && message.hasOwnProperty("taskOutput")) {
                        var error = $root.infinitusapi.INFTaskOutput.verify(message.taskOutput);
                        if (error)
                            return "taskOutput." + error;
                    }
                    if (message.taskStatus != null && message.hasOwnProperty("taskStatus"))
                        switch (message.taskStatus) {
                        default:
                            return "taskStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        switch (message.taskType) {
                        default:
                            return "taskType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.pbmDiscoveryTaskOutput != null && message.hasOwnProperty("pbmDiscoveryTaskOutput")) {
                        var error = $root.infinitusapi.INFPBMDiscoveryTaskOutput.verify(message.pbmDiscoveryTaskOutput);
                        if (error)
                            return "pbmDiscoveryTaskOutput." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a DataMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusapi.GetTaskResultsResponse.DataMessage} DataMessage
                 */
                DataMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusapi.GetTaskResultsResponse.DataMessage)
                        return object;
                    var message = new $root.infinitusapi.GetTaskResultsResponse.DataMessage();
                    if (object.bvTaskOutput != null) {
                        if (typeof object.bvTaskOutput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.bvTaskOutput: object expected");
                        message.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.fromObject(object.bvTaskOutput);
                    }
                    if (object.customerId != null)
                        message.customerId = String(object.customerId);
                    if (object.customerSegment != null)
                        message.customerSegment = String(object.customerSegment);
                    if (object.pbmTaskOutput != null) {
                        if (typeof object.pbmTaskOutput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.pbmTaskOutput: object expected");
                        message.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.fromObject(object.pbmTaskOutput);
                    }
                    if (object.prescriptionSavingsTaskInput != null) {
                        if (typeof object.prescriptionSavingsTaskInput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.prescriptionSavingsTaskInput: object expected");
                        message.prescriptionSavingsTaskInput = $root.infinitusapi.INFPrescriptionSavingsTaskInput.fromObject(object.prescriptionSavingsTaskInput);
                    }
                    if (object.prescriptionSavingsTaskOutput != null) {
                        if (typeof object.prescriptionSavingsTaskOutput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.prescriptionSavingsTaskOutput: object expected");
                        message.prescriptionSavingsTaskOutput = $root.infinitusapi.INFPrescriptionSavingsTaskOutput.fromObject(object.prescriptionSavingsTaskOutput);
                    }
                    if (object.prescriptionTransferTaskOutput != null) {
                        if (typeof object.prescriptionTransferTaskOutput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.prescriptionTransferTaskOutput: object expected");
                        message.prescriptionTransferTaskOutput = $root.infinitusapi.INFPrescriptionTransferTaskOutput.fromObject(object.prescriptionTransferTaskOutput);
                    }
                    if (object.priorAuthTaskInput != null) {
                        if (typeof object.priorAuthTaskInput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.priorAuthTaskInput: object expected");
                        message.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.fromObject(object.priorAuthTaskInput);
                    }
                    if (object.priorAuthTaskOutput != null) {
                        if (typeof object.priorAuthTaskOutput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.priorAuthTaskOutput: object expected");
                        message.priorAuthTaskOutput = $root.infinitusapi.INFPriorAuthTaskOutput.fromObject(object.priorAuthTaskOutput);
                    }
                    if (object.programId != null)
                        message.programId = String(object.programId);
                    if (object.taskCompletionDate != null)
                        message.taskCompletionDate = String(object.taskCompletionDate);
                    if (object.taskCreationDate != null)
                        message.taskCreationDate = String(object.taskCreationDate);
                    if (object.taskId != null)
                        message.taskId = String(object.taskId);
                    if (object.taskInputs != null) {
                        if (typeof object.taskInputs !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.taskInputs: object expected");
                        message.taskInputs = $root.infinitusapi.INFTaskInput.fromObject(object.taskInputs);
                    }
                    if (object.taskOutput != null) {
                        if (typeof object.taskOutput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.taskOutput: object expected");
                        message.taskOutput = $root.infinitusapi.INFTaskOutput.fromObject(object.taskOutput);
                    }
                    switch (object.taskStatus) {
                    default:
                        if (typeof object.taskStatus === "number") {
                            message.taskStatus = object.taskStatus;
                            break;
                        }
                        break;
                    case "INF_TASK_STATUS_UNKNOWN":
                    case 0:
                        message.taskStatus = 0;
                        break;
                    case "INF_TASK_STATUS_SUCCESSFUL":
                    case 1:
                        message.taskStatus = 1;
                        break;
                    case "INF_TASK_STATUS_UNSUCCESSFUL":
                    case 2:
                        message.taskStatus = 2;
                        break;
                    case "INF_TASK_STATUS_IN_PROGRESS":
                    case 3:
                        message.taskStatus = 3;
                        break;
                    }
                    switch (object.taskType) {
                    default:
                        if (typeof object.taskType === "number") {
                            message.taskType = object.taskType;
                            break;
                        }
                        break;
                    case "INF_TASK_TYPE_UNKNOWN":
                    case 0:
                        message.taskType = 0;
                        break;
                    case "INF_TASK_TYPE_BENEFITS_VERIFICATION":
                    case 1:
                        message.taskType = 1;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_BENEFIT_MANAGER":
                    case 2:
                        message.taskType = 2;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_TRANSFER":
                    case 3:
                        message.taskType = 3;
                        break;
                    case "INF_TASK_TYPE_PRESCRIPTION_SAVINGS":
                    case 4:
                        message.taskType = 4;
                        break;
                    case "INF_TASK_TYPE_CLAIMS_FOLLOWUP":
                    case 5:
                        message.taskType = 5;
                        break;
                    case "INF_TASK_TYPE_PRIOR_AUTH_FOLLOWUP":
                    case 6:
                        message.taskType = 6;
                        break;
                    case "INF_TASK_TYPE_DENTAL_BENEFITS_VERIFICATION":
                    case 7:
                        message.taskType = 7;
                        break;
                    case "INF_TASK_TYPE_SHIPMENT_FOLLOWUP":
                    case 8:
                        message.taskType = 8;
                        break;
                    case "INF_TASK_TYPE_PATIENT_OUTREACH":
                    case 9:
                        message.taskType = 9;
                        break;
                    case "INF_TASK_TYPE_PROVIDER_OUTREACH":
                    case 10:
                        message.taskType = 10;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_MM":
                    case 11:
                        message.taskType = 11;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_PBM":
                    case 12:
                        message.taskType = 12;
                        break;
                    case "INF_TASK_TYPE_PHARMACY_STOCK_CHECK":
                    case 13:
                        message.taskType = 13;
                        break;
                    case "INF_TASK_TYPE_BASIC_BV":
                    case 14:
                        message.taskType = 14;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP":
                    case 15:
                        message.taskType = 15;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_DENIAL":
                    case 16:
                        message.taskType = 16;
                        break;
                    case "INF_TASK_TYPE_FASTTRACK_CLAIMS_APPEAL":
                    case 17:
                        message.taskType = 17;
                        break;
                    case "INF_TASK_TYPE_PBM_DISCOVERY":
                    case 18:
                        message.taskType = 18;
                        break;
                    }
                    if (object.pbmDiscoveryTaskOutput != null) {
                        if (typeof object.pbmDiscoveryTaskOutput !== "object")
                            throw TypeError(".infinitusapi.GetTaskResultsResponse.DataMessage.pbmDiscoveryTaskOutput: object expected");
                        message.pbmDiscoveryTaskOutput = $root.infinitusapi.INFPBMDiscoveryTaskOutput.fromObject(object.pbmDiscoveryTaskOutput);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DataMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {infinitusapi.GetTaskResultsResponse.DataMessage} message DataMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DataMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.bvTaskOutput = null;
                        object.customerId = "";
                        object.customerSegment = "";
                        object.pbmTaskOutput = null;
                        object.prescriptionSavingsTaskInput = null;
                        object.prescriptionSavingsTaskOutput = null;
                        object.prescriptionTransferTaskOutput = null;
                        object.priorAuthTaskInput = null;
                        object.priorAuthTaskOutput = null;
                        object.programId = "";
                        object.taskCompletionDate = "";
                        object.taskCreationDate = "";
                        object.taskId = "";
                        object.taskInputs = null;
                        object.taskOutput = null;
                        object.taskStatus = options.enums === String ? "INF_TASK_STATUS_UNKNOWN" : 0;
                        object.taskType = options.enums === String ? "INF_TASK_TYPE_UNKNOWN" : 0;
                        object.pbmDiscoveryTaskOutput = null;
                    }
                    if (message.bvTaskOutput != null && message.hasOwnProperty("bvTaskOutput"))
                        object.bvTaskOutput = $root.infinitusapi.INFBVTaskOutput.toObject(message.bvTaskOutput, options);
                    if (message.customerId != null && message.hasOwnProperty("customerId"))
                        object.customerId = message.customerId;
                    if (message.customerSegment != null && message.hasOwnProperty("customerSegment"))
                        object.customerSegment = message.customerSegment;
                    if (message.pbmTaskOutput != null && message.hasOwnProperty("pbmTaskOutput"))
                        object.pbmTaskOutput = $root.infinitusapi.INFPBMTaskOutput.toObject(message.pbmTaskOutput, options);
                    if (message.prescriptionSavingsTaskInput != null && message.hasOwnProperty("prescriptionSavingsTaskInput"))
                        object.prescriptionSavingsTaskInput = $root.infinitusapi.INFPrescriptionSavingsTaskInput.toObject(message.prescriptionSavingsTaskInput, options);
                    if (message.prescriptionSavingsTaskOutput != null && message.hasOwnProperty("prescriptionSavingsTaskOutput"))
                        object.prescriptionSavingsTaskOutput = $root.infinitusapi.INFPrescriptionSavingsTaskOutput.toObject(message.prescriptionSavingsTaskOutput, options);
                    if (message.prescriptionTransferTaskOutput != null && message.hasOwnProperty("prescriptionTransferTaskOutput"))
                        object.prescriptionTransferTaskOutput = $root.infinitusapi.INFPrescriptionTransferTaskOutput.toObject(message.prescriptionTransferTaskOutput, options);
                    if (message.priorAuthTaskInput != null && message.hasOwnProperty("priorAuthTaskInput"))
                        object.priorAuthTaskInput = $root.infinitusapi.INFPriorAuthTaskInput.toObject(message.priorAuthTaskInput, options);
                    if (message.priorAuthTaskOutput != null && message.hasOwnProperty("priorAuthTaskOutput"))
                        object.priorAuthTaskOutput = $root.infinitusapi.INFPriorAuthTaskOutput.toObject(message.priorAuthTaskOutput, options);
                    if (message.programId != null && message.hasOwnProperty("programId"))
                        object.programId = message.programId;
                    if (message.taskCompletionDate != null && message.hasOwnProperty("taskCompletionDate"))
                        object.taskCompletionDate = message.taskCompletionDate;
                    if (message.taskCreationDate != null && message.hasOwnProperty("taskCreationDate"))
                        object.taskCreationDate = message.taskCreationDate;
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        object.taskId = message.taskId;
                    if (message.taskInputs != null && message.hasOwnProperty("taskInputs"))
                        object.taskInputs = $root.infinitusapi.INFTaskInput.toObject(message.taskInputs, options);
                    if (message.taskOutput != null && message.hasOwnProperty("taskOutput"))
                        object.taskOutput = $root.infinitusapi.INFTaskOutput.toObject(message.taskOutput, options);
                    if (message.taskStatus != null && message.hasOwnProperty("taskStatus"))
                        object.taskStatus = options.enums === String ? $root.infinitusapi.INFTaskStatus[message.taskStatus] === undefined ? message.taskStatus : $root.infinitusapi.INFTaskStatus[message.taskStatus] : message.taskStatus;
                    if (message.taskType != null && message.hasOwnProperty("taskType"))
                        object.taskType = options.enums === String ? $root.infinitusapi.INFTaskType[message.taskType] === undefined ? message.taskType : $root.infinitusapi.INFTaskType[message.taskType] : message.taskType;
                    if (message.pbmDiscoveryTaskOutput != null && message.hasOwnProperty("pbmDiscoveryTaskOutput"))
                        object.pbmDiscoveryTaskOutput = $root.infinitusapi.INFPBMDiscoveryTaskOutput.toObject(message.pbmDiscoveryTaskOutput, options);
                    return object;
                };
    
                /**
                 * Converts this DataMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DataMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DataMessage
                 * @function getTypeUrl
                 * @memberof infinitusapi.GetTaskResultsResponse.DataMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DataMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusapi.GetTaskResultsResponse.DataMessage";
                };
    
                return DataMessage;
            })();
    
            return GetTaskResultsResponse;
        })();
    
        infinitusapi.INFAddress = (function() {
    
            /**
             * Properties of a INFAddress.
             * @memberof infinitusapi
             * @interface IINFAddress
             * @property {string|null} [city] INFAddress city
             * @property {string|null} [state] INFAddress state
             * @property {string|null} [streetAddress] INFAddress streetAddress
             * @property {string|null} [streetAddressLine2] INFAddress streetAddressLine2
             * @property {string|null} [zip] INFAddress zip
             */
    
            /**
             * Constructs a new INFAddress.
             * @memberof infinitusapi
             * @classdesc Represents a INFAddress.
             * @implements IINFAddress
             * @constructor
             * @param {infinitusapi.IINFAddress=} [properties] Properties to set
             */
            function INFAddress(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFAddress city.
             * @member {string} city
             * @memberof infinitusapi.INFAddress
             * @instance
             */
            INFAddress.prototype.city = "";
    
            /**
             * INFAddress state.
             * @member {string} state
             * @memberof infinitusapi.INFAddress
             * @instance
             */
            INFAddress.prototype.state = "";
    
            /**
             * INFAddress streetAddress.
             * @member {string} streetAddress
             * @memberof infinitusapi.INFAddress
             * @instance
             */
            INFAddress.prototype.streetAddress = "";
    
            /**
             * INFAddress streetAddressLine2.
             * @member {string} streetAddressLine2
             * @memberof infinitusapi.INFAddress
             * @instance
             */
            INFAddress.prototype.streetAddressLine2 = "";
    
            /**
             * INFAddress zip.
             * @member {string} zip
             * @memberof infinitusapi.INFAddress
             * @instance
             */
            INFAddress.prototype.zip = "";
    
            /**
             * Creates a new INFAddress instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {infinitusapi.IINFAddress=} [properties] Properties to set
             * @returns {infinitusapi.INFAddress} INFAddress instance
             */
            INFAddress.create = function create(properties) {
                return new INFAddress(properties);
            };
    
            /**
             * Encodes the specified INFAddress message. Does not implicitly {@link infinitusapi.INFAddress.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {infinitusapi.IINFAddress} message INFAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFAddress.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.city);
                if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.state);
                if (message.streetAddress != null && Object.hasOwnProperty.call(message, "streetAddress"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.streetAddress);
                if (message.streetAddressLine2 != null && Object.hasOwnProperty.call(message, "streetAddressLine2"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.streetAddressLine2);
                if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.zip);
                return writer;
            };
    
            /**
             * Encodes the specified INFAddress message, length delimited. Does not implicitly {@link infinitusapi.INFAddress.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {infinitusapi.IINFAddress} message INFAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFAddress.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFAddress message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFAddress} INFAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFAddress.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFAddress();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.city = reader.string();
                            break;
                        }
                    case 2: {
                            message.state = reader.string();
                            break;
                        }
                    case 3: {
                            message.streetAddress = reader.string();
                            break;
                        }
                    case 4: {
                            message.streetAddressLine2 = reader.string();
                            break;
                        }
                    case 5: {
                            message.zip = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFAddress message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFAddress} INFAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFAddress.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFAddress message.
             * @function verify
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFAddress.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.city != null && message.hasOwnProperty("city"))
                    if (!$util.isString(message.city))
                        return "city: string expected";
                if (message.state != null && message.hasOwnProperty("state"))
                    if (!$util.isString(message.state))
                        return "state: string expected";
                if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
                    if (!$util.isString(message.streetAddress))
                        return "streetAddress: string expected";
                if (message.streetAddressLine2 != null && message.hasOwnProperty("streetAddressLine2"))
                    if (!$util.isString(message.streetAddressLine2))
                        return "streetAddressLine2: string expected";
                if (message.zip != null && message.hasOwnProperty("zip"))
                    if (!$util.isString(message.zip))
                        return "zip: string expected";
                return null;
            };
    
            /**
             * Creates a INFAddress message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFAddress} INFAddress
             */
            INFAddress.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFAddress)
                    return object;
                var message = new $root.infinitusapi.INFAddress();
                if (object.city != null)
                    message.city = String(object.city);
                if (object.state != null)
                    message.state = String(object.state);
                if (object.streetAddress != null)
                    message.streetAddress = String(object.streetAddress);
                if (object.streetAddressLine2 != null)
                    message.streetAddressLine2 = String(object.streetAddressLine2);
                if (object.zip != null)
                    message.zip = String(object.zip);
                return message;
            };
    
            /**
             * Creates a plain object from a INFAddress message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {infinitusapi.INFAddress} message INFAddress
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFAddress.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.city = "";
                    object.state = "";
                    object.streetAddress = "";
                    object.streetAddressLine2 = "";
                    object.zip = "";
                }
                if (message.city != null && message.hasOwnProperty("city"))
                    object.city = message.city;
                if (message.state != null && message.hasOwnProperty("state"))
                    object.state = message.state;
                if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
                    object.streetAddress = message.streetAddress;
                if (message.streetAddressLine2 != null && message.hasOwnProperty("streetAddressLine2"))
                    object.streetAddressLine2 = message.streetAddressLine2;
                if (message.zip != null && message.hasOwnProperty("zip"))
                    object.zip = message.zip;
                return object;
            };
    
            /**
             * Converts this INFAddress to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFAddress
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFAddress.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFAddress
             * @function getTypeUrl
             * @memberof infinitusapi.INFAddress
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFAddress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFAddress";
            };
    
            return INFAddress;
        })();
    
        infinitusapi.INFBVPayerInfo = (function() {
    
            /**
             * Properties of a INFBVPayerInfo.
             * @memberof infinitusapi
             * @interface IINFBVPayerInfo
             * @property {string|null} [customerPayerName] INFBVPayerInfo customerPayerName
             * @property {string|null} [customerPayerPhoneNumber] INFBVPayerInfo customerPayerPhoneNumber
             * @property {string|null} [infinitusPayerId] INFBVPayerInfo infinitusPayerId
             * @property {string|null} [subscriberId] INFBVPayerInfo subscriberId
             */
    
            /**
             * Constructs a new INFBVPayerInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVPayerInfo.
             * @implements IINFBVPayerInfo
             * @constructor
             * @param {infinitusapi.IINFBVPayerInfo=} [properties] Properties to set
             */
            function INFBVPayerInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVPayerInfo customerPayerName.
             * @member {string} customerPayerName
             * @memberof infinitusapi.INFBVPayerInfo
             * @instance
             */
            INFBVPayerInfo.prototype.customerPayerName = "";
    
            /**
             * INFBVPayerInfo customerPayerPhoneNumber.
             * @member {string} customerPayerPhoneNumber
             * @memberof infinitusapi.INFBVPayerInfo
             * @instance
             */
            INFBVPayerInfo.prototype.customerPayerPhoneNumber = "";
    
            /**
             * INFBVPayerInfo infinitusPayerId.
             * @member {string} infinitusPayerId
             * @memberof infinitusapi.INFBVPayerInfo
             * @instance
             */
            INFBVPayerInfo.prototype.infinitusPayerId = "";
    
            /**
             * INFBVPayerInfo subscriberId.
             * @member {string} subscriberId
             * @memberof infinitusapi.INFBVPayerInfo
             * @instance
             */
            INFBVPayerInfo.prototype.subscriberId = "";
    
            /**
             * Creates a new INFBVPayerInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {infinitusapi.IINFBVPayerInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFBVPayerInfo} INFBVPayerInfo instance
             */
            INFBVPayerInfo.create = function create(properties) {
                return new INFBVPayerInfo(properties);
            };
    
            /**
             * Encodes the specified INFBVPayerInfo message. Does not implicitly {@link infinitusapi.INFBVPayerInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {infinitusapi.IINFBVPayerInfo} message INFBVPayerInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVPayerInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.customerPayerName != null && Object.hasOwnProperty.call(message, "customerPayerName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerPayerName);
                if (message.customerPayerPhoneNumber != null && Object.hasOwnProperty.call(message, "customerPayerPhoneNumber"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerPayerPhoneNumber);
                if (message.infinitusPayerId != null && Object.hasOwnProperty.call(message, "infinitusPayerId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.infinitusPayerId);
                if (message.subscriberId != null && Object.hasOwnProperty.call(message, "subscriberId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.subscriberId);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVPayerInfo message, length delimited. Does not implicitly {@link infinitusapi.INFBVPayerInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {infinitusapi.IINFBVPayerInfo} message INFBVPayerInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVPayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVPayerInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVPayerInfo} INFBVPayerInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVPayerInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVPayerInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.customerPayerName = reader.string();
                            break;
                        }
                    case 2: {
                            message.customerPayerPhoneNumber = reader.string();
                            break;
                        }
                    case 3: {
                            message.infinitusPayerId = reader.string();
                            break;
                        }
                    case 4: {
                            message.subscriberId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVPayerInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVPayerInfo} INFBVPayerInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVPayerInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVPayerInfo message.
             * @function verify
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVPayerInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    if (!$util.isString(message.customerPayerName))
                        return "customerPayerName: string expected";
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    if (!$util.isString(message.customerPayerPhoneNumber))
                        return "customerPayerPhoneNumber: string expected";
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    if (!$util.isString(message.infinitusPayerId))
                        return "infinitusPayerId: string expected";
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    if (!$util.isString(message.subscriberId))
                        return "subscriberId: string expected";
                return null;
            };
    
            /**
             * Creates a INFBVPayerInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVPayerInfo} INFBVPayerInfo
             */
            INFBVPayerInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVPayerInfo)
                    return object;
                var message = new $root.infinitusapi.INFBVPayerInfo();
                if (object.customerPayerName != null)
                    message.customerPayerName = String(object.customerPayerName);
                if (object.customerPayerPhoneNumber != null)
                    message.customerPayerPhoneNumber = String(object.customerPayerPhoneNumber);
                if (object.infinitusPayerId != null)
                    message.infinitusPayerId = String(object.infinitusPayerId);
                if (object.subscriberId != null)
                    message.subscriberId = String(object.subscriberId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVPayerInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {infinitusapi.INFBVPayerInfo} message INFBVPayerInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVPayerInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.customerPayerName = "";
                    object.customerPayerPhoneNumber = "";
                    object.infinitusPayerId = "";
                    object.subscriberId = "";
                }
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    object.customerPayerName = message.customerPayerName;
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    object.customerPayerPhoneNumber = message.customerPayerPhoneNumber;
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    object.infinitusPayerId = message.infinitusPayerId;
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    object.subscriberId = message.subscriberId;
                return object;
            };
    
            /**
             * Converts this INFBVPayerInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVPayerInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVPayerInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVPayerInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVPayerInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVPayerInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVPayerInfo";
            };
    
            return INFBVPayerInfo;
        })();
    
        infinitusapi.INFBVTaskInput = (function() {
    
            /**
             * Properties of a INFBVTaskInput.
             * @memberof infinitusapi
             * @interface IINFBVTaskInput
             * @property {string|null} [additionalNotes] INFBVTaskInput additionalNotes
             * @property {boolean|null} [chainPBM] INFBVTaskInput chainPBM
             * @property {Array.<infinitusapi.IINFBVTaskInputCPT>|null} [cpt] INFBVTaskInput cpt
             * @property {Array.<infinitusapi.IINFTaskInputDiagnosis>|null} [diagnosis] INFBVTaskInput diagnosis
             * @property {infinitusapi.IINFTaskInputMember|null} [member] INFBVTaskInput member
             * @property {infinitusapi.IINFTaskInputPayer|null} [payer] INFBVTaskInput payer
             * @property {infinitusapi.IINFBVTaskInputPlaceOfService|null} [placeOfService] INFBVTaskInput placeOfService
             * @property {infinitusapi.IINFBVTaskInputPolicyHolder|null} [policyHolder] INFBVTaskInput policyHolder
             * @property {infinitusapi.IINFTaskInputPractice|null} [practice] INFBVTaskInput practice
             * @property {Array.<infinitusapi.IINFTaskInputProduct>|null} [products] INFBVTaskInput products
             * @property {infinitusapi.IINFTaskInputProvider|null} [provider] INFBVTaskInput provider
             * @property {Array.<infinitusapi.IINFBVTaskInputSiteOfCare>|null} [sitesOfCare] INFBVTaskInput sitesOfCare
             */
    
            /**
             * Constructs a new INFBVTaskInput.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskInput.
             * @implements IINFBVTaskInput
             * @constructor
             * @param {infinitusapi.IINFBVTaskInput=} [properties] Properties to set
             */
            function INFBVTaskInput(properties) {
                this.cpt = [];
                this.diagnosis = [];
                this.products = [];
                this.sitesOfCare = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskInput additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.additionalNotes = "";
    
            /**
             * INFBVTaskInput chainPBM.
             * @member {boolean} chainPBM
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.chainPBM = false;
    
            /**
             * INFBVTaskInput cpt.
             * @member {Array.<infinitusapi.IINFBVTaskInputCPT>} cpt
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.cpt = $util.emptyArray;
    
            /**
             * INFBVTaskInput diagnosis.
             * @member {Array.<infinitusapi.IINFTaskInputDiagnosis>} diagnosis
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.diagnosis = $util.emptyArray;
    
            /**
             * INFBVTaskInput member.
             * @member {infinitusapi.IINFTaskInputMember|null|undefined} member
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.member = null;
    
            /**
             * INFBVTaskInput payer.
             * @member {infinitusapi.IINFTaskInputPayer|null|undefined} payer
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.payer = null;
    
            /**
             * INFBVTaskInput placeOfService.
             * @member {infinitusapi.IINFBVTaskInputPlaceOfService|null|undefined} placeOfService
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.placeOfService = null;
    
            /**
             * INFBVTaskInput policyHolder.
             * @member {infinitusapi.IINFBVTaskInputPolicyHolder|null|undefined} policyHolder
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.policyHolder = null;
    
            /**
             * INFBVTaskInput practice.
             * @member {infinitusapi.IINFTaskInputPractice|null|undefined} practice
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.practice = null;
    
            /**
             * INFBVTaskInput products.
             * @member {Array.<infinitusapi.IINFTaskInputProduct>} products
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.products = $util.emptyArray;
    
            /**
             * INFBVTaskInput provider.
             * @member {infinitusapi.IINFTaskInputProvider|null|undefined} provider
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.provider = null;
    
            /**
             * INFBVTaskInput sitesOfCare.
             * @member {Array.<infinitusapi.IINFBVTaskInputSiteOfCare>} sitesOfCare
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             */
            INFBVTaskInput.prototype.sitesOfCare = $util.emptyArray;
    
            /**
             * Creates a new INFBVTaskInput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {infinitusapi.IINFBVTaskInput=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskInput} INFBVTaskInput instance
             */
            INFBVTaskInput.create = function create(properties) {
                return new INFBVTaskInput(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskInput message. Does not implicitly {@link infinitusapi.INFBVTaskInput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {infinitusapi.IINFBVTaskInput} message INFBVTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.chainPBM != null && Object.hasOwnProperty.call(message, "chainPBM"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.chainPBM);
                if (message.cpt != null && message.cpt.length)
                    for (var i = 0; i < message.cpt.length; ++i)
                        $root.infinitusapi.INFBVTaskInputCPT.encode(message.cpt[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.diagnosis != null && message.diagnosis.length)
                    for (var i = 0; i < message.diagnosis.length; ++i)
                        $root.infinitusapi.INFTaskInputDiagnosis.encode(message.diagnosis[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.member != null && Object.hasOwnProperty.call(message, "member"))
                    $root.infinitusapi.INFTaskInputMember.encode(message.member, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.payer != null && Object.hasOwnProperty.call(message, "payer"))
                    $root.infinitusapi.INFTaskInputPayer.encode(message.payer, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.placeOfService != null && Object.hasOwnProperty.call(message, "placeOfService"))
                    $root.infinitusapi.INFBVTaskInputPlaceOfService.encode(message.placeOfService, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.policyHolder != null && Object.hasOwnProperty.call(message, "policyHolder"))
                    $root.infinitusapi.INFBVTaskInputPolicyHolder.encode(message.policyHolder, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.practice != null && Object.hasOwnProperty.call(message, "practice"))
                    $root.infinitusapi.INFTaskInputPractice.encode(message.practice, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.products != null && message.products.length)
                    for (var i = 0; i < message.products.length; ++i)
                        $root.infinitusapi.INFTaskInputProduct.encode(message.products[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
                    $root.infinitusapi.INFTaskInputProvider.encode(message.provider, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.sitesOfCare != null && message.sitesOfCare.length)
                    for (var i = 0; i < message.sitesOfCare.length; ++i)
                        $root.infinitusapi.INFBVTaskInputSiteOfCare.encode(message.sitesOfCare[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskInput message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskInput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {infinitusapi.IINFBVTaskInput} message INFBVTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskInput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskInput} INFBVTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskInput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.chainPBM = reader.bool();
                            break;
                        }
                    case 3: {
                            if (!(message.cpt && message.cpt.length))
                                message.cpt = [];
                            message.cpt.push($root.infinitusapi.INFBVTaskInputCPT.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.diagnosis && message.diagnosis.length))
                                message.diagnosis = [];
                            message.diagnosis.push($root.infinitusapi.INFTaskInputDiagnosis.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            message.member = $root.infinitusapi.INFTaskInputMember.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.payer = $root.infinitusapi.INFTaskInputPayer.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.placeOfService = $root.infinitusapi.INFBVTaskInputPlaceOfService.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.practice = $root.infinitusapi.INFTaskInputPractice.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            if (!(message.products && message.products.length))
                                message.products = [];
                            message.products.push($root.infinitusapi.INFTaskInputProduct.decode(reader, reader.uint32()));
                            break;
                        }
                    case 11: {
                            message.provider = $root.infinitusapi.INFTaskInputProvider.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            if (!(message.sitesOfCare && message.sitesOfCare.length))
                                message.sitesOfCare = [];
                            message.sitesOfCare.push($root.infinitusapi.INFBVTaskInputSiteOfCare.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskInput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskInput} INFBVTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskInput message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskInput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.chainPBM != null && message.hasOwnProperty("chainPBM"))
                    if (typeof message.chainPBM !== "boolean")
                        return "chainPBM: boolean expected";
                if (message.cpt != null && message.hasOwnProperty("cpt")) {
                    if (!Array.isArray(message.cpt))
                        return "cpt: array expected";
                    for (var i = 0; i < message.cpt.length; ++i) {
                        var error = $root.infinitusapi.INFBVTaskInputCPT.verify(message.cpt[i]);
                        if (error)
                            return "cpt." + error;
                    }
                }
                if (message.diagnosis != null && message.hasOwnProperty("diagnosis")) {
                    if (!Array.isArray(message.diagnosis))
                        return "diagnosis: array expected";
                    for (var i = 0; i < message.diagnosis.length; ++i) {
                        var error = $root.infinitusapi.INFTaskInputDiagnosis.verify(message.diagnosis[i]);
                        if (error)
                            return "diagnosis." + error;
                    }
                }
                if (message.member != null && message.hasOwnProperty("member")) {
                    var error = $root.infinitusapi.INFTaskInputMember.verify(message.member);
                    if (error)
                        return "member." + error;
                }
                if (message.payer != null && message.hasOwnProperty("payer")) {
                    var error = $root.infinitusapi.INFTaskInputPayer.verify(message.payer);
                    if (error)
                        return "payer." + error;
                }
                if (message.placeOfService != null && message.hasOwnProperty("placeOfService")) {
                    var error = $root.infinitusapi.INFBVTaskInputPlaceOfService.verify(message.placeOfService);
                    if (error)
                        return "placeOfService." + error;
                }
                if (message.policyHolder != null && message.hasOwnProperty("policyHolder")) {
                    var error = $root.infinitusapi.INFBVTaskInputPolicyHolder.verify(message.policyHolder);
                    if (error)
                        return "policyHolder." + error;
                }
                if (message.practice != null && message.hasOwnProperty("practice")) {
                    var error = $root.infinitusapi.INFTaskInputPractice.verify(message.practice);
                    if (error)
                        return "practice." + error;
                }
                if (message.products != null && message.hasOwnProperty("products")) {
                    if (!Array.isArray(message.products))
                        return "products: array expected";
                    for (var i = 0; i < message.products.length; ++i) {
                        var error = $root.infinitusapi.INFTaskInputProduct.verify(message.products[i]);
                        if (error)
                            return "products." + error;
                    }
                }
                if (message.provider != null && message.hasOwnProperty("provider")) {
                    var error = $root.infinitusapi.INFTaskInputProvider.verify(message.provider);
                    if (error)
                        return "provider." + error;
                }
                if (message.sitesOfCare != null && message.hasOwnProperty("sitesOfCare")) {
                    if (!Array.isArray(message.sitesOfCare))
                        return "sitesOfCare: array expected";
                    for (var i = 0; i < message.sitesOfCare.length; ++i) {
                        var error = $root.infinitusapi.INFBVTaskInputSiteOfCare.verify(message.sitesOfCare[i]);
                        if (error)
                            return "sitesOfCare." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a INFBVTaskInput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskInput} INFBVTaskInput
             */
            INFBVTaskInput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskInput)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskInput();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.chainPBM != null)
                    message.chainPBM = Boolean(object.chainPBM);
                if (object.cpt) {
                    if (!Array.isArray(object.cpt))
                        throw TypeError(".infinitusapi.INFBVTaskInput.cpt: array expected");
                    message.cpt = [];
                    for (var i = 0; i < object.cpt.length; ++i) {
                        if (typeof object.cpt[i] !== "object")
                            throw TypeError(".infinitusapi.INFBVTaskInput.cpt: object expected");
                        message.cpt[i] = $root.infinitusapi.INFBVTaskInputCPT.fromObject(object.cpt[i]);
                    }
                }
                if (object.diagnosis) {
                    if (!Array.isArray(object.diagnosis))
                        throw TypeError(".infinitusapi.INFBVTaskInput.diagnosis: array expected");
                    message.diagnosis = [];
                    for (var i = 0; i < object.diagnosis.length; ++i) {
                        if (typeof object.diagnosis[i] !== "object")
                            throw TypeError(".infinitusapi.INFBVTaskInput.diagnosis: object expected");
                        message.diagnosis[i] = $root.infinitusapi.INFTaskInputDiagnosis.fromObject(object.diagnosis[i]);
                    }
                }
                if (object.member != null) {
                    if (typeof object.member !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskInput.member: object expected");
                    message.member = $root.infinitusapi.INFTaskInputMember.fromObject(object.member);
                }
                if (object.payer != null) {
                    if (typeof object.payer !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskInput.payer: object expected");
                    message.payer = $root.infinitusapi.INFTaskInputPayer.fromObject(object.payer);
                }
                if (object.placeOfService != null) {
                    if (typeof object.placeOfService !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskInput.placeOfService: object expected");
                    message.placeOfService = $root.infinitusapi.INFBVTaskInputPlaceOfService.fromObject(object.placeOfService);
                }
                if (object.policyHolder != null) {
                    if (typeof object.policyHolder !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskInput.policyHolder: object expected");
                    message.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.fromObject(object.policyHolder);
                }
                if (object.practice != null) {
                    if (typeof object.practice !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskInput.practice: object expected");
                    message.practice = $root.infinitusapi.INFTaskInputPractice.fromObject(object.practice);
                }
                if (object.products) {
                    if (!Array.isArray(object.products))
                        throw TypeError(".infinitusapi.INFBVTaskInput.products: array expected");
                    message.products = [];
                    for (var i = 0; i < object.products.length; ++i) {
                        if (typeof object.products[i] !== "object")
                            throw TypeError(".infinitusapi.INFBVTaskInput.products: object expected");
                        message.products[i] = $root.infinitusapi.INFTaskInputProduct.fromObject(object.products[i]);
                    }
                }
                if (object.provider != null) {
                    if (typeof object.provider !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskInput.provider: object expected");
                    message.provider = $root.infinitusapi.INFTaskInputProvider.fromObject(object.provider);
                }
                if (object.sitesOfCare) {
                    if (!Array.isArray(object.sitesOfCare))
                        throw TypeError(".infinitusapi.INFBVTaskInput.sitesOfCare: array expected");
                    message.sitesOfCare = [];
                    for (var i = 0; i < object.sitesOfCare.length; ++i) {
                        if (typeof object.sitesOfCare[i] !== "object")
                            throw TypeError(".infinitusapi.INFBVTaskInput.sitesOfCare: object expected");
                        message.sitesOfCare[i] = $root.infinitusapi.INFBVTaskInputSiteOfCare.fromObject(object.sitesOfCare[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskInput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {infinitusapi.INFBVTaskInput} message INFBVTaskInput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskInput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.cpt = [];
                    object.diagnosis = [];
                    object.products = [];
                    object.sitesOfCare = [];
                }
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.chainPBM = false;
                    object.member = null;
                    object.payer = null;
                    object.placeOfService = null;
                    object.policyHolder = null;
                    object.practice = null;
                    object.provider = null;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.chainPBM != null && message.hasOwnProperty("chainPBM"))
                    object.chainPBM = message.chainPBM;
                if (message.cpt && message.cpt.length) {
                    object.cpt = [];
                    for (var j = 0; j < message.cpt.length; ++j)
                        object.cpt[j] = $root.infinitusapi.INFBVTaskInputCPT.toObject(message.cpt[j], options);
                }
                if (message.diagnosis && message.diagnosis.length) {
                    object.diagnosis = [];
                    for (var j = 0; j < message.diagnosis.length; ++j)
                        object.diagnosis[j] = $root.infinitusapi.INFTaskInputDiagnosis.toObject(message.diagnosis[j], options);
                }
                if (message.member != null && message.hasOwnProperty("member"))
                    object.member = $root.infinitusapi.INFTaskInputMember.toObject(message.member, options);
                if (message.payer != null && message.hasOwnProperty("payer"))
                    object.payer = $root.infinitusapi.INFTaskInputPayer.toObject(message.payer, options);
                if (message.placeOfService != null && message.hasOwnProperty("placeOfService"))
                    object.placeOfService = $root.infinitusapi.INFBVTaskInputPlaceOfService.toObject(message.placeOfService, options);
                if (message.policyHolder != null && message.hasOwnProperty("policyHolder"))
                    object.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.toObject(message.policyHolder, options);
                if (message.practice != null && message.hasOwnProperty("practice"))
                    object.practice = $root.infinitusapi.INFTaskInputPractice.toObject(message.practice, options);
                if (message.products && message.products.length) {
                    object.products = [];
                    for (var j = 0; j < message.products.length; ++j)
                        object.products[j] = $root.infinitusapi.INFTaskInputProduct.toObject(message.products[j], options);
                }
                if (message.provider != null && message.hasOwnProperty("provider"))
                    object.provider = $root.infinitusapi.INFTaskInputProvider.toObject(message.provider, options);
                if (message.sitesOfCare && message.sitesOfCare.length) {
                    object.sitesOfCare = [];
                    for (var j = 0; j < message.sitesOfCare.length; ++j)
                        object.sitesOfCare[j] = $root.infinitusapi.INFBVTaskInputSiteOfCare.toObject(message.sitesOfCare[j], options);
                }
                return object;
            };
    
            /**
             * Converts this INFBVTaskInput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskInput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskInput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskInput
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskInput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskInput";
            };
    
            return INFBVTaskInput;
        })();
    
        infinitusapi.INFBVTaskInputCPT = (function() {
    
            /**
             * Properties of a INFBVTaskInputCPT.
             * @memberof infinitusapi
             * @interface IINFBVTaskInputCPT
             * @property {string|null} [code] INFBVTaskInputCPT code
             */
    
            /**
             * Constructs a new INFBVTaskInputCPT.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskInputCPT.
             * @implements IINFBVTaskInputCPT
             * @constructor
             * @param {infinitusapi.IINFBVTaskInputCPT=} [properties] Properties to set
             */
            function INFBVTaskInputCPT(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskInputCPT code.
             * @member {string} code
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @instance
             */
            INFBVTaskInputCPT.prototype.code = "";
    
            /**
             * Creates a new INFBVTaskInputCPT instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {infinitusapi.IINFBVTaskInputCPT=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskInputCPT} INFBVTaskInputCPT instance
             */
            INFBVTaskInputCPT.create = function create(properties) {
                return new INFBVTaskInputCPT(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskInputCPT message. Does not implicitly {@link infinitusapi.INFBVTaskInputCPT.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {infinitusapi.IINFBVTaskInputCPT} message INFBVTaskInputCPT message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInputCPT.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskInputCPT message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskInputCPT.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {infinitusapi.IINFBVTaskInputCPT} message INFBVTaskInputCPT message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInputCPT.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskInputCPT message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskInputCPT} INFBVTaskInputCPT
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInputCPT.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskInputCPT();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskInputCPT message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskInputCPT} INFBVTaskInputCPT
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInputCPT.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskInputCPT message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskInputCPT.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                return null;
            };
    
            /**
             * Creates a INFBVTaskInputCPT message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskInputCPT} INFBVTaskInputCPT
             */
            INFBVTaskInputCPT.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskInputCPT)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskInputCPT();
                if (object.code != null)
                    message.code = String(object.code);
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskInputCPT message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {infinitusapi.INFBVTaskInputCPT} message INFBVTaskInputCPT
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskInputCPT.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.code = "";
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                return object;
            };
    
            /**
             * Converts this INFBVTaskInputCPT to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskInputCPT.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskInputCPT
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskInputCPT
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskInputCPT.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskInputCPT";
            };
    
            return INFBVTaskInputCPT;
        })();
    
        infinitusapi.INFBVTaskInputPlaceOfService = (function() {
    
            /**
             * Properties of a INFBVTaskInputPlaceOfService.
             * @memberof infinitusapi
             * @interface IINFBVTaskInputPlaceOfService
             * @property {infinitusapi.INFFacilityType|null} [facilityType] INFBVTaskInputPlaceOfService facilityType
             * @property {string|null} [facilityTypeAdditionalNotes] INFBVTaskInputPlaceOfService facilityTypeAdditionalNotes
             * @property {google.protobuf.IBoolValue|null} [specialtyPharmacyInfoRequired] INFBVTaskInputPlaceOfService specialtyPharmacyInfoRequired
             * @property {string|null} [treatmentDate] INFBVTaskInputPlaceOfService treatmentDate
             */
    
            /**
             * Constructs a new INFBVTaskInputPlaceOfService.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskInputPlaceOfService.
             * @implements IINFBVTaskInputPlaceOfService
             * @constructor
             * @param {infinitusapi.IINFBVTaskInputPlaceOfService=} [properties] Properties to set
             */
            function INFBVTaskInputPlaceOfService(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskInputPlaceOfService facilityType.
             * @member {infinitusapi.INFFacilityType} facilityType
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @instance
             */
            INFBVTaskInputPlaceOfService.prototype.facilityType = 0;
    
            /**
             * INFBVTaskInputPlaceOfService facilityTypeAdditionalNotes.
             * @member {string} facilityTypeAdditionalNotes
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @instance
             */
            INFBVTaskInputPlaceOfService.prototype.facilityTypeAdditionalNotes = "";
    
            /**
             * INFBVTaskInputPlaceOfService specialtyPharmacyInfoRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} specialtyPharmacyInfoRequired
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @instance
             */
            INFBVTaskInputPlaceOfService.prototype.specialtyPharmacyInfoRequired = null;
    
            /**
             * INFBVTaskInputPlaceOfService treatmentDate.
             * @member {string} treatmentDate
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @instance
             */
            INFBVTaskInputPlaceOfService.prototype.treatmentDate = "";
    
            /**
             * Creates a new INFBVTaskInputPlaceOfService instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {infinitusapi.IINFBVTaskInputPlaceOfService=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskInputPlaceOfService} INFBVTaskInputPlaceOfService instance
             */
            INFBVTaskInputPlaceOfService.create = function create(properties) {
                return new INFBVTaskInputPlaceOfService(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskInputPlaceOfService message. Does not implicitly {@link infinitusapi.INFBVTaskInputPlaceOfService.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {infinitusapi.IINFBVTaskInputPlaceOfService} message INFBVTaskInputPlaceOfService message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInputPlaceOfService.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.facilityType != null && Object.hasOwnProperty.call(message, "facilityType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.facilityType);
                if (message.facilityTypeAdditionalNotes != null && Object.hasOwnProperty.call(message, "facilityTypeAdditionalNotes"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.facilityTypeAdditionalNotes);
                if (message.specialtyPharmacyInfoRequired != null && Object.hasOwnProperty.call(message, "specialtyPharmacyInfoRequired"))
                    $root.google.protobuf.BoolValue.encode(message.specialtyPharmacyInfoRequired, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.treatmentDate != null && Object.hasOwnProperty.call(message, "treatmentDate"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.treatmentDate);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskInputPlaceOfService message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskInputPlaceOfService.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {infinitusapi.IINFBVTaskInputPlaceOfService} message INFBVTaskInputPlaceOfService message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInputPlaceOfService.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskInputPlaceOfService message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskInputPlaceOfService} INFBVTaskInputPlaceOfService
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInputPlaceOfService.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskInputPlaceOfService();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.facilityType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.facilityTypeAdditionalNotes = reader.string();
                            break;
                        }
                    case 3: {
                            message.specialtyPharmacyInfoRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.treatmentDate = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskInputPlaceOfService message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskInputPlaceOfService} INFBVTaskInputPlaceOfService
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInputPlaceOfService.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskInputPlaceOfService message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskInputPlaceOfService.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                    switch (message.facilityType) {
                    default:
                        return "facilityType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.facilityTypeAdditionalNotes != null && message.hasOwnProperty("facilityTypeAdditionalNotes"))
                    if (!$util.isString(message.facilityTypeAdditionalNotes))
                        return "facilityTypeAdditionalNotes: string expected";
                if (message.specialtyPharmacyInfoRequired != null && message.hasOwnProperty("specialtyPharmacyInfoRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.specialtyPharmacyInfoRequired);
                    if (error)
                        return "specialtyPharmacyInfoRequired." + error;
                }
                if (message.treatmentDate != null && message.hasOwnProperty("treatmentDate"))
                    if (!$util.isString(message.treatmentDate))
                        return "treatmentDate: string expected";
                return null;
            };
    
            /**
             * Creates a INFBVTaskInputPlaceOfService message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskInputPlaceOfService} INFBVTaskInputPlaceOfService
             */
            INFBVTaskInputPlaceOfService.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskInputPlaceOfService)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskInputPlaceOfService();
                switch (object.facilityType) {
                default:
                    if (typeof object.facilityType === "number") {
                        message.facilityType = object.facilityType;
                        break;
                    }
                    break;
                case "INF_FACILITY_TYPE_UNKNOWN":
                case 0:
                    message.facilityType = 0;
                    break;
                case "INF_FACILITY_TYPE_SPECIALIST_OFFICE":
                case 1:
                    message.facilityType = 1;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT":
                case 2:
                    message.facilityType = 2;
                    break;
                case "INF_FACILITY_TYPE_PHARMACY":
                case 3:
                    message.facilityType = 3;
                    break;
                case "INF_FACILITY_TYPE_SHIP_TO_HOME":
                case 4:
                    message.facilityType = 4;
                    break;
                case "INF_FACILITY_TYPE_INFUSION_CENTER":
                case 5:
                    message.facilityType = 5;
                    break;
                case "INF_FACILITY_TYPE_AMBULATORY_SURGICAL_CENTER":
                case 6:
                    message.facilityType = 6;
                    break;
                case "INF_FACILITY_TYPE_HOME_INFUSION":
                case 7:
                    message.facilityType = 7;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT_OFF_CAMPUS":
                case 8:
                    message.facilityType = 8;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_INPATIENT":
                case 9:
                    message.facilityType = 9;
                    break;
                case "INF_FACILITY_TYPE_SNF":
                case 10:
                    message.facilityType = 10;
                    break;
                case "INF_FACILITY_TYPE_HOSPICE":
                case 11:
                    message.facilityType = 11;
                    break;
                case "INF_FACILITY_TYPE_IHC":
                case 12:
                    message.facilityType = 12;
                    break;
                case "INF_FACILITY_TYPE_FED_QUALIFIED_HC":
                case 13:
                    message.facilityType = 13;
                    break;
                case "INF_FACILITY_TYPE_INPATIENT_REHAB":
                case 14:
                    message.facilityType = 14;
                    break;
                case "INF_FACILITY_TYPE_OUTPATIENT_REHAB":
                case 15:
                    message.facilityType = 15;
                    break;
                case "INF_FACILITY_TYPE_PUBLIC_HEALTH_CLINIC":
                case 16:
                    message.facilityType = 16;
                    break;
                case "INF_FACILITY_TYPE_RURAL_HEALTH_CLINIC":
                case 17:
                    message.facilityType = 17;
                    break;
                case "INF_FACILITY_TYPE_DIAGNOSTIC_TEST_LAB":
                case 18:
                    message.facilityType = 18;
                    break;
                }
                if (object.facilityTypeAdditionalNotes != null)
                    message.facilityTypeAdditionalNotes = String(object.facilityTypeAdditionalNotes);
                if (object.specialtyPharmacyInfoRequired != null) {
                    if (typeof object.specialtyPharmacyInfoRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskInputPlaceOfService.specialtyPharmacyInfoRequired: object expected");
                    message.specialtyPharmacyInfoRequired = $root.google.protobuf.BoolValue.fromObject(object.specialtyPharmacyInfoRequired);
                }
                if (object.treatmentDate != null)
                    message.treatmentDate = String(object.treatmentDate);
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskInputPlaceOfService message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {infinitusapi.INFBVTaskInputPlaceOfService} message INFBVTaskInputPlaceOfService
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskInputPlaceOfService.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.facilityType = options.enums === String ? "INF_FACILITY_TYPE_UNKNOWN" : 0;
                    object.facilityTypeAdditionalNotes = "";
                    object.specialtyPharmacyInfoRequired = null;
                    object.treatmentDate = "";
                }
                if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                    object.facilityType = options.enums === String ? $root.infinitusapi.INFFacilityType[message.facilityType] === undefined ? message.facilityType : $root.infinitusapi.INFFacilityType[message.facilityType] : message.facilityType;
                if (message.facilityTypeAdditionalNotes != null && message.hasOwnProperty("facilityTypeAdditionalNotes"))
                    object.facilityTypeAdditionalNotes = message.facilityTypeAdditionalNotes;
                if (message.specialtyPharmacyInfoRequired != null && message.hasOwnProperty("specialtyPharmacyInfoRequired"))
                    object.specialtyPharmacyInfoRequired = $root.google.protobuf.BoolValue.toObject(message.specialtyPharmacyInfoRequired, options);
                if (message.treatmentDate != null && message.hasOwnProperty("treatmentDate"))
                    object.treatmentDate = message.treatmentDate;
                return object;
            };
    
            /**
             * Converts this INFBVTaskInputPlaceOfService to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskInputPlaceOfService.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskInputPlaceOfService
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskInputPlaceOfService
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskInputPlaceOfService.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskInputPlaceOfService";
            };
    
            return INFBVTaskInputPlaceOfService;
        })();
    
        infinitusapi.INFBVTaskInputPolicyHolder = (function() {
    
            /**
             * Properties of a INFBVTaskInputPolicyHolder.
             * @memberof infinitusapi
             * @interface IINFBVTaskInputPolicyHolder
             * @property {string|null} [dateOfBirth] INFBVTaskInputPolicyHolder dateOfBirth
             * @property {string|null} [firstName] INFBVTaskInputPolicyHolder firstName
             * @property {string|null} [lastName] INFBVTaskInputPolicyHolder lastName
             */
    
            /**
             * Constructs a new INFBVTaskInputPolicyHolder.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskInputPolicyHolder.
             * @implements IINFBVTaskInputPolicyHolder
             * @constructor
             * @param {infinitusapi.IINFBVTaskInputPolicyHolder=} [properties] Properties to set
             */
            function INFBVTaskInputPolicyHolder(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskInputPolicyHolder dateOfBirth.
             * @member {string} dateOfBirth
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @instance
             */
            INFBVTaskInputPolicyHolder.prototype.dateOfBirth = "";
    
            /**
             * INFBVTaskInputPolicyHolder firstName.
             * @member {string} firstName
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @instance
             */
            INFBVTaskInputPolicyHolder.prototype.firstName = "";
    
            /**
             * INFBVTaskInputPolicyHolder lastName.
             * @member {string} lastName
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @instance
             */
            INFBVTaskInputPolicyHolder.prototype.lastName = "";
    
            /**
             * Creates a new INFBVTaskInputPolicyHolder instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {infinitusapi.IINFBVTaskInputPolicyHolder=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskInputPolicyHolder} INFBVTaskInputPolicyHolder instance
             */
            INFBVTaskInputPolicyHolder.create = function create(properties) {
                return new INFBVTaskInputPolicyHolder(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskInputPolicyHolder message. Does not implicitly {@link infinitusapi.INFBVTaskInputPolicyHolder.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {infinitusapi.IINFBVTaskInputPolicyHolder} message INFBVTaskInputPolicyHolder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInputPolicyHolder.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.dateOfBirth);
                if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskInputPolicyHolder message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskInputPolicyHolder.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {infinitusapi.IINFBVTaskInputPolicyHolder} message INFBVTaskInputPolicyHolder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInputPolicyHolder.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskInputPolicyHolder message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskInputPolicyHolder} INFBVTaskInputPolicyHolder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInputPolicyHolder.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskInputPolicyHolder();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dateOfBirth = reader.string();
                            break;
                        }
                    case 2: {
                            message.firstName = reader.string();
                            break;
                        }
                    case 3: {
                            message.lastName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskInputPolicyHolder message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskInputPolicyHolder} INFBVTaskInputPolicyHolder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInputPolicyHolder.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskInputPolicyHolder message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskInputPolicyHolder.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                    if (!$util.isString(message.dateOfBirth))
                        return "dateOfBirth: string expected";
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    if (!$util.isString(message.firstName))
                        return "firstName: string expected";
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    if (!$util.isString(message.lastName))
                        return "lastName: string expected";
                return null;
            };
    
            /**
             * Creates a INFBVTaskInputPolicyHolder message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskInputPolicyHolder} INFBVTaskInputPolicyHolder
             */
            INFBVTaskInputPolicyHolder.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskInputPolicyHolder)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskInputPolicyHolder();
                if (object.dateOfBirth != null)
                    message.dateOfBirth = String(object.dateOfBirth);
                if (object.firstName != null)
                    message.firstName = String(object.firstName);
                if (object.lastName != null)
                    message.lastName = String(object.lastName);
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskInputPolicyHolder message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {infinitusapi.INFBVTaskInputPolicyHolder} message INFBVTaskInputPolicyHolder
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskInputPolicyHolder.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.dateOfBirth = "";
                    object.firstName = "";
                    object.lastName = "";
                }
                if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                    object.dateOfBirth = message.dateOfBirth;
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    object.firstName = message.firstName;
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    object.lastName = message.lastName;
                return object;
            };
    
            /**
             * Converts this INFBVTaskInputPolicyHolder to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskInputPolicyHolder.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskInputPolicyHolder
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskInputPolicyHolder
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskInputPolicyHolder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskInputPolicyHolder";
            };
    
            return INFBVTaskInputPolicyHolder;
        })();
    
        infinitusapi.INFBVTaskInputSiteOfCare = (function() {
    
            /**
             * Properties of a INFBVTaskInputSiteOfCare.
             * @memberof infinitusapi
             * @interface IINFBVTaskInputSiteOfCare
             * @property {infinitusapi.IINFAddress|null} [address] INFBVTaskInputSiteOfCare address
             * @property {Array.<string>|null} [alternateNames] INFBVTaskInputSiteOfCare alternateNames
             * @property {string|null} [name] INFBVTaskInputSiteOfCare name
             * @property {string|null} [npi] INFBVTaskInputSiteOfCare npi
             * @property {string|null} [taxId] INFBVTaskInputSiteOfCare taxId
             */
    
            /**
             * Constructs a new INFBVTaskInputSiteOfCare.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskInputSiteOfCare.
             * @implements IINFBVTaskInputSiteOfCare
             * @constructor
             * @param {infinitusapi.IINFBVTaskInputSiteOfCare=} [properties] Properties to set
             */
            function INFBVTaskInputSiteOfCare(properties) {
                this.alternateNames = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskInputSiteOfCare address.
             * @member {infinitusapi.IINFAddress|null|undefined} address
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @instance
             */
            INFBVTaskInputSiteOfCare.prototype.address = null;
    
            /**
             * INFBVTaskInputSiteOfCare alternateNames.
             * @member {Array.<string>} alternateNames
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @instance
             */
            INFBVTaskInputSiteOfCare.prototype.alternateNames = $util.emptyArray;
    
            /**
             * INFBVTaskInputSiteOfCare name.
             * @member {string} name
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @instance
             */
            INFBVTaskInputSiteOfCare.prototype.name = "";
    
            /**
             * INFBVTaskInputSiteOfCare npi.
             * @member {string} npi
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @instance
             */
            INFBVTaskInputSiteOfCare.prototype.npi = "";
    
            /**
             * INFBVTaskInputSiteOfCare taxId.
             * @member {string} taxId
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @instance
             */
            INFBVTaskInputSiteOfCare.prototype.taxId = "";
    
            /**
             * Creates a new INFBVTaskInputSiteOfCare instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {infinitusapi.IINFBVTaskInputSiteOfCare=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskInputSiteOfCare} INFBVTaskInputSiteOfCare instance
             */
            INFBVTaskInputSiteOfCare.create = function create(properties) {
                return new INFBVTaskInputSiteOfCare(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskInputSiteOfCare message. Does not implicitly {@link infinitusapi.INFBVTaskInputSiteOfCare.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {infinitusapi.IINFBVTaskInputSiteOfCare} message INFBVTaskInputSiteOfCare message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInputSiteOfCare.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.infinitusapi.INFAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.alternateNames != null && message.alternateNames.length)
                    for (var i = 0; i < message.alternateNames.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.alternateNames[i]);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.npi != null && Object.hasOwnProperty.call(message, "npi"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.npi);
                if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.taxId);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskInputSiteOfCare message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskInputSiteOfCare.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {infinitusapi.IINFBVTaskInputSiteOfCare} message INFBVTaskInputSiteOfCare message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskInputSiteOfCare.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskInputSiteOfCare message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskInputSiteOfCare} INFBVTaskInputSiteOfCare
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInputSiteOfCare.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskInputSiteOfCare();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.alternateNames && message.alternateNames.length))
                                message.alternateNames = [];
                            message.alternateNames.push(reader.string());
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.npi = reader.string();
                            break;
                        }
                    case 5: {
                            message.taxId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskInputSiteOfCare message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskInputSiteOfCare} INFBVTaskInputSiteOfCare
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskInputSiteOfCare.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskInputSiteOfCare message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskInputSiteOfCare.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                if (message.alternateNames != null && message.hasOwnProperty("alternateNames")) {
                    if (!Array.isArray(message.alternateNames))
                        return "alternateNames: array expected";
                    for (var i = 0; i < message.alternateNames.length; ++i)
                        if (!$util.isString(message.alternateNames[i]))
                            return "alternateNames: string[] expected";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.npi != null && message.hasOwnProperty("npi"))
                    if (!$util.isString(message.npi))
                        return "npi: string expected";
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    if (!$util.isString(message.taxId))
                        return "taxId: string expected";
                return null;
            };
    
            /**
             * Creates a INFBVTaskInputSiteOfCare message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskInputSiteOfCare} INFBVTaskInputSiteOfCare
             */
            INFBVTaskInputSiteOfCare.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskInputSiteOfCare)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskInputSiteOfCare();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskInputSiteOfCare.address: object expected");
                    message.address = $root.infinitusapi.INFAddress.fromObject(object.address);
                }
                if (object.alternateNames) {
                    if (!Array.isArray(object.alternateNames))
                        throw TypeError(".infinitusapi.INFBVTaskInputSiteOfCare.alternateNames: array expected");
                    message.alternateNames = [];
                    for (var i = 0; i < object.alternateNames.length; ++i)
                        message.alternateNames[i] = String(object.alternateNames[i]);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.npi != null)
                    message.npi = String(object.npi);
                if (object.taxId != null)
                    message.taxId = String(object.taxId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskInputSiteOfCare message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {infinitusapi.INFBVTaskInputSiteOfCare} message INFBVTaskInputSiteOfCare
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskInputSiteOfCare.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.alternateNames = [];
                if (options.defaults) {
                    object.address = null;
                    object.name = "";
                    object.npi = "";
                    object.taxId = "";
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.infinitusapi.INFAddress.toObject(message.address, options);
                if (message.alternateNames && message.alternateNames.length) {
                    object.alternateNames = [];
                    for (var j = 0; j < message.alternateNames.length; ++j)
                        object.alternateNames[j] = message.alternateNames[j];
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.npi != null && message.hasOwnProperty("npi"))
                    object.npi = message.npi;
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    object.taxId = message.taxId;
                return object;
            };
    
            /**
             * Converts this INFBVTaskInputSiteOfCare to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskInputSiteOfCare.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskInputSiteOfCare
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskInputSiteOfCare
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskInputSiteOfCare.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskInputSiteOfCare";
            };
    
            return INFBVTaskInputSiteOfCare;
        })();
    
        infinitusapi.INFBVTaskOutput = (function() {
    
            /**
             * Properties of a INFBVTaskOutput.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutput
             * @property {infinitusapi.IINFBVTaskOutputAccessInfo|null} [accessInfo] INFBVTaskOutput accessInfo
             * @property {string|null} [additionalNotes] INFBVTaskOutput additionalNotes
             * @property {string|null} [billingNotes] INFBVTaskOutput billingNotes
             * @property {string|null} [chcControlNumber] INFBVTaskOutput chcControlNumber
             * @property {Array.<infinitusapi.IINFBVTaskOutputCPTCodeBenefits>|null} [cptCodeBenefits] INFBVTaskOutput cptCodeBenefits
             * @property {infinitusapi.IINFBVTaskOutputDeductiblesAndOOPS|null} [deductiblesAndOops] INFBVTaskOutput deductiblesAndOops
             * @property {infinitusapi.IINFPriorAuthDenialInfo|null} [denialInfo] INFBVTaskOutput denialInfo
             * @property {infinitusapi.IINFBVTaskOutputErrorInfo|null} [errorInfo] INFBVTaskOutput errorInfo
             * @property {infinitusapi.IINFBVTaskOutputHomeHealthBenefits|null} [homeHealthBenefits] INFBVTaskOutput homeHealthBenefits
             * @property {string|null} [infinitusPayerId] INFBVTaskOutput infinitusPayerId
             * @property {infinitusapi.IINFBVTaskOutputOfficeBenefits|null} [officeBenefits] INFBVTaskOutput officeBenefits
             * @property {string|null} [payerAgentName] INFBVTaskOutput payerAgentName
             * @property {string|null} [payerName] INFBVTaskOutput payerName
             * @property {string|null} [payerPhone] INFBVTaskOutput payerPhone
             * @property {infinitusapi.IINFBVTaskOutputPlanInfo|null} [planInfo] INFBVTaskOutput planInfo
             * @property {Array.<infinitusapi.IINFBVTaskOutputProductCodeBenefits>|null} [productCodeBenefits] INFBVTaskOutput productCodeBenefits
             * @property {infinitusapi.INFReasonForNonCoverage|null} [reasonForNonCoverage] INFBVTaskOutput reasonForNonCoverage
             * @property {string|null} [referenceNumber] INFBVTaskOutput referenceNumber
             * @property {google.protobuf.IBoolValue|null} [reviewRequired] INFBVTaskOutput reviewRequired
             * @property {string|null} [taskLink] INFBVTaskOutput taskLink
             */
    
            /**
             * Constructs a new INFBVTaskOutput.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutput.
             * @implements IINFBVTaskOutput
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutput=} [properties] Properties to set
             */
            function INFBVTaskOutput(properties) {
                this.cptCodeBenefits = [];
                this.productCodeBenefits = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutput accessInfo.
             * @member {infinitusapi.IINFBVTaskOutputAccessInfo|null|undefined} accessInfo
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.accessInfo = null;
    
            /**
             * INFBVTaskOutput additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.additionalNotes = "";
    
            /**
             * INFBVTaskOutput billingNotes.
             * @member {string} billingNotes
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.billingNotes = "";
    
            /**
             * INFBVTaskOutput chcControlNumber.
             * @member {string} chcControlNumber
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.chcControlNumber = "";
    
            /**
             * INFBVTaskOutput cptCodeBenefits.
             * @member {Array.<infinitusapi.IINFBVTaskOutputCPTCodeBenefits>} cptCodeBenefits
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.cptCodeBenefits = $util.emptyArray;
    
            /**
             * INFBVTaskOutput deductiblesAndOops.
             * @member {infinitusapi.IINFBVTaskOutputDeductiblesAndOOPS|null|undefined} deductiblesAndOops
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.deductiblesAndOops = null;
    
            /**
             * INFBVTaskOutput denialInfo.
             * @member {infinitusapi.IINFPriorAuthDenialInfo|null|undefined} denialInfo
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.denialInfo = null;
    
            /**
             * INFBVTaskOutput errorInfo.
             * @member {infinitusapi.IINFBVTaskOutputErrorInfo|null|undefined} errorInfo
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.errorInfo = null;
    
            /**
             * INFBVTaskOutput homeHealthBenefits.
             * @member {infinitusapi.IINFBVTaskOutputHomeHealthBenefits|null|undefined} homeHealthBenefits
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.homeHealthBenefits = null;
    
            /**
             * INFBVTaskOutput infinitusPayerId.
             * @member {string} infinitusPayerId
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.infinitusPayerId = "";
    
            /**
             * INFBVTaskOutput officeBenefits.
             * @member {infinitusapi.IINFBVTaskOutputOfficeBenefits|null|undefined} officeBenefits
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.officeBenefits = null;
    
            /**
             * INFBVTaskOutput payerAgentName.
             * @member {string} payerAgentName
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.payerAgentName = "";
    
            /**
             * INFBVTaskOutput payerName.
             * @member {string} payerName
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.payerName = "";
    
            /**
             * INFBVTaskOutput payerPhone.
             * @member {string} payerPhone
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.payerPhone = "";
    
            /**
             * INFBVTaskOutput planInfo.
             * @member {infinitusapi.IINFBVTaskOutputPlanInfo|null|undefined} planInfo
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.planInfo = null;
    
            /**
             * INFBVTaskOutput productCodeBenefits.
             * @member {Array.<infinitusapi.IINFBVTaskOutputProductCodeBenefits>} productCodeBenefits
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.productCodeBenefits = $util.emptyArray;
    
            /**
             * INFBVTaskOutput reasonForNonCoverage.
             * @member {infinitusapi.INFReasonForNonCoverage} reasonForNonCoverage
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.reasonForNonCoverage = 0;
    
            /**
             * INFBVTaskOutput referenceNumber.
             * @member {string} referenceNumber
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.referenceNumber = "";
    
            /**
             * INFBVTaskOutput reviewRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} reviewRequired
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.reviewRequired = null;
    
            /**
             * INFBVTaskOutput taskLink.
             * @member {string} taskLink
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             */
            INFBVTaskOutput.prototype.taskLink = "";
    
            /**
             * Creates a new INFBVTaskOutput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {infinitusapi.IINFBVTaskOutput=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutput} INFBVTaskOutput instance
             */
            INFBVTaskOutput.create = function create(properties) {
                return new INFBVTaskOutput(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutput message. Does not implicitly {@link infinitusapi.INFBVTaskOutput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {infinitusapi.IINFBVTaskOutput} message INFBVTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accessInfo != null && Object.hasOwnProperty.call(message, "accessInfo"))
                    $root.infinitusapi.INFBVTaskOutputAccessInfo.encode(message.accessInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.additionalNotes);
                if (message.billingNotes != null && Object.hasOwnProperty.call(message, "billingNotes"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.billingNotes);
                if (message.chcControlNumber != null && Object.hasOwnProperty.call(message, "chcControlNumber"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.chcControlNumber);
                if (message.cptCodeBenefits != null && message.cptCodeBenefits.length)
                    for (var i = 0; i < message.cptCodeBenefits.length; ++i)
                        $root.infinitusapi.INFBVTaskOutputCPTCodeBenefits.encode(message.cptCodeBenefits[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.deductiblesAndOops != null && Object.hasOwnProperty.call(message, "deductiblesAndOops"))
                    $root.infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.encode(message.deductiblesAndOops, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.denialInfo != null && Object.hasOwnProperty.call(message, "denialInfo"))
                    $root.infinitusapi.INFPriorAuthDenialInfo.encode(message.denialInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.errorInfo != null && Object.hasOwnProperty.call(message, "errorInfo"))
                    $root.infinitusapi.INFBVTaskOutputErrorInfo.encode(message.errorInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.homeHealthBenefits != null && Object.hasOwnProperty.call(message, "homeHealthBenefits"))
                    $root.infinitusapi.INFBVTaskOutputHomeHealthBenefits.encode(message.homeHealthBenefits, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.infinitusPayerId != null && Object.hasOwnProperty.call(message, "infinitusPayerId"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.infinitusPayerId);
                if (message.officeBenefits != null && Object.hasOwnProperty.call(message, "officeBenefits"))
                    $root.infinitusapi.INFBVTaskOutputOfficeBenefits.encode(message.officeBenefits, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.payerAgentName != null && Object.hasOwnProperty.call(message, "payerAgentName"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.payerAgentName);
                if (message.payerName != null && Object.hasOwnProperty.call(message, "payerName"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.payerName);
                if (message.payerPhone != null && Object.hasOwnProperty.call(message, "payerPhone"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.payerPhone);
                if (message.planInfo != null && Object.hasOwnProperty.call(message, "planInfo"))
                    $root.infinitusapi.INFBVTaskOutputPlanInfo.encode(message.planInfo, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.productCodeBenefits != null && message.productCodeBenefits.length)
                    for (var i = 0; i < message.productCodeBenefits.length; ++i)
                        $root.infinitusapi.INFBVTaskOutputProductCodeBenefits.encode(message.productCodeBenefits[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.reasonForNonCoverage != null && Object.hasOwnProperty.call(message, "reasonForNonCoverage"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int32(message.reasonForNonCoverage);
                if (message.referenceNumber != null && Object.hasOwnProperty.call(message, "referenceNumber"))
                    writer.uint32(/* id 18, wireType 2 =*/146).string(message.referenceNumber);
                if (message.reviewRequired != null && Object.hasOwnProperty.call(message, "reviewRequired"))
                    $root.google.protobuf.BoolValue.encode(message.reviewRequired, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.taskLink != null && Object.hasOwnProperty.call(message, "taskLink"))
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.taskLink);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutput message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {infinitusapi.IINFBVTaskOutput} message INFBVTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutput} INFBVTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accessInfo = $root.infinitusapi.INFBVTaskOutputAccessInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 3: {
                            message.billingNotes = reader.string();
                            break;
                        }
                    case 4: {
                            message.chcControlNumber = reader.string();
                            break;
                        }
                    case 5: {
                            if (!(message.cptCodeBenefits && message.cptCodeBenefits.length))
                                message.cptCodeBenefits = [];
                            message.cptCodeBenefits.push($root.infinitusapi.INFBVTaskOutputCPTCodeBenefits.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            message.deductiblesAndOops = $root.infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.denialInfo = $root.infinitusapi.INFPriorAuthDenialInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.errorInfo = $root.infinitusapi.INFBVTaskOutputErrorInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.homeHealthBenefits = $root.infinitusapi.INFBVTaskOutputHomeHealthBenefits.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.infinitusPayerId = reader.string();
                            break;
                        }
                    case 11: {
                            message.officeBenefits = $root.infinitusapi.INFBVTaskOutputOfficeBenefits.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.payerAgentName = reader.string();
                            break;
                        }
                    case 13: {
                            message.payerName = reader.string();
                            break;
                        }
                    case 14: {
                            message.payerPhone = reader.string();
                            break;
                        }
                    case 15: {
                            message.planInfo = $root.infinitusapi.INFBVTaskOutputPlanInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            if (!(message.productCodeBenefits && message.productCodeBenefits.length))
                                message.productCodeBenefits = [];
                            message.productCodeBenefits.push($root.infinitusapi.INFBVTaskOutputProductCodeBenefits.decode(reader, reader.uint32()));
                            break;
                        }
                    case 17: {
                            message.reasonForNonCoverage = reader.int32();
                            break;
                        }
                    case 18: {
                            message.referenceNumber = reader.string();
                            break;
                        }
                    case 19: {
                            message.reviewRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 20: {
                            message.taskLink = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutput} INFBVTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutput message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accessInfo != null && message.hasOwnProperty("accessInfo")) {
                    var error = $root.infinitusapi.INFBVTaskOutputAccessInfo.verify(message.accessInfo);
                    if (error)
                        return "accessInfo." + error;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.billingNotes != null && message.hasOwnProperty("billingNotes"))
                    if (!$util.isString(message.billingNotes))
                        return "billingNotes: string expected";
                if (message.chcControlNumber != null && message.hasOwnProperty("chcControlNumber"))
                    if (!$util.isString(message.chcControlNumber))
                        return "chcControlNumber: string expected";
                if (message.cptCodeBenefits != null && message.hasOwnProperty("cptCodeBenefits")) {
                    if (!Array.isArray(message.cptCodeBenefits))
                        return "cptCodeBenefits: array expected";
                    for (var i = 0; i < message.cptCodeBenefits.length; ++i) {
                        var error = $root.infinitusapi.INFBVTaskOutputCPTCodeBenefits.verify(message.cptCodeBenefits[i]);
                        if (error)
                            return "cptCodeBenefits." + error;
                    }
                }
                if (message.deductiblesAndOops != null && message.hasOwnProperty("deductiblesAndOops")) {
                    var error = $root.infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.verify(message.deductiblesAndOops);
                    if (error)
                        return "deductiblesAndOops." + error;
                }
                if (message.denialInfo != null && message.hasOwnProperty("denialInfo")) {
                    var error = $root.infinitusapi.INFPriorAuthDenialInfo.verify(message.denialInfo);
                    if (error)
                        return "denialInfo." + error;
                }
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo")) {
                    var error = $root.infinitusapi.INFBVTaskOutputErrorInfo.verify(message.errorInfo);
                    if (error)
                        return "errorInfo." + error;
                }
                if (message.homeHealthBenefits != null && message.hasOwnProperty("homeHealthBenefits")) {
                    var error = $root.infinitusapi.INFBVTaskOutputHomeHealthBenefits.verify(message.homeHealthBenefits);
                    if (error)
                        return "homeHealthBenefits." + error;
                }
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    if (!$util.isString(message.infinitusPayerId))
                        return "infinitusPayerId: string expected";
                if (message.officeBenefits != null && message.hasOwnProperty("officeBenefits")) {
                    var error = $root.infinitusapi.INFBVTaskOutputOfficeBenefits.verify(message.officeBenefits);
                    if (error)
                        return "officeBenefits." + error;
                }
                if (message.payerAgentName != null && message.hasOwnProperty("payerAgentName"))
                    if (!$util.isString(message.payerAgentName))
                        return "payerAgentName: string expected";
                if (message.payerName != null && message.hasOwnProperty("payerName"))
                    if (!$util.isString(message.payerName))
                        return "payerName: string expected";
                if (message.payerPhone != null && message.hasOwnProperty("payerPhone"))
                    if (!$util.isString(message.payerPhone))
                        return "payerPhone: string expected";
                if (message.planInfo != null && message.hasOwnProperty("planInfo")) {
                    var error = $root.infinitusapi.INFBVTaskOutputPlanInfo.verify(message.planInfo);
                    if (error)
                        return "planInfo." + error;
                }
                if (message.productCodeBenefits != null && message.hasOwnProperty("productCodeBenefits")) {
                    if (!Array.isArray(message.productCodeBenefits))
                        return "productCodeBenefits: array expected";
                    for (var i = 0; i < message.productCodeBenefits.length; ++i) {
                        var error = $root.infinitusapi.INFBVTaskOutputProductCodeBenefits.verify(message.productCodeBenefits[i]);
                        if (error)
                            return "productCodeBenefits." + error;
                    }
                }
                if (message.reasonForNonCoverage != null && message.hasOwnProperty("reasonForNonCoverage"))
                    switch (message.reasonForNonCoverage) {
                    default:
                        return "reasonForNonCoverage: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                        break;
                    }
                if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                    if (!$util.isString(message.referenceNumber))
                        return "referenceNumber: string expected";
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.reviewRequired);
                    if (error)
                        return "reviewRequired." + error;
                }
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    if (!$util.isString(message.taskLink))
                        return "taskLink: string expected";
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutput} INFBVTaskOutput
             */
            INFBVTaskOutput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutput)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutput();
                if (object.accessInfo != null) {
                    if (typeof object.accessInfo !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutput.accessInfo: object expected");
                    message.accessInfo = $root.infinitusapi.INFBVTaskOutputAccessInfo.fromObject(object.accessInfo);
                }
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.billingNotes != null)
                    message.billingNotes = String(object.billingNotes);
                if (object.chcControlNumber != null)
                    message.chcControlNumber = String(object.chcControlNumber);
                if (object.cptCodeBenefits) {
                    if (!Array.isArray(object.cptCodeBenefits))
                        throw TypeError(".infinitusapi.INFBVTaskOutput.cptCodeBenefits: array expected");
                    message.cptCodeBenefits = [];
                    for (var i = 0; i < object.cptCodeBenefits.length; ++i) {
                        if (typeof object.cptCodeBenefits[i] !== "object")
                            throw TypeError(".infinitusapi.INFBVTaskOutput.cptCodeBenefits: object expected");
                        message.cptCodeBenefits[i] = $root.infinitusapi.INFBVTaskOutputCPTCodeBenefits.fromObject(object.cptCodeBenefits[i]);
                    }
                }
                if (object.deductiblesAndOops != null) {
                    if (typeof object.deductiblesAndOops !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutput.deductiblesAndOops: object expected");
                    message.deductiblesAndOops = $root.infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.fromObject(object.deductiblesAndOops);
                }
                if (object.denialInfo != null) {
                    if (typeof object.denialInfo !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutput.denialInfo: object expected");
                    message.denialInfo = $root.infinitusapi.INFPriorAuthDenialInfo.fromObject(object.denialInfo);
                }
                if (object.errorInfo != null) {
                    if (typeof object.errorInfo !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutput.errorInfo: object expected");
                    message.errorInfo = $root.infinitusapi.INFBVTaskOutputErrorInfo.fromObject(object.errorInfo);
                }
                if (object.homeHealthBenefits != null) {
                    if (typeof object.homeHealthBenefits !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutput.homeHealthBenefits: object expected");
                    message.homeHealthBenefits = $root.infinitusapi.INFBVTaskOutputHomeHealthBenefits.fromObject(object.homeHealthBenefits);
                }
                if (object.infinitusPayerId != null)
                    message.infinitusPayerId = String(object.infinitusPayerId);
                if (object.officeBenefits != null) {
                    if (typeof object.officeBenefits !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutput.officeBenefits: object expected");
                    message.officeBenefits = $root.infinitusapi.INFBVTaskOutputOfficeBenefits.fromObject(object.officeBenefits);
                }
                if (object.payerAgentName != null)
                    message.payerAgentName = String(object.payerAgentName);
                if (object.payerName != null)
                    message.payerName = String(object.payerName);
                if (object.payerPhone != null)
                    message.payerPhone = String(object.payerPhone);
                if (object.planInfo != null) {
                    if (typeof object.planInfo !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutput.planInfo: object expected");
                    message.planInfo = $root.infinitusapi.INFBVTaskOutputPlanInfo.fromObject(object.planInfo);
                }
                if (object.productCodeBenefits) {
                    if (!Array.isArray(object.productCodeBenefits))
                        throw TypeError(".infinitusapi.INFBVTaskOutput.productCodeBenefits: array expected");
                    message.productCodeBenefits = [];
                    for (var i = 0; i < object.productCodeBenefits.length; ++i) {
                        if (typeof object.productCodeBenefits[i] !== "object")
                            throw TypeError(".infinitusapi.INFBVTaskOutput.productCodeBenefits: object expected");
                        message.productCodeBenefits[i] = $root.infinitusapi.INFBVTaskOutputProductCodeBenefits.fromObject(object.productCodeBenefits[i]);
                    }
                }
                switch (object.reasonForNonCoverage) {
                default:
                    if (typeof object.reasonForNonCoverage === "number") {
                        message.reasonForNonCoverage = object.reasonForNonCoverage;
                        break;
                    }
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_UNKNOWN":
                case 0:
                    message.reasonForNonCoverage = 0;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NOT_APPLICABLE":
                case 1:
                    message.reasonForNonCoverage = 1;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM":
                case 2:
                    message.reasonForNonCoverage = 2;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS":
                case 3:
                    message.reasonForNonCoverage = 3;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B":
                case 4:
                    message.reasonForNonCoverage = 4;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_CAP_MET":
                case 5:
                    message.reasonForNonCoverage = 5;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS":
                case 6:
                    message.reasonForNonCoverage = 6;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG":
                case 7:
                    message.reasonForNonCoverage = 7;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT":
                case 8:
                    message.reasonForNonCoverage = 8;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG":
                case 9:
                    message.reasonForNonCoverage = 9;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL":
                case 10:
                    message.reasonForNonCoverage = 10;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY":
                case 11:
                    message.reasonForNonCoverage = 11;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION":
                case 12:
                    message.reasonForNonCoverage = 12;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY":
                case 13:
                    message.reasonForNonCoverage = 13;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED":
                case 14:
                    message.reasonForNonCoverage = 14;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED":
                case 15:
                    message.reasonForNonCoverage = 15;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK":
                case 16:
                    message.reasonForNonCoverage = 16;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_OTHER":
                case 17:
                    message.reasonForNonCoverage = 17;
                    break;
                }
                if (object.referenceNumber != null)
                    message.referenceNumber = String(object.referenceNumber);
                if (object.reviewRequired != null) {
                    if (typeof object.reviewRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutput.reviewRequired: object expected");
                    message.reviewRequired = $root.google.protobuf.BoolValue.fromObject(object.reviewRequired);
                }
                if (object.taskLink != null)
                    message.taskLink = String(object.taskLink);
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {infinitusapi.INFBVTaskOutput} message INFBVTaskOutput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.cptCodeBenefits = [];
                    object.productCodeBenefits = [];
                }
                if (options.defaults) {
                    object.accessInfo = null;
                    object.additionalNotes = "";
                    object.billingNotes = "";
                    object.chcControlNumber = "";
                    object.deductiblesAndOops = null;
                    object.denialInfo = null;
                    object.errorInfo = null;
                    object.homeHealthBenefits = null;
                    object.infinitusPayerId = "";
                    object.officeBenefits = null;
                    object.payerAgentName = "";
                    object.payerName = "";
                    object.payerPhone = "";
                    object.planInfo = null;
                    object.reasonForNonCoverage = options.enums === String ? "INF_REASON_FOR_NON_COVERAGE_UNKNOWN" : 0;
                    object.referenceNumber = "";
                    object.reviewRequired = null;
                    object.taskLink = "";
                }
                if (message.accessInfo != null && message.hasOwnProperty("accessInfo"))
                    object.accessInfo = $root.infinitusapi.INFBVTaskOutputAccessInfo.toObject(message.accessInfo, options);
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.billingNotes != null && message.hasOwnProperty("billingNotes"))
                    object.billingNotes = message.billingNotes;
                if (message.chcControlNumber != null && message.hasOwnProperty("chcControlNumber"))
                    object.chcControlNumber = message.chcControlNumber;
                if (message.cptCodeBenefits && message.cptCodeBenefits.length) {
                    object.cptCodeBenefits = [];
                    for (var j = 0; j < message.cptCodeBenefits.length; ++j)
                        object.cptCodeBenefits[j] = $root.infinitusapi.INFBVTaskOutputCPTCodeBenefits.toObject(message.cptCodeBenefits[j], options);
                }
                if (message.deductiblesAndOops != null && message.hasOwnProperty("deductiblesAndOops"))
                    object.deductiblesAndOops = $root.infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.toObject(message.deductiblesAndOops, options);
                if (message.denialInfo != null && message.hasOwnProperty("denialInfo"))
                    object.denialInfo = $root.infinitusapi.INFPriorAuthDenialInfo.toObject(message.denialInfo, options);
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo"))
                    object.errorInfo = $root.infinitusapi.INFBVTaskOutputErrorInfo.toObject(message.errorInfo, options);
                if (message.homeHealthBenefits != null && message.hasOwnProperty("homeHealthBenefits"))
                    object.homeHealthBenefits = $root.infinitusapi.INFBVTaskOutputHomeHealthBenefits.toObject(message.homeHealthBenefits, options);
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    object.infinitusPayerId = message.infinitusPayerId;
                if (message.officeBenefits != null && message.hasOwnProperty("officeBenefits"))
                    object.officeBenefits = $root.infinitusapi.INFBVTaskOutputOfficeBenefits.toObject(message.officeBenefits, options);
                if (message.payerAgentName != null && message.hasOwnProperty("payerAgentName"))
                    object.payerAgentName = message.payerAgentName;
                if (message.payerName != null && message.hasOwnProperty("payerName"))
                    object.payerName = message.payerName;
                if (message.payerPhone != null && message.hasOwnProperty("payerPhone"))
                    object.payerPhone = message.payerPhone;
                if (message.planInfo != null && message.hasOwnProperty("planInfo"))
                    object.planInfo = $root.infinitusapi.INFBVTaskOutputPlanInfo.toObject(message.planInfo, options);
                if (message.productCodeBenefits && message.productCodeBenefits.length) {
                    object.productCodeBenefits = [];
                    for (var j = 0; j < message.productCodeBenefits.length; ++j)
                        object.productCodeBenefits[j] = $root.infinitusapi.INFBVTaskOutputProductCodeBenefits.toObject(message.productCodeBenefits[j], options);
                }
                if (message.reasonForNonCoverage != null && message.hasOwnProperty("reasonForNonCoverage"))
                    object.reasonForNonCoverage = options.enums === String ? $root.infinitusapi.INFReasonForNonCoverage[message.reasonForNonCoverage] === undefined ? message.reasonForNonCoverage : $root.infinitusapi.INFReasonForNonCoverage[message.reasonForNonCoverage] : message.reasonForNonCoverage;
                if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                    object.referenceNumber = message.referenceNumber;
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired"))
                    object.reviewRequired = $root.google.protobuf.BoolValue.toObject(message.reviewRequired, options);
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    object.taskLink = message.taskLink;
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutput
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutput";
            };
    
            return INFBVTaskOutput;
        })();
    
        infinitusapi.INFBVTaskOutputAccessInfo = (function() {
    
            /**
             * Properties of a INFBVTaskOutputAccessInfo.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputAccessInfo
             * @property {string|null} [additionalNotes] INFBVTaskOutputAccessInfo additionalNotes
             * @property {string|null} [attestationProcess] INFBVTaskOutputAccessInfo attestationProcess
             * @property {google.protobuf.IBoolValue|null} [attestationProcessExists] INFBVTaskOutputAccessInfo attestationProcessExists
             * @property {infinitusapi.INFBuyAndBillAvailability|null} [buyAndBillAvailability] INFBVTaskOutputAccessInfo buyAndBillAvailability
             * @property {infinitusapi.IINFAddress|null} [claimsAddress] INFBVTaskOutputAccessInfo claimsAddress
             * @property {google.protobuf.IBoolValue|null} [coverageExceptionProcessExists] INFBVTaskOutputAccessInfo coverageExceptionProcessExists
             * @property {string|null} [coverageExceptionTurnaroundTime] INFBVTaskOutputAccessInfo coverageExceptionTurnaroundTime
             * @property {string|null} [coverageRestrictions] INFBVTaskOutputAccessInfo coverageRestrictions
             * @property {string|null} [cptObtainPreDeterminationOrg] INFBVTaskOutputAccessInfo cptObtainPreDeterminationOrg
             * @property {string|null} [cptObtainPredeterminationFax] INFBVTaskOutputAccessInfo cptObtainPredeterminationFax
             * @property {string|null} [cptObtainPredeterminationPhone] INFBVTaskOutputAccessInfo cptObtainPredeterminationPhone
             * @property {string|null} [cptObtainPredeterminationWebsite] INFBVTaskOutputAccessInfo cptObtainPredeterminationWebsite
             * @property {infinitusapi.IINFAddress|null} [cptObtainPriorAuthAddress] INFBVTaskOutputAccessInfo cptObtainPriorAuthAddress
             * @property {string|null} [cptObtainPriorAuthFax] INFBVTaskOutputAccessInfo cptObtainPriorAuthFax
             * @property {string|null} [cptObtainPriorAuthFormLink] INFBVTaskOutputAccessInfo cptObtainPriorAuthFormLink
             * @property {string|null} [cptObtainPriorAuthOrg] INFBVTaskOutputAccessInfo cptObtainPriorAuthOrg
             * @property {string|null} [cptObtainPriorAuthPhone] INFBVTaskOutputAccessInfo cptObtainPriorAuthPhone
             * @property {Array.<infinitusapi.INFPriorAuthRequirementType>|null} [cptObtainPriorAuthRequirements] INFBVTaskOutputAccessInfo cptObtainPriorAuthRequirements
             * @property {string|null} [cptObtainPriorAuthWebsite] INFBVTaskOutputAccessInfo cptObtainPriorAuthWebsite
             * @property {string|null} [cptPredeterminationApprovedQuantity] INFBVTaskOutputAccessInfo cptPredeterminationApprovedQuantity
             * @property {string|null} [cptPredeterminationApprovedQuantityUsed] INFBVTaskOutputAccessInfo cptPredeterminationApprovedQuantityUsed
             * @property {string|null} [cptPredeterminationEndDate] INFBVTaskOutputAccessInfo cptPredeterminationEndDate
             * @property {string|null} [cptPredeterminationNumber] INFBVTaskOutputAccessInfo cptPredeterminationNumber
             * @property {string|null} [cptPredeterminationStartDate] INFBVTaskOutputAccessInfo cptPredeterminationStartDate
             * @property {string|null} [cptPredeterminationTurnaroundTime] INFBVTaskOutputAccessInfo cptPredeterminationTurnaroundTime
             * @property {string|null} [cptPriorAuthApprovalNumber] INFBVTaskOutputAccessInfo cptPriorAuthApprovalNumber
             * @property {string|null} [cptPriorAuthApprovedQuantity] INFBVTaskOutputAccessInfo cptPriorAuthApprovedQuantity
             * @property {string|null} [cptPriorAuthApprovedQuantityUsed] INFBVTaskOutputAccessInfo cptPriorAuthApprovedQuantityUsed
             * @property {string|null} [cptPriorAuthApprovedTreatments] INFBVTaskOutputAccessInfo cptPriorAuthApprovedTreatments
             * @property {string|null} [cptPriorAuthApprovedTreatmentsUsed] INFBVTaskOutputAccessInfo cptPriorAuthApprovedTreatmentsUsed
             * @property {string|null} [cptPriorAuthEndDate] INFBVTaskOutputAccessInfo cptPriorAuthEndDate
             * @property {infinitusapi.INFPriorAuthNotificationMethod|null} [cptPriorAuthNotificationMethod] INFBVTaskOutputAccessInfo cptPriorAuthNotificationMethod
             * @property {Array.<infinitusapi.INFPriorAuthNotificationMethod>|null} [cptPriorAuthNotificationMethods] INFBVTaskOutputAccessInfo cptPriorAuthNotificationMethods
             * @property {google.protobuf.IBoolValue|null} [cptPriorAuthProcessDifferent] INFBVTaskOutputAccessInfo cptPriorAuthProcessDifferent
             * @property {string|null} [cptPriorAuthStartDate] INFBVTaskOutputAccessInfo cptPriorAuthStartDate
             * @property {infinitusapi.INFPriorAuthSubmissionMethod|null} [cptPriorAuthSubmissionMethod] INFBVTaskOutputAccessInfo cptPriorAuthSubmissionMethod
             * @property {Array.<infinitusapi.INFPriorAuthSubmissionMethod>|null} [cptPriorAuthSubmissionMethods] INFBVTaskOutputAccessInfo cptPriorAuthSubmissionMethods
             * @property {string|null} [cptPriorAuthTurnaroundTime] INFBVTaskOutputAccessInfo cptPriorAuthTurnaroundTime
             * @property {google.protobuf.IBoolValue|null} [hasSpecialtyPharmacyExclusions] INFBVTaskOutputAccessInfo hasSpecialtyPharmacyExclusions
             * @property {google.protobuf.IBoolValue|null} [homeHealthCovered] INFBVTaskOutputAccessInfo homeHealthCovered
             * @property {string|null} [homeHealthObtainPriorAuthFax] INFBVTaskOutputAccessInfo homeHealthObtainPriorAuthFax
             * @property {string|null} [homeHealthObtainPriorAuthPhone] INFBVTaskOutputAccessInfo homeHealthObtainPriorAuthPhone
             * @property {google.protobuf.IBoolValue|null} [homeHealthPriorAuthRequired] INFBVTaskOutputAccessInfo homeHealthPriorAuthRequired
             * @property {string|null} [homeHealthProviderName] INFBVTaskOutputAccessInfo homeHealthProviderName
             * @property {string|null} [homeHealthProviderPhone] INFBVTaskOutputAccessInfo homeHealthProviderPhone
             * @property {string|null} [homeHealthServices] INFBVTaskOutputAccessInfo homeHealthServices
             * @property {google.protobuf.IBoolValue|null} [homeboundRequired] INFBVTaskOutputAccessInfo homeboundRequired
             * @property {google.protobuf.IBoolValue|null} [lodgingCovered] INFBVTaskOutputAccessInfo lodgingCovered
             * @property {string|null} [lodgingServicePhoneNumber] INFBVTaskOutputAccessInfo lodgingServicePhoneNumber
             * @property {string|null} [medicalGroupName] INFBVTaskOutputAccessInfo medicalGroupName
             * @property {string|null} [medicalGroupPhoneNumber] INFBVTaskOutputAccessInfo medicalGroupPhoneNumber
             * @property {google.protobuf.IBoolValue|null} [medicalPolicyAvailableOnWebsite] INFBVTaskOutputAccessInfo medicalPolicyAvailableOnWebsite
             * @property {string|null} [medicalPolicyNumber] INFBVTaskOutputAccessInfo medicalPolicyNumber
             * @property {infinitusapi.IINFAddress|null} [obtainCoverageExceptionAddress] INFBVTaskOutputAccessInfo obtainCoverageExceptionAddress
             * @property {string|null} [obtainCoverageExceptionFax] INFBVTaskOutputAccessInfo obtainCoverageExceptionFax
             * @property {string|null} [obtainCoverageExceptionPhone] INFBVTaskOutputAccessInfo obtainCoverageExceptionPhone
             * @property {string|null} [obtainCoverageExceptionProcess] INFBVTaskOutputAccessInfo obtainCoverageExceptionProcess
             * @property {string|null} [obtainCoverageExceptionWebsite] INFBVTaskOutputAccessInfo obtainCoverageExceptionWebsite
             * @property {string|null} [obtainPreDeterminationOrg] INFBVTaskOutputAccessInfo obtainPreDeterminationOrg
             * @property {string|null} [obtainPredeterminationFax] INFBVTaskOutputAccessInfo obtainPredeterminationFax
             * @property {string|null} [obtainPredeterminationPhone] INFBVTaskOutputAccessInfo obtainPredeterminationPhone
             * @property {string|null} [obtainPredeterminationWebsite] INFBVTaskOutputAccessInfo obtainPredeterminationWebsite
             * @property {infinitusapi.IINFAddress|null} [obtainPriorAuthAddress] INFBVTaskOutputAccessInfo obtainPriorAuthAddress
             * @property {string|null} [obtainPriorAuthFax] INFBVTaskOutputAccessInfo obtainPriorAuthFax
             * @property {string|null} [obtainPriorAuthFormLink] INFBVTaskOutputAccessInfo obtainPriorAuthFormLink
             * @property {string|null} [obtainPriorAuthOrg] INFBVTaskOutputAccessInfo obtainPriorAuthOrg
             * @property {string|null} [obtainPriorAuthPhone] INFBVTaskOutputAccessInfo obtainPriorAuthPhone
             * @property {Array.<infinitusapi.INFPriorAuthRequirementType>|null} [obtainPriorAuthRequirements] INFBVTaskOutputAccessInfo obtainPriorAuthRequirements
             * @property {string|null} [obtainPriorAuthWebsite] INFBVTaskOutputAccessInfo obtainPriorAuthWebsite
             * @property {string|null} [obtainQuantityLimitExceptionPhone] INFBVTaskOutputAccessInfo obtainQuantityLimitExceptionPhone
             * @property {string|null} [obtainQuantityLimitExceptionProcess] INFBVTaskOutputAccessInfo obtainQuantityLimitExceptionProcess
             * @property {string|null} [pcpName] INFBVTaskOutputAccessInfo pcpName
             * @property {string|null} [pcpPhone] INFBVTaskOutputAccessInfo pcpPhone
             * @property {google.protobuf.IBoolValue|null} [preDeterminationAvailable] INFBVTaskOutputAccessInfo preDeterminationAvailable
             * @property {string|null} [preDeterminationNumber] INFBVTaskOutputAccessInfo preDeterminationNumber
             * @property {google.protobuf.IBoolValue|null} [preDeterminationOnFile] INFBVTaskOutputAccessInfo preDeterminationOnFile
             * @property {google.protobuf.IBoolValue|null} [preDeterminationRequired] INFBVTaskOutputAccessInfo preDeterminationRequired
             * @property {infinitusapi.INFPredeterminationRequirementType|null} [preDeterminationRequirement] INFBVTaskOutputAccessInfo preDeterminationRequirement
             * @property {infinitusapi.IINFAddress|null} [predeterminationAddressOnFile] INFBVTaskOutputAccessInfo predeterminationAddressOnFile
             * @property {string|null} [predeterminationApprovedQuantity] INFBVTaskOutputAccessInfo predeterminationApprovedQuantity
             * @property {string|null} [predeterminationApprovedQuantityUsed] INFBVTaskOutputAccessInfo predeterminationApprovedQuantityUsed
             * @property {string|null} [predeterminationCompletionDate] INFBVTaskOutputAccessInfo predeterminationCompletionDate
             * @property {string|null} [predeterminationEndDate] INFBVTaskOutputAccessInfo predeterminationEndDate
             * @property {google.protobuf.IBoolValue|null} [predeterminationOnFileApplies] INFBVTaskOutputAccessInfo predeterminationOnFileApplies
             * @property {string|null} [predeterminationProviderNameOnFile] INFBVTaskOutputAccessInfo predeterminationProviderNameOnFile
             * @property {string|null} [predeterminationReceivedDate] INFBVTaskOutputAccessInfo predeterminationReceivedDate
             * @property {google.protobuf.IBoolValue|null} [predeterminationRenewalProcessExists] INFBVTaskOutputAccessInfo predeterminationRenewalProcessExists
             * @property {string|null} [predeterminationStartDate] INFBVTaskOutputAccessInfo predeterminationStartDate
             * @property {infinitusapi.INFPredeterminationStatus|null} [predeterminationStatus] INFBVTaskOutputAccessInfo predeterminationStatus
             * @property {string|null} [predeterminationSubmissionDate] INFBVTaskOutputAccessInfo predeterminationSubmissionDate
             * @property {string|null} [predeterminationTurnaroundTime] INFBVTaskOutputAccessInfo predeterminationTurnaroundTime
             * @property {Array.<infinitusapi.IINFBVTaskOutputPreferredSpecialtyPharmacy>|null} [preferredSpecialtyPharmaciesAvailable] INFBVTaskOutputAccessInfo preferredSpecialtyPharmaciesAvailable
             * @property {infinitusapi.IINFAddress|null} [priorAuthAddressOnFile] INFBVTaskOutputAccessInfo priorAuthAddressOnFile
             * @property {string|null} [priorAuthApprovalDate] INFBVTaskOutputAccessInfo priorAuthApprovalDate
             * @property {string|null} [priorAuthApprovalNumber] INFBVTaskOutputAccessInfo priorAuthApprovalNumber
             * @property {string|null} [priorAuthApprovedQuantity] INFBVTaskOutputAccessInfo priorAuthApprovedQuantity
             * @property {string|null} [priorAuthApprovedQuantityUsed] INFBVTaskOutputAccessInfo priorAuthApprovedQuantityUsed
             * @property {string|null} [priorAuthApprovedTreatments] INFBVTaskOutputAccessInfo priorAuthApprovedTreatments
             * @property {string|null} [priorAuthApprovedTreatmentsUsed] INFBVTaskOutputAccessInfo priorAuthApprovedTreatmentsUsed
             * @property {Array.<string>|null} [priorAuthCodesOnFile] INFBVTaskOutputAccessInfo priorAuthCodesOnFile
             * @property {string|null} [priorAuthCompletionDate] INFBVTaskOutputAccessInfo priorAuthCompletionDate
             * @property {string|null} [priorAuthEndDate] INFBVTaskOutputAccessInfo priorAuthEndDate
             * @property {string|null} [priorAuthExceptionReason] INFBVTaskOutputAccessInfo priorAuthExceptionReason
             * @property {string|null} [priorAuthInitiationDate] INFBVTaskOutputAccessInfo priorAuthInitiationDate
             * @property {infinitusapi.INFPriorAuthNotificationMethod|null} [priorAuthNotificationMethod] INFBVTaskOutputAccessInfo priorAuthNotificationMethod
             * @property {Array.<infinitusapi.INFPriorAuthNotificationMethod>|null} [priorAuthNotificationMethods] INFBVTaskOutputAccessInfo priorAuthNotificationMethods
             * @property {google.protobuf.IBoolValue|null} [priorAuthOnFile] INFBVTaskOutputAccessInfo priorAuthOnFile
             * @property {google.protobuf.IBoolValue|null} [priorAuthOnFileApplies] INFBVTaskOutputAccessInfo priorAuthOnFileApplies
             * @property {string|null} [priorAuthProviderNameOnFile] INFBVTaskOutputAccessInfo priorAuthProviderNameOnFile
             * @property {google.protobuf.IBoolValue|null} [priorAuthRenewalProcessExists] INFBVTaskOutputAccessInfo priorAuthRenewalProcessExists
             * @property {google.protobuf.IBoolValue|null} [priorAuthRequired] INFBVTaskOutputAccessInfo priorAuthRequired
             * @property {Array.<string>|null} [priorAuthRequiredCodes] INFBVTaskOutputAccessInfo priorAuthRequiredCodes
             * @property {infinitusapi.INFPriorAuthResponsibleOrg|null} [priorAuthResponsibleOrg] INFBVTaskOutputAccessInfo priorAuthResponsibleOrg
             * @property {string|null} [priorAuthStartDate] INFBVTaskOutputAccessInfo priorAuthStartDate
             * @property {infinitusapi.INFPriorAuthStatus|null} [priorAuthStatus] INFBVTaskOutputAccessInfo priorAuthStatus
             * @property {string|null} [priorAuthSubmissionDate] INFBVTaskOutputAccessInfo priorAuthSubmissionDate
             * @property {infinitusapi.INFPriorAuthSubmissionMethod|null} [priorAuthSubmissionMethod] INFBVTaskOutputAccessInfo priorAuthSubmissionMethod
             * @property {Array.<infinitusapi.INFPriorAuthSubmissionMethod>|null} [priorAuthSubmissionMethods] INFBVTaskOutputAccessInfo priorAuthSubmissionMethods
             * @property {string|null} [priorAuthTurnaroundTime] INFBVTaskOutputAccessInfo priorAuthTurnaroundTime
             * @property {infinitusapi.INFPriorAuthType|null} [priorAuthType] INFBVTaskOutputAccessInfo priorAuthType
             * @property {string|null} [quantityLimit] INFBVTaskOutputAccessInfo quantityLimit
             * @property {infinitusapi.IINFAddress|null} [referralAddress] INFBVTaskOutputAccessInfo referralAddress
             * @property {string|null} [referralEffectiveDate] INFBVTaskOutputAccessInfo referralEffectiveDate
             * @property {string|null} [referralFax] INFBVTaskOutputAccessInfo referralFax
             * @property {google.protobuf.IDoubleValue|null} [referralNumVisitsApproved] INFBVTaskOutputAccessInfo referralNumVisitsApproved
             * @property {google.protobuf.IDoubleValue|null} [referralNumVisitsUsed] INFBVTaskOutputAccessInfo referralNumVisitsUsed
             * @property {string|null} [referralNumber] INFBVTaskOutputAccessInfo referralNumber
             * @property {google.protobuf.IBoolValue|null} [referralOnFile] INFBVTaskOutputAccessInfo referralOnFile
             * @property {string|null} [referralRecertDate] INFBVTaskOutputAccessInfo referralRecertDate
             * @property {google.protobuf.IBoolValue|null} [referralRequired] INFBVTaskOutputAccessInfo referralRequired
             * @property {infinitusapi.InfReferralRequirementsType|null} [referralRequirements] INFBVTaskOutputAccessInfo referralRequirements
             * @property {string|null} [referralSubmissionProcess] INFBVTaskOutputAccessInfo referralSubmissionProcess
             * @property {string|null} [referralWebsite] INFBVTaskOutputAccessInfo referralWebsite
             * @property {infinitusapi.IINFAddress|null} [specialtyPharmacy2Address] INFBVTaskOutputAccessInfo specialtyPharmacy2Address
             * @property {string|null} [specialtyPharmacy2Fax] INFBVTaskOutputAccessInfo specialtyPharmacy2Fax
             * @property {string|null} [specialtyPharmacy2Name] INFBVTaskOutputAccessInfo specialtyPharmacy2Name
             * @property {string|null} [specialtyPharmacy2Phone] INFBVTaskOutputAccessInfo specialtyPharmacy2Phone
             * @property {infinitusapi.IINFAddress|null} [specialtyPharmacy3Address] INFBVTaskOutputAccessInfo specialtyPharmacy3Address
             * @property {string|null} [specialtyPharmacy3Fax] INFBVTaskOutputAccessInfo specialtyPharmacy3Fax
             * @property {string|null} [specialtyPharmacy3Name] INFBVTaskOutputAccessInfo specialtyPharmacy3Name
             * @property {string|null} [specialtyPharmacy3Phone] INFBVTaskOutputAccessInfo specialtyPharmacy3Phone
             * @property {infinitusapi.IINFAddress|null} [specialtyPharmacyAddress] INFBVTaskOutputAccessInfo specialtyPharmacyAddress
             * @property {infinitusapi.INFSpecialtyPharmacyAvailability|null} [specialtyPharmacyAvailability] INFBVTaskOutputAccessInfo specialtyPharmacyAvailability
             * @property {string|null} [specialtyPharmacyExclusions] INFBVTaskOutputAccessInfo specialtyPharmacyExclusions
             * @property {string|null} [specialtyPharmacyFax] INFBVTaskOutputAccessInfo specialtyPharmacyFax
             * @property {string|null} [specialtyPharmacyName] INFBVTaskOutputAccessInfo specialtyPharmacyName
             * @property {infinitusapi.INFSpecialtyPharmacyNetwork|null} [specialtyPharmacyNetwork] INFBVTaskOutputAccessInfo specialtyPharmacyNetwork
             * @property {string|null} [specialtyPharmacyObtainPreDeterminationOrg] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPreDeterminationOrg
             * @property {string|null} [specialtyPharmacyObtainPredeterminationFax] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPredeterminationFax
             * @property {string|null} [specialtyPharmacyObtainPredeterminationPhone] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPredeterminationPhone
             * @property {string|null} [specialtyPharmacyObtainPredeterminationWebsite] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPredeterminationWebsite
             * @property {infinitusapi.IINFAddress|null} [specialtyPharmacyObtainPriorAuthAddress] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthAddress
             * @property {string|null} [specialtyPharmacyObtainPriorAuthFax] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthFax
             * @property {string|null} [specialtyPharmacyObtainPriorAuthFormLink] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthFormLink
             * @property {string|null} [specialtyPharmacyObtainPriorAuthOrg] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthOrg
             * @property {string|null} [specialtyPharmacyObtainPriorAuthPhone] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthPhone
             * @property {Array.<infinitusapi.INFPriorAuthRequirementType>|null} [specialtyPharmacyObtainPriorAuthRequirements] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthRequirements
             * @property {string|null} [specialtyPharmacyObtainPriorAuthWebsite] INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthWebsite
             * @property {string|null} [specialtyPharmacyPhone] INFBVTaskOutputAccessInfo specialtyPharmacyPhone
             * @property {string|null} [specialtyPharmacyPredeterminationApprovedQuantity] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationApprovedQuantity
             * @property {string|null} [specialtyPharmacyPredeterminationApprovedQuantityUsed] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationApprovedQuantityUsed
             * @property {string|null} [specialtyPharmacyPredeterminationEndDate] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationEndDate
             * @property {string|null} [specialtyPharmacyPredeterminationNumber] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationNumber
             * @property {google.protobuf.IBoolValue|null} [specialtyPharmacyPredeterminationOnFile] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationOnFile
             * @property {infinitusapi.INFPredeterminationRequirementType|null} [specialtyPharmacyPredeterminationRequirementType] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationRequirementType
             * @property {string|null} [specialtyPharmacyPredeterminationStartDate] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationStartDate
             * @property {infinitusapi.INFSpecialtyPharmacyPredeterminationStatus|null} [specialtyPharmacyPredeterminationStatus] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationStatus
             * @property {string|null} [specialtyPharmacyPredeterminationTurnaroundTime] INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationTurnaroundTime
             * @property {string|null} [specialtyPharmacyPriorAuthApprovalNumber] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovalNumber
             * @property {string|null} [specialtyPharmacyPriorAuthApprovedQuantity] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovedQuantity
             * @property {string|null} [specialtyPharmacyPriorAuthApprovedQuantityUsed] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovedQuantityUsed
             * @property {string|null} [specialtyPharmacyPriorAuthApprovedTreatments] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovedTreatments
             * @property {string|null} [specialtyPharmacyPriorAuthApprovedTreatmentsUsed] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovedTreatmentsUsed
             * @property {string|null} [specialtyPharmacyPriorAuthEndDate] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthEndDate
             * @property {infinitusapi.INFPriorAuthNotificationMethod|null} [specialtyPharmacyPriorAuthNotificationMethod] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthNotificationMethod
             * @property {Array.<infinitusapi.INFPriorAuthNotificationMethod>|null} [specialtyPharmacyPriorAuthNotificationMethods] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthNotificationMethods
             * @property {google.protobuf.IBoolValue|null} [specialtyPharmacyPriorAuthProcessDifferent] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthProcessDifferent
             * @property {google.protobuf.IBoolValue|null} [specialtyPharmacyPriorAuthRequired] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthRequired
             * @property {string|null} [specialtyPharmacyPriorAuthStartDate] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthStartDate
             * @property {infinitusapi.INFPriorAuthStatus|null} [specialtyPharmacyPriorAuthStatus] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthStatus
             * @property {infinitusapi.INFPriorAuthSubmissionMethod|null} [specialtyPharmacyPriorAuthSubmissionMethod] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthSubmissionMethod
             * @property {Array.<infinitusapi.INFPriorAuthSubmissionMethod>|null} [specialtyPharmacyPriorAuthSubmissionMethods] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthSubmissionMethods
             * @property {string|null} [specialtyPharmacyPriorAuthTurnaroundTime] INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthTurnaroundTime
             * @property {infinitusapi.INFStepTherapyMethod|null} [stepTherapyMethod] INFBVTaskOutputAccessInfo stepTherapyMethod
             * @property {google.protobuf.IBoolValue|null} [stepTherapyRequired] INFBVTaskOutputAccessInfo stepTherapyRequired
             * @property {string|null} [stepTherapyTreatment] INFBVTaskOutputAccessInfo stepTherapyTreatment
             * @property {string|null} [therapyAvailabilityDate] INFBVTaskOutputAccessInfo therapyAvailabilityDate
             * @property {string|null} [therapySeasonStartDate] INFBVTaskOutputAccessInfo therapySeasonStartDate
             * @property {string|null} [timelyFilingPeriod] INFBVTaskOutputAccessInfo timelyFilingPeriod
             * @property {google.protobuf.IBoolValue|null} [transportationServiceCovered] INFBVTaskOutputAccessInfo transportationServiceCovered
             * @property {string|null} [transportationServicePhoneNumber] INFBVTaskOutputAccessInfo transportationServicePhoneNumber
             */
    
            /**
             * Constructs a new INFBVTaskOutputAccessInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputAccessInfo.
             * @implements IINFBVTaskOutputAccessInfo
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputAccessInfo=} [properties] Properties to set
             */
            function INFBVTaskOutputAccessInfo(properties) {
                this.cptObtainPriorAuthRequirements = [];
                this.cptPriorAuthNotificationMethods = [];
                this.cptPriorAuthSubmissionMethods = [];
                this.obtainPriorAuthRequirements = [];
                this.preferredSpecialtyPharmaciesAvailable = [];
                this.priorAuthCodesOnFile = [];
                this.priorAuthNotificationMethods = [];
                this.priorAuthRequiredCodes = [];
                this.priorAuthSubmissionMethods = [];
                this.specialtyPharmacyObtainPriorAuthRequirements = [];
                this.specialtyPharmacyPriorAuthNotificationMethods = [];
                this.specialtyPharmacyPriorAuthSubmissionMethods = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputAccessInfo additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.additionalNotes = "";
    
            /**
             * INFBVTaskOutputAccessInfo attestationProcess.
             * @member {string} attestationProcess
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.attestationProcess = "";
    
            /**
             * INFBVTaskOutputAccessInfo attestationProcessExists.
             * @member {google.protobuf.IBoolValue|null|undefined} attestationProcessExists
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.attestationProcessExists = null;
    
            /**
             * INFBVTaskOutputAccessInfo buyAndBillAvailability.
             * @member {infinitusapi.INFBuyAndBillAvailability} buyAndBillAvailability
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.buyAndBillAvailability = 0;
    
            /**
             * INFBVTaskOutputAccessInfo claimsAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} claimsAddress
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.claimsAddress = null;
    
            /**
             * INFBVTaskOutputAccessInfo coverageExceptionProcessExists.
             * @member {google.protobuf.IBoolValue|null|undefined} coverageExceptionProcessExists
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.coverageExceptionProcessExists = null;
    
            /**
             * INFBVTaskOutputAccessInfo coverageExceptionTurnaroundTime.
             * @member {string} coverageExceptionTurnaroundTime
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.coverageExceptionTurnaroundTime = "";
    
            /**
             * INFBVTaskOutputAccessInfo coverageRestrictions.
             * @member {string} coverageRestrictions
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.coverageRestrictions = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPreDeterminationOrg.
             * @member {string} cptObtainPreDeterminationOrg
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPreDeterminationOrg = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPredeterminationFax.
             * @member {string} cptObtainPredeterminationFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPredeterminationFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPredeterminationPhone.
             * @member {string} cptObtainPredeterminationPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPredeterminationPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPredeterminationWebsite.
             * @member {string} cptObtainPredeterminationWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPredeterminationWebsite = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPriorAuthAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} cptObtainPriorAuthAddress
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPriorAuthAddress = null;
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPriorAuthFax.
             * @member {string} cptObtainPriorAuthFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPriorAuthFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPriorAuthFormLink.
             * @member {string} cptObtainPriorAuthFormLink
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPriorAuthFormLink = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPriorAuthOrg.
             * @member {string} cptObtainPriorAuthOrg
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPriorAuthOrg = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPriorAuthPhone.
             * @member {string} cptObtainPriorAuthPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPriorAuthPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPriorAuthRequirements.
             * @member {Array.<infinitusapi.INFPriorAuthRequirementType>} cptObtainPriorAuthRequirements
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPriorAuthRequirements = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo cptObtainPriorAuthWebsite.
             * @member {string} cptObtainPriorAuthWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptObtainPriorAuthWebsite = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPredeterminationApprovedQuantity.
             * @member {string} cptPredeterminationApprovedQuantity
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPredeterminationApprovedQuantity = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPredeterminationApprovedQuantityUsed.
             * @member {string} cptPredeterminationApprovedQuantityUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPredeterminationApprovedQuantityUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPredeterminationEndDate.
             * @member {string} cptPredeterminationEndDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPredeterminationEndDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPredeterminationNumber.
             * @member {string} cptPredeterminationNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPredeterminationNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPredeterminationStartDate.
             * @member {string} cptPredeterminationStartDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPredeterminationStartDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPredeterminationTurnaroundTime.
             * @member {string} cptPredeterminationTurnaroundTime
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPredeterminationTurnaroundTime = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthApprovalNumber.
             * @member {string} cptPriorAuthApprovalNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthApprovalNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthApprovedQuantity.
             * @member {string} cptPriorAuthApprovedQuantity
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthApprovedQuantity = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthApprovedQuantityUsed.
             * @member {string} cptPriorAuthApprovedQuantityUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthApprovedQuantityUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthApprovedTreatments.
             * @member {string} cptPriorAuthApprovedTreatments
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthApprovedTreatments = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthApprovedTreatmentsUsed.
             * @member {string} cptPriorAuthApprovedTreatmentsUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthApprovedTreatmentsUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthEndDate.
             * @member {string} cptPriorAuthEndDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthEndDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthNotificationMethod.
             * @member {infinitusapi.INFPriorAuthNotificationMethod} cptPriorAuthNotificationMethod
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthNotificationMethod = 0;
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthNotificationMethods.
             * @member {Array.<infinitusapi.INFPriorAuthNotificationMethod>} cptPriorAuthNotificationMethods
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthNotificationMethods = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthProcessDifferent.
             * @member {google.protobuf.IBoolValue|null|undefined} cptPriorAuthProcessDifferent
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthProcessDifferent = null;
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthStartDate.
             * @member {string} cptPriorAuthStartDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthStartDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthSubmissionMethod.
             * @member {infinitusapi.INFPriorAuthSubmissionMethod} cptPriorAuthSubmissionMethod
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthSubmissionMethod = 0;
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthSubmissionMethods.
             * @member {Array.<infinitusapi.INFPriorAuthSubmissionMethod>} cptPriorAuthSubmissionMethods
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthSubmissionMethods = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo cptPriorAuthTurnaroundTime.
             * @member {string} cptPriorAuthTurnaroundTime
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.cptPriorAuthTurnaroundTime = "";
    
            /**
             * INFBVTaskOutputAccessInfo hasSpecialtyPharmacyExclusions.
             * @member {google.protobuf.IBoolValue|null|undefined} hasSpecialtyPharmacyExclusions
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.hasSpecialtyPharmacyExclusions = null;
    
            /**
             * INFBVTaskOutputAccessInfo homeHealthCovered.
             * @member {google.protobuf.IBoolValue|null|undefined} homeHealthCovered
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.homeHealthCovered = null;
    
            /**
             * INFBVTaskOutputAccessInfo homeHealthObtainPriorAuthFax.
             * @member {string} homeHealthObtainPriorAuthFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.homeHealthObtainPriorAuthFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo homeHealthObtainPriorAuthPhone.
             * @member {string} homeHealthObtainPriorAuthPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.homeHealthObtainPriorAuthPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo homeHealthPriorAuthRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} homeHealthPriorAuthRequired
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.homeHealthPriorAuthRequired = null;
    
            /**
             * INFBVTaskOutputAccessInfo homeHealthProviderName.
             * @member {string} homeHealthProviderName
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.homeHealthProviderName = "";
    
            /**
             * INFBVTaskOutputAccessInfo homeHealthProviderPhone.
             * @member {string} homeHealthProviderPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.homeHealthProviderPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo homeHealthServices.
             * @member {string} homeHealthServices
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.homeHealthServices = "";
    
            /**
             * INFBVTaskOutputAccessInfo homeboundRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} homeboundRequired
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.homeboundRequired = null;
    
            /**
             * INFBVTaskOutputAccessInfo lodgingCovered.
             * @member {google.protobuf.IBoolValue|null|undefined} lodgingCovered
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.lodgingCovered = null;
    
            /**
             * INFBVTaskOutputAccessInfo lodgingServicePhoneNumber.
             * @member {string} lodgingServicePhoneNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.lodgingServicePhoneNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo medicalGroupName.
             * @member {string} medicalGroupName
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.medicalGroupName = "";
    
            /**
             * INFBVTaskOutputAccessInfo medicalGroupPhoneNumber.
             * @member {string} medicalGroupPhoneNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.medicalGroupPhoneNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo medicalPolicyAvailableOnWebsite.
             * @member {google.protobuf.IBoolValue|null|undefined} medicalPolicyAvailableOnWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.medicalPolicyAvailableOnWebsite = null;
    
            /**
             * INFBVTaskOutputAccessInfo medicalPolicyNumber.
             * @member {string} medicalPolicyNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.medicalPolicyNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainCoverageExceptionAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} obtainCoverageExceptionAddress
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainCoverageExceptionAddress = null;
    
            /**
             * INFBVTaskOutputAccessInfo obtainCoverageExceptionFax.
             * @member {string} obtainCoverageExceptionFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainCoverageExceptionFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainCoverageExceptionPhone.
             * @member {string} obtainCoverageExceptionPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainCoverageExceptionPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainCoverageExceptionProcess.
             * @member {string} obtainCoverageExceptionProcess
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainCoverageExceptionProcess = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainCoverageExceptionWebsite.
             * @member {string} obtainCoverageExceptionWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainCoverageExceptionWebsite = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPreDeterminationOrg.
             * @member {string} obtainPreDeterminationOrg
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPreDeterminationOrg = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPredeterminationFax.
             * @member {string} obtainPredeterminationFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPredeterminationFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPredeterminationPhone.
             * @member {string} obtainPredeterminationPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPredeterminationPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPredeterminationWebsite.
             * @member {string} obtainPredeterminationWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPredeterminationWebsite = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPriorAuthAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} obtainPriorAuthAddress
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPriorAuthAddress = null;
    
            /**
             * INFBVTaskOutputAccessInfo obtainPriorAuthFax.
             * @member {string} obtainPriorAuthFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPriorAuthFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPriorAuthFormLink.
             * @member {string} obtainPriorAuthFormLink
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPriorAuthFormLink = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPriorAuthOrg.
             * @member {string} obtainPriorAuthOrg
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPriorAuthOrg = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPriorAuthPhone.
             * @member {string} obtainPriorAuthPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPriorAuthPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainPriorAuthRequirements.
             * @member {Array.<infinitusapi.INFPriorAuthRequirementType>} obtainPriorAuthRequirements
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPriorAuthRequirements = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo obtainPriorAuthWebsite.
             * @member {string} obtainPriorAuthWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainPriorAuthWebsite = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainQuantityLimitExceptionPhone.
             * @member {string} obtainQuantityLimitExceptionPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainQuantityLimitExceptionPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo obtainQuantityLimitExceptionProcess.
             * @member {string} obtainQuantityLimitExceptionProcess
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.obtainQuantityLimitExceptionProcess = "";
    
            /**
             * INFBVTaskOutputAccessInfo pcpName.
             * @member {string} pcpName
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.pcpName = "";
    
            /**
             * INFBVTaskOutputAccessInfo pcpPhone.
             * @member {string} pcpPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.pcpPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo preDeterminationAvailable.
             * @member {google.protobuf.IBoolValue|null|undefined} preDeterminationAvailable
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.preDeterminationAvailable = null;
    
            /**
             * INFBVTaskOutputAccessInfo preDeterminationNumber.
             * @member {string} preDeterminationNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.preDeterminationNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo preDeterminationOnFile.
             * @member {google.protobuf.IBoolValue|null|undefined} preDeterminationOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.preDeterminationOnFile = null;
    
            /**
             * INFBVTaskOutputAccessInfo preDeterminationRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} preDeterminationRequired
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.preDeterminationRequired = null;
    
            /**
             * INFBVTaskOutputAccessInfo preDeterminationRequirement.
             * @member {infinitusapi.INFPredeterminationRequirementType} preDeterminationRequirement
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.preDeterminationRequirement = 0;
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationAddressOnFile.
             * @member {infinitusapi.IINFAddress|null|undefined} predeterminationAddressOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationAddressOnFile = null;
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationApprovedQuantity.
             * @member {string} predeterminationApprovedQuantity
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationApprovedQuantity = "";
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationApprovedQuantityUsed.
             * @member {string} predeterminationApprovedQuantityUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationApprovedQuantityUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationCompletionDate.
             * @member {string} predeterminationCompletionDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationCompletionDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationEndDate.
             * @member {string} predeterminationEndDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationEndDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationOnFileApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} predeterminationOnFileApplies
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationOnFileApplies = null;
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationProviderNameOnFile.
             * @member {string} predeterminationProviderNameOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationProviderNameOnFile = "";
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationReceivedDate.
             * @member {string} predeterminationReceivedDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationReceivedDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationRenewalProcessExists.
             * @member {google.protobuf.IBoolValue|null|undefined} predeterminationRenewalProcessExists
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationRenewalProcessExists = null;
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationStartDate.
             * @member {string} predeterminationStartDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationStartDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationStatus.
             * @member {infinitusapi.INFPredeterminationStatus} predeterminationStatus
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationStatus = 0;
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationSubmissionDate.
             * @member {string} predeterminationSubmissionDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationSubmissionDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo predeterminationTurnaroundTime.
             * @member {string} predeterminationTurnaroundTime
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.predeterminationTurnaroundTime = "";
    
            /**
             * INFBVTaskOutputAccessInfo preferredSpecialtyPharmaciesAvailable.
             * @member {Array.<infinitusapi.IINFBVTaskOutputPreferredSpecialtyPharmacy>} preferredSpecialtyPharmaciesAvailable
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.preferredSpecialtyPharmaciesAvailable = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthAddressOnFile.
             * @member {infinitusapi.IINFAddress|null|undefined} priorAuthAddressOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthAddressOnFile = null;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthApprovalDate.
             * @member {string} priorAuthApprovalDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthApprovalDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthApprovalNumber.
             * @member {string} priorAuthApprovalNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthApprovalNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthApprovedQuantity.
             * @member {string} priorAuthApprovedQuantity
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthApprovedQuantity = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthApprovedQuantityUsed.
             * @member {string} priorAuthApprovedQuantityUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthApprovedQuantityUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthApprovedTreatments.
             * @member {string} priorAuthApprovedTreatments
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthApprovedTreatments = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthApprovedTreatmentsUsed.
             * @member {string} priorAuthApprovedTreatmentsUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthApprovedTreatmentsUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthCodesOnFile.
             * @member {Array.<string>} priorAuthCodesOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthCodesOnFile = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthCompletionDate.
             * @member {string} priorAuthCompletionDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthCompletionDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthEndDate.
             * @member {string} priorAuthEndDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthEndDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthExceptionReason.
             * @member {string} priorAuthExceptionReason
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthExceptionReason = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthInitiationDate.
             * @member {string} priorAuthInitiationDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthInitiationDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthNotificationMethod.
             * @member {infinitusapi.INFPriorAuthNotificationMethod} priorAuthNotificationMethod
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthNotificationMethod = 0;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthNotificationMethods.
             * @member {Array.<infinitusapi.INFPriorAuthNotificationMethod>} priorAuthNotificationMethods
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthNotificationMethods = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthOnFile.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthOnFile = null;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthOnFileApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthOnFileApplies
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthOnFileApplies = null;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthProviderNameOnFile.
             * @member {string} priorAuthProviderNameOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthProviderNameOnFile = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthRenewalProcessExists.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthRenewalProcessExists
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthRenewalProcessExists = null;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthRequired
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthRequired = null;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthRequiredCodes.
             * @member {Array.<string>} priorAuthRequiredCodes
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthRequiredCodes = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthResponsibleOrg.
             * @member {infinitusapi.INFPriorAuthResponsibleOrg} priorAuthResponsibleOrg
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthResponsibleOrg = 0;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthStartDate.
             * @member {string} priorAuthStartDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthStartDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthStatus.
             * @member {infinitusapi.INFPriorAuthStatus} priorAuthStatus
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthStatus = 0;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthSubmissionDate.
             * @member {string} priorAuthSubmissionDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthSubmissionDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthSubmissionMethod.
             * @member {infinitusapi.INFPriorAuthSubmissionMethod} priorAuthSubmissionMethod
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthSubmissionMethod = 0;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthSubmissionMethods.
             * @member {Array.<infinitusapi.INFPriorAuthSubmissionMethod>} priorAuthSubmissionMethods
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthSubmissionMethods = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthTurnaroundTime.
             * @member {string} priorAuthTurnaroundTime
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthTurnaroundTime = "";
    
            /**
             * INFBVTaskOutputAccessInfo priorAuthType.
             * @member {infinitusapi.INFPriorAuthType} priorAuthType
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.priorAuthType = 0;
    
            /**
             * INFBVTaskOutputAccessInfo quantityLimit.
             * @member {string} quantityLimit
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.quantityLimit = "";
    
            /**
             * INFBVTaskOutputAccessInfo referralAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} referralAddress
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralAddress = null;
    
            /**
             * INFBVTaskOutputAccessInfo referralEffectiveDate.
             * @member {string} referralEffectiveDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralEffectiveDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo referralFax.
             * @member {string} referralFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo referralNumVisitsApproved.
             * @member {google.protobuf.IDoubleValue|null|undefined} referralNumVisitsApproved
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralNumVisitsApproved = null;
    
            /**
             * INFBVTaskOutputAccessInfo referralNumVisitsUsed.
             * @member {google.protobuf.IDoubleValue|null|undefined} referralNumVisitsUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralNumVisitsUsed = null;
    
            /**
             * INFBVTaskOutputAccessInfo referralNumber.
             * @member {string} referralNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo referralOnFile.
             * @member {google.protobuf.IBoolValue|null|undefined} referralOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralOnFile = null;
    
            /**
             * INFBVTaskOutputAccessInfo referralRecertDate.
             * @member {string} referralRecertDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralRecertDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo referralRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} referralRequired
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralRequired = null;
    
            /**
             * INFBVTaskOutputAccessInfo referralRequirements.
             * @member {infinitusapi.InfReferralRequirementsType} referralRequirements
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralRequirements = 0;
    
            /**
             * INFBVTaskOutputAccessInfo referralSubmissionProcess.
             * @member {string} referralSubmissionProcess
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralSubmissionProcess = "";
    
            /**
             * INFBVTaskOutputAccessInfo referralWebsite.
             * @member {string} referralWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.referralWebsite = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacy2Address.
             * @member {infinitusapi.IINFAddress|null|undefined} specialtyPharmacy2Address
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacy2Address = null;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacy2Fax.
             * @member {string} specialtyPharmacy2Fax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacy2Fax = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacy2Name.
             * @member {string} specialtyPharmacy2Name
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacy2Name = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacy2Phone.
             * @member {string} specialtyPharmacy2Phone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacy2Phone = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacy3Address.
             * @member {infinitusapi.IINFAddress|null|undefined} specialtyPharmacy3Address
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacy3Address = null;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacy3Fax.
             * @member {string} specialtyPharmacy3Fax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacy3Fax = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacy3Name.
             * @member {string} specialtyPharmacy3Name
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacy3Name = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacy3Phone.
             * @member {string} specialtyPharmacy3Phone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacy3Phone = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} specialtyPharmacyAddress
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyAddress = null;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyAvailability.
             * @member {infinitusapi.INFSpecialtyPharmacyAvailability} specialtyPharmacyAvailability
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyAvailability = 0;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyExclusions.
             * @member {string} specialtyPharmacyExclusions
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyExclusions = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyFax.
             * @member {string} specialtyPharmacyFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyName.
             * @member {string} specialtyPharmacyName
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyName = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyNetwork.
             * @member {infinitusapi.INFSpecialtyPharmacyNetwork} specialtyPharmacyNetwork
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyNetwork = 0;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPreDeterminationOrg.
             * @member {string} specialtyPharmacyObtainPreDeterminationOrg
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPreDeterminationOrg = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPredeterminationFax.
             * @member {string} specialtyPharmacyObtainPredeterminationFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPredeterminationFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPredeterminationPhone.
             * @member {string} specialtyPharmacyObtainPredeterminationPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPredeterminationPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPredeterminationWebsite.
             * @member {string} specialtyPharmacyObtainPredeterminationWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPredeterminationWebsite = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} specialtyPharmacyObtainPriorAuthAddress
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPriorAuthAddress = null;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthFax.
             * @member {string} specialtyPharmacyObtainPriorAuthFax
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPriorAuthFax = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthFormLink.
             * @member {string} specialtyPharmacyObtainPriorAuthFormLink
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPriorAuthFormLink = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthOrg.
             * @member {string} specialtyPharmacyObtainPriorAuthOrg
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPriorAuthOrg = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthPhone.
             * @member {string} specialtyPharmacyObtainPriorAuthPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPriorAuthPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthRequirements.
             * @member {Array.<infinitusapi.INFPriorAuthRequirementType>} specialtyPharmacyObtainPriorAuthRequirements
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPriorAuthRequirements = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyObtainPriorAuthWebsite.
             * @member {string} specialtyPharmacyObtainPriorAuthWebsite
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyObtainPriorAuthWebsite = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPhone.
             * @member {string} specialtyPharmacyPhone
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPhone = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationApprovedQuantity.
             * @member {string} specialtyPharmacyPredeterminationApprovedQuantity
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationApprovedQuantity = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationApprovedQuantityUsed.
             * @member {string} specialtyPharmacyPredeterminationApprovedQuantityUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationApprovedQuantityUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationEndDate.
             * @member {string} specialtyPharmacyPredeterminationEndDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationEndDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationNumber.
             * @member {string} specialtyPharmacyPredeterminationNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationOnFile.
             * @member {google.protobuf.IBoolValue|null|undefined} specialtyPharmacyPredeterminationOnFile
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationOnFile = null;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationRequirementType.
             * @member {infinitusapi.INFPredeterminationRequirementType} specialtyPharmacyPredeterminationRequirementType
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationRequirementType = 0;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationStartDate.
             * @member {string} specialtyPharmacyPredeterminationStartDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationStartDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationStatus.
             * @member {infinitusapi.INFSpecialtyPharmacyPredeterminationStatus} specialtyPharmacyPredeterminationStatus
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationStatus = 0;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPredeterminationTurnaroundTime.
             * @member {string} specialtyPharmacyPredeterminationTurnaroundTime
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPredeterminationTurnaroundTime = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovalNumber.
             * @member {string} specialtyPharmacyPriorAuthApprovalNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthApprovalNumber = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovedQuantity.
             * @member {string} specialtyPharmacyPriorAuthApprovedQuantity
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthApprovedQuantity = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovedQuantityUsed.
             * @member {string} specialtyPharmacyPriorAuthApprovedQuantityUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthApprovedQuantityUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovedTreatments.
             * @member {string} specialtyPharmacyPriorAuthApprovedTreatments
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthApprovedTreatments = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthApprovedTreatmentsUsed.
             * @member {string} specialtyPharmacyPriorAuthApprovedTreatmentsUsed
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthApprovedTreatmentsUsed = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthEndDate.
             * @member {string} specialtyPharmacyPriorAuthEndDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthEndDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthNotificationMethod.
             * @member {infinitusapi.INFPriorAuthNotificationMethod} specialtyPharmacyPriorAuthNotificationMethod
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthNotificationMethod = 0;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthNotificationMethods.
             * @member {Array.<infinitusapi.INFPriorAuthNotificationMethod>} specialtyPharmacyPriorAuthNotificationMethods
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthNotificationMethods = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthProcessDifferent.
             * @member {google.protobuf.IBoolValue|null|undefined} specialtyPharmacyPriorAuthProcessDifferent
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthProcessDifferent = null;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} specialtyPharmacyPriorAuthRequired
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthRequired = null;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthStartDate.
             * @member {string} specialtyPharmacyPriorAuthStartDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthStartDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthStatus.
             * @member {infinitusapi.INFPriorAuthStatus} specialtyPharmacyPriorAuthStatus
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthStatus = 0;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthSubmissionMethod.
             * @member {infinitusapi.INFPriorAuthSubmissionMethod} specialtyPharmacyPriorAuthSubmissionMethod
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthSubmissionMethod = 0;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthSubmissionMethods.
             * @member {Array.<infinitusapi.INFPriorAuthSubmissionMethod>} specialtyPharmacyPriorAuthSubmissionMethods
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthSubmissionMethods = $util.emptyArray;
    
            /**
             * INFBVTaskOutputAccessInfo specialtyPharmacyPriorAuthTurnaroundTime.
             * @member {string} specialtyPharmacyPriorAuthTurnaroundTime
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.specialtyPharmacyPriorAuthTurnaroundTime = "";
    
            /**
             * INFBVTaskOutputAccessInfo stepTherapyMethod.
             * @member {infinitusapi.INFStepTherapyMethod} stepTherapyMethod
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.stepTherapyMethod = 0;
    
            /**
             * INFBVTaskOutputAccessInfo stepTherapyRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} stepTherapyRequired
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.stepTherapyRequired = null;
    
            /**
             * INFBVTaskOutputAccessInfo stepTherapyTreatment.
             * @member {string} stepTherapyTreatment
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.stepTherapyTreatment = "";
    
            /**
             * INFBVTaskOutputAccessInfo therapyAvailabilityDate.
             * @member {string} therapyAvailabilityDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.therapyAvailabilityDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo therapySeasonStartDate.
             * @member {string} therapySeasonStartDate
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.therapySeasonStartDate = "";
    
            /**
             * INFBVTaskOutputAccessInfo timelyFilingPeriod.
             * @member {string} timelyFilingPeriod
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.timelyFilingPeriod = "";
    
            /**
             * INFBVTaskOutputAccessInfo transportationServiceCovered.
             * @member {google.protobuf.IBoolValue|null|undefined} transportationServiceCovered
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.transportationServiceCovered = null;
    
            /**
             * INFBVTaskOutputAccessInfo transportationServicePhoneNumber.
             * @member {string} transportationServicePhoneNumber
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             */
            INFBVTaskOutputAccessInfo.prototype.transportationServicePhoneNumber = "";
    
            /**
             * Creates a new INFBVTaskOutputAccessInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputAccessInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputAccessInfo} INFBVTaskOutputAccessInfo instance
             */
            INFBVTaskOutputAccessInfo.create = function create(properties) {
                return new INFBVTaskOutputAccessInfo(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputAccessInfo message. Does not implicitly {@link infinitusapi.INFBVTaskOutputAccessInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputAccessInfo} message INFBVTaskOutputAccessInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputAccessInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.attestationProcess != null && Object.hasOwnProperty.call(message, "attestationProcess"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.attestationProcess);
                if (message.attestationProcessExists != null && Object.hasOwnProperty.call(message, "attestationProcessExists"))
                    $root.google.protobuf.BoolValue.encode(message.attestationProcessExists, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.buyAndBillAvailability != null && Object.hasOwnProperty.call(message, "buyAndBillAvailability"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.buyAndBillAvailability);
                if (message.claimsAddress != null && Object.hasOwnProperty.call(message, "claimsAddress"))
                    $root.infinitusapi.INFAddress.encode(message.claimsAddress, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.coverageExceptionProcessExists != null && Object.hasOwnProperty.call(message, "coverageExceptionProcessExists"))
                    $root.google.protobuf.BoolValue.encode(message.coverageExceptionProcessExists, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.coverageExceptionTurnaroundTime != null && Object.hasOwnProperty.call(message, "coverageExceptionTurnaroundTime"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.coverageExceptionTurnaroundTime);
                if (message.coverageRestrictions != null && Object.hasOwnProperty.call(message, "coverageRestrictions"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.coverageRestrictions);
                if (message.cptObtainPreDeterminationOrg != null && Object.hasOwnProperty.call(message, "cptObtainPreDeterminationOrg"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.cptObtainPreDeterminationOrg);
                if (message.cptObtainPredeterminationFax != null && Object.hasOwnProperty.call(message, "cptObtainPredeterminationFax"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.cptObtainPredeterminationFax);
                if (message.cptObtainPredeterminationPhone != null && Object.hasOwnProperty.call(message, "cptObtainPredeterminationPhone"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.cptObtainPredeterminationPhone);
                if (message.cptObtainPredeterminationWebsite != null && Object.hasOwnProperty.call(message, "cptObtainPredeterminationWebsite"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.cptObtainPredeterminationWebsite);
                if (message.cptObtainPriorAuthAddress != null && Object.hasOwnProperty.call(message, "cptObtainPriorAuthAddress"))
                    $root.infinitusapi.INFAddress.encode(message.cptObtainPriorAuthAddress, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.cptObtainPriorAuthFax != null && Object.hasOwnProperty.call(message, "cptObtainPriorAuthFax"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.cptObtainPriorAuthFax);
                if (message.cptObtainPriorAuthFormLink != null && Object.hasOwnProperty.call(message, "cptObtainPriorAuthFormLink"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.cptObtainPriorAuthFormLink);
                if (message.cptObtainPriorAuthOrg != null && Object.hasOwnProperty.call(message, "cptObtainPriorAuthOrg"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.cptObtainPriorAuthOrg);
                if (message.cptObtainPriorAuthPhone != null && Object.hasOwnProperty.call(message, "cptObtainPriorAuthPhone"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.cptObtainPriorAuthPhone);
                if (message.cptObtainPriorAuthRequirements != null && message.cptObtainPriorAuthRequirements.length) {
                    writer.uint32(/* id 18, wireType 2 =*/146).fork();
                    for (var i = 0; i < message.cptObtainPriorAuthRequirements.length; ++i)
                        writer.int32(message.cptObtainPriorAuthRequirements[i]);
                    writer.ldelim();
                }
                if (message.cptObtainPriorAuthWebsite != null && Object.hasOwnProperty.call(message, "cptObtainPriorAuthWebsite"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.cptObtainPriorAuthWebsite);
                if (message.cptPredeterminationApprovedQuantity != null && Object.hasOwnProperty.call(message, "cptPredeterminationApprovedQuantity"))
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.cptPredeterminationApprovedQuantity);
                if (message.cptPredeterminationApprovedQuantityUsed != null && Object.hasOwnProperty.call(message, "cptPredeterminationApprovedQuantityUsed"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.cptPredeterminationApprovedQuantityUsed);
                if (message.cptPredeterminationEndDate != null && Object.hasOwnProperty.call(message, "cptPredeterminationEndDate"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.cptPredeterminationEndDate);
                if (message.cptPredeterminationNumber != null && Object.hasOwnProperty.call(message, "cptPredeterminationNumber"))
                    writer.uint32(/* id 23, wireType 2 =*/186).string(message.cptPredeterminationNumber);
                if (message.cptPredeterminationStartDate != null && Object.hasOwnProperty.call(message, "cptPredeterminationStartDate"))
                    writer.uint32(/* id 24, wireType 2 =*/194).string(message.cptPredeterminationStartDate);
                if (message.cptPredeterminationTurnaroundTime != null && Object.hasOwnProperty.call(message, "cptPredeterminationTurnaroundTime"))
                    writer.uint32(/* id 25, wireType 2 =*/202).string(message.cptPredeterminationTurnaroundTime);
                if (message.cptPriorAuthApprovalNumber != null && Object.hasOwnProperty.call(message, "cptPriorAuthApprovalNumber"))
                    writer.uint32(/* id 26, wireType 2 =*/210).string(message.cptPriorAuthApprovalNumber);
                if (message.cptPriorAuthApprovedQuantity != null && Object.hasOwnProperty.call(message, "cptPriorAuthApprovedQuantity"))
                    writer.uint32(/* id 27, wireType 2 =*/218).string(message.cptPriorAuthApprovedQuantity);
                if (message.cptPriorAuthApprovedQuantityUsed != null && Object.hasOwnProperty.call(message, "cptPriorAuthApprovedQuantityUsed"))
                    writer.uint32(/* id 28, wireType 2 =*/226).string(message.cptPriorAuthApprovedQuantityUsed);
                if (message.cptPriorAuthApprovedTreatments != null && Object.hasOwnProperty.call(message, "cptPriorAuthApprovedTreatments"))
                    writer.uint32(/* id 29, wireType 2 =*/234).string(message.cptPriorAuthApprovedTreatments);
                if (message.cptPriorAuthApprovedTreatmentsUsed != null && Object.hasOwnProperty.call(message, "cptPriorAuthApprovedTreatmentsUsed"))
                    writer.uint32(/* id 30, wireType 2 =*/242).string(message.cptPriorAuthApprovedTreatmentsUsed);
                if (message.cptPriorAuthEndDate != null && Object.hasOwnProperty.call(message, "cptPriorAuthEndDate"))
                    writer.uint32(/* id 31, wireType 2 =*/250).string(message.cptPriorAuthEndDate);
                if (message.cptPriorAuthNotificationMethod != null && Object.hasOwnProperty.call(message, "cptPriorAuthNotificationMethod"))
                    writer.uint32(/* id 32, wireType 0 =*/256).int32(message.cptPriorAuthNotificationMethod);
                if (message.cptPriorAuthNotificationMethods != null && message.cptPriorAuthNotificationMethods.length) {
                    writer.uint32(/* id 33, wireType 2 =*/266).fork();
                    for (var i = 0; i < message.cptPriorAuthNotificationMethods.length; ++i)
                        writer.int32(message.cptPriorAuthNotificationMethods[i]);
                    writer.ldelim();
                }
                if (message.cptPriorAuthProcessDifferent != null && Object.hasOwnProperty.call(message, "cptPriorAuthProcessDifferent"))
                    $root.google.protobuf.BoolValue.encode(message.cptPriorAuthProcessDifferent, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.cptPriorAuthStartDate != null && Object.hasOwnProperty.call(message, "cptPriorAuthStartDate"))
                    writer.uint32(/* id 35, wireType 2 =*/282).string(message.cptPriorAuthStartDate);
                if (message.cptPriorAuthSubmissionMethod != null && Object.hasOwnProperty.call(message, "cptPriorAuthSubmissionMethod"))
                    writer.uint32(/* id 36, wireType 0 =*/288).int32(message.cptPriorAuthSubmissionMethod);
                if (message.cptPriorAuthSubmissionMethods != null && message.cptPriorAuthSubmissionMethods.length) {
                    writer.uint32(/* id 37, wireType 2 =*/298).fork();
                    for (var i = 0; i < message.cptPriorAuthSubmissionMethods.length; ++i)
                        writer.int32(message.cptPriorAuthSubmissionMethods[i]);
                    writer.ldelim();
                }
                if (message.cptPriorAuthTurnaroundTime != null && Object.hasOwnProperty.call(message, "cptPriorAuthTurnaroundTime"))
                    writer.uint32(/* id 38, wireType 2 =*/306).string(message.cptPriorAuthTurnaroundTime);
                if (message.hasSpecialtyPharmacyExclusions != null && Object.hasOwnProperty.call(message, "hasSpecialtyPharmacyExclusions"))
                    $root.google.protobuf.BoolValue.encode(message.hasSpecialtyPharmacyExclusions, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                if (message.homeHealthCovered != null && Object.hasOwnProperty.call(message, "homeHealthCovered"))
                    $root.google.protobuf.BoolValue.encode(message.homeHealthCovered, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                if (message.homeHealthObtainPriorAuthFax != null && Object.hasOwnProperty.call(message, "homeHealthObtainPriorAuthFax"))
                    writer.uint32(/* id 41, wireType 2 =*/330).string(message.homeHealthObtainPriorAuthFax);
                if (message.homeHealthObtainPriorAuthPhone != null && Object.hasOwnProperty.call(message, "homeHealthObtainPriorAuthPhone"))
                    writer.uint32(/* id 42, wireType 2 =*/338).string(message.homeHealthObtainPriorAuthPhone);
                if (message.homeHealthPriorAuthRequired != null && Object.hasOwnProperty.call(message, "homeHealthPriorAuthRequired"))
                    $root.google.protobuf.BoolValue.encode(message.homeHealthPriorAuthRequired, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                if (message.homeHealthProviderName != null && Object.hasOwnProperty.call(message, "homeHealthProviderName"))
                    writer.uint32(/* id 44, wireType 2 =*/354).string(message.homeHealthProviderName);
                if (message.homeHealthProviderPhone != null && Object.hasOwnProperty.call(message, "homeHealthProviderPhone"))
                    writer.uint32(/* id 45, wireType 2 =*/362).string(message.homeHealthProviderPhone);
                if (message.homeHealthServices != null && Object.hasOwnProperty.call(message, "homeHealthServices"))
                    writer.uint32(/* id 46, wireType 2 =*/370).string(message.homeHealthServices);
                if (message.homeboundRequired != null && Object.hasOwnProperty.call(message, "homeboundRequired"))
                    $root.google.protobuf.BoolValue.encode(message.homeboundRequired, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                if (message.lodgingCovered != null && Object.hasOwnProperty.call(message, "lodgingCovered"))
                    $root.google.protobuf.BoolValue.encode(message.lodgingCovered, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
                if (message.lodgingServicePhoneNumber != null && Object.hasOwnProperty.call(message, "lodgingServicePhoneNumber"))
                    writer.uint32(/* id 49, wireType 2 =*/394).string(message.lodgingServicePhoneNumber);
                if (message.medicalGroupName != null && Object.hasOwnProperty.call(message, "medicalGroupName"))
                    writer.uint32(/* id 50, wireType 2 =*/402).string(message.medicalGroupName);
                if (message.medicalGroupPhoneNumber != null && Object.hasOwnProperty.call(message, "medicalGroupPhoneNumber"))
                    writer.uint32(/* id 51, wireType 2 =*/410).string(message.medicalGroupPhoneNumber);
                if (message.medicalPolicyAvailableOnWebsite != null && Object.hasOwnProperty.call(message, "medicalPolicyAvailableOnWebsite"))
                    $root.google.protobuf.BoolValue.encode(message.medicalPolicyAvailableOnWebsite, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
                if (message.medicalPolicyNumber != null && Object.hasOwnProperty.call(message, "medicalPolicyNumber"))
                    writer.uint32(/* id 53, wireType 2 =*/426).string(message.medicalPolicyNumber);
                if (message.obtainCoverageExceptionAddress != null && Object.hasOwnProperty.call(message, "obtainCoverageExceptionAddress"))
                    $root.infinitusapi.INFAddress.encode(message.obtainCoverageExceptionAddress, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
                if (message.obtainCoverageExceptionFax != null && Object.hasOwnProperty.call(message, "obtainCoverageExceptionFax"))
                    writer.uint32(/* id 55, wireType 2 =*/442).string(message.obtainCoverageExceptionFax);
                if (message.obtainCoverageExceptionPhone != null && Object.hasOwnProperty.call(message, "obtainCoverageExceptionPhone"))
                    writer.uint32(/* id 56, wireType 2 =*/450).string(message.obtainCoverageExceptionPhone);
                if (message.obtainCoverageExceptionProcess != null && Object.hasOwnProperty.call(message, "obtainCoverageExceptionProcess"))
                    writer.uint32(/* id 57, wireType 2 =*/458).string(message.obtainCoverageExceptionProcess);
                if (message.obtainCoverageExceptionWebsite != null && Object.hasOwnProperty.call(message, "obtainCoverageExceptionWebsite"))
                    writer.uint32(/* id 58, wireType 2 =*/466).string(message.obtainCoverageExceptionWebsite);
                if (message.obtainPreDeterminationOrg != null && Object.hasOwnProperty.call(message, "obtainPreDeterminationOrg"))
                    writer.uint32(/* id 59, wireType 2 =*/474).string(message.obtainPreDeterminationOrg);
                if (message.obtainPredeterminationFax != null && Object.hasOwnProperty.call(message, "obtainPredeterminationFax"))
                    writer.uint32(/* id 60, wireType 2 =*/482).string(message.obtainPredeterminationFax);
                if (message.obtainPredeterminationPhone != null && Object.hasOwnProperty.call(message, "obtainPredeterminationPhone"))
                    writer.uint32(/* id 61, wireType 2 =*/490).string(message.obtainPredeterminationPhone);
                if (message.obtainPredeterminationWebsite != null && Object.hasOwnProperty.call(message, "obtainPredeterminationWebsite"))
                    writer.uint32(/* id 62, wireType 2 =*/498).string(message.obtainPredeterminationWebsite);
                if (message.obtainPriorAuthAddress != null && Object.hasOwnProperty.call(message, "obtainPriorAuthAddress"))
                    $root.infinitusapi.INFAddress.encode(message.obtainPriorAuthAddress, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
                if (message.obtainPriorAuthFax != null && Object.hasOwnProperty.call(message, "obtainPriorAuthFax"))
                    writer.uint32(/* id 64, wireType 2 =*/514).string(message.obtainPriorAuthFax);
                if (message.obtainPriorAuthFormLink != null && Object.hasOwnProperty.call(message, "obtainPriorAuthFormLink"))
                    writer.uint32(/* id 65, wireType 2 =*/522).string(message.obtainPriorAuthFormLink);
                if (message.obtainPriorAuthOrg != null && Object.hasOwnProperty.call(message, "obtainPriorAuthOrg"))
                    writer.uint32(/* id 66, wireType 2 =*/530).string(message.obtainPriorAuthOrg);
                if (message.obtainPriorAuthPhone != null && Object.hasOwnProperty.call(message, "obtainPriorAuthPhone"))
                    writer.uint32(/* id 67, wireType 2 =*/538).string(message.obtainPriorAuthPhone);
                if (message.obtainPriorAuthRequirements != null && message.obtainPriorAuthRequirements.length) {
                    writer.uint32(/* id 68, wireType 2 =*/546).fork();
                    for (var i = 0; i < message.obtainPriorAuthRequirements.length; ++i)
                        writer.int32(message.obtainPriorAuthRequirements[i]);
                    writer.ldelim();
                }
                if (message.obtainPriorAuthWebsite != null && Object.hasOwnProperty.call(message, "obtainPriorAuthWebsite"))
                    writer.uint32(/* id 69, wireType 2 =*/554).string(message.obtainPriorAuthWebsite);
                if (message.obtainQuantityLimitExceptionPhone != null && Object.hasOwnProperty.call(message, "obtainQuantityLimitExceptionPhone"))
                    writer.uint32(/* id 70, wireType 2 =*/562).string(message.obtainQuantityLimitExceptionPhone);
                if (message.obtainQuantityLimitExceptionProcess != null && Object.hasOwnProperty.call(message, "obtainQuantityLimitExceptionProcess"))
                    writer.uint32(/* id 71, wireType 2 =*/570).string(message.obtainQuantityLimitExceptionProcess);
                if (message.pcpName != null && Object.hasOwnProperty.call(message, "pcpName"))
                    writer.uint32(/* id 72, wireType 2 =*/578).string(message.pcpName);
                if (message.pcpPhone != null && Object.hasOwnProperty.call(message, "pcpPhone"))
                    writer.uint32(/* id 73, wireType 2 =*/586).string(message.pcpPhone);
                if (message.preDeterminationAvailable != null && Object.hasOwnProperty.call(message, "preDeterminationAvailable"))
                    $root.google.protobuf.BoolValue.encode(message.preDeterminationAvailable, writer.uint32(/* id 74, wireType 2 =*/594).fork()).ldelim();
                if (message.preDeterminationNumber != null && Object.hasOwnProperty.call(message, "preDeterminationNumber"))
                    writer.uint32(/* id 75, wireType 2 =*/602).string(message.preDeterminationNumber);
                if (message.preDeterminationOnFile != null && Object.hasOwnProperty.call(message, "preDeterminationOnFile"))
                    $root.google.protobuf.BoolValue.encode(message.preDeterminationOnFile, writer.uint32(/* id 76, wireType 2 =*/610).fork()).ldelim();
                if (message.preDeterminationRequired != null && Object.hasOwnProperty.call(message, "preDeterminationRequired"))
                    $root.google.protobuf.BoolValue.encode(message.preDeterminationRequired, writer.uint32(/* id 77, wireType 2 =*/618).fork()).ldelim();
                if (message.preDeterminationRequirement != null && Object.hasOwnProperty.call(message, "preDeterminationRequirement"))
                    writer.uint32(/* id 78, wireType 0 =*/624).int32(message.preDeterminationRequirement);
                if (message.predeterminationAddressOnFile != null && Object.hasOwnProperty.call(message, "predeterminationAddressOnFile"))
                    $root.infinitusapi.INFAddress.encode(message.predeterminationAddressOnFile, writer.uint32(/* id 79, wireType 2 =*/634).fork()).ldelim();
                if (message.predeterminationApprovedQuantity != null && Object.hasOwnProperty.call(message, "predeterminationApprovedQuantity"))
                    writer.uint32(/* id 80, wireType 2 =*/642).string(message.predeterminationApprovedQuantity);
                if (message.predeterminationApprovedQuantityUsed != null && Object.hasOwnProperty.call(message, "predeterminationApprovedQuantityUsed"))
                    writer.uint32(/* id 81, wireType 2 =*/650).string(message.predeterminationApprovedQuantityUsed);
                if (message.predeterminationCompletionDate != null && Object.hasOwnProperty.call(message, "predeterminationCompletionDate"))
                    writer.uint32(/* id 82, wireType 2 =*/658).string(message.predeterminationCompletionDate);
                if (message.predeterminationEndDate != null && Object.hasOwnProperty.call(message, "predeterminationEndDate"))
                    writer.uint32(/* id 83, wireType 2 =*/666).string(message.predeterminationEndDate);
                if (message.predeterminationOnFileApplies != null && Object.hasOwnProperty.call(message, "predeterminationOnFileApplies"))
                    $root.google.protobuf.BoolValue.encode(message.predeterminationOnFileApplies, writer.uint32(/* id 84, wireType 2 =*/674).fork()).ldelim();
                if (message.predeterminationProviderNameOnFile != null && Object.hasOwnProperty.call(message, "predeterminationProviderNameOnFile"))
                    writer.uint32(/* id 85, wireType 2 =*/682).string(message.predeterminationProviderNameOnFile);
                if (message.predeterminationReceivedDate != null && Object.hasOwnProperty.call(message, "predeterminationReceivedDate"))
                    writer.uint32(/* id 86, wireType 2 =*/690).string(message.predeterminationReceivedDate);
                if (message.predeterminationRenewalProcessExists != null && Object.hasOwnProperty.call(message, "predeterminationRenewalProcessExists"))
                    $root.google.protobuf.BoolValue.encode(message.predeterminationRenewalProcessExists, writer.uint32(/* id 87, wireType 2 =*/698).fork()).ldelim();
                if (message.predeterminationStartDate != null && Object.hasOwnProperty.call(message, "predeterminationStartDate"))
                    writer.uint32(/* id 88, wireType 2 =*/706).string(message.predeterminationStartDate);
                if (message.predeterminationStatus != null && Object.hasOwnProperty.call(message, "predeterminationStatus"))
                    writer.uint32(/* id 89, wireType 0 =*/712).int32(message.predeterminationStatus);
                if (message.predeterminationSubmissionDate != null && Object.hasOwnProperty.call(message, "predeterminationSubmissionDate"))
                    writer.uint32(/* id 90, wireType 2 =*/722).string(message.predeterminationSubmissionDate);
                if (message.predeterminationTurnaroundTime != null && Object.hasOwnProperty.call(message, "predeterminationTurnaroundTime"))
                    writer.uint32(/* id 91, wireType 2 =*/730).string(message.predeterminationTurnaroundTime);
                if (message.preferredSpecialtyPharmaciesAvailable != null && message.preferredSpecialtyPharmaciesAvailable.length)
                    for (var i = 0; i < message.preferredSpecialtyPharmaciesAvailable.length; ++i)
                        $root.infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy.encode(message.preferredSpecialtyPharmaciesAvailable[i], writer.uint32(/* id 92, wireType 2 =*/738).fork()).ldelim();
                if (message.priorAuthAddressOnFile != null && Object.hasOwnProperty.call(message, "priorAuthAddressOnFile"))
                    $root.infinitusapi.INFAddress.encode(message.priorAuthAddressOnFile, writer.uint32(/* id 93, wireType 2 =*/746).fork()).ldelim();
                if (message.priorAuthApprovalDate != null && Object.hasOwnProperty.call(message, "priorAuthApprovalDate"))
                    writer.uint32(/* id 94, wireType 2 =*/754).string(message.priorAuthApprovalDate);
                if (message.priorAuthApprovalNumber != null && Object.hasOwnProperty.call(message, "priorAuthApprovalNumber"))
                    writer.uint32(/* id 95, wireType 2 =*/762).string(message.priorAuthApprovalNumber);
                if (message.priorAuthApprovedQuantity != null && Object.hasOwnProperty.call(message, "priorAuthApprovedQuantity"))
                    writer.uint32(/* id 96, wireType 2 =*/770).string(message.priorAuthApprovedQuantity);
                if (message.priorAuthApprovedQuantityUsed != null && Object.hasOwnProperty.call(message, "priorAuthApprovedQuantityUsed"))
                    writer.uint32(/* id 97, wireType 2 =*/778).string(message.priorAuthApprovedQuantityUsed);
                if (message.priorAuthApprovedTreatments != null && Object.hasOwnProperty.call(message, "priorAuthApprovedTreatments"))
                    writer.uint32(/* id 98, wireType 2 =*/786).string(message.priorAuthApprovedTreatments);
                if (message.priorAuthApprovedTreatmentsUsed != null && Object.hasOwnProperty.call(message, "priorAuthApprovedTreatmentsUsed"))
                    writer.uint32(/* id 99, wireType 2 =*/794).string(message.priorAuthApprovedTreatmentsUsed);
                if (message.priorAuthCodesOnFile != null && message.priorAuthCodesOnFile.length)
                    for (var i = 0; i < message.priorAuthCodesOnFile.length; ++i)
                        writer.uint32(/* id 100, wireType 2 =*/802).string(message.priorAuthCodesOnFile[i]);
                if (message.priorAuthCompletionDate != null && Object.hasOwnProperty.call(message, "priorAuthCompletionDate"))
                    writer.uint32(/* id 101, wireType 2 =*/810).string(message.priorAuthCompletionDate);
                if (message.priorAuthEndDate != null && Object.hasOwnProperty.call(message, "priorAuthEndDate"))
                    writer.uint32(/* id 102, wireType 2 =*/818).string(message.priorAuthEndDate);
                if (message.priorAuthExceptionReason != null && Object.hasOwnProperty.call(message, "priorAuthExceptionReason"))
                    writer.uint32(/* id 103, wireType 2 =*/826).string(message.priorAuthExceptionReason);
                if (message.priorAuthInitiationDate != null && Object.hasOwnProperty.call(message, "priorAuthInitiationDate"))
                    writer.uint32(/* id 104, wireType 2 =*/834).string(message.priorAuthInitiationDate);
                if (message.priorAuthNotificationMethod != null && Object.hasOwnProperty.call(message, "priorAuthNotificationMethod"))
                    writer.uint32(/* id 105, wireType 0 =*/840).int32(message.priorAuthNotificationMethod);
                if (message.priorAuthNotificationMethods != null && message.priorAuthNotificationMethods.length) {
                    writer.uint32(/* id 106, wireType 2 =*/850).fork();
                    for (var i = 0; i < message.priorAuthNotificationMethods.length; ++i)
                        writer.int32(message.priorAuthNotificationMethods[i]);
                    writer.ldelim();
                }
                if (message.priorAuthOnFile != null && Object.hasOwnProperty.call(message, "priorAuthOnFile"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthOnFile, writer.uint32(/* id 107, wireType 2 =*/858).fork()).ldelim();
                if (message.priorAuthOnFileApplies != null && Object.hasOwnProperty.call(message, "priorAuthOnFileApplies"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthOnFileApplies, writer.uint32(/* id 108, wireType 2 =*/866).fork()).ldelim();
                if (message.priorAuthProviderNameOnFile != null && Object.hasOwnProperty.call(message, "priorAuthProviderNameOnFile"))
                    writer.uint32(/* id 109, wireType 2 =*/874).string(message.priorAuthProviderNameOnFile);
                if (message.priorAuthRenewalProcessExists != null && Object.hasOwnProperty.call(message, "priorAuthRenewalProcessExists"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthRenewalProcessExists, writer.uint32(/* id 110, wireType 2 =*/882).fork()).ldelim();
                if (message.priorAuthRequired != null && Object.hasOwnProperty.call(message, "priorAuthRequired"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthRequired, writer.uint32(/* id 111, wireType 2 =*/890).fork()).ldelim();
                if (message.priorAuthRequiredCodes != null && message.priorAuthRequiredCodes.length)
                    for (var i = 0; i < message.priorAuthRequiredCodes.length; ++i)
                        writer.uint32(/* id 112, wireType 2 =*/898).string(message.priorAuthRequiredCodes[i]);
                if (message.priorAuthResponsibleOrg != null && Object.hasOwnProperty.call(message, "priorAuthResponsibleOrg"))
                    writer.uint32(/* id 113, wireType 0 =*/904).int32(message.priorAuthResponsibleOrg);
                if (message.priorAuthStartDate != null && Object.hasOwnProperty.call(message, "priorAuthStartDate"))
                    writer.uint32(/* id 114, wireType 2 =*/914).string(message.priorAuthStartDate);
                if (message.priorAuthStatus != null && Object.hasOwnProperty.call(message, "priorAuthStatus"))
                    writer.uint32(/* id 115, wireType 0 =*/920).int32(message.priorAuthStatus);
                if (message.priorAuthSubmissionDate != null && Object.hasOwnProperty.call(message, "priorAuthSubmissionDate"))
                    writer.uint32(/* id 116, wireType 2 =*/930).string(message.priorAuthSubmissionDate);
                if (message.priorAuthSubmissionMethod != null && Object.hasOwnProperty.call(message, "priorAuthSubmissionMethod"))
                    writer.uint32(/* id 117, wireType 0 =*/936).int32(message.priorAuthSubmissionMethod);
                if (message.priorAuthSubmissionMethods != null && message.priorAuthSubmissionMethods.length) {
                    writer.uint32(/* id 118, wireType 2 =*/946).fork();
                    for (var i = 0; i < message.priorAuthSubmissionMethods.length; ++i)
                        writer.int32(message.priorAuthSubmissionMethods[i]);
                    writer.ldelim();
                }
                if (message.priorAuthTurnaroundTime != null && Object.hasOwnProperty.call(message, "priorAuthTurnaroundTime"))
                    writer.uint32(/* id 119, wireType 2 =*/954).string(message.priorAuthTurnaroundTime);
                if (message.priorAuthType != null && Object.hasOwnProperty.call(message, "priorAuthType"))
                    writer.uint32(/* id 120, wireType 0 =*/960).int32(message.priorAuthType);
                if (message.quantityLimit != null && Object.hasOwnProperty.call(message, "quantityLimit"))
                    writer.uint32(/* id 121, wireType 2 =*/970).string(message.quantityLimit);
                if (message.referralAddress != null && Object.hasOwnProperty.call(message, "referralAddress"))
                    $root.infinitusapi.INFAddress.encode(message.referralAddress, writer.uint32(/* id 122, wireType 2 =*/978).fork()).ldelim();
                if (message.referralEffectiveDate != null && Object.hasOwnProperty.call(message, "referralEffectiveDate"))
                    writer.uint32(/* id 123, wireType 2 =*/986).string(message.referralEffectiveDate);
                if (message.referralFax != null && Object.hasOwnProperty.call(message, "referralFax"))
                    writer.uint32(/* id 124, wireType 2 =*/994).string(message.referralFax);
                if (message.referralNumVisitsApproved != null && Object.hasOwnProperty.call(message, "referralNumVisitsApproved"))
                    $root.google.protobuf.DoubleValue.encode(message.referralNumVisitsApproved, writer.uint32(/* id 125, wireType 2 =*/1002).fork()).ldelim();
                if (message.referralNumVisitsUsed != null && Object.hasOwnProperty.call(message, "referralNumVisitsUsed"))
                    $root.google.protobuf.DoubleValue.encode(message.referralNumVisitsUsed, writer.uint32(/* id 126, wireType 2 =*/1010).fork()).ldelim();
                if (message.referralNumber != null && Object.hasOwnProperty.call(message, "referralNumber"))
                    writer.uint32(/* id 127, wireType 2 =*/1018).string(message.referralNumber);
                if (message.referralOnFile != null && Object.hasOwnProperty.call(message, "referralOnFile"))
                    $root.google.protobuf.BoolValue.encode(message.referralOnFile, writer.uint32(/* id 128, wireType 2 =*/1026).fork()).ldelim();
                if (message.referralRecertDate != null && Object.hasOwnProperty.call(message, "referralRecertDate"))
                    writer.uint32(/* id 129, wireType 2 =*/1034).string(message.referralRecertDate);
                if (message.referralRequired != null && Object.hasOwnProperty.call(message, "referralRequired"))
                    $root.google.protobuf.BoolValue.encode(message.referralRequired, writer.uint32(/* id 130, wireType 2 =*/1042).fork()).ldelim();
                if (message.referralRequirements != null && Object.hasOwnProperty.call(message, "referralRequirements"))
                    writer.uint32(/* id 131, wireType 0 =*/1048).int32(message.referralRequirements);
                if (message.referralSubmissionProcess != null && Object.hasOwnProperty.call(message, "referralSubmissionProcess"))
                    writer.uint32(/* id 132, wireType 2 =*/1058).string(message.referralSubmissionProcess);
                if (message.referralWebsite != null && Object.hasOwnProperty.call(message, "referralWebsite"))
                    writer.uint32(/* id 133, wireType 2 =*/1066).string(message.referralWebsite);
                if (message.specialtyPharmacy2Address != null && Object.hasOwnProperty.call(message, "specialtyPharmacy2Address"))
                    $root.infinitusapi.INFAddress.encode(message.specialtyPharmacy2Address, writer.uint32(/* id 134, wireType 2 =*/1074).fork()).ldelim();
                if (message.specialtyPharmacy2Fax != null && Object.hasOwnProperty.call(message, "specialtyPharmacy2Fax"))
                    writer.uint32(/* id 135, wireType 2 =*/1082).string(message.specialtyPharmacy2Fax);
                if (message.specialtyPharmacy2Name != null && Object.hasOwnProperty.call(message, "specialtyPharmacy2Name"))
                    writer.uint32(/* id 136, wireType 2 =*/1090).string(message.specialtyPharmacy2Name);
                if (message.specialtyPharmacy2Phone != null && Object.hasOwnProperty.call(message, "specialtyPharmacy2Phone"))
                    writer.uint32(/* id 137, wireType 2 =*/1098).string(message.specialtyPharmacy2Phone);
                if (message.specialtyPharmacy3Address != null && Object.hasOwnProperty.call(message, "specialtyPharmacy3Address"))
                    $root.infinitusapi.INFAddress.encode(message.specialtyPharmacy3Address, writer.uint32(/* id 138, wireType 2 =*/1106).fork()).ldelim();
                if (message.specialtyPharmacy3Fax != null && Object.hasOwnProperty.call(message, "specialtyPharmacy3Fax"))
                    writer.uint32(/* id 139, wireType 2 =*/1114).string(message.specialtyPharmacy3Fax);
                if (message.specialtyPharmacy3Name != null && Object.hasOwnProperty.call(message, "specialtyPharmacy3Name"))
                    writer.uint32(/* id 140, wireType 2 =*/1122).string(message.specialtyPharmacy3Name);
                if (message.specialtyPharmacy3Phone != null && Object.hasOwnProperty.call(message, "specialtyPharmacy3Phone"))
                    writer.uint32(/* id 141, wireType 2 =*/1130).string(message.specialtyPharmacy3Phone);
                if (message.specialtyPharmacyAddress != null && Object.hasOwnProperty.call(message, "specialtyPharmacyAddress"))
                    $root.infinitusapi.INFAddress.encode(message.specialtyPharmacyAddress, writer.uint32(/* id 142, wireType 2 =*/1138).fork()).ldelim();
                if (message.specialtyPharmacyAvailability != null && Object.hasOwnProperty.call(message, "specialtyPharmacyAvailability"))
                    writer.uint32(/* id 143, wireType 0 =*/1144).int32(message.specialtyPharmacyAvailability);
                if (message.specialtyPharmacyExclusions != null && Object.hasOwnProperty.call(message, "specialtyPharmacyExclusions"))
                    writer.uint32(/* id 144, wireType 2 =*/1154).string(message.specialtyPharmacyExclusions);
                if (message.specialtyPharmacyFax != null && Object.hasOwnProperty.call(message, "specialtyPharmacyFax"))
                    writer.uint32(/* id 145, wireType 2 =*/1162).string(message.specialtyPharmacyFax);
                if (message.specialtyPharmacyName != null && Object.hasOwnProperty.call(message, "specialtyPharmacyName"))
                    writer.uint32(/* id 146, wireType 2 =*/1170).string(message.specialtyPharmacyName);
                if (message.specialtyPharmacyNetwork != null && Object.hasOwnProperty.call(message, "specialtyPharmacyNetwork"))
                    writer.uint32(/* id 147, wireType 0 =*/1176).int32(message.specialtyPharmacyNetwork);
                if (message.specialtyPharmacyObtainPreDeterminationOrg != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPreDeterminationOrg"))
                    writer.uint32(/* id 148, wireType 2 =*/1186).string(message.specialtyPharmacyObtainPreDeterminationOrg);
                if (message.specialtyPharmacyObtainPredeterminationFax != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPredeterminationFax"))
                    writer.uint32(/* id 149, wireType 2 =*/1194).string(message.specialtyPharmacyObtainPredeterminationFax);
                if (message.specialtyPharmacyObtainPredeterminationPhone != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPredeterminationPhone"))
                    writer.uint32(/* id 150, wireType 2 =*/1202).string(message.specialtyPharmacyObtainPredeterminationPhone);
                if (message.specialtyPharmacyObtainPredeterminationWebsite != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPredeterminationWebsite"))
                    writer.uint32(/* id 151, wireType 2 =*/1210).string(message.specialtyPharmacyObtainPredeterminationWebsite);
                if (message.specialtyPharmacyObtainPriorAuthAddress != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPriorAuthAddress"))
                    $root.infinitusapi.INFAddress.encode(message.specialtyPharmacyObtainPriorAuthAddress, writer.uint32(/* id 152, wireType 2 =*/1218).fork()).ldelim();
                if (message.specialtyPharmacyObtainPriorAuthFax != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPriorAuthFax"))
                    writer.uint32(/* id 153, wireType 2 =*/1226).string(message.specialtyPharmacyObtainPriorAuthFax);
                if (message.specialtyPharmacyObtainPriorAuthFormLink != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPriorAuthFormLink"))
                    writer.uint32(/* id 154, wireType 2 =*/1234).string(message.specialtyPharmacyObtainPriorAuthFormLink);
                if (message.specialtyPharmacyObtainPriorAuthOrg != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPriorAuthOrg"))
                    writer.uint32(/* id 155, wireType 2 =*/1242).string(message.specialtyPharmacyObtainPriorAuthOrg);
                if (message.specialtyPharmacyObtainPriorAuthPhone != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPriorAuthPhone"))
                    writer.uint32(/* id 156, wireType 2 =*/1250).string(message.specialtyPharmacyObtainPriorAuthPhone);
                if (message.specialtyPharmacyObtainPriorAuthRequirements != null && message.specialtyPharmacyObtainPriorAuthRequirements.length) {
                    writer.uint32(/* id 157, wireType 2 =*/1258).fork();
                    for (var i = 0; i < message.specialtyPharmacyObtainPriorAuthRequirements.length; ++i)
                        writer.int32(message.specialtyPharmacyObtainPriorAuthRequirements[i]);
                    writer.ldelim();
                }
                if (message.specialtyPharmacyObtainPriorAuthWebsite != null && Object.hasOwnProperty.call(message, "specialtyPharmacyObtainPriorAuthWebsite"))
                    writer.uint32(/* id 158, wireType 2 =*/1266).string(message.specialtyPharmacyObtainPriorAuthWebsite);
                if (message.specialtyPharmacyPhone != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPhone"))
                    writer.uint32(/* id 159, wireType 2 =*/1274).string(message.specialtyPharmacyPhone);
                if (message.specialtyPharmacyPredeterminationApprovedQuantity != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationApprovedQuantity"))
                    writer.uint32(/* id 160, wireType 2 =*/1282).string(message.specialtyPharmacyPredeterminationApprovedQuantity);
                if (message.specialtyPharmacyPredeterminationApprovedQuantityUsed != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationApprovedQuantityUsed"))
                    writer.uint32(/* id 161, wireType 2 =*/1290).string(message.specialtyPharmacyPredeterminationApprovedQuantityUsed);
                if (message.specialtyPharmacyPredeterminationEndDate != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationEndDate"))
                    writer.uint32(/* id 162, wireType 2 =*/1298).string(message.specialtyPharmacyPredeterminationEndDate);
                if (message.specialtyPharmacyPredeterminationNumber != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationNumber"))
                    writer.uint32(/* id 163, wireType 2 =*/1306).string(message.specialtyPharmacyPredeterminationNumber);
                if (message.specialtyPharmacyPredeterminationOnFile != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationOnFile"))
                    $root.google.protobuf.BoolValue.encode(message.specialtyPharmacyPredeterminationOnFile, writer.uint32(/* id 164, wireType 2 =*/1314).fork()).ldelim();
                if (message.specialtyPharmacyPredeterminationRequirementType != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationRequirementType"))
                    writer.uint32(/* id 165, wireType 0 =*/1320).int32(message.specialtyPharmacyPredeterminationRequirementType);
                if (message.specialtyPharmacyPredeterminationStartDate != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationStartDate"))
                    writer.uint32(/* id 166, wireType 2 =*/1330).string(message.specialtyPharmacyPredeterminationStartDate);
                if (message.specialtyPharmacyPredeterminationStatus != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationStatus"))
                    writer.uint32(/* id 167, wireType 0 =*/1336).int32(message.specialtyPharmacyPredeterminationStatus);
                if (message.specialtyPharmacyPredeterminationTurnaroundTime != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPredeterminationTurnaroundTime"))
                    writer.uint32(/* id 168, wireType 2 =*/1346).string(message.specialtyPharmacyPredeterminationTurnaroundTime);
                if (message.specialtyPharmacyPriorAuthApprovalNumber != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthApprovalNumber"))
                    writer.uint32(/* id 169, wireType 2 =*/1354).string(message.specialtyPharmacyPriorAuthApprovalNumber);
                if (message.specialtyPharmacyPriorAuthApprovedQuantity != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthApprovedQuantity"))
                    writer.uint32(/* id 170, wireType 2 =*/1362).string(message.specialtyPharmacyPriorAuthApprovedQuantity);
                if (message.specialtyPharmacyPriorAuthApprovedQuantityUsed != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthApprovedQuantityUsed"))
                    writer.uint32(/* id 171, wireType 2 =*/1370).string(message.specialtyPharmacyPriorAuthApprovedQuantityUsed);
                if (message.specialtyPharmacyPriorAuthApprovedTreatments != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthApprovedTreatments"))
                    writer.uint32(/* id 172, wireType 2 =*/1378).string(message.specialtyPharmacyPriorAuthApprovedTreatments);
                if (message.specialtyPharmacyPriorAuthApprovedTreatmentsUsed != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthApprovedTreatmentsUsed"))
                    writer.uint32(/* id 173, wireType 2 =*/1386).string(message.specialtyPharmacyPriorAuthApprovedTreatmentsUsed);
                if (message.specialtyPharmacyPriorAuthEndDate != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthEndDate"))
                    writer.uint32(/* id 174, wireType 2 =*/1394).string(message.specialtyPharmacyPriorAuthEndDate);
                if (message.specialtyPharmacyPriorAuthNotificationMethod != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthNotificationMethod"))
                    writer.uint32(/* id 175, wireType 0 =*/1400).int32(message.specialtyPharmacyPriorAuthNotificationMethod);
                if (message.specialtyPharmacyPriorAuthNotificationMethods != null && message.specialtyPharmacyPriorAuthNotificationMethods.length) {
                    writer.uint32(/* id 176, wireType 2 =*/1410).fork();
                    for (var i = 0; i < message.specialtyPharmacyPriorAuthNotificationMethods.length; ++i)
                        writer.int32(message.specialtyPharmacyPriorAuthNotificationMethods[i]);
                    writer.ldelim();
                }
                if (message.specialtyPharmacyPriorAuthProcessDifferent != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthProcessDifferent"))
                    $root.google.protobuf.BoolValue.encode(message.specialtyPharmacyPriorAuthProcessDifferent, writer.uint32(/* id 177, wireType 2 =*/1418).fork()).ldelim();
                if (message.specialtyPharmacyPriorAuthRequired != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthRequired"))
                    $root.google.protobuf.BoolValue.encode(message.specialtyPharmacyPriorAuthRequired, writer.uint32(/* id 178, wireType 2 =*/1426).fork()).ldelim();
                if (message.specialtyPharmacyPriorAuthStartDate != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthStartDate"))
                    writer.uint32(/* id 179, wireType 2 =*/1434).string(message.specialtyPharmacyPriorAuthStartDate);
                if (message.specialtyPharmacyPriorAuthStatus != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthStatus"))
                    writer.uint32(/* id 180, wireType 0 =*/1440).int32(message.specialtyPharmacyPriorAuthStatus);
                if (message.specialtyPharmacyPriorAuthSubmissionMethod != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthSubmissionMethod"))
                    writer.uint32(/* id 181, wireType 0 =*/1448).int32(message.specialtyPharmacyPriorAuthSubmissionMethod);
                if (message.specialtyPharmacyPriorAuthSubmissionMethods != null && message.specialtyPharmacyPriorAuthSubmissionMethods.length) {
                    writer.uint32(/* id 182, wireType 2 =*/1458).fork();
                    for (var i = 0; i < message.specialtyPharmacyPriorAuthSubmissionMethods.length; ++i)
                        writer.int32(message.specialtyPharmacyPriorAuthSubmissionMethods[i]);
                    writer.ldelim();
                }
                if (message.specialtyPharmacyPriorAuthTurnaroundTime != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPriorAuthTurnaroundTime"))
                    writer.uint32(/* id 183, wireType 2 =*/1466).string(message.specialtyPharmacyPriorAuthTurnaroundTime);
                if (message.stepTherapyMethod != null && Object.hasOwnProperty.call(message, "stepTherapyMethod"))
                    writer.uint32(/* id 184, wireType 0 =*/1472).int32(message.stepTherapyMethod);
                if (message.stepTherapyRequired != null && Object.hasOwnProperty.call(message, "stepTherapyRequired"))
                    $root.google.protobuf.BoolValue.encode(message.stepTherapyRequired, writer.uint32(/* id 185, wireType 2 =*/1482).fork()).ldelim();
                if (message.stepTherapyTreatment != null && Object.hasOwnProperty.call(message, "stepTherapyTreatment"))
                    writer.uint32(/* id 186, wireType 2 =*/1490).string(message.stepTherapyTreatment);
                if (message.therapyAvailabilityDate != null && Object.hasOwnProperty.call(message, "therapyAvailabilityDate"))
                    writer.uint32(/* id 187, wireType 2 =*/1498).string(message.therapyAvailabilityDate);
                if (message.therapySeasonStartDate != null && Object.hasOwnProperty.call(message, "therapySeasonStartDate"))
                    writer.uint32(/* id 188, wireType 2 =*/1506).string(message.therapySeasonStartDate);
                if (message.timelyFilingPeriod != null && Object.hasOwnProperty.call(message, "timelyFilingPeriod"))
                    writer.uint32(/* id 189, wireType 2 =*/1514).string(message.timelyFilingPeriod);
                if (message.transportationServiceCovered != null && Object.hasOwnProperty.call(message, "transportationServiceCovered"))
                    $root.google.protobuf.BoolValue.encode(message.transportationServiceCovered, writer.uint32(/* id 190, wireType 2 =*/1522).fork()).ldelim();
                if (message.transportationServicePhoneNumber != null && Object.hasOwnProperty.call(message, "transportationServicePhoneNumber"))
                    writer.uint32(/* id 191, wireType 2 =*/1530).string(message.transportationServicePhoneNumber);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputAccessInfo message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputAccessInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputAccessInfo} message INFBVTaskOutputAccessInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputAccessInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputAccessInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputAccessInfo} INFBVTaskOutputAccessInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputAccessInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputAccessInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.attestationProcess = reader.string();
                            break;
                        }
                    case 3: {
                            message.attestationProcessExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.buyAndBillAvailability = reader.int32();
                            break;
                        }
                    case 5: {
                            message.claimsAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.coverageExceptionProcessExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.coverageExceptionTurnaroundTime = reader.string();
                            break;
                        }
                    case 8: {
                            message.coverageRestrictions = reader.string();
                            break;
                        }
                    case 9: {
                            message.cptObtainPreDeterminationOrg = reader.string();
                            break;
                        }
                    case 10: {
                            message.cptObtainPredeterminationFax = reader.string();
                            break;
                        }
                    case 11: {
                            message.cptObtainPredeterminationPhone = reader.string();
                            break;
                        }
                    case 12: {
                            message.cptObtainPredeterminationWebsite = reader.string();
                            break;
                        }
                    case 13: {
                            message.cptObtainPriorAuthAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.cptObtainPriorAuthFax = reader.string();
                            break;
                        }
                    case 15: {
                            message.cptObtainPriorAuthFormLink = reader.string();
                            break;
                        }
                    case 16: {
                            message.cptObtainPriorAuthOrg = reader.string();
                            break;
                        }
                    case 17: {
                            message.cptObtainPriorAuthPhone = reader.string();
                            break;
                        }
                    case 18: {
                            if (!(message.cptObtainPriorAuthRequirements && message.cptObtainPriorAuthRequirements.length))
                                message.cptObtainPriorAuthRequirements = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.cptObtainPriorAuthRequirements.push(reader.int32());
                            } else
                                message.cptObtainPriorAuthRequirements.push(reader.int32());
                            break;
                        }
                    case 19: {
                            message.cptObtainPriorAuthWebsite = reader.string();
                            break;
                        }
                    case 20: {
                            message.cptPredeterminationApprovedQuantity = reader.string();
                            break;
                        }
                    case 21: {
                            message.cptPredeterminationApprovedQuantityUsed = reader.string();
                            break;
                        }
                    case 22: {
                            message.cptPredeterminationEndDate = reader.string();
                            break;
                        }
                    case 23: {
                            message.cptPredeterminationNumber = reader.string();
                            break;
                        }
                    case 24: {
                            message.cptPredeterminationStartDate = reader.string();
                            break;
                        }
                    case 25: {
                            message.cptPredeterminationTurnaroundTime = reader.string();
                            break;
                        }
                    case 26: {
                            message.cptPriorAuthApprovalNumber = reader.string();
                            break;
                        }
                    case 27: {
                            message.cptPriorAuthApprovedQuantity = reader.string();
                            break;
                        }
                    case 28: {
                            message.cptPriorAuthApprovedQuantityUsed = reader.string();
                            break;
                        }
                    case 29: {
                            message.cptPriorAuthApprovedTreatments = reader.string();
                            break;
                        }
                    case 30: {
                            message.cptPriorAuthApprovedTreatmentsUsed = reader.string();
                            break;
                        }
                    case 31: {
                            message.cptPriorAuthEndDate = reader.string();
                            break;
                        }
                    case 32: {
                            message.cptPriorAuthNotificationMethod = reader.int32();
                            break;
                        }
                    case 33: {
                            if (!(message.cptPriorAuthNotificationMethods && message.cptPriorAuthNotificationMethods.length))
                                message.cptPriorAuthNotificationMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.cptPriorAuthNotificationMethods.push(reader.int32());
                            } else
                                message.cptPriorAuthNotificationMethods.push(reader.int32());
                            break;
                        }
                    case 34: {
                            message.cptPriorAuthProcessDifferent = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 35: {
                            message.cptPriorAuthStartDate = reader.string();
                            break;
                        }
                    case 36: {
                            message.cptPriorAuthSubmissionMethod = reader.int32();
                            break;
                        }
                    case 37: {
                            if (!(message.cptPriorAuthSubmissionMethods && message.cptPriorAuthSubmissionMethods.length))
                                message.cptPriorAuthSubmissionMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.cptPriorAuthSubmissionMethods.push(reader.int32());
                            } else
                                message.cptPriorAuthSubmissionMethods.push(reader.int32());
                            break;
                        }
                    case 38: {
                            message.cptPriorAuthTurnaroundTime = reader.string();
                            break;
                        }
                    case 39: {
                            message.hasSpecialtyPharmacyExclusions = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 40: {
                            message.homeHealthCovered = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 41: {
                            message.homeHealthObtainPriorAuthFax = reader.string();
                            break;
                        }
                    case 42: {
                            message.homeHealthObtainPriorAuthPhone = reader.string();
                            break;
                        }
                    case 43: {
                            message.homeHealthPriorAuthRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 44: {
                            message.homeHealthProviderName = reader.string();
                            break;
                        }
                    case 45: {
                            message.homeHealthProviderPhone = reader.string();
                            break;
                        }
                    case 46: {
                            message.homeHealthServices = reader.string();
                            break;
                        }
                    case 47: {
                            message.homeboundRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 48: {
                            message.lodgingCovered = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 49: {
                            message.lodgingServicePhoneNumber = reader.string();
                            break;
                        }
                    case 50: {
                            message.medicalGroupName = reader.string();
                            break;
                        }
                    case 51: {
                            message.medicalGroupPhoneNumber = reader.string();
                            break;
                        }
                    case 52: {
                            message.medicalPolicyAvailableOnWebsite = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 53: {
                            message.medicalPolicyNumber = reader.string();
                            break;
                        }
                    case 54: {
                            message.obtainCoverageExceptionAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 55: {
                            message.obtainCoverageExceptionFax = reader.string();
                            break;
                        }
                    case 56: {
                            message.obtainCoverageExceptionPhone = reader.string();
                            break;
                        }
                    case 57: {
                            message.obtainCoverageExceptionProcess = reader.string();
                            break;
                        }
                    case 58: {
                            message.obtainCoverageExceptionWebsite = reader.string();
                            break;
                        }
                    case 59: {
                            message.obtainPreDeterminationOrg = reader.string();
                            break;
                        }
                    case 60: {
                            message.obtainPredeterminationFax = reader.string();
                            break;
                        }
                    case 61: {
                            message.obtainPredeterminationPhone = reader.string();
                            break;
                        }
                    case 62: {
                            message.obtainPredeterminationWebsite = reader.string();
                            break;
                        }
                    case 63: {
                            message.obtainPriorAuthAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 64: {
                            message.obtainPriorAuthFax = reader.string();
                            break;
                        }
                    case 65: {
                            message.obtainPriorAuthFormLink = reader.string();
                            break;
                        }
                    case 66: {
                            message.obtainPriorAuthOrg = reader.string();
                            break;
                        }
                    case 67: {
                            message.obtainPriorAuthPhone = reader.string();
                            break;
                        }
                    case 68: {
                            if (!(message.obtainPriorAuthRequirements && message.obtainPriorAuthRequirements.length))
                                message.obtainPriorAuthRequirements = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.obtainPriorAuthRequirements.push(reader.int32());
                            } else
                                message.obtainPriorAuthRequirements.push(reader.int32());
                            break;
                        }
                    case 69: {
                            message.obtainPriorAuthWebsite = reader.string();
                            break;
                        }
                    case 70: {
                            message.obtainQuantityLimitExceptionPhone = reader.string();
                            break;
                        }
                    case 71: {
                            message.obtainQuantityLimitExceptionProcess = reader.string();
                            break;
                        }
                    case 72: {
                            message.pcpName = reader.string();
                            break;
                        }
                    case 73: {
                            message.pcpPhone = reader.string();
                            break;
                        }
                    case 74: {
                            message.preDeterminationAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 75: {
                            message.preDeterminationNumber = reader.string();
                            break;
                        }
                    case 76: {
                            message.preDeterminationOnFile = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 77: {
                            message.preDeterminationRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 78: {
                            message.preDeterminationRequirement = reader.int32();
                            break;
                        }
                    case 79: {
                            message.predeterminationAddressOnFile = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 80: {
                            message.predeterminationApprovedQuantity = reader.string();
                            break;
                        }
                    case 81: {
                            message.predeterminationApprovedQuantityUsed = reader.string();
                            break;
                        }
                    case 82: {
                            message.predeterminationCompletionDate = reader.string();
                            break;
                        }
                    case 83: {
                            message.predeterminationEndDate = reader.string();
                            break;
                        }
                    case 84: {
                            message.predeterminationOnFileApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 85: {
                            message.predeterminationProviderNameOnFile = reader.string();
                            break;
                        }
                    case 86: {
                            message.predeterminationReceivedDate = reader.string();
                            break;
                        }
                    case 87: {
                            message.predeterminationRenewalProcessExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 88: {
                            message.predeterminationStartDate = reader.string();
                            break;
                        }
                    case 89: {
                            message.predeterminationStatus = reader.int32();
                            break;
                        }
                    case 90: {
                            message.predeterminationSubmissionDate = reader.string();
                            break;
                        }
                    case 91: {
                            message.predeterminationTurnaroundTime = reader.string();
                            break;
                        }
                    case 92: {
                            if (!(message.preferredSpecialtyPharmaciesAvailable && message.preferredSpecialtyPharmaciesAvailable.length))
                                message.preferredSpecialtyPharmaciesAvailable = [];
                            message.preferredSpecialtyPharmaciesAvailable.push($root.infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy.decode(reader, reader.uint32()));
                            break;
                        }
                    case 93: {
                            message.priorAuthAddressOnFile = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 94: {
                            message.priorAuthApprovalDate = reader.string();
                            break;
                        }
                    case 95: {
                            message.priorAuthApprovalNumber = reader.string();
                            break;
                        }
                    case 96: {
                            message.priorAuthApprovedQuantity = reader.string();
                            break;
                        }
                    case 97: {
                            message.priorAuthApprovedQuantityUsed = reader.string();
                            break;
                        }
                    case 98: {
                            message.priorAuthApprovedTreatments = reader.string();
                            break;
                        }
                    case 99: {
                            message.priorAuthApprovedTreatmentsUsed = reader.string();
                            break;
                        }
                    case 100: {
                            if (!(message.priorAuthCodesOnFile && message.priorAuthCodesOnFile.length))
                                message.priorAuthCodesOnFile = [];
                            message.priorAuthCodesOnFile.push(reader.string());
                            break;
                        }
                    case 101: {
                            message.priorAuthCompletionDate = reader.string();
                            break;
                        }
                    case 102: {
                            message.priorAuthEndDate = reader.string();
                            break;
                        }
                    case 103: {
                            message.priorAuthExceptionReason = reader.string();
                            break;
                        }
                    case 104: {
                            message.priorAuthInitiationDate = reader.string();
                            break;
                        }
                    case 105: {
                            message.priorAuthNotificationMethod = reader.int32();
                            break;
                        }
                    case 106: {
                            if (!(message.priorAuthNotificationMethods && message.priorAuthNotificationMethods.length))
                                message.priorAuthNotificationMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.priorAuthNotificationMethods.push(reader.int32());
                            } else
                                message.priorAuthNotificationMethods.push(reader.int32());
                            break;
                        }
                    case 107: {
                            message.priorAuthOnFile = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 108: {
                            message.priorAuthOnFileApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 109: {
                            message.priorAuthProviderNameOnFile = reader.string();
                            break;
                        }
                    case 110: {
                            message.priorAuthRenewalProcessExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 111: {
                            message.priorAuthRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 112: {
                            if (!(message.priorAuthRequiredCodes && message.priorAuthRequiredCodes.length))
                                message.priorAuthRequiredCodes = [];
                            message.priorAuthRequiredCodes.push(reader.string());
                            break;
                        }
                    case 113: {
                            message.priorAuthResponsibleOrg = reader.int32();
                            break;
                        }
                    case 114: {
                            message.priorAuthStartDate = reader.string();
                            break;
                        }
                    case 115: {
                            message.priorAuthStatus = reader.int32();
                            break;
                        }
                    case 116: {
                            message.priorAuthSubmissionDate = reader.string();
                            break;
                        }
                    case 117: {
                            message.priorAuthSubmissionMethod = reader.int32();
                            break;
                        }
                    case 118: {
                            if (!(message.priorAuthSubmissionMethods && message.priorAuthSubmissionMethods.length))
                                message.priorAuthSubmissionMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.priorAuthSubmissionMethods.push(reader.int32());
                            } else
                                message.priorAuthSubmissionMethods.push(reader.int32());
                            break;
                        }
                    case 119: {
                            message.priorAuthTurnaroundTime = reader.string();
                            break;
                        }
                    case 120: {
                            message.priorAuthType = reader.int32();
                            break;
                        }
                    case 121: {
                            message.quantityLimit = reader.string();
                            break;
                        }
                    case 122: {
                            message.referralAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 123: {
                            message.referralEffectiveDate = reader.string();
                            break;
                        }
                    case 124: {
                            message.referralFax = reader.string();
                            break;
                        }
                    case 125: {
                            message.referralNumVisitsApproved = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 126: {
                            message.referralNumVisitsUsed = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 127: {
                            message.referralNumber = reader.string();
                            break;
                        }
                    case 128: {
                            message.referralOnFile = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 129: {
                            message.referralRecertDate = reader.string();
                            break;
                        }
                    case 130: {
                            message.referralRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 131: {
                            message.referralRequirements = reader.int32();
                            break;
                        }
                    case 132: {
                            message.referralSubmissionProcess = reader.string();
                            break;
                        }
                    case 133: {
                            message.referralWebsite = reader.string();
                            break;
                        }
                    case 134: {
                            message.specialtyPharmacy2Address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 135: {
                            message.specialtyPharmacy2Fax = reader.string();
                            break;
                        }
                    case 136: {
                            message.specialtyPharmacy2Name = reader.string();
                            break;
                        }
                    case 137: {
                            message.specialtyPharmacy2Phone = reader.string();
                            break;
                        }
                    case 138: {
                            message.specialtyPharmacy3Address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 139: {
                            message.specialtyPharmacy3Fax = reader.string();
                            break;
                        }
                    case 140: {
                            message.specialtyPharmacy3Name = reader.string();
                            break;
                        }
                    case 141: {
                            message.specialtyPharmacy3Phone = reader.string();
                            break;
                        }
                    case 142: {
                            message.specialtyPharmacyAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 143: {
                            message.specialtyPharmacyAvailability = reader.int32();
                            break;
                        }
                    case 144: {
                            message.specialtyPharmacyExclusions = reader.string();
                            break;
                        }
                    case 145: {
                            message.specialtyPharmacyFax = reader.string();
                            break;
                        }
                    case 146: {
                            message.specialtyPharmacyName = reader.string();
                            break;
                        }
                    case 147: {
                            message.specialtyPharmacyNetwork = reader.int32();
                            break;
                        }
                    case 148: {
                            message.specialtyPharmacyObtainPreDeterminationOrg = reader.string();
                            break;
                        }
                    case 149: {
                            message.specialtyPharmacyObtainPredeterminationFax = reader.string();
                            break;
                        }
                    case 150: {
                            message.specialtyPharmacyObtainPredeterminationPhone = reader.string();
                            break;
                        }
                    case 151: {
                            message.specialtyPharmacyObtainPredeterminationWebsite = reader.string();
                            break;
                        }
                    case 152: {
                            message.specialtyPharmacyObtainPriorAuthAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 153: {
                            message.specialtyPharmacyObtainPriorAuthFax = reader.string();
                            break;
                        }
                    case 154: {
                            message.specialtyPharmacyObtainPriorAuthFormLink = reader.string();
                            break;
                        }
                    case 155: {
                            message.specialtyPharmacyObtainPriorAuthOrg = reader.string();
                            break;
                        }
                    case 156: {
                            message.specialtyPharmacyObtainPriorAuthPhone = reader.string();
                            break;
                        }
                    case 157: {
                            if (!(message.specialtyPharmacyObtainPriorAuthRequirements && message.specialtyPharmacyObtainPriorAuthRequirements.length))
                                message.specialtyPharmacyObtainPriorAuthRequirements = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.specialtyPharmacyObtainPriorAuthRequirements.push(reader.int32());
                            } else
                                message.specialtyPharmacyObtainPriorAuthRequirements.push(reader.int32());
                            break;
                        }
                    case 158: {
                            message.specialtyPharmacyObtainPriorAuthWebsite = reader.string();
                            break;
                        }
                    case 159: {
                            message.specialtyPharmacyPhone = reader.string();
                            break;
                        }
                    case 160: {
                            message.specialtyPharmacyPredeterminationApprovedQuantity = reader.string();
                            break;
                        }
                    case 161: {
                            message.specialtyPharmacyPredeterminationApprovedQuantityUsed = reader.string();
                            break;
                        }
                    case 162: {
                            message.specialtyPharmacyPredeterminationEndDate = reader.string();
                            break;
                        }
                    case 163: {
                            message.specialtyPharmacyPredeterminationNumber = reader.string();
                            break;
                        }
                    case 164: {
                            message.specialtyPharmacyPredeterminationOnFile = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 165: {
                            message.specialtyPharmacyPredeterminationRequirementType = reader.int32();
                            break;
                        }
                    case 166: {
                            message.specialtyPharmacyPredeterminationStartDate = reader.string();
                            break;
                        }
                    case 167: {
                            message.specialtyPharmacyPredeterminationStatus = reader.int32();
                            break;
                        }
                    case 168: {
                            message.specialtyPharmacyPredeterminationTurnaroundTime = reader.string();
                            break;
                        }
                    case 169: {
                            message.specialtyPharmacyPriorAuthApprovalNumber = reader.string();
                            break;
                        }
                    case 170: {
                            message.specialtyPharmacyPriorAuthApprovedQuantity = reader.string();
                            break;
                        }
                    case 171: {
                            message.specialtyPharmacyPriorAuthApprovedQuantityUsed = reader.string();
                            break;
                        }
                    case 172: {
                            message.specialtyPharmacyPriorAuthApprovedTreatments = reader.string();
                            break;
                        }
                    case 173: {
                            message.specialtyPharmacyPriorAuthApprovedTreatmentsUsed = reader.string();
                            break;
                        }
                    case 174: {
                            message.specialtyPharmacyPriorAuthEndDate = reader.string();
                            break;
                        }
                    case 175: {
                            message.specialtyPharmacyPriorAuthNotificationMethod = reader.int32();
                            break;
                        }
                    case 176: {
                            if (!(message.specialtyPharmacyPriorAuthNotificationMethods && message.specialtyPharmacyPriorAuthNotificationMethods.length))
                                message.specialtyPharmacyPriorAuthNotificationMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.specialtyPharmacyPriorAuthNotificationMethods.push(reader.int32());
                            } else
                                message.specialtyPharmacyPriorAuthNotificationMethods.push(reader.int32());
                            break;
                        }
                    case 177: {
                            message.specialtyPharmacyPriorAuthProcessDifferent = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 178: {
                            message.specialtyPharmacyPriorAuthRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 179: {
                            message.specialtyPharmacyPriorAuthStartDate = reader.string();
                            break;
                        }
                    case 180: {
                            message.specialtyPharmacyPriorAuthStatus = reader.int32();
                            break;
                        }
                    case 181: {
                            message.specialtyPharmacyPriorAuthSubmissionMethod = reader.int32();
                            break;
                        }
                    case 182: {
                            if (!(message.specialtyPharmacyPriorAuthSubmissionMethods && message.specialtyPharmacyPriorAuthSubmissionMethods.length))
                                message.specialtyPharmacyPriorAuthSubmissionMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.specialtyPharmacyPriorAuthSubmissionMethods.push(reader.int32());
                            } else
                                message.specialtyPharmacyPriorAuthSubmissionMethods.push(reader.int32());
                            break;
                        }
                    case 183: {
                            message.specialtyPharmacyPriorAuthTurnaroundTime = reader.string();
                            break;
                        }
                    case 184: {
                            message.stepTherapyMethod = reader.int32();
                            break;
                        }
                    case 185: {
                            message.stepTherapyRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 186: {
                            message.stepTherapyTreatment = reader.string();
                            break;
                        }
                    case 187: {
                            message.therapyAvailabilityDate = reader.string();
                            break;
                        }
                    case 188: {
                            message.therapySeasonStartDate = reader.string();
                            break;
                        }
                    case 189: {
                            message.timelyFilingPeriod = reader.string();
                            break;
                        }
                    case 190: {
                            message.transportationServiceCovered = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 191: {
                            message.transportationServicePhoneNumber = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputAccessInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputAccessInfo} INFBVTaskOutputAccessInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputAccessInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputAccessInfo message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputAccessInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.attestationProcess != null && message.hasOwnProperty("attestationProcess"))
                    if (!$util.isString(message.attestationProcess))
                        return "attestationProcess: string expected";
                if (message.attestationProcessExists != null && message.hasOwnProperty("attestationProcessExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.attestationProcessExists);
                    if (error)
                        return "attestationProcessExists." + error;
                }
                if (message.buyAndBillAvailability != null && message.hasOwnProperty("buyAndBillAvailability"))
                    switch (message.buyAndBillAvailability) {
                    default:
                        return "buyAndBillAvailability: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.claimsAddress != null && message.hasOwnProperty("claimsAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.claimsAddress);
                    if (error)
                        return "claimsAddress." + error;
                }
                if (message.coverageExceptionProcessExists != null && message.hasOwnProperty("coverageExceptionProcessExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.coverageExceptionProcessExists);
                    if (error)
                        return "coverageExceptionProcessExists." + error;
                }
                if (message.coverageExceptionTurnaroundTime != null && message.hasOwnProperty("coverageExceptionTurnaroundTime"))
                    if (!$util.isString(message.coverageExceptionTurnaroundTime))
                        return "coverageExceptionTurnaroundTime: string expected";
                if (message.coverageRestrictions != null && message.hasOwnProperty("coverageRestrictions"))
                    if (!$util.isString(message.coverageRestrictions))
                        return "coverageRestrictions: string expected";
                if (message.cptObtainPreDeterminationOrg != null && message.hasOwnProperty("cptObtainPreDeterminationOrg"))
                    if (!$util.isString(message.cptObtainPreDeterminationOrg))
                        return "cptObtainPreDeterminationOrg: string expected";
                if (message.cptObtainPredeterminationFax != null && message.hasOwnProperty("cptObtainPredeterminationFax"))
                    if (!$util.isString(message.cptObtainPredeterminationFax))
                        return "cptObtainPredeterminationFax: string expected";
                if (message.cptObtainPredeterminationPhone != null && message.hasOwnProperty("cptObtainPredeterminationPhone"))
                    if (!$util.isString(message.cptObtainPredeterminationPhone))
                        return "cptObtainPredeterminationPhone: string expected";
                if (message.cptObtainPredeterminationWebsite != null && message.hasOwnProperty("cptObtainPredeterminationWebsite"))
                    if (!$util.isString(message.cptObtainPredeterminationWebsite))
                        return "cptObtainPredeterminationWebsite: string expected";
                if (message.cptObtainPriorAuthAddress != null && message.hasOwnProperty("cptObtainPriorAuthAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.cptObtainPriorAuthAddress);
                    if (error)
                        return "cptObtainPriorAuthAddress." + error;
                }
                if (message.cptObtainPriorAuthFax != null && message.hasOwnProperty("cptObtainPriorAuthFax"))
                    if (!$util.isString(message.cptObtainPriorAuthFax))
                        return "cptObtainPriorAuthFax: string expected";
                if (message.cptObtainPriorAuthFormLink != null && message.hasOwnProperty("cptObtainPriorAuthFormLink"))
                    if (!$util.isString(message.cptObtainPriorAuthFormLink))
                        return "cptObtainPriorAuthFormLink: string expected";
                if (message.cptObtainPriorAuthOrg != null && message.hasOwnProperty("cptObtainPriorAuthOrg"))
                    if (!$util.isString(message.cptObtainPriorAuthOrg))
                        return "cptObtainPriorAuthOrg: string expected";
                if (message.cptObtainPriorAuthPhone != null && message.hasOwnProperty("cptObtainPriorAuthPhone"))
                    if (!$util.isString(message.cptObtainPriorAuthPhone))
                        return "cptObtainPriorAuthPhone: string expected";
                if (message.cptObtainPriorAuthRequirements != null && message.hasOwnProperty("cptObtainPriorAuthRequirements")) {
                    if (!Array.isArray(message.cptObtainPriorAuthRequirements))
                        return "cptObtainPriorAuthRequirements: array expected";
                    for (var i = 0; i < message.cptObtainPriorAuthRequirements.length; ++i)
                        switch (message.cptObtainPriorAuthRequirements[i]) {
                        default:
                            return "cptObtainPriorAuthRequirements: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.cptObtainPriorAuthWebsite != null && message.hasOwnProperty("cptObtainPriorAuthWebsite"))
                    if (!$util.isString(message.cptObtainPriorAuthWebsite))
                        return "cptObtainPriorAuthWebsite: string expected";
                if (message.cptPredeterminationApprovedQuantity != null && message.hasOwnProperty("cptPredeterminationApprovedQuantity"))
                    if (!$util.isString(message.cptPredeterminationApprovedQuantity))
                        return "cptPredeterminationApprovedQuantity: string expected";
                if (message.cptPredeterminationApprovedQuantityUsed != null && message.hasOwnProperty("cptPredeterminationApprovedQuantityUsed"))
                    if (!$util.isString(message.cptPredeterminationApprovedQuantityUsed))
                        return "cptPredeterminationApprovedQuantityUsed: string expected";
                if (message.cptPredeterminationEndDate != null && message.hasOwnProperty("cptPredeterminationEndDate"))
                    if (!$util.isString(message.cptPredeterminationEndDate))
                        return "cptPredeterminationEndDate: string expected";
                if (message.cptPredeterminationNumber != null && message.hasOwnProperty("cptPredeterminationNumber"))
                    if (!$util.isString(message.cptPredeterminationNumber))
                        return "cptPredeterminationNumber: string expected";
                if (message.cptPredeterminationStartDate != null && message.hasOwnProperty("cptPredeterminationStartDate"))
                    if (!$util.isString(message.cptPredeterminationStartDate))
                        return "cptPredeterminationStartDate: string expected";
                if (message.cptPredeterminationTurnaroundTime != null && message.hasOwnProperty("cptPredeterminationTurnaroundTime"))
                    if (!$util.isString(message.cptPredeterminationTurnaroundTime))
                        return "cptPredeterminationTurnaroundTime: string expected";
                if (message.cptPriorAuthApprovalNumber != null && message.hasOwnProperty("cptPriorAuthApprovalNumber"))
                    if (!$util.isString(message.cptPriorAuthApprovalNumber))
                        return "cptPriorAuthApprovalNumber: string expected";
                if (message.cptPriorAuthApprovedQuantity != null && message.hasOwnProperty("cptPriorAuthApprovedQuantity"))
                    if (!$util.isString(message.cptPriorAuthApprovedQuantity))
                        return "cptPriorAuthApprovedQuantity: string expected";
                if (message.cptPriorAuthApprovedQuantityUsed != null && message.hasOwnProperty("cptPriorAuthApprovedQuantityUsed"))
                    if (!$util.isString(message.cptPriorAuthApprovedQuantityUsed))
                        return "cptPriorAuthApprovedQuantityUsed: string expected";
                if (message.cptPriorAuthApprovedTreatments != null && message.hasOwnProperty("cptPriorAuthApprovedTreatments"))
                    if (!$util.isString(message.cptPriorAuthApprovedTreatments))
                        return "cptPriorAuthApprovedTreatments: string expected";
                if (message.cptPriorAuthApprovedTreatmentsUsed != null && message.hasOwnProperty("cptPriorAuthApprovedTreatmentsUsed"))
                    if (!$util.isString(message.cptPriorAuthApprovedTreatmentsUsed))
                        return "cptPriorAuthApprovedTreatmentsUsed: string expected";
                if (message.cptPriorAuthEndDate != null && message.hasOwnProperty("cptPriorAuthEndDate"))
                    if (!$util.isString(message.cptPriorAuthEndDate))
                        return "cptPriorAuthEndDate: string expected";
                if (message.cptPriorAuthNotificationMethod != null && message.hasOwnProperty("cptPriorAuthNotificationMethod"))
                    switch (message.cptPriorAuthNotificationMethod) {
                    default:
                        return "cptPriorAuthNotificationMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.cptPriorAuthNotificationMethods != null && message.hasOwnProperty("cptPriorAuthNotificationMethods")) {
                    if (!Array.isArray(message.cptPriorAuthNotificationMethods))
                        return "cptPriorAuthNotificationMethods: array expected";
                    for (var i = 0; i < message.cptPriorAuthNotificationMethods.length; ++i)
                        switch (message.cptPriorAuthNotificationMethods[i]) {
                        default:
                            return "cptPriorAuthNotificationMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.cptPriorAuthProcessDifferent != null && message.hasOwnProperty("cptPriorAuthProcessDifferent")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.cptPriorAuthProcessDifferent);
                    if (error)
                        return "cptPriorAuthProcessDifferent." + error;
                }
                if (message.cptPriorAuthStartDate != null && message.hasOwnProperty("cptPriorAuthStartDate"))
                    if (!$util.isString(message.cptPriorAuthStartDate))
                        return "cptPriorAuthStartDate: string expected";
                if (message.cptPriorAuthSubmissionMethod != null && message.hasOwnProperty("cptPriorAuthSubmissionMethod"))
                    switch (message.cptPriorAuthSubmissionMethod) {
                    default:
                        return "cptPriorAuthSubmissionMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.cptPriorAuthSubmissionMethods != null && message.hasOwnProperty("cptPriorAuthSubmissionMethods")) {
                    if (!Array.isArray(message.cptPriorAuthSubmissionMethods))
                        return "cptPriorAuthSubmissionMethods: array expected";
                    for (var i = 0; i < message.cptPriorAuthSubmissionMethods.length; ++i)
                        switch (message.cptPriorAuthSubmissionMethods[i]) {
                        default:
                            return "cptPriorAuthSubmissionMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                }
                if (message.cptPriorAuthTurnaroundTime != null && message.hasOwnProperty("cptPriorAuthTurnaroundTime"))
                    if (!$util.isString(message.cptPriorAuthTurnaroundTime))
                        return "cptPriorAuthTurnaroundTime: string expected";
                if (message.hasSpecialtyPharmacyExclusions != null && message.hasOwnProperty("hasSpecialtyPharmacyExclusions")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.hasSpecialtyPharmacyExclusions);
                    if (error)
                        return "hasSpecialtyPharmacyExclusions." + error;
                }
                if (message.homeHealthCovered != null && message.hasOwnProperty("homeHealthCovered")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.homeHealthCovered);
                    if (error)
                        return "homeHealthCovered." + error;
                }
                if (message.homeHealthObtainPriorAuthFax != null && message.hasOwnProperty("homeHealthObtainPriorAuthFax"))
                    if (!$util.isString(message.homeHealthObtainPriorAuthFax))
                        return "homeHealthObtainPriorAuthFax: string expected";
                if (message.homeHealthObtainPriorAuthPhone != null && message.hasOwnProperty("homeHealthObtainPriorAuthPhone"))
                    if (!$util.isString(message.homeHealthObtainPriorAuthPhone))
                        return "homeHealthObtainPriorAuthPhone: string expected";
                if (message.homeHealthPriorAuthRequired != null && message.hasOwnProperty("homeHealthPriorAuthRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.homeHealthPriorAuthRequired);
                    if (error)
                        return "homeHealthPriorAuthRequired." + error;
                }
                if (message.homeHealthProviderName != null && message.hasOwnProperty("homeHealthProviderName"))
                    if (!$util.isString(message.homeHealthProviderName))
                        return "homeHealthProviderName: string expected";
                if (message.homeHealthProviderPhone != null && message.hasOwnProperty("homeHealthProviderPhone"))
                    if (!$util.isString(message.homeHealthProviderPhone))
                        return "homeHealthProviderPhone: string expected";
                if (message.homeHealthServices != null && message.hasOwnProperty("homeHealthServices"))
                    if (!$util.isString(message.homeHealthServices))
                        return "homeHealthServices: string expected";
                if (message.homeboundRequired != null && message.hasOwnProperty("homeboundRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.homeboundRequired);
                    if (error)
                        return "homeboundRequired." + error;
                }
                if (message.lodgingCovered != null && message.hasOwnProperty("lodgingCovered")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.lodgingCovered);
                    if (error)
                        return "lodgingCovered." + error;
                }
                if (message.lodgingServicePhoneNumber != null && message.hasOwnProperty("lodgingServicePhoneNumber"))
                    if (!$util.isString(message.lodgingServicePhoneNumber))
                        return "lodgingServicePhoneNumber: string expected";
                if (message.medicalGroupName != null && message.hasOwnProperty("medicalGroupName"))
                    if (!$util.isString(message.medicalGroupName))
                        return "medicalGroupName: string expected";
                if (message.medicalGroupPhoneNumber != null && message.hasOwnProperty("medicalGroupPhoneNumber"))
                    if (!$util.isString(message.medicalGroupPhoneNumber))
                        return "medicalGroupPhoneNumber: string expected";
                if (message.medicalPolicyAvailableOnWebsite != null && message.hasOwnProperty("medicalPolicyAvailableOnWebsite")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.medicalPolicyAvailableOnWebsite);
                    if (error)
                        return "medicalPolicyAvailableOnWebsite." + error;
                }
                if (message.medicalPolicyNumber != null && message.hasOwnProperty("medicalPolicyNumber"))
                    if (!$util.isString(message.medicalPolicyNumber))
                        return "medicalPolicyNumber: string expected";
                if (message.obtainCoverageExceptionAddress != null && message.hasOwnProperty("obtainCoverageExceptionAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.obtainCoverageExceptionAddress);
                    if (error)
                        return "obtainCoverageExceptionAddress." + error;
                }
                if (message.obtainCoverageExceptionFax != null && message.hasOwnProperty("obtainCoverageExceptionFax"))
                    if (!$util.isString(message.obtainCoverageExceptionFax))
                        return "obtainCoverageExceptionFax: string expected";
                if (message.obtainCoverageExceptionPhone != null && message.hasOwnProperty("obtainCoverageExceptionPhone"))
                    if (!$util.isString(message.obtainCoverageExceptionPhone))
                        return "obtainCoverageExceptionPhone: string expected";
                if (message.obtainCoverageExceptionProcess != null && message.hasOwnProperty("obtainCoverageExceptionProcess"))
                    if (!$util.isString(message.obtainCoverageExceptionProcess))
                        return "obtainCoverageExceptionProcess: string expected";
                if (message.obtainCoverageExceptionWebsite != null && message.hasOwnProperty("obtainCoverageExceptionWebsite"))
                    if (!$util.isString(message.obtainCoverageExceptionWebsite))
                        return "obtainCoverageExceptionWebsite: string expected";
                if (message.obtainPreDeterminationOrg != null && message.hasOwnProperty("obtainPreDeterminationOrg"))
                    if (!$util.isString(message.obtainPreDeterminationOrg))
                        return "obtainPreDeterminationOrg: string expected";
                if (message.obtainPredeterminationFax != null && message.hasOwnProperty("obtainPredeterminationFax"))
                    if (!$util.isString(message.obtainPredeterminationFax))
                        return "obtainPredeterminationFax: string expected";
                if (message.obtainPredeterminationPhone != null && message.hasOwnProperty("obtainPredeterminationPhone"))
                    if (!$util.isString(message.obtainPredeterminationPhone))
                        return "obtainPredeterminationPhone: string expected";
                if (message.obtainPredeterminationWebsite != null && message.hasOwnProperty("obtainPredeterminationWebsite"))
                    if (!$util.isString(message.obtainPredeterminationWebsite))
                        return "obtainPredeterminationWebsite: string expected";
                if (message.obtainPriorAuthAddress != null && message.hasOwnProperty("obtainPriorAuthAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.obtainPriorAuthAddress);
                    if (error)
                        return "obtainPriorAuthAddress." + error;
                }
                if (message.obtainPriorAuthFax != null && message.hasOwnProperty("obtainPriorAuthFax"))
                    if (!$util.isString(message.obtainPriorAuthFax))
                        return "obtainPriorAuthFax: string expected";
                if (message.obtainPriorAuthFormLink != null && message.hasOwnProperty("obtainPriorAuthFormLink"))
                    if (!$util.isString(message.obtainPriorAuthFormLink))
                        return "obtainPriorAuthFormLink: string expected";
                if (message.obtainPriorAuthOrg != null && message.hasOwnProperty("obtainPriorAuthOrg"))
                    if (!$util.isString(message.obtainPriorAuthOrg))
                        return "obtainPriorAuthOrg: string expected";
                if (message.obtainPriorAuthPhone != null && message.hasOwnProperty("obtainPriorAuthPhone"))
                    if (!$util.isString(message.obtainPriorAuthPhone))
                        return "obtainPriorAuthPhone: string expected";
                if (message.obtainPriorAuthRequirements != null && message.hasOwnProperty("obtainPriorAuthRequirements")) {
                    if (!Array.isArray(message.obtainPriorAuthRequirements))
                        return "obtainPriorAuthRequirements: array expected";
                    for (var i = 0; i < message.obtainPriorAuthRequirements.length; ++i)
                        switch (message.obtainPriorAuthRequirements[i]) {
                        default:
                            return "obtainPriorAuthRequirements: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.obtainPriorAuthWebsite != null && message.hasOwnProperty("obtainPriorAuthWebsite"))
                    if (!$util.isString(message.obtainPriorAuthWebsite))
                        return "obtainPriorAuthWebsite: string expected";
                if (message.obtainQuantityLimitExceptionPhone != null && message.hasOwnProperty("obtainQuantityLimitExceptionPhone"))
                    if (!$util.isString(message.obtainQuantityLimitExceptionPhone))
                        return "obtainQuantityLimitExceptionPhone: string expected";
                if (message.obtainQuantityLimitExceptionProcess != null && message.hasOwnProperty("obtainQuantityLimitExceptionProcess"))
                    if (!$util.isString(message.obtainQuantityLimitExceptionProcess))
                        return "obtainQuantityLimitExceptionProcess: string expected";
                if (message.pcpName != null && message.hasOwnProperty("pcpName"))
                    if (!$util.isString(message.pcpName))
                        return "pcpName: string expected";
                if (message.pcpPhone != null && message.hasOwnProperty("pcpPhone"))
                    if (!$util.isString(message.pcpPhone))
                        return "pcpPhone: string expected";
                if (message.preDeterminationAvailable != null && message.hasOwnProperty("preDeterminationAvailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.preDeterminationAvailable);
                    if (error)
                        return "preDeterminationAvailable." + error;
                }
                if (message.preDeterminationNumber != null && message.hasOwnProperty("preDeterminationNumber"))
                    if (!$util.isString(message.preDeterminationNumber))
                        return "preDeterminationNumber: string expected";
                if (message.preDeterminationOnFile != null && message.hasOwnProperty("preDeterminationOnFile")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.preDeterminationOnFile);
                    if (error)
                        return "preDeterminationOnFile." + error;
                }
                if (message.preDeterminationRequired != null && message.hasOwnProperty("preDeterminationRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.preDeterminationRequired);
                    if (error)
                        return "preDeterminationRequired." + error;
                }
                if (message.preDeterminationRequirement != null && message.hasOwnProperty("preDeterminationRequirement"))
                    switch (message.preDeterminationRequirement) {
                    default:
                        return "preDeterminationRequirement: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.predeterminationAddressOnFile != null && message.hasOwnProperty("predeterminationAddressOnFile")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.predeterminationAddressOnFile);
                    if (error)
                        return "predeterminationAddressOnFile." + error;
                }
                if (message.predeterminationApprovedQuantity != null && message.hasOwnProperty("predeterminationApprovedQuantity"))
                    if (!$util.isString(message.predeterminationApprovedQuantity))
                        return "predeterminationApprovedQuantity: string expected";
                if (message.predeterminationApprovedQuantityUsed != null && message.hasOwnProperty("predeterminationApprovedQuantityUsed"))
                    if (!$util.isString(message.predeterminationApprovedQuantityUsed))
                        return "predeterminationApprovedQuantityUsed: string expected";
                if (message.predeterminationCompletionDate != null && message.hasOwnProperty("predeterminationCompletionDate"))
                    if (!$util.isString(message.predeterminationCompletionDate))
                        return "predeterminationCompletionDate: string expected";
                if (message.predeterminationEndDate != null && message.hasOwnProperty("predeterminationEndDate"))
                    if (!$util.isString(message.predeterminationEndDate))
                        return "predeterminationEndDate: string expected";
                if (message.predeterminationOnFileApplies != null && message.hasOwnProperty("predeterminationOnFileApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.predeterminationOnFileApplies);
                    if (error)
                        return "predeterminationOnFileApplies." + error;
                }
                if (message.predeterminationProviderNameOnFile != null && message.hasOwnProperty("predeterminationProviderNameOnFile"))
                    if (!$util.isString(message.predeterminationProviderNameOnFile))
                        return "predeterminationProviderNameOnFile: string expected";
                if (message.predeterminationReceivedDate != null && message.hasOwnProperty("predeterminationReceivedDate"))
                    if (!$util.isString(message.predeterminationReceivedDate))
                        return "predeterminationReceivedDate: string expected";
                if (message.predeterminationRenewalProcessExists != null && message.hasOwnProperty("predeterminationRenewalProcessExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.predeterminationRenewalProcessExists);
                    if (error)
                        return "predeterminationRenewalProcessExists." + error;
                }
                if (message.predeterminationStartDate != null && message.hasOwnProperty("predeterminationStartDate"))
                    if (!$util.isString(message.predeterminationStartDate))
                        return "predeterminationStartDate: string expected";
                if (message.predeterminationStatus != null && message.hasOwnProperty("predeterminationStatus"))
                    switch (message.predeterminationStatus) {
                    default:
                        return "predeterminationStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                if (message.predeterminationSubmissionDate != null && message.hasOwnProperty("predeterminationSubmissionDate"))
                    if (!$util.isString(message.predeterminationSubmissionDate))
                        return "predeterminationSubmissionDate: string expected";
                if (message.predeterminationTurnaroundTime != null && message.hasOwnProperty("predeterminationTurnaroundTime"))
                    if (!$util.isString(message.predeterminationTurnaroundTime))
                        return "predeterminationTurnaroundTime: string expected";
                if (message.preferredSpecialtyPharmaciesAvailable != null && message.hasOwnProperty("preferredSpecialtyPharmaciesAvailable")) {
                    if (!Array.isArray(message.preferredSpecialtyPharmaciesAvailable))
                        return "preferredSpecialtyPharmaciesAvailable: array expected";
                    for (var i = 0; i < message.preferredSpecialtyPharmaciesAvailable.length; ++i) {
                        var error = $root.infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy.verify(message.preferredSpecialtyPharmaciesAvailable[i]);
                        if (error)
                            return "preferredSpecialtyPharmaciesAvailable." + error;
                    }
                }
                if (message.priorAuthAddressOnFile != null && message.hasOwnProperty("priorAuthAddressOnFile")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.priorAuthAddressOnFile);
                    if (error)
                        return "priorAuthAddressOnFile." + error;
                }
                if (message.priorAuthApprovalDate != null && message.hasOwnProperty("priorAuthApprovalDate"))
                    if (!$util.isString(message.priorAuthApprovalDate))
                        return "priorAuthApprovalDate: string expected";
                if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber"))
                    if (!$util.isString(message.priorAuthApprovalNumber))
                        return "priorAuthApprovalNumber: string expected";
                if (message.priorAuthApprovedQuantity != null && message.hasOwnProperty("priorAuthApprovedQuantity"))
                    if (!$util.isString(message.priorAuthApprovedQuantity))
                        return "priorAuthApprovedQuantity: string expected";
                if (message.priorAuthApprovedQuantityUsed != null && message.hasOwnProperty("priorAuthApprovedQuantityUsed"))
                    if (!$util.isString(message.priorAuthApprovedQuantityUsed))
                        return "priorAuthApprovedQuantityUsed: string expected";
                if (message.priorAuthApprovedTreatments != null && message.hasOwnProperty("priorAuthApprovedTreatments"))
                    if (!$util.isString(message.priorAuthApprovedTreatments))
                        return "priorAuthApprovedTreatments: string expected";
                if (message.priorAuthApprovedTreatmentsUsed != null && message.hasOwnProperty("priorAuthApprovedTreatmentsUsed"))
                    if (!$util.isString(message.priorAuthApprovedTreatmentsUsed))
                        return "priorAuthApprovedTreatmentsUsed: string expected";
                if (message.priorAuthCodesOnFile != null && message.hasOwnProperty("priorAuthCodesOnFile")) {
                    if (!Array.isArray(message.priorAuthCodesOnFile))
                        return "priorAuthCodesOnFile: array expected";
                    for (var i = 0; i < message.priorAuthCodesOnFile.length; ++i)
                        if (!$util.isString(message.priorAuthCodesOnFile[i]))
                            return "priorAuthCodesOnFile: string[] expected";
                }
                if (message.priorAuthCompletionDate != null && message.hasOwnProperty("priorAuthCompletionDate"))
                    if (!$util.isString(message.priorAuthCompletionDate))
                        return "priorAuthCompletionDate: string expected";
                if (message.priorAuthEndDate != null && message.hasOwnProperty("priorAuthEndDate"))
                    if (!$util.isString(message.priorAuthEndDate))
                        return "priorAuthEndDate: string expected";
                if (message.priorAuthExceptionReason != null && message.hasOwnProperty("priorAuthExceptionReason"))
                    if (!$util.isString(message.priorAuthExceptionReason))
                        return "priorAuthExceptionReason: string expected";
                if (message.priorAuthInitiationDate != null && message.hasOwnProperty("priorAuthInitiationDate"))
                    if (!$util.isString(message.priorAuthInitiationDate))
                        return "priorAuthInitiationDate: string expected";
                if (message.priorAuthNotificationMethod != null && message.hasOwnProperty("priorAuthNotificationMethod"))
                    switch (message.priorAuthNotificationMethod) {
                    default:
                        return "priorAuthNotificationMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.priorAuthNotificationMethods != null && message.hasOwnProperty("priorAuthNotificationMethods")) {
                    if (!Array.isArray(message.priorAuthNotificationMethods))
                        return "priorAuthNotificationMethods: array expected";
                    for (var i = 0; i < message.priorAuthNotificationMethods.length; ++i)
                        switch (message.priorAuthNotificationMethods[i]) {
                        default:
                            return "priorAuthNotificationMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.priorAuthOnFile != null && message.hasOwnProperty("priorAuthOnFile")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthOnFile);
                    if (error)
                        return "priorAuthOnFile." + error;
                }
                if (message.priorAuthOnFileApplies != null && message.hasOwnProperty("priorAuthOnFileApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthOnFileApplies);
                    if (error)
                        return "priorAuthOnFileApplies." + error;
                }
                if (message.priorAuthProviderNameOnFile != null && message.hasOwnProperty("priorAuthProviderNameOnFile"))
                    if (!$util.isString(message.priorAuthProviderNameOnFile))
                        return "priorAuthProviderNameOnFile: string expected";
                if (message.priorAuthRenewalProcessExists != null && message.hasOwnProperty("priorAuthRenewalProcessExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthRenewalProcessExists);
                    if (error)
                        return "priorAuthRenewalProcessExists." + error;
                }
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthRequired);
                    if (error)
                        return "priorAuthRequired." + error;
                }
                if (message.priorAuthRequiredCodes != null && message.hasOwnProperty("priorAuthRequiredCodes")) {
                    if (!Array.isArray(message.priorAuthRequiredCodes))
                        return "priorAuthRequiredCodes: array expected";
                    for (var i = 0; i < message.priorAuthRequiredCodes.length; ++i)
                        if (!$util.isString(message.priorAuthRequiredCodes[i]))
                            return "priorAuthRequiredCodes: string[] expected";
                }
                if (message.priorAuthResponsibleOrg != null && message.hasOwnProperty("priorAuthResponsibleOrg"))
                    switch (message.priorAuthResponsibleOrg) {
                    default:
                        return "priorAuthResponsibleOrg: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.priorAuthStartDate != null && message.hasOwnProperty("priorAuthStartDate"))
                    if (!$util.isString(message.priorAuthStartDate))
                        return "priorAuthStartDate: string expected";
                if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                    switch (message.priorAuthStatus) {
                    default:
                        return "priorAuthStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                if (message.priorAuthSubmissionDate != null && message.hasOwnProperty("priorAuthSubmissionDate"))
                    if (!$util.isString(message.priorAuthSubmissionDate))
                        return "priorAuthSubmissionDate: string expected";
                if (message.priorAuthSubmissionMethod != null && message.hasOwnProperty("priorAuthSubmissionMethod"))
                    switch (message.priorAuthSubmissionMethod) {
                    default:
                        return "priorAuthSubmissionMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.priorAuthSubmissionMethods != null && message.hasOwnProperty("priorAuthSubmissionMethods")) {
                    if (!Array.isArray(message.priorAuthSubmissionMethods))
                        return "priorAuthSubmissionMethods: array expected";
                    for (var i = 0; i < message.priorAuthSubmissionMethods.length; ++i)
                        switch (message.priorAuthSubmissionMethods[i]) {
                        default:
                            return "priorAuthSubmissionMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                }
                if (message.priorAuthTurnaroundTime != null && message.hasOwnProperty("priorAuthTurnaroundTime"))
                    if (!$util.isString(message.priorAuthTurnaroundTime))
                        return "priorAuthTurnaroundTime: string expected";
                if (message.priorAuthType != null && message.hasOwnProperty("priorAuthType"))
                    switch (message.priorAuthType) {
                    default:
                        return "priorAuthType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.quantityLimit != null && message.hasOwnProperty("quantityLimit"))
                    if (!$util.isString(message.quantityLimit))
                        return "quantityLimit: string expected";
                if (message.referralAddress != null && message.hasOwnProperty("referralAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.referralAddress);
                    if (error)
                        return "referralAddress." + error;
                }
                if (message.referralEffectiveDate != null && message.hasOwnProperty("referralEffectiveDate"))
                    if (!$util.isString(message.referralEffectiveDate))
                        return "referralEffectiveDate: string expected";
                if (message.referralFax != null && message.hasOwnProperty("referralFax"))
                    if (!$util.isString(message.referralFax))
                        return "referralFax: string expected";
                if (message.referralNumVisitsApproved != null && message.hasOwnProperty("referralNumVisitsApproved")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.referralNumVisitsApproved);
                    if (error)
                        return "referralNumVisitsApproved." + error;
                }
                if (message.referralNumVisitsUsed != null && message.hasOwnProperty("referralNumVisitsUsed")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.referralNumVisitsUsed);
                    if (error)
                        return "referralNumVisitsUsed." + error;
                }
                if (message.referralNumber != null && message.hasOwnProperty("referralNumber"))
                    if (!$util.isString(message.referralNumber))
                        return "referralNumber: string expected";
                if (message.referralOnFile != null && message.hasOwnProperty("referralOnFile")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.referralOnFile);
                    if (error)
                        return "referralOnFile." + error;
                }
                if (message.referralRecertDate != null && message.hasOwnProperty("referralRecertDate"))
                    if (!$util.isString(message.referralRecertDate))
                        return "referralRecertDate: string expected";
                if (message.referralRequired != null && message.hasOwnProperty("referralRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.referralRequired);
                    if (error)
                        return "referralRequired." + error;
                }
                if (message.referralRequirements != null && message.hasOwnProperty("referralRequirements"))
                    switch (message.referralRequirements) {
                    default:
                        return "referralRequirements: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.referralSubmissionProcess != null && message.hasOwnProperty("referralSubmissionProcess"))
                    if (!$util.isString(message.referralSubmissionProcess))
                        return "referralSubmissionProcess: string expected";
                if (message.referralWebsite != null && message.hasOwnProperty("referralWebsite"))
                    if (!$util.isString(message.referralWebsite))
                        return "referralWebsite: string expected";
                if (message.specialtyPharmacy2Address != null && message.hasOwnProperty("specialtyPharmacy2Address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.specialtyPharmacy2Address);
                    if (error)
                        return "specialtyPharmacy2Address." + error;
                }
                if (message.specialtyPharmacy2Fax != null && message.hasOwnProperty("specialtyPharmacy2Fax"))
                    if (!$util.isString(message.specialtyPharmacy2Fax))
                        return "specialtyPharmacy2Fax: string expected";
                if (message.specialtyPharmacy2Name != null && message.hasOwnProperty("specialtyPharmacy2Name"))
                    if (!$util.isString(message.specialtyPharmacy2Name))
                        return "specialtyPharmacy2Name: string expected";
                if (message.specialtyPharmacy2Phone != null && message.hasOwnProperty("specialtyPharmacy2Phone"))
                    if (!$util.isString(message.specialtyPharmacy2Phone))
                        return "specialtyPharmacy2Phone: string expected";
                if (message.specialtyPharmacy3Address != null && message.hasOwnProperty("specialtyPharmacy3Address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.specialtyPharmacy3Address);
                    if (error)
                        return "specialtyPharmacy3Address." + error;
                }
                if (message.specialtyPharmacy3Fax != null && message.hasOwnProperty("specialtyPharmacy3Fax"))
                    if (!$util.isString(message.specialtyPharmacy3Fax))
                        return "specialtyPharmacy3Fax: string expected";
                if (message.specialtyPharmacy3Name != null && message.hasOwnProperty("specialtyPharmacy3Name"))
                    if (!$util.isString(message.specialtyPharmacy3Name))
                        return "specialtyPharmacy3Name: string expected";
                if (message.specialtyPharmacy3Phone != null && message.hasOwnProperty("specialtyPharmacy3Phone"))
                    if (!$util.isString(message.specialtyPharmacy3Phone))
                        return "specialtyPharmacy3Phone: string expected";
                if (message.specialtyPharmacyAddress != null && message.hasOwnProperty("specialtyPharmacyAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.specialtyPharmacyAddress);
                    if (error)
                        return "specialtyPharmacyAddress." + error;
                }
                if (message.specialtyPharmacyAvailability != null && message.hasOwnProperty("specialtyPharmacyAvailability"))
                    switch (message.specialtyPharmacyAvailability) {
                    default:
                        return "specialtyPharmacyAvailability: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.specialtyPharmacyExclusions != null && message.hasOwnProperty("specialtyPharmacyExclusions"))
                    if (!$util.isString(message.specialtyPharmacyExclusions))
                        return "specialtyPharmacyExclusions: string expected";
                if (message.specialtyPharmacyFax != null && message.hasOwnProperty("specialtyPharmacyFax"))
                    if (!$util.isString(message.specialtyPharmacyFax))
                        return "specialtyPharmacyFax: string expected";
                if (message.specialtyPharmacyName != null && message.hasOwnProperty("specialtyPharmacyName"))
                    if (!$util.isString(message.specialtyPharmacyName))
                        return "specialtyPharmacyName: string expected";
                if (message.specialtyPharmacyNetwork != null && message.hasOwnProperty("specialtyPharmacyNetwork"))
                    switch (message.specialtyPharmacyNetwork) {
                    default:
                        return "specialtyPharmacyNetwork: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.specialtyPharmacyObtainPreDeterminationOrg != null && message.hasOwnProperty("specialtyPharmacyObtainPreDeterminationOrg"))
                    if (!$util.isString(message.specialtyPharmacyObtainPreDeterminationOrg))
                        return "specialtyPharmacyObtainPreDeterminationOrg: string expected";
                if (message.specialtyPharmacyObtainPredeterminationFax != null && message.hasOwnProperty("specialtyPharmacyObtainPredeterminationFax"))
                    if (!$util.isString(message.specialtyPharmacyObtainPredeterminationFax))
                        return "specialtyPharmacyObtainPredeterminationFax: string expected";
                if (message.specialtyPharmacyObtainPredeterminationPhone != null && message.hasOwnProperty("specialtyPharmacyObtainPredeterminationPhone"))
                    if (!$util.isString(message.specialtyPharmacyObtainPredeterminationPhone))
                        return "specialtyPharmacyObtainPredeterminationPhone: string expected";
                if (message.specialtyPharmacyObtainPredeterminationWebsite != null && message.hasOwnProperty("specialtyPharmacyObtainPredeterminationWebsite"))
                    if (!$util.isString(message.specialtyPharmacyObtainPredeterminationWebsite))
                        return "specialtyPharmacyObtainPredeterminationWebsite: string expected";
                if (message.specialtyPharmacyObtainPriorAuthAddress != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.specialtyPharmacyObtainPriorAuthAddress);
                    if (error)
                        return "specialtyPharmacyObtainPriorAuthAddress." + error;
                }
                if (message.specialtyPharmacyObtainPriorAuthFax != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthFax"))
                    if (!$util.isString(message.specialtyPharmacyObtainPriorAuthFax))
                        return "specialtyPharmacyObtainPriorAuthFax: string expected";
                if (message.specialtyPharmacyObtainPriorAuthFormLink != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthFormLink"))
                    if (!$util.isString(message.specialtyPharmacyObtainPriorAuthFormLink))
                        return "specialtyPharmacyObtainPriorAuthFormLink: string expected";
                if (message.specialtyPharmacyObtainPriorAuthOrg != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthOrg"))
                    if (!$util.isString(message.specialtyPharmacyObtainPriorAuthOrg))
                        return "specialtyPharmacyObtainPriorAuthOrg: string expected";
                if (message.specialtyPharmacyObtainPriorAuthPhone != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthPhone"))
                    if (!$util.isString(message.specialtyPharmacyObtainPriorAuthPhone))
                        return "specialtyPharmacyObtainPriorAuthPhone: string expected";
                if (message.specialtyPharmacyObtainPriorAuthRequirements != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthRequirements")) {
                    if (!Array.isArray(message.specialtyPharmacyObtainPriorAuthRequirements))
                        return "specialtyPharmacyObtainPriorAuthRequirements: array expected";
                    for (var i = 0; i < message.specialtyPharmacyObtainPriorAuthRequirements.length; ++i)
                        switch (message.specialtyPharmacyObtainPriorAuthRequirements[i]) {
                        default:
                            return "specialtyPharmacyObtainPriorAuthRequirements: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.specialtyPharmacyObtainPriorAuthWebsite != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthWebsite"))
                    if (!$util.isString(message.specialtyPharmacyObtainPriorAuthWebsite))
                        return "specialtyPharmacyObtainPriorAuthWebsite: string expected";
                if (message.specialtyPharmacyPhone != null && message.hasOwnProperty("specialtyPharmacyPhone"))
                    if (!$util.isString(message.specialtyPharmacyPhone))
                        return "specialtyPharmacyPhone: string expected";
                if (message.specialtyPharmacyPredeterminationApprovedQuantity != null && message.hasOwnProperty("specialtyPharmacyPredeterminationApprovedQuantity"))
                    if (!$util.isString(message.specialtyPharmacyPredeterminationApprovedQuantity))
                        return "specialtyPharmacyPredeterminationApprovedQuantity: string expected";
                if (message.specialtyPharmacyPredeterminationApprovedQuantityUsed != null && message.hasOwnProperty("specialtyPharmacyPredeterminationApprovedQuantityUsed"))
                    if (!$util.isString(message.specialtyPharmacyPredeterminationApprovedQuantityUsed))
                        return "specialtyPharmacyPredeterminationApprovedQuantityUsed: string expected";
                if (message.specialtyPharmacyPredeterminationEndDate != null && message.hasOwnProperty("specialtyPharmacyPredeterminationEndDate"))
                    if (!$util.isString(message.specialtyPharmacyPredeterminationEndDate))
                        return "specialtyPharmacyPredeterminationEndDate: string expected";
                if (message.specialtyPharmacyPredeterminationNumber != null && message.hasOwnProperty("specialtyPharmacyPredeterminationNumber"))
                    if (!$util.isString(message.specialtyPharmacyPredeterminationNumber))
                        return "specialtyPharmacyPredeterminationNumber: string expected";
                if (message.specialtyPharmacyPredeterminationOnFile != null && message.hasOwnProperty("specialtyPharmacyPredeterminationOnFile")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.specialtyPharmacyPredeterminationOnFile);
                    if (error)
                        return "specialtyPharmacyPredeterminationOnFile." + error;
                }
                if (message.specialtyPharmacyPredeterminationRequirementType != null && message.hasOwnProperty("specialtyPharmacyPredeterminationRequirementType"))
                    switch (message.specialtyPharmacyPredeterminationRequirementType) {
                    default:
                        return "specialtyPharmacyPredeterminationRequirementType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.specialtyPharmacyPredeterminationStartDate != null && message.hasOwnProperty("specialtyPharmacyPredeterminationStartDate"))
                    if (!$util.isString(message.specialtyPharmacyPredeterminationStartDate))
                        return "specialtyPharmacyPredeterminationStartDate: string expected";
                if (message.specialtyPharmacyPredeterminationStatus != null && message.hasOwnProperty("specialtyPharmacyPredeterminationStatus"))
                    switch (message.specialtyPharmacyPredeterminationStatus) {
                    default:
                        return "specialtyPharmacyPredeterminationStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                if (message.specialtyPharmacyPredeterminationTurnaroundTime != null && message.hasOwnProperty("specialtyPharmacyPredeterminationTurnaroundTime"))
                    if (!$util.isString(message.specialtyPharmacyPredeterminationTurnaroundTime))
                        return "specialtyPharmacyPredeterminationTurnaroundTime: string expected";
                if (message.specialtyPharmacyPriorAuthApprovalNumber != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovalNumber"))
                    if (!$util.isString(message.specialtyPharmacyPriorAuthApprovalNumber))
                        return "specialtyPharmacyPriorAuthApprovalNumber: string expected";
                if (message.specialtyPharmacyPriorAuthApprovedQuantity != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovedQuantity"))
                    if (!$util.isString(message.specialtyPharmacyPriorAuthApprovedQuantity))
                        return "specialtyPharmacyPriorAuthApprovedQuantity: string expected";
                if (message.specialtyPharmacyPriorAuthApprovedQuantityUsed != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovedQuantityUsed"))
                    if (!$util.isString(message.specialtyPharmacyPriorAuthApprovedQuantityUsed))
                        return "specialtyPharmacyPriorAuthApprovedQuantityUsed: string expected";
                if (message.specialtyPharmacyPriorAuthApprovedTreatments != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovedTreatments"))
                    if (!$util.isString(message.specialtyPharmacyPriorAuthApprovedTreatments))
                        return "specialtyPharmacyPriorAuthApprovedTreatments: string expected";
                if (message.specialtyPharmacyPriorAuthApprovedTreatmentsUsed != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovedTreatmentsUsed"))
                    if (!$util.isString(message.specialtyPharmacyPriorAuthApprovedTreatmentsUsed))
                        return "specialtyPharmacyPriorAuthApprovedTreatmentsUsed: string expected";
                if (message.specialtyPharmacyPriorAuthEndDate != null && message.hasOwnProperty("specialtyPharmacyPriorAuthEndDate"))
                    if (!$util.isString(message.specialtyPharmacyPriorAuthEndDate))
                        return "specialtyPharmacyPriorAuthEndDate: string expected";
                if (message.specialtyPharmacyPriorAuthNotificationMethod != null && message.hasOwnProperty("specialtyPharmacyPriorAuthNotificationMethod"))
                    switch (message.specialtyPharmacyPriorAuthNotificationMethod) {
                    default:
                        return "specialtyPharmacyPriorAuthNotificationMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.specialtyPharmacyPriorAuthNotificationMethods != null && message.hasOwnProperty("specialtyPharmacyPriorAuthNotificationMethods")) {
                    if (!Array.isArray(message.specialtyPharmacyPriorAuthNotificationMethods))
                        return "specialtyPharmacyPriorAuthNotificationMethods: array expected";
                    for (var i = 0; i < message.specialtyPharmacyPriorAuthNotificationMethods.length; ++i)
                        switch (message.specialtyPharmacyPriorAuthNotificationMethods[i]) {
                        default:
                            return "specialtyPharmacyPriorAuthNotificationMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.specialtyPharmacyPriorAuthProcessDifferent != null && message.hasOwnProperty("specialtyPharmacyPriorAuthProcessDifferent")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.specialtyPharmacyPriorAuthProcessDifferent);
                    if (error)
                        return "specialtyPharmacyPriorAuthProcessDifferent." + error;
                }
                if (message.specialtyPharmacyPriorAuthRequired != null && message.hasOwnProperty("specialtyPharmacyPriorAuthRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.specialtyPharmacyPriorAuthRequired);
                    if (error)
                        return "specialtyPharmacyPriorAuthRequired." + error;
                }
                if (message.specialtyPharmacyPriorAuthStartDate != null && message.hasOwnProperty("specialtyPharmacyPriorAuthStartDate"))
                    if (!$util.isString(message.specialtyPharmacyPriorAuthStartDate))
                        return "specialtyPharmacyPriorAuthStartDate: string expected";
                if (message.specialtyPharmacyPriorAuthStatus != null && message.hasOwnProperty("specialtyPharmacyPriorAuthStatus"))
                    switch (message.specialtyPharmacyPriorAuthStatus) {
                    default:
                        return "specialtyPharmacyPriorAuthStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                if (message.specialtyPharmacyPriorAuthSubmissionMethod != null && message.hasOwnProperty("specialtyPharmacyPriorAuthSubmissionMethod"))
                    switch (message.specialtyPharmacyPriorAuthSubmissionMethod) {
                    default:
                        return "specialtyPharmacyPriorAuthSubmissionMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.specialtyPharmacyPriorAuthSubmissionMethods != null && message.hasOwnProperty("specialtyPharmacyPriorAuthSubmissionMethods")) {
                    if (!Array.isArray(message.specialtyPharmacyPriorAuthSubmissionMethods))
                        return "specialtyPharmacyPriorAuthSubmissionMethods: array expected";
                    for (var i = 0; i < message.specialtyPharmacyPriorAuthSubmissionMethods.length; ++i)
                        switch (message.specialtyPharmacyPriorAuthSubmissionMethods[i]) {
                        default:
                            return "specialtyPharmacyPriorAuthSubmissionMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                }
                if (message.specialtyPharmacyPriorAuthTurnaroundTime != null && message.hasOwnProperty("specialtyPharmacyPriorAuthTurnaroundTime"))
                    if (!$util.isString(message.specialtyPharmacyPriorAuthTurnaroundTime))
                        return "specialtyPharmacyPriorAuthTurnaroundTime: string expected";
                if (message.stepTherapyMethod != null && message.hasOwnProperty("stepTherapyMethod"))
                    switch (message.stepTherapyMethod) {
                    default:
                        return "stepTherapyMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.stepTherapyRequired != null && message.hasOwnProperty("stepTherapyRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.stepTherapyRequired);
                    if (error)
                        return "stepTherapyRequired." + error;
                }
                if (message.stepTherapyTreatment != null && message.hasOwnProperty("stepTherapyTreatment"))
                    if (!$util.isString(message.stepTherapyTreatment))
                        return "stepTherapyTreatment: string expected";
                if (message.therapyAvailabilityDate != null && message.hasOwnProperty("therapyAvailabilityDate"))
                    if (!$util.isString(message.therapyAvailabilityDate))
                        return "therapyAvailabilityDate: string expected";
                if (message.therapySeasonStartDate != null && message.hasOwnProperty("therapySeasonStartDate"))
                    if (!$util.isString(message.therapySeasonStartDate))
                        return "therapySeasonStartDate: string expected";
                if (message.timelyFilingPeriod != null && message.hasOwnProperty("timelyFilingPeriod"))
                    if (!$util.isString(message.timelyFilingPeriod))
                        return "timelyFilingPeriod: string expected";
                if (message.transportationServiceCovered != null && message.hasOwnProperty("transportationServiceCovered")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.transportationServiceCovered);
                    if (error)
                        return "transportationServiceCovered." + error;
                }
                if (message.transportationServicePhoneNumber != null && message.hasOwnProperty("transportationServicePhoneNumber"))
                    if (!$util.isString(message.transportationServicePhoneNumber))
                        return "transportationServicePhoneNumber: string expected";
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputAccessInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputAccessInfo} INFBVTaskOutputAccessInfo
             */
            INFBVTaskOutputAccessInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputAccessInfo)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputAccessInfo();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.attestationProcess != null)
                    message.attestationProcess = String(object.attestationProcess);
                if (object.attestationProcessExists != null) {
                    if (typeof object.attestationProcessExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.attestationProcessExists: object expected");
                    message.attestationProcessExists = $root.google.protobuf.BoolValue.fromObject(object.attestationProcessExists);
                }
                switch (object.buyAndBillAvailability) {
                default:
                    if (typeof object.buyAndBillAvailability === "number") {
                        message.buyAndBillAvailability = object.buyAndBillAvailability;
                        break;
                    }
                    break;
                case "INF_BUY_AND_BILL_AVAILABILITY_UNKNOWN":
                case 0:
                    message.buyAndBillAvailability = 0;
                    break;
                case "INF_BUY_AND_BILL_AVAILABILITY_NOT_APPLICABLE":
                case 1:
                    message.buyAndBillAvailability = 1;
                    break;
                case "INF_BUY_AND_BILL_AVAILABILITY_REQUIRED":
                case 2:
                    message.buyAndBillAvailability = 2;
                    break;
                case "INF_BUY_AND_BILL_AVAILABILITY_AVAILABLE":
                case 3:
                    message.buyAndBillAvailability = 3;
                    break;
                case "INF_BUY_AND_BILL_AVAILABILITY_NOT_AVAILABLE":
                case 4:
                    message.buyAndBillAvailability = 4;
                    break;
                }
                if (object.claimsAddress != null) {
                    if (typeof object.claimsAddress !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.claimsAddress: object expected");
                    message.claimsAddress = $root.infinitusapi.INFAddress.fromObject(object.claimsAddress);
                }
                if (object.coverageExceptionProcessExists != null) {
                    if (typeof object.coverageExceptionProcessExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.coverageExceptionProcessExists: object expected");
                    message.coverageExceptionProcessExists = $root.google.protobuf.BoolValue.fromObject(object.coverageExceptionProcessExists);
                }
                if (object.coverageExceptionTurnaroundTime != null)
                    message.coverageExceptionTurnaroundTime = String(object.coverageExceptionTurnaroundTime);
                if (object.coverageRestrictions != null)
                    message.coverageRestrictions = String(object.coverageRestrictions);
                if (object.cptObtainPreDeterminationOrg != null)
                    message.cptObtainPreDeterminationOrg = String(object.cptObtainPreDeterminationOrg);
                if (object.cptObtainPredeterminationFax != null)
                    message.cptObtainPredeterminationFax = String(object.cptObtainPredeterminationFax);
                if (object.cptObtainPredeterminationPhone != null)
                    message.cptObtainPredeterminationPhone = String(object.cptObtainPredeterminationPhone);
                if (object.cptObtainPredeterminationWebsite != null)
                    message.cptObtainPredeterminationWebsite = String(object.cptObtainPredeterminationWebsite);
                if (object.cptObtainPriorAuthAddress != null) {
                    if (typeof object.cptObtainPriorAuthAddress !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.cptObtainPriorAuthAddress: object expected");
                    message.cptObtainPriorAuthAddress = $root.infinitusapi.INFAddress.fromObject(object.cptObtainPriorAuthAddress);
                }
                if (object.cptObtainPriorAuthFax != null)
                    message.cptObtainPriorAuthFax = String(object.cptObtainPriorAuthFax);
                if (object.cptObtainPriorAuthFormLink != null)
                    message.cptObtainPriorAuthFormLink = String(object.cptObtainPriorAuthFormLink);
                if (object.cptObtainPriorAuthOrg != null)
                    message.cptObtainPriorAuthOrg = String(object.cptObtainPriorAuthOrg);
                if (object.cptObtainPriorAuthPhone != null)
                    message.cptObtainPriorAuthPhone = String(object.cptObtainPriorAuthPhone);
                if (object.cptObtainPriorAuthRequirements) {
                    if (!Array.isArray(object.cptObtainPriorAuthRequirements))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.cptObtainPriorAuthRequirements: array expected");
                    message.cptObtainPriorAuthRequirements = [];
                    for (var i = 0; i < object.cptObtainPriorAuthRequirements.length; ++i)
                        switch (object.cptObtainPriorAuthRequirements[i]) {
                        default:
                            if (typeof object.cptObtainPriorAuthRequirements[i] === "number") {
                                message.cptObtainPriorAuthRequirements[i] = object.cptObtainPriorAuthRequirements[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_REQUIREMENT_UNKNOWN":
                        case 0:
                            message.cptObtainPriorAuthRequirements[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_PA_FORM":
                        case 1:
                            message.cptObtainPriorAuthRequirements[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY":
                        case 2:
                            message.cptObtainPriorAuthRequirements[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS":
                        case 3:
                            message.cptObtainPriorAuthRequirements[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET":
                        case 4:
                            message.cptObtainPriorAuthRequirements[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE":
                        case 5:
                            message.cptObtainPriorAuthRequirements[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_NOT_APPLICABLE":
                        case 6:
                            message.cptObtainPriorAuthRequirements[i] = 6;
                            break;
                        }
                }
                if (object.cptObtainPriorAuthWebsite != null)
                    message.cptObtainPriorAuthWebsite = String(object.cptObtainPriorAuthWebsite);
                if (object.cptPredeterminationApprovedQuantity != null)
                    message.cptPredeterminationApprovedQuantity = String(object.cptPredeterminationApprovedQuantity);
                if (object.cptPredeterminationApprovedQuantityUsed != null)
                    message.cptPredeterminationApprovedQuantityUsed = String(object.cptPredeterminationApprovedQuantityUsed);
                if (object.cptPredeterminationEndDate != null)
                    message.cptPredeterminationEndDate = String(object.cptPredeterminationEndDate);
                if (object.cptPredeterminationNumber != null)
                    message.cptPredeterminationNumber = String(object.cptPredeterminationNumber);
                if (object.cptPredeterminationStartDate != null)
                    message.cptPredeterminationStartDate = String(object.cptPredeterminationStartDate);
                if (object.cptPredeterminationTurnaroundTime != null)
                    message.cptPredeterminationTurnaroundTime = String(object.cptPredeterminationTurnaroundTime);
                if (object.cptPriorAuthApprovalNumber != null)
                    message.cptPriorAuthApprovalNumber = String(object.cptPriorAuthApprovalNumber);
                if (object.cptPriorAuthApprovedQuantity != null)
                    message.cptPriorAuthApprovedQuantity = String(object.cptPriorAuthApprovedQuantity);
                if (object.cptPriorAuthApprovedQuantityUsed != null)
                    message.cptPriorAuthApprovedQuantityUsed = String(object.cptPriorAuthApprovedQuantityUsed);
                if (object.cptPriorAuthApprovedTreatments != null)
                    message.cptPriorAuthApprovedTreatments = String(object.cptPriorAuthApprovedTreatments);
                if (object.cptPriorAuthApprovedTreatmentsUsed != null)
                    message.cptPriorAuthApprovedTreatmentsUsed = String(object.cptPriorAuthApprovedTreatmentsUsed);
                if (object.cptPriorAuthEndDate != null)
                    message.cptPriorAuthEndDate = String(object.cptPriorAuthEndDate);
                switch (object.cptPriorAuthNotificationMethod) {
                default:
                    if (typeof object.cptPriorAuthNotificationMethod === "number") {
                        message.cptPriorAuthNotificationMethod = object.cptPriorAuthNotificationMethod;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                case 0:
                    message.cptPriorAuthNotificationMethod = 0;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                case 1:
                    message.cptPriorAuthNotificationMethod = 1;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                case 2:
                    message.cptPriorAuthNotificationMethod = 2;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                case 3:
                    message.cptPriorAuthNotificationMethod = 3;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                case 4:
                    message.cptPriorAuthNotificationMethod = 4;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                case 5:
                    message.cptPriorAuthNotificationMethod = 5;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                case 6:
                    message.cptPriorAuthNotificationMethod = 6;
                    break;
                }
                if (object.cptPriorAuthNotificationMethods) {
                    if (!Array.isArray(object.cptPriorAuthNotificationMethods))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.cptPriorAuthNotificationMethods: array expected");
                    message.cptPriorAuthNotificationMethods = [];
                    for (var i = 0; i < object.cptPriorAuthNotificationMethods.length; ++i)
                        switch (object.cptPriorAuthNotificationMethods[i]) {
                        default:
                            if (typeof object.cptPriorAuthNotificationMethods[i] === "number") {
                                message.cptPriorAuthNotificationMethods[i] = object.cptPriorAuthNotificationMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                        case 0:
                            message.cptPriorAuthNotificationMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.cptPriorAuthNotificationMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                        case 2:
                            message.cptPriorAuthNotificationMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                        case 3:
                            message.cptPriorAuthNotificationMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                        case 4:
                            message.cptPriorAuthNotificationMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                        case 5:
                            message.cptPriorAuthNotificationMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                        case 6:
                            message.cptPriorAuthNotificationMethods[i] = 6;
                            break;
                        }
                }
                if (object.cptPriorAuthProcessDifferent != null) {
                    if (typeof object.cptPriorAuthProcessDifferent !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.cptPriorAuthProcessDifferent: object expected");
                    message.cptPriorAuthProcessDifferent = $root.google.protobuf.BoolValue.fromObject(object.cptPriorAuthProcessDifferent);
                }
                if (object.cptPriorAuthStartDate != null)
                    message.cptPriorAuthStartDate = String(object.cptPriorAuthStartDate);
                switch (object.cptPriorAuthSubmissionMethod) {
                default:
                    if (typeof object.cptPriorAuthSubmissionMethod === "number") {
                        message.cptPriorAuthSubmissionMethod = object.cptPriorAuthSubmissionMethod;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN":
                case 0:
                    message.cptPriorAuthSubmissionMethod = 0;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE":
                case 1:
                    message.cptPriorAuthSubmissionMethod = 1;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE":
                case 2:
                    message.cptPriorAuthSubmissionMethod = 2;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX":
                case 3:
                    message.cptPriorAuthSubmissionMethod = 3;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL":
                case 4:
                    message.cptPriorAuthSubmissionMethod = 4;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE":
                case 5:
                    message.cptPriorAuthSubmissionMethod = 5;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL":
                case 6:
                    message.cptPriorAuthSubmissionMethod = 6;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION":
                case 7:
                    message.cptPriorAuthSubmissionMethod = 7;
                    break;
                }
                if (object.cptPriorAuthSubmissionMethods) {
                    if (!Array.isArray(object.cptPriorAuthSubmissionMethods))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.cptPriorAuthSubmissionMethods: array expected");
                    message.cptPriorAuthSubmissionMethods = [];
                    for (var i = 0; i < object.cptPriorAuthSubmissionMethods.length; ++i)
                        switch (object.cptPriorAuthSubmissionMethods[i]) {
                        default:
                            if (typeof object.cptPriorAuthSubmissionMethods[i] === "number") {
                                message.cptPriorAuthSubmissionMethods[i] = object.cptPriorAuthSubmissionMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN":
                        case 0:
                            message.cptPriorAuthSubmissionMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.cptPriorAuthSubmissionMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE":
                        case 2:
                            message.cptPriorAuthSubmissionMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX":
                        case 3:
                            message.cptPriorAuthSubmissionMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL":
                        case 4:
                            message.cptPriorAuthSubmissionMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE":
                        case 5:
                            message.cptPriorAuthSubmissionMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL":
                        case 6:
                            message.cptPriorAuthSubmissionMethods[i] = 6;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION":
                        case 7:
                            message.cptPriorAuthSubmissionMethods[i] = 7;
                            break;
                        }
                }
                if (object.cptPriorAuthTurnaroundTime != null)
                    message.cptPriorAuthTurnaroundTime = String(object.cptPriorAuthTurnaroundTime);
                if (object.hasSpecialtyPharmacyExclusions != null) {
                    if (typeof object.hasSpecialtyPharmacyExclusions !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.hasSpecialtyPharmacyExclusions: object expected");
                    message.hasSpecialtyPharmacyExclusions = $root.google.protobuf.BoolValue.fromObject(object.hasSpecialtyPharmacyExclusions);
                }
                if (object.homeHealthCovered != null) {
                    if (typeof object.homeHealthCovered !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.homeHealthCovered: object expected");
                    message.homeHealthCovered = $root.google.protobuf.BoolValue.fromObject(object.homeHealthCovered);
                }
                if (object.homeHealthObtainPriorAuthFax != null)
                    message.homeHealthObtainPriorAuthFax = String(object.homeHealthObtainPriorAuthFax);
                if (object.homeHealthObtainPriorAuthPhone != null)
                    message.homeHealthObtainPriorAuthPhone = String(object.homeHealthObtainPriorAuthPhone);
                if (object.homeHealthPriorAuthRequired != null) {
                    if (typeof object.homeHealthPriorAuthRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.homeHealthPriorAuthRequired: object expected");
                    message.homeHealthPriorAuthRequired = $root.google.protobuf.BoolValue.fromObject(object.homeHealthPriorAuthRequired);
                }
                if (object.homeHealthProviderName != null)
                    message.homeHealthProviderName = String(object.homeHealthProviderName);
                if (object.homeHealthProviderPhone != null)
                    message.homeHealthProviderPhone = String(object.homeHealthProviderPhone);
                if (object.homeHealthServices != null)
                    message.homeHealthServices = String(object.homeHealthServices);
                if (object.homeboundRequired != null) {
                    if (typeof object.homeboundRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.homeboundRequired: object expected");
                    message.homeboundRequired = $root.google.protobuf.BoolValue.fromObject(object.homeboundRequired);
                }
                if (object.lodgingCovered != null) {
                    if (typeof object.lodgingCovered !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.lodgingCovered: object expected");
                    message.lodgingCovered = $root.google.protobuf.BoolValue.fromObject(object.lodgingCovered);
                }
                if (object.lodgingServicePhoneNumber != null)
                    message.lodgingServicePhoneNumber = String(object.lodgingServicePhoneNumber);
                if (object.medicalGroupName != null)
                    message.medicalGroupName = String(object.medicalGroupName);
                if (object.medicalGroupPhoneNumber != null)
                    message.medicalGroupPhoneNumber = String(object.medicalGroupPhoneNumber);
                if (object.medicalPolicyAvailableOnWebsite != null) {
                    if (typeof object.medicalPolicyAvailableOnWebsite !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.medicalPolicyAvailableOnWebsite: object expected");
                    message.medicalPolicyAvailableOnWebsite = $root.google.protobuf.BoolValue.fromObject(object.medicalPolicyAvailableOnWebsite);
                }
                if (object.medicalPolicyNumber != null)
                    message.medicalPolicyNumber = String(object.medicalPolicyNumber);
                if (object.obtainCoverageExceptionAddress != null) {
                    if (typeof object.obtainCoverageExceptionAddress !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.obtainCoverageExceptionAddress: object expected");
                    message.obtainCoverageExceptionAddress = $root.infinitusapi.INFAddress.fromObject(object.obtainCoverageExceptionAddress);
                }
                if (object.obtainCoverageExceptionFax != null)
                    message.obtainCoverageExceptionFax = String(object.obtainCoverageExceptionFax);
                if (object.obtainCoverageExceptionPhone != null)
                    message.obtainCoverageExceptionPhone = String(object.obtainCoverageExceptionPhone);
                if (object.obtainCoverageExceptionProcess != null)
                    message.obtainCoverageExceptionProcess = String(object.obtainCoverageExceptionProcess);
                if (object.obtainCoverageExceptionWebsite != null)
                    message.obtainCoverageExceptionWebsite = String(object.obtainCoverageExceptionWebsite);
                if (object.obtainPreDeterminationOrg != null)
                    message.obtainPreDeterminationOrg = String(object.obtainPreDeterminationOrg);
                if (object.obtainPredeterminationFax != null)
                    message.obtainPredeterminationFax = String(object.obtainPredeterminationFax);
                if (object.obtainPredeterminationPhone != null)
                    message.obtainPredeterminationPhone = String(object.obtainPredeterminationPhone);
                if (object.obtainPredeterminationWebsite != null)
                    message.obtainPredeterminationWebsite = String(object.obtainPredeterminationWebsite);
                if (object.obtainPriorAuthAddress != null) {
                    if (typeof object.obtainPriorAuthAddress !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.obtainPriorAuthAddress: object expected");
                    message.obtainPriorAuthAddress = $root.infinitusapi.INFAddress.fromObject(object.obtainPriorAuthAddress);
                }
                if (object.obtainPriorAuthFax != null)
                    message.obtainPriorAuthFax = String(object.obtainPriorAuthFax);
                if (object.obtainPriorAuthFormLink != null)
                    message.obtainPriorAuthFormLink = String(object.obtainPriorAuthFormLink);
                if (object.obtainPriorAuthOrg != null)
                    message.obtainPriorAuthOrg = String(object.obtainPriorAuthOrg);
                if (object.obtainPriorAuthPhone != null)
                    message.obtainPriorAuthPhone = String(object.obtainPriorAuthPhone);
                if (object.obtainPriorAuthRequirements) {
                    if (!Array.isArray(object.obtainPriorAuthRequirements))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.obtainPriorAuthRequirements: array expected");
                    message.obtainPriorAuthRequirements = [];
                    for (var i = 0; i < object.obtainPriorAuthRequirements.length; ++i)
                        switch (object.obtainPriorAuthRequirements[i]) {
                        default:
                            if (typeof object.obtainPriorAuthRequirements[i] === "number") {
                                message.obtainPriorAuthRequirements[i] = object.obtainPriorAuthRequirements[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_REQUIREMENT_UNKNOWN":
                        case 0:
                            message.obtainPriorAuthRequirements[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_PA_FORM":
                        case 1:
                            message.obtainPriorAuthRequirements[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY":
                        case 2:
                            message.obtainPriorAuthRequirements[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS":
                        case 3:
                            message.obtainPriorAuthRequirements[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET":
                        case 4:
                            message.obtainPriorAuthRequirements[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE":
                        case 5:
                            message.obtainPriorAuthRequirements[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_NOT_APPLICABLE":
                        case 6:
                            message.obtainPriorAuthRequirements[i] = 6;
                            break;
                        }
                }
                if (object.obtainPriorAuthWebsite != null)
                    message.obtainPriorAuthWebsite = String(object.obtainPriorAuthWebsite);
                if (object.obtainQuantityLimitExceptionPhone != null)
                    message.obtainQuantityLimitExceptionPhone = String(object.obtainQuantityLimitExceptionPhone);
                if (object.obtainQuantityLimitExceptionProcess != null)
                    message.obtainQuantityLimitExceptionProcess = String(object.obtainQuantityLimitExceptionProcess);
                if (object.pcpName != null)
                    message.pcpName = String(object.pcpName);
                if (object.pcpPhone != null)
                    message.pcpPhone = String(object.pcpPhone);
                if (object.preDeterminationAvailable != null) {
                    if (typeof object.preDeterminationAvailable !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.preDeterminationAvailable: object expected");
                    message.preDeterminationAvailable = $root.google.protobuf.BoolValue.fromObject(object.preDeterminationAvailable);
                }
                if (object.preDeterminationNumber != null)
                    message.preDeterminationNumber = String(object.preDeterminationNumber);
                if (object.preDeterminationOnFile != null) {
                    if (typeof object.preDeterminationOnFile !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.preDeterminationOnFile: object expected");
                    message.preDeterminationOnFile = $root.google.protobuf.BoolValue.fromObject(object.preDeterminationOnFile);
                }
                if (object.preDeterminationRequired != null) {
                    if (typeof object.preDeterminationRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.preDeterminationRequired: object expected");
                    message.preDeterminationRequired = $root.google.protobuf.BoolValue.fromObject(object.preDeterminationRequired);
                }
                switch (object.preDeterminationRequirement) {
                default:
                    if (typeof object.preDeterminationRequirement === "number") {
                        message.preDeterminationRequirement = object.preDeterminationRequirement;
                        break;
                    }
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_UNKNOWN":
                case 0:
                    message.preDeterminationRequirement = 0;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_NOT_APPLICABLE":
                case 1:
                    message.preDeterminationRequirement = 1;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_REQUIRED":
                case 2:
                    message.preDeterminationRequirement = 2;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED":
                case 3:
                    message.preDeterminationRequirement = 3;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_AVAILABLE":
                case 4:
                    message.preDeterminationRequirement = 4;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE":
                case 5:
                    message.preDeterminationRequirement = 5;
                    break;
                }
                if (object.predeterminationAddressOnFile != null) {
                    if (typeof object.predeterminationAddressOnFile !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.predeterminationAddressOnFile: object expected");
                    message.predeterminationAddressOnFile = $root.infinitusapi.INFAddress.fromObject(object.predeterminationAddressOnFile);
                }
                if (object.predeterminationApprovedQuantity != null)
                    message.predeterminationApprovedQuantity = String(object.predeterminationApprovedQuantity);
                if (object.predeterminationApprovedQuantityUsed != null)
                    message.predeterminationApprovedQuantityUsed = String(object.predeterminationApprovedQuantityUsed);
                if (object.predeterminationCompletionDate != null)
                    message.predeterminationCompletionDate = String(object.predeterminationCompletionDate);
                if (object.predeterminationEndDate != null)
                    message.predeterminationEndDate = String(object.predeterminationEndDate);
                if (object.predeterminationOnFileApplies != null) {
                    if (typeof object.predeterminationOnFileApplies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.predeterminationOnFileApplies: object expected");
                    message.predeterminationOnFileApplies = $root.google.protobuf.BoolValue.fromObject(object.predeterminationOnFileApplies);
                }
                if (object.predeterminationProviderNameOnFile != null)
                    message.predeterminationProviderNameOnFile = String(object.predeterminationProviderNameOnFile);
                if (object.predeterminationReceivedDate != null)
                    message.predeterminationReceivedDate = String(object.predeterminationReceivedDate);
                if (object.predeterminationRenewalProcessExists != null) {
                    if (typeof object.predeterminationRenewalProcessExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.predeterminationRenewalProcessExists: object expected");
                    message.predeterminationRenewalProcessExists = $root.google.protobuf.BoolValue.fromObject(object.predeterminationRenewalProcessExists);
                }
                if (object.predeterminationStartDate != null)
                    message.predeterminationStartDate = String(object.predeterminationStartDate);
                switch (object.predeterminationStatus) {
                default:
                    if (typeof object.predeterminationStatus === "number") {
                        message.predeterminationStatus = object.predeterminationStatus;
                        break;
                    }
                    break;
                case "INF_PREDETERMINATION_STATUS_UNKNOWN":
                case 0:
                    message.predeterminationStatus = 0;
                    break;
                case "INF_PREDETERMINATION_STATUS_NOT_APPLICABLE":
                case 1:
                    message.predeterminationStatus = 1;
                    break;
                case "INF_PREDETERMINATION_STATUS_NOT_ON_FILE":
                case 2:
                    message.predeterminationStatus = 2;
                    break;
                case "INF_PREDETERMINATION_STATUS_APPROVED":
                case 3:
                    message.predeterminationStatus = 3;
                    break;
                case "INF_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR":
                case 4:
                    message.predeterminationStatus = 4;
                    break;
                case "INF_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS":
                case 5:
                    message.predeterminationStatus = 5;
                    break;
                case "INF_PREDETERMINATION_STATUS_DENIED":
                case 6:
                    message.predeterminationStatus = 6;
                    break;
                case "INF_PREDETERMINATION_STATUS_EXPIRED":
                case 7:
                    message.predeterminationStatus = 7;
                    break;
                case "INF_PREDETERMINATION_STATUS_FUTURE":
                case 8:
                    message.predeterminationStatus = 8;
                    break;
                case "INF_PREDETERMINATION_STATUS_PENDING":
                case 9:
                    message.predeterminationStatus = 9;
                    break;
                case "INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS":
                case 10:
                    message.predeterminationStatus = 10;
                    break;
                case "INF_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS":
                case 11:
                    message.predeterminationStatus = 11;
                    break;
                }
                if (object.predeterminationSubmissionDate != null)
                    message.predeterminationSubmissionDate = String(object.predeterminationSubmissionDate);
                if (object.predeterminationTurnaroundTime != null)
                    message.predeterminationTurnaroundTime = String(object.predeterminationTurnaroundTime);
                if (object.preferredSpecialtyPharmaciesAvailable) {
                    if (!Array.isArray(object.preferredSpecialtyPharmaciesAvailable))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.preferredSpecialtyPharmaciesAvailable: array expected");
                    message.preferredSpecialtyPharmaciesAvailable = [];
                    for (var i = 0; i < object.preferredSpecialtyPharmaciesAvailable.length; ++i) {
                        if (typeof object.preferredSpecialtyPharmaciesAvailable[i] !== "object")
                            throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.preferredSpecialtyPharmaciesAvailable: object expected");
                        message.preferredSpecialtyPharmaciesAvailable[i] = $root.infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy.fromObject(object.preferredSpecialtyPharmaciesAvailable[i]);
                    }
                }
                if (object.priorAuthAddressOnFile != null) {
                    if (typeof object.priorAuthAddressOnFile !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthAddressOnFile: object expected");
                    message.priorAuthAddressOnFile = $root.infinitusapi.INFAddress.fromObject(object.priorAuthAddressOnFile);
                }
                if (object.priorAuthApprovalDate != null)
                    message.priorAuthApprovalDate = String(object.priorAuthApprovalDate);
                if (object.priorAuthApprovalNumber != null)
                    message.priorAuthApprovalNumber = String(object.priorAuthApprovalNumber);
                if (object.priorAuthApprovedQuantity != null)
                    message.priorAuthApprovedQuantity = String(object.priorAuthApprovedQuantity);
                if (object.priorAuthApprovedQuantityUsed != null)
                    message.priorAuthApprovedQuantityUsed = String(object.priorAuthApprovedQuantityUsed);
                if (object.priorAuthApprovedTreatments != null)
                    message.priorAuthApprovedTreatments = String(object.priorAuthApprovedTreatments);
                if (object.priorAuthApprovedTreatmentsUsed != null)
                    message.priorAuthApprovedTreatmentsUsed = String(object.priorAuthApprovedTreatmentsUsed);
                if (object.priorAuthCodesOnFile) {
                    if (!Array.isArray(object.priorAuthCodesOnFile))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthCodesOnFile: array expected");
                    message.priorAuthCodesOnFile = [];
                    for (var i = 0; i < object.priorAuthCodesOnFile.length; ++i)
                        message.priorAuthCodesOnFile[i] = String(object.priorAuthCodesOnFile[i]);
                }
                if (object.priorAuthCompletionDate != null)
                    message.priorAuthCompletionDate = String(object.priorAuthCompletionDate);
                if (object.priorAuthEndDate != null)
                    message.priorAuthEndDate = String(object.priorAuthEndDate);
                if (object.priorAuthExceptionReason != null)
                    message.priorAuthExceptionReason = String(object.priorAuthExceptionReason);
                if (object.priorAuthInitiationDate != null)
                    message.priorAuthInitiationDate = String(object.priorAuthInitiationDate);
                switch (object.priorAuthNotificationMethod) {
                default:
                    if (typeof object.priorAuthNotificationMethod === "number") {
                        message.priorAuthNotificationMethod = object.priorAuthNotificationMethod;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                case 0:
                    message.priorAuthNotificationMethod = 0;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                case 1:
                    message.priorAuthNotificationMethod = 1;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                case 2:
                    message.priorAuthNotificationMethod = 2;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                case 3:
                    message.priorAuthNotificationMethod = 3;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                case 4:
                    message.priorAuthNotificationMethod = 4;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                case 5:
                    message.priorAuthNotificationMethod = 5;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                case 6:
                    message.priorAuthNotificationMethod = 6;
                    break;
                }
                if (object.priorAuthNotificationMethods) {
                    if (!Array.isArray(object.priorAuthNotificationMethods))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthNotificationMethods: array expected");
                    message.priorAuthNotificationMethods = [];
                    for (var i = 0; i < object.priorAuthNotificationMethods.length; ++i)
                        switch (object.priorAuthNotificationMethods[i]) {
                        default:
                            if (typeof object.priorAuthNotificationMethods[i] === "number") {
                                message.priorAuthNotificationMethods[i] = object.priorAuthNotificationMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                        case 0:
                            message.priorAuthNotificationMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.priorAuthNotificationMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                        case 2:
                            message.priorAuthNotificationMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                        case 3:
                            message.priorAuthNotificationMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                        case 4:
                            message.priorAuthNotificationMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                        case 5:
                            message.priorAuthNotificationMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                        case 6:
                            message.priorAuthNotificationMethods[i] = 6;
                            break;
                        }
                }
                if (object.priorAuthOnFile != null) {
                    if (typeof object.priorAuthOnFile !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthOnFile: object expected");
                    message.priorAuthOnFile = $root.google.protobuf.BoolValue.fromObject(object.priorAuthOnFile);
                }
                if (object.priorAuthOnFileApplies != null) {
                    if (typeof object.priorAuthOnFileApplies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthOnFileApplies: object expected");
                    message.priorAuthOnFileApplies = $root.google.protobuf.BoolValue.fromObject(object.priorAuthOnFileApplies);
                }
                if (object.priorAuthProviderNameOnFile != null)
                    message.priorAuthProviderNameOnFile = String(object.priorAuthProviderNameOnFile);
                if (object.priorAuthRenewalProcessExists != null) {
                    if (typeof object.priorAuthRenewalProcessExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthRenewalProcessExists: object expected");
                    message.priorAuthRenewalProcessExists = $root.google.protobuf.BoolValue.fromObject(object.priorAuthRenewalProcessExists);
                }
                if (object.priorAuthRequired != null) {
                    if (typeof object.priorAuthRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthRequired: object expected");
                    message.priorAuthRequired = $root.google.protobuf.BoolValue.fromObject(object.priorAuthRequired);
                }
                if (object.priorAuthRequiredCodes) {
                    if (!Array.isArray(object.priorAuthRequiredCodes))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthRequiredCodes: array expected");
                    message.priorAuthRequiredCodes = [];
                    for (var i = 0; i < object.priorAuthRequiredCodes.length; ++i)
                        message.priorAuthRequiredCodes[i] = String(object.priorAuthRequiredCodes[i]);
                }
                switch (object.priorAuthResponsibleOrg) {
                default:
                    if (typeof object.priorAuthResponsibleOrg === "number") {
                        message.priorAuthResponsibleOrg = object.priorAuthResponsibleOrg;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_RESPONSIBLE_ORG_UNKNOWN":
                case 0:
                    message.priorAuthResponsibleOrg = 0;
                    break;
                case "INF_PRIOR_AUTH_RESPONSIBLE_ORG_NOT_APPLICABLE":
                case 1:
                    message.priorAuthResponsibleOrg = 1;
                    break;
                case "INF_PRIOR_AUTH_RESPONSIBLE_ORG_PLAN":
                case 2:
                    message.priorAuthResponsibleOrg = 2;
                    break;
                case "INF_PRIOR_AUTH_RESPONSIBLE_ORG_MEDICAL_GROUP":
                case 3:
                    message.priorAuthResponsibleOrg = 3;
                    break;
                }
                if (object.priorAuthStartDate != null)
                    message.priorAuthStartDate = String(object.priorAuthStartDate);
                switch (object.priorAuthStatus) {
                default:
                    if (typeof object.priorAuthStatus === "number") {
                        message.priorAuthStatus = object.priorAuthStatus;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_STATUS_UNKNOWN":
                case 0:
                    message.priorAuthStatus = 0;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_APPLICABLE":
                case 1:
                    message.priorAuthStatus = 1;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_ON_FILE":
                case 2:
                    message.priorAuthStatus = 2;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED":
                case 3:
                    message.priorAuthStatus = 3;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR":
                case 4:
                    message.priorAuthStatus = 4;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS":
                case 5:
                    message.priorAuthStatus = 5;
                    break;
                case "INF_PRIOR_AUTH_STATUS_DENIED":
                case 6:
                    message.priorAuthStatus = 6;
                    break;
                case "INF_PRIOR_AUTH_STATUS_EXPIRED":
                case 7:
                    message.priorAuthStatus = 7;
                    break;
                case "INF_PRIOR_AUTH_STATUS_FUTURE":
                case 8:
                    message.priorAuthStatus = 8;
                    break;
                case "INF_PRIOR_AUTH_STATUS_PENDING":
                case 9:
                    message.priorAuthStatus = 9;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS":
                case 10:
                    message.priorAuthStatus = 10;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS":
                case 11:
                    message.priorAuthStatus = 11;
                    break;
                }
                if (object.priorAuthSubmissionDate != null)
                    message.priorAuthSubmissionDate = String(object.priorAuthSubmissionDate);
                switch (object.priorAuthSubmissionMethod) {
                default:
                    if (typeof object.priorAuthSubmissionMethod === "number") {
                        message.priorAuthSubmissionMethod = object.priorAuthSubmissionMethod;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN":
                case 0:
                    message.priorAuthSubmissionMethod = 0;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE":
                case 1:
                    message.priorAuthSubmissionMethod = 1;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE":
                case 2:
                    message.priorAuthSubmissionMethod = 2;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX":
                case 3:
                    message.priorAuthSubmissionMethod = 3;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL":
                case 4:
                    message.priorAuthSubmissionMethod = 4;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE":
                case 5:
                    message.priorAuthSubmissionMethod = 5;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL":
                case 6:
                    message.priorAuthSubmissionMethod = 6;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION":
                case 7:
                    message.priorAuthSubmissionMethod = 7;
                    break;
                }
                if (object.priorAuthSubmissionMethods) {
                    if (!Array.isArray(object.priorAuthSubmissionMethods))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.priorAuthSubmissionMethods: array expected");
                    message.priorAuthSubmissionMethods = [];
                    for (var i = 0; i < object.priorAuthSubmissionMethods.length; ++i)
                        switch (object.priorAuthSubmissionMethods[i]) {
                        default:
                            if (typeof object.priorAuthSubmissionMethods[i] === "number") {
                                message.priorAuthSubmissionMethods[i] = object.priorAuthSubmissionMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN":
                        case 0:
                            message.priorAuthSubmissionMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.priorAuthSubmissionMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE":
                        case 2:
                            message.priorAuthSubmissionMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX":
                        case 3:
                            message.priorAuthSubmissionMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL":
                        case 4:
                            message.priorAuthSubmissionMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE":
                        case 5:
                            message.priorAuthSubmissionMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL":
                        case 6:
                            message.priorAuthSubmissionMethods[i] = 6;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION":
                        case 7:
                            message.priorAuthSubmissionMethods[i] = 7;
                            break;
                        }
                }
                if (object.priorAuthTurnaroundTime != null)
                    message.priorAuthTurnaroundTime = String(object.priorAuthTurnaroundTime);
                switch (object.priorAuthType) {
                default:
                    if (typeof object.priorAuthType === "number") {
                        message.priorAuthType = object.priorAuthType;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_TYPE_UNKNOWN":
                case 0:
                    message.priorAuthType = 0;
                    break;
                case "INF_PRIOR_AUTH_TYPE_PRIOR_AUTHORIZATION":
                case 1:
                    message.priorAuthType = 1;
                    break;
                case "INF_PRIOR_AUTH_TYPE_PRECERTIFICATION":
                case 2:
                    message.priorAuthType = 2;
                    break;
                case "INF_PRIOR_AUTH_TYPE_PREDETERMINATION":
                case 3:
                    message.priorAuthType = 3;
                    break;
                case "INF_PRIOR_AUTH_TYPE_FORMULARY_EXCEPTION":
                case 4:
                    message.priorAuthType = 4;
                    break;
                case "INF_PRIOR_AUTH_TYPE_TIER_EXCEPTION":
                case 5:
                    message.priorAuthType = 5;
                    break;
                case "INF_PRIOR_AUTH_TYPE_NOT_APPLICABLE":
                case 6:
                    message.priorAuthType = 6;
                    break;
                case "INF_PRIOR_AUTH_TYPE_NONE":
                case 7:
                    message.priorAuthType = 7;
                    break;
                }
                if (object.quantityLimit != null)
                    message.quantityLimit = String(object.quantityLimit);
                if (object.referralAddress != null) {
                    if (typeof object.referralAddress !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.referralAddress: object expected");
                    message.referralAddress = $root.infinitusapi.INFAddress.fromObject(object.referralAddress);
                }
                if (object.referralEffectiveDate != null)
                    message.referralEffectiveDate = String(object.referralEffectiveDate);
                if (object.referralFax != null)
                    message.referralFax = String(object.referralFax);
                if (object.referralNumVisitsApproved != null) {
                    if (typeof object.referralNumVisitsApproved !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.referralNumVisitsApproved: object expected");
                    message.referralNumVisitsApproved = $root.google.protobuf.DoubleValue.fromObject(object.referralNumVisitsApproved);
                }
                if (object.referralNumVisitsUsed != null) {
                    if (typeof object.referralNumVisitsUsed !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.referralNumVisitsUsed: object expected");
                    message.referralNumVisitsUsed = $root.google.protobuf.DoubleValue.fromObject(object.referralNumVisitsUsed);
                }
                if (object.referralNumber != null)
                    message.referralNumber = String(object.referralNumber);
                if (object.referralOnFile != null) {
                    if (typeof object.referralOnFile !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.referralOnFile: object expected");
                    message.referralOnFile = $root.google.protobuf.BoolValue.fromObject(object.referralOnFile);
                }
                if (object.referralRecertDate != null)
                    message.referralRecertDate = String(object.referralRecertDate);
                if (object.referralRequired != null) {
                    if (typeof object.referralRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.referralRequired: object expected");
                    message.referralRequired = $root.google.protobuf.BoolValue.fromObject(object.referralRequired);
                }
                switch (object.referralRequirements) {
                default:
                    if (typeof object.referralRequirements === "number") {
                        message.referralRequirements = object.referralRequirements;
                        break;
                    }
                    break;
                case "INF_REFERRAL_REQUIREMENTS_UNKNOWN":
                case 0:
                    message.referralRequirements = 0;
                    break;
                case "INF_REFERRAL_REQUIREMENTS_NOT_APPLICABLE":
                case 1:
                    message.referralRequirements = 1;
                    break;
                case "INF_REFERRAL_REQUIREMENTS_NOT_REQUIRED":
                case 2:
                    message.referralRequirements = 2;
                    break;
                case "INF_REFERRAL_REQUIREMENTS_REQUIRED":
                case 3:
                    message.referralRequirements = 3;
                    break;
                case "INF_REFERRAL_REQUIREMENTS_REQUIRED_BUT_WAIVED":
                case 4:
                    message.referralRequirements = 4;
                    break;
                case "INF_REFERRAL_REQUIREMENTS_DOCTOR_TO_DOCTOR":
                case 5:
                    message.referralRequirements = 5;
                    break;
                }
                if (object.referralSubmissionProcess != null)
                    message.referralSubmissionProcess = String(object.referralSubmissionProcess);
                if (object.referralWebsite != null)
                    message.referralWebsite = String(object.referralWebsite);
                if (object.specialtyPharmacy2Address != null) {
                    if (typeof object.specialtyPharmacy2Address !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacy2Address: object expected");
                    message.specialtyPharmacy2Address = $root.infinitusapi.INFAddress.fromObject(object.specialtyPharmacy2Address);
                }
                if (object.specialtyPharmacy2Fax != null)
                    message.specialtyPharmacy2Fax = String(object.specialtyPharmacy2Fax);
                if (object.specialtyPharmacy2Name != null)
                    message.specialtyPharmacy2Name = String(object.specialtyPharmacy2Name);
                if (object.specialtyPharmacy2Phone != null)
                    message.specialtyPharmacy2Phone = String(object.specialtyPharmacy2Phone);
                if (object.specialtyPharmacy3Address != null) {
                    if (typeof object.specialtyPharmacy3Address !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacy3Address: object expected");
                    message.specialtyPharmacy3Address = $root.infinitusapi.INFAddress.fromObject(object.specialtyPharmacy3Address);
                }
                if (object.specialtyPharmacy3Fax != null)
                    message.specialtyPharmacy3Fax = String(object.specialtyPharmacy3Fax);
                if (object.specialtyPharmacy3Name != null)
                    message.specialtyPharmacy3Name = String(object.specialtyPharmacy3Name);
                if (object.specialtyPharmacy3Phone != null)
                    message.specialtyPharmacy3Phone = String(object.specialtyPharmacy3Phone);
                if (object.specialtyPharmacyAddress != null) {
                    if (typeof object.specialtyPharmacyAddress !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacyAddress: object expected");
                    message.specialtyPharmacyAddress = $root.infinitusapi.INFAddress.fromObject(object.specialtyPharmacyAddress);
                }
                switch (object.specialtyPharmacyAvailability) {
                default:
                    if (typeof object.specialtyPharmacyAvailability === "number") {
                        message.specialtyPharmacyAvailability = object.specialtyPharmacyAvailability;
                        break;
                    }
                    break;
                case "INF_SPECIALTY_PHARMACY_AVAILABILITY_UNKNOWN":
                case 0:
                    message.specialtyPharmacyAvailability = 0;
                    break;
                case "INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_APPLICABLE":
                case 1:
                    message.specialtyPharmacyAvailability = 1;
                    break;
                case "INF_SPECIALTY_PHARMACY_AVAILABILITY_REQUIRED":
                case 2:
                    message.specialtyPharmacyAvailability = 2;
                    break;
                case "INF_SPECIALTY_PHARMACY_AVAILABILITY_AVAILABLE":
                case 3:
                    message.specialtyPharmacyAvailability = 3;
                    break;
                case "INF_SPECIALTY_PHARMACY_AVAILABILITY_NOT_AVAILABLE":
                case 4:
                    message.specialtyPharmacyAvailability = 4;
                    break;
                }
                if (object.specialtyPharmacyExclusions != null)
                    message.specialtyPharmacyExclusions = String(object.specialtyPharmacyExclusions);
                if (object.specialtyPharmacyFax != null)
                    message.specialtyPharmacyFax = String(object.specialtyPharmacyFax);
                if (object.specialtyPharmacyName != null)
                    message.specialtyPharmacyName = String(object.specialtyPharmacyName);
                switch (object.specialtyPharmacyNetwork) {
                default:
                    if (typeof object.specialtyPharmacyNetwork === "number") {
                        message.specialtyPharmacyNetwork = object.specialtyPharmacyNetwork;
                        break;
                    }
                    break;
                case "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_UNKNOWN":
                case 0:
                    message.specialtyPharmacyNetwork = 0;
                    break;
                case "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_NOT_APPLICABLE":
                case 1:
                    message.specialtyPharmacyNetwork = 1;
                    break;
                case "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_EXCLUSIVE":
                case 2:
                    message.specialtyPharmacyNetwork = 2;
                    break;
                case "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_PREFERRED":
                case 3:
                    message.specialtyPharmacyNetwork = 3;
                    break;
                case "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_LIMITED":
                case 4:
                    message.specialtyPharmacyNetwork = 4;
                    break;
                case "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_OPEN":
                case 5:
                    message.specialtyPharmacyNetwork = 5;
                    break;
                }
                if (object.specialtyPharmacyObtainPreDeterminationOrg != null)
                    message.specialtyPharmacyObtainPreDeterminationOrg = String(object.specialtyPharmacyObtainPreDeterminationOrg);
                if (object.specialtyPharmacyObtainPredeterminationFax != null)
                    message.specialtyPharmacyObtainPredeterminationFax = String(object.specialtyPharmacyObtainPredeterminationFax);
                if (object.specialtyPharmacyObtainPredeterminationPhone != null)
                    message.specialtyPharmacyObtainPredeterminationPhone = String(object.specialtyPharmacyObtainPredeterminationPhone);
                if (object.specialtyPharmacyObtainPredeterminationWebsite != null)
                    message.specialtyPharmacyObtainPredeterminationWebsite = String(object.specialtyPharmacyObtainPredeterminationWebsite);
                if (object.specialtyPharmacyObtainPriorAuthAddress != null) {
                    if (typeof object.specialtyPharmacyObtainPriorAuthAddress !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacyObtainPriorAuthAddress: object expected");
                    message.specialtyPharmacyObtainPriorAuthAddress = $root.infinitusapi.INFAddress.fromObject(object.specialtyPharmacyObtainPriorAuthAddress);
                }
                if (object.specialtyPharmacyObtainPriorAuthFax != null)
                    message.specialtyPharmacyObtainPriorAuthFax = String(object.specialtyPharmacyObtainPriorAuthFax);
                if (object.specialtyPharmacyObtainPriorAuthFormLink != null)
                    message.specialtyPharmacyObtainPriorAuthFormLink = String(object.specialtyPharmacyObtainPriorAuthFormLink);
                if (object.specialtyPharmacyObtainPriorAuthOrg != null)
                    message.specialtyPharmacyObtainPriorAuthOrg = String(object.specialtyPharmacyObtainPriorAuthOrg);
                if (object.specialtyPharmacyObtainPriorAuthPhone != null)
                    message.specialtyPharmacyObtainPriorAuthPhone = String(object.specialtyPharmacyObtainPriorAuthPhone);
                if (object.specialtyPharmacyObtainPriorAuthRequirements) {
                    if (!Array.isArray(object.specialtyPharmacyObtainPriorAuthRequirements))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacyObtainPriorAuthRequirements: array expected");
                    message.specialtyPharmacyObtainPriorAuthRequirements = [];
                    for (var i = 0; i < object.specialtyPharmacyObtainPriorAuthRequirements.length; ++i)
                        switch (object.specialtyPharmacyObtainPriorAuthRequirements[i]) {
                        default:
                            if (typeof object.specialtyPharmacyObtainPriorAuthRequirements[i] === "number") {
                                message.specialtyPharmacyObtainPriorAuthRequirements[i] = object.specialtyPharmacyObtainPriorAuthRequirements[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_REQUIREMENT_UNKNOWN":
                        case 0:
                            message.specialtyPharmacyObtainPriorAuthRequirements[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_PA_FORM":
                        case 1:
                            message.specialtyPharmacyObtainPriorAuthRequirements[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY":
                        case 2:
                            message.specialtyPharmacyObtainPriorAuthRequirements[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS":
                        case 3:
                            message.specialtyPharmacyObtainPriorAuthRequirements[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET":
                        case 4:
                            message.specialtyPharmacyObtainPriorAuthRequirements[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE":
                        case 5:
                            message.specialtyPharmacyObtainPriorAuthRequirements[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_NOT_APPLICABLE":
                        case 6:
                            message.specialtyPharmacyObtainPriorAuthRequirements[i] = 6;
                            break;
                        }
                }
                if (object.specialtyPharmacyObtainPriorAuthWebsite != null)
                    message.specialtyPharmacyObtainPriorAuthWebsite = String(object.specialtyPharmacyObtainPriorAuthWebsite);
                if (object.specialtyPharmacyPhone != null)
                    message.specialtyPharmacyPhone = String(object.specialtyPharmacyPhone);
                if (object.specialtyPharmacyPredeterminationApprovedQuantity != null)
                    message.specialtyPharmacyPredeterminationApprovedQuantity = String(object.specialtyPharmacyPredeterminationApprovedQuantity);
                if (object.specialtyPharmacyPredeterminationApprovedQuantityUsed != null)
                    message.specialtyPharmacyPredeterminationApprovedQuantityUsed = String(object.specialtyPharmacyPredeterminationApprovedQuantityUsed);
                if (object.specialtyPharmacyPredeterminationEndDate != null)
                    message.specialtyPharmacyPredeterminationEndDate = String(object.specialtyPharmacyPredeterminationEndDate);
                if (object.specialtyPharmacyPredeterminationNumber != null)
                    message.specialtyPharmacyPredeterminationNumber = String(object.specialtyPharmacyPredeterminationNumber);
                if (object.specialtyPharmacyPredeterminationOnFile != null) {
                    if (typeof object.specialtyPharmacyPredeterminationOnFile !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacyPredeterminationOnFile: object expected");
                    message.specialtyPharmacyPredeterminationOnFile = $root.google.protobuf.BoolValue.fromObject(object.specialtyPharmacyPredeterminationOnFile);
                }
                switch (object.specialtyPharmacyPredeterminationRequirementType) {
                default:
                    if (typeof object.specialtyPharmacyPredeterminationRequirementType === "number") {
                        message.specialtyPharmacyPredeterminationRequirementType = object.specialtyPharmacyPredeterminationRequirementType;
                        break;
                    }
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_UNKNOWN":
                case 0:
                    message.specialtyPharmacyPredeterminationRequirementType = 0;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_NOT_APPLICABLE":
                case 1:
                    message.specialtyPharmacyPredeterminationRequirementType = 1;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_REQUIRED":
                case 2:
                    message.specialtyPharmacyPredeterminationRequirementType = 2;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED":
                case 3:
                    message.specialtyPharmacyPredeterminationRequirementType = 3;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_AVAILABLE":
                case 4:
                    message.specialtyPharmacyPredeterminationRequirementType = 4;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE":
                case 5:
                    message.specialtyPharmacyPredeterminationRequirementType = 5;
                    break;
                }
                if (object.specialtyPharmacyPredeterminationStartDate != null)
                    message.specialtyPharmacyPredeterminationStartDate = String(object.specialtyPharmacyPredeterminationStartDate);
                switch (object.specialtyPharmacyPredeterminationStatus) {
                default:
                    if (typeof object.specialtyPharmacyPredeterminationStatus === "number") {
                        message.specialtyPharmacyPredeterminationStatus = object.specialtyPharmacyPredeterminationStatus;
                        break;
                    }
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_UNKNOWN":
                case 0:
                    message.specialtyPharmacyPredeterminationStatus = 0;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_APPLICABLE":
                case 1:
                    message.specialtyPharmacyPredeterminationStatus = 1;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_NOT_ON_FILE":
                case 2:
                    message.specialtyPharmacyPredeterminationStatus = 2;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED":
                case 3:
                    message.specialtyPharmacyPredeterminationStatus = 3;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DOCTOR":
                case 4:
                    message.specialtyPharmacyPredeterminationStatus = 4;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_APPROVED_DIFFERENT_DIAGNOSIS":
                case 5:
                    message.specialtyPharmacyPredeterminationStatus = 5;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_DENIED":
                case 6:
                    message.specialtyPharmacyPredeterminationStatus = 6;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_EXPIRED":
                case 7:
                    message.specialtyPharmacyPredeterminationStatus = 7;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_FUTURE":
                case 8:
                    message.specialtyPharmacyPredeterminationStatus = 8;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_PENDING":
                case 9:
                    message.specialtyPharmacyPredeterminationStatus = 9;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_POS":
                case 10:
                    message.specialtyPharmacyPredeterminationStatus = 10;
                    break;
                case "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_APPROVED_DIFFERENT_ADDRESS":
                case 11:
                    message.specialtyPharmacyPredeterminationStatus = 11;
                    break;
                }
                if (object.specialtyPharmacyPredeterminationTurnaroundTime != null)
                    message.specialtyPharmacyPredeterminationTurnaroundTime = String(object.specialtyPharmacyPredeterminationTurnaroundTime);
                if (object.specialtyPharmacyPriorAuthApprovalNumber != null)
                    message.specialtyPharmacyPriorAuthApprovalNumber = String(object.specialtyPharmacyPriorAuthApprovalNumber);
                if (object.specialtyPharmacyPriorAuthApprovedQuantity != null)
                    message.specialtyPharmacyPriorAuthApprovedQuantity = String(object.specialtyPharmacyPriorAuthApprovedQuantity);
                if (object.specialtyPharmacyPriorAuthApprovedQuantityUsed != null)
                    message.specialtyPharmacyPriorAuthApprovedQuantityUsed = String(object.specialtyPharmacyPriorAuthApprovedQuantityUsed);
                if (object.specialtyPharmacyPriorAuthApprovedTreatments != null)
                    message.specialtyPharmacyPriorAuthApprovedTreatments = String(object.specialtyPharmacyPriorAuthApprovedTreatments);
                if (object.specialtyPharmacyPriorAuthApprovedTreatmentsUsed != null)
                    message.specialtyPharmacyPriorAuthApprovedTreatmentsUsed = String(object.specialtyPharmacyPriorAuthApprovedTreatmentsUsed);
                if (object.specialtyPharmacyPriorAuthEndDate != null)
                    message.specialtyPharmacyPriorAuthEndDate = String(object.specialtyPharmacyPriorAuthEndDate);
                switch (object.specialtyPharmacyPriorAuthNotificationMethod) {
                default:
                    if (typeof object.specialtyPharmacyPriorAuthNotificationMethod === "number") {
                        message.specialtyPharmacyPriorAuthNotificationMethod = object.specialtyPharmacyPriorAuthNotificationMethod;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                case 0:
                    message.specialtyPharmacyPriorAuthNotificationMethod = 0;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                case 1:
                    message.specialtyPharmacyPriorAuthNotificationMethod = 1;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                case 2:
                    message.specialtyPharmacyPriorAuthNotificationMethod = 2;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                case 3:
                    message.specialtyPharmacyPriorAuthNotificationMethod = 3;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                case 4:
                    message.specialtyPharmacyPriorAuthNotificationMethod = 4;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                case 5:
                    message.specialtyPharmacyPriorAuthNotificationMethod = 5;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                case 6:
                    message.specialtyPharmacyPriorAuthNotificationMethod = 6;
                    break;
                }
                if (object.specialtyPharmacyPriorAuthNotificationMethods) {
                    if (!Array.isArray(object.specialtyPharmacyPriorAuthNotificationMethods))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacyPriorAuthNotificationMethods: array expected");
                    message.specialtyPharmacyPriorAuthNotificationMethods = [];
                    for (var i = 0; i < object.specialtyPharmacyPriorAuthNotificationMethods.length; ++i)
                        switch (object.specialtyPharmacyPriorAuthNotificationMethods[i]) {
                        default:
                            if (typeof object.specialtyPharmacyPriorAuthNotificationMethods[i] === "number") {
                                message.specialtyPharmacyPriorAuthNotificationMethods[i] = object.specialtyPharmacyPriorAuthNotificationMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                        case 0:
                            message.specialtyPharmacyPriorAuthNotificationMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.specialtyPharmacyPriorAuthNotificationMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                        case 2:
                            message.specialtyPharmacyPriorAuthNotificationMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                        case 3:
                            message.specialtyPharmacyPriorAuthNotificationMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                        case 4:
                            message.specialtyPharmacyPriorAuthNotificationMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                        case 5:
                            message.specialtyPharmacyPriorAuthNotificationMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                        case 6:
                            message.specialtyPharmacyPriorAuthNotificationMethods[i] = 6;
                            break;
                        }
                }
                if (object.specialtyPharmacyPriorAuthProcessDifferent != null) {
                    if (typeof object.specialtyPharmacyPriorAuthProcessDifferent !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacyPriorAuthProcessDifferent: object expected");
                    message.specialtyPharmacyPriorAuthProcessDifferent = $root.google.protobuf.BoolValue.fromObject(object.specialtyPharmacyPriorAuthProcessDifferent);
                }
                if (object.specialtyPharmacyPriorAuthRequired != null) {
                    if (typeof object.specialtyPharmacyPriorAuthRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacyPriorAuthRequired: object expected");
                    message.specialtyPharmacyPriorAuthRequired = $root.google.protobuf.BoolValue.fromObject(object.specialtyPharmacyPriorAuthRequired);
                }
                if (object.specialtyPharmacyPriorAuthStartDate != null)
                    message.specialtyPharmacyPriorAuthStartDate = String(object.specialtyPharmacyPriorAuthStartDate);
                switch (object.specialtyPharmacyPriorAuthStatus) {
                default:
                    if (typeof object.specialtyPharmacyPriorAuthStatus === "number") {
                        message.specialtyPharmacyPriorAuthStatus = object.specialtyPharmacyPriorAuthStatus;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_STATUS_UNKNOWN":
                case 0:
                    message.specialtyPharmacyPriorAuthStatus = 0;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_APPLICABLE":
                case 1:
                    message.specialtyPharmacyPriorAuthStatus = 1;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_ON_FILE":
                case 2:
                    message.specialtyPharmacyPriorAuthStatus = 2;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED":
                case 3:
                    message.specialtyPharmacyPriorAuthStatus = 3;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR":
                case 4:
                    message.specialtyPharmacyPriorAuthStatus = 4;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS":
                case 5:
                    message.specialtyPharmacyPriorAuthStatus = 5;
                    break;
                case "INF_PRIOR_AUTH_STATUS_DENIED":
                case 6:
                    message.specialtyPharmacyPriorAuthStatus = 6;
                    break;
                case "INF_PRIOR_AUTH_STATUS_EXPIRED":
                case 7:
                    message.specialtyPharmacyPriorAuthStatus = 7;
                    break;
                case "INF_PRIOR_AUTH_STATUS_FUTURE":
                case 8:
                    message.specialtyPharmacyPriorAuthStatus = 8;
                    break;
                case "INF_PRIOR_AUTH_STATUS_PENDING":
                case 9:
                    message.specialtyPharmacyPriorAuthStatus = 9;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS":
                case 10:
                    message.specialtyPharmacyPriorAuthStatus = 10;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS":
                case 11:
                    message.specialtyPharmacyPriorAuthStatus = 11;
                    break;
                }
                switch (object.specialtyPharmacyPriorAuthSubmissionMethod) {
                default:
                    if (typeof object.specialtyPharmacyPriorAuthSubmissionMethod === "number") {
                        message.specialtyPharmacyPriorAuthSubmissionMethod = object.specialtyPharmacyPriorAuthSubmissionMethod;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN":
                case 0:
                    message.specialtyPharmacyPriorAuthSubmissionMethod = 0;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE":
                case 1:
                    message.specialtyPharmacyPriorAuthSubmissionMethod = 1;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE":
                case 2:
                    message.specialtyPharmacyPriorAuthSubmissionMethod = 2;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX":
                case 3:
                    message.specialtyPharmacyPriorAuthSubmissionMethod = 3;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL":
                case 4:
                    message.specialtyPharmacyPriorAuthSubmissionMethod = 4;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE":
                case 5:
                    message.specialtyPharmacyPriorAuthSubmissionMethod = 5;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL":
                case 6:
                    message.specialtyPharmacyPriorAuthSubmissionMethod = 6;
                    break;
                case "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION":
                case 7:
                    message.specialtyPharmacyPriorAuthSubmissionMethod = 7;
                    break;
                }
                if (object.specialtyPharmacyPriorAuthSubmissionMethods) {
                    if (!Array.isArray(object.specialtyPharmacyPriorAuthSubmissionMethods))
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.specialtyPharmacyPriorAuthSubmissionMethods: array expected");
                    message.specialtyPharmacyPriorAuthSubmissionMethods = [];
                    for (var i = 0; i < object.specialtyPharmacyPriorAuthSubmissionMethods.length; ++i)
                        switch (object.specialtyPharmacyPriorAuthSubmissionMethods[i]) {
                        default:
                            if (typeof object.specialtyPharmacyPriorAuthSubmissionMethods[i] === "number") {
                                message.specialtyPharmacyPriorAuthSubmissionMethods[i] = object.specialtyPharmacyPriorAuthSubmissionMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN":
                        case 0:
                            message.specialtyPharmacyPriorAuthSubmissionMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.specialtyPharmacyPriorAuthSubmissionMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE":
                        case 2:
                            message.specialtyPharmacyPriorAuthSubmissionMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX":
                        case 3:
                            message.specialtyPharmacyPriorAuthSubmissionMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL":
                        case 4:
                            message.specialtyPharmacyPriorAuthSubmissionMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE":
                        case 5:
                            message.specialtyPharmacyPriorAuthSubmissionMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL":
                        case 6:
                            message.specialtyPharmacyPriorAuthSubmissionMethods[i] = 6;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION":
                        case 7:
                            message.specialtyPharmacyPriorAuthSubmissionMethods[i] = 7;
                            break;
                        }
                }
                if (object.specialtyPharmacyPriorAuthTurnaroundTime != null)
                    message.specialtyPharmacyPriorAuthTurnaroundTime = String(object.specialtyPharmacyPriorAuthTurnaroundTime);
                switch (object.stepTherapyMethod) {
                default:
                    if (typeof object.stepTherapyMethod === "number") {
                        message.stepTherapyMethod = object.stepTherapyMethod;
                        break;
                    }
                    break;
                case "INF_STEP_THERAPY_METHOD_UNKNOWN":
                case 0:
                    message.stepTherapyMethod = 0;
                    break;
                case "INF_STEP_THERAPY_METHOD_NOT_APPLICABLE":
                case 1:
                    message.stepTherapyMethod = 1;
                    break;
                case "INF_STEP_THERAPY_METHOD_PREFERRED_TREATMENT":
                case 2:
                    message.stepTherapyMethod = 2;
                    break;
                case "INF_STEP_THERAPY_METHOD_SUBMIT_CLINICAL_NOTES":
                case 3:
                    message.stepTherapyMethod = 3;
                    break;
                }
                if (object.stepTherapyRequired != null) {
                    if (typeof object.stepTherapyRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.stepTherapyRequired: object expected");
                    message.stepTherapyRequired = $root.google.protobuf.BoolValue.fromObject(object.stepTherapyRequired);
                }
                if (object.stepTherapyTreatment != null)
                    message.stepTherapyTreatment = String(object.stepTherapyTreatment);
                if (object.therapyAvailabilityDate != null)
                    message.therapyAvailabilityDate = String(object.therapyAvailabilityDate);
                if (object.therapySeasonStartDate != null)
                    message.therapySeasonStartDate = String(object.therapySeasonStartDate);
                if (object.timelyFilingPeriod != null)
                    message.timelyFilingPeriod = String(object.timelyFilingPeriod);
                if (object.transportationServiceCovered != null) {
                    if (typeof object.transportationServiceCovered !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputAccessInfo.transportationServiceCovered: object expected");
                    message.transportationServiceCovered = $root.google.protobuf.BoolValue.fromObject(object.transportationServiceCovered);
                }
                if (object.transportationServicePhoneNumber != null)
                    message.transportationServicePhoneNumber = String(object.transportationServicePhoneNumber);
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputAccessInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {infinitusapi.INFBVTaskOutputAccessInfo} message INFBVTaskOutputAccessInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputAccessInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.cptObtainPriorAuthRequirements = [];
                    object.cptPriorAuthNotificationMethods = [];
                    object.cptPriorAuthSubmissionMethods = [];
                    object.obtainPriorAuthRequirements = [];
                    object.preferredSpecialtyPharmaciesAvailable = [];
                    object.priorAuthCodesOnFile = [];
                    object.priorAuthNotificationMethods = [];
                    object.priorAuthRequiredCodes = [];
                    object.priorAuthSubmissionMethods = [];
                    object.specialtyPharmacyObtainPriorAuthRequirements = [];
                    object.specialtyPharmacyPriorAuthNotificationMethods = [];
                    object.specialtyPharmacyPriorAuthSubmissionMethods = [];
                }
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.attestationProcess = "";
                    object.attestationProcessExists = null;
                    object.buyAndBillAvailability = options.enums === String ? "INF_BUY_AND_BILL_AVAILABILITY_UNKNOWN" : 0;
                    object.claimsAddress = null;
                    object.coverageExceptionProcessExists = null;
                    object.coverageExceptionTurnaroundTime = "";
                    object.coverageRestrictions = "";
                    object.cptObtainPreDeterminationOrg = "";
                    object.cptObtainPredeterminationFax = "";
                    object.cptObtainPredeterminationPhone = "";
                    object.cptObtainPredeterminationWebsite = "";
                    object.cptObtainPriorAuthAddress = null;
                    object.cptObtainPriorAuthFax = "";
                    object.cptObtainPriorAuthFormLink = "";
                    object.cptObtainPriorAuthOrg = "";
                    object.cptObtainPriorAuthPhone = "";
                    object.cptObtainPriorAuthWebsite = "";
                    object.cptPredeterminationApprovedQuantity = "";
                    object.cptPredeterminationApprovedQuantityUsed = "";
                    object.cptPredeterminationEndDate = "";
                    object.cptPredeterminationNumber = "";
                    object.cptPredeterminationStartDate = "";
                    object.cptPredeterminationTurnaroundTime = "";
                    object.cptPriorAuthApprovalNumber = "";
                    object.cptPriorAuthApprovedQuantity = "";
                    object.cptPriorAuthApprovedQuantityUsed = "";
                    object.cptPriorAuthApprovedTreatments = "";
                    object.cptPriorAuthApprovedTreatmentsUsed = "";
                    object.cptPriorAuthEndDate = "";
                    object.cptPriorAuthNotificationMethod = options.enums === String ? "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN" : 0;
                    object.cptPriorAuthProcessDifferent = null;
                    object.cptPriorAuthStartDate = "";
                    object.cptPriorAuthSubmissionMethod = options.enums === String ? "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN" : 0;
                    object.cptPriorAuthTurnaroundTime = "";
                    object.hasSpecialtyPharmacyExclusions = null;
                    object.homeHealthCovered = null;
                    object.homeHealthObtainPriorAuthFax = "";
                    object.homeHealthObtainPriorAuthPhone = "";
                    object.homeHealthPriorAuthRequired = null;
                    object.homeHealthProviderName = "";
                    object.homeHealthProviderPhone = "";
                    object.homeHealthServices = "";
                    object.homeboundRequired = null;
                    object.lodgingCovered = null;
                    object.lodgingServicePhoneNumber = "";
                    object.medicalGroupName = "";
                    object.medicalGroupPhoneNumber = "";
                    object.medicalPolicyAvailableOnWebsite = null;
                    object.medicalPolicyNumber = "";
                    object.obtainCoverageExceptionAddress = null;
                    object.obtainCoverageExceptionFax = "";
                    object.obtainCoverageExceptionPhone = "";
                    object.obtainCoverageExceptionProcess = "";
                    object.obtainCoverageExceptionWebsite = "";
                    object.obtainPreDeterminationOrg = "";
                    object.obtainPredeterminationFax = "";
                    object.obtainPredeterminationPhone = "";
                    object.obtainPredeterminationWebsite = "";
                    object.obtainPriorAuthAddress = null;
                    object.obtainPriorAuthFax = "";
                    object.obtainPriorAuthFormLink = "";
                    object.obtainPriorAuthOrg = "";
                    object.obtainPriorAuthPhone = "";
                    object.obtainPriorAuthWebsite = "";
                    object.obtainQuantityLimitExceptionPhone = "";
                    object.obtainQuantityLimitExceptionProcess = "";
                    object.pcpName = "";
                    object.pcpPhone = "";
                    object.preDeterminationAvailable = null;
                    object.preDeterminationNumber = "";
                    object.preDeterminationOnFile = null;
                    object.preDeterminationRequired = null;
                    object.preDeterminationRequirement = options.enums === String ? "INF_PREDETERMINATION_REQUIREMENT_UNKNOWN" : 0;
                    object.predeterminationAddressOnFile = null;
                    object.predeterminationApprovedQuantity = "";
                    object.predeterminationApprovedQuantityUsed = "";
                    object.predeterminationCompletionDate = "";
                    object.predeterminationEndDate = "";
                    object.predeterminationOnFileApplies = null;
                    object.predeterminationProviderNameOnFile = "";
                    object.predeterminationReceivedDate = "";
                    object.predeterminationRenewalProcessExists = null;
                    object.predeterminationStartDate = "";
                    object.predeterminationStatus = options.enums === String ? "INF_PREDETERMINATION_STATUS_UNKNOWN" : 0;
                    object.predeterminationSubmissionDate = "";
                    object.predeterminationTurnaroundTime = "";
                    object.priorAuthAddressOnFile = null;
                    object.priorAuthApprovalDate = "";
                    object.priorAuthApprovalNumber = "";
                    object.priorAuthApprovedQuantity = "";
                    object.priorAuthApprovedQuantityUsed = "";
                    object.priorAuthApprovedTreatments = "";
                    object.priorAuthApprovedTreatmentsUsed = "";
                    object.priorAuthCompletionDate = "";
                    object.priorAuthEndDate = "";
                    object.priorAuthExceptionReason = "";
                    object.priorAuthInitiationDate = "";
                    object.priorAuthNotificationMethod = options.enums === String ? "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN" : 0;
                    object.priorAuthOnFile = null;
                    object.priorAuthOnFileApplies = null;
                    object.priorAuthProviderNameOnFile = "";
                    object.priorAuthRenewalProcessExists = null;
                    object.priorAuthRequired = null;
                    object.priorAuthResponsibleOrg = options.enums === String ? "INF_PRIOR_AUTH_RESPONSIBLE_ORG_UNKNOWN" : 0;
                    object.priorAuthStartDate = "";
                    object.priorAuthStatus = options.enums === String ? "INF_PRIOR_AUTH_STATUS_UNKNOWN" : 0;
                    object.priorAuthSubmissionDate = "";
                    object.priorAuthSubmissionMethod = options.enums === String ? "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN" : 0;
                    object.priorAuthTurnaroundTime = "";
                    object.priorAuthType = options.enums === String ? "INF_PRIOR_AUTH_TYPE_UNKNOWN" : 0;
                    object.quantityLimit = "";
                    object.referralAddress = null;
                    object.referralEffectiveDate = "";
                    object.referralFax = "";
                    object.referralNumVisitsApproved = null;
                    object.referralNumVisitsUsed = null;
                    object.referralNumber = "";
                    object.referralOnFile = null;
                    object.referralRecertDate = "";
                    object.referralRequired = null;
                    object.referralRequirements = options.enums === String ? "INF_REFERRAL_REQUIREMENTS_UNKNOWN" : 0;
                    object.referralSubmissionProcess = "";
                    object.referralWebsite = "";
                    object.specialtyPharmacy2Address = null;
                    object.specialtyPharmacy2Fax = "";
                    object.specialtyPharmacy2Name = "";
                    object.specialtyPharmacy2Phone = "";
                    object.specialtyPharmacy3Address = null;
                    object.specialtyPharmacy3Fax = "";
                    object.specialtyPharmacy3Name = "";
                    object.specialtyPharmacy3Phone = "";
                    object.specialtyPharmacyAddress = null;
                    object.specialtyPharmacyAvailability = options.enums === String ? "INF_SPECIALTY_PHARMACY_AVAILABILITY_UNKNOWN" : 0;
                    object.specialtyPharmacyExclusions = "";
                    object.specialtyPharmacyFax = "";
                    object.specialtyPharmacyName = "";
                    object.specialtyPharmacyNetwork = options.enums === String ? "INF_SPECIALTY_PHARMACY_NETWORK_TYPE_UNKNOWN" : 0;
                    object.specialtyPharmacyObtainPreDeterminationOrg = "";
                    object.specialtyPharmacyObtainPredeterminationFax = "";
                    object.specialtyPharmacyObtainPredeterminationPhone = "";
                    object.specialtyPharmacyObtainPredeterminationWebsite = "";
                    object.specialtyPharmacyObtainPriorAuthAddress = null;
                    object.specialtyPharmacyObtainPriorAuthFax = "";
                    object.specialtyPharmacyObtainPriorAuthFormLink = "";
                    object.specialtyPharmacyObtainPriorAuthOrg = "";
                    object.specialtyPharmacyObtainPriorAuthPhone = "";
                    object.specialtyPharmacyObtainPriorAuthWebsite = "";
                    object.specialtyPharmacyPhone = "";
                    object.specialtyPharmacyPredeterminationApprovedQuantity = "";
                    object.specialtyPharmacyPredeterminationApprovedQuantityUsed = "";
                    object.specialtyPharmacyPredeterminationEndDate = "";
                    object.specialtyPharmacyPredeterminationNumber = "";
                    object.specialtyPharmacyPredeterminationOnFile = null;
                    object.specialtyPharmacyPredeterminationRequirementType = options.enums === String ? "INF_PREDETERMINATION_REQUIREMENT_UNKNOWN" : 0;
                    object.specialtyPharmacyPredeterminationStartDate = "";
                    object.specialtyPharmacyPredeterminationStatus = options.enums === String ? "INF_SPECIALTY_PHARMACY_PREDETERMINATION_STATUS_UNKNOWN" : 0;
                    object.specialtyPharmacyPredeterminationTurnaroundTime = "";
                    object.specialtyPharmacyPriorAuthApprovalNumber = "";
                    object.specialtyPharmacyPriorAuthApprovedQuantity = "";
                    object.specialtyPharmacyPriorAuthApprovedQuantityUsed = "";
                    object.specialtyPharmacyPriorAuthApprovedTreatments = "";
                    object.specialtyPharmacyPriorAuthApprovedTreatmentsUsed = "";
                    object.specialtyPharmacyPriorAuthEndDate = "";
                    object.specialtyPharmacyPriorAuthNotificationMethod = options.enums === String ? "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN" : 0;
                    object.specialtyPharmacyPriorAuthProcessDifferent = null;
                    object.specialtyPharmacyPriorAuthRequired = null;
                    object.specialtyPharmacyPriorAuthStartDate = "";
                    object.specialtyPharmacyPriorAuthStatus = options.enums === String ? "INF_PRIOR_AUTH_STATUS_UNKNOWN" : 0;
                    object.specialtyPharmacyPriorAuthSubmissionMethod = options.enums === String ? "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN" : 0;
                    object.specialtyPharmacyPriorAuthTurnaroundTime = "";
                    object.stepTherapyMethod = options.enums === String ? "INF_STEP_THERAPY_METHOD_UNKNOWN" : 0;
                    object.stepTherapyRequired = null;
                    object.stepTherapyTreatment = "";
                    object.therapyAvailabilityDate = "";
                    object.therapySeasonStartDate = "";
                    object.timelyFilingPeriod = "";
                    object.transportationServiceCovered = null;
                    object.transportationServicePhoneNumber = "";
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.attestationProcess != null && message.hasOwnProperty("attestationProcess"))
                    object.attestationProcess = message.attestationProcess;
                if (message.attestationProcessExists != null && message.hasOwnProperty("attestationProcessExists"))
                    object.attestationProcessExists = $root.google.protobuf.BoolValue.toObject(message.attestationProcessExists, options);
                if (message.buyAndBillAvailability != null && message.hasOwnProperty("buyAndBillAvailability"))
                    object.buyAndBillAvailability = options.enums === String ? $root.infinitusapi.INFBuyAndBillAvailability[message.buyAndBillAvailability] === undefined ? message.buyAndBillAvailability : $root.infinitusapi.INFBuyAndBillAvailability[message.buyAndBillAvailability] : message.buyAndBillAvailability;
                if (message.claimsAddress != null && message.hasOwnProperty("claimsAddress"))
                    object.claimsAddress = $root.infinitusapi.INFAddress.toObject(message.claimsAddress, options);
                if (message.coverageExceptionProcessExists != null && message.hasOwnProperty("coverageExceptionProcessExists"))
                    object.coverageExceptionProcessExists = $root.google.protobuf.BoolValue.toObject(message.coverageExceptionProcessExists, options);
                if (message.coverageExceptionTurnaroundTime != null && message.hasOwnProperty("coverageExceptionTurnaroundTime"))
                    object.coverageExceptionTurnaroundTime = message.coverageExceptionTurnaroundTime;
                if (message.coverageRestrictions != null && message.hasOwnProperty("coverageRestrictions"))
                    object.coverageRestrictions = message.coverageRestrictions;
                if (message.cptObtainPreDeterminationOrg != null && message.hasOwnProperty("cptObtainPreDeterminationOrg"))
                    object.cptObtainPreDeterminationOrg = message.cptObtainPreDeterminationOrg;
                if (message.cptObtainPredeterminationFax != null && message.hasOwnProperty("cptObtainPredeterminationFax"))
                    object.cptObtainPredeterminationFax = message.cptObtainPredeterminationFax;
                if (message.cptObtainPredeterminationPhone != null && message.hasOwnProperty("cptObtainPredeterminationPhone"))
                    object.cptObtainPredeterminationPhone = message.cptObtainPredeterminationPhone;
                if (message.cptObtainPredeterminationWebsite != null && message.hasOwnProperty("cptObtainPredeterminationWebsite"))
                    object.cptObtainPredeterminationWebsite = message.cptObtainPredeterminationWebsite;
                if (message.cptObtainPriorAuthAddress != null && message.hasOwnProperty("cptObtainPriorAuthAddress"))
                    object.cptObtainPriorAuthAddress = $root.infinitusapi.INFAddress.toObject(message.cptObtainPriorAuthAddress, options);
                if (message.cptObtainPriorAuthFax != null && message.hasOwnProperty("cptObtainPriorAuthFax"))
                    object.cptObtainPriorAuthFax = message.cptObtainPriorAuthFax;
                if (message.cptObtainPriorAuthFormLink != null && message.hasOwnProperty("cptObtainPriorAuthFormLink"))
                    object.cptObtainPriorAuthFormLink = message.cptObtainPriorAuthFormLink;
                if (message.cptObtainPriorAuthOrg != null && message.hasOwnProperty("cptObtainPriorAuthOrg"))
                    object.cptObtainPriorAuthOrg = message.cptObtainPriorAuthOrg;
                if (message.cptObtainPriorAuthPhone != null && message.hasOwnProperty("cptObtainPriorAuthPhone"))
                    object.cptObtainPriorAuthPhone = message.cptObtainPriorAuthPhone;
                if (message.cptObtainPriorAuthRequirements && message.cptObtainPriorAuthRequirements.length) {
                    object.cptObtainPriorAuthRequirements = [];
                    for (var j = 0; j < message.cptObtainPriorAuthRequirements.length; ++j)
                        object.cptObtainPriorAuthRequirements[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthRequirementType[message.cptObtainPriorAuthRequirements[j]] === undefined ? message.cptObtainPriorAuthRequirements[j] : $root.infinitusapi.INFPriorAuthRequirementType[message.cptObtainPriorAuthRequirements[j]] : message.cptObtainPriorAuthRequirements[j];
                }
                if (message.cptObtainPriorAuthWebsite != null && message.hasOwnProperty("cptObtainPriorAuthWebsite"))
                    object.cptObtainPriorAuthWebsite = message.cptObtainPriorAuthWebsite;
                if (message.cptPredeterminationApprovedQuantity != null && message.hasOwnProperty("cptPredeterminationApprovedQuantity"))
                    object.cptPredeterminationApprovedQuantity = message.cptPredeterminationApprovedQuantity;
                if (message.cptPredeterminationApprovedQuantityUsed != null && message.hasOwnProperty("cptPredeterminationApprovedQuantityUsed"))
                    object.cptPredeterminationApprovedQuantityUsed = message.cptPredeterminationApprovedQuantityUsed;
                if (message.cptPredeterminationEndDate != null && message.hasOwnProperty("cptPredeterminationEndDate"))
                    object.cptPredeterminationEndDate = message.cptPredeterminationEndDate;
                if (message.cptPredeterminationNumber != null && message.hasOwnProperty("cptPredeterminationNumber"))
                    object.cptPredeterminationNumber = message.cptPredeterminationNumber;
                if (message.cptPredeterminationStartDate != null && message.hasOwnProperty("cptPredeterminationStartDate"))
                    object.cptPredeterminationStartDate = message.cptPredeterminationStartDate;
                if (message.cptPredeterminationTurnaroundTime != null && message.hasOwnProperty("cptPredeterminationTurnaroundTime"))
                    object.cptPredeterminationTurnaroundTime = message.cptPredeterminationTurnaroundTime;
                if (message.cptPriorAuthApprovalNumber != null && message.hasOwnProperty("cptPriorAuthApprovalNumber"))
                    object.cptPriorAuthApprovalNumber = message.cptPriorAuthApprovalNumber;
                if (message.cptPriorAuthApprovedQuantity != null && message.hasOwnProperty("cptPriorAuthApprovedQuantity"))
                    object.cptPriorAuthApprovedQuantity = message.cptPriorAuthApprovedQuantity;
                if (message.cptPriorAuthApprovedQuantityUsed != null && message.hasOwnProperty("cptPriorAuthApprovedQuantityUsed"))
                    object.cptPriorAuthApprovedQuantityUsed = message.cptPriorAuthApprovedQuantityUsed;
                if (message.cptPriorAuthApprovedTreatments != null && message.hasOwnProperty("cptPriorAuthApprovedTreatments"))
                    object.cptPriorAuthApprovedTreatments = message.cptPriorAuthApprovedTreatments;
                if (message.cptPriorAuthApprovedTreatmentsUsed != null && message.hasOwnProperty("cptPriorAuthApprovedTreatmentsUsed"))
                    object.cptPriorAuthApprovedTreatmentsUsed = message.cptPriorAuthApprovedTreatmentsUsed;
                if (message.cptPriorAuthEndDate != null && message.hasOwnProperty("cptPriorAuthEndDate"))
                    object.cptPriorAuthEndDate = message.cptPriorAuthEndDate;
                if (message.cptPriorAuthNotificationMethod != null && message.hasOwnProperty("cptPriorAuthNotificationMethod"))
                    object.cptPriorAuthNotificationMethod = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.cptPriorAuthNotificationMethod] === undefined ? message.cptPriorAuthNotificationMethod : $root.infinitusapi.INFPriorAuthNotificationMethod[message.cptPriorAuthNotificationMethod] : message.cptPriorAuthNotificationMethod;
                if (message.cptPriorAuthNotificationMethods && message.cptPriorAuthNotificationMethods.length) {
                    object.cptPriorAuthNotificationMethods = [];
                    for (var j = 0; j < message.cptPriorAuthNotificationMethods.length; ++j)
                        object.cptPriorAuthNotificationMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.cptPriorAuthNotificationMethods[j]] === undefined ? message.cptPriorAuthNotificationMethods[j] : $root.infinitusapi.INFPriorAuthNotificationMethod[message.cptPriorAuthNotificationMethods[j]] : message.cptPriorAuthNotificationMethods[j];
                }
                if (message.cptPriorAuthProcessDifferent != null && message.hasOwnProperty("cptPriorAuthProcessDifferent"))
                    object.cptPriorAuthProcessDifferent = $root.google.protobuf.BoolValue.toObject(message.cptPriorAuthProcessDifferent, options);
                if (message.cptPriorAuthStartDate != null && message.hasOwnProperty("cptPriorAuthStartDate"))
                    object.cptPriorAuthStartDate = message.cptPriorAuthStartDate;
                if (message.cptPriorAuthSubmissionMethod != null && message.hasOwnProperty("cptPriorAuthSubmissionMethod"))
                    object.cptPriorAuthSubmissionMethod = options.enums === String ? $root.infinitusapi.INFPriorAuthSubmissionMethod[message.cptPriorAuthSubmissionMethod] === undefined ? message.cptPriorAuthSubmissionMethod : $root.infinitusapi.INFPriorAuthSubmissionMethod[message.cptPriorAuthSubmissionMethod] : message.cptPriorAuthSubmissionMethod;
                if (message.cptPriorAuthSubmissionMethods && message.cptPriorAuthSubmissionMethods.length) {
                    object.cptPriorAuthSubmissionMethods = [];
                    for (var j = 0; j < message.cptPriorAuthSubmissionMethods.length; ++j)
                        object.cptPriorAuthSubmissionMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthSubmissionMethod[message.cptPriorAuthSubmissionMethods[j]] === undefined ? message.cptPriorAuthSubmissionMethods[j] : $root.infinitusapi.INFPriorAuthSubmissionMethod[message.cptPriorAuthSubmissionMethods[j]] : message.cptPriorAuthSubmissionMethods[j];
                }
                if (message.cptPriorAuthTurnaroundTime != null && message.hasOwnProperty("cptPriorAuthTurnaroundTime"))
                    object.cptPriorAuthTurnaroundTime = message.cptPriorAuthTurnaroundTime;
                if (message.hasSpecialtyPharmacyExclusions != null && message.hasOwnProperty("hasSpecialtyPharmacyExclusions"))
                    object.hasSpecialtyPharmacyExclusions = $root.google.protobuf.BoolValue.toObject(message.hasSpecialtyPharmacyExclusions, options);
                if (message.homeHealthCovered != null && message.hasOwnProperty("homeHealthCovered"))
                    object.homeHealthCovered = $root.google.protobuf.BoolValue.toObject(message.homeHealthCovered, options);
                if (message.homeHealthObtainPriorAuthFax != null && message.hasOwnProperty("homeHealthObtainPriorAuthFax"))
                    object.homeHealthObtainPriorAuthFax = message.homeHealthObtainPriorAuthFax;
                if (message.homeHealthObtainPriorAuthPhone != null && message.hasOwnProperty("homeHealthObtainPriorAuthPhone"))
                    object.homeHealthObtainPriorAuthPhone = message.homeHealthObtainPriorAuthPhone;
                if (message.homeHealthPriorAuthRequired != null && message.hasOwnProperty("homeHealthPriorAuthRequired"))
                    object.homeHealthPriorAuthRequired = $root.google.protobuf.BoolValue.toObject(message.homeHealthPriorAuthRequired, options);
                if (message.homeHealthProviderName != null && message.hasOwnProperty("homeHealthProviderName"))
                    object.homeHealthProviderName = message.homeHealthProviderName;
                if (message.homeHealthProviderPhone != null && message.hasOwnProperty("homeHealthProviderPhone"))
                    object.homeHealthProviderPhone = message.homeHealthProviderPhone;
                if (message.homeHealthServices != null && message.hasOwnProperty("homeHealthServices"))
                    object.homeHealthServices = message.homeHealthServices;
                if (message.homeboundRequired != null && message.hasOwnProperty("homeboundRequired"))
                    object.homeboundRequired = $root.google.protobuf.BoolValue.toObject(message.homeboundRequired, options);
                if (message.lodgingCovered != null && message.hasOwnProperty("lodgingCovered"))
                    object.lodgingCovered = $root.google.protobuf.BoolValue.toObject(message.lodgingCovered, options);
                if (message.lodgingServicePhoneNumber != null && message.hasOwnProperty("lodgingServicePhoneNumber"))
                    object.lodgingServicePhoneNumber = message.lodgingServicePhoneNumber;
                if (message.medicalGroupName != null && message.hasOwnProperty("medicalGroupName"))
                    object.medicalGroupName = message.medicalGroupName;
                if (message.medicalGroupPhoneNumber != null && message.hasOwnProperty("medicalGroupPhoneNumber"))
                    object.medicalGroupPhoneNumber = message.medicalGroupPhoneNumber;
                if (message.medicalPolicyAvailableOnWebsite != null && message.hasOwnProperty("medicalPolicyAvailableOnWebsite"))
                    object.medicalPolicyAvailableOnWebsite = $root.google.protobuf.BoolValue.toObject(message.medicalPolicyAvailableOnWebsite, options);
                if (message.medicalPolicyNumber != null && message.hasOwnProperty("medicalPolicyNumber"))
                    object.medicalPolicyNumber = message.medicalPolicyNumber;
                if (message.obtainCoverageExceptionAddress != null && message.hasOwnProperty("obtainCoverageExceptionAddress"))
                    object.obtainCoverageExceptionAddress = $root.infinitusapi.INFAddress.toObject(message.obtainCoverageExceptionAddress, options);
                if (message.obtainCoverageExceptionFax != null && message.hasOwnProperty("obtainCoverageExceptionFax"))
                    object.obtainCoverageExceptionFax = message.obtainCoverageExceptionFax;
                if (message.obtainCoverageExceptionPhone != null && message.hasOwnProperty("obtainCoverageExceptionPhone"))
                    object.obtainCoverageExceptionPhone = message.obtainCoverageExceptionPhone;
                if (message.obtainCoverageExceptionProcess != null && message.hasOwnProperty("obtainCoverageExceptionProcess"))
                    object.obtainCoverageExceptionProcess = message.obtainCoverageExceptionProcess;
                if (message.obtainCoverageExceptionWebsite != null && message.hasOwnProperty("obtainCoverageExceptionWebsite"))
                    object.obtainCoverageExceptionWebsite = message.obtainCoverageExceptionWebsite;
                if (message.obtainPreDeterminationOrg != null && message.hasOwnProperty("obtainPreDeterminationOrg"))
                    object.obtainPreDeterminationOrg = message.obtainPreDeterminationOrg;
                if (message.obtainPredeterminationFax != null && message.hasOwnProperty("obtainPredeterminationFax"))
                    object.obtainPredeterminationFax = message.obtainPredeterminationFax;
                if (message.obtainPredeterminationPhone != null && message.hasOwnProperty("obtainPredeterminationPhone"))
                    object.obtainPredeterminationPhone = message.obtainPredeterminationPhone;
                if (message.obtainPredeterminationWebsite != null && message.hasOwnProperty("obtainPredeterminationWebsite"))
                    object.obtainPredeterminationWebsite = message.obtainPredeterminationWebsite;
                if (message.obtainPriorAuthAddress != null && message.hasOwnProperty("obtainPriorAuthAddress"))
                    object.obtainPriorAuthAddress = $root.infinitusapi.INFAddress.toObject(message.obtainPriorAuthAddress, options);
                if (message.obtainPriorAuthFax != null && message.hasOwnProperty("obtainPriorAuthFax"))
                    object.obtainPriorAuthFax = message.obtainPriorAuthFax;
                if (message.obtainPriorAuthFormLink != null && message.hasOwnProperty("obtainPriorAuthFormLink"))
                    object.obtainPriorAuthFormLink = message.obtainPriorAuthFormLink;
                if (message.obtainPriorAuthOrg != null && message.hasOwnProperty("obtainPriorAuthOrg"))
                    object.obtainPriorAuthOrg = message.obtainPriorAuthOrg;
                if (message.obtainPriorAuthPhone != null && message.hasOwnProperty("obtainPriorAuthPhone"))
                    object.obtainPriorAuthPhone = message.obtainPriorAuthPhone;
                if (message.obtainPriorAuthRequirements && message.obtainPriorAuthRequirements.length) {
                    object.obtainPriorAuthRequirements = [];
                    for (var j = 0; j < message.obtainPriorAuthRequirements.length; ++j)
                        object.obtainPriorAuthRequirements[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthRequirementType[message.obtainPriorAuthRequirements[j]] === undefined ? message.obtainPriorAuthRequirements[j] : $root.infinitusapi.INFPriorAuthRequirementType[message.obtainPriorAuthRequirements[j]] : message.obtainPriorAuthRequirements[j];
                }
                if (message.obtainPriorAuthWebsite != null && message.hasOwnProperty("obtainPriorAuthWebsite"))
                    object.obtainPriorAuthWebsite = message.obtainPriorAuthWebsite;
                if (message.obtainQuantityLimitExceptionPhone != null && message.hasOwnProperty("obtainQuantityLimitExceptionPhone"))
                    object.obtainQuantityLimitExceptionPhone = message.obtainQuantityLimitExceptionPhone;
                if (message.obtainQuantityLimitExceptionProcess != null && message.hasOwnProperty("obtainQuantityLimitExceptionProcess"))
                    object.obtainQuantityLimitExceptionProcess = message.obtainQuantityLimitExceptionProcess;
                if (message.pcpName != null && message.hasOwnProperty("pcpName"))
                    object.pcpName = message.pcpName;
                if (message.pcpPhone != null && message.hasOwnProperty("pcpPhone"))
                    object.pcpPhone = message.pcpPhone;
                if (message.preDeterminationAvailable != null && message.hasOwnProperty("preDeterminationAvailable"))
                    object.preDeterminationAvailable = $root.google.protobuf.BoolValue.toObject(message.preDeterminationAvailable, options);
                if (message.preDeterminationNumber != null && message.hasOwnProperty("preDeterminationNumber"))
                    object.preDeterminationNumber = message.preDeterminationNumber;
                if (message.preDeterminationOnFile != null && message.hasOwnProperty("preDeterminationOnFile"))
                    object.preDeterminationOnFile = $root.google.protobuf.BoolValue.toObject(message.preDeterminationOnFile, options);
                if (message.preDeterminationRequired != null && message.hasOwnProperty("preDeterminationRequired"))
                    object.preDeterminationRequired = $root.google.protobuf.BoolValue.toObject(message.preDeterminationRequired, options);
                if (message.preDeterminationRequirement != null && message.hasOwnProperty("preDeterminationRequirement"))
                    object.preDeterminationRequirement = options.enums === String ? $root.infinitusapi.INFPredeterminationRequirementType[message.preDeterminationRequirement] === undefined ? message.preDeterminationRequirement : $root.infinitusapi.INFPredeterminationRequirementType[message.preDeterminationRequirement] : message.preDeterminationRequirement;
                if (message.predeterminationAddressOnFile != null && message.hasOwnProperty("predeterminationAddressOnFile"))
                    object.predeterminationAddressOnFile = $root.infinitusapi.INFAddress.toObject(message.predeterminationAddressOnFile, options);
                if (message.predeterminationApprovedQuantity != null && message.hasOwnProperty("predeterminationApprovedQuantity"))
                    object.predeterminationApprovedQuantity = message.predeterminationApprovedQuantity;
                if (message.predeterminationApprovedQuantityUsed != null && message.hasOwnProperty("predeterminationApprovedQuantityUsed"))
                    object.predeterminationApprovedQuantityUsed = message.predeterminationApprovedQuantityUsed;
                if (message.predeterminationCompletionDate != null && message.hasOwnProperty("predeterminationCompletionDate"))
                    object.predeterminationCompletionDate = message.predeterminationCompletionDate;
                if (message.predeterminationEndDate != null && message.hasOwnProperty("predeterminationEndDate"))
                    object.predeterminationEndDate = message.predeterminationEndDate;
                if (message.predeterminationOnFileApplies != null && message.hasOwnProperty("predeterminationOnFileApplies"))
                    object.predeterminationOnFileApplies = $root.google.protobuf.BoolValue.toObject(message.predeterminationOnFileApplies, options);
                if (message.predeterminationProviderNameOnFile != null && message.hasOwnProperty("predeterminationProviderNameOnFile"))
                    object.predeterminationProviderNameOnFile = message.predeterminationProviderNameOnFile;
                if (message.predeterminationReceivedDate != null && message.hasOwnProperty("predeterminationReceivedDate"))
                    object.predeterminationReceivedDate = message.predeterminationReceivedDate;
                if (message.predeterminationRenewalProcessExists != null && message.hasOwnProperty("predeterminationRenewalProcessExists"))
                    object.predeterminationRenewalProcessExists = $root.google.protobuf.BoolValue.toObject(message.predeterminationRenewalProcessExists, options);
                if (message.predeterminationStartDate != null && message.hasOwnProperty("predeterminationStartDate"))
                    object.predeterminationStartDate = message.predeterminationStartDate;
                if (message.predeterminationStatus != null && message.hasOwnProperty("predeterminationStatus"))
                    object.predeterminationStatus = options.enums === String ? $root.infinitusapi.INFPredeterminationStatus[message.predeterminationStatus] === undefined ? message.predeterminationStatus : $root.infinitusapi.INFPredeterminationStatus[message.predeterminationStatus] : message.predeterminationStatus;
                if (message.predeterminationSubmissionDate != null && message.hasOwnProperty("predeterminationSubmissionDate"))
                    object.predeterminationSubmissionDate = message.predeterminationSubmissionDate;
                if (message.predeterminationTurnaroundTime != null && message.hasOwnProperty("predeterminationTurnaroundTime"))
                    object.predeterminationTurnaroundTime = message.predeterminationTurnaroundTime;
                if (message.preferredSpecialtyPharmaciesAvailable && message.preferredSpecialtyPharmaciesAvailable.length) {
                    object.preferredSpecialtyPharmaciesAvailable = [];
                    for (var j = 0; j < message.preferredSpecialtyPharmaciesAvailable.length; ++j)
                        object.preferredSpecialtyPharmaciesAvailable[j] = $root.infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy.toObject(message.preferredSpecialtyPharmaciesAvailable[j], options);
                }
                if (message.priorAuthAddressOnFile != null && message.hasOwnProperty("priorAuthAddressOnFile"))
                    object.priorAuthAddressOnFile = $root.infinitusapi.INFAddress.toObject(message.priorAuthAddressOnFile, options);
                if (message.priorAuthApprovalDate != null && message.hasOwnProperty("priorAuthApprovalDate"))
                    object.priorAuthApprovalDate = message.priorAuthApprovalDate;
                if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber"))
                    object.priorAuthApprovalNumber = message.priorAuthApprovalNumber;
                if (message.priorAuthApprovedQuantity != null && message.hasOwnProperty("priorAuthApprovedQuantity"))
                    object.priorAuthApprovedQuantity = message.priorAuthApprovedQuantity;
                if (message.priorAuthApprovedQuantityUsed != null && message.hasOwnProperty("priorAuthApprovedQuantityUsed"))
                    object.priorAuthApprovedQuantityUsed = message.priorAuthApprovedQuantityUsed;
                if (message.priorAuthApprovedTreatments != null && message.hasOwnProperty("priorAuthApprovedTreatments"))
                    object.priorAuthApprovedTreatments = message.priorAuthApprovedTreatments;
                if (message.priorAuthApprovedTreatmentsUsed != null && message.hasOwnProperty("priorAuthApprovedTreatmentsUsed"))
                    object.priorAuthApprovedTreatmentsUsed = message.priorAuthApprovedTreatmentsUsed;
                if (message.priorAuthCodesOnFile && message.priorAuthCodesOnFile.length) {
                    object.priorAuthCodesOnFile = [];
                    for (var j = 0; j < message.priorAuthCodesOnFile.length; ++j)
                        object.priorAuthCodesOnFile[j] = message.priorAuthCodesOnFile[j];
                }
                if (message.priorAuthCompletionDate != null && message.hasOwnProperty("priorAuthCompletionDate"))
                    object.priorAuthCompletionDate = message.priorAuthCompletionDate;
                if (message.priorAuthEndDate != null && message.hasOwnProperty("priorAuthEndDate"))
                    object.priorAuthEndDate = message.priorAuthEndDate;
                if (message.priorAuthExceptionReason != null && message.hasOwnProperty("priorAuthExceptionReason"))
                    object.priorAuthExceptionReason = message.priorAuthExceptionReason;
                if (message.priorAuthInitiationDate != null && message.hasOwnProperty("priorAuthInitiationDate"))
                    object.priorAuthInitiationDate = message.priorAuthInitiationDate;
                if (message.priorAuthNotificationMethod != null && message.hasOwnProperty("priorAuthNotificationMethod"))
                    object.priorAuthNotificationMethod = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethod] === undefined ? message.priorAuthNotificationMethod : $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethod] : message.priorAuthNotificationMethod;
                if (message.priorAuthNotificationMethods && message.priorAuthNotificationMethods.length) {
                    object.priorAuthNotificationMethods = [];
                    for (var j = 0; j < message.priorAuthNotificationMethods.length; ++j)
                        object.priorAuthNotificationMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethods[j]] === undefined ? message.priorAuthNotificationMethods[j] : $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethods[j]] : message.priorAuthNotificationMethods[j];
                }
                if (message.priorAuthOnFile != null && message.hasOwnProperty("priorAuthOnFile"))
                    object.priorAuthOnFile = $root.google.protobuf.BoolValue.toObject(message.priorAuthOnFile, options);
                if (message.priorAuthOnFileApplies != null && message.hasOwnProperty("priorAuthOnFileApplies"))
                    object.priorAuthOnFileApplies = $root.google.protobuf.BoolValue.toObject(message.priorAuthOnFileApplies, options);
                if (message.priorAuthProviderNameOnFile != null && message.hasOwnProperty("priorAuthProviderNameOnFile"))
                    object.priorAuthProviderNameOnFile = message.priorAuthProviderNameOnFile;
                if (message.priorAuthRenewalProcessExists != null && message.hasOwnProperty("priorAuthRenewalProcessExists"))
                    object.priorAuthRenewalProcessExists = $root.google.protobuf.BoolValue.toObject(message.priorAuthRenewalProcessExists, options);
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired"))
                    object.priorAuthRequired = $root.google.protobuf.BoolValue.toObject(message.priorAuthRequired, options);
                if (message.priorAuthRequiredCodes && message.priorAuthRequiredCodes.length) {
                    object.priorAuthRequiredCodes = [];
                    for (var j = 0; j < message.priorAuthRequiredCodes.length; ++j)
                        object.priorAuthRequiredCodes[j] = message.priorAuthRequiredCodes[j];
                }
                if (message.priorAuthResponsibleOrg != null && message.hasOwnProperty("priorAuthResponsibleOrg"))
                    object.priorAuthResponsibleOrg = options.enums === String ? $root.infinitusapi.INFPriorAuthResponsibleOrg[message.priorAuthResponsibleOrg] === undefined ? message.priorAuthResponsibleOrg : $root.infinitusapi.INFPriorAuthResponsibleOrg[message.priorAuthResponsibleOrg] : message.priorAuthResponsibleOrg;
                if (message.priorAuthStartDate != null && message.hasOwnProperty("priorAuthStartDate"))
                    object.priorAuthStartDate = message.priorAuthStartDate;
                if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                    object.priorAuthStatus = options.enums === String ? $root.infinitusapi.INFPriorAuthStatus[message.priorAuthStatus] === undefined ? message.priorAuthStatus : $root.infinitusapi.INFPriorAuthStatus[message.priorAuthStatus] : message.priorAuthStatus;
                if (message.priorAuthSubmissionDate != null && message.hasOwnProperty("priorAuthSubmissionDate"))
                    object.priorAuthSubmissionDate = message.priorAuthSubmissionDate;
                if (message.priorAuthSubmissionMethod != null && message.hasOwnProperty("priorAuthSubmissionMethod"))
                    object.priorAuthSubmissionMethod = options.enums === String ? $root.infinitusapi.INFPriorAuthSubmissionMethod[message.priorAuthSubmissionMethod] === undefined ? message.priorAuthSubmissionMethod : $root.infinitusapi.INFPriorAuthSubmissionMethod[message.priorAuthSubmissionMethod] : message.priorAuthSubmissionMethod;
                if (message.priorAuthSubmissionMethods && message.priorAuthSubmissionMethods.length) {
                    object.priorAuthSubmissionMethods = [];
                    for (var j = 0; j < message.priorAuthSubmissionMethods.length; ++j)
                        object.priorAuthSubmissionMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthSubmissionMethod[message.priorAuthSubmissionMethods[j]] === undefined ? message.priorAuthSubmissionMethods[j] : $root.infinitusapi.INFPriorAuthSubmissionMethod[message.priorAuthSubmissionMethods[j]] : message.priorAuthSubmissionMethods[j];
                }
                if (message.priorAuthTurnaroundTime != null && message.hasOwnProperty("priorAuthTurnaroundTime"))
                    object.priorAuthTurnaroundTime = message.priorAuthTurnaroundTime;
                if (message.priorAuthType != null && message.hasOwnProperty("priorAuthType"))
                    object.priorAuthType = options.enums === String ? $root.infinitusapi.INFPriorAuthType[message.priorAuthType] === undefined ? message.priorAuthType : $root.infinitusapi.INFPriorAuthType[message.priorAuthType] : message.priorAuthType;
                if (message.quantityLimit != null && message.hasOwnProperty("quantityLimit"))
                    object.quantityLimit = message.quantityLimit;
                if (message.referralAddress != null && message.hasOwnProperty("referralAddress"))
                    object.referralAddress = $root.infinitusapi.INFAddress.toObject(message.referralAddress, options);
                if (message.referralEffectiveDate != null && message.hasOwnProperty("referralEffectiveDate"))
                    object.referralEffectiveDate = message.referralEffectiveDate;
                if (message.referralFax != null && message.hasOwnProperty("referralFax"))
                    object.referralFax = message.referralFax;
                if (message.referralNumVisitsApproved != null && message.hasOwnProperty("referralNumVisitsApproved"))
                    object.referralNumVisitsApproved = $root.google.protobuf.DoubleValue.toObject(message.referralNumVisitsApproved, options);
                if (message.referralNumVisitsUsed != null && message.hasOwnProperty("referralNumVisitsUsed"))
                    object.referralNumVisitsUsed = $root.google.protobuf.DoubleValue.toObject(message.referralNumVisitsUsed, options);
                if (message.referralNumber != null && message.hasOwnProperty("referralNumber"))
                    object.referralNumber = message.referralNumber;
                if (message.referralOnFile != null && message.hasOwnProperty("referralOnFile"))
                    object.referralOnFile = $root.google.protobuf.BoolValue.toObject(message.referralOnFile, options);
                if (message.referralRecertDate != null && message.hasOwnProperty("referralRecertDate"))
                    object.referralRecertDate = message.referralRecertDate;
                if (message.referralRequired != null && message.hasOwnProperty("referralRequired"))
                    object.referralRequired = $root.google.protobuf.BoolValue.toObject(message.referralRequired, options);
                if (message.referralRequirements != null && message.hasOwnProperty("referralRequirements"))
                    object.referralRequirements = options.enums === String ? $root.infinitusapi.InfReferralRequirementsType[message.referralRequirements] === undefined ? message.referralRequirements : $root.infinitusapi.InfReferralRequirementsType[message.referralRequirements] : message.referralRequirements;
                if (message.referralSubmissionProcess != null && message.hasOwnProperty("referralSubmissionProcess"))
                    object.referralSubmissionProcess = message.referralSubmissionProcess;
                if (message.referralWebsite != null && message.hasOwnProperty("referralWebsite"))
                    object.referralWebsite = message.referralWebsite;
                if (message.specialtyPharmacy2Address != null && message.hasOwnProperty("specialtyPharmacy2Address"))
                    object.specialtyPharmacy2Address = $root.infinitusapi.INFAddress.toObject(message.specialtyPharmacy2Address, options);
                if (message.specialtyPharmacy2Fax != null && message.hasOwnProperty("specialtyPharmacy2Fax"))
                    object.specialtyPharmacy2Fax = message.specialtyPharmacy2Fax;
                if (message.specialtyPharmacy2Name != null && message.hasOwnProperty("specialtyPharmacy2Name"))
                    object.specialtyPharmacy2Name = message.specialtyPharmacy2Name;
                if (message.specialtyPharmacy2Phone != null && message.hasOwnProperty("specialtyPharmacy2Phone"))
                    object.specialtyPharmacy2Phone = message.specialtyPharmacy2Phone;
                if (message.specialtyPharmacy3Address != null && message.hasOwnProperty("specialtyPharmacy3Address"))
                    object.specialtyPharmacy3Address = $root.infinitusapi.INFAddress.toObject(message.specialtyPharmacy3Address, options);
                if (message.specialtyPharmacy3Fax != null && message.hasOwnProperty("specialtyPharmacy3Fax"))
                    object.specialtyPharmacy3Fax = message.specialtyPharmacy3Fax;
                if (message.specialtyPharmacy3Name != null && message.hasOwnProperty("specialtyPharmacy3Name"))
                    object.specialtyPharmacy3Name = message.specialtyPharmacy3Name;
                if (message.specialtyPharmacy3Phone != null && message.hasOwnProperty("specialtyPharmacy3Phone"))
                    object.specialtyPharmacy3Phone = message.specialtyPharmacy3Phone;
                if (message.specialtyPharmacyAddress != null && message.hasOwnProperty("specialtyPharmacyAddress"))
                    object.specialtyPharmacyAddress = $root.infinitusapi.INFAddress.toObject(message.specialtyPharmacyAddress, options);
                if (message.specialtyPharmacyAvailability != null && message.hasOwnProperty("specialtyPharmacyAvailability"))
                    object.specialtyPharmacyAvailability = options.enums === String ? $root.infinitusapi.INFSpecialtyPharmacyAvailability[message.specialtyPharmacyAvailability] === undefined ? message.specialtyPharmacyAvailability : $root.infinitusapi.INFSpecialtyPharmacyAvailability[message.specialtyPharmacyAvailability] : message.specialtyPharmacyAvailability;
                if (message.specialtyPharmacyExclusions != null && message.hasOwnProperty("specialtyPharmacyExclusions"))
                    object.specialtyPharmacyExclusions = message.specialtyPharmacyExclusions;
                if (message.specialtyPharmacyFax != null && message.hasOwnProperty("specialtyPharmacyFax"))
                    object.specialtyPharmacyFax = message.specialtyPharmacyFax;
                if (message.specialtyPharmacyName != null && message.hasOwnProperty("specialtyPharmacyName"))
                    object.specialtyPharmacyName = message.specialtyPharmacyName;
                if (message.specialtyPharmacyNetwork != null && message.hasOwnProperty("specialtyPharmacyNetwork"))
                    object.specialtyPharmacyNetwork = options.enums === String ? $root.infinitusapi.INFSpecialtyPharmacyNetwork[message.specialtyPharmacyNetwork] === undefined ? message.specialtyPharmacyNetwork : $root.infinitusapi.INFSpecialtyPharmacyNetwork[message.specialtyPharmacyNetwork] : message.specialtyPharmacyNetwork;
                if (message.specialtyPharmacyObtainPreDeterminationOrg != null && message.hasOwnProperty("specialtyPharmacyObtainPreDeterminationOrg"))
                    object.specialtyPharmacyObtainPreDeterminationOrg = message.specialtyPharmacyObtainPreDeterminationOrg;
                if (message.specialtyPharmacyObtainPredeterminationFax != null && message.hasOwnProperty("specialtyPharmacyObtainPredeterminationFax"))
                    object.specialtyPharmacyObtainPredeterminationFax = message.specialtyPharmacyObtainPredeterminationFax;
                if (message.specialtyPharmacyObtainPredeterminationPhone != null && message.hasOwnProperty("specialtyPharmacyObtainPredeterminationPhone"))
                    object.specialtyPharmacyObtainPredeterminationPhone = message.specialtyPharmacyObtainPredeterminationPhone;
                if (message.specialtyPharmacyObtainPredeterminationWebsite != null && message.hasOwnProperty("specialtyPharmacyObtainPredeterminationWebsite"))
                    object.specialtyPharmacyObtainPredeterminationWebsite = message.specialtyPharmacyObtainPredeterminationWebsite;
                if (message.specialtyPharmacyObtainPriorAuthAddress != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthAddress"))
                    object.specialtyPharmacyObtainPriorAuthAddress = $root.infinitusapi.INFAddress.toObject(message.specialtyPharmacyObtainPriorAuthAddress, options);
                if (message.specialtyPharmacyObtainPriorAuthFax != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthFax"))
                    object.specialtyPharmacyObtainPriorAuthFax = message.specialtyPharmacyObtainPriorAuthFax;
                if (message.specialtyPharmacyObtainPriorAuthFormLink != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthFormLink"))
                    object.specialtyPharmacyObtainPriorAuthFormLink = message.specialtyPharmacyObtainPriorAuthFormLink;
                if (message.specialtyPharmacyObtainPriorAuthOrg != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthOrg"))
                    object.specialtyPharmacyObtainPriorAuthOrg = message.specialtyPharmacyObtainPriorAuthOrg;
                if (message.specialtyPharmacyObtainPriorAuthPhone != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthPhone"))
                    object.specialtyPharmacyObtainPriorAuthPhone = message.specialtyPharmacyObtainPriorAuthPhone;
                if (message.specialtyPharmacyObtainPriorAuthRequirements && message.specialtyPharmacyObtainPriorAuthRequirements.length) {
                    object.specialtyPharmacyObtainPriorAuthRequirements = [];
                    for (var j = 0; j < message.specialtyPharmacyObtainPriorAuthRequirements.length; ++j)
                        object.specialtyPharmacyObtainPriorAuthRequirements[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthRequirementType[message.specialtyPharmacyObtainPriorAuthRequirements[j]] === undefined ? message.specialtyPharmacyObtainPriorAuthRequirements[j] : $root.infinitusapi.INFPriorAuthRequirementType[message.specialtyPharmacyObtainPriorAuthRequirements[j]] : message.specialtyPharmacyObtainPriorAuthRequirements[j];
                }
                if (message.specialtyPharmacyObtainPriorAuthWebsite != null && message.hasOwnProperty("specialtyPharmacyObtainPriorAuthWebsite"))
                    object.specialtyPharmacyObtainPriorAuthWebsite = message.specialtyPharmacyObtainPriorAuthWebsite;
                if (message.specialtyPharmacyPhone != null && message.hasOwnProperty("specialtyPharmacyPhone"))
                    object.specialtyPharmacyPhone = message.specialtyPharmacyPhone;
                if (message.specialtyPharmacyPredeterminationApprovedQuantity != null && message.hasOwnProperty("specialtyPharmacyPredeterminationApprovedQuantity"))
                    object.specialtyPharmacyPredeterminationApprovedQuantity = message.specialtyPharmacyPredeterminationApprovedQuantity;
                if (message.specialtyPharmacyPredeterminationApprovedQuantityUsed != null && message.hasOwnProperty("specialtyPharmacyPredeterminationApprovedQuantityUsed"))
                    object.specialtyPharmacyPredeterminationApprovedQuantityUsed = message.specialtyPharmacyPredeterminationApprovedQuantityUsed;
                if (message.specialtyPharmacyPredeterminationEndDate != null && message.hasOwnProperty("specialtyPharmacyPredeterminationEndDate"))
                    object.specialtyPharmacyPredeterminationEndDate = message.specialtyPharmacyPredeterminationEndDate;
                if (message.specialtyPharmacyPredeterminationNumber != null && message.hasOwnProperty("specialtyPharmacyPredeterminationNumber"))
                    object.specialtyPharmacyPredeterminationNumber = message.specialtyPharmacyPredeterminationNumber;
                if (message.specialtyPharmacyPredeterminationOnFile != null && message.hasOwnProperty("specialtyPharmacyPredeterminationOnFile"))
                    object.specialtyPharmacyPredeterminationOnFile = $root.google.protobuf.BoolValue.toObject(message.specialtyPharmacyPredeterminationOnFile, options);
                if (message.specialtyPharmacyPredeterminationRequirementType != null && message.hasOwnProperty("specialtyPharmacyPredeterminationRequirementType"))
                    object.specialtyPharmacyPredeterminationRequirementType = options.enums === String ? $root.infinitusapi.INFPredeterminationRequirementType[message.specialtyPharmacyPredeterminationRequirementType] === undefined ? message.specialtyPharmacyPredeterminationRequirementType : $root.infinitusapi.INFPredeterminationRequirementType[message.specialtyPharmacyPredeterminationRequirementType] : message.specialtyPharmacyPredeterminationRequirementType;
                if (message.specialtyPharmacyPredeterminationStartDate != null && message.hasOwnProperty("specialtyPharmacyPredeterminationStartDate"))
                    object.specialtyPharmacyPredeterminationStartDate = message.specialtyPharmacyPredeterminationStartDate;
                if (message.specialtyPharmacyPredeterminationStatus != null && message.hasOwnProperty("specialtyPharmacyPredeterminationStatus"))
                    object.specialtyPharmacyPredeterminationStatus = options.enums === String ? $root.infinitusapi.INFSpecialtyPharmacyPredeterminationStatus[message.specialtyPharmacyPredeterminationStatus] === undefined ? message.specialtyPharmacyPredeterminationStatus : $root.infinitusapi.INFSpecialtyPharmacyPredeterminationStatus[message.specialtyPharmacyPredeterminationStatus] : message.specialtyPharmacyPredeterminationStatus;
                if (message.specialtyPharmacyPredeterminationTurnaroundTime != null && message.hasOwnProperty("specialtyPharmacyPredeterminationTurnaroundTime"))
                    object.specialtyPharmacyPredeterminationTurnaroundTime = message.specialtyPharmacyPredeterminationTurnaroundTime;
                if (message.specialtyPharmacyPriorAuthApprovalNumber != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovalNumber"))
                    object.specialtyPharmacyPriorAuthApprovalNumber = message.specialtyPharmacyPriorAuthApprovalNumber;
                if (message.specialtyPharmacyPriorAuthApprovedQuantity != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovedQuantity"))
                    object.specialtyPharmacyPriorAuthApprovedQuantity = message.specialtyPharmacyPriorAuthApprovedQuantity;
                if (message.specialtyPharmacyPriorAuthApprovedQuantityUsed != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovedQuantityUsed"))
                    object.specialtyPharmacyPriorAuthApprovedQuantityUsed = message.specialtyPharmacyPriorAuthApprovedQuantityUsed;
                if (message.specialtyPharmacyPriorAuthApprovedTreatments != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovedTreatments"))
                    object.specialtyPharmacyPriorAuthApprovedTreatments = message.specialtyPharmacyPriorAuthApprovedTreatments;
                if (message.specialtyPharmacyPriorAuthApprovedTreatmentsUsed != null && message.hasOwnProperty("specialtyPharmacyPriorAuthApprovedTreatmentsUsed"))
                    object.specialtyPharmacyPriorAuthApprovedTreatmentsUsed = message.specialtyPharmacyPriorAuthApprovedTreatmentsUsed;
                if (message.specialtyPharmacyPriorAuthEndDate != null && message.hasOwnProperty("specialtyPharmacyPriorAuthEndDate"))
                    object.specialtyPharmacyPriorAuthEndDate = message.specialtyPharmacyPriorAuthEndDate;
                if (message.specialtyPharmacyPriorAuthNotificationMethod != null && message.hasOwnProperty("specialtyPharmacyPriorAuthNotificationMethod"))
                    object.specialtyPharmacyPriorAuthNotificationMethod = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.specialtyPharmacyPriorAuthNotificationMethod] === undefined ? message.specialtyPharmacyPriorAuthNotificationMethod : $root.infinitusapi.INFPriorAuthNotificationMethod[message.specialtyPharmacyPriorAuthNotificationMethod] : message.specialtyPharmacyPriorAuthNotificationMethod;
                if (message.specialtyPharmacyPriorAuthNotificationMethods && message.specialtyPharmacyPriorAuthNotificationMethods.length) {
                    object.specialtyPharmacyPriorAuthNotificationMethods = [];
                    for (var j = 0; j < message.specialtyPharmacyPriorAuthNotificationMethods.length; ++j)
                        object.specialtyPharmacyPriorAuthNotificationMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.specialtyPharmacyPriorAuthNotificationMethods[j]] === undefined ? message.specialtyPharmacyPriorAuthNotificationMethods[j] : $root.infinitusapi.INFPriorAuthNotificationMethod[message.specialtyPharmacyPriorAuthNotificationMethods[j]] : message.specialtyPharmacyPriorAuthNotificationMethods[j];
                }
                if (message.specialtyPharmacyPriorAuthProcessDifferent != null && message.hasOwnProperty("specialtyPharmacyPriorAuthProcessDifferent"))
                    object.specialtyPharmacyPriorAuthProcessDifferent = $root.google.protobuf.BoolValue.toObject(message.specialtyPharmacyPriorAuthProcessDifferent, options);
                if (message.specialtyPharmacyPriorAuthRequired != null && message.hasOwnProperty("specialtyPharmacyPriorAuthRequired"))
                    object.specialtyPharmacyPriorAuthRequired = $root.google.protobuf.BoolValue.toObject(message.specialtyPharmacyPriorAuthRequired, options);
                if (message.specialtyPharmacyPriorAuthStartDate != null && message.hasOwnProperty("specialtyPharmacyPriorAuthStartDate"))
                    object.specialtyPharmacyPriorAuthStartDate = message.specialtyPharmacyPriorAuthStartDate;
                if (message.specialtyPharmacyPriorAuthStatus != null && message.hasOwnProperty("specialtyPharmacyPriorAuthStatus"))
                    object.specialtyPharmacyPriorAuthStatus = options.enums === String ? $root.infinitusapi.INFPriorAuthStatus[message.specialtyPharmacyPriorAuthStatus] === undefined ? message.specialtyPharmacyPriorAuthStatus : $root.infinitusapi.INFPriorAuthStatus[message.specialtyPharmacyPriorAuthStatus] : message.specialtyPharmacyPriorAuthStatus;
                if (message.specialtyPharmacyPriorAuthSubmissionMethod != null && message.hasOwnProperty("specialtyPharmacyPriorAuthSubmissionMethod"))
                    object.specialtyPharmacyPriorAuthSubmissionMethod = options.enums === String ? $root.infinitusapi.INFPriorAuthSubmissionMethod[message.specialtyPharmacyPriorAuthSubmissionMethod] === undefined ? message.specialtyPharmacyPriorAuthSubmissionMethod : $root.infinitusapi.INFPriorAuthSubmissionMethod[message.specialtyPharmacyPriorAuthSubmissionMethod] : message.specialtyPharmacyPriorAuthSubmissionMethod;
                if (message.specialtyPharmacyPriorAuthSubmissionMethods && message.specialtyPharmacyPriorAuthSubmissionMethods.length) {
                    object.specialtyPharmacyPriorAuthSubmissionMethods = [];
                    for (var j = 0; j < message.specialtyPharmacyPriorAuthSubmissionMethods.length; ++j)
                        object.specialtyPharmacyPriorAuthSubmissionMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthSubmissionMethod[message.specialtyPharmacyPriorAuthSubmissionMethods[j]] === undefined ? message.specialtyPharmacyPriorAuthSubmissionMethods[j] : $root.infinitusapi.INFPriorAuthSubmissionMethod[message.specialtyPharmacyPriorAuthSubmissionMethods[j]] : message.specialtyPharmacyPriorAuthSubmissionMethods[j];
                }
                if (message.specialtyPharmacyPriorAuthTurnaroundTime != null && message.hasOwnProperty("specialtyPharmacyPriorAuthTurnaroundTime"))
                    object.specialtyPharmacyPriorAuthTurnaroundTime = message.specialtyPharmacyPriorAuthTurnaroundTime;
                if (message.stepTherapyMethod != null && message.hasOwnProperty("stepTherapyMethod"))
                    object.stepTherapyMethod = options.enums === String ? $root.infinitusapi.INFStepTherapyMethod[message.stepTherapyMethod] === undefined ? message.stepTherapyMethod : $root.infinitusapi.INFStepTherapyMethod[message.stepTherapyMethod] : message.stepTherapyMethod;
                if (message.stepTherapyRequired != null && message.hasOwnProperty("stepTherapyRequired"))
                    object.stepTherapyRequired = $root.google.protobuf.BoolValue.toObject(message.stepTherapyRequired, options);
                if (message.stepTherapyTreatment != null && message.hasOwnProperty("stepTherapyTreatment"))
                    object.stepTherapyTreatment = message.stepTherapyTreatment;
                if (message.therapyAvailabilityDate != null && message.hasOwnProperty("therapyAvailabilityDate"))
                    object.therapyAvailabilityDate = message.therapyAvailabilityDate;
                if (message.therapySeasonStartDate != null && message.hasOwnProperty("therapySeasonStartDate"))
                    object.therapySeasonStartDate = message.therapySeasonStartDate;
                if (message.timelyFilingPeriod != null && message.hasOwnProperty("timelyFilingPeriod"))
                    object.timelyFilingPeriod = message.timelyFilingPeriod;
                if (message.transportationServiceCovered != null && message.hasOwnProperty("transportationServiceCovered"))
                    object.transportationServiceCovered = $root.google.protobuf.BoolValue.toObject(message.transportationServiceCovered, options);
                if (message.transportationServicePhoneNumber != null && message.hasOwnProperty("transportationServicePhoneNumber"))
                    object.transportationServicePhoneNumber = message.transportationServicePhoneNumber;
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputAccessInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputAccessInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputAccessInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputAccessInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputAccessInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputAccessInfo";
            };
    
            return INFBVTaskOutputAccessInfo;
        })();
    
        infinitusapi.INFBVTaskOutputCPTCodeBenefits = (function() {
    
            /**
             * Properties of a INFBVTaskOutputCPTCodeBenefits.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputCPTCodeBenefits
             * @property {string|null} [additionalNotes] INFBVTaskOutputCPTCodeBenefits additionalNotes
             * @property {string|null} [code] INFBVTaskOutputCPTCodeBenefits code
             * @property {google.protobuf.IDoubleValue|null} [coinsurance] INFBVTaskOutputCPTCodeBenefits coinsurance
             * @property {google.protobuf.IDoubleValue|null} [copay] INFBVTaskOutputCPTCodeBenefits copay
             * @property {google.protobuf.IBoolValue|null} [deductibleApplies] INFBVTaskOutputCPTCodeBenefits deductibleApplies
             * @property {google.protobuf.IBoolValue|null} [preDeterminationOnFile] INFBVTaskOutputCPTCodeBenefits preDeterminationOnFile
             * @property {infinitusapi.INFPredeterminationRequirementType|null} [preDeterminationRequirement] INFBVTaskOutputCPTCodeBenefits preDeterminationRequirement
             * @property {google.protobuf.IBoolValue|null} [priorAuthRequired] INFBVTaskOutputCPTCodeBenefits priorAuthRequired
             * @property {infinitusapi.INFPriorAuthStatus|null} [priorAuthStatus] INFBVTaskOutputCPTCodeBenefits priorAuthStatus
             * @property {infinitusapi.INFReasonForNonCoverage|null} [reasonForNonCoverage] INFBVTaskOutputCPTCodeBenefits reasonForNonCoverage
             * @property {google.protobuf.IBoolValue|null} [valid] INFBVTaskOutputCPTCodeBenefits valid
             */
    
            /**
             * Constructs a new INFBVTaskOutputCPTCodeBenefits.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputCPTCodeBenefits.
             * @implements IINFBVTaskOutputCPTCodeBenefits
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputCPTCodeBenefits=} [properties] Properties to set
             */
            function INFBVTaskOutputCPTCodeBenefits(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputCPTCodeBenefits additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.additionalNotes = "";
    
            /**
             * INFBVTaskOutputCPTCodeBenefits code.
             * @member {string} code
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.code = "";
    
            /**
             * INFBVTaskOutputCPTCodeBenefits coinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} coinsurance
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.coinsurance = null;
    
            /**
             * INFBVTaskOutputCPTCodeBenefits copay.
             * @member {google.protobuf.IDoubleValue|null|undefined} copay
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.copay = null;
    
            /**
             * INFBVTaskOutputCPTCodeBenefits deductibleApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} deductibleApplies
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.deductibleApplies = null;
    
            /**
             * INFBVTaskOutputCPTCodeBenefits preDeterminationOnFile.
             * @member {google.protobuf.IBoolValue|null|undefined} preDeterminationOnFile
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.preDeterminationOnFile = null;
    
            /**
             * INFBVTaskOutputCPTCodeBenefits preDeterminationRequirement.
             * @member {infinitusapi.INFPredeterminationRequirementType} preDeterminationRequirement
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.preDeterminationRequirement = 0;
    
            /**
             * INFBVTaskOutputCPTCodeBenefits priorAuthRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthRequired
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.priorAuthRequired = null;
    
            /**
             * INFBVTaskOutputCPTCodeBenefits priorAuthStatus.
             * @member {infinitusapi.INFPriorAuthStatus} priorAuthStatus
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.priorAuthStatus = 0;
    
            /**
             * INFBVTaskOutputCPTCodeBenefits reasonForNonCoverage.
             * @member {infinitusapi.INFReasonForNonCoverage} reasonForNonCoverage
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.reasonForNonCoverage = 0;
    
            /**
             * INFBVTaskOutputCPTCodeBenefits valid.
             * @member {google.protobuf.IBoolValue|null|undefined} valid
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.valid = null;
    
            /**
             * Creates a new INFBVTaskOutputCPTCodeBenefits instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputCPTCodeBenefits=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputCPTCodeBenefits} INFBVTaskOutputCPTCodeBenefits instance
             */
            INFBVTaskOutputCPTCodeBenefits.create = function create(properties) {
                return new INFBVTaskOutputCPTCodeBenefits(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputCPTCodeBenefits message. Does not implicitly {@link infinitusapi.INFBVTaskOutputCPTCodeBenefits.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputCPTCodeBenefits} message INFBVTaskOutputCPTCodeBenefits message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputCPTCodeBenefits.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                if (message.coinsurance != null && Object.hasOwnProperty.call(message, "coinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.coinsurance, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.copay != null && Object.hasOwnProperty.call(message, "copay"))
                    $root.google.protobuf.DoubleValue.encode(message.copay, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.deductibleApplies != null && Object.hasOwnProperty.call(message, "deductibleApplies"))
                    $root.google.protobuf.BoolValue.encode(message.deductibleApplies, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.preDeterminationOnFile != null && Object.hasOwnProperty.call(message, "preDeterminationOnFile"))
                    $root.google.protobuf.BoolValue.encode(message.preDeterminationOnFile, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.preDeterminationRequirement != null && Object.hasOwnProperty.call(message, "preDeterminationRequirement"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.preDeterminationRequirement);
                if (message.priorAuthRequired != null && Object.hasOwnProperty.call(message, "priorAuthRequired"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthRequired, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.priorAuthStatus != null && Object.hasOwnProperty.call(message, "priorAuthStatus"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.priorAuthStatus);
                if (message.reasonForNonCoverage != null && Object.hasOwnProperty.call(message, "reasonForNonCoverage"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.reasonForNonCoverage);
                if (message.valid != null && Object.hasOwnProperty.call(message, "valid"))
                    $root.google.protobuf.BoolValue.encode(message.valid, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputCPTCodeBenefits message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputCPTCodeBenefits.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputCPTCodeBenefits} message INFBVTaskOutputCPTCodeBenefits message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputCPTCodeBenefits.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputCPTCodeBenefits message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputCPTCodeBenefits} INFBVTaskOutputCPTCodeBenefits
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputCPTCodeBenefits.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputCPTCodeBenefits();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.code = reader.string();
                            break;
                        }
                    case 3: {
                            message.coinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.copay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.deductibleApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.preDeterminationOnFile = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.preDeterminationRequirement = reader.int32();
                            break;
                        }
                    case 8: {
                            message.priorAuthRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.priorAuthStatus = reader.int32();
                            break;
                        }
                    case 10: {
                            message.reasonForNonCoverage = reader.int32();
                            break;
                        }
                    case 11: {
                            message.valid = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputCPTCodeBenefits message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputCPTCodeBenefits} INFBVTaskOutputCPTCodeBenefits
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputCPTCodeBenefits.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputCPTCodeBenefits message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputCPTCodeBenefits.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.coinsurance != null && message.hasOwnProperty("coinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.coinsurance);
                    if (error)
                        return "coinsurance." + error;
                }
                if (message.copay != null && message.hasOwnProperty("copay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.copay);
                    if (error)
                        return "copay." + error;
                }
                if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.deductibleApplies);
                    if (error)
                        return "deductibleApplies." + error;
                }
                if (message.preDeterminationOnFile != null && message.hasOwnProperty("preDeterminationOnFile")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.preDeterminationOnFile);
                    if (error)
                        return "preDeterminationOnFile." + error;
                }
                if (message.preDeterminationRequirement != null && message.hasOwnProperty("preDeterminationRequirement"))
                    switch (message.preDeterminationRequirement) {
                    default:
                        return "preDeterminationRequirement: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthRequired);
                    if (error)
                        return "priorAuthRequired." + error;
                }
                if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                    switch (message.priorAuthStatus) {
                    default:
                        return "priorAuthStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                if (message.reasonForNonCoverage != null && message.hasOwnProperty("reasonForNonCoverage"))
                    switch (message.reasonForNonCoverage) {
                    default:
                        return "reasonForNonCoverage: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                        break;
                    }
                if (message.valid != null && message.hasOwnProperty("valid")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.valid);
                    if (error)
                        return "valid." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputCPTCodeBenefits message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputCPTCodeBenefits} INFBVTaskOutputCPTCodeBenefits
             */
            INFBVTaskOutputCPTCodeBenefits.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputCPTCodeBenefits)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputCPTCodeBenefits();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.code != null)
                    message.code = String(object.code);
                if (object.coinsurance != null) {
                    if (typeof object.coinsurance !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputCPTCodeBenefits.coinsurance: object expected");
                    message.coinsurance = $root.google.protobuf.DoubleValue.fromObject(object.coinsurance);
                }
                if (object.copay != null) {
                    if (typeof object.copay !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputCPTCodeBenefits.copay: object expected");
                    message.copay = $root.google.protobuf.DoubleValue.fromObject(object.copay);
                }
                if (object.deductibleApplies != null) {
                    if (typeof object.deductibleApplies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputCPTCodeBenefits.deductibleApplies: object expected");
                    message.deductibleApplies = $root.google.protobuf.BoolValue.fromObject(object.deductibleApplies);
                }
                if (object.preDeterminationOnFile != null) {
                    if (typeof object.preDeterminationOnFile !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputCPTCodeBenefits.preDeterminationOnFile: object expected");
                    message.preDeterminationOnFile = $root.google.protobuf.BoolValue.fromObject(object.preDeterminationOnFile);
                }
                switch (object.preDeterminationRequirement) {
                default:
                    if (typeof object.preDeterminationRequirement === "number") {
                        message.preDeterminationRequirement = object.preDeterminationRequirement;
                        break;
                    }
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_UNKNOWN":
                case 0:
                    message.preDeterminationRequirement = 0;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_NOT_APPLICABLE":
                case 1:
                    message.preDeterminationRequirement = 1;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_REQUIRED":
                case 2:
                    message.preDeterminationRequirement = 2;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_HIGHLY_RECOMMENDED":
                case 3:
                    message.preDeterminationRequirement = 3;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_AVAILABLE":
                case 4:
                    message.preDeterminationRequirement = 4;
                    break;
                case "INF_PREDETERMINATION_REQUIREMENT_NOT_AVAILABLE":
                case 5:
                    message.preDeterminationRequirement = 5;
                    break;
                }
                if (object.priorAuthRequired != null) {
                    if (typeof object.priorAuthRequired !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputCPTCodeBenefits.priorAuthRequired: object expected");
                    message.priorAuthRequired = $root.google.protobuf.BoolValue.fromObject(object.priorAuthRequired);
                }
                switch (object.priorAuthStatus) {
                default:
                    if (typeof object.priorAuthStatus === "number") {
                        message.priorAuthStatus = object.priorAuthStatus;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_STATUS_UNKNOWN":
                case 0:
                    message.priorAuthStatus = 0;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_APPLICABLE":
                case 1:
                    message.priorAuthStatus = 1;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_ON_FILE":
                case 2:
                    message.priorAuthStatus = 2;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED":
                case 3:
                    message.priorAuthStatus = 3;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR":
                case 4:
                    message.priorAuthStatus = 4;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS":
                case 5:
                    message.priorAuthStatus = 5;
                    break;
                case "INF_PRIOR_AUTH_STATUS_DENIED":
                case 6:
                    message.priorAuthStatus = 6;
                    break;
                case "INF_PRIOR_AUTH_STATUS_EXPIRED":
                case 7:
                    message.priorAuthStatus = 7;
                    break;
                case "INF_PRIOR_AUTH_STATUS_FUTURE":
                case 8:
                    message.priorAuthStatus = 8;
                    break;
                case "INF_PRIOR_AUTH_STATUS_PENDING":
                case 9:
                    message.priorAuthStatus = 9;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS":
                case 10:
                    message.priorAuthStatus = 10;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS":
                case 11:
                    message.priorAuthStatus = 11;
                    break;
                }
                switch (object.reasonForNonCoverage) {
                default:
                    if (typeof object.reasonForNonCoverage === "number") {
                        message.reasonForNonCoverage = object.reasonForNonCoverage;
                        break;
                    }
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_UNKNOWN":
                case 0:
                    message.reasonForNonCoverage = 0;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NOT_APPLICABLE":
                case 1:
                    message.reasonForNonCoverage = 1;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM":
                case 2:
                    message.reasonForNonCoverage = 2;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS":
                case 3:
                    message.reasonForNonCoverage = 3;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B":
                case 4:
                    message.reasonForNonCoverage = 4;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_CAP_MET":
                case 5:
                    message.reasonForNonCoverage = 5;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS":
                case 6:
                    message.reasonForNonCoverage = 6;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG":
                case 7:
                    message.reasonForNonCoverage = 7;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT":
                case 8:
                    message.reasonForNonCoverage = 8;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG":
                case 9:
                    message.reasonForNonCoverage = 9;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL":
                case 10:
                    message.reasonForNonCoverage = 10;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY":
                case 11:
                    message.reasonForNonCoverage = 11;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION":
                case 12:
                    message.reasonForNonCoverage = 12;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY":
                case 13:
                    message.reasonForNonCoverage = 13;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED":
                case 14:
                    message.reasonForNonCoverage = 14;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED":
                case 15:
                    message.reasonForNonCoverage = 15;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK":
                case 16:
                    message.reasonForNonCoverage = 16;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_OTHER":
                case 17:
                    message.reasonForNonCoverage = 17;
                    break;
                }
                if (object.valid != null) {
                    if (typeof object.valid !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputCPTCodeBenefits.valid: object expected");
                    message.valid = $root.google.protobuf.BoolValue.fromObject(object.valid);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputCPTCodeBenefits message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {infinitusapi.INFBVTaskOutputCPTCodeBenefits} message INFBVTaskOutputCPTCodeBenefits
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputCPTCodeBenefits.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.code = "";
                    object.coinsurance = null;
                    object.copay = null;
                    object.deductibleApplies = null;
                    object.preDeterminationOnFile = null;
                    object.preDeterminationRequirement = options.enums === String ? "INF_PREDETERMINATION_REQUIREMENT_UNKNOWN" : 0;
                    object.priorAuthRequired = null;
                    object.priorAuthStatus = options.enums === String ? "INF_PRIOR_AUTH_STATUS_UNKNOWN" : 0;
                    object.reasonForNonCoverage = options.enums === String ? "INF_REASON_FOR_NON_COVERAGE_UNKNOWN" : 0;
                    object.valid = null;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.coinsurance != null && message.hasOwnProperty("coinsurance"))
                    object.coinsurance = $root.google.protobuf.DoubleValue.toObject(message.coinsurance, options);
                if (message.copay != null && message.hasOwnProperty("copay"))
                    object.copay = $root.google.protobuf.DoubleValue.toObject(message.copay, options);
                if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies"))
                    object.deductibleApplies = $root.google.protobuf.BoolValue.toObject(message.deductibleApplies, options);
                if (message.preDeterminationOnFile != null && message.hasOwnProperty("preDeterminationOnFile"))
                    object.preDeterminationOnFile = $root.google.protobuf.BoolValue.toObject(message.preDeterminationOnFile, options);
                if (message.preDeterminationRequirement != null && message.hasOwnProperty("preDeterminationRequirement"))
                    object.preDeterminationRequirement = options.enums === String ? $root.infinitusapi.INFPredeterminationRequirementType[message.preDeterminationRequirement] === undefined ? message.preDeterminationRequirement : $root.infinitusapi.INFPredeterminationRequirementType[message.preDeterminationRequirement] : message.preDeterminationRequirement;
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired"))
                    object.priorAuthRequired = $root.google.protobuf.BoolValue.toObject(message.priorAuthRequired, options);
                if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                    object.priorAuthStatus = options.enums === String ? $root.infinitusapi.INFPriorAuthStatus[message.priorAuthStatus] === undefined ? message.priorAuthStatus : $root.infinitusapi.INFPriorAuthStatus[message.priorAuthStatus] : message.priorAuthStatus;
                if (message.reasonForNonCoverage != null && message.hasOwnProperty("reasonForNonCoverage"))
                    object.reasonForNonCoverage = options.enums === String ? $root.infinitusapi.INFReasonForNonCoverage[message.reasonForNonCoverage] === undefined ? message.reasonForNonCoverage : $root.infinitusapi.INFReasonForNonCoverage[message.reasonForNonCoverage] : message.reasonForNonCoverage;
                if (message.valid != null && message.hasOwnProperty("valid"))
                    object.valid = $root.google.protobuf.BoolValue.toObject(message.valid, options);
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputCPTCodeBenefits to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputCPTCodeBenefits.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputCPTCodeBenefits
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputCPTCodeBenefits
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputCPTCodeBenefits.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputCPTCodeBenefits";
            };
    
            return INFBVTaskOutputCPTCodeBenefits;
        })();
    
        infinitusapi.INFBVTaskOutputDeductiblesAndOOPS = (function() {
    
            /**
             * Properties of a INFBVTaskOutputDeductiblesAndOOPS.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputDeductiblesAndOOPS
             * @property {string|null} [additionalNotes] INFBVTaskOutputDeductiblesAndOOPS additionalNotes
             * @property {google.protobuf.IDoubleValue|null} [annualBenefitCap] INFBVTaskOutputDeductiblesAndOOPS annualBenefitCap
             * @property {google.protobuf.IBoolValue|null} [annualBenefitCapExists] INFBVTaskOutputDeductiblesAndOOPS annualBenefitCapExists
             * @property {google.protobuf.IDoubleValue|null} [annualBenefitCapMetAmount] INFBVTaskOutputDeductiblesAndOOPS annualBenefitCapMetAmount
             * @property {google.protobuf.IBoolValue|null} [coinsuranceWaivedAfterOop] INFBVTaskOutputDeductiblesAndOOPS coinsuranceWaivedAfterOop
             * @property {google.protobuf.IBoolValue|null} [copayAppliesToOop] INFBVTaskOutputDeductiblesAndOOPS copayAppliesToOop
             * @property {google.protobuf.IBoolValue|null} [copayWaivedAfterOop] INFBVTaskOutputDeductiblesAndOOPS copayWaivedAfterOop
             * @property {google.protobuf.IBoolValue|null} [deductibleIncludedInOop] INFBVTaskOutputDeductiblesAndOOPS deductibleIncludedInOop
             * @property {google.protobuf.IBoolValue|null} [deductibleMetBeforeCOB] INFBVTaskOutputDeductiblesAndOOPS deductibleMetBeforeCOB
             * @property {google.protobuf.IBoolValue|null} [familyDeductibleExists] INFBVTaskOutputDeductiblesAndOOPS familyDeductibleExists
             * @property {google.protobuf.IDoubleValue|null} [familyDeductibleMetAmount] INFBVTaskOutputDeductiblesAndOOPS familyDeductibleMetAmount
             * @property {google.protobuf.IDoubleValue|null} [familyOopMaximum] INFBVTaskOutputDeductiblesAndOOPS familyOopMaximum
             * @property {google.protobuf.IBoolValue|null} [familyOopMaximumExists] INFBVTaskOutputDeductiblesAndOOPS familyOopMaximumExists
             * @property {google.protobuf.IDoubleValue|null} [familyOopMetAmount] INFBVTaskOutputDeductiblesAndOOPS familyOopMetAmount
             * @property {google.protobuf.IDoubleValue|null} [familyTotalDeductible] INFBVTaskOutputDeductiblesAndOOPS familyTotalDeductible
             * @property {google.protobuf.IBoolValue|null} [homeHealthDeductibleExists] INFBVTaskOutputDeductiblesAndOOPS homeHealthDeductibleExists
             * @property {google.protobuf.IDoubleValue|null} [homeHealthDeductibleMetAmount] INFBVTaskOutputDeductiblesAndOOPS homeHealthDeductibleMetAmount
             * @property {google.protobuf.IDoubleValue|null} [homeHealthOopMaximum] INFBVTaskOutputDeductiblesAndOOPS homeHealthOopMaximum
             * @property {google.protobuf.IBoolValue|null} [homeHealthOopMaximumExists] INFBVTaskOutputDeductiblesAndOOPS homeHealthOopMaximumExists
             * @property {google.protobuf.IDoubleValue|null} [homeHealthOopMaximumMetAmount] INFBVTaskOutputDeductiblesAndOOPS homeHealthOopMaximumMetAmount
             * @property {google.protobuf.IDoubleValue|null} [homeHealthTotalDeductible] INFBVTaskOutputDeductiblesAndOOPS homeHealthTotalDeductible
             * @property {google.protobuf.IBoolValue|null} [individualDeductibleExists] INFBVTaskOutputDeductiblesAndOOPS individualDeductibleExists
             * @property {google.protobuf.IDoubleValue|null} [individualDeductibleMetAmount] INFBVTaskOutputDeductiblesAndOOPS individualDeductibleMetAmount
             * @property {google.protobuf.IDoubleValue|null} [individualOopMaximum] INFBVTaskOutputDeductiblesAndOOPS individualOopMaximum
             * @property {google.protobuf.IBoolValue|null} [individualOopMaximumExists] INFBVTaskOutputDeductiblesAndOOPS individualOopMaximumExists
             * @property {google.protobuf.IDoubleValue|null} [individualOopMetAmount] INFBVTaskOutputDeductiblesAndOOPS individualOopMetAmount
             * @property {google.protobuf.IBoolValue|null} [individualOopMetUnavailable] INFBVTaskOutputDeductiblesAndOOPS individualOopMetUnavailable
             * @property {google.protobuf.IDoubleValue|null} [individualTotalDeductible] INFBVTaskOutputDeductiblesAndOOPS individualTotalDeductible
             * @property {google.protobuf.IBoolValue|null} [isAccumulatorPlan] INFBVTaskOutputDeductiblesAndOOPS isAccumulatorPlan
             * @property {google.protobuf.IBoolValue|null} [isMaximizerPlan] INFBVTaskOutputDeductiblesAndOOPS isMaximizerPlan
             * @property {google.protobuf.IDoubleValue|null} [lifetimeMaximum] INFBVTaskOutputDeductiblesAndOOPS lifetimeMaximum
             * @property {google.protobuf.IBoolValue|null} [lifetimeMaximumExists] INFBVTaskOutputDeductiblesAndOOPS lifetimeMaximumExists
             * @property {google.protobuf.IDoubleValue|null} [lifetimeMaximumMetAmount] INFBVTaskOutputDeductiblesAndOOPS lifetimeMaximumMetAmount
             * @property {google.protobuf.IBoolValue|null} [multipleCopayResponsibility] INFBVTaskOutputDeductiblesAndOOPS multipleCopayResponsibility
             * @property {google.protobuf.IBoolValue|null} [oopMetBeforeCOB] INFBVTaskOutputDeductiblesAndOOPS oopMetBeforeCOB
             * @property {google.protobuf.IBoolValue|null} [spendDownExists] INFBVTaskOutputDeductiblesAndOOPS spendDownExists
             * @property {google.protobuf.IDoubleValue|null} [spendDownMet] INFBVTaskOutputDeductiblesAndOOPS spendDownMet
             * @property {google.protobuf.IDoubleValue|null} [spendDownTotal] INFBVTaskOutputDeductiblesAndOOPS spendDownTotal
             */
    
            /**
             * Constructs a new INFBVTaskOutputDeductiblesAndOOPS.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputDeductiblesAndOOPS.
             * @implements IINFBVTaskOutputDeductiblesAndOOPS
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputDeductiblesAndOOPS=} [properties] Properties to set
             */
            function INFBVTaskOutputDeductiblesAndOOPS(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.additionalNotes = "";
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS annualBenefitCap.
             * @member {google.protobuf.IDoubleValue|null|undefined} annualBenefitCap
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.annualBenefitCap = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS annualBenefitCapExists.
             * @member {google.protobuf.IBoolValue|null|undefined} annualBenefitCapExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.annualBenefitCapExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS annualBenefitCapMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} annualBenefitCapMetAmount
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.annualBenefitCapMetAmount = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS coinsuranceWaivedAfterOop.
             * @member {google.protobuf.IBoolValue|null|undefined} coinsuranceWaivedAfterOop
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.coinsuranceWaivedAfterOop = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS copayAppliesToOop.
             * @member {google.protobuf.IBoolValue|null|undefined} copayAppliesToOop
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.copayAppliesToOop = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS copayWaivedAfterOop.
             * @member {google.protobuf.IBoolValue|null|undefined} copayWaivedAfterOop
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.copayWaivedAfterOop = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS deductibleIncludedInOop.
             * @member {google.protobuf.IBoolValue|null|undefined} deductibleIncludedInOop
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.deductibleIncludedInOop = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS deductibleMetBeforeCOB.
             * @member {google.protobuf.IBoolValue|null|undefined} deductibleMetBeforeCOB
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.deductibleMetBeforeCOB = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS familyDeductibleExists.
             * @member {google.protobuf.IBoolValue|null|undefined} familyDeductibleExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.familyDeductibleExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS familyDeductibleMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} familyDeductibleMetAmount
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.familyDeductibleMetAmount = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS familyOopMaximum.
             * @member {google.protobuf.IDoubleValue|null|undefined} familyOopMaximum
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.familyOopMaximum = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS familyOopMaximumExists.
             * @member {google.protobuf.IBoolValue|null|undefined} familyOopMaximumExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.familyOopMaximumExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS familyOopMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} familyOopMetAmount
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.familyOopMetAmount = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS familyTotalDeductible.
             * @member {google.protobuf.IDoubleValue|null|undefined} familyTotalDeductible
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.familyTotalDeductible = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS homeHealthDeductibleExists.
             * @member {google.protobuf.IBoolValue|null|undefined} homeHealthDeductibleExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.homeHealthDeductibleExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS homeHealthDeductibleMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} homeHealthDeductibleMetAmount
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.homeHealthDeductibleMetAmount = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS homeHealthOopMaximum.
             * @member {google.protobuf.IDoubleValue|null|undefined} homeHealthOopMaximum
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.homeHealthOopMaximum = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS homeHealthOopMaximumExists.
             * @member {google.protobuf.IBoolValue|null|undefined} homeHealthOopMaximumExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.homeHealthOopMaximumExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS homeHealthOopMaximumMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} homeHealthOopMaximumMetAmount
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.homeHealthOopMaximumMetAmount = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS homeHealthTotalDeductible.
             * @member {google.protobuf.IDoubleValue|null|undefined} homeHealthTotalDeductible
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.homeHealthTotalDeductible = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS individualDeductibleExists.
             * @member {google.protobuf.IBoolValue|null|undefined} individualDeductibleExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.individualDeductibleExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS individualDeductibleMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} individualDeductibleMetAmount
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.individualDeductibleMetAmount = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS individualOopMaximum.
             * @member {google.protobuf.IDoubleValue|null|undefined} individualOopMaximum
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.individualOopMaximum = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS individualOopMaximumExists.
             * @member {google.protobuf.IBoolValue|null|undefined} individualOopMaximumExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.individualOopMaximumExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS individualOopMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} individualOopMetAmount
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.individualOopMetAmount = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS individualOopMetUnavailable.
             * @member {google.protobuf.IBoolValue|null|undefined} individualOopMetUnavailable
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.individualOopMetUnavailable = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS individualTotalDeductible.
             * @member {google.protobuf.IDoubleValue|null|undefined} individualTotalDeductible
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.individualTotalDeductible = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS isAccumulatorPlan.
             * @member {google.protobuf.IBoolValue|null|undefined} isAccumulatorPlan
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.isAccumulatorPlan = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS isMaximizerPlan.
             * @member {google.protobuf.IBoolValue|null|undefined} isMaximizerPlan
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.isMaximizerPlan = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS lifetimeMaximum.
             * @member {google.protobuf.IDoubleValue|null|undefined} lifetimeMaximum
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.lifetimeMaximum = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS lifetimeMaximumExists.
             * @member {google.protobuf.IBoolValue|null|undefined} lifetimeMaximumExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.lifetimeMaximumExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS lifetimeMaximumMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} lifetimeMaximumMetAmount
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.lifetimeMaximumMetAmount = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS multipleCopayResponsibility.
             * @member {google.protobuf.IBoolValue|null|undefined} multipleCopayResponsibility
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.multipleCopayResponsibility = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS oopMetBeforeCOB.
             * @member {google.protobuf.IBoolValue|null|undefined} oopMetBeforeCOB
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.oopMetBeforeCOB = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS spendDownExists.
             * @member {google.protobuf.IBoolValue|null|undefined} spendDownExists
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.spendDownExists = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS spendDownMet.
             * @member {google.protobuf.IDoubleValue|null|undefined} spendDownMet
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.spendDownMet = null;
    
            /**
             * INFBVTaskOutputDeductiblesAndOOPS spendDownTotal.
             * @member {google.protobuf.IDoubleValue|null|undefined} spendDownTotal
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.spendDownTotal = null;
    
            /**
             * Creates a new INFBVTaskOutputDeductiblesAndOOPS instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {infinitusapi.IINFBVTaskOutputDeductiblesAndOOPS=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputDeductiblesAndOOPS} INFBVTaskOutputDeductiblesAndOOPS instance
             */
            INFBVTaskOutputDeductiblesAndOOPS.create = function create(properties) {
                return new INFBVTaskOutputDeductiblesAndOOPS(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputDeductiblesAndOOPS message. Does not implicitly {@link infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {infinitusapi.IINFBVTaskOutputDeductiblesAndOOPS} message INFBVTaskOutputDeductiblesAndOOPS message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputDeductiblesAndOOPS.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.annualBenefitCap != null && Object.hasOwnProperty.call(message, "annualBenefitCap"))
                    $root.google.protobuf.DoubleValue.encode(message.annualBenefitCap, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.annualBenefitCapExists != null && Object.hasOwnProperty.call(message, "annualBenefitCapExists"))
                    $root.google.protobuf.BoolValue.encode(message.annualBenefitCapExists, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.annualBenefitCapMetAmount != null && Object.hasOwnProperty.call(message, "annualBenefitCapMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.annualBenefitCapMetAmount, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.coinsuranceWaivedAfterOop != null && Object.hasOwnProperty.call(message, "coinsuranceWaivedAfterOop"))
                    $root.google.protobuf.BoolValue.encode(message.coinsuranceWaivedAfterOop, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.copayAppliesToOop != null && Object.hasOwnProperty.call(message, "copayAppliesToOop"))
                    $root.google.protobuf.BoolValue.encode(message.copayAppliesToOop, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.copayWaivedAfterOop != null && Object.hasOwnProperty.call(message, "copayWaivedAfterOop"))
                    $root.google.protobuf.BoolValue.encode(message.copayWaivedAfterOop, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.deductibleIncludedInOop != null && Object.hasOwnProperty.call(message, "deductibleIncludedInOop"))
                    $root.google.protobuf.BoolValue.encode(message.deductibleIncludedInOop, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.deductibleMetBeforeCOB != null && Object.hasOwnProperty.call(message, "deductibleMetBeforeCOB"))
                    $root.google.protobuf.BoolValue.encode(message.deductibleMetBeforeCOB, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.familyDeductibleExists != null && Object.hasOwnProperty.call(message, "familyDeductibleExists"))
                    $root.google.protobuf.BoolValue.encode(message.familyDeductibleExists, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.familyDeductibleMetAmount != null && Object.hasOwnProperty.call(message, "familyDeductibleMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.familyDeductibleMetAmount, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.familyOopMaximum != null && Object.hasOwnProperty.call(message, "familyOopMaximum"))
                    $root.google.protobuf.DoubleValue.encode(message.familyOopMaximum, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.familyOopMaximumExists != null && Object.hasOwnProperty.call(message, "familyOopMaximumExists"))
                    $root.google.protobuf.BoolValue.encode(message.familyOopMaximumExists, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.familyOopMetAmount != null && Object.hasOwnProperty.call(message, "familyOopMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.familyOopMetAmount, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.familyTotalDeductible != null && Object.hasOwnProperty.call(message, "familyTotalDeductible"))
                    $root.google.protobuf.DoubleValue.encode(message.familyTotalDeductible, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.homeHealthDeductibleExists != null && Object.hasOwnProperty.call(message, "homeHealthDeductibleExists"))
                    $root.google.protobuf.BoolValue.encode(message.homeHealthDeductibleExists, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.homeHealthDeductibleMetAmount != null && Object.hasOwnProperty.call(message, "homeHealthDeductibleMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.homeHealthDeductibleMetAmount, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.homeHealthOopMaximum != null && Object.hasOwnProperty.call(message, "homeHealthOopMaximum"))
                    $root.google.protobuf.DoubleValue.encode(message.homeHealthOopMaximum, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.homeHealthOopMaximumExists != null && Object.hasOwnProperty.call(message, "homeHealthOopMaximumExists"))
                    $root.google.protobuf.BoolValue.encode(message.homeHealthOopMaximumExists, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.homeHealthOopMaximumMetAmount != null && Object.hasOwnProperty.call(message, "homeHealthOopMaximumMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.homeHealthOopMaximumMetAmount, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.homeHealthTotalDeductible != null && Object.hasOwnProperty.call(message, "homeHealthTotalDeductible"))
                    $root.google.protobuf.DoubleValue.encode(message.homeHealthTotalDeductible, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.individualDeductibleExists != null && Object.hasOwnProperty.call(message, "individualDeductibleExists"))
                    $root.google.protobuf.BoolValue.encode(message.individualDeductibleExists, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.individualDeductibleMetAmount != null && Object.hasOwnProperty.call(message, "individualDeductibleMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.individualDeductibleMetAmount, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.individualOopMaximum != null && Object.hasOwnProperty.call(message, "individualOopMaximum"))
                    $root.google.protobuf.DoubleValue.encode(message.individualOopMaximum, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.individualOopMaximumExists != null && Object.hasOwnProperty.call(message, "individualOopMaximumExists"))
                    $root.google.protobuf.BoolValue.encode(message.individualOopMaximumExists, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.individualOopMetAmount != null && Object.hasOwnProperty.call(message, "individualOopMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.individualOopMetAmount, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.individualOopMetUnavailable != null && Object.hasOwnProperty.call(message, "individualOopMetUnavailable"))
                    $root.google.protobuf.BoolValue.encode(message.individualOopMetUnavailable, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.individualTotalDeductible != null && Object.hasOwnProperty.call(message, "individualTotalDeductible"))
                    $root.google.protobuf.DoubleValue.encode(message.individualTotalDeductible, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.isAccumulatorPlan != null && Object.hasOwnProperty.call(message, "isAccumulatorPlan"))
                    $root.google.protobuf.BoolValue.encode(message.isAccumulatorPlan, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                if (message.isMaximizerPlan != null && Object.hasOwnProperty.call(message, "isMaximizerPlan"))
                    $root.google.protobuf.BoolValue.encode(message.isMaximizerPlan, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.lifetimeMaximum != null && Object.hasOwnProperty.call(message, "lifetimeMaximum"))
                    $root.google.protobuf.DoubleValue.encode(message.lifetimeMaximum, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                if (message.lifetimeMaximumExists != null && Object.hasOwnProperty.call(message, "lifetimeMaximumExists"))
                    $root.google.protobuf.BoolValue.encode(message.lifetimeMaximumExists, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.lifetimeMaximumMetAmount != null && Object.hasOwnProperty.call(message, "lifetimeMaximumMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.lifetimeMaximumMetAmount, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                if (message.multipleCopayResponsibility != null && Object.hasOwnProperty.call(message, "multipleCopayResponsibility"))
                    $root.google.protobuf.BoolValue.encode(message.multipleCopayResponsibility, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.oopMetBeforeCOB != null && Object.hasOwnProperty.call(message, "oopMetBeforeCOB"))
                    $root.google.protobuf.BoolValue.encode(message.oopMetBeforeCOB, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                if (message.spendDownExists != null && Object.hasOwnProperty.call(message, "spendDownExists"))
                    $root.google.protobuf.BoolValue.encode(message.spendDownExists, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                if (message.spendDownMet != null && Object.hasOwnProperty.call(message, "spendDownMet"))
                    $root.google.protobuf.DoubleValue.encode(message.spendDownMet, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                if (message.spendDownTotal != null && Object.hasOwnProperty.call(message, "spendDownTotal"))
                    $root.google.protobuf.DoubleValue.encode(message.spendDownTotal, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputDeductiblesAndOOPS message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {infinitusapi.IINFBVTaskOutputDeductiblesAndOOPS} message INFBVTaskOutputDeductiblesAndOOPS message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputDeductiblesAndOOPS.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputDeductiblesAndOOPS message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputDeductiblesAndOOPS} INFBVTaskOutputDeductiblesAndOOPS
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputDeductiblesAndOOPS.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputDeductiblesAndOOPS();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.annualBenefitCap = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.annualBenefitCapExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.annualBenefitCapMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.coinsuranceWaivedAfterOop = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.copayAppliesToOop = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.copayWaivedAfterOop = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.deductibleIncludedInOop = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.deductibleMetBeforeCOB = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.familyDeductibleExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.familyDeductibleMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.familyOopMaximum = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.familyOopMaximumExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.familyOopMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.familyTotalDeductible = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.homeHealthDeductibleExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.homeHealthDeductibleMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.homeHealthOopMaximum = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 19: {
                            message.homeHealthOopMaximumExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 20: {
                            message.homeHealthOopMaximumMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 21: {
                            message.homeHealthTotalDeductible = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 22: {
                            message.individualDeductibleExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 23: {
                            message.individualDeductibleMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 24: {
                            message.individualOopMaximum = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 25: {
                            message.individualOopMaximumExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 26: {
                            message.individualOopMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 27: {
                            message.individualOopMetUnavailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 28: {
                            message.individualTotalDeductible = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 29: {
                            message.isAccumulatorPlan = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 30: {
                            message.isMaximizerPlan = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 31: {
                            message.lifetimeMaximum = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 32: {
                            message.lifetimeMaximumExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 33: {
                            message.lifetimeMaximumMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 34: {
                            message.multipleCopayResponsibility = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 35: {
                            message.oopMetBeforeCOB = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 36: {
                            message.spendDownExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 37: {
                            message.spendDownMet = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 38: {
                            message.spendDownTotal = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputDeductiblesAndOOPS message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputDeductiblesAndOOPS} INFBVTaskOutputDeductiblesAndOOPS
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputDeductiblesAndOOPS.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputDeductiblesAndOOPS message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputDeductiblesAndOOPS.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.annualBenefitCap != null && message.hasOwnProperty("annualBenefitCap")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.annualBenefitCap);
                    if (error)
                        return "annualBenefitCap." + error;
                }
                if (message.annualBenefitCapExists != null && message.hasOwnProperty("annualBenefitCapExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.annualBenefitCapExists);
                    if (error)
                        return "annualBenefitCapExists." + error;
                }
                if (message.annualBenefitCapMetAmount != null && message.hasOwnProperty("annualBenefitCapMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.annualBenefitCapMetAmount);
                    if (error)
                        return "annualBenefitCapMetAmount." + error;
                }
                if (message.coinsuranceWaivedAfterOop != null && message.hasOwnProperty("coinsuranceWaivedAfterOop")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.coinsuranceWaivedAfterOop);
                    if (error)
                        return "coinsuranceWaivedAfterOop." + error;
                }
                if (message.copayAppliesToOop != null && message.hasOwnProperty("copayAppliesToOop")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.copayAppliesToOop);
                    if (error)
                        return "copayAppliesToOop." + error;
                }
                if (message.copayWaivedAfterOop != null && message.hasOwnProperty("copayWaivedAfterOop")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.copayWaivedAfterOop);
                    if (error)
                        return "copayWaivedAfterOop." + error;
                }
                if (message.deductibleIncludedInOop != null && message.hasOwnProperty("deductibleIncludedInOop")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.deductibleIncludedInOop);
                    if (error)
                        return "deductibleIncludedInOop." + error;
                }
                if (message.deductibleMetBeforeCOB != null && message.hasOwnProperty("deductibleMetBeforeCOB")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.deductibleMetBeforeCOB);
                    if (error)
                        return "deductibleMetBeforeCOB." + error;
                }
                if (message.familyDeductibleExists != null && message.hasOwnProperty("familyDeductibleExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.familyDeductibleExists);
                    if (error)
                        return "familyDeductibleExists." + error;
                }
                if (message.familyDeductibleMetAmount != null && message.hasOwnProperty("familyDeductibleMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.familyDeductibleMetAmount);
                    if (error)
                        return "familyDeductibleMetAmount." + error;
                }
                if (message.familyOopMaximum != null && message.hasOwnProperty("familyOopMaximum")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.familyOopMaximum);
                    if (error)
                        return "familyOopMaximum." + error;
                }
                if (message.familyOopMaximumExists != null && message.hasOwnProperty("familyOopMaximumExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.familyOopMaximumExists);
                    if (error)
                        return "familyOopMaximumExists." + error;
                }
                if (message.familyOopMetAmount != null && message.hasOwnProperty("familyOopMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.familyOopMetAmount);
                    if (error)
                        return "familyOopMetAmount." + error;
                }
                if (message.familyTotalDeductible != null && message.hasOwnProperty("familyTotalDeductible")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.familyTotalDeductible);
                    if (error)
                        return "familyTotalDeductible." + error;
                }
                if (message.homeHealthDeductibleExists != null && message.hasOwnProperty("homeHealthDeductibleExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.homeHealthDeductibleExists);
                    if (error)
                        return "homeHealthDeductibleExists." + error;
                }
                if (message.homeHealthDeductibleMetAmount != null && message.hasOwnProperty("homeHealthDeductibleMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.homeHealthDeductibleMetAmount);
                    if (error)
                        return "homeHealthDeductibleMetAmount." + error;
                }
                if (message.homeHealthOopMaximum != null && message.hasOwnProperty("homeHealthOopMaximum")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.homeHealthOopMaximum);
                    if (error)
                        return "homeHealthOopMaximum." + error;
                }
                if (message.homeHealthOopMaximumExists != null && message.hasOwnProperty("homeHealthOopMaximumExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.homeHealthOopMaximumExists);
                    if (error)
                        return "homeHealthOopMaximumExists." + error;
                }
                if (message.homeHealthOopMaximumMetAmount != null && message.hasOwnProperty("homeHealthOopMaximumMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.homeHealthOopMaximumMetAmount);
                    if (error)
                        return "homeHealthOopMaximumMetAmount." + error;
                }
                if (message.homeHealthTotalDeductible != null && message.hasOwnProperty("homeHealthTotalDeductible")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.homeHealthTotalDeductible);
                    if (error)
                        return "homeHealthTotalDeductible." + error;
                }
                if (message.individualDeductibleExists != null && message.hasOwnProperty("individualDeductibleExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.individualDeductibleExists);
                    if (error)
                        return "individualDeductibleExists." + error;
                }
                if (message.individualDeductibleMetAmount != null && message.hasOwnProperty("individualDeductibleMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.individualDeductibleMetAmount);
                    if (error)
                        return "individualDeductibleMetAmount." + error;
                }
                if (message.individualOopMaximum != null && message.hasOwnProperty("individualOopMaximum")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.individualOopMaximum);
                    if (error)
                        return "individualOopMaximum." + error;
                }
                if (message.individualOopMaximumExists != null && message.hasOwnProperty("individualOopMaximumExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.individualOopMaximumExists);
                    if (error)
                        return "individualOopMaximumExists." + error;
                }
                if (message.individualOopMetAmount != null && message.hasOwnProperty("individualOopMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.individualOopMetAmount);
                    if (error)
                        return "individualOopMetAmount." + error;
                }
                if (message.individualOopMetUnavailable != null && message.hasOwnProperty("individualOopMetUnavailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.individualOopMetUnavailable);
                    if (error)
                        return "individualOopMetUnavailable." + error;
                }
                if (message.individualTotalDeductible != null && message.hasOwnProperty("individualTotalDeductible")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.individualTotalDeductible);
                    if (error)
                        return "individualTotalDeductible." + error;
                }
                if (message.isAccumulatorPlan != null && message.hasOwnProperty("isAccumulatorPlan")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isAccumulatorPlan);
                    if (error)
                        return "isAccumulatorPlan." + error;
                }
                if (message.isMaximizerPlan != null && message.hasOwnProperty("isMaximizerPlan")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isMaximizerPlan);
                    if (error)
                        return "isMaximizerPlan." + error;
                }
                if (message.lifetimeMaximum != null && message.hasOwnProperty("lifetimeMaximum")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.lifetimeMaximum);
                    if (error)
                        return "lifetimeMaximum." + error;
                }
                if (message.lifetimeMaximumExists != null && message.hasOwnProperty("lifetimeMaximumExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.lifetimeMaximumExists);
                    if (error)
                        return "lifetimeMaximumExists." + error;
                }
                if (message.lifetimeMaximumMetAmount != null && message.hasOwnProperty("lifetimeMaximumMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.lifetimeMaximumMetAmount);
                    if (error)
                        return "lifetimeMaximumMetAmount." + error;
                }
                if (message.multipleCopayResponsibility != null && message.hasOwnProperty("multipleCopayResponsibility")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.multipleCopayResponsibility);
                    if (error)
                        return "multipleCopayResponsibility." + error;
                }
                if (message.oopMetBeforeCOB != null && message.hasOwnProperty("oopMetBeforeCOB")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.oopMetBeforeCOB);
                    if (error)
                        return "oopMetBeforeCOB." + error;
                }
                if (message.spendDownExists != null && message.hasOwnProperty("spendDownExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.spendDownExists);
                    if (error)
                        return "spendDownExists." + error;
                }
                if (message.spendDownMet != null && message.hasOwnProperty("spendDownMet")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.spendDownMet);
                    if (error)
                        return "spendDownMet." + error;
                }
                if (message.spendDownTotal != null && message.hasOwnProperty("spendDownTotal")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.spendDownTotal);
                    if (error)
                        return "spendDownTotal." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputDeductiblesAndOOPS message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputDeductiblesAndOOPS} INFBVTaskOutputDeductiblesAndOOPS
             */
            INFBVTaskOutputDeductiblesAndOOPS.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputDeductiblesAndOOPS)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputDeductiblesAndOOPS();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.annualBenefitCap != null) {
                    if (typeof object.annualBenefitCap !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.annualBenefitCap: object expected");
                    message.annualBenefitCap = $root.google.protobuf.DoubleValue.fromObject(object.annualBenefitCap);
                }
                if (object.annualBenefitCapExists != null) {
                    if (typeof object.annualBenefitCapExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.annualBenefitCapExists: object expected");
                    message.annualBenefitCapExists = $root.google.protobuf.BoolValue.fromObject(object.annualBenefitCapExists);
                }
                if (object.annualBenefitCapMetAmount != null) {
                    if (typeof object.annualBenefitCapMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.annualBenefitCapMetAmount: object expected");
                    message.annualBenefitCapMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.annualBenefitCapMetAmount);
                }
                if (object.coinsuranceWaivedAfterOop != null) {
                    if (typeof object.coinsuranceWaivedAfterOop !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.coinsuranceWaivedAfterOop: object expected");
                    message.coinsuranceWaivedAfterOop = $root.google.protobuf.BoolValue.fromObject(object.coinsuranceWaivedAfterOop);
                }
                if (object.copayAppliesToOop != null) {
                    if (typeof object.copayAppliesToOop !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.copayAppliesToOop: object expected");
                    message.copayAppliesToOop = $root.google.protobuf.BoolValue.fromObject(object.copayAppliesToOop);
                }
                if (object.copayWaivedAfterOop != null) {
                    if (typeof object.copayWaivedAfterOop !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.copayWaivedAfterOop: object expected");
                    message.copayWaivedAfterOop = $root.google.protobuf.BoolValue.fromObject(object.copayWaivedAfterOop);
                }
                if (object.deductibleIncludedInOop != null) {
                    if (typeof object.deductibleIncludedInOop !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.deductibleIncludedInOop: object expected");
                    message.deductibleIncludedInOop = $root.google.protobuf.BoolValue.fromObject(object.deductibleIncludedInOop);
                }
                if (object.deductibleMetBeforeCOB != null) {
                    if (typeof object.deductibleMetBeforeCOB !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.deductibleMetBeforeCOB: object expected");
                    message.deductibleMetBeforeCOB = $root.google.protobuf.BoolValue.fromObject(object.deductibleMetBeforeCOB);
                }
                if (object.familyDeductibleExists != null) {
                    if (typeof object.familyDeductibleExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.familyDeductibleExists: object expected");
                    message.familyDeductibleExists = $root.google.protobuf.BoolValue.fromObject(object.familyDeductibleExists);
                }
                if (object.familyDeductibleMetAmount != null) {
                    if (typeof object.familyDeductibleMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.familyDeductibleMetAmount: object expected");
                    message.familyDeductibleMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.familyDeductibleMetAmount);
                }
                if (object.familyOopMaximum != null) {
                    if (typeof object.familyOopMaximum !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.familyOopMaximum: object expected");
                    message.familyOopMaximum = $root.google.protobuf.DoubleValue.fromObject(object.familyOopMaximum);
                }
                if (object.familyOopMaximumExists != null) {
                    if (typeof object.familyOopMaximumExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.familyOopMaximumExists: object expected");
                    message.familyOopMaximumExists = $root.google.protobuf.BoolValue.fromObject(object.familyOopMaximumExists);
                }
                if (object.familyOopMetAmount != null) {
                    if (typeof object.familyOopMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.familyOopMetAmount: object expected");
                    message.familyOopMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.familyOopMetAmount);
                }
                if (object.familyTotalDeductible != null) {
                    if (typeof object.familyTotalDeductible !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.familyTotalDeductible: object expected");
                    message.familyTotalDeductible = $root.google.protobuf.DoubleValue.fromObject(object.familyTotalDeductible);
                }
                if (object.homeHealthDeductibleExists != null) {
                    if (typeof object.homeHealthDeductibleExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.homeHealthDeductibleExists: object expected");
                    message.homeHealthDeductibleExists = $root.google.protobuf.BoolValue.fromObject(object.homeHealthDeductibleExists);
                }
                if (object.homeHealthDeductibleMetAmount != null) {
                    if (typeof object.homeHealthDeductibleMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.homeHealthDeductibleMetAmount: object expected");
                    message.homeHealthDeductibleMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.homeHealthDeductibleMetAmount);
                }
                if (object.homeHealthOopMaximum != null) {
                    if (typeof object.homeHealthOopMaximum !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.homeHealthOopMaximum: object expected");
                    message.homeHealthOopMaximum = $root.google.protobuf.DoubleValue.fromObject(object.homeHealthOopMaximum);
                }
                if (object.homeHealthOopMaximumExists != null) {
                    if (typeof object.homeHealthOopMaximumExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.homeHealthOopMaximumExists: object expected");
                    message.homeHealthOopMaximumExists = $root.google.protobuf.BoolValue.fromObject(object.homeHealthOopMaximumExists);
                }
                if (object.homeHealthOopMaximumMetAmount != null) {
                    if (typeof object.homeHealthOopMaximumMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.homeHealthOopMaximumMetAmount: object expected");
                    message.homeHealthOopMaximumMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.homeHealthOopMaximumMetAmount);
                }
                if (object.homeHealthTotalDeductible != null) {
                    if (typeof object.homeHealthTotalDeductible !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.homeHealthTotalDeductible: object expected");
                    message.homeHealthTotalDeductible = $root.google.protobuf.DoubleValue.fromObject(object.homeHealthTotalDeductible);
                }
                if (object.individualDeductibleExists != null) {
                    if (typeof object.individualDeductibleExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.individualDeductibleExists: object expected");
                    message.individualDeductibleExists = $root.google.protobuf.BoolValue.fromObject(object.individualDeductibleExists);
                }
                if (object.individualDeductibleMetAmount != null) {
                    if (typeof object.individualDeductibleMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.individualDeductibleMetAmount: object expected");
                    message.individualDeductibleMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.individualDeductibleMetAmount);
                }
                if (object.individualOopMaximum != null) {
                    if (typeof object.individualOopMaximum !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.individualOopMaximum: object expected");
                    message.individualOopMaximum = $root.google.protobuf.DoubleValue.fromObject(object.individualOopMaximum);
                }
                if (object.individualOopMaximumExists != null) {
                    if (typeof object.individualOopMaximumExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.individualOopMaximumExists: object expected");
                    message.individualOopMaximumExists = $root.google.protobuf.BoolValue.fromObject(object.individualOopMaximumExists);
                }
                if (object.individualOopMetAmount != null) {
                    if (typeof object.individualOopMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.individualOopMetAmount: object expected");
                    message.individualOopMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.individualOopMetAmount);
                }
                if (object.individualOopMetUnavailable != null) {
                    if (typeof object.individualOopMetUnavailable !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.individualOopMetUnavailable: object expected");
                    message.individualOopMetUnavailable = $root.google.protobuf.BoolValue.fromObject(object.individualOopMetUnavailable);
                }
                if (object.individualTotalDeductible != null) {
                    if (typeof object.individualTotalDeductible !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.individualTotalDeductible: object expected");
                    message.individualTotalDeductible = $root.google.protobuf.DoubleValue.fromObject(object.individualTotalDeductible);
                }
                if (object.isAccumulatorPlan != null) {
                    if (typeof object.isAccumulatorPlan !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.isAccumulatorPlan: object expected");
                    message.isAccumulatorPlan = $root.google.protobuf.BoolValue.fromObject(object.isAccumulatorPlan);
                }
                if (object.isMaximizerPlan != null) {
                    if (typeof object.isMaximizerPlan !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.isMaximizerPlan: object expected");
                    message.isMaximizerPlan = $root.google.protobuf.BoolValue.fromObject(object.isMaximizerPlan);
                }
                if (object.lifetimeMaximum != null) {
                    if (typeof object.lifetimeMaximum !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.lifetimeMaximum: object expected");
                    message.lifetimeMaximum = $root.google.protobuf.DoubleValue.fromObject(object.lifetimeMaximum);
                }
                if (object.lifetimeMaximumExists != null) {
                    if (typeof object.lifetimeMaximumExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.lifetimeMaximumExists: object expected");
                    message.lifetimeMaximumExists = $root.google.protobuf.BoolValue.fromObject(object.lifetimeMaximumExists);
                }
                if (object.lifetimeMaximumMetAmount != null) {
                    if (typeof object.lifetimeMaximumMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.lifetimeMaximumMetAmount: object expected");
                    message.lifetimeMaximumMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.lifetimeMaximumMetAmount);
                }
                if (object.multipleCopayResponsibility != null) {
                    if (typeof object.multipleCopayResponsibility !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.multipleCopayResponsibility: object expected");
                    message.multipleCopayResponsibility = $root.google.protobuf.BoolValue.fromObject(object.multipleCopayResponsibility);
                }
                if (object.oopMetBeforeCOB != null) {
                    if (typeof object.oopMetBeforeCOB !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.oopMetBeforeCOB: object expected");
                    message.oopMetBeforeCOB = $root.google.protobuf.BoolValue.fromObject(object.oopMetBeforeCOB);
                }
                if (object.spendDownExists != null) {
                    if (typeof object.spendDownExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.spendDownExists: object expected");
                    message.spendDownExists = $root.google.protobuf.BoolValue.fromObject(object.spendDownExists);
                }
                if (object.spendDownMet != null) {
                    if (typeof object.spendDownMet !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.spendDownMet: object expected");
                    message.spendDownMet = $root.google.protobuf.DoubleValue.fromObject(object.spendDownMet);
                }
                if (object.spendDownTotal != null) {
                    if (typeof object.spendDownTotal !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputDeductiblesAndOOPS.spendDownTotal: object expected");
                    message.spendDownTotal = $root.google.protobuf.DoubleValue.fromObject(object.spendDownTotal);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputDeductiblesAndOOPS message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {infinitusapi.INFBVTaskOutputDeductiblesAndOOPS} message INFBVTaskOutputDeductiblesAndOOPS
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputDeductiblesAndOOPS.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.annualBenefitCap = null;
                    object.annualBenefitCapExists = null;
                    object.annualBenefitCapMetAmount = null;
                    object.coinsuranceWaivedAfterOop = null;
                    object.copayAppliesToOop = null;
                    object.copayWaivedAfterOop = null;
                    object.deductibleIncludedInOop = null;
                    object.deductibleMetBeforeCOB = null;
                    object.familyDeductibleExists = null;
                    object.familyDeductibleMetAmount = null;
                    object.familyOopMaximum = null;
                    object.familyOopMaximumExists = null;
                    object.familyOopMetAmount = null;
                    object.familyTotalDeductible = null;
                    object.homeHealthDeductibleExists = null;
                    object.homeHealthDeductibleMetAmount = null;
                    object.homeHealthOopMaximum = null;
                    object.homeHealthOopMaximumExists = null;
                    object.homeHealthOopMaximumMetAmount = null;
                    object.homeHealthTotalDeductible = null;
                    object.individualDeductibleExists = null;
                    object.individualDeductibleMetAmount = null;
                    object.individualOopMaximum = null;
                    object.individualOopMaximumExists = null;
                    object.individualOopMetAmount = null;
                    object.individualOopMetUnavailable = null;
                    object.individualTotalDeductible = null;
                    object.isAccumulatorPlan = null;
                    object.isMaximizerPlan = null;
                    object.lifetimeMaximum = null;
                    object.lifetimeMaximumExists = null;
                    object.lifetimeMaximumMetAmount = null;
                    object.multipleCopayResponsibility = null;
                    object.oopMetBeforeCOB = null;
                    object.spendDownExists = null;
                    object.spendDownMet = null;
                    object.spendDownTotal = null;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.annualBenefitCap != null && message.hasOwnProperty("annualBenefitCap"))
                    object.annualBenefitCap = $root.google.protobuf.DoubleValue.toObject(message.annualBenefitCap, options);
                if (message.annualBenefitCapExists != null && message.hasOwnProperty("annualBenefitCapExists"))
                    object.annualBenefitCapExists = $root.google.protobuf.BoolValue.toObject(message.annualBenefitCapExists, options);
                if (message.annualBenefitCapMetAmount != null && message.hasOwnProperty("annualBenefitCapMetAmount"))
                    object.annualBenefitCapMetAmount = $root.google.protobuf.DoubleValue.toObject(message.annualBenefitCapMetAmount, options);
                if (message.coinsuranceWaivedAfterOop != null && message.hasOwnProperty("coinsuranceWaivedAfterOop"))
                    object.coinsuranceWaivedAfterOop = $root.google.protobuf.BoolValue.toObject(message.coinsuranceWaivedAfterOop, options);
                if (message.copayAppliesToOop != null && message.hasOwnProperty("copayAppliesToOop"))
                    object.copayAppliesToOop = $root.google.protobuf.BoolValue.toObject(message.copayAppliesToOop, options);
                if (message.copayWaivedAfterOop != null && message.hasOwnProperty("copayWaivedAfterOop"))
                    object.copayWaivedAfterOop = $root.google.protobuf.BoolValue.toObject(message.copayWaivedAfterOop, options);
                if (message.deductibleIncludedInOop != null && message.hasOwnProperty("deductibleIncludedInOop"))
                    object.deductibleIncludedInOop = $root.google.protobuf.BoolValue.toObject(message.deductibleIncludedInOop, options);
                if (message.deductibleMetBeforeCOB != null && message.hasOwnProperty("deductibleMetBeforeCOB"))
                    object.deductibleMetBeforeCOB = $root.google.protobuf.BoolValue.toObject(message.deductibleMetBeforeCOB, options);
                if (message.familyDeductibleExists != null && message.hasOwnProperty("familyDeductibleExists"))
                    object.familyDeductibleExists = $root.google.protobuf.BoolValue.toObject(message.familyDeductibleExists, options);
                if (message.familyDeductibleMetAmount != null && message.hasOwnProperty("familyDeductibleMetAmount"))
                    object.familyDeductibleMetAmount = $root.google.protobuf.DoubleValue.toObject(message.familyDeductibleMetAmount, options);
                if (message.familyOopMaximum != null && message.hasOwnProperty("familyOopMaximum"))
                    object.familyOopMaximum = $root.google.protobuf.DoubleValue.toObject(message.familyOopMaximum, options);
                if (message.familyOopMaximumExists != null && message.hasOwnProperty("familyOopMaximumExists"))
                    object.familyOopMaximumExists = $root.google.protobuf.BoolValue.toObject(message.familyOopMaximumExists, options);
                if (message.familyOopMetAmount != null && message.hasOwnProperty("familyOopMetAmount"))
                    object.familyOopMetAmount = $root.google.protobuf.DoubleValue.toObject(message.familyOopMetAmount, options);
                if (message.familyTotalDeductible != null && message.hasOwnProperty("familyTotalDeductible"))
                    object.familyTotalDeductible = $root.google.protobuf.DoubleValue.toObject(message.familyTotalDeductible, options);
                if (message.homeHealthDeductibleExists != null && message.hasOwnProperty("homeHealthDeductibleExists"))
                    object.homeHealthDeductibleExists = $root.google.protobuf.BoolValue.toObject(message.homeHealthDeductibleExists, options);
                if (message.homeHealthDeductibleMetAmount != null && message.hasOwnProperty("homeHealthDeductibleMetAmount"))
                    object.homeHealthDeductibleMetAmount = $root.google.protobuf.DoubleValue.toObject(message.homeHealthDeductibleMetAmount, options);
                if (message.homeHealthOopMaximum != null && message.hasOwnProperty("homeHealthOopMaximum"))
                    object.homeHealthOopMaximum = $root.google.protobuf.DoubleValue.toObject(message.homeHealthOopMaximum, options);
                if (message.homeHealthOopMaximumExists != null && message.hasOwnProperty("homeHealthOopMaximumExists"))
                    object.homeHealthOopMaximumExists = $root.google.protobuf.BoolValue.toObject(message.homeHealthOopMaximumExists, options);
                if (message.homeHealthOopMaximumMetAmount != null && message.hasOwnProperty("homeHealthOopMaximumMetAmount"))
                    object.homeHealthOopMaximumMetAmount = $root.google.protobuf.DoubleValue.toObject(message.homeHealthOopMaximumMetAmount, options);
                if (message.homeHealthTotalDeductible != null && message.hasOwnProperty("homeHealthTotalDeductible"))
                    object.homeHealthTotalDeductible = $root.google.protobuf.DoubleValue.toObject(message.homeHealthTotalDeductible, options);
                if (message.individualDeductibleExists != null && message.hasOwnProperty("individualDeductibleExists"))
                    object.individualDeductibleExists = $root.google.protobuf.BoolValue.toObject(message.individualDeductibleExists, options);
                if (message.individualDeductibleMetAmount != null && message.hasOwnProperty("individualDeductibleMetAmount"))
                    object.individualDeductibleMetAmount = $root.google.protobuf.DoubleValue.toObject(message.individualDeductibleMetAmount, options);
                if (message.individualOopMaximum != null && message.hasOwnProperty("individualOopMaximum"))
                    object.individualOopMaximum = $root.google.protobuf.DoubleValue.toObject(message.individualOopMaximum, options);
                if (message.individualOopMaximumExists != null && message.hasOwnProperty("individualOopMaximumExists"))
                    object.individualOopMaximumExists = $root.google.protobuf.BoolValue.toObject(message.individualOopMaximumExists, options);
                if (message.individualOopMetAmount != null && message.hasOwnProperty("individualOopMetAmount"))
                    object.individualOopMetAmount = $root.google.protobuf.DoubleValue.toObject(message.individualOopMetAmount, options);
                if (message.individualOopMetUnavailable != null && message.hasOwnProperty("individualOopMetUnavailable"))
                    object.individualOopMetUnavailable = $root.google.protobuf.BoolValue.toObject(message.individualOopMetUnavailable, options);
                if (message.individualTotalDeductible != null && message.hasOwnProperty("individualTotalDeductible"))
                    object.individualTotalDeductible = $root.google.protobuf.DoubleValue.toObject(message.individualTotalDeductible, options);
                if (message.isAccumulatorPlan != null && message.hasOwnProperty("isAccumulatorPlan"))
                    object.isAccumulatorPlan = $root.google.protobuf.BoolValue.toObject(message.isAccumulatorPlan, options);
                if (message.isMaximizerPlan != null && message.hasOwnProperty("isMaximizerPlan"))
                    object.isMaximizerPlan = $root.google.protobuf.BoolValue.toObject(message.isMaximizerPlan, options);
                if (message.lifetimeMaximum != null && message.hasOwnProperty("lifetimeMaximum"))
                    object.lifetimeMaximum = $root.google.protobuf.DoubleValue.toObject(message.lifetimeMaximum, options);
                if (message.lifetimeMaximumExists != null && message.hasOwnProperty("lifetimeMaximumExists"))
                    object.lifetimeMaximumExists = $root.google.protobuf.BoolValue.toObject(message.lifetimeMaximumExists, options);
                if (message.lifetimeMaximumMetAmount != null && message.hasOwnProperty("lifetimeMaximumMetAmount"))
                    object.lifetimeMaximumMetAmount = $root.google.protobuf.DoubleValue.toObject(message.lifetimeMaximumMetAmount, options);
                if (message.multipleCopayResponsibility != null && message.hasOwnProperty("multipleCopayResponsibility"))
                    object.multipleCopayResponsibility = $root.google.protobuf.BoolValue.toObject(message.multipleCopayResponsibility, options);
                if (message.oopMetBeforeCOB != null && message.hasOwnProperty("oopMetBeforeCOB"))
                    object.oopMetBeforeCOB = $root.google.protobuf.BoolValue.toObject(message.oopMetBeforeCOB, options);
                if (message.spendDownExists != null && message.hasOwnProperty("spendDownExists"))
                    object.spendDownExists = $root.google.protobuf.BoolValue.toObject(message.spendDownExists, options);
                if (message.spendDownMet != null && message.hasOwnProperty("spendDownMet"))
                    object.spendDownMet = $root.google.protobuf.DoubleValue.toObject(message.spendDownMet, options);
                if (message.spendDownTotal != null && message.hasOwnProperty("spendDownTotal"))
                    object.spendDownTotal = $root.google.protobuf.DoubleValue.toObject(message.spendDownTotal, options);
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputDeductiblesAndOOPS to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputDeductiblesAndOOPS.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputDeductiblesAndOOPS
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputDeductiblesAndOOPS
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputDeductiblesAndOOPS.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputDeductiblesAndOOPS";
            };
    
            return INFBVTaskOutputDeductiblesAndOOPS;
        })();
    
        infinitusapi.INFBVTaskOutputErrorInfo = (function() {
    
            /**
             * Properties of a INFBVTaskOutputErrorInfo.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputErrorInfo
             * @property {infinitusapi.INFFailureReason|null} [failureReason] INFBVTaskOutputErrorInfo failureReason
             */
    
            /**
             * Constructs a new INFBVTaskOutputErrorInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputErrorInfo.
             * @implements IINFBVTaskOutputErrorInfo
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputErrorInfo=} [properties] Properties to set
             */
            function INFBVTaskOutputErrorInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputErrorInfo failureReason.
             * @member {infinitusapi.INFFailureReason} failureReason
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @instance
             */
            INFBVTaskOutputErrorInfo.prototype.failureReason = 0;
    
            /**
             * Creates a new INFBVTaskOutputErrorInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputErrorInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputErrorInfo} INFBVTaskOutputErrorInfo instance
             */
            INFBVTaskOutputErrorInfo.create = function create(properties) {
                return new INFBVTaskOutputErrorInfo(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputErrorInfo message. Does not implicitly {@link infinitusapi.INFBVTaskOutputErrorInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputErrorInfo} message INFBVTaskOutputErrorInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputErrorInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.failureReason);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputErrorInfo message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputErrorInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputErrorInfo} message INFBVTaskOutputErrorInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputErrorInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputErrorInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputErrorInfo} INFBVTaskOutputErrorInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputErrorInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputErrorInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.failureReason = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputErrorInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputErrorInfo} INFBVTaskOutputErrorInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputErrorInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputErrorInfo message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputErrorInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    switch (message.failureReason) {
                    default:
                        return "failureReason: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputErrorInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputErrorInfo} INFBVTaskOutputErrorInfo
             */
            INFBVTaskOutputErrorInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputErrorInfo)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputErrorInfo();
                switch (object.failureReason) {
                default:
                    if (typeof object.failureReason === "number") {
                        message.failureReason = object.failureReason;
                        break;
                    }
                    break;
                case "INF_FAILURE_REASON_UNKNOWN":
                case 0:
                    message.failureReason = 0;
                    break;
                case "INF_FAILURE_REASON_BAD_DATA":
                case 1:
                    message.failureReason = 1;
                    break;
                case "INF_FAILURE_REASON_PAYER_UNREACHABLE":
                case 2:
                    message.failureReason = 2;
                    break;
                case "INF_FAILURE_REASON_PAYER_REFUSED_THIRD_PARTY":
                case 3:
                    message.failureReason = 3;
                    break;
                case "INF_FAILURE_REASON_CANCELLED_BY_CUSTOMER":
                case 4:
                    message.failureReason = 4;
                    break;
                case "INF_FAILURE_REASON_TASK_EXPIRED":
                case 5:
                    message.failureReason = 5;
                    break;
                case "INF_FAILURE_REASON_OTHER":
                case 6:
                    message.failureReason = 6;
                    break;
                case "INF_FAILURE_REASON_PRESCRIBER_UNREACHABLE":
                case 7:
                    message.failureReason = 7;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputErrorInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.INFBVTaskOutputErrorInfo} message INFBVTaskOutputErrorInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputErrorInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.failureReason = options.enums === String ? "INF_FAILURE_REASON_UNKNOWN" : 0;
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    object.failureReason = options.enums === String ? $root.infinitusapi.INFFailureReason[message.failureReason] === undefined ? message.failureReason : $root.infinitusapi.INFFailureReason[message.failureReason] : message.failureReason;
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputErrorInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputErrorInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputErrorInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputErrorInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputErrorInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputErrorInfo";
            };
    
            return INFBVTaskOutputErrorInfo;
        })();
    
        infinitusapi.INFBVTaskOutputHomeHealthBenefits = (function() {
    
            /**
             * Properties of a INFBVTaskOutputHomeHealthBenefits.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputHomeHealthBenefits
             * @property {google.protobuf.IDoubleValue|null} [coinsurance] INFBVTaskOutputHomeHealthBenefits coinsurance
             * @property {google.protobuf.IDoubleValue|null} [copay] INFBVTaskOutputHomeHealthBenefits copay
             * @property {google.protobuf.IBoolValue|null} [deductibleApplies] INFBVTaskOutputHomeHealthBenefits deductibleApplies
             */
    
            /**
             * Constructs a new INFBVTaskOutputHomeHealthBenefits.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputHomeHealthBenefits.
             * @implements IINFBVTaskOutputHomeHealthBenefits
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputHomeHealthBenefits=} [properties] Properties to set
             */
            function INFBVTaskOutputHomeHealthBenefits(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputHomeHealthBenefits coinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} coinsurance
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @instance
             */
            INFBVTaskOutputHomeHealthBenefits.prototype.coinsurance = null;
    
            /**
             * INFBVTaskOutputHomeHealthBenefits copay.
             * @member {google.protobuf.IDoubleValue|null|undefined} copay
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @instance
             */
            INFBVTaskOutputHomeHealthBenefits.prototype.copay = null;
    
            /**
             * INFBVTaskOutputHomeHealthBenefits deductibleApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} deductibleApplies
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @instance
             */
            INFBVTaskOutputHomeHealthBenefits.prototype.deductibleApplies = null;
    
            /**
             * Creates a new INFBVTaskOutputHomeHealthBenefits instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputHomeHealthBenefits=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputHomeHealthBenefits} INFBVTaskOutputHomeHealthBenefits instance
             */
            INFBVTaskOutputHomeHealthBenefits.create = function create(properties) {
                return new INFBVTaskOutputHomeHealthBenefits(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputHomeHealthBenefits message. Does not implicitly {@link infinitusapi.INFBVTaskOutputHomeHealthBenefits.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputHomeHealthBenefits} message INFBVTaskOutputHomeHealthBenefits message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputHomeHealthBenefits.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.coinsurance != null && Object.hasOwnProperty.call(message, "coinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.coinsurance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.copay != null && Object.hasOwnProperty.call(message, "copay"))
                    $root.google.protobuf.DoubleValue.encode(message.copay, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.deductibleApplies != null && Object.hasOwnProperty.call(message, "deductibleApplies"))
                    $root.google.protobuf.BoolValue.encode(message.deductibleApplies, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputHomeHealthBenefits message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputHomeHealthBenefits.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputHomeHealthBenefits} message INFBVTaskOutputHomeHealthBenefits message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputHomeHealthBenefits.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputHomeHealthBenefits message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputHomeHealthBenefits} INFBVTaskOutputHomeHealthBenefits
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputHomeHealthBenefits.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputHomeHealthBenefits();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.coinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.copay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.deductibleApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputHomeHealthBenefits message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputHomeHealthBenefits} INFBVTaskOutputHomeHealthBenefits
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputHomeHealthBenefits.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputHomeHealthBenefits message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputHomeHealthBenefits.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.coinsurance != null && message.hasOwnProperty("coinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.coinsurance);
                    if (error)
                        return "coinsurance." + error;
                }
                if (message.copay != null && message.hasOwnProperty("copay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.copay);
                    if (error)
                        return "copay." + error;
                }
                if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.deductibleApplies);
                    if (error)
                        return "deductibleApplies." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputHomeHealthBenefits message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputHomeHealthBenefits} INFBVTaskOutputHomeHealthBenefits
             */
            INFBVTaskOutputHomeHealthBenefits.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputHomeHealthBenefits)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputHomeHealthBenefits();
                if (object.coinsurance != null) {
                    if (typeof object.coinsurance !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputHomeHealthBenefits.coinsurance: object expected");
                    message.coinsurance = $root.google.protobuf.DoubleValue.fromObject(object.coinsurance);
                }
                if (object.copay != null) {
                    if (typeof object.copay !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputHomeHealthBenefits.copay: object expected");
                    message.copay = $root.google.protobuf.DoubleValue.fromObject(object.copay);
                }
                if (object.deductibleApplies != null) {
                    if (typeof object.deductibleApplies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputHomeHealthBenefits.deductibleApplies: object expected");
                    message.deductibleApplies = $root.google.protobuf.BoolValue.fromObject(object.deductibleApplies);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputHomeHealthBenefits message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {infinitusapi.INFBVTaskOutputHomeHealthBenefits} message INFBVTaskOutputHomeHealthBenefits
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputHomeHealthBenefits.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.coinsurance = null;
                    object.copay = null;
                    object.deductibleApplies = null;
                }
                if (message.coinsurance != null && message.hasOwnProperty("coinsurance"))
                    object.coinsurance = $root.google.protobuf.DoubleValue.toObject(message.coinsurance, options);
                if (message.copay != null && message.hasOwnProperty("copay"))
                    object.copay = $root.google.protobuf.DoubleValue.toObject(message.copay, options);
                if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies"))
                    object.deductibleApplies = $root.google.protobuf.BoolValue.toObject(message.deductibleApplies, options);
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputHomeHealthBenefits to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputHomeHealthBenefits.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputHomeHealthBenefits
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputHomeHealthBenefits
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputHomeHealthBenefits.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputHomeHealthBenefits";
            };
    
            return INFBVTaskOutputHomeHealthBenefits;
        })();
    
        infinitusapi.INFBVTaskOutputOfficeBenefits = (function() {
    
            /**
             * Properties of a INFBVTaskOutputOfficeBenefits.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputOfficeBenefits
             * @property {string|null} [additionalNotes] INFBVTaskOutputOfficeBenefits additionalNotes
             * @property {google.protobuf.IBoolValue|null} [alwaysApplies] INFBVTaskOutputOfficeBenefits alwaysApplies
             * @property {google.protobuf.IDoubleValue|null} [coinsurance] INFBVTaskOutputOfficeBenefits coinsurance
             * @property {google.protobuf.IDoubleValue|null} [copay] INFBVTaskOutputOfficeBenefits copay
             * @property {google.protobuf.IBoolValue|null} [deductibleApplies] INFBVTaskOutputOfficeBenefits deductibleApplies
             */
    
            /**
             * Constructs a new INFBVTaskOutputOfficeBenefits.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputOfficeBenefits.
             * @implements IINFBVTaskOutputOfficeBenefits
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputOfficeBenefits=} [properties] Properties to set
             */
            function INFBVTaskOutputOfficeBenefits(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputOfficeBenefits additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @instance
             */
            INFBVTaskOutputOfficeBenefits.prototype.additionalNotes = "";
    
            /**
             * INFBVTaskOutputOfficeBenefits alwaysApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} alwaysApplies
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @instance
             */
            INFBVTaskOutputOfficeBenefits.prototype.alwaysApplies = null;
    
            /**
             * INFBVTaskOutputOfficeBenefits coinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} coinsurance
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @instance
             */
            INFBVTaskOutputOfficeBenefits.prototype.coinsurance = null;
    
            /**
             * INFBVTaskOutputOfficeBenefits copay.
             * @member {google.protobuf.IDoubleValue|null|undefined} copay
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @instance
             */
            INFBVTaskOutputOfficeBenefits.prototype.copay = null;
    
            /**
             * INFBVTaskOutputOfficeBenefits deductibleApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} deductibleApplies
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @instance
             */
            INFBVTaskOutputOfficeBenefits.prototype.deductibleApplies = null;
    
            /**
             * Creates a new INFBVTaskOutputOfficeBenefits instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputOfficeBenefits=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputOfficeBenefits} INFBVTaskOutputOfficeBenefits instance
             */
            INFBVTaskOutputOfficeBenefits.create = function create(properties) {
                return new INFBVTaskOutputOfficeBenefits(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputOfficeBenefits message. Does not implicitly {@link infinitusapi.INFBVTaskOutputOfficeBenefits.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputOfficeBenefits} message INFBVTaskOutputOfficeBenefits message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputOfficeBenefits.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.alwaysApplies != null && Object.hasOwnProperty.call(message, "alwaysApplies"))
                    $root.google.protobuf.BoolValue.encode(message.alwaysApplies, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.coinsurance != null && Object.hasOwnProperty.call(message, "coinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.coinsurance, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.copay != null && Object.hasOwnProperty.call(message, "copay"))
                    $root.google.protobuf.DoubleValue.encode(message.copay, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.deductibleApplies != null && Object.hasOwnProperty.call(message, "deductibleApplies"))
                    $root.google.protobuf.BoolValue.encode(message.deductibleApplies, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputOfficeBenefits message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputOfficeBenefits.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputOfficeBenefits} message INFBVTaskOutputOfficeBenefits message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputOfficeBenefits.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputOfficeBenefits message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputOfficeBenefits} INFBVTaskOutputOfficeBenefits
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputOfficeBenefits.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputOfficeBenefits();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.alwaysApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.coinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.copay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.deductibleApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputOfficeBenefits message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputOfficeBenefits} INFBVTaskOutputOfficeBenefits
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputOfficeBenefits.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputOfficeBenefits message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputOfficeBenefits.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.alwaysApplies != null && message.hasOwnProperty("alwaysApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.alwaysApplies);
                    if (error)
                        return "alwaysApplies." + error;
                }
                if (message.coinsurance != null && message.hasOwnProperty("coinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.coinsurance);
                    if (error)
                        return "coinsurance." + error;
                }
                if (message.copay != null && message.hasOwnProperty("copay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.copay);
                    if (error)
                        return "copay." + error;
                }
                if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.deductibleApplies);
                    if (error)
                        return "deductibleApplies." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputOfficeBenefits message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputOfficeBenefits} INFBVTaskOutputOfficeBenefits
             */
            INFBVTaskOutputOfficeBenefits.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputOfficeBenefits)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputOfficeBenefits();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.alwaysApplies != null) {
                    if (typeof object.alwaysApplies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputOfficeBenefits.alwaysApplies: object expected");
                    message.alwaysApplies = $root.google.protobuf.BoolValue.fromObject(object.alwaysApplies);
                }
                if (object.coinsurance != null) {
                    if (typeof object.coinsurance !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputOfficeBenefits.coinsurance: object expected");
                    message.coinsurance = $root.google.protobuf.DoubleValue.fromObject(object.coinsurance);
                }
                if (object.copay != null) {
                    if (typeof object.copay !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputOfficeBenefits.copay: object expected");
                    message.copay = $root.google.protobuf.DoubleValue.fromObject(object.copay);
                }
                if (object.deductibleApplies != null) {
                    if (typeof object.deductibleApplies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputOfficeBenefits.deductibleApplies: object expected");
                    message.deductibleApplies = $root.google.protobuf.BoolValue.fromObject(object.deductibleApplies);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputOfficeBenefits message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {infinitusapi.INFBVTaskOutputOfficeBenefits} message INFBVTaskOutputOfficeBenefits
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputOfficeBenefits.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.alwaysApplies = null;
                    object.coinsurance = null;
                    object.copay = null;
                    object.deductibleApplies = null;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.alwaysApplies != null && message.hasOwnProperty("alwaysApplies"))
                    object.alwaysApplies = $root.google.protobuf.BoolValue.toObject(message.alwaysApplies, options);
                if (message.coinsurance != null && message.hasOwnProperty("coinsurance"))
                    object.coinsurance = $root.google.protobuf.DoubleValue.toObject(message.coinsurance, options);
                if (message.copay != null && message.hasOwnProperty("copay"))
                    object.copay = $root.google.protobuf.DoubleValue.toObject(message.copay, options);
                if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies"))
                    object.deductibleApplies = $root.google.protobuf.BoolValue.toObject(message.deductibleApplies, options);
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputOfficeBenefits to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputOfficeBenefits.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputOfficeBenefits
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputOfficeBenefits
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputOfficeBenefits.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputOfficeBenefits";
            };
    
            return INFBVTaskOutputOfficeBenefits;
        })();
    
        infinitusapi.INFBVTaskOutputPlanInfo = (function() {
    
            /**
             * Properties of a INFBVTaskOutputPlanInfo.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputPlanInfo
             * @property {string|null} [additionalNotes] INFBVTaskOutputPlanInfo additionalNotes
             * @property {google.protobuf.IDoubleValue|null} [benefitsTier] INFBVTaskOutputPlanInfo benefitsTier
             * @property {string|null} [claimsResponsibleOrgName] INFBVTaskOutputPlanInfo claimsResponsibleOrgName
             * @property {string|null} [cobraGracePeriod] INFBVTaskOutputPlanInfo cobraGracePeriod
             * @property {google.protobuf.IBoolValue|null} [cobraHasGracePeriod] INFBVTaskOutputPlanInfo cobraHasGracePeriod
             * @property {string|null} [cobraPaidThroughDate] INFBVTaskOutputPlanInfo cobraPaidThroughDate
             * @property {infinitusapi.INFCoordinationOfBenefits|null} [coordinationOfBenefits] INFBVTaskOutputPlanInfo coordinationOfBenefits
             * @property {infinitusapi.INFCurrentPlanPriority|null} [currentPlanPriority] INFBVTaskOutputPlanInfo currentPlanPriority
             * @property {google.protobuf.IBoolValue|null} [followsMedicareGuidelines] INFBVTaskOutputPlanInfo followsMedicareGuidelines
             * @property {infinitusapi.INFFundingType|null} [fundingType] INFBVTaskOutputPlanInfo fundingType
             * @property {google.protobuf.IBoolValue|null} [hasStandardPlanLetter] INFBVTaskOutputPlanInfo hasStandardPlanLetter
             * @property {string|null} [healthExchangeGracePeriod] INFBVTaskOutputPlanInfo healthExchangeGracePeriod
             * @property {google.protobuf.IBoolValue|null} [healthExchangeHasGracePeriod] INFBVTaskOutputPlanInfo healthExchangeHasGracePeriod
             * @property {string|null} [healthExchangePaidThroughDate] INFBVTaskOutputPlanInfo healthExchangePaidThroughDate
             * @property {string|null} [inNetworkConsideration] INFBVTaskOutputPlanInfo inNetworkConsideration
             * @property {google.protobuf.IBoolValue|null} [isCobraPlan] INFBVTaskOutputPlanInfo isCobraPlan
             * @property {google.protobuf.IBoolValue|null} [isHealthExchangePlan] INFBVTaskOutputPlanInfo isHealthExchangePlan
             * @property {google.protobuf.IBoolValue|null} [newPlanAvailable] INFBVTaskOutputPlanInfo newPlanAvailable
             * @property {string|null} [newPlanEffectiveDate] INFBVTaskOutputPlanInfo newPlanEffectiveDate
             * @property {string|null} [newPlanSubscriberId] INFBVTaskOutputPlanInfo newPlanSubscriberId
             * @property {google.protobuf.IBoolValue|null} [otherInsuranceExists] INFBVTaskOutputPlanInfo otherInsuranceExists
             * @property {string|null} [otherInsuranceName] INFBVTaskOutputPlanInfo otherInsuranceName
             * @property {google.protobuf.IBoolValue|null} [patientReceivesSubsidies] INFBVTaskOutputPlanInfo patientReceivesSubsidies
             * @property {google.protobuf.IBoolValue|null} [pbmExists] INFBVTaskOutputPlanInfo pbmExists
             * @property {string|null} [pbmName] INFBVTaskOutputPlanInfo pbmName
             * @property {string|null} [pbmPayerID] INFBVTaskOutputPlanInfo pbmPayerID
             * @property {string|null} [pbmPhone] INFBVTaskOutputPlanInfo pbmPhone
             * @property {string|null} [pbmPolicyID] INFBVTaskOutputPlanInfo pbmPolicyID
             * @property {string|null} [planCode] INFBVTaskOutputPlanInfo planCode
             * @property {string|null} [planEffectiveDate] INFBVTaskOutputPlanInfo planEffectiveDate
             * @property {string|null} [planGroupName] INFBVTaskOutputPlanInfo planGroupName
             * @property {string|null} [planGroupNumber] INFBVTaskOutputPlanInfo planGroupNumber
             * @property {string|null} [planName] INFBVTaskOutputPlanInfo planName
             * @property {infinitusapi.INFPlanRenewalMonth|null} [planRenewalMonth] INFBVTaskOutputPlanInfo planRenewalMonth
             * @property {string|null} [planTerminationDate] INFBVTaskOutputPlanInfo planTerminationDate
             * @property {infinitusapi.INFPlanType|null} [planType] INFBVTaskOutputPlanInfo planType
             * @property {string|null} [policyHolderDateOfBirth] INFBVTaskOutputPlanInfo policyHolderDateOfBirth
             * @property {string|null} [policyHolderFirstName] INFBVTaskOutputPlanInfo policyHolderFirstName
             * @property {string|null} [policyHolderLastName] INFBVTaskOutputPlanInfo policyHolderLastName
             * @property {infinitusapi.INFPolicyHolderRelationship|null} [policyHolderRelationship] INFBVTaskOutputPlanInfo policyHolderRelationship
             * @property {infinitusapi.INFPolicyType|null} [policyType] INFBVTaskOutputPlanInfo policyType
             * @property {infinitusapi.INFPracticeNetworkStatus|null} [practiceNetworkStatus] INFBVTaskOutputPlanInfo practiceNetworkStatus
             * @property {infinitusapi.IINFAddress|null} [providerInNetworkAddress] INFBVTaskOutputPlanInfo providerInNetworkAddress
             * @property {infinitusapi.INFProviderNetworkStatus|null} [providerNetworkStatus] INFBVTaskOutputPlanInfo providerNetworkStatus
             * @property {infinitusapi.INFProviderNetworkStatusOnly|null} [providerNetworkStatusOnly] INFBVTaskOutputPlanInfo providerNetworkStatusOnly
             * @property {infinitusapi.INFPlanRenewalType|null} [renewalType] INFBVTaskOutputPlanInfo renewalType
             * @property {Array.<infinitusapi.IINFBVTaskOutputPlanInfoSiteOfCare>|null} [sitesOfCare] INFBVTaskOutputPlanInfo sitesOfCare
             * @property {infinitusapi.INFStandardPlanLetter|null} [standardPlanLetter] INFBVTaskOutputPlanInfo standardPlanLetter
             * @property {google.protobuf.IBoolValue|null} [willCoverIfPrimaryDenies] INFBVTaskOutputPlanInfo willCoverIfPrimaryDenies
             * @property {google.protobuf.IBoolValue|null} [willCoverPartBDeductible] INFBVTaskOutputPlanInfo willCoverPartBDeductible
             */
    
            /**
             * Constructs a new INFBVTaskOutputPlanInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputPlanInfo.
             * @implements IINFBVTaskOutputPlanInfo
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputPlanInfo=} [properties] Properties to set
             */
            function INFBVTaskOutputPlanInfo(properties) {
                this.sitesOfCare = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputPlanInfo additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.additionalNotes = "";
    
            /**
             * INFBVTaskOutputPlanInfo benefitsTier.
             * @member {google.protobuf.IDoubleValue|null|undefined} benefitsTier
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.benefitsTier = null;
    
            /**
             * INFBVTaskOutputPlanInfo claimsResponsibleOrgName.
             * @member {string} claimsResponsibleOrgName
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.claimsResponsibleOrgName = "";
    
            /**
             * INFBVTaskOutputPlanInfo cobraGracePeriod.
             * @member {string} cobraGracePeriod
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.cobraGracePeriod = "";
    
            /**
             * INFBVTaskOutputPlanInfo cobraHasGracePeriod.
             * @member {google.protobuf.IBoolValue|null|undefined} cobraHasGracePeriod
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.cobraHasGracePeriod = null;
    
            /**
             * INFBVTaskOutputPlanInfo cobraPaidThroughDate.
             * @member {string} cobraPaidThroughDate
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.cobraPaidThroughDate = "";
    
            /**
             * INFBVTaskOutputPlanInfo coordinationOfBenefits.
             * @member {infinitusapi.INFCoordinationOfBenefits} coordinationOfBenefits
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.coordinationOfBenefits = 0;
    
            /**
             * INFBVTaskOutputPlanInfo currentPlanPriority.
             * @member {infinitusapi.INFCurrentPlanPriority} currentPlanPriority
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.currentPlanPriority = 0;
    
            /**
             * INFBVTaskOutputPlanInfo followsMedicareGuidelines.
             * @member {google.protobuf.IBoolValue|null|undefined} followsMedicareGuidelines
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.followsMedicareGuidelines = null;
    
            /**
             * INFBVTaskOutputPlanInfo fundingType.
             * @member {infinitusapi.INFFundingType} fundingType
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.fundingType = 0;
    
            /**
             * INFBVTaskOutputPlanInfo hasStandardPlanLetter.
             * @member {google.protobuf.IBoolValue|null|undefined} hasStandardPlanLetter
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.hasStandardPlanLetter = null;
    
            /**
             * INFBVTaskOutputPlanInfo healthExchangeGracePeriod.
             * @member {string} healthExchangeGracePeriod
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.healthExchangeGracePeriod = "";
    
            /**
             * INFBVTaskOutputPlanInfo healthExchangeHasGracePeriod.
             * @member {google.protobuf.IBoolValue|null|undefined} healthExchangeHasGracePeriod
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.healthExchangeHasGracePeriod = null;
    
            /**
             * INFBVTaskOutputPlanInfo healthExchangePaidThroughDate.
             * @member {string} healthExchangePaidThroughDate
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.healthExchangePaidThroughDate = "";
    
            /**
             * INFBVTaskOutputPlanInfo inNetworkConsideration.
             * @member {string} inNetworkConsideration
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.inNetworkConsideration = "";
    
            /**
             * INFBVTaskOutputPlanInfo isCobraPlan.
             * @member {google.protobuf.IBoolValue|null|undefined} isCobraPlan
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.isCobraPlan = null;
    
            /**
             * INFBVTaskOutputPlanInfo isHealthExchangePlan.
             * @member {google.protobuf.IBoolValue|null|undefined} isHealthExchangePlan
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.isHealthExchangePlan = null;
    
            /**
             * INFBVTaskOutputPlanInfo newPlanAvailable.
             * @member {google.protobuf.IBoolValue|null|undefined} newPlanAvailable
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.newPlanAvailable = null;
    
            /**
             * INFBVTaskOutputPlanInfo newPlanEffectiveDate.
             * @member {string} newPlanEffectiveDate
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.newPlanEffectiveDate = "";
    
            /**
             * INFBVTaskOutputPlanInfo newPlanSubscriberId.
             * @member {string} newPlanSubscriberId
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.newPlanSubscriberId = "";
    
            /**
             * INFBVTaskOutputPlanInfo otherInsuranceExists.
             * @member {google.protobuf.IBoolValue|null|undefined} otherInsuranceExists
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.otherInsuranceExists = null;
    
            /**
             * INFBVTaskOutputPlanInfo otherInsuranceName.
             * @member {string} otherInsuranceName
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.otherInsuranceName = "";
    
            /**
             * INFBVTaskOutputPlanInfo patientReceivesSubsidies.
             * @member {google.protobuf.IBoolValue|null|undefined} patientReceivesSubsidies
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.patientReceivesSubsidies = null;
    
            /**
             * INFBVTaskOutputPlanInfo pbmExists.
             * @member {google.protobuf.IBoolValue|null|undefined} pbmExists
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.pbmExists = null;
    
            /**
             * INFBVTaskOutputPlanInfo pbmName.
             * @member {string} pbmName
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.pbmName = "";
    
            /**
             * INFBVTaskOutputPlanInfo pbmPayerID.
             * @member {string} pbmPayerID
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.pbmPayerID = "";
    
            /**
             * INFBVTaskOutputPlanInfo pbmPhone.
             * @member {string} pbmPhone
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.pbmPhone = "";
    
            /**
             * INFBVTaskOutputPlanInfo pbmPolicyID.
             * @member {string} pbmPolicyID
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.pbmPolicyID = "";
    
            /**
             * INFBVTaskOutputPlanInfo planCode.
             * @member {string} planCode
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.planCode = "";
    
            /**
             * INFBVTaskOutputPlanInfo planEffectiveDate.
             * @member {string} planEffectiveDate
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.planEffectiveDate = "";
    
            /**
             * INFBVTaskOutputPlanInfo planGroupName.
             * @member {string} planGroupName
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.planGroupName = "";
    
            /**
             * INFBVTaskOutputPlanInfo planGroupNumber.
             * @member {string} planGroupNumber
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.planGroupNumber = "";
    
            /**
             * INFBVTaskOutputPlanInfo planName.
             * @member {string} planName
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.planName = "";
    
            /**
             * INFBVTaskOutputPlanInfo planRenewalMonth.
             * @member {infinitusapi.INFPlanRenewalMonth} planRenewalMonth
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.planRenewalMonth = 0;
    
            /**
             * INFBVTaskOutputPlanInfo planTerminationDate.
             * @member {string} planTerminationDate
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.planTerminationDate = "";
    
            /**
             * INFBVTaskOutputPlanInfo planType.
             * @member {infinitusapi.INFPlanType} planType
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.planType = 0;
    
            /**
             * INFBVTaskOutputPlanInfo policyHolderDateOfBirth.
             * @member {string} policyHolderDateOfBirth
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.policyHolderDateOfBirth = "";
    
            /**
             * INFBVTaskOutputPlanInfo policyHolderFirstName.
             * @member {string} policyHolderFirstName
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.policyHolderFirstName = "";
    
            /**
             * INFBVTaskOutputPlanInfo policyHolderLastName.
             * @member {string} policyHolderLastName
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.policyHolderLastName = "";
    
            /**
             * INFBVTaskOutputPlanInfo policyHolderRelationship.
             * @member {infinitusapi.INFPolicyHolderRelationship} policyHolderRelationship
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.policyHolderRelationship = 0;
    
            /**
             * INFBVTaskOutputPlanInfo policyType.
             * @member {infinitusapi.INFPolicyType} policyType
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.policyType = 0;
    
            /**
             * INFBVTaskOutputPlanInfo practiceNetworkStatus.
             * @member {infinitusapi.INFPracticeNetworkStatus} practiceNetworkStatus
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.practiceNetworkStatus = 0;
    
            /**
             * INFBVTaskOutputPlanInfo providerInNetworkAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} providerInNetworkAddress
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.providerInNetworkAddress = null;
    
            /**
             * INFBVTaskOutputPlanInfo providerNetworkStatus.
             * @member {infinitusapi.INFProviderNetworkStatus} providerNetworkStatus
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.providerNetworkStatus = 0;
    
            /**
             * INFBVTaskOutputPlanInfo providerNetworkStatusOnly.
             * @member {infinitusapi.INFProviderNetworkStatusOnly} providerNetworkStatusOnly
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.providerNetworkStatusOnly = 0;
    
            /**
             * INFBVTaskOutputPlanInfo renewalType.
             * @member {infinitusapi.INFPlanRenewalType} renewalType
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.renewalType = 0;
    
            /**
             * INFBVTaskOutputPlanInfo sitesOfCare.
             * @member {Array.<infinitusapi.IINFBVTaskOutputPlanInfoSiteOfCare>} sitesOfCare
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.sitesOfCare = $util.emptyArray;
    
            /**
             * INFBVTaskOutputPlanInfo standardPlanLetter.
             * @member {infinitusapi.INFStandardPlanLetter} standardPlanLetter
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.standardPlanLetter = 0;
    
            /**
             * INFBVTaskOutputPlanInfo willCoverIfPrimaryDenies.
             * @member {google.protobuf.IBoolValue|null|undefined} willCoverIfPrimaryDenies
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.willCoverIfPrimaryDenies = null;
    
            /**
             * INFBVTaskOutputPlanInfo willCoverPartBDeductible.
             * @member {google.protobuf.IBoolValue|null|undefined} willCoverPartBDeductible
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             */
            INFBVTaskOutputPlanInfo.prototype.willCoverPartBDeductible = null;
    
            /**
             * Creates a new INFBVTaskOutputPlanInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPlanInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputPlanInfo} INFBVTaskOutputPlanInfo instance
             */
            INFBVTaskOutputPlanInfo.create = function create(properties) {
                return new INFBVTaskOutputPlanInfo(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputPlanInfo message. Does not implicitly {@link infinitusapi.INFBVTaskOutputPlanInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPlanInfo} message INFBVTaskOutputPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputPlanInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.benefitsTier != null && Object.hasOwnProperty.call(message, "benefitsTier"))
                    $root.google.protobuf.DoubleValue.encode(message.benefitsTier, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.claimsResponsibleOrgName != null && Object.hasOwnProperty.call(message, "claimsResponsibleOrgName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.claimsResponsibleOrgName);
                if (message.cobraGracePeriod != null && Object.hasOwnProperty.call(message, "cobraGracePeriod"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.cobraGracePeriod);
                if (message.cobraHasGracePeriod != null && Object.hasOwnProperty.call(message, "cobraHasGracePeriod"))
                    $root.google.protobuf.BoolValue.encode(message.cobraHasGracePeriod, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.cobraPaidThroughDate != null && Object.hasOwnProperty.call(message, "cobraPaidThroughDate"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.cobraPaidThroughDate);
                if (message.coordinationOfBenefits != null && Object.hasOwnProperty.call(message, "coordinationOfBenefits"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.coordinationOfBenefits);
                if (message.currentPlanPriority != null && Object.hasOwnProperty.call(message, "currentPlanPriority"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.currentPlanPriority);
                if (message.followsMedicareGuidelines != null && Object.hasOwnProperty.call(message, "followsMedicareGuidelines"))
                    $root.google.protobuf.BoolValue.encode(message.followsMedicareGuidelines, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.fundingType != null && Object.hasOwnProperty.call(message, "fundingType"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.fundingType);
                if (message.hasStandardPlanLetter != null && Object.hasOwnProperty.call(message, "hasStandardPlanLetter"))
                    $root.google.protobuf.BoolValue.encode(message.hasStandardPlanLetter, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.healthExchangeGracePeriod != null && Object.hasOwnProperty.call(message, "healthExchangeGracePeriod"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.healthExchangeGracePeriod);
                if (message.healthExchangeHasGracePeriod != null && Object.hasOwnProperty.call(message, "healthExchangeHasGracePeriod"))
                    $root.google.protobuf.BoolValue.encode(message.healthExchangeHasGracePeriod, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.healthExchangePaidThroughDate != null && Object.hasOwnProperty.call(message, "healthExchangePaidThroughDate"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.healthExchangePaidThroughDate);
                if (message.inNetworkConsideration != null && Object.hasOwnProperty.call(message, "inNetworkConsideration"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.inNetworkConsideration);
                if (message.isCobraPlan != null && Object.hasOwnProperty.call(message, "isCobraPlan"))
                    $root.google.protobuf.BoolValue.encode(message.isCobraPlan, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.isHealthExchangePlan != null && Object.hasOwnProperty.call(message, "isHealthExchangePlan"))
                    $root.google.protobuf.BoolValue.encode(message.isHealthExchangePlan, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.newPlanAvailable != null && Object.hasOwnProperty.call(message, "newPlanAvailable"))
                    $root.google.protobuf.BoolValue.encode(message.newPlanAvailable, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.newPlanEffectiveDate != null && Object.hasOwnProperty.call(message, "newPlanEffectiveDate"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.newPlanEffectiveDate);
                if (message.newPlanSubscriberId != null && Object.hasOwnProperty.call(message, "newPlanSubscriberId"))
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.newPlanSubscriberId);
                if (message.otherInsuranceExists != null && Object.hasOwnProperty.call(message, "otherInsuranceExists"))
                    $root.google.protobuf.BoolValue.encode(message.otherInsuranceExists, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.otherInsuranceName != null && Object.hasOwnProperty.call(message, "otherInsuranceName"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.otherInsuranceName);
                if (message.patientReceivesSubsidies != null && Object.hasOwnProperty.call(message, "patientReceivesSubsidies"))
                    $root.google.protobuf.BoolValue.encode(message.patientReceivesSubsidies, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.pbmExists != null && Object.hasOwnProperty.call(message, "pbmExists"))
                    $root.google.protobuf.BoolValue.encode(message.pbmExists, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.pbmName != null && Object.hasOwnProperty.call(message, "pbmName"))
                    writer.uint32(/* id 25, wireType 2 =*/202).string(message.pbmName);
                if (message.pbmPayerID != null && Object.hasOwnProperty.call(message, "pbmPayerID"))
                    writer.uint32(/* id 26, wireType 2 =*/210).string(message.pbmPayerID);
                if (message.pbmPhone != null && Object.hasOwnProperty.call(message, "pbmPhone"))
                    writer.uint32(/* id 27, wireType 2 =*/218).string(message.pbmPhone);
                if (message.pbmPolicyID != null && Object.hasOwnProperty.call(message, "pbmPolicyID"))
                    writer.uint32(/* id 28, wireType 2 =*/226).string(message.pbmPolicyID);
                if (message.planCode != null && Object.hasOwnProperty.call(message, "planCode"))
                    writer.uint32(/* id 29, wireType 2 =*/234).string(message.planCode);
                if (message.planEffectiveDate != null && Object.hasOwnProperty.call(message, "planEffectiveDate"))
                    writer.uint32(/* id 30, wireType 2 =*/242).string(message.planEffectiveDate);
                if (message.planGroupName != null && Object.hasOwnProperty.call(message, "planGroupName"))
                    writer.uint32(/* id 31, wireType 2 =*/250).string(message.planGroupName);
                if (message.planGroupNumber != null && Object.hasOwnProperty.call(message, "planGroupNumber"))
                    writer.uint32(/* id 32, wireType 2 =*/258).string(message.planGroupNumber);
                if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                    writer.uint32(/* id 33, wireType 2 =*/266).string(message.planName);
                if (message.planRenewalMonth != null && Object.hasOwnProperty.call(message, "planRenewalMonth"))
                    writer.uint32(/* id 34, wireType 0 =*/272).int32(message.planRenewalMonth);
                if (message.planTerminationDate != null && Object.hasOwnProperty.call(message, "planTerminationDate"))
                    writer.uint32(/* id 35, wireType 2 =*/282).string(message.planTerminationDate);
                if (message.planType != null && Object.hasOwnProperty.call(message, "planType"))
                    writer.uint32(/* id 36, wireType 0 =*/288).int32(message.planType);
                if (message.policyHolderDateOfBirth != null && Object.hasOwnProperty.call(message, "policyHolderDateOfBirth"))
                    writer.uint32(/* id 37, wireType 2 =*/298).string(message.policyHolderDateOfBirth);
                if (message.policyHolderFirstName != null && Object.hasOwnProperty.call(message, "policyHolderFirstName"))
                    writer.uint32(/* id 38, wireType 2 =*/306).string(message.policyHolderFirstName);
                if (message.policyHolderLastName != null && Object.hasOwnProperty.call(message, "policyHolderLastName"))
                    writer.uint32(/* id 39, wireType 2 =*/314).string(message.policyHolderLastName);
                if (message.policyHolderRelationship != null && Object.hasOwnProperty.call(message, "policyHolderRelationship"))
                    writer.uint32(/* id 40, wireType 0 =*/320).int32(message.policyHolderRelationship);
                if (message.policyType != null && Object.hasOwnProperty.call(message, "policyType"))
                    writer.uint32(/* id 41, wireType 0 =*/328).int32(message.policyType);
                if (message.practiceNetworkStatus != null && Object.hasOwnProperty.call(message, "practiceNetworkStatus"))
                    writer.uint32(/* id 42, wireType 0 =*/336).int32(message.practiceNetworkStatus);
                if (message.providerInNetworkAddress != null && Object.hasOwnProperty.call(message, "providerInNetworkAddress"))
                    $root.infinitusapi.INFAddress.encode(message.providerInNetworkAddress, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                if (message.providerNetworkStatus != null && Object.hasOwnProperty.call(message, "providerNetworkStatus"))
                    writer.uint32(/* id 44, wireType 0 =*/352).int32(message.providerNetworkStatus);
                if (message.providerNetworkStatusOnly != null && Object.hasOwnProperty.call(message, "providerNetworkStatusOnly"))
                    writer.uint32(/* id 45, wireType 0 =*/360).int32(message.providerNetworkStatusOnly);
                if (message.renewalType != null && Object.hasOwnProperty.call(message, "renewalType"))
                    writer.uint32(/* id 46, wireType 0 =*/368).int32(message.renewalType);
                if (message.sitesOfCare != null && message.sitesOfCare.length)
                    for (var i = 0; i < message.sitesOfCare.length; ++i)
                        $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare.encode(message.sitesOfCare[i], writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                if (message.standardPlanLetter != null && Object.hasOwnProperty.call(message, "standardPlanLetter"))
                    writer.uint32(/* id 48, wireType 0 =*/384).int32(message.standardPlanLetter);
                if (message.willCoverIfPrimaryDenies != null && Object.hasOwnProperty.call(message, "willCoverIfPrimaryDenies"))
                    $root.google.protobuf.BoolValue.encode(message.willCoverIfPrimaryDenies, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                if (message.willCoverPartBDeductible != null && Object.hasOwnProperty.call(message, "willCoverPartBDeductible"))
                    $root.google.protobuf.BoolValue.encode(message.willCoverPartBDeductible, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputPlanInfo message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputPlanInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPlanInfo} message INFBVTaskOutputPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputPlanInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputPlanInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputPlanInfo} INFBVTaskOutputPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputPlanInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputPlanInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.benefitsTier = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.claimsResponsibleOrgName = reader.string();
                            break;
                        }
                    case 4: {
                            message.cobraGracePeriod = reader.string();
                            break;
                        }
                    case 5: {
                            message.cobraHasGracePeriod = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.cobraPaidThroughDate = reader.string();
                            break;
                        }
                    case 7: {
                            message.coordinationOfBenefits = reader.int32();
                            break;
                        }
                    case 8: {
                            message.currentPlanPriority = reader.int32();
                            break;
                        }
                    case 9: {
                            message.followsMedicareGuidelines = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.fundingType = reader.int32();
                            break;
                        }
                    case 11: {
                            message.hasStandardPlanLetter = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.healthExchangeGracePeriod = reader.string();
                            break;
                        }
                    case 13: {
                            message.healthExchangeHasGracePeriod = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.healthExchangePaidThroughDate = reader.string();
                            break;
                        }
                    case 15: {
                            message.inNetworkConsideration = reader.string();
                            break;
                        }
                    case 16: {
                            message.isCobraPlan = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.isHealthExchangePlan = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.newPlanAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 19: {
                            message.newPlanEffectiveDate = reader.string();
                            break;
                        }
                    case 20: {
                            message.newPlanSubscriberId = reader.string();
                            break;
                        }
                    case 21: {
                            message.otherInsuranceExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 22: {
                            message.otherInsuranceName = reader.string();
                            break;
                        }
                    case 23: {
                            message.patientReceivesSubsidies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 24: {
                            message.pbmExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 25: {
                            message.pbmName = reader.string();
                            break;
                        }
                    case 26: {
                            message.pbmPayerID = reader.string();
                            break;
                        }
                    case 27: {
                            message.pbmPhone = reader.string();
                            break;
                        }
                    case 28: {
                            message.pbmPolicyID = reader.string();
                            break;
                        }
                    case 29: {
                            message.planCode = reader.string();
                            break;
                        }
                    case 30: {
                            message.planEffectiveDate = reader.string();
                            break;
                        }
                    case 31: {
                            message.planGroupName = reader.string();
                            break;
                        }
                    case 32: {
                            message.planGroupNumber = reader.string();
                            break;
                        }
                    case 33: {
                            message.planName = reader.string();
                            break;
                        }
                    case 34: {
                            message.planRenewalMonth = reader.int32();
                            break;
                        }
                    case 35: {
                            message.planTerminationDate = reader.string();
                            break;
                        }
                    case 36: {
                            message.planType = reader.int32();
                            break;
                        }
                    case 37: {
                            message.policyHolderDateOfBirth = reader.string();
                            break;
                        }
                    case 38: {
                            message.policyHolderFirstName = reader.string();
                            break;
                        }
                    case 39: {
                            message.policyHolderLastName = reader.string();
                            break;
                        }
                    case 40: {
                            message.policyHolderRelationship = reader.int32();
                            break;
                        }
                    case 41: {
                            message.policyType = reader.int32();
                            break;
                        }
                    case 42: {
                            message.practiceNetworkStatus = reader.int32();
                            break;
                        }
                    case 43: {
                            message.providerInNetworkAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 44: {
                            message.providerNetworkStatus = reader.int32();
                            break;
                        }
                    case 45: {
                            message.providerNetworkStatusOnly = reader.int32();
                            break;
                        }
                    case 46: {
                            message.renewalType = reader.int32();
                            break;
                        }
                    case 47: {
                            if (!(message.sitesOfCare && message.sitesOfCare.length))
                                message.sitesOfCare = [];
                            message.sitesOfCare.push($root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare.decode(reader, reader.uint32()));
                            break;
                        }
                    case 48: {
                            message.standardPlanLetter = reader.int32();
                            break;
                        }
                    case 49: {
                            message.willCoverIfPrimaryDenies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 50: {
                            message.willCoverPartBDeductible = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputPlanInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputPlanInfo} INFBVTaskOutputPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputPlanInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputPlanInfo message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputPlanInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.benefitsTier != null && message.hasOwnProperty("benefitsTier")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.benefitsTier);
                    if (error)
                        return "benefitsTier." + error;
                }
                if (message.claimsResponsibleOrgName != null && message.hasOwnProperty("claimsResponsibleOrgName"))
                    if (!$util.isString(message.claimsResponsibleOrgName))
                        return "claimsResponsibleOrgName: string expected";
                if (message.cobraGracePeriod != null && message.hasOwnProperty("cobraGracePeriod"))
                    if (!$util.isString(message.cobraGracePeriod))
                        return "cobraGracePeriod: string expected";
                if (message.cobraHasGracePeriod != null && message.hasOwnProperty("cobraHasGracePeriod")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.cobraHasGracePeriod);
                    if (error)
                        return "cobraHasGracePeriod." + error;
                }
                if (message.cobraPaidThroughDate != null && message.hasOwnProperty("cobraPaidThroughDate"))
                    if (!$util.isString(message.cobraPaidThroughDate))
                        return "cobraPaidThroughDate: string expected";
                if (message.coordinationOfBenefits != null && message.hasOwnProperty("coordinationOfBenefits"))
                    switch (message.coordinationOfBenefits) {
                    default:
                        return "coordinationOfBenefits: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.currentPlanPriority != null && message.hasOwnProperty("currentPlanPriority"))
                    switch (message.currentPlanPriority) {
                    default:
                        return "currentPlanPriority: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.followsMedicareGuidelines != null && message.hasOwnProperty("followsMedicareGuidelines")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.followsMedicareGuidelines);
                    if (error)
                        return "followsMedicareGuidelines." + error;
                }
                if (message.fundingType != null && message.hasOwnProperty("fundingType"))
                    switch (message.fundingType) {
                    default:
                        return "fundingType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.hasStandardPlanLetter != null && message.hasOwnProperty("hasStandardPlanLetter")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.hasStandardPlanLetter);
                    if (error)
                        return "hasStandardPlanLetter." + error;
                }
                if (message.healthExchangeGracePeriod != null && message.hasOwnProperty("healthExchangeGracePeriod"))
                    if (!$util.isString(message.healthExchangeGracePeriod))
                        return "healthExchangeGracePeriod: string expected";
                if (message.healthExchangeHasGracePeriod != null && message.hasOwnProperty("healthExchangeHasGracePeriod")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.healthExchangeHasGracePeriod);
                    if (error)
                        return "healthExchangeHasGracePeriod." + error;
                }
                if (message.healthExchangePaidThroughDate != null && message.hasOwnProperty("healthExchangePaidThroughDate"))
                    if (!$util.isString(message.healthExchangePaidThroughDate))
                        return "healthExchangePaidThroughDate: string expected";
                if (message.inNetworkConsideration != null && message.hasOwnProperty("inNetworkConsideration"))
                    if (!$util.isString(message.inNetworkConsideration))
                        return "inNetworkConsideration: string expected";
                if (message.isCobraPlan != null && message.hasOwnProperty("isCobraPlan")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isCobraPlan);
                    if (error)
                        return "isCobraPlan." + error;
                }
                if (message.isHealthExchangePlan != null && message.hasOwnProperty("isHealthExchangePlan")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isHealthExchangePlan);
                    if (error)
                        return "isHealthExchangePlan." + error;
                }
                if (message.newPlanAvailable != null && message.hasOwnProperty("newPlanAvailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.newPlanAvailable);
                    if (error)
                        return "newPlanAvailable." + error;
                }
                if (message.newPlanEffectiveDate != null && message.hasOwnProperty("newPlanEffectiveDate"))
                    if (!$util.isString(message.newPlanEffectiveDate))
                        return "newPlanEffectiveDate: string expected";
                if (message.newPlanSubscriberId != null && message.hasOwnProperty("newPlanSubscriberId"))
                    if (!$util.isString(message.newPlanSubscriberId))
                        return "newPlanSubscriberId: string expected";
                if (message.otherInsuranceExists != null && message.hasOwnProperty("otherInsuranceExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.otherInsuranceExists);
                    if (error)
                        return "otherInsuranceExists." + error;
                }
                if (message.otherInsuranceName != null && message.hasOwnProperty("otherInsuranceName"))
                    if (!$util.isString(message.otherInsuranceName))
                        return "otherInsuranceName: string expected";
                if (message.patientReceivesSubsidies != null && message.hasOwnProperty("patientReceivesSubsidies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.patientReceivesSubsidies);
                    if (error)
                        return "patientReceivesSubsidies." + error;
                }
                if (message.pbmExists != null && message.hasOwnProperty("pbmExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.pbmExists);
                    if (error)
                        return "pbmExists." + error;
                }
                if (message.pbmName != null && message.hasOwnProperty("pbmName"))
                    if (!$util.isString(message.pbmName))
                        return "pbmName: string expected";
                if (message.pbmPayerID != null && message.hasOwnProperty("pbmPayerID"))
                    if (!$util.isString(message.pbmPayerID))
                        return "pbmPayerID: string expected";
                if (message.pbmPhone != null && message.hasOwnProperty("pbmPhone"))
                    if (!$util.isString(message.pbmPhone))
                        return "pbmPhone: string expected";
                if (message.pbmPolicyID != null && message.hasOwnProperty("pbmPolicyID"))
                    if (!$util.isString(message.pbmPolicyID))
                        return "pbmPolicyID: string expected";
                if (message.planCode != null && message.hasOwnProperty("planCode"))
                    if (!$util.isString(message.planCode))
                        return "planCode: string expected";
                if (message.planEffectiveDate != null && message.hasOwnProperty("planEffectiveDate"))
                    if (!$util.isString(message.planEffectiveDate))
                        return "planEffectiveDate: string expected";
                if (message.planGroupName != null && message.hasOwnProperty("planGroupName"))
                    if (!$util.isString(message.planGroupName))
                        return "planGroupName: string expected";
                if (message.planGroupNumber != null && message.hasOwnProperty("planGroupNumber"))
                    if (!$util.isString(message.planGroupNumber))
                        return "planGroupNumber: string expected";
                if (message.planName != null && message.hasOwnProperty("planName"))
                    if (!$util.isString(message.planName))
                        return "planName: string expected";
                if (message.planRenewalMonth != null && message.hasOwnProperty("planRenewalMonth"))
                    switch (message.planRenewalMonth) {
                    default:
                        return "planRenewalMonth: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.planTerminationDate != null && message.hasOwnProperty("planTerminationDate"))
                    if (!$util.isString(message.planTerminationDate))
                        return "planTerminationDate: string expected";
                if (message.planType != null && message.hasOwnProperty("planType"))
                    switch (message.planType) {
                    default:
                        return "planType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                if (message.policyHolderDateOfBirth != null && message.hasOwnProperty("policyHolderDateOfBirth"))
                    if (!$util.isString(message.policyHolderDateOfBirth))
                        return "policyHolderDateOfBirth: string expected";
                if (message.policyHolderFirstName != null && message.hasOwnProperty("policyHolderFirstName"))
                    if (!$util.isString(message.policyHolderFirstName))
                        return "policyHolderFirstName: string expected";
                if (message.policyHolderLastName != null && message.hasOwnProperty("policyHolderLastName"))
                    if (!$util.isString(message.policyHolderLastName))
                        return "policyHolderLastName: string expected";
                if (message.policyHolderRelationship != null && message.hasOwnProperty("policyHolderRelationship"))
                    switch (message.policyHolderRelationship) {
                    default:
                        return "policyHolderRelationship: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.policyType != null && message.hasOwnProperty("policyType"))
                    switch (message.policyType) {
                    default:
                        return "policyType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                        break;
                    }
                if (message.practiceNetworkStatus != null && message.hasOwnProperty("practiceNetworkStatus"))
                    switch (message.practiceNetworkStatus) {
                    default:
                        return "practiceNetworkStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.providerInNetworkAddress != null && message.hasOwnProperty("providerInNetworkAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.providerInNetworkAddress);
                    if (error)
                        return "providerInNetworkAddress." + error;
                }
                if (message.providerNetworkStatus != null && message.hasOwnProperty("providerNetworkStatus"))
                    switch (message.providerNetworkStatus) {
                    default:
                        return "providerNetworkStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.providerNetworkStatusOnly != null && message.hasOwnProperty("providerNetworkStatusOnly"))
                    switch (message.providerNetworkStatusOnly) {
                    default:
                        return "providerNetworkStatusOnly: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.renewalType != null && message.hasOwnProperty("renewalType"))
                    switch (message.renewalType) {
                    default:
                        return "renewalType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.sitesOfCare != null && message.hasOwnProperty("sitesOfCare")) {
                    if (!Array.isArray(message.sitesOfCare))
                        return "sitesOfCare: array expected";
                    for (var i = 0; i < message.sitesOfCare.length; ++i) {
                        var error = $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare.verify(message.sitesOfCare[i]);
                        if (error)
                            return "sitesOfCare." + error;
                    }
                }
                if (message.standardPlanLetter != null && message.hasOwnProperty("standardPlanLetter"))
                    switch (message.standardPlanLetter) {
                    default:
                        return "standardPlanLetter: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                        break;
                    }
                if (message.willCoverIfPrimaryDenies != null && message.hasOwnProperty("willCoverIfPrimaryDenies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.willCoverIfPrimaryDenies);
                    if (error)
                        return "willCoverIfPrimaryDenies." + error;
                }
                if (message.willCoverPartBDeductible != null && message.hasOwnProperty("willCoverPartBDeductible")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.willCoverPartBDeductible);
                    if (error)
                        return "willCoverPartBDeductible." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputPlanInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputPlanInfo} INFBVTaskOutputPlanInfo
             */
            INFBVTaskOutputPlanInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputPlanInfo)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputPlanInfo();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.benefitsTier != null) {
                    if (typeof object.benefitsTier !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.benefitsTier: object expected");
                    message.benefitsTier = $root.google.protobuf.DoubleValue.fromObject(object.benefitsTier);
                }
                if (object.claimsResponsibleOrgName != null)
                    message.claimsResponsibleOrgName = String(object.claimsResponsibleOrgName);
                if (object.cobraGracePeriod != null)
                    message.cobraGracePeriod = String(object.cobraGracePeriod);
                if (object.cobraHasGracePeriod != null) {
                    if (typeof object.cobraHasGracePeriod !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.cobraHasGracePeriod: object expected");
                    message.cobraHasGracePeriod = $root.google.protobuf.BoolValue.fromObject(object.cobraHasGracePeriod);
                }
                if (object.cobraPaidThroughDate != null)
                    message.cobraPaidThroughDate = String(object.cobraPaidThroughDate);
                switch (object.coordinationOfBenefits) {
                default:
                    if (typeof object.coordinationOfBenefits === "number") {
                        message.coordinationOfBenefits = object.coordinationOfBenefits;
                        break;
                    }
                    break;
                case "INF_COORDINATION_OF_BENEFITS_UNKNOWN":
                case 0:
                    message.coordinationOfBenefits = 0;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_NOT_APPLICABLE":
                case 1:
                    message.coordinationOfBenefits = 1;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_SUPPLEMENT":
                case 2:
                    message.coordinationOfBenefits = 2;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_STANDARD":
                case 3:
                    message.coordinationOfBenefits = 3;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_NON_DUPLICATION":
                case 4:
                    message.coordinationOfBenefits = 4;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_COME_OUT_WHOLE":
                case 5:
                    message.coordinationOfBenefits = 5;
                    break;
                }
                switch (object.currentPlanPriority) {
                default:
                    if (typeof object.currentPlanPriority === "number") {
                        message.currentPlanPriority = object.currentPlanPriority;
                        break;
                    }
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_UNKNOWN":
                case 0:
                    message.currentPlanPriority = 0;
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_NOT_APPLICABLE":
                case 1:
                    message.currentPlanPriority = 1;
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_PRIMARY":
                case 2:
                    message.currentPlanPriority = 2;
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_SECONDARY":
                case 3:
                    message.currentPlanPriority = 3;
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_TERTIARY":
                case 4:
                    message.currentPlanPriority = 4;
                    break;
                }
                if (object.followsMedicareGuidelines != null) {
                    if (typeof object.followsMedicareGuidelines !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.followsMedicareGuidelines: object expected");
                    message.followsMedicareGuidelines = $root.google.protobuf.BoolValue.fromObject(object.followsMedicareGuidelines);
                }
                switch (object.fundingType) {
                default:
                    if (typeof object.fundingType === "number") {
                        message.fundingType = object.fundingType;
                        break;
                    }
                    break;
                case "INF_FUNDING_TYPE_UNKNOWN":
                case 0:
                    message.fundingType = 0;
                    break;
                case "INF_FUNDING_TYPE_NOT_APPLICABLE":
                case 1:
                    message.fundingType = 1;
                    break;
                case "INF_FUNDING_TYPE_SELF":
                case 2:
                    message.fundingType = 2;
                    break;
                case "INF_FUNDING_TYPE_FULLY":
                case 3:
                    message.fundingType = 3;
                    break;
                }
                if (object.hasStandardPlanLetter != null) {
                    if (typeof object.hasStandardPlanLetter !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.hasStandardPlanLetter: object expected");
                    message.hasStandardPlanLetter = $root.google.protobuf.BoolValue.fromObject(object.hasStandardPlanLetter);
                }
                if (object.healthExchangeGracePeriod != null)
                    message.healthExchangeGracePeriod = String(object.healthExchangeGracePeriod);
                if (object.healthExchangeHasGracePeriod != null) {
                    if (typeof object.healthExchangeHasGracePeriod !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.healthExchangeHasGracePeriod: object expected");
                    message.healthExchangeHasGracePeriod = $root.google.protobuf.BoolValue.fromObject(object.healthExchangeHasGracePeriod);
                }
                if (object.healthExchangePaidThroughDate != null)
                    message.healthExchangePaidThroughDate = String(object.healthExchangePaidThroughDate);
                if (object.inNetworkConsideration != null)
                    message.inNetworkConsideration = String(object.inNetworkConsideration);
                if (object.isCobraPlan != null) {
                    if (typeof object.isCobraPlan !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.isCobraPlan: object expected");
                    message.isCobraPlan = $root.google.protobuf.BoolValue.fromObject(object.isCobraPlan);
                }
                if (object.isHealthExchangePlan != null) {
                    if (typeof object.isHealthExchangePlan !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.isHealthExchangePlan: object expected");
                    message.isHealthExchangePlan = $root.google.protobuf.BoolValue.fromObject(object.isHealthExchangePlan);
                }
                if (object.newPlanAvailable != null) {
                    if (typeof object.newPlanAvailable !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.newPlanAvailable: object expected");
                    message.newPlanAvailable = $root.google.protobuf.BoolValue.fromObject(object.newPlanAvailable);
                }
                if (object.newPlanEffectiveDate != null)
                    message.newPlanEffectiveDate = String(object.newPlanEffectiveDate);
                if (object.newPlanSubscriberId != null)
                    message.newPlanSubscriberId = String(object.newPlanSubscriberId);
                if (object.otherInsuranceExists != null) {
                    if (typeof object.otherInsuranceExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.otherInsuranceExists: object expected");
                    message.otherInsuranceExists = $root.google.protobuf.BoolValue.fromObject(object.otherInsuranceExists);
                }
                if (object.otherInsuranceName != null)
                    message.otherInsuranceName = String(object.otherInsuranceName);
                if (object.patientReceivesSubsidies != null) {
                    if (typeof object.patientReceivesSubsidies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.patientReceivesSubsidies: object expected");
                    message.patientReceivesSubsidies = $root.google.protobuf.BoolValue.fromObject(object.patientReceivesSubsidies);
                }
                if (object.pbmExists != null) {
                    if (typeof object.pbmExists !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.pbmExists: object expected");
                    message.pbmExists = $root.google.protobuf.BoolValue.fromObject(object.pbmExists);
                }
                if (object.pbmName != null)
                    message.pbmName = String(object.pbmName);
                if (object.pbmPayerID != null)
                    message.pbmPayerID = String(object.pbmPayerID);
                if (object.pbmPhone != null)
                    message.pbmPhone = String(object.pbmPhone);
                if (object.pbmPolicyID != null)
                    message.pbmPolicyID = String(object.pbmPolicyID);
                if (object.planCode != null)
                    message.planCode = String(object.planCode);
                if (object.planEffectiveDate != null)
                    message.planEffectiveDate = String(object.planEffectiveDate);
                if (object.planGroupName != null)
                    message.planGroupName = String(object.planGroupName);
                if (object.planGroupNumber != null)
                    message.planGroupNumber = String(object.planGroupNumber);
                if (object.planName != null)
                    message.planName = String(object.planName);
                switch (object.planRenewalMonth) {
                default:
                    if (typeof object.planRenewalMonth === "number") {
                        message.planRenewalMonth = object.planRenewalMonth;
                        break;
                    }
                    break;
                case "INF_PLAN_RENEWAL_MONTH_UNKNOWN":
                case 0:
                    message.planRenewalMonth = 0;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_NOT_APPLICABLE":
                case 1:
                    message.planRenewalMonth = 1;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_JAN":
                case 2:
                    message.planRenewalMonth = 2;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_FEB":
                case 3:
                    message.planRenewalMonth = 3;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_MAR":
                case 4:
                    message.planRenewalMonth = 4;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_APR":
                case 5:
                    message.planRenewalMonth = 5;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_MAY":
                case 6:
                    message.planRenewalMonth = 6;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_JUN":
                case 7:
                    message.planRenewalMonth = 7;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_JUL":
                case 8:
                    message.planRenewalMonth = 8;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_AUG":
                case 9:
                    message.planRenewalMonth = 9;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_SEP":
                case 10:
                    message.planRenewalMonth = 10;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_OCT":
                case 11:
                    message.planRenewalMonth = 11;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_NOV":
                case 12:
                    message.planRenewalMonth = 12;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_DEC":
                case 13:
                    message.planRenewalMonth = 13;
                    break;
                }
                if (object.planTerminationDate != null)
                    message.planTerminationDate = String(object.planTerminationDate);
                switch (object.planType) {
                default:
                    if (typeof object.planType === "number") {
                        message.planType = object.planType;
                        break;
                    }
                    break;
                case "INF_PLAN_TYPE_UNKNOWN":
                case 0:
                    message.planType = 0;
                    break;
                case "INF_PLAN_TYPE_NOT_APPLICABLE":
                case 1:
                    message.planType = 1;
                    break;
                case "INF_PLAN_TYPE_EPO":
                case 2:
                    message.planType = 2;
                    break;
                case "INF_PLAN_TYPE_HMO":
                case 3:
                    message.planType = 3;
                    break;
                case "INF_PLAN_TYPE_INDEMNITY":
                case 4:
                    message.planType = 4;
                    break;
                case "INF_PLAN_TYPE_MEDICAID":
                case 5:
                    message.planType = 5;
                    break;
                case "INF_PLAN_TYPE_OAP":
                case 6:
                    message.planType = 6;
                    break;
                case "INF_PLAN_TYPE_POS":
                case 7:
                    message.planType = 7;
                    break;
                case "INF_PLAN_TYPE_PPO":
                case 8:
                    message.planType = 8;
                    break;
                case "INF_PLAN_TYPE_TRICARE":
                case 9:
                    message.planType = 9;
                    break;
                case "INF_PLAN_TYPE_MEDICARE_SUPPLEMENT":
                case 10:
                    message.planType = 10;
                    break;
                case "INF_PLAN_TYPE_HMO_POS":
                case 11:
                    message.planType = 11;
                    break;
                case "INF_PLAN_TYPE_HDHP":
                case 12:
                    message.planType = 12;
                    break;
                }
                if (object.policyHolderDateOfBirth != null)
                    message.policyHolderDateOfBirth = String(object.policyHolderDateOfBirth);
                if (object.policyHolderFirstName != null)
                    message.policyHolderFirstName = String(object.policyHolderFirstName);
                if (object.policyHolderLastName != null)
                    message.policyHolderLastName = String(object.policyHolderLastName);
                switch (object.policyHolderRelationship) {
                default:
                    if (typeof object.policyHolderRelationship === "number") {
                        message.policyHolderRelationship = object.policyHolderRelationship;
                        break;
                    }
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_UNKNOWN":
                case 0:
                    message.policyHolderRelationship = 0;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_SELF":
                case 1:
                    message.policyHolderRelationship = 1;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_CHILD":
                case 2:
                    message.policyHolderRelationship = 2;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_SPOUSE":
                case 3:
                    message.policyHolderRelationship = 3;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_OTHER":
                case 4:
                    message.policyHolderRelationship = 4;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_NOT_APPLICABLE":
                case 5:
                    message.policyHolderRelationship = 5;
                    break;
                }
                switch (object.policyType) {
                default:
                    if (typeof object.policyType === "number") {
                        message.policyType = object.policyType;
                        break;
                    }
                    break;
                case "INF_POLICY_TYPE_UNKNOWN":
                case 0:
                    message.policyType = 0;
                    break;
                case "INF_POLICY_TYPE_NOT_APPLICABLE":
                case 1:
                    message.policyType = 1;
                    break;
                case "INF_POLICY_TYPE_OTHER":
                case 2:
                    message.policyType = 2;
                    break;
                case "INF_POLICY_TYPE_COMMERCIAL":
                case 3:
                    message.policyType = 3;
                    break;
                case "INF_POLICY_TYPE_GOVERNMENT_MEDICARE":
                case 4:
                    message.policyType = 4;
                    break;
                case "INF_POLICY_TYPE_GOVERNMENT_MEDICAID":
                case 5:
                    message.policyType = 5;
                    break;
                case "INF_POLICY_TYPE_GOVERNMENT_TRICARE":
                case 6:
                    message.policyType = 6;
                    break;
                case "INF_POLICY_TYPE_FEDERAL_EMPLOYEE_PROGRAM":
                case 7:
                    message.policyType = 7;
                    break;
                case "INF_POLICY_TYPE_MANAGED_MEDICAID":
                case 8:
                    message.policyType = 8;
                    break;
                case "INF_POLICY_TYPE_MEDICARE_ADVANTAGE":
                case 9:
                    message.policyType = 9;
                    break;
                case "INF_POLICY_TYPE_MEDICARE_PDP":
                case 10:
                    message.policyType = 10;
                    break;
                }
                switch (object.practiceNetworkStatus) {
                default:
                    if (typeof object.practiceNetworkStatus === "number") {
                        message.practiceNetworkStatus = object.practiceNetworkStatus;
                        break;
                    }
                    break;
                case "INF_PRACTICE_NETWORK_STATUS_UNKNOWN":
                case 0:
                    message.practiceNetworkStatus = 0;
                    break;
                case "INF_PRACTICE_NETWORK_STATUS_NOT_APPLICABLE":
                case 1:
                    message.practiceNetworkStatus = 1;
                    break;
                case "INF_PRACTICE_NETWORK_STATUS_IN_NETWORK":
                case 2:
                    message.practiceNetworkStatus = 2;
                    break;
                case "INF_PRACTICE_NETWORK_STATUS_OUT_OF_NETWORK":
                case 3:
                    message.practiceNetworkStatus = 3;
                    break;
                }
                if (object.providerInNetworkAddress != null) {
                    if (typeof object.providerInNetworkAddress !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.providerInNetworkAddress: object expected");
                    message.providerInNetworkAddress = $root.infinitusapi.INFAddress.fromObject(object.providerInNetworkAddress);
                }
                switch (object.providerNetworkStatus) {
                default:
                    if (typeof object.providerNetworkStatus === "number") {
                        message.providerNetworkStatus = object.providerNetworkStatus;
                        break;
                    }
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_UNKNOWN":
                case 0:
                    message.providerNetworkStatus = 0;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_NOT_APPLICABLE":
                case 1:
                    message.providerNetworkStatus = 1;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_IN_NETWORK":
                case 2:
                    message.providerNetworkStatus = 2;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_OUT_OF_NETWORK":
                case 3:
                    message.providerNetworkStatus = 3;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_IN":
                case 4:
                    message.providerNetworkStatus = 4;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_IN":
                case 5:
                    message.providerNetworkStatus = 5;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_UNDETERMINED_BOTH_OUT":
                case 6:
                    message.providerNetworkStatus = 6;
                    break;
                }
                switch (object.providerNetworkStatusOnly) {
                default:
                    if (typeof object.providerNetworkStatusOnly === "number") {
                        message.providerNetworkStatusOnly = object.providerNetworkStatusOnly;
                        break;
                    }
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_ONLY_UNKNOWN":
                case 0:
                    message.providerNetworkStatusOnly = 0;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_ONLY_NOT_APPLICABLE":
                case 1:
                    message.providerNetworkStatusOnly = 1;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK":
                case 2:
                    message.providerNetworkStatusOnly = 2;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS":
                case 3:
                    message.providerNetworkStatusOnly = 3;
                    break;
                case "INF_PROVIDER_NETWORK_STATUS_ONLY_OUT_OF_NETWORK":
                case 4:
                    message.providerNetworkStatusOnly = 4;
                    break;
                }
                switch (object.renewalType) {
                default:
                    if (typeof object.renewalType === "number") {
                        message.renewalType = object.renewalType;
                        break;
                    }
                    break;
                case "INF_PLAN_RENEWAL_TYPE_UNKNOWN":
                case 0:
                    message.renewalType = 0;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_NOT_APPLICABLE":
                case 1:
                    message.renewalType = 1;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_CALENDAR_YEARLY":
                case 2:
                    message.renewalType = 2;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_CALENDAR_QUARTERLY":
                case 3:
                    message.renewalType = 3;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_CALENDAR_MONTHLY":
                case 4:
                    message.renewalType = 4;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_BENEFIT_YEARLY":
                case 5:
                    message.renewalType = 5;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_BENEFIT_QUARTERLY":
                case 6:
                    message.renewalType = 6;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_BENEFIT_MONTHLY":
                case 7:
                    message.renewalType = 7;
                    break;
                }
                if (object.sitesOfCare) {
                    if (!Array.isArray(object.sitesOfCare))
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.sitesOfCare: array expected");
                    message.sitesOfCare = [];
                    for (var i = 0; i < object.sitesOfCare.length; ++i) {
                        if (typeof object.sitesOfCare[i] !== "object")
                            throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.sitesOfCare: object expected");
                        message.sitesOfCare[i] = $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare.fromObject(object.sitesOfCare[i]);
                    }
                }
                switch (object.standardPlanLetter) {
                default:
                    if (typeof object.standardPlanLetter === "number") {
                        message.standardPlanLetter = object.standardPlanLetter;
                        break;
                    }
                    break;
                case "INF_UNKNOWN":
                case 0:
                    message.standardPlanLetter = 0;
                    break;
                case "INF_PLAN_LETTER_NOT_APPLICABLE":
                case 1:
                    message.standardPlanLetter = 1;
                    break;
                case "INF_A":
                case 2:
                    message.standardPlanLetter = 2;
                    break;
                case "INF_B":
                case 3:
                    message.standardPlanLetter = 3;
                    break;
                case "INF_C":
                case 4:
                    message.standardPlanLetter = 4;
                    break;
                case "INF_D":
                case 5:
                    message.standardPlanLetter = 5;
                    break;
                case "INF_E":
                case 6:
                    message.standardPlanLetter = 6;
                    break;
                case "INF_F":
                case 7:
                    message.standardPlanLetter = 7;
                    break;
                case "INF_G":
                case 8:
                    message.standardPlanLetter = 8;
                    break;
                case "INF_H":
                case 9:
                    message.standardPlanLetter = 9;
                    break;
                case "INF_I":
                case 10:
                    message.standardPlanLetter = 10;
                    break;
                case "INF_J":
                case 11:
                    message.standardPlanLetter = 11;
                    break;
                case "INF_K":
                case 12:
                    message.standardPlanLetter = 12;
                    break;
                case "INF_L":
                case 13:
                    message.standardPlanLetter = 13;
                    break;
                case "INF_M":
                case 14:
                    message.standardPlanLetter = 14;
                    break;
                case "INF_N":
                case 15:
                    message.standardPlanLetter = 15;
                    break;
                }
                if (object.willCoverIfPrimaryDenies != null) {
                    if (typeof object.willCoverIfPrimaryDenies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.willCoverIfPrimaryDenies: object expected");
                    message.willCoverIfPrimaryDenies = $root.google.protobuf.BoolValue.fromObject(object.willCoverIfPrimaryDenies);
                }
                if (object.willCoverPartBDeductible != null) {
                    if (typeof object.willCoverPartBDeductible !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPlanInfo.willCoverPartBDeductible: object expected");
                    message.willCoverPartBDeductible = $root.google.protobuf.BoolValue.fromObject(object.willCoverPartBDeductible);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputPlanInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.INFBVTaskOutputPlanInfo} message INFBVTaskOutputPlanInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputPlanInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.sitesOfCare = [];
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.benefitsTier = null;
                    object.claimsResponsibleOrgName = "";
                    object.cobraGracePeriod = "";
                    object.cobraHasGracePeriod = null;
                    object.cobraPaidThroughDate = "";
                    object.coordinationOfBenefits = options.enums === String ? "INF_COORDINATION_OF_BENEFITS_UNKNOWN" : 0;
                    object.currentPlanPriority = options.enums === String ? "INF_CURRENT_PLAN_PRIORITY_UNKNOWN" : 0;
                    object.followsMedicareGuidelines = null;
                    object.fundingType = options.enums === String ? "INF_FUNDING_TYPE_UNKNOWN" : 0;
                    object.hasStandardPlanLetter = null;
                    object.healthExchangeGracePeriod = "";
                    object.healthExchangeHasGracePeriod = null;
                    object.healthExchangePaidThroughDate = "";
                    object.inNetworkConsideration = "";
                    object.isCobraPlan = null;
                    object.isHealthExchangePlan = null;
                    object.newPlanAvailable = null;
                    object.newPlanEffectiveDate = "";
                    object.newPlanSubscriberId = "";
                    object.otherInsuranceExists = null;
                    object.otherInsuranceName = "";
                    object.patientReceivesSubsidies = null;
                    object.pbmExists = null;
                    object.pbmName = "";
                    object.pbmPayerID = "";
                    object.pbmPhone = "";
                    object.pbmPolicyID = "";
                    object.planCode = "";
                    object.planEffectiveDate = "";
                    object.planGroupName = "";
                    object.planGroupNumber = "";
                    object.planName = "";
                    object.planRenewalMonth = options.enums === String ? "INF_PLAN_RENEWAL_MONTH_UNKNOWN" : 0;
                    object.planTerminationDate = "";
                    object.planType = options.enums === String ? "INF_PLAN_TYPE_UNKNOWN" : 0;
                    object.policyHolderDateOfBirth = "";
                    object.policyHolderFirstName = "";
                    object.policyHolderLastName = "";
                    object.policyHolderRelationship = options.enums === String ? "INF_POLICY_HOLDER_RELATIONSHIP_UNKNOWN" : 0;
                    object.policyType = options.enums === String ? "INF_POLICY_TYPE_UNKNOWN" : 0;
                    object.practiceNetworkStatus = options.enums === String ? "INF_PRACTICE_NETWORK_STATUS_UNKNOWN" : 0;
                    object.providerInNetworkAddress = null;
                    object.providerNetworkStatus = options.enums === String ? "INF_PROVIDER_NETWORK_STATUS_UNKNOWN" : 0;
                    object.providerNetworkStatusOnly = options.enums === String ? "INF_PROVIDER_NETWORK_STATUS_ONLY_UNKNOWN" : 0;
                    object.renewalType = options.enums === String ? "INF_PLAN_RENEWAL_TYPE_UNKNOWN" : 0;
                    object.standardPlanLetter = options.enums === String ? "INF_UNKNOWN" : 0;
                    object.willCoverIfPrimaryDenies = null;
                    object.willCoverPartBDeductible = null;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.benefitsTier != null && message.hasOwnProperty("benefitsTier"))
                    object.benefitsTier = $root.google.protobuf.DoubleValue.toObject(message.benefitsTier, options);
                if (message.claimsResponsibleOrgName != null && message.hasOwnProperty("claimsResponsibleOrgName"))
                    object.claimsResponsibleOrgName = message.claimsResponsibleOrgName;
                if (message.cobraGracePeriod != null && message.hasOwnProperty("cobraGracePeriod"))
                    object.cobraGracePeriod = message.cobraGracePeriod;
                if (message.cobraHasGracePeriod != null && message.hasOwnProperty("cobraHasGracePeriod"))
                    object.cobraHasGracePeriod = $root.google.protobuf.BoolValue.toObject(message.cobraHasGracePeriod, options);
                if (message.cobraPaidThroughDate != null && message.hasOwnProperty("cobraPaidThroughDate"))
                    object.cobraPaidThroughDate = message.cobraPaidThroughDate;
                if (message.coordinationOfBenefits != null && message.hasOwnProperty("coordinationOfBenefits"))
                    object.coordinationOfBenefits = options.enums === String ? $root.infinitusapi.INFCoordinationOfBenefits[message.coordinationOfBenefits] === undefined ? message.coordinationOfBenefits : $root.infinitusapi.INFCoordinationOfBenefits[message.coordinationOfBenefits] : message.coordinationOfBenefits;
                if (message.currentPlanPriority != null && message.hasOwnProperty("currentPlanPriority"))
                    object.currentPlanPriority = options.enums === String ? $root.infinitusapi.INFCurrentPlanPriority[message.currentPlanPriority] === undefined ? message.currentPlanPriority : $root.infinitusapi.INFCurrentPlanPriority[message.currentPlanPriority] : message.currentPlanPriority;
                if (message.followsMedicareGuidelines != null && message.hasOwnProperty("followsMedicareGuidelines"))
                    object.followsMedicareGuidelines = $root.google.protobuf.BoolValue.toObject(message.followsMedicareGuidelines, options);
                if (message.fundingType != null && message.hasOwnProperty("fundingType"))
                    object.fundingType = options.enums === String ? $root.infinitusapi.INFFundingType[message.fundingType] === undefined ? message.fundingType : $root.infinitusapi.INFFundingType[message.fundingType] : message.fundingType;
                if (message.hasStandardPlanLetter != null && message.hasOwnProperty("hasStandardPlanLetter"))
                    object.hasStandardPlanLetter = $root.google.protobuf.BoolValue.toObject(message.hasStandardPlanLetter, options);
                if (message.healthExchangeGracePeriod != null && message.hasOwnProperty("healthExchangeGracePeriod"))
                    object.healthExchangeGracePeriod = message.healthExchangeGracePeriod;
                if (message.healthExchangeHasGracePeriod != null && message.hasOwnProperty("healthExchangeHasGracePeriod"))
                    object.healthExchangeHasGracePeriod = $root.google.protobuf.BoolValue.toObject(message.healthExchangeHasGracePeriod, options);
                if (message.healthExchangePaidThroughDate != null && message.hasOwnProperty("healthExchangePaidThroughDate"))
                    object.healthExchangePaidThroughDate = message.healthExchangePaidThroughDate;
                if (message.inNetworkConsideration != null && message.hasOwnProperty("inNetworkConsideration"))
                    object.inNetworkConsideration = message.inNetworkConsideration;
                if (message.isCobraPlan != null && message.hasOwnProperty("isCobraPlan"))
                    object.isCobraPlan = $root.google.protobuf.BoolValue.toObject(message.isCobraPlan, options);
                if (message.isHealthExchangePlan != null && message.hasOwnProperty("isHealthExchangePlan"))
                    object.isHealthExchangePlan = $root.google.protobuf.BoolValue.toObject(message.isHealthExchangePlan, options);
                if (message.newPlanAvailable != null && message.hasOwnProperty("newPlanAvailable"))
                    object.newPlanAvailable = $root.google.protobuf.BoolValue.toObject(message.newPlanAvailable, options);
                if (message.newPlanEffectiveDate != null && message.hasOwnProperty("newPlanEffectiveDate"))
                    object.newPlanEffectiveDate = message.newPlanEffectiveDate;
                if (message.newPlanSubscriberId != null && message.hasOwnProperty("newPlanSubscriberId"))
                    object.newPlanSubscriberId = message.newPlanSubscriberId;
                if (message.otherInsuranceExists != null && message.hasOwnProperty("otherInsuranceExists"))
                    object.otherInsuranceExists = $root.google.protobuf.BoolValue.toObject(message.otherInsuranceExists, options);
                if (message.otherInsuranceName != null && message.hasOwnProperty("otherInsuranceName"))
                    object.otherInsuranceName = message.otherInsuranceName;
                if (message.patientReceivesSubsidies != null && message.hasOwnProperty("patientReceivesSubsidies"))
                    object.patientReceivesSubsidies = $root.google.protobuf.BoolValue.toObject(message.patientReceivesSubsidies, options);
                if (message.pbmExists != null && message.hasOwnProperty("pbmExists"))
                    object.pbmExists = $root.google.protobuf.BoolValue.toObject(message.pbmExists, options);
                if (message.pbmName != null && message.hasOwnProperty("pbmName"))
                    object.pbmName = message.pbmName;
                if (message.pbmPayerID != null && message.hasOwnProperty("pbmPayerID"))
                    object.pbmPayerID = message.pbmPayerID;
                if (message.pbmPhone != null && message.hasOwnProperty("pbmPhone"))
                    object.pbmPhone = message.pbmPhone;
                if (message.pbmPolicyID != null && message.hasOwnProperty("pbmPolicyID"))
                    object.pbmPolicyID = message.pbmPolicyID;
                if (message.planCode != null && message.hasOwnProperty("planCode"))
                    object.planCode = message.planCode;
                if (message.planEffectiveDate != null && message.hasOwnProperty("planEffectiveDate"))
                    object.planEffectiveDate = message.planEffectiveDate;
                if (message.planGroupName != null && message.hasOwnProperty("planGroupName"))
                    object.planGroupName = message.planGroupName;
                if (message.planGroupNumber != null && message.hasOwnProperty("planGroupNumber"))
                    object.planGroupNumber = message.planGroupNumber;
                if (message.planName != null && message.hasOwnProperty("planName"))
                    object.planName = message.planName;
                if (message.planRenewalMonth != null && message.hasOwnProperty("planRenewalMonth"))
                    object.planRenewalMonth = options.enums === String ? $root.infinitusapi.INFPlanRenewalMonth[message.planRenewalMonth] === undefined ? message.planRenewalMonth : $root.infinitusapi.INFPlanRenewalMonth[message.planRenewalMonth] : message.planRenewalMonth;
                if (message.planTerminationDate != null && message.hasOwnProperty("planTerminationDate"))
                    object.planTerminationDate = message.planTerminationDate;
                if (message.planType != null && message.hasOwnProperty("planType"))
                    object.planType = options.enums === String ? $root.infinitusapi.INFPlanType[message.planType] === undefined ? message.planType : $root.infinitusapi.INFPlanType[message.planType] : message.planType;
                if (message.policyHolderDateOfBirth != null && message.hasOwnProperty("policyHolderDateOfBirth"))
                    object.policyHolderDateOfBirth = message.policyHolderDateOfBirth;
                if (message.policyHolderFirstName != null && message.hasOwnProperty("policyHolderFirstName"))
                    object.policyHolderFirstName = message.policyHolderFirstName;
                if (message.policyHolderLastName != null && message.hasOwnProperty("policyHolderLastName"))
                    object.policyHolderLastName = message.policyHolderLastName;
                if (message.policyHolderRelationship != null && message.hasOwnProperty("policyHolderRelationship"))
                    object.policyHolderRelationship = options.enums === String ? $root.infinitusapi.INFPolicyHolderRelationship[message.policyHolderRelationship] === undefined ? message.policyHolderRelationship : $root.infinitusapi.INFPolicyHolderRelationship[message.policyHolderRelationship] : message.policyHolderRelationship;
                if (message.policyType != null && message.hasOwnProperty("policyType"))
                    object.policyType = options.enums === String ? $root.infinitusapi.INFPolicyType[message.policyType] === undefined ? message.policyType : $root.infinitusapi.INFPolicyType[message.policyType] : message.policyType;
                if (message.practiceNetworkStatus != null && message.hasOwnProperty("practiceNetworkStatus"))
                    object.practiceNetworkStatus = options.enums === String ? $root.infinitusapi.INFPracticeNetworkStatus[message.practiceNetworkStatus] === undefined ? message.practiceNetworkStatus : $root.infinitusapi.INFPracticeNetworkStatus[message.practiceNetworkStatus] : message.practiceNetworkStatus;
                if (message.providerInNetworkAddress != null && message.hasOwnProperty("providerInNetworkAddress"))
                    object.providerInNetworkAddress = $root.infinitusapi.INFAddress.toObject(message.providerInNetworkAddress, options);
                if (message.providerNetworkStatus != null && message.hasOwnProperty("providerNetworkStatus"))
                    object.providerNetworkStatus = options.enums === String ? $root.infinitusapi.INFProviderNetworkStatus[message.providerNetworkStatus] === undefined ? message.providerNetworkStatus : $root.infinitusapi.INFProviderNetworkStatus[message.providerNetworkStatus] : message.providerNetworkStatus;
                if (message.providerNetworkStatusOnly != null && message.hasOwnProperty("providerNetworkStatusOnly"))
                    object.providerNetworkStatusOnly = options.enums === String ? $root.infinitusapi.INFProviderNetworkStatusOnly[message.providerNetworkStatusOnly] === undefined ? message.providerNetworkStatusOnly : $root.infinitusapi.INFProviderNetworkStatusOnly[message.providerNetworkStatusOnly] : message.providerNetworkStatusOnly;
                if (message.renewalType != null && message.hasOwnProperty("renewalType"))
                    object.renewalType = options.enums === String ? $root.infinitusapi.INFPlanRenewalType[message.renewalType] === undefined ? message.renewalType : $root.infinitusapi.INFPlanRenewalType[message.renewalType] : message.renewalType;
                if (message.sitesOfCare && message.sitesOfCare.length) {
                    object.sitesOfCare = [];
                    for (var j = 0; j < message.sitesOfCare.length; ++j)
                        object.sitesOfCare[j] = $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare.toObject(message.sitesOfCare[j], options);
                }
                if (message.standardPlanLetter != null && message.hasOwnProperty("standardPlanLetter"))
                    object.standardPlanLetter = options.enums === String ? $root.infinitusapi.INFStandardPlanLetter[message.standardPlanLetter] === undefined ? message.standardPlanLetter : $root.infinitusapi.INFStandardPlanLetter[message.standardPlanLetter] : message.standardPlanLetter;
                if (message.willCoverIfPrimaryDenies != null && message.hasOwnProperty("willCoverIfPrimaryDenies"))
                    object.willCoverIfPrimaryDenies = $root.google.protobuf.BoolValue.toObject(message.willCoverIfPrimaryDenies, options);
                if (message.willCoverPartBDeductible != null && message.hasOwnProperty("willCoverPartBDeductible"))
                    object.willCoverPartBDeductible = $root.google.protobuf.BoolValue.toObject(message.willCoverPartBDeductible, options);
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputPlanInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputPlanInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputPlanInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputPlanInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputPlanInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputPlanInfo";
            };
    
            return INFBVTaskOutputPlanInfo;
        })();
    
        infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare = (function() {
    
            /**
             * Properties of a INFBVTaskOutputPlanInfoSiteOfCare.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputPlanInfoSiteOfCare
             * @property {string|null} [name] INFBVTaskOutputPlanInfoSiteOfCare name
             * @property {infinitusapi.INFBVTaskOutputPlanInfoSiteOfCareNetworkStatus|null} [networkStatus] INFBVTaskOutputPlanInfoSiteOfCare networkStatus
             */
    
            /**
             * Constructs a new INFBVTaskOutputPlanInfoSiteOfCare.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputPlanInfoSiteOfCare.
             * @implements IINFBVTaskOutputPlanInfoSiteOfCare
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputPlanInfoSiteOfCare=} [properties] Properties to set
             */
            function INFBVTaskOutputPlanInfoSiteOfCare(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputPlanInfoSiteOfCare name.
             * @member {string} name
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @instance
             */
            INFBVTaskOutputPlanInfoSiteOfCare.prototype.name = "";
    
            /**
             * INFBVTaskOutputPlanInfoSiteOfCare networkStatus.
             * @member {infinitusapi.INFBVTaskOutputPlanInfoSiteOfCareNetworkStatus} networkStatus
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @instance
             */
            INFBVTaskOutputPlanInfoSiteOfCare.prototype.networkStatus = 0;
    
            /**
             * Creates a new INFBVTaskOutputPlanInfoSiteOfCare instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPlanInfoSiteOfCare=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare} INFBVTaskOutputPlanInfoSiteOfCare instance
             */
            INFBVTaskOutputPlanInfoSiteOfCare.create = function create(properties) {
                return new INFBVTaskOutputPlanInfoSiteOfCare(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputPlanInfoSiteOfCare message. Does not implicitly {@link infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPlanInfoSiteOfCare} message INFBVTaskOutputPlanInfoSiteOfCare message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputPlanInfoSiteOfCare.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.networkStatus);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputPlanInfoSiteOfCare message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPlanInfoSiteOfCare} message INFBVTaskOutputPlanInfoSiteOfCare message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputPlanInfoSiteOfCare.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputPlanInfoSiteOfCare message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare} INFBVTaskOutputPlanInfoSiteOfCare
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputPlanInfoSiteOfCare.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.networkStatus = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputPlanInfoSiteOfCare message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare} INFBVTaskOutputPlanInfoSiteOfCare
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputPlanInfoSiteOfCare.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputPlanInfoSiteOfCare message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputPlanInfoSiteOfCare.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                    switch (message.networkStatus) {
                    default:
                        return "networkStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputPlanInfoSiteOfCare message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare} INFBVTaskOutputPlanInfoSiteOfCare
             */
            INFBVTaskOutputPlanInfoSiteOfCare.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare();
                if (object.name != null)
                    message.name = String(object.name);
                switch (object.networkStatus) {
                default:
                    if (typeof object.networkStatus === "number") {
                        message.networkStatus = object.networkStatus;
                        break;
                    }
                    break;
                case "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_UNKNOWN":
                case 0:
                    message.networkStatus = 0;
                    break;
                case "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK":
                case 1:
                    message.networkStatus = 1;
                    break;
                case "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_IN_NETWORK_DIFFERENT_ADDRESS":
                case 2:
                    message.networkStatus = 2;
                    break;
                case "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_OUT_OF_NETWORK":
                case 3:
                    message.networkStatus = 3;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputPlanInfoSiteOfCare message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare} message INFBVTaskOutputPlanInfoSiteOfCare
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputPlanInfoSiteOfCare.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.networkStatus = options.enums === String ? "INF_SITE_OF_CARE_NETWORK_STATUS_ONLY_UNKNOWN" : 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                    object.networkStatus = options.enums === String ? $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCareNetworkStatus[message.networkStatus] === undefined ? message.networkStatus : $root.infinitusapi.INFBVTaskOutputPlanInfoSiteOfCareNetworkStatus[message.networkStatus] : message.networkStatus;
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputPlanInfoSiteOfCare to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputPlanInfoSiteOfCare.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputPlanInfoSiteOfCare
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputPlanInfoSiteOfCare.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputPlanInfoSiteOfCare";
            };
    
            return INFBVTaskOutputPlanInfoSiteOfCare;
        })();
    
        infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy = (function() {
    
            /**
             * Properties of a INFBVTaskOutputPreferredSpecialtyPharmacy.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputPreferredSpecialtyPharmacy
             * @property {google.protobuf.IBoolValue|null} [available] INFBVTaskOutputPreferredSpecialtyPharmacy available
             * @property {string|null} [name] INFBVTaskOutputPreferredSpecialtyPharmacy name
             */
    
            /**
             * Constructs a new INFBVTaskOutputPreferredSpecialtyPharmacy.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputPreferredSpecialtyPharmacy.
             * @implements IINFBVTaskOutputPreferredSpecialtyPharmacy
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputPreferredSpecialtyPharmacy=} [properties] Properties to set
             */
            function INFBVTaskOutputPreferredSpecialtyPharmacy(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputPreferredSpecialtyPharmacy available.
             * @member {google.protobuf.IBoolValue|null|undefined} available
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @instance
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.prototype.available = null;
    
            /**
             * INFBVTaskOutputPreferredSpecialtyPharmacy name.
             * @member {string} name
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @instance
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.prototype.name = "";
    
            /**
             * Creates a new INFBVTaskOutputPreferredSpecialtyPharmacy instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPreferredSpecialtyPharmacy=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy} INFBVTaskOutputPreferredSpecialtyPharmacy instance
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.create = function create(properties) {
                return new INFBVTaskOutputPreferredSpecialtyPharmacy(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputPreferredSpecialtyPharmacy message. Does not implicitly {@link infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPreferredSpecialtyPharmacy} message INFBVTaskOutputPreferredSpecialtyPharmacy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.available != null && Object.hasOwnProperty.call(message, "available"))
                    $root.google.protobuf.BoolValue.encode(message.available, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputPreferredSpecialtyPharmacy message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {infinitusapi.IINFBVTaskOutputPreferredSpecialtyPharmacy} message INFBVTaskOutputPreferredSpecialtyPharmacy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputPreferredSpecialtyPharmacy message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy} INFBVTaskOutputPreferredSpecialtyPharmacy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.available = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputPreferredSpecialtyPharmacy message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy} INFBVTaskOutputPreferredSpecialtyPharmacy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputPreferredSpecialtyPharmacy message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.available != null && message.hasOwnProperty("available")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.available);
                    if (error)
                        return "available." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputPreferredSpecialtyPharmacy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy} INFBVTaskOutputPreferredSpecialtyPharmacy
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy();
                if (object.available != null) {
                    if (typeof object.available !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy.available: object expected");
                    message.available = $root.google.protobuf.BoolValue.fromObject(object.available);
                }
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputPreferredSpecialtyPharmacy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy} message INFBVTaskOutputPreferredSpecialtyPharmacy
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.available = null;
                    object.name = "";
                }
                if (message.available != null && message.hasOwnProperty("available"))
                    object.available = $root.google.protobuf.BoolValue.toObject(message.available, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputPreferredSpecialtyPharmacy to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputPreferredSpecialtyPharmacy
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputPreferredSpecialtyPharmacy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputPreferredSpecialtyPharmacy";
            };
    
            return INFBVTaskOutputPreferredSpecialtyPharmacy;
        })();
    
        infinitusapi.INFBVTaskOutputProductCodeBenefits = (function() {
    
            /**
             * Properties of a INFBVTaskOutputProductCodeBenefits.
             * @memberof infinitusapi
             * @interface IINFBVTaskOutputProductCodeBenefits
             * @property {string|null} [additionalNotes] INFBVTaskOutputProductCodeBenefits additionalNotes
             * @property {string|null} [code] INFBVTaskOutputProductCodeBenefits code
             * @property {google.protobuf.IDoubleValue|null} [coinsurance] INFBVTaskOutputProductCodeBenefits coinsurance
             * @property {google.protobuf.IDoubleValue|null} [copay] INFBVTaskOutputProductCodeBenefits copay
             * @property {google.protobuf.IBoolValue|null} [deductibleApplies] INFBVTaskOutputProductCodeBenefits deductibleApplies
             * @property {google.protobuf.IBoolValue|null} [isCoverageSameForBNBAndSP] INFBVTaskOutputProductCodeBenefits isCoverageSameForBNBAndSP
             * @property {google.protobuf.IDoubleValue|null} [specialtyPharmacyCoinsurance] INFBVTaskOutputProductCodeBenefits specialtyPharmacyCoinsurance
             * @property {google.protobuf.IDoubleValue|null} [specialtyPharmacyCopay] INFBVTaskOutputProductCodeBenefits specialtyPharmacyCopay
             * @property {google.protobuf.IBoolValue|null} [specialtyPharmacyCoverage] INFBVTaskOutputProductCodeBenefits specialtyPharmacyCoverage
             * @property {google.protobuf.IBoolValue|null} [specialtyPharmacyDeductibleApplies] INFBVTaskOutputProductCodeBenefits specialtyPharmacyDeductibleApplies
             * @property {infinitusapi.INFReasonForNonCoverage|null} [specialtyPharmacyReasonForNonCoverage] INFBVTaskOutputProductCodeBenefits specialtyPharmacyReasonForNonCoverage
             * @property {google.protobuf.IBoolValue|null} [valid] INFBVTaskOutputProductCodeBenefits valid
             */
    
            /**
             * Constructs a new INFBVTaskOutputProductCodeBenefits.
             * @memberof infinitusapi
             * @classdesc Represents a INFBVTaskOutputProductCodeBenefits.
             * @implements IINFBVTaskOutputProductCodeBenefits
             * @constructor
             * @param {infinitusapi.IINFBVTaskOutputProductCodeBenefits=} [properties] Properties to set
             */
            function INFBVTaskOutputProductCodeBenefits(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFBVTaskOutputProductCodeBenefits additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.additionalNotes = "";
    
            /**
             * INFBVTaskOutputProductCodeBenefits code.
             * @member {string} code
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.code = "";
    
            /**
             * INFBVTaskOutputProductCodeBenefits coinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} coinsurance
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.coinsurance = null;
    
            /**
             * INFBVTaskOutputProductCodeBenefits copay.
             * @member {google.protobuf.IDoubleValue|null|undefined} copay
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.copay = null;
    
            /**
             * INFBVTaskOutputProductCodeBenefits deductibleApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} deductibleApplies
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.deductibleApplies = null;
    
            /**
             * INFBVTaskOutputProductCodeBenefits isCoverageSameForBNBAndSP.
             * @member {google.protobuf.IBoolValue|null|undefined} isCoverageSameForBNBAndSP
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.isCoverageSameForBNBAndSP = null;
    
            /**
             * INFBVTaskOutputProductCodeBenefits specialtyPharmacyCoinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} specialtyPharmacyCoinsurance
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.specialtyPharmacyCoinsurance = null;
    
            /**
             * INFBVTaskOutputProductCodeBenefits specialtyPharmacyCopay.
             * @member {google.protobuf.IDoubleValue|null|undefined} specialtyPharmacyCopay
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.specialtyPharmacyCopay = null;
    
            /**
             * INFBVTaskOutputProductCodeBenefits specialtyPharmacyCoverage.
             * @member {google.protobuf.IBoolValue|null|undefined} specialtyPharmacyCoverage
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.specialtyPharmacyCoverage = null;
    
            /**
             * INFBVTaskOutputProductCodeBenefits specialtyPharmacyDeductibleApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} specialtyPharmacyDeductibleApplies
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.specialtyPharmacyDeductibleApplies = null;
    
            /**
             * INFBVTaskOutputProductCodeBenefits specialtyPharmacyReasonForNonCoverage.
             * @member {infinitusapi.INFReasonForNonCoverage} specialtyPharmacyReasonForNonCoverage
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.specialtyPharmacyReasonForNonCoverage = 0;
    
            /**
             * INFBVTaskOutputProductCodeBenefits valid.
             * @member {google.protobuf.IBoolValue|null|undefined} valid
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             */
            INFBVTaskOutputProductCodeBenefits.prototype.valid = null;
    
            /**
             * Creates a new INFBVTaskOutputProductCodeBenefits instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputProductCodeBenefits=} [properties] Properties to set
             * @returns {infinitusapi.INFBVTaskOutputProductCodeBenefits} INFBVTaskOutputProductCodeBenefits instance
             */
            INFBVTaskOutputProductCodeBenefits.create = function create(properties) {
                return new INFBVTaskOutputProductCodeBenefits(properties);
            };
    
            /**
             * Encodes the specified INFBVTaskOutputProductCodeBenefits message. Does not implicitly {@link infinitusapi.INFBVTaskOutputProductCodeBenefits.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputProductCodeBenefits} message INFBVTaskOutputProductCodeBenefits message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputProductCodeBenefits.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                if (message.coinsurance != null && Object.hasOwnProperty.call(message, "coinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.coinsurance, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.copay != null && Object.hasOwnProperty.call(message, "copay"))
                    $root.google.protobuf.DoubleValue.encode(message.copay, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.deductibleApplies != null && Object.hasOwnProperty.call(message, "deductibleApplies"))
                    $root.google.protobuf.BoolValue.encode(message.deductibleApplies, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.isCoverageSameForBNBAndSP != null && Object.hasOwnProperty.call(message, "isCoverageSameForBNBAndSP"))
                    $root.google.protobuf.BoolValue.encode(message.isCoverageSameForBNBAndSP, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.specialtyPharmacyCoinsurance != null && Object.hasOwnProperty.call(message, "specialtyPharmacyCoinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.specialtyPharmacyCoinsurance, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.specialtyPharmacyCopay != null && Object.hasOwnProperty.call(message, "specialtyPharmacyCopay"))
                    $root.google.protobuf.DoubleValue.encode(message.specialtyPharmacyCopay, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.specialtyPharmacyCoverage != null && Object.hasOwnProperty.call(message, "specialtyPharmacyCoverage"))
                    $root.google.protobuf.BoolValue.encode(message.specialtyPharmacyCoverage, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.specialtyPharmacyDeductibleApplies != null && Object.hasOwnProperty.call(message, "specialtyPharmacyDeductibleApplies"))
                    $root.google.protobuf.BoolValue.encode(message.specialtyPharmacyDeductibleApplies, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.specialtyPharmacyReasonForNonCoverage != null && Object.hasOwnProperty.call(message, "specialtyPharmacyReasonForNonCoverage"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.specialtyPharmacyReasonForNonCoverage);
                if (message.valid != null && Object.hasOwnProperty.call(message, "valid"))
                    $root.google.protobuf.BoolValue.encode(message.valid, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFBVTaskOutputProductCodeBenefits message, length delimited. Does not implicitly {@link infinitusapi.INFBVTaskOutputProductCodeBenefits.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {infinitusapi.IINFBVTaskOutputProductCodeBenefits} message INFBVTaskOutputProductCodeBenefits message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFBVTaskOutputProductCodeBenefits.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFBVTaskOutputProductCodeBenefits message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFBVTaskOutputProductCodeBenefits} INFBVTaskOutputProductCodeBenefits
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputProductCodeBenefits.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFBVTaskOutputProductCodeBenefits();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.code = reader.string();
                            break;
                        }
                    case 3: {
                            message.coinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.copay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.deductibleApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.isCoverageSameForBNBAndSP = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.specialtyPharmacyCoinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.specialtyPharmacyCopay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.specialtyPharmacyCoverage = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.specialtyPharmacyDeductibleApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.specialtyPharmacyReasonForNonCoverage = reader.int32();
                            break;
                        }
                    case 12: {
                            message.valid = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFBVTaskOutputProductCodeBenefits message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFBVTaskOutputProductCodeBenefits} INFBVTaskOutputProductCodeBenefits
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFBVTaskOutputProductCodeBenefits.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFBVTaskOutputProductCodeBenefits message.
             * @function verify
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFBVTaskOutputProductCodeBenefits.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.coinsurance != null && message.hasOwnProperty("coinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.coinsurance);
                    if (error)
                        return "coinsurance." + error;
                }
                if (message.copay != null && message.hasOwnProperty("copay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.copay);
                    if (error)
                        return "copay." + error;
                }
                if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.deductibleApplies);
                    if (error)
                        return "deductibleApplies." + error;
                }
                if (message.isCoverageSameForBNBAndSP != null && message.hasOwnProperty("isCoverageSameForBNBAndSP")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isCoverageSameForBNBAndSP);
                    if (error)
                        return "isCoverageSameForBNBAndSP." + error;
                }
                if (message.specialtyPharmacyCoinsurance != null && message.hasOwnProperty("specialtyPharmacyCoinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.specialtyPharmacyCoinsurance);
                    if (error)
                        return "specialtyPharmacyCoinsurance." + error;
                }
                if (message.specialtyPharmacyCopay != null && message.hasOwnProperty("specialtyPharmacyCopay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.specialtyPharmacyCopay);
                    if (error)
                        return "specialtyPharmacyCopay." + error;
                }
                if (message.specialtyPharmacyCoverage != null && message.hasOwnProperty("specialtyPharmacyCoverage")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.specialtyPharmacyCoverage);
                    if (error)
                        return "specialtyPharmacyCoverage." + error;
                }
                if (message.specialtyPharmacyDeductibleApplies != null && message.hasOwnProperty("specialtyPharmacyDeductibleApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.specialtyPharmacyDeductibleApplies);
                    if (error)
                        return "specialtyPharmacyDeductibleApplies." + error;
                }
                if (message.specialtyPharmacyReasonForNonCoverage != null && message.hasOwnProperty("specialtyPharmacyReasonForNonCoverage"))
                    switch (message.specialtyPharmacyReasonForNonCoverage) {
                    default:
                        return "specialtyPharmacyReasonForNonCoverage: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                        break;
                    }
                if (message.valid != null && message.hasOwnProperty("valid")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.valid);
                    if (error)
                        return "valid." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFBVTaskOutputProductCodeBenefits message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFBVTaskOutputProductCodeBenefits} INFBVTaskOutputProductCodeBenefits
             */
            INFBVTaskOutputProductCodeBenefits.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFBVTaskOutputProductCodeBenefits)
                    return object;
                var message = new $root.infinitusapi.INFBVTaskOutputProductCodeBenefits();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.code != null)
                    message.code = String(object.code);
                if (object.coinsurance != null) {
                    if (typeof object.coinsurance !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.coinsurance: object expected");
                    message.coinsurance = $root.google.protobuf.DoubleValue.fromObject(object.coinsurance);
                }
                if (object.copay != null) {
                    if (typeof object.copay !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.copay: object expected");
                    message.copay = $root.google.protobuf.DoubleValue.fromObject(object.copay);
                }
                if (object.deductibleApplies != null) {
                    if (typeof object.deductibleApplies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.deductibleApplies: object expected");
                    message.deductibleApplies = $root.google.protobuf.BoolValue.fromObject(object.deductibleApplies);
                }
                if (object.isCoverageSameForBNBAndSP != null) {
                    if (typeof object.isCoverageSameForBNBAndSP !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.isCoverageSameForBNBAndSP: object expected");
                    message.isCoverageSameForBNBAndSP = $root.google.protobuf.BoolValue.fromObject(object.isCoverageSameForBNBAndSP);
                }
                if (object.specialtyPharmacyCoinsurance != null) {
                    if (typeof object.specialtyPharmacyCoinsurance !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.specialtyPharmacyCoinsurance: object expected");
                    message.specialtyPharmacyCoinsurance = $root.google.protobuf.DoubleValue.fromObject(object.specialtyPharmacyCoinsurance);
                }
                if (object.specialtyPharmacyCopay != null) {
                    if (typeof object.specialtyPharmacyCopay !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.specialtyPharmacyCopay: object expected");
                    message.specialtyPharmacyCopay = $root.google.protobuf.DoubleValue.fromObject(object.specialtyPharmacyCopay);
                }
                if (object.specialtyPharmacyCoverage != null) {
                    if (typeof object.specialtyPharmacyCoverage !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.specialtyPharmacyCoverage: object expected");
                    message.specialtyPharmacyCoverage = $root.google.protobuf.BoolValue.fromObject(object.specialtyPharmacyCoverage);
                }
                if (object.specialtyPharmacyDeductibleApplies != null) {
                    if (typeof object.specialtyPharmacyDeductibleApplies !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.specialtyPharmacyDeductibleApplies: object expected");
                    message.specialtyPharmacyDeductibleApplies = $root.google.protobuf.BoolValue.fromObject(object.specialtyPharmacyDeductibleApplies);
                }
                switch (object.specialtyPharmacyReasonForNonCoverage) {
                default:
                    if (typeof object.specialtyPharmacyReasonForNonCoverage === "number") {
                        message.specialtyPharmacyReasonForNonCoverage = object.specialtyPharmacyReasonForNonCoverage;
                        break;
                    }
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_UNKNOWN":
                case 0:
                    message.specialtyPharmacyReasonForNonCoverage = 0;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NOT_APPLICABLE":
                case 1:
                    message.specialtyPharmacyReasonForNonCoverage = 1;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM":
                case 2:
                    message.specialtyPharmacyReasonForNonCoverage = 2;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS":
                case 3:
                    message.specialtyPharmacyReasonForNonCoverage = 3;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B":
                case 4:
                    message.specialtyPharmacyReasonForNonCoverage = 4;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_CAP_MET":
                case 5:
                    message.specialtyPharmacyReasonForNonCoverage = 5;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS":
                case 6:
                    message.specialtyPharmacyReasonForNonCoverage = 6;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG":
                case 7:
                    message.specialtyPharmacyReasonForNonCoverage = 7;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT":
                case 8:
                    message.specialtyPharmacyReasonForNonCoverage = 8;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG":
                case 9:
                    message.specialtyPharmacyReasonForNonCoverage = 9;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL":
                case 10:
                    message.specialtyPharmacyReasonForNonCoverage = 10;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY":
                case 11:
                    message.specialtyPharmacyReasonForNonCoverage = 11;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION":
                case 12:
                    message.specialtyPharmacyReasonForNonCoverage = 12;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY":
                case 13:
                    message.specialtyPharmacyReasonForNonCoverage = 13;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED":
                case 14:
                    message.specialtyPharmacyReasonForNonCoverage = 14;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED":
                case 15:
                    message.specialtyPharmacyReasonForNonCoverage = 15;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK":
                case 16:
                    message.specialtyPharmacyReasonForNonCoverage = 16;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_OTHER":
                case 17:
                    message.specialtyPharmacyReasonForNonCoverage = 17;
                    break;
                }
                if (object.valid != null) {
                    if (typeof object.valid !== "object")
                        throw TypeError(".infinitusapi.INFBVTaskOutputProductCodeBenefits.valid: object expected");
                    message.valid = $root.google.protobuf.BoolValue.fromObject(object.valid);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFBVTaskOutputProductCodeBenefits message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {infinitusapi.INFBVTaskOutputProductCodeBenefits} message INFBVTaskOutputProductCodeBenefits
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFBVTaskOutputProductCodeBenefits.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.code = "";
                    object.coinsurance = null;
                    object.copay = null;
                    object.deductibleApplies = null;
                    object.isCoverageSameForBNBAndSP = null;
                    object.specialtyPharmacyCoinsurance = null;
                    object.specialtyPharmacyCopay = null;
                    object.specialtyPharmacyCoverage = null;
                    object.specialtyPharmacyDeductibleApplies = null;
                    object.specialtyPharmacyReasonForNonCoverage = options.enums === String ? "INF_REASON_FOR_NON_COVERAGE_UNKNOWN" : 0;
                    object.valid = null;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.coinsurance != null && message.hasOwnProperty("coinsurance"))
                    object.coinsurance = $root.google.protobuf.DoubleValue.toObject(message.coinsurance, options);
                if (message.copay != null && message.hasOwnProperty("copay"))
                    object.copay = $root.google.protobuf.DoubleValue.toObject(message.copay, options);
                if (message.deductibleApplies != null && message.hasOwnProperty("deductibleApplies"))
                    object.deductibleApplies = $root.google.protobuf.BoolValue.toObject(message.deductibleApplies, options);
                if (message.isCoverageSameForBNBAndSP != null && message.hasOwnProperty("isCoverageSameForBNBAndSP"))
                    object.isCoverageSameForBNBAndSP = $root.google.protobuf.BoolValue.toObject(message.isCoverageSameForBNBAndSP, options);
                if (message.specialtyPharmacyCoinsurance != null && message.hasOwnProperty("specialtyPharmacyCoinsurance"))
                    object.specialtyPharmacyCoinsurance = $root.google.protobuf.DoubleValue.toObject(message.specialtyPharmacyCoinsurance, options);
                if (message.specialtyPharmacyCopay != null && message.hasOwnProperty("specialtyPharmacyCopay"))
                    object.specialtyPharmacyCopay = $root.google.protobuf.DoubleValue.toObject(message.specialtyPharmacyCopay, options);
                if (message.specialtyPharmacyCoverage != null && message.hasOwnProperty("specialtyPharmacyCoverage"))
                    object.specialtyPharmacyCoverage = $root.google.protobuf.BoolValue.toObject(message.specialtyPharmacyCoverage, options);
                if (message.specialtyPharmacyDeductibleApplies != null && message.hasOwnProperty("specialtyPharmacyDeductibleApplies"))
                    object.specialtyPharmacyDeductibleApplies = $root.google.protobuf.BoolValue.toObject(message.specialtyPharmacyDeductibleApplies, options);
                if (message.specialtyPharmacyReasonForNonCoverage != null && message.hasOwnProperty("specialtyPharmacyReasonForNonCoverage"))
                    object.specialtyPharmacyReasonForNonCoverage = options.enums === String ? $root.infinitusapi.INFReasonForNonCoverage[message.specialtyPharmacyReasonForNonCoverage] === undefined ? message.specialtyPharmacyReasonForNonCoverage : $root.infinitusapi.INFReasonForNonCoverage[message.specialtyPharmacyReasonForNonCoverage] : message.specialtyPharmacyReasonForNonCoverage;
                if (message.valid != null && message.hasOwnProperty("valid"))
                    object.valid = $root.google.protobuf.BoolValue.toObject(message.valid, options);
                return object;
            };
    
            /**
             * Converts this INFBVTaskOutputProductCodeBenefits to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFBVTaskOutputProductCodeBenefits.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFBVTaskOutputProductCodeBenefits
             * @function getTypeUrl
             * @memberof infinitusapi.INFBVTaskOutputProductCodeBenefits
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFBVTaskOutputProductCodeBenefits.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFBVTaskOutputProductCodeBenefits";
            };
    
            return INFBVTaskOutputProductCodeBenefits;
        })();
    
        infinitusapi.INFCreateTasksError = (function() {
    
            /**
             * Properties of a INFCreateTasksError.
             * @memberof infinitusapi
             * @interface IINFCreateTasksError
             * @property {infinitusapi.INFCreateTaskErrorCode|null} [errorCode] INFCreateTasksError errorCode
             * @property {string|null} [errorMessage] INFCreateTasksError errorMessage
             */
    
            /**
             * Constructs a new INFCreateTasksError.
             * @memberof infinitusapi
             * @classdesc Represents a INFCreateTasksError.
             * @implements IINFCreateTasksError
             * @constructor
             * @param {infinitusapi.IINFCreateTasksError=} [properties] Properties to set
             */
            function INFCreateTasksError(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFCreateTasksError errorCode.
             * @member {infinitusapi.INFCreateTaskErrorCode} errorCode
             * @memberof infinitusapi.INFCreateTasksError
             * @instance
             */
            INFCreateTasksError.prototype.errorCode = 0;
    
            /**
             * INFCreateTasksError errorMessage.
             * @member {string} errorMessage
             * @memberof infinitusapi.INFCreateTasksError
             * @instance
             */
            INFCreateTasksError.prototype.errorMessage = "";
    
            /**
             * Creates a new INFCreateTasksError instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {infinitusapi.IINFCreateTasksError=} [properties] Properties to set
             * @returns {infinitusapi.INFCreateTasksError} INFCreateTasksError instance
             */
            INFCreateTasksError.create = function create(properties) {
                return new INFCreateTasksError(properties);
            };
    
            /**
             * Encodes the specified INFCreateTasksError message. Does not implicitly {@link infinitusapi.INFCreateTasksError.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {infinitusapi.IINFCreateTasksError} message INFCreateTasksError message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFCreateTasksError.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.errorCode);
                if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
                return writer;
            };
    
            /**
             * Encodes the specified INFCreateTasksError message, length delimited. Does not implicitly {@link infinitusapi.INFCreateTasksError.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {infinitusapi.IINFCreateTasksError} message INFCreateTasksError message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFCreateTasksError.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFCreateTasksError message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFCreateTasksError} INFCreateTasksError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFCreateTasksError.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFCreateTasksError();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.errorCode = reader.int32();
                            break;
                        }
                    case 2: {
                            message.errorMessage = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFCreateTasksError message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFCreateTasksError} INFCreateTasksError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFCreateTasksError.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFCreateTasksError message.
             * @function verify
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFCreateTasksError.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                    switch (message.errorCode) {
                    default:
                        return "errorCode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    if (!$util.isString(message.errorMessage))
                        return "errorMessage: string expected";
                return null;
            };
    
            /**
             * Creates a INFCreateTasksError message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFCreateTasksError} INFCreateTasksError
             */
            INFCreateTasksError.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFCreateTasksError)
                    return object;
                var message = new $root.infinitusapi.INFCreateTasksError();
                switch (object.errorCode) {
                default:
                    if (typeof object.errorCode === "number") {
                        message.errorCode = object.errorCode;
                        break;
                    }
                    break;
                case "INF_CREATE_TASK_ERROR_CODE_INVALID_INPUT":
                case 0:
                    message.errorCode = 0;
                    break;
                case "INF_CREATE_TASK_ERROR_CODE_MISSING_INPUT":
                case 1:
                    message.errorCode = 1;
                    break;
                case "INF_CREATE_TASK_ERROR_CODE_CAPACITY_REACHED":
                case 2:
                    message.errorCode = 2;
                    break;
                case "INF_CREATE_TASK_ERROR_CODE_DUPLICATE_TASK":
                case 3:
                    message.errorCode = 3;
                    break;
                case "INF_CREATE_TASK_ERROR_CODE_INTEGRATION_TEST_NO_OUTPUTS_AVAILABLE":
                case 4:
                    message.errorCode = 4;
                    break;
                case "INF_CREATE_TASK_ERROR_CODE_INTERNAL_SERVER_ERROR":
                case 5:
                    message.errorCode = 5;
                    break;
                case "INF_CREATE_TASK_ERROR_CODE_BUNDLE_TASK_REJECTION":
                case 6:
                    message.errorCode = 6;
                    break;
                }
                if (object.errorMessage != null)
                    message.errorMessage = String(object.errorMessage);
                return message;
            };
    
            /**
             * Creates a plain object from a INFCreateTasksError message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {infinitusapi.INFCreateTasksError} message INFCreateTasksError
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFCreateTasksError.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.errorCode = options.enums === String ? "INF_CREATE_TASK_ERROR_CODE_INVALID_INPUT" : 0;
                    object.errorMessage = "";
                }
                if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                    object.errorCode = options.enums === String ? $root.infinitusapi.INFCreateTaskErrorCode[message.errorCode] === undefined ? message.errorCode : $root.infinitusapi.INFCreateTaskErrorCode[message.errorCode] : message.errorCode;
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    object.errorMessage = message.errorMessage;
                return object;
            };
    
            /**
             * Converts this INFCreateTasksError to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFCreateTasksError
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFCreateTasksError.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFCreateTasksError
             * @function getTypeUrl
             * @memberof infinitusapi.INFCreateTasksError
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFCreateTasksError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFCreateTasksError";
            };
    
            return INFCreateTasksError;
        })();
    
        infinitusapi.INFCustomerWebhookResponse = (function() {
    
            /**
             * Properties of a INFCustomerWebhookResponse.
             * @memberof infinitusapi
             * @interface IINFCustomerWebhookResponse
             * @property {string|null} [customerId] INFCustomerWebhookResponse customerId
             * @property {infinitusapi.INFCustomerWebhookStatus|null} [status] INFCustomerWebhookResponse status
             * @property {string|null} [taskId] INFCustomerWebhookResponse taskId
             */
    
            /**
             * Constructs a new INFCustomerWebhookResponse.
             * @memberof infinitusapi
             * @classdesc Represents a INFCustomerWebhookResponse.
             * @implements IINFCustomerWebhookResponse
             * @constructor
             * @param {infinitusapi.IINFCustomerWebhookResponse=} [properties] Properties to set
             */
            function INFCustomerWebhookResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFCustomerWebhookResponse customerId.
             * @member {string} customerId
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @instance
             */
            INFCustomerWebhookResponse.prototype.customerId = "";
    
            /**
             * INFCustomerWebhookResponse status.
             * @member {infinitusapi.INFCustomerWebhookStatus} status
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @instance
             */
            INFCustomerWebhookResponse.prototype.status = 0;
    
            /**
             * INFCustomerWebhookResponse taskId.
             * @member {string} taskId
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @instance
             */
            INFCustomerWebhookResponse.prototype.taskId = "";
    
            /**
             * Creates a new INFCustomerWebhookResponse instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {infinitusapi.IINFCustomerWebhookResponse=} [properties] Properties to set
             * @returns {infinitusapi.INFCustomerWebhookResponse} INFCustomerWebhookResponse instance
             */
            INFCustomerWebhookResponse.create = function create(properties) {
                return new INFCustomerWebhookResponse(properties);
            };
    
            /**
             * Encodes the specified INFCustomerWebhookResponse message. Does not implicitly {@link infinitusapi.INFCustomerWebhookResponse.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {infinitusapi.IINFCustomerWebhookResponse} message INFCustomerWebhookResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFCustomerWebhookResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.taskId);
                return writer;
            };
    
            /**
             * Encodes the specified INFCustomerWebhookResponse message, length delimited. Does not implicitly {@link infinitusapi.INFCustomerWebhookResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {infinitusapi.IINFCustomerWebhookResponse} message INFCustomerWebhookResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFCustomerWebhookResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFCustomerWebhookResponse message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFCustomerWebhookResponse} INFCustomerWebhookResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFCustomerWebhookResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFCustomerWebhookResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.customerId = reader.string();
                            break;
                        }
                    case 2: {
                            message.status = reader.int32();
                            break;
                        }
                    case 3: {
                            message.taskId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFCustomerWebhookResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFCustomerWebhookResponse} INFCustomerWebhookResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFCustomerWebhookResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFCustomerWebhookResponse message.
             * @function verify
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFCustomerWebhookResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.customerId != null && message.hasOwnProperty("customerId"))
                    if (!$util.isString(message.customerId))
                        return "customerId: string expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.taskId != null && message.hasOwnProperty("taskId"))
                    if (!$util.isString(message.taskId))
                        return "taskId: string expected";
                return null;
            };
    
            /**
             * Creates a INFCustomerWebhookResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFCustomerWebhookResponse} INFCustomerWebhookResponse
             */
            INFCustomerWebhookResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFCustomerWebhookResponse)
                    return object;
                var message = new $root.infinitusapi.INFCustomerWebhookResponse();
                if (object.customerId != null)
                    message.customerId = String(object.customerId);
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "INF_CUSTOMER_WEBHOOK_STATUS_UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "INF_CUSTOMER_WEBHOOK_STATUS_SUCCESS":
                case 1:
                    message.status = 1;
                    break;
                case "INF_CUSTOMER_WEBHOOK_STATUS_FAILURE":
                case 2:
                    message.status = 2;
                    break;
                }
                if (object.taskId != null)
                    message.taskId = String(object.taskId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFCustomerWebhookResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {infinitusapi.INFCustomerWebhookResponse} message INFCustomerWebhookResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFCustomerWebhookResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.customerId = "";
                    object.status = options.enums === String ? "INF_CUSTOMER_WEBHOOK_STATUS_UNKNOWN" : 0;
                    object.taskId = "";
                }
                if (message.customerId != null && message.hasOwnProperty("customerId"))
                    object.customerId = message.customerId;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.infinitusapi.INFCustomerWebhookStatus[message.status] === undefined ? message.status : $root.infinitusapi.INFCustomerWebhookStatus[message.status] : message.status;
                if (message.taskId != null && message.hasOwnProperty("taskId"))
                    object.taskId = message.taskId;
                return object;
            };
    
            /**
             * Converts this INFCustomerWebhookResponse to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFCustomerWebhookResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFCustomerWebhookResponse
             * @function getTypeUrl
             * @memberof infinitusapi.INFCustomerWebhookResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFCustomerWebhookResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFCustomerWebhookResponse";
            };
    
            return INFCustomerWebhookResponse;
        })();
    
        infinitusapi.INFInputValue = (function() {
    
            /**
             * Properties of a INFInputValue.
             * @memberof infinitusapi
             * @interface IINFInputValue
             * @property {infinitusapi.IINFInputValueArray|null} [array] INFInputValue array
             * @property {infinitusapi.IINFInputValueNestedFields|null} [nestedFields] INFInputValue nestedFields
             * @property {infinitusapi.IINFInputValueLeaf|null} [value] INFInputValue value
             */
    
            /**
             * Constructs a new INFInputValue.
             * @memberof infinitusapi
             * @classdesc Represents a INFInputValue.
             * @implements IINFInputValue
             * @constructor
             * @param {infinitusapi.IINFInputValue=} [properties] Properties to set
             */
            function INFInputValue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFInputValue array.
             * @member {infinitusapi.IINFInputValueArray|null|undefined} array
             * @memberof infinitusapi.INFInputValue
             * @instance
             */
            INFInputValue.prototype.array = null;
    
            /**
             * INFInputValue nestedFields.
             * @member {infinitusapi.IINFInputValueNestedFields|null|undefined} nestedFields
             * @memberof infinitusapi.INFInputValue
             * @instance
             */
            INFInputValue.prototype.nestedFields = null;
    
            /**
             * INFInputValue value.
             * @member {infinitusapi.IINFInputValueLeaf|null|undefined} value
             * @memberof infinitusapi.INFInputValue
             * @instance
             */
            INFInputValue.prototype.value = null;
    
            /**
             * Creates a new INFInputValue instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {infinitusapi.IINFInputValue=} [properties] Properties to set
             * @returns {infinitusapi.INFInputValue} INFInputValue instance
             */
            INFInputValue.create = function create(properties) {
                return new INFInputValue(properties);
            };
    
            /**
             * Encodes the specified INFInputValue message. Does not implicitly {@link infinitusapi.INFInputValue.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {infinitusapi.IINFInputValue} message INFInputValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFInputValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.array != null && Object.hasOwnProperty.call(message, "array"))
                    $root.infinitusapi.INFInputValueArray.encode(message.array, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.nestedFields != null && Object.hasOwnProperty.call(message, "nestedFields"))
                    $root.infinitusapi.INFInputValueNestedFields.encode(message.nestedFields, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    $root.infinitusapi.INFInputValueLeaf.encode(message.value, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFInputValue message, length delimited. Does not implicitly {@link infinitusapi.INFInputValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {infinitusapi.IINFInputValue} message INFInputValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFInputValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFInputValue message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFInputValue} INFInputValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFInputValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFInputValue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.array = $root.infinitusapi.INFInputValueArray.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.nestedFields = $root.infinitusapi.INFInputValueNestedFields.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.value = $root.infinitusapi.INFInputValueLeaf.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFInputValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFInputValue} INFInputValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFInputValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFInputValue message.
             * @function verify
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFInputValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.array != null && message.hasOwnProperty("array")) {
                    var error = $root.infinitusapi.INFInputValueArray.verify(message.array);
                    if (error)
                        return "array." + error;
                }
                if (message.nestedFields != null && message.hasOwnProperty("nestedFields")) {
                    var error = $root.infinitusapi.INFInputValueNestedFields.verify(message.nestedFields);
                    if (error)
                        return "nestedFields." + error;
                }
                if (message.value != null && message.hasOwnProperty("value")) {
                    var error = $root.infinitusapi.INFInputValueLeaf.verify(message.value);
                    if (error)
                        return "value." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFInputValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFInputValue} INFInputValue
             */
            INFInputValue.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFInputValue)
                    return object;
                var message = new $root.infinitusapi.INFInputValue();
                if (object.array != null) {
                    if (typeof object.array !== "object")
                        throw TypeError(".infinitusapi.INFInputValue.array: object expected");
                    message.array = $root.infinitusapi.INFInputValueArray.fromObject(object.array);
                }
                if (object.nestedFields != null) {
                    if (typeof object.nestedFields !== "object")
                        throw TypeError(".infinitusapi.INFInputValue.nestedFields: object expected");
                    message.nestedFields = $root.infinitusapi.INFInputValueNestedFields.fromObject(object.nestedFields);
                }
                if (object.value != null) {
                    if (typeof object.value !== "object")
                        throw TypeError(".infinitusapi.INFInputValue.value: object expected");
                    message.value = $root.infinitusapi.INFInputValueLeaf.fromObject(object.value);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFInputValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {infinitusapi.INFInputValue} message INFInputValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFInputValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.array = null;
                    object.nestedFields = null;
                    object.value = null;
                }
                if (message.array != null && message.hasOwnProperty("array"))
                    object.array = $root.infinitusapi.INFInputValueArray.toObject(message.array, options);
                if (message.nestedFields != null && message.hasOwnProperty("nestedFields"))
                    object.nestedFields = $root.infinitusapi.INFInputValueNestedFields.toObject(message.nestedFields, options);
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = $root.infinitusapi.INFInputValueLeaf.toObject(message.value, options);
                return object;
            };
    
            /**
             * Converts this INFInputValue to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFInputValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFInputValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFInputValue
             * @function getTypeUrl
             * @memberof infinitusapi.INFInputValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFInputValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFInputValue";
            };
    
            return INFInputValue;
        })();
    
        infinitusapi.INFInputValueArray = (function() {
    
            /**
             * Properties of a INFInputValueArray.
             * @memberof infinitusapi
             * @interface IINFInputValueArray
             * @property {string|null} [displayName] INFInputValueArray displayName
             * @property {string|null} [name] INFInputValueArray name
             * @property {Array.<infinitusapi.IINFInputValueNestedFields>|null} [nestedFields] INFInputValueArray nestedFields
             * @property {Array.<infinitusapi.IINFInputValueLeaf>|null} [values] INFInputValueArray values
             */
    
            /**
             * Constructs a new INFInputValueArray.
             * @memberof infinitusapi
             * @classdesc Represents a INFInputValueArray.
             * @implements IINFInputValueArray
             * @constructor
             * @param {infinitusapi.IINFInputValueArray=} [properties] Properties to set
             */
            function INFInputValueArray(properties) {
                this.nestedFields = [];
                this.values = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFInputValueArray displayName.
             * @member {string} displayName
             * @memberof infinitusapi.INFInputValueArray
             * @instance
             */
            INFInputValueArray.prototype.displayName = "";
    
            /**
             * INFInputValueArray name.
             * @member {string} name
             * @memberof infinitusapi.INFInputValueArray
             * @instance
             */
            INFInputValueArray.prototype.name = "";
    
            /**
             * INFInputValueArray nestedFields.
             * @member {Array.<infinitusapi.IINFInputValueNestedFields>} nestedFields
             * @memberof infinitusapi.INFInputValueArray
             * @instance
             */
            INFInputValueArray.prototype.nestedFields = $util.emptyArray;
    
            /**
             * INFInputValueArray values.
             * @member {Array.<infinitusapi.IINFInputValueLeaf>} values
             * @memberof infinitusapi.INFInputValueArray
             * @instance
             */
            INFInputValueArray.prototype.values = $util.emptyArray;
    
            /**
             * Creates a new INFInputValueArray instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {infinitusapi.IINFInputValueArray=} [properties] Properties to set
             * @returns {infinitusapi.INFInputValueArray} INFInputValueArray instance
             */
            INFInputValueArray.create = function create(properties) {
                return new INFInputValueArray(properties);
            };
    
            /**
             * Encodes the specified INFInputValueArray message. Does not implicitly {@link infinitusapi.INFInputValueArray.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {infinitusapi.IINFInputValueArray} message INFInputValueArray message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFInputValueArray.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.displayName);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.nestedFields != null && message.nestedFields.length)
                    for (var i = 0; i < message.nestedFields.length; ++i)
                        $root.infinitusapi.INFInputValueNestedFields.encode(message.nestedFields[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.values != null && message.values.length)
                    for (var i = 0; i < message.values.length; ++i)
                        $root.infinitusapi.INFInputValueLeaf.encode(message.values[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFInputValueArray message, length delimited. Does not implicitly {@link infinitusapi.INFInputValueArray.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {infinitusapi.IINFInputValueArray} message INFInputValueArray message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFInputValueArray.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFInputValueArray message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFInputValueArray} INFInputValueArray
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFInputValueArray.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFInputValueArray();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.displayName = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.nestedFields && message.nestedFields.length))
                                message.nestedFields = [];
                            message.nestedFields.push($root.infinitusapi.INFInputValueNestedFields.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.values && message.values.length))
                                message.values = [];
                            message.values.push($root.infinitusapi.INFInputValueLeaf.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFInputValueArray message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFInputValueArray} INFInputValueArray
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFInputValueArray.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFInputValueArray message.
             * @function verify
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFInputValueArray.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.displayName != null && message.hasOwnProperty("displayName"))
                    if (!$util.isString(message.displayName))
                        return "displayName: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.nestedFields != null && message.hasOwnProperty("nestedFields")) {
                    if (!Array.isArray(message.nestedFields))
                        return "nestedFields: array expected";
                    for (var i = 0; i < message.nestedFields.length; ++i) {
                        var error = $root.infinitusapi.INFInputValueNestedFields.verify(message.nestedFields[i]);
                        if (error)
                            return "nestedFields." + error;
                    }
                }
                if (message.values != null && message.hasOwnProperty("values")) {
                    if (!Array.isArray(message.values))
                        return "values: array expected";
                    for (var i = 0; i < message.values.length; ++i) {
                        var error = $root.infinitusapi.INFInputValueLeaf.verify(message.values[i]);
                        if (error)
                            return "values." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a INFInputValueArray message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFInputValueArray} INFInputValueArray
             */
            INFInputValueArray.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFInputValueArray)
                    return object;
                var message = new $root.infinitusapi.INFInputValueArray();
                if (object.displayName != null)
                    message.displayName = String(object.displayName);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.nestedFields) {
                    if (!Array.isArray(object.nestedFields))
                        throw TypeError(".infinitusapi.INFInputValueArray.nestedFields: array expected");
                    message.nestedFields = [];
                    for (var i = 0; i < object.nestedFields.length; ++i) {
                        if (typeof object.nestedFields[i] !== "object")
                            throw TypeError(".infinitusapi.INFInputValueArray.nestedFields: object expected");
                        message.nestedFields[i] = $root.infinitusapi.INFInputValueNestedFields.fromObject(object.nestedFields[i]);
                    }
                }
                if (object.values) {
                    if (!Array.isArray(object.values))
                        throw TypeError(".infinitusapi.INFInputValueArray.values: array expected");
                    message.values = [];
                    for (var i = 0; i < object.values.length; ++i) {
                        if (typeof object.values[i] !== "object")
                            throw TypeError(".infinitusapi.INFInputValueArray.values: object expected");
                        message.values[i] = $root.infinitusapi.INFInputValueLeaf.fromObject(object.values[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFInputValueArray message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {infinitusapi.INFInputValueArray} message INFInputValueArray
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFInputValueArray.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.nestedFields = [];
                    object.values = [];
                }
                if (options.defaults) {
                    object.displayName = "";
                    object.name = "";
                }
                if (message.displayName != null && message.hasOwnProperty("displayName"))
                    object.displayName = message.displayName;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.nestedFields && message.nestedFields.length) {
                    object.nestedFields = [];
                    for (var j = 0; j < message.nestedFields.length; ++j)
                        object.nestedFields[j] = $root.infinitusapi.INFInputValueNestedFields.toObject(message.nestedFields[j], options);
                }
                if (message.values && message.values.length) {
                    object.values = [];
                    for (var j = 0; j < message.values.length; ++j)
                        object.values[j] = $root.infinitusapi.INFInputValueLeaf.toObject(message.values[j], options);
                }
                return object;
            };
    
            /**
             * Converts this INFInputValueArray to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFInputValueArray
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFInputValueArray.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFInputValueArray
             * @function getTypeUrl
             * @memberof infinitusapi.INFInputValueArray
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFInputValueArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFInputValueArray";
            };
    
            return INFInputValueArray;
        })();
    
        infinitusapi.INFInputValueLeaf = (function() {
    
            /**
             * Properties of a INFInputValueLeaf.
             * @memberof infinitusapi
             * @interface IINFInputValueLeaf
             * @property {google.protobuf.IBoolValue|null} [bool] INFInputValueLeaf bool
             * @property {google.protobuf.IStringValue|null} [date] INFInputValueLeaf date
             * @property {string|null} [displayName] INFInputValueLeaf displayName
             * @property {google.protobuf.IDoubleValue|null} [float] INFInputValueLeaf float
             * @property {google.protobuf.IDoubleValue|null} [int] INFInputValueLeaf int
             * @property {google.protobuf.IDoubleValue|null} [money] INFInputValueLeaf money
             * @property {string|null} [name] INFInputValueLeaf name
             * @property {google.protobuf.IStringValue|null} [string] INFInputValueLeaf string
             */
    
            /**
             * Constructs a new INFInputValueLeaf.
             * @memberof infinitusapi
             * @classdesc Represents a INFInputValueLeaf.
             * @implements IINFInputValueLeaf
             * @constructor
             * @param {infinitusapi.IINFInputValueLeaf=} [properties] Properties to set
             */
            function INFInputValueLeaf(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFInputValueLeaf bool.
             * @member {google.protobuf.IBoolValue|null|undefined} bool
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             */
            INFInputValueLeaf.prototype.bool = null;
    
            /**
             * INFInputValueLeaf date.
             * @member {google.protobuf.IStringValue|null|undefined} date
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             */
            INFInputValueLeaf.prototype.date = null;
    
            /**
             * INFInputValueLeaf displayName.
             * @member {string} displayName
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             */
            INFInputValueLeaf.prototype.displayName = "";
    
            /**
             * INFInputValueLeaf float.
             * @member {google.protobuf.IDoubleValue|null|undefined} float
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             */
            INFInputValueLeaf.prototype.float = null;
    
            /**
             * INFInputValueLeaf int.
             * @member {google.protobuf.IDoubleValue|null|undefined} int
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             */
            INFInputValueLeaf.prototype.int = null;
    
            /**
             * INFInputValueLeaf money.
             * @member {google.protobuf.IDoubleValue|null|undefined} money
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             */
            INFInputValueLeaf.prototype.money = null;
    
            /**
             * INFInputValueLeaf name.
             * @member {string} name
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             */
            INFInputValueLeaf.prototype.name = "";
    
            /**
             * INFInputValueLeaf string.
             * @member {google.protobuf.IStringValue|null|undefined} string
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             */
            INFInputValueLeaf.prototype.string = null;
    
            /**
             * Creates a new INFInputValueLeaf instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {infinitusapi.IINFInputValueLeaf=} [properties] Properties to set
             * @returns {infinitusapi.INFInputValueLeaf} INFInputValueLeaf instance
             */
            INFInputValueLeaf.create = function create(properties) {
                return new INFInputValueLeaf(properties);
            };
    
            /**
             * Encodes the specified INFInputValueLeaf message. Does not implicitly {@link infinitusapi.INFInputValueLeaf.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {infinitusapi.IINFInputValueLeaf} message INFInputValueLeaf message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFInputValueLeaf.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bool != null && Object.hasOwnProperty.call(message, "bool"))
                    $root.google.protobuf.BoolValue.encode(message.bool, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    $root.google.protobuf.StringValue.encode(message.date, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
                if (message.float != null && Object.hasOwnProperty.call(message, "float"))
                    $root.google.protobuf.DoubleValue.encode(message.float, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.int != null && Object.hasOwnProperty.call(message, "int"))
                    $root.google.protobuf.DoubleValue.encode(message.int, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.money != null && Object.hasOwnProperty.call(message, "money"))
                    $root.google.protobuf.DoubleValue.encode(message.money, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
                if (message.string != null && Object.hasOwnProperty.call(message, "string"))
                    $root.google.protobuf.StringValue.encode(message.string, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFInputValueLeaf message, length delimited. Does not implicitly {@link infinitusapi.INFInputValueLeaf.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {infinitusapi.IINFInputValueLeaf} message INFInputValueLeaf message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFInputValueLeaf.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFInputValueLeaf message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFInputValueLeaf} INFInputValueLeaf
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFInputValueLeaf.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFInputValueLeaf();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.bool = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.date = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.displayName = reader.string();
                            break;
                        }
                    case 4: {
                            message.float = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.int = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.money = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.name = reader.string();
                            break;
                        }
                    case 8: {
                            message.string = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFInputValueLeaf message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFInputValueLeaf} INFInputValueLeaf
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFInputValueLeaf.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFInputValueLeaf message.
             * @function verify
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFInputValueLeaf.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.bool != null && message.hasOwnProperty("bool")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.bool);
                    if (error)
                        return "bool." + error;
                }
                if (message.date != null && message.hasOwnProperty("date")) {
                    var error = $root.google.protobuf.StringValue.verify(message.date);
                    if (error)
                        return "date." + error;
                }
                if (message.displayName != null && message.hasOwnProperty("displayName"))
                    if (!$util.isString(message.displayName))
                        return "displayName: string expected";
                if (message.float != null && message.hasOwnProperty("float")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.float);
                    if (error)
                        return "float." + error;
                }
                if (message.int != null && message.hasOwnProperty("int")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.int);
                    if (error)
                        return "int." + error;
                }
                if (message.money != null && message.hasOwnProperty("money")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.money);
                    if (error)
                        return "money." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.string != null && message.hasOwnProperty("string")) {
                    var error = $root.google.protobuf.StringValue.verify(message.string);
                    if (error)
                        return "string." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFInputValueLeaf message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFInputValueLeaf} INFInputValueLeaf
             */
            INFInputValueLeaf.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFInputValueLeaf)
                    return object;
                var message = new $root.infinitusapi.INFInputValueLeaf();
                if (object.bool != null) {
                    if (typeof object.bool !== "object")
                        throw TypeError(".infinitusapi.INFInputValueLeaf.bool: object expected");
                    message.bool = $root.google.protobuf.BoolValue.fromObject(object.bool);
                }
                if (object.date != null) {
                    if (typeof object.date !== "object")
                        throw TypeError(".infinitusapi.INFInputValueLeaf.date: object expected");
                    message.date = $root.google.protobuf.StringValue.fromObject(object.date);
                }
                if (object.displayName != null)
                    message.displayName = String(object.displayName);
                if (object.float != null) {
                    if (typeof object.float !== "object")
                        throw TypeError(".infinitusapi.INFInputValueLeaf.float: object expected");
                    message.float = $root.google.protobuf.DoubleValue.fromObject(object.float);
                }
                if (object.int != null) {
                    if (typeof object.int !== "object")
                        throw TypeError(".infinitusapi.INFInputValueLeaf.int: object expected");
                    message.int = $root.google.protobuf.DoubleValue.fromObject(object.int);
                }
                if (object.money != null) {
                    if (typeof object.money !== "object")
                        throw TypeError(".infinitusapi.INFInputValueLeaf.money: object expected");
                    message.money = $root.google.protobuf.DoubleValue.fromObject(object.money);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.string != null) {
                    if (typeof object.string !== "object")
                        throw TypeError(".infinitusapi.INFInputValueLeaf.string: object expected");
                    message.string = $root.google.protobuf.StringValue.fromObject(object.string);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFInputValueLeaf message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {infinitusapi.INFInputValueLeaf} message INFInputValueLeaf
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFInputValueLeaf.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.bool = null;
                    object.date = null;
                    object.displayName = "";
                    object.float = null;
                    object.int = null;
                    object.money = null;
                    object.name = "";
                    object.string = null;
                }
                if (message.bool != null && message.hasOwnProperty("bool"))
                    object.bool = $root.google.protobuf.BoolValue.toObject(message.bool, options);
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = $root.google.protobuf.StringValue.toObject(message.date, options);
                if (message.displayName != null && message.hasOwnProperty("displayName"))
                    object.displayName = message.displayName;
                if (message.float != null && message.hasOwnProperty("float"))
                    object.float = $root.google.protobuf.DoubleValue.toObject(message.float, options);
                if (message.int != null && message.hasOwnProperty("int"))
                    object.int = $root.google.protobuf.DoubleValue.toObject(message.int, options);
                if (message.money != null && message.hasOwnProperty("money"))
                    object.money = $root.google.protobuf.DoubleValue.toObject(message.money, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.string != null && message.hasOwnProperty("string"))
                    object.string = $root.google.protobuf.StringValue.toObject(message.string, options);
                return object;
            };
    
            /**
             * Converts this INFInputValueLeaf to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFInputValueLeaf
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFInputValueLeaf.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFInputValueLeaf
             * @function getTypeUrl
             * @memberof infinitusapi.INFInputValueLeaf
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFInputValueLeaf.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFInputValueLeaf";
            };
    
            return INFInputValueLeaf;
        })();
    
        infinitusapi.INFInputValueNestedFields = (function() {
    
            /**
             * Properties of a INFInputValueNestedFields.
             * @memberof infinitusapi
             * @interface IINFInputValueNestedFields
             * @property {string|null} [displayName] INFInputValueNestedFields displayName
             * @property {string|null} [name] INFInputValueNestedFields name
             * @property {Array.<infinitusapi.IINFInputValueLeaf>|null} [values] INFInputValueNestedFields values
             */
    
            /**
             * Constructs a new INFInputValueNestedFields.
             * @memberof infinitusapi
             * @classdesc Represents a INFInputValueNestedFields.
             * @implements IINFInputValueNestedFields
             * @constructor
             * @param {infinitusapi.IINFInputValueNestedFields=} [properties] Properties to set
             */
            function INFInputValueNestedFields(properties) {
                this.values = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFInputValueNestedFields displayName.
             * @member {string} displayName
             * @memberof infinitusapi.INFInputValueNestedFields
             * @instance
             */
            INFInputValueNestedFields.prototype.displayName = "";
    
            /**
             * INFInputValueNestedFields name.
             * @member {string} name
             * @memberof infinitusapi.INFInputValueNestedFields
             * @instance
             */
            INFInputValueNestedFields.prototype.name = "";
    
            /**
             * INFInputValueNestedFields values.
             * @member {Array.<infinitusapi.IINFInputValueLeaf>} values
             * @memberof infinitusapi.INFInputValueNestedFields
             * @instance
             */
            INFInputValueNestedFields.prototype.values = $util.emptyArray;
    
            /**
             * Creates a new INFInputValueNestedFields instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {infinitusapi.IINFInputValueNestedFields=} [properties] Properties to set
             * @returns {infinitusapi.INFInputValueNestedFields} INFInputValueNestedFields instance
             */
            INFInputValueNestedFields.create = function create(properties) {
                return new INFInputValueNestedFields(properties);
            };
    
            /**
             * Encodes the specified INFInputValueNestedFields message. Does not implicitly {@link infinitusapi.INFInputValueNestedFields.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {infinitusapi.IINFInputValueNestedFields} message INFInputValueNestedFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFInputValueNestedFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.displayName);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.values != null && message.values.length)
                    for (var i = 0; i < message.values.length; ++i)
                        $root.infinitusapi.INFInputValueLeaf.encode(message.values[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFInputValueNestedFields message, length delimited. Does not implicitly {@link infinitusapi.INFInputValueNestedFields.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {infinitusapi.IINFInputValueNestedFields} message INFInputValueNestedFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFInputValueNestedFields.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFInputValueNestedFields message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFInputValueNestedFields} INFInputValueNestedFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFInputValueNestedFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFInputValueNestedFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.displayName = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.values && message.values.length))
                                message.values = [];
                            message.values.push($root.infinitusapi.INFInputValueLeaf.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFInputValueNestedFields message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFInputValueNestedFields} INFInputValueNestedFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFInputValueNestedFields.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFInputValueNestedFields message.
             * @function verify
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFInputValueNestedFields.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.displayName != null && message.hasOwnProperty("displayName"))
                    if (!$util.isString(message.displayName))
                        return "displayName: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.values != null && message.hasOwnProperty("values")) {
                    if (!Array.isArray(message.values))
                        return "values: array expected";
                    for (var i = 0; i < message.values.length; ++i) {
                        var error = $root.infinitusapi.INFInputValueLeaf.verify(message.values[i]);
                        if (error)
                            return "values." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a INFInputValueNestedFields message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFInputValueNestedFields} INFInputValueNestedFields
             */
            INFInputValueNestedFields.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFInputValueNestedFields)
                    return object;
                var message = new $root.infinitusapi.INFInputValueNestedFields();
                if (object.displayName != null)
                    message.displayName = String(object.displayName);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.values) {
                    if (!Array.isArray(object.values))
                        throw TypeError(".infinitusapi.INFInputValueNestedFields.values: array expected");
                    message.values = [];
                    for (var i = 0; i < object.values.length; ++i) {
                        if (typeof object.values[i] !== "object")
                            throw TypeError(".infinitusapi.INFInputValueNestedFields.values: object expected");
                        message.values[i] = $root.infinitusapi.INFInputValueLeaf.fromObject(object.values[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFInputValueNestedFields message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {infinitusapi.INFInputValueNestedFields} message INFInputValueNestedFields
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFInputValueNestedFields.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.values = [];
                if (options.defaults) {
                    object.displayName = "";
                    object.name = "";
                }
                if (message.displayName != null && message.hasOwnProperty("displayName"))
                    object.displayName = message.displayName;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.values && message.values.length) {
                    object.values = [];
                    for (var j = 0; j < message.values.length; ++j)
                        object.values[j] = $root.infinitusapi.INFInputValueLeaf.toObject(message.values[j], options);
                }
                return object;
            };
    
            /**
             * Converts this INFInputValueNestedFields to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFInputValueNestedFields
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFInputValueNestedFields.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFInputValueNestedFields
             * @function getTypeUrl
             * @memberof infinitusapi.INFInputValueNestedFields
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFInputValueNestedFields.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFInputValueNestedFields";
            };
    
            return INFInputValueNestedFields;
        })();
    
        infinitusapi.INFMedicarePartD = (function() {
    
            /**
             * Properties of a INFMedicarePartD.
             * @memberof infinitusapi
             * @interface IINFMedicarePartD
             * @property {google.protobuf.IDoubleValue|null} [catastrophicCoinsurance] INFMedicarePartD catastrophicCoinsurance
             * @property {google.protobuf.IDoubleValue|null} [catastrophicCopay] INFMedicarePartD catastrophicCopay
             * @property {google.protobuf.IDoubleValue|null} [coverageGapCoinsurance] INFMedicarePartD coverageGapCoinsurance
             * @property {google.protobuf.IDoubleValue|null} [coverageGapCopay] INFMedicarePartD coverageGapCopay
             * @property {infinitusapi.INFPartDCurrentStage|null} [partDCurrentStage] INFMedicarePartD partDCurrentStage
             * @property {google.protobuf.IDoubleValue|null} [troopMetAmount] INFMedicarePartD troopMetAmount
             */
    
            /**
             * Constructs a new INFMedicarePartD.
             * @memberof infinitusapi
             * @classdesc Represents a INFMedicarePartD.
             * @implements IINFMedicarePartD
             * @constructor
             * @param {infinitusapi.IINFMedicarePartD=} [properties] Properties to set
             */
            function INFMedicarePartD(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFMedicarePartD catastrophicCoinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} catastrophicCoinsurance
             * @memberof infinitusapi.INFMedicarePartD
             * @instance
             */
            INFMedicarePartD.prototype.catastrophicCoinsurance = null;
    
            /**
             * INFMedicarePartD catastrophicCopay.
             * @member {google.protobuf.IDoubleValue|null|undefined} catastrophicCopay
             * @memberof infinitusapi.INFMedicarePartD
             * @instance
             */
            INFMedicarePartD.prototype.catastrophicCopay = null;
    
            /**
             * INFMedicarePartD coverageGapCoinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} coverageGapCoinsurance
             * @memberof infinitusapi.INFMedicarePartD
             * @instance
             */
            INFMedicarePartD.prototype.coverageGapCoinsurance = null;
    
            /**
             * INFMedicarePartD coverageGapCopay.
             * @member {google.protobuf.IDoubleValue|null|undefined} coverageGapCopay
             * @memberof infinitusapi.INFMedicarePartD
             * @instance
             */
            INFMedicarePartD.prototype.coverageGapCopay = null;
    
            /**
             * INFMedicarePartD partDCurrentStage.
             * @member {infinitusapi.INFPartDCurrentStage} partDCurrentStage
             * @memberof infinitusapi.INFMedicarePartD
             * @instance
             */
            INFMedicarePartD.prototype.partDCurrentStage = 0;
    
            /**
             * INFMedicarePartD troopMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} troopMetAmount
             * @memberof infinitusapi.INFMedicarePartD
             * @instance
             */
            INFMedicarePartD.prototype.troopMetAmount = null;
    
            /**
             * Creates a new INFMedicarePartD instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {infinitusapi.IINFMedicarePartD=} [properties] Properties to set
             * @returns {infinitusapi.INFMedicarePartD} INFMedicarePartD instance
             */
            INFMedicarePartD.create = function create(properties) {
                return new INFMedicarePartD(properties);
            };
    
            /**
             * Encodes the specified INFMedicarePartD message. Does not implicitly {@link infinitusapi.INFMedicarePartD.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {infinitusapi.IINFMedicarePartD} message INFMedicarePartD message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFMedicarePartD.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.catastrophicCoinsurance != null && Object.hasOwnProperty.call(message, "catastrophicCoinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.catastrophicCoinsurance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.catastrophicCopay != null && Object.hasOwnProperty.call(message, "catastrophicCopay"))
                    $root.google.protobuf.DoubleValue.encode(message.catastrophicCopay, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.coverageGapCoinsurance != null && Object.hasOwnProperty.call(message, "coverageGapCoinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.coverageGapCoinsurance, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.coverageGapCopay != null && Object.hasOwnProperty.call(message, "coverageGapCopay"))
                    $root.google.protobuf.DoubleValue.encode(message.coverageGapCopay, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.partDCurrentStage != null && Object.hasOwnProperty.call(message, "partDCurrentStage"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.partDCurrentStage);
                if (message.troopMetAmount != null && Object.hasOwnProperty.call(message, "troopMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.troopMetAmount, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFMedicarePartD message, length delimited. Does not implicitly {@link infinitusapi.INFMedicarePartD.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {infinitusapi.IINFMedicarePartD} message INFMedicarePartD message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFMedicarePartD.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFMedicarePartD message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFMedicarePartD} INFMedicarePartD
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFMedicarePartD.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFMedicarePartD();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.catastrophicCoinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.catastrophicCopay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.coverageGapCoinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.coverageGapCopay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.partDCurrentStage = reader.int32();
                            break;
                        }
                    case 6: {
                            message.troopMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFMedicarePartD message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFMedicarePartD} INFMedicarePartD
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFMedicarePartD.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFMedicarePartD message.
             * @function verify
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFMedicarePartD.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.catastrophicCoinsurance != null && message.hasOwnProperty("catastrophicCoinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.catastrophicCoinsurance);
                    if (error)
                        return "catastrophicCoinsurance." + error;
                }
                if (message.catastrophicCopay != null && message.hasOwnProperty("catastrophicCopay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.catastrophicCopay);
                    if (error)
                        return "catastrophicCopay." + error;
                }
                if (message.coverageGapCoinsurance != null && message.hasOwnProperty("coverageGapCoinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.coverageGapCoinsurance);
                    if (error)
                        return "coverageGapCoinsurance." + error;
                }
                if (message.coverageGapCopay != null && message.hasOwnProperty("coverageGapCopay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.coverageGapCopay);
                    if (error)
                        return "coverageGapCopay." + error;
                }
                if (message.partDCurrentStage != null && message.hasOwnProperty("partDCurrentStage"))
                    switch (message.partDCurrentStage) {
                    default:
                        return "partDCurrentStage: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.troopMetAmount != null && message.hasOwnProperty("troopMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.troopMetAmount);
                    if (error)
                        return "troopMetAmount." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFMedicarePartD message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFMedicarePartD} INFMedicarePartD
             */
            INFMedicarePartD.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFMedicarePartD)
                    return object;
                var message = new $root.infinitusapi.INFMedicarePartD();
                if (object.catastrophicCoinsurance != null) {
                    if (typeof object.catastrophicCoinsurance !== "object")
                        throw TypeError(".infinitusapi.INFMedicarePartD.catastrophicCoinsurance: object expected");
                    message.catastrophicCoinsurance = $root.google.protobuf.DoubleValue.fromObject(object.catastrophicCoinsurance);
                }
                if (object.catastrophicCopay != null) {
                    if (typeof object.catastrophicCopay !== "object")
                        throw TypeError(".infinitusapi.INFMedicarePartD.catastrophicCopay: object expected");
                    message.catastrophicCopay = $root.google.protobuf.DoubleValue.fromObject(object.catastrophicCopay);
                }
                if (object.coverageGapCoinsurance != null) {
                    if (typeof object.coverageGapCoinsurance !== "object")
                        throw TypeError(".infinitusapi.INFMedicarePartD.coverageGapCoinsurance: object expected");
                    message.coverageGapCoinsurance = $root.google.protobuf.DoubleValue.fromObject(object.coverageGapCoinsurance);
                }
                if (object.coverageGapCopay != null) {
                    if (typeof object.coverageGapCopay !== "object")
                        throw TypeError(".infinitusapi.INFMedicarePartD.coverageGapCopay: object expected");
                    message.coverageGapCopay = $root.google.protobuf.DoubleValue.fromObject(object.coverageGapCopay);
                }
                switch (object.partDCurrentStage) {
                default:
                    if (typeof object.partDCurrentStage === "number") {
                        message.partDCurrentStage = object.partDCurrentStage;
                        break;
                    }
                    break;
                case "INF_PART_D_STAGE_UNKNOWN":
                case 0:
                    message.partDCurrentStage = 0;
                    break;
                case "INF_PART_D_STAGE_INITIAL_COVERAGE":
                case 1:
                    message.partDCurrentStage = 1;
                    break;
                case "INF_PART_D_STAGE_COVERAGE_GAP":
                case 2:
                    message.partDCurrentStage = 2;
                    break;
                case "INF_PART_D_STAGE_CATASTROPHIC":
                case 3:
                    message.partDCurrentStage = 3;
                    break;
                case "INF_PART_D_STAGE_NOT_APPLICABLE":
                case 4:
                    message.partDCurrentStage = 4;
                    break;
                }
                if (object.troopMetAmount != null) {
                    if (typeof object.troopMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFMedicarePartD.troopMetAmount: object expected");
                    message.troopMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.troopMetAmount);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFMedicarePartD message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {infinitusapi.INFMedicarePartD} message INFMedicarePartD
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFMedicarePartD.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.catastrophicCoinsurance = null;
                    object.catastrophicCopay = null;
                    object.coverageGapCoinsurance = null;
                    object.coverageGapCopay = null;
                    object.partDCurrentStage = options.enums === String ? "INF_PART_D_STAGE_UNKNOWN" : 0;
                    object.troopMetAmount = null;
                }
                if (message.catastrophicCoinsurance != null && message.hasOwnProperty("catastrophicCoinsurance"))
                    object.catastrophicCoinsurance = $root.google.protobuf.DoubleValue.toObject(message.catastrophicCoinsurance, options);
                if (message.catastrophicCopay != null && message.hasOwnProperty("catastrophicCopay"))
                    object.catastrophicCopay = $root.google.protobuf.DoubleValue.toObject(message.catastrophicCopay, options);
                if (message.coverageGapCoinsurance != null && message.hasOwnProperty("coverageGapCoinsurance"))
                    object.coverageGapCoinsurance = $root.google.protobuf.DoubleValue.toObject(message.coverageGapCoinsurance, options);
                if (message.coverageGapCopay != null && message.hasOwnProperty("coverageGapCopay"))
                    object.coverageGapCopay = $root.google.protobuf.DoubleValue.toObject(message.coverageGapCopay, options);
                if (message.partDCurrentStage != null && message.hasOwnProperty("partDCurrentStage"))
                    object.partDCurrentStage = options.enums === String ? $root.infinitusapi.INFPartDCurrentStage[message.partDCurrentStage] === undefined ? message.partDCurrentStage : $root.infinitusapi.INFPartDCurrentStage[message.partDCurrentStage] : message.partDCurrentStage;
                if (message.troopMetAmount != null && message.hasOwnProperty("troopMetAmount"))
                    object.troopMetAmount = $root.google.protobuf.DoubleValue.toObject(message.troopMetAmount, options);
                return object;
            };
    
            /**
             * Converts this INFMedicarePartD to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFMedicarePartD
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFMedicarePartD.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFMedicarePartD
             * @function getTypeUrl
             * @memberof infinitusapi.INFMedicarePartD
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFMedicarePartD.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFMedicarePartD";
            };
    
            return INFMedicarePartD;
        })();
    
        infinitusapi.INFOutputValue = (function() {
    
            /**
             * Properties of a INFOutputValue.
             * @memberof infinitusapi
             * @interface IINFOutputValue
             * @property {google.protobuf.IDoubleValue|null} [amountInDollarsValue] INFOutputValue amountInDollarsValue
             * @property {google.protobuf.IBoolValue|null} [boolValue] INFOutputValue boolValue
             * @property {string|null} [category] INFOutputValue category
             * @property {string|null} [dateValue] INFOutputValue dateValue
             * @property {string|null} [description] INFOutputValue description
             * @property {google.protobuf.IDoubleValue|null} [intValue] INFOutputValue intValue
             * @property {string|null} [name] INFOutputValue name
             * @property {string|null} [stringValue] INFOutputValue stringValue
             * @property {string|null} [type] INFOutputValue type
             */
    
            /**
             * Constructs a new INFOutputValue.
             * @memberof infinitusapi
             * @classdesc Represents a INFOutputValue.
             * @implements IINFOutputValue
             * @constructor
             * @param {infinitusapi.IINFOutputValue=} [properties] Properties to set
             */
            function INFOutputValue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFOutputValue amountInDollarsValue.
             * @member {google.protobuf.IDoubleValue|null|undefined} amountInDollarsValue
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.amountInDollarsValue = null;
    
            /**
             * INFOutputValue boolValue.
             * @member {google.protobuf.IBoolValue|null|undefined} boolValue
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.boolValue = null;
    
            /**
             * INFOutputValue category.
             * @member {string} category
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.category = "";
    
            /**
             * INFOutputValue dateValue.
             * @member {string} dateValue
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.dateValue = "";
    
            /**
             * INFOutputValue description.
             * @member {string} description
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.description = "";
    
            /**
             * INFOutputValue intValue.
             * @member {google.protobuf.IDoubleValue|null|undefined} intValue
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.intValue = null;
    
            /**
             * INFOutputValue name.
             * @member {string} name
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.name = "";
    
            /**
             * INFOutputValue stringValue.
             * @member {string} stringValue
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.stringValue = "";
    
            /**
             * INFOutputValue type.
             * @member {string} type
             * @memberof infinitusapi.INFOutputValue
             * @instance
             */
            INFOutputValue.prototype.type = "";
    
            /**
             * Creates a new INFOutputValue instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {infinitusapi.IINFOutputValue=} [properties] Properties to set
             * @returns {infinitusapi.INFOutputValue} INFOutputValue instance
             */
            INFOutputValue.create = function create(properties) {
                return new INFOutputValue(properties);
            };
    
            /**
             * Encodes the specified INFOutputValue message. Does not implicitly {@link infinitusapi.INFOutputValue.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {infinitusapi.IINFOutputValue} message INFOutputValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFOutputValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.amountInDollarsValue != null && Object.hasOwnProperty.call(message, "amountInDollarsValue"))
                    $root.google.protobuf.DoubleValue.encode(message.amountInDollarsValue, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.boolValue != null && Object.hasOwnProperty.call(message, "boolValue"))
                    $root.google.protobuf.BoolValue.encode(message.boolValue, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.category);
                if (message.dateValue != null && Object.hasOwnProperty.call(message, "dateValue"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.dateValue);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
                    $root.google.protobuf.DoubleValue.encode(message.intValue, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.stringValue);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.type);
                return writer;
            };
    
            /**
             * Encodes the specified INFOutputValue message, length delimited. Does not implicitly {@link infinitusapi.INFOutputValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {infinitusapi.IINFOutputValue} message INFOutputValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFOutputValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFOutputValue message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFOutputValue} INFOutputValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFOutputValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFOutputValue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.amountInDollarsValue = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.boolValue = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.category = reader.string();
                            break;
                        }
                    case 4: {
                            message.dateValue = reader.string();
                            break;
                        }
                    case 5: {
                            message.description = reader.string();
                            break;
                        }
                    case 6: {
                            message.intValue = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.name = reader.string();
                            break;
                        }
                    case 8: {
                            message.stringValue = reader.string();
                            break;
                        }
                    case 9: {
                            message.type = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFOutputValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFOutputValue} INFOutputValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFOutputValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFOutputValue message.
             * @function verify
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFOutputValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.amountInDollarsValue != null && message.hasOwnProperty("amountInDollarsValue")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.amountInDollarsValue);
                    if (error)
                        return "amountInDollarsValue." + error;
                }
                if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.boolValue);
                    if (error)
                        return "boolValue." + error;
                }
                if (message.category != null && message.hasOwnProperty("category"))
                    if (!$util.isString(message.category))
                        return "category: string expected";
                if (message.dateValue != null && message.hasOwnProperty("dateValue"))
                    if (!$util.isString(message.dateValue))
                        return "dateValue: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.intValue != null && message.hasOwnProperty("intValue")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.intValue);
                    if (error)
                        return "intValue." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!$util.isString(message.stringValue))
                        return "stringValue: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    if (!$util.isString(message.type))
                        return "type: string expected";
                return null;
            };
    
            /**
             * Creates a INFOutputValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFOutputValue} INFOutputValue
             */
            INFOutputValue.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFOutputValue)
                    return object;
                var message = new $root.infinitusapi.INFOutputValue();
                if (object.amountInDollarsValue != null) {
                    if (typeof object.amountInDollarsValue !== "object")
                        throw TypeError(".infinitusapi.INFOutputValue.amountInDollarsValue: object expected");
                    message.amountInDollarsValue = $root.google.protobuf.DoubleValue.fromObject(object.amountInDollarsValue);
                }
                if (object.boolValue != null) {
                    if (typeof object.boolValue !== "object")
                        throw TypeError(".infinitusapi.INFOutputValue.boolValue: object expected");
                    message.boolValue = $root.google.protobuf.BoolValue.fromObject(object.boolValue);
                }
                if (object.category != null)
                    message.category = String(object.category);
                if (object.dateValue != null)
                    message.dateValue = String(object.dateValue);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.intValue != null) {
                    if (typeof object.intValue !== "object")
                        throw TypeError(".infinitusapi.INFOutputValue.intValue: object expected");
                    message.intValue = $root.google.protobuf.DoubleValue.fromObject(object.intValue);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.stringValue != null)
                    message.stringValue = String(object.stringValue);
                if (object.type != null)
                    message.type = String(object.type);
                return message;
            };
    
            /**
             * Creates a plain object from a INFOutputValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {infinitusapi.INFOutputValue} message INFOutputValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFOutputValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.amountInDollarsValue = null;
                    object.boolValue = null;
                    object.category = "";
                    object.dateValue = "";
                    object.description = "";
                    object.intValue = null;
                    object.name = "";
                    object.stringValue = "";
                    object.type = "";
                }
                if (message.amountInDollarsValue != null && message.hasOwnProperty("amountInDollarsValue"))
                    object.amountInDollarsValue = $root.google.protobuf.DoubleValue.toObject(message.amountInDollarsValue, options);
                if (message.boolValue != null && message.hasOwnProperty("boolValue"))
                    object.boolValue = $root.google.protobuf.BoolValue.toObject(message.boolValue, options);
                if (message.category != null && message.hasOwnProperty("category"))
                    object.category = message.category;
                if (message.dateValue != null && message.hasOwnProperty("dateValue"))
                    object.dateValue = message.dateValue;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.intValue != null && message.hasOwnProperty("intValue"))
                    object.intValue = $root.google.protobuf.DoubleValue.toObject(message.intValue, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = message.stringValue;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = message.type;
                return object;
            };
    
            /**
             * Converts this INFOutputValue to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFOutputValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFOutputValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFOutputValue
             * @function getTypeUrl
             * @memberof infinitusapi.INFOutputValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFOutputValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFOutputValue";
            };
    
            return INFOutputValue;
        })();
    
        infinitusapi.INFPATaskInputCPT = (function() {
    
            /**
             * Properties of a INFPATaskInputCPT.
             * @memberof infinitusapi
             * @interface IINFPATaskInputCPT
             * @property {string|null} [code] INFPATaskInputCPT code
             */
    
            /**
             * Constructs a new INFPATaskInputCPT.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskInputCPT.
             * @implements IINFPATaskInputCPT
             * @constructor
             * @param {infinitusapi.IINFPATaskInputCPT=} [properties] Properties to set
             */
            function INFPATaskInputCPT(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskInputCPT code.
             * @member {string} code
             * @memberof infinitusapi.INFPATaskInputCPT
             * @instance
             */
            INFPATaskInputCPT.prototype.code = "";
    
            /**
             * Creates a new INFPATaskInputCPT instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {infinitusapi.IINFPATaskInputCPT=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskInputCPT} INFPATaskInputCPT instance
             */
            INFPATaskInputCPT.create = function create(properties) {
                return new INFPATaskInputCPT(properties);
            };
    
            /**
             * Encodes the specified INFPATaskInputCPT message. Does not implicitly {@link infinitusapi.INFPATaskInputCPT.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {infinitusapi.IINFPATaskInputCPT} message INFPATaskInputCPT message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputCPT.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskInputCPT message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskInputCPT.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {infinitusapi.IINFPATaskInputCPT} message INFPATaskInputCPT message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputCPT.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskInputCPT message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskInputCPT} INFPATaskInputCPT
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputCPT.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskInputCPT();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskInputCPT message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskInputCPT} INFPATaskInputCPT
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputCPT.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskInputCPT message.
             * @function verify
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskInputCPT.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                return null;
            };
    
            /**
             * Creates a INFPATaskInputCPT message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskInputCPT} INFPATaskInputCPT
             */
            INFPATaskInputCPT.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskInputCPT)
                    return object;
                var message = new $root.infinitusapi.INFPATaskInputCPT();
                if (object.code != null)
                    message.code = String(object.code);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskInputCPT message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {infinitusapi.INFPATaskInputCPT} message INFPATaskInputCPT
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskInputCPT.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.code = "";
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                return object;
            };
    
            /**
             * Converts this INFPATaskInputCPT to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskInputCPT
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskInputCPT.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskInputCPT
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskInputCPT
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskInputCPT.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskInputCPT";
            };
    
            return INFPATaskInputCPT;
        })();
    
        infinitusapi.INFPATaskInputPayer = (function() {
    
            /**
             * Properties of a INFPATaskInputPayer.
             * @memberof infinitusapi
             * @interface IINFPATaskInputPayer
             * @property {string|null} [customerPayerName] INFPATaskInputPayer customerPayerName
             * @property {string|null} [customerPayerPhoneNumber] INFPATaskInputPayer customerPayerPhoneNumber
             * @property {string|null} [infinitusPayerId] INFPATaskInputPayer infinitusPayerId
             * @property {string|null} [subscriberId] INFPATaskInputPayer subscriberId
             */
    
            /**
             * Constructs a new INFPATaskInputPayer.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskInputPayer.
             * @implements IINFPATaskInputPayer
             * @constructor
             * @param {infinitusapi.IINFPATaskInputPayer=} [properties] Properties to set
             */
            function INFPATaskInputPayer(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskInputPayer customerPayerName.
             * @member {string} customerPayerName
             * @memberof infinitusapi.INFPATaskInputPayer
             * @instance
             */
            INFPATaskInputPayer.prototype.customerPayerName = "";
    
            /**
             * INFPATaskInputPayer customerPayerPhoneNumber.
             * @member {string} customerPayerPhoneNumber
             * @memberof infinitusapi.INFPATaskInputPayer
             * @instance
             */
            INFPATaskInputPayer.prototype.customerPayerPhoneNumber = "";
    
            /**
             * INFPATaskInputPayer infinitusPayerId.
             * @member {string} infinitusPayerId
             * @memberof infinitusapi.INFPATaskInputPayer
             * @instance
             */
            INFPATaskInputPayer.prototype.infinitusPayerId = "";
    
            /**
             * INFPATaskInputPayer subscriberId.
             * @member {string} subscriberId
             * @memberof infinitusapi.INFPATaskInputPayer
             * @instance
             */
            INFPATaskInputPayer.prototype.subscriberId = "";
    
            /**
             * Creates a new INFPATaskInputPayer instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {infinitusapi.IINFPATaskInputPayer=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskInputPayer} INFPATaskInputPayer instance
             */
            INFPATaskInputPayer.create = function create(properties) {
                return new INFPATaskInputPayer(properties);
            };
    
            /**
             * Encodes the specified INFPATaskInputPayer message. Does not implicitly {@link infinitusapi.INFPATaskInputPayer.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {infinitusapi.IINFPATaskInputPayer} message INFPATaskInputPayer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputPayer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.customerPayerName != null && Object.hasOwnProperty.call(message, "customerPayerName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerPayerName);
                if (message.customerPayerPhoneNumber != null && Object.hasOwnProperty.call(message, "customerPayerPhoneNumber"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerPayerPhoneNumber);
                if (message.infinitusPayerId != null && Object.hasOwnProperty.call(message, "infinitusPayerId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.infinitusPayerId);
                if (message.subscriberId != null && Object.hasOwnProperty.call(message, "subscriberId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.subscriberId);
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskInputPayer message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskInputPayer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {infinitusapi.IINFPATaskInputPayer} message INFPATaskInputPayer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputPayer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskInputPayer message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskInputPayer} INFPATaskInputPayer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputPayer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskInputPayer();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.customerPayerName = reader.string();
                            break;
                        }
                    case 2: {
                            message.customerPayerPhoneNumber = reader.string();
                            break;
                        }
                    case 3: {
                            message.infinitusPayerId = reader.string();
                            break;
                        }
                    case 4: {
                            message.subscriberId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskInputPayer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskInputPayer} INFPATaskInputPayer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputPayer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskInputPayer message.
             * @function verify
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskInputPayer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    if (!$util.isString(message.customerPayerName))
                        return "customerPayerName: string expected";
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    if (!$util.isString(message.customerPayerPhoneNumber))
                        return "customerPayerPhoneNumber: string expected";
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    if (!$util.isString(message.infinitusPayerId))
                        return "infinitusPayerId: string expected";
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    if (!$util.isString(message.subscriberId))
                        return "subscriberId: string expected";
                return null;
            };
    
            /**
             * Creates a INFPATaskInputPayer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskInputPayer} INFPATaskInputPayer
             */
            INFPATaskInputPayer.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskInputPayer)
                    return object;
                var message = new $root.infinitusapi.INFPATaskInputPayer();
                if (object.customerPayerName != null)
                    message.customerPayerName = String(object.customerPayerName);
                if (object.customerPayerPhoneNumber != null)
                    message.customerPayerPhoneNumber = String(object.customerPayerPhoneNumber);
                if (object.infinitusPayerId != null)
                    message.infinitusPayerId = String(object.infinitusPayerId);
                if (object.subscriberId != null)
                    message.subscriberId = String(object.subscriberId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskInputPayer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {infinitusapi.INFPATaskInputPayer} message INFPATaskInputPayer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskInputPayer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.customerPayerName = "";
                    object.customerPayerPhoneNumber = "";
                    object.infinitusPayerId = "";
                    object.subscriberId = "";
                }
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    object.customerPayerName = message.customerPayerName;
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    object.customerPayerPhoneNumber = message.customerPayerPhoneNumber;
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    object.infinitusPayerId = message.infinitusPayerId;
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    object.subscriberId = message.subscriberId;
                return object;
            };
    
            /**
             * Converts this INFPATaskInputPayer to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskInputPayer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskInputPayer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskInputPayer
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskInputPayer
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskInputPayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskInputPayer";
            };
    
            return INFPATaskInputPayer;
        })();
    
        infinitusapi.INFPATaskInputPlaceOfService = (function() {
    
            /**
             * Properties of a INFPATaskInputPlaceOfService.
             * @memberof infinitusapi
             * @interface IINFPATaskInputPlaceOfService
             * @property {infinitusapi.IINFAddress|null} [address] INFPATaskInputPlaceOfService address
             * @property {infinitusapi.INFFacilityType|null} [facilityType] INFPATaskInputPlaceOfService facilityType
             * @property {string|null} [name] INFPATaskInputPlaceOfService name
             * @property {infinitusapi.INFPlaceOfServiceNetworkStatus|null} [networkStatus] INFPATaskInputPlaceOfService networkStatus
             * @property {string|null} [npi] INFPATaskInputPlaceOfService npi
             * @property {string|null} [taxId] INFPATaskInputPlaceOfService taxId
             */
    
            /**
             * Constructs a new INFPATaskInputPlaceOfService.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskInputPlaceOfService.
             * @implements IINFPATaskInputPlaceOfService
             * @constructor
             * @param {infinitusapi.IINFPATaskInputPlaceOfService=} [properties] Properties to set
             */
            function INFPATaskInputPlaceOfService(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskInputPlaceOfService address.
             * @member {infinitusapi.IINFAddress|null|undefined} address
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @instance
             */
            INFPATaskInputPlaceOfService.prototype.address = null;
    
            /**
             * INFPATaskInputPlaceOfService facilityType.
             * @member {infinitusapi.INFFacilityType} facilityType
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @instance
             */
            INFPATaskInputPlaceOfService.prototype.facilityType = 0;
    
            /**
             * INFPATaskInputPlaceOfService name.
             * @member {string} name
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @instance
             */
            INFPATaskInputPlaceOfService.prototype.name = "";
    
            /**
             * INFPATaskInputPlaceOfService networkStatus.
             * @member {infinitusapi.INFPlaceOfServiceNetworkStatus} networkStatus
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @instance
             */
            INFPATaskInputPlaceOfService.prototype.networkStatus = 0;
    
            /**
             * INFPATaskInputPlaceOfService npi.
             * @member {string} npi
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @instance
             */
            INFPATaskInputPlaceOfService.prototype.npi = "";
    
            /**
             * INFPATaskInputPlaceOfService taxId.
             * @member {string} taxId
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @instance
             */
            INFPATaskInputPlaceOfService.prototype.taxId = "";
    
            /**
             * Creates a new INFPATaskInputPlaceOfService instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {infinitusapi.IINFPATaskInputPlaceOfService=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskInputPlaceOfService} INFPATaskInputPlaceOfService instance
             */
            INFPATaskInputPlaceOfService.create = function create(properties) {
                return new INFPATaskInputPlaceOfService(properties);
            };
    
            /**
             * Encodes the specified INFPATaskInputPlaceOfService message. Does not implicitly {@link infinitusapi.INFPATaskInputPlaceOfService.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {infinitusapi.IINFPATaskInputPlaceOfService} message INFPATaskInputPlaceOfService message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputPlaceOfService.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.infinitusapi.INFAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.facilityType != null && Object.hasOwnProperty.call(message, "facilityType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.facilityType);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.networkStatus);
                if (message.npi != null && Object.hasOwnProperty.call(message, "npi"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.npi);
                if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.taxId);
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskInputPlaceOfService message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskInputPlaceOfService.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {infinitusapi.IINFPATaskInputPlaceOfService} message INFPATaskInputPlaceOfService message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputPlaceOfService.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskInputPlaceOfService message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskInputPlaceOfService} INFPATaskInputPlaceOfService
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputPlaceOfService.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskInputPlaceOfService();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.facilityType = reader.int32();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.networkStatus = reader.int32();
                            break;
                        }
                    case 5: {
                            message.npi = reader.string();
                            break;
                        }
                    case 6: {
                            message.taxId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskInputPlaceOfService message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskInputPlaceOfService} INFPATaskInputPlaceOfService
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputPlaceOfService.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskInputPlaceOfService message.
             * @function verify
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskInputPlaceOfService.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                    switch (message.facilityType) {
                    default:
                        return "facilityType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                    switch (message.networkStatus) {
                    default:
                        return "networkStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.npi != null && message.hasOwnProperty("npi"))
                    if (!$util.isString(message.npi))
                        return "npi: string expected";
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    if (!$util.isString(message.taxId))
                        return "taxId: string expected";
                return null;
            };
    
            /**
             * Creates a INFPATaskInputPlaceOfService message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskInputPlaceOfService} INFPATaskInputPlaceOfService
             */
            INFPATaskInputPlaceOfService.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskInputPlaceOfService)
                    return object;
                var message = new $root.infinitusapi.INFPATaskInputPlaceOfService();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".infinitusapi.INFPATaskInputPlaceOfService.address: object expected");
                    message.address = $root.infinitusapi.INFAddress.fromObject(object.address);
                }
                switch (object.facilityType) {
                default:
                    if (typeof object.facilityType === "number") {
                        message.facilityType = object.facilityType;
                        break;
                    }
                    break;
                case "INF_FACILITY_TYPE_UNKNOWN":
                case 0:
                    message.facilityType = 0;
                    break;
                case "INF_FACILITY_TYPE_SPECIALIST_OFFICE":
                case 1:
                    message.facilityType = 1;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT":
                case 2:
                    message.facilityType = 2;
                    break;
                case "INF_FACILITY_TYPE_PHARMACY":
                case 3:
                    message.facilityType = 3;
                    break;
                case "INF_FACILITY_TYPE_SHIP_TO_HOME":
                case 4:
                    message.facilityType = 4;
                    break;
                case "INF_FACILITY_TYPE_INFUSION_CENTER":
                case 5:
                    message.facilityType = 5;
                    break;
                case "INF_FACILITY_TYPE_AMBULATORY_SURGICAL_CENTER":
                case 6:
                    message.facilityType = 6;
                    break;
                case "INF_FACILITY_TYPE_HOME_INFUSION":
                case 7:
                    message.facilityType = 7;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT_OFF_CAMPUS":
                case 8:
                    message.facilityType = 8;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_INPATIENT":
                case 9:
                    message.facilityType = 9;
                    break;
                case "INF_FACILITY_TYPE_SNF":
                case 10:
                    message.facilityType = 10;
                    break;
                case "INF_FACILITY_TYPE_HOSPICE":
                case 11:
                    message.facilityType = 11;
                    break;
                case "INF_FACILITY_TYPE_IHC":
                case 12:
                    message.facilityType = 12;
                    break;
                case "INF_FACILITY_TYPE_FED_QUALIFIED_HC":
                case 13:
                    message.facilityType = 13;
                    break;
                case "INF_FACILITY_TYPE_INPATIENT_REHAB":
                case 14:
                    message.facilityType = 14;
                    break;
                case "INF_FACILITY_TYPE_OUTPATIENT_REHAB":
                case 15:
                    message.facilityType = 15;
                    break;
                case "INF_FACILITY_TYPE_PUBLIC_HEALTH_CLINIC":
                case 16:
                    message.facilityType = 16;
                    break;
                case "INF_FACILITY_TYPE_RURAL_HEALTH_CLINIC":
                case 17:
                    message.facilityType = 17;
                    break;
                case "INF_FACILITY_TYPE_DIAGNOSTIC_TEST_LAB":
                case 18:
                    message.facilityType = 18;
                    break;
                }
                if (object.name != null)
                    message.name = String(object.name);
                switch (object.networkStatus) {
                default:
                    if (typeof object.networkStatus === "number") {
                        message.networkStatus = object.networkStatus;
                        break;
                    }
                    break;
                case "INF_PLACE_OF_SERVICE_NETWORK_STATUS_UNKNOWN":
                case 0:
                    message.networkStatus = 0;
                    break;
                case "INF_PLACE_OF_SERVICE_NETWORK_STATUS_IN_NETWORK":
                case 1:
                    message.networkStatus = 1;
                    break;
                case "INF_PLACE_OF_SERVICE_NETWORK_STATUS_OUT_OF_NETWORK":
                case 2:
                    message.networkStatus = 2;
                    break;
                }
                if (object.npi != null)
                    message.npi = String(object.npi);
                if (object.taxId != null)
                    message.taxId = String(object.taxId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskInputPlaceOfService message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {infinitusapi.INFPATaskInputPlaceOfService} message INFPATaskInputPlaceOfService
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskInputPlaceOfService.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = null;
                    object.facilityType = options.enums === String ? "INF_FACILITY_TYPE_UNKNOWN" : 0;
                    object.name = "";
                    object.networkStatus = options.enums === String ? "INF_PLACE_OF_SERVICE_NETWORK_STATUS_UNKNOWN" : 0;
                    object.npi = "";
                    object.taxId = "";
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.infinitusapi.INFAddress.toObject(message.address, options);
                if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                    object.facilityType = options.enums === String ? $root.infinitusapi.INFFacilityType[message.facilityType] === undefined ? message.facilityType : $root.infinitusapi.INFFacilityType[message.facilityType] : message.facilityType;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                    object.networkStatus = options.enums === String ? $root.infinitusapi.INFPlaceOfServiceNetworkStatus[message.networkStatus] === undefined ? message.networkStatus : $root.infinitusapi.INFPlaceOfServiceNetworkStatus[message.networkStatus] : message.networkStatus;
                if (message.npi != null && message.hasOwnProperty("npi"))
                    object.npi = message.npi;
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    object.taxId = message.taxId;
                return object;
            };
    
            /**
             * Converts this INFPATaskInputPlaceOfService to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskInputPlaceOfService.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskInputPlaceOfService
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskInputPlaceOfService
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskInputPlaceOfService.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskInputPlaceOfService";
            };
    
            return INFPATaskInputPlaceOfService;
        })();
    
        infinitusapi.INFPATaskInputPriorAuthInfo = (function() {
    
            /**
             * Properties of a INFPATaskInputPriorAuthInfo.
             * @memberof infinitusapi
             * @interface IINFPATaskInputPriorAuthInfo
             * @property {string|null} [priorAuthExpirationDate] INFPATaskInputPriorAuthInfo priorAuthExpirationDate
             * @property {string|null} [priorAuthNumber] INFPATaskInputPriorAuthInfo priorAuthNumber
             * @property {string|null} [testName] INFPATaskInputPriorAuthInfo testName
             * @property {string|null} [treatmentDate] INFPATaskInputPriorAuthInfo treatmentDate
             */
    
            /**
             * Constructs a new INFPATaskInputPriorAuthInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskInputPriorAuthInfo.
             * @implements IINFPATaskInputPriorAuthInfo
             * @constructor
             * @param {infinitusapi.IINFPATaskInputPriorAuthInfo=} [properties] Properties to set
             */
            function INFPATaskInputPriorAuthInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskInputPriorAuthInfo priorAuthExpirationDate.
             * @member {string} priorAuthExpirationDate
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @instance
             */
            INFPATaskInputPriorAuthInfo.prototype.priorAuthExpirationDate = "";
    
            /**
             * INFPATaskInputPriorAuthInfo priorAuthNumber.
             * @member {string} priorAuthNumber
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @instance
             */
            INFPATaskInputPriorAuthInfo.prototype.priorAuthNumber = "";
    
            /**
             * INFPATaskInputPriorAuthInfo testName.
             * @member {string} testName
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @instance
             */
            INFPATaskInputPriorAuthInfo.prototype.testName = "";
    
            /**
             * INFPATaskInputPriorAuthInfo treatmentDate.
             * @member {string} treatmentDate
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @instance
             */
            INFPATaskInputPriorAuthInfo.prototype.treatmentDate = "";
    
            /**
             * Creates a new INFPATaskInputPriorAuthInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {infinitusapi.IINFPATaskInputPriorAuthInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskInputPriorAuthInfo} INFPATaskInputPriorAuthInfo instance
             */
            INFPATaskInputPriorAuthInfo.create = function create(properties) {
                return new INFPATaskInputPriorAuthInfo(properties);
            };
    
            /**
             * Encodes the specified INFPATaskInputPriorAuthInfo message. Does not implicitly {@link infinitusapi.INFPATaskInputPriorAuthInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {infinitusapi.IINFPATaskInputPriorAuthInfo} message INFPATaskInputPriorAuthInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputPriorAuthInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.priorAuthExpirationDate != null && Object.hasOwnProperty.call(message, "priorAuthExpirationDate"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.priorAuthExpirationDate);
                if (message.priorAuthNumber != null && Object.hasOwnProperty.call(message, "priorAuthNumber"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.priorAuthNumber);
                if (message.testName != null && Object.hasOwnProperty.call(message, "testName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.testName);
                if (message.treatmentDate != null && Object.hasOwnProperty.call(message, "treatmentDate"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.treatmentDate);
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskInputPriorAuthInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskInputPriorAuthInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {infinitusapi.IINFPATaskInputPriorAuthInfo} message INFPATaskInputPriorAuthInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputPriorAuthInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskInputPriorAuthInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskInputPriorAuthInfo} INFPATaskInputPriorAuthInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputPriorAuthInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskInputPriorAuthInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.priorAuthExpirationDate = reader.string();
                            break;
                        }
                    case 2: {
                            message.priorAuthNumber = reader.string();
                            break;
                        }
                    case 3: {
                            message.testName = reader.string();
                            break;
                        }
                    case 4: {
                            message.treatmentDate = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskInputPriorAuthInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskInputPriorAuthInfo} INFPATaskInputPriorAuthInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputPriorAuthInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskInputPriorAuthInfo message.
             * @function verify
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskInputPriorAuthInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.priorAuthExpirationDate != null && message.hasOwnProperty("priorAuthExpirationDate"))
                    if (!$util.isString(message.priorAuthExpirationDate))
                        return "priorAuthExpirationDate: string expected";
                if (message.priorAuthNumber != null && message.hasOwnProperty("priorAuthNumber"))
                    if (!$util.isString(message.priorAuthNumber))
                        return "priorAuthNumber: string expected";
                if (message.testName != null && message.hasOwnProperty("testName"))
                    if (!$util.isString(message.testName))
                        return "testName: string expected";
                if (message.treatmentDate != null && message.hasOwnProperty("treatmentDate"))
                    if (!$util.isString(message.treatmentDate))
                        return "treatmentDate: string expected";
                return null;
            };
    
            /**
             * Creates a INFPATaskInputPriorAuthInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskInputPriorAuthInfo} INFPATaskInputPriorAuthInfo
             */
            INFPATaskInputPriorAuthInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskInputPriorAuthInfo)
                    return object;
                var message = new $root.infinitusapi.INFPATaskInputPriorAuthInfo();
                if (object.priorAuthExpirationDate != null)
                    message.priorAuthExpirationDate = String(object.priorAuthExpirationDate);
                if (object.priorAuthNumber != null)
                    message.priorAuthNumber = String(object.priorAuthNumber);
                if (object.testName != null)
                    message.testName = String(object.testName);
                if (object.treatmentDate != null)
                    message.treatmentDate = String(object.treatmentDate);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskInputPriorAuthInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {infinitusapi.INFPATaskInputPriorAuthInfo} message INFPATaskInputPriorAuthInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskInputPriorAuthInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.priorAuthExpirationDate = "";
                    object.priorAuthNumber = "";
                    object.testName = "";
                    object.treatmentDate = "";
                }
                if (message.priorAuthExpirationDate != null && message.hasOwnProperty("priorAuthExpirationDate"))
                    object.priorAuthExpirationDate = message.priorAuthExpirationDate;
                if (message.priorAuthNumber != null && message.hasOwnProperty("priorAuthNumber"))
                    object.priorAuthNumber = message.priorAuthNumber;
                if (message.testName != null && message.hasOwnProperty("testName"))
                    object.testName = message.testName;
                if (message.treatmentDate != null && message.hasOwnProperty("treatmentDate"))
                    object.treatmentDate = message.treatmentDate;
                return object;
            };
    
            /**
             * Converts this INFPATaskInputPriorAuthInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskInputPriorAuthInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskInputPriorAuthInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskInputPriorAuthInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskInputPriorAuthInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskInputPriorAuthInfo";
            };
    
            return INFPATaskInputPriorAuthInfo;
        })();
    
        infinitusapi.INFPATaskInputProduct = (function() {
    
            /**
             * Properties of a INFPATaskInputProduct.
             * @memberof infinitusapi
             * @interface IINFPATaskInputProduct
             * @property {string|null} [code] INFPATaskInputProduct code
             * @property {string|null} [name] INFPATaskInputProduct name
             */
    
            /**
             * Constructs a new INFPATaskInputProduct.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskInputProduct.
             * @implements IINFPATaskInputProduct
             * @constructor
             * @param {infinitusapi.IINFPATaskInputProduct=} [properties] Properties to set
             */
            function INFPATaskInputProduct(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskInputProduct code.
             * @member {string} code
             * @memberof infinitusapi.INFPATaskInputProduct
             * @instance
             */
            INFPATaskInputProduct.prototype.code = "";
    
            /**
             * INFPATaskInputProduct name.
             * @member {string} name
             * @memberof infinitusapi.INFPATaskInputProduct
             * @instance
             */
            INFPATaskInputProduct.prototype.name = "";
    
            /**
             * Creates a new INFPATaskInputProduct instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {infinitusapi.IINFPATaskInputProduct=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskInputProduct} INFPATaskInputProduct instance
             */
            INFPATaskInputProduct.create = function create(properties) {
                return new INFPATaskInputProduct(properties);
            };
    
            /**
             * Encodes the specified INFPATaskInputProduct message. Does not implicitly {@link infinitusapi.INFPATaskInputProduct.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {infinitusapi.IINFPATaskInputProduct} message INFPATaskInputProduct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputProduct.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskInputProduct message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskInputProduct.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {infinitusapi.IINFPATaskInputProduct} message INFPATaskInputProduct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputProduct.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskInputProduct message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskInputProduct} INFPATaskInputProduct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputProduct.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskInputProduct();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskInputProduct message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskInputProduct} INFPATaskInputProduct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputProduct.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskInputProduct message.
             * @function verify
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskInputProduct.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };
    
            /**
             * Creates a INFPATaskInputProduct message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskInputProduct} INFPATaskInputProduct
             */
            INFPATaskInputProduct.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskInputProduct)
                    return object;
                var message = new $root.infinitusapi.INFPATaskInputProduct();
                if (object.code != null)
                    message.code = String(object.code);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskInputProduct message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {infinitusapi.INFPATaskInputProduct} message INFPATaskInputProduct
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskInputProduct.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.name = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };
    
            /**
             * Converts this INFPATaskInputProduct to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskInputProduct
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskInputProduct.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskInputProduct
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskInputProduct
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskInputProduct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskInputProduct";
            };
    
            return INFPATaskInputProduct;
        })();
    
        infinitusapi.INFPATaskInputProvider = (function() {
    
            /**
             * Properties of a INFPATaskInputProvider.
             * @memberof infinitusapi
             * @interface IINFPATaskInputProvider
             * @property {infinitusapi.IINFAddress|null} [address] INFPATaskInputProvider address
             * @property {string|null} [firstName] INFPATaskInputProvider firstName
             * @property {string|null} [lastName] INFPATaskInputProvider lastName
             * @property {infinitusapi.INFBVTaskInputNetworkStatus|null} [networkStatus] INFPATaskInputProvider networkStatus
             * @property {string|null} [npi] INFPATaskInputProvider npi
             * @property {string|null} [taxId] INFPATaskInputProvider taxId
             */
    
            /**
             * Constructs a new INFPATaskInputProvider.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskInputProvider.
             * @implements IINFPATaskInputProvider
             * @constructor
             * @param {infinitusapi.IINFPATaskInputProvider=} [properties] Properties to set
             */
            function INFPATaskInputProvider(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskInputProvider address.
             * @member {infinitusapi.IINFAddress|null|undefined} address
             * @memberof infinitusapi.INFPATaskInputProvider
             * @instance
             */
            INFPATaskInputProvider.prototype.address = null;
    
            /**
             * INFPATaskInputProvider firstName.
             * @member {string} firstName
             * @memberof infinitusapi.INFPATaskInputProvider
             * @instance
             */
            INFPATaskInputProvider.prototype.firstName = "";
    
            /**
             * INFPATaskInputProvider lastName.
             * @member {string} lastName
             * @memberof infinitusapi.INFPATaskInputProvider
             * @instance
             */
            INFPATaskInputProvider.prototype.lastName = "";
    
            /**
             * INFPATaskInputProvider networkStatus.
             * @member {infinitusapi.INFBVTaskInputNetworkStatus} networkStatus
             * @memberof infinitusapi.INFPATaskInputProvider
             * @instance
             */
            INFPATaskInputProvider.prototype.networkStatus = 0;
    
            /**
             * INFPATaskInputProvider npi.
             * @member {string} npi
             * @memberof infinitusapi.INFPATaskInputProvider
             * @instance
             */
            INFPATaskInputProvider.prototype.npi = "";
    
            /**
             * INFPATaskInputProvider taxId.
             * @member {string} taxId
             * @memberof infinitusapi.INFPATaskInputProvider
             * @instance
             */
            INFPATaskInputProvider.prototype.taxId = "";
    
            /**
             * Creates a new INFPATaskInputProvider instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {infinitusapi.IINFPATaskInputProvider=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskInputProvider} INFPATaskInputProvider instance
             */
            INFPATaskInputProvider.create = function create(properties) {
                return new INFPATaskInputProvider(properties);
            };
    
            /**
             * Encodes the specified INFPATaskInputProvider message. Does not implicitly {@link infinitusapi.INFPATaskInputProvider.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {infinitusapi.IINFPATaskInputProvider} message INFPATaskInputProvider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputProvider.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.infinitusapi.INFAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.networkStatus);
                if (message.npi != null && Object.hasOwnProperty.call(message, "npi"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.npi);
                if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.taxId);
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskInputProvider message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskInputProvider.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {infinitusapi.IINFPATaskInputProvider} message INFPATaskInputProvider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskInputProvider.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskInputProvider message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskInputProvider} INFPATaskInputProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputProvider.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskInputProvider();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.firstName = reader.string();
                            break;
                        }
                    case 3: {
                            message.lastName = reader.string();
                            break;
                        }
                    case 4: {
                            message.networkStatus = reader.int32();
                            break;
                        }
                    case 5: {
                            message.npi = reader.string();
                            break;
                        }
                    case 6: {
                            message.taxId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskInputProvider message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskInputProvider} INFPATaskInputProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskInputProvider.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskInputProvider message.
             * @function verify
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskInputProvider.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    if (!$util.isString(message.firstName))
                        return "firstName: string expected";
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    if (!$util.isString(message.lastName))
                        return "lastName: string expected";
                if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                    switch (message.networkStatus) {
                    default:
                        return "networkStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.npi != null && message.hasOwnProperty("npi"))
                    if (!$util.isString(message.npi))
                        return "npi: string expected";
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    if (!$util.isString(message.taxId))
                        return "taxId: string expected";
                return null;
            };
    
            /**
             * Creates a INFPATaskInputProvider message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskInputProvider} INFPATaskInputProvider
             */
            INFPATaskInputProvider.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskInputProvider)
                    return object;
                var message = new $root.infinitusapi.INFPATaskInputProvider();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".infinitusapi.INFPATaskInputProvider.address: object expected");
                    message.address = $root.infinitusapi.INFAddress.fromObject(object.address);
                }
                if (object.firstName != null)
                    message.firstName = String(object.firstName);
                if (object.lastName != null)
                    message.lastName = String(object.lastName);
                switch (object.networkStatus) {
                default:
                    if (typeof object.networkStatus === "number") {
                        message.networkStatus = object.networkStatus;
                        break;
                    }
                    break;
                case "INF_INPUT_NETWORK_STATUS_UNKNOWN":
                case 0:
                    message.networkStatus = 0;
                    break;
                case "INF_INPUT_NETWORK_STATUS_IN_NETWORK":
                case 1:
                    message.networkStatus = 1;
                    break;
                case "INF_INPUT_NETWORK_STATUS_OUT_OF_NETWORK":
                case 2:
                    message.networkStatus = 2;
                    break;
                }
                if (object.npi != null)
                    message.npi = String(object.npi);
                if (object.taxId != null)
                    message.taxId = String(object.taxId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskInputProvider message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {infinitusapi.INFPATaskInputProvider} message INFPATaskInputProvider
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskInputProvider.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = null;
                    object.firstName = "";
                    object.lastName = "";
                    object.networkStatus = options.enums === String ? "INF_INPUT_NETWORK_STATUS_UNKNOWN" : 0;
                    object.npi = "";
                    object.taxId = "";
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.infinitusapi.INFAddress.toObject(message.address, options);
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    object.firstName = message.firstName;
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    object.lastName = message.lastName;
                if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                    object.networkStatus = options.enums === String ? $root.infinitusapi.INFBVTaskInputNetworkStatus[message.networkStatus] === undefined ? message.networkStatus : $root.infinitusapi.INFBVTaskInputNetworkStatus[message.networkStatus] : message.networkStatus;
                if (message.npi != null && message.hasOwnProperty("npi"))
                    object.npi = message.npi;
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    object.taxId = message.taxId;
                return object;
            };
    
            /**
             * Converts this INFPATaskInputProvider to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskInputProvider
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskInputProvider.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskInputProvider
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskInputProvider
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskInputProvider.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskInputProvider";
            };
    
            return INFPATaskInputProvider;
        })();
    
        infinitusapi.INFPATaskOutputCPTCodePARequirements = (function() {
    
            /**
             * Properties of a INFPATaskOutputCPTCodePARequirements.
             * @memberof infinitusapi
             * @interface IINFPATaskOutputCPTCodePARequirements
             * @property {string|null} [code] INFPATaskOutputCPTCodePARequirements code
             * @property {google.protobuf.IBoolValue|null} [priorAuthRequired] INFPATaskOutputCPTCodePARequirements priorAuthRequired
             */
    
            /**
             * Constructs a new INFPATaskOutputCPTCodePARequirements.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskOutputCPTCodePARequirements.
             * @implements IINFPATaskOutputCPTCodePARequirements
             * @constructor
             * @param {infinitusapi.IINFPATaskOutputCPTCodePARequirements=} [properties] Properties to set
             */
            function INFPATaskOutputCPTCodePARequirements(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskOutputCPTCodePARequirements code.
             * @member {string} code
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @instance
             */
            INFPATaskOutputCPTCodePARequirements.prototype.code = "";
    
            /**
             * INFPATaskOutputCPTCodePARequirements priorAuthRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthRequired
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @instance
             */
            INFPATaskOutputCPTCodePARequirements.prototype.priorAuthRequired = null;
    
            /**
             * Creates a new INFPATaskOutputCPTCodePARequirements instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {infinitusapi.IINFPATaskOutputCPTCodePARequirements=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskOutputCPTCodePARequirements} INFPATaskOutputCPTCodePARequirements instance
             */
            INFPATaskOutputCPTCodePARequirements.create = function create(properties) {
                return new INFPATaskOutputCPTCodePARequirements(properties);
            };
    
            /**
             * Encodes the specified INFPATaskOutputCPTCodePARequirements message. Does not implicitly {@link infinitusapi.INFPATaskOutputCPTCodePARequirements.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {infinitusapi.IINFPATaskOutputCPTCodePARequirements} message INFPATaskOutputCPTCodePARequirements message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputCPTCodePARequirements.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                if (message.priorAuthRequired != null && Object.hasOwnProperty.call(message, "priorAuthRequired"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthRequired, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskOutputCPTCodePARequirements message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskOutputCPTCodePARequirements.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {infinitusapi.IINFPATaskOutputCPTCodePARequirements} message INFPATaskOutputCPTCodePARequirements message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputCPTCodePARequirements.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskOutputCPTCodePARequirements message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskOutputCPTCodePARequirements} INFPATaskOutputCPTCodePARequirements
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputCPTCodePARequirements.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskOutputCPTCodePARequirements();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    case 2: {
                            message.priorAuthRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskOutputCPTCodePARequirements message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskOutputCPTCodePARequirements} INFPATaskOutputCPTCodePARequirements
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputCPTCodePARequirements.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskOutputCPTCodePARequirements message.
             * @function verify
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskOutputCPTCodePARequirements.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthRequired);
                    if (error)
                        return "priorAuthRequired." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPATaskOutputCPTCodePARequirements message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskOutputCPTCodePARequirements} INFPATaskOutputCPTCodePARequirements
             */
            INFPATaskOutputCPTCodePARequirements.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskOutputCPTCodePARequirements)
                    return object;
                var message = new $root.infinitusapi.INFPATaskOutputCPTCodePARequirements();
                if (object.code != null)
                    message.code = String(object.code);
                if (object.priorAuthRequired != null) {
                    if (typeof object.priorAuthRequired !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputCPTCodePARequirements.priorAuthRequired: object expected");
                    message.priorAuthRequired = $root.google.protobuf.BoolValue.fromObject(object.priorAuthRequired);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskOutputCPTCodePARequirements message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {infinitusapi.INFPATaskOutputCPTCodePARequirements} message INFPATaskOutputCPTCodePARequirements
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskOutputCPTCodePARequirements.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.priorAuthRequired = null;
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired"))
                    object.priorAuthRequired = $root.google.protobuf.BoolValue.toObject(message.priorAuthRequired, options);
                return object;
            };
    
            /**
             * Converts this INFPATaskOutputCPTCodePARequirements to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskOutputCPTCodePARequirements.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskOutputCPTCodePARequirements
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskOutputCPTCodePARequirements
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskOutputCPTCodePARequirements.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskOutputCPTCodePARequirements";
            };
    
            return INFPATaskOutputCPTCodePARequirements;
        })();
    
        infinitusapi.INFPATaskOutputPlanInfo = (function() {
    
            /**
             * Properties of a INFPATaskOutputPlanInfo.
             * @memberof infinitusapi
             * @interface IINFPATaskOutputPlanInfo
             * @property {string|null} [planTerminationDate] INFPATaskOutputPlanInfo planTerminationDate
             */
    
            /**
             * Constructs a new INFPATaskOutputPlanInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskOutputPlanInfo.
             * @implements IINFPATaskOutputPlanInfo
             * @constructor
             * @param {infinitusapi.IINFPATaskOutputPlanInfo=} [properties] Properties to set
             */
            function INFPATaskOutputPlanInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskOutputPlanInfo planTerminationDate.
             * @member {string} planTerminationDate
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @instance
             */
            INFPATaskOutputPlanInfo.prototype.planTerminationDate = "";
    
            /**
             * Creates a new INFPATaskOutputPlanInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPlanInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskOutputPlanInfo} INFPATaskOutputPlanInfo instance
             */
            INFPATaskOutputPlanInfo.create = function create(properties) {
                return new INFPATaskOutputPlanInfo(properties);
            };
    
            /**
             * Encodes the specified INFPATaskOutputPlanInfo message. Does not implicitly {@link infinitusapi.INFPATaskOutputPlanInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPlanInfo} message INFPATaskOutputPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputPlanInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.planTerminationDate != null && Object.hasOwnProperty.call(message, "planTerminationDate"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.planTerminationDate);
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskOutputPlanInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskOutputPlanInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPlanInfo} message INFPATaskOutputPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputPlanInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskOutputPlanInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskOutputPlanInfo} INFPATaskOutputPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputPlanInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskOutputPlanInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.planTerminationDate = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskOutputPlanInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskOutputPlanInfo} INFPATaskOutputPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputPlanInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskOutputPlanInfo message.
             * @function verify
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskOutputPlanInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.planTerminationDate != null && message.hasOwnProperty("planTerminationDate"))
                    if (!$util.isString(message.planTerminationDate))
                        return "planTerminationDate: string expected";
                return null;
            };
    
            /**
             * Creates a INFPATaskOutputPlanInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskOutputPlanInfo} INFPATaskOutputPlanInfo
             */
            INFPATaskOutputPlanInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskOutputPlanInfo)
                    return object;
                var message = new $root.infinitusapi.INFPATaskOutputPlanInfo();
                if (object.planTerminationDate != null)
                    message.planTerminationDate = String(object.planTerminationDate);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskOutputPlanInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {infinitusapi.INFPATaskOutputPlanInfo} message INFPATaskOutputPlanInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskOutputPlanInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.planTerminationDate = "";
                if (message.planTerminationDate != null && message.hasOwnProperty("planTerminationDate"))
                    object.planTerminationDate = message.planTerminationDate;
                return object;
            };
    
            /**
             * Converts this INFPATaskOutputPlanInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskOutputPlanInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskOutputPlanInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskOutputPlanInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskOutputPlanInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskOutputPlanInfo";
            };
    
            return INFPATaskOutputPlanInfo;
        })();
    
        infinitusapi.INFPATaskOutputPriorAuthAppealInfo = (function() {
    
            /**
             * Properties of a INFPATaskOutputPriorAuthAppealInfo.
             * @memberof infinitusapi
             * @interface IINFPATaskOutputPriorAuthAppealInfo
             * @property {google.protobuf.IBoolValue|null} [priorAuthAppealAvailable] INFPATaskOutputPriorAuthAppealInfo priorAuthAppealAvailable
             * @property {google.protobuf.IStringValue|null} [priorAuthAppealsContactOrg] INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsContactOrg
             * @property {google.protobuf.IStringValue|null} [priorAuthAppealsContactPhone] INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsContactPhone
             * @property {Array.<infinitusapi.INFAppealsNotificationMethod>|null} [priorAuthAppealsNotificationMethods] INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsNotificationMethods
             * @property {Array.<infinitusapi.INFAppealsRequiredDocuments>|null} [priorAuthAppealsRequiredDocuments] INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsRequiredDocuments
             * @property {google.protobuf.IStringValue|null} [priorAuthAppealsSubmissionDeadline] INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsSubmissionDeadline
             * @property {google.protobuf.IStringValue|null} [priorAuthAppealsTurnaroundTime] INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsTurnaroundTime
             */
    
            /**
             * Constructs a new INFPATaskOutputPriorAuthAppealInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskOutputPriorAuthAppealInfo.
             * @implements IINFPATaskOutputPriorAuthAppealInfo
             * @constructor
             * @param {infinitusapi.IINFPATaskOutputPriorAuthAppealInfo=} [properties] Properties to set
             */
            function INFPATaskOutputPriorAuthAppealInfo(properties) {
                this.priorAuthAppealsNotificationMethods = [];
                this.priorAuthAppealsRequiredDocuments = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskOutputPriorAuthAppealInfo priorAuthAppealAvailable.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthAppealAvailable
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @instance
             */
            INFPATaskOutputPriorAuthAppealInfo.prototype.priorAuthAppealAvailable = null;
    
            /**
             * INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsContactOrg.
             * @member {google.protobuf.IStringValue|null|undefined} priorAuthAppealsContactOrg
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @instance
             */
            INFPATaskOutputPriorAuthAppealInfo.prototype.priorAuthAppealsContactOrg = null;
    
            /**
             * INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsContactPhone.
             * @member {google.protobuf.IStringValue|null|undefined} priorAuthAppealsContactPhone
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @instance
             */
            INFPATaskOutputPriorAuthAppealInfo.prototype.priorAuthAppealsContactPhone = null;
    
            /**
             * INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsNotificationMethods.
             * @member {Array.<infinitusapi.INFAppealsNotificationMethod>} priorAuthAppealsNotificationMethods
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @instance
             */
            INFPATaskOutputPriorAuthAppealInfo.prototype.priorAuthAppealsNotificationMethods = $util.emptyArray;
    
            /**
             * INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsRequiredDocuments.
             * @member {Array.<infinitusapi.INFAppealsRequiredDocuments>} priorAuthAppealsRequiredDocuments
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @instance
             */
            INFPATaskOutputPriorAuthAppealInfo.prototype.priorAuthAppealsRequiredDocuments = $util.emptyArray;
    
            /**
             * INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsSubmissionDeadline.
             * @member {google.protobuf.IStringValue|null|undefined} priorAuthAppealsSubmissionDeadline
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @instance
             */
            INFPATaskOutputPriorAuthAppealInfo.prototype.priorAuthAppealsSubmissionDeadline = null;
    
            /**
             * INFPATaskOutputPriorAuthAppealInfo priorAuthAppealsTurnaroundTime.
             * @member {google.protobuf.IStringValue|null|undefined} priorAuthAppealsTurnaroundTime
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @instance
             */
            INFPATaskOutputPriorAuthAppealInfo.prototype.priorAuthAppealsTurnaroundTime = null;
    
            /**
             * Creates a new INFPATaskOutputPriorAuthAppealInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthAppealInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskOutputPriorAuthAppealInfo} INFPATaskOutputPriorAuthAppealInfo instance
             */
            INFPATaskOutputPriorAuthAppealInfo.create = function create(properties) {
                return new INFPATaskOutputPriorAuthAppealInfo(properties);
            };
    
            /**
             * Encodes the specified INFPATaskOutputPriorAuthAppealInfo message. Does not implicitly {@link infinitusapi.INFPATaskOutputPriorAuthAppealInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthAppealInfo} message INFPATaskOutputPriorAuthAppealInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputPriorAuthAppealInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.priorAuthAppealAvailable != null && Object.hasOwnProperty.call(message, "priorAuthAppealAvailable"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthAppealAvailable, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.priorAuthAppealsContactOrg != null && Object.hasOwnProperty.call(message, "priorAuthAppealsContactOrg"))
                    $root.google.protobuf.StringValue.encode(message.priorAuthAppealsContactOrg, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.priorAuthAppealsContactPhone != null && Object.hasOwnProperty.call(message, "priorAuthAppealsContactPhone"))
                    $root.google.protobuf.StringValue.encode(message.priorAuthAppealsContactPhone, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.priorAuthAppealsNotificationMethods != null && message.priorAuthAppealsNotificationMethods.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (var i = 0; i < message.priorAuthAppealsNotificationMethods.length; ++i)
                        writer.int32(message.priorAuthAppealsNotificationMethods[i]);
                    writer.ldelim();
                }
                if (message.priorAuthAppealsRequiredDocuments != null && message.priorAuthAppealsRequiredDocuments.length) {
                    writer.uint32(/* id 5, wireType 2 =*/42).fork();
                    for (var i = 0; i < message.priorAuthAppealsRequiredDocuments.length; ++i)
                        writer.int32(message.priorAuthAppealsRequiredDocuments[i]);
                    writer.ldelim();
                }
                if (message.priorAuthAppealsSubmissionDeadline != null && Object.hasOwnProperty.call(message, "priorAuthAppealsSubmissionDeadline"))
                    $root.google.protobuf.StringValue.encode(message.priorAuthAppealsSubmissionDeadline, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.priorAuthAppealsTurnaroundTime != null && Object.hasOwnProperty.call(message, "priorAuthAppealsTurnaroundTime"))
                    $root.google.protobuf.StringValue.encode(message.priorAuthAppealsTurnaroundTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskOutputPriorAuthAppealInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskOutputPriorAuthAppealInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthAppealInfo} message INFPATaskOutputPriorAuthAppealInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputPriorAuthAppealInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskOutputPriorAuthAppealInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskOutputPriorAuthAppealInfo} INFPATaskOutputPriorAuthAppealInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputPriorAuthAppealInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskOutputPriorAuthAppealInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.priorAuthAppealAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.priorAuthAppealsContactOrg = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.priorAuthAppealsContactPhone = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            if (!(message.priorAuthAppealsNotificationMethods && message.priorAuthAppealsNotificationMethods.length))
                                message.priorAuthAppealsNotificationMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.priorAuthAppealsNotificationMethods.push(reader.int32());
                            } else
                                message.priorAuthAppealsNotificationMethods.push(reader.int32());
                            break;
                        }
                    case 5: {
                            if (!(message.priorAuthAppealsRequiredDocuments && message.priorAuthAppealsRequiredDocuments.length))
                                message.priorAuthAppealsRequiredDocuments = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.priorAuthAppealsRequiredDocuments.push(reader.int32());
                            } else
                                message.priorAuthAppealsRequiredDocuments.push(reader.int32());
                            break;
                        }
                    case 6: {
                            message.priorAuthAppealsSubmissionDeadline = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.priorAuthAppealsTurnaroundTime = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskOutputPriorAuthAppealInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskOutputPriorAuthAppealInfo} INFPATaskOutputPriorAuthAppealInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputPriorAuthAppealInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskOutputPriorAuthAppealInfo message.
             * @function verify
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskOutputPriorAuthAppealInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.priorAuthAppealAvailable != null && message.hasOwnProperty("priorAuthAppealAvailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthAppealAvailable);
                    if (error)
                        return "priorAuthAppealAvailable." + error;
                }
                if (message.priorAuthAppealsContactOrg != null && message.hasOwnProperty("priorAuthAppealsContactOrg")) {
                    var error = $root.google.protobuf.StringValue.verify(message.priorAuthAppealsContactOrg);
                    if (error)
                        return "priorAuthAppealsContactOrg." + error;
                }
                if (message.priorAuthAppealsContactPhone != null && message.hasOwnProperty("priorAuthAppealsContactPhone")) {
                    var error = $root.google.protobuf.StringValue.verify(message.priorAuthAppealsContactPhone);
                    if (error)
                        return "priorAuthAppealsContactPhone." + error;
                }
                if (message.priorAuthAppealsNotificationMethods != null && message.hasOwnProperty("priorAuthAppealsNotificationMethods")) {
                    if (!Array.isArray(message.priorAuthAppealsNotificationMethods))
                        return "priorAuthAppealsNotificationMethods: array expected";
                    for (var i = 0; i < message.priorAuthAppealsNotificationMethods.length; ++i)
                        switch (message.priorAuthAppealsNotificationMethods[i]) {
                        default:
                            return "priorAuthAppealsNotificationMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                }
                if (message.priorAuthAppealsRequiredDocuments != null && message.hasOwnProperty("priorAuthAppealsRequiredDocuments")) {
                    if (!Array.isArray(message.priorAuthAppealsRequiredDocuments))
                        return "priorAuthAppealsRequiredDocuments: array expected";
                    for (var i = 0; i < message.priorAuthAppealsRequiredDocuments.length; ++i)
                        switch (message.priorAuthAppealsRequiredDocuments[i]) {
                        default:
                            return "priorAuthAppealsRequiredDocuments: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                if (message.priorAuthAppealsSubmissionDeadline != null && message.hasOwnProperty("priorAuthAppealsSubmissionDeadline")) {
                    var error = $root.google.protobuf.StringValue.verify(message.priorAuthAppealsSubmissionDeadline);
                    if (error)
                        return "priorAuthAppealsSubmissionDeadline." + error;
                }
                if (message.priorAuthAppealsTurnaroundTime != null && message.hasOwnProperty("priorAuthAppealsTurnaroundTime")) {
                    var error = $root.google.protobuf.StringValue.verify(message.priorAuthAppealsTurnaroundTime);
                    if (error)
                        return "priorAuthAppealsTurnaroundTime." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPATaskOutputPriorAuthAppealInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskOutputPriorAuthAppealInfo} INFPATaskOutputPriorAuthAppealInfo
             */
            INFPATaskOutputPriorAuthAppealInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskOutputPriorAuthAppealInfo)
                    return object;
                var message = new $root.infinitusapi.INFPATaskOutputPriorAuthAppealInfo();
                if (object.priorAuthAppealAvailable != null) {
                    if (typeof object.priorAuthAppealAvailable !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthAppealInfo.priorAuthAppealAvailable: object expected");
                    message.priorAuthAppealAvailable = $root.google.protobuf.BoolValue.fromObject(object.priorAuthAppealAvailable);
                }
                if (object.priorAuthAppealsContactOrg != null) {
                    if (typeof object.priorAuthAppealsContactOrg !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthAppealInfo.priorAuthAppealsContactOrg: object expected");
                    message.priorAuthAppealsContactOrg = $root.google.protobuf.StringValue.fromObject(object.priorAuthAppealsContactOrg);
                }
                if (object.priorAuthAppealsContactPhone != null) {
                    if (typeof object.priorAuthAppealsContactPhone !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthAppealInfo.priorAuthAppealsContactPhone: object expected");
                    message.priorAuthAppealsContactPhone = $root.google.protobuf.StringValue.fromObject(object.priorAuthAppealsContactPhone);
                }
                if (object.priorAuthAppealsNotificationMethods) {
                    if (!Array.isArray(object.priorAuthAppealsNotificationMethods))
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthAppealInfo.priorAuthAppealsNotificationMethods: array expected");
                    message.priorAuthAppealsNotificationMethods = [];
                    for (var i = 0; i < object.priorAuthAppealsNotificationMethods.length; ++i)
                        switch (object.priorAuthAppealsNotificationMethods[i]) {
                        default:
                            if (typeof object.priorAuthAppealsNotificationMethods[i] === "number") {
                                message.priorAuthAppealsNotificationMethods[i] = object.priorAuthAppealsNotificationMethods[i];
                                break;
                            }
                        case "INF_APPEALS_NOTIFICATION_METHOD_UNKNOWN":
                        case 0:
                            message.priorAuthAppealsNotificationMethods[i] = 0;
                            break;
                        case "INF_APPEALS_NOTIFICATION_METHOD_PHONE":
                        case 1:
                            message.priorAuthAppealsNotificationMethods[i] = 1;
                            break;
                        case "INF_APPEALS_NOTIFICATION_METHOD_FAX":
                        case 2:
                            message.priorAuthAppealsNotificationMethods[i] = 2;
                            break;
                        case "INF_APPEALS_NOTIFICATION_METHOD_EMAIL":
                        case 3:
                            message.priorAuthAppealsNotificationMethods[i] = 3;
                            break;
                        case "INF_APPEALS_NOTIFICATION_METHOD_WEBSITE":
                        case 4:
                            message.priorAuthAppealsNotificationMethods[i] = 4;
                            break;
                        case "INF_APPEALS_NOTIFICATION_METHOD_NOT_APPLICABLE":
                        case 5:
                            message.priorAuthAppealsNotificationMethods[i] = 5;
                            break;
                        }
                }
                if (object.priorAuthAppealsRequiredDocuments) {
                    if (!Array.isArray(object.priorAuthAppealsRequiredDocuments))
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthAppealInfo.priorAuthAppealsRequiredDocuments: array expected");
                    message.priorAuthAppealsRequiredDocuments = [];
                    for (var i = 0; i < object.priorAuthAppealsRequiredDocuments.length; ++i)
                        switch (object.priorAuthAppealsRequiredDocuments[i]) {
                        default:
                            if (typeof object.priorAuthAppealsRequiredDocuments[i] === "number") {
                                message.priorAuthAppealsRequiredDocuments[i] = object.priorAuthAppealsRequiredDocuments[i];
                                break;
                            }
                        case "INF_APPEALS_REQUIRED_DOCUMENTS_UNKNOWN":
                        case 0:
                            message.priorAuthAppealsRequiredDocuments[i] = 0;
                            break;
                        case "INF_APPEALS_REQUIRED_DOCUMENTS_NOT_APPLICABLE":
                        case 1:
                            message.priorAuthAppealsRequiredDocuments[i] = 1;
                            break;
                        case "INF_APPEALS_REQUIRED_DOCUMENTS_DENIAL_LETTER":
                        case 2:
                            message.priorAuthAppealsRequiredDocuments[i] = 2;
                            break;
                        case "INF_APPEALS_REQUIRED_DOCUMENTS_LETTER_OF_MEDICAL_NECESSITY":
                        case 3:
                            message.priorAuthAppealsRequiredDocuments[i] = 3;
                            break;
                        case "INF_APPEALS_REQUIRED_DOCUMENTS_NONE":
                        case 4:
                            message.priorAuthAppealsRequiredDocuments[i] = 4;
                            break;
                        }
                }
                if (object.priorAuthAppealsSubmissionDeadline != null) {
                    if (typeof object.priorAuthAppealsSubmissionDeadline !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthAppealInfo.priorAuthAppealsSubmissionDeadline: object expected");
                    message.priorAuthAppealsSubmissionDeadline = $root.google.protobuf.StringValue.fromObject(object.priorAuthAppealsSubmissionDeadline);
                }
                if (object.priorAuthAppealsTurnaroundTime != null) {
                    if (typeof object.priorAuthAppealsTurnaroundTime !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthAppealInfo.priorAuthAppealsTurnaroundTime: object expected");
                    message.priorAuthAppealsTurnaroundTime = $root.google.protobuf.StringValue.fromObject(object.priorAuthAppealsTurnaroundTime);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskOutputPriorAuthAppealInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {infinitusapi.INFPATaskOutputPriorAuthAppealInfo} message INFPATaskOutputPriorAuthAppealInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskOutputPriorAuthAppealInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.priorAuthAppealsNotificationMethods = [];
                    object.priorAuthAppealsRequiredDocuments = [];
                }
                if (options.defaults) {
                    object.priorAuthAppealAvailable = null;
                    object.priorAuthAppealsContactOrg = null;
                    object.priorAuthAppealsContactPhone = null;
                    object.priorAuthAppealsSubmissionDeadline = null;
                    object.priorAuthAppealsTurnaroundTime = null;
                }
                if (message.priorAuthAppealAvailable != null && message.hasOwnProperty("priorAuthAppealAvailable"))
                    object.priorAuthAppealAvailable = $root.google.protobuf.BoolValue.toObject(message.priorAuthAppealAvailable, options);
                if (message.priorAuthAppealsContactOrg != null && message.hasOwnProperty("priorAuthAppealsContactOrg"))
                    object.priorAuthAppealsContactOrg = $root.google.protobuf.StringValue.toObject(message.priorAuthAppealsContactOrg, options);
                if (message.priorAuthAppealsContactPhone != null && message.hasOwnProperty("priorAuthAppealsContactPhone"))
                    object.priorAuthAppealsContactPhone = $root.google.protobuf.StringValue.toObject(message.priorAuthAppealsContactPhone, options);
                if (message.priorAuthAppealsNotificationMethods && message.priorAuthAppealsNotificationMethods.length) {
                    object.priorAuthAppealsNotificationMethods = [];
                    for (var j = 0; j < message.priorAuthAppealsNotificationMethods.length; ++j)
                        object.priorAuthAppealsNotificationMethods[j] = options.enums === String ? $root.infinitusapi.INFAppealsNotificationMethod[message.priorAuthAppealsNotificationMethods[j]] === undefined ? message.priorAuthAppealsNotificationMethods[j] : $root.infinitusapi.INFAppealsNotificationMethod[message.priorAuthAppealsNotificationMethods[j]] : message.priorAuthAppealsNotificationMethods[j];
                }
                if (message.priorAuthAppealsRequiredDocuments && message.priorAuthAppealsRequiredDocuments.length) {
                    object.priorAuthAppealsRequiredDocuments = [];
                    for (var j = 0; j < message.priorAuthAppealsRequiredDocuments.length; ++j)
                        object.priorAuthAppealsRequiredDocuments[j] = options.enums === String ? $root.infinitusapi.INFAppealsRequiredDocuments[message.priorAuthAppealsRequiredDocuments[j]] === undefined ? message.priorAuthAppealsRequiredDocuments[j] : $root.infinitusapi.INFAppealsRequiredDocuments[message.priorAuthAppealsRequiredDocuments[j]] : message.priorAuthAppealsRequiredDocuments[j];
                }
                if (message.priorAuthAppealsSubmissionDeadline != null && message.hasOwnProperty("priorAuthAppealsSubmissionDeadline"))
                    object.priorAuthAppealsSubmissionDeadline = $root.google.protobuf.StringValue.toObject(message.priorAuthAppealsSubmissionDeadline, options);
                if (message.priorAuthAppealsTurnaroundTime != null && message.hasOwnProperty("priorAuthAppealsTurnaroundTime"))
                    object.priorAuthAppealsTurnaroundTime = $root.google.protobuf.StringValue.toObject(message.priorAuthAppealsTurnaroundTime, options);
                return object;
            };
    
            /**
             * Converts this INFPATaskOutputPriorAuthAppealInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskOutputPriorAuthAppealInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskOutputPriorAuthAppealInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskOutputPriorAuthAppealInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskOutputPriorAuthAppealInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskOutputPriorAuthAppealInfo";
            };
    
            return INFPATaskOutputPriorAuthAppealInfo;
        })();
    
        infinitusapi.INFPATaskOutputPriorAuthInfo = (function() {
    
            /**
             * Properties of a INFPATaskOutputPriorAuthInfo.
             * @memberof infinitusapi
             * @interface IINFPATaskOutputPriorAuthInfo
             * @property {Array.<infinitusapi.IINFPATaskOutputPriorAuthInfoDetail>|null} [priorAuthInfoDetails] INFPATaskOutputPriorAuthInfo priorAuthInfoDetails
             */
    
            /**
             * Constructs a new INFPATaskOutputPriorAuthInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskOutputPriorAuthInfo.
             * @implements IINFPATaskOutputPriorAuthInfo
             * @constructor
             * @param {infinitusapi.IINFPATaskOutputPriorAuthInfo=} [properties] Properties to set
             */
            function INFPATaskOutputPriorAuthInfo(properties) {
                this.priorAuthInfoDetails = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskOutputPriorAuthInfo priorAuthInfoDetails.
             * @member {Array.<infinitusapi.IINFPATaskOutputPriorAuthInfoDetail>} priorAuthInfoDetails
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @instance
             */
            INFPATaskOutputPriorAuthInfo.prototype.priorAuthInfoDetails = $util.emptyArray;
    
            /**
             * Creates a new INFPATaskOutputPriorAuthInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskOutputPriorAuthInfo} INFPATaskOutputPriorAuthInfo instance
             */
            INFPATaskOutputPriorAuthInfo.create = function create(properties) {
                return new INFPATaskOutputPriorAuthInfo(properties);
            };
    
            /**
             * Encodes the specified INFPATaskOutputPriorAuthInfo message. Does not implicitly {@link infinitusapi.INFPATaskOutputPriorAuthInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthInfo} message INFPATaskOutputPriorAuthInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputPriorAuthInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.priorAuthInfoDetails != null && message.priorAuthInfoDetails.length)
                    for (var i = 0; i < message.priorAuthInfoDetails.length; ++i)
                        $root.infinitusapi.INFPATaskOutputPriorAuthInfoDetail.encode(message.priorAuthInfoDetails[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskOutputPriorAuthInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskOutputPriorAuthInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthInfo} message INFPATaskOutputPriorAuthInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputPriorAuthInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskOutputPriorAuthInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskOutputPriorAuthInfo} INFPATaskOutputPriorAuthInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputPriorAuthInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskOutputPriorAuthInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.priorAuthInfoDetails && message.priorAuthInfoDetails.length))
                                message.priorAuthInfoDetails = [];
                            message.priorAuthInfoDetails.push($root.infinitusapi.INFPATaskOutputPriorAuthInfoDetail.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskOutputPriorAuthInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskOutputPriorAuthInfo} INFPATaskOutputPriorAuthInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputPriorAuthInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskOutputPriorAuthInfo message.
             * @function verify
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskOutputPriorAuthInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.priorAuthInfoDetails != null && message.hasOwnProperty("priorAuthInfoDetails")) {
                    if (!Array.isArray(message.priorAuthInfoDetails))
                        return "priorAuthInfoDetails: array expected";
                    for (var i = 0; i < message.priorAuthInfoDetails.length; ++i) {
                        var error = $root.infinitusapi.INFPATaskOutputPriorAuthInfoDetail.verify(message.priorAuthInfoDetails[i]);
                        if (error)
                            return "priorAuthInfoDetails." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a INFPATaskOutputPriorAuthInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskOutputPriorAuthInfo} INFPATaskOutputPriorAuthInfo
             */
            INFPATaskOutputPriorAuthInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskOutputPriorAuthInfo)
                    return object;
                var message = new $root.infinitusapi.INFPATaskOutputPriorAuthInfo();
                if (object.priorAuthInfoDetails) {
                    if (!Array.isArray(object.priorAuthInfoDetails))
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfo.priorAuthInfoDetails: array expected");
                    message.priorAuthInfoDetails = [];
                    for (var i = 0; i < object.priorAuthInfoDetails.length; ++i) {
                        if (typeof object.priorAuthInfoDetails[i] !== "object")
                            throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfo.priorAuthInfoDetails: object expected");
                        message.priorAuthInfoDetails[i] = $root.infinitusapi.INFPATaskOutputPriorAuthInfoDetail.fromObject(object.priorAuthInfoDetails[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskOutputPriorAuthInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {infinitusapi.INFPATaskOutputPriorAuthInfo} message INFPATaskOutputPriorAuthInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskOutputPriorAuthInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.priorAuthInfoDetails = [];
                if (message.priorAuthInfoDetails && message.priorAuthInfoDetails.length) {
                    object.priorAuthInfoDetails = [];
                    for (var j = 0; j < message.priorAuthInfoDetails.length; ++j)
                        object.priorAuthInfoDetails[j] = $root.infinitusapi.INFPATaskOutputPriorAuthInfoDetail.toObject(message.priorAuthInfoDetails[j], options);
                }
                return object;
            };
    
            /**
             * Converts this INFPATaskOutputPriorAuthInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskOutputPriorAuthInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskOutputPriorAuthInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskOutputPriorAuthInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskOutputPriorAuthInfo";
            };
    
            return INFPATaskOutputPriorAuthInfo;
        })();
    
        infinitusapi.INFPATaskOutputPriorAuthInfoDetail = (function() {
    
            /**
             * Properties of a INFPATaskOutputPriorAuthInfoDetail.
             * @memberof infinitusapi
             * @interface IINFPATaskOutputPriorAuthInfoDetail
             * @property {string|null} [priorAuthApprovalDate] INFPATaskOutputPriorAuthInfoDetail priorAuthApprovalDate
             * @property {infinitusapi.INFPriorAuthApprovalLevel|null} [priorAuthApprovalLevel] INFPATaskOutputPriorAuthInfoDetail priorAuthApprovalLevel
             * @property {string|null} [priorAuthApprovalNumber] INFPATaskOutputPriorAuthInfoDetail priorAuthApprovalNumber
             * @property {Array.<string>|null} [priorAuthCodesOnFile] INFPATaskOutputPriorAuthInfoDetail priorAuthCodesOnFile
             * @property {string|null} [priorAuthCompletionDate] INFPATaskOutputPriorAuthInfoDetail priorAuthCompletionDate
             * @property {infinitusapi.INFPriorAuthDenialReason|null} [priorAuthDenialReason] INFPATaskOutputPriorAuthInfoDetail priorAuthDenialReason
             * @property {string|null} [priorAuthDenialReasonOther] INFPATaskOutputPriorAuthInfoDetail priorAuthDenialReasonOther
             * @property {string|null} [priorAuthDoseOnFile] INFPATaskOutputPriorAuthInfoDetail priorAuthDoseOnFile
             * @property {string|null} [priorAuthEndDate] INFPATaskOutputPriorAuthInfoDetail priorAuthEndDate
             * @property {infinitusapi.IINFPriorAuthEntityOnFile|null} [priorAuthEntityOnFile] INFPATaskOutputPriorAuthInfoDetail priorAuthEntityOnFile
             * @property {google.protobuf.IBoolValue|null} [priorAuthLockedToSpecificEntity] INFPATaskOutputPriorAuthInfoDetail priorAuthLockedToSpecificEntity
             * @property {Array.<infinitusapi.INFPriorAuthNotificationMethod>|null} [priorAuthNotificationMethods] INFPATaskOutputPriorAuthInfoDetail priorAuthNotificationMethods
             * @property {google.protobuf.IBoolValue|null} [priorAuthOnFileApplies] INFPATaskOutputPriorAuthInfoDetail priorAuthOnFileApplies
             * @property {string|null} [priorAuthStartDate] INFPATaskOutputPriorAuthInfoDetail priorAuthStartDate
             * @property {infinitusapi.INFPriorAuthStatus|null} [priorAuthStatus] INFPATaskOutputPriorAuthInfoDetail priorAuthStatus
             * @property {Array.<infinitusapi.INFPriorAuthSubmissionMethod>|null} [priorAuthSubmissionMethods] INFPATaskOutputPriorAuthInfoDetail priorAuthSubmissionMethods
             * @property {string|null} [priorAuthTurnaroundNotes] INFPATaskOutputPriorAuthInfoDetail priorAuthTurnaroundNotes
             * @property {infinitusapi.INFPriorAuthProductSourcingMethod|null} [productSourcingMethod] INFPATaskOutputPriorAuthInfoDetail productSourcingMethod
             * @property {string|null} [quantityLimit] INFPATaskOutputPriorAuthInfoDetail quantityLimit
             * @property {google.protobuf.IBoolValue|null} [quantityLimitExists] INFPATaskOutputPriorAuthInfoDetail quantityLimitExists
             */
    
            /**
             * Constructs a new INFPATaskOutputPriorAuthInfoDetail.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskOutputPriorAuthInfoDetail.
             * @implements IINFPATaskOutputPriorAuthInfoDetail
             * @constructor
             * @param {infinitusapi.IINFPATaskOutputPriorAuthInfoDetail=} [properties] Properties to set
             */
            function INFPATaskOutputPriorAuthInfoDetail(properties) {
                this.priorAuthCodesOnFile = [];
                this.priorAuthNotificationMethods = [];
                this.priorAuthSubmissionMethods = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthApprovalDate.
             * @member {string} priorAuthApprovalDate
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthApprovalDate = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthApprovalLevel.
             * @member {infinitusapi.INFPriorAuthApprovalLevel} priorAuthApprovalLevel
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthApprovalLevel = 0;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthApprovalNumber.
             * @member {string} priorAuthApprovalNumber
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthApprovalNumber = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthCodesOnFile.
             * @member {Array.<string>} priorAuthCodesOnFile
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthCodesOnFile = $util.emptyArray;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthCompletionDate.
             * @member {string} priorAuthCompletionDate
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthCompletionDate = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthDenialReason.
             * @member {infinitusapi.INFPriorAuthDenialReason} priorAuthDenialReason
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthDenialReason = 0;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthDenialReasonOther.
             * @member {string} priorAuthDenialReasonOther
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthDenialReasonOther = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthDoseOnFile.
             * @member {string} priorAuthDoseOnFile
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthDoseOnFile = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthEndDate.
             * @member {string} priorAuthEndDate
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthEndDate = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthEntityOnFile.
             * @member {infinitusapi.IINFPriorAuthEntityOnFile|null|undefined} priorAuthEntityOnFile
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthEntityOnFile = null;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthLockedToSpecificEntity.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthLockedToSpecificEntity
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthLockedToSpecificEntity = null;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthNotificationMethods.
             * @member {Array.<infinitusapi.INFPriorAuthNotificationMethod>} priorAuthNotificationMethods
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthNotificationMethods = $util.emptyArray;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthOnFileApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthOnFileApplies
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthOnFileApplies = null;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthStartDate.
             * @member {string} priorAuthStartDate
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthStartDate = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthStatus.
             * @member {infinitusapi.INFPriorAuthStatus} priorAuthStatus
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthStatus = 0;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthSubmissionMethods.
             * @member {Array.<infinitusapi.INFPriorAuthSubmissionMethod>} priorAuthSubmissionMethods
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthSubmissionMethods = $util.emptyArray;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail priorAuthTurnaroundNotes.
             * @member {string} priorAuthTurnaroundNotes
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.priorAuthTurnaroundNotes = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail productSourcingMethod.
             * @member {infinitusapi.INFPriorAuthProductSourcingMethod} productSourcingMethod
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.productSourcingMethod = 0;
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail quantityLimit.
             * @member {string} quantityLimit
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.quantityLimit = "";
    
            /**
             * INFPATaskOutputPriorAuthInfoDetail quantityLimitExists.
             * @member {google.protobuf.IBoolValue|null|undefined} quantityLimitExists
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.quantityLimitExists = null;
    
            /**
             * Creates a new INFPATaskOutputPriorAuthInfoDetail instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthInfoDetail=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskOutputPriorAuthInfoDetail} INFPATaskOutputPriorAuthInfoDetail instance
             */
            INFPATaskOutputPriorAuthInfoDetail.create = function create(properties) {
                return new INFPATaskOutputPriorAuthInfoDetail(properties);
            };
    
            /**
             * Encodes the specified INFPATaskOutputPriorAuthInfoDetail message. Does not implicitly {@link infinitusapi.INFPATaskOutputPriorAuthInfoDetail.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthInfoDetail} message INFPATaskOutputPriorAuthInfoDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputPriorAuthInfoDetail.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.priorAuthApprovalDate != null && Object.hasOwnProperty.call(message, "priorAuthApprovalDate"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.priorAuthApprovalDate);
                if (message.priorAuthApprovalLevel != null && Object.hasOwnProperty.call(message, "priorAuthApprovalLevel"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.priorAuthApprovalLevel);
                if (message.priorAuthApprovalNumber != null && Object.hasOwnProperty.call(message, "priorAuthApprovalNumber"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.priorAuthApprovalNumber);
                if (message.priorAuthCodesOnFile != null && message.priorAuthCodesOnFile.length)
                    for (var i = 0; i < message.priorAuthCodesOnFile.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.priorAuthCodesOnFile[i]);
                if (message.priorAuthCompletionDate != null && Object.hasOwnProperty.call(message, "priorAuthCompletionDate"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.priorAuthCompletionDate);
                if (message.priorAuthDenialReason != null && Object.hasOwnProperty.call(message, "priorAuthDenialReason"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.priorAuthDenialReason);
                if (message.priorAuthDenialReasonOther != null && Object.hasOwnProperty.call(message, "priorAuthDenialReasonOther"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.priorAuthDenialReasonOther);
                if (message.priorAuthDoseOnFile != null && Object.hasOwnProperty.call(message, "priorAuthDoseOnFile"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.priorAuthDoseOnFile);
                if (message.priorAuthEndDate != null && Object.hasOwnProperty.call(message, "priorAuthEndDate"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.priorAuthEndDate);
                if (message.priorAuthEntityOnFile != null && Object.hasOwnProperty.call(message, "priorAuthEntityOnFile"))
                    $root.infinitusapi.INFPriorAuthEntityOnFile.encode(message.priorAuthEntityOnFile, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.priorAuthLockedToSpecificEntity != null && Object.hasOwnProperty.call(message, "priorAuthLockedToSpecificEntity"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthLockedToSpecificEntity, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.priorAuthNotificationMethods != null && message.priorAuthNotificationMethods.length) {
                    writer.uint32(/* id 12, wireType 2 =*/98).fork();
                    for (var i = 0; i < message.priorAuthNotificationMethods.length; ++i)
                        writer.int32(message.priorAuthNotificationMethods[i]);
                    writer.ldelim();
                }
                if (message.priorAuthOnFileApplies != null && Object.hasOwnProperty.call(message, "priorAuthOnFileApplies"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthOnFileApplies, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.priorAuthStartDate != null && Object.hasOwnProperty.call(message, "priorAuthStartDate"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.priorAuthStartDate);
                if (message.priorAuthStatus != null && Object.hasOwnProperty.call(message, "priorAuthStatus"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.priorAuthStatus);
                if (message.priorAuthSubmissionMethods != null && message.priorAuthSubmissionMethods.length) {
                    writer.uint32(/* id 16, wireType 2 =*/130).fork();
                    for (var i = 0; i < message.priorAuthSubmissionMethods.length; ++i)
                        writer.int32(message.priorAuthSubmissionMethods[i]);
                    writer.ldelim();
                }
                if (message.priorAuthTurnaroundNotes != null && Object.hasOwnProperty.call(message, "priorAuthTurnaroundNotes"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.priorAuthTurnaroundNotes);
                if (message.productSourcingMethod != null && Object.hasOwnProperty.call(message, "productSourcingMethod"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int32(message.productSourcingMethod);
                if (message.quantityLimit != null && Object.hasOwnProperty.call(message, "quantityLimit"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.quantityLimit);
                if (message.quantityLimitExists != null && Object.hasOwnProperty.call(message, "quantityLimitExists"))
                    $root.google.protobuf.BoolValue.encode(message.quantityLimitExists, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskOutputPriorAuthInfoDetail message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskOutputPriorAuthInfoDetail.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {infinitusapi.IINFPATaskOutputPriorAuthInfoDetail} message INFPATaskOutputPriorAuthInfoDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputPriorAuthInfoDetail.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskOutputPriorAuthInfoDetail message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskOutputPriorAuthInfoDetail} INFPATaskOutputPriorAuthInfoDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputPriorAuthInfoDetail.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskOutputPriorAuthInfoDetail();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.priorAuthApprovalDate = reader.string();
                            break;
                        }
                    case 2: {
                            message.priorAuthApprovalLevel = reader.int32();
                            break;
                        }
                    case 3: {
                            message.priorAuthApprovalNumber = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.priorAuthCodesOnFile && message.priorAuthCodesOnFile.length))
                                message.priorAuthCodesOnFile = [];
                            message.priorAuthCodesOnFile.push(reader.string());
                            break;
                        }
                    case 5: {
                            message.priorAuthCompletionDate = reader.string();
                            break;
                        }
                    case 6: {
                            message.priorAuthDenialReason = reader.int32();
                            break;
                        }
                    case 7: {
                            message.priorAuthDenialReasonOther = reader.string();
                            break;
                        }
                    case 8: {
                            message.priorAuthDoseOnFile = reader.string();
                            break;
                        }
                    case 9: {
                            message.priorAuthEndDate = reader.string();
                            break;
                        }
                    case 10: {
                            message.priorAuthEntityOnFile = $root.infinitusapi.INFPriorAuthEntityOnFile.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.priorAuthLockedToSpecificEntity = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            if (!(message.priorAuthNotificationMethods && message.priorAuthNotificationMethods.length))
                                message.priorAuthNotificationMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.priorAuthNotificationMethods.push(reader.int32());
                            } else
                                message.priorAuthNotificationMethods.push(reader.int32());
                            break;
                        }
                    case 13: {
                            message.priorAuthOnFileApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.priorAuthStartDate = reader.string();
                            break;
                        }
                    case 15: {
                            message.priorAuthStatus = reader.int32();
                            break;
                        }
                    case 16: {
                            if (!(message.priorAuthSubmissionMethods && message.priorAuthSubmissionMethods.length))
                                message.priorAuthSubmissionMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.priorAuthSubmissionMethods.push(reader.int32());
                            } else
                                message.priorAuthSubmissionMethods.push(reader.int32());
                            break;
                        }
                    case 17: {
                            message.priorAuthTurnaroundNotes = reader.string();
                            break;
                        }
                    case 18: {
                            message.productSourcingMethod = reader.int32();
                            break;
                        }
                    case 19: {
                            message.quantityLimit = reader.string();
                            break;
                        }
                    case 20: {
                            message.quantityLimitExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskOutputPriorAuthInfoDetail message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskOutputPriorAuthInfoDetail} INFPATaskOutputPriorAuthInfoDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputPriorAuthInfoDetail.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskOutputPriorAuthInfoDetail message.
             * @function verify
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskOutputPriorAuthInfoDetail.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.priorAuthApprovalDate != null && message.hasOwnProperty("priorAuthApprovalDate"))
                    if (!$util.isString(message.priorAuthApprovalDate))
                        return "priorAuthApprovalDate: string expected";
                if (message.priorAuthApprovalLevel != null && message.hasOwnProperty("priorAuthApprovalLevel"))
                    switch (message.priorAuthApprovalLevel) {
                    default:
                        return "priorAuthApprovalLevel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber"))
                    if (!$util.isString(message.priorAuthApprovalNumber))
                        return "priorAuthApprovalNumber: string expected";
                if (message.priorAuthCodesOnFile != null && message.hasOwnProperty("priorAuthCodesOnFile")) {
                    if (!Array.isArray(message.priorAuthCodesOnFile))
                        return "priorAuthCodesOnFile: array expected";
                    for (var i = 0; i < message.priorAuthCodesOnFile.length; ++i)
                        if (!$util.isString(message.priorAuthCodesOnFile[i]))
                            return "priorAuthCodesOnFile: string[] expected";
                }
                if (message.priorAuthCompletionDate != null && message.hasOwnProperty("priorAuthCompletionDate"))
                    if (!$util.isString(message.priorAuthCompletionDate))
                        return "priorAuthCompletionDate: string expected";
                if (message.priorAuthDenialReason != null && message.hasOwnProperty("priorAuthDenialReason"))
                    switch (message.priorAuthDenialReason) {
                    default:
                        return "priorAuthDenialReason: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                if (message.priorAuthDenialReasonOther != null && message.hasOwnProperty("priorAuthDenialReasonOther"))
                    if (!$util.isString(message.priorAuthDenialReasonOther))
                        return "priorAuthDenialReasonOther: string expected";
                if (message.priorAuthDoseOnFile != null && message.hasOwnProperty("priorAuthDoseOnFile"))
                    if (!$util.isString(message.priorAuthDoseOnFile))
                        return "priorAuthDoseOnFile: string expected";
                if (message.priorAuthEndDate != null && message.hasOwnProperty("priorAuthEndDate"))
                    if (!$util.isString(message.priorAuthEndDate))
                        return "priorAuthEndDate: string expected";
                if (message.priorAuthEntityOnFile != null && message.hasOwnProperty("priorAuthEntityOnFile")) {
                    var error = $root.infinitusapi.INFPriorAuthEntityOnFile.verify(message.priorAuthEntityOnFile);
                    if (error)
                        return "priorAuthEntityOnFile." + error;
                }
                if (message.priorAuthLockedToSpecificEntity != null && message.hasOwnProperty("priorAuthLockedToSpecificEntity")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthLockedToSpecificEntity);
                    if (error)
                        return "priorAuthLockedToSpecificEntity." + error;
                }
                if (message.priorAuthNotificationMethods != null && message.hasOwnProperty("priorAuthNotificationMethods")) {
                    if (!Array.isArray(message.priorAuthNotificationMethods))
                        return "priorAuthNotificationMethods: array expected";
                    for (var i = 0; i < message.priorAuthNotificationMethods.length; ++i)
                        switch (message.priorAuthNotificationMethods[i]) {
                        default:
                            return "priorAuthNotificationMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.priorAuthOnFileApplies != null && message.hasOwnProperty("priorAuthOnFileApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthOnFileApplies);
                    if (error)
                        return "priorAuthOnFileApplies." + error;
                }
                if (message.priorAuthStartDate != null && message.hasOwnProperty("priorAuthStartDate"))
                    if (!$util.isString(message.priorAuthStartDate))
                        return "priorAuthStartDate: string expected";
                if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                    switch (message.priorAuthStatus) {
                    default:
                        return "priorAuthStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                if (message.priorAuthSubmissionMethods != null && message.hasOwnProperty("priorAuthSubmissionMethods")) {
                    if (!Array.isArray(message.priorAuthSubmissionMethods))
                        return "priorAuthSubmissionMethods: array expected";
                    for (var i = 0; i < message.priorAuthSubmissionMethods.length; ++i)
                        switch (message.priorAuthSubmissionMethods[i]) {
                        default:
                            return "priorAuthSubmissionMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                }
                if (message.priorAuthTurnaroundNotes != null && message.hasOwnProperty("priorAuthTurnaroundNotes"))
                    if (!$util.isString(message.priorAuthTurnaroundNotes))
                        return "priorAuthTurnaroundNotes: string expected";
                if (message.productSourcingMethod != null && message.hasOwnProperty("productSourcingMethod"))
                    switch (message.productSourcingMethod) {
                    default:
                        return "productSourcingMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.quantityLimit != null && message.hasOwnProperty("quantityLimit"))
                    if (!$util.isString(message.quantityLimit))
                        return "quantityLimit: string expected";
                if (message.quantityLimitExists != null && message.hasOwnProperty("quantityLimitExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.quantityLimitExists);
                    if (error)
                        return "quantityLimitExists." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPATaskOutputPriorAuthInfoDetail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskOutputPriorAuthInfoDetail} INFPATaskOutputPriorAuthInfoDetail
             */
            INFPATaskOutputPriorAuthInfoDetail.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskOutputPriorAuthInfoDetail)
                    return object;
                var message = new $root.infinitusapi.INFPATaskOutputPriorAuthInfoDetail();
                if (object.priorAuthApprovalDate != null)
                    message.priorAuthApprovalDate = String(object.priorAuthApprovalDate);
                switch (object.priorAuthApprovalLevel) {
                default:
                    if (typeof object.priorAuthApprovalLevel === "number") {
                        message.priorAuthApprovalLevel = object.priorAuthApprovalLevel;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_APPROVAL_LEVEL_NDC":
                case 0:
                    message.priorAuthApprovalLevel = 0;
                    break;
                case "INF_PRIOR_AUTH_APPROVAL_LEVEL_DRUG":
                case 1:
                    message.priorAuthApprovalLevel = 1;
                    break;
                case "INF_PRIOR_AUTH_APPROVAL_LEVEL_NOT_APPLICABLE":
                case 2:
                    message.priorAuthApprovalLevel = 2;
                    break;
                case "INF_PRIOR_AUTH_APPROVAL_LEVEL_UNKNOWN":
                case 3:
                    message.priorAuthApprovalLevel = 3;
                    break;
                }
                if (object.priorAuthApprovalNumber != null)
                    message.priorAuthApprovalNumber = String(object.priorAuthApprovalNumber);
                if (object.priorAuthCodesOnFile) {
                    if (!Array.isArray(object.priorAuthCodesOnFile))
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfoDetail.priorAuthCodesOnFile: array expected");
                    message.priorAuthCodesOnFile = [];
                    for (var i = 0; i < object.priorAuthCodesOnFile.length; ++i)
                        message.priorAuthCodesOnFile[i] = String(object.priorAuthCodesOnFile[i]);
                }
                if (object.priorAuthCompletionDate != null)
                    message.priorAuthCompletionDate = String(object.priorAuthCompletionDate);
                switch (object.priorAuthDenialReason) {
                default:
                    if (typeof object.priorAuthDenialReason === "number") {
                        message.priorAuthDenialReason = object.priorAuthDenialReason;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_OTHER":
                case 0:
                    message.priorAuthDenialReason = 0;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_MISSING_CLINICAL_DOCUMENTATION":
                case 1:
                    message.priorAuthDenialReason = 1;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_CRITERIA_NOT_MET":
                case 2:
                    message.priorAuthDenialReason = 2;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_LACK_OF_MEDICAL_NECESSITY":
                case 3:
                    message.priorAuthDenialReason = 3;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_MISSING_LETTER_OF_MEDICAL_NECESSITY":
                case 4:
                    message.priorAuthDenialReason = 4;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_NON_FORMULARY_DRUG":
                case 5:
                    message.priorAuthDenialReason = 5;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_STEP_THERAPY_REQUIRED":
                case 6:
                    message.priorAuthDenialReason = 6;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_NON_PREFERRED_TREATMENT":
                case 7:
                    message.priorAuthDenialReason = 7;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_DATE_OF_SERVICE_PASSED":
                case 8:
                    message.priorAuthDenialReason = 8;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_PROVIDER_NOT_IN_NETWORK":
                case 9:
                    message.priorAuthDenialReason = 9;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_NOT_COVERED_BY_PATIENT_PLAN":
                case 10:
                    message.priorAuthDenialReason = 10;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_PATIENT_PLAN_PROCEDURES_NOT_FOLLOWED":
                case 11:
                    message.priorAuthDenialReason = 11;
                    break;
                case "INF_PRIOR_AUTH_DENIAL_REASON_NOT_APPLICABLE":
                case 12:
                    message.priorAuthDenialReason = 12;
                    break;
                }
                if (object.priorAuthDenialReasonOther != null)
                    message.priorAuthDenialReasonOther = String(object.priorAuthDenialReasonOther);
                if (object.priorAuthDoseOnFile != null)
                    message.priorAuthDoseOnFile = String(object.priorAuthDoseOnFile);
                if (object.priorAuthEndDate != null)
                    message.priorAuthEndDate = String(object.priorAuthEndDate);
                if (object.priorAuthEntityOnFile != null) {
                    if (typeof object.priorAuthEntityOnFile !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfoDetail.priorAuthEntityOnFile: object expected");
                    message.priorAuthEntityOnFile = $root.infinitusapi.INFPriorAuthEntityOnFile.fromObject(object.priorAuthEntityOnFile);
                }
                if (object.priorAuthLockedToSpecificEntity != null) {
                    if (typeof object.priorAuthLockedToSpecificEntity !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfoDetail.priorAuthLockedToSpecificEntity: object expected");
                    message.priorAuthLockedToSpecificEntity = $root.google.protobuf.BoolValue.fromObject(object.priorAuthLockedToSpecificEntity);
                }
                if (object.priorAuthNotificationMethods) {
                    if (!Array.isArray(object.priorAuthNotificationMethods))
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfoDetail.priorAuthNotificationMethods: array expected");
                    message.priorAuthNotificationMethods = [];
                    for (var i = 0; i < object.priorAuthNotificationMethods.length; ++i)
                        switch (object.priorAuthNotificationMethods[i]) {
                        default:
                            if (typeof object.priorAuthNotificationMethods[i] === "number") {
                                message.priorAuthNotificationMethods[i] = object.priorAuthNotificationMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                        case 0:
                            message.priorAuthNotificationMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.priorAuthNotificationMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                        case 2:
                            message.priorAuthNotificationMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                        case 3:
                            message.priorAuthNotificationMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                        case 4:
                            message.priorAuthNotificationMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                        case 5:
                            message.priorAuthNotificationMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                        case 6:
                            message.priorAuthNotificationMethods[i] = 6;
                            break;
                        }
                }
                if (object.priorAuthOnFileApplies != null) {
                    if (typeof object.priorAuthOnFileApplies !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfoDetail.priorAuthOnFileApplies: object expected");
                    message.priorAuthOnFileApplies = $root.google.protobuf.BoolValue.fromObject(object.priorAuthOnFileApplies);
                }
                if (object.priorAuthStartDate != null)
                    message.priorAuthStartDate = String(object.priorAuthStartDate);
                switch (object.priorAuthStatus) {
                default:
                    if (typeof object.priorAuthStatus === "number") {
                        message.priorAuthStatus = object.priorAuthStatus;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_STATUS_UNKNOWN":
                case 0:
                    message.priorAuthStatus = 0;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_APPLICABLE":
                case 1:
                    message.priorAuthStatus = 1;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_ON_FILE":
                case 2:
                    message.priorAuthStatus = 2;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED":
                case 3:
                    message.priorAuthStatus = 3;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR":
                case 4:
                    message.priorAuthStatus = 4;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS":
                case 5:
                    message.priorAuthStatus = 5;
                    break;
                case "INF_PRIOR_AUTH_STATUS_DENIED":
                case 6:
                    message.priorAuthStatus = 6;
                    break;
                case "INF_PRIOR_AUTH_STATUS_EXPIRED":
                case 7:
                    message.priorAuthStatus = 7;
                    break;
                case "INF_PRIOR_AUTH_STATUS_FUTURE":
                case 8:
                    message.priorAuthStatus = 8;
                    break;
                case "INF_PRIOR_AUTH_STATUS_PENDING":
                case 9:
                    message.priorAuthStatus = 9;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS":
                case 10:
                    message.priorAuthStatus = 10;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS":
                case 11:
                    message.priorAuthStatus = 11;
                    break;
                }
                if (object.priorAuthSubmissionMethods) {
                    if (!Array.isArray(object.priorAuthSubmissionMethods))
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfoDetail.priorAuthSubmissionMethods: array expected");
                    message.priorAuthSubmissionMethods = [];
                    for (var i = 0; i < object.priorAuthSubmissionMethods.length; ++i)
                        switch (object.priorAuthSubmissionMethods[i]) {
                        default:
                            if (typeof object.priorAuthSubmissionMethods[i] === "number") {
                                message.priorAuthSubmissionMethods[i] = object.priorAuthSubmissionMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN":
                        case 0:
                            message.priorAuthSubmissionMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.priorAuthSubmissionMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE":
                        case 2:
                            message.priorAuthSubmissionMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX":
                        case 3:
                            message.priorAuthSubmissionMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL":
                        case 4:
                            message.priorAuthSubmissionMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE":
                        case 5:
                            message.priorAuthSubmissionMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL":
                        case 6:
                            message.priorAuthSubmissionMethods[i] = 6;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION":
                        case 7:
                            message.priorAuthSubmissionMethods[i] = 7;
                            break;
                        }
                }
                if (object.priorAuthTurnaroundNotes != null)
                    message.priorAuthTurnaroundNotes = String(object.priorAuthTurnaroundNotes);
                switch (object.productSourcingMethod) {
                default:
                    if (typeof object.productSourcingMethod === "number") {
                        message.productSourcingMethod = object.productSourcingMethod;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_BUY_AND_BILL":
                case 0:
                    message.productSourcingMethod = 0;
                    break;
                case "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_SPECIALTY_PHARMACY":
                case 1:
                    message.productSourcingMethod = 1;
                    break;
                case "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_PHARMACY_BENEFIT_MANAGER":
                case 2:
                    message.productSourcingMethod = 2;
                    break;
                case "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_NOT_APPLICABLE":
                case 3:
                    message.productSourcingMethod = 3;
                    break;
                }
                if (object.quantityLimit != null)
                    message.quantityLimit = String(object.quantityLimit);
                if (object.quantityLimitExists != null) {
                    if (typeof object.quantityLimitExists !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputPriorAuthInfoDetail.quantityLimitExists: object expected");
                    message.quantityLimitExists = $root.google.protobuf.BoolValue.fromObject(object.quantityLimitExists);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskOutputPriorAuthInfoDetail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {infinitusapi.INFPATaskOutputPriorAuthInfoDetail} message INFPATaskOutputPriorAuthInfoDetail
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskOutputPriorAuthInfoDetail.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.priorAuthCodesOnFile = [];
                    object.priorAuthNotificationMethods = [];
                    object.priorAuthSubmissionMethods = [];
                }
                if (options.defaults) {
                    object.priorAuthApprovalDate = "";
                    object.priorAuthApprovalLevel = options.enums === String ? "INF_PRIOR_AUTH_APPROVAL_LEVEL_NDC" : 0;
                    object.priorAuthApprovalNumber = "";
                    object.priorAuthCompletionDate = "";
                    object.priorAuthDenialReason = options.enums === String ? "INF_PRIOR_AUTH_DENIAL_REASON_OTHER" : 0;
                    object.priorAuthDenialReasonOther = "";
                    object.priorAuthDoseOnFile = "";
                    object.priorAuthEndDate = "";
                    object.priorAuthEntityOnFile = null;
                    object.priorAuthLockedToSpecificEntity = null;
                    object.priorAuthOnFileApplies = null;
                    object.priorAuthStartDate = "";
                    object.priorAuthStatus = options.enums === String ? "INF_PRIOR_AUTH_STATUS_UNKNOWN" : 0;
                    object.priorAuthTurnaroundNotes = "";
                    object.productSourcingMethod = options.enums === String ? "INF_PRIOR_AUTH_PRODUCT_SOURCING_METHOD_BUY_AND_BILL" : 0;
                    object.quantityLimit = "";
                    object.quantityLimitExists = null;
                }
                if (message.priorAuthApprovalDate != null && message.hasOwnProperty("priorAuthApprovalDate"))
                    object.priorAuthApprovalDate = message.priorAuthApprovalDate;
                if (message.priorAuthApprovalLevel != null && message.hasOwnProperty("priorAuthApprovalLevel"))
                    object.priorAuthApprovalLevel = options.enums === String ? $root.infinitusapi.INFPriorAuthApprovalLevel[message.priorAuthApprovalLevel] === undefined ? message.priorAuthApprovalLevel : $root.infinitusapi.INFPriorAuthApprovalLevel[message.priorAuthApprovalLevel] : message.priorAuthApprovalLevel;
                if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber"))
                    object.priorAuthApprovalNumber = message.priorAuthApprovalNumber;
                if (message.priorAuthCodesOnFile && message.priorAuthCodesOnFile.length) {
                    object.priorAuthCodesOnFile = [];
                    for (var j = 0; j < message.priorAuthCodesOnFile.length; ++j)
                        object.priorAuthCodesOnFile[j] = message.priorAuthCodesOnFile[j];
                }
                if (message.priorAuthCompletionDate != null && message.hasOwnProperty("priorAuthCompletionDate"))
                    object.priorAuthCompletionDate = message.priorAuthCompletionDate;
                if (message.priorAuthDenialReason != null && message.hasOwnProperty("priorAuthDenialReason"))
                    object.priorAuthDenialReason = options.enums === String ? $root.infinitusapi.INFPriorAuthDenialReason[message.priorAuthDenialReason] === undefined ? message.priorAuthDenialReason : $root.infinitusapi.INFPriorAuthDenialReason[message.priorAuthDenialReason] : message.priorAuthDenialReason;
                if (message.priorAuthDenialReasonOther != null && message.hasOwnProperty("priorAuthDenialReasonOther"))
                    object.priorAuthDenialReasonOther = message.priorAuthDenialReasonOther;
                if (message.priorAuthDoseOnFile != null && message.hasOwnProperty("priorAuthDoseOnFile"))
                    object.priorAuthDoseOnFile = message.priorAuthDoseOnFile;
                if (message.priorAuthEndDate != null && message.hasOwnProperty("priorAuthEndDate"))
                    object.priorAuthEndDate = message.priorAuthEndDate;
                if (message.priorAuthEntityOnFile != null && message.hasOwnProperty("priorAuthEntityOnFile"))
                    object.priorAuthEntityOnFile = $root.infinitusapi.INFPriorAuthEntityOnFile.toObject(message.priorAuthEntityOnFile, options);
                if (message.priorAuthLockedToSpecificEntity != null && message.hasOwnProperty("priorAuthLockedToSpecificEntity"))
                    object.priorAuthLockedToSpecificEntity = $root.google.protobuf.BoolValue.toObject(message.priorAuthLockedToSpecificEntity, options);
                if (message.priorAuthNotificationMethods && message.priorAuthNotificationMethods.length) {
                    object.priorAuthNotificationMethods = [];
                    for (var j = 0; j < message.priorAuthNotificationMethods.length; ++j)
                        object.priorAuthNotificationMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethods[j]] === undefined ? message.priorAuthNotificationMethods[j] : $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethods[j]] : message.priorAuthNotificationMethods[j];
                }
                if (message.priorAuthOnFileApplies != null && message.hasOwnProperty("priorAuthOnFileApplies"))
                    object.priorAuthOnFileApplies = $root.google.protobuf.BoolValue.toObject(message.priorAuthOnFileApplies, options);
                if (message.priorAuthStartDate != null && message.hasOwnProperty("priorAuthStartDate"))
                    object.priorAuthStartDate = message.priorAuthStartDate;
                if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                    object.priorAuthStatus = options.enums === String ? $root.infinitusapi.INFPriorAuthStatus[message.priorAuthStatus] === undefined ? message.priorAuthStatus : $root.infinitusapi.INFPriorAuthStatus[message.priorAuthStatus] : message.priorAuthStatus;
                if (message.priorAuthSubmissionMethods && message.priorAuthSubmissionMethods.length) {
                    object.priorAuthSubmissionMethods = [];
                    for (var j = 0; j < message.priorAuthSubmissionMethods.length; ++j)
                        object.priorAuthSubmissionMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthSubmissionMethod[message.priorAuthSubmissionMethods[j]] === undefined ? message.priorAuthSubmissionMethods[j] : $root.infinitusapi.INFPriorAuthSubmissionMethod[message.priorAuthSubmissionMethods[j]] : message.priorAuthSubmissionMethods[j];
                }
                if (message.priorAuthTurnaroundNotes != null && message.hasOwnProperty("priorAuthTurnaroundNotes"))
                    object.priorAuthTurnaroundNotes = message.priorAuthTurnaroundNotes;
                if (message.productSourcingMethod != null && message.hasOwnProperty("productSourcingMethod"))
                    object.productSourcingMethod = options.enums === String ? $root.infinitusapi.INFPriorAuthProductSourcingMethod[message.productSourcingMethod] === undefined ? message.productSourcingMethod : $root.infinitusapi.INFPriorAuthProductSourcingMethod[message.productSourcingMethod] : message.productSourcingMethod;
                if (message.quantityLimit != null && message.hasOwnProperty("quantityLimit"))
                    object.quantityLimit = message.quantityLimit;
                if (message.quantityLimitExists != null && message.hasOwnProperty("quantityLimitExists"))
                    object.quantityLimitExists = $root.google.protobuf.BoolValue.toObject(message.quantityLimitExists, options);
                return object;
            };
    
            /**
             * Converts this INFPATaskOutputPriorAuthInfoDetail to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskOutputPriorAuthInfoDetail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskOutputPriorAuthInfoDetail
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskOutputPriorAuthInfoDetail
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskOutputPriorAuthInfoDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskOutputPriorAuthInfoDetail";
            };
    
            return INFPATaskOutputPriorAuthInfoDetail;
        })();
    
        infinitusapi.INFPATaskOutputProductCodePARequirements = (function() {
    
            /**
             * Properties of a INFPATaskOutputProductCodePARequirements.
             * @memberof infinitusapi
             * @interface IINFPATaskOutputProductCodePARequirements
             * @property {string|null} [code] INFPATaskOutputProductCodePARequirements code
             * @property {google.protobuf.IBoolValue|null} [priorAuthRequired] INFPATaskOutputProductCodePARequirements priorAuthRequired
             */
    
            /**
             * Constructs a new INFPATaskOutputProductCodePARequirements.
             * @memberof infinitusapi
             * @classdesc Represents a INFPATaskOutputProductCodePARequirements.
             * @implements IINFPATaskOutputProductCodePARequirements
             * @constructor
             * @param {infinitusapi.IINFPATaskOutputProductCodePARequirements=} [properties] Properties to set
             */
            function INFPATaskOutputProductCodePARequirements(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPATaskOutputProductCodePARequirements code.
             * @member {string} code
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @instance
             */
            INFPATaskOutputProductCodePARequirements.prototype.code = "";
    
            /**
             * INFPATaskOutputProductCodePARequirements priorAuthRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthRequired
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @instance
             */
            INFPATaskOutputProductCodePARequirements.prototype.priorAuthRequired = null;
    
            /**
             * Creates a new INFPATaskOutputProductCodePARequirements instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {infinitusapi.IINFPATaskOutputProductCodePARequirements=} [properties] Properties to set
             * @returns {infinitusapi.INFPATaskOutputProductCodePARequirements} INFPATaskOutputProductCodePARequirements instance
             */
            INFPATaskOutputProductCodePARequirements.create = function create(properties) {
                return new INFPATaskOutputProductCodePARequirements(properties);
            };
    
            /**
             * Encodes the specified INFPATaskOutputProductCodePARequirements message. Does not implicitly {@link infinitusapi.INFPATaskOutputProductCodePARequirements.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {infinitusapi.IINFPATaskOutputProductCodePARequirements} message INFPATaskOutputProductCodePARequirements message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputProductCodePARequirements.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                if (message.priorAuthRequired != null && Object.hasOwnProperty.call(message, "priorAuthRequired"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthRequired, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPATaskOutputProductCodePARequirements message, length delimited. Does not implicitly {@link infinitusapi.INFPATaskOutputProductCodePARequirements.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {infinitusapi.IINFPATaskOutputProductCodePARequirements} message INFPATaskOutputProductCodePARequirements message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPATaskOutputProductCodePARequirements.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPATaskOutputProductCodePARequirements message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPATaskOutputProductCodePARequirements} INFPATaskOutputProductCodePARequirements
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputProductCodePARequirements.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPATaskOutputProductCodePARequirements();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    case 2: {
                            message.priorAuthRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPATaskOutputProductCodePARequirements message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPATaskOutputProductCodePARequirements} INFPATaskOutputProductCodePARequirements
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPATaskOutputProductCodePARequirements.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPATaskOutputProductCodePARequirements message.
             * @function verify
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPATaskOutputProductCodePARequirements.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthRequired);
                    if (error)
                        return "priorAuthRequired." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPATaskOutputProductCodePARequirements message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPATaskOutputProductCodePARequirements} INFPATaskOutputProductCodePARequirements
             */
            INFPATaskOutputProductCodePARequirements.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPATaskOutputProductCodePARequirements)
                    return object;
                var message = new $root.infinitusapi.INFPATaskOutputProductCodePARequirements();
                if (object.code != null)
                    message.code = String(object.code);
                if (object.priorAuthRequired != null) {
                    if (typeof object.priorAuthRequired !== "object")
                        throw TypeError(".infinitusapi.INFPATaskOutputProductCodePARequirements.priorAuthRequired: object expected");
                    message.priorAuthRequired = $root.google.protobuf.BoolValue.fromObject(object.priorAuthRequired);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPATaskOutputProductCodePARequirements message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {infinitusapi.INFPATaskOutputProductCodePARequirements} message INFPATaskOutputProductCodePARequirements
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPATaskOutputProductCodePARequirements.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.priorAuthRequired = null;
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired"))
                    object.priorAuthRequired = $root.google.protobuf.BoolValue.toObject(message.priorAuthRequired, options);
                return object;
            };
    
            /**
             * Converts this INFPATaskOutputProductCodePARequirements to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPATaskOutputProductCodePARequirements.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPATaskOutputProductCodePARequirements
             * @function getTypeUrl
             * @memberof infinitusapi.INFPATaskOutputProductCodePARequirements
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPATaskOutputProductCodePARequirements.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPATaskOutputProductCodePARequirements";
            };
    
            return INFPATaskOutputProductCodePARequirements;
        })();
    
        infinitusapi.INFPBMDiscoveryTaskInput = (function() {
    
            /**
             * Properties of a INFPBMDiscoveryTaskInput.
             * @memberof infinitusapi
             * @interface IINFPBMDiscoveryTaskInput
             * @property {infinitusapi.IINFTaskInputMember|null} [member] INFPBMDiscoveryTaskInput member
             * @property {infinitusapi.IINFTaskInputPractice|null} [practice] INFPBMDiscoveryTaskInput practice
             * @property {infinitusapi.IINFTaskInputProvider|null} [provider] INFPBMDiscoveryTaskInput provider
             * @property {Array.<infinitusapi.IINFTaskInputProduct>|null} [products] INFPBMDiscoveryTaskInput products
             * @property {Array.<infinitusapi.IINFTaskInputDiagnosis>|null} [diagnosis] INFPBMDiscoveryTaskInput diagnosis
             * @property {boolean|null} [chainPBM] INFPBMDiscoveryTaskInput chainPBM
             * @property {infinitusapi.IINFPBMDiscoveryTaskInputPayer|null} [payer] INFPBMDiscoveryTaskInput payer
             * @property {string|null} [treatmentDate] INFPBMDiscoveryTaskInput treatmentDate
             * @property {infinitusapi.IINFBVTaskInputPolicyHolder|null} [policyHolder] INFPBMDiscoveryTaskInput policyHolder
             */
    
            /**
             * Constructs a new INFPBMDiscoveryTaskInput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMDiscoveryTaskInput.
             * @implements IINFPBMDiscoveryTaskInput
             * @constructor
             * @param {infinitusapi.IINFPBMDiscoveryTaskInput=} [properties] Properties to set
             */
            function INFPBMDiscoveryTaskInput(properties) {
                this.products = [];
                this.diagnosis = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMDiscoveryTaskInput member.
             * @member {infinitusapi.IINFTaskInputMember|null|undefined} member
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.member = null;
    
            /**
             * INFPBMDiscoveryTaskInput practice.
             * @member {infinitusapi.IINFTaskInputPractice|null|undefined} practice
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.practice = null;
    
            /**
             * INFPBMDiscoveryTaskInput provider.
             * @member {infinitusapi.IINFTaskInputProvider|null|undefined} provider
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.provider = null;
    
            /**
             * INFPBMDiscoveryTaskInput products.
             * @member {Array.<infinitusapi.IINFTaskInputProduct>} products
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.products = $util.emptyArray;
    
            /**
             * INFPBMDiscoveryTaskInput diagnosis.
             * @member {Array.<infinitusapi.IINFTaskInputDiagnosis>} diagnosis
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.diagnosis = $util.emptyArray;
    
            /**
             * INFPBMDiscoveryTaskInput chainPBM.
             * @member {boolean} chainPBM
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.chainPBM = false;
    
            /**
             * INFPBMDiscoveryTaskInput payer.
             * @member {infinitusapi.IINFPBMDiscoveryTaskInputPayer|null|undefined} payer
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.payer = null;
    
            /**
             * INFPBMDiscoveryTaskInput treatmentDate.
             * @member {string} treatmentDate
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.treatmentDate = "";
    
            /**
             * INFPBMDiscoveryTaskInput policyHolder.
             * @member {infinitusapi.IINFBVTaskInputPolicyHolder|null|undefined} policyHolder
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             */
            INFPBMDiscoveryTaskInput.prototype.policyHolder = null;
    
            /**
             * Creates a new INFPBMDiscoveryTaskInput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskInput=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMDiscoveryTaskInput} INFPBMDiscoveryTaskInput instance
             */
            INFPBMDiscoveryTaskInput.create = function create(properties) {
                return new INFPBMDiscoveryTaskInput(properties);
            };
    
            /**
             * Encodes the specified INFPBMDiscoveryTaskInput message. Does not implicitly {@link infinitusapi.INFPBMDiscoveryTaskInput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskInput} message INFPBMDiscoveryTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMDiscoveryTaskInput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.member != null && Object.hasOwnProperty.call(message, "member"))
                    $root.infinitusapi.INFTaskInputMember.encode(message.member, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.practice != null && Object.hasOwnProperty.call(message, "practice"))
                    $root.infinitusapi.INFTaskInputPractice.encode(message.practice, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
                    $root.infinitusapi.INFTaskInputProvider.encode(message.provider, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.products != null && message.products.length)
                    for (var i = 0; i < message.products.length; ++i)
                        $root.infinitusapi.INFTaskInputProduct.encode(message.products[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.diagnosis != null && message.diagnosis.length)
                    for (var i = 0; i < message.diagnosis.length; ++i)
                        $root.infinitusapi.INFTaskInputDiagnosis.encode(message.diagnosis[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.chainPBM != null && Object.hasOwnProperty.call(message, "chainPBM"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.chainPBM);
                if (message.payer != null && Object.hasOwnProperty.call(message, "payer"))
                    $root.infinitusapi.INFPBMDiscoveryTaskInputPayer.encode(message.payer, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.treatmentDate != null && Object.hasOwnProperty.call(message, "treatmentDate"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.treatmentDate);
                if (message.policyHolder != null && Object.hasOwnProperty.call(message, "policyHolder"))
                    $root.infinitusapi.INFBVTaskInputPolicyHolder.encode(message.policyHolder, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMDiscoveryTaskInput message, length delimited. Does not implicitly {@link infinitusapi.INFPBMDiscoveryTaskInput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskInput} message INFPBMDiscoveryTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMDiscoveryTaskInput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMDiscoveryTaskInput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMDiscoveryTaskInput} INFPBMDiscoveryTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMDiscoveryTaskInput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMDiscoveryTaskInput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.member = $root.infinitusapi.INFTaskInputMember.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.practice = $root.infinitusapi.INFTaskInputPractice.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.provider = $root.infinitusapi.INFTaskInputProvider.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            if (!(message.products && message.products.length))
                                message.products = [];
                            message.products.push($root.infinitusapi.INFTaskInputProduct.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.diagnosis && message.diagnosis.length))
                                message.diagnosis = [];
                            message.diagnosis.push($root.infinitusapi.INFTaskInputDiagnosis.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            message.chainPBM = reader.bool();
                            break;
                        }
                    case 7: {
                            message.payer = $root.infinitusapi.INFPBMDiscoveryTaskInputPayer.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.treatmentDate = reader.string();
                            break;
                        }
                    case 9: {
                            message.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMDiscoveryTaskInput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMDiscoveryTaskInput} INFPBMDiscoveryTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMDiscoveryTaskInput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMDiscoveryTaskInput message.
             * @function verify
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMDiscoveryTaskInput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.member != null && message.hasOwnProperty("member")) {
                    var error = $root.infinitusapi.INFTaskInputMember.verify(message.member);
                    if (error)
                        return "member." + error;
                }
                if (message.practice != null && message.hasOwnProperty("practice")) {
                    var error = $root.infinitusapi.INFTaskInputPractice.verify(message.practice);
                    if (error)
                        return "practice." + error;
                }
                if (message.provider != null && message.hasOwnProperty("provider")) {
                    var error = $root.infinitusapi.INFTaskInputProvider.verify(message.provider);
                    if (error)
                        return "provider." + error;
                }
                if (message.products != null && message.hasOwnProperty("products")) {
                    if (!Array.isArray(message.products))
                        return "products: array expected";
                    for (var i = 0; i < message.products.length; ++i) {
                        var error = $root.infinitusapi.INFTaskInputProduct.verify(message.products[i]);
                        if (error)
                            return "products." + error;
                    }
                }
                if (message.diagnosis != null && message.hasOwnProperty("diagnosis")) {
                    if (!Array.isArray(message.diagnosis))
                        return "diagnosis: array expected";
                    for (var i = 0; i < message.diagnosis.length; ++i) {
                        var error = $root.infinitusapi.INFTaskInputDiagnosis.verify(message.diagnosis[i]);
                        if (error)
                            return "diagnosis." + error;
                    }
                }
                if (message.chainPBM != null && message.hasOwnProperty("chainPBM"))
                    if (typeof message.chainPBM !== "boolean")
                        return "chainPBM: boolean expected";
                if (message.payer != null && message.hasOwnProperty("payer")) {
                    var error = $root.infinitusapi.INFPBMDiscoveryTaskInputPayer.verify(message.payer);
                    if (error)
                        return "payer." + error;
                }
                if (message.treatmentDate != null && message.hasOwnProperty("treatmentDate"))
                    if (!$util.isString(message.treatmentDate))
                        return "treatmentDate: string expected";
                if (message.policyHolder != null && message.hasOwnProperty("policyHolder")) {
                    var error = $root.infinitusapi.INFBVTaskInputPolicyHolder.verify(message.policyHolder);
                    if (error)
                        return "policyHolder." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPBMDiscoveryTaskInput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMDiscoveryTaskInput} INFPBMDiscoveryTaskInput
             */
            INFPBMDiscoveryTaskInput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMDiscoveryTaskInput)
                    return object;
                var message = new $root.infinitusapi.INFPBMDiscoveryTaskInput();
                if (object.member != null) {
                    if (typeof object.member !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.member: object expected");
                    message.member = $root.infinitusapi.INFTaskInputMember.fromObject(object.member);
                }
                if (object.practice != null) {
                    if (typeof object.practice !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.practice: object expected");
                    message.practice = $root.infinitusapi.INFTaskInputPractice.fromObject(object.practice);
                }
                if (object.provider != null) {
                    if (typeof object.provider !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.provider: object expected");
                    message.provider = $root.infinitusapi.INFTaskInputProvider.fromObject(object.provider);
                }
                if (object.products) {
                    if (!Array.isArray(object.products))
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.products: array expected");
                    message.products = [];
                    for (var i = 0; i < object.products.length; ++i) {
                        if (typeof object.products[i] !== "object")
                            throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.products: object expected");
                        message.products[i] = $root.infinitusapi.INFTaskInputProduct.fromObject(object.products[i]);
                    }
                }
                if (object.diagnosis) {
                    if (!Array.isArray(object.diagnosis))
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.diagnosis: array expected");
                    message.diagnosis = [];
                    for (var i = 0; i < object.diagnosis.length; ++i) {
                        if (typeof object.diagnosis[i] !== "object")
                            throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.diagnosis: object expected");
                        message.diagnosis[i] = $root.infinitusapi.INFTaskInputDiagnosis.fromObject(object.diagnosis[i]);
                    }
                }
                if (object.chainPBM != null)
                    message.chainPBM = Boolean(object.chainPBM);
                if (object.payer != null) {
                    if (typeof object.payer !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.payer: object expected");
                    message.payer = $root.infinitusapi.INFPBMDiscoveryTaskInputPayer.fromObject(object.payer);
                }
                if (object.treatmentDate != null)
                    message.treatmentDate = String(object.treatmentDate);
                if (object.policyHolder != null) {
                    if (typeof object.policyHolder !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskInput.policyHolder: object expected");
                    message.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.fromObject(object.policyHolder);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMDiscoveryTaskInput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {infinitusapi.INFPBMDiscoveryTaskInput} message INFPBMDiscoveryTaskInput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMDiscoveryTaskInput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.products = [];
                    object.diagnosis = [];
                }
                if (options.defaults) {
                    object.member = null;
                    object.practice = null;
                    object.provider = null;
                    object.chainPBM = false;
                    object.payer = null;
                    object.treatmentDate = "";
                    object.policyHolder = null;
                }
                if (message.member != null && message.hasOwnProperty("member"))
                    object.member = $root.infinitusapi.INFTaskInputMember.toObject(message.member, options);
                if (message.practice != null && message.hasOwnProperty("practice"))
                    object.practice = $root.infinitusapi.INFTaskInputPractice.toObject(message.practice, options);
                if (message.provider != null && message.hasOwnProperty("provider"))
                    object.provider = $root.infinitusapi.INFTaskInputProvider.toObject(message.provider, options);
                if (message.products && message.products.length) {
                    object.products = [];
                    for (var j = 0; j < message.products.length; ++j)
                        object.products[j] = $root.infinitusapi.INFTaskInputProduct.toObject(message.products[j], options);
                }
                if (message.diagnosis && message.diagnosis.length) {
                    object.diagnosis = [];
                    for (var j = 0; j < message.diagnosis.length; ++j)
                        object.diagnosis[j] = $root.infinitusapi.INFTaskInputDiagnosis.toObject(message.diagnosis[j], options);
                }
                if (message.chainPBM != null && message.hasOwnProperty("chainPBM"))
                    object.chainPBM = message.chainPBM;
                if (message.payer != null && message.hasOwnProperty("payer"))
                    object.payer = $root.infinitusapi.INFPBMDiscoveryTaskInputPayer.toObject(message.payer, options);
                if (message.treatmentDate != null && message.hasOwnProperty("treatmentDate"))
                    object.treatmentDate = message.treatmentDate;
                if (message.policyHolder != null && message.hasOwnProperty("policyHolder"))
                    object.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.toObject(message.policyHolder, options);
                return object;
            };
    
            /**
             * Converts this INFPBMDiscoveryTaskInput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMDiscoveryTaskInput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMDiscoveryTaskInput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMDiscoveryTaskInput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMDiscoveryTaskInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMDiscoveryTaskInput";
            };
    
            return INFPBMDiscoveryTaskInput;
        })();
    
        infinitusapi.INFPBMDiscoveryTaskInputPayer = (function() {
    
            /**
             * Properties of a INFPBMDiscoveryTaskInputPayer.
             * @memberof infinitusapi
             * @interface IINFPBMDiscoveryTaskInputPayer
             * @property {string|null} [infinitusPayerId] INFPBMDiscoveryTaskInputPayer infinitusPayerId
             * @property {string|null} [customerPayerName] INFPBMDiscoveryTaskInputPayer customerPayerName
             * @property {string|null} [customerPayerPhoneNumber] INFPBMDiscoveryTaskInputPayer customerPayerPhoneNumber
             * @property {string|null} [subscriberId] INFPBMDiscoveryTaskInputPayer subscriberId
             * @property {string|null} [groupId] INFPBMDiscoveryTaskInputPayer groupId
             * @property {string|null} [groupName] INFPBMDiscoveryTaskInputPayer groupName
             * @property {string|null} [planName] INFPBMDiscoveryTaskInputPayer planName
             */
    
            /**
             * Constructs a new INFPBMDiscoveryTaskInputPayer.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMDiscoveryTaskInputPayer.
             * @implements IINFPBMDiscoveryTaskInputPayer
             * @constructor
             * @param {infinitusapi.IINFPBMDiscoveryTaskInputPayer=} [properties] Properties to set
             */
            function INFPBMDiscoveryTaskInputPayer(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMDiscoveryTaskInputPayer infinitusPayerId.
             * @member {string} infinitusPayerId
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @instance
             */
            INFPBMDiscoveryTaskInputPayer.prototype.infinitusPayerId = "";
    
            /**
             * INFPBMDiscoveryTaskInputPayer customerPayerName.
             * @member {string} customerPayerName
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @instance
             */
            INFPBMDiscoveryTaskInputPayer.prototype.customerPayerName = "";
    
            /**
             * INFPBMDiscoveryTaskInputPayer customerPayerPhoneNumber.
             * @member {string} customerPayerPhoneNumber
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @instance
             */
            INFPBMDiscoveryTaskInputPayer.prototype.customerPayerPhoneNumber = "";
    
            /**
             * INFPBMDiscoveryTaskInputPayer subscriberId.
             * @member {string} subscriberId
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @instance
             */
            INFPBMDiscoveryTaskInputPayer.prototype.subscriberId = "";
    
            /**
             * INFPBMDiscoveryTaskInputPayer groupId.
             * @member {string} groupId
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @instance
             */
            INFPBMDiscoveryTaskInputPayer.prototype.groupId = "";
    
            /**
             * INFPBMDiscoveryTaskInputPayer groupName.
             * @member {string} groupName
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @instance
             */
            INFPBMDiscoveryTaskInputPayer.prototype.groupName = "";
    
            /**
             * INFPBMDiscoveryTaskInputPayer planName.
             * @member {string} planName
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @instance
             */
            INFPBMDiscoveryTaskInputPayer.prototype.planName = "";
    
            /**
             * Creates a new INFPBMDiscoveryTaskInputPayer instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskInputPayer=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMDiscoveryTaskInputPayer} INFPBMDiscoveryTaskInputPayer instance
             */
            INFPBMDiscoveryTaskInputPayer.create = function create(properties) {
                return new INFPBMDiscoveryTaskInputPayer(properties);
            };
    
            /**
             * Encodes the specified INFPBMDiscoveryTaskInputPayer message. Does not implicitly {@link infinitusapi.INFPBMDiscoveryTaskInputPayer.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskInputPayer} message INFPBMDiscoveryTaskInputPayer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMDiscoveryTaskInputPayer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.infinitusPayerId != null && Object.hasOwnProperty.call(message, "infinitusPayerId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.infinitusPayerId);
                if (message.customerPayerName != null && Object.hasOwnProperty.call(message, "customerPayerName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerPayerName);
                if (message.customerPayerPhoneNumber != null && Object.hasOwnProperty.call(message, "customerPayerPhoneNumber"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.customerPayerPhoneNumber);
                if (message.subscriberId != null && Object.hasOwnProperty.call(message, "subscriberId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.subscriberId);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.groupId);
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.groupName);
                if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.planName);
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMDiscoveryTaskInputPayer message, length delimited. Does not implicitly {@link infinitusapi.INFPBMDiscoveryTaskInputPayer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskInputPayer} message INFPBMDiscoveryTaskInputPayer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMDiscoveryTaskInputPayer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMDiscoveryTaskInputPayer message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMDiscoveryTaskInputPayer} INFPBMDiscoveryTaskInputPayer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMDiscoveryTaskInputPayer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMDiscoveryTaskInputPayer();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.infinitusPayerId = reader.string();
                            break;
                        }
                    case 2: {
                            message.customerPayerName = reader.string();
                            break;
                        }
                    case 3: {
                            message.customerPayerPhoneNumber = reader.string();
                            break;
                        }
                    case 4: {
                            message.subscriberId = reader.string();
                            break;
                        }
                    case 5: {
                            message.groupId = reader.string();
                            break;
                        }
                    case 6: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 7: {
                            message.planName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMDiscoveryTaskInputPayer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMDiscoveryTaskInputPayer} INFPBMDiscoveryTaskInputPayer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMDiscoveryTaskInputPayer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMDiscoveryTaskInputPayer message.
             * @function verify
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMDiscoveryTaskInputPayer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    if (!$util.isString(message.infinitusPayerId))
                        return "infinitusPayerId: string expected";
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    if (!$util.isString(message.customerPayerName))
                        return "customerPayerName: string expected";
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    if (!$util.isString(message.customerPayerPhoneNumber))
                        return "customerPayerPhoneNumber: string expected";
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    if (!$util.isString(message.subscriberId))
                        return "subscriberId: string expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isString(message.groupId))
                        return "groupId: string expected";
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.planName != null && message.hasOwnProperty("planName"))
                    if (!$util.isString(message.planName))
                        return "planName: string expected";
                return null;
            };
    
            /**
             * Creates a INFPBMDiscoveryTaskInputPayer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMDiscoveryTaskInputPayer} INFPBMDiscoveryTaskInputPayer
             */
            INFPBMDiscoveryTaskInputPayer.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMDiscoveryTaskInputPayer)
                    return object;
                var message = new $root.infinitusapi.INFPBMDiscoveryTaskInputPayer();
                if (object.infinitusPayerId != null)
                    message.infinitusPayerId = String(object.infinitusPayerId);
                if (object.customerPayerName != null)
                    message.customerPayerName = String(object.customerPayerName);
                if (object.customerPayerPhoneNumber != null)
                    message.customerPayerPhoneNumber = String(object.customerPayerPhoneNumber);
                if (object.subscriberId != null)
                    message.subscriberId = String(object.subscriberId);
                if (object.groupId != null)
                    message.groupId = String(object.groupId);
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.planName != null)
                    message.planName = String(object.planName);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMDiscoveryTaskInputPayer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {infinitusapi.INFPBMDiscoveryTaskInputPayer} message INFPBMDiscoveryTaskInputPayer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMDiscoveryTaskInputPayer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.infinitusPayerId = "";
                    object.customerPayerName = "";
                    object.customerPayerPhoneNumber = "";
                    object.subscriberId = "";
                    object.groupId = "";
                    object.groupName = "";
                    object.planName = "";
                }
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    object.infinitusPayerId = message.infinitusPayerId;
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    object.customerPayerName = message.customerPayerName;
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    object.customerPayerPhoneNumber = message.customerPayerPhoneNumber;
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    object.subscriberId = message.subscriberId;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    object.groupId = message.groupId;
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.planName != null && message.hasOwnProperty("planName"))
                    object.planName = message.planName;
                return object;
            };
    
            /**
             * Converts this INFPBMDiscoveryTaskInputPayer to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMDiscoveryTaskInputPayer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMDiscoveryTaskInputPayer
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMDiscoveryTaskInputPayer
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMDiscoveryTaskInputPayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMDiscoveryTaskInputPayer";
            };
    
            return INFPBMDiscoveryTaskInputPayer;
        })();
    
        infinitusapi.INFPBMDiscoveryTaskOutput = (function() {
    
            /**
             * Properties of a INFPBMDiscoveryTaskOutput.
             * @memberof infinitusapi
             * @interface IINFPBMDiscoveryTaskOutput
             * @property {string|null} [payerAgentName] INFPBMDiscoveryTaskOutput payerAgentName
             * @property {string|null} [referenceNumber] INFPBMDiscoveryTaskOutput referenceNumber
             * @property {string|null} [additionalNotes] INFPBMDiscoveryTaskOutput additionalNotes
             * @property {string|null} [taskLink] INFPBMDiscoveryTaskOutput taskLink
             * @property {google.protobuf.IBoolValue|null} [reviewRequired] INFPBMDiscoveryTaskOutput reviewRequired
             * @property {infinitusapi.IINFPBMDiscoveryTaskOutputPlanInfo|null} [planInfo] INFPBMDiscoveryTaskOutput planInfo
             * @property {string|null} [billingNotes] INFPBMDiscoveryTaskOutput billingNotes
             */
    
            /**
             * Constructs a new INFPBMDiscoveryTaskOutput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMDiscoveryTaskOutput.
             * @implements IINFPBMDiscoveryTaskOutput
             * @constructor
             * @param {infinitusapi.IINFPBMDiscoveryTaskOutput=} [properties] Properties to set
             */
            function INFPBMDiscoveryTaskOutput(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMDiscoveryTaskOutput payerAgentName.
             * @member {string} payerAgentName
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @instance
             */
            INFPBMDiscoveryTaskOutput.prototype.payerAgentName = "";
    
            /**
             * INFPBMDiscoveryTaskOutput referenceNumber.
             * @member {string} referenceNumber
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @instance
             */
            INFPBMDiscoveryTaskOutput.prototype.referenceNumber = "";
    
            /**
             * INFPBMDiscoveryTaskOutput additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @instance
             */
            INFPBMDiscoveryTaskOutput.prototype.additionalNotes = "";
    
            /**
             * INFPBMDiscoveryTaskOutput taskLink.
             * @member {string} taskLink
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @instance
             */
            INFPBMDiscoveryTaskOutput.prototype.taskLink = "";
    
            /**
             * INFPBMDiscoveryTaskOutput reviewRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} reviewRequired
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @instance
             */
            INFPBMDiscoveryTaskOutput.prototype.reviewRequired = null;
    
            /**
             * INFPBMDiscoveryTaskOutput planInfo.
             * @member {infinitusapi.IINFPBMDiscoveryTaskOutputPlanInfo|null|undefined} planInfo
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @instance
             */
            INFPBMDiscoveryTaskOutput.prototype.planInfo = null;
    
            /**
             * INFPBMDiscoveryTaskOutput billingNotes.
             * @member {string} billingNotes
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @instance
             */
            INFPBMDiscoveryTaskOutput.prototype.billingNotes = "";
    
            /**
             * Creates a new INFPBMDiscoveryTaskOutput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskOutput=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMDiscoveryTaskOutput} INFPBMDiscoveryTaskOutput instance
             */
            INFPBMDiscoveryTaskOutput.create = function create(properties) {
                return new INFPBMDiscoveryTaskOutput(properties);
            };
    
            /**
             * Encodes the specified INFPBMDiscoveryTaskOutput message. Does not implicitly {@link infinitusapi.INFPBMDiscoveryTaskOutput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskOutput} message INFPBMDiscoveryTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMDiscoveryTaskOutput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.payerAgentName != null && Object.hasOwnProperty.call(message, "payerAgentName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.payerAgentName);
                if (message.referenceNumber != null && Object.hasOwnProperty.call(message, "referenceNumber"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.referenceNumber);
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.additionalNotes);
                if (message.taskLink != null && Object.hasOwnProperty.call(message, "taskLink"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskLink);
                if (message.reviewRequired != null && Object.hasOwnProperty.call(message, "reviewRequired"))
                    $root.google.protobuf.BoolValue.encode(message.reviewRequired, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.planInfo != null && Object.hasOwnProperty.call(message, "planInfo"))
                    $root.infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.encode(message.planInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.billingNotes != null && Object.hasOwnProperty.call(message, "billingNotes"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.billingNotes);
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMDiscoveryTaskOutput message, length delimited. Does not implicitly {@link infinitusapi.INFPBMDiscoveryTaskOutput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskOutput} message INFPBMDiscoveryTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMDiscoveryTaskOutput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMDiscoveryTaskOutput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMDiscoveryTaskOutput} INFPBMDiscoveryTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMDiscoveryTaskOutput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMDiscoveryTaskOutput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.payerAgentName = reader.string();
                            break;
                        }
                    case 2: {
                            message.referenceNumber = reader.string();
                            break;
                        }
                    case 3: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 4: {
                            message.taskLink = reader.string();
                            break;
                        }
                    case 5: {
                            message.reviewRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.planInfo = $root.infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.billingNotes = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMDiscoveryTaskOutput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMDiscoveryTaskOutput} INFPBMDiscoveryTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMDiscoveryTaskOutput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMDiscoveryTaskOutput message.
             * @function verify
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMDiscoveryTaskOutput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.payerAgentName != null && message.hasOwnProperty("payerAgentName"))
                    if (!$util.isString(message.payerAgentName))
                        return "payerAgentName: string expected";
                if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                    if (!$util.isString(message.referenceNumber))
                        return "referenceNumber: string expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    if (!$util.isString(message.taskLink))
                        return "taskLink: string expected";
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.reviewRequired);
                    if (error)
                        return "reviewRequired." + error;
                }
                if (message.planInfo != null && message.hasOwnProperty("planInfo")) {
                    var error = $root.infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.verify(message.planInfo);
                    if (error)
                        return "planInfo." + error;
                }
                if (message.billingNotes != null && message.hasOwnProperty("billingNotes"))
                    if (!$util.isString(message.billingNotes))
                        return "billingNotes: string expected";
                return null;
            };
    
            /**
             * Creates a INFPBMDiscoveryTaskOutput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMDiscoveryTaskOutput} INFPBMDiscoveryTaskOutput
             */
            INFPBMDiscoveryTaskOutput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMDiscoveryTaskOutput)
                    return object;
                var message = new $root.infinitusapi.INFPBMDiscoveryTaskOutput();
                if (object.payerAgentName != null)
                    message.payerAgentName = String(object.payerAgentName);
                if (object.referenceNumber != null)
                    message.referenceNumber = String(object.referenceNumber);
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.taskLink != null)
                    message.taskLink = String(object.taskLink);
                if (object.reviewRequired != null) {
                    if (typeof object.reviewRequired !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskOutput.reviewRequired: object expected");
                    message.reviewRequired = $root.google.protobuf.BoolValue.fromObject(object.reviewRequired);
                }
                if (object.planInfo != null) {
                    if (typeof object.planInfo !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskOutput.planInfo: object expected");
                    message.planInfo = $root.infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.fromObject(object.planInfo);
                }
                if (object.billingNotes != null)
                    message.billingNotes = String(object.billingNotes);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMDiscoveryTaskOutput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {infinitusapi.INFPBMDiscoveryTaskOutput} message INFPBMDiscoveryTaskOutput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMDiscoveryTaskOutput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.payerAgentName = "";
                    object.referenceNumber = "";
                    object.additionalNotes = "";
                    object.taskLink = "";
                    object.reviewRequired = null;
                    object.planInfo = null;
                    object.billingNotes = "";
                }
                if (message.payerAgentName != null && message.hasOwnProperty("payerAgentName"))
                    object.payerAgentName = message.payerAgentName;
                if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                    object.referenceNumber = message.referenceNumber;
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    object.taskLink = message.taskLink;
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired"))
                    object.reviewRequired = $root.google.protobuf.BoolValue.toObject(message.reviewRequired, options);
                if (message.planInfo != null && message.hasOwnProperty("planInfo"))
                    object.planInfo = $root.infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.toObject(message.planInfo, options);
                if (message.billingNotes != null && message.hasOwnProperty("billingNotes"))
                    object.billingNotes = message.billingNotes;
                return object;
            };
    
            /**
             * Converts this INFPBMDiscoveryTaskOutput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMDiscoveryTaskOutput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMDiscoveryTaskOutput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMDiscoveryTaskOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMDiscoveryTaskOutput";
            };
    
            return INFPBMDiscoveryTaskOutput;
        })();
    
        infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo = (function() {
    
            /**
             * Properties of a INFPBMDiscoveryTaskOutputPlanInfo.
             * @memberof infinitusapi
             * @interface IINFPBMDiscoveryTaskOutputPlanInfo
             * @property {string|null} [planEffectiveDate] INFPBMDiscoveryTaskOutputPlanInfo planEffectiveDate
             * @property {string|null} [planTerminationDate] INFPBMDiscoveryTaskOutputPlanInfo planTerminationDate
             * @property {google.protobuf.IBoolValue|null} [newPlanAvailable] INFPBMDiscoveryTaskOutputPlanInfo newPlanAvailable
             * @property {string|null} [newPlanSubscriberId] INFPBMDiscoveryTaskOutputPlanInfo newPlanSubscriberId
             * @property {string|null} [newPlanEffectiveDate] INFPBMDiscoveryTaskOutputPlanInfo newPlanEffectiveDate
             * @property {google.protobuf.IBoolValue|null} [pbmExists] INFPBMDiscoveryTaskOutputPlanInfo pbmExists
             * @property {string|null} [pbmName] INFPBMDiscoveryTaskOutputPlanInfo pbmName
             * @property {string|null} [pbmPhone] INFPBMDiscoveryTaskOutputPlanInfo pbmPhone
             * @property {string|null} [pbmPolicyID] INFPBMDiscoveryTaskOutputPlanInfo pbmPolicyID
             * @property {string|null} [pbmPayerID] INFPBMDiscoveryTaskOutputPlanInfo pbmPayerID
             */
    
            /**
             * Constructs a new INFPBMDiscoveryTaskOutputPlanInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMDiscoveryTaskOutputPlanInfo.
             * @implements IINFPBMDiscoveryTaskOutputPlanInfo
             * @constructor
             * @param {infinitusapi.IINFPBMDiscoveryTaskOutputPlanInfo=} [properties] Properties to set
             */
            function INFPBMDiscoveryTaskOutputPlanInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo planEffectiveDate.
             * @member {string} planEffectiveDate
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.planEffectiveDate = "";
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo planTerminationDate.
             * @member {string} planTerminationDate
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.planTerminationDate = "";
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo newPlanAvailable.
             * @member {google.protobuf.IBoolValue|null|undefined} newPlanAvailable
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.newPlanAvailable = null;
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo newPlanSubscriberId.
             * @member {string} newPlanSubscriberId
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.newPlanSubscriberId = "";
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo newPlanEffectiveDate.
             * @member {string} newPlanEffectiveDate
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.newPlanEffectiveDate = "";
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo pbmExists.
             * @member {google.protobuf.IBoolValue|null|undefined} pbmExists
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.pbmExists = null;
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo pbmName.
             * @member {string} pbmName
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.pbmName = "";
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo pbmPhone.
             * @member {string} pbmPhone
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.pbmPhone = "";
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo pbmPolicyID.
             * @member {string} pbmPolicyID
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.pbmPolicyID = "";
    
            /**
             * INFPBMDiscoveryTaskOutputPlanInfo pbmPayerID.
             * @member {string} pbmPayerID
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.pbmPayerID = "";
    
            /**
             * Creates a new INFPBMDiscoveryTaskOutputPlanInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskOutputPlanInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo} INFPBMDiscoveryTaskOutputPlanInfo instance
             */
            INFPBMDiscoveryTaskOutputPlanInfo.create = function create(properties) {
                return new INFPBMDiscoveryTaskOutputPlanInfo(properties);
            };
    
            /**
             * Encodes the specified INFPBMDiscoveryTaskOutputPlanInfo message. Does not implicitly {@link infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskOutputPlanInfo} message INFPBMDiscoveryTaskOutputPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMDiscoveryTaskOutputPlanInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.planEffectiveDate != null && Object.hasOwnProperty.call(message, "planEffectiveDate"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.planEffectiveDate);
                if (message.planTerminationDate != null && Object.hasOwnProperty.call(message, "planTerminationDate"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.planTerminationDate);
                if (message.newPlanAvailable != null && Object.hasOwnProperty.call(message, "newPlanAvailable"))
                    $root.google.protobuf.BoolValue.encode(message.newPlanAvailable, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.newPlanSubscriberId != null && Object.hasOwnProperty.call(message, "newPlanSubscriberId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.newPlanSubscriberId);
                if (message.newPlanEffectiveDate != null && Object.hasOwnProperty.call(message, "newPlanEffectiveDate"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.newPlanEffectiveDate);
                if (message.pbmExists != null && Object.hasOwnProperty.call(message, "pbmExists"))
                    $root.google.protobuf.BoolValue.encode(message.pbmExists, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.pbmName != null && Object.hasOwnProperty.call(message, "pbmName"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.pbmName);
                if (message.pbmPhone != null && Object.hasOwnProperty.call(message, "pbmPhone"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.pbmPhone);
                if (message.pbmPolicyID != null && Object.hasOwnProperty.call(message, "pbmPolicyID"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.pbmPolicyID);
                if (message.pbmPayerID != null && Object.hasOwnProperty.call(message, "pbmPayerID"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.pbmPayerID);
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMDiscoveryTaskOutputPlanInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPBMDiscoveryTaskOutputPlanInfo} message INFPBMDiscoveryTaskOutputPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMDiscoveryTaskOutputPlanInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMDiscoveryTaskOutputPlanInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo} INFPBMDiscoveryTaskOutputPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMDiscoveryTaskOutputPlanInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.planEffectiveDate = reader.string();
                            break;
                        }
                    case 2: {
                            message.planTerminationDate = reader.string();
                            break;
                        }
                    case 3: {
                            message.newPlanAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.newPlanSubscriberId = reader.string();
                            break;
                        }
                    case 5: {
                            message.newPlanEffectiveDate = reader.string();
                            break;
                        }
                    case 6: {
                            message.pbmExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.pbmName = reader.string();
                            break;
                        }
                    case 8: {
                            message.pbmPhone = reader.string();
                            break;
                        }
                    case 9: {
                            message.pbmPolicyID = reader.string();
                            break;
                        }
                    case 10: {
                            message.pbmPayerID = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMDiscoveryTaskOutputPlanInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo} INFPBMDiscoveryTaskOutputPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMDiscoveryTaskOutputPlanInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMDiscoveryTaskOutputPlanInfo message.
             * @function verify
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMDiscoveryTaskOutputPlanInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.planEffectiveDate != null && message.hasOwnProperty("planEffectiveDate"))
                    if (!$util.isString(message.planEffectiveDate))
                        return "planEffectiveDate: string expected";
                if (message.planTerminationDate != null && message.hasOwnProperty("planTerminationDate"))
                    if (!$util.isString(message.planTerminationDate))
                        return "planTerminationDate: string expected";
                if (message.newPlanAvailable != null && message.hasOwnProperty("newPlanAvailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.newPlanAvailable);
                    if (error)
                        return "newPlanAvailable." + error;
                }
                if (message.newPlanSubscriberId != null && message.hasOwnProperty("newPlanSubscriberId"))
                    if (!$util.isString(message.newPlanSubscriberId))
                        return "newPlanSubscriberId: string expected";
                if (message.newPlanEffectiveDate != null && message.hasOwnProperty("newPlanEffectiveDate"))
                    if (!$util.isString(message.newPlanEffectiveDate))
                        return "newPlanEffectiveDate: string expected";
                if (message.pbmExists != null && message.hasOwnProperty("pbmExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.pbmExists);
                    if (error)
                        return "pbmExists." + error;
                }
                if (message.pbmName != null && message.hasOwnProperty("pbmName"))
                    if (!$util.isString(message.pbmName))
                        return "pbmName: string expected";
                if (message.pbmPhone != null && message.hasOwnProperty("pbmPhone"))
                    if (!$util.isString(message.pbmPhone))
                        return "pbmPhone: string expected";
                if (message.pbmPolicyID != null && message.hasOwnProperty("pbmPolicyID"))
                    if (!$util.isString(message.pbmPolicyID))
                        return "pbmPolicyID: string expected";
                if (message.pbmPayerID != null && message.hasOwnProperty("pbmPayerID"))
                    if (!$util.isString(message.pbmPayerID))
                        return "pbmPayerID: string expected";
                return null;
            };
    
            /**
             * Creates a INFPBMDiscoveryTaskOutputPlanInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo} INFPBMDiscoveryTaskOutputPlanInfo
             */
            INFPBMDiscoveryTaskOutputPlanInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo)
                    return object;
                var message = new $root.infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo();
                if (object.planEffectiveDate != null)
                    message.planEffectiveDate = String(object.planEffectiveDate);
                if (object.planTerminationDate != null)
                    message.planTerminationDate = String(object.planTerminationDate);
                if (object.newPlanAvailable != null) {
                    if (typeof object.newPlanAvailable !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.newPlanAvailable: object expected");
                    message.newPlanAvailable = $root.google.protobuf.BoolValue.fromObject(object.newPlanAvailable);
                }
                if (object.newPlanSubscriberId != null)
                    message.newPlanSubscriberId = String(object.newPlanSubscriberId);
                if (object.newPlanEffectiveDate != null)
                    message.newPlanEffectiveDate = String(object.newPlanEffectiveDate);
                if (object.pbmExists != null) {
                    if (typeof object.pbmExists !== "object")
                        throw TypeError(".infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo.pbmExists: object expected");
                    message.pbmExists = $root.google.protobuf.BoolValue.fromObject(object.pbmExists);
                }
                if (object.pbmName != null)
                    message.pbmName = String(object.pbmName);
                if (object.pbmPhone != null)
                    message.pbmPhone = String(object.pbmPhone);
                if (object.pbmPolicyID != null)
                    message.pbmPolicyID = String(object.pbmPolicyID);
                if (object.pbmPayerID != null)
                    message.pbmPayerID = String(object.pbmPayerID);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMDiscoveryTaskOutputPlanInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo} message INFPBMDiscoveryTaskOutputPlanInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMDiscoveryTaskOutputPlanInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.planEffectiveDate = "";
                    object.planTerminationDate = "";
                    object.newPlanAvailable = null;
                    object.newPlanSubscriberId = "";
                    object.newPlanEffectiveDate = "";
                    object.pbmExists = null;
                    object.pbmName = "";
                    object.pbmPhone = "";
                    object.pbmPolicyID = "";
                    object.pbmPayerID = "";
                }
                if (message.planEffectiveDate != null && message.hasOwnProperty("planEffectiveDate"))
                    object.planEffectiveDate = message.planEffectiveDate;
                if (message.planTerminationDate != null && message.hasOwnProperty("planTerminationDate"))
                    object.planTerminationDate = message.planTerminationDate;
                if (message.newPlanAvailable != null && message.hasOwnProperty("newPlanAvailable"))
                    object.newPlanAvailable = $root.google.protobuf.BoolValue.toObject(message.newPlanAvailable, options);
                if (message.newPlanSubscriberId != null && message.hasOwnProperty("newPlanSubscriberId"))
                    object.newPlanSubscriberId = message.newPlanSubscriberId;
                if (message.newPlanEffectiveDate != null && message.hasOwnProperty("newPlanEffectiveDate"))
                    object.newPlanEffectiveDate = message.newPlanEffectiveDate;
                if (message.pbmExists != null && message.hasOwnProperty("pbmExists"))
                    object.pbmExists = $root.google.protobuf.BoolValue.toObject(message.pbmExists, options);
                if (message.pbmName != null && message.hasOwnProperty("pbmName"))
                    object.pbmName = message.pbmName;
                if (message.pbmPhone != null && message.hasOwnProperty("pbmPhone"))
                    object.pbmPhone = message.pbmPhone;
                if (message.pbmPolicyID != null && message.hasOwnProperty("pbmPolicyID"))
                    object.pbmPolicyID = message.pbmPolicyID;
                if (message.pbmPayerID != null && message.hasOwnProperty("pbmPayerID"))
                    object.pbmPayerID = message.pbmPayerID;
                return object;
            };
    
            /**
             * Converts this INFPBMDiscoveryTaskOutputPlanInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMDiscoveryTaskOutputPlanInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMDiscoveryTaskOutputPlanInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMDiscoveryTaskOutputPlanInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMDiscoveryTaskOutputPlanInfo";
            };
    
            return INFPBMDiscoveryTaskOutputPlanInfo;
        })();
    
        infinitusapi.INFPBMPayerInfo = (function() {
    
            /**
             * Properties of a INFPBMPayerInfo.
             * @memberof infinitusapi
             * @interface IINFPBMPayerInfo
             * @property {string|null} [customerId] INFPBMPayerInfo customerId
             * @property {string|null} [customerPayerName] INFPBMPayerInfo customerPayerName
             * @property {string|null} [customerPayerPhoneNumber] INFPBMPayerInfo customerPayerPhoneNumber
             * @property {string|null} [infinitusPayerId] INFPBMPayerInfo infinitusPayerId
             * @property {string|null} [subscriberId] INFPBMPayerInfo subscriberId
             */
    
            /**
             * Constructs a new INFPBMPayerInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMPayerInfo.
             * @implements IINFPBMPayerInfo
             * @constructor
             * @param {infinitusapi.IINFPBMPayerInfo=} [properties] Properties to set
             */
            function INFPBMPayerInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMPayerInfo customerId.
             * @member {string} customerId
             * @memberof infinitusapi.INFPBMPayerInfo
             * @instance
             */
            INFPBMPayerInfo.prototype.customerId = "";
    
            /**
             * INFPBMPayerInfo customerPayerName.
             * @member {string} customerPayerName
             * @memberof infinitusapi.INFPBMPayerInfo
             * @instance
             */
            INFPBMPayerInfo.prototype.customerPayerName = "";
    
            /**
             * INFPBMPayerInfo customerPayerPhoneNumber.
             * @member {string} customerPayerPhoneNumber
             * @memberof infinitusapi.INFPBMPayerInfo
             * @instance
             */
            INFPBMPayerInfo.prototype.customerPayerPhoneNumber = "";
    
            /**
             * INFPBMPayerInfo infinitusPayerId.
             * @member {string} infinitusPayerId
             * @memberof infinitusapi.INFPBMPayerInfo
             * @instance
             */
            INFPBMPayerInfo.prototype.infinitusPayerId = "";
    
            /**
             * INFPBMPayerInfo subscriberId.
             * @member {string} subscriberId
             * @memberof infinitusapi.INFPBMPayerInfo
             * @instance
             */
            INFPBMPayerInfo.prototype.subscriberId = "";
    
            /**
             * Creates a new INFPBMPayerInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {infinitusapi.IINFPBMPayerInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMPayerInfo} INFPBMPayerInfo instance
             */
            INFPBMPayerInfo.create = function create(properties) {
                return new INFPBMPayerInfo(properties);
            };
    
            /**
             * Encodes the specified INFPBMPayerInfo message. Does not implicitly {@link infinitusapi.INFPBMPayerInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {infinitusapi.IINFPBMPayerInfo} message INFPBMPayerInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMPayerInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
                if (message.customerPayerName != null && Object.hasOwnProperty.call(message, "customerPayerName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerPayerName);
                if (message.customerPayerPhoneNumber != null && Object.hasOwnProperty.call(message, "customerPayerPhoneNumber"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.customerPayerPhoneNumber);
                if (message.infinitusPayerId != null && Object.hasOwnProperty.call(message, "infinitusPayerId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.infinitusPayerId);
                if (message.subscriberId != null && Object.hasOwnProperty.call(message, "subscriberId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.subscriberId);
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMPayerInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPBMPayerInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {infinitusapi.IINFPBMPayerInfo} message INFPBMPayerInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMPayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMPayerInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMPayerInfo} INFPBMPayerInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMPayerInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMPayerInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.customerId = reader.string();
                            break;
                        }
                    case 2: {
                            message.customerPayerName = reader.string();
                            break;
                        }
                    case 3: {
                            message.customerPayerPhoneNumber = reader.string();
                            break;
                        }
                    case 4: {
                            message.infinitusPayerId = reader.string();
                            break;
                        }
                    case 5: {
                            message.subscriberId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMPayerInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMPayerInfo} INFPBMPayerInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMPayerInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMPayerInfo message.
             * @function verify
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMPayerInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.customerId != null && message.hasOwnProperty("customerId"))
                    if (!$util.isString(message.customerId))
                        return "customerId: string expected";
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    if (!$util.isString(message.customerPayerName))
                        return "customerPayerName: string expected";
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    if (!$util.isString(message.customerPayerPhoneNumber))
                        return "customerPayerPhoneNumber: string expected";
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    if (!$util.isString(message.infinitusPayerId))
                        return "infinitusPayerId: string expected";
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    if (!$util.isString(message.subscriberId))
                        return "subscriberId: string expected";
                return null;
            };
    
            /**
             * Creates a INFPBMPayerInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMPayerInfo} INFPBMPayerInfo
             */
            INFPBMPayerInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMPayerInfo)
                    return object;
                var message = new $root.infinitusapi.INFPBMPayerInfo();
                if (object.customerId != null)
                    message.customerId = String(object.customerId);
                if (object.customerPayerName != null)
                    message.customerPayerName = String(object.customerPayerName);
                if (object.customerPayerPhoneNumber != null)
                    message.customerPayerPhoneNumber = String(object.customerPayerPhoneNumber);
                if (object.infinitusPayerId != null)
                    message.infinitusPayerId = String(object.infinitusPayerId);
                if (object.subscriberId != null)
                    message.subscriberId = String(object.subscriberId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMPayerInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {infinitusapi.INFPBMPayerInfo} message INFPBMPayerInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMPayerInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.customerId = "";
                    object.customerPayerName = "";
                    object.customerPayerPhoneNumber = "";
                    object.infinitusPayerId = "";
                    object.subscriberId = "";
                }
                if (message.customerId != null && message.hasOwnProperty("customerId"))
                    object.customerId = message.customerId;
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    object.customerPayerName = message.customerPayerName;
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    object.customerPayerPhoneNumber = message.customerPayerPhoneNumber;
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    object.infinitusPayerId = message.infinitusPayerId;
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    object.subscriberId = message.subscriberId;
                return object;
            };
    
            /**
             * Converts this INFPBMPayerInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMPayerInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMPayerInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMPayerInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMPayerInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMPayerInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMPayerInfo";
            };
    
            return INFPBMPayerInfo;
        })();
    
        infinitusapi.INFPBMTaskChainBVInput = (function() {
    
            /**
             * Properties of a INFPBMTaskChainBVInput.
             * @memberof infinitusapi
             * @interface IINFPBMTaskChainBVInput
             * @property {Array.<infinitusapi.IINFBVTaskInputCPT>|null} [cpt] INFPBMTaskChainBVInput cpt
             * @property {infinitusapi.INFFacilityType|null} [facilityType] INFPBMTaskChainBVInput facilityType
             */
    
            /**
             * Constructs a new INFPBMTaskChainBVInput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMTaskChainBVInput.
             * @implements IINFPBMTaskChainBVInput
             * @constructor
             * @param {infinitusapi.IINFPBMTaskChainBVInput=} [properties] Properties to set
             */
            function INFPBMTaskChainBVInput(properties) {
                this.cpt = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMTaskChainBVInput cpt.
             * @member {Array.<infinitusapi.IINFBVTaskInputCPT>} cpt
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @instance
             */
            INFPBMTaskChainBVInput.prototype.cpt = $util.emptyArray;
    
            /**
             * INFPBMTaskChainBVInput facilityType.
             * @member {infinitusapi.INFFacilityType} facilityType
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @instance
             */
            INFPBMTaskChainBVInput.prototype.facilityType = 0;
    
            /**
             * Creates a new INFPBMTaskChainBVInput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {infinitusapi.IINFPBMTaskChainBVInput=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMTaskChainBVInput} INFPBMTaskChainBVInput instance
             */
            INFPBMTaskChainBVInput.create = function create(properties) {
                return new INFPBMTaskChainBVInput(properties);
            };
    
            /**
             * Encodes the specified INFPBMTaskChainBVInput message. Does not implicitly {@link infinitusapi.INFPBMTaskChainBVInput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {infinitusapi.IINFPBMTaskChainBVInput} message INFPBMTaskChainBVInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskChainBVInput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.cpt != null && message.cpt.length)
                    for (var i = 0; i < message.cpt.length; ++i)
                        $root.infinitusapi.INFBVTaskInputCPT.encode(message.cpt[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.facilityType != null && Object.hasOwnProperty.call(message, "facilityType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.facilityType);
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMTaskChainBVInput message, length delimited. Does not implicitly {@link infinitusapi.INFPBMTaskChainBVInput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {infinitusapi.IINFPBMTaskChainBVInput} message INFPBMTaskChainBVInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskChainBVInput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMTaskChainBVInput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMTaskChainBVInput} INFPBMTaskChainBVInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskChainBVInput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMTaskChainBVInput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.cpt && message.cpt.length))
                                message.cpt = [];
                            message.cpt.push($root.infinitusapi.INFBVTaskInputCPT.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.facilityType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMTaskChainBVInput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMTaskChainBVInput} INFPBMTaskChainBVInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskChainBVInput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMTaskChainBVInput message.
             * @function verify
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMTaskChainBVInput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cpt != null && message.hasOwnProperty("cpt")) {
                    if (!Array.isArray(message.cpt))
                        return "cpt: array expected";
                    for (var i = 0; i < message.cpt.length; ++i) {
                        var error = $root.infinitusapi.INFBVTaskInputCPT.verify(message.cpt[i]);
                        if (error)
                            return "cpt." + error;
                    }
                }
                if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                    switch (message.facilityType) {
                    default:
                        return "facilityType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a INFPBMTaskChainBVInput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMTaskChainBVInput} INFPBMTaskChainBVInput
             */
            INFPBMTaskChainBVInput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMTaskChainBVInput)
                    return object;
                var message = new $root.infinitusapi.INFPBMTaskChainBVInput();
                if (object.cpt) {
                    if (!Array.isArray(object.cpt))
                        throw TypeError(".infinitusapi.INFPBMTaskChainBVInput.cpt: array expected");
                    message.cpt = [];
                    for (var i = 0; i < object.cpt.length; ++i) {
                        if (typeof object.cpt[i] !== "object")
                            throw TypeError(".infinitusapi.INFPBMTaskChainBVInput.cpt: object expected");
                        message.cpt[i] = $root.infinitusapi.INFBVTaskInputCPT.fromObject(object.cpt[i]);
                    }
                }
                switch (object.facilityType) {
                default:
                    if (typeof object.facilityType === "number") {
                        message.facilityType = object.facilityType;
                        break;
                    }
                    break;
                case "INF_FACILITY_TYPE_UNKNOWN":
                case 0:
                    message.facilityType = 0;
                    break;
                case "INF_FACILITY_TYPE_SPECIALIST_OFFICE":
                case 1:
                    message.facilityType = 1;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT":
                case 2:
                    message.facilityType = 2;
                    break;
                case "INF_FACILITY_TYPE_PHARMACY":
                case 3:
                    message.facilityType = 3;
                    break;
                case "INF_FACILITY_TYPE_SHIP_TO_HOME":
                case 4:
                    message.facilityType = 4;
                    break;
                case "INF_FACILITY_TYPE_INFUSION_CENTER":
                case 5:
                    message.facilityType = 5;
                    break;
                case "INF_FACILITY_TYPE_AMBULATORY_SURGICAL_CENTER":
                case 6:
                    message.facilityType = 6;
                    break;
                case "INF_FACILITY_TYPE_HOME_INFUSION":
                case 7:
                    message.facilityType = 7;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT_OFF_CAMPUS":
                case 8:
                    message.facilityType = 8;
                    break;
                case "INF_FACILITY_TYPE_HOSPITAL_INPATIENT":
                case 9:
                    message.facilityType = 9;
                    break;
                case "INF_FACILITY_TYPE_SNF":
                case 10:
                    message.facilityType = 10;
                    break;
                case "INF_FACILITY_TYPE_HOSPICE":
                case 11:
                    message.facilityType = 11;
                    break;
                case "INF_FACILITY_TYPE_IHC":
                case 12:
                    message.facilityType = 12;
                    break;
                case "INF_FACILITY_TYPE_FED_QUALIFIED_HC":
                case 13:
                    message.facilityType = 13;
                    break;
                case "INF_FACILITY_TYPE_INPATIENT_REHAB":
                case 14:
                    message.facilityType = 14;
                    break;
                case "INF_FACILITY_TYPE_OUTPATIENT_REHAB":
                case 15:
                    message.facilityType = 15;
                    break;
                case "INF_FACILITY_TYPE_PUBLIC_HEALTH_CLINIC":
                case 16:
                    message.facilityType = 16;
                    break;
                case "INF_FACILITY_TYPE_RURAL_HEALTH_CLINIC":
                case 17:
                    message.facilityType = 17;
                    break;
                case "INF_FACILITY_TYPE_DIAGNOSTIC_TEST_LAB":
                case 18:
                    message.facilityType = 18;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMTaskChainBVInput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {infinitusapi.INFPBMTaskChainBVInput} message INFPBMTaskChainBVInput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMTaskChainBVInput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.cpt = [];
                if (options.defaults)
                    object.facilityType = options.enums === String ? "INF_FACILITY_TYPE_UNKNOWN" : 0;
                if (message.cpt && message.cpt.length) {
                    object.cpt = [];
                    for (var j = 0; j < message.cpt.length; ++j)
                        object.cpt[j] = $root.infinitusapi.INFBVTaskInputCPT.toObject(message.cpt[j], options);
                }
                if (message.facilityType != null && message.hasOwnProperty("facilityType"))
                    object.facilityType = options.enums === String ? $root.infinitusapi.INFFacilityType[message.facilityType] === undefined ? message.facilityType : $root.infinitusapi.INFFacilityType[message.facilityType] : message.facilityType;
                return object;
            };
    
            /**
             * Converts this INFPBMTaskChainBVInput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMTaskChainBVInput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMTaskChainBVInput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMTaskChainBVInput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMTaskChainBVInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMTaskChainBVInput";
            };
    
            return INFPBMTaskChainBVInput;
        })();
    
        infinitusapi.INFPBMTaskInput = (function() {
    
            /**
             * Properties of a INFPBMTaskInput.
             * @memberof infinitusapi
             * @interface IINFPBMTaskInput
             * @property {boolean|null} [chainBV] INFPBMTaskInput chainBV
             * @property {infinitusapi.IINFPBMTaskChainBVInput|null} [chainBVInput] INFPBMTaskInput chainBVInput
             * @property {Array.<infinitusapi.IINFTaskInputDiagnosis>|null} [diagnosis] INFPBMTaskInput diagnosis
             * @property {infinitusapi.IINFTaskInputMember|null} [member] INFPBMTaskInput member
             * @property {infinitusapi.IINFTaskInputPayer|null} [payer] INFPBMTaskInput payer
             * @property {infinitusapi.IINFBVTaskInputPolicyHolder|null} [policyHolder] INFPBMTaskInput policyHolder
             * @property {infinitusapi.IINFTaskInputPractice|null} [practice] INFPBMTaskInput practice
             * @property {Array.<infinitusapi.IINFTaskInputProduct>|null} [products] INFPBMTaskInput products
             * @property {infinitusapi.IINFTaskInputProvider|null} [provider] INFPBMTaskInput provider
             * @property {string|null} [treatmentDate] INFPBMTaskInput treatmentDate
             */
    
            /**
             * Constructs a new INFPBMTaskInput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMTaskInput.
             * @implements IINFPBMTaskInput
             * @constructor
             * @param {infinitusapi.IINFPBMTaskInput=} [properties] Properties to set
             */
            function INFPBMTaskInput(properties) {
                this.diagnosis = [];
                this.products = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMTaskInput chainBV.
             * @member {boolean} chainBV
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.chainBV = false;
    
            /**
             * INFPBMTaskInput chainBVInput.
             * @member {infinitusapi.IINFPBMTaskChainBVInput|null|undefined} chainBVInput
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.chainBVInput = null;
    
            /**
             * INFPBMTaskInput diagnosis.
             * @member {Array.<infinitusapi.IINFTaskInputDiagnosis>} diagnosis
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.diagnosis = $util.emptyArray;
    
            /**
             * INFPBMTaskInput member.
             * @member {infinitusapi.IINFTaskInputMember|null|undefined} member
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.member = null;
    
            /**
             * INFPBMTaskInput payer.
             * @member {infinitusapi.IINFTaskInputPayer|null|undefined} payer
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.payer = null;
    
            /**
             * INFPBMTaskInput policyHolder.
             * @member {infinitusapi.IINFBVTaskInputPolicyHolder|null|undefined} policyHolder
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.policyHolder = null;
    
            /**
             * INFPBMTaskInput practice.
             * @member {infinitusapi.IINFTaskInputPractice|null|undefined} practice
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.practice = null;
    
            /**
             * INFPBMTaskInput products.
             * @member {Array.<infinitusapi.IINFTaskInputProduct>} products
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.products = $util.emptyArray;
    
            /**
             * INFPBMTaskInput provider.
             * @member {infinitusapi.IINFTaskInputProvider|null|undefined} provider
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.provider = null;
    
            /**
             * INFPBMTaskInput treatmentDate.
             * @member {string} treatmentDate
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             */
            INFPBMTaskInput.prototype.treatmentDate = "";
    
            /**
             * Creates a new INFPBMTaskInput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {infinitusapi.IINFPBMTaskInput=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMTaskInput} INFPBMTaskInput instance
             */
            INFPBMTaskInput.create = function create(properties) {
                return new INFPBMTaskInput(properties);
            };
    
            /**
             * Encodes the specified INFPBMTaskInput message. Does not implicitly {@link infinitusapi.INFPBMTaskInput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {infinitusapi.IINFPBMTaskInput} message INFPBMTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskInput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chainBV != null && Object.hasOwnProperty.call(message, "chainBV"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.chainBV);
                if (message.chainBVInput != null && Object.hasOwnProperty.call(message, "chainBVInput"))
                    $root.infinitusapi.INFPBMTaskChainBVInput.encode(message.chainBVInput, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.diagnosis != null && message.diagnosis.length)
                    for (var i = 0; i < message.diagnosis.length; ++i)
                        $root.infinitusapi.INFTaskInputDiagnosis.encode(message.diagnosis[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.member != null && Object.hasOwnProperty.call(message, "member"))
                    $root.infinitusapi.INFTaskInputMember.encode(message.member, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.payer != null && Object.hasOwnProperty.call(message, "payer"))
                    $root.infinitusapi.INFTaskInputPayer.encode(message.payer, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.policyHolder != null && Object.hasOwnProperty.call(message, "policyHolder"))
                    $root.infinitusapi.INFBVTaskInputPolicyHolder.encode(message.policyHolder, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.practice != null && Object.hasOwnProperty.call(message, "practice"))
                    $root.infinitusapi.INFTaskInputPractice.encode(message.practice, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.products != null && message.products.length)
                    for (var i = 0; i < message.products.length; ++i)
                        $root.infinitusapi.INFTaskInputProduct.encode(message.products[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
                    $root.infinitusapi.INFTaskInputProvider.encode(message.provider, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.treatmentDate != null && Object.hasOwnProperty.call(message, "treatmentDate"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.treatmentDate);
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMTaskInput message, length delimited. Does not implicitly {@link infinitusapi.INFPBMTaskInput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {infinitusapi.IINFPBMTaskInput} message INFPBMTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskInput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMTaskInput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMTaskInput} INFPBMTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskInput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMTaskInput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chainBV = reader.bool();
                            break;
                        }
                    case 2: {
                            message.chainBVInput = $root.infinitusapi.INFPBMTaskChainBVInput.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            if (!(message.diagnosis && message.diagnosis.length))
                                message.diagnosis = [];
                            message.diagnosis.push($root.infinitusapi.INFTaskInputDiagnosis.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            message.member = $root.infinitusapi.INFTaskInputMember.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.payer = $root.infinitusapi.INFTaskInputPayer.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.practice = $root.infinitusapi.INFTaskInputPractice.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            if (!(message.products && message.products.length))
                                message.products = [];
                            message.products.push($root.infinitusapi.INFTaskInputProduct.decode(reader, reader.uint32()));
                            break;
                        }
                    case 9: {
                            message.provider = $root.infinitusapi.INFTaskInputProvider.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.treatmentDate = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMTaskInput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMTaskInput} INFPBMTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskInput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMTaskInput message.
             * @function verify
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMTaskInput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chainBV != null && message.hasOwnProperty("chainBV"))
                    if (typeof message.chainBV !== "boolean")
                        return "chainBV: boolean expected";
                if (message.chainBVInput != null && message.hasOwnProperty("chainBVInput")) {
                    var error = $root.infinitusapi.INFPBMTaskChainBVInput.verify(message.chainBVInput);
                    if (error)
                        return "chainBVInput." + error;
                }
                if (message.diagnosis != null && message.hasOwnProperty("diagnosis")) {
                    if (!Array.isArray(message.diagnosis))
                        return "diagnosis: array expected";
                    for (var i = 0; i < message.diagnosis.length; ++i) {
                        var error = $root.infinitusapi.INFTaskInputDiagnosis.verify(message.diagnosis[i]);
                        if (error)
                            return "diagnosis." + error;
                    }
                }
                if (message.member != null && message.hasOwnProperty("member")) {
                    var error = $root.infinitusapi.INFTaskInputMember.verify(message.member);
                    if (error)
                        return "member." + error;
                }
                if (message.payer != null && message.hasOwnProperty("payer")) {
                    var error = $root.infinitusapi.INFTaskInputPayer.verify(message.payer);
                    if (error)
                        return "payer." + error;
                }
                if (message.policyHolder != null && message.hasOwnProperty("policyHolder")) {
                    var error = $root.infinitusapi.INFBVTaskInputPolicyHolder.verify(message.policyHolder);
                    if (error)
                        return "policyHolder." + error;
                }
                if (message.practice != null && message.hasOwnProperty("practice")) {
                    var error = $root.infinitusapi.INFTaskInputPractice.verify(message.practice);
                    if (error)
                        return "practice." + error;
                }
                if (message.products != null && message.hasOwnProperty("products")) {
                    if (!Array.isArray(message.products))
                        return "products: array expected";
                    for (var i = 0; i < message.products.length; ++i) {
                        var error = $root.infinitusapi.INFTaskInputProduct.verify(message.products[i]);
                        if (error)
                            return "products." + error;
                    }
                }
                if (message.provider != null && message.hasOwnProperty("provider")) {
                    var error = $root.infinitusapi.INFTaskInputProvider.verify(message.provider);
                    if (error)
                        return "provider." + error;
                }
                if (message.treatmentDate != null && message.hasOwnProperty("treatmentDate"))
                    if (!$util.isString(message.treatmentDate))
                        return "treatmentDate: string expected";
                return null;
            };
    
            /**
             * Creates a INFPBMTaskInput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMTaskInput} INFPBMTaskInput
             */
            INFPBMTaskInput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMTaskInput)
                    return object;
                var message = new $root.infinitusapi.INFPBMTaskInput();
                if (object.chainBV != null)
                    message.chainBV = Boolean(object.chainBV);
                if (object.chainBVInput != null) {
                    if (typeof object.chainBVInput !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskInput.chainBVInput: object expected");
                    message.chainBVInput = $root.infinitusapi.INFPBMTaskChainBVInput.fromObject(object.chainBVInput);
                }
                if (object.diagnosis) {
                    if (!Array.isArray(object.diagnosis))
                        throw TypeError(".infinitusapi.INFPBMTaskInput.diagnosis: array expected");
                    message.diagnosis = [];
                    for (var i = 0; i < object.diagnosis.length; ++i) {
                        if (typeof object.diagnosis[i] !== "object")
                            throw TypeError(".infinitusapi.INFPBMTaskInput.diagnosis: object expected");
                        message.diagnosis[i] = $root.infinitusapi.INFTaskInputDiagnosis.fromObject(object.diagnosis[i]);
                    }
                }
                if (object.member != null) {
                    if (typeof object.member !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskInput.member: object expected");
                    message.member = $root.infinitusapi.INFTaskInputMember.fromObject(object.member);
                }
                if (object.payer != null) {
                    if (typeof object.payer !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskInput.payer: object expected");
                    message.payer = $root.infinitusapi.INFTaskInputPayer.fromObject(object.payer);
                }
                if (object.policyHolder != null) {
                    if (typeof object.policyHolder !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskInput.policyHolder: object expected");
                    message.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.fromObject(object.policyHolder);
                }
                if (object.practice != null) {
                    if (typeof object.practice !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskInput.practice: object expected");
                    message.practice = $root.infinitusapi.INFTaskInputPractice.fromObject(object.practice);
                }
                if (object.products) {
                    if (!Array.isArray(object.products))
                        throw TypeError(".infinitusapi.INFPBMTaskInput.products: array expected");
                    message.products = [];
                    for (var i = 0; i < object.products.length; ++i) {
                        if (typeof object.products[i] !== "object")
                            throw TypeError(".infinitusapi.INFPBMTaskInput.products: object expected");
                        message.products[i] = $root.infinitusapi.INFTaskInputProduct.fromObject(object.products[i]);
                    }
                }
                if (object.provider != null) {
                    if (typeof object.provider !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskInput.provider: object expected");
                    message.provider = $root.infinitusapi.INFTaskInputProvider.fromObject(object.provider);
                }
                if (object.treatmentDate != null)
                    message.treatmentDate = String(object.treatmentDate);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMTaskInput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {infinitusapi.INFPBMTaskInput} message INFPBMTaskInput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMTaskInput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.diagnosis = [];
                    object.products = [];
                }
                if (options.defaults) {
                    object.chainBV = false;
                    object.chainBVInput = null;
                    object.member = null;
                    object.payer = null;
                    object.policyHolder = null;
                    object.practice = null;
                    object.provider = null;
                    object.treatmentDate = "";
                }
                if (message.chainBV != null && message.hasOwnProperty("chainBV"))
                    object.chainBV = message.chainBV;
                if (message.chainBVInput != null && message.hasOwnProperty("chainBVInput"))
                    object.chainBVInput = $root.infinitusapi.INFPBMTaskChainBVInput.toObject(message.chainBVInput, options);
                if (message.diagnosis && message.diagnosis.length) {
                    object.diagnosis = [];
                    for (var j = 0; j < message.diagnosis.length; ++j)
                        object.diagnosis[j] = $root.infinitusapi.INFTaskInputDiagnosis.toObject(message.diagnosis[j], options);
                }
                if (message.member != null && message.hasOwnProperty("member"))
                    object.member = $root.infinitusapi.INFTaskInputMember.toObject(message.member, options);
                if (message.payer != null && message.hasOwnProperty("payer"))
                    object.payer = $root.infinitusapi.INFTaskInputPayer.toObject(message.payer, options);
                if (message.policyHolder != null && message.hasOwnProperty("policyHolder"))
                    object.policyHolder = $root.infinitusapi.INFBVTaskInputPolicyHolder.toObject(message.policyHolder, options);
                if (message.practice != null && message.hasOwnProperty("practice"))
                    object.practice = $root.infinitusapi.INFTaskInputPractice.toObject(message.practice, options);
                if (message.products && message.products.length) {
                    object.products = [];
                    for (var j = 0; j < message.products.length; ++j)
                        object.products[j] = $root.infinitusapi.INFTaskInputProduct.toObject(message.products[j], options);
                }
                if (message.provider != null && message.hasOwnProperty("provider"))
                    object.provider = $root.infinitusapi.INFTaskInputProvider.toObject(message.provider, options);
                if (message.treatmentDate != null && message.hasOwnProperty("treatmentDate"))
                    object.treatmentDate = message.treatmentDate;
                return object;
            };
    
            /**
             * Converts this INFPBMTaskInput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMTaskInput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMTaskInput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMTaskInput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMTaskInput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMTaskInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMTaskInput";
            };
    
            return INFPBMTaskInput;
        })();
    
        infinitusapi.INFPBMTaskOutput = (function() {
    
            /**
             * Properties of a INFPBMTaskOutput.
             * @memberof infinitusapi
             * @interface IINFPBMTaskOutput
             * @property {infinitusapi.IINFPBMTaskOutputAccessInfo|null} [accessInfo] INFPBMTaskOutput accessInfo
             * @property {string|null} [additionalNotes] INFPBMTaskOutput additionalNotes
             * @property {string|null} [billingNotes] INFPBMTaskOutput billingNotes
             * @property {infinitusapi.IINFPBMTaskOutputDeductiblesAndOOPS|null} [deductiblesAndOops] INFPBMTaskOutput deductiblesAndOops
             * @property {infinitusapi.IINFPriorAuthDenialInfo|null} [denialInfo] INFPBMTaskOutput denialInfo
             * @property {infinitusapi.IINFPBMTaskOutputErrorInfo|null} [errorInfo] INFPBMTaskOutput errorInfo
             * @property {string|null} [infinitusPayerId] INFPBMTaskOutput infinitusPayerId
             * @property {string|null} [payerAgentName] INFPBMTaskOutput payerAgentName
             * @property {string|null} [payerName] INFPBMTaskOutput payerName
             * @property {string|null} [payerPhone] INFPBMTaskOutput payerPhone
             * @property {string|null} [pbmName] INFPBMTaskOutput pbmName
             * @property {infinitusapi.IINFPBMTaskOutputPlanInfo|null} [planInfo] INFPBMTaskOutput planInfo
             * @property {Array.<infinitusapi.IINFPBMTaskOutputProductCoverage>|null} [productCoverage] INFPBMTaskOutput productCoverage
             * @property {infinitusapi.INFReasonForNonCoverage|null} [reasonForNonCoverage] INFPBMTaskOutput reasonForNonCoverage
             * @property {string|null} [referenceNumber] INFPBMTaskOutput referenceNumber
             * @property {google.protobuf.IBoolValue|null} [reviewRequired] INFPBMTaskOutput reviewRequired
             * @property {string|null} [taskLink] INFPBMTaskOutput taskLink
             */
    
            /**
             * Constructs a new INFPBMTaskOutput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMTaskOutput.
             * @implements IINFPBMTaskOutput
             * @constructor
             * @param {infinitusapi.IINFPBMTaskOutput=} [properties] Properties to set
             */
            function INFPBMTaskOutput(properties) {
                this.productCoverage = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMTaskOutput accessInfo.
             * @member {infinitusapi.IINFPBMTaskOutputAccessInfo|null|undefined} accessInfo
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.accessInfo = null;
    
            /**
             * INFPBMTaskOutput additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.additionalNotes = "";
    
            /**
             * INFPBMTaskOutput billingNotes.
             * @member {string} billingNotes
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.billingNotes = "";
    
            /**
             * INFPBMTaskOutput deductiblesAndOops.
             * @member {infinitusapi.IINFPBMTaskOutputDeductiblesAndOOPS|null|undefined} deductiblesAndOops
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.deductiblesAndOops = null;
    
            /**
             * INFPBMTaskOutput denialInfo.
             * @member {infinitusapi.IINFPriorAuthDenialInfo|null|undefined} denialInfo
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.denialInfo = null;
    
            /**
             * INFPBMTaskOutput errorInfo.
             * @member {infinitusapi.IINFPBMTaskOutputErrorInfo|null|undefined} errorInfo
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.errorInfo = null;
    
            /**
             * INFPBMTaskOutput infinitusPayerId.
             * @member {string} infinitusPayerId
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.infinitusPayerId = "";
    
            /**
             * INFPBMTaskOutput payerAgentName.
             * @member {string} payerAgentName
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.payerAgentName = "";
    
            /**
             * INFPBMTaskOutput payerName.
             * @member {string} payerName
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.payerName = "";
    
            /**
             * INFPBMTaskOutput payerPhone.
             * @member {string} payerPhone
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.payerPhone = "";
    
            /**
             * INFPBMTaskOutput pbmName.
             * @member {string} pbmName
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.pbmName = "";
    
            /**
             * INFPBMTaskOutput planInfo.
             * @member {infinitusapi.IINFPBMTaskOutputPlanInfo|null|undefined} planInfo
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.planInfo = null;
    
            /**
             * INFPBMTaskOutput productCoverage.
             * @member {Array.<infinitusapi.IINFPBMTaskOutputProductCoverage>} productCoverage
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.productCoverage = $util.emptyArray;
    
            /**
             * INFPBMTaskOutput reasonForNonCoverage.
             * @member {infinitusapi.INFReasonForNonCoverage} reasonForNonCoverage
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.reasonForNonCoverage = 0;
    
            /**
             * INFPBMTaskOutput referenceNumber.
             * @member {string} referenceNumber
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.referenceNumber = "";
    
            /**
             * INFPBMTaskOutput reviewRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} reviewRequired
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.reviewRequired = null;
    
            /**
             * INFPBMTaskOutput taskLink.
             * @member {string} taskLink
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             */
            INFPBMTaskOutput.prototype.taskLink = "";
    
            /**
             * Creates a new INFPBMTaskOutput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {infinitusapi.IINFPBMTaskOutput=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMTaskOutput} INFPBMTaskOutput instance
             */
            INFPBMTaskOutput.create = function create(properties) {
                return new INFPBMTaskOutput(properties);
            };
    
            /**
             * Encodes the specified INFPBMTaskOutput message. Does not implicitly {@link infinitusapi.INFPBMTaskOutput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {infinitusapi.IINFPBMTaskOutput} message INFPBMTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accessInfo != null && Object.hasOwnProperty.call(message, "accessInfo"))
                    $root.infinitusapi.INFPBMTaskOutputAccessInfo.encode(message.accessInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.additionalNotes);
                if (message.billingNotes != null && Object.hasOwnProperty.call(message, "billingNotes"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.billingNotes);
                if (message.deductiblesAndOops != null && Object.hasOwnProperty.call(message, "deductiblesAndOops"))
                    $root.infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.encode(message.deductiblesAndOops, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.denialInfo != null && Object.hasOwnProperty.call(message, "denialInfo"))
                    $root.infinitusapi.INFPriorAuthDenialInfo.encode(message.denialInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.errorInfo != null && Object.hasOwnProperty.call(message, "errorInfo"))
                    $root.infinitusapi.INFPBMTaskOutputErrorInfo.encode(message.errorInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.infinitusPayerId != null && Object.hasOwnProperty.call(message, "infinitusPayerId"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.infinitusPayerId);
                if (message.payerAgentName != null && Object.hasOwnProperty.call(message, "payerAgentName"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.payerAgentName);
                if (message.payerName != null && Object.hasOwnProperty.call(message, "payerName"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.payerName);
                if (message.payerPhone != null && Object.hasOwnProperty.call(message, "payerPhone"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.payerPhone);
                if (message.pbmName != null && Object.hasOwnProperty.call(message, "pbmName"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.pbmName);
                if (message.planInfo != null && Object.hasOwnProperty.call(message, "planInfo"))
                    $root.infinitusapi.INFPBMTaskOutputPlanInfo.encode(message.planInfo, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.productCoverage != null && message.productCoverage.length)
                    for (var i = 0; i < message.productCoverage.length; ++i)
                        $root.infinitusapi.INFPBMTaskOutputProductCoverage.encode(message.productCoverage[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.reasonForNonCoverage != null && Object.hasOwnProperty.call(message, "reasonForNonCoverage"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.reasonForNonCoverage);
                if (message.referenceNumber != null && Object.hasOwnProperty.call(message, "referenceNumber"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.referenceNumber);
                if (message.reviewRequired != null && Object.hasOwnProperty.call(message, "reviewRequired"))
                    $root.google.protobuf.BoolValue.encode(message.reviewRequired, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.taskLink != null && Object.hasOwnProperty.call(message, "taskLink"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.taskLink);
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMTaskOutput message, length delimited. Does not implicitly {@link infinitusapi.INFPBMTaskOutput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {infinitusapi.IINFPBMTaskOutput} message INFPBMTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMTaskOutput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMTaskOutput} INFPBMTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMTaskOutput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.accessInfo = $root.infinitusapi.INFPBMTaskOutputAccessInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 3: {
                            message.billingNotes = reader.string();
                            break;
                        }
                    case 4: {
                            message.deductiblesAndOops = $root.infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.denialInfo = $root.infinitusapi.INFPriorAuthDenialInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.errorInfo = $root.infinitusapi.INFPBMTaskOutputErrorInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.infinitusPayerId = reader.string();
                            break;
                        }
                    case 8: {
                            message.payerAgentName = reader.string();
                            break;
                        }
                    case 9: {
                            message.payerName = reader.string();
                            break;
                        }
                    case 10: {
                            message.payerPhone = reader.string();
                            break;
                        }
                    case 11: {
                            message.pbmName = reader.string();
                            break;
                        }
                    case 12: {
                            message.planInfo = $root.infinitusapi.INFPBMTaskOutputPlanInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            if (!(message.productCoverage && message.productCoverage.length))
                                message.productCoverage = [];
                            message.productCoverage.push($root.infinitusapi.INFPBMTaskOutputProductCoverage.decode(reader, reader.uint32()));
                            break;
                        }
                    case 14: {
                            message.reasonForNonCoverage = reader.int32();
                            break;
                        }
                    case 15: {
                            message.referenceNumber = reader.string();
                            break;
                        }
                    case 16: {
                            message.reviewRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.taskLink = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMTaskOutput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMTaskOutput} INFPBMTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMTaskOutput message.
             * @function verify
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMTaskOutput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accessInfo != null && message.hasOwnProperty("accessInfo")) {
                    var error = $root.infinitusapi.INFPBMTaskOutputAccessInfo.verify(message.accessInfo);
                    if (error)
                        return "accessInfo." + error;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.billingNotes != null && message.hasOwnProperty("billingNotes"))
                    if (!$util.isString(message.billingNotes))
                        return "billingNotes: string expected";
                if (message.deductiblesAndOops != null && message.hasOwnProperty("deductiblesAndOops")) {
                    var error = $root.infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.verify(message.deductiblesAndOops);
                    if (error)
                        return "deductiblesAndOops." + error;
                }
                if (message.denialInfo != null && message.hasOwnProperty("denialInfo")) {
                    var error = $root.infinitusapi.INFPriorAuthDenialInfo.verify(message.denialInfo);
                    if (error)
                        return "denialInfo." + error;
                }
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo")) {
                    var error = $root.infinitusapi.INFPBMTaskOutputErrorInfo.verify(message.errorInfo);
                    if (error)
                        return "errorInfo." + error;
                }
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    if (!$util.isString(message.infinitusPayerId))
                        return "infinitusPayerId: string expected";
                if (message.payerAgentName != null && message.hasOwnProperty("payerAgentName"))
                    if (!$util.isString(message.payerAgentName))
                        return "payerAgentName: string expected";
                if (message.payerName != null && message.hasOwnProperty("payerName"))
                    if (!$util.isString(message.payerName))
                        return "payerName: string expected";
                if (message.payerPhone != null && message.hasOwnProperty("payerPhone"))
                    if (!$util.isString(message.payerPhone))
                        return "payerPhone: string expected";
                if (message.pbmName != null && message.hasOwnProperty("pbmName"))
                    if (!$util.isString(message.pbmName))
                        return "pbmName: string expected";
                if (message.planInfo != null && message.hasOwnProperty("planInfo")) {
                    var error = $root.infinitusapi.INFPBMTaskOutputPlanInfo.verify(message.planInfo);
                    if (error)
                        return "planInfo." + error;
                }
                if (message.productCoverage != null && message.hasOwnProperty("productCoverage")) {
                    if (!Array.isArray(message.productCoverage))
                        return "productCoverage: array expected";
                    for (var i = 0; i < message.productCoverage.length; ++i) {
                        var error = $root.infinitusapi.INFPBMTaskOutputProductCoverage.verify(message.productCoverage[i]);
                        if (error)
                            return "productCoverage." + error;
                    }
                }
                if (message.reasonForNonCoverage != null && message.hasOwnProperty("reasonForNonCoverage"))
                    switch (message.reasonForNonCoverage) {
                    default:
                        return "reasonForNonCoverage: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                        break;
                    }
                if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                    if (!$util.isString(message.referenceNumber))
                        return "referenceNumber: string expected";
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.reviewRequired);
                    if (error)
                        return "reviewRequired." + error;
                }
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    if (!$util.isString(message.taskLink))
                        return "taskLink: string expected";
                return null;
            };
    
            /**
             * Creates a INFPBMTaskOutput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMTaskOutput} INFPBMTaskOutput
             */
            INFPBMTaskOutput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMTaskOutput)
                    return object;
                var message = new $root.infinitusapi.INFPBMTaskOutput();
                if (object.accessInfo != null) {
                    if (typeof object.accessInfo !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutput.accessInfo: object expected");
                    message.accessInfo = $root.infinitusapi.INFPBMTaskOutputAccessInfo.fromObject(object.accessInfo);
                }
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.billingNotes != null)
                    message.billingNotes = String(object.billingNotes);
                if (object.deductiblesAndOops != null) {
                    if (typeof object.deductiblesAndOops !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutput.deductiblesAndOops: object expected");
                    message.deductiblesAndOops = $root.infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.fromObject(object.deductiblesAndOops);
                }
                if (object.denialInfo != null) {
                    if (typeof object.denialInfo !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutput.denialInfo: object expected");
                    message.denialInfo = $root.infinitusapi.INFPriorAuthDenialInfo.fromObject(object.denialInfo);
                }
                if (object.errorInfo != null) {
                    if (typeof object.errorInfo !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutput.errorInfo: object expected");
                    message.errorInfo = $root.infinitusapi.INFPBMTaskOutputErrorInfo.fromObject(object.errorInfo);
                }
                if (object.infinitusPayerId != null)
                    message.infinitusPayerId = String(object.infinitusPayerId);
                if (object.payerAgentName != null)
                    message.payerAgentName = String(object.payerAgentName);
                if (object.payerName != null)
                    message.payerName = String(object.payerName);
                if (object.payerPhone != null)
                    message.payerPhone = String(object.payerPhone);
                if (object.pbmName != null)
                    message.pbmName = String(object.pbmName);
                if (object.planInfo != null) {
                    if (typeof object.planInfo !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutput.planInfo: object expected");
                    message.planInfo = $root.infinitusapi.INFPBMTaskOutputPlanInfo.fromObject(object.planInfo);
                }
                if (object.productCoverage) {
                    if (!Array.isArray(object.productCoverage))
                        throw TypeError(".infinitusapi.INFPBMTaskOutput.productCoverage: array expected");
                    message.productCoverage = [];
                    for (var i = 0; i < object.productCoverage.length; ++i) {
                        if (typeof object.productCoverage[i] !== "object")
                            throw TypeError(".infinitusapi.INFPBMTaskOutput.productCoverage: object expected");
                        message.productCoverage[i] = $root.infinitusapi.INFPBMTaskOutputProductCoverage.fromObject(object.productCoverage[i]);
                    }
                }
                switch (object.reasonForNonCoverage) {
                default:
                    if (typeof object.reasonForNonCoverage === "number") {
                        message.reasonForNonCoverage = object.reasonForNonCoverage;
                        break;
                    }
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_UNKNOWN":
                case 0:
                    message.reasonForNonCoverage = 0;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NOT_APPLICABLE":
                case 1:
                    message.reasonForNonCoverage = 1;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NO_RX_BENEFITS_AS_DRUG_COVERED_UNDER_PBM":
                case 2:
                    message.reasonForNonCoverage = 2;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NO_INFUSION_OR_NO_INJECTABLE_BENEFITS":
                case 3:
                    message.reasonForNonCoverage = 3;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NOT_ENROLLED_IN_PART_B":
                case 4:
                    message.reasonForNonCoverage = 4;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_CAP_MET":
                case 5:
                    message.reasonForNonCoverage = 5;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DIAGNOSIS":
                case 6:
                    message.reasonForNonCoverage = 6;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_EXCLUSION_DRUG":
                case 7:
                    message.reasonForNonCoverage = 7;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_COVERED_UNDER_MEDICAL_BENEFIT":
                case 8:
                    message.reasonForNonCoverage = 8;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_FEDERAL_LEGEND_DRUG":
                case 9:
                    message.reasonForNonCoverage = 9;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NEED_APPEAL":
                case 10:
                    message.reasonForNonCoverage = 10;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_NON_FORMULARY":
                case 11:
                    message.reasonForNonCoverage = 11;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PLAN_EXCLUSION":
                case 12:
                    message.reasonForNonCoverage = 12;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PLAN_IS_SECONDARY":
                case 13:
                    message.reasonForNonCoverage = 13;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_PRIOR_AUTHORIZATION_REQUIRED":
                case 14:
                    message.reasonForNonCoverage = 14;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_STEP_THERAPY_REQUIRED":
                case 15:
                    message.reasonForNonCoverage = 15;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_OUT_OF_NETWORK":
                case 16:
                    message.reasonForNonCoverage = 16;
                    break;
                case "INF_REASON_FOR_NON_COVERAGE_OTHER":
                case 17:
                    message.reasonForNonCoverage = 17;
                    break;
                }
                if (object.referenceNumber != null)
                    message.referenceNumber = String(object.referenceNumber);
                if (object.reviewRequired != null) {
                    if (typeof object.reviewRequired !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutput.reviewRequired: object expected");
                    message.reviewRequired = $root.google.protobuf.BoolValue.fromObject(object.reviewRequired);
                }
                if (object.taskLink != null)
                    message.taskLink = String(object.taskLink);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMTaskOutput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {infinitusapi.INFPBMTaskOutput} message INFPBMTaskOutput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMTaskOutput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.productCoverage = [];
                if (options.defaults) {
                    object.accessInfo = null;
                    object.additionalNotes = "";
                    object.billingNotes = "";
                    object.deductiblesAndOops = null;
                    object.denialInfo = null;
                    object.errorInfo = null;
                    object.infinitusPayerId = "";
                    object.payerAgentName = "";
                    object.payerName = "";
                    object.payerPhone = "";
                    object.pbmName = "";
                    object.planInfo = null;
                    object.reasonForNonCoverage = options.enums === String ? "INF_REASON_FOR_NON_COVERAGE_UNKNOWN" : 0;
                    object.referenceNumber = "";
                    object.reviewRequired = null;
                    object.taskLink = "";
                }
                if (message.accessInfo != null && message.hasOwnProperty("accessInfo"))
                    object.accessInfo = $root.infinitusapi.INFPBMTaskOutputAccessInfo.toObject(message.accessInfo, options);
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.billingNotes != null && message.hasOwnProperty("billingNotes"))
                    object.billingNotes = message.billingNotes;
                if (message.deductiblesAndOops != null && message.hasOwnProperty("deductiblesAndOops"))
                    object.deductiblesAndOops = $root.infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.toObject(message.deductiblesAndOops, options);
                if (message.denialInfo != null && message.hasOwnProperty("denialInfo"))
                    object.denialInfo = $root.infinitusapi.INFPriorAuthDenialInfo.toObject(message.denialInfo, options);
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo"))
                    object.errorInfo = $root.infinitusapi.INFPBMTaskOutputErrorInfo.toObject(message.errorInfo, options);
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    object.infinitusPayerId = message.infinitusPayerId;
                if (message.payerAgentName != null && message.hasOwnProperty("payerAgentName"))
                    object.payerAgentName = message.payerAgentName;
                if (message.payerName != null && message.hasOwnProperty("payerName"))
                    object.payerName = message.payerName;
                if (message.payerPhone != null && message.hasOwnProperty("payerPhone"))
                    object.payerPhone = message.payerPhone;
                if (message.pbmName != null && message.hasOwnProperty("pbmName"))
                    object.pbmName = message.pbmName;
                if (message.planInfo != null && message.hasOwnProperty("planInfo"))
                    object.planInfo = $root.infinitusapi.INFPBMTaskOutputPlanInfo.toObject(message.planInfo, options);
                if (message.productCoverage && message.productCoverage.length) {
                    object.productCoverage = [];
                    for (var j = 0; j < message.productCoverage.length; ++j)
                        object.productCoverage[j] = $root.infinitusapi.INFPBMTaskOutputProductCoverage.toObject(message.productCoverage[j], options);
                }
                if (message.reasonForNonCoverage != null && message.hasOwnProperty("reasonForNonCoverage"))
                    object.reasonForNonCoverage = options.enums === String ? $root.infinitusapi.INFReasonForNonCoverage[message.reasonForNonCoverage] === undefined ? message.reasonForNonCoverage : $root.infinitusapi.INFReasonForNonCoverage[message.reasonForNonCoverage] : message.reasonForNonCoverage;
                if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                    object.referenceNumber = message.referenceNumber;
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired"))
                    object.reviewRequired = $root.google.protobuf.BoolValue.toObject(message.reviewRequired, options);
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    object.taskLink = message.taskLink;
                return object;
            };
    
            /**
             * Converts this INFPBMTaskOutput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMTaskOutput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMTaskOutput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMTaskOutput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMTaskOutput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMTaskOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMTaskOutput";
            };
    
            return INFPBMTaskOutput;
        })();
    
        infinitusapi.INFPBMTaskOutputAccessInfo = (function() {
    
            /**
             * Properties of a INFPBMTaskOutputAccessInfo.
             * @memberof infinitusapi
             * @interface IINFPBMTaskOutputAccessInfo
             * @property {infinitusapi.INFAppealsNotificationMethod|null} [appealsNotificationMethod] INFPBMTaskOutputAccessInfo appealsNotificationMethod
             * @property {string|null} [appealsTurnaroundTime] INFPBMTaskOutputAccessInfo appealsTurnaroundTime
             * @property {google.protobuf.IBoolValue|null} [formularyDrug] INFPBMTaskOutputAccessInfo formularyDrug
             * @property {google.protobuf.IBoolValue|null} [formularyExceptionRequired] INFPBMTaskOutputAccessInfo formularyExceptionRequired
             * @property {infinitusapi.INFFormularyExceptionStatus|null} [formularyExceptionStatus] INFPBMTaskOutputAccessInfo formularyExceptionStatus
             * @property {string|null} [mailOrderPharmacyName] INFPBMTaskOutputAccessInfo mailOrderPharmacyName
             * @property {string|null} [mailOrderPharmacyPhone] INFPBMTaskOutputAccessInfo mailOrderPharmacyPhone
             * @property {string|null} [obtainFormularyExceptionDocument] INFPBMTaskOutputAccessInfo obtainFormularyExceptionDocument
             * @property {string|null} [obtainFormularyExceptionFax] INFPBMTaskOutputAccessInfo obtainFormularyExceptionFax
             * @property {infinitusapi.INFFormularyExceptionNotificationMethod|null} [obtainFormularyExceptionNotificationMethod] INFPBMTaskOutputAccessInfo obtainFormularyExceptionNotificationMethod
             * @property {string|null} [obtainFormularyExceptionOrg] INFPBMTaskOutputAccessInfo obtainFormularyExceptionOrg
             * @property {string|null} [obtainFormularyExceptionPhone] INFPBMTaskOutputAccessInfo obtainFormularyExceptionPhone
             * @property {string|null} [obtainFormularyExceptionTurnaroundTime] INFPBMTaskOutputAccessInfo obtainFormularyExceptionTurnaroundTime
             * @property {string|null} [obtainFormularyExceptionWebsite] INFPBMTaskOutputAccessInfo obtainFormularyExceptionWebsite
             * @property {string|null} [obtainPriorAuthFax] INFPBMTaskOutputAccessInfo obtainPriorAuthFax
             * @property {string|null} [obtainPriorAuthOrg] INFPBMTaskOutputAccessInfo obtainPriorAuthOrg
             * @property {string|null} [obtainPriorAuthPhone] INFPBMTaskOutputAccessInfo obtainPriorAuthPhone
             * @property {Array.<infinitusapi.INFPriorAuthRequirementType>|null} [obtainPriorAuthRequirements] INFPBMTaskOutputAccessInfo obtainPriorAuthRequirements
             * @property {string|null} [obtainPriorAuthWebsite] INFPBMTaskOutputAccessInfo obtainPriorAuthWebsite
             * @property {string|null} [obtainTierExceptionPhone] INFPBMTaskOutputAccessInfo obtainTierExceptionPhone
             * @property {infinitusapi.INFPBMSpecialtyPharmacyRequirements|null} [pbmSpecialtyPharmacyRequirements] INFPBMTaskOutputAccessInfo pbmSpecialtyPharmacyRequirements
             * @property {string|null} [predeterminationSubmissionDate] INFPBMTaskOutputAccessInfo predeterminationSubmissionDate
             * @property {google.protobuf.IBoolValue|null} [preferredDrug] INFPBMTaskOutputAccessInfo preferredDrug
             * @property {string|null} [preferredDrugName] INFPBMTaskOutputAccessInfo preferredDrugName
             * @property {string|null} [priorAuthAppealsContactFax] INFPBMTaskOutputAccessInfo priorAuthAppealsContactFax
             * @property {string|null} [priorAuthApprovalDate] INFPBMTaskOutputAccessInfo priorAuthApprovalDate
             * @property {google.protobuf.IBoolValue|null} [priorAuthApprovalNeededToGetBenefits] INFPBMTaskOutputAccessInfo priorAuthApprovalNeededToGetBenefits
             * @property {string|null} [priorAuthApprovalNumber] INFPBMTaskOutputAccessInfo priorAuthApprovalNumber
             * @property {string|null} [priorAuthApprovedDate] INFPBMTaskOutputAccessInfo priorAuthApprovedDate
             * @property {string|null} [priorAuthDenialReason] INFPBMTaskOutputAccessInfo priorAuthDenialReason
             * @property {string|null} [priorAuthEndDate] INFPBMTaskOutputAccessInfo priorAuthEndDate
             * @property {string|null} [priorAuthInitiationDate] INFPBMTaskOutputAccessInfo priorAuthInitiationDate
             * @property {infinitusapi.INFPriorAuthNotificationMethod|null} [priorAuthNotificationMethod] INFPBMTaskOutputAccessInfo priorAuthNotificationMethod
             * @property {Array.<infinitusapi.INFPriorAuthNotificationMethod>|null} [priorAuthNotificationMethods] INFPBMTaskOutputAccessInfo priorAuthNotificationMethods
             * @property {google.protobuf.IBoolValue|null} [priorAuthOnFile] INFPBMTaskOutputAccessInfo priorAuthOnFile
             * @property {string|null} [priorAuthOnFileForDose] INFPBMTaskOutputAccessInfo priorAuthOnFileForDose
             * @property {google.protobuf.IBoolValue|null} [priorAuthRequired] INFPBMTaskOutputAccessInfo priorAuthRequired
             * @property {string|null} [priorAuthStartDate] INFPBMTaskOutputAccessInfo priorAuthStartDate
             * @property {infinitusapi.INFPriorAuthStatus|null} [priorAuthStatus] INFPBMTaskOutputAccessInfo priorAuthStatus
             * @property {string|null} [priorAuthSubmissionDate] INFPBMTaskOutputAccessInfo priorAuthSubmissionDate
             * @property {Array.<infinitusapi.INFPriorAuthSubmissionMethod>|null} [priorAuthSubmissionMethods] INFPBMTaskOutputAccessInfo priorAuthSubmissionMethods
             * @property {string|null} [priorAuthTurnaroundTime] INFPBMTaskOutputAccessInfo priorAuthTurnaroundTime
             * @property {infinitusapi.INFPriorAuthType|null} [priorAuthType] INFPBMTaskOutputAccessInfo priorAuthType
             * @property {google.protobuf.IDoubleValue|null} [productTier] INFPBMTaskOutputAccessInfo productTier
             * @property {string|null} [quantityLimit] INFPBMTaskOutputAccessInfo quantityLimit
             * @property {google.protobuf.IBoolValue|null} [separatePriorAuthRequired] INFPBMTaskOutputAccessInfo separatePriorAuthRequired
             * @property {google.protobuf.IBoolValue|null} [specialtyCoverageCarveout] INFPBMTaskOutputAccessInfo specialtyCoverageCarveout
             * @property {string|null} [specialtyCoverageCarveoutEntity] INFPBMTaskOutputAccessInfo specialtyCoverageCarveoutEntity
             * @property {infinitusapi.IINFAddress|null} [specialtyPharmacy2Address] INFPBMTaskOutputAccessInfo specialtyPharmacy2Address
             * @property {string|null} [specialtyPharmacy2Fax] INFPBMTaskOutputAccessInfo specialtyPharmacy2Fax
             * @property {string|null} [specialtyPharmacy2Name] INFPBMTaskOutputAccessInfo specialtyPharmacy2Name
             * @property {string|null} [specialtyPharmacy2Phone] INFPBMTaskOutputAccessInfo specialtyPharmacy2Phone
             * @property {infinitusapi.IINFAddress|null} [specialtyPharmacy3Address] INFPBMTaskOutputAccessInfo specialtyPharmacy3Address
             * @property {string|null} [specialtyPharmacy3Fax] INFPBMTaskOutputAccessInfo specialtyPharmacy3Fax
             * @property {string|null} [specialtyPharmacy3Name] INFPBMTaskOutputAccessInfo specialtyPharmacy3Name
             * @property {string|null} [specialtyPharmacy3Phone] INFPBMTaskOutputAccessInfo specialtyPharmacy3Phone
             * @property {infinitusapi.IINFAddress|null} [specialtyPharmacyAddress] INFPBMTaskOutputAccessInfo specialtyPharmacyAddress
             * @property {string|null} [specialtyPharmacyFax] INFPBMTaskOutputAccessInfo specialtyPharmacyFax
             * @property {string|null} [specialtyPharmacyName] INFPBMTaskOutputAccessInfo specialtyPharmacyName
             * @property {string|null} [specialtyPharmacyPhone] INFPBMTaskOutputAccessInfo specialtyPharmacyPhone
             * @property {google.protobuf.IBoolValue|null} [stepTherapyRequired] INFPBMTaskOutputAccessInfo stepTherapyRequired
             * @property {string|null} [stepTherapyTreatment] INFPBMTaskOutputAccessInfo stepTherapyTreatment
             * @property {string|null} [therapyAvailabilityDate] INFPBMTaskOutputAccessInfo therapyAvailabilityDate
             * @property {string|null} [therapySeasonStartDate] INFPBMTaskOutputAccessInfo therapySeasonStartDate
             * @property {google.protobuf.IBoolValue|null} [tierExceptionProcess] INFPBMTaskOutputAccessInfo tierExceptionProcess
             * @property {google.protobuf.IDoubleValue|null} [totalTiers] INFPBMTaskOutputAccessInfo totalTiers
             */
    
            /**
             * Constructs a new INFPBMTaskOutputAccessInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMTaskOutputAccessInfo.
             * @implements IINFPBMTaskOutputAccessInfo
             * @constructor
             * @param {infinitusapi.IINFPBMTaskOutputAccessInfo=} [properties] Properties to set
             */
            function INFPBMTaskOutputAccessInfo(properties) {
                this.obtainPriorAuthRequirements = [];
                this.priorAuthNotificationMethods = [];
                this.priorAuthSubmissionMethods = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMTaskOutputAccessInfo appealsNotificationMethod.
             * @member {infinitusapi.INFAppealsNotificationMethod} appealsNotificationMethod
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.appealsNotificationMethod = 0;
    
            /**
             * INFPBMTaskOutputAccessInfo appealsTurnaroundTime.
             * @member {string} appealsTurnaroundTime
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.appealsTurnaroundTime = "";
    
            /**
             * INFPBMTaskOutputAccessInfo formularyDrug.
             * @member {google.protobuf.IBoolValue|null|undefined} formularyDrug
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.formularyDrug = null;
    
            /**
             * INFPBMTaskOutputAccessInfo formularyExceptionRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} formularyExceptionRequired
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.formularyExceptionRequired = null;
    
            /**
             * INFPBMTaskOutputAccessInfo formularyExceptionStatus.
             * @member {infinitusapi.INFFormularyExceptionStatus} formularyExceptionStatus
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.formularyExceptionStatus = 0;
    
            /**
             * INFPBMTaskOutputAccessInfo mailOrderPharmacyName.
             * @member {string} mailOrderPharmacyName
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.mailOrderPharmacyName = "";
    
            /**
             * INFPBMTaskOutputAccessInfo mailOrderPharmacyPhone.
             * @member {string} mailOrderPharmacyPhone
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.mailOrderPharmacyPhone = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainFormularyExceptionDocument.
             * @member {string} obtainFormularyExceptionDocument
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainFormularyExceptionDocument = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainFormularyExceptionFax.
             * @member {string} obtainFormularyExceptionFax
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainFormularyExceptionFax = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainFormularyExceptionNotificationMethod.
             * @member {infinitusapi.INFFormularyExceptionNotificationMethod} obtainFormularyExceptionNotificationMethod
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainFormularyExceptionNotificationMethod = 0;
    
            /**
             * INFPBMTaskOutputAccessInfo obtainFormularyExceptionOrg.
             * @member {string} obtainFormularyExceptionOrg
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainFormularyExceptionOrg = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainFormularyExceptionPhone.
             * @member {string} obtainFormularyExceptionPhone
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainFormularyExceptionPhone = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainFormularyExceptionTurnaroundTime.
             * @member {string} obtainFormularyExceptionTurnaroundTime
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainFormularyExceptionTurnaroundTime = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainFormularyExceptionWebsite.
             * @member {string} obtainFormularyExceptionWebsite
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainFormularyExceptionWebsite = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainPriorAuthFax.
             * @member {string} obtainPriorAuthFax
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainPriorAuthFax = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainPriorAuthOrg.
             * @member {string} obtainPriorAuthOrg
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainPriorAuthOrg = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainPriorAuthPhone.
             * @member {string} obtainPriorAuthPhone
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainPriorAuthPhone = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainPriorAuthRequirements.
             * @member {Array.<infinitusapi.INFPriorAuthRequirementType>} obtainPriorAuthRequirements
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainPriorAuthRequirements = $util.emptyArray;
    
            /**
             * INFPBMTaskOutputAccessInfo obtainPriorAuthWebsite.
             * @member {string} obtainPriorAuthWebsite
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainPriorAuthWebsite = "";
    
            /**
             * INFPBMTaskOutputAccessInfo obtainTierExceptionPhone.
             * @member {string} obtainTierExceptionPhone
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.obtainTierExceptionPhone = "";
    
            /**
             * INFPBMTaskOutputAccessInfo pbmSpecialtyPharmacyRequirements.
             * @member {infinitusapi.INFPBMSpecialtyPharmacyRequirements} pbmSpecialtyPharmacyRequirements
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.pbmSpecialtyPharmacyRequirements = 0;
    
            /**
             * INFPBMTaskOutputAccessInfo predeterminationSubmissionDate.
             * @member {string} predeterminationSubmissionDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.predeterminationSubmissionDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo preferredDrug.
             * @member {google.protobuf.IBoolValue|null|undefined} preferredDrug
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.preferredDrug = null;
    
            /**
             * INFPBMTaskOutputAccessInfo preferredDrugName.
             * @member {string} preferredDrugName
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.preferredDrugName = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthAppealsContactFax.
             * @member {string} priorAuthAppealsContactFax
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthAppealsContactFax = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthApprovalDate.
             * @member {string} priorAuthApprovalDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthApprovalDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthApprovalNeededToGetBenefits.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthApprovalNeededToGetBenefits
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthApprovalNeededToGetBenefits = null;
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthApprovalNumber.
             * @member {string} priorAuthApprovalNumber
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthApprovalNumber = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthApprovedDate.
             * @member {string} priorAuthApprovedDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthApprovedDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthDenialReason.
             * @member {string} priorAuthDenialReason
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthDenialReason = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthEndDate.
             * @member {string} priorAuthEndDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthEndDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthInitiationDate.
             * @member {string} priorAuthInitiationDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthInitiationDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthNotificationMethod.
             * @member {infinitusapi.INFPriorAuthNotificationMethod} priorAuthNotificationMethod
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthNotificationMethod = 0;
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthNotificationMethods.
             * @member {Array.<infinitusapi.INFPriorAuthNotificationMethod>} priorAuthNotificationMethods
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthNotificationMethods = $util.emptyArray;
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthOnFile.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthOnFile
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthOnFile = null;
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthOnFileForDose.
             * @member {string} priorAuthOnFileForDose
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthOnFileForDose = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} priorAuthRequired
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthRequired = null;
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthStartDate.
             * @member {string} priorAuthStartDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthStartDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthStatus.
             * @member {infinitusapi.INFPriorAuthStatus} priorAuthStatus
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthStatus = 0;
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthSubmissionDate.
             * @member {string} priorAuthSubmissionDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthSubmissionDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthSubmissionMethods.
             * @member {Array.<infinitusapi.INFPriorAuthSubmissionMethod>} priorAuthSubmissionMethods
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthSubmissionMethods = $util.emptyArray;
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthTurnaroundTime.
             * @member {string} priorAuthTurnaroundTime
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthTurnaroundTime = "";
    
            /**
             * INFPBMTaskOutputAccessInfo priorAuthType.
             * @member {infinitusapi.INFPriorAuthType} priorAuthType
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.priorAuthType = 0;
    
            /**
             * INFPBMTaskOutputAccessInfo productTier.
             * @member {google.protobuf.IDoubleValue|null|undefined} productTier
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.productTier = null;
    
            /**
             * INFPBMTaskOutputAccessInfo quantityLimit.
             * @member {string} quantityLimit
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.quantityLimit = "";
    
            /**
             * INFPBMTaskOutputAccessInfo separatePriorAuthRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} separatePriorAuthRequired
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.separatePriorAuthRequired = null;
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyCoverageCarveout.
             * @member {google.protobuf.IBoolValue|null|undefined} specialtyCoverageCarveout
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyCoverageCarveout = null;
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyCoverageCarveoutEntity.
             * @member {string} specialtyCoverageCarveoutEntity
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyCoverageCarveoutEntity = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacy2Address.
             * @member {infinitusapi.IINFAddress|null|undefined} specialtyPharmacy2Address
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacy2Address = null;
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacy2Fax.
             * @member {string} specialtyPharmacy2Fax
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacy2Fax = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacy2Name.
             * @member {string} specialtyPharmacy2Name
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacy2Name = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacy2Phone.
             * @member {string} specialtyPharmacy2Phone
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacy2Phone = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacy3Address.
             * @member {infinitusapi.IINFAddress|null|undefined} specialtyPharmacy3Address
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacy3Address = null;
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacy3Fax.
             * @member {string} specialtyPharmacy3Fax
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacy3Fax = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacy3Name.
             * @member {string} specialtyPharmacy3Name
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacy3Name = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacy3Phone.
             * @member {string} specialtyPharmacy3Phone
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacy3Phone = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacyAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} specialtyPharmacyAddress
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacyAddress = null;
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacyFax.
             * @member {string} specialtyPharmacyFax
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacyFax = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacyName.
             * @member {string} specialtyPharmacyName
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacyName = "";
    
            /**
             * INFPBMTaskOutputAccessInfo specialtyPharmacyPhone.
             * @member {string} specialtyPharmacyPhone
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.specialtyPharmacyPhone = "";
    
            /**
             * INFPBMTaskOutputAccessInfo stepTherapyRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} stepTherapyRequired
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.stepTherapyRequired = null;
    
            /**
             * INFPBMTaskOutputAccessInfo stepTherapyTreatment.
             * @member {string} stepTherapyTreatment
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.stepTherapyTreatment = "";
    
            /**
             * INFPBMTaskOutputAccessInfo therapyAvailabilityDate.
             * @member {string} therapyAvailabilityDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.therapyAvailabilityDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo therapySeasonStartDate.
             * @member {string} therapySeasonStartDate
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.therapySeasonStartDate = "";
    
            /**
             * INFPBMTaskOutputAccessInfo tierExceptionProcess.
             * @member {google.protobuf.IBoolValue|null|undefined} tierExceptionProcess
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.tierExceptionProcess = null;
    
            /**
             * INFPBMTaskOutputAccessInfo totalTiers.
             * @member {google.protobuf.IDoubleValue|null|undefined} totalTiers
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             */
            INFPBMTaskOutputAccessInfo.prototype.totalTiers = null;
    
            /**
             * Creates a new INFPBMTaskOutputAccessInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputAccessInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMTaskOutputAccessInfo} INFPBMTaskOutputAccessInfo instance
             */
            INFPBMTaskOutputAccessInfo.create = function create(properties) {
                return new INFPBMTaskOutputAccessInfo(properties);
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputAccessInfo message. Does not implicitly {@link infinitusapi.INFPBMTaskOutputAccessInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputAccessInfo} message INFPBMTaskOutputAccessInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputAccessInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.appealsNotificationMethod != null && Object.hasOwnProperty.call(message, "appealsNotificationMethod"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.appealsNotificationMethod);
                if (message.appealsTurnaroundTime != null && Object.hasOwnProperty.call(message, "appealsTurnaroundTime"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.appealsTurnaroundTime);
                if (message.formularyDrug != null && Object.hasOwnProperty.call(message, "formularyDrug"))
                    $root.google.protobuf.BoolValue.encode(message.formularyDrug, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.formularyExceptionRequired != null && Object.hasOwnProperty.call(message, "formularyExceptionRequired"))
                    $root.google.protobuf.BoolValue.encode(message.formularyExceptionRequired, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.formularyExceptionStatus != null && Object.hasOwnProperty.call(message, "formularyExceptionStatus"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.formularyExceptionStatus);
                if (message.mailOrderPharmacyName != null && Object.hasOwnProperty.call(message, "mailOrderPharmacyName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.mailOrderPharmacyName);
                if (message.mailOrderPharmacyPhone != null && Object.hasOwnProperty.call(message, "mailOrderPharmacyPhone"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.mailOrderPharmacyPhone);
                if (message.obtainFormularyExceptionDocument != null && Object.hasOwnProperty.call(message, "obtainFormularyExceptionDocument"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.obtainFormularyExceptionDocument);
                if (message.obtainFormularyExceptionFax != null && Object.hasOwnProperty.call(message, "obtainFormularyExceptionFax"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.obtainFormularyExceptionFax);
                if (message.obtainFormularyExceptionNotificationMethod != null && Object.hasOwnProperty.call(message, "obtainFormularyExceptionNotificationMethod"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.obtainFormularyExceptionNotificationMethod);
                if (message.obtainFormularyExceptionOrg != null && Object.hasOwnProperty.call(message, "obtainFormularyExceptionOrg"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.obtainFormularyExceptionOrg);
                if (message.obtainFormularyExceptionPhone != null && Object.hasOwnProperty.call(message, "obtainFormularyExceptionPhone"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.obtainFormularyExceptionPhone);
                if (message.obtainFormularyExceptionTurnaroundTime != null && Object.hasOwnProperty.call(message, "obtainFormularyExceptionTurnaroundTime"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.obtainFormularyExceptionTurnaroundTime);
                if (message.obtainFormularyExceptionWebsite != null && Object.hasOwnProperty.call(message, "obtainFormularyExceptionWebsite"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.obtainFormularyExceptionWebsite);
                if (message.obtainPriorAuthFax != null && Object.hasOwnProperty.call(message, "obtainPriorAuthFax"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.obtainPriorAuthFax);
                if (message.obtainPriorAuthOrg != null && Object.hasOwnProperty.call(message, "obtainPriorAuthOrg"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.obtainPriorAuthOrg);
                if (message.obtainPriorAuthPhone != null && Object.hasOwnProperty.call(message, "obtainPriorAuthPhone"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.obtainPriorAuthPhone);
                if (message.obtainPriorAuthRequirements != null && message.obtainPriorAuthRequirements.length) {
                    writer.uint32(/* id 18, wireType 2 =*/146).fork();
                    for (var i = 0; i < message.obtainPriorAuthRequirements.length; ++i)
                        writer.int32(message.obtainPriorAuthRequirements[i]);
                    writer.ldelim();
                }
                if (message.obtainPriorAuthWebsite != null && Object.hasOwnProperty.call(message, "obtainPriorAuthWebsite"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.obtainPriorAuthWebsite);
                if (message.obtainTierExceptionPhone != null && Object.hasOwnProperty.call(message, "obtainTierExceptionPhone"))
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.obtainTierExceptionPhone);
                if (message.pbmSpecialtyPharmacyRequirements != null && Object.hasOwnProperty.call(message, "pbmSpecialtyPharmacyRequirements"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int32(message.pbmSpecialtyPharmacyRequirements);
                if (message.predeterminationSubmissionDate != null && Object.hasOwnProperty.call(message, "predeterminationSubmissionDate"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.predeterminationSubmissionDate);
                if (message.preferredDrug != null && Object.hasOwnProperty.call(message, "preferredDrug"))
                    $root.google.protobuf.BoolValue.encode(message.preferredDrug, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.preferredDrugName != null && Object.hasOwnProperty.call(message, "preferredDrugName"))
                    writer.uint32(/* id 24, wireType 2 =*/194).string(message.preferredDrugName);
                if (message.priorAuthAppealsContactFax != null && Object.hasOwnProperty.call(message, "priorAuthAppealsContactFax"))
                    writer.uint32(/* id 25, wireType 2 =*/202).string(message.priorAuthAppealsContactFax);
                if (message.priorAuthApprovalDate != null && Object.hasOwnProperty.call(message, "priorAuthApprovalDate"))
                    writer.uint32(/* id 26, wireType 2 =*/210).string(message.priorAuthApprovalDate);
                if (message.priorAuthApprovalNeededToGetBenefits != null && Object.hasOwnProperty.call(message, "priorAuthApprovalNeededToGetBenefits"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthApprovalNeededToGetBenefits, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.priorAuthApprovalNumber != null && Object.hasOwnProperty.call(message, "priorAuthApprovalNumber"))
                    writer.uint32(/* id 28, wireType 2 =*/226).string(message.priorAuthApprovalNumber);
                if (message.priorAuthApprovedDate != null && Object.hasOwnProperty.call(message, "priorAuthApprovedDate"))
                    writer.uint32(/* id 29, wireType 2 =*/234).string(message.priorAuthApprovedDate);
                if (message.priorAuthDenialReason != null && Object.hasOwnProperty.call(message, "priorAuthDenialReason"))
                    writer.uint32(/* id 30, wireType 2 =*/242).string(message.priorAuthDenialReason);
                if (message.priorAuthEndDate != null && Object.hasOwnProperty.call(message, "priorAuthEndDate"))
                    writer.uint32(/* id 31, wireType 2 =*/250).string(message.priorAuthEndDate);
                if (message.priorAuthInitiationDate != null && Object.hasOwnProperty.call(message, "priorAuthInitiationDate"))
                    writer.uint32(/* id 32, wireType 2 =*/258).string(message.priorAuthInitiationDate);
                if (message.priorAuthNotificationMethod != null && Object.hasOwnProperty.call(message, "priorAuthNotificationMethod"))
                    writer.uint32(/* id 33, wireType 0 =*/264).int32(message.priorAuthNotificationMethod);
                if (message.priorAuthNotificationMethods != null && message.priorAuthNotificationMethods.length) {
                    writer.uint32(/* id 34, wireType 2 =*/274).fork();
                    for (var i = 0; i < message.priorAuthNotificationMethods.length; ++i)
                        writer.int32(message.priorAuthNotificationMethods[i]);
                    writer.ldelim();
                }
                if (message.priorAuthOnFile != null && Object.hasOwnProperty.call(message, "priorAuthOnFile"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthOnFile, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                if (message.priorAuthOnFileForDose != null && Object.hasOwnProperty.call(message, "priorAuthOnFileForDose"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.priorAuthOnFileForDose);
                if (message.priorAuthRequired != null && Object.hasOwnProperty.call(message, "priorAuthRequired"))
                    $root.google.protobuf.BoolValue.encode(message.priorAuthRequired, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                if (message.priorAuthStartDate != null && Object.hasOwnProperty.call(message, "priorAuthStartDate"))
                    writer.uint32(/* id 38, wireType 2 =*/306).string(message.priorAuthStartDate);
                if (message.priorAuthStatus != null && Object.hasOwnProperty.call(message, "priorAuthStatus"))
                    writer.uint32(/* id 39, wireType 0 =*/312).int32(message.priorAuthStatus);
                if (message.priorAuthSubmissionDate != null && Object.hasOwnProperty.call(message, "priorAuthSubmissionDate"))
                    writer.uint32(/* id 40, wireType 2 =*/322).string(message.priorAuthSubmissionDate);
                if (message.priorAuthSubmissionMethods != null && message.priorAuthSubmissionMethods.length) {
                    writer.uint32(/* id 41, wireType 2 =*/330).fork();
                    for (var i = 0; i < message.priorAuthSubmissionMethods.length; ++i)
                        writer.int32(message.priorAuthSubmissionMethods[i]);
                    writer.ldelim();
                }
                if (message.priorAuthTurnaroundTime != null && Object.hasOwnProperty.call(message, "priorAuthTurnaroundTime"))
                    writer.uint32(/* id 42, wireType 2 =*/338).string(message.priorAuthTurnaroundTime);
                if (message.priorAuthType != null && Object.hasOwnProperty.call(message, "priorAuthType"))
                    writer.uint32(/* id 43, wireType 0 =*/344).int32(message.priorAuthType);
                if (message.productTier != null && Object.hasOwnProperty.call(message, "productTier"))
                    $root.google.protobuf.DoubleValue.encode(message.productTier, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                if (message.quantityLimit != null && Object.hasOwnProperty.call(message, "quantityLimit"))
                    writer.uint32(/* id 45, wireType 2 =*/362).string(message.quantityLimit);
                if (message.separatePriorAuthRequired != null && Object.hasOwnProperty.call(message, "separatePriorAuthRequired"))
                    $root.google.protobuf.BoolValue.encode(message.separatePriorAuthRequired, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                if (message.specialtyCoverageCarveout != null && Object.hasOwnProperty.call(message, "specialtyCoverageCarveout"))
                    $root.google.protobuf.BoolValue.encode(message.specialtyCoverageCarveout, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                if (message.specialtyCoverageCarveoutEntity != null && Object.hasOwnProperty.call(message, "specialtyCoverageCarveoutEntity"))
                    writer.uint32(/* id 48, wireType 2 =*/386).string(message.specialtyCoverageCarveoutEntity);
                if (message.specialtyPharmacy2Address != null && Object.hasOwnProperty.call(message, "specialtyPharmacy2Address"))
                    $root.infinitusapi.INFAddress.encode(message.specialtyPharmacy2Address, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                if (message.specialtyPharmacy2Fax != null && Object.hasOwnProperty.call(message, "specialtyPharmacy2Fax"))
                    writer.uint32(/* id 50, wireType 2 =*/402).string(message.specialtyPharmacy2Fax);
                if (message.specialtyPharmacy2Name != null && Object.hasOwnProperty.call(message, "specialtyPharmacy2Name"))
                    writer.uint32(/* id 51, wireType 2 =*/410).string(message.specialtyPharmacy2Name);
                if (message.specialtyPharmacy2Phone != null && Object.hasOwnProperty.call(message, "specialtyPharmacy2Phone"))
                    writer.uint32(/* id 52, wireType 2 =*/418).string(message.specialtyPharmacy2Phone);
                if (message.specialtyPharmacy3Address != null && Object.hasOwnProperty.call(message, "specialtyPharmacy3Address"))
                    $root.infinitusapi.INFAddress.encode(message.specialtyPharmacy3Address, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
                if (message.specialtyPharmacy3Fax != null && Object.hasOwnProperty.call(message, "specialtyPharmacy3Fax"))
                    writer.uint32(/* id 54, wireType 2 =*/434).string(message.specialtyPharmacy3Fax);
                if (message.specialtyPharmacy3Name != null && Object.hasOwnProperty.call(message, "specialtyPharmacy3Name"))
                    writer.uint32(/* id 55, wireType 2 =*/442).string(message.specialtyPharmacy3Name);
                if (message.specialtyPharmacy3Phone != null && Object.hasOwnProperty.call(message, "specialtyPharmacy3Phone"))
                    writer.uint32(/* id 56, wireType 2 =*/450).string(message.specialtyPharmacy3Phone);
                if (message.specialtyPharmacyAddress != null && Object.hasOwnProperty.call(message, "specialtyPharmacyAddress"))
                    $root.infinitusapi.INFAddress.encode(message.specialtyPharmacyAddress, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
                if (message.specialtyPharmacyFax != null && Object.hasOwnProperty.call(message, "specialtyPharmacyFax"))
                    writer.uint32(/* id 58, wireType 2 =*/466).string(message.specialtyPharmacyFax);
                if (message.specialtyPharmacyName != null && Object.hasOwnProperty.call(message, "specialtyPharmacyName"))
                    writer.uint32(/* id 59, wireType 2 =*/474).string(message.specialtyPharmacyName);
                if (message.specialtyPharmacyPhone != null && Object.hasOwnProperty.call(message, "specialtyPharmacyPhone"))
                    writer.uint32(/* id 60, wireType 2 =*/482).string(message.specialtyPharmacyPhone);
                if (message.stepTherapyRequired != null && Object.hasOwnProperty.call(message, "stepTherapyRequired"))
                    $root.google.protobuf.BoolValue.encode(message.stepTherapyRequired, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
                if (message.stepTherapyTreatment != null && Object.hasOwnProperty.call(message, "stepTherapyTreatment"))
                    writer.uint32(/* id 62, wireType 2 =*/498).string(message.stepTherapyTreatment);
                if (message.therapyAvailabilityDate != null && Object.hasOwnProperty.call(message, "therapyAvailabilityDate"))
                    writer.uint32(/* id 63, wireType 2 =*/506).string(message.therapyAvailabilityDate);
                if (message.therapySeasonStartDate != null && Object.hasOwnProperty.call(message, "therapySeasonStartDate"))
                    writer.uint32(/* id 64, wireType 2 =*/514).string(message.therapySeasonStartDate);
                if (message.tierExceptionProcess != null && Object.hasOwnProperty.call(message, "tierExceptionProcess"))
                    $root.google.protobuf.BoolValue.encode(message.tierExceptionProcess, writer.uint32(/* id 65, wireType 2 =*/522).fork()).ldelim();
                if (message.totalTiers != null && Object.hasOwnProperty.call(message, "totalTiers"))
                    $root.google.protobuf.DoubleValue.encode(message.totalTiers, writer.uint32(/* id 66, wireType 2 =*/530).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputAccessInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPBMTaskOutputAccessInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputAccessInfo} message INFPBMTaskOutputAccessInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputAccessInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMTaskOutputAccessInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMTaskOutputAccessInfo} INFPBMTaskOutputAccessInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputAccessInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMTaskOutputAccessInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.appealsNotificationMethod = reader.int32();
                            break;
                        }
                    case 2: {
                            message.appealsTurnaroundTime = reader.string();
                            break;
                        }
                    case 3: {
                            message.formularyDrug = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.formularyExceptionRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.formularyExceptionStatus = reader.int32();
                            break;
                        }
                    case 6: {
                            message.mailOrderPharmacyName = reader.string();
                            break;
                        }
                    case 7: {
                            message.mailOrderPharmacyPhone = reader.string();
                            break;
                        }
                    case 8: {
                            message.obtainFormularyExceptionDocument = reader.string();
                            break;
                        }
                    case 9: {
                            message.obtainFormularyExceptionFax = reader.string();
                            break;
                        }
                    case 10: {
                            message.obtainFormularyExceptionNotificationMethod = reader.int32();
                            break;
                        }
                    case 11: {
                            message.obtainFormularyExceptionOrg = reader.string();
                            break;
                        }
                    case 12: {
                            message.obtainFormularyExceptionPhone = reader.string();
                            break;
                        }
                    case 13: {
                            message.obtainFormularyExceptionTurnaroundTime = reader.string();
                            break;
                        }
                    case 14: {
                            message.obtainFormularyExceptionWebsite = reader.string();
                            break;
                        }
                    case 15: {
                            message.obtainPriorAuthFax = reader.string();
                            break;
                        }
                    case 16: {
                            message.obtainPriorAuthOrg = reader.string();
                            break;
                        }
                    case 17: {
                            message.obtainPriorAuthPhone = reader.string();
                            break;
                        }
                    case 18: {
                            if (!(message.obtainPriorAuthRequirements && message.obtainPriorAuthRequirements.length))
                                message.obtainPriorAuthRequirements = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.obtainPriorAuthRequirements.push(reader.int32());
                            } else
                                message.obtainPriorAuthRequirements.push(reader.int32());
                            break;
                        }
                    case 19: {
                            message.obtainPriorAuthWebsite = reader.string();
                            break;
                        }
                    case 20: {
                            message.obtainTierExceptionPhone = reader.string();
                            break;
                        }
                    case 21: {
                            message.pbmSpecialtyPharmacyRequirements = reader.int32();
                            break;
                        }
                    case 22: {
                            message.predeterminationSubmissionDate = reader.string();
                            break;
                        }
                    case 23: {
                            message.preferredDrug = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 24: {
                            message.preferredDrugName = reader.string();
                            break;
                        }
                    case 25: {
                            message.priorAuthAppealsContactFax = reader.string();
                            break;
                        }
                    case 26: {
                            message.priorAuthApprovalDate = reader.string();
                            break;
                        }
                    case 27: {
                            message.priorAuthApprovalNeededToGetBenefits = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 28: {
                            message.priorAuthApprovalNumber = reader.string();
                            break;
                        }
                    case 29: {
                            message.priorAuthApprovedDate = reader.string();
                            break;
                        }
                    case 30: {
                            message.priorAuthDenialReason = reader.string();
                            break;
                        }
                    case 31: {
                            message.priorAuthEndDate = reader.string();
                            break;
                        }
                    case 32: {
                            message.priorAuthInitiationDate = reader.string();
                            break;
                        }
                    case 33: {
                            message.priorAuthNotificationMethod = reader.int32();
                            break;
                        }
                    case 34: {
                            if (!(message.priorAuthNotificationMethods && message.priorAuthNotificationMethods.length))
                                message.priorAuthNotificationMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.priorAuthNotificationMethods.push(reader.int32());
                            } else
                                message.priorAuthNotificationMethods.push(reader.int32());
                            break;
                        }
                    case 35: {
                            message.priorAuthOnFile = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 36: {
                            message.priorAuthOnFileForDose = reader.string();
                            break;
                        }
                    case 37: {
                            message.priorAuthRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 38: {
                            message.priorAuthStartDate = reader.string();
                            break;
                        }
                    case 39: {
                            message.priorAuthStatus = reader.int32();
                            break;
                        }
                    case 40: {
                            message.priorAuthSubmissionDate = reader.string();
                            break;
                        }
                    case 41: {
                            if (!(message.priorAuthSubmissionMethods && message.priorAuthSubmissionMethods.length))
                                message.priorAuthSubmissionMethods = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.priorAuthSubmissionMethods.push(reader.int32());
                            } else
                                message.priorAuthSubmissionMethods.push(reader.int32());
                            break;
                        }
                    case 42: {
                            message.priorAuthTurnaroundTime = reader.string();
                            break;
                        }
                    case 43: {
                            message.priorAuthType = reader.int32();
                            break;
                        }
                    case 44: {
                            message.productTier = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 45: {
                            message.quantityLimit = reader.string();
                            break;
                        }
                    case 46: {
                            message.separatePriorAuthRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 47: {
                            message.specialtyCoverageCarveout = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 48: {
                            message.specialtyCoverageCarveoutEntity = reader.string();
                            break;
                        }
                    case 49: {
                            message.specialtyPharmacy2Address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 50: {
                            message.specialtyPharmacy2Fax = reader.string();
                            break;
                        }
                    case 51: {
                            message.specialtyPharmacy2Name = reader.string();
                            break;
                        }
                    case 52: {
                            message.specialtyPharmacy2Phone = reader.string();
                            break;
                        }
                    case 53: {
                            message.specialtyPharmacy3Address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 54: {
                            message.specialtyPharmacy3Fax = reader.string();
                            break;
                        }
                    case 55: {
                            message.specialtyPharmacy3Name = reader.string();
                            break;
                        }
                    case 56: {
                            message.specialtyPharmacy3Phone = reader.string();
                            break;
                        }
                    case 57: {
                            message.specialtyPharmacyAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 58: {
                            message.specialtyPharmacyFax = reader.string();
                            break;
                        }
                    case 59: {
                            message.specialtyPharmacyName = reader.string();
                            break;
                        }
                    case 60: {
                            message.specialtyPharmacyPhone = reader.string();
                            break;
                        }
                    case 61: {
                            message.stepTherapyRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 62: {
                            message.stepTherapyTreatment = reader.string();
                            break;
                        }
                    case 63: {
                            message.therapyAvailabilityDate = reader.string();
                            break;
                        }
                    case 64: {
                            message.therapySeasonStartDate = reader.string();
                            break;
                        }
                    case 65: {
                            message.tierExceptionProcess = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 66: {
                            message.totalTiers = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMTaskOutputAccessInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMTaskOutputAccessInfo} INFPBMTaskOutputAccessInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputAccessInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMTaskOutputAccessInfo message.
             * @function verify
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMTaskOutputAccessInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.appealsNotificationMethod != null && message.hasOwnProperty("appealsNotificationMethod"))
                    switch (message.appealsNotificationMethod) {
                    default:
                        return "appealsNotificationMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.appealsTurnaroundTime != null && message.hasOwnProperty("appealsTurnaroundTime"))
                    if (!$util.isString(message.appealsTurnaroundTime))
                        return "appealsTurnaroundTime: string expected";
                if (message.formularyDrug != null && message.hasOwnProperty("formularyDrug")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.formularyDrug);
                    if (error)
                        return "formularyDrug." + error;
                }
                if (message.formularyExceptionRequired != null && message.hasOwnProperty("formularyExceptionRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.formularyExceptionRequired);
                    if (error)
                        return "formularyExceptionRequired." + error;
                }
                if (message.formularyExceptionStatus != null && message.hasOwnProperty("formularyExceptionStatus"))
                    switch (message.formularyExceptionStatus) {
                    default:
                        return "formularyExceptionStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.mailOrderPharmacyName != null && message.hasOwnProperty("mailOrderPharmacyName"))
                    if (!$util.isString(message.mailOrderPharmacyName))
                        return "mailOrderPharmacyName: string expected";
                if (message.mailOrderPharmacyPhone != null && message.hasOwnProperty("mailOrderPharmacyPhone"))
                    if (!$util.isString(message.mailOrderPharmacyPhone))
                        return "mailOrderPharmacyPhone: string expected";
                if (message.obtainFormularyExceptionDocument != null && message.hasOwnProperty("obtainFormularyExceptionDocument"))
                    if (!$util.isString(message.obtainFormularyExceptionDocument))
                        return "obtainFormularyExceptionDocument: string expected";
                if (message.obtainFormularyExceptionFax != null && message.hasOwnProperty("obtainFormularyExceptionFax"))
                    if (!$util.isString(message.obtainFormularyExceptionFax))
                        return "obtainFormularyExceptionFax: string expected";
                if (message.obtainFormularyExceptionNotificationMethod != null && message.hasOwnProperty("obtainFormularyExceptionNotificationMethod"))
                    switch (message.obtainFormularyExceptionNotificationMethod) {
                    default:
                        return "obtainFormularyExceptionNotificationMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.obtainFormularyExceptionOrg != null && message.hasOwnProperty("obtainFormularyExceptionOrg"))
                    if (!$util.isString(message.obtainFormularyExceptionOrg))
                        return "obtainFormularyExceptionOrg: string expected";
                if (message.obtainFormularyExceptionPhone != null && message.hasOwnProperty("obtainFormularyExceptionPhone"))
                    if (!$util.isString(message.obtainFormularyExceptionPhone))
                        return "obtainFormularyExceptionPhone: string expected";
                if (message.obtainFormularyExceptionTurnaroundTime != null && message.hasOwnProperty("obtainFormularyExceptionTurnaroundTime"))
                    if (!$util.isString(message.obtainFormularyExceptionTurnaroundTime))
                        return "obtainFormularyExceptionTurnaroundTime: string expected";
                if (message.obtainFormularyExceptionWebsite != null && message.hasOwnProperty("obtainFormularyExceptionWebsite"))
                    if (!$util.isString(message.obtainFormularyExceptionWebsite))
                        return "obtainFormularyExceptionWebsite: string expected";
                if (message.obtainPriorAuthFax != null && message.hasOwnProperty("obtainPriorAuthFax"))
                    if (!$util.isString(message.obtainPriorAuthFax))
                        return "obtainPriorAuthFax: string expected";
                if (message.obtainPriorAuthOrg != null && message.hasOwnProperty("obtainPriorAuthOrg"))
                    if (!$util.isString(message.obtainPriorAuthOrg))
                        return "obtainPriorAuthOrg: string expected";
                if (message.obtainPriorAuthPhone != null && message.hasOwnProperty("obtainPriorAuthPhone"))
                    if (!$util.isString(message.obtainPriorAuthPhone))
                        return "obtainPriorAuthPhone: string expected";
                if (message.obtainPriorAuthRequirements != null && message.hasOwnProperty("obtainPriorAuthRequirements")) {
                    if (!Array.isArray(message.obtainPriorAuthRequirements))
                        return "obtainPriorAuthRequirements: array expected";
                    for (var i = 0; i < message.obtainPriorAuthRequirements.length; ++i)
                        switch (message.obtainPriorAuthRequirements[i]) {
                        default:
                            return "obtainPriorAuthRequirements: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.obtainPriorAuthWebsite != null && message.hasOwnProperty("obtainPriorAuthWebsite"))
                    if (!$util.isString(message.obtainPriorAuthWebsite))
                        return "obtainPriorAuthWebsite: string expected";
                if (message.obtainTierExceptionPhone != null && message.hasOwnProperty("obtainTierExceptionPhone"))
                    if (!$util.isString(message.obtainTierExceptionPhone))
                        return "obtainTierExceptionPhone: string expected";
                if (message.pbmSpecialtyPharmacyRequirements != null && message.hasOwnProperty("pbmSpecialtyPharmacyRequirements"))
                    switch (message.pbmSpecialtyPharmacyRequirements) {
                    default:
                        return "pbmSpecialtyPharmacyRequirements: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.predeterminationSubmissionDate != null && message.hasOwnProperty("predeterminationSubmissionDate"))
                    if (!$util.isString(message.predeterminationSubmissionDate))
                        return "predeterminationSubmissionDate: string expected";
                if (message.preferredDrug != null && message.hasOwnProperty("preferredDrug")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.preferredDrug);
                    if (error)
                        return "preferredDrug." + error;
                }
                if (message.preferredDrugName != null && message.hasOwnProperty("preferredDrugName"))
                    if (!$util.isString(message.preferredDrugName))
                        return "preferredDrugName: string expected";
                if (message.priorAuthAppealsContactFax != null && message.hasOwnProperty("priorAuthAppealsContactFax"))
                    if (!$util.isString(message.priorAuthAppealsContactFax))
                        return "priorAuthAppealsContactFax: string expected";
                if (message.priorAuthApprovalDate != null && message.hasOwnProperty("priorAuthApprovalDate"))
                    if (!$util.isString(message.priorAuthApprovalDate))
                        return "priorAuthApprovalDate: string expected";
                if (message.priorAuthApprovalNeededToGetBenefits != null && message.hasOwnProperty("priorAuthApprovalNeededToGetBenefits")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthApprovalNeededToGetBenefits);
                    if (error)
                        return "priorAuthApprovalNeededToGetBenefits." + error;
                }
                if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber"))
                    if (!$util.isString(message.priorAuthApprovalNumber))
                        return "priorAuthApprovalNumber: string expected";
                if (message.priorAuthApprovedDate != null && message.hasOwnProperty("priorAuthApprovedDate"))
                    if (!$util.isString(message.priorAuthApprovedDate))
                        return "priorAuthApprovedDate: string expected";
                if (message.priorAuthDenialReason != null && message.hasOwnProperty("priorAuthDenialReason"))
                    if (!$util.isString(message.priorAuthDenialReason))
                        return "priorAuthDenialReason: string expected";
                if (message.priorAuthEndDate != null && message.hasOwnProperty("priorAuthEndDate"))
                    if (!$util.isString(message.priorAuthEndDate))
                        return "priorAuthEndDate: string expected";
                if (message.priorAuthInitiationDate != null && message.hasOwnProperty("priorAuthInitiationDate"))
                    if (!$util.isString(message.priorAuthInitiationDate))
                        return "priorAuthInitiationDate: string expected";
                if (message.priorAuthNotificationMethod != null && message.hasOwnProperty("priorAuthNotificationMethod"))
                    switch (message.priorAuthNotificationMethod) {
                    default:
                        return "priorAuthNotificationMethod: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.priorAuthNotificationMethods != null && message.hasOwnProperty("priorAuthNotificationMethods")) {
                    if (!Array.isArray(message.priorAuthNotificationMethods))
                        return "priorAuthNotificationMethods: array expected";
                    for (var i = 0; i < message.priorAuthNotificationMethods.length; ++i)
                        switch (message.priorAuthNotificationMethods[i]) {
                        default:
                            return "priorAuthNotificationMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                }
                if (message.priorAuthOnFile != null && message.hasOwnProperty("priorAuthOnFile")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthOnFile);
                    if (error)
                        return "priorAuthOnFile." + error;
                }
                if (message.priorAuthOnFileForDose != null && message.hasOwnProperty("priorAuthOnFileForDose"))
                    if (!$util.isString(message.priorAuthOnFileForDose))
                        return "priorAuthOnFileForDose: string expected";
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.priorAuthRequired);
                    if (error)
                        return "priorAuthRequired." + error;
                }
                if (message.priorAuthStartDate != null && message.hasOwnProperty("priorAuthStartDate"))
                    if (!$util.isString(message.priorAuthStartDate))
                        return "priorAuthStartDate: string expected";
                if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                    switch (message.priorAuthStatus) {
                    default:
                        return "priorAuthStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                if (message.priorAuthSubmissionDate != null && message.hasOwnProperty("priorAuthSubmissionDate"))
                    if (!$util.isString(message.priorAuthSubmissionDate))
                        return "priorAuthSubmissionDate: string expected";
                if (message.priorAuthSubmissionMethods != null && message.hasOwnProperty("priorAuthSubmissionMethods")) {
                    if (!Array.isArray(message.priorAuthSubmissionMethods))
                        return "priorAuthSubmissionMethods: array expected";
                    for (var i = 0; i < message.priorAuthSubmissionMethods.length; ++i)
                        switch (message.priorAuthSubmissionMethods[i]) {
                        default:
                            return "priorAuthSubmissionMethods: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                }
                if (message.priorAuthTurnaroundTime != null && message.hasOwnProperty("priorAuthTurnaroundTime"))
                    if (!$util.isString(message.priorAuthTurnaroundTime))
                        return "priorAuthTurnaroundTime: string expected";
                if (message.priorAuthType != null && message.hasOwnProperty("priorAuthType"))
                    switch (message.priorAuthType) {
                    default:
                        return "priorAuthType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.productTier != null && message.hasOwnProperty("productTier")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.productTier);
                    if (error)
                        return "productTier." + error;
                }
                if (message.quantityLimit != null && message.hasOwnProperty("quantityLimit"))
                    if (!$util.isString(message.quantityLimit))
                        return "quantityLimit: string expected";
                if (message.separatePriorAuthRequired != null && message.hasOwnProperty("separatePriorAuthRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.separatePriorAuthRequired);
                    if (error)
                        return "separatePriorAuthRequired." + error;
                }
                if (message.specialtyCoverageCarveout != null && message.hasOwnProperty("specialtyCoverageCarveout")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.specialtyCoverageCarveout);
                    if (error)
                        return "specialtyCoverageCarveout." + error;
                }
                if (message.specialtyCoverageCarveoutEntity != null && message.hasOwnProperty("specialtyCoverageCarveoutEntity"))
                    if (!$util.isString(message.specialtyCoverageCarveoutEntity))
                        return "specialtyCoverageCarveoutEntity: string expected";
                if (message.specialtyPharmacy2Address != null && message.hasOwnProperty("specialtyPharmacy2Address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.specialtyPharmacy2Address);
                    if (error)
                        return "specialtyPharmacy2Address." + error;
                }
                if (message.specialtyPharmacy2Fax != null && message.hasOwnProperty("specialtyPharmacy2Fax"))
                    if (!$util.isString(message.specialtyPharmacy2Fax))
                        return "specialtyPharmacy2Fax: string expected";
                if (message.specialtyPharmacy2Name != null && message.hasOwnProperty("specialtyPharmacy2Name"))
                    if (!$util.isString(message.specialtyPharmacy2Name))
                        return "specialtyPharmacy2Name: string expected";
                if (message.specialtyPharmacy2Phone != null && message.hasOwnProperty("specialtyPharmacy2Phone"))
                    if (!$util.isString(message.specialtyPharmacy2Phone))
                        return "specialtyPharmacy2Phone: string expected";
                if (message.specialtyPharmacy3Address != null && message.hasOwnProperty("specialtyPharmacy3Address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.specialtyPharmacy3Address);
                    if (error)
                        return "specialtyPharmacy3Address." + error;
                }
                if (message.specialtyPharmacy3Fax != null && message.hasOwnProperty("specialtyPharmacy3Fax"))
                    if (!$util.isString(message.specialtyPharmacy3Fax))
                        return "specialtyPharmacy3Fax: string expected";
                if (message.specialtyPharmacy3Name != null && message.hasOwnProperty("specialtyPharmacy3Name"))
                    if (!$util.isString(message.specialtyPharmacy3Name))
                        return "specialtyPharmacy3Name: string expected";
                if (message.specialtyPharmacy3Phone != null && message.hasOwnProperty("specialtyPharmacy3Phone"))
                    if (!$util.isString(message.specialtyPharmacy3Phone))
                        return "specialtyPharmacy3Phone: string expected";
                if (message.specialtyPharmacyAddress != null && message.hasOwnProperty("specialtyPharmacyAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.specialtyPharmacyAddress);
                    if (error)
                        return "specialtyPharmacyAddress." + error;
                }
                if (message.specialtyPharmacyFax != null && message.hasOwnProperty("specialtyPharmacyFax"))
                    if (!$util.isString(message.specialtyPharmacyFax))
                        return "specialtyPharmacyFax: string expected";
                if (message.specialtyPharmacyName != null && message.hasOwnProperty("specialtyPharmacyName"))
                    if (!$util.isString(message.specialtyPharmacyName))
                        return "specialtyPharmacyName: string expected";
                if (message.specialtyPharmacyPhone != null && message.hasOwnProperty("specialtyPharmacyPhone"))
                    if (!$util.isString(message.specialtyPharmacyPhone))
                        return "specialtyPharmacyPhone: string expected";
                if (message.stepTherapyRequired != null && message.hasOwnProperty("stepTherapyRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.stepTherapyRequired);
                    if (error)
                        return "stepTherapyRequired." + error;
                }
                if (message.stepTherapyTreatment != null && message.hasOwnProperty("stepTherapyTreatment"))
                    if (!$util.isString(message.stepTherapyTreatment))
                        return "stepTherapyTreatment: string expected";
                if (message.therapyAvailabilityDate != null && message.hasOwnProperty("therapyAvailabilityDate"))
                    if (!$util.isString(message.therapyAvailabilityDate))
                        return "therapyAvailabilityDate: string expected";
                if (message.therapySeasonStartDate != null && message.hasOwnProperty("therapySeasonStartDate"))
                    if (!$util.isString(message.therapySeasonStartDate))
                        return "therapySeasonStartDate: string expected";
                if (message.tierExceptionProcess != null && message.hasOwnProperty("tierExceptionProcess")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.tierExceptionProcess);
                    if (error)
                        return "tierExceptionProcess." + error;
                }
                if (message.totalTiers != null && message.hasOwnProperty("totalTiers")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.totalTiers);
                    if (error)
                        return "totalTiers." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPBMTaskOutputAccessInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMTaskOutputAccessInfo} INFPBMTaskOutputAccessInfo
             */
            INFPBMTaskOutputAccessInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMTaskOutputAccessInfo)
                    return object;
                var message = new $root.infinitusapi.INFPBMTaskOutputAccessInfo();
                switch (object.appealsNotificationMethod) {
                default:
                    if (typeof object.appealsNotificationMethod === "number") {
                        message.appealsNotificationMethod = object.appealsNotificationMethod;
                        break;
                    }
                    break;
                case "INF_APPEALS_NOTIFICATION_METHOD_UNKNOWN":
                case 0:
                    message.appealsNotificationMethod = 0;
                    break;
                case "INF_APPEALS_NOTIFICATION_METHOD_PHONE":
                case 1:
                    message.appealsNotificationMethod = 1;
                    break;
                case "INF_APPEALS_NOTIFICATION_METHOD_FAX":
                case 2:
                    message.appealsNotificationMethod = 2;
                    break;
                case "INF_APPEALS_NOTIFICATION_METHOD_EMAIL":
                case 3:
                    message.appealsNotificationMethod = 3;
                    break;
                case "INF_APPEALS_NOTIFICATION_METHOD_WEBSITE":
                case 4:
                    message.appealsNotificationMethod = 4;
                    break;
                case "INF_APPEALS_NOTIFICATION_METHOD_NOT_APPLICABLE":
                case 5:
                    message.appealsNotificationMethod = 5;
                    break;
                }
                if (object.appealsTurnaroundTime != null)
                    message.appealsTurnaroundTime = String(object.appealsTurnaroundTime);
                if (object.formularyDrug != null) {
                    if (typeof object.formularyDrug !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.formularyDrug: object expected");
                    message.formularyDrug = $root.google.protobuf.BoolValue.fromObject(object.formularyDrug);
                }
                if (object.formularyExceptionRequired != null) {
                    if (typeof object.formularyExceptionRequired !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.formularyExceptionRequired: object expected");
                    message.formularyExceptionRequired = $root.google.protobuf.BoolValue.fromObject(object.formularyExceptionRequired);
                }
                switch (object.formularyExceptionStatus) {
                default:
                    if (typeof object.formularyExceptionStatus === "number") {
                        message.formularyExceptionStatus = object.formularyExceptionStatus;
                        break;
                    }
                    break;
                case "INF_FORMULARY_EXCEPTION_STATUS_UNKNOWN":
                case 0:
                    message.formularyExceptionStatus = 0;
                    break;
                case "INF_FORMULARY_EXCEPTION_STATUS_ON_FILE":
                case 1:
                    message.formularyExceptionStatus = 1;
                    break;
                case "INF_FORMULARY_EXCEPTION_STATUS_NOT_ON_FILE":
                case 2:
                    message.formularyExceptionStatus = 2;
                    break;
                case "INF_FORMULARY_EXCEPTION_STATUS_NOT_APPLICABLE":
                case 3:
                    message.formularyExceptionStatus = 3;
                    break;
                }
                if (object.mailOrderPharmacyName != null)
                    message.mailOrderPharmacyName = String(object.mailOrderPharmacyName);
                if (object.mailOrderPharmacyPhone != null)
                    message.mailOrderPharmacyPhone = String(object.mailOrderPharmacyPhone);
                if (object.obtainFormularyExceptionDocument != null)
                    message.obtainFormularyExceptionDocument = String(object.obtainFormularyExceptionDocument);
                if (object.obtainFormularyExceptionFax != null)
                    message.obtainFormularyExceptionFax = String(object.obtainFormularyExceptionFax);
                switch (object.obtainFormularyExceptionNotificationMethod) {
                default:
                    if (typeof object.obtainFormularyExceptionNotificationMethod === "number") {
                        message.obtainFormularyExceptionNotificationMethod = object.obtainFormularyExceptionNotificationMethod;
                        break;
                    }
                    break;
                case "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_UNKNOWN":
                case 0:
                    message.obtainFormularyExceptionNotificationMethod = 0;
                    break;
                case "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_PHONE":
                case 1:
                    message.obtainFormularyExceptionNotificationMethod = 1;
                    break;
                case "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_FAX":
                case 2:
                    message.obtainFormularyExceptionNotificationMethod = 2;
                    break;
                case "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_EMAIL":
                case 3:
                    message.obtainFormularyExceptionNotificationMethod = 3;
                    break;
                case "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_WEBSITE":
                case 4:
                    message.obtainFormularyExceptionNotificationMethod = 4;
                    break;
                case "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_MAIL":
                case 5:
                    message.obtainFormularyExceptionNotificationMethod = 5;
                    break;
                case "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_NOT_APPLICABLE":
                case 6:
                    message.obtainFormularyExceptionNotificationMethod = 6;
                    break;
                }
                if (object.obtainFormularyExceptionOrg != null)
                    message.obtainFormularyExceptionOrg = String(object.obtainFormularyExceptionOrg);
                if (object.obtainFormularyExceptionPhone != null)
                    message.obtainFormularyExceptionPhone = String(object.obtainFormularyExceptionPhone);
                if (object.obtainFormularyExceptionTurnaroundTime != null)
                    message.obtainFormularyExceptionTurnaroundTime = String(object.obtainFormularyExceptionTurnaroundTime);
                if (object.obtainFormularyExceptionWebsite != null)
                    message.obtainFormularyExceptionWebsite = String(object.obtainFormularyExceptionWebsite);
                if (object.obtainPriorAuthFax != null)
                    message.obtainPriorAuthFax = String(object.obtainPriorAuthFax);
                if (object.obtainPriorAuthOrg != null)
                    message.obtainPriorAuthOrg = String(object.obtainPriorAuthOrg);
                if (object.obtainPriorAuthPhone != null)
                    message.obtainPriorAuthPhone = String(object.obtainPriorAuthPhone);
                if (object.obtainPriorAuthRequirements) {
                    if (!Array.isArray(object.obtainPriorAuthRequirements))
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.obtainPriorAuthRequirements: array expected");
                    message.obtainPriorAuthRequirements = [];
                    for (var i = 0; i < object.obtainPriorAuthRequirements.length; ++i)
                        switch (object.obtainPriorAuthRequirements[i]) {
                        default:
                            if (typeof object.obtainPriorAuthRequirements[i] === "number") {
                                message.obtainPriorAuthRequirements[i] = object.obtainPriorAuthRequirements[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_REQUIREMENT_UNKNOWN":
                        case 0:
                            message.obtainPriorAuthRequirements[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_PA_FORM":
                        case 1:
                            message.obtainPriorAuthRequirements[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_LETTER_OF_NECESSITY":
                        case 2:
                            message.obtainPriorAuthRequirements[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_MEDICAL_DOCUMENTS":
                        case 3:
                            message.obtainPriorAuthRequirements[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_FAX_COVERSHEET":
                        case 4:
                            message.obtainPriorAuthRequirements[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_SP_MUST_INITIATE":
                        case 5:
                            message.obtainPriorAuthRequirements[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_REQUIREMENT_NOT_APPLICABLE":
                        case 6:
                            message.obtainPriorAuthRequirements[i] = 6;
                            break;
                        }
                }
                if (object.obtainPriorAuthWebsite != null)
                    message.obtainPriorAuthWebsite = String(object.obtainPriorAuthWebsite);
                if (object.obtainTierExceptionPhone != null)
                    message.obtainTierExceptionPhone = String(object.obtainTierExceptionPhone);
                switch (object.pbmSpecialtyPharmacyRequirements) {
                default:
                    if (typeof object.pbmSpecialtyPharmacyRequirements === "number") {
                        message.pbmSpecialtyPharmacyRequirements = object.pbmSpecialtyPharmacyRequirements;
                        break;
                    }
                    break;
                case "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_UNKNOWN":
                case 0:
                    message.pbmSpecialtyPharmacyRequirements = 0;
                    break;
                case "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_PREFERRED":
                case 1:
                    message.pbmSpecialtyPharmacyRequirements = 1;
                    break;
                case "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_REQUIRED":
                case 2:
                    message.pbmSpecialtyPharmacyRequirements = 2;
                    break;
                case "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_NOT_APPLICABLE":
                case 3:
                    message.pbmSpecialtyPharmacyRequirements = 3;
                    break;
                }
                if (object.predeterminationSubmissionDate != null)
                    message.predeterminationSubmissionDate = String(object.predeterminationSubmissionDate);
                if (object.preferredDrug != null) {
                    if (typeof object.preferredDrug !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.preferredDrug: object expected");
                    message.preferredDrug = $root.google.protobuf.BoolValue.fromObject(object.preferredDrug);
                }
                if (object.preferredDrugName != null)
                    message.preferredDrugName = String(object.preferredDrugName);
                if (object.priorAuthAppealsContactFax != null)
                    message.priorAuthAppealsContactFax = String(object.priorAuthAppealsContactFax);
                if (object.priorAuthApprovalDate != null)
                    message.priorAuthApprovalDate = String(object.priorAuthApprovalDate);
                if (object.priorAuthApprovalNeededToGetBenefits != null) {
                    if (typeof object.priorAuthApprovalNeededToGetBenefits !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.priorAuthApprovalNeededToGetBenefits: object expected");
                    message.priorAuthApprovalNeededToGetBenefits = $root.google.protobuf.BoolValue.fromObject(object.priorAuthApprovalNeededToGetBenefits);
                }
                if (object.priorAuthApprovalNumber != null)
                    message.priorAuthApprovalNumber = String(object.priorAuthApprovalNumber);
                if (object.priorAuthApprovedDate != null)
                    message.priorAuthApprovedDate = String(object.priorAuthApprovedDate);
                if (object.priorAuthDenialReason != null)
                    message.priorAuthDenialReason = String(object.priorAuthDenialReason);
                if (object.priorAuthEndDate != null)
                    message.priorAuthEndDate = String(object.priorAuthEndDate);
                if (object.priorAuthInitiationDate != null)
                    message.priorAuthInitiationDate = String(object.priorAuthInitiationDate);
                switch (object.priorAuthNotificationMethod) {
                default:
                    if (typeof object.priorAuthNotificationMethod === "number") {
                        message.priorAuthNotificationMethod = object.priorAuthNotificationMethod;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                case 0:
                    message.priorAuthNotificationMethod = 0;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                case 1:
                    message.priorAuthNotificationMethod = 1;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                case 2:
                    message.priorAuthNotificationMethod = 2;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                case 3:
                    message.priorAuthNotificationMethod = 3;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                case 4:
                    message.priorAuthNotificationMethod = 4;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                case 5:
                    message.priorAuthNotificationMethod = 5;
                    break;
                case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                case 6:
                    message.priorAuthNotificationMethod = 6;
                    break;
                }
                if (object.priorAuthNotificationMethods) {
                    if (!Array.isArray(object.priorAuthNotificationMethods))
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.priorAuthNotificationMethods: array expected");
                    message.priorAuthNotificationMethods = [];
                    for (var i = 0; i < object.priorAuthNotificationMethods.length; ++i)
                        switch (object.priorAuthNotificationMethods[i]) {
                        default:
                            if (typeof object.priorAuthNotificationMethods[i] === "number") {
                                message.priorAuthNotificationMethods[i] = object.priorAuthNotificationMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN":
                        case 0:
                            message.priorAuthNotificationMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.priorAuthNotificationMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_PHONE":
                        case 2:
                            message.priorAuthNotificationMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_FAX":
                        case 3:
                            message.priorAuthNotificationMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_EMAIL":
                        case 4:
                            message.priorAuthNotificationMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_MAIL":
                        case 5:
                            message.priorAuthNotificationMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_NOTIFICATION_METHOD_WEBSITE":
                        case 6:
                            message.priorAuthNotificationMethods[i] = 6;
                            break;
                        }
                }
                if (object.priorAuthOnFile != null) {
                    if (typeof object.priorAuthOnFile !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.priorAuthOnFile: object expected");
                    message.priorAuthOnFile = $root.google.protobuf.BoolValue.fromObject(object.priorAuthOnFile);
                }
                if (object.priorAuthOnFileForDose != null)
                    message.priorAuthOnFileForDose = String(object.priorAuthOnFileForDose);
                if (object.priorAuthRequired != null) {
                    if (typeof object.priorAuthRequired !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.priorAuthRequired: object expected");
                    message.priorAuthRequired = $root.google.protobuf.BoolValue.fromObject(object.priorAuthRequired);
                }
                if (object.priorAuthStartDate != null)
                    message.priorAuthStartDate = String(object.priorAuthStartDate);
                switch (object.priorAuthStatus) {
                default:
                    if (typeof object.priorAuthStatus === "number") {
                        message.priorAuthStatus = object.priorAuthStatus;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_STATUS_UNKNOWN":
                case 0:
                    message.priorAuthStatus = 0;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_APPLICABLE":
                case 1:
                    message.priorAuthStatus = 1;
                    break;
                case "INF_PRIOR_AUTH_STATUS_NOT_ON_FILE":
                case 2:
                    message.priorAuthStatus = 2;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED":
                case 3:
                    message.priorAuthStatus = 3;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DOCTOR":
                case 4:
                    message.priorAuthStatus = 4;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_DIAGNOSIS":
                case 5:
                    message.priorAuthStatus = 5;
                    break;
                case "INF_PRIOR_AUTH_STATUS_DENIED":
                case 6:
                    message.priorAuthStatus = 6;
                    break;
                case "INF_PRIOR_AUTH_STATUS_EXPIRED":
                case 7:
                    message.priorAuthStatus = 7;
                    break;
                case "INF_PRIOR_AUTH_STATUS_FUTURE":
                case 8:
                    message.priorAuthStatus = 8;
                    break;
                case "INF_PRIOR_AUTH_STATUS_PENDING":
                case 9:
                    message.priorAuthStatus = 9;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_POS":
                case 10:
                    message.priorAuthStatus = 10;
                    break;
                case "INF_PRIOR_AUTH_STATUS_APPROVED_DIFFERENT_ADDRESS":
                case 11:
                    message.priorAuthStatus = 11;
                    break;
                }
                if (object.priorAuthSubmissionDate != null)
                    message.priorAuthSubmissionDate = String(object.priorAuthSubmissionDate);
                if (object.priorAuthSubmissionMethods) {
                    if (!Array.isArray(object.priorAuthSubmissionMethods))
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.priorAuthSubmissionMethods: array expected");
                    message.priorAuthSubmissionMethods = [];
                    for (var i = 0; i < object.priorAuthSubmissionMethods.length; ++i)
                        switch (object.priorAuthSubmissionMethods[i]) {
                        default:
                            if (typeof object.priorAuthSubmissionMethods[i] === "number") {
                                message.priorAuthSubmissionMethods[i] = object.priorAuthSubmissionMethods[i];
                                break;
                            }
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_UNKNOWN":
                        case 0:
                            message.priorAuthSubmissionMethods[i] = 0;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_NOT_APPLICABLE":
                        case 1:
                            message.priorAuthSubmissionMethods[i] = 1;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_PHONE":
                        case 2:
                            message.priorAuthSubmissionMethods[i] = 2;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_FAX":
                        case 3:
                            message.priorAuthSubmissionMethods[i] = 3;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_MAIL":
                        case 4:
                            message.priorAuthSubmissionMethods[i] = 4;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_WEBSITE":
                        case 5:
                            message.priorAuthSubmissionMethods[i] = 5;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_METHOD_EMAIL":
                        case 6:
                            message.priorAuthSubmissionMethods[i] = 6;
                            break;
                        case "INF_PRIOR_AUTH_SUBMISSION_ELECTRONIC_TRANSMISSION":
                        case 7:
                            message.priorAuthSubmissionMethods[i] = 7;
                            break;
                        }
                }
                if (object.priorAuthTurnaroundTime != null)
                    message.priorAuthTurnaroundTime = String(object.priorAuthTurnaroundTime);
                switch (object.priorAuthType) {
                default:
                    if (typeof object.priorAuthType === "number") {
                        message.priorAuthType = object.priorAuthType;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_TYPE_UNKNOWN":
                case 0:
                    message.priorAuthType = 0;
                    break;
                case "INF_PRIOR_AUTH_TYPE_PRIOR_AUTHORIZATION":
                case 1:
                    message.priorAuthType = 1;
                    break;
                case "INF_PRIOR_AUTH_TYPE_PRECERTIFICATION":
                case 2:
                    message.priorAuthType = 2;
                    break;
                case "INF_PRIOR_AUTH_TYPE_PREDETERMINATION":
                case 3:
                    message.priorAuthType = 3;
                    break;
                case "INF_PRIOR_AUTH_TYPE_FORMULARY_EXCEPTION":
                case 4:
                    message.priorAuthType = 4;
                    break;
                case "INF_PRIOR_AUTH_TYPE_TIER_EXCEPTION":
                case 5:
                    message.priorAuthType = 5;
                    break;
                case "INF_PRIOR_AUTH_TYPE_NOT_APPLICABLE":
                case 6:
                    message.priorAuthType = 6;
                    break;
                case "INF_PRIOR_AUTH_TYPE_NONE":
                case 7:
                    message.priorAuthType = 7;
                    break;
                }
                if (object.productTier != null) {
                    if (typeof object.productTier !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.productTier: object expected");
                    message.productTier = $root.google.protobuf.DoubleValue.fromObject(object.productTier);
                }
                if (object.quantityLimit != null)
                    message.quantityLimit = String(object.quantityLimit);
                if (object.separatePriorAuthRequired != null) {
                    if (typeof object.separatePriorAuthRequired !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.separatePriorAuthRequired: object expected");
                    message.separatePriorAuthRequired = $root.google.protobuf.BoolValue.fromObject(object.separatePriorAuthRequired);
                }
                if (object.specialtyCoverageCarveout != null) {
                    if (typeof object.specialtyCoverageCarveout !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.specialtyCoverageCarveout: object expected");
                    message.specialtyCoverageCarveout = $root.google.protobuf.BoolValue.fromObject(object.specialtyCoverageCarveout);
                }
                if (object.specialtyCoverageCarveoutEntity != null)
                    message.specialtyCoverageCarveoutEntity = String(object.specialtyCoverageCarveoutEntity);
                if (object.specialtyPharmacy2Address != null) {
                    if (typeof object.specialtyPharmacy2Address !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.specialtyPharmacy2Address: object expected");
                    message.specialtyPharmacy2Address = $root.infinitusapi.INFAddress.fromObject(object.specialtyPharmacy2Address);
                }
                if (object.specialtyPharmacy2Fax != null)
                    message.specialtyPharmacy2Fax = String(object.specialtyPharmacy2Fax);
                if (object.specialtyPharmacy2Name != null)
                    message.specialtyPharmacy2Name = String(object.specialtyPharmacy2Name);
                if (object.specialtyPharmacy2Phone != null)
                    message.specialtyPharmacy2Phone = String(object.specialtyPharmacy2Phone);
                if (object.specialtyPharmacy3Address != null) {
                    if (typeof object.specialtyPharmacy3Address !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.specialtyPharmacy3Address: object expected");
                    message.specialtyPharmacy3Address = $root.infinitusapi.INFAddress.fromObject(object.specialtyPharmacy3Address);
                }
                if (object.specialtyPharmacy3Fax != null)
                    message.specialtyPharmacy3Fax = String(object.specialtyPharmacy3Fax);
                if (object.specialtyPharmacy3Name != null)
                    message.specialtyPharmacy3Name = String(object.specialtyPharmacy3Name);
                if (object.specialtyPharmacy3Phone != null)
                    message.specialtyPharmacy3Phone = String(object.specialtyPharmacy3Phone);
                if (object.specialtyPharmacyAddress != null) {
                    if (typeof object.specialtyPharmacyAddress !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.specialtyPharmacyAddress: object expected");
                    message.specialtyPharmacyAddress = $root.infinitusapi.INFAddress.fromObject(object.specialtyPharmacyAddress);
                }
                if (object.specialtyPharmacyFax != null)
                    message.specialtyPharmacyFax = String(object.specialtyPharmacyFax);
                if (object.specialtyPharmacyName != null)
                    message.specialtyPharmacyName = String(object.specialtyPharmacyName);
                if (object.specialtyPharmacyPhone != null)
                    message.specialtyPharmacyPhone = String(object.specialtyPharmacyPhone);
                if (object.stepTherapyRequired != null) {
                    if (typeof object.stepTherapyRequired !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.stepTherapyRequired: object expected");
                    message.stepTherapyRequired = $root.google.protobuf.BoolValue.fromObject(object.stepTherapyRequired);
                }
                if (object.stepTherapyTreatment != null)
                    message.stepTherapyTreatment = String(object.stepTherapyTreatment);
                if (object.therapyAvailabilityDate != null)
                    message.therapyAvailabilityDate = String(object.therapyAvailabilityDate);
                if (object.therapySeasonStartDate != null)
                    message.therapySeasonStartDate = String(object.therapySeasonStartDate);
                if (object.tierExceptionProcess != null) {
                    if (typeof object.tierExceptionProcess !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.tierExceptionProcess: object expected");
                    message.tierExceptionProcess = $root.google.protobuf.BoolValue.fromObject(object.tierExceptionProcess);
                }
                if (object.totalTiers != null) {
                    if (typeof object.totalTiers !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputAccessInfo.totalTiers: object expected");
                    message.totalTiers = $root.google.protobuf.DoubleValue.fromObject(object.totalTiers);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMTaskOutputAccessInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {infinitusapi.INFPBMTaskOutputAccessInfo} message INFPBMTaskOutputAccessInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMTaskOutputAccessInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.obtainPriorAuthRequirements = [];
                    object.priorAuthNotificationMethods = [];
                    object.priorAuthSubmissionMethods = [];
                }
                if (options.defaults) {
                    object.appealsNotificationMethod = options.enums === String ? "INF_APPEALS_NOTIFICATION_METHOD_UNKNOWN" : 0;
                    object.appealsTurnaroundTime = "";
                    object.formularyDrug = null;
                    object.formularyExceptionRequired = null;
                    object.formularyExceptionStatus = options.enums === String ? "INF_FORMULARY_EXCEPTION_STATUS_UNKNOWN" : 0;
                    object.mailOrderPharmacyName = "";
                    object.mailOrderPharmacyPhone = "";
                    object.obtainFormularyExceptionDocument = "";
                    object.obtainFormularyExceptionFax = "";
                    object.obtainFormularyExceptionNotificationMethod = options.enums === String ? "INF_FORMULARY_EXCEPTION_NOTIFICATION_METHOD_UNKNOWN" : 0;
                    object.obtainFormularyExceptionOrg = "";
                    object.obtainFormularyExceptionPhone = "";
                    object.obtainFormularyExceptionTurnaroundTime = "";
                    object.obtainFormularyExceptionWebsite = "";
                    object.obtainPriorAuthFax = "";
                    object.obtainPriorAuthOrg = "";
                    object.obtainPriorAuthPhone = "";
                    object.obtainPriorAuthWebsite = "";
                    object.obtainTierExceptionPhone = "";
                    object.pbmSpecialtyPharmacyRequirements = options.enums === String ? "INF_PBM_SPECIALTY_PHARMACY_REQUIREMENTS_UNKNOWN" : 0;
                    object.predeterminationSubmissionDate = "";
                    object.preferredDrug = null;
                    object.preferredDrugName = "";
                    object.priorAuthAppealsContactFax = "";
                    object.priorAuthApprovalDate = "";
                    object.priorAuthApprovalNeededToGetBenefits = null;
                    object.priorAuthApprovalNumber = "";
                    object.priorAuthApprovedDate = "";
                    object.priorAuthDenialReason = "";
                    object.priorAuthEndDate = "";
                    object.priorAuthInitiationDate = "";
                    object.priorAuthNotificationMethod = options.enums === String ? "INF_PRIOR_AUTH_NOTIFICATION_METHOD_UNKNOWN" : 0;
                    object.priorAuthOnFile = null;
                    object.priorAuthOnFileForDose = "";
                    object.priorAuthRequired = null;
                    object.priorAuthStartDate = "";
                    object.priorAuthStatus = options.enums === String ? "INF_PRIOR_AUTH_STATUS_UNKNOWN" : 0;
                    object.priorAuthSubmissionDate = "";
                    object.priorAuthTurnaroundTime = "";
                    object.priorAuthType = options.enums === String ? "INF_PRIOR_AUTH_TYPE_UNKNOWN" : 0;
                    object.productTier = null;
                    object.quantityLimit = "";
                    object.separatePriorAuthRequired = null;
                    object.specialtyCoverageCarveout = null;
                    object.specialtyCoverageCarveoutEntity = "";
                    object.specialtyPharmacy2Address = null;
                    object.specialtyPharmacy2Fax = "";
                    object.specialtyPharmacy2Name = "";
                    object.specialtyPharmacy2Phone = "";
                    object.specialtyPharmacy3Address = null;
                    object.specialtyPharmacy3Fax = "";
                    object.specialtyPharmacy3Name = "";
                    object.specialtyPharmacy3Phone = "";
                    object.specialtyPharmacyAddress = null;
                    object.specialtyPharmacyFax = "";
                    object.specialtyPharmacyName = "";
                    object.specialtyPharmacyPhone = "";
                    object.stepTherapyRequired = null;
                    object.stepTherapyTreatment = "";
                    object.therapyAvailabilityDate = "";
                    object.therapySeasonStartDate = "";
                    object.tierExceptionProcess = null;
                    object.totalTiers = null;
                }
                if (message.appealsNotificationMethod != null && message.hasOwnProperty("appealsNotificationMethod"))
                    object.appealsNotificationMethod = options.enums === String ? $root.infinitusapi.INFAppealsNotificationMethod[message.appealsNotificationMethod] === undefined ? message.appealsNotificationMethod : $root.infinitusapi.INFAppealsNotificationMethod[message.appealsNotificationMethod] : message.appealsNotificationMethod;
                if (message.appealsTurnaroundTime != null && message.hasOwnProperty("appealsTurnaroundTime"))
                    object.appealsTurnaroundTime = message.appealsTurnaroundTime;
                if (message.formularyDrug != null && message.hasOwnProperty("formularyDrug"))
                    object.formularyDrug = $root.google.protobuf.BoolValue.toObject(message.formularyDrug, options);
                if (message.formularyExceptionRequired != null && message.hasOwnProperty("formularyExceptionRequired"))
                    object.formularyExceptionRequired = $root.google.protobuf.BoolValue.toObject(message.formularyExceptionRequired, options);
                if (message.formularyExceptionStatus != null && message.hasOwnProperty("formularyExceptionStatus"))
                    object.formularyExceptionStatus = options.enums === String ? $root.infinitusapi.INFFormularyExceptionStatus[message.formularyExceptionStatus] === undefined ? message.formularyExceptionStatus : $root.infinitusapi.INFFormularyExceptionStatus[message.formularyExceptionStatus] : message.formularyExceptionStatus;
                if (message.mailOrderPharmacyName != null && message.hasOwnProperty("mailOrderPharmacyName"))
                    object.mailOrderPharmacyName = message.mailOrderPharmacyName;
                if (message.mailOrderPharmacyPhone != null && message.hasOwnProperty("mailOrderPharmacyPhone"))
                    object.mailOrderPharmacyPhone = message.mailOrderPharmacyPhone;
                if (message.obtainFormularyExceptionDocument != null && message.hasOwnProperty("obtainFormularyExceptionDocument"))
                    object.obtainFormularyExceptionDocument = message.obtainFormularyExceptionDocument;
                if (message.obtainFormularyExceptionFax != null && message.hasOwnProperty("obtainFormularyExceptionFax"))
                    object.obtainFormularyExceptionFax = message.obtainFormularyExceptionFax;
                if (message.obtainFormularyExceptionNotificationMethod != null && message.hasOwnProperty("obtainFormularyExceptionNotificationMethod"))
                    object.obtainFormularyExceptionNotificationMethod = options.enums === String ? $root.infinitusapi.INFFormularyExceptionNotificationMethod[message.obtainFormularyExceptionNotificationMethod] === undefined ? message.obtainFormularyExceptionNotificationMethod : $root.infinitusapi.INFFormularyExceptionNotificationMethod[message.obtainFormularyExceptionNotificationMethod] : message.obtainFormularyExceptionNotificationMethod;
                if (message.obtainFormularyExceptionOrg != null && message.hasOwnProperty("obtainFormularyExceptionOrg"))
                    object.obtainFormularyExceptionOrg = message.obtainFormularyExceptionOrg;
                if (message.obtainFormularyExceptionPhone != null && message.hasOwnProperty("obtainFormularyExceptionPhone"))
                    object.obtainFormularyExceptionPhone = message.obtainFormularyExceptionPhone;
                if (message.obtainFormularyExceptionTurnaroundTime != null && message.hasOwnProperty("obtainFormularyExceptionTurnaroundTime"))
                    object.obtainFormularyExceptionTurnaroundTime = message.obtainFormularyExceptionTurnaroundTime;
                if (message.obtainFormularyExceptionWebsite != null && message.hasOwnProperty("obtainFormularyExceptionWebsite"))
                    object.obtainFormularyExceptionWebsite = message.obtainFormularyExceptionWebsite;
                if (message.obtainPriorAuthFax != null && message.hasOwnProperty("obtainPriorAuthFax"))
                    object.obtainPriorAuthFax = message.obtainPriorAuthFax;
                if (message.obtainPriorAuthOrg != null && message.hasOwnProperty("obtainPriorAuthOrg"))
                    object.obtainPriorAuthOrg = message.obtainPriorAuthOrg;
                if (message.obtainPriorAuthPhone != null && message.hasOwnProperty("obtainPriorAuthPhone"))
                    object.obtainPriorAuthPhone = message.obtainPriorAuthPhone;
                if (message.obtainPriorAuthRequirements && message.obtainPriorAuthRequirements.length) {
                    object.obtainPriorAuthRequirements = [];
                    for (var j = 0; j < message.obtainPriorAuthRequirements.length; ++j)
                        object.obtainPriorAuthRequirements[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthRequirementType[message.obtainPriorAuthRequirements[j]] === undefined ? message.obtainPriorAuthRequirements[j] : $root.infinitusapi.INFPriorAuthRequirementType[message.obtainPriorAuthRequirements[j]] : message.obtainPriorAuthRequirements[j];
                }
                if (message.obtainPriorAuthWebsite != null && message.hasOwnProperty("obtainPriorAuthWebsite"))
                    object.obtainPriorAuthWebsite = message.obtainPriorAuthWebsite;
                if (message.obtainTierExceptionPhone != null && message.hasOwnProperty("obtainTierExceptionPhone"))
                    object.obtainTierExceptionPhone = message.obtainTierExceptionPhone;
                if (message.pbmSpecialtyPharmacyRequirements != null && message.hasOwnProperty("pbmSpecialtyPharmacyRequirements"))
                    object.pbmSpecialtyPharmacyRequirements = options.enums === String ? $root.infinitusapi.INFPBMSpecialtyPharmacyRequirements[message.pbmSpecialtyPharmacyRequirements] === undefined ? message.pbmSpecialtyPharmacyRequirements : $root.infinitusapi.INFPBMSpecialtyPharmacyRequirements[message.pbmSpecialtyPharmacyRequirements] : message.pbmSpecialtyPharmacyRequirements;
                if (message.predeterminationSubmissionDate != null && message.hasOwnProperty("predeterminationSubmissionDate"))
                    object.predeterminationSubmissionDate = message.predeterminationSubmissionDate;
                if (message.preferredDrug != null && message.hasOwnProperty("preferredDrug"))
                    object.preferredDrug = $root.google.protobuf.BoolValue.toObject(message.preferredDrug, options);
                if (message.preferredDrugName != null && message.hasOwnProperty("preferredDrugName"))
                    object.preferredDrugName = message.preferredDrugName;
                if (message.priorAuthAppealsContactFax != null && message.hasOwnProperty("priorAuthAppealsContactFax"))
                    object.priorAuthAppealsContactFax = message.priorAuthAppealsContactFax;
                if (message.priorAuthApprovalDate != null && message.hasOwnProperty("priorAuthApprovalDate"))
                    object.priorAuthApprovalDate = message.priorAuthApprovalDate;
                if (message.priorAuthApprovalNeededToGetBenefits != null && message.hasOwnProperty("priorAuthApprovalNeededToGetBenefits"))
                    object.priorAuthApprovalNeededToGetBenefits = $root.google.protobuf.BoolValue.toObject(message.priorAuthApprovalNeededToGetBenefits, options);
                if (message.priorAuthApprovalNumber != null && message.hasOwnProperty("priorAuthApprovalNumber"))
                    object.priorAuthApprovalNumber = message.priorAuthApprovalNumber;
                if (message.priorAuthApprovedDate != null && message.hasOwnProperty("priorAuthApprovedDate"))
                    object.priorAuthApprovedDate = message.priorAuthApprovedDate;
                if (message.priorAuthDenialReason != null && message.hasOwnProperty("priorAuthDenialReason"))
                    object.priorAuthDenialReason = message.priorAuthDenialReason;
                if (message.priorAuthEndDate != null && message.hasOwnProperty("priorAuthEndDate"))
                    object.priorAuthEndDate = message.priorAuthEndDate;
                if (message.priorAuthInitiationDate != null && message.hasOwnProperty("priorAuthInitiationDate"))
                    object.priorAuthInitiationDate = message.priorAuthInitiationDate;
                if (message.priorAuthNotificationMethod != null && message.hasOwnProperty("priorAuthNotificationMethod"))
                    object.priorAuthNotificationMethod = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethod] === undefined ? message.priorAuthNotificationMethod : $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethod] : message.priorAuthNotificationMethod;
                if (message.priorAuthNotificationMethods && message.priorAuthNotificationMethods.length) {
                    object.priorAuthNotificationMethods = [];
                    for (var j = 0; j < message.priorAuthNotificationMethods.length; ++j)
                        object.priorAuthNotificationMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethods[j]] === undefined ? message.priorAuthNotificationMethods[j] : $root.infinitusapi.INFPriorAuthNotificationMethod[message.priorAuthNotificationMethods[j]] : message.priorAuthNotificationMethods[j];
                }
                if (message.priorAuthOnFile != null && message.hasOwnProperty("priorAuthOnFile"))
                    object.priorAuthOnFile = $root.google.protobuf.BoolValue.toObject(message.priorAuthOnFile, options);
                if (message.priorAuthOnFileForDose != null && message.hasOwnProperty("priorAuthOnFileForDose"))
                    object.priorAuthOnFileForDose = message.priorAuthOnFileForDose;
                if (message.priorAuthRequired != null && message.hasOwnProperty("priorAuthRequired"))
                    object.priorAuthRequired = $root.google.protobuf.BoolValue.toObject(message.priorAuthRequired, options);
                if (message.priorAuthStartDate != null && message.hasOwnProperty("priorAuthStartDate"))
                    object.priorAuthStartDate = message.priorAuthStartDate;
                if (message.priorAuthStatus != null && message.hasOwnProperty("priorAuthStatus"))
                    object.priorAuthStatus = options.enums === String ? $root.infinitusapi.INFPriorAuthStatus[message.priorAuthStatus] === undefined ? message.priorAuthStatus : $root.infinitusapi.INFPriorAuthStatus[message.priorAuthStatus] : message.priorAuthStatus;
                if (message.priorAuthSubmissionDate != null && message.hasOwnProperty("priorAuthSubmissionDate"))
                    object.priorAuthSubmissionDate = message.priorAuthSubmissionDate;
                if (message.priorAuthSubmissionMethods && message.priorAuthSubmissionMethods.length) {
                    object.priorAuthSubmissionMethods = [];
                    for (var j = 0; j < message.priorAuthSubmissionMethods.length; ++j)
                        object.priorAuthSubmissionMethods[j] = options.enums === String ? $root.infinitusapi.INFPriorAuthSubmissionMethod[message.priorAuthSubmissionMethods[j]] === undefined ? message.priorAuthSubmissionMethods[j] : $root.infinitusapi.INFPriorAuthSubmissionMethod[message.priorAuthSubmissionMethods[j]] : message.priorAuthSubmissionMethods[j];
                }
                if (message.priorAuthTurnaroundTime != null && message.hasOwnProperty("priorAuthTurnaroundTime"))
                    object.priorAuthTurnaroundTime = message.priorAuthTurnaroundTime;
                if (message.priorAuthType != null && message.hasOwnProperty("priorAuthType"))
                    object.priorAuthType = options.enums === String ? $root.infinitusapi.INFPriorAuthType[message.priorAuthType] === undefined ? message.priorAuthType : $root.infinitusapi.INFPriorAuthType[message.priorAuthType] : message.priorAuthType;
                if (message.productTier != null && message.hasOwnProperty("productTier"))
                    object.productTier = $root.google.protobuf.DoubleValue.toObject(message.productTier, options);
                if (message.quantityLimit != null && message.hasOwnProperty("quantityLimit"))
                    object.quantityLimit = message.quantityLimit;
                if (message.separatePriorAuthRequired != null && message.hasOwnProperty("separatePriorAuthRequired"))
                    object.separatePriorAuthRequired = $root.google.protobuf.BoolValue.toObject(message.separatePriorAuthRequired, options);
                if (message.specialtyCoverageCarveout != null && message.hasOwnProperty("specialtyCoverageCarveout"))
                    object.specialtyCoverageCarveout = $root.google.protobuf.BoolValue.toObject(message.specialtyCoverageCarveout, options);
                if (message.specialtyCoverageCarveoutEntity != null && message.hasOwnProperty("specialtyCoverageCarveoutEntity"))
                    object.specialtyCoverageCarveoutEntity = message.specialtyCoverageCarveoutEntity;
                if (message.specialtyPharmacy2Address != null && message.hasOwnProperty("specialtyPharmacy2Address"))
                    object.specialtyPharmacy2Address = $root.infinitusapi.INFAddress.toObject(message.specialtyPharmacy2Address, options);
                if (message.specialtyPharmacy2Fax != null && message.hasOwnProperty("specialtyPharmacy2Fax"))
                    object.specialtyPharmacy2Fax = message.specialtyPharmacy2Fax;
                if (message.specialtyPharmacy2Name != null && message.hasOwnProperty("specialtyPharmacy2Name"))
                    object.specialtyPharmacy2Name = message.specialtyPharmacy2Name;
                if (message.specialtyPharmacy2Phone != null && message.hasOwnProperty("specialtyPharmacy2Phone"))
                    object.specialtyPharmacy2Phone = message.specialtyPharmacy2Phone;
                if (message.specialtyPharmacy3Address != null && message.hasOwnProperty("specialtyPharmacy3Address"))
                    object.specialtyPharmacy3Address = $root.infinitusapi.INFAddress.toObject(message.specialtyPharmacy3Address, options);
                if (message.specialtyPharmacy3Fax != null && message.hasOwnProperty("specialtyPharmacy3Fax"))
                    object.specialtyPharmacy3Fax = message.specialtyPharmacy3Fax;
                if (message.specialtyPharmacy3Name != null && message.hasOwnProperty("specialtyPharmacy3Name"))
                    object.specialtyPharmacy3Name = message.specialtyPharmacy3Name;
                if (message.specialtyPharmacy3Phone != null && message.hasOwnProperty("specialtyPharmacy3Phone"))
                    object.specialtyPharmacy3Phone = message.specialtyPharmacy3Phone;
                if (message.specialtyPharmacyAddress != null && message.hasOwnProperty("specialtyPharmacyAddress"))
                    object.specialtyPharmacyAddress = $root.infinitusapi.INFAddress.toObject(message.specialtyPharmacyAddress, options);
                if (message.specialtyPharmacyFax != null && message.hasOwnProperty("specialtyPharmacyFax"))
                    object.specialtyPharmacyFax = message.specialtyPharmacyFax;
                if (message.specialtyPharmacyName != null && message.hasOwnProperty("specialtyPharmacyName"))
                    object.specialtyPharmacyName = message.specialtyPharmacyName;
                if (message.specialtyPharmacyPhone != null && message.hasOwnProperty("specialtyPharmacyPhone"))
                    object.specialtyPharmacyPhone = message.specialtyPharmacyPhone;
                if (message.stepTherapyRequired != null && message.hasOwnProperty("stepTherapyRequired"))
                    object.stepTherapyRequired = $root.google.protobuf.BoolValue.toObject(message.stepTherapyRequired, options);
                if (message.stepTherapyTreatment != null && message.hasOwnProperty("stepTherapyTreatment"))
                    object.stepTherapyTreatment = message.stepTherapyTreatment;
                if (message.therapyAvailabilityDate != null && message.hasOwnProperty("therapyAvailabilityDate"))
                    object.therapyAvailabilityDate = message.therapyAvailabilityDate;
                if (message.therapySeasonStartDate != null && message.hasOwnProperty("therapySeasonStartDate"))
                    object.therapySeasonStartDate = message.therapySeasonStartDate;
                if (message.tierExceptionProcess != null && message.hasOwnProperty("tierExceptionProcess"))
                    object.tierExceptionProcess = $root.google.protobuf.BoolValue.toObject(message.tierExceptionProcess, options);
                if (message.totalTiers != null && message.hasOwnProperty("totalTiers"))
                    object.totalTiers = $root.google.protobuf.DoubleValue.toObject(message.totalTiers, options);
                return object;
            };
    
            /**
             * Converts this INFPBMTaskOutputAccessInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMTaskOutputAccessInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMTaskOutputAccessInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMTaskOutputAccessInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMTaskOutputAccessInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMTaskOutputAccessInfo";
            };
    
            return INFPBMTaskOutputAccessInfo;
        })();
    
        infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS = (function() {
    
            /**
             * Properties of a INFPBMTaskOutputDeductiblesAndOOPS.
             * @memberof infinitusapi
             * @interface IINFPBMTaskOutputDeductiblesAndOOPS
             * @property {google.protobuf.IDoubleValue|null} [annualBenefitCap] INFPBMTaskOutputDeductiblesAndOOPS annualBenefitCap
             * @property {google.protobuf.IBoolValue|null} [annualBenefitCapExists] INFPBMTaskOutputDeductiblesAndOOPS annualBenefitCapExists
             * @property {google.protobuf.IDoubleValue|null} [annualBenefitCapMetAmount] INFPBMTaskOutputDeductiblesAndOOPS annualBenefitCapMetAmount
             * @property {google.protobuf.IBoolValue|null} [canUseCoPayAssistance] INFPBMTaskOutputDeductiblesAndOOPS canUseCoPayAssistance
             * @property {google.protobuf.IBoolValue|null} [copayAppliesToOop] INFPBMTaskOutputDeductiblesAndOOPS copayAppliesToOop
             * @property {google.protobuf.IBoolValue|null} [copayWaivedAfterOop] INFPBMTaskOutputDeductiblesAndOOPS copayWaivedAfterOop
             * @property {google.protobuf.IBoolValue|null} [deductibleIncludedInOop] INFPBMTaskOutputDeductiblesAndOOPS deductibleIncludedInOop
             * @property {google.protobuf.IBoolValue|null} [familyDeductibleExists] INFPBMTaskOutputDeductiblesAndOOPS familyDeductibleExists
             * @property {google.protobuf.IDoubleValue|null} [familyDeductibleMetAmount] INFPBMTaskOutputDeductiblesAndOOPS familyDeductibleMetAmount
             * @property {google.protobuf.IDoubleValue|null} [familyOopMaximum] INFPBMTaskOutputDeductiblesAndOOPS familyOopMaximum
             * @property {google.protobuf.IBoolValue|null} [familyOopMaximumExists] INFPBMTaskOutputDeductiblesAndOOPS familyOopMaximumExists
             * @property {google.protobuf.IDoubleValue|null} [familyOopMetAmount] INFPBMTaskOutputDeductiblesAndOOPS familyOopMetAmount
             * @property {google.protobuf.IDoubleValue|null} [familyTotalDeductible] INFPBMTaskOutputDeductiblesAndOOPS familyTotalDeductible
             * @property {google.protobuf.IBoolValue|null} [fullAmtCoPayAssistanceApplies] INFPBMTaskOutputDeductiblesAndOOPS fullAmtCoPayAssistanceApplies
             * @property {google.protobuf.IBoolValue|null} [individualDeductibleExists] INFPBMTaskOutputDeductiblesAndOOPS individualDeductibleExists
             * @property {google.protobuf.IDoubleValue|null} [individualDeductibleMetAmount] INFPBMTaskOutputDeductiblesAndOOPS individualDeductibleMetAmount
             * @property {google.protobuf.IDoubleValue|null} [individualOopMaximum] INFPBMTaskOutputDeductiblesAndOOPS individualOopMaximum
             * @property {google.protobuf.IBoolValue|null} [individualOopMaximumExists] INFPBMTaskOutputDeductiblesAndOOPS individualOopMaximumExists
             * @property {google.protobuf.IDoubleValue|null} [individualOopMetAmount] INFPBMTaskOutputDeductiblesAndOOPS individualOopMetAmount
             * @property {google.protobuf.IBoolValue|null} [individualOopMetUnavailable] INFPBMTaskOutputDeductiblesAndOOPS individualOopMetUnavailable
             * @property {google.protobuf.IDoubleValue|null} [individualTotalDeductible] INFPBMTaskOutputDeductiblesAndOOPS individualTotalDeductible
             * @property {google.protobuf.IDoubleValue|null} [initialCoverageLimitMetAmount] INFPBMTaskOutputDeductiblesAndOOPS initialCoverageLimitMetAmount
             * @property {google.protobuf.IDoubleValue|null} [initialCoverageLimitTotal] INFPBMTaskOutputDeductiblesAndOOPS initialCoverageLimitTotal
             * @property {google.protobuf.IBoolValue|null} [isAccumulatorPlan] INFPBMTaskOutputDeductiblesAndOOPS isAccumulatorPlan
             * @property {google.protobuf.IBoolValue|null} [isMaximizerPlan] INFPBMTaskOutputDeductiblesAndOOPS isMaximizerPlan
             * @property {google.protobuf.IBoolValue|null} [isOnLowIncomeSubsidy] INFPBMTaskOutputDeductiblesAndOOPS isOnLowIncomeSubsidy
             * @property {google.protobuf.IDoubleValue|null} [lifetimeMaximum] INFPBMTaskOutputDeductiblesAndOOPS lifetimeMaximum
             * @property {google.protobuf.IBoolValue|null} [lifetimeMaximumExists] INFPBMTaskOutputDeductiblesAndOOPS lifetimeMaximumExists
             * @property {google.protobuf.IDoubleValue|null} [lifetimeMaximumMetAmount] INFPBMTaskOutputDeductiblesAndOOPS lifetimeMaximumMetAmount
             * @property {infinitusapi.INFLowIncomeSubsidyLevel|null} [lowIncomeSubsidyLevel] INFPBMTaskOutputDeductiblesAndOOPS lowIncomeSubsidyLevel
             * @property {string|null} [maximizerOptInDate] INFPBMTaskOutputDeductiblesAndOOPS maximizerOptInDate
             * @property {string|null} [maximizerProgramName] INFPBMTaskOutputDeductiblesAndOOPS maximizerProgramName
             * @property {string|null} [maximizerProgramPhone] INFPBMTaskOutputDeductiblesAndOOPS maximizerProgramPhone
             * @property {infinitusapi.IINFMedicarePartD|null} [medicarePartD] INFPBMTaskOutputDeductiblesAndOOPS medicarePartD
             */
    
            /**
             * Constructs a new INFPBMTaskOutputDeductiblesAndOOPS.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMTaskOutputDeductiblesAndOOPS.
             * @implements IINFPBMTaskOutputDeductiblesAndOOPS
             * @constructor
             * @param {infinitusapi.IINFPBMTaskOutputDeductiblesAndOOPS=} [properties] Properties to set
             */
            function INFPBMTaskOutputDeductiblesAndOOPS(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS annualBenefitCap.
             * @member {google.protobuf.IDoubleValue|null|undefined} annualBenefitCap
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.annualBenefitCap = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS annualBenefitCapExists.
             * @member {google.protobuf.IBoolValue|null|undefined} annualBenefitCapExists
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.annualBenefitCapExists = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS annualBenefitCapMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} annualBenefitCapMetAmount
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.annualBenefitCapMetAmount = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS canUseCoPayAssistance.
             * @member {google.protobuf.IBoolValue|null|undefined} canUseCoPayAssistance
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.canUseCoPayAssistance = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS copayAppliesToOop.
             * @member {google.protobuf.IBoolValue|null|undefined} copayAppliesToOop
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.copayAppliesToOop = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS copayWaivedAfterOop.
             * @member {google.protobuf.IBoolValue|null|undefined} copayWaivedAfterOop
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.copayWaivedAfterOop = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS deductibleIncludedInOop.
             * @member {google.protobuf.IBoolValue|null|undefined} deductibleIncludedInOop
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.deductibleIncludedInOop = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS familyDeductibleExists.
             * @member {google.protobuf.IBoolValue|null|undefined} familyDeductibleExists
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.familyDeductibleExists = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS familyDeductibleMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} familyDeductibleMetAmount
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.familyDeductibleMetAmount = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS familyOopMaximum.
             * @member {google.protobuf.IDoubleValue|null|undefined} familyOopMaximum
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.familyOopMaximum = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS familyOopMaximumExists.
             * @member {google.protobuf.IBoolValue|null|undefined} familyOopMaximumExists
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.familyOopMaximumExists = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS familyOopMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} familyOopMetAmount
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.familyOopMetAmount = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS familyTotalDeductible.
             * @member {google.protobuf.IDoubleValue|null|undefined} familyTotalDeductible
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.familyTotalDeductible = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS fullAmtCoPayAssistanceApplies.
             * @member {google.protobuf.IBoolValue|null|undefined} fullAmtCoPayAssistanceApplies
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.fullAmtCoPayAssistanceApplies = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS individualDeductibleExists.
             * @member {google.protobuf.IBoolValue|null|undefined} individualDeductibleExists
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.individualDeductibleExists = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS individualDeductibleMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} individualDeductibleMetAmount
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.individualDeductibleMetAmount = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS individualOopMaximum.
             * @member {google.protobuf.IDoubleValue|null|undefined} individualOopMaximum
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.individualOopMaximum = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS individualOopMaximumExists.
             * @member {google.protobuf.IBoolValue|null|undefined} individualOopMaximumExists
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.individualOopMaximumExists = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS individualOopMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} individualOopMetAmount
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.individualOopMetAmount = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS individualOopMetUnavailable.
             * @member {google.protobuf.IBoolValue|null|undefined} individualOopMetUnavailable
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.individualOopMetUnavailable = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS individualTotalDeductible.
             * @member {google.protobuf.IDoubleValue|null|undefined} individualTotalDeductible
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.individualTotalDeductible = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS initialCoverageLimitMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} initialCoverageLimitMetAmount
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.initialCoverageLimitMetAmount = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS initialCoverageLimitTotal.
             * @member {google.protobuf.IDoubleValue|null|undefined} initialCoverageLimitTotal
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.initialCoverageLimitTotal = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS isAccumulatorPlan.
             * @member {google.protobuf.IBoolValue|null|undefined} isAccumulatorPlan
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.isAccumulatorPlan = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS isMaximizerPlan.
             * @member {google.protobuf.IBoolValue|null|undefined} isMaximizerPlan
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.isMaximizerPlan = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS isOnLowIncomeSubsidy.
             * @member {google.protobuf.IBoolValue|null|undefined} isOnLowIncomeSubsidy
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.isOnLowIncomeSubsidy = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS lifetimeMaximum.
             * @member {google.protobuf.IDoubleValue|null|undefined} lifetimeMaximum
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.lifetimeMaximum = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS lifetimeMaximumExists.
             * @member {google.protobuf.IBoolValue|null|undefined} lifetimeMaximumExists
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.lifetimeMaximumExists = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS lifetimeMaximumMetAmount.
             * @member {google.protobuf.IDoubleValue|null|undefined} lifetimeMaximumMetAmount
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.lifetimeMaximumMetAmount = null;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS lowIncomeSubsidyLevel.
             * @member {infinitusapi.INFLowIncomeSubsidyLevel} lowIncomeSubsidyLevel
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.lowIncomeSubsidyLevel = 0;
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS maximizerOptInDate.
             * @member {string} maximizerOptInDate
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.maximizerOptInDate = "";
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS maximizerProgramName.
             * @member {string} maximizerProgramName
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.maximizerProgramName = "";
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS maximizerProgramPhone.
             * @member {string} maximizerProgramPhone
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.maximizerProgramPhone = "";
    
            /**
             * INFPBMTaskOutputDeductiblesAndOOPS medicarePartD.
             * @member {infinitusapi.IINFMedicarePartD|null|undefined} medicarePartD
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.medicarePartD = null;
    
            /**
             * Creates a new INFPBMTaskOutputDeductiblesAndOOPS instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputDeductiblesAndOOPS=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS} INFPBMTaskOutputDeductiblesAndOOPS instance
             */
            INFPBMTaskOutputDeductiblesAndOOPS.create = function create(properties) {
                return new INFPBMTaskOutputDeductiblesAndOOPS(properties);
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputDeductiblesAndOOPS message. Does not implicitly {@link infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputDeductiblesAndOOPS} message INFPBMTaskOutputDeductiblesAndOOPS message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputDeductiblesAndOOPS.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.annualBenefitCap != null && Object.hasOwnProperty.call(message, "annualBenefitCap"))
                    $root.google.protobuf.DoubleValue.encode(message.annualBenefitCap, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.annualBenefitCapExists != null && Object.hasOwnProperty.call(message, "annualBenefitCapExists"))
                    $root.google.protobuf.BoolValue.encode(message.annualBenefitCapExists, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.annualBenefitCapMetAmount != null && Object.hasOwnProperty.call(message, "annualBenefitCapMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.annualBenefitCapMetAmount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.canUseCoPayAssistance != null && Object.hasOwnProperty.call(message, "canUseCoPayAssistance"))
                    $root.google.protobuf.BoolValue.encode(message.canUseCoPayAssistance, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.copayAppliesToOop != null && Object.hasOwnProperty.call(message, "copayAppliesToOop"))
                    $root.google.protobuf.BoolValue.encode(message.copayAppliesToOop, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.copayWaivedAfterOop != null && Object.hasOwnProperty.call(message, "copayWaivedAfterOop"))
                    $root.google.protobuf.BoolValue.encode(message.copayWaivedAfterOop, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.deductibleIncludedInOop != null && Object.hasOwnProperty.call(message, "deductibleIncludedInOop"))
                    $root.google.protobuf.BoolValue.encode(message.deductibleIncludedInOop, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.familyDeductibleExists != null && Object.hasOwnProperty.call(message, "familyDeductibleExists"))
                    $root.google.protobuf.BoolValue.encode(message.familyDeductibleExists, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.familyDeductibleMetAmount != null && Object.hasOwnProperty.call(message, "familyDeductibleMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.familyDeductibleMetAmount, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.familyOopMaximum != null && Object.hasOwnProperty.call(message, "familyOopMaximum"))
                    $root.google.protobuf.DoubleValue.encode(message.familyOopMaximum, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.familyOopMaximumExists != null && Object.hasOwnProperty.call(message, "familyOopMaximumExists"))
                    $root.google.protobuf.BoolValue.encode(message.familyOopMaximumExists, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.familyOopMetAmount != null && Object.hasOwnProperty.call(message, "familyOopMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.familyOopMetAmount, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.familyTotalDeductible != null && Object.hasOwnProperty.call(message, "familyTotalDeductible"))
                    $root.google.protobuf.DoubleValue.encode(message.familyTotalDeductible, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.fullAmtCoPayAssistanceApplies != null && Object.hasOwnProperty.call(message, "fullAmtCoPayAssistanceApplies"))
                    $root.google.protobuf.BoolValue.encode(message.fullAmtCoPayAssistanceApplies, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.individualDeductibleExists != null && Object.hasOwnProperty.call(message, "individualDeductibleExists"))
                    $root.google.protobuf.BoolValue.encode(message.individualDeductibleExists, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.individualDeductibleMetAmount != null && Object.hasOwnProperty.call(message, "individualDeductibleMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.individualDeductibleMetAmount, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.individualOopMaximum != null && Object.hasOwnProperty.call(message, "individualOopMaximum"))
                    $root.google.protobuf.DoubleValue.encode(message.individualOopMaximum, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.individualOopMaximumExists != null && Object.hasOwnProperty.call(message, "individualOopMaximumExists"))
                    $root.google.protobuf.BoolValue.encode(message.individualOopMaximumExists, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.individualOopMetAmount != null && Object.hasOwnProperty.call(message, "individualOopMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.individualOopMetAmount, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.individualOopMetUnavailable != null && Object.hasOwnProperty.call(message, "individualOopMetUnavailable"))
                    $root.google.protobuf.BoolValue.encode(message.individualOopMetUnavailable, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.individualTotalDeductible != null && Object.hasOwnProperty.call(message, "individualTotalDeductible"))
                    $root.google.protobuf.DoubleValue.encode(message.individualTotalDeductible, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.initialCoverageLimitMetAmount != null && Object.hasOwnProperty.call(message, "initialCoverageLimitMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.initialCoverageLimitMetAmount, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.initialCoverageLimitTotal != null && Object.hasOwnProperty.call(message, "initialCoverageLimitTotal"))
                    $root.google.protobuf.DoubleValue.encode(message.initialCoverageLimitTotal, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.isAccumulatorPlan != null && Object.hasOwnProperty.call(message, "isAccumulatorPlan"))
                    $root.google.protobuf.BoolValue.encode(message.isAccumulatorPlan, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.isMaximizerPlan != null && Object.hasOwnProperty.call(message, "isMaximizerPlan"))
                    $root.google.protobuf.BoolValue.encode(message.isMaximizerPlan, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.isOnLowIncomeSubsidy != null && Object.hasOwnProperty.call(message, "isOnLowIncomeSubsidy"))
                    $root.google.protobuf.BoolValue.encode(message.isOnLowIncomeSubsidy, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.lifetimeMaximum != null && Object.hasOwnProperty.call(message, "lifetimeMaximum"))
                    $root.google.protobuf.DoubleValue.encode(message.lifetimeMaximum, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.lifetimeMaximumExists != null && Object.hasOwnProperty.call(message, "lifetimeMaximumExists"))
                    $root.google.protobuf.BoolValue.encode(message.lifetimeMaximumExists, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.lifetimeMaximumMetAmount != null && Object.hasOwnProperty.call(message, "lifetimeMaximumMetAmount"))
                    $root.google.protobuf.DoubleValue.encode(message.lifetimeMaximumMetAmount, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                if (message.lowIncomeSubsidyLevel != null && Object.hasOwnProperty.call(message, "lowIncomeSubsidyLevel"))
                    writer.uint32(/* id 30, wireType 0 =*/240).int32(message.lowIncomeSubsidyLevel);
                if (message.maximizerOptInDate != null && Object.hasOwnProperty.call(message, "maximizerOptInDate"))
                    writer.uint32(/* id 31, wireType 2 =*/250).string(message.maximizerOptInDate);
                if (message.maximizerProgramName != null && Object.hasOwnProperty.call(message, "maximizerProgramName"))
                    writer.uint32(/* id 32, wireType 2 =*/258).string(message.maximizerProgramName);
                if (message.maximizerProgramPhone != null && Object.hasOwnProperty.call(message, "maximizerProgramPhone"))
                    writer.uint32(/* id 33, wireType 2 =*/266).string(message.maximizerProgramPhone);
                if (message.medicarePartD != null && Object.hasOwnProperty.call(message, "medicarePartD"))
                    $root.infinitusapi.INFMedicarePartD.encode(message.medicarePartD, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputDeductiblesAndOOPS message, length delimited. Does not implicitly {@link infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputDeductiblesAndOOPS} message INFPBMTaskOutputDeductiblesAndOOPS message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputDeductiblesAndOOPS.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMTaskOutputDeductiblesAndOOPS message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS} INFPBMTaskOutputDeductiblesAndOOPS
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputDeductiblesAndOOPS.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.annualBenefitCap = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.annualBenefitCapExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.annualBenefitCapMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.canUseCoPayAssistance = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.copayAppliesToOop = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.copayWaivedAfterOop = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.deductibleIncludedInOop = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.familyDeductibleExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.familyDeductibleMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.familyOopMaximum = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.familyOopMaximumExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.familyOopMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.familyTotalDeductible = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.fullAmtCoPayAssistanceApplies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.individualDeductibleExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.individualDeductibleMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.individualOopMaximum = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.individualOopMaximumExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 19: {
                            message.individualOopMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 20: {
                            message.individualOopMetUnavailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 21: {
                            message.individualTotalDeductible = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 22: {
                            message.initialCoverageLimitMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 23: {
                            message.initialCoverageLimitTotal = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 24: {
                            message.isAccumulatorPlan = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 25: {
                            message.isMaximizerPlan = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 26: {
                            message.isOnLowIncomeSubsidy = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 27: {
                            message.lifetimeMaximum = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 28: {
                            message.lifetimeMaximumExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 29: {
                            message.lifetimeMaximumMetAmount = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 30: {
                            message.lowIncomeSubsidyLevel = reader.int32();
                            break;
                        }
                    case 31: {
                            message.maximizerOptInDate = reader.string();
                            break;
                        }
                    case 32: {
                            message.maximizerProgramName = reader.string();
                            break;
                        }
                    case 33: {
                            message.maximizerProgramPhone = reader.string();
                            break;
                        }
                    case 34: {
                            message.medicarePartD = $root.infinitusapi.INFMedicarePartD.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMTaskOutputDeductiblesAndOOPS message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS} INFPBMTaskOutputDeductiblesAndOOPS
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputDeductiblesAndOOPS.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMTaskOutputDeductiblesAndOOPS message.
             * @function verify
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMTaskOutputDeductiblesAndOOPS.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annualBenefitCap != null && message.hasOwnProperty("annualBenefitCap")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.annualBenefitCap);
                    if (error)
                        return "annualBenefitCap." + error;
                }
                if (message.annualBenefitCapExists != null && message.hasOwnProperty("annualBenefitCapExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.annualBenefitCapExists);
                    if (error)
                        return "annualBenefitCapExists." + error;
                }
                if (message.annualBenefitCapMetAmount != null && message.hasOwnProperty("annualBenefitCapMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.annualBenefitCapMetAmount);
                    if (error)
                        return "annualBenefitCapMetAmount." + error;
                }
                if (message.canUseCoPayAssistance != null && message.hasOwnProperty("canUseCoPayAssistance")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.canUseCoPayAssistance);
                    if (error)
                        return "canUseCoPayAssistance." + error;
                }
                if (message.copayAppliesToOop != null && message.hasOwnProperty("copayAppliesToOop")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.copayAppliesToOop);
                    if (error)
                        return "copayAppliesToOop." + error;
                }
                if (message.copayWaivedAfterOop != null && message.hasOwnProperty("copayWaivedAfterOop")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.copayWaivedAfterOop);
                    if (error)
                        return "copayWaivedAfterOop." + error;
                }
                if (message.deductibleIncludedInOop != null && message.hasOwnProperty("deductibleIncludedInOop")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.deductibleIncludedInOop);
                    if (error)
                        return "deductibleIncludedInOop." + error;
                }
                if (message.familyDeductibleExists != null && message.hasOwnProperty("familyDeductibleExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.familyDeductibleExists);
                    if (error)
                        return "familyDeductibleExists." + error;
                }
                if (message.familyDeductibleMetAmount != null && message.hasOwnProperty("familyDeductibleMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.familyDeductibleMetAmount);
                    if (error)
                        return "familyDeductibleMetAmount." + error;
                }
                if (message.familyOopMaximum != null && message.hasOwnProperty("familyOopMaximum")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.familyOopMaximum);
                    if (error)
                        return "familyOopMaximum." + error;
                }
                if (message.familyOopMaximumExists != null && message.hasOwnProperty("familyOopMaximumExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.familyOopMaximumExists);
                    if (error)
                        return "familyOopMaximumExists." + error;
                }
                if (message.familyOopMetAmount != null && message.hasOwnProperty("familyOopMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.familyOopMetAmount);
                    if (error)
                        return "familyOopMetAmount." + error;
                }
                if (message.familyTotalDeductible != null && message.hasOwnProperty("familyTotalDeductible")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.familyTotalDeductible);
                    if (error)
                        return "familyTotalDeductible." + error;
                }
                if (message.fullAmtCoPayAssistanceApplies != null && message.hasOwnProperty("fullAmtCoPayAssistanceApplies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.fullAmtCoPayAssistanceApplies);
                    if (error)
                        return "fullAmtCoPayAssistanceApplies." + error;
                }
                if (message.individualDeductibleExists != null && message.hasOwnProperty("individualDeductibleExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.individualDeductibleExists);
                    if (error)
                        return "individualDeductibleExists." + error;
                }
                if (message.individualDeductibleMetAmount != null && message.hasOwnProperty("individualDeductibleMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.individualDeductibleMetAmount);
                    if (error)
                        return "individualDeductibleMetAmount." + error;
                }
                if (message.individualOopMaximum != null && message.hasOwnProperty("individualOopMaximum")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.individualOopMaximum);
                    if (error)
                        return "individualOopMaximum." + error;
                }
                if (message.individualOopMaximumExists != null && message.hasOwnProperty("individualOopMaximumExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.individualOopMaximumExists);
                    if (error)
                        return "individualOopMaximumExists." + error;
                }
                if (message.individualOopMetAmount != null && message.hasOwnProperty("individualOopMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.individualOopMetAmount);
                    if (error)
                        return "individualOopMetAmount." + error;
                }
                if (message.individualOopMetUnavailable != null && message.hasOwnProperty("individualOopMetUnavailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.individualOopMetUnavailable);
                    if (error)
                        return "individualOopMetUnavailable." + error;
                }
                if (message.individualTotalDeductible != null && message.hasOwnProperty("individualTotalDeductible")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.individualTotalDeductible);
                    if (error)
                        return "individualTotalDeductible." + error;
                }
                if (message.initialCoverageLimitMetAmount != null && message.hasOwnProperty("initialCoverageLimitMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.initialCoverageLimitMetAmount);
                    if (error)
                        return "initialCoverageLimitMetAmount." + error;
                }
                if (message.initialCoverageLimitTotal != null && message.hasOwnProperty("initialCoverageLimitTotal")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.initialCoverageLimitTotal);
                    if (error)
                        return "initialCoverageLimitTotal." + error;
                }
                if (message.isAccumulatorPlan != null && message.hasOwnProperty("isAccumulatorPlan")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isAccumulatorPlan);
                    if (error)
                        return "isAccumulatorPlan." + error;
                }
                if (message.isMaximizerPlan != null && message.hasOwnProperty("isMaximizerPlan")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isMaximizerPlan);
                    if (error)
                        return "isMaximizerPlan." + error;
                }
                if (message.isOnLowIncomeSubsidy != null && message.hasOwnProperty("isOnLowIncomeSubsidy")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isOnLowIncomeSubsidy);
                    if (error)
                        return "isOnLowIncomeSubsidy." + error;
                }
                if (message.lifetimeMaximum != null && message.hasOwnProperty("lifetimeMaximum")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.lifetimeMaximum);
                    if (error)
                        return "lifetimeMaximum." + error;
                }
                if (message.lifetimeMaximumExists != null && message.hasOwnProperty("lifetimeMaximumExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.lifetimeMaximumExists);
                    if (error)
                        return "lifetimeMaximumExists." + error;
                }
                if (message.lifetimeMaximumMetAmount != null && message.hasOwnProperty("lifetimeMaximumMetAmount")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.lifetimeMaximumMetAmount);
                    if (error)
                        return "lifetimeMaximumMetAmount." + error;
                }
                if (message.lowIncomeSubsidyLevel != null && message.hasOwnProperty("lowIncomeSubsidyLevel"))
                    switch (message.lowIncomeSubsidyLevel) {
                    default:
                        return "lowIncomeSubsidyLevel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.maximizerOptInDate != null && message.hasOwnProperty("maximizerOptInDate"))
                    if (!$util.isString(message.maximizerOptInDate))
                        return "maximizerOptInDate: string expected";
                if (message.maximizerProgramName != null && message.hasOwnProperty("maximizerProgramName"))
                    if (!$util.isString(message.maximizerProgramName))
                        return "maximizerProgramName: string expected";
                if (message.maximizerProgramPhone != null && message.hasOwnProperty("maximizerProgramPhone"))
                    if (!$util.isString(message.maximizerProgramPhone))
                        return "maximizerProgramPhone: string expected";
                if (message.medicarePartD != null && message.hasOwnProperty("medicarePartD")) {
                    var error = $root.infinitusapi.INFMedicarePartD.verify(message.medicarePartD);
                    if (error)
                        return "medicarePartD." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPBMTaskOutputDeductiblesAndOOPS message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS} INFPBMTaskOutputDeductiblesAndOOPS
             */
            INFPBMTaskOutputDeductiblesAndOOPS.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS)
                    return object;
                var message = new $root.infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS();
                if (object.annualBenefitCap != null) {
                    if (typeof object.annualBenefitCap !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.annualBenefitCap: object expected");
                    message.annualBenefitCap = $root.google.protobuf.DoubleValue.fromObject(object.annualBenefitCap);
                }
                if (object.annualBenefitCapExists != null) {
                    if (typeof object.annualBenefitCapExists !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.annualBenefitCapExists: object expected");
                    message.annualBenefitCapExists = $root.google.protobuf.BoolValue.fromObject(object.annualBenefitCapExists);
                }
                if (object.annualBenefitCapMetAmount != null) {
                    if (typeof object.annualBenefitCapMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.annualBenefitCapMetAmount: object expected");
                    message.annualBenefitCapMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.annualBenefitCapMetAmount);
                }
                if (object.canUseCoPayAssistance != null) {
                    if (typeof object.canUseCoPayAssistance !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.canUseCoPayAssistance: object expected");
                    message.canUseCoPayAssistance = $root.google.protobuf.BoolValue.fromObject(object.canUseCoPayAssistance);
                }
                if (object.copayAppliesToOop != null) {
                    if (typeof object.copayAppliesToOop !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.copayAppliesToOop: object expected");
                    message.copayAppliesToOop = $root.google.protobuf.BoolValue.fromObject(object.copayAppliesToOop);
                }
                if (object.copayWaivedAfterOop != null) {
                    if (typeof object.copayWaivedAfterOop !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.copayWaivedAfterOop: object expected");
                    message.copayWaivedAfterOop = $root.google.protobuf.BoolValue.fromObject(object.copayWaivedAfterOop);
                }
                if (object.deductibleIncludedInOop != null) {
                    if (typeof object.deductibleIncludedInOop !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.deductibleIncludedInOop: object expected");
                    message.deductibleIncludedInOop = $root.google.protobuf.BoolValue.fromObject(object.deductibleIncludedInOop);
                }
                if (object.familyDeductibleExists != null) {
                    if (typeof object.familyDeductibleExists !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.familyDeductibleExists: object expected");
                    message.familyDeductibleExists = $root.google.protobuf.BoolValue.fromObject(object.familyDeductibleExists);
                }
                if (object.familyDeductibleMetAmount != null) {
                    if (typeof object.familyDeductibleMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.familyDeductibleMetAmount: object expected");
                    message.familyDeductibleMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.familyDeductibleMetAmount);
                }
                if (object.familyOopMaximum != null) {
                    if (typeof object.familyOopMaximum !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.familyOopMaximum: object expected");
                    message.familyOopMaximum = $root.google.protobuf.DoubleValue.fromObject(object.familyOopMaximum);
                }
                if (object.familyOopMaximumExists != null) {
                    if (typeof object.familyOopMaximumExists !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.familyOopMaximumExists: object expected");
                    message.familyOopMaximumExists = $root.google.protobuf.BoolValue.fromObject(object.familyOopMaximumExists);
                }
                if (object.familyOopMetAmount != null) {
                    if (typeof object.familyOopMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.familyOopMetAmount: object expected");
                    message.familyOopMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.familyOopMetAmount);
                }
                if (object.familyTotalDeductible != null) {
                    if (typeof object.familyTotalDeductible !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.familyTotalDeductible: object expected");
                    message.familyTotalDeductible = $root.google.protobuf.DoubleValue.fromObject(object.familyTotalDeductible);
                }
                if (object.fullAmtCoPayAssistanceApplies != null) {
                    if (typeof object.fullAmtCoPayAssistanceApplies !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.fullAmtCoPayAssistanceApplies: object expected");
                    message.fullAmtCoPayAssistanceApplies = $root.google.protobuf.BoolValue.fromObject(object.fullAmtCoPayAssistanceApplies);
                }
                if (object.individualDeductibleExists != null) {
                    if (typeof object.individualDeductibleExists !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.individualDeductibleExists: object expected");
                    message.individualDeductibleExists = $root.google.protobuf.BoolValue.fromObject(object.individualDeductibleExists);
                }
                if (object.individualDeductibleMetAmount != null) {
                    if (typeof object.individualDeductibleMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.individualDeductibleMetAmount: object expected");
                    message.individualDeductibleMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.individualDeductibleMetAmount);
                }
                if (object.individualOopMaximum != null) {
                    if (typeof object.individualOopMaximum !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.individualOopMaximum: object expected");
                    message.individualOopMaximum = $root.google.protobuf.DoubleValue.fromObject(object.individualOopMaximum);
                }
                if (object.individualOopMaximumExists != null) {
                    if (typeof object.individualOopMaximumExists !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.individualOopMaximumExists: object expected");
                    message.individualOopMaximumExists = $root.google.protobuf.BoolValue.fromObject(object.individualOopMaximumExists);
                }
                if (object.individualOopMetAmount != null) {
                    if (typeof object.individualOopMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.individualOopMetAmount: object expected");
                    message.individualOopMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.individualOopMetAmount);
                }
                if (object.individualOopMetUnavailable != null) {
                    if (typeof object.individualOopMetUnavailable !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.individualOopMetUnavailable: object expected");
                    message.individualOopMetUnavailable = $root.google.protobuf.BoolValue.fromObject(object.individualOopMetUnavailable);
                }
                if (object.individualTotalDeductible != null) {
                    if (typeof object.individualTotalDeductible !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.individualTotalDeductible: object expected");
                    message.individualTotalDeductible = $root.google.protobuf.DoubleValue.fromObject(object.individualTotalDeductible);
                }
                if (object.initialCoverageLimitMetAmount != null) {
                    if (typeof object.initialCoverageLimitMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.initialCoverageLimitMetAmount: object expected");
                    message.initialCoverageLimitMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.initialCoverageLimitMetAmount);
                }
                if (object.initialCoverageLimitTotal != null) {
                    if (typeof object.initialCoverageLimitTotal !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.initialCoverageLimitTotal: object expected");
                    message.initialCoverageLimitTotal = $root.google.protobuf.DoubleValue.fromObject(object.initialCoverageLimitTotal);
                }
                if (object.isAccumulatorPlan != null) {
                    if (typeof object.isAccumulatorPlan !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.isAccumulatorPlan: object expected");
                    message.isAccumulatorPlan = $root.google.protobuf.BoolValue.fromObject(object.isAccumulatorPlan);
                }
                if (object.isMaximizerPlan != null) {
                    if (typeof object.isMaximizerPlan !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.isMaximizerPlan: object expected");
                    message.isMaximizerPlan = $root.google.protobuf.BoolValue.fromObject(object.isMaximizerPlan);
                }
                if (object.isOnLowIncomeSubsidy != null) {
                    if (typeof object.isOnLowIncomeSubsidy !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.isOnLowIncomeSubsidy: object expected");
                    message.isOnLowIncomeSubsidy = $root.google.protobuf.BoolValue.fromObject(object.isOnLowIncomeSubsidy);
                }
                if (object.lifetimeMaximum != null) {
                    if (typeof object.lifetimeMaximum !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.lifetimeMaximum: object expected");
                    message.lifetimeMaximum = $root.google.protobuf.DoubleValue.fromObject(object.lifetimeMaximum);
                }
                if (object.lifetimeMaximumExists != null) {
                    if (typeof object.lifetimeMaximumExists !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.lifetimeMaximumExists: object expected");
                    message.lifetimeMaximumExists = $root.google.protobuf.BoolValue.fromObject(object.lifetimeMaximumExists);
                }
                if (object.lifetimeMaximumMetAmount != null) {
                    if (typeof object.lifetimeMaximumMetAmount !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.lifetimeMaximumMetAmount: object expected");
                    message.lifetimeMaximumMetAmount = $root.google.protobuf.DoubleValue.fromObject(object.lifetimeMaximumMetAmount);
                }
                switch (object.lowIncomeSubsidyLevel) {
                default:
                    if (typeof object.lowIncomeSubsidyLevel === "number") {
                        message.lowIncomeSubsidyLevel = object.lowIncomeSubsidyLevel;
                        break;
                    }
                    break;
                case "INF_LOW_INCOME_SUBSIDY_LEVEL_UNKNOWN":
                case 0:
                    message.lowIncomeSubsidyLevel = 0;
                    break;
                case "INF_LOW_INCOME_SUBSIDY_LEVEL_ONE":
                case 1:
                    message.lowIncomeSubsidyLevel = 1;
                    break;
                case "INF_LOW_INCOME_SUBSIDY_LEVEL_TWO":
                case 2:
                    message.lowIncomeSubsidyLevel = 2;
                    break;
                case "INF_LOW_INCOME_SUBSIDY_LEVEL_THREE":
                case 3:
                    message.lowIncomeSubsidyLevel = 3;
                    break;
                case "INF_LOW_INCOME_SUBSIDY_LEVEL_FOUR":
                case 4:
                    message.lowIncomeSubsidyLevel = 4;
                    break;
                case "INF_LOW_INCOME_SUBSIDY_LEVEL_NOT_APPLICABLE":
                case 5:
                    message.lowIncomeSubsidyLevel = 5;
                    break;
                }
                if (object.maximizerOptInDate != null)
                    message.maximizerOptInDate = String(object.maximizerOptInDate);
                if (object.maximizerProgramName != null)
                    message.maximizerProgramName = String(object.maximizerProgramName);
                if (object.maximizerProgramPhone != null)
                    message.maximizerProgramPhone = String(object.maximizerProgramPhone);
                if (object.medicarePartD != null) {
                    if (typeof object.medicarePartD !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS.medicarePartD: object expected");
                    message.medicarePartD = $root.infinitusapi.INFMedicarePartD.fromObject(object.medicarePartD);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMTaskOutputDeductiblesAndOOPS message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS} message INFPBMTaskOutputDeductiblesAndOOPS
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMTaskOutputDeductiblesAndOOPS.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.annualBenefitCap = null;
                    object.annualBenefitCapExists = null;
                    object.annualBenefitCapMetAmount = null;
                    object.canUseCoPayAssistance = null;
                    object.copayAppliesToOop = null;
                    object.copayWaivedAfterOop = null;
                    object.deductibleIncludedInOop = null;
                    object.familyDeductibleExists = null;
                    object.familyDeductibleMetAmount = null;
                    object.familyOopMaximum = null;
                    object.familyOopMaximumExists = null;
                    object.familyOopMetAmount = null;
                    object.familyTotalDeductible = null;
                    object.fullAmtCoPayAssistanceApplies = null;
                    object.individualDeductibleExists = null;
                    object.individualDeductibleMetAmount = null;
                    object.individualOopMaximum = null;
                    object.individualOopMaximumExists = null;
                    object.individualOopMetAmount = null;
                    object.individualOopMetUnavailable = null;
                    object.individualTotalDeductible = null;
                    object.initialCoverageLimitMetAmount = null;
                    object.initialCoverageLimitTotal = null;
                    object.isAccumulatorPlan = null;
                    object.isMaximizerPlan = null;
                    object.isOnLowIncomeSubsidy = null;
                    object.lifetimeMaximum = null;
                    object.lifetimeMaximumExists = null;
                    object.lifetimeMaximumMetAmount = null;
                    object.lowIncomeSubsidyLevel = options.enums === String ? "INF_LOW_INCOME_SUBSIDY_LEVEL_UNKNOWN" : 0;
                    object.maximizerOptInDate = "";
                    object.maximizerProgramName = "";
                    object.maximizerProgramPhone = "";
                    object.medicarePartD = null;
                }
                if (message.annualBenefitCap != null && message.hasOwnProperty("annualBenefitCap"))
                    object.annualBenefitCap = $root.google.protobuf.DoubleValue.toObject(message.annualBenefitCap, options);
                if (message.annualBenefitCapExists != null && message.hasOwnProperty("annualBenefitCapExists"))
                    object.annualBenefitCapExists = $root.google.protobuf.BoolValue.toObject(message.annualBenefitCapExists, options);
                if (message.annualBenefitCapMetAmount != null && message.hasOwnProperty("annualBenefitCapMetAmount"))
                    object.annualBenefitCapMetAmount = $root.google.protobuf.DoubleValue.toObject(message.annualBenefitCapMetAmount, options);
                if (message.canUseCoPayAssistance != null && message.hasOwnProperty("canUseCoPayAssistance"))
                    object.canUseCoPayAssistance = $root.google.protobuf.BoolValue.toObject(message.canUseCoPayAssistance, options);
                if (message.copayAppliesToOop != null && message.hasOwnProperty("copayAppliesToOop"))
                    object.copayAppliesToOop = $root.google.protobuf.BoolValue.toObject(message.copayAppliesToOop, options);
                if (message.copayWaivedAfterOop != null && message.hasOwnProperty("copayWaivedAfterOop"))
                    object.copayWaivedAfterOop = $root.google.protobuf.BoolValue.toObject(message.copayWaivedAfterOop, options);
                if (message.deductibleIncludedInOop != null && message.hasOwnProperty("deductibleIncludedInOop"))
                    object.deductibleIncludedInOop = $root.google.protobuf.BoolValue.toObject(message.deductibleIncludedInOop, options);
                if (message.familyDeductibleExists != null && message.hasOwnProperty("familyDeductibleExists"))
                    object.familyDeductibleExists = $root.google.protobuf.BoolValue.toObject(message.familyDeductibleExists, options);
                if (message.familyDeductibleMetAmount != null && message.hasOwnProperty("familyDeductibleMetAmount"))
                    object.familyDeductibleMetAmount = $root.google.protobuf.DoubleValue.toObject(message.familyDeductibleMetAmount, options);
                if (message.familyOopMaximum != null && message.hasOwnProperty("familyOopMaximum"))
                    object.familyOopMaximum = $root.google.protobuf.DoubleValue.toObject(message.familyOopMaximum, options);
                if (message.familyOopMaximumExists != null && message.hasOwnProperty("familyOopMaximumExists"))
                    object.familyOopMaximumExists = $root.google.protobuf.BoolValue.toObject(message.familyOopMaximumExists, options);
                if (message.familyOopMetAmount != null && message.hasOwnProperty("familyOopMetAmount"))
                    object.familyOopMetAmount = $root.google.protobuf.DoubleValue.toObject(message.familyOopMetAmount, options);
                if (message.familyTotalDeductible != null && message.hasOwnProperty("familyTotalDeductible"))
                    object.familyTotalDeductible = $root.google.protobuf.DoubleValue.toObject(message.familyTotalDeductible, options);
                if (message.fullAmtCoPayAssistanceApplies != null && message.hasOwnProperty("fullAmtCoPayAssistanceApplies"))
                    object.fullAmtCoPayAssistanceApplies = $root.google.protobuf.BoolValue.toObject(message.fullAmtCoPayAssistanceApplies, options);
                if (message.individualDeductibleExists != null && message.hasOwnProperty("individualDeductibleExists"))
                    object.individualDeductibleExists = $root.google.protobuf.BoolValue.toObject(message.individualDeductibleExists, options);
                if (message.individualDeductibleMetAmount != null && message.hasOwnProperty("individualDeductibleMetAmount"))
                    object.individualDeductibleMetAmount = $root.google.protobuf.DoubleValue.toObject(message.individualDeductibleMetAmount, options);
                if (message.individualOopMaximum != null && message.hasOwnProperty("individualOopMaximum"))
                    object.individualOopMaximum = $root.google.protobuf.DoubleValue.toObject(message.individualOopMaximum, options);
                if (message.individualOopMaximumExists != null && message.hasOwnProperty("individualOopMaximumExists"))
                    object.individualOopMaximumExists = $root.google.protobuf.BoolValue.toObject(message.individualOopMaximumExists, options);
                if (message.individualOopMetAmount != null && message.hasOwnProperty("individualOopMetAmount"))
                    object.individualOopMetAmount = $root.google.protobuf.DoubleValue.toObject(message.individualOopMetAmount, options);
                if (message.individualOopMetUnavailable != null && message.hasOwnProperty("individualOopMetUnavailable"))
                    object.individualOopMetUnavailable = $root.google.protobuf.BoolValue.toObject(message.individualOopMetUnavailable, options);
                if (message.individualTotalDeductible != null && message.hasOwnProperty("individualTotalDeductible"))
                    object.individualTotalDeductible = $root.google.protobuf.DoubleValue.toObject(message.individualTotalDeductible, options);
                if (message.initialCoverageLimitMetAmount != null && message.hasOwnProperty("initialCoverageLimitMetAmount"))
                    object.initialCoverageLimitMetAmount = $root.google.protobuf.DoubleValue.toObject(message.initialCoverageLimitMetAmount, options);
                if (message.initialCoverageLimitTotal != null && message.hasOwnProperty("initialCoverageLimitTotal"))
                    object.initialCoverageLimitTotal = $root.google.protobuf.DoubleValue.toObject(message.initialCoverageLimitTotal, options);
                if (message.isAccumulatorPlan != null && message.hasOwnProperty("isAccumulatorPlan"))
                    object.isAccumulatorPlan = $root.google.protobuf.BoolValue.toObject(message.isAccumulatorPlan, options);
                if (message.isMaximizerPlan != null && message.hasOwnProperty("isMaximizerPlan"))
                    object.isMaximizerPlan = $root.google.protobuf.BoolValue.toObject(message.isMaximizerPlan, options);
                if (message.isOnLowIncomeSubsidy != null && message.hasOwnProperty("isOnLowIncomeSubsidy"))
                    object.isOnLowIncomeSubsidy = $root.google.protobuf.BoolValue.toObject(message.isOnLowIncomeSubsidy, options);
                if (message.lifetimeMaximum != null && message.hasOwnProperty("lifetimeMaximum"))
                    object.lifetimeMaximum = $root.google.protobuf.DoubleValue.toObject(message.lifetimeMaximum, options);
                if (message.lifetimeMaximumExists != null && message.hasOwnProperty("lifetimeMaximumExists"))
                    object.lifetimeMaximumExists = $root.google.protobuf.BoolValue.toObject(message.lifetimeMaximumExists, options);
                if (message.lifetimeMaximumMetAmount != null && message.hasOwnProperty("lifetimeMaximumMetAmount"))
                    object.lifetimeMaximumMetAmount = $root.google.protobuf.DoubleValue.toObject(message.lifetimeMaximumMetAmount, options);
                if (message.lowIncomeSubsidyLevel != null && message.hasOwnProperty("lowIncomeSubsidyLevel"))
                    object.lowIncomeSubsidyLevel = options.enums === String ? $root.infinitusapi.INFLowIncomeSubsidyLevel[message.lowIncomeSubsidyLevel] === undefined ? message.lowIncomeSubsidyLevel : $root.infinitusapi.INFLowIncomeSubsidyLevel[message.lowIncomeSubsidyLevel] : message.lowIncomeSubsidyLevel;
                if (message.maximizerOptInDate != null && message.hasOwnProperty("maximizerOptInDate"))
                    object.maximizerOptInDate = message.maximizerOptInDate;
                if (message.maximizerProgramName != null && message.hasOwnProperty("maximizerProgramName"))
                    object.maximizerProgramName = message.maximizerProgramName;
                if (message.maximizerProgramPhone != null && message.hasOwnProperty("maximizerProgramPhone"))
                    object.maximizerProgramPhone = message.maximizerProgramPhone;
                if (message.medicarePartD != null && message.hasOwnProperty("medicarePartD"))
                    object.medicarePartD = $root.infinitusapi.INFMedicarePartD.toObject(message.medicarePartD, options);
                return object;
            };
    
            /**
             * Converts this INFPBMTaskOutputDeductiblesAndOOPS to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMTaskOutputDeductiblesAndOOPS.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMTaskOutputDeductiblesAndOOPS
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMTaskOutputDeductiblesAndOOPS.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMTaskOutputDeductiblesAndOOPS";
            };
    
            return INFPBMTaskOutputDeductiblesAndOOPS;
        })();
    
        infinitusapi.INFPBMTaskOutputErrorInfo = (function() {
    
            /**
             * Properties of a INFPBMTaskOutputErrorInfo.
             * @memberof infinitusapi
             * @interface IINFPBMTaskOutputErrorInfo
             * @property {infinitusapi.INFFailureReason|null} [failureReason] INFPBMTaskOutputErrorInfo failureReason
             */
    
            /**
             * Constructs a new INFPBMTaskOutputErrorInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMTaskOutputErrorInfo.
             * @implements IINFPBMTaskOutputErrorInfo
             * @constructor
             * @param {infinitusapi.IINFPBMTaskOutputErrorInfo=} [properties] Properties to set
             */
            function INFPBMTaskOutputErrorInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMTaskOutputErrorInfo failureReason.
             * @member {infinitusapi.INFFailureReason} failureReason
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @instance
             */
            INFPBMTaskOutputErrorInfo.prototype.failureReason = 0;
    
            /**
             * Creates a new INFPBMTaskOutputErrorInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputErrorInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMTaskOutputErrorInfo} INFPBMTaskOutputErrorInfo instance
             */
            INFPBMTaskOutputErrorInfo.create = function create(properties) {
                return new INFPBMTaskOutputErrorInfo(properties);
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputErrorInfo message. Does not implicitly {@link infinitusapi.INFPBMTaskOutputErrorInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputErrorInfo} message INFPBMTaskOutputErrorInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputErrorInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.failureReason);
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputErrorInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPBMTaskOutputErrorInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputErrorInfo} message INFPBMTaskOutputErrorInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputErrorInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMTaskOutputErrorInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMTaskOutputErrorInfo} INFPBMTaskOutputErrorInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputErrorInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMTaskOutputErrorInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.failureReason = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMTaskOutputErrorInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMTaskOutputErrorInfo} INFPBMTaskOutputErrorInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputErrorInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMTaskOutputErrorInfo message.
             * @function verify
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMTaskOutputErrorInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    switch (message.failureReason) {
                    default:
                        return "failureReason: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a INFPBMTaskOutputErrorInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMTaskOutputErrorInfo} INFPBMTaskOutputErrorInfo
             */
            INFPBMTaskOutputErrorInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMTaskOutputErrorInfo)
                    return object;
                var message = new $root.infinitusapi.INFPBMTaskOutputErrorInfo();
                switch (object.failureReason) {
                default:
                    if (typeof object.failureReason === "number") {
                        message.failureReason = object.failureReason;
                        break;
                    }
                    break;
                case "INF_FAILURE_REASON_UNKNOWN":
                case 0:
                    message.failureReason = 0;
                    break;
                case "INF_FAILURE_REASON_BAD_DATA":
                case 1:
                    message.failureReason = 1;
                    break;
                case "INF_FAILURE_REASON_PAYER_UNREACHABLE":
                case 2:
                    message.failureReason = 2;
                    break;
                case "INF_FAILURE_REASON_PAYER_REFUSED_THIRD_PARTY":
                case 3:
                    message.failureReason = 3;
                    break;
                case "INF_FAILURE_REASON_CANCELLED_BY_CUSTOMER":
                case 4:
                    message.failureReason = 4;
                    break;
                case "INF_FAILURE_REASON_TASK_EXPIRED":
                case 5:
                    message.failureReason = 5;
                    break;
                case "INF_FAILURE_REASON_OTHER":
                case 6:
                    message.failureReason = 6;
                    break;
                case "INF_FAILURE_REASON_PRESCRIBER_UNREACHABLE":
                case 7:
                    message.failureReason = 7;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMTaskOutputErrorInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.INFPBMTaskOutputErrorInfo} message INFPBMTaskOutputErrorInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMTaskOutputErrorInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.failureReason = options.enums === String ? "INF_FAILURE_REASON_UNKNOWN" : 0;
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    object.failureReason = options.enums === String ? $root.infinitusapi.INFFailureReason[message.failureReason] === undefined ? message.failureReason : $root.infinitusapi.INFFailureReason[message.failureReason] : message.failureReason;
                return object;
            };
    
            /**
             * Converts this INFPBMTaskOutputErrorInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMTaskOutputErrorInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMTaskOutputErrorInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMTaskOutputErrorInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMTaskOutputErrorInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMTaskOutputErrorInfo";
            };
    
            return INFPBMTaskOutputErrorInfo;
        })();
    
        infinitusapi.INFPBMTaskOutputPlanInfo = (function() {
    
            /**
             * Properties of a INFPBMTaskOutputPlanInfo.
             * @memberof infinitusapi
             * @interface IINFPBMTaskOutputPlanInfo
             * @property {string|null} [RxGroupNumber] INFPBMTaskOutputPlanInfo RxGroupNumber
             * @property {string|null} [bankIdentificationNumber] INFPBMTaskOutputPlanInfo bankIdentificationNumber
             * @property {string|null} [cobraGracePeriod] INFPBMTaskOutputPlanInfo cobraGracePeriod
             * @property {google.protobuf.IBoolValue|null} [cobraHasGracePeriod] INFPBMTaskOutputPlanInfo cobraHasGracePeriod
             * @property {string|null} [cobraPaidThroughDate] INFPBMTaskOutputPlanInfo cobraPaidThroughDate
             * @property {infinitusapi.INFCoordinationOfBenefits|null} [coordinationOfBenefits] INFPBMTaskOutputPlanInfo coordinationOfBenefits
             * @property {infinitusapi.INFCurrentPlanPriority|null} [currentPlanPriority] INFPBMTaskOutputPlanInfo currentPlanPriority
             * @property {infinitusapi.INFFundingType|null} [fundingType] INFPBMTaskOutputPlanInfo fundingType
             * @property {string|null} [healthExchangeGracePeriod] INFPBMTaskOutputPlanInfo healthExchangeGracePeriod
             * @property {google.protobuf.IBoolValue|null} [healthExchangeHasGracePeriod] INFPBMTaskOutputPlanInfo healthExchangeHasGracePeriod
             * @property {string|null} [healthExchangePaidThroughDate] INFPBMTaskOutputPlanInfo healthExchangePaidThroughDate
             * @property {google.protobuf.IBoolValue|null} [isCobraPlan] INFPBMTaskOutputPlanInfo isCobraPlan
             * @property {google.protobuf.IBoolValue|null} [isHealthExchangePlan] INFPBMTaskOutputPlanInfo isHealthExchangePlan
             * @property {google.protobuf.IBoolValue|null} [newPlanAvailable] INFPBMTaskOutputPlanInfo newPlanAvailable
             * @property {string|null} [newPlanEffectiveDate] INFPBMTaskOutputPlanInfo newPlanEffectiveDate
             * @property {string|null} [newPlanSubscriberId] INFPBMTaskOutputPlanInfo newPlanSubscriberId
             * @property {google.protobuf.IBoolValue|null} [otherInsuranceExists] INFPBMTaskOutputPlanInfo otherInsuranceExists
             * @property {string|null} [planEffectiveDate] INFPBMTaskOutputPlanInfo planEffectiveDate
             * @property {string|null} [planFax] INFPBMTaskOutputPlanInfo planFax
             * @property {string|null} [planGroupName] INFPBMTaskOutputPlanInfo planGroupName
             * @property {string|null} [planGroupNumber] INFPBMTaskOutputPlanInfo planGroupNumber
             * @property {string|null} [planName] INFPBMTaskOutputPlanInfo planName
             * @property {infinitusapi.INFPlanRenewalMonth|null} [planRenewalMonth] INFPBMTaskOutputPlanInfo planRenewalMonth
             * @property {string|null} [planTerminationDate] INFPBMTaskOutputPlanInfo planTerminationDate
             * @property {infinitusapi.INFPlanType|null} [planType] INFPBMTaskOutputPlanInfo planType
             * @property {string|null} [policyHolderDateOfBirth] INFPBMTaskOutputPlanInfo policyHolderDateOfBirth
             * @property {string|null} [policyHolderFirstName] INFPBMTaskOutputPlanInfo policyHolderFirstName
             * @property {string|null} [policyHolderLastName] INFPBMTaskOutputPlanInfo policyHolderLastName
             * @property {infinitusapi.INFPolicyHolderRelationship|null} [policyHolderRelationship] INFPBMTaskOutputPlanInfo policyHolderRelationship
             * @property {string|null} [policyNumber] INFPBMTaskOutputPlanInfo policyNumber
             * @property {infinitusapi.INFPolicyType|null} [policyType] INFPBMTaskOutputPlanInfo policyType
             * @property {string|null} [processorControlNumber] INFPBMTaskOutputPlanInfo processorControlNumber
             * @property {infinitusapi.INFPlanRenewalType|null} [renewalType] INFPBMTaskOutputPlanInfo renewalType
             * @property {google.protobuf.IBoolValue|null} [willCoverIfPrimaryDenies] INFPBMTaskOutputPlanInfo willCoverIfPrimaryDenies
             */
    
            /**
             * Constructs a new INFPBMTaskOutputPlanInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMTaskOutputPlanInfo.
             * @implements IINFPBMTaskOutputPlanInfo
             * @constructor
             * @param {infinitusapi.IINFPBMTaskOutputPlanInfo=} [properties] Properties to set
             */
            function INFPBMTaskOutputPlanInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMTaskOutputPlanInfo RxGroupNumber.
             * @member {string} RxGroupNumber
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.RxGroupNumber = "";
    
            /**
             * INFPBMTaskOutputPlanInfo bankIdentificationNumber.
             * @member {string} bankIdentificationNumber
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.bankIdentificationNumber = "";
    
            /**
             * INFPBMTaskOutputPlanInfo cobraGracePeriod.
             * @member {string} cobraGracePeriod
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.cobraGracePeriod = "";
    
            /**
             * INFPBMTaskOutputPlanInfo cobraHasGracePeriod.
             * @member {google.protobuf.IBoolValue|null|undefined} cobraHasGracePeriod
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.cobraHasGracePeriod = null;
    
            /**
             * INFPBMTaskOutputPlanInfo cobraPaidThroughDate.
             * @member {string} cobraPaidThroughDate
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.cobraPaidThroughDate = "";
    
            /**
             * INFPBMTaskOutputPlanInfo coordinationOfBenefits.
             * @member {infinitusapi.INFCoordinationOfBenefits} coordinationOfBenefits
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.coordinationOfBenefits = 0;
    
            /**
             * INFPBMTaskOutputPlanInfo currentPlanPriority.
             * @member {infinitusapi.INFCurrentPlanPriority} currentPlanPriority
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.currentPlanPriority = 0;
    
            /**
             * INFPBMTaskOutputPlanInfo fundingType.
             * @member {infinitusapi.INFFundingType} fundingType
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.fundingType = 0;
    
            /**
             * INFPBMTaskOutputPlanInfo healthExchangeGracePeriod.
             * @member {string} healthExchangeGracePeriod
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.healthExchangeGracePeriod = "";
    
            /**
             * INFPBMTaskOutputPlanInfo healthExchangeHasGracePeriod.
             * @member {google.protobuf.IBoolValue|null|undefined} healthExchangeHasGracePeriod
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.healthExchangeHasGracePeriod = null;
    
            /**
             * INFPBMTaskOutputPlanInfo healthExchangePaidThroughDate.
             * @member {string} healthExchangePaidThroughDate
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.healthExchangePaidThroughDate = "";
    
            /**
             * INFPBMTaskOutputPlanInfo isCobraPlan.
             * @member {google.protobuf.IBoolValue|null|undefined} isCobraPlan
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.isCobraPlan = null;
    
            /**
             * INFPBMTaskOutputPlanInfo isHealthExchangePlan.
             * @member {google.protobuf.IBoolValue|null|undefined} isHealthExchangePlan
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.isHealthExchangePlan = null;
    
            /**
             * INFPBMTaskOutputPlanInfo newPlanAvailable.
             * @member {google.protobuf.IBoolValue|null|undefined} newPlanAvailable
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.newPlanAvailable = null;
    
            /**
             * INFPBMTaskOutputPlanInfo newPlanEffectiveDate.
             * @member {string} newPlanEffectiveDate
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.newPlanEffectiveDate = "";
    
            /**
             * INFPBMTaskOutputPlanInfo newPlanSubscriberId.
             * @member {string} newPlanSubscriberId
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.newPlanSubscriberId = "";
    
            /**
             * INFPBMTaskOutputPlanInfo otherInsuranceExists.
             * @member {google.protobuf.IBoolValue|null|undefined} otherInsuranceExists
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.otherInsuranceExists = null;
    
            /**
             * INFPBMTaskOutputPlanInfo planEffectiveDate.
             * @member {string} planEffectiveDate
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.planEffectiveDate = "";
    
            /**
             * INFPBMTaskOutputPlanInfo planFax.
             * @member {string} planFax
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.planFax = "";
    
            /**
             * INFPBMTaskOutputPlanInfo planGroupName.
             * @member {string} planGroupName
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.planGroupName = "";
    
            /**
             * INFPBMTaskOutputPlanInfo planGroupNumber.
             * @member {string} planGroupNumber
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.planGroupNumber = "";
    
            /**
             * INFPBMTaskOutputPlanInfo planName.
             * @member {string} planName
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.planName = "";
    
            /**
             * INFPBMTaskOutputPlanInfo planRenewalMonth.
             * @member {infinitusapi.INFPlanRenewalMonth} planRenewalMonth
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.planRenewalMonth = 0;
    
            /**
             * INFPBMTaskOutputPlanInfo planTerminationDate.
             * @member {string} planTerminationDate
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.planTerminationDate = "";
    
            /**
             * INFPBMTaskOutputPlanInfo planType.
             * @member {infinitusapi.INFPlanType} planType
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.planType = 0;
    
            /**
             * INFPBMTaskOutputPlanInfo policyHolderDateOfBirth.
             * @member {string} policyHolderDateOfBirth
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.policyHolderDateOfBirth = "";
    
            /**
             * INFPBMTaskOutputPlanInfo policyHolderFirstName.
             * @member {string} policyHolderFirstName
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.policyHolderFirstName = "";
    
            /**
             * INFPBMTaskOutputPlanInfo policyHolderLastName.
             * @member {string} policyHolderLastName
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.policyHolderLastName = "";
    
            /**
             * INFPBMTaskOutputPlanInfo policyHolderRelationship.
             * @member {infinitusapi.INFPolicyHolderRelationship} policyHolderRelationship
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.policyHolderRelationship = 0;
    
            /**
             * INFPBMTaskOutputPlanInfo policyNumber.
             * @member {string} policyNumber
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.policyNumber = "";
    
            /**
             * INFPBMTaskOutputPlanInfo policyType.
             * @member {infinitusapi.INFPolicyType} policyType
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.policyType = 0;
    
            /**
             * INFPBMTaskOutputPlanInfo processorControlNumber.
             * @member {string} processorControlNumber
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.processorControlNumber = "";
    
            /**
             * INFPBMTaskOutputPlanInfo renewalType.
             * @member {infinitusapi.INFPlanRenewalType} renewalType
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.renewalType = 0;
    
            /**
             * INFPBMTaskOutputPlanInfo willCoverIfPrimaryDenies.
             * @member {google.protobuf.IBoolValue|null|undefined} willCoverIfPrimaryDenies
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             */
            INFPBMTaskOutputPlanInfo.prototype.willCoverIfPrimaryDenies = null;
    
            /**
             * Creates a new INFPBMTaskOutputPlanInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputPlanInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMTaskOutputPlanInfo} INFPBMTaskOutputPlanInfo instance
             */
            INFPBMTaskOutputPlanInfo.create = function create(properties) {
                return new INFPBMTaskOutputPlanInfo(properties);
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputPlanInfo message. Does not implicitly {@link infinitusapi.INFPBMTaskOutputPlanInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputPlanInfo} message INFPBMTaskOutputPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputPlanInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.RxGroupNumber != null && Object.hasOwnProperty.call(message, "RxGroupNumber"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.RxGroupNumber);
                if (message.bankIdentificationNumber != null && Object.hasOwnProperty.call(message, "bankIdentificationNumber"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.bankIdentificationNumber);
                if (message.cobraGracePeriod != null && Object.hasOwnProperty.call(message, "cobraGracePeriod"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.cobraGracePeriod);
                if (message.cobraHasGracePeriod != null && Object.hasOwnProperty.call(message, "cobraHasGracePeriod"))
                    $root.google.protobuf.BoolValue.encode(message.cobraHasGracePeriod, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.cobraPaidThroughDate != null && Object.hasOwnProperty.call(message, "cobraPaidThroughDate"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.cobraPaidThroughDate);
                if (message.coordinationOfBenefits != null && Object.hasOwnProperty.call(message, "coordinationOfBenefits"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.coordinationOfBenefits);
                if (message.currentPlanPriority != null && Object.hasOwnProperty.call(message, "currentPlanPriority"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.currentPlanPriority);
                if (message.fundingType != null && Object.hasOwnProperty.call(message, "fundingType"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.fundingType);
                if (message.healthExchangeGracePeriod != null && Object.hasOwnProperty.call(message, "healthExchangeGracePeriod"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.healthExchangeGracePeriod);
                if (message.healthExchangeHasGracePeriod != null && Object.hasOwnProperty.call(message, "healthExchangeHasGracePeriod"))
                    $root.google.protobuf.BoolValue.encode(message.healthExchangeHasGracePeriod, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.healthExchangePaidThroughDate != null && Object.hasOwnProperty.call(message, "healthExchangePaidThroughDate"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.healthExchangePaidThroughDate);
                if (message.isCobraPlan != null && Object.hasOwnProperty.call(message, "isCobraPlan"))
                    $root.google.protobuf.BoolValue.encode(message.isCobraPlan, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.isHealthExchangePlan != null && Object.hasOwnProperty.call(message, "isHealthExchangePlan"))
                    $root.google.protobuf.BoolValue.encode(message.isHealthExchangePlan, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.newPlanAvailable != null && Object.hasOwnProperty.call(message, "newPlanAvailable"))
                    $root.google.protobuf.BoolValue.encode(message.newPlanAvailable, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.newPlanEffectiveDate != null && Object.hasOwnProperty.call(message, "newPlanEffectiveDate"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.newPlanEffectiveDate);
                if (message.newPlanSubscriberId != null && Object.hasOwnProperty.call(message, "newPlanSubscriberId"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.newPlanSubscriberId);
                if (message.otherInsuranceExists != null && Object.hasOwnProperty.call(message, "otherInsuranceExists"))
                    $root.google.protobuf.BoolValue.encode(message.otherInsuranceExists, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.planEffectiveDate != null && Object.hasOwnProperty.call(message, "planEffectiveDate"))
                    writer.uint32(/* id 18, wireType 2 =*/146).string(message.planEffectiveDate);
                if (message.planFax != null && Object.hasOwnProperty.call(message, "planFax"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.planFax);
                if (message.planGroupName != null && Object.hasOwnProperty.call(message, "planGroupName"))
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.planGroupName);
                if (message.planGroupNumber != null && Object.hasOwnProperty.call(message, "planGroupNumber"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.planGroupNumber);
                if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.planName);
                if (message.planRenewalMonth != null && Object.hasOwnProperty.call(message, "planRenewalMonth"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int32(message.planRenewalMonth);
                if (message.planTerminationDate != null && Object.hasOwnProperty.call(message, "planTerminationDate"))
                    writer.uint32(/* id 24, wireType 2 =*/194).string(message.planTerminationDate);
                if (message.planType != null && Object.hasOwnProperty.call(message, "planType"))
                    writer.uint32(/* id 25, wireType 0 =*/200).int32(message.planType);
                if (message.policyHolderDateOfBirth != null && Object.hasOwnProperty.call(message, "policyHolderDateOfBirth"))
                    writer.uint32(/* id 26, wireType 2 =*/210).string(message.policyHolderDateOfBirth);
                if (message.policyHolderFirstName != null && Object.hasOwnProperty.call(message, "policyHolderFirstName"))
                    writer.uint32(/* id 27, wireType 2 =*/218).string(message.policyHolderFirstName);
                if (message.policyHolderLastName != null && Object.hasOwnProperty.call(message, "policyHolderLastName"))
                    writer.uint32(/* id 28, wireType 2 =*/226).string(message.policyHolderLastName);
                if (message.policyHolderRelationship != null && Object.hasOwnProperty.call(message, "policyHolderRelationship"))
                    writer.uint32(/* id 29, wireType 0 =*/232).int32(message.policyHolderRelationship);
                if (message.policyNumber != null && Object.hasOwnProperty.call(message, "policyNumber"))
                    writer.uint32(/* id 30, wireType 2 =*/242).string(message.policyNumber);
                if (message.policyType != null && Object.hasOwnProperty.call(message, "policyType"))
                    writer.uint32(/* id 31, wireType 0 =*/248).int32(message.policyType);
                if (message.processorControlNumber != null && Object.hasOwnProperty.call(message, "processorControlNumber"))
                    writer.uint32(/* id 32, wireType 2 =*/258).string(message.processorControlNumber);
                if (message.renewalType != null && Object.hasOwnProperty.call(message, "renewalType"))
                    writer.uint32(/* id 33, wireType 0 =*/264).int32(message.renewalType);
                if (message.willCoverIfPrimaryDenies != null && Object.hasOwnProperty.call(message, "willCoverIfPrimaryDenies"))
                    $root.google.protobuf.BoolValue.encode(message.willCoverIfPrimaryDenies, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputPlanInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPBMTaskOutputPlanInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputPlanInfo} message INFPBMTaskOutputPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputPlanInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMTaskOutputPlanInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMTaskOutputPlanInfo} INFPBMTaskOutputPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputPlanInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMTaskOutputPlanInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.RxGroupNumber = reader.string();
                            break;
                        }
                    case 2: {
                            message.bankIdentificationNumber = reader.string();
                            break;
                        }
                    case 3: {
                            message.cobraGracePeriod = reader.string();
                            break;
                        }
                    case 4: {
                            message.cobraHasGracePeriod = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.cobraPaidThroughDate = reader.string();
                            break;
                        }
                    case 6: {
                            message.coordinationOfBenefits = reader.int32();
                            break;
                        }
                    case 7: {
                            message.currentPlanPriority = reader.int32();
                            break;
                        }
                    case 8: {
                            message.fundingType = reader.int32();
                            break;
                        }
                    case 9: {
                            message.healthExchangeGracePeriod = reader.string();
                            break;
                        }
                    case 10: {
                            message.healthExchangeHasGracePeriod = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.healthExchangePaidThroughDate = reader.string();
                            break;
                        }
                    case 12: {
                            message.isCobraPlan = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.isHealthExchangePlan = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.newPlanAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.newPlanEffectiveDate = reader.string();
                            break;
                        }
                    case 16: {
                            message.newPlanSubscriberId = reader.string();
                            break;
                        }
                    case 17: {
                            message.otherInsuranceExists = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.planEffectiveDate = reader.string();
                            break;
                        }
                    case 19: {
                            message.planFax = reader.string();
                            break;
                        }
                    case 20: {
                            message.planGroupName = reader.string();
                            break;
                        }
                    case 21: {
                            message.planGroupNumber = reader.string();
                            break;
                        }
                    case 22: {
                            message.planName = reader.string();
                            break;
                        }
                    case 23: {
                            message.planRenewalMonth = reader.int32();
                            break;
                        }
                    case 24: {
                            message.planTerminationDate = reader.string();
                            break;
                        }
                    case 25: {
                            message.planType = reader.int32();
                            break;
                        }
                    case 26: {
                            message.policyHolderDateOfBirth = reader.string();
                            break;
                        }
                    case 27: {
                            message.policyHolderFirstName = reader.string();
                            break;
                        }
                    case 28: {
                            message.policyHolderLastName = reader.string();
                            break;
                        }
                    case 29: {
                            message.policyHolderRelationship = reader.int32();
                            break;
                        }
                    case 30: {
                            message.policyNumber = reader.string();
                            break;
                        }
                    case 31: {
                            message.policyType = reader.int32();
                            break;
                        }
                    case 32: {
                            message.processorControlNumber = reader.string();
                            break;
                        }
                    case 33: {
                            message.renewalType = reader.int32();
                            break;
                        }
                    case 34: {
                            message.willCoverIfPrimaryDenies = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMTaskOutputPlanInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMTaskOutputPlanInfo} INFPBMTaskOutputPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputPlanInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMTaskOutputPlanInfo message.
             * @function verify
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMTaskOutputPlanInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.RxGroupNumber != null && message.hasOwnProperty("RxGroupNumber"))
                    if (!$util.isString(message.RxGroupNumber))
                        return "RxGroupNumber: string expected";
                if (message.bankIdentificationNumber != null && message.hasOwnProperty("bankIdentificationNumber"))
                    if (!$util.isString(message.bankIdentificationNumber))
                        return "bankIdentificationNumber: string expected";
                if (message.cobraGracePeriod != null && message.hasOwnProperty("cobraGracePeriod"))
                    if (!$util.isString(message.cobraGracePeriod))
                        return "cobraGracePeriod: string expected";
                if (message.cobraHasGracePeriod != null && message.hasOwnProperty("cobraHasGracePeriod")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.cobraHasGracePeriod);
                    if (error)
                        return "cobraHasGracePeriod." + error;
                }
                if (message.cobraPaidThroughDate != null && message.hasOwnProperty("cobraPaidThroughDate"))
                    if (!$util.isString(message.cobraPaidThroughDate))
                        return "cobraPaidThroughDate: string expected";
                if (message.coordinationOfBenefits != null && message.hasOwnProperty("coordinationOfBenefits"))
                    switch (message.coordinationOfBenefits) {
                    default:
                        return "coordinationOfBenefits: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.currentPlanPriority != null && message.hasOwnProperty("currentPlanPriority"))
                    switch (message.currentPlanPriority) {
                    default:
                        return "currentPlanPriority: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.fundingType != null && message.hasOwnProperty("fundingType"))
                    switch (message.fundingType) {
                    default:
                        return "fundingType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.healthExchangeGracePeriod != null && message.hasOwnProperty("healthExchangeGracePeriod"))
                    if (!$util.isString(message.healthExchangeGracePeriod))
                        return "healthExchangeGracePeriod: string expected";
                if (message.healthExchangeHasGracePeriod != null && message.hasOwnProperty("healthExchangeHasGracePeriod")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.healthExchangeHasGracePeriod);
                    if (error)
                        return "healthExchangeHasGracePeriod." + error;
                }
                if (message.healthExchangePaidThroughDate != null && message.hasOwnProperty("healthExchangePaidThroughDate"))
                    if (!$util.isString(message.healthExchangePaidThroughDate))
                        return "healthExchangePaidThroughDate: string expected";
                if (message.isCobraPlan != null && message.hasOwnProperty("isCobraPlan")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isCobraPlan);
                    if (error)
                        return "isCobraPlan." + error;
                }
                if (message.isHealthExchangePlan != null && message.hasOwnProperty("isHealthExchangePlan")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isHealthExchangePlan);
                    if (error)
                        return "isHealthExchangePlan." + error;
                }
                if (message.newPlanAvailable != null && message.hasOwnProperty("newPlanAvailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.newPlanAvailable);
                    if (error)
                        return "newPlanAvailable." + error;
                }
                if (message.newPlanEffectiveDate != null && message.hasOwnProperty("newPlanEffectiveDate"))
                    if (!$util.isString(message.newPlanEffectiveDate))
                        return "newPlanEffectiveDate: string expected";
                if (message.newPlanSubscriberId != null && message.hasOwnProperty("newPlanSubscriberId"))
                    if (!$util.isString(message.newPlanSubscriberId))
                        return "newPlanSubscriberId: string expected";
                if (message.otherInsuranceExists != null && message.hasOwnProperty("otherInsuranceExists")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.otherInsuranceExists);
                    if (error)
                        return "otherInsuranceExists." + error;
                }
                if (message.planEffectiveDate != null && message.hasOwnProperty("planEffectiveDate"))
                    if (!$util.isString(message.planEffectiveDate))
                        return "planEffectiveDate: string expected";
                if (message.planFax != null && message.hasOwnProperty("planFax"))
                    if (!$util.isString(message.planFax))
                        return "planFax: string expected";
                if (message.planGroupName != null && message.hasOwnProperty("planGroupName"))
                    if (!$util.isString(message.planGroupName))
                        return "planGroupName: string expected";
                if (message.planGroupNumber != null && message.hasOwnProperty("planGroupNumber"))
                    if (!$util.isString(message.planGroupNumber))
                        return "planGroupNumber: string expected";
                if (message.planName != null && message.hasOwnProperty("planName"))
                    if (!$util.isString(message.planName))
                        return "planName: string expected";
                if (message.planRenewalMonth != null && message.hasOwnProperty("planRenewalMonth"))
                    switch (message.planRenewalMonth) {
                    default:
                        return "planRenewalMonth: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.planTerminationDate != null && message.hasOwnProperty("planTerminationDate"))
                    if (!$util.isString(message.planTerminationDate))
                        return "planTerminationDate: string expected";
                if (message.planType != null && message.hasOwnProperty("planType"))
                    switch (message.planType) {
                    default:
                        return "planType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                if (message.policyHolderDateOfBirth != null && message.hasOwnProperty("policyHolderDateOfBirth"))
                    if (!$util.isString(message.policyHolderDateOfBirth))
                        return "policyHolderDateOfBirth: string expected";
                if (message.policyHolderFirstName != null && message.hasOwnProperty("policyHolderFirstName"))
                    if (!$util.isString(message.policyHolderFirstName))
                        return "policyHolderFirstName: string expected";
                if (message.policyHolderLastName != null && message.hasOwnProperty("policyHolderLastName"))
                    if (!$util.isString(message.policyHolderLastName))
                        return "policyHolderLastName: string expected";
                if (message.policyHolderRelationship != null && message.hasOwnProperty("policyHolderRelationship"))
                    switch (message.policyHolderRelationship) {
                    default:
                        return "policyHolderRelationship: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.policyNumber != null && message.hasOwnProperty("policyNumber"))
                    if (!$util.isString(message.policyNumber))
                        return "policyNumber: string expected";
                if (message.policyType != null && message.hasOwnProperty("policyType"))
                    switch (message.policyType) {
                    default:
                        return "policyType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                        break;
                    }
                if (message.processorControlNumber != null && message.hasOwnProperty("processorControlNumber"))
                    if (!$util.isString(message.processorControlNumber))
                        return "processorControlNumber: string expected";
                if (message.renewalType != null && message.hasOwnProperty("renewalType"))
                    switch (message.renewalType) {
                    default:
                        return "renewalType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.willCoverIfPrimaryDenies != null && message.hasOwnProperty("willCoverIfPrimaryDenies")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.willCoverIfPrimaryDenies);
                    if (error)
                        return "willCoverIfPrimaryDenies." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPBMTaskOutputPlanInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMTaskOutputPlanInfo} INFPBMTaskOutputPlanInfo
             */
            INFPBMTaskOutputPlanInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMTaskOutputPlanInfo)
                    return object;
                var message = new $root.infinitusapi.INFPBMTaskOutputPlanInfo();
                if (object.RxGroupNumber != null)
                    message.RxGroupNumber = String(object.RxGroupNumber);
                if (object.bankIdentificationNumber != null)
                    message.bankIdentificationNumber = String(object.bankIdentificationNumber);
                if (object.cobraGracePeriod != null)
                    message.cobraGracePeriod = String(object.cobraGracePeriod);
                if (object.cobraHasGracePeriod != null) {
                    if (typeof object.cobraHasGracePeriod !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputPlanInfo.cobraHasGracePeriod: object expected");
                    message.cobraHasGracePeriod = $root.google.protobuf.BoolValue.fromObject(object.cobraHasGracePeriod);
                }
                if (object.cobraPaidThroughDate != null)
                    message.cobraPaidThroughDate = String(object.cobraPaidThroughDate);
                switch (object.coordinationOfBenefits) {
                default:
                    if (typeof object.coordinationOfBenefits === "number") {
                        message.coordinationOfBenefits = object.coordinationOfBenefits;
                        break;
                    }
                    break;
                case "INF_COORDINATION_OF_BENEFITS_UNKNOWN":
                case 0:
                    message.coordinationOfBenefits = 0;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_NOT_APPLICABLE":
                case 1:
                    message.coordinationOfBenefits = 1;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_SUPPLEMENT":
                case 2:
                    message.coordinationOfBenefits = 2;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_STANDARD":
                case 3:
                    message.coordinationOfBenefits = 3;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_NON_DUPLICATION":
                case 4:
                    message.coordinationOfBenefits = 4;
                    break;
                case "INF_COORDINATION_OF_BENEFITS_COME_OUT_WHOLE":
                case 5:
                    message.coordinationOfBenefits = 5;
                    break;
                }
                switch (object.currentPlanPriority) {
                default:
                    if (typeof object.currentPlanPriority === "number") {
                        message.currentPlanPriority = object.currentPlanPriority;
                        break;
                    }
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_UNKNOWN":
                case 0:
                    message.currentPlanPriority = 0;
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_NOT_APPLICABLE":
                case 1:
                    message.currentPlanPriority = 1;
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_PRIMARY":
                case 2:
                    message.currentPlanPriority = 2;
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_SECONDARY":
                case 3:
                    message.currentPlanPriority = 3;
                    break;
                case "INF_CURRENT_PLAN_PRIORITY_TERTIARY":
                case 4:
                    message.currentPlanPriority = 4;
                    break;
                }
                switch (object.fundingType) {
                default:
                    if (typeof object.fundingType === "number") {
                        message.fundingType = object.fundingType;
                        break;
                    }
                    break;
                case "INF_FUNDING_TYPE_UNKNOWN":
                case 0:
                    message.fundingType = 0;
                    break;
                case "INF_FUNDING_TYPE_NOT_APPLICABLE":
                case 1:
                    message.fundingType = 1;
                    break;
                case "INF_FUNDING_TYPE_SELF":
                case 2:
                    message.fundingType = 2;
                    break;
                case "INF_FUNDING_TYPE_FULLY":
                case 3:
                    message.fundingType = 3;
                    break;
                }
                if (object.healthExchangeGracePeriod != null)
                    message.healthExchangeGracePeriod = String(object.healthExchangeGracePeriod);
                if (object.healthExchangeHasGracePeriod != null) {
                    if (typeof object.healthExchangeHasGracePeriod !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputPlanInfo.healthExchangeHasGracePeriod: object expected");
                    message.healthExchangeHasGracePeriod = $root.google.protobuf.BoolValue.fromObject(object.healthExchangeHasGracePeriod);
                }
                if (object.healthExchangePaidThroughDate != null)
                    message.healthExchangePaidThroughDate = String(object.healthExchangePaidThroughDate);
                if (object.isCobraPlan != null) {
                    if (typeof object.isCobraPlan !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputPlanInfo.isCobraPlan: object expected");
                    message.isCobraPlan = $root.google.protobuf.BoolValue.fromObject(object.isCobraPlan);
                }
                if (object.isHealthExchangePlan != null) {
                    if (typeof object.isHealthExchangePlan !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputPlanInfo.isHealthExchangePlan: object expected");
                    message.isHealthExchangePlan = $root.google.protobuf.BoolValue.fromObject(object.isHealthExchangePlan);
                }
                if (object.newPlanAvailable != null) {
                    if (typeof object.newPlanAvailable !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputPlanInfo.newPlanAvailable: object expected");
                    message.newPlanAvailable = $root.google.protobuf.BoolValue.fromObject(object.newPlanAvailable);
                }
                if (object.newPlanEffectiveDate != null)
                    message.newPlanEffectiveDate = String(object.newPlanEffectiveDate);
                if (object.newPlanSubscriberId != null)
                    message.newPlanSubscriberId = String(object.newPlanSubscriberId);
                if (object.otherInsuranceExists != null) {
                    if (typeof object.otherInsuranceExists !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputPlanInfo.otherInsuranceExists: object expected");
                    message.otherInsuranceExists = $root.google.protobuf.BoolValue.fromObject(object.otherInsuranceExists);
                }
                if (object.planEffectiveDate != null)
                    message.planEffectiveDate = String(object.planEffectiveDate);
                if (object.planFax != null)
                    message.planFax = String(object.planFax);
                if (object.planGroupName != null)
                    message.planGroupName = String(object.planGroupName);
                if (object.planGroupNumber != null)
                    message.planGroupNumber = String(object.planGroupNumber);
                if (object.planName != null)
                    message.planName = String(object.planName);
                switch (object.planRenewalMonth) {
                default:
                    if (typeof object.planRenewalMonth === "number") {
                        message.planRenewalMonth = object.planRenewalMonth;
                        break;
                    }
                    break;
                case "INF_PLAN_RENEWAL_MONTH_UNKNOWN":
                case 0:
                    message.planRenewalMonth = 0;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_NOT_APPLICABLE":
                case 1:
                    message.planRenewalMonth = 1;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_JAN":
                case 2:
                    message.planRenewalMonth = 2;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_FEB":
                case 3:
                    message.planRenewalMonth = 3;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_MAR":
                case 4:
                    message.planRenewalMonth = 4;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_APR":
                case 5:
                    message.planRenewalMonth = 5;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_MAY":
                case 6:
                    message.planRenewalMonth = 6;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_JUN":
                case 7:
                    message.planRenewalMonth = 7;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_JUL":
                case 8:
                    message.planRenewalMonth = 8;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_AUG":
                case 9:
                    message.planRenewalMonth = 9;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_SEP":
                case 10:
                    message.planRenewalMonth = 10;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_OCT":
                case 11:
                    message.planRenewalMonth = 11;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_NOV":
                case 12:
                    message.planRenewalMonth = 12;
                    break;
                case "INF_PLAN_RENEWAL_MONTH_DEC":
                case 13:
                    message.planRenewalMonth = 13;
                    break;
                }
                if (object.planTerminationDate != null)
                    message.planTerminationDate = String(object.planTerminationDate);
                switch (object.planType) {
                default:
                    if (typeof object.planType === "number") {
                        message.planType = object.planType;
                        break;
                    }
                    break;
                case "INF_PLAN_TYPE_UNKNOWN":
                case 0:
                    message.planType = 0;
                    break;
                case "INF_PLAN_TYPE_NOT_APPLICABLE":
                case 1:
                    message.planType = 1;
                    break;
                case "INF_PLAN_TYPE_EPO":
                case 2:
                    message.planType = 2;
                    break;
                case "INF_PLAN_TYPE_HMO":
                case 3:
                    message.planType = 3;
                    break;
                case "INF_PLAN_TYPE_INDEMNITY":
                case 4:
                    message.planType = 4;
                    break;
                case "INF_PLAN_TYPE_MEDICAID":
                case 5:
                    message.planType = 5;
                    break;
                case "INF_PLAN_TYPE_OAP":
                case 6:
                    message.planType = 6;
                    break;
                case "INF_PLAN_TYPE_POS":
                case 7:
                    message.planType = 7;
                    break;
                case "INF_PLAN_TYPE_PPO":
                case 8:
                    message.planType = 8;
                    break;
                case "INF_PLAN_TYPE_TRICARE":
                case 9:
                    message.planType = 9;
                    break;
                case "INF_PLAN_TYPE_MEDICARE_SUPPLEMENT":
                case 10:
                    message.planType = 10;
                    break;
                case "INF_PLAN_TYPE_HMO_POS":
                case 11:
                    message.planType = 11;
                    break;
                case "INF_PLAN_TYPE_HDHP":
                case 12:
                    message.planType = 12;
                    break;
                }
                if (object.policyHolderDateOfBirth != null)
                    message.policyHolderDateOfBirth = String(object.policyHolderDateOfBirth);
                if (object.policyHolderFirstName != null)
                    message.policyHolderFirstName = String(object.policyHolderFirstName);
                if (object.policyHolderLastName != null)
                    message.policyHolderLastName = String(object.policyHolderLastName);
                switch (object.policyHolderRelationship) {
                default:
                    if (typeof object.policyHolderRelationship === "number") {
                        message.policyHolderRelationship = object.policyHolderRelationship;
                        break;
                    }
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_UNKNOWN":
                case 0:
                    message.policyHolderRelationship = 0;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_SELF":
                case 1:
                    message.policyHolderRelationship = 1;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_CHILD":
                case 2:
                    message.policyHolderRelationship = 2;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_SPOUSE":
                case 3:
                    message.policyHolderRelationship = 3;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_OTHER":
                case 4:
                    message.policyHolderRelationship = 4;
                    break;
                case "INF_POLICY_HOLDER_RELATIONSHIP_NOT_APPLICABLE":
                case 5:
                    message.policyHolderRelationship = 5;
                    break;
                }
                if (object.policyNumber != null)
                    message.policyNumber = String(object.policyNumber);
                switch (object.policyType) {
                default:
                    if (typeof object.policyType === "number") {
                        message.policyType = object.policyType;
                        break;
                    }
                    break;
                case "INF_POLICY_TYPE_UNKNOWN":
                case 0:
                    message.policyType = 0;
                    break;
                case "INF_POLICY_TYPE_NOT_APPLICABLE":
                case 1:
                    message.policyType = 1;
                    break;
                case "INF_POLICY_TYPE_OTHER":
                case 2:
                    message.policyType = 2;
                    break;
                case "INF_POLICY_TYPE_COMMERCIAL":
                case 3:
                    message.policyType = 3;
                    break;
                case "INF_POLICY_TYPE_GOVERNMENT_MEDICARE":
                case 4:
                    message.policyType = 4;
                    break;
                case "INF_POLICY_TYPE_GOVERNMENT_MEDICAID":
                case 5:
                    message.policyType = 5;
                    break;
                case "INF_POLICY_TYPE_GOVERNMENT_TRICARE":
                case 6:
                    message.policyType = 6;
                    break;
                case "INF_POLICY_TYPE_FEDERAL_EMPLOYEE_PROGRAM":
                case 7:
                    message.policyType = 7;
                    break;
                case "INF_POLICY_TYPE_MANAGED_MEDICAID":
                case 8:
                    message.policyType = 8;
                    break;
                case "INF_POLICY_TYPE_MEDICARE_ADVANTAGE":
                case 9:
                    message.policyType = 9;
                    break;
                case "INF_POLICY_TYPE_MEDICARE_PDP":
                case 10:
                    message.policyType = 10;
                    break;
                }
                if (object.processorControlNumber != null)
                    message.processorControlNumber = String(object.processorControlNumber);
                switch (object.renewalType) {
                default:
                    if (typeof object.renewalType === "number") {
                        message.renewalType = object.renewalType;
                        break;
                    }
                    break;
                case "INF_PLAN_RENEWAL_TYPE_UNKNOWN":
                case 0:
                    message.renewalType = 0;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_NOT_APPLICABLE":
                case 1:
                    message.renewalType = 1;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_CALENDAR_YEARLY":
                case 2:
                    message.renewalType = 2;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_CALENDAR_QUARTERLY":
                case 3:
                    message.renewalType = 3;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_CALENDAR_MONTHLY":
                case 4:
                    message.renewalType = 4;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_BENEFIT_YEARLY":
                case 5:
                    message.renewalType = 5;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_BENEFIT_QUARTERLY":
                case 6:
                    message.renewalType = 6;
                    break;
                case "INF_PLAN_RENEWAL_TYPE_BENEFIT_MONTHLY":
                case 7:
                    message.renewalType = 7;
                    break;
                }
                if (object.willCoverIfPrimaryDenies != null) {
                    if (typeof object.willCoverIfPrimaryDenies !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputPlanInfo.willCoverIfPrimaryDenies: object expected");
                    message.willCoverIfPrimaryDenies = $root.google.protobuf.BoolValue.fromObject(object.willCoverIfPrimaryDenies);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMTaskOutputPlanInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {infinitusapi.INFPBMTaskOutputPlanInfo} message INFPBMTaskOutputPlanInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMTaskOutputPlanInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.RxGroupNumber = "";
                    object.bankIdentificationNumber = "";
                    object.cobraGracePeriod = "";
                    object.cobraHasGracePeriod = null;
                    object.cobraPaidThroughDate = "";
                    object.coordinationOfBenefits = options.enums === String ? "INF_COORDINATION_OF_BENEFITS_UNKNOWN" : 0;
                    object.currentPlanPriority = options.enums === String ? "INF_CURRENT_PLAN_PRIORITY_UNKNOWN" : 0;
                    object.fundingType = options.enums === String ? "INF_FUNDING_TYPE_UNKNOWN" : 0;
                    object.healthExchangeGracePeriod = "";
                    object.healthExchangeHasGracePeriod = null;
                    object.healthExchangePaidThroughDate = "";
                    object.isCobraPlan = null;
                    object.isHealthExchangePlan = null;
                    object.newPlanAvailable = null;
                    object.newPlanEffectiveDate = "";
                    object.newPlanSubscriberId = "";
                    object.otherInsuranceExists = null;
                    object.planEffectiveDate = "";
                    object.planFax = "";
                    object.planGroupName = "";
                    object.planGroupNumber = "";
                    object.planName = "";
                    object.planRenewalMonth = options.enums === String ? "INF_PLAN_RENEWAL_MONTH_UNKNOWN" : 0;
                    object.planTerminationDate = "";
                    object.planType = options.enums === String ? "INF_PLAN_TYPE_UNKNOWN" : 0;
                    object.policyHolderDateOfBirth = "";
                    object.policyHolderFirstName = "";
                    object.policyHolderLastName = "";
                    object.policyHolderRelationship = options.enums === String ? "INF_POLICY_HOLDER_RELATIONSHIP_UNKNOWN" : 0;
                    object.policyNumber = "";
                    object.policyType = options.enums === String ? "INF_POLICY_TYPE_UNKNOWN" : 0;
                    object.processorControlNumber = "";
                    object.renewalType = options.enums === String ? "INF_PLAN_RENEWAL_TYPE_UNKNOWN" : 0;
                    object.willCoverIfPrimaryDenies = null;
                }
                if (message.RxGroupNumber != null && message.hasOwnProperty("RxGroupNumber"))
                    object.RxGroupNumber = message.RxGroupNumber;
                if (message.bankIdentificationNumber != null && message.hasOwnProperty("bankIdentificationNumber"))
                    object.bankIdentificationNumber = message.bankIdentificationNumber;
                if (message.cobraGracePeriod != null && message.hasOwnProperty("cobraGracePeriod"))
                    object.cobraGracePeriod = message.cobraGracePeriod;
                if (message.cobraHasGracePeriod != null && message.hasOwnProperty("cobraHasGracePeriod"))
                    object.cobraHasGracePeriod = $root.google.protobuf.BoolValue.toObject(message.cobraHasGracePeriod, options);
                if (message.cobraPaidThroughDate != null && message.hasOwnProperty("cobraPaidThroughDate"))
                    object.cobraPaidThroughDate = message.cobraPaidThroughDate;
                if (message.coordinationOfBenefits != null && message.hasOwnProperty("coordinationOfBenefits"))
                    object.coordinationOfBenefits = options.enums === String ? $root.infinitusapi.INFCoordinationOfBenefits[message.coordinationOfBenefits] === undefined ? message.coordinationOfBenefits : $root.infinitusapi.INFCoordinationOfBenefits[message.coordinationOfBenefits] : message.coordinationOfBenefits;
                if (message.currentPlanPriority != null && message.hasOwnProperty("currentPlanPriority"))
                    object.currentPlanPriority = options.enums === String ? $root.infinitusapi.INFCurrentPlanPriority[message.currentPlanPriority] === undefined ? message.currentPlanPriority : $root.infinitusapi.INFCurrentPlanPriority[message.currentPlanPriority] : message.currentPlanPriority;
                if (message.fundingType != null && message.hasOwnProperty("fundingType"))
                    object.fundingType = options.enums === String ? $root.infinitusapi.INFFundingType[message.fundingType] === undefined ? message.fundingType : $root.infinitusapi.INFFundingType[message.fundingType] : message.fundingType;
                if (message.healthExchangeGracePeriod != null && message.hasOwnProperty("healthExchangeGracePeriod"))
                    object.healthExchangeGracePeriod = message.healthExchangeGracePeriod;
                if (message.healthExchangeHasGracePeriod != null && message.hasOwnProperty("healthExchangeHasGracePeriod"))
                    object.healthExchangeHasGracePeriod = $root.google.protobuf.BoolValue.toObject(message.healthExchangeHasGracePeriod, options);
                if (message.healthExchangePaidThroughDate != null && message.hasOwnProperty("healthExchangePaidThroughDate"))
                    object.healthExchangePaidThroughDate = message.healthExchangePaidThroughDate;
                if (message.isCobraPlan != null && message.hasOwnProperty("isCobraPlan"))
                    object.isCobraPlan = $root.google.protobuf.BoolValue.toObject(message.isCobraPlan, options);
                if (message.isHealthExchangePlan != null && message.hasOwnProperty("isHealthExchangePlan"))
                    object.isHealthExchangePlan = $root.google.protobuf.BoolValue.toObject(message.isHealthExchangePlan, options);
                if (message.newPlanAvailable != null && message.hasOwnProperty("newPlanAvailable"))
                    object.newPlanAvailable = $root.google.protobuf.BoolValue.toObject(message.newPlanAvailable, options);
                if (message.newPlanEffectiveDate != null && message.hasOwnProperty("newPlanEffectiveDate"))
                    object.newPlanEffectiveDate = message.newPlanEffectiveDate;
                if (message.newPlanSubscriberId != null && message.hasOwnProperty("newPlanSubscriberId"))
                    object.newPlanSubscriberId = message.newPlanSubscriberId;
                if (message.otherInsuranceExists != null && message.hasOwnProperty("otherInsuranceExists"))
                    object.otherInsuranceExists = $root.google.protobuf.BoolValue.toObject(message.otherInsuranceExists, options);
                if (message.planEffectiveDate != null && message.hasOwnProperty("planEffectiveDate"))
                    object.planEffectiveDate = message.planEffectiveDate;
                if (message.planFax != null && message.hasOwnProperty("planFax"))
                    object.planFax = message.planFax;
                if (message.planGroupName != null && message.hasOwnProperty("planGroupName"))
                    object.planGroupName = message.planGroupName;
                if (message.planGroupNumber != null && message.hasOwnProperty("planGroupNumber"))
                    object.planGroupNumber = message.planGroupNumber;
                if (message.planName != null && message.hasOwnProperty("planName"))
                    object.planName = message.planName;
                if (message.planRenewalMonth != null && message.hasOwnProperty("planRenewalMonth"))
                    object.planRenewalMonth = options.enums === String ? $root.infinitusapi.INFPlanRenewalMonth[message.planRenewalMonth] === undefined ? message.planRenewalMonth : $root.infinitusapi.INFPlanRenewalMonth[message.planRenewalMonth] : message.planRenewalMonth;
                if (message.planTerminationDate != null && message.hasOwnProperty("planTerminationDate"))
                    object.planTerminationDate = message.planTerminationDate;
                if (message.planType != null && message.hasOwnProperty("planType"))
                    object.planType = options.enums === String ? $root.infinitusapi.INFPlanType[message.planType] === undefined ? message.planType : $root.infinitusapi.INFPlanType[message.planType] : message.planType;
                if (message.policyHolderDateOfBirth != null && message.hasOwnProperty("policyHolderDateOfBirth"))
                    object.policyHolderDateOfBirth = message.policyHolderDateOfBirth;
                if (message.policyHolderFirstName != null && message.hasOwnProperty("policyHolderFirstName"))
                    object.policyHolderFirstName = message.policyHolderFirstName;
                if (message.policyHolderLastName != null && message.hasOwnProperty("policyHolderLastName"))
                    object.policyHolderLastName = message.policyHolderLastName;
                if (message.policyHolderRelationship != null && message.hasOwnProperty("policyHolderRelationship"))
                    object.policyHolderRelationship = options.enums === String ? $root.infinitusapi.INFPolicyHolderRelationship[message.policyHolderRelationship] === undefined ? message.policyHolderRelationship : $root.infinitusapi.INFPolicyHolderRelationship[message.policyHolderRelationship] : message.policyHolderRelationship;
                if (message.policyNumber != null && message.hasOwnProperty("policyNumber"))
                    object.policyNumber = message.policyNumber;
                if (message.policyType != null && message.hasOwnProperty("policyType"))
                    object.policyType = options.enums === String ? $root.infinitusapi.INFPolicyType[message.policyType] === undefined ? message.policyType : $root.infinitusapi.INFPolicyType[message.policyType] : message.policyType;
                if (message.processorControlNumber != null && message.hasOwnProperty("processorControlNumber"))
                    object.processorControlNumber = message.processorControlNumber;
                if (message.renewalType != null && message.hasOwnProperty("renewalType"))
                    object.renewalType = options.enums === String ? $root.infinitusapi.INFPlanRenewalType[message.renewalType] === undefined ? message.renewalType : $root.infinitusapi.INFPlanRenewalType[message.renewalType] : message.renewalType;
                if (message.willCoverIfPrimaryDenies != null && message.hasOwnProperty("willCoverIfPrimaryDenies"))
                    object.willCoverIfPrimaryDenies = $root.google.protobuf.BoolValue.toObject(message.willCoverIfPrimaryDenies, options);
                return object;
            };
    
            /**
             * Converts this INFPBMTaskOutputPlanInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMTaskOutputPlanInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMTaskOutputPlanInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMTaskOutputPlanInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMTaskOutputPlanInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMTaskOutputPlanInfo";
            };
    
            return INFPBMTaskOutputPlanInfo;
        })();
    
        infinitusapi.INFPBMTaskOutputProductCoverage = (function() {
    
            /**
             * Properties of a INFPBMTaskOutputProductCoverage.
             * @memberof infinitusapi
             * @interface IINFPBMTaskOutputProductCoverage
             * @property {string|null} [code] INFPBMTaskOutputProductCoverage code
             * @property {string|null} [costShareTransparencyNotes] INFPBMTaskOutputProductCoverage costShareTransparencyNotes
             * @property {google.protobuf.IBoolValue|null} [isProductCovered] INFPBMTaskOutputProductCoverage isProductCovered
             * @property {google.protobuf.IBoolValue|null} [isProductCoveredMailOrder] INFPBMTaskOutputProductCoverage isProductCoveredMailOrder
             * @property {google.protobuf.IBoolValue|null} [isProductCoveredRetail] INFPBMTaskOutputProductCoverage isProductCoveredRetail
             * @property {google.protobuf.IDoubleValue|null} [mailOrderPharmacyCoinsurance] INFPBMTaskOutputProductCoverage mailOrderPharmacyCoinsurance
             * @property {google.protobuf.IDoubleValue|null} [mailOrderPharmacyCopay] INFPBMTaskOutputProductCoverage mailOrderPharmacyCopay
             * @property {google.protobuf.IDoubleValue|null} [retailPharmacyCoinsurance] INFPBMTaskOutputProductCoverage retailPharmacyCoinsurance
             * @property {google.protobuf.IDoubleValue|null} [retailPharmacyCopay] INFPBMTaskOutputProductCoverage retailPharmacyCopay
             * @property {google.protobuf.IDoubleValue|null} [specialtyPharmacyCoinsurance] INFPBMTaskOutputProductCoverage specialtyPharmacyCoinsurance
             * @property {google.protobuf.IDoubleValue|null} [specialtyPharmacyCopay] INFPBMTaskOutputProductCoverage specialtyPharmacyCopay
             */
    
            /**
             * Constructs a new INFPBMTaskOutputProductCoverage.
             * @memberof infinitusapi
             * @classdesc Represents a INFPBMTaskOutputProductCoverage.
             * @implements IINFPBMTaskOutputProductCoverage
             * @constructor
             * @param {infinitusapi.IINFPBMTaskOutputProductCoverage=} [properties] Properties to set
             */
            function INFPBMTaskOutputProductCoverage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPBMTaskOutputProductCoverage code.
             * @member {string} code
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.code = "";
    
            /**
             * INFPBMTaskOutputProductCoverage costShareTransparencyNotes.
             * @member {string} costShareTransparencyNotes
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.costShareTransparencyNotes = "";
    
            /**
             * INFPBMTaskOutputProductCoverage isProductCovered.
             * @member {google.protobuf.IBoolValue|null|undefined} isProductCovered
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.isProductCovered = null;
    
            /**
             * INFPBMTaskOutputProductCoverage isProductCoveredMailOrder.
             * @member {google.protobuf.IBoolValue|null|undefined} isProductCoveredMailOrder
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.isProductCoveredMailOrder = null;
    
            /**
             * INFPBMTaskOutputProductCoverage isProductCoveredRetail.
             * @member {google.protobuf.IBoolValue|null|undefined} isProductCoveredRetail
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.isProductCoveredRetail = null;
    
            /**
             * INFPBMTaskOutputProductCoverage mailOrderPharmacyCoinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} mailOrderPharmacyCoinsurance
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.mailOrderPharmacyCoinsurance = null;
    
            /**
             * INFPBMTaskOutputProductCoverage mailOrderPharmacyCopay.
             * @member {google.protobuf.IDoubleValue|null|undefined} mailOrderPharmacyCopay
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.mailOrderPharmacyCopay = null;
    
            /**
             * INFPBMTaskOutputProductCoverage retailPharmacyCoinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} retailPharmacyCoinsurance
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.retailPharmacyCoinsurance = null;
    
            /**
             * INFPBMTaskOutputProductCoverage retailPharmacyCopay.
             * @member {google.protobuf.IDoubleValue|null|undefined} retailPharmacyCopay
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.retailPharmacyCopay = null;
    
            /**
             * INFPBMTaskOutputProductCoverage specialtyPharmacyCoinsurance.
             * @member {google.protobuf.IDoubleValue|null|undefined} specialtyPharmacyCoinsurance
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.specialtyPharmacyCoinsurance = null;
    
            /**
             * INFPBMTaskOutputProductCoverage specialtyPharmacyCopay.
             * @member {google.protobuf.IDoubleValue|null|undefined} specialtyPharmacyCopay
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             */
            INFPBMTaskOutputProductCoverage.prototype.specialtyPharmacyCopay = null;
    
            /**
             * Creates a new INFPBMTaskOutputProductCoverage instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputProductCoverage=} [properties] Properties to set
             * @returns {infinitusapi.INFPBMTaskOutputProductCoverage} INFPBMTaskOutputProductCoverage instance
             */
            INFPBMTaskOutputProductCoverage.create = function create(properties) {
                return new INFPBMTaskOutputProductCoverage(properties);
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputProductCoverage message. Does not implicitly {@link infinitusapi.INFPBMTaskOutputProductCoverage.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputProductCoverage} message INFPBMTaskOutputProductCoverage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputProductCoverage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                if (message.costShareTransparencyNotes != null && Object.hasOwnProperty.call(message, "costShareTransparencyNotes"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.costShareTransparencyNotes);
                if (message.isProductCovered != null && Object.hasOwnProperty.call(message, "isProductCovered"))
                    $root.google.protobuf.BoolValue.encode(message.isProductCovered, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.isProductCoveredMailOrder != null && Object.hasOwnProperty.call(message, "isProductCoveredMailOrder"))
                    $root.google.protobuf.BoolValue.encode(message.isProductCoveredMailOrder, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isProductCoveredRetail != null && Object.hasOwnProperty.call(message, "isProductCoveredRetail"))
                    $root.google.protobuf.BoolValue.encode(message.isProductCoveredRetail, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.mailOrderPharmacyCoinsurance != null && Object.hasOwnProperty.call(message, "mailOrderPharmacyCoinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.mailOrderPharmacyCoinsurance, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.mailOrderPharmacyCopay != null && Object.hasOwnProperty.call(message, "mailOrderPharmacyCopay"))
                    $root.google.protobuf.DoubleValue.encode(message.mailOrderPharmacyCopay, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.retailPharmacyCoinsurance != null && Object.hasOwnProperty.call(message, "retailPharmacyCoinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.retailPharmacyCoinsurance, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.retailPharmacyCopay != null && Object.hasOwnProperty.call(message, "retailPharmacyCopay"))
                    $root.google.protobuf.DoubleValue.encode(message.retailPharmacyCopay, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.specialtyPharmacyCoinsurance != null && Object.hasOwnProperty.call(message, "specialtyPharmacyCoinsurance"))
                    $root.google.protobuf.DoubleValue.encode(message.specialtyPharmacyCoinsurance, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.specialtyPharmacyCopay != null && Object.hasOwnProperty.call(message, "specialtyPharmacyCopay"))
                    $root.google.protobuf.DoubleValue.encode(message.specialtyPharmacyCopay, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPBMTaskOutputProductCoverage message, length delimited. Does not implicitly {@link infinitusapi.INFPBMTaskOutputProductCoverage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {infinitusapi.IINFPBMTaskOutputProductCoverage} message INFPBMTaskOutputProductCoverage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPBMTaskOutputProductCoverage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPBMTaskOutputProductCoverage message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPBMTaskOutputProductCoverage} INFPBMTaskOutputProductCoverage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputProductCoverage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPBMTaskOutputProductCoverage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    case 2: {
                            message.costShareTransparencyNotes = reader.string();
                            break;
                        }
                    case 3: {
                            message.isProductCovered = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.isProductCoveredMailOrder = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.isProductCoveredRetail = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.mailOrderPharmacyCoinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.mailOrderPharmacyCopay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.retailPharmacyCoinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.retailPharmacyCopay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.specialtyPharmacyCoinsurance = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.specialtyPharmacyCopay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPBMTaskOutputProductCoverage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPBMTaskOutputProductCoverage} INFPBMTaskOutputProductCoverage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPBMTaskOutputProductCoverage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPBMTaskOutputProductCoverage message.
             * @function verify
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPBMTaskOutputProductCoverage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.costShareTransparencyNotes != null && message.hasOwnProperty("costShareTransparencyNotes"))
                    if (!$util.isString(message.costShareTransparencyNotes))
                        return "costShareTransparencyNotes: string expected";
                if (message.isProductCovered != null && message.hasOwnProperty("isProductCovered")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isProductCovered);
                    if (error)
                        return "isProductCovered." + error;
                }
                if (message.isProductCoveredMailOrder != null && message.hasOwnProperty("isProductCoveredMailOrder")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isProductCoveredMailOrder);
                    if (error)
                        return "isProductCoveredMailOrder." + error;
                }
                if (message.isProductCoveredRetail != null && message.hasOwnProperty("isProductCoveredRetail")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isProductCoveredRetail);
                    if (error)
                        return "isProductCoveredRetail." + error;
                }
                if (message.mailOrderPharmacyCoinsurance != null && message.hasOwnProperty("mailOrderPharmacyCoinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.mailOrderPharmacyCoinsurance);
                    if (error)
                        return "mailOrderPharmacyCoinsurance." + error;
                }
                if (message.mailOrderPharmacyCopay != null && message.hasOwnProperty("mailOrderPharmacyCopay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.mailOrderPharmacyCopay);
                    if (error)
                        return "mailOrderPharmacyCopay." + error;
                }
                if (message.retailPharmacyCoinsurance != null && message.hasOwnProperty("retailPharmacyCoinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.retailPharmacyCoinsurance);
                    if (error)
                        return "retailPharmacyCoinsurance." + error;
                }
                if (message.retailPharmacyCopay != null && message.hasOwnProperty("retailPharmacyCopay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.retailPharmacyCopay);
                    if (error)
                        return "retailPharmacyCopay." + error;
                }
                if (message.specialtyPharmacyCoinsurance != null && message.hasOwnProperty("specialtyPharmacyCoinsurance")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.specialtyPharmacyCoinsurance);
                    if (error)
                        return "specialtyPharmacyCoinsurance." + error;
                }
                if (message.specialtyPharmacyCopay != null && message.hasOwnProperty("specialtyPharmacyCopay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.specialtyPharmacyCopay);
                    if (error)
                        return "specialtyPharmacyCopay." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPBMTaskOutputProductCoverage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPBMTaskOutputProductCoverage} INFPBMTaskOutputProductCoverage
             */
            INFPBMTaskOutputProductCoverage.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPBMTaskOutputProductCoverage)
                    return object;
                var message = new $root.infinitusapi.INFPBMTaskOutputProductCoverage();
                if (object.code != null)
                    message.code = String(object.code);
                if (object.costShareTransparencyNotes != null)
                    message.costShareTransparencyNotes = String(object.costShareTransparencyNotes);
                if (object.isProductCovered != null) {
                    if (typeof object.isProductCovered !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.isProductCovered: object expected");
                    message.isProductCovered = $root.google.protobuf.BoolValue.fromObject(object.isProductCovered);
                }
                if (object.isProductCoveredMailOrder != null) {
                    if (typeof object.isProductCoveredMailOrder !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.isProductCoveredMailOrder: object expected");
                    message.isProductCoveredMailOrder = $root.google.protobuf.BoolValue.fromObject(object.isProductCoveredMailOrder);
                }
                if (object.isProductCoveredRetail != null) {
                    if (typeof object.isProductCoveredRetail !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.isProductCoveredRetail: object expected");
                    message.isProductCoveredRetail = $root.google.protobuf.BoolValue.fromObject(object.isProductCoveredRetail);
                }
                if (object.mailOrderPharmacyCoinsurance != null) {
                    if (typeof object.mailOrderPharmacyCoinsurance !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.mailOrderPharmacyCoinsurance: object expected");
                    message.mailOrderPharmacyCoinsurance = $root.google.protobuf.DoubleValue.fromObject(object.mailOrderPharmacyCoinsurance);
                }
                if (object.mailOrderPharmacyCopay != null) {
                    if (typeof object.mailOrderPharmacyCopay !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.mailOrderPharmacyCopay: object expected");
                    message.mailOrderPharmacyCopay = $root.google.protobuf.DoubleValue.fromObject(object.mailOrderPharmacyCopay);
                }
                if (object.retailPharmacyCoinsurance != null) {
                    if (typeof object.retailPharmacyCoinsurance !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.retailPharmacyCoinsurance: object expected");
                    message.retailPharmacyCoinsurance = $root.google.protobuf.DoubleValue.fromObject(object.retailPharmacyCoinsurance);
                }
                if (object.retailPharmacyCopay != null) {
                    if (typeof object.retailPharmacyCopay !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.retailPharmacyCopay: object expected");
                    message.retailPharmacyCopay = $root.google.protobuf.DoubleValue.fromObject(object.retailPharmacyCopay);
                }
                if (object.specialtyPharmacyCoinsurance != null) {
                    if (typeof object.specialtyPharmacyCoinsurance !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.specialtyPharmacyCoinsurance: object expected");
                    message.specialtyPharmacyCoinsurance = $root.google.protobuf.DoubleValue.fromObject(object.specialtyPharmacyCoinsurance);
                }
                if (object.specialtyPharmacyCopay != null) {
                    if (typeof object.specialtyPharmacyCopay !== "object")
                        throw TypeError(".infinitusapi.INFPBMTaskOutputProductCoverage.specialtyPharmacyCopay: object expected");
                    message.specialtyPharmacyCopay = $root.google.protobuf.DoubleValue.fromObject(object.specialtyPharmacyCopay);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPBMTaskOutputProductCoverage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {infinitusapi.INFPBMTaskOutputProductCoverage} message INFPBMTaskOutputProductCoverage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPBMTaskOutputProductCoverage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.costShareTransparencyNotes = "";
                    object.isProductCovered = null;
                    object.isProductCoveredMailOrder = null;
                    object.isProductCoveredRetail = null;
                    object.mailOrderPharmacyCoinsurance = null;
                    object.mailOrderPharmacyCopay = null;
                    object.retailPharmacyCoinsurance = null;
                    object.retailPharmacyCopay = null;
                    object.specialtyPharmacyCoinsurance = null;
                    object.specialtyPharmacyCopay = null;
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.costShareTransparencyNotes != null && message.hasOwnProperty("costShareTransparencyNotes"))
                    object.costShareTransparencyNotes = message.costShareTransparencyNotes;
                if (message.isProductCovered != null && message.hasOwnProperty("isProductCovered"))
                    object.isProductCovered = $root.google.protobuf.BoolValue.toObject(message.isProductCovered, options);
                if (message.isProductCoveredMailOrder != null && message.hasOwnProperty("isProductCoveredMailOrder"))
                    object.isProductCoveredMailOrder = $root.google.protobuf.BoolValue.toObject(message.isProductCoveredMailOrder, options);
                if (message.isProductCoveredRetail != null && message.hasOwnProperty("isProductCoveredRetail"))
                    object.isProductCoveredRetail = $root.google.protobuf.BoolValue.toObject(message.isProductCoveredRetail, options);
                if (message.mailOrderPharmacyCoinsurance != null && message.hasOwnProperty("mailOrderPharmacyCoinsurance"))
                    object.mailOrderPharmacyCoinsurance = $root.google.protobuf.DoubleValue.toObject(message.mailOrderPharmacyCoinsurance, options);
                if (message.mailOrderPharmacyCopay != null && message.hasOwnProperty("mailOrderPharmacyCopay"))
                    object.mailOrderPharmacyCopay = $root.google.protobuf.DoubleValue.toObject(message.mailOrderPharmacyCopay, options);
                if (message.retailPharmacyCoinsurance != null && message.hasOwnProperty("retailPharmacyCoinsurance"))
                    object.retailPharmacyCoinsurance = $root.google.protobuf.DoubleValue.toObject(message.retailPharmacyCoinsurance, options);
                if (message.retailPharmacyCopay != null && message.hasOwnProperty("retailPharmacyCopay"))
                    object.retailPharmacyCopay = $root.google.protobuf.DoubleValue.toObject(message.retailPharmacyCopay, options);
                if (message.specialtyPharmacyCoinsurance != null && message.hasOwnProperty("specialtyPharmacyCoinsurance"))
                    object.specialtyPharmacyCoinsurance = $root.google.protobuf.DoubleValue.toObject(message.specialtyPharmacyCoinsurance, options);
                if (message.specialtyPharmacyCopay != null && message.hasOwnProperty("specialtyPharmacyCopay"))
                    object.specialtyPharmacyCopay = $root.google.protobuf.DoubleValue.toObject(message.specialtyPharmacyCopay, options);
                return object;
            };
    
            /**
             * Converts this INFPBMTaskOutputProductCoverage to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPBMTaskOutputProductCoverage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPBMTaskOutputProductCoverage
             * @function getTypeUrl
             * @memberof infinitusapi.INFPBMTaskOutputProductCoverage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPBMTaskOutputProductCoverage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPBMTaskOutputProductCoverage";
            };
    
            return INFPBMTaskOutputProductCoverage;
        })();
    
        infinitusapi.INFPrescriptionSavingsCallee = (function() {
    
            /**
             * Properties of a INFPrescriptionSavingsCallee.
             * @memberof infinitusapi
             * @interface IINFPrescriptionSavingsCallee
             * @property {string|null} [name] INFPrescriptionSavingsCallee name
             * @property {string|null} [phoneNumber] INFPrescriptionSavingsCallee phoneNumber
             */
    
            /**
             * Constructs a new INFPrescriptionSavingsCallee.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionSavingsCallee.
             * @implements IINFPrescriptionSavingsCallee
             * @constructor
             * @param {infinitusapi.IINFPrescriptionSavingsCallee=} [properties] Properties to set
             */
            function INFPrescriptionSavingsCallee(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionSavingsCallee name.
             * @member {string} name
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @instance
             */
            INFPrescriptionSavingsCallee.prototype.name = "";
    
            /**
             * INFPrescriptionSavingsCallee phoneNumber.
             * @member {string} phoneNumber
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @instance
             */
            INFPrescriptionSavingsCallee.prototype.phoneNumber = "";
    
            /**
             * Creates a new INFPrescriptionSavingsCallee instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsCallee=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionSavingsCallee} INFPrescriptionSavingsCallee instance
             */
            INFPrescriptionSavingsCallee.create = function create(properties) {
                return new INFPrescriptionSavingsCallee(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsCallee message. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsCallee.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsCallee} message INFPrescriptionSavingsCallee message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsCallee.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.phoneNumber);
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsCallee message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsCallee.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsCallee} message INFPrescriptionSavingsCallee message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsCallee.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionSavingsCallee message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionSavingsCallee} INFPrescriptionSavingsCallee
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsCallee.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionSavingsCallee();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.phoneNumber = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionSavingsCallee message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionSavingsCallee} INFPrescriptionSavingsCallee
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsCallee.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionSavingsCallee message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionSavingsCallee.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    if (!$util.isString(message.phoneNumber))
                        return "phoneNumber: string expected";
                return null;
            };
    
            /**
             * Creates a INFPrescriptionSavingsCallee message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionSavingsCallee} INFPrescriptionSavingsCallee
             */
            INFPrescriptionSavingsCallee.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionSavingsCallee)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionSavingsCallee();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phoneNumber != null)
                    message.phoneNumber = String(object.phoneNumber);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionSavingsCallee message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {infinitusapi.INFPrescriptionSavingsCallee} message INFPrescriptionSavingsCallee
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionSavingsCallee.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.phoneNumber = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = message.phoneNumber;
                return object;
            };
    
            /**
             * Converts this INFPrescriptionSavingsCallee to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionSavingsCallee.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionSavingsCallee
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionSavingsCallee
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionSavingsCallee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionSavingsCallee";
            };
    
            return INFPrescriptionSavingsCallee;
        })();
    
        infinitusapi.INFPrescriptionSavingsPatient = (function() {
    
            /**
             * Properties of a INFPrescriptionSavingsPatient.
             * @memberof infinitusapi
             * @interface IINFPrescriptionSavingsPatient
             * @property {string|null} [dateOfBirth] INFPrescriptionSavingsPatient dateOfBirth
             * @property {string|null} [firstName] INFPrescriptionSavingsPatient firstName
             * @property {string|null} [lastName] INFPrescriptionSavingsPatient lastName
             */
    
            /**
             * Constructs a new INFPrescriptionSavingsPatient.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionSavingsPatient.
             * @implements IINFPrescriptionSavingsPatient
             * @constructor
             * @param {infinitusapi.IINFPrescriptionSavingsPatient=} [properties] Properties to set
             */
            function INFPrescriptionSavingsPatient(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionSavingsPatient dateOfBirth.
             * @member {string} dateOfBirth
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @instance
             */
            INFPrescriptionSavingsPatient.prototype.dateOfBirth = "";
    
            /**
             * INFPrescriptionSavingsPatient firstName.
             * @member {string} firstName
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @instance
             */
            INFPrescriptionSavingsPatient.prototype.firstName = "";
    
            /**
             * INFPrescriptionSavingsPatient lastName.
             * @member {string} lastName
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @instance
             */
            INFPrescriptionSavingsPatient.prototype.lastName = "";
    
            /**
             * Creates a new INFPrescriptionSavingsPatient instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsPatient=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionSavingsPatient} INFPrescriptionSavingsPatient instance
             */
            INFPrescriptionSavingsPatient.create = function create(properties) {
                return new INFPrescriptionSavingsPatient(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsPatient message. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsPatient.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsPatient} message INFPrescriptionSavingsPatient message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsPatient.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.dateOfBirth);
                if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsPatient message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsPatient.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsPatient} message INFPrescriptionSavingsPatient message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsPatient.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionSavingsPatient message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionSavingsPatient} INFPrescriptionSavingsPatient
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsPatient.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionSavingsPatient();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dateOfBirth = reader.string();
                            break;
                        }
                    case 2: {
                            message.firstName = reader.string();
                            break;
                        }
                    case 3: {
                            message.lastName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionSavingsPatient message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionSavingsPatient} INFPrescriptionSavingsPatient
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsPatient.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionSavingsPatient message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionSavingsPatient.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                    if (!$util.isString(message.dateOfBirth))
                        return "dateOfBirth: string expected";
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    if (!$util.isString(message.firstName))
                        return "firstName: string expected";
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    if (!$util.isString(message.lastName))
                        return "lastName: string expected";
                return null;
            };
    
            /**
             * Creates a INFPrescriptionSavingsPatient message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionSavingsPatient} INFPrescriptionSavingsPatient
             */
            INFPrescriptionSavingsPatient.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionSavingsPatient)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionSavingsPatient();
                if (object.dateOfBirth != null)
                    message.dateOfBirth = String(object.dateOfBirth);
                if (object.firstName != null)
                    message.firstName = String(object.firstName);
                if (object.lastName != null)
                    message.lastName = String(object.lastName);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionSavingsPatient message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {infinitusapi.INFPrescriptionSavingsPatient} message INFPrescriptionSavingsPatient
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionSavingsPatient.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.dateOfBirth = "";
                    object.firstName = "";
                    object.lastName = "";
                }
                if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                    object.dateOfBirth = message.dateOfBirth;
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    object.firstName = message.firstName;
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    object.lastName = message.lastName;
                return object;
            };
    
            /**
             * Converts this INFPrescriptionSavingsPatient to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionSavingsPatient.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionSavingsPatient
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionSavingsPatient
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionSavingsPatient.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionSavingsPatient";
            };
    
            return INFPrescriptionSavingsPatient;
        })();
    
        infinitusapi.INFPrescriptionSavingsPlanInfo = (function() {
    
            /**
             * Properties of a INFPrescriptionSavingsPlanInfo.
             * @memberof infinitusapi
             * @interface IINFPrescriptionSavingsPlanInfo
             * @property {string|null} [bankIdentificationNumber] INFPrescriptionSavingsPlanInfo bankIdentificationNumber
             * @property {string|null} [groupName] INFPrescriptionSavingsPlanInfo groupName
             * @property {string|null} [groupNumber] INFPrescriptionSavingsPlanInfo groupNumber
             * @property {string|null} [payerName] INFPrescriptionSavingsPlanInfo payerName
             * @property {string|null} [planName] INFPrescriptionSavingsPlanInfo planName
             * @property {string|null} [processorControlNumber] INFPrescriptionSavingsPlanInfo processorControlNumber
             */
    
            /**
             * Constructs a new INFPrescriptionSavingsPlanInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionSavingsPlanInfo.
             * @implements IINFPrescriptionSavingsPlanInfo
             * @constructor
             * @param {infinitusapi.IINFPrescriptionSavingsPlanInfo=} [properties] Properties to set
             */
            function INFPrescriptionSavingsPlanInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionSavingsPlanInfo bankIdentificationNumber.
             * @member {string} bankIdentificationNumber
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @instance
             */
            INFPrescriptionSavingsPlanInfo.prototype.bankIdentificationNumber = "";
    
            /**
             * INFPrescriptionSavingsPlanInfo groupName.
             * @member {string} groupName
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @instance
             */
            INFPrescriptionSavingsPlanInfo.prototype.groupName = "";
    
            /**
             * INFPrescriptionSavingsPlanInfo groupNumber.
             * @member {string} groupNumber
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @instance
             */
            INFPrescriptionSavingsPlanInfo.prototype.groupNumber = "";
    
            /**
             * INFPrescriptionSavingsPlanInfo payerName.
             * @member {string} payerName
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @instance
             */
            INFPrescriptionSavingsPlanInfo.prototype.payerName = "";
    
            /**
             * INFPrescriptionSavingsPlanInfo planName.
             * @member {string} planName
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @instance
             */
            INFPrescriptionSavingsPlanInfo.prototype.planName = "";
    
            /**
             * INFPrescriptionSavingsPlanInfo processorControlNumber.
             * @member {string} processorControlNumber
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @instance
             */
            INFPrescriptionSavingsPlanInfo.prototype.processorControlNumber = "";
    
            /**
             * Creates a new INFPrescriptionSavingsPlanInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsPlanInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionSavingsPlanInfo} INFPrescriptionSavingsPlanInfo instance
             */
            INFPrescriptionSavingsPlanInfo.create = function create(properties) {
                return new INFPrescriptionSavingsPlanInfo(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsPlanInfo message. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsPlanInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsPlanInfo} message INFPrescriptionSavingsPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsPlanInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bankIdentificationNumber != null && Object.hasOwnProperty.call(message, "bankIdentificationNumber"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.bankIdentificationNumber);
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.groupName);
                if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.groupNumber);
                if (message.payerName != null && Object.hasOwnProperty.call(message, "payerName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.payerName);
                if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.planName);
                if (message.processorControlNumber != null && Object.hasOwnProperty.call(message, "processorControlNumber"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.processorControlNumber);
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsPlanInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsPlanInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsPlanInfo} message INFPrescriptionSavingsPlanInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsPlanInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionSavingsPlanInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionSavingsPlanInfo} INFPrescriptionSavingsPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsPlanInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionSavingsPlanInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.bankIdentificationNumber = reader.string();
                            break;
                        }
                    case 2: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 3: {
                            message.groupNumber = reader.string();
                            break;
                        }
                    case 4: {
                            message.payerName = reader.string();
                            break;
                        }
                    case 5: {
                            message.planName = reader.string();
                            break;
                        }
                    case 6: {
                            message.processorControlNumber = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionSavingsPlanInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionSavingsPlanInfo} INFPrescriptionSavingsPlanInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsPlanInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionSavingsPlanInfo message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionSavingsPlanInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.bankIdentificationNumber != null && message.hasOwnProperty("bankIdentificationNumber"))
                    if (!$util.isString(message.bankIdentificationNumber))
                        return "bankIdentificationNumber: string expected";
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                    if (!$util.isString(message.groupNumber))
                        return "groupNumber: string expected";
                if (message.payerName != null && message.hasOwnProperty("payerName"))
                    if (!$util.isString(message.payerName))
                        return "payerName: string expected";
                if (message.planName != null && message.hasOwnProperty("planName"))
                    if (!$util.isString(message.planName))
                        return "planName: string expected";
                if (message.processorControlNumber != null && message.hasOwnProperty("processorControlNumber"))
                    if (!$util.isString(message.processorControlNumber))
                        return "processorControlNumber: string expected";
                return null;
            };
    
            /**
             * Creates a INFPrescriptionSavingsPlanInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionSavingsPlanInfo} INFPrescriptionSavingsPlanInfo
             */
            INFPrescriptionSavingsPlanInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionSavingsPlanInfo)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionSavingsPlanInfo();
                if (object.bankIdentificationNumber != null)
                    message.bankIdentificationNumber = String(object.bankIdentificationNumber);
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.groupNumber != null)
                    message.groupNumber = String(object.groupNumber);
                if (object.payerName != null)
                    message.payerName = String(object.payerName);
                if (object.planName != null)
                    message.planName = String(object.planName);
                if (object.processorControlNumber != null)
                    message.processorControlNumber = String(object.processorControlNumber);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionSavingsPlanInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {infinitusapi.INFPrescriptionSavingsPlanInfo} message INFPrescriptionSavingsPlanInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionSavingsPlanInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.bankIdentificationNumber = "";
                    object.groupName = "";
                    object.groupNumber = "";
                    object.payerName = "";
                    object.planName = "";
                    object.processorControlNumber = "";
                }
                if (message.bankIdentificationNumber != null && message.hasOwnProperty("bankIdentificationNumber"))
                    object.bankIdentificationNumber = message.bankIdentificationNumber;
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.groupNumber != null && message.hasOwnProperty("groupNumber"))
                    object.groupNumber = message.groupNumber;
                if (message.payerName != null && message.hasOwnProperty("payerName"))
                    object.payerName = message.payerName;
                if (message.planName != null && message.hasOwnProperty("planName"))
                    object.planName = message.planName;
                if (message.processorControlNumber != null && message.hasOwnProperty("processorControlNumber"))
                    object.processorControlNumber = message.processorControlNumber;
                return object;
            };
    
            /**
             * Converts this INFPrescriptionSavingsPlanInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionSavingsPlanInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionSavingsPlanInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionSavingsPlanInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionSavingsPlanInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionSavingsPlanInfo";
            };
    
            return INFPrescriptionSavingsPlanInfo;
        })();
    
        infinitusapi.INFPrescriptionSavingsProduct = (function() {
    
            /**
             * Properties of a INFPrescriptionSavingsProduct.
             * @memberof infinitusapi
             * @interface IINFPrescriptionSavingsProduct
             * @property {string|null} [code] INFPrescriptionSavingsProduct code
             * @property {string|null} [dosage] INFPrescriptionSavingsProduct dosage
             * @property {google.protobuf.IDoubleValue|null} [expectedCopayAfterSavings] INFPrescriptionSavingsProduct expectedCopayAfterSavings
             * @property {string|null} [name] INFPrescriptionSavingsProduct name
             * @property {google.protobuf.IDoubleValue|null} [originalCopay] INFPrescriptionSavingsProduct originalCopay
             * @property {string|null} [savingsCardId] INFPrescriptionSavingsProduct savingsCardId
             * @property {string|null} [savingsCardName] INFPrescriptionSavingsProduct savingsCardName
             */
    
            /**
             * Constructs a new INFPrescriptionSavingsProduct.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionSavingsProduct.
             * @implements IINFPrescriptionSavingsProduct
             * @constructor
             * @param {infinitusapi.IINFPrescriptionSavingsProduct=} [properties] Properties to set
             */
            function INFPrescriptionSavingsProduct(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionSavingsProduct code.
             * @member {string} code
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @instance
             */
            INFPrescriptionSavingsProduct.prototype.code = "";
    
            /**
             * INFPrescriptionSavingsProduct dosage.
             * @member {string} dosage
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @instance
             */
            INFPrescriptionSavingsProduct.prototype.dosage = "";
    
            /**
             * INFPrescriptionSavingsProduct expectedCopayAfterSavings.
             * @member {google.protobuf.IDoubleValue|null|undefined} expectedCopayAfterSavings
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @instance
             */
            INFPrescriptionSavingsProduct.prototype.expectedCopayAfterSavings = null;
    
            /**
             * INFPrescriptionSavingsProduct name.
             * @member {string} name
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @instance
             */
            INFPrescriptionSavingsProduct.prototype.name = "";
    
            /**
             * INFPrescriptionSavingsProduct originalCopay.
             * @member {google.protobuf.IDoubleValue|null|undefined} originalCopay
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @instance
             */
            INFPrescriptionSavingsProduct.prototype.originalCopay = null;
    
            /**
             * INFPrescriptionSavingsProduct savingsCardId.
             * @member {string} savingsCardId
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @instance
             */
            INFPrescriptionSavingsProduct.prototype.savingsCardId = "";
    
            /**
             * INFPrescriptionSavingsProduct savingsCardName.
             * @member {string} savingsCardName
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @instance
             */
            INFPrescriptionSavingsProduct.prototype.savingsCardName = "";
    
            /**
             * Creates a new INFPrescriptionSavingsProduct instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsProduct=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionSavingsProduct} INFPrescriptionSavingsProduct instance
             */
            INFPrescriptionSavingsProduct.create = function create(properties) {
                return new INFPrescriptionSavingsProduct(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsProduct message. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsProduct.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsProduct} message INFPrescriptionSavingsProduct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsProduct.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                if (message.dosage != null && Object.hasOwnProperty.call(message, "dosage"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.dosage);
                if (message.expectedCopayAfterSavings != null && Object.hasOwnProperty.call(message, "expectedCopayAfterSavings"))
                    $root.google.protobuf.DoubleValue.encode(message.expectedCopayAfterSavings, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                if (message.originalCopay != null && Object.hasOwnProperty.call(message, "originalCopay"))
                    $root.google.protobuf.DoubleValue.encode(message.originalCopay, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.savingsCardId != null && Object.hasOwnProperty.call(message, "savingsCardId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.savingsCardId);
                if (message.savingsCardName != null && Object.hasOwnProperty.call(message, "savingsCardName"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.savingsCardName);
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsProduct message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsProduct.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsProduct} message INFPrescriptionSavingsProduct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsProduct.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionSavingsProduct message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionSavingsProduct} INFPrescriptionSavingsProduct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsProduct.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionSavingsProduct();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    case 2: {
                            message.dosage = reader.string();
                            break;
                        }
                    case 3: {
                            message.expectedCopayAfterSavings = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.name = reader.string();
                            break;
                        }
                    case 5: {
                            message.originalCopay = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.savingsCardId = reader.string();
                            break;
                        }
                    case 7: {
                            message.savingsCardName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionSavingsProduct message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionSavingsProduct} INFPrescriptionSavingsProduct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsProduct.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionSavingsProduct message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionSavingsProduct.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.dosage != null && message.hasOwnProperty("dosage"))
                    if (!$util.isString(message.dosage))
                        return "dosage: string expected";
                if (message.expectedCopayAfterSavings != null && message.hasOwnProperty("expectedCopayAfterSavings")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.expectedCopayAfterSavings);
                    if (error)
                        return "expectedCopayAfterSavings." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.originalCopay != null && message.hasOwnProperty("originalCopay")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.originalCopay);
                    if (error)
                        return "originalCopay." + error;
                }
                if (message.savingsCardId != null && message.hasOwnProperty("savingsCardId"))
                    if (!$util.isString(message.savingsCardId))
                        return "savingsCardId: string expected";
                if (message.savingsCardName != null && message.hasOwnProperty("savingsCardName"))
                    if (!$util.isString(message.savingsCardName))
                        return "savingsCardName: string expected";
                return null;
            };
    
            /**
             * Creates a INFPrescriptionSavingsProduct message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionSavingsProduct} INFPrescriptionSavingsProduct
             */
            INFPrescriptionSavingsProduct.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionSavingsProduct)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionSavingsProduct();
                if (object.code != null)
                    message.code = String(object.code);
                if (object.dosage != null)
                    message.dosage = String(object.dosage);
                if (object.expectedCopayAfterSavings != null) {
                    if (typeof object.expectedCopayAfterSavings !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsProduct.expectedCopayAfterSavings: object expected");
                    message.expectedCopayAfterSavings = $root.google.protobuf.DoubleValue.fromObject(object.expectedCopayAfterSavings);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.originalCopay != null) {
                    if (typeof object.originalCopay !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsProduct.originalCopay: object expected");
                    message.originalCopay = $root.google.protobuf.DoubleValue.fromObject(object.originalCopay);
                }
                if (object.savingsCardId != null)
                    message.savingsCardId = String(object.savingsCardId);
                if (object.savingsCardName != null)
                    message.savingsCardName = String(object.savingsCardName);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionSavingsProduct message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {infinitusapi.INFPrescriptionSavingsProduct} message INFPrescriptionSavingsProduct
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionSavingsProduct.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.dosage = "";
                    object.expectedCopayAfterSavings = null;
                    object.name = "";
                    object.originalCopay = null;
                    object.savingsCardId = "";
                    object.savingsCardName = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.dosage != null && message.hasOwnProperty("dosage"))
                    object.dosage = message.dosage;
                if (message.expectedCopayAfterSavings != null && message.hasOwnProperty("expectedCopayAfterSavings"))
                    object.expectedCopayAfterSavings = $root.google.protobuf.DoubleValue.toObject(message.expectedCopayAfterSavings, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.originalCopay != null && message.hasOwnProperty("originalCopay"))
                    object.originalCopay = $root.google.protobuf.DoubleValue.toObject(message.originalCopay, options);
                if (message.savingsCardId != null && message.hasOwnProperty("savingsCardId"))
                    object.savingsCardId = message.savingsCardId;
                if (message.savingsCardName != null && message.hasOwnProperty("savingsCardName"))
                    object.savingsCardName = message.savingsCardName;
                return object;
            };
    
            /**
             * Converts this INFPrescriptionSavingsProduct to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionSavingsProduct.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionSavingsProduct
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionSavingsProduct
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionSavingsProduct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionSavingsProduct";
            };
    
            return INFPrescriptionSavingsProduct;
        })();
    
        infinitusapi.INFPrescriptionSavingsTaskInput = (function() {
    
            /**
             * Properties of a INFPrescriptionSavingsTaskInput.
             * @memberof infinitusapi
             * @interface IINFPrescriptionSavingsTaskInput
             * @property {infinitusapi.IINFPrescriptionSavingsCallee|null} [callee] INFPrescriptionSavingsTaskInput callee
             * @property {infinitusapi.IINFPrescriptionSavingsPatient|null} [patient] INFPrescriptionSavingsTaskInput patient
             * @property {infinitusapi.IINFPrescriptionSavingsPlanInfo|null} [planInfo] INFPrescriptionSavingsTaskInput planInfo
             * @property {Array.<infinitusapi.IINFPrescriptionSavingsProduct>|null} [products] INFPrescriptionSavingsTaskInput products
             */
    
            /**
             * Constructs a new INFPrescriptionSavingsTaskInput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionSavingsTaskInput.
             * @implements IINFPrescriptionSavingsTaskInput
             * @constructor
             * @param {infinitusapi.IINFPrescriptionSavingsTaskInput=} [properties] Properties to set
             */
            function INFPrescriptionSavingsTaskInput(properties) {
                this.products = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionSavingsTaskInput callee.
             * @member {infinitusapi.IINFPrescriptionSavingsCallee|null|undefined} callee
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @instance
             */
            INFPrescriptionSavingsTaskInput.prototype.callee = null;
    
            /**
             * INFPrescriptionSavingsTaskInput patient.
             * @member {infinitusapi.IINFPrescriptionSavingsPatient|null|undefined} patient
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @instance
             */
            INFPrescriptionSavingsTaskInput.prototype.patient = null;
    
            /**
             * INFPrescriptionSavingsTaskInput planInfo.
             * @member {infinitusapi.IINFPrescriptionSavingsPlanInfo|null|undefined} planInfo
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @instance
             */
            INFPrescriptionSavingsTaskInput.prototype.planInfo = null;
    
            /**
             * INFPrescriptionSavingsTaskInput products.
             * @member {Array.<infinitusapi.IINFPrescriptionSavingsProduct>} products
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @instance
             */
            INFPrescriptionSavingsTaskInput.prototype.products = $util.emptyArray;
    
            /**
             * Creates a new INFPrescriptionSavingsTaskInput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskInput=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionSavingsTaskInput} INFPrescriptionSavingsTaskInput instance
             */
            INFPrescriptionSavingsTaskInput.create = function create(properties) {
                return new INFPrescriptionSavingsTaskInput(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsTaskInput message. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsTaskInput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskInput} message INFPrescriptionSavingsTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsTaskInput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.callee != null && Object.hasOwnProperty.call(message, "callee"))
                    $root.infinitusapi.INFPrescriptionSavingsCallee.encode(message.callee, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.patient != null && Object.hasOwnProperty.call(message, "patient"))
                    $root.infinitusapi.INFPrescriptionSavingsPatient.encode(message.patient, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.planInfo != null && Object.hasOwnProperty.call(message, "planInfo"))
                    $root.infinitusapi.INFPrescriptionSavingsPlanInfo.encode(message.planInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.products != null && message.products.length)
                    for (var i = 0; i < message.products.length; ++i)
                        $root.infinitusapi.INFPrescriptionSavingsProduct.encode(message.products[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsTaskInput message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsTaskInput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskInput} message INFPrescriptionSavingsTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsTaskInput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionSavingsTaskInput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionSavingsTaskInput} INFPrescriptionSavingsTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsTaskInput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionSavingsTaskInput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.callee = $root.infinitusapi.INFPrescriptionSavingsCallee.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.patient = $root.infinitusapi.INFPrescriptionSavingsPatient.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.planInfo = $root.infinitusapi.INFPrescriptionSavingsPlanInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            if (!(message.products && message.products.length))
                                message.products = [];
                            message.products.push($root.infinitusapi.INFPrescriptionSavingsProduct.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionSavingsTaskInput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionSavingsTaskInput} INFPrescriptionSavingsTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsTaskInput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionSavingsTaskInput message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionSavingsTaskInput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.callee != null && message.hasOwnProperty("callee")) {
                    var error = $root.infinitusapi.INFPrescriptionSavingsCallee.verify(message.callee);
                    if (error)
                        return "callee." + error;
                }
                if (message.patient != null && message.hasOwnProperty("patient")) {
                    var error = $root.infinitusapi.INFPrescriptionSavingsPatient.verify(message.patient);
                    if (error)
                        return "patient." + error;
                }
                if (message.planInfo != null && message.hasOwnProperty("planInfo")) {
                    var error = $root.infinitusapi.INFPrescriptionSavingsPlanInfo.verify(message.planInfo);
                    if (error)
                        return "planInfo." + error;
                }
                if (message.products != null && message.hasOwnProperty("products")) {
                    if (!Array.isArray(message.products))
                        return "products: array expected";
                    for (var i = 0; i < message.products.length; ++i) {
                        var error = $root.infinitusapi.INFPrescriptionSavingsProduct.verify(message.products[i]);
                        if (error)
                            return "products." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a INFPrescriptionSavingsTaskInput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionSavingsTaskInput} INFPrescriptionSavingsTaskInput
             */
            INFPrescriptionSavingsTaskInput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionSavingsTaskInput)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionSavingsTaskInput();
                if (object.callee != null) {
                    if (typeof object.callee !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskInput.callee: object expected");
                    message.callee = $root.infinitusapi.INFPrescriptionSavingsCallee.fromObject(object.callee);
                }
                if (object.patient != null) {
                    if (typeof object.patient !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskInput.patient: object expected");
                    message.patient = $root.infinitusapi.INFPrescriptionSavingsPatient.fromObject(object.patient);
                }
                if (object.planInfo != null) {
                    if (typeof object.planInfo !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskInput.planInfo: object expected");
                    message.planInfo = $root.infinitusapi.INFPrescriptionSavingsPlanInfo.fromObject(object.planInfo);
                }
                if (object.products) {
                    if (!Array.isArray(object.products))
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskInput.products: array expected");
                    message.products = [];
                    for (var i = 0; i < object.products.length; ++i) {
                        if (typeof object.products[i] !== "object")
                            throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskInput.products: object expected");
                        message.products[i] = $root.infinitusapi.INFPrescriptionSavingsProduct.fromObject(object.products[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionSavingsTaskInput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {infinitusapi.INFPrescriptionSavingsTaskInput} message INFPrescriptionSavingsTaskInput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionSavingsTaskInput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.products = [];
                if (options.defaults) {
                    object.callee = null;
                    object.patient = null;
                    object.planInfo = null;
                }
                if (message.callee != null && message.hasOwnProperty("callee"))
                    object.callee = $root.infinitusapi.INFPrescriptionSavingsCallee.toObject(message.callee, options);
                if (message.patient != null && message.hasOwnProperty("patient"))
                    object.patient = $root.infinitusapi.INFPrescriptionSavingsPatient.toObject(message.patient, options);
                if (message.planInfo != null && message.hasOwnProperty("planInfo"))
                    object.planInfo = $root.infinitusapi.INFPrescriptionSavingsPlanInfo.toObject(message.planInfo, options);
                if (message.products && message.products.length) {
                    object.products = [];
                    for (var j = 0; j < message.products.length; ++j)
                        object.products[j] = $root.infinitusapi.INFPrescriptionSavingsProduct.toObject(message.products[j], options);
                }
                return object;
            };
    
            /**
             * Converts this INFPrescriptionSavingsTaskInput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionSavingsTaskInput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionSavingsTaskInput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionSavingsTaskInput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionSavingsTaskInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionSavingsTaskInput";
            };
    
            return INFPrescriptionSavingsTaskInput;
        })();
    
        infinitusapi.INFPrescriptionSavingsTaskOutput = (function() {
    
            /**
             * Properties of a INFPrescriptionSavingsTaskOutput.
             * @memberof infinitusapi
             * @interface IINFPrescriptionSavingsTaskOutput
             * @property {infinitusapi.IINFTaskOutputErrorInfo|null} [errorInfo] INFPrescriptionSavingsTaskOutput errorInfo
             * @property {google.protobuf.IBoolValue|null} [patientOnFileConfirmation] INFPrescriptionSavingsTaskOutput patientOnFileConfirmation
             * @property {Array.<infinitusapi.IINFPrescriptionSavingsTaskOutputProduct>|null} [products] INFPrescriptionSavingsTaskOutput products
             * @property {google.protobuf.IBoolValue|null} [reviewRequired] INFPrescriptionSavingsTaskOutput reviewRequired
             * @property {string|null} [taskLink] INFPrescriptionSavingsTaskOutput taskLink
             */
    
            /**
             * Constructs a new INFPrescriptionSavingsTaskOutput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionSavingsTaskOutput.
             * @implements IINFPrescriptionSavingsTaskOutput
             * @constructor
             * @param {infinitusapi.IINFPrescriptionSavingsTaskOutput=} [properties] Properties to set
             */
            function INFPrescriptionSavingsTaskOutput(properties) {
                this.products = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionSavingsTaskOutput errorInfo.
             * @member {infinitusapi.IINFTaskOutputErrorInfo|null|undefined} errorInfo
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @instance
             */
            INFPrescriptionSavingsTaskOutput.prototype.errorInfo = null;
    
            /**
             * INFPrescriptionSavingsTaskOutput patientOnFileConfirmation.
             * @member {google.protobuf.IBoolValue|null|undefined} patientOnFileConfirmation
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @instance
             */
            INFPrescriptionSavingsTaskOutput.prototype.patientOnFileConfirmation = null;
    
            /**
             * INFPrescriptionSavingsTaskOutput products.
             * @member {Array.<infinitusapi.IINFPrescriptionSavingsTaskOutputProduct>} products
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @instance
             */
            INFPrescriptionSavingsTaskOutput.prototype.products = $util.emptyArray;
    
            /**
             * INFPrescriptionSavingsTaskOutput reviewRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} reviewRequired
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @instance
             */
            INFPrescriptionSavingsTaskOutput.prototype.reviewRequired = null;
    
            /**
             * INFPrescriptionSavingsTaskOutput taskLink.
             * @member {string} taskLink
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @instance
             */
            INFPrescriptionSavingsTaskOutput.prototype.taskLink = "";
    
            /**
             * Creates a new INFPrescriptionSavingsTaskOutput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskOutput=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionSavingsTaskOutput} INFPrescriptionSavingsTaskOutput instance
             */
            INFPrescriptionSavingsTaskOutput.create = function create(properties) {
                return new INFPrescriptionSavingsTaskOutput(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsTaskOutput message. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsTaskOutput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskOutput} message INFPrescriptionSavingsTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsTaskOutput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.errorInfo != null && Object.hasOwnProperty.call(message, "errorInfo"))
                    $root.infinitusapi.INFTaskOutputErrorInfo.encode(message.errorInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.patientOnFileConfirmation != null && Object.hasOwnProperty.call(message, "patientOnFileConfirmation"))
                    $root.google.protobuf.BoolValue.encode(message.patientOnFileConfirmation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.products != null && message.products.length)
                    for (var i = 0; i < message.products.length; ++i)
                        $root.infinitusapi.INFPrescriptionSavingsTaskOutputProduct.encode(message.products[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.reviewRequired != null && Object.hasOwnProperty.call(message, "reviewRequired"))
                    $root.google.protobuf.BoolValue.encode(message.reviewRequired, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.taskLink != null && Object.hasOwnProperty.call(message, "taskLink"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.taskLink);
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsTaskOutput message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsTaskOutput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskOutput} message INFPrescriptionSavingsTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsTaskOutput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionSavingsTaskOutput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionSavingsTaskOutput} INFPrescriptionSavingsTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsTaskOutput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionSavingsTaskOutput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.errorInfo = $root.infinitusapi.INFTaskOutputErrorInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.patientOnFileConfirmation = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            if (!(message.products && message.products.length))
                                message.products = [];
                            message.products.push($root.infinitusapi.INFPrescriptionSavingsTaskOutputProduct.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            message.reviewRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.taskLink = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionSavingsTaskOutput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionSavingsTaskOutput} INFPrescriptionSavingsTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsTaskOutput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionSavingsTaskOutput message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionSavingsTaskOutput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo")) {
                    var error = $root.infinitusapi.INFTaskOutputErrorInfo.verify(message.errorInfo);
                    if (error)
                        return "errorInfo." + error;
                }
                if (message.patientOnFileConfirmation != null && message.hasOwnProperty("patientOnFileConfirmation")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.patientOnFileConfirmation);
                    if (error)
                        return "patientOnFileConfirmation." + error;
                }
                if (message.products != null && message.hasOwnProperty("products")) {
                    if (!Array.isArray(message.products))
                        return "products: array expected";
                    for (var i = 0; i < message.products.length; ++i) {
                        var error = $root.infinitusapi.INFPrescriptionSavingsTaskOutputProduct.verify(message.products[i]);
                        if (error)
                            return "products." + error;
                    }
                }
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.reviewRequired);
                    if (error)
                        return "reviewRequired." + error;
                }
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    if (!$util.isString(message.taskLink))
                        return "taskLink: string expected";
                return null;
            };
    
            /**
             * Creates a INFPrescriptionSavingsTaskOutput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionSavingsTaskOutput} INFPrescriptionSavingsTaskOutput
             */
            INFPrescriptionSavingsTaskOutput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionSavingsTaskOutput)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionSavingsTaskOutput();
                if (object.errorInfo != null) {
                    if (typeof object.errorInfo !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutput.errorInfo: object expected");
                    message.errorInfo = $root.infinitusapi.INFTaskOutputErrorInfo.fromObject(object.errorInfo);
                }
                if (object.patientOnFileConfirmation != null) {
                    if (typeof object.patientOnFileConfirmation !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutput.patientOnFileConfirmation: object expected");
                    message.patientOnFileConfirmation = $root.google.protobuf.BoolValue.fromObject(object.patientOnFileConfirmation);
                }
                if (object.products) {
                    if (!Array.isArray(object.products))
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutput.products: array expected");
                    message.products = [];
                    for (var i = 0; i < object.products.length; ++i) {
                        if (typeof object.products[i] !== "object")
                            throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutput.products: object expected");
                        message.products[i] = $root.infinitusapi.INFPrescriptionSavingsTaskOutputProduct.fromObject(object.products[i]);
                    }
                }
                if (object.reviewRequired != null) {
                    if (typeof object.reviewRequired !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutput.reviewRequired: object expected");
                    message.reviewRequired = $root.google.protobuf.BoolValue.fromObject(object.reviewRequired);
                }
                if (object.taskLink != null)
                    message.taskLink = String(object.taskLink);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionSavingsTaskOutput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {infinitusapi.INFPrescriptionSavingsTaskOutput} message INFPrescriptionSavingsTaskOutput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionSavingsTaskOutput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.products = [];
                if (options.defaults) {
                    object.errorInfo = null;
                    object.patientOnFileConfirmation = null;
                    object.reviewRequired = null;
                    object.taskLink = "";
                }
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo"))
                    object.errorInfo = $root.infinitusapi.INFTaskOutputErrorInfo.toObject(message.errorInfo, options);
                if (message.patientOnFileConfirmation != null && message.hasOwnProperty("patientOnFileConfirmation"))
                    object.patientOnFileConfirmation = $root.google.protobuf.BoolValue.toObject(message.patientOnFileConfirmation, options);
                if (message.products && message.products.length) {
                    object.products = [];
                    for (var j = 0; j < message.products.length; ++j)
                        object.products[j] = $root.infinitusapi.INFPrescriptionSavingsTaskOutputProduct.toObject(message.products[j], options);
                }
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired"))
                    object.reviewRequired = $root.google.protobuf.BoolValue.toObject(message.reviewRequired, options);
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    object.taskLink = message.taskLink;
                return object;
            };
    
            /**
             * Converts this INFPrescriptionSavingsTaskOutput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionSavingsTaskOutput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionSavingsTaskOutput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionSavingsTaskOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionSavingsTaskOutput";
            };
    
            return INFPrescriptionSavingsTaskOutput;
        })();
    
        infinitusapi.INFPrescriptionSavingsTaskOutputProduct = (function() {
    
            /**
             * Properties of a INFPrescriptionSavingsTaskOutputProduct.
             * @memberof infinitusapi
             * @interface IINFPrescriptionSavingsTaskOutputProduct
             * @property {string|null} [code] INFPrescriptionSavingsTaskOutputProduct code
             * @property {google.protobuf.IDoubleValue|null} [copayAfterSavings] INFPrescriptionSavingsTaskOutputProduct copayAfterSavings
             * @property {google.protobuf.IBoolValue|null} [isMedicationOnFile] INFPrescriptionSavingsTaskOutputProduct isMedicationOnFile
             * @property {google.protobuf.IBoolValue|null} [isPrescriptionActive] INFPrescriptionSavingsTaskOutputProduct isPrescriptionActive
             * @property {google.protobuf.IDoubleValue|null} [lastRefillCost] INFPrescriptionSavingsTaskOutputProduct lastRefillCost
             * @property {google.protobuf.IStringValue|null} [lastRefillDate] INFPrescriptionSavingsTaskOutputProduct lastRefillDate
             * @property {string|null} [name] INFPrescriptionSavingsTaskOutputProduct name
             * @property {string|null} [outcome] INFPrescriptionSavingsTaskOutputProduct outcome
             * @property {google.protobuf.IStringValue|null} [reasonForNotApplyingSavings] INFPrescriptionSavingsTaskOutputProduct reasonForNotApplyingSavings
             * @property {google.protobuf.IBoolValue|null} [rebilled] INFPrescriptionSavingsTaskOutputProduct rebilled
             * @property {google.protobuf.IStringValue|null} [refillDenialReason] INFPrescriptionSavingsTaskOutputProduct refillDenialReason
             * @property {google.protobuf.IBoolValue|null} [refillRequested] INFPrescriptionSavingsTaskOutputProduct refillRequested
             * @property {google.protobuf.IBoolValue|null} [savingsCardAdded] INFPrescriptionSavingsTaskOutputProduct savingsCardAdded
             * @property {google.protobuf.IBoolValue|null} [savingsCardApplied] INFPrescriptionSavingsTaskOutputProduct savingsCardApplied
             */
    
            /**
             * Constructs a new INFPrescriptionSavingsTaskOutputProduct.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionSavingsTaskOutputProduct.
             * @implements IINFPrescriptionSavingsTaskOutputProduct
             * @constructor
             * @param {infinitusapi.IINFPrescriptionSavingsTaskOutputProduct=} [properties] Properties to set
             */
            function INFPrescriptionSavingsTaskOutputProduct(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct code.
             * @member {string} code
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.code = "";
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct copayAfterSavings.
             * @member {google.protobuf.IDoubleValue|null|undefined} copayAfterSavings
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.copayAfterSavings = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct isMedicationOnFile.
             * @member {google.protobuf.IBoolValue|null|undefined} isMedicationOnFile
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.isMedicationOnFile = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct isPrescriptionActive.
             * @member {google.protobuf.IBoolValue|null|undefined} isPrescriptionActive
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.isPrescriptionActive = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct lastRefillCost.
             * @member {google.protobuf.IDoubleValue|null|undefined} lastRefillCost
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.lastRefillCost = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct lastRefillDate.
             * @member {google.protobuf.IStringValue|null|undefined} lastRefillDate
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.lastRefillDate = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct name.
             * @member {string} name
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.name = "";
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct outcome.
             * @member {string} outcome
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.outcome = "";
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct reasonForNotApplyingSavings.
             * @member {google.protobuf.IStringValue|null|undefined} reasonForNotApplyingSavings
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.reasonForNotApplyingSavings = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct rebilled.
             * @member {google.protobuf.IBoolValue|null|undefined} rebilled
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.rebilled = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct refillDenialReason.
             * @member {google.protobuf.IStringValue|null|undefined} refillDenialReason
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.refillDenialReason = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct refillRequested.
             * @member {google.protobuf.IBoolValue|null|undefined} refillRequested
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.refillRequested = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct savingsCardAdded.
             * @member {google.protobuf.IBoolValue|null|undefined} savingsCardAdded
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.savingsCardAdded = null;
    
            /**
             * INFPrescriptionSavingsTaskOutputProduct savingsCardApplied.
             * @member {google.protobuf.IBoolValue|null|undefined} savingsCardApplied
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.savingsCardApplied = null;
    
            /**
             * Creates a new INFPrescriptionSavingsTaskOutputProduct instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskOutputProduct=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionSavingsTaskOutputProduct} INFPrescriptionSavingsTaskOutputProduct instance
             */
            INFPrescriptionSavingsTaskOutputProduct.create = function create(properties) {
                return new INFPrescriptionSavingsTaskOutputProduct(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsTaskOutputProduct message. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsTaskOutputProduct.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskOutputProduct} message INFPrescriptionSavingsTaskOutputProduct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsTaskOutputProduct.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                if (message.copayAfterSavings != null && Object.hasOwnProperty.call(message, "copayAfterSavings"))
                    $root.google.protobuf.DoubleValue.encode(message.copayAfterSavings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.isMedicationOnFile != null && Object.hasOwnProperty.call(message, "isMedicationOnFile"))
                    $root.google.protobuf.BoolValue.encode(message.isMedicationOnFile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.isPrescriptionActive != null && Object.hasOwnProperty.call(message, "isPrescriptionActive"))
                    $root.google.protobuf.BoolValue.encode(message.isPrescriptionActive, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.lastRefillCost != null && Object.hasOwnProperty.call(message, "lastRefillCost"))
                    $root.google.protobuf.DoubleValue.encode(message.lastRefillCost, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.lastRefillDate != null && Object.hasOwnProperty.call(message, "lastRefillDate"))
                    $root.google.protobuf.StringValue.encode(message.lastRefillDate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
                if (message.outcome != null && Object.hasOwnProperty.call(message, "outcome"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.outcome);
                if (message.reasonForNotApplyingSavings != null && Object.hasOwnProperty.call(message, "reasonForNotApplyingSavings"))
                    $root.google.protobuf.StringValue.encode(message.reasonForNotApplyingSavings, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.rebilled != null && Object.hasOwnProperty.call(message, "rebilled"))
                    $root.google.protobuf.BoolValue.encode(message.rebilled, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.refillDenialReason != null && Object.hasOwnProperty.call(message, "refillDenialReason"))
                    $root.google.protobuf.StringValue.encode(message.refillDenialReason, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.refillRequested != null && Object.hasOwnProperty.call(message, "refillRequested"))
                    $root.google.protobuf.BoolValue.encode(message.refillRequested, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.savingsCardAdded != null && Object.hasOwnProperty.call(message, "savingsCardAdded"))
                    $root.google.protobuf.BoolValue.encode(message.savingsCardAdded, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.savingsCardApplied != null && Object.hasOwnProperty.call(message, "savingsCardApplied"))
                    $root.google.protobuf.BoolValue.encode(message.savingsCardApplied, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionSavingsTaskOutputProduct message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionSavingsTaskOutputProduct.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {infinitusapi.IINFPrescriptionSavingsTaskOutputProduct} message INFPrescriptionSavingsTaskOutputProduct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionSavingsTaskOutputProduct.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionSavingsTaskOutputProduct message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionSavingsTaskOutputProduct} INFPrescriptionSavingsTaskOutputProduct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsTaskOutputProduct.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionSavingsTaskOutputProduct();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    case 2: {
                            message.copayAfterSavings = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.isMedicationOnFile = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.isPrescriptionActive = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.lastRefillCost = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.lastRefillDate = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.name = reader.string();
                            break;
                        }
                    case 8: {
                            message.outcome = reader.string();
                            break;
                        }
                    case 9: {
                            message.reasonForNotApplyingSavings = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.rebilled = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.refillDenialReason = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.refillRequested = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.savingsCardAdded = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.savingsCardApplied = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionSavingsTaskOutputProduct message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionSavingsTaskOutputProduct} INFPrescriptionSavingsTaskOutputProduct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionSavingsTaskOutputProduct.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionSavingsTaskOutputProduct message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionSavingsTaskOutputProduct.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.copayAfterSavings != null && message.hasOwnProperty("copayAfterSavings")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.copayAfterSavings);
                    if (error)
                        return "copayAfterSavings." + error;
                }
                if (message.isMedicationOnFile != null && message.hasOwnProperty("isMedicationOnFile")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isMedicationOnFile);
                    if (error)
                        return "isMedicationOnFile." + error;
                }
                if (message.isPrescriptionActive != null && message.hasOwnProperty("isPrescriptionActive")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.isPrescriptionActive);
                    if (error)
                        return "isPrescriptionActive." + error;
                }
                if (message.lastRefillCost != null && message.hasOwnProperty("lastRefillCost")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.lastRefillCost);
                    if (error)
                        return "lastRefillCost." + error;
                }
                if (message.lastRefillDate != null && message.hasOwnProperty("lastRefillDate")) {
                    var error = $root.google.protobuf.StringValue.verify(message.lastRefillDate);
                    if (error)
                        return "lastRefillDate." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.outcome != null && message.hasOwnProperty("outcome"))
                    if (!$util.isString(message.outcome))
                        return "outcome: string expected";
                if (message.reasonForNotApplyingSavings != null && message.hasOwnProperty("reasonForNotApplyingSavings")) {
                    var error = $root.google.protobuf.StringValue.verify(message.reasonForNotApplyingSavings);
                    if (error)
                        return "reasonForNotApplyingSavings." + error;
                }
                if (message.rebilled != null && message.hasOwnProperty("rebilled")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.rebilled);
                    if (error)
                        return "rebilled." + error;
                }
                if (message.refillDenialReason != null && message.hasOwnProperty("refillDenialReason")) {
                    var error = $root.google.protobuf.StringValue.verify(message.refillDenialReason);
                    if (error)
                        return "refillDenialReason." + error;
                }
                if (message.refillRequested != null && message.hasOwnProperty("refillRequested")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.refillRequested);
                    if (error)
                        return "refillRequested." + error;
                }
                if (message.savingsCardAdded != null && message.hasOwnProperty("savingsCardAdded")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.savingsCardAdded);
                    if (error)
                        return "savingsCardAdded." + error;
                }
                if (message.savingsCardApplied != null && message.hasOwnProperty("savingsCardApplied")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.savingsCardApplied);
                    if (error)
                        return "savingsCardApplied." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPrescriptionSavingsTaskOutputProduct message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionSavingsTaskOutputProduct} INFPrescriptionSavingsTaskOutputProduct
             */
            INFPrescriptionSavingsTaskOutputProduct.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionSavingsTaskOutputProduct)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionSavingsTaskOutputProduct();
                if (object.code != null)
                    message.code = String(object.code);
                if (object.copayAfterSavings != null) {
                    if (typeof object.copayAfterSavings !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.copayAfterSavings: object expected");
                    message.copayAfterSavings = $root.google.protobuf.DoubleValue.fromObject(object.copayAfterSavings);
                }
                if (object.isMedicationOnFile != null) {
                    if (typeof object.isMedicationOnFile !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.isMedicationOnFile: object expected");
                    message.isMedicationOnFile = $root.google.protobuf.BoolValue.fromObject(object.isMedicationOnFile);
                }
                if (object.isPrescriptionActive != null) {
                    if (typeof object.isPrescriptionActive !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.isPrescriptionActive: object expected");
                    message.isPrescriptionActive = $root.google.protobuf.BoolValue.fromObject(object.isPrescriptionActive);
                }
                if (object.lastRefillCost != null) {
                    if (typeof object.lastRefillCost !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.lastRefillCost: object expected");
                    message.lastRefillCost = $root.google.protobuf.DoubleValue.fromObject(object.lastRefillCost);
                }
                if (object.lastRefillDate != null) {
                    if (typeof object.lastRefillDate !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.lastRefillDate: object expected");
                    message.lastRefillDate = $root.google.protobuf.StringValue.fromObject(object.lastRefillDate);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.outcome != null)
                    message.outcome = String(object.outcome);
                if (object.reasonForNotApplyingSavings != null) {
                    if (typeof object.reasonForNotApplyingSavings !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.reasonForNotApplyingSavings: object expected");
                    message.reasonForNotApplyingSavings = $root.google.protobuf.StringValue.fromObject(object.reasonForNotApplyingSavings);
                }
                if (object.rebilled != null) {
                    if (typeof object.rebilled !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.rebilled: object expected");
                    message.rebilled = $root.google.protobuf.BoolValue.fromObject(object.rebilled);
                }
                if (object.refillDenialReason != null) {
                    if (typeof object.refillDenialReason !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.refillDenialReason: object expected");
                    message.refillDenialReason = $root.google.protobuf.StringValue.fromObject(object.refillDenialReason);
                }
                if (object.refillRequested != null) {
                    if (typeof object.refillRequested !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.refillRequested: object expected");
                    message.refillRequested = $root.google.protobuf.BoolValue.fromObject(object.refillRequested);
                }
                if (object.savingsCardAdded != null) {
                    if (typeof object.savingsCardAdded !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.savingsCardAdded: object expected");
                    message.savingsCardAdded = $root.google.protobuf.BoolValue.fromObject(object.savingsCardAdded);
                }
                if (object.savingsCardApplied != null) {
                    if (typeof object.savingsCardApplied !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionSavingsTaskOutputProduct.savingsCardApplied: object expected");
                    message.savingsCardApplied = $root.google.protobuf.BoolValue.fromObject(object.savingsCardApplied);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionSavingsTaskOutputProduct message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {infinitusapi.INFPrescriptionSavingsTaskOutputProduct} message INFPrescriptionSavingsTaskOutputProduct
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionSavingsTaskOutputProduct.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.copayAfterSavings = null;
                    object.isMedicationOnFile = null;
                    object.isPrescriptionActive = null;
                    object.lastRefillCost = null;
                    object.lastRefillDate = null;
                    object.name = "";
                    object.outcome = "";
                    object.reasonForNotApplyingSavings = null;
                    object.rebilled = null;
                    object.refillDenialReason = null;
                    object.refillRequested = null;
                    object.savingsCardAdded = null;
                    object.savingsCardApplied = null;
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.copayAfterSavings != null && message.hasOwnProperty("copayAfterSavings"))
                    object.copayAfterSavings = $root.google.protobuf.DoubleValue.toObject(message.copayAfterSavings, options);
                if (message.isMedicationOnFile != null && message.hasOwnProperty("isMedicationOnFile"))
                    object.isMedicationOnFile = $root.google.protobuf.BoolValue.toObject(message.isMedicationOnFile, options);
                if (message.isPrescriptionActive != null && message.hasOwnProperty("isPrescriptionActive"))
                    object.isPrescriptionActive = $root.google.protobuf.BoolValue.toObject(message.isPrescriptionActive, options);
                if (message.lastRefillCost != null && message.hasOwnProperty("lastRefillCost"))
                    object.lastRefillCost = $root.google.protobuf.DoubleValue.toObject(message.lastRefillCost, options);
                if (message.lastRefillDate != null && message.hasOwnProperty("lastRefillDate"))
                    object.lastRefillDate = $root.google.protobuf.StringValue.toObject(message.lastRefillDate, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.outcome != null && message.hasOwnProperty("outcome"))
                    object.outcome = message.outcome;
                if (message.reasonForNotApplyingSavings != null && message.hasOwnProperty("reasonForNotApplyingSavings"))
                    object.reasonForNotApplyingSavings = $root.google.protobuf.StringValue.toObject(message.reasonForNotApplyingSavings, options);
                if (message.rebilled != null && message.hasOwnProperty("rebilled"))
                    object.rebilled = $root.google.protobuf.BoolValue.toObject(message.rebilled, options);
                if (message.refillDenialReason != null && message.hasOwnProperty("refillDenialReason"))
                    object.refillDenialReason = $root.google.protobuf.StringValue.toObject(message.refillDenialReason, options);
                if (message.refillRequested != null && message.hasOwnProperty("refillRequested"))
                    object.refillRequested = $root.google.protobuf.BoolValue.toObject(message.refillRequested, options);
                if (message.savingsCardAdded != null && message.hasOwnProperty("savingsCardAdded"))
                    object.savingsCardAdded = $root.google.protobuf.BoolValue.toObject(message.savingsCardAdded, options);
                if (message.savingsCardApplied != null && message.hasOwnProperty("savingsCardApplied"))
                    object.savingsCardApplied = $root.google.protobuf.BoolValue.toObject(message.savingsCardApplied, options);
                return object;
            };
    
            /**
             * Converts this INFPrescriptionSavingsTaskOutputProduct to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionSavingsTaskOutputProduct.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionSavingsTaskOutputProduct
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionSavingsTaskOutputProduct
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionSavingsTaskOutputProduct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionSavingsTaskOutputProduct";
            };
    
            return INFPrescriptionSavingsTaskOutputProduct;
        })();
    
        infinitusapi.INFPrescriptionTransferTaskOutput = (function() {
    
            /**
             * Properties of a INFPrescriptionTransferTaskOutput.
             * @memberof infinitusapi
             * @interface IINFPrescriptionTransferTaskOutput
             * @property {string|null} [additionalNotes] INFPrescriptionTransferTaskOutput additionalNotes
             * @property {infinitusapi.IINFPrescriptionTransferTaskOutputErrorInfo|null} [errorInfo] INFPrescriptionTransferTaskOutput errorInfo
             * @property {infinitusapi.INFPrescriptionTransferTaskOutputOutcome|null} [outcome] INFPrescriptionTransferTaskOutput outcome
             * @property {string|null} [taskLink] INFPrescriptionTransferTaskOutput taskLink
             */
    
            /**
             * Constructs a new INFPrescriptionTransferTaskOutput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionTransferTaskOutput.
             * @implements IINFPrescriptionTransferTaskOutput
             * @constructor
             * @param {infinitusapi.IINFPrescriptionTransferTaskOutput=} [properties] Properties to set
             */
            function INFPrescriptionTransferTaskOutput(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionTransferTaskOutput additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @instance
             */
            INFPrescriptionTransferTaskOutput.prototype.additionalNotes = "";
    
            /**
             * INFPrescriptionTransferTaskOutput errorInfo.
             * @member {infinitusapi.IINFPrescriptionTransferTaskOutputErrorInfo|null|undefined} errorInfo
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @instance
             */
            INFPrescriptionTransferTaskOutput.prototype.errorInfo = null;
    
            /**
             * INFPrescriptionTransferTaskOutput outcome.
             * @member {infinitusapi.INFPrescriptionTransferTaskOutputOutcome} outcome
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @instance
             */
            INFPrescriptionTransferTaskOutput.prototype.outcome = 0;
    
            /**
             * INFPrescriptionTransferTaskOutput taskLink.
             * @member {string} taskLink
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @instance
             */
            INFPrescriptionTransferTaskOutput.prototype.taskLink = "";
    
            /**
             * Creates a new INFPrescriptionTransferTaskOutput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {infinitusapi.IINFPrescriptionTransferTaskOutput=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionTransferTaskOutput} INFPrescriptionTransferTaskOutput instance
             */
            INFPrescriptionTransferTaskOutput.create = function create(properties) {
                return new INFPrescriptionTransferTaskOutput(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionTransferTaskOutput message. Does not implicitly {@link infinitusapi.INFPrescriptionTransferTaskOutput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {infinitusapi.IINFPrescriptionTransferTaskOutput} message INFPrescriptionTransferTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionTransferTaskOutput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.errorInfo != null && Object.hasOwnProperty.call(message, "errorInfo"))
                    $root.infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo.encode(message.errorInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.outcome != null && Object.hasOwnProperty.call(message, "outcome"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.outcome);
                if (message.taskLink != null && Object.hasOwnProperty.call(message, "taskLink"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskLink);
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionTransferTaskOutput message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionTransferTaskOutput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {infinitusapi.IINFPrescriptionTransferTaskOutput} message INFPrescriptionTransferTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionTransferTaskOutput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionTransferTaskOutput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionTransferTaskOutput} INFPrescriptionTransferTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionTransferTaskOutput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionTransferTaskOutput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.errorInfo = $root.infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.outcome = reader.int32();
                            break;
                        }
                    case 4: {
                            message.taskLink = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionTransferTaskOutput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionTransferTaskOutput} INFPrescriptionTransferTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionTransferTaskOutput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionTransferTaskOutput message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionTransferTaskOutput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo")) {
                    var error = $root.infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo.verify(message.errorInfo);
                    if (error)
                        return "errorInfo." + error;
                }
                if (message.outcome != null && message.hasOwnProperty("outcome"))
                    switch (message.outcome) {
                    default:
                        return "outcome: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    if (!$util.isString(message.taskLink))
                        return "taskLink: string expected";
                return null;
            };
    
            /**
             * Creates a INFPrescriptionTransferTaskOutput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionTransferTaskOutput} INFPrescriptionTransferTaskOutput
             */
            INFPrescriptionTransferTaskOutput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionTransferTaskOutput)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionTransferTaskOutput();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.errorInfo != null) {
                    if (typeof object.errorInfo !== "object")
                        throw TypeError(".infinitusapi.INFPrescriptionTransferTaskOutput.errorInfo: object expected");
                    message.errorInfo = $root.infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo.fromObject(object.errorInfo);
                }
                switch (object.outcome) {
                default:
                    if (typeof object.outcome === "number") {
                        message.outcome = object.outcome;
                        break;
                    }
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_UNKNOWN":
                case 0:
                    message.outcome = 0;
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_ESCRIPTS":
                case 1:
                    message.outcome = 1;
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_FAX":
                case 2:
                    message.outcome = 2;
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_SEE_PATIENT":
                case 3:
                    message.outcome = 3;
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_TALK_TO_PATIENT":
                case 4:
                    message.outcome = 4;
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_NO_ACTIVE_SCRIPTS":
                case 5:
                    message.outcome = 5;
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_NEED_VERBAL_TRANSFER":
                case 6:
                    message.outcome = 6;
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_VOICEMAIL":
                case 7:
                    message.outcome = 7;
                    break;
                case "INF_PRESCRIPTION_TRANSFER_OUTCOME_OTHER":
                case 8:
                    message.outcome = 8;
                    break;
                }
                if (object.taskLink != null)
                    message.taskLink = String(object.taskLink);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionTransferTaskOutput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {infinitusapi.INFPrescriptionTransferTaskOutput} message INFPrescriptionTransferTaskOutput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionTransferTaskOutput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.errorInfo = null;
                    object.outcome = options.enums === String ? "INF_PRESCRIPTION_TRANSFER_OUTCOME_UNKNOWN" : 0;
                    object.taskLink = "";
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo"))
                    object.errorInfo = $root.infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo.toObject(message.errorInfo, options);
                if (message.outcome != null && message.hasOwnProperty("outcome"))
                    object.outcome = options.enums === String ? $root.infinitusapi.INFPrescriptionTransferTaskOutputOutcome[message.outcome] === undefined ? message.outcome : $root.infinitusapi.INFPrescriptionTransferTaskOutputOutcome[message.outcome] : message.outcome;
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    object.taskLink = message.taskLink;
                return object;
            };
    
            /**
             * Converts this INFPrescriptionTransferTaskOutput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionTransferTaskOutput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionTransferTaskOutput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionTransferTaskOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionTransferTaskOutput";
            };
    
            return INFPrescriptionTransferTaskOutput;
        })();
    
        infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo = (function() {
    
            /**
             * Properties of a INFPrescriptionTransferTaskOutputErrorInfo.
             * @memberof infinitusapi
             * @interface IINFPrescriptionTransferTaskOutputErrorInfo
             * @property {string|null} [additionalNotes] INFPrescriptionTransferTaskOutputErrorInfo additionalNotes
             * @property {infinitusapi.INFFailureReason|null} [failureReason] INFPrescriptionTransferTaskOutputErrorInfo failureReason
             */
    
            /**
             * Constructs a new INFPrescriptionTransferTaskOutputErrorInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPrescriptionTransferTaskOutputErrorInfo.
             * @implements IINFPrescriptionTransferTaskOutputErrorInfo
             * @constructor
             * @param {infinitusapi.IINFPrescriptionTransferTaskOutputErrorInfo=} [properties] Properties to set
             */
            function INFPrescriptionTransferTaskOutputErrorInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPrescriptionTransferTaskOutputErrorInfo additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @instance
             */
            INFPrescriptionTransferTaskOutputErrorInfo.prototype.additionalNotes = "";
    
            /**
             * INFPrescriptionTransferTaskOutputErrorInfo failureReason.
             * @member {infinitusapi.INFFailureReason} failureReason
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @instance
             */
            INFPrescriptionTransferTaskOutputErrorInfo.prototype.failureReason = 0;
    
            /**
             * Creates a new INFPrescriptionTransferTaskOutputErrorInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFPrescriptionTransferTaskOutputErrorInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo} INFPrescriptionTransferTaskOutputErrorInfo instance
             */
            INFPrescriptionTransferTaskOutputErrorInfo.create = function create(properties) {
                return new INFPrescriptionTransferTaskOutputErrorInfo(properties);
            };
    
            /**
             * Encodes the specified INFPrescriptionTransferTaskOutputErrorInfo message. Does not implicitly {@link infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFPrescriptionTransferTaskOutputErrorInfo} message INFPrescriptionTransferTaskOutputErrorInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionTransferTaskOutputErrorInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.failureReason);
                return writer;
            };
    
            /**
             * Encodes the specified INFPrescriptionTransferTaskOutputErrorInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFPrescriptionTransferTaskOutputErrorInfo} message INFPrescriptionTransferTaskOutputErrorInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPrescriptionTransferTaskOutputErrorInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPrescriptionTransferTaskOutputErrorInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo} INFPrescriptionTransferTaskOutputErrorInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionTransferTaskOutputErrorInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.failureReason = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPrescriptionTransferTaskOutputErrorInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo} INFPrescriptionTransferTaskOutputErrorInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPrescriptionTransferTaskOutputErrorInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPrescriptionTransferTaskOutputErrorInfo message.
             * @function verify
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPrescriptionTransferTaskOutputErrorInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    switch (message.failureReason) {
                    default:
                        return "failureReason: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a INFPrescriptionTransferTaskOutputErrorInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo} INFPrescriptionTransferTaskOutputErrorInfo
             */
            INFPrescriptionTransferTaskOutputErrorInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo)
                    return object;
                var message = new $root.infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                switch (object.failureReason) {
                default:
                    if (typeof object.failureReason === "number") {
                        message.failureReason = object.failureReason;
                        break;
                    }
                    break;
                case "INF_FAILURE_REASON_UNKNOWN":
                case 0:
                    message.failureReason = 0;
                    break;
                case "INF_FAILURE_REASON_BAD_DATA":
                case 1:
                    message.failureReason = 1;
                    break;
                case "INF_FAILURE_REASON_PAYER_UNREACHABLE":
                case 2:
                    message.failureReason = 2;
                    break;
                case "INF_FAILURE_REASON_PAYER_REFUSED_THIRD_PARTY":
                case 3:
                    message.failureReason = 3;
                    break;
                case "INF_FAILURE_REASON_CANCELLED_BY_CUSTOMER":
                case 4:
                    message.failureReason = 4;
                    break;
                case "INF_FAILURE_REASON_TASK_EXPIRED":
                case 5:
                    message.failureReason = 5;
                    break;
                case "INF_FAILURE_REASON_OTHER":
                case 6:
                    message.failureReason = 6;
                    break;
                case "INF_FAILURE_REASON_PRESCRIBER_UNREACHABLE":
                case 7:
                    message.failureReason = 7;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPrescriptionTransferTaskOutputErrorInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo} message INFPrescriptionTransferTaskOutputErrorInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPrescriptionTransferTaskOutputErrorInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.failureReason = options.enums === String ? "INF_FAILURE_REASON_UNKNOWN" : 0;
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    object.failureReason = options.enums === String ? $root.infinitusapi.INFFailureReason[message.failureReason] === undefined ? message.failureReason : $root.infinitusapi.INFFailureReason[message.failureReason] : message.failureReason;
                return object;
            };
    
            /**
             * Converts this INFPrescriptionTransferTaskOutputErrorInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPrescriptionTransferTaskOutputErrorInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPrescriptionTransferTaskOutputErrorInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPrescriptionTransferTaskOutputErrorInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPrescriptionTransferTaskOutputErrorInfo";
            };
    
            return INFPrescriptionTransferTaskOutputErrorInfo;
        })();
    
        infinitusapi.INFPriorAuthDenialInfo = (function() {
    
            /**
             * Properties of a INFPriorAuthDenialInfo.
             * @memberof infinitusapi
             * @interface IINFPriorAuthDenialInfo
             * @property {string|null} [additionalNotes] INFPriorAuthDenialInfo additionalNotes
             * @property {google.protobuf.IBoolValue|null} [appealAvailable] INFPriorAuthDenialInfo appealAvailable
             * @property {infinitusapi.IINFAddress|null} [appealsContactAddress] INFPriorAuthDenialInfo appealsContactAddress
             * @property {string|null} [appealsContactFax] INFPriorAuthDenialInfo appealsContactFax
             * @property {string|null} [appealsContactOrg] INFPriorAuthDenialInfo appealsContactOrg
             * @property {string|null} [appealsContactPhone] INFPriorAuthDenialInfo appealsContactPhone
             * @property {string|null} [appealsContactWebsite] INFPriorAuthDenialInfo appealsContactWebsite
             * @property {infinitusapi.INFAppealsRequiredDocuments|null} [appealsRequiredDocuments] INFPriorAuthDenialInfo appealsRequiredDocuments
             * @property {string|null} [appealsSubmissionDeadline] INFPriorAuthDenialInfo appealsSubmissionDeadline
             * @property {string|null} [appealsTurnaroundTime] INFPriorAuthDenialInfo appealsTurnaroundTime
             * @property {string|null} [denialDate] INFPriorAuthDenialInfo denialDate
             * @property {string|null} [denialNumber] INFPriorAuthDenialInfo denialNumber
             * @property {string|null} [denialReason] INFPriorAuthDenialInfo denialReason
             * @property {google.protobuf.IDoubleValue|null} [numberOfAppealsAvailable] INFPriorAuthDenialInfo numberOfAppealsAvailable
             * @property {google.protobuf.IBoolValue|null} [peerToPeerAvailable] INFPriorAuthDenialInfo peerToPeerAvailable
             * @property {string|null} [peerToPeerPhone] INFPriorAuthDenialInfo peerToPeerPhone
             * @property {string|null} [peerToPeerSubmissionDeadline] INFPriorAuthDenialInfo peerToPeerSubmissionDeadline
             * @property {string|null} [priorAuthAppealsContactFax] INFPriorAuthDenialInfo priorAuthAppealsContactFax
             */
    
            /**
             * Constructs a new INFPriorAuthDenialInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFPriorAuthDenialInfo.
             * @implements IINFPriorAuthDenialInfo
             * @constructor
             * @param {infinitusapi.IINFPriorAuthDenialInfo=} [properties] Properties to set
             */
            function INFPriorAuthDenialInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPriorAuthDenialInfo additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.additionalNotes = "";
    
            /**
             * INFPriorAuthDenialInfo appealAvailable.
             * @member {google.protobuf.IBoolValue|null|undefined} appealAvailable
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealAvailable = null;
    
            /**
             * INFPriorAuthDenialInfo appealsContactAddress.
             * @member {infinitusapi.IINFAddress|null|undefined} appealsContactAddress
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealsContactAddress = null;
    
            /**
             * INFPriorAuthDenialInfo appealsContactFax.
             * @member {string} appealsContactFax
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealsContactFax = "";
    
            /**
             * INFPriorAuthDenialInfo appealsContactOrg.
             * @member {string} appealsContactOrg
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealsContactOrg = "";
    
            /**
             * INFPriorAuthDenialInfo appealsContactPhone.
             * @member {string} appealsContactPhone
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealsContactPhone = "";
    
            /**
             * INFPriorAuthDenialInfo appealsContactWebsite.
             * @member {string} appealsContactWebsite
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealsContactWebsite = "";
    
            /**
             * INFPriorAuthDenialInfo appealsRequiredDocuments.
             * @member {infinitusapi.INFAppealsRequiredDocuments} appealsRequiredDocuments
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealsRequiredDocuments = 0;
    
            /**
             * INFPriorAuthDenialInfo appealsSubmissionDeadline.
             * @member {string} appealsSubmissionDeadline
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealsSubmissionDeadline = "";
    
            /**
             * INFPriorAuthDenialInfo appealsTurnaroundTime.
             * @member {string} appealsTurnaroundTime
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.appealsTurnaroundTime = "";
    
            /**
             * INFPriorAuthDenialInfo denialDate.
             * @member {string} denialDate
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.denialDate = "";
    
            /**
             * INFPriorAuthDenialInfo denialNumber.
             * @member {string} denialNumber
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.denialNumber = "";
    
            /**
             * INFPriorAuthDenialInfo denialReason.
             * @member {string} denialReason
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.denialReason = "";
    
            /**
             * INFPriorAuthDenialInfo numberOfAppealsAvailable.
             * @member {google.protobuf.IDoubleValue|null|undefined} numberOfAppealsAvailable
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.numberOfAppealsAvailable = null;
    
            /**
             * INFPriorAuthDenialInfo peerToPeerAvailable.
             * @member {google.protobuf.IBoolValue|null|undefined} peerToPeerAvailable
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.peerToPeerAvailable = null;
    
            /**
             * INFPriorAuthDenialInfo peerToPeerPhone.
             * @member {string} peerToPeerPhone
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.peerToPeerPhone = "";
    
            /**
             * INFPriorAuthDenialInfo peerToPeerSubmissionDeadline.
             * @member {string} peerToPeerSubmissionDeadline
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.peerToPeerSubmissionDeadline = "";
    
            /**
             * INFPriorAuthDenialInfo priorAuthAppealsContactFax.
             * @member {string} priorAuthAppealsContactFax
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             */
            INFPriorAuthDenialInfo.prototype.priorAuthAppealsContactFax = "";
    
            /**
             * Creates a new INFPriorAuthDenialInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {infinitusapi.IINFPriorAuthDenialInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFPriorAuthDenialInfo} INFPriorAuthDenialInfo instance
             */
            INFPriorAuthDenialInfo.create = function create(properties) {
                return new INFPriorAuthDenialInfo(properties);
            };
    
            /**
             * Encodes the specified INFPriorAuthDenialInfo message. Does not implicitly {@link infinitusapi.INFPriorAuthDenialInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {infinitusapi.IINFPriorAuthDenialInfo} message INFPriorAuthDenialInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPriorAuthDenialInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.appealAvailable != null && Object.hasOwnProperty.call(message, "appealAvailable"))
                    $root.google.protobuf.BoolValue.encode(message.appealAvailable, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.appealsContactAddress != null && Object.hasOwnProperty.call(message, "appealsContactAddress"))
                    $root.infinitusapi.INFAddress.encode(message.appealsContactAddress, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.appealsContactFax != null && Object.hasOwnProperty.call(message, "appealsContactFax"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.appealsContactFax);
                if (message.appealsContactOrg != null && Object.hasOwnProperty.call(message, "appealsContactOrg"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.appealsContactOrg);
                if (message.appealsContactPhone != null && Object.hasOwnProperty.call(message, "appealsContactPhone"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.appealsContactPhone);
                if (message.appealsContactWebsite != null && Object.hasOwnProperty.call(message, "appealsContactWebsite"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.appealsContactWebsite);
                if (message.appealsRequiredDocuments != null && Object.hasOwnProperty.call(message, "appealsRequiredDocuments"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.appealsRequiredDocuments);
                if (message.appealsSubmissionDeadline != null && Object.hasOwnProperty.call(message, "appealsSubmissionDeadline"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.appealsSubmissionDeadline);
                if (message.appealsTurnaroundTime != null && Object.hasOwnProperty.call(message, "appealsTurnaroundTime"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.appealsTurnaroundTime);
                if (message.denialDate != null && Object.hasOwnProperty.call(message, "denialDate"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.denialDate);
                if (message.denialNumber != null && Object.hasOwnProperty.call(message, "denialNumber"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.denialNumber);
                if (message.denialReason != null && Object.hasOwnProperty.call(message, "denialReason"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.denialReason);
                if (message.numberOfAppealsAvailable != null && Object.hasOwnProperty.call(message, "numberOfAppealsAvailable"))
                    $root.google.protobuf.DoubleValue.encode(message.numberOfAppealsAvailable, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.peerToPeerAvailable != null && Object.hasOwnProperty.call(message, "peerToPeerAvailable"))
                    $root.google.protobuf.BoolValue.encode(message.peerToPeerAvailable, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.peerToPeerPhone != null && Object.hasOwnProperty.call(message, "peerToPeerPhone"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.peerToPeerPhone);
                if (message.peerToPeerSubmissionDeadline != null && Object.hasOwnProperty.call(message, "peerToPeerSubmissionDeadline"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.peerToPeerSubmissionDeadline);
                if (message.priorAuthAppealsContactFax != null && Object.hasOwnProperty.call(message, "priorAuthAppealsContactFax"))
                    writer.uint32(/* id 18, wireType 2 =*/146).string(message.priorAuthAppealsContactFax);
                return writer;
            };
    
            /**
             * Encodes the specified INFPriorAuthDenialInfo message, length delimited. Does not implicitly {@link infinitusapi.INFPriorAuthDenialInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {infinitusapi.IINFPriorAuthDenialInfo} message INFPriorAuthDenialInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPriorAuthDenialInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPriorAuthDenialInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPriorAuthDenialInfo} INFPriorAuthDenialInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPriorAuthDenialInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPriorAuthDenialInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.appealAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.appealsContactAddress = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.appealsContactFax = reader.string();
                            break;
                        }
                    case 5: {
                            message.appealsContactOrg = reader.string();
                            break;
                        }
                    case 6: {
                            message.appealsContactPhone = reader.string();
                            break;
                        }
                    case 7: {
                            message.appealsContactWebsite = reader.string();
                            break;
                        }
                    case 8: {
                            message.appealsRequiredDocuments = reader.int32();
                            break;
                        }
                    case 9: {
                            message.appealsSubmissionDeadline = reader.string();
                            break;
                        }
                    case 10: {
                            message.appealsTurnaroundTime = reader.string();
                            break;
                        }
                    case 11: {
                            message.denialDate = reader.string();
                            break;
                        }
                    case 12: {
                            message.denialNumber = reader.string();
                            break;
                        }
                    case 13: {
                            message.denialReason = reader.string();
                            break;
                        }
                    case 14: {
                            message.numberOfAppealsAvailable = $root.google.protobuf.DoubleValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.peerToPeerAvailable = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.peerToPeerPhone = reader.string();
                            break;
                        }
                    case 17: {
                            message.peerToPeerSubmissionDeadline = reader.string();
                            break;
                        }
                    case 18: {
                            message.priorAuthAppealsContactFax = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPriorAuthDenialInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPriorAuthDenialInfo} INFPriorAuthDenialInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPriorAuthDenialInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPriorAuthDenialInfo message.
             * @function verify
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPriorAuthDenialInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.appealAvailable != null && message.hasOwnProperty("appealAvailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.appealAvailable);
                    if (error)
                        return "appealAvailable." + error;
                }
                if (message.appealsContactAddress != null && message.hasOwnProperty("appealsContactAddress")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.appealsContactAddress);
                    if (error)
                        return "appealsContactAddress." + error;
                }
                if (message.appealsContactFax != null && message.hasOwnProperty("appealsContactFax"))
                    if (!$util.isString(message.appealsContactFax))
                        return "appealsContactFax: string expected";
                if (message.appealsContactOrg != null && message.hasOwnProperty("appealsContactOrg"))
                    if (!$util.isString(message.appealsContactOrg))
                        return "appealsContactOrg: string expected";
                if (message.appealsContactPhone != null && message.hasOwnProperty("appealsContactPhone"))
                    if (!$util.isString(message.appealsContactPhone))
                        return "appealsContactPhone: string expected";
                if (message.appealsContactWebsite != null && message.hasOwnProperty("appealsContactWebsite"))
                    if (!$util.isString(message.appealsContactWebsite))
                        return "appealsContactWebsite: string expected";
                if (message.appealsRequiredDocuments != null && message.hasOwnProperty("appealsRequiredDocuments"))
                    switch (message.appealsRequiredDocuments) {
                    default:
                        return "appealsRequiredDocuments: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.appealsSubmissionDeadline != null && message.hasOwnProperty("appealsSubmissionDeadline"))
                    if (!$util.isString(message.appealsSubmissionDeadline))
                        return "appealsSubmissionDeadline: string expected";
                if (message.appealsTurnaroundTime != null && message.hasOwnProperty("appealsTurnaroundTime"))
                    if (!$util.isString(message.appealsTurnaroundTime))
                        return "appealsTurnaroundTime: string expected";
                if (message.denialDate != null && message.hasOwnProperty("denialDate"))
                    if (!$util.isString(message.denialDate))
                        return "denialDate: string expected";
                if (message.denialNumber != null && message.hasOwnProperty("denialNumber"))
                    if (!$util.isString(message.denialNumber))
                        return "denialNumber: string expected";
                if (message.denialReason != null && message.hasOwnProperty("denialReason"))
                    if (!$util.isString(message.denialReason))
                        return "denialReason: string expected";
                if (message.numberOfAppealsAvailable != null && message.hasOwnProperty("numberOfAppealsAvailable")) {
                    var error = $root.google.protobuf.DoubleValue.verify(message.numberOfAppealsAvailable);
                    if (error)
                        return "numberOfAppealsAvailable." + error;
                }
                if (message.peerToPeerAvailable != null && message.hasOwnProperty("peerToPeerAvailable")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.peerToPeerAvailable);
                    if (error)
                        return "peerToPeerAvailable." + error;
                }
                if (message.peerToPeerPhone != null && message.hasOwnProperty("peerToPeerPhone"))
                    if (!$util.isString(message.peerToPeerPhone))
                        return "peerToPeerPhone: string expected";
                if (message.peerToPeerSubmissionDeadline != null && message.hasOwnProperty("peerToPeerSubmissionDeadline"))
                    if (!$util.isString(message.peerToPeerSubmissionDeadline))
                        return "peerToPeerSubmissionDeadline: string expected";
                if (message.priorAuthAppealsContactFax != null && message.hasOwnProperty("priorAuthAppealsContactFax"))
                    if (!$util.isString(message.priorAuthAppealsContactFax))
                        return "priorAuthAppealsContactFax: string expected";
                return null;
            };
    
            /**
             * Creates a INFPriorAuthDenialInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPriorAuthDenialInfo} INFPriorAuthDenialInfo
             */
            INFPriorAuthDenialInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPriorAuthDenialInfo)
                    return object;
                var message = new $root.infinitusapi.INFPriorAuthDenialInfo();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.appealAvailable != null) {
                    if (typeof object.appealAvailable !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthDenialInfo.appealAvailable: object expected");
                    message.appealAvailable = $root.google.protobuf.BoolValue.fromObject(object.appealAvailable);
                }
                if (object.appealsContactAddress != null) {
                    if (typeof object.appealsContactAddress !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthDenialInfo.appealsContactAddress: object expected");
                    message.appealsContactAddress = $root.infinitusapi.INFAddress.fromObject(object.appealsContactAddress);
                }
                if (object.appealsContactFax != null)
                    message.appealsContactFax = String(object.appealsContactFax);
                if (object.appealsContactOrg != null)
                    message.appealsContactOrg = String(object.appealsContactOrg);
                if (object.appealsContactPhone != null)
                    message.appealsContactPhone = String(object.appealsContactPhone);
                if (object.appealsContactWebsite != null)
                    message.appealsContactWebsite = String(object.appealsContactWebsite);
                switch (object.appealsRequiredDocuments) {
                default:
                    if (typeof object.appealsRequiredDocuments === "number") {
                        message.appealsRequiredDocuments = object.appealsRequiredDocuments;
                        break;
                    }
                    break;
                case "INF_APPEALS_REQUIRED_DOCUMENTS_UNKNOWN":
                case 0:
                    message.appealsRequiredDocuments = 0;
                    break;
                case "INF_APPEALS_REQUIRED_DOCUMENTS_NOT_APPLICABLE":
                case 1:
                    message.appealsRequiredDocuments = 1;
                    break;
                case "INF_APPEALS_REQUIRED_DOCUMENTS_DENIAL_LETTER":
                case 2:
                    message.appealsRequiredDocuments = 2;
                    break;
                case "INF_APPEALS_REQUIRED_DOCUMENTS_LETTER_OF_MEDICAL_NECESSITY":
                case 3:
                    message.appealsRequiredDocuments = 3;
                    break;
                case "INF_APPEALS_REQUIRED_DOCUMENTS_NONE":
                case 4:
                    message.appealsRequiredDocuments = 4;
                    break;
                }
                if (object.appealsSubmissionDeadline != null)
                    message.appealsSubmissionDeadline = String(object.appealsSubmissionDeadline);
                if (object.appealsTurnaroundTime != null)
                    message.appealsTurnaroundTime = String(object.appealsTurnaroundTime);
                if (object.denialDate != null)
                    message.denialDate = String(object.denialDate);
                if (object.denialNumber != null)
                    message.denialNumber = String(object.denialNumber);
                if (object.denialReason != null)
                    message.denialReason = String(object.denialReason);
                if (object.numberOfAppealsAvailable != null) {
                    if (typeof object.numberOfAppealsAvailable !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthDenialInfo.numberOfAppealsAvailable: object expected");
                    message.numberOfAppealsAvailable = $root.google.protobuf.DoubleValue.fromObject(object.numberOfAppealsAvailable);
                }
                if (object.peerToPeerAvailable != null) {
                    if (typeof object.peerToPeerAvailable !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthDenialInfo.peerToPeerAvailable: object expected");
                    message.peerToPeerAvailable = $root.google.protobuf.BoolValue.fromObject(object.peerToPeerAvailable);
                }
                if (object.peerToPeerPhone != null)
                    message.peerToPeerPhone = String(object.peerToPeerPhone);
                if (object.peerToPeerSubmissionDeadline != null)
                    message.peerToPeerSubmissionDeadline = String(object.peerToPeerSubmissionDeadline);
                if (object.priorAuthAppealsContactFax != null)
                    message.priorAuthAppealsContactFax = String(object.priorAuthAppealsContactFax);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPriorAuthDenialInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {infinitusapi.INFPriorAuthDenialInfo} message INFPriorAuthDenialInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPriorAuthDenialInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.appealAvailable = null;
                    object.appealsContactAddress = null;
                    object.appealsContactFax = "";
                    object.appealsContactOrg = "";
                    object.appealsContactPhone = "";
                    object.appealsContactWebsite = "";
                    object.appealsRequiredDocuments = options.enums === String ? "INF_APPEALS_REQUIRED_DOCUMENTS_UNKNOWN" : 0;
                    object.appealsSubmissionDeadline = "";
                    object.appealsTurnaroundTime = "";
                    object.denialDate = "";
                    object.denialNumber = "";
                    object.denialReason = "";
                    object.numberOfAppealsAvailable = null;
                    object.peerToPeerAvailable = null;
                    object.peerToPeerPhone = "";
                    object.peerToPeerSubmissionDeadline = "";
                    object.priorAuthAppealsContactFax = "";
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.appealAvailable != null && message.hasOwnProperty("appealAvailable"))
                    object.appealAvailable = $root.google.protobuf.BoolValue.toObject(message.appealAvailable, options);
                if (message.appealsContactAddress != null && message.hasOwnProperty("appealsContactAddress"))
                    object.appealsContactAddress = $root.infinitusapi.INFAddress.toObject(message.appealsContactAddress, options);
                if (message.appealsContactFax != null && message.hasOwnProperty("appealsContactFax"))
                    object.appealsContactFax = message.appealsContactFax;
                if (message.appealsContactOrg != null && message.hasOwnProperty("appealsContactOrg"))
                    object.appealsContactOrg = message.appealsContactOrg;
                if (message.appealsContactPhone != null && message.hasOwnProperty("appealsContactPhone"))
                    object.appealsContactPhone = message.appealsContactPhone;
                if (message.appealsContactWebsite != null && message.hasOwnProperty("appealsContactWebsite"))
                    object.appealsContactWebsite = message.appealsContactWebsite;
                if (message.appealsRequiredDocuments != null && message.hasOwnProperty("appealsRequiredDocuments"))
                    object.appealsRequiredDocuments = options.enums === String ? $root.infinitusapi.INFAppealsRequiredDocuments[message.appealsRequiredDocuments] === undefined ? message.appealsRequiredDocuments : $root.infinitusapi.INFAppealsRequiredDocuments[message.appealsRequiredDocuments] : message.appealsRequiredDocuments;
                if (message.appealsSubmissionDeadline != null && message.hasOwnProperty("appealsSubmissionDeadline"))
                    object.appealsSubmissionDeadline = message.appealsSubmissionDeadline;
                if (message.appealsTurnaroundTime != null && message.hasOwnProperty("appealsTurnaroundTime"))
                    object.appealsTurnaroundTime = message.appealsTurnaroundTime;
                if (message.denialDate != null && message.hasOwnProperty("denialDate"))
                    object.denialDate = message.denialDate;
                if (message.denialNumber != null && message.hasOwnProperty("denialNumber"))
                    object.denialNumber = message.denialNumber;
                if (message.denialReason != null && message.hasOwnProperty("denialReason"))
                    object.denialReason = message.denialReason;
                if (message.numberOfAppealsAvailable != null && message.hasOwnProperty("numberOfAppealsAvailable"))
                    object.numberOfAppealsAvailable = $root.google.protobuf.DoubleValue.toObject(message.numberOfAppealsAvailable, options);
                if (message.peerToPeerAvailable != null && message.hasOwnProperty("peerToPeerAvailable"))
                    object.peerToPeerAvailable = $root.google.protobuf.BoolValue.toObject(message.peerToPeerAvailable, options);
                if (message.peerToPeerPhone != null && message.hasOwnProperty("peerToPeerPhone"))
                    object.peerToPeerPhone = message.peerToPeerPhone;
                if (message.peerToPeerSubmissionDeadline != null && message.hasOwnProperty("peerToPeerSubmissionDeadline"))
                    object.peerToPeerSubmissionDeadline = message.peerToPeerSubmissionDeadline;
                if (message.priorAuthAppealsContactFax != null && message.hasOwnProperty("priorAuthAppealsContactFax"))
                    object.priorAuthAppealsContactFax = message.priorAuthAppealsContactFax;
                return object;
            };
    
            /**
             * Converts this INFPriorAuthDenialInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPriorAuthDenialInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPriorAuthDenialInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFPriorAuthDenialInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPriorAuthDenialInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPriorAuthDenialInfo";
            };
    
            return INFPriorAuthDenialInfo;
        })();
    
        infinitusapi.INFPriorAuthEntityOnFile = (function() {
    
            /**
             * Properties of a INFPriorAuthEntityOnFile.
             * @memberof infinitusapi
             * @interface IINFPriorAuthEntityOnFile
             * @property {string|null} [name] INFPriorAuthEntityOnFile name
             * @property {string|null} [npi] INFPriorAuthEntityOnFile npi
             * @property {infinitusapi.INFPriorAuthEntityOnFileType|null} [type] INFPriorAuthEntityOnFile type
             */
    
            /**
             * Constructs a new INFPriorAuthEntityOnFile.
             * @memberof infinitusapi
             * @classdesc Represents a INFPriorAuthEntityOnFile.
             * @implements IINFPriorAuthEntityOnFile
             * @constructor
             * @param {infinitusapi.IINFPriorAuthEntityOnFile=} [properties] Properties to set
             */
            function INFPriorAuthEntityOnFile(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPriorAuthEntityOnFile name.
             * @member {string} name
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @instance
             */
            INFPriorAuthEntityOnFile.prototype.name = "";
    
            /**
             * INFPriorAuthEntityOnFile npi.
             * @member {string} npi
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @instance
             */
            INFPriorAuthEntityOnFile.prototype.npi = "";
    
            /**
             * INFPriorAuthEntityOnFile type.
             * @member {infinitusapi.INFPriorAuthEntityOnFileType} type
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @instance
             */
            INFPriorAuthEntityOnFile.prototype.type = 0;
    
            /**
             * Creates a new INFPriorAuthEntityOnFile instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {infinitusapi.IINFPriorAuthEntityOnFile=} [properties] Properties to set
             * @returns {infinitusapi.INFPriorAuthEntityOnFile} INFPriorAuthEntityOnFile instance
             */
            INFPriorAuthEntityOnFile.create = function create(properties) {
                return new INFPriorAuthEntityOnFile(properties);
            };
    
            /**
             * Encodes the specified INFPriorAuthEntityOnFile message. Does not implicitly {@link infinitusapi.INFPriorAuthEntityOnFile.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {infinitusapi.IINFPriorAuthEntityOnFile} message INFPriorAuthEntityOnFile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPriorAuthEntityOnFile.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.npi != null && Object.hasOwnProperty.call(message, "npi"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.npi);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                return writer;
            };
    
            /**
             * Encodes the specified INFPriorAuthEntityOnFile message, length delimited. Does not implicitly {@link infinitusapi.INFPriorAuthEntityOnFile.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {infinitusapi.IINFPriorAuthEntityOnFile} message INFPriorAuthEntityOnFile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPriorAuthEntityOnFile.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPriorAuthEntityOnFile message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPriorAuthEntityOnFile} INFPriorAuthEntityOnFile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPriorAuthEntityOnFile.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPriorAuthEntityOnFile();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.npi = reader.string();
                            break;
                        }
                    case 3: {
                            message.type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPriorAuthEntityOnFile message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPriorAuthEntityOnFile} INFPriorAuthEntityOnFile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPriorAuthEntityOnFile.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPriorAuthEntityOnFile message.
             * @function verify
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPriorAuthEntityOnFile.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.npi != null && message.hasOwnProperty("npi"))
                    if (!$util.isString(message.npi))
                        return "npi: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a INFPriorAuthEntityOnFile message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPriorAuthEntityOnFile} INFPriorAuthEntityOnFile
             */
            INFPriorAuthEntityOnFile.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPriorAuthEntityOnFile)
                    return object;
                var message = new $root.infinitusapi.INFPriorAuthEntityOnFile();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.npi != null)
                    message.npi = String(object.npi);
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PROVIDER":
                case 0:
                    message.type = 0;
                    break;
                case "INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PLACE_OF_SERVICE":
                case 1:
                    message.type = 1;
                    break;
                case "INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_UNKNOWN":
                case 2:
                    message.type = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPriorAuthEntityOnFile message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {infinitusapi.INFPriorAuthEntityOnFile} message INFPriorAuthEntityOnFile
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPriorAuthEntityOnFile.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.npi = "";
                    object.type = options.enums === String ? "INF_PRIOR_AUTH_ENTITY_ON_FILE_TYPE_PROVIDER" : 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.npi != null && message.hasOwnProperty("npi"))
                    object.npi = message.npi;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.infinitusapi.INFPriorAuthEntityOnFileType[message.type] === undefined ? message.type : $root.infinitusapi.INFPriorAuthEntityOnFileType[message.type] : message.type;
                return object;
            };
    
            /**
             * Converts this INFPriorAuthEntityOnFile to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPriorAuthEntityOnFile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPriorAuthEntityOnFile
             * @function getTypeUrl
             * @memberof infinitusapi.INFPriorAuthEntityOnFile
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPriorAuthEntityOnFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPriorAuthEntityOnFile";
            };
    
            return INFPriorAuthEntityOnFile;
        })();
    
        infinitusapi.INFPriorAuthTaskInput = (function() {
    
            /**
             * Properties of a INFPriorAuthTaskInput.
             * @memberof infinitusapi
             * @interface IINFPriorAuthTaskInput
             * @property {Array.<infinitusapi.IINFPATaskInputCPT>|null} [cpt] INFPriorAuthTaskInput cpt
             * @property {Array.<infinitusapi.IINFTaskInputDiagnosis>|null} [diagnosis] INFPriorAuthTaskInput diagnosis
             * @property {infinitusapi.IINFTaskInputMember|null} [member] INFPriorAuthTaskInput member
             * @property {infinitusapi.IINFPATaskInputPayer|null} [payer] INFPriorAuthTaskInput payer
             * @property {infinitusapi.IINFPATaskInputPlaceOfService|null} [placeOfService] INFPriorAuthTaskInput placeOfService
             * @property {infinitusapi.IINFPATaskInputPriorAuthInfo|null} [priorAuthInfo] INFPriorAuthTaskInput priorAuthInfo
             * @property {Array.<infinitusapi.IINFPATaskInputProduct>|null} [products] INFPriorAuthTaskInput products
             * @property {infinitusapi.IINFPATaskInputProvider|null} [provider] INFPriorAuthTaskInput provider
             */
    
            /**
             * Constructs a new INFPriorAuthTaskInput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPriorAuthTaskInput.
             * @implements IINFPriorAuthTaskInput
             * @constructor
             * @param {infinitusapi.IINFPriorAuthTaskInput=} [properties] Properties to set
             */
            function INFPriorAuthTaskInput(properties) {
                this.cpt = [];
                this.diagnosis = [];
                this.products = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPriorAuthTaskInput cpt.
             * @member {Array.<infinitusapi.IINFPATaskInputCPT>} cpt
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             */
            INFPriorAuthTaskInput.prototype.cpt = $util.emptyArray;
    
            /**
             * INFPriorAuthTaskInput diagnosis.
             * @member {Array.<infinitusapi.IINFTaskInputDiagnosis>} diagnosis
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             */
            INFPriorAuthTaskInput.prototype.diagnosis = $util.emptyArray;
    
            /**
             * INFPriorAuthTaskInput member.
             * @member {infinitusapi.IINFTaskInputMember|null|undefined} member
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             */
            INFPriorAuthTaskInput.prototype.member = null;
    
            /**
             * INFPriorAuthTaskInput payer.
             * @member {infinitusapi.IINFPATaskInputPayer|null|undefined} payer
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             */
            INFPriorAuthTaskInput.prototype.payer = null;
    
            /**
             * INFPriorAuthTaskInput placeOfService.
             * @member {infinitusapi.IINFPATaskInputPlaceOfService|null|undefined} placeOfService
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             */
            INFPriorAuthTaskInput.prototype.placeOfService = null;
    
            /**
             * INFPriorAuthTaskInput priorAuthInfo.
             * @member {infinitusapi.IINFPATaskInputPriorAuthInfo|null|undefined} priorAuthInfo
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             */
            INFPriorAuthTaskInput.prototype.priorAuthInfo = null;
    
            /**
             * INFPriorAuthTaskInput products.
             * @member {Array.<infinitusapi.IINFPATaskInputProduct>} products
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             */
            INFPriorAuthTaskInput.prototype.products = $util.emptyArray;
    
            /**
             * INFPriorAuthTaskInput provider.
             * @member {infinitusapi.IINFPATaskInputProvider|null|undefined} provider
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             */
            INFPriorAuthTaskInput.prototype.provider = null;
    
            /**
             * Creates a new INFPriorAuthTaskInput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {infinitusapi.IINFPriorAuthTaskInput=} [properties] Properties to set
             * @returns {infinitusapi.INFPriorAuthTaskInput} INFPriorAuthTaskInput instance
             */
            INFPriorAuthTaskInput.create = function create(properties) {
                return new INFPriorAuthTaskInput(properties);
            };
    
            /**
             * Encodes the specified INFPriorAuthTaskInput message. Does not implicitly {@link infinitusapi.INFPriorAuthTaskInput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {infinitusapi.IINFPriorAuthTaskInput} message INFPriorAuthTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPriorAuthTaskInput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.cpt != null && message.cpt.length)
                    for (var i = 0; i < message.cpt.length; ++i)
                        $root.infinitusapi.INFPATaskInputCPT.encode(message.cpt[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.diagnosis != null && message.diagnosis.length)
                    for (var i = 0; i < message.diagnosis.length; ++i)
                        $root.infinitusapi.INFTaskInputDiagnosis.encode(message.diagnosis[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.member != null && Object.hasOwnProperty.call(message, "member"))
                    $root.infinitusapi.INFTaskInputMember.encode(message.member, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.payer != null && Object.hasOwnProperty.call(message, "payer"))
                    $root.infinitusapi.INFPATaskInputPayer.encode(message.payer, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.placeOfService != null && Object.hasOwnProperty.call(message, "placeOfService"))
                    $root.infinitusapi.INFPATaskInputPlaceOfService.encode(message.placeOfService, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.priorAuthInfo != null && Object.hasOwnProperty.call(message, "priorAuthInfo"))
                    $root.infinitusapi.INFPATaskInputPriorAuthInfo.encode(message.priorAuthInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.products != null && message.products.length)
                    for (var i = 0; i < message.products.length; ++i)
                        $root.infinitusapi.INFPATaskInputProduct.encode(message.products[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
                    $root.infinitusapi.INFPATaskInputProvider.encode(message.provider, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFPriorAuthTaskInput message, length delimited. Does not implicitly {@link infinitusapi.INFPriorAuthTaskInput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {infinitusapi.IINFPriorAuthTaskInput} message INFPriorAuthTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPriorAuthTaskInput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPriorAuthTaskInput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPriorAuthTaskInput} INFPriorAuthTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPriorAuthTaskInput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPriorAuthTaskInput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.cpt && message.cpt.length))
                                message.cpt = [];
                            message.cpt.push($root.infinitusapi.INFPATaskInputCPT.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(message.diagnosis && message.diagnosis.length))
                                message.diagnosis = [];
                            message.diagnosis.push($root.infinitusapi.INFTaskInputDiagnosis.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.member = $root.infinitusapi.INFTaskInputMember.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.payer = $root.infinitusapi.INFPATaskInputPayer.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.placeOfService = $root.infinitusapi.INFPATaskInputPlaceOfService.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.priorAuthInfo = $root.infinitusapi.INFPATaskInputPriorAuthInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            if (!(message.products && message.products.length))
                                message.products = [];
                            message.products.push($root.infinitusapi.INFPATaskInputProduct.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            message.provider = $root.infinitusapi.INFPATaskInputProvider.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPriorAuthTaskInput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPriorAuthTaskInput} INFPriorAuthTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPriorAuthTaskInput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPriorAuthTaskInput message.
             * @function verify
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPriorAuthTaskInput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cpt != null && message.hasOwnProperty("cpt")) {
                    if (!Array.isArray(message.cpt))
                        return "cpt: array expected";
                    for (var i = 0; i < message.cpt.length; ++i) {
                        var error = $root.infinitusapi.INFPATaskInputCPT.verify(message.cpt[i]);
                        if (error)
                            return "cpt." + error;
                    }
                }
                if (message.diagnosis != null && message.hasOwnProperty("diagnosis")) {
                    if (!Array.isArray(message.diagnosis))
                        return "diagnosis: array expected";
                    for (var i = 0; i < message.diagnosis.length; ++i) {
                        var error = $root.infinitusapi.INFTaskInputDiagnosis.verify(message.diagnosis[i]);
                        if (error)
                            return "diagnosis." + error;
                    }
                }
                if (message.member != null && message.hasOwnProperty("member")) {
                    var error = $root.infinitusapi.INFTaskInputMember.verify(message.member);
                    if (error)
                        return "member." + error;
                }
                if (message.payer != null && message.hasOwnProperty("payer")) {
                    var error = $root.infinitusapi.INFPATaskInputPayer.verify(message.payer);
                    if (error)
                        return "payer." + error;
                }
                if (message.placeOfService != null && message.hasOwnProperty("placeOfService")) {
                    var error = $root.infinitusapi.INFPATaskInputPlaceOfService.verify(message.placeOfService);
                    if (error)
                        return "placeOfService." + error;
                }
                if (message.priorAuthInfo != null && message.hasOwnProperty("priorAuthInfo")) {
                    var error = $root.infinitusapi.INFPATaskInputPriorAuthInfo.verify(message.priorAuthInfo);
                    if (error)
                        return "priorAuthInfo." + error;
                }
                if (message.products != null && message.hasOwnProperty("products")) {
                    if (!Array.isArray(message.products))
                        return "products: array expected";
                    for (var i = 0; i < message.products.length; ++i) {
                        var error = $root.infinitusapi.INFPATaskInputProduct.verify(message.products[i]);
                        if (error)
                            return "products." + error;
                    }
                }
                if (message.provider != null && message.hasOwnProperty("provider")) {
                    var error = $root.infinitusapi.INFPATaskInputProvider.verify(message.provider);
                    if (error)
                        return "provider." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFPriorAuthTaskInput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPriorAuthTaskInput} INFPriorAuthTaskInput
             */
            INFPriorAuthTaskInput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPriorAuthTaskInput)
                    return object;
                var message = new $root.infinitusapi.INFPriorAuthTaskInput();
                if (object.cpt) {
                    if (!Array.isArray(object.cpt))
                        throw TypeError(".infinitusapi.INFPriorAuthTaskInput.cpt: array expected");
                    message.cpt = [];
                    for (var i = 0; i < object.cpt.length; ++i) {
                        if (typeof object.cpt[i] !== "object")
                            throw TypeError(".infinitusapi.INFPriorAuthTaskInput.cpt: object expected");
                        message.cpt[i] = $root.infinitusapi.INFPATaskInputCPT.fromObject(object.cpt[i]);
                    }
                }
                if (object.diagnosis) {
                    if (!Array.isArray(object.diagnosis))
                        throw TypeError(".infinitusapi.INFPriorAuthTaskInput.diagnosis: array expected");
                    message.diagnosis = [];
                    for (var i = 0; i < object.diagnosis.length; ++i) {
                        if (typeof object.diagnosis[i] !== "object")
                            throw TypeError(".infinitusapi.INFPriorAuthTaskInput.diagnosis: object expected");
                        message.diagnosis[i] = $root.infinitusapi.INFTaskInputDiagnosis.fromObject(object.diagnosis[i]);
                    }
                }
                if (object.member != null) {
                    if (typeof object.member !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskInput.member: object expected");
                    message.member = $root.infinitusapi.INFTaskInputMember.fromObject(object.member);
                }
                if (object.payer != null) {
                    if (typeof object.payer !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskInput.payer: object expected");
                    message.payer = $root.infinitusapi.INFPATaskInputPayer.fromObject(object.payer);
                }
                if (object.placeOfService != null) {
                    if (typeof object.placeOfService !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskInput.placeOfService: object expected");
                    message.placeOfService = $root.infinitusapi.INFPATaskInputPlaceOfService.fromObject(object.placeOfService);
                }
                if (object.priorAuthInfo != null) {
                    if (typeof object.priorAuthInfo !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskInput.priorAuthInfo: object expected");
                    message.priorAuthInfo = $root.infinitusapi.INFPATaskInputPriorAuthInfo.fromObject(object.priorAuthInfo);
                }
                if (object.products) {
                    if (!Array.isArray(object.products))
                        throw TypeError(".infinitusapi.INFPriorAuthTaskInput.products: array expected");
                    message.products = [];
                    for (var i = 0; i < object.products.length; ++i) {
                        if (typeof object.products[i] !== "object")
                            throw TypeError(".infinitusapi.INFPriorAuthTaskInput.products: object expected");
                        message.products[i] = $root.infinitusapi.INFPATaskInputProduct.fromObject(object.products[i]);
                    }
                }
                if (object.provider != null) {
                    if (typeof object.provider !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskInput.provider: object expected");
                    message.provider = $root.infinitusapi.INFPATaskInputProvider.fromObject(object.provider);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFPriorAuthTaskInput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {infinitusapi.INFPriorAuthTaskInput} message INFPriorAuthTaskInput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPriorAuthTaskInput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.cpt = [];
                    object.diagnosis = [];
                    object.products = [];
                }
                if (options.defaults) {
                    object.member = null;
                    object.payer = null;
                    object.placeOfService = null;
                    object.priorAuthInfo = null;
                    object.provider = null;
                }
                if (message.cpt && message.cpt.length) {
                    object.cpt = [];
                    for (var j = 0; j < message.cpt.length; ++j)
                        object.cpt[j] = $root.infinitusapi.INFPATaskInputCPT.toObject(message.cpt[j], options);
                }
                if (message.diagnosis && message.diagnosis.length) {
                    object.diagnosis = [];
                    for (var j = 0; j < message.diagnosis.length; ++j)
                        object.diagnosis[j] = $root.infinitusapi.INFTaskInputDiagnosis.toObject(message.diagnosis[j], options);
                }
                if (message.member != null && message.hasOwnProperty("member"))
                    object.member = $root.infinitusapi.INFTaskInputMember.toObject(message.member, options);
                if (message.payer != null && message.hasOwnProperty("payer"))
                    object.payer = $root.infinitusapi.INFPATaskInputPayer.toObject(message.payer, options);
                if (message.placeOfService != null && message.hasOwnProperty("placeOfService"))
                    object.placeOfService = $root.infinitusapi.INFPATaskInputPlaceOfService.toObject(message.placeOfService, options);
                if (message.priorAuthInfo != null && message.hasOwnProperty("priorAuthInfo"))
                    object.priorAuthInfo = $root.infinitusapi.INFPATaskInputPriorAuthInfo.toObject(message.priorAuthInfo, options);
                if (message.products && message.products.length) {
                    object.products = [];
                    for (var j = 0; j < message.products.length; ++j)
                        object.products[j] = $root.infinitusapi.INFPATaskInputProduct.toObject(message.products[j], options);
                }
                if (message.provider != null && message.hasOwnProperty("provider"))
                    object.provider = $root.infinitusapi.INFPATaskInputProvider.toObject(message.provider, options);
                return object;
            };
    
            /**
             * Converts this INFPriorAuthTaskInput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPriorAuthTaskInput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPriorAuthTaskInput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPriorAuthTaskInput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPriorAuthTaskInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPriorAuthTaskInput";
            };
    
            return INFPriorAuthTaskInput;
        })();
    
        infinitusapi.INFPriorAuthTaskOutput = (function() {
    
            /**
             * Properties of a INFPriorAuthTaskOutput.
             * @memberof infinitusapi
             * @interface IINFPriorAuthTaskOutput
             * @property {string|null} [additionalNotes] INFPriorAuthTaskOutput additionalNotes
             * @property {Array.<infinitusapi.IINFPATaskOutputCPTCodePARequirements>|null} [cptCodePriorAuthRequirements] INFPriorAuthTaskOutput cptCodePriorAuthRequirements
             * @property {infinitusapi.IINFTaskOutputErrorInfo|null} [errorInfo] INFPriorAuthTaskOutput errorInfo
             * @property {string|null} [payerAgentName] INFPriorAuthTaskOutput payerAgentName
             * @property {string|null} [payerPhone] INFPriorAuthTaskOutput payerPhone
             * @property {infinitusapi.IINFPATaskOutputPlanInfo|null} [planInfo] INFPriorAuthTaskOutput planInfo
             * @property {infinitusapi.IINFPATaskOutputPriorAuthAppealInfo|null} [priorAuthAppealInfo] INFPriorAuthTaskOutput priorAuthAppealInfo
             * @property {infinitusapi.IINFPATaskOutputPriorAuthInfo|null} [priorAuthInfo] INFPriorAuthTaskOutput priorAuthInfo
             * @property {Array.<infinitusapi.IINFPATaskOutputProductCodePARequirements>|null} [productCodePriorAuthRequirements] INFPriorAuthTaskOutput productCodePriorAuthRequirements
             * @property {string|null} [referenceNumber] INFPriorAuthTaskOutput referenceNumber
             * @property {google.protobuf.IBoolValue|null} [reviewRequired] INFPriorAuthTaskOutput reviewRequired
             * @property {string|null} [taskLink] INFPriorAuthTaskOutput taskLink
             */
    
            /**
             * Constructs a new INFPriorAuthTaskOutput.
             * @memberof infinitusapi
             * @classdesc Represents a INFPriorAuthTaskOutput.
             * @implements IINFPriorAuthTaskOutput
             * @constructor
             * @param {infinitusapi.IINFPriorAuthTaskOutput=} [properties] Properties to set
             */
            function INFPriorAuthTaskOutput(properties) {
                this.cptCodePriorAuthRequirements = [];
                this.productCodePriorAuthRequirements = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFPriorAuthTaskOutput additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.additionalNotes = "";
    
            /**
             * INFPriorAuthTaskOutput cptCodePriorAuthRequirements.
             * @member {Array.<infinitusapi.IINFPATaskOutputCPTCodePARequirements>} cptCodePriorAuthRequirements
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.cptCodePriorAuthRequirements = $util.emptyArray;
    
            /**
             * INFPriorAuthTaskOutput errorInfo.
             * @member {infinitusapi.IINFTaskOutputErrorInfo|null|undefined} errorInfo
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.errorInfo = null;
    
            /**
             * INFPriorAuthTaskOutput payerAgentName.
             * @member {string} payerAgentName
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.payerAgentName = "";
    
            /**
             * INFPriorAuthTaskOutput payerPhone.
             * @member {string} payerPhone
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.payerPhone = "";
    
            /**
             * INFPriorAuthTaskOutput planInfo.
             * @member {infinitusapi.IINFPATaskOutputPlanInfo|null|undefined} planInfo
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.planInfo = null;
    
            /**
             * INFPriorAuthTaskOutput priorAuthAppealInfo.
             * @member {infinitusapi.IINFPATaskOutputPriorAuthAppealInfo|null|undefined} priorAuthAppealInfo
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.priorAuthAppealInfo = null;
    
            /**
             * INFPriorAuthTaskOutput priorAuthInfo.
             * @member {infinitusapi.IINFPATaskOutputPriorAuthInfo|null|undefined} priorAuthInfo
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.priorAuthInfo = null;
    
            /**
             * INFPriorAuthTaskOutput productCodePriorAuthRequirements.
             * @member {Array.<infinitusapi.IINFPATaskOutputProductCodePARequirements>} productCodePriorAuthRequirements
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.productCodePriorAuthRequirements = $util.emptyArray;
    
            /**
             * INFPriorAuthTaskOutput referenceNumber.
             * @member {string} referenceNumber
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.referenceNumber = "";
    
            /**
             * INFPriorAuthTaskOutput reviewRequired.
             * @member {google.protobuf.IBoolValue|null|undefined} reviewRequired
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.reviewRequired = null;
    
            /**
             * INFPriorAuthTaskOutput taskLink.
             * @member {string} taskLink
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             */
            INFPriorAuthTaskOutput.prototype.taskLink = "";
    
            /**
             * Creates a new INFPriorAuthTaskOutput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {infinitusapi.IINFPriorAuthTaskOutput=} [properties] Properties to set
             * @returns {infinitusapi.INFPriorAuthTaskOutput} INFPriorAuthTaskOutput instance
             */
            INFPriorAuthTaskOutput.create = function create(properties) {
                return new INFPriorAuthTaskOutput(properties);
            };
    
            /**
             * Encodes the specified INFPriorAuthTaskOutput message. Does not implicitly {@link infinitusapi.INFPriorAuthTaskOutput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {infinitusapi.IINFPriorAuthTaskOutput} message INFPriorAuthTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPriorAuthTaskOutput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.cptCodePriorAuthRequirements != null && message.cptCodePriorAuthRequirements.length)
                    for (var i = 0; i < message.cptCodePriorAuthRequirements.length; ++i)
                        $root.infinitusapi.INFPATaskOutputCPTCodePARequirements.encode(message.cptCodePriorAuthRequirements[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.errorInfo != null && Object.hasOwnProperty.call(message, "errorInfo"))
                    $root.infinitusapi.INFTaskOutputErrorInfo.encode(message.errorInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.payerAgentName != null && Object.hasOwnProperty.call(message, "payerAgentName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.payerAgentName);
                if (message.payerPhone != null && Object.hasOwnProperty.call(message, "payerPhone"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.payerPhone);
                if (message.planInfo != null && Object.hasOwnProperty.call(message, "planInfo"))
                    $root.infinitusapi.INFPATaskOutputPlanInfo.encode(message.planInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.priorAuthAppealInfo != null && Object.hasOwnProperty.call(message, "priorAuthAppealInfo"))
                    $root.infinitusapi.INFPATaskOutputPriorAuthAppealInfo.encode(message.priorAuthAppealInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.priorAuthInfo != null && Object.hasOwnProperty.call(message, "priorAuthInfo"))
                    $root.infinitusapi.INFPATaskOutputPriorAuthInfo.encode(message.priorAuthInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.productCodePriorAuthRequirements != null && message.productCodePriorAuthRequirements.length)
                    for (var i = 0; i < message.productCodePriorAuthRequirements.length; ++i)
                        $root.infinitusapi.INFPATaskOutputProductCodePARequirements.encode(message.productCodePriorAuthRequirements[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.referenceNumber != null && Object.hasOwnProperty.call(message, "referenceNumber"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.referenceNumber);
                if (message.reviewRequired != null && Object.hasOwnProperty.call(message, "reviewRequired"))
                    $root.google.protobuf.BoolValue.encode(message.reviewRequired, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.taskLink != null && Object.hasOwnProperty.call(message, "taskLink"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.taskLink);
                return writer;
            };
    
            /**
             * Encodes the specified INFPriorAuthTaskOutput message, length delimited. Does not implicitly {@link infinitusapi.INFPriorAuthTaskOutput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {infinitusapi.IINFPriorAuthTaskOutput} message INFPriorAuthTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFPriorAuthTaskOutput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFPriorAuthTaskOutput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFPriorAuthTaskOutput} INFPriorAuthTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPriorAuthTaskOutput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFPriorAuthTaskOutput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.cptCodePriorAuthRequirements && message.cptCodePriorAuthRequirements.length))
                                message.cptCodePriorAuthRequirements = [];
                            message.cptCodePriorAuthRequirements.push($root.infinitusapi.INFPATaskOutputCPTCodePARequirements.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.errorInfo = $root.infinitusapi.INFTaskOutputErrorInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.payerAgentName = reader.string();
                            break;
                        }
                    case 5: {
                            message.payerPhone = reader.string();
                            break;
                        }
                    case 6: {
                            message.planInfo = $root.infinitusapi.INFPATaskOutputPlanInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.priorAuthAppealInfo = $root.infinitusapi.INFPATaskOutputPriorAuthAppealInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.priorAuthInfo = $root.infinitusapi.INFPATaskOutputPriorAuthInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            if (!(message.productCodePriorAuthRequirements && message.productCodePriorAuthRequirements.length))
                                message.productCodePriorAuthRequirements = [];
                            message.productCodePriorAuthRequirements.push($root.infinitusapi.INFPATaskOutputProductCodePARequirements.decode(reader, reader.uint32()));
                            break;
                        }
                    case 10: {
                            message.referenceNumber = reader.string();
                            break;
                        }
                    case 11: {
                            message.reviewRequired = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.taskLink = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFPriorAuthTaskOutput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFPriorAuthTaskOutput} INFPriorAuthTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFPriorAuthTaskOutput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFPriorAuthTaskOutput message.
             * @function verify
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFPriorAuthTaskOutput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.cptCodePriorAuthRequirements != null && message.hasOwnProperty("cptCodePriorAuthRequirements")) {
                    if (!Array.isArray(message.cptCodePriorAuthRequirements))
                        return "cptCodePriorAuthRequirements: array expected";
                    for (var i = 0; i < message.cptCodePriorAuthRequirements.length; ++i) {
                        var error = $root.infinitusapi.INFPATaskOutputCPTCodePARequirements.verify(message.cptCodePriorAuthRequirements[i]);
                        if (error)
                            return "cptCodePriorAuthRequirements." + error;
                    }
                }
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo")) {
                    var error = $root.infinitusapi.INFTaskOutputErrorInfo.verify(message.errorInfo);
                    if (error)
                        return "errorInfo." + error;
                }
                if (message.payerAgentName != null && message.hasOwnProperty("payerAgentName"))
                    if (!$util.isString(message.payerAgentName))
                        return "payerAgentName: string expected";
                if (message.payerPhone != null && message.hasOwnProperty("payerPhone"))
                    if (!$util.isString(message.payerPhone))
                        return "payerPhone: string expected";
                if (message.planInfo != null && message.hasOwnProperty("planInfo")) {
                    var error = $root.infinitusapi.INFPATaskOutputPlanInfo.verify(message.planInfo);
                    if (error)
                        return "planInfo." + error;
                }
                if (message.priorAuthAppealInfo != null && message.hasOwnProperty("priorAuthAppealInfo")) {
                    var error = $root.infinitusapi.INFPATaskOutputPriorAuthAppealInfo.verify(message.priorAuthAppealInfo);
                    if (error)
                        return "priorAuthAppealInfo." + error;
                }
                if (message.priorAuthInfo != null && message.hasOwnProperty("priorAuthInfo")) {
                    var error = $root.infinitusapi.INFPATaskOutputPriorAuthInfo.verify(message.priorAuthInfo);
                    if (error)
                        return "priorAuthInfo." + error;
                }
                if (message.productCodePriorAuthRequirements != null && message.hasOwnProperty("productCodePriorAuthRequirements")) {
                    if (!Array.isArray(message.productCodePriorAuthRequirements))
                        return "productCodePriorAuthRequirements: array expected";
                    for (var i = 0; i < message.productCodePriorAuthRequirements.length; ++i) {
                        var error = $root.infinitusapi.INFPATaskOutputProductCodePARequirements.verify(message.productCodePriorAuthRequirements[i]);
                        if (error)
                            return "productCodePriorAuthRequirements." + error;
                    }
                }
                if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                    if (!$util.isString(message.referenceNumber))
                        return "referenceNumber: string expected";
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.reviewRequired);
                    if (error)
                        return "reviewRequired." + error;
                }
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    if (!$util.isString(message.taskLink))
                        return "taskLink: string expected";
                return null;
            };
    
            /**
             * Creates a INFPriorAuthTaskOutput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFPriorAuthTaskOutput} INFPriorAuthTaskOutput
             */
            INFPriorAuthTaskOutput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFPriorAuthTaskOutput)
                    return object;
                var message = new $root.infinitusapi.INFPriorAuthTaskOutput();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.cptCodePriorAuthRequirements) {
                    if (!Array.isArray(object.cptCodePriorAuthRequirements))
                        throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.cptCodePriorAuthRequirements: array expected");
                    message.cptCodePriorAuthRequirements = [];
                    for (var i = 0; i < object.cptCodePriorAuthRequirements.length; ++i) {
                        if (typeof object.cptCodePriorAuthRequirements[i] !== "object")
                            throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.cptCodePriorAuthRequirements: object expected");
                        message.cptCodePriorAuthRequirements[i] = $root.infinitusapi.INFPATaskOutputCPTCodePARequirements.fromObject(object.cptCodePriorAuthRequirements[i]);
                    }
                }
                if (object.errorInfo != null) {
                    if (typeof object.errorInfo !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.errorInfo: object expected");
                    message.errorInfo = $root.infinitusapi.INFTaskOutputErrorInfo.fromObject(object.errorInfo);
                }
                if (object.payerAgentName != null)
                    message.payerAgentName = String(object.payerAgentName);
                if (object.payerPhone != null)
                    message.payerPhone = String(object.payerPhone);
                if (object.planInfo != null) {
                    if (typeof object.planInfo !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.planInfo: object expected");
                    message.planInfo = $root.infinitusapi.INFPATaskOutputPlanInfo.fromObject(object.planInfo);
                }
                if (object.priorAuthAppealInfo != null) {
                    if (typeof object.priorAuthAppealInfo !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.priorAuthAppealInfo: object expected");
                    message.priorAuthAppealInfo = $root.infinitusapi.INFPATaskOutputPriorAuthAppealInfo.fromObject(object.priorAuthAppealInfo);
                }
                if (object.priorAuthInfo != null) {
                    if (typeof object.priorAuthInfo !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.priorAuthInfo: object expected");
                    message.priorAuthInfo = $root.infinitusapi.INFPATaskOutputPriorAuthInfo.fromObject(object.priorAuthInfo);
                }
                if (object.productCodePriorAuthRequirements) {
                    if (!Array.isArray(object.productCodePriorAuthRequirements))
                        throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.productCodePriorAuthRequirements: array expected");
                    message.productCodePriorAuthRequirements = [];
                    for (var i = 0; i < object.productCodePriorAuthRequirements.length; ++i) {
                        if (typeof object.productCodePriorAuthRequirements[i] !== "object")
                            throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.productCodePriorAuthRequirements: object expected");
                        message.productCodePriorAuthRequirements[i] = $root.infinitusapi.INFPATaskOutputProductCodePARequirements.fromObject(object.productCodePriorAuthRequirements[i]);
                    }
                }
                if (object.referenceNumber != null)
                    message.referenceNumber = String(object.referenceNumber);
                if (object.reviewRequired != null) {
                    if (typeof object.reviewRequired !== "object")
                        throw TypeError(".infinitusapi.INFPriorAuthTaskOutput.reviewRequired: object expected");
                    message.reviewRequired = $root.google.protobuf.BoolValue.fromObject(object.reviewRequired);
                }
                if (object.taskLink != null)
                    message.taskLink = String(object.taskLink);
                return message;
            };
    
            /**
             * Creates a plain object from a INFPriorAuthTaskOutput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {infinitusapi.INFPriorAuthTaskOutput} message INFPriorAuthTaskOutput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFPriorAuthTaskOutput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.cptCodePriorAuthRequirements = [];
                    object.productCodePriorAuthRequirements = [];
                }
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.errorInfo = null;
                    object.payerAgentName = "";
                    object.payerPhone = "";
                    object.planInfo = null;
                    object.priorAuthAppealInfo = null;
                    object.priorAuthInfo = null;
                    object.referenceNumber = "";
                    object.reviewRequired = null;
                    object.taskLink = "";
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.cptCodePriorAuthRequirements && message.cptCodePriorAuthRequirements.length) {
                    object.cptCodePriorAuthRequirements = [];
                    for (var j = 0; j < message.cptCodePriorAuthRequirements.length; ++j)
                        object.cptCodePriorAuthRequirements[j] = $root.infinitusapi.INFPATaskOutputCPTCodePARequirements.toObject(message.cptCodePriorAuthRequirements[j], options);
                }
                if (message.errorInfo != null && message.hasOwnProperty("errorInfo"))
                    object.errorInfo = $root.infinitusapi.INFTaskOutputErrorInfo.toObject(message.errorInfo, options);
                if (message.payerAgentName != null && message.hasOwnProperty("payerAgentName"))
                    object.payerAgentName = message.payerAgentName;
                if (message.payerPhone != null && message.hasOwnProperty("payerPhone"))
                    object.payerPhone = message.payerPhone;
                if (message.planInfo != null && message.hasOwnProperty("planInfo"))
                    object.planInfo = $root.infinitusapi.INFPATaskOutputPlanInfo.toObject(message.planInfo, options);
                if (message.priorAuthAppealInfo != null && message.hasOwnProperty("priorAuthAppealInfo"))
                    object.priorAuthAppealInfo = $root.infinitusapi.INFPATaskOutputPriorAuthAppealInfo.toObject(message.priorAuthAppealInfo, options);
                if (message.priorAuthInfo != null && message.hasOwnProperty("priorAuthInfo"))
                    object.priorAuthInfo = $root.infinitusapi.INFPATaskOutputPriorAuthInfo.toObject(message.priorAuthInfo, options);
                if (message.productCodePriorAuthRequirements && message.productCodePriorAuthRequirements.length) {
                    object.productCodePriorAuthRequirements = [];
                    for (var j = 0; j < message.productCodePriorAuthRequirements.length; ++j)
                        object.productCodePriorAuthRequirements[j] = $root.infinitusapi.INFPATaskOutputProductCodePARequirements.toObject(message.productCodePriorAuthRequirements[j], options);
                }
                if (message.referenceNumber != null && message.hasOwnProperty("referenceNumber"))
                    object.referenceNumber = message.referenceNumber;
                if (message.reviewRequired != null && message.hasOwnProperty("reviewRequired"))
                    object.reviewRequired = $root.google.protobuf.BoolValue.toObject(message.reviewRequired, options);
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    object.taskLink = message.taskLink;
                return object;
            };
    
            /**
             * Converts this INFPriorAuthTaskOutput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFPriorAuthTaskOutput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFPriorAuthTaskOutput
             * @function getTypeUrl
             * @memberof infinitusapi.INFPriorAuthTaskOutput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFPriorAuthTaskOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFPriorAuthTaskOutput";
            };
    
            return INFPriorAuthTaskOutput;
        })();
    
        infinitusapi.INFTaskInput = (function() {
    
            /**
             * Properties of a INFTaskInput.
             * @memberof infinitusapi
             * @interface IINFTaskInput
             * @property {Array.<infinitusapi.IINFInputValue>|null} [inputs] INFTaskInput inputs
             */
    
            /**
             * Constructs a new INFTaskInput.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskInput.
             * @implements IINFTaskInput
             * @constructor
             * @param {infinitusapi.IINFTaskInput=} [properties] Properties to set
             */
            function INFTaskInput(properties) {
                this.inputs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskInput inputs.
             * @member {Array.<infinitusapi.IINFInputValue>} inputs
             * @memberof infinitusapi.INFTaskInput
             * @instance
             */
            INFTaskInput.prototype.inputs = $util.emptyArray;
    
            /**
             * Creates a new INFTaskInput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {infinitusapi.IINFTaskInput=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskInput} INFTaskInput instance
             */
            INFTaskInput.create = function create(properties) {
                return new INFTaskInput(properties);
            };
    
            /**
             * Encodes the specified INFTaskInput message. Does not implicitly {@link infinitusapi.INFTaskInput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {infinitusapi.IINFTaskInput} message INFTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.inputs != null && message.inputs.length)
                    for (var i = 0; i < message.inputs.length; ++i)
                        $root.infinitusapi.INFInputValue.encode(message.inputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskInput message, length delimited. Does not implicitly {@link infinitusapi.INFTaskInput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {infinitusapi.IINFTaskInput} message INFTaskInput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskInput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskInput} INFTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskInput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.inputs && message.inputs.length))
                                message.inputs = [];
                            message.inputs.push($root.infinitusapi.INFInputValue.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskInput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskInput} INFTaskInput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskInput message.
             * @function verify
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskInput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.inputs != null && message.hasOwnProperty("inputs")) {
                    if (!Array.isArray(message.inputs))
                        return "inputs: array expected";
                    for (var i = 0; i < message.inputs.length; ++i) {
                        var error = $root.infinitusapi.INFInputValue.verify(message.inputs[i]);
                        if (error)
                            return "inputs." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a INFTaskInput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskInput} INFTaskInput
             */
            INFTaskInput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskInput)
                    return object;
                var message = new $root.infinitusapi.INFTaskInput();
                if (object.inputs) {
                    if (!Array.isArray(object.inputs))
                        throw TypeError(".infinitusapi.INFTaskInput.inputs: array expected");
                    message.inputs = [];
                    for (var i = 0; i < object.inputs.length; ++i) {
                        if (typeof object.inputs[i] !== "object")
                            throw TypeError(".infinitusapi.INFTaskInput.inputs: object expected");
                        message.inputs[i] = $root.infinitusapi.INFInputValue.fromObject(object.inputs[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskInput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {infinitusapi.INFTaskInput} message INFTaskInput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskInput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.inputs = [];
                if (message.inputs && message.inputs.length) {
                    object.inputs = [];
                    for (var j = 0; j < message.inputs.length; ++j)
                        object.inputs[j] = $root.infinitusapi.INFInputValue.toObject(message.inputs[j], options);
                }
                return object;
            };
    
            /**
             * Converts this INFTaskInput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskInput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskInput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskInput
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskInput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskInput";
            };
    
            return INFTaskInput;
        })();
    
        infinitusapi.INFTaskInputDiagnosis = (function() {
    
            /**
             * Properties of a INFTaskInputDiagnosis.
             * @memberof infinitusapi
             * @interface IINFTaskInputDiagnosis
             * @property {string|null} [code] INFTaskInputDiagnosis code
             */
    
            /**
             * Constructs a new INFTaskInputDiagnosis.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskInputDiagnosis.
             * @implements IINFTaskInputDiagnosis
             * @constructor
             * @param {infinitusapi.IINFTaskInputDiagnosis=} [properties] Properties to set
             */
            function INFTaskInputDiagnosis(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskInputDiagnosis code.
             * @member {string} code
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @instance
             */
            INFTaskInputDiagnosis.prototype.code = "";
    
            /**
             * Creates a new INFTaskInputDiagnosis instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {infinitusapi.IINFTaskInputDiagnosis=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskInputDiagnosis} INFTaskInputDiagnosis instance
             */
            INFTaskInputDiagnosis.create = function create(properties) {
                return new INFTaskInputDiagnosis(properties);
            };
    
            /**
             * Encodes the specified INFTaskInputDiagnosis message. Does not implicitly {@link infinitusapi.INFTaskInputDiagnosis.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {infinitusapi.IINFTaskInputDiagnosis} message INFTaskInputDiagnosis message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputDiagnosis.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskInputDiagnosis message, length delimited. Does not implicitly {@link infinitusapi.INFTaskInputDiagnosis.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {infinitusapi.IINFTaskInputDiagnosis} message INFTaskInputDiagnosis message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputDiagnosis.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskInputDiagnosis message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskInputDiagnosis} INFTaskInputDiagnosis
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputDiagnosis.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskInputDiagnosis();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskInputDiagnosis message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskInputDiagnosis} INFTaskInputDiagnosis
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputDiagnosis.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskInputDiagnosis message.
             * @function verify
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskInputDiagnosis.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                return null;
            };
    
            /**
             * Creates a INFTaskInputDiagnosis message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskInputDiagnosis} INFTaskInputDiagnosis
             */
            INFTaskInputDiagnosis.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskInputDiagnosis)
                    return object;
                var message = new $root.infinitusapi.INFTaskInputDiagnosis();
                if (object.code != null)
                    message.code = String(object.code);
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskInputDiagnosis message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {infinitusapi.INFTaskInputDiagnosis} message INFTaskInputDiagnosis
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskInputDiagnosis.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.code = "";
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                return object;
            };
    
            /**
             * Converts this INFTaskInputDiagnosis to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskInputDiagnosis.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskInputDiagnosis
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskInputDiagnosis
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskInputDiagnosis.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskInputDiagnosis";
            };
    
            return INFTaskInputDiagnosis;
        })();
    
        infinitusapi.INFTaskInputMember = (function() {
    
            /**
             * Properties of a INFTaskInputMember.
             * @memberof infinitusapi
             * @interface IINFTaskInputMember
             * @property {infinitusapi.IINFAddress|null} [address] INFTaskInputMember address
             * @property {string|null} [dateOfBirth] INFTaskInputMember dateOfBirth
             * @property {string|null} [firstName] INFTaskInputMember firstName
             * @property {string|null} [lastName] INFTaskInputMember lastName
             */
    
            /**
             * Constructs a new INFTaskInputMember.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskInputMember.
             * @implements IINFTaskInputMember
             * @constructor
             * @param {infinitusapi.IINFTaskInputMember=} [properties] Properties to set
             */
            function INFTaskInputMember(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskInputMember address.
             * @member {infinitusapi.IINFAddress|null|undefined} address
             * @memberof infinitusapi.INFTaskInputMember
             * @instance
             */
            INFTaskInputMember.prototype.address = null;
    
            /**
             * INFTaskInputMember dateOfBirth.
             * @member {string} dateOfBirth
             * @memberof infinitusapi.INFTaskInputMember
             * @instance
             */
            INFTaskInputMember.prototype.dateOfBirth = "";
    
            /**
             * INFTaskInputMember firstName.
             * @member {string} firstName
             * @memberof infinitusapi.INFTaskInputMember
             * @instance
             */
            INFTaskInputMember.prototype.firstName = "";
    
            /**
             * INFTaskInputMember lastName.
             * @member {string} lastName
             * @memberof infinitusapi.INFTaskInputMember
             * @instance
             */
            INFTaskInputMember.prototype.lastName = "";
    
            /**
             * Creates a new INFTaskInputMember instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {infinitusapi.IINFTaskInputMember=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskInputMember} INFTaskInputMember instance
             */
            INFTaskInputMember.create = function create(properties) {
                return new INFTaskInputMember(properties);
            };
    
            /**
             * Encodes the specified INFTaskInputMember message. Does not implicitly {@link infinitusapi.INFTaskInputMember.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {infinitusapi.IINFTaskInputMember} message INFTaskInputMember message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputMember.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.infinitusapi.INFAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateOfBirth);
                if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskInputMember message, length delimited. Does not implicitly {@link infinitusapi.INFTaskInputMember.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {infinitusapi.IINFTaskInputMember} message INFTaskInputMember message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputMember.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskInputMember message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskInputMember} INFTaskInputMember
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputMember.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskInputMember();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.dateOfBirth = reader.string();
                            break;
                        }
                    case 3: {
                            message.firstName = reader.string();
                            break;
                        }
                    case 4: {
                            message.lastName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskInputMember message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskInputMember} INFTaskInputMember
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputMember.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskInputMember message.
             * @function verify
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskInputMember.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                    if (!$util.isString(message.dateOfBirth))
                        return "dateOfBirth: string expected";
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    if (!$util.isString(message.firstName))
                        return "firstName: string expected";
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    if (!$util.isString(message.lastName))
                        return "lastName: string expected";
                return null;
            };
    
            /**
             * Creates a INFTaskInputMember message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskInputMember} INFTaskInputMember
             */
            INFTaskInputMember.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskInputMember)
                    return object;
                var message = new $root.infinitusapi.INFTaskInputMember();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".infinitusapi.INFTaskInputMember.address: object expected");
                    message.address = $root.infinitusapi.INFAddress.fromObject(object.address);
                }
                if (object.dateOfBirth != null)
                    message.dateOfBirth = String(object.dateOfBirth);
                if (object.firstName != null)
                    message.firstName = String(object.firstName);
                if (object.lastName != null)
                    message.lastName = String(object.lastName);
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskInputMember message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {infinitusapi.INFTaskInputMember} message INFTaskInputMember
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskInputMember.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.address = null;
                    object.dateOfBirth = "";
                    object.firstName = "";
                    object.lastName = "";
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.infinitusapi.INFAddress.toObject(message.address, options);
                if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                    object.dateOfBirth = message.dateOfBirth;
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    object.firstName = message.firstName;
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    object.lastName = message.lastName;
                return object;
            };
    
            /**
             * Converts this INFTaskInputMember to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskInputMember
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskInputMember.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskInputMember
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskInputMember
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskInputMember.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskInputMember";
            };
    
            return INFTaskInputMember;
        })();
    
        infinitusapi.INFTaskInputPayer = (function() {
    
            /**
             * Properties of a INFTaskInputPayer.
             * @memberof infinitusapi
             * @interface IINFTaskInputPayer
             * @property {infinitusapi.IINFBVPayerInfo|null} [bvInfo] INFTaskInputPayer bvInfo
             * @property {string|null} [customerPayerName] INFTaskInputPayer customerPayerName
             * @property {string|null} [customerPayerPhoneNumber] INFTaskInputPayer customerPayerPhoneNumber
             * @property {string|null} [groupId] INFTaskInputPayer groupId
             * @property {string|null} [groupName] INFTaskInputPayer groupName
             * @property {string|null} [infinitusPayerId] INFTaskInputPayer infinitusPayerId
             * @property {infinitusapi.INFOtherInsuranceStatus|null} [otherInsuranceStatus] INFTaskInputPayer otherInsuranceStatus
             * @property {infinitusapi.IINFPBMPayerInfo|null} [pbmInfo] INFTaskInputPayer pbmInfo
             * @property {string|null} [planName] INFTaskInputPayer planName
             * @property {string|null} [subscriberId] INFTaskInputPayer subscriberId
             */
    
            /**
             * Constructs a new INFTaskInputPayer.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskInputPayer.
             * @implements IINFTaskInputPayer
             * @constructor
             * @param {infinitusapi.IINFTaskInputPayer=} [properties] Properties to set
             */
            function INFTaskInputPayer(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskInputPayer bvInfo.
             * @member {infinitusapi.IINFBVPayerInfo|null|undefined} bvInfo
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.bvInfo = null;
    
            /**
             * INFTaskInputPayer customerPayerName.
             * @member {string} customerPayerName
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.customerPayerName = "";
    
            /**
             * INFTaskInputPayer customerPayerPhoneNumber.
             * @member {string} customerPayerPhoneNumber
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.customerPayerPhoneNumber = "";
    
            /**
             * INFTaskInputPayer groupId.
             * @member {string} groupId
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.groupId = "";
    
            /**
             * INFTaskInputPayer groupName.
             * @member {string} groupName
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.groupName = "";
    
            /**
             * INFTaskInputPayer infinitusPayerId.
             * @member {string} infinitusPayerId
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.infinitusPayerId = "";
    
            /**
             * INFTaskInputPayer otherInsuranceStatus.
             * @member {infinitusapi.INFOtherInsuranceStatus} otherInsuranceStatus
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.otherInsuranceStatus = 0;
    
            /**
             * INFTaskInputPayer pbmInfo.
             * @member {infinitusapi.IINFPBMPayerInfo|null|undefined} pbmInfo
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.pbmInfo = null;
    
            /**
             * INFTaskInputPayer planName.
             * @member {string} planName
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.planName = "";
    
            /**
             * INFTaskInputPayer subscriberId.
             * @member {string} subscriberId
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             */
            INFTaskInputPayer.prototype.subscriberId = "";
    
            /**
             * Creates a new INFTaskInputPayer instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {infinitusapi.IINFTaskInputPayer=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskInputPayer} INFTaskInputPayer instance
             */
            INFTaskInputPayer.create = function create(properties) {
                return new INFTaskInputPayer(properties);
            };
    
            /**
             * Encodes the specified INFTaskInputPayer message. Does not implicitly {@link infinitusapi.INFTaskInputPayer.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {infinitusapi.IINFTaskInputPayer} message INFTaskInputPayer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputPayer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bvInfo != null && Object.hasOwnProperty.call(message, "bvInfo"))
                    $root.infinitusapi.INFBVPayerInfo.encode(message.bvInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.customerPayerName != null && Object.hasOwnProperty.call(message, "customerPayerName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerPayerName);
                if (message.customerPayerPhoneNumber != null && Object.hasOwnProperty.call(message, "customerPayerPhoneNumber"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.customerPayerPhoneNumber);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.groupId);
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.groupName);
                if (message.infinitusPayerId != null && Object.hasOwnProperty.call(message, "infinitusPayerId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.infinitusPayerId);
                if (message.otherInsuranceStatus != null && Object.hasOwnProperty.call(message, "otherInsuranceStatus"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.otherInsuranceStatus);
                if (message.pbmInfo != null && Object.hasOwnProperty.call(message, "pbmInfo"))
                    $root.infinitusapi.INFPBMPayerInfo.encode(message.pbmInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.planName != null && Object.hasOwnProperty.call(message, "planName"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.planName);
                if (message.subscriberId != null && Object.hasOwnProperty.call(message, "subscriberId"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.subscriberId);
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskInputPayer message, length delimited. Does not implicitly {@link infinitusapi.INFTaskInputPayer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {infinitusapi.IINFTaskInputPayer} message INFTaskInputPayer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputPayer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskInputPayer message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskInputPayer} INFTaskInputPayer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputPayer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskInputPayer();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.bvInfo = $root.infinitusapi.INFBVPayerInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.customerPayerName = reader.string();
                            break;
                        }
                    case 3: {
                            message.customerPayerPhoneNumber = reader.string();
                            break;
                        }
                    case 4: {
                            message.groupId = reader.string();
                            break;
                        }
                    case 5: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 6: {
                            message.infinitusPayerId = reader.string();
                            break;
                        }
                    case 7: {
                            message.otherInsuranceStatus = reader.int32();
                            break;
                        }
                    case 8: {
                            message.pbmInfo = $root.infinitusapi.INFPBMPayerInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.planName = reader.string();
                            break;
                        }
                    case 10: {
                            message.subscriberId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskInputPayer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskInputPayer} INFTaskInputPayer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputPayer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskInputPayer message.
             * @function verify
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskInputPayer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.bvInfo != null && message.hasOwnProperty("bvInfo")) {
                    var error = $root.infinitusapi.INFBVPayerInfo.verify(message.bvInfo);
                    if (error)
                        return "bvInfo." + error;
                }
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    if (!$util.isString(message.customerPayerName))
                        return "customerPayerName: string expected";
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    if (!$util.isString(message.customerPayerPhoneNumber))
                        return "customerPayerPhoneNumber: string expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isString(message.groupId))
                        return "groupId: string expected";
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    if (!$util.isString(message.infinitusPayerId))
                        return "infinitusPayerId: string expected";
                if (message.otherInsuranceStatus != null && message.hasOwnProperty("otherInsuranceStatus"))
                    switch (message.otherInsuranceStatus) {
                    default:
                        return "otherInsuranceStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.pbmInfo != null && message.hasOwnProperty("pbmInfo")) {
                    var error = $root.infinitusapi.INFPBMPayerInfo.verify(message.pbmInfo);
                    if (error)
                        return "pbmInfo." + error;
                }
                if (message.planName != null && message.hasOwnProperty("planName"))
                    if (!$util.isString(message.planName))
                        return "planName: string expected";
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    if (!$util.isString(message.subscriberId))
                        return "subscriberId: string expected";
                return null;
            };
    
            /**
             * Creates a INFTaskInputPayer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskInputPayer} INFTaskInputPayer
             */
            INFTaskInputPayer.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskInputPayer)
                    return object;
                var message = new $root.infinitusapi.INFTaskInputPayer();
                if (object.bvInfo != null) {
                    if (typeof object.bvInfo !== "object")
                        throw TypeError(".infinitusapi.INFTaskInputPayer.bvInfo: object expected");
                    message.bvInfo = $root.infinitusapi.INFBVPayerInfo.fromObject(object.bvInfo);
                }
                if (object.customerPayerName != null)
                    message.customerPayerName = String(object.customerPayerName);
                if (object.customerPayerPhoneNumber != null)
                    message.customerPayerPhoneNumber = String(object.customerPayerPhoneNumber);
                if (object.groupId != null)
                    message.groupId = String(object.groupId);
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.infinitusPayerId != null)
                    message.infinitusPayerId = String(object.infinitusPayerId);
                switch (object.otherInsuranceStatus) {
                default:
                    if (typeof object.otherInsuranceStatus === "number") {
                        message.otherInsuranceStatus = object.otherInsuranceStatus;
                        break;
                    }
                    break;
                case "INF_OTHER_INSURANCE_STATUS_UNKNOWN":
                case 0:
                    message.otherInsuranceStatus = 0;
                    break;
                case "INF_OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B":
                case 1:
                    message.otherInsuranceStatus = 1;
                    break;
                case "INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY":
                case 2:
                    message.otherInsuranceStatus = 2;
                    break;
                case "INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY":
                case 3:
                    message.otherInsuranceStatus = 3;
                    break;
                case "INF_OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY":
                case 4:
                    message.otherInsuranceStatus = 4;
                    break;
                }
                if (object.pbmInfo != null) {
                    if (typeof object.pbmInfo !== "object")
                        throw TypeError(".infinitusapi.INFTaskInputPayer.pbmInfo: object expected");
                    message.pbmInfo = $root.infinitusapi.INFPBMPayerInfo.fromObject(object.pbmInfo);
                }
                if (object.planName != null)
                    message.planName = String(object.planName);
                if (object.subscriberId != null)
                    message.subscriberId = String(object.subscriberId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskInputPayer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {infinitusapi.INFTaskInputPayer} message INFTaskInputPayer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskInputPayer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.bvInfo = null;
                    object.customerPayerName = "";
                    object.customerPayerPhoneNumber = "";
                    object.groupId = "";
                    object.groupName = "";
                    object.infinitusPayerId = "";
                    object.otherInsuranceStatus = options.enums === String ? "INF_OTHER_INSURANCE_STATUS_UNKNOWN" : 0;
                    object.pbmInfo = null;
                    object.planName = "";
                    object.subscriberId = "";
                }
                if (message.bvInfo != null && message.hasOwnProperty("bvInfo"))
                    object.bvInfo = $root.infinitusapi.INFBVPayerInfo.toObject(message.bvInfo, options);
                if (message.customerPayerName != null && message.hasOwnProperty("customerPayerName"))
                    object.customerPayerName = message.customerPayerName;
                if (message.customerPayerPhoneNumber != null && message.hasOwnProperty("customerPayerPhoneNumber"))
                    object.customerPayerPhoneNumber = message.customerPayerPhoneNumber;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    object.groupId = message.groupId;
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.infinitusPayerId != null && message.hasOwnProperty("infinitusPayerId"))
                    object.infinitusPayerId = message.infinitusPayerId;
                if (message.otherInsuranceStatus != null && message.hasOwnProperty("otherInsuranceStatus"))
                    object.otherInsuranceStatus = options.enums === String ? $root.infinitusapi.INFOtherInsuranceStatus[message.otherInsuranceStatus] === undefined ? message.otherInsuranceStatus : $root.infinitusapi.INFOtherInsuranceStatus[message.otherInsuranceStatus] : message.otherInsuranceStatus;
                if (message.pbmInfo != null && message.hasOwnProperty("pbmInfo"))
                    object.pbmInfo = $root.infinitusapi.INFPBMPayerInfo.toObject(message.pbmInfo, options);
                if (message.planName != null && message.hasOwnProperty("planName"))
                    object.planName = message.planName;
                if (message.subscriberId != null && message.hasOwnProperty("subscriberId"))
                    object.subscriberId = message.subscriberId;
                return object;
            };
    
            /**
             * Converts this INFTaskInputPayer to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskInputPayer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskInputPayer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskInputPayer
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskInputPayer
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskInputPayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskInputPayer";
            };
    
            return INFTaskInputPayer;
        })();
    
        infinitusapi.INFTaskInputPractice = (function() {
    
            /**
             * Properties of a INFTaskInputPractice.
             * @memberof infinitusapi
             * @interface IINFTaskInputPractice
             * @property {infinitusapi.IINFAddress|null} [address] INFTaskInputPractice address
             * @property {Array.<string>|null} [alternateNames] INFTaskInputPractice alternateNames
             * @property {string|null} [name] INFTaskInputPractice name
             * @property {string|null} [npi] INFTaskInputPractice npi
             * @property {string|null} [taxId] INFTaskInputPractice taxId
             */
    
            /**
             * Constructs a new INFTaskInputPractice.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskInputPractice.
             * @implements IINFTaskInputPractice
             * @constructor
             * @param {infinitusapi.IINFTaskInputPractice=} [properties] Properties to set
             */
            function INFTaskInputPractice(properties) {
                this.alternateNames = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskInputPractice address.
             * @member {infinitusapi.IINFAddress|null|undefined} address
             * @memberof infinitusapi.INFTaskInputPractice
             * @instance
             */
            INFTaskInputPractice.prototype.address = null;
    
            /**
             * INFTaskInputPractice alternateNames.
             * @member {Array.<string>} alternateNames
             * @memberof infinitusapi.INFTaskInputPractice
             * @instance
             */
            INFTaskInputPractice.prototype.alternateNames = $util.emptyArray;
    
            /**
             * INFTaskInputPractice name.
             * @member {string} name
             * @memberof infinitusapi.INFTaskInputPractice
             * @instance
             */
            INFTaskInputPractice.prototype.name = "";
    
            /**
             * INFTaskInputPractice npi.
             * @member {string} npi
             * @memberof infinitusapi.INFTaskInputPractice
             * @instance
             */
            INFTaskInputPractice.prototype.npi = "";
    
            /**
             * INFTaskInputPractice taxId.
             * @member {string} taxId
             * @memberof infinitusapi.INFTaskInputPractice
             * @instance
             */
            INFTaskInputPractice.prototype.taxId = "";
    
            /**
             * Creates a new INFTaskInputPractice instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {infinitusapi.IINFTaskInputPractice=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskInputPractice} INFTaskInputPractice instance
             */
            INFTaskInputPractice.create = function create(properties) {
                return new INFTaskInputPractice(properties);
            };
    
            /**
             * Encodes the specified INFTaskInputPractice message. Does not implicitly {@link infinitusapi.INFTaskInputPractice.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {infinitusapi.IINFTaskInputPractice} message INFTaskInputPractice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputPractice.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.infinitusapi.INFAddress.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.alternateNames != null && message.alternateNames.length)
                    for (var i = 0; i < message.alternateNames.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.alternateNames[i]);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.npi != null && Object.hasOwnProperty.call(message, "npi"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.npi);
                if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.taxId);
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskInputPractice message, length delimited. Does not implicitly {@link infinitusapi.INFTaskInputPractice.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {infinitusapi.IINFTaskInputPractice} message INFTaskInputPractice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputPractice.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskInputPractice message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskInputPractice} INFTaskInputPractice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputPractice.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskInputPractice();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.alternateNames && message.alternateNames.length))
                                message.alternateNames = [];
                            message.alternateNames.push(reader.string());
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.npi = reader.string();
                            break;
                        }
                    case 5: {
                            message.taxId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskInputPractice message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskInputPractice} INFTaskInputPractice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputPractice.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskInputPractice message.
             * @function verify
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskInputPractice.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                if (message.alternateNames != null && message.hasOwnProperty("alternateNames")) {
                    if (!Array.isArray(message.alternateNames))
                        return "alternateNames: array expected";
                    for (var i = 0; i < message.alternateNames.length; ++i)
                        if (!$util.isString(message.alternateNames[i]))
                            return "alternateNames: string[] expected";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.npi != null && message.hasOwnProperty("npi"))
                    if (!$util.isString(message.npi))
                        return "npi: string expected";
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    if (!$util.isString(message.taxId))
                        return "taxId: string expected";
                return null;
            };
    
            /**
             * Creates a INFTaskInputPractice message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskInputPractice} INFTaskInputPractice
             */
            INFTaskInputPractice.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskInputPractice)
                    return object;
                var message = new $root.infinitusapi.INFTaskInputPractice();
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".infinitusapi.INFTaskInputPractice.address: object expected");
                    message.address = $root.infinitusapi.INFAddress.fromObject(object.address);
                }
                if (object.alternateNames) {
                    if (!Array.isArray(object.alternateNames))
                        throw TypeError(".infinitusapi.INFTaskInputPractice.alternateNames: array expected");
                    message.alternateNames = [];
                    for (var i = 0; i < object.alternateNames.length; ++i)
                        message.alternateNames[i] = String(object.alternateNames[i]);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.npi != null)
                    message.npi = String(object.npi);
                if (object.taxId != null)
                    message.taxId = String(object.taxId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskInputPractice message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {infinitusapi.INFTaskInputPractice} message INFTaskInputPractice
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskInputPractice.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.alternateNames = [];
                if (options.defaults) {
                    object.address = null;
                    object.name = "";
                    object.npi = "";
                    object.taxId = "";
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.infinitusapi.INFAddress.toObject(message.address, options);
                if (message.alternateNames && message.alternateNames.length) {
                    object.alternateNames = [];
                    for (var j = 0; j < message.alternateNames.length; ++j)
                        object.alternateNames[j] = message.alternateNames[j];
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.npi != null && message.hasOwnProperty("npi"))
                    object.npi = message.npi;
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    object.taxId = message.taxId;
                return object;
            };
    
            /**
             * Converts this INFTaskInputPractice to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskInputPractice
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskInputPractice.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskInputPractice
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskInputPractice
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskInputPractice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskInputPractice";
            };
    
            return INFTaskInputPractice;
        })();
    
        infinitusapi.INFTaskInputProduct = (function() {
    
            /**
             * Properties of a INFTaskInputProduct.
             * @memberof infinitusapi
             * @interface IINFTaskInputProduct
             * @property {string|null} [code] INFTaskInputProduct code
             * @property {infinitusapi.INFDaysSupply|null} [daysSupply] INFTaskInputProduct daysSupply
             * @property {string|null} [name] INFTaskInputProduct name
             * @property {string|null} [numberOfUnits] INFTaskInputProduct numberOfUnits
             * @property {google.protobuf.IBoolValue|null} [offLabel] INFTaskInputProduct offLabel
             */
    
            /**
             * Constructs a new INFTaskInputProduct.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskInputProduct.
             * @implements IINFTaskInputProduct
             * @constructor
             * @param {infinitusapi.IINFTaskInputProduct=} [properties] Properties to set
             */
            function INFTaskInputProduct(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskInputProduct code.
             * @member {string} code
             * @memberof infinitusapi.INFTaskInputProduct
             * @instance
             */
            INFTaskInputProduct.prototype.code = "";
    
            /**
             * INFTaskInputProduct daysSupply.
             * @member {infinitusapi.INFDaysSupply} daysSupply
             * @memberof infinitusapi.INFTaskInputProduct
             * @instance
             */
            INFTaskInputProduct.prototype.daysSupply = 0;
    
            /**
             * INFTaskInputProduct name.
             * @member {string} name
             * @memberof infinitusapi.INFTaskInputProduct
             * @instance
             */
            INFTaskInputProduct.prototype.name = "";
    
            /**
             * INFTaskInputProduct numberOfUnits.
             * @member {string} numberOfUnits
             * @memberof infinitusapi.INFTaskInputProduct
             * @instance
             */
            INFTaskInputProduct.prototype.numberOfUnits = "";
    
            /**
             * INFTaskInputProduct offLabel.
             * @member {google.protobuf.IBoolValue|null|undefined} offLabel
             * @memberof infinitusapi.INFTaskInputProduct
             * @instance
             */
            INFTaskInputProduct.prototype.offLabel = null;
    
            /**
             * Creates a new INFTaskInputProduct instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {infinitusapi.IINFTaskInputProduct=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskInputProduct} INFTaskInputProduct instance
             */
            INFTaskInputProduct.create = function create(properties) {
                return new INFTaskInputProduct(properties);
            };
    
            /**
             * Encodes the specified INFTaskInputProduct message. Does not implicitly {@link infinitusapi.INFTaskInputProduct.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {infinitusapi.IINFTaskInputProduct} message INFTaskInputProduct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputProduct.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                if (message.daysSupply != null && Object.hasOwnProperty.call(message, "daysSupply"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.daysSupply);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.numberOfUnits != null && Object.hasOwnProperty.call(message, "numberOfUnits"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.numberOfUnits);
                if (message.offLabel != null && Object.hasOwnProperty.call(message, "offLabel"))
                    $root.google.protobuf.BoolValue.encode(message.offLabel, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskInputProduct message, length delimited. Does not implicitly {@link infinitusapi.INFTaskInputProduct.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {infinitusapi.IINFTaskInputProduct} message INFTaskInputProduct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputProduct.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskInputProduct message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskInputProduct} INFTaskInputProduct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputProduct.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskInputProduct();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.code = reader.string();
                            break;
                        }
                    case 2: {
                            message.daysSupply = reader.int32();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.numberOfUnits = reader.string();
                            break;
                        }
                    case 5: {
                            message.offLabel = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskInputProduct message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskInputProduct} INFTaskInputProduct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputProduct.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskInputProduct message.
             * @function verify
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskInputProduct.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.daysSupply != null && message.hasOwnProperty("daysSupply"))
                    switch (message.daysSupply) {
                    default:
                        return "daysSupply: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.numberOfUnits != null && message.hasOwnProperty("numberOfUnits"))
                    if (!$util.isString(message.numberOfUnits))
                        return "numberOfUnits: string expected";
                if (message.offLabel != null && message.hasOwnProperty("offLabel")) {
                    var error = $root.google.protobuf.BoolValue.verify(message.offLabel);
                    if (error)
                        return "offLabel." + error;
                }
                return null;
            };
    
            /**
             * Creates a INFTaskInputProduct message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskInputProduct} INFTaskInputProduct
             */
            INFTaskInputProduct.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskInputProduct)
                    return object;
                var message = new $root.infinitusapi.INFTaskInputProduct();
                if (object.code != null)
                    message.code = String(object.code);
                switch (object.daysSupply) {
                default:
                    if (typeof object.daysSupply === "number") {
                        message.daysSupply = object.daysSupply;
                        break;
                    }
                    break;
                case "INF_DAYS_SUPPLY_UNKNOWN":
                case 0:
                    message.daysSupply = 0;
                    break;
                case "INF_DAYS_SUPPLY_30_DAYS":
                case 1:
                    message.daysSupply = 1;
                    break;
                case "INF_DAYS_SUPPLY_90_DAYS":
                case 2:
                    message.daysSupply = 2;
                    break;
                case "INF_DAYS_SUPPLY_60_DAYS":
                case 3:
                    message.daysSupply = 3;
                    break;
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.numberOfUnits != null)
                    message.numberOfUnits = String(object.numberOfUnits);
                if (object.offLabel != null) {
                    if (typeof object.offLabel !== "object")
                        throw TypeError(".infinitusapi.INFTaskInputProduct.offLabel: object expected");
                    message.offLabel = $root.google.protobuf.BoolValue.fromObject(object.offLabel);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskInputProduct message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {infinitusapi.INFTaskInputProduct} message INFTaskInputProduct
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskInputProduct.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.daysSupply = options.enums === String ? "INF_DAYS_SUPPLY_UNKNOWN" : 0;
                    object.name = "";
                    object.numberOfUnits = "";
                    object.offLabel = null;
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.daysSupply != null && message.hasOwnProperty("daysSupply"))
                    object.daysSupply = options.enums === String ? $root.infinitusapi.INFDaysSupply[message.daysSupply] === undefined ? message.daysSupply : $root.infinitusapi.INFDaysSupply[message.daysSupply] : message.daysSupply;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.numberOfUnits != null && message.hasOwnProperty("numberOfUnits"))
                    object.numberOfUnits = message.numberOfUnits;
                if (message.offLabel != null && message.hasOwnProperty("offLabel"))
                    object.offLabel = $root.google.protobuf.BoolValue.toObject(message.offLabel, options);
                return object;
            };
    
            /**
             * Converts this INFTaskInputProduct to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskInputProduct
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskInputProduct.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskInputProduct
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskInputProduct
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskInputProduct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskInputProduct";
            };
    
            return INFTaskInputProduct;
        })();
    
        infinitusapi.INFTaskInputProvider = (function() {
    
            /**
             * Properties of a INFTaskInputProvider.
             * @memberof infinitusapi
             * @interface IINFTaskInputProvider
             * @property {string|null} [additionalNotes] INFTaskInputProvider additionalNotes
             * @property {infinitusapi.IINFAddress|null} [address] INFTaskInputProvider address
             * @property {string|null} [firstName] INFTaskInputProvider firstName
             * @property {string|null} [lastName] INFTaskInputProvider lastName
             * @property {infinitusapi.INFBVTaskInputNetworkStatus|null} [networkStatus] INFTaskInputProvider networkStatus
             * @property {string|null} [npi] INFTaskInputProvider npi
             * @property {Array.<string>|null} [preferredSpecialtyPharmacies] INFTaskInputProvider preferredSpecialtyPharmacies
             * @property {string|null} [ptan] INFTaskInputProvider ptan
             * @property {string|null} [taxId] INFTaskInputProvider taxId
             */
    
            /**
             * Constructs a new INFTaskInputProvider.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskInputProvider.
             * @implements IINFTaskInputProvider
             * @constructor
             * @param {infinitusapi.IINFTaskInputProvider=} [properties] Properties to set
             */
            function INFTaskInputProvider(properties) {
                this.preferredSpecialtyPharmacies = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskInputProvider additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.additionalNotes = "";
    
            /**
             * INFTaskInputProvider address.
             * @member {infinitusapi.IINFAddress|null|undefined} address
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.address = null;
    
            /**
             * INFTaskInputProvider firstName.
             * @member {string} firstName
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.firstName = "";
    
            /**
             * INFTaskInputProvider lastName.
             * @member {string} lastName
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.lastName = "";
    
            /**
             * INFTaskInputProvider networkStatus.
             * @member {infinitusapi.INFBVTaskInputNetworkStatus} networkStatus
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.networkStatus = 0;
    
            /**
             * INFTaskInputProvider npi.
             * @member {string} npi
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.npi = "";
    
            /**
             * INFTaskInputProvider preferredSpecialtyPharmacies.
             * @member {Array.<string>} preferredSpecialtyPharmacies
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.preferredSpecialtyPharmacies = $util.emptyArray;
    
            /**
             * INFTaskInputProvider ptan.
             * @member {string} ptan
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.ptan = "";
    
            /**
             * INFTaskInputProvider taxId.
             * @member {string} taxId
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             */
            INFTaskInputProvider.prototype.taxId = "";
    
            /**
             * Creates a new INFTaskInputProvider instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {infinitusapi.IINFTaskInputProvider=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskInputProvider} INFTaskInputProvider instance
             */
            INFTaskInputProvider.create = function create(properties) {
                return new INFTaskInputProvider(properties);
            };
    
            /**
             * Encodes the specified INFTaskInputProvider message. Does not implicitly {@link infinitusapi.INFTaskInputProvider.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {infinitusapi.IINFTaskInputProvider} message INFTaskInputProvider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputProvider.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.infinitusapi.INFAddress.encode(message.address, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
                if (message.networkStatus != null && Object.hasOwnProperty.call(message, "networkStatus"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.networkStatus);
                if (message.npi != null && Object.hasOwnProperty.call(message, "npi"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.npi);
                if (message.preferredSpecialtyPharmacies != null && message.preferredSpecialtyPharmacies.length)
                    for (var i = 0; i < message.preferredSpecialtyPharmacies.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.preferredSpecialtyPharmacies[i]);
                if (message.ptan != null && Object.hasOwnProperty.call(message, "ptan"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.ptan);
                if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.taxId);
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskInputProvider message, length delimited. Does not implicitly {@link infinitusapi.INFTaskInputProvider.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {infinitusapi.IINFTaskInputProvider} message INFTaskInputProvider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskInputProvider.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskInputProvider message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskInputProvider} INFTaskInputProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputProvider.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskInputProvider();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.address = $root.infinitusapi.INFAddress.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.firstName = reader.string();
                            break;
                        }
                    case 4: {
                            message.lastName = reader.string();
                            break;
                        }
                    case 5: {
                            message.networkStatus = reader.int32();
                            break;
                        }
                    case 6: {
                            message.npi = reader.string();
                            break;
                        }
                    case 7: {
                            if (!(message.preferredSpecialtyPharmacies && message.preferredSpecialtyPharmacies.length))
                                message.preferredSpecialtyPharmacies = [];
                            message.preferredSpecialtyPharmacies.push(reader.string());
                            break;
                        }
                    case 8: {
                            message.ptan = reader.string();
                            break;
                        }
                    case 9: {
                            message.taxId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskInputProvider message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskInputProvider} INFTaskInputProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskInputProvider.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskInputProvider message.
             * @function verify
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskInputProvider.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.infinitusapi.INFAddress.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    if (!$util.isString(message.firstName))
                        return "firstName: string expected";
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    if (!$util.isString(message.lastName))
                        return "lastName: string expected";
                if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                    switch (message.networkStatus) {
                    default:
                        return "networkStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.npi != null && message.hasOwnProperty("npi"))
                    if (!$util.isString(message.npi))
                        return "npi: string expected";
                if (message.preferredSpecialtyPharmacies != null && message.hasOwnProperty("preferredSpecialtyPharmacies")) {
                    if (!Array.isArray(message.preferredSpecialtyPharmacies))
                        return "preferredSpecialtyPharmacies: array expected";
                    for (var i = 0; i < message.preferredSpecialtyPharmacies.length; ++i)
                        if (!$util.isString(message.preferredSpecialtyPharmacies[i]))
                            return "preferredSpecialtyPharmacies: string[] expected";
                }
                if (message.ptan != null && message.hasOwnProperty("ptan"))
                    if (!$util.isString(message.ptan))
                        return "ptan: string expected";
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    if (!$util.isString(message.taxId))
                        return "taxId: string expected";
                return null;
            };
    
            /**
             * Creates a INFTaskInputProvider message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskInputProvider} INFTaskInputProvider
             */
            INFTaskInputProvider.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskInputProvider)
                    return object;
                var message = new $root.infinitusapi.INFTaskInputProvider();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".infinitusapi.INFTaskInputProvider.address: object expected");
                    message.address = $root.infinitusapi.INFAddress.fromObject(object.address);
                }
                if (object.firstName != null)
                    message.firstName = String(object.firstName);
                if (object.lastName != null)
                    message.lastName = String(object.lastName);
                switch (object.networkStatus) {
                default:
                    if (typeof object.networkStatus === "number") {
                        message.networkStatus = object.networkStatus;
                        break;
                    }
                    break;
                case "INF_INPUT_NETWORK_STATUS_UNKNOWN":
                case 0:
                    message.networkStatus = 0;
                    break;
                case "INF_INPUT_NETWORK_STATUS_IN_NETWORK":
                case 1:
                    message.networkStatus = 1;
                    break;
                case "INF_INPUT_NETWORK_STATUS_OUT_OF_NETWORK":
                case 2:
                    message.networkStatus = 2;
                    break;
                }
                if (object.npi != null)
                    message.npi = String(object.npi);
                if (object.preferredSpecialtyPharmacies) {
                    if (!Array.isArray(object.preferredSpecialtyPharmacies))
                        throw TypeError(".infinitusapi.INFTaskInputProvider.preferredSpecialtyPharmacies: array expected");
                    message.preferredSpecialtyPharmacies = [];
                    for (var i = 0; i < object.preferredSpecialtyPharmacies.length; ++i)
                        message.preferredSpecialtyPharmacies[i] = String(object.preferredSpecialtyPharmacies[i]);
                }
                if (object.ptan != null)
                    message.ptan = String(object.ptan);
                if (object.taxId != null)
                    message.taxId = String(object.taxId);
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskInputProvider message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {infinitusapi.INFTaskInputProvider} message INFTaskInputProvider
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskInputProvider.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.preferredSpecialtyPharmacies = [];
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.address = null;
                    object.firstName = "";
                    object.lastName = "";
                    object.networkStatus = options.enums === String ? "INF_INPUT_NETWORK_STATUS_UNKNOWN" : 0;
                    object.npi = "";
                    object.ptan = "";
                    object.taxId = "";
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.infinitusapi.INFAddress.toObject(message.address, options);
                if (message.firstName != null && message.hasOwnProperty("firstName"))
                    object.firstName = message.firstName;
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    object.lastName = message.lastName;
                if (message.networkStatus != null && message.hasOwnProperty("networkStatus"))
                    object.networkStatus = options.enums === String ? $root.infinitusapi.INFBVTaskInputNetworkStatus[message.networkStatus] === undefined ? message.networkStatus : $root.infinitusapi.INFBVTaskInputNetworkStatus[message.networkStatus] : message.networkStatus;
                if (message.npi != null && message.hasOwnProperty("npi"))
                    object.npi = message.npi;
                if (message.preferredSpecialtyPharmacies && message.preferredSpecialtyPharmacies.length) {
                    object.preferredSpecialtyPharmacies = [];
                    for (var j = 0; j < message.preferredSpecialtyPharmacies.length; ++j)
                        object.preferredSpecialtyPharmacies[j] = message.preferredSpecialtyPharmacies[j];
                }
                if (message.ptan != null && message.hasOwnProperty("ptan"))
                    object.ptan = message.ptan;
                if (message.taxId != null && message.hasOwnProperty("taxId"))
                    object.taxId = message.taxId;
                return object;
            };
    
            /**
             * Converts this INFTaskInputProvider to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskInputProvider
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskInputProvider.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskInputProvider
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskInputProvider
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskInputProvider.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskInputProvider";
            };
    
            return INFTaskInputProvider;
        })();
    
        infinitusapi.INFTaskOutput = (function() {
    
            /**
             * Properties of a INFTaskOutput.
             * @memberof infinitusapi
             * @interface IINFTaskOutput
             * @property {string|null} [additionalNotes] INFTaskOutput additionalNotes
             * @property {infinitusapi.INFFailureReason|null} [failureReason] INFTaskOutput failureReason
             * @property {Array.<infinitusapi.IINFOutputValue>|null} [outputs] INFTaskOutput outputs
             * @property {string|null} [taskLink] INFTaskOutput taskLink
             */
    
            /**
             * Constructs a new INFTaskOutput.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskOutput.
             * @implements IINFTaskOutput
             * @constructor
             * @param {infinitusapi.IINFTaskOutput=} [properties] Properties to set
             */
            function INFTaskOutput(properties) {
                this.outputs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskOutput additionalNotes.
             * @member {string} additionalNotes
             * @memberof infinitusapi.INFTaskOutput
             * @instance
             */
            INFTaskOutput.prototype.additionalNotes = "";
    
            /**
             * INFTaskOutput failureReason.
             * @member {infinitusapi.INFFailureReason} failureReason
             * @memberof infinitusapi.INFTaskOutput
             * @instance
             */
            INFTaskOutput.prototype.failureReason = 0;
    
            /**
             * INFTaskOutput outputs.
             * @member {Array.<infinitusapi.IINFOutputValue>} outputs
             * @memberof infinitusapi.INFTaskOutput
             * @instance
             */
            INFTaskOutput.prototype.outputs = $util.emptyArray;
    
            /**
             * INFTaskOutput taskLink.
             * @member {string} taskLink
             * @memberof infinitusapi.INFTaskOutput
             * @instance
             */
            INFTaskOutput.prototype.taskLink = "";
    
            /**
             * Creates a new INFTaskOutput instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {infinitusapi.IINFTaskOutput=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskOutput} INFTaskOutput instance
             */
            INFTaskOutput.create = function create(properties) {
                return new INFTaskOutput(properties);
            };
    
            /**
             * Encodes the specified INFTaskOutput message. Does not implicitly {@link infinitusapi.INFTaskOutput.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {infinitusapi.IINFTaskOutput} message INFTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskOutput.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.additionalNotes != null && Object.hasOwnProperty.call(message, "additionalNotes"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.additionalNotes);
                if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.failureReason);
                if (message.outputs != null && message.outputs.length)
                    for (var i = 0; i < message.outputs.length; ++i)
                        $root.infinitusapi.INFOutputValue.encode(message.outputs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.taskLink != null && Object.hasOwnProperty.call(message, "taskLink"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskLink);
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskOutput message, length delimited. Does not implicitly {@link infinitusapi.INFTaskOutput.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {infinitusapi.IINFTaskOutput} message INFTaskOutput message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskOutput.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskOutput message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskOutput} INFTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskOutput.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskOutput();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.additionalNotes = reader.string();
                            break;
                        }
                    case 2: {
                            message.failureReason = reader.int32();
                            break;
                        }
                    case 3: {
                            if (!(message.outputs && message.outputs.length))
                                message.outputs = [];
                            message.outputs.push($root.infinitusapi.INFOutputValue.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            message.taskLink = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskOutput message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskOutput} INFTaskOutput
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskOutput.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskOutput message.
             * @function verify
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskOutput.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    if (!$util.isString(message.additionalNotes))
                        return "additionalNotes: string expected";
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    switch (message.failureReason) {
                    default:
                        return "failureReason: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.outputs != null && message.hasOwnProperty("outputs")) {
                    if (!Array.isArray(message.outputs))
                        return "outputs: array expected";
                    for (var i = 0; i < message.outputs.length; ++i) {
                        var error = $root.infinitusapi.INFOutputValue.verify(message.outputs[i]);
                        if (error)
                            return "outputs." + error;
                    }
                }
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    if (!$util.isString(message.taskLink))
                        return "taskLink: string expected";
                return null;
            };
    
            /**
             * Creates a INFTaskOutput message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskOutput} INFTaskOutput
             */
            INFTaskOutput.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskOutput)
                    return object;
                var message = new $root.infinitusapi.INFTaskOutput();
                if (object.additionalNotes != null)
                    message.additionalNotes = String(object.additionalNotes);
                switch (object.failureReason) {
                default:
                    if (typeof object.failureReason === "number") {
                        message.failureReason = object.failureReason;
                        break;
                    }
                    break;
                case "INF_FAILURE_REASON_UNKNOWN":
                case 0:
                    message.failureReason = 0;
                    break;
                case "INF_FAILURE_REASON_BAD_DATA":
                case 1:
                    message.failureReason = 1;
                    break;
                case "INF_FAILURE_REASON_PAYER_UNREACHABLE":
                case 2:
                    message.failureReason = 2;
                    break;
                case "INF_FAILURE_REASON_PAYER_REFUSED_THIRD_PARTY":
                case 3:
                    message.failureReason = 3;
                    break;
                case "INF_FAILURE_REASON_CANCELLED_BY_CUSTOMER":
                case 4:
                    message.failureReason = 4;
                    break;
                case "INF_FAILURE_REASON_TASK_EXPIRED":
                case 5:
                    message.failureReason = 5;
                    break;
                case "INF_FAILURE_REASON_OTHER":
                case 6:
                    message.failureReason = 6;
                    break;
                case "INF_FAILURE_REASON_PRESCRIBER_UNREACHABLE":
                case 7:
                    message.failureReason = 7;
                    break;
                }
                if (object.outputs) {
                    if (!Array.isArray(object.outputs))
                        throw TypeError(".infinitusapi.INFTaskOutput.outputs: array expected");
                    message.outputs = [];
                    for (var i = 0; i < object.outputs.length; ++i) {
                        if (typeof object.outputs[i] !== "object")
                            throw TypeError(".infinitusapi.INFTaskOutput.outputs: object expected");
                        message.outputs[i] = $root.infinitusapi.INFOutputValue.fromObject(object.outputs[i]);
                    }
                }
                if (object.taskLink != null)
                    message.taskLink = String(object.taskLink);
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskOutput message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {infinitusapi.INFTaskOutput} message INFTaskOutput
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskOutput.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.outputs = [];
                if (options.defaults) {
                    object.additionalNotes = "";
                    object.failureReason = options.enums === String ? "INF_FAILURE_REASON_UNKNOWN" : 0;
                    object.taskLink = "";
                }
                if (message.additionalNotes != null && message.hasOwnProperty("additionalNotes"))
                    object.additionalNotes = message.additionalNotes;
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    object.failureReason = options.enums === String ? $root.infinitusapi.INFFailureReason[message.failureReason] === undefined ? message.failureReason : $root.infinitusapi.INFFailureReason[message.failureReason] : message.failureReason;
                if (message.outputs && message.outputs.length) {
                    object.outputs = [];
                    for (var j = 0; j < message.outputs.length; ++j)
                        object.outputs[j] = $root.infinitusapi.INFOutputValue.toObject(message.outputs[j], options);
                }
                if (message.taskLink != null && message.hasOwnProperty("taskLink"))
                    object.taskLink = message.taskLink;
                return object;
            };
    
            /**
             * Converts this INFTaskOutput to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskOutput
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskOutput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskOutput
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskOutput
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskOutput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskOutput";
            };
    
            return INFTaskOutput;
        })();
    
        infinitusapi.INFTaskOutputErrorInfo = (function() {
    
            /**
             * Properties of a INFTaskOutputErrorInfo.
             * @memberof infinitusapi
             * @interface IINFTaskOutputErrorInfo
             * @property {infinitusapi.INFFailureReason|null} [failureReason] INFTaskOutputErrorInfo failureReason
             */
    
            /**
             * Constructs a new INFTaskOutputErrorInfo.
             * @memberof infinitusapi
             * @classdesc Represents a INFTaskOutputErrorInfo.
             * @implements IINFTaskOutputErrorInfo
             * @constructor
             * @param {infinitusapi.IINFTaskOutputErrorInfo=} [properties] Properties to set
             */
            function INFTaskOutputErrorInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFTaskOutputErrorInfo failureReason.
             * @member {infinitusapi.INFFailureReason} failureReason
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @instance
             */
            INFTaskOutputErrorInfo.prototype.failureReason = 0;
    
            /**
             * Creates a new INFTaskOutputErrorInfo instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFTaskOutputErrorInfo=} [properties] Properties to set
             * @returns {infinitusapi.INFTaskOutputErrorInfo} INFTaskOutputErrorInfo instance
             */
            INFTaskOutputErrorInfo.create = function create(properties) {
                return new INFTaskOutputErrorInfo(properties);
            };
    
            /**
             * Encodes the specified INFTaskOutputErrorInfo message. Does not implicitly {@link infinitusapi.INFTaskOutputErrorInfo.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFTaskOutputErrorInfo} message INFTaskOutputErrorInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskOutputErrorInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.failureReason);
                return writer;
            };
    
            /**
             * Encodes the specified INFTaskOutputErrorInfo message, length delimited. Does not implicitly {@link infinitusapi.INFTaskOutputErrorInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.IINFTaskOutputErrorInfo} message INFTaskOutputErrorInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFTaskOutputErrorInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFTaskOutputErrorInfo message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFTaskOutputErrorInfo} INFTaskOutputErrorInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskOutputErrorInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFTaskOutputErrorInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.failureReason = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFTaskOutputErrorInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFTaskOutputErrorInfo} INFTaskOutputErrorInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFTaskOutputErrorInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFTaskOutputErrorInfo message.
             * @function verify
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFTaskOutputErrorInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    switch (message.failureReason) {
                    default:
                        return "failureReason: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a INFTaskOutputErrorInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFTaskOutputErrorInfo} INFTaskOutputErrorInfo
             */
            INFTaskOutputErrorInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFTaskOutputErrorInfo)
                    return object;
                var message = new $root.infinitusapi.INFTaskOutputErrorInfo();
                switch (object.failureReason) {
                default:
                    if (typeof object.failureReason === "number") {
                        message.failureReason = object.failureReason;
                        break;
                    }
                    break;
                case "INF_FAILURE_REASON_UNKNOWN":
                case 0:
                    message.failureReason = 0;
                    break;
                case "INF_FAILURE_REASON_BAD_DATA":
                case 1:
                    message.failureReason = 1;
                    break;
                case "INF_FAILURE_REASON_PAYER_UNREACHABLE":
                case 2:
                    message.failureReason = 2;
                    break;
                case "INF_FAILURE_REASON_PAYER_REFUSED_THIRD_PARTY":
                case 3:
                    message.failureReason = 3;
                    break;
                case "INF_FAILURE_REASON_CANCELLED_BY_CUSTOMER":
                case 4:
                    message.failureReason = 4;
                    break;
                case "INF_FAILURE_REASON_TASK_EXPIRED":
                case 5:
                    message.failureReason = 5;
                    break;
                case "INF_FAILURE_REASON_OTHER":
                case 6:
                    message.failureReason = 6;
                    break;
                case "INF_FAILURE_REASON_PRESCRIBER_UNREACHABLE":
                case 7:
                    message.failureReason = 7;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a INFTaskOutputErrorInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {infinitusapi.INFTaskOutputErrorInfo} message INFTaskOutputErrorInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFTaskOutputErrorInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.failureReason = options.enums === String ? "INF_FAILURE_REASON_UNKNOWN" : 0;
                if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                    object.failureReason = options.enums === String ? $root.infinitusapi.INFFailureReason[message.failureReason] === undefined ? message.failureReason : $root.infinitusapi.INFFailureReason[message.failureReason] : message.failureReason;
                return object;
            };
    
            /**
             * Converts this INFTaskOutputErrorInfo to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFTaskOutputErrorInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFTaskOutputErrorInfo
             * @function getTypeUrl
             * @memberof infinitusapi.INFTaskOutputErrorInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFTaskOutputErrorInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFTaskOutputErrorInfo";
            };
    
            return INFTaskOutputErrorInfo;
        })();
    
        infinitusapi.INFWebhookBasicAuth = (function() {
    
            /**
             * Properties of a INFWebhookBasicAuth.
             * @memberof infinitusapi
             * @interface IINFWebhookBasicAuth
             * @property {string|null} [password] INFWebhookBasicAuth password
             * @property {string|null} [username] INFWebhookBasicAuth username
             */
    
            /**
             * Constructs a new INFWebhookBasicAuth.
             * @memberof infinitusapi
             * @classdesc Represents a INFWebhookBasicAuth.
             * @implements IINFWebhookBasicAuth
             * @constructor
             * @param {infinitusapi.IINFWebhookBasicAuth=} [properties] Properties to set
             */
            function INFWebhookBasicAuth(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * INFWebhookBasicAuth password.
             * @member {string} password
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @instance
             */
            INFWebhookBasicAuth.prototype.password = "";
    
            /**
             * INFWebhookBasicAuth username.
             * @member {string} username
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @instance
             */
            INFWebhookBasicAuth.prototype.username = "";
    
            /**
             * Creates a new INFWebhookBasicAuth instance using the specified properties.
             * @function create
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {infinitusapi.IINFWebhookBasicAuth=} [properties] Properties to set
             * @returns {infinitusapi.INFWebhookBasicAuth} INFWebhookBasicAuth instance
             */
            INFWebhookBasicAuth.create = function create(properties) {
                return new INFWebhookBasicAuth(properties);
            };
    
            /**
             * Encodes the specified INFWebhookBasicAuth message. Does not implicitly {@link infinitusapi.INFWebhookBasicAuth.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {infinitusapi.IINFWebhookBasicAuth} message INFWebhookBasicAuth message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFWebhookBasicAuth.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.password);
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                return writer;
            };
    
            /**
             * Encodes the specified INFWebhookBasicAuth message, length delimited. Does not implicitly {@link infinitusapi.INFWebhookBasicAuth.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {infinitusapi.IINFWebhookBasicAuth} message INFWebhookBasicAuth message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            INFWebhookBasicAuth.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a INFWebhookBasicAuth message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.INFWebhookBasicAuth} INFWebhookBasicAuth
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFWebhookBasicAuth.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.INFWebhookBasicAuth();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.password = reader.string();
                            break;
                        }
                    case 2: {
                            message.username = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a INFWebhookBasicAuth message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.INFWebhookBasicAuth} INFWebhookBasicAuth
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            INFWebhookBasicAuth.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a INFWebhookBasicAuth message.
             * @function verify
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            INFWebhookBasicAuth.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                return null;
            };
    
            /**
             * Creates a INFWebhookBasicAuth message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.INFWebhookBasicAuth} INFWebhookBasicAuth
             */
            INFWebhookBasicAuth.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.INFWebhookBasicAuth)
                    return object;
                var message = new $root.infinitusapi.INFWebhookBasicAuth();
                if (object.password != null)
                    message.password = String(object.password);
                if (object.username != null)
                    message.username = String(object.username);
                return message;
            };
    
            /**
             * Creates a plain object from a INFWebhookBasicAuth message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {infinitusapi.INFWebhookBasicAuth} message INFWebhookBasicAuth
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            INFWebhookBasicAuth.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.password = "";
                    object.username = "";
                }
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                return object;
            };
    
            /**
             * Converts this INFWebhookBasicAuth to JSON.
             * @function toJSON
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            INFWebhookBasicAuth.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for INFWebhookBasicAuth
             * @function getTypeUrl
             * @memberof infinitusapi.INFWebhookBasicAuth
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            INFWebhookBasicAuth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.INFWebhookBasicAuth";
            };
    
            return INFWebhookBasicAuth;
        })();
    
        infinitusapi.ListPayersResponse = (function() {
    
            /**
             * Properties of a ListPayersResponse.
             * @memberof infinitusapi
             * @interface IListPayersResponse
             * @property {Array.<infinitusapi.ListPayersResponse.IDataMessage>|null} [data] ListPayersResponse data
             */
    
            /**
             * Constructs a new ListPayersResponse.
             * @memberof infinitusapi
             * @classdesc Represents a ListPayersResponse.
             * @implements IListPayersResponse
             * @constructor
             * @param {infinitusapi.IListPayersResponse=} [properties] Properties to set
             */
            function ListPayersResponse(properties) {
                this.data = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ListPayersResponse data.
             * @member {Array.<infinitusapi.ListPayersResponse.IDataMessage>} data
             * @memberof infinitusapi.ListPayersResponse
             * @instance
             */
            ListPayersResponse.prototype.data = $util.emptyArray;
    
            /**
             * Creates a new ListPayersResponse instance using the specified properties.
             * @function create
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {infinitusapi.IListPayersResponse=} [properties] Properties to set
             * @returns {infinitusapi.ListPayersResponse} ListPayersResponse instance
             */
            ListPayersResponse.create = function create(properties) {
                return new ListPayersResponse(properties);
            };
    
            /**
             * Encodes the specified ListPayersResponse message. Does not implicitly {@link infinitusapi.ListPayersResponse.verify|verify} messages.
             * @function encode
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {infinitusapi.IListPayersResponse} message ListPayersResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListPayersResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && message.data.length)
                    for (var i = 0; i < message.data.length; ++i)
                        $root.infinitusapi.ListPayersResponse.DataMessage.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ListPayersResponse message, length delimited. Does not implicitly {@link infinitusapi.ListPayersResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {infinitusapi.IListPayersResponse} message ListPayersResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListPayersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListPayersResponse message from the specified reader or buffer.
             * @function decode
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {infinitusapi.ListPayersResponse} ListPayersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListPayersResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.ListPayersResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.data && message.data.length))
                                message.data = [];
                            message.data.push($root.infinitusapi.ListPayersResponse.DataMessage.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListPayersResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {infinitusapi.ListPayersResponse} ListPayersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListPayersResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListPayersResponse message.
             * @function verify
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListPayersResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data")) {
                    if (!Array.isArray(message.data))
                        return "data: array expected";
                    for (var i = 0; i < message.data.length; ++i) {
                        var error = $root.infinitusapi.ListPayersResponse.DataMessage.verify(message.data[i]);
                        if (error)
                            return "data." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a ListPayersResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {infinitusapi.ListPayersResponse} ListPayersResponse
             */
            ListPayersResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.infinitusapi.ListPayersResponse)
                    return object;
                var message = new $root.infinitusapi.ListPayersResponse();
                if (object.data) {
                    if (!Array.isArray(object.data))
                        throw TypeError(".infinitusapi.ListPayersResponse.data: array expected");
                    message.data = [];
                    for (var i = 0; i < object.data.length; ++i) {
                        if (typeof object.data[i] !== "object")
                            throw TypeError(".infinitusapi.ListPayersResponse.data: object expected");
                        message.data[i] = $root.infinitusapi.ListPayersResponse.DataMessage.fromObject(object.data[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ListPayersResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {infinitusapi.ListPayersResponse} message ListPayersResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListPayersResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.data = [];
                if (message.data && message.data.length) {
                    object.data = [];
                    for (var j = 0; j < message.data.length; ++j)
                        object.data[j] = $root.infinitusapi.ListPayersResponse.DataMessage.toObject(message.data[j], options);
                }
                return object;
            };
    
            /**
             * Converts this ListPayersResponse to JSON.
             * @function toJSON
             * @memberof infinitusapi.ListPayersResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListPayersResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ListPayersResponse
             * @function getTypeUrl
             * @memberof infinitusapi.ListPayersResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListPayersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/infinitusapi.ListPayersResponse";
            };
    
            ListPayersResponse.DataMessage = (function() {
    
                /**
                 * Properties of a DataMessage.
                 * @memberof infinitusapi.ListPayersResponse
                 * @interface IDataMessage
                 * @property {string|null} [id] DataMessage id
                 * @property {string|null} [name] DataMessage name
                 * @property {Array.<string>|null} [states] DataMessage states
                 */
    
                /**
                 * Constructs a new DataMessage.
                 * @memberof infinitusapi.ListPayersResponse
                 * @classdesc Represents a DataMessage.
                 * @implements IDataMessage
                 * @constructor
                 * @param {infinitusapi.ListPayersResponse.IDataMessage=} [properties] Properties to set
                 */
                function DataMessage(properties) {
                    this.states = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DataMessage id.
                 * @member {string} id
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.id = "";
    
                /**
                 * DataMessage name.
                 * @member {string} name
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.name = "";
    
                /**
                 * DataMessage states.
                 * @member {Array.<string>} states
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @instance
                 */
                DataMessage.prototype.states = $util.emptyArray;
    
                /**
                 * Creates a new DataMessage instance using the specified properties.
                 * @function create
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {infinitusapi.ListPayersResponse.IDataMessage=} [properties] Properties to set
                 * @returns {infinitusapi.ListPayersResponse.DataMessage} DataMessage instance
                 */
                DataMessage.create = function create(properties) {
                    return new DataMessage(properties);
                };
    
                /**
                 * Encodes the specified DataMessage message. Does not implicitly {@link infinitusapi.ListPayersResponse.DataMessage.verify|verify} messages.
                 * @function encode
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {infinitusapi.ListPayersResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.states != null && message.states.length)
                        for (var i = 0; i < message.states.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.states[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified DataMessage message, length delimited. Does not implicitly {@link infinitusapi.ListPayersResponse.DataMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {infinitusapi.ListPayersResponse.IDataMessage} message DataMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DataMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {infinitusapi.ListPayersResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.infinitusapi.ListPayersResponse.DataMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.states && message.states.length))
                                    message.states = [];
                                message.states.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DataMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {infinitusapi.ListPayersResponse.DataMessage} DataMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DataMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DataMessage message.
                 * @function verify
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DataMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.states != null && message.hasOwnProperty("states")) {
                        if (!Array.isArray(message.states))
                            return "states: array expected";
                        for (var i = 0; i < message.states.length; ++i)
                            if (!$util.isString(message.states[i]))
                                return "states: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a DataMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {infinitusapi.ListPayersResponse.DataMessage} DataMessage
                 */
                DataMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.infinitusapi.ListPayersResponse.DataMessage)
                        return object;
                    var message = new $root.infinitusapi.ListPayersResponse.DataMessage();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.states) {
                        if (!Array.isArray(object.states))
                            throw TypeError(".infinitusapi.ListPayersResponse.DataMessage.states: array expected");
                        message.states = [];
                        for (var i = 0; i < object.states.length; ++i)
                            message.states[i] = String(object.states[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DataMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {infinitusapi.ListPayersResponse.DataMessage} message DataMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DataMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.states = [];
                    if (options.defaults) {
                        object.id = "";
                        object.name = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.states && message.states.length) {
                        object.states = [];
                        for (var j = 0; j < message.states.length; ++j)
                            object.states[j] = message.states[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this DataMessage to JSON.
                 * @function toJSON
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DataMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DataMessage
                 * @function getTypeUrl
                 * @memberof infinitusapi.ListPayersResponse.DataMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DataMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/infinitusapi.ListPayersResponse.DataMessage";
                };
    
                return DataMessage;
            })();
    
            return ListPayersResponse;
        })();
    
        return infinitusapi;
    })();
    
    $root.google = (function() {
    
        /**
         * Namespace google.
         * @exports google
         * @namespace
         */
        var google = {};
    
        google.protobuf = (function() {
    
            /**
             * Namespace protobuf.
             * @memberof google
             * @namespace
             */
            var protobuf = {};
    
            protobuf.FileDescriptorSet = (function() {
    
                /**
                 * Properties of a FileDescriptorSet.
                 * @memberof google.protobuf
                 * @interface IFileDescriptorSet
                 * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
                 */
    
                /**
                 * Constructs a new FileDescriptorSet.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileDescriptorSet.
                 * @implements IFileDescriptorSet
                 * @constructor
                 * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
                 */
                function FileDescriptorSet(properties) {
                    this.file = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileDescriptorSet file.
                 * @member {Array.<google.protobuf.IFileDescriptorProto>} file
                 * @memberof google.protobuf.FileDescriptorSet
                 * @instance
                 */
                FileDescriptorSet.prototype.file = $util.emptyArray;
    
                /**
                 * Creates a new FileDescriptorSet instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
                 */
                FileDescriptorSet.create = function create(properties) {
                    return new FileDescriptorSet(properties);
                };
    
                /**
                 * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorSet.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.file != null && message.file.length)
                        for (var i = 0; i < message.file.length; ++i)
                            $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileDescriptorSet message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorSet.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.file && message.file.length))
                                    message.file = [];
                                message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileDescriptorSet message.
                 * @function verify
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileDescriptorSet.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.file != null && message.hasOwnProperty("file")) {
                        if (!Array.isArray(message.file))
                            return "file: array expected";
                        for (var i = 0; i < message.file.length; ++i) {
                            var error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                            if (error)
                                return "file." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 */
                FileDescriptorSet.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileDescriptorSet)
                        return object;
                    var message = new $root.google.protobuf.FileDescriptorSet();
                    if (object.file) {
                        if (!Array.isArray(object.file))
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                        message.file = [];
                        for (var i = 0; i < object.file.length; ++i) {
                            if (typeof object.file[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                            message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileDescriptorSet.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.file = [];
                    if (message.file && message.file.length) {
                        object.file = [];
                        for (var j = 0; j < message.file.length; ++j)
                            object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FileDescriptorSet to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileDescriptorSet
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileDescriptorSet.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FileDescriptorSet
                 * @function getTypeUrl
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FileDescriptorSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FileDescriptorSet";
                };
    
                return FileDescriptorSet;
            })();
    
            protobuf.FileDescriptorProto = (function() {
    
                /**
                 * Properties of a FileDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IFileDescriptorProto
                 * @property {string|null} [name] FileDescriptorProto name
                 * @property {string|null} ["package"] FileDescriptorProto package
                 * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
                 * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
                 * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
                 * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
                 * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
                 * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
                 * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
                 * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
                 * @property {string|null} [syntax] FileDescriptorProto syntax
                 */
    
                /**
                 * Constructs a new FileDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileDescriptorProto.
                 * @implements IFileDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
                 */
                function FileDescriptorProto(properties) {
                    this.dependency = [];
                    this.publicDependency = [];
                    this.weakDependency = [];
                    this.messageType = [];
                    this.enumType = [];
                    this.service = [];
                    this.extension = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.name = "";
    
                /**
                 * FileDescriptorProto package.
                 * @member {string} package
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype["package"] = "";
    
                /**
                 * FileDescriptorProto dependency.
                 * @member {Array.<string>} dependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.dependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto publicDependency.
                 * @member {Array.<number>} publicDependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.publicDependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto weakDependency.
                 * @member {Array.<number>} weakDependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.weakDependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto messageType.
                 * @member {Array.<google.protobuf.IDescriptorProto>} messageType
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.messageType = $util.emptyArray;
    
                /**
                 * FileDescriptorProto enumType.
                 * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.enumType = $util.emptyArray;
    
                /**
                 * FileDescriptorProto service.
                 * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.service = $util.emptyArray;
    
                /**
                 * FileDescriptorProto extension.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.extension = $util.emptyArray;
    
                /**
                 * FileDescriptorProto options.
                 * @member {google.protobuf.IFileOptions|null|undefined} options
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.options = null;
    
                /**
                 * FileDescriptorProto sourceCodeInfo.
                 * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.sourceCodeInfo = null;
    
                /**
                 * FileDescriptorProto syntax.
                 * @member {string} syntax
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.syntax = "";
    
                /**
                 * Creates a new FileDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
                 */
                FileDescriptorProto.create = function create(properties) {
                    return new FileDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                    if (message.dependency != null && message.dependency.length)
                        for (var i = 0; i < message.dependency.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                    if (message.messageType != null && message.messageType.length)
                        for (var i = 0; i < message.messageType.length; ++i)
                            $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.enumType != null && message.enumType.length)
                        for (var i = 0; i < message.enumType.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.service != null && message.service.length)
                        for (var i = 0; i < message.service.length; ++i)
                            $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.extension != null && message.extension.length)
                        for (var i = 0; i < message.extension.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                        $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.publicDependency != null && message.publicDependency.length)
                        for (var i = 0; i < message.publicDependency.length; ++i)
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                    if (message.weakDependency != null && message.weakDependency.length)
                        for (var i = 0; i < message.weakDependency.length; ++i)
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                    if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                    return writer;
                };
    
                /**
                 * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message["package"] = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.dependency && message.dependency.length))
                                    message.dependency = [];
                                message.dependency.push(reader.string());
                                break;
                            }
                        case 10: {
                                if (!(message.publicDependency && message.publicDependency.length))
                                    message.publicDependency = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.publicDependency.push(reader.int32());
                                } else
                                    message.publicDependency.push(reader.int32());
                                break;
                            }
                        case 11: {
                                if (!(message.weakDependency && message.weakDependency.length))
                                    message.weakDependency = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.weakDependency.push(reader.int32());
                                } else
                                    message.weakDependency.push(reader.int32());
                                break;
                            }
                        case 4: {
                                if (!(message.messageType && message.messageType.length))
                                    message.messageType = [];
                                message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(message.enumType && message.enumType.length))
                                    message.enumType = [];
                                message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                if (!(message.service && message.service.length))
                                    message.service = [];
                                message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 7: {
                                if (!(message.extension && message.extension.length))
                                    message.extension = [];
                                message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 8: {
                                message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.syntax = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message["package"] != null && message.hasOwnProperty("package"))
                        if (!$util.isString(message["package"]))
                            return "package: string expected";
                    if (message.dependency != null && message.hasOwnProperty("dependency")) {
                        if (!Array.isArray(message.dependency))
                            return "dependency: array expected";
                        for (var i = 0; i < message.dependency.length; ++i)
                            if (!$util.isString(message.dependency[i]))
                                return "dependency: string[] expected";
                    }
                    if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                        if (!Array.isArray(message.publicDependency))
                            return "publicDependency: array expected";
                        for (var i = 0; i < message.publicDependency.length; ++i)
                            if (!$util.isInteger(message.publicDependency[i]))
                                return "publicDependency: integer[] expected";
                    }
                    if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                        if (!Array.isArray(message.weakDependency))
                            return "weakDependency: array expected";
                        for (var i = 0; i < message.weakDependency.length; ++i)
                            if (!$util.isInteger(message.weakDependency[i]))
                                return "weakDependency: integer[] expected";
                    }
                    if (message.messageType != null && message.hasOwnProperty("messageType")) {
                        if (!Array.isArray(message.messageType))
                            return "messageType: array expected";
                        for (var i = 0; i < message.messageType.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                            if (error)
                                return "messageType." + error;
                        }
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (!Array.isArray(message.enumType))
                            return "enumType: array expected";
                        for (var i = 0; i < message.enumType.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                            if (error)
                                return "enumType." + error;
                        }
                    }
                    if (message.service != null && message.hasOwnProperty("service")) {
                        if (!Array.isArray(message.service))
                            return "service: array expected";
                        for (var i = 0; i < message.service.length; ++i) {
                            var error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                            if (error)
                                return "service." + error;
                        }
                    }
                    if (message.extension != null && message.hasOwnProperty("extension")) {
                        if (!Array.isArray(message.extension))
                            return "extension: array expected";
                        for (var i = 0; i < message.extension.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                            if (error)
                                return "extension." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.FileOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                        var error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                        if (error)
                            return "sourceCodeInfo." + error;
                    }
                    if (message.syntax != null && message.hasOwnProperty("syntax"))
                        if (!$util.isString(message.syntax))
                            return "syntax: string expected";
                    return null;
                };
    
                /**
                 * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 */
                FileDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.FileDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object["package"] != null)
                        message["package"] = String(object["package"]);
                    if (object.dependency) {
                        if (!Array.isArray(object.dependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                        message.dependency = [];
                        for (var i = 0; i < object.dependency.length; ++i)
                            message.dependency[i] = String(object.dependency[i]);
                    }
                    if (object.publicDependency) {
                        if (!Array.isArray(object.publicDependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                        message.publicDependency = [];
                        for (var i = 0; i < object.publicDependency.length; ++i)
                            message.publicDependency[i] = object.publicDependency[i] | 0;
                    }
                    if (object.weakDependency) {
                        if (!Array.isArray(object.weakDependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                        message.weakDependency = [];
                        for (var i = 0; i < object.weakDependency.length; ++i)
                            message.weakDependency[i] = object.weakDependency[i] | 0;
                    }
                    if (object.messageType) {
                        if (!Array.isArray(object.messageType))
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                        message.messageType = [];
                        for (var i = 0; i < object.messageType.length; ++i) {
                            if (typeof object.messageType[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                            message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                        }
                    }
                    if (object.enumType) {
                        if (!Array.isArray(object.enumType))
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                        message.enumType = [];
                        for (var i = 0; i < object.enumType.length; ++i) {
                            if (typeof object.enumType[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                        }
                    }
                    if (object.service) {
                        if (!Array.isArray(object.service))
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                        message.service = [];
                        for (var i = 0; i < object.service.length; ++i) {
                            if (typeof object.service[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                            message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                        }
                    }
                    if (object.extension) {
                        if (!Array.isArray(object.extension))
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                        message.extension = [];
                        for (var i = 0; i < object.extension.length; ++i) {
                            if (typeof object.extension[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                    }
                    if (object.sourceCodeInfo != null) {
                        if (typeof object.sourceCodeInfo !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                        message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                    }
                    if (object.syntax != null)
                        message.syntax = String(object.syntax);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.dependency = [];
                        object.messageType = [];
                        object.enumType = [];
                        object.service = [];
                        object.extension = [];
                        object.publicDependency = [];
                        object.weakDependency = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object["package"] = "";
                        object.options = null;
                        object.sourceCodeInfo = null;
                        object.syntax = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message["package"] != null && message.hasOwnProperty("package"))
                        object["package"] = message["package"];
                    if (message.dependency && message.dependency.length) {
                        object.dependency = [];
                        for (var j = 0; j < message.dependency.length; ++j)
                            object.dependency[j] = message.dependency[j];
                    }
                    if (message.messageType && message.messageType.length) {
                        object.messageType = [];
                        for (var j = 0; j < message.messageType.length; ++j)
                            object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                    }
                    if (message.enumType && message.enumType.length) {
                        object.enumType = [];
                        for (var j = 0; j < message.enumType.length; ++j)
                            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                    }
                    if (message.service && message.service.length) {
                        object.service = [];
                        for (var j = 0; j < message.service.length; ++j)
                            object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                    }
                    if (message.extension && message.extension.length) {
                        object.extension = [];
                        for (var j = 0; j < message.extension.length; ++j)
                            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                    if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                        object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                    if (message.publicDependency && message.publicDependency.length) {
                        object.publicDependency = [];
                        for (var j = 0; j < message.publicDependency.length; ++j)
                            object.publicDependency[j] = message.publicDependency[j];
                    }
                    if (message.weakDependency && message.weakDependency.length) {
                        object.weakDependency = [];
                        for (var j = 0; j < message.weakDependency.length; ++j)
                            object.weakDependency[j] = message.weakDependency[j];
                    }
                    if (message.syntax != null && message.hasOwnProperty("syntax"))
                        object.syntax = message.syntax;
                    return object;
                };
    
                /**
                 * Converts this FileDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FileDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FileDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FileDescriptorProto";
                };
    
                return FileDescriptorProto;
            })();
    
            protobuf.DescriptorProto = (function() {
    
                /**
                 * Properties of a DescriptorProto.
                 * @memberof google.protobuf
                 * @interface IDescriptorProto
                 * @property {string|null} [name] DescriptorProto name
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
                 * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
                 * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
                 * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
                 * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
                 * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
                 * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
                 * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
                 */
    
                /**
                 * Constructs a new DescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a DescriptorProto.
                 * @implements IDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
                 */
                function DescriptorProto(properties) {
                    this.field = [];
                    this.extension = [];
                    this.nestedType = [];
                    this.enumType = [];
                    this.extensionRange = [];
                    this.oneofDecl = [];
                    this.reservedRange = [];
                    this.reservedName = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.name = "";
    
                /**
                 * DescriptorProto field.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.field = $util.emptyArray;
    
                /**
                 * DescriptorProto extension.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.extension = $util.emptyArray;
    
                /**
                 * DescriptorProto nestedType.
                 * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.nestedType = $util.emptyArray;
    
                /**
                 * DescriptorProto enumType.
                 * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.enumType = $util.emptyArray;
    
                /**
                 * DescriptorProto extensionRange.
                 * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.extensionRange = $util.emptyArray;
    
                /**
                 * DescriptorProto oneofDecl.
                 * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.oneofDecl = $util.emptyArray;
    
                /**
                 * DescriptorProto options.
                 * @member {google.protobuf.IMessageOptions|null|undefined} options
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.options = null;
    
                /**
                 * DescriptorProto reservedRange.
                 * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.reservedRange = $util.emptyArray;
    
                /**
                 * DescriptorProto reservedName.
                 * @member {Array.<string>} reservedName
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.reservedName = $util.emptyArray;
    
                /**
                 * Creates a new DescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
                 */
                DescriptorProto.create = function create(properties) {
                    return new DescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.field != null && message.field.length)
                        for (var i = 0; i < message.field.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.nestedType != null && message.nestedType.length)
                        for (var i = 0; i < message.nestedType.length; ++i)
                            $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.enumType != null && message.enumType.length)
                        for (var i = 0; i < message.enumType.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.extensionRange != null && message.extensionRange.length)
                        for (var i = 0; i < message.extensionRange.length; ++i)
                            $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.extension != null && message.extension.length)
                        for (var i = 0; i < message.extension.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.oneofDecl != null && message.oneofDecl.length)
                        for (var i = 0; i < message.oneofDecl.length; ++i)
                            $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.reservedRange != null && message.reservedRange.length)
                        for (var i = 0; i < message.reservedRange.length; ++i)
                            $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.reservedName != null && message.reservedName.length)
                        for (var i = 0; i < message.reservedName.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.field && message.field.length))
                                    message.field = [];
                                message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                if (!(message.extension && message.extension.length))
                                    message.extension = [];
                                message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.nestedType && message.nestedType.length))
                                    message.nestedType = [];
                                message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.enumType && message.enumType.length))
                                    message.enumType = [];
                                message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(message.extensionRange && message.extensionRange.length))
                                    message.extensionRange = [];
                                message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                                break;
                            }
                        case 8: {
                                if (!(message.oneofDecl && message.oneofDecl.length))
                                    message.oneofDecl = [];
                                message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 7: {
                                message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                if (!(message.reservedRange && message.reservedRange.length))
                                    message.reservedRange = [];
                                message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                if (!(message.reservedName && message.reservedName.length))
                                    message.reservedName = [];
                                message.reservedName.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.field != null && message.hasOwnProperty("field")) {
                        if (!Array.isArray(message.field))
                            return "field: array expected";
                        for (var i = 0; i < message.field.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                            if (error)
                                return "field." + error;
                        }
                    }
                    if (message.extension != null && message.hasOwnProperty("extension")) {
                        if (!Array.isArray(message.extension))
                            return "extension: array expected";
                        for (var i = 0; i < message.extension.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                            if (error)
                                return "extension." + error;
                        }
                    }
                    if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                        if (!Array.isArray(message.nestedType))
                            return "nestedType: array expected";
                        for (var i = 0; i < message.nestedType.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                            if (error)
                                return "nestedType." + error;
                        }
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (!Array.isArray(message.enumType))
                            return "enumType: array expected";
                        for (var i = 0; i < message.enumType.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                            if (error)
                                return "enumType." + error;
                        }
                    }
                    if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                        if (!Array.isArray(message.extensionRange))
                            return "extensionRange: array expected";
                        for (var i = 0; i < message.extensionRange.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                            if (error)
                                return "extensionRange." + error;
                        }
                    }
                    if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                        if (!Array.isArray(message.oneofDecl))
                            return "oneofDecl: array expected";
                        for (var i = 0; i < message.oneofDecl.length; ++i) {
                            var error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                            if (error)
                                return "oneofDecl." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.MessageOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                        if (!Array.isArray(message.reservedRange))
                            return "reservedRange: array expected";
                        for (var i = 0; i < message.reservedRange.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                            if (error)
                                return "reservedRange." + error;
                        }
                    }
                    if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                        if (!Array.isArray(message.reservedName))
                            return "reservedName: array expected";
                        for (var i = 0; i < message.reservedName.length; ++i)
                            if (!$util.isString(message.reservedName[i]))
                                return "reservedName: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 */
                DescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.field) {
                        if (!Array.isArray(object.field))
                            throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                        message.field = [];
                        for (var i = 0; i < object.field.length; ++i) {
                            if (typeof object.field[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                            message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                        }
                    }
                    if (object.extension) {
                        if (!Array.isArray(object.extension))
                            throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                        message.extension = [];
                        for (var i = 0; i < object.extension.length; ++i) {
                            if (typeof object.extension[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                        }
                    }
                    if (object.nestedType) {
                        if (!Array.isArray(object.nestedType))
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                        message.nestedType = [];
                        for (var i = 0; i < object.nestedType.length; ++i) {
                            if (typeof object.nestedType[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                            message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                        }
                    }
                    if (object.enumType) {
                        if (!Array.isArray(object.enumType))
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                        message.enumType = [];
                        for (var i = 0; i < object.enumType.length; ++i) {
                            if (typeof object.enumType[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                        }
                    }
                    if (object.extensionRange) {
                        if (!Array.isArray(object.extensionRange))
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                        message.extensionRange = [];
                        for (var i = 0; i < object.extensionRange.length; ++i) {
                            if (typeof object.extensionRange[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                            message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                        }
                    }
                    if (object.oneofDecl) {
                        if (!Array.isArray(object.oneofDecl))
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                        message.oneofDecl = [];
                        for (var i = 0; i < object.oneofDecl.length; ++i) {
                            if (typeof object.oneofDecl[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                            message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                    }
                    if (object.reservedRange) {
                        if (!Array.isArray(object.reservedRange))
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                        message.reservedRange = [];
                        for (var i = 0; i < object.reservedRange.length; ++i) {
                            if (typeof object.reservedRange[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                            message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                        }
                    }
                    if (object.reservedName) {
                        if (!Array.isArray(object.reservedName))
                            throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                        message.reservedName = [];
                        for (var i = 0; i < object.reservedName.length; ++i)
                            message.reservedName[i] = String(object.reservedName[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.DescriptorProto} message DescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.field = [];
                        object.nestedType = [];
                        object.enumType = [];
                        object.extensionRange = [];
                        object.extension = [];
                        object.oneofDecl = [];
                        object.reservedRange = [];
                        object.reservedName = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.field && message.field.length) {
                        object.field = [];
                        for (var j = 0; j < message.field.length; ++j)
                            object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                    }
                    if (message.nestedType && message.nestedType.length) {
                        object.nestedType = [];
                        for (var j = 0; j < message.nestedType.length; ++j)
                            object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                    }
                    if (message.enumType && message.enumType.length) {
                        object.enumType = [];
                        for (var j = 0; j < message.enumType.length; ++j)
                            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                    }
                    if (message.extensionRange && message.extensionRange.length) {
                        object.extensionRange = [];
                        for (var j = 0; j < message.extensionRange.length; ++j)
                            object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                    }
                    if (message.extension && message.extension.length) {
                        object.extension = [];
                        for (var j = 0; j < message.extension.length; ++j)
                            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                    if (message.oneofDecl && message.oneofDecl.length) {
                        object.oneofDecl = [];
                        for (var j = 0; j < message.oneofDecl.length; ++j)
                            object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                    }
                    if (message.reservedRange && message.reservedRange.length) {
                        object.reservedRange = [];
                        for (var j = 0; j < message.reservedRange.length; ++j)
                            object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                    }
                    if (message.reservedName && message.reservedName.length) {
                        object.reservedName = [];
                        for (var j = 0; j < message.reservedName.length; ++j)
                            object.reservedName[j] = message.reservedName[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this DescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto";
                };
    
                DescriptorProto.ExtensionRange = (function() {
    
                    /**
                     * Properties of an ExtensionRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @interface IExtensionRange
                     * @property {number|null} [start] ExtensionRange start
                     * @property {number|null} [end] ExtensionRange end
                     */
    
                    /**
                     * Constructs a new ExtensionRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @classdesc Represents an ExtensionRange.
                     * @implements IExtensionRange
                     * @constructor
                     * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                     */
                    function ExtensionRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExtensionRange start.
                     * @member {number} start
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.start = 0;
    
                    /**
                     * ExtensionRange end.
                     * @member {number} end
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.end = 0;
    
                    /**
                     * Creates a new ExtensionRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                     */
                    ExtensionRange.create = function create(properties) {
                        return new ExtensionRange(properties);
                    };
    
                    /**
                     * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtensionRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExtensionRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtensionRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.start = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.end = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExtensionRange message.
                     * @function verify
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExtensionRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     */
                    ExtensionRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                            return object;
                        var message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExtensionRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this ExtensionRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExtensionRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for ExtensionRange
                     * @function getTypeUrl
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ExtensionRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.DescriptorProto.ExtensionRange";
                    };
    
                    return ExtensionRange;
                })();
    
                DescriptorProto.ReservedRange = (function() {
    
                    /**
                     * Properties of a ReservedRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @interface IReservedRange
                     * @property {number|null} [start] ReservedRange start
                     * @property {number|null} [end] ReservedRange end
                     */
    
                    /**
                     * Constructs a new ReservedRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @classdesc Represents a ReservedRange.
                     * @implements IReservedRange
                     * @constructor
                     * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                     */
                    function ReservedRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReservedRange start.
                     * @member {number} start
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     */
                    ReservedRange.prototype.start = 0;
    
                    /**
                     * ReservedRange end.
                     * @member {number} end
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     */
                    ReservedRange.prototype.end = 0;
    
                    /**
                     * Creates a new ReservedRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                     */
                    ReservedRange.create = function create(properties) {
                        return new ReservedRange(properties);
                    };
    
                    /**
                     * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReservedRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReservedRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReservedRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.start = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.end = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReservedRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReservedRange message.
                     * @function verify
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReservedRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     */
                    ReservedRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                            return object;
                        var message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReservedRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this ReservedRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReservedRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for ReservedRange
                     * @function getTypeUrl
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ReservedRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.DescriptorProto.ReservedRange";
                    };
    
                    return ReservedRange;
                })();
    
                return DescriptorProto;
            })();
    
            protobuf.FieldDescriptorProto = (function() {
    
                /**
                 * Properties of a FieldDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IFieldDescriptorProto
                 * @property {string|null} [name] FieldDescriptorProto name
                 * @property {number|null} [number] FieldDescriptorProto number
                 * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
                 * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
                 * @property {string|null} [typeName] FieldDescriptorProto typeName
                 * @property {string|null} [extendee] FieldDescriptorProto extendee
                 * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
                 * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
                 * @property {string|null} [jsonName] FieldDescriptorProto jsonName
                 * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
                 */
    
                /**
                 * Constructs a new FieldDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldDescriptorProto.
                 * @implements IFieldDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
                 */
                function FieldDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.name = "";
    
                /**
                 * FieldDescriptorProto number.
                 * @member {number} number
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.number = 0;
    
                /**
                 * FieldDescriptorProto label.
                 * @member {google.protobuf.FieldDescriptorProto.Label} label
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.label = 1;
    
                /**
                 * FieldDescriptorProto type.
                 * @member {google.protobuf.FieldDescriptorProto.Type} type
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.type = 1;
    
                /**
                 * FieldDescriptorProto typeName.
                 * @member {string} typeName
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.typeName = "";
    
                /**
                 * FieldDescriptorProto extendee.
                 * @member {string} extendee
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.extendee = "";
    
                /**
                 * FieldDescriptorProto defaultValue.
                 * @member {string} defaultValue
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.defaultValue = "";
    
                /**
                 * FieldDescriptorProto oneofIndex.
                 * @member {number} oneofIndex
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.oneofIndex = 0;
    
                /**
                 * FieldDescriptorProto jsonName.
                 * @member {string} jsonName
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.jsonName = "";
    
                /**
                 * FieldDescriptorProto options.
                 * @member {google.protobuf.IFieldOptions|null|undefined} options
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new FieldDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
                 */
                FieldDescriptorProto.create = function create(properties) {
                    return new FieldDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                    if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                    if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                    if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.number = reader.int32();
                                break;
                            }
                        case 4: {
                                message.label = reader.int32();
                                break;
                            }
                        case 5: {
                                message.type = reader.int32();
                                break;
                            }
                        case 6: {
                                message.typeName = reader.string();
                                break;
                            }
                        case 2: {
                                message.extendee = reader.string();
                                break;
                            }
                        case 7: {
                                message.defaultValue = reader.string();
                                break;
                            }
                        case 9: {
                                message.oneofIndex = reader.int32();
                                break;
                            }
                        case 10: {
                                message.jsonName = reader.string();
                                break;
                            }
                        case 8: {
                                message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.label != null && message.hasOwnProperty("label"))
                        switch (message.label) {
                        default:
                            return "label: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.extendee != null && message.hasOwnProperty("extendee"))
                        if (!$util.isString(message.extendee))
                            return "extendee: string expected";
                    if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                        if (!$util.isString(message.defaultValue))
                            return "defaultValue: string expected";
                    if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                        if (!$util.isInteger(message.oneofIndex))
                            return "oneofIndex: integer expected";
                    if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                        if (!$util.isString(message.jsonName))
                            return "jsonName: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.FieldOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 */
                FieldDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.FieldDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.number != null)
                        message.number = object.number | 0;
                    switch (object.label) {
                    default:
                        if (typeof object.label === "number") {
                            message.label = object.label;
                            break;
                        }
                        break;
                    case "LABEL_OPTIONAL":
                    case 1:
                        message.label = 1;
                        break;
                    case "LABEL_REQUIRED":
                    case 2:
                        message.label = 2;
                        break;
                    case "LABEL_REPEATED":
                    case 3:
                        message.label = 3;
                        break;
                    }
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "TYPE_DOUBLE":
                    case 1:
                        message.type = 1;
                        break;
                    case "TYPE_FLOAT":
                    case 2:
                        message.type = 2;
                        break;
                    case "TYPE_INT64":
                    case 3:
                        message.type = 3;
                        break;
                    case "TYPE_UINT64":
                    case 4:
                        message.type = 4;
                        break;
                    case "TYPE_INT32":
                    case 5:
                        message.type = 5;
                        break;
                    case "TYPE_FIXED64":
                    case 6:
                        message.type = 6;
                        break;
                    case "TYPE_FIXED32":
                    case 7:
                        message.type = 7;
                        break;
                    case "TYPE_BOOL":
                    case 8:
                        message.type = 8;
                        break;
                    case "TYPE_STRING":
                    case 9:
                        message.type = 9;
                        break;
                    case "TYPE_GROUP":
                    case 10:
                        message.type = 10;
                        break;
                    case "TYPE_MESSAGE":
                    case 11:
                        message.type = 11;
                        break;
                    case "TYPE_BYTES":
                    case 12:
                        message.type = 12;
                        break;
                    case "TYPE_UINT32":
                    case 13:
                        message.type = 13;
                        break;
                    case "TYPE_ENUM":
                    case 14:
                        message.type = 14;
                        break;
                    case "TYPE_SFIXED32":
                    case 15:
                        message.type = 15;
                        break;
                    case "TYPE_SFIXED64":
                    case 16:
                        message.type = 16;
                        break;
                    case "TYPE_SINT32":
                    case 17:
                        message.type = 17;
                        break;
                    case "TYPE_SINT64":
                    case 18:
                        message.type = 18;
                        break;
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.extendee != null)
                        message.extendee = String(object.extendee);
                    if (object.defaultValue != null)
                        message.defaultValue = String(object.defaultValue);
                    if (object.oneofIndex != null)
                        message.oneofIndex = object.oneofIndex | 0;
                    if (object.jsonName != null)
                        message.jsonName = String(object.jsonName);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.extendee = "";
                        object.number = 0;
                        object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                        object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                        object.typeName = "";
                        object.defaultValue = "";
                        object.options = null;
                        object.oneofIndex = 0;
                        object.jsonName = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.extendee != null && message.hasOwnProperty("extendee"))
                        object.extendee = message.extendee;
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] === undefined ? message.label : $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] === undefined ? message.type : $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                        object.defaultValue = message.defaultValue;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                    if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                        object.oneofIndex = message.oneofIndex;
                    if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                        object.jsonName = message.jsonName;
                    return object;
                };
    
                /**
                 * Converts this FieldDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FieldDescriptorProto";
                };
    
                /**
                 * Type enum.
                 * @name google.protobuf.FieldDescriptorProto.Type
                 * @enum {number}
                 * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
                 * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
                 * @property {number} TYPE_INT64=3 TYPE_INT64 value
                 * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
                 * @property {number} TYPE_INT32=5 TYPE_INT32 value
                 * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
                 * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
                 * @property {number} TYPE_BOOL=8 TYPE_BOOL value
                 * @property {number} TYPE_STRING=9 TYPE_STRING value
                 * @property {number} TYPE_GROUP=10 TYPE_GROUP value
                 * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
                 * @property {number} TYPE_BYTES=12 TYPE_BYTES value
                 * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
                 * @property {number} TYPE_ENUM=14 TYPE_ENUM value
                 * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
                 * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
                 * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
                 * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
                 */
                FieldDescriptorProto.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                    values[valuesById[2] = "TYPE_FLOAT"] = 2;
                    values[valuesById[3] = "TYPE_INT64"] = 3;
                    values[valuesById[4] = "TYPE_UINT64"] = 4;
                    values[valuesById[5] = "TYPE_INT32"] = 5;
                    values[valuesById[6] = "TYPE_FIXED64"] = 6;
                    values[valuesById[7] = "TYPE_FIXED32"] = 7;
                    values[valuesById[8] = "TYPE_BOOL"] = 8;
                    values[valuesById[9] = "TYPE_STRING"] = 9;
                    values[valuesById[10] = "TYPE_GROUP"] = 10;
                    values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                    values[valuesById[12] = "TYPE_BYTES"] = 12;
                    values[valuesById[13] = "TYPE_UINT32"] = 13;
                    values[valuesById[14] = "TYPE_ENUM"] = 14;
                    values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                    values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                    values[valuesById[17] = "TYPE_SINT32"] = 17;
                    values[valuesById[18] = "TYPE_SINT64"] = 18;
                    return values;
                })();
    
                /**
                 * Label enum.
                 * @name google.protobuf.FieldDescriptorProto.Label
                 * @enum {number}
                 * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
                 * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
                 * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
                 */
                FieldDescriptorProto.Label = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                    values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                    values[valuesById[3] = "LABEL_REPEATED"] = 3;
                    return values;
                })();
    
                return FieldDescriptorProto;
            })();
    
            protobuf.OneofDescriptorProto = (function() {
    
                /**
                 * Properties of an OneofDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IOneofDescriptorProto
                 * @property {string|null} [name] OneofDescriptorProto name
                 * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
                 */
    
                /**
                 * Constructs a new OneofDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an OneofDescriptorProto.
                 * @implements IOneofDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
                 */
                function OneofDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OneofDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 */
                OneofDescriptorProto.prototype.name = "";
    
                /**
                 * OneofDescriptorProto options.
                 * @member {google.protobuf.IOneofOptions|null|undefined} options
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 */
                OneofDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new OneofDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
                 */
                OneofDescriptorProto.create = function create(properties) {
                    return new OneofDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OneofDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OneofDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OneofDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.OneofOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 */
                OneofDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.OneofDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OneofDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this OneofDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OneofDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OneofDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OneofDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.OneofDescriptorProto";
                };
    
                return OneofDescriptorProto;
            })();
    
            protobuf.EnumDescriptorProto = (function() {
    
                /**
                 * Properties of an EnumDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IEnumDescriptorProto
                 * @property {string|null} [name] EnumDescriptorProto name
                 * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
                 * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
                 */
    
                /**
                 * Constructs a new EnumDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumDescriptorProto.
                 * @implements IEnumDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
                 */
                function EnumDescriptorProto(properties) {
                    this.value = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.name = "";
    
                /**
                 * EnumDescriptorProto value.
                 * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.value = $util.emptyArray;
    
                /**
                 * EnumDescriptorProto options.
                 * @member {google.protobuf.IEnumOptions|null|undefined} options
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new EnumDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
                 */
                EnumDescriptorProto.create = function create(properties) {
                    return new EnumDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.value != null && message.value.length)
                        for (var i = 0; i < message.value.length; ++i)
                            $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.value && message.value.length))
                                    message.value = [];
                                message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.value != null && message.hasOwnProperty("value")) {
                        if (!Array.isArray(message.value))
                            return "value: array expected";
                        for (var i = 0; i < message.value.length; ++i) {
                            var error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                            if (error)
                                return "value." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.EnumOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 */
                EnumDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.EnumDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.value) {
                        if (!Array.isArray(object.value))
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                        message.value = [];
                        for (var i = 0; i < object.value.length; ++i) {
                            if (typeof object.value[i] !== "object")
                                throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                            message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.value = [];
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.value && message.value.length) {
                        object.value = [];
                        for (var j = 0; j < message.value.length; ++j)
                            object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this EnumDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnumDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumDescriptorProto";
                };
    
                return EnumDescriptorProto;
            })();
    
            protobuf.EnumValueDescriptorProto = (function() {
    
                /**
                 * Properties of an EnumValueDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IEnumValueDescriptorProto
                 * @property {string|null} [name] EnumValueDescriptorProto name
                 * @property {number|null} [number] EnumValueDescriptorProto number
                 * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
                 */
    
                /**
                 * Constructs a new EnumValueDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumValueDescriptorProto.
                 * @implements IEnumValueDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
                 */
                function EnumValueDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumValueDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.name = "";
    
                /**
                 * EnumValueDescriptorProto number.
                 * @member {number} number
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.number = 0;
    
                /**
                 * EnumValueDescriptorProto options.
                 * @member {google.protobuf.IEnumValueOptions|null|undefined} options
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new EnumValueDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
                 */
                EnumValueDescriptorProto.create = function create(properties) {
                    return new EnumValueDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.number = reader.int32();
                                break;
                            }
                        case 3: {
                                message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumValueDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumValueDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 */
                EnumValueDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.EnumValueDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.number != null)
                        message.number = object.number | 0;
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumValueDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.number = 0;
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this EnumValueDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnumValueDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumValueDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumValueDescriptorProto";
                };
    
                return EnumValueDescriptorProto;
            })();
    
            protobuf.ServiceDescriptorProto = (function() {
    
                /**
                 * Properties of a ServiceDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IServiceDescriptorProto
                 * @property {string|null} [name] ServiceDescriptorProto name
                 * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
                 * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
                 */
    
                /**
                 * Constructs a new ServiceDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a ServiceDescriptorProto.
                 * @implements IServiceDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
                 */
                function ServiceDescriptorProto(properties) {
                    this.method = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ServiceDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.name = "";
    
                /**
                 * ServiceDescriptorProto method.
                 * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.method = $util.emptyArray;
    
                /**
                 * ServiceDescriptorProto options.
                 * @member {google.protobuf.IServiceOptions|null|undefined} options
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new ServiceDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
                 */
                ServiceDescriptorProto.create = function create(properties) {
                    return new ServiceDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.method != null && message.method.length)
                        for (var i = 0; i < message.method.length; ++i)
                            $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.method && message.method.length))
                                    message.method = [];
                                message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ServiceDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.method != null && message.hasOwnProperty("method")) {
                        if (!Array.isArray(message.method))
                            return "method: array expected";
                        for (var i = 0; i < message.method.length; ++i) {
                            var error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                            if (error)
                                return "method." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.ServiceOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 */
                ServiceDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.ServiceDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.method) {
                        if (!Array.isArray(object.method))
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                        message.method = [];
                        for (var i = 0; i < object.method.length; ++i) {
                            if (typeof object.method[i] !== "object")
                                throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                            message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.method = [];
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.method && message.method.length) {
                        object.method = [];
                        for (var j = 0; j < message.method.length; ++j)
                            object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this ServiceDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ServiceDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.ServiceDescriptorProto";
                };
    
                return ServiceDescriptorProto;
            })();
    
            protobuf.MethodDescriptorProto = (function() {
    
                /**
                 * Properties of a MethodDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IMethodDescriptorProto
                 * @property {string|null} [name] MethodDescriptorProto name
                 * @property {string|null} [inputType] MethodDescriptorProto inputType
                 * @property {string|null} [outputType] MethodDescriptorProto outputType
                 * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
                 * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
                 * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
                 */
    
                /**
                 * Constructs a new MethodDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a MethodDescriptorProto.
                 * @implements IMethodDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
                 */
                function MethodDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MethodDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.name = "";
    
                /**
                 * MethodDescriptorProto inputType.
                 * @member {string} inputType
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.inputType = "";
    
                /**
                 * MethodDescriptorProto outputType.
                 * @member {string} outputType
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.outputType = "";
    
                /**
                 * MethodDescriptorProto options.
                 * @member {google.protobuf.IMethodOptions|null|undefined} options
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.options = null;
    
                /**
                 * MethodDescriptorProto clientStreaming.
                 * @member {boolean} clientStreaming
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.clientStreaming = false;
    
                /**
                 * MethodDescriptorProto serverStreaming.
                 * @member {boolean} serverStreaming
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.serverStreaming = false;
    
                /**
                 * Creates a new MethodDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
                 */
                MethodDescriptorProto.create = function create(properties) {
                    return new MethodDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                    if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                    if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                    return writer;
                };
    
                /**
                 * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MethodDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.inputType = reader.string();
                                break;
                            }
                        case 3: {
                                message.outputType = reader.string();
                                break;
                            }
                        case 4: {
                                message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.clientStreaming = reader.bool();
                                break;
                            }
                        case 6: {
                                message.serverStreaming = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MethodDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MethodDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        if (!$util.isString(message.inputType))
                            return "inputType: string expected";
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        if (!$util.isString(message.outputType))
                            return "outputType: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.MethodOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                        if (typeof message.clientStreaming !== "boolean")
                            return "clientStreaming: boolean expected";
                    if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                        if (typeof message.serverStreaming !== "boolean")
                            return "serverStreaming: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 */
                MethodDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.MethodDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.inputType != null)
                        message.inputType = String(object.inputType);
                    if (object.outputType != null)
                        message.outputType = String(object.outputType);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                    }
                    if (object.clientStreaming != null)
                        message.clientStreaming = Boolean(object.clientStreaming);
                    if (object.serverStreaming != null)
                        message.serverStreaming = Boolean(object.serverStreaming);
                    return message;
                };
    
                /**
                 * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MethodDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.inputType = "";
                        object.outputType = "";
                        object.options = null;
                        object.clientStreaming = false;
                        object.serverStreaming = false;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        object.inputType = message.inputType;
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        object.outputType = message.outputType;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                    if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                        object.clientStreaming = message.clientStreaming;
                    if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                        object.serverStreaming = message.serverStreaming;
                    return object;
                };
    
                /**
                 * Converts this MethodDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MethodDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MethodDescriptorProto
                 * @function getTypeUrl
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MethodDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.MethodDescriptorProto";
                };
    
                return MethodDescriptorProto;
            })();
    
            protobuf.FileOptions = (function() {
    
                /**
                 * Properties of a FileOptions.
                 * @memberof google.protobuf
                 * @interface IFileOptions
                 * @property {string|null} [javaPackage] FileOptions javaPackage
                 * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
                 * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
                 * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
                 * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
                 * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
                 * @property {string|null} [goPackage] FileOptions goPackage
                 * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
                 * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
                 * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
                 * @property {boolean|null} [deprecated] FileOptions deprecated
                 * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
                 * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
                 * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new FileOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileOptions.
                 * @implements IFileOptions
                 * @constructor
                 * @param {google.protobuf.IFileOptions=} [properties] Properties to set
                 */
                function FileOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileOptions javaPackage.
                 * @member {string} javaPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaPackage = "";
    
                /**
                 * FileOptions javaOuterClassname.
                 * @member {string} javaOuterClassname
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaOuterClassname = "";
    
                /**
                 * FileOptions javaMultipleFiles.
                 * @member {boolean} javaMultipleFiles
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaMultipleFiles = false;
    
                /**
                 * FileOptions javaGenerateEqualsAndHash.
                 * @member {boolean} javaGenerateEqualsAndHash
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaGenerateEqualsAndHash = false;
    
                /**
                 * FileOptions javaStringCheckUtf8.
                 * @member {boolean} javaStringCheckUtf8
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaStringCheckUtf8 = false;
    
                /**
                 * FileOptions optimizeFor.
                 * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.optimizeFor = 1;
    
                /**
                 * FileOptions goPackage.
                 * @member {string} goPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.goPackage = "";
    
                /**
                 * FileOptions ccGenericServices.
                 * @member {boolean} ccGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.ccGenericServices = false;
    
                /**
                 * FileOptions javaGenericServices.
                 * @member {boolean} javaGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaGenericServices = false;
    
                /**
                 * FileOptions pyGenericServices.
                 * @member {boolean} pyGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.pyGenericServices = false;
    
                /**
                 * FileOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.deprecated = false;
    
                /**
                 * FileOptions ccEnableArenas.
                 * @member {boolean} ccEnableArenas
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.ccEnableArenas = false;
    
                /**
                 * FileOptions objcClassPrefix.
                 * @member {string} objcClassPrefix
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.objcClassPrefix = "";
    
                /**
                 * FileOptions csharpNamespace.
                 * @member {string} csharpNamespace
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.csharpNamespace = "";
    
                /**
                 * FileOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new FileOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions=} [properties] Properties to set
                 * @returns {google.protobuf.FileOptions} FileOptions instance
                 */
                FileOptions.create = function create(properties) {
                    return new FileOptions(properties);
                };
    
                /**
                 * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                    if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                    if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                    if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                    if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                    if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                        writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                    if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                        writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                    if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                        writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                    if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                        writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                    if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                        writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                    if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                        writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                    if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                        writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                    if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                        writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileOptions} FileOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.javaPackage = reader.string();
                                break;
                            }
                        case 8: {
                                message.javaOuterClassname = reader.string();
                                break;
                            }
                        case 10: {
                                message.javaMultipleFiles = reader.bool();
                                break;
                            }
                        case 20: {
                                message.javaGenerateEqualsAndHash = reader.bool();
                                break;
                            }
                        case 27: {
                                message.javaStringCheckUtf8 = reader.bool();
                                break;
                            }
                        case 9: {
                                message.optimizeFor = reader.int32();
                                break;
                            }
                        case 11: {
                                message.goPackage = reader.string();
                                break;
                            }
                        case 16: {
                                message.ccGenericServices = reader.bool();
                                break;
                            }
                        case 17: {
                                message.javaGenericServices = reader.bool();
                                break;
                            }
                        case 18: {
                                message.pyGenericServices = reader.bool();
                                break;
                            }
                        case 23: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 31: {
                                message.ccEnableArenas = reader.bool();
                                break;
                            }
                        case 36: {
                                message.objcClassPrefix = reader.string();
                                break;
                            }
                        case 37: {
                                message.csharpNamespace = reader.string();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileOptions} FileOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileOptions message.
                 * @function verify
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                        if (!$util.isString(message.javaPackage))
                            return "javaPackage: string expected";
                    if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                        if (!$util.isString(message.javaOuterClassname))
                            return "javaOuterClassname: string expected";
                    if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                        if (typeof message.javaMultipleFiles !== "boolean")
                            return "javaMultipleFiles: boolean expected";
                    if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                        if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                            return "javaGenerateEqualsAndHash: boolean expected";
                    if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                        if (typeof message.javaStringCheckUtf8 !== "boolean")
                            return "javaStringCheckUtf8: boolean expected";
                    if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                        switch (message.optimizeFor) {
                        default:
                            return "optimizeFor: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                        if (!$util.isString(message.goPackage))
                            return "goPackage: string expected";
                    if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                        if (typeof message.ccGenericServices !== "boolean")
                            return "ccGenericServices: boolean expected";
                    if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                        if (typeof message.javaGenericServices !== "boolean")
                            return "javaGenericServices: boolean expected";
                    if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                        if (typeof message.pyGenericServices !== "boolean")
                            return "pyGenericServices: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                        if (typeof message.ccEnableArenas !== "boolean")
                            return "ccEnableArenas: boolean expected";
                    if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                        if (!$util.isString(message.objcClassPrefix))
                            return "objcClassPrefix: string expected";
                    if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                        if (!$util.isString(message.csharpNamespace))
                            return "csharpNamespace: string expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileOptions} FileOptions
                 */
                FileOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileOptions)
                        return object;
                    var message = new $root.google.protobuf.FileOptions();
                    if (object.javaPackage != null)
                        message.javaPackage = String(object.javaPackage);
                    if (object.javaOuterClassname != null)
                        message.javaOuterClassname = String(object.javaOuterClassname);
                    if (object.javaMultipleFiles != null)
                        message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                    if (object.javaGenerateEqualsAndHash != null)
                        message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                    if (object.javaStringCheckUtf8 != null)
                        message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                    switch (object.optimizeFor) {
                    default:
                        if (typeof object.optimizeFor === "number") {
                            message.optimizeFor = object.optimizeFor;
                            break;
                        }
                        break;
                    case "SPEED":
                    case 1:
                        message.optimizeFor = 1;
                        break;
                    case "CODE_SIZE":
                    case 2:
                        message.optimizeFor = 2;
                        break;
                    case "LITE_RUNTIME":
                    case 3:
                        message.optimizeFor = 3;
                        break;
                    }
                    if (object.goPackage != null)
                        message.goPackage = String(object.goPackage);
                    if (object.ccGenericServices != null)
                        message.ccGenericServices = Boolean(object.ccGenericServices);
                    if (object.javaGenericServices != null)
                        message.javaGenericServices = Boolean(object.javaGenericServices);
                    if (object.pyGenericServices != null)
                        message.pyGenericServices = Boolean(object.pyGenericServices);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.ccEnableArenas != null)
                        message.ccEnableArenas = Boolean(object.ccEnableArenas);
                    if (object.objcClassPrefix != null)
                        message.objcClassPrefix = String(object.objcClassPrefix);
                    if (object.csharpNamespace != null)
                        message.csharpNamespace = String(object.csharpNamespace);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.FileOptions} message FileOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.javaPackage = "";
                        object.javaOuterClassname = "";
                        object.optimizeFor = options.enums === String ? "SPEED" : 1;
                        object.javaMultipleFiles = false;
                        object.goPackage = "";
                        object.ccGenericServices = false;
                        object.javaGenericServices = false;
                        object.pyGenericServices = false;
                        object.javaGenerateEqualsAndHash = false;
                        object.deprecated = false;
                        object.javaStringCheckUtf8 = false;
                        object.ccEnableArenas = false;
                        object.objcClassPrefix = "";
                        object.csharpNamespace = "";
                    }
                    if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                        object.javaPackage = message.javaPackage;
                    if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                        object.javaOuterClassname = message.javaOuterClassname;
                    if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                        object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] === undefined ? message.optimizeFor : $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                    if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                        object.javaMultipleFiles = message.javaMultipleFiles;
                    if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                        object.goPackage = message.goPackage;
                    if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                        object.ccGenericServices = message.ccGenericServices;
                    if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                        object.javaGenericServices = message.javaGenericServices;
                    if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                        object.pyGenericServices = message.pyGenericServices;
                    if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                        object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                        object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                    if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                        object.ccEnableArenas = message.ccEnableArenas;
                    if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                        object.objcClassPrefix = message.objcClassPrefix;
                    if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                        object.csharpNamespace = message.csharpNamespace;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FileOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FileOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FileOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FileOptions";
                };
    
                /**
                 * OptimizeMode enum.
                 * @name google.protobuf.FileOptions.OptimizeMode
                 * @enum {number}
                 * @property {number} SPEED=1 SPEED value
                 * @property {number} CODE_SIZE=2 CODE_SIZE value
                 * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
                 */
                FileOptions.OptimizeMode = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "SPEED"] = 1;
                    values[valuesById[2] = "CODE_SIZE"] = 2;
                    values[valuesById[3] = "LITE_RUNTIME"] = 3;
                    return values;
                })();
    
                return FileOptions;
            })();
    
            protobuf.MessageOptions = (function() {
    
                /**
                 * Properties of a MessageOptions.
                 * @memberof google.protobuf
                 * @interface IMessageOptions
                 * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
                 * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
                 * @property {boolean|null} [deprecated] MessageOptions deprecated
                 * @property {boolean|null} [mapEntry] MessageOptions mapEntry
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new MessageOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a MessageOptions.
                 * @implements IMessageOptions
                 * @constructor
                 * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
                 */
                function MessageOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MessageOptions messageSetWireFormat.
                 * @member {boolean} messageSetWireFormat
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.messageSetWireFormat = false;
    
                /**
                 * MessageOptions noStandardDescriptorAccessor.
                 * @member {boolean} noStandardDescriptorAccessor
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.noStandardDescriptorAccessor = false;
    
                /**
                 * MessageOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.deprecated = false;
    
                /**
                 * MessageOptions mapEntry.
                 * @member {boolean} mapEntry
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.mapEntry = false;
    
                /**
                 * MessageOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new MessageOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
                 * @returns {google.protobuf.MessageOptions} MessageOptions instance
                 */
                MessageOptions.create = function create(properties) {
                    return new MessageOptions(properties);
                };
    
                /**
                 * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                    if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MessageOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.messageSetWireFormat = reader.bool();
                                break;
                            }
                        case 2: {
                                message.noStandardDescriptorAccessor = reader.bool();
                                break;
                            }
                        case 3: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 7: {
                                message.mapEntry = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MessageOptions message.
                 * @function verify
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MessageOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                        if (typeof message.messageSetWireFormat !== "boolean")
                            return "messageSetWireFormat: boolean expected";
                    if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                        if (typeof message.noStandardDescriptorAccessor !== "boolean")
                            return "noStandardDescriptorAccessor: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                        if (typeof message.mapEntry !== "boolean")
                            return "mapEntry: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 */
                MessageOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MessageOptions)
                        return object;
                    var message = new $root.google.protobuf.MessageOptions();
                    if (object.messageSetWireFormat != null)
                        message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                    if (object.noStandardDescriptorAccessor != null)
                        message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.mapEntry != null)
                        message.mapEntry = Boolean(object.mapEntry);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.MessageOptions} message MessageOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MessageOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.messageSetWireFormat = false;
                        object.noStandardDescriptorAccessor = false;
                        object.deprecated = false;
                        object.mapEntry = false;
                    }
                    if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                        object.messageSetWireFormat = message.messageSetWireFormat;
                    if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                        object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                        object.mapEntry = message.mapEntry;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MessageOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MessageOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MessageOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MessageOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.MessageOptions";
                };
    
                return MessageOptions;
            })();
    
            protobuf.FieldOptions = (function() {
    
                /**
                 * Properties of a FieldOptions.
                 * @memberof google.protobuf
                 * @interface IFieldOptions
                 * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
                 * @property {boolean|null} [packed] FieldOptions packed
                 * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
                 * @property {boolean|null} [lazy] FieldOptions lazy
                 * @property {boolean|null} [deprecated] FieldOptions deprecated
                 * @property {boolean|null} [weak] FieldOptions weak
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
                 * @property {boolean|null} [".infinitusai.be.containsPhi"] FieldOptions .infinitusai.be.containsPhi
                 * @property {boolean|null} [".infinitusai.be.collectSeparateOonValue"] FieldOptions .infinitusai.be.collectSeparateOonValue
                 */
    
                /**
                 * Constructs a new FieldOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldOptions.
                 * @implements IFieldOptions
                 * @constructor
                 * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
                 */
                function FieldOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldOptions ctype.
                 * @member {google.protobuf.FieldOptions.CType} ctype
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.ctype = 0;
    
                /**
                 * FieldOptions packed.
                 * @member {boolean} packed
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.packed = false;
    
                /**
                 * FieldOptions jstype.
                 * @member {google.protobuf.FieldOptions.JSType} jstype
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.jstype = 0;
    
                /**
                 * FieldOptions lazy.
                 * @member {boolean} lazy
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.lazy = false;
    
                /**
                 * FieldOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.deprecated = false;
    
                /**
                 * FieldOptions weak.
                 * @member {boolean} weak
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.weak = false;
    
                /**
                 * FieldOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * FieldOptions .infinitusai.be.containsPhi.
                 * @member {boolean} .infinitusai.be.containsPhi
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype[".infinitusai.be.containsPhi"] = false;
    
                /**
                 * FieldOptions .infinitusai.be.collectSeparateOonValue.
                 * @member {boolean} .infinitusai.be.collectSeparateOonValue
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype[".infinitusai.be.collectSeparateOonValue"] = false;
    
                /**
                 * Creates a new FieldOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
                 * @returns {google.protobuf.FieldOptions} FieldOptions instance
                 */
                FieldOptions.create = function create(properties) {
                    return new FieldOptions(properties);
                };
    
                /**
                 * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                    if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                    if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                    if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    if (message[".infinitusai.be.collectSeparateOonValue"] != null && Object.hasOwnProperty.call(message, ".infinitusai.be.collectSeparateOonValue"))
                        writer.uint32(/* id 50001, wireType 0 =*/400008).bool(message[".infinitusai.be.collectSeparateOonValue"]);
                    if (message[".infinitusai.be.containsPhi"] != null && Object.hasOwnProperty.call(message, ".infinitusai.be.containsPhi"))
                        writer.uint32(/* id 50002, wireType 0 =*/400016).bool(message[".infinitusai.be.containsPhi"]);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ctype = reader.int32();
                                break;
                            }
                        case 2: {
                                message.packed = reader.bool();
                                break;
                            }
                        case 6: {
                                message.jstype = reader.int32();
                                break;
                            }
                        case 5: {
                                message.lazy = reader.bool();
                                break;
                            }
                        case 3: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 10: {
                                message.weak = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        case 50002: {
                                message[".infinitusai.be.containsPhi"] = reader.bool();
                                break;
                            }
                        case 50001: {
                                message[".infinitusai.be.collectSeparateOonValue"] = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldOptions message.
                 * @function verify
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ctype != null && message.hasOwnProperty("ctype"))
                        switch (message.ctype) {
                        default:
                            return "ctype: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.packed != null && message.hasOwnProperty("packed"))
                        if (typeof message.packed !== "boolean")
                            return "packed: boolean expected";
                    if (message.jstype != null && message.hasOwnProperty("jstype"))
                        switch (message.jstype) {
                        default:
                            return "jstype: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.lazy != null && message.hasOwnProperty("lazy"))
                        if (typeof message.lazy !== "boolean")
                            return "lazy: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.weak != null && message.hasOwnProperty("weak"))
                        if (typeof message.weak !== "boolean")
                            return "weak: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    if (message[".infinitusai.be.containsPhi"] != null && message.hasOwnProperty(".infinitusai.be.containsPhi"))
                        if (typeof message[".infinitusai.be.containsPhi"] !== "boolean")
                            return ".infinitusai.be.containsPhi: boolean expected";
                    if (message[".infinitusai.be.collectSeparateOonValue"] != null && message.hasOwnProperty(".infinitusai.be.collectSeparateOonValue"))
                        if (typeof message[".infinitusai.be.collectSeparateOonValue"] !== "boolean")
                            return ".infinitusai.be.collectSeparateOonValue: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 */
                FieldOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldOptions)
                        return object;
                    var message = new $root.google.protobuf.FieldOptions();
                    switch (object.ctype) {
                    default:
                        if (typeof object.ctype === "number") {
                            message.ctype = object.ctype;
                            break;
                        }
                        break;
                    case "STRING":
                    case 0:
                        message.ctype = 0;
                        break;
                    case "CORD":
                    case 1:
                        message.ctype = 1;
                        break;
                    case "STRING_PIECE":
                    case 2:
                        message.ctype = 2;
                        break;
                    }
                    if (object.packed != null)
                        message.packed = Boolean(object.packed);
                    switch (object.jstype) {
                    default:
                        if (typeof object.jstype === "number") {
                            message.jstype = object.jstype;
                            break;
                        }
                        break;
                    case "JS_NORMAL":
                    case 0:
                        message.jstype = 0;
                        break;
                    case "JS_STRING":
                    case 1:
                        message.jstype = 1;
                        break;
                    case "JS_NUMBER":
                    case 2:
                        message.jstype = 2;
                        break;
                    }
                    if (object.lazy != null)
                        message.lazy = Boolean(object.lazy);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.weak != null)
                        message.weak = Boolean(object.weak);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    if (object[".infinitusai.be.containsPhi"] != null)
                        message[".infinitusai.be.containsPhi"] = Boolean(object[".infinitusai.be.containsPhi"]);
                    if (object[".infinitusai.be.collectSeparateOonValue"] != null)
                        message[".infinitusai.be.collectSeparateOonValue"] = Boolean(object[".infinitusai.be.collectSeparateOonValue"]);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.FieldOptions} message FieldOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.ctype = options.enums === String ? "STRING" : 0;
                        object.packed = false;
                        object.deprecated = false;
                        object.lazy = false;
                        object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                        object.weak = false;
                        object[".infinitusai.be.collectSeparateOonValue"] = false;
                        object[".infinitusai.be.containsPhi"] = false;
                    }
                    if (message.ctype != null && message.hasOwnProperty("ctype"))
                        object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] === undefined ? message.ctype : $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                    if (message.packed != null && message.hasOwnProperty("packed"))
                        object.packed = message.packed;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.lazy != null && message.hasOwnProperty("lazy"))
                        object.lazy = message.lazy;
                    if (message.jstype != null && message.hasOwnProperty("jstype"))
                        object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] === undefined ? message.jstype : $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                    if (message.weak != null && message.hasOwnProperty("weak"))
                        object.weak = message.weak;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    if (message[".infinitusai.be.collectSeparateOonValue"] != null && message.hasOwnProperty(".infinitusai.be.collectSeparateOonValue"))
                        object[".infinitusai.be.collectSeparateOonValue"] = message[".infinitusai.be.collectSeparateOonValue"];
                    if (message[".infinitusai.be.containsPhi"] != null && message.hasOwnProperty(".infinitusai.be.containsPhi"))
                        object[".infinitusai.be.containsPhi"] = message[".infinitusai.be.containsPhi"];
                    return object;
                };
    
                /**
                 * Converts this FieldOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FieldOptions";
                };
    
                /**
                 * CType enum.
                 * @name google.protobuf.FieldOptions.CType
                 * @enum {number}
                 * @property {number} STRING=0 STRING value
                 * @property {number} CORD=1 CORD value
                 * @property {number} STRING_PIECE=2 STRING_PIECE value
                 */
                FieldOptions.CType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STRING"] = 0;
                    values[valuesById[1] = "CORD"] = 1;
                    values[valuesById[2] = "STRING_PIECE"] = 2;
                    return values;
                })();
    
                /**
                 * JSType enum.
                 * @name google.protobuf.FieldOptions.JSType
                 * @enum {number}
                 * @property {number} JS_NORMAL=0 JS_NORMAL value
                 * @property {number} JS_STRING=1 JS_STRING value
                 * @property {number} JS_NUMBER=2 JS_NUMBER value
                 */
                FieldOptions.JSType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "JS_NORMAL"] = 0;
                    values[valuesById[1] = "JS_STRING"] = 1;
                    values[valuesById[2] = "JS_NUMBER"] = 2;
                    return values;
                })();
    
                return FieldOptions;
            })();
    
            protobuf.OneofOptions = (function() {
    
                /**
                 * Properties of an OneofOptions.
                 * @memberof google.protobuf
                 * @interface IOneofOptions
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new OneofOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an OneofOptions.
                 * @implements IOneofOptions
                 * @constructor
                 * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
                 */
                function OneofOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OneofOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.OneofOptions
                 * @instance
                 */
                OneofOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new OneofOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
                 * @returns {google.protobuf.OneofOptions} OneofOptions instance
                 */
                OneofOptions.create = function create(properties) {
                    return new OneofOptions(properties);
                };
    
                /**
                 * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OneofOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OneofOptions message.
                 * @function verify
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OneofOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 */
                OneofOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.OneofOptions)
                        return object;
                    var message = new $root.google.protobuf.OneofOptions();
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.OneofOptions} message OneofOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OneofOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this OneofOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.OneofOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OneofOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for OneofOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OneofOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.OneofOptions";
                };
    
                return OneofOptions;
            })();
    
            protobuf.EnumOptions = (function() {
    
                /**
                 * Properties of an EnumOptions.
                 * @memberof google.protobuf
                 * @interface IEnumOptions
                 * @property {boolean|null} [allowAlias] EnumOptions allowAlias
                 * @property {boolean|null} [deprecated] EnumOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new EnumOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumOptions.
                 * @implements IEnumOptions
                 * @constructor
                 * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
                 */
                function EnumOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumOptions allowAlias.
                 * @member {boolean} allowAlias
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.allowAlias = false;
    
                /**
                 * EnumOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.deprecated = false;
    
                /**
                 * EnumOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new EnumOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
                 * @returns {google.protobuf.EnumOptions} EnumOptions instance
                 */
                EnumOptions.create = function create(properties) {
                    return new EnumOptions(properties);
                };
    
                /**
                 * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.allowAlias = reader.bool();
                                break;
                            }
                        case 3: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumOptions message.
                 * @function verify
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                        if (typeof message.allowAlias !== "boolean")
                            return "allowAlias: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 */
                EnumOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumOptions)
                        return object;
                    var message = new $root.google.protobuf.EnumOptions();
                    if (object.allowAlias != null)
                        message.allowAlias = Boolean(object.allowAlias);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.EnumOptions} message EnumOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.allowAlias = false;
                        object.deprecated = false;
                    }
                    if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                        object.allowAlias = message.allowAlias;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this EnumOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnumOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumOptions";
                };
    
                return EnumOptions;
            })();
    
            protobuf.EnumValueOptions = (function() {
    
                /**
                 * Properties of an EnumValueOptions.
                 * @memberof google.protobuf
                 * @interface IEnumValueOptions
                 * @property {boolean|null} [deprecated] EnumValueOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new EnumValueOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumValueOptions.
                 * @implements IEnumValueOptions
                 * @constructor
                 * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
                 */
                function EnumValueOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumValueOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype.deprecated = false;
    
                /**
                 * EnumValueOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new EnumValueOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
                 */
                EnumValueOptions.create = function create(properties) {
                    return new EnumValueOptions(properties);
                };
    
                /**
                 * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumValueOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumValueOptions message.
                 * @function verify
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumValueOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 */
                EnumValueOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumValueOptions)
                        return object;
                    var message = new $root.google.protobuf.EnumValueOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumValueOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults)
                        object.deprecated = false;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this EnumValueOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumValueOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for EnumValueOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumValueOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumValueOptions";
                };
    
                return EnumValueOptions;
            })();
    
            protobuf.ServiceOptions = (function() {
    
                /**
                 * Properties of a ServiceOptions.
                 * @memberof google.protobuf
                 * @interface IServiceOptions
                 * @property {boolean|null} [deprecated] ServiceOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new ServiceOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a ServiceOptions.
                 * @implements IServiceOptions
                 * @constructor
                 * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
                 */
                function ServiceOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ServiceOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 */
                ServiceOptions.prototype.deprecated = false;
    
                /**
                 * ServiceOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 */
                ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new ServiceOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
                 */
                ServiceOptions.create = function create(properties) {
                    return new ServiceOptions(properties);
                };
    
                /**
                 * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ServiceOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 33: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ServiceOptions message.
                 * @function verify
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 */
                ServiceOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ServiceOptions)
                        return object;
                    var message = new $root.google.protobuf.ServiceOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.ServiceOptions} message ServiceOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults)
                        object.deprecated = false;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ServiceOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ServiceOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.ServiceOptions";
                };
    
                return ServiceOptions;
            })();
    
            protobuf.MethodOptions = (function() {
    
                /**
                 * Properties of a MethodOptions.
                 * @memberof google.protobuf
                 * @interface IMethodOptions
                 * @property {boolean|null} [deprecated] MethodOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new MethodOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a MethodOptions.
                 * @implements IMethodOptions
                 * @constructor
                 * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
                 */
                function MethodOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MethodOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.deprecated = false;
    
                /**
                 * MethodOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new MethodOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
                 * @returns {google.protobuf.MethodOptions} MethodOptions instance
                 */
                MethodOptions.create = function create(properties) {
                    return new MethodOptions(properties);
                };
    
                /**
                 * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MethodOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 33: {
                                message.deprecated = reader.bool();
                                break;
                            }
                        case 999: {
                                if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                    message.uninterpretedOption = [];
                                message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MethodOptions message.
                 * @function verify
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MethodOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 */
                MethodOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MethodOptions)
                        return object;
                    var message = new $root.google.protobuf.MethodOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.MethodOptions} message MethodOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MethodOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults)
                        object.deprecated = false;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MethodOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MethodOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MethodOptions
                 * @function getTypeUrl
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MethodOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.MethodOptions";
                };
    
                return MethodOptions;
            })();
    
            protobuf.UninterpretedOption = (function() {
    
                /**
                 * Properties of an UninterpretedOption.
                 * @memberof google.protobuf
                 * @interface IUninterpretedOption
                 * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
                 * @property {string|null} [identifierValue] UninterpretedOption identifierValue
                 * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
                 * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
                 * @property {number|null} [doubleValue] UninterpretedOption doubleValue
                 * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
                 * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
                 */
    
                /**
                 * Constructs a new UninterpretedOption.
                 * @memberof google.protobuf
                 * @classdesc Represents an UninterpretedOption.
                 * @implements IUninterpretedOption
                 * @constructor
                 * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
                 */
                function UninterpretedOption(properties) {
                    this.name = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UninterpretedOption name.
                 * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.name = $util.emptyArray;
    
                /**
                 * UninterpretedOption identifierValue.
                 * @member {string} identifierValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.identifierValue = "";
    
                /**
                 * UninterpretedOption positiveIntValue.
                 * @member {number|Long} positiveIntValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
                /**
                 * UninterpretedOption negativeIntValue.
                 * @member {number|Long} negativeIntValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UninterpretedOption doubleValue.
                 * @member {number} doubleValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.doubleValue = 0;
    
                /**
                 * UninterpretedOption stringValue.
                 * @member {Uint8Array} stringValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.stringValue = $util.newBuffer([]);
    
                /**
                 * UninterpretedOption aggregateValue.
                 * @member {string} aggregateValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.aggregateValue = "";
    
                /**
                 * Creates a new UninterpretedOption instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
                 */
                UninterpretedOption.create = function create(properties) {
                    return new UninterpretedOption(properties);
                };
    
                /**
                 * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UninterpretedOption.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && message.name.length)
                        for (var i = 0; i < message.name.length; ++i)
                            $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                    if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                    if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                    if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                    if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                        writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                    if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                    return writer;
                };
    
                /**
                 * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UninterpretedOption message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UninterpretedOption.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                if (!(message.name && message.name.length))
                                    message.name = [];
                                message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.identifierValue = reader.string();
                                break;
                            }
                        case 4: {
                                message.positiveIntValue = reader.uint64();
                                break;
                            }
                        case 5: {
                                message.negativeIntValue = reader.int64();
                                break;
                            }
                        case 6: {
                                message.doubleValue = reader.double();
                                break;
                            }
                        case 7: {
                                message.stringValue = reader.bytes();
                                break;
                            }
                        case 8: {
                                message.aggregateValue = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UninterpretedOption message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UninterpretedOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        if (!Array.isArray(message.name))
                            return "name: array expected";
                        for (var i = 0; i < message.name.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                            if (error)
                                return "name." + error;
                        }
                    }
                    if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                        if (!$util.isString(message.identifierValue))
                            return "identifierValue: string expected";
                    if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                        if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                            return "positiveIntValue: integer|Long expected";
                    if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                        if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                            return "negativeIntValue: integer|Long expected";
                    if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                        if (typeof message.doubleValue !== "number")
                            return "doubleValue: number expected";
                    if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                        if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                            return "stringValue: buffer expected";
                    if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                        if (!$util.isString(message.aggregateValue))
                            return "aggregateValue: string expected";
                    return null;
                };
    
                /**
                 * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 */
                UninterpretedOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption)
                        return object;
                    var message = new $root.google.protobuf.UninterpretedOption();
                    if (object.name) {
                        if (!Array.isArray(object.name))
                            throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                        message.name = [];
                        for (var i = 0; i < object.name.length; ++i) {
                            if (typeof object.name[i] !== "object")
                                throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                            message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                        }
                    }
                    if (object.identifierValue != null)
                        message.identifierValue = String(object.identifierValue);
                    if (object.positiveIntValue != null)
                        if ($util.Long)
                            (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                        else if (typeof object.positiveIntValue === "string")
                            message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                        else if (typeof object.positiveIntValue === "number")
                            message.positiveIntValue = object.positiveIntValue;
                        else if (typeof object.positiveIntValue === "object")
                            message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                    if (object.negativeIntValue != null)
                        if ($util.Long)
                            (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                        else if (typeof object.negativeIntValue === "string")
                            message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                        else if (typeof object.negativeIntValue === "number")
                            message.negativeIntValue = object.negativeIntValue;
                        else if (typeof object.negativeIntValue === "object")
                            message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                    if (object.doubleValue != null)
                        message.doubleValue = Number(object.doubleValue);
                    if (object.stringValue != null)
                        if (typeof object.stringValue === "string")
                            $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                        else if (object.stringValue.length >= 0)
                            message.stringValue = object.stringValue;
                    if (object.aggregateValue != null)
                        message.aggregateValue = String(object.aggregateValue);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UninterpretedOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.name = [];
                    if (options.defaults) {
                        object.identifierValue = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.positiveIntValue = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.negativeIntValue = options.longs === String ? "0" : 0;
                        object.doubleValue = 0;
                        if (options.bytes === String)
                            object.stringValue = "";
                        else {
                            object.stringValue = [];
                            if (options.bytes !== Array)
                                object.stringValue = $util.newBuffer(object.stringValue);
                        }
                        object.aggregateValue = "";
                    }
                    if (message.name && message.name.length) {
                        object.name = [];
                        for (var j = 0; j < message.name.length; ++j)
                            object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                    }
                    if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                        object.identifierValue = message.identifierValue;
                    if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                        if (typeof message.positiveIntValue === "number")
                            object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                        else
                            object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                    if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                        if (typeof message.negativeIntValue === "number")
                            object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                        else
                            object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                    if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                        object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                    if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                        object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                    if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                        object.aggregateValue = message.aggregateValue;
                    return object;
                };
    
                /**
                 * Converts this UninterpretedOption to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UninterpretedOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UninterpretedOption
                 * @function getTypeUrl
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UninterpretedOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.UninterpretedOption";
                };
    
                UninterpretedOption.NamePart = (function() {
    
                    /**
                     * Properties of a NamePart.
                     * @memberof google.protobuf.UninterpretedOption
                     * @interface INamePart
                     * @property {string} namePart NamePart namePart
                     * @property {boolean} isExtension NamePart isExtension
                     */
    
                    /**
                     * Constructs a new NamePart.
                     * @memberof google.protobuf.UninterpretedOption
                     * @classdesc Represents a NamePart.
                     * @implements INamePart
                     * @constructor
                     * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                     */
                    function NamePart(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * NamePart namePart.
                     * @member {string} namePart
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     */
                    NamePart.prototype.namePart = "";
    
                    /**
                     * NamePart isExtension.
                     * @member {boolean} isExtension
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     */
                    NamePart.prototype.isExtension = false;
    
                    /**
                     * Creates a new NamePart instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                     */
                    NamePart.create = function create(properties) {
                        return new NamePart(properties);
                    };
    
                    /**
                     * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NamePart.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NamePart.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a NamePart message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NamePart.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.namePart = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.isExtension = reader.bool();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        if (!message.hasOwnProperty("namePart"))
                            throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                        if (!message.hasOwnProperty("isExtension"))
                            throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                        return message;
                    };
    
                    /**
                     * Decodes a NamePart message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NamePart.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a NamePart message.
                     * @function verify
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NamePart.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (!$util.isString(message.namePart))
                            return "namePart: string expected";
                        if (typeof message.isExtension !== "boolean")
                            return "isExtension: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     */
                    NamePart.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                            return object;
                        var message = new $root.google.protobuf.UninterpretedOption.NamePart();
                        if (object.namePart != null)
                            message.namePart = String(object.namePart);
                        if (object.isExtension != null)
                            message.isExtension = Boolean(object.isExtension);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NamePart.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.namePart = "";
                            object.isExtension = false;
                        }
                        if (message.namePart != null && message.hasOwnProperty("namePart"))
                            object.namePart = message.namePart;
                        if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                            object.isExtension = message.isExtension;
                        return object;
                    };
    
                    /**
                     * Converts this NamePart to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NamePart.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for NamePart
                     * @function getTypeUrl
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    NamePart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.UninterpretedOption.NamePart";
                    };
    
                    return NamePart;
                })();
    
                return UninterpretedOption;
            })();
    
            protobuf.SourceCodeInfo = (function() {
    
                /**
                 * Properties of a SourceCodeInfo.
                 * @memberof google.protobuf
                 * @interface ISourceCodeInfo
                 * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
                 */
    
                /**
                 * Constructs a new SourceCodeInfo.
                 * @memberof google.protobuf
                 * @classdesc Represents a SourceCodeInfo.
                 * @implements ISourceCodeInfo
                 * @constructor
                 * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
                 */
                function SourceCodeInfo(properties) {
                    this.location = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SourceCodeInfo location.
                 * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
                 * @memberof google.protobuf.SourceCodeInfo
                 * @instance
                 */
                SourceCodeInfo.prototype.location = $util.emptyArray;
    
                /**
                 * Creates a new SourceCodeInfo instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
                 */
                SourceCodeInfo.create = function create(properties) {
                    return new SourceCodeInfo(properties);
                };
    
                /**
                 * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SourceCodeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.location != null && message.location.length)
                        for (var i = 0; i < message.location.length; ++i)
                            $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SourceCodeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SourceCodeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.location && message.location.length))
                                    message.location = [];
                                message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SourceCodeInfo message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SourceCodeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.location != null && message.hasOwnProperty("location")) {
                        if (!Array.isArray(message.location))
                            return "location: array expected";
                        for (var i = 0; i < message.location.length; ++i) {
                            var error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                            if (error)
                                return "location." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 */
                SourceCodeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo)
                        return object;
                    var message = new $root.google.protobuf.SourceCodeInfo();
                    if (object.location) {
                        if (!Array.isArray(object.location))
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                        message.location = [];
                        for (var i = 0; i < object.location.length; ++i) {
                            if (typeof object.location[i] !== "object")
                                throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                            message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SourceCodeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.location = [];
                    if (message.location && message.location.length) {
                        object.location = [];
                        for (var j = 0; j < message.location.length; ++j)
                            object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this SourceCodeInfo to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SourceCodeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for SourceCodeInfo
                 * @function getTypeUrl
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SourceCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.SourceCodeInfo";
                };
    
                SourceCodeInfo.Location = (function() {
    
                    /**
                     * Properties of a Location.
                     * @memberof google.protobuf.SourceCodeInfo
                     * @interface ILocation
                     * @property {Array.<number>|null} [path] Location path
                     * @property {Array.<number>|null} [span] Location span
                     * @property {string|null} [leadingComments] Location leadingComments
                     * @property {string|null} [trailingComments] Location trailingComments
                     * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                     */
    
                    /**
                     * Constructs a new Location.
                     * @memberof google.protobuf.SourceCodeInfo
                     * @classdesc Represents a Location.
                     * @implements ILocation
                     * @constructor
                     * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                     */
                    function Location(properties) {
                        this.path = [];
                        this.span = [];
                        this.leadingDetachedComments = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Location path.
                     * @member {Array.<number>} path
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.path = $util.emptyArray;
    
                    /**
                     * Location span.
                     * @member {Array.<number>} span
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.span = $util.emptyArray;
    
                    /**
                     * Location leadingComments.
                     * @member {string} leadingComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.leadingComments = "";
    
                    /**
                     * Location trailingComments.
                     * @member {string} trailingComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.trailingComments = "";
    
                    /**
                     * Location leadingDetachedComments.
                     * @member {Array.<string>} leadingDetachedComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.leadingDetachedComments = $util.emptyArray;
    
                    /**
                     * Creates a new Location instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                     */
                    Location.create = function create(properties) {
                        return new Location(properties);
                    };
    
                    /**
                     * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Location.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.path.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.path.length; ++i)
                                writer.int32(message.path[i]);
                            writer.ldelim();
                        }
                        if (message.span != null && message.span.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.span.length; ++i)
                                writer.int32(message.span[i]);
                            writer.ldelim();
                        }
                        if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                        if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                        if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                            for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Location.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Location message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Location.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.path && message.path.length))
                                        message.path = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.path.push(reader.int32());
                                    } else
                                        message.path.push(reader.int32());
                                    break;
                                }
                            case 2: {
                                    if (!(message.span && message.span.length))
                                        message.span = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.span.push(reader.int32());
                                    } else
                                        message.span.push(reader.int32());
                                    break;
                                }
                            case 3: {
                                    message.leadingComments = reader.string();
                                    break;
                                }
                            case 4: {
                                    message.trailingComments = reader.string();
                                    break;
                                }
                            case 6: {
                                    if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                        message.leadingDetachedComments = [];
                                    message.leadingDetachedComments.push(reader.string());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Location message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Location.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Location message.
                     * @function verify
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Location.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path")) {
                            if (!Array.isArray(message.path))
                                return "path: array expected";
                            for (var i = 0; i < message.path.length; ++i)
                                if (!$util.isInteger(message.path[i]))
                                    return "path: integer[] expected";
                        }
                        if (message.span != null && message.hasOwnProperty("span")) {
                            if (!Array.isArray(message.span))
                                return "span: array expected";
                            for (var i = 0; i < message.span.length; ++i)
                                if (!$util.isInteger(message.span[i]))
                                    return "span: integer[] expected";
                        }
                        if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                            if (!$util.isString(message.leadingComments))
                                return "leadingComments: string expected";
                        if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                            if (!$util.isString(message.trailingComments))
                                return "trailingComments: string expected";
                        if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                            if (!Array.isArray(message.leadingDetachedComments))
                                return "leadingDetachedComments: array expected";
                            for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                                if (!$util.isString(message.leadingDetachedComments[i]))
                                    return "leadingDetachedComments: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Location message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     */
                    Location.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                            return object;
                        var message = new $root.google.protobuf.SourceCodeInfo.Location();
                        if (object.path) {
                            if (!Array.isArray(object.path))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                            message.path = [];
                            for (var i = 0; i < object.path.length; ++i)
                                message.path[i] = object.path[i] | 0;
                        }
                        if (object.span) {
                            if (!Array.isArray(object.span))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                            message.span = [];
                            for (var i = 0; i < object.span.length; ++i)
                                message.span[i] = object.span[i] | 0;
                        }
                        if (object.leadingComments != null)
                            message.leadingComments = String(object.leadingComments);
                        if (object.trailingComments != null)
                            message.trailingComments = String(object.trailingComments);
                        if (object.leadingDetachedComments) {
                            if (!Array.isArray(object.leadingDetachedComments))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                            message.leadingDetachedComments = [];
                            for (var i = 0; i < object.leadingDetachedComments.length; ++i)
                                message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Location message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.Location} message Location
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Location.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.path = [];
                            object.span = [];
                            object.leadingDetachedComments = [];
                        }
                        if (options.defaults) {
                            object.leadingComments = "";
                            object.trailingComments = "";
                        }
                        if (message.path && message.path.length) {
                            object.path = [];
                            for (var j = 0; j < message.path.length; ++j)
                                object.path[j] = message.path[j];
                        }
                        if (message.span && message.span.length) {
                            object.span = [];
                            for (var j = 0; j < message.span.length; ++j)
                                object.span[j] = message.span[j];
                        }
                        if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                            object.leadingComments = message.leadingComments;
                        if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                            object.trailingComments = message.trailingComments;
                        if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                            object.leadingDetachedComments = [];
                            for (var j = 0; j < message.leadingDetachedComments.length; ++j)
                                object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Location to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Location.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Location
                     * @function getTypeUrl
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.SourceCodeInfo.Location";
                    };
    
                    return Location;
                })();
    
                return SourceCodeInfo;
            })();
    
            protobuf.GeneratedCodeInfo = (function() {
    
                /**
                 * Properties of a GeneratedCodeInfo.
                 * @memberof google.protobuf
                 * @interface IGeneratedCodeInfo
                 * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
                 */
    
                /**
                 * Constructs a new GeneratedCodeInfo.
                 * @memberof google.protobuf
                 * @classdesc Represents a GeneratedCodeInfo.
                 * @implements IGeneratedCodeInfo
                 * @constructor
                 * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
                 */
                function GeneratedCodeInfo(properties) {
                    this.annotation = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GeneratedCodeInfo annotation.
                 * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @instance
                 */
                GeneratedCodeInfo.prototype.annotation = $util.emptyArray;
    
                /**
                 * Creates a new GeneratedCodeInfo instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
                 */
                GeneratedCodeInfo.create = function create(properties) {
                    return new GeneratedCodeInfo(properties);
                };
    
                /**
                 * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCodeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.annotation != null && message.annotation.length)
                        for (var i = 0; i < message.annotation.length; ++i)
                            $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCodeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.annotation && message.annotation.length))
                                    message.annotation = [];
                                message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GeneratedCodeInfo message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GeneratedCodeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.annotation != null && message.hasOwnProperty("annotation")) {
                        if (!Array.isArray(message.annotation))
                            return "annotation: array expected";
                        for (var i = 0; i < message.annotation.length; ++i) {
                            var error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                            if (error)
                                return "annotation." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 */
                GeneratedCodeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                        return object;
                    var message = new $root.google.protobuf.GeneratedCodeInfo();
                    if (object.annotation) {
                        if (!Array.isArray(object.annotation))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                        message.annotation = [];
                        for (var i = 0; i < object.annotation.length; ++i) {
                            if (typeof object.annotation[i] !== "object")
                                throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                            message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GeneratedCodeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.annotation = [];
                    if (message.annotation && message.annotation.length) {
                        object.annotation = [];
                        for (var j = 0; j < message.annotation.length; ++j)
                            object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GeneratedCodeInfo to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for GeneratedCodeInfo
                 * @function getTypeUrl
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                GeneratedCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo";
                };
    
                GeneratedCodeInfo.Annotation = (function() {
    
                    /**
                     * Properties of an Annotation.
                     * @memberof google.protobuf.GeneratedCodeInfo
                     * @interface IAnnotation
                     * @property {Array.<number>|null} [path] Annotation path
                     * @property {string|null} [sourceFile] Annotation sourceFile
                     * @property {number|null} [begin] Annotation begin
                     * @property {number|null} [end] Annotation end
                     */
    
                    /**
                     * Constructs a new Annotation.
                     * @memberof google.protobuf.GeneratedCodeInfo
                     * @classdesc Represents an Annotation.
                     * @implements IAnnotation
                     * @constructor
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                     */
                    function Annotation(properties) {
                        this.path = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Annotation path.
                     * @member {Array.<number>} path
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.path = $util.emptyArray;
    
                    /**
                     * Annotation sourceFile.
                     * @member {string} sourceFile
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.sourceFile = "";
    
                    /**
                     * Annotation begin.
                     * @member {number} begin
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.begin = 0;
    
                    /**
                     * Annotation end.
                     * @member {number} end
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.end = 0;
    
                    /**
                     * Creates a new Annotation instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                     */
                    Annotation.create = function create(properties) {
                        return new Annotation(properties);
                    };
    
                    /**
                     * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Annotation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.path.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.path.length; ++i)
                                writer.int32(message.path[i]);
                            writer.ldelim();
                        }
                        if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                        if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Annotation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Annotation message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Annotation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.path && message.path.length))
                                        message.path = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.path.push(reader.int32());
                                    } else
                                        message.path.push(reader.int32());
                                    break;
                                }
                            case 2: {
                                    message.sourceFile = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.begin = reader.int32();
                                    break;
                                }
                            case 4: {
                                    message.end = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Annotation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Annotation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Annotation message.
                     * @function verify
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Annotation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path")) {
                            if (!Array.isArray(message.path))
                                return "path: array expected";
                            for (var i = 0; i < message.path.length; ++i)
                                if (!$util.isInteger(message.path[i]))
                                    return "path: integer[] expected";
                        }
                        if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                            if (!$util.isString(message.sourceFile))
                                return "sourceFile: string expected";
                        if (message.begin != null && message.hasOwnProperty("begin"))
                            if (!$util.isInteger(message.begin))
                                return "begin: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     */
                    Annotation.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                            return object;
                        var message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                        if (object.path) {
                            if (!Array.isArray(object.path))
                                throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                            message.path = [];
                            for (var i = 0; i < object.path.length; ++i)
                                message.path[i] = object.path[i] | 0;
                        }
                        if (object.sourceFile != null)
                            message.sourceFile = String(object.sourceFile);
                        if (object.begin != null)
                            message.begin = object.begin | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Annotation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.path = [];
                        if (options.defaults) {
                            object.sourceFile = "";
                            object.begin = 0;
                            object.end = 0;
                        }
                        if (message.path && message.path.length) {
                            object.path = [];
                            for (var j = 0; j < message.path.length; ++j)
                                object.path[j] = message.path[j];
                        }
                        if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                            object.sourceFile = message.sourceFile;
                        if (message.begin != null && message.hasOwnProperty("begin"))
                            object.begin = message.begin;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this Annotation to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Annotation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Annotation
                     * @function getTypeUrl
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Annotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo.Annotation";
                    };
    
                    return Annotation;
                })();
    
                return GeneratedCodeInfo;
            })();
    
            protobuf.DoubleValue = (function() {
    
                /**
                 * Properties of a DoubleValue.
                 * @memberof google.protobuf
                 * @interface IDoubleValue
                 * @property {number|null} [value] DoubleValue value
                 */
    
                /**
                 * Constructs a new DoubleValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a DoubleValue.
                 * @implements IDoubleValue
                 * @constructor
                 * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
                 */
                function DoubleValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DoubleValue value.
                 * @member {number} value
                 * @memberof google.protobuf.DoubleValue
                 * @instance
                 */
                DoubleValue.prototype.value = 0;
    
                /**
                 * Creates a new DoubleValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
                 * @returns {google.protobuf.DoubleValue} DoubleValue instance
                 */
                DoubleValue.create = function create(properties) {
                    return new DoubleValue(properties);
                };
    
                /**
                 * Encodes the specified DoubleValue message. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DoubleValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified DoubleValue message, length delimited. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DoubleValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DoubleValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DoubleValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DoubleValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DoubleValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DoubleValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DoubleValue message.
                 * @function verify
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DoubleValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "number")
                            return "value: number expected";
                    return null;
                };
    
                /**
                 * Creates a DoubleValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 */
                DoubleValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DoubleValue)
                        return object;
                    var message = new $root.google.protobuf.DoubleValue();
                    if (object.value != null)
                        message.value = Number(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DoubleValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.DoubleValue} message DoubleValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DoubleValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                    return object;
                };
    
                /**
                 * Converts this DoubleValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DoubleValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DoubleValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for DoubleValue
                 * @function getTypeUrl
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DoubleValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DoubleValue";
                };
    
                return DoubleValue;
            })();
    
            protobuf.FloatValue = (function() {
    
                /**
                 * Properties of a FloatValue.
                 * @memberof google.protobuf
                 * @interface IFloatValue
                 * @property {number|null} [value] FloatValue value
                 */
    
                /**
                 * Constructs a new FloatValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a FloatValue.
                 * @implements IFloatValue
                 * @constructor
                 * @param {google.protobuf.IFloatValue=} [properties] Properties to set
                 */
                function FloatValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FloatValue value.
                 * @member {number} value
                 * @memberof google.protobuf.FloatValue
                 * @instance
                 */
                FloatValue.prototype.value = 0;
    
                /**
                 * Creates a new FloatValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue=} [properties] Properties to set
                 * @returns {google.protobuf.FloatValue} FloatValue instance
                 */
                FloatValue.create = function create(properties) {
                    return new FloatValue(properties);
                };
    
                /**
                 * Encodes the specified FloatValue message. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FloatValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 5 =*/13).float(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified FloatValue message, length delimited. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FloatValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FloatValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FloatValue} FloatValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FloatValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FloatValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.float();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FloatValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FloatValue} FloatValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FloatValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FloatValue message.
                 * @function verify
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FloatValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "number")
                            return "value: number expected";
                    return null;
                };
    
                /**
                 * Creates a FloatValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FloatValue} FloatValue
                 */
                FloatValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FloatValue)
                        return object;
                    var message = new $root.google.protobuf.FloatValue();
                    if (object.value != null)
                        message.value = Number(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FloatValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.FloatValue} message FloatValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FloatValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                    return object;
                };
    
                /**
                 * Converts this FloatValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FloatValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FloatValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FloatValue
                 * @function getTypeUrl
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FloatValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FloatValue";
                };
    
                return FloatValue;
            })();
    
            protobuf.Int64Value = (function() {
    
                /**
                 * Properties of an Int64Value.
                 * @memberof google.protobuf
                 * @interface IInt64Value
                 * @property {number|Long|null} [value] Int64Value value
                 */
    
                /**
                 * Constructs a new Int64Value.
                 * @memberof google.protobuf
                 * @classdesc Represents an Int64Value.
                 * @implements IInt64Value
                 * @constructor
                 * @param {google.protobuf.IInt64Value=} [properties] Properties to set
                 */
                function Int64Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Int64Value value.
                 * @member {number|Long} value
                 * @memberof google.protobuf.Int64Value
                 * @instance
                 */
                Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new Int64Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value=} [properties] Properties to set
                 * @returns {google.protobuf.Int64Value} Int64Value instance
                 */
                Int64Value.create = function create(properties) {
                    return new Int64Value(properties);
                };
    
                /**
                 * Encodes the specified Int64Value message. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int64Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified Int64Value message, length delimited. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int64Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Int64Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Int64Value} Int64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int64Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int64Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Int64Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Int64Value} Int64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int64Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Int64Value message.
                 * @function verify
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Int64Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                            return "value: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an Int64Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Int64Value} Int64Value
                 */
                Int64Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Int64Value)
                        return object;
                    var message = new $root.google.protobuf.Int64Value();
                    if (object.value != null)
                        if ($util.Long)
                            (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                        else if (typeof object.value === "string")
                            message.value = parseInt(object.value, 10);
                        else if (typeof object.value === "number")
                            message.value = object.value;
                        else if (typeof object.value === "object")
                            message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an Int64Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.Int64Value} message Int64Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Int64Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.value = options.longs === String ? "0" : 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value === "number")
                            object.value = options.longs === String ? String(message.value) : message.value;
                        else
                            object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
                    return object;
                };
    
                /**
                 * Converts this Int64Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Int64Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Int64Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Int64Value
                 * @function getTypeUrl
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Int64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.Int64Value";
                };
    
                return Int64Value;
            })();
    
            protobuf.UInt64Value = (function() {
    
                /**
                 * Properties of a UInt64Value.
                 * @memberof google.protobuf
                 * @interface IUInt64Value
                 * @property {number|Long|null} [value] UInt64Value value
                 */
    
                /**
                 * Constructs a new UInt64Value.
                 * @memberof google.protobuf
                 * @classdesc Represents a UInt64Value.
                 * @implements IUInt64Value
                 * @constructor
                 * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
                 */
                function UInt64Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UInt64Value value.
                 * @member {number|Long} value
                 * @memberof google.protobuf.UInt64Value
                 * @instance
                 */
                UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
                /**
                 * Creates a new UInt64Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
                 * @returns {google.protobuf.UInt64Value} UInt64Value instance
                 */
                UInt64Value.create = function create(properties) {
                    return new UInt64Value(properties);
                };
    
                /**
                 * Encodes the specified UInt64Value message. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt64Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified UInt64Value message, length delimited. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt64Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UInt64Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt64Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt64Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.uint64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UInt64Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt64Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UInt64Value message.
                 * @function verify
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UInt64Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                            return "value: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a UInt64Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 */
                UInt64Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UInt64Value)
                        return object;
                    var message = new $root.google.protobuf.UInt64Value();
                    if (object.value != null)
                        if ($util.Long)
                            (message.value = $util.Long.fromValue(object.value)).unsigned = true;
                        else if (typeof object.value === "string")
                            message.value = parseInt(object.value, 10);
                        else if (typeof object.value === "number")
                            message.value = object.value;
                        else if (typeof object.value === "object")
                            message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber(true);
                    return message;
                };
    
                /**
                 * Creates a plain object from a UInt64Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.UInt64Value} message UInt64Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UInt64Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.value = options.longs === String ? "0" : 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value === "number")
                            object.value = options.longs === String ? String(message.value) : message.value;
                        else
                            object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber(true) : message.value;
                    return object;
                };
    
                /**
                 * Converts this UInt64Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UInt64Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UInt64Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UInt64Value
                 * @function getTypeUrl
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UInt64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.UInt64Value";
                };
    
                return UInt64Value;
            })();
    
            protobuf.Int32Value = (function() {
    
                /**
                 * Properties of an Int32Value.
                 * @memberof google.protobuf
                 * @interface IInt32Value
                 * @property {number|null} [value] Int32Value value
                 */
    
                /**
                 * Constructs a new Int32Value.
                 * @memberof google.protobuf
                 * @classdesc Represents an Int32Value.
                 * @implements IInt32Value
                 * @constructor
                 * @param {google.protobuf.IInt32Value=} [properties] Properties to set
                 */
                function Int32Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Int32Value value.
                 * @member {number} value
                 * @memberof google.protobuf.Int32Value
                 * @instance
                 */
                Int32Value.prototype.value = 0;
    
                /**
                 * Creates a new Int32Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value=} [properties] Properties to set
                 * @returns {google.protobuf.Int32Value} Int32Value instance
                 */
                Int32Value.create = function create(properties) {
                    return new Int32Value(properties);
                };
    
                /**
                 * Encodes the specified Int32Value message. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int32Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified Int32Value message, length delimited. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int32Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Int32Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Int32Value} Int32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int32Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int32Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Int32Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Int32Value} Int32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int32Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Int32Value message.
                 * @function verify
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Int32Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value))
                            return "value: integer expected";
                    return null;
                };
    
                /**
                 * Creates an Int32Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Int32Value} Int32Value
                 */
                Int32Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Int32Value)
                        return object;
                    var message = new $root.google.protobuf.Int32Value();
                    if (object.value != null)
                        message.value = object.value | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from an Int32Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.Int32Value} message Int32Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Int32Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this Int32Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Int32Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Int32Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Int32Value
                 * @function getTypeUrl
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Int32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.Int32Value";
                };
    
                return Int32Value;
            })();
    
            protobuf.UInt32Value = (function() {
    
                /**
                 * Properties of a UInt32Value.
                 * @memberof google.protobuf
                 * @interface IUInt32Value
                 * @property {number|null} [value] UInt32Value value
                 */
    
                /**
                 * Constructs a new UInt32Value.
                 * @memberof google.protobuf
                 * @classdesc Represents a UInt32Value.
                 * @implements IUInt32Value
                 * @constructor
                 * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
                 */
                function UInt32Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UInt32Value value.
                 * @member {number} value
                 * @memberof google.protobuf.UInt32Value
                 * @instance
                 */
                UInt32Value.prototype.value = 0;
    
                /**
                 * Creates a new UInt32Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
                 * @returns {google.protobuf.UInt32Value} UInt32Value instance
                 */
                UInt32Value.create = function create(properties) {
                    return new UInt32Value(properties);
                };
    
                /**
                 * Encodes the specified UInt32Value message. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt32Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified UInt32Value message, length delimited. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt32Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UInt32Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt32Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt32Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.uint32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UInt32Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt32Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UInt32Value message.
                 * @function verify
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UInt32Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value))
                            return "value: integer expected";
                    return null;
                };
    
                /**
                 * Creates a UInt32Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 */
                UInt32Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UInt32Value)
                        return object;
                    var message = new $root.google.protobuf.UInt32Value();
                    if (object.value != null)
                        message.value = object.value >>> 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a UInt32Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.UInt32Value} message UInt32Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UInt32Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this UInt32Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UInt32Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UInt32Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for UInt32Value
                 * @function getTypeUrl
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UInt32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.UInt32Value";
                };
    
                return UInt32Value;
            })();
    
            protobuf.BoolValue = (function() {
    
                /**
                 * Properties of a BoolValue.
                 * @memberof google.protobuf
                 * @interface IBoolValue
                 * @property {boolean|null} [value] BoolValue value
                 */
    
                /**
                 * Constructs a new BoolValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a BoolValue.
                 * @implements IBoolValue
                 * @constructor
                 * @param {google.protobuf.IBoolValue=} [properties] Properties to set
                 */
                function BoolValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BoolValue value.
                 * @member {boolean} value
                 * @memberof google.protobuf.BoolValue
                 * @instance
                 */
                BoolValue.prototype.value = false;
    
                /**
                 * Creates a new BoolValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue=} [properties] Properties to set
                 * @returns {google.protobuf.BoolValue} BoolValue instance
                 */
                BoolValue.create = function create(properties) {
                    return new BoolValue(properties);
                };
    
                /**
                 * Encodes the specified BoolValue message. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoolValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified BoolValue message, length delimited. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoolValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BoolValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.BoolValue} BoolValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoolValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BoolValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BoolValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.BoolValue} BoolValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoolValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BoolValue message.
                 * @function verify
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BoolValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "boolean")
                            return "value: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.BoolValue} BoolValue
                 */
                BoolValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.BoolValue)
                        return object;
                    var message = new $root.google.protobuf.BoolValue();
                    if (object.value != null)
                        message.value = Boolean(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.BoolValue} message BoolValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BoolValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = false;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this BoolValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.BoolValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BoolValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BoolValue
                 * @function getTypeUrl
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BoolValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.BoolValue";
                };
    
                return BoolValue;
            })();
    
            protobuf.StringValue = (function() {
    
                /**
                 * Properties of a StringValue.
                 * @memberof google.protobuf
                 * @interface IStringValue
                 * @property {string|null} [value] StringValue value
                 */
    
                /**
                 * Constructs a new StringValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a StringValue.
                 * @implements IStringValue
                 * @constructor
                 * @param {google.protobuf.IStringValue=} [properties] Properties to set
                 */
                function StringValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StringValue value.
                 * @member {string} value
                 * @memberof google.protobuf.StringValue
                 * @instance
                 */
                StringValue.prototype.value = "";
    
                /**
                 * Creates a new StringValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue=} [properties] Properties to set
                 * @returns {google.protobuf.StringValue} StringValue instance
                 */
                StringValue.create = function create(properties) {
                    return new StringValue(properties);
                };
    
                /**
                 * Encodes the specified StringValue message. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StringValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified StringValue message, length delimited. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StringValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StringValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.StringValue} StringValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StringValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.StringValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StringValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.StringValue} StringValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StringValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StringValue message.
                 * @function verify
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StringValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    return null;
                };
    
                /**
                 * Creates a StringValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.StringValue} StringValue
                 */
                StringValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.StringValue)
                        return object;
                    var message = new $root.google.protobuf.StringValue();
                    if (object.value != null)
                        message.value = String(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a StringValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.StringValue} message StringValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StringValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = "";
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this StringValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.StringValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StringValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for StringValue
                 * @function getTypeUrl
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StringValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.StringValue";
                };
    
                return StringValue;
            })();
    
            protobuf.BytesValue = (function() {
    
                /**
                 * Properties of a BytesValue.
                 * @memberof google.protobuf
                 * @interface IBytesValue
                 * @property {Uint8Array|null} [value] BytesValue value
                 */
    
                /**
                 * Constructs a new BytesValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a BytesValue.
                 * @implements IBytesValue
                 * @constructor
                 * @param {google.protobuf.IBytesValue=} [properties] Properties to set
                 */
                function BytesValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BytesValue value.
                 * @member {Uint8Array} value
                 * @memberof google.protobuf.BytesValue
                 * @instance
                 */
                BytesValue.prototype.value = $util.newBuffer([]);
    
                /**
                 * Creates a new BytesValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue=} [properties] Properties to set
                 * @returns {google.protobuf.BytesValue} BytesValue instance
                 */
                BytesValue.create = function create(properties) {
                    return new BytesValue(properties);
                };
    
                /**
                 * Encodes the specified BytesValue message. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BytesValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified BytesValue message, length delimited. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BytesValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BytesValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.BytesValue} BytesValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BytesValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BytesValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.value = reader.bytes();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BytesValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.BytesValue} BytesValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BytesValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BytesValue message.
                 * @function verify
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BytesValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                            return "value: buffer expected";
                    return null;
                };
    
                /**
                 * Creates a BytesValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.BytesValue} BytesValue
                 */
                BytesValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.BytesValue)
                        return object;
                    var message = new $root.google.protobuf.BytesValue();
                    if (object.value != null)
                        if (typeof object.value === "string")
                            $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                        else if (object.value.length >= 0)
                            message.value = object.value;
                    return message;
                };
    
                /**
                 * Creates a plain object from a BytesValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.BytesValue} message BytesValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BytesValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if (options.bytes === String)
                            object.value = "";
                        else {
                            object.value = [];
                            if (options.bytes !== Array)
                                object.value = $util.newBuffer(object.value);
                        }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                    return object;
                };
    
                /**
                 * Converts this BytesValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.BytesValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BytesValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for BytesValue
                 * @function getTypeUrl
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BytesValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.BytesValue";
                };
    
                return BytesValue;
            })();
    
            protobuf.Timestamp = (function() {
    
                /**
                 * Properties of a Timestamp.
                 * @memberof google.protobuf
                 * @interface ITimestamp
                 * @property {number|Long|null} [seconds] Timestamp seconds
                 * @property {number|null} [nanos] Timestamp nanos
                 */
    
                /**
                 * Constructs a new Timestamp.
                 * @memberof google.protobuf
                 * @classdesc Represents a Timestamp.
                 * @implements ITimestamp
                 * @constructor
                 * @param {google.protobuf.ITimestamp=} [properties] Properties to set
                 */
                function Timestamp(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Timestamp seconds.
                 * @member {number|Long} seconds
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 */
                Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Timestamp nanos.
                 * @member {number} nanos
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 */
                Timestamp.prototype.nanos = 0;
    
                /**
                 * Creates a new Timestamp instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp=} [properties] Properties to set
                 * @returns {google.protobuf.Timestamp} Timestamp instance
                 */
                Timestamp.create = function create(properties) {
                    return new Timestamp(properties);
                };
    
                /**
                 * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                    return writer;
                };
    
                /**
                 * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Timestamp message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.seconds = reader.int64();
                                break;
                            }
                        case 2: {
                                message.nanos = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Timestamp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Timestamp message.
                 * @function verify
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Timestamp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                            return "seconds: integer|Long expected";
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        if (!$util.isInteger(message.nanos))
                            return "nanos: integer expected";
                    return null;
                };
    
                /**
                 * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Timestamp} Timestamp
                 */
                Timestamp.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Timestamp)
                        return object;
                    var message = new $root.google.protobuf.Timestamp();
                    if (object.seconds != null)
                        if ($util.Long)
                            (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                        else if (typeof object.seconds === "string")
                            message.seconds = parseInt(object.seconds, 10);
                        else if (typeof object.seconds === "number")
                            message.seconds = object.seconds;
                        else if (typeof object.seconds === "object")
                            message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                    if (object.nanos != null)
                        message.nanos = object.nanos | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.Timestamp} message Timestamp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Timestamp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.seconds = options.longs === String ? "0" : 0;
                        object.nanos = 0;
                    }
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (typeof message.seconds === "number")
                            object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                        else
                            object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        object.nanos = message.nanos;
                    return object;
                };
    
                /**
                 * Converts this Timestamp to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Timestamp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Timestamp
                 * @function getTypeUrl
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.Timestamp";
                };
    
                return Timestamp;
            })();
    
            protobuf.FieldMask = (function() {
    
                /**
                 * Properties of a FieldMask.
                 * @memberof google.protobuf
                 * @interface IFieldMask
                 * @property {Array.<string>|null} [paths] FieldMask paths
                 */
    
                /**
                 * Constructs a new FieldMask.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldMask.
                 * @implements IFieldMask
                 * @constructor
                 * @param {google.protobuf.IFieldMask=} [properties] Properties to set
                 */
                function FieldMask(properties) {
                    this.paths = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldMask paths.
                 * @member {Array.<string>} paths
                 * @memberof google.protobuf.FieldMask
                 * @instance
                 */
                FieldMask.prototype.paths = $util.emptyArray;
    
                /**
                 * Creates a new FieldMask instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {google.protobuf.IFieldMask=} [properties] Properties to set
                 * @returns {google.protobuf.FieldMask} FieldMask instance
                 */
                FieldMask.create = function create(properties) {
                    return new FieldMask(properties);
                };
    
                /**
                 * Encodes the specified FieldMask message. Does not implicitly {@link google.protobuf.FieldMask.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {google.protobuf.IFieldMask} message FieldMask message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldMask.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.paths != null && message.paths.length)
                        for (var i = 0; i < message.paths.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.paths[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldMask message, length delimited. Does not implicitly {@link google.protobuf.FieldMask.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {google.protobuf.IFieldMask} message FieldMask message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldMask.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldMask message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldMask} FieldMask
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldMask.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldMask();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.paths && message.paths.length))
                                    message.paths = [];
                                message.paths.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldMask message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldMask} FieldMask
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldMask.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldMask message.
                 * @function verify
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldMask.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.paths != null && message.hasOwnProperty("paths")) {
                        if (!Array.isArray(message.paths))
                            return "paths: array expected";
                        for (var i = 0; i < message.paths.length; ++i)
                            if (!$util.isString(message.paths[i]))
                                return "paths: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldMask message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldMask} FieldMask
                 */
                FieldMask.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldMask)
                        return object;
                    var message = new $root.google.protobuf.FieldMask();
                    if (object.paths) {
                        if (!Array.isArray(object.paths))
                            throw TypeError(".google.protobuf.FieldMask.paths: array expected");
                        message.paths = [];
                        for (var i = 0; i < object.paths.length; ++i)
                            message.paths[i] = String(object.paths[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldMask message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {google.protobuf.FieldMask} message FieldMask
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldMask.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.paths = [];
                    if (message.paths && message.paths.length) {
                        object.paths = [];
                        for (var j = 0; j < message.paths.length; ++j)
                            object.paths[j] = message.paths[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this FieldMask to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldMask
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldMask.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for FieldMask
                 * @function getTypeUrl
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                FieldMask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.FieldMask";
                };
    
                return FieldMask;
            })();
    
            protobuf.Any = (function() {
    
                /**
                 * Properties of an Any.
                 * @memberof google.protobuf
                 * @interface IAny
                 * @property {string|null} [type_url] Any type_url
                 * @property {Uint8Array|null} [value] Any value
                 */
    
                /**
                 * Constructs a new Any.
                 * @memberof google.protobuf
                 * @classdesc Represents an Any.
                 * @implements IAny
                 * @constructor
                 * @param {google.protobuf.IAny=} [properties] Properties to set
                 */
                function Any(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Any type_url.
                 * @member {string} type_url
                 * @memberof google.protobuf.Any
                 * @instance
                 */
                Any.prototype.type_url = "";
    
                /**
                 * Any value.
                 * @member {Uint8Array} value
                 * @memberof google.protobuf.Any
                 * @instance
                 */
                Any.prototype.value = $util.newBuffer([]);
    
                /**
                 * Creates a new Any instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {google.protobuf.IAny=} [properties] Properties to set
                 * @returns {google.protobuf.Any} Any instance
                 */
                Any.create = function create(properties) {
                    return new Any(properties);
                };
    
                /**
                 * Encodes the specified Any message. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {google.protobuf.IAny} message Any message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Any.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type_url != null && Object.hasOwnProperty.call(message, "type_url"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.type_url);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified Any message, length delimited. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {google.protobuf.IAny} message Any message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Any.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Any message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Any} Any
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Any.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Any();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type_url = reader.string();
                                break;
                            }
                        case 2: {
                                message.value = reader.bytes();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Any message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Any} Any
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Any.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Any message.
                 * @function verify
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Any.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type_url != null && message.hasOwnProperty("type_url"))
                        if (!$util.isString(message.type_url))
                            return "type_url: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                            return "value: buffer expected";
                    return null;
                };
    
                /**
                 * Creates an Any message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Any} Any
                 */
                Any.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Any)
                        return object;
                    var message = new $root.google.protobuf.Any();
                    if (object.type_url != null)
                        message.type_url = String(object.type_url);
                    if (object.value != null)
                        if (typeof object.value === "string")
                            $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                        else if (object.value.length >= 0)
                            message.value = object.value;
                    return message;
                };
    
                /**
                 * Creates a plain object from an Any message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {google.protobuf.Any} message Any
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Any.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type_url = "";
                        if (options.bytes === String)
                            object.value = "";
                        else {
                            object.value = [];
                            if (options.bytes !== Array)
                                object.value = $util.newBuffer(object.value);
                        }
                    }
                    if (message.type_url != null && message.hasOwnProperty("type_url"))
                        object.type_url = message.type_url;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                    return object;
                };
    
                /**
                 * Converts this Any to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Any
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Any.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Any
                 * @function getTypeUrl
                 * @memberof google.protobuf.Any
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Any.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.Any";
                };
    
                return Any;
            })();
    
            protobuf.Struct = (function() {
    
                /**
                 * Properties of a Struct.
                 * @memberof google.protobuf
                 * @interface IStruct
                 * @property {Object.<string,google.protobuf.IValue>|null} [fields] Struct fields
                 */
    
                /**
                 * Constructs a new Struct.
                 * @memberof google.protobuf
                 * @classdesc Represents a Struct.
                 * @implements IStruct
                 * @constructor
                 * @param {google.protobuf.IStruct=} [properties] Properties to set
                 */
                function Struct(properties) {
                    this.fields = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Struct fields.
                 * @member {Object.<string,google.protobuf.IValue>} fields
                 * @memberof google.protobuf.Struct
                 * @instance
                 */
                Struct.prototype.fields = $util.emptyObject;
    
                /**
                 * Creates a new Struct instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {google.protobuf.IStruct=} [properties] Properties to set
                 * @returns {google.protobuf.Struct} Struct instance
                 */
                Struct.create = function create(properties) {
                    return new Struct(properties);
                };
    
                /**
                 * Encodes the specified Struct message. Does not implicitly {@link google.protobuf.Struct.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {google.protobuf.IStruct} message Struct message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Struct.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fields != null && Object.hasOwnProperty.call(message, "fields"))
                        for (var keys = Object.keys(message.fields), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.google.protobuf.Value.encode(message.fields[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified Struct message, length delimited. Does not implicitly {@link google.protobuf.Struct.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {google.protobuf.IStruct} message Struct message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Struct.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Struct message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Struct} Struct
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Struct.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Struct(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (message.fields === $util.emptyObject)
                                    message.fields = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.google.protobuf.Value.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.fields[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Struct message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Struct} Struct
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Struct.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Struct message.
                 * @function verify
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Struct.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fields != null && message.hasOwnProperty("fields")) {
                        if (!$util.isObject(message.fields))
                            return "fields: object expected";
                        var key = Object.keys(message.fields);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.google.protobuf.Value.verify(message.fields[key[i]]);
                            if (error)
                                return "fields." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Struct message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Struct} Struct
                 */
                Struct.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Struct)
                        return object;
                    var message = new $root.google.protobuf.Struct();
                    if (object.fields) {
                        if (typeof object.fields !== "object")
                            throw TypeError(".google.protobuf.Struct.fields: object expected");
                        message.fields = {};
                        for (var keys = Object.keys(object.fields), i = 0; i < keys.length; ++i) {
                            if (typeof object.fields[keys[i]] !== "object")
                                throw TypeError(".google.protobuf.Struct.fields: object expected");
                            message.fields[keys[i]] = $root.google.protobuf.Value.fromObject(object.fields[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Struct message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {google.protobuf.Struct} message Struct
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Struct.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.fields = {};
                    var keys2;
                    if (message.fields && (keys2 = Object.keys(message.fields)).length) {
                        object.fields = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.fields[keys2[j]] = $root.google.protobuf.Value.toObject(message.fields[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Struct to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Struct
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Struct.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Struct
                 * @function getTypeUrl
                 * @memberof google.protobuf.Struct
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Struct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.Struct";
                };
    
                return Struct;
            })();
    
            protobuf.Value = (function() {
    
                /**
                 * Properties of a Value.
                 * @memberof google.protobuf
                 * @interface IValue
                 * @property {google.protobuf.NullValue|null} [nullValue] Value nullValue
                 * @property {number|null} [numberValue] Value numberValue
                 * @property {string|null} [stringValue] Value stringValue
                 * @property {boolean|null} [boolValue] Value boolValue
                 * @property {google.protobuf.IStruct|null} [structValue] Value structValue
                 * @property {google.protobuf.IListValue|null} [listValue] Value listValue
                 */
    
                /**
                 * Constructs a new Value.
                 * @memberof google.protobuf
                 * @classdesc Represents a Value.
                 * @implements IValue
                 * @constructor
                 * @param {google.protobuf.IValue=} [properties] Properties to set
                 */
                function Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Value nullValue.
                 * @member {google.protobuf.NullValue|null|undefined} nullValue
                 * @memberof google.protobuf.Value
                 * @instance
                 */
                Value.prototype.nullValue = null;
    
                /**
                 * Value numberValue.
                 * @member {number|null|undefined} numberValue
                 * @memberof google.protobuf.Value
                 * @instance
                 */
                Value.prototype.numberValue = null;
    
                /**
                 * Value stringValue.
                 * @member {string|null|undefined} stringValue
                 * @memberof google.protobuf.Value
                 * @instance
                 */
                Value.prototype.stringValue = null;
    
                /**
                 * Value boolValue.
                 * @member {boolean|null|undefined} boolValue
                 * @memberof google.protobuf.Value
                 * @instance
                 */
                Value.prototype.boolValue = null;
    
                /**
                 * Value structValue.
                 * @member {google.protobuf.IStruct|null|undefined} structValue
                 * @memberof google.protobuf.Value
                 * @instance
                 */
                Value.prototype.structValue = null;
    
                /**
                 * Value listValue.
                 * @member {google.protobuf.IListValue|null|undefined} listValue
                 * @memberof google.protobuf.Value
                 * @instance
                 */
                Value.prototype.listValue = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * Value kind.
                 * @member {"nullValue"|"numberValue"|"stringValue"|"boolValue"|"structValue"|"listValue"|undefined} kind
                 * @memberof google.protobuf.Value
                 * @instance
                 */
                Object.defineProperty(Value.prototype, "kind", {
                    get: $util.oneOfGetter($oneOfFields = ["nullValue", "numberValue", "stringValue", "boolValue", "structValue", "listValue"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {google.protobuf.IValue=} [properties] Properties to set
                 * @returns {google.protobuf.Value} Value instance
                 */
                Value.create = function create(properties) {
                    return new Value(properties);
                };
    
                /**
                 * Encodes the specified Value message. Does not implicitly {@link google.protobuf.Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {google.protobuf.IValue} message Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.nullValue != null && Object.hasOwnProperty.call(message, "nullValue"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.nullValue);
                    if (message.numberValue != null && Object.hasOwnProperty.call(message, "numberValue"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.numberValue);
                    if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.stringValue);
                    if (message.boolValue != null && Object.hasOwnProperty.call(message, "boolValue"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.boolValue);
                    if (message.structValue != null && Object.hasOwnProperty.call(message, "structValue"))
                        $root.google.protobuf.Struct.encode(message.structValue, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.listValue != null && Object.hasOwnProperty.call(message, "listValue"))
                        $root.google.protobuf.ListValue.encode(message.listValue, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Value message, length delimited. Does not implicitly {@link google.protobuf.Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {google.protobuf.IValue} message Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Value} Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.nullValue = reader.int32();
                                break;
                            }
                        case 2: {
                                message.numberValue = reader.double();
                                break;
                            }
                        case 3: {
                                message.stringValue = reader.string();
                                break;
                            }
                        case 4: {
                                message.boolValue = reader.bool();
                                break;
                            }
                        case 5: {
                                message.structValue = $root.google.protobuf.Struct.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.listValue = $root.google.protobuf.ListValue.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Value} Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Value message.
                 * @function verify
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.nullValue != null && message.hasOwnProperty("nullValue")) {
                        properties.kind = 1;
                        switch (message.nullValue) {
                        default:
                            return "nullValue: enum value expected";
                        case 0:
                            break;
                        }
                    }
                    if (message.numberValue != null && message.hasOwnProperty("numberValue")) {
                        if (properties.kind === 1)
                            return "kind: multiple values";
                        properties.kind = 1;
                        if (typeof message.numberValue !== "number")
                            return "numberValue: number expected";
                    }
                    if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                        if (properties.kind === 1)
                            return "kind: multiple values";
                        properties.kind = 1;
                        if (!$util.isString(message.stringValue))
                            return "stringValue: string expected";
                    }
                    if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                        if (properties.kind === 1)
                            return "kind: multiple values";
                        properties.kind = 1;
                        if (typeof message.boolValue !== "boolean")
                            return "boolValue: boolean expected";
                    }
                    if (message.structValue != null && message.hasOwnProperty("structValue")) {
                        if (properties.kind === 1)
                            return "kind: multiple values";
                        properties.kind = 1;
                        {
                            var error = $root.google.protobuf.Struct.verify(message.structValue);
                            if (error)
                                return "structValue." + error;
                        }
                    }
                    if (message.listValue != null && message.hasOwnProperty("listValue")) {
                        if (properties.kind === 1)
                            return "kind: multiple values";
                        properties.kind = 1;
                        {
                            var error = $root.google.protobuf.ListValue.verify(message.listValue);
                            if (error)
                                return "listValue." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Value} Value
                 */
                Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Value)
                        return object;
                    var message = new $root.google.protobuf.Value();
                    switch (object.nullValue) {
                    default:
                        if (typeof object.nullValue === "number") {
                            message.nullValue = object.nullValue;
                            break;
                        }
                        break;
                    case "NULL_VALUE":
                    case 0:
                        message.nullValue = 0;
                        break;
                    }
                    if (object.numberValue != null)
                        message.numberValue = Number(object.numberValue);
                    if (object.stringValue != null)
                        message.stringValue = String(object.stringValue);
                    if (object.boolValue != null)
                        message.boolValue = Boolean(object.boolValue);
                    if (object.structValue != null) {
                        if (typeof object.structValue !== "object")
                            throw TypeError(".google.protobuf.Value.structValue: object expected");
                        message.structValue = $root.google.protobuf.Struct.fromObject(object.structValue);
                    }
                    if (object.listValue != null) {
                        if (typeof object.listValue !== "object")
                            throw TypeError(".google.protobuf.Value.listValue: object expected");
                        message.listValue = $root.google.protobuf.ListValue.fromObject(object.listValue);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {google.protobuf.Value} message Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.nullValue != null && message.hasOwnProperty("nullValue")) {
                        object.nullValue = options.enums === String ? $root.google.protobuf.NullValue[message.nullValue] === undefined ? message.nullValue : $root.google.protobuf.NullValue[message.nullValue] : message.nullValue;
                        if (options.oneofs)
                            object.kind = "nullValue";
                    }
                    if (message.numberValue != null && message.hasOwnProperty("numberValue")) {
                        object.numberValue = options.json && !isFinite(message.numberValue) ? String(message.numberValue) : message.numberValue;
                        if (options.oneofs)
                            object.kind = "numberValue";
                    }
                    if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                        object.stringValue = message.stringValue;
                        if (options.oneofs)
                            object.kind = "stringValue";
                    }
                    if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                        object.boolValue = message.boolValue;
                        if (options.oneofs)
                            object.kind = "boolValue";
                    }
                    if (message.structValue != null && message.hasOwnProperty("structValue")) {
                        object.structValue = $root.google.protobuf.Struct.toObject(message.structValue, options);
                        if (options.oneofs)
                            object.kind = "structValue";
                    }
                    if (message.listValue != null && message.hasOwnProperty("listValue")) {
                        object.listValue = $root.google.protobuf.ListValue.toObject(message.listValue, options);
                        if (options.oneofs)
                            object.kind = "listValue";
                    }
                    return object;
                };
    
                /**
                 * Converts this Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Value
                 * @function getTypeUrl
                 * @memberof google.protobuf.Value
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.Value";
                };
    
                return Value;
            })();
    
            /**
             * NullValue enum.
             * @name google.protobuf.NullValue
             * @enum {number}
             * @property {number} NULL_VALUE=0 NULL_VALUE value
             */
            protobuf.NullValue = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NULL_VALUE"] = 0;
                return values;
            })();
    
            protobuf.ListValue = (function() {
    
                /**
                 * Properties of a ListValue.
                 * @memberof google.protobuf
                 * @interface IListValue
                 * @property {Array.<google.protobuf.IValue>|null} [values] ListValue values
                 */
    
                /**
                 * Constructs a new ListValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a ListValue.
                 * @implements IListValue
                 * @constructor
                 * @param {google.protobuf.IListValue=} [properties] Properties to set
                 */
                function ListValue(properties) {
                    this.values = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ListValue values.
                 * @member {Array.<google.protobuf.IValue>} values
                 * @memberof google.protobuf.ListValue
                 * @instance
                 */
                ListValue.prototype.values = $util.emptyArray;
    
                /**
                 * Creates a new ListValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {google.protobuf.IListValue=} [properties] Properties to set
                 * @returns {google.protobuf.ListValue} ListValue instance
                 */
                ListValue.create = function create(properties) {
                    return new ListValue(properties);
                };
    
                /**
                 * Encodes the specified ListValue message. Does not implicitly {@link google.protobuf.ListValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {google.protobuf.IListValue} message ListValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.values != null && message.values.length)
                        for (var i = 0; i < message.values.length; ++i)
                            $root.google.protobuf.Value.encode(message.values[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ListValue message, length delimited. Does not implicitly {@link google.protobuf.ListValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {google.protobuf.IListValue} message ListValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ListValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ListValue} ListValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ListValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push($root.google.protobuf.Value.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ListValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ListValue} ListValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ListValue message.
                 * @function verify
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.values != null && message.hasOwnProperty("values")) {
                        if (!Array.isArray(message.values))
                            return "values: array expected";
                        for (var i = 0; i < message.values.length; ++i) {
                            var error = $root.google.protobuf.Value.verify(message.values[i]);
                            if (error)
                                return "values." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ListValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ListValue} ListValue
                 */
                ListValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ListValue)
                        return object;
                    var message = new $root.google.protobuf.ListValue();
                    if (object.values) {
                        if (!Array.isArray(object.values))
                            throw TypeError(".google.protobuf.ListValue.values: array expected");
                        message.values = [];
                        for (var i = 0; i < object.values.length; ++i) {
                            if (typeof object.values[i] !== "object")
                                throw TypeError(".google.protobuf.ListValue.values: object expected");
                            message.values[i] = $root.google.protobuf.Value.fromObject(object.values[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ListValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {google.protobuf.ListValue} message ListValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.values = [];
                    if (message.values && message.values.length) {
                        object.values = [];
                        for (var j = 0; j < message.values.length; ++j)
                            object.values[j] = $root.google.protobuf.Value.toObject(message.values[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ListValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ListValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for ListValue
                 * @function getTypeUrl
                 * @memberof google.protobuf.ListValue
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ListValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.ListValue";
                };
    
                return ListValue;
            })();
    
            return protobuf;
        })();
    
        google.type = (function() {
    
            /**
             * Namespace type.
             * @memberof google
             * @namespace
             */
            var type = {};
    
            type.TimeOfDay = (function() {
    
                /**
                 * Properties of a TimeOfDay.
                 * @memberof google.type
                 * @interface ITimeOfDay
                 * @property {number|null} [hours] TimeOfDay hours
                 * @property {number|null} [minutes] TimeOfDay minutes
                 * @property {number|null} [seconds] TimeOfDay seconds
                 * @property {number|null} [nanos] TimeOfDay nanos
                 */
    
                /**
                 * Constructs a new TimeOfDay.
                 * @memberof google.type
                 * @classdesc Represents a TimeOfDay.
                 * @implements ITimeOfDay
                 * @constructor
                 * @param {google.type.ITimeOfDay=} [properties] Properties to set
                 */
                function TimeOfDay(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TimeOfDay hours.
                 * @member {number} hours
                 * @memberof google.type.TimeOfDay
                 * @instance
                 */
                TimeOfDay.prototype.hours = 0;
    
                /**
                 * TimeOfDay minutes.
                 * @member {number} minutes
                 * @memberof google.type.TimeOfDay
                 * @instance
                 */
                TimeOfDay.prototype.minutes = 0;
    
                /**
                 * TimeOfDay seconds.
                 * @member {number} seconds
                 * @memberof google.type.TimeOfDay
                 * @instance
                 */
                TimeOfDay.prototype.seconds = 0;
    
                /**
                 * TimeOfDay nanos.
                 * @member {number} nanos
                 * @memberof google.type.TimeOfDay
                 * @instance
                 */
                TimeOfDay.prototype.nanos = 0;
    
                /**
                 * Creates a new TimeOfDay instance using the specified properties.
                 * @function create
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {google.type.ITimeOfDay=} [properties] Properties to set
                 * @returns {google.type.TimeOfDay} TimeOfDay instance
                 */
                TimeOfDay.create = function create(properties) {
                    return new TimeOfDay(properties);
                };
    
                /**
                 * Encodes the specified TimeOfDay message. Does not implicitly {@link google.type.TimeOfDay.verify|verify} messages.
                 * @function encode
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {google.type.ITimeOfDay} message TimeOfDay message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeOfDay.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hours != null && Object.hasOwnProperty.call(message, "hours"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hours);
                    if (message.minutes != null && Object.hasOwnProperty.call(message, "minutes"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minutes);
                    if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.seconds);
                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.nanos);
                    return writer;
                };
    
                /**
                 * Encodes the specified TimeOfDay message, length delimited. Does not implicitly {@link google.type.TimeOfDay.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {google.type.ITimeOfDay} message TimeOfDay message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeOfDay.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TimeOfDay message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.type.TimeOfDay} TimeOfDay
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeOfDay.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.type.TimeOfDay();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hours = reader.int32();
                                break;
                            }
                        case 2: {
                                message.minutes = reader.int32();
                                break;
                            }
                        case 3: {
                                message.seconds = reader.int32();
                                break;
                            }
                        case 4: {
                                message.nanos = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TimeOfDay message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.type.TimeOfDay} TimeOfDay
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeOfDay.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TimeOfDay message.
                 * @function verify
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimeOfDay.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hours != null && message.hasOwnProperty("hours"))
                        if (!$util.isInteger(message.hours))
                            return "hours: integer expected";
                    if (message.minutes != null && message.hasOwnProperty("minutes"))
                        if (!$util.isInteger(message.minutes))
                            return "minutes: integer expected";
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (!$util.isInteger(message.seconds))
                            return "seconds: integer expected";
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        if (!$util.isInteger(message.nanos))
                            return "nanos: integer expected";
                    return null;
                };
    
                /**
                 * Creates a TimeOfDay message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.type.TimeOfDay} TimeOfDay
                 */
                TimeOfDay.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.type.TimeOfDay)
                        return object;
                    var message = new $root.google.type.TimeOfDay();
                    if (object.hours != null)
                        message.hours = object.hours | 0;
                    if (object.minutes != null)
                        message.minutes = object.minutes | 0;
                    if (object.seconds != null)
                        message.seconds = object.seconds | 0;
                    if (object.nanos != null)
                        message.nanos = object.nanos | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a TimeOfDay message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {google.type.TimeOfDay} message TimeOfDay
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimeOfDay.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hours = 0;
                        object.minutes = 0;
                        object.seconds = 0;
                        object.nanos = 0;
                    }
                    if (message.hours != null && message.hasOwnProperty("hours"))
                        object.hours = message.hours;
                    if (message.minutes != null && message.hasOwnProperty("minutes"))
                        object.minutes = message.minutes;
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        object.seconds = message.seconds;
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        object.nanos = message.nanos;
                    return object;
                };
    
                /**
                 * Converts this TimeOfDay to JSON.
                 * @function toJSON
                 * @memberof google.type.TimeOfDay
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimeOfDay.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TimeOfDay
                 * @function getTypeUrl
                 * @memberof google.type.TimeOfDay
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TimeOfDay.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.type.TimeOfDay";
                };
    
                return TimeOfDay;
            })();
    
            return type;
        })();
    
        return google;
    })();

    return $root;
});
